import { PMIC } from "../../../constants/componentType";
import { strDelimited } from "../../helper/split";

function getPortPins(portPinsGroups) {
  let powerPins = [], groundPins = [];
  for (let item of portPinsGroups) {
    powerPins.push(...item.powerPins);
    groundPins.push(...item.groundPins)
  }
  return { powerPins, groundPins }
}

class SignOffGroups {
  constructor() {
    this.signOffGroups = new Map();
    this.soc = null;
    this.gnd = null;
    this.templateGroups = new Map();
    this.option = null;
  }

  clearCache = () => {
    this.signOffGroups = new Map();
    this.soc = null;
    this.gnd = null;
    this.templateGroups = new Map();
    this.option = null;
  }

  updateTemplateSetting = ({ soc, gnd, option }) => {
    this.soc = soc || this.soc;
    this.gnd = gnd || this.gnd;
    this.option = option || option;
  }

  initGroups = ({ signOffTemplateInfo, soc, gnd, option }) => {
    let signOffGroups = new Map();
    this.soc = soc;
    this.gnd = gnd;
    this.option = option;

    const groupKeys = [...new Set(signOffTemplateInfo.map(item => item.groupKey))];
    for (let group of groupKeys) {
      const [, , , isPmic] = strDelimited(group || "", "::");
      if (isPmic) {
        continue;
      }
      const templates = signOffTemplateInfo.filter(item => item.groupKey === group);

      signOffGroups.set(group, {
        socGroups: templates.filter(item => item.compType !== PMIC),
        pmicGroups: templates.filter(item => item.compType === PMIC)
      })
    }
    this.signOffGroups = signOffGroups;
  }

  getSignOffGroups = () => {
    return this.signOffGroups;
  }

  getTemplateGroups = () => {
    return this.templateGroups;
  }

  updateGroupData = () => {
    this.templateGroups = new Map();
    for (let key of Array.from(this.signOffGroups.keys())) {
      const group = this.signOffGroups.get(key);
      const [, soc, gnd] = strDelimited(key, "::");
      let socGroups = JSON.parse(JSON.stringify(group.socGroups || [])),
        pmicGroups = JSON.parse(JSON.stringify(group.pmicGroups || []));

      let powerNets = [], groundNets = [],
        extendNets = [],
        pmicList = [], socPortPins = [], pmicPortPins = [], pmicAndNets = [];

      if (!socGroups.length) {
        continue;
      }

      socGroups.forEach(item => {
        if (!item.refdes) {
          item.refdes = soc;
          item.compType = "SOC";
        }
      });

      pmicList = pmicGroups && pmicGroups.length ? pmicGroups.map(it => it.refdes) : "";
      pmicAndNets = pmicGroups && pmicGroups.length ? pmicGroups.map(it => { return { net: it.powerNetName, pmic: it.refdes } }) : "";

      powerNets = [...new Set(socGroups.map(item => item.powerNetName))].filter(item => !!item);
      groundNets = [...new Set(socGroups.map(item => item.gndNetName))].filter(item => !!item);
      extendNets = [...new Set(pmicGroups.map(item => item.powerNetName))].filter(item => !!item && !powerNets.includes(item));

      if (!groundNets.length && gnd) {
        groundNets = [gnd]
      }

      socPortPins = socGroups.map(item => {
        return {
          comp: item.refdes,
          powerPins: [...(item.powerPins || [])],
          groundPins: [...(item.groundPins || [])],
          effectivePowerPins: [...(item.effectivePowerPins || [])],
          effectiveGroundPins: [...(item.effectiveGroundPins || [])],
          impedance: JSON.parse(JSON.stringify(item.impedance || [])),
          portName: item.portName,
          templateIndex: item.index
        }
      });
      pmicPortPins = pmicGroups.map(item => {
        return {
          comp: item.refdes,
          powerPins: [...(item.powerPins || [])],
          groundPins: [...(item.groundPins || [])],
          effectivePowerPins: [...(item.effectivePowerPins || [])],
          effectiveGroundPins: [...(item.effectiveGroundPins || [])],
          portName: item.portName,
          templateIndex: item.index
        }
      })

      if (!soc) {
        continue;
      }

      this.templateGroups.set(key, {
        targetIC: soc,
        pmicList,
        pmicAndNets,
        powerNets,
        groundNets,
        extendNets,
        socPortPins,
        pmicPortPins
      })
    }
  }

  getMergeSocGroups = (option) => {
    const _option = option ? option : this.option;
    switch (_option) {
      case "powerNetAndGroundPinGroup":
        return this.mergeGroupByPowerNetAndGroundPins();
      case "groundPinGroup":
        return this.mergeGroupByGroundPinGroup();
      case "all":
      default:
        return this.mergeGroupsByTargetIC();
    }
  }

  mergeGroupsByTargetIC = () => {
    let groups = [];
    const keys = Array.from(this.templateGroups.keys());
    const targetICList = [...new Set(keys.map(item => { return strDelimited(item, "::", { returnIndex: 1 }) }))];
    for (let targetIC of targetICList) {
      const filterGroups = Array.from(this.templateGroups.values()).filter(item => item.targetIC === targetIC);
      if (!filterGroups.length) {
        continue;
      }
      groups.push({
        targetIC,
        contentList: [...JSON.parse(JSON.stringify(filterGroups))],
        index: groups.length,
      })
    }

    return groups;
  }

  mergeGroupByGroundPinGroup = () => {
    let newGroup = [], existPins = [];

    for (let key of Array.from(this.templateGroups.keys())) {
      const group = this.templateGroups.get(key);
      if (!group) {
        continue;
      }

      for (let portItem of group.socPortPins) {


        if ((portItem.powerPins[0] && portItem.powerPins[0].match(/ALL/ig))
          || (portItem.groundPins[0] && portItem.groundPins[0].match(/ALL/ig))) {

          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }
          newGroup.push({
            targetIC: group.targetIC,
            groundNet: group.groundNets[0] || "",
            socPortPinsGroups: [JSON.parse(JSON.stringify(portItem))],
            contentList: [{
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            }],
            index: newGroup.length,
            all: true,
          });
          existPins.push(JSON.parse(JSON.stringify(portItem)));
          continue;
        }

        const filterSameICGroups = newGroup.filter(item => !item.all && group.groundNets.length && item.groundNet === group.groundNets[0]);

        if (!newGroup.length || !filterSameICGroups.length) {
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }
          newGroup.push({
            targetIC: group.targetIC,
            groundNet: group.groundNets[0] || '',
            socPortPinsGroups: [JSON.parse(JSON.stringify(portItem))],
            contentList: [{
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            }],
            index: newGroup.length,
            all: false
          });
          existPins.push(JSON.parse(JSON.stringify(portItem)))
          continue;
        }

        let flag = false;
        for (let groupItem of filterSameICGroups) {
          const { powerPins, groundPins } = getPortPins(groupItem.socPortPinsGroups);
          const { powerPins: currPowerPins, groundPins: currGroundPins } = getPortPins([portItem]);

          if (currPowerPins.find(item => powerPins.includes(item))
            || currGroundPins.find(item => groundPins.includes(item))) {
            continue;
          }
          const index = newGroup.findIndex(it => it.index === groupItem.index);

          if (index < 0) {
            continue;
          }
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }

          newGroup[index].socPortPinsGroups.push(JSON.parse(JSON.stringify(portItem)));
          const contentIndex = newGroup[index].contentList.findIndex(item => item.powerNets.length && group.powerNets.length && item.powerNets[0] === group.powerNets[0])
          if (contentIndex > -1) {
            newGroup[index].contentList[contentIndex].socPortPins.push(JSON.parse(JSON.stringify(portItem)));
          } else {
            newGroup[index].contentList.push(JSON.parse(JSON.stringify({
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            })));;
          }
          existPins.push(JSON.parse(JSON.stringify(portItem)));
          flag = true;
        }

        if (!flag) {
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }
          newGroup.push({
            targetIC: group.targetIC,
            groundNet: group.groundNets[0] || '',
            socPortPinsGroups: [JSON.parse(JSON.stringify(portItem))],
            contentList: [{
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            }],
            index: newGroup.length,
            all: false
          });
          existPins.push(JSON.parse(JSON.stringify(portItem)));
        }
      }
    }
    return newGroup;
  }

  mergeGroupByPowerNetAndGroundPins = () => {
    let newGroup = [], existPins = [];
    //split group by power net and ground pins
    for (let key of Array.from(this.templateGroups.keys())) {
      const group = this.templateGroups.get(key);
      if (!group) {
        continue;
      }

      if (group.socPortPins.length < 2) {
        if (existPins.find(item => JSON.stringify(item) === JSON.stringify(group.socPortPins[0] || {}))) {
          continue;
        }
        newGroup.push({
          targetIC: group.targetIC,
          socPortPinsGroups: [...JSON.parse(JSON.stringify(group.socPortPins))],
          contentList: [{
            ...JSON.parse(JSON.stringify(group))
          }],
          index: newGroup.length
        });
        existPins.push(...JSON.parse(JSON.stringify(group.socPortPins)));
        continue;
      }

      let subGroups = [];
      for (let portItem of group.socPortPins) {

        if ((portItem.powerPins[0] && portItem.powerPins[0].match(/ALL/ig))
          || (portItem.groundPins[0] && portItem.groundPins[0].match(/ALL/ig))) {
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }
          subGroups.push({
            targetIC: group.targetIC,
            socPortPinsGroups: [JSON.parse(JSON.stringify(portItem))],
            contentList: [{
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            }],
            index: subGroups.length,
            all: true,
          });
          existPins.push(JSON.parse(JSON.stringify(portItem)));
          continue;
        }

        const filterSameICGroups = subGroups.filter(item => !item.all);

        if (!subGroups.length || !filterSameICGroups.length) {
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }
          subGroups.push({
            targetIC: group.targetIC,
            socPortPinsGroups: [JSON.parse(JSON.stringify(portItem))],
            contentList: [{
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            }],
            index: subGroups.length,
            all: false,
          });
          existPins.push(JSON.parse(JSON.stringify(portItem)));
          continue;
        }

        let flag = false;
        for (let groupItem of filterSameICGroups) {
          const { powerPins, groundPins } = getPortPins(groupItem.socPortPinsGroups);
          const { powerPins: currPowerPins, groundPins: currGroundPins } = getPortPins([portItem]);

          if (currPowerPins.find(item => powerPins.includes(item))
            || currGroundPins.find(item => groundPins.includes(item))) {
            continue;
          }
          const index = subGroups.findIndex(it => it.index === groupItem.index);

          if (index < 0) {
            continue;
          }
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }

          subGroups[index].socPortPinsGroups.push(JSON.parse(JSON.stringify(portItem)));
          subGroups[index].contentList[0].socPortPins.push(JSON.parse(JSON.stringify(portItem)));
          existPins.push(JSON.parse(JSON.stringify(portItem)));
          flag = true;
        }

        if (!flag) {
          if (existPins.find(item => JSON.stringify(item) === JSON.stringify(portItem))) {
            continue;
          }
          subGroups.push({
            targetIC: group.targetIC,
            socPortPinsGroups: [JSON.parse(JSON.stringify(portItem))],
            contentList: [{
              ...JSON.parse(JSON.stringify(group)),
              socPortPins: [JSON.parse(JSON.stringify(portItem))]
            }],
            index: subGroups.length,
            all: false
          });
          existPins.push(JSON.parse(JSON.stringify(portItem)));
        }
      }

      newGroup.push(...subGroups)
    }
    return newGroup;
  }
}

/* group template by power / gnd /soc /pmic  */
function groupSignOffTemplate(signOffTemplate, soc, gnd) {
  let signOffGroups = new Map();

  let currSoc = "";
  const loopDcrFind = signOffTemplate.find(temp => temp.loopDcrSpec && temp.loopDcrSpec.unit);
  const loopDcrUnit = loopDcrFind ? loopDcrFind.loopDcrSpec.unit : "mΩ";

  for (let i = 0; i < signOffTemplate.length; i++) {
    const template = signOffTemplate[i];

    let _soc = template.refdes || soc, _gnd = template.gndNetName || gnd;
    const key = `${template.powerNetName}::${_soc}::${_gnd}`;

    if (template.compType === "PMIC") {
      const groupList = signOffGroups.get(currSoc);
      if (currSoc && groupList && groupList.length) {
        groupList.push(template);
        signOffGroups.set(currSoc, groupList);
        template.groupKey = currSoc;
      } else {
        const pmicKey = `${template.powerNetName}::${template.refdes}::${template.gndNetName}::PMIC`;
        signOffGroups.set(pmicKey, [template])
        template.groupKey = pmicKey;
      }
      continue;
    }

    currSoc = `${template.powerNetName}::${_soc}::${_gnd}`;

    const groupList = signOffGroups.get(key);
    if (!groupList || !groupList.length) {
      signOffGroups.set(key, [template]);
      template.groupKey = key;
    } else {
      groupList.push({ ...template });
      signOffGroups.set(key, groupList);
      template.groupKey = key;
    }
    //add loop dcr spec 
    if (!template.loopDcrSpec) {
      template.loopDcrSpec = {
        pmicSpec: "",
        pmicUnit: loopDcrUnit,
        senseSpec: "",
        senseUnit: loopDcrUnit,
        sensePort: false
      }
    }
    signOffTemplate[i] = template;
  }

  return signOffTemplate;
}

let templateGroups = new SignOffGroups();

export default templateGroups;
export {
  SignOffGroups,
  groupSignOffTemplate
}