import CeGeomTools from './CeGeomTools';
import CeGeometry from './CeGeometry';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeVertex = function (type) {
  var geomType = "Pnt";
  if (type != null && type != undefined)
    geomType = type;
  CeGeometry.call(this, geomType);

  this.mX = 0.0;
  this.mY = 0.0;
}

// subclass extends superclass
CeVertex.prototype = Object.create(CeGeometry.prototype);
CeVertex.prototype.constructor = CeVertex;

CeVertex.prototype.Scaling = function (scaleFactor) {
  this.mX *= scaleFactor;
  this.mY *= scaleFactor;
}

CeVertex.prototype.SetData = function (x, y) {
  this.mX = x;
  this.mY = y;
}

CeVertex.prototype.getX = function () {
  return this.mX;
}

CeVertex.prototype.getY = function () {
  return this.mY;
}

CeVertex.prototype.OnSameLocation = function (pnt2) {
  return CeGeomTools.TwoNumberEqual(this.mX, pnt2.mX) &&
    CeGeomTools.TwoNumberEqual(this.mY, pnt2.mY);
}

CeVertex.prototype.CopyFrom = function (fromObj) {
  this.mX = fromObj.mX;
  this.mY = fromObj.mY;
}

CeVertex.prototype.Clone = function () {
  var clnObj = new CeVertex();
  clnObj.SetData(this.mX, this.mY);
  return clnObj;
}

CeVertex.prototype.IsArcPoint = function () {
  return false;
}

CeVertex.prototype.equals = function (pnt2) {
  if (!(pnt2 instanceof CeVertex))
    return false;

  if (CeGeomTools.TwoNumberEqual(this.mX, pnt2.mX) == false ||
    CeGeomTools.TwoNumberEqual(this.mY, pnt2.mY) == false)
    return false;
  return true;
}

CeVertex.prototype.Multiply = function (convertFactor) {
  this.mX *= convertFactor;
  this.mY *= convertFactor;
}

CeVertex.prototype.ToPointDistance = function (toPnt) {
  var dis = (toPnt.mX - this.mX) * (toPnt.mX - this.mX) +
    (toPnt.mY - this.mY) * (toPnt.mY - this.mY);
  if (CeGeomTools.TwoNumberEqual(dis, 0.0))
    return 0.0;
  return Math.sqrt(dis);
}

CeVertex.prototype.WriteIntoIODataNode = function () {
  return new CeIODataItem(this.GetGeomType(), this.GetDataString());
}

CeVertex.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  if (dataVals.size() < 2)
    return false;

  return this.UpdateByString(dataVals);
}

CeVertex.prototype.GetDataCmdString = function () {
  var cmdStr = "(" + this.mX + " " + this.mY + ")";
  return cmdStr;
}

CeVertex.prototype.GetDataString = function () {
  var dataList = new StringList();
  dataList.push(this.mX);
  dataList.push(this.mY);
  return dataList;
}

CeVertex.prototype.UpdateByString = function (dataString) {
  if (dataString.size() >= 2) {
    this.mX = parseFloat(dataString.get(0));
    this.mY = parseFloat(dataString.get(1));
    return true;
  }
  return false;
}

CeVertex.prototype.MoveToLocalCS = function (origin) {
  this.mX -= origin.mX;
  this.mY -= origin.mY;
}

CeVertex.prototype.Transform = function (rotAngle, cw, loc, flipOnX, flipOnY) {
  var radian = rotAngle * Math.PI / 180.0;
  if (cw == true && CeGeomTools.TwoNumberEqual(radian, 0.0) == false)
    radian = 2.0 * Math.PI - radian;

  if (CeGeomTools.TwoNumberEqual(radian, 0.0) == true) {
    if (flipOnX) this.mY = -this.mY;
    if (flipOnY) this.mX = -this.mX;
    this.mX += loc.mX;
    this.mY += loc.mY;
    return;
  }
  var cosAngle = Math.cos(radian);
  var sinAngle = Math.sin(radian);
  var x = this.mX;
  var y = this.mY;
  // this.mX = cosAngle*x - sinAngle*y + loc.mX;
  // this.mY = sinAngle*x + cosAngle*y + loc.mY;

  //flip first and then Rotate
  if (flipOnX) y = -y;
  if (flipOnY) x = -x;
  this.mX = cosAngle * x - sinAngle * y;
  this.mY = sinAngle * x + cosAngle * y;
  this.mX += loc.mX;
  this.mY += loc.mY;
}

export default CeVertex;