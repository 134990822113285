class ProjectEndToEndChannels {
  constructor() {
    this.projects = new Map(); // key - projectId, value - end to end channels

    this.set = this.set.bind(this);
    this.getEndToEndChannelsByProjectId = this.getEndToEndChannelsByProjectId.bind(this);
    this.delEndToEndChannel = this.delEndToEndChannel.bind(this);
    this.delete = this.delete.bind(this);
    this.clearCache = this.clearCache.bind(this);
  };

  set(projectId, endToEndChannels) {
    this.projects.set(projectId, endToEndChannels);
  };

  getEndToEndChannelsByProjectId(projectId) {
    return this.projects.get(projectId);
  };

  delEndToEndChannel(projectId, endToEndChannelId) {
    let endToEndChannelIds = this.projects.get(projectId);
    endToEndChannelIds = endToEndChannelIds.filter(item => item !== endToEndChannelId);
    this.projects.set(projectId, endToEndChannelIds);
  }

  delete(projectId) {
    this.projects.delete(projectId);
  }

  clearCache() {
    this.projects = new Map();
  }
};

const projectEndToEndConstructor = new ProjectEndToEndChannels();
export default projectEndToEndConstructor;