import CeLocation from '../geometry/CeLocation';
import StringList from '../utility/StringList';

var CeNetVia = function () {
  this.mLocation = new CeLocation();
  this.mLocation.SetClockwire(true);
};

CeNetVia.prototype.SetData = function (viaID, location) {
  this.mViaID = viaID;
  this.mLocation = location;
};

CeNetVia.prototype.GetDataStrings = function () {
  var vals = new StringList();
  vals.add(this.mViaID.toString());
  vals.add(this.mLocation.GetDataStrings());
  return vals;
}

CeNetVia.prototype.UpdateByString = function (dataString) {
  if (dataString.size() < 8)
    return false;

  this.mViaID = parseInt(dataString.get(0), 10);
  var locData = dataString.SubStringList(1, dataString.size());
  this.mLocation.UpdateByString(locData);
  return true;
}

export default CeNetVia;