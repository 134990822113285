import React, { Component } from 'react';
import { Tooltip } from 'antd';
import { PCB_LEFT_RIGHT, PCB_ONLY, PCB_TOP_BOTTOM } from '../../constants/layoutConstants';
import '@/publicCss/tree.css';
import './index.css';

class SplitPCBWindow extends Component {

  pcbLayoutChange = (e, type) => {
    e && e.stopPropagation();
    this.props.changePCBLayout(type);
  }

  render = () => {
    const { pcbLayout } = this.props;
    const placement = 'left';
    return <div className='pcb-split-icon-box'>
      {/* pcb icon */}
      <div>
        <Tooltip title='Single PCB' overlayClassName='aurora-tooltip' placement={placement}>
          <div
            className={`aurora-layout-button-3 ${pcbLayout === PCB_ONLY && 'aurora-layout-selected'}`}
            onClick={(e) => this.pcbLayoutChange(e, PCB_ONLY)}
          >
            1
          </div>
        </Tooltip>
      </div>
      {/* layout left and right icon */}
      <div>
        <Tooltip title='Split Screen' overlayClassName='aurora-tooltip' placement={placement}>
          <div
            className={`aurora-layout-button-1 ${pcbLayout === PCB_LEFT_RIGHT && 'aurora-layout-selected'}`}
            onClick={(e) => this.pcbLayoutChange(e, PCB_LEFT_RIGHT)}
          >
            <div className='aurora-layout-button-left'>1</div>
            <div className='aurora-layout-button-right'>2</div>
          </div>
        </Tooltip>
      </div>
      {/* layout top and bottom icon */}
      <div>
        <Tooltip title='Split Screen' overlayClassName='aurora-tooltip' placement={placement}>
          <div
            className={`aurora-layout-button-2 ${pcbLayout === PCB_TOP_BOTTOM && 'aurora-layout-selected'}`}
            onClick={(e) => this.pcbLayoutChange(e, PCB_TOP_BOTTOM)}
          >
            <div className='aurora-layout-button-top'>1</div>
            <div className='aurora-layout-button-bottom'>2</div>
          </div>
        </Tooltip>
      </div>
    </div>
  }
}
export default SplitPCBWindow;