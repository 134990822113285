import getProfileInfo from '../api/profile';
import apiProcess from '../api/utility';

export default function getProfileData(verificationId) {
  return apiProcess(getProfileInfo, verificationId);
}

export function secondFormat(time) {
  function format(num) {
    return num < 10 ? `0${num}` : `${num}`;
  }
  let str = ''
  const h = parseInt(time / 3600);
  str = format(h) + ':';
  const m = parseInt((time - h * 3600) / 60);
  str += format(m) + ':';
  const s = time - h * 3600 - m * 60;
  str += format(s);
  return str;
}

export function memoryFormat(memory) {
  const _memory = Number(memory);
  let mem = '';
  if (_memory < 1024) {
    return _memory + ' K';
  }
  const toM = _memory / 1024;
  mem = parseInt(toM) + ' M';
  if (toM > 1024) {
    mem = parseFloat(toM / 1024).toFixed(2) + ' G';
  }
  return mem;
}

export function parseProfile(profileLog) {
  profileLog.forEach(d => {
    if (!d.task) {
      d.task = '\xa0';
    } else {
      d.task = d.task.replace(' ', '\xa0');
    }
    if (d.memory !== undefined) {
      d.memory = memoryFormat(d.memory);
    }
    if (d.realTime !== undefined) {
      d.realTime = secondFormat(d.realTime);
    }
    if (d.cpuTime !== undefined) {
      d.cpuTime = secondFormat(d.cpuTime);
    }
  })
  return profileLog;
}

export function shouldGetProfile(taskList) {
  if (taskList && Array.isArray(taskList)) {
    return taskList.find(item => item.worker === 'Ansys');
  }
  return false;
}