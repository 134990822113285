export const
  TRACE = "trace",
  VIA = "via",
  MICROSTRIP = "microstrip",
  STRIPLINE = "stripline",
  TRACE_VERSION = "0.0.1",
  VIA_VERSION = "0.0.1",
  SINGLE = 'single-ended',
  DIFF = 'differential',
  traceTypes = [{
    title: 'Microstrip', key: MICROSTRIP
  }, {
    title: 'Stripline', key: STRIPLINE
  }],

  signalTypes = [{
    title: 'Single-ended',
    key: SINGLE
  }, {
    title: 'Differential',
    key: DIFF
  }],

  BUFFER = 'buffer',
  VRM = 'VRM',
  PACKAGE = "package",
  LIBRARY_PACKAGE = "library_package",
  LIB_PKG = 'library_package',
  PASSIVE = 'passive',
  PKG_TOUCHSTONE = 'pkg_touchstone',
  PKG_SPICE = 'pkg_spice',
  PASSIVE_TOUCHSTONE = 'passive_touchstone',
  PASSIVE_SPICE = 'passive_spice',
  SPICE = 'spice',
  VECTOR = "vector",
  IBIS = "ibis",
  REPEATER = "repeater",
  CONNECTOR = "connector",
  CONNECTOR_TOUCHSTONE = "connector_touchstone",
  CONNECTOR_SPICE_SIERRA = "connector",
  CABLE_TOUCHSTONE = "cable_touchstone",
  PCB_TOUCHSTONE = "pcb_touchstone",
  PCB_SPICE = "pcb_spice",
  CUSTOM = "custom",
  CUSTOM_TOUCHSTONE = "custom_touchstone",
  CUSTOM_SPICE = "custom_spice",
  LIBRARY_SIERRA = [
    IBIS,
    SPICE,
    CONNECTOR,
    REPEATER,
    VECTOR,
    PASSIVE_TOUCHSTONE, PASSIVE_SPICE,
    PKG_TOUCHSTONE, PKG_SPICE,
    CONNECTOR_TOUCHSTONE,
    PCB_SPICE, PCB_TOUCHSTONE,
    CUSTOM_TOUCHSTONE,
    CUSTOM_SPICE,
    TRACE
  ],
  TOUCHSTONE = "Touchstone",
  EBD = 'ebd',
  DECAP = "decap",
  DECAP_TOUCHSTONE = "decap_touchstone",
  DECAP_SPICE = "decap_spice",
  DECAP_RLC = "decap_rlc",
  PKG_AC = "pkg_ac",
  PKG_DC = "pkg_dc",
  TOUCHSTONE_SPICE = "Touchstone / SPICE",
  IBIS_AMI = "ibis_ami",
  DECAP_MODEL_MAP = "decap_model_map",
  PIN_MAP = "pin_map",
  PIN_CONNECTION = "res_pin_map",
  PMIC_PART = 'PMIC_part',
  POWER_SWITCH_PART = 'power_switch_part',
  DRIVER = "driver",
  SOCKET = "socket",
  SOCKET_TOUCHSTONE = "socket_touchstone",
  SOCKET_SPICE = "socket_spice",
  CARD_LIBRARY = "card_library",
  SPICE_PARAMETERS = "spice_parameters",
  SOC_BUFFER = "soc_buffer",
  SOC_SPICE = "soc_spice",
  SOC_WRAPPER = "soc_wrapper",
  PCB_SUBCKT = "pcb_subckt",
  PVT = "pvt",
  VDE_NUMBER = "vdeNum",
  SPICE_PARAMETERS_LIB = "spice_parameters_lib",
  CUSTOM_COMPONENT = "custom_component",
  WIRE_BOND_XML = "wire_bond_xml",
  CPM_SPICE = 'cpm_spice',
  EXTRACTION_OPTIONS = "extraction_options",
  HFSS_OPTIONS = "hfss_options",
  SIWAVE_OPTIONS = "siwave_options",
  POWERSI_OPTIONS = "powersi_options",
  POWERDC_OPTIONS = "powerdc_options",
  ADS_OPTIONS = "ads_options",
  EYE_MASK = "eye_mask",
  PART_LIBRARY = "part_library",
  PART_IBIS_LIBRARY = "part_IBIS",
  PART_REPEATER_LIBRARY = "part_repeater",
  LIBRARY_PCB_MODEL = 'pcb_model',
  ON_DIE_MODEL = 'on_die_model',
  PCB_PDN_MODEL = 'pcb_pdn_model',
  ON_DIE_TOUCHSTONE = "on_die_touchstone",
  ON_DIE_SPICE = "on_die_spice",
  PCB_PDN_TOUCHSTONE = "pcb_pdn_touchstone",
  PCB_PDN_SPICE = "pcb_pdn_spice",
  GENERIC_TOUCHSTONE = "generic_touchstone",
  GENERIC_LIBRARY = "generic",
  LEQ_RESPONSE = "leq_response",
  PATTERN_LIBRARY = "pattern",
  USER_DEFINED_NETLIST = "user_defined_netlist",
  CUSTOM_POST_PROCESS = "custom_post_process",
  NETS_FILTER_LIBRARY = "nets_filter",
  BUFFER_SPICE = "buffer_spice",
  CPHY_EYE_MASK = "cphy_eye_mask";

/* STACKUP */
export const MATERIAL = "material";