import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { Progress, Row, Col, Tooltip } from 'antd';
import { cancelUploadWorkflow } from '../../SierraSider/Upload/store/action';
import { REPLACE_PCB } from '../../../../constants/treeConstants';
import './style.css';

class UploadMonitor extends Component {

  constructor(props) {
    super(props);
    this.scrollRef = createRef();
  }


  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }

  cancelWorkflow = (e) => {
    e.stopPropagation();
    //cancel upload workflow
    const { uploadWorkflowId } = this.props;
    if (uploadWorkflowId) {
      this.props.cancelUploadWorkflow(uploadWorkflowId);
    }
  }

  render() {
    const { visible, msg, uploadProgress, transProgress, monitor,
      endMsg, projectName,
      uploadProjectId, currentProjectId, compressProgress, currentPCBKey } = this.props;
    const progressStyle = monitor ? 0 : 20;

    return (
      <Fragment>
        <Row className='my-sierra-monitor-holygrail' span={visible ? 18 : 4}>
          <Col className='sierra-simulation-msg'
            span={7}>
            {projectName && <div className='sierra-simulation-span sierra-simulation-project'>
              <span className='font-bold span-msg-project'>Project </span>
              <span className='span-msg'>{projectName}</span>
            </div>}
          </Col>
          <Col className='my-sierra-middle' span={12} style={{ marginLeft: progressStyle }}>
            {uploadProjectId === currentProjectId && visible && <div className='my-sierra-monitor-progress'>
              {currentPCBKey === REPLACE_PCB && compressProgress > -1 && compressProgress <= 100 && uploadProgress <= 0 ?
                <Progress
                  size={{ height: 12 }}
                  strokeColor={'#f5850d'}
                  percent={compressProgress}
                  format={(percent) => `Compressing: ${percent}%`}
                  className="monitor-upload-progress-bar"
                />
                :
                <Progress
                  size={{ height: 12 }}
                  strokeColor={'#1890ff'}
                  percent={uploadProgress}
                  success={{ percent: transProgress }}
                  format={(percent, successPercent) => `Uploading: ${percent}% / Compiling: ${successPercent}%`}
                  className="monitor-upload-progress-bar"
                />}
            </div>}
          </Col>
          <div className='sierra-simulation-btn'>
            {uploadProjectId === currentProjectId && transProgress > -1 &&
              <div className='sierra-cancel-button'>
                <Tooltip title='Cancel upload PCB' overlayClassName='icon-tooltip'>
                  <span className='iconfont icon-cancel9' onClick={(e) => this.cancelWorkflow(e)}></span>
                </Tooltip>
              </div>}
          </div>
        </Row>
        <div className='my-sierra-monitor'>
          <div className='my-sierra-monitor-log'>
            {uploadProjectId === currentProjectId && (monitor || msg || endMsg) && <pre style={{ margin: 20, overflow: 'unset' }}>
              {msg}
              <br />
              {<ul style={{ paddingLeft: 34, margin: 0 }}>
                {monitor && monitor.length > 0 && monitor.map(item =>
                  <li key={item.id} style={{ listStyle: 'none', width: '100%' }}>
                    <p style={{ margin: 0 }}>{item.time}  {item.log}</p>
                  </li>)}
              </ul>}
              {endMsg}
            </pre>}
            <div ref={(el) => { this.scrollRef = el; }}></div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { project: { currentProjectId } } = state.SierraReducer;
  const { visible, uploadProgress, transProgress, msg, monitor, uploadProjectId,
    endMsg, uploadDesignId, uploadWorkflowId, compressProgress, currentPCBKey } = state.SierraReducer.SierraUploadReducer;
  /*   const { tabVisible, projectName } = state.SierraReducer.TabMonitorReducer; */
  const { projectName, tabVisible } = state.TabMonitorReducer;
  return {
    visible,
    msg,
    uploadProgress,
    transProgress,
    compressProgress,
    monitor,
    uploadProjectId,
    endMsg,
    uploadDesignId,
    tabVisible,
    projectName,
    currentProjectId,
    uploadWorkflowId,
    currentPCBKey
  }
}

const mapDispatch = (dispatch) => ({
  cancelUploadWorkflow(workflowId) {
    dispatch(cancelUploadWorkflow(workflowId));
  }
})

export default connect(mapState, mapDispatch)(UploadMonitor);