import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ExtractionOptionsContent from '../Content/PinToPinSetting/extractionOptionsContent';
import {
  updateMultiSetupExtraction,
  updateMultiSetupExtractionPorts,
  saveMultiSetupExtraction,
  updateMultiSetupExtractionStatus
} from '../store/multiInterface/action';
import { judgeUpdateGapPortSetup, updateExtractionGapPortsSetup } from '../../../services/Sierra/helper/extractionPortsHelper';
import { judgeUpdateGapPortGapSizeSetup, updateHFSSExtractionCompsGapSize } from '../../../services/ExtractionPortsHelper';
import { saveReExtraction } from '../store/sierra/action';
import _ from "lodash";

class ExtractionOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ifDoExtraction: 0,
      channelType: "Default",
      portImpedance: "50",
      update: false,
      prevExtraction: null
    }
    this.dialogRoot = document.getElementById('root');
  };

  componentDidMount() {
    this.getExtractionValue()
  }

  componentDidUpdate(prevProps) {
    const { isUpdateExtraction, optionsStatus } = this.props;

    if (/* prevProps.current !== current
      || */ (isUpdateExtraction && prevProps.isUpdateExtraction !== isUpdateExtraction)) {
      this.getExtractionValue()
      this.props._updateMultiSetupExtractionStatus(false)
      this.props.closeLoading();
    }

    if (!optionsStatus && prevProps.optionsStatus && prevProps.optionsStatus !== optionsStatus) {
      this.props.closeModal();
    }
  }

  getExtractionValue = () => {
    const { extractionInfo, portsInfo, current } = this.props;

    const { channel, ifDoExtraction, extraction } = extractionInfo[current] || {};
    const { port_setups = [] } = portsInfo[current] || {};
    //extraction
    const channelType = channel && channel.type ? channel.type : 'Default';
    const portImpedance = port_setups && port_setups.length ? port_setups[0].z0 : "50";

    this.setState({
      ifDoExtraction: ifDoExtraction ? 1 : 0,
      channelType,
      portImpedance,
      update: true,
      prevExtraction: JSON.parse(JSON.stringify(extraction || {}))
    })
  }

  updateStatus = () => {
    this.setState({
      update: false
    })
  }

  updateReExtraction = ({ ifDoExtraction }) => {
    const { interfaceId } = this.props;
    this.setState({
      ifDoExtraction
    })
    this.props.saveReExtraction(interfaceId, ifDoExtraction);
  }

  editChannelType = (info) => {
    const { channel } = info;
    const { channelType: prevChannelType } = this.state;
    const { portsInfo, current, extractionInfo } = this.props;
    let _portsInfo = { ...portsInfo }, _extractionInfo = { ...extractionInfo };
    if (!_extractionInfo[current]) {
      _extractionInfo[current] = {}
    }
    _extractionInfo[current].channel = {
      type: channel,
      fileName: '',
      modelName: '',
      libraryId: '',
    }
    if (!_portsInfo[current]) {
      _portsInfo[current] = {}
    }
    _portsInfo[current].channel = { ..._extractionInfo.channel }

    if (judgeUpdateGapPortSetup(_portsInfo[current])) {
      const data = updateExtractionGapPortsSetup(_portsInfo[current], current);
      _portsInfo[current] = data.newData;
    }

    if (judgeUpdateGapPortGapSizeSetup({
      components: _portsInfo[current].components,
      extractionType: channel,
      prevExtractionType: prevChannelType,
      ports_generate_setup_list: _portsInfo[current].ports_generate_setup_list
    })) {
      _portsInfo[current].components = updateHFSSExtractionCompsGapSize(_portsInfo[current].components, _portsInfo[current].ports_generate_setup_list)
    }
    this.setState({
      channelType: channel
    })
    this.props._updateMultiSetupExtraction({ [current]: _extractionInfo[current] });
    this.props._updateMultiSetupExtractionPorts({ [current]: _portsInfo[current] });

  }

  saveExtraction = (extraction, pcb, channel, apply, isClose, { xTalk, ifDoExtraction, update }) => {
    let { verificationInfo = {}, current } = this.props;
    const { channelType, prevExtraction } = this.state;
    const prevChannelType = channelType;
    this.setState({
      channelType: channel
    })
    const { applyIds, applyType } = this.props.getApplyVIds();
    if (!update && !_.isEqual(prevExtraction || {}, extraction)) {
      update = true;
    }

    if (!update) {
      isClose && this.props.closeModal();
      !isClose && this.props.updateCurrent();
      return;
    }
    this.props._saveMultiSetupExtraction({
      extraction,
      pcb: current,
      channel,
      apply,
      prevChannelType,
      verificationInfo,
      isClose,
      xTalk,
      applyIds,
      ifDoExtraction,
      applyType,
      update
    });
  }

  _updateOptions = (type, value, update) => {
    //update xTalk
    const { extractionInfo, verificationId } = this.props;
    if (!verificationId || !extractionInfo.currentConfig) {
      return;
    }
    let _extractionInfo = { ...extractionInfo };
    let config = _extractionInfo.currentConfig ? _extractionInfo.currentConfig.config || {} : {};
    config.xTalk = value !== null ? value : (config.xTalk !== null ? config.xTalk : 1);
    this.props._updateMultiSetupExtraction({ currentConfig: { ...(_extractionInfo.currentConfig || {}), config } });
  }

  render() {
    const { current, interfaceId, extractionInfo, apply } = this.props;
    const { ifDoExtraction, channelType, portImpedance, update } = this.state;
    const _extractionInfo = extractionInfo[current] || {};
    return <ExtractionOptionsContent
      onRef={this.props.onRef}
      isMultiSetup={true}
      ifDoExtraction={ifDoExtraction}
      interfaceId={interfaceId}
      extraction={_extractionInfo.extraction || {}}
      pcbId={current}
      current={current}
      currentConfig={extractionInfo.currentConfig ? extractionInfo.currentConfig.config || {} : {}}
      update={update}
      channelType={channelType}
      portImpedance={portImpedance}
      apply={apply}
      editChannelType={this.editChannelType}
      saveExtraction={this.saveExtraction}
      _updateOptions={this._updateOptions}
      updateReExtraction={this.updateReExtraction}
      updateStatus={this.updateStatus}
    />
  }
}

const mapState = (state) => {
  const { SierraReducer: { multiInterfaceSetup: { extractionInfo, portsInfo, isUpdateExtraction, optionsStatus } } } = state;
  return {
    extractionInfo,
    portsInfo,
    isUpdateExtraction,
    optionsStatus
  };
}

const mapDispatch = (dispatch) => ({
  _updateMultiSetupExtraction(extractionInfo) {
    dispatch(updateMultiSetupExtraction(extractionInfo))
  },
  _updateMultiSetupExtractionPorts(portsInfo) {
    dispatch(updateMultiSetupExtractionPorts(portsInfo))
  },
  saveReExtraction(interfaceId, ifDoExtraction) {
    dispatch(saveReExtraction(interfaceId, ifDoExtraction))
  },
  _saveMultiSetupExtraction(info) {
    dispatch(saveMultiSetupExtraction(info))
  },
  _updateMultiSetupExtractionStatus(status) {
    dispatch(updateMultiSetupExtractionStatus(status))
  }
})

export default connect(mapState, mapDispatch)(ExtractionOptions);