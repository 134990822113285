import React from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { getDCValue, updateDCSelectedKey } from '../../store/result/action';

class DCMenu extends React.Component {
  changeSelectedKey = (e, resultId) => {
    const { key } = e
    this.props._updateDCSelectedKey(key)
  }

  componentDidMount() {
    const { verificationId } = this.props;
    this.props._getDCValue(verificationId, true)
  }

  render() {
    const { DCMenuList = [], DCSelectedKey = [] } = this.props;
    return (
      <div className="PDN-dc-Result-Menu">
        <Menu mode="horizontal" selectedKeys={[DCSelectedKey]} items={DCMenuList ? DCMenuList.map(item => ({ key: item.id, label: item.key, onClick: this.changeSelectedKey })) : []} />
      </div>
    );
  }
}

const mapState = (state) => {
  const { resultReducer: { resultKey, DCMenuList, DCSelectedKey }, project: { verificationId } } = state.PDNReducer
  return {
    verificationId,
    resultKey,
    DCMenuList,
    DCSelectedKey
  }
}

const mapDispatch = (dispatch) => ({
  _getDCValue(verificationId, showFile) {
    dispatch(getDCValue(verificationId, showFile))
  },
  _updateDCSelectedKey(key) {
    dispatch(updateDCSelectedKey(key))
  }
})

export default connect(mapState, mapDispatch)(DCMenu);