import React, { Component } from 'react';
import PortCanvas from './portCanvas';
import PortTables from './portTables';
import LayoutData from '@/services/data/LayoutData';
import { getPortData, autoSplitRefeByPower, getDiePortsByPloc } from '../..//services/helper/portCanvasHelper';
import { Spin } from 'antd';
import _ from 'lodash';
import auroraDBJson from '../../services/Designs/auroraDbData';
import './index.css';

class PortSetup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DesignData: null,
      layerList: [],
      powerPins: {},
      referencePins: {},
      designUpdate: false,
      tableData: [],
      descData: [],
      show: [],
      showPinList: [],
      maxPinLen: 1,
      refeGroup: [],
      updatePinSelected: false,
      gridColumn: 1,
      gridRow: 1,
      border: 'power',
      size: 100,
      widthSize: 100,
      heightSize: 100,
      range: 200,
      loading: true
    }
    this.tableRef = null;
  }

  componentDidMount() {
    // document.addEventListener('contextmenu', this.getContextMenu)
    this.renderDesign();
  }

  componentDidUpdate(prevProps) {
    const { designId, PowerNets, ReferenceNets, chip } = this.props
    if (designId !== prevProps.designId
      || PowerNets.length !== prevProps.PowerNets.length
      || ReferenceNets.length !== prevProps.ReferenceNets.length
      || chip !== prevProps.chip
    ) {
      this.setState({
        gridColumn: 1,
        gridRow: 1,
        border: 'power',
        size: 100,
        widthSize: 100,
        heightSize: 100,
        range: 200,
        loading: true
      }, () => {
        this.renderDesign();
      })
    }
  }

  updateCurrentState = (key, value, afterFn) => {
    this.setState({
      [key]: value
    }, () => {
      afterFn && afterFn()
    })
  }

  async renderDesign() {
    const { designId, PowerNets, ReferenceNets, chip } = this.props;
    const compInfo = auroraDBJson.getComponent(designId, chip);
    try {
      if (compInfo && compInfo.layer) {
        const layer = compInfo.layer.replace('COMP_', '');
        await LayoutData.LoadLayoutDB(designId, [layer]);
      } else {
        await LayoutData.LoadLayoutDB(designId, true);
      }
    } catch(e) {
      console.error(e);
    }
    let DesignData = LayoutData.getLayout(designId);
    let filterData = getPortData(DesignData, chip, PowerNets, ReferenceNets);
    if (!filterData || !filterData.layerList || !filterData.layerList.length) {
      await LayoutData.LoadLayoutDB(designId, false);
      DesignData = LayoutData.getLayout(designId);
      filterData = getPortData(DesignData, chip, PowerNets, ReferenceNets);
    }
    const { layerList, powerPins, referencePins, data, showPinList } = filterData;
    this.setState({
      DesignData: data,
      designUpdate: true,
      layerList,
      powerPins,
      referencePins,
      showPinList
    }, () => {
      this.getDescData()
    })
  }

  getDescData = () => {
    const { ports } = this.props;
    const { powerPins, referencePins } = this.state;
    let power = [], refe = [], maxPinLen = 1;
    const _ports = !ports.length ? this.pushAllPinsToOneGroup() : ports
    let pins = [powerPins, referencePins];
    pins.forEach((pinObj, index) => {
      for (let key in pinObj) {
        const arr = pinObj[key];
        let maxLen = Math.max(...arr.map(pin => pin.length));
        maxPinLen = maxPinLen > maxLen ? maxPinLen : maxLen;
        if (index === 0) {
          power.push(...arr)
        } else {
          refe.push(...arr)
        }
      }
    })
    const usedPower = _ports.map(item => item.powerPins).flat(2);
    const usedRefe = _ports.map(item => item.referencePins).flat(2);
    let desc = [
      {
        title: 'Power Pins',
        netType: 'PowerNet',
        used: [...new Set([...usedPower])],
        unused: power.filter(item => !usedPower.includes(item))
      }, {
        title: 'Reference Pins',
        netType: 'ReferenceNet',
        used: [...new Set([...usedRefe])],
        unused: refe.filter(item => !usedRefe.includes(item))
      }
    ]
    let refeGroup = [];
    _ports.forEach(item => {
      const { referencePins } = item
      const find = refeGroup.find(group => group.length && group.every(g => referencePins.includes(g)));
      if (!find && referencePins.length) {
        refeGroup.push(item.referencePins)
      }
    })
    this.setState({
      descData: desc,
      maxPinLen,
      tableData: JSON.parse(JSON.stringify(_ports)),
      refeGroup,
      updatePinSelected: true,
      loading: false
    })
  }

  pushAllPinsToOneGroup = () => {
    const { powerPins, referencePins } = this.state;
    const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
    const reference = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
    return [{
      powerPins: power,
      referencePins: reference,
      port: "1",
      current: ""
    }]
  }

  updatePinUse = ({ table, type, save = false, afterSaveFn, DCLoad }) => {
    const { descData } = this.state;
    const desc = [...descData];
    if (type === 'allPins') {
      const { powerPins, referencePins } = this.state;
      const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2)
      const refe = Object.keys(referencePins).map(key => referencePins[key]).flat(2)
      for (let i = 0; i < 2; i++) {
        if (DCLoad) {
          desc[i].used = i === 0 ? power : refe;
          desc[i].unused = [];
        } else {
          desc[i].used = [];
          desc[i].unused = i === 0 ? power : refe;
        }
      }
    } else {
      const pinObj = this.state[type];
      const pins = Object.keys(pinObj).map(key => pinObj[key]).flat(2);
      const used = table.map(item => item[type]).flat(2);
      const index = type === 'powerPins' ? 0 : 1;
      desc[index].used = [...new Set([...used])];
      desc[index].unused = pins.filter(item => !used.includes(item));
    }
    this.setState({
      descData: desc,
      tableData: JSON.parse(JSON.stringify(table)),
      updatePinSelected: true
    }, () => {
      save && this.saveTable();
      afterSaveFn && afterSaveFn()
    })
  }

  saveTable = () => {
    const { tableData, powerPins, referencePins } = this.state;
    const { powerDomainId, DCLoad, signOffTemplate } = this.props;
    const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
    const reference = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
    let newTableData = JSON.parse(JSON.stringify(tableData))
    if (!signOffTemplate
      && tableData.length === 1
      && _.isEqual(tableData[0].powerPins.sort(), power.sort())
      && _.isEqual(tableData[0].referencePins.sort(), reference.sort())
      && (DCLoad ? (!tableData[0].current) : !(tableData[0].target && tableData[0].target.length !== 0))) {
      newTableData = [];
    }
    this.props.savePorts(powerDomainId, newTableData);
  }

  deleteTableRow = (port) => {
    const { tableData, refeGroup } = this.state;
    const { powerDomainId, multiPCB } = this.props;
    const currentRow = tableData.find(item => item.port === port);
    if (!currentRow) return;

    const newTable = [...tableData].filter(item => item.port !== port);
    this.updatePinUse({ table: newTable, type: 'powerPins', save: false });
    this.updatePinUse({ table: newTable, type: 'referencePins', save: false });
    let _refeGroup = refeGroup;
    if (currentRow.referencePins.length) {
      const refeFirst = currentRow.referencePins[0];
      let exist = false;
      newTable.forEach(item => {
        if (item.referencePins.includes(refeFirst)) {
          exist = true;
        }
      })
      if (!exist) {
        const findIndex = _refeGroup.findIndex(item => item.includes(refeFirst));
        _refeGroup.splice(findIndex, 1);
      }
    }

    this.setState({
      tableData: JSON.parse(JSON.stringify(newTable)),
      refeGroup: _refeGroup,
      updatePinSelected: true
    }, () => {
      this.props.savePorts(powerDomainId, newTable);
    })
  }

  selectTags = (pin, netType, toShow = false) => {
    const { show } = this.state;
    if (!toShow && show.length && show.every(item => pin.includes(item))) {
      this.setState({
        show: show.filter(item => !pin.includes(item))
      }, () => {
        this.canvasRef.selectPins([...pin], false, netType)
      })
    } else {
      this.setState({
        show: [...pin]
      }, () => {
        this.canvasRef.selectPins([...pin], true, netType)
      })
    }
  }

  showGroupPins = (portShowList) => {
    this.canvasRef.drawGroup(portShowList);
  }

  autoFindReference = () => {
    const { tableData, descData, refeGroup } = this.state;
    const newTable = [...tableData];
    const idleRefe = descData[1].unused;
    let refePinsInfo = this.canvasRef.getPinsInfo(idleRefe);
    let powerPinsInfo = newTable.filter(row => !row.referencePins.length)
      .map(row => this.canvasRef.getPinsInfo(row.powerPins)).flat(2);
    const powerInfoWithRefe = autoSplitRefeByPower(powerPinsInfo, refePinsInfo, refeGroup)
    for (let row of newTable) {
      const { powerPins, referencePins } = row;
      if (referencePins.length) {
        continue;
      }
      let findRefe = [];
      powerPins.forEach(pin => {
        const find = powerInfoWithRefe.find(item => item.pinNumber === pin);
        if (find && find.refePins) {
          findRefe.push(...find.refePins)
        }
      })
      row.referencePins.push(...findRefe);
    }

    const _refeGroup = newTable.map(item => item.referencePins);
    this.setState({
      refeGroup: _refeGroup
    }, () => {
      this.updatePinUse({ table: newTable, type: 'referencePins', save: true, DCLoad: this.props.DCLoad });
    })
  }

  createGridGroup = () => {
    this.updatePinUse({ table: [], type: 'allPins', save: true, DCLoad: false });
    const { descData } = this.state;
    const powerPinsInfo = this.canvasRef.getPinsInfo(descData[0].unused);
    const refePinsInfo = this.canvasRef.getPinsInfo(descData[1].unused);
    const lines = this.canvasRef.getCurrentLines()
    const points = this.canvasRef.getCurrentPoints()
    if (points && points.topLeft && points.bottomRight) {
      const newTable = []
      const { topLeft, bottomRight } = points;
      const xCoords = new Set([topLeft.x, bottomRight.x]);
      const yCoords = new Set([topLeft.y, bottomRight.y]);
      if (lines && lines.verticalLines && lines.horizontalLines) {
        const { verticalLines, horizontalLines } = lines
        verticalLines.forEach(line => {
          xCoords.add(line.x1);
          yCoords.add(line.y1);
          yCoords.add(line.y2);
        });
        horizontalLines.forEach(line => {
          xCoords.add(line.x1);
          xCoords.add(line.x2);
          yCoords.add(line.y1);
        });
      }
      const sortedXCoords = Array.from(xCoords).sort((a, b) => a - b);
      const sortedYCoords = Array.from(yCoords).sort((a, b) => b - a);

      for (let i = 0; i < sortedXCoords.length - 1; i++) {
        for (let j = 0; j < sortedYCoords.length - 1; j++) {
          const cellTopLeftX = sortedXCoords[i]
          const cellTopLeftY = sortedYCoords[j]
          const cellBottomRightX = sortedXCoords[i + 1]
          const cellBottomRightY = sortedYCoords[j + 1];
          const newPowerpins = powerPinsInfo.filter(pin => {
            const location = this.canvasRef.getPinLocation(pin);
            return cellTopLeftX <= location.x && location.x < cellBottomRightX &&
              cellTopLeftY >= location.y && location.y > cellBottomRightY
          })
          if (newPowerpins && newPowerpins.length) {
            const newRefePins = refePinsInfo.filter(pin => {
              const location = this.canvasRef.getPinLocation(pin);
              return cellTopLeftX <= location.x && location.x < cellBottomRightX &&
                cellTopLeftY >= location.y && location.y > cellBottomRightY
            })
            const groups = newTable.map(item => Number(item.port));
            let port = newTable.length + 1;
            while (groups.includes(port)) {
              port = port + 1;
            }
            newTable.push({ port, powerPins: newPowerpins.map(info => info.pinNumber), referencePins: newRefePins.map(info => info.pinNumber) })
          }
        }
      }
      const _refeGroup = newTable.map(item => item.referencePins);
      this.setState({
        tableData: [...newTable],
        refeGroup: _refeGroup
      }, () => {
        this.updatePinUse({ table: newTable, type: 'powerPins', save: true, DCLoad: this.props.DCLoad })
        this.updatePinUse({ table: newTable, type: 'referencePins', save: true, DCLoad: this.props.DCLoad })
        this.saveTable();
      })
    }
  }

  savePlocPorts = (cpmPairs) => {
    const { designId, chip, PowerNets, ReferenceNets } = this.props;
    const cpmPins = cpmPairs.filter(item => [...PowerNets, ...ReferenceNets].includes(item.net));
    const newPorts = getDiePortsByPloc({
      packageId: designId,
      component: chip,
      PowerNets,
      ReferenceNets,
      cpmPins
    })
    if (newPorts.length) {
      const _refeGroup = newPorts.map(item => item.referencePins);
      this.setState({
        tableData: [...newPorts],
        refeGroup: _refeGroup
      }, () => {
        this.updatePinUse({ table: newPorts, type: 'powerPins', save: true, DCLoad: this.props.DCLoad })
        this.updatePinUse({ table: newPorts, type: 'referencePins', save: true, DCLoad: this.props.DCLoad })
      })
    }
  }

  savePortSetupPin = (pinInfo, saveInfo) => {
    const { pinNumber } = pinInfo;
    const { pinType, type, port } = saveInfo;
    const { tableData } = this.state;
    if (!port) {
      const groups = tableData.map(item => Number(item.port));
      let _port = tableData.length + 1;
      while (groups.includes(_port)) {
        _port = _port + 1;
      }
      let row = { port: _port, powerPins: [], referencePins: [] };
      row[pinType] = [pinNumber];
      this.updatePinUse({ table: [...tableData, row], type: pinType, save: true, DCLoad: this.props.DCLoad })
    } else {
      this.setPortPin(pinNumber, pinType, type, port)
    }
  }

  setPortPin = (pinNumber, pinType, type, port) => {
    const { tableData } = this.state;
    const record = tableData.find(item => item.port === port);
    if (type === 'remove') {
      this.tableRef.removePins(pinNumber, record, null, pinType)
    } else {
      const list = record[pinType];
      this.tableRef.addPins([...list, pinNumber], record, null, pinType)
    }
  }

  updateGridData = (gridColumn, gridRow, range) => {
    this.setState({
      gridColumn,
      gridRow,
      range
    }, () => {
      this.canvasRef.drawGridLine();
    })
  }

  removeGridLines = () => {
    this.canvasRef.removeGridLines()
  }

  updatePinPath = (border, size, widthSize, heightSize, range) => {
    this.setState({
      border,
      size,
      widthSize,
      heightSize,
      range
    }, () => {
      this.canvasRef.getPinPath(border, size / 100, widthSize / 100, heightSize / 100)
    })
  }

  removePinPath = () => {
    this.canvasRef.removePinPath()
  }

  clearShowPinList = () => {
    this.setState({
      showPinList: []
    })
  }

  render() {
    const { DesignData, designUpdate, layerList, powerPins, referencePins, tableData, descData, show, maxPinLen, refeGroup, updatePinSelected, gridColumn, gridRow, widthSize, heightSize, range, border, size, loading, showPinList } = this.state;
    const { chip, PowerNets, ReferenceNets, DCLoad, targetSetup, powerDomainId, maxFreq, notIncludeTarget, product, targetIC, error, portSetupTagClass, plocModelList, designId, plocSupport, autoMatch } = this.props;
    return <div className={portSetupTagClass ? 'port-setup port-tag-setup' : 'port-setup'} >
      <div className="port-setup-content" style={{ overflow: 'auto' }}>
        <Spin spinning={loading} tip={'Loading...'} >
          <PortTables
            onRef={node => this.tableRef = node}
            powerPins={powerPins}
            referencePins={referencePins}
            designUpdate={designUpdate}
            descData={descData}
            tableData={tableData}
            updateCurrentState={this.updateCurrentState}
            show={show}
            chip={chip}
            selectTags={this.selectTags}
            maxPinLen={maxPinLen}
            updatePinUse={this.updatePinUse}
            saveTable={this.saveTable}
            deleteTableRow={this.deleteTableRow}
            showGroupPins={this.showGroupPins}
            autoFindReference={this.autoFindReference}
            refeGroup={refeGroup}
            DCLoad={DCLoad}
            pushAllPinsToOneGroup={this.pushAllPinsToOneGroup}
            targetSetup={targetSetup}
            powerDomainId={powerDomainId}
            PowerNets={PowerNets}
            maxFreq={maxFreq}
            notIncludeTarget={notIncludeTarget}
            gridColumn={gridColumn}
            gridRow={gridRow}
            widthSize={widthSize}
            heightSize={heightSize}
            range={range}
            border={border}
            size={size}
            error={error}
            updateGridData={this.updateGridData}
            createGridGroup={this.createGridGroup}
            removeGridLines={this.removeGridLines}
            updatePinPath={this.updatePinPath}
            removePinPath={this.removePinPath}
            plocModelList={plocModelList}
            designId={designId}
            ReferenceNets={ReferenceNets}
            product={product}
            savePlocPorts={this.savePlocPorts}
            plocSupport={plocSupport}
            autoMatch={autoMatch}
          />
        </Spin>
      </div>
      <div className="port-setup-content">
        <PortCanvas
          onRef={node => this.canvasRef = node}
          product={product}
          tableData={tableData}
          updatePinSelected={updatePinSelected}
          DesignData={DesignData}
          designUpdate={designUpdate}
          layerList={layerList}
          powerPins={powerPins}
          referencePins={referencePins}
          chip={chip}
          PowerNets={PowerNets}
          ReferenceNets={ReferenceNets}
          targetIC={targetIC}
          gridColumn={gridColumn}
          gridRow={gridRow}
          showPinList={showPinList}
          updateParentState={this.updateCurrentState}
          savePortSetupPin={this.savePortSetupPin}
          clearShowPinList={this.clearShowPinList}
        />
      </div>
    </div>
  }
}

export default PortSetup;