import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import VirtualCompEditTable from './virtualCompEditTable';
import LayoutCanvas from './layoutCanvas';
import _ from 'lodash';
import MsgDialog from '../MsgDialog';
import { updateViewVComps, updateViewVCompsPanel, VC_RLC_TYPES } from '../../services/VirtualComponent';
import "./index.css";

class VirtualCompEditPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 1000,
      maxHeight: 1000,
      virtualComps: [],
      viewVirtualComps: [],
      openedVirtualComps: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 1000),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.initData();
  }

  initData = () => {
    const { virtualComponents } = this.props;
    this.setState({
      virtualComps: JSON.parse(JSON.stringify(virtualComponents || []))
    })
  }


  closePanel = () => {
    const { virtualComps } = this.state;
    const _virtualComponents = JSON.parse(JSON.stringify(virtualComps));
    _virtualComponents.forEach(item => delete item.errors);
    this.props.updateVirtualComponents(_virtualComponents);
    this.props.closePanel()
  }

  _updateVcComponents = (virtualComps) => {
    this.setState({
      virtualComps: JSON.parse(JSON.stringify(virtualComps))
    })
  }

  _updateViewVirtualComps = ({ comps, isUpdateCanvas }) => {
    const { viewVirtualComps, virtualComps, openedVirtualComps } = this.state;
    const {
      _comps,
      _openedVirtualComps,
      addedComps,
      removedComps } = updateViewVComps({
        viewVirtualComps,
        virtualComps,
        comps,
        openedVirtualComps
      })

    this.setState({
      viewVirtualComps: [..._comps],
      openedVirtualComps: _openedVirtualComps
    }, () => {
      isUpdateCanvas && this.canvasChild.switchVcCompsStatus({
        viewVirtualComps: [..._comps],
        openedVirtualComps: _openedVirtualComps,
        addedComps,
        removedComps,
        isUpdateLine: true
      })
    })
  }

  _updateViewVirtualCompsPanel = ({ comps, isUpdateCanvas }) => {
    const { viewVirtualComps, virtualComps, openedVirtualComps } = this.state;
    const {
      _comps,
      _viewVirtualComps,
      addedComps,
      removedComps } = updateViewVCompsPanel({
        viewVirtualComps,
        virtualComps,
        comps,
        openedVirtualComps
      })
    this.setState({
      viewVirtualComps: _viewVirtualComps,
      openedVirtualComps: [..._comps]
    }, () => {
      isUpdateCanvas && this.canvasChild.switchVcCompsStatus({
        viewVirtualComps: _viewVirtualComps,
        openedVirtualComps: [..._comps],
        addedComps,
        removedComps,
        isEditPanel: true
      })
    })
  }

  updateCanvasViewVCompsToTable = ({ viewVirtualComps, openedVirtualComps }) => {
    this.setState({
      viewVirtualComps: viewVirtualComps ? viewVirtualComps : this.state.viewVirtualComps,
      openedVirtualComps: openedVirtualComps ? openedVirtualComps : this.state.openedVirtualComps
    })
  }

  _delVirtualComps = (name) => {
    const { virtualComps, viewVirtualComps, openedVirtualComps } = this.state;

    if (name) {
      const findComp = virtualComps.find(item => item.name === name);
      let _viewVirtualComps = [...viewVirtualComps],
        _virtualComps = [...virtualComps],
        _openedVirtualComps = [...openedVirtualComps],
        deleteComps = [name];

      if (VC_RLC_TYPES.includes(findComp.type)) {
        //clear all comps by same location
        deleteComps = virtualComps.filter(item => _.isEqual(findComp.location, item.location)).map(item => item.name);
        _viewVirtualComps = viewVirtualComps.filter(item => !deleteComps.includes(item));
        _virtualComps = virtualComps.filter(item => !_.isEqual(findComp.location, item.location));
        _openedVirtualComps = _openedVirtualComps.filter(item => !deleteComps.includes(item))
      } else {
        _viewVirtualComps = viewVirtualComps.filter(item => item !== name);
        _virtualComps = virtualComps.filter(item => item.name !== name);
        _openedVirtualComps = _openedVirtualComps.filter(item => item !== name);
      }
      this.setState({
        viewVirtualComps: _viewVirtualComps,
        virtualComps: _virtualComps,
        openedVirtualComps: _openedVirtualComps
      }, () => {
        this.canvasChild.switchVcCompsStatus({
          viewVirtualComps: _viewVirtualComps,
          openedVirtualComps: _openedVirtualComps,
          deletedComps: [...deleteComps]
        })
      })
    } else {
      const comps = virtualComps.map(item => item.name);
      this.setState({
        viewVirtualComps: [],
        virtualComps: [],
        openedVirtualComps: []
      }, () => {
        this.canvasChild.switchVcCompsStatus({
          viewVirtualComps: [],
          openedVirtualComps: [],
          deletedComps: comps
        })
      })
    }

  }

  onCanvasRef = (ref) => {
    this.canvasChild = ref;
  }

  showErrors = (errors, name) => {
    const { virtualComps } = this.state;
    let errorMsgs = [];
    if (name) {
      errorMsgs = [{ title: name, children: errors }]
    } else {
      errorMsgs = virtualComps.filter(item => item.errors && item.errors.length).map(item => { return { title: item.name, children: item.errors || [] } })
    }
    this.setState({
      errorMsgs: errorMsgs
    })
  }

  clickDialogOkButton = () => {
    this.setState({
      errorMsgs: null
    })
  }

  showVcErrors = (error) => {
    this.setState({
      errorMsg: error
    })

    setTimeout(() => {
      this.setState({
        errorMsg: null
      })
    }, 4000);
  }

  render() {
    const { maxWidth, maxHeight, virtualComps, viewVirtualComps, errorMsgs, errorMsg,
      openedVirtualComps } = this.state;
    const { components, signals, designId, product, otherVirtualComps } = this.props;
    const content = (
      <Panel
        className='virtual-component-edit-panel'
        title={<div className='aurora-debug-title'>Virtual Components</div>}
        onCancel={this.closePanel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 900 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='virtual-component-edit-main'>
          <div className='virtual-component-edit-table-content'>
            <VirtualCompEditTable
              componentList={virtualComps}
              viewVirtualComps={viewVirtualComps}
              openedVirtualComps={openedVirtualComps}
              _delVirtualComps={this._delVirtualComps}
              _updateViewVirtualComps={this._updateViewVirtualComps}
              showErrors={this.showErrors}
              _updateViewVirtualCompsPanel={this._updateViewVirtualCompsPanel}
            />
            {errorMsg ? <span className='aurora-model-name-error-msg'>{errorMsg}</span> : null}
          </div>
          <div className='virtual-component-edit-canvas-content'>
            <LayoutCanvas
              product={product}
              onRef={this.onCanvasRef}
              components={components}
              signals={signals}
              designId={designId}
              virtualComps={virtualComps}
              viewVirtualComps={viewVirtualComps}
              otherVirtualComps={otherVirtualComps}
              _updateVcComponents={this._updateVcComponents}
              updateCanvasViewVCompsToTable={this.updateCanvasViewVCompsToTable}
              showVcErrors={this.showVcErrors}
            />
          </div>
        </div>
        {errorMsgs && errorMsgs.length ? <MsgDialog
          mask={false}
          type="message"
          allowButton={true}
          messageList={errorMsgs}
          showChildren={true}
          clickOkButton={this.clickDialogOkButton}
        /> : null}
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}
export default VirtualCompEditPanel;