import React, { Component, Fragment } from 'react';
import { Input, Button, Select, Slider } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { fastPIValueCheck } from '@/services/helper/fastPIValueCheck';
import './index.css';

const { Option } = Select

class PortSetupPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      maxWidth: 300,
      maxHeight: 100,
      gridColumn: '',
      gridRow: '',
      border: '',
      size: 100,
      advanced: false,
      widthSize: 100,
      heightSize: 100,
      range: 200,
      maxSize: 200,
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 300),
      maxHeight: getPanelMaxHeight(offset, 100)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { gridColumn, gridRow, border, size, widthSize, heightSize, range } = this.props
    this.setState({
      gridColumn,
      gridRow,
      border,
      size,
      widthSize,
      heightSize,
      range,
      maxSize: range
    }, () => {
      const { border, size, widthSize, heightSize, range } = this.state
      this.props.removePinPath()
      this.props.updatePinPath(border, size, widthSize, heightSize, range)
    })
  }

  closeModal = () => {
    const { error } = this.state;
    if (error) {
      return;
    }
    this.props.closeModal(false);
  }

  changeValue = (e, type) => {
    this.props.removeGridLines()
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    }, () => {
      const { gridColumn, gridRow, range } = this.state
      let _check = null;
      const unit = null
      _check = fastPIValueCheck({ value, inputType: type, unit });
      if (_check) {
        return
      }
      this.props.updateGridData(gridColumn, gridRow, range)
    });
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { error } = this.state;
    const unit = null
    _check = fastPIValueCheck({ value, inputType, unit });

    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: value,
        error: newError
      });
      if (inputType === 'range') {
        this.setState({ maxSize: value })
      }
    }
  }

  changeOptionValue = (value, type) => {
    this.props.removePinPath()
    this.props.removeGridLines()
    this.setState({ [type]: value, }, () => {
      const { gridColumn, gridRow, border, size, widthSize, heightSize, range } = this.state
      this.props.updatePinPath(border, size, widthSize, heightSize, range)
      this.props.updateGridData(gridColumn, gridRow, range)
    })
  }

  creatPortGroup = () => {
    const { error } = this.state;
    if (error) {
      return;
    }
    this.props.createGroupInCell()
    this.closeModal()
  }

  showAdvanced = (e) => {
    e && e.stopPropagation();
    this.setState({
      advanced: !this.state.advanced
    })
  }

  render() {
    const { error, maxWidth, maxHeight, gridColumn, gridRow, border, size, advanced, widthSize, heightSize, range, maxSize } = this.state;
    const setting = { defaultWidth: 500 }
    const Icon = advanced ? DownOutlined : RightOutlined
    const content = (
      <Panel
        className='port-setup-panel'
        title={<div className='port-setup-panel-name'>Port Setup Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        minHeight={100}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='port-setup-panel-main'>
          <div className='port-setup-panel-content'>
            <span className='port-setup-panel-content-body'>Border</span>
            <Select
              dropdownStyle={{ zIndex: 100000 }}
              className='aurora-select port-setup-panel-content-input'
              value={border || ''}
              onChange={(value) => { this.changeOptionValue(value, 'border') }}
              popupClassName='aurora-select-dropdown'
              getPopupContainer={() => document.getElementById('root')}
            >
              <Option value="power">Power pins</Option>
              <Option value="all">All pins</Option>
            </Select>
          </div>
          <div className='port-setup-panel-content port-setup-panel-advanced-content'>
            <span className='port-setup-panel-content-body port-setup-panel-advanced-body' onClick={this.showAdvanced}>
              {<Icon className='advanced-arrow-icon' />}
              Size
            </span>
            <Slider
              className='port-setup-panel-content-slider port-setup-panel-content-input'
              value={size}
              step={25}
              max={Number(maxSize)}
              tooltip={
                {
                  tipFormatter: (value) => `${value}%`,
                  placement: 'top',
                }
              }
              onChange={(value) => { this.changeOptionValue(value, 'size') }}
            />
          </div>
          {advanced && <Fragment>
            <div className='port-setup-panel-content'>
              <span className='port-setup-panel-content-body port-setup-panel-sub-span'>Width</span>
              <Slider
                className='port-setup-panel-content-slider port-setup-panel-content-input'
                value={widthSize}
                step={25}
                max={Number(maxSize)}
                tooltip={
                  {
                    tipFormatter: (value) => `${value}%`,
                    placement: 'top',
                  }
                }
                onChange={(value) => { this.changeOptionValue(value, 'widthSize') }}
              />
            </div>
            <div className='port-setup-panel-content'>
              <span className='port-setup-panel-content-body port-setup-panel-sub-span'>Height</span>
              <Slider
                className='port-setup-panel-content-slider port-setup-panel-content-input'
                value={heightSize}
                step={25}
                max={Number(maxSize)}
                onChange={(value) => { this.changeOptionValue(value, 'heightSize') }}
                tooltip={
                  {
                    tipFormatter: (value) => `${value}%`,
                    placement: 'top',
                  }
                }
              />
            </div>
            <div className='port-setup-panel-content'>
              <span className='port-setup-panel-content-body port-setup-panel-sub-span'>Range</span>
              <Input
                className='port-setup-panel-content-input port-setup-panel-content-input-addonAfter'
                value={range}
                onChange={(e) => this.changeValue(e, 'range')}
                onBlur={(e) => this.inputBlur(e, 'range')}
                addonAfter={'%'}
              />
            </div>
          </Fragment>
          }
          <div className='port-setup-panel-content'>
            <span className='port-setup-panel-content-body'>Row</span>
            <Input
              className='port-setup-panel-content-input'
              value={gridRow}
              onChange={(e) => this.changeValue(e, 'gridRow')}
              onBlur={(e) => this.inputBlur(e, 'gridRow')}
            />
          </div>
          <div className='port-setup-panel-content'>
            <span className='port-setup-panel-content-body'>Column</span>
            <Input
              className='port-setup-panel-content-input'
              value={gridColumn}
              onChange={(e) => this.changeValue(e, 'gridColumn')}
              onBlur={(e) => this.inputBlur(e, 'gridColumn')}
            />
          </div>
          <div className='port-setup-panel-content margin-top-10'>
            <Button className='port-setup-panel-button' onClick={this.creatPortGroup} >Create</Button>
          </div>
          {error && <span className='port-setup-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PortSetupPanel;