import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
class DetailPanel extends Component {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    this.props.closeDetails()
  }

  getPowerTreeDetail = () => {
    const { logs = [] } = this.props;
    return logs.map((log, index) => <div className={`cascade-power-tree-detail-tree-${log.type}`} key={index}>{log.text}</div>)
  }

  render() {
    const { name } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='library-data-panel'
          position='panel-center-left'
          title={name}
          zIndex={2000}
          onCancel={() => { this.closeModal() }}
          draggable
          minHeight={200}
          maxHeight={800}
          width={800}
        >
          <div className="cascade-power-tree-details">
            <div className='cascade-display-detail-box cascade-power-tree-details-box'>
              <pre>{this.getPowerTreeDetail()}</pre>
            </div>
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default DetailPanel
