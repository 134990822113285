import {
  CHANGE_MENU,
  CHOOSE_ITEM,
  UPDATE_PROGRESS,
  UPDATE_PAGE,
  SELECT_PCIE_INTERFACE,
  GET_INTERFACE_CONTENT,
  START_SERDES_VERIFICATION,
  SELECT_PCIE_GROUP,
  SAVE_PCIE_CONFIG,
  SELECT_MANAGER,
  MANAGER_INFO,
  SELECT_INTERFACE,
  MULTI_VERIFY_STATUS,
  RELOAD_MANAGER,
  CLOSE_SUMMARY,
  SINGLE_INTERFACE_VERIFY_INFO,
  SHOW_LOG,
  GET_SINGLE_MONITOR,
  APPEND_SINGLE_MONITOR,
  GENERATE_REPORT,
  UPDATA_REPORT_PROGRESS,
  CANCEL_WORKFLOW,
  CLOSE_MONITOR,
  CLOSED_MESSAGE,
  SHOW_ERROR_INFO,
  UPDATE_INTERFACE,
  RUNNING_SHOW,
  SAVE_PROJECT_NAME,
  ADD_MULTI_MONITOR,
  CLEAN_SINGLE_PROGRESS,
  CHANGE_RESULT,
  GET_PCIE_INTERFACE_INFO,
  UPDATE_INTERFACE_TYPE,
  INTERFACE_TYPE_STATUS,
  SHOW_MULTI_LOG,
  SELECT_PROJECT,
  RELOAD_INTERFACE,
  SELECT_EXPLORER,
  SAVE_PCIE_TO_SERVER
} from './actionType';


export const changeMenu = (menu) => ({
  type: CHANGE_MENU,
  menu,
})

export const changeResult = (menu, interfaceType, interfaceName, verificationId) => ({
  type: CHANGE_RESULT,
  menu,
  verificationId,
  interfaceType,
  interfaceName
})

export const getPCIeInterfaceInfo = ({ interfaceName, designName, interfaceType, menu, heightStatus }) => ({
  type: GET_PCIE_INTERFACE_INFO,
  interfaceName,
  designName,
  interfaceType,
  menu,
  heightStatus
})

export const chooseItem = (item) => ({
  type: CHOOSE_ITEM,
  item,
})

export const updateProgress = ({ interfaceName, interfaceType, progress }) => ({
  type: UPDATE_PROGRESS,
  interfaceName,
  interfaceType,
  progress
})

export const updatePage = () => ({
  type: UPDATE_PAGE,
})

export const selectPCIeInterface = ({ interfaceName, designName, interfaceType, heightStatus }) => ({
  type: SELECT_PCIE_INTERFACE,
  interfaceName,
  designName,
  interfaceType,
  heightStatus
})

export const getInterfaceContent = (interfaceInfo, heightStatus) => ({
  type: GET_INTERFACE_CONTENT,
  interfaceInfo,
  heightStatus
})

export const startSerdesVerification = (id) => ({
  type: START_SERDES_VERIFICATION,
  id
})

export const selectPCIeGroups = (groupId, info) => ({
  type: SELECT_PCIE_GROUP,
  groupId,
  info
})

export const savePCIeConfig = (serdesType) => ({
  type: SAVE_PCIE_CONFIG,
  serdesType
})

export const selectManager = (interfaceType) => ({
  type: SELECT_MANAGER,
  interfaceType
})

export const updateManagerInfo = (info, heightStatus) => ({
  type: MANAGER_INFO,
  info,
  heightStatus
})

export const selectInterface = (selectKeys) => ({
  type: SELECT_INTERFACE,
  selectKeys
})

export const multiVerifyStatus = (status) => ({
  type: MULTI_VERIFY_STATUS,
  status
})

export const reloadManager = () => ({
  type: RELOAD_MANAGER
})

export const closeSummary = () => ({
  type: CLOSE_SUMMARY
});

export const singleInterfaceVerifyInfo = ({ workflowId, interfaceType, interfaceName, allowCancel }) => ({
  type: SINGLE_INTERFACE_VERIFY_INFO,
  workflowId,
  interfaceType,
  interfaceName,
  allowCancel
})

export const showLog = (log) => ({
  type: SHOW_LOG,
  log
})

export const getSingleMonitor = (workflowId, multiType, verifyType, verifyName) => ({
  type: GET_SINGLE_MONITOR,
  workflowId,
  multiType,
  verifyType,
  verifyName,
  index: -1,
})

export const appendSingleMonitorLog = (interfaceType, interfaceName, log = []) => ({
  type: APPEND_SINGLE_MONITOR,
  interfaceType,
  interfaceName,
  log
})

export const generateReportByType = () => ({
  type: GENERATE_REPORT,
})

export const updateReportProgress = (progress) => ({
  type: UPDATA_REPORT_PROGRESS,
  reportProgress: progress
})

export const cancelWorkflow = (workflowId) => ({
  type: CANCEL_WORKFLOW,
  workflowId
});
export const cleanSingleProgress = (interfaceName, interfaceType) => ({
  type: CLEAN_SINGLE_PROGRESS,
  interfaceName,
  interfaceType
});

export const closeMonitor = () => ({
  type: CLOSE_MONITOR
});

export const closedMessage = (cancelType, interfaceName) => ({
  type: CLOSED_MESSAGE,
  cancelType,
  interfaceName
});

export const showErrorCheck = (connCheck, errorShow, interfaceName) => ({
  type: SHOW_ERROR_INFO,
  connCheck,
  errorShow,
  interfaceName
});

export const updateInterface = (data) => ({
  type: UPDATE_INTERFACE,
  data
});

export const runningShow = (runningShow) => ({
  type: RUNNING_SHOW,
  runningShow
});

export const saveProjectName = (projectName) => ({
  type: SAVE_PROJECT_NAME,
  projectName
});

export const addMultiMonitor = (multiMonitor) => ({
  type: ADD_MULTI_MONITOR,
  multiMonitor
});
export const updateInterfaceType = (changeType) => ({
  type: UPDATE_INTERFACE_TYPE,
  changeType
});
export const interfaceTypeStatus = (typeStatus) => ({
  type: INTERFACE_TYPE_STATUS,
  typeStatus
});
export const ShowMultiLog = (showMultiLog, multiLog, logType, logName) => ({
  type: SHOW_MULTI_LOG,
  showMultiLog,
  multiLog,
  logType,
  logName
});

export const selectProject = () => ({
  type: SELECT_PROJECT,
});

export const reloadInterface = (reload) => ({
  type: RELOAD_INTERFACE,
  reload
});

export const selectExplorer = (designId) => ({
  type: SELECT_EXPLORER,
  designId,
});

export const savePCIeToServer = () => ({
  type: SAVE_PCIE_TO_SERVER
})