import React from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchPlus, faSearchMinus, faArrowsAlt, } from '@fortawesome/free-solid-svg-icons';
import "./index.css";

export default function ResultZoom({
  zoomIn,
  zoomOut,
  fitView,
  plot = null
}) {
  return <Menu
    className='result-zoom-menu'
    items={[
      {
        key: 'ZoomIn',
        onClick: stopPropagation,
        label: <button className="layBtn" title="Zoom In" onClick={(e) => zoomIn ? zoomIn(e, plot) : null}>
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>
      },
      {
        key: 'ZoomOut',
        onClick: stopPropagation,
        label: <button className="layBtn" title="Zoom Out" onClick={(e) => zoomOut ? zoomOut(e, plot) : null}>
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>
      },
      {
        key: 'FitView',
        onClick: stopPropagation,
        label: <button className="layBtn" title="Fit View" onClick={(e) => fitView ? fitView(e, plot) : null}>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </button>
      }
    ]}
  />
}

function stopPropagation({ domEvent }) {
  domEvent && domEvent.stopPropagation()
}