import Parameter from './parameters';

class DDRParamSpec extends Parameter {
  constructor(props) {
    super(props);
    this.dependency = [];
    this.description = null; // Description of the parameter
  };

  initSpec(param) {
    this.description = param.description || null;
    this.reference = param.reference || null;
    this.dependency = param.dependency || [];
    this.paramInit(param);
  }
};

export default DDRParamSpec;