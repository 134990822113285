const PROJECT_TYPE = "SIERRA";
export const ADD_EXPERIMENT = `${PROJECT_TYPE}/add_experiment`;
export const UPDATE_EXPERIMENT_INFO = `${PROJECT_TYPE}/update_experiment_info`;
export const UPDATE_SWEEP_CREATE_STATUS = `${PROJECT_TYPE}/update_sweep_create_status`
export const ADD_EXPERIMENTS_BY_PASSIVE = `${PROJECT_TYPE}/add_experiment_by_passive`;
export const CREATE_EXPS_BY_MODEL = `${PROJECT_TYPE}/create_exps_by_model`;
export const UPDATE_SINGLE_EXPERIMENT = `${PROJECT_TYPE}/update_single_experiment`;
export const UPDATE_EXPERIMENT_COMP = `${PROJECT_TYPE}/update_experiment_component`;
export const REDUCE_SWEEP_TABLE_COLUMN = `${PROJECT_TYPE}/reduce_sweep_table_column`;
export const UPDATE_EXPERIMENTS_STATUS = `${PROJECT_TYPE}/update_experiments_status`;
export const CREATE_EXPS_BY_TRACE = `${PROJECT_TYPE}/create_exps_by_trace`;
export const UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO = `${PROJECT_TYPE}/update_sweep_schematic_pre_layout_info`;
export const GET_ALL_EXP_MEASUREMENT_CONFIG = `${PROJECT_TYPE}/get_all_exp_measurement_config`;
export const UPDATE_SWEEP_MEASUREMENT_DISPLAY_INFO = `${PROJECT_TYPE}/update_sweep_measurement_display_info`;