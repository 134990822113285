import {
  AURORA_AAF,
  AURORA_DB,
  CADENCE_BRD,
  ALTIUM,
  ANSYS,
  ALLEGRO_ALG,
  PRE_LAYOUT,
  AEDB,
  CADENCE_MCM,
  SPD,
  ODB_PP,
  CADENCE_SIP
} from "../../constants/designVendor";
import { ANDES_V2, ROCKY, SIERRA, FASTPI, CASCADE, HIMALAYAS } from "../../constants/pageType";
import { PCB_CHANNEL, END_TO_END_CHANNEL, PATH_R, IR_EXPLORER, IMPEDANCE, DCR, POWER_TREE, DESIGN_TREE, SINGLE_TREE, MULTIPLE_RESULT, MULTIPLE_CHANNEL, HIMALAYAS_WRITE, EXPERIMENTS } from "../../constants/treeConstants";
import { RUN_MENU_LIST } from "../BMA/helper/result";
import { SSN_RESULT } from "../Rocky/constants";

const PCB_PARSING = "PCBParsing", SIMULATION_DEBUG = "simulation", CARD_EXTRACTION_DEBUG = "card_extraction", PDN = "PDN", REPORT = "report",
  PACKAGE_DEBUG = "package", INTERFACE_SIMULATION = "interface simulation", S_PARAMETER_DOWNLOAD = "sParameterModelDownload", RESULT = 'result',
  DOWNLOAD_RESULT = "download_result", EXPERIMENT_S_PARAMETER_DOWNLOAD = "experiment_S_parameter_download";

export const log = { name: 'Log file', default: true, option: 'log' },
  dat = { name: 'Interface.dat', default: true, option: 'interface' },
  AAF = { name: 'Aurora AAF', vendor: AURORA_AAF, option: 'aaf' },
  AuroraDB = { name: 'Aurora DB', default: true, vendor: AURORA_DB, option: 'adb' },
  brd = { name: '.brd file', vendor: CADENCE_BRD, option: 'brd' },
  PcbDoc = { name: '.PcbDoc file', vendor: ALTIUM, option: 'asc' },
  ANF = { name: "Ansys ANF", vendor: ANSYS, option: 'anf' },
  alg = { name: ".alg file", vendor: ALLEGRO_ALG, option: 'alg' },
  json = { name: 'Interface.json', default: true, option: 'interface' },
  prelayout = { name: 'Pre-layout.json', default: true, option: 'prelayout' },
  Ansys_AEDB = { name: 'Ansys EDB', vendor: AEDB, option: 'aedb' },
  Cadence_MCM = { name: '.mcm file', vendor: CADENCE_MCM, option: 'mcm' },
  SPD_FILE = { name: ".spd file", vendor: SPD, option: 'spd' },
  ODB = { name: "ODB++", vender: ODB_PP, option: 'odb' },
  Cadence_SIP = { name: '.sip file', vendor: CADENCE_SIP, option: 'sip' };

function getDebugDownloadList(product, { menuType, vendor, extraction, result, hspice, simulation, isPdn, ioSimMode, selectInfo }) {
  let list = [];
  if (menuType === PCB_PARSING) {
    if (!vendor) {
      list = [log, dat, { name: 'Design Folder', option: 'design', default: true }]
    } else {
      list = [log, AuroraDB, dat];
    }

    if ([SIERRA, ANDES_V2, FASTPI, CASCADE].includes(product)) {
      //removed dat option
      list = list.filter(item => item.option !== "interface");
    }

    let typeList = [];
    switch (vendor) {
      case AURORA_AAF:
        typeList = [AAF]; break;
      case AURORA_DB:
        typeList = []; break;
      case CADENCE_BRD:
        typeList = [brd, alg, AAF]; break;
      case ALTIUM:
        typeList = [PcbDoc, AAF]; break;
      case ANSYS:
        typeList = [ANF, AAF]; break;
      case ALLEGRO_ALG:
        typeList = [alg, AAF]; break;
      case PRE_LAYOUT:
        if (product === ANDES_V2) {
          list = [prelayout];
        } else if (product === SIERRA) {
          list = [prelayout];
        } else {
          list = [log, prelayout, json];
        }
        break;
      case AEDB:
        typeList = [AAF, Ansys_AEDB];
        break;
      case CADENCE_MCM:
        typeList = [Cadence_MCM, alg, AAF]; break;
      case SPD:
        typeList = [SPD_FILE, AAF]; break;
      case ODB_PP:
        typeList = [ODB]; break;
      case Cadence_SIP:
        typeList = [Cadence_SIP, alg, AAF]; break;
      default:
        typeList = []; break;
    }

    list = [...list, ...typeList]
  } else if ([SIMULATION_DEBUG, INTERFACE_SIMULATION, CARD_EXTRACTION_DEBUG, IMPEDANCE, DCR].includes(menuType)) {
    list = getSimulationDownloadList(product, { vendor, extraction, result, hspice, simulation, menuType, selectInfo });
  } else if ([PACKAGE_DEBUG, PCB_CHANNEL].includes(menuType)) {
    // if (isPdn) {
    list = [
      { name: 'Extraction folder', option: "extraction", default: true },
      { name: 'S-parameter file', option: "touchstone", default: true }]
    // } else {
    // list = menuType === PCB_CHANNEL && !isPdn ? [{ name: 'Extraction folder', option: "extraction", default: true }] : [
    //   { name: 'Ansys extraction folder', option: "extraction", default: true },
    //   { name: 'S-parameter file', option: "touchstone", default: true }]
    // }
  } else if (menuType === RESULT) {
    list = [
      { name: 'Export the eye parameter and crosstalk result to excel', option: "download", default: true }]
  } else if (menuType === S_PARAMETER_DOWNLOAD) {
    list = [
      { name: 'S-Parameter file', option: "s_parameter", default: true }]
  } else if (menuType === DOWNLOAD_RESULT) {
    let ssnList = [{ name: 'AC', option: "AC", default: true, children: [{ name: 'ac0 file', option: "AC_ac0", default: true }] },
    {
      name: 'SSN', option: "SSN", default: true, children: [
        { name: 'tr0 file', option: "SSN_tr0", default: true },
        { name: 'mt0 file', option: "SSN_mt0", default: true },
        { name: 'result', option: "SSN_result", default: true }
      ]
    },
    {
      name: 'DP', option: "DP", default: true, children: [
        { name: 'tr0 file', option: "DP_tr0", default: true },
        { name: 'mt0 file', option: "DP_mt0", default: true },
        { name: 'cfg file', option: "DP_cfg", default: true },
        { name: 'result', option: "DP_result", default: true }
      ]
    }],
      delayList = [{
        name: 'Write', option: "DELAY_WRITE", default: true, children: [
          { name: 'tr0 file', option: "DELAY_WRITE_tr0", default: true },
          { name: 'mt0 file', option: "DELAY_WRITE_mt0", default: true },
          { name: 'cfg file', option: "DELAY_WRITE_cfg", default: true },
          { name: 'result', option: "DELAY_WRITE_result", default: true }
        ]
      },
      {
        name: 'ZL', option: "DELAY_ZL", default: true, children: [
          { name: 'tr0 file', option: "DELAY_ZL_tr0", default: true },
          { name: 'mt0 file', option: "DELAY_ZL_mt0", default: true },
          { name: 'cfg file', option: "DELAY_ZL_cfg", default: true },
          { name: 'result', option: "DELAY_ZL_result", default: true }
        ]
      },
      {
        name: 'ZH', option: "DELAY_ZH", default: true, children: [
          { name: 'tr0 file', option: "DELAY_ZH_tr0", default: true },
          { name: 'mt0 file', option: "DELAY_ZH_mt0", default: true },
          { name: 'cfg file', option: "DELAY_ZH_cfg", default: true },
          { name: 'result', option: "DELAY_ZH_result", default: true }
        ]
      },
      {
        name: 'Read', option: "DELAY_READ", default: true, children: [
          { name: 'tr0 file', option: "DELAY_READ_tr0", default: true },
          { name: 'mt0 file', option: "DELAY_READ_mt0", default: true },
          { name: 'cfg file', option: "DELAY_READ_cfg", default: true },
          { name: 'result', option: "DELAY_READ_result", default: true }
        ]
      }];
    list = ioSimMode === HIMALAYAS_WRITE ? [
      ...ssnList,
      { name: 'Input file', option: "input", default: true },
      ...delayList
    ] : [
      ...delayList,
      { name: 'Input file', option: "input", default: true },
    ]

  } else if (menuType === MULTIPLE_RESULT) {
    list = [
      { name: 'Download crosstalk result', option: "download", default: true }]
  } else if (menuType === SSN_RESULT) {
    list = [
      { name: 'Export the eye parameter result to excel', option: "download", default: true }]
  } else if (menuType === MULTIPLE_CHANNEL) {
    list = [{ name: 'Extraction folder', option: "extraction", default: true }]
  }


  return list;
}

const PRE_LAYOUT_ITEM = { name: 'The pre layout file', option: "pcb" },
  POST_LAYOUT_ITEM = { name: 'The uploaded layout file', option: "pcb" };

function getSimulationDownloadList(product, params) {
  let list = [];
  switch (product) {
    case ROCKY:
    case SIERRA:
      list = [
        { name: 'Log file', default: true, option: 'log' }]

      if (params.menuType !== CARD_EXTRACTION_DEBUG) {
        list.push({ name: 'Simulation folder', default: true, option: 'simulation' })
      }
      if (params.vendor !== 'SEV' && product === ROCKY) {
        list.push(params.vendor === PRE_LAYOUT ? PRE_LAYOUT_ITEM : POST_LAYOUT_ITEM)
      }
      if (params.extraction) {
        list.push(
          { name: 'Extraction folder', option: "extraction" },
        )
      }

      if (params.result) {
        list.push(
          { name: 'Result data folder', option: 'result' },
        )
        if (product === SIERRA) {
          list.push({ name: 'Original raw file', option: 'raw' });
        }
      }

      if (params.hspice) {
        list.push(
          { name: 'HSPICE tr0 data file', option: 'tr0' },
        )
      }
      if (params.vendor === "SEV") {
        list.push(
          { name: 'Waveforms (.raw) and eye-diagrams (.png)', option: 'result' },
          { name: 'PinToPinSim.sp files', option: 'PinToPinSimSpFiles' },
          // { name: 'Simulation setting excel', option: 'sev_setting_excel' },
        )
      }
      break;
    case ANDES_V2:
      list = getAndesV2PCBChannelDebugList(params.vendor, params.selectInfo)
      break;
    case FASTPI:
      list = [
        { name: 'Log file', option: 'log', default: true },
        { name: 'Extraction folder', option: 'extraction' },
        { name: 'The uploaded layout file', option: 'pcb' },
        { name: 'Simulation folder', option: 'simulation', default: true }
      ]
      break
    case CASCADE:
      list = [
        { name: 'Log file', option: 'log', default: true },
        { name: 'Extraction folder', option: 'extraction' },
        { name: 'The uploaded layout file', option: 'pcb' },
      ]
      if (params.menuType === IMPEDANCE) {
        list.push(
          { name: 'Transient folder', option: 'transient' },
          { name: 'Optimization folder', option: 'optimization' }
        )
      }
      if (params.menuType === DCR) {
        list.push(
          { name: 'Path-R import log', option: 'import_log' }
        )
      }
      if (params.result) {
        list.push(
          { name: 'Result folder', option: "result" }
        )
      }
      if (params.simulation) {
        list.push(
          { name: 'Simulation folder', option: 'simulation', default: true }
        )
      }
      break;
    case HIMALAYAS:
      list = [
        { name: 'Log file', option: 'log', default: true },
        { name: 'Simulation folder', option: 'simulation', default: true }
      ];
      break;
    default: break;
  }
  return list;
}

function getAndesV2PCBChannelDebugList(vendor) {
  let list = [
    { name: 'Log file', default: true, option: 'log' },
    { name: 'Extraction folder', default: true, option: 'extraction' },
    { name: 'Simulation folder', default: true, option: 'simulation' }
  ];
  const pcbItem = vendor === PRE_LAYOUT ? PRE_LAYOUT_ITEM : POST_LAYOUT_ITEM;
  list.push(pcbItem);
  return list;
}

/**
 * get download debug simulation url
 * rocky
 * @param projectId
 * @param projectName
 * @param channel current checked channel { id:"", name: ""};
 * @param verification current checked verification { id:"", name: ""};
 * @param options checked options ["extraction","result","log", ...]
 *  */
function getRockyDownloadSimulationUrl({ projectId, channel, verification, verificationIds, options, token, menuType, designId, isPdn, menuKey, pdnId }) {
  let url = ``, name = null;
  if (menuType === PACKAGE_DEBUG) {
    // url = `/api/v3/rocky/package/debug/download?pdnId=${pdnId}&channelIds=${verificationIds}&options=${options}&access_token=${token}`;
    if (isPdn) {
      url = `/api/v3/rocky/package/debug/download?pdnId=${pdnId}&options=${options}&access_token=${token}`;
    } else {
      url = `/api/v3/rocky/package/debug/download?channelIds=${verificationIds}&options=${options}&access_token=${token}`;
    }
  } else if (menuType === RESULT) {
    if (menuKey === MULTIPLE_RESULT) {
      url = `/api/v3/verification/result/crosstalk/csv?verificationId=${verification.id}&access_token=${token}`
    } else {
      url = `/api/v3/verification/result/crosstalk/excel?designId=${designId}&access_token=${token}`
    }
  } else if (menuType === S_PARAMETER_DOWNLOAD) {
    return `verification/result/extraction-model?verificationIds=${verificationIds}&access_token=${token}`
  } else if (menuType === PCB_CHANNEL) {
    if (isPdn) {
      url = `/api/v3/rocky/package/debug/download?pdnId=${pdnId}&options=${options}&access_token=${token}`;
    } else {
      url = `/debug/rocky/project/${projectId}?channel=${pdnId}&verification=${verification ? verification.id : 'all'}&options=${options}&access_token=${token}`
    }
  } else if (channel && channel.id !== 'all') {
    url = [SIMULATION_DEBUG, CARD_EXTRACTION_DEBUG, MULTIPLE_CHANNEL].includes(menuType) ? `/debug/rocky/project/${projectId}?channel=${channel.id}&verification=${verification ? verification.id : 'all'}&options=${options}&access_token=${token}`
      : `/api/v3/debug/rocky/project/${projectId}?channel=${channel.id}&verification=${verification ? verification.id : 'all'}&options=${options}&access_token=${token}`;
    name = channel.name;
  } else {
    url = [SIMULATION_DEBUG, CARD_EXTRACTION_DEBUG, MULTIPLE_CHANNEL].includes(menuType) ? `/debug/rocky/project/${projectId}?channel=${"all"}&options=${options}&access_token=${token}`
      : `/api/v3/debug/rocky/project/${projectId}?channel=${"all"}&options=${options}&access_token=${token}`;
  }
  return { url, name };
}

function getAndesV2DownloadSimulationUrl({ menuType, designId, channelId, options, token, sweepId }) {
  switch (menuType) {
    case PCB_PARSING:
      return `/api/v3/my-project/design/debug/pcb-parsing?designId=${designId}&options=${options}&access_token=${token}`;
    case PCB_CHANNEL:
      return `/api/v3/andes/channel/debug?channelId=${channelId}&options=${options}&access_token=${token}`;
    case END_TO_END_CHANNEL:
      return "";
    case REPORT:
      return `/api/v3/andes/report/debug?channelIds=${options}&access_token=${token}`;
    case EXPERIMENTS:
      return `/andes/channel/sweep/debug?sweepId=${sweepId}&options=${options}`;
    default: return null;
  }
}

function getFastPIDownloadSimulationUrl({ menuType, designId, options, token, verificationId }) {
  switch (menuType) {
    case PCB_PARSING:
      return `/api/v3/project/design/debug?designId=${designId}&options=${options}&access_token=${token}`;
    case SIMULATION_DEBUG:
      return `/api/v3/project/verification/PDN/debug?options=${options}&verificationId=${verificationId}&access_token=${token}`;
    case REPORT:
      return `/api/v3/fastpi/report/debug?pdnIds=${options}&access_token=${token}`;
    default: return null;
  }
}

function getBMADownloadSimulationUrl({ bmaChannelId, simulationTypes, token }) {
  return `/bma/channel/result/download?bmaChannelId=${bmaChannelId}&options=${simulationTypes}&access_token=${token}`;
}

function getSierraDebugDownloadList({ menuType, vendor, extraction, result, hspice }) {
  return getDebugDownloadList(SIERRA, { menuType, vendor, extraction, result, hspice })
}

function getRockyDebugDownloadList({ menuType, vendor, extraction, result, hspice, isPdn }) {
  return getDebugDownloadList(ROCKY, { menuType, vendor, extraction, result, hspice, isPdn })
}

function getAndesV2DebugDownloadList(params) {
  return getDebugDownloadList(ANDES_V2, { ...params });
}

function getFastPIDebugDownloadList(params) {
  return getDebugDownloadList(FASTPI, { ...params });
}

function getCascadeDebugDownloadList(params) {
  return getDebugDownloadList(CASCADE, { ...params });
}

function getBMADebugDownloadList(params) {
  let list = RUN_MENU_LIST.map(item => { return { name: item.title, option: item.key, default: true } });
  list = [
    ...list,
    { name: "Export raw data to CSV files", option: "input_to_csv" },
    { name: "Report", option: "report" }
  ]
  return list
}

const DEBUG_SUCCESS = 1, DEBUG_FAILED = 0;
function getDebugMessage(status, type) {
  if (status === DEBUG_SUCCESS) {
    return `Download ${type} zip file successfully!`;
  }
  if (status === DEBUG_FAILED) {
    return `Download ${type} zip file failed!`;
  }
}

function getDebugCheckValues(values, prevValues, downloadAllList) {
  let currentCheckValues = [...prevValues];
  let newValues = [];
  for (let item of values) {
    let value = { name: item, /* children: [] */ }
    const find = currentCheckValues.find(it => it.name === item);

    if (find && find.children) {
      value.children = find.children;
    } else {
      const findAll = downloadAllList.find(it => it.option === item);
      value.children = findAll && findAll.children ? findAll.children.map(it => it.option) : null;
    }
    newValues.push(value);
  }
  return newValues;
}

function getHimalayasDebugDownloadList(menuType, ioSimMode) {
  return getDebugDownloadList(HIMALAYAS, { menuType, ioSimMode });
}

function getHimalayasDownloadSimulationUrl({ menuType, options, verificationId, channelIds, token }) {
  if (menuType === SIMULATION_DEBUG) {
    return `/Himalayas/project/debug?options=${options}&verificationId=${verificationId}&access_token=${token}`;
  } else if (menuType === DOWNLOAD_RESULT) {
    return { url: `/Himalayas/channel/download/result`, params: { channelIds, options } }
  } else if (menuType === REPORT) {
    return `/Himalayas/report/debug?channelIds=${channelIds}&access_token=${token}`
  }
}

function getLayoutDownloadSimulationUrl({ menuType, designId, options, token, verificationId }) {
  switch (menuType) {
    case PCB_PARSING:
      return `/api/v3/project/design/debug?designId=${designId}&options=${options}&access_token=${token}`;
    default: return null;
  }
}

export {
  PCB_PARSING,
  SIMULATION_DEBUG,
  PDN,
  PATH_R,
  IMPEDANCE,
  IR_EXPLORER,
  DCR,
  POWER_TREE,
  DESIGN_TREE,
  SINGLE_TREE,
  PACKAGE_DEBUG,
  REPORT,
  INTERFACE_SIMULATION,
  getRockyDownloadSimulationUrl,
  getAndesV2DownloadSimulationUrl,
  getSierraDebugDownloadList,
  getRockyDebugDownloadList,
  getAndesV2DebugDownloadList,
  getDebugMessage,
  DEBUG_SUCCESS,
  DEBUG_FAILED,
  getFastPIDebugDownloadList,
  getCascadeDebugDownloadList,
  getAndesV2PCBChannelDebugList,
  getFastPIDownloadSimulationUrl,
  getBMADownloadSimulationUrl,
  getBMADebugDownloadList,
  getDebugCheckValues,
  S_PARAMETER_DOWNLOAD,
  CARD_EXTRACTION_DEBUG,
  getHimalayasDebugDownloadList,
  getHimalayasDownloadSimulationUrl,
  RESULT,
  DOWNLOAD_RESULT,
  getDebugDownloadList,
  getLayoutDownloadSimulationUrl,
  EXPERIMENT_S_PARAMETER_DOWNLOAD
}