import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ComponentTable from './ComponentTable';
import SignalPinTable from './signalPinTable';
import designConstructor from '@/services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import LayoutData from '@/services/data/LayoutData';
import { selectLayer, selectChange } from '../../LayoutExplorer/store/Andes_v2/actionCreators';
import PowerNetsTable from './PowerNetsTable';
import DesignInfo from '@/services/Andes_v2/pcbInfo';
import { refreshPCB } from '../store/project/action';
import { isEndToEndChildrenChannel } from '@/services/Andes_v2';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import canvas from '@/services/LayoutCanvas';
import MultiModelContent from './multiModelContent';
import { updateMultiModelStatus, updateChannelContent, updateSimulationSolverStatus } from '../store/channel/action';
import { getSetupComponentInfo } from '@/services/Andes_v2/channel';
import _ from 'lodash';
import { SERDES_TYPES } from '../../../services/PCBHelper';
import AdditionalNets from '@/components/AdditionalNets';
import SimulationContent from '../SimulationContent';
import { CPHY } from '../../../services/PCBHelper/constants';
import "./index.css";
import { HSPICE } from '../../../services/Andes_v2/simulation';
import { ANDES_V2 } from '../../../constants/pageType';

class ChannelSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PCBNetsObj: {},
      compsInfo: {},
      updateComponentsStatus: false,
      advanced: false
    }
  }

  componentDidMount = () => {
    this.getPcbNets({});
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, simulationSolver, simulationSolverStatus } = this.props;
    if ((verificationId && verificationId !== prevProps.verificationId)) {
      this.setState({ advanced: false })
    }

    if (simulationSolver === HSPICE && simulationSolverStatus) {
      const hspiceSimulationElement = document.getElementById("andes-v2-hspice-simulation");
      // If the anchor corresponding to ID exists, jump to the anchor
      if (hspiceSimulationElement) { hspiceSimulationElement.scrollIntoView() }
      this.props._updateSimulationSolverStatus(false);
    }
    this.getPcbNets(prevProps);
  }

  changeAdvanced = () => {
    this.setState({
      advanced: !this.state.advanced
    })
  }

  displayToLayout = (compNames, nets, pinsObj) => {
    const { channelDesignId, viewList } = this.props;
    const design = designConstructor.getDesign(channelDesignId);
    if (viewList && viewList.length > 1 && design.vendor !== PRE_LAYOUT) {
      this.viewToLayout(compNames, nets, pinsObj);
    }
  }

  viewToLayout = (compNames = [], nets = [], pinsObj = {}) => {
    const { channelDesignId } = this.props;
    const layout = canvas.getLayout(channelDesignId);
    let layers = layout.findCurrentLayer(compNames);
    layers = [...new Set(layers)];
    this.props._selectLayer(layers, channelDesignId);
    this.props._selectChange({ nets: [...nets], comps: [...compNames], pinsObj }, channelDesignId);
  }

  checkLoadPCB = ({ PCBNetsObj, channelDesignId, prevProps, refreshDesignId }) => {
    if (!PCBNetsObj[channelDesignId]
      || !PCBNetsObj[channelDesignId].length
      || prevProps.channelDesignId !== channelDesignId
      || (refreshDesignId && refreshDesignId === channelDesignId)
    ) {
      return true;
    }
  }

  getPcbNets = (prevProps) => {
    //get pcb info
    const { channelDesignId, pcbComponentsNets = [], refreshDesignId, components, signals } = this.props;
    const { PCBNetsObj } = this.state;

    const compNames = components ? components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name) : [];
    const prevCompNames = prevProps.components ? prevProps.components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name) : [];

    if (channelDesignId && this.checkLoadPCB({ PCBNetsObj, channelDesignId, prevProps, refreshDesignId }) && pcbComponentsNets.includes(channelDesignId)) {
      const design = designConstructor.getDesign(channelDesignId);
      if (design && design.vendor === PRE_LAYOUT) {
        return;
      }
      const info = DesignInfo.getPCBInfo(channelDesignId);
      const netsList = info && info.netsList ? info.netsList : [];
      let _PCBNetsObj = PCBNetsObj || {};
      _PCBNetsObj[channelDesignId] = netsList;
      //get components pins nets
      if (refreshDesignId && refreshDesignId === channelDesignId) {
        LayoutData.setCompPinsNets(channelDesignId);
      }
      LayoutData.getCompPinsNets(channelDesignId);

      this.setState({
        PCBNetsObj: _PCBNetsObj,
        compsInfo: getSetupComponentInfo({ channelDesignId, components, signals })
      }, () => {
        this.signalChild && this.signalChild.getPCBNets();
      })
    } else if (!_.isEqual(compNames, prevCompNames)) {
      const design = designConstructor.getDesign(channelDesignId);
      if (design && design.vendor !== PRE_LAYOUT) {
        this.setState({
          compsInfo: getSetupComponentInfo({ channelDesignId, components, signals })
        })
      }
    }

    if (refreshDesignId && refreshDesignId !== prevProps.refreshDesignId) {
      this.props._refreshPCB(null)
    }
  }

  onRef = (ref) => {
    this.signalChild = ref;
  }

  // openPackagePanel = (compName) => {
  // open PCB model panel;
  //   setTimeout(() => {
  //     if (compName) {
  //       // If all connected models are deleted, the package panel will automatically open
  //       const btn = document.getElementById(`${compName}-pcb-column`) ? document.getElementById(`${compName}-pcb-column`).parentNode : null;
  //       if (btn) {
  //         // Implement automatic click to expand PCB Model Panel
  //         btn.click()
  //       }
  //     }
  //   }, 100)
  // }

  changeStatus = () => {
    setTimeout(() => {
      this.setState({
        updateComponentsStatus: !this.state.updateComponentsStatus
      })
    })
  }

  scrollToAnchor = (compName, updateStatus) => {
    // Find anchor point
    if (updateStatus) {
      setTimeout(() => {
        this.setState({
          updateComponentsStatus: !this.state.updateComponentsStatus
        }, () => {
          setTimeout(() => { this.compScrollIntoView(compName) }, 100)
        })
      })
    } else {
      this.compScrollIntoView(compName)
    }
  }

  compScrollIntoView = (compName) => {
    if (compName) {
      let anchorElement = document.getElementById(`${compName}-multi-content-setup-dialog`);
      // If the anchor corresponding to ID exists, jump to the anchor
      if (anchorElement) { anchorElement.scrollIntoView(); }
    }
  }

  saveAdditionalNets = (nets) => {
    this.props._updateChannelContent({ additionalNets: nets });
  }

  render() {
    const { components, signals, serdesType, libraryStatus, selectedSignals, isEndToEndChildren,
      powerNets, endToEndId, channelDesignId, channelLoading, channelId, viewList, selectedDesignIDs,
      verificationId, multiModelUpdateStatus, additionalNets, simulationSolver } = this.props;
    const { PCBNetsObj, updateComponentsStatus, compsInfo, advanced } = this.state;
    const design = designConstructor.getDesign(channelDesignId);
    const pcbNetsList = PCBNetsObj[channelDesignId] || [];
    return (
      <Fragment>
        <div className='andes-v2-content-setting pcb-channel-main'>
          <Spin spinning={channelLoading}>
            <ComponentTable
              components={components}
              signals={signals}
              selectedDesignIDs={selectedDesignIDs}
              powerNets={powerNets}
              channelDesignId={channelDesignId}
              viewList={viewList}
              channelId={channelId}
              libraryStatus={libraryStatus}
              pcbNetsList={pcbNetsList}
              serdesType={serdesType}
              scrollToAnchor={this.scrollToAnchor}
              displayToLayout={this.displayToLayout} />
            <div className='space-10'>
              <SignalPinTable
                onRef={this.onRef}
                components={components}
                selectedDesignIDs={selectedDesignIDs}
                endToEndId={endToEndId}
                isEndToEndChildren={isEndToEndChildren}
                displayToLayout={this.displayToLayout}
                channelDesignId={channelDesignId}
                signals={signals}
                designVendor={design ? design.vendor : null}
                viewList={viewList}
                serdesType={serdesType}
                channelId={channelId}
                pcbNetsList={pcbNetsList}
                selectedSignals={selectedSignals}
                compsInfo={compsInfo}
              />
            </div>
            {design && design.vendor !== PRE_LAYOUT ? <div className='space-10'>
              <PowerNetsTable
                powerNets={powerNets}
                displayToLayout={this.displayToLayout}
                channelDesignId={channelDesignId}
                pcbNetsList={pcbNetsList}
                signals={signals}
                serdesType={serdesType}
              />
            </div> : null}
            {design && design.vendor !== PRE_LAYOUT ? <Fragment>
              <div className='space-10'>
                <div className='channel-setup-border'>
                  <div className='channel-advanced-title'>
                    {advanced ? <DownOutlined onClick={this.changeAdvanced} /> : <RightOutlined onClick={this.changeAdvanced} />}
                    <span onClick={this.changeAdvanced}>Advanced</span>
                  </div>
                  {advanced ? <div className='space-10'>
                    <AdditionalNets
                      designId={channelDesignId}
                      additionalNets={additionalNets}
                      saveAdditionalNets={this.saveAdditionalNets}
                      matchWidth={false}
                      product={ANDES_V2}
                      signals={signals}
                    />
                  </div> : null}
                </div>
              </div>
            </Fragment> : null}
            <MultiModelContent
              components={components}
              signals={signals}
              verificationId={verificationId}
              updateMultiModelStatus={this.props._updateMultiModelStatus}
              multiModelUpdateStatus={multiModelUpdateStatus}
              updateComponentsStatus={updateComponentsStatus}
              changeUpdateMultiStatus={this.changeStatus}
              libraryStatus={libraryStatus}
              design={design}
              serdesType={serdesType}
            />
            {serdesType === CPHY && simulationSolver === HSPICE ? <div className='space-10'>
              <SimulationContent />
            </div> : null}
          </Spin>
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const {
    channel: { channelInfo: { content = {}, designId, type, endToEndId, verificationId }, channelLoading, channelId, multiModelUpdateStatus, simulationSolverStatus },
    project: { viewList, layout, pcbComponentsNets, refreshDesignId, selectedKeys }, library: { libraryStatus },
    explorer: { selections }
  } = state.AndesV2Reducer;
  const {
    components = [],
    signals = [],
    powerNets = [],
    selectedSignals = [],
    additionalNets = [],
    simulationSolver
  } = content;
  const layers = selections[designId] ? (selections[designId].layers || []) : [];
  return {
    components,
    signals,
    powerNets,
    channelDesignId: designId,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    viewList,
    layers,
    channelId,
    layout,
    pcbComponentsNets,
    serdesType: type,
    refreshDesignId,
    selectedSignals,
    channelLoading,
    libraryStatus,
    endToEndId,
    isEndToEndChildren: isEndToEndChildrenChannel({ endToEndId }) ? true : false,
    verificationId,
    multiModelUpdateStatus,
    additionalNets,
    simulationSolver,
    simulationSolverStatus
  }
}

const mapDispatch = (dispatch) => ({
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _refreshPCB(refreshDesignId) {
    dispatch(refreshPCB(refreshDesignId))
  },
  _updateMultiModelStatus(status) {
    dispatch(updateMultiModelStatus(status))
  },
  _updateChannelContent(content) {
    dispatch(updateChannelContent(content))
  },
  _updateSimulationSolverStatus(status) {
    dispatch(updateSimulationSolverStatus(status))
  }
})

export default connect(mapState, mapDispatch)(ChannelSetup);