import {
  CREATE_CHANNEL,
  DELETE_CHANNEL,
  GET_DESIGN_CHANNEL_LIST,
  GET_CHANNEL_CONTENT,
  UPDATE_CHANNEL_INFO,
  OPEN_PCB_CHANNEL,
  CLEAR_PCB_CHANNEL_INFO,
  EDIT_COMPONENT_TYPE,
  UPDATE_CHANNEL_CONTENT,
  SAVE_CHANNEL_EXTRACTION_CONFIG,
  AUTO_GET_CHANNEL_LIST,
  ADD_SIGNAL,
  SAVE_SELECT_NETS,
  CHANGE_SIGNAL_NAME,
  DELETE_SIGNAL,
  CHANGE_GROUP_NAME,
  SAVE_CHANNEL_CONFIG,
  CHANNEL_REPLACE,
  UPDATE_CHANNEL_REPLACE_MONITOR,
  CHANNEL_RENAME,
  UPDATE_CHANNEL_NAME,
  UPDATE_REFERENCE_NETS,
  COPY_PCB_CHANNEL,
  UPDATE_PORT_SETUPS_TO_SERVER,
  POWER_NETS_SELECTION,
  POWER_NET_UPDATE_VOLTAGE,
  OPEN_CHANNEL_LOADING,
  SAVE_COMP_PACKAGE_MODEL,
  UPDATE_GENERATE_PORTS_ERRORS,
  CHECK_SIGNALS,
  CHANGE_CHANNEL_CREATE_VISIBLE,
  UPDATE_EXIST_RESULT_STATUS,
  SAVE_DESIGN_CLIP_INFO,
  FORCE_PREVIEW_PANEL_SHOW,
  SAVE_COMP_CONNECTOR_MODEL,
  SAVE_PREVIEW_STATUS,
  DELETE_PWR_GND_NET,
  UPDATE_ADS_CONFIG,
  EDIT_CMC_COMP_PINS_CONNECT,
  SAVE_CMC_MODEL,
  SAVE_COMP_DIODE_MODEL,
  UPDATE_CHANNEL_CONNECTION,
  UPDATE_COMPLIANCE_CONFIG,
  UPDATE_HYBRID_STATUS,
  SAVE_CHANNEL_HYBRID_REGIONS,
  UPDATE_HYBRID_BOUNDARIES,
  UPDATE_HYBRID_INFO,
  SAVE_COMP_PCB_MODEL,
  UPDATE_MULTI_MODEL_STATUS,
  UPDATE_IC_COMPONENT_PIN,
  UPDATE_DIODE_COMPONENT_PINS,
  EXPAND_CHANNEL,
  CHANGE_SIMULATION_SOLVER,
  UPDATE_HSPICE_CONFIG,
  UPDATE_SIMULATION_SOLVER_STATUS,
  UPDATE_HSPICE_CONFIG_STATUS
} from './actionTypes';

export const createChannel = ({ data, designId, content = {}, generationPortErrors = null, generationPortWarnings = null, netInfo = null }) => ({
  type: CREATE_CHANNEL,
  data,
  designId,
  content,
  generationPortErrors,
  generationPortWarnings,
  netInfo
});

export const changeChannelCreateVisible = (visible) => ({
  type: CHANGE_CHANNEL_CREATE_VISIBLE,
  visible
});

export const getDesignChannelList = (data) => ({
  type: GET_DESIGN_CHANNEL_LIST,
  data
});

export const deleteChannel = (data) => ({
  type: DELETE_CHANNEL,
  data
});

/* content */
export const getChannelContent = (id) => ({
  type: GET_CHANNEL_CONTENT,
  id
});

export const updateChannelInfo = (info) => ({
  type: UPDATE_CHANNEL_INFO,
  info
});

export const openPCBChannel = (id, simulating) => ({
  type: OPEN_PCB_CHANNEL,
  id,
  simulating
});

export const clearPCBChannelInfo = () => ({
  type: CLEAR_PCB_CHANNEL_INFO
});

/* SETUP */
export const updateChannelContent = (data) => ({
  type: UPDATE_CHANNEL_CONTENT,
  data
});

export const editComponentType = (record) => ({
  type: EDIT_COMPONENT_TYPE,
  record
});

export const saveChannelExtractionConfig = (extraction, prevExtraction, channelId) => ({
  type: SAVE_CHANNEL_EXTRACTION_CONFIG,
  extraction,
  prevExtraction,
  channelId
});

export const autoGetChannelList = ({ currentSimInfo, projectId }) => ({
  type: AUTO_GET_CHANNEL_LIST,
  currentSimInfo,
  projectId
});

export const addSignal = (group, groupList) => ({
  type: ADD_SIGNAL,
  group,
  groupList
});

export const saveSelectNets = ({ newNets, record, prevNets }) => ({
  type: SAVE_SELECT_NETS,
  newNets,
  record,
  prevNets
});

export const changeSignalName = (record, prevRecord) => ({
  type: CHANGE_SIGNAL_NAME,
  record,
  prevRecord
});

export const deleteSignal = (record) => ({
  type: DELETE_SIGNAL,
  record
});

export const changeGroupName = (record, prevRecord) => ({
  type: CHANGE_GROUP_NAME,
  record,
  prevRecord
});

export const saveChannelConfig = (simulateId) => ({
  type: SAVE_CHANNEL_CONFIG,
  simulateId
});

export const channelReplace = ({ channelInfo, channelId, isUpdate }) => ({
  type: CHANNEL_REPLACE,
  channelInfo,
  channelId,
  isUpdate
});

export const updateChannelReplaceMonitor = (replaceMonitor) => ({
  type: UPDATE_CHANNEL_REPLACE_MONITOR,
  replaceMonitor
});

export const channelRename = (data) => ({
  type: CHANNEL_RENAME,
  data
});

export const updateChannelName = ({ name }) => ({
  type: UPDATE_CHANNEL_NAME,
  name
});

export const updateReferenceNets = () => ({
  type: UPDATE_REFERENCE_NETS
});

export const copyChannel = (name, channelId) => ({
  type: COPY_PCB_CHANNEL,
  name,
  channelId
});

export const updatePortSetupsToServer = (data) => ({
  type: UPDATE_PORT_SETUPS_TO_SERVER,
  data
});

export const powerNetsSelection = ({ name, prevName }) => ({
  type: POWER_NETS_SELECTION,
  name,
  prevName
});

export const powerVoltageUpdate = ({ name, voltage, prevVoltage }) => ({
  type: POWER_NET_UPDATE_VOLTAGE,
  name,
  voltage,
  prevVoltage
});

export const openChannelLoading = (status) => ({
  type: OPEN_CHANNEL_LOADING,
  status
});

export const saveCompPackageModel = (pkg, component, modelsObj, dataIndex = 'pkg') => ({
  type: SAVE_COMP_PACKAGE_MODEL,
  pkg,
  component,
  modelsObj,
  dataIndex
});

export const updateGeneratePortsErrors = (errorObj) => ({
  type: UPDATE_GENERATE_PORTS_ERRORS,
  errorObj
});

export const checkedSignals = (checked, key, groupType) => ({
  type: CHECK_SIGNALS,
  checked,
  key,
  groupType
});

export const updateChannelResultExist = (resultExist) => ({
  type: UPDATE_EXIST_RESULT_STATUS,
  resultExist
});

export const saveDesignClip = (info) => ({
  type: SAVE_DESIGN_CLIP_INFO,
  info
})

export const forcePreviewPanelShow = (show) => ({
  type: FORCE_PREVIEW_PANEL_SHOW,
  show
})

export const saveCompConnectorModel = (model, component, modelList) => ({
  type: SAVE_COMP_CONNECTOR_MODEL,
  model,
  component,
  modelList
})

export const savePreviewStatus = (status) => ({
  type: SAVE_PREVIEW_STATUS,
  status
});

export const deletePwrGnd = (netName) => ({
  type: DELETE_PWR_GND_NET,
  netName
});

export const updateADSConfig = (adsConfig, options = { save: true, isCloseModal: false, id: null }) => ({
  type: UPDATE_ADS_CONFIG,
  adsConfig,
  save: options.save,
  id: options.id,
  isCloseModal: options.isCloseModal
});

export const editCMCCompPinsConnect = (part, pinMap) => ({
  type: EDIT_CMC_COMP_PINS_CONNECT,
  part,
  pinMap
});

export const saveCMCModel = (part, files, pinList) => ({
  type: SAVE_CMC_MODEL,
  part,
  pinList,
  files
});

export const saveCompDiodeModel = (part, model) => ({
  type: SAVE_COMP_DIODE_MODEL,
  part,
  model
});

export const updateChannelConnection = () => ({
  type: UPDATE_CHANNEL_CONNECTION
});

export const updateComplianceConfig = (configObj) => ({
  type: UPDATE_COMPLIANCE_CONFIG,
  ...configObj
});

export const updateHybridStatus = (status) => ({
  type: UPDATE_HYBRID_STATUS,
  status
});

export const saveChannelHybridRegion = ({ hybridRegions, hybridLines, verificationId, saveHfssZones = false }) => ({
  type: SAVE_CHANNEL_HYBRID_REGIONS,
  hybridRegions,
  hybridLines,
  verificationId,
  saveHfssZones
});

export const updateHybridBoundaries = (verificationId, clearRegions) => ({
  type: UPDATE_HYBRID_BOUNDARIES,
  verificationId,
  clearRegions
});

export const updateHybridInfo = ({ hybridLines, hybridRegions, id }) => ({
  type: UPDATE_HYBRID_INFO,
  hybridLines,
  hybridRegions,
  id
});

export const saveCompPCBModel = (model, modelsList, component) => ({
  type: SAVE_COMP_PCB_MODEL,
  model,
  modelsList,
  component
})

export const updateMultiModelStatus = (status) => ({
  type: UPDATE_MULTI_MODEL_STATUS,
  status
});

export const updateICCompPin = (record, compName, pinInfo) => ({
  type: UPDATE_IC_COMPONENT_PIN,
  record,
  compName,
  pinInfo
});

export const updateDiodeCompPins = (part, components) => ({
  type: UPDATE_DIODE_COMPONENT_PINS,
  part,
  components
})

export const expandChannel = (channelId, designId, addSweepToCache = true) => ({
  type: EXPAND_CHANNEL,
  channelId,
  designId,
  addSweepToCache
})

// CPHY hspice simulation
export const changeSimulationSolver = (simulationSolver) => ({
  type: CHANGE_SIMULATION_SOLVER,
  simulationSolver
});

export const updateHSPICEConfig = (hspiceConfig, id) => ({
  type: UPDATE_HSPICE_CONFIG,
  hspiceConfig,
  id
});

export const updateSimulationSolverStatus = (status) => ({
  type: UPDATE_SIMULATION_SOLVER_STATUS,
  status
});

export const updateHspiceConfigStatus = (status) => ({
  type: UPDATE_HSPICE_CONFIG_STATUS,
  status
});