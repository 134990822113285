import React, { Component, createRef } from 'react';
import { Button, Select, Input, InputNumber, message, Dropdown, Space } from 'antd';
import StackupData, { UNIT, changeExcelToCSV, uploadDatFile } from '@/services/data/newStackupData';
import LayoutData from '@/services/data/LayoutData';
import CeIODataBlock from '@/services/CeFileIO/CeIODataBlock';
import { stackupDataProcess } from '@/services/helper/dataProcess';
import { ReExtractionByDesign } from '@/services/api/designFile';
import { downloadFile } from '@/services/helper/downloadHelper'
import { updateThicknessByUnit, getThicknessScale } from '../../../../services/Stackup';

const Option = Select.Option;
const SpaceCompact = Space.Compact;

class StackupFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settingButon: 'block',
      settingInput: 'none',
      settingDielectric: 'none',
      settingConductivity: 'none',
      type: null,
      range: null,
      thickness: null,
      dielectric: null,
      losstangent: null,
      conductivity: null,
    }
    this.stackupRef = createRef();

  }

  showMetalSetting = (type, range) => {
    this.setState({
      type: type,
      range: range,
      settingButon: 'none',
      settingInput: 'block',
      settingConductivity: 'block'
    })
  }

  showAllDielectric(type) {
    this.setState({
      type: type,
      settingButon: 'none',
      settingDielectric: 'block',
      settingInput: 'block',
      settingConductivity: 'none'
    })
  }

  cancelClick = () => {
    this.setState({
      settingButon: 'block',
      settingInput: 'none',
      settingDielectric: 'none',
      thickness: null,
      dielectric: null,
      losstangent: null,
      type: null,
      range: null,
      conductivity: null,
      settingConductivity: 'none'
    })
  }

  settingUnitChange = (value) => {
    const { data, designID, unit, _reCheckSimulation } = this.props;
    this.props.settingUnit(value, designID);
    const scale = getThicknessScale(value, unit);
    let updateLayers = [...data];
    updateLayers.forEach(layer => {
      layer.thickness = updateThicknessByUnit(scale, layer.thickness)
    });
    this.props.changeTable(updateLayers, value, designID);
    StackupData.saveLayer(updateLayers, value, designID).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
      if (_reCheckSimulation) {
        _reCheckSimulation(updateLayers);
      }
    })
  }

  thicknessChange = (value) => {
    this.setState({
      thickness: value,
    })
  }

  conductivityChange = (e) => {
    this.setState({
      conductivity: e.target.value
    })
  }

  dielectricChange = (value) => {
    this.setState({
      dielectric: value,
    })
  }

  LossTangentChange = (value) => {
    this.setState({
      losstangent: value
    })
  }

  errorCheck = (stackupData) => {
    let { metalList = [], dielectricList = [] } = stackupData;
    const { designID } = this.props;
    const designInfo = LayoutData.getLayout(designID)
    const layoutList = designInfo && designInfo.mLayerMgr && designInfo.mLayerMgr.mMetalLayers ? designInfo.mLayerMgr.mMetalLayers : [];
    // Check if the metal layers are consistent
    const dataName = layoutList.map(item => item.mName)
    if (dataName.length !== metalList.length) {
      message.error('Metal layers mismatch.');
      return true;
    }
    for (let item of dataName) {
      if (metalList.findIndex(m => m.name === item) < 0) {
        message.error('Metal layers mismatch.');
        return true;
      }
    }
    // Check if the layer names are duplicated
    let nameCheck = [];
    for (let check of [...metalList, ...dielectricList]) {
      if (nameCheck[check.name]) {
        message.error('Upload layers name is duplicated.');
        return true;
      }
      nameCheck[check.name] = true;
    }

    for (let index in dataName) {
      if (dataName[index] !== metalList[index].name) {
        message.error('Upload stackup failed!');
        return true;
      }
    }

    for (let item of metalList) {
      if (!item.thickness && item.thickness !== 0) {
        message.error('Thickness cannot be empty.');
        return true;
      } else if (item.thickness <= 0) { //range >0
        message.error('Thickness should be greater than 0.');
        return true;
      }

      if (!item.conductivity && item.conductivity !== 0) {
        message.error('Conductivity cannot be empty.');
        return true;
      } else {
        //range 1.0e5 ~ 1.0e10
        if (isNaN(item.conductivity)) {
          message.error('Conductivity must be a number.');
          return true;
        }
        if (parseFloat(item.conductivity) < 1.0e5 || parseFloat(item.conductivity) > 1.0e10) {
          message.error('Conductivity should be between 1.0e5 ~ 1.0e10.');
          return true;
        }
      }
    };

    for (let item of dielectricList) {
      // check dielectric name
      if (/[^0-9a-zA-Z_-]/i.test(item.name)) {
        message.error('Dielectric name may only contain the following characters: number, letters, underscores, minus.');
        return true;
      }

      if (!item.thickness && item.thickness !== 0) {
        message.error('Thickness cannot be empty.');
        return true;
      } else if (item.thickness <= 0) { //range >0
        message.error('Thickness should be greater than 0.');
        return true;
      }

      if (!item.constant && item.constant !== 0) {
        message.error('Dielectric Constant cannot be empty.');
        return true;
      } else {
        //range 1-10
        if (parseFloat(item.constant) < 1 || parseFloat(item.constant) > 10) {
          message.error('Dielectric Constant should be between 1 ~ 10.');
          return true;
        }
      }

      if (!item.lossTangent && item.lossTangent !== 0) {
        message.error('Loss Tangent cannot be empty.');
        return true;
      } else {
        //range 0-0.1
        if (parseFloat(item.lossTangent) < 0 || parseFloat(item.lossTangent) > 0.1) {
          message.error('Loss Tangent should be between 0 ~ 0.1.');
          return true;
        }
      }
    }
  }

  setLayersProperty = () => {
    const { data, unit, designID } = this.props;
    /* let newData = [].concat(data); */
    let newData = [];//Deep copy
    for (let i = 0; i < data.length; i++) {
      let item = { ...data[i] };
      newData.push(item);
    }
    const { range, type, dielectric, losstangent, conductivity } = this.state;
    let { thickness } = this.state;
    if ((!thickness && thickness !== 0) && (!dielectric && dielectric !== 0) && (!losstangent && losstangent !== 0) && (!conductivity && conductivity !== 0)) {
      this.cancelClick();
      return;
    }
    let error = null;
    let startIndex = 0, lastIndex = newData.length - 1;
    startIndex = newData.findIndex(item => item.type === type);
    let typeData = newData.map(item => item.type);
    lastIndex = typeData.lastIndexOf(type);

    //value error check

    if (thickness || thickness === 0) {
      if (parseFloat(thickness) <= 0) {//range >0
        message.error('Thickness should be greater than 0.');
        error = true;
      }
    }

    if (type === 'Metal') {
      if (conductivity || conductivity === 0) {
        //range 1.0e5 ~ 1.0e10
        if (isNaN(conductivity)) {
          message.error('Conductivity must be a number.');
          error = true;
        }
        if (parseFloat(conductivity) < 1.0e5 || parseFloat(conductivity) > 1.0e10) {
          message.error('Conductivity should be between 1.0e5 ~ 1.0e10.');
          error = true;
        }
      }
    } else if (type === 'Dielectric') {

      if (losstangent || losstangent === 0) {
        //range 0-0.1
        if (parseFloat(losstangent) < 0 || parseFloat(losstangent) > 0.1) {
          message.error('Loss Tangent should be between 0 ~ 0.1.');
          error = true;
        }
      }
      if (dielectric || dielectric === 0) {
        //range 1-10
        if (parseFloat(dielectric) < 1 || parseFloat(dielectric) > 10) {
          message.error('Dielectric Constant should be between 1 ~ 10.');
          error = true;
        }
      }
    }
    if (error) {
      return;
    }
    for (let i = 0; i < newData.length; i++) {
      if ((range === 'Outer' && i !== startIndex && i !== lastIndex) ||
        (range === 'Inner' && (i === startIndex || i === lastIndex))) {
        continue;
      }
      let item = newData[i];
      if (item.type === type) {
        if (thickness) {
          item.thickness = thickness;
        }

        if (dielectric) {
          item.epsilon = dielectric;
        }

        if (losstangent || losstangent === 0) {
          item.delta = losstangent
        }

        if (conductivity) {
          item.conductivity = conductivity;
        }

      }
    };
    this.props.changeTable(newData, unit, designID);
    const { _reCheckSimulation } = this.props;
    StackupData.saveLayer(newData, unit, designID).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
      if (_reCheckSimulation) {
        _reCheckSimulation(newData);
      }
    })
    this.cancelClick();
  }

  reloadData = (unit, reSet) => {
    const { designID, _reCheckSimulation } = this.props;
    StackupData.getLayer(true, false, reSet, designID).then(res => {
      const data = JSON.parse(JSON.stringify(res));
      this.props.changeTable(data, unit, designID);
      if (_reCheckSimulation) {
        _reCheckSimulation(data);
      }
    })
  }

  reloadStackup = () => {
    const { designID, data } = this.props;
    LayoutData.requestStackup(designID, true).then(response => {
      const unit = response.getUnitString();
      /*  this.reloadData(unit, true); */
      StackupData.getLayer(true, false, true, designID).then(res => {
        const newData = JSON.parse(JSON.stringify(res));
        // is re extraction
        const isExtraction = StackupData.reExtractionStackupCheck(data, newData);
        if (isExtraction) {
          ReExtractionByDesign(designID);
        }
        this.props.changeTable(newData, unit, designID);
      })
    })
  }

  uploadStackup = (e) => {
    const el = this.stackupRef.current;
    if (!el) return;
    el.click();
  }

  onStackupChange = (e) => {
    const fileContent = e.target.files[0];
    if (fileContent) {
      const { designID } = this.props;
      const regexpMatchName = fileContent.name.match(/\.csv|\.xls|\.xlsx|\.dat$/i);
      let extensionName = regexpMatchName ? regexpMatchName[0] : null;

      if (extensionName === null) {
        message.error("Unrecognized file type. We only support .csv, .dat, .xls and .xlsx files.")
      } else if (extensionName === '.dat') {
        const reader = new FileReader();
        reader.onload = (e) => {
          let stackupBlock = new CeIODataBlock("Stackup");
          stackupBlock.ReadBlockFromFile(e.target.result);
          const layerItemList = stackupBlock.GetAllBlockItems();
          let data = layerItemList.filter(item => item.mName === 'Dielectric' || item.mName === 'Metal');
          let { metalList, dielectricList } = stackupDataProcess(data);
          let error = this.errorCheck({ metalList, dielectricList });
          if (error) {
            return;
          }
          uploadDatFile(fileContent, designID).then((res) => {
            this.reloadStackup();
          }, (error) => {
            message.error('Upload stackup failed!');
            console.error(error);
          })
        };
        reader.readAsText(fileContent);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (extensionName === '.xls' || extensionName === '.xlsx') {
            changeExcelToCSV(fileContent).then(res => {
              // TODO
            })
          } else {
            //Deep copy
            let prevData = this.props.data.map(item => {
              return { ...item };
            })
            const { data, unit, errorMsg } = StackupData.parseUploadFile(e.target.result, prevData);
            if (errorMsg) {
              message.error(errorMsg);
              return;
            }
            if (!StackupData.checkUnit(unit)) {
              message.error("Unrecognized unit. We only support 'mil', 'um', 'mm'");
              return;
            }
            const error = StackupData.checkData([...data]);
            if (error) {
              message.error(error);
              return;
            }
            StackupData.updateBeforeUpload(data);
            StackupData.saveLayer(data, unit, designID).then((res) => {
              if (res.data.code !== 0) {
                message.error('Upload stackup failed!');
              }
              this.reloadData(unit);
            }, (error) => {
              message.error('Upload stackup failed!');
              console.error(error);

            })
          }
        };
        reader.readAsText(fileContent);
      }
    }

    // Fix the same path file can not be repeated upload problem : empty file value
    this.stackupRef.current.value = '';
  }

  downloadStackup = () => {
    let filename;

    var csv = this.convertArrayOfObjectsToCSV({
      data: this.generateStackupCSVData()
    });
    if (csv === null) return;

    filename = 'stackup.csv';

    csv = '#' + csv;

    let type = 'text/csv;charset=utf-8;';
    downloadFile(csv, filename, type)
  }

  convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data === null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  generateStackupCSVData() {
    const { data, unit } = this.props;
    const csvData = data.map(dataItem => {
      let outputData = {
        name: dataItem.name || "",
        type: dataItem.type || "",
      }
      outputData["thickness(" + unit + ")"] = dataItem.thickness || "";
      outputData["dielectric constant"] = dataItem.epsilon || "";
      outputData["loss tangent"] = dataItem.delta || "";
      outputData["conductivity"] = dataItem.conductivity || "";
      return outputData;
    });
    return csvData;
  }

  render() {
    const { settingButon, settingInput, settingDielectric, settingConductivity } = this.state;
    const { unit } = this.props;
    const menu = [
      { key: 'Upload Stackup', label: 'Upload Stackup', onClick: this.uploadStackup },
      { key: 'Download Stackup', label: 'Download Stackup', onClick: this.downloadStackup },
      { type: 'divider' },
      { key: 'All Metal Layers', label: 'All Metal Layers', onClick: () => this.showMetalSetting('Metal', 'All') },
      { key: 'Outer Metal Layers', label: 'Outer Metal Layers', onClick: () => this.showMetalSetting('Metal', 'Outer') },
      { key: 'Inner Metal Layers', label: 'Inner Metal Layers', onClick: () => this.showMetalSetting('Metal', 'Inner') },
      { type: 'divider' },
      { key: 'All Dielectric Layers', label: 'All Dielectric Layers', onClick: () => this.showAllDielectric('Dielectric') }
    ]
    return (
      <div className="stack-footer stackup-clear">
        <div className="stackup-footer-value-setting" style={{ display: settingButon }}>
          <Dropdown menu={{ items: menu }} trigger={['click']} placement="topRight">
            <Button
              type="primary"
              style={{ float: "right" }}
            >
              Menu
            </Button>
          </Dropdown>
          <input
            type='file'
            ref={this.stackupRef}
            style={{ display: 'none' }}
            accept=".csv,.xls,.xlsx,.dat"
            onChange={this.onStackupChange}
          />
        </div>
        <div className="stackup-input-row stackup-clear" style={{ display: settingInput }}>
          <div className="stackup-input-col stackup-clear">
            <span className="stackup-thickness-name">Thickness</span>
            <SpaceCompact className="stackup-input-group">
              <InputNumber
                min={0}
                onChange={this.thicknessChange}
                value={this.state.thickness}
                width={100}
              />
              <Select
                defaultValue={unit}
                value={unit}
                onChange={this.settingUnitChange}
                style={{ width: '64px' }}
              >
                {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
              </Select>
            </SpaceCompact>
          </div>
          <div className="stackup-input-col stackup-clear" style={{ display: settingConductivity }}>
            <span className="stackup-set-name">Conductivity</span>
            <Input
              onChange={this.conductivityChange}
              value={this.state.conductivity}
              addonAfter={'S/m'}
              className='stackup-conductivity'
            />
          </div>
          <div className="stackup-input-col stackup-clear" style={{ display: settingDielectric }}>
            <span className="stackup-set-name">Dielectric Constant</span>
            <InputNumber
              min={0}
              onChange={this.dielectricChange}
              width={100}
              value={this.state.dielectric}
            />
          </div>
          <div className="stackup-input-col stackup-clear" style={{ display: settingDielectric }}>
            <span className="stackup-set-name">Loss Tangent</span>
            <InputNumber
              min={0}
              onChange={this.LossTangentChange}
              width={100}
              value={this.state.losstangent}
            />
          </div>
          <div className="stackup-input-col stackup-clear">
            <Button type="primary" className="stackup-set-button" onClick={this.setLayersProperty}>Set</Button>
            <Button type="primary" onClick={this.cancelClick}>Cancel</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default StackupFooter;