import { select } from 'd3-selection';
import { getTextWidth } from '../../../../helper/getTextWidth';
import { FALLING, RISING, HIGH, LOW, HOLD, SETUP, VHIGH, VLOW, VINH, VINL, OVERSHOOT, UNDERSHOOT } from './markConstant';

const SETUP_COLOR = '#69aaef', HOLD_COLOR = '#3bb53d', HIGH_COLOR = '#69aaef', LOW_COLOR = '#3bb53d', V_COLOR = '#ff7d7da4', SHOOT_COLOR = '#000000aa';
const ARROW_WIDTH = 4, V_SPACE = 20, TIME_DISTANCE = 15, INDENT = 10, SHOOT_DISTANCE = 40, DIVID_SPACE = 5;

class MarkNode {
  constructor({ hashId, prevX, ym, yValue, color, x, targetX, targetY, getYValue, getXValue, type, anotherX, height, width, anotherY, extra, usage }) {
    this.hashId = hashId;
    this.prevX = prevX;
    this.id = `node-${hashId}-${prevX}`;
    this.ym = ym;
    this.yValue = yValue;
    this.color = color;
    this.x = x;
    this.targetX = targetX;
    this.targetY = targetY;
    this.root = null;
    this.getYValue = getYValue;
    this.getXValue = getXValue
    this.type = type;
    this.typeColor = this.getTypeColor(type, color);
    this.anotherX = anotherX;
    this.height = height;
    this.width = width;
    this.anotherY = anotherY;
    this.extra = extra;
    this.usage = usage;
  }

  drawNode(root, removeNode) {
    this.root = root.append('g')
      .attr('id', 'curve-mark-node-' + this.id);

    let defs = this.root.append("defs");

    let arrowMarker = defs.append("marker")
      .attr("id", "arrow")
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", "7")
      .attr("markerHeight", "7")
      .attr("viewBox", "0 0 12 12")
      .attr('refX', '6')
      .attr('refY', '6')
      .attr("orient", "auto");

    let arrow_path = "M2,2 L10,6 L2,10 L6,6 L2,2";

    arrowMarker.append("path")
      .attr("d", arrow_path)
      .attr("fill", "red");

    this.root.append("line")
      .attr("x1", this.x)
      .attr("y1", this.type === RISING ? this.ym + 4 : this.ym - 4)
      .attr("x2", this.x)
      .attr("y2", this.type === RISING ? this.ym - 2 : this.ym + 2)
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("marker-end", "url(#arrow)");

    const db = this.root.append('g')
      .attr('class', 'mark-node-box')
      .attr('x', this.x + 5)
      .attr('y', this.ym - 15)
      .on('click', function (event) {
        event && event.stopPropagation();
      })
      .on('mouseenter', () => this.drawNodeNameBox(removeNode))

    //add rect
    db.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', this.x + 5)
      .attr('y', this.ym - 15)
      .attr('fill', '#e9e9e900')
      .style('width', '75px')
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(this.yValue)
      .attr('x', this.x + 7)
      .attr('y', this.ym - 11)
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.color)
      .on('click', function (event) {
        event && event.stopPropagation();
      });

  }

  drawNodeNameBox(removeNode) {
    //Move the mouse to change curve mark rect width
    let hoverBg = this.root.select('.mark-node-box').append('g')
      .attr('id', `hover-${this.id}`)
      .attr('x', this.x + 5)
      .attr('y', this.ym - 15)
      .on('click', function (event) {
        event && event.stopPropagation();
      })
      .on('mouseleave', () => {
        select(`#hover-${this.id}`).remove();
      });

    hoverBg.append('rect')
      .attr('class', 'curve-mark-hover-rect')
      .attr('id', `hover-${this.id}`)
      .attr('x', this.x + 5)
      .attr('y', this.ym - 15)
      .attr('fill', '#ffffff')
      .style('width', this.yValue.length <= 5 ? '48px' : '60px')
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    hoverBg.append('text')
      .text(`${this.yValue}`)
      .attr('class', 'mark-node-text')
      .attr('x', this.x + 7)
      .attr('y', this.ym - 11)
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.color)
      .on('click', function (event) {
        event && event.stopPropagation();
      });


    hoverBg.append('text')
      .text(`X`)
      .attr('class', 'curve-box-close')
      .attr('x', this.getNodeRemoveXPosition())
      .attr('y', this.ym - 11)
      .on('click', (event) => {
        removeNode(this.hashId);
        event && event.stopPropagation();
      });
  }

  deleteNode() {
    this.root.remove();
  }

  getNodeRemoveXPosition() {
    let textX = this.x + 35;
    if (this.yValue.length === 6) {
      textX = this.x + 39;
    } else if (this.yValue.length > 6) {
      textX = this.x + this.yValue.length * 5.5;
    }
    return textX;
  }

  redrawNode = ({ padding, height, yMin, yMax }) => {
    this.draw(padding, height, yMin, yMax)
  }

  draw(padding, height, yMin, yMax) {
    switch (this.type) {
      case FALLING:
      case RISING:
        this.refreshNode(padding);
        break;
      case SETUP:
      case HOLD:
      case HIGH:
      case LOW:
        this.refreshTimeLine(padding);
        break;
      case VHIGH:
      case VLOW:
      case VINH:
      case VINL:
        this.refreshMeasure(padding, height, yMin, yMax);
        break;
      case OVERSHOOT:
      case UNDERSHOOT:
        this.refreshShoot(padding, height, yMin, yMax);
        break;
      default:
        return;
    }
  }

  drawArrowLine(root, removeNode) {
    this.root = root.append('g')
      .attr('id', 'curve-mark-node-' + this.id);

    let defs = this.root.append("defs");

    let arrowMarker = defs.append("marker")
      .attr("id", `arrow-${this.type}`)
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", "7")
      .attr("markerHeight", "7")
      .attr("viewBox", "0 0 12 12")
      .attr('refX', '6')
      .attr('refY', '6')
      .attr("orient", "auto");

    let arrow_path = "M2,2 L10,6 L2,10 L6,6 L2,2";

    arrowMarker.append("path")
      .attr("d", arrow_path)
      .attr("fill", this.typeColor);

    // reverse arrow
    let reverseArrowMarker = defs.append("marker")
      .attr("id", `arrow-${this.type}-reversed`)
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", "7")
      .attr("markerHeight", "7")
      .attr("viewBox", "0 0 12 12")
      .attr('refX', '6')
      .attr('refY', '6')
      .attr("orient", "auto");

    let reverse_arrow_path = "M10,2 L2,6 L10,10 L6,6 L10,2";

    reverseArrowMarker.append("path")
      .attr("d", reverse_arrow_path)
      .attr("fill", this.typeColor);

    // horizontal timeline
    const x1 = this.x > this.anotherX.x ? this.anotherX.x : this.x,
      x2 = this.x > this.anotherX.x ? this.x : this.anotherX.x
    this.root.append("line")
      .attr("id", `time-line-${this.id}`)
      .attr("x1", x1 + ARROW_WIDTH)
      .attr("y1", this.ym)
      .attr("x2", x2 - ARROW_WIDTH)
      .attr("y2", this.ym)
      .attr("stroke", this.typeColor)
      .attr("stroke-width", 2)
      .attr("marker-start", `url(#arrow-${this.type}-reversed)`)
      .attr("marker-end", `url(#arrow-${this.type})`);

    // dividing line
    this.root.append("line")
      .attr("id", `dividing-line-${this.id}`)
      .attr("x1", this.x)
      .attr("y1", this.ym - DIVID_SPACE)
      .attr("x2", this.x)
      .attr("y2", this.ym + DIVID_SPACE)
      .attr("stroke", this.typeColor)
      .attr("stroke-width", 2);

    // another dividing line
    this.root.append("line")
      .attr("id", `dividing-line-${this.id}-another`)
      .attr("x1", this.anotherX.x)
      .attr("y1", this.ym - DIVID_SPACE)
      .attr("x2", this.x)
      .attr("y2", this.ym + DIVID_SPACE)
      .attr("stroke", this.typeColor)
      .attr("stroke-width", 2);

    const title = this.getTypeTitle(this.type, this.usage);
    const width = getTextWidth(title, 11, false, 100)
    const textX = x1 + 0.5 * (x2 - x1) - 0.5 * width;
    const db = this.root.append('g')
      .attr('class', 'mark-node-box')
      .attr('x', textX)
      .attr('y', this.ym + TIME_DISTANCE)
      .on('click', function (event) {
        event && event.stopPropagation();
      })
      .on('mouseenter', () => {
        this.root.select(`#remove-icon-${this.id}`).attr('visibility', 'visible');
      })
      .on('mouseleave', () => {
        this.root.select(`#remove-icon-${this.id}`).attr('visibility', 'hidden');
      })

    // add rect
    db.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', textX)
      .attr('y', this.ym + TIME_DISTANCE)
      .attr('fill', '#e9e9e900')
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(title)
      .attr('id', `title-${this.id}`)
      .attr('x', textX)
      .attr('y', this.ym + TIME_DISTANCE)
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.typeColor)
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(`X`)
      .attr('id', `remove-icon-${this.id}`)
      .attr('class', 'curve-box-close')
      .attr('visibility', 'hidden')
      .attr('x', textX + width)
      .attr('y', this.ym + TIME_DISTANCE)
      .on('click', (event) => {
        removeNode(this.hashId);
        event && event.stopPropagation();
      });
  }

  drawMeasure(root, removeNode) {
    this.root = root.append('g')
      .attr('id', 'curve-mark-node-' + this.id);
    this.x = 0;
    // v line
    this.root.append("line")
      .attr("id", `v-line-${this.id}`)
      .attr("x1", this.x)
      .attr("y1", this.ym)
      .attr("x2", this.x + this.width)
      .attr("y2", this.ym)
      .attr("stroke", this.typeColor)
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "4,4")

    const title = [this.type, ...(this.extra ? this.extra.sameType || [] : [])].map(type => this.getTypeTitle(type, this.usage)).join(', ');
    const db = this.root.append('g')
      .attr('class', 'mark-node-box')
      .attr('x', V_SPACE + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)
      .on('click', function (event) {
        event && event.stopPropagation();
      })
      .on('mouseenter', () => {
        this.root.select(`#remove-icon-${this.id}`).attr('visibility', 'visible');
      })
      .on('mouseleave', () => {
        this.root.select(`#remove-icon-${this.id}`).attr('visibility', 'hidden');
      })

    // add rect
    db.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', V_SPACE + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)
      .attr('fill', '#e9e9e900')
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(`${title} ${this.yValue}V`)
      .attr('id', `title-${this.id}`)
      .attr('x', V_SPACE + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.typeColor)
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    const width = getTextWidth(`${title} ${this.yValue}V`, 11, false, 100)
    db.append('text')
      .text(`X`)
      .attr('id', `remove-icon-${this.id}`)
      .attr('class', 'curve-box-close')
      .attr('visibility', 'hidden')
      .attr('x', V_SPACE + width + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)
      .on('click', (event) => {
        removeNode(this.hashId);
        event && event.stopPropagation();
      });
  }

  drawShootLine(root, removeNode) {
    this.root = root.append('g')
      .attr('id', 'curve-mark-node-' + this.id);

    let defs = this.root.append("defs");

    let arrowMarker = defs.append("marker")
      .attr("id", `arrow-${this.type}`)
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", "7")
      .attr("markerHeight", "7")
      .attr("viewBox", "0 0 12 12")
      .attr('refX', '6')
      .attr('refY', '6')
      .attr("orient", "auto");

    let arrow_path = "M2,2 L10,6 L2,10 L6,6 L2,2";

    arrowMarker.append("path")
      .attr("d", arrow_path)
      .attr("fill", this.typeColor);

    // reverse arrow
    let reverseArrowMarker = defs.append("marker")
      .attr("id", `arrow-${this.type}-reversed`)
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", "7")
      .attr("markerHeight", "7")
      .attr("viewBox", "0 0 12 12")
      .attr('refX', '6')
      .attr('refY', '6')
      .attr("orient", "auto");

    let reverse_arrow_path = "M10,2 L2,6 L10,10 L6,6 L10,2";

    reverseArrowMarker.append("path")
      .attr("d", reverse_arrow_path)
      .attr("fill", this.typeColor);

    const y = this.y, _y = this.anotherY.y;
    const y1 = y > _y ? y : _y, y2 = y < _y ? y : _y;
    let _SHOOT_DISTANCE = SHOOT_DISTANCE;
    if ((this.type === UNDERSHOOT && this.extra.timeType === RISING) || (this.type === OVERSHOOT && this.extra.timeType === FALLING)) {
      _SHOOT_DISTANCE = 0 - _SHOOT_DISTANCE
    }
    // horizontal timeline
    this.root.append("line")
      .attr("id", `top-line-${this.id}`)
      .attr("x1", this.x + _SHOOT_DISTANCE - 50)
      .attr("y1", y1)
      .attr("x2", this.x + _SHOOT_DISTANCE + 50)
      .attr("y2", y1)
      .attr("stroke", this.typeColor)
      .attr("stroke-dasharray", "5,5")
      .attr("stroke-width", 2);

    this.root.append("line")
      .attr("id", `bottom-line-${this.id}`)
      .attr("x1", this.x + _SHOOT_DISTANCE - 50)
      .attr("y1", y2)
      .attr("x2", this.x + _SHOOT_DISTANCE + 50)
      .attr("y2", y2)
      .attr("stroke", this.typeColor)
      .attr("stroke-dasharray", "5,5")
      .attr("stroke-width", 2);

    // arrow line
    this.root.append("line")
      .attr("id", `arrow-top-line-${this.id}`)
      .attr("x1", this.x)
      .attr("y1", y1 - ARROW_WIDTH)
      .attr("x2", this.x)
      .attr("y2", y2 + ARROW_WIDTH)
      .attr("stroke", this.typeColor)
      .attr("stroke-width", 2)
      .attr("marker-start", `url(#arrow-${this.type}-reversed)`)
      .attr("marker-end", `url(#arrow-${this.type})`);


    const title = this.getTypeTitle(this.type, this.usage);
    const textX = this.x + _SHOOT_DISTANCE + 10;
    const width = getTextWidth(title, 11, false, 100)
    const db = this.root.append('g')
      .attr('class', 'mark-node-box')
      .attr('x', textX)
      .attr('y', y2 + 0.5 * (y1 - y2))
      .on('click', function (event) {
        event && event.stopPropagation();
      })
      .on('mouseenter', () => {
        this.root.select(`#remove-icon-${this.id}`).attr('visibility', 'visible');
      })
      .on('mouseleave', () => {
        this.root.select(`#remove-icon-${this.id}`).attr('visibility', 'hidden');
      })

    // add rect
    db.append('rect')
      .attr('class', 'curve-mark-rect')
      .attr('x', textX)
      .attr('y', y2 + 0.5 * (y1 - y2))
      .attr('fill', '#e9e9e900')
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(title)
      .attr('id', `title-${this.id}`)
      .attr('x', textX)
      .attr('y', y2 + 0.5 * (y1 - y2))
      .attr('stroke-width', 1)
      .style('font-size', '11px')
      .style('font-weight', 100)
      .attr('stroke', this.typeColor)
      .on('click', function (event) {
        event && event.stopPropagation();
      });

    db.append('text')
      .text(`X`)
      .attr('id', `remove-icon-${this.id}`)
      .attr('class', 'curve-box-close')
      .attr('visibility', 'hidden')
      .attr('x', textX + width)
      .attr('y', y2 + 0.5 * (y1 - y2))
      .on('click', (event) => {
        removeNode(this.hashId);
        event && event.stopPropagation();
      });
  }

  refreshNode(padding) {

    const x = this.getXValue(this.targetX) + padding.left;
    const y = this.getYValue(this.targetY) + padding.top;
    this.x = x;
    this.ym = y;

    this.root.select('line')
      .attr("x1", this.x)
      .attr("y1", this.type === RISING ? this.ym + 4 : this.ym - 4)
      .attr("x2", this.x)
      .attr("y2", this.type === RISING ? this.ym - 2 : this.ym + 2)

    const textBoxX = this.x + 5;
    const textBoxY = this.ym - 5;

    const markBox = this.root.select('.mark-node-box')
      .attr('x', textBoxX)
      .attr('y', textBoxY)

    markBox.select('rect')
      .attr('x', this.x + 5)
      .attr('y', this.ym - 15)

    markBox.select('text')
      .attr('x', this.x + 7)
      .attr('y', this.ym - 11)
  }

  refreshTimeLine(padding) {
    const x = this.getXValue(this.targetX) + padding.left;
    const y = this.getYValue(this.targetY) + padding.top;
    const anotherX = this.getXValue(this.anotherX.targetX) + padding.left;
    this.x = x;
    this.ym = y;
    this.anotherX = { ...this.anotherX, x: anotherX }

    const x1 = this.x > this.anotherX.x ? this.anotherX.x : this.x,
      x2 = this.x > this.anotherX.x ? this.x : this.anotherX.x
    this.root.select(`#time-line-${this.id}`)
      .attr("x1", x1 + ARROW_WIDTH)
      .attr("y1", this.ym)
      .attr("x2", x2 - ARROW_WIDTH)
      .attr("y2", this.ym)

    this.root.select(`#dividing-line-${this.id}`)
      .attr("x1", this.x)
      .attr("y1", this.ym - DIVID_SPACE)
      .attr("x2", this.x)
      .attr("y2", this.ym + DIVID_SPACE);

    this.root.select(`#dividing-line-${this.id}-another`)
      .attr("x1", this.anotherX.x)
      .attr("y1", this.ym - DIVID_SPACE)
      .attr("x2", this.anotherX.x)
      .attr("y2", this.ym + DIVID_SPACE);

    const title = this.getTypeTitle(this.type, this.usage);
    const width = getTextWidth(title, 11, false, 100)
    const textX = x1 + 0.5 * (x2 - x1) - 0.5 * width;
    const textBoxY = this.ym + TIME_DISTANCE;

    const markBox = this.root.select('.mark-node-box')
      .attr('x', textX)
      .attr('y', textBoxY)

    markBox.select('rect')
      .attr('x', textX)
      .attr('y', textBoxY)

    markBox.select(`#title-${this.id}`)
      .attr('x', textX)
      .attr('y', textBoxY)

    markBox.select(`#remove-icon-${this.id}`)
      .attr('x', textX + width)
      .attr('y', textBoxY)
  }

  refreshMeasure(padding, height, yMin, yMax) {
    const x = 0 + padding.left;
    this.x = x;
    let dy = yMax - yMin;
    const y = height - ((this.yValue - yMin) / dy * height)
    this.ym = y + padding.top;
    this.root.select(`#v-line-${this.id}`)
      .attr("x1", this.x)
      .attr("y1", this.ym)
      .attr("x2", this.x + this.width)
      .attr("y2", this.ym)

    const title = [this.type, ...(this.extra ? this.extra.sameType || [] : [])].map(type => this.getTypeTitle(type, this.usage)).join(', ');
    const width = getTextWidth(`${title} ${this.yValue}V`, 11, false, 100)

    const markBox = this.root.select('.mark-node-box')
      .attr('x', this.x + INDENT)
      .attr('y', this.ym)

    markBox.select('rect')
      .attr('x', this.x + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)

    markBox.select(`#title-${this.id}`)
      .attr('x', this.x + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)

    markBox.select(`#remove-icon-${this.id}`)
      .attr('x', this.x + width + INDENT)
      .attr('y', this.ym + TIME_DISTANCE)
  }

  refreshShoot(padding, height, yMin, yMax) {
    const x = this.getXValue(this.targetX) + padding.left;
    this.x = x;
    let dy = yMax - yMin;
    const y = height - ((this.yValue - yMin) / dy * height)
    this.ym = y + padding.top;
    const anotherY = height - ((this.anotherY.yValue - yMin) / dy * height);
    this.anotherY.y = anotherY + padding.top;
    const ym = this.ym, _y = this.anotherY.y;
    const y1 = ym > _y ? ym : _y, y2 = ym < _y ? ym : _y;
    const shoot = this.type === OVERSHOOT ? this.yValue - this.anotherY.yValue : this.anotherY.yValue - this.yValue
    const display = shoot > ((1 / 40) * dy) ? 'visible' : 'hidden';
    let _SHOOT_DISTANCE = SHOOT_DISTANCE;
    if ((this.type === UNDERSHOOT && this.extra.timeType === RISING) || (this.type === OVERSHOOT && this.extra.timeType === FALLING)) {
      _SHOOT_DISTANCE = 0 - _SHOOT_DISTANCE
    }

    // horizontal timeline
    this.root.select(`#top-line-${this.id}`)
      .attr("x1", this.x + _SHOOT_DISTANCE - 50)
      .attr("y1", y1)
      .attr("x2", this.x + _SHOOT_DISTANCE + 50)
      .attr("y2", y1)
      .attr("visibility", display)

    this.root.select(`#bottom-line-${this.id}`)
      .attr("x1", this.x + _SHOOT_DISTANCE - 50)
      .attr("y1", y2)
      .attr("x2", this.x + _SHOOT_DISTANCE + 50)
      .attr("y2", y2)
      .attr("visibility", display)

    // arrow line
    this.root.select(`#arrow-top-line-${this.id}`)
      .attr("x1", this.x + _SHOOT_DISTANCE)
      .attr("y1", y1 - ARROW_WIDTH)
      .attr("x2", this.x + _SHOOT_DISTANCE)
      .attr("y2", y2 + ARROW_WIDTH)
      .attr("visibility", display)

    const title = this.getTypeTitle(this.type, this.usage);
    const textX = this.x + _SHOOT_DISTANCE + 10;
    const width = getTextWidth(title, 11, false, 100)

    const markBox = this.root.select('.mark-node-box')
      .attr('x', textX)
      .attr('y', this.ym)
      .attr("visibility", display)

    markBox.select('rect')
      .attr('x', textX)
      .attr('y', y2 + 0.5 * (y1 - y2))

    markBox.select(`#title-${this.id}`)
      .attr('x', textX)
      .attr('y', y2 + 0.5 * (y1 - y2))

    markBox.select(`#remove-icon-${this.id}`)
      .attr('x', textX + width)
      .attr('y', y2 + 0.5 * (y1 - y2))
  }

  getTypeColor(type, color) {
    switch (type) {
      case SETUP:
        return SETUP_COLOR
      case HOLD:
        return HOLD_COLOR
      case HIGH:
        return HIGH_COLOR;
      case LOW:
        return LOW_COLOR;
      case VHIGH:
      case VLOW:
      case VINH:
      case VINL:
        return V_COLOR;
      case OVERSHOOT:
      case UNDERSHOOT:
        return SHOOT_COLOR;
      case FALLING:
      case RISING:
      default:
        return color;
    }
  }

  getTypeTitle(type, usage) {
    switch (type) {
      case SETUP:
        return 'Setup Time'
      case HOLD:
        return 'Hold Time'
      case HIGH:
        return 'High Time'
      case LOW:
        return 'Low Time'
      case VHIGH:
        return 'Vhigh'
      case VLOW:
        return 'Vlow'
      case VINH:
        return usage === "Driver" ? "VOH" : 'VIH'
      case VINL:
        return usage === "Driver" ? "VOL" : 'VIL'
      case OVERSHOOT:
        return 'Overshoot'
      case UNDERSHOOT:
        return 'Undershoot'
      case FALLING:
      case RISING:
      default:
        return '';
    }
  }
}

export default MarkNode;