import React, { Component, Fragment } from 'react';
import AdvancePanel from './AdvancePanel';
import DebugButton from './debugButton';

class Advance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  openPanel = (e) => {
    e.stopPropagation();
    this.setState({
      visible: true
    })
  }

  closeModal = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { visible } = this.state;
    const { extraction } = this.props;
    return <Fragment>
      <div className='andes-advance'>
        <span className='andes-advance-title'>Advanced</span>
        <div className='andes-advance-content'>
         {/*  <span className='andes-advance-option'>Extraction options</span>
          <Icon className='andes-extraction-icon' type='setting' onClick={(e) => { this.openPanel(e) }} /> */}
          <span className='andes-advance-option'>Debug</span>
          <DebugButton />
        </div>
      </div>
      {visible && <AdvancePanel
        closeModal={this.closeModal}
        saveExtraction={this.props.saveExtraction}
        extraction={extraction}
      />}
    </Fragment>
  }
}

export default Advance;