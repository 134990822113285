const TYPE = 'SIERRA';
export const START_SIERRA_VERIFICATION = `${TYPE}/start_sierra_verification`;
export const CHANGE_VERIFICATION_LIST = `${TYPE}/change_verification_list`;
export const UPDATE_SIERRA_PROGRESS = `${TYPE}/update_sierra_progress`;
export const GET_SINGLE_MONITOR = `${TYPE}/get_single_monitor`;
export const CLEAN_SINGLE_PROGRESS = `${TYPE}/clean_single_progress`;
export const UPDATE_SINGLE_MONITOR = `${TYPE}/update_single_monitor`;
export const CLEAN_MONITOR = `${TYPE}/clean_monitor`;
export const CHANGE_UPLOAD_MES = `${TYPE}/change_upload_mes`;
export const DEBUG_VERIFY = `${TYPE}/debug_verify`;
export const RESULT_EXIST = `${TYPE}/result_exist`;
export const VERIFICATION_FLOW = `${TYPE}/verification_flow`;
export const SAVE_CURRENT_LOG = `${TYPE}/save_current_log`;
export const GET_CURRENT_LOG = `${TYPE}/get_current_log`;
export const WAITING_INDEX = `${TYPE}/waiting_index`;
export const UPDATE_ERROR_CHECK_LIST = `${TYPE}/update_error_check_list`;
export const KILL_WORKFLOW = `${TYPE}/kill_workflow`;
export const UPDATE_PRE_PROCESS_LOG = `${TYPE}/update_pre_progress_log`;
export const GET_INTERFACE_MONITOR = `${TYPE}/get_interface_monitor`;
export const CANCEL_VERIFICATION_WORKFLOW = `${TYPE}/cancel_verification_workflow`;
export const GET_VERIFICATION_MONITOR = `${TYPE}/get_verification_monitor`;
export const UPDATE_START_MSG = `${TYPE}/update_start_msg`;
export const UPDATE_END_MSG = `${TYPE}/update_end_msg`;
export const UPDATE_SIMULATION_MESSAGE = `${TYPE}/update_simulation_message`;
export const GET_CURRENT_PROFILE = `${TYPE}/get_current_profile`;
export const SAVE_CURRENT_PROFILE = `${TYPE}/save_current_profile`;
export const GET_EXPERIMENT_LOG = `${TYPE}/get_experiment_log`;
export const UPDATE_EXPERIMENT_QUEUE = `${TYPE}/update_experiment_queue`;
export const SAVE_EXPERIMENT_QUEUE = `${TYPE}/save_experiment_queue`;
export const CANCEL_EXPERIMENT = `${TYPE}/cancel_experiment`;
export const UPDATE_DESIGN_STACKUP_ERROR_MESSAGE = `${TYPE}/update_design_stackup_error_message`;
export const UPDATE_CONNECTOR_ERROR = `${TYPE}/update_connector_error`;
export const RUN_MULTI_EXPERIMENT_SIM = `${TYPE}/run_multi_experiment_sim`;