import React, { Component } from "react";
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getPanelWidth, getPanelMaxHeight, getPanelMaxWidth } from "@/services/helper/panelSizeHelper";
import DebugDownloadContent from '@/components/DebugDownload_v2';
import '@/publicCss/style.css';
import '@/publicCss/debug.css';
import { EXPERIMENT_S_PARAMETER_DOWNLOAD } from "../../../services/helper/debugHelper";
import sweepConstructor from "../../../services/Andes_v2/sweep/sweepConstructor";
import { getExperimentsBySweepIdPromise } from "../../../services/Andes_v2/sweep";
import { VERIFY_SUCCESS } from "../../../constants/verificationStatus";
import { ANDES_V2 } from "../../../constants/pageType";

class SParameterModelDownload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadMenuList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 384),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.setState({
      downloadMenuList: this.getData()
    })
    this.resize();
  }

  getData = () => {
    const { downloadData, sweepId } = this.props;
    const { downloadList, currentCheckValues } = this._getDownloadList(sweepId, downloadData);
    return [{
      title: "Experiment",
      key: EXPERIMENT_S_PARAMETER_DOWNLOAD,
      menuType: EXPERIMENT_S_PARAMETER_DOWNLOAD,
      info: {
        notSelected: true
      },
      enabledChildren: true,
      downloadList,
      currentCheckValues,
      expandChange: this.expandChange
    }]
  }


  _getDownloadList = (sweepId, downloadData) => {
    const children = downloadData.map(item => ({
      name: item.name,
      default: true,
      option: item.id
    }))
    const sweep = sweepConstructor.getSweep(sweepId);
    if (!sweep) {
      return { downloadList: [], currentCheckValues: [] }
    }
    // get sweep list by channelId
    const sweeps = sweepConstructor.getSweepValues(sweep.channelId);
    const downloadList = sweeps.map(sweep => {
      if (sweep.id === sweepId) {
        return {
          name: sweep.name,
          default: true,
          option: sweep.id,
          children,
          // Check whether to obtain the experiment
          isUpdate: false
        }
      }
      return {
        name: sweep.name,
        default: true,
        option: sweep.id,
        children: [],
        isUpdate: true
      }
    })
    const currentCheckValues = [];
    return { downloadList, currentCheckValues }
  }

  getDownloadURL = ({ experimentIds, token }) => {
    const experimentIdStr = 'experimentIds=' + experimentIds.join('&experimentIds=')
    return `/andes/simulation/sweep-channel/touchstones?${experimentIdStr}`;
  }

  // getHeight = () => {
  // 	const content = document.getElementById("s-parameter-download-content-id");

  // 	if (content) {
  // 		const height = content.offsetHeight + 70 || 336;
  // 		return height > 336 ? height : 336;
  // 	}
  // 	return 336;
  // }

  expandChange = (option, variablesTables) => {
    const { downloadMenuList } = this.state;
    const { downloadList } = downloadMenuList[0];
    let _downloadList = [...downloadList];
    const expandIndex = _downloadList.findIndex(item => item.option === option);
    if (expandIndex > -1) {
      const children = variablesTables.filter(item => item.status === VERIFY_SUCCESS).map((it) => ({
        name: it.name,
        default: true,
        option: it.id
      }))
      _downloadList[expandIndex].children = children;
      _downloadList[expandIndex].isUpdate = false;
      this.setState({ downloadMenuList: [{ ...downloadMenuList[0], downloadList: _downloadList }] })
    }
    return _downloadList;
  }

  render() {
    const { maxWidth, maxHeight, downloadMenuList } = this.state;
    // const height = this.getHeight();
    const content = (
      <Panel
        className='aurora-debug-panel andes-v2-s-parameter-download-panel panel-x-scroll-hidden'
        title={<div className='aurora-debug-title'>Touchstone Download</div>}
        onCancel={this.props.closePanel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 450 })}
        position='panel-center'
        draggable
        minWidth={450}
        maxHeight={maxHeight}
        // height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <div id="s-parameter-download-content-id">
          <DebugDownloadContent
            product={ANDES_V2}
            debugMenuList={downloadMenuList}
            getDownloadList={this._getDownloadList}
            getDownloadSimulationURL={this.getDownloadURL}
            getExperimentsBySweepIdPromise={getExperimentsBySweepIdPromise}
          />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default SParameterModelDownload;