import { getResultFileInAndes, getResultHistoryList, saveCurrentAndesResult, deleteResultHistory } from '../../api/Andes';
import WaveformResultHelper from './WaveformResultHelper';
import { getResultsCurveInAndesPromise } from '../projectCtrl';
import apiProcess from '../../api/utility';

function getWaveform({ projectId, verificationId, designId, resultKey, type, time }) {
  let waveform = new WaveformResultHelper(type, time);
  let path = `result/${designId}_${resultKey.toLowerCase()}.raw`;
  if (type === 'history') {
    path = `history/${time}/${path}`;
  }
  return new Promise((resolve, reject) => {
    getResultFileInAndes({ projectId, verificationId, path }).then((res) => {
      // get file success, parse the curve file
      waveform.parseRawCurve(res.data);
      resolve(waveform);
    }, (error) => {
      console.error(error)
      resolve(waveform);
    })
  });
}

export function getSBRTDR(verificationId, projectId, designId, type, time, resultKey) {
  return getWaveform({ projectId, verificationId, designId, resultKey, type, time });
}

/**
 * Exports a component information array list
 * 
 * @param {Array} curves - [{ curve }]
 * @exports compInfo - color, component, curveIndex, curveName, signal, usage
 */
export function getComponentInfo(curves, time) {
  function matchSignal(name) {
    if (!name) return null;
    let match = name.match(/(\S*)_(Controller|Device)_(\S*)/);
    if (match && match.length > 3) {
      return {
        signal: match[1],
        usage: match[2],
        component: match[3]
      }
    } else {
      return null;
    }
  }
  const compInfo = curves.map((curve, i) => {
    const { name, color } = curve;
    let item = {
      curveName: name,
      curveIndex: i,
      color,
      time,
      curveId: `${time}-${i}`
    }
    const matchSignalName = matchSignal(name);
    if (matchSignalName) {
      const { signal, usage, component } = matchSignalName;
      item = { ...item, signal, usage, component };
    } else {
      item = { ...item, signal: name };
    }
    return item;
  });
  return compInfo;
};

/**
 * get andes delay data
 * 
 * @param 
 * @exports Data: [ [ { signalName, Delay, PNSkew } ] ], unit: s,ps,ns
 */
export function getDelayData({ projectId, verificationId, designId, time }) {
  let path = `result/${designId}_delay.txt`;
  if (time !== 'current') {
    path = `history/${time}/result/${designId}_delay.txt`;
  }
  return new Promise((resolve, reject) => {
    getResultFileInAndes({ projectId, verificationId, path }).then((response) => {
      let data = parseFile(response.data);
      let { dataList, unit } = getDataList(data);
      resolve({ Data: dataList, /* tableTitle, */ unit })
    }, (error) => {
      reject(null);
    }
    );
  })
}

export function parseFile(fileContent) {
  let data = fileContent.match(/[^\r\n]+/g);
  return data.map(function (item) {
    if (item.indexOf(' ') > -1 && item.indexOf('\t') < 0) {
      return item.trim().split(' ');
    } else {
      return item.trim().split('\t')
    }
  });
}

export function getDataList(data, pair, components) {
  let dataList = [], unit = null, v2 = false;
  if (!data.length) {
    return { dataList, unit, v2 };
  }
  if (data[0][0].match(/^#signal/)) {
    v2 = true;
    const parse = delayParseV2(data, pair, components);
    dataList = parse.dataList;
    unit = parse.unit;
  } else {
    data.forEach(item => {
      let [signal, delay, pn, _unit] = item;
      dataList.push(new Delay(signal, delay, pn));
      unit = _unit;
    })
  }
  return { dataList, unit, v2 };
}

function getPinByComp(compName1, pin1, pin2, components) {
  const comPins = (components.find(item => item.name === compName1) || { pins: [] }).pins;
  const net_1 = (comPins.find(item => item.pin === pin1) || {}).net;
  const net_2 = (comPins.find(item => item.pin === pin2) || {}).net;

  return { net_1, net_2 };
}

function delayParseV2(data, pair, components) {
  let unit = null, dataList = [];
  for (let i = 1; i < data.length; i++) {
    let [signal_comp_pin, diff_delay, pos_delay, neg_delay, skew, _unit] = data[i];
    const [signal, comp_1, p_pin_1, n_pin_1, comp_2, p_pin_2, n_pin_2] = signal_comp_pin.split(',');
    unit = _unit;
    const _diff_delay = diff_delay.split(',')[0],
      _pos_delay = pos_delay.split(',')[0],
      _neg_delay = neg_delay.split(',')[0],
      _skew = skew.split(',')[0]
    let net_1 = '', net_2 = '';
    if (pair) {
      const nets = getPinByComp(comp_1, p_pin_1, n_pin_1, components);
      net_1 = nets.net_1;
      net_2 = nets.net_2;
    }
    dataList.push(new DelayV2({
      signal: pair ? `${signal} (${pair})` : signal,
      comp_1,
      comp_1_pin: p_pin_1,
      comp_2,
      comp_2_pin: p_pin_2,
      diff_delay: _diff_delay,
      delay: _pos_delay,
      skew: _skew,
      index: (i - 1) * 2,
      className: i === data.length - 1 && !pair && "border-bottom-0",
      sigType: "positive",
      net: net_1
    }))
    dataList.push(new DelayV2({
      signal: pair ? `${signal} (${pair})` : signal,
      comp_1,
      comp_1_pin: n_pin_1,
      comp_2,
      comp_2_pin: n_pin_2,
      diff_delay: _diff_delay,
      delay: _neg_delay,
      skew: _skew,
      index: (i - 1) * 2 + 1,
      sigType: "negative",
      net: net_2
    }))
  }

  return { dataList, unit }
}

function DelayV2({ signal, comp_1, comp_1_pin, comp_2_pin, comp_2, diff_delay, delay, skew, index, className, sigType, net }) {
  this.signal = signal;
  this.comp1 = {
    signal: comp_1,
    pin: comp_1_pin
  }
  this.comp2 = {
    signal: comp_2,
    pin: comp_2_pin
  }
  this.diff_delay = diff_delay;
  this.delay = delay;
  this.skew = skew;
  this.index = index;
  this.className = className || "";
  this.sigType = sigType || "";
  this.net = net;
}

function Delay(signal, delay, pn, unit) {
  this.SignalName = signal;
  this.Delay = delay;
  this.PNSkew = pn || null;
  this.unit = unit;
}

export function getVerificationJson(verificationId, projectId) {
  let path = 'verification.json'
  return new Promise((resolve, reject) => {
    getResultFileInAndes({ projectId, verificationId, path }).then((res) => {
      resolve(res.data)
    }, (error) => {
      reject(null);
    })
  })
}

export function convertArrayOfObjectsToCSV(args) {
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data === null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';
  let type = '';
  switch (args.currentResultKey) {
    case 'insertionLoss': type = 'Insertion Loss'; break;
    case 'diffToComm': type = 'Differential to Common Mode'; break;
    case 'returnLoss': type = 'Return Loss'; break;
    default: return;
  }
  keys = Object.keys(data[0]);
  result = `Type: ${type} Interface Name: ${args.interface} PCB: ${args.pcb}${lineDelimiter}`;
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
};

export function getCurveData(file, currentResultKey) {
  let titleNames = [];
  if (currentResultKey === 'returnLoss' || currentResultKey === 'insertionLoss' || currentResultKey === 'diffToComm') {
    for (const fileNetList of file.netList) {
      const ports = fileNetList.ports, signal = fileNetList.signal;
      for (const portNumber of ports) {
        const portIndex = portNumber - 1;
        const port = file.ports[portIndex];
        for (const matrix of ports) {
          const matrixIndex = matrix - 1;
          const _matrix = file.matrix[portIndex][matrixIndex];
          if (_matrix.display) {
            let name = `S(${portNumber}:${matrix})_${signal}_${port.compType}_${port.comp}`;
            if (currentResultKey === 'insertionLoss' || currentResultKey === 'diffToComm') {
              name = `S(${portNumber}:${matrix})_${signal}_(${port.compType}_${port.comp}:${file.ports[matrixIndex].compType}_${file.ports[matrixIndex].comp})`
            }
            titleNames.push({
              name,
              dbCurve: _matrix.sdb || [],
              phCurve: [],
              col: matrixIndex,
              row: portIndex
            })
          }
        }
      }
    }
  }
  return titleNames;
}

export function generateCSVData(file, verificationId, currentResultKey, time) {
  let titleNames = getCurveData(file, currentResultKey);
  let samPath = `result/${file.pcbId}_mm.sam`, sphPath = `result/${file.pcbId}_mm.sph`;
  if (time) {
    samPath = `history/${time}/${samPath}`;
    sphPath = `history/${time}/${sphPath}`;
  }
  const promiseList = titleNames.map((item) => {
    return new Promise((resolve, reject) => {
      getResultsCurveInAndesPromise({ verificationId, cols: [item.col], rows: [item.row], path: samPath }).then((response) => {
        getResultsCurveInAndesPromise({ verificationId, cols: [item.col], rows: [item.row], path: sphPath }).then((res) => {
          item.dbCurve = response[0].curve || [];
          item.phCurve = res[0].curve || [];
          resolve(item);
        })
      })
    })
  });
  return Promise.all(promiseList);
}

/** Get  result hitory list in andes 
* 2020/04/02
* @param {string} verificationId
*/
export function getAndesResultList(verificationId) {
  return new Promise((resolve, reject) => {
    apiProcess(getResultHistoryList, verificationId).then((response) => {
      resolve(response)
    }, error => {
      reject(error)
    })
  })
}

export function getAndesResultHistoryList(verificationId) {
  return new Promise((resolve) => {
    getAndesResultList(verificationId).then(res => {
      const historyList = res && res.history && res.history.length ?
        [...res.history.map(item => ({ ...item, verificationId, show: false }))] :
        [];
      resolve(historyList);
    })
  })
}

/** Get  result hitory json in andes by time
* 2020/04/02
* @param {string} verificationId
* @param {string} projectId
* @param {string} time  result time
*/
export function getHistoryJson(verificationId, projectId, time) {
  let path = `history/${time}/verification.json`
  return new Promise((resolve, reject) => {
    getResultFileInAndes({ projectId, verificationId, path }).then((res) => {
      resolve(res.data)
    }, (error) => {
      reject(null);
    })
  })
}

/**
 * save andes current result
 * 2020/04/02
 * @param verificationId
 * @param resultName
 * @export
 * @returns Promise
 */
export function saveAndesResult(verificationId, resultName) {
  return new Promise((resolve, reject) => {
    apiProcess(saveCurrentAndesResult, { verificationId, resultName }).then(res => {
      resolve(res)
    }, error => {
      reject(error);
    })
  });
}

/**
 * delele andes result history
 *
 * @param verificationId
 * @param dataTime
 * @export
 * @returns Promise
 */
export function delResultHistory(verificationId, dataTime) {
  return new Promise((resolve, reject) => {
    apiProcess(deleteResultHistory, { verificationId, dataTime }).then(res => {
      resolve(res);
    }, error => {
      reject(error)
    })
  });
}