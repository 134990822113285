import React, { Component, Fragment, createRef } from 'react';
import Parameter from '../../../../services/Cascade/helper/Sparameter/Parameter';
import { drawPlot } from '@/services/Cascade/helper/Sparameter/PlotCtrl';
import { getImpTargetName } from '../../../../services/Cascade/Impedance';
import hash from 'object-hash';
import '../index.css';

class TargetPlot extends Component {

  constructor(props) {
    super(props);
    const { targetName, record, targetType, PowerNets } = props;
    const name = getImpTargetName({
      targetName,
      record,
      targetType,
      PowerNets,
    })
    this.state = {
      targetName: name
    }
    this.svgRef = createRef();
    this.param = new Parameter({ id: 'target' });
    this.plot2d = null;
  }

  componentDidMount() {
    this.initPlot();
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { width, height, points, x, y } = this.props;
    if (width !== prevProps.width || height !== prevProps.height || x !== prevProps.x || y !== prevProps.y) {
      this.draw();
    }

    if (points && hash(points) !== hash(prevProps.points)) {
      this.initPlot()
    }
  }

  initPlot = () => {
    const points = this.props.points;
    let frequency = [], impedance = [];
    for (let point of points) {
      frequency.push(Number(point.frequency))
      impedance.push(Number(point.impedance))
    }
    const data = [{
      id: 'target-main',
      frequency,
      impedance
    }]

    this.param.importData(data);

    this.draw();
  }

  draw = () => {
    this.param.cleanPlotViewer();
    this.plot2d = this.param.createPlot(this.svgRef.current);
    drawPlot(this.param, this.plot2d, 'target');
  }

  render() {
    const { showType, width = '800px', height = '400px' } = this.props;
    let _width = Number(width.replace('px', '')) / 2 - 8;
    let _height = Number(height.replace('px', ''))
    return <Fragment>
      <svg ref={this.svgRef} id={showType} width={_width} height={_height}></svg>
    </Fragment>
  }
}

export default TargetPlot;