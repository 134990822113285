import { draw } from "../../workers/Cascade/drawPowerTree.worker";

class TreePlot {

  constructor(props) {
    const { id, width, height, branch, deep, groundNet, root, columns, pcbIndex, connections, includeInd } = props;
    this.id = id;
    this.width = width;
    this.height = height;
    this.branch = branch;
    this.deep = deep;
    this.columns = columns;
    this.groundNet = groundNet;
    this.root = root;
    this.pcbIndex = pcbIndex || [];
    this.connections = connections || [];
    this.netName = [];
    this.includeInd = includeInd;
  }

  draw = async () => {
    const { id, width, height, branch, deep, groundNet, root, columns, pcbIndex, connections, includeInd } = this;
    let canvas = document.getElementById(id);
    if(!canvas) {
      return;
    }
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, width, height);
    const offsetCanvas = await draw({ id, width, height, branch, deep, groundNet, root, columns, pcbIndex, connections, includeInd });
    ctx.drawImage(offsetCanvas, 0, 0);
  }

  reDraw = ({ width, height, columns, includeInd, pcbIndex }) => {
    this.width = width;
    this.height = height;
    this.columns = columns;
    this.includeInd = includeInd;
    this.pcbIndex = pcbIndex;
  }

  clear = () => {
    const { width, height, id } = this;
    let canvas = document.getElementById(id);
    if(!canvas) {
      return;
    }
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, width, height);
    canvas.width = width;
  }
}

export default TreePlot;