import React from 'react';
import CCCMsg from '../../pages/CCC/CCCMsg';
import { DETAIL, MONITOR, PCB, PROFILE, SERVICE, LAYOUT } from '../../services/helper/tabSwitchHelper';
import { ANDES_V2 } from '../../constants/pageType';
import permissionData from '../../services/helper/data/permissionData';

function monitorChild({ menuType, tabVisible, tabSelectKeys,
  projectId, getCCCVerifications, getCurrentVerification,
  pageType,
  titleSelectedKeys,
  setupType,
  isGroup,
  projectVersion,
  width = undefined,
  displayBMAMonitor
},
  UploadMonitor,
  SimulationMonitor,
  DetailMonitor,
  ProfileTable,
  SpecifyService = null,
  LayoutMonitor = null,
  MultiSetupMonitor = null,
  BMAMonitor = null) {
  //pcb monitor (upload, pcb replace)
  const selectedKey = tabSelectKeys.length ? tabSelectKeys[0] : MONITOR;
  switch (selectedKey) {
    case MONITOR:
      if (pageType === ANDES_V2) {
        return <SimulationMonitor />;
      }
      if (menuType === "group") {
        return <MultiSetupMonitor />;
      }
      if (displayBMAMonitor) {
        return <BMAMonitor />
      }
      return menuType === 'upload' ? <UploadMonitor /> : <SimulationMonitor />;
    case PCB:
      return <UploadMonitor />;
    case DETAIL:
      return DetailMonitor ? <DetailMonitor width={width} /> : null;
    case PROFILE:
      return <ProfileTable />;
    case SERVICE:
      return SpecifyService ? <SpecifyService
        setupType={setupType}
        projectId={projectId}
        projectVersion={projectVersion}
        isGroup={isGroup}
        titleSelectedKeys={titleSelectedKeys}
        getCurrentVerification={getCurrentVerification}
        getCCCVerifications={getCCCVerifications}
      /> : null;
    case LAYOUT:
      return LayoutMonitor && <LayoutMonitor />
    default:
      return permissionData.hasCCC() ? <CCCMsg
        width={width}
        tabVisible={tabVisible}
        tabSelectKeys={tabSelectKeys}
      /> : null;
  }
}

export default monitorChild;