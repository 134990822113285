import { getCompPinSize } from "../Designs/helper";
import { BGA, IC } from "../../constants/componentType";
import { SOLDER } from "../Stackup/Material";

const CYLINDER = 'Cylinder', SPHEROID = 'Spheroid', NONE = 'None';

class BallSize {
  constructor(props) {
    const {
      bgaSize, bgaShape, bgaMiddle, bgaHeight,
      dieSize, dieShape, dieMiddle, dieHeight,
      DIE, BGA, designId
    } = props;

    this.bgaShape = bgaShape || SPHEROID;
    let pinSize = 0;
    if (!bgaSize || !bgaMiddle || !bgaHeight) {
      pinSize = getCompPinSize(BGA, designId, true);
    }
    this.bgaSize = bgaSize || `${Number((pinSize * 0.9).toFixed(2))}um`;
    this.bgaHeight = bgaHeight || `${Number((pinSize * 0.8).toFixed(2))}um`;
    this.bgaMiddle = bgaMiddle || `${Number((pinSize * 1.1).toFixed(2))}um`;

    this.dieShape = dieShape || CYLINDER;

    if (!dieSize || !dieMiddle || !dieHeight) {
      pinSize = getCompPinSize(DIE.length ? DIE[0] : null, designId, true);
    }
    this.dieSize = dieSize || `${Number((pinSize * 0.9).toFixed(2))}um`;
    this.dieHeight = dieHeight || `${Number((pinSize * 0.8).toFixed(2))}um`;
    this.dieMiddle = dieMiddle || `${Number((pinSize * 1.1).toFixed(2))}um`;
  }
}

class BallSizeMap {
  constructor(props) {
    const {
      ballSizeMap = {},
      comps, designId
    } = props;

    let _ballSizeMap = {}

    comps.forEach(comp => {
      const pinSize = getCompPinSize(comp, designId, true);
      const ballSizeInfo = ballSizeMap[comp.name]
      const _ballShape = comp.usage === BGA ? SPHEROID : (comp.usage === IC ? NONE : CYLINDER)
      _ballSizeMap[comp.name] = {
        ballShape: ballSizeInfo && ballSizeInfo.ballShape ? ballSizeInfo.ballShape : _ballShape,
        ballSize: ballSizeInfo && ballSizeInfo.ballSize ? ballSizeInfo.ballSize : `${Number((pinSize * 0.9).toFixed(2))}um`,
        ballHeight: ballSizeInfo && ballSizeInfo.ballHeight ? ballSizeInfo.ballHeight : `${Number((pinSize * 0.8).toFixed(2))}um`,
        ballMiddle: ballSizeInfo && ballSizeInfo.ballMiddle ? ballSizeInfo.ballMiddle : `${Number((pinSize * 1.1).toFixed(2))}um`,
        ballMaterial: ballSizeInfo && ballSizeInfo.ballMaterial ? ballSizeInfo.ballMaterial : SOLDER
      }
    })

    this.ballSizeMap = _ballSizeMap
  }

  getBallSizeMap() {
    return this.ballSizeMap
  }
}

export {
  CYLINDER,
  SPHEROID,
  NONE,
  BallSize,
  BallSizeMap
}