import { takeEvery, call, select, put, fork, delay, cancel } from 'redux-saga/effects';
import { message } from 'antd';
import {
  UPDATE_PROJECT_MENU,
  ADD_PROJECT,
  CREATE_PROJECT,
  CANCEL_CREATE_PROJECT,
  OPEN_PROJECT,
  CLOSE_PROJECT,
  DELETE_PROJECT,
  OPEN_PAGE,
  RENAME_CHANNEL,
  RENAME_PROJECT,
  CLEAN_TRASH,
  UPDATE_TRASH,
  SAVE_SERVICE_OPTION,
  SAVE_REPORT_CONFIG,
  GET_PROJECT_REPORT
} from "./actionTypes";
import {
  getProjectList,
  HIMALAYAS_PROJECT_INDEX,
  getDefaultProjectTree,
  createProjectPromise,
  projectItem,
  getProjectChildren,
  HIMALAYAS_PROJECT_VERSION,
  getProjectChildrenPromise,
  getSpiceCardTemplateTree,
  getIOSimTree,
  updateProjectChild,
  deleteProjectPromise,
  getSetupIndexAndType,
  updateChannelTree,
  channelRenamePromise,
  getProjectChildTitle,
  projectRenamePromise,
  cleanHimalayasTrash,
  getHimalayasTrashList,
  updateHimalayasServiceOptions,
  updateReportConfig,
  getReportGenerationStatus,
  getReportFile
} from '../../../../services/Himalayas';
import {
  updateExpand,
  updateTreeList,
  updateOpenedProjectInfo,
  clearProjectInfo,
  closeProject,
  updateSelectedKeys,
  updateViewList,
  updateTrash,
  updateReportInfo
} from './action';
import {
  PROJECT,
  PROJECTS,
  PROJECT_CREATE,
  SPICE_CARD_TEMPLATE,
  RESULT,
  IO_SIM,
  MY_TRASH,
  IO_SIM_GROUPS
} from '../../../../constants/treeConstants';
import himalayasProjects from '../../../../services/Himalayas/project/projectConstructor';
import { getDefaultName } from '../../../../services/helper/setDefaultName';
import {
  filterKeys,
  getViewListBySelectedKey,
  updateTreeSelectKeys
} from '../../../../services/helper/filterHelper';
import {
  changeTabMenu,
  closeTabFooter,
  openTabFooter
} from '../../../MonitorStore/action';
import spiceCardTemplatesConstructor from '../../../../services/Himalayas/spiceCardTemplate/spiceCardTemplatesConstructor';
import ioSimsConstructor from '../../../../services/Himalayas/ioSim/ioSimsConstructor';
import { HIMALAYAS } from '../../../../constants/pageType';
import himalayasChannels from '../../../../services/Himalayas/project/channelsConstructor';
import {
  openSpiceCardTemplatePage,
  clearSpiceCardInfo
} from '../spiceCardTemplate/action';
import { openIoSimPage } from '../ioSim/action';
import { HIMALAYAS_IO_SIM_INDEX, HIMALAYAS_TRASH_INDEX } from '../../../../services/Himalayas/constants';

//get all project list
function* _updateProjectList() {
  let res = [];
  try {
    res = yield call(getProjectList);
  } catch (error) {
    console.error("Get project list failed! " + error);
  }

  const { HimalayasReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems];
  const projects = getDefaultProjectTree(res);
  //update store
  _treeItems[HIMALAYAS_PROJECT_INDEX].children = projects;
  //save project list to cache
  himalayasProjects.addProjects(projects);
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _addNewProject() {
  const { HimalayasReducer: { project: { treeItems, expandedKeys } } } = yield select();
  let _treeItems = [...treeItems];

  if (!expandedKeys.includes(PROJECTS)) {
    //expand project tree
    yield put(updateExpand([...expandedKeys, PROJECTS]));
  }
  //check creating project exist
  let dataTypes = _treeItems[HIMALAYAS_PROJECT_INDEX].children.map(item => item.dataType);
  if (dataTypes.includes(PROJECT_CREATE)) {
    message.error('There are already projects being created.')
    return;
  }

  //get default project name
  const projectList = himalayasProjects.getProjectValues();
  const name = getDefaultName({ nameList: projectList, defaultKey: "Project" });

  _treeItems[HIMALAYAS_PROJECT_INDEX].children.unshift(
    {
      id: name,
      name: name,
      key: name,
      dataType: PROJECT_CREATE,
      nodeClass: 'project-create-node'
    }
  )
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _createNewProject(action) {
  const { data } = action;//{ name }
  try {
    const res = yield call(createProjectPromise, {
      data: {
        id: "",
        name: data.name,
        version: HIMALAYAS_PROJECT_VERSION
      }
    });
    if (!res) {
      message.error("Create project failed!");
      return;
    }
    //res-> {id,name, category,...}
    const project = new projectItem({
      id: res.id,
      name: res.name,
      category: res.category,
      children: getProjectChildren(res)
    })
    himalayasProjects.addProject(project.id, project);
    const { HimalayasReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    const createIndex = _treeItems[HIMALAYAS_PROJECT_INDEX].children.findIndex(item => item.dataType === PROJECT_CREATE);
    _treeItems[HIMALAYAS_PROJECT_INDEX].children.splice(createIndex, 1);
    _treeItems[HIMALAYAS_PROJECT_INDEX].children.unshift(project);
    yield put(updateTreeList({ treeItems: _treeItems }))
  } catch (error) {
    console.error("Create project failed! " + error);
    message.error("Create project failed! " + error);
  }
}

function* _cancelCreateProject() {
  const { HimalayasReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems];
  const createIndex = _treeItems[HIMALAYAS_PROJECT_INDEX].children.findIndex(item => item.dataType === PROJECT_CREATE);
  _treeItems[HIMALAYAS_PROJECT_INDEX].children.splice(createIndex, 1);
  yield put(updateTreeList({ treeItems: _treeItems }));
}

function* _openProject(action) {
  const { id } = action;
  const { HimalayasReducer: { project: { treeItems, expandedKeys } } } = yield select();
  //If expandedKeys not includes current project key, push
  let _expandedKeys = [...expandedKeys];
  const key = `${PROJECT}-${id}`;
  if (!_expandedKeys.includes(key)) {
    //close other open project
    _expandedKeys = filterKeys(_expandedKeys, PROJECT, id);
    //expand current project
    _expandedKeys.push(key);
    yield put(updateExpand(_expandedKeys));
  }

  //clear prev opened project cache
  clearProjectCache();

  //update project info in store
  yield put(updateOpenedProjectInfo({
    id
  }));

  let res = null;
  try {
    res = yield call(getProjectChildrenPromise, id)
  } catch (error) {
    console.error(error);
    return;
  }
  let _treeItems = [...treeItems];
  const projectIndex = _treeItems[HIMALAYAS_PROJECT_INDEX].children.findIndex(item => item.id === id);
  if (projectIndex < 0) {
    return;
  }

  //update tab monitor info -> projectId,projectName
  yield put(changeTabMenu({
    currentProjectId: id,
    projectName: _treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].name,
  }));

  if (res && res.himalayasChannel) {
    const spiceCardTemplateList = getSpiceCardTemplateTree(res.himalayasChannel[SPICE_CARD_TEMPLATE]);
    spiceCardTemplatesConstructor.addList(spiceCardTemplateList);
    himalayasChannels.setList(SPICE_CARD_TEMPLATE, id, spiceCardTemplateList);

    const { ioSimList, ioSimTreeList } = getIOSimTree(res.himalayasChannel[IO_SIM], id);
    ioSimsConstructor.addList(ioSimList);
    himalayasChannels.setList(IO_SIM, id, ioSimList);
    himalayasChannels.setList(IO_SIM_GROUPS, id, ioSimTreeList);

    _treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].children = updateProjectChild(_treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].children, { spiceCardTemplateList, ioSimTreeList })
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
  }
}

function* _closeProject() {
  //const { HimalayasReducer: { project: { treeItems } } } = yield select();
  yield put(clearProjectInfo())
  clearProjectCache();
}

function clearProjectCache() {
  spiceCardTemplatesConstructor.clearCache();
  ioSimsConstructor.clearCache();
}

function* _delProject(action) {
  const { id } = action;
  try {
    //delete project
    yield call(deleteProjectPromise, [id]);
    const { HimalayasReducer: { project: { treeItems, openProjectId } } } = yield select();
    let _treeItems = [...treeItems];
    if (openProjectId === id) {
      //if opened curr project, clear project status
      yield put(closeProject());
    }
    //update treeItems
    _treeItems[HIMALAYAS_PROJECT_INDEX].children = _treeItems[HIMALAYAS_PROJECT_INDEX].children.filter(item => item.id !== id);
    yield put(updateTreeList({ treeItems: _treeItems }));
    //delete cache
    himalayasProjects.delProject(id);
    yield put(updateTrash());
  } catch (error) {
    console.error(error);
    message.error("Delete project failed! " + error);
    yield put(updateTrash());
  }
}

function* _openPage(action) {
  const { pageType, id } = action;
  let _pageType = pageType;

  switch (pageType) {
    case SPICE_CARD_TEMPLATE:
      yield put(openSpiceCardTemplatePage(id));
      //open monitor box
      const findSpiceCard = spiceCardTemplatesConstructor.get(id);
      yield put(changeTabMenu({
        tabSelectKeys: ["monitor"],
        currentVerificationId: findSpiceCard ? findSpiceCard.verificationId : null,
        verificationName: findSpiceCard ? findSpiceCard.name : "",
        menuType: "simulation"
      }));
      yield put(openTabFooter());
      break;
    case IO_SIM:
      yield put(openIoSimPage(id));
      const findIoSim = ioSimsConstructor.get(id);
      //open monitor box
      yield put(changeTabMenu({
        tabSelectKeys: ["monitor"],
        currentVerificationId: findIoSim ? findIoSim.verificationId : null,
        verificationName: findIoSim ? findIoSim.name : "",
        menuType: "simulation"
      }));
      yield put(openTabFooter());
      break;
    case RESULT:
      // yield put(openResultPage(id));
      //Close tab monitor box
      yield put(closeTabFooter());
      _pageType = IO_SIM;
      break;
    default: break;
  }

  const { HimalayasReducer: { project: { layout, selectedKeys, pcbLayout, viewList: preViewList } } } = yield select();
  let _selectedKeys = [...selectedKeys];
  //if selectedKeys not includes key,update selectedKeys
  if (!selectedKeys.includes(`${_pageType}-${id}`)) {
    _selectedKeys = updateTreeSelectKeys({
      prevSelectedKeys: [...selectedKeys],
      key: _pageType,
      eventKey: `${_pageType}-${id}`,
      selected: true,
      layout,
      pcbLayout,
      productType: HIMALAYAS
    });
    yield put(updateSelectedKeys(_selectedKeys));
  }

  // update viewList
  let _viewList = getViewListBySelectedKey(_selectedKeys);
  if (_pageType !== pageType) {  //result page
    //[IO_SIM, SPICE_CARD_TEMPLATE]
    const index = _viewList.findIndex(v => v === _pageType);
    if (index > -1) {
      //viewList -> [RESULT, SPICE_CARD_TEMPLATE]
      _viewList[index] = pageType;
      yield put(updateViewList(_viewList));
    }
  } else {
    //if prev viewList exist result page and selectedKey includes setup(ioSim) page, update result key to viewList
    if (_pageType !== IO_SIM && _viewList.includes(IO_SIM) && preViewList.includes(RESULT)) {
      const index = _viewList.findIndex(v => v === IO_SIM);
      _viewList[index] = RESULT;
      yield put(updateViewList(_viewList));
    } else {
      yield put(updateViewList(_viewList));
    }
  }
  //save and clear other prev opened setup page
  yield call(saveAndClearSetupPageInfo, { viewList: _viewList, clearList: [SPICE_CARD_TEMPLATE, IO_SIM] });
}

function* saveAndClearSetupPageInfo(action) {
  const { clearList, viewList } = action;

  if (clearList.includes(SPICE_CARD_TEMPLATE) && !viewList.includes(SPICE_CARD_TEMPLATE)) {
    // TODO: save prev SPICE_CARD_TEMPLATE content
    //clear prev opened SPICE_CARD_TEMPLATE content
    yield put(clearSpiceCardInfo());
  }

  if (clearList.includes(IO_SIM) && !viewList.includes(IO_SIM)) {
    // TODO: save prev opened setup(IO_SIM) content
    //TODO: clear prev opened setup(IO_SIM) content
  }
}

export function* updateTree(action) {
  const { res, dataType, open, name } = action;
  if (!res || !res.himalayasChannel) {
    return;
  }
  const { HimalayasReducer: { project: { treeItems, openProjectId } } } = yield select();
  let _treeItems = [...treeItems];
  const { himalayasChannel } = res;
  //get index in tree items
  const { channelIndex, channelType } = getSetupIndexAndType(dataType);
  if (channelIndex < 0) {
    return;
  }
  let channels = himalayasChannel[channelType] || [];
  const { list = [], treeList = [] } = channels.length ? updateChannelTree(channels, dataType, openProjectId) : {};
  const projectIndex = _treeItems[HIMALAYAS_PROJECT_INDEX].children.findIndex(item => item.id === openProjectId);
  if (projectIndex < 0) {
    return;
  }

  himalayasChannels.setList(dataType, openProjectId, list);
  //update tree items
  if (dataType === IO_SIM) {
    _treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].children[channelIndex].children = treeList;
    ioSimsConstructor.addList(list);
    himalayasChannels.setList(IO_SIM_GROUPS, openProjectId, treeList);
  } else if (dataType === SPICE_CARD_TEMPLATE) {
    _treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].children[channelIndex].children = list;
    spiceCardTemplatesConstructor.addList(list);
    //update spice card template children io sim list
    let ioSimChannels = himalayasChannel[IO_SIM] || [];
    const { list: ioSimList, treeList: ioSimTreeList } = updateChannelTree(ioSimChannels, IO_SIM, openProjectId);
    _treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].children[HIMALAYAS_IO_SIM_INDEX].children = ioSimTreeList;
    himalayasChannels.setList(IO_SIM, openProjectId, ioSimList);
    himalayasChannels.setList(IO_SIM_GROUPS, openProjectId, ioSimTreeList);
    ioSimsConstructor.addList(ioSimList);
  }

  yield put(updateTreeList({ treeItems: _treeItems }));
  //open channel setup
  if (open) {
    const currentItem = list.find(item => item.name === name);
    const id = currentItem ? currentItem.id : null;
    if (!id) {
      return;
    }
    yield call(_openPage, { pageType: dataType, id })
  }
}

function* _renameChannel(action) {
  const { itemData } = action;

  try {
    const res = yield call(channelRenamePromise, { verificationId: itemData.verificationId, name: itemData.name })
    if (!res) {
      message.error(`${getProjectChildTitle(itemData.dataType)} rename failed!`);
      return;
    }
    yield call(updateTree, { res, dataType: itemData.dataType });
  } catch (error) {
    console.error(error);
    message.error(`${getProjectChildTitle(itemData.dataType)} rename failed! ${error}`);
  }
}

function* _renameProject(action) {
  const { itemData } = action;
  try {
    const res = yield call(projectRenamePromise, { projectId: itemData.id, projectName: itemData.name })
    if (!res) {
      message.error(`Project rename failed!`);
      return;
    }
    const { HimalayasReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];
    const projectIndex = _treeItems[HIMALAYAS_PROJECT_INDEX].children.findIndex(item => item.id === itemData.id);
    if (projectIndex < 0) {
      return;
    }
    _treeItems[HIMALAYAS_PROJECT_INDEX].children[projectIndex].name = res.name;
    yield put(updateTreeList({ treeItems: _treeItems }));
  } catch (error) {
    console.error(error);
    message.error(`Project rename failed! ${error}`);
  }
}

function* _cleanTrash() {
  try {
    yield call(cleanHimalayasTrash);
    yield put(updateTrash());
  } catch (error) {
    console.error(error);
  }
}

function* _updateTrash() {
  try {
    const trash = yield call(getHimalayasTrashList);
    const { HimalayasReducer: { project: { treeItems } } } = yield select();
    let _treeItems = [...treeItems];

    let trashList = []
    if (trash.length) {
      trashList = trash.map(item => ({
        name: item.name,
        key: `${PROJECT}-${item.id}`,
        id: item.id,
        dataType: MY_TRASH
      }));
    }

    _treeItems[HIMALAYAS_TRASH_INDEX].children = trashList;
    yield put(updateTreeList({ treeItems: _treeItems }));
  } catch (error) {
    console.error(error)
  }
}

function* saveProjectServiceOptionConfig() {
  const { HimalayasReducer: { project: { hspiceOption, openProjectId } } } = yield select();
  try {
    yield call(updateHimalayasServiceOptions, { projectId: openProjectId, hspiceOption });
  } catch (error) {
    message.error('Update service options failed! ' + error)
    return;
  }
}

function* _saveReportConfigSetup(action) {
  const { projectId } = action;
  const { HimalayasReducer: { project: { reportInfo } } } = yield select();
  let config = reportInfo ? reportInfo.reportConfig : null;

  if (!config || Object.keys(config).length === 0) {
    config = {
      channelIds: [],
      language: 'english',
      templateId: "",
      format: "pptx",
      fileName: "",
      projectId
    };
  }

  // save report config to server
  try {
    yield call(updateReportConfig, { projectId, config });
  } catch (error) {
    console.error(error);
  }
}

let reportTask = null;
function* _getProjectReport(action) {
  const { projectId, format, mime, fileName } = action;
  reportTask = yield fork(_getRockyReportFlow, { projectId, format, mime, fileName });
}

function* _getRockyReportFlow(action) {
  const { projectId, format, mime, fileName } = action;
  let status = 'running';
  let progress = 0;
  while (status === 'running') {
    yield delay(3000);
    try {
      const res = yield call(getReportGenerationStatus, projectId);
      status = res.status;
      progress = res.progress;
      if (progress <= 97) {
        yield put(updateReportInfo({ reportProgress: progress }));
      }
    } catch (error) {
      status = 'failed';
    }
  }

  yield put(updateReportInfo({ reportProgress: 98 }));
  yield call(_getReportFileByFormat, { projectId, format, mime, fileName });
}

function* _getReportFileByFormat(action) {
  const { projectId, format } = action;
  try {
    //download pptx or pdf
    const downloadKey = yield call(getReportFile, { projectId, format });
    if (downloadKey) {
      const { HimalayasReducer: { project: { openProjectId } } } = yield select();
      if (openProjectId !== projectId) {//open other project
        yield call(_clearProjectReportInfo);
        return;
      }
      const token = localStorage.getItem('token')
      const a = document.createElement('a');
      a.href = `api/v3/download/file?downloadKey=${downloadKey}&access_token=${token}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      yield put(updateReportInfo({
        reportProgress: 100,
        reportMessage: `Download report in ${format.toUpperCase()} format successfully!`
      }));
    } else {
      yield put(updateReportInfo({
        reportMessage: `Download report in ${format.toUpperCase()} format failed!`
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(updateReportInfo({
      reportMessage: `Download report in ${format.toUpperCase()} format failed!`
    }));
  }
  yield delay(1000);
  yield put(updateReportInfo({ reportVisible: false, reportProgress: 0 }));
}

function* _clearProjectReportInfo() {
  //cancel prev report task
  if (reportTask) {
    yield cancel(reportTask);
  }
}

export default function* projectSaga() {
  yield takeEvery(UPDATE_PROJECT_MENU, _updateProjectList);
  yield takeEvery(ADD_PROJECT, _addNewProject);
  yield takeEvery(CREATE_PROJECT, _createNewProject);
  yield takeEvery(CANCEL_CREATE_PROJECT, _cancelCreateProject);
  yield takeEvery(OPEN_PROJECT, _openProject);
  yield takeEvery(CLOSE_PROJECT, _closeProject);
  yield takeEvery(DELETE_PROJECT, _delProject);
  yield takeEvery(OPEN_PAGE, _openPage);
  yield takeEvery(RENAME_CHANNEL, _renameChannel);
  yield takeEvery(RENAME_PROJECT, _renameProject);
  yield takeEvery(CLEAN_TRASH, _cleanTrash);
  yield takeEvery(UPDATE_TRASH, _updateTrash);
  yield takeEvery(SAVE_SERVICE_OPTION, saveProjectServiceOptionConfig);
  yield takeEvery(SAVE_REPORT_CONFIG, _saveReportConfigSetup);
  yield takeEvery(GET_PROJECT_REPORT, _getProjectReport);
}