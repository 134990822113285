import LayoutData from '@/services/data/LayoutData';
import Boundaries from '@/services/helper/cutDesign/Boundaries';
import calcBoundary from '@/services/helper/cutDesign/boundary';
import NetsSize from './cutDesign/NetsSize';
import { unitChange } from './mathHelper';
import { getComponentPinLocation } from '../ExtractionPortsHelper';

export function getPowerNetsBoundaryArr({ ratio, nets, designId, components = [], referenceNets = [] }) {
  const db = LayoutData.getLayout(designId);
  let _unit = db.mUnits;
  if (_unit === "mils") {
    _unit = "mil";
  }
  const netsSize = new NetsSize({ layoutDB: db });
  let size = Math.ceil(netsSize.getNetsSize(nets) * ratio);
  let _size = size;
  if (size > 3) {
    _size = 3;
  }
  const refePinsArr = getReferencePortBoundaryArr({
    size,
    components,
    designId,
    referenceNets
  })

  let maxX = Infinity, maxY = Infinity, minX = -Infinity, minY = -Infinity;
  if (db.mProfile.mVertices.length) {
    maxX = Math.max(...db.mProfile.mVertices.map(d => d.mX));
    maxY = Math.max(...db.mProfile.mVertices.map(d => d.mY));
    minX = Math.min(...db.mProfile.mVertices.map(d => d.mX));
    minY = Math.min(...db.mProfile.mVertices.map(d => d.mY));
  }

  const boundary = getNetsBoundaryArr({ clipSizeZ: _size, nets, designId }).concat(refePinsArr);
  const verticesArr = getVerticesArr(boundary);
  let data = calcBoundary(verticesArr, _size, _unit, { maxX, maxY, minX, minY });
  if (size > 3) {
    const _boundaries = new Boundaries({ layoutDB: db, distance: size - 3, clipSize: size - 3 });
    const _verticesArr = _boundaries.getGeneralBoundary(data.data);
    data = calcBoundary(_verticesArr, size, _unit, { maxX, maxY, minX, minY });
  }
  return data;
}

export function getNetsBoundaryArr({ clipSize, nets, designId }) {
  const db = LayoutData.getLayout(designId);
  const boundaries = new Boundaries({ layoutDB: db, distance: updateDistance(clipSize, db.mUnits), clipSize });
  let arr = [];
  nets.forEach(net => {
    arr.push({ net, boundary: boundaries.getNetBoundary(net) });
  });
  return arr;
}


function getVerticesArr(vertices) {
  const _netsVertices = [...vertices];

  let _vertices = [];
  for (let item of _netsVertices) {
    const { boundary } = item;
    _vertices = _vertices.concat(boundary);
  }

  let obj = {};
  _vertices = _vertices.filter(item => {
    if (!obj[item.toString()]) {
      obj[item.toString()] = true;
      return item;
    }
    return false;
  });

  return _vertices;
}

function getReferencePortBoundaryArr({ clipSize, components, designId, referenceNets }) {
  const db = LayoutData.getLayout(designId);
  const boundaries = new Boundaries({ layoutDB: db, distance: updateDistance(clipSize, db.mUnits), clipSize });

  const refPins = components.map(item => {
    const { name, pins } = item;
    const _pins = pins.filter(pin => referenceNets.includes(pin.net)).map(pin => pin.pin)
    if (!_pins.length) {
      return null;
    }
    return { comp: name, pins: _pins }
  }).filter(item => !!item)
  let arr = [];
  for (const compInfo of refPins) {
    const compPins = getComponentPinLocation({
      scaling: 1,
      component: db.getComponent(compInfo.comp),
      compName: compInfo.comp,
      mPartMgr: db.mPartMgr,
      savePad: true
    }).filter(d => compInfo.pins.includes(d.number));
    arr.push(...boundaries.getPinsBoundaryArr(compPins));
  }
  return arr;
}

function updateDistance(clipSize, unit) {
  let _unit = unit;
  if (_unit === "mils") {
    _unit = "mil";
  }
  const { number } = unitChange({ num: parseFloat(clipSize), oldUnit: 'mm', newUnit: _unit, decimals: 12 });
  return parseFloat(number);
}