import {
  START_ANDES_VERIFICATION,
  CHANGE_VERIFICATION_LIST,
  UPDATE_ANDES_PROGRESS,
  UPDATE_SINGLE_MONITOR,
  SINGLE_VERIFY_INFO,
  CLEAN_SINGLE_PROGRESS,
  CLEAN_MONITOR,
  RUNNING_SHOW,
  CANCEL_SHOW,
  CHANGE_UPLOAD_MES,
  RESULT_EXIST,
  UPDATE_SIMULATION_MSG,
  SAVE_CURRENT_LOG,
  WAITING_INDEX,
  UPDATE_ERROR_CHECK_LIST,
  UPDATE_PRE_PROCESS_LOG,
  CHANGE_VERIFY_STATUS,
  SAVE_CURRENT_PROFILE_LOG
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  verificationId: null,
  simulateKeys: [],
  singleProgress: [],
  singleVerifyInfo: null,
  singleMonitor: [],
  runningStatus: false,
  cancelStatus: false,
  uploadDebugMes: null,
  existResult: false,
  simulationMsg: [],
  waitingQueue: [],
  andesInfoErrorCheck: [],
  preProcessLog: [],
  verifyDisabled: false
}

export const simulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case START_ANDES_VERIFICATION:
      return {
        ...state,
        verificationId: action.verificationId
      }
    case CHANGE_VERIFICATION_LIST:
      return {
        ...state,
        simulateKeys: action.simulateKeys
      }
    case UPDATE_ANDES_PROGRESS:
      const { singleProgress } = state;
      let progress = [...singleProgress];
      const index = singleProgress.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (index) !== 'boolean' && index > -1) {
        progress[index].progress = action.progress;
      } else {
        progress.push({
          progress: action.progress,
          verificationId: action.verificationId
        })
      }
      return {
        ...state,
        singleProgress: [...progress]
      };
    case CLEAN_SINGLE_PROGRESS:
      let progressInfo = [...state.singleProgress];
      const i = progressInfo.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (i) !== 'boolean' && i > -1) {
        progressInfo.splice(i, 1);
      }
      return {
        ...state,
        singleProgress: [...progressInfo]
      }
    case SINGLE_VERIFY_INFO:
      return {
        ...state,
        singleVerifyInfo: {
          verificationId: action.verificationId,
          workflowId: action.workflowId
        }
      }
    case UPDATE_SINGLE_MONITOR:
      const { singleMonitor } = state;
      let monitor = [...singleMonitor];
      const n = monitor.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (n) !== 'boolean' && n > -1) {
        monitor[n].monitor = action.monitor;
      } else {
        monitor.push({
          monitor: action.monitor,
          verificationId: action.verificationId,
          workflowId: action.workflowId
        })
      }
      return {
        ...state,
        singleMonitor: [...monitor]
      }
    case CLEAN_MONITOR:
      let monitors = [...state.singleMonitor];
      const j = monitors.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (j) !== 'boolean' && j > -1) {
        monitors.splice(j, 1);
      }
      return {
        ...state,
        singleMonitor: [...monitors]
      }
    case RUNNING_SHOW:
      return {
        ...state,
        runningStatus: action.status
      }
    case CANCEL_SHOW:
      return {
        ...state,
        cancelStatus: action.status,
        queueIndex: (action.status && -1) || state.queueIndex,
        waitingTime: ""
      }
    case CHANGE_UPLOAD_MES:
      return {
        ...state,
        uploadDebugMes: action.mes
      }
    case RESULT_EXIST:
      return {
        ...state,
        existResult: action.existResult
      }
    case UPDATE_SIMULATION_MSG:
      let { simulationMsg } = state;
      let msg = [...simulationMsg];
      const m = msg.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (m) !== 'boolean' && m > -1) {
        if (action.msg) {
          msg[m].msg = action.msg;
        } else {
          msg.splice(m, 1);
        }
      } else {
        msg.push({
          msg: action.msg,
          verificationId: action.verificationId
        })
      }
      return {
        ...state,
        simulationMsg: [...msg]
      }
    case SAVE_CURRENT_LOG:
      return {
        ...state,
        log: action.log
      }
    case WAITING_INDEX:
      return {
        ...state,
        waitingQueue: action.waitingQueue
      }
    case UPDATE_ERROR_CHECK_LIST:
      return {
        ...state,
        andesInfoErrorCheck: action.andesInfoErrorCheck
      }
    case UPDATE_PRE_PROCESS_LOG:
      return {
        ...state,
        preProcessLog: [...action.preProcessLog]
      }
    case CHANGE_VERIFY_STATUS:
      return {
        ...state,
        verifyDisabled: action.disabled
      }
    case USER_LOGOUT:
      return {
        ...defaultState,
        andesInfoErrorCheck: [],
      }
    case SAVE_CURRENT_PROFILE_LOG:
      return {
        ...state,
        profile: action.profile
      }
    default: return state
  }
};