import { UPDATE_DOWNLOAD_STATUS } from './actionTypes';

const defaultState = {
  download: false,
  systemType: '',
  downloadType: '',
  fileName: '',
  selectedVersion: '',
  selectedType: '',
  _url: ''
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_DOWNLOAD_STATUS:
      return {
        ...state,
        download: action.flag,
        systemType: action.systemType,
        downloadType: action.downloadType,
        fileName: action.fileName,
        selectedVersion: action.selectedVersion,
        selectedType: action.selectedType,
        _url: action._url
      }
    default:
      return state;
  }
}