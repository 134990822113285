import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Table from "@/components/EditableTable";
import { getComponentByName } from '../../../services/PCBHelper';
import LayoutData from '../../../services/data/LayoutData';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../services/helper/panelSizeHelper';
import { Checkbox } from 'antd';
import './index.css';
import { CPHY } from '../../../services/PCBHelper/constants';


const columns = [
  {
    title: "Component",
    dataIndex: "component",
    width: "20%"
  },
  {
    title: "Pins",
    dataIndex: "pins",
    width: "80%"
  }
]
class EditDiodePinsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      components: [],
      applyAll: true
    }
    this.dialogRoot = document.getElementById('root');

    columns[1].render = (text, record) => {
      const { selectedPins } = record;
      return <div className='diode-component-pin-selection'>
        {(selectedPins || []).map(item => `${item.pin} (${item.net})`).join(", ")}
      </div>
    }

    columns[1].onCell = (record) => {
      const pins = (record.selectedPins || []).map(item => item.pin)
      const treeData = this.getPinTreeData(record);
      return {
        record: { ...record, selectPins: pins },
        edit: 'tree-select',
        dataIndex: "selectPins",
        treeData,
        selectedValues: [...pins],
        handleSave: this.onTreeChange,
        placeholder: "Select Pins",
        dropdownStyle: { maxHeight: 500 }
      }
    }
  }

  closeModal = () => {
    const { diodeComp } = this.props;
    const { components } = this.state;
    this.props.editDiodeCompPins(diodeComp.part, components);
    this.props.closeModal();
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.getData()
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }


  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();

    this.setState({
      maxWidth: getPanelMaxWidth(offset, 770),
      maxHeight: getPanelMaxHeight(offset, 400),
    })
  }

  componentDidUpdate = (prevProps) => {
    const { record } = this.props;
    const { record: prevRecord } = prevProps;
    if (record && (!prevRecord || record.part !== prevRecord.part)) {
      this.getData()
    }
  }

  getData = () => {
    const { diodeComp, designId, signals, serdesType } = this.props;
    if (diodeComp && diodeComp.comps && diodeComp.comps[0]) {
      const pcbComp = getComponentByName(designId, diodeComp.comps[0].name);
      const mPinList = pcbComp.mPart && pcbComp.mPart.mPinList ? pcbComp.mPart.mPinList : [];
      const pinList = JSON.parse(JSON.stringify(mPinList));
      const CompPinsNets = LayoutData.getCompPinsNets(designId);
      let components = []
      for (let comp of diodeComp.comps) {
        const compPinsInfo = CompPinsNets[comp.name] || {};//{[pin]:{mName:net name, ... }}
        let allCompPins = [], selectedPins = JSON.parse(JSON.stringify(comp.pins));
        for (let pin of pinList || []) {
          const pinNet = (compPinsInfo[pin.mNumber] || {}).mName;
          const signal = signals.find(item =>
            serdesType === CPHY ?
              item.nets_A.includes(pinNet) || item.nets_B.includes(pinNet) || item.nets_C.includes(pinNet)
              : item.nets_P.includes(pinNet) || item.nets_N.includes(pinNet));
          allCompPins.push({
            pin: pin.mNumber,
            pinName: pin.mName,
            net: pinNet,
            signal: signal ? signal.name : ""
          })
        }
        components.push({
          component: comp.name,
          part: diodeComp.part,
          pins: allCompPins,
          selectedPins
        })
      }

      this.setState({ components })
    }
  }

  getPinTreeData = (record) => {
    const { pins } = record;
    return [{
      title: "All",
      key: "All",
      value: "All",
      children: (pins || []).map(item => {
        return {
          title: `${item.pin} (${item.net})`,
          key: item.pin,
          value: item.pin,
          disabled: !item.signal
        }
      })
    }]
  }

  onTreeChange = (keys, record) => {
    let newSelectPins = record.selectedPins;
    if (keys.length === 1 && keys[0] === "All") {
      newSelectPins = record.pins.filter(item => !!item.signal);
    } else {
      const pinKeys = keys.map(item => item.split(" (")[0]);
      newSelectPins = record.pins.filter(item => pinKeys.includes(item.pin) && !!item.signal);
    }

    const { components, applyAll } = this.state;
    let _components = [...components];
    if (applyAll) {
      for (let comp of _components) {
        const newPins = newSelectPins.map(item => item.pin);
        comp.selectedPins = comp.pins.filter(item => newPins.includes(item.pin) && !!item.signal).map(item => { return { pin: item.pin, net: item.net, signal: item.signal } });
      }
    } else {
      const index = _components.findIndex(item => item.component === record.component);
      if (index < 0) {
        return;
      }
      _components[index].selectedPins = newSelectPins.map(item => { return { pin: item.pin, net: item.net, signal: item.signal } });
    }

    this.setState({
      components: _components
    })
  }

  applyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  render = () => {
    const { diodeComp } = this.props;
    const { components, maxHeight, maxWidth, applyAll } = this.state;
    const content = (
      <Panel
        className='andes-create-channel-panel andes-channel-diode-panel'
        position='panel-center-left'
        title={`Pins Selection - ${diodeComp.part}`}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={getPanelWidth(maxWidth, { defaultWidth: 700 })}
        maxWidth={maxWidth}
        draggable
        maxHeight={maxHeight}
        minHeight={200}
      >
        <div className='create-channel-content'>
          <Table
            columns={columns}
            dataSource={components}
            className="andes-diode-component-pin-table"
            rowKey={(record) => record.component}
          />
          <div className="diode-component-pins-apply-all-content">
            <span>Apply pins selection to all components in the table</span>
            <Checkbox
              checked={applyAll}
              onChange={(e) => this.applyChange(e)}
            />
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}


export default EditDiodePinsPanel;