import React, { Component, Fragment } from 'react';
import { Spin, message } from 'antd';
import { getTargetFile } from '@/services/Cascade/Impedance';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { TargetNameEdit } from './TargetNameEdit';
import { parseTargetFile } from '@/services/helper/parseFile';
import { getImpTargetName } from '../../../../services/Cascade/Impedance';
import '../index.css';

class TargetFileContent extends Component {

  constructor(props) {
    super(props);
    const { record, targetName } = props;
    let name = targetName ? targetName : record && record.MAIN_POWER_NETS && record.MAIN_POWER_NETS.length
      ? `Target_${record.MAIN_POWER_NETS[0]}` : 'Target_Customize';
    this.state = {
      file: '',
      fileLoading: true,
      nameStatus: false,
      targetName: name,
      tempName: name,
      error: null,
    }
  }

  componentDidMount() {
    this.getTargetFile();
    this.initState()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { targetIndex } = this.props;
    if (targetIndex !== prevProps.targetIndex) {
      this.getTargetFile();
      this.initState();
    }
  }

  initState = () => {
    const { record, targetIndex, target, targetType, PowerNets } = this.props;
    const currentTarget = target[targetIndex];
    const name = getImpTargetName({
      targetName: currentTarget ? currentTarget.targetName : "",
      record,
      targetType,
      PowerNets,
    });
    this.setState({
      nameStatus: false,
      targetName: name,
      tempName: name,
      error: null,
      save: false
    }, () => {
      this.props.savePoints(this.getFrequencyPoints());
    })
  }

  getTargetFile = async () => {
    const { powerDomainId, target, targetIndex } = this.props;
    const currentTarget = target[targetIndex];
    if (!currentTarget) {
      return;
    }
    const { fileName: targetFileName, libraryId } = currentTarget
    if (!targetFileName) {
      return;
    }
    try {
      const file = await getTargetFile(libraryId ? { libraryId } : { powerDomainId, targetFileName });
      this.setState({
        file,
        fileLoading: false
      }, () => { this.props.savePoints(this.getFrequencyPoints()); });
    } catch (error) {
      message.error('Get target file failed!');
      this.setState({
        fileLoading: false
      })
    }
  }

  inputRef = (ref) => {
    this.targetNameRef = ref;
  }

  changeName = (e, status) => {
    e.stopPropagation();
    this.setState({
      nameStatus: status,
      error: null
    }, () => {
      if (status) {
        this.targetNameRef.focus();
      }
    })
  }

  saveTempName = (e) => {
    this.setState({
      tempName: e.target.value
    })
  }

  saveNewTargetName = (e) => {
    e.stopPropagation();
    const { tempName } = this.state;
    const error = checkNameFormat(tempName);
    if (error) {
      this.setState({
        error
      })
    } else {
      this.setState({
        targetName: tempName,
        nameStatus: false,
        error: null
      })
      const { target, targetIndex } = this.props;
      if (target[targetIndex]) {
        const targetNames = target.filter(item => item.targetName);
        let _tempName = tempName
        while (targetNames.includes(_tempName)) {
          _tempName = `${_tempName}_1`
        }
        const _target = { ...target[targetIndex], targetName: _tempName };
        this.props.updateTarget(_target, targetIndex);
      }
    }
  }

  getFrequencyPoints = () => {
    const { file } = this.state;
    const frequencyPoints = parseTargetFile(file);
    return frequencyPoints;
  }


  render() {
    const { file, fileLoading, error, targetName, nameStatus } = this.state;
    const { panelHeight = '400px' } = this.props;
    let _height = Number(panelHeight.replace('px', '')) - 100;
    return <Fragment>
      <div className="impedance-target-file">
        {TargetNameEdit({
          targetName,
          nameStatus,
          changeName: this.changeName,
          saveTempName: this.saveTempName,
          saveNewTargetName: this.saveNewTargetName,
          onRef: this.inputRef,
        })}
        <div className='imp-target-file-content-display'>
          <Spin spinning={fileLoading} tip="Loading...">
            <pre style={{ margin: 0, overflow: 'auto', minHeight: 300, minWidth: 300, maxHeight: 700, height: _height }} className='imp-target-file-content'>
              {file}
            </pre>
          </Spin>
        </div>
      </div>
      {error && <span className='imp-target-name-error-msg'>{error}</span>}
    </Fragment>
  }
}

export default TargetFileContent;