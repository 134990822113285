import { getSSNCentricContentByID, updateSSNCentricContentByID, updateSSNBufferModelByCSMModel, getPTSWMask } from '../api/Rocky/rockySSNCtrl';
import apiProcess from '../api/utility';

export function getCentricContentPromise(verificationId) {
  return new Promise((resolve, reject) => {
    apiProcess(getSSNCentricContentByID, verificationId).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
      console.error(error);
    })
  })
};

export function updateSSNCentricContentPromise(content) {
  return apiProcess(updateSSNCentricContentByID, content)
}

/**
 * 
 * update interface buffer model by csm pairs
 * @export
 * @param {string} ssnChannelId
 * @returns
 */
export function updateSSNBufferModelByCSM(ssnChannelId) {
  return apiProcess(updateSSNBufferModelByCSMModel, ssnChannelId)
}

export function getPTSWMaskPromise({ channelId, victimNet, fileName }) {
  return apiProcess(getPTSWMask, { channelId, victimNet, fileName }, false, false, true)
}