import { getPDNResultFile } from '../../api/pdn/PDNAPICtrl';
import { getResultInfoPromise } from '../PDNCtrl';
import defaultColor from '@/constants/defaultColors';
class ParameterData {

  constructor({ name, verificationId, projectId, pdnId, type, time, id, hashId }) {
    this.id = id;
    this.name = name || null;
    this.ports = null;
    this.freq = null;
    this.matrix = null;
    this.verificationId = verificationId;
    this.projectId = projectId;
    this.pdnId = pdnId;
    this.type = type;
    this.time = time || null;
    this.fileType = time && time !== 'current' ? 'history' : null;
    this.hashId = hashId
  }

  /**
   * Get curve data
   *
   * @param param - s,y,z
   * @param value - am for amplitude, ​ph for phase​, re for real, im for imaginary
   * type - s/y/z + am/ph/re/im
   */
  getCurveData = ({ row, col, param, value, fileIndex, fileType, time, curveType }) => {
    // TODO - multi file
    if (!this.matrix || !this.matrix[row] || this.matrix[row][col]) {
      this.initMatrix(fileIndex);
    }

    let item = this.matrix[row][col],
      type = param + value;

    return new Promise((resolve, reject) => {
      if (item[type]) {
        resolve({
          type,
          color: item.color,
          curve: item[type]
        })
      } else {
        if (value === 'am' || value === 'ph') {
          // snp match: Reg /\.s[0-9]{1,}p/i
          const name = this.name.split(/\.s[0-9]{1,}p/i)[0];
          let path = 'pdn.zam', fileName = name;
          if (fileType === 'history' && time && time !== 'current') {
            path = `${time}/${name}/pdn.zam`;
            fileName = 'history';
          };
          if (curveType === 'import') {
            path = `${name}/pdn.zam`;
            fileName = 'import';
          }
          getResultInfoPromise({ verificationId: this.verificationId, fileName: fileName, cols: [col], rows: [row], path: path }).then((response) => {
            item[type] = response[0].curve;
            resolve({
              type,
              color: item.color,
              curve: item[type]
            })
          }, error => {
            resolve(null);
          })
        } else {
          this.getCurveData({ row, col, param, value: 'am' }).then(amData => {
            this.getCurveData({ row, col, param, value: 'ph' }).then((phData) => {
              const cos = Math.cos;
              const sin = Math.sin;
              item[param + 're'] = amData.curve.map(function (am, i) {
                return am * cos(phData.curve[i]);
              });
              item[param + 'im'] = amData.curve.map(function (am, i) {
                return am * sin(phData.curve[i])
              });
              resolve({
                type,
                color: item.color,
                curve: item[type]
              })
            })
          })
        }
      }
    })
  }

  initMatrix = (index) => {
    let n = index ? index : 0
    this.matrix = [];
    for (let i = 0, num = this.ports.length; i < num; i++) {
      this.matrix[i] = [];
      for (let j = 0; j < num; j++) {
        this.matrix[i][j] = {
          display: true,
          color: this.getColor(j * num + i + n),
        }
      }
    }
  }

  getColor(i) {
    const defaultColors = defaultColor;
    return defaultColors[i % defaultColors.length];
  }

  getNpiFile = (index) => {
    return new Promise((resolve, reject) => {
      // snp match: Reg /\.s[0-9]{1,}p/i
      const name = this.name.split(/\.s[0-9]{1,}p/i)[0];
      getPDNResultFile(this.projectId, this.pdnId, `${name}/pdn.npi`).then(res => {
        this.parseNpiFile(res.data);
        this.initMatrix(index);
        resolve(this);
      }, error => {
        console.error(error);
        resolve(null);
      })
    });
  }

  getHistoryNpiFile = (index) => {
    return new Promise((resolve, reject) => {
      const name = this.name.split(/\.s[0-9]{1,}p/i)[0];
      getPDNResultFile(this.projectId, this.pdnId, `history/${this.time}/${name}/pdn.npi`).then(res => {
        this.parseNpiFile(res.data);
        this.initMatrix(index);
        resolve(this);
      }, error => {
        console.error(error);
        resolve(null);
      })
    });
  }

  getImportNpiFile = (index) => {
    return new Promise((resolve, reject) => {
      const name = this.name.split(/\.s[0-9]{1,}p/i)[0];
      getPDNResultFile(this.projectId, this.pdnId, `import/${name}/pdn.npi`).then(res => {
        this.parseNpiFile(res.data);
        this.initMatrix(index);
        resolve(this);
      }, error => {
        console.error(error);
        resolve(null);
      })
    });
  }

  parseNpiFile = (data) => {
    const parsedData = parseNpiFile(data)
    this.ports = parsedData.ports;
    this.freq = parsedData.frequency;
  }
}

export function parseNpiFile(data) {
  if (!data) {
    return {
      ports: [],
      freq: []
    }
  }
  let lineBuffer = data.match(/[^\r\n]+/g);
  if (lineBuffer.length < 2) {
    return {
      ports: [],
      freq: []
    }
  }
  let portNum = Number(lineBuffer[0].slice(5));
  let ports = [],
    frequency = [],
    endLine = portNum + 1;
  for (let i = 1; i < endLine; i++) {
    if (!lineBuffer[i]) continue;
    let words = lineBuffer[i].split(' ');
    let name = words[2];
    if (name.match(/^port/i)) {
      const splitName = name.split('_');
      if (splitName.length > 0) {
        name = name.slice(splitName[0].length + 1, name.length);
      }
    }
    ports.push({
      index: Number(words[0]),
      impedance: Number(words[1]),
      name: name,
      display: i === 1 ? true : false
    })
  }

  for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
    frequency.push(Number(lineBuffer[i]))
  }
  return {
    ports,
    frequency
  }
}

export default ParameterData;