import { getSignalElementType } from "../../Andes_v2/channel/signalsIdentification";
import { CPHY } from "../../PCBHelper/constants";

function npiParse(data, matchName, components, type) {
  let lineBuffer = data.match(/[^\r\n]+/g);
  let portNum = Number(lineBuffer[0].slice(5));
  let ports = [],
    frequency = [],
    endLine = portNum + 1;
  for (let i = 1; i < endLine; i++) {
    if (!lineBuffer[i]) continue;
    let words = lineBuffer[i].split(' ');//"1", "100.0"
    let name = words[2];
    const { signal = null, portType = null, comp = null, compType = null } = matchName(name, components);
    const { positivePin, negativePin } = getCompPins({
      portType,
      signal,
      comp,
      name
    }, components, false, type);
    ports.push({
      index: Number(words[0]),
      impedance: Number(words[1]),
      name: `${comp}<+${positivePin}, -${negativePin}> ${signal} [${portType}]`,
      portType,
      comp,
      signal,
      compType,
      display: true,
      positivePin,
      negativePin
    })
  }

  for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
    frequency.push(Number(lineBuffer[i]))
  }
  return {
    ports: ports,
    freq: frequency
  }
}

export function npiFileParse(data) {
  return npiParse(data, matchPortName);
}

export function npiFileParseV2(data, components, type) {
  return npiParse(data, matchPortNameV2, components, type);
}

/**
 * Diff_PCIe_TX0_X_Controller_U1_A
 * signal - PCIe_TX0
 * portType - Diff
 * compType - Controller
 * comp - U1_A
 * @param {*} name
 */
function matchPortName(name) {
  if (!name) return {};
  let match = name.match(/^(Diff|Comm)_(\S*)_(TX|X|RX)_(Controller|Device|Connector|IC|BGA|DIE)_(\S*)/i);
  if (match && match.length > 5) {
    return {
      portType: match[1],
      signal: match[2],
      compType: match[4],
      comp: match[5]
    }
  }
  return {};
}

/**
 * Diff_PCIE_LANE0_TX_U37
 * signal - PCIE_LANE0_TX
 * portType - Diff
 * compType - IC/Connector
 * comp - U37
 * @param {*} name
 */
function matchPortNameV2(name, components) {
  if (!name) return {};
  const componentNames = components.map(d => d.name);
  let match = name.match(new RegExp(`^(Diff|Comm)_(\\S*)_(${componentNames.join('|')})`, 'i'));

  if (match && match.length > 3) {
    return {
      portType: match[1],
      signal: match[2],
      compType: components.find(d => d.name === match[3]).type,
      comp: match[3]
    }
  }
  return {}
}

// 0 - Not Equal, 1 - not Equal
// FEXT - Far end crosstalk
// NEXT - Near end crosstalk
// IL - Insertion loss
// RL - Return loss
// portComp portSignal
//     0        0       FEXT
//     0        1       IL
//     1        0       NEXT
//     1        1       RL

function getValue(key) {
  const FEXT = 'FEXT', NEXT = 'NEXT', IL = 'IL', RL = 'RL';
  const obj = {
    '00': FEXT,
    '01': IL,
    '10': NEXT,
    '11': RL
  };
  return obj[key];
};

function getGroupSignals(portList, signals) {
  let _signalsList = signals.map(sig => {
    const ports = portList.filter(d => d.signal === sig.name);
    let obj = {
      signal: sig.name, show: true, netList: [{
        displayName: sig.name,
        ports: ports.map(p => p.index),
        portsInfo: ports,
        signal: sig.name
      }]
    };
    return obj;
  });
  return _signalsList;
}

export function filterPorts(portsList, type) {
  return portsList.filter(d => d.portType === type);
}

export function getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin, _portSignal }, getColorByIndex) {
  return {
    id: `${portFileID}::${portIndex - 1}::${_portIndex - 1}`,
    name: `S(${portIndex}, ${_portIndex})`,
    namePrev: `S(${portIndex}, ${_portIndex})`,
    comp: portComp,
    positivePin,
    negativePin,
    _comp: _portComp,
    _positivePin,
    _negativePin,
    _signal: _portSignal,
    color: getColorByIndex(portFileID, portIndex - 1, _portIndex - 1)
  };
}

// { rowName, children: [{ id, name, color }] }
export function getResultList(content, portList, getColorByIndex, type, paramFiles) {

  if (type === CPHY) {
    return getCPHYResultList(content, portList, getColorByIndex, paramFiles);
  }

  let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], COMMIL = [], COMMRL = [];  // [{ rowName, children: [{ id, name, color }] }]
  if (!portList || !portList.length) {
    return { FEXT, NEXT, IL, RL, DiffToComm };
  }
  const _Components = content.components;
  const _signals = content.signals;
  const _chips = _Components.filter(comp => ['IC', 'Controller', 'Device', 'Connector', "BGA", "DIE"].includes(comp.type));
  const chips = _chips.map(it => it.name);
  let compType = {};
  _chips.forEach(c => {
    compType[c.name] = c.type;
  });
  const _groupSignals = getGroupSignals([...portList], _signals);
  // FEXT, NEXT, IL, RL
  const diffPortList = filterPorts(portList, 'Diff');
  const commPortList = filterPorts(portList, 'Comm');
  for (let sigInfo of _groupSignals) {
    const portSignal = sigInfo.signal;
    let info = {
      FEXT: { rowName: portSignal, children: [] },
      NEXT: { rowName: portSignal, children: [] },
      IL: { rowName: portSignal, children: [] },
      RL: { rowName: portSignal, children: [] },
      DiffToComm: { rowName: portSignal, children: [] },
      COMMIL: { rowName: portSignal, children: [] },
      COMMRL: { rowName: portSignal, children: [] },
      COMMFEXT: { rowName: portSignal, children: [] },
      COMMNEXT: { rowName: portSignal, children: [] },
    }
    for (let port of filterPorts(sigInfo.netList[0].portsInfo, 'Diff')) {
      const portComp = port.comp,
        portIndex = port.index;
      const portFileID = port ? port.fileId : null;
      const { positivePin, negativePin } = getCompPins(port, _chips, true);


      for (let portItem of diffPortList) {
        const _portComp = portItem.comp,
          _portIndex = portItem.index,
          _portSignal = portItem.signal;
        const { positivePin: _positivePin, negativePin: _negativePin } = getCompPins(portItem, _chips, true);
        const item = getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin, _portSignal }, getColorByIndex);
        // Controller, Memory
        if (chips.includes(portComp) && chips.includes(_portComp)) {
          const portCompEq = portComp === _portComp ? '1' : '0',
            portSignalEq = portSignal === _portSignal ? '1' : '0';
          const key = portCompEq + portSignalEq;
          const infoKey = getValue(key);
          if (infoKey === 'IL') {
            const findIL = info[infoKey].children.find(d => d.id === `${portFileID}::${_portIndex - 1}::${portIndex - 1}`);
            if (!findIL) {
              info[infoKey].children.push(item);
            }
          } else {
            info[infoKey].children.push(item);
          }
        } else if (portComp === _portComp) {
          // Return loss - RLC port
          info.RL.children.push(item)
        };
      }

      for (let commPort of commPortList.filter(d => d.signal === portSignal)) {
        const _portComp = commPort.comp,
          _portIndex = commPort.index;
        if (_portComp !== portComp) {
          const { positivePin: _positivePin, negativePin: _negativePin } = getCompPins(commPort, _chips, true);
          let _item = getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin }, getColorByIndex);
          info.DiffToComm.children.push(_item)
        }
      }
    };

    for (let port of filterPorts(sigInfo.netList[0].portsInfo, 'Comm')) {
      const portComp = port.comp,
        portIndex = port.index;
      const portFileID = port ? port.fileId : null;
      const { positivePin, negativePin } = getCompPins(port, _chips, true);

      for (let portItem of commPortList) {
        const _portComp = portItem.comp,
          _portIndex = portItem.index,
          _portSignal = portItem.signal;
        const { positivePin: _positivePin, negativePin: _negativePin } = getCompPins(portItem, _chips, true);
        const item = getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin, _portSignal }, getColorByIndex);
        // Controller, Memory
        if (chips.includes(portComp) && chips.includes(_portComp)) {
          const portCompEq = portComp === _portComp ? '1' : '0',
            portSignalEq = portSignal === _portSignal ? '1' : '0';
          const key = portCompEq + portSignalEq;
          const infoKey = "COMM" + getValue(key);
          if (infoKey === 'COMMIL') {
            const findIL = info[infoKey].children.find(d => d.id === `${portFileID}::${_portIndex - 1}::${portIndex - 1}`);
            if (!findIL) {
              info[infoKey].children.push(item);
            }
          } else {
            info[infoKey].children.push(item);
          }
        } else if (portComp === _portComp) {
          // Return loss - RLC port
          info.COMMRL.children.push(item)
        };
      }

    };
    FEXT.push(info.FEXT);
    NEXT.push(info.NEXT);
    IL.push(info.IL);
    RL.push(info.RL);
    DiffToComm.push(info.DiffToComm)
    COMMIL.push(info.COMMIL)
    COMMRL.push(info.COMMRL)
  };
  return { FEXT, NEXT, IL, RL, DiffToComm, COMMIL, COMMRL }
}

function getCompPins(portItem, chips = [], isNew, type) {

  if (type === CPHY) {
    return getCPHYCompPins({ portItem, chips, isNew });
  }

  let positivePin = null, negativePin = null;
  const compPins = (chips.find(item => item.name === portItem.comp) || { pins: [] }).pins.filter(item => item.signal === portItem.signal);
  if (!compPins || compPins.length < 2) {
    return {
      positivePin,
      negativePin
    }
  }

  if (isNew) {
    //port.name -> U2<+PCIE0_TX_P, -PCIE0_TX_N> TX0 [Diff]
    const startIndex = portItem.name.indexOf("<");
    const endIndex = portItem.name.indexOf(">");
    const _compPinsSplit = portItem.name.slice(startIndex + 2, endIndex);
    const _compPins = _compPinsSplit.split(", -");

    if (compPins.length === 2) {
      positivePin = _compPins[0];
      negativePin = _compPins[1];
    }
  } else {
    //port.name -> Diff_RX1_U1_PCIE1_RX_P_PCIE1_RX_N
    const compPinsSplit = portItem.name.replace(`${portItem.portType}_${portItem.signal}_${portItem.comp}_`, "");
    const pinIndex = compPinsSplit.indexOf(compPins[0].pin);

    if (pinIndex === 0) {
      positivePin = compPins[0].pin;
      negativePin = compPins[1].pin;
    } else {
      positivePin = compPins[1].pin;
      negativePin = compPins[0].pin;
    }
  }

  return { positivePin, negativePin }
}

function getCPHYGroupSignals(portList, signals) {
  function getObj({ signal, ports, pair }) {
    return {
      signal: signal.name,
      pair,
      show: true,
      netList: [{
        displayName: `${signal.name}::${pair}`,
        ports: ports.map(p => p.index),
        portsInfo: ports,
        signal,
      }]
    }
  }

  let _signalsList = signals.map(signal => {
    const ports = portList.filter(d => d.signal === signal.name);
    let obj = ['AB', 'BC', 'CA'].map(pair => getObj({ signal, ports, pair }));
    return obj;
  });
  return _signalsList.flat();
}

function getPair({ signal, netP, netN }) {
  let pair = "";
  for (const key of getSignalElementType(CPHY)) {
    if (signal[key].includes(netP)) {
      pair = key.replace('nets_', "") + pair;
    } else if (signal[key].includes(netN)) {
      pair = pair + key.replace('nets_', "");
    }
  }

  return pair;
}

function getCPHYCompPins({ portItem, chips = [], isNew, signal }) {
  let positivePin = null, negativePin = null, pairP = null, pairN = null, pair = "";
  const compPins = (chips.find(item => item.name === portItem.comp) || { pins: [] }).pins.filter(item => item.signal === portItem.signal);
  if (!compPins || compPins.length < 3) {
    return {
      positivePin,
      negativePin,
      pair
    }
  }

  if (isNew) {
    const startIndex = portItem.name.indexOf("<");
    const endIndex = portItem.name.indexOf(">");
    const _compPinsSplit = portItem.name.slice(startIndex + 2, endIndex);
    const _compPins = _compPinsSplit.split(", -");

    if (compPins.length === 3) {
      positivePin = _compPins[0];
      negativePin = _compPins[1];
      const pairPins = (chips.find(item => item.name !== portItem.comp) || { pins: [] }).pins.filter(item => item.signal === portItem.signal);
      const netP = (compPins.find(item => item.pin === positivePin) || {}).net;
      const netN = (compPins.find(item => item.pin === negativePin) || {}).net;
      pairP = (pairPins.find(item => item.net === netP) || {}).pin;
      pairN = (pairPins.find(item => item.net === netN) || {}).pin;
      if (signal) {
        pair = getPair({ signal, netP, netN });
      }
    }
  } else {
    const compPinsSplit = portItem.name.replace(`${portItem.portType}_${portItem.signal}_${portItem.comp}_`, "");
    for (const pinData of compPins) {
      const index = compPinsSplit.search(pinData.pin);
      if (index === 0) {
        positivePin = pinData.pin;
      } else if (index > -1) {
        negativePin = pinData.pin;
      }
    }
  }

  return { positivePin, negativePin, pairP, pairN, pair }
}

function getCouplingItem(sigInfo, paramFiles, col, getColorByIndex) {
  const channelId = sigInfo.netList[0].portsInfo[0].fileId.split("::")[0];
  const fileId = [channelId, sigInfo.signal, sigInfo.pair].join('::');
  const file = paramFiles.find(item => item.id === fileId);
  if (!file || !file.ports) {
    return;
  }
  const row = file.ports.length;
  const coupling = {
    id: `${fileId}::${row}::${col}`,
    name: 'S(3,4) [Comm] - S(1,2) [Diff]',
    namePrev: 'S(3,4) [Comm] - S(1,2) [Diff]',
    color: getColorByIndex(fileId, row, col)
  }
  return coupling;
}

export function getCPHYResultList(content, portList, getColorByIndex, paramFiles = []) {
  let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], COMMIL = [], COMMRL = [], CrossCoupling = [];  // [{ rowName, children: [{ id, name, color }] }]
  if (!portList || !portList.length) {
    return { FEXT, NEXT, IL, RL, DiffToComm };
  }
  const _Components = content.components;
  const _signals = content.signals;
  const _chips = _Components.filter(comp => ['IC', 'Controller', 'Device', 'Connector', "BGA", "DIE"].includes(comp.type));
  const chips = _chips.map(it => it.name);
  const _groupSignals = getCPHYGroupSignals(portList, _signals);
  // FEXT, NEXT, IL, RL
  const diffPortList = filterPorts(portList, 'Diff');
  const commPortList = filterPorts(portList, 'Comm');
  let col = 0, lastSignal = '';
  for (let sigInfo of _groupSignals) {
    const portSignal = sigInfo.signal;
    if (portSignal !== lastSignal) {
      col = 0;
      lastSignal = portSignal;
    }
    const rowName = `${sigInfo.signal} (${sigInfo.pair})`;
    const coupling = getCouplingItem(sigInfo, paramFiles, col, getColorByIndex);
    col++;
    let info = {
      FEXT: { rowName, children: [] },
      NEXT: { rowName, children: [] },
      IL: { rowName, children: [] },
      RL: { rowName, children: [] },
      DiffToComm: { rowName, children: [] },
      COMMIL: { rowName, children: [] },
      COMMRL: { rowName, children: [] },
      COMMFEXT: { rowName, children: [] },
      COMMNEXT: { rowName, children: [] },
      CrossCoupling: { rowName, children: coupling ? [coupling] : [] }
    }
    for (let port of filterPorts(sigInfo.netList[0].portsInfo, 'Diff')) {
      const portComp = port.comp,
        portIndex = port.index;
      const portFileID = port ? port.fileId : null;
      const { positivePin, negativePin, pairP, pairN, pair } = getCPHYCompPins({ portItem: port, chips: _chips, isNew: true, signal: sigInfo.netList[0].signal });
      for (let portItem of diffPortList) {
        const _portComp = portItem.comp,
          _portIndex = portItem.index,
          _portSignal = portItem.signal;
        const { positivePin: _positivePin, negativePin: _negativePin } = getCPHYCompPins({ portItem, chips: _chips, isNew: true });
        const item = getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin, _portSignal }, getColorByIndex);
        // Controller, Memory
        if (chips.includes(portComp) && chips.includes(_portComp)) {
          const portCompEq = portComp === _portComp ? '1' : '0',
            portSignalEq = portSignal === _portSignal ? '1' : '0';
          const key = portCompEq + portSignalEq;
          const infoKey = getValue(key);
          if (infoKey === 'IL') {
            const findIL = info[infoKey].children.find(d => d.id === `${portFileID}::${_portIndex - 1}::${portIndex - 1}`);
            if (!findIL && _negativePin === pairN && _positivePin === pairP && pair === sigInfo.pair) {
              info[infoKey].children.push(item);
            }
          } else if (infoKey === 'RL') {
            if (negativePin === _negativePin && positivePin === _positivePin && pair === sigInfo.pair) {
              info[infoKey].children.push(item);
            }
          } else {
            info[infoKey].children.push(item);
          }
        } else if (portComp === _portComp) {
          // Return loss - RLC port
          info.RL.children.push(item)
        };
      }

      for (let commPort of commPortList.filter(d => d.signal === portSignal && d.com !== portComp)) {
        const _portComp = commPort.comp,
          _portIndex = commPort.index;
        const { positivePin: _positivePin, negativePin: _negativePin } = getCPHYCompPins({ portItem: commPort, chips: _chips, isNew: true });
        let _item = getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin }, getColorByIndex);
        if (_negativePin === pairN && _positivePin === pairP && pair === sigInfo.pair) {
          info.DiffToComm.children.push(_item)
        }
      }
    };

    for (let port of filterPorts(sigInfo.netList[0].portsInfo, 'Comm')) {
      const portComp = port.comp,
        portIndex = port.index;
      const portFileID = port ? port.fileId : null;

      const { positivePin, negativePin, pairN, pairP, pair } = getCPHYCompPins({ portItem: port, chips: _chips, isNew: true, signal: sigInfo.netList[0].signal });

      for (let portItem of commPortList) {
        const _portComp = portItem.comp,
          _portIndex = portItem.index,
          _portSignal = portItem.signal;
        const { positivePin: _positivePin, negativePin: _negativePin } = getCPHYCompPins({ portItem, chips: _chips, isNew: true, signal: sigInfo.netList[0].signal });
        const item = getItem({ portFileID, portIndex, portComp, positivePin, negativePin }, { _portIndex, _portComp, _positivePin, _negativePin, _portSignal }, getColorByIndex);
        // Controller, Memory
        if (chips.includes(portComp) && chips.includes(_portComp)) {
          const portCompEq = portComp === _portComp ? '1' : '0',
            portSignalEq = portSignal === _portSignal ? '1' : '0';
          const key = portCompEq + portSignalEq;
          const infoKey = "COMM" + getValue(key);
          if (infoKey === 'COMMIL') {
            const findIL = info[infoKey].children.find(d => d.id === `${portFileID}::${_portIndex - 1}::${portIndex - 1}`);
            if (!findIL && _negativePin === pairN && _positivePin === pairP && pair === sigInfo.pair) {
              info[infoKey].children.push(item);
            }
          } else if (infoKey === 'COMMRL') {
            if (negativePin === _negativePin && positivePin === _positivePin && pair === sigInfo.pair) {
              info[infoKey].children.push(item);
            }
          } else {
            info[infoKey].children.push(item);
          }
        } else if (portComp === _portComp) {
          // Return loss - RLC port
          info.COMMRL.children.push(item)
        };
      }
    };
    FEXT.push(info.FEXT);
    NEXT.push(info.NEXT);
    IL.push(info.IL);
    RL.push(info.RL);
    DiffToComm.push(info.DiffToComm)
    COMMIL.push(info.COMMIL)
    COMMRL.push(info.COMMRL)
    CrossCoupling.push(info.CrossCoupling);
  };
  return { FEXT, NEXT, IL, RL, DiffToComm, COMMIL, COMMRL, CrossCoupling }
}

export function handleCPHYDefaultResultList(list, show, isUpdatePairGroup = true, pairGroup) {
  const signalMap = new Map();
  if (!list) {
    return { list: [], show: [] }
  }
  for (const item of list) {
    if (signalMap.has(item.children[0].fileId)) {
      const oldData = signalMap.get(item.children[0].fileId);
      oldData.push(item);
      signalMap.set(item.children[0].fileId, oldData)
    } else {
      signalMap.set(item.children[0].fileId, [item])
    }
  }

  let CPHYResultList = new Map(), _pairGroup = [];
  signalMap.forEach((value, key) => {
    const [, signal, pair] = key.split('::');
    const allRowName = value.map(item => item.rowName);
    const ele = {
      list: value,
      show: allRowName.filter(item => show.includes(item))
    }

    if (isUpdatePairGroup) {
      const p = {
        show: ele.show.length > 0 ? true : false,
        rowId: key,
        rowName: `${signal} (${pair})`,
        checked: false,
        indeterminate: ele.show.length > 0 && ele.show.length < allRowName.length
      }
      _pairGroup.push(p)
    }

    CPHYResultList.set(key, ele);
  })

  return {
    CPHYResultList,
    pairGroup: isUpdatePairGroup ? _pairGroup : pairGroup
  }
}