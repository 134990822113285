import {
  INIT_STACKUP_DATA,
  CHANGE_STACKUP_TABLE,
  SETTING_UNIT
} from './actionTypes';
import {
  CLEAN_STATUS
} from '../../../store/FastPI/actionTypes';
import { USER_LOGOUT } from '../../../../Login/actionTypes';
import { updateOldStackupData, updateStackupDataByType } from '../../../../../services/Stackup';

const defaultStackupState = {
  data: [],
  unit: null,
  loading: true,
}

const defaultState = {
  stackupObj: {}
}

const stackupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_STACKUP_DATA:
    case CHANGE_STACKUP_TABLE:
      return {
        ...state,
        stackupObj: updateOldStackupData(action.designID, state.stackupObj, action, defaultStackupState)
      }
    case SETTING_UNIT:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "unit", action.unit, defaultStackupState)
      }
    case CLEAN_STATUS:
      return {
        ...defaultState
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default:
      return state;
  }
}

export default stackupReducer;