import http from "../http";

/** 
 * Get pre layout content
 * 2021.03.17
*/
export function getAndesV2PreLayoutInfoById(id) {
  return http.get(`/my-project/my-design/andes/pre-layout/detail?id=${id}`);
}

/** 
 * update pre layout content
 * @param {object} data {id, name, version }
 * 2021.03.17
*/
export function updateAndesV2ePreLayout(data) {
  return http.post(`/my-project/my-design/andes/pre-layout`, { ...data });
}