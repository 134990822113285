export const OPEN_UPLOAD_PROGRESS_MODAL = 'open_upload_progress_modal';
export const UPLOAD_PROGRESS_CHANGE = 'upload_progress_changed';
export const SHOW_ERROR_MSG = 'show_error_msg';
export const CLOSE_UPLOAD_PROGRESS_MODAL = 'close_upload_progress_modal';

export const TRANSLATION_SUCCESS = 'translation_success';
export const TRANSLATION_RUNNING = 'translation_running';
export const TRANSLATION_FAILED = 'translation_failed';

export const GET_WORK_FLOWS = 'get_work_flows';
export const TRANSLATION_MONITOR = 'translation_monitor';
export const GET_TRANSLATION_MONITOR = 'get_translation_monitor';
export const UPLOAD_PROJECT_ID = 'upload_project_id';
export const ADD_MSG = 'add_msg';
export const CURRENT_UPLOAD_DESIGN_ID = 'current_upload_design_Id';