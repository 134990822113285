import {
  PortsGenerationSetup,
  AutoGeneratePorts,
  getDefaultReferenceNets,
  getDefaultPortSetupList,
  GAP,
  ALL_PINS,
  NEARBY_PINS,
  PIN_PER_REF_NET
} from '@/services/ExtractionPortsHelper';
import { sierraExtractionPortsType } from '@/pages/Sierra/constants';
import DesignInfo from '../pcbInfo';
import { updateSetupComponentsByPortType, getPortGenerateSetupList, CIRCUIT, PIN_GROUP } from '../../ExtractionPortsHelper';
import { userDefaultSettings } from '../../userDefaultSetting/userDefaultSettingCtrl';
import { CONNECTOR, IC } from '../../PCBHelper';

async function updateExtractionPortsSetup(_interface, pcbInfo, change = {}, userDefaultSetting) {
  if (!userDefaultSetting) {
    userDefaultSetting = userDefaultSettings.getSettings()
  }

  if (!_interface || !_interface.content) {
    return {};
  }
  const { changeRef = true, changeSetup = true } = change;
  const { powerNets, components, signals, channel } = _interface.content;
  let ports_generate_setup_list = _interface.content.ports_generate_setup_list;
  let referenceNets = _interface.content.referenceNets;
  if (!ports_generate_setup_list || !ports_generate_setup_list.length || changeSetup) {
    ports_generate_setup_list = getPortGenerateSetupList({ components, setup: null, setup_list: ports_generate_setup_list, userDefaultSetting })
  }
  if (!referenceNets || changeRef) {
    referenceNets = getDefaultReferenceNets(powerNets);
  }

  const data = getDefaultPortSetupList({
    components,
    signals,
    pcbInfo: pcbInfo,
    referenceNets: referenceNets ? referenceNets : [],
    designId: _interface.pcbId,
    ports_generate_setup_list,
    types: sierraExtractionPortsType,
    extractionType: channel ? channel.type : ""
  });

  const _components = updateSetupComponentsByPortType({
    components,
    designId: _interface.pcbId,
    ports_generate_setup_list,
    extractionType: channel ? channel.type : "",
    userDefaultSetting
  })
  return { ports_generate_setup_list, referenceNets, components: _components, ...data }
}

function judgeUpdateGapPortSetup(data, channelType) {
  const ports_generate_setup_list = data.ports_generate_setup_list || [];
  //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  //re generate port setups by port type is GAP, reference type is All pins
  let judge = false;
  ports_generate_setup_list.forEach(item => {
    if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
      judge = true;
    }
  });
  return judge;
}

function updateExtractionGapPortsSetup(data, designId) {
  let newData = { ...data };
  let ports_generate_setup_list = newData.ports_generate_setup_list;
  const referenceNets = newData.referenceNets;
  let port_setups = newData.port_setups;

  // //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  // //re generate port setups by port type is GAP, reference type is All pins
  ports_generate_setup_list.forEach(item => {
    if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
      item.setup = new PortsGenerationSetup({
        portType: GAP,
        referenceType: ALL_PINS,
      });
    }
  });
  const autoPorts = new AutoGeneratePorts();
  const pcbInfo = DesignInfo.getPCBInfo(designId);
  const _data = autoPorts.autoGeneratePorts(
    port_setups,
    pcbInfo,
    {
      ports_generate_setup_list,
      referenceNets,
      referenceZ0: port_setups[0].z0,
      designId: designId
    }
  );

  const warnings = autoPorts.getWarnings();
  const errors = autoPorts.getErrors();

  if (!errors || !errors.length) {
    newData.ports_generate_setup_list = _data.setupList;
    newData.port_setups = _data.port_setups;
  }

  return { newData, warnings, errors };
}

function getComponentsPortInfo(components) {
  return components.map(item => {
    return {
      ball_size: item.ball_size,
      ball_height: item.ball_height,
      gap_size: item.gap_size
    }
  })
}

function judgeUpdatePowerSISetup(ports_generate_setup_list) {
  const _ports_generate_setup_list = ports_generate_setup_list || [];
  for (let item of _ports_generate_setup_list) {
    if (![PIN_GROUP, CIRCUIT].includes(item.setup.portType)) {
      return true
    }
  }
  return false
}

function getDefaultPortsGenerateSetupList({ components }) {

  let ports_generate_setup_list = [];
  for (let comp of components) {
    if (![IC, CONNECTOR].includes(comp.type)) {
      continue;
    }

    const find = ports_generate_setup_list.find(it => it.component === comp.name);
    if (!find) {
      ports_generate_setup_list.push({
        component: comp.name,
        setup: new PortsGenerationSetup({})
      });
    }
  }
  return ports_generate_setup_list;
}

function getExtractionPortsSetup(data, designId) {
  let newData = { ...data };
  let ports_generate_setup_list = newData.ports_generate_setup_list;
  const referenceNets = newData.referenceNets;
  let port_setups = newData.port_setups,
    applyComponents = newData.applyComponents && newData.applyComponents.length ? newData.applyComponents : null;

  const setting = applyComponents ? {
    ports_generate_setup_list,
    referenceNets: referenceNets,
    referenceZ0: port_setups[0].z0,
    designId: designId,
    applyComponents
  } : {
    ports_generate_setup_list,
    referenceNets: referenceNets,
    referenceZ0: port_setups[0].z0,
    designId: designId
  }

  const autoPorts = new AutoGeneratePorts();
  const pcbInfo = DesignInfo.getPCBInfo(designId);
  const _data = autoPorts.autoGeneratePorts(
    port_setups,
    pcbInfo,
    setting
  );
  port_setups = _data.port_setups;
  ports_generate_setup_list = _data.setupList;

  const warnings = autoPorts.getWarnings();
  const errors = autoPorts.getErrors();

  if (!errors || !errors.length) {
    newData.ports_generate_setup_list = ports_generate_setup_list;
    newData.port_setups = port_setups;
  }

  return { newData, warnings, errors };
}

export {
  updateExtractionPortsSetup,
  judgeUpdateGapPortSetup,
  updateExtractionGapPortsSetup,
  getComponentsPortInfo,
  judgeUpdatePowerSISetup,
  getDefaultPortsGenerateSetupList,
  getExtractionPortsSetup
}