import { call, put, takeEvery, select, delay, takeLatest, fork, cancel } from 'redux-saga/effects';
import {
  UPDATE_PROJECT_MENU, OPEN_PROJECT, SHOW_SIERRA_RESULT, UPDATE_TRASH_MENU, SAVE_COMPONENTS_NETS_IN_PROJECT,
  CHANGE_VIEW_LIST, AUTO_GET_VERIFICATION_LIST, DOWNLOAD_DEBUG_MSG,
  LOAD_SWEEP_LIST, SAVE_SWEEP_NAME, DELETE_PCB, UPDATE_PCB_LAYOUT, OPEN_NEW_PAGE, CLEAR_REPORT_INFO,
  SAVE_REPORT_CONFIG,
  GET_REPORT_CONFIG,
  GET_REPORT,
  GET_REPORT_LOG
} from './actionTypes';
import {
  projectMenu, saveOpenProjectInfo, openPage, trashMenu, updatePCBComponentsNets, changeTreeSelected, autoGetVerificationList, refreshPCB,
  updateCurrentVerificationStatus, debugDownloadLog, closeDebugDownloadMsg,
  updateProjectMenu, changeViewList, updatePreLayoutNets, showResult,
  updateReportInfo,
  getReportLogMsg,
  clearReportInfo
} from './action';
import { PROJECT, PCBS, PCB, RESULT, VERIFICATIONS, VERIFICATION, MY_TRASH, PCB_PRE_LAYOUT, VERIFICATION_GROUP } from '@/constants/treeConstants';
import { VERIFY_SUCCESS, VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { changeProject, changeVerification, closeTabFooter, openTabFooter, changeTabMenu } from '../../../tabMonitor/action';
import {
  changeVerificationList,
  existResult,
  getInterfaceMonitor
} from '../simulation/action';
import { expandMenu, getVerificationContent } from '../sierra/action';
import {
  getProjectList,
  getSierraProjectPromise,
  getSierraTrashList,
  getProjectByProjectId,
  SweepData,
  editExperimentGroupList,
  deleteDesignInProject,
  getReportStatus,
  getReportFile,
  saveReportConfigPromise,
  getReportConfigPromise,
  getReportLog
} from '@/services/Sierra';
import LayoutData from '@/services/data/LayoutData';
import DesignInfo from '@/services/Sierra/pcbInfo';
import projectDesigns from '@/services/helper/projectDesigns';
import { getTouchstoneFile } from '@/services/Sierra/results';
import { saveResultChannel } from '../result/action';
import { getCurrentLog, changeUploadMes } from '../simulation/action';
import { debugDownMsg } from '@/services/Sierra';
import { SIERRA } from '@/constants/pageType';
import { message } from 'antd';
import { strDelimited } from '@/services/helper/split';
import designConstructor from '@/services/helper/designConstructor';
import { COPY_FINISH, COPYING } from '@/constants/copyStatus';
import { addIdentificationAction } from '@/services/Sierra/library/libraryData';
import { cleanStatus } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import { updatePCBRefreshStatus } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import { PCB_ONLY, PCB_TOP_BOTTOM, PCB_LEFT_RIGHT } from '../../../../constants/layoutConstants';
import { updateTreeAfterChangePCBLayout, updateTreeSelectKeys } from '../../../../services/helper/filterHelper';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import { EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP } from '../../../../constants/treeConstants';
import { clearMultiSetupInfo, updateMultiSetupSimErrorMsg } from '../multiInterface/action';
import { getVerificationLogMsgByStatus } from '../../../../services/Sierra/helper/monitorHelper';
import canvas from '@/services/LayoutCanvas';
import projectVerifications from '../../../../services/Sierra/data/projectVerifications';
import projects from '../../../../services/Sierra/data/projects';
import { downloadFileByChunk, getDownloadFileSize } from '../../../../services/helper/downloadHelper';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { EXPERIMENT_GROUP_VERSION } from '../../../../version';

function* _updateProjectMenu(action) {
  const { SierraReducer: { project, sierra } } = yield select();
  let { treeItems } = project;
  let res = null;
  try {
    res = yield call(getProjectList);
  } catch (error) {
    console.error(error)
    return;
  }

  if (!res) {
    return;
  }

  //get openProject data
  const { obj, openProjectId, doNotCopy } = action;
  let id = null;
  let verificationName = null;
  let verificationId = null;
  let openProjectData = null;
  if (openProjectId) {
    id = openProjectId;
  } else if (obj && obj.openProjectId) {
    id = obj.openProjectId;
    verificationName = obj.verificationName;
    verificationId = obj.verificationId;
  } else if (obj && obj.isImport && res.length) {
    // import project, open the last project
    id = res[0].id
  }
  if (id) {
    let _openProject = treeItems[1].children.filter(item => item.id === id)
    openProjectData = _openProject && _openProject.length > 0 ? _openProject[0].children : null;
  }
  // treeItems[1] - 'projects'
  treeItems[1].children = res.map(item => ({
    id: item.id,
    name: item.name,
    key: PROJECT + '-' + item.id,
    dataType: PROJECT,
    children: item.id === id && openProjectData ? openProjectData : [{
      name: 'PCB',
      key: PCBS + '-' + item.id,
      dataType: PCBS
    },
    {
      name: 'Interface',
      key: VERIFICATIONS + '-' + item.id,
      dataType: VERIFICATIONS
    }]
  }));
  let projectList = [];
  let copyProjectList = [];
  res.forEach(item => {
    //Ready:category=1  Copy:category=3
    if (item.category === COPYING) {
      copyProjectList.push({
        name: item.name,
        id: item.id,
        category: item.category
      })
    }
    projectList.push({
      name: item.name,
      id: item.id
    })
  })
  projects.setProjects(treeItems[1].children);
  yield put(projectMenu({ treeItems: [...treeItems], projectList, copyProjectList }));

  if (id) {
    yield call(openProject, { id: id, verificationName: verificationName, verificationId: verificationId });

    let { expandedKeys } = sierra;
    const key = `${PROJECT}-${id}`;

    if (!expandedKeys.includes(key)) {
      expandedKeys = expandedKeys.filter(item => {
        const [_key, _id] = strDelimited(item, "-");

        if (!(_key === PROJECT && _id !== id)) {
          return item;
        }
        return false;
      });
      expandedKeys.push(key);
    }
    yield put(expandMenu(expandedKeys));
  }

  let notCopy = false;
  if (doNotCopy) {
    notCopy = true;
  }

  if (copyProjectList && copyProjectList.length > 0 && !notCopy) {
    yield fork(_getCopyProjectStatus, { copyProjectList });
  }

}

function* _getCopyProjectStatus(action) {
  let { copyProjectList } = action;
  if (!copyProjectList || !copyProjectList.length) {
    return;
  }
  while (true) {
    let id = null;
    let len = copyProjectList.length;
    if (copyProjectList && copyProjectList.length === 0) {
      break;
    };
    yield delay(3000);
    yield* copyProjectList.map(function* (item) {
      if (item.id) {
        const project = yield call(getProjectByProjectId, item.id);
        if (project && project.category) {
          item.category = project.category;
          if (project.category === COPY_FINISH) {
            id = project.id;
          }
        }
      }
    });
    copyProjectList = copyProjectList.filter(item => item.category === COPYING);
    if (copyProjectList.length !== len) {
      yield call(_updateProjectMenu, { openProjectId: id, doNotCopy: true })
    }
  }
}

function* openProject(action) {
  const { SierraReducer: { project, sierra: { expandedKeys }, multiInterfaceSetup: { multiSetupGroup } } } = yield select();
  let { treeItems, selectedKeys } = project;
  let { id, verificationName, verificationId } = action;

  let _treeItems = [...treeItems];
  // treeItems[1] - 'projects'
  const findIndex = _treeItems[1].children.findIndex(item => item.id === id);
  let res = null
  try {
    res = yield call(getSierraProjectPromise, id);
  } catch (error) {
    console.error(error)
  }

  if (!res) {
    return;
  }

  const designs = (res.designs || []).map(item => {
    const key = item.vendor === PRE_LAYOUT ? PCB_PRE_LAYOUT : PCB;
    return {
      id: item.id,
      subId: item.subId,
      key: key + '-' + item.id,
      name: item.name,
      dataType: key,
      designVersion: item.designVersion,
      projectId: item.projectId,
      vendor: item.vendor,
      nodeClass: 'tree-node-pcb-name',
      category: item.category,
      multiZone: item.multiZone
    }
  });
  designConstructor.addDesigns(designs);
  let verifications = [];
  const expandedVerification = expandedKeys
    .filter(item => item.includes(VERIFICATION))
    .map(item => { const id = strDelimited(item, "-", { returnIndex: 1 }); return id });
  let verificationGroupMap = new Map(), notHasGroupVerList = [];
  yield* (res.verifications || []).map(function* (item) {
    /*   richTextData.getData(SIERRA,VERIFICATION, item.id); */
    let experiments = [];
    if (expandedVerification.includes(item.id)) {
      experiments = yield call(SweepData.getData, item.id);
    }

    const itemVerification = {
      id: item.id,
      key: VERIFICATION + '-' + item.id,
      name: item.name,
      dataType: VERIFICATION,
      subId: item.subId,
      /*  verificationId: item.verificationId, */
      status: item.status,
      readyForSim: item.readyForSim,
      nodeClass: 'tree-node-interface-name',
      type: item.tag,
      group: item.group,
      children: [{
        name: 'Result',
        key: RESULT + '-' + item.id /* + '-' + item.verificationId */,
        dataType: RESULT,
        status: item.status,
        nodeClass: 'tree-node-interface-result-name',
      }, ...experiments]
    }
    if (item.group && verificationGroupMap.has(item.group)) {
      verificationGroupMap.set(item.group, [...verificationGroupMap.get(item.group), JSON.parse(JSON.stringify(itemVerification))]);
    } else if (item.group) {
      verificationGroupMap.set(item.group, [JSON.parse(JSON.stringify(itemVerification))]);
    } else {
      notHasGroupVerList.push(JSON.parse(JSON.stringify(itemVerification)))
    }
    verifications.push(itemVerification)
    projectVerifications.setVerification(item.id, itemVerification);
  });

  let treeVerList = []
  if (Array.from(verificationGroupMap.keys()).length) {
    treeVerList = [...Array.from(verificationGroupMap.keys()).map(item => {
      return {
        id: item,
        key: VERIFICATION_GROUP + '-' + item,
        name: item,
        dataType: VERIFICATION_GROUP,
        nodeClass: 'tree-node-interface-name',
        children: [...verificationGroupMap.get(item)]
      }
    }), ...notHasGroupVerList]
  } else {
    treeVerList = verifications;
  }

  if (findIndex > -1) {
    _treeItems[1].children[findIndex].children = [{
      name: 'PCB',
      key: PCBS + '-' + id,
      dataType: PCBS,
      children: designs,
    },
    {
      name: 'Interface',
      key: VERIFICATIONS + '-' + id,
      dataType: VERIFICATIONS,
      children: [...treeVerList, new addIdentificationAction(id)]
    }];
  }

  projectVerifications.setProject(id, verifications, treeVerList);

  yield put(projectMenu({ treeItems: [..._treeItems] }));
  let name = "";
  if (findIndex > -1) {
    name = _treeItems[1].children[findIndex].name;
  }
  yield put(changeProject(name));
  const { SierraReducer: { SierraUploadReducer: { disabled, uploadProjectId }, project: { viewList } } } = yield select();
  if (disabled && uploadProjectId === id) {
    yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'upload', verificationId: null, projectId: id }));
    yield put(openTabFooter());
  }
  verifications.forEach(i => {
    if (i.id === verificationId) {
      verificationName = i.name
    }
  })

  if (verificationId && (selectedKeys.includes(`${VERIFICATION}-${verificationId}`) || selectedKeys.includes(`${RESULT}-${verificationId}`))) {
    yield put(changeTabMenu({
      selectKeys: ['monitor'],
      menuType: 'simulation',
      verificationId,
      projectId: id,
      verificationName
    }));
    if (selectedKeys.includes(`${VERIFICATION}-${verificationId}`)) {
      yield put(openTabFooter());
    }
    //get workflow
    yield put(getInterfaceMonitor(verificationId));
  }

  if (viewList.includes(MULTI_INTERFACE_SETUP)) {
    yield put(changeTabMenu({
      selectKeys: ['monitor'],
      menuType: 'group',
      verificationId: multiSetupGroup,
      projectId: id,
      verificationName: multiSetupGroup,
    }));
    yield put(openTabFooter());
  }

  projectDesigns.set(id, designs)

  yield put(changeVerificationList([]));
  yield put(saveOpenProjectInfo({ id, name, verifications: verifications }));
  yield put(autoGetVerificationList(id));
}

const projectIndex = 1, interfaceIndex = 1;
function* loadVerificationSweep(action) {
  const { verificationId } = action;
  const { SierraReducer: { project: { treeItems, currentProjectId, currentProjectVerifications } } } = yield select();
  const _treeItems = [...treeItems];
  const findProjectIndex = _treeItems[projectIndex].children.findIndex(child => child.id === currentProjectId);
  if (findProjectIndex > -1 && _treeItems[projectIndex].children[findProjectIndex].children) {
    const interfaceList = _treeItems[projectIndex].children[findProjectIndex].children[interfaceIndex].children || [];
    const ver = currentProjectVerifications.find(it => it.id === verificationId);
    let groupIndex = -1, verificationIndex = -1;
    if (ver && ver.group) {
      groupIndex = interfaceList.findIndex(child => child.id === ver.group);
      verificationIndex = groupIndex > -1 ? interfaceList[groupIndex].children.findIndex(child => child.id === verificationId) : -1;
    } else {
      verificationIndex = interfaceList.findIndex(child => child.id === verificationId);
    }
    if (verificationIndex > -1) {
      const experiments = yield call(SweepData.getData, verificationId);
      const result = groupIndex > -1
        ? interfaceList[groupIndex].children[verificationIndex].children.filter(item => item.dataType === RESULT)
        : interfaceList[verificationIndex].children.filter(item => item.dataType === RESULT);
      if (groupIndex > -1) {
        interfaceList[groupIndex].children[verificationIndex].children = result;
        interfaceList[groupIndex].children[verificationIndex].children.push(...experiments);
      } else {
        interfaceList[verificationIndex].children = result;
        interfaceList[verificationIndex].children.push(...experiments);
      }

      _treeItems[projectIndex].children[findProjectIndex].children[interfaceIndex].children = interfaceList;
      yield put(projectMenu({ treeItems: [..._treeItems] }));
    }
  }
}

function* getVerifications(action) {
  const { projectId, currentSimInfo } = action;
  let delayTime = 30000;
  if (!currentSimInfo) {
    let first = true;
    let changedStatus = true;
    while (changedStatus) {
      if (!first) {
        yield delay(delayTime);//4000
      }
      first = false;
      const { SierraReducer: { project, multiInterfaceSetup: { simCheckLogs } }, LoginReducer: { pageType } } = yield select();
      const currentProjectId = project.currentProjectId;
      const verificationId = project.verificationId;
      const verifications = project.currentProjectVerifications;
      let _simCheckLogs = [...(simCheckLogs || [])];
      // If no project is expanded, stop polling
      if (!currentProjectId || projectId !== currentProjectId) {
        return;
      }

      if (pageType !== SIERRA) {
        return;
      }
      let { treeItems } = project;

      let _treeItems = [...treeItems];
      // treeItems[1] - 'projects'
      const findProjectIndex = _treeItems[1].children.findIndex(item => item.id === projectId);
      const res = yield call(getSierraProjectPromise, projectId);

      if (findProjectIndex > -1 && res && res.verifications) {
        let statusList = res.verifications.map(item => item.status);

        if (statusList.includes(VERIFY_RUNNING) || statusList.includes(WAITING)) {
          delayTime = 4000;
          changedStatus = true;
        } else {
          delayTime = 30000;
          changedStatus = false;
        }
        const verificationIndex = res.verifications.findIndex(i => i.id === verificationId);
        if (verificationId && verificationIndex > -1) {
          //update current verification status
          yield put(updateCurrentVerificationStatus(res.verifications[verificationIndex].status))
        }
        let projectChildren = (_treeItems[1].children[findProjectIndex] || {}).children || [];
        const interfaceList = ((projectChildren || []).find(item => item.name === 'Interface') || {}).children || [];

        interfaceList.forEach(item => {
          if (item.dataType === VERIFICATION_GROUP) {
            item.children.forEach(it => {
              const index = res.verifications.findIndex(i => i.id === it.id);
              if (index > -1 && (res.verifications[index].status !== it.status || res.verifications[index].readyForSim !== it.readyForSim)) {
                it.status = res.verifications[index].status;
                it.readyForSim = res.verifications[index].readyForSim;
                it.simStatus = null;
                if (it.children[0]) {
                  it.children[0].status = res.verifications[index].status;
                }
                const ver = projectVerifications.getVer(it.id);
                ver && projectVerifications.setVerification(it.id, { ...ver, status: it.status, readyForSim: it.readyForSim });
                const vIndex = verifications.findIndex(veItem => it.id === veItem.id);
                if (vIndex > -1) {
                  verifications[vIndex].status = it.status;
                  verifications[vIndex].readyForSim = it.readyForSim;
                  verifications[vIndex].simStatus = null;
                }
              }

              if (!!_simCheckLogs.length) {
                const simIndex = (_simCheckLogs || []).findIndex(_it => _it.verificationId === it.id
                  && (!_it.errors || !_it.errors.length)
                  && (!_it.stackupError || !_it.stackupError.length));
                if (simIndex > -1) {
                  const prevLog = _simCheckLogs[simIndex].logs ? _simCheckLogs[simIndex].logs[0] || "" : ""
                  _simCheckLogs[simIndex].logs = [getVerificationLogMsgByStatus(res.verifications[index].status, prevLog)];
                }
              }
            })
          } else {
            const index = res.verifications.findIndex(i => i.id === item.id);
            if (index > -1 && (res.verifications[index].status !== item.status || res.verifications[index].readyForSim !== item.readyForSim)) {
              item.status = res.verifications[index].status;
              item.readyForSim = res.verifications[index].readyForSim;
              item.simStatus = null;
              if (item.children[0]) {
                item.children[0].status = res.verifications[index].status;
              }
              const ver = projectVerifications.getVer(item.id);
              ver && projectVerifications.setVerification(item.id, { ...ver, status: item.status, readyForSim: item.readyForSim });
              const vIndex = verifications.findIndex(it => it.id === item.id);
              if (vIndex > -1) {
                verifications[vIndex].status = item.status;
                verifications[vIndex].readyForSim = item.readyForSim;
                verifications[vIndex].simStatus = null;
              }
            }
            item.simStatus = null;
            if (!!_simCheckLogs.length) {
              const _index = (_simCheckLogs || []).findIndex(_it => _it.verificationId === item.id
                && (!_it.errors || !_it.errors.length)
                && (!_it.stackupError || !_it.stackupError.length));
              if (_index > -1) {
                const prevLog = _simCheckLogs[_index].logs ? _simCheckLogs[_index].logs[0] || "" : ""
                _simCheckLogs[_index].logs = [getVerificationLogMsgByStatus(res.verifications[index].status, prevLog)];
              }
            }
          }
        })
        if (_treeItems[1].children
          && _treeItems[1].children[findProjectIndex]
          && _treeItems[1].children[findProjectIndex].children
          && _treeItems[1].children[findProjectIndex].children[interfaceIndex]
          && _treeItems[1].children[findProjectIndex].children[interfaceIndex].children) {
          _treeItems[1].children[findProjectIndex].children[interfaceIndex].children = interfaceList
        }

      }
      yield put(projectMenu({ treeItems: [..._treeItems], verifications }));
      yield put(updateMultiSetupSimErrorMsg(_simCheckLogs))
    }
  } else {
    const { SierraReducer: { project } } = yield select();
    let { treeItems } = project;

    let _treeItems = [...treeItems];
    // treeItems[1] - 'projects'
    const findIndex = _treeItems[1].children.findIndex(item => item.id === projectId);
    if (findIndex > -1) {
      let interfaceList = _treeItems[1].children[findIndex].children.find(item => item.name === 'Interface').children || [];
      interfaceList.forEach(item => {
        if (item.dataType === VERIFICATION_GROUP) {
          item.children.forEach(it => {
            const index = currentSimInfo.findIndex(i => i.verificationId === it.id);
            if (index > -1 && (!it.simStatus || (it.simStatus && currentSimInfo.status !== it.simStatus))) {
              it.simStatus = currentSimInfo[index].status;
            }
          })
        } else {
          const index = currentSimInfo.findIndex(i => i.verificationId === item.id);
          if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo.status !== item.simStatus))) {
            item.simStatus = currentSimInfo[index].status;
          }
        }
      })
      _treeItems[1].children[findIndex].children[interfaceIndex].children = interfaceList
    }
    yield put(projectMenu({ treeItems: [..._treeItems] }));
  }
}

function* showSierraResult(action) {
  const { view, verificationId, getStatus, verificationName, rename, isMultiSetup = false } = action;
  const promise = yield call(checkVerificationStatus, verificationId);
  const getChannelList = yield call(getTouchstoneFile, verificationId);
  let channelList = getChannelList;
  const { SierraReducer: { project: { currentProjectId } } } = yield select();
  const currentProjectDesigns = projectDesigns.getAvailableDesigns(currentProjectId)
  let newChannel = [];
  if (currentProjectDesigns.length > 0) {
    channelList.forEach(item => {
      const [key, pcbSubId] = strDelimited(item.fileName, "_");
      item.pcb = "";
      const findPCB = currentProjectDesigns.find(item => item.subId === pcbSubId);
      if (findPCB) {
        item.pcb = findPCB.name;
      } else {
        item.pcb = key;
      };
      newChannel.push(item);
    })
  };
  let resultExist = false;
  if (promise && promise.status) {
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    }
  };
  yield put(existResult(resultExist));
  yield put(saveResultChannel(newChannel));
  yield put(openPage(view, { verificationId, getStatus, verificationName, rename, isMultiSetup }))
}

function* updateTrashMenu() {
  const { SierraReducer: { project } } = yield select();
  const { treeItems } = project;
  let newData = [...treeItems];
  const res = yield call(getSierraTrashList);
  newData[2].children = [];

  if (res.length < 1) {
    newData[2].children = [];
  } else {
    res.forEach(item => {
      newData[2].children.push({
        name: item.name,
        key: `project-${item.id}`,
        id: item.id,
        dataType: MY_TRASH
      });
    });
  }
  yield put(trashMenu({ treeItems: [...newData] }));
};

export function* saveComponentsNets(action) {
  const { pcbIds, status } = action;
  let { SierraReducer: { project: { pcbComponentsNets, preLayoutReady } } } = yield select();
  const _filter = status ? pcbIds : pcbIds.filter(id => !pcbComponentsNets.includes(id));
  let _preLayoutReady = [...preLayoutReady];
  yield* _filter.map(function* (id) {
    try {
      const vendor = designConstructor.getDesignVendor(id);
      if (vendor === PRE_LAYOUT) {
        _preLayoutReady.push(id);
        yield call(preLayoutData.getPreLayout, id);
      } else {
        yield call(auroraDBJson.getAuroraJson, id, {}, SIERRA)
        yield call(getLayoutDB, id, false);
        const _DesginData = LayoutData.getLayout(id);
        const info = {
          netsList: [..._DesginData.mNetManager.mNetList.mVals],
          layers: [..._DesginData.mLayerMgr.mMetalLayers]
        };
        pcbComponentsNets.push(id);
        DesignInfo.savePCBInfo(id, info);
      }
    } catch (error) {
      console.error(error)
    };
  });
  yield put(updatePreLayoutNets([...new Set(_preLayoutReady)]))
  pcbComponentsNets = [...new Set(pcbComponentsNets)];
  yield put(updatePCBComponentsNets(pcbComponentsNets));
  if (status) {
    const { SierraReducer: { project: { selectedKeys, currentProjectId } } } = yield select();
    yield put(autoGetVerificationList(currentProjectId));
    yield put(refreshPCB(true));
    if (pcbIds[0]) {
      //clean pcb state
      yield put(cleanStatus(pcbIds[0]));
      yield put(updatePCBRefreshStatus(true, pcbIds[0]));
      canvas && canvas.clearLayout(pcbIds[0]);
    }
    if (selectedKeys.length > 0) {
      yield* selectedKeys.map(function* (item) {
        if (item) {
          const [key, id] = strDelimited(item, "-");
          if (key === VERIFICATION) {
            yield put(getVerificationContent(id, true));
            const { SierraReducer: { project: { currentProjectId, currentProjectVerifications } } } = yield select();
            const current = currentProjectVerifications.find(item => item.id === id);
            if (current) {
              let verificationName = current.name;
              yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: id, projectId: currentProjectId }));
              yield put(changeVerification(verificationName));
              yield put(openTabFooter());
            }
          } else if (key === RESULT) {
            yield put(openPage(RESULT, { verificationId: id }));
          }
        }
      })
    }
  }
}

function getLayoutDB(id, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    LayoutData.LoadLayoutDB(id, onlyCompLayer).then(res => {
      resolve(true);
    }, error => {
      resolve(true);
    })
  })
}

// Open page after change view layout
function* _changeViewList(action) {
  const { viewList, viewType } = action;
  //clear multi setup info
  if (viewList && viewList.includes && !viewList.includes(MULTI_INTERFACE_SETUP)) {
    yield put(clearMultiSetupInfo());
  }
  if (!action.viewType) {
    return;
  }
  const { SierraReducer: { project, sierra: { expandedKeys, sierraInfo } } } = yield select();
  const { verificationId = '', selectedKeys, currentProjectVerifications, currentProjectId } = project;
  let newKeys = [...selectedKeys], PCBsInInterface = [];
  if (sierraInfo && sierraInfo.PCBsInInterface) {
    PCBsInInterface = sierraInfo.PCBsInInterface;
  }
  switch (viewType) {
    case PCB:
      if (!expandedKeys.includes(`${PCBS}-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `${PCBS}-${currentProjectId}`]));
      }
      let pcbId;
      const currentProjectDesigns = projectDesigns.getAvailableDesigns(currentProjectId).filter(d => d.vendor !== PRE_LAYOUT);
      if (selectedKeys.every(d => d.split('-')[0] !== PCB || d.split('-')[0] !== PCB_PRE_LAYOUT) && currentProjectDesigns.length > 0) {
        pcbId = currentProjectDesigns[0].id;
        if (PCBsInInterface.length && PCBsInInterface.length > 0) {
          const id = PCBsInInterface.find(i => currentProjectDesigns.find(d => d.id === i));
          pcbId = id || pcbId
        }
        if (viewList.length === 1) {
          yield put(closeTabFooter());
          newKeys = [`${PCB}-${pcbId}`];
        } else {
          newKeys.push(`${PCB}-${pcbId}`);
        }
        yield put(changeTreeSelected([...newKeys]));
      }
      break;
    case VERIFICATION:
      if (!expandedKeys.includes(`${VERIFICATIONS}-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `${VERIFICATIONS}-${currentProjectId}`]));
      }
      let openVerificationId, verificationName;
      if (!currentProjectVerifications.length) {
        return;
      }
      const find = currentProjectVerifications.find(item => item.id === verificationId);
      if (find) {
        openVerificationId = verificationId;
        verificationName = find.name;
      } else {
        openVerificationId = currentProjectVerifications[0].id;
        verificationName = currentProjectVerifications[0].name;
      }

      const key = `${VERIFICATION}-${openVerificationId}`;
      if (!selectedKeys.includes(key)) {
        newKeys = newKeys.filter(k => ![VERIFICATION, VERIFICATION_GROUP].includes(k.split('-')[0]));
        newKeys.push(`${VERIFICATION}-${openVerificationId}`);
      }

      yield put(changeTreeSelected([...newKeys]));
      yield put(openPage(VERIFICATION, { verificationId: openVerificationId }));
      yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: openVerificationId, projectId: currentProjectId }));
      yield put(changeVerification(verificationName));
      yield put(openTabFooter());
      yield put(changeUploadMes(null))
      yield put(getCurrentLog(openVerificationId));
      break;
    case PCB_PRE_LAYOUT:
      if (!expandedKeys.includes(`${PCBS}-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `${PCBS}-${currentProjectId}`]));
      }
      const currentProjectPreLayout = projectDesigns.getAvailableDesigns(currentProjectId).filter(d => d.vendor === PRE_LAYOUT);
      if (selectedKeys.every(d => d.split('-')[0] !== PCB_PRE_LAYOUT || d.split('-')[0] !== PCB) && currentProjectPreLayout.length > 0) {
        pcbId = currentProjectPreLayout[0].id;
        if (PCBsInInterface.length && PCBsInInterface.length > 0) {
          const id = PCBsInInterface.find(i => currentProjectPreLayout.find(d => d.id === i));
          pcbId = id || pcbId
        }
        if (viewList.length === 1) {
          yield put(closeTabFooter());
          newKeys = [`${PCB_PRE_LAYOUT}-${pcbId}`];
        } else {
          newKeys.push(`${PCB_PRE_LAYOUT}-${pcbId}`);
        }
        yield put(changeTreeSelected([...newKeys]));
        yield put(openPage(PCB_PRE_LAYOUT, { id: pcbId }));
      }
      break;
    default: return;
  }
};

function* downloadDebugLog(action) {
  const { project, fileName, verificationId, downloadType } = action;
  let ready = false, first = true, count = 0;
  while (!ready) {
    count++;
    if (!first) {
      yield delay(2000);
    };
    if (first) {
      first = false;
    };
    if (count > 90) {
      // time out
      return;
    }
    // { data: [], ready: true }
    const response = yield call(debugDownMsg, verificationId, downloadType);
    // debugDownloadMsg: { verificationId: { downloadType: { data: [], ready: true } } }
    let { SierraReducer: { project: { debugDownloadMsg } } } = yield select();
    if (!debugDownloadMsg) {
      debugDownloadMsg = [];
    };
    if (!debugDownloadMsg[verificationId]) {
      debugDownloadMsg[verificationId] = {}
    };
    const findIndex = debugDownloadMsg.findIndex(item => item.verificationId === verificationId && item.downloadType === downloadType);
    if ((!response || (response && !response.data)) && count > 2) {
      message.error('Can not get download log.')
      if (!debugDownloadMsg.length) {
        yield delay(2000);
        yield put(closeDebugDownloadMsg())
      }
      return;
    }
    if (response && response.data) {
      ready = response.ready;
      if (findIndex > -1) {
        debugDownloadMsg[findIndex] = { ...debugDownloadMsg[findIndex], ...response };
      } else {
        const data = { project, fileName, verificationId, downloadType, ...response, show: true };
        debugDownloadMsg.push(data);
      }
      yield put(debugDownloadLog(debugDownloadMsg));
    };
  }
}

function* renameSweep(action) {
  const { name, info } = action;
  const { id, verificationId } = info;
  try {
    const sweepItem = SweepData.getSweep(id, verificationId) || {}
    yield call(editExperimentGroupList, { id, name, verificationId, version: sweepItem.version || EXPERIMENT_GROUP_VERSION });
    SweepData.rename(id, name, verificationId);
    yield call(loadVerificationSweep, { verificationId });
  } catch (err) {
    return;
  }
}

function* _deletePCB(action) {
  const { id, dataType } = action.item;
  const { SierraReducer: { project: { currentProjectId, verificationId, viewList, selectedKeys },
    sierra: { sierraInfo } } } = yield select();
  try {
    yield call(deleteDesignInProject, id)
  } catch (error) {
    console.error(error);
  }
  const key = dataType === PCB_PRE_LAYOUT ? PCB_PRE_LAYOUT : PCB;
  // Update project menu list
  yield put(updateProjectMenu({ openProjectId: currentProjectId, verificationId }))
  //  Update selected keys
  const newKeys = selectedKeys.filter(k => k !== `${key}-${id}`);
  yield put(changeTreeSelected([...newKeys]));
  let _viewList = [...viewList];
  if (newKeys.every(k => !k.includes(`${key}-`))) {
    _viewList = _viewList.filter(item => item !== key);
  }
  yield put(changeViewList(_viewList));
  //clean pcb state
  yield put(cleanStatus(id));
  // delete designConstructor data
  designConstructor.delDesign(id)

  //update interface json when delete pcb in interface
  if (verificationId) {
    const pcbList = sierraInfo && sierraInfo.PCBsInInterface ? sierraInfo.PCBsInInterface : [];
    if (pcbList.includes(id)) {
      yield put(getVerificationContent(verificationId, true));
    }
  }
}

function* _updatePCBLayout(action) {
  const { pcbLayout, prePcbLayout } = action;
  // prePcbLayout b,c -> a: close PCB
  const { SierraReducer: { project } } = yield select();
  let { viewList, selectedKeys } = project;
  if (pcbLayout === PCB_ONLY && [PCB_TOP_BOTTOM, PCB_LEFT_RIGHT].includes(prePcbLayout)) {
    const data = updateTreeAfterChangePCBLayout(selectedKeys, viewList);
    if (data) {
      const { _selectedKeys, _viewList } = data;
      yield put(changeTreeSelected(_selectedKeys));
      yield put(changeViewList(_viewList));
    }
  }
}

function* _openPageV2(action) {
  const { pageType, id } = action;

  const { SierraReducer: { project } } = yield select();
  let { viewList, layout, selectedKeys, pcbLayout, verificationId, currentProjectVerifications } = project;
  //update viewList
  let list = [...viewList, pageType];
  list = [...new Set(list)];
  list = list.filter(item => ![RESULT, VERIFICATION, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
  list.push(pageType);
  if (layout === 3) {
    list = [pageType]
  }
  yield put(changeViewList(list));

  //if selectedKeys not includes key,update selectedKeys
  if (!selectedKeys.includes(`${pageType}-${id}`)) {
    let _selectedKeys = [...selectedKeys]
    _selectedKeys = updateTreeSelectKeys({
      prevSelectedKeys: [...selectedKeys],
      key: pageType,
      eventKey: `${pageType}-${id}`,
      selected: true,
      layout,
      pcbLayout,
      productType: SIERRA
    });
    yield put(changeTreeSelected(_selectedKeys));
  }
  switch (pageType) {
    case VERIFICATION:
      yield put(openPage(VERIFICATION, { verificationId: id }));
      break;
    case RESULT:
      const verificationName = currentProjectVerifications.find(item => item.id === id).name;
      if (id !== verificationId) {
        yield put(showResult('result', { verificationId: id, verificationName, getStatus: true }));
      } else {
        yield put(showResult('result', { verificationId: id, verificationName }));
      }
      yield put(closeTabFooter());
      break;
    case EXPERIMENTS:
      yield put(openPage(EXPERIMENTS, { id }));
      break;
    case EXPERIMENTS_RESULT:
      yield put(openPage(EXPERIMENTS_RESULT, { id }));
      yield put(closeTabFooter());
      break;
    default: break;
  }
}
let reportTask = null;
function* _getReport(action) {
  const { projectId, format, fileName } = action;
  reportTask = yield fork(_getRockyReportFlow, { projectId, format, fileName });
}

function* _getRockyReportFlow(action) {
  const { projectId, format, fileName } = action;
  let status = 'running';
  let progress = 0;
  while (status === 'running') {
    yield delay(3000);
    try {
      const { SierraReducer: { project: { currentProjectId } } } = yield select();
      if (currentProjectId !== projectId) {//open other project
        yield put(clearReportInfo());
        return;
      }
      const res = yield call(getReportStatus, projectId);;
      status = res.status;
      progress = res.progress;
      if (progress <= 98) {
        yield put(updateReportInfo({ reportProgress: progress }));
      }
      //GET LOG
      yield put(getReportLogMsg(projectId))
    } catch (error) {
      status = 'failed';
      //GET LOG
      yield put(getReportLogMsg(projectId))
    }
  }

  yield put(updateReportInfo({ reportProgress: 99 }));
  yield call(_getReportFileByFormat, { projectId, status, format, fileName });
  yield delay(500);
  //GET LOG
  yield put(getReportLogMsg(projectId))
}

function* _getReportLog(action) {
  const { projectId } = action;
  try {
    const logRes = yield call(getReportLog, projectId);
    const { SierraReducer: { project: { reportInfo } } } = yield select();
    const prevLog = reportInfo.reportLog || [];
    yield put(updateReportInfo({ reportLog: logRes || prevLog }));
  } catch (error) {
    console.error(error)
  }
}

function* _getReportFileByFormat(action) {
  const { projectId, status, format, fileName } = action;
  const msg = status === 'failed'
    ? `Download report in ${format.toUpperCase()} format failed!`
    : `Download report in ${format.toUpperCase()} format successfully!`
  try {
    //download pptx or pdf
    const downloadKey = yield call(getReportFile, { projectId });
    if (downloadKey) {
      const fileSizeInfo = yield call(getDownloadFileSize, downloadKey);
      const fileSize = fileSizeInfo ? fileSizeInfo.size : 0;

      const { SierraReducer: { project: { currentProjectId } } } = yield select();
      if (currentProjectId !== projectId) {//open other project
        yield put(clearReportInfo());
        return;
      }
      if (fileSize > 1024 * 1024 * 1024) {
        yield call(downloadFileByChunk, {
          fileSize,
          downloadKey,
          fileName: `${fileName}.zip`,
          msg,
          updateDownloadProgress: (params) => updateProgress(params, { format, reportStatus: status })
        });
      } else {
        const token = localStorage.getItem('token')
        const a = document.createElement('a');
        a.href = `api/v3/download/file?downloadKey=${downloadKey}&access_token=${token}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        yield put(updateReportInfo({
          reportProgress: 100,
          reportMessage: msg
        }));
      }
    } else {
      yield put(updateReportInfo({
        reportMessage: `Download report in ${format.toUpperCase()} format failed!`
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(updateReportInfo({
      reportMessage: `Download report in ${format.toUpperCase()} format failed!`
    }));
  }
  yield delay(1000);
  yield put(updateReportInfo({ reportVisible: false, reportProgress: 0 }));
}

function* updateProgress({ message, status }, { format, reportStatus }) {
  if (status) {
    const msg = status === "success" && reportStatus !== "failed" ? "successfully" : "failed";
    yield put(updateReportInfo({
      reportMessage: `Download report in ${format.toUpperCase()} format ${msg}!`,
    }));
    yield delay(1000);
    yield put(updateReportInfo({ reportVisible: false, reportProgress: 0 }));
  } else {
    yield put(updateReportInfo({ reportMessage: message, reportProgress: 0 }));
  }
}

function* _clearProjectReportInfo() {
  //cancel prev report task
  if (reportTask) {
    yield cancel(reportTask);
  }
}

function* _saveReportConfig(action) {
  const { verificationIds } = action;
  const { SierraReducer: { project: { currentProjectId, reportInfo } } } = yield select();
  let config = reportInfo ? reportInfo.reportConfig : null;
  if (!config || Object.keys(config).length === 0) {
    config = {
      verificationIds: [...(verificationIds || [])],
      language: 'english',
      templateId: "",
      format: "PPTX",
      fileName: "",
    };
  } else {
    config.verificationIds = [...(verificationIds || [])]
  }
  // save report config to server
  try {
    yield call(saveReportConfigPromise, { projectId: currentProjectId, config });
  } catch (error) {
    console.error(error);
  }
}

function* _getReportConfig(action) {
  const { projectId, verificationId } = action;
  yield put(updateReportInfo({ reportConfigLoading: true }));
  const defaultConfig = {
    verificationIds: verificationId ? [verificationId] : [],
    language: 'english',
    templateId: "",
    format: "PPTX",
    fileName: "",
    custom: {
      guideValue: [],
      isXTable: false
    },
    enableOptions: ["traceCapacitance"]
  };
  try {
    let config = yield call(getReportConfigPromise, projectId);
    if (!config || Object.keys(config).length === 0) {
      config = defaultConfig;
    } else if (verificationId) {
      config.verificationIds = [...new Set([...(config.verificationIds || []), verificationId])];
      if (!config.enableOptions) {
        config.enableOptions = defaultConfig.enableOptions;
      }
    }

    yield put(updateReportInfo({ reportConfig: { ...config, custom: { ...config.custom, /* guideValue: [] */ } }, reportConfigLoading: false }));
  } catch (error) {
    yield put(updateReportInfo({ reportConfig: defaultConfig, reportConfigLoading: false }));
  }
}


function* projectSaga() {
  yield takeEvery(UPDATE_PROJECT_MENU, _updateProjectMenu);
  yield takeEvery(OPEN_PROJECT, openProject);
  yield takeEvery(SHOW_SIERRA_RESULT, showSierraResult);
  yield takeEvery(UPDATE_TRASH_MENU, updateTrashMenu);
  yield takeEvery(SAVE_COMPONENTS_NETS_IN_PROJECT, saveComponentsNets);
  yield takeEvery(CHANGE_VIEW_LIST, _changeViewList);
  yield takeLatest(AUTO_GET_VERIFICATION_LIST, getVerifications);
  yield takeEvery(DOWNLOAD_DEBUG_MSG, downloadDebugLog);
  yield takeEvery(LOAD_SWEEP_LIST, loadVerificationSweep);
  yield takeEvery(SAVE_SWEEP_NAME, renameSweep);
  yield takeEvery(DELETE_PCB, _deletePCB);
  yield takeLatest(UPDATE_PCB_LAYOUT, _updatePCBLayout);
  yield takeEvery(OPEN_NEW_PAGE, _openPageV2);
  yield takeEvery(SAVE_REPORT_CONFIG, _saveReportConfig);
  yield takeEvery(GET_REPORT_CONFIG, _getReportConfig);
  yield takeEvery(GET_REPORT, _getReport);
  yield takeEvery(CLEAR_REPORT_INFO, _clearProjectReportInfo);
  yield takeEvery(GET_REPORT_LOG, _getReportLog);
}

export default projectSaga;