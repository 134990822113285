import { VERIFY_RUNNING, WAITING, VERIFY_SUCCESS, VERIFY_CANCEL, VERIFY_FAILED } from "../../../constants/verificationStatus";

function getLibraryErrorList(interfaceLibraryError, currentVerificationId) {
  let libraryError = null;
  if (interfaceLibraryError && interfaceLibraryError.length > 0) {
    let currentLibraryError = interfaceLibraryError.find(item => item.verificationId === currentVerificationId);
    if (currentLibraryError && currentLibraryError.error) {
      let error = currentLibraryError.error;
      libraryError = [];
      if (error.model && error.model.length > 0) {
        error.model.forEach(item => {
          libraryError.push(item.error)
        })
      }

      if (error.stimuli && error.stimuli.length > 0) {
        error.stimuli.forEach(item => {
          libraryError.push(item.error)
        })
      }

      if (error.repeater && error.repeater.length > 0) {
        error.repeater.forEach(item => {
          libraryError.push(item.error)
        })
      }

      if (error.connector && error.connector.length > 0) {
        error.connector.forEach(item => {
          libraryError.push(item.error)
        })
      }
    }
  }
  return libraryError;
}

function getSierraMonitorTree({ projectName, interfaceList, changeInterface, interfaceType, isExperiment, sweepVerificationName, sweepName }) {
  const _children = interfaceList.map((item) => {
    return {
      key: item.id,
      type: interfaceType,
      name: item.name,
      isSelect: true,
      changeSelected: changeInterface
    }
  })
  const children = isExperiment ? [{
    name: sweepVerificationName,
    type: 'Interface',
    key: sweepVerificationName || '_Interface',
    children: [{
      name: sweepName,
      type: 'Sweep',
      key: sweepName || '_Sweep',
      children: _children
    }]
  }] : _children
  return [{
    name: projectName,
    type: 'Project',
    children: children,
    key: projectName || '_Project',
  }]
}

function getSierraMultiSetupMonitorTree({
  projectName,
  verificationId,
  verificationName }) {
  const children = [{
    key: verificationId,
    type: "Interfaces Group",
    name: verificationName
  }]
  return [{
    name: projectName,
    type: 'Project',
    children: children,
    key: projectName || '_Project',
  }]
}

function getServiceTitleList({ isExperiment, projectName, sweepVerificationName, sweepName, verificationName }) {
  return isExperiment ? [{ type: 'Project', name: projectName },
  { type: "Interface", name: sweepVerificationName },
  { type: "Sweep", name: sweepName },
  { type: "", name: verificationName }]
    : [{ type: "Project", name: projectName }, { type: "Interface", name: verificationName }];
}

function getVerificationLogMsgByStatus(status, log) {
  switch (status) {
    case VERIFY_RUNNING:
      return "==> Simulation running..."
    case WAITING:
      return "==> Simulation waiting..."
    case VERIFY_SUCCESS:
      return "==> Simulation completed!";
    case VERIFY_CANCEL:
      return "==> Simulation cancelled!";
    case VERIFY_FAILED:
      return "==> Simulation failed!";
    default: return log || "";
  }
}


export {
  getLibraryErrorList,
  getSierraMonitorTree,
  getServiceTitleList,
  getSierraMultiSetupMonitorTree,
  getVerificationLogMsgByStatus
}