import { CHANGE_LEFT, CHANGE_TOP } from './actionTypes';
import { USER_LOGOUT } from '../Login/actionTypes';

const defaultState = {
  defaultLeft: 0,
  defaultTop: 0
}

export const PanelStatus = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_LEFT:
      return {
        ...state,
        defaultLeft: action.left,
      }
    case CHANGE_TOP:
      return {
        ...state,
        defaultTop: action.top,
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default: return state
  }
};