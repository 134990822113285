import { select, event, mouse } from 'd3-selection';

class ConnectionLine {
  constructor(ref, clickFeedback, clickLineFeedBack) {
    this.svgRoot = ref;
    this.clickFeedback = clickFeedback;
    this.clickLineFeedBack = clickLineFeedBack;
    this.position = {};
  }

  drawTempLine = (key) => {
    let _key = this.keyHandle(key)
    if (!this.position[_key]) {
      this.position[_key] = {}
    }
    select(this.svgRoot).on('mousemove', () => {
      const [x, y] = mouse(this.svgRoot);
      if (!this.position[_key].startX || !this.position[_key].startY) {
        this.position[_key].startX = x;
        this.position[_key].startY = y;
      }
      this.position[_key].endX = x;
      this.position[_key].endY = y;
      this.drawTemp(_key)
    }).on('click', () => {
      const { endX, endY } = this.position[_key];
      this.clickFeedback && this.clickFeedback(key, endX, endY);
      this.position = {};
      this.removeOnFn(_key);
    })
  }

  drawTemp = (key) => {
    const { startX, startY, endX, endY } = this.position[key];
    select(this.svgRoot)
      .select(`#${key}-line`)
      .remove()
    select(this.svgRoot)
      .append("line")
      .attr("id", `${key}-line`)
      .attr("x1", startX)
      .attr("y1", startY)
      .attr("x2", endX)
      .attr("y2", endY)
      .attr("stroke", "black")
      .attr("stroke-width", 4)
  }

  removeOnFn = (key) => {
    select(this.svgRoot)
      .on('mousemove', () => { })
      .on('click', () => { })
      .select(`#${key}-line`)
      .remove()
  }

  drawLines = (key, { x, y, _x, _y }) => {
    let _key = this.keyHandle(key)
    select(this.svgRoot)
      .select(`#${_key}`)
      .remove()
    select(this.svgRoot)
      .append("line")
      .attr("id", `${_key}`)
      .attr("class", "cascade-connector-line")
      .attr("x1", x)
      .attr("y1", y)
      .attr("x2", _x)
      .attr("y2", _y)
      .attr("stroke", "black")
      .attr("stroke-width", 4)
      .on('click', () => {
        this.clickLineFeedBack && this.clickLineFeedBack(key)
      })
  }

  removeLine = (key) => {
    let _key = this.keyHandle(key)
    select(this.svgRoot)
      .select(`#${_key}`)
      .remove()
  }

  removeAllLines = () => {
    select(this.svgRoot)
      .selectAll(`.cascade-connector-line`)
      .remove()
  }

  keyHandle = (key) => {
    return key.replace(/[^A-Za-z0-9]/ig, '');
  }

  getLinesId = () => {
    const lines = document.getElementsByClassName('cascade-connector-line') || [];
    const keys = [];
    for (let line of lines) {
      const id = line.id;
      keys.push(id)
    }
    return keys
  }

  isConnectionKey = (key) => {
    const _key = this.keyHandle(key);
    const keyList = this.getLinesId();
    return keyList.find(k => k.includes(_key))
  }
}

export default ConnectionLine