import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Spin } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import projectDesigns from '@/services/helper/projectDesigns';
import { getAllCascadeComponents } from '../../../../services/Cascade/helper/setupData';
import { IGNORE } from '../../../../constants/componentType';
import AddConnection from './addConnection';
import componentSetting from '../../../../services/Cascade/helper/compSettingHelper';
import designConstructor from '../../../../services/helper/designConstructor';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import auroraDBJson from '../../../../services/Designs/auroraDbData';

class ConnectorPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 800,
      maxHeight: 1000,
      designList: [],
      connectors: [{ name: "", designId: props.designId, netShip: [] }],
      connComps: [],
      loading: true,
      contentWidth: 600,
      contentHeight: 500,
      drawStatus: false
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const contentRoot = document.getElementById('cascade-net-connection-content') || {};
    const { drawStatus } = this.state;
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 800),
      maxHeight: getPanelMaxHeight(offset, 1000),
      contentWidth: contentRoot.offsetWidth || 600,
      contentHeight: contentRoot.offsetHeight || 500,
      drawStatus: !drawStatus
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.checkData();
  }

  closeModal = () => {
    const { connectors } = this.state;
    const { designId } = this.props;
    this.props.createNewLayout(designId, connectors)
    this.props.closeModal(false)
  }

  loadPCB = async (pcbId) => {
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      await preLayoutData.getPreLayout(pcbId);
    } else {
      const setting = await componentSetting.getSetting({ designId: pcbId })
      await auroraDBJson.getAuroraJson(pcbId, setting);
    }
  }

  checkData = async () => {
    const { data, designId: pcbId } = this.props;
    if (data) {
      await this.loadPCB(pcbId, true);
      const { name, from, to } = data;
      let netShip = [];
      for (let ship of [...from, ...to]) {
        const { components, designId, nets } = ship;
        if (!nets) {
          continue;
        }
        await this.loadPCB(pcbId, true);
        if (components.length) {
          const connector = components[0];
          const prevNets = nets[`${name}-${pcbId}`] || [];
          const nextNets = nets[`${connector}-${designId}`] || [];
          let _nets = [];
          for (let i = 0; i < prevNets.length; i++) {
            const prevNet = prevNets[i];
            const nextNet = nextNets[i];
            if (!prevNet || !nextNet) {
              break;
            }
            _nets.push([prevNet, nextNet])
          }
          netShip.push({ connector, designId, nets: _nets })
        }
      }

      this.setState({
        connectors: [{ name, designId: pcbId, netShip }],
      }, () => {
        this.getConnComps();
      })
    } else {
      this.getConnComps();
    }
  }

  getConnComps = async () => {
    const { designId, projectId } = this.props;
    const connComps = await this.getConnectionComps(designId);
    const pcbList = projectDesigns.getAvailablePCBs(projectId);
    this.setState({
      connComps,
      designList: pcbList.filter(item => designId !== item.id),
      loading: false
    }, () => {
      this.resize();
    })
  }

  getConnectionComps = async (pcbId) => {
    await this.loadPCB(pcbId, true);
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      const preData = await preLayoutData.getPreLayout(pcbId);
      if (preData) {
        const { content } = preData;
        const { components } = content;
        return components
      }
      return []
    }
    const components = getAllCascadeComponents({ pcbId });
    const connComps = [...components.values()].filter(comp => comp.type === IGNORE).sort((a, b) => a.name > b.name ? 1 : -1) || []
    return connComps
  }

  addConnectorShip = (index, name) => {
    const { connectors } = this.state;
    let _connectors = [...connectors];
    _connectors[index].netShip.push({ connector: "", designId: "", nets: [] });
    this.setState({
      connectors: _connectors
    })
  }

  saveConnectorInfo = (index, key, value, resize) => {
    const { connectors } = this.state;
    let _connectors = [...connectors];
    _connectors[index][key] = value;
    this.setState({
      connectors: _connectors
    }, () => {
      if (resize) {
        this.resize()
      }
    })
  }

  timeResize = () => {
    setTimeout(() => {
      this.resize()
    }, 1000)
  }

  connectorRender = () => {
    const { connectors, connComps, designList, contentWidth, contentHeight, drawStatus } = this.state;
    const { data, designId } = this.props;
    return <div className="cascade-setup-border cascade-add-pcb-connector-content">
      <AddConnection
        connector={connectors[0]}
        index={0}
        saveConnectorInfo={this.saveConnectorInfo}
        deleteShip={this.props.deleteShip}
        addConnectorShip={this.addConnectorShip}
        connComps={connComps}
        designId={designId}
        pcbList={designList}
        getConnectionComps={this.getConnectionComps}
        disabledName={data ? true : false}
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        drawStatus={drawStatus}
        resize={this.resize}
        getAutoConnectionNet={this.props.getAutoConnectionNet}
      />
    </div>
  }

  render() {
    const { maxWidth, maxHeight, loading } = this.state;
    const { designName } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='cascade-add-pcb-panel'
          title={`${designName} - Add Connection`}
          onCancel={this.closeModal}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { defaultWidth: 800 })}
          maxHeight={maxHeight}
          position='panel-center'
          draggable
          minHeight={150}
          minWidth={200}
          defaultTop={100}
          resizeEnd={this.timeResize}
        >
          <Spin spinning={loading}>
            {this.connectorRender()}
          </Spin>
        </Panel>
        <div id="cascade-add-connection-dialog"></div>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
};


export default ConnectorPanel;
