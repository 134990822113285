import React, { Component } from 'react';
import { Input, Select, Checkbox } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import SerdesType from './SerdesType';
import {
  preLayoutTypes,
  RX,
  TX,
  HDMI_SIGNALS_GROUPS,
  PCIE_SIGNALS_GROUPS,
  CLK,
  SCHEMATIC
} from '../../../services/Andes_v2/preLayout';
import { checkNameFormat } from '../../../services/helper/nameFormatCheck';
import { CPHY, ETHERNET, HDMI, PCIE, GENERIC } from '../../../services/PCBHelper/constants';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

const Option = Select.Option;

class Setting extends Component {

  constructor(props) {
    super(props);
    const { type, width, prelayout, selectedGroups, diffPairsNumber, netPrefix, lanesNumber } = props;
    this.state = {
      type,
      width,
      prelayout,
      selectedGroups,
      diffPairsNumber: diffPairsNumber || "1",
      netPrefix: netPrefix || "D",
      error: null,
      lanesNumber
    };
    this.dialogRoot = document.getElementById('root');
  }

  close = (e) => {
    e.stopPropagation();
    this.props.closePanel("setting", false);
    const { type, width, prelayout, selectedGroups, diffPairsNumber, netPrefix, error, lanesNumber } = this.state;
    if (error) {
      return;
    }
    this.props.changePreLayoutConfig({
      type,
      width,
      prelayout,
      selectedGroups,
      diffPairsNumber,
      netPrefix,
      lanesNumber
    });
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  selectChange = (changeType, key) => {
    const { type } = this.state;
    if (changeType === "prelayout" && key === SCHEMATIC && type === CPHY) {
      this.changeInterfaceType(PCIE)
    }
    this.setState({
      [changeType]: key
    })
  }

  changeInterfaceType = (key) => {
    let width = "", diffPairsNumber = "", netPrefix = "", selectedGroups = this.state.selectedGroups, lanesNumber = "";
    switch (key) {
      case PCIE:
      case ETHERNET:
        width = "x1";
        selectedGroups = [TX, RX];
        break;
      case GENERIC:
        diffPairsNumber = "1";
        selectedGroups = [];
        netPrefix = "D";
        break;
      case HDMI:
        diffPairsNumber = "3";
        selectedGroups = HDMI_SIGNALS_GROUPS;
        netPrefix = HDMI;
        break;
      case CPHY:
        lanesNumber = 1;
        selectedGroups = [CPHY];
        netPrefix = CPHY;
        break;
      default: break;
    }
    this.setState({
      type: key,
      width,
      diffPairsNumber,
      selectedGroups,
      netPrefix,
      lanesNumber
    })
  }

  changeValue = (e, type) => {
    const { error } = this.state;
    this.setState({
      [type]: e.target.value,
      error: error && error.type === type ? null : error
    });
  }

  netPrefixBlur = (e) => {
    const value = e.target.value;
    const error = checkNameFormat(value, { errorType: "Net prefix" });
    if (error) {
      this.setState({
        error: { type: "netPrefix", error }
      })
    }
  }

  addCLKChange = (e) => {
    const { selectedGroups } = this.state;
    let _selectedGroups = [...selectedGroups];
    if (e.target.checked) {
      _selectedGroups = [...new Set([..._selectedGroups, CLK])]
    } else {
      _selectedGroups = _selectedGroups.filter(item => item !== CLK);
    }
    this.setState({
      selectedGroups: _selectedGroups
    })
  }

  render() {
    const { type, width, prelayout, selectedGroups, diffPairsNumber, netPrefix, error, lanesNumber } = this.state;
    const content = (
      <Panel
        className='andes-v2-pre-layout-panel'
        title='Setting'
        zIndex={2000}
        width={500}
        minWidth={300}
        minHeight={200}
        position='panel-center'
        onCancel={this.close}
        overflow='hidden'
        draggable
      >
        <div className='pre-layout-setting'>
          <div className="pre-layout-pcie-select">
            <span className='out-title-color pre-layout-span'>Pre Layout Type</span>
            <Select
              value={prelayout}
              onChange={(key) => this.selectChange("prelayout", key)}
              placeholder={"Pre Layout Prototype Type"}
              className='aurora-select pre-layout-select'
              popupClassName='aurora-select-dropdown'
              getPopupContainer={() => document.getElementById('root')}
            >
              {preLayoutTypes.map(item =>
                <Option key={item.key} >{item.title}</Option>
              )}
            </Select>
          </div>
          <SerdesType
            width={width}
            type={type}
            lanesNumber={lanesNumber}
            diffPairsNumber={diffPairsNumber}
            changeInterfaceType={this.changeInterfaceType}
            inputChange={this.selectChange}
            prelayout={prelayout}
          />
          {[GENERIC, HDMI].includes(type) ?
            <div className='pre-layout-pcie-select'>
              <span className='out-title-color pre-layout-span'>Net Prefix</span>
              <Input
                className="aurora-input pre-layout-input"
                value={netPrefix}
                onChange={(e) => this.changeValue(e, "netPrefix")}
                onBlur={this.netPrefixBlur}
                /*  disabled={diffPairsNumber !== this.props.diffPairsNumber ? false : true} */
                onKeyDown={this.keyDown}
              />
            </div>
            : null}
          {type === PCIE || type === ETHERNET ? <div className='pre-layout-pcie-select'>
            <label className="out-title-color pre-layout-span">Signal Groups</label>
            <Select
              value={selectedGroups}
              onChange={(keys) => this.selectChange("selectedGroups", keys)}
              mode="multiple"
              placeholder="Signal Groups"
              className='aurora-select pre-layout-select'
              popupClassName='aurora-select-dropdown'
            >
              {PCIE_SIGNALS_GROUPS.map(item => <Option key={item} value={item}>{item}</Option>)}
            </Select>
          </div> : null}
          {type === HDMI ? <div className='pre-layout-pcie-select'>
            <label className="out-title-color pre-layout-span">Include CLK</label>
            <Checkbox
              onChange={e => this.addCLKChange(e)}
              checked={selectedGroups.includes(CLK)}
            />
          </div> : null}

          {error && <span className='aurora-model-name-error-msg'>{error.error}</span>}
        </div>
      </Panel>)

    return createPortal(content, this.dialogRoot);
  }
}

export default Setting;