import React, { PureComponent, lazy } from 'react';
import { END_TO_END_CHANNEL, PCB, PCB_PRE_LAYOUT, PCB_CHANNEL, PCB_CHANNEL_RESULT, END_TO_END_CHANNEL_RESULT, PACKAGE, EXPERIMENTS } from '@/constants/treeConstants';
import TopBar from '../TopBar';
import ChannelResult from '../results/channel';
import { topBarResize } from '@/services/helper/topBarResize';
import ContentLayoutTB from '@/components/ContentLayout/layoutTB';
import EndToEndResult from '../results/endToEnd';
import PCBLayout from './pcbLayout';
import { connect } from 'react-redux';
import { PCB_TOP_BOTTOM, TOP_BOTTOM_LAYOUT } from '../../../constants/layoutConstants';
import { EXPERIMENTS_RESULT } from '../../../constants/treeConstants';
import SweepResult from '../results/sweep';

const ChannelSetup = lazy(() => import("../Channel"));
const EndToEndChannel = lazy(() => import("../EndToEndChannel"));
const SweepContent = lazy(() => import("../Sweep"));

class LayoutTB extends PureComponent {

  topBarResize = () => {
    topBarResize('andes-v2-top-bar', this.props.getTopBarWidth());
    topBarResize('andes-v2-prelayout-top-bar', 370);
  }

  getBottomSetup = (width) => {
    const { viewList, siderWidth } = this.props;
    const views = viewList.filter(item => ![PCB, PCB_PRE_LAYOUT, PACKAGE].includes(item));
    switch (views[0]) {
      case PCB_CHANNEL_RESULT:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-tb-result-main'
          style={{ width }}
        >
          <ChannelResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      case END_TO_END_CHANNEL:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-tb-content-main'
          style={{ width }}
        >
          <EndToEndChannel />
        </div>
      case PCB_CHANNEL:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-tb-content-main'
          style={{ width }}
        >
          <ChannelSetup />
        </div>
      case END_TO_END_CHANNEL_RESULT:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-tb-result-main'
          style={{ width }}
        >
          <EndToEndResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      case EXPERIMENTS:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-tb-result-main'
          style={{ width }}
        >
          <SweepContent />
        </div>
      case EXPERIMENTS_RESULT:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-tb-result-main'
          style={{ width }}
        >
          <SweepResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      default: return null;
    }
  }

  getTop = (height) => {
    const { siderWidth } = this.props;
    return <PCBLayout siderWidth={siderWidth} bottomHeight={height} />
  }


  topBar = (width) => {
    return <TopBar width={width} />
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PCB_PRE_LAYOUT, PACKAGE].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) {
      return 1;
    } else if (pcbs.length > 1 && pcbLayout === PCB_TOP_BOTTOM && layout === TOP_BOTTOM_LAYOUT) {
      return 3;
    } else {
      return 2;
    }
  }

  render() {
    const splitSize = this._getSplitSize();
    return (
      <ContentLayoutTB
        {...this.props}
        bottomClassName={"andes-v2-content-bottom"}
        topBarResize={this.topBarResize}
        getTop={this.getTop}
        getBottomSetup={this.getBottomSetup}
        topBar={this.topBar}
        splitSize={splitSize}
      />
    )
  }
};

const mapState = (state) => {
  const { AndesV2Reducer } = state;
  const { project: { selectedKeys, pcbLayout, layout } } = AndesV2Reducer;
  return {
    selectedKeys,
    pcbLayout,
    layout
  };
}

export default connect(mapState, null)(LayoutTB);
