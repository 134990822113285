import React, { Component } from 'react';
import TopBox from './TopBox';
import { Dropdown, Button, Select } from 'antd';
import { getTopMenuWidth, getTopMenu } from '../../services/helper/topMenuHelper';
import './index.css';
import { SPLIT_LINE } from '../../services/Rocky/helper/topMenuHelper';

const Option = Select.Option;
class ResultTopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      totalWidth: null
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { product, menu, isSweep, measurementWidth, backSetup, isStatEye } = this.props;
    const _menu = getTopMenu(menu, "result-top-menu-id", product, { isSweep, measurementWidth, backSetup });
    this.setState({
      menuList: isStatEye ? _menu.filter(item => item.name !== "Waveform" && item.name !== "Margins") : _menu
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    const ele = document.getElementById("result-top-menu-id");
    const totalWidth = ele ? ele.offsetWidth : null;
    this.setState({
      totalWidth
    });
    const { menu = [], selectedKey } = this.props;
    const isExistSelect = menu.find(item => item.key === selectedKey);
    if (!isExistSelect && menu[0]) {
      this.props.changeResultType(menu[0].key)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { menu, isCLK, measurementWidth, isStatEye, resultPageType } = this.props;

    const ele = document.getElementById("result-top-menu-id");
    const totalWidth = ele ? ele.offsetWidth : null;
    if (totalWidth !== prevState.totalWidth || prevProps.menu.length !== menu.length || prevProps.isCLK !== isCLK || measurementWidth !== prevProps.measurementWidth || prevProps.isStatEye !== isStatEye || prevProps.resultPageType !== resultPageType) {
      this.setState({
        totalWidth
      });
      this.resize();
    }
  }

  subMenu = (children) => {
    return children.map(it => ({ key: it.key, label: it.name, className: this.props.selectedKey === it.key ? "ant-menu-item-selected" : "" }))
  }

  render = () => {
    const { selectedKey, changeResultType, children, backSetup, downloadClick, selectDataList, verificationId, selectedChildren, selectItem } = this.props;
    const { menuList } = this.state;
    const menuWidth = getTopMenuWidth(menuList);
    return (
      <TopBox>
        <div className='result-top-menu-content'>
          {backSetup ?
            <div className='result-top-menu-item result-top-menu-setup'>
              <span
                title="Back to setup"
                key={backSetup.key}
                className={selectedKey === backSetup.key ? "menu-item-title menu-item-title-select" : "menu-item-title menu-item-back-setup"}
                onClick={(e) => changeResultType(backSetup.key)}
              >
                <span className="menu-item-title-icon">&lt;</span>
                <span className="menu-item-title-span">{backSetup.name}</span>
              </span>
              <span className='aurora-top-bar-vertical-line'></span>
            </div> : null}
          {selectItem ? selectItem() : null}
          {selectDataList && selectDataList.length ?
            <div className='result-top-menu-item result-top-menu-setup result-top-menu-select-setup'>
              <span>Signal Group</span>
              <Select
                placeholder={"Model File"}
                value={verificationId}
                onSelect={this.props.changeChannelTab}
                popupClassName='aurora-select-dropdown'
                className='result-top-menu-select'
              >
                {selectDataList.map(item => <Option
                  key={item.id}
                  value={item.id}
                  title={item.name}
                >{item.name}</Option>)}
              </Select>
              <span className='aurora-top-bar-vertical-line'></span>
            </div>
            : null}
          <div style={{ width: menuWidth }} className="result-top-menu-list clear">
            {menuList.map(menuItem => menuItem.key === "subMenu" ?
              <Dropdown menu={{ items: this.subMenu(menuItem.children), onClick: ({ key }) => this.props.changeResultType(key) }} key={menuItem.key + '-item'}>
                <div className='result-top-menu-item'
                  key={menuItem.key + '-item'}
                ><span
                  key={menuItem.key}
                  className={menuItem.children.find(it => it.key === selectedKey) ? "menu-item-title menu-item-title-select" : "menu-item-title"}
                >{menuItem.name}</span>
                </div>
              </Dropdown>
              : (menuItem.key === 'download' ? <div className="result-top-menu-item result-download-content" key={menuItem.key + '-item'} onClick={downloadClick}>
                <span className='aurora-top-bar-vertical-line'></span>
                <Button
                  type="primary"
                  ghost
                  className="result-export-button">Export xls</Button>
              </div>
                : menuItem.key === SPLIT_LINE ? <div className='result-top-menu-item' key={menuItem.key + '-item'}>
                  <div className='result-top-menu-item-line' onClick={e => e.preventDefault()}>
                    <span className='rocky-top-bar-vertical-line'></span>
                  </div>
                </div> :
                  <div className='result-top-menu-item'
                    key={menuItem.key + '-item'}
                  >
                    <span
                      key={menuItem.key}
                      className={selectedKey === menuItem.key ? "menu-item-title menu-item-title-select" : "menu-item-title"}
                      onClick={(e) => changeResultType(menuItem.key)}
                    >{menuItem.name}</span>
                  </div>))}
          </div>
          {children}
          {selectedChildren ? selectedChildren() : null}
        </div>
      </TopBox >
    )
  }
}

export default ResultTopMenu;