import { START_ROCKY_SSN_VERIFICATION, START_ROCKY_MERGE_EXTRACTION, RECALCULATE_POWER_SUM_WORKFLOW } from './actionType';

export const startRockySSNVerification = ({ verificationIds, currentVerificationId, isSSN, openSSNVerificationIds, ssnVerificationId, packageIsPreLayout,
  pcbIsPreLayout }) => ({
    type: START_ROCKY_SSN_VERIFICATION,
    verificationIds,
    currentVerificationId,
    isSSN,
    openSSNVerificationIds,
    ssnVerificationId,
    packageIsPreLayout,
    pcbIsPreLayout
  })

export const startMergeExtraction = (obj) => ({
  type: START_ROCKY_MERGE_EXTRACTION,
  obj
})

export const recalculatePowerSumWorkflow = (workflow, verificationId, obj) => ({
  type: RECALCULATE_POWER_SUM_WORKFLOW,
  workflow,
  verificationId,
  obj
})