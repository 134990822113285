const TYPE = 'Cascade/Impedance/Result';

export const CHANGE_SELECT_KEY = `${TYPE}/change_select_key`;
export const BACK_TO_SETUP = `${TYPE}/back_to_setup`;
export const GET_HISTORYS = `${TYPE}/get_history`;
export const SAVE_HISTORY = `${TYPE}/save_history`;
export const DELETE_HISTORY = `${TYPE}/delete_history`;
export const UPDATE_HISTORT_LIST = `${TYPE}/update_histort_list`;
export const UPDATE_HISTORY_STATUS = `${TYPE}/update_history_status`;
export const UPDATE_IMPORT_LIST = `${TYPE}/update_import_list`;
export const UPDATE_IMPORT_STATUS = `${TYPE}/update_import_status`;
export const UPDATE_COMPARE_LIST = `${TYPE}/update_compare_list`;
export const UPDATE_COMPARE_STATUS = `${TYPE}/update_compare_status`;
export const GET_IMPORTS = `${TYPE}/get_imports`;
export const DELETE_IMPORT = `${TYPE}/delete_import`;
export const RUNNING_IMPORT_LIST = `${TYPE}/running_import_list`;
export const GET_COMPARES = `${TYPE}/get_compares`;
export const SAVE_COMPARES = `${TYPE}/save_compares`;
