import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { getWaveform, getComponentInfo, getWaveFormPostProcessEdgesInfo, getWaveFormJsonConfig } from '@/services/Sierra/results';
import NP from 'number-precision';
import { ICTypes } from '../../constants';
import { ResultData } from '@/services/Sierra';
import { getVerificationDataList, judgePostProcessSignalType } from '@/services/Sierra/helper/WaveformResultHelper';
import WaveForm from '../components/WaveForm';
import defaultColor from '@/constants/defaultColors';
import { recalcMeasure } from '../../store/result/action';
import { getWaveformDisplayMode, saveResultDisplaySettings } from '../../../../services/Sierra/results';

NP.enableBoundaryChecking(false);
class WaveformResult extends Component {
  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.contentRef = createRef();
    this.state = {
      loading: true,
      driverList: [],
      selectedDriverKey: null,
      compInfo: [],
      currentInterfaceInfo: null,
      displayMode: 'Hi-Re',
      dataList: { Waveform: { list: [], show: [] } },
      portSelect: {},
      isPostProcess: false,
      allDesigns: []
    }
  }

  onRef = (ref) => {
    this.waveformSetting = ref;
  }

  componentDidMount() {
    this.screenChange();
    const { verificationSubId, verificationId } = this.props;
    if (verificationSubId && verificationId) {
      this.getJson(true)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { verificationSubId, verificationId, recalc } = this.props;
    if ((verificationId !== prevProps.verificationId) && verificationSubId) {
      this.setState({
        driverList: [],
        selectedDriverKey: null,
        compInfo: [],
        currentInterfaceInfo: null,
        dataList: { Waveform: { list: [], show: [] } },
        portSelect: {},
        isPostProcess: false,
        allDesigns: []
      }, () => {
        this.getJson(true)
      })
    }

    if ((verificationId && !recalc && recalc !== prevProps.recalc) && verificationSubId) {
      this.getJson()
    }
  }

  getJson = (isFirst) => {
    const { verificationSubId, currentProjectId, verificationId } = this.props;
    let allDesigns = [];
    ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId).then(res => {
      if (res) {
        this.designs = []
        let currentInterfaces = ResultData.getInterfaces();
        currentInterfaces.forEach(item => {
          this.designs.push({
            id: item.pcbId,
            name: item.pcb
          });
        });

        let currentInterfaceInfo = ResultData.getInfo();
        const { components } = currentInterfaceInfo;
        const _filter = components.filter(comp => ICTypes.includes(comp.type));
        const driverList = _filter.map(comp => ({ designName: comp.pcb, name: comp.name, type: comp.type }));
        allDesigns = [...new Set([...allDesigns, ...this.designs.map(item => item.name)])]
        this.setState({
          currentInterfaceInfo,
          driverList,
          selectedDriverKey: driverList.length ? 0 : null,
          allDesigns: allDesigns.map((name, index) => ({ name, color: defaultColor[index + 1] }))
        }, () => {
          this.getWaveform(isFirst);
        })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    if (this.waveform) {
      this.waveform.plot.redrawPlot(this.svgRef.current);
    }
  }

  updateState = (key, value) => {
    this.setState({ [key]: value });
  }

  getWaveform = async (isFirst) => {
    this.setState({
      loading: true
    }, async () => {
      const { displayMode, currentInterfaceInfo } = this.state;
      const { currentProjectId, verificationId } = this.props;
      let _displayMode = displayMode;
      if (isFirst) {
        _displayMode = await getWaveformDisplayMode(verificationId, _displayMode);
      }

      const isOriginal = _displayMode === 'Hi-Re' ? true : false;
      this.setState({
        displayMode: _displayMode
      })
      getWaveform(verificationId, isOriginal, currentProjectId).then(async waveform => {
        const events = {
          changeMouse: this.childComp.changeMouse,
          cancelMove: this.childComp.cancelMove,
          changeAxis: this.childComp.changeAxis,
        }
        waveform.plotWaveform({ svgElement: this.svgRef.current, events });
        this.waveform = waveform;
        const curves = waveform.getCurves();
        let compInfo = [];
        try {
          compInfo = getComponentInfo(currentInterfaceInfo, curves, this.designs);
        } catch (error) {
          message.error('Error parsing the result file. ' + error)
          this.setState({
            loading: false,
          });
          return;
        }
        let newCompInfo = [], postProcessDataList = null, isPostProcess = false, postProcessConfig = null;
        // postProcessDataList = await getWaveFormPostProcessConfig(verificationId)
        try {
          postProcessDataList = await getWaveFormPostProcessEdgesInfo(verificationId)
          isPostProcess = true
        } catch (error) {
          console.error("Get edges file error: ", error);
        }
        try {
          postProcessConfig = await getWaveFormJsonConfig(verificationId)
        } catch (error) {
          console.error("Get pproc-config file error: ", error);
        }
        if (postProcessConfig) {
          postProcessDataList = judgePostProcessSignalType(postProcessDataList, postProcessConfig)
        }

        for (let info of compInfo) {
          const { curveName } = info;
          let postProcessData = postProcessDataList && postProcessDataList[curveName] ? { ...postProcessDataList[curveName], curveName } : {};
          newCompInfo.push({ ...info, postProcessData })
        }
        //draw clk components by default
        let clkSignal = currentInterfaceInfo.signals.map(signal => signal.name)[0];
        const { _compInfo, portSelect, dataList } = getVerificationDataList(newCompInfo);
        const portSelectKeys = Object.keys(portSelect)
        clkSignal = portSelectKeys.length ? portSelectKeys[0] : clkSignal
        const selectedComponentKeys = newCompInfo.filter(item => item.signal === clkSignal && item.usage).map(item => item.curveIndex);
        this.setState({
          loading: false,
          compInfo: _compInfo,
          portSelect,
          dataList,
          selectedComponentKeys,
          isPostProcess,
          postProcessConfig
        });
        curves.forEach((curve, i) => {
          curve.visible = selectedComponentKeys.includes(i);
        });
        this.waveform.updataRawCurvePlot();
      })
    })
  }

  getDesignIdByName(name) {
    for (const design of this.designs) {
      if (design.name === name) {
        return design.id;
      }
    }
    return null;
  }

  displayModeChange = (e) => {
    this.setState({ displayMode: e.target.value }, () => {
      this.getWaveform();
      try {
        saveResultDisplaySettings({
          verificationId: this.props.verificationId,
          setting: { waveform: { displayMode: e.target.value } }
        });
      } catch (error) {
        console.error(error);
      }
    });
  }

  render() {
    const { loading, compInfo, displayMode, currentInterfaceInfo, portSelect, dataList, isPostProcess, allDesigns, postProcessConfig } = this.state;
    const { verificationSubId, verificationId, currentProjectId, recalc, currentProgress, measurePanelVisible } = this.props
    return <WaveForm
      {...this.resultListAction}
      onRef={this.onRef}
      compRef={(ref) => { this.childComp = ref }}
      loading={loading}
      compInfo={compInfo}
      portSelect={portSelect}
      dataList={dataList}
      displayMode={displayMode}
      currentInterfaceInfo={currentInterfaceInfo}
      waveform={this.waveform}
      svgRef={this.svgRef}
      updateState={this.updateState}
      waveformSetting={this.waveformSetting}
      verificationSubId={verificationSubId}
      displayModeChange={this.displayModeChange}
      verificationId={verificationId}
      isPostProcess={isPostProcess}
      allDesigns={allDesigns}
      sweep={false}
      currentProjectId={currentProjectId}
      postProcessConfig={postProcessConfig}
      recalcMeasure={this.props.recalcMeasure}
      recalc={recalc}
      progress={currentProgress}
      measurePanelVisible={measurePanelVisible}
      changeMeasureVisible={this.props.changeMeasureVisible}
    />
  }

  colorChange = (e, id) => {
    const value = e.toHexString()
    if (value) {
      const { compInfo } = this.state;
      const curves = this.waveform.getCurves();
      const current = compInfo.find(item => item.curveName === id);
      if (current) {
        compInfo[current.curveIndex].color = value;
        curves[current.curveIndex].color = value;
        const { _compInfo, dataList } = getVerificationDataList(compInfo);
        this.waveform.updataRawCurvePlot();
        this.setState({
          compInfo: _compInfo,
          dataList
        })
      }
    }
  }

  _rowNameClick = (displayMode, value) => {
    let _dataList = { ...this.state.dataList };
    if (_dataList[displayMode]) {
      let _show = [..._dataList[displayMode].show];
      if (_show.includes(value)) {
        _show = _show.filter(item => item !== value);
      } else {
        _show.push(value);
      }
      _dataList[displayMode].show = _show;
      this.setState({
        dataList: _dataList
      });
    }
  };

  clearPort = () => {
    this.setState({
      portSelect: {}
    });
  }

  changeSelectAll = (e, group, fileId) => {
    const check = e.target.checked;
    const { portSelect, compInfo } = this.state;
    const values = group.children.map(d => d.id)
    if (check) {
      portSelect[group.rowName] = [...values]
    } else {
      portSelect[group.rowName] = [];
    }

    this.setState({
      portSelect
    });

    const keyMap = Object.keys(portSelect);
    let selectedComponentKeys = [];
    for (let key of keyMap) {
      const select = portSelect[key];
      select.forEach(s => {
        const info = compInfo.find(comp => comp.portSelectId === s);
        if (info) {
          selectedComponentKeys.push(info.curveIndex);
        }
      })
    }
    const curves = this.waveform.getCurves();
    curves.forEach((curve, i) => {
      curve.visible = selectedComponentKeys.includes(i);
    });
    // this.waveform.plot.removeCrossProbing(values)
    this.waveform.updataRawCurvePlot();
  }

  changePort = (values, rowName) => {
    const { portSelect, compInfo } = this.state;
    portSelect[rowName] = values;
    this.setState({
      portSelect
    });
    const keyMap = Object.keys(portSelect);
    let selectedComponentKeys = [];
    for (let key of keyMap) {
      const select = portSelect[key];
      select.forEach(s => {
        const info = compInfo.find(comp => comp.portSelectId === s);
        if (info) {
          selectedComponentKeys.push(info.curveIndex);
        }
      })
    }
    const curves = this.waveform.getCurves();
    curves.forEach((curve, i) => {
      curve.visible = selectedComponentKeys.includes(i);
    });
    // this.waveform.plot.removeCrossProbing(values)
    this.waveform.updataRawCurvePlot();
  }

  resultListAction = {
    colorChange: this.colorChange,
    changePort: this.changePort,
    _rowNameClick: this._rowNameClick,
    clearPort: this.clearPort,
    _changeSelectAll: this.changeSelectAll
  }
}

const mapState = (state) => {
  const { project, simulationReducer: { singleProgress }, resultReducer: { resultInfo: { verificationSubId, verificationId }, recalc } } = state.SierraReducer;
  const { currentProjectId } = project;
  const currentProgress = singleProgress.find(item => item.verificationId === verificationId)
  return {
    currentProjectId,
    verificationSubId,
    verificationId,
    recalc,
    currentProgress: currentProgress ? currentProgress.progress : false
  }
}

const mapDispatch = (dispatch) => ({
  recalcMeasure(configList) {
    dispatch(recalcMeasure(configList))
  }
})

export default connect(mapState, mapDispatch)(WaveformResult);