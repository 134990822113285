import http from "../http";

export function getCascadeDCRData(verificationId) {
  return http.get(`/Cascade/verification/${verificationId}/dcr`)
}

export function updateCascadeDCRInfo({ verificationId, dcr, pairs, designName, config, errorMessage }) {
  return http.post(`/Cascade/update/dcr`, { verificationId, dcr, pairs, designName, config, errorMessage })
}

export function importDCR({ verificationId, file, importType }) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return http.post(`/Cascade/dcr/import?verificationId=${verificationId}&importType=${importType}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function simulationDCR({ verificationId }) {
  return http.post(`/Cascade/dcr/simulation?verificationId=${verificationId}`)
}

export function deleteCascadePathRInfo({ verificationId, dcrIdList }) {
  return http.post(`/Cascade/delete/dcr`, { verificationId, dcrIdList })
}

export function selectDCRPCB({ verificationId, designId }) {
  return http.post(`Cascade/dcr/pcb/select`, { verificationId, designId })
}