import { SERDES_TYPES } from '../../../PCBHelper';
import { CPHY } from '../../../PCBHelper/constants';
import { filterPorts } from '../../../Result/Andes/dataHelper';

export function endToEndNpiParse(data, content, interfaceType) {
  const components = getComponents(getSignalGroup(content));
  const pcbIndexMap = getPCBIndexMap(content.endToEnd.content.pcbConnections);
  let lineBuffer = data.match(/[^\r\n]+/g);
  let portNum = Number(lineBuffer[0].slice(5));
  let ports = [],
    frequency = [],
    endLine = portNum + 1;

  for (let i = 1; i < endLine; i++) {
    if (!lineBuffer[i]) continue;
    // pcb name with the blank spaces -> "1 85.0 Diff_Signal1_844-02563-01_Celeste_mlb_pp1 1_20241120_U600_B16_A15"
    // let words = lineBuffer[i].split(' ');//"1", "100.0"
    // let name = words[2];
    let words = lineBuffer[i].split(' ');//"1", "100.0"
    const nameList = words.splice(2)
    let name = nameList.join(' ');
    const { signal = null, portType = null, component = null, design = null, pins = [] } = matchPortName(name, components, interfaceType);
    if (!signal || !component || !design || !pins) {
      ports.push(null)
      continue;
    }
    if (pcbIndexMap.get(design) === undefined) {
      continue;
    }
    const _design = `PCB${pcbIndexMap.get(design) + 1}`;
    const pinsText = pins.length === 2 ? `<+${pins[0]}, -${pins[1]}>` : "<>";
    ports.push({
      index: Number(words[0]),
      impedance: Number(words[1]),
      // +V6, -V5
      name: `${_design} ${component}${pinsText} ${signal} [${portType}]`,
      portType,
      comp: component,
      signal,
      pins,
      pinsText,
      design,
      _design,
      display: true
    })
  }

  for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
    frequency.push(Number(lineBuffer[i]))
  }

  return {
    ports: ports,
    freq: frequency
  }
}

export function getPCBIndexMap(pcbConnections) {
  let pcbIndexMap = new Map();
  pcbConnections.forEach((d, index) => {
    pcbIndexMap.set(d.designName, index)
  });
  return pcbIndexMap;
}

/**
 * Signal_Design_Component_PositivePin_NegtivePin
 * Diff_PCIE_LANE4_RX_demo_PCIe_U20_V6_V5
 * Diff_PCIE_LANE4_RX_demo_PCIe_1_U20_V6_V5
 * 
 * Type - Diff
 * Signal - PCIE_LANE4_RX
 * Design - demo_PCIe
 * Component - U20
 * Positive_Pin - V6
 * Negtive_Pin - V5
 * 
 * @param {*} name
 */
function matchPortName(name, components, interfaceType) {
  if (!name) return {};
  const matchName = function (comp) {
    return [`${comp.name}_${comp.designName}_${comp.component}_(${comp.pins.join('|')})_(${comp.pins.join('|')})`]
  }
  const find = components.find(comp => name.match(new RegExp(`^(Diff|Comm)_(${matchName(comp).join('|')})`, 'i')))
  if (find) {
    const match = name.match(new RegExp(`^(Diff|Comm)_${find.name}_${find.designName}_${find.component}_(${find.pins.join('|')})_(${find.pins.join('|')})(\\S*)`, 'i'));
    return {
      portType: match[1],
      signal: find.name,
      component: find.component,
      design: find.designName,
      pins: interfaceType === CPHY ? [match[2], match[3]] : find.pins
    }
  }
  return {}
}

// Components: [Component], Component - { name, design, channel, signal, component, posPin, negPin }
// TDR
// PCIE_LANE4_RX - End to end signal group name, this name used the first design's signal name
// 1	PCIE_LANE4_RX_demo_PCIe_U20_V6_V5	voltage    Signal 
// 2	PCIE_LANE4_RX_demo_PCIe_1_U20_V6_V5	voltage

// SBR
// PCIE_LANE4_RX - End to end signal group name, this name used the first design's signal name
// 1	PCIE_LANE4_RX_demo_PCIe_U20_V6_V5_demo_PCIe_1_U20_V6_V5	voltage

// Network Parameter
// PCIE_LANE4_RX - End to end signal group name, this name used the first design's signal name
// 1 100.0 Diff_PCIE_LANE4_RX_demo_PCIe_U20_V6_V5
// 2 100.0 Diff_PCIE_LANE4_RX_demo_PCIe_2_U37_B33_B34
// 33 25.0 Comm_PCIE_LANE4_RX_demo_PCIe_U20_V6_V5
// 34 25.0 Comm_PCIE_LANE4_RX_demo_PCIe_2_U37_B33_B34

/**
 *           {    design1 - channel1   }       {    design2 - channel1   }      {    design3 - channel1   }
 *            —————— V6          ——————         ——————          V6 ——————        ——————          V6 ——————
 *   {name}  |  U1  |-----------|  U2  |       |  U3  |-----------|  U4  |      |  U5  |-----------|  U6  |
 *           |      |-----------|      |       |      |-----------|      |      |      |-----------|      |
 *            —————— V5 signal1  ——————         ——————  signal1 V5 ——————        ——————  signal1 V5 ——————
 *              (signal name in channel)
 * 
 * Component {componentPin}
 * name - end to end signal group name, this name used the first design's signal name
 * designName - design name
 * channelName - channel name
 * signalName - signal name in channel
 * component - component name
 * pins - component pins
 *
 * @param {*} sigGroup { name, direction, beginChannel, endChannel }
 * name: end to end signal group name
 * direction: TX/RX
 * beginChannel/endChannel: { channelId, channelName, designId, designName, signalName, components: [{ comp, pins: [] }] }
 */

export function getComponents(sigGroup) {
  let components = [];
  sigGroup.forEach(sig => {
    components.push(...sig.beginChannel.components.map(comp => new componentPin({
      name: sig.name,
      designName: sig.beginChannel.designName,
      channelName: sig.beginChannel.channelName,
      signalName: sig.beginChannel.signalName,
      component: comp.name,
      pins: comp.pins
    })))
    components.push(...sig.endChannel.components.map(comp => new componentPin({
      name: sig.name,
      designName: sig.endChannel.designName,
      channelName: sig.endChannel.channelName,
      signalName: sig.endChannel.signalName,
      component: comp.name,
      pins: comp.pins
    })))
  });
  return components;
}

export function getSignalGroup(content) {
  try {
    // find the begin and end channel
    const connections = content.endToEnd.content.connections;
    let conns = null;

    conns = findBeginEndChannel(content);

    let signalGroups = content.signals.map(sig => {
      let _sig = { direction: sig.direction, name: sig.name };
      _sig.beginChannel = { ...sig.details[0], ...conns.find(c => c.channelId === sig.details[0].channelId) };
      _sig.endChannel = { ...sig.details[sig.details.length - 1], ...conns.find(c => c.channelId === sig.details[sig.details.length - 1].channelId) };
      return _sig;
    });

    let channelIndex = {};
    function getComps(signalInfo, key) {
      let index = channelIndex[signalInfo[key].channelId]
      if (index === undefined) {
        index = content.channels.findIndex(d => d.id === signalInfo[key].channelId);
        channelIndex[signalInfo[key].channelId] = index;
      }
      const channelInfo = content.channels[index];
      const comps = channelInfo.content.components.filter(comp =>
        (SERDES_TYPES.includes(comp.type)) &&
        comp.name !== findFilterComp(connections, signalInfo, key)
      );
      return comps.map(comp => ({
        name: comp.name,
        pins: comp.pins.filter(pin => pin.signal === signalInfo[key].signalName).map(d => d.pin)
      }))
    }

    signalGroups.forEach(signalInfo => {
      // begin channel
      signalInfo.beginChannel.components = getComps(signalInfo, 'beginChannel')
      // end channel
      signalInfo.endChannel.components = getComps(signalInfo, 'endChannel')
    })
    return signalGroups;
  } catch (error) {
    console.error(error);
    return []
  }
}

function findFilterComp(connections, signalInfo, key) {
  const connID = key === 'beginChannel' ? signalInfo[key].nextConnectionId : signalInfo[key].prevConnectionId;
  const findConnection = connections.find(conn => conn.CONNECTION_ID === connID);
  if (findConnection.channel1.channelId === signalInfo[key].channelId) {
    return findConnection.connection.connector1.component;
  } else {
    return findConnection.connection.connector2.component;
  }
}

function findBeginEndChannel(content) {
  const pcbConnections = content.endToEnd.content.pcbConnections;
  return pcbConnections;
}

/**
 *
 * name - end to end signal group name, this name used the first design's signal name
 * designName - design name
 * channelName - channel name
 * signalName - signal name in channel
 * component - component name
 * posPin - positive pin
 * negPin - negative pin
 *
 * @param {*} { name, design, channel, signal, component, posPin, negPin }
 */
function componentPin({ name, designName, channelName, signalName, component, pins }) {
  this.name = name;
  this.designName = designName;
  this.channelName = channelName;
  this.signalName = signalName;
  this.component = component;
  this.pins = pins;
}

function findPort(portList, sigGroup, key) {
  return portList.find(d => d.signal === sigGroup.name && d.design === sigGroup[key].designName);
}

function signalItem(name) {
  this.FEXT = { rowName: name, children: [] };
  this.NEXT = { rowName: name, children: [] };
  this.IL = { rowName: name, children: [] };
  this.RL = { rowName: name, children: [] };
  this.COMMIL = { rowName: name, children: [] };
  this.COMMRL = { rowName: name, children: [] };
  this.DiffToComm = { rowName: name, children: [] };
}

function portInfo(list, index) {
  const port = list.find(d => d.index === index);
  return {
    design: port._design,
    compPin: `${port.comp}${port.pinsText}`,
    signal: port.signal
  }
}

export function getEndToEndResultList(content, portList, getColorByIndex, interfaceType, paramFiles) {

  if (interfaceType === CPHY) {
    return getCPHYEndToEndResultList(content, portList, getColorByIndex, paramFiles);
  }

  let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], COMMIL = [], COMMRL = [];  // [{ rowName, children: [{ id, name, color }] }]
  let _portList = portList.filter(d => d.index !== undefined);
  if (!_portList.length) {
    return { FEXT, NEXT, IL, RL, DiffToComm };
  }
  const fileID = _portList[0].fileId;
  function curveInfo(index1, index2, info1, info2) {
    this.id = `${fileID}::${index1 - 1}::${index2 - 1}`;
    this.name = `S(${index1}, ${index2}) ${info2 ? `(${info1}, ${info2})` : info1}`;
    this.color = getColorByIndex(fileID, index1 - 1, index2 - 1);
    this.namePrev = `S(${index1}, ${index2})`;
    this._design1 = info1.design;
    this._design1CompPin = info1.compPin;
    if (info2) {
      this._design2 = info2.design;
      this._design2CompPin = info2.compPin;
      this._signal2 = info2.signal || null;
    }
    this.index1 = index1;
    this.index2 = index2;
  }

  const diffPortList = filterPorts(_portList, 'Diff');
  const commPortList = filterPorts(_portList, 'Comm');
  let _signalGroups = [];

  for (let sigGroup of getSignalGroup(content)) {
    const beginIndexFind = findPort(diffPortList, sigGroup, 'beginChannel');
    const endIndexFind = findPort(diffPortList, sigGroup, 'endChannel');
    const commEndFind = findPort(commPortList, sigGroup, 'endChannel');
    const commBeginFind = findPort(commPortList, sigGroup, 'beginChannel');
    if (beginIndexFind && endIndexFind && commEndFind) {
      sigGroup.beginIndex = beginIndexFind.index;
      sigGroup.endIndex = endIndexFind.index;
      sigGroup.commEndIndex = commEndFind.index;
      sigGroup.commBeginFind = commBeginFind.index;
      _signalGroups.push(sigGroup);
    }
  }
  for (let sigGroup of _signalGroups) {
    let info = new signalItem(sigGroup.name);

    const beginIndex = sigGroup.beginIndex;
    const beginInfo = portInfo(diffPortList, beginIndex);

    const endIndex = sigGroup.endIndex;
    const endInfo = portInfo(diffPortList, endIndex);

    const commBeginIndex = sigGroup.commBeginFind;
    const commBeginPort = portInfo(commPortList, commBeginIndex);


    const commEndIndex = sigGroup.commEndIndex;
    const commEndPort = portInfo(commPortList, commEndIndex);

    info.IL.children.push(new curveInfo(beginIndex, endIndex, beginInfo, endInfo))
    info.RL.children.push(...[new curveInfo(beginIndex, beginIndex, beginInfo), new curveInfo(endIndex, endIndex, endInfo)])
    info.COMMIL.children.push(new curveInfo(commBeginIndex, commEndIndex, commBeginPort, commEndPort))
    info.COMMRL.children.push(...[new curveInfo(commBeginIndex, commBeginIndex, commBeginPort), new curveInfo(commEndIndex, commEndIndex, commEndPort)])
    info.DiffToComm.children.push(new curveInfo(endIndex, commEndIndex, beginInfo, {
      ...commEndPort,
      design: commEndPort.design
    }))

    // info.FEXT/NEXT
    for (let _sigGroup of _signalGroups.filter(d => d.name !== sigGroup.name)) {
      const _beginIndex = _sigGroup.beginIndex;
      const _beginInfo = portInfo(diffPortList, _beginIndex);

      const _endIndex = _sigGroup.endIndex;
      const _endInfo = portInfo(diffPortList, _endIndex);

      const item1 = new curveInfo(beginIndex, _beginIndex, beginInfo, _beginInfo);
      const item2 = new curveInfo(beginIndex, _endIndex, beginInfo, _endInfo);
      const item3 = new curveInfo(endIndex, _beginIndex, endInfo, _beginInfo);
      const item4 = new curveInfo(endIndex, _endIndex, endInfo, _endInfo);

      info.NEXT.children.push(item1)
      info.NEXT.children.push(item4)
      info.FEXT.children.push(item2)
      info.FEXT.children.push(item3)
      // sort
      info.NEXT.children = info.NEXT.children.sort(sort);
      info.FEXT.children = info.FEXT.children.sort(sort);
    }
    FEXT.push(info.FEXT);
    NEXT.push(info.NEXT);
    IL.push(info.IL);
    RL.push(info.RL);
    DiffToComm.push(info.DiffToComm);
    COMMIL.push(info.COMMIL)
    COMMRL.push(info.COMMRL)
  }
  return { FEXT, NEXT, IL, RL, DiffToComm, COMMIL, COMMRL }
}

function portInfoCPHY(list, index, pair) {

  const port = list.find(d => {
    const _pair = d.fileId.split('::')[2]
    return _pair === pair && d.index === index
  });

  return {
    design: port._design,
    compPin: `${port.comp}${port.pinsText}`,
    signal: port.signal,
    fileId: port.fileId
  }
}

function signalItemCPHY(name) {
  this.FEXT = { rowName: name, children: [] };
  this.NEXT = { rowName: name, children: [] };
  this.IL = { rowName: name, children: [] };
  this.RL = { rowName: name, children: [] };
  this.COMMIL = { rowName: name, children: [] };
  this.COMMRL = { rowName: name, children: [] };
  this.DiffToComm = { rowName: name, children: [] };
  this.CrossCoupling = { rowName: name, children: [] };
}

function findCPHYPort(portList, sigGroup, key, pair) {
  return portList.find(d => {
    const _pair = d.fileId.split('::')[2]
    return d.signal === sigGroup.name && d.design === sigGroup[key].designName && _pair === pair
  });
}

function getCouplingItem(info1, info2, col, paramFiles, getColorByIndex) {
  const fileId = info1.fileId || info2.fileId;
  const file = paramFiles.find(item => item.id === fileId);
  const row = file.ports.length;
  const coupling = {
    id: `${fileId}::${row}::${col}`,
    name: 'S(3,4) [Comm] - S(1,2) [Diff]',
    namePrev: 'S(3,4) [Comm] - S(1,2) [Diff]',
    color: getColorByIndex(fileId, row, col)
  }
  return coupling;
}

export function getCPHYEndToEndResultList(content, portList, getColorByIndex, paramFiles) {
  let FEXT = [], NEXT = [], IL = [], RL = [], DiffToComm = [], COMMIL = [], COMMRL = [], CrossCoupling = [];  // [{ rowName, children: [{ id, name, color }] }]
  let _portList = portList.filter(d => d.index !== undefined);
  if (!_portList.length) {
    return { FEXT, NEXT, IL, RL, DiffToComm, CrossCoupling };
  }
  function curveInfo(index1, index2, info1, info2) {
    const fileID = info1.fileId || info2.fileId;
    this.id = `${fileID}::${index1 - 1}::${index2 - 1}`;
    this.name = `S(${index1}, ${index2}) ${info2 ? `(${info1}, ${info2})` : info1}`;
    this.color = getColorByIndex(fileID, index1 - 1, index2 - 1);
    this.namePrev = `S(${index1}, ${index2})`;
    this._design1 = info1.design;
    this._design1CompPin = info1.compPin;
    if (info2) {
      this._design2 = info2.design;
      this._design2CompPin = info2.compPin;
      this._signal2 = info2.signal || null;
    }
    this.index1 = index1;
    this.index2 = index2;
  }

  const diffPortList = filterPorts(_portList, 'Diff');
  const commPortList = filterPorts(_portList, 'Comm');
  let _signalGroups = [];

  for (const sigGroup of getSignalGroup(content)) {
    for (const pair of ['AB', 'BC', 'CA']) {
      const _sigGroup = { ...sigGroup };
      const beginIndexFind = findCPHYPort(diffPortList, sigGroup, 'beginChannel', pair);
      const endIndexFind = findCPHYPort(diffPortList, sigGroup, 'endChannel', pair);
      const commBeginFind = findCPHYPort(commPortList, sigGroup, 'beginChannel', pair);
      const commEndFind = findCPHYPort(commPortList, sigGroup, 'endChannel', pair);
      if (beginIndexFind && endIndexFind && commEndFind) {
        _sigGroup.beginIndex = beginIndexFind.index;
        _sigGroup.endIndex = endIndexFind.index;
        _sigGroup.commEndIndex = commEndFind.index;
        _sigGroup.commBeginFind = commBeginFind.index;
        _sigGroup.pair = pair;
        _signalGroups.push(_sigGroup);
      }
    }
  }

  let col = 0, lastSignal = '';
  for (let sigGroup of _signalGroups) {
    if (sigGroup.name !== lastSignal) {
      col = 0;
      lastSignal = sigGroup.name;
    }
    // rowName 
    let info = new signalItemCPHY(`${sigGroup.name} (${sigGroup.pair})`);

    const beginIndex = sigGroup.beginIndex;
    const beginInfo = portInfoCPHY(diffPortList, beginIndex, sigGroup.pair);

    const endIndex = sigGroup.endIndex;
    const endInfo = portInfoCPHY(diffPortList, endIndex, sigGroup.pair);

    const commBeginIndex = sigGroup.commBeginFind;
    const commBeginPort = portInfoCPHY(commPortList, commBeginIndex, sigGroup.pair);


    const commEndIndex = sigGroup.commEndIndex;
    const commEndPort = portInfoCPHY(commPortList, commEndIndex, sigGroup.pair);

    info.IL.children.push(new curveInfo(beginIndex, endIndex, beginInfo, endInfo))
    info.RL.children.push(...[new curveInfo(beginIndex, beginIndex, beginInfo), new curveInfo(endIndex, endIndex, endInfo)])
    info.COMMIL.children.push(new curveInfo(commBeginIndex, commEndIndex, commBeginPort, commEndPort))
    info.COMMRL.children.push(...[new curveInfo(commBeginIndex, commBeginIndex, commBeginPort), new curveInfo(commEndIndex, commEndIndex, commEndPort)])
    info.DiffToComm.children.push(new curveInfo(endIndex, commEndIndex, beginInfo, {
      ...commEndPort,
      design: commEndPort.design
    }))
    info.CrossCoupling.children.push(getCouplingItem(beginInfo, endInfo, col, paramFiles, getColorByIndex))
    col++;

    IL.push(info.IL);
    RL.push(info.RL);
    DiffToComm.push(info.DiffToComm);
    COMMIL.push(info.COMMIL)
    COMMRL.push(info.COMMRL)
    CrossCoupling.push(info.CrossCoupling)
  }

  return { IL, RL, DiffToComm, COMMIL, COMMRL, NEXT, FEXT, CrossCoupling }
}

function sort(a, b) {
  if (a.index1 !== b.index1) {
    return a.index1 - b.index1;
  } else {
    return a.index2 - b.index2
  }
}