const TYPE = "Cascade/DCR";

export const GET_DCR_INFO = `${TYPE}/get_DCR_info`;
export const UPDATE_DCR_DATA = `${TYPE}/updata_DCR_data`;
export const SAVE_DCR_RESISTANCE_DATA = `${TYPE}/save_DCR_resistance_data`;
export const CHANGE_RESISTANCE_TABLE_LOADING = `${TYPE}/change_resistance_table_loading`;
export const UPLOAD_DCR = `${TYPE}/upload_dcr`;
export const UPLOAD_DCR_MSG = `${TYPE}/upload_dcr_msg`;
export const SAVA_VERIFICATION_ID = `${TYPE}/sava_verfication_id`;
export const DELETE_PATHR_DATA = `${TYPE}/delete_pathR_data`;
export const RESISTANCE_SELECTED_LIST = `${TYPE}/PathR-resistance_selected_list`;
export const CLEAR_CURRENT_PATHR_DATA = `${TYPE}/clear_current_pathr_data`;
export const UPDATE_PCB_ID = `${TYPE}/update_pcb_id`;
export const CHANGE_PCB = `${TYPE}/change_pcb`;
export const SAVE_EXTRACTION = `${TYPE}/save_extraction`;
export const UPDATE_WARNING_MSG = `${TYPE}/update_warning_msg`;