import { RootItem, ComponentItem, ICItem, DriverItem, ConnectionItem, PowerTree } from './treeClass';
import { getAllCascadeComponents, getPMIC, getPowerDomain, checkConnectComp } from '../helper/setupData';
import componentSetting from '../helper/compSettingHelper';
import GetPowerTree from '../../helper/componentsHelper/GetPath/getPowerTree';
import { getLayoutComponents } from '../../helper/setup/setupData';
import { getComponentsByNets } from '../../helper/componentsHelper';
import { IGNORE } from '@/constants/componentType';
import { RES, IND, SWITCH, FERRITE, JUMPER, TRANSISTOR } from '../../PCBHelper';
import { splitArrayToArrays } from '../../helper/arrayHelper';
import { checkPowerTreeWorker, setPowerTreeValueWorker, sortPowerTreeWorker } from './treeWorker';
import _ from 'lodash';
import auroraDBJson from '../../Designs/auroraDbData';

const ComponentType = [IND, RES, SWITCH, FERRITE, JUMPER];
async function getRootComponents(designId) {
  const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(designId);
  const allComponents = getAllCascadeComponents({ pcbId: designId });
  const PMIC = getPMIC(COMP_PREFIX_LIB);
  const basicComponents = [...allComponents.values()].filter(comp => comp.type === IGNORE
    && (comp.pins.size >= 5 || comp.pmicType));

  const comps = basicComponents.map(comp => {
    const pmic = PMIC.find(item => item.partName === comp.partName);
    if (pmic) {
      return { ...comp, pmicType: pmic.type }
    } else {
      return comp
    }
  })

  return comps.sort((a, b) => a.name > b.name ? 1 : -1).sort((a, b) => !b.pmicType && a.pmicType ? -1 : 1)
}

// For power tree find path
function findPowerTreePath(params) {
  const treeClass = { RootItem, ComponentItem, ICItem, DriverItem }
  const treeFunctions = { getComponents: getAllCascadeComponents, getLayoutComponents, getPowerDomain, sortTree }

  const GetTree = new GetPowerTree(params, treeClass, treeFunctions)
  const tree = GetTree.getPowerTree();

  let deep = -1;
  tree.forEach(branch => {
    if (deep < branch[branch.length - 1].deepIndex) {
      deep = branch[branch.length - 1].deepIndex
    }
  })

  return { tree, deep }
}

async function sortTree(treeData, connectors = []) {
  let tree = JSON.parse(JSON.stringify(treeData));
  const newTree = await sortPowerTreeWorker(tree, connectors, ComponentType)
  return newTree
}

function powerTreeErrorCheck(powerTrees) {
  let error = [], hasValue = false;
  for (let powerTree of powerTrees) {
    const { tree } = powerTree;
    const { name, branch } = tree;
    const valueInfo = { res: [] };
    for (let column of branch) {
      for (let item of column) {
        switch (item.type) {
          case 'root':
            if (!item.voltage) {
              valueInfo.root = `[Power Tree][${name}][${item.name}] Voltage can not be empty.`;
            }
            break;
          case 'Load':
          case 'VRM':
            if (item.load && ((item.middle && !item.voltage) || !item.middle)) {
              hasValue = true;
              valueInfo.hasPath = true;
            }
            break;
          case 'Diode':
            break;
          default:
            if (!item.resistance) {
              valueInfo.res = [...valueInfo.res, `[Power Tree][${name}][${item.name}] Value can not be empty.`];
            }
            break;
        }
      }
    }
    if (valueInfo.hasPath) {
      if (valueInfo.root) {
        error.push(valueInfo.root)
      }
      error.push(...valueInfo.res)
    }
  }
  return hasValue ? [...error] : [`[Power Tree] No path found to simulation.`];
}

function getGroundNetByGroundPins({ groundPins, designId, chip }) {
  const allComponents = getAllCascadeComponents({ pcbId: designId });
  const comp = allComponents.get(chip);
  if (comp) {
    const { pins = [] } = comp;
    const ground = [...pins.values()].filter(pin => groundPins.includes(pin.pinName));
    const groundNet = [...new Set(ground.map(pin => pin.net))];
    return groundNet.join(', ')
  }
  return ""
}

async function generatePowerTree({ PMICs, pmic, setting, table, pinMap, designId, doNotStuff, connectors, moreSetting, pinConnection, showGnd, pcbTable = [], treeColor = [], setTreeMonitors, useInput, powerTreeLevel }) {
  const treeObj = {};
  const treeClass = { RootItem, ComponentItem, ICItem, DriverItem, ConnectionItem }
  const treeFunctions = { getComponents: getAllCascadeComponents, getLayoutComponents, getPowerDomain, sortTree, reShowGndLine, setTreeMonitors };
  // get Power Tree by PMIC
  const { partName, name, pins, type } = pmic;
  const find = pinMap.find(item => (item.partNumber === partName && (!item.components || !item.components.length || item.components.includes(name))) || (type === TRANSISTOR && item.partNumber.includes(name)));
  const extraPMIC = pcbTable.map(item => item.components).flat();
  if (find && find.data && find.data.length) {
    const { data } = find;

    let broComp = undefined;
    if (type === TRANSISTOR) {
      const buckConverter = setting.discreteBuckConverter || [];
      const findBuck = buckConverter.find(item => item.includes(name));
      if (findBuck) {
        const bro = PMICs.find(p => findBuck.includes(p.name) && name !== p.name);
        broComp = { ...bro }
      }
    }


    const connect = await checkConnectComp(name, [...pins.values()], designId);
    let output = data.map(item => item.output);
    if (pcbTable.length) {
      const row = pcbTable.find(item => (item.components && item.components.includes(name)) || partName === item.partNumber || (type === TRANSISTOR && item.partNumber.includes(name)));
      if (row && row.nets) {
        output = row.nets.map(item => item.pinNames);
      } else if (!row || !row.nets || !row.nets.length) {
        setTreeMonitors([{ text: `\t\tX Skip. ${name} does not select nets.`, type: 'error' }])
      }
    }
    const groundPins = data.map(item => item.ground || []).flat(2);
    let groundNet = "";
    if (groundPins.length) {
      if (type === TRANSISTOR && !groundNet) {
        const _groundPins = groundPins.map(pin => {
          const [comp, p] = pin.split('_');
          return { comp, pin: p }
        });
        const selfPins = _groundPins.filter(item => item.comp === name);
        if (selfPins.length) {
          groundNet = getGroundNetByGroundPins({ groundPins: selfPins.map(p => p.pin), designId, chip: name });
        } else {
          const broPins = _groundPins.filter(item => item.comp === broComp.name);
          groundNet = getGroundNetByGroundPins({ groundPins: broPins.map(p => p.pin), designId, chip: broComp.name });
        }
      } else {
        groundNet = getGroundNetByGroundPins({ groundPins, designId, chip: name });
      }
    }
    if (!groundNet) {
      groundNet = getPowerDomain({ chip: name, designId, COMP_PREFIX_LIB: setting }).groundNet;
    }
    if (type === TRANSISTOR && connect.some(c => groundNet.split(', ').includes(c.net) && output.flat(2).includes(`${name}_${c.pinName}`))) {
      setTreeMonitors([{ text: `\t\tX Skip. ${name} - The output Net is the same as the ground Net. Please reset the Output pins or Ground pins in the Pin Map.`, type: 'error' }])
      return {};
    }

    const treeParams = {
      root: name,
      GroundNet: groundNet,
      COMP_PREFIX_LIB: setting,
      PMIC: getPMIC(setting),
      designId,
      compTable: table,
      isDesignTree: true,
      doNotStuff,
      broComp,
      pinMap,
      connectors,
      moreSetting,
      pinConnection,
      showGnd,
      treeColor,
      useInput,
      powerTreeLevel,
      extraPMIC
    }
    const allOutput = output.flat(2)
    for (let outPins of output) {
      let _pins = connect.filter(item => outPins.includes(item.pinName) || (type === TRANSISTOR && outPins.includes(`${name}_${item.pinName}`)));
      if (_pins.length) {
        const netName = _pins[0].nextNet ? _pins[0].nextNet : _pins[0].net;
        if (_pins[0].nextNet) {
          _pins = connect.filter(item => item.nextNet === netName && (allOutput.includes(item.pinName) || (type === TRANSISTOR && allOutput.includes(`${name}_${item.pinName}`))))
        }
        if (treeObj[netName]) {
          continue;
        }
        const treeName = `${netName} - ${name} - ${partName}`;
        setTreeMonitors([{ text: `\t\t* ${outPins} -> ${netName}`, type: 'nomral', key: treeName }])
        const GetTree = new GetPowerTree({ ...treeParams, pins: _pins.map(pin => pin.pin), treeName }, treeClass, treeFunctions);
        const paths = GetTree.getPowerTree();
        if (paths.length) {
          treeObj[treeName] = {
            name,
            GetTree,
            groundNet,
            pins: connect,
            select: _pins.map(pin => pin.pin),
            part: partName,
            treeName,
            paths
          }
        }
      } else {
        setTreeMonitors([{ text: `\t\tX Skip. Pin Map for ${name} whitout out pins.`, type: 'error' }])
      }
    }
  } else {
    setTreeMonitors([{ text: `\t\tX Skip. Pin Map for ${name} not found.`, type: 'error' }])
  }
  return treeObj;
}

async function checkingPowerTree({ designId, setting, treeObj, pinMap, table, root, pcbTable }) {
  // merge tree by pin map
  if (!treeObj[root]) {
    return treeObj
  }
  const allComponents = getAllCascadeComponents({ pcbId: designId });
  const allNets = auroraDBJson.getNets(designId)
  const { paths, groundNet: rootGnd } = treeObj[root];
  const params = { paths, allComponents, pinMap, designId, treeObj, rootGnd, setting, table, root, pcbTable, nets: [...allNets.values()] };
  const fn = { checkConnectComp, getCompsInGndPath, getGndComps }

  // Promise.race
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error('Timeout')), 100000);
  });

  const workerPromise = checkPowerTreeWorker(params, fn);

  try {
    const { _paths, groundComps, objChange, nextKeys } = await Promise.race([workerPromise, timeoutPromise]);

    treeObj[root].groundComps = [...groundComps];
    treeObj[root].paths = [..._paths];
    treeObj[root].nextKeys = [...nextKeys];

    const objKeys = Object.keys(objChange);
    for (let key of objKeys) {
      const itemKeys = Object.keys(objChange[key]);
      for (let itemKey of itemKeys) {
        treeObj[key][itemKey] = objChange[key][itemKey];
      }
    }

    return treeObj;
  } catch (error) {
    console.error(error)
    return treeObj;
  }
}

async function mergePowerTree(treeObj, root, treeId, setSingleTreeLoading, oldPowerTrees, treeMonitorLogs) {
  // generate tree
  let PowerTrees = []
  const { paths, select, GetTree, groundNet, part, name, groundComps = [], nextKeys = [] } = treeObj;
  const tree = await GetTree.generateDesignTree(paths, select);
  const pcbIndex = GetTree.getPCBIndex();
  const allKeys = [`${root} - ${name} - ${part}`, ...nextKeys];
  const logs = allKeys.map(k => {
    const compName = k.split(' - ')[1] || '';
    return [{ text: `PMIC: ${compName}`, type: 'nomral' }, ...treeMonitorLogs.filter(log => log.key === k), { text: ``, type: 'empty' }]
  }).flat(2);

  if (tree.length <= 1) {
    return PowerTrees;
  }

  treeId && setSingleTreeLoading && setSingleTreeLoading(treeId, 'Sorting Power Tree Data...')
  let deep = -1;
  tree.forEach(branch => {
    if (branch[branch.length - 1] && deep < branch[branch.length - 1].deepIndex) {
      deep = branch[branch.length - 1].deepIndex
    }

    const connectors = branch.filter(item => item.type === 'Connector');
    const gndComps = branch.filter(item => item.type === 'Load' && item.isGnd);
    if (connectors.length || gndComps.length) {
      branch.forEach(comp => {
        if (connectors.length) {
          if (comp.type === 'Connector') {
            const existDeep = branch.map(item => item.deepIndex).sort((a, b) => a - b)
            const connList = branch.filter(item => item.name === comp.name && item.type === comp.type && item.pcbKey === comp.pcbKey);
            const connMap = connList.map(item => item.deepIndex).sort((a, b) => a - b);
            let grid = 1, index = connMap.findIndex(i => i === comp.deepIndex), startIndex = comp.deepIndex;
            for (let i = index + 1; i < connMap.length; i++) {
              if (connMap[i] === startIndex + 1) {
                grid = grid + 1;
                startIndex = startIndex + 1
              } else {
                let exist = false;
                for (let j = startIndex + 1; j < connMap[i]; j++) {
                  if (existDeep.includes(j)) {
                    exist = true;
                    break;
                  }
                }
                if (exist) {
                  break;
                }
                grid = grid + connMap[i] - startIndex;
                startIndex = connMap[i]
              }
            }
            comp.grid = grid
            let connectorPins = {};
            connList.forEach(conn => {
              connectorPins[conn.prevNet] = conn.pins
            })
            comp.connectorPins = connectorPins;
          }
        }

        if (gndComps.length && !comp.isGnd) {
          const findGnd = gndComps.filter(item => item.name === comp.name && item.pcbKey === comp.pcbKey);
          if (findGnd.length) {
            comp.gnd = [...new Set(findGnd.map(f => f.prevNet))].join(', ')
          }
        }

        if (comp.pcbKey && !comp.isGnd && !comp.gnd && comp.type === 'Load') {
          const findGnd = tree.flat(2).filter(item => item.name === comp.name && item.pcbKey === comp.pcbKey && item.isGnd);
          if (findGnd.length) {
            comp.gnd = [...new Set(findGnd.map(f => f.prevNet))].join(', ')
          } else {
            const { groundNet: _groundNet } = getPowerDomain({ chip: comp.name, designId: comp.pcbId });
            comp.gnd = _groundNet
          }
        }

        if (comp.isGnd && comp.gnd) {
          comp.gnd = ""
        }
      })
    }
  })

  treeId && setSingleTreeLoading && setSingleTreeLoading(treeId, 'Transferring Power Tree Data...')
  const powerTree = new PowerTree({
    id: treeId,
    tree: {
      root: name,
      name: `${root} - ${name} - ${part}`,
      key: `${root} - ${name} - ${part}`,
      branch: tree,
      groundNet,
      pins: { select },
      deep,
      groundComps,
      pcbIndex,
      logs,
      nextKeys,
      whatIf: false,
      whatIfData: []
    }
  });

  let newPowerTree = { ...powerTree }
  const findTree = oldPowerTrees.find(item => item.tree.name === newPowerTree.tree.name
    || (item.tree.root === newPowerTree.tree.root && item.tree.pins.select
      && newPowerTree.tree.pins.select && newPowerTree.tree.pins.select.some(pin => item.tree.pins.select.includes(pin))));
  if (findTree) {
    const oldData = findTree.tree.branch.flat(2);
    newPowerTree = await setPowerTreeValueWorker(newPowerTree, oldData)
  }

  PowerTrees.push(newPowerTree);

  return PowerTrees;
}

async function getDesignPowerTree_DEPRECATED({ PMICs, setting, table, pinMap, designId, doNotStuff, connectors, moreSetting, treeKeys = [], pinConnection, showGnd, pcbTable = [], treeColor = [] }) {
  const pathObj = {};
  const treeClass = { RootItem, ComponentItem, ICItem, DriverItem, ConnectionItem }
  const treeFunctions = { getComponents: getAllCascadeComponents, getLayoutComponents, getPowerDomain, sortTree, reShowGndLine }
  // get Power tree by PMIC
  for (let pmic of PMICs) {
    const { part, name, pins, usage } = pmic;
    const find = pinMap.find(item => (item.partNumber === part && (!item.components || !item.components.length || item.components.includes(name))) || (usage === TRANSISTOR && item.partNumber.includes(name)));
    if (find && find.data && find.data.length) {
      const { data } = find;
      const connect = await checkConnectComp(name, pins, designId);
      let output = data.map(item => item.output);
      if (pcbTable.length) {
        const row = pcbTable.find(item => (item.components && item.components.includes(name)) || part === item.partNumber || (usage === TRANSISTOR && item.partNumber.includes(name)));
        if (row && row.nets) {
          output = row.nets.map(item => item.pinNames);
        }
      }
      const groundPins = data.map(item => item.ground || []).flat(2);
      let groundNet = "";
      if (groundPins.length) {
        groundNet = getGroundNetByGroundPins({ groundPins, designId, chip: name });
      }
      if (!groundNet) {
        groundNet = getPowerDomain({ chip: name, designId, COMP_PREFIX_LIB: setting }).groundNet;
      }
      if (usage === TRANSISTOR && connect.some(c => groundNet.split(', ').includes(c.net) && output.flat(2).includes(`${name}_${c.pinName}`))) {
        continue;
      }

      let broComp = undefined;
      if (usage === TRANSISTOR) {
        const buckConverter = setting.discreteBuckConverter || [];
        const findBuck = buckConverter.find(item => item.includes(name));
        if (findBuck) {
          const bro = PMICs.find(p => findBuck.includes(p.name) && name !== p.name);
          broComp = { ...bro }
        }
      }

      const treeParams = {
        root: name,
        GroundNet: groundNet,
        COMP_PREFIX_LIB: setting,
        PMIC: getPMIC(setting),
        designId,
        compTable: table,
        isDesignTree: true,
        doNotStuff,
        broComp,
        pinMap,
        connectors,
        moreSetting,
        pinConnection,
        showGnd,
        treeColor
      }
      const allOutput = output.flat(2)
      for (let outPins of output) {
        let _pins = connect.filter(item => outPins.includes(item.pinName) || (usage === TRANSISTOR && outPins.includes(`${name}_${item.pinName}`)));
        if (_pins.length) {
          const netName = _pins[0].nextNet ? _pins[0].nextNet : _pins[0].net;
          if (_pins[0].nextNet) {
            _pins = connect.filter(item => item.nextNet === netName && (allOutput.includes(item.pinName) || (usage === TRANSISTOR && allOutput.includes(`${name}_${item.pinName}`))))
          }
          if (pathObj[netName]) {
            continue;
          }
          const GetTree = new GetPowerTree({ ...treeParams, pins: _pins.map(pin => pin.pin) }, treeClass, treeFunctions);
          const paths = GetTree.getPowerTree();
          pathObj[netName] = {
            name,
            GetTree,
            paths: paths || [],
            groundNet,
            pins: connect,
            select: _pins.map(pin => pin.pin),
            part
          }
        }
      }
    }
  }

  // merge tree by pin map
  const allComponents = getAllCascadeComponents({ pcbId: designId });
  const roots = Object.keys(pathObj);
  for (let root of roots) {
    const { paths, groundNet: rootGnd } = pathObj[root];
    let _paths = [], groundComps = [];
    for (let path of paths) {
      const lastComp = path[path.length - 1];
      const { part, type, name, pins } = lastComp;
      const currentComp = allComponents.get(name);
      const allPins = currentComp && currentComp.pins ? [...currentComp.pins.values()] : [];
      if (type === 'VRM') {
        const find = pinMap.find(item => item.partNumber === part);
        let out = [];
        if (find && find.data && find.data.length) {
          const { data } = find;
          const pinNames = pins.map(item => item.pinName);
          out = pinNames.map(item => {
            const findMap = data.filter(d => d.input.includes(item));
            return findMap.length ? findMap.map(d => d.output).flat(2) : [];
          })
          out = [...new Set(out.flat(2))]
        }
        const _pins = allPins.filter(p => out.includes(p.pinName));
        let _connect = await checkConnectComp(name, _pins, designId);
        _connect = [...new Set(_connect.map(c => c.nextNet ? c.nextNet : c.net))];
        if (_connect.length) {
          for (let netName of _connect) {
            let VRMPath = pathObj[netName] ? pathObj[netName].paths : null;
            if (VRMPath && out.length) {
              const pinNet = pathObj[netName].pins.filter(item => out.includes(item.pinName)).map(item => item.net);
              const rule = (item) => {
                return pinNet.includes(item[0].prevNet) ? true : false;
              }
              const { trueArray: filterPath, falseArray: keepPath } = splitArrayToArrays({ array: VRMPath, rule });
              const lastIndex = path.length - 1;
              path[lastIndex].pins = pathObj[netName].pins.filter(pin => [...rootGnd.split(', '), ...pinNet, path[lastIndex].prevNet].includes(pin.net));
              pathObj[netName].paths = keepPath;
              const filterPins = pathObj[netName].pins.filter(p => out.includes(p.pinName)).map(p => p.pin);
              pathObj[netName].select = pathObj[netName].select.filter(se => !filterPins.includes(se));
              path[lastIndex].middle = true;
              const { groundNet } = getPowerDomain({ chip: path[lastIndex].name, designId, COMP_PREFIX_LIB: setting, prevGround: rootGnd });
              if (groundNet !== rootGnd) {
                path[lastIndex].gnd = groundNet;
                const comp = allComponents.get(path[lastIndex].name);
                const pins = comp ? [...comp.pins.values()].filter(pin => pin.net === groundNet) : [];
                path[lastIndex].pins.push(...pins)
                let gndComps = getCompsInGndPath(groundNet, rootGnd, [], designId, setting, allComponents);
                gndComps = getGndComps(gndComps, groundComps, table);
                groundComps.push(...gndComps)
              }
              path[lastIndex].gnd = groundNet;
              _paths.push(...filterPath.map(p => {
                const nextNet = p[0].prevNet;
                const _path = _.cloneDeep(path)
                _path[lastIndex].nextNet = nextNet;
                _path[lastIndex].pins = _path[lastIndex].pins.filter(pin => [groundNet, path[lastIndex].prevNet, nextNet].includes(pin.net))
                let _p = [...p];
                const { groundNet: lastGnd } = getPowerDomain({ chip: _p[_p.length - 1].name, designId, COMP_PREFIX_LIB: setting, prevGround: rootGnd });
                if (lastGnd !== rootGnd) {
                  _p[_p.length - 1].gnd = lastGnd;
                }
                return [..._path, ..._p]
              }));
            } else {
              const lastIndex = path.length - 1;
              const { groundNet } = getPowerDomain({ chip: path[lastIndex].name, designId, COMP_PREFIX_LIB: setting, prevGround: rootGnd });
              if (groundNet !== rootGnd) {
                path[lastIndex].gnd = groundNet;
                const comp = allComponents.get(path[lastIndex].name);
                const pins = comp ? [...comp.pins.values()].filter(pin => pin.net === groundNet) : [];
                path[lastIndex].pins = [...path[lastIndex].pins, ...pins]
                let gndComps = getCompsInGndPath(groundNet, rootGnd, [], designId, setting, allComponents);
                gndComps = getGndComps(gndComps, groundComps, table);
                groundComps.push(...gndComps)
              }
              _paths.push(path)
            }
          }
        } else {
          const lastIndex = path.length - 1;
          const { groundNet } = getPowerDomain({ chip: path[lastIndex].name, designId, COMP_PREFIX_LIB: setting, prevGround: rootGnd });
          if (groundNet !== rootGnd) {
            path[lastIndex].gnd = groundNet;
            const comp = allComponents.get(path[lastIndex].name);
            const pins = comp ? [...comp.pins.values()].filter(pin => pin.net === groundNet) : [];
            path[lastIndex].pins = [...path[lastIndex].pins, ...pins]
            let gndComps = getCompsInGndPath(groundNet, rootGnd, [], designId, setting, allComponents);
            gndComps = getGndComps(gndComps, groundComps, table);
            groundComps.push(...gndComps)
          }
          _paths.push(path)
        }
      } else {
        const lastIndex = path.length - 1;
        const { groundNet } = getPowerDomain({ chip: path[lastIndex].name, designId, COMP_PREFIX_LIB: setting, prevGround: rootGnd });
        if (groundNet !== rootGnd) {
          path[lastIndex].gnd = groundNet;
          const comp = allComponents.get(path[lastIndex].name);
          const pins = comp ? [...comp.pins.values()].filter(pin => pin.net === groundNet) : [];
          path[lastIndex].pins = [...path[lastIndex].pins, ...pins]
          let gndComps = getCompsInGndPath(groundNet, rootGnd, [], designId, setting, allComponents);
          gndComps = getGndComps(gndComps, groundComps, table);
          groundComps.push(...gndComps)
        }
        _paths.push(path)
      }
    }
    pathObj[root].groundComps = [...groundComps]
    pathObj[root].paths = [..._paths]
  }

  // generate tree
  let PowerTrees = []
  for (let root of roots) {
    const { paths, select, GetTree, groundNet, part, name, groundComps = [] } = pathObj[root];
    if (!treeKeys.length || treeKeys.includes(`${root} - ${name} - ${part}`)) {
      const tree = await GetTree.generateDesignTree(paths, select);
      const pcbIndex = GetTree.getPCBIndex();
      const logs = GetTree.getLogs();

      if (tree.length <= 1) {
        continue;
      }

      let deep = -1;
      tree.forEach(branch => {
        if (branch[branch.length - 1] && deep < branch[branch.length - 1].deepIndex) {
          deep = branch[branch.length - 1].deepIndex
        }

        const connectors = branch.filter(item => item.type === 'Connector');
        const gndComps = branch.filter(item => item.type === 'Load' && item.isGnd);
        if (connectors.length || gndComps.length) {
          branch.forEach(comp => {
            if (connectors.length) {
              if (comp.type === 'Connector') {
                const existDeep = branch.map(item => item.deepIndex).sort((a, b) => a - b)
                const connList = branch.filter(item => item.name === comp.name && item.type === comp.type && item.pcbKey === comp.pcbKey);
                const connMap = connList.map(item => item.deepIndex).sort((a, b) => a - b);
                let grid = 1, index = connMap.findIndex(i => i === comp.deepIndex), startIndex = comp.deepIndex;
                for (let i = index + 1; i < connMap.length; i++) {
                  if (connMap[i] === startIndex + 1) {
                    grid = grid + 1;
                    startIndex = startIndex + 1
                  } else {
                    let exist = false;
                    for (let j = startIndex + 1; j < connMap[i]; j++) {
                      if (existDeep.includes(j)) {
                        exist = true;
                        break;
                      }
                    }
                    if (exist) {
                      break;
                    }
                    grid = grid + connMap[i] - startIndex;
                    startIndex = connMap[i]
                  }
                }
                comp.grid = grid
                let connectorPins = {};
                connList.forEach(conn => {
                  connectorPins[conn.prevNet] = conn.pins
                })
                comp.connectorPins = connectorPins;
              }
            }

            if (gndComps.length && !comp.isGnd) {
              const findGnd = gndComps.filter(item => item.name === comp.name && item.pcbKey === comp.pcbKey);
              if (findGnd.length) {
                comp.gnd = [...new Set(findGnd.map(f => f.prevNet))].join(', ')
              }
            }

            if (comp.pcbKey && !comp.isGnd && !comp.gnd && comp.type === 'Load') {
              const findGnd = tree.flat(2).filter(item => item.name === comp.name && item.pcbKey === comp.pcbKey && item.isGnd);
              if (findGnd.length) {
                comp.gnd = [...new Set(findGnd.map(f => f.prevNet))].join(', ')
              } else {
                const { groundNet: _groundNet } = getPowerDomain({ chip: comp.name, designId: comp.pcbId });
                comp.gnd = _groundNet
              }
            }
          })
        }
      })

      const powerTree = new PowerTree({
        tree: {
          root: name,
          name: `${root} - ${name} - ${part}`,
          key: `${root} - ${name} - ${part}`,
          branch: tree,
          groundNet,
          pins: { select },
          deep,
          groundComps,
          pcbIndex,
          logs
        }
      });
      PowerTrees.push(powerTree);
    }
  }
  return PowerTrees
}

function getGndComps(gndComps, groundComps, table) {
  let comps = gndComps.map(item => {
    if (groundComps.map(c => c.name).includes(item.name)) {
      return null;
    } else {
      const value = table.find(t => t.name.includes(item.name));
      return { ...item, value: value ? value.value : '0' }
    }
  }).filter(item => !!item)
  return comps
}

const found = []
function getCompsInGndPath(groundNet, rootGnd, comps, designId, COMP_PREFIX_LIB, allComponents) {
  if (!found.includes(`${groundNet}-${rootGnd}`)) {
    if (!groundNet) {
      return [];
    }
    let components = getComponentsByNets({
      netList: groundNet.split(', '),
      pcbId: designId,
      COMP_PREFIX_LIB,
      layers: {},
      getLayoutComponents: getLayoutComponents,
      powerSwitch: COMP_PREFIX_LIB.powerSwitch || []
    }).map(comp => comp.name)

    components = [...allComponents.values()].filter(item => components.includes(item.name)).filter(item => item.usage !== 'Ignore');
    found.push(`${groundNet}-${rootGnd}`)

    return components.filter(item => [...item.pins.values()].some(pin => pin.net === rootGnd))
  }
  return []
}

function reShowGndLine(tree) {
  let _tree = [...tree];
  _tree.forEach((branch, index) => {
    branch.forEach((comp, cIndex) => {
      const pathKey = comp.name.includes('hidden') ? comp.pathKey : (comp.pathKey || []).filter(item => !item.name.includes('hidden'))
      const compIndex = pathKey ? pathKey.findIndex(p => p.pcbKey === comp.pcbKey && p.name === comp.name) : -1;
      const nextComp = pathKey && compIndex > -1 ? pathKey[compIndex + 1] : undefined;
      if (comp.isGnd) {
        const sameComp = branch.map((item, gIndex) => item.name === comp.name && (!comp.connComp || (comp.connComp && comp.connComp === item.connComp)) && item.prevNet === comp.prevNet && item.pcbKey === comp.pcbKey && item.isGnd ? gIndex : undefined).filter(g => g !== undefined);
        if (sameComp.length < 2) {
          comp.hiddenLine = false;
          comp.hiddenPins = false;
          // _tree[index - 1].forEach(_comp => {
          //   if (_comp.gndKey === comp.gndKey) {
          //     _comp.hiddenLine = false;
          //   }
          // })
        } else {

          if (cIndex === sameComp[sameComp.length - 1]) {
            comp.hiddenPins = false;
            comp.hiddenLine = false;
          } else if (comp.type === 'Connector' || comp.name.includes('hidden')) {
            comp.hiddenPins = true;
            const _sameComp = branch.map((item, gIndex) => {
              const _pathKey = item.name.includes('hidden') ? item.pathKey : (item.pathKey || []).filter(i => !i.name.includes('hidden'))
              const index = _pathKey ? _pathKey.findIndex(p => p.pcbKey === item.pcbKey && p.name === item.name) : -1;
              if (!nextComp || index < 0 || !_pathKey[index + 1]) {
                return item.name === comp.name && (!comp.connComp || (comp.connComp && comp.connComp === item.connComp)) && item.prevNet === comp.prevNet && item.pcbKey === comp.pcbKey && item.isGnd ? gIndex : undefined
              }
              const _nextComp = _pathKey[index + 1];
              return (nextComp.name === _nextComp.name) && nextComp.pcbKey === _nextComp.pcbKey && item.name === comp.name && (!comp.connComp || (comp.connComp && comp.connComp === item.connComp)) && item.prevNet === comp.prevNet && item.pcbKey === comp.pcbKey && item.isGnd ? gIndex : undefined
            })
              .filter(g => g !== undefined);
            comp.hiddenLine = true;
            comp.hiddenLine = _sameComp.length > 1 ? cIndex === _sameComp[_sameComp.length - 1] ? false : true : false;
          }
        }
      } else if (comp.type === 'Connector') {
        const sameComp = branch.map((item, gIndex) => item.name === comp.name && item.nextNet === comp.nextNet && item.prevNet === comp.prevNet && item.pcbKey === comp.pcbKey && !item.isGnd ? gIndex : undefined).filter(g => g !== undefined);
        if (sameComp.length > 1) {
          if (cIndex === sameComp[0]) {
            comp.hiddenPins = false;
            comp.hiddenLine = false;
          } else {
            comp.hiddenPins = true;
            const _sameComp = branch.map((item, gIndex) => {
              const _pathKey = item.name.includes('hidden') ? item.pathKey : (item.pathKey || []).filter(i => !i.name.includes('hidden'))
              const index = _pathKey ? _pathKey.findIndex(p => p.pcbKey === item.pcbKey && p.name === item.name) : -1;
              if (!nextComp || index < 0 || !_pathKey[index + 1]) {
                return item.name === comp.name && item.nextNet === comp.nextNet && item.prevNet === comp.prevNet && item.pcbKey === comp.pcbKey && !item.isGnd ? gIndex : undefined
              }
              const _nextComp = _pathKey[index + 1];
              return (nextComp.name === _nextComp.name) && nextComp.pcbKey === _nextComp.pcbKey && item.name === comp.name && item.nextNet === comp.nextNet && item.prevNet === comp.prevNet && item.pcbKey === comp.pcbKey && !item.isGnd ? gIndex : undefined
            })
              .filter(g => g !== undefined);
            comp.hiddenLine = _sameComp.length > 1 ? cIndex === _sameComp[0] ? false : true : false;
          }
        }
      }
    })
  })
  return _tree
}


export {
  getRootComponents,
  findPowerTreePath,
  sortTree,
  powerTreeErrorCheck,
  getDesignPowerTree_DEPRECATED,
  reShowGndLine,
  generatePowerTree,
  checkingPowerTree,
  mergePowerTree,
  getCompsInGndPath,
  getGndComps
}