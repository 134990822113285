import {
  RESET_SETUP,
  SET_POWER_TREE_DATA,
  GET_POWER_TREE_SETTING,
  SAVE_CONNECTORS_TO_SETUP,
  SAVE_ROOT_TO_SETUP,
  SAVE_MULTI_RESULT,
  SAVE_MULTI_SPICE_RESULT
} from "./actionType"

export const resetSetup = () => ({
  type: RESET_SETUP
})

export const setPowerTreeData = (params) => ({
  type: SET_POWER_TREE_DATA,
  params
})

export const getPowerTreeSetting = (verificationId) => ({
  type: GET_POWER_TREE_SETTING,
  verificationId
})

export const saveConnectors = (connectors, refresh) => ({
  type: SAVE_CONNECTORS_TO_SETUP,
  connectors,
  refresh
})

export const saveRootInfo = (root, refresh) => ({
  type: SAVE_ROOT_TO_SETUP,
  root,
  refresh
})

export const saveMultiResults = (results) => ({
  type: SAVE_MULTI_RESULT,
  results
})

export const saveMultiSpiceResults = (spiceResults) => ({
  type: SAVE_MULTI_SPICE_RESULT,
  spiceResults
})