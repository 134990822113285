import React, { Component } from "react";
import { connect } from 'react-redux';
import Table from '@/components/EditableTable';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getPCBConnectionsColumns, getEndToEndPCBConnections } from '@/services/Andes_v2/endToEndChannel';
import {
  updateEndToEndChannelContent,
  saveChannelSelect,
  delPCBConnection,
  addPCBInConnection
} from '../store/endToEndChannel/action';
import ChannelSelect from './ChannelSelect';
import PCBChannelSchematic from './PCBChannelSchematic';
import endToEndChannelConstructor from '@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor';
import "./index.css";

class ChannelTopology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionColumns: getPCBConnectionsColumns(props.pcbConnections)
    }
  }

  componentDidMount = () => {
    const connectionColumns = getPCBConnectionsColumns(this.props.pcbConnections);
    this.setState({ connectionColumns }, () => {
      this.columnsCell(connectionColumns);
    })
  }

  componentDidUpdate = (prevProps) => {
    const { pcbConnections, CONNECTION_ID, channelTopologyUpdate } = this.props;
    if (prevProps.pcbConnections.length !== pcbConnections.length
      || CONNECTION_ID !== prevProps.CONNECTION_ID
      || (channelTopologyUpdate && channelTopologyUpdate !== prevProps.channelTopologyUpdate)
    ) {
      const connectionColumns = getPCBConnectionsColumns(this.props.pcbConnections);
      this.setState({ connectionColumns }, () => {
        this.columnsCell(connectionColumns);
      })
      channelTopologyUpdate && this.props._setupUpdateStatus(false, "channelTopologyUpdate");
    }
  }

  columnsCell = (connectionColumns) => {
    const { pcbConnections = [] } = this.props;
    let _connectionColumns = [...connectionColumns];
    _connectionColumns.forEach((column, index) => {
      const dataIndex = column.dataIndex;
      column.title = () => {
        return (
          <div className='pcb-connection-table-title'>
            <span>{column.name}</span>
            {<PlusCircleOutlined
              title="Add a PCB"
              className="pcb-connection-add-section"
              onClick={() => this.props._addPCBInConnection(column.key)} />
            }
            {_connectionColumns.length > 2 ?
              <CloseOutlined
                className="pcb-connection-delete-section"
                title="Delete the PCB"
                onClick={() => this.props._delPCBConnection(column.key)} />
              : null}
          </div>
        );
      }

      column.onCell = (record) => {
        const { projectId, endToEndChannelId, _saveChannelSelect } = this.props;
        return {
          edit: true,
          record,
          info: record[dataIndex],
          text: this.getChannelText(record[dataIndex]),
          customInput: ChannelSelect,
          pcbConnections,
          dataIndex,
          projectId,
          endToEndChannel: endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {},
          saveChannel: _saveChannelSelect
        }
      }

      column.render = (text, record) => {
        return <div>{this.getChannelText(record[dataIndex])}</div>
      }
    })

    this.setState({ connectionColumns: _connectionColumns })
  }

  getChannelText = (info) => {
    if (!info || !info.designName) {
      return null;
    }

    return info.designName;
  }

  render() {
    const { pcbConnections, CONNECTION_ID, connections, channelTopologyLoading, endToEndChannelId } = this.props;
    const { connectionColumns } = this.state;
    const dataList = getEndToEndPCBConnections(pcbConnections);
    const data = dataList ? dataList.map((record, index) => ({ ...record, index })) : []
    return (<div className="aurora-setup-border">
      <span className='out-title-color font-bold'>Channel Topology</span>
      <Spin spinning={channelTopologyLoading} size="small" tip="Saving...">
        <Table
          rowKey={record => `${endToEndChannelId}-${record[record.dataIndex]}`}
          columns={connectionColumns}
          size="small"
          dataSource={data}
          className='andes-v2-table end-to-end-channel-connection-table space-10'
          rowClassName={(record, index) => index % 2 !== 0 ? 'editable-row aurora-row-background' : 'editable-row'}
        />
      </Spin>
      <PCBChannelSchematic
        pcbConnections={pcbConnections}
        CONNECTION_ID={CONNECTION_ID}
        connections={connections}
      />

    </div>
    )
  }
}

const mapDispatch = (dispatch) => ({
  _updateEndToEndChannelContent(data) {
    dispatch(updateEndToEndChannelContent(data))
  },
  _saveChannelSelect(record, dataIndex) {
    dispatch(saveChannelSelect(record, dataIndex))
  },
  _delPCBConnection(key) {
    dispatch(delPCBConnection(key))
  },
  _addPCBInConnection(key) {
    dispatch(addPCBInConnection(key))
  }
})

export default connect(null, mapDispatch)(ChannelTopology);