import React, { Component, Fragment } from 'react';
import { Select } from 'antd';
import Panel from '@/components/Panel';
import { resolveDriverModel } from '@/services/Cascade/helper/setupData';
import { createPortal } from 'react-dom';

const Option = Select.Option;
class DriverModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {},
      pinMap: props.pinMap,
      nodes: props.nodes
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    const { model = {}, driverList } = this.props;
    let _model = { ...model };
    if (!driverList.some(item => item.id === model.id)) {
      _model = {};
    }
    this.setState({
      model: _model
    })
  }

  closeModal = () => {
    const { model, pinMap } = this.state;
    const prevModel = this.props.model;
    let _pinMap = [...pinMap]
    if (prevModel.id !== model.id || prevModel.subckt !== model.subckt) {
      _pinMap.forEach(row => {
        row.input_node = "";
        row.output_node = "";
        row.inputGround_node = "";
        row.outputGround_node = "";
      })
    }
    this.props.saveDriver(model, _pinMap)
  }

  changeModel = (id) => {
    const { driverList } = this.props;
    const find = driverList.find(m => m.id === id);
    if (find) {
      this.setState({
        model: { id: find.id, name: find.name, subckt: "" }
      }, async () => {
        const nodes = id ? await resolveDriverModel(id) : [];
        const subckts = Object.keys(nodes);
        const subckt = subckts.length === 1 ? subckts[0] : ""
        this.setState({
          nodes,
          model: {
            ...this.state.model,
            subckt
          }
        })
      })
    }
  }

  changeSubckt = (subckt) => {
    this.setState({
      model: {
        ...this.state.model,
        subckt
      }
    })
  }

  render() {
    const { name, driverList } = this.props;
    const { model, nodes } = this.state;
    const subckts = Object.keys(nodes)
    const content = (
      <Fragment>
        <Panel
          className='library-data-panel'
          position='panel-center-left'
          title={name}
          zIndex={2000}
          onCancel={() => { this.closeModal() }}
          width={400}
          draggable
          minHeight={200}
          maxHeight={800}
        >
          <div className="driver-model-content">
            <div className="driver-model-select-file">
              <span>Model</span>
              <Select
                className='aurora-select'
                showSearch
                value={model.id || ''}
                popupClassName='driver-model-select-drowdown'
                onChange={this.changeModel}
              >
                {driverList.map(item => (
                  <Option key={item.id} title={item.name}>{item.name}</Option>))}
              </Select>
            </div>
            <div className="driver-model-select-file">
              <span>Subckt</span>
              <Select
                className='aurora-select'
                showSearch
                value={model.subckt || ''}
                popupClassName='driver-model-select-drowdown'
                onChange={this.changeSubckt}
              >
                {subckts.map(item => (
                  <Option key={item} title={item}>{item}</Option>))}
              </Select>
            </div>
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default DriverModel
