import { takeEvery, cancel, fork, select, put, call, delay } from "@redux-saga/core/effects";
import dayjs from 'dayjs';
import * as taskStatus from '@/constants/workflowStatus';
import { VERIFY_RUNNING, WAITING } from "@/constants/verificationStatus";
import {
  workflowWaitingIndex,
  getMonitor,
  checkVerificationStatus,
  getVerificationWorkFlow,
  getMonitorList
} from '@/services/workflow/workflow';
import {
  GET_VERIFICATION_WORKFLOW,
  GET_WORKFLOW_MONITOR,
  GET_VERIFICATION_LOG,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_CURRENT_PROFILE,
  RECALCULATE_TDR_SBR_WORKFLOW
} from "./actionTypes";
import {
  updateSimulationInfo,
  updateSimulationReducer,
  getWorkflowMonitor,
  updateMultiSimulationInfo,
  getVerificationLog,
  getCurrentProfile
} from "./action";
import { getWorkFlow, cancelVerificationWorkflow } from "@/services/api/v2/workflowCtrl";
import { fakeProgress } from "@/services/helper/dataProcess";
import monitorLog from '@/services/helper/monitorLog';
import { VERIFY_NEVER } from "@/constants/verificationStatus";
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';
import { getChannelExtractionLog } from '@/services/Andes_v2/extractionCtrl';
import { getEndToEndSimulationLog } from '@/services/Andes_v2/endToEndChannel';
import { END_TO_END_CHANNEL, PCB_CHANNEL } from "@/constants/treeConstants";
import { autoGetChannelList, updateChannelResultExist } from "../channel/action";
import { autoGetEndToEndList, autoGetEndToEndChildrenChannelList, updateEndToEndResultExist } from "../endToEndChannel/action";
import { openTabFooter } from "../../../MonitorStore/action";
import { ANDES_V2 } from "@/constants/pageType";
import { cancelCallWorkflow } from "@/services/Andes_v2";
import channelConstructor from "../../../../services/Andes_v2/channel/channelConstructor";
import { isEndToEndChildrenChannel } from "../../../../services/Andes_v2";
import { isExistResult } from '@/services/Andes_v2/extractionCtrl';
import { EXPERIMENTS } from "../../../../constants/treeConstants";
import { getExperimentExtractionLog } from "../../../../services/Andes_v2/sweep/sweepCtrl";
import { autoGetExperimentData } from "./sweep/saga";

let workflowTask = null;
export function* startNewWorkflow(action) {
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  workflowTask = yield fork(verificationWorkFlow, { ...action });
}

function* verificationWorkFlow(action) {
  //workType -> PCB_CHANNEL / END_TO_END_CHANNEL /EXPERIMENTS
  const { workType, verificationId, channelId, endToEndChannelId, verificationWork, tdrSbrWork } = action;
  //clean prev simulation info
  if (!verificationWork || verificationWork.length === 0) {
    return;
  }

  //get last workflow
  let { id, status, progress, currentTaskIndex, taskList } = verificationWork[verificationWork.length - 1];
  const { AndesV2Reducer } = yield select();
  const { project: { openProjectId } } = AndesV2Reducer;
  let channel = null;
  if (workType === PCB_CHANNEL) {
    channel = channelConstructor.getChannelByVerificationId(verificationId) || {};
  }
  const isEndToEndChildren = isEndToEndChildrenChannel(channel);
  //auto get tree list
  yield call(autoGetTreeList, { openProjectId, channel, workType, isEndToEndChildren, tdrSbrWork, verificationId })

  // [{ worker: "Ansys", taskName: "Ansys3DExtraction", taskId: null }]
  const getProfile = shouldGetProfile(taskList);
  let _simulation = { ...AndesV2Reducer.simulation };
  //update current verification simulation reducer info
  const prevProgress = _simulation[verificationId] ? _simulation[verificationId].progress : -1;
  progress = prevProgress > progress ? prevProgress : progress;
  _simulation[verificationId] = {
    log: null,
    monitor: null,
    monitorList: null,
    logList: null,
    waitingIndex: -1,
    waitingTime: null,
    startMsg: "==> Start simulating...",
    progress
  }
  //update simulation reducer
  yield put(updateSimulationReducer(_simulation));

  //wait index
  if (currentTaskIndex < 2) {
    //get waiting index
    const cancelWaiting = yield call(getWaitingIndex, { verificationId, id, workType, channelId, endToEndChannelId });
    if (cancelWaiting) {
      yield call(updateResultExistStatus, { workType, verificationId, channelId, endToEndChannelId });
      return;
    }
  }

  if (progress === 0) {
    progress += 2;
  }

  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      startMsg: "==> Simulation running...\n==> Data processing...",
      progress
    }
  }));

  //simulation running
  const stopWorkflowPolling = yield call(getSimulationRunning, { status, getProfile, verificationId, id, progress, workType, channelId, endToEndChannelId });

  if (stopWorkflowPolling) {
    yield call(updateResultExistStatus, { workType, verificationId, channelId, endToEndChannelId });
    return;
  }
  //update current verification simulation progress
  yield put(updateSimulationInfo({ verificationId, item: "progress", info: status === taskStatus.SUCCEED ? 100 : -1 }));

  // if (workType !== END_TO_END_CHANNEL) {
  if (![END_TO_END_CHANNEL, EXPERIMENTS].includes(workType)) {
    yield put(getCurrentProfile(verificationId));
  }

  yield put(getWorkflowMonitor(id, verificationId, workType));
  yield call(updateResultExistStatus, { workType, verificationId, channelId, endToEndChannelId });
  yield delay(800);
  //update current verification simulation info
  yield put(updateMultiSimulationInfo({
    verificationId, info: {
      progress: -1,
      startMsg: null,
      endMsg: null
    }
  }));
  //auto get tree list
  yield call(autoGetTreeList, { openProjectId, channel, workType, isEndToEndChildren, tdrSbrWork, verificationId });
  //get monitor
  yield delay(3000);
  yield put(getWorkflowMonitor(id, verificationId, workType));
}

function* getWaitingIndex(action) {
  //id -> workflowId
  const { verificationId, id, workType, channelId, endToEndChannelId } = action;
  //wait index
  let time;
  let waitingIndex = -1;
  waitingIndex = yield call(workflowWaitingIndex, id);
  // while()
  while (waitingIndex >= 0) {
    const stopWorkflowPolling = yield call(cancelWorkflowJudge, { verificationId, workType });
    if (stopWorkflowPolling) {
      return true;
    }
    time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    //update current verification simulation info
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: { waitingIndex, waitingTime }
    }));
    yield delay(3000);
    // update waiting index
    waitingIndex = yield call(workflowWaitingIndex, id);
  }

  if (waitingIndex === -2) {//-2  simulation cancel
    //update current verification simulation info
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        waitingIndex: -1,
        waitingTime: null,
        startMsg: null,
        progress: -1,
        endMsg: null
      }
    }));
    yield call(updateResultExistStatus, { workType, verificationId, channelId, endToEndChannelId });
    yield delay(3000);
    yield put(getWorkflowMonitor(id, verificationId, workType));
    return;
  }

  if (waitingIndex === -3) {//-3 simulation complete

    //update current verification simulation info
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        waitingIndex: -1,
        waitingTime: null,
        startMsg: null,
        endMsg: null,
        progress: -1
      }
    }));
    yield call(updateResultExistStatus, { workType, verificationId, channelId, endToEndChannelId });
    yield delay(3000);
    yield put(getWorkflowMonitor(id, verificationId, workType));
    return;
  }

  //clean current verification waiting info
  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      waitingIndex: null,
      waitingTime: null
    }
  }));
}

function* getSimulationRunning(action) {
  let { status, getProfile, verificationId, id, progress, workType, channelId, endToEndChannelId } = action;
  //simulation running
  let indexNum = 0;
  while (status === taskStatus.RUNNING) {
    const stopWorkflowPolling = yield call(cancelWorkflowJudge, { verificationId, workType });
    if (stopWorkflowPolling) {
      return true;
    }
    //get monitor
    yield put(getWorkflowMonitor(id, verificationId, workType));
    if (workType !== END_TO_END_CHANNEL && getProfile && indexNum % 6 === 0) {
      yield put(getCurrentProfile(verificationId));
    }
    yield delay(5000);
    //get workflow (progress)
    const { data: { data } } = yield call(getWorkFlow, id);
    let _progress = progress;
    if (!data) {
      status = taskStatus.FAILED;
    } else {
      status = data.status;
      _progress = data.progress;
    }

    indexNum += 1;

    progress = fakeProgress(progress, indexNum, _progress);
    if (status === taskStatus.RUNNING) {
      //update current verification simulation progress
      //pproc not progress,  fix: progress  is 100, status is running
      progress = progress >= 99 ? 99 : progress;
      yield put(updateSimulationInfo({ verificationId, item: "progress", info: progress }));
    }
    //result exist
    yield call(updateResultExistStatus, { workType, verificationId, channelId, endToEndChannelId });
  }
}

function* _getWorkflowMonitor(action) {
  const { workflowId, verificationId, workType } = action;
  //end to end monitor
  if (workType === END_TO_END_CHANNEL) {
    try {
      let logList = yield call(getMonitorList, { workflowId, verificationId, product: "andes" });
      //filter logs
      const _logList = logList ? logList.map(item => {
        if (item.logs) {
          item.logs = monitorLog(item.logs, "string");
        }
        return item;
      }) : []
      yield put(updateSimulationInfo({ verificationId, item: "monitorList", info: _logList }));
      return _logList;
    } catch (error) {
      console.error(error);
    }
  } else {
    try {
      let log = [], logType = "";
      // get  monitor
      const channel = channelConstructor.getChannelByVerificationId(verificationId) || {};

      if (isEndToEndChildrenChannel(channel)) {
        const logList = yield call(getMonitorList, { workflowId, verificationId, product: "andes" });
        log = logList && logList[0] ? logList[0].logs : [];
        logType = "string";
      } else {
        log = yield call(getMonitor, workflowId)
      }
      if (log && log.length > 0) {
        yield put(updateSimulationInfo({ verificationId, item: "monitor", info: monitorLog(log, logType) }));
        return log;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function isRunning(workType, channelId, verifyStatus) {
  if (workType === PCB_CHANNEL) {
    const channel = channelConstructor.getChannel(channelId);
    if (isEndToEndChildrenChannel(channel) && ![VERIFY_RUNNING, WAITING].includes(verifyStatus)) {
      return false;
    };
  }
  return true;
}

function* _getVerificationWorkflow(action) {
  const { verificationId, channelId, endToEndChannelId, workType } = action;
  try {
    const promise = yield call(checkVerificationStatus, verificationId);
    const verifyStatus = promise ? promise.status : null;

    //if never run simulation, return
    if (verifyStatus && verifyStatus === VERIFY_NEVER) {
      return;
    }

    const verificationStatus = yield call(getVerificationWorkFlow, verificationId);
    //isRunning(workType, channelId, verifyStatus):
    // -> if workType is pcb channel, and is multi pcb children channel,and verification status is running or waiting
    // -> if workType is not pcb channel
    // -> if workType is pcb channel, and is not multi pcb children channel
    if ((verificationStatus && verificationStatus.length > 0) && isRunning(workType, channelId, verifyStatus)) {
      yield call(startNewWorkflow, {
        workType,
        verificationId,
        channelId,
        endToEndChannelId,
        verificationWork: verificationStatus
      });
      return;
    } else {
      //is exist result
      let resultExist = null;
      try {
        if (channelId || endToEndChannelId) {
          resultExist = yield call(isExistResult, verificationId);
        }
      } catch (error) {
        console.error(error);
      }
      if (channelId) {
        yield put(updateChannelResultExist({ id: channelId, exist: resultExist ? resultExist.exist : false }))
      }
      if (endToEndChannelId) {
        yield put(updateEndToEndResultExist({ id: endToEndChannelId, exist: resultExist ? resultExist.exist : false }))
      }

      yield call(cleanVerificationSimulationInfo, verificationId);
      //get monitor / log
      const { AndesV2Reducer } = yield select();
      let simulation = AndesV2Reducer.simulation;

      if (simulation[verificationId] && simulation[verificationId].monitor && simulation[verificationId].monitor.length > 0) {
        let workflowId = simulation[verificationId].monitor[0].workflowId;
        const log = workflowId ? yield call(_getWorkflowMonitor, { workflowId, verificationId, workType }) : null;
        if (!log || !log.length) {
          // get log
          yield put(getVerificationLog(verificationId, workType));
        }
      } else {
        // GET log
        yield put(getVerificationLog(verificationId, workType));
      }
      if (workType !== END_TO_END_CHANNEL) {
        yield put(getCurrentProfile(verificationId));
      }
    }
  } catch (error) {
    console.error(error);
    yield call(cleanVerificationSimulationInfo, verificationId);
    return;
  }
}

function* cleanVerificationSimulationInfo(verificationId) {
  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      startMsg: null,
      endMsg: null,
      progress: -1,
      waitingIndex: -1,
      waitingTime: null
    }
  }));
}

function* _getLog(action) {
  const { verificationId, workType = PCB_CHANNEL } = action;
  let _log = '';
  const logFn = workType === PCB_CHANNEL ? getChannelExtractionLog : workType === EXPERIMENTS ? getExperimentExtractionLog : getEndToEndSimulationLog;
  try {
    let log = yield call(logFn, verificationId);
    if (log) {
      _log = log;
    }
  } catch (error) {
    console.log(error)
  }

  const infoType = [PCB_CHANNEL, EXPERIMENTS].includes(workType) ? "log" : "logList";
  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      [infoType]: _log,
      monitor: null,
      monitorList: null
    }
  }));
}

function* _cancelWorkflow(action) {
  const { verificationId } = action;
  try {
    yield call(cancelVerificationWorkflow, verificationId);
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: null,
        endMsg: "==> Simulation canceling..."
      }
    }));
    yield delay(5000);
    yield put(updateSimulationInfo({ verificationId, item: "endMsg", info: null }));
  } catch (error) {
    console.log(error);
  }
}

function* _getProfileLog(action) {
  const { verificationId } = action;
  try {
    let profileLog = yield call(getProfileData, verificationId);
    if (!profileLog) {
      profileLog = [];
    }
    yield put(updateSimulationInfo({ verificationId, item: "profileLog", info: parseProfile(profileLog) }));
  } catch (error) {
    console.error(error)
  }
}

export function* checkChannelSimStatus(verificationId) {
  //check verification status
  const promise = yield call(checkVerificationStatus, verificationId);
  if (promise && promise.status && [VERIFY_RUNNING, WAITING].includes(promise.status)) {
    return true;
  }
  return false;
}

function* tdrsbrWorkflow(action) {
  const { workflow, isEndToEnd, workType } = action;
  let _workType = workType;
  if (!_workType) {
    _workType = isEndToEnd ? END_TO_END_CHANNEL : PCB_CHANNEL;
  }
  yield put(openTabFooter());
  yield call(startNewWorkflow, {
    workType: _workType,
    verificationId: workflow.verificationId,
    verificationWork: [workflow],
    tdrSbrWork: true
  });
}

function* cancelWorkflowJudge({ verificationId, workType }) {
  const { LoginReducer: { pageType }, AndesV2Reducer: { project: { selectedKeys } },
    MonitorInfoReducer: { monitorScreenInfo: { currentVerificationId } } } = yield select();

  if (pageType !== ANDES_V2 || cancelCallWorkflow(selectedKeys, verificationId, workType, currentVerificationId)) {
    return true;
  }
}

function* autoGetTreeList(action) {
  const { openProjectId, channel, workType, isEndToEndChildren, tdrSbrWork, verificationId } = action;

  if (tdrSbrWork && channel && isEndToEndChildren) {
    yield put(autoGetEndToEndChildrenChannelList([channel.endToEndId], openProjectId));
    return;
  }

  if (workType === PCB_CHANNEL && !isEndToEndChildren) {
    yield put(autoGetChannelList({ projectId: openProjectId }));
  }

  if (workType === END_TO_END_CHANNEL || isEndToEndChildren) {
    yield put(autoGetEndToEndList({ projectId: openProjectId, runSimulation: true }));
  }

  if (workType === EXPERIMENTS) {
    yield call(autoGetExperimentData, { verificationId })
  }
}

export function* updateResultExistStatus({ workType, verificationId, channelId, endToEndChannelId }) {
  if (workType === EXPERIMENTS) {
    yield call(autoGetExperimentData, { verificationId })
  }
  //is exist result
  let resultExist = null;
  try {
    resultExist = yield call(isExistResult, verificationId);
  } catch (error) {
    console.error(error);
  }
  const { AndesV2Reducer: { project: { viewList },
    channel: { channelId: viewChannelId },
    endToEndChannel: { endToEndChannelId: viewEndToEndChannelId } } } = yield select();

  //if open channel is simulation channel , re get result exist api
  if (workType === PCB_CHANNEL && viewList.includes(PCB_CHANNEL) && viewChannelId === channelId) {
    yield put(updateChannelResultExist({ id: channelId, exist: resultExist ? resultExist.exist : false }))

  } else if (workType === END_TO_END_CHANNEL && viewList.includes(END_TO_END_CHANNEL) && viewEndToEndChannelId === endToEndChannelId) {
    yield put(updateEndToEndResultExist({ id: endToEndChannelId, exist: resultExist ? resultExist.exist : false }))
  }
}

function* simulationSaga() {
  yield takeEvery(GET_WORKFLOW_MONITOR, _getWorkflowMonitor);
  yield takeEvery(GET_VERIFICATION_WORKFLOW, _getVerificationWorkflow);
  yield takeEvery(GET_VERIFICATION_LOG, _getLog);
  yield takeEvery(CANCEL_VERIFICATION_WORKFLOW, _cancelWorkflow);
  yield takeEvery(GET_CURRENT_PROFILE, _getProfileLog);
  yield takeEvery(RECALCULATE_TDR_SBR_WORKFLOW, tdrsbrWorkflow)
}

export default simulationSaga;