import React, { Component, Fragment } from 'react';
import { Select, Button } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { filterPowerNetsByIC } from '../../../../services/Cascade/SignOffTemplate';
import { getAllCascadeComponents } from '../../../../services/Cascade/helper/setupData';
import './index.css';

const Option = Select.Option;
class CreatePowerDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      powerNet: null,
      gnd: props.gnd,
      soc: props.soc,
      nets: [],
      pmicPowerNet: null,
      pmic: null,
      pmicNets: [],
      filterNets: [],
      pmicOutNet: "",
      pmicOutNets: []
    }
    this.dialogRoot = document.getElementById('root');

  }

  filterPowerNets = (nets, signOffTemplate) => {
    const existNets = signOffTemplate.filter(it => it.compType !== "PMIC").map(item => item.powerNetName).filter(item => !!item);
    return nets.filter(item => !existNets.includes(item));
  }

  pmicPowerNets = (nets, signOffTemplate) => {
    const existNets = signOffTemplate.filter(it => it.compType !== "PMIC").map(item => item.powerNetName).filter(item => !!item);
    return [...new Set(existNets.filter(item => nets.includes(item)))];
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 950),
      maxHeight: getPanelMaxHeight(offset, 400)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { signOffTemplate, nets, designId, isAddPMIC } = this.props;
    const { soc, gnd } = this.state;
    const _nets = isAddPMIC ? nets : this.filterPowerNets(nets, signOffTemplate);
    let filterNets = [..._nets];
    if (soc) {
      filterNets = filterPowerNetsByIC(designId, soc, _nets);
    }
    const allComponents = getAllCascadeComponents({ pcbId: designId });
    const components = [...allComponents.values()].filter(comp => comp.pins && comp.pins.size > 1 ? gnd ? [...comp.pins.values()].some(pin => pin.net === gnd) : true : false).map(item => item.name).sort();
    this.setState({
      nets: _nets,
      pmicNets: this.pmicPowerNets(nets, signOffTemplate),
      filterNets,
      components
    })
  }

  selectChange = (key, type) => {

    const { signOffTemplate, designId } = this.props;
    if (type === "pmicPowerNet") {

      const findTemp = signOffTemplate.find(item => item.powerNetName === key);
      this.setState({
        soc: findTemp ? findTemp.soc : null,
        pmicOutNets: [key, ...this.state.pmicOutNets || []]
      })
    }

    if (type === "soc") {
      let { nets, filterNets, powerNet } = this.state;
      filterNets = filterPowerNetsByIC(designId, key, nets);
      //update power nets
      if (!filterNets.includes(powerNet)) {
        powerNet = null;
      }
      this.setState({
        filterNets,
        powerNet
      })
    }

    if (type === "pmic") {
      let { nets, pmicOutNet, pmicPowerNet } = this.state;
      const pmicOutNets = filterPowerNetsByIC(designId, key, nets);
      //update power nets
      if (!pmicOutNets.includes(pmicOutNet) && pmicPowerNet !== pmicOutNet) {
        pmicOutNet = null;
      }
      this.setState({
        pmicOutNets: pmicPowerNet ? [...new Set([pmicPowerNet, ...pmicOutNets])] : pmicOutNets,
        pmicOutNet
      })
    }
    this.setState({
      [type]: key
    })
  }

  closeModal = () => {
    this.props.closeModal();
  }

  clickCreate = () => {
    const { powerNet, gnd, soc, pmic, pmicPowerNet, pmicOutNet } = this.state;
    const { isAddPMIC } = this.props;
    this.props.createPower({ powerNet, gnd, soc, pmic, pmicPowerNet, isAddPMIC, pmicOutNet });
  }

  powerDomainRender = () => {
    const { powerNet, nets, gnd, soc, filterNets } = this.state;
    const { socList } = this.props;
    return <Fragment>
      {this.selectionComponent({
        title: "TargetIC",
        value: soc,
        type: "soc",
        options: socList ? socList.map(item => item.name) : []
      })}
      {this.selectionComponent({
        title: "Power Net",
        value: powerNet,
        type: "powerNet",
        options: filterNets
      })}
      {this.selectionComponent({
        title: "Gnd Net",
        value: gnd,
        type: "gnd",
        options: nets
      })}
    </Fragment>
  }

  pmicRender = () => {
    const { pmicPowerNet, pmic, pmicNets, pmicOutNet, pmicOutNets, components } = this.state;
    return <Fragment>
      {this.selectionComponent({
        title: "Power Net",
        value: pmicPowerNet,
        type: "pmicPowerNet",
        options: pmicNets
      })}
      {/*  {this.selectionComponent({
        title: "Gnd Net",
        value: gnd,
        type: "gnd",
        options: allPowerNets
      })} */}
      {this.selectionComponent({
        title: "PMIC",
        value: pmic,
        type: "pmic",
        options: components
      })}
      {this.selectionComponent({
        title: "PMIC Output Net",
        value: pmicOutNet,
        type: "pmicOutNet",
        options: pmicOutNets
      })}
    </Fragment>
  }

  render() {
    const { maxWidth, maxHeight } = this.state;
    const setting = { defaultWidth: 700 };
    const content = (
      <Panel
        className='cascade-top-template-create-panel'
        title={<div className='cascade-top-template-create-panel-name'>{this.props.isAddPMIC ? "Assign PMIC To Power Domain" : "Create Power Domain"}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="200"
      >
        <div className='cascade-top-template-create-panel-main'>
          {this.props.isAddPMIC ?
            this.pmicRender()
            : this.powerDomainRender()}
          <div className='template-button-item'>
            <Button
              onClick={() => this.clickCreate()}
              size="small"
            >Create</Button>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  selectionComponent = ({ title, value, type, options }) => {
    return <div className='template-create-item'>
      <span>{title}</span>
      <Select
        placeholder={title}
        value={value}
        showSearch
        onSelect={(key) => this.selectChange(key, type)}
        className="aurora-select"
        popupClassName='aurora-select-dropdown'
        popupMatchSelectWidth={false}
        getPopupContainer={() => document.getElementById('root')}
      >
        {(options || []).map(item => <Option
          key={item}
          value={item}
          title={item}
        >{item}</Option>)}
      </Select>
    </div>
  }
}

export default CreatePowerDomain;