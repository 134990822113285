import React, { Component } from "react";
import { Select, Tooltip, Input } from 'antd';
import { CustomLibraryListRender } from "./customLIbraryListRender";
import _ from 'lodash';

const Option = Select.Option;

export default class CustomLibrary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      model: JSON.parse(JSON.stringify(props.model))
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.model, prevProps.model)) {
      this.setState({
        model: JSON.parse(JSON.stringify(this.props.model))
      })
    }
  }

  systemLibraryTypeChange = (key) => {
    this.props.systemLibraryTypeChange(key)
    this.setState({
      model: {}
    })
  }

  changeModel = (model) => {
    if (!model) {
      return;
    }
    this.setState({
      model: {
        id: model.id,
        name: model.name,
        type: 'Custom',
        subcktName: '',
        libraryType: model.type,
        path: model.path
      }
    }, () => {
      setTimeout(() => {
        this.props.modelChange(this.state.model)
      }, 10)
    })
  }

  /*   searchModel = (value) => {
      this.setState({
        searchValue: value
      })
    } */

  render() {
    const { customModelList, nameWidth, manufacturerWidth, getCustomLibraryPath } = this.props;
    const { model } = this.state;
    return (
      <div>
        <div className="decap-select-model-content">
          <div className="select-model-content-row">
            <div className="select-model-content-cal select-model-content-cal-custom">
              <span className="type-name custom-span">Type</span>
              <Select size="small"
                value={"Custom"}
                onChange={this.systemLibraryTypeChange}
                popupClassName="select-model-select-dropdown">
                <Option value="vendor">Vendor</Option>
                <Option value="generic">Generic</Option>
                <Option value="Custom">Custom</Option>
              </Select>
            </div>
          </div>
          <div className="select-model-content-row">
            <div className="select-model-content-cal select-model-content-cal-custom">
              <span className="type-name custom-span">ManufacturerPartNumber</span>
              <Tooltip
                placement="left"
                overlayClassName='aurora-tooltip system-library-tooltip'
                title={model ? getCustomLibraryPath(model.path) || model.name : undefined}
              >
                <Input
                  size="small"
                  placeholder="ManufacturerPartNumber"
                  value={model.name}
                  key={model.name}
                  className='select-model-input-component'
                  disabled={true}
                  /* onChange={value => { this.searchModel(value) }} */ />
              </Tooltip>
              {/*  <Tooltip
                placement="left"
                overlayClassName='aurora-tooltip system-library-tooltip'
                title={model ? getCustomLibraryPath(model.path) || model.name : undefined}
              >
                <Select
                  size="small"
                  onChange={this.changeModel}
                  value={model ? model.id : undefined}
                  placeholder='Model File'
                  className='select-model-selection-component'
                  dropdownStyle={{ zIndex: 1000000 }}
                >
                  {customModelList.map(item =>
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      title={model ? getCustomLibraryPath(model.path) || model.name : undefined}
                    >{item.name}</Select.Option>)}
                </Select>
              </Tooltip> */}
            </div>
          </div>
          {CustomLibraryListRender({
            customLibraryList: customModelList,
            nameWidth,
            manufacturerWidth,
            selectLibrary: this.changeModel,
            selectedModel: { ...model }
          })}
        </div>
      </div>
    )
  }
}