import http from "../http";

/** 
 * Get pre layout content
 * 2024.01.10
*/
export function getCascadePreLayoutInfoById(id) {
  return http.get(`/my-project/my-design/cascade/pre-layout/detail?id=${id}`);
}

/** 
 * update pre layout content
 * 2024.01.10
*/
export function updateCascadePreLayout(data) {
  return http.post(`/my-project/my-design/cascade/pre-layout`, { ...data });
}