import { NetPins, Component } from './config';
import { saveConfig, getInterfacesList, getInterfaceContent, deleteInterface, generateReport } from '../api/serdes';
import { uploadLibrary } from '../api/library';
import { SUCCESS } from '../../constants/returnCode';
import checkError from '../api/checkError';
import LayoutData from '@/services/CeLayoutDB/newCeLayoutData';

const layout = LayoutData.getLayout();
let CompsInfo = null;

export function cleanSerdesCompsInfo() {
  CompsInfo = null;
}

export function getNetPin(netList) {
  let arr = [];
  const _netList = [...new Set([...netList])];
  _netList.forEach(netName => {
    const pinList = getPinList(netName);
    let netPins = new NetPins(netName);
    netPins.addPins(pinList);
    arr.push(netPins);
  });
  return arr;
}

export function getComponents(netList) {
  if (!CompsInfo) {
    CompsInfo = getLayoutComponents();
  }
  let ComponentList = [];
  const CompList = getCompsByNets(netList);
  ComponentList.push(...CompList.map(comp => {
    const { type, part, value } = CompsInfo[comp];
    return new Component(comp, { type, part, value });
  }));
  return ComponentList;
}

export function getNetsByComp(compName, netPins) {
  let nets = [];
  for (let net of netPins) {
    const compNames = net.pins.map(item => item.component_name);
    if (compNames.indexOf(compName) > -1) {
      nets.push(net.net_name);
    }
  }
  return nets;
}

export function getNetsBySignalName(signalName, signals) {
  const index = signals.map(signal => signal.signal_name).indexOf(signalName);
  const nets = signals[index].nets;
  return nets;
}

export function uploadConfig({ projectId, config, name, type, project, designName }) {
  return saveConfig({ projectId, config, name, type, designName, project }).then(res => {
    return res;
  }, error => {
    console.log(error);
    return error;
  });
}

export function getSerdesInterfacesList({ projectId, designName }) {
  return getInterfacesList({ projectId, designName }).then(res => {
    return res;
  }, error => {
    console.log(error);
    return error;
  })
}

export function getSerdesInterfaces(data) {
  // type = PCIe
  return data.map(item => {
    return {
      ...item,
      type: 'PCIe'
    }
  })
}

export function getInterfaceInfo({ projectId, interfaceName, designName, type = 'PCIe' }) {
  return getInterfaceContent({ projectId, interfaceName, designName, type }).then(res => {
    return res;
  }, error => {
    console.error(error);
    return error;
  })
}

// '0' for 'TX' and '1' for 'RX'
export function getSignalDirection(signalName) {
  let rtx = signalName.split('_')[1];
  const RTX = rtx ? rtx.toUpperCase() : null;
  let dir = null;
  if (RTX === 'TX') {
    dir = '0';
  } else if (RTX === 'RX') {
    dir = '1';
  }
  return dir;
}


export function checkExistNets(nets, netNamelist) {
  if (netNamelist.length > 0) {
    const checkNets = nets.filter(net => netNamelist.includes(net));
    return checkNets;
  }
  return false;
}

export function getNetNamesByNetPins(NetPins) {
  return NetPins.map(net => net.net_name);
}

export function deleteSerdesInterface({ designName, name, type = 'PCIe', projectId }) {
  return deleteInterface({ projectId, designName, name, type }).then(res => {
    return res;
  }, error => {
    console.error(error);
    return error;
  })
}

export function uploadPKGModel({ projectId, files }) {
  return uploadLibrary({ projectId, files }).then(res => {
    return res;
  }, error => {
    console.error(error);
    return error;
  })
}


export function generateSerdesReport({ projectId, interfaceName, type }) {
  return new Promise((resolve, reject) => {
    generateReport({ projectId, interfaceName, type }).then(res => {
      if (res.data.code === SUCCESS) {
        // workflow {id,status,progress}
        resolve(res.data.data);
      } else {
        resolve(false);
      }
    }, error => {
      console.log(error)
      resolve(false);
    })
  })
}

export const Type = ['PCIe'];

export const Version = ['2.x', '3.x', '4.x'];
export const PerLaneSpeed = {
  /*   '1.x': '2.000 Gbps', */
  '2.x': ['2.5 Gbps', '5.0 Gbps'],
  '3.x': ['2.5 Gbps', '5.0 Gbps', '8.0 Gbps'],
  '4.x': ['2.5 Gbps', '5.0 Gbps', '8.0 Gbps', '16.0 Gbps']
};
export const Lanes = ['1', '2', '4', '8', '12', '16', '32'];

function getPinList(netName) {
  const netObj = layout.mNetManager.GetNetFromName(netName);
  // netObj.mPinList - mLayerName, mCompName, mPinNum, mMetalLayerName
  return netObj.mPinList;
}

function getCompsByNets(netList) {
  let comps = [];
  const _netList = [...new Set([...netList])];
  _netList.forEach(netName => {
    const pinList = getPinList(netName);
    pinList.forEach(pin => {
      if (comps.indexOf(pin.mCompName) < 0) {
        comps.push(pin.mCompName)
      }
    });
  });
  return comps;
}

function getLayoutComponents() {
  const layerMgr = layout.GetLayerManager();
  let compList = [];
  let layoutCompList = {};
  layerMgr.mMetalLayers.forEach(item => {
    if (item.mComponentLayer !== null) {
      compList.push(item.mComponentLayer);
    }
  });

  compList.forEach(layer => {
    let comps = layer.mComponents;
    if (comps) {
      for (let component of comps) {
        layoutCompList[component.mName] = { comp: component };
      }
    }
  });

  //update the component information
  for (var compName in layoutCompList) {
    var compInfo = layoutCompList[compName];

    compInfo.name = compName;
    compInfo.part = compInfo.comp.mPart.mInfo.mPartName;
    compInfo.value = compInfo.comp.mPart.mInfo.mPhyProps.getValue('value');

    if (compInfo.comp.mPart.mInfo.mPartType &&
      compInfo.comp.mPart.mInfo.mPartType.length > 0) {
      compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      var type = compInfo.type.toUpperCase();
      if (type == 'R' || type == 'RES' || type == 'RESISTOR') {
        compInfo.type = 'Res';
      }
      else if (type == 'C' || type == 'CAP' || type == 'CAPACITOR') {
        compInfo.type = 'Cap';
      }
      else if (type == 'L' || type == 'IND' || type == 'INDUCTOR') {
        compInfo.type = 'Ind';
      }
      else {
        compInfo.type = '';
        // compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      }
    }
    else {
      switch (compName.toLowerCase()[0]) {
        case "c":
          compInfo.type = "Cap";
          break;
        case "r":
          compInfo.type = "Res";
          // for resistors, check the number of pins
          if (compInfo.comp.mPart.mPinList.length > 2) {
            compInfo.type = "RCA";
          }
          break;
        case "l":
          compInfo.type = "Ind";
          break;
        default:
          compInfo.type = null;
          break;
      }
    }
    delete compInfo.comp;
  }
  return layoutCompList;
}

// This class is used to PCIe in the verification page.
class Serdes {
  constructor() {
    this.projectId = window.location.search.split('=')[1];
    this.interfacesInfo = {}; // {designName:[interfaceInfoList]}
  }

  getInterfaceInfo = ({ interfaceName, designName, type = 'PCIe' }) => {
    return new Promise((resolve, reject) => {
      const index = this.interfacesInfo[designName] ? this.interfacesInfo[designName].map(item => item.Name).indexOf(interfaceName) : -1;
      if (index > -1) {
        resolve(this.interfacesInfo[designName][index]);
      } else {
        getInterfaceContent({ projectId: this.projectId, interfaceName, designName, type }).then(res => {
          if (res.data.code === SUCCESS) {
            const interfaceInfo = res.data.data;
            if (this.interfacesInfo[designName]) {
              this.interfacesInfo[designName].push(interfaceInfo);
            } else {
              this.interfacesInfo[designName] = [interfaceInfo];
            }
            resolve(interfaceInfo);
          } else {
            resolve(null);
          }
        }, error => {
          checkError(error);
          resolve(null);
        })
      }
    })
  }

  cleanSerdesInterfaceInfo(designName, interfaceName) {
    const index = this.interfacesInfo[designName] ? this.interfacesInfo[designName].map(item => item.Name).indexOf(interfaceName) : -1;
    if (index > -1) {
      this.interfacesInfo[designName].splice(index, 1);
    }
  }
}

export const serdes = new Serdes();

export const USAGE = ['Controller', 'Device', 'Connector', 'Res', 'Cap', 'Ind'];
const SORT_USAGE = ['NotSet', 'Controller', 'Device', 'Connector', 'Res', 'Cap', 'Ind'];

export function SortComponent(components) {
  let arr = [];
  SORT_USAGE.forEach(usage => arr[usage] = []);
  components.forEach(comp => arr[comp.type] ? arr[comp.type].push(comp) : arr['NotSet'].push(comp));
  let newComponent = [];
  SORT_USAGE.forEach(item => newComponent.push(...arr[item]));
  return newComponent;
}

const SPEED = [2.5, 5.0, 8.0, 16.0];
const EYE_WIDTH = {
  '2.x': [0.4, 0.4],
  '3.x': [0.4, 0.4, 0.35],
  '4.x': [0.4, 0.32, 0.3, 0.3]
}

const EYE_HEIGHT = {
  '2.x': [175, 120],
  '3.x': [175, 120, 50],
  '4.x': [175, 100, 25, 15]
}

export function getEyeParam(version, dataRate) {
  let eyeParam = { width: null, height: null }
  const index = SPEED.findIndex(speed => parseFloat(dataRate) == speed);
  const WIDTH = EYE_WIDTH[version];
  const HEIGHT = EYE_HEIGHT[version];
  if (index > -1) {
    const width = WIDTH[index] || null;
    const height = HEIGHT[index] || null;
    eyeParam.width = width;
    eyeParam.height = height;
  }
  return eyeParam;
}