const PROJECT_TYPE = 'ROCKY';
export const UPLOAD_PCB_START_UPDATE_STATUS = `${PROJECT_TYPE}/upload_pcb_start_update_status`;
export const UPDATE_UPLOAD_PROGRESS = `${PROJECT_TYPE}/update_upload_progress`;
export const UPDATE_UPLOAD_PCB_MSG = `${PROJECT_TYPE}/update_upload_pcb_msg`;
export const TRANSLATION_FLOW = `${PROJECT_TYPE}/translation_flow`;
export const CLEAN_UPLOAD_PCB_STATUS = `${PROJECT_TYPE}/clean_upload_pcb_status`;
export const UPDATE_UPLOAD_WORKFLOW = `${PROJECT_TYPE}/update_upload_workflow`;
export const UPDATE_TRANSLATION_MONITOR = `${PROJECT_TYPE}/update_translation_monitor`;
export const UPDATE_TRANSLATION_DEBUG = `${PROJECT_TYPE}/update_translation_debug`;
export const UPDATE_TRANSLATION_PROGRESS = `${PROJECT_TYPE}/update_translation_progress`;
export const UPDATE_UPLOAD_END_MSG = `${PROJECT_TYPE}/update_upload_end_msg`;
export const CANCEL_UPLOAD_WORKFLOW = `${PROJECT_TYPE}/cancel_upload_workflow`;
export const SAVE_CURRENT_PCB_KEY = `${PROJECT_TYPE}/save_current_pcb_key`;
export const UPDATE_COMPRESS_PROGRESS = `${PROJECT_TYPE}/update_compress_progress`;
export const GET_TRANSLATION_MONITOR = `${PROJECT_TYPE}/get_translation_monitor`;