const PROJECT_TYPE = 'ANDES';
export const CHANGE_VIEW = `${PROJECT_TYPE}/change_view`;
export const SAVE_OPEN_PROJECT_INFO = `${PROJECT_TYPE}/save_open_project_info`;
export const UPDATE_PROJECT_MENU = `${PROJECT_TYPE}/update_project_menu`;
export const OPEN_PROJECT = `${PROJECT_TYPE}/open_project`;
export const PROJECT_MENU = `${PROJECT_TYPE}/project_menu`;
export const CHANGE_LAYOUT = `${PROJECT_TYPE}/change_layout`;
export const CLEAR_CURRENT_PROJECT = `${PROJECT_TYPE}/clear_current_project`;
export const UPDATE_TRASH_MENU = `${PROJECT_TYPE}/update_trash_menu`;
export const TRASH_MENU = `${PROJECT_TYPE}/trash_menu`;
export const CHANGE_VIEW_LIST = `${PROJECT_TYPE}/change_view_list`;
export const SAVE_COMPONENTS_NETS_IN_PROJECT = `${PROJECT_TYPE}/save_components_nets_in_project`;
export const UPDATE_PCBS_LAYOUTDB = `${PROJECT_TYPE}/update_pcbs_layout`;
export const CHANGE_TREE_SELECTED_KEY = `${PROJECT_TYPE}/change_tree_selected_key`;
export const SHOW_RESULT = `${PROJECT_TYPE}/show_result`;
export const UPDATE_VIEW_STATUS = `${PROJECT_TYPE}/update_view_status`;
export const AUTO_GET_VERIFICATION_LIST = `${PROJECT_TYPE}/auto_get_verification_list`;
export const REFRESH_PCB = `${PROJECT_TYPE}/refresh_pcb`;
export const UPDATE_CURRENT_VERIFICATION_STATUS = `${PROJECT_TYPE}/update_current_verification_status`;
