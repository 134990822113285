import { getDefaultName } from "../../../helper/setDefaultName";
import { CONNECTOR, IC, RES } from "../../../PCBHelper";
import { GENERIC } from "../../../PCBHelper/constants";
import { I2CI3C } from "../prelayoutConstants";
import defaultColor from "../../../../constants/defaultColors";
import { splitValueUnitByUnits } from "../../../helper/numberHelper";
import { TRACE_LENGTH_UNITS } from "./constants";

function getNewComponentName(type, components) {
  const compNames = components.filter(item => item.type === type).map(item => item.name);
  let name = getCompPrefix(type);
  name = getDefaultName({ nameList: compNames, defaultKey: name, key: "" })
  return name;
}

function getDefaultSignalNames(GPIO, signalNumber) {
  switch (GPIO) {
    case I2CI3C:
      return ["SCL", "SDA"];
    case GENERIC:
    default:
      if (signalNumber > 0) {
        let names = []
        for (let i = 1; i <= signalNumber; i++) {
          names.push(`Signal${i}`)
        }
        return names;
      }
      else {
        return ["Signal1"]
      }
  }
}

function getCompPrefix(type) {
  switch (type) {
    case IC:
      return 'U';
    case CONNECTOR:
      return 'J';
    case RES:
      return 'R';
    default:
      return '';
  }
}

function updateSignalsAndCompsByGPIO({ signals, components, signalNumber, GPIO, differentialSections }) {
  let _signals = [], _components = [...components || []], _differentialSections = [...differentialSections || []];
  const names = getDefaultSignalNames(GPIO, signalNumber);

  const findFirstSignal = signals.find(item => item.points && item.points.length) || {};

  const filterSignals = signals.filter(item => item.name !== findFirstSignal.name);

  let newSignals = [];
  for (let i = 0; i < names.length; i++) {
    let prevName = null;
    if (i === 0) {
      prevName = findFirstSignal.name;
      const newSignal = updateSectionAndPointsPin(prevName, names[0], findFirstSignal);
      newSignal.nets = [names[0]];
      _signals.push({ ...newSignal, name: names[0] });
    } else if (filterSignals[i - 1]) {
      prevName = filterSignals[i - 1].name;
      const newSignal = updateSectionAndPointsPin(prevName, names[i], filterSignals[i - 1]);
      newSignal.nets = [names[i]];
      _signals.push({ ...newSignal, name: names[i] });
    }

    if (i !== 0 && !filterSignals[i - 1]) {
      newSignals.push(names[i]);
    } else {
      _differentialSections = updateDifferentialSectionsSignal(prevName, names[i], _differentialSections)
      _components = updateComponentsKey(prevName, names[i], _components);
    }
  }
  for (let comp of _components) {
    comp.pins = comp.pins.filter(item => names.includes(item.signal));
  }
  _components = _components.filter(item => !!item.pins.length);

  _differentialSections.forEach(item => {
    item = item.filter(it => names.includes(it.signal));
  })
  _differentialSections = _differentialSections.filter(item => !!item.length);


  if (newSignals.length) {
    const info = addNewSignals({ _signals, _components, newSignals, _differentialSections });
    _signals = info._signals;
    _components = info._components;
  }
  return { signals: _signals, components: _components, differentialSections: _differentialSections }
}

function addNewSignals({ _signals, _components, newSignals, _differentialSections }) {
  const resComps = _components.filter(item => item.type === RES).map(item => item.name);
  const findNewFirstSignal = _signals.find(item => item.points && item.points.length) || {};

  const allPointExistKeys = getAllSignalPointNames(_signals);

  for (let signalName of newSignals) {
    let newSignal = {
      name: signalName,
      nets: [signalName],
      color: getSignalColor(_signals.map(signal => signal.color)),
      sections: [],
      points: []
    }
    let pointName = getPointName(allPointExistKeys);
    allPointExistKeys.push(pointName);
    newSignal.sections = JSON.parse(JSON.stringify(findNewFirstSignal.sections || []));

    for (let secItem of newSignal.sections) {
      if (secItem.in && secItem.in.type === "component") {
        secItem.in.pin = signalName;
        let comp = secItem.in.component;
        const pin = { pin: signalName, net: signalName, signal: signalName, location: [] };
        const compIndex = _components.findIndex(it => it.name === secItem.in.component);

        if (resComps.includes(secItem.in.component)) {
          comp = getDefaultName({ nameList: resComps, defaultKey: 'R', key: "", firstIndex: 1 });
          _components.push({
            name: comp,
            location: [],
            width: 2,
            height: 2,
            pins: [pin],
            type: RES,
            value: _components[compIndex] ? _components[compIndex].value : "",
            voltage: _components[compIndex] ? _components[compIndex].voltage : ""
          });
          resComps.push(comp);
          secItem.in.component = comp;
        } else if (compIndex > -1) {
          _components[compIndex].pins.push(pin)
        }
      }
      if (secItem.out && secItem.out.type === "component") {
        secItem.out.pin = signalName;
        let comp = secItem.out.component;
        const pin = { pin: signalName, net: signalName, signal: signalName, location: [] };
        const compIndex = _components.findIndex(it => it.name === secItem.out.component);

        if (resComps.includes(secItem.out.component)) {
          comp = getDefaultName({ nameList: resComps, defaultKey: 'R', key: "", firstIndex: 1 });
          _components.push({
            name: comp,
            location: [],
            width: 2,
            height: 2,
            pins: [pin],
            type: RES,
            value: _components[compIndex] ? _components[compIndex].value : "",
            voltage: _components[compIndex] ? _components[compIndex].voltage : ""
          });
          resComps.push(comp);
          secItem.out.component = comp;
        } else if (compIndex > -1) {
          _components[compIndex].pins.push(pin)
        }
      }
      if (secItem.in && secItem.in.type === "point") {
        const prevName = secItem.in.component.match(/[0-9]+/g);
        const num = prevName ? prevName[0] : "1"
        secItem.in.component = `${pointName}${num}`;
        secItem.in.pin = `${pointName}${num}`;
      }
      if (secItem.out && secItem.out.type === "point") {
        const prevName = secItem.out.component.match(/[0-9]+/g);
        const num = prevName ? prevName[0] : "1"
        secItem.out.component = `${pointName}${num}`;
        secItem.out.pin = `${pointName}${num}`;
      }

      for (let secList of _differentialSections) {
        if (secList.find(it => it.sectionId === secItem.id)) {
          secList.push({ signal: signalName, sectionId: secItem.id });
        }
      }
    }

    _signals.push(newSignal);
  }

  return { _signals, _components, _differentialSections }
}

function updateSignalsAndCompsBySignalNum({ signals, components, signalNumber, differentialSections, GPIO }) {
  let _signals = [...signals], _components = [...components], _differentialSections = [...differentialSections];

  if (signalNumber < signals.length) {
    const findFirstSignal = _signals.find(item => item.points && item.points.length) || {};
    const filterSignals = _signals.filter(item => item.name !== findFirstSignal.name);
    let delSignals = []
    _signals = [findFirstSignal, ...filterSignals.filter((item, index) => {
      if (index >= signalNumber - 1) {
        delSignals.push(item.name);
      }
      return index < signalNumber - 1
    })];

    if (delSignals.length) {
      for (let comp of _components) {
        comp.pins = comp.pins.filter(item => !delSignals.includes(item.signal));
      }
      _components = _components.filter(item => !!item.pins.length);

      _differentialSections.forEach(item => {
        item = item.filter(it => !delSignals.includes(it.signal));
      })
      _differentialSections = _differentialSections.filter(item => !!item.length);
    }
  } else if (signalNumber > signals.length && signals.length) {
    //ONLY Generic
    /*  const defaultSignals = getDefaultSignalNames(GPIO, signalNumber); */

    const number = signalNumber - signals.length;
    const existSignals = _signals.map(item => item.name);
    const newSignals = [];
    for (let i = 0; i < number; i++) {
      let index = i + existSignals.length;
      const name = getDefaultName({ nameList: existSignals, defaultKey: 'Signal', key: "", firstIndex: index })
      existSignals.push(name);
      newSignals.push(name);
    }
    const info = addNewSignals({ _signals, _components, newSignals, _differentialSections });
    _signals = info._signals;
    _components = info._components;
    _differentialSections = info._differentialSections;
  }
  return { signals: _signals, components: _components, differentialSections: _differentialSections }
}

function getAllSignalPointNames(signals) {
  let allPointExistKeys = [];

  for (let signal of signals) {
    for (let it of (signal.sections || [])) {
      if (it.in && it.in.type === "point") {
        allPointExistKeys.push(it.in.component.replace(/[0-9]+/ig, ""))
        break;
      } else if (it.out && it.out.type === "point") {
        allPointExistKeys.push(it.out.component.replace(/[0-9]+/ig, ""))
        break;
      }
    }
  }
  allPointExistKeys = [...new Set(allPointExistKeys)];

  return allPointExistKeys;
}

function getPointName(existKeys) {
  let key = 'A';
  function isExist(key) {
    return !existKeys.includes(key);
  }

  while (!isExist(key)) {
    key = String.fromCharCode(key.charCodeAt(0) + 1);
  }

  return key;
}

function updateSectionAndPointsPin(prevPin, newPin, signal) {

  if (signal.points) {
    signal.points.forEach(item => {
      item.points.forEach(poiItem => {
        if (poiItem.type === "component" && poiItem.pin === prevPin) {
          poiItem.pin = newPin;
        }
      })
    })
  }

  if (signal.sections) {
    signal.sections.forEach(item => {
      if (item.in && item.in.type === "component" && item.in.pin === prevPin) {
        item.in.pin = newPin;
      }
      if (item.out && item.out.type === "component" && item.out.pin === prevPin) {
        item.out.pin = newPin;
      }
    })
  }
  return signal;
}

function updateComponentsKey(prevSignal, newSignal, components) {
  for (let comp of components) {
    comp.pins.forEach(pin => {
      if (pin.signal === prevSignal) {
        pin.pin = newSignal;
        pin.signal = newSignal;
        pin.net = newSignal;
      }
    })
  }
  return components;
}

function updateDifferentialSectionsSignal(prevSignal, newSignal, differentialSections) {
  for (let item of differentialSections) {
    (item || []).forEach(secItem => {
      if (secItem.signal === prevSignal) {
        secItem.signal = newSignal;
      }
    })
  }
  return differentialSections;
}

function getSignalColor(existColors) {
  let defaultIndex = existColors.length > defaultColor.length ? 0 : existColors.length;
  let color = defaultColor[defaultIndex], index = defaultIndex;

  while (existColors.includes(color)) {
    index += 1;
    if (index > defaultColor.length) {
      color = defaultColor[existColors.length];
      break;
    }
    color = defaultColor[defaultIndex];
  }
  return color;
}

function getPreLayoutSignalsSections(signals, returnInfo = false) {
  let sections = {};

  const findFirstSignal = signals.find(item => item.points && item.points.length) || {};

  for (let secItem of findFirstSignal.sections || []) {
    if (!secItem.in || !secItem.in.component || !secItem.out || !secItem.out.component) {
      continue;
    }
    sections[secItem.id] = {
      titleIn: secItem.in.component,
      titleOut: secItem.out.component,
      in: [secItem.in.component],
      out: [secItem.out.component],
      traceLength: secItem.traceLength || "",
      template: secItem.template,
      inType: secItem.in.type,
      outType: secItem.out.type,
    };
  }

  for (let signal of signals || []) {
    if (findFirstSignal && signal.name === findFirstSignal.name) {
      continue;
    }
    for (let secItem of signal.sections) {
      if (!secItem.in || !secItem.in.component || !secItem.out || !secItem.out.component) {
        continue;
      }
      if (!sections[secItem.id]) {
        sections[secItem.id] = {
          titleIn: secItem.in.component,
          titleOut: secItem.out.component,
          in: [secItem.in.component],
          out: [secItem.out.component],
          traceLength: secItem.traceLength || "",
          template: secItem.template,
          inType: secItem.in.type,
          outType: secItem.out.type,
        };
      } else {
        if (!sections[secItem.id].in.includes(secItem.in.component) && secItem.in.type === "component") {
          sections[secItem.id].titleIn = `${sections[secItem.id].titleIn}, ${secItem.in.component}`;
          sections[secItem.id].in.push(secItem.in.component);
        }
        if (!sections[secItem.id].out.includes(secItem.out.component) && secItem.out.type === "component") {
          sections[secItem.id].titleOut = `${sections[secItem.id].titleOut}, ${secItem.out.component}`;
          sections[secItem.id].out.push(secItem.out.component);
        }
      }
    }
  }

  return Object.keys(sections).map(id => {
    const title = `${sections[id].titleIn} --> ${sections[id].titleOut}`;
    if (returnInfo) {
      const { value, unit } = splitValueUnitByUnits(sections[id].traceLength || "", TRACE_LENGTH_UNITS);
      return {
        id,
        title,
        traceLength: sections[id].traceLength || "",
        traceLengthValue: value,
        traceLengthInput: value,
        traceLengthUnit: unit || TRACE_LENGTH_UNITS[0],
        template: sections[id].template,
        in: sections[id].in || [],
        out: sections[id].out || [],
        inType: sections[id].inType,
        outType: sections[id].outType
      }
    }
    return {
      id,
      title,
      in: sections[id].in || [],
      out: sections[id].out || [],
      inType: sections[id].inType,
      outType: sections[id].outType
    }
  });
}

function getSchematicPreLayoutPowerNets(signals, components, interComps, prevPowerNets) {
  const sections = getPreLayoutSignalsSections(signals);
  const resComps = components.filter(item => item.type === RES);
  const resCompNames = resComps.map(item => item.name);

  const interfaceRes = (interComps || []).filter(item => item.type === RES);
  const interfaceResComps = interComps ? interfaceRes.map(item => item.name) : [];
  let powerNets = [], allPowerNets = [];

  for (let item of sections) {
    if (item.inType === "component") {
      const findIn = item.in.find(comp => resCompNames.includes(comp));
      if (findIn) {
        const comp = resComps.find(it => it.name === item.in[0]);
        if (comp) {
          allPowerNets.push({
            name: getDefaultName({ nameList: allPowerNets, defaultKey: "PWR", delimiter: "", firstIndex: 1 }),
            value: comp.voltage ? comp.voltage : "",
            comps: [...item.in],
            type: "Pull Up/Down"
          })
        }
      }
    }

    if (item.outType === "component") {
      const findOut = item.out.find(comp => resCompNames.includes(comp));
      if (findOut) {
        const comp = resComps.find(it => it.name === item.out[0]);
        if (comp) {
          allPowerNets.push({
            name: getDefaultName({ nameList: allPowerNets, defaultKey: "PWR", delimiter: "", firstIndex: 1 }),
            value: comp.voltage ? comp.voltage : "",
            comps: [...item.out],
            type: "Pull Up/Down"
          })
        }
      }
    }
  }

  if (!interComps) {
    return allPowerNets
  }

  for (let netItem of allPowerNets) {
    const comp = interfaceResComps.find(compName => netItem.comps && netItem.comps.includes(compName));
    if (!comp) {
      continue;
    }
    let voltage = netItem.value ? netItem.value : "";
    const findPrev = (prevPowerNets || []).find(it => it.comps && it.comps.length && (netItem.comps || []).find(compItem => it.comps.includes(compItem)));
    if (findPrev && findPrev.value) {
      voltage = findPrev.value;
    }
    powerNets.push({
      ...netItem,
      value: voltage
    });
  }

  return powerNets;
}

function checkTraceLength(length, unit) {
  let minValue = 0;
  switch (unit) {
    case "mm":
      minValue = 0.1;
      break;
    case "um":
      minValue = 100;
      break;
    case "mil":
      minValue = 3.94;//0.1mm
      break;
    default:
      break;
  }
  if (parseFloat(length) < minValue) {
    return `cannot be less than ${minValue}${unit}`;
  }
  return null;
}

export {
  getNewComponentName,
  updateSignalsAndCompsByGPIO,
  getDefaultSignalNames,
  getPointName,
  getSignalColor,
  updateSignalsAndCompsBySignalNum,
  getPreLayoutSignalsSections,
  getSchematicPreLayoutPowerNets,
  checkTraceLength
}