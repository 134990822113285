import { USER_LOGOUT } from '../../../Login/actionTypes';
import {
  UPDATE_END_TO_END_CHANNEL_CONTENT,
  UPDATE_END_TO_END_CHANNEL_INFO,
  OPEN_END_TO_END_CHANNEL,
  CLEAR_END_TO_END_CHANNEL_INFO,
  UPDATE_CONNECTION_MODEL_SETUP,
  UPDATE_END_TO_END_SETUP_MSG_LIST,
  UPDATE_END_TO_END_REPLACE_MONITOR,
  UPDATE_EXIST_RESULT_STATUS,
  UPDATE_CHANNEL_TOPOLOGY_LOADING,
  UPDATE_MULTI_COMPLIANCE_CONFIG
} from './actionTypes';

const defaultState = {
  endToEndChannelInfo: {
    content: {}
  },
  endToEndChannelId: null,
  connectionSetupStatus: false,
  endToEndSetupUpdateInfo: null,
  channelReplaceMonitorList: null,
  resultExist: null,
  channelTopologyLoading: false
}

export const AndesEndToEndChannelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_END_TO_END_CHANNEL_CONTENT:
      return {
        ...state,
        endToEndChannelInfo: {
          ...state.endToEndChannelInfo,
          content: {
            ...state.endToEndChannelInfo.content,
            ...action.data
          }
        }
      }
    case UPDATE_END_TO_END_CHANNEL_INFO:
      return {
        ...state,
        endToEndChannelInfo: action.info
      }
    case OPEN_END_TO_END_CHANNEL:
      return {
        ...state,
        endToEndChannelId: action.id
      }
    case CLEAR_END_TO_END_CHANNEL_INFO:
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_CONNECTION_MODEL_SETUP:
      return {
        ...state,
        connectionSetupStatus: action.status
      }
    case UPDATE_END_TO_END_SETUP_MSG_LIST:
      return {
        ...state,
        endToEndSetupUpdateInfo: action.endToEndSetupUpdateInfo
      }
    case UPDATE_END_TO_END_REPLACE_MONITOR:
      return {
        ...state,
        channelReplaceMonitorList: action.replaceMonitorList
      }
    case UPDATE_EXIST_RESULT_STATUS:
      return {
        ...state,
        resultExist: action.resultExist
      }
    case UPDATE_CHANNEL_TOPOLOGY_LOADING:
      return {
        ...state,
        channelTopologyLoading: action.loading
      }
    case UPDATE_MULTI_COMPLIANCE_CONFIG:
      return {
        ...state,
        endToEndChannelInfo: {
          ...state.endToEndChannelInfo,
          config: {
            channels: action.channels || state.endToEndChannelInfo.config.channels,
            analysis: {
              channels: action.analysisChannels || state.endToEndChannelInfo.config.analysis.channels,
              options: action.analysisOptions || state.endToEndChannelInfo.config.analysis.options
            }
          }
        }
      }
    default: return state;
  }
};