import React, { Component, createRef } from 'react';
import { Table } from 'antd';
import { EditableCell, EditableFormRow } from '../EditableTable/EditableTable';
import debounce from '../../services/helper/debounceFn';
import { isMac } from '@/services/api/userAgent';
import { RANK_MARGIN } from '../../services/BMA/helper/result';
import './index.css'


class VirtualTable extends Component {

  constructor(props) {
    super(props);
    const {
      rowKey,
      loading,
      columns,
      pagination,
      scroll,
      size,
      dataSource,
      bordered,
      setting,
      height,
      disableSort = false,
      tableType
    } = props;
    const headerCellClick = { onHeaderCell: disableSort ? null : cell => { return { onClick: () => this.sortClick(cell) } } };
    columns.map((item) => {
      Object.assign(item, headerCellClick);
    })
    const isScroll = (height - 40) < dataSource.length * setting.itemHeight;
    let _height = 0, _list = [];
    if (tableType === `${RANK_MARGIN}Result`) {
      _height = height;
      _list = [...dataSource]
    }

    this.state = {
      rowKey,
      loading,
      columns,
      pagination,
      scroll,
      size,
      dataSource,
      bordered,
      SETTINGS: setting,
      InitialState: this.setInitialState(setting),
      list: _list,
      data: [],
      sortHistory: '',
      countHistory: 0,
      hasScrollY: true,
      height: _height,
      isScroll,
      minHeight: 55
    }
    this.tableTitleHeight = isMac() ? 55 : 40;
    this.scrollRef = createRef();
  }


  /* 
 *    table :         ------------------------------------------------------|
 *                    |           |top padding        |       |             |
 *             topPaddingHeight   |-------------------|   itemAbove         |
 *                    |           |                   |       |             |
 *                    ------------|-------------------|--------             |
 *                    |           |outlet             |       |             |
 *             toleranceHeight    |-------------------|       |             |
 *                    |           |                   |       |             |
 *                    ------------|-------------------|       |             |
 *                    |           |visible viewport   |  bufferedHeight     |
 *                    |           |-------------------|       |             |--totalHeight
 *             viewportHeight     |                   |  bufferItems=3+2*2  |
 *                    |           |-------------------|       |             |
 *                    |           |                   |       |             |
 *                    ------------|-------------------|       |             |
 *                    |           |outlet             |       |             |
 *             toleranceHeight    |-------------------|       |             |
 *                    |           |                   |       |             |
 *                    ------------|-------------------|--------             |
 *                    |           |bottom padding     |                     |
 *            bottomPaddingHeight |-------------------|                     |
 *                    |           |                   |                     |
 *                    ------------------------------------------------------|
 */

  setInitialState = (settings) => {
    const {
      itemHeight,  //The length of a single row in the table
      amount,      //The number of data displayed in the table
      tolerance,   //The number of data on both sides of the data displayed
      minIndex,
      maxIndex,
      startIndex   //The starting position of the data displayed
    } = settings;
    const viewportHeight = amount * itemHeight;
    const totalHeight = (maxIndex - minIndex + 1) * itemHeight;
    const toleranceHeight = tolerance * itemHeight;
    const bufferHeight = viewportHeight + 2 * toleranceHeight;
    const bufferedItems = amount + 2 * tolerance;
    const itemsAbove = startIndex - tolerance - minIndex;
    const topPaddingHeight = itemsAbove * itemHeight;
    const bottomPaddingHeight = totalHeight - topPaddingHeight;
    const initialPosition = topPaddingHeight + toleranceHeight;
    return {
      viewportHeight,
      totalHeight,
      toleranceHeight,
      bufferHeight,
      bufferedItems,
      topPaddingHeight,
      bottomPaddingHeight,
      initialPosition,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.dataSource.length !== this.state.dataSource.length || nextProps.updateDataStatus !== this.props.updateDataStatus) {
      this.setState({
        dataSource: nextProps.dataSource,
        loading: nextProps.loading,
        SETTINGS: nextProps.setting,
        InitialState: this.setInitialState(nextProps.setting),
        list: JSON.parse(JSON.stringify(nextProps.dataSource)),
        data: [],
        countHistory: 0,
        isScroll: (nextProps.height - this.tableTitleHeight) < nextProps.dataSource.length * nextProps.setting.itemHeight
      });
    }
    if (nextProps.height !== this.state.height) {
      const isScroll = (nextProps.height - this.tableTitleHeight) < nextProps.dataSource.length * nextProps.setting.itemHeight;
      this.setState({ height: nextProps.height, lastHeight: this.state.height, isScroll });
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({
        loading: nextProps.loading
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { data, InitialState, SETTINGS, height, lastHeight, isScroll, minHeight } = this.state;
    const { scrollId, tableType } = this.props;
    try {
      if (data.length === 0) {
        const index = SETTINGS.minIndex;
        const data = this.getData(0, InitialState.bufferedItems);
        const topPaddingHeight = Math.max((index - SETTINGS.minIndex) * SETTINGS.itemHeight, 0);
        const bottomPaddingHeight = Math.max(
          InitialState.totalHeight - topPaddingHeight - data.length * SETTINGS.itemHeight - minHeight,
          0
        );
        const PaddingHeight = Object.assign({}, InitialState, {
          topPaddingHeight,
          bottomPaddingHeight
        })
        if (this.scrollRef) {
          this.scrollRef.scrollTop = 0;
        }
        this.setState({
          data: data,
          InitialState: PaddingHeight,
        });
      }
    } catch (error) {
      console.log("Data acquisition failed, please reopen the panel!");
    }
    if (height !== lastHeight || (isScroll && isScroll !== prevState.isScroll)) {
      let e = document.getElementById(scrollId || "virtual-table-scroll");
      this.setScrollHtml()
      this.setState({ lastHeight: height });
      if (e) {
        this.setState(e.scrollHeight > e.clientHeight ? { hasScrollY: true } : { hasScrollY: false });
      }
    }

    if (tableType !== prevProps.tableType) {
      const { dataSource, loading, setting, disableSort, columns } = this.props;
      const headerCellClick = { onHeaderCell: disableSort ? null : cell => { return { onClick: () => this.sortClick(cell) } } };
      columns.map((item) => {
        Object.assign(item, headerCellClick);
      })
      this.setState({
        dataSource: dataSource,
        loading: loading,
        SETTINGS: setting,
        InitialState: this.setInitialState(setting),
        list: JSON.parse(JSON.stringify(dataSource)),
        data: [],
        countHistory: 0,
        isScroll: (height - this.tableTitleHeight) < dataSource.length * setting.itemHeight,
        columns: columns
      });
    }
  }


  onScrollEvent = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    debounce(() => {
      this.getNewScrollData()
    }, 10, false, "virtualTable")()
  }

  getNewScrollData = () => {
    const { InitialState, SETTINGS, minHeight } = this.state;
    const scrollTop = this.scrollRef ? this.scrollRef.scrollTop : 0;
    const index = SETTINGS.minIndex + Math.floor((scrollTop - InitialState.toleranceHeight) / SETTINGS.itemHeight);

    const data = this.getData(index, InitialState.bufferedItems);
    const topPaddingHeight = Math.max((index - SETTINGS.minIndex) * SETTINGS.itemHeight, 0);
    const bottomPaddingHeight = Math.max(
      (InitialState.totalHeight - topPaddingHeight - data.length * SETTINGS.itemHeight) - minHeight,
      0
    );
    const PaddingHeight = Object.assign({}, InitialState, {
      topPaddingHeight,
      bottomPaddingHeight
    })
    this.setState({
      data: data,
      InitialState: PaddingHeight
    });
  }

  getData(offset, limit, list = this.state.list) {
    let data = [];
    const { rowKey } = this.props;
    const { SETTINGS } = this.state;
    const start = Math.max(SETTINGS.minIndex, offset);
    const end = Math.min(offset + limit - 1, SETTINGS.maxIndex);
    if (start <= end && list.length) {
      for (let i = start; i < end; i++) {
        data.push(list[i])
      }
    }
    data = [{ [rowKey ? rowKey : 'name']: 'topPaddingHeight' }, ...data, { [rowKey ? rowKey : 'name']: 'bottomPaddingHeight' }]
    return data;
  }

  sortClick(cell) {
    const { list, sortHistory, countHistory, dataSource } = this.state;
    if (!list || !list.length) {
      return;
    }
    const { scrollId } = this.props;
    const name = cell.dataIndex;
    if (cell.doNotSortData) { return }
    if (this.props.clearSortIconStatus) { this.props.clearSortIconStatus() }
    document.getElementById(scrollId || "virtual-table-scroll").scrollTo(0, 0);
    if (cell.dataIndex !== sortHistory) {
      this.setState({
        sortHistory: name,
      })
      this.sortUp(list, name, cell.numberSort);
    }
    else {
      if (countHistory === 2) {
        this.setState({ list: JSON.parse(JSON.stringify(dataSource)) })
        const data = this.getData(0, this.state.InitialState.bufferedItems, dataSource);
        this.setState({
          countHistory: 0,
          data
        })
      }
      else if (countHistory === 0) {
        this.sortUp(list, name, cell.numberSort);
      }
      else {
        this.sortDown(list, name, cell.numberSort);
      }
    }
  }

  sortUp(list, name, numberSort) {
    if (numberSort) {
      if (numberSort === 'value') {
        list.sort((a, b) => Number(a[name]) - Number(b[name]));
      } else if (numberSort === 'absolute') {
        list.sort((a, b) => {
          const aValue = parseFloat(a[name]) > 0 ? parseFloat(a[name]) : -parseFloat(a[name])
          const bValue = parseFloat(b[name]) > 0 ? parseFloat(b[name]) : -parseFloat(b[name])
          return aValue - bValue
        });
      }
      const data = this.getData(0, this.state.InitialState.bufferedItems);
      this.setState({
        data: data,
        countHistory: 1
      });
    } else if (typeof (list[0][name]) === 'string') {
      list.sort((a, b) => a[name].localeCompare(b[name]));
      const data = this.getData(0, this.state.InitialState.bufferedItems);
      this.setState({
        data: data,
        countHistory: 1
      });
    } else if (typeof (list[0][name]) === 'number') {
      list.sort((a, b) => a[name] - b[name]);
      const data = this.getData(0, this.state.InitialState.bufferedItems);
      this.setState({
        data: data,
        countHistory: 1
      });
    }
  }

  sortDown(list, name, numberSort) {
    if (numberSort) {
      if (numberSort === 'value') {
        list.sort((a, b) => Number(b[name]) - Number(a[name]));
      } else if (numberSort === 'absolute') {
        list.sort((a, b) => {
          const aValue = parseFloat(a[name]) > 0 ? parseFloat(a[name]) : -parseFloat(a[name])
          const bValue = parseFloat(b[name]) > 0 ? parseFloat(b[name]) : -parseFloat(b[name])
          return bValue - aValue
        });
      }
      const data = this.getData(0, this.state.InitialState.bufferedItems);
      this.setState({
        data,
        countHistory: 2
      });
    } else if (typeof (list[0][name]) === 'string') {
      list.sort((a, b) => -(a[name].localeCompare(b[name])));
      const data = this.getData(0, this.state.InitialState.bufferedItems);
      this.setState({
        data,
        countHistory: 2,
      });
    } else if (typeof (list[0][name]) === 'number') {
      list.sort((a, b) => b[name] - a[name]);
      const data = this.getData(0, this.state.InitialState.bufferedItems);
      this.setState({
        data,
        countHistory: 2
      });
    }
  }

  componentDidMount = () => {
    this.setScrollHtml();
    const { dataSource, height, setting } = this.props;
    this.setState({
      isScroll: (height - this.tableTitleHeight) < dataSource.length * setting.itemHeight
    })
  }

  setScrollHtml = () => {
    const { scrollId } = this.props;
    const tbodyHtml = document.getElementById(scrollId || "virtual-table-scroll").getElementsByClassName("ant-table-body")[0];
    if(tbodyHtml) {
      this.scrollRef = tbodyHtml;
      tbodyHtml.addEventListener("scroll", this.onScrollEvent);
      tbodyHtml.setAttribute("id", "virtual-table-body")
    } else {
      const tbodyHtml = document.getElementById(scrollId || "virtual-table-scroll").getElementsByClassName("ant-table-tbody")[0];
      this.scrollRef = tbodyHtml;
      tbodyHtml.addEventListener("scroll", this.onScrollEvent);
      tbodyHtml.setAttribute("id", "virtual-table-body")
    }
  }

  bodyRow = (props) => {
    /* return props[`data-row-key`] && ['topPaddingHeight', 'bottomPaddingHeight'].includes(props[`data-row-key`]) ?
      <tr style={{ height: this.state.InitialState[props[`data-row-key`]] }} ></tr>
      : <tr {...props}></tr> */
  }

  EditableFormRow = (props) => {
    return props[`data-row-key`] && ['topPaddingHeight', 'bottomPaddingHeight'].includes(props[`data-row-key`]) ?
      <tr style={{ height: this.state.InitialState[props[`data-row-key`]] }}></tr>
      : <EditableFormRow {...props} />
  };

  render() {
    const {
      loading,
      columns,
      pagination,
      size,
      bordered,
      data,
      height,
      hasScrollY,
      isScroll
    } = this.state;
    const { rowKey, handleChange, scrollId, rowSelection, className } = this.props;
    const components = {
      body: {
        row: this.EditableFormRow /* this.bodyRow */,
        cell: EditableCell,
      }
    }
    return (
      <div>
        <Table
          rowSelection={rowSelection || null}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          size={size}
          bordered={bordered}
          loading={loading}
          rowKey={rowKey ? rowKey : 'name'}
          rowClassName={() => 'editable-row'}
          className={`virtual-table ${className || ""} ${hasScrollY ? '' : 'virtual-table-without-scroll-Y'}`}
          components={components}
          tableLayout='fixed'
          scroll={isScroll ? { y: `${height - this.tableTitleHeight}px` } : {}}
          id={scrollId || "virtual-table-scroll"}
          onChange={handleChange ? handleChange : null}
        />
      </div>
    )
  }
}

export default VirtualTable;