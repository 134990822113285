import { select, event } from 'd3-selection';
import LayerRenderer from './LayerRenderer';
import MetalLayerRenderer from './MetalLayerRenderer';
import { getNetsShapeLocation } from '../helper/canvasHelper/canvasMath'

/** The SelectionLyrRenderer draws selected objects in a design
 *  The main purpose of this renderer is to display all traces in a net
 */
var SelectionLyrRenderer = function (layoutRenderer, layerName, hidden) {
  LayerRenderer.call(this, layoutRenderer, layerName, hidden);
  this.netInfo = [];
  this.netObjLists = [];
  this.viaLists = [];
  this.d3NetGroups = null; // d3 drawing root
};

SelectionLyrRenderer.prototype = Object.create(LayerRenderer.prototype);
SelectionLyrRenderer.prototype.constructor = SelectionLyrRenderer;

SelectionLyrRenderer.prototype.prepareData = function (arrNetNames) {

  var self = this;
  this.netObjLists = [];
  this.viaLists = [];
  var netInfo = this.layoutRenderer.netInfo;
  var keys = [];
  for (var iNet = 0; iNet < netInfo.length; iNet++) {
    if (arrNetNames.indexOf(netInfo[iNet].name) > -1) {
      keys.push(iNet);
    }

    if (keys.length === arrNetNames.length) break;
  }

  if (keys.length) {
    this.layoutRenderer.layerList.forEach(function (e) {
      if (e instanceof MetalLayerRenderer) {
        for (var i = 0; i < keys.length; i++) {
          self.netObjLists.push(e.netObjLists[keys[i]]);
          self.viaLists.push(e.viaLists[keys[i]]);
        }
      }
    })
  }

}; // SelectionLyrRenderer.prototype.prepareData

SelectionLyrRenderer.prototype.redraw = function (arrNetNames, color) {

  // draw the objects
  var self = this;

  this.layerElement.selectAll('g').remove();

  this.d3NetGroups = this.layerElement
    .selectAll('g')
    .data(this.netObjLists)
    .enter()
    .append('g')
  //.attr('fill','magenta');

  this.d3NetGroups
    .data(self.netObjLists)
    .each(function (d, i) {
      d.drawSVG(select(this));
      var layer = getLayerName(d, "layer");
      if (!layer) return;
      var netName = getLayerName(d, "net");
      let index = i;
      if (arrNetNames && arrNetNames.length > 0) {
        index = arrNetNames.findIndex(item => item === netName);
      }
      let _color = color ? color : self.layoutRenderer.layoutDB.GetLayoutSettings().GetSelectNetColor(index)[0];
      //if(isLyrVisible(layer)){
      select(this)
        //.attr("fill", 'magenta')
        .attr("fill", _color)
        .select('g')
        //.selectAll("polyline")
        .attr('stroke', _color);
      select(this)
        .append('title')
        .text(netName);

      if (self.layoutRenderer.hasVia === undefined || self.layoutRenderer.hasVia)
        select(this)
          .append('g')
          .selectAll('g')
          .data(self.viaLists[i])
          .enter()
          .append('g')
          .each(function (via) {
            var viaElement = select(this),
              text = 'Net: ' + via.net +
                '\nLayer: ' + self.layerName;
            if (via.canvasCircleList[0]) {
              text += '\nCoordinate: ' + via.canvasCircleList[0].xc + ',' + via.canvasCircleList[0].yc +
                '\nBarrel Diameter: ' + via.barrel +
                '\nPad Diameter: ' + via.pad +
                (via.antipad ? ('\nAntipad Diameter: ' + via.antipad) : '');
            }

            viaElement.append('title').text(text);
            via.drawVia(viaElement);
          })
      //self.viaLists[i].drawSVG(d3.select(this).append('g'));

      /*}else{
        d3.select(this)
          .attr("fill", 'none')
          .attr('stroke','LightSlateGray')
          .attr('title', '')
          .attr('opacity', '0.7')
          .attr('stroke-dasharray', function(){
            var width = d3.select("svg").attr('stroke-width');
            return 2*width + "," + 3*width;
          })
          .selectAll("polyline")
          .attr('stroke-dasharray', function(d){
            return 2*d.width + "," + 3*d.width;
          });

        d3.select(this)
          .select('g')
          .attr('stroke','LightSlateGray')
          .selectAll("path")
          .attr('stroke-dasharray', function(d){
            return d.width + "," + 2*d.width;
          });

        d3.select(this)
          .append('title')
          .text(netName + " (" + layer + ")");
      }*/

      //var viagroup = d3.select(this).append('g');
      //d.via.drawSVG(viagroup);
    });

  // call the virtual function of the parent
  LayerRenderer.prototype.redraw.call(this);

}; // SelectionLyrRenderer.prototype.redraw
function getLayerName(netObj, property) {

  if (netObj.canvasCircleList.length > 0) {
    return netObj.canvasCircleList[0][property];
  } else if (netObj.canvasLineList.length > 0) {
    return netObj.canvasLineList[0][property];
  } else if (netObj.canvasPathList.length > 0) {
    return netObj.canvasPathList[0][property];
  } else if (netObj.canvasPolygonList.length > 0) {
    return netObj.canvasPolygonList[0][property];
  }
  if (netObj.canvasLarcList.length > 0) {
    return netObj.canvasLarcList[0][property];
  }
  return null;
}

SelectionLyrRenderer.prototype.clear = function () {
  this.layerElement.selectAll('g').remove();
  this.netObjLists = [];
  this.d3NetGroups = null;
};

SelectionLyrRenderer.prototype.getNetsShape = function (nets) {
  const POWERNET = 'powerNet', SIGNALNET = 'signalNet';
  const netListMap = this.layoutDB.GetNetManager().GetNetList();
  let netShapeInfo = [];
  for (let netName of Object.keys(nets)) {
    let type = nets[netName];
    const netInfo = netListMap.getValue(netName);
    let allGeomListData = getNetsShapeLocation({ netInfo, nets, netName, type, netObjLists: this.netObjLists })
    if (!allGeomListData.length) {
      type = (nets[netName] === SIGNALNET) ? POWERNET : SIGNALNET
      allGeomListData = getNetsShapeLocation({ netInfo, nets, netName, type, netObjLists: this.netObjLists })
    }
    const maxCanvasList = getMaxCanvasList(allGeomListData)
    netShapeInfo.push({ net: netName, shapeInfo: maxCanvasList, type: type })
  }
  function getMaxCanvasList(canvasList) {
    let compare = function (obj1, obj2) {
      let val1 = obj1.shapeSize * 1;
      let val2 = obj2.shapeSize * 1;
      if (val1 > val2) {
        return -1;
      } else if (val1 < val2) {
        return 1;
      } else {
        return 0;
      }
    }
    const sortCanvasList = canvasList.sort(compare)
    // slice
    const _length = sortCanvasList.length > 10 ? 10 : sortCanvasList.length;
    const maxCanvasList = sortCanvasList.slice(0, _length)
    return maxCanvasList
  }
  return netShapeInfo
}

SelectionLyrRenderer.prototype.updateNetDisplay = function () {
  var self = this;
  this.d3NetGroups
    .data(self.netObjLists)
    .each(function (d, i) {
      var layer = getLayerName(d, "layer");
      if (!layer) return;
      var netName = getLayerName(d, "net");
      var color = self.layoutRenderer.layoutDB.GetLayoutSettings().GetLayerColor(layer);
      select(this)
        //.attr("fill", 'magenta')
        .attr("fill", color)
        .select('g')
        //.selectAll("polyline")
        .attr('stroke', color);
      select(this)
        .append('title')
        .text(netName);
      /*if(isLyrVisible(layer)){
          d3.select(this)
            .attr("fill", 'magenta')
            .attr('stroke', null)
            .attr('opacity', '1')
            .attr('stroke-dasharray', null)
            .selectAll("polyline")
            .attr('stroke','magenta')
            .attr('stroke-dasharray', null);
          d3.select(this)
            .select('g')
            .attr('stroke','magenta')
            .selectAll("path")
            .attr('stroke-dasharray', null);
          d3.select(this)
            .select('title')
            .text(netName);
        }else{
          d3.select(this)
            .attr("fill", 'none')
            .attr('stroke','LightSlateGray')
            .attr('opacity', '0.7')
            .attr('stroke-dasharray', function(){
              var width = d3.select("svg").attr('stroke-width');
              return 2*width + "," + 3*width;
            })
            .selectAll("polyline")
            .attr('stroke','LightSlateGray')
            .attr('stroke-dasharray', function(d){
              return 2*d.width + "," + 3*d.width;
            });
          d3.select(this)
            .select('g')
            .attr('stroke','LightSlateGray')
            .selectAll("path")
            .attr('stroke-dasharray', function(d){
              return d.width + "," + 2*d.width;
            });
          d3.select(this)
            .select('title')
                .text(netName+" ("+layer+")");;
        }*/
    });
};

export default SelectionLyrRenderer;