import {
} from './actionType';

const defaultState = {
};

export const CascadeResultReducer = (state = defaultState, action) => {
  switch (action.type) {
    default: return state
  }
}