import PCBInterfacesInfo from './PCBInterfacesInfo';
import { splitComponentName } from '@/services/helper/splitComponent';

class ChannelComponentsInfo {
  constructor() {
    this.getChannelComponents.bind(this);
    this.deleteChannelComponents.bind(this);
  }

  getChannelComponents(projectId, channelId, channelList) {
    return new Promise((resolve, reject) => {
      if (projectId && channelId) {
        const channelInfo = this[projectId] ? this[projectId][channelId] : null;
        if (channelInfo) {
          resolve(channelInfo);
        } else {
          PCBInterfacesInfo.getPCBInterfaces(projectId, channelList).then(res => {
            if (res) {
              let currentChannel = res[channelId] ? res[channelId] : {};
              if (!currentChannel) {
                resolve({});
              }
              const componentList = currentChannel && currentChannel.DDR ? currentChannel.DDR.find(group => group.Name.includes('CLK_ADR')).Interfaces[0].Content.Components : [];
              componentList.forEach(component => { component.name = splitComponentName(component.name) })
              const name = currentChannel && currentChannel.Name ? currentChannel.Name : '';
              if (!this[projectId]) {
                //save current channel info
                this[projectId] = {
                  [channelId]: {
                    name: name,
                    componentList
                  }
                }
              } else {
                //save current channel info
                this[projectId][channelId] = {
                  name: name,
                  componentList
                }
              }
              if (this[projectId][channelId]) {
                resolve(this[projectId][channelId])
              } else {
                resolve({})
              }
            } else {
              resolve({})
            }
          })
        }
      }
    })
  }

  deleteChannelComponents(projectId) {
    if (this[projectId]) {
      delete this[projectId]
    }
  }
}

const Info = new ChannelComponentsInfo();
export default Info;
