import apiProcess from "../../api/utility";
import {
  getHimalayasProjectList,
  createHimalayasProject,
  getHimalayasProjectChildren,
  delHimalayasProject,
  renameHimalayasChannel
} from '../../api/Himalayas/project';
import { cleanupTrash, getProjectServiceOptionsConfig, getTrashList, projectRename, updateProjectServiceOptionsConfig } from "../../api/v2/projectCtrl";

/** 
* Get project list
* 2023/05/18
* @return Promise
*/
function getProjectList() {
  return apiProcess(getHimalayasProjectList)
}

/** 
* create new project
* 2023/05/19
* @param {object} data: { name }
* @return Promise
*/
function createProjectPromise({ data }) {
  return apiProcess(createHimalayasProject, { data });
}

/** 
* get project children
* 2023/05/22
* @param {string} projectId
* @return Promise
*/
function getProjectChildrenPromise(projectId) {
  return apiProcess(getHimalayasProjectChildren, projectId);
}

/** 
* delete projects by ids
* 2023/05/22
* @param {Array} projectIds
* @return Promise
*/
function deleteProjectPromise(projectIds) {
  return apiProcess(delHimalayasProject, projectIds);
}

/** 
* channel (spice card and io sim ) rename
* 2023/06/01
* @param {string} verificationId
* @param {string} name -> new name
* @return
*/
function channelRenamePromise({ verificationId, name }) {
  return apiProcess(renameHimalayasChannel, { verificationId, name })
}

/** 
* Project rename
* 2023/06/01
* @param {string} projectId -> projectId
* @param {string} projectName -> new name
* @return
*/
function projectRenamePromise({ projectId, projectName }) {
  return apiProcess(projectRename, { projectId, projectName })
}

/** 
* Project clean trash
* 2023/06/02
* @return
*/
function cleanHimalayasTrash() {
  return apiProcess(cleanupTrash, 'Himalayas');
}

/** 
* get project trash list
* 2023/06/02
* @return
*/
function getHimalayasTrashList() {
  return apiProcess(getTrashList, 'Himalayas');
}

/**
 * Save project service options config
 *
 * @export
 * @param {string} projectId
 * @param {string} hspiceOption
 * @returns promise
 */
function updateHimalayasServiceOptions({ projectId, hspiceOption }) {
  return apiProcess(updateProjectServiceOptionsConfig, { projectId, options: { hspiceOption } })
}

/**
 * Get himalayas project config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
function getProjectConfig(projectId) {
  return apiProcess(getProjectServiceOptionsConfig, projectId)
}

export {
  getProjectList,
  createProjectPromise,
  getProjectChildrenPromise,
  deleteProjectPromise,
  channelRenamePromise,
  projectRenamePromise,
  cleanHimalayasTrash,
  getHimalayasTrashList,
  updateHimalayasServiceOptions,
  getProjectConfig
}
