import {
  UPLOAD_SNP_FILE,
  CHANGE_RESULT_UPLOAD_PROGRESS,
  CHANGE_FILE_STATUS,
  CHANGE_RESULT_TYPE,
  GET_DECAP_FILE_VALUE,
  GET_DECAP_INFO_SUCCESS,
  GET_DC_VALUE,
  SAVE_DC_VALUE,
  UPDATE_DC_SELECTED_KEY,
  GET_DC_INFO_SUCCESS,
  CHANGE_DC_RESULT_TYPE,
  UPDATE_DC_RESULT_TABLE_LOADING,
  UPDATE_DC_RESULT_STATUS,
  CLEAR_DC_RESULT_DATA
} from './actionTypes';

export const uploadSnpFile = (verificationId, file) => ({
  type: UPLOAD_SNP_FILE,
  verificationId,
  file
});

export const changeUploadProgress = (verificationId, progress) => ({
  type: CHANGE_RESULT_UPLOAD_PROGRESS,
  verificationId,
  progress
})

export const changeFileStatus = (status) => ({
  type: CHANGE_FILE_STATUS,
  status
})

export const changeResultType = (resultKey) => ({
  type: CHANGE_RESULT_TYPE,
  resultKey
})

export const getDecapValue = (projectId, pdnId, verificationId) => ({
  type: GET_DECAP_FILE_VALUE,
  projectId,
  pdnId,
  verificationId
})

export const getDecapInfoSuccess = (decapData) => ({
  type: GET_DECAP_INFO_SUCCESS,
  decapData
})

export const getDCValue = (verificationId, showFile) => ({
  type: GET_DC_VALUE,
  verificationId,
  showFile
})

export const saveDCValue = (list, resultPowerDomainData) => ({
  type: SAVE_DC_VALUE,
  list,
  resultPowerDomainData
})

export const updateDCSelectedKey = (selectedKey) => ({
  type: UPDATE_DC_SELECTED_KEY,
  selectedKey
})

export const getDCInfoSuccess = (DCResultData) => ({
  type: GET_DC_INFO_SUCCESS,
  DCResultData
})

export const changeDCResultType = (DCResultKey) => ({
  type: CHANGE_DC_RESULT_TYPE,
  DCResultKey
})

export const updateDCResultType = (loading) => ({
  type: UPDATE_DC_RESULT_TABLE_LOADING,
  loading
})

export const updateDCResultStatus = (status) => ({
  type: UPDATE_DC_RESULT_STATUS,
  status
})

export const clearDCResultData = () => ({
  type: CLEAR_DC_RESULT_DATA,
})