
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TreeForm from '@/components/TreeNode';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  PlusSquareOutlined,
  PushpinFilled,
  PushpinOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Input, Tooltip, Select, message, Checkbox, Tag } from 'antd';
import UploadPCB from './Upload';
import { expandMenu } from '../store/pdn/action';
import { updateLibraryMenu, updateProjectMenu, openProject, clearCurrentProject, updateTrashMenu, changeTreeSelected, changeViewList, setDefaultDecap, delDefaultDecap, projectMenu, startLayoutCheck } from '../store/project/action';
import { closeTabFooter } from '../../tabMonitor/action';
import { changeVerification } from '../../tabMonitor/action';
import {
  MY_LIBRARY, LIBRARY, LIBRARYS, PROJECTS, PROJECT, IntelSPIM_FILE,
  VRM_DATA, DECAP_DATA, DECAP_FILE, PCBs, Packages, PDNs,
  PCB, Package, PDN, PROJECT_CREATE, PDN_CREATE, TRASH,
  POWER_DOMAIN_FILE, POWER_DOMAIN_TOUCHSTONE, LIBRARY_FILE, DECAP_TOUCHSTONE
} from '../constants';
import FileSaver from 'file-saver';
import DelConfirm from '@/components/DelConfirm';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { strDelimited } from '@/services/helper/split';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import debounce from '@/services/helper/debounceFn';
import { DESIGN_FAILED, DESIGN_SUCCESS } from '@/constants/designCategory';
import { SPIM_MISSING_FILE, SPIM_UNAVAILABLE } from '@/constants/librarySPIMStatus';
import { getNewSPIMLog } from '../../../services/PDN/library';
import '../pdn.css';

// Action
const ADD = 'add', DELETE = 'delete', EDIT = 'edit', SHOW = 'show', UPLOAD_PCB = 'upload_pcb',
  VERIFY = 'verify', RESULT = 'result', SIMULATION = 'simulation', ADD_PACKAGE = 'add_package',
  CLEAN = 'clean', DOWNLOAD = 'download', RENAME = 'rename', LOADING = 'loading', PRE_PDN = 'pre_pdn',
  UNUSE_VERIFY = 'unuse_verify', DEFAULT = 'default', WARNING = 'warning', WARNING_PDN = 'warning_pdn',
  IMPORTEXPORT = 'import_export', BATCH_DELETE = 'batch_delete', LAYOUT_CHECKOUT = 'layout_checkout', LOG = 'log', REPLACE = 'replace';

const Option = Select.Option;

function getIconKey(type) {
  switch (type) {
    case LIBRARY:
      return 1;
    case PROJECTS:
      return 17;
    case PCB:
      return 19;
    case VRM_DATA:
    case DECAP_DATA:
    case Package:
      return 2;
    case PCBs:
      return 5;
    case PDN:
      return 6;
    case PDNs:
      return 7;
    case Packages:
      return 8;
    case TRASH:
      return 9;
    case IntelSPIM_FILE:
      return 18;
    case DECAP_TOUCHSTONE:
      return 11;
    case PROJECT:
      return 12;
    case DECAP_FILE:
      return 13;
    case PRE_PDN:
      return 14;
    case WARNING_PDN:
      return 15;
    case MY_LIBRARY:
      return 16;
    default: return 0;
  }
}
const IconKey = {
  // add import/export icon
  1: [ADD],
  2: [DELETE],
  3: [DELETE, EDIT],
  4: [DELETE, SHOW],
  5: [UPLOAD_PCB],
  6: [DELETE, VERIFY],
  7: [ADD, SIMULATION, BATCH_DELETE],
  8: [ADD_PACKAGE],
  9: [CLEAN],
  11: [DELETE, DOWNLOAD],
  12: [DELETE, RENAME],
  13: [DELETE, DOWNLOAD, DEFAULT],
  14: [LOADING, UNUSE_VERIFY],
  15: [DELETE, WARNING, VERIFY],
  16: [IMPORTEXPORT],
  17: [ADD, IMPORTEXPORT],
  18: [DELETE, DOWNLOAD, LOG],
  19: [DELETE, REPLACE, LAYOUT_CHECKOUT],
  0: []
}

let count = 0;
class PDNTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      checkedKeys: [],
      pdnSelect: undefined,
      inputPDNName: null,
      loading: null,
      downloadProgress: -1,
      downloadVisible: false,
      saveHistoryMsg: null,
      saveHistoryPDNInfo: null,
      openSaveHistoryPrompt: false
    }
  }

  componentDidMount() {
    this.props.UpdateLibraryMenu();
    this.props.updateTrashMenu();
    const { expandedKeys, PDNID, verificationName } = this.props;

    if (expandedKeys.length > 2) {
      expandedKeys.forEach((item, index) => {
        const [key, id] = strDelimited(item, "-");
        if (key === 'project') {
          this.props.UpdateProjectMenu({ openProjectId: id, verificationName, pdnId: PDNID });
        }
      });
    } else {
      this.props.UpdateProjectMenu();
    }
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const comfirm = document.getElementsByClassName('pdn-simulate-pop-confirm')[0];
    if (comfirm && comfirm.contains(target) && target.classList[0] !== 'ant-btn') {
      e.stopPropagation();
      return;
    }
  }


  componentDidUpdate = (prevProps) => {
    const { autoFocus, inputPDNName } = this.props;
    if (inputPDNName !== this.state.inputPDNName) {
      this.setState({
        inputPDNName: inputPDNName
      })
    }
    if (autoFocus && this.inputRef) {
      const { input } = this.inputRef;
      input.focus();
    }
    if (autoFocus && this.selectRef) {
      if (this.selectRef) {
        this.selectRef.focus();
      }
    }
  }

  getType = (dataType) => {
    switch (dataType) {
      case Package:
        return 'package';
      case PCB:
        return PCB;
      case IntelSPIM_FILE:
      case DECAP_FILE:
      case VRM_DATA:
      case DECAP_DATA:
      case DECAP_TOUCHSTONE:
        return 'library';
      case PROJECT:
        return 'project';
      case PDN:
        return 'PDN';
      default: return false;
    }
  }

  getPDNSimulateTitle = (verifyDisabled, selectedKeys) => {
    if (verifyDisabled) {
      if (selectedKeys.length === 0) {
        return 'Please check one PDN!'
      } else if (selectedKeys.length > 1) {
        return 'PDN can only check one!'
      } else {
        return 'Already one PDN is simulating!'
      }
    } else {
      return null;
    }
  }

  getIcon = (key, item) => {
    const { downloadVisible } = this.state;
    const { delItem, editItem, changePDNCheck, verifyDisabled, selectedKeys, defaultDecap, pkgDisabled, simulationDesigns, uploading, showImportExportModel } = this.props;
    const select = selectedKeys.findIndex(selected => (selected.id === item.id));
    let checked = false;
    if (select > -1 && typeof (select) !== 'boolean') {
      checked = true;
    }
    let isSimId = null;
    if (selectedKeys.length === 1) {
      isSimId = selectedKeys[0].verificationId;
    }
    let isDefaultDecap = false;
    if (defaultDecap && item.id === defaultDecap.id) {
      isDefaultDecap = true;
    }
    switch (key) {
      case ADD:
        return (
          <PlusSquareOutlined
            className='aurora-tree-add-icon'
            key={key}
            onClick={(e) => this._addItem(e, item)} />
        );
      case DELETE:
        const type = this.getType(item.dataType);
        return type && downloadVisible !== item.id ? <CloseOutlined
          key={item.key}
          className='aurora-tree-del-icon'
          onClick={(e) => this._deleteClick(e, item)} /> :
          downloadVisible !== item.id ? <CloseOutlined
            key={key}
            className='aurora-tree-del-icon'
            onClick={(e) => delItem(e, item)} /> : null;
      case EDIT:
        return (
          <EditOutlined
            className='aurora-tree-edit-icon'
            key={key}
            onClick={(e) => editItem(e, item.key, item)} />
        );
      case UPLOAD_PCB:
        return <UploadPCB key={key} data={item} type='upload' />;
      case VERIFY:
        return <span key={key} onClick={(e) => this.spanClick(e)}><Checkbox key={key} checked={checked} onChange={(e) => changePDNCheck(e, key, item)} /></span>
      case SIMULATION:
        return (
          < Tooltip
            key={key}
            placement="topLeft"
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            title={this.getPDNSimulateTitle(verifyDisabled, selectedKeys)}
            className='pdn-simulate-button'
            overlayClassName='aurora-tooltip'
          >
            <span onClick={(e) => this.spanClick(e)} >
              <PlayCircleOutlined
                key={key}
                id={`simulate-button-${item.key}`}
                className={[(!verifyDisabled && 'aurora-tree-simulate-icon') || '', selectedKeys.length !== 1 ? 'simulate-icon-disabled' : '']}
                onClick={(e) => this.simulateClick(e, isSimId)}></PlayCircleOutlined>
            </span>
          </Tooltip >
        );
      case ADD_PACKAGE:
        return (
          <PlusSquareOutlined
            className={item.iconDisabled || pkgDisabled ? 'aurora-tree-add-icon icon-disabled' : 'aurora-tree-add-icon'}
            key={key}
            onClick={(e) => this._addItem(e, item)
            } />
        );
      case IMPORTEXPORT:
        return uploading && uploading.key === item.key && uploading.status
          ?
          <Tooltip key={key} title='Files are being imported or exported' overlayClassName='aurora-tooltip'>
            <Loading3QuartersOutlined
              spin
              onClick={(e) => { e && e.stopPropagation(); showImportExportModel(true) }}
              key={key}
              className={item.dataType === MY_LIBRARY ? `aurora-tree-import-export-loading-icon aurora-tree-import-export-library-loading` : `aurora-tree-import-export-loading-icon aurora-tree-import-export-project-loading`} />
          </Tooltip>
          :
          <span
            key={key}
            title='Import Or Export'
            onClick={(e) => this.importExportFolder(e, item)}
            className={item.dataType === MY_LIBRARY ? `iconfont icon-import-export aurora-tree-import-export-library-icon` : `iconfont icon-import-export aurora-tree-import-export-project-icon`}
            style={{ color: '#1890ff' }}
          ></span>;
      case CLEAN:
        return (
          <DeleteOutlined
            key={item.key}
            className='aurora-tree-del-icon'
            onClick={(e) => this._deleteClick(e, item)} />
        );
      case DOWNLOAD:
        return downloadVisible !== item.id ? <Tooltip key={key} title='Download' overlayClassName='aurora-tooltip'>
          <DownloadOutlined
            style={{ marginRight: 20 }}
            className="aurora-tree-download-icon"
            onClick={(e) => this.downloadFile(e, item)} />
        </Tooltip> : null;
      case RENAME:
        return (
          <EditOutlined
            title='Rename'
            className='aurora-tree-rename-icon'
            key={key}
            onClick={(e) => this.renameProject(e, item)} />
        );
      case LOADING:
        return <Tooltip key={key} title='This PDN is preparing, please do not refresh the page or delete project!' overlayClassName='aurora-tooltip'>
          <span
            key={key}
            onClick={(e) => this.spanClick(e)}
            title='Preparing Current PDN'
            className={`iconfont icon-loading3 aurora-tree-loading-icon prepare-loading-icon`}
            style={{ color: '#1890ff' }}
          ></span>
        </Tooltip>;
      case UNUSE_VERIFY:
        return <span key={key}><Checkbox key={key} defaultChecked={false} disabled /></span>
      case DEFAULT:
        return isDefaultDecap && downloadVisible !== item.id ?
          <Tooltip key={key} title='Cancel default decap library' overlayClassName='aurora-tooltip'>
            <PushpinFilled
              style={{ marginRight: 40 }}
              className="aurora-tree-set-icon"
              onClick={() => this.changeDecap(false)} />
          </Tooltip>
          :
          <Tooltip key={key} title='Set as default decap library' overlayClassName='aurora-tooltip'>
            <PushpinOutlined
              style={{ marginRight: 40 }}
              className="aurora-tree-set-icon"
              onClick={() => this.changeDecap(item)} />
          </Tooltip>;
      case WARNING:
        return (
          <Tooltip key={key} title='Is not available!' overlayClassName='aurora-tooltip pdn-warning-tooltip'>
            <ExclamationCircleOutlined className="aurora-tree-warning-icon" />
          </Tooltip>
        );
      case BATCH_DELETE:
        return (
          <span key={key} onClick={(e) => { this.inputClick(e) }} >
            <Tooltip
              placement="top"
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              title={selectedKeys && selectedKeys.length > 0 ? null : 'Please Check PDNs!'}
              overlayClassName='aurora-tooltip'
            >
              <DeleteOutlined
                id={`simulate-button-${item.key}`}
                key={key}
                className={selectedKeys && selectedKeys.length > 0 ? 'aurora-tree-batch-delete-icon' : 'aurora-tree-batch-delete-icon simulate-icon-disabled'}
                onClick={(e) => this.batchDeleteClick(e, item.key, item)} />
            </Tooltip>
          </span>
        );
      case LAYOUT_CHECKOUT:
        return simulationDesigns.includes(item.id) ?
          <Tooltip key={key} title='Checking Layout' overlayClassName='aurora-tooltip'>
            <LoadingOutlined key={key} className={`aurora-tree-layout-check-loading-icon`} />
          </Tooltip >
          : null;
      case LOG:
        return (
          <FileTextOutlined
            title='Log'
            className='aurora-tree-file-text-icon'
            key={key}
            onClick={(e) => this.openLogPanel(e, item)} />
        );
      case REPLACE:
        if (item.category === DESIGN_SUCCESS) {
          return (<span key={key} className='tree-node-pcb-replace-icon'><UploadPCB key={key} data={item} type='replace' /></span>);
        } break
      default: break;
    }
  }

  importExportFolder = (e, item) => {
    e.stopPropagation();
    this.props.importExportData(item)
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  _addItem = (e, item) => {
    if (item.dataType === PROJECTS) {
      this.addProjects();
    }
    this.props.addItem(e, item.key, item.dataType, item);
  }

  addProjects = () => {
    let { treeItems, expandedKeys } = this.props;

    if (!expandedKeys.includes(PROJECTS)) {
      this.props.expandMenu([...expandedKeys, PROJECTS]);
    }
    if (treeItems[1].children) {
      let dataTypes = treeItems[1].children.map(item => item.dataType);

      if (dataTypes.includes(PROJECT_CREATE)) {
        message.error('There are already projects being created.');
        return;
      }
    }

    const name = getDefaultName({ nameList: treeItems[1].children, defaultKey: "Project" });
    treeItems[1].children.unshift(
      {
        id: name,
        name: name,
        key: name,
        dataType: PROJECT_CREATE,
        nodeClass: 'project-create-node'
      }
    );
    this.props.projectMenu({ treeItems: [...treeItems] });
    this.setState({
      inputValue: name
    })
  }

  simulateClick = (e, isSimVerificationId) => {
    e && e.stopPropagation();
    if (isSimVerificationId) {
      //selectedKeys length === 1
      //Determine whether history exists
      const historyExist = this.judgeHistory(isSimVerificationId);
      if (historyExist) {
        this.props.startVerification(e);
      } else {
        // history not exist, open prompt panel
        this.openSaveHistoryPrompt(isSimVerificationId);
      }
    } else {//selectedKeys >1 || === 0
      this.props.startVerification(e);
    }
  }

  layoutCheckout = (e, item) => {
    e && e.stopPropagation();
    this.props.startLayoutCheck(item)
  }

  judgeHistory = (verificationId) => {
    //Determine whether history exists
    const { currentProjectPDNs } = this.props;
    const currentPdn = currentProjectPDNs.find(item => item.verificationId === verificationId);
    if (currentPdn && currentPdn.result && !currentPdn.historyExist) {
      return false;
    } else {
      return true;
    }
  }

  openSaveHistoryPrompt = (verificationId) => {
    const { currentProjectPDNs } = this.props;
    const currentPdn = currentProjectPDNs.find(item => item.verificationId === verificationId);
    //prompt message
    const saveHistoryMsg = <span>The PDN {<span className="font-bold">{currentPdn.name}</span>} result is not saved to history, whether to continue simulate?</span>;
    this.setState({
      openSaveHistoryPrompt: true,
      saveHistoryMsg,
      saveHistoryPDNInfo: currentPdn
    })
  }

  _onSimulate = (e) => {
    this.props.startVerification(e);
    this.clearSaveHistoryPrompt();
  }

  clearSaveHistoryPrompt = () => {
    this.setState({
      openSaveHistoryPrompt: false,
      saveHistoryMsg: null,
      saveHistoryPDNInfo: null
    })
  }

  renameProject = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectRenameId: item.id,
      projectInputName: item.name
    }, () => {
      if (this.inputProjectReNameRef) {
        const { input } = this.inputProjectReNameRef;
        input.focus();
      }
    });
  }

  cancelProjectName = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectRenameId: null,
      projectInputName: null,
      projectRenameError: null
    })
  }

  ProjectNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      projectInputName: e.target.value,
      projectRenameError: null
    })
  }

  changeProjectName = (e, name, item) => {
    e.stopPropagation();
    debounce(() => {
      if (name === item.name) {
        this.setState({
          projectRenameId: null,
          projectInputName: null,
          projectRenameError: null
        });
        return;
      }
      const error = checkNameFormat(name);
      if (error) {
        this.setState({
          projectRenameError: error
        });
        return;
      }
      const { projectList } = this.props;
      const _filter = projectList.filter(project => project.id !== item.id);
      const exsitV = _filter.map(item => item.name);
      if (exsitV.includes(name)) {
        this.setState({
          projectRenameError: 'Project:' + name + ' already exists!'
        });
        return;
      }
      this.setState({
        projectRenameId: null,
        projectInputName: null,
        projectRenameError: null
      });
      this.props.SaveProjectName(name, item);
    }, 1000, true, 'pdnProjectName')();
  }

  downloadFile = (e, item) => {
    e.stopPropagation();

    this.setState({
      downloadProgress: 0,
      downloadVisible: item.id
    })

    this.Time = setInterval(() => {
      let progress = this.state.downloadProgress;
      progress += 4;
      if (progress > 98) {
        progress = 98;
      }
      this.setState({
        downloadProgress: progress
      })

    }, 800);

    if (item.dataType === IntelSPIM_FILE) {
      let id = item.id, name = item.name;
      const token = localStorage.getItem('token');
      const url = `/api/v3/library/libraryId/${id}/download/file?fileName=${name}&access_token=${token}`;
      const fileName = `${name}.zip`;
      this.getZipBlob(url, fileName).then(blob => {
        FileSaver.saveAs(blob, fileName);
      });

    } else if (item.dataType === DECAP_FILE || item.dataType === DECAP_TOUCHSTONE) {
      let filename;
      filename = item.fileName
      if (item.type === "folder") {
        filename = `${item.fileName}.zip`
      }
      const token = localStorage.getItem('token');
      const url = `/api/v3/library/${item.id}/file/download?fileName=${filename}&access_token=${token}`;
      this.getZipBlob(url, filename).then(blob => {
        FileSaver.saveAs(blob, filename);
      });
    }
  }

  getBlob = (url, fileName) => {
    return new Promise(resolve => {
      let that = this;
      const xhr = new XMLHttpRequest();

      xhr.open("GET", url, true);

      xhr.addEventListener(
        "progress",
        function (evt) {
          if (evt.lengthComputable) {
            let percentComplete = evt.loaded / evt.total;
            that.percentage = percentComplete * 100;
            if (that.percentage === 100) {
              clearInterval(that.Time);
              that.setState({
                downloadProgress: that.percentage
              })
              message.success(`Download ${fileName} successfully!`);
              setTimeout(() => {
                that.setState({
                  downloadProgress: -1,
                  downloadVisible: false
                })
              }, 1000);
            }
          }
        },
        false
      );

      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };

      xhr.send();
    });
  }

  getZipBlob = (url, fileName) => {
    return new Promise(resolve => {
      let that = this;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);

      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
            clearInterval(that.Time);
            that.setState({
              downloadProgress: 100
            })
            setTimeout(() => {
              that.setState({
                downloadProgress: -1,
                downloadVisible: false
              })
            }, 1000);
            message.success(`Download ${fileName} successfully!`);
          } else {

            clearInterval(that.Time);
            that.setState({
              downloadProgress: -1,
              downloadVisible: false
            })
            message.error(`Download ${fileName} failed!`);
          }
        }

      };
      xhr.send();
    });
  }

  openLogPanel = async (e, item) => {
    e && e.stopPropagation();
    const { id } = item;
    const logList = await getNewSPIMLog(id);
    const logs = (logList || []).join('\n');
    this.props.openShowPanel(logs, true);
  }

  renderTitle = (item) => {
    switch (item.dataType) {
      case PROJECT_CREATE:
        return this.projectCreate(item);
      case PDN_CREATE:
        return this.pdnCreate(item);
      case PDN:
        return this.PDNTitle(item);
      case LIBRARY:
      case PROJECTS:
      case MY_LIBRARY:
      case LIBRARYS:
      case PCBs:
      case Packages:
      case PDNs:
      case TRASH:
        return this.outTitle(item);
      case PROJECT:
        return this.ProjectTitle(item);
      case IntelSPIM_FILE:
      case DECAP_FILE:
      case DECAP_TOUCHSTONE:
        return this.libraryTitle(item);
      default:
        return this.defaultTitle(item);
    }
  }

  ProjectTitle = (item) => {
    const { projectRenameId, projectInputName, projectRenameError } = this.state;
    const Keys = IconKey[getIconKey(item.dataType)];
    return projectRenameId && projectRenameId === item.id ?
      <span className='project-rename-input' onClick={(e) => { this.inputClick(e) }}>
        <Tooltip
          className='aurora-error-tooltip'
          title={projectRenameError ? projectRenameError : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={projectRenameError ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            value={projectInputName}
            className='project-name'
            onChange={(e) => this.ProjectNameChange(e)}
            ref={(input) => { this.inputProjectReNameRef = input; }}
            onPressEnter={(e) => this.changeProjectName(e, e.target.value, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => this.cancelProjectName(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={(e) => this.changeProjectName(e, projectInputName, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
      :
      <span>
        <Tooltip
          key={item.key}
          placement="topLeft"
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          className='tooltip-span'
          overlayClassName='aurora-tooltip'
        >
          <span className='pdn-project-title' onDoubleClick={(e) => this.renameProject(e, item)}>{item.name}</span>
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </span>;
  }

  PDNTitle = (item) => {
    let type = item.status
    const { preparingPDN } = this.props;
    let _className = ''
    if (!item.libraryId) {
      type = PDN;
    }
    preparingPDN.forEach(id => {
      if (item.id === id) type = PRE_PDN;
    })
    if (type === PRE_PDN) {
      _className = 'pdn-tooltip-span pdn-preparing'
    } else if (type === WARNING_PDN) {
      _className = 'pdn-tooltip-span pdn-warning'
    } else {
      _className = 'pdn-tooltip-span'
    }
    const Keys = IconKey[getIconKey(type)];
    return <span>
      <Tooltip placement="topLeft" mouseEnterDelay={0.8} mouseLeaveDelay={0} title={item.name} className={_className} overlayClassName={type === WARNING_PDN ? 'aurora-tooltip pdn-warning-tooltip' : 'aurora-tooltip'}>{item.name}</Tooltip>
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  inputNameChange = (e) => {
    this.setState({
      projectErrorMsg: null,
      inputValue: e.target.value
    })
  }

  inputPDNNameChange = (e) => {
    this.props.PDNNameChange(e.target.value);
  }

  projectCreate = (item) => {
    const { inputValue, projectErrorMsg } = this.state;
    return (
      <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={projectErrorMsg ? projectErrorMsg : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={projectErrorMsg ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            defaultValue={inputValue}
            className='project-name'
            onFocus={(e) => this.inputNameChange(e)}
            onBlur={(e) => this.inputNameChange(e)}
            ref={(input) => { this.inputRef = input; }}
            onPressEnter={(e) => this.pdnProjectCreate(e, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => this.cancelCreateProject(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={(e) => this.pdnProjectCreate(e, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
    );
  }

  pdnProjectCreate = (e, item) => {
    e.stopPropagation();
    const { inputValue } = this.state;
    const name = this.inputRef && this.inputRef.input ? this.inputRef.input.value : inputValue;
    debounce(() => {
      item.name = name;
      const error = checkNameFormat(name);
      if (error) {
        this.setState({
          projectErrorMsg: error
        });
        return;
      }
      item.name = name;
      this.setState({
        projectErrorMsg: null
      }, () => {
        this.props.createProject(e, item)
      })
    }, 1000, true, 'pdnCreate')();
  }

  cancelCreateProject = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectErrorMsg: null
    });
    this.props.deleteCreateProject(e, item);
  }

  getOptions = () => {
    const { currentProjectPackages, SPIMNames, currentProjectPDNs } = this.props;
    if (!currentProjectPackages.length || !SPIMNames.length) {
      return null;
    } else {
      const libraryId = currentProjectPackages[0].libraryId;
      const libraryInfo = SPIMNames.find(item => item.id === libraryId);
      const existPDNs = currentProjectPDNs.map(item => item.name);
      let PowerNets = libraryInfo ? (libraryInfo.children || [{ name: libraryInfo.name }]) : [];
      PowerNets = PowerNets.filter(item => !existPDNs.includes(item.name));
      if (!PowerNets.length) {
        return null;
      } else {
        return PowerNets;
      }
    }
  }

  pdnInputName = (item) => {
    const { inputPDNName } = this.state;
    const value = this.inputRef && this.inputRef.input ? this.inputRef.input.value : inputPDNName;
    this.pdnSelection(value, item)
  }

  pdnSelection = (value, item) => {
    this.setState({
      pdnSelect: undefined,
      inputPDNName: null
    }, () => {
      console.log(value, item)
      this.props.createPDN(value, item)
    })
  }

  pdnCreate = (item) => {
    const powerNets = this.getOptions();
    const { deletePDNCreate } = this.props;
    const { pdnSelect, inputPDNName } = this.state;
    if (!powerNets) {
      return (
        <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
          <Input
            defaultValue={inputPDNName}
            className='project-name'
            onFocus={(e) => this.inputPDNNameChange(e)}
            onBlur={(e) => this.inputPDNNameChange(e)}
            ref={(input) => { this.inputRef = input; }}
            onPressEnter={(e) => this.pdnInputName(item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => deletePDNCreate(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={() => this.pdnInputName(item)} />
            </Fragment>}
          />
        </span>
      );
    }
    return <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
      <Select
        placeholder='Power Domain'
        value={pdnSelect}
        onChange={(value) => this.pdnSelection(value, item)}
        size='small'
        className='pdn-create-name'
        ref={(select) => { this.selectRef = select; }}
        popupClassName='pdn-create-dropdown-menu'
      >
        {powerNets.map((power) => <Option key={power.name} value={power.name} title={power.name}>{power.name}</Option>)}
      </Select>
    </span>
  }

  libraryTitle = (item) => {
    const { defaultDecap } = this.props;
    const Keys = IconKey[getIconKey(item.dataType)];
    const { downloadProgress, downloadVisible } = this.state;
    let className_decap = '', name = item.name;
    if (item.dataType === 'decap_file' && item.id === defaultDecap.id) {
      className_decap = 'fastpi-library-span tooltip-span decap-default-title';
    } else {
      className_decap = 'fastpi-library-span tooltip-span';
    }
    if (item.dataType === 'decap_file' || item.dataType === 'decap_data' || item.dataType === 'decap_touchstone') {
      name = item.fileName
    }
    if (item.status === SPIM_MISSING_FILE) {
      className_decap = `${className_decap} fastpi-missing-spim-span`;
    }
    return (
      <span className={downloadVisible === item.id ? (downloadProgress === 100 ? 'download-progress-bar download-progress-bar-success' : 'download-progress-bar') : ""} style={{ "--percent": downloadProgress }}>
        <div className='tree-library-content'>
          <Tooltip
            placement="topLeft"
            mouseEnterDelay={0.8}
            mouseLeaveDelay={0}
            title={item.fileName ? item.fileName : item.name}
            className={
              item.dataType !== IntelSPIM_FILE ?
                `pdn-icon-color ${className_decap}` : className_decap}
            overlayClassName='aurora-tooltip'
            onClick={(e) => this.FileOperation(e, item)}
          >{name}
          </Tooltip>
          {item.status === SPIM_MISSING_FILE &&
            <Tooltip title="SPIM is incomplete" overlayClassName='aurora-tooltip aurora-design-tooltip'>
              <ExclamationCircleOutlined className="debug-exclamation-SPIM-icon debug-exclamation-SPIM-icon-hover" />
            </Tooltip>
          }
          {item.dataType === 'decap_file' && item.id === defaultDecap.id && downloadVisible !== item.id ?
            <Tag color="#fff" className="fastpi-decap-default">Default</Tag> : null}
          <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
        </div>
      </span>
    );
  }

  changeDecap = (item) => {
    const { DecapNames } = this.props;
    if (item && item.id) {
      this.props._setDefaultDecap(item.id);
    } else {
      let _decap = DecapNames.filter(item => item.type === 'file');
      if (_decap.length === 1) {
        message.info('You need to specify a decap library as the default!');
      } else {
        this.props.delDefaultDecap();
      }
    }
  }

  defaultTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    if (item.dataType === RESULT) {
      return <span className='result-style'>{item.name}
        {Keys.map((key, index) => { return this.getIcon(key, item) })} </span>
    }

    if (item.dataType === PCB) {
      const _keys = item.category === DESIGN_FAILED ? Keys.filter(key => key !== LAYOUT_CHECKOUT) : Keys;
      return (
        <span>
          <Tooltip
            placement="topLeft"
            mouseEnterDelay={0.8}
            mouseLeaveDelay={0}
            title={item.name}
            className={item.category === DESIGN_FAILED ? 'PDN-debug-tooltip-span' : 'pdn-success-tooltip-span'}
            overlayClassName='aurora-tooltip'
          >{item.name}
          </Tooltip>
          {item.category === DESIGN_FAILED &&
            <Tooltip title="PCB upload failed" overlayClassName='aurora-tooltip aurora-design-tooltip'>
              <ExclamationCircleOutlined className="debug-design-icon" />
            </Tooltip>
          }
          <Fragment>{_keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
        </span>
      );
    }
    let errorClassName = "";
    if (item.dataType === Package && item.libraryId) {
      const findSPIM = this.props.SPIMNames.find(it => it.id === item.libraryId)
      errorClassName = !findSPIM ? "pdn-package-library-error-span" : "";
    }
    return <span>
      <Tooltip
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={errorClassName ? `Library SPIM "${item.model}" does not exist.` : (item.fileName ? item.fileName : item.name)}
        className={
          item.dataType !== IntelSPIM_FILE && item.dataType !== LIBRARY_FILE ?
            'pdn-icon-color tooltip-span' : 'tooltip-span'}
        overlayClassName='aurora-tooltip'
        onClick={(e) => this.FileOperation(e, item)}
      >
        <span className={errorClassName ? errorClassName : ""}>{item.name}</span>
      </Tooltip>
      {item.dataType === LIBRARY_FILE && this.librarySPIMStatus(item)}
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  librarySPIMStatus = (item) => {
    if (item.status === SPIM_MISSING_FILE) {
      return (
        <Tooltip title={item.msg} overlayClassName='aurora-tooltip aurora-design-tooltip'>
          <ExclamationCircleOutlined className="debug-exclamation-SPIM-icon" />
        </Tooltip>
      );
    } else if (item.status === SPIM_UNAVAILABLE) {
      return (
        <Tooltip title={item.msg} overlayClassName='aurora-tooltip aurora-design-tooltip'>
          <QuestionCircleOutlined className="debug-question-SPIM-children-icon" />
        </Tooltip>
      );
    } else return null;
  }

  FileOperation = (e, item) => {
    /*  e.stopPropagation(); */
    if (item.type === "folder") {
      return;
    }
    if (item.dataType === DECAP_FILE) {
      this.props.showDecapFile(e, item);
      return;
    };
    if (item.dataType === DECAP_DATA || item.dataType === VRM_DATA || item.dataType === Package) {
      this.props.editItem(e, item.key, item);
      return;
    };
    if (item.dataType === POWER_DOMAIN_FILE) {
      this.props.showPowerDomainFile(e, item);
      return;
    }
    if (item.dataType === POWER_DOMAIN_TOUCHSTONE) {
      this.props.showPowerDomainTouchstone(e, item);
    }
    if (item.dataType === DECAP_TOUCHSTONE || item.dataType === "decap_touchstone_file") {
      this.props.showDecapTouchstone(e, item)
    }
    return;
  }

  outTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    return <span>
      <span className='out-title-color'>{item.name}</span>
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  openProject = (id) => {
    this.props.openProject(id);
    this.props.closePackagePanel();
  }

  getPopMessage = (type, dataType) => {
    switch (dataType) {
      case PROJECT:
      case PDN:
        return `Can not be restored after deleting the ${type}. Are you sure you want to delete it?`;
      case TRASH:
        return "Can not be restored after clearing trash. Are you sure you want to delete it?"
      case PDNs:
        return "These PDNs cannot be recovered once deleted. Are you sure that you want to proceed?"
      default:
        return `Once the ${type} is deleted, all the PDN setup will be ${dataType === Package ? 'invalidated' : 'gone'}.`;
    }
  }

  _deleteClick = (e, data) => {
    e.stopPropagation();
    this.setState({
      delConfirmInfo: data,
      delConfirmVisible: true
    })
  }

  deleteConfirm = (e, data) => {
    e.stopPropagation();
    this.props.delItem(e, data);
    this.setState({
      delConfirmInfo: null,
      delConfirmVisible: false
    })
  }

  batchDeleteClick = (e, key, item) => {
    e.stopPropagation();
    this.setState({
      delConfirmInfo: item,
      delConfirmVisible: true
    })
  }

  cancelDel = (e) => {
    e.stopPropagation();
    this.setState({
      delConfirmInfo: null,
      delConfirmVisible: false
    })
  }

  onExpand = (keys) => {
    const { expandedKeys, treeSelectedKeys } = this.props;
    const openNew = keys.filter(key => !expandedKeys.includes(key));

    if (openNew.length) {
      const [key, id] = strDelimited(openNew[0], "-");

      if (key === PROJECT) {
        if (keys && keys.length > 0) {
          keys = keys.filter(item => {
            if (item) {
              const [_key, _id] = strDelimited(item, "-");
              if (!(_key === PROJECT && _id !== id)) {
                return item;
              }
            };
            return false;
          });
        }

        if (treeSelectedKeys.length > 0) {
          this.props.changeTreeSelected([]);
        };

        this.props.clearCurrentProject();
        this.props.changeViewList([]);
        this.props.closePackagePanel();
        this.props.closeTabFooter();
        this.openProject(id);
      }
    } else {
      const closeNode = expandedKeys.filter(key => !keys.includes(key));

      if (closeNode.length) {
        const [key] = strDelimited(closeNode[0], "-");

        if (key === PROJECT) {
          this.props.changeTreeSelected([]);
          this.props.changeViewList([]);
          this.props.clearCurrentProject(true);
          this.props.closePackagePanel();
          this.props.closeTabFooter();
        }
      }
    }

    this.props.expandMenu(keys);
  };

  onSelect = (selectedKeys, e) => {
    count += 1;
    setTimeout(() => {
      if (count === 1) {
        //expand current click node
        const { expandedKeys } = this.props;
        let keys = [...expandedKeys];
        const { node } = e;
        if (node.dataRef.category === DESIGN_FAILED) { return }
        if (node && node.dataRef && node.dataRef.key && node.dataRef.children) {
          const currentClickKey = node.dataRef.key;
          if (keys.includes(currentClickKey)) {
            keys = keys.filter(key => key !== currentClickKey);
          } else {
            keys.push(currentClickKey)
          }

          keys = [...new Set([...keys])];
          this.onExpand(keys)
        }

        //select node
        if (!selectedKeys.length || selectedKeys.length < this.props.treeSelectedKeys.length) {
          count = 0;
          return;
        }

        let key, id, verificationId, currentIndex = selectedKeys.length - 1;
        [key, id, verificationId] = strDelimited(selectedKeys[currentIndex], "-");

        if (key === 'PDN' || key === 'result' || key === 'PCB') {

          if (selectedKeys.length === 1) {
            this.props.changeTreeSelected([...selectedKeys]);
          } else {
            let selected = selectedKeys.filter((item, index) => index < currentIndex);
            let RIndex = -1;
            for (let i = 0; i < selected.length; i++) {
              const item = selected[i];
              if (item) {
                const [keyed] = strDelimited(item, "-");
                if (key !== 'PCB' && keyed !== 'PCB') {
                  RIndex = i;
                  break;
                }
              }
            };
            if (RIndex > -1) {
              selected[RIndex] = selectedKeys[currentIndex];
            } else {
              selected.push(selectedKeys[currentIndex])
            }

            this.props.changeTreeSelected([...selected])
          }
        }
        switch (key) {
          case PCB:
            this.props.openPCB(id);
            break;
          case PDN:
            this.props.openPDN(id);
            break;
          case RESULT:
            this.props.showPdnResult(id, verificationId);
            break;
          default: break;
        }
      }
      count = 0;
    }, 500);
  };

  updateProjectMenu = () => {
    const { currentProjectId } = this.props
    this.props.UpdateProjectMenu({ openProjectId: currentProjectId })
  }

  render() {
    const { treeItems, expandedKeys, treeSelectedKeys } = this.props;
    const { delConfirmVisible, delConfirmInfo, openSaveHistoryPrompt, saveHistoryPDNInfo, saveHistoryMsg, projectRenameId } = this.state;
    const type = delConfirmInfo ? this.getType(delConfirmInfo.dataType) : "";
    let errorMsg = delConfirmInfo ? this.getPopMessage(type, delConfirmInfo.dataType) : "";
    return (
      <Fragment>
        <TreeForm
          treeData={treeItems}
          renderTitle={this.renderTitle}
          onExpand={this.onExpand}
          defaultExpandedKeys={['library', 'projects']}
          expandedKeys={expandedKeys}
          onSelect={this.onSelect}
          selectedKeys={treeSelectedKeys}
          autoExpandParent={false}
          defaultExpandParent={false}
          updateProjectMenu={this.updateProjectMenu}
          disableDragProjectId={projectRenameId}
        />
        {delConfirmVisible ? <DelConfirm
          data={delConfirmInfo}
          deleteConfirm={this.deleteConfirm}
          cancelDel={this.cancelDel}
          message={errorMsg}
        /> : null}
        {openSaveHistoryPrompt ? <DelConfirm
          type={"simulate"}
          data={saveHistoryPDNInfo}
          onSimulate={this._onSimulate}
          cancelSim={this.clearSaveHistoryPrompt}
          message={saveHistoryMsg}
        /> : null}
      </Fragment>
    )
  }
}


const mapState = (state) => {
  const { PDNReducer: { pdn, project, simulationReducer }, LayoutReducer: { simulationDesigns } } = state;
  const { treeItems, currentProjectPackages = [], SPIMNames = [], DecapNames = [], PDNID, verificationId, treeSelectedKeys, currentProjectPDNs = [], projectList = [], defaultDecap, preparingPDN, currentProjectId } = project;
  const { verifyDisabled, selectedKeys } = simulationReducer;
  const { verificationName } = state.TabMonitorReducer;
  return {
    expandedKeys: pdn.expandedKeys,
    treeItems,
    currentProjectPackages,
    SPIMNames,
    verifyDisabled,
    selectedKeys,
    PDNID,
    verificationId,
    treeSelectedKeys,
    verificationName,
    currentProjectPDNs,
    projectList,
    preparingPDN,
    defaultDecap,
    DecapNames,
    currentProjectId,
    simulationDesigns
  }
}

const mapDispatch = (dispatch) => ({
  expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  UpdateLibraryMenu() {
    dispatch(updateLibraryMenu())
  },
  UpdateProjectMenu(obj) {
    dispatch(updateProjectMenu(obj))
  },
  openProject(id) {
    dispatch(openProject(id));
  },
  clearCurrentProject(status) {
    dispatch(clearCurrentProject(status));
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  updateTrashMenu() {
    dispatch(updateTrashMenu());
  },
  changeTreeSelected(treeSelectedKeys) {
    dispatch(changeTreeSelected(treeSelectedKeys))
  },
  changeVerification(verificationName) {
    dispatch(changeVerification(verificationName));
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList))
  },
  _setDefaultDecap(libraryId) {
    dispatch(setDefaultDecap(libraryId));
  },
  delDefaultDecap() {
    dispatch(delDefaultDecap());
  },
  projectMenu({ treeItems }) {
    dispatch(projectMenu({ treeItems }));
  },
  startLayoutCheck(designItem) {
    dispatch(startLayoutCheck(designItem))
  }
});

export default connect(mapState, mapDispatch)(PDNTree);