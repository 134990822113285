export const LOCATION_CHANGE = 'location';
export const NET_CHANGE = 'net_change';
export const LAYER_CHANGE = 'layer_change';
export const COMP_CHANGE = 'comp_change';
export const CLEAR_SELECTION = 'clear_selection';
export const OPEN_PANEL = 'open_panel';
export const CLOSE_PANEL = 'close_panel';
export const SELECT_SIGNAL = 'select_signal';
export const CREATE_SIGNAL = 'create_signal';
export const CANCEL_SIGNAL = 'cancel_signal'
export const COLORBY_CHANGE = 'colorby_change';
export const OPEN_DESIGN_INFO_PANEL = 'open_design_info_panel';
export const CLOSE_DESIGN_INFO_PANEL = 'close_design_info_panel';
export const SIMULATION_FINISHED = 'simulation_finished';
export const SELECTION_CLEAR = 'selection_clear';
export const SELECTED_ALL = 'selected_all';
export const CANCEL_SELECTED_ALL = 'cancel_selected_all';
export const SHOW_SELECTED = 'show_selected';
export const SELECTION_CHECKBOX_CHANGE = 'selection_checkbox_change';
export const CLEAN_STATUS = 'clean_status';
export const CHANGE_SELECTED_DISPLAY = 'change_selected_display';
export const SELECT_CHANGE = `select_change`;