import http from "../http";


/**
 * upload prelayout file (.xlsx, .xls)
 * 2021.08.27
 * @param {file} file 
 * @param {string} designId
 *  */
function prelayoutImport({ file, designId }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('designId', designId);
  return http.post(`excel/prelayout/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * export prelayout file (.xlsx, .xls)
 * 2021.08.27
 * @param {string} designId
 *  */
function prelayoutExport(designId) {
  return http.get(`excel/prelayout/export?designId=${designId}`, {
    responseType: 'arraybuffer'
  })
}


export {
  prelayoutImport,
  prelayoutExport
}