
import { strDelimited } from './split';
export function getPCBCompAndPin(name) {
  let newName = name.replace(/_in[)]$|_in$/, '');
  //pcb3985054829392_int0400_tp548_u_in
  let pcbInfo = strDelimited(newName, '_', { returnIndex: 0 });
  let pinName = strDelimited(newName, '_', { returnIndex: -1 });
  let firstIncludePCB = true, hasPkg = false;
  if (pinName === 'u') {
    hasPkg = true;
  }
  let compName = newName.replace(`${pcbInfo}_`, '');
  if (!pcbInfo.includes('pcb')) {
    firstIncludePCB = false;
    const ndEnList = newName.split('_');
    if (ndEnList[1] === 'en') {
      pcbInfo = strDelimited(newName, '_', { returnIndex: 2 });
      compName = newName.replace(`nd_en_${pcbInfo}_`, '');
    }
  };
  if (hasPkg) {
    compName = compName.slice(0, compName.length - 2);
  }
  return { pcbInfo, compPinName: compName, firstIncludePCB }
}