import React, { Component } from "react";
import Panel from "../Panel/Panel";
import { createPortal } from "react-dom";
import { TreeSelect } from "antd";
import { getPanelWidth, getPanelMaxWidth, getPanelMaxHeight } from "../../services/helper/panelSizeHelper";
import "./index.css";

const { SHOW_PARENT } = TreeSelect;

class CopySetupPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dialogRoot = document.getElementById("root");
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();

    this.setState({
      maxWidth: getPanelMaxWidth(offset, 500),
      maxHeight: getPanelMaxHeight(offset, 400),
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
  }

  render = () => {
    const { maxWidth, maxHeight } = this.state;
    const { title, children, typeTitle, treeData, selectedValue, placeholder } = this.props;
    const content = (
      <Panel
        title={title}
        className="copy-setup-panel"
        onCancel={this.props.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 500 })}
        position='panel-center-left'
        draggable
        minHeight={220}
        maxWidth={maxWidth}
        minWidth={300}
        maxHeight={maxHeight}
        overflow={true}
      >
        <div className="copy-setup-panel-content">
          {children}
          <div className="copy-setup-panel-footer">
            <span>Apply {typeTitle} to</span>
            <TreeSelect
              treeData={treeData || []}
              value={selectedValue || []}
              onChange={this.props.onTreeChange}
              treeCheckable={true}
              showCheckedStrategy={SHOW_PARENT}
              placeholder={placeholder || 'Select signals'}
              className='aurora-select'
              getPopupContainer={() => document.getElementById('root')}
              popupClassName='copy-setup-panel-select-dropdown-menu'
            />
          </div>
        </div>

      </Panel>)
    return createPortal(content, this.dialogRoot)
  }
}

export default CopySetupPanel;