import React, { Component } from 'react';
import './index.css';

class ProbePinsPoint extends Component {

  render() {
    const { probePinsList, selectProbePin } = this.props;

    return probePinsList.map(item => {
      const leftPins = item.leftPins;
      const rightPins = item.rightPins;
      const height = leftPins.length > rightPins.length ? leftPins.length * 32 : rightPins.length * 32;
      return <div className='probe-pins-content' key={item.component} style={{ height: height }}>
        <div className='probe-pins-component-pin-left'>
          {leftPins.map((pin, index) => (
            <div
              className={pin.selected ? 'probe-pins-component-pin probe-pins-component-pin-selected' : 'probe-pins-component-pin'}
              key={pin.pin}
            >
              {pin.net ? <span className='probe-pins-net'>{pin.net}</span> : null}
              <div className='probe-pins-line'></div>
              <div
                className={pin.signal ? 'probe-pins-circle' : 'probe-pins-circle probe-pins-circle-not-allowed'}
                onClick={pin.signal ? () => selectProbePin({ pin: pin.pin, component: item.component }) : null}>
              </div>
              <span className={pin.signal ? 'probe-pins-pin' : 'probe-pins-pin probe-pins-pin-not-allowed'}>
                <span onClick={pin.signal ? () => selectProbePin({ pin: pin.pin, component: item.component }) : null}>{pin.pin}</span>
              </span>
            </div>
          ))}
        </div>
        <div className='probe-pins-component' style={{ lineHeight: `${height - 4}px` }}>{item.component} </div>
        <div className='probe-pins-component-pin-right'>
          {rightPins.map((pin, index) => (
            <div
              className={pin.selected ? 'probe-pins-component-pin probe-pins-component-pin-selected' : 'probe-pins-component-pin'}
              key={pin.pin}
            >
              <span className={pin.signal ? 'probe-pins-pin' : 'probe-pins-pin probe-pins-pin-not-allowed'}>
                <span onClick={pin.signal ? () => selectProbePin({ pin: pin.pin, component: item.component }) : null}>{pin.pin}</span>
              </span>
              <div
                className={pin.signal ? 'probe-pins-circle' : 'probe-pins-circle probe-pins-circle-not-allowed'}
                onClick={pin.signal ? () => selectProbePin({ pin: pin.pin, component: item.component }) : null}>
              </div>
              <div className='probe-pins-line'></div>
              {pin.net ? <span className='probe-pins-net'>{pin.net}</span> : null}
            </div>
          ))}
        </div>
      </div>
    })
  }
}

export default ProbePinsPoint;