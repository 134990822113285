import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ImpedanceComp, { PreLayoutImpedanceComp } from '../components/Impedance';
import { getImpedance, getInterfacesSignals, getImpedanceSignal } from '@/services/Sierra/results';
import makeCancelable from '@/services/api/makeCancelable';
import { SweepResultData } from '@/services/Sierra';
import NP from 'number-precision';
import { getPreImpedanceResultBySignals, getPreLayoutInterfaceSignalNets, getSchematicImpedance } from '../../../../services/Sierra/results';
import designConstructor from '../../../../services/helper/designConstructor';
import { SCHEMATIC } from '../../../../services/Sierra/prelayout/prelayoutConstants';
class ImpedanceResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      resultList: []
    }
  }

  componentDidMount = () => {
    const { sweepId } = this.props;

    if (sweepId) {
      this.getData()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { sweepId, resultList } = this.props;
    if (prevProps.sweepId !== sweepId) {
      this.getData()
    }

    if (resultList.length !== prevProps.resultList.length) {
      this.getData(true)
    }
  }

  getData = (keep = false) => {
    const newResultList = keep ? this.keepResult() : this.props.resultList;
    this.setState({
      resultList: JSON.parse(JSON.stringify(newResultList))
    }, () => {
      this.getSelectedKey();
    })
  }

  keepResult = () => {
    let newResultList = JSON.parse(JSON.stringify(this.props.resultList));
    const { resultList } = this.state;
    return newResultList.map(item => {
      const current = resultList.find(r => r.id === item.id);
      return current ? current : item
    })
  }

  componentWillUnmount() {
    if (this.cancelable) {
      this.cancelable.cancel();
    }
  }

  getSelectedKey = async () => {
    const { currentProjectId } = this.props;
    const { resultList } = this.state;
    const newResultList = [];
    for (let exp of resultList) {
      const res = await SweepResultData.getVerificationJsonPromise(exp.id, currentProjectId);
      if (res) {
        let currentInterfaces = SweepResultData.getInterfaces(exp.id);
        let designList = [], hasPreLayout = false;
        currentInterfaces && currentInterfaces.forEach(item => {
          const isPreLayout = designConstructor.isPreLayout(item.PCBID)
          designList.push({
            pcbSubId: item.pcbId,
            name: item.pcb,
            pcbId: item.PCBID,
            isPreLayout
          });
          if (isPreLayout) {
            hasPreLayout = true
          }
        });
        let preLayoutInfoList = [];
        try {
          preLayoutInfoList = hasPreLayout ? await SweepResultData.getPreLayoutJsonByVerificationId(exp.id, exp.verificationId) : [];
        } catch (error) {
          console.error(error);
        }
        designList = designList.map(item => {
          if (item.isPreLayout) {
            const findInfo = preLayoutInfoList.find(it => it.subId === item.pcbSubId || it.id === item.pcbId || it.designId === item.pcbSubId);
            item.isSchematic = findInfo && findInfo.content && findInfo.content.prelayout === SCHEMATIC;
          }
          return item;
        });
        designList = designList.filter(item => !item.isPreLayout || item.isSchematic);
        const selectedKey = (designList && designList.length) ? 0 : null;
        newResultList.push({ ...exp, selectedKey, designList, units: {} });
      } else {
        newResultList.push({ ...exp, selectedKey: null, designList: [], units: {} });
      }
    }
    this.setState({
      resultList: newResultList
    }, () => {
      this.getWholeImpedanceData()
    })
  }

  getWholeImpedanceData = async () => {
    const { resultList } = this.state;
    const newResultList = [];
    for (let result of resultList) {
      const { id, selectedKey } = result;
      let impedanceData = {}, units = { stackupUnit: "", lengthUnit: "", widthUnit: "" };
      if (result.impedanceData) {
        impedanceData = result.impedanceData;
        units = result.units || units;
      } else {
        const { impedanceData: _impedanceData, units: _units } = await this.getImpedanceData(id, selectedKey);
        impedanceData = _impedanceData;
        units = _units;
      }
      newResultList.push({ ...result, impedanceData: impedanceData ? impedanceData : {}, units })
    }
    this.setState({
      resultList: newResultList,
      loading: false
    })
  }

  getImpedanceData = async (key, selectedKey) => {
    const { currentProjectId } = this.props;
    let currentInterfaces = SweepResultData.getInterfaces(key);
    if (!currentInterfaces || !currentInterfaces.length) {
      await SweepResultData.getVerificationJsonPromise(key, currentProjectId);;
      currentInterfaces = SweepResultData.getInterfaces(key)
    }

    let interfaceName = ''
    if (currentInterfaces && currentInterfaces.length > 0) {
      interfaceName = currentInterfaces[0].name;
    }
    const { resultList } = this.state;
    const current = resultList.find(item => item.id === key) || {};
    let designList = current ? current.designList || [] : [];

    if (designList && designList[selectedKey] && designList[selectedKey].isSchematic) {
      const { impedanceData, units } = await this.getPreImpedanceData({
        key,
        interfaceName,
        designList,
        selectedKey,
        currentInterfaces,
        current
      })
      return { impedanceData, units }
    } else {
      const { impedanceData, units } = await this.getPostImpedanceData({
        key,
        interfaceName,
        designList,
        selectedKey,
        currentInterfaces,
        current
      })
      return { impedanceData, units }
    }
  }

  getPostImpedanceData = async ({ key, interfaceName, designList, selectedKey, currentInterfaces, current }) => {
    const { currentProjectId } = this.props;
    try {
      const promise = getImpedance({
        experimentId: key,
        verificationName: interfaceName,
        design: designList ? designList[selectedKey] : {},
        Interfaces: currentInterfaces,
        projectId: currentProjectId
      });
      const signals = getInterfacesSignals(currentInterfaces);
      this.cancelable = makeCancelable(promise);
      let impedance = []
      const response = await this.cancelable.promise;
      impedance = await getImpedanceSignal(response, signals);
      let _units = { stackupUnit: impedance.stackupUnit, lengthUnit: impedance.unit, widthUnit: impedance.unit };
      return { impedanceData: impedance, units: _units };
    } catch (error) {
      console.error(error);
      return { impedanceData: [], units: {} };
    }
  }

  getPreImpedanceData = async ({ key, interfaceName, designList, selectedKey, currentInterfaces, current, isUpdateUnit }) => {
    const { currentProjectId } = this.props;
    try {
      const promise = getSchematicImpedance({
        verificationName: interfaceName,
        verificationId: current.verificationId,
        design: designList[selectedKey],
        Interfaces: currentInterfaces,
        projectId: currentProjectId,
        experimentId: key
      });
      this.cancelable = makeCancelable(promise);
      const response = await this.cancelable.promise;
      const signalNets = getPreLayoutInterfaceSignalNets({ Interfaces: currentInterfaces, verificationName: interfaceName, name: designList[selectedKey] ? designList[selectedKey].name : "" });
      const { impedanceData = [], unit = "mil" } = response || {};
      const _impedanceData = getPreImpedanceResultBySignals(impedanceData, signalNets);
      return { impedanceData: { preLayoutImpData: _impedanceData }, units: { length: unit, width: unit, thickness: unit } };
    } catch (error) {
      console.error(error);
      return { impedanceData: [], units: {} };
    }
  }

  handleChange = async (key, id) => {
    const { resultList } = this.state;
    this.setState({
      loading: true
    });
    let { impedanceData, units } = await this.getImpedanceData(id, key, true)
    const newResult = resultList.map(item => item.id === id ? { ...item, selectedKey: key, impedanceData, units } : item);
    this.setState({
      resultList: newResult,
      loading: false
    })
  }


  getScale = (changedUnit, prevUnit) => {
    const scaleObj = {
      mil: {
        mm: 0.0254,
        um: 25.4
      },
      mm: {
        mil: 1 / 0.0254,
        um: 1e3
      },
      um: {
        mil: 1 / 25.4,
        mm: 1 / 1e3
      }
    };
    if (scaleObj[prevUnit] && scaleObj[prevUnit][changedUnit]) {
      return scaleObj[prevUnit][changedUnit];
    }
    return 1;
  }

  updateDataByUnit = ({ _impedanceData, type, unit, prevUnit, units, defaultStackupUnit = "mil", defaultLengthUnit = "mil" }) => {
    let stackupUnit = unit, lengthUnit = unit, widthUnit = unit,
      prevStackupUnit = prevUnit, prevLengthUnit = prevUnit, prevWidthUnit = prevUnit;
    if (!type) {
      stackupUnit = units.stackupUnit;
      lengthUnit = units.unit;
      prevStackupUnit = defaultStackupUnit;
      prevLengthUnit = defaultLengthUnit;
    }
    const stackupScale = this.getScale(stackupUnit, prevStackupUnit);
    const stackupDecimals = stackupUnit === 'um' ? 1 : 4;
    const lengthScale = this.getScale(lengthUnit, prevLengthUnit);
    const decimals = lengthUnit === 'um' ? 1 : 4;
    const widthScale = this.getScale(widthUnit, prevWidthUnit);
    const widthDecimals = widthUnit === 'um' ? 1 : 4;
    _impedanceData.layerStats.forEach(item => {
      if (item.thickness && (!type || type === "stackupUnit")) {
        const newThickness = NP.times(stackupScale, parseFloat(item.thickness));
        item.thickness = parseFloat(newThickness.toFixed(stackupDecimals));
      }

      if (item.w && (!type || type === "widthUnit")) {
        const newWidth = NP.times(widthScale, parseFloat(item.w));
        item.w = parseFloat(newWidth.toFixed(widthDecimals));
      }

      if (item.length && (!type || type === "lengthUnit")) {
        const newLength = NP.times(lengthScale, parseFloat(item.length));
        item.length = parseFloat(newLength.toFixed(decimals));
      }
    })

    _impedanceData.netStats.forEach(item => {
      if (item.length && (!type || type === "lengthUnit")) {
        const newLength = NP.times(lengthScale, parseFloat(item.length));
        item.length = parseFloat(newLength.toFixed(decimals));
        item.layerStats.forEach(it => {
          const _newLength = NP.times(lengthScale, parseFloat(it.length));
          it.length = parseFloat(_newLength.toFixed(decimals));
        })
      }
    })
    return _impedanceData;
  }

  updatePreDataByUnit = ({ _impedanceData, type, unit, prevUnit, units, defaultThicknessUnit = "mil", defaultLengthUnit = "mil", defaultWidthUnit = "mil" }) => {
    let lengthUnit = unit, widthUnit = unit, thicknessUnit = unit,
      prevLengthUnit = prevUnit, prevWidthUnit = prevUnit, prevThicknessUnit = prevUnit;

    if (!type) {
      thicknessUnit = units.thickness;
      lengthUnit = units.length;
      widthUnit = units.width;
      prevThicknessUnit = defaultThicknessUnit;
      prevWidthUnit = defaultWidthUnit;
      prevLengthUnit = defaultLengthUnit;
    }

    const thicknessScale = this.getScale(thicknessUnit, prevThicknessUnit);
    const thicknessDecimals = thicknessUnit === 'um' ? 1 : 4;
    const lengthScale = this.getScale(lengthUnit, prevLengthUnit);
    const decimals = lengthUnit === 'um' ? 1 : 4;
    const widthScale = this.getScale(widthUnit, prevWidthUnit);
    const widthDecimals = widthUnit === 'um' ? 1 : 4;
    _impedanceData.preLayoutImpData.forEach(item => {

      if (item.length && type === "length") {
        const newLength = NP.times(lengthScale, parseFloat(item.length));
        item.length = parseFloat(newLength.toFixed(decimals));
      }

      (item.traceList || []).forEach(child => {
        if (child.thickness && type === "thickness") {
          const newThickness = NP.times(thicknessScale, parseFloat(child.thickness));
          child.thickness = parseFloat(newThickness.toFixed(thicknessDecimals));
        }

        if (child.width && type === "width") {
          const newWidth = NP.times(widthScale, parseFloat(child.width));
          child.width = parseFloat(newWidth.toFixed(widthDecimals));
        }

        if (child.length && type === "length") {
          const newLength = NP.times(lengthScale, parseFloat(child.length));
          child.length = parseFloat(newLength.toFixed(decimals));
        }
      })
    })
    return _impedanceData;
  }

  settingUnitChange = (key, type, id, isPre) => {
    const { resultList } = this.state;
    const index = resultList.findIndex(item => item.id === id);
    if (index < 0) {
      return;
    }
    let _resultList = [...resultList];
    let _impedanceData = { ..._resultList[index].impedanceData };
    const units = _resultList[index].units || {};
    const prevUnit = units[type];
    _impedanceData = isPre ? this.updatePreDataByUnit({
      _impedanceData,
      type,
      unit: key,
      prevUnit,
      units
    }) : this.updateDataByUnit({
      _impedanceData,
      type,
      unit: key,
      prevUnit,
      units
    })
    _resultList[index].units = { ...units, [type]: key };
    _resultList[index].impedanceData = _impedanceData;
    this.setState({
      resultList: _resultList
    })
  }

  preSettingUnitChange = () => {
  }

  render() {
    const { resultList, loading } = this.state;
    return <Fragment>
      <Spin spinning={loading} size='large'>
        {resultList.map(item => {
          const { designList = [], selectedKey = null, impedanceData = {}, id, name, units } = item;
          const curr = designList[selectedKey] || {};
          return curr.isSchematic ? PreLayoutImpedanceComp({
            key: id,
            designList,
            selectedKey,
            impedanceData,
            experimentName: name,
            handleChange: this.handleChange,
            units,
            settingUnitChange: (key, type) => this.settingUnitChange(key, type, id, true),
          }) : ImpedanceComp({
            key: id,
            designList,
            selectedKey,
            impedanceData,
            handleChange: this.handleChange,
            experimentName: name,
            units: units || {},
            settingUnitChange: (key, type) => this.settingUnitChange(key, type, id),
          })
        })}
      </Spin>
    </Fragment>
  }
}

const mapState = (state) => {
  const { resultReducer, project, sierra, sweep } = state.SierraReducer;
  const { currentResultKey } = resultReducer;
  const { sierraInfo } = sierra;
  const { currentProjectId } = project;
  const { experimentInfo } = sweep;
  const { id = "" } = experimentInfo;
  return {
    currentResultKey,
    sierraInfo,
    currentProjectId,
    sweepId: id
  }
}

export default connect(mapState)(ImpedanceResult);