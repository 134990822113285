import React, { Component, Fragment, createRef } from 'react';
import ResultLayout from '@/services/Result/Public/resultLayout';
import WaveformSetting from '@/services/Result/Public/waveform/waveformSetting';
import { getHistoryVerificationJson, getExperimentVerificationJson } from '@/services/Andes_v2/results'
import { getHistoryWaveformResult, getExperimentWaveformResult } from '@/services/Andes_v2/results/waveform';
import WaveformPlot from '@/services/Andes/results/waveformPlot';
import ResultRightMenu from '@/services/Result/Public/resultRightMenu';
import ResultList, { RESULT, HISTORY } from '@/components/Sparameter/resultList';
import { rowDisplayChange } from '@/services/Result/Public/sparameter/resultsMenuHelper';
import { selectedChange, cancelMove } from '@/services/Result/Public/sparameter/dataHelper';
import { Spin, Tabs, Divider } from 'antd';
import getIndex from '@/services/helper/insertionSearch';
import { strDelimited } from '@/services/helper/split';
import { changeCurveColor } from '@/services/Result/Public/sparameter/dataHelper';
import { checkResultStatus, GET, CLEAN } from '@/services/helper/resultStatus';
import { scaleConversion } from '@/services/helper/numberHelper';
import { VERIFY_SUCCESS } from '@/constants/verificationStatus';
import { EXPERIMENTS } from '@/constants/treeConstants';
import { CPHY } from '@/services/PCBHelper/constants';
import PowerSumSetting from './PowerSumSetting';
import DisplayMode from '@/components/Sparameter/displayMode';
import './index.css';
import { ROCKY } from '../../constants/pageType';
import { TOUCHSTONE } from '../../constants/libraryConstants';
import { PRELAYOUT } from '../../services/Rocky/preLayout/preLayoutConfig';

// andes exist experiment and history and normal value
const { TabPane } = Tabs;
const MIXED_MODE_KEY = "MM_POWERSUM", SINGLE_MODE_KEY = "SE_POWERSUM";
const ModeChildren = [
  {
    name: 'NEXT',
    value: 'NEXT'
  },
  {
    name: 'FEXT',
    value: 'FEXT'
  },
  {
    name: 'NEXT + FEXT',
    value: 'XTALK'
  }
]
const modelList = [
  {
    title: 'Single Ended',
    key: SINGLE_MODE_KEY
  },
  {
    title: 'Mixed Mode',
    key: MIXED_MODE_KEY
  }
]
const singleModelList = [{
  title: 'Single Ended',
  key: SINGLE_MODE_KEY
}]

class PowerSumResult extends Component {
  constructor() {
    super()
    this.svgRef = createRef();
    this.state = {
      loading: false,
      resultsObj: {},
      portSelect: {},
      historyObj: {},
      historyFiles: [],
      modeActive: SINGLE_MODE_KEY,
      displayMode: 'NEXT',
      experimentObj: {},
      experimentFiles: [],
      folderList: []
    }
    this.historyContent = new Map();
    this.experimentContent = new Map();
    this.isPreLayout = false;
  }

  getHistoryIds = () => {
    const { historyList } = this.props;
    return historyList.map(d => d.id);
  }

  getExperimentIds = () => {
    const { id, experimentList, status } = this.props;
    const experimentIds = experimentList ? experimentList.map(d => d.id) : []
    if (status === VERIFY_SUCCESS) {
      experimentIds.unshift(id);
    }
    return experimentIds;
  }

  onRef = (ref) => {
    this.waveformSetting = ref;
  }

  componentDidMount() {
    if (this.props.resultPageType) {
      // andes 中sweep的情况下有且为EXPERIMENTS
      this.renderResultExperiment();
    } else if (this.props.id) {
      this.getWaveform();
      !this.props.notDisplayHistory && this.renderResultHistory();
    }
    this.getResultConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, historyList, status, sweepId, sweepStatus, resultPageType, updateExperimentListStatus, reportConfigLoading, reportConfig, notDisplayHistory } = this.props;

    const { displayMode, modeActive } = this.state;
    if (id !== prevProps.id || displayMode !== prevState.displayMode || modeActive !== prevState.modeActive) {
      if (resultPageType === EXPERIMENTS) {
        this.renderResultExperiment();
      } else {
        this.getWaveform();
        !this.props.notDisplayHistory && this.renderResultHistory();
      }

      if (id !== prevProps.id) {
        this.getResultConfig();
      }
    }

    if (!notDisplayHistory) {
      // history
      const historyListIds = this.getHistoryIds();
      const preHistoryListsIds = prevProps.historyList.map(d => d.id);
      const historyListsNames = historyList.map(d => d.name);
      const preHistoryListsNames = prevProps.historyList.map(d => d.name);

      if (historyListIds.length !== preHistoryListsIds.length ||
        historyListIds.some(id => !preHistoryListsIds.includes(id))
      ) {
        this.renderResultHistory();
      }

      // Rename
      if (historyListIds.length === preHistoryListsIds.length &&
        historyListIds.every(id => preHistoryListsIds.includes(id)) &&
        historyListsNames.some(id => !preHistoryListsNames.includes(id))
      ) {
        this.refreshHistoryResultList();
      }
    }

    // status
    if (id === prevProps.id && prevProps.status !== status) {
      const updateStatus = checkResultStatus(prevProps.status, status);
      switch (updateStatus) {
        case GET:
          // reload result data
          this.getWaveform();
          !this.props.notDisplayHistory && this.renderResultHistory();
          break;
        case CLEAN:
          this.initResult();
          break;
        default: break;
      }
    }

    if (sweepId === prevProps.sweepId && prevProps.sweepStatus !== sweepStatus) {
      const updateStatus = checkResultStatus(prevProps.sweepStatus, sweepStatus);
      switch (updateStatus) {
        case GET:
          // reload result data
          this.renderResultExperiment();
          break;
        case CLEAN:
          this.initResult();
          break;
        default: break;
      }
    }

    if ((prevProps.updateExperimentListStatus !== updateExperimentListStatus && updateExperimentListStatus) || updateExperimentListStatus === 'first') {
      this.renderResultExperiment();
      this.props.changeUpdateExperimentListStatus(false);
    }

    if (!reportConfigLoading && reportConfigLoading !== prevProps.reportConfigLoading) {
      const { horizontalLine = [], verticalLine = [] } = reportConfig.powerSumTargetLine || {}
      this.setTargetLinesValue({ horizontalLine, verticalLine })
    }
  }

  componentWillUnmount() {
    this.initResult();
    // save report info to serve
    const { id, isEndToEnd } = this.props;
    this.props._saveReportConfig(id, isEndToEnd);
  }

  refreshHistoryResultList = () => {
    const { historyList } = this.props;
    let { historyFiles } = this.state;
    historyList.forEach(his => {
      const index = historyFiles.findIndex(f => f.id === his.id);
      if (index > -1) {
        historyFiles[index].rowName = his.name;
      }
    });
    this.setState({
      historyFiles
    })
  }

  getCurves = () => {
    return WaveformPlot.getCurves();
  }

  getList = (curve, type) => {
    const { resultsObj, historyObj, displayMode, experimentObj } = this.state;
    if (type === 'channel') {
      return resultsObj[displayMode] ? (resultsObj[displayMode].list || []) : [];
    } else if (type === 'history') {
      return historyObj[curve.id] && historyObj[curve.id][displayMode] ? (historyObj[curve.id][displayMode].list || []) : [];
    } else if (type === 'experiment') {
      return experimentObj[curve.id] && experimentObj[curve.id][displayMode] ? (experimentObj[curve.id][displayMode].list || []) : [];
    }
  }

  changeMouse = (time, yPrefix) => {
    const curves = this.getCurves();
    const { id } = this.props;
    let { resultsObj, historyObj, displayMode, experimentObj } = this.state;
    if (!curves || !curves.length) return;
    const _resultsObj = resultsObj && Object.keys(resultsObj).length ? { ...resultsObj } : {};
    for (const curve of curves) {
      let type = '';
      if (historyObj[curve.id]) {
        type = 'history';
      } else if (experimentObj[curve.id]) {
        type = 'experiment';
      } else if (curve.id === id) {
        type = 'channel';
      }
      const { listIndex, childIndex } = this.getListChildIndex(curve, this.getList(curve, type));
      if (listIndex < 0) continue;
      if (curve.visible && curve.y && curve.y.length) {
        const index = this._getIndex(curve.x, time);
        const value = index > -1 ? yPrefix(curve.y[index]) : '';
        if (type === 'history') {
          historyObj[curve.id][displayMode].list[listIndex].children[childIndex].value = value;
        } else if (type === 'experiment') {
          experimentObj[curve.id][displayMode].list[listIndex].children[childIndex].value = value;
        } else if (type === 'channel') {
          _resultsObj[displayMode].list[listIndex].children[childIndex].value = value;
        }
      } else {
        if (type === 'history') {
          historyObj[curve.id][displayMode].list[listIndex].children[childIndex].value = '';
        } else if (type === 'experiment') {
          experimentObj[curve.id][displayMode].list[listIndex].children[childIndex].value = '';
        } else if (type === 'channel') {
          _resultsObj[displayMode].list[listIndex].children[childIndex].value = '';
        }
      }
    };

    this.setState({
      resultsObj: _resultsObj,
      historyObj,
      experimentObj
    })
  }

  getListChildIndex(curve, list = []) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].children.length; j++) {
        if (list[i].children[j].id === curve.name) {
          return {
            listIndex: i,
            childIndex: j
          }
        }
      }
    }
    return {
      listIndex: -1,
      childIndex: -1
    }
  }

  _getIndex(xPoints, time) {
    return getIndex(xPoints, time, 0, xPoints.length - 1);
  }

  getCurrentSelects = () => {
    let { portSelect, displayMode } = this.state;
    if (!portSelect || !portSelect[displayMode]) {
      return [];
    }
    return Object.values(portSelect[displayMode]).reduce((a, b) => {
      return Object.values(a).concat(...Object.values(b));
    }, []);
  }

  cancelMove = () => {
    const { resultsObj, historyObj, displayMode, experimentObj } = this.state;
    const selectArr = this.getCurrentSelects();
    let _historyObj = { ...historyObj }, _experimentObj = experimentObj;
    Object.keys(historyObj).forEach(id => {
      _historyObj[id] = cancelMove({ resultsObj: _historyObj[id], displayMode, selectArr })
    })
    Object.keys(experimentObj).forEach(id => {
      _experimentObj[id] = cancelMove({ resultsObj: _experimentObj[id], displayMode, selectArr })
    })
    this.setState({
      resultsObj: cancelMove({ resultsObj, displayMode, selectArr }),
      historyObj: _historyObj,
      experimentObj: _experimentObj
    })
  }

  changeAxis = (axis) => {
    this.waveformSetting.setAxis(axis);
  }

  initResult = () => {
    this.setState({
      resultsObj: {},
      portSelect: {}, // TODO
      historyObj: {},
      loading: false,
      folderList: []
    })
    WaveformPlot.cleanWaveformPlot();
    if (this.plot2D) {
      if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
        this.svgRef.current.removeChild(this.svgRef.current.children[0]);
      }
    }
    this.plot2D = null;
  }

  getWaveform = async (ifInitResult = true) => {
    this.interfaceContent = null;
    if (ifInitResult) {
      this.initResult();
    }
    this.setState({ loading: true })
    try {
      const { id, isEndToEnd, setupInfo, interfaceType, getWaveformResult, getVerificationJson } = this.props;
      const { modeActive, displayMode } = this.state;
      this.interfaceContent = setupInfo || await getVerificationJson(id, isEndToEnd);
      if (!this.interfaceContent) {
        this.setState({
          loading: false,
          folderList: []
        })
        return
      };
      let resultObj = {}, folderList = [];
      if (interfaceType === CPHY) {
        const resultObjList = [], curvesList = [];
        for (const signal of (this.interfaceContent.content.signals || [])) {
          for (const pair of ['AB', 'BC', 'CA']) {
            const waveform = await getWaveformResult({ id, type: `${modeActive}_${displayMode}`, isEndToEnd, pair, signal: signal.name });
            const res = await this.waveformDataProcess({ waveform, id, pair, colorIndex: curvesList.length });
            const curves = this.getCurves().filter(d => d.id === id);
            curvesList.push(...curves);
            resultObjList.push(res);
          }
        }
        const list = resultObjList.map(item => item.list).flat();
        const show = resultObjList.map(item => item.show).flat();
        resultObj = { list, show };
        const historyCurves = this.getCurves().filter(d => d.id !== id);
        historyCurves.push(...curvesList);
        WaveformPlot.setCurves(historyCurves)
      } else if (interfaceType === PRELAYOUT) {
        folderList = this.interfaceContent.content && this.interfaceContent.content.model && this.interfaceContent.content.model.libraries ? this.interfaceContent.content.model.libraries : [];
        resultObj = await this.getPreLayoutResultObjList({ id, modeActive, displayMode })
      } else {
        const waveform = await getWaveformResult({ id, type: `${modeActive}_${displayMode}`, isEndToEnd });
        resultObj = await this.waveformDataProcess({ waveform, id });
      }
      // TODO
      let portSelect = { ...this.state.portSelect };
      if (portSelect[displayMode] && portSelect[displayMode][id]) {
        portSelect[displayMode][id] = {};
      }
      if (id === this.props.id) {
        this.setState((prevState) => {
          return {
            resultsObj: { ...prevState.resultsObj, [displayMode]: resultObj },
            portSelect: portSelect,
            folderList
          }
        })
        this.initSelect(resultObj, displayMode, id);
      }
      this.setState({ loading: false, folderList })
    } catch (error) {
      console.error(error)
      this.setState({ loading: false, folderList: [] })
    }
  }

  renderResultHistory = async () => {
    const { historyList, isEndToEnd } = this.props;
    const { displayMode, modeActive } = this.state;
    // historyList - [{ id, name, channelId }], id: historyId
    let lists = [];
    for (const history of historyList) {
      const historyJson = await getHistoryVerificationJson(history.id, isEndToEnd);
      if (historyJson) {
        this.historyContent.set(history.id, historyJson);
        if (historyJson.type === CPHY) {
          const resultObjList = [], curvesList = [];
          for (const signal of (historyJson.content.signals || [])) {
            for (const pair of ['AB', 'BC', 'CA']) {
              const waveform = await getHistoryWaveformResult({ historyId: history.id, type: `${modeActive}_${displayMode}`, name: history.name, isEndToEnd, pair, signal: signal.name });
              const res = await this.waveformDataProcess({ waveform, id: history.id, isHistory: true, pair, colorIndex: curvesList.length });
              const curves = this.getCurves().filter(d => d.id === history.id);
              curvesList.push(...curves);
              resultObjList.push(res);
            }
          }
          const list = resultObjList.map(item => item.list).flat();
          const show = resultObjList.map(item => item.show).flat();
          const resultObj = { list, show };
          lists.push({ resultObj, id: history.id });
          const historyCurves = this.getCurves().filter(d => d.id !== history.id);
          historyCurves.push(...curvesList);
          WaveformPlot.setCurves(historyCurves);
        } else {
          const waveform = await getHistoryWaveformResult({ historyId: history.id, type: `${modeActive}_${displayMode}`, name: history.name, isEndToEnd });
          const resultObj = await this.waveformDataProcess({ waveform, id: history.id, isHistory: true });
          lists.push({ resultObj, id: history.id });
        }
      }
    }

    let _historyObj = {};
    lists.forEach(d => {
      _historyObj[d.id] = {};
      _historyObj[d.id][displayMode] = d.resultObj;
    });
    let historyFiles = historyList.map(d => ({ id: d.id, show: false, rowName: d.name, rowId: d.id }))
    this.setState({
      historyFiles: historyFiles,
      historyObj: _historyObj
    })
  }

  renderResultExperiment = async (ifInitResult = true) => {
    if (ifInitResult) {
      this.initResult();
    }
    const { modeActive, displayMode } = this.state;
    const { experimentList, isEndToEnd, status, id, setupInfo, getWaveformResult } = this.props;
    const list = experimentList.map(async experiment => {
      const interfaceContent = await getExperimentVerificationJson(experiment.id, isEndToEnd);
      if (interfaceContent) {
        this.experimentContent.set(experiment.id, interfaceContent);
      }
      const waveform = await getExperimentWaveformResult({ experimentId: experiment.id, type: `${modeActive}_${displayMode}`, name: experiment.name });
      const resultObj = await this.waveformDataProcess({ waveform, id: experiment.id, isHistory: false, isExperiment: true });
      return { resultObj, id: experiment.id };
    })
    let defaultFile = null;
    // add channel result
    if (status === VERIFY_SUCCESS) {
      try {
        const interfaceContent = setupInfo || await this.props.getVerificationJson(id, isEndToEnd);
        if (interfaceContent) {
          this.experimentContent.set(id, interfaceContent);
        }
        const waveform = await getWaveformResult({ id, type: `${modeActive}_${displayMode}`, isEndToEnd });
        const resultObj = await this.waveformDataProcess({ waveform, id, isHistory: false, isExperiment: true });
        list.unshift({ resultObj, id });
        defaultFile = { id: id, show: true, rowName: 'Default', rowId: id }
      } catch (error) {
        console.error(error);
      }
    }
    const lists = await Promise.all(list);
    let _experimentObj = {};
    lists.forEach(d => {
      _experimentObj[d.id] = {};
      _experimentObj[d.id][displayMode] = d.resultObj;
    });
    let experimentFiles = experimentList.map((d, index) => ({ id: d.id, show: index === 0 && status !== VERIFY_SUCCESS ? true : false, rowName: d.name, rowId: d.id }))
    if (defaultFile) {
      experimentFiles.unshift(defaultFile);
    }
    this.setState({
      experimentFiles,
      experimentObj: _experimentObj
    }, () => {
      if (experimentFiles && experimentFiles[0] && experimentFiles[0].id) {
        this.initSelect(_experimentObj[experimentFiles[0].id][displayMode], displayMode, experimentFiles[0].id);
      }
    })
  }

  getPreLayoutResultObjList = async ({ id, isHistory = false, isExperiment = false, pair, colorIndex, modeActive, displayMode }) => {
    const { interfaceType } = this.props;
    let fileList = [];
    if (this.interfaceContent && this.interfaceContent.content && this.interfaceContent.content.model && this.interfaceContent.content.model.modelType === TOUCHSTONE) {
      fileList = this.interfaceContent.content.model.libraries;
    }

    const waveform = await this.props.getWaveformResult({ fileList, id, pageType: interfaceType, type: `${modeActive}_${displayMode}` });
    const axis = { yUnit: 'dB', yLabel: 'Power Sum', xUnit: 'Hz', xLabel: 'Frequency' };
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis
    };
    this.plot2D = WaveformPlot.plotWaveform({ svgElement: this.svgRef.current, events, waveform, axis, id, colorIndex, pair });
    // TODO - set default xMin and xMax
    const curves = this.getCurves().filter(d => d.id === id);
    const interfaceContent = this.interfaceContent;
    const { isEndToEnd, getPowerSumResultList } = this.props;
    return await getPowerSumResultList({ curves, isEndToEnd, interfaceContent, displayMode, pair, interfaceType });
  }

  waveformDataProcess = async ({ waveform, id, isHistory = false, isExperiment = false, pair, colorIndex }) => {
    const { modeActive } = this.state;
    const axis = { yUnit: 'dB', yLabel: 'Power Sum', xUnit: 'Hz', xLabel: 'Frequency' };
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis
    };
    this.plot2D = WaveformPlot.plotWaveform({ svgElement: this.svgRef.current, events, waveform, axis, id, colorIndex, pair });
    // TODO - set default xMin and xMax
    const curves = this.getCurves().filter(d => d.id === id);
    return await this.getResultList({ curves, displayMode: modeActive, id, isHistory, isExperiment, pair })
  }

  initSelect = (resultObj, displayMode, id) => {
    const { reportConfig, isMulti } = this.props;
    const { horizontalLine = [], verticalLine = [] } = reportConfig.powerSumTargetLine || {}
    this.setTargetLinesValue({ horizontalLine, verticalLine });
    if (resultObj.list.length > 0) {
      const values = isMulti ? resultObj.list[0].children[0].children.map(d => d.id) : resultObj.list[0].children.map(d => d.id);
      const key = isMulti ? resultObj.list[0].children[0].rowName : resultObj.list[0].rowName;
      this.changePort(values, key, id, []);
    }
  }

  updateCurves = (values, key, id, prevSelects) => {
    let _prevSelects = prevSelects;
    const { portSelect, displayMode } = this.state;
    let curves = this.getCurves();
    try {
      // set state
      if (!_prevSelects) {
        _prevSelects = (portSelect[displayMode] && portSelect[displayMode][id] && portSelect[displayMode][id][key]) || [];
      }
      const { unchecks, newChecks } = selectedChange({ prevSelects: _prevSelects, values })
      unchecks.forEach(un => {
        const curveIndex = curves.findIndex(d => d.name === un);
        if (curveIndex > -1) {
          curves[curveIndex].visible = false;
        }

      })
      newChecks.forEach(ne => {
        const curveIndex = curves.findIndex(d => d.name === ne);
        if (curveIndex > -1) {
          curves[curveIndex].visible = true;
        }
      })
      this.updatePortSelect(key, values, id)
    } catch (error) {
      console.error(error);
    }
  }

  changePort = (values, key, id, prevSelects) => {
    this.updateCurves(values, key, id, prevSelects);
    this.plot2D.updatePlot();
  }

  changeMultiPort = (e, key, childList, id) => {
    const { portSelect, displayMode } = this.state;
    const check = e.target.checked;

    let _portSelect = JSON.parse(JSON.stringify(portSelect));
    let curves = this.getCurves();
    for (let childInfo of childList) {
      const { children, rowName } = childInfo;

      const values = check ? children.map(item => item.id) : [];
      let _prevSelects = (portSelect[displayMode] && portSelect[displayMode][id] && portSelect[displayMode][id][rowName]) || [];

      const { unchecks, newChecks } = selectedChange({ prevSelects: _prevSelects, values })
      unchecks.forEach(un => {
        const curveIndex = curves.findIndex(d => d.name === un);
        if (curveIndex > -1) {
          curves[curveIndex].visible = false;
        }

      })
      newChecks.forEach(ne => {
        const curveIndex = curves.findIndex(d => d.name === ne);
        if (curveIndex > -1) {
          curves[curveIndex].visible = true;
        }
      })
      if (!_portSelect[displayMode]) {
        _portSelect[displayMode] = {};
      }
      if (!_portSelect[displayMode][id]) {
        _portSelect[displayMode][id] = {};
      }
      _portSelect[displayMode][id][rowName] = values;
    }

    this.setState({
      portSelect: _portSelect
    })
    this.plot2D.updatePlot();
  }

  multiRowNameClick = (children, value,) => {
    const { resultsObj, displayMode } = this.state;
    let _resultsObj = { ...resultsObj };
    let displayList = [value];
    if (children && children.length) {
      let childrenShow = children.map(item => item.rowName);
      displayList = [...displayList, ...childrenShow]
    }

    if (_resultsObj[displayMode]) {
      let _show = [..._resultsObj[displayMode].show];
      if (_show.includes(value)) {
        _show = _show.filter(item => !displayList.includes(item))
      } else {
        _show = [...new Set([..._show, ...displayList])]
      }
      _resultsObj[displayMode].show = _show;
    }
    this.setState({ resultsObj: _resultsObj });
  }

  updatePortSelect = (rowName, values, id) => {
    const { displayMode } = this.state;
    this.setState((prevState) => {
      let _portSelect = prevState.portSelect;
      if (!_portSelect[displayMode]) {
        _portSelect[displayMode] = {};
      }
      if (!_portSelect[displayMode][id]) {
        _portSelect[displayMode][id] = {};
      }
      _portSelect[displayMode][id][rowName] = values;
      return {
        portSelect: _portSelect
      }
    });
  }

  getResultList = async ({ curves, displayMode, id, isHistory, isExperiment, pair }) => {
    const interfaceContent = isHistory ? this.historyContent.get(id) : isExperiment ? this.experimentContent.get(id) : this.interfaceContent;
    const { isEndToEnd, getPowerSumResultList } = this.props;
    return await getPowerSumResultList({ curves, isEndToEnd, interfaceContent, displayMode, pair });
  }

  changeWidthCB = () => {
    if (this.plot2D) {
      this.plot2D.redrawPlot(this.svgRef.current);
    }
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax, xUnit }) => {
    let plot = this.plot2D;

    if (type === 'xMin' || type === 'xMax') {
      let xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = parseFloat(_xMin) * 1e-9,
        end = parseFloat(_xMax) * 1e-9;
      if (plot.options.xUnit === 'Hz') {
        start = parseFloat(_xMin) / scaleConversion(xUnit, 'Hz');
        end = parseFloat(_xMax) / scaleConversion(xUnit, 'Hz');
      }
      if (parseFloat(start).toString() === 'NaN') {
        start = 0;
      };
      if (parseFloat(end).toString() === 'NaN') {
        end = 0;
      };

      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      const xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = (xrange[0] - xMin) / (xMax - xMin) * width,
        endX = (xrange[1] - xMin) / (xMax - xMin) * width;
      plot.updateRange(startX, endX);
    } else {
      // y axis
      let yStart = parseFloat(_yMin),
        yEnd = parseFloat(_yMax);

      if (parseFloat(yStart).toString() === 'NaN') {
        yStart = 0;
      };
      if (parseFloat(yEnd).toString() === 'NaN') {
        yEnd = 0;
      };

      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB();
  }

  reloadAxisCB = () => {
    this.plot2D.updatePlot();
  }

  colorChange = (e, key) => {
    if (e.toHexString()) {
      const curves = WaveformPlot.getCurves();
      const curveIndex = curves.findIndex(d => d.name === key);
      curves[curveIndex].color = e.toHexString();
      this.reloadAxisCB()
      this.setResultObjColor(e, key);
    }
  }

  setResultObjColor = (e, key) => {
    const { resultsObj, historyObj, displayMode, experimentObj } = this.state;
    const { resultPageType, isMulti } = this.props;
    let _resultsObj = { ...resultsObj }, _historyObj = { ...historyObj }, _experimentObj = { ...experimentObj };
    const fileId = strDelimited(key, "::")[0];

    if (this.getExperimentIds().includes(fileId) && resultPageType === EXPERIMENTS) {
      const { resultList } = changeCurveColor({ e, key, resultList: _experimentObj[fileId], displayMode });
      _experimentObj[fileId] = resultList;
    } else if (this.getHistoryIds().includes(fileId)) {//history
      const { resultList } = changeCurveColor({ e, key, resultList: _historyObj[fileId], displayMode });
      _historyObj[fileId] = resultList;
    } else {
      //result
      const { resultList } = changeCurveColor({ e, key, resultList: _resultsObj, displayMode: displayMode, isMultiList: isMulti })
      _resultsObj = resultList;
    }
    this.setState({
      resultsObj: _resultsObj,
      historyObj: _historyObj,
      experimentObj: _experimentObj
    })
  }

  rowNameClick = (displayMode, value) => {
    this.setState({
      resultsObj: rowDisplayChange(this.state.resultsObj, displayMode, value)
    })
  };

  _historyRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.historyObj[rowId] = rowDisplayChange(prevState.historyObj[rowId], displayMode, value)
      return {
        historyObj: prevState.historyObj
      }
    })
  }

  _experimentRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.experimentObj[rowId] = rowDisplayChange(prevState.experimentObj[rowId], displayMode, value)
      return {
        experimentObj: prevState.experimentObj
      }
    })
  }

  _fileNameClick = (id) => {
    const { historyFiles } = this.state;
    const index = historyFiles.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.historyFiles[index].show = !prevState.historyFiles[index].show;
      return {
        historyFiles: [...prevState.historyFiles]
      }
    })
  }

  _experimentfileNameClick = (id) => {
    const { experimentFiles } = this.state;
    const index = experimentFiles.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.experimentFiles[index].show = !prevState.experimentFiles[index].show;
      return {
        experimentFiles: [...prevState.experimentFiles]
      }
    })
  }

  changeSelectAll = (e, group, id) => {
    const check = e.target.checked;
    if (check) {
      this.changePort(group.children.map(d => d.id), group.rowName, id)
    } else {
      this.changePort([], group.rowName, id)
    }
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div
          className='waveform-svg'
          style={{ width: "100%", height: "100%" }}>
          <svg ref={this.svgRef}></svg>
        </div>
      </Spin>
    )
  }

  customizeTitle = (item) => {
    const { isEndToEnd } = this.props;
    const { modeActive } = this.state;

    if (isEndToEnd && modeActive === MIXED_MODE_KEY) {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.displayDesign}</span>
        <span className='component-pin-name'>&nbsp;{item.displayCompPins}</span>
        <span className='design-name'>&nbsp;[{item.type}]</span>
      </span>
    } else if (isEndToEnd && modeActive === SINGLE_MODE_KEY) {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.displayDesign}</span>
        <span className='component-pin-name'>&nbsp;{item.displayCompPins}</span>
      </span>
    }

    if (modeActive === MIXED_MODE_KEY) {
      return <span className='andes-customized-title'>
        <span className='component-pin-name'>{item.compPins}</span>
        <span className='design-name'>&nbsp;[{item.type}]</span>
      </span>
    } else if (modeActive === SINGLE_MODE_KEY) {
      return <span className='andes-customized-title'>
        <span className='component-pin-name'>{item.compPins}</span>
      </span>
    }
  }

  resultModeChange = (key) => {
    this.setState({
      modeActive: key
    })
    this.displayModeChange("NEXT")
  }

  displayModeChange = (key) => {
    this.setState({
      displayMode: key,
      portSelect: {}
    })
  }

  getAxis = () => {
    if (!this.waveformSetting || !this.waveformSetting.getAxis) return {};
    return this.waveformSetting.getAxis();
  }

  setTargetLinesValue = ({ horizontalLine, verticalLine, drawType, isDraw, axis = {} }) => {
    const { reportConfig } = this.props;
    if (!this.plot2D || !this.plot2D.targetLine) return;

    // save data
    this.plot2D.targetLine.setValue({ horizontalLine, verticalLine })
    isDraw && this.plot2D.targetLine.drawLines(this.plot2D.root, drawType);

    // update report config
    isDraw && this.props._updateReportInfo({ reportConfig: { ...reportConfig, powerSumTargetLine: { horizontalLine, verticalLine } } })
  }

  getResultConfig = () => {
    const { interfaceType, id, projectId, dataRate, isEndToEnd } = this.props;
    this.props._getReportConfig({ channelId: isEndToEnd ? '' : id, projectId, endToEndChannelId: isEndToEnd ? id : '', isEndToEnd, dataRate, interfaceType })
  }

  rightTopRender = () => {
    const { displayMode, modeActive, folderList } = this.state;
    const { id, isEndToEnd, dataRate, status, sweepId, reportConfig, pageType, channelId, Setting, updateSetting, changeSettingStatus, interfaceType } = this.props;
    const { horizontalLine = [], verticalLine = [] } = reportConfig.powerSumTargetLine || {};
    const _modelList = pageType === ROCKY ? [...singleModelList] : [...modelList];

    let xMax = [];
    if (this.plot2D && this.plot2D.options) {
      xMax = this.plot2D.options.xMax;
    }

    return (
      <>
        <PowerSumSetting
          id={id}
          isEndToEnd={isEndToEnd}
          recalculateTDRSBRWorkflow={this.props.recalculateTDRSBRWorkflow}
          isPreLayout={this.isPreLayout}
          status={status}
          sweepId={sweepId}
          dataRate={dataRate}
          horizontalLine={horizontalLine}
          verticalLine={verticalLine}
          setTargetLinesValue={this.setTargetLinesValue}
          getAxis={this.getAxis}
          generateTDRSBRResult={this.props.generateTDRSBRResult}
          getTDRSBRSettingData={this.props.getTDRSBRSettingData}
          pageType={pageType}
          channelId={channelId}
          Setting={Setting}
          maxFreq={xMax}
          updateSetting={updateSetting}
          changeSettingStatus={changeSettingStatus}
          reportConfig={reportConfig}
          folderList={folderList}
          updateReportInfo={this.props._updateReportInfo}
          interfaceType={interfaceType}
        />
        {pageType === ROCKY ?
          <DisplayMode
            displayModeChange={(e) => this.displayModeChange(e.target.value)}
            displayMode={displayMode}
            Modes={ModeChildren}
          /> :
          <Tabs onChange={this.resultModeChange} type="card" className='aurora-result-mode-tab' activeKey={modeActive}>
            {_modelList.map(d =>
              <TabPane tab={d.title} key={d.key}>
                <DisplayMode
                  displayModeChange={(e) => this.displayModeChange(e.target.value)}
                  displayMode={displayMode}
                  Modes={ModeChildren}
                />
              </TabPane>
            )}
          </Tabs>}

      </>
    )
  }

  resultListRender = () => {
    const { portSelect, resultsObj, displayMode, historyObj, historyFiles, experimentObj, experimentFiles } = this.state;
    const { id, resultPageType, notDisplayHistory, isMulti } = this.props;
    return (
      <div style={{ marginTop: "10px" }} className='result-file-list-content'>
        {
          resultPageType === EXPERIMENTS ? <ResultList
            title='Current Result'
            type={HISTORY}
            multiFiles={true}
            displayMode={displayMode}
            portSelect={portSelect[displayMode] || {}}
            portSelectMultiFile={true}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={experimentObj}
            rowNameClick={this._experimentRowNameClick}
            changeSelectAll={this.changeSelectAll}
            files={experimentFiles}
            fileNameClick={this._experimentfileNameClick}
            customizeTitle={this.customizeTitle}
          />
            : <Fragment>
              {/* Result list */}
              <ResultList
                type={RESULT}
                fileId={id}
                rowId={id}
                displayMode={displayMode}
                portSelect={(portSelect[displayMode] && portSelect[displayMode][id]) || {}}
                changePort={this.changePort}
                colorChange={this.colorChange}
                resultList={resultsObj}
                rowNameClick={this.rowNameClick}
                changeSelectAll={this.changeSelectAll}
                customizeTitle={this.customizeTitle}
                isMulti={isMulti}
                changeMultiPort={this.changeMultiPort}
                multiRowNameClick={this.multiRowNameClick}
              />
              {/* History list */}
              {notDisplayHistory ? null :
                <ResultList
                  type={HISTORY}
                  multiFiles={true}
                  displayMode={displayMode}
                  portSelect={portSelect[displayMode] || {}}
                  portSelectMultiFile={true}
                  changePort={this.changePort}
                  colorChange={this.colorChange}
                  resultList={historyObj}
                  rowNameClick={this._historyRowNameClick}
                  changeSelectAll={this.changeSelectAll}
                  files={historyFiles}
                  fileNameClick={this._fileNameClick}
                  customizeTitle={this.customizeTitle}
                />
              }
            </Fragment>
        }
      </div>
    )
  }

  settingRender = () => {
    const { modeActive } = this.state
    return (
      <div>
        <Divider
          orientation="left"
          className='waveform-setting-title'
          style={{
            margin: "6px 0px"
          }}
        >Setting</Divider>
        <div className='aurora-v2-waveform-setting-css'>
          <WaveformSetting
            onRef={this.onRef}
            plot={this.plot2D}
            axisChangeCB={this.axisChangeCB}
            reloadAxisCB={this.reloadAxisCB}
            currentResultKey={modeActive}
          />
        </div>
      </div>
    )
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        onRef={this.resultRightMenuOnRef}
        top={this.rightTopRender}
        resultList={this.resultListRender}
        setting={this.settingRender}
      />
    )
  }

  render() {
    return (
      <ResultLayout
        resultLeft={this.resultLeft}
        resultRight={this.resultRight}
        changeWidthCB={this.changeWidthCB}
      />
    )
  }
}

export default PowerSumResult;