import { select, event, mouse, selectAll } from 'd3-selection';
import { zoom, zoomTransform, zoomIdentity } from 'd3-zoom';
import CanvasPath from './CanvasPath';
import { getThicknessScale } from '../Stackup';
import NP from 'number-precision';
import { PointMatcher, TriMapping, getNewOkgPointsByTransForm, getPinMapper } from './pin_mapper';
import { SortFn } from '../helper/sort';
import CePolygon from '../geometry/CePolygon';
import { getCanvasTextWidth } from '../helper/getTextWidth';
import { unitChange } from '../helper/mathHelper';
import { getPointColor } from './PortsCanvas';
import _ from 'lodash';
/**
 * create and initial layerRenderer by layout db
 * @param {dom} svg dom element to be rendered
 * @param {object} layoutDB layout data object
 * @param {dom} locationSvg location dom element
 * @param {object} events zoom and mousemove event functions
 */

class NodesCanvas {
  constructor() {
    this.svgNode = null;
    this.locationSvg = null;
    this.layoutDB = null;
    this.events = {};
    this.points = [];
  }

  initLayout = ({ svgEle, layoutDB, locationSvg, events, points, unit, r, designUnit }) => {
    this.svgNode = svgEle;
    this.locationSvg = locationSvg;
    this.layoutDB = layoutDB;
    this.events = events;
    this.points = points;
    this.unit = unit;
    this.r = r;
    this.designUnit = designUnit;
    this.triPointObj = { A: null, B: null, C: null };
    this.triPoints = [];
    this.initPointsData()
    this.initData()
    this.resetCanvas();
  }

  getNewModelPointsByTransForm = (transForms, log) => {
    this.transFormPrams = transForms;
    const pkgPoints = this.points.map(item => item.location)
    const newModelPoints = getNewOkgPointsByTransForm(this.transFormPrams, pkgPoints, log);
    this.newPoints = this.points.map((item, index) => {
      if (isNaN(newModelPoints[index][0]) || isNaN(newModelPoints[index][1])) {
        Array.isArray(log) && log.push(`Node ${item.node ? item.node.node : ""} - new location (${newModelPoints[0]}, ${newModelPoints[1]}) location is NaN.`)
      }
      return {
        ...item,
        location: newModelPoints[index]
      }
    })
    return this.newPoints
  }

  getCircleRadius = () => {
    return this.r;
  }

  updateTriPairs = (pairs) => {
    this.triPairs = pairs;
  }

  initPointsData = () => {
    if (this.designUnit === "mils") {
      this.designUnit = "mil"
    }

    if (!this.r) {
      const scale = this.designUnit === "mil" ? 1 : getThicknessScale(this.designUnit, "mil")
      this.r = NP.times(2.5, scale)
    }

    if (this.designUnit !== this.unit) {
      const scale = getThicknessScale(this.designUnit, this.unit)
      this.unit = this.designUnit;
      //update point by unit
      for (let point of this.points) {
        point.location[0] = NP.times(scale, point.location[0]);
        point.location[1] = NP.times(scale, point.location[1]);
      }
    }

    this.minX = Math.min(...this.points.map(item => item.location[0]));
    this.minY = Math.min(...this.points.map(item => item.location[1]))
    this.maxX = Math.max(...this.points.map(item => item.location[0]));
    this.maxY = Math.max(...this.points.map(item => item.location[1]));
    this.width = this.maxX - this.minX;
    this.height = this.maxY - this.minY;
  }

  getCanvasPointsData = () => {
    return this.points
  }

  getCanvasUnit = () => {
    return this.unit
  }

  renderCompOutLine = () => {
    this.compOutline = new CePolygon();
    const pointA = this.points.find(item => item.location[0] === this.minX).location;
    const pointB = this.points.find(item => item.location[1] === this.minY).location;
    const pointC = this.points.find(item => item.location[0] === this.maxX).location;
    const pointD = this.points.find(item => item.location[1] === this.maxY).location;
    //todo
    /*  const centerPoint = getCenterPoint(this.points.map(item => item.location));
     let AB = [this.minX - this.minX, this.maxY - this.minY], AC = [centerPoint[0] - this.minX, centerPoint[1] - this.minY]
     const cross_product = AB[0] * AC[1] - AB[1] * AC[0];
     const dot_product = AB[0] * AC[0] + AB[1] * AC[1];
     const rotation = Math.atan2(cross_product, dot_product);
     const sinRot = Math.sin(rotation),
       cosRot = Math.cos(rotation);
     const cx = [cosRot, -sinRot];
     const cy = [sinRot, cosRot];
     const pointA = [cx[0] * this.minX + cx[1] * this.minY, cy[0] * this.minX + cy[1] * this.minY];
     const pointB = [cx[0] * this.minX + cx[1] * this.maxY, cy[0] * this.minX + cy[1] * this.maxY];
     const pointC = [cx[0] * this.maxX + cx[1] * this.minY, cy[0] * this.maxX + cy[1] * this.minY];
     const pointD = [cx[0] * this.maxX + cx[1] * this.maxY, cy[0] * this.maxX + cy[1] * this.maxY];
     this.compOutline.AddVertex(pointA[0], pointA[1]);
     this.compOutline.AddVertex(pointB[0], pointB[1]);
     this.compOutline.AddVertex(pointD[0], pointD[1]);
     this.compOutline.AddVertex(pointC[0], pointC[1]); */
    /*   this.compOutline.AddVertex(this.minX, this.minY);
      this.compOutline.AddVertex(this.minX, this.maxY);
      this.compOutline.AddVertex(this.maxX, this.minY);
      this.compOutline.AddVertex(this.maxX, this.maxY); */
    this.compOutline.AddVertex(pointA[0], pointA[1]);
    this.compOutline.AddVertex(pointB[0], pointB[1]);
    this.compOutline.AddVertex(pointC[0], pointC[1]);
    this.compOutline.AddVertex(pointD[0], pointD[1]);
    this.compOutline = this.compOutline.ConvertToGCPolygon();
    this.compOutlineObj = new CanvasPath();
    this.compOutlineObj.pathData = this.compOutline.GetSvgPath();

    /*    this.outlineObj.layer = layerName;
       this.outlineObj.type = 'component';
       this.outlineObj.comp = compName; */
    this.compOutLineElement = this.d3Root
      .append('g')
      .attr('layer', "COMP__OUTLINE__")
      .attr('hidden', null);
    this.compOutLineElement.selectAll('path').remove();
    this.compOutlineObj.drawSVG(this.compOutLineElement, {
      fill: 'none',
      "stroke": "yellow",
      "stroke-width": this.outlineStrokeWidth
    });
  }

  initData = () => {
    // root group
    this.d3Root = select(this.svgNode)
      .append('g')
      .attr('transform', 'translate(0, 0) scale(1, -1)')
      .attr('fill-rule', 'evenodd');

    /** the scaling factor when the design is fit to the canvas */
    this.fitScaling = 1.0;
    // svg viewBox
    this.viewBoxXc = 0;
    this.viewBoxYc = 0;
    this.outlineStrokeWidth = 1.0;

    // set the zooming event handling
    const _scaleExtent = [0.05, 400];
    this.zoomCtrl = zoom()
      .scaleExtent(_scaleExtent)
      .on("zoom", () => {
        const { k, x, y } = event.transform;
        this.d3Root.attr('transform', `translate(${x}, ${y}) scale(${k}, -${k})`)
        this.updateStrokeWidth();
      });

    select(this.svgNode).call(this.zoomCtrl);

    const mousemoveCallback = this.events.mousemove; //mousemove callback
    this.clickCallback = this.events.click;
    select(this.svgNode).on('mousemove', () => {
      const [x, y] = mouse(this.d3Root.node());
      mousemoveCallback(x, y);
    }).on('click', function () {
      if (event.defaultPrevented) return;
      if (!event.ctrlKey && !event.shiftKey) {
        /*  this.events.click(); */
      }
      selectAll(`g.node-setup-canvas-node-select-g`).remove();
    });
  }

  resizeCanvas = () => {
    if (!this.layoutDB || !this.layoutDB.GetProfile) return;
    // get the bounding box of the outline
    var designOutline = this.layoutDB.GetProfile();
    if (!designOutline) return;

    var x = this.minX - 0.05 * this.width;
    var y = -(this.maxY + 0.05 * this.height);
    var w = this.width * 1.1;
    var h = this.height * 1.1;

    select(this.svgNode)
      .attr('viewBox', x + ' ' + y + ' ' + w + ' ' + h);
    this.viewBoxXc = x + 0.5 * w;
    this.viewBoxYc = y + 0.5 * h;
    this.viewBoxWidth = w;
    this.viewBoxHeight = h;

    var viewW = this.svgNode.clientWidth;
    var viewH = this.svgNode.clientHeight;

    // calculate the scaling factor and shifting
    var ratioX = 0.9 * viewW / this.width;
    var ratioY = 0.9 * viewH / this.height;

    this.fitScaling = Math.min(ratioX, ratioY);

    // set the global line width
    select(this.svgNode).attr('stroke-width', 1 / this.fitScaling);
    this.updateStrokeWidth();
  };

  resetCanvas = () => {
    // remove the old group elements of the layers
    this.d3Root.selectAll('g').remove();
    // calculate the scaling and shifting according to the layout boundary
    this.resizeCanvas();

    // add a layer for outline
    /*  this.renderOutline() */

    this.renderPoints()

    /*     this.renderCompOutLine() */
  };

  updateStrokeWidth = () => {
    // outline stroke width is set to be fixed visually regardless of the zoom
    var width1 = 2 / this.fitScaling;
    var width2 = 3 / (this.fitScaling * zoomTransform(this.svgNode).k);
    this.outlineStrokeWidth = Math.min(width1, width2);

    // do not use this.fillMode for the if statement below because it might not be assigned at
    // the beginning
    if (!this.layoutDB.GetLayoutSettings().InFilledMode()) {
      // for non-filling mode, this is a global setting
      select(this.svgNode).attr('stroke-width', this.outlineStrokeWidth);
    }
  }

  renderOutline = () => {
    // get the design profile and convert it into SVG path data
    var profile = this.layoutDB.GetProfile();
    var gcpoly = profile.ConvertToGCPolygon();

    this.outlineObj = new CanvasPath();
    this.outlineObj.pathData = gcpoly.GetSvgPath();

    /** Make the outline always visible */
    // add the path to the layer's svg element

    this.outLineElement = this.d3Root
      .append('g')
      .attr('layer', "__OUTLINE__")
      .attr('hidden', null);
    this.outLineElement.selectAll('path').remove();
    this.outlineObj.drawSVG(this.outLineElement, {
      fill: 'none',
      "stroke": "red",
      "stroke-width": this.outlineStrokeWidth
    });

    this.updateStrokeWidth();
  };

  showNodesName = (show) => {
    if (show) {
      select(this.svgNode)
        .select('g')
        .select('g.nodeNameBox')
        .remove();

      let nodeNameBox = select(this.svgNode)
        .select('g')
        .append('g')
        .attr('class', 'nodeNameBox')

      for (let nodeEle of this.points) {
        if (!nodeEle) continue;
        const location = nodeEle.location;
        if (!location) {
          continue;
        }
        const [x, y] = location;

        const xTrans = parseFloat(this.milToUnit(nodeEle.node.length * this.r / 4));
        nodeNameBox
          .append('text')
          .text(nodeEle.node)
          .attr('transform', `translate(${x - xTrans}, ${y + 1.2 * this.r}) rotate(-180) scale(-1, 1)`)
          .attr('stroke-width', this.milToUnit(Math.floor(this.unitToMil(this.r)) / 10))
          .style('font-size', this.r > 1.5 ? this.r - 0.5 : this.r)
          .attr('stroke', 'yellow')
      }
    } else {
      select(this.svgNode)
        .select('g')
        .select('g.nodeNameBox')
        .remove()
    }
  }

  zoomIn = () => {
    this.zoom(1.272);
  };

  zoomOut = () => {
    this.zoom(1 / 1.272);
  };

  renderPoints = (radius) => {
    if (!isNaN(radius)) {
      this.r = parseFloat(radius);
    }
    if (this.d3Root.select("g.node-points-g")) {
      this.d3Root.select("g.node-points-g").remove()
    };
    this.pointsElement = this.d3Root
      .append('g')
      .attr('layer', "points")
      .attr("class", "node-points-g")
      .attr('hidden', null);

    let displayNameList = [/* { name, location } */]
    for (let point of this.points) {
      if (displayNameList.find(item => item.node === point.node && _.isEqual(item.location, point.location))) {
        continue
      }
      displayNameList.push({ node: point.node, location: point.location })
      this.drawPoint(point)
    }
  }

  drawPoint = (point) => {
    const color = point.type === "Power" ? "coral" : point.type === "Signal" ? "#05c11a" : "cornflowerblue";
    const name = getClassName(point.node)
    this.pointsElement
      .append('circle')
      .attr('class', "node-canvas-point-circle")
      .attr("id", `node-canvas-point-circle-${name}`)
      .attr('cx', point.location[0])
      .attr('cy', point.location[1])
      .attr('r', this.r)
      .attr('fill', color)
      .on("mousedown", () => {
        event && event.stopPropagation()
        if (event && event.button === 2) {
          this.rightClickNode(point)
        }
      })
      .append('title')
      .text(point.type === "Signal" ? `${point.net}:${point.node}` : `${point.node}:${point.net}`);
  }

  fitView = () => {
    // re-calculate the bounding box
    this.resizeCanvas();

    // clear the zooming shifting, todo
    // this.zoomCtrl.scaleTo(1).translate([0, 0]);
    this.d3Root.attr('transform', 'translate(0, 0) scale(1.0, -1.0)');
    this.svgNode.__zoom = new zoomIdentity.constructor(1, 0, 0);
    this.updateStrokeWidth();
  };

  zoom = (ratio, x, y) => {
    var xc, yc, scale;
    if (x === undefined || y === undefined) {
      const t = zoomTransform(this.svgNode);
      xc = this.viewBoxXc * (1 - ratio) + t.x * ratio;
      yc = this.viewBoxYc * (1 - ratio) + t.y * ratio;

      // remember this setting in the zoom control object
      // this.zoomCtrl.translate([xc, yc]);
      scale = t.k * ratio;
      // get the scale from the zoom object so that it won't be out of bound
      if (scale < 0.05) scale = 0.05;
      else if (scale > 100) scale = 100;
    } else {
      // Responding to the mouse event, the shifting and scaling are calculated by
      // d3. In this mode, the parameter 'ratio' is the actual scaling factor.
      xc = x;
      yc = y;
      scale = ratio;
    }

    this.svgNode.__zoom = new zoomIdentity.constructor(scale, xc, yc);
    this.d3Root.attr('transform', 'translate(' + xc + ' ' + yc + ') scale(' + scale + ',-' + scale + ')');
    this.updateStrokeWidth();
  }

  rightClickNode = (point, type = "Add") => {
    //right mouse click
    const key = type === "Remove" ? Object.keys(this.triPointObj).find(key => this.triPointObj[key] && this.triPointObj[key].node === point.node) : null;

    let selectedList = type === "Add" ? ["C", "B", "A"].map(item => {
      return {
        title: `Add node ${point.node} to point ${item} of the mapping triangle`,
        key: item
      };
    }) : [{ title: `Remove node ${point.node} of the mapping triangle`, key }];

    const maxItem = selectedList.map(item => item).sort((a, b) => {
      const length = a.title.length - b.title.length;
      return length > 0 ? -1 : 1
    })[0];

    selectAll(`g.node-setup-canvas-node-select-g`).remove();
    const [x, y] = point.location || [];
    const fontSize = this.unitToMil(this.r);
    const width = getCanvasTextWidth(`XX ${maxItem.title ? maxItem.title : maxItem} XXX`, parseFloat(this.milToUnit(fontSize)), select(`svg#cpm-setup-model-canvas`));
    const height = parseFloat(this.milToUnit(fontSize * 2 * selectedList.length));

    const rectYc = y - height;
    const name = getClassName(point.node)
    select(`svg#cpm-setup-model-canvas`)
      .select('g')
      .append("g")
      .attr("id", `node-setup-canvas-node-select-g-${name}`)
      .attr("class", `node-setup-canvas-node-select-g`)
      .on('click', () => {
        event.stopPropagation && event.stopPropagation();
      });

    //rect
    select(`g#node-setup-canvas-node-select-g-${name}`)
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr('x', x)
      .attr('y', y - height)
      .attr('fill', '#ffffff')

    //close button
    select(`g#node-setup-canvas-node-select-g-${name} `)
      .append("text")
      .text(`X`)
      .attr('x', x + width - this.milToUnit(fontSize * 1.5))
      .attr('y', y - this.milToUnit((fontSize) / 1.8))
      .style('font-size', this.milToUnit(fontSize))
      .style('font-weight', this.milToUnit(100))
      .attr('stroke-width', 1)
      .attr('fill', '#666')
      .style('cursor', "pointer")
      .on('click', () => {
        event.stopPropagation && event.stopPropagation();
        //remove
        selectAll(`g.node-setup-canvas-node-select-g`).remove();
      });

    //select list text
    for (let i = 0; i < selectedList.length; i++) {
      const item = selectedList[i];
      const yc = rectYc + i * this.milToUnit(fontSize * 2);
      const xTranslate = parseFloat(this.milToUnit(fontSize)),
        yTranslate = parseFloat(this.milToUnit(-(fontSize * 3) / 4));
      const transform = `translate(${x + xTranslate}, ${yc - yTranslate}) rotate(-180) scale(-1, 1)`;
      select(`g#node-setup-canvas-node-select-g-${name} `)
        .append('text')
        .text(`${item.title ? item.title : item} `)
        .attr('transform', transform)
        .attr("class", "node-setup-canvas-node-select-item")
        .style('font-size', this.milToUnit(fontSize))
        .style('font-weight', this.milToUnit(100))
        .attr('stroke-width', 1)
        .attr('fill', '#40a9ff')
        .style('cursor', "pointer")
        .on('click', () => {
          //save port
          event.stopPropagation && event.stopPropagation();
          if (type === "Remove") {
            if (this.events && this.events.removeNode) {
              this.triPointObj[item.key] && this.deleteTriPoints(this.triPointObj[item.key].node);
              this.events.removeNode(point, item.key)
              this.triPointObj[item.key] = null;
              this.triPoints = [...Object.keys(this.triPointObj || {}).map(it => (this.triPointObj[it] || {}).node).filter(it => !!it)];
              this.canvasTripPoints();
            }
          } else {
            if (this.events && this.events.selectNode) {
              this.triPointObj[item.key] && this.deleteTriPoints(this.triPointObj[item.key].node);
              this.events.selectNode(point, item.key)
              this.triPointObj[item.key] = point;
              this.triPoints = [...Object.keys(this.triPointObj || {}).map(it => (this.triPointObj[it] || {}).node).filter(it => !!it)];
              this.canvasTripPoints();
            }
          }

          selectAll(`g.node-setup-canvas-node-select-g`).remove();
        })
    }
  }

  deleteTriPoints = (node) => {
    const name = getClassName(node)
    this.pointsElement.select(`circle#node-canvas-point-circle-${name}-tri`).remove()
    this.pointsElement.select(`text#name-canvas-point-circle-${name}-tri-text`).remove()
  }

  clearMatchIcon = () => {
    this.pointsElement.selectAll(`g.node-canvas-point-tri-match`).remove()
  }

  canvasTripPoints = (notDisplayMatch) => {
    //delete prev points
    this.pointsElement.selectAll(`circle.node-canvas-point-circle-tri`).remove()
    this.pointsElement.selectAll(`text.node-canvas-point-circle-tri-text`).remove()
    this.pointsElement.selectAll(`g.node-canvas-point-tri-match`).remove()
    //draw line
    this.showPointLines()

    const showMatch = ["A", "B", "C"].filter(key => this.triPointObj[key] && this.triPointObj[key].node).length === 3;
    // const _showMatch = this.triPairs.every(item => item.node && item.node.node && item.pin && item.pin.pinNumber);

    for (let key in this.triPointObj || {}) {
      const point = this.triPointObj[key];
      if (!point || !point.node) {
        continue;
      }
      const name = getClassName(point.node)

      this.pointsElement
        .append('circle')
        .attr('class', "node-canvas-point-circle node-canvas-point-circle-tri")
        .attr("id", `node-canvas-point-circle-${name}-tri`)
        .attr('cx', point.location[0])
        .attr('cy', point.location[1])
        .attr('r', this.r)
        .attr('fill', getPointColor(key))
        .on("mousedown", () => {
          event && event.stopPropagation()
          if (event && event.button === 2) {
            this.rightClickNode(point, "Remove")
          }
        })
        .append('title')
        .text(point.type === "Signal" ? `${point.net}:${point.node}` : `${point.node}:${point.net}`);

      const xTrans = parseFloat(this.milToUnit(this.r / 3));
      let transform = `translate(${this.triPointObj[key].location[0] - xTrans}, ${this.triPointObj[key].location[1] - 0.3 * this.r}) rotate(-180) scale(-1, 1)`;
      this.pointsElement
        .append('text')
        .attr("id", `node-canvas-point-circle-${name}-tri-text`)
        .attr("class", `node-canvas-point-circle-tri-text`)
        .text(key)
        .attr('transform', transform)
        .attr('stroke-width', this.milToUnit(Math.ceil(this.unitToMil(this.r)) / 10))
        .style('font-size', this.r)
        .attr('stroke', 'yellow')
        .on("mousedown", (canvas) => {
          event && event.stopPropagation()
          if (event && event.button === 2) {
            this.rightClickNode(point, "Remove")
          }
        }).append('title')
        .text(point.type === "Signal" ? `${point.net}:${point.node}` : `${point.node}:${point.net}`);

      if (key === "C" && showMatch && !notDisplayMatch) {
        const matchEle = this.pointsElement
          .append("g")
          .attr("class", "node-canvas-point-tri-match");

        const fontSize = this.unitToMil(this.r);
        const width = getCanvasTextWidth(`MatchMat`, parseFloat(this.milToUnit(fontSize)), select(`svg#cpm-setup-model-canvas`));
        const height = parseFloat(this.milToUnit(fontSize * 2));
        matchEle
          .append("rect")
          .attr("class", "node-canvas-point-tri-match-rect")
          .attr("width", width)
          .attr("height", height)
          .attr('x', point.location[0] + this.r)
          .attr('y', point.location[1])
          .attr('fill', '#ffffff');

        const rectYc = point.location[1] - height;
        const yc = rectYc + 1 * this.milToUnit(fontSize * 2);
        const xTranslate = parseFloat(this.milToUnit(fontSize)),
          yTranslate = parseFloat(this.milToUnit(-(fontSize * 3) / 4));
        const transform = `translate(${point.location[0] + parseFloat(this.r) + xTranslate}, ${yc - yTranslate}) rotate(-180) scale(-1, 1)`;
        matchEle
          .append('text')
          .text("Match")
          .attr('transform', transform)
          .attr("class", "node-canvas-point-tri-match-text")
          .style('font-size', this.milToUnit(fontSize))
          .style('font-weight', this.milToUnit(100))
          .attr('stroke-width', 1)
          .attr('fill', '#40a9ff')
          .style('cursor', "pointer")
          .on('click', () => {
            //Match
            event.stopPropagation && event.stopPropagation();
            this.events.matchClick && this.events.matchClick()
          })
      }
    }

  }

  showPointLines = () => {
    this.pointsElement.selectAll("g.spice-node-canvas-points").remove()
    const points = Object.keys(this.triPointObj).map(item => this.triPointObj[item]).filter(item => !!item && item.node)
    const size1 = this.r < this.milToUnit(1.5) ? this.milToUnit(1) : this.milToUnit(1.5),
      size2 = this.r < this.milToUnit(1.5) ? this.milToUnit(0.6) : this.milToUnit(1);
    if (points.length > 1) {
      let existLine = []
      for (let i = 0; i < points.length; i++) {
        const point = points[i];
        const point2 = i === points.length - 1 ? points[0] : points[i + 1];
        if (!point || !point2 || !point.node || !point2.node) {
          continue;
        }
        if (existLine.find(item => _.isEqual(item, [point.node, point2.node]) || _.isEqual(item, [point2.node, point.node]))) {
          continue
        }
        existLine.push([point.node, point2.node]);
        let pointBox =
          this.pointsElement
            .append('g').attr('class', `spice-node-canvas-points`);

        pointBox.append('line')
          .attr('x1', point.location[0])
          .attr('y1', point.location[1])
          .attr('x2', point2.location[0])
          .attr('y2', point2.location[1])
          .attr('stroke', '#ffffffdd')
          .attr('stroke-dasharray', `${size1},${size1} `)
          .attr('stroke-width', size2)
      }
    }
  }

  milToUnit = (num) => {
    // mil to unit
    return unitChange({
      num: parseFloat(num),
      newUnit: this.unit === 'mils' ? 'mil' : this.unit,
      decimals: 12
    }).number;
  }

  unitToMil = (num) => {
    // pcb unit to mil
    return unitChange({
      num: parseFloat(num),
      oldUnit: this.unit === 'mils' ? 'mil' : this.unit,
      newUnit: 'mil',
      decimals: 12
    }).number;
  }

  removeAllTriPoints = () => {
    for (let key in this.triPointObj) {
      this.triPointObj[key] && this.deleteTriPoints(this.triPointObj[key].node);
      this.triPointObj[key] = null;
    }
    this.triPoints = []
    this.pointsElement.selectAll("g.spice-node-canvas-points").remove();
    this.pointsElement.selectAll(`circle.node-canvas-point-circle-tri`).remove();
    this.pointsElement.selectAll(`text.node-canvas-point-circle-tri-text`).remove();
    this.pointsElement.selectAll(`g.node-canvas-point-tri-match`).remove();
  }

  reDrawAllTriPoints = (pairs, notDisplayMatch) => {
    for (let key in this.triPointObj) {
      const findPair = pairs.find(item => item.index === key);
      if (!findPair) {
        continue;
      }
      this.triPointObj[key] = { ...findPair.node };
    }
    this.triPoints = [...Object.keys(this.triPointObj || {}).map(it => (this.triPointObj[it] || {}).node).filter(it => !!it)];
    this.canvasTripPoints(notDisplayMatch)
  }

  highlightPoints = (nodes = []) => {
    this.removeHighlight()
    for (let node of nodes) {
      const name = getClassName(node)
      this.pointsElement.select(`circle#node-canvas-point-circle-${name}`)
        .attr('fill', "magenta")
    }

    this.highlightNodes = [...nodes];

    const locations = nodes.map(item => this.points.find(it => it.node === item)).map(item => item.location).filter(item => !!item);
    const xArr = locations.map(d => d[0]), yArr = locations.map(d => d[1]);
    const xMin = Math.min(...xArr), xMax = Math.max(...xArr), yMin = Math.min(...yArr), yMax = Math.max(...yArr);
    // rate
    let width = xMax - xMin, height = yMax - yMin;
    let _rate = null;
    if (width === 0 && height === 0) {
      _rate = 0.1;
      width = 1;
      height = 1;
    } else {
      const r = Math.min(this.viewBoxWidth / width, this.viewBoxHeight / height);
      if (!_rate) {
        if (r > 30) {
          _rate = 0.1;
        } else if (r > 10) {
          _rate = 0.3;
        } else if (r > 3) {
          _rate = 0.8;
        } else {
          _rate = 0.9;
        }
      }
    }
    this.zoomToBox(xMin, yMin, width, height, _rate);
  }

  removeHighlight = () => {
    for (let node of this.highlightNodes || []) {
      const point = this.points.find(it => it.node === node);
      if (!point) { continue }
      const color = point.type === "Power" ? "coral" : point.type === "Signal" ? "#05c11a" : "cornflowerblue";
      const name = getClassName(node)
      this.pointsElement.select(`circle#node-canvas-point-circle-${name}`)
        .attr('fill', color);
    }
  }

  zoomToBox = (x, y, w, h, rate = 0.9) => {
    if (w >= 0 && h >= 0) {

      // find the optimum scaling factor
      var scale = Math.min(this.viewBoxWidth / w, this.viewBoxHeight / h) * rate;

      // run through the zoom object limit check
      if (scale < 0.05) scale = 0.05;
      else if (scale > 100) scale = 100;

      // this.zoomCtrl.scale(scale);
      // scale = this.zoomCtrl.scale();

      // find the shifting so that the center point of the box is located at the view
      // center after the zoom
      //   Xc_view = xc_box * s + dx
      // where Xc_view is the view center, xc_box is the center coordinate of the box,
      // s is the scaling factor, and dx is the shift
      var dx = this.viewBoxXc - (x + 0.5 * w) * scale;
      var dy = this.viewBoxYc + (y + 0.5 * h) * scale; // the y coordinate is reverted
      // var dx = this.viewBoxXc - x * scale;
      //var dy = this.viewBoxYc + y * scale; // the y coordinate is reverted
      // this.zoomCtrl.translate([dx, dy]);
      // const t = zoomIdentity.translate(dx, dy).rescaleX();
      this.svgNode.__zoom = new zoomIdentity.constructor(scale, dx, dy);
      this.d3Root
        .transition()
        .duration(750)
        .attr('transform', `translate(${dx}, ${dy}) scale(${scale}, -${scale})`);

      this.updateStrokeWidth();
    }
  };
}

let nodesCanvas = new NodesCanvas();

function getCPMSpiceByTransForm({
  diePoints,
  pinList,
  diePinsNets,
  PowerNets,
  ReferenceNets,
  setting,
  SignalNets
}) {
  const _setting = {
    scale: 1.4,
    rotation: 70,
    shiftX: 4.780,
    shiftY: 10.56,
    flipX: true,
    tol: 1e-6,
    ...setting
  }
  const pkgPoints = getPinMapper(diePoints, _setting);
  const cpmList = writeCPMSpice(pkgPoints, pinList, diePinsNets, PowerNets, ReferenceNets);
  const csmList = writeCSMSpice(pkgPoints, pinList, diePinsNets, SignalNets)
  return [...cpmList, ...csmList]
}

function writeCSMSpice(pkgPoints, pinList, diePinsNets, SignalNets) {
  let csmList = [], subcktModelList = [];
  csmList.push("*** Begin Signal ***");
  csmList.push("* Instance : Location (x,y) : Orientation : Tx/Rx {used, additional} : SPICE Node {in, out} : Net {in, out, IO PG, core PG} : Cell Model {used, additional} : LEF Cell : Type {used, additional}")

  subcktModelList.push("* End Chip Package Protocol<--")
  subcktModelList.push("*")
  subcktModelList.push("***********************************************")
  subcktModelList.push(".subckt CSM_Model1")

  const clkReg = /(CLK)|(DQS)|(RDQS)|(WCK)/ig;
  let netList = []
  for (let i = 0; i < pkgPoints.length; i++) {
    const findPin = pinList[i];//pinNumber
    const net = diePinsNets[findPin.pinNumber] ? diePinsNets[findPin.pinNumber].mName : "";
    let location = pkgPoints[i];
    const findInfo = SignalNets.find(item => item.nets.includes(net));
    if (!findInfo || !findInfo.name) {
      continue;
    }
    netList.push({ net, location, name: findInfo.name })
  }

  // * Instance : Location (x,y) : Orientation : Tx/Rx {used, additional} : SPICE Node {in, out} : Net {in, out, IO PG, core PG} : Cell Model {used, additional} : LEF Cell : Type {used, additional}
  let _netList = JSON.parse(JSON.stringify(netList))
  _netList = _netList.sort((a, b) => {
    const aMatch = a.net.match(clkReg);
    const bMatch = b.net.match(clkReg)
    if (aMatch && !bMatch) {
      return 1
    } else if (!aMatch && bMatch) {
      return -1
    }
    return 0;
  })
  let newNetList = [];
  for (let i = 0; i < _netList.length; i++) {
    const { net, location } = _netList[i]
    let ports = net.match(clkReg) ? ["INN_inst", "INP_inst", "OUTN_inst", "OUTP_inst"] : ["IN_inst", "PAD_inst"];
    // let ports = net.match(clkReg) ? ["OUTN_inst", "OUTP_inst"] : ["PAD_inst"];
    ports = ports.map(item => { return `${item}${i}` });
    newNetList.push({
      ..._netList[i],
      ports
    })
    csmList.push(`* LGMSECO_COMBO_H_${i} : ( ${location[0]} , ${location[1]} ) : FS : {Tx, } : { ${ports.join(` , `)} } : { ,  , AVDDQ AVSS, } : {LGMSELIO_Dummy_Model1_xtor, } : LGMSECO_COMBO_H : {Xtor, }`)
    continue;
  }

  const list = ["CLK", "DQS", "DM", "DQ", "CA", "CS", "CK"];
  newNetList = newNetList.sort((a, b) => {
    const aIndex = list.findIndex(it => a.name.includes(it))
    const bIndex = list.findIndex(it => b.name.includes(it))
    if (aIndex > -1 && bIndex > -1) {
      if (aIndex === bIndex) {
        const aNumber = a.name.match(/\d+/g);
        const bNumber = b.name.match(/\d+/g);
        return aNumber - bNumber;
      }
      return aIndex - bIndex
    } else if (aIndex > -1) {
      return 1;
    } else if (bIndex > -1) {
      return -1
    }
    return 0
  })
  for (let i = 0; i < newNetList.length; i++) {
    const { name, ports } = newNetList[i];
    subcktModelList.push(`+  ${ports.join(` `)}   $${name}`)
  }

  csmList.push("*** End Signal ***")
  csmList.push("*")
  return csmList.length > 4 ? [...csmList, ...subcktModelList] : [];
}

function writeCPMSpice(pkgPoints, pinList, diePinsNets, PowerNets, ReferenceNets) {
  let list = [];
  list.push("*=========================================================")
  list.push("*=========================================================")
  list.push("*")
  list.push("* Begin Chip Package Protocol --->")
  list.push("* Start Units")
  list.push("*   Length mils")
  list.push("* End Units")
  list.push("*** Begin Power Ground ***")
  list.push("* Pad (PLOC) : Pad Location (x,y) : Pad Type : SPICE Node : DEF Net");

  let existNets = {}, pinLines = [], signalIndex = 0
  for (let i = 0; i < pkgPoints.length; i++) {
    /* * VDDQ_DDR1 : ( 112.406894 , -52.781559 ) : Power : VDDQ_DDR1 : VDDQ_DDR 23 */
    const findPin = pinList[i];//pinNumber
    const net = diePinsNets[findPin.pinNumber] ? diePinsNets[findPin.pinNumber].mName : "";
    let location = pkgPoints[i];
    const type = PowerNets.includes(net) ? "Power" : ReferenceNets.includes(net) ? "Ground" : null;
    if (!type) {
      continue;
    }
    let index = 1;
    if (!existNets[net]) {
      index = 1;
      existNets[net] = 1;
    } else {
      index = existNets[net] + 1;
      existNets[net] = index;
    }
    let node = type === "Power" ? "AVDDQ" : "VSS";
    pinLines.push({ net, info: `* ${node}${index} : (${location[0]} , ${location[1]} ) : ${type} : ${node}${index} : ${net} : ${findPin.pinNumber} ` })
  }
  list.push(...SortFn(pinLines, [...PowerNets, ...ReferenceNets], "net").map(item => item.info))
  list.push("*** End Power Ground ***")
  list.push("*")

  return list;
}

function downloadTransFromSpiceFile(list = [], fileName = "CPM_test.sp") {
  const content = list.join("\t\n");
  const a = document.createElement('a');
  a.href = `data: text/plain; charset=utf-8, ${encodeURIComponent(content)}`;
  a.download = fileName;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function getTransformByTriMapping({ triPairs, nodeUnit, designUnit, log }) {
  writeTripMappingLog(triPairs, nodeUnit, designUnit, log)
  const triMapping = new TriMapping({ triPairs, nodeUnit, designUnit, log })
  return triMapping.getTransform();
}

function writeTripMappingLog(triPairs, nodeUnit, designUnit, log) {
  log.push(`Node Unit: ${nodeUnit} `);
  log.push(`Design Unit: ${designUnit} `);
  log.push("*** Trip Mapping ***");
  try {
    for (let item of triPairs) {
      const pin = item.pin || { mLocation: {} };
      const node = item.node || { location: [] };
      log.push(`Point: ${item.index}: Pin(${pin.pinNumber}:: ${pin.net}:: ${pin.netType}, Location(${pin.mLocation.xc}, ${pin.mLocation.yc}), R = ${pin.mLocation.r})`)
      log.push(`Point: ${item.index}: Node(${node.node}:: ${node.net}:: ${node.type}, Location(${node.location[0]}, ${node.location[1]}))`)
    }
    log.push("*** End Trip Mapping ***");
  } catch (error) {
    console.error(error)
  }

  return log;
}

function getPinNodeMapping({ pinPoints, pkgPoints, r, log, signalMapping }) {
  let pinMap = new Map();
  const _pinPoints = pinPoints.map(item => { pinMap.set(`pin::${item.mLocation.xc}::${item.mLocation.yc} `, item); return [item.mLocation.xc, item.mLocation.yc] });
  const _pkgPoints = pkgPoints.map(item => item.location);
  const pinMatcher = new PointMatcher(_pinPoints)
  let matched = pinMatcher.match(_pkgPoints, 1e-6);
  let pinMapping = {}, isMapping = false;

  let numMatched = matched.filter(m => m != null).length;
  const minLength = pinPoints.length > pkgPoints.length ? pkgPoints.length : pinPoints.length;

  const maxMatchLength = minLength * 0.8;
  Array.isArray(log) && log.push(`Matched - ${numMatched} / ${minLength} (* 0.8)`)

  if (numMatched < maxMatchLength) {
    let matchInfo = getMatchNumber({ r, numMatched, maxMatchLength, _pkgPoints, pinMatcher, matched, log, minLength });
    matched = matchInfo.matched;
    numMatched = matchInfo.numMatched;
  }
  Array.isArray(log) && log.push(`Target Matched - ${numMatched} / ${minLength} (* 0.8)`)
  let _pinMapping = [], mappingLog = {
    pin: [],
    pkg: []
  }

  //write log info 
  for (let pinItem of pinPoints) {
    mappingLog.pin.push({
      type: pinItem.netType,
      log: `* ${pinItem.pinNumber} : ( ${pinItem.mLocation.xc} , ${pinItem.mLocation.yc} ) : ${pinItem.netType} : ${pinItem.pinNumber} : ${pinItem.net}`,
      pin: pinItem.pinNumber
    });
  }
  for (let nodeItem of pkgPoints) {
    mappingLog.pkg.push({
      type: nodeItem.type,
      log: `* ${nodeItem.node} : ( ${nodeItem.location[0]} , ${nodeItem.location[0]} ) : ${nodeItem.type} : ${nodeItem.node} : ${nodeItem.net}`,
      node: nodeItem.node
    })
  }

  if (numMatched < maxMatchLength) {
    writeMatchLog(mappingLog, log);
    return { pinMapping: [], isMapping: false, log };
  }

  for (let i = 0; i < pkgPoints.length; i++) {

    if (!matched || !matched[i]) {
      Array.isArray(log) && log.push(`package node does not match any pins  - ${pkgPoints[i] && pkgPoints[i].node ? pkgPoints[i].node.node : ""}`)
      continue;
    }
    const pinData = pinMap.get(`pin::${matched[i][0]}::${matched[i][1]} `) || {};
    if (!pinData.pinNumber) {
      Array.isArray(log) && log.push(`No matching pins found for package node - ${pkgPoints[i] && pkgPoints[i].node ? pkgPoints[i].node.node : ""}, (${matched[i][0]}, ${matched[i][1]})`)
      continue;
    }
    isMapping = true;
    if (pinMapping[pinData.pinNumber] && pinMapping[pinData.pinNumber].node && pinData.netType === "Signal"
      && pinMapping[pinData.pinNumber].node.name === pkgPoints[i].name && _.isEqual(pinMapping[pinData.pinNumber].node.location, pkgPoints[i].location) && pkgPoints[i].port && pkgPoints[i].port.type) {
      const currentInfo = signalMapping.find(item => item.nets.includes(pinData.net));
      if (currentInfo) {
        // When only DQS/CLK is needed, matching is required on P and N.
        // DQS0PA -> DQS0NA;
        let _name = null;
        if (currentInfo.pkgSignal.match(/(DQSP)|(CLKP)|DQS[0-9]+P|CLK[0-9]+P|CLKP[0-9]+/ig)) {
          _name = currentInfo.pkgSignal.replace("P", "N")
        } else if (currentInfo.pkgSignal.match(/(WCK_T)|(RDQS_T)|([RDQS|WCK]+[0-9]+T)|/ig)) {
          _name = currentInfo.pkgSignal.replace("T", "C")
        }
        const findData = signalMapping.find(item => item.pkgSignal === _name);
        if (findData) {
          const info = pinPoints.find(item => item.net === findData.nets[0]);
          if (info && info.pinNumber) {
            pinMapping[info.pinNumber] = {
              node: pkgPoints[i],
              pin: { ...info }
            }
          }
        }
      }

    } else {
      pinMapping[pinData.pinNumber] = {
        node: pkgPoints[i],
        pin: { ...pinMap.get(`pin::${matched[i][0]}::${matched[i][1]} `), matchLocation: matched[i] || null },
      }
    }
  }

  let csmMappingInfo = [];
  for (let pinItem of pinPoints) {
    const pinNode = pinMapping[pinItem.pinNumber];
    let node = "";
    if (pinNode && pinNode.node) {
      node = pinNode.node.node || "";
      if (pinNode.node.type && pinNode.node.type === "Signal") {
        csmMappingInfo.push({
          csmSignal: pinNode.node.name,
          nets: [pinItem.net],
          in: pinNode.node.port && pinNode.node.port.IN ? pinNode.node.port.IN : "",
          pad: pinNode.node.port && pinNode.node.port.PAD ? pinNode.node.port.PAD : "",
          readPad: pinNode.node.port && pinNode.node.port.READ_PAD ? pinNode.node.port.READ_PAD : "",
          csmInstanceSignal: pinNode.node.net || ""
        })
      } else {
        _pinMapping.push({
          pin: pinItem.pinNumber,
          net: pinItem.net,
          node,
          csmNet: pinNode.node.net,
          portName: pinNode.node.portName
        })
      }

      const index = mappingLog.pkg.findIndex(item => item.node === node);
      if (index > -1 && mappingLog.pin.length > index) {
        mappingLog.pin[index].log = `${mappingLog.pin[index].pin} :: ${pinItem.pinNumber}`;
      } else {
        mappingLog.pkg.push({
          type: pinNode.node.type,
          log: `* ${node} : ( ${pinNode.node.location[0]} , ${pinNode.node.location[0]} ) : ${pinNode.node.type} : ${node} : ${pinNode.node.net} :: ${pinItem.pinNumber}`,
          node
        })
      }
    }

    const index = mappingLog.pin.findIndex(item => item.pin === pinItem.pinNumber);
    if (index > -1 && mappingLog.pin.length > index) {
      mappingLog.pin[index].log = `${mappingLog.pin[index].log} :: ${node}`;
    } else {
      mappingLog.pin.push({
        type: pinItem.netType,
        log: `* ${pinItem.pinNumber} : ( ${pinItem.mLocation.xc} , ${pinItem.mLocation.yc} ) : ${pinItem.netType} : ${pinItem.pinNumber} : ${pinItem.net} :: ${node}`,
        pin: pinItem.pinNumber
      });
    }
  }

  writeMatchLog(mappingLog, log);

  return { pinMapping: _pinMapping, isMapping, log, csmMappingInfo };
}

function getClassName(node) {
  const name = node ? node.replace(/\$/ig, "") : '';
  return name
}

function writeMatchLog(mappingLog, log) {
  log.push("*** Package Die Bump Information ***");
  log.push("* Pin : Pin Location (x,y) : Net Type : Pin : DEF Net :: CSM bump node");
  log.push(...SortFn(mappingLog.pin, ["Power", "Ground"], "type").map(item => item.log));
  log.push("*** End Package Die Bump Information ***");

  log.push("*** CSM Bump Node Information ***");
  log.push("* Pad (PLOC) : Pad Location (x,y) : Pad Type : SPICE Node : DEF Net :: Package Die Bump");
  log.push(...SortFn(mappingLog.pkg, ["Power", "Ground"], "type").map(item => item.log));
  log.push("*** End CSM Bump Node Information ***");
  return log;
}

function getMatchNumber({ r, numMatched, maxMatchLength, _pkgPoints, pinMatcher, matched, log, minLength }) {
  if (numMatched < maxMatchLength) {
    matched = pinMatcher.match(_pkgPoints, 1e-3);
    numMatched = matched.filter(m => m != null).length;
    Array.isArray(log) && log.push(`Matched - ${numMatched} / ${minLength}(* 0.8)`)
  }

  if (numMatched < maxMatchLength) {
    matched = pinMatcher.match(_pkgPoints, r / 2);
    numMatched = matched.filter(m => m != null).length;
    Array.isArray(log) && log.push(`Matched - ${numMatched} / ${minLength}(* 0.8)`)
  }

  if (numMatched < maxMatchLength) {
    matched = pinMatcher.match(_pkgPoints, r);
    numMatched = matched.filter(m => m != null).length;
    Array.isArray(log) && log.push(`Matched - ${numMatched} / ${minLength}(* 0.8)`)
  }

  if (numMatched < maxMatchLength) {
    matched = pinMatcher.match(_pkgPoints, r * 1.5);
    numMatched = matched.filter(m => m != null).length;
    Array.isArray(log) && log.push(`Matched - ${numMatched} / ${minLength}(* 0.8)`)
  }

  if (numMatched < maxMatchLength) {
    matched = pinMatcher.match(_pkgPoints, r * 2);
    numMatched = matched.filter(m => m != null).length;
    Array.isArray(log) && log.push(`Matched - ${numMatched} / ${minLength}(* 0.8)`)
  }

  return { numMatched, matched }
}

export {
  nodesCanvas,
  getCPMSpiceByTransForm,
  getTransformByTriMapping,
  getPinNodeMapping,
  downloadTransFromSpiceFile
};

