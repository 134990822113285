// Net constants
export const POWER = 'Power',
  SIGNAL = 'Signal',
  NONET = 'NONET';

// Pin polygon
export const CIRCLE = 'Circle',
  POLYGEN = 'Polygen',
  SQUARE = 'Square',
  RECTCORNER = 'RectCutCorner',
  RECT = 'Rectangle';