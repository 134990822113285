import { getFileInRockyVerification } from "../../api/Rocky/rockyCtrl";
import { getVerificationJson } from "../result";
import { getPCBChannelContentPromise } from "../rockyCtrl";

class pcbChannelInfo {
  constructor() {
    this.pcbChannelInfo = new Map(); // key - pcbId/packageId, value - pcbChannelInfo
    this.pcbResultChannelInfo = new Map();
  }

  setInfo = (id, pdnInfo) => {
    this.pcbChannelInfo.set(id, pdnInfo)
  }

  getInfo = (id) => {
    return this.pcbChannelInfo.get(id)
  }

  setResultInfo = (id, info) => {
    this.pcbResultChannelInfo.set(id, info)
  }

  getPcbChannelInfo = (id, update = false) => {
    return new Promise((resolve, reject) => {
      const info = this.pcbChannelInfo.get(id)
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getPCBChannelContentPromise({ channelId: id }).then(res => {
          if (res) {
            this.setInfo(id, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  getPcbResultChannelInfo = (id, verificationSubId, update = false) => {
    return new Promise((resolve, reject) => {
      const info = this.pcbResultChannelInfo.get(id)
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getVerificationJson(id, verificationSubId).then(res => {
          if (res) {
            this.setResultInfo(id, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  clearInfo = () => {
    this.pcbChannelInfo = new Map();
    this.pcbResultChannelInfo = new Map();
  }
}

const PCBChannelContentConstructor = new pcbChannelInfo();
export default PCBChannelContentConstructor;