import { RockyInterface, Driver, Receiver } from './IntegratedInterface';
import { ddrDMTypes, LPDDR5, portSavePath } from '../constants';
import { PROJECT_V2 } from '../../../constants/projectVersion';

const TYPES = ['Controller', 'Memory'];
class RockyMergeSetup {

  mergeInterfacesInfo(interfaces, verificationName, projectType, projectVersion) {
    let newContent = new RockyInterface(verificationName);
    let interfacesInfo = JSON.parse(JSON.stringify(interfaces));
    if (interfacesInfo.length > 0) {
      if (projectVersion === PROJECT_V2 && verificationName.includes("CLK")) {
        let components = [], signals = [], powerNets = []
        for (let info of interfacesInfo) {
          const { Components, Signals, PowerNets } = info.Content;
          const filterPowerNets = PowerNets.filter(item => !powerNets.find(it => it.name === item.name));
          if (filterPowerNets && filterPowerNets.length) {
            powerNets = [...powerNets, ...filterPowerNets];
          }

          const filterSignals = Signals.filter(item => !signals.find(it => it.name === item.name));
          if (filterSignals && filterSignals.length) {
            signals = [...signals, ...filterSignals];
          }

          for (let compInfo of Components) {
            const { name, pins, pkg } = compInfo;
            const findIndex = components.findIndex(item => item.name === name);
            if (findIndex > -1) {
              const filterPins = pins.filter(item => !components[findIndex].pins.find(it => it.pin === item.pin && it.net === item.net));
              if (filterPins && filterPins.length) {
                components[findIndex].pins.push(...filterPins)
              }
              // pkg
              if (pkg && pkg.type !== "None" && pkg.pairs && pkg.pairs.length) {
                // 
                const { files, pairs } = pkg;
                const currentCompPkgInfo = components[findIndex].pkg;
                const filterFiles = files.filter(item => !currentCompPkgInfo.files.find(it => it.subckt === item.subckt && it.fileName === item.fileName && it.libraryId === item.libraryId && it.modelKey === item.modelKey))
                if (filterFiles && filterFiles.length) {
                  components[findIndex].pkg.files.push(...filterFiles);
                }
                const filterPairs = pairs.filter(item => !currentCompPkgInfo.pairs.find(it => it.pin === item.pin));
                if (filterPairs && filterPairs.length) {
                  components[findIndex].pkg.pairs.push(...filterPairs);
                }
              }
            } else {
              components.push(compInfo);
            }
          }
        }
        newContent.Components = components;
        newContent.Signals = signals;
        newContent.PowerNets = powerNets;
      } else {
        let content = JSON.parse(JSON.stringify(interfacesInfo[0].Content))
        PRAMS.forEach(pram => content[pram] && newContent[pram].push(...content[pram]));
      }

    }
    interfacesInfo.forEach(info => {
      let { Content } = info;

      /* Merge component pins */
      /*  comp: {
         "deviceVcc": "1.5",
           "part": "XC7Z020-1CLG400I_XC7Z010-1CLG40",
             "name": "U8",
               "pins": [
                 {
                   "pin": "L2",
                   "corner": "typ",
                   "Driver": {
                     powerOff:"",
                     model:{},
                     pinModels:[]
                   },
                   "Receiver": {
                     model:{}
                   },
                   "net": "+DDR3_CK",
                   "signal": "CLKP"
                 }
               ]
       } */
      Content.Components.forEach(comp => {
        let index = newContent.Components.findIndex(item => item.name === comp.name);
        if (index > -1) {
          newContent.Components[index].pins.forEach(pin => {
            if (TYPES.includes(comp.type)) {
              let pinFind = comp.pins.find(item => item.pin === pin.pin);
              let Read = false;
              //DM READ interface not display
              //find DM delete DM read usage
              if (pinFind) {
                let DMReg = new RegExp(`^(DM)`, 'ig');
                const words = pinFind.signal.match(DMReg);
                if ((words && ddrDMTypes.includes(projectType)) || (projectType === LPDDR5 && pinFind.signal.match(/(WCK)|(DQS)/g))) {
                  //DDR3/DDR3L/DDR4/DDR5/DDR4L  DM unidirectional
                  //LPDDR4 DM  Bidirectional
                  //LPDDR5 DQS unidirectional
                  let regRead = new RegExp(`(Read)`, 'ig');
                  const readFind = info.name.match(regRead);
                  if (readFind) {
                    Read = true;
                  }
                }
              }

              if (pinFind && pinFind.usage === 'Driver' && !Read) {
                pin.driver = new Driver({ powerOff: pinFind.powerOff, pinModels: pinFind.pinModels, model: pinFind.model })
              } else if (pinFind && pinFind.usage === 'Receiver' && !Read) {
                pin.receiver = new Receiver({ model: pinFind.model });
              }
              if (pinFind && pinFind.stdModel) {
                pin.stdModel = { ...pinFind.stdModel };
              }
              if (pinFind && pinFind.dimmStdModels) {
                pin.dimmStdModels = JSON.parse(JSON.stringify(pinFind.dimmStdModels));
              }

              delete pin.pinModels;
              delete pin.model;
              delete pin.usage;
              delete pin.powerOff;
            }
          })
        }
      })
    });
    //add channel and extraction
    //newContent.Channel = interfacesInfo && interfacesInfo[0] && interfacesInfo[0].Content.Channel || new Channel();
    //newContent.Extraction = interfacesInfo && interfacesInfo[0] && interfacesInfo[0].Content.Extraction || new Extraction();

    newContent.VTTNets = interfacesInfo && interfacesInfo[0] && interfacesInfo[0].Content.VTTNets ? JSON.parse(JSON.stringify(interfacesInfo[0].Content.VTTNets)) : [];
    //find ports by interface
    const portsInterface = interfacesInfo.find(i => portSavePath.find(p => i.name.match(p))).Content;
    newContent.Ports_generate_setup_list = portsInterface && portsInterface.Ports_generate_setup_list ? JSON.parse(JSON.stringify(portsInterface.Ports_generate_setup_list)) : [];
    newContent.ReferenceNets = portsInterface && portsInterface.ReferenceNets ? JSON.parse(JSON.stringify(portsInterface.ReferenceNets)) : [];
    newContent.Port_setups = portsInterface && portsInterface.Port_setups ? JSON.parse(JSON.stringify(portsInterface.Port_setups)) : [];
    newContent.VirtualComps = portsInterface && portsInterface.VirtualComps ? JSON.parse(JSON.stringify(portsInterface.VirtualComps)) : [];

    return newContent;
  }

  mergeCLKInterfacesInfo(interfaces, verificationName, projectType, projectVersion) {
    let newContent = new RockyInterface(verificationName);
    let interfacesInfo = JSON.parse(JSON.stringify(interfaces));
    if (interfacesInfo.length > 0) {
      let components = [], signals = [], powerNets = []
      for (let info of interfacesInfo) {
        const { Components, Signals, PowerNets } = info.Content;
        const filterPowerNets = PowerNets.filter(item => !powerNets.find(it => it.name === item.name));
        if (filterPowerNets && filterPowerNets.length) {
          powerNets = [...powerNets, ...filterPowerNets];
        }

        const filterSignals = Signals.filter(item => !signals.find(it => it.name === item.name));
        if (filterSignals && filterSignals.length) {
          signals = [...signals, ...filterSignals];
        }

        for (let compInfo of Components) {
          const { name, pins } = compInfo;
          const findIndex = components.findIndex(item => item.name === name);
          if (findIndex > -1) {
            const filterPins = pins.filter(item => !components[findIndex].pins.find(it => it.pin === item.pin && it.net === item.net));
            if (filterPins && filterPins.length) {
              components[findIndex].pins.push(...filterPins)
            }
          } else {
            components.push(compInfo);
          }
        }
      }
      newContent.Components = components;
      newContent.Signals = signals;
      newContent.PowerNets = powerNets;
    }

    interfacesInfo.forEach(info => {
      let { Content } = info;

      Content.Components.forEach(comp => {
        let index = newContent.Components.findIndex(item => item.name === comp.name);
        if (index > -1) {
          newContent.Components[index].pins.forEach(pin => {
            if (TYPES.includes(comp.type)) {
              let pinFind = comp.pins.find(item => item.pin === pin.pin);
              let Read = false;
              //DM READ interface not display
              //find DM delete DM read usage
              if (pinFind) {
                let DMReg = new RegExp(`^(DM)`, 'ig');
                const words = pinFind.signal.match(DMReg);
                if ((words && ddrDMTypes.includes(projectType)) || (projectType === LPDDR5 && pinFind.signal.match(/(WCK)|(DQS)/g))) {
                  //DDR3/DDR3L/DDR4/DDR5/DDR4L  DM unidirectional
                  //LPDDR4 DM  Bidirectional
                  //LPDDR5 DQS unidirectional
                  let regRead = new RegExp(`(Read)`, 'ig');
                  const readFind = info.name.match(regRead);
                  if (readFind) {
                    Read = true;
                  }
                }
              }

              if (pinFind && pinFind.usage === 'Driver' && !Read) {
                pin.driver = new Driver({ powerOff: pinFind.powerOff, pinModels: pinFind.pinModels, model: pinFind.model })
              } else if (pinFind && pinFind.usage === 'Receiver' && !Read) {
                pin.receiver = new Receiver({ model: pinFind.model });
              }
              if (pinFind && pinFind.stdModel) {
                pin.stdModel = { ...pinFind.stdModel };
              }
              if (pinFind && pinFind.dimmStdModels) {
                pin.dimmStdModels = JSON.parse(JSON.stringify(pinFind.dimmStdModels));
              }

              delete pin.pinModels;
              delete pin.model;
              delete pin.usage;
              delete pin.powerOff;
            }
          })
        }
      })
    });
    //add channel and extraction
    newContent.VTTNets = interfacesInfo && interfacesInfo[0] && interfacesInfo[0].Content.VTTNets ? JSON.parse(JSON.stringify(interfacesInfo[0].Content.VTTNets)) : [];
    //find ports by interface
    const portsInterface = interfacesInfo.find(i => portSavePath.find(p => i.name.match(p))).Content;
    newContent.Ports_generate_setup_list = portsInterface && portsInterface.Ports_generate_setup_list ? JSON.parse(JSON.stringify(portsInterface.Ports_generate_setup_list)) : [];
    newContent.ReferenceNets = portsInterface && portsInterface.ReferenceNets ? JSON.parse(JSON.stringify(portsInterface.ReferenceNets)) : [];
    newContent.Port_setups = portsInterface && portsInterface.Port_setups ? JSON.parse(JSON.stringify(portsInterface.Port_setups)) : [];
    newContent.VirtualComps = portsInterface && portsInterface.VirtualComps ? JSON.parse(JSON.stringify(portsInterface.VirtualComps)) : [];

    return newContent;
  }
}

const PRAMS = ['Signals', 'Components', 'PowerNets'];


const RockySetup = new RockyMergeSetup();
export default RockySetup;