import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { UPLOAD_SNP_FILE, GET_DECAP_FILE_VALUE, GET_DC_VALUE, UPDATE_DC_SELECTED_KEY } from './actionTypes';
import { UploadSnpFile, getDecapFile, getFastPIDCResultListPromise, getDCRsultFileDataPromise } from '@/services/PDN';
import { getWorkFlow } from '@/services/api/v2/workflowCtrl';
import { changeUploadProgress, changeFileStatus, getDecapInfoSuccess, saveDCValue, updateDCSelectedKey, getDCInfoSuccess, updateDCResultType, updateDCResultStatus, changeResultType, clearDCResultData } from './action';
import { getPDNSettingInfo } from '@/services/api/pdn/PDNAPICtrl';
import { IRVia, IRPin, IRBump } from '@/services/Cascade/result/IR/IRTable';
import { getVoltageDrop } from '@/services/PDN'
import { message } from 'antd';

function* uploadSnpFile(action) {
  const { verificationId, file } = action;
  const res = yield call(UploadSnpFile, verificationId, file);
  if (res) {
    let { status, progress, id } = res;
    yield put(changeUploadProgress(verificationId, progress));
    while (status === 1) {
      yield delay(2000);
      const { data: { data } } = yield call(getWorkFlow, id);
      status = data.status;
      if (data.progress >= 3) {
        progress = data.progress;
      } else {
        progress = 3;
      }
      yield put(changeUploadProgress(verificationId, progress));
    }

    yield put(changeUploadProgress(verificationId, 100));
    yield put(changeFileStatus(true));
    message.success('Upload file success!');
    yield delay(2000);
    yield put(changeUploadProgress(verificationId, -1));
  }
}

function* getDecapValue(action) {
  const { projectId, pdnId, verificationId } = action
  var new_decapData = [];
  const data = yield call(getDecapFile, projectId, pdnId);
  if (data) {
    const ReadInfo = yield call(getPDNSettingInfo, verificationId)
    let components = ReadInfo.data.Components
    let _data = data.match(/[^\r\n]+/g);
    for (let i in _data) {
      let decapData = _data[i].toString().split(' ');
      const component = components.filter(item => item.name === decapData[0]);
      let resistance = (decapData[1] * 1000).toFixed(2)
      let inductances = (decapData[2] * 1e12).toFixed(2)
      let inductances_10Mhz = decapData[3] ? (decapData[3] * 1e12).toFixed(2) : undefined;
      var obj = {
        ...component[0],
        name: decapData[0],
        resistance: resistance,
        inductances: inductances,
        inductances_10Mhz
      }
      new_decapData.push(obj)
    }
  }
  yield put(getDecapInfoSuccess(new_decapData))
}

function* getDCValue(action) {
  const { verificationId, showFile } = action;
  try {
    if (!verificationId) {
      return;
    }
    const readInfo = yield call(getFastPIDCResultListPromise, verificationId);
    const { PDNReducer: { resultReducer: { resultKey } } } = yield select();
    let list = [], resultPowerDomainData = {};
    for (let key in readInfo) {
      if (readInfo[key] === 'reference1') {
        list.push({ key, id: readInfo[key] });
        resultPowerDomainData.gndNets = key;
      } else if (key === 'voltage') {
        resultPowerDomainData.nominal = readInfo[key]
      } else {
        resultPowerDomainData.powerNets = key;
        list.unshift({ key, id: readInfo[key] });
      }
    }
    yield put(saveDCValue(list, [resultPowerDomainData]));
    if (list.length) {
      yield put(updateDCResultStatus(true))
      if (showFile) {
        yield put(updateDCSelectedKey(list[0].id))
      }
    } else {
      if (resultKey === 'dc') {
        yield put(clearDCResultData())
        yield put(changeResultType('channel'))
      }
      yield put(updateDCResultStatus(false))
    }
  } catch (error) {
    console.error(error)
  }
}

function getType(key) {
  switch (key) {
    case 'via':
      return 1
    case 'pin':
      return 2
    case 'bump':
      return 3
    default: return;
  }
}

function* getDCValueFile(action) {
  const { selectedKey } = action;
  const { PDNReducer: { resultReducer: { DCResultKey, DCMenuList, resultPowerDomainData }, project: { verificationId } } } = yield select();
  const type = yield call(getType, DCResultKey)
  try {
    const res = yield call(getDCRsultFileDataPromise, selectedKey, type, verificationId);
    if (res) {
      let DCResultData = [];
      const IRMenuItem = DCMenuList.find(item => item.id === selectedKey)
      let data = res.match(/[^\r\n]+/g);
      data.forEach((item, index) => {
        let itemArr = item.split(',');
        if (DCResultKey === 'via') {
          DCResultData.push(new IRVia(itemArr, IRMenuItem.key));
        } else if (DCResultKey === 'pin') {
          const voltageDrop = getVoltageDrop(IRMenuItem, resultPowerDomainData, itemArr)
          DCResultData.push(new IRPin(itemArr, IRMenuItem.key, voltageDrop));
        } else if (DCResultKey === 'bump') {
          const voltageDrop = getVoltageDrop(IRMenuItem, resultPowerDomainData, itemArr)
          DCResultData.push(new IRBump(itemArr, IRMenuItem.key, index, voltageDrop));
        }
      })
      yield put(getDCInfoSuccess(DCResultData))
    }
  } catch (error) {
    console.error(error)
  }
  yield put(updateDCResultType(false))
}

function* resultSaga() {
  yield takeEvery(UPLOAD_SNP_FILE, uploadSnpFile);
  yield takeEvery(GET_DECAP_FILE_VALUE, getDecapValue);
  yield takeEvery(GET_DC_VALUE, getDCValue);
  yield takeEvery(UPDATE_DC_SELECTED_KEY, getDCValueFile)
}

export default resultSaga;