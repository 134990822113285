import {
  CLOSE_TAB_FOOTER, OPEN_TAB_FOOTER, CHANGE_TAB_MENU, CHANGE_PROJECT,
  CHANGE_VERIFICATION, CLEAN_MONITOR_MSG, DEBUG_MONITOR, CLEAN_TAB_MONITOR_STATUS
} from './actionTypes';

export const closeTabFooter = () => ({
  type: CLOSE_TAB_FOOTER,
});

export const openTabFooter = () => ({
  type: OPEN_TAB_FOOTER,
});

export const changeTabMenu = ({ selectKeys, menuType, verificationId, projectId, verificationName, projectName }) => ({
  type: CHANGE_TAB_MENU,
  selectKeys,
  verificationId,
  projectId,
  menuType,
  verificationName,
  projectName
});

export const changeProject = (projectName) => ({
  type: CHANGE_PROJECT,
  projectName
});

export const changeVerification = (verificationName, verificationId, projectId) => ({
  type: CHANGE_VERIFICATION,
  verificationName,
  verificationId,
  projectId
});

export const cleanTabMonitorMsg = () => ({
  type: CLEAN_MONITOR_MSG,
});

export const debugMonitorAction = (msg) => ({
  type: DEBUG_MONITOR,
  msg
});

export const cleanTabMonitorStatus = () => ({
  type: CLEAN_TAB_MONITOR_STATUS,
});