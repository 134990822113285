import React, { Component, createRef, Fragment } from 'react';
import './index.css';

class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newX: 0,
      preX: 0,
      width: props.width,
      shapeLeft: props.width,
      delta: 0
    }
  }

  componentDidUpdate = (prevProps) => {
    const { width } = this.props;
    if (width && width > 3 && prevProps.width !== width) {
      this.setState({
        shapeLeft: width,
      })
    }
  }

  changeShapeStart(e) {
    e.stopPropagation();
    const { clientX } = e;
    this.setState({
      preX: clientX,
      width: this.props.width,
      display: true
    })
  }

  changeShape(e) {
    e.stopPropagation();
    const { clientX } = e;
    const { changeWidth } = this.props;
    //Used to set clientX
    this.setState((preState) => {
      if (clientX === 0) {
        return {
          newX: preState.newX
        }
      } else {
        return {
          newX: clientX
        }
      }
    }, () => {
      const { newX, preX, width } = this.state;
      let delta = newX - preX;
      if (this.props.negative) {
        delta = -delta;
      }
      let newWidth = width + delta;
      if (!this.props.resize) {
        changeWidth && changeWidth(newWidth);
      }
      this.setState({ delta, shapeLeft: newWidth });
    })
  }

  changeShapeEnd(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ width: this.state.width + this.state.delta, display: false });
    if (this.props.resize) {
      this.props.resize(this.state.width + this.state.delta);
    }
  }

  setStyle() {
    const { floatSide, position = {}, resize } = this.props;
    let { shapeLeft } = this.state;
    if (resize) {
      if (position.minWidth && shapeLeft < position.minWidth) {
        this.setState({
          shapeLeft: position.minWidth
        })
      }
      if (position.maxWidth && shapeLeft > position.maxWidth) {
        this.setState({
          shapeLeft: position.maxWidth
        })
      }
      if (floatSide) {
        return {
          float: floatSide,
        }
      } else if (position) {
        position.minWidth = null;
        position.maxWidth = null;
        return position;
      }
    } else {
      if (floatSide) {
        return {
          float: floatSide,
        }
      } else if (position) {
        return position;
      }
    }

  }

  render() {
    let style = this.setStyle();
    const { display } = this.state;
    const { resize, className, showLine = false } = this.props;
    const draggingStyle = display && resize ? {
      background: '#e2dfdf',
    } : {}
    return (
      <Fragment>
        <div
          className={`dividingLine ${className}`}
          draggable="true"
          onDragStart={(e) => this.changeShapeStart(e)}
          onDrag={(e) => this.changeShape(e)}
          onDragEnd={(e) => this.changeShapeEnd(e)}
          style={{ ...style, ...draggingStyle }}
        />
        <div
          className='dividingLine-hover'
          style={{ ...style, left: style.left ? style.left - 7 : 'unset' }}
        />
        {showLine && <div
          className={`dividingLine-border`}
          style={{ ...style }}
        />}
      </Fragment>
    )
  }
}

export default Line;


