import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Table from '@/components/EditableTable';
import { Button, message } from 'antd';
import { CASCADE } from '../../../constants/pageType';
import { getResBlob } from '../../../services/helper/downloadHelper';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { CAP } from '../../../constants/componentType';
import './index.css';

const columns = [{
  title: 'Power Domain',
  dataIndex: 'powerDomainName',
  width: '25%'
}, {
  title: 'Group',
  dataIndex: 'groupName',
  width: '25%'
}, {
  title: 'Decap Components',
  dataIndex: 'decapComponentsName',
  width: '50%'
}]

class DecapGroup extends Component {
  constructor(props) {
    super(props);

    this.dialogRoot = document.getElementById('root');
    this.uploadFileRef = createRef();
    this.state = {
      dataSource: []
    }
  }

  componentDidMount = () => {
    this.getDataSource();
    this.initColumns();
  }

  componentDidUpdate = (prevProps) => {
    const { powerDomainGroups } = this.props;
    if (!_.isEqual(powerDomainGroups, prevProps.powerDomainGroups)) {
      this.getDataSource();
    }
  }

  closeModal = () => {
    this.getPowerDomainGroups()
    this.props.decapGroupOpen(false)
  }

  initColumns = () => {

    columns[0].render = (text, record) => {
      const { dataSource } = this.state;
      const { powerDomainName, groupName } = record;
      const powerDomains = dataSource.filter(item => item.powerDomainName === powerDomainName);
      if (!powerDomains.length) {
        return "";
      }
      return {
        children: text,
        props: {
          rowSpan: powerDomains[0] && powerDomains[0].powerDomainName === powerDomainName && powerDomains[0].groupName === groupName ? powerDomains.length : 0
        }
      }
    }

    columns[2].render = (text) => {
      return text.join(', ')
    }

    columns[2].onCell = (record) => {
      const { powerDomainName, groupName } = record;
      const { data } = this.props;
      const { dataSource } = this.state;
      const usedComps = dataSource.filter(item => item.powerDomainName === powerDomainName && item.groupName !== groupName).map(item => item.decapComponentsName).flat(2);
      const components = data.filter(domain => domain && domain.content && domain.content.MAIN_POWER_NETS && domain.content.MAIN_POWER_NETS.includes(powerDomainName))
        .map(domain => domain.content ? domain.content.Components || [] : []).flat(2).filter(comp => comp.usage === CAP && !usedComps.includes(comp.name)).map(comp => comp.name);
      return {
        edit: 'select',
        selectMode: 'multiple',
        options: [...new Set(components)],
        record: { ...record },
        dataIndex: 'decapComponentsName',
        handleSave: (record) => this.selectComps(record),
        clearSelected: (record) => this.selectComps(record),
        dropdownMenuClassName: 'cascade-effect-select-dropdown-menu',
        getPopupContainer: document.getElementById('root')
      }
    }
  }

  getDataSource = () => {
    const { powerDomainGroups = [] } = this.props;
    let dataSource = [];
    for (let powerDomainGroup of powerDomainGroups) {
      const { powerDomainName, groups } = powerDomainGroup;
      for (let group of groups) {
        const { groupName, decapComponentsName } = group;
        dataSource.push({ powerDomainName, groupName, decapComponentsName })
      }
    }
    this.setState({
      dataSource
    })
  }

  getPowerDomainGroups = () => {
    const { dataSource } = this.state;
    const powerDomains = [...new Set(dataSource.map(d => d.powerDomainName))];
    const _powerDomainGroups = [];
    for (let powerDomain of powerDomains) {
      const groups = dataSource.filter(item => item.powerDomainName === powerDomain).map(item => ({ groupName: item.groupName, decapComponentsName: item.decapComponentsName }));
      _powerDomainGroups.push({ powerDomainName: powerDomain, groups })
    }
    this.props.updateDecapGroup(_powerDomainGroups)
  }

  downloadExcel = () => {
    const token = localStorage.getItem('token');
    let url = `/api/v3/Cascade/decap/group/help`;
    getResBlob(url, { token, pageType: CASCADE }).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, 'decap_group_template.xlsx');
      } else {
        message.error('Download failed!')
      }
    })
  }

  uploadFile = () => {
    const el = this.uploadFileRef.current;
    if (!el) return;
    el.click();
  }

  uploadDecapGroup = (e) => {
    const file = e.target.files[0];
    this.props.updateDecapGroup(file);
    this.uploadFileRef.current.value = "";
  }

  selectComps = (record) => {
    const dataSource = [...this.state.dataSource];
    const findIndex = dataSource.findIndex(item => item.powerDomainName === record.powerDomainName && item.groupName === record.groupName);
    if (findIndex > -1) {
      dataSource[findIndex] = { ...record };
      this.setState({
        dataSource
      })
    }
  }

  renderDialog() {
    const { dataSource } = this.state;
    let footerButtons = [
      <Button key="download" onClick={this.downloadExcel} >Download Template</Button>,
      <Button key="upload" onClick={this.uploadFile} >Upload</Button>
    ]
    if (dataSource.length) {
      footerButtons.push(<Button key="delete" onClick={() => this.props.updateDecapGroup()} >Delete</Button>)
    }
    const content = (
      <Panel
        className='cascade-decap-group-panel'
        title={'Decap Group'}
        onCancel={this.closeModal}
        zIndex={2000}
        width={1100}
        maxHeight={1000}
        minWidth={1000}
        minHeight={200}
        footer={footerButtons}
        position='panel-center'
        draggable
      >
        <Table
          className='cascade-decap-group-table'
          rowKey={(record) => `${record.powerDomainName}_${record.groupName}`}
          columns={columns}
          dataSource={dataSource}
          size="small"
          tableLayout="fixed"
        />
        <input
          type='file'
          ref={this.uploadFileRef}
          style={{ display: 'none' }}
          onChange={(e) => this.uploadDecapGroup(e)}
        />
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return (
      <Fragment>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default DecapGroup;