import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import PowerDomain from './powerDomain';
import VRM from './vrm';
import Components from './components';
import LayoutData from '@/services/data/LayoutData';
import { getPowerDomainNets, getPowerReferenceNets, getPDNComponentsByNets, getReferenceComps, getPowerDomainRNetwork } from '@/services/PDN';
import { selectNets, checkNets, findVRMModel, findVRMListModel, saveBGAConnectionName, getVRMComponent, saveMainNets, createCopyDecap, updateCapsConnectRefNet, savePackageComponent } from '../../store/pdn/action';
import { removeCurrentPreparingPDN } from '../../store/project/action';
import makeCancelable from '@/services/api/makeCancelable';
import { getListVRMModel } from '@/services/PDN/library';
import { versionCompareSize } from '@/services/helper/dataProcess';
import { autoFindPowerGNDNets } from '@/services/helper/NetHelper';
import { FASTPI } from '@/constants/pageType';
import './index.css';

class PDNSetting extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pcbLoading: false,
      nets: [],
      below: false,
      mainNetsLoading: false
    }
  }

  getPowerDomain = (voltage) => {
    const { PowerNets, ReferenceNets } = this.props;
    const data = [{
      powerNets: PowerNets,
      referenceNets: ReferenceNets,
      voltage: voltage
    }];
    return data;
  }

  judgeMainPowerNetExist = () => {
    const { MAIN_POWER_NETS, MAIN_REF_NETS } = this.props;
    if ((!MAIN_POWER_NETS || !MAIN_POWER_NETS.length) && (!MAIN_REF_NETS || !MAIN_REF_NETS.length)) {
      return false;
    } else {
      return true;
    }
  }

  loadPCB = (pcbId) => {
    this.setState({
      pcbLoading: true,
    });
    const { ReferenceNets, PowerNets, Name, PDNID, COMP_PREFIX_LIB, designId, VRM_COMPS } = this.props;
    if ((!ReferenceNets.length && !PowerNets.length)) {
      this.setState({
        loading: true
      });
    }

    // getVRMModel
    const { packageInfo } = this.props;
    const libraryId = packageInfo
      && packageInfo.length > 0
      ? packageInfo[0].libraryId : '';
    if (libraryId) {
      getListVRMModel(Name, libraryId).then(res => {
        if (res && res.length > 0) {
          const vrmId = res[0].userLibraryId;
          const vrmIds = res.map(item => item.userLibraryId)
          if (vrmId) {
            this.props._findVRMModel(vrmId);
            this.props._findVRMListModel(vrmIds)
          }
        }
      }, error => {
        console.error(error)
      });
    }

    const loadLayoutDBPromise = LayoutData.LoadLayoutDB(pcbId);
    this.cancelableLoadLayout = makeCancelable(loadLayoutDBPromise);
    this.cancelableLoadLayout.promise.then(res => {
      const _DesignData = LayoutData.getLayout(pcbId);
      const netList = _DesignData.mNetManager.mNetList;
      // Nets filter
      this.setState({
        pcbLoading: false,
      });
      const _nets = autoFindPowerGNDNets(netList, FASTPI);

      if (ReferenceNets.length) {
        const { findCaps } = getPDNComponentsByNets({ ReferenceNets, PowerNets: [], pcbId: designId, COMP_PREFIX_LIB })
        const { referenceComps } = getReferenceComps({ ReferenceNets, pcbId, COMP_PREFIX_LIB, VRM_COMPS, findCaps })
        this.props._updateCapsConnectRefNet(referenceComps)
      }
      if (!ReferenceNets.length && !PowerNets.length) {
        this.setState({
          nets: _nets
        });
        this.getNets(netList, false, PDNID);
      } else {
        if (!this.judgeMainPowerNetExist()) {
          this.setState({
            mainNetsLoading: true
          })
          //find main power & ground nets, add to MAIN_POWER_NETS and MAIN_REF_NETS to pdnContent and server
          this.getNets(netList, true, PDNID);
        } else {
          this.setState({
            loading: false,
            mainNetsLoading: false
          });
        }
        if (this._isMounted) {
          this.setState({
            nets: _nets
          });
          const { pdnVersion, vrmData } = this.props;
          // support 0.1a basic version
          // versionCompareSize(currentVersion,basicVersion)  if currentVersion < basicVersion return true
          if (!pdnVersion || (pdnVersion && (pdnVersion === '0.1a' || versionCompareSize(pdnVersion, "0.1.8")))) {
            if (vrmData && vrmData.length > 0 && vrmData[0].VRM_COMP === undefined
            ) {
              this.props._getVRMComponent();
            }
          }
        }
      }
    }, error => {
      console.error(error);
      this.setState({
        loading: false,
      });
    });
  };

  getNets = async (netList, mainNet, currentId) => {
    const { Name, packageInfo } = this.props;
    const libraryId = packageInfo
      && packageInfo.length > 0
      ? packageInfo[0].libraryId : '';
    if (libraryId) {
      const chip = packageInfo[0] ? packageInfo[0].chip : '';
      // getVRMModel
      getListVRMModel(Name, libraryId).then(res => {
        if (res && res.length > 0) {
          const vrmId = res[0].userLibraryId;
          const vrmIds = res.map(item => item.userLibraryId)
          if (vrmId) {
            this.props._findVRMModel(vrmId);
            this.props._findVRMListModel(vrmIds)
          }
        }
      }, error => {
        console.error(error)
      });
      // Get ckt connections
      const getPowerDomainNetsPromise = getPowerDomainNets(libraryId, Name);
      this.PowerDomainNetsCancel = makeCancelable(getPowerDomainNetsPromise);
      this.PowerDomainNetsCancel.promise.then(async data => {
        if (currentId !== this.props.PDNID) {
          this.setState({ mainNetsLoading: false });
          return;
        }
        if (data) {
          let rNetworkData = null;
          try {
            rNetworkData = await getPowerDomainRNetwork(libraryId, Name);
          } catch (error) {
            console.error(error);
          }
          const { connections } = data;
          const { pwrNets, gndNets, BGAConnectionName } = getPowerReferenceNets(connections, netList, chip);
          if (rNetworkData) {
            const { connections } = rNetworkData;
            const { BGAConnectionName: PackageComponent } = getPowerReferenceNets(connections, netList, chip);
            this.props.savePackageComponent(PackageComponent);
          }
          if (mainNet) {
            // save MAIN_POWER_NETS and MAIN_REF_NETS to pdnContent and server
            this.props._saveMainNets(pwrNets, gndNets);
          } else {
            this.props.saveBGAConnectionName(BGAConnectionName);
            const powerNets = [
              { powerType: 'Reference', nets: gndNets },
              { powerType: 'Power', nets: pwrNets }
            ];
            this.props._selectNets(powerNets, true);
            this.props._checkNets(pwrNets, gndNets);
            this.props._createCopyDecap();
            this.props.removePreparing('');
          }
        }
        this.setState({
          loading: false,
          mainNetsLoading: false
        })
      }, error => {
        console.error(error);
        this.setState({
          loading: false,
        })
        this.props.removePreparing('');
      });
    } else {
      if (this._isMounted) {
        this.setState({
          loading: false,
          mainNetsLoading: false
        })
      } else {
        this.setState({
          mainNetsLoading: false
        })
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.screenChange();
    const { designId } = this.props;
    if (designId) {
      this.loadPCB(designId);
    }
    const { tabVisible } = this.props;
    this.bottomRef = document.getElementsByClassName('pdn-content-bottom')[0];

    if (this.bottomRef) {
      let width = this.bottomRef.offsetWidth;
      if (width < 1000 || tabVisible) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }

    this.leftRef = document.getElementsByClassName('pdn-content-left')[0];

    if (this.leftRef) {
      let width = this.leftRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    if (this.cancelableLoadLayout) {
      this.cancelableLoadLayout.cancel();
    };
    if (this.PowerDomainNetsCancel) {
      this.PowerDomainNetsCancel.cancel();
    }
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { tabVisible } = this.props;
    this.bottomRef = document.getElementsByClassName('pdn-content-bottom')[0];
    this.leftRef = document.getElementsByClassName('pdn-content-left')[0];
    this.mainRef = document.getElementsByClassName('aurora-content')[0];
    if (this.bottomRef) {
      let width = this.bottomRef.offsetWidth;
      if (width < 1000 || tabVisible) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    } else if (this.leftRef) {
      let width = this.leftRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    } else {
      let width = this.mainRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { designId, Name, pdnVersion, vrmData, preparingPDN, PDNID } = this.props;
    // Project switch
    if (designId && designId !== prevProps.designId) {
      this.loadPCB(designId);
    };

    // PDN switch
    if (designId === prevProps.designId && Name !== prevProps.Name) {
      const { ReferenceNets, PowerNets } = this.props;
      const _DesignData = LayoutData.getLayout(designId);
      if (!_DesignData || !_DesignData.mNetManager || !_DesignData.mNetManager.mNetList) {
        // Can not get the net list
        return;
      }
      const netList = _DesignData.mNetManager.mNetList;
      // Nets filter
      const _nets = autoFindPowerGNDNets(netList, FASTPI);
      this.setState({
        nets: _nets,
      });
      // getVRMModel
      const { packageInfo } = this.props;
      const libraryId = packageInfo
        && packageInfo.length > 0
        ? packageInfo[0].libraryId : '';
      if (libraryId) {
        getListVRMModel(Name, libraryId).then(res => {
          if (res && res.length > 0) {
            const vrmId = res[0].userLibraryId;
            const vrmIds = res.map(item => item.userLibraryId)
            if (vrmId) {
              this.props._findVRMModel(vrmId);
              this.props._findVRMListModel(vrmIds)
            }
          }
        }, error => {
          console.error(error)
        });
      }
      if (!ReferenceNets.length && !PowerNets.length) {
        this.setState({
          loading: true
        }, () => {
          // Auto find power & ground nets
          this.getNets(netList, false, PDNID);
        });
      } else {
        if (!this.judgeMainPowerNetExist()) {
          this.setState({
            mainNetsLoading: true
          })
          //find main power & ground nets, add to MAIN_POWER_NETS and MAIN_REF_NETS to pdnContent and server
          this.getNets(netList, true, PDNID);
        } else {
          this.setState({
            loading: false
          })
        }
        // support 0.1a basic version
        //versionCompareSize(currentVersion,basicVersion)  if currentVersion < basicVersion return true
        if (!pdnVersion || (pdnVersion && (pdnVersion === '0.1a' || versionCompareSize(pdnVersion, "0.1.8")))) {
          if (vrmData && vrmData.length > 0 && vrmData[0].VRM_COMP === undefined
          ) {
            // PDN changed, and PCB has been loaded
            if (!this.state.pcbLoading) {
              this.props._getVRMComponent();
            }
          }
        }
      }
    }

    const { tabVisible, pdnResize } = this.props;
    this.bottomRef = document.getElementsByClassName('pdn-content-bottom')[0];
    if (this.bottomRef && prevProps.tabVisible !== tabVisible) {
      let width = this.bottomRef.offsetWidth, below = false;
      if (width < 1000 || tabVisible) {
        below = true;
      }
      this.setState({
        below
      });
    }

    if (pdnResize !== prevProps.pdnResize) {
      this.contentRef = document.getElementById('pdn-content-main');
      let width = this.contentRef.offsetWidth, below = false;
      if (width < 1000) {
        below = true;
      }
      this.setState({
        below
      });
    }

    // PDNSetting switch
    if (prevProps.preparingPDN.length !== preparingPDN.length) {
      if (preparingPDN && PDNID && preparingPDN.includes(PDNID)) {
        this.setState({ loading: true });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { nets, loading, pcbLoading, mainNetsLoading } = this.state;
    const { voltage, copyComponents } = this.props;
    const powerDomain = this.getPowerDomain(voltage);
    const componentList = this.props.Components;
    return (
      <div className='pdn-setting'>
        <Spin tip="Loading..." spinning={loading}>
          <Row>
            <Col span={24} className='pdn-setup-border'>
              <PowerDomain
                mainNetsLoading={mainNetsLoading}
                pcbLoading={pcbLoading}
                nets={nets}
                dataSource={powerDomain}
              />
            </Col>
          </Row>
          <Row className='space-16'>
            <Col span={24} className='pdn-setup-border'>
              <VRM
                components={componentList}
                voltage={voltage}
                loading={pcbLoading}
              />
            </Col>
          </Row>
          <Row className='space-16'>
            <Col span={24} className='pdn-setup-border'>
              <Components loading={pcbLoading} copyComponents={copyComponents} />
            </Col>
          </Row>
          <div id='pdn-model-dialog'></div>
        </Spin>
      </div>
    )
  }
}

const mapState = (state) => {
  const { PDNReducer: { pdn, project, /* TabMonitorReducer: { tabVisible } */ } } = state;
  const { tabVisible } = state.TabMonitorReducer;
  const { PDNID, preparingPDN } = project;
  const { copyComponents, pdnInfo = null } = pdn;
  let vrmData, Components = [], PowerNets = [], ReferenceNets = [], designId = '', pdnContent = null,
    Name = '', voltage, pdnVersion = null, MAIN_POWER_NETS = null, MAIN_REF_NETS = null, pdnId = null, COMP_PREFIX_LIB = {}, VRM_COMPS = [];
  if (pdnInfo) {
    designId = pdnInfo.designId;
    pdnContent = pdnInfo.pdnContent;
    pdnVersion = pdnInfo.pdnVersion;
  }
  if (pdnContent) {
    vrmData = pdnContent.VRM;
    Components = pdnContent.Components;
    PowerNets = pdnContent.PowerNets;
    ReferenceNets = pdnContent.ReferenceNets;
    VRM_COMPS = pdnContent.VRM_COMPS;
    Name = pdnContent.Name;
    MAIN_POWER_NETS = pdnContent.MAIN_POWER_NETS;
    MAIN_REF_NETS = pdnContent.MAIN_POWER_NETS;
    COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB;

    if (Array.isArray(pdnContent.VRM)) {
      voltage = vrmData.length > 0 ? vrmData[0].voltage : '';
    } else {
      voltage = vrmData.voltage;
    }
  }
  return {
    Components, PowerNets, ReferenceNets, designId, voltage, Name, tabVisible,
    packageInfo: project.currentProjectPackages, pdnVersion, vrmData,
    MAIN_POWER_NETS, MAIN_REF_NETS, copyComponents, PDNID, preparingPDN, COMP_PREFIX_LIB,
    VRM_COMPS
  }
};

const mapDispatch = (dispatch) => ({
  _selectNets(type, nets, first) {
    dispatch(selectNets(type, nets, first))
  },
  _checkNets(pwrNets, gndNets) {
    dispatch(checkNets(pwrNets, gndNets))
  },
  _findVRMModel(vrmId) {
    dispatch(findVRMModel(vrmId))
  },
  _findVRMListModel(vrmIds) {
    dispatch(findVRMListModel(vrmIds))
  },
  saveBGAConnectionName(BGAConnectionName) {
    dispatch(saveBGAConnectionName(BGAConnectionName))
  },
  _getVRMComponent() {
    dispatch(getVRMComponent())
  },
  _saveMainNets(pwrNets, gndNets) {
    dispatch(saveMainNets(pwrNets, gndNets));
  },
  _createCopyDecap() {
    dispatch(createCopyDecap());
  },
  removePreparing(id) {
    dispatch(removeCurrentPreparingPDN(id));
  },
  _updateCapsConnectRefNet(capsConnectRefNet) {
    dispatch(updateCapsConnectRefNet(capsConnectRefNet))
  },
  savePackageComponent(name) {
    dispatch(savePackageComponent(name))
  }
})

export default connect(mapState, mapDispatch)(PDNSetting);