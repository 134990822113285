import React, { Component, Fragment } from "react";
import Panel from "@/components/Panel";
import { createPortal } from 'react-dom';
import EditableTable from "@/components/EditableTable";
import { CloseOutlined } from '@ant-design/icons';
import { Tooltip, Button } from "antd";
import { getDefaultIndex } from "../../../../services/helper/setDefaultName";

const columns = [{
  key: "input",
  dataIndex: "input",
  title: "Input",
  width: "50%"
}, {
  key: "output",
  dataIndex: "output",
  title: "Output",
  width: "50%"
}]
class RepeaterConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connections: [],
      model: {}
    }
    this.dialogRoot = document.getElementById('root');

    columns[0].render = (value, record) => {

      if (record.index === "Add") {
        return <Button
          type="primary"
          className="sierra-repeater-connections-add-button"
          onClick={(e) => { this.addNewPinMap(e) }}
        >Add a row</Button>
      }
      const pinText = (record.input || []).map(item => item);

      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={pinText}
      >
        <div>{pinText}</div>
      </Tooltip>
    }

    columns[0].onCell = (record) => {
      if (record.index === "Add") {
        return { edit: false }
      }
      const pinText = (record.input || []).map(item => item);
      return {
        record: { ...record, input: pinText },
        edit: true,
        dataIndex: "input",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "input") },
      }
    }

    columns[1].onCell = (record) => {
      if (record.index === "Add") {
        return { edit: false }
      }
      const pinText = (record.output || []).map(item => item);
      return {
        record: { ...record, output: pinText },
        edit: true,
        dataIndex: "output",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "output") }
      }
    }

    columns[1].render = (value, record) => {
      if (record.index === "Add") {
        return null
      }
      const pinText = (record.output || []).map(item => item);
      return (
        <Fragment>
          <Tooltip
            overlayClassName="aurora-tooltip"
            title={pinText}
          >
            <div>{pinText}</div>
          </Tooltip>
          <CloseOutlined
            className="delete-icon"
            onClick={(e) => this.deletePinMap(e, record.index)} />
        </Fragment>
      );
    }
  }

  updatePairs = ({ pairs, prevPin, newPin }) => {
    if (!pairs || !pairs.length) {
      return pairs;
    }
    const index = pairs.findIndex(item => prevPin && item.pin === prevPin);
    if (index < 0) {
      return pairs;
    }
    if (!newPin) {
      pairs.splice(index, 1)
    } else {
      pairs[index].pin = newPin;
    }

    return pairs;
  }

  editPinMap = (record, prevRecord, type) => {
    const { connections, model } = this.state;
    let _connections = JSON.parse(JSON.stringify([...connections])), _model = { ...model };
    const index = _connections.findIndex(item => item.index === record.index);
    if (!record[type]) {
      _model.pairs = this.updatePairs({
        pairs: _model.pairs,
        prevPin: _connections[index][type],
        newPin: ""
      })
      _connections[index][type] = [""];
      this.setState({
        connections: _connections,
        model: _model
      })
      return;
    }
    const otherType = type === 'input' ? "output" : 'input';
    const findPin = _connections.find(item =>
      (item[otherType] && item[otherType].includes(record[type]))
      || (item[type] && item[type].includes(record[type])));

    let error = "";
    if (findPin) {
      error = `Pin ${record[type]} already exists`;
      this.setState({
        error
      })
      return;
    }

    _model.pairs = this.updatePairs({
      pairs: _model.pairs,
      prevPin: _connections[index][type],
      newPin: record[type]
    })

    _connections[index][type] = [record[type]];

    this.setState({
      connections: _connections,
      model: _model
    })
  }

  addNewPinMap = (e) => {
    e && e.stopPropagation();
    const { connections } = this.state;
    let _connections = [...connections];
    _connections.push({
      input: [],
      output: [],
      index: getDefaultIndex(_connections.length, _connections.map(item => item.index))
    })
    this.setState({
      connections: _connections
    })
  }

  deletePinMap = (e, pinMapIndex) => {
    e && e.stopPropagation();
    const { connections, model } = this.state;
    let _connections = [...connections];
    const index = _connections.findIndex(item => item.index === pinMapIndex);
    const deletedPins = [_connections[index].input[0] || "", _connections[index].output[0] || ""].filter(item => !!item);
    let _model = model || {};
    if (_model.pairs && _model.pairs.length) {
      _model.pairs = _model.pairs.filter(item => !deletedPins.includes(item.pin));
    }
    _connections = _connections.filter(item => item.index !== pinMapIndex);
    this.setState({
      connections: _connections,
      model: _model
    })
  }

  saveConnection = () => {
    const { connections, model } = this.state;
    let _connections = [...connections];
    _connections.forEach(item => { delete item.index })
    this.props.saveConnectionInfo({ partNumber: this.props.partNumber, connections, model, position: this.props.position })
    this.props.save();
  }

  componentDidMount = () => {
    this.setDefaultData()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.partNumber !== this.props.partNumber || prevProps.position !== this.props.position) {
      this.setDefaultData()
    }
  }

  setDefaultData = () => {
    const { connections, model } = this.props;
    if (!connections || !connections.length) {
      this.setState({
        pinList: [],
        connections: [],
        model: {}
      })
      return;
    }

    let _connections = [], index = 0;
    for (let pinItem of connections) {
      index += 1;
      _connections.push({
        index: index.toString(),
        input: [...(pinItem.input || [])],
        output: [...(pinItem.output || [])]
      })
    }
    this.setState({
      connections: _connections,
      model: model || {}
    })
  }

  render = () => {
    const { partNumber, position } = this.props;
    const { connections } = this.state;

    const content = <Panel
      title={`Connections - ${partNumber}${position ? ` - ${position}` : ""}`}
      className='sierra-repeater-connections-panel sierra-panel'
      position='panel-center-left'
      zIndex={2000}
      onCancel={() => { this.saveConnection() }}
      width={700}
      draggable
      minHeight={200}
      maxHeight={800}
    >
      <div className="sierra-repeater-connections-content">
        <EditableTable
          columns={columns}
          dataSource={[...(connections || []), { index: "Add" }]}
          className="sierra-repeater-connections-table"
          rowKey={(record) => record.index}
        />
      </div>
    </Panel>
    return createPortal(content, this.dialogRoot)
  }
}

export default RepeaterConnection;