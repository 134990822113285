import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Spin, Input } from 'antd';
import Panel from '../Panel';
import { TRACE, VIA } from '@/constants/libraryConstants';
import {
  checkLibraryName
} from '@/services/PreLayout/PreLayoutLibrary';
import TraceSetup from './traceSetup';
import ViaSetup from './viaSetup';
import '@/publicCss/style.css';
import '@/publicCss/tree.css';
import '@/publicCss/aurora.css';
import './index.css';
import { SIERRA } from '../../constants/pageType';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../services/helper/panelSizeHelper';

class LibraryPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: null,
      errorMsg: null,
      loading: false,
      maxHeight: 1000,
      maxWidth: 1000,
      isComplete: false
    }

    this.dialogRoot = document.getElementById('root');
  }

  updateLoading = (loading) => {
    this.setState({
      loading
    })
  }

  closeAndSave = (isComplete, config) => {
    this.setState({
      isComplete,
      saveConfig: config
    })
  }

  async closeModal() {
    const { errorMsg } = this.state;
    if (errorMsg) {
      return;
    }
    this.updateLoading(true);
   /*  const { product } = this.props; */
    if (this.Child && this.Child.getConfig) {
      this.setState({
        isComplete: false
      })
      const info = await this.Child.getConfig();
      this.saveInfo = info;
     /*  if (product !== SIERRA || !info || !info.save || info.autoSave) { */
        this.closePanelAndSave(info);
  /*     } */
    }
  }

  closePanelAndSave = (info) => {
    if (!info) {
      this.updateLoading(false);
      /* this.props.closeLibraryPanel(); */
      return;
    }
    const { library, modelType, libraryId } = this.props;
    let config = null, type = modelType, version = "0.0.1", save = false;
    config = info.config;
    type = info.type;
    version = info.version;
    save = info.save;
    const { name } = this.state;
    const error = this.saveName({ target: { value: name } });
    if (error) {
      this.updateLoading(false);
      return;
    }

    const data = {
      id: library && library.id ? library.id : libraryId,
      name,
      type,
      config,
      version
    }
    //save -> data modified
    //!library -> create library
    //name !== library.name -> modified library name
    if (save || !library || name !== library.name) {
      this.props._updateLibraryData(modelType, data);
    }
    this.updateLoading(false);
    this.props.closeLibraryPanel();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 1000) - 20,
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.setLibraryName();
  }

  componentDidUpdate = (prevProps, prevStates) => {
    const { libraryId } = this.props;
    if (libraryId !== prevProps.libraryId) {
      this.setLibraryName()
    }

 /*    const { isComplete, saveConfig } = this.state
    if (isComplete && isComplete !== prevStates.isComplete && saveConfig && this.saveInfo) {
      this.closePanelAndSave({ ...this.saveInfo, config: { ...saveConfig, type: this.saveInfo.signalType } })
    } */
  }

  setLibraryName = () => {
    const { defaultLibraryName, library } = this.props;
    this.setState({
      name: library ? library.name : defaultLibraryName,
      errorMsg: null
    })
  }

  changeName = (e) => {
    const value = e.target.value;
    const { errorMsg } = this.state;
    this.setState({
      name: value,
      errorMsg: errorMsg && errorMsg.type === "name" ? null : errorMsg
    })
  }

  saveName = (e) => {
    const value = e.target.value;
    const { libraryList, library, libraryId } = this.props;
    // Error check
    let error = checkLibraryName({ name: value, libraryList, libraryId: library ? library.id : libraryId });
    if (error) {
      e.target.focus && e.target.focus();
      this.setState({
        errorMsg: error
      });
      return error;
    }
  }

  updateErrorMsg = (errorMsg) => {
    this.setState({
      errorMsg
    })
  }

  onRef = (ref) => {
    this.Child = ref;
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render = () => {
    const { modelName, modelType, product } = this.props;
    const { errorMsg, loading, name, maxHeight, maxWidth } = this.state;
    const content = (
      <Panel
        className='aurora-library-panel'
        position='panel-center-top-40'
        title={modelName}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={getPanelWidth(maxWidth, { defaultWidth: product === SIERRA ? 800 : 600 })}
        // minWidth={400}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        draggable
        minHeight={200}
        overflow={"auto"}
      >
        <div className='library-content-box'>
          <Spin spinning={loading} tip='Saving...'>
            <div className='library-item'>
              {/* name */}
              <label className={modelType === TRACE ? 'library-edit-value-label' : 'library-edit-value-label-via'}>Name</label>
              <Input
                className={modelType === TRACE ? 'aurora-input library-input' : 'aurora-input library-input-via'}
                placeholder='Name'
                value={name}
                onChange={(e) => this.changeName(e)}
                onBlur={(e) => this.saveName(e)}
                onKeyDown={(e) => this.keyDown(e)}
              />
            </div>
            {this.getContent()}
            {errorMsg && errorMsg.msg && <span className='model-name-error-msg'>{errorMsg.msg}</span>}
          </Spin>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  getContent = () => {
    const { modelType, singleMode, library, libraryId } = this.props;
    const { errorMsg, name } = this.state;
    const id = library && library.id ? library.id : libraryId;
    if (modelType === TRACE) {
      return <TraceSetup
        {...this.props}
        onRef={this.onRef}
        updateErrorMsg={this.updateErrorMsg}
        errorMsg={errorMsg}
        libraryId={id}
        name={name}
        updateLoading={this.updateLoading}
        saveName={this.saveName}
        singleMode={singleMode}
        closeAndSave={this.closeAndSave}
      />
    } else if (modelType === VIA) {
      return <ViaSetup
        {...this.props}
        onRef={this.onRef}
        libraryId={id}
        updateErrorMsg={this.updateErrorMsg}
        errorMsg={errorMsg}
      />
    }
  }
}


export default LibraryPanel;