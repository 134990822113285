import CePart from './CePart';
import FootPrintSymbol from './FootPrintSymbol';
import CeGeomHolder from '../geometry/CeGeomHolder';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import StringList from '../utility/StringList';

const CePartDB = function () {
  this.mUnit = "mil";
  this.mGeomHolder = new CeGeomHolder();
  this.mPartList = [];
  this.mSchSymbolList = [];
  this.mFootprintList = [];
};

CePartDB.prototype.ClearAll = function () {
  this.mGeomHolder.Clear();
  this.mPartList = [];
  this.mSchSymbolList = [];
  this.mFootprintList = [];
}

CePartDB.prototype.GetGeometryHolder = function () {
  return this.mGeomHolder;
}

CePartDB.prototype.GetSchematicSymbolIDList = function () {
  var idList = new StringList();
  for (var i = 0; i < this.mSchSymbolList.length; i++) {
    idList.push(this.mSchSymbolList[i].GetSymbolID());
  }
  return idList;
}

CePartDB.prototype.GetFootprintSymbolIDList = function () {
  var idList = new StringList();
  for (var i = 0; i < this.mFootprintList.length; i++) {
    idList.push(this.mFootprintList[i].GetSymbolID());
  }
  return idList;
}

CePartDB.prototype.AddPart = function (partName) {
  var existPart = this.GetPartFromName(partName);
  if (existPart == null) {
    var newPart = new CePart();
    newPart.SetName(partName);
    this.mPartList.push(newPart);
    existPart = newPart;
  }
  return existPart;
}

CePartDB.prototype.GetPartAt = function (ipos) {
  if (ipos >= this.mPartList.size())
    return null;
  return this.mPartList[ipos];
}

CePartDB.prototype.GetPart = function (partName) {
  return this.GetPartFromName(partName);
}

CePartDB.prototype.AddFootPrintSymbol = function () {
  var idList = this.GetFootprintSymbolIDList();
  var newID = idList.GenerateUniqueString(null);
  var futSymbol = new FootPrintSymbol();
  futSymbol.SetID(newID);
  this.mFootprintList.push(futSymbol);
  return futSymbol;
}

CePartDB.prototype.DelSchematicSymbol = function (symbolID) {
  for (var i = 0; i < this.mSchSymbolList.length; i++) {
    if (symbolID == this.mSchSymbolList[i].GetSymbolID()) {
      this.mSchSymbolList.splice(i, 1);
      break;
    }
  }
}

CePartDB.prototype.GetSchematicSymbol = function (id) {
  for (var i = 0; i < this.mSchSymbolList.length; i++) {
    if (id == this.mSchSymbolList[i].GetSymbolID())
      return this.mSchSymbolList[i];
  }
  return null;
}

CePartDB.prototype.GetPartFromName = function (partName) {
  for (var i = 0; i < this.mPartList.length; i++) {
    if (partName == this.mPartList[i].GetName()) {
      return this.mPartList[i];
    }
  }
  return null;
}

CePartDB.prototype.SaveDB = function (myBlock) {
  var partsBlock = new CeIODataBlock("PartList");
  for (var i = 0; i < this.mPartList.length; i++) {
    var partBlk = this.mPartList[i].WriteIntoIODataNode();
    partsBlock.AddBlockNode(partBlk);
  }
  myBlock.AddBlockNode(partsBlock);

  if (this.mSchSymbolList != null && this.mSchSymbolList.length > 0) {
    var symbListBlock = new CeIODataBlock("SymbolList");
    for (var i = 0; i < this.mSchSymbolList.length; i++) {
      var symbolBlk = this.mSchSymbolList[i].WriteIntoIODataNode();
      symbListBlock.AddBlockNode(symbolBlk);
    }
    myBlock.AddBlockNode(symbListBlock);
  }

  if (this.mFootprintList != null && this.mFootprintList.length > 0) {
    var footprintListBlock = new CeIODataBlock("FootprintList");
    for (var i = 0; i < this.mFootprintList.length; i++) {
      var footprintBlk = this.mFootprintList[i].WriteIntoIODataNode();
      footprintListBlock.AddBlockNode(footprintBlk);
    }
    myBlock.AddBlockNode(footprintListBlock);
  }

  return true;
}

CePartDB.prototype.LoadDB = function (myBlock) {

  this.ClearAll();

  var partsBlock = myBlock.GetSubBlock("PartList");
  if (partsBlock == null) {
    console.log("Fail to find 'PartList' block.");
    return false;
  }
  var partBlks = partsBlock.GetAllSubBlocks();
  for (var i = 0; i < partBlks.length; i++) {
    var part = new CePart();
    if (part.ReadFromIODataNode(partBlks[i]))
      this.mPartList.push(part);
  }

  // var symbolsBlock = myBlock.GetSubBlock("SymbolList");
  // if (symbolsBlock != null) {
  // }

  var footprintsBlock = myBlock.GetSubBlock("FootprintList");
  if (footprintsBlock != null) {
    var footprntBlks = footprintsBlock.GetAllSubBlocks();
    for (var i = 0; i < footprntBlks.length; i++) {
      var symbol = new FootPrintSymbol();
      if (symbol.ReadFromIODataNode(footprntBlks[i]) == true) {
        this.mFootprintList.push(symbol);
      }
    }
  }

  return true;
}

CePartDB.prototype.SchematicSymbolExists = function (symbolID) {
  var idList = this.GetSchematicSymbolIDList();
  if (idList == null || idList.contains(symbolID) == false)
    return false;
  return true;
}

CePartDB.prototype.PartExists = function (partName) {
  var part = this.GetPartFromName(partName);
  return part != null;
}

CePartDB.prototype.DeletePart = function (partName) {
  for (var i = 0; i < this.mPartList.length; i++) {
    if (partName == this.mPartList[i].GetName()) {
      this.mPartList.splice(i, 1);
      break;
    }
  }
}

// Footprint related methods

CePartDB.prototype.AddFootPrintSymbol = function (symbolID) {
  var symbol = new FootPrintSymbol();
  symbol.SetID(symbolID);
  this.mFootprintList.push(symbol);
}

CePartDB.prototype.DelFootPrintSymbol = function (symbolID) {
  for (var i = 0; i < this.mFootprintList.length; i++) {
    if (symbolID.equals(this.mFootprintList[i].GetSymbolID()))
      this.mFootprintList.splice(i, 1);
  }
}

CePartDB.prototype.GetFootPrintSymbol = function (id) {
  for (var i = 0; i < this.mFootprintList.length; i++) {
    if (id == this.mFootprintList[i].GetSymbolID())
      return this.mFootprintList[i];
  }
  return null;
}

CePartDB.prototype.FootPrintSymbolExists = function (symbolID) {
  var idList = this.GetFootprintSymbolIDList();
  if (idList == null || idList.contains(symbolID) == false)
    return false;
  return true;
}

CePartDB.prototype.GetUsedSymbols = function () {
  var symbols = new StringList();
  for (var i = 0; i < this.mPartList.length; i++) {
    symbols.push(this.mPartList[i].GetUsedSymbols());
  }
  return symbols;
}

CePartDB.prototype.GetUsedFootprints = function () {
  var footprints = new StringList();
  for (var i = 0; i < this.mPartList.length; i++) {
    var part = this.mPartList[i];
    footprints.push(part.GetUsedFootprints());
  }
  return footprints;
}

CePartDB.prototype.SetUnit = function (unit) {
  this.mUnit = unit;

  // var ceUnit = CsUnits.StringToUnits(unit);
  // DoubleRounding.SetCoorDecimalDigitsByUnits(ceUnit);
  // DoubleRounding.SetConvertToSI(true);
}

CePartDB.prototype.GetAllPartNames = function () {
  var names = new StringList();
  for (var i = 0; i < this.mPartList.length; i++) {
    names.push(this.mPartList[i].GetName());
  }
  return names;
}

export default CePartDB;