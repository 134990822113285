import React, { Component, Fragment } from 'react';
import { FileOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Select, Switch, Tooltip, Divider, TreeSelect } from 'antd';
import {
  ADS_TX,
  SET_ALL_DATA_SELECT_LIST,
  AMISignalPRBSConfig,
  updateParamsValueByCorner,
  adsConfigItemErrorCheck,
  getCPHYIBISModelSelect,
  getCPHYIBISAMIModelSelect,
  updatePRBSOptions
} from '../../../../services/Andes_v2/AMIModelHelper';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import FileContentPanel from '@/components/LibraryUpload/FileContentPanel';
import { getFileContent } from '@/services/Andes_v2/library';
import { IBIS, IBIS_AMI } from '../../../../constants/libraryConstants';
import PRBSSetup from '../SetupComponents/PRBSSetup';
import { END_TO_END_CHANNEL } from '../../../../constants/treeConstants';
import SetupInputGroup, { SetupInputGroupItem } from '../SetupComponents/SetupInputGroup';
import { ADS_RX, CPHY_AMI_PRBS_MODE_LIST, ELECTRICAL, getModelSetupDataList, getPinModelDataList, JITTER, TX_EQ, WAVEFORM } from '../../../../services/Andes_v2/AMIModelHelper';
import IbisEQ from '../IbisEQ';
import { CPHY } from '../../../../services/PCBHelper/constants';
import { CPHYIBISModelConfig } from '../../../../services/Andes_v2/AMIModelHelper/IntegratedConfig';
import { getValueFormEquation } from '../../../../services/Andes_v2/AMIModelHelper/equationCount';
import "./index.css";

const { Option } = Select;

const jitterRadioList = [{
  label: "Enable Random Jitter",
  type: "enableRJ"
}, {
  label: "Enable Periodic Jitter",
  type: "enablePJ"
}, {
  label: "Enable Clock DCD",
  type: "enableClockDCD"
}];

class CPHYIBISModelSetup extends Component {
  constructor(props) {
    super(props);
    const { modelInfo: { model, component, prbs, interfaceType, channelId, dirType }, serdesType } = this.props;
    this.state = {
      applyAll: false,
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new CPHYIBISModelConfig({
        type: interfaceType,
        component,
        channelId,
        modelDir: dirType
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      fileVisible: false,
      libraryComponents: [],
      libraryModels: [],
      modelSelectors: [],
      modelVisible: true,
      fileTreeExpandedKeys: ['IBIS', 'IBIS_AMI']
    }
    this.fileInfo = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.defaultModel();
  }

  componentDidUpdate = (prevProps) => {
    const { modelInfo: { signal, dirType } } = this.props;
    const prevSignal = prevProps.modelInfo ? prevProps.modelInfo.signal : null;
    const prevDirType = prevProps.modelInfo ? prevProps.modelInfo.dirType : null;

    if ((prevSignal !== signal) || (dirType !== prevDirType)) {
      this.defaultModel();
    }
  }

  closeModal = (isClose) => {
    const { applyAll, model, prbs } = this.state;
    const { modelInfo: { dirType, signal } } = this.props;

    let _model = { ...model };
    if (model && model.EQ && dirType === ADS_RX) {
      _model.EQ = getValueFormEquation(model.EQ);
    }
    this.props.closeModal({ model: _model, prbs, signal, dirType, applyAll, isClose });
  }

  defaultModel = () => {
    const { modelInfo: { model, component, prbs, interfaceType, channelId, dirType }, serdesType } = this.props;
    if (!model || !model.libraryId) {
      this.setState({
        model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new CPHYIBISModelConfig({
          type: interfaceType,
          modelType: this.modelType,
          component,
          channelId,
          modelDir: dirType
        }),
        prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
        fileVisible: false,
        libraryComponents: [],
        libraryModels: [],
        modelVisible: true
      })
      return;
    }

    let file = null;
    if (model.libraryType === IBIS_AMI) {
      const fileList = libraryConstructor.getLibraryValues(IBIS_AMI) || [];
      // file = fileList.find(item => item.id === model.libraryId) || {};
      const folder = fileList.find(item => item.id === model.libraryId) || {};
      file = (folder.children || []).filter(item => item.fileName === model.libraryFile)[0]
    } else {
      const fileList = libraryConstructor.getLibraryValues(IBIS) || [];
      file = fileList.find(item => item.id === model.libraryId)
    }

    if (file) {
      this.fileInfo = {
        id: file.id,
        name: file.name
      }
    }

    this.setState({
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new CPHYIBISModelConfig({
        type: interfaceType,
        component,
        channelId,
        modelDir: dirType
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      fileVisible: false,
      libraryComponents: [],
      libraryModels: [],
      modelVisible: true
    }, () => {
      this.getFileParse(file, false);
    })
  }

  changeModelVisible = () => {
    this.setState({ modelVisible: !this.state.modelVisible })
  }

  saveModel = (model) => {
    this.setState({ model })
  }

  updateError = (error) => {
    this.setState({ error })
  }

  changeSpecifyIbisFile = (checked) => {
    const { model } = this.state;
    this.setState({ model: { ...model, specifyIbis: checked } }, () => {
      this.closeModal(false);
    })
  }

  // change waveform/eq/jitter/cphyElectrical/pinModel
  changeModelEleValue = (type, value) => {
    const { model } = this.state;
    const _model = { ...model };
    _model[type] = value;
    this.setState({ model: _model })
  }

  applyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  render() {
    const { modelInfo: { dirType } } = this.props;
    const { model, applyAll, prbs, error, fileVisible } = this.state;
    return <Fragment>
      {<div className="ads-model-select-item">
        <span>Specify IBIS</span>
        <Switch
          size="small"
          className='aurora-switch-small ibis-switch-top-7'
          onChange={this.changeSpecifyIbisFile}
          checked={model.specifyIbis}
        />
      </div>}
      {model.specifyIbis ? this.modelFileSelectRender() : null}
      {dirType === ADS_TX ? this.modelWaveformRender(model.cphyWaveform) : null}
      {dirType === ADS_TX ? this.modelJittersRender(model.cphyJitter) : null}
      {model.EQ ? this.modelEQSRender(model, dirType) : null}
      {dirType === ADS_TX ? this.modelPRBSRender(prbs) : null}
      {this.modelElectricalRender(model.cphyElectrical, dirType)}
      {error ? <span className={error.errorType === "warning" ? "aurora-model-name-warning-msg" : "aurora-error-msg-span"}>{error.error}</span> : null}
      {<div className="ads-model-apply-all-content">
        <span>Apply setting to all {dirType} model</span>
        <Checkbox
          checked={applyAll}
          onChange={(e) => this.applyChange(e)}
        />
      </div>}
      {fileVisible && model.libraryFile.length ? <FileContentPanel
        title={model.libraryFile}
        getLibraryFileContent={getFileContent}
        closeModal={() => this.showFileContent(false)}
        libraryId={model.libraryId}
        fileName={model.libraryFile}
      /> : null}
    </Fragment>
  }

  getFileParse = async (file, isChangeModel) => {
    if (!file) {
      return;
    }

    const { model } = this.state;
    let libraryComponents = null, libraryModels = [], _model = null, modelSelectors = null;

    if (model.libraryType === IBIS) {
      const data = await getCPHYIBISModelSelect(file, model);
      libraryComponents = data.libraryComponents;
      libraryModels = data.libraryModels;
      _model = data.model;
      modelSelectors = data.modelSelectors;
    } else if (model.libraryType === IBIS_AMI) {
      const data = getCPHYIBISAMIModelSelect(file, model);
      libraryComponents = data.libraryComponents;
      libraryModels = data.libraryModels;
      _model = data.model;
      modelSelectors = data.modelSelectors;
    }

    this.setState({
      libraryComponents,
      libraryModels,
      model: isChangeModel ? _model : model,
      modelSelectors
    }, () => {
      isChangeModel && this.closeModal(false);
    })
  }

  modelFileSelectRender = () => {
    const { model, libraryComponents, fileTreeExpandedKeys } = this.state;
    return <>
      {this.getIBISAndAMIFileSelect({
        title: "IBIS File",
        model,
        type: "libraryFile",
        libraryId: model.libraryId,
        ibisList: libraryConstructor.getLibraryValues(IBIS) || [],
        amiList: libraryConstructor.getLibraryValues(IBIS_AMI) || [],
        allowClear: true,
        fileTreeExpandedKeys
      })}
      {this.getSelectComponent({
        title: "Component",
        type: "ibisComponent",
        value: model.ibisComponent,
        list: libraryComponents || []
      })}
      {this.getSelectComponent({
        title: "Corner",
        type: "dataType",
        value: model.dataType,
        list: SET_ALL_DATA_SELECT_LIST,
      })}
      {this.pinAndModelSelectorRender(model.pinModel)}
      {this.usePkgCheckBox(model.usePackage)}
    </>
  }

  changeUsePkg = (e) => {
    const { model } = this.state;
    this.setState({ model: { ...model, usePackage: e.target.checked } })
  }

  usePkgCheckBox = (usePackage) => {
    const { componentPkgInfo, modelInfo: { component, interfaceType, channelId } } = this.props;
    const key = interfaceType === END_TO_END_CHANNEL ? `${component}::${channelId}` : component;
    const disabled = componentPkgInfo && componentPkgInfo[key];
    return <div
      className={`ads-model-select-item`}
      title={disabled ? `Component "${component}" has already set the package model.` : ""}>
      <span>Use Package</span>
      <Checkbox
        className='ami-model-select-checkbox'
        checked={usePackage}
        disabled={disabled}
        onChange={(e) => this.changeUsePkg(e)} />
    </div>
  }

  handlePinSelect = (value, item, pinModel) => {
    const { libraryModels } = this.state;
    const index = value.split("::")[1];
    const libraryModel = libraryModels[Number(index)] || {};

    let _pinModel = { ...pinModel }
    const { parentEleName } = item;
    _pinModel = {
      ...pinModel,
      [parentEleName]: {
        ...pinModel[parentEleName],
        pin: libraryModel.pin,
        isPinDiff: libraryModel.isPinDiff,
        nonPin: libraryModel.nonPin,
        invPin: libraryModel.invPin
      }
    }

    this.changeModelEleValue('pinModel', _pinModel)
  }

  handleModelSelect = (value, item, pinModel) => {
    const { parentEleName } = item;
    const _pinModel = {
      ...pinModel,
      [parentEleName]: { ...pinModel[parentEleName], pinModel: value }
    }

    this.changeModelEleValue('pinModel', _pinModel)
  }

  getPinOptions = () => {
    const { libraryModels } = this.state;
    return libraryModels.map((it, index) => {
      return <Option key={it.pin} value={it.pin + "::" + index}>{it.pin}</Option>
    })
  }

  getModelOptions = () => {
    const { modelSelectors } = this.state;
    let dataList = modelSelectors || [];
    return dataList.map(item => {
      return <Option key={item} value={item}>{item}</Option>
    })
  }

  // pinModel selector
  pinAndModelSelectorRender = (pinModel) => {
    const dataList = getPinModelDataList(pinModel);
    return (
      <div>
        {dataList.map(item => {
          return (
            <div className='ibis-pin-and-selector-flex-box' key={item.pinLabel}>
              <div className='ibis-pin-and-selector-flex-box-pin'>
                <label>{item.pinLabel}</label>
                <Select
                  className="aurora-select"
                  popupClassName="aurora-select-dropdown"
                  value={item.pinValue}
                  onChange={(value) => this.handlePinSelect(value, item, pinModel)}
                >
                  {this.getPinOptions()}
                </Select>
              </div>
              <div className='ibis-pin-and-selector-flex-box-select'>
                <label>Model</label>
                <Select
                  className="aurora-select"
                  popupClassName="aurora-select-dropdown"
                  value={item.modelValue}
                  onChange={(value) => this.handleModelSelect(value, item, pinModel)}
                >
                  {this.getModelOptions()}
                </Select>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  // Waveform
  modelWaveformRender = (waveform) => {
    return <SetupInputGroup
      header="Waveform"
      dataList={getModelSetupDataList(WAVEFORM, { data: waveform, dataType: 'necessary' })}
      childrenPosition="after"
      onChange={(eleName, value) => this.changeModelEleValue('cphyWaveform', { ...waveform, [eleName]: value })}
    >
      {/* Advanced*/}
      <SetupInputGroup
        header="Advanced"
        dataList={getModelSetupDataList(WAVEFORM, { data: waveform, dataType: 'advanced' })}
        collapseClassName="ibis-waveform-advanced-collapse"
        onChange={(eleName, value) => this.changeModelEleValue('cphyWaveform', { ...waveform, [eleName]: value })}
      >
        {/* RiseTime */}
        <div className="setup-input-group-flex-box">
          <div className='ibis-model-waveform-risetime'>
            <span className="ibis-waveform-setup-title">Risetime</span>
            <SetupInputGroupItem
              dataList={getModelSetupDataList(WAVEFORM, { data: waveform, dataType: 'risetime' })}
              onChange={(eleName, value) => this.changeModelEleValue('cphyWaveform', { ...waveform, [eleName]: value })}
            />
          </div>
          <div>
            <span className="ibis-waveform-setup-title">Falltime</span>
            <SetupInputGroupItem
              dataList={getModelSetupDataList(WAVEFORM, { data: waveform, dataType: 'falltime' })}
              onChange={(eleName, value) => this.changeModelEleValue('cphyWaveform', { ...waveform, [eleName]: value })}
            />
          </div>
        </div>
      </SetupInputGroup>
    </SetupInputGroup >
  }

  modelJittersRender = (cphyJitter = {}) => {
    return <SetupInputGroup
      header="Jitters"
      dataList={[]}
      contentClassName="ibis-eq-setup-item"
    >
      {jitterRadioList.map(item => (
        <div key={item.type}>
          <div className='ads-model-select-item ibis-jitters-switch-box'>
            <label>{item.label}</label>
            <Switch
              size="small"
              className='aurora-switch-small ibis-switch-top-7'
              onChange={(checked) => this.changeModelEleValue('cphyJitter', { ...cphyJitter, [item.type]: checked })}
              checked={cphyJitter[item.type]}
            />
          </div>
          {cphyJitter[item.type] ? <>
            <SetupInputGroupItem
              dataList={getModelSetupDataList(JITTER, { data: cphyJitter, dataType: item.type })}
              contentClassName="ibis-eq-setup-item"
              onChange={(eleName, value) => this.changeModelEleValue('cphyJitter', { ...cphyJitter, [eleName]: value })}
            />
            {item.type !== 'enableClockDCD' && <Divider />}
          </> : null}
        </div>
      ))
      }
    </SetupInputGroup>
  }

  // EQ
  modelEQSRender = (model, dirType) => {
    const { bitRate, prbsType } = this.props;
    return <>
      {dirType === ADS_TX ? <SetupInputGroup
        header="EQ"
        dataList={model.enableEQ ? getModelSetupDataList(TX_EQ, { data: model.EQ }) : []}
        onChange={(eleName, value) => this.changeModelEleValue('EQ', { ...model.EQ, [eleName]: value })}
      >
        <div className='ibis-jitters-switch-box'>
          <label>Enable Advanced TxEQ</label>
          <Switch
            size="small"
            className='aurora-switch-small'
            onChange={(checked) => this.changeModelEleValue('enableEQ', checked)}
            checked={model.enableEQ}
          />
        </div>
      </SetupInputGroup > : <IbisEQ
        model={model}
        dirType={dirType}
        serdesType={CPHY}
        error={this.state.error}
        bitRate={bitRate}
        prbsType={prbsType}
        saveModel={this.saveModel}
        updateError={this.updateError}
      />}
    </>
  }

  saveConfigValue = (value, type) => {
    const { prbs } = this.state;
    let _prbs = prbs ? { ...prbs } : {};
    _prbs[type] = value;
    _prbs = updatePRBSOptions(_prbs, value, CPHY)

    this.setState({ prbs: _prbs })
  }

  inputChange = (e, type) => {
    const value = e.target.value;
    const { error } = this.state;
    this.setState({
      error: error && error.type === type ? null : error
    })
    this.saveConfigValue(value, type);
  }

  inputBlur = (e, type) => {
    const value = e.target.value;
    const { error: _error } = adsConfigItemErrorCheck({ value, type });
    if (_error) {
      e.target.focus();
      this.setState({
        error: { type, error: _error }
      })
    } else {
      const { error } = this.state;
      this.setState({
        error: error && error.type === type ? null : error
      })
      this.saveConfigValue(value, type);
    }
  }

  // PRBS
  modelPRBSRender = (prbs) => {
    return <PRBSSetup
      prbs={prbs}
      getSelectComponent={this.getSelectComponent}
      saveConfigValue={this.saveConfigValue}
      inputBlur={this.inputBlur}
      inputChange={this.inputChange}
      modelList={CPHY_AMI_PRBS_MODE_LIST}
    />
  }

  // Electrical
  modelElectricalRender = (cphyElectrical, dirType) => {
    return <SetupInputGroup
      header="Electrical"
      dataList={getModelSetupDataList(ELECTRICAL, { data: cphyElectrical }, dirType)}
      onChange={(eleName, value) => this.changeModelEleValue('cphyElectrical', { ...cphyElectrical, [eleName]: value })}
    />
  }

  showFileContent = (fileVisible) => {
    this.setState({ fileVisible })
  }

  selectChange = (key, type) => {
    const { model } = this.state;
    let _model = { ...model };
    _model[type] = key;
    if (type === "dataType" && _model.setAllData) {
      _model = updateParamsValueByCorner({ model: _model, corner: key, fileInfo: this.fileInfo });
    }
    this.setState({ model: _model })
  }

  getSelectComponent = ({
    title,
    value,
    type,
    list = [],
    className,
    saveValue,
    disabled,
    allowClear
  }) => {
    return (
      <div className={`ads-model-select-item ${className}`}>
        <span>{title}</span>
        <Select
          value={value}
          onChange={(key) => saveValue ? saveValue(key, type) : this.selectChange(key, type)}
          popupMatchSelectWidth={false}
          className={value && type === "libraryFile" ? "aurora-select ads-model-file-select" : "aurora-select"}
          popupClassName="aurora-select-dropdown"
          getPopupContainer={() => document.getElementById('root')}
          disabled={disabled}
          allowClear={allowClear}
        >
          {list.map((item) => (
            this.getSelectOption(type, item)))}
        </Select>
      </div>
    );
  }

  selectIBISFileByTree = (id) => {
    const { model } = this.state;
    const { modelInfo } = this.props;
    let _model = { ...model };
    const libraryList = libraryConstructor.getLibraryValues(IBIS) || [];
    const file = libraryList.find(item => item.id === id);

    if (!file) {
      this.components = [];
      this.fileInfo = {
        id: "",
        name: "",
        fileName: "",
        ibisAmi: {}
      }
      _model = new CPHYIBISModelConfig({
        ...model,
        type: modelInfo.interfaceType,
        component: modelInfo.component,
        channelId: modelInfo.channelId,
        modelDir: modelInfo.dirType,
        libraryId: "",
        libraryName: "",
        libraryFile: "",
        libraryType: IBIS,
        pinModel: {},
        ibisComponent: ""
      });
      this.setState({
        model: _model
      })
      return;
    }

    if (file && file.id === _model.libraryId && file.name === _model.libraryFile) {
      return;
    }

    this.fileInfo = {
      id: file.id,
      name: file.name,
    }
    _model = new CPHYIBISModelConfig({
      ...model,
      type: modelInfo.interfaceType,
      component: modelInfo.component,
      channelId: modelInfo.channelId,
      modelDir: modelInfo.dirType,
      libraryId: file.id,
      libraryName: "",
      libraryFile: file.name,
      libraryType: IBIS,
      ibisComponent: "",
      pinModel: {}
    });
    this.setState({
      model: _model,
      libraryModels: [],
      libraryComponents: []
    }, () => {
      this.getFileParse(file, true);
    })
  }

  selectAMIFileByTree = (file) => {
    const { model } = this.state;
    const { modelInfo } = this.props;
    let _model = { ...model };
    let libraryComponents = [], libraryModels = [];

    if (!file) {
      libraryComponents = [];
      this.fileInfo = {
        id: "",
        name: "",
        fileName: "",
        ibisAmi: {}
      }
      _model = new CPHYIBISModelConfig({
        ...model,
        type: modelInfo.interfaceType,
        component: modelInfo.component,
        channelId: modelInfo.channelId,
        modelDir: modelInfo.dirType,
        libraryId: "",
        libraryName: "",
        libraryFile: "",
        libraryType: IBIS_AMI,
        ibisComponent: "",
        pinModel: {},
      });
      this.setState({
        model: _model
      })
      return;
    }

    if (file && file.id === _model.libraryId && file.childFile === _model.libraryFile) {
      return;
    }

    const currFile = file.children.find(item => item.fileName === file.childFile) || {};
    this.fileInfo = {
      id: file.id,
      name: file.name,
      fileName: file.childFile
    }
    _model = new CPHYIBISModelConfig({
      ...model,
      type: modelInfo.interfaceType,
      component: modelInfo.component,
      channelId: modelInfo.channelId,
      modelDir: modelInfo.dirType,
      libraryId: file.id,
      libraryName: file.name,
      libraryFile: file.childFile,
      libraryType: IBIS_AMI,
      ibisComponent: "",
      pinModel: {}
    });
    this.setState({
      model: _model,
      libraryComponents,
      libraryModels
    }, () => {
      this.getFileParse(currFile, true);
    })
  }

  selectIBISOrAMIFile = (value, node) => {
    const [type, fileName] = value.split(" ");
    if (type === IBIS) {
      this.selectIBISFileByTree(fileName)
    } else {
      const { dataInfo } = node.props;
      this.selectAMIFileByTree(dataInfo)
    }
  }

  clearIBISOrAMIFile = (value) => {
    if (!value) {
      const { model } = this.state;
      const { modelInfo } = this.props;
      const _model = new CPHYIBISModelConfig({
        ...model,
        type: modelInfo.interfaceType,
        component: modelInfo.component,
        channelId: modelInfo.channelId,
        libraryId: "",
        libraryName: "",
        libraryFile: "",
        libraryType: "",
        ibisComponent: "",
        pinModel: {},
      });
      this.setState({
        model: _model,
        libraryModels: [],
        libraryComponents: []
      }, () => {
        this.closeModal(false)
      })
      return;
    }
  }

  clickTreeNode = (key) => {
    const { fileTreeExpandedKeys } = this.state;
    if (fileTreeExpandedKeys.includes(key)) {
      this.setState({ fileTreeExpandedKeys: fileTreeExpandedKeys.filter((k) => k !== key) })
    } else {
      this.setState({ fileTreeExpandedKeys: [...fileTreeExpandedKeys, key] })
    }
  }

  getTreeData = (ibisList, amiList) => {
    return [{
      key: "IBIS",
      value: "IBIS",
      selectable: false,
      title: <span className="ibis-model-ibis-file-treeSelect-title" onClick={() => this.clickTreeNode("IBIS")}>IBIS</span>,
      children: ibisList.map((item) => ({
        key: `${IBIS} ${item.id}`,
        value: `${IBIS} ${item.id}`,
        title: item.name,
        type: IBIS,
        selectable: true,
        isLeaf: true,
        icon: <FileOutlined className='ibis-model-ibis-file-treeIcon' />
      }))
    },
    {
      key: "IBIS_AMI",
      value: "IBIS_AMI",
      selectable: false,
      title: <span className="ibis-model-ibis-file-treeSelect-title" onClick={() => this.clickTreeNode("IBIS_AMI")}>IBIS AMI</span>,
      children: amiList.map((item) => ({
        title: <span className="ibis-model-ibis-file-treeSelect-title" onClick={() => this.clickTreeNode(item.id)}>{item.name}</span>,
        key: item.id,
        selectable: false,
        value: item.id,
        children: item.children.map((it) => ({
          key: `${IBIS_AMI} ${it.fileName}`,
          value: `${IBIS_AMI} ${it.fileName}`,
          title: it.fileName,
          type: IBIS_AMI,
          selectable: true,
          isLeaf: true,
          dataInfo: { ...item, childFile: it.fileName },
          icon: <FileOutlined className='ibis-model-ibis-file-treeIcon' />
        }))
      }))
    }]
  }

  getIBISAndAMIFileSelect = ({
    title,
    model,
    type,
    ibisList = [],
    amiList = [],
    className,
    fileTreeExpandedKeys
  }) => {
    const value = model.libraryType === IBIS && model.libraryId ? `${model.libraryType} ${model.libraryId}`
      : model.libraryType === IBIS_AMI && model.libraryFile ? `${model.libraryType} ${model.libraryFile}` : '';
    return (
      (<div className={`ads-model-select-item ${className}`}>
        <span>{title}</span>
        <TreeSelect
          value={value}
          allowClear
          treeIcon
          className={value && type === "libraryFile" ? "aurora-select ads-model-file-select" : "aurora-select"}
          popupClassName="aurora-select-dropdown ibis-model-ibis-file-treeSelect"
          dropdownStyle={{ maxHeight: '500px' }}
          onChange={this.clearIBISOrAMIFile}
          onSelect={this.selectIBISOrAMIFile}
          treeExpandedKeys={fileTreeExpandedKeys}
          onTreeExpand={(keys) => this.setState({ fileTreeExpandedKeys: keys })}
          treeData={this.getTreeData(ibisList, amiList)}
          virtual={false}
        />
        {value && type === "libraryFile" ?
          <Tooltip overlayClassName="aurora-tooltip" title="Show File Content">
            <InfoCircleOutlined
              className="ads-model-file-content-icon"
              onClick={() => this.showFileContent(true)} />
          </Tooltip>
          : null}
      </div>)
    );
  }

  getSelectOption = (type, item) => {
    switch (type) {
      case "mode":
        return <Option
          key={item.key}
          value={item.key}
          title={item.title}
        >{item.title}</Option>
      default:
        return <Option
          key={item}
          value={item}
          title={item}
        >{item}</Option>
    }

  }
}


export default CPHYIBISModelSetup;