import FutprtMetalLayer from './FutprtMetalLayer';
import FutprtPadTemplate from './FutprtPadTemplate';
import CeLayerBase from './CeLayerBase';
import FutprtPartPad from './FutprtPartPad';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import CeGeomFactory from '../geometry/CeGeomFactory';
import CePolygon from '../geometry/CePolygon';
import CeLine from '../geometry/CeLine';
import CeLinkedMap from '../utility/CeLinkedMap';

var FootPrintSymbol = function () {
  this.mID = "0";
  this.mOutline = null;
  this.mMetalLayerList = [];
  this.mPadTemplates = new CeLinkedMap();
};

FootPrintSymbol.prototype.SetID = function (id) {
  this.mID = id;
}

FootPrintSymbol.prototype.GetPadTemplate = function (padTpltId) {
  return this.mPadTemplates.getValue(padTpltId);
}

FootPrintSymbol.prototype.GetPadsOutline = function () {
  if (this.mMetalLayerList.length > 0)
    return this.mMetalLayerList[0].GetPadsOutline(this);
  return null;
}

FootPrintSymbol.prototype.GetPadGeometries = function () {
  if (this.mMetalLayerList.length > 0)
    return this.mMetalLayerList[0].GetPadGeometries(this);
  return null;
}

FootPrintSymbol.prototype.WriteIntoIODataNode = function () {
  var myBlock = new CeIODataBlock("FootPrintSymbol");
  myBlock.AddDataItem("SymbolID", this.mID);

  var keys = this.mPadTemplates.keySet();
  for (var i = 0; i < keys.length; i++) {
    var padTemplate = this.mPadTemplates.getValue(keys[i]);
    var padTmpBlock = new CeIODataBlock("PadTemplate");
    padTemplate.WriteIntoIODataNode(padTmpBlock);
    myBlock.AddBlockNode(padTmpBlock);
  }

  for (var i = 0; i < this.mMetalLayerList.length; i++) {
    var layerBlock = new CeIODataBlock("MetalLayer");
    this.mMetalLayerList[i].WriteIntoIODataNode(layerBlock);
    myBlock.AddBlockNode(layerBlock);
  }

  if (this.mOutline != null) {
    var geomtype = this.mOutline.GetGeomType();
    myBlock.AddDataItem("OLGeomType", geomtype);
    var outlineNode = this.mOutline.WriteIntoIODataNode();
    outlineNode.SetName("Outline");
    myBlock.AddBlockNode(outlineNode);
  }

  return myBlock;
}

FootPrintSymbol.prototype.ReadFromIODataNode = function (myBlock) {
  this.mID = myBlock.GetItemString("SymbolID");
  var outlineNode = myBlock.GetBlockNode("Outline");
  if (outlineNode != null) {
    var geomtype = myBlock.GetItemString("OLGeomType");
    this.mOutline = CeGeomFactory.CreateGeometryFromType(geomtype);
    if (this.mOutline != null)
      this.mOutline.ReadFromIODataNode(outlineNode);
  }

  var metalLyrBlks = myBlock.GetSubBlocksOfSameName("MetalLayer");
  for (var i = 0; i < metalLyrBlks.length; i++) {
    var metalLayer = new FutprtMetalLayer();
    metalLayer.ReadFromIODataNode(metalLyrBlks[i]);
    this.mMetalLayerList.push(metalLayer);
  }

  var padTmpBlks = myBlock.GetSubBlocksOfSameName("PadTemplate");
  for (var i = 0; i < padTmpBlks.length; i++) {
    var padTemplate = new FutprtPadTemplate();
    padTemplate.ReadFromIODataNode(padTmpBlks[i]);
    this.mPadTemplates.put(padTemplate.GetID().toString(), padTemplate);
  }
  return true;
}

FootPrintSymbol.prototype.GetSymbolID = function () {
  return this.mID;
}

FootPrintSymbol.prototype.AddPartOutline = function (outline) {
  this.mOutline = outline;
}

FootPrintSymbol.prototype.GetPartOutline = function () {
  if (this.mOutline)
    return this.mOutline.ConvertToGCPolygon();

  // The Altium designs stores the outline as line segments in the part's document layer.
  // The following code tries to convert it into a polygon
  var metalLayer = this.mMetalLayerList[0];
  for (var iLayer = 0, numLayer = metalLayer.mLogicLayers.length; iLayer < numLayer; iLayer++) {

    if (metalLayer.mLogicLayers[iLayer].GetType() != CeLayerBase.CeLayerType.DOCUMENT) continue;

    var geomList = metalLayer.mLogicLayers[iLayer].mGeomList.slice(0);
    if (geomList.length < 3) continue;


    // try to construct a polygon
    var outline = new CePolygon();
    var newPointAdded = true;
    var startPoint = null;
    var endPoint = null;
    while (newPointAdded) {

      newPointAdded = false;
      for (var iGeom = 0, numGeom = geomList.length; iGeom < numGeom; iGeom++) {

        var geom = geomList[iGeom].mGeometry;
        if (geom instanceof CeLine) {

          if (endPoint == null) {
            // the polygon is empty, add the line
            startPoint = geom.GetStart();
            outline.AddVertex(geom.GetStart());
            endPoint = geom.GetEnd();
            newPointAdded = true;
          } else if (geom.GetStart().getX() == endPoint.getX() &&
            geom.GetStart().getY() == endPoint.getY()) {
            // the start point matches
            outline.AddVertex(geom.GetStart());
            endPoint = geom.GetEnd();
            newPointAdded = true;
          } else if (geom.GetEnd().getX() == endPoint.getX() &&
            geom.GetEnd().getY() == endPoint.getY()) {
            // the end point matches
            outline.AddVertex(geom.GetEnd());
            endPoint = geom.GetStart();
            newPointAdded = true;
          }

          if (newPointAdded) {

            // check if the polygon is closed
            if (endPoint.getX() == startPoint.getX() &&
              endPoint.getY() == startPoint.getY()) {
              this.mOutline = outline;
              return this.mOutline.ConvertToGCPolygon();
            }

            // remove the point from the list
            geomList.splice(iGeom, 1);
            break;
          }

        } // if (geom instanceof CeLine)

      } // for (var iGeom = 0, numGeom = geomList.length; iGeom < numGeom; iGeom++)

    } // while (newPointAdded)

  } // for (var iLayer = 0, numLayer = metalLayer.mLogicLayers.length; iLayer < numLayer; iLayer++) {

  return null;

}; // FootPrintSymbol.prototype.GetPartOutline

FootPrintSymbol.prototype.SetPadGeomList = function (geomList) {
  var padList = [];
  var id = 1;
  for (var i = 0; i < geomList.length; i++) {
    var localGeom = geomList[i].Clone();
    var center = localGeom.MoveToCenterLocalCS();
    var padTemp = this.AddPadTemplateIfUnexist(localGeom);
    var name = id++;
    var partPad = new FutprtPartPad(name.toString(), padTemp.GetID());
    partPad.SetLocation(center, 0.0);
    padList.push(partPad);
  }
  if (this.mMetalLayerList.length == 0) {
    this.mMetalLayerList.push(new FutprtMetalLayer());
  }
  var metalLayer = this.mMetalLayerList.get(0);
  metalLayer.SetPadList(padList);
}

FootPrintSymbol.prototype.AddPadTemplateIfUnexist = function (padGeom) {
  var tmpList = this.mPadTemplates.values();
  var newID = 0;
  for (var i in tmpList) {
    var pad = tmpList[i];
    newID = newID > pad.GetID() ? newID : pad.GetID();
    if (pad.HasSameGeometry(padGeom))
      return pad;
  }
  newID++;
  var padTemp = new FutprtPadTemplate(newID);
  padTemp.AddGeometry(padGeom);
  this.mPadTemplates.put(newID, padTemp);
  return padTemp;
}

FootPrintSymbol.prototype.GetMetalLayer = function (lyrName, lyrPos, createIt) {
  for (var i = 0; i < this.mMetalLayerList.length; i++) {
    if (lyrName == this.mMetalLayerList[i].GetName() &&
      lyrPos == this.mMetalLayerList[i].GetPosition())
      return metalLayer;
  }
  if (createIt) {
    var metalLayer = new FutprtMetalLayer(lyrName, lyrPos);
    this.mMetalLayerList.push(metalLayer);
    return metalLayer;
  }
  return null;
}

FootPrintSymbol.prototype.AddGeomOnMetalLayer = function (lyrName, lyrPos, geom) {
  var metalLayer = this.GetMetalLayer(lyrName, lyrPos, true);
  metalLayer.AddGeometry(geom);
}

FootPrintSymbol.prototype.AddGeomOnLogicLayer = function (lyrName, lyrPos, logicLayer,
  logicType, geom) {
  var metalLayer = this.GetMetalLayer(lyrName, lyrPos, true);
  metalLayer.AddGeomOnLogicLayer(logicLayer, logicType, geom);
}

FootPrintSymbol.prototype.AddPadTemplate = function (padId) {
  this.mPadTemplates.put(padId, new FutprtPadTemplate(padId));
}

export default FootPrintSymbol;