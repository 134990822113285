import http from '../http';

/**
 * Get rocky channel PDN content by PDNID
 *
 * @export
 * @param {string} PDNID
 * @returns
 */
export function getChannelPDNContentByID(PDNID) {
  return http.get(`/rocky_pdn/${PDNID}/content`);
}

/**
 * update rocky pdn content
 *
 * @export
 * @param {string} PDNID
 * @returns
 */
export function updateRockyPDNContent({ PDNID, pdnContent, readyForSim, version }) {
  return http.put(`/rocky_pdn/content`, {
    content: {
      ...pdnContent
    },
    readyForSim,
    rockyPDNId: PDNID,
    version
  });
}

/**
 * rocky pdn Re-extraction model
 * 
 * @param {String} rockyPDNId PDN ID
 * @export 
 * @returns XMLHttpRequest
 */
export function rockyPDNReExtractionModel(rockyPDNId) {
  return http.put(`/rocky_pdn/reExtraction?rockyPDNID=${rockyPDNId}`);
}

