import { getPreLayoutInfoById } from "./preLayout/preLayoutCtrl";
import { getPreLayoutResultJsonPromise } from "./rockyCtrl";


class preLayoutInfo {
  constructor() {
    this.preLayoutInfo = new Map(); // key - preLayoutId(designId), value - preLayoutInfo
    this.preLayoutResultInfo = new Map();// key - verificationId, value - preLayoutResultInfo
  }

  setInfo = (id, info) => {
    this.preLayoutInfo.set(id, info)
  }

  getInfo = (id) => {
    return this.preLayoutInfo.get(id)
  }

  getPreLayoutInfo = (id, update = false) => {
    return new Promise((resolve, reject) => {
      const info = this.getInfo(id)
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getPreLayoutInfoById(id).then(res => {
          if (res) {
            this.setInfo(id, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  setResultInfo = (id, info) => {
    this.preLayoutResultInfo.set(id, info)
  }

  getResultInfo = (id) => {
    return this.preLayoutResultInfo.get(id)
  }

  getPreLayoutResultJson = (verificationId, update) => {
    return new Promise((resolve, reject) => {
      const info = this.getResultInfo(verificationId);
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getPreLayoutResultJsonPromise(verificationId).then(res => {
          if (res) {
            this.setResultInfo(verificationId, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })

  }

  clearInfo = (isResult) => {
    this.preLayoutResultInfo = new Map();
    if (!isResult) { this.preLayoutInfo = new Map() }
  }
}

const preLayoutContentConstructor = new preLayoutInfo();
export default preLayoutContentConstructor;