import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { DownloadOutlined, SettingOutlined, LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown } from 'antd';
import {
  HIMALAYAS_READ,
  HIMALAYAS_WRITE,
  IO_SIM,
  SPICE_CARD_TEMPLATE
} from "../../../constants/treeConstants";
import { topBarResize } from "../../../services/helper/topBarResize";
import { getTopBarWidth } from "../../../services/Himalayas";
import { reportTopBarTitle } from '../../../components/Report';
import { openTabFooter } from "../../MonitorStore/action";
import { startSpiceCardRun } from "../store/simulation/spiceCardTemplate/action";
import { startIoSimRun } from '../store/simulation/ioSim/action';
import { saveSimOptions } from '../store/ioSim/action';
import ServiceOptionPanel from './ServiceOptionPanel';
import DebugPanel from "./DebugPanel";
// import RunMenu from "./runMenu";
import SimulationOptionPanel from "./SimulationOptionPanel";
import DownloadPanel from "./DownloadPanel";
import Report from "./Report";
import './index.css';

const RUN = "run", DEBUG = "debug", REPORT = "report", RESULT = "result", SERVICES_OPTIONS = "services_options", SIMULATION_OPTIONS = "simulation_options";
const RUN_SSN = "SSN", RUN_DP = "DP", RUN_AC = "AC", DOWNLOAD = "download", RUN_DELAY_WRITE = "DELAY_WRITE", RUN_DELAY_ZL = "DELAY_ZL", RUN_DELAY_ZH = "DELAY_ZH", RUN_DELAY_READ = "DELAY_READ";
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: {
        [DEBUG]: false,
        [REPORT]: false,
        [SERVICES_OPTIONS]: false,
        [SIMULATION_OPTIONS]: false,
        [DOWNLOAD]: false
      },
      simulationLoading: true,
      [RUN_SSN]: true,
      [RUN_DP]: false,
      [RUN_AC]: false,
      [RUN_DELAY_WRITE]: false,
      [RUN_DELAY_ZL]: false,
      [RUN_DELAY_ZH]: false,
      [RUN_DELAY_READ]: false
    }
  }

  screenChange() {
    window.addEventListener('resize', this._changeTopBarSize);
  }

  componentDidMount() {
    this._changeTopBarSize()
    this.screenChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._changeTopBarSize);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.openProjectId !== this.props.openProjectId) {
      this.setState({
        visible: {
          [DEBUG]: false,
          [REPORT]: false,
          [SERVICES_OPTIONS]: false,
          [SIMULATION_OPTIONS]: false
        }
      })
    }
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const dialog = document.getElementById('himalayas-simulation-dropdown');
    if (!dialog || !dialog.contains(target)) {
      this.setState({
        simulationDropdown: false
      })
    }
  }


  _changeTopBarSize = () => {
    topBarResize('himalayas-top-bar', getTopBarWidth(this.props.viewList));
  }

  updateState = (e, key, value) => {
    e && e.stopPropagation()
    if (key === RUN_DP && value) {
      this.setState({
        [RUN_SSN]: true
      })
    } else if (key === RUN_SSN && !value) {
      this.setState({
        [RUN_DP]: false
      })
    }
    this.setState({
      [key]: value
    })
  }

  // runMenu = (runIconLoading, disabledClassName) => {
  //   const { runDropdown } = this.state;
  //   return runDropdown ? <RunMenu
  //     updateState={this.updateState}
  //     runClick={this.runClick}
  //     SSN={this.state[RUN_SSN]}
  //     RUN_SSN={RUN_SSN}
  //     DP={this.state[RUN_DP]}
  //     RUN_DP={RUN_DP}
  //     RUN_AC={RUN_AC}
  //     AC={this.state[RUN_AC]}
  //     RUN_DELAY_WRITE={RUN_DELAY_WRITE}
  //     RUN_DELAY_ZL={RUN_DELAY_ZL}
  //     RUN_DELAY_ZH={RUN_DELAY_ZH}
  //     RUN_DELAY_READ={RUN_DELAY_READ}
  //     DELAY_WRITE={this.state[RUN_DELAY_WRITE]}
  //     DELAY_ZL={this.state[RUN_DELAY_ZL]}
  //     DELAY_ZH={this.state[RUN_DELAY_ZH]}
  //     DELAY_READ={this.state[RUN_DELAY_READ]}
  //     ioSimMode={this.props.ioSimMode}
  //     runIconLoading={runIconLoading}
  //     disabledClassName={disabledClassName}
  //   /> : <Fragment></Fragment>
  // }

  runDropdownRender = (runIconLoading, disabledClassName) => {
    const { ioSimMode } = this.props;
    if (ioSimMode === HIMALAYAS_READ) {
      return this.writeDropdown(runIconLoading, disabledClassName)
    } else if (ioSimMode === HIMALAYAS_WRITE) {
      return this.readDropdown(runIconLoading, disabledClassName)
    } else {
      return []
    }
  }

  readDropdown = (runIconLoading, disabledClassName) => {
    const _simulateActive = this.state[RUN_AC] || this.state[RUN_SSN] || this.state[RUN_DP] ? true : false;
    return [
      {
        key: 'AC',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_AC]}
            onClick={(e) => this.updateState(e, RUN_AC, !this.state[RUN_AC])}
          />
          AC
        </Fragment>
      },
      {
        key: 'SSN',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_SSN]}
            onClick={(e) => this.updateState(e, RUN_SSN, !this.state[RUN_SSN])}
          />
          SSN
        </Fragment>
      },
      {
        key: 'DP',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_DP]}
            onClick={(e) => this.updateState(e, RUN_DP, !this.state[RUN_DP])}
          />
          DP
        </Fragment>
      },
      {
        key: 'Run',
        className: `himalayas-run-dropdown-run ant-dropdown-menu-item ${_simulateActive ? '' : 'himalayas-run-dropdown-run-disabled'}`,
        onClick: (e) => !_simulateActive || runIconLoading || disabledClassName ? null : this.runClick(e),
        label: 'Run'
      }
    ]
  }

  writeDropdown = (runIconLoading, disabledClassName) => {
    const _simulateActive = this.state[RUN_DELAY_WRITE] || this.state[RUN_DELAY_ZL] || this.state[RUN_DELAY_ZH] || this.state[RUN_DELAY_READ] ? true : false;
    return [
      {
        key: 'DELAY_WRITE',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_DELAY_WRITE]}
            onClick={(e) => this.updateState(e, RUN_DELAY_WRITE, !this.state[RUN_DELAY_WRITE])}
          />
          Delay-Write
        </Fragment>
      },
      {
        key: 'DELAY_ZL',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_DELAY_ZL]}
            onClick={(e) => this.updateState(e, RUN_DELAY_ZL, !this.state[RUN_DELAY_ZL])}
          />
          Delay-ZL
        </Fragment>
      },
      {
        key: 'DELAY_ZH',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_DELAY_ZH]}
            onClick={(e) => this.updateState(e, RUN_DELAY_ZH, !this.state[RUN_DELAY_ZH])}
          />
          Delay-ZH
        </Fragment>
      },
      {
        key: 'DELAY_READ',
        className: `ant-dropdown-menu-item himalayas-run-menu-item`,
        label: <Fragment>
          <Checkbox
            className='himalayas-run-dropdown-checkbox'
            checked={this.state[RUN_DELAY_READ]}
            onClick={(e) => this.updateState(e, RUN_DELAY_READ, !this.state[RUN_DELAY_READ])}
          />
          Delay-Read
        </Fragment>
      },
      {
        key: 'Run',
        className: `himalayas-run-dropdown-run ant-dropdown-menu-item ${_simulateActive ? '' : 'himalayas-run-dropdown-run-disabled'}`,
        onClick: (e) => !_simulateActive || runIconLoading || disabledClassName ? null : this.runClick(e),
        label: 'Run'
      }
    ]
  }

  runRender = () => {
    const { simulation } = this.props;
    const { verificationId, view } = this.getView();
    const runIconLoading = simulation[verificationId] ? simulation[verificationId].runIconLoading : false;
    const disabledClassName = view === SPICE_CARD_TEMPLATE ? "himalayas-top-bar-item-disabled" : "";
    const Icon = runIconLoading ? LoadingOutlined : PlayCircleOutlined
    return (
      <Fragment key="run">
        <Dropdown
          menu={{ items: this.runDropdownRender(runIconLoading, disabledClassName), className: "himalayas-run-menu-content" }}
          trigger={['click']}
          placement="bottom"
        >
          <div
            className={`himalayas-top-bar-item ${disabledClassName}`}
            key='run' title='Run'>
            <div className="himalayas-top-bar-setup">
              <Icon className="himalayas-top-bar-icon" />
              <span className='himalayas-top-bar-item-hide'>Run</span>
            </div>
          </div>
        </Dropdown>
      </Fragment>
    );
  }

  getView = () => {
    const { viewList, spiceCardVerificationId, spiceCardChannelId, ioSimVerificationId, ioSimChannelId } = this.props;
    const view = viewList.find(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));
    switch (view) {
      case SPICE_CARD_TEMPLATE:
        return { view, verificationId: spiceCardVerificationId, channelId: spiceCardChannelId }
      case IO_SIM:
        return { view, verificationId: ioSimVerificationId, channelId: ioSimChannelId }
      default: return { view: null, verificationId: null, channelId: null };
    }
  }

  runClick = () => {
    const {/*  spiceCardVerificationId, spiceCardChannelId, */ ioSimVerificationId, ioSimMode } = this.props;
    const { view } = this.getView();
    // Prevent clicking too fast
    if (!this.state.simulationLoading) {
      return
    }
    this.setState({
      simulationLoading: false,
      runDropdown: false
    })
    setTimeout(() => {
      this.setState({
        simulationLoading: true
      })
    }, 1000);
    this.props._openTabFooter()
    switch (view) {
      case SPICE_CARD_TEMPLATE:
        //this.props._startSpiceCardRun(spiceCardChannelId, spiceCardVerificationId);
        break;
      case IO_SIM:

        const types = ioSimMode === HIMALAYAS_READ ? [RUN_DELAY_WRITE, RUN_DELAY_ZL, RUN_DELAY_ZH, RUN_DELAY_READ] : [RUN_AC, RUN_DP, RUN_SSN]
        const workflowTypes = types.filter(item => this.state[item]);
        if (this.state[RUN_DP] && !this.state[RUN_SSN]) {
          workflowTypes.push(RUN_SSN)
        }

        this.props._startIoSimRun(ioSimVerificationId, workflowTypes);
        break;
      default: break;
    }
  }

  reportRender = () => {
    const { visible } = this.state;
    const { reportInfo: { reportProgress, reportVisible } } = this.props;
    return reportTopBarTitle({
      reportPanelVisible: visible[REPORT],
      reportVisible,
      reportProgress,
      className: "himalayas-top-bar-item-hide",
      updateReportPanel: this.updateReportPanel
    })
  }

  updateReportPanel = async (visible) => {
    this.setState({
      visible: { ...this.state, visible, [REPORT]: visible }
    })
    //TODO
    //await this._changeReportConfig();
  }

  resultRender = () => {
    return <div
      className={`himalayas-top-bar-result-item`}
      onClick={() => this.openResult()}
      key='result'>
      <span className='himalayas-top-bar-result-vertical-line'></span>
      <div className='himalayas-top-bar-result-title'>
        <span className='himalayas-top-bar-item-hide'>Result</span>
        <span className='himalayas-top-bar-result-icon'>&gt;</span>
      </div>
    </div>
  }

  openResult = () => {
    const { viewList, spiceCardVerificationId, ioSimVerificationId } = this.props;
    const view = viewList.find(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));

    let id = null;
    if (view === SPICE_CARD_TEMPLATE) {
      id = spiceCardVerificationId;
    } else if (view === IO_SIM) {
      id = ioSimVerificationId;
    }

    if (!view || !id) {
      return;
    }

    //this.props._openPage({ pageType: view, id });
  }

  debugRender = () => {
    return <div
      className='himalayas-top-bar-item'
      onClick={(e) => this.openPanel(e, DEBUG)}
      key='debug' title='Debug'>
      <div className="himalayas-top-bar-setup">
        <i className='iconfont icon-Debug himalayas-top-bar-icon'></i>
        <span className='himalayas-top-bar-item-hide'>Debug</span>
      </div>
    </div>
  }

  servicesOptionsRender = () => {
    return (
      <div
        className='himalayas-top-bar-item'
        onClick={(e) => this.openPanel(e, SERVICES_OPTIONS)}
        key={SERVICES_OPTIONS}
        title='Service Options'
      >
        <div className="himalayas-top-bar-setup">
          <SettingOutlined
            className='himalayas-top-bar-setting himalayas-top-bar-icon'
            title='Services Options' />
          <span className='himalayas-top-bar-item-hide'>Service Options</span>
        </div>
      </div>
    );
  }

  simulationOptionsRender = () => {
    return (
      <div
        className='himalayas-top-bar-item'
        onClick={(e) => this.openPanel(e, SIMULATION_OPTIONS)}
        key={SIMULATION_OPTIONS}
        title='Simulation Options'
      >
        <div className="himalayas-top-bar-setup">
          <SettingOutlined
            className='himalayas-top-bar-setting himalayas-top-bar-icon'
            title='Simulation Options' />
          <span className='himalayas-top-bar-item-hide'>Simulation Options</span>
        </div>
      </div>
    );
  }

  downloadRender = () => {
    return (
      <div
        className='himalayas-top-bar-item'
        onClick={(e) => this.openPanel(e, DOWNLOAD)}
        key={DOWNLOAD}
        title='Download'
      >
        <div className="himalayas-top-bar-setup">
          <DownloadOutlined
            className='himalayas-top-bar-setting himalayas-top-bar-icon'
            title='Download' />
          <span className='himalayas-top-bar-item-hide'>Download</span>
        </div>
      </div>
    );
  }

  openPanel = (e, type) => {
    e && e.stopPropagation();
    this.setState({
      visible: { ...this.state.visible, [type]: true }
    })
  }

  getTopBarOptions = () => {
    const { viewList, openProjectId } = this.props;
    const view = viewList.find(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));
    switch (view) {
      case SPICE_CARD_TEMPLATE:
        return { left: [RUN, REPORT, SERVICES_OPTIONS, RESULT], right: [DEBUG] };
      case IO_SIM:
        return { left: [RUN, REPORT, DOWNLOAD, SIMULATION_OPTIONS, SERVICES_OPTIONS, RESULT], right: [DEBUG] };
      default:
        return openProjectId ? { left: [SERVICES_OPTIONS], right: [DEBUG] } : { left: [], right: [] }
    }
  }

  closePanel = (type) => {
    this.setState({
      visible: { ...this.state.visible, [type]: false }
    })
  }

  render = () => {
    const { width, openProjectId, ioSimChannelId, ioSimOptions, ioSimMode } = this.props;
    const { visible } = this.state;
    const { left = [], right = [] } = this.getTopBarOptions();
    const { view, verificationId, channelId } = this.getView();
    return <Fragment>
      {left.length || right.length ?
        <div id='himalayas-top-bar' style={{ width: width ? width : '100%' }}>
          <Fragment>
            <div className='himalayas-top-bar-left-item' id='himalayas-left-top-bar'>
              {left.map(item => {
                switch (item) {
                  case RUN: return this.runRender();
                  case REPORT: return this.reportRender();
                  case SERVICES_OPTIONS: return this.servicesOptionsRender();
                  case SIMULATION_OPTIONS: return this.simulationOptionsRender();
                  case RESULT: return this.resultRender();
                  case DOWNLOAD: return this.downloadRender();
                  default: return null;
                }
              })}
            </div>
            <div className='himalayas-top-bar-right-item' id='himalayas-right-top-bar'>
              {right.map(item => {
                switch (item) {
                  case DEBUG: return this.debugRender();
                  default: return null;
                }
              })}
            </div>
          </Fragment>
        </div>
        : null
      }
      {visible[DEBUG] ? <DebugPanel
        closePanel={() => this.closePanel(DEBUG)}
        view={view}
        verificationId={verificationId}
        projectId={openProjectId}
        channelId={channelId}
      /> : null}
      {visible[SERVICES_OPTIONS] ? <ServiceOptionPanel
        closePanel={() => this.closePanel(SERVICES_OPTIONS)}
      /> : null}
      {visible[SIMULATION_OPTIONS] ? <SimulationOptionPanel
        closePanel={() => this.closePanel(SIMULATION_OPTIONS)}
        channelId={ioSimChannelId}
        simOptions={ioSimOptions}
        saveSimOptions={this.props.saveSimOptions}
      /> : null}
      {visible[DOWNLOAD] ? <DownloadPanel
        closePanel={() => this.closePanel(DOWNLOAD)}
        view={view}
        verificationId={verificationId}
        channelId={channelId}
        projectId={openProjectId}
        ioSimMode={ioSimMode}
      /> : null}
      {visible[REPORT] ? <Report
        closePanel={() => this.closePanel(REPORT)}
        view={view}
        verificationId={verificationId}
        channelId={channelId}
      /> : null}
    </Fragment>
  }
}
const mapState = (state) => {
  const { HimalayasReducer: {
    project: { openProjectId, viewList, reportInfo = {} },
    spiceCard: {
      verificationId: spiceCardVerificationId,
      channelId: spiceCardChannelId },
    ioSim: {
      verificationId: ioSimVerificationId,
      channelId: ioSimChannelId,
      simOptions: ioSimOptions,
      mode: ioSimMode },
    simulation
  } } = state;
  return {
    openProjectId,
    viewList,
    reportInfo,
    spiceCardChannelId,
    spiceCardVerificationId,
    simulation,
    ioSimVerificationId,
    ioSimChannelId,
    ioSimOptions,
    ioSimMode
  }
}

const mapDispatch = (dispatch) => ({
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _startSpiceCardRun(channelId, verificationId) {
    dispatch(startSpiceCardRun(channelId, verificationId))
  },
  _startIoSimRun(verificationId, workflowTypes) {
    dispatch(startIoSimRun(verificationId, workflowTypes))
  },
  saveSimOptions(channelId, options, allInterfaces) {
    dispatch(saveSimOptions(channelId, options, allInterfaces))
  }
})

export default connect(mapState, mapDispatch)(TopBar);