import React, { Component, Fragment, createRef } from 'react'
import { connect } from 'react-redux'
import { SettingOutlined, LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { message, Dropdown, Tooltip, Checkbox } from 'antd';
import { changeView, changeTreeSelected, changeViewList, updatePDNReportConfig, updatePDNReoprtMessage, forcePreviewShow, changeLayout, updateSimLoading } from '../../../store/project/action';
import { getPDNContent, saveCurrentPDN, updateSimConfig, savePreviewStatus } from '../../../store/pdn/action';
import { openTabFooter, changeTabMenu, changeVerification } from '../../../../tabMonitor/action';
import { startPDNVerification, pdnCheckError, getCurrentPDNLog, pdnStackupCheckError, changeUploadMes, cleanSingleProgress, getVRMModelUpdate } from '../../../store/simulation/action';
import { PDN } from '../../../constants';
import { saveConfig } from '../../../store/pdn/action';
import { downloadDebugMsg } from '../../../store/project/action';
import { PDNDebugVerify } from '../../../store/pdn/action';
import { UploadDebug, getPDNReportConfigpromise } from '@/services/PDN/projectCtrl';
import ReportPanel from './report'
import ExtractionPanel from './ExtractionPanel';
import { topBarResize } from '@/services/helper/topBarResize';
import DelConfirm from '@/components/DelConfirm';
import { strDelimited } from '@/services/helper/split';
import SimulatePanel from './SimulatePanel'
import DebugDownloadPanel from './DebugDownloadPanel'
import projectDesigns from '@/services/helper/projectDesigns';
import { getPowerNetsBoundaryArr } from '@/services/helper/cutDesignHelper';
import preview, { cleanPreview } from '@/services/helper/cutDesign/preview';
import { LEFT_RIGHT_lAYOUT } from '@/constants/layoutConstants';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';

const SETUP = 1, EXTRACTION = 2, SIMULATION = 3, RESULT = 4;
class TopBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null,
      extractionPanelVisible: false,
      reportPanelVisible: false,
      translationVisible: false,
      saveHistoryMsg: null,
      saveHistoryPDNInfo: null,
      openSaveHistoryPrompt: false,
      reportProgressVisible: false,
      simulatePanelVisible: false,
      debugDownloadVisible: false
    }
    this.dialogRoot = document.getElementById('root');
    this.uploadDebugRef = createRef();
  }

  previewBtnOnRef = (ref) => {
    this.previewBtnChild = ref;
  }

  screenChange() {
    window.addEventListener('resize', this._changeTopBarSize);
  }

  componentDidMount() {
    this._changeTopBarSize()
    this.screenChange();
    if (this.props.previewStatus) {
      this.setState({
        extractionPanelVisible: true,
      })
      setTimeout(() => {
        const { pdnContent } = this.props;
        const config = pdnContent ? pdnContent.Config : null;
        if (config && config.ratio !== undefined) {
          this.changePreviewShowState(true);
          this.showBoundary(config.ratio);
        }
      }, 50)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._changeTopBarSize);
  }

  componentDidUpdate(prevProps) {
    const { forcePreviewShow } = this.props;
    if (!prevProps.forcePreviewShow && forcePreviewShow) {
      this.previewBtnChild.setPreviewBtnLoadState(false);
    }
  }

  _changeTopBarSize = () => {
    topBarResize('pdn-top-bar-block', 650);
  }

  judgeHistory = (verificationId) => {
    //Determine whether history exists
    const { currentProjectPDNs } = this.props;
    const currentPdn = currentProjectPDNs.find(item => item.verificationId === verificationId);
    if (currentPdn && currentPdn.result && !currentPdn.historyExist) {
      return false;
    } else {
      return true;
    }
  }

  startVerification = (id) => {
    //Determine whether history exists
    const historyExist = this.judgeHistory(id);
    if (historyExist) {
      //save current pdn ,and do simulation
      this.props._saveCurrentPDN([id], id);
    } else {
      //Prompt the user whether to save the history
      this.openSaveHistoryPrompt(id);
    }
  }

  simulateClick = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    this.props._openTabFooter();
    const { selectedKeys, PDNID, treeSelectedKeys, verificationId } = this.props;

    // Prevent clicking too fast  
    this.props.updateSimLoading(true)

    if (selectedKeys.length === 0 && verificationId) {
      this.startVerification(verificationId);
      return;
    };
    if (selectedKeys.length === 0) {
      this.props.updateSimLoading(false)
      return;
    };
    if (selectedKeys.length > 1) {
      message.error('Multiple PDN simulations: Please submit one by one!');
      this.props.updateSimLoading(false)
      return;
    };
    if (selectedKeys.length === 1) {
      if (PDNID !== selectedKeys[0].id || treeSelectedKeys.includes(`result-${selectedKeys[0].id}-${selectedKeys[0].verificationId}`)) {
        //Determine whether history exists
        const historyExist = this.judgeHistory(selectedKeys[0].verificationId);
        if (historyExist) {
          //open selected pdn, and do simulation
          this.openPDN(selectedKeys[0].id);
          this.props._startPDNVerification([selectedKeys[0].verificationId]);
        } else {
          //Prompt the user whether to save the history
          this.openSaveHistoryPrompt(selectedKeys[0].verificationId, true);
        }
      } else {
        this.startVerification(selectedKeys[0].verificationId);
      }
    }
  }

  openSaveHistoryPrompt = (verificationId, open) => {
    const { currentProjectPDNs } = this.props;
    const currentPdn = currentProjectPDNs.find(item => item.verificationId === verificationId);
    const saveHistoryMsg = <span>The PDN {<span className="font-bold">{currentPdn.name}</span>} result is not saved to history, whether to continue simulate?</span>;
    this.setState({
      openSaveHistoryPrompt: true,
      saveHistoryMsg,
      saveHistoryPDNInfo: currentPdn,
      pdnOpen: open
    })
  }

  _onSimulate = () => {
    const { pdnOpen, saveHistoryPDNInfo } = this.state;
    if (pdnOpen) {
      //open selected pdn, and do simulation
      this.openPDN(saveHistoryPDNInfo.id);
      this.props._startPDNVerification([saveHistoryPDNInfo.verificationId]);
    } else {
      //save current pdn ,and do simulation
      this.props._saveCurrentPDN([saveHistoryPDNInfo.verificationId], saveHistoryPDNInfo.verificationId);
    }
    this.clearSaveHistoryPrompt();
  }

  clearSaveHistoryPrompt = () => {
    this.setState({
      openSaveHistoryPrompt: false,
      saveHistoryMsg: null,
      saveHistoryPDNInfo: null,
      pdnOpen: false
    })
    this.props.updateSimLoading(false)
  }

  openPDN = (PDNID) => {
    const { currentProjectPDNs, currentProjectId, viewList, layout } = this.props;
    const index = currentProjectPDNs.findIndex(item => item.id === PDNID);
    let pdnName = null, verificationId = null;
    if (typeof (index) !== 'boolean' && index > -1) {
      pdnName = currentProjectPDNs[index].name;
      verificationId = currentProjectPDNs[index].verificationId;
    }
    this.props._pdnCheckError(null);
    this.props._pdnStackupCheckError(null);
    this.props._viewChange('PDN', { verificationId, PDNID });
    let list = [...viewList, 'PDN'];
    list = [...new Set(list)];
    list = list.filter(item => item !== 'result');
    if (layout === 3) {
      list = ['PDN']
    }
    this.props._changeViewList(list);
    const { treeSelectedKeys } = this.props;
    if (treeSelectedKeys.length > 0) {
      let newKeys = [];
      treeSelectedKeys.forEach((item, index) => {
        const [key] = strDelimited(item, "-");
        if (key !== PDN && key !== 'result') {
          newKeys.push(item);
        }
      });
      newKeys.push(`${PDN}-${PDNID}`);
      this.props._changeTreeSelected([...newKeys]);
    } else {
      this.props._changeTreeSelected([`${PDN}-${PDNID}`])
    }

    this.props._getPDNContent(PDNID);
    this.props._getVRMModelUpdate(currentProjectId, PDNID);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId });
    this.props._changeVerification(pdnName);
    this.props._openTabFooter();
  }

  openExtractionPanel = () => {
    if (!this.props.verificationId) return;
    this.setState({
      extractionPanelVisible: true
    })
  }

  openSimulatePanel = () => {
    if (!this.props.verificationId) return;
    this.setState({
      simulatePanelVisible: true
    })
  }

  openReportPanelOperation = () => {
    const { currentProjectId, reportConfig, currentProjectPDNs } = this.props
    const { templateId } = reportConfig;
    if (!currentProjectId) {
      return;
    }
    let _templateId = templateId ? templateId : '';
    let _verificationSelect = ['all']
    let defaultConfig = {
      templateId: _templateId,
      verificationSelect: _verificationSelect,
      projectId: currentProjectId,
    }
    getPDNReportConfigpromise(currentProjectId).then(res => {
      if (res && Object.keys(res).length > 0) {
        defaultConfig = { ...res };
        if (!defaultConfig.templateId) {
          defaultConfig.templateId = _templateId;
        }
        if (!defaultConfig.verificationSelect) {
          if (res.pdnIds.length > 0) {
            let currents = currentProjectPDNs.filter(obj => res.pdnIds.some(obj1 => obj1 === obj.id))
            defaultConfig.verificationSelect = currents.map(d => d.name);
          }
          else {
            defaultConfig.verificationSelect = _verificationSelect;
          }
        }
      };
      this.props._updatePDNReportConfig(defaultConfig);
    }, error => {
      this.props._updatePDNReportConfig(defaultConfig);
    })
  }

  openReportPanel = () => {
    const { currentProjectPDNs, verificationId } = this.props
    if (!verificationId && !currentProjectPDNs.length) return;
    this.openReportPanelOperation()
    this.props._updatePDNReoprtMessage("")
    this.setState({
      reportPanelVisible: true,
      reportProgressVisible: false,
    })
  }
  PDNminiConfig = () => {
    this.setState({
      reportProgressVisible: true
    })
  }

  closeExtractionPanel = () => {
    this.setState({
      extractionPanelVisible: false
    })
    this.props._savePreviewStatus(false)
  }

  closeSimulatePanel = () => {
    this.setState({
      simulatePanelVisible: false
    })
  }

  closeReportPanel = () => {
    this.setState({
      reportPanelVisible: false
    })
  }

  openDebugDownloadModal = () => {
    this.setState({
      debugDownloadVisible: true
    })
  }

  closeDebugDownloadPanel = () => {
    this.setState({
      debugDownloadVisible: false
    })
  }

  UploadDebugClick = (e, status) => {
    const el = this.uploadDebugRef.current;
    this.setState({
      status: status
    })
    this.props._changeUploadMes(null);
    if (!el) return;
    el.click();
  }

  onUploadDebug = (e) => {
    const { status } = this.state;
    const file = e.target.files[0];
    let type = null;
    switch (status) {
      case SETUP: type = 'setup'; break;
      case EXTRACTION: type = 'extraction'; break;
      case SIMULATION: type = 'simulation'; break;
      case RESULT: type = 'result'; break;
      default: break;
    }
    const { currentVerificationId } = this.props;
    UploadDebug(file, currentVerificationId, status, this.UploadProgress).then(res => {
      let msg = null;
      if (res.status === 'success') {
        msg = res.msg;
      } else {
        msg = `Debug (${type}) file upload failed!`
      }
      msg = `==> ${msg}`;
      this.props._changeUploadMes(msg);
      this.props._cleanSingleProgress(currentVerificationId);
    }, error => {
      let msg = `Debug (${type}) file upload failed!`;
      msg = `==> ${msg}`;
      this.props._changeUploadMes(msg);
      this.props._cleanSingleProgress(currentVerificationId);
    });
    this.uploadDebugRef.current.value = "";
  }

  verifyClick = (e, status) => {
    const { currentVerificationId } = this.props;
    this.props._PDNDebugVerify(status, currentVerificationId);
  }

  updateSimulationConfig = (e, type, prevCheck) => {
    e && e.stopPropagation()
    const check = prevCheck ? 0 : 1;
    this.props.updateSimConfig({ [type]: check })
  }

  debugMenu = () => {
    const { verificationName, currentVerificationId } = this.props;
    const menu = [
      { key: 'debug_download_panel', label: 'Download', onClick: (e) => this.openDebugDownloadModal(e) }
    ]
    if (verificationName && currentVerificationId) {
      menu.push(...[
        {
          key: 'debug_upload',
          label: 'Upload',
          children: [
            { key: 'upload_setup', label: 'Setup', onClick: (e) => this.UploadDebugClick(e, SETUP) },
            { key: 'upload_extraction', label: 'Extraction', onClick: (e) => this.UploadDebugClick(e, EXTRACTION) },
            { key: 'upload_simulation', label: 'Simulation', onClick: (e) => this.UploadDebugClick(e, SIMULATION) },
            { key: 'upload_result', label: 'Result display', onClick: (e) => this.UploadDebugClick(e, RESULT) }
          ]
        },
        {
          key: 'verify',
          label: 'Verify',
          children: [
            { key: 'verify_setup', label: 'Setup', onClick: (e) => this.verifyClick(e, SETUP) },
            { key: 'verify_extraction', label: 'Extraction', onClick: (e) => this.verifyClick(e, EXTRACTION) },
            { key: 'verify_simulation', label: 'Simulation', onClick: (e) => this.verifyClick(e, SIMULATION) },
          ]
        }
      ])
    }
    return menu
  }

  SimRender = (simulateActive) => {
    const { simulationLoading } = this.props
    return (
      <Tooltip title="Simulate" overlayClassName="pdn-top-bar-tip">
        {simulationLoading ? <LoadingOutlined /> : <PlayCircleOutlined />}
        <span className='pdn-top-bar-item-hide'>Simulate</span>
      </Tooltip>
    );
  }

  disableDCFlow = () => {
    const { pdnContent } = this.props;
    if (pdnContent && pdnContent.SimConfig) {
      this.props.updateSimConfig({ DC: 0, AC: 1 });
    }
  }

  simMenu = (SimConfig, simulateActive, config) => {
    let AC = 1, DC = 0;
    if (SimConfig) {
      AC = SimConfig.AC;
      DC = SimConfig.DC;
    }
    const _simulateActive = AC || DC ? simulateActive : false;
    const hasDC = config && SimConfig ? true : false;
    const { simulationLoading } = this.props;
    return [
      {
        key: 'AC',
        label: <Fragment>
          <Checkbox
            className='pdn-sim-dropdown-checkbox'
            checked={AC}
            onClick={(e) => hasDC ? this.updateSimulationConfig(e, 'AC', AC) : null}
          />
          AC Impedance
        </Fragment>,

      },
      {
        key: 'DC',
        label: <Fragment>
          <Checkbox
            className='pdn-sim-dropdown-checkbox'
            checked={DC}
            disabled={hasDC ? false : true}
            onClick={(e) => hasDC ? this.updateSimulationConfig(e, 'DC', DC) : null}
          />
          DC IR Drop
        </Fragment>,

      },
      {
        key: 'Run',
        label: 'Run',
        className: `pdn-sim-dropdown-run ${_simulateActive ? '' : 'pdn-sim-dropdown-run-disabled'}`,
        onClick: (e) => _simulateActive && !simulationLoading ? this.simulateClick(e) : null
      }
    ]
  }

  // TODO
  showPreview = (ratio) => {
    const { pdnInfo, layout, selectedDesignIDs, viewList, treeSelectedKeys } = this.props;
    const designId = pdnInfo ? pdnInfo.designId : null;

    if (!this.previewBtnChild.state.previewShow) {
      cleanPreview(designId);
      return;
    }
    this.forcePreview = this.previewBtnChild.state.previewShow;

    if (layout === 3 || !selectedDesignIDs.includes(designId)) {
      this.props.changeLayout(LEFT_RIGHT_lAYOUT);
      this.props._viewChange('PCB', { designId });
      let list = [];
      list = [...viewList, 'PCB'];
      list = [...new Set(list)];
      this.props._changeViewList(list);

      let keys = [...treeSelectedKeys];
      keys = keys.filter(d => !d.includes('PCB-'));
      keys.push(`PCB-${designId}`);
      this.props._changeTreeSelected(keys);
      return;
    }

    this.showBoundary(ratio);
  }

  showBoundary = (ratio) => {
    const { pdnContent, pdnInfo } = this.props;
    const designId = pdnInfo ? pdnInfo.designId : null;
    const powerNets = pdnContent ? pdnContent.PowerNets : null;
    if (powerNets && powerNets.length && designId) {
      const referenceNets = pdnContent ? pdnContent.ReferenceNets : null;
      const components = pdnContent ? pdnContent.Components : []
      const data = getPowerNetsBoundaryArr({ ratio, nets: powerNets, designId, referenceNets, components });
      preview(data, designId);
      this.previewBtnChild.setPreviewBtnLoadState(false);
    }
  }

  changePreviewShowState = (state) => {
    this.previewBtnChild && this.previewBtnChild.setPreviewShowState(state);
  }

  render() {
    const { width, verificationId, reportVisible, selectedKeys, pdnContent, reportProgress, currentProjectId, PDNID, currentProjectPDNs, pdnInfo, currentProjectName } = this.props;
    const config = pdnContent ? pdnContent.Config : null;
    const { extractionPanelVisible, simulatePanelVisible, reportPanelVisible, reportProgressVisible, openSaveHistoryPrompt, saveHistoryPDNInfo, saveHistoryMsg, debugDownloadVisible } = this.state;
    const simulateActive = (
      (selectedKeys && selectedKeys.length && selectedKeys.length === 1) ||
      verificationId
    ) ? true : false;
    const currentProjectDesigns = projectDesigns.getDesigns(currentProjectId);
    const SimConfig = pdnContent ? pdnContent.SimConfig : null;
    const powerNets = pdnContent ? pdnContent.PowerNets : null;
    return (
      <Fragment>
        <div id="pdn-top-bar-block" style={{ width: width ? width : '100%' }}>
          {PDNID ? <Dropdown
            menu={{ items: this.simMenu(SimConfig, simulateActive, config), className: 'upload-dropdown-button pdn-sim-dropdown' }}
            trigger={['click']}
            placement="bottomLeft"
          >
            <div className={simulateActive ? 'pdn-top-bar-item' : 'pdn-top-bar-item pdn-top-bar-item-disabled'} onClick={simulateActive ? this.showSimDropdown : null}>
              {this.SimRender(simulateActive)}
            </div>
          </Dropdown> : null}
          {reportVisible && reportProgressVisible ? <Fragment>
            <div className={verificationId || currentProjectPDNs.length ? 'pdn-top-bar-item PDN-top-sus-report-item' : 'pdn-top-bar-item pdn-top-bar-item-disabled'}
              onClick={verificationId || currentProjectPDNs.length ? this.openReportPanel : null}
              style={reportVisible ? { background: '#c7e0f4' } : {}}
            >
              <Tooltip title="Report" overlayClassName="pdn-top-bar-tip">
                <span className="iconfont icon-baogao PDN-top-sus-report-icon" title='Report'></span>
                <span className='pdn-top-bar-item-hide'>Report</span>
              </Tooltip>
              <div className='PDN-top-sus-report-progress' style={{ width: `${reportProgress}%` }} />
            </div>
            <span className='PDN-report-progress-span'>{reportProgress}%</span>
          </Fragment>
            :
            <div
              className={verificationId || currentProjectPDNs.length ? 'pdn-top-bar-item' : 'pdn-top-bar-item pdn-top-bar-item-disabled'}
              onClick={(verificationId || currentProjectPDNs.length) ? this.openReportPanel : null}
              style={reportVisible ? { background: '#c7e0f4' } : {}}
            >
              <Tooltip title="Report" overlayClassName="pdn-top-bar-tip">
                <span className="iconfont icon-baogao PDN-top-sus-report-icon" title='Report'></span>
                <span className='pdn-top-bar-item-hide'>Report</span>
              </Tooltip>
            </div>
          }
          {PDNID ? <Fragment><div
            className={verificationId ? 'pdn-top-bar-item' : 'pdn-top-bar-item pdn-top-bar-item-disabled'}
            onClick={this.openExtractionPanel}
          >
            <Tooltip title="Extraction" overlayClassName="pdn-top-bar-tip">
              <SettingOutlined />
              <span className='pdn-top-bar-item-hide'>Extraction</span>
            </Tooltip>
          </div>
            <div
              className={verificationId ? 'pdn-top-bar-item' : 'pdn-top-bar-item pdn-top-bar-item-disabled'}
              onClick={this.openSimulatePanel}
            >
              <Tooltip title="Options" overlayClassName="pdn-top-bar-tip">
                <SettingOutlined />
                <span className='pdn-top-bar-item-hide'>Options</span>
              </Tooltip>
            </div></Fragment> : null}
          <Dropdown menu={{ items: this.debugMenu(), className: 'upload-dropdown-button' }} trigger={['click']} placement="bottomLeft">
            <div className='pdn-top-bar-item'>
              <Tooltip title="Debug" overlayClassName="pdn-top-bar-tip">
                <i className='iconfont icon-Debug'></i>
                <span className='pdn-top-bar-item-hide'>Debug</span>
              </Tooltip>
            </div>
          </Dropdown>
        </div>
        {
          reportPanelVisible &&
          <ReportPanel
            closeModal={this.closeReportPanel}
            saveConfig={this.props._saveConfig}
            miniConfig={this.PDNminiConfig}
            Config={config}
          />
        }
        {
          extractionPanelVisible &&
          <ExtractionPanel
            closeModal={this.closeExtractionPanel}
            saveConfig={this.props._saveConfig}
            disableDCFlow={this.disableDCFlow}
            Config={config}
            powerNets={powerNets}
            designId={pdnInfo ? pdnInfo.designId : null}
            showPreview={this.showPreview}
            previewBtnOnRef={this.previewBtnOnRef}
            savePreviewStatus={this.props._savePreviewStatus}
            changePreviewShowState={this.changePreviewShowState}
          />
        }
        {
          simulatePanelVisible &&
          <SimulatePanel
            closeModal={this.closeSimulatePanel}
            saveConfig={this.props._saveConfig}
            Config={config}
          />
        }
        {debugDownloadVisible &&
          <DebugDownloadPanel
            closeModel={this.closeDebugDownloadPanel}
            currentProjectPDNs={currentProjectPDNs}
            currentProjectDesigns={currentProjectDesigns}
            PDNID={PDNID}
            currentProjectName={currentProjectName}
          />
        }
        <input
          type='file'
          ref={this.uploadDebugRef}
          style={{ display: 'none' }}
          accept='.zip'
          onChange={(e) => this.onUploadDebug(e)}
        />
        {openSaveHistoryPrompt ? <DelConfirm
          type={"simulate"}
          data={saveHistoryPDNInfo}
          onSimulate={this._onSimulate}
          cancelSim={this.clearSaveHistoryPrompt}
          message={saveHistoryMsg}
        /> : null}
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { PDNReducer: {
    project: { currentProjectId, reportProgress, reportVisible, reportConfig, verificationId, viewList, layout, currentProjectPDNs, PDNID, treeSelectedKeys, forcePreviewShow, currentProjectName, simulationLoading },
    simulationReducer: { selectedKeys },
    pdn },
    TabMonitorReducer: { verificationName, currentVerificationId }
  } = state;
  const pdnContent = pdn.pdnInfo ? pdn.pdnInfo.pdnContent : null;
  const { previewStatus } = pdn;
  return {
    currentProjectId,
    reportConfig,
    verificationId,
    viewList,
    layout,
    currentProjectPDNs,
    PDNID,
    selectedKeys,
    treeSelectedKeys,
    pdnContent,
    pdnInfo: pdn.pdnInfo,
    verificationName,
    currentVerificationId,
    reportProgress,
    reportVisible,
    forcePreviewShow,
    previewStatus,
    selectedDesignIDs: getSelectedDesignIDs(treeSelectedKeys),
    currentProjectName,
    simulationLoading
  }
}

const mapDispatch = (dispatch) => ({
  _viewChange(view, info) {
    dispatch(changeView(view, info));
  },
  _getPDNContent(PDNID) {
    dispatch(getPDNContent(PDNID));
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId, verificationName }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId, verificationName }))
  },
  _startPDNVerification(verificationIds) {
    dispatch(startPDNVerification(verificationIds))
  },
  _changeVerification(verificationName) {
    dispatch(changeVerification(verificationName));
  },
  _pdnCheckError(errorCheck) {
    dispatch(pdnCheckError(errorCheck))
  },
  _pdnStackupCheckError(stackupCheck) {
    dispatch(pdnStackupCheckError(stackupCheck))
  },
  _changeTreeSelected(treeSelectedKeys) {
    dispatch(changeTreeSelected(treeSelectedKeys))
  },
  _getCurrentPDNLog(verificationId) {
    dispatch(getCurrentPDNLog(verificationId))
  },
  _changeViewList(viewList, viewType) {
    dispatch(changeViewList(viewList, viewType))
  },
  _saveCurrentPDN(verificationIds, verificationId) {
    dispatch(saveCurrentPDN(verificationIds, verificationId))
  },
  _saveConfig(config, optionType) {
    dispatch(saveConfig(config, optionType));
  },
  _downloadDebugMsg({ project, fileName, verificationId, downloadType }) {
    dispatch(downloadDebugMsg({ project, fileName, verificationId, downloadType }))
  },
  _PDNDebugVerify(status, verificationId) {
    dispatch(PDNDebugVerify(status, verificationId))
  },
  _changeUploadMes(mes) {
    dispatch(changeUploadMes(mes));
  },
  _cleanSingleProgress(verificationId) {
    dispatch(cleanSingleProgress(verificationId));
  },
  _getVRMModelUpdate(currentProjectId, PDNID) {
    dispatch(getVRMModelUpdate({ currentProjectId, PDNID }))
  },
  _updatePDNReportConfig(reportConfig) {
    dispatch(updatePDNReportConfig(reportConfig))
  },
  _updatePDNReoprtMessage(message) {
    dispatch(updatePDNReoprtMessage(message))
  },
  updateSimConfig(type, checked) {
    dispatch(updateSimConfig(type, checked))
  },
  _forcePreviewPanelShow(show) {
    dispatch(forcePreviewShow(show))
  },
  changeLayout(layout) {
    dispatch(changeLayout(layout))
  },
  _savePreviewStatus(previewStatus) {
    dispatch(savePreviewStatus(previewStatus))
  },
  updateSimLoading(loading) {
    dispatch(updateSimLoading(loading))
  }
})

export default connect(mapState, mapDispatch)(TopBar)