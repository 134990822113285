import NP from 'number-precision';

function mergeDecapData(impedanceSetup, decapEffectiveRL) {
  let data = []
  const { layouts, powerDomains = [], designId, designName, type } = impedanceSetup;
  let _layouts = layouts;
  if (!_layouts) {
    _layouts = [{ powerDomains, designId, designName, type }]
  }
  decapEffectiveRL.forEach(info => {
    const capCompsData = info.decapResultList.map(item => {
      const { components, res, ind, ind10MHz, ind100MHz } = item;
      const decapRL = new DecapRL({ name: components[0], resistance: res, inductance: ind, inductance_10: ind10MHz ? ind10MHz : NaN, inductance_100: ind100MHz ? ind100MHz : NaN })
      decapRL.changeResistanceTomOhm()
      decapRL.changeInductanceTopH()
      return decapRL
    })
    const findLayout = info.designId ? _layouts.find(layout => layout.designId === info.designId) : _layouts[0]
    if (findLayout) {
      const designId = findLayout.designId;
      const designName = findLayout.designName;
      const type = findLayout.type;
      const _data = findLayout.powerDomains.map(item => {
        const { content: { MAIN_POWER_NETS, Components } } = item
        Object.assign(item, { showTable: true })
        const capComps = Components.map(item => {
          const { name, part } = item
          return item.COMP_TYPE === 'Cap' ? { name, part } : null
        }).filter(item => !!item).map(item => {
          const param = capCompsData.find(param => param.name === item.name)
          if (param) {
            return Object.assign(item, param)
          }
          return null
        }).filter(item => !!item);
        return {
          designId,
          designName,
          type,
          powerNet: MAIN_POWER_NETS[0],
          showTable: true,
          capComps
        }
      })
      data.push(..._data)
    }
  })
  return data
}

function DecapRL({ name = '', resistance = '', inductance = '', inductance_10, inductance_100 }) {
  this.name = name
  this.resistance = Number(resistance)
  this.inductance = Number(inductance)
  this.inductance_10 = Number(inductance_10)
  this.inductance_100 = Number(inductance_100)
}

DecapRL.prototype.changeResistanceTomOhm = function () {
  if (typeof this.resistance === "number") {
    this.resistance = NP.round(NP.times(this.resistance, 1000), 3)
  }
}
DecapRL.prototype.changeInductanceTopH = function () {
  if (typeof this.inductance === "number") {
    this.inductance = NP.round(NP.times(this.inductance, 1e12), 2)
  }
  if (typeof this.inductance_10 === "number") {
    this.inductance_10 = NP.round(NP.times(this.inductance_10, 1e12), 2)
  }
  if (typeof this.inductance_100 === "number") {
    this.inductance_100 = NP.round(NP.times(this.inductance_100, 1e12), 2)
  }
}

export {
  mergeDecapData
}