import {
  UPDATE_PDN_CONTENT,
  UPDATE_PDN_INTERFACE,
  EXPAND_MENU,
  DefaultVRMModelInSPIM,
  UPDATE_PDN_IFDOEXTRACTION,
  PREFIX_PROMPT,
  FIND_VRM_LOADING,
  UPDATE_CHECK_NETS,
  CLOSE_CHECK_NETS_MSG,
  SAVE_SELECTED_MODEL,
  CREATE_COPY_DECAP,
  CREATE_COPY_DECAP_END,
  SAVE_VRM_MODEL,
  FIND_VRM_LIST_MODEL,
  FIND_POWER_DOMAIN_LOADING,
  UPDATE_CAPCONNECTREFNET,
  SAVE_PACKAGE_COMPONENT,
  SAVE_PREVIEW_STATUS
} from './actionTypes';
import { CLEAR_CURRENT_PROJECT } from '../project/actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  expandedKeys: ['library', 'projects'],
  showPrefix: false,
  vrmFindLoading: false,
  // first indicates whether to open pdn for the first time, model is the previous selected model
  selectedModel: { first: false, model: null },
  copyComponents: false,
  channelPDNVRMIds: [],
  powerDomainFindLoading: false,
  capsConnectRefNet: []
}

export const PDNReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PDN_CONTENT:
      return {
        ...state,
        pdnInfo: {
          ...action.info
        }
      }
    case UPDATE_PDN_INTERFACE:
      return {
        ...state,
        pdnInfo: {
          ...state.pdnInfo,
          pdnContent: {
            ...state.pdnInfo.pdnContent,
            ...action.data
          }
        }
      }
    case EXPAND_MENU:
      return {
        ...state,
        expandedKeys: action.expandKeys
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...defaultState,
        expandedKeys: state.expandedKeys
      }
    case DefaultVRMModelInSPIM:
      return {
        ...state,
        defaultVRM: action.defaultVRM
      };
    case UPDATE_PDN_IFDOEXTRACTION:
      return {
        ...state,
        pdnInfo: {
          ...state.pdnInfo,
          ifDoExtraction: action.data
        }
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case PREFIX_PROMPT:
      return {
        ...state,
        showPrefix: action.showPrefix
      }
    case UPDATE_CHECK_NETS:
      return {
        ...state,
        netsError: action.error
      }
    case CLOSE_CHECK_NETS_MSG:
      return {
        ...state,
        netsError: {}
      }
    case FIND_VRM_LOADING:
      return {
        ...state,
        vrmFindLoading: action.loading
      }
    case SAVE_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.model
      }
    case CREATE_COPY_DECAP:
      return {
        ...state,
        copyComponents: true
      }
    case CREATE_COPY_DECAP_END:
      return {
        ...state,
        copyComponents: false
      }
    case SAVE_VRM_MODEL:
      return {
        ...state,
        VRMModel: action.data
      }
    case FIND_VRM_LIST_MODEL:
      return {
        ...state,
        channelPDNVRMIds: action.vrmIds
      }
    case FIND_POWER_DOMAIN_LOADING:
      return {
        ...state,
        powerDomainFindLoading: action.loading
      }
    case UPDATE_CAPCONNECTREFNET:
      return {
        ...state,
        capsConnectRefNet: action.capsConnectRefNet
      }
    case SAVE_PREVIEW_STATUS:
      return {
        ...state,
        previewStatus: action.status
      }
    default: return state;
  }
};