import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import { Select, Checkbox, Spin } from "antd";
import Panel from '@/components/Panel';
import PkgModel from './PkgModel';
import { ANDES_V2, ROCKY, SIERRA } from '../../constants/pageType';
import { SOCKET } from '../../constants/libraryConstants';
import { CARD } from '../../constants/treeConstants';
import { MEMORY } from '@/constants/componentType';
import './index.css';

const { Option, OptGroup } = Select;
const IBIS = "IBIS", EBD = 'EBD', TOUCHSTONE = "Touchstone", TOUCHSTONE_SPICE = "Touchstone / SPICE", NONE = "None", SPICE = "SPICE", Connector = "Connector", PCB = "PCB", OPTGROUP_MODEL = "Model", LAYOUT = "Layout";
const andesPackageTypeList = [NONE, TOUCHSTONE], rockySocketTypeList = [NONE, TOUCHSTONE_SPICE], packageOptGroup = [IBIS, EBD, TOUCHSTONE_SPICE], rockyPackageTypeList = [NONE, OPTGROUP_MODEL], sevPackageOptGroup = [IBIS, TOUCHSTONE_SPICE];
const packageTypeList_ebd_controller = [NONE, OPTGROUP_MODEL, LAYOUT];
const NPortModelList = [TOUCHSTONE_SPICE]
/* 
  pkg: {},
  None: pkg={type:'None'},
  IBIS: {type:'IBIS',component:'',model:''} //model:Generic/Pin/Matrix
  SPICE: {type:'SPICE', files: [ { fileName:'',libraryId:'',subckt:''} ], pairs:[ {pin: '', node: '',libraryId:'',subckt:''} ]}
  Touchstone: {type:'Touchstone',files: [ { fileName:'',libraryId:'',subckt:''} ], pairs:[ {pin: '', node: '',libraryId:'',subckt:''} ]}
 */

class PackageModel extends Component {
  constructor(props) {
    super(props);
    const { pkg, comps, connectorModel, socketModel } = props.record;
    let _model = { type: NONE };

    if (props.modelType === Connector) {
      _model = connectorModel;
    } else if (props.modelType === SOCKET) {
      _model = socketModel;
    } else {
      _model = pkg;
    }
    let _pkg = _model ? JSON.parse(JSON.stringify(_model)) : { type: NONE };
    let type = _pkg && _pkg.type ? _pkg.type : NONE;
    //prev version
    if (type === SPICE || type === TOUCHSTONE) {
      type = TOUCHSTONE_SPICE;
      let file = null;
      if (_pkg.fileName) {
        file = {
          type: _pkg.type,
          fileName: _pkg.fileName,
          libraryId: _pkg.libraryId,
          subckt: _pkg.subckt,
          height: 0
        }
      }
      _pkg = {
        type,
        files: file ? [file] : [],
        pairs: [],
        rank: null
      }
    }

    this.state = {
      loading: false,
      pkg: _pkg,
      component: comps && comps.length > 0 && comps[0].name ? comps[0].name : "",// component name
      pkgType: type,//package type "None" / "IBIS" / "Touchstone / SPICE"
      packages: [], // ibis model packages
      IBISPackageModelList: [],
      maxHeight: 600,
      maxWidth: 690,
      applyAll: true,
      libraryInfoList: [],
    };
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  savePackageModelInfo = () => {
    const { applyAll } = this.state;
    const { record, savePackageModel, isPageDisplay } = this.props;
    if (!isPageDisplay) { return }
    const { _pkg, modelList } = this.getPkgModelList()
    savePackageModel(_pkg, record, modelList, applyAll);
  }

  getPkgModelList() {
    const { pkg } = this.state;
    const { product, interfaceType, record, modelType } = this.props;
    let _pkg = { ...pkg };
    let modelList = [];
    if ([TOUCHSTONE, SPICE, EBD, TOUCHSTONE_SPICE].includes(pkg.type) && this.modelChild) {
      const { files, pairs, rank, isSave, termination, topology } = this.modelChild.getNetListModel();
      if (!isSave) { return { isSave: false } }
      //modelInfo: {files:[], pairs:[] }
      _pkg = { ..._pkg, files, pairs };
      if (product === ROCKY && interfaceType !== CARD && record.type === MEMORY) {
        _pkg.rank = rank
      }
      if (product === ANDES_V2 && modelType === "DIE") {
        _pkg.termination = termination;
        _pkg.topology = topology;
      }
      //delete prev version field
      _pkg["fileName"] && delete _pkg["fileName"];
      _pkg["libraryId"] && delete _pkg["libraryId"];
      _pkg["subckt"] && delete _pkg["subckt"];

      // //get other type model (pkg,connector,cable)
      modelList = this.modelChild.getModelsObj();
    }
    return { _pkg, modelList, isSave: true }
  }

  closeModal = () => {
    const { applyAll } = this.state;
    const { _pkg, modelList, isSave } = this.getPkgModelList();
    if (!isSave) { return }
    // //_pkg: IBIS: {type:"IBIS", component, model}
    // //_pkg: Touchstone / SPICE { type:"Touchstone / SPICE", files, pairs }
    const { record, savePackageModel, switchRankStatus, save, closeRank, componentName, packageMsg } = this.props;
    savePackageModel(_pkg, record, modelList, applyAll);
    if (closeRank && !_pkg.rank && record.pkg.rank) {
      closeRank(record, componentName)
    }
    switchRankStatus && switchRankStatus(true);
    if (packageMsg && packageMsg.length) {
      this.props.updatePackageMsg([])
    }
    save();
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, packageLoading, packageMsg, pinList, nameStatus } = this.props;
    if (verificationId !== prevProps.verificationId) {
      this.props.save && this.props.save();
    }

    if (pinList.length && pinList.length !== prevProps.pinList.length) {
      if (this.modelChild) {
        this.modelChild.updatePKGModelInfo()
      }
    }

    if (nameStatus !== prevProps.nameStatus) {
      this.updateContent()
    }

    if (packageLoading === 'close') {
      this.props.save();
      this.props.updatePackageLoading(false)
    }

    if (packageMsg && packageMsg.length !== prevProps.packageMsg.length) {
      this.setContentHeight(0);
    }
  }

  updateContent = () => {
    const { modelType, record } = this.props;
    const { pkg, comps, connectorModel, socketModel } = record;
    let _model = { type: NONE };
    if (modelType === Connector) {
      _model = connectorModel;
    } else if (modelType === SOCKET) {
      _model = socketModel;
    } else {
      _model = pkg;
    }
    let _pkg = _model ? JSON.parse(JSON.stringify(_model)) : { type: NONE };
    let type = _pkg && _pkg.type ? _pkg.type : NONE;

    if (type === SPICE || type === TOUCHSTONE) {
      type = TOUCHSTONE_SPICE;
      let file = null;
      if (_pkg.fileName) {
        file = {
          type: _pkg.type,
          fileName: _pkg.fileName,
          libraryId: _pkg.libraryId,
          subckt: _pkg.subckt,
          height: 0
        }
      }
      _pkg = {
        type,
        files: file ? [file] : [],
        pairs: [],
        rank: null
      }
    } else if (PkgModel === IBIS) {
      this.getIBISModels(pkg.libraryId, pkg);
      return
    }

    this.setState({
      loading: false,
      pkg: _pkg,
      component: comps && comps.length > 0 && comps[0].name ? comps[0].name : "",
      pkgType: type,
      packages: [],
      IBISPackageModelList: [],
      applyAll: true
    }, () => {
      if (this.modelChild) {
        this.modelChild.updatePKGModelInfo()
      }
    });
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this, this.props.compIndex);
    }
    window.addEventListener('resize', this.resize);
    const { pkg, model } = this.props.record;
    const { pkgType } = this.state;
    if (pkgType === IBIS) {
      this.getIBISModels(pkg.libraryId, pkg);
    }
    if (model && model.libraryId) {
      this.getLibraryInfo(model.libraryId);
    }
    this.resize();
    this.setContentHeight(0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      maxHeight: this.getMaxHeight(),
      maxWidth: this.getMaxWidth()
    })
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  selectPackageType = (key) => {
    const { pkgType, applyAll } = this.state;
    const { isDisplayModelTitle, componentName, modelType, record, notAddMultiModel } = this.props;
    if (key === TOUCHSTONE) {
      key = TOUCHSTONE_SPICE;
    }
    if (pkgType === key) {
      return;
    }
    let pkg = this.getPKGInfo(key);
    if (key === TOUCHSTONE_SPICE && isDisplayModelTitle && !notAddMultiModel && [Connector, PCB].includes(modelType)) {
      //In the model panel, if you convert type to touchstone, you will be redirected to the setup page
      // BGA PCB Model
      this.props.savePackageModel(pkg, record, [], applyAll);
      this.props.save && this.props.save()
      this.props.scrollToAnchor && this.props.scrollToAnchor(componentName, true)
      return
    }
    this.setState({
      pkgType: key,
      pkg
    }, () => {
      if (key === IBIS) {
        const model = this.getComponentModel();
        this.setState({ loading: true });
        const libraryId = model && model.libraryId ? model.libraryId : null;
        this.getIBISModels(libraryId);
      }

      if (this.modelChild) {
        this.modelChild.changePKGType(key);
      }

      if (key === NONE && isDisplayModelTitle && !notAddMultiModel && [Connector, PCB].includes(modelType)) {
        this.savePackageModelInfo()
        this.props.changeUpdateMultiStatus && this.props.changeUpdateMultiStatus()
        // Save the data and open the PCB panel
        // openPackagePanel && openPackagePanel(componentName)
        return
      }
      this.setContentHeight(0);
      this.resize();
    })
  }

  getComponentModel = () => {
    const { record, product } = this.props;
    const { model } = record;
    let _model = null;
    if (product === SIERRA && model && Array.isArray(model.files) && model.files[0]) {
      _model = model.files[0];
    } else if (product === ROCKY && model) {
      _model = { ...model, type: model.libType };
    }
    return _model;
  }

  getLibraryInfo = async (libraryId) => {
    const { getLibraryByIdPromise } = this.props;
    let _libraryInfoList = [];
    if (libraryId) {
      try {
        const res = await getLibraryByIdPromise({ libraryId })
        if (res && res.length > 0) {
          _libraryInfoList = res;
        }
      } catch (e) {
        console.error(e)
      }
    }
    this.setState({
      libraryInfoList: _libraryInfoList
    })
  }

  getPKGInfo = (type) => {
    const { PackageModelInfo } = this.props;
    const model = this.getComponentModel();
    let pkg = {};
    switch (type) {
      case NONE:
        pkg = {
          type: NONE
        };
        break;
      case IBIS:
        pkg = {
          type: IBIS,
          component: '',
          libraryId: model && model.libraryId ? model.libraryId : null,
          model: '',
          modelInfo: ''
        };
        break;
      case SPICE:
      case TOUCHSTONE:
      case TOUCHSTONE_SPICE:
      case EBD:
        pkg = new PackageModelInfo(type);
        break;
      default: break;
    }
    return pkg;
  }

  getIBISModels = (id, pkgObj) => {
    this.props.getIbisPackageList(id).then(res => {
      const { pkg } = this.state;
      let IBISPackageModelList = [];
      let find = null;
      if (pkgObj) {
        find = res ? res.find(item => item.component === pkgObj.component) : null;
      } else {
        find = res && res[0] ? res[0] : null;
      }
      if (find) {
        if (find.generic && find.generic.length > 0) {
          const _modelInfo = this.getIBISPackageModelInfo(res, { component: find.component }, 'Generic');
          IBISPackageModelList.push({ name: 'Generic', modelInfo: _modelInfo });
        }
        if (find.pinSection && find.pinSection.length > 0) {
          IBISPackageModelList.push({ name: 'Pin', modelInfo: null });
        }
        if (find.packageModel) {
          const _modelInfo = this.getIBISPackageModelInfo(res, { component: find.component }, 'Matrix');
          IBISPackageModelList.push({ name: 'Matrix', modelInfo: _modelInfo });
        }
      }

      let model = pkgObj ? pkgObj.model : (IBISPackageModelList[0] ? IBISPackageModelList[0].name : '');
      let component = pkgObj ? pkgObj.component : (find ? find.component : '');

      let newPkg = {
        ...pkg,
        component: component,
        model: model
      };
      let packageModel = '';
      if (model) {
        packageModel = this.getIBISPackageModelInfo(res, newPkg, model);
      }
      newPkg.modelInfo = packageModel;
      this.setState({
        packages: res ? res : [],
        pkg: { ...newPkg },
        IBISPackageModelList,
        loading: false
      })
    })
  }

  selectIBISComponent = (key) => {
    const { packages, pkg } = this.state;
    let find = packages.find(item => item.component === key);
    let IBISPackageModelList = [];
    if (find) {
      if (find.generic && find.generic.length > 0) {
        const _modelInfo = this.getIBISPackageModelInfo(packages, { component: key }, 'Generic');
        IBISPackageModelList.push({ name: 'Generic', modelInfo: _modelInfo });
      }
      if (find.pinSection && find.pinSection.length > 0) {
        IBISPackageModelList.push({ name: 'Pin', modelInfo: null });
      }
      if (find.packageModel) {
        const _modelInfo = this.getIBISPackageModelInfo(packages, { component: key }, 'Matrix');
        IBISPackageModelList.push({ name: 'Matrix', modelInfo: _modelInfo });
      }
    }

    let newPkg = { ...pkg, component: key, model: IBISPackageModelList[0] ? IBISPackageModelList[0].name : '', };
    let packageModel = '';
    if (IBISPackageModelList[0]) {
      packageModel = this.getIBISPackageModelInfo(packages, newPkg, IBISPackageModelList[0].name);
    }
    newPkg.modelInfo = packageModel;
    this.setState({
      pkg: newPkg,
      IBISPackageModelList
    })
  }

  getIBISPackageModelInfo = (packages, pkg, model) => {
    let packageModel = null;

    if (packages && pkg && model) {
      const findComp = packages.find(item => pkg && item.component === pkg.component);

      if (findComp) {
        switch (model) {
          case 'Generic':
            packageModel = {};
            findComp.generic.forEach(item => {

              if (item.name === 'R_pkg' || item.name.match(/R/ig)) {
                packageModel.R = item.typ;
              }
              if (item.name === 'L_pkg' || item.name.match(/L/ig)) {
                packageModel.L = item.typ;
              }
              if (item.name === 'C_pkg' || item.name.match(/C/ig)) {
                packageModel.C = item.typ;
              }
            });
            break;
          case 'Pin':
            packageModel = '';
            break;
          case 'Matrix':
            packageModel = findComp.packageModel;
            break;
          default:
            break;
        }
      }
    }
    return packageModel;
  }

  getMaxHeight = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { height } = offset;
      if (!height) return 800;
      const _height = (height - 100) * 0.88;
      return _height > 1400 ? 1400 : _height;
    }
  }

  getMaxWidth = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { width } = offset;
      const { modelType, displayType, componentName, isPageDisplay, compIndex } = this.props;
      const idIndex = isPageDisplay && compIndex > -1 ? compIndex : '0';
      let content = document.getElementById(`spice-model_${componentName}_${modelType}_${displayType}_component_${idIndex}`);
      let contentWidth = content ? content.offsetWidth : 690;
      const otherWidth = modelType === "DIE" ? 50 : 0;
      const _width = width ? width * 0.8 : 0;
      if (content) {
        return width && contentWidth + 44 + otherWidth > _width ? _width : contentWidth + 44 + otherWidth
      }
      return _width > 690 || _width === 0 ? 690 : _width;
    }
  }

  selectIBISPackageModel = (key) => {
    const { pkg, packages } = this.state;
    const packageModel = this.getIBISPackageModelInfo(packages, pkg, key);
    this.setState({
      pkg: { ...pkg, model: key, modelInfo: packageModel }
    })
  }

  getPackageTypeValue = (value, product, type) => {
    if (product === ANDES_V2 && value === TOUCHSTONE_SPICE) {
      return TOUCHSTONE;
    }
    if (product === ROCKY && type === "Model File") {
      let _value = value && value.name ? value.name : '';
      return _value
    }
    return value;
  }

  getSelectComponent(type, placeholder, value, selectHandler, list, modelType) {
    const modelInfo = this.getComponentModel();
    const modelExist = this.modelChild ? this.modelChild.getModelFilesExist() : false;
    const { product, isPageDisplay, isDisplayModelTitle, notDisplayEBD } = this.props;
    const width = product === ANDES_V2 && isDisplayModelTitle ? 142 : (modelExist || isPageDisplay ? 120 : 100);

    return <div className={`package-model-select ${modelType === "DIE" ? "package-die-model-select" : ""}`}>
      <span style={{ width: modelType === 'DIE' ? width + 30 : width, minWidth: width }}>{type}</span>
      <Select
        placeholder={placeholder}
        value={this.getPackageTypeValue(value, product, type)}
        onSelect={selectHandler}
        className='package-model-selection'
        popupClassName='package-model-select-dropdown'
        popupMatchSelectWidth={false}
        getPopupContainer={() => document.getElementById('root')}
        size='small'
      >
        {
          list.map(item => {
            if (type === 'Component') {
              return <Option
                key={item.component}
                value={item.component}
                title={item.component}
              >{item.component}</Option>
            }
            if (type === 'Model') {
              return <Option
                key={item.name}
                value={item.name}
                disabled={item.name === 'Matrix' ? true : false}
                title={item.name === 'Matrix' ? "Not available." : (item.modelInfo ?
                  (item.name === 'Generic'
                    ? `${item.name}(R:${item.modelInfo.R}, L:${item.modelInfo.L}, C:${item.modelInfo.C})`
                    : `${item.name}(${item.modelInfo})`)
                  : item.name)}
              >
                {item.modelInfo ?
                  (item.name === 'Generic'
                    ? `${item.name}(R:${item.modelInfo.R}, L:${item.modelInfo.L}, C:${item.modelInfo.C})`
                    : `${item.name}(${item.modelInfo})`)
                  : item.name}
              </Option>
            }
            if (type === 'Model Type') {
              if (item === OPTGROUP_MODEL) {
                const disabled = (!modelInfo || !modelInfo.libraryId || modelInfo.type !== IBIS);
                const list = notDisplayEBD ? sevPackageOptGroup : packageOptGroup;
                return <OptGroup label={item} key={item}>
                  {list.map(optItem => {
                    return <Option
                      disabled={(optItem === IBIS) ? disabled : false}
                      key={optItem}
                      value={optItem}
                      title={((optItem === IBIS && disabled) ? 'Need to set up Model first.' : optItem)}
                    >{optItem}</Option>
                  })}
                </OptGroup>
              }
              return <Option
                disabled={(item === IBIS) ? (!modelInfo || !modelInfo.libraryId || modelInfo.type !== IBIS) : false}
                key={item}
                value={item}
                title={((item === IBIS && (!modelInfo || !modelInfo.libraryId)) ? 'Need to set up Model first.' : item)}
              >{item}</Option>
            }
            if (type === "Model File") {
              return <Option
                key={item.name}
                value={item.name}
              >{item.name}</Option>
            }
            return null
          })
        }
      </Select>
    </div>
  }

  setContentHeight = (time) => {
    let _time = 300;
    if (time !== undefined) {
      _time = time;
    }
    const { modelType, displayType, componentName, isPageDisplay, compIndex } = this.props;
    const { pkgType } = this.state;
    const prevHeight = this.state.height;
    const defaultHeight = (pkgType === SPICE || pkgType === TOUCHSTONE || pkgType === TOUCHSTONE_SPICE) ? 400 : (pkgType === IBIS ? 180 : 108);
    const idIndex = isPageDisplay && compIndex > -1 ? compIndex : '0';
    setTimeout(() => {
      //set panel height
      let content = document.getElementById(`spice-model_${componentName}_${modelType}_${displayType}_component_${idIndex}`);
      const otherHeight = modelType === "DIE" ? 10 : 0;
      let height = content ? content.offsetHeight + 78 + otherHeight : prevHeight && defaultHeight;
      this.setState({
        height
      })
    }, _time);
  }

  onApplyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  getPackageTypeList = (product, modelType, record, interfaceType) => {
    let _packageTypeList = andesPackageTypeList;
    switch (product) {
      case ROCKY:
        if (record.type === "Controller") {
          _packageTypeList = packageTypeList_ebd_controller;
        } else if (modelType === "Package" && interfaceType !== 'card') {
          _packageTypeList = rockyPackageTypeList;
        } else {
          _packageTypeList = rockySocketTypeList
        }
        break;
      case ANDES_V2:
        _packageTypeList = andesPackageTypeList;
        break;
      default:
        break;
    }
    if (modelType === "N-Port") {
      _packageTypeList = NPortModelList;
    }
    return _packageTypeList
  }

  massageDisplay = () => {
    const { pkgType } = this.state;
    if (pkgType !== LAYOUT) { return null }
    const { packageMsg } = this.props;
    return <div className='find-package-layout-errorMsg-box'>
      <div>[Warning] The selected package interface failed to match, and the components and pins that failed to match are as follows:</div>
      {packageMsg.map((item, index) => {
        const { channelName, errorCompInfo = {} } = item;
        return <div key={index}>
          <div className='errorMsg-box-div'>{channelName}</div>
          <span className='errorMsg-box-span'>[{errorCompInfo.comp}]: </span>
          <span>{errorCompInfo.pins.join(', ')}</span>
        </div>
      })}
    </div>
  }

  selectLayoutPackageModel = async (key) => {
    // select Package layout
    const { componentName, packageLayoutList } = this.props;
    const findData = packageLayoutList.find(item => item.name === key)
    const packageInfo = { id: findData.id, name: findData.name }
    this.props.selectPackageLayoutModel(packageInfo, componentName)
  }

  renderContent() {
    const { pkgType, pkg, height, maxHeight, maxWidth, applyAll, loading, IBISPackageModelList, packages, libraryInfoList } = this.state;
    const { modelType, displayType, componentName, pinList, product, isPageDisplay, compIndex, nameTitleContent, record, packageLayoutList, packageMsg, packageLoading, interfaceType, ibisList, isDisplayModelTitle } = this.props;
    const packageTypeList = this.getPackageTypeList(product, modelType, record, interfaceType)
    const idIndex = isPageDisplay && compIndex > -1 ? compIndex : '0';
    return <div className='spice-model-content' id={`pkg-scrollId-${idIndex}`}>
      <Spin spinning={packageLoading ? true : false} tip={packageLoading ? `Loading Package...` : ''}>
        {nameTitleContent && nameTitleContent(componentName)}
        <div id={`spice-model_${componentName}_${modelType}_${displayType}_component_${idIndex}`}>
          {
            product === ANDES_V2 && modelType === "DIE" ? <div className="model-row-item">
              <label className='model-title'>DIE</label>
            </div> : null
          }
          {!isDisplayModelTitle ? this.getSelectComponent("Model Type", 'Model Type', pkgType, this.selectPackageType, packageTypeList, modelType) : null}
          {pkgType === IBIS ? <Spin spinning={loading}>
            {interfaceType === CARD ? this.getSelectComponent("Model File", 'Model File', pkg, this.selectIBISFile, ibisList, modelType) : null}
            {this.getSelectComponent("Component", 'Component Name', pkg.component, this.selectIBISComponent, packages, modelType)}
            {this.getSelectComponent("Model", 'IBIS Package Model Type', pkg.model, this.selectIBISPackageModel, IBISPackageModelList, modelType)}
          </Spin>
            : pkgType === LAYOUT ?
              this.getSelectComponent("Model File", 'Package Model', pkg.packageLayoutInfo, this.selectLayoutPackageModel, packageLayoutList, modelType) :
              pkgType !== NONE && <PkgModel
                {...this.props}
                pkgType={pkgType}
                onRef={this.onRef}
                scrollId={`pkg-scrollId-${idIndex}`}
                pkg={pkg}
                pins={pinList}
                setContentHeight={this.setContentHeight}
                resize={this.resize}
                panelHeight={height}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                product={product}
                savePackageModelInfo={this.savePackageModelInfo}
                isPageDisplay={isPageDisplay}
                closeModalPanel={this.closeModal}
                libraryInfoList={libraryInfoList}
              />}
          {modelType === SOCKET ? <div className='apply-all-main'>
            <div className='component-apply-all-title'>Apply model to all interfaces components with same part</div>
            <Checkbox
              checked={applyAll}
              onChange={(e) => this.onApplyChange(e)}
              className='component-apply-all-checkbox'
            />
          </div> : null}
          {packageMsg && packageMsg.length ? this.massageDisplay() : null}
        </div>
      </Spin>
    </div>
  }

  renderDialog() {
    const { height, maxHeight, maxWidth } = this.state;
    const { modelType, componentName, product } = this.props;
    const _height = height > maxHeight ? maxHeight : height;

    const title = modelType === SOCKET ? "Socket" : modelType;
    const className = product === ROCKY ? 'spice-model-panel pkg-panel pkg-new-panel rocky-pkg-panel' : 'spice-model-panel pkg-panel pkg-new-panel';
    const content = (
      <Panel
        className={className}
        title={<div className='package-model-title'>{modelType !== "DIE" ? `${componentName} ${title} Model` : `${title} Model`}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={maxWidth}
        minWidth={200}
        minHeight={200}
        height={_height}
        maxHeight={maxHeight + 100}
        position='panel-center'
        draggable
        createWH={true}
        overflow='hidden'
      >
        {this.renderContent()}
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  getText = (pkg) => {
    let text;
    if (pkg && pkg.type === IBIS) {
      text = this.props.text;
    } else if (pkg && (pkg.type === SPICE || pkg.type === TOUCHSTONE || pkg.type === TOUCHSTONE_SPICE)) {
      const files = pkg.files && pkg.files.length ? pkg.files : [];
      const fileNames = [...new Set(files.map(item => item.fileName))];
      text = fileNames && fileNames.length > 0 ? fileNames.join(", ") : "";
    } else {
      text = '';
    }
    return text;
  }

  render() {
    const { inputRef } = this;
    const { pkg } = this.props.record;
    const { isPageDisplay } = this.props;
    if (!isPageDisplay) {
      let text = this.props.text;
      if (!text) {
        text = this.getText(pkg);
      }

      return (
        <Fragment>
          <div className='editable-cell-value-wrap' ref={inputRef}>
            {text}
          </div>
          {this.renderDialog()}
        </Fragment>
      )
    } else {
      return <Fragment>
        <div className='pkg-panel pkg-new-panel' >
          {this.renderContent()}
        </div>
      </Fragment>
    }
  }
}

export default PackageModel;