import CeLarc from "../../geometry/CeLarc";
import CeLine from "../../geometry/CeLine";
import Circle from "./geometry/Circle";
import Rectangle from "./geometry/Rectangle";
import Polygon from './geometry/Polygon';
import CePolygonWithHole from "../../geometry/CePolygonWithHole";
import CePolygon from "../../geometry/CePolygon";

class NetsSize {
  constructor({ layoutDB }) {
    this.layoutDB = layoutDB;
    this.tempShape = new Map();  // { key - id, value - shapeSymbol }
  }

  getNetsSize(nets) {
    let sizeArray = [Infinity, -Infinity, Infinity, -Infinity];

    for (let net of nets) {
      const sizeArr = this._getNetSize(net);
      sizeArray = this._updateSizeArray(sizeArr, sizeArray)
    }
    const dX = sizeArray[1] - sizeArray[0], dY = sizeArray[3] - sizeArray[2];
    return Math.sqrt(dX * dX + dY * dY);
  }

  _getNetSize(netName) {
    const { mNetManager } = this.layoutDB;
    const net = mNetManager.GetNetFromName(netName);
    // sizeArray = [minX, maxX, minY, maxY];
    let sizeArray = [Infinity, -Infinity, Infinity, -Infinity];

    for (let { mRefGeom: geom } of net.mGeomList) {
      // case1: mGeometry: null, mLocation ("Circle", "Rectangle"),  mRotAngle
      // TODO - If it is a rectangle, the rotation angle is not considered at present, geom.mLocation.mRotAngle
      if (!geom.mGeometry && geom.mLocation) {
        const { mPosition } = geom.mLocation;
        if (mPosition.mGeomType === 'Pnt') {
          const { mX, mY } = mPosition;
          const arr = this.getShapeSize(geom.mSymbolID, { mX, mY });
          sizeArray = this._updateSizeArray(arr, sizeArray);
        } else {
          console.error(`[Debug] Unrecognized type (mPosition.mGeomType)`, mPosition.mGeomType)
          continue;
        }
      } else if (!geom.mLocation && geom.mGeometry) {
        if (geom.mGeometry instanceof CeLine) {
          const { mStart, mEnd } = geom.mGeometry;
          const arr = this.getShapeSize(geom.mSymbolID, { mStart, mEnd }, 'Line');
          sizeArray = this._updateSizeArray(arr, sizeArray);
        } else if (geom.mGeometry instanceof CeLarc) {
          const { mStart, mEnd, mCenter, mCCW } = geom.mGeometry;
          const arr = this.getShapeSize(geom.mSymbolID, { mStart, mEnd, mCenter, mCCW }, 'Larc');
          sizeArray = this._updateSizeArray(arr, sizeArray);
        } else if (geom.mGeometry instanceof CePolygonWithHole || geom.mGeometry instanceof CePolygon) {
          const { mVertices } = geom.mGeometry;
          const arr = this.getShapeSize(geom.mSymbolID, { mVertices }, 'Polygon');
          sizeArray = this._updateSizeArray(arr, sizeArray);
        } else {
          console.error(`[Debug] Unrecognized type (geom.mGeometry)`, geom.mGeometry)
          continue;
        }
      } else {
        // TODO
        continue;
      }
    }

    return sizeArray;
  }

  getShapeSize(id, data, type) {
    let symbol = null;
    if (id !== -1) {
      symbol = this.getShapeSymbolById(id);
    }
    // minX, maxX, minY, maxY;
    let sizeArray = []
    if (id !== -1 && !symbol) return null;
    if (type === 'Line') {
      const r = symbol.mDiameter / 2;
      const { mStart, mEnd } = data;
      const minY = Math.min(mStart.mY, mEnd.mY), maxY = Math.max(mStart.mY, mEnd.mY),
        minX = Math.min(mStart.mX, mEnd.mX), maxX = Math.max(mStart.mX, mEnd.mX);
      sizeArray = [minX - r, maxX + r, minY - r, maxY + r];
      // In order to reduce the calculation, use the radius value for calculation, r >= a and b
      // The following is the exact calculation
      // if (mStart.mX !== mEnd.mX || mStart.mY !== mEnd.mY) {
      //    const arc = Math.abs(calcArc(mStart, mEnd));
      //    const a = Math.abs(getAbyArcAndR(arc, symbol.mDiameter));
      //    const b = Math.abs(getBbyArcAndR(arc, symbol.mDiameter));
      //    sizeArray = [minX - b, maxX + b, minY - a, maxY + a];
      // }
    } else if (type === 'Larc') {
      // const { mStart, mEnd, mCenter, mCCW } = data;
      // TODO - Larc
    } else if (type === 'Polygon') {
      const { mVertices } = data;
      const poly = new Polygon({ mVertices });
      sizeArray = poly.getSizeArray();
    } else if (symbol instanceof Circle) {
      const { mX, mY } = data;
      const r = symbol.mDiameter / 2;
      sizeArray = [mX - r, mX + r, mY - r, mY + r];
    } else if (symbol instanceof Rectangle) {
      const { mX, mY } = data;
      const h = symbol.mHeight / 2, w = symbol.mWidth / 2;
      sizeArray = [mX - w, mX + w, mY - h, mY + h];
    } else if (symbol instanceof Polygon) {
      const { mX, mY } = data;
      const poly = new Polygon({ ...symbol, mX, mY });
      sizeArray = poly.getSizeArray();
    }
    return sizeArray;
  }

  getShapeSymbolById(id) {
    if (!this.tempShape.has(id)) {
      const { mSymbolMgr } = this.layoutDB;
      const symbol = mSymbolMgr.GetShapeGeomObj(id);
      const geomType = symbol.mGeometry.mGeomType;
      if (geomType === 'Circle') {
        const { mCenter, mDiameter } = symbol.mGeometry;
        this.tempShape.set(id, new Circle({
          mDiameter: mDiameter,
          mX: mCenter.mX,
          mY: mCenter.mY
        }))
      } else if (geomType === 'Rectangle' || geomType === 'RectCutCorner' || geomType === 'Square') {
        const { mCenter, mHeight, mWidth } = symbol.mGeometry;
        this.tempShape.set(id, new Rectangle({
          mHeight,
          mWidth,
          mX: mCenter.mX,
          mY: mCenter.mY
        }))
      } else if (geomType === 'Polygon') {
        const { mCCW, mVertices } = symbol.mGeometry;
        this.tempShape.set(id, new Polygon({
          mCCW,
          mVertices
        }))
      } else {
        console.error(`[Debug] Unrecognized type (geomType)`, geomType)
      }
    }
    return this.tempShape.get(id) || [];
  }

  _updateSizeArray(arr, sizeArray) {
    if (arr.length === 4) {
      if (arr[0] < sizeArray[0]) {
        sizeArray[0] = arr[0];
      }

      if (arr[1] > sizeArray[1]) {
        sizeArray[1] = arr[1];
      }

      if (arr[2] < sizeArray[2]) {
        sizeArray[2] = arr[2];
      }

      if (arr[3] > sizeArray[3]) {
        sizeArray[3] = arr[3];
      }
    }
    return sizeArray;
  }
}

export default NetsSize;