// Initialize extraction options
function ExtractionOptions(config = {}) {
  let {
    SOLVER = "SIwave",
    // Common
    SAPD = 100, FMAX = 0, DC = 1, REFR,
    DISC = 0, RESA, PKGR, DCF = 1, IPC = 1, SIWE = 0,
    couplingMinFreq = '3.5MHz', VR_FSW, CVRM_MODEL = 0,
    numCores = '4', corePercent = '50', ramPercent = '90',
    // SIWAVE CONFIG
    VRTC = 1, PADM, PLNM = '1e-6', VOID = '1e-6',
    // POWERSI CONFIG
    Dogleg = 0.01, Thermal = 0.01, Hole = 0.01,
    ViaHole = 0.01, SHPM = 0.1, ratio = 0.3,
    bottomFloating = false, bottomDistance = '0.01', topFloating = false, topDistance = '0.01',
    acDcFit = true
  } = config

  this.SOLVER = SOLVER;

  // Samples Per Decade, default 100  [2,200]
  this.SAPD = SAPD;

  // Port Impedance, default 0, display "Use Package Model Port Impedance"   [0.001,1000]
  this.REFR = REFR ? REFR : SOLVER === 'SIwave' ? 0 : 10;

  // Re-sample at the Package Model Frequencies, default 1, display Switch "On" [0/1]
  this.RESA = RESA || RESA === 0 ? RESA : 1;

  // Export with Package Model Port Impedance, default 1, display Switch "On"   [0/1]
  this.PKGR = PKGR || PKGR === 0 ? PKGR : 1;

  // New fields in version v0.1.10. -> update defaulf value v0.1.14(08/13 Feedback)
  // Intra-plane Coupling, default 1, display Switch "On"  [0/1]
  this.IPC = IPC;

  // If use CVRM, VR_FSW will be open
  this.CVRM_MODEL = CVRM_MODEL;
  if (VR_FSW) {
    this.VR_FSW = VR_FSW;
  }

  // hpc core
  this.numCores = numCores;
  this.corePercent = corePercent;
  this.ramPercent = ramPercent;

  if (SOLVER === 'SIwave') {

    // Clipping, default 1, display Switch "Off"   [0/1]
    this.CLIP = config.CLIP === undefined ? 1 : config.CLIP;

    // Discrete Sampling at the Package Model Frequencies, default 0, display Switch "Off"  [0/1]
    this.DISC = DISC;

    // update default value v0.1.15
    // Maximum Frequency, default 0, display "Use Package Model Max Frequency"  [2e7,2e9] (20MHz-2GHz)       
    this.FMAX = FMAX;

    // v0.1.13 new field
    // Snap vertices separated by less, default 2.5   [0,10]
    // v0.1.17 update default value to 1
    this.VRTC = VRTC;

    // v0.1.12 new field
    // Explicitly mesh pads larger than, default 1.0   [0,5]
    // v0.1.17 update default value to 1e-6
    this.PADM = PADM ? PADM : '1e-6';

    // v0.1.13 new field
    // Ignore planes smaller than, default 6.25e-6   [0,1e-4]
    // v0.1.17 update default value to 1e-6
    this.PLNM = PLNM;

    // New fields in version v0.1.1
    // Do not mesh the void smaller than, default 0.001   [0,10]
    // update default value to 1e-6 v0.1.17
    this.VOID = VOID;

    // New fields in version v0.1.3 -> update default value v0.1.7
    // [Public Settings] EM Solver Enhanced Mode, default 1, display Switch "On" [0/1]
    this.DCF = DCF;

    this.DC = DC;


    // New fields in version v0.1.11.
    // [Public Settings] Use SIwave Solver Explicitly, default 0,  display Switch "Off" [0/1]
    this.SIWE = SIWE;


    // v0.1.20
    // Add the couplingMinFreq 
    this.couplingMinFreq = couplingMinFreq;

    this.acDcFit = acDcFit;
  } else if (SOLVER === 'PowerSI') {
    // Clipping, default 1, display Switch "Off"   [0/1]
    this.CLIP = config.CLIP === undefined ? 1 : config.CLIP;

    // Dogleg hole smaller than
    this.Dogleg = Dogleg;

    // Thermal hole smaller than
    this.Thermal = Thermal;

    // Other hole smaller than
    this.Hole = Hole;

    // Via hole smaller than
    this.ViaHole = ViaHole;

    this.PADM = PADM ? PADM : 0.1;
    this.SHPM = SHPM;

    this.ratio = ratio;

    this.bottomFloating = bottomFloating;
    this.bottomDistance = bottomDistance;
    this.topFloating = topFloating;
    this.topDistance = topDistance;
  }

}

function SIWAVEOptions(config = {}) {
  const { VRTC = 1, PADM = '1e-6', PLNM = '1e-6', VOID = '1e-6',
    DC = 1, DCF = 1, SIWE = 0, couplingMinFreq = '3.5MHz',
    DISC, FMAX, acDcFit = true
  } = config;
  this.VRTC = VRTC;
  this.PADM = PADM;
  this.PLNM = PLNM;
  this.VOID = VOID;
  this.DC = DC;
  this.DCF = DCF;
  this.SIWE = SIWE;
  this.couplingMinFreq = couplingMinFreq;
  this.FMAX = FMAX;
  this.DISC = DISC;
  this.acDcFit = acDcFit;
}

function PowerSIOptions(config = {}) {
  const { Dogleg = 0.01, Thermal = 0.01, Hole = 0.01,
    ViaHole = 0.01, PADM = 0.1, SHPM = 0.1, PKGR, REFR, CLIP = 0, ratio,
    bottomFloating = false, bottomDistance = '0.01', topFloating = false, topDistance = '0.01'
  } = config;
  this.Dogleg = Dogleg;
  this.Thermal = Thermal;
  this.Hole = Hole;
  this.ViaHole = ViaHole;
  this.PADM = PADM;
  this.SHPM = SHPM;
  this.CLIP = CLIP;
  this.IPC = 0;
  if (PKGR === 1 && (!REFR || REFR === 0)) {
    this.REFR = 10;
  }
  this.ratio = ratio;
  this.bottomFloating = bottomFloating;
  this.bottomDistance = bottomDistance;
  this.topFloating = topFloating;
  this.topDistance = topDistance;
}

const PowerSIParams = [
  { key: 'Dogleg', title: 'Dogleg hole smaller than', unit: 'mm' },
  { key: 'Thermal', title: 'Thermal hole smaller than', unit: 'mm' },
  { key: 'Hole', title: 'Other hole smaller than', unit: 'mm' },
  { key: 'ViaHole', title: 'Via hole smaller than', unit: 'mm' }
];

export { ExtractionOptions, SIWAVEOptions, PowerSIOptions, PowerSIParams };