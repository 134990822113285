import React, { Component, Fragment, createRef } from 'react';
import {
  CheckCircleOutlined,
  CloseOutlined,
  FileZipOutlined,
  FolderAddOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Spin, Button, Input, Select, Progress } from 'antd';
import JSZip from 'jszip';
import { strDelimited } from '@/services/helper/split';
import './index.css';
import { ROCKY } from '../../constants/pageType';

const { Option } = Select;
const folderList = [{
  value: '.zip',
  title: 'Zip'
}, {
  value: 'directory',
  title: 'Folder'
}],
  spFolderList = [{
    value: '.sp',
    title: '*.sp'
  },
  {
    value: '.zip',
    title: 'Zip'
  }, {
    value: 'directory',
    title: 'Folder'
  }],
  plocSpFolderList = [{
    value: '.sp,.ploc',
    title: '*.sp,*.ploc'
  }, {
    value: '.zip',
    title: 'Zip'
  }, {
    value: 'directory',
    title: 'Folder'
  }],
  sNpFolderList = [{
    value: '*',
    title: '*.sNp'
  }, {
    value: '.zip',
    title: 'Zip'
  }, {
    value: 'directory',
    title: 'Folder'
  }],
  sNpZipList = [{
    value: '*',
    title: '*.sNp'
  }, {
    value: '.zip',
    title: 'Zip'
  }],
  fileAndFolderList = [{
    value: '*',
    title: 'File'
  },
  {
    value: '.zip',
    title: 'Zip'
  }, {
    value: 'directory',
    title: 'Folder'
  }],
  ibisZipList = [{
    value: ".ibs",
    title: "*.ibs"
    // }, {
    //   value: ".directory",
    //   title: 'Folder'
  }, {

    value: ".zip",
    title: 'Zip'
  }],
  txtZipList = [{
    value: ".txt",
    title: "*.txt",
  }, {
    value: 'directory',
    title: 'Folder'
  }, {
    value: '.zip',
    title: 'Zip'
  }],
  cpmFolderList = [{
    value: '.zip',
    title: 'Zip'
  }, {
    value: 'directory',
    title: 'Folder'
  }];
const SP_FOLDER = "spFolder", PLOC_SP_FOLDER = 'plocSpFolder', ZIP = ".zip", SNP_FOLDER = 'sNpFolder', SNP_ZIP = 'sNpZip', FOLDER = "folder", FILE_FOLDER = 'file_folder', IBIS_ZIP = "ibis_zip", TXT_ZIP = "txt_zip", CPM_FOLDER = 'cpmFolder';
class UploadFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      compressing: false,
      fileList: [],
      showFileList: false,
      editStatus: false,
      currentName: '',
      formatError: '',
      errorFileList: [],
      acceptType: ZIP,
      compressProgress: -1,
      uploadProgress: -1
    }
    this.uploadRef = createRef();
  }

  componentDidMount = () => {
    const { special, fileList = [], errorFileList = [] } = this.props;
    let acceptType = ZIP;
    if (special === SP_FOLDER) {
      acceptType = '.sp'
    } else if (special === PLOC_SP_FOLDER) {
      acceptType = '.sp,.ploc'
    } else if (special === SNP_FOLDER || special === CPM_FOLDER) {
      acceptType = 'directory'
    } else if (special === FILE_FOLDER) {
      acceptType = '*'
    } else if (special === IBIS_ZIP) {
      acceptType = '.ibs'
    } else if (special === TXT_ZIP) {
      acceptType = '.txt'
    }
    this.setState({
      acceptType,
      fileList,
      errorFileList,
      showFileList: fileList.length || errorFileList.length ? true : false
    });
  }

  componentDidUpdate = (prevProps) => {
    const { accept, special, fileList = [], errorFileList = [] } = this.props;
    if (accept !== prevProps.accept) {
      let acceptType = ZIP;
      if (special === SP_FOLDER) {
        acceptType = '.sp'
      } else if (special === PLOC_SP_FOLDER) {
        acceptType = '.sp,.ploc'
      } else if (special === SNP_FOLDER || special === CPM_FOLDER) {
        acceptType = 'directory'
      } else if (special === FILE_FOLDER) {
        acceptType = '*'
      } else if (special === IBIS_ZIP) {
        acceptType = '.ibs'
      } else if (special === TXT_ZIP) {
        acceptType = '.txt'
      }
      this.setState({
        compressing: false,
        fileList,
        showFileList: fileList.length || errorFileList.length ? true : false,
        editStatus: false,
        currentName: '',
        formatError: '',
        errorFileList,
        acceptType,
      })
    }
  }

  changeFileType = (key) => {
    this.setState({
      compressing: false,
      fileList: [],
      showFileList: false,
      editStatus: false,
      currentName: '',
      formatError: '',
      errorFileList: [],
      acceptType: key
    })
  }

  unloadFile = (e) => {
    const el = this.uploadRef.current;
    if (!el) return;
    el.click();
  }

  onUploadFile = (e, special) => {
    this.setState({
      showFileList: true,
    })
    const { fileList, errorFileList, acceptType } = this.state;
    let _fileList = [...fileList];
    let _errorFileList = [...errorFileList];
    const files = e.target.files;
    const { accept, extraAccept, libraryOptions } = this.props;
    let defaultOptionObj = {}, addOptions = false, optionKeys = [];
    if (libraryOptions && libraryOptions.length) {
      addOptions = true;
      for (let item of libraryOptions) {
        defaultOptionObj[item.key] = item.default;
        optionKeys.push(item.key);
      }
    }
    let zip = new JSZip();
    if ((accept === ZIP || [SP_FOLDER, PLOC_SP_FOLDER, SNP_FOLDER, FOLDER, FILE_FOLDER, TXT_ZIP, CPM_FOLDER].includes(special)) && acceptType === 'directory') {
      this.setState({
        compressing: true,
      })
      const number = files[0].webkitRelativePath.indexOf('/');
      const fileName = files[0].webkitRelativePath.substring(0, number);
      for (const file of files) {
        zip.file(file.webkitRelativePath, file);
      }
      let fileObj = null;
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, (metadata) => {
        // var msg = "Progress " + metadata.percent.toFixed(2) + " %";
        // if (metadata.currentFile) {
        //   msg += ", current file " + metadata.currentFile;
        // };
        // console.log(msg);
        this.setState({
          compressProgress: metadata.percent.toFixed(2)
        })
      }).then(blob => {
        this.setState({
          compressProgress: -1
        })
        fileObj = new File([blob], `${fileName}.zip`);
        const regObj = new RegExp('(.zip)$', 'ig');
        const match = fileObj.name.match(regObj);
        const name = fileObj.name.slice(0, -(match[0].length));
        _fileList.push({
          name: name,
          fileName: fileObj.name,
          editStatus: false,
          file: { file: fileObj, fileName: fileObj.name },
          fileSuffix: ZIP
        })

        this.setState({
          fileList: [..._fileList],
          compressing: false,
          showFileList: true,
          errorFileList: [..._errorFileList]
        });
        this.props.onUploadFile(_fileList, _errorFileList, 'folder');
        if (this.uploadRef && this.uploadRef.current) {
          this.uploadRef.current.value = "";
        }
      })
    } else {
      let fileType = 'file';
      if (accept !== '*' || special) {
        const _acceptList = strDelimited(accept, ",");
        let arrTxt = _acceptList.join('|');
        if (special === '.sNp' || ([SNP_FOLDER, SNP_ZIP].includes(special) && acceptType === '*')) {
          arrTxt = '.s[0-9]{1,}p';
        }

        if (([SNP_FOLDER, SNP_ZIP, SP_FOLDER, PLOC_SP_FOLDER, FOLDER, FILE_FOLDER, IBIS_ZIP, TXT_ZIP, CPM_FOLDER].includes(special)) && acceptType === ZIP) {
          arrTxt = ZIP;
          fileType = 'zip';
        }

        if (extraAccept) {
          const extraList = strDelimited(extraAccept, ",");
          arrTxt = `${arrTxt}|${extraList.join('|')}`
        }

        const regObj = accept === "*" && acceptType !== ZIP && !([SNP_FOLDER, SNP_ZIP].includes(special) && acceptType === '*') ? false : new RegExp('(' + arrTxt + ')$', 'ig');
        for (let file of files) {
          const match = regObj ? file.name.match(regObj) : true;
          if (!match) {
            const _fileInfo = {
              name: file.name,
              fileName: file.name,
              editStatus: false,
              errorFile: true,
              file: { file: file, fileName: file.name }
            }
            if (addOptions) {
              for (let item of optionKeys) {
                _fileInfo[item] = defaultOptionObj[item]
              }
            }
            _errorFileList.push(_fileInfo);

            let formatError
            if (special === '.sNp') {
              formatError = `This file is not a touchstone file!`;
            } else {
              const title = special === FILE_FOLDER ? "" : (special ? special : accept)
              formatError = `This file is not a ${title} format file!`;
            }

            this.setState({
              formatError
            });
          } else {
            const fileInfo = {
              name: !regObj ? file.name : file.name.slice(0, -(match[0].length)),
              fileName: file.name,
              editStatus: false,
              file: { file: file, fileName: file.name },
              fileSuffix: match[0]
            }
            if (addOptions) {
              for (let item of optionKeys) {
                fileInfo[item] = defaultOptionObj[item]
              }
            }
            _fileList.push(fileInfo)
          }
        };
      } else {
        const _files = [...files].map(file => {
          const optionInfo = {};
          if (addOptions) {
            for (let item of optionKeys) {
              optionInfo[item] = defaultOptionObj[item]
            }
          }
          return {
            name: file.name,
            fileName: file.name,
            editStatus: false,
            file: { file: file, fileName: file.name },
            fileSuffix: '',
            ...optionInfo
          }
        });
        _fileList = [..._fileList, ..._files];
      }
      this.setState({
        fileList: [..._fileList],
        showFileList: true,
        errorFileList: [..._errorFileList]
      });
      this.props.onUploadFile(_fileList, _errorFileList, fileType);
      this.uploadRef.current.value = "";
    }
  }

  delFile = (e, name, checkIndex, isError) => {
    e.stopPropagation();
    let { fileList, errorFileList } = this.state;
    let checkFileList = isError ? errorFileList : fileList, index;
    for (let fileIndex = 0; fileIndex < checkFileList.length; fileIndex++) {
      if (checkFileList[fileIndex].name === name && fileIndex === checkIndex) {
        index = fileIndex;
      }
    }
    if (isError) {
      errorFileList.splice(index, 1);

      if (errorFileList.length > 0) {
        this.setState({
          errorFileList: [...errorFileList]
        })
      } else {
        this.setState({
          errorFileList: [],
          formatError: ''
        });
      }
    } else {
      fileList.splice(index, 1);

      if (fileList.length > 0) {
        this.setState({
          fileList: [...fileList]
        })
      } else {
        this.setState({
          fileList: []
        })
      }
    }
    this.setState({
      showFileList: (fileList.length || errorFileList.length) ? true : false
    })
    this.props.delFile(fileList, errorFileList);
  }

  changeEditStatus = (item, i) => {
    const { fileList } = this.state;
    let _fileList = [...fileList];
    const index = _fileList.findIndex((file, _index) => item.name === file.name && _index === i);
    _fileList[index].editStatus = _fileList[index].editStatus ? false : true;
    if (_fileList[index].editStatus && this.inputRef) {
      const { input } = this.inputRef;
      input.focus();
    }
    this.setState({
      fileList: [..._fileList],
      currentName: _fileList[index].name
    }, () => {
      if (_fileList[index].editStatus && this.inputRef) {
        const { input } = this.inputRef;
        input.focus();
      }
    })
  }

  changeName = (e, item) => {
    e.stopPropagation();
    e.target.focus()
    this.setState({
      currentName: e.target.value
    })
  }

  saveName = (e, item, i) => {
    e.stopPropagation();
    const { fileList } = this.state;
    const { accept } = this.props;
    let _fileList = [...fileList];
    const index = _fileList.findIndex((file, _index) => item.name === file.name && _index === i);
    if (e.target.value) {
      _fileList[index].name = `${e.target.value}`;
      _fileList[index].fileName = `${e.target.value}${accept}`;
    }
    this.setState({
      fileList: [..._fileList]
    })
    _fileList[index].editStatus = _fileList[index].editStatus ? false : true;
    this.setState({
      fileList: [..._fileList]
    });
    this.props.changeFileName(_fileList[index].name, item.name);
  }

  InputClick = (e) => {
    e.stopPropagation();
  }

  saveFile = () => {
    const { errorFileList, acceptType } = this.state;
    if (errorFileList.length > 0) {
      return;
    } else {
      let _libraryFormat = "file";
      if (acceptType === ZIP) {
        _libraryFormat = "zip";
      }
      if (acceptType === "directory" && [ROCKY, "BMA"].includes(this.props.product)) {
        _libraryFormat = "folder";
      }
      this.props.saveFile(_libraryFormat);
    }
  }

  selectClick = (e) => {
    e.domEvent.stopPropagation()
  }
  divClick = (e) => {
    e.stopPropagation()
  }


  getProgressFormat = (percent, successPercent) => {
    if (successPercent === 100) {
      return <CheckCircleOutlined className='upload-success-icon' />;
    } else if (percent > -1 && percent < 100) {
      return `Compressing: ${percent}%`;
    } else if (successPercent > -1 && successPercent < 100) {
      return `Uploading: ${successPercent}%`;
    } else {
      return '';
    }
  };

  changeSelect = (value, key, fileInfo, fileIndex) => {

    if (!value) {
      return;
    }
    const { fileList } = this.state;
    let _fileList = [...fileList];
    const index = _fileList.findIndex((file, _index) => fileInfo.name === file.name && _index === fileIndex);
    if (value && index > -1) {
      _fileList[index][key] = value;
    }
    this.setState({
      fileList: [..._fileList],
    });
    this.props.updateFileList && this.props.updateFileList(_fileList);
  }

  getLibraryOptionsTitle = (libraryOptions) => {
    return <li className='upload-file-title'>
      <div className="upload-file-name-title">File Name</div>
      <div className="upload-file-option-title">
        {libraryOptions.map(item => {
          return <div key={item.key} className="upload-file-option-title-item">{item.title}</div>
        })}
      </div>
    </li>
  }

  getLibraryOptions = (libraryOptions, itemData, fileIndex) => {
    return <div className="upload-file-option-main">
      {libraryOptions.map(item =>
        <Select
          key={item.key}
          onChange={(value) => this.changeSelect(value, item.key, itemData, fileIndex)}
          className="upload-file-option-select aurora-select"
          value={itemData[item.key] || ""}
          popupClassName="upload-file-option-dropdown"
        >
          {item.options.map(it => <Option key={it} value={it}>{it}</Option>)}
        </Select>)}
    </div>
  }

  render() {
    const { text, accept, repeatedName, doubleName = '', errorName = [],
      errorMsg, importError, allowMulti, nameStatus, importText, special, extraAccept, libraryOptions, uploadCheckMessage, product } = this.props;
    let { fileList, compressing, showFileList, currentName, formatError,
      errorFileList, acceptType } = this.state;
    const { compressProgress, uploadProgress, uploading, notSelect = false, uploadTip } = this.props;
    repeatedName && allowMulti && fileList.forEach(item => {
      const index = repeatedName.findIndex(name => name === (nameStatus ? item.fileName : item.name));
      if (index > -1) {
        item.className = 'color-red';
      } else {
        item.className = '';
      }
    });

    if (allowMulti) {
      fileList.forEach(file => {
        let name = nameStatus ? file.fileName : file.name;
        if (name === doubleName) {
          file.class = 'color-red';
        } else {
          file.class = '';
        }
      })
    }
    if (allowMulti) {
      fileList.forEach(file => {
        const index = errorName.findIndex(name => name === file.name);
        if (index > -1 && typeof (index) !== 'boolean') {
          file.Color = 'color-red';
        } else {
          file.Color = '';
        }
      })
    }

    let _accept = accept, options = folderList;
    if (special === SP_FOLDER) {
      _accept = acceptType;
      options = spFolderList;
    } else if (special === PLOC_SP_FOLDER) {
      _accept = acceptType
      options = plocSpFolderList
    } else if (special === SNP_FOLDER) {
      _accept = acceptType;
      options = sNpFolderList;
    } else if (special === SNP_ZIP) {
      _accept = acceptType;
      options = sNpZipList;
    } else if (special === FILE_FOLDER) {
      _accept = acceptType;
      options = fileAndFolderList;
    } else if (special === IBIS_ZIP) {
      _accept = acceptType;
      options = ibisZipList;
    } else if (special === TXT_ZIP) {
      _accept = acceptType;
      options = txtZipList;
    } else if (special === CPM_FOLDER) {
      _accept = acceptType
      options = cpmFolderList
    }
    _accept = extraAccept ? `${_accept},${extraAccept}` : _accept;

    const hasOptions = libraryOptions && libraryOptions.length;
    return (
      <Fragment>
        {importError ? <div className={"library-upload-msg-bar import-folder-message upload-error"}>{importError}</div>
          : (compressProgress > -1 || uploadProgress > -1) && <Progress
            size={{ height: 12 }}
            strokeColor={'#1890ff'}
            percent={compressProgress}
            success={{ percent: uploadProgress }}
            format={(percent, successPercent) => this.getProgressFormat(percent, successPercent)}
            className="library-upload-progress-bar"
          />}
        <Spin spinning={uploading} tip={uploadTip ? uploadTip : "Uploading..."}>
          {!showFileList && <div className="upload-box" onClick={(e) => this.unloadFile(e)}>
            <div className="upload-drag">
              <span className="upload-btn">
                <div className="upload-drag-container">
                  <p className="upload-drag-icon">
                    <FolderAddOutlined className='upload-icon-add' />
                  </p>
                  <p className="upload-text" style={(accept === ZIP || [SNP_FOLDER, SP_FOLDER, PLOC_SP_FOLDER, SNP_ZIP, IBIS_ZIP, TXT_ZIP, CPM_FOLDER].includes(special)) ? { display: 'inline-block' } : {}}>{text}</p>
                  {!notSelect && (accept === ZIP || [SNP_FOLDER, SP_FOLDER, PLOC_SP_FOLDER, SNP_ZIP, FOLDER, FILE_FOLDER, IBIS_ZIP, TXT_ZIP, CPM_FOLDER].includes(special)) ? <div className='upload-file-select-div' onClick={(e) => this.divClick(e)}>
                    <Select dropdownStyle={{ zIndex: 100000 }} value={acceptType} onChange={(e) => this.changeFileType(e)} popupMatchSelectWidth={false}>
                      {options.map(item => (
                        <Option key={item.value} onClick={(e) => this.selectClick(e)} title={item.title} value={item.value}>{item.title}</Option>
                      ))}
                    </Select>
                  </div> : null}
                </div>
              </span>
            </div>
          </div>}
          {showFileList &&
            <div className={hasOptions ? 'file-list-has-option-box file-list-box' : 'file-list-box'}>
              <Spin spinning={compressing} tip="Compressing...">
                {libraryOptions && libraryOptions.length ? this.getLibraryOptionsTitle(libraryOptions) : null}
                <ul className='upload-file-list'>
                  {errorFileList.map((item, index) =>
                    <li key={`${item.name}-${index}`} className='upload-file-item'>
                      <div className="file-name-main">
                        <FileZipOutlined className='upload-file-icon' />
                        <span className='file-name-span'>
                          <span className={`file-name-content color-red`}>{item.name}</span>
                        </span>
                      </div>
                      <CloseOutlined
                        className='upload-del-icon'
                        onClick={(e) => this.delFile(e, item.name, index, true)} />
                    </li>
                  )}
                  {fileList.map((item, index) => {
                    const notEditClass = ((product === ROCKY || special === IBIS_ZIP) && item.fileSuffix === ZIP) ? "file-name-no-edit-content" : "";
                    return (
                      <li key={`${item.name}-${index}`} className='upload-file-item'>
                        <div className="file-name-main">
                          <FileZipOutlined className='upload-file-icon' />
                          <span className='file-name-span'>
                            {item.editStatus ?
                              <Input
                                ref={(input) => { this.inputRef = input; }}
                                className='file-name-input'
                                defaultValue={item.name}
                                value={currentName}
                                onChange={(e) => this.changeName(e, item, index)}
                                onBlur={(e) => this.saveName(e, item, index)}
                                onClick={(e) => this.InputClick(e)} />
                              :
                              <span className={`file-name-content ${item.className} ${item.class} ${item.Color} ${notEditClass}`}
                                onClick={() => { !notEditClass && this.changeEditStatus(item, index) }}>{item.name}</span>}
                            {item.fileSuffix}
                          </span>
                        </div>
                        {libraryOptions && libraryOptions.length ? this.getLibraryOptions(libraryOptions, item, index) : null}
                        <CloseOutlined
                          className='upload-del-icon'
                          onClick={(e) => this.delFile(e, item.name, index, false)} />
                      </li>
                    );
                  })}
                </ul>
                {(errorMsg || formatError) && <span className='upload-file-error-msg'>{errorMsg}<div>{formatError}</div></span>}
                {uploadCheckMessage && <span className='upload-file-error-msg upload-file-check-msg'>{uploadCheckMessage}</span>}
                {/*  {formatError && <span className='upload-file-error-msg'>{formatError}</span>} */}
                {fileList.length > 0 ? <div className='upload-file-button'>
                  {allowMulti && <div className='upload-move-button' onClick={(e) => this.unloadFile(e)} ><PlusCircleOutlined className='import-file-icon' /><span className='import-file-span'>Import {importText}</span></div>}
                  <Button type='primary' className='upload-button' onClick={this.saveFile}>Upload</Button>
                </div> : null}
              </Spin>
            </div>
          }
          {acceptType === 'directory' ? <input
            type='file'
            ref={this.uploadRef}
            style={{ display: 'none' }}
            webkitdirectory="true"
            directory="true"
            multiple={allowMulti}
            onChange={(e) => this.onUploadFile(e, special)}
          /> : <input
            type='file'
            ref={this.uploadRef}
            style={{ display: 'none' }}
            accept={_accept}
            multiple={allowMulti}
            onChange={(e) => this.onUploadFile(e, special)}
          />
          }
        </Spin>
      </Fragment>
    );
  }
}

export default UploadFile;
