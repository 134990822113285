import { equal } from "./ArrayHelper";

function getType(data) {
  return Object.prototype.toString.call(data);
}

function isEqualType(a, b) {
  return getType(a) === getType(b);
}

function isNotSupportType(data) {
  return [
    '[object Object]',
    '[object Function]',
    '[object Date]',
    '[object RegExp]',
    '[object Error]',
    '[object Arguments]'
  ].includes(getType(data));
}

// It is only applicable to simple data type judgment
function isEqualObject(a, b) {
  if (getType(a) !== '[object Object]' || getType(b) !== '[object Object]') {
    return false;
  }
  if (equal(Object.keys(a), Object.keys(b))) {
    for (let k of Object.keys(a)) {
      if (!isEqualType(a[k], b[k]) || isNotSupportType(a[k])) {
        return false;
      }
      if (getType(a[k]) === '[object Array]') {
        if (!equal(a[k], b[k])) {
          return false;
        }
      } else if (a[k] !== b[k]) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

export {
  isEqualObject
}