import http from "./http";
import apiProcess from "./utility";

/**
 * auto find reference nets by signal nets
 * 2020/06/28
 *
 * @export
 * @param {object} signals
 * @returns
 */
function findReferenceNets({ signals }) {
  return http.post(`/sierra/ref-net/find`, signals);
}

/**
 * Get auto find reference nets by signal nets
 * 2020/06/28
 *
 * @export
 * @param {object} signals
 * @returns
 */
function getReferenceNets(key) {
  return http.get(`/sierra/ref-net/detail?key=${key}`);
}

/**
 * Get auto find reference nets by signal nets
 * 2020/06/28
 *
 * @export
 * @param {object} signals
 * @returns
 */
function getReferenceNetsBySignal({ key }) {
  return apiProcess(getReferenceNets, key);
}

/**
 * Get auto find reference nets by signal nets
 * 2020/06/28
 *
 * @export
 * @param {object} signals
 * @returns
 */
function findReferenceNetsBySignal({ signals }) {
  return apiProcess(findReferenceNets, { signals });
}

export {
  findReferenceNetsBySignal,
  getReferenceNetsBySignal
}