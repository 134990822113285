import { doSimulation, getRockyVerificationLog, canGenerateEyeDiagram, eyediagramGenerate, getVerificationEyeMaskSetting, generateRockyEyeDiagram, doPackageSimulation, doPackagePDNSimulation, doSSNSimulation, doPCBChannelSimulation, doSSNExtraction, getRockySSNChannelLog } from '../api/Rocky/simulationCtrl';
import { getChannelResultCurve, getChannelResultList, getPackageChannelResultCurve, getMergeChannelResultList, getMergeChannelResultCurve } from '../api/Rocky/simulationCtrl';
import apiProcess from '../api/utility';
import { SUCCESS, API_RES_MESSAGE, CHECK_FAIL } from '../../constants/returnCode';

/**
 * do simulation 
 * 2020/09/24
 * @param {array} ids
 * @export
 * @returns Promise
 */
export function doRockySimulation(ids) {
  return new Promise((resolve, reject) => {
    return doSimulation(ids).then(res => {
      if (!res || !res.data) {
        reject("[ERROR] Simulation failed!");
        return;
      }
      if (res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else if (res.data.code === API_RES_MESSAGE) {
        reject({ msg: res.data.msg || "", code: API_RES_MESSAGE })
      } else {
        reject(res.data.msg || "[ERROR] Simulation failed!")
      }
    }, error => {
      reject(`[ERROR] ${error}` || "[ERROR] Simulation failed!")
    })
  })
}

/**
 * Get verification log, response: file
 * 2020.06/22
 * @param {string} verificationId
 * @export
 * @returns Promise
 */
export function getVerificationLogByID(verificationId) {
  return new Promise((resolve, reject) => {
    getRockyVerificationLog(verificationId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
};

/**
 * Get eye diagram status, Whether the eye diagram can be generated
 *
 * @export
 * @param {String} verificationId
 * @returns
 */
export function canGenerateEyeDiagramByVerificationId(verificationId) {
  return new Promise((resolve, reject) => {
    apiProcess(canGenerateEyeDiagram, verificationId).then(res => {
      resolve(res);
    }, error => {
      console.error(error);
      resolve(false);
    })
  })
}


export function getChannelResultListPromise(verificationId) {
  return apiProcess(getChannelResultList, verificationId)
}

export function getChannelResultCurvePromise(params) {
  return apiProcess(getChannelResultCurve, params)
}

export function eyediagramGeneratePromise(params) {
  return apiProcess(eyediagramGenerate, params)
}

export function getVerificationEyeMaskSettingPromise(verificationId) {
  return apiProcess(getVerificationEyeMaskSetting, verificationId)
}

export function generateRockyEyeDiagramPromise(params) {
  return apiProcess(generateRockyEyeDiagram, params)
}

export function getPackageChannelResultCurvePromise(params) {
  return apiProcess(getPackageChannelResultCurve, params)
}

/**
 * do package simulation 
 * 2023/02/06
 * @param {array} ids
 * @export
 * @returns Promise
 */
export function doRockyPackageSimulation(ids) {
  return apiProcess(doPackageSimulation, ids, false, true);
}

export function doRockyPackagePDNSimulation(id) {
  return apiProcess(doPackagePDNSimulation, id)
}

export function doRockySSNSimulation(verificationId) {
  return new Promise((resolve, reject) => {
    return doSSNSimulation(verificationId).then(res => {
      if (!res || !res.data) {
        reject("[ERROR] Simulation failed!");
        return;
      }
      if (res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else if (res.data.code === API_RES_MESSAGE) {
        reject({ msg: res.data.msg || "", code: API_RES_MESSAGE })
      } else {
        reject(res.data.msg || "[ERROR] Simulation failed!")
      }
    }, error => {
      if (error && error.response && error.response.data && error.response.data.code === CHECK_FAIL && error.response.data.msg) {
        reject(`[ERROR] ${error.response.data.msg}` || "[ERROR] Simulation failed!")
      } else {
        reject(`[ERROR] ${error}` || "[ERROR] Simulation failed!")
      }
    })
  })
}

export function doRockySSNExtraction(obj) {
  return new Promise((resolve, reject) => {
    return doSSNExtraction(obj).then(res => {
      if (!res || !res.data) {
        reject("[ERROR] Simulation failed!");
        return;
      }
      if (res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else if (res.data.code === API_RES_MESSAGE) {
        reject({ msg: res.data.msg || "", code: API_RES_MESSAGE })
      } else {
        reject(res.data.msg || "[ERROR] Simulation failed!")
      }
    }, error => {
      if (error && error.response && error.response.data && error.response.data.code === CHECK_FAIL && error.response.data.msg) {
        reject(`[ERROR] ${error.response.data.msg}` || "[ERROR] Simulation failed!")
      } else {
        reject(`[ERROR] ${error}` || "[ERROR] Simulation failed!")
      }
    })
  })
}

export function doRockyPCBChannelSimulation(id) {
  return apiProcess(doPCBChannelSimulation, id)
}

export function getMergeChannelResultListPromise({ verificationId, category }) {
  return new Promise((resolve, reject) => {
    if (!verificationId) {
      resolve(null);
      return;
    }
    getMergeChannelResultList({ verificationId, category }).then(response => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  });
}

export function getMergeChannelResultCurvePromise(params) {
  return apiProcess(getMergeChannelResultCurve, params)
}

/**
 * Get ssn channel log
 * 2024.06/21
 * @param {string} verificationId
 * @export
 * @returns Promise
 */
export function getSSNChannelLogPromise(verificationId) {
  return new Promise((resolve, reject) => {
    getRockySSNChannelLog(verificationId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
};