import React from 'react';
import { numberCheck } from '@/services/PDN/library/libraryData';

export function getDecapErrorCheck(decapData, decapNames, decapInfo) {
  let error = [];

  if (decapData && decapData.length > 0) {
    decapData.forEach(item => {
      //item: { id : {R: '',  L: '', C: ''} }

      if (Object.keys(item) && Object.keys(item).length > 0) {
        let key = Object.keys(item)[0];
        //{R: '',  L: '', C: ''}
        const data = item[key];
        let currentInfo = decapNames.find(decap => decap.id === key); // {name,id,libraryType,type,key}\

        //current Decap not exist
        if (!currentInfo) {
          const info = decapInfo.find(item => item.id === key);
          if (info) {
            error.push(<p style={{ margin: 0 }}>Decap model <span className="font-bold">{info.name}</span> does not exist.</p>);
          }
        }
        if (data && currentInfo) {
          //Check the Resistance value format
          let errorMsg = numberCheck(data.R);
          if (errorMsg) {
            error.push(<p style={{ margin: 0 }}>The value of decap model <span className="font-bold">{currentInfo.name}</span> Resistance {errorMsg}</p>);
          }

          //Check the Inductance value format
          errorMsg = numberCheck(data.L);

          if (errorMsg) {
            error.push(<p style={{ margin: 0 }}>The value of decap model <span className="font-bold">{currentInfo.name}</span> Inductance {errorMsg}</p>);
          }

          //Check the Capacitance value format
          errorMsg = numberCheck(data.C);

          if (errorMsg) {
            error.push(<p style={{ margin: 0 }}>The value of decap model <span className="font-bold">{currentInfo.name}</span> Capacitance {errorMsg}</p>);
          }
        }
      }
    })
  }

  if (error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }
}