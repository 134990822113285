import { AndesProjectSaga } from './project';
import { AndesLibrarySaga } from './library';
import AndesV2DesignUploadSaga from '../AndesSider/uploadPCB/store/sagas';
import { AndesPreLayoutSaga } from './preLayout';
import { AndesChannelSaga } from './channel';
import { AndesEndToEndChannelSaga } from './endToEndChannel';
import { AndesSimulationSaga } from './simulation';
import AndesChannelExtractionSaga from './simulation/extraction/saga';
import channelResultSaga from './result/resultSaga';
import endToEndSimulationSaga from './simulation/endToEndChannel/saga';
import { AndesSweepSaga } from './sweep';
import AndesSweepExtractionSaga from './simulation/sweep/saga';

export default [
  AndesProjectSaga,
  AndesLibrarySaga,
  AndesV2DesignUploadSaga,
  AndesPreLayoutSaga,
  AndesChannelSaga,
  AndesSimulationSaga,
  AndesChannelExtractionSaga,
  AndesEndToEndChannelSaga,
  channelResultSaga,
  endToEndSimulationSaga,
  AndesSweepSaga,
  AndesSweepExtractionSaga
]