import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import Line from '@/components/Line';
import LayoutExplorer from '../../LayoutExplorer/Andes';
import { closeTabFooter, changeTabMenu } from '../../tabMonitor/action';
import TabFooter from '@/components/TabSwitchFooter';
import { getCCCMsg } from '../../CCC/store/action';
import { VERIFICATION, RESULT } from '../constants';
import Setup from './Setup';
import Result from '../results';
import './content.css';

class LayoutLR extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: 500,
      linePosition: {
        position: "absolute",
        left: 500,
      },
      height: 250,
      bottomLinePosition: {
        position: "absolute",
        bottom: 250
      },
      andesResize: true,
      loading: true
    }
  }

  componentDidUpdate(prevProps) {
    const { leftWidth } = this.props;
    const bodyWidth = document.body.clientWidth;

    if (prevProps.leftWidth !== leftWidth) {
      let { width } = this.state;
      if (width > bodyWidth - 320 - leftWidth) {
        width = bodyWidth - 320 - leftWidth;
      }
      this.setState({ width })
      let position = {
        position: "absolute",
        left: width,
        minWidth: 396,
        maxWidth: bodyWidth - 320 - leftWidth
      }
      const { andesResize } = this.state;
      this.setState({
        linePosition: position,
        andesResize: !andesResize
      });
    }

    const { tabVisible, tabSelectKeys } = this.props;

    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props.getCCCMsg();
    }
  }

  changeWidth = (width) => {
    const bodyWidth = document.body.clientWidth;
    const { leftWidth } = this.props;

    if (width < 396) {
      width = 396
    } else if (width > bodyWidth - 320 - leftWidth) {
      width = bodyWidth - 320 - leftWidth;
    }
    this.setState({ width })
    let position = {
      position: "absolute",
      left: width,
      minWidth: 396,
      maxWidth: bodyWidth - 320 - leftWidth
    }
    const { andesResize } = this.state;
    this.setState({
      linePosition: position,
      andesResize: !andesResize
    });
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this.screenChange();
    this.parentEle = document.getElementsByClassName('aurora-content')[0];

    if (this.parentEle) {
      const width = this.parentEle.offsetWidth / 2;
      this.setState({
        width: width,
        linePosition: {
          position: "absolute",
          left: width,
        },
      });
    }
    this.setState({
      loading: false
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getBodySize();
  }

  getBodySize = () => {
    let bodyClientHeight = document.body.clientHeight;
    let bodyClientWidth = document.body.clientWidth;
    if (bodyClientHeight <= 300) {
      this.setState({
        height: 100,
        bottomLinePosition: {
          position: "absolute",
          bottom: 100,
        },
      })
    } else {
      this.setState({
        height: 250,
        bottomLinePosition: {
          position: "absolute",
          bottom: 250,
        },
      })
    }

    this.parentEle = document.getElementsByClassName('aurora-content')[0];
    if (this.parentEle) {
      const width = this.parentEle.offsetWidth / 2;
      this.setState({
        width: width,
        linePosition: {
          position: "absolute",
          left: width,
        }
      });
    }
    return { bodyClientWidth, bodyClientHeight };
  }

  changeHeight = (height) => {
    let { bodyClientHeight } = this.getBodySize();
    if (height <= 142) {
      height = 142;
    } else if (height >= bodyClientHeight - 100) {
      height = bodyClientHeight - 100
    }
    if (height < 142) {
      height = 142;
    }
    this.setState({ height })
    let position = {
      position: "absolute",
      bottom: height + 1,
      minHeight: 142,
      maxHeight: bodyClientHeight - 100
    }
    this.setState({
      bottomLinePosition: position,
    });
  }

  changeSize = (height) => {
    this.changeHeight(height);
  }

  getVerification = () => {
    const { tabVisible, viewList, view, currentProjectVerifications } = this.props;
    const { bottomLinePosition, andesResize } = this.state;
    let list = view ? [...viewList] : [];
    if (list.includes(RESULT)) {
      return <Fragment>
        <div
          id='andes-content-main'
          className='my-andes-content'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, overflow: 'hidden' }}
        >
          <Result />
        </div>
        {tabVisible && this.getMonitor()}
      </Fragment>
    } else if (list.includes(VERIFICATION)) {
      return <Fragment>
        {currentProjectVerifications.length > 0 ?
          (<div
            id='andes-content-main'
            className='my-andes-content'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0 }}
          >
            <Setup andesResize={andesResize} />
          </div>) : null}
        {tabVisible && this.getMonitor()}
      </Fragment>
    }
    return tabVisible && this.getMonitor();
  }


  changeTabMenu = ({ selectKeys }) => {
    const { menuType, verificationId, currentProjectId } = this.props;
    this.props._changeTabMenu({ selectKeys, menuType, verificationId, projectId: currentProjectId })
  }

  getMonitor = () => {
    const { closeTabFooter } = this.props;
    const { height, bottomLinePosition, width } = this.state;
    return <div className='andes-footer'>
      <TabFooter
        linePosition={bottomLinePosition}
        height={height}
        changeHeight={this.changeHeight}
        closeTabFooter={closeTabFooter}
        changeSize={this.changeSize}
        leftWidth={width}
        changeTabMenu={this.changeTabMenu}
      >
        {this.props.monitorChild(width)}
      </TabFooter>
    </div>
  }

  getPCB = () => {
    const { leftWidth, view, viewList, currentProjectDesigns } = this.props;
    const { width } = this.state;
    let list = view ? [...viewList] : [];
    if (list.includes('PCB') && currentProjectDesigns.length > 0) {
      return <Fragment>
        <LayoutExplorer leftWidth={leftWidth + width} width={width} viewList={list} />
      </Fragment>
    };
    return null;
  }

  render() {
    const { width, linePosition, loading } = this.state;
    return (
      <Fragment>
        <Spin size='large' spinning={loading}>
          <div className='andes-content-left'
            style={{ width: width }}
          >
            {this.getVerification()}
          </div>
          <Line
            position={linePosition}
            changeWidth={(width) => this.changeWidth(width)}
            width={width}
            resize={(width) => this.changeWidth(width)}
          />
          <div className='aurora-content-right aurora-PCB-layout-content'
            style={{ left: width }}
          >
            {this.getPCB()}
          </div>
        </Spin>
      </Fragment>
    )
  }
};

const mapState = (state) => {
  const { AndesReducer } = state;
  const { project: { view, verificationId, currentProjectId, currentProjectVerifications, currentProjectDesigns }, simulationReducer: { existResult } } = AndesReducer;
  const { tabVisible, tabSelectKeys, menuType } = state.TabMonitorReducer;
  return {
    view,
    tabVisible,
    tabSelectKeys,
    verificationId,
    currentProjectId,
    menuType,
    existResult,
    currentProjectVerifications,
    currentProjectDesigns
  };
}

const mapDispatch = (dispatch) => ({
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }));
  },
  getCCCMsg() {
    dispatch(getCCCMsg())
  }
})

export default connect(mapState, mapDispatch)(LayoutLR);

