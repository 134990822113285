class EndToEndChannelConstructor {
  constructor() {
    this.endToEndChannelList = new Map(); //key - end to end channel id, value :{ id ,name,type, version, subId, projectId,verificationId .... }
  }

  getSize = () => {
    return this.endToEndChannelList.size;
  };

  getEndToEndChannelList = () => {
    return this.endToEndChannelList;
  }

  getEndToEndChannelValues = (projectId) => {
    const endToEndChannels = Array.from(this.endToEndChannelList.values());
    if (!projectId) {
      return endToEndChannels;
    } else {
      return endToEndChannels.filter(item => item.projectId === projectId);
    }
  }

  getEndToEndChannelByVerificationId(verificationId) {
    const endToEndChannels = Array.from(this.endToEndChannelList.values());
    return endToEndChannels.find(item => item.verificationId === verificationId);
  }

  getEndToEndChannel = (id) => {
    return this.endToEndChannelList.get(id);
  }

  getList = (ids) => {
    return ids.map(id => this.endToEndChannelList.get(id));
  }

  addEndToEndChannel = (id, data) => {
    this.endToEndChannelList.set(id, data);
  }

  delEndToEndChannel = (id) => {
    this.endToEndChannelList.delete(id);
  }

  updateEndToEndChannel = (newData) => {
    this.endToEndChannelList = newData;
  }

  clearCache = () => {
    this.endToEndChannelList = new Map();
  }
};

let endToEndChannelConstructor = new EndToEndChannelConstructor();
export default endToEndChannelConstructor;