import { PKG_SPICE, PKG_TOUCHSTONE, CONNECTOR_TOUCHSTONE, CABLE_TOUCHSTONE, PCB_TOUCHSTONE } from "@/constants/libraryConstants";
import { SPICE } from "../../constants/libraryConstants";

function getTitleFromType(type) {
  switch (type) {
    case "pkg":
      return "Package";
    case "connector":
      return 'Connector';
    case "pcb":
      return 'PCB';
    case "cable":
      return "Cable"
    default: return "";
  }
}

function connectorPkgErrorCheck({ model, comp, error, type, libraryConstructor, isMultiModel }) {
  const pkgFiles = model.files;
  const existFiles = pkgFiles && pkgFiles.length ? pkgFiles.filter(item => item.fileName && item.type) : [];
  let checkedFileIds = [];

  if (!existFiles.length) {
    return error;
    /*  const titleType = getTitleFromType(type)
     error.push({
       title: "[Components]",
       boldKey: comp.name,
       errorMsg: `${titleType} Model files cannot be empty.`
     }) */
  }
  const titleType = getTitleFromType(type);
  const compTitle = titleType ? `[Components - ${titleType} Model]` : "[Components]";

  for (let _file of existFiles) {
    if (!_file.libraryId || (_file.type === 'SPICE' && !_file.subckt)) {
      continue;
    }
    const fileType = _file.type === 'Touchstone' ? 'touchstone' : 'spice';
    let _types = [];
    if (type === "pkg") {
      // _types = [PKG_TOUCHSTONE, PKG_SPICE];
      _types = [PKG_TOUCHSTONE, SPICE];
    } else if (type === "pcb") {
      _types = [PCB_TOUCHSTONE, SPICE]
    } else if (type === "connector") {
      _types = [CONNECTOR_TOUCHSTONE, SPICE]
    } else if (type === 'cable') {
      _types = [CABLE_TOUCHSTONE, SPICE]
    }

    const fileExist = _file.type === 'Touchstone' ? libraryConstructor.checkFile(_types[0], _file.libraryId) : libraryConstructor.checkFile(_types[1], _file.libraryId);
    checkedFileIds.push(_file.libraryId);
    if (!fileExist || !checkedFileIds.includes(_file.libraryId)) {
      error.push({
        title: compTitle,
        boldKey: comp.name,
        errorMsg: `${fileType} file ${_file.fileName} not exist.`
      })
    }
  }

  const pairs = model.pairs || [];
  let _disType = "out", _type = "u";
  if (type === "pkg") {
    _disType = "die";
  } else if (type === "pcb" && !isMultiModel) {
    _disType = "pcb";
    _type = "pcb";
  }

  for (let pin of comp.pins) {
    const pinNode = pairs.find(item => item.pin === pin.pin) || {};
    const pinDieNode = pairs.find(item => item.pin === `${pin.pin}_${_type}`) || {};
    if (pinNode.node && !pinDieNode.node) {
      error.push({
        title: compTitle,
        boldKey: comp.name,
        errorMsg: ` ${pin.pin} ${_disType} port is not set.`
      });
      continue;
    }

    if (!pinNode.node && pinDieNode.node) {
      error.push({
        title: compTitle,
        boldKey: comp.name,
        errorMsg: ` ${pin.pin} port is not set.`
      });
      continue;
    }

    if (pinNode.node && pinDieNode.node && (pinDieNode.libraryId !== pinNode.libraryId
      || ((pinNode.subckt || pinDieNode.subckt) && pinDieNode.subckt !== pinNode.subckt))) {
      error.push({
        title: compTitle,
        boldKey: comp.name,
        errorMsg: ` ${pin.pin} port and ${pin.pin} ${_disType} port should be the same model file.`
      });
    }
  }
  return error;
}

function getModelBoldKey(modelType) {
  switch (modelType) {
    case "pcb":
    case "PCB":
      return "PCB";
    case "pkg":
    case "Package":
      return "Package";
    default: return modelType.charAt(0).toUpperCase() + modelType.slice(1);
  }
}

function getMultiFileErrorCheck(model, multiFilesParseInfo) {
  let error = [], errorId = [];
  for (let fileInfo of model.files) {
    const findMulti = multiFilesParseInfo.find(item => item.libraryId === fileInfo.libraryId);
    if (findMulti && !errorId.includes(fileInfo.libraryId)) {
      const filterPort = model.pairs.filter(item => item.libraryId === fileInfo.libraryId && item.modelKey === fileInfo.modelKey);
      if (filterPort && filterPort.length && filterPort.length !== findMulti.ports.length) {
        errorId.push(findMulti.libraryId)
        const type = getModelBoldKey(model.modelType)
        error.push({
          title: "[Components]",
          boldKey: `${type} Model`,
          errorMsg: `When reusing "${findMulti.fileName}" files, all ports in this file need to be used.`
        })
      }
    }
  }
  return error
}

export { connectorPkgErrorCheck, getMultiFileErrorCheck };