
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TreeForm from '@/components/TreeNode';
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  PlayCircleOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Input, Tooltip, Checkbox, Button, Popconfirm, message } from 'antd';
import UploadPCB from './Upload';
import { expandMenu } from '../store/andes/action';
import { updateProjectMenu, openProject, clearCurrentProject, updateTrashMenu, changeTreeSelected, changeViewList, projectMenu } from '../store/project/action';
import { closeTabFooter } from '../../tabMonitor/action';
import {
  PROJECTS,
  PROJECT, PCBs, PCB,
  PROJECT_CREATE, TRASH, VERIFICATIONS,
  VERIFICATION, VERIFICATION_CREATE, RESULT
} from '../constants';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { andesProjectCopy } from '@/services/Andes';
import { strDelimited } from '@/services/helper/split';
import { getVerificationStatus } from '../../../services/Andes/helper/projectTree';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { checkNameFormat } from '../../../services/helper/nameFormatCheck';

const ADD = 'add', DELETE = 'delete', EDIT = 'edit', SHOW = 'show', UPLOAD_PCB = 'upload_pcb',
  VERIFY = 'verify', SIMULATION = 'simulation', CLEAN = 'clean', STATUS = 'status', COPY = 'copy', REPLACE = 'replace', RENAME = 'renames';

function getIconKey(type) {
  switch (type) {
    case PROJECTS:
      return 1;
    case PCB:
      return 2;
    case PCBs:
      return 3;
    case VERIFICATION:
      return 4;
    case VERIFICATIONS:
      return 5;
    case TRASH:
      return 6;
    case PROJECT:
      return 7;
    default: return 0;
  }
}
const IconKey = {
  1: [ADD],
  2: [DELETE, REPLACE],
  3: [UPLOAD_PCB],
  4: [DELETE, VERIFY, STATUS],
  5: [ADD, SIMULATION],
  6: [CLEAN],
  7: [DELETE, COPY, RENAME],
  0: []
}
class AndesTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      checkedKeys: [],
      andesSelect: undefined,
      inputVerificationName: null,
      deleteKey: null,
      copyLoading: null
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    this.props.updateTrashMenu();
    const { expandedKeys, verificationName, verificationId } = this.props;
    if (expandedKeys.length > 2) {
      expandedKeys.forEach((item, index) => {
        const [key, id] = strDelimited(item, "-");
        if (key === 'project') {
          this.props.UpdateProjectMenu({ openProjectId: id, verificationName, verificationId });
        }
      });
    } else {
      this.props.UpdateProjectMenu();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { autoFocus, view, layout, currentProjectVerifications, currentProjectDesigns } = this.props;
    if (autoFocus && this.inputRef) {
      const { input } = this.inputRef;
      input.focus();
    }
    if (autoFocus && this.selectRef) {
      if (this.selectRef) {
        this.selectRef.focus();
      }
    }

    const { designID, verificationId } = this.props;

    if (layout === 3 && view !== prevProps.view) {

      if (view === PCB && currentProjectDesigns.length > 0) {
        let current = currentProjectDesigns.find(item => item.id === designID);
        if (current) {
          this.props.changeTreeSelected([`${view}-${designID}`]);
        }
      } else if (view === VERIFICATION) {
        const currentVerification = currentProjectVerifications.find(item => item.id === verificationId);
        if (currentVerification) {
          this.props.changeTreeSelected([`${view}-${verificationId}`]);
        }
      } else if (view === RESULT) {
        this.props.changeTreeSelected([`${view}-${verificationId}`]);
      }
    }

    if (layout !== prevProps.layout && layout === 3) {
      if (view === PCB && currentProjectDesigns.length > 0) {
        let current = currentProjectDesigns.find(item => item.id === designID);
        if (current) {
          this.props.changeTreeSelected([`${view}-${designID}`]);
        }
      } else if (view === VERIFICATION) {
        const currentVerification = currentProjectVerifications.find(item => item.id === verificationId);
        if (currentVerification) {
          this.props.changeTreeSelected([`${view}-${verificationId}`]);
        }
      } else if (view === RESULT) {
        this.props.changeTreeSelected([`${view}-${verificationId}`]);
      }

    }
  }

  getType = (dataType) => {
    switch (dataType) {
      case PCB:
        return PCB;
      case PROJECT:
        return 'project';
      case VERIFICATION:
        return 'interface';
      default: return false;
    }
  }

  getIcon = (key, item) => {
    const { copyLoading } = this.state;
    const { delItem, editItem, startVerification, changeSimulateCheck, simulateKeys, showDecapFile } = this.props;
    const select = simulateKeys.findIndex(selected => (selected.verificationId === item.id));
    let checked = false;
    if (select > -1 && typeof (select) !== 'boolean') {
      checked = true;
    }

    let simMes = null;
    if (simulateKeys && simulateKeys.length > 1) {
      simMes = 'Please select an interface!'
    } else {
      simMes = 'Simulate';
    }

    let disabled = (item.status === VERIFY_RUNNING || item.status === WAITING || item.simStatus) ? true : false;
    switch (key) {
      case ADD:
        return (
          <PlusSquareOutlined
            className='aurora-tree-add-icon'
            id={`andes-add-${item.key}-icon`}
            key={key}
            onClick={(e) => this.addItem(e, { key: item.key, dataType: item.dataType, item })} />
        );
      case DELETE:
        const type = this.getType(item.dataType);
        return (type && this.delConfirm(key, item, type)) ||
          <CloseOutlined
            key={key}
            className='aurora-tree-del-icon'
            onClick={(e) => delItem(e, item.key, item)} />;
      case EDIT:
        return (
          <EditOutlined
            className='aurora-tree-edit-icon'
            key={key}
            onClick={(e) => editItem(e, { key: item.key, dataType: item.dataType, item })} />
        );
      case UPLOAD_PCB:
        return <UploadPCB key={key} data={item} type='upload' />;
      case SHOW:
        return (
          <InfoCircleOutlined
            className='aurora-tree-edit-icon'
            key={key}
            onClick={(e) => showDecapFile(e, item)} />
        );
      case VERIFY:
        return <span
          key={key}
          onClick={(e) => this.spanClick(e)}>
          <Checkbox
            key={key}
            disabled={disabled}
            checked={disabled ? false : checked}
            onChange={(e) => changeSimulateCheck(e, key, item)} />
        </span>
      case SIMULATION:
        return (
          <Tooltip
            key={key}
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            title={simMes}
            overlayClassName='aurora-tooltip'
          >
            <PlayCircleOutlined
              className={simulateKeys && simulateKeys.length > 0 ? 'aurora-tree-simulate-icon' : 'simulate-icon-disabled'}
              onClick={(e) => startVerification(e, item.key, item)} />
          </Tooltip >
        );
      case CLEAN:
        return (
          <Popconfirm
            key={key}
            overlayClassName='andes-clean-pop-confirm'
            placement="right"
            icon={<ExclamationCircleOutlined className='andes-confirm-icon' />}
            title="Whether you want to empty all the projects in the trash, empty can not be restored?"
            onConfirm={(e) => delItem(e, item.key, item)}
            okText="OK"
          >
            <DeleteOutlined className='aurora-tree-del-icon' onClick={(e) => this.spanClick(e)} />
          </Popconfirm>
        );
      case STATUS:
        let { verificationStatus, color } = getVerificationStatus(item.status, item.readyForSim, item);
        return verificationStatus === 'loading3' || verificationStatus === 'tick' ?
          <span key={key} className={`iconfont icon-${verificationStatus} aurora-status-icon`} style={{ color: color }} ></span>
          : <span
            key={key}
            className={verificationStatus === 'W' ? 'aurora-status-span aurora-status-waiting' : 'aurora-status-span'}
            style={{ color: color }}
          >
            {verificationStatus}</span>
      case REPLACE:
        return <UploadPCB key={key} data={item} type='replace' />;
      case COPY:
        return copyLoading && copyLoading === item.id ?
          <span
            key={key}
            title='Copying Current Project'
            className={`iconfont icon-loading3 aurora-tree-loading-icon`}
            style={{ color: '#1890ff' }}
          ></span>
          : <CopyOutlined
          title='Copy Current Project'
          className='aurora-tree-copy-icon'
          key={key}
          onClick={(e) => this.copyProject(e, item)
          } />;
      case RENAME:
        return (
          <EditOutlined
            title='Change Project Name'
            className='aurora-tree-project-rename-icon'
            key={key}
            onClick={(e) => this.renameProject(e, item)} />
        );
      default: return;
    }
  }

  addItem = (e, { key, dataType, item }) => {
    e.stopPropagation();
    switch (dataType) {
      case PROJECTS:
        this.addProjects();
        break;
      case VERIFICATIONS:
        this.addVerification(key);
        break;
      default: return;
    };
  }

  addProjects = () => {
    let { treeItems, expandedKeys } = this.props;
    if (!expandedKeys.includes(PROJECTS)) {
      this.props.expandMenu([...expandedKeys, PROJECTS]);
    }
    if (treeItems[0].children) {
      let dataTypes = treeItems[0].children.map(item => item.dataType);

      if (dataTypes.includes(PROJECT_CREATE)) {
        message.error('There are already projects being created.');
        return;
      }
    }

    const name = getDefaultName({ nameList: treeItems[0].children, defaultKey: 'Project' });
    treeItems[0].children.unshift(
      {
        id: name,
        name: name,
        key: name,
        dataType: PROJECT_CREATE,
        nodeClass: 'project-create-node'
      }
    );
    this.props.projectMenu({ treeItems: [...treeItems] });
    this.setState({
      inputValue: name
    })
    this.props.setAutoFocus(true);
  }

  addVerification = (key) => {
    if (!key) return;
    let { treeItems, currentProjectVerifications, currentProjectId } = this.props;
    const projectId = strDelimited(key, "-", { returnIndex: 1 });
    if (!projectId) return;
    const ProjectList = treeItems[0].children;
    const projectIndex = ProjectList.findIndex(item => item.id === projectId);
    if (treeItems[0].children[projectIndex] && treeItems[0].children[projectIndex].children[1] && treeItems[0].children[projectIndex].children[1].children) {
      const dataTypes = treeItems[0].children[projectIndex].children[1].children.map(item => item.dataType);
      if (dataTypes.includes(VERIFICATION_CREATE)) {
        message.error('There are already interface being created.');
        return;
      }
    }
    // children[1].children: verification list
    const name = getDefaultName({ nameList: currentProjectVerifications, defaultKey: 'Interface' });
    treeItems[0].children[projectIndex].children[1].children.push(
      {
        id: name,
        name: name,
        key: `${VERIFICATION_CREATE}-${projectId}`,
        dataType: VERIFICATION_CREATE,
        nodeClass: 'verification-create-node'
      }
    );
    this.props.projectMenu({ treeItems: [...treeItems] });
    let { expandedKeys } = this.props;
    const openVerifications = expandedKeys.find(item => item === `${VERIFICATIONS}-${currentProjectId}`);
    if (!openVerifications) {
      expandedKeys.push(`${VERIFICATIONS}-${currentProjectId}`);
    }
    this.props.expandMenu(expandedKeys);
    this.setState({
      inputVerificationName: name
    });
    this.props.setAutoFocus(true);
  }

  renameProject = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectRenameId: item.id,
      projectInputName: item.name
    }, () => {
      if (this.inputProjectReNameRef) {
        const { input } = this.inputProjectReNameRef;
        input.focus();
      }
    });
  }

  cancelProjectName = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectRenameId: null,
      projectInputName: null,
      projectRenameError: null
    })
  }

  ProjectNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      projectInputName: e.target.value,
      projectRenameError: null
    })
  }

  changeProjectName = (e, name, item) => {
    e.stopPropagation();
    if (name === item.name) {
      this.setState({
        projectRenameId: null,
        projectInputName: null,
        projectRenameError: null
      });
      return;
    }
    const errorMsg = checkNameFormat(name);
    if (errorMsg) {
      this.setState({
        projectRenameError: errorMsg
      });
      return;
    }
    const { projectList } = this.props;
    const _filter = projectList.filter(project => project.id !== item.id);
    const exsitV = _filter.map(item => item.name);
    if (exsitV.includes(name)) {
      this.setState({
        projectRenameError: 'Project:' + name + ' already exists!'
      });
      return;
    }
    this.setState({
      projectRenameId: null,
      projectInputName: null,
      projectRenameError: null
    });
    this.props.SaveProjectName(name, item);
  }

  copyProject = (e, item) => {
    let id = item.id;
    this.setState({
      copyLoading: id
    });
    andesProjectCopy(id).then(res => {
      this.props.UpdateProjectMenu({ openProjectId: res.id });
      this.setState({
        copyLoading: null
      });
    }, error => {
      this.setState({
        copyLoading: null
      });
      message.error(error ? `Failed to copy project: ${error}` : 'Failed to copy project');
    })
  }

  deleteClick = (e, item) => {
    e.stopPropagation();
    this.setState({
      deleteKey: item.key
    })
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { deleteKey } = this.state;
    const deleteConfirm = document.getElementById(deleteKey);
    if (deleteConfirm && deleteConfirm.contains(target)) {
      if (target.classList.contains('andes-delete-button')) {
        this.setState({
          deleteKey: null
        })
        return;
      };
      e.stopPropagation();
      return;
    } else {
      if (deleteKey) {
        this.setState({
          deleteKey: null
        })
      }
    }
  }

  delConfirm = (key, item, type) => {
    const { deleteKey } = this.state;
    return (
      <Popconfirm
        key={key}
        id={item.key}
        overlayClassName='andes-delete-pop-confirm'
        placement="right"
        icon={<ExclamationCircleOutlined className='andes-confirm-icon' />}
        title={<div className='andes-delete-content'>
          {(item.dataType === PROJECT || item.dataType === VERIFICATION || item.dataType === RESULT) ? <span className='andes-delete-title'>
            Cannot be restored after deleting the {type}. Are you sure you want to delete it?
          </span> :
            <span className='andes-delete-title'>
              {`Once the ${type} is deleted, all the interface will be gone.`}
            </span>}
          <div className='andes-delete-confirm'>
            <Button type='primary'
              className='andes-delete-button'
              onClick={(e) => this.props.delItem(e, item.key, item)}
            >OK</Button>
          </div>
        </div>}
        open={deleteKey === item.key}
      >
        <CloseOutlined
          className='aurora-tree-del-icon'
          onClick={(e) => this.deleteClick(e, item)} />
      </Popconfirm>
    );
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  renderTitle = (item) => {
    switch (item.dataType) {
      case PROJECT_CREATE:
        return this.projectCreate(item);
      case VERIFICATION_CREATE:
        return this.verifyCreate(item);
      case VERIFICATION:
        return this.VerificationTitle(item);
      case PROJECTS:
      case PCBs:
      case VERIFICATIONS:
      case TRASH:
        return this.outTitle(item);
      case PROJECT:
        return this.ProjectTitle(item);
      default:
        return this.defaultTitle(item);
    }
  }

  ProjectTitle = (item) => {
    const { projectRenameId, projectInputName, projectRenameError } = this.state;
    const Keys = IconKey[getIconKey(item.dataType)];
    return projectRenameId && projectRenameId === item.id ?
      <span className='project-name-input'>
        <Tooltip
          className='aurora-error-tooltip'
          title={projectRenameError ? projectRenameError : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={projectRenameError ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            value={projectInputName}
            className='project-name'
            onChange={(e) => this.ProjectNameChange(e)}
            ref={(input) => { this.inputProjectReNameRef = input; }}
            onPressEnter={(e) => this.changeProjectName(e, e.target.value, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => this.cancelProjectName(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={(e) => this.changeProjectName(e, projectInputName, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
      :
      <Fragment>
        <Tooltip
          key={item.key}
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          className='andes-tooltip-span'
          overlayClassName='aurora-tooltip'
        >
          <span className='andes-project-title' onDoubleClick={(e) => this.renameProject(e, item)}>{item.name}</span>
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </Fragment>;
  }


  VerificationTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    let { msg } = getVerificationStatus(item.status, item.readyForSim, item);
    return <span>
      <Tooltip
        title={<Fragment><span>{item.name}</span><span> · {msg}</span></Fragment>}
        className='andes-verification-tooltip'
        overlayClassName='aurora-tooltip'
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
      >{item.name}
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </Tooltip>
    </span>
  }

  inputNameChange = (e) => {
    this.setState({
      inputValue: e.target.value
    })
  }

  inputVerificationNameChange = (e) => {
    this.setState({
      inputVerificationName: e.target.value
    })
  }

  projectCreate = (item) => {
    const { deleteCreateProject, createProject } = this.props;
    const { inputValue } = this.state;
    item.name = inputValue;
    return (
      <span className='project-name-input'>
        <Input
          value={inputValue}
          className='project-name'
          onChange={(e) => this.inputNameChange(e)}
          ref={(input) => { this.inputRef = input; }}
          onPressEnter={(e) => createProject(e, item)}
          onClick={(e) => this.inputClick(e)}
          addonAfter={<Fragment>
            <CloseOutlined
              className='aurora-tree-create-icon'
              id="andes-close-project-icon"
              onClick={(e) => deleteCreateProject(e, item)} />
            <CheckOutlined
              className='aurora-tree-create-icon mg-r-8'
              id="andes-check-project-icon"
              onClick={(e) => createProject(e, item)} />
          </Fragment>}
        />
      </span>
    );
  }

  verificationCreate = (value, item) => {
    this.setState({
      andesSelect: undefined,
      inputVerificationName: null
    }, () => {
      this.props.createVerification(value, item)
    })
  }

  verifyCreate = (item) => {
    const { deleteVerificationCreate } = this.props;
    const { inputVerificationName } = this.state;
    return (
      <span className='project-name-input'>
        <Input
          value={inputVerificationName}
          className='project-name'
          onChange={(e) => this.inputVerificationNameChange(e)}
          ref={(input) => { this.inputRef = input; }}
          onPressEnter={(e) => this.verificationCreate(e.target.value, item)}
          onClick={(e) => this.inputClick(e)}
          addonAfter={<Fragment>
            <CloseOutlined
              className='aurora-tree-create-icon'
              onClick={(e) => deleteVerificationCreate(e, item)} />
            <CheckOutlined
              className='aurora-tree-create-icon mg-r-8'
              onClick={() => this.verificationCreate(inputVerificationName, item)} />
          </Fragment>}
        />
      </span>
    );
  }

  defaultTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    if (item.dataType === RESULT) {
      return <span className='result-style'>{item.name}</span>
    }
    if (item.dataType === PCB) {
      return <span>
        <Tooltip
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          overlayClassName='aurora-tooltip'
          className='andes-tooltip-span'
        >{item.name}
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </span>
    }
    return <span>
      <Tooltip
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={item.name}
        className='andes-tooltip-span'
        overlayClassName='aurora-tooltip'
      >{item.name}
      </Tooltip>
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  outTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    return <span>
      {item.name}
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  openProject = (id) => {
    this.props.openProject(id);
  }

  onExpand = (keys) => {
    const { expandedKeys } = this.props;
    const { selectedKeys } = this.props;
    const openNew = keys.filter(key => !expandedKeys.includes(key));

    if (openNew.length) {
      const [key, id] = strDelimited(openNew[0], "-");

      if (key === PROJECT) {
        if (keys && keys.length > 0) {
          keys = keys.filter(item => {
            if (item) {
              const [_key, _id] = strDelimited(item, "-");
              if (!(_key === PROJECT && _id !== id)) {
                return item;
              }
            };
            return false;
          });
        }

        if (selectedKeys.length > 0) {
          this.props.changeTreeSelected([]);
        };

        this.props.clearCurrentProject();
        this.props.changeViewList([]);
        this.props.closeTabFooter();
        this.openProject(id);
      }
    } else {
      const closeNode = expandedKeys.filter(key => !keys.includes(key));

      if (closeNode.length) {
        const [key] = strDelimited(closeNode[0], "-");
        if (key === PROJECT) {
          this.props.changeTreeSelected([]);
          this.props.changeViewList([]);
          this.props.clearCurrentProject(true);
          this.props.closeTabFooter();
        }
      }
    }

    this.props.expandMenu(keys);
  };

  onSelect = (selectedKeys) => {
    if (!selectedKeys.length) return;

    if (selectedKeys.length < this.props.selectedKeys.length) return;
    let key, id, currentIndex = selectedKeys.length - 1;
    [key, id] = strDelimited(selectedKeys[currentIndex], "-");

    if (key === VERIFICATION || key === RESULT || key === PCB) {

      if (selectedKeys.length === 1) {
        this.props.changeTreeSelected([...selectedKeys]);
      } else {
        let selected = selectedKeys.filter((item, index) => index < currentIndex);
        let RIndex = -1;
        for (let i = 0; i < selected.length; i++) {
          const item = selected[i];
          const [keyed] = strDelimited(item, "-");
          if (key === keyed || (key === RESULT && keyed === VERIFICATION) || (keyed === RESULT && key === VERIFICATION)) {
            RIndex = i;
            break;
          }
        }
        if (RIndex > -1) {
          selected[RIndex] = selectedKeys[currentIndex];
        } else {
          selected.push(selectedKeys[currentIndex])
        }

        this.props.changeTreeSelected([...selected]);

      }
    }
    switch (key) {
      case PCB:
        this.props.openPCB(id);
        break;
      case VERIFICATION:
        this.props.openVerification(id);
        break;
      case RESULT:
        this.props.showAndesResult(id);
        break;
      default: break;
    }
  };

  render() {
    const { treeItems } = this.props;
    const { expandedKeys, selectedKeys } = this.props;
    return (
      <Fragment>
        <TreeForm
          treeData={treeItems}
          renderTitle={this.renderTitle}
          onExpand={this.onExpand}
          defaultExpandedKeys={['projects']}
          expandedKeys={expandedKeys}
          onSelect={this.onSelect}
          selectedKeys={selectedKeys}
          autoExpandParent={false}
          defaultExpandParent={false}
        // Expand parent: autoExpandParent || !prevProps && defaultExpandParent (Auto find the parent node)
        />
      </Fragment>
    )
  }
}


const mapState = (state) => {
  const { AndesReducer: { andes, project, simulationReducer } } = state;
  const { treeItems, view, designID, verificationId, viewList, layout, selectedKeys, currentProjectVerifications, currentProjectDesigns, projectList = [], currentProjectId } = project;
  const { simulateKeys, singleVerifyInfo } = simulationReducer;
  const { verificationName } = state.TabMonitorReducer;
  return {
    expandedKeys: andes.expandedKeys,
    treeItems,
    simulateKeys,
    view,
    designID,
    verificationId,
    viewList,
    layout,
    selectedKeys,
    verificationName,
    singleVerifyInfo,
    currentProjectVerifications,
    currentProjectDesigns,
    projectList,
    currentProjectId
  }
}

const mapDispatch = (dispatch) => ({
  expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  UpdateProjectMenu(obj) {
    dispatch(updateProjectMenu(obj))
  },
  openProject(id) {
    dispatch(openProject(id));
  },
  clearCurrentProject(status) {
    dispatch(clearCurrentProject(status));
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  updateTrashMenu() {
    dispatch(updateTrashMenu());
  },
  changeTreeSelected(selectedKeys) {
    dispatch(changeTreeSelected(selectedKeys))
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList))
  },
  projectMenu({ treeItems }) {
    dispatch(projectMenu({ treeItems }));
  }
});

export default connect(mapState, mapDispatch)(AndesTree);