import makeCancelable from "../api/makeCancelable";
import LayoutData from "../data/LayoutData";

function getLayoutDBInfo(designId, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    const getLayoutDBPromise = LayoutData.LoadLayoutDB(designId, onlyCompLayer);
    const cancelable = makeCancelable(getLayoutDBPromise);
    cancelable.promise.then(res => {
      const DesignData = LayoutData.getLayout(designId);
      const pcbInfo = {
        netsList: [...DesignData.mNetManager.mNetList.mVals],
        layers: [...DesignData.mLayerMgr.mMetalLayers]
      };
      resolve(pcbInfo);
    }, error => {
      console.log(error);
      resolve(null)
    });
  })
}

function getMetalCompLayers(metalLayerNames, _LayerManager) {
  let layers = [];
  metalLayerNames.forEach((name, index) => {
    const metalComp = _LayerManager.GetMetalCompLayer(name);
    if (metalComp) {
      if (index < metalLayerNames.length - 1) {
        layers.push(metalComp.GetName());
        layers.push(name)
      } else {
        layers.push(name)
        layers.push(metalComp.GetName());
      }
    } else {
      layers.push(name)
    }
  });
  return layers;
}

export {
  getLayoutDBInfo,
  getMetalCompLayers
}