import {
  UPDATE_CARD_INFO,
  UPDATE_CARD_EXTRACTION_CONFIG,
  OPEN_CARD_CHANNEL,
  CLOSE_CARD_CHANNEL,
  UPDATE_CARD_ERROR_CHECK_LIST
} from './actionTypes';

const defaultState = {
  cardChannelId: null,
  cardInfo: {},
  extraction: {},
  isOpenExtractionPanel: false,
  cardErrorCheck: []
};

export const RockyCardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_CARD_INFO:
      return {
        ...state,
        cardInfo: action.info
      };
    case UPDATE_CARD_EXTRACTION_CONFIG:
      return {
        ...state,
        extraction: action.extraction,
        isOpenExtractionPanel: action.isOpenExtractionPanel !== undefined ? action.isOpenExtractionPanel : state.isOpenExtractionPanel
      }
    case OPEN_CARD_CHANNEL:
      return {
        ...state,
        cardChannelId: action.id
      }
    case CLOSE_CARD_CHANNEL:
      return {
        ...defaultState
      }
    case UPDATE_CARD_ERROR_CHECK_LIST:
      return {
        ...state,
        cardErrorCheck: action.cardErrorCheck
      }
    default: return state;
  }
}