import React, { Component } from "react";
import { connect } from "react-redux";
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Input, Select, Tooltip } from "antd";
import CtleModel from "./CtleModel";
import TxModel from "./TxModel";
import RxModel from "./RxModel";
import RxCpadModel from "./RxCpadModel";
import SubcktNodeSelect from "./components/SubcktNodeSelect";
import {
  drawHelper,
  getDrawer,
  ctleKeyPrefixs,
  getNodeDataList,
  getNodeSelectOption,
  getSubcktByPairInfo,
  packageHspiceConfig,
  getIsShow,
  getTooltipPrefix,
  getModelKey,
  clickBoxPosition,
} from "../../../services/Andes_v2/simulation";
import { PCB_CHANNEL } from "../../../constants/treeConstants";
import { numberCheck } from "../../../services/helper/dataProcess";
import { updateHSPICEConfig, updateHspiceConfigStatus } from "../store/channel/action";
// import SettingPanel from './SettingPanel';
import "./index.css";
import { SERDES_TYPES } from "../../../services/PCBHelper";

const { Option } = Select;

const pageState = {
  modelVisible: {
    txModel: false,
    rxModel: false,
    rxCpadModel: false,
    ctleModel: false,
  },
  clickSignalInfo: {},
  clickSignal: {},
  // For staging termination input value
  editInputInfo: {},
  // settingVisible: false,
  // tx subckt node select list
  txSubckt: {},
  ctleSubckt: {}, // ctleKey -> subcktInfo
};

class SimulationContent extends Component {
  constructor() {
    super();
    this.state = {
      canvasVisible: {
        rxCpad: true,
        ctle: true,
        rx: true
      },
      hspiceConfig: {},
      ...pageState,
    };
  }

  componentDidMount() {
    const { hspiceConfig, signals } = this.props;
    const { drawer, canvas } = getDrawer("andes-v2-hspice-simulation-canvas");
    this.drawer = drawer;
    this.canvas = canvas;
    const { isShowCtle, isShowRxCpad, isShowRx } = getIsShow(hspiceConfig);
    this.drawCanvas({ signals, isShowCtle, isShowRxCpad, isShowRx });
    document.addEventListener("mousedown", this.handleClickOutside, true);

    const _hspiceConfig = packageHspiceConfig(hspiceConfig, PCB_CHANNEL);
    this.setState({
      hspiceConfig: _hspiceConfig,
      canvasVisible: { rxCpad: isShowRxCpad, ctle: isShowCtle, rx: isShowRx },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      signals,
      hspiceConfig,
      channelLoading,
      simulationSolver,
      // libraryStatus
      hspiceConfigStatus
    } = this.props;
    // change channel
    if (
      (channelLoading !== prevProps.channelLoading && !channelLoading) ||
      simulationSolver !== prevProps.simulationSolver ||
      signals.length !== prevProps.signals.length
    ) {
      const { isShowCtle, isShowRxCpad, isShowRx } = getIsShow(hspiceConfig);
      this.drawCanvas({ signals, isShowCtle, isShowRxCpad, isShowRx });
      const _hspiceConfig = packageHspiceConfig(hspiceConfig, PCB_CHANNEL);

      this.setState({
        hspiceConfig: _hspiceConfig,
        canvasVisible: { rxCpad: isShowRxCpad, ctle: isShowCtle, rx: isShowRx },
        ...pageState,
      });
    }

    // if (prevProps.libraryStatus !== libraryStatus) {
    //   this.setState({ hspiceConfig: packageHspiceConfig(hspiceConfig, PCB_CHANNEL) });
    // }

    if (hspiceConfigStatus) {
      this.setState({ hspiceConfig: packageHspiceConfig(hspiceConfig, PCB_CHANNEL) });
      this.props._updateHspiceConfigStatus(false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { nodeVisible } = this.state;
    const PopoverRoot = document.getElementById(nodeVisible);

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || !PopoverRoot.contains(target)) {
      this.setState({ nodeVisible: "" });
    }
  };

  drawCanvas = ({ signals, isShowCtle, isShowRxCpad, isShowRx }) => {
    // height = 400 * signal number
    const height = 400 * signals.length
    drawHelper({
      drawer: this.drawer,
      canvas: this.canvas,
      openModal: this.openModal,
      signals,
      height,
      isShowCtle,
      isShowRxCpad,
      isShowRx,
      width: isShowCtle ? 1200 : 950,
    });
  };

  // according to type and signalIndex open panel，init data
  openModal = (modalType, signalIndex) => {
    const { hspiceConfig: { signals: hspiceSignals } } = this.state;
    const { signals } = this.props;
    const signalName = signals[signalIndex].name;
    const hspiceSignal = hspiceSignals.find((item) => item.signalName === signalName);
    const signal = signals.find((item) => item.name === signalName);
    this.setState({
      modelVisible: {
        ...this.state.modelVisible,
        [modalType]: true,
      },
      clickSignalInfo: hspiceSignal,
      clickSignal: signal
    });
  };

  closeModal = (modelType, model, applyToAll) => {
    const { clickSignalInfo, hspiceConfig } = this.state;
    let _hspiceConfig = hspiceConfig;
    if (applyToAll) {
      _hspiceConfig = hspiceConfig.applyToAllSignals(modelType, model);
    } else {
      _hspiceConfig = hspiceConfig.setModelBySignalName(
        modelType,
        clickSignalInfo.signalName,
        model
      );
    }
    this.setState(
      {
        modelVisible: {
          ...this.state.modelVisible,
          [modelType]: false,
        },
        hspiceConfig: _hspiceConfig,
      },
      () => {
        this.props._updateHSPICEConfig(_hspiceConfig);
      }
    );
  };

  changeCanvasEle = (type, value) => {
    const { hspiceConfig } = this.state;
    // change model type
    let _hspiceConfig = hspiceConfig;
    if (type === "ctle") {
      _hspiceConfig.setModelType("ctleModel", value);
    } else if (type === "rxCpad") {
      _hspiceConfig.setModelType("rxCpadModel", value);
    } else if (type === "rx") {
      _hspiceConfig.setModelType("rxModel", value);
    }
    this.setState({
      canvasVisible: {
        ...this.state.canvasVisible,
        [type]: value,
      },
      hspiceConfig: _hspiceConfig
    }, () => {
      const { signals } = this.props;
      const { canvasVisible } = this.state;
      // height = 400 * signal number
      const height = 400 * signals.length;
      drawHelper({
        drawer: this.drawer,
        canvas: this.canvas,
        openModal: this.openModal,
        signals,
        height,
        width: canvasVisible.ctle ? 1200 : 950,
        isShowCtle: canvasVisible.ctle,
        isShowRxCpad: canvasVisible.rxCpad,
        isShowRx: canvasVisible.rx
      });
      this.props._updateHSPICEConfig(_hspiceConfig);
    });
  };

  changeEditInputInfo = (item) => {
    this.setState({ editInputInfo: { ...item } });
  };

  editInputBlur = (signalName, { key, unit, pin }) => {
    const {
      hspiceConfig,
      editInputInfo: { value },
    } = this.state;
    let _hspiceConfig = null;
    if (!numberCheck(value)) {
      const isCap = key.includes("Cap");
      _hspiceConfig = hspiceConfig.setTerminationValue(
        signalName,
        pin,
        `${value} ${unit}`,
        isCap
      );
    }
    this.setState({
      hspiceConfig: _hspiceConfig || hspiceConfig,
      editInputInfo: {},
    });
  };

  selectNode = ({ node, pin, component }, signalName, modelType) => {
    const { hspiceConfig } = this.state;
    let _hspiceConfig = hspiceConfig;
    if (modelType === "txModel") {
      _hspiceConfig = hspiceConfig.setTxModelNode(signalName, pin, node);
    } else if (modelType === "ctleModel") {
      _hspiceConfig = hspiceConfig.setCtleModelNode(
        signalName,
        pin,
        component,
        node
      );
    }
    this.setState({ hspiceConfig: _hspiceConfig, nodeVisible: "" });
  };

  clearNodeVisible = () => {
    this.setState({ nodeVisible: "" });
  };

  setTxNodeVisible = async (visible, allInfo, txModel = {}) => {
    const { files = [] } = txModel;
    const fileInfo = allInfo.node
      ? allInfo
      : files.length > 0
        ? files[0]
        : null;
    if (visible && fileInfo) {
      // get nodeList
      const subcktInfo = await getSubcktByPairInfo(fileInfo);
      this.setState({ txSubckt: subcktInfo });
    }
    this.setState({ nodeVisible: visible });
  };

  setCtleNodeVisible = async (visible, { allInfo, key }, ctleModel = {}) => {
    const { ctleSubckt } = this.state;
    const { files = [] } = ctleModel;
    const pairIndex = allInfo.pairIndex;
    const spiceType = files.length > 1 ? 'only' : 'share';
    const modelKey = getModelKey({ files, pairIndex, modelKey: allInfo.modelKey, spiceType })
    const fileInfo = allInfo.node ? allInfo : files.find((item) => item.modelKey === modelKey);
    if (visible && fileInfo) {
      const subcktInfo = await getSubcktByPairInfo(fileInfo);
      this.setState({ ctleSubckt: { ...ctleSubckt, [key]: subcktInfo } });
    }
    this.setState({ nodeVisible: visible });
  };

  getNodeList = (signalName, index, ctleDataList) => {
    const {
      txSubckt,
      ctleSubckt,
      hspiceConfig: { signals = [] },
    } = this.state;
    const signalConfig = signals.find((item) => item.signalName === signalName) || {};
    const { txModel = {}, ctleModel = {} } = signalConfig;
    const txNodeList = getNodeSelectOption("txModel", txModel.pairs, txSubckt);
    // ctle
    let ctleNodeLists = {};
    for (const keyPrefix of ctleKeyPrefixs) {
      const key = `${keyPrefix}${index}`;
      let pairs = ctleModel.pairs;
      if ((ctleModel.files || []).length > 1) {
        const ctleData = ctleDataList.find((item) => item.key === key);
        pairs = [ctleData.leftValue, ctleData.rightValue];
      }
      const ctleNodeList = getNodeSelectOption(
        "ctleModel",
        pairs,
        ctleSubckt[key]
      );
      ctleNodeLists[key] = ctleNodeList;
    }
    // rx cpad
    // let rxCpadNodeLists = {};
    // if (rxCpadModel.type === SPICE) {
    //   for (const keyPrefix of rxCpadKeyPrefixs) {
    //     const key = `${keyPrefix}${index}`
    //     const rxCpadNodeList = getNodeSelectOption('rxCpadModel', rxCpadModel.pairs, rxCpadModel);
    //     rxCpadNodeLists[key] = rxCpadNodeList;
    //   }
    // }
    return { txNodeList, ctleNodeLists };
  };

  positionEleRender = (signalName, index) => {
    const {
      canvasVisible,
      editInputInfo,
      hspiceConfig: { signals: hspiceSignals = [], controller, device },
      nodeVisible,
    } = this.state;
    const { components, signals } = this.props;
    const hspiceSignalConfig =
      hspiceSignals.find((item) => item.signalName === signalName) || {};
    const {
      txNodeDataList,
      txNetDataList,
      z0DataList,
      ctleDataList,
      rxModelDataList,
      rxCpadDataList,
    } = getNodeDataList({
      signalName,
      index,
      hspiceSignalConfig,
      signals,
      components,
      device,
      controller,
    });
    const { txNodeList, ctleNodeLists } = this.getNodeList(
      signalName,
      index,
      ctleDataList
    );
    const { rxCpadPrefix, rxPrefix } = getTooltipPrefix(hspiceSignalConfig);

    return (
      <>
        {/* SignalName */}
        <div
          className="andes-v2-hspice-simulation-canvas-signal-name andes-v2-hspice-no-wrap"
          style={{ top: `${index * 400 + 120}px` }}
          title={signalName}
        >
          {signalName}
        </div>
        {/* Tx - node*/}
        {txNodeDataList.map((item) => (
          <div
            className="andes-v2-hspice-simulation-canvas-div"
            style={{ left: item.left, top: item.top }}
            onClick={() => this.changeEditInputInfo(item)}
            key={item.key}
          >
            <SubcktNodeSelect
              id={`simulation-subckt-node-popover-${item.key}`}
              nodeList={txNodeList}
              nodeValue={item.value}
              displayPin={item.pin}
              selectNode={(node) => this.selectNode({ node, pin: item.pin }, signalName, "txModel")}
              visible={nodeVisible === `simulation-subckt-node-popover-${item.key}`}
              clickNode={() =>
                this.setTxNodeVisible(
                  `simulation-subckt-node-popover-${item.key}`,
                  item.allInfo,
                  hspiceSignalConfig.txModel
                )
              }
              closeNodelSelect={() => this.clearNodeVisible()}
            />
          </div>
        ))}
        {/* Tx - net */}
        {txNetDataList.map((item) => (
          <div
            key={item.key}
            className="andes-v2-hspice-simulation-canvas-div-net"
            style={{ left: item.left, top: item.top }}
            title={item.value}
          >
            {item.value}
          </div>
        ))}
        {/* Rx - Model */}
        {canvasVisible.rx ? rxModelDataList.map((item) => (
          <Tooltip
            placement="top"
            title={`${rxPrefix}${item.modelName || item.node}`}
            key={item.key}
            onClick={() => this.openModal("rxModel", index)}
          >
            <div
              className="andes-v2-hspice-simulation-canvas-div"
              key={item.key}
              style={{ left: item.left, top: item.top }}
            >
              {item.modelName || item.node}
            </div>
          </Tooltip>
        )) : null}
        {/* RxCpad */}
        {canvasVisible.rxCpad ? rxCpadDataList.map((item) => (
          <Tooltip
            placement="top"
            title={`${rxCpadPrefix}${item.cpadValue || item.node}`}
            key={item.key}
            onClick={() => this.openModal("rxCpadModel", index)}
          >
            <div
              className="andes-v2-hspice-simulation-canvas-div"
              style={{ left: item.left, top: item.top }}
            >
              {item.cpadValue || item.node}
            </div>
          </Tooltip>
        )) : null}
        {/* Z0 */}
        {z0DataList.map((item) => {
          return (
            <div
              className="spice-pin-value-box andes-v2-hspice-simulation-input"
              style={{ left: item.left, top: item.top }}
              key={item.key}
              onClick={() => this.changeEditInputInfo(item)}
            >
              {editInputInfo.key === item.key || !item.value ? (
                <Input
                  className="andes-v2-hspice-simulation-z0-input"
                  placeholder="Z0"
                  value={editInputInfo.value}
                  onChange={(e) =>
                    this.changeEditInputInfo({
                      ...editInputInfo,
                      value: e.target.value,
                    })
                  }
                  onBlur={() => this.editInputBlur(signalName, item)}
                  onPressEnter={() => this.editInputBlur(signalName, item)}
                  suffix={item.key.includes("Cap") ? "pF" : "Ω"}
                  autoFocus={editInputInfo.key === item.key}
                />
              ) : (
                <span className="spice-pin-value">
                  {item.key.includes("Cap")
                    ? (<>Cap: {item.value} {item.unit}</>)
                    : (<>R: {item.value} {item.unit.replace("Ohm", "Ω")}</>)}
                </span>
              )}
            </div>
          );
        })}
        {/* ctle */}
        {canvasVisible.ctle ? ctleDataList.map((item, index) => (
          <div
            className="andes-v2-hspice-simulation-ctle-item andes-v2-hspice-simulation-ctle-item-zIndex"
            key={item.key}
            style={{ left: item.left, top: item.top }}
          >
            <SubcktNodeSelect
              id={`simulation-subckt-node-popover-${item.key}-left`}
              displayPin={item.leftValue.pin}
              nodeValue={item.leftValue.node}
              nodeList={ctleNodeLists[item.key] || []}
              selectNode={(node) =>
                this.selectNode(
                  {
                    node,
                    pin: item.leftValue.pin,
                    component: item.leftValue.component,
                  },
                  signalName,
                  "ctleModel"
                )
              }
              visible={
                nodeVisible ===
                `simulation-subckt-node-popover-${item.key}-left`
              }
              clickNode={() =>
                this.setCtleNodeVisible(
                  `simulation-subckt-node-popover-${item.key}-left`,
                  { key: item.key, allInfo: item.leftValue },
                  hspiceSignalConfig.ctleModel
                )
              }
              closeNodelSelect={() => this.clearNodeVisible()}
            />
            <SubcktNodeSelect
              id={`simulation-subckt-node-popover-${item.key}-right`}
              displayPin={item.rightValue.pin}
              nodeValue={item.rightValue.node}
              nodeList={ctleNodeLists[item.key] || []}
              selectNode={(node) =>
                this.selectNode(
                  {
                    node,
                    pin: item.rightValue.pin,
                    component: item.rightValue.component,
                  },
                  signalName,
                  "ctleModel"
                )
              }
              visible={
                nodeVisible ===
                `simulation-subckt-node-popover-${item.key}-right`
              }
              clickNode={() =>
                this.setCtleNodeVisible(
                  `simulation-subckt-node-popover-${item.key}-right`,
                  { key: item.key, allInfo: item.rightValue },
                  hspiceSignalConfig.ctleModel
                )
              }
              closeNodelSelect={() => this.clearNodeVisible()}
            />
          </div>
        )) : null}
        {/* Clickable area box */}
        {/* tx/rx */}
        {clickBoxPosition.map(position => {
          if ((!canvasVisible.ctle && position.modalType === 'ctleModel')
            || (!canvasVisible.rxCpad && position.modalType === 'rxCpadModel')
            || (!canvasVisible.rx && position.modalType === 'rxModel')) {
            return null
          }
          return <div
            key={position.key}
            className={`andes-v2-hspice-simulation-canvas-click-box ${position.className}`}
            style={{ left: `${position.left}px`, top: `${position.top + 400 * index}px` }}
            onClick={() => this.openModal(position.modalType, index)}
          >
          </div>
        })}
      </>
    );
  };

  changeComponent = (currType, compName) => {
    const { hspiceConfig } = this.state;
    const { components } = this.props;
    const otherType = currType === "controller" ? "device" : "controller";
    const currComp = components.find((item) => item.name === hspiceConfig[currType]) || {};
    const otherComp = components.find((item) => item.name === hspiceConfig[otherType]) || {};
    const compIndex = components.findIndex((item) => item.name === compName);
    const resComp = components[compIndex] || {};
    // If currently want to replace the result comp is another comp, swap controller and device, then replace txModel, rxModel inside the pin
    let _hspiceConfig = null;
    if (resComp.name === otherComp.name) {
      let controllerComp = resComp,
        deviceComp = currComp;
      if (currType === "device") {
        controllerComp = currComp;
        deviceComp = resComp;
      }

      _hspiceConfig = hspiceConfig.handleCompnentSwap(
        controllerComp,
        deviceComp
      );
    } else {
      // Replace the corresponding txModel/rxModel and ctleModel
      _hspiceConfig = hspiceConfig.handleComponentChange(
        currType,
        resComp
      );
    }

    this.setState({ hspiceConfig: hspiceConfig || _hspiceConfig });
  };

  openSetting = () => {
    this.setState({ settingVisible: true });
  };

  render() {
    const {
      modelVisible = {},
      canvasVisible = {},
      clickSignalInfo,
      hspiceConfig,
      clickSignal,
    } = this.state;
    const { signals, components, libraryStatus } = this.props;
    const { controller, device } = hspiceConfig;
    const _components = components.filter((item) => SERDES_TYPES.includes(item.type));
    return (
      <div className="aurora-setup-border" id="andes-v2-hspice-simulation">
        <span className="out-title-color font-bold">
          HSPICE Simulation
          {/* <Icon
            className="signal-add-icon channel-add-signal-icon"
            type="setting"
            title="Set Config"
            onClick={(e) => this.openSetting(e)}
          /> */}
        </span>
        <div className="andes-v2-hspice-simulation-container">
          {/* title */}
          <div className="andes-v2-hspice-simulation-title-box">
            <div className="andes-v2-hspice-simulation-title">
              Tx Buffer
              <Select
                className="andes-v2-hspice-simulation-component-select"
                onChange={(value) => this.changeComponent("controller", value)}
                value={controller}
              >
                {_components.map((item) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="andes-v2-hspice-simulation-title">
              {canvasVisible.rx ? (
                <>
                  Rx Buffer
                  <Select
                    className="andes-v2-hspice-simulation-component-select"
                    onChange={(value) => this.changeComponent("device", value)}
                    value={device}
                  >
                    {_components.map((item, index) => (
                      <Option key={item.name} value={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                  <CloseOutlined
                    title="Delete Rx Buffer"
                    className="andes-v2-hspice-simulation-icon"
                    onClick={() => this.changeCanvasEle("rx", false)}
                  />
                </>
              ) : (
                <PlusCircleOutlined
                  title="Add Rx Buffer"
                  className="andes-v2-hspice-simulation-icon andes-v2-hspice-simulation-add-icon"
                  onClick={() => this.changeCanvasEle("rx", true)}
                />
              )}
            </div>
            <div className="andes-v2-hspice-simulation-title">
              {canvasVisible.rxCpad ? (
                <>
                  Rx C_Pad
                  <CloseOutlined
                    title="Delete Rx CPad"
                    className="andes-v2-hspice-simulation-icon"
                    onClick={() => this.changeCanvasEle("rxCpad", false)} />
                </>
              ) : (
                <PlusCircleOutlined
                  title="Add Rx Cpad"
                  className="andes-v2-hspice-simulation-icon andes-v2-hspice-simulation-add-icon"
                  onClick={() => this.changeCanvasEle("rxCpad", true)} />
              )}
            </div>
            <div className="andes-v2-hspice-simulation-title">Termination</div>
            <div className="andes-v2-hspice-simulation-title">
              {canvasVisible.ctle ? (
                <>
                  CTLE
                  <CloseOutlined
                    title="Delete CTLE"
                    className="andes-v2-hspice-simulation-icon"
                    onClick={() => this.changeCanvasEle("ctle", false)} />
                </>
              ) : (
                <PlusCircleOutlined
                  title="Add CTLE"
                  className="andes-v2-hspice-simulation-icon andes-v2-hspice-simulation-add-icon"
                  onClick={() => this.changeCanvasEle("ctle", true)} />
              )}
            </div>
          </div>
          {/* canvas */}
          <div className="andes-v2-hspice-simulation-canvas-box">
            {signals.map((signal, index) => {
              const signalName = signal.name;
              return (
                <div key={`hspice-${signalName}-${index}`}>
                  {this.positionEleRender(signalName, index)}
                </div>
              );
            })}
            <canvas
              id="andes-v2-hspice-simulation-canvas"
              width="1200"
              height="400"
            ></canvas>
            {modelVisible.txModel && (
              <TxModel
                closeModal={(model, applyAll) => this.closeModal("txModel", model, applyAll)}
                model={clickSignalInfo.txModel}
                libraryStatus={libraryStatus}
                signalName={clickSignalInfo.signalName}
              />
            )}
            {modelVisible.rxModel && (
              <RxModel
                closeModal={(model, applyAll) => this.closeModal("rxModel", model, applyAll)}
                model={clickSignalInfo.rxModel}
                libraryStatus={libraryStatus}
              />
            )}
            {modelVisible.ctleModel && (
              <CtleModel
                closeModal={(model, applyAll) => this.closeModal("ctleModel", model, applyAll)}
                model={clickSignalInfo.ctleModel}
                signal={clickSignal}
                controller={controller}
                device={device}
                libraryStatus={libraryStatus}
              />
            )}
            {modelVisible.rxCpadModel && (
              <RxCpadModel
                closeModal={(model, applyAll) => this.closeModal("rxCpadModel", model, applyAll)}
                model={clickSignalInfo.rxCpadModel}
                libraryStatus={libraryStatus}
                signalName={clickSignalInfo.signalName}
              />
            )}
            {/* {settingVisible && <SettingPanel
              hspiceConfig={hspiceConfig}
              closeModal={this.saveSettingInfo}
            />} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  const {
    channel: {
      channelInfo: { content = {}, hspiceConfig, type },
      channelId,
      channelLoading,
      hspiceConfigStatus
    },
    library: { libraryStatus }
  } = state.AndesV2Reducer;
  const {
    components = [],
    signals = [],
    simulationSolver,
  } = content;

  return {
    components,
    signals,
    hspiceConfig,
    channelId,
    serdesType: type,
    libraryStatus,
    channelLoading,
    simulationSolver,
    hspiceConfigStatus
  };
};

const mapDispatch = (dispatch) => ({
  _updateHSPICEConfig(hspiceConfig) {
    dispatch(updateHSPICEConfig(hspiceConfig));
  },
  _updateHspiceConfigStatus(status) {
    dispatch(updateHspiceConfigStatus(status));
  },
});

export default connect(
  mapState,
  mapDispatch
)(SimulationContent);
