class CanvasDrawer {
  constructor(canvas, startPoint, padding) {
    this.canvas = canvas;
    this.context = canvas.getContext('2d');
    this.padding = padding || { left: 10, top: 1.5 };

    // init top left corner starting point
    this.startPoint = startPoint || { x: 0, y: 0 };
    this.devicePixelRatio = window.devicePixelRatio || 1;
    this.adjustForHighDPI();
  }

  setStartPoint({ x, y }) {
    this.startPoint = { x, y };
  }

  clearCanvas() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  // Adapted to high resolution screens
  adjustForHighDPI() {
    const width = this.canvas.width;
    const height = this.canvas.height;

    this.canvas.width = width * this.devicePixelRatio;
    this.canvas.height = height * this.devicePixelRatio;
    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height + 'px';

    // Zoom plot context
    this.context.scale(this.devicePixelRatio, this.devicePixelRatio);
  }

  //Resize the canvas
  adjustCanvasSize(width, height) {
    if (width) {
      this.canvas.width = width * this.devicePixelRatio;
      this.canvas.style.width = width + 'px';
    }
    this.canvas.height = height * this.devicePixelRatio;
    this.canvas.style.height = height + 'px';
    this.context.scale(this.devicePixelRatio, this.devicePixelRatio);
  }

  drawText({ x, y, text, font = '16px Arial', fillStyle = 'black', textAlign = 'center', textBaseline = 'middle' }) {
    this.context.font = font; // set font size and style
    this.context.fillStyle = fillStyle; // fill color
    this.context.textAlign = textAlign; // Text alignment
    this.context.textBaseline = textBaseline; // Text baseline alignment

    this.context.fillText(text, x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y);
  }

  drawLine({ x1, y1, x2, y2, color = '#005ab3', lineWidth = 1.5 }) {
    this.context.beginPath();
    this.context.moveTo(x1 + this.padding.left + this.startPoint.x, y1 + this.padding.top + this.startPoint.y);
    this.context.lineTo(x2 + this.padding.left + this.startPoint.x, y2 + this.padding.top + this.startPoint.y);
    this.context.strokeStyle = color;
    this.context.lineWidth = lineWidth / this.devicePixelRatio;;
    this.context.stroke();
    this.context.closePath();
  }

  drawTriangle({ x1, y1, x2, y2, x3, y3, color = 'black', fillColor = null, lineWidth = 1.5 }) {
    this.context.beginPath();
    this.context.moveTo(x1 + this.padding.left + this.startPoint.x, y1 + this.padding.top + this.startPoint.y);
    this.context.lineTo(x2 + this.padding.left + this.startPoint.x, y2 + this.padding.top + this.startPoint.y);
    this.context.lineTo(x3 + this.padding.left + this.startPoint.x, y3 + this.padding.top + this.startPoint.y);
    this.context.closePath();

    if (fillColor) {
      this.context.fillStyle = fillColor;
      this.context.fill();
    }

    this.context.strokeStyle = color;
    this.context.lineWidth = lineWidth / this.devicePixelRatio;;
    this.context.stroke();
  }

  //  x,y is the top-left coordinate
  drawRectangle({ x, y, width, height, color = 'transparent', fillColor = '', lineWidth = 1.5, radius = 2 }) {
    this.context.beginPath();
    this.context.moveTo(x + this.padding.left + this.startPoint.x + radius, y + this.padding.top + this.startPoint.y);

    this.context.arcTo(x + this.padding.left + this.startPoint.x + width, y + this.padding.top + this.startPoint.y, x + this.padding.left + this.startPoint.x + width, y + this.padding.top + this.startPoint.y + height, radius);   // 右上角圆角
    this.context.arcTo(x + this.padding.left + this.startPoint.x + width, y + this.padding.top + this.startPoint.y + height, x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y + height, radius);  // 右下角圆角
    this.context.arcTo(x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y + height, x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y, radius);                   // 左下角圆角
    this.context.arcTo(x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y, x + this.padding.left + this.startPoint.x + width, y + this.padding.top + this.startPoint.y, radius);                    // 左上角圆角

    if (fillColor) {
      this.context.fillStyle = fillColor;
      this.context.fill();
    }

    this.context.strokeStyle = color;
    this.context.lineWidth = lineWidth / this.devicePixelRatio;
    this.context.stroke();
    this.context.closePath();
  }

  drawCircle({ x, y, radius = 5, color = '#bfbfbf', fillColor = null }) {
    this.context.beginPath();
    this.context.arc(x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y, radius, 0, 2 * Math.PI);

    if (fillColor) {
      this.context.fillStyle = fillColor;
      this.context.fill();
    }

    this.context.strokeStyle = color;
    this.context.stroke();
    this.context.closePath();
  }

  /**
     * Draw an unsealed semi-arc
     * @param {number} x x-coordinate of the center of the circle
     * @param {number} y y-coordinate of the center of the circle
     * @param {number} radius radius of the circle
     */
  drawArc({ x, y, radius = 6, startAngle = Math.PI, endAngle = 0, strokeColor = '#005ab3', lineWidth = 1.5 }) {
    this.context.beginPath();
    this.context.arc(x + this.padding.left + this.startPoint.x, y + this.padding.top + this.startPoint.y, radius, startAngle, endAngle);
    if (strokeColor) {
      this.context.strokeStyle = strokeColor;
      this.context.lineWidth = lineWidth;
      this.context.stroke();
    }
  }
}

function getDrawer(id, startPoint, padding) {
  const canvas = document.getElementById(id);
  const drawer = new CanvasDrawer(canvas, startPoint, padding);
  return { drawer, canvas };
}

function drawModel({ drawer, rectInfo, isShowCtle, isShowRxCpad, isShowRx }) {
  // Tx triangle, 3
  const txPadding = { left: 30, top: 25 };
  const txGap = { left: 0, top: 5 }
  const txTriangles = [
    { x1: 1, y1: 1, x2: 47, y2: 31, x3: 1, y3: 61 },
    { x1: 1, y1: 61, x2: 47, y2: 91, x3: 1, y3: 121 },
    { x1: 1, y1: 121, x2: 47, y2: 151, x3: 1, y3: 181 }
  ];
  // The length of the horizontal segment connecting the Tx-channel
  const lineLength = 222;

  // termination
  const zPadding = { left: 810, top: 50 };
  const zGap = { left: 0, top: 40 }
  const zLineList = [
    { x1: 0, y1: 0, x2: 47, y2: 31, x3: 0, y3: 61 },
    { x1: 0, y1: 61, x2: 47, y2: 91, x3: 0, y3: 121 },
    { x1: 0, y1: 121, x2: 47, y2: 151, x3: 0, y3: 181 }
  ]
  const lineInfoChannelToZ = {
    hLineLength: 15,
    vLineLength: 60,
    lineNumber: 3
  }
  const zhline = 15

  for (let i in txTriangles) {
    const info = txTriangles[i];
    drawer.drawTriangle({
      x1: info.x1 + txPadding.left + txGap.left * i,
      y1: info.y1 + txPadding.top + txGap.top * i,
      x2: info.x2 + txPadding.left + txGap.left * i,
      y2: info.y2 + txPadding.top + txGap.top * i,
      x3: info.x3 + txPadding.left + txGap.left * i,
      y3: info.y3 + txPadding.top + txGap.top * i
    });

    // connect Tx - channel
    drawer.drawLine({
      x1: info.x2 + txPadding.left + txGap.left * i,
      y1: info.y2 + txPadding.top + txGap.top * i,
      x2: info.x2 + txPadding.left + txGap.left * i + lineLength,
      y2: info.y2 + txPadding.top + txGap.top * i
    });
  }

  // connnect Channel - Ctle
  const lineInfoChannelToCtle = {
    lineLength: 480,
    lineNumber: 3,
    lineGap: 100
  }
  const startNode = { x: 450, y: 1 + 20 }
  for (let i = 0; i < lineInfoChannelToCtle.lineNumber; i++) {

    // left horizontal line
    if (i === 0) {
      drawer.drawLine({
        x1: startNode.x,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i,
        x2: startNode.x + lineInfoChannelToCtle.lineLength,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i,
      })
    } else {
      drawer.drawLine({
        x1: startNode.x,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i,
        x2: zLineList[0].x2 + zPadding.left - 1 + zhline - 10 - 6,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i,
      })
      // arc
      drawer.drawArc({ x: zLineList[0].x2 + zPadding.left - 1 + zhline - 10, y: startNode.y + lineInfoChannelToCtle.lineGap * i });
      // Right horizontal line
      drawer.drawLine({
        x1: zLineList[0].x2 + zPadding.left - 1 + zhline - 10 + 6,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i,
        x2: startNode.x + lineInfoChannelToCtle.lineLength,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i,
      })
    }
  }

  // Rx
  const rxPadding = { left: 530, top: 50 };
  const rxGap = { left: 80, top: 40 }
  const rxTriangles = [
    { x1: 1, y1: 1, x2: 47, y2: 31, x3: 1, y3: 61 },
    { x1: 1, y1: 61, x2: 47, y2: 91, x3: 1, y3: 121 },
    { x1: 1, y1: 121, x2: 47, y2: 151, x3: 1, y3: 181 }

  ];
  const rxCpadPadding = { left: rxPadding.left + rxGap.left + 50, top: 0 };
  // connect Rx - Channel
  // horizon ——
  // vertical |
  const lineInfoChannelToRx = {
    hLineLength: 90,
    vLineLength: 60,
    lineNumber: 3
  }

  const lineInfoChannelToCPad = {
    hLineLength: 30,
    vLineLength: 40,
    // Vertical lines connecting triangles
    vLineLength2: 20,
    lineNumber: 3,
    hLineGap: 5
  }

  for (let i in rxTriangles) {
    const info = rxTriangles[i];
    if (isShowRx) {
      drawer.drawTriangle({
        x1: info.x1 + rxPadding.left + rxGap.left,
        y1: info.y1 + rxPadding.top + rxGap.top * i,
        x2: info.x2 + rxPadding.left + rxGap.left,
        y2: info.y2 + rxPadding.top + rxGap.top * i,
        x3: info.x3 + rxPadding.left + rxGap.left,
        y3: info.y3 + rxPadding.top + rxGap.top * i
      });

      // connect line - Rx
      // horizontal line
      drawer.drawLine({
        x1: info.x2 + rxPadding.left + rxGap.left - 46,
        y1: info.y2 + rxPadding.top + rxGap.top * i,
        x2: info.x2 + rxPadding.left + rxGap.left - 46 - lineInfoChannelToRx.hLineLength,
        y2: info.y2 + rxPadding.top + rxGap.top * i
      });
      // vertical line
      drawer.drawLine({
        x1: info.x2 + rxPadding.left + rxGap.left - 46 - lineInfoChannelToRx.hLineLength,
        y1: info.y2 + rxPadding.top + rxGap.top * i,
        x2: info.x2 + rxPadding.left + rxGap.left - 46 - lineInfoChannelToRx.hLineLength,
        y2: info.y2 + rxPadding.top + rxGap.top * i - lineInfoChannelToRx.vLineLength
      });
    }

    // draw RxCpad
    if (isShowRxCpad) {
      // channel to ctle Vertical connection line
      drawer.drawLine({
        x1: info.x2 + rxCpadPadding.left,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i,
        x2: info.x2 + rxCpadPadding.left,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i + lineInfoChannelToCPad.vLineLength
      });
      // Two horizontal connecting lines
      drawer.drawLine({
        x1: info.x2 + rxCpadPadding.left - lineInfoChannelToCPad.hLineLength / 2,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i + lineInfoChannelToCPad.vLineLength,
        x2: info.x2 + rxCpadPadding.left + lineInfoChannelToCPad.hLineLength / 2,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i + lineInfoChannelToCPad.vLineLength
      });
      drawer.drawLine({
        x1: info.x2 + rxCpadPadding.left - lineInfoChannelToCPad.hLineLength / 2,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap,
        x2: info.x2 + rxCpadPadding.left + lineInfoChannelToCPad.hLineLength / 2,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap
      });
      // Vertical lines connecting triangles
      drawer.drawLine({
        x1: info.x2 + rxCpadPadding.left,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap,
        x2: info.x2 + rxCpadPadding.left,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap + lineInfoChannelToCPad.vLineLength2
      });
      drawer.drawTriangle({
        x1: info.x2 + rxCpadPadding.left - 8,
        y1: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap + lineInfoChannelToCPad.vLineLength2,
        x2: info.x2 + rxCpadPadding.left + 8,
        y2: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap + lineInfoChannelToCPad.vLineLength2,
        x3: info.x2 + rxCpadPadding.left,
        y3: startNode.y + lineInfoChannelToCtle.lineGap * i
          + lineInfoChannelToCPad.vLineLength + lineInfoChannelToCPad.hLineGap + lineInfoChannelToCPad.vLineLength2 + 8,
        color: '#005ab3'
      });
    }
  }

  // termination
  for (let i in zLineList) {
    const info = zLineList[i];
    // Resistance radian
    const startPoint = {
      x: info.x2 + zPadding.left + zGap.left * i - 46,
      y: info.y2 + zPadding.top + zGap.top * i
    }

    const pointGap = { x: 5, y: 7 }
    for (let j = 1; j < 6; j++) {
      let x1 = startPoint.x + j * pointGap.x, x2 = startPoint.x + (j + 1) * pointGap.x;
      const y1 = startPoint.y + pointGap.y, y2 = startPoint.y - pointGap.y

      if (j === 1) {
        drawer.drawLine({ x1: startPoint.x, y1: startPoint.y, x2: x1, y2 });
      } else if (j === 5) {
        drawer.drawLine({ x1: x2, y1, x2: x2 + pointGap.x, y2: startPoint.y });
        // Horizontal line segments
        drawer.drawLine({ x1: x2 + pointGap.x + zhline, y1: startPoint.y, x2: x2 + pointGap.x, y2: startPoint.y });
      }
      if (j % 2 !== 0) {
        x1 = startPoint.x + (j + 1) * pointGap.x;
        x2 = startPoint.x + j * pointGap.x;
      }
      drawer.drawLine({ x1, y1, x2, y2 });
    }

    // line - Rx
    drawer.drawLine({
      x1: info.x2 + zPadding.left + zGap.left * i - 46,
      y1: info.y2 + zPadding.top + zGap.top * i,
      x2: info.x2 + zPadding.left + zGap.left * i - 46 - lineInfoChannelToZ.hLineLength,
      y2: info.y2 + zPadding.top + zGap.top * i
    });

    drawer.drawLine({
      x1: info.x2 + zPadding.left + zGap.left * i - 46 - lineInfoChannelToZ.hLineLength,
      y1: info.y2 + zPadding.top + zGap.top * i,
      x2: info.x2 + zPadding.left + zGap.left * i - 46 - lineInfoChannelToZ.hLineLength,
      y2: info.y2 + zPadding.top + zGap.top * i - lineInfoChannelToZ.vLineLength
    });

    // draw ctle right，To modify the origin position
    const lineInfoCtle = {
      lineLength: 40,
      lineNumber: 3,
      lineGap: 100
    }
    const ctleStartNode = { x: isShowCtle ? 1130 : 1130 - 200 - 40 - 1, y: 1 + 20 }
    for (let i = 0; i < lineInfoCtle.lineNumber; i++) {
      if (isShowCtle) {
        drawer.drawLine({
          x1: ctleStartNode.x,
          y1: ctleStartNode.y + lineInfoCtle.lineGap * i,
          x2: ctleStartNode.x + lineInfoCtle.lineLength,
          y2: ctleStartNode.y + lineInfoCtle.lineGap * i
        })
      }
      // draw Circle
      drawer.drawCircle({
        x: ctleStartNode.x + lineInfoCtle.lineLength,
        y: ctleStartNode.y + lineInfoCtle.lineGap * i,
        radius: 5,
        color: '#f91414',
        fillColor: '#f91414'
      })
    }
  }

  // termination，The right segment is grounded
  const z_h_LineLength = 30, z_v_LineLength1 = 350, z_v_LineLength2 = 20;

  const z_h_Gap = 5;
  const v_x1 = zLineList[0].x2 + zPadding.left - 1 + zhline - 10,
    v_x2 = zLineList[0].x2 + zPadding.left - 1 + zhline - 10,
    v_y1 = zLineList[0].y2 + zPadding.top,
    v_y2 = z_v_LineLength1;
  // vertical 1
  drawer.drawLine({ x1: v_x1, y1: v_y1, x2: v_x2, y2: v_y2 })
  // horizontal 1
  drawer.drawLine({
    x1: v_x1 - z_h_LineLength / 2,
    y1: v_y2,
    x2: v_x2 + z_h_LineLength / 2,
    y2: v_y2
  })
  // horizontal 2
  drawer.drawLine({
    x1: v_x1 - z_h_LineLength / 2,
    y1: v_y2 + z_h_Gap,
    x2: v_x1 + z_h_LineLength / 2,
    y2: v_y2 + z_h_Gap,
  })
  // vertical 2
  drawer.drawLine({
    x1: v_x2,
    y1: v_y2 + z_h_Gap,
    x2: v_x2,
    y2: v_y2 + z_h_Gap + z_v_LineLength2
  })
  drawer.drawTriangle({
    x1: v_x2 - 8,
    y1: v_y2 + 25,
    x2: v_x2 + 8,
    y2: v_y2 + 25,
    x3: v_x2,
    y3: v_y2 + 33,
    color: '#005ab3'
  })
}

function drawHelper({ drawer, canvas, openModal = null, signals, width, height, isShowCtle, isShowRxCpad, isShowRx }) {
  drawer.clearCanvas();
  drawer.adjustCanvasSize(width, height)

  // TX,RX,RxCapd,CTLE container 
  const rectInfo = {
    tx: { x: 10, y: 20, width: 160, height: 200 },
    // signal: { x: 290, y: 100, width: 130, height: 40, color: "#d4d4d4", fillColor: '#fff' },
    channel: { x: 200, y: 0, width: 150, height: 240, color: 'black', fillColor: '#f2f2f2' },
    rx: { x: 480, y: 40, width: 160, height: 285 },
    rxCpad: { x: 660, y: 40, width: 55, height: 285 },
    // z0: { x: 770, y: 40, width: 80, height: 285 },
    ctle: { x: 910, y: 0, width: 150, height: 240, color: 'black' }
  }

  if (!signals || signals.length === 0) {
    return;
  }

  // draw Channel
  // height = 240 + 400 * signal number;
  const info = { x: 300, y: 0, width: 150, height: 240 + 400 * (signals.length - 1), color: 'black' }
  drawer.drawRectangle({
    x: info.x,
    y: info.y,
    width: info.width,
    height: info.height,
    color: info.color,
    fillColor: info.fillColor
  });

  for (const i in signals) {
    drawer.setStartPoint({ x: 0, y: 400 * i });
    drawModel({ drawer, rectInfo, isShowCtle, isShowRxCpad, isShowRx });
  }

  drawer.setStartPoint({ x: 0, y: 0 });
}

function drawTxModelHelper({ drawer }) {
  const rectInfo = {
    tx: { x: 0, y: 0, width: 140, height: 180 },
    channel: { x: 420, y: 0, width: 140, height: 180, color: 'black' },
  }
  for (const i of Object.keys(rectInfo)) {
    drawer.drawRectangle({
      x: rectInfo[i].x,
      y: rectInfo[i].y,
      width: rectInfo[i].width,
      height: rectInfo[i].height,
      color: rectInfo[i].color,
      fillColor: rectInfo[i].fillColor
    })
  }

  const txPadding = { left: 16, top: 10 };
  const txGap = { left: 0, top: 8 }
  const txTriangles = [
    { x1: 0, y1: 0, x2: 32, y2: 24, x3: 0, y3: 48 },
    { x1: 0, y1: 48, x2: 32, y2: 72, x3: 0, y3: 96 },
    { x1: 0, y1: 96, x2: 32, y2: 120, x3: 0, y3: 144 }
  ];

  const lineLength = 372
  for (let i in txTriangles) {
    const info = txTriangles[i];
    drawer.drawTriangle({
      x1: info.x1 + txPadding.left + txGap.left * i,
      y1: info.y1 + txPadding.top + txGap.top * i,
      x2: info.x2 + txPadding.left + txGap.left * i,
      y2: info.y2 + txPadding.top + txGap.top * i,
      x3: info.x3 + txPadding.left + txGap.left * i,
      y3: info.y3 + txPadding.top + txGap.top * i
    });

    // connect Tx - channel
    drawer.drawLine({
      x1: info.x2 + txPadding.left + txGap.left * i,
      y1: info.y2 + txPadding.top + txGap.top * i,
      x2: info.x2 + txPadding.left + txGap.left * i + lineLength,
      y2: info.y2 + txPadding.top + txGap.top * i
    });
  }
}

function drawRxCpadModelHelper({ drawer }) {
  const rectInfo = {
    channel: { x: 0, y: 0, width: 140, height: 210, color: 'black' }
  }
  for (const i of Object.keys(rectInfo)) {
    drawer.drawRectangle({
      x: rectInfo[i].x,
      y: rectInfo[i].y,
      width: rectInfo[i].width,
      height: rectInfo[i].height,
      color: rectInfo[i].color
    })
  }

  const lineLength = 372
  const lineStartPoint = { x: 140, y: 24 }
  const line_v_gap = 80;
  const triangleX = 350;
  const v_line1 = 25, v_line2 = 20, h_line = 24;
  const triangle_height = 8, triangle_h_width = 14, triangle_h_gap = 5;
  for (let i = 0; i < 3; i++) {
    // connect channel - rxCapd
    drawer.drawLine({
      x1: lineStartPoint.x,
      y1: lineStartPoint.y + line_v_gap * i,
      x2: lineStartPoint.x + lineLength,
      y2: lineStartPoint.y + line_v_gap * i
    });
    // right circle
    drawer.drawCircle({
      x: lineStartPoint.x + lineLength,
      y: lineStartPoint.y + line_v_gap * i,
      radius: 5,
      color: '#f91414',
      fillColor: '#f91414'
    })
    // vertical 1
    drawer.drawLine({
      x1: triangleX,
      y1: lineStartPoint.y + line_v_gap * i,
      x2: triangleX,
      y2: lineStartPoint.y + line_v_gap * i + v_line1
    });
    // Horizontal 1
    drawer.drawLine({
      x1: triangleX + h_line / 2,
      y1: lineStartPoint.y + line_v_gap * i + v_line1,
      x2: triangleX - h_line / 2,
      y2: lineStartPoint.y + line_v_gap * i + v_line1
    });
    // Horizontal 2
    drawer.drawLine({
      x1: triangleX + h_line / 2,
      y1: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap,
      x2: triangleX - h_line / 2,
      y2: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap
    });
    // vertical 2
    drawer.drawLine({
      x1: triangleX,
      y1: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap,
      x2: triangleX,
      y2: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap + v_line2
    });
    drawer.drawTriangle({
      x1: triangleX - triangle_h_width / 2,
      y1: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap + v_line2,
      x2: triangleX + triangle_h_width / 2,
      y2: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap + v_line2,
      x3: triangleX,
      y3: lineStartPoint.y + line_v_gap * i + v_line1 + triangle_h_gap + v_line2 + triangle_height,
      color: '#005ab3'
    });
  }
}

export {
  getDrawer,
  drawHelper,
  drawTxModelHelper,
  drawRxCpadModelHelper
}