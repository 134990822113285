import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, CloseOutlined, DashOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';
import { getPanelMaxHeight, getPanelMaxWidth } from '@/services/helper/panelSizeHelper';
import { getPkgDisplayPortList, updatePkgPinPort, getPkgNetDisplay, getPortIndexList } from '../../../services/helper/packageModelHelper';
import { getPinHeight } from '@/services/helper/packageHelper';
import { ANDES_V2 } from '../../../constants/pageType';
import { CPHY } from '../../../services/PCBHelper/constants';
import './index.css';

class SignalPinsPortSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editNode: null,
      maxWidth: 600,
      maxHeight: 600,
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 360),
      maxWidth: getPanelMaxWidth(offset, 830)
    })
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const PopoverRoot = document.getElementsByClassName('connection-model-port-select-Popover')[0];

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || (PopoverRoot && !PopoverRoot.contains(target))) {
      this.closeNodesSelection();
    }
  }

  selectPinPort = ({ port, pin, type, net, currentPortInfo, modelKey, component, subckt }) => {
    //type -> pin type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
    const { portsObj, pinList, pairs, fromSignal, determineComp, termination = {}, modelType, product, models } = this.props;
    const libraryId = currentPortInfo.libraryId;
    //find current libraryId ports
    const fileObj = portsObj[libraryId] ? portsObj[libraryId] : [];
    const ports = fileObj.length && fileObj[0] ? fileObj[0].ports || [] : [];
    if (ports.length === 0) {
      return;
    }
    const { _pinList, _pairs, newModel } = updatePkgPinPort({
      port,
      modelId: libraryId,
      modelKey,
      pin,
      type,
      pinList,
      pairs: type === 'pinTermination' ? termination.pairs : pairs,
      net,
      determineComp,
      component,
      subckt,
      modelType,
      product,
      files: type === 'pinTermination' ? termination.files : models
    })
    this.props._updatePorts({
      pinList: _pinList,
      pairs: _pairs,
      type,
      newModel
    }, true, fromSignal);
    this.setState({
      searchValue: port
    })
    this.closeNodesSelection();
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: "",
      searchValue: ""
    })
  }

  openSelectNode = ({ pin, type }) => {
    this.setState({
      editNode: `${pin}::${type}`
    })
  }

  deleteSelectedPort = (e, pin, type, net, component) => {
    e && e.stopPropagation();
    const { pinList, pairs, fromSignal, determineComp } = this.props;
    const { _pinList, _pairs } = updatePkgPinPort({
      pin,
      type,
      pinList,
      pairs,
      net,
      port: "",
      modelId: "",
      modelKey: "",
      determineComp,
      component
    })
    this.props._updatePorts({
      pinList: _pinList,
      pairs: _pairs,
      type
    }, true, fromSignal);
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  render = () => {
    const { signalInfo = [], signal, signalKey, modelType, isMultiInfo, differentDisplay, rank, product, topology, serdesType } = this.props;
    const { topNet, bottomNet } = getPkgNetDisplay(signalInfo);
    const className = differentDisplay ? "single-pin-port-content" : ""
    const portClassName = isMultiInfo ? 'pkg-multi-model-port-pins' : '';

    let style = {}, pkgClassName = '';
    if (rank && rank.number && modelType === "Package" && signalInfo && signalInfo.length) {
      let height = getPinHeight(signalInfo[0]) - 30
      if (height > 100) {
        style = { height };
        pkgClassName = 'pkg-model-select-ports-item-flex'
      }
    }

    if (product === ANDES_V2 && modelType === "DIE" && topology === 'parallel') {
      pkgClassName = 'pkg-model-select-ports-main-fit-content';
    }

    return <div className={`pkg-model-select-ports-main ${pkgClassName}`} key={signalKey} style={{ ...style }}>
      <div className={`pkg-model-port-pins ${portClassName}`}>
        <div
          className={`pkg-model-long-line-content ${className}`}>
          {signalInfo.map((item, index) => (
            <div key={index} className='pkg-pin-long-line-item'>
              <div className='pkg-pin-long-line'></div>
            </div>))}
        </div>
        {this.getSignalPinLeftRender({ signalInfo, className, isMultiInfo, rank })}
        <div className={`pkg-model-port-signal-main ${serdesType === CPHY ? "pkg-model-port-signal-main-cphy" : ""}`}>
          {!differentDisplay && topNet ? <div className="pkg-model-port-net">( + ) {topNet}</div> : null}
          <div className="pkg-model-port-signal">{signal}</div>
          {!differentDisplay && bottomNet ? <div className="pkg-model-port-net">( - ) {bottomNet}</div> : null}
          {differentDisplay ? <div className="pkg-model-port-net">{bottomNet}</div> : null}
        </div>
        {product === ANDES_V2 && modelType === "DIE" && topology === 'parallel' ? null : this.getSignalPinRightRender({ signalInfo, className, isMultiInfo })}
        {product === ANDES_V2 && modelType === "DIE" && topology !== 'parallel' ? this.getSignalPinTerminationRender({ signalInfo, className, isMultiInfo }) : null}
      </div>
    </div >
  }

  portPopover = ({ pinItem, type, placement, net, pinType, placeholder }) => {
    const cssId = `${pinItem.pin}::${type}`;
    const { editNode } = this.state;
    if (editNode === cssId) {
      return <Popover
        overlayClassName='connection-model-port-select-Popover'
        content={this.nodeSelectRender({ pin: pinItem.pin, type, pinPort: pinItem.pinValue, pinItem, net, pinType, component: pinItem.component })}
        title=""
        trigger="click"
        open={true}
        placement={placement ? placement : "top"}
      >{this.connectorPinPortInput({
        pin: pinItem.pin,
        pinPort: pinItem.pinValue,
        type,
        cssId,
        libraryId: pinItem.pinLibraryId,
        pinModelKey: pinItem.pinModelKey,
        placeholder
      })}</Popover>
    }

    if (pinItem.pinValue) {
      return (
        <div
          className='pkg-model-pin-input-div'
          id={cssId}
          title={pinItem.pinValue}
          onClick={() => this.openSelectNode({
            pin: pinItem.pin,
            type
          })}
        >
          <div className='connection-pin-input-pin-port'>
            <span className='connection-pin-input-pin-port-text'>
              {pinItem.pinValue}
            </span>
          </div>
          <CloseCircleFilled
            className='pkg-model-pin-node-delete-icon'
            onClick={(e) => { this.deleteSelectedPort(e, pinItem.pin, type, net, pinItem.component) }} />
        </div>
      );
    }

    return this.connectorPinPortInput({
      pin: pinItem.pin,
      pinPort: pinItem.pinValue,
      type,
      cssId,
      placeholder
    })
  }

  connectorPinPortInput = ({
    pin,
    pinPort,
    type,
    cssId,
    placeholder = "Port"
  }) => {
    return <Input
      className='pkg-model-pin-input'
      placeholder={placeholder}
      value={pinPort}
      id={cssId}
      onClick={() => this.openSelectNode({ pin, type })}
    />
  }

  getSignalPinLeftRender = ({ signalInfo, className, isMultiInfo, rank }) => {
    return (
      <div className={`pkg-model-pin-list ${className} pkg-model-left-pin-list`}>
        {signalInfo.map(item => (
          item.pinLeft.map((pin, index) => (
            <div key={index} className="pkg-model-pin-item">
              {isMultiInfo && <Fragment>
                <div className="pkg-model-port-left-pin-content">
                  <div className="pkg-model-port-left-pin-title" title={pin.pinDisplay} style={{ marginRight: 10 }}>{pin.pinDisplay}</div>
                  <div className="pkg-model-port-left-pin-title"><DashOutlined /></div>
                </div>
              </Fragment>}
              <div className="pkg-model-port-pin">
                {this.portPopover({
                  pinItem: pin,
                  type: "pinLeft",
                  net: item.net,
                  pinType: item.pinType,
                })}
              </div>
              {rank && rank.number &&
                <div className='spice-pin-line-box'>
                  <div className='spice-pin-line'></div>
                </div>
              }
              {!isMultiInfo &&
                <div className="pkg-model-port-pin-content">
                  <div className="pkg-model-port-pin-title" title={pin.pinDisplay}>{pin.pinDisplay}</div>
                </div>}
            </div>
          ))
        ))}
      </div>
    );
  }

  getSignalPinRightRender = ({ signalInfo, className, isMultiInfo }) => {
    const { modelType, product } = this.props;
    const otherClassName = product === ANDES_V2 && modelType === "DIE" ? "pkg-die-model-pin-item" : ""
    return <div className={`pkg-model-pin-list ${className}`}>
      {signalInfo.map(item => (
        item.pinRight.map((pin, index) => (
          <div key={index} className={`pkg-model-pin-item ${otherClassName}`}>
            {product === ANDES_V2 && modelType === "DIE" ? null
              : <div className="pkg-model-port-pin-content" style={{ zIndex: !pin.pinDisplay ? -1 : 1 }}>
                {!isMultiInfo && <div className="pkg-model-port-pin-title" title={pin.pinDisplay}>{pin.pinDisplay}</div>}
              </div>}
            <div className="pkg-model-port-pin">
              {this.portPopover({
                pinItem: pin,
                type: "pinRight",
                net: item.net,
                pinType: item.pinType
              })}
            </div>
          </div>
        ))
      ))}
    </div>
  }

  saveTermination = (pinInfo, net) => {
    const { editInputInfo } = this.state;
    const { pinList, termination, fromSignal } = this.props;
    let _pinList = JSON.parse(JSON.stringify(pinList || []));
    let _terminationPairs = JSON.parse(JSON.stringify(termination.pairs || []));

    const { pinListIndex, pinListIndexChild, pairIndex } = getPortIndexList({ pinList: _pinList, pairs: _terminationPairs, pinInfo, net, type: 'pinTermination' })
    if (pinListIndex > -1 && pinListIndexChild > -1) {
      _pinList[pinListIndex].pinTermination[pinListIndexChild] = {
        ..._pinList[pinListIndex].pinTermination[pinListIndexChild],
        pinRes: editInputInfo.value
      }
    }
    if (pairIndex !== -1) {
      _terminationPairs[pairIndex] = {
        ..._terminationPairs[pairIndex],
        r: `${editInputInfo.value} ohm`
      }
    }
    this.setState({
      editInputInfo: {
        pin: "",
        type: "",
        value: ""
      }
    }, () => {
      this.props._updatePorts({
        pinList: _pinList,
        pairs: _terminationPairs,
        type: 'pinTermination'
      }, true, fromSignal);
    })
  }

  getSignalPinTerminationRender = ({ signalInfo, className }) => {
    const { terminationType } = this.props;
    const { editInputInfo = {} } = this.state;
    return <div className={`pkg-model-pin-list ${className}`}>
      {signalInfo.map(item => (
        item.pinTermination.map((pinT, index) => (
          <div key={index} className="pkg-model-pin-item pkg-die-model-pin-item">
            {
              terminationType === 'value'
                ? <Input
                  suffix="Ω"
                  size="small"
                  value={editInputInfo.pin === pinT.pin && editInputInfo.type === 'pinTermination' ? editInputInfo.value : pinT.pinRes}
                  onFocus={() => this.setState({ editInputInfo: { pin: pinT.pin, type: "pinTermination", value: pinT.pinRes } })}
                  onChange={(e) => this.setState({ editInputInfo: { ...this.state.editInputInfo, value: e.target.value } })}
                  onBlur={() => this.saveTermination(pinT, item.net)}
                />
                : <div className="pkg-model-port-pin">
                  {this.portPopover({
                    pinItem: pinT,
                    type: "pinTermination",
                    net: item.net,
                    pinType: item.pinType,
                    placeholder: "Node"
                  })}
                </div>
            }
          </div>
        ))
      ))}
    </div>
  }

  getSelectFile = (type, pinType, net, pin) => {
    const { selectFile, selectFile2, terminationSelectFile, modelType, product, pairs, pinList, models } = this.props;
    if (type === 'pinTermination') {
      return terminationSelectFile;
    } else if (pinType === 'nagative') {
      return selectFile2 || selectFile;
    } else if (product === ANDES_V2 && modelType === "DIE") {
      const anotherType = type === 'pinLeft' ? 'pinRight' : 'pinLeft';
      const { pinListIndex, pinListIndexChild } = getPortIndexList({ pinList, pairs, pinInfo: { pin }, net, type });
      const anotherPin = pinListIndex !== -1 && pinListIndexChild !== -1 ? pinList[pinListIndex][anotherType][pinListIndexChild] : {};
      if (anotherPin.pinModelKey) {
        const fileInfo = models.find(item => item.modelKey === anotherPin.pinModelKey) || selectFile;
        return fileInfo;
      } else {
        return selectFile
      }
    } else {
      return selectFile
    }
  }

  nodeSelectRender = ({ pin, type, pinPort, pinItem, net, pinType, component, placeholder = "Port" }) => {
    //type -> pin type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
    const { searchValue, maxWidth, maxHeight } = this.state;
    const { portsObj, pinList, modelType, product } = this.props;
    const _selectFile = this.getSelectFile(type, pinType, net, pin);
    const _portList = getPkgDisplayPortList({ currentItem: pinItem, type, searchValue, portsObj, selectFile: _selectFile, pinList, modelType, product });
    return (
      <div className='pkg-model-ports-content' style={{ maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200, maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100 }}>
        <div className='pkg-model-ports-content-header'>
          <span>Component Pin {pin}</span>
        </div>
        <div className='pkg-model-ports-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='pkg-model-ports-content-close-icon' />
        </div>
        <div className="pkg-model-port-list-body-with-search">
          <div className="pkg-model-port-list-body-search-wrapper">
            <Input
              placeholder={`Search ${placeholder}`}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
              className='pkg-model-port-list-body-with-search-input'
            />
            <ul className='pkg-model-port-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {_portList.map(item =>
                <li
                  key={item.port}
                  title={item.info}
                  className={item.port === pinPort && item.select ? 'pkg-current-pin-selected-port-li' : (item.select ? 'pkg-port-li-selected' : '')}
                  onClick={!item.select ? () => this.selectPinPort({ port: item.port, pin, type, currentPortInfo: item, modelKey: _selectFile.modelKey, net, component, subckt: item.subckt }) : null}
                >
                  {item.info !== item.port ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item.port}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SignalPinsPortSetup;