import CeNet from './CeNet';
import CeLinkedMap from '../utility/CeLinkedMap';
import StringList from '../utility/StringList';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeNetManager = function () {
  this.mNetList = new CeLinkedMap();
};

CeNetManager.prototype.ClearAll = function () {
  this.mNetList.clear();
}

CeNetManager.prototype.GetNetList = function () {
  return this.mNetList;
}

CeNetManager.prototype.GetNetFromName = function (name) {
  if (this.mNetList.containsKey(name))
    return this.mNetList.getValue(name);
  return null;
}

CeNetManager.prototype.GetAllNets = function () {
  var netNames = new StringList();
  var keys = this.mNetList.keySet();
  for (var i in keys) {
    netNames.add(keys[i]);
  }
  return netNames;
}

CeNetManager.prototype.GetNetsByComp = function (comp) {
  if(!this.mNetList || !this.mNetList.mVals) {
    return []
  }
  const compNets = this.mNetList.mVals.filter(item => item.mPinList.map(m => m.mCompName).includes(comp));
  return compNets.map(net => net.mPinList.filter(item => item.mCompName === comp).map(item => ({ ...item, net: net.mName }))).flat(2)
}

CeNetManager.prototype.CreateNet = function (name) {
  //var oldNet = this.GetNetFromName(name);

  var newNet = new CeNet(name);
  this.mNetList.put(name, newNet);
  return newNet;
}

CeNetManager.prototype.WriteIntoIODataNode = function (myBlock) {
  var keys = this.mNetList.keySet();
  for (var i in keys) {
    var netBlock = new CeIODataBlock(keys[i]);
    this.mNetList.getValue(keys[i]).WriteIntoIODataNode(netBlock);
    myBlock.AddBlockNode(netBlock);
  }
}

CeNetManager.prototype.ReadFromIODataNode = function (myBlock) {
  var allNetBlks = myBlock.GetAllSubBlocks();
  for (var i = 0; i < allNetBlks.length; i++) {
    var name = allNetBlks[i].GetName();
    var newNet = new CeNet(name);
    if (newNet.ReadFromIODataNode(allNetBlks[i]))
      this.mNetList.put(name, newNet);
  }
}

CeNetManager.prototype.AddNet = function (netName, netType) {
  var ceNet = this.GetNetFromName(netName);
  if (ceNet == null) {
    var newNet = new CeNet(netName);
    newNet.SetNetType(netType);
    this.mNetList.put(netName, newNet);
  }
}

export default CeNetManager;