import { COMP_REPEATER, RES, TEST_POINT } from "../../../constants/componentType";
import defaultColor from '../../../constants/defaultColors';
import { getCanvasCompWidth } from './prelayoutHelper';
import { getTextWidthAndHeight } from '../../helper/getTextWidth';
import { CANVAS_PADDING, COMP_HEIGHT, COMP_PADDING, getCanvasCompColor, GROUP_RES_DISTANCE, REPEATER_WIDTH, RES_COLOR, RES_WIDTH, SIGNAL_COMP_WIDTH, SIGNAL_DISTANCE, SIGNAL_TO_COMP, TEST_WIDTH } from "./prelayoutConstants";

const PADDING_X = CANVAS_PADDING, PADDING_Y = CANVAS_PADDING;
class CanvasPlot {
  constructor(props) {
    const { id, width, height, components, signals, isPuppeteer = false } = props;
    this.id = id;
    this.width = width;
    this.height = height;
    this.components = components;
    this.signals = signals;
    this.isPuppeteer = isPuppeteer;
    this.font = 14;
    this.ctx = this.initCtx(id);
    this.virtualCanvas = document.createElement("canvas");
  }

  initCtx = (id) => {
    const { width, height } = this;
    let canvas = document.getElementById(id);
    if (this.isPuppeteer) {
      const ctx = canvas.getContext('2d');
      ctx.lineWidth = 3;
      ctx.font = `normal ${this.font}px Arial`;
      ctx.fillStyle = '#000000';
      //roundRect not support in puppeteer
      ctx.roundRectCustom = (x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
      }
      return ctx;
    }
    const virtualCanvas = document.createElement("canvas");
    virtualCanvas.width = width || canvas.width;
    virtualCanvas.height = height || canvas.height;
    this.virtualCanvas = virtualCanvas;
    const ctx = virtualCanvas.getContext('2d');
    ctx.lineWidth = 3;
    ctx.font = `normal ${this.font}px Arial`;
    ctx.fillStyle = '#000000';
    return ctx;
  }

  clear = () => {
    const { ctx, width, height, id } = this;
    ctx.clearRect(0, 0, width, height);
    this.ctx = this.initCtx(id);
    let canvas = document.getElementById(id);
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
    }
  }

  reset = ({ width, height, components, signals }) => {
    this.clear();
    this.ctx = this.initCtx(this.id);
    this.width = width;
    this.height = height;
    this.components = components;
    this.signals = signals
  }

  setNewData = ({ width, height, signals, components }) => {
    this.width = width;
    this.height = height;
    this.signals = signals;
    this.components = components;
  }

  redraw = () => {
    this.clear();
    this.draw();
  }

  draw = () => {
    this.drawSignals();

    this.drawComponents();

    if (this.isPuppeteer) {
      return;
    }

    this.drawToTrueCanvas();
  }

  drawToTrueCanvas = () => {
    const { id, virtualCanvas } = this;
    let canvas = document.getElementById(id);
    if (canvas) {
      let ctx = canvas.getContext('2d');
      ctx.drawImage(virtualCanvas, 0, 0);
    }
  }

  drawBackground = () => {
    const { ctx, width, height } = this;
    ctx.beginPath();
    ctx.fillStyle = '#ffffff';
    ctx.rect(0, 0, width, height);
    ctx.fill();
    ctx.closePath();
    ctx.fillStyle = '#000000';
  }

  drawSignals = () => {
    const { ctx, signals, width } = this;
    let startX = PADDING_X, endX = width - PADDING_X, y = PADDING_Y;

    let colorIndex = 0;
    for (let signal of signals) {
      ctx.strokeStyle = defaultColor[colorIndex];
      ctx.fillStyle = defaultColor[colorIndex];
      ctx.beginPath();
      ctx.moveTo(startX, y);
      ctx.lineTo(endX, y);
      ctx.stroke();

      ctx.fillText(signal, startX, y + 15);
      ctx.closePath();

      colorIndex = colorIndex + 1;
      y = y + SIGNAL_DISTANCE;
    }

    ctx.strokeStyle = '#000000';
    ctx.fillStyle = '#000000';
  }

  drawComponents = () => {
    const { ctx, signals, components, width, font } = this;
    const calcWidth = 2 * CANVAS_PADDING + components.map(comp => getCanvasCompWidth(comp, signals)).reduce((total, value) => total + value, 0);

    let redundancyWidth = 0;
    if (calcWidth < width) {
      redundancyWidth = (width - calcWidth) / components.length;
      redundancyWidth = redundancyWidth > 10 ? Math.round(redundancyWidth) : 0;
    }
    let resGroup = []
    let startX = PADDING_X + redundancyWidth;
    for (let comp of components) {
      const { type, pins, name, group } = comp;
      const compColor = getCanvasCompColor(type);
      const compStartX = startX + COMP_PADDING;
      if (type === COMP_REPEATER || type === TEST_POINT) {
        const compStartY = PADDING_Y - 15;
        const height = compStartY + SIGNAL_DISTANCE * (signals.length - 1) + 35;
        const compWidth = type === COMP_REPEATER ? REPEATER_WIDTH : TEST_WIDTH
        this.drawSingleComp(compColor, compStartX, compStartY, compWidth, height);
        this.drawCompName(name, compColor, compStartX, compStartY, compWidth, height);

        if (type === COMP_REPEATER) {
          for (let pin of pins) {
            const signalIndex = signals.findIndex(s => s === pin.signal);
            const y = PADDING_Y + SIGNAL_DISTANCE * signalIndex;;
            if (pin.pin.includes('_in')) {
              this.drawPinName(pin.pin, defaultColor[signalIndex], compStartX, y, { left: true })
            } else if (pin.pin.includes('_out')) {
              this.drawPinName(pin.pin, defaultColor[signalIndex], compStartX + compWidth, y, { right: true })
            }

          }
        }

        startX = startX + getCanvasCompWidth(comp, signals) + redundancyWidth;
        continue;
      }

      const compStartY = PADDING_Y + SIGNAL_DISTANCE * (signals.length - 1) + SIGNAL_TO_COMP;
      const compWidth = type === RES ? RES_WIDTH : signals.length * SIGNAL_COMP_WIDTH
      this.drawSingleComp(compColor, compStartX, compStartY, compWidth, COMP_HEIGHT)
      this.drawCompName(name, compColor, compStartX, compStartY, compWidth, COMP_HEIGHT, true);

      let signalX = startX + (resGroup.includes(group) && type === RES ? GROUP_RES_DISTANCE : COMP_PADDING) + 0.5 * (type === RES ? RES_WIDTH : SIGNAL_COMP_WIDTH);
      for (let pin of pins) {
        const signalIndex = signals.findIndex(s => s === pin.signal);
        const signalY = PADDING_Y + SIGNAL_DISTANCE * signalIndex;
        const endY = signalY + SIGNAL_DISTANCE * (signals.length - 1 - signalIndex) + SIGNAL_TO_COMP;
        ctx.strokeStyle = defaultColor[signalIndex];
        ctx.fillStyle = defaultColor[signalIndex];
        ctx.beginPath();
        ctx.moveTo(signalX, signalY);
        ctx.lineTo(signalX, endY);
        ctx.stroke();
        ctx.closePath();

        if (type === RES) {
          ctx.strokeStyle = RES_COLOR;
          ctx.beginPath();
          ctx.moveTo(signalX, endY + COMP_HEIGHT);
          ctx.lineTo(signalX, endY + COMP_HEIGHT + 10);
          ctx.stroke();
          ctx.closePath();
          ctx.strokeStyle = '#000000';
        }

        this.drawPinName(pin.pin, defaultColor[signalIndex], signalX, endY, { center: true })

        signalX = signalX + SIGNAL_COMP_WIDTH;
      }

      ctx.font = `normal ${font}px Arial`;
      ctx.strokeStyle = '#000000';
      ctx.fillStyle = '#000000';

      if (type === RES && !resGroup.includes(group)) {
        resGroup.includes(group);
        startX = startX + getCanvasCompWidth(comp, signals);
        continue;
      }

      if (type === RES && resGroup.includes(group)) {
        resGroup.includes(group);
        startX = startX + getCanvasCompWidth(comp, signals) + COMP_PADDING + redundancyWidth;
        continue;
      }

      startX = startX + getCanvasCompWidth(comp, signals) + redundancyWidth;
    }
  }

  drawSingleComp = (color, startX, startY, width, height) => {
    const { ctx } = this;

    ctx.strokeStyle = color;
    ctx.fillStyle = `#ffffff`;
    ctx.beginPath();
    if (this.isPuppeteer) {
      ctx.roundRectCustom(startX, startY, width, height, 5)
    } else {
      ctx.roundRect(startX, startY, width, height, 5);
    }
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
  }

  drawCompName = (name, color, startX, startY, width, height, sink = false) => {
    const { ctx } = this;
    const { width: nameWidth, height: nameHeight } = getTextWidthAndHeight(name, { fontSize: 20, fontWeight: 'bold' });
    ctx.lineWidth = 1
    ctx.fillStyle = color;
    ctx.font = `bold 20px Arial`;
    const nameX = startX + 0.5 * (width - nameWidth);
    const nameY = startY + (sink ? 0.75 : 0.5) * (height + 0.5 * nameHeight);
    ctx.beginPath();
    ctx.fillText(name, nameX, nameY);
    ctx.closePath();
    ctx.lineWidth = 3
    ctx.fillStyle = "#000000";
    ctx.strokeStyle = "#000000"
  }

  drawPinName = (name, color, x, y, { left = false, right = false }) => {
    const { ctx } = this;
    const { width: nameWidth } = getTextWidthAndHeight(name, { fontSize: 12, fontWeight: 'bold' });
    ctx.lineWidth = 1
    ctx.fillStyle = color;
    ctx.font = `normal 12px Arial`;
    const nameX = left ? x + 5 : right ? x - nameWidth - 5 : x - 0.5 * nameWidth;
    const nameY = left || right ? y : y + 15;
    ctx.beginPath();
    ctx.fillText(name, nameX, nameY);
    ctx.closePath();
    ctx.lineWidth = 3
    ctx.fillStyle = "#000000";
    ctx.strokeStyle = "#000000"
  }
}

export {
  CanvasPlot
}