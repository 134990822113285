import { EyeOutlined, RetweetOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { getDecapValue } from '../store/result/action'
import EditableTable from '@/components/EditableTable';
import projectDesigns from '../../../services/helper/projectDesigns';
import { selectChange, selectLayer } from '../../LayoutExplorer/store/FastPI/actionCreators';
import canvas from '@/services/LayoutCanvas';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import './index.css'

const compColumns = [
  {
    title: 'Component',
    dataIndex: 'name',
    width: '25%',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Part Number',
    dataIndex: 'part',
    width: '25%',
    sorter: (a, b) => a.part.localeCompare(b.part),
  },
  {
    title: 'Resistance DC (mOhm)',
    dataIndex: 'resistance',
    width: '16.6%',
    sorter: (a, b) => a.resistance - b.resistance
  }
];
const inductances_1 = [
  {
    title: 'Inductances @1MHz (pH)',
    dataIndex: 'inductances',
    width: '16.6%',
    sorter: (a, b) => a.inductances - b.inductances
  }
]
const inductances_10 = [
  {
    title: 'Inductances @10MHz (pH)',
    dataIndex: 'inductances_10Mhz',
    width: '16.6%',
    sorter: (a, b) => a.inductances_10Mhz - b.inductances_10Mhz
  }
]

class Decap extends PureComponent {

  constructor(props) {
    super(props);

    compColumns[0].render = (data, record) => {
      return <div>
        <span>{record.name}</span>
        {record.usage === 'Unused' ? <span className="pdn-table-unstuffed-span">(Unstuffed)</span> : null}
      </div>
    }

    this.state = {
      columns: [...compColumns, ...inductances_1, ...inductances_10],
      height: 484
    }
  }

  componentDidMount() {
    const { projectId, pdnId, verificationId } = this.props;
    this.props.getDecapValue(projectId, pdnId, verificationId);
    // this.setColumns();
    this.getHeight();
    window.addEventListener('resize', this.getHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getHeight);
  }

  getHeight = () => {
    const ele = document.getElementById("PDN-result-decap-content");
    this.setState({
      height: ele ? ele.clientHeight : 484
    })
  }

  setColumns = () => {
    const { columns } = this.state;
    let _columns = [...columns];

    const title = columns[3].dataIndex === 'inductances' ? 'Inductances @1MHz (pH)' : 'Inductances @10MHz (pH)';
    columns[3].title = () => {
      const { decapData } = this.props;
      const change = decapData && decapData.length && decapData[0].inductances_10Mhz ? true : false;
      return (
        <div>
          <span>{title}</span>
          {change && <RetweetOutlined className="pdn-decap-switch-icon" onClick={(e) => this.changeMhz(e)} />}
        </div>
      );
    }

    this.setState({
      columns: _columns
    })
  }

  changeMhz = (e) => {
    e && e.stopPropagation();
    const { columns } = this.state;
    let _columns = [...columns];
    const mhzList = _columns[3].dataIndex === 'inductances' ? [...inductances_10] : [...inductances_1];
    this.setState({
      columns: [...compColumns, ...mhzList]
    }, () => {
      this.setColumns()
    })
  }

  viewStuffedDecap = (e, components) => {
    let stuffedComps = [];
    components.forEach(item => {
      stuffedComps.push(item.name)
    });
    stuffedComps = [...new Set(stuffedComps)];
    const { selectedDesignIDs, currentProjectId } = this.props;
    let pcbId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)
    if (selectedDesignIDs.includes(pcbId)) {
      const layout = canvas.getLayout(pcbId);
      if (!layout) {
        return;
      }
      let layers = layout.findCurrentLayer(stuffedComps);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...stuffedComps] }, pcbId);
    }
  }

  TableClick = (record, view) => {
    if (!view) {
      return
    }
    let stuffedComps = []
    stuffedComps.push(record.name)
    const { selectedDesignIDs, currentProjectId } = this.props;
    let pcbId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ comps: [...stuffedComps] })
    }
  }

  render() {
    const { decapData, selectedDesignIDs, currentProjectId } = this.props;
    const { columns, height } = this.state;
    let pcbId = projectDesigns.getAvailableDesignsFirstId(currentProjectId)
    let view = false;
    if (selectedDesignIDs.includes(pcbId)) { view = true }
    return (
      <div className="PDN-result-decap-content" id="PDN-result-decap-content">
        <div>
          <span className="pdn-result-decap-span">Decap</span>
          {decapData.length > 0 ?
            <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
              <EyeOutlined
                className="pdn-result-view-icon"
                onClick={(e) => this.viewStuffedDecap(e, decapData)} />
            </Tooltip>
            : null}
        </div>
        <EditableTable
          onRow={record => {
            return {
              onClick: event => { this.TableClick(record, view) }
            }
          }}
          dataSource={decapData}
          columns={columns}
          size="small"
          rowKey={(record) => { return record.name }}
          scroll={(decapData.length + 1) * 60 > height - 120 ? { y: height - 120 } : {}}
          className="PDN-result-decap-table"
        />
      </div>
    );
  }
}

const mapState = (state) => {
  const { resultReducer: { resultKey, decapData }, project: { currentProjectId, treeSelectedKeys } } = state.PDNReducer
  return {
    resultKey,
    decapData,
    currentProjectId,
    selectedDesignIDs: getSelectedDesignIDs(treeSelectedKeys),
  }
}

const mapDispatch = (dispatch) => ({
  getDecapValue(projectId, pdnId, verificationId) {
    dispatch(getDecapValue(projectId, pdnId, verificationId))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
})

export default connect(mapState, mapDispatch)(Decap)
