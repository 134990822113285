import React, { Component, Fragment } from 'react';
import { Switch, Input, Select, Radio, Divider } from 'antd';
import { scaleConversion } from '@/services/helper/numberHelper';
import { valueCheck } from '@/services/helper/valueCheck';
import NP from 'number-precision';
import { numberCheck } from '@/services/helper/dataProcess';
import { SIERRA } from '@/constants/pageType';
import MultiZoneOptions from '../../../../components/ExtractionOptions/MultiZoneOptions';
import '../index.css';
import SweepOptions from './sweepOptions';

const { Option } = Select;
class AdvancePanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  unitSelect = (key) => {
    const { units } = this.props;
    const options = this.getUnitOptions(key);
    const unitKey = `${key}Unit`;
    const value = units[unitKey];
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      value={value}
      style={{ width: 68 }}
      onChange={(e) => this.changeUnit(e, key, unitKey)}
    >
      {options.map(item => (
        <Option key={item} value={item}>{item}</Option>
      ))}
    </Select>
  }

  getUnitOptions = (key) => {
    let options = [];
    switch (key) {
      case 'logSweepMin':
        options = ["Hz", "KHz"];
        break;
      case 'logSweepMax':
      case 'linearSweepMax':
        options = ["Hz", "KHz", "MHz", "GHz"];
        break;
      case 'linearSweepFreqStep':
      case 'maxFreqStep':
        options = ["Hz", "KHz", "MHz"];
        break;
      case 'maxFreq':
        options = ["GHz"];
        break;
      default:
        options = ["Hz", "KHz", "MHz", "GHz"];
        break;
    }
    return options;
  }

  changeUnit = (key, type, typeUnit) => {
    const { units, extraction } = this.props;
    let _units = { ...units };
    let _config = { ...extraction };

    if (_units[typeUnit] === key) {
      return;
    }

    const scale = scaleConversion(key, _units[typeUnit]);
    _config[type] = _config[type] && !numberCheck(_config[type]) ? NP.times(_config[type], scale).toString() : _config[type];
    _units[typeUnit] = key;
    this.props.updateUnits(_units, "units");
    this.props.updateCurrentExtraction(_config, "extraction");
  }

  changeClip = (checked) => {
    const { extraction, error } = this.props;
    let config = { ...extraction };
    config.clipping = checked;
    if (!checked) {
      config.clipSize = "0.5";
      if (error && error.type === 'clipSize') {
        this.props.updateError(null);
      }
    }
    this.props.updateCurrentExtraction(config, "extraction");
  }

  changeConfigValue = (e, type, subType) => {
    let value = e.target.value;
    const { extraction, error } = this.props;
    let config = { ...extraction };
    if (["hpc_options"].includes(type)) {
      config[type][subType] = value;
    } else {
      config[type] = value;
    }
    if (error && error.type === type) {
      this.props.updateError(null);
    }
    this.props.updateCurrentExtraction(config, "extraction");
  }

  inputBlur = (e, inputType, subType) => {
    let error = null;
    const { units, extraction } = this.props;
    let value = extraction[inputType];
    let config = { ...extraction };
    let _type = inputType;

    if (["hpc_options"].includes(inputType)) {
      value = extraction[inputType][subType];
      _type = subType;
    }

    const unit = units[`${_type}Unit`];

    if (['maxFreq', 'maxFreqStep'].includes(_type)) {
      if (value) {
        error = valueCheck({ value, inputType: _type, unit, version: SIERRA });
      }
    } else {
      error = valueCheck({ value, inputType: _type, unit, version: SIERRA });
    }

    if (error) {
      e.target.focus();
      this.props.updateError({ type: _type, error });
    } else {
      if (_type === 'maxFreq') {
        const scale = scaleConversion("GHz", units.maxFreqUnit);
        config["meshFrequency"] = config["meshFrequency"] && !numberCheck(config["meshFrequency"]) ? NP.times(config["meshFrequency"], scale).toString() : config["meshFrequency"];
        this.props.updateCurrentExtraction(config, "extraction");
      } else if (inputType === "hpc_options") {
        config[inputType][subType] = value;
        this.props.updateCurrentExtraction(config, "extraction");
      }
      const prevError = this.props.error;
      if (prevError && (prevError.type === _type || prevError.type === "judge")) {
        this.props.updateError(null);
      }
    }
  }

  changeClipSizeRadio = (e) => {
    const { extraction, clipSizeUnit, error } = this.props;
    let config = { ...extraction };
    if (clipSizeUnit === e.target.value) {
      return;
    }
    let value = '';
    if (e.target.value === 'ratio') {
      value = '0.5';
    } else if (e.target.value === 'mm') {
      value = '5';
    }

    config.clipSize = value;
    if (error && error.type === 'clipSize') {
      this.props.updateError(null);
    }
    this.props.updateUnits(e.target.value, "clipSizeUnit");
    this.props.updateCurrentExtraction(config, "extraction");
  }

  switchChange = (checked, type) => {
    const { extraction } = this.props;
    let config = { ...extraction };
    if (["hpcOptionSwitch"].includes(type)) {
      this.props.changeSwitch(checked, type);
    } else {
      config[type] = checked;

      if (type === "includeDC" && !checked) {
        config["exactDC"] = false;
      }
      this.props.updateCurrentExtraction(config, "extraction");
    }
  }

  switchMultiZone = (checked, type) => {
    const { extraction } = this.props;
    let config = { ...extraction };
    if (type === 'multiZone') {
      config['multiZone'] = checked ? { ...config['multiZone'], stackupMode: 'Multizone' } : { ...config['multiZone'], stackupMode: 'Laminate' };
    } else {
      config['multiZone'] = { ...config['multiZone'], [type]: checked }
    }
    this.props.updateCurrentExtraction(config, "extraction");
  }

  multiZoneRender = (type) => {
    const { simulateHFSS, extraction, isMultiZone } = this.props;
    const { multiZone } = extraction;
    return <Fragment>
      <MultiZoneOptions
        isMultiZone={!simulateHFSS ? false : isMultiZone}
        // multiZonePreview={multiZonePreview}
        zoneOptions={{
          multiZone: multiZone && multiZone.stackupMode && multiZone.stackupMode === 'Multizone',
          enableBends: !multiZone || multiZone.enableBends === undefined ? true : multiZone.enableBends
        }}
        isPowerSI={false}
        changeBooleanSwitch={this.switchMultiZone}
        className="sierra-extraction-content"
      />
      <Divider />
    </Fragment>
  }

  HPCContent = () => {
    const { extraction = {}, hpcOptionSwitch } = this.props;
    const { hpc_options = {} } = extraction;
    return <div className='sierra-extraction-content extraction-content-hpc'>
      <span className='sierra-extraction-content-body'>Custom HPC</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={hpcOptionSwitch}
        onChange={(e) => this.switchChange(e, "hpcOptionSwitch")}
      />
      {hpcOptionSwitch ? <Fragment>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-content-body sierra-extraction-body-width'>Number of CPU</span>
          <Input
            className='extraction-input sierra-extraction-input-width'
            value={hpc_options.num_cores}
            onChange={(e) => this.changeConfigValue(e, 'hpc_options', "num_cores")}
            onBlur={(e) => this.inputBlur(e, 'hpc_options', "num_cores")}
          />
        </div>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-content-body sierra-extraction-body-width'>Number of GPU</span>
          <Input
            className='extraction-input sierra-extraction-input-width'
            value={hpc_options.num_gpus}
            onChange={(e) => this.changeConfigValue(e, 'hpc_options', "num_gpus")}
            onBlur={(e) => this.inputBlur(e, 'hpc_options', "num_gpus")}
          />
        </div>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-content-body sierra-extraction-body-width'>Max RAM</span>
          <Input
            className='extraction-input sierra-extraction-input-width'
            value={hpc_options.ram_percent}
            onChange={(e) => this.changeConfigValue(e, 'hpc_options', 'ram_percent')}
            addonAfter={"%"}
            onBlur={(e) => this.inputBlur(e, 'hpc_options', "ram_percent")}
          />
        </div>
        <div className='sierra-extraction-content'>
          <span className='sierra-extraction-content-body sierra-extraction-body-width'>Parallel Freq. solve</span>
          <Input
            className='extraction-input sierra-extraction-input-width'
            value={hpc_options.num_tasks}
            onChange={(e) => this.changeConfigValue(e, 'hpc_options', "num_tasks")}
            onBlur={(e) => this.inputBlur(e, 'hpc_options', "num_tasks")}
          />
        </div></Fragment> : null}
    </div>
  }

  render() {
    const { extraction, clipSizeUnit, error, simulateHFSS, units } = this.props;
    const { clipping,
      clipSize, includeDC, exactDC, /* discreteSweep, */
      meshFrequency, maxPasses, maxDelta } = extraction;
    return <div className='sierra-extraction-options-main' >
      {/* Design Clipping */}
      <div className='sierra-extraction-content'>
        <span className='sierra-extraction-content-body'>Clip design</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={clipping}
          onChange={this.changeClip}
        />
        <Radio.Group onChange={this.changeClipSizeRadio} value={clipSizeUnit} className='extraction-radio-clip-group'>
          <Radio
            value={'ratio'}
            className='extraction-clip-radio-item'
            disabled={!clipping}
          >
            Ratio</Radio>
          <Radio
            disabled={!clipping}
            value={'mm'}
            className='extraction-clip-radio-item'
          >
            Distance</Radio>
        </Radio.Group>
        <Input
          value={clipSize}
          className='extraction-input'
          onChange={(e) => this.changeConfigValue(e, 'clipSize')}
          onBlur={(e) => this.inputBlur(e, 'clipSize')}
          disabled={!clipping}
          addonAfter={clipSizeUnit === "mm" ? "mm" : null}
        />
      </div>
      <Divider />
      <div className='sierra-extraction-content'>
        <span className='sierra-extraction-content-body'>Include DC</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={includeDC}
          onChange={(checked) => this.switchChange(checked, "includeDC")}
        />
        <span className='sierra-extraction-content-body sierra-extraction-exact-dc'>Exact DC Solver</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={exactDC}
          onChange={(checked) => this.switchChange(checked, "exactDC")}
        />
      </div>
      {/* Frequency Sweep */}
      <SweepOptions
        extraction={extraction}
        units={units}
        error={error}
        updateUnit={this.props.updateUnits}
        updateError={this.props.updateError}
        updateExtractionConfig={(config) => this.props.updateCurrentExtraction(config, "extraction")} />
      {/* <div className='sierra-extraction-content'>
        <span className='sierra-extraction-content-body'>Discrete Sampling</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={discreteSweep}
          onChange={(checked) => this.switchChange(checked, "discreteSweep")}
        />
      </div> */}
      <Divider />
      {/* Extraction Setup */}
      <div className='sierra-extraction-content'>
        <span className='sierra-extraction-content-body sierra-extraction-body-width'>Meshing Frequency</span>
        {/* >1 */}
        <Input
          className='extraction-input sierra-extraction-input-width'
          value={meshFrequency}
          addonAfter={'GHz'}
          onChange={(e) => this.changeConfigValue(e, 'meshFrequency')}
          onBlur={(e) => this.inputBlur(e, 'meshFrequency')}
        />
      </div>
      {/* Multi Zone */}
      {this.multiZoneRender()}
      <Fragment>
        <div className={simulateHFSS ? 'sierra-extraction-content' : 'sierra-extraction-content sierra-extraction-gray'}>
          <span className='sierra-extraction-content-body sierra-extraction-body-width'>Adaptive Meshing Max Number of Passes</span>
          {/* >2 only show if modeling with HFSS */}
          <Input
            className='extraction-input sierra-extraction-input-width'
            value={maxPasses}
            onChange={(e) => this.changeConfigValue(e, 'maxPasses')}
            onBlur={(e) => this.inputBlur(e, 'maxPasses')}
            disabled={!simulateHFSS}
          />
        </div>
        <div className={simulateHFSS ? 'sierra-extraction-content' : 'sierra-extraction-content sierra-extraction-gray'}>
          <span className='sierra-extraction-content-body sierra-extraction-body-width'>Adaptive Meshing Max Delta</span>
          {/* 0.001~1 only show if modeling with HFSS */}
          <Input
            className='extraction-input sierra-extraction-input-width'
            value={maxDelta}
            onChange={(e) => this.changeConfigValue(e, 'maxDelta')}
            onBlur={(e) => this.inputBlur(e, 'maxDelta')}
            disabled={!simulateHFSS}
          />
        </div>
        <Divider />
        {this.HPCContent()}
        {this.props.passivePortContent ?
          <Fragment>
            <Divider />
            {this.props.passivePortContent()}
          </Fragment>
          : null}
      </Fragment>
      {error && error.error && <span className='sierra-extraction-option-error-msg'>Error: {error.error}</span>}
    </div>
  }
}

export default AdvancePanel;