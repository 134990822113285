import {
  PROJECT, PCB, PROJECTS, PCBS,
  TRASH, VERIFICATION, RESULT, VERIFICATIONS, LIBRARY,
  LIBRARY_FILE, LIBRARY_DATA, LIBRARY_FOLDER, EXPERIMENTS, MY_LIBRARY, PCB_SETTING,
  PCB_PRE_LAYOUT, VERIFICATION_GROUP, UPLOAD_PCB, REPLACE_PCB
} from '@/constants/treeConstants';
import {
  VERIFY_RUNNING,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  VERIFY_CANCEL,
  WAITING
} from '@/constants/verificationStatus';
import { PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY, SPICE, NETS_FILTER_LIBRARY, TRACE } from '../../../constants/libraryConstants';

export const ADD = 'add', DELETE = 'delete', EDIT = 'edit',
  VERIFY = 'verify', SIMULATION = 'simulation', CLEAN = 'clean', STATUS = 'status',
  MACRO_MODELING = 'macro_modeling', RENAME = 'renames', COPY = 'copy',
  LOADING = 'loading', COPY_PROJECT = 'copy_project', ADD_LIBRARY = 'add_library', FILE = 'file', FOLDER = 'folder',
  UPDATE = 'update', MORE = 'more', INFO = 'info', EXPERIMENT = 'experiment', IMPORTEXPORT = 'import_export',
  BATCH_DELETE = 'batch_delete', COMBINE_INTERFACE = 'combine_interface', MULTI_SETUP = "multi_setup", BLANK_FILE = "blank_file",
  DEFAULT = "default";

function getIconKey(type, key, fileType) {
  switch (type) {
    case PROJECTS:
      return 1;
    case RESULT:
      return 2;
    case PCBS:
      return 5;
    case VERIFICATION:
      return 6;
    case VERIFICATIONS:
      return 7;
    case TRASH:
      return 9;
    case LIBRARY_FILE:
    case LIBRARY_DATA:
      return [SPICE].includes(key) && fileType === "file" ? 20 : 10;
    case PROJECT:
      return 11;
    case PCB:
      return 12;
    case COPY_PROJECT:
      return 13;
    case LIBRARY:
      if (key === TRACE) {
        return 22;
      }
      return [PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY, NETS_FILTER_LIBRARY].includes(key) ? 0 : 14;
    case LIBRARY_FOLDER:
      return 15;
    case EXPERIMENTS:
      return 16;
    case MY_LIBRARY:
      return 17
    case PCB_PRE_LAYOUT:
      return 21;
    case VERIFICATION_GROUP:
      return 19;
    default: return 0;
  }
}
const IconKey = {
  1: [ADD, IMPORTEXPORT],
  2: [DELETE],
  5: [UPLOAD_PCB],
  6: [MORE, INFO, VERIFY, STATUS],
  7: [ADD, SIMULATION, BATCH_DELETE, COMBINE_INTERFACE, MULTI_SETUP],
  9: [CLEAN],
  10: [DELETE, UPDATE, MACRO_MODELING],
  11: [DELETE, COPY, RENAME],
  12: [DELETE, REPLACE_PCB, PCB_SETTING, INFO],
  13: [LOADING],
  14: [ADD_LIBRARY],
  15: [DELETE, ADD_LIBRARY, RENAME],
  16: [DELETE, RENAME],
  17: [IMPORTEXPORT],
  18: [EDIT],
  19: [VERIFY],
  20: [DELETE, UPDATE, DEFAULT],
  21: [DELETE, INFO],
  22: [ADD],
  0: []
}

function getIconCollectionKey(type) {
  switch (type) {
    case VERIFICATION:
      return [EXPERIMENT, RENAME, COPY, DELETE];
    default:
      break;
  }
}

function getPopMessage(type, dataType) {
  switch (dataType) {
    case PROJECT:
    case VERIFICATION:
      return `The ${type} can not be restored once it is deleted. Are you sure that you want to proceed?`;
    case LIBRARY_DATA:
      if (type === TRACE) {
        return 'Once the library is deleted, all pre layout that use this library will be updated.';
      }
      return 'Once the file is deleted, all interfaces that use this file will be invalid.';
    case LIBRARY_FILE:
      return 'Once the file is deleted, all interfaces that use this file will be invalid.';
    case TRASH:
      return "Can not be restored after clearing trash. Are you sure you want to delete it?";
    case RESULT:
      return "Can not be restored after deleting the result. Are you sure you want to delete it?";
    case LIBRARY_FOLDER:
      return "Once the folder is deleted, all files in the folder will also be deleted.";
    case EXPERIMENTS:
      return `The sweep can not be restored once it is deleted. Are you sure that you want to proceed?`;
    case VERIFICATIONS:
      return `These interfaces cannot be recovered once deleted. Are you sure that you want to proceed?`;
    case PCB_PRE_LAYOUT:
      return `Once the PreLayout is deleted, all the interface setup will be invalid.`;
    default:
      return `Once the ${type} is deleted, all the interface setup will be invalid.`;
  }
}

function getType(dataType, fileType) {
  switch (dataType) {
    case PCB:
      return PCB;
    case LIBRARY_FILE:
      return LIBRARY_FILE;
    case LIBRARY_DATA:
      return fileType === TRACE ? TRACE : LIBRARY_DATA;
    case PROJECT:
      return 'project';
    case VERIFICATION:
      return 'interface';
    case RESULT:
      return 'results';
    case LIBRARY_FOLDER:
      return LIBRARY_FOLDER;
    case EXPERIMENTS:
      return EXPERIMENTS;
    case PCB_PRE_LAYOUT:
      return PCB_PRE_LAYOUT;
    default: return false;
  }
}

function getVerificationStatus(status, readyForSim, verification) {
  let verificationStatus = '', msg = '', color = '';
  if (verification.simStatus) {
    switch (verification.simStatus) {
      case 'checking':
        verificationStatus = 'loading3'; msg = 'Checking for simulation'; color = '#ff5500'; break;
      /*   case 'error':
          verificationStatus = 'N'; msg = 'Not Prepared'; color = '#7b7e8c'; break; */
      case 'success':
        verificationStatus = 'tick'; msg = 'Checked Successful'; color = '#4dbd16'; break;
      /*  case VERIFY_FAILED: */
      default: /* verificationStatus = 'tick'; msg = 'Preparing for simulation'; color = '#4dbd16';  */break;
    }
  } else {
    switch (status) {
      /*   case VERIFY_NEVER:
          if (readyForSim === 0) {
            verificationStatus = 'N';
            msg = 'Not Prepared';
            color = '#7b7e8c';
          } else if (readyForSim === 1) {
            verificationStatus = 'P';
            msg = 'Prepared for simulation';
            color = '#e850dc';
          }
          break; */
      case VERIFY_RUNNING:
        verificationStatus = 'R'; msg = 'Running'; color = '#31aaf3'; break;
      case VERIFY_SUCCESS:
        verificationStatus = 'S'; msg = 'Success'; color = '#4dbd16'; break;
      case VERIFY_FAILED:
        verificationStatus = 'F'; msg = 'Failed'; color = '#b32222e8'; break;
      case VERIFY_CANCEL:
        verificationStatus = 'C'; msg = 'Cancel'; color = '#9d7ace'; break;
      case WAITING:
        verificationStatus = 'W'; msg = 'Waiting'; color = '#ff5500'; break;
      default: /* verificationStatus = 'P'; msg = 'Prepared for simulation'; color = '#e850dc';  */break;
    }

    /*   if (readyForSim === 0) {
        verificationStatus = 'N';
        msg = 'Not Prepared';
        color = '#7b7e8c';
      } */
  }
  return { verificationStatus, msg, color }
}

export {
  getPopMessage,
  getType,
  IconKey,
  getIconKey,
  getIconCollectionKey,
  getVerificationStatus
}