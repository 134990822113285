import CanvasObject from './CanvasObject';

const CanvasPolyline = function () {
  CanvasObject.call(this);
  this.points = "";
  this.width = 0;
};

CanvasPolyline.prototype = Object.create(CanvasObject.prototype);
CanvasPolyline.prototype.constructor = CanvasPolyline;

CanvasPolyline.prototype.drawSVG = function (svgElement, attributes) {

  var self = this;
  var element = svgElement
    .append('polyline')
    .attr(attributes)
    .attr('points', function (x) {
      return self.points;
    });

  if (this.toolTip) {
    element.append('title').text(self.toolTip);
  }

};

export default CanvasPolyline;