import {
  UPDATE_SPICE_CARD_INFO,
  UPDATE_SPICE_CARD_DATA,
  CLEAR_SPICE_CARD_INFO
} from './actionTypes';

const defaultState = {
  verificationId: null,
  channelId: null,
  data: {}
}

export const HimalayasSpiceCardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SPICE_CARD_DATA:
      return {
        ...state,
        data: { ...action.data }
      }
    case UPDATE_SPICE_CARD_INFO:
      return {
        ...state,
        ...action.data //data -> { [dataType]: value, ... }
      }
    case CLEAR_SPICE_CARD_INFO:
      return {
        ...state,
        verificationId: null,
        channelId: null,
        data: {}
      }
    default: return state;
  }
};