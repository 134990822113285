import { select } from 'd3-selection';
import Viewer, { CurveItem } from './PlotView';
import { getYLabel } from '../../../helper/sparameter/utility';
import { ANDES_V2 } from '../../../../constants/pageType';

class Parameters {
  constructor() {
    this.parameters = new Map();
    this.plotViewer = null;
  }

  getSize() {
    return this.parameters.size;
  }

  getParameter(id) {
    return this.parameters.get(id);
  }

  initParameter(key, parameterData) {
    this.parameters.set(key, parameterData)
    return this.parameters;
  }

  createPlot(element, setting, events, product) {
    this.plotViewer = new Viewer({ element, events, product });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('Hz');
    xAxis.setLabel('Frequency');
    xAxis.setScale('linear');
    let yAxis = this.plotViewer.getAxis('y');
    // TODO: Y axis unit:  zam - ohm
    yAxis.setUnit('');
    yAxis.setLabel(getYLabel(setting));
    // Default y scale
    yAxis.setting.scale = "linear";

    if (product === ANDES_V2) {
      this.plotViewer.crossReferenceLine({ lineDatas: setting.referenceLineData, displayMode: setting.displayMode, isShowReferenceLine: true, redrawType: '', isDraw: false })
    }
    return this.plotViewer;
  }

  showCurve({ parameter, row, col, param, value, fileIndex, curveType, fileName = '', hashId, couplingType, wordIndex }) {
    if (!parameter || !parameter.getCurveData || !parameter.ports) return new Promise((resolve) => { resolve(null) });
    const [, signal, pair] = parameter.id.split("::");
    return new Promise((resolve) => {
      parameter.getCurveData({ row, col, param, value, fileIndex, curveType, fileName, hashId, signal, pair, couplingType, wordIndex }).then((response) => {
        let curve = new CurveItem();
        curve.x = response.freq ? response.freq : parameter.freq;
        curve.y = response.curve;
        curve.row = row;
        curve.col = col;
        curve.type = response.type;
        curve.name = parameter.name;
        curve.id = parameter.id;
        curve.color = response.color;
        curve.curveType = curveType || null;
        curve.hashId = hashId;
        resolve(curve)
      });
    })
  };

  // curves -[{ parameter, fileIndex, row, col, curveType }]
  showCurves({ curves, param, value, curveType, fileName = '', couplingType }) {
    const curvesFilter = curves.filter(d => d && d.parameter && d.parameter.getCurveData && d.parameter.ports);
    return Promise.all(curvesFilter.map(cur => cur.parameter.getCurveData({ row: cur.row, col: cur.col, param, value, fileIndex: cur.fileIndex, curveType: curveType ? curveType : cur.curveType, fileName, signal: cur.signal, pair: cur.pair, couplingType, wordIndex: cur.wordIndex }).then(response => {
      let curve = new CurveItem();
      curve.x = response.freq ? response.freq : cur.parameter.freq;
      curve.y = response.curve;
      curve.row = cur.row;
      curve.col = cur.col;
      curve.type = response.type;
      curve.name = cur.parameter.name;
      curve.id = cur.parameter.id;
      curve.color = response.color;
      curve.curveType = cur.curveType || null;
      curve.hashId = cur.hashId;
      return curve;
    })))
  }

  removeCurve({ row, col, id, hashId }) {
    return this.plotViewer.removeCurve({ row, col, id, hashId });
  }

  // [{ row, col, id }]
  removeCurves(curves) {
    this.plotViewer.removeCurves(curves);
  }

  clearCurveMark() {
    this.plotViewer.clearCurveMark();
  }

  changeAxis(axis, { param, value, scale }) {
    if (this.plotViewer) {
      const _axis = axis.toUpperCase();
      if (_axis === 'Y') {
        let yAxis = this.plotViewer.getAxis('y');
        yAxis.setLabel(getYLabel({ param, value, yScale: scale }));
      }
      this.plotViewer['update' + _axis + 'Axis']({ param, value, scale });
    }
  }

  updateRange(type, axis) {
    if (axis) {
      this.plotViewer.updateAxisRange(type, axis)
    }
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }

  changeParameter({ parameters, setting, yScale, xScale, couplingType }) {
    if (this.plotViewer === null) return;
    const xAxis = this.plotViewer.getAxis('x'),
      yAxis = this.plotViewer.getAxis('y');
    xAxis.setScale(xScale);
    yAxis.setScale(yScale);

    const curves = this.plotViewer.curves;
    const promises = curves.map(item => {
      const parameter = parameters.find((param) => param.id === item.id);
      const [, signal, pair] = parameter.id.split("::");
      return parameter.getCurveData({ row: item.row, col: item.col, param: setting.parameter, value: setting.value, curveType: parameter.type, fileName: parameter.fileName, signal, pair, couplingType }).then(
        (response) => {
          item.y = response.curve;
          item.type = response.type;
        }
      )
    });
    return Promise.all(promises).then(() => {
      yAxis.setLabel(getYLabel({ param: setting.parameter, value: setting.value, yScale }));
      let unit = '';
      xAxis.setUnit("Hz");

      if (setting.value === 'am') {
        if (setting.parameter.toLowerCase() === 's') {
          unit = '';
        } else {
          unit = setting.parameter.toLowerCase() === 'y' ? 'Ω⁻¹' : 'Ω';
        }
      } else {
        unit = '';
      }
      yAxis.setUnit(unit);
      this.plotViewer.updateYAxis({ param: setting.parameter, value: setting.value, scale: yScale });
    })
  }

  cleanParameters = () => {
    this.parameters = new Map();
    this.plotViewer = null;
  }

  zoomIn = () => {
    this.plotViewer.zoomIn()
  }

  zoomOut = () => {
    this.plotViewer.zoomOut()
  }

  fitView = () => {
    this.plotViewer.fitView()
  }
}

export default Parameters;