/**
 * 
 *
 * @param {Array} arr 
 * @param {Number} value
 * @param {Number} start
 * @param {Number} end
 * @returns
 */
function insertionSearch(arr, value, start, end) {
  let mid = start + (value - arr[start]) / (arr[end] - arr[start]) * (end - start);
  let index = Math.round(mid);
  let prev = index - 1, next = index + 1;
  if (index === start) {
    prev = start;
  } else if (index === end) {
    next = end;
  };

  if (value > arr[prev] && value < arr[next]) {
    return index;
  } else if (value === arr[prev]) {
    return prev;
  } else if (value === arr[next]) {
    return next;
  };

  if (value < arr[prev]) {
    return insertionSearch(arr, value, start, prev);
  }
  if (value > arr[next]) {
    return insertionSearch(arr, value, next, end);
  }
};

/**
 * Get the index value of a frequency or a time in a whole frequency range or time range
 * arr is a array that stores time or frequency values
 *
 * @param {Array} arr
 * @param {Number} value
 * @param {Number} start
 * @param {Number} end
 * @returns
 */
function getIndex(arr, value, start, end) {
  if (value < arr[start]) {
    return -1;
  } else if (value > arr[end]) {
    return -1;
  };
  return insertionSearch(arr, value, start, end);
};

// function testInsertionSearch() {
//   // Frequency
//   console.time();
//   const arr = [1000, 1258.93, 1584.89, 1995.26, 2511.89, 3162.28, 3981.07, 5011.87, 6309.57, 7943.28, 10000, 12589.3, 15848.9, 19952.6, 25118.9, 31622.8, 39810.7, 50118.7, 63095.7, 79432.8, 100000, 125893, 158489, 199526, 251189, 316228, 398107, 501187, 630957, 794328, 1e+06, 1.25893e+06, 1.58489e+06, 1.99526e+06, 2.51189e+06, 3.16228e+06, 3.98107e+06, 5.01187e+06, 6.30957e+06, 7.94328e+06, 1e+07, 1.5e+07, 2e+07, 2.5e+07, 3e+07, 3.5e+07, 4e+07, 4.5e+07, 5e+07, 5.5e+07, 6e+07, 6.5e+07, 7e+07, 7.5e+07, 8e+07, 8.5e+07, 9e+07, 9.5e+07, 1e+08]
//   for (const value of [0, ...arr, 1e10]) {
//     const index = getIndex(arr, value, 0, arr.length - 1);
//     console.log(index);
//   };
//   console.timeEnd();
// };
// testInsertionSearch()

export default getIndex;