import { login, getVersion, updateTimeZone, getDescription, updatePassword } from './api/auth';
import apiProcess from './api/utility';

export function userloginPromise(email, password) {
  return new Promise((resolve, reject) => {
    apiProcess(login, { email, password }).then((res) => {
      resolve(res);
    }, error => {
      resolve(false);
    });
  })
}

export function getVersionPromise() {
  return apiProcess(getVersion);
}

export function updateTimeZonePromise(timeZone) {
  return apiProcess(updateTimeZone, timeZone);
}

export function getDescriptionPromise() {
  return apiProcess(getDescription)
}

export function resetPasswordPromise(param) {
  return apiProcess(updatePassword, param);
}