import { updateDoNotStuff, getDoNotStuff, uploadDoNotStuffFile, downloadDoNotStuffFile } from '../../api/compSetting'
import apiProcess from '../../api/utility';
import _ from 'lodash';
import { versionUpdate } from '../dataProcess';

class CompDoNotStuff {
  constructor() {
    this.componentDoNotStuff = new Map();  // key - designId, notStuffInfo - []
  }

  saveDoNotStuff(designId, notStuffInfo = []) {
    this.componentDoNotStuff.set(designId, notStuffInfo);
  }

  getDoNotStuff = async (designId) => {
    if (!designId) {
      return {};
    }
    let obj = this.componentDoNotStuff.get(designId);
    if (!obj) {
      try {
        obj = await getCompDoNotStuff(designId);
        const data = obj && obj.length ? obj : [];
        this.saveDoNotStuff(designId, data);
      } catch (err) {
        this.saveDoNotStuff(designId, []);
        console.error(err);
      }
    }
    return obj
  }

  compareData = (designId, doNotStuffList = [], returnKey) => {
    let prevSetting = this.componentDoNotStuff.get(designId) || [];
    if (returnKey) {
      let newUnStuff = doNotStuffList.filter(it => !prevSetting.includes(it));
      let newStuff = prevSetting.filter(it => !doNotStuffList.includes(it));
      return { newUnStuff, newStuff, save: newStuff.length || newUnStuff.length }
    }
    if ((!prevSetting && doNotStuffList.length)
      || doNotStuffList.length !== prevSetting.length
      || !_.isEqual(doNotStuffList, prevSetting)) {
      return true
    }
    return false
  }

  updateDoNotStuffSetting = async (componentDoNotStuffs) => {
    try {
      await updateCompDoNotStuff(componentDoNotStuffs);
      componentDoNotStuffs.forEach(obj => {
        const { designId, doNotStuff } = obj;
        this.saveDoNotStuff(designId, doNotStuff);
      })
    } catch (error) {
      console.error(error)
    }
  }

  uploadFile = async ({ designId, file, type, compName, settingStore, tableStore }) => {
    try {
      const obj = await uploadCompDoNotStuffFile({ designId, file, type });
      let data = [];
      if (obj && obj.length) {
        data = obj.filter(item => compName.includes(item));
      }

      const { save, newUnStuff, newStuff } = await this.compareData(designId, data);
      this.updateDoNotStuffSetting([{ designId, doNotStuff: data }]);
      if (save && settingStore && settingStore.getVersion) {
        //update component setting version and re search vrm
        const settingVersion = await settingStore.getVersion(designId);
        const newSettingVersion = versionUpdate(settingVersion);
        await settingStore.updateVersion(designId, newSettingVersion, null, true);
      }
      if (save && tableStore && tableStore.updateTableByDoNotStuff) {
        await settingStore.updateTableByDoNotStuff({ pcbId: designId, newUnStuff, newStuff })
      }
      return data;
    } catch (error) {
      console.error(error)
      return error;
    }
  }

  downloadFile = async (designId) => {
    const txt = await downloadDoNotStuff(designId);
    return txt || null;
  }

  clearDoNotStuff(designId) {
    this.saveDoNotStuff(designId, null);
  }
}

function getCompDoNotStuff(designId) {
  return apiProcess(getDoNotStuff, designId);
}

function updateCompDoNotStuff(componentDoNotStuff) {
  return apiProcess(updateDoNotStuff, componentDoNotStuff);
}

function uploadCompDoNotStuffFile({ designId, file, type }) {
  return apiProcess(uploadDoNotStuffFile, { designId, file, type });
}

function downloadDoNotStuff(designId) {
  return apiProcess(downloadDoNotStuffFile, designId, false, false, true)
}

const compDoNotStuff = new CompDoNotStuff();

export default compDoNotStuff;