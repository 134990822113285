
import { defaultTreeItems } from '@/services/Rocky/helper/rockyTree';
import {
  UPDATE_PROJECT_MENU,
  EXPAND_MENU,
  PROJECT_MENU,
  LIBRARY_MENU,
  SAVE_OPEN_PROJECT_INFO,
  UPDATE_TREE_SELECTED_KEYS,
  UPDATE_VIEW_LIST,
  OPEN_CHANNEL,
  CLEAR_CURRENT_PROJECT,
  SAVE_CURRENT_CHANNEL_INFO,
  OPEN_PAGE_INFO,
  CHANGE_LAYOUT,
  TRASH_MENU,
  CLEAN_CURRENT_CHANNEL,
  UPDATE_REPORT_PROGRESS,
  UPDATE_REPORT_VISIBLE,
  UPDATE_REPORT_CONFIG,
  UPDATE_REPORT_MESSAGE,
  UPDATE_EXTRACTION_CONFIG,
  UPDATE_CHANNEL_COMPONENT_LIST,
  UPDATE_SSN_CHECK,
  DEBUG_MONITOR,
  UPDATE_PROJECT_INTERFACES_INFO,
  SHARE_MENU,
  UPDATE_EXPORT_PROGRESS,
  UPDATE_PCB_LAYOUT,
  UPDATE_PACKAGE_EXTRACTION_CONFIG,
  OPEN_PACKAGE_TREE,
  CLEAN_PACKAGE_CURRENT_CHANNEL,
  UPDATE_CURRENT_PACKAGE_ID,
  UPDATE_STACKUP_STATUS_LIST,
  UPDATE_CURRENT_LOADING_PCB,
  UPDATE_WAVEFORM_CONFIG,
  CLEAR_PCB_RELATED_DATA,
  UPDATA_DEFAULT_DECAP,
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { VERIFICATION } from '@/services/Rocky/constants';
import { OPEN_PROJECT } from '../../../Design/components/actionType';
import { SINGLE_PAGE_LAYOUT, PCB_ONLY } from '@/constants/layoutConstants';
import { PACKAGE_VERIFICATION, CARD_VERIFICATION, BMA_CHANNEL } from '../../../../constants/treeConstants';
import { CLOSE_CARD_CHANNEL } from '../card/actionTypes';
import { PROJECT_V1 } from '../../../../constants/projectVersion';

const defaultState = {
  viewList: [],
  treeItems: defaultTreeItems,
  currentProjectId: null,
  currentChannelId: null,
  layout: SINGLE_PAGE_LAYOUT,
  pcbLayout: PCB_ONLY,
  selectedKeys: [],
  expandedKeys: ['library', 'projects'],
  projectList: [],
  ibisList: [],
  spiceList: [],
  vectorList: [],
  pkgSpiceList: [],
  pkgTouchstoneList: [],
  ebdList: [],
  socketTouchstoneList: [],
  socketSpiceList: [],
  eyeMaskList: [],
  traceList: [],
  viaList: [],
  cardLibraryList: [],
  wireBondProfileList: [],
  customTouchstoneList: [],
  customSpiceList: [],
  decapTouchstoneList: [],
  decapSpiceList: [],
  openVerificationId: null,   // current select verification id
  pcbComponentsNets: [],
  reportProgress: 0,
  prelayoutProgress: 0,
  reportVisible: false,
  reportConfig: {
    channelIds: ['0'],
    language: 'english',
    templateId: '',
    custom: { guideValue: [], isXTable: false }
  },
  reportMessage: null,
  extraction: {},
  currentChannelComponents: [],
  contentType: "",
  ssnSelectPDNIds: [],
  VRMList: [],
  debugVRMMonitor: [],
  currentProjectInterfaceInfo: null,
  isOpenExtractionPanel: false,
  packageLayoutList: [],
  packageExtraction: {},
  currentPackageDesignId: null,
  openPackageVerificationId: null,
  stackupStatusList: [],
  currentLoadingPCBs: [],
  waveformConfig: null,
  projectVersion: PROJECT_V1,
  defaultDecap: {},
  DecapGeneric: [],
  customPostProcessList: [],
  ibisAmiList: [],
  packageCombineLoading: null
}

export const RockyProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_MENU:
      return {
        ...state
      }
    case EXPAND_MENU:
      return {
        ...state,
        expandedKeys: action.expandedKeys
      }
    case PROJECT_MENU:
      return {
        ...state,
        treeItems: action.treeItems,
        projectList: action.projectList ? action.projectList : state.projectList,
        packageLayoutList: action.packageLayoutList ? action.packageLayoutList : state.packageLayoutList
      }
    case LIBRARY_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems],
        ibisList: action.ibisList ? action.ibisList : state.ibisList,
        spiceList: action.spiceList ? action.spiceList : state.spiceList,
        vectorList: action.vectorList ? action.vectorList : state.vectorList,
        VRMList: action.VRMList ? action.VRMList : state.VRMList,
        pkgTouchstoneList: action.pkgTouchstoneList ? action.pkgTouchstoneList : state.pkgTouchstoneList,
        pkgSpiceList: action.pkgSpiceList ? action.pkgSpiceList : state.pkgSpiceList,
        eyeMaskList: action.eyeMaskList ? action.eyeMaskList : state.eyeMaskList,
        traceList: action.traceList ? action.traceList : state.traceList,
        viaList: action.viaList ? action.viaList : state.viaList,
        ebdList: action.ebdList ? action.ebdList : state.ebdList,
        socketTouchstoneList: action.socketTouchstoneList ? action.socketTouchstoneList : state.socketTouchstoneList,
        socketSpiceList: action.socketSpiceList ? action.socketSpiceList : state.socketSpiceList,
        cardLibraryList: action.cardLibraryList ? action.cardLibraryList : state.cardLibraryList,
        wireBondProfileList: action.wireBondProfileList ? action.wireBondProfileList : state.wireBondProfileList,
        customTouchstoneList: action.customTouchstoneList ? action.customTouchstoneList : state.customTouchstoneList,
        customSpiceList: action.customSpiceList ? action.customSpiceList : state.customSpiceList,
        pcbTouchstoneList: action.pcbTouchstoneList ? action.pcbTouchstoneList : state.pcbTouchstoneList,
        pcbSpiceList: action.pcbSpiceList ? action.pcbSpiceList : state.pcbSpiceList,
        onDieTouchstoneList: action.onDieTouchstoneList ? action.onDieTouchstoneList : state.onDieTouchstoneList,
        onDieSpiceList: action.onDieSpiceList ? action.onDieSpiceList : state.onDieSpiceList,
        pdnTouchstoneList: action.pdnTouchstoneList ? action.pdnTouchstoneList : state.pdnTouchstoneList,
        pdnSpiceList: action.pdnSpiceList ? action.pdnSpiceList : state.pdnSpiceList,
        decapTouchstoneList: action.decapTouchstoneList ? action.decapTouchstoneList : state.decapTouchstoneList,
        decapSpiceList: action.decapSpiceList ? action.decapSpiceList : state.decapSpiceList,
        extractionHfssOptionsList: action.extractionHfssOptionsList ? action.extractionHfssOptionsList : state.extractionHfssOptionsList,
        extractionSiwaveOptionsList: action.extractionSiwaveOptionsList ? action.extractionSiwaveOptionsList : state.extractionSiwaveOptionsList,
        patternLibraryList: action.patternLibraryList ? action.patternLibraryList : state.patternLibraryList,
        specificSpiceLibraryList: action.specificSpiceLibraryList ? action.specificSpiceLibraryList : state.specificSpiceLibraryList,
        userDefinedNetListList: action.userDefinedNetListList ? action.userDefinedNetListList : state.userDefinedNetListList,
        DecapGeneric: action.DecapGeneric ? action.DecapGeneric : state.DecapGeneric,
        customPostProcessList: action.customPostProcessList ? action.customPostProcessList : state.customPostProcessList,
        ibisAmiList: action.ibisAmiList ? action.ibisAmiList : state.ibisAmiList
      }
    case SAVE_OPEN_PROJECT_INFO:
      return {
        ...state,
        currentProjectId: action.id ? action.id : state.currentProjectId,
        currentProjectName: action.name ? action.name : state.currentProjectName,
        projectVersion: action.version ? action.version : state.projectVersion
      }
    case UPDATE_TREE_SELECTED_KEYS:
      return {
        ...state,
        selectedKeys: action.selectedKeys
      }
    case UPDATE_VIEW_LIST:
      return {
        ...state,
        viewList: action.viewList
      }
    case OPEN_CHANNEL:
      return {
        ...state,
        currentChannelId: action.channelId
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        currentProjectId: null,
        openVerificationId: null,
        currentChannelId: null,
        viewList: [],
        currentProjectInterfaceInfo: null,
        stackupStatusList: [],
        contentType: action.isBMA && state.contentType === BMA_CHANNEL ? state.contentType : VERIFICATION
      };
    // case CLEAR_PCB_RELATED_DATA:
    //   return {
    //     ...state,
    //     currentProjectInterfaceInfo: null
    //   }
    case SAVE_CURRENT_CHANNEL_INFO:
      return {
        ...state,
        currentChannelId: action.currentChannelId
      }
    case OPEN_PAGE_INFO:
      return {
        ...state,
        openVerificationId: action.info.verificationId ? action.info.verificationId : state.openVerificationId,
        PDNID: action.info.PDNID ? action.info.PDNID : state.PDNID,
        contentType: action.info.contentType ? action.info.contentType : state.contentType,
        currentChannelId: action.info.currentChannelId ? action.info.currentChannelId : state.currentChannelId
      }
    case CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      }
    case TRASH_MENU:
    case SHARE_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems]
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CLEAN_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannelId: null,
        openVerificationId: state.contentType !== PACKAGE_VERIFICATION ? null : state.openVerificationId,
        currentChannelComponents: [],
        ssnSelectPDNIds: []
      }
    case UPDATE_REPORT_PROGRESS:
      return {
        ...state,
        reportProgress: action.progress
      }
    case UPDATE_REPORT_VISIBLE:
      return {
        ...state,
        reportVisible: action.visible
      }
    case UPDATE_REPORT_CONFIG:
      return {
        ...state,
        reportConfig: action.reportConfig
      }
    case UPDATE_REPORT_MESSAGE:
      return {
        ...state,
        reportMessage: action.message
      }
    case UPDATE_EXTRACTION_CONFIG:
      return {
        ...state,
        extraction: action.extraction,
        isOpenExtractionPanel: action.isOpenExtractionPanel !== undefined ? action.isOpenExtractionPanel : state.isOpenExtractionPanel
      }
    case OPEN_PROJECT:
      return {
        ...state,
        currentProjectId: action.id,
      }
    case UPDATE_CHANNEL_COMPONENT_LIST:
      return {
        ...state,
        currentChannelComponents: action.componentList
      }
    case UPDATE_SSN_CHECK:
      return {
        ...state,
        ssnSelectPDNIds: action.ssnSelectPDNIds
      }
    case DEBUG_MONITOR:
      return {
        ...state,
        debugVRMMonitor: [...state.debugVRMMonitor, ...action.msg]
      }
    case UPDATE_PROJECT_INTERFACES_INFO:
      return {
        ...state,
        currentProjectInterfaceInfo: action.currentProjectInterfaceInfo
      };
    case UPDATE_EXPORT_PROGRESS:
      return {
        ...state,
        prelayoutProgress: action.progress
      }
    case UPDATE_PCB_LAYOUT:
      return {
        ...state,
        pcbLayout: action.pcbLayout
      }
    case UPDATE_PACKAGE_EXTRACTION_CONFIG:
      return {
        ...state,
        packageExtraction: action.packageExtraction
      }
    case OPEN_PACKAGE_TREE:
      return {
        ...state,
        currentPackageDesignId: action.packageDesignId
      }
    case CLEAN_PACKAGE_CURRENT_CHANNEL:
      return {
        ...state,
        currentPackageDesignId: null,
        openVerificationId: state.contentType === PACKAGE_VERIFICATION ? null : state.openVerificationId,
        contentType: state.contentType === PACKAGE_VERIFICATION ? VERIFICATION : state.contentType
      }
    case CLOSE_CARD_CHANNEL:
      return {
        ...state,
        contentType: state.contentType === CARD_VERIFICATION ? null : state.contentType,
      }
    case UPDATE_CURRENT_PACKAGE_ID:
      return {
        ...state,
        currentPackageDesignId: action.id
      }
    case UPDATE_STACKUP_STATUS_LIST:
      return {
        ...state,
        stackupStatusList: action.stackupStatusList || []
      }
    case UPDATE_CURRENT_LOADING_PCB:
      return {
        ...state,
        currentLoadingPCBs: action.currentLoadingPCBs
      }
    case UPDATE_WAVEFORM_CONFIG:
      return {
        ...state,
        waveformConfig: action.waveformConfig
      }
    case UPDATA_DEFAULT_DECAP:
      return {
        ...state,
        defaultDecap: action.defaultDecap
      }
    default: return state
  }
};