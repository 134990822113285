import React, { Component, isValidElement } from 'react';
import Table from '@/components/EditableTable';
import { Select } from 'antd';
import { getEdgesColumns, timeUnitList, getTitle, timeValueList, timeArrayList } from '../../../../services/Sierra/helper/WaveformResultHelper';
import { FALLING, RISING, HIGH, LOW, /* HOLD, SETUP, */ VHIGH, VLOW, VINH, VINL, OVERSHOOT, UNDERSHOOT } from '../../../../services/Result/Public/waveform/Mark/markConstant';
import { isMac } from '../../../../services/api/userAgent';
import { unitChange } from '../../../../services/helper/mathHelper';

const Option = Select.Option;
const timeList = ['u', 'n', 'p'];
class PostProcessEdgesContent extends Component {
  constructor() {
    super();
    this.state = {
      risingEdgeTableData: [],
      fallingEdgeTableData: [],
      title: '',
      tableData: [],
      minmaxDataTableData: [],
      timeUnit: '',
      columns: getEdgesColumns(false),
      minMaxColumns: getEdgesColumns('minMaxColumns'),
      selected: [],
      keyCode: null
    }
    this.ctrlKey = isMac() ? 91 : 17;
    this.shiftKey = 16;
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    const { currentPostProcessData, onClearSelected } = this.props;
    if (currentPostProcessData) {
      this.getPanelData(currentPostProcessData)
    }
    if (onClearSelected) {
      onClearSelected(this.clearSelected);
    }
    document.addEventListener('keydown', this.KeyDown, true);
    document.addEventListener('keyup', this.KeyUp, true);
  }
  componentDidUpdate = (prevProps) => {
    const { currentPostProcessData } = this.props;
    if (currentPostProcessData && currentPostProcessData.title && (!prevProps.currentPostProcessData || prevProps.currentPostProcessData.title !== currentPostProcessData.title)) {
      this.getPanelData(currentPostProcessData)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.KeyDown, true);
    document.removeEventListener('keyup', this.KeyUp, true);
  }

  KeyDown = (e) => {
    if ([this.ctrlKey, this.shiftKey].includes(e.keyCode)) {
      this.setState({
        keyCode: e.keyCode
      })
    }
  }

  KeyUp = (e) => {
    this.setState({
      keyCode: null,
    });
  }

  clearSelected = () => {
    this.setState({
      selected: []
    })
  }

  getPanelData = async (currentPostProcessData) => {
    const { edgesInfo, title, displayTitle, timeUnit, isCLK } = currentPostProcessData;
    let tableData = [], _minmaxDataTableData = []
    if (edgesInfo && edgesInfo.edges && edgesInfo.edges.length) {
      // for(let i=0;i<edgesInfo.edges.length)
      for (let info of edgesInfo.edges) {
        tableData.push(info)
      }
      const { minInfo = {}, maxInfo = {} } = edgesInfo
      _minmaxDataTableData = [{ index: "Min", ...minInfo || {} }, { index: "Max", ...maxInfo || {} }]
    }

    let _timeUnit = this.state.timeUnit
    if (timeUnit) {
      _timeUnit = timeUnit;
    }

    const newColumns = getEdgesColumns(false, isCLK);
    const minMaxColumns = getEdgesColumns('minMaxColumns', isCLK);
    newColumns.forEach((item) => {
      if (timeUnitList.includes(item.dataIndex)) {
        const title = getTitle(item.dataIndex)
        item.title = `${title} (${_timeUnit}s)`
      }
    })

    const oldTimeUnit = this.state.timeUnit;

    tableData.forEach((item, index) => { item.index = index + 1; })
    this.setState({
      title,
      displayTitle,
      tableData: JSON.parse(JSON.stringify(tableData)),
      timeUnit: _timeUnit,
      minmaxDataTableData: JSON.parse(JSON.stringify(_minmaxDataTableData)),
      columns: newColumns,
      minMaxColumns,
      selected: []
    }, () => {
      if (oldTimeUnit && timeList.includes(oldTimeUnit) && oldTimeUnit !== this.state.timeUnit) {
        this.timeChange(oldTimeUnit)
      }
    })
  }

  getValue = (number, isList, value, value2) => {
    if (!isList && !value) { return "" };
    if (isList && (!value || !value2)) { return [] }
    const _value = value / number;
    if (!isList) {
      return _value;
    }
    const _value2 = value2 / number;
    return [_value, _value2]
  }

  dataSelect = (record, index) => {
    const { selected: prevSelected, keyCode } = this.state

    let _selected = [...prevSelected];
    if (keyCode === this.ctrlKey) {
      _selected = [index]
    } else if (keyCode === this.shiftKey) {
      _selected = _selected.includes(index) ? _selected.filter(item => item !== index) : [..._selected, index]
    } else {
      const mirrorIndex = record.is_rising ? index + 1 : index - 1;
      _selected = prevSelected.includes(index) ? [] : [index, mirrorIndex];
    }
    this.setState({
      selected: _selected
    }, () => {
      let risingList = [], fallingList = [], anotherTimeList = [], measureList = [], shootList = [];
      const { selected, tableData, timeUnit } = this.state;
      const { currentPostProcessData } = this.props;
      const { isCLK } = currentPostProcessData;

      if (!selected.length) {
        this.props.clickPostProcessData({ timeList: [...fallingList, ...risingList], ...currentPostProcessData, timeUnit, rangeList: [...risingList, ...fallingList], display: false })
        return;
      }

      const edgesInfo = currentPostProcessData.edgesInfo;
      if (edgesInfo) {
        const VList = [VHIGH, VLOW, VINH, VINL];
        for (let key of VList) {
          const value = edgesInfo[key];
          if (!value && value !== 0) {
            continue;
          }
          const findIndex = measureList.findIndex(item => item.value === value);
          if (findIndex > -1) {
            if (measureList[findIndex].extra.sameType) {
              measureList[findIndex].extra.sameType.push(key)
            } else {
              measureList[findIndex].extra.sameType = [key]
            }
            continue
          }
          measureList.push({ value, type: key, extra: { sameType: [] } })
        }
      }

      for (let _index of selected) {
        const data = tableData[_index];

        if (!tableData[_index]) {
          continue
        }

        let overShootTime = [], underShootTime = [];
        const timeType = data.timeRising && data.timeRising.length ? RISING : FALLING
        if (data.timeRising && data.timeRising.length) {
          risingList = data.timeRising.map(item => { return { value: item, type: RISING } })
          data.timeRising[1] && overShootTime.push(data.timeRising[1])
          data.timeRising[0] && underShootTime.push(data.timeRising[0])
          if (isCLK) {
            data.high_time && anotherTimeList.push({ value: data.timeRising[1] + data.high_time, another: data.timeRising[1], type: HIGH })
            data.high_time && overShootTime.push(data.timeRising[1] + data.high_time)
          }/*  else {
            data.hold_time && anotherTimeList.push({ value: data.timeRising[0] - data.hold_time, another: data.timeRising[0], type: HOLD })
            data.setup_time && anotherTimeList.push({ value: data.timeRising[1] + data.setup_time, another: data.timeRising[1], type: SETUP })
            data.setup_time && overShootTime.push(data.timeRising[1] + data.setup_time)
          } */
        }
        if (data.timeFalling && data.timeFalling.length) {
          fallingList = data.timeFalling.map(item => { return { value: item, type: FALLING } })
          data.timeFalling[0] && overShootTime.push(data.timeFalling[0])
          data.timeFalling[1] && underShootTime.push(data.timeFalling[1])
          if (isCLK) {
            data.low_time && anotherTimeList.push({ value: data.timeFalling[1] + data.low_time, another: data.timeFalling[1], type: LOW })
          }/*  else {
            data.hold_time && anotherTimeList.push({ value: data.timeFalling[0] - data.hold_time, another: data.timeFalling[0], type: HOLD })
            data.setup_time && anotherTimeList.push({ value: data.timeFalling[1] + data.setup_time, another: data.timeFalling[1], type: SETUP })
          } */
        }

        if (data[OVERSHOOT]) {
          let _value = data[OVERSHOOT];
          if (_value > 1) {
            _value = _value.toFixed(2)
          } else {
            _value = _value.toPrecision(2)
          }
          if (!shootList.find(item => item.value === _value && item.type === OVERSHOOT)) {
            _value = Number(_value)
            overShootTime.length && shootList.push({ value: _value + edgesInfo[VHIGH], type: OVERSHOOT, another: edgesInfo ? edgesInfo[VHIGH] : _value, shootTime: overShootTime, extra: { timeType } })
          }
        }
        if (data[UNDERSHOOT]) {
          let _value = data[UNDERSHOOT];
          if (_value > 1) {
            _value = _value.toFixed(2)
          } else {
            _value = _value.toPrecision(2)
          }
          if (!shootList.find(item => item.value === _value && item.type === UNDERSHOOT)) {
            _value = Number(_value)
            underShootTime.length && shootList.push({ value: edgesInfo[VLOW] - _value, type: UNDERSHOOT, another: edgesInfo ? edgesInfo[VLOW] : _value, shootTime: underShootTime, extra: { timeType } })
          }
        }
      }
      this.props.clickPostProcessData({ timeList: [...fallingList, ...risingList, ...anotherTimeList], shootList, measureList, ...currentPostProcessData, timeUnit, rangeList: [...risingList, ...fallingList, ...anotherTimeList], display: true });
    })
  }

  getYScroll = () => {
    const contentRoot = document.getElementById('waveFrom-post-process-content-id') || {}
    if (contentRoot) {
      const height = contentRoot.clientHeight;
      return !isNaN(height) ? height - 160 : 200
    }
    return 200
  }

  timeChange = (value) => {
    const { timeUnit, tableData, minmaxDataTableData, columns } = this.state;
    const oldTimeUnit = `${timeUnit}s`;
    const newTimeUnit = `${value}s`;
    let newColumns = [...columns];
    newColumns.forEach((item) => {
      if (timeUnitList.includes(item.dataIndex)) {
        const title = getTitle(item.dataIndex)
        item.title = `${title} (${newTimeUnit})`
      }
    })

    let newTableData = [...tableData];
    newTableData.forEach(item => {
      timeValueList.forEach(key => {
        if (item[key]) {
          item[key] = unitChange({ num: item[key], oldUnit: oldTimeUnit, newUnit: newTimeUnit }).number
        }
      })
      timeArrayList.forEach(key => {
        if (item[key] && Array.isArray(item[key])) {
          item[key].forEach((v, index) => {
            item[key][index] = unitChange({ num: v, oldUnit: oldTimeUnit, newUnit: newTimeUnit }).number
          })
        }
      })
    })

    let newMinMaxData = [...minmaxDataTableData];
    newMinMaxData.forEach(item => {
      timeUnitList.forEach(key => {
        if (item[key]) {
          item[key] = unitChange({ num: item[key], oldUnit: oldTimeUnit, newUnit: newTimeUnit }).number
        }
      })
    })

    this.setState({
      timeUnit: value,
      columns: newColumns,
      tableData: newTableData,
      minmaxDataTableData: newMinMaxData
    })
  }


  render() {
    const { title, tableData, displayTitle, minmaxDataTableData, columns, minMaxColumns, selected, timeUnit } = this.state;
    const yScroll = this.getYScroll();
    return <div className='waveFrom-post-process-content' id="waveFrom-post-process-content-id">
      <div className='post-process-content-title'>
        {displayTitle && isValidElement(displayTitle) ? displayTitle : title}
        <span className='post-process-content-title-divide'>|</span>
        <span className='post-process-content-title-unit'>Time Unit:</span>
        <Select
          size="small"
          value={timeUnit}
          onChange={this.timeChange}
        >
          {timeList.map(t => <Option key={t}>{t}s</Option>)}
        </Select>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.index}
        dataSource={tableData}
        bordered
        size="small"
        scroll={{ y: yScroll }}
        className="post-process-panel-data-table"
        onRow={(record, index) => {
          return {
            onClick: e => this.dataSelect(record, index),
          };
        }}
        rowClassName={(record, index) => selected.includes(index) ? 'post-process-panel-data-selected-row post-process-panel-data-row' : 'post-process-panel-data-row'}
      />
      <Table
        columns={minMaxColumns}
        rowKey={(record) => record.index}
        dataSource={minmaxDataTableData}
        bordered
        size="small"
        className="post-process-panel-compare-table post-process-panel-edge-compare-table"
        showHeader={false}
      />
    </div>
  }
}

export default PostProcessEdgesContent;