import React from 'react';
import { EXTRACTION_PCB_MODEL } from '../constants';
import { getNetPinList } from '../../../services/helper/componentsHelper/componentData';

export function getPdnErrorCheck({ pdnContent, currentProjectPackages, designId, SPIMNames }) {
  const SimConfig = pdnContent.SimConfig ? pdnContent.SimConfig : {};
  const { AC = 1, DC = 0 } = SimConfig;
  if (!pdnContent) {
    return;
  }
  let error = [];
  let check = [];
  if (!currentProjectPackages || currentProjectPackages.length === 0) {
    error.push(<p style={{ margin: 0 }}>Does not found chip.</p>);
  }
  const chip = currentProjectPackages && currentProjectPackages.length > 0 ? currentProjectPackages[0].chip : null;
  const PowerNets = [...pdnContent.PowerNets];
  const ReferenceNets = [...pdnContent.ReferenceNets];
  const Config = { ...pdnContent.Config };
  let VRM;
  if (Array.isArray(pdnContent.VRM)) {
    VRM = [...pdnContent.VRM];
  } else {
    VRM = { ...pdnContent.VRM };
  }

  const components = [...pdnContent.Components];

  if (PowerNets.length < 1) {
    check.push(<p style={{ margin: 0 }}>[Power Domain] No power nets set.</p>);
  }

  if (ReferenceNets.length < 1) {
    check.push(<p style={{ margin: 0 }}>[Power Domain] No reference nets set.</p>);
  }
  if (Array.isArray(VRM)) {
    if (VRM.length < 1) {
      error.push(<p style={{ margin: 0 }}>[VRM] VRM is not set.</p>);
    }
    for (let i = 0; i < VRM.length; i++) {
      const item = VRM[i];
      if (item.powerPin.length < 1) {
        error.push(<p style={{ margin: 0 }}>[VRM] No equivalent power pin is set.</p>);
      }

      if (item.groundPin.length < 1) {
        error.push(<p style={{ margin: 0 }}>[VRM] No equivalent ground pin is set.</p>);
      }

      if (DC && i === 0 && !item.voltage && item.voltage !== 0) {
        error.push(<p style={{ margin: 0 }}>[Power Domain] Nominal voltage is not set.</p>);
      }

      if (item.model && Object.keys(item.model).length > 0) {
        if (!item.model.name && Config && !Config.VR_FSW) {
          let vrmModelMsgName = '';
          if (item.powerPin.length > 0) {
            vrmModelMsgName += item.powerPin[0].comp;
          };
          if (item.groundPin.length > 0) {
            vrmModelMsgName += ' ' + item.groundPin[0].comp;
          };
          if (vrmModelMsgName) {
            error.push(<p style={{ margin: 0 }}>[VRM] {vrmModelMsgName} - VRM model is not set.</p>);
          };
        }
      }

      //Find the pins of the component of the Eq.Pwr Pin connected to the Power nets
      if (ReferenceNets.length && designId) {
        for (let groupPin of item.groundPin) {
          const netPinList = getNetPinList(ReferenceNets, designId, groupPin.comp);
          if (!netPinList.length) {
            error.push(<p style={{ margin: 0 }}>[VRM] No equivalent ground pin [{groupPin.comp}] not connected to any reference nets.</p>);
          }
        }
      }
    }
  }

  let existChip = false, multiDecapModelsParts = [];

  if (components.length > 0) {
    components.some(item => {
      if (item.usage === 'Chip') {
        existChip = true;
        if (!EXTRACTION_PCB_MODEL) {
          if (!item.pkg || (item.pkg && (!item.pkg.name || !item.pkg.type))) {
            error.push(<p style={{ margin: 0 }}>Does not set the model.</p>);
            return true;
          }
        }
        const findSPIM = SPIMNames.find(it => item.pkg && item.pkg.id && it.id === item.pkg.id);
        if (!findSPIM && item.pkg && item.pkg.id) {
          error.push(<p style={{ margin: 0 }}>Library SPIM <span className="font-bold">{item.pkg.name}</span> dost not exist.</p>);
          return true;
        }
      }
      return false;
    })
    if (AC) {
      components.some((item, index) => {
        if (item.usage === 'Cap') {
          if (item.models && item.models.length) {
            for (let _model of item.models) {
              if (!_model || !_model.name) {
                error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part}</span>- Decap model{_model.editName ? ` ${_model.editName}` : ""} is not set.</p>);
                return true;
              }
              else if (_model.type === "spice") {
                if (_model && _model.name && _model.id) {
                  if (!_model.subcktName) {
                    error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part}</span>- ${_model.name} Subckt is not set.</p>);
                    return true;
                  }
                }
              }
            }
            if (item.models.length > 1) {
              multiDecapModelsParts.push(item.part);
            }
          } else {
            error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part}</span>- Decap model is not set.</p>);
            return true;
          }
        }
        return false;
      });
    }
  }
  multiDecapModelsParts = [...new Set(multiDecapModelsParts)];
  if (AC && multiDecapModelsParts.length > 1) {
    error.push(<p style={{ margin: 0 }}>
      <span className="font-bold">
        [Decap models] - Only one decap part is allowed to specify multiple decap models.
      </span>
    </p>)
  }

  if (AC && multiDecapModelsParts.length && pdnContent.Optimization && pdnContent.Optimization.minimizeDecap) {
    error.push(<p style={{ margin: 0 }}>
      <span className="font-bold">
        [Optimization] - The optimization must be turned off when multiple decap model is present.
      </span>
    </p>)
  }

  if (!existChip && chip) {
    error = [...check, <p style={{ margin: 0 }}>"{chip}" is not found in power nets.</p>, ...error];
  } else {
    error = [...check, ...error];
  }
  if (error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }

}