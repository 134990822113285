import cookie from 'react-cookies';

export default function getDevelopMode() {
  const develop = cookie.load('debugMode');
  if (develop === 'false') {
    return false;
  } else {
    return true;
  }
}

export function getConfigMode() {
  const config = cookie.load('Config');
  if (!config) {
    return false
  } else {
    return config;
  }
}

export function getTranslationMode() {
  const translationMode = cookie.load('translationMode');
  if (!translationMode) {
    return 'OFF'; // true - PCB Processing
  } else {
    if (translationMode === 'ON') {
      return 'ON'; // false - Aurora
    } else {
      return 'OFF';
    }
  }
}

export function getFastPISIWEMode() {
  const mode = cookie.load('UseSiWaveSolverExplicitly');
  if (!mode) {
    return 1;
  } else if (mode === 'off') {
    return 0;
  } else {
    return 1;
  }
}

export function getFastPICouplingMinFreq() {
  const mode = cookie.load('couplingMinFreq');
  if (!mode) {
    return '3.5MHz'
  } else {
    return mode
  }
}