const TYPE = 'ANDES';
export const START_ANDES_VERIFICATION = `${TYPE}/start_andes_verification`;
export const CHANGE_VERIFICATION_LIST = `${TYPE}/change_verification_list`;
export const UPDATE_ANDES_PROGRESS = `${TYPE}/update_pdn_progress`;
export const GET_SINGLE_MONITOR = `${TYPE}/get_single_monitor`;
export const SINGLE_VERIFY_INFO = `${TYPE}/single_verify_info`;
export const CLEAN_SINGLE_PROGRESS = `${TYPE}/clean_single_progress`;
export const UPDATE_SINGLE_MONITOR = `${TYPE}/update_single_monitor`;
export const CLEAN_MONITOR = `${TYPE}/clean_monitor`;
export const RUNNING_SHOW = `${TYPE}/running_show`;
export const CANCEL_WORKFLOW = `${TYPE}/cancel_workflow`;
export const CANCEL_SHOW = `${TYPE}/cancel_show`;
export const CHANGE_UPLOAD_MES = `${TYPE}/change_upload_mes`;
export const DEBUG_VERIFY = `${TYPE}/debug_verify`;
export const RESULT_EXIST = `${TYPE}/result_exist`;
export const UPDATE_SIMULATION_MSG = `${TYPE}/update_simulation_msg`;
export const VERIFICATION_FLOW = `${TYPE}/verification_flow`;
export const SAVE_CURRENT_LOG = `${TYPE}/save_current_log`;
export const GET_CURRENT_LOG = `${TYPE}/get_current_log`;
export const WAITING_INDEX = `${TYPE}/waiting_index`;
export const UPDATE_ERROR_CHECK_LIST = `${TYPE}/update_error_check_list`;
export const KILL_WORKFLOW = `${TYPE}/kill_workflow`;
export const UPDATE_PRE_PROCESS_LOG = `${TYPE}/update_pre_progress_log`;
export const GET_INTERFACE_MONITOR = `${TYPE}/get_interface_monitor`;
export const CHANGE_VERIFY_STATUS = `${TYPE}/change_verify_status`;
export const CANCEL_VERIFICATION_WORKFLOW = `${TYPE}/cancel_verification_workflow`;
export const GET_CURRENT_PROFILE = `${TYPE}/get_current_profile`;
export const SAVE_CURRENT_PROFILE_LOG = `${TYPE}/save_current_profile`;
