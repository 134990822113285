import { getPartByProject } from '../../api/Cascade/project';
import { getComponentSetting, updateComponentSetting } from '../../api/compSetting'
import { versionUpdate } from '../../helper/dataProcess';
import projectDesigns from '../../helper/projectDesigns';
import apiProcess from '../../api/utility';
import compTableHelper from '../helper/compTableHelper';
import compPinMap from './compPinMap';
import { CascadeCompRLCPrefixLib, initDiscreteBuckConverter, getMultiPortResPart } from './setupData';
import debounce from '../../helper/debounceFn';
import designConstructor from '../../helper/designConstructor';
import auroraDBJson from '../../Designs/auroraDbData';

class CompSettingHelper {
  constructor() {
    this.componentSetting = new Map();  // key - designId, value - { compPrefixLib, version }
  }

  saveSetting(designId, setting) {
    this.componentSetting.set(designId, setting);
  }

  getSetting = async ({ designId, updateLibraryMenu }) => {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (!designId || isPreLayout) {
      return {};
    }
    let setting = this.componentSetting.get(designId);
    if (!setting) {
      try {
        setting = await this.getSettingFromBackend({ designId, updateLibraryMenu });
      } catch (err) {
        console.error(err);
      }
    }
    return setting
  }

  getSettingFromBackend = async ({ designId, updateLibraryMenu }) => {
    let setting = await getCompPrefixLib(designId);
    let compPrefixLib = new CascadeCompRLCPrefixLib(setting.compPrefixLib || {});
    let init = setting.init || {};
    let save = false;

    if (!auroraDBJson.checkAuroraJson(designId) && ['discreteBuckConverter', 'multiPortRes'].some(item => !init[item])) {
      await auroraDBJson.getAuroraJson(designId, { compPrefixLib })
    }

    if (!init.discreteBuckConverter) {
      const newConverter = await initDiscreteBuckConverter({ compPrefixLib, designId, updateLibraryMenu });
      compPrefixLib = new CascadeCompRLCPrefixLib({ ...compPrefixLib, discreteBuckConverter: newConverter } || {})
      init['discreteBuckConverter'] = true;
      save = true;
    }
    if (!init.multiPortRes) {
      const multiPort = getMultiPortResPart(designId);
      compPrefixLib = new CascadeCompRLCPrefixLib({ ...compPrefixLib, multiPortRes: multiPort } || {})
      init['multiPortRes'] = true;
      save = true;
    }

    setting = {
      version: setting.version || '0.0.1',
      compPrefixLib,
      init
    }
    this.saveSetting(designId, setting);

    if (save) {
      this.updateSetting([{ designId, componentSetting: setting }], 'cascadeGetSetting')
    }

    return setting
  }

  getPrefixLib = async (designId) => {
    const setting = await this.getSetting({ designId });
    return setting && setting.compPrefixLib ? setting.compPrefixLib : {};
  }

  getPrefixLibFromMap = (designId) => {
    let setting = this.componentSetting.get(designId);
    return setting && setting.compPrefixLib ? setting.compPrefixLib : {}
  }

  getVersion = async (designId) => {
    const setting = await this.getSetting({ designId });
    return setting && setting.version ? setting.version : '0.0.1';
  }

  updateVersion = async (designId, version, compPrefixLib, save = true) => {
    const setting = await this.getSetting({ designId });
    if (save) {
      this.updateSetting([{ designId, componentSetting: { ...setting, compPrefixLib: compPrefixLib ? compPrefixLib : setting.compPrefixLib, version } }], 'cascadeVersionSetting')
    } else {
      const _componentSetting = { ...setting, compPrefixLib: compPrefixLib ? compPrefixLib : setting.compPrefixLib, version };
      this.saveSetting(designId, _componentSetting);
    }
  }

  updateSetting(componentSettings, debuouceKey = 'cascadeUpdateSetting') {
    try {
      debounce(() => {
        const _componentSettings = [], designIds = [];
        componentSettings.forEach(setting => {
          const { designId, componentSetting } = setting;
          const _setting = this.componentSetting.get(designId) || {};
          const _componentSetting = { ..._setting, ...componentSetting };
          _componentSettings.push({ designId, componentSetting: _componentSetting })
          this.saveSetting(designId, _componentSetting);
          auroraDBJson.setComponentsType(designId, _componentSetting);
          designIds.push(designId);

        })
        updateCompPrefixLib(_componentSettings).then(res => {
          designIds.forEach(designId => {
            compTableHelper.getTable(designId);
            compPinMap.getPinMap(designId, true);
          })
        })
      }, 500, false, debuouceKey)()
    } catch (error) {
      console.error(error)
    }
  }

  clearSetting(designId) {
    this.saveSetting(designId, null);
  }

  compareSetting(designId, setting) {
    let prevSetting = this.componentSetting.get(designId);
    let compPrefixLib = prevSetting ? prevSetting.compPrefixLib : {};
    return compareCompPrefixLib(compPrefixLib, setting)
  }

  applyAllSetting = async (pcbId, projectId, COMP_PREFIX_LIB) => {
    const componentSettings = await applyCompPrefixLib(pcbId, projectId, COMP_PREFIX_LIB);
    if (componentSettings && componentSettings.length) {
      this.updateSetting(componentSettings, 'cascadeApplySetting')
    }
  }
}

function getCompPrefixLib(designId) {
  return apiProcess(getComponentSetting, { designId });
}

function updateCompPrefixLib(componentSettings) {
  return apiProcess(updateComponentSetting, componentSettings);
}

function getAllPartNames(projectId) {
  return apiProcess(getPartByProject, projectId)
}

function compareCompPrefixLib(prevSetting = {}, setting) {
  const keys = Object.keys(setting);
  for (let key of keys) {
    if (!prevSetting[key] || prevSetting[key].length !== setting[key].length) {
      return true;
    } else {
      for (let item of setting[key]) {
        if (key !== 'discreteBuckConverter') {
          if (!prevSetting[key].includes(item)) {
            return true;
          }
        } else {
          for (let i = 0; i < setting[key].length; i++) {
            if (!prevSetting[key][i]) {
              return true;
            }

            if (!prevSetting[key][i].every(p => setting[key][i].includes(p))) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
}

async function applyCompPrefixLib(pcbId, projectId, COMP_PREFIX_LIB) {
  const partNames = await getAllPartNames(projectId);
  const designList = projectDesigns.getAvailablePCBs(projectId).filter(item => item.id !== pcbId);
  const componentSettings = [];
  for (let design of designList) {
    const partName = partNames.find(p => p.designId === design.id);
    if (partName) {
      const {
        linearSwitch = [],
        linearRegulator = [],
        switchingRegulator = [],
        specialized = [],
        powerSwitch = []
      } = COMP_PREFIX_LIB;
      const _partName = [...linearSwitch, ...linearRegulator, ...switchingRegulator, ...specialized, ...powerSwitch];
      if (_partName.length && _partName.every(net => partName.parts.includes(net))) {
        const setting = await getCompPrefixLib(design.id);
        const version = versionUpdate(setting.version)
        componentSettings.push({ designId: design.id, componentSetting: { compPrefixLib: COMP_PREFIX_LIB, version } })
      }
    }
  }
  return componentSettings
}

const componentSetting = new CompSettingHelper();

export default componentSetting;

export {
  compareCompPrefixLib
}