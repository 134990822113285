import {
  START_ANDES_VERIFICATION,
  CHANGE_VERIFICATION_LIST,
  UPDATE_ANDES_PROGRESS,
  GET_SINGLE_MONITOR,
  SINGLE_VERIFY_INFO,
  CLEAN_SINGLE_PROGRESS,
  UPDATE_SINGLE_MONITOR,
  CLEAN_MONITOR,
  RUNNING_SHOW,
  CANCEL_SHOW,
  CANCEL_WORKFLOW,
  DEBUG_VERIFY,
  RESULT_EXIST,
  UPDATE_SIMULATION_MSG,
  VERIFICATION_FLOW,
  SAVE_CURRENT_LOG,
  GET_CURRENT_LOG,
  WAITING_INDEX,
  UPDATE_ERROR_CHECK_LIST,
  UPDATE_PRE_PROCESS_LOG,
  GET_INTERFACE_MONITOR,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_CURRENT_PROFILE,
  SAVE_CURRENT_PROFILE_LOG
} from './actionTypes';

export const startAndesVerification = (verificationIds) => ({
  type: START_ANDES_VERIFICATION,
  verificationIds
});

export const changeVerificationList = (simulateKeys) => ({
  type: CHANGE_VERIFICATION_LIST,
  simulateKeys
});

export const updateProgress = ({ verificationId, progress }) => ({
  type: UPDATE_ANDES_PROGRESS,
  verificationId,
  progress
});

export const getSingleMonitor = (workflowId, verificationId) => ({
  type: GET_SINGLE_MONITOR,
  workflowId,
  verificationId
});

export const singleVerifyInfo = ({ workflowId, verificationId }) => ({
  type: SINGLE_VERIFY_INFO,
  workflowId,
  verificationId
});

export const cleanSingleProgress = (verificationId) => ({
  type: CLEAN_SINGLE_PROGRESS,
  verificationId,
});

export const updateSingleMonitor = ({ workflowId, monitor, verificationId }) => ({
  type: UPDATE_SINGLE_MONITOR,
  workflowId,
  monitor,
  verificationId
});

export const cleanMonitor = (verificationId) => ({
  type: CLEAN_MONITOR,
  verificationId,
});

export const runningShow = (status) => ({
  type: RUNNING_SHOW,
  status,
});

export const cancelWorkflow = (workflowId) => ({
  type: CANCEL_WORKFLOW,
  workflowId,
});

export const cancelShow = (status) => ({
  type: CANCEL_SHOW,
  status,
});

export const debugVerify = (step, verificationId) => ({
  type: DEBUG_VERIFY,
  step,
  verificationId
});

export const existResult = (existResult) => ({
  type: RESULT_EXIST,
  existResult
});

export const updateSimulationMsg = ({ msg, verificationId }) => ({
  type: UPDATE_SIMULATION_MSG,
  msg,
  verificationId
});

export const verificationFlow = ({ verificationId, interfaceStatus }) => ({
  type: VERIFICATION_FLOW,
  interfaceStatus,
  verificationId
});

export const saveCurrentLog = (log) => ({
  type: SAVE_CURRENT_LOG,
  log
});

export const getCurrentLog = (verificationId) => ({
  type: GET_CURRENT_LOG,
  verificationId
});

export const waitingIndexAction = (waitingQueue) => ({
  type: WAITING_INDEX,
  waitingQueue
});

export const updateErrorCheckList = (andesInfoErrorCheck) => ({
  type: UPDATE_ERROR_CHECK_LIST,
  andesInfoErrorCheck
});

export const updatePreProcessLog = (preProcessLog) => ({
  type: UPDATE_PRE_PROCESS_LOG,
  preProcessLog
});

export const getInterfaceMonitor = (verificationId) => ({
  type: GET_INTERFACE_MONITOR,
  verificationId
});

export const cancelVerificationWorkflow = (verificationId) => ({
  type: CANCEL_VERIFICATION_WORKFLOW,
  verificationId,
});

export const getCurrentProfile = (verificationId) => ({
  type: GET_CURRENT_PROFILE,
  verificationId
});

export const saveCurrentProfileLog = (profile) => ({
  type: SAVE_CURRENT_PROFILE_LOG,
  profile
})