import { Extraction, SIwaveInfo, HFSSInfo } from './IntegratedChannel';
import { numExponentialFormat } from '@/services/helper/numberHelper';

const SIWAVE = 'SIwave';

function updateExtractionInfo(extraction = {}) {
  let newExtraction = {}
  if (!extraction || !Object.keys(extraction)) {
    newExtraction = new Extraction();
  } else {
    newExtraction = JSON.parse(JSON.stringify(extraction))
  }
  const { type, siwave, hfss } = extraction;
  const meshFrequency = numExponentialFormat(Math.min(newExtraction.maxFreq, '50e9'))

  if (type === SIWAVE) {
    newExtraction.siwave = siwave ? siwave : new SIwaveInfo(meshFrequency);
    if (extraction.hybrid) {
      newExtraction.hfss = hfss ? hfss : new HFSSInfo();
    }
  } else {
    newExtraction.hfss = hfss ? hfss : new HFSSInfo(newExtraction.maxPasses, newExtraction.maxDelta, meshFrequency);
  }

  delete newExtraction.maxPasses;
  delete newExtraction.maxDelta;
  return newExtraction
}

export {
  updateExtractionInfo
}