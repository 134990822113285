import { Delaunay } from "d3-delaunay";
import { default as printf } from 'printf';
import { getThicknessScale } from "../Stackup";
import NP from 'number-precision';

const ANGLE_TOL = 0.01; // angle tolerance
const SCALE_TOL = 0.01; // scale tolerance
const MAX_SCALE = 2; // maximum scale
const MAX_SCALE2 = MAX_SCALE * MAX_SCALE;

const MAX_SIMILAR = 400; // limit the number of pairs of similar triangle in mesh comparison
const MAX_HIT_RATIO = 4; // ratio between the matched triangles comparing with the most probable transformation
const MAX_TRANSFORM = 20; // maximum number of transformations to test

const RAD_TO_DEG = 180 / Math.PI;
const DEG_TO_RAD = Math.PI / 180;

class Transformation {
  constructor(shiftX = 0.0, shiftY = 0.0, rotation = 0.0, flipX = false, scale = 1.0, log) {
    this.rotation = rotation;
    this.flipX = flipX;
    this.scale = scale;
    /* Transformation matrix
              shift       rotate         scale   flipX
        [x2] = [dx] + [cos(a) -sin(a)] * [s 0] * [f 0] * [x]
        [y2]   [dy]   [sin(a)  cos(a)]   [0 s]   [0 1]   [y]
    */
    const f = flipX ? -1 : 1;
    const sinRot = Math.sin(rotation * DEG_TO_RAD),
      cosRot = Math.cos(rotation * DEG_TO_RAD);
    this.cx = [cosRot * scale * f, -sinRot * scale];
    this.cy = [sinRot * scale * f, cosRot * scale];
    this.dx = shiftX;
    this.dy = shiftY;
    this.log = log;
    Array.isArray(this.log) && this.log.push(`sinRot(${sinRot}), cosRot(${cosRot}), cx(${this.cy}), cy(${this.cy}), dx(${this.dx}), dy(${this.dy})`);
  }

  key(tol) {
    return [this.rotation / ANGLE_TOL, this.flipX ? 1 : 0, this.scale / SCALE_TOL, this.dx / tol, this.dy / tol]
      .map(Math.round).toString();
  }

  toString() {
    return `shift = (${this.dx}, ${this.dy}), rotation = ${this.rotation}, scale = ${this.scale}, flip = ${this.flipX}`
  }

  transform(points) {
    // scale, flip, rotate, shift
    return points.map(p =>
      [this.dx + this.cx[0] * p[0] + this.cx[1] * p[1],
      this.dy + this.cy[0] * p[0] + this.cy[1] * p[1]]
    );
  }

  static fromSegments(points, flipX) {
    /** Find the transformation from two segments
       points(Array(8)): 4 points of the two segments 
       flipX(boolean): whether the x coordinate shold be flipped
    */
    let [x1, y1, x2, y2, x3, y3, x4, y4] = points.flat();
    if (flipX) {
      x1 = -x1;
      x2 = -x2;
    }
    const x12 = x2 - x1,
      y12 = y2 - y1,
      x34 = x4 - x3,
      y34 = y4 - y3;
    // crsProd = L12 * L34 * sin(a)
    // dotProd = L12 * L34 * cos(a)
    // L34 / L12 = scale
    // x3 = x1 * scale * cos(a) - y1 * scale * sin(a) + dx = x1 * dotProd / L12^2 - y1 * crsProd / L12^2 + dx
    // y3 = x1 * scale * sin(a) + y1 * scale * cos(a) + dy = x1 * crsProd / L12^2 + y1 * dotProd / L12^2 + dy
    const crsProd = x12 * y34 - x34 * y12,
      dotProd = x12 * x34 + y12 * y34;
    const oneOverL12sq = 1 / (x12 * x12 + y12 * y12);
    const scaleRaw = Math.sqrt((x34 * x34 + y34 * y34) * oneOverL12sq);
    const scale = Math.round(scaleRaw / SCALE_TOL) * SCALE_TOL;
    const rotationRaw = Math.atan2(crsProd, dotProd) * RAD_TO_DEG;
    const rotation = Math.round(rotationRaw / ANGLE_TOL) * ANGLE_TOL;
    const dx = x3 - (x1 * dotProd - y1 * crsProd) * oneOverL12sq,
      dy = y3 - (x1 * crsProd + y1 * dotProd) * oneOverL12sq;
    return new Transformation(dx, dy, rotation, flipX, scale);
  }
} // class Transformation


const TRI_NEXT = [1, 2, 0];
const TRI_PREV = [2, 0, 1];
class TriInfo {
  constructor(points, tri) {
    // calculate the angles in the triangle
    //                n2
    //                o
    //               / \
    //              /   \
    //          e1 /     \ e0
    //            /       \
    //           /         \
    //       n0 o-----------o n1
    //                e2
    const dx = TRI_NEXT.map((iN, i) => points[tri[iN]][0] - points[tri[i]][0]),
      dy = TRI_NEXT.map((iN, i) => points[tri[iN]][1] - points[tri[i]][1]);
    this.edgeLenSq = TRI_NEXT.map(i => dx[i] * dx[i] + dy[i] * dy[i]);
    const crssProd = -dx[0] * dy[2] + dx[2] * dy[0]; // the cross products are the same for all angles
    this.angles = TRI_PREV.map((iP, i) => -dx[i] * dx[iP] - dy[i] * dy[iP])  // take the cross product
      .map(dotP => Math.atan2(crssProd, dotP) * RAD_TO_DEG)  // find the angle in degree
      .map(ang => Math.round(ang / ANGLE_TOL));  // round by the tolerance
    const sortedAngles = this.angles.map((v, i) => ({ v, i })).sort((a1, a2) => a2.v - a1.v);
    this.ind = sortedAngles.map(s => s.i);
    this.key = sortedAngles.map(s => s.v).toString();
  }

  get maxEdgeLenSq() {
    return this.edgeLenSq[this.ind[0]];
  }

  match(tInfo) {
    let result = [];
    if (this.key != tInfo.key) {
      return result;
    }

    // loop through the angles
    const i1 = this.ind[0];
    for (const i2 of tInfo.ind) {
      if (this.angles[i1] != tInfo.angles[i2]) {
        break;
      }

      if (tInfo.edgeLenSq[i2] > MAX_SCALE2 * this.edgeLenSq[i1] ||
        this.edgeLenSq[i1] > MAX_SCALE2 * tInfo.edgeLenSq[i2]) {
        break;
      }

      const indNode1 = [TRI_NEXT[i1], TRI_PREV[i1]];
      if (this.angles[TRI_NEXT[i1]] == tInfo.angles[TRI_NEXT[i2]]) {
        const indNode2 = [TRI_NEXT[i2], TRI_PREV[i2]];
        result.push([indNode1, indNode2, false]);
      }
      if (this.angles[TRI_NEXT[i1]] == tInfo.angles[TRI_PREV[i2]]) {
        // flip
        const indNode2 = [TRI_PREV[i2], TRI_NEXT[i2]];
        result.push([indNode1, indNode2, true]);
      }
    }
    return result;
  } // match
} // class TriInfo


class Mesh {
  constructor(points) {
    const { triangles } = new Delaunay(points.flat());
    this.points = points;
    this.numTri = triangles.length / 3;
    this.triangles = [...Array(this.numTri).keys()] // d3 Delaunay triangles are clock-wise. Change to counter-clock-wise
      .map(iTri => [triangles[iTri * 3], triangles[iTri * 3 + 2], triangles[iTri * 3 + 1]]);
    this.triMap = {};
    this.triInfo = this.triangles.map((tri, iTri) => {
      const tInfo = new TriInfo(points, tri);
      if (tInfo.key in this.triMap) {
        this.triMap[tInfo.key].push(iTri);
      } else {
        this.triMap[tInfo.key] = [iTri];
      }
      return tInfo;
    });
  }

  matchWith(mesh2) {
    // Map the coordinates to another set of component pins
    console.log(`==> start matching, # points = ${this.points.length}, # points in second mesh = ${mesh2.points.length}`);

    // find similar triangles in the mesh, and sort them by the number of pairs
    const keySet = new Set(Object.keys(mesh2.triMap));
    const similarTris = [];
    Object.keys(this.triMap).forEach(key => {
      if (keySet.has(key)) {
        similarTris.push([key, this.triMap[key], mesh2.triMap[key], this.triMap[key].length * mesh2.triMap[key].length])
      }
    });

    // sort the similar triangles by uniqueness
    similarTris.sort((st1, st2) => st1[3] - st2[3]);
    console.log(`==> found ${similarTris.length} triangles that have similar triangles in both groups`);

    const candidates = {};
    let maxPairs = 0;
    for (const similar of similarTris) {
      const [key, tris1, tris2, numPairs] = similar;
      if (maxPairs > 0 && numPairs > maxPairs) {
        break;
      }

      // for each pair of similar triangles, find the corresponding transformation
      for (const indTri1 of tris1) {
        const tInfo1 = this.triInfo[indTri1];
        for (const indTri2 of tris2) {
          const tInfo2 = mesh2.triInfo[indTri2];

          // compare the two triangles and find possible ways of matching
          const triMatches = tInfo1.match(tInfo2);
          for (const match of triMatches) {
            // find the possible matching between the two triangles
            const [indNode1, indNode2, flipX] = match;

            // determine the transformation from the two edges
            const p1 = this.points[this.triangles[indTri1][indNode1[0]]],
              p2 = this.points[this.triangles[indTri1][indNode1[1]]],
              p3 = mesh2.points[mesh2.triangles[indTri2][indNode2[0]]],
              p4 = mesh2.points[mesh2.triangles[indTri2][indNode2[1]]];
            const transform = Transformation.fromSegments([p1, p2, p3, p4].flat(), flipX);
            const edgeLenSq = this.triInfo[indTri1].maxEdgeSq;

            // insert and count the transformation
            const transKey = transform.key(tol);
            if (transKey in candidates) {
              // count the matched triangle pairs
              candidates[transKey][1] += 1;
              if (edgeLenSq > candidates[transKey][2]) {
                // prefer the transformation calculated from the larger triangle
                candidates[transKey][2] = edgeLenSq;
                candidates[transKey][0] = transform;
              }
            } else {
              // a new transformation
              candidates[transKey] = [transform, 1, edgeLenSq];
            }
          } // for (const match of triMatches)
        } // for (const indTri2 of tris2)
      } // for (const indTri1 of tris1)

      // limit the number of pairs of similar triangles to reduce calculation.
      // this can be relaxed if the best match is not satisfactory
      if (maxPairs == 0 && Object.keys(candidates).length > 0) {
        maxPairs = numPairs * MAX_SIMILAR;
      }
    } // for (const similar in sortedTris)

    // sort the candidates by number of matching triangles
    const sortedCandidates = Object.values(candidates).sort((c1, c2) => c2[1] - c1[1]);
    console.log(`==> found ${sortedCandidates.length} possible transformations`);
    return sortedCandidates;
  } // matchWith
} // class Mesh


class Node {
  constructor(point, left = null, right = null) {
    this.point = point;
    this.left = left;
    this.right = right;
  }
}


export class PointMatcher {
  constructor(points) {
    this.tree = this._buildKdTree(points.map(p => p));
  }

  match(points, tol = 1e-6) {
    const tol2 = tol;//tol*tol
    return points.map(p => this.closestPoint(this.tree, p, tol))
      .map(c => c[1] === null || c[1] > tol2 ? null : c[0]);
  }

  _buildKdTree(points, depth = 0) {
    if (points.length === 0) {
      return null;
    }

    const axis = depth & 0x1;
    points.sort((p1, p2) => p1[axis] === p2[axis] ? p1[1 - axis] - p2[1 - axis] : p1[axis] - p2[axis]);
    const median = Math.floor(points.length / 2);

    //console.log(`=== build tree depth = ${depth}`)
    return new Node(
      points[median],
      this._buildKdTree(points.slice(0, median), depth + 1),
      this._buildKdTree(points.slice(median + 1), depth + 1)
    );
  }

  _distance2(p1, p2) {
    return (p1[0] - p2[0]) * (p1[0] - p2[0]) + (p1[1] - p2[1]) * (p1[1] - p2[1]);
  }

  closestPoint(node, target, tol, depth = 0, best = null, bestDist = null) {
    if (node === null) {
      return [best, bestDist];
    }

    const axis = depth & 0x1;
    const gap1 = target[axis] - node.point[axis];
    let nextBranch;
    if (gap1 < -tol) {
      nextBranch = node.left;
    } else if (gap1 > tol) {
      nextBranch = node.right;
    } else {
      const gap2 = target[1 - axis] - node.point[1 - axis];
      if (gap2 < -tol) {
        nextBranch = node.left;
      } else if (gap2 > tol) {
        nextBranch = node.right;
      } else {
        // only compute the distance when it is within the tolerance
        const nodeDistance2 = this._distance2(node.point, target);
        if (bestDist == null || bestDist > nodeDistance2) {
          best = node.point;
          bestDist = nodeDistance2;
        }

        if (gap1 < 0) {
          nextBranch = node.left;
        } else if (gap1 > 0) {
          nextBranch = node.right;
        } else {
          nextBranch = gap2 < 0 ? node.left : node.right;
        }
      }
    }
    return this.closestPoint(nextBranch, target, tol, depth + 1, best, bestDist);
  } // closestPoint
} // class PointMatcher


class CompPins {
  constructor(points) {
    this.points = points;
    this._mesh = null;
  }

  createMesh(index = null) {
    if (index == null) {
      this._mesh = new Mesh(this.points);
    } else {
      this._mesh = new Mesh(index.map(i => this.points[i]));
    }
  }

  get mesh() {
    if (this._mesh == null) {
      this.createMesh();
    }
    return this._mesh;
  }

  matchWith(compPins2, tol, index = null) {
    /* Match with another set of points using all or a subset of points. 
       This gives the flexibility of using pins of selected nets to test for
       the mapping. The resulting transformation, of course, is applied to
       all pins.
    */
    this.createMesh(index);

    // test the most possible candidates
    const candidates = this.mesh.matchWith(compPins2.mesh, tol);
    const pinMatcher = new PointMatcher(compPins2.points);

    console.log('==> checking transformation candidates')
    console.log('    -------------------------------------------------------------------------------');
    console.log('           dx           dy        rotation    flip    scale  #tri pairs  #matched');
    console.log('    -------------------------------------------------------------------------------');
    let maxMatched = 0;
    let bestMatch = [];
    let bestTrans = null;
    let numChecked = 0;
    for (const cand of candidates) {
      const [trans, numTri, edgeLenSq] = cand;
      if (numChecked >= MAX_TRANSFORM || numTri < candidates[0][1] / MAX_HIT_RATIO) {
        console.log('    --------------------------------------------------------------------------------');
        if (numChecked < MAX_TRANSFORM) {
          console.log(`==> remaining candidates are ignored because they have too little triangle pairs (<=${numTri})`);
        }
        break;
      }

      numChecked++;
      const matched = pinMatcher.match(trans.transform(this.points), tol);
      const numMatched = matched.filter(m => m != null).length;
      console.log(printf('%3d %12.6f %12.6f %12.4f %7s %8.4f %7d %10d', numChecked, trans.dx, trans.dy, trans.rotation, trans.flipX, trans.scale, numTri, numMatched));
      //console.log(`      ${numMatched}/${this.points.length} nodes matched`);
      if (numMatched > 0 && numMatched > maxMatched) {
        maxMatched = numMatched;
        bestMatch = matched;
        bestTrans = trans;
      }
    } // for (const cand of candidates)

    console.log(`==> ${numChecked}/${candidates.length} transformation checked`)
    if (bestTrans != null) {
      console.log(`==> best transformation found: ${maxMatched} nodes matched`);
    } else {
      console.log(`==> no transformation found`)
    }
    return [bestTrans, maxMatched, bestMatch];
  } // matchWith
} // CompPins


/* console.log('-------- Pin Mapping Example --------'); */
/* const diePoints = [
  [-0.003472, 0.000200], [-0.003645, 0.000501], [-0.003472, 0.000501], [-0.003645, 0.000802],
  [-0.003472, 0.000802], [-0.003645, 0.001103], [-0.003472, 0.001103], [-0.003645, 0.001404],
  [-0.003472, 0.001404], [-0.003463, -0.004086], [-0.003289, -0.004086], [-0.003463, -0.003785],
  [-0.003289, -0.003785], [-0.003463, -0.003484], [-0.003289, -0.003484], [-0.003289, -0.003183],
  [-0.003463, -0.003183], [-0.003463, -0.002882], [-0.003289, -0.002882], [-0.003463, -0.002581],
  [-0.003289, -0.002581], [-0.003463, -0.002281], [-0.003289, -0.002281], [-0.003463, -0.001980],
  [-0.003289, -0.001980], [-0.003463, -0.001679], [-0.003289, -0.001679], [-0.001762, 0.000702],
  [-0.001588, 0.000702], [-0.001762, 0.001002], [-0.001588, 0.001002], [-0.001762, 0.001303],
  [-0.001588, 0.001303], [-0.001762, 0.001604], [-0.001588, 0.001604], [-0.001762, 0.001905],
  [-0.001588, 0.001905], [-0.001762, 0.002206], [-0.001588, 0.002206], [-0.001762, 0.002507],
  [-0.001588, 0.002507], [-0.001762, 0.002807], [-0.001588, 0.002807], [-0.001762, 0.003108],
  [-0.001588, 0.003108], [-0.001570, -0.004086], [-0.001396, -0.004086], [-0.001570, -0.003785],
  [-0.001396, -0.003785], [-0.001570, -0.003484], [-0.001396, -0.003484], [-0.001570, -0.003183],
  [-0.001396, -0.003183], [-0.001570, -0.002882], [-0.001396, -0.002882], [-0.001570, -0.002581],
  [-0.001396, -0.002581], [-0.001570, -0.002281], [-0.001396, -0.002281], [-0.001570, -0.001980],
  [-0.001396, -0.001980], [-0.001396, -0.001679], [-0.001570, -0.001679], [0.000131, 0.000702],
  [0.000305, 0.000702], [0.000131, 0.001002], [0.000305, 0.001002], [0.000131, 0.001303],
  [0.000305, 0.001303], [0.000131, 0.001604], [0.000305, 0.001604], [0.000131, 0.001905],
  [0.000305, 0.001905], [0.000131, 0.002206], [0.000305, 0.002206], [0.000131, 0.002507],
  [0.000305, 0.002507], [0.000131, 0.002807], [0.000305, 0.002807], [0.000131, 0.003108],
  [0.000305, 0.003108], [0.001986, -0.003584], [0.002159, -0.003584], [0.002159, -0.003283],
  [0.001986, -0.003283], [0.001986, -0.002983], [0.002159, -0.002983], [0.001986, -0.002682],
  [0.002159, -0.002682], [0.001986, -0.002381], [0.002159, -0.002381], [0.001986, -0.002080],
  [0.002159, -0.002080], [0.001986, -0.001779], [0.002159, -0.001779], [0.001986, -0.001478],
  [0.002159, -0.001478], [0.001986, -0.001177], [0.002159, -0.001177], [0.001986, 0.000300],
  [0.002159, 0.000300], [0.001986, 0.000601], [0.002159, 0.000601], [0.001986, 0.000902],
  [0.002159, 0.000902], [0.001986, 0.001203], [0.002159, 0.001203], [0.001986, 0.001504],
  [0.002159, 0.001504], [0.001986, 0.001805], [0.002159, 0.001805], [0.001986, 0.002106],
  [0.002159, 0.002106], [0.001986, 0.002406], [0.002159, 0.002406], [0.002159, 0.002707],
  [0.001986, 0.002707], [-0.003559, 0.000150], [-0.003559, 0.000250], [-0.003559, 0.000451],
  [-0.003559, 0.000551], [-0.003559, 0.000752], [-0.003559, 0.000852], [-0.003559, 0.001053],
  [-0.003559, 0.001153], [-0.003559, 0.001353], [-0.003559, 0.001454], [-0.003376, -0.004136],
  [-0.003376, -0.004036], [-0.003376, -0.003835], [-0.003376, -0.003735], [-0.003376, -0.003434],
  [-0.003376, -0.003534], [-0.003376, -0.003133], [-0.003376, -0.003233], [-0.003376, -0.002932],
  [-0.003376, -0.002832], [-0.003376, -0.002632], [-0.003376, -0.002531], [-0.003376, -0.002331],
  [-0.003376, -0.002230], [-0.003376, -0.002030], [-0.003376, -0.001930], [-0.003376, -0.001629],
  [-0.003376, -0.001729], [-0.001675, 0.000651], [-0.001675, 0.000752], [-0.001675, 0.000952],
  [-0.001675, 0.001053], [-0.001675, 0.001253], [-0.001675, 0.001353], [-0.001675, 0.001654],
  [-0.001675, 0.001554], [-0.001675, 0.001855], [-0.001675, 0.001955], [-0.001675, 0.002156],
  [-0.001675, 0.002256], [-0.001675, 0.002456], [-0.001675, 0.002557], [-0.001675, 0.002757],
  [-0.001675, 0.002858], [-0.001675, 0.003058], [-0.001675, 0.003158], [-0.001483, -0.004136],
  [-0.001483, -0.004036], [-0.001483, -0.003835], [-0.001483, -0.003735], [-0.001483, -0.003534],
  [-0.001483, -0.003434], [-0.001483, -0.003133], [-0.001483, -0.003233], [-0.001483, -0.002932],
  [-0.001483, -0.002832], [-0.001483, -0.002632], [-0.001483, -0.002531], [-0.001483, -0.002230],
  [-0.001483, -0.002331], [-0.001483, -0.002030], [-0.001483, -0.001930], [-0.001483, -0.001629],
  [-0.001483, -0.001729], [0.000218, 0.000651], [0.000218, 0.000752], [0.000218, 0.000952],
  [0.000218, 0.001053], [0.000218, 0.001253], [0.000218, 0.001353], [0.000218, 0.001554],
  [0.000218, 0.001654], [0.000218, 0.001955], [0.000218, 0.001855], [0.000218, 0.002156],
  [0.000218, 0.002256], [0.000218, 0.002456], [0.000218, 0.002557], [0.000218, 0.002757],
  [0.000218, 0.002858], [0.000218, 0.003158], [0.000218, 0.003058], [0.002073, -0.003634],
  [0.002073, -0.003534], [0.002073, -0.003334], [0.002073, -0.003233], [0.002073, -0.002932],
  [0.002073, -0.003033], [0.002073, -0.002632], [0.002073, -0.002732], [0.002073, -0.002331],
  [0.002073, -0.002431], [0.002073, -0.002030], [0.002073, -0.002130], [0.002073, -0.001729],
  [0.002073, -0.001829], [0.002073, -0.001428], [0.002073, -0.001528], [0.002073, -0.001127],
  [0.002073, -0.001228], [0.002073, 0.000250], [0.002073, 0.000351], [0.002073, 0.000651],
  [0.002073, 0.000551], [0.002073, 0.000952], [0.002073, 0.000852], [0.002073, 0.001153],
  [0.002073, 0.001253], [0.002073, 0.001454], [0.002073, 0.001554], [0.002073, 0.001855],
  [0.002073, 0.001754], [0.002073, 0.002156], [0.002073, 0.002055], [0.002073, 0.002456],
]; */


const scale = 1.2,
  rotation = 45,
  shiftX = 2.780,
  shiftY = 10.56,
  flipX = false,
  tol = 1e-6;

/*  ==> best transformation = [shift = (2.7800000000000002, 10.56), rotation = 45, scale = 1.2, flip = false] */

export function getPinMapper(diePoints, setting) {
  const _setting = {
    scale: 1.2,
    rotation: 45,
    shiftX: 2.780,
    shiftY: 10.56,
    flipX: false,
    tol: 1e-6
  };
  const params = setting ? setting : _setting;
  // fake the package pins from a known transform
  const t0 = new Transformation(params.shiftX, params.shiftY, params.rotation, params.flipX, params.scale);
  const pkgPoints = t0.transform(diePoints);
 /*  console.log(`==> faked package pins using a known transformation`); */

  /*  // match the pins using mesh
   const diePins = new CompPins(diePoints),
     pkgPins = new CompPins(pkgPoints);
 
   const [bestTrans, maxMatched, bestMatch] = pkgPins.matchWith(diePins, tol);
   if (bestTrans == null) {
     console.log(`==> no matching transformation found`)
   } else {
     console.log(`==> best transformation = [${bestTrans.toString()}]`);
   }
   console.log(`==> actual transformation = [${t0.toString()}]`);
   if (bestTrans) {
     const _t0 = new Transformation(bestTrans.dx, bestTrans.dy, bestTrans.rotation, bestTrans.flipX, bestTrans.scale);
     const _pkgPoints = _t0.transform(pkgPoints);
   } */

  return pkgPoints
}

function sortCoords(points) {
  return points.sort(function (a, b) {
    if (a[0] === b[0]) {
      return a[1] - b[1];
    } else {
      return a[0] - b[0];
    }
  });
}

export function getPointsTransform(diePoints, pkgPoints) {
  // match the pins using mesh
  const pkgPins = new CompPins(pkgPoints),
    diePins = new CompPins(diePoints);

  const [bestTrans, maxMatched, bestMatch] = pkgPins.matchWith(diePins, tol);
  if (bestTrans == null) {
    console.log(`==> no matching transformation found`)
    return null;
  } else {
    console.log(`==> best transformation = [${bestTrans.toString()}]`);
  }
  return bestTrans /* ? {
    ...bestTrans,
    shiftX: -bestTrans.dx,
    shiftY: -bestTrans.dy,
    rotation: -bestTrans.rotation,
    scale: 1 / bestTrans.scale
  } : null; */
  /*   console.log(`==> actual transformation = [${t0.toString()}]`); */

  // fake the package pins from a known transform
  /*     const t0 = new Transformation(shiftX, shiftY, rotation, flipX, scale);
      const pkgPoints = t0.transform(diePoints); */
}

export function getNewOkgPointsByTransForm(params, pkgPoints, log) {
  // fake the package pins from a known transform
  const t0 = new Transformation(params.shiftX, params.shiftY, params.rotation, params.flipX, params.scale);
  const _pkgPoints = t0.transform(pkgPoints);
  return _pkgPoints;
}

// calculate the angles in the triangle
//                A                             a
//                o                             o
//               / \                           / \
//              /   \                         /   \
//          e1 /     \ e0                 e1 /     \ e0
//            /       \                     /       \
//           /         \                   /         \
//        B o-----------o C             b o-----------o c
//                e2                            e2
/* Transformation matrix
      shift       rotate         scale   flipX
[x2] = [dx] + [cos(a) -sin(a)] * [s 0] * [f 0] * [x]
[y2]   [dy]   [sin(a)  cos(a)]   [0 s]   [0 1]   [y]
*/
export class TriMapping {
  constructor({ triPairs, designUnit, nodeUnit, log }) {
    this.triPairs = triPairs || [];
    this.designUnit = designUnit || "";
    this.nodeUnit = nodeUnit || "";
    this.flipX = false;//about the y axis
    this.scale = 1.0;
    this.shiftX = 0.0;
    this.shiftY = 0.0;
    this.rotation = 0.0;
    this.pinIsNotTriangle = false;
    this.nodeIsNotTriangle = false;
    this.log = log;
  }

  getTransform = () => {
    this.initData()

    if (!this.triPairs.length) {
      return null
    }

    //judge 
    this.isTriangle();
    if (this.pinIsNotTriangle || this.nodeIsNotTriangle) {
      return {
        pinIsNotTriangle: this.pinIsNotTriangle,
        nodeIsNotTriangle: this.nodeIsNotTriangle
      }
    }
    //calc flip
    this.calcFlip()
    //calc scale
    this.calcScale()
    //calc rotation
    this.calcRotation()
    //calc Shift dx,dy
    this.calcShift()
    return {
      flipX: this.flipX,
      scale: this.scale,
      shiftX: this.shiftX,
      shiftY: this.shiftY,
      rotation: this.rotation,
      log: this.log
    }
  }

  initData = () => {
    if (this.designUnit === "mils") {
      this.designUnit = "mil"
    }

    if (this.designUnit !== this.nodeUnit) {
      const scale = getThicknessScale(this.designUnit, this.nodeUnit)
      if (isNaN(scale)) {
        Array.isArray(this.log) && this.log.push(`Design unit (${this.designUnit}) and package die node location unit (${this.nodeUnit}) conversion failed.`)
      }
      this.nodeUnit = this.designUnit;
      //update point by unit
      for (let itemData of this.triPairs) {
        if (!itemData.node || !itemData.node.location || !itemData.node.location.length) {
          continue;
        }
        itemData.node.location[0] = NP.times(scale, itemData.node.location[0]);
        itemData.node.location[1] = NP.times(scale, itemData.node.location[1]);
        if (isNaN(itemData.node.location[0]) || isNaN(itemData.node.location[1])) {
          Array.isArray(this.log) && this.log.push(`Die node (${itemData.node.node}) location is NaN.`)
        }
      }
    }

    if (!this.triPairs.length) {
      Array.isArray(this.log) && this.log.push(`triPairs is a empty array.`)
      return
    }

    const pinA = this.triPairs[0].pin, pinB = this.triPairs[1].pin, pinC = this.triPairs[2].pin,
      nodeA = this.triPairs[0].node, nodeB = this.triPairs[1].node, nodeC = this.triPairs[2].node;
    this.pinPointA = [pinA.mLocation.xc, pinA.mLocation.yc];
    this.pinPointB = [pinB.mLocation.xc, pinB.mLocation.yc];
    this.pinPointC = [pinC.mLocation.xc, pinC.mLocation.yc];

    this.pkgPointA = [...nodeA.location];
    this.pkgPointB = [...nodeB.location];
    this.pkgPointC = [...nodeC.location];
    // (x2 - x1, y2 - y1) 
    this.pinAB = [this.pinPointB[0] - this.pinPointA[0], this.pinPointB[1] - this.pinPointA[1]];
    this.pinAC = [this.pinPointC[0] - this.pinPointA[0], this.pinPointC[1] - this.pinPointA[1]];

    this.nodeAB = [this.pkgPointB[0] - this.pkgPointA[0], this.pkgPointB[1] - this.pkgPointA[1]];
    this.nodeAC = [this.pkgPointC[0] - this.pkgPointA[0], this.pkgPointC[1] - this.pkgPointA[1]];

    if (isNaN(this.pinAB[0]) || isNaN(this.pinAB[1])) {
      Array.isArray(this.log) && this.log.push(`Pin - pinAB (${this.pinAB[0]}, ${this.pinAB[1]}) location is NaN. 
      pinPointA:(${this.pinPointA[0]}, ${this.pinPointA[1]}),
      pinPointB:(${this.pinPointB[0]}, ${this.pinPointB[1]})`)
    }

    if (isNaN(this.pinAC[0]) || isNaN(this.pinAC[1])) {
      Array.isArray(this.log) && this.log.push(`Pin - pinAC (${this.pinAC[0]}, ${this.pinAC[1]}) location is NaN. 
      pinPointA:(${this.pinPointA[0]}, ${this.pinPointA[1]}),
      pinPointC:(${this.pinPointC[0]}, ${this.pinPointC[1]})`)
    }

    if (isNaN(this.nodeAB[0]) || isNaN(this.nodeAB[1])) {
      Array.isArray(this.log) && this.log.push(`Node - nodeAB (${this.nodeAB[0]}, ${this.nodeAB[1]}) location is NaN. 
      pkgPointA:(${this.pkgPointA[0]}, ${this.pkgPointA[1]}),
      pkgPointB:(${this.pkgPointB[0]}, ${this.pkgPointB[1]})`)
    }

    if (isNaN(this.nodeAC[0]) || isNaN(this.nodeAC[1])) {
      Array.isArray(this.log) && this.log.push(`Node - nodeAC (${this.nodeAC[0]}, ${this.nodeAC[1]}) location is NaN. 
      pkgPointA:(${this.pkgPointA[0]}, ${this.pkgPointA[1]}),
      pkgPointC:(${this.pkgPointC[0]}, ${this.pkgPointC[1]})`)
    }
  }

  //
  isTriangle = () => {
    //(y3 - y1) * (x2 - x1) - (y2 - y1) * (x3 - x1)===0
    //die
    const pin_PARAM = (this.pinPointC[1] - this.pinPointA[1]) * (this.pinPointB[0] - this.pinPointA[0]) - (this.pinPointB[1] - this.pinPointA[1]) * (this.pinPointC[0] - this.pinPointA[0])
    if (pin_PARAM === 0) {
      this.pinIsNotTriangle = true;
      Array.isArray(this.log) && this.log.push(`The points of die pins cannot form a triangle.`);
    }
    //package die
    const node_PARAM = (this.pkgPointC[1] - this.pkgPointA[1]) * (this.pkgPointB[0] - this.pkgPointA[0]) - (this.pkgPointB[1] - this.pkgPointA[1]) * (this.pkgPointC[0] - this.pkgPointA[0])
    if (node_PARAM === 0) {
      this.nodeIsNotTriangle = true;
      Array.isArray(this.log) && this.log.push(`The points of package nodes cannot form a triangle.`);
    }
  }

  calcFlip = () => {
    //about the y axis
    /* AB x AC ,  ab x ac*/
    /* AB = (x2 - x1, y2 - y1) */

    //AB X AC = (x1,y1)*(x2*y2) = x1*y2 - y1*x2
    const pin_AB_AC = this.pinAB[0] * this.pinAC[1] - this.pinAB[1] * this.pinAC[0];
    const node_AB_AC = this.nodeAB[0] * this.nodeAC[1] - this.nodeAB[1] * this.nodeAC[0];
    if ((pin_AB_AC >= 0 && node_AB_AC >= 0)
      || (pin_AB_AC < 0 && node_AB_AC < 0)) {
      this.flipX = false;
      Array.isArray(this.log) && this.log.push(`flipX is false.`);
      return;
    }
    this.flipX = true;
    Array.isArray(this.log) && this.log.push(`flipX is true.`);
    if (this.flipX) {
      /*  x1 = -x1;
       x2 = -x2; */
      this._pkgPointA = [-this.pkgPointA[0], this.pkgPointA[1]];
      this._pkgPointB = [-this.pkgPointB[0], this.pkgPointB[1]];
      this._pkgPointC = [-this.pkgPointC[0], this.pkgPointC[1]];
      this._nodeAB = [this._pkgPointB[0] - this._pkgPointA[0], this._pkgPointB[1] - this._pkgPointA[1]];
      this._nodeAC = [this._pkgPointC[0] - this._pkgPointA[0], this._pkgPointC[1] - this._pkgPointA[1]];

      if (isNaN(this._nodeAB[0]) || isNaN(this._nodeAB[1])) {
        Array.isArray(this.log) && this.log.push(`Node - flip nodeAB (${this._nodeAB[0]}, ${this._nodeAB[1]}) location is NaN. 
        flip pkgPointA:(${this._pkgPointA[0]}, ${this._pkgPointA[1]}),
        flip pkgPointB:(${this._pkgPointB[0]}, ${this._pkgPointB[1]})`)
      }

      if (isNaN(this._nodeAC[0]) || isNaN(this._nodeAC[1])) {
        Array.isArray(this.log) && this.log.push(`Node - flip nodeAC (${this._nodeAC[0]}, ${this._nodeAC[1]}) location is NaN. 
        flip pkgPointA:(${this._pkgPointA[0]}, ${this._pkgPointA[1]}),
        flip pkgPointC:(${this._pkgPointC[0]}, ${this._pkgPointC[1]})`)
      }
    }
  }

  calcScale = () => {
    // |AB| / |ab|
    //|AB| = Math.sqrt((x1-x2)*(x1-x2) + (y1-y2)*(y1-y2))
    const pin_AB_distance = Math.sqrt((this.pinPointA[0] - this.pinPointB[0]) ** 2 + (this.pinPointA[1] - this.pinPointB[1]) ** 2)
    const node_ab_distance = Math.sqrt((this.pkgPointA[0] - this.pkgPointB[0]) ** 2 + (this.pkgPointA[1] - this.pkgPointB[1]) ** 2)
    Array.isArray(this.log) && this.log.push(`calcScale - pin_AB_distance (${pin_AB_distance}), node_ab_distance (${node_ab_distance}).`);
    this.scale = Math.abs(pin_AB_distance) / Math.abs(node_ab_distance);
    Array.isArray(this.log) && this.log.push(`calcScale - Scale (${this.scale}).`);
  }

  calcRotation = () => {
    //the rotation can be found by calculating the angle between the edges ab and AB. This can be done using both the cross product and the dot product
    // rotation= Math.atan2(a'b' x AB, a'b' ·AB)
    ///where a'b' is simply ab if there is no flip. 
    //If there is a flip, then is equal to the vector where  ab is flipped about the y axis.
    //a'b' x AB =(x1,y1)*(x2*y2) = x1*y2 - y1*x2
    let ab = this.flipX ? this._nodeAB : this.nodeAB
    this.cross_product = ab[0] * this.pinAB[1] - ab[1] * this.pinAB[0];
    Array.isArray(this.log) && this.log.push(`calcRotation - cross_product (${this.cross_product}).`);
    // a'b' · AB = dot_product = a'b' · AB = a'b'.x * AB.x + a'b'.y * AB.y
    this.dot_product = ab[0] * this.pinAB[0] + ab[1] * this.pinAB[1];
    Array.isArray(this.log) && this.log.push(`calcRotation - cross_product (${this.dot_product}).`);
    this.rotation = Math.atan2(this.cross_product, this.dot_product) * RAD_TO_DEG;
    Array.isArray(this.log) && this.log.push(`calcRotation - rotation (${this.rotation}).`);
  }

  calcShift = () => {
    // x3 = x1 * scale * cos(a) - y1 * scale * sin(a) + dx = x1 * dotProd / L12^2 - y1 * crsProd / L12^2 + dx
    // y3 = x1 * scale * sin(a) + y1 * scale * cos(a) + dy = x1 * crsProd / L12^2 + y1 * dotProd / L12^2 + dy
    /*     const dx = x3 - (x1 * dotProd - y1 * crsProd) * oneOverL12sq,
      dy = y3 - (x1 * crsProd + y1 * dotProd) * oneOverL12sq; */
    /*     const crsProd = x12 * y34 - x34 * y12,
    dotProd = x12 * x34 + y12 * y34;
  const oneOverL12sq = 1 / (x12 * x12 + y12 * y12);
  const scaleRaw = Math.sqrt((x34 * x34 + y34 * y34) * oneOverL12sq);
  const scale = Math.round(scaleRaw / SCALE_TOL) * SCALE_TOL;
  const rotationRaw = Math.atan2(crsProd, dotProd) * RAD_TO_DEG;
  const rotation = Math.round(rotationRaw / ANGLE_TOL) * ANGLE_TOL;
  const dx = x3 - (x1 * dotProd - y1 * crsProd) * oneOverL12sq,
    dy = y3 - (x1 * crsProd + y1 * dotProd) * oneOverL12sq; */
    //oneOverL12sq = 1 / (x12 * x12 + y12 * y12);
    let ab = this.flipX ? this._nodeAB : this.nodeAB;
    const oneOverL12sq = 1 / (ab[0] * ab[0] + ab[1] * ab[1]);
    Array.isArray(this.log) && this.log.push(`calcShift - oneOverL12sq (${oneOverL12sq}).`);
    const pkgPointA = this.flipX ? this._pkgPointA : this.pkgPointA;
    this.shiftX = this.pinPointA[0] - (pkgPointA[0] * this.dot_product - pkgPointA[1] * this.cross_product) * oneOverL12sq;
    this.shiftY = this.pinPointA[1] - (pkgPointA[0] * this.cross_product + pkgPointA[1] * this.dot_product) * oneOverL12sq;
    Array.isArray(this.log) && this.log.push(`calcShift - shiftX (${this.shiftX}), shiftY (${this.shiftY}).`);

    /*     this.cross_product =  this.pinAB[0] * this.nodeAB[1] -  this.pinAB[1] * this.nodeAB[0];
        // a'b' · AB = dot_product = a'b' · AB = a'b'.x * AB.x + a'b'.y * AB.y
        this.dot_product =  this.pinAB[0] * this.nodeAB[0] +  this.pinAB[1] * this.nodeAB[1];
        
    const oneOverL12sq = 1 / (this.pinAB[0] * this.pinAB[0] + this.pinAB[1] * this.pinAB[1]);
    this.shiftX = this.pkgPointA[0] - (this.pinPointA[0] * this.dot_product - this.pinPointA[1] * this.cross_product) * oneOverL12sq;
    this.shiftY = this.pkgPointA[1] - (this.pinPointA[0] * this.cross_product + this.pinPointA[1] * this.dot_product) * oneOverL12sq; */
  }
}