import { PDNDoSimulation, debugVerify, getSimulationLog } from '../api/pdn/PDNAPICtrl';
import apiProcess from '../api/utility';
/**
 * pdn do simulation 
 *
 * @export
 * @param {String} verificationId verificationId
 * @returns Promise
 */
export function doSimulation(verificationIds) {
  return apiProcess(PDNDoSimulation, verificationIds, false, true)
}

/**
 * get  pdn simulation monitor 
 *
 * @export
 * @param {string} resultType resultType /1(setup)/2(extraction)/3(simulation)
 * @param {string} verificationId verificationId 
 * @returns Promise
 */
export function doDebugVerify({ resultType, verificationId }) {
  return apiProcess(debugVerify, { resultType, verificationId });
}

/**
 * download  pdn simulation log 
 *
 * @export
 * @param {string} verificationId verificationId 
 * @returns Promise
 */

export function getPdnSimulationLog(verificationId) {
  return new Promise((resolve, reject) => {
    getSimulationLog(verificationId).then(res => {
      resolve(res.data);
    }, error => {
      resolve('');
    })
  })
}