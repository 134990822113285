import { takeEvery, call, put, select, takeLatest, delay, fork, cancel } from 'redux-saga/effects';
import { message } from 'antd';
import {
  PARSE_FILE_AND_CREATE_SIGN_OFF_TASK,
  GET_SIGN_OFF_CONTENT,
  CHANGE_SIGN_OFF_PCB,
  CREATE_AND_RUN_IMPEDANCE_TASK,
  UPDATE_SIGN_OFF_TEMPLATE_LIST_STATUS,
  UPDATE_TEMPLATE_PORTS,
  SAVE_TEMPLATE_CAP_MODEL,
  UPDATE_TEMPLATE_SOC,
  UPDATE_TEMPLATE_COMP_SETTING,
  UPDATE_TEMPLATE_GND,
  SAVE_SIGN_OFF_CONTENT,
  UPDATE_TEMPLATE,
  SAVE_TEMPLATE_CONFIG,
  RE_ASSIGN_TEMPLATE_DECAP_MODEL,
  DOWNLOAD_TEMPLATE_S1P_FILE,
  SAVE_IMPEDANCE_VALUE,
  SAVE_TEMPLATE_VALUE,
  CREATE_NEW_POWER,
  DELETE_TEMPLATE_PWR_DOMAIN,
  UPDATE_SIGN_EXTRACTION,
  SAVE_IMP_FREQ_LIST,
  DELETE_IMPEDANCE_FREQ,
  UPDATE_DCR_EXTRACTION,
  SAVE_ADDITIONAL_NETS,
  UPDATE_SIGN_OFF_TEMPLATE_LOGS,
  UPDATE_TEMPLATE_COMP_TABLE_DISPLAY,
  UPDATE_TEMPLATE_DECAP_MODEL,
  CHOOSE_PDF_TABLE
} from './actionType';
import {
  updateSignOffTemplateInfo,
  savaSignOffVerificationId,
  getSignOffContent,
  updateSignOffCreateTaskStatus,
  updateSignOffTaskError,
  resetTemplateSetup,
  updateTemplateErrors,
  updateTemplatePCBLoading,
  selectSignOffTask,
  updateTemplateContent,
  updateTemplateComponentSettingStatus,
  updateTemplateContentLoading,
  saveTemplateContent,
  updateTemplate,
  updateModelAssignLoading,
  updateCreateTaskMsg,
  updateTemplateSetup,
  updateTemplateSetupValue,
  updateTemplateStackupError,
  updateDesignId,
  saveTemplateLogs,
  saveTemplateDisplay,
  updateTemplatePDFPanel
} from './action';
import {
  parseAndCreateSignOffTask,
  getSignOffTempalteUploadLog,
  getSignOffTemplateContentPromise,
  updateSignOffTemplateInfoPromise,
  updateSignOffStatus,
  checkAndUpdateSignOffTemplateContent,
  downloadTemplateS1P,
  getPowerGndPinsByNetAndComp,
  updateOnePowerCapComponents,
  updateTemplateItemCompsByVersion,
  deleteComponents,
  templateOhmUnitConversion,
  ohmUnitConversion,
  templateAddDefaultLoopDcrSpec,
  frequencyStrFormat,
  getACSensePorts,
  getDCPmicPortPins,
  selectSignOffTemplatePCB,
  setPortsToImpedanceTask,
  checkPathRData,
  templateUpdateLoopDcrSpec,
  uploadDecapModelExcel,
  chooseTemplateTable,
  selectTemplateTable
} from '../../../../services/Cascade/SignOffTemplate';
import { strDelimited } from '../../../../services/helper/split';
import { createVerification, updateTreeAfterChangeVerification } from '../project/projectSaga';
import { IMPEDANCE, SIGN_OFF_TEMPLATE, CUSTOM_LIBRARY, DCR } from '../../../../constants/treeConstants';
import CascadeChannels from '../../../../services/Cascade/DB/cascadeChannels';
import { getDefaultIndex, getDefaultName } from '../../../../services/helper/setDefaultName';
import componentSetting from '../../../../services/Cascade/helper/compSettingHelper';
import compPinMap from '../../../../services/Cascade/helper/compPinMap';
import {
  ImpExtraction,
  ImpOptimization,
  ImpOptions,
  saveImpedanceDomain,
  saveImpedanceSetup,
  ImpedanceErrorCheck,
} from '@/services/Cascade/Impedance';
import {
  getExplorerTree,
  getProjectChildren,
  selectTaskPCB,
  deleteCascadeChannel
} from '@/services/Cascade/project';
import { IMPEDANCE_VERSION } from '@/version';
import { startImpedanceSimulation } from '../simulation/Impedance/action';
import { startDCRSimulation } from '../simulation/DCR/action';
import {
  updateExpand,
  updateTreeList,
  updateTemplateLog
} from '../project/action';
import { numberCheck, versionCompareSize } from '../../../../services/helper/dataProcess';
import { CASCADE } from '../../../../constants/pageType';
import { PROJECTS_INDEX } from '@/services/Cascade';
import { SIGN_OFF_UPDATE, SIGN_OFF_RUNNING, SIGN_OFF_FINISH } from '../../../../constants/signOffTemplateStatus';
import projectDesigns from '@/services/helper/projectDesigns';
import { changeTabMenu, openTabFooter } from '../../../MonitorStore/action';
import { updateLibSetting } from '@/services/Cascade/library';
import { updateModelSetting } from '../project/action';
import { unitChange } from '@/services/helper/mathHelper';
import { valueUnitSplit } from '../../../../services/helper/valueUnitSplit';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import SystemLibrary from '@/services/Library/systemLibraryStore';
import compTableHelper from '@/services/Cascade/helper/compTableHelper.js';
import { _updateLibraryMenu } from '../library/saga';
import FileSaver from 'file-saver';
import templateGroups, { groupSignOffTemplate } from '../../../../services/Cascade/SignOffTemplate/impedanceInfo';
import { SIGN_OFF_TEMPLATE_VERSION } from '../../../../version';
import { NPTimes } from '@/services/helper/numberHelper';
import { updateDCRInfo } from '@/services/Cascade/DCR/DCRCtrl';
import pinMapStore from '@/services/Cascade/library/pinMapHelper';
import _ from 'lodash';
import { checkResistanceData } from '../../../../services/Cascade/DCR/checkPathRData';
import { getCheckDataMessage } from '@/services/Cascade/helper/PathRHelper';
import { getStackupErrorCheck } from '../simulation/simulationSaga';
import { getExplorerInfo } from '../../../../services/Cascade/project';
import designConstructor from '../../../../services/helper/designConstructor';
import { updateWarningMsg, uploadDCRErrorMsg } from '../DCR/action';
import { DCR_TITLE, IMPEDANCE_PCB, IMPEDANCE_TITLE } from '../../../../services/Cascade/constants';
import { DCExtraction } from '../../../../services/Cascade/helper/setupClass';
import { CAP, IGNORE, IND, PMIC, RES } from '../../../../constants/componentType';
import store from '../../../store';
import { upgradeSetupDataToMulti } from '../../../../services/Cascade/Impedance';
import { updateCompDecapModel } from '../../../../services/helper/decapHelper/MatchModel';
import {
  getAllCascadeNets,
  getCascadeComponents,
  _getVRM,
  getPMIC,
  CascadeCompPrefixVersion
} from '../../../../services/Cascade/helper/setupData';
import { SUCCESS } from '../../../../constants/returnCode';
import { userDefaultSettings } from '../../../../services/userDefaultSetting/userDefaultSettingCtrl';
import { COMPONENTBASED, PARTBASED } from '../../../../constants/resolution';
import { DECAP_SPICE, DECAP_TOUCHSTONE } from '../../../../constants/libraryConstants';
import { getLibraryFileInfo } from '../../../../services/Cascade/library';
import { parseSPModelSelector } from '../../../../services/Library';
import { getPartNumber } from '../../../../services/PCBHelper';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { SortFn } from '../../../../services/helper/sort';
import { PDF } from '../../../../constants/fileType';
import { checkVerificationStatus } from '../../../../services/workflow/workflow';
import { VERIFY_FAILED, VERIFY_SUCCESS, WAITING } from '../../../../constants/verificationStatus';

function* _createSignOffTask(action) {
  const { file, fileName, uploadItem, templateType } = action;
  const projectId = strDelimited(uploadItem.key, "-", { returnIndex: 1 });
  const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId);
  const nameList = list.map(item => item.name);
  const name = getDefaultName({ nameList, defaultKey: 'Template', key: "" });
  try {
    const res = yield call(parseAndCreateSignOffTask, { file, fileName, name, projectId, type: templateType });
    if (!res) {
      message.error("Upload sign-off template failed!");
      return;
    }
    if (res.code === SUCCESS) {
      if (templateType === PDF) {
        yield call(createSignOffTaskByPDF, { res: res.data, dataType: SIGN_OFF_TEMPLATE, open: true, name, projectId });
        return;
      }
      yield call(updateTreeAfterChangeVerification, { res: res.data, dataType: SIGN_OFF_TEMPLATE, open: true, name });
    } else {
      message.error("Upload sign-off template failed! " + res.msg);
    }
    const logContent = yield call(getSignOffTempalteUploadLog, { projectId, verificationId: res.data.verificationId });
    yield put(updateTemplateLog([logContent]));
    yield put(changeTabMenu({
      tabSelectKeys: ['monitor'],
      currentVerificationId: res.data.verificationId,
      verificationName: name ? name : SIGN_OFF_TEMPLATE,
      menuType: 'simulation'
    }))
    yield put(openTabFooter())
  } catch (error) {
    console.error(error)
    message.error("Upload sign-off template failed!" + error);
  }
}

function createDefaultExtraction(signOffTemplate, getMaxFreq = false) {
  let frequencyArr = []

  if (signOffTemplate && signOffTemplate.length) {
    signOffTemplate.forEach(item => {
      if (item.impedance && item.impedance.length) {
        item.impedance.forEach(imp => {
          if (imp.frequency) {
            const rowVal = imp.frequency
            const { value, unit } = valueUnitSplit(rowVal || '')

            let frequency = null
            if (unit && value) {
              frequency = unitChange({ num: value, newUnit: 'Hz', oldUnit: unit }).number
            }
            frequencyArr.push(frequency)
          }
        })
      }
    })
  }

  // Take 1.1 times the maximum frequency
  let maxFreq = "2e8";
  if (frequencyArr.length > 0) {
    const FMAX = NPTimes(Math.max(...frequencyArr), 1.1);
    maxFreq = isNaN(FMAX) ? "2e8" : FMAX;
    if (getMaxFreq) {
      return isNaN(FMAX) ? { maxFreq } : { maxFreq, update: true }
    }
  }
  return getMaxFreq ? { maxFreq } : new ImpExtraction({ FMAX: maxFreq })
}

function* _getContent(action) {
  const { verificationId } = action;
  const { CascadeReducer: { project: { openProjectId, applyModelList } } } = yield select();

  const userSetting = userDefaultSettings.getLocalSetting();
  const { cascadeSettings = {} } = userSetting;
  const { impedanceComponentsTableDisplay = PARTBASED } = cascadeSettings;
  yield put(saveTemplateDisplay(impedanceComponentsTableDisplay))

  try {
    yield put(resetTemplateSetup());
    templateGroups.clearCache();
    yield put(updateTemplatePCBLoading(true));
    let save = false;
    let res = yield call(getSignOffTemplateContentPromise, { verificationId });
    yield put(savaSignOffVerificationId(verificationId));
    //If design is not selected, and there is only one design in the Project,auto select design.
    if (!res.designId || !res.version) {
      const pcbList = projectDesigns.getAvailablePCBs(openProjectId);
      if (!res.designId && pcbList.length === 1) {
        res.designId = pcbList[0].id;
        yield call(selectTaskPCB, { verificationId, designId: res.designId });
      }
      if (res.designId) {
        //get design
        yield call(getAuroraDB, res.designId);
        //error check and get cap components by sign off template
        const prevComps = JSON.parse(JSON.stringify(res.components || []));
        yield put(saveTemplateLogs([], true));
        yield put(openTabFooter());
        yield put(changeTabMenu({
          tabSelectKeys: ["detail"],
          currentVerificationId: verificationId,
          verificationName: SIGN_OFF_TEMPLATE,
          menuType: "simulation"
        }))
        const saveLogs = (logs) => {
          store.dispatch({ type: UPDATE_SIGN_OFF_TEMPLATE_LOGS, logs })
        }
        const { errors, signOffTemplateInfo, powerErrorMap } = yield call(checkAndUpdateSignOffTemplateContent, {
          signOffTemplateInfo: res,
          group: true,
          saveLogs
        });
        signOffTemplateInfo.components = yield call(updateComponentDecapModel, {
          designId: res.designId,
          components: signOffTemplateInfo.components,
          prevComps
        })
        res = signOffTemplateInfo;
        yield put(updateTemplateErrors({ errors, powerErrorMap, id: verificationId }));
        res.signOffTemplate = templateOhmUnitConversion(res.signOffTemplate);
        save = true;
      } else {
        res.signOffTemplate = groupSignOffTemplate(res.signOffTemplate, res.soc, res.gnd);
        res.signOffTemplate = templateOhmUnitConversion(res.signOffTemplate);
        save = true;
      }
      res.extraction = createDefaultExtraction(res.signOffTemplate)
    }

    const findGroupKey = res.signOffTemplate.find(item => !item.groupKey);
    if (versionCompareSize(res.version, "0.0.2") || findGroupKey) {
      res.signOffTemplate = groupSignOffTemplate(res.signOffTemplate, res.soc, res.gnd);
      //get design
      yield call(getAuroraDB, res.designId);
      res = yield call(updateTemplateItemCompsByVersion, res);
      res.version = "0.0.2";
      save = true;
    }

    if (versionCompareSize(res.version, "0.0.3")) {
      res.extraction = createDefaultExtraction(res.signOffTemplate)
      res.version = "0.0.3";
      save = true;
    }

    if (versionCompareSize(res.version, "0.0.4")) {
      res.signOffTemplate = templateOhmUnitConversion(res.signOffTemplate);
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true;
    }

    if (versionCompareSize(res.version, "0.0.5")) {
      //If the loop dcr spec does not exist, add the default 
      res.signOffTemplate = templateAddDefaultLoopDcrSpec(res.signOffTemplate);
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true;
    }

    if (!res.config) {
      res.config = { option: "all", vrmOpen: false, includeSense: false };
      save = true;
    } else if (versionCompareSize(res.version, "0.0.6")) {
      //add new option "vrmOpen" and "includeSense" to config, default is false
      res.config = { ...res.config, vrmOpen: false, includeSense: false };
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true;
    } else if (versionCompareSize(res.version, "0.0.8")) {
      //add new option "includeSense" to config, default is false
      res.config = { ...res.config, includeSense: false };
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true;
    } else if (versionCompareSize(res.version, "0.0.10")) {
      res.config = { ...res.config, rlMin: '1', rlMax: '' }
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true;
    }

    if ((versionCompareSize(res.version, "0.0.7") && res.extraction) || (res.extraction && res.extraction.includeDC === undefined)) {
      //add new option "includeDC" and "exactDC" to extraction, default is false
      res.extraction = { ...res.extraction, includeDC: false, exactDC: false };
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true;
    }

    if (versionCompareSize(res.version, "0.0.9")) {
      res.signOffTemplate = templateUpdateLoopDcrSpec(res.signOffTemplate);
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true
    }

    if (versionCompareSize(res.version, "1.0.0")) {
      res.components.forEach(comp => {
        if (comp.COMP_TYPE === CAP && comp.model) {
          comp.models = [{ ...comp.model, key: 0 }]
        }
      });
      res.version = SIGN_OFF_TEMPLATE_VERSION;
      save = true
    }

    if (res.designId && !res.designName) {
      //add designName to template json
      res.designName = designConstructor.getDesignName(res.designId);
      save = true;
    }
    const _setting = yield call(componentSetting.getSetting, { designId: res.designId, updateLibraryMenu: _updateLibraryMenu });

    if (!res.compSettingVersion && res.designId && _setting) {
      res.compSettingVersion = _setting.version;
      save = true;
    }

    if (_setting && res.compSettingVersion !== _setting.version && res.designId) {
      yield put(updateTemplateComponentSettingStatus(true))
    }

    //apply model to same part
    if (res.components && res.components.length) {
      res.components.forEach(comp => {
        if (comp.COMP_TYPE === "Cap") {
          const findItem = applyModelList.find(item => item.partName === comp.part);
          //comp.model.apply === false , The model modified by the user without checking apply all
          if (findItem && !findItem.defaultId && comp.models && comp.models.length === 1 && comp.models[0].apply !== false) {
            comp.models = [{ ...findItem.model, key: 0 }]
          }
        }
      })
    }

    res.dcExtraction = new DCExtraction(res.dcExtraction || {})

    yield put(updateSignOffTemplateInfo(res || {}));

    const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
    const currentItem = list.find(i => i.id === verificationId);

    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : SIGN_OFF_TEMPLATE,
      menuType: "simulation"
    }));
    yield put(openTabFooter());
    //update status
    if (res.signOffStatus === SIGN_OFF_UPDATE) {
      try {
        yield call(updateSignOffStatus, { verificationId });
      } catch (error) {
        console.error(error)
      }
    }

    if (save) {
      yield put(saveTemplateContent());
    }
    //get design
    if (res.designId) {
      yield call(getAuroraDB, res.designId);
    }
    yield put(updateTemplatePCBLoading(false));
  } catch (error) {
    console.error(error);
    yield put(updateTemplatePCBLoading(false));
  }
}

function* saveSignOffContent(action) {
  try {
    const { update } = action;
    const { CascadeReducer: { SignOffTemplate: { data, verificationId } } } = yield select();
    const res = yield call(updateSignOffTemplateInfoPromise, {
      content: {
        fileName: data.fileName,
        signOffTemplate: data.signOffTemplate,
        components: data.components,
        soc: data.soc || "",
        gnd: data.gnd || "",
        version: SIGN_OFF_TEMPLATE_VERSION,
        compSettingVersion: data.compSettingVersion,
        taskMapping: data.taskMapping,
        pathRTaskMapping: data.pathRTaskMapping,
        config: data.config || { option: "all", vrmOpen: false, includeSense: false, rlMin: '1', rlMax: '' },
        extraction: data.extraction,
        designName: data.designName,
        dcExtraction: data.dcExtraction,
        additionalNets: data.additionalNets || [],
        componentsTableDisplay: data.componentsTableDisplay
      },
      verificationId
    })
    if (res && update) {
      yield put(updateSignOffTemplateInfo(res || {}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateSignExtraction(action) {
  const { extraction } = action
  const { CascadeReducer: { SignOffTemplate: { data } } } = yield select();

  const newData = JSON.parse(JSON.stringify(data))
  newData.extraction = extraction
  yield put(updateSignOffTemplateInfo(newData));
  yield put(saveTemplateContent());
}

function* saveDCRExtraction(action) {
  const { extraction } = action
  const { CascadeReducer: { SignOffTemplate: { data } } } = yield select();

  const newData = JSON.parse(JSON.stringify(data))
  newData.dcExtraction = new DCExtraction(extraction)
  yield put(updateSignOffTemplateInfo(newData));
  yield put(saveTemplateContent());
}

function* _updatePCB(action) {
  const { designId } = action;
  const { CascadeReducer: { SignOffTemplate: { data, verificationId }, project: { openProjectId } } } = yield select();
  let _data = { ...data };
  _data.designId = designId;
  _data.designName = designConstructor.getDesignName(designId);
  yield delay(100)
  yield put(updateTemplatePCBLoading(true));
  yield put(updateTemplateContentLoading(true));
  try {
    //switch pcb and get json by pcb
    let res = yield call(selectSignOffTemplatePCB, { verificationId, designId: _data.designId });
    _data = res ? res : _data;
    _data.soc = "";
    _data.gnd = "";
    _data.additionalNets = [];
    yield call(getAuroraDB, _data.designId);
    let prevComps = JSON.parse(JSON.stringify(_data.components || []));
    yield put(saveTemplateLogs([], true));
    const saveLogs = (logs) => {
      store.dispatch({ type: UPDATE_SIGN_OFF_TEMPLATE_LOGS, logs })
    }

    const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
    const currentItem = list.find(i => i.id === verificationId);
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : SIGN_OFF_TEMPLATE,
      menuType: "simulation"
    }));
    //error check and get cap components by sign off template
    const { errors, signOffTemplateInfo, powerErrorMap } = yield call(checkAndUpdateSignOffTemplateContent, {
      signOffTemplateInfo: _data,
      updatePcb: true,
      saveLogs
    });
    signOffTemplateInfo.components = yield call(updateComponentDecapModel, {
      designId: data.designId,
      components: signOffTemplateInfo.components,
      prevComps
    })

    let newAdditionalNets = data.additionalNets || [];
    if (newAdditionalNets.length) {
      const nets = getAllCascadeNets({ pcbId: data.designId });
      const oldNets = getAllCascadeNets({ pcbId: data.designId });
      newAdditionalNets = oldNets.length === newAdditionalNets.length ? nets : newAdditionalNets.filter(net => nets.includes(net))
    }
    signOffTemplateInfo.additionalNets = newAdditionalNets;

    _data = signOffTemplateInfo;
    yield put(updateTemplateErrors({ errors, powerErrorMap, id: verificationId }));
    yield put(updateTemplateContent({ ..._data }));
    yield put(updateDesignId(designId))
    yield put(updateTemplateSetup(true));
    yield put(updateTemplateContentLoading(false));
    yield put(updateTemplatePCBLoading(false));
    yield put(saveTemplateContent());
  } catch (error) {
    console.error(error);
    yield put(updateTemplateContentLoading(false));
    yield put(updateTemplatePCBLoading(false));
  }
}

function* _createRunImpedanceTask(action) {
  const { signOffVerificationId, createConfig: { createImpTask, createDCRTask } } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {}, verificationId, templateStackupErrors, templateComponentsTableDisplay }, project: { openProjectId, expandedKeys } } } = yield select();
  yield put(updateSignOffCreateTaskStatus(true));
  yield put(updateTemplateLog([]));
  let createTaskMsg = [];
  createTaskMsg = ["Parsing template data and loading PCB..."];
  yield delay(50);

  try {
    yield call(getAuroraDB, data.designId);
    //error check

    createTaskMsg.push("Checking template data...");
    yield put(updateCreateTaskMsg(createTaskMsg));
    const { errors, signOffTemplateInfo, newTemplates, powerErrorMap } = yield call(checkAndUpdateSignOffTemplateContent, {
      signOffTemplateInfo: data,
      isUpdateComp: false,
      createDCRTask
    });
    yield put(updateTemplateContent({ ...signOffTemplateInfo }));
    const capComponents = signOffTemplateInfo.components || [];
    yield put(updateTemplateErrors({ errors, powerErrorMap, id: signOffVerificationId }));

    if (errors && errors.length && !newTemplates.length) {
      yield put(updateSignOffCreateTaskStatus(false));
      yield put(updateCreateTaskMsg([]))
      return;
    }
    const extractionConfig = data.extraction || new ImpExtraction();
    if (auroraDBJson.isFlexBoard(data.designId)) {
      extractionConfig.CLIP = 0
    }
    //stackup error check
    const stackupError = yield call(getStackupErrorCheck, {
      designId: data.designId,
      verificationId,
      extractionSolver: extractionConfig.type,
      isTemplate: true
    });
    let _templateStackupErrors = templateStackupErrors ? [...templateStackupErrors] : [];
    _templateStackupErrors = _templateStackupErrors.filter(item => item.verificationId !== verificationId);
    if (stackupError && stackupError.length) {
      //update stackup error
      _templateStackupErrors.push({
        verificationId,
        stackupError
      })
      yield put(updateTemplateStackupError(_templateStackupErrors));
      yield put(updateSignOffCreateTaskStatus(false));
      yield put(updateCreateTaskMsg([]))
      return;
    } else {
      yield put(updateTemplateStackupError(_templateStackupErrors));
    }

    const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
    const currentItem = list.find(i => i.id === verificationId);

    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : SIGN_OFF_TEMPLATE,
      menuType: "simulation"
    }));
    yield put(openTabFooter());

    const prevImpedanceList = CascadeChannels.getList(IMPEDANCE, openProjectId);
    const prevDCRList = CascadeChannels.getList(DCR, openProjectId);
    const currentImpTaskIds = prevImpedanceList.filter(item => item.signOffId === signOffVerificationId).map(item => item.id);
    const currentDCRTaskIds = prevDCRList.filter(item => item.signOffId === signOffVerificationId).map(item => item.id)

    // delete prev impedance tasks
    if (currentImpTaskIds.length && createImpTask) {
      yield call(deleteCascadeChannel, currentImpTaskIds);
      CascadeChannels.setList(IMPEDANCE, openProjectId, prevImpedanceList.filter(item => !currentImpTaskIds.includes(item.id)));
    }

    // delete prev DCR tasks
    if (currentDCRTaskIds.length && createDCRTask) {
      yield call(deleteCascadeChannel, currentDCRTaskIds);
      CascadeChannels.setList(DCR, openProjectId, prevDCRList.filter(item => !currentDCRTaskIds.includes(item.id)));
    }

    const designId = data.designId;
    createTaskMsg.push("Grouping power domains...");
    yield put(updateCreateTaskMsg(createTaskMsg))
    const option = signOffTemplateInfo.config ? signOffTemplateInfo.config.option : null;
    templateGroups.initGroups({
      signOffTemplateInfo: newTemplates,
      soc: signOffTemplateInfo.soc,
      gnd: signOffTemplateInfo.gnd,
      option
    })
    templateGroups.updateGroupData();
    const impedanceGroups = templateGroups.getMergeSocGroups(option);

    createTaskMsg.push("Loading components settings data...");
    yield put(updateCreateTaskMsg(createTaskMsg))
    const setting = yield call(componentSetting.getSetting, { designId, updateLibraryMenu: _updateLibraryMenu });
    const pinConnection = yield call(compPinMap.getPinConnection, designId);
    let compPrefixLibVersion = setting.version;

    let taskErrorMap = new Map(), pathRTaskMapping = new Map(),
      taskMapping = new Map(), pathTaskErrorMap = new Map();

    /*   createTaskMsg.push("Loading components passive values data...");
      yield put(updateCreateTaskMsg(createTaskMsg)) */
    const table = yield call(compTableHelper.getTableData, designId);

    /*  createTaskMsg.push("Tracing PMIC data and updating components, ports and targets...");
     yield put(updateCreateTaskMsg(createTaskMsg)); */

    // get user sensePin 
    const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName")

    let pinMap = yield call(compPinMap.getPinMapData, designId);

    let pinMapList = []
    yield* pinMap.map(function* (_map) {
      let data = [];
      if (_map.libraryId) {
        try {
          const res = yield call([pinMapStore, pinMapStore.getPinMapLibrary], _map, designId, "pinName")
          data = res && res.config && res.config.pinTable ? [...res.config.pinTable] : [];
        } catch (error) {
          console.error("Can not find library file");
        }
      }
      pinMapList.push({ ..._map, data })
    })

    let signOffTemplate = data.signOffTemplate || [];
    const { maxFreq, update } = createDefaultExtraction(signOffTemplate, true);
    data.extraction.FMAX = !data.extraction.FMAX || parseFloat(data.extraction.FMAX) <= 0 || (parseFloat(data.extraction.FMAX) < parseFloat(maxFreq) && update)
      ? (maxFreq || "2e8") : (data.extraction.FMAX || "2e8");

    const dcrList = CascadeChannels.getList(DCR, openProjectId);
    let dcrNameList = dcrList.map(item => item.name);
    const impList = CascadeChannels.getList(IMPEDANCE, openProjectId);
    let impNameList = impList.map(item => item.name);

    for (let i = 0; i < impedanceGroups.length; i++) {
      const impedanceGroup = impedanceGroups[i];
      let _impedanceGroup = JSON.parse(JSON.stringify(impedanceGroup))
      const Config = JSON.parse(JSON.stringify((data.extraction || new ImpExtraction())));
      const dcExtraction = data.dcExtraction || new DCExtraction();

      const Optimization = new ImpOptimization();
      const Options = new ImpOptions(data.config);
      const additionalNets = data.additionalNets || [];
      let _data = [];
      let impedancePowerNets = [], VRMInfo = {};
      createTaskMsg.push("Tracing PMIC data and updating components, ports and targets...");
      yield put(updateCreateTaskMsg(createTaskMsg));
      for (let j = 0; j < impedanceGroup.contentList.length; j++) {
        const group = impedanceGroup.contentList[j];
        const { targetIC, powerNets, groundNets, pmicPortPins, socPortPins, pmicAndNets } = group || {};
        impedancePowerNets.push(...powerNets);
        // const pmicPart = findPMIC.part;

        // add pmic part to specialized
        let _setting = yield call(componentSetting.getSetting, { designId, updateLibraryMenu: _updateLibraryMenu });
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
        compPrefixLibVersion = _setting.version;
        // const prevPmicParts = [..._setting.compPrefixLib.specialized,
        // ..._setting.compPrefixLib.linearRegulator,
        // ..._setting.compPrefixLib.linearSwitch,
        // ..._setting.compPrefixLib.switchingRegulator
        // ]
        // const existPmic = prevPmicParts.includes(pmicPart);
        // const newSpecialized = pmicPart && !existPmic ? [...new Set([..._setting.compPrefixLib.specialized, pmicPart])] : [..._setting.compPrefixLib.specialized];
        //update setting
        // if (componentSetting.compareSetting(designId, { ..._setting.compPrefixLib, specialized: newSpecialized })) {
        //   const newVersion = versionUpdate(_setting.version);
        //   const newSetting = {
        //     designId, componentSetting: {
        //       compPrefixLib: { ..._setting.compPrefixLib, specialized: newSpecialized },
        //       version: newVersion
        //     }
        //   }
        //   compPrefixLibVersion = newVersion;
        //   componentSetting.updateSetting([newSetting]);
        //   _setting = yield call(componentSetting.getSetting, { designId, updateLibraryMenu: _updateLibraryMenu });
        // }

        /* pin: 'M12', net: 'ARM_REF_CLK', pinName: 'M12', mName: 'M12' */

        let loadSelect = [{ comp: targetIC }];

        let specify = [];
        if (pmicPortPins) {
          for (let pmic of pmicPortPins) {
            const { comp, powerPins, effectivePowerPins } = pmic;
            const filterNets = pmicAndNets.filter(item => item.pmic === comp).map(item => item.net);
            const compInfo = getCascadeComponents({ pcbId: designId, name: comp });
            if (compInfo) {
              const _powerPins = powerPins && powerPins.length === 1 && powerPins[0] === "ALL Power Pins" ? effectivePowerPins : powerPins
              if (_powerPins && _powerPins.length) {
                const nets = [...compInfo.pins.values()].filter(item => _powerPins.includes(item.pin)).map(item => item.net);
                specify.push({ name: comp, nets: [...new Set(nets)], pins: _powerPins });
              } else if (filterNets && filterNets.length) {
                const pins = [...compInfo.pins.values()].filter(item => filterNets.includes(item.net)).map(item => item.pin);
                specify.push({ name: comp, nets: pins.length ? [...new Set(filterNets)] : [], pins: pins });
              } else {
                specify.push({ name: comp, nets: [], pins: [] });
              }
            }
          }
        }

        for (let powerNet of powerNets) {
          /*  createTaskMsg.push(`Tracing "${powerNet}" PMIC...`);
           yield put(updateCreateTaskMsg(createTaskMsg)); */
          VRMInfo = yield call(_getVRM, {
            designId,
            GroundNets: [...groundNets],
            PowerNets: [powerNet],
            ExtendNets: [],
            COMP_PREFIX_LIB: _setting.compPrefixLib,
            loadSelect,
            connectInductance: false,
            findExtend: true,
            isAC: true,
            PMIC: getPMIC(_setting.compPrefixLib),
            powerSwitch: _setting.compPrefixLib.powerSwitch,
            doNotStuff,
            table,
            pinMapSense,
            isTemplate: createDCRTask ? true : false,
            pmicAndNets,
            pinMap: pinMapList,
            specify,
            pinConnection
          })

          /* createTaskMsg.push(`Updating PMIC and components of "${powerNet}"...`);
          yield put(updateCreateTaskMsg(createTaskMsg)); */
          const trueVRMs = VRMInfo.VRM.map(item => item.VRM_COMP).flat(2);
          let warningLogs = [];
          specify.forEach(comp => {
            if (!trueVRMs.includes(comp.name)) {
              warningLogs.push({ title: '[VRM] - ', error: `${powerNet} - Not found ${comp.name} as a PMIC.` })
            }
          })
          const { CascadeReducer: { SignOffTemplate: { templateError: { errors, powerErrorMap, id } } } } = yield select();
          yield put(updateTemplateErrors({ errors: [...errors, ...warningLogs], powerErrorMap, id }));
          for (let item of VRMInfo.VRM) {
            //set power pin
            let pmicPowerPins = []
            for (const pmic of pmicPortPins) {
              if (item.powerPins && item.powerPins[0] && pmic.comp === item.powerPin[0].comp) {
                const pmicInfo = getCascadeComponents({ pcbId: designId, name: pmic.comp });
                //all pins
                if (pmicInfo) {
                  if (pmic.powerPins.length) {
                    pmicPowerPins.push(...pmic.effectivePowerPins)
                    item.powerPin[0].pins = pmicPowerPins
                  }
                  for (let comp of item.component || []) {
                    if (comp.pwrComp === pmic.comp) {
                      comp.powerPins = [...item.powerPin[0].pins];
                      const compInfo = getCascadeComponents({ pcbId: designId, name: comp.pwrComp });
                      if (compInfo) {
                        const currentNet = [...compInfo.pins.values()].filter(item => comp.powerPins.includes(item.pin)).map(item => item.net);
                        comp.extendNets = comp.extendNets.filter(net => currentNet.includes(net))
                      }
                    }
                  }
                }
              }
            }

            //set gnd pin
            const _findPmicIndex = pmicPortPins.findIndex(it => item.groundPin[0] && it.comp === item.groundPin[0].comp);
            if (_findPmicIndex > -1) {
              const pmic = pmicPortPins[_findPmicIndex];
              const pmicInfo = getCascadeComponents({ pcbId: designId, name: pmic.comp });
              if (pmicInfo) {
                //all pins
                const pmicPins = [...pmicInfo.pins.values()]
                if (pmicPortPins[_findPmicIndex].groundPins[0] && pmicPortPins[_findPmicIndex].groundPins[0].match(/ALL/ig)) {
                  pmicPortPins[_findPmicIndex].groundPins = pmicPins.filter(it => groundNets.includes(it.net)).map(it => it.pin);
                }
                item.groundPin[0].pins = pmicPortPins[_findPmicIndex].groundPins.length ? [...pmicPortPins[_findPmicIndex].groundPins] : item.groundPin[0].pins;
                for (let comp of item.component || []) {
                  comp.groundPins = [...item.groundPin[0].pins];
                }
              }
            }
          }

          if (createDCRTask) {
            // if template no pmic, and sensePin maybe exist
            _impedanceGroup = getDCPmicPortPins(pmicPortPins, VRMInfo, _impedanceGroup, j)
          }

          // if sense exist, change sensePort (true/false)
          // abandoned
          //   if ((VRMInfo.sensePorts && VRMInfo.sensePorts.length > 0)) {
          //     for (let sense of VRMInfo.sensePorts) {

          //       let sensePwrNet = null;
          //       if (sense.powerSensePort && sense.powerSensePort.length) {
          //         sensePwrNet = sense.powerSensePort.find(item => item && item.type === 'net').name;
          //       }
          //       /*        if (sense.groundSensePort && sense.groundSensePort.length) {
          //                senseGndNet = sense.groundSensePort.find(item => item && item.type === 'net').name;
          //              }
          // */
          //       signOffTemplate.forEach(item => {
          //         const _powerNet = item.powerNetName;
          //         const gndNet = item.gndNetName;
          //         if (item.loopDcrSpec && _powerNet === powerNet && _powerNet === sensePwrNet) {
          //           item.loopDcrSpec.sensePort = true;
          //         } else if (item.loopDcrSpec && (_powerNet === powerNet && groundNets.includes(gndNet))) {
          //           item.loopDcrSpec.sensePort = false;
          //         }
          //       })
          //     }
          //   } else {
          //     signOffTemplate.forEach(item => {
          //       const _powerNet = item.powerNetName;
          //       const gndNet = item.gndNetName;
          //       if (item.loopDcrSpec && (_powerNet === powerNet && groundNets.includes(gndNet))) {
          //         item.loopDcrSpec.sensePort = false;
          //       }
          //     })
          //   }

          //assign model to task components
          VRMInfo.Components = updateTempComponentModelToTask({ components: VRMInfo.Components, templateComponents: capComponents })
        }
        /*  createTaskMsg.push(`Updating ports and impedance targets data...`);
         yield put(updateCreateTaskMsg(createTaskMsg)); */
        //set ports and port targets

        if (createImpTask) {
          const { ports, templatePortsList } = setPortsToImpedanceTask({ socPortPins, powerNets });
          let _VRMInfo = { ...VRMInfo };

          if (Options.vrmOpen && !_VRMInfo.VRM.length) {
            for (let powerNet of powerNets) {
              const components = auroraDBJson.getComponentsByNets(designId, [powerNet]).filter(comp => ([IND, RES].includes(comp.type) || (comp.type === IGNORE && comp.pins.size > 4)) && comp.name !== targetIC);
              const _components = SortFn(components, [IGNORE, IND, RES], 'type');
              if (_components.length > 0) {
                const comp = _components[0];
                VRMInfo = yield call(_getVRM, {
                  designId,
                  GroundNets: [...groundNets],
                  PowerNets: [powerNet],
                  ExtendNets: [],
                  COMP_PREFIX_LIB: _setting.compPrefixLib,
                  loadSelect,
                  connectInductance: false,
                  findExtend: true,
                  isAC: true,
                  PMIC: getPMIC(_setting.compPrefixLib),
                  powerSwitch: _setting.compPrefixLib.powerSwitch,
                  doNotStuff,
                  table,
                  pinMapSense,
                  isTemplate: createDCRTask ? true : false,
                  pmicAndNets,
                  pinMap: pinMapList,
                  specify: [{ name: comp.name, nets: [powerNet] }],
                  pinConnection
                })
                VRMInfo.Components = updateTempComponentModelToTask({ components: VRMInfo.Components, templateComponents: capComponents })
              }
            }
          }

          _data.push({
            id: "",
            content: {
              ...VRMInfo,
              sensePorts: getACSensePorts(VRMInfo.sensePorts),
              PowerNets: [...new Set([...(VRMInfo.PowerNets || []), ...powerNets])],
              ReferenceNets: [...groundNets],
              MAIN_POWER_NETS: [...powerNets],
              MAIN_REF_NETS: [...groundNets],
              includeExtended: true,
              ports: ports || [],
              target: []
            },
            templatePortsList: templatePortsList || []
          })
        }
      }

      let name = "", defaultKey = "";
      impedancePowerNets = [...new Set(impedancePowerNets)];
      if (impedancePowerNets.length && impedancePowerNets.length <= 4) {
        name = impedancePowerNets.join("-");
        defaultKey = `${name}_`;
        if (defaultKey.length > 250) {
          name = `${data.name}_Task${i + 1}`;
          defaultKey = `${data.name}_Task`;
        }
      } else {
        name = `${data.name}_Task${i + 1}`;
        defaultKey = `${data.name}_Task`;
      }
      // create DCR
      if (createDCRTask) {
        let dcrName = name
        if (dcrNameList.includes(dcrName) || !dcrName) {
          dcrName = getDefaultName({ nameList: dcrNameList, firstIndex: 1, defaultKey, key: "" });
        }
        dcrNameList.push(dcrName)
        createTaskMsg.push(`Creating DCR task "${dcrName}"....`);
        yield put(updateCreateTaskMsg(createTaskMsg));
        const dcrInfo = yield call(createDCRByTemplate, {
          designId,
          openProjectId,
          name: dcrName,
          signOffId: signOffVerificationId,
          impedanceGroup: _impedanceGroup,
          impedancePowerNets,
          pathRTaskMapping,
          expandedKeys,
          index: i,
          signOffTemplate,
          pathTaskErrorMap,
          dcExtraction
        })
        pathRTaskMapping = dcrInfo && dcrInfo.pathRTaskMapping ? dcrInfo.pathRTaskMapping : pathRTaskMapping;
        signOffTemplate = dcrInfo && dcrInfo.signOffTemplate ? dcrInfo.signOffTemplate : signOffTemplate;
        yield delay(800)
      }

      //create impedance
      if (createImpTask) {
        let impName = name
        if (impNameList.includes(impName) || !impName) {
          impName = getDefaultName({ nameList: impNameList, firstIndex: 1, defaultKey, key: "" });
        }
        impNameList.push(impName)
        createTaskMsg.push(`Creating impedance task "${impName}"....`);
        yield put(updateCreateTaskMsg(createTaskMsg));
        const componentsDisplay = data.componentsTableDisplay || templateComponentsTableDisplay
        const impInfo = yield call(createImpByTemplate, {
          designId,
          openProjectId,
          name: impName,
          signOffId: signOffVerificationId,
          compPrefixLibVersion,
          Config,
          Options,
          Optimization,
          impedanceGroup,
          _data,
          taskErrorMap,
          impedancePowerNets,
          taskMapping,
          expandedKeys,
          index: i,
          additionalNets,
          componentsTableDisplay: [COMPONENTBASED, PARTBASED].includes(componentsDisplay) ? componentsDisplay : PARTBASED
        })
        taskMapping = impInfo && impInfo.taskMapping ? impInfo.taskMapping : taskMapping;
      }
    }
    // eslint-disable-next-line no-sequences
    const pathRObj = pathRTaskMapping ? [...pathRTaskMapping.entries()].reduce((obj, [key, value]) => (obj[key] = value, obj), {}) : null;
    // eslint-disable-next-line no-sequences
    const taskObj = taskMapping ? [...taskMapping.entries()].reduce((obj, [key, value]) => (obj[key] = value, obj), {}) : null;
    yield put(updateTemplateContent({
      taskMapping: createImpTask ? taskObj : data.taskMapping,
      pathRTaskMapping: createDCRTask ? pathRObj : data.pathRTaskMapping,
      signOffTemplate,
      extraction: data.extraction
    }));
    yield put(updateSignOffCreateTaskStatus(false));
    yield put(selectSignOffTask(null));
    yield put(saveTemplateContent());
    yield delay(800)
    yield put(updateCreateTaskMsg([]));
  } catch (error) {
    yield put(updateSignOffCreateTaskStatus(false));
    yield put(updateCreateTaskMsg([]));
    console.error(error)
  }
}

function* createDCRByTemplate({ designId, openProjectId, name, signOffId, impedanceGroup, impedancePowerNets, pathRTaskMapping, expandedKeys, index, signOffTemplate, pathTaskErrorMap, dcExtraction }) {
  const DCR_RES = yield call(createVerification, {
    dataType: DCR,
    id: openProjectId,
    name,
    open: false,
    signOffId
  })
  if (!DCR_RES) {
    return;
  }

  const { cascadeChannel } = DCR_RES
  const DCRList = cascadeChannel["PathR_Explorer"];
  const { list: newDCRList } = DCRList ? getExplorerTree(DCRList, DCR, openProjectId) : [];
  const currentDCR = newDCRList.find(item => item.name === name);
  const dcrId = currentDCR ? currentDCR.id : "";

  yield call(selectTaskPCB, { verificationId: dcrId, designId })

  // create DCR data
  const resistanceData = []
  let DCR_index = 0;
  signOffTemplate.forEach(item => {
    delete item.pmicPortPins;
  })
  let pairIndexList = [];
  for (const content of impedanceGroup.contentList) {
    const { socPortPins, pmicPortPins, powerNets } = content;
    for (const soc of socPortPins) {
      for (const pmic of pmicPortPins) {
        // init
        let PowerData = { point1s: [], point2s: [], net: [] };
        PowerData.point1s.push({
          comp: soc.comp,
          net: content.powerNets[0],
          pins: soc.effectivePowerPins && soc.effectivePowerPins.length ? soc.effectivePowerPins : []
        });
        PowerData.net.push(content.powerNets[0]);
        for (let pwrItem of (pmic.powerList || [])) {
          const pwrPoint = {
            comp: pwrItem.powerSense || pwrItem.comp,
            net: pwrItem.extendNet,
            pins: pwrItem.effectivePowerPins || []
          }
          if (!PowerData.point2s.find(it => _.isEqual(it, pwrPoint))) {
            PowerData.point2s.push(pwrPoint);
            PowerData.net.push(pwrItem.extendNet);
          }
        }
        PowerData.net = [...new Set([...(PowerData.net || [])])].filter(item => !!item);
        PowerData.id = ""
        PowerData.index = ++DCR_index;
        PowerData.templateIndex = soc.templateIndex;
        PowerData.sense = pmic.sense;
        const powerIndex = DCR_index - 1;
        const pwrErrorList = checkPathRData(PowerData, "Power", content.powerNets[0]);


        let GroundData = { point1s: [], point2s: [], net: [] };
        GroundData.point1s.push({
          comp: soc.comp,
          net: content.groundNets[0],
          pins: soc.effectiveGroundPins && soc.effectiveGroundPins.length ? soc.effectiveGroundPins : []
        });
        GroundData.net.push(content.groundNets[0]);
        for (let gndItem of (pmic.gndList || [])) {
          const gndPoint = {
            comp: gndItem.groundSense || gndItem.comp,
            net: gndItem.extendNet,
            pins: gndItem.effectiveGroundPins || []
          }
          if (!GroundData.point2s.find(it => _.isEqual(it, gndPoint))) {
            GroundData.point2s.push(gndPoint);
            GroundData.net.push(gndItem.extendNet);
          }
        }
        GroundData.net = [...new Set([...(GroundData.net || [])])].filter(item => !!item);
        GroundData.id = ""
        GroundData.index = ++DCR_index;
        GroundData.templateIndex = soc.templateIndex;
        GroundData.sense = pmic.sense;
        const gndIndex = DCR_index - 1;
        const gndErrorList = checkPathRData(GroundData, "Ground", content.powerNets[0]);

        resistanceData.push(PowerData, GroundData);

        let errors = [...pwrErrorList, ...gndErrorList];

        // pmic power/gnd - pmic sense
        let pmicToSensePorts = {};
        if (pmic.sense) {
          const powerPmicPorts = pmicPortPins.filter(item => !item.sense);
          for (let powerPmic of powerPmicPorts) {
            // power - pmic
            let pmicPowerData = { point1s: [], point2s: [], net: [] };
            for (let pwrItem of (powerPmic.powerList || [])) {
              const pwrPoint = {
                comp: pwrItem.comp,
                net: pwrItem.extendNet,
                pins: pwrItem.effectivePowerPins || []
              }
              if (!pmicPowerData.point1s.find(it => _.isEqual(it, pwrPoint))) {
                pmicPowerData.point1s.push(pwrPoint);
                pmicPowerData.net.push(pwrItem.extendNet);
              }
            }

            for (let pwrItem of (pmic.powerList || [])) {
              const pwrPoint = {
                comp: pwrItem.powerSense || pwrItem.comp,
                net: pwrItem.extendNet,
                pins: pwrItem.effectivePowerPins || []
              }
              if (!pmicPowerData.point2s.find(it => _.isEqual(it, pwrPoint))) {
                pmicPowerData.point2s.push(pwrPoint);
                pmicPowerData.net.push(pwrItem.extendNet);
              }
            }
            if (_.isEqual(pmicPowerData.point1s, pmicPowerData.point2s)) {
              pmicPowerData = undefined;
            } else {
              pmicPowerData.net = [...new Set([...(pmicPowerData.net || [])])].filter(item => !!item);
              pmicPowerData.id = ""
              pmicPowerData.index = ++DCR_index;
              pmicPowerData.templateIndex = soc.templateIndex;
              pmicPowerData.sense = pmic.sense;
              pmicPowerData.pmicSense = true;
            }

            // gnd - pmic
            let pmicGroundData = { point1s: [], point2s: [], net: [] };
            for (let gndItem of (powerPmic.gndList || [])) {
              const gndPoint = {
                comp: gndItem.comp,
                net: gndItem.extendNet,
                pins: gndItem.effectiveGroundPins || []
              }
              if (!pmicGroundData.point1s.find(it => _.isEqual(it, gndPoint))) {
                pmicGroundData.point1s.push(gndPoint);
                pmicGroundData.net.push(gndItem.extendNet);
              }
            }

            for (let gndItem of (pmic.gndList || [])) {
              const gndPoint = {
                comp: gndItem.groundSense || gndItem.comp,
                net: gndItem.extendNet,
                pins: gndItem.effectiveGroundPins || []
              }
              if (!pmicGroundData.point2s.find(it => _.isEqual(it, gndPoint))) {
                pmicGroundData.point2s.push(gndPoint);
                pmicGroundData.net.push(gndItem.extendNet);
              }
            }
            if (_.isEqual(pmicGroundData.point1s, pmicGroundData.point2s)) {
              pmicGroundData = undefined;
            } else {
              pmicGroundData.net = [...new Set([...(pmicGroundData.net || [])])].filter(item => !!item);
              pmicGroundData.id = ""
              pmicGroundData.index = ++DCR_index;
              pmicGroundData.templateIndex = soc.templateIndex;
              pmicGroundData.sense = pmic.sense;
              pmicGroundData.pmicSense = true;
            }

            if (pmicPowerData) {
              resistanceData.push(pmicPowerData)
              pmicToSensePorts['pmicToSensePower'] = {
                point1s: JSON.parse(JSON.stringify(pmicPowerData.point1s)),
                point2s: JSON.parse(JSON.stringify(pmicPowerData.point2s))
              }
            }

            if (pmicGroundData) {
              resistanceData.push(pmicGroundData)
              pmicToSensePorts['pmicToSenseGnd'] = {
                point1s: JSON.parse(JSON.stringify(pmicGroundData.point1s)),
                point2s: JSON.parse(JSON.stringify(pmicGroundData.point2s))
              }
            }
          }
        }

        if (errors && errors.length) {
          if (pathTaskErrorMap.has(content.powerNets[0])) {
            pathTaskErrorMap.set(content.powerNets[0], [...pathTaskErrorMap.get(content.powerNets[0]), { error: errors, id: dcrId, name: name }])
          } else {
            pathTaskErrorMap.set(content.powerNets[0], [{ error: errors, id: dcrId, name: name }]);
          }
        }

        let spec = "";
        //update pmic pins to template json
        let index = -1;
        if (soc.templateIndex) {
          index = signOffTemplate.findIndex(temp => temp.index === soc.templateIndex);
        }

        if (index < 0) {
          index = signOffTemplate.findIndex(temp =>
            temp.compType !== "PMIC"
            && powerNets.includes(temp.powerNetName)
            && _.isEqual(temp.effectivePowerPins, soc.effectivePowerPins)
            && _.isEqual(temp.effectiveGroundPins, soc.effectiveGroundPins)
          )
        }

        if (index > -1) {
          const loopDcrSpec = signOffTemplate[index].loopDcrSpec || {};
          const unit = loopDcrSpec.unit ? loopDcrSpec.unit.replace("Ω", "").replace("Ω", "") : "m";
          spec = `${pmic.sense ? loopDcrSpec.senseSpec : loopDcrSpec.pmicSpec || ""}${unit}`;
          const powerText = pmic.sense ? 'sensePower' : 'power';
          const gndText = pmic.sense ? 'senseGnd' : 'gnd';
          let prevPorts = signOffTemplate[index].pathRPorts || {};
          const pmicPorts = {
            ...prevPorts,
            ...pmicToSensePorts,
            [powerText]: {
              point1s: JSON.parse(JSON.stringify(PowerData.point1s)),
              point2s: JSON.parse(JSON.stringify(PowerData.point2s))
            },
            [gndText]: {
              point1s: JSON.parse(JSON.stringify(GroundData.point1s)),
              point2s: JSON.parse(JSON.stringify(GroundData.point2s))
            }
          };
          signOffTemplate[index].pathRPorts = pmicPorts;
        }
        if (!pmic.sense) {
          pairIndexList.push({ ids: [powerIndex, gndIndex], spec });
        }
      }
    }
  }

  const res = yield call(updateDCRInfo, { verificationId: dcrId, dcr: resistanceData, pairs: [], designName: designConstructor.getDesignName(designId), config: dcExtraction });
  //add points group and group spec
  const pairs = pairIndexList.map(item => {
    const ids = (res.dcr || []).filter((it, _index) => item.ids.includes(_index)).map(it => it.id);
    if (ids.length) {
      return { ids, spec: item.spec }
    } else {
      return null;
    }
  }).filter(it => !!it);
  //update data
  const dataList = (res.dcr || []).map((item, index) => {
    let _net = [];
    if (!item.content.net || !item.content.net.length) {
      if (Array.isArray(item.content.point1s)) {
        _net.push(...item.content.point1s.map(it => it.net).filter(it => !!it))
      } else if (item.content.point1 && item.content.point1.net) {
        _net.push(item.content.point1.net)
      }
      if (Array.isArray(item.content.point2s)) {
        _net.push(...item.content.point2s.map(it => it.net).filter(it => !!it))
      } else if (item.content.point2 && item.content.point2.net) {
        _net.push(item.content.point2.net)
      }
    } else {
      _net = item.content.net
    }
    return ({
      point1s: item.content.point1s,
      point2s: item.content.point2s,
      net: [...new Set(_net)],
      id: item.id,
      resistance: item.resistance,
      index: index + 1,
      spec: item.spec
    })
  });

  // check resistance data
  const { debugMonitor, warningMessage, _data } = checkResistanceData({ data: dataList, designId, getMessage: getCheckDataMessage });

  yield call(updateDCRInfo, { verificationId: dcrId, dcr: _data, pairs, designName: res.designName, config: dcExtraction, errorMessage: [...debugMonitor] });

  for (let powerNetName of impedancePowerNets) {

    if (pathRTaskMapping.has(powerNetName)) {
      pathRTaskMapping.set(powerNetName, [...pathRTaskMapping.get(powerNetName), dcrId])
    } else {
      pathRTaskMapping.set(powerNetName, [dcrId]);
    }
  }
  const { CascadeReducer: { SignOffTemplate: { pathTaskErrorMap: prevErrorMap } } } = yield select();
  let newErrorMap = prevErrorMap ? prevErrorMap : {};
  newErrorMap[signOffId] = pathTaskErrorMap;
  yield put(updateSignOffTaskError({ pathTaskErrorMap: newErrorMap }));

  if (index === 0) {
    //expand impedance explorer
    yield put(updateExpand([...new Set([...expandedKeys, `DCR_group-${openProjectId}`])]))
  }

  yield put(updateWarningMsg(warningMessage));

  // run
  yield put(startDCRSimulation(dcrId, resistanceData, designId, signOffId))
  yield put(uploadDCRErrorMsg(debugMonitor));

  return { pathRTaskMapping, signOffTemplate }
}

function* createImpByTemplate({ designId, openProjectId, name, signOffId, compPrefixLibVersion, Config, Options, Optimization, impedanceGroup, _data, taskErrorMap, impedancePowerNets, taskMapping, expandedKeys, index, additionalNets, componentsTableDisplay }) {
  // create impedance
  const res = yield call(createVerification, {
    dataType: IMPEDANCE,
    id: openProjectId,
    name,
    open: false,
    signOffId
  })

  if (!res) {
    return { taskMapping }
  }

  const { cascadeChannel } = res;
  const channelType = "Impedance_Explorer";
  const { list: impedanceList } = cascadeChannel[channelType] ? getExplorerTree(cascadeChannel[channelType], IMPEDANCE, openProjectId) : [];
  const currentItem = impedanceList.find(item => item.name === name);
  const id = currentItem ? currentItem.id : null;
  if (!id) {
    return { taskMapping }
  }
  //update impedance setup
  const config = Config ? Config : new ImpExtraction();
  const targetNets = _data.map(domain => domain.content.MAIN_POWER_NETS).flat(2);
  const setup = {
    COMP_PREFIX_LIB: new CascadeCompPrefixVersion(compPrefixLibVersion),
    Config: config,
    Options: Options ? Options : new ImpOptions(),
    Optimization: Optimization ? Optimization : new ImpOptimization(),
    targetIC: impedanceGroup.targetIC,
    setupVersion: IMPEDANCE_VERSION,
    type: designConstructor.getDesignType(designId),
    designName: designConstructor.getDesignName(designId),
    targetNets: [...new Set(targetNets)],
    componentsTableDisplay
  }
  yield call(saveImpedanceSetup, {
    verificationId: id,
    content: setup
  })
  //select pcb
  yield call(selectTaskPCB, { verificationId: id, designId });
  // check data error
  const { layouts } = upgradeSetupDataToMulti({ designId, additionalNets, powerDomains: _data, name, ...setup })
  const { error, data: newData } = ImpedanceErrorCheck(layouts);
  _data = newData;
  //update domain
  const _res = yield call(saveImpedanceDomain, {
    verificationId: id,
    layouts: newData
  });
  for (let powerNetName of impedancePowerNets) {
    const filterError = powerNetName ? error.filter(item => item.match(powerNetName)) : null;
    if (filterError && filterError.length && powerNetName) {
      if (taskErrorMap.has(powerNetName)) {
        taskErrorMap.set(powerNetName, [...taskErrorMap.get(powerNetName), { error: filterError, id, name: name }])
      } else {
        taskErrorMap.set(powerNetName, [{ error: filterError, id, name: name }]);
      }
    }
    if (taskMapping.has(powerNetName)) {
      taskMapping.set(powerNetName, [...taskMapping.get(powerNetName), id])
    } else {
      taskMapping.set(powerNetName, [id]);
    }
  }

  const { CascadeReducer: { SignOffTemplate: { taskErrorMap: prevErrorMap } } } = yield select();
  let newErrorMap = prevErrorMap ? prevErrorMap : {};
  newErrorMap[signOffId] = taskErrorMap;
  yield put(updateSignOffTaskError({ taskErrorMap: newErrorMap }));
  if (index === 0) {
    //expand impedance explorer
    yield put(updateExpand([...new Set([...expandedKeys, `impedance_explorer_group-${openProjectId}`])]))
  }
  // run
  yield put(startImpedanceSimulation(id, {
    data: _res && _res.layouts ? _res.layouts : [],
    signOffVerificationId: signOffId,
    designId,
    designType: designConstructor.getDesignType(designId) || IMPEDANCE_PCB,
    options: ['impedance']
  }))

  return { taskMapping }
}

function updateTempComponentModelToTask({ components, templateComponents }) {
  for (let i = 0; i < components.length; i++) {
    const comp = components[i];
    if (comp.COMP_TYPE !== "Cap") {
      continue;
    }

    //find model in prev comp
    const findComp = templateComponents.find(item => item.name === comp.name || item.part === comp.part);
    if (findComp && findComp.models && findComp.models.length) {
      components[i].models = JSON.parse(JSON.stringify(findComp.models));
      if (findComp.applySweep) {
        components[i].applySweep = findComp.applySweep
      }
      continue;
    }
  }

  return components;
}

export function* updateComponentDecapModel({
  components = [],
  prevComps = [],
  designId,
  type,
  existModelParts = [],
  saveLib = true,
  newNoModelCompNames,
  voltage
}) {
  const { CascadeReducer: { project: { defaultDecap, applyModelList, openProjectId } } } = yield select()
  const customLibrary = yield call(SystemLibrary.getChildren, { folderId: CUSTOM_LIBRARY, type: CUSTOM_LIBRARY })
  let _components = [], _existModelParts = []
  try {
    const value = yield call(updateCompDecapModel, {
      components,
      prevComps,
      designId,
      type,
      existModelParts,
      newNoModelCompNames,
      voltage,
      defaultDecap,
      applyModelList,
      customLibrary,
      product: CASCADE
    })
    _components = value.components.map(comp => {
      if (comp.model) {
        return { ...comp, models: [{ ...comp.model, key: 0 }] };
      }
      return comp;
    });
    _existModelParts = value.existModelParts;
    const _applyModelList = value.applyModelList;
    if (_applyModelList) {
      yield put(updateModelSetting(_applyModelList));
      if (saveLib) {
        yield call(updateLibSetting, { librarySettings: _applyModelList, projectId: openProjectId });
      }
    }
  } catch (error) {
    console.error(error)
  }

  return type === "re-assign" ? {
    components: _components,
    existModelParts: _existModelParts,
  } : _components;
}

function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}
let runningUpdateTask = null;

function* updateRunningList(action) {
  const { signOffList, projectId } = action;
  if (runningUpdateTask) {
    yield cancel(runningUpdateTask);
  }
  runningUpdateTask = yield fork(_updateRunningList, { signOffList, projectId });
}

function* _updateRunningList(action) {
  const { signOffList, projectId } = action;

  let first = true,
    delayTime = 5000,
    isWhile = true,
    allList = [...signOffList];

  while (isWhile) {
    if (!first) {
      yield delay(delayTime);//5000
    }

    first = false;

    const { LoginReducer: { pageType },
      CascadeReducer: { project: { treeItems, openProjectId, viewList }, SignOffTemplate: { verificationId } } } = yield select();
    //if project close , return
    if (pageType !== CASCADE || openProjectId !== projectId) {
      return;
    }
    let prevList = JSON.parse(JSON.stringify(allList));
    let res = null;
    try {
      res = yield call(getProjectChildren, projectId);
      if (!res || !res.cascadeChannel || !res.cascadeChannel.Sign_Off_Template) {
        isWhile = false;
        return;
      }
    } catch (error) {
      console.error(error);
      isWhile = false;
      return;
    }

    let _treeItems = [...treeItems];
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
    if (projectIndex < 0) {
      isWhile = false;
      return;
    }
    const { verificationIndex: DCR_INDEX } = getExplorerInfo(DCR);
    const { verificationIndex: IMPEDANCE_INDEX } = getExplorerInfo(IMPEDANCE);
    const { verificationIndex: SIGN_OFF_INDEX } = getExplorerInfo(SIGN_OFF_TEMPLATE);
    const prevTemplateList = _treeItems[PROJECTS_INDEX].children[projectIndex].children[SIGN_OFF_INDEX].children ?
      JSON.parse(JSON.stringify(_treeItems[PROJECTS_INDEX].children[projectIndex].children[SIGN_OFF_INDEX].children)) : [];
    const prevImpedanceList = _treeItems[PROJECTS_INDEX].children[projectIndex].children[IMPEDANCE_INDEX].children ?
      JSON.parse(JSON.stringify(_treeItems[PROJECTS_INDEX].children[projectIndex].children[IMPEDANCE_INDEX].children)) : [];
    const prevDCRList = _treeItems[PROJECTS_INDEX].children[projectIndex].children[DCR_INDEX].children ?
      JSON.parse(JSON.stringify(_treeItems[PROJECTS_INDEX].children[projectIndex].children[DCR_INDEX].children)) : [];
    const { list: _signOffList } = getExplorerTree(res.cascadeChannel.Sign_Off_Template, SIGN_OFF_TEMPLATE, openProjectId, prevTemplateList);
    const { list: _impedanceList, treeTaskList } = getExplorerTree(res.cascadeChannel.Impedance_Explorer, IMPEDANCE, openProjectId, prevImpedanceList);
    const { list: _DCRList, treeTaskList: DCRTreeTaskList } = getExplorerTree(res.cascadeChannel.PathR_Explorer, DCR, openProjectId, prevDCRList);

    CascadeChannels.setList(SIGN_OFF_TEMPLATE, projectId, _signOffList);
    CascadeChannels.setList(IMPEDANCE, projectId, _impedanceList);
    CascadeChannels.setList(DCR, projectId, _DCRList);



    const findPrev = prevList.find(item => item.id === verificationId);
    const findCurrIndex = _signOffList.findIndex(item => item.id === verificationId);
    const findCurr = findCurrIndex > -1 ? _signOffList[findCurrIndex] : null;

    if ((findCurr && findCurr.status === SIGN_OFF_UPDATE) || (findPrev && findCurr
      && findCurr.status !== findPrev.status
      && [SIGN_OFF_FINISH, SIGN_OFF_UPDATE].includes(findCurr.status))) {
      try {
        //update status to server
        yield call(updateSignOffStatus, { verificationId });
      } catch (error) {
        console.error(error)
      }

      if (viewList.includes(SIGN_OFF_TEMPLATE)) {
        //update content
        yield put(getSignOffContent(verificationId));
      }
      //update status
      _signOffList[findCurrIndex].status = findCurr.status;
    }

    _treeItems[PROJECTS_INDEX].children[projectIndex].children[SIGN_OFF_INDEX].children = _signOffList;
    _treeItems[PROJECTS_INDEX].children[projectIndex].children[IMPEDANCE_INDEX].children = treeTaskList;
    _treeItems[PROJECTS_INDEX].children[projectIndex].children[DCR_INDEX].children = DCRTreeTaskList;

    allList = JSON.parse(JSON.stringify(_signOffList));
    yield put(updateTreeList({ treeItems: [..._treeItems] }));

    const allRunningIds = _signOffList.filter(item => [SIGN_OFF_RUNNING, SIGN_OFF_UPDATE].includes(item.status)).map(item => item.id);
    if (!allRunningIds.length) {
      isWhile = false;
      return;
    }
  }
}

function* _updatePorts(action) {
  // save updated ports
  const { ports, record, defaultPortIndexList } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  let signOffTemplate = data.signOffTemplate || [];

  const defaultTemplate = signOffTemplate.find(item => item.index === defaultPortIndexList[0]);

  if (!defaultTemplate) {
    return;
  }

  try {
    const currKey = `${record.powerNetName}::${record.refdes || data.soc}::${record.gndNetName || data.gnd}`
    //filter prev added ports
    signOffTemplate = signOffTemplate.filter(item => {
      if (item.compType !== record.compType) {
        return true
      }
      const key = `${item.powerNetName}::${item.refdes || data.soc}::${item.gndNetName || data.gnd}`;
      if (key !== currKey) {
        return true;
      } else {
        if (defaultPortIndexList.includes(item.index)) {
          return true;
        } else {
          return false;
        }
      }
    });
    //get all power pins and gnd pins 
    const { powerPins, gndPins } = getPowerGndPinsByNetAndComp({
      comp: record.refdes || data.soc,
      powerNet: record.powerNetName,
      gndNet: record.gndNetName || data.gnd,
      designId: data.designId
    })
    for (let port of ports) {
      //judge all power and gnd pins
      let portPowerPins = [...port.powerPins], portGndPins = [...port.referencePins];
      if (port.powerPins.length && JSON.stringify(port.powerPins.sort()) === JSON.stringify(powerPins.sort())) {
        portPowerPins = ["ALL Power Pins"];
      }

      if (port.referencePins.length && JSON.stringify(port.referencePins.sort()) === JSON.stringify(gndPins.sort())) {
        portGndPins = ["ALL Ground Pins"];
      }

      const temIndex = port.index ? signOffTemplate.findIndex(item => item.index === port.index) : -1;
      if (temIndex > -1) {
        signOffTemplate[temIndex].powerPins = [...portPowerPins];
        signOffTemplate[temIndex].groundPins = [...portGndPins];
        signOffTemplate[temIndex].effectivePowerPins = [...port.powerPins];
        signOffTemplate[temIndex].effectiveGroundPins = [...port.referencePins];
      } else {
        const index = getDefaultIndex(signOffTemplate.length, signOffTemplate.map(item => item.index));
        //impedance
        let impedance = JSON.parse(JSON.stringify(defaultTemplate.impedance || []));
        impedance = impedance.map(item => { delete item.actualValue; item.value = ""; return item });
        //loopDcrSpec
        const findLoopDcr = defaultTemplate.loopDcrSpec || {};
        //couplingImpedance
        let couplingImpedances = JSON.parse(JSON.stringify(defaultTemplate.couplingImpedance || []));
        couplingImpedances = couplingImpedances.map(item => { delete item.couplingResult; item.value = ""; return item });
        //shortSocCoupling
        let shortSocCoupling = JSON.parse(JSON.stringify(defaultTemplate.shortSocCoupling || []));
        shortSocCoupling = shortSocCoupling.map(item => { delete item.couplingResult; item.value = ""; return item });
        //inductanceSpec
        let inductances = JSON.parse(JSON.stringify(defaultTemplate.inductanceSpec || []));
        inductances = inductances.map(item => { delete item.actualValue; item.value = ""; return item });

        signOffTemplate.push(
          {
            ...JSON.parse(JSON.stringify(defaultTemplate)),
            index,
            resultExists: false,
            impedance,
            couplingImpedance: couplingImpedances,
            shortSocCoupling: shortSocCoupling,
            inductanceSpec: inductances,
            loopDcrSpec: {
              pmicSpec: "",
              pmicUnit: findLoopDcr ? findLoopDcr.pmicUnit : findLoopDcr.unit ? findLoopDcr.unit : 'mΩ',
              senseSpec: "",
              senseUnit: findLoopDcr ? findLoopDcr.senseUnit : findLoopDcr.unit ? findLoopDcr.unit : 'mΩ',
              sensePort: findLoopDcr.sensePort ? findLoopDcr.sensePort : false
            },
            powerPins: [...portPowerPins],
            groundPins: [...portGndPins],
            effectivePowerPins: [...port.powerPins],
            effectiveGroundPins: [...port.referencePins],
            groupKey: record.groupKey
          }
        )
      }
    }
    //delete ports template
    const portIndexList = ports.filter(item => !!item.index).map(item => item.index);
    const deleteIndexList = defaultPortIndexList.filter(item => !portIndexList.includes(item));

    if (ports.length === 0) {
      //if ports is empty, keeping one template
      const _deleteIndexList = deleteIndexList.filter((item, index) => index > 0);
      signOffTemplate = signOffTemplate.filter(item => !_deleteIndexList.includes(item.index));
      //clear ports
      const index = signOffTemplate.findIndex(item => item.index === deleteIndexList[0]);
      if (index > -1) {
        signOffTemplate[index].powerPins = [];
        signOffTemplate[index].groundPins = [];
        signOffTemplate[index].effectivePowerPins = [];
        signOffTemplate[index].effectiveGroundPins = [];
      }
    } else {
      signOffTemplate = signOffTemplate.filter(item => !deleteIndexList.includes(item.index));
    }

    yield put(updateTemplateContent({ signOffTemplate }));
    yield put(updateTemplateSetup(true));
    yield put(saveTemplateContent());
  } catch (e) {
    console.error(e)
  }
}

function* _saveCapModel(action) {
  const { record, value, models, apply, applyModelList, applySweep } = action;

  const { CascadeReducer: { SignOffTemplate: { data = {} }, project: { openProjectId } } } = yield select();
  let capComponents = data.components ? [...data.components] : [];
  const { part, components } = record;
  const compName = components.map(item => item.name);
  const _applySweep = models.length > 1 && applySweep
  capComponents = capComponents.map(comp => {
    if ((comp.part === part && apply) || compName.includes(comp.name)) {
      return { ...comp, value, models: models.map(model => { return { ...model, apply: apply } }), applySweep: _applySweep };
    } else {
      if (comp.applySweep && _applySweep) {
        return { ...comp, applySweep: false }
      }
      return { ...comp }
    }
  })
  yield put(updateTemplateContent({ components: capComponents }));
  yield put(saveTemplateContent());
  if (apply) {
    try {
      yield call(updateLibSetting, { librarySettings: applyModelList, projectId: openProjectId })
      yield put(updateModelSetting(applyModelList))
    } catch (error) {
      console.error(error)
    }
  }
}

function* _updateSoc(action) {
  const { name } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  yield put(updateTemplateContentLoading(true))

  let _data = { ...data };
  yield put(updateTemplateContent({ soc: name }));
  _data.soc = name;
  yield put(updateTemplate({ data: _data }))
}

function* updateTemplateByCompSettings(action) {
  const { update } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {}, verificationId }, project: { openProjectId } } } = yield select();
  let _data = { ...data };
  yield put(updateTemplateContentLoading(true));
  if (update && data.designId) {
    let prevComps = JSON.parse(JSON.stringify(_data.components || []));
    yield put(saveTemplateLogs([], true));
    const saveLogs = (logs) => {
      store.dispatch({ type: UPDATE_SIGN_OFF_TEMPLATE_LOGS, logs })
    }
    const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
    const currentItem = list.find(i => i.id === verificationId);
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : SIGN_OFF_TEMPLATE,
      menuType: "simulation"
    }));
    //error check and get cap components by sign off template
    const { errors, signOffTemplateInfo, powerErrorMap } = yield call(checkAndUpdateSignOffTemplateContent, {
      signOffTemplateInfo: _data,
      saveLogs
    });
    signOffTemplateInfo.components = yield call(updateComponentDecapModel, {
      designId: data.designId,
      components: signOffTemplateInfo.components,
      prevComps
    })
    _data = signOffTemplateInfo;
    yield put(updateTemplateErrors({ errors, powerErrorMap, id: verificationId }));
    yield put(updateTemplateContent({ ..._data }));
  } else {
    const _setting = yield call(componentSetting.getSetting, { designId: _data.designId, updateLibraryMenu: _updateLibraryMenu });
    _data.compSettingVersion = _setting.version;
    yield put(updateTemplateContent({ ..._data }));
  }
  yield put(updateTemplateComponentSettingStatus(false));
  yield put(updateTemplateSetup(true));
  yield put(updateTemplateContentLoading(false));
  yield put(saveTemplateContent());
}

function* _updateGnd(action) {
  const { name } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  yield put(updateTemplateContentLoading(true))

  let _data = { ...data };
  yield put(updateTemplateContent({ gnd: name }));
  _data.gnd = name;
  yield put(updateTemplate({ data: _data }))
}

function* updateTemplateInfo(action) {
  const { data, updatePcb } = action;
  let _data = { ...data };
  const { CascadeReducer: { SignOffTemplate: { verificationId }, project: { openProjectId } } } = yield select();
  yield delay(50)
  try {
    if (updatePcb) {
      yield call(getAuroraDB, _data.designId);
    }
    let prevComps = JSON.parse(JSON.stringify(_data.components || []));
    yield put(saveTemplateLogs([], true));
    const saveLogs = (logs) => {
      store.dispatch({ type: UPDATE_SIGN_OFF_TEMPLATE_LOGS, logs })
    }
    const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
    const currentItem = list.find(i => i.id === verificationId);
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: verificationId,
      verificationName: currentItem ? currentItem.name : SIGN_OFF_TEMPLATE,
      menuType: "simulation"
    }));
    //error check and get cap components by sign off template
    const { errors, signOffTemplateInfo, powerErrorMap } = yield call(checkAndUpdateSignOffTemplateContent, {
      signOffTemplateInfo: _data,
      updatePcb,
      saveLogs
    });
    signOffTemplateInfo.components = yield call(updateComponentDecapModel, {
      designId: data.designId,
      components: signOffTemplateInfo.components,
      prevComps
    })
    _data = signOffTemplateInfo;
    yield put(updateTemplateErrors({ errors, powerErrorMap, id: verificationId }));
    yield put(updateTemplateContent({ ..._data }));
    yield put(updateTemplateContentLoading(false));
    yield put(updateTemplateSetup(true));
    if (updatePcb) {
      yield call(selectTaskPCB, { verificationId, designId: data.designId });
      yield put(updateTemplatePCBLoading(false));
    }
    yield put(saveTemplateContent());
  } catch (error) {
    console.error(error);
    yield put(updateTemplateContentLoading(false));
  }
}

function* _saveConfig(action) {
  const { config, update } = action;
  yield put(updateTemplateContent({ config }));
  yield put(saveTemplateContent(update));
}

function* _reAssignModel() {
  const { CascadeReducer: { SignOffTemplate: { data } } } = yield select();

  yield put(updateModelAssignLoading(true));
  let _components = [...(data.components || [])];

  const info = yield call(updateComponentDecapModel, {
    designId: data.designId,
    components: _components,
    prevComps: [],
    type: "re-assign"
  });
  _components = info.components.map(comp => {
    if (comp.model) {
      return { ...comp, models: [{ ...comp.model, key: 0 }] };
    }
    return comp;
  });;
  yield put(updateTemplateContent({ components: _components }));
  yield put(updateModelAssignLoading(false));
  yield put(saveTemplateContent());
}

function* _downloadS1PFile(action) {
  const { record } = action;
  const { CascadeReducer: { SignOffTemplate: { verificationId } } } = yield select();
  try {
    const res = yield call(downloadTemplateS1P, {
      groundPins: record.effectiveGroundPins,
      powerPins: record.effectivePowerPins,
      powerNetName: record.powerNetName,
      id: verificationId
    })
    if (res) {
      FileSaver.saveAs(res, `impedance.s1p`);
    } else {
      message.error("Download s1p file failed!")
    }
  } catch (error) {
    console.error(error);
    message.error("Download s1p file failed!")
  }
}

function* _saveImpedanceSpecValue(action) {
  const { value, record, dataIndex, impType } = action;
  //impType -> impedance / couplingImpedance
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  let signOffTemplate = data.signOffTemplate || [];
  const index = signOffTemplate.findIndex(item => item.index === record.index);
  if (index < 0) {
    return;
  }
  //impedance_mΩ_25MHz
  //impedance_res
  //impedance_ind
  //couplingImpedance_mΩ_25MHz
  //couplingImpedance_mΩ_2$5MHz -> couplingImpedance_mΩ_2.5MHz
  if (!signOffTemplate[index][impType]) {
    signOffTemplate[index][impType] = [];
  }
  if (["impedance_res", "impedance_ind", "couplingImpedance_res", "couplingImpedance_ind", "shortSocCoupling_res", "shortSocCoupling_ind"].includes(dataIndex)) {
    const [, valueType] = strDelimited(dataIndex, "_");
    const imIndex = signOffTemplate[index][impType].findIndex(item => item.res && item.ind);
    if (imIndex < 0) {
      let rUnit = "mΩ", iUnit = "pH";
      const findRLImpedance = signOffTemplate.find(item => item[impType] && item[impType].find(it => it.res && it.ind));
      if (findRLImpedance) {
        const findRL = findRLImpedance[impType].find(it => it.res && it.ind) || {};
        rUnit = findRL.res.unit || "mΩ";
        iUnit = findRL.ind.unit || "pH";
      }
      const spec = {
        res: { unit: rUnit, value: "" },
        ind: { unit: iUnit, value: "" }
      };
      spec[valueType].value = value;
      signOffTemplate[index][impType] = [spec]
    } else {
      signOffTemplate[index][impType][imIndex][valueType].value = value;
    }
  } else {
    const [, unit, freq] = strDelimited(dataIndex, "_");
    const actualFreq = frequencyStrFormat(freq, true);

    const imIndex = signOffTemplate[index][impType].findIndex(item => ohmUnitConversion(item.unit) === ohmUnitConversion(unit) && item.frequency === actualFreq);
    if (imIndex < 0) {
      signOffTemplate[index][impType].push({
        value,
        unit: ohmUnitConversion(unit),
        frequency: actualFreq
      })
    } else {
      signOffTemplate[index][impType][imIndex].unit = ohmUnitConversion(signOffTemplate[index][impType][imIndex].unit);
      signOffTemplate[index][impType][imIndex].value = value;
    }
  }
  yield put(updateTemplateContent({ signOffTemplate }));
  /*  yield put(updateTemplateSetup(true)); */
  yield put(updateTemplateSetupValue(true));
  yield put(saveTemplateContent(true));
}

function* _saveTemplateValue(action) {
  yield put(updateTemplateContentLoading(true));
  const { value, record, dataIndex } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {}, verificationId } } } = yield select();
  let _data = { ...data }
  let signOffTemplate = data.signOffTemplate || [];
  const index = signOffTemplate.findIndex(item => item.index === record.index);
  if (index < 0) {
    return;
  }
  if (dataIndex === "voltage") {
    if (typeof (signOffTemplate[index].voltage) !== "object") {
      signOffTemplate[index].voltage = {
        unit: "V",
        value,
      }
    }
    signOffTemplate[index].voltage.value = value;
  } else if (dataIndex === "portName") {
    signOffTemplate[index].portName = value;
  } else if (dataIndex === "loopDcrSpec_soc") {
    signOffTemplate[index].loopDcrSpec.pmicSpec = value;
  } else if (dataIndex === "loopDcrSpec_sense") {
    signOffTemplate[index].loopDcrSpec.senseSpec = value;
  } else if (dataIndex === 'powerNetName') {
    signOffTemplate[index].powerNetName = value;
    if (signOffTemplate[index].compType !== PMIC) {
      signOffTemplate[index].groupKey = value + signOffTemplate[index].groupKey
      if (signOffTemplate.length > index + 1 && signOffTemplate[index + 1].compType === PMIC) {
        signOffTemplate[index + 1].groupKey = signOffTemplate[index].groupKey
      }
    } else if (index > 0) {
      signOffTemplate[index].groupKey = signOffTemplate[index - 1].groupKey
    }
    if (signOffTemplate[index].toNormalRow) {
      signOffTemplate[index].toNormalRow[1] = value
    }
    _data.signOffTemplate = signOffTemplate
    yield put(saveTemplateLogs([], true));
    let prevComps = JSON.parse(JSON.stringify(_data.components || []));
    const saveLogs = (logs) => {
      store.dispatch({ type: UPDATE_SIGN_OFF_TEMPLATE_LOGS, logs })
    }
    const { errors, signOffTemplateInfo, powerErrorMap } = yield call(checkAndUpdateSignOffTemplateContent, {
      signOffTemplateInfo: _data,
      saveLogs
    });
    signOffTemplateInfo.components = yield call(updateComponentDecapModel, {
      designId: _data.designId,
      components: signOffTemplateInfo.components,
      prevComps
    })
    _data = signOffTemplateInfo;
    yield put(updateTemplateErrors({ errors, powerErrorMap, id: verificationId }));
  } else {
    const [type, unit] = strDelimited(dataIndex, "_");
    if (!signOffTemplate[index][type]) {
      signOffTemplate[index][type] = { unit: dataIndex === "peakCurrent" ? "mA" : unit, value: "" };
    }

    if (!signOffTemplate[index][type].unit) {
      signOffTemplate[index][type].unit = dataIndex === "peakCurrent" ? "mA" : "mΩ";
    }
    if (dataIndex !== "peakCurrent") {
      signOffTemplate[index][type].unit = ohmUnitConversion(signOffTemplate[index][type].unit);
    }
    signOffTemplate[index][type].value = value;
  }
  if (dataIndex === 'powerNetName') {
    yield put(updateTemplateContent({ ..._data }));
  } else {
    yield put(updateTemplateContent({ signOffTemplate }));
  }
  /*  yield put(updateTemplateSetup(true)); */
  yield put(updateTemplateSetupValue(true));
  yield put(saveTemplateContent());
  yield put(updateTemplateContentLoading(false));
}

function* _createTemplatePower(action) {
  const { powerDomainInfo } = action;
  yield put(updateTemplateContentLoading(true))
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  let _data = { ...data };
  let signOffTemplate = data.signOffTemplate || [];
  const index = getDefaultIndex(signOffTemplate.length, signOffTemplate.map(item => item.index));
  let template = null, pmicGroupKey = null;
  if (powerDomainInfo.isAddPMIC) {
    const groupKeys = [...new Set(signOffTemplate.filter(item => item.powerNetName === powerDomainInfo.pmicPowerNet).map(item => item.groupKey))];
    //pmic, pmicPowerNet, isAddPMIC
    if (groupKeys.length) {
      pmicGroupKey = groupKeys[0];
    }
    template = {
      index,
      powerNetName: powerDomainInfo.pmicOutNet || powerDomainInfo.pmicPowerNet,
      gndNetName: powerDomainInfo.gnd || "",
      refdes: powerDomainInfo.pmic || "",
      compType: "PMIC",
      resultExists: false,
      impedance: [],
      powerPins: [],
      groundPins: [],
      effectivePowerPins: [],
      effectiveGroundPins: [],
      loopDcrSpec: {},
      groupKey: pmicGroupKey || `${powerDomainInfo.pmicPowerNet}::${powerDomainInfo.soc || data.soc}::${powerDomainInfo.gnd || data.gnd}`
    }
  } else {
    //loopDcrSpec
    const findLoopDcrPmic = signOffTemplate.find(item => item.loopDcrSpec && item.loopDcrSpec.pmicUnit);
    const findLoopDcrSense = signOffTemplate.find(item => item.loopDcrSpec && item.loopDcrSpec.senseUnit);
    //impedance
    const findImpedances = signOffTemplate.find(item => item.impedance && item.impedance.length);
    let impedances = findImpedances ? JSON.parse(JSON.stringify(findImpedances.impedance)) : []
    impedances = impedances.map(item => { delete item.actualValue; item.value = ""; return item });
    //couplingImpedance
    const findCouplingImpedance = signOffTemplate.find(item => item.couplingImpedance && item.couplingImpedance.length);
    let couplingImpedances = findCouplingImpedance ? JSON.parse(JSON.stringify(findCouplingImpedance.couplingImpedance)) : []
    couplingImpedances = couplingImpedances.map(item => { delete item.couplingResult; item.value = ""; return item });
    //shortSocCoupling
    const findShortSocCoupling = signOffTemplate.find(item => item.shortSocCoupling && item.shortSocCoupling.length);
    let shortSocCoupling = findShortSocCoupling ? JSON.parse(JSON.stringify(findShortSocCoupling.shortSocCoupling)) : []
    shortSocCoupling = shortSocCoupling.map(item => { delete item.couplingResult; item.value = ""; return item });
    //inductanceSpec
    const findInductanceSpec = signOffTemplate.find(item => item.inductanceSpec && item.inductanceSpec.length);
    let inductances = findInductanceSpec ? JSON.parse(JSON.stringify(findInductanceSpec.inductanceSpec)) : []
    inductances = inductances.map(item => { delete item.actualValue; item.value = ""; return item });

    template = {
      index,
      powerNetName: powerDomainInfo.powerNet,
      gndNetName: powerDomainInfo.gnd || "",
      refdes: powerDomainInfo.soc || "",
      compType: "SOC",
      resultExists: false,
      impedance: impedances,
      couplingImpedance: couplingImpedances,
      shortSocCoupling: shortSocCoupling,
      inductanceSpec: inductances,
      powerPins: [],
      groundPins: [],
      effectivePowerPins: [],
      effectiveGroundPins: [],
      loopDcrSpec: {
        pmicSpec: "",
        pmicUnit: findLoopDcrPmic ? findLoopDcrPmic.loopDcrSpec.pmicUnit : "mΩ",
        senseSpec: "",
        senseUnit: findLoopDcrSense ? findLoopDcrSense.loopDcrSpec.senseUnit : "mΩ",
        sensePort: false
      },
      groupKey: `${powerDomainInfo.powerNet}::${powerDomainInfo.soc || data.soc}::${powerDomainInfo.gnd || data.gnd}`
    }
  }

  signOffTemplate.push({ ...template });
  yield put(updateTemplateContent({ signOffTemplate }));
  yield put(updateTemplateSetup(true));
  yield put(saveTemplateContent());
  yield put(updateTemplateContentLoading(false));

  _data.signOffTemplate = signOffTemplate;
  const soc = powerDomainInfo.soc || data.soc,
    gnd = powerDomainInfo.gnd || data.gnd;

  const addPMIC = powerDomainInfo.isAddPMIC && powerDomainInfo.pmicPowerNet && powerDomainInfo.pmic;
  if ((powerDomainInfo.powerNet && soc && gnd) || addPMIC) {
    const info = yield call(updateOnePowerCapComponents, {
      signOffTemplateInfo: _data,
      templates: addPMIC ? signOffTemplate.filter(item => item.groupKey === pmicGroupKey) : [template]
    })
    _data = info.signOffTemplateInfo;
    const newNoModelCompNames = info.newNoModelCompNames;
    _data.components = yield call(updateComponentDecapModel, {
      designId: data.designId,
      components: _data.components,
      newNoModelCompNames,
      prevComps: []
    })
    yield put(updateTemplateContent({ components: _data.components, signOffTemplate: _data.signOffTemplate }));
    yield put(updateTemplateSetup(true));
    yield put(saveTemplateContent());
  }

}

function* _delPwrDomain(action) {
  const { record } = action;
  yield put(updateTemplateContentLoading(true))
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  let _data = { ...data };
  let signOffTemplate = data.signOffTemplate || [], components = data.components || [];
  const delTemplate = JSON.parse(JSON.stringify(signOffTemplate.find(item => item.index === record.index) || {}));
  signOffTemplate = signOffTemplate.filter(item => item.index !== record.index);

  const groupTemplates = signOffTemplate.filter(item => item.groupKey === record.groupKey);
  let findTemplate = null;
  let delSoc = delTemplate.refdes,
    delGnd = delTemplate.gndNetName || data.gnd;
  if (record.compType !== "PMIC") {
    findTemplate = groupTemplates.find(item => item.compType !== "PMIC");
    delSoc = delTemplate.refdes || data.soc;
  }

  if (findTemplate && delTemplate.components && delTemplate.components.length) {
    const index = signOffTemplate.findIndex(item => item.groupKey === record.groupKey);
    signOffTemplate[index].components = JSON.parse(JSON.stringify(delTemplate.components));
  }

  if (!signOffTemplate.length) {
    components = [];
  }

  yield put(updateTemplateContent({ signOffTemplate, components }));
  yield put(updateTemplateSetup(true));
  yield put(saveTemplateContent());
  yield put(updateTemplateContentLoading(false));

  if (signOffTemplate.length && record.compType === "PMIC") {
    yield put(updateTemplateContentLoading(true));
    _data.signOffTemplate = signOffTemplate;
    yield put(updateTemplate({ data: _data }));
    return;
  }

  if (signOffTemplate.length && delTemplate && delTemplate.powerNetName && delSoc && delGnd && !findTemplate) {
    components = deleteComponents(delTemplate.components, signOffTemplate, components);
    yield put(updateTemplateContent({ components }));
    yield put(updateTemplateSetup(true));
    yield put(saveTemplateContent());
  }
}

function* _saveImpedanceFreq(action) {
  let { list, RLValueChecked, impType } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  let signOffTemplate = data.signOffTemplate || [];

  list = list.filter(item => !numberCheck(item));

  for (let temp of signOffTemplate) {

    if (!temp[impType]) {
      temp[impType] = [];
    } else if (temp[impType].find(it => !!it.res && RLValueChecked)) {
      return;
    }

    if (RLValueChecked) {
      temp[impType] = [{
        res: { unit: "mΩ", value: "" },
        ind: { unit: "pH", value: "" }
      }]
    } else {
      const newList = JSON.parse(JSON.stringify(list));
      temp[impType] = temp[impType].filter(it => !it.res);
      temp[impType].push(...newList.map(item => {
        const findItem = temp[impType].find(it => it.frequency === `${item}MHz`);
        if (findItem) {
          return null;
        }
        return {
          frequency: `${item}MHz`,
          unit: impType === "inductanceSpec" ? "pH" : "mΩ",
          value: "",
        }
      }).filter(it => !!it));
    }
  }

  yield put(updateTemplateContent({ signOffTemplate }));
  yield put(updateTemplateSetup(true));
  yield put(saveTemplateContent(true));
}

function* _delImpFreq(action) {
  let { dataIndex } = action;
  const { CascadeReducer: { SignOffTemplate: { data = {} } } } = yield select();
  let signOffTemplate = data.signOffTemplate || [];

  //dataIndex `couplingImpedance_${couplingImpedanceUnit}_${item}`,
  //dataIndex `couplingImpedance_rl_value`
  //dataIndex `impedance_rl_value`
  const [impType, unit, freq] = strDelimited(dataIndex, "_");
  const actualFreq = frequencyStrFormat(freq, true);
  signOffTemplate.forEach(temp => {
    if (!temp[impType]) {
      temp[impType] = [];
    }
    if (["impedance_rl_value", "couplingImpedance_rl_value", "shortSocCoupling_rl_value"].includes(dataIndex)) {
      temp[impType] = temp[impType].filter(item => !item.res);
    } else {
      temp[impType] = temp[impType].filter(item => {
        if (!(ohmUnitConversion(item.unit) === ohmUnitConversion(unit) && item.frequency === actualFreq)) {
          return true
        }
        return false;
      });
    }
  })

  yield put(updateTemplateContent({ signOffTemplate }));
  yield put(updateTemplateSetup(true));
  yield put(saveTemplateContent());
}

export function* updateErrorToTemplate({ openProjectId, id, _signOffId, error, type }) {
  //if impedance/path_r created by sign-off template, update error message to sign-off template
  const impedanceName = CascadeChannels.getVerificationName(type, openProjectId, id);
  const typeTitle = type === IMPEDANCE ? IMPEDANCE_TITLE : (type === DCR ? DCR_TITLE : "")
  let { CascadeReducer: { SignOffTemplate: { templateError } } } = yield select();
  const errorItem = {
    title: `[${typeTitle}] - ${impedanceName}`,
    error: ` ${error}!`
  }
  if (templateError && templateError.id === _signOffId) {
    templateError.errors = templateError.errors ? templateError.errors : []
    templateError.errors.push(errorItem);
  } else {
    templateError = { id: _signOffId, errors: [errorItem], powerErrorMap: null }
  }
}

let saveAdditionWork
function* saveAdditionalNets(action) {
  const { nets } = action;
  yield put(updateTemplateContent({ additionalNets: nets }));
  if (saveAdditionWork) {
    yield cancel(saveAdditionWork);
  }
  saveAdditionWork = yield fork(debounce, 2000, saveTemplateContent, 'put');

}

function* debounce(time = 0, callback, yieldType) {
  yield delay(time);
  if (yieldType === 'put') {
    yield put(callback())
  } else {
    yield call(callback);
  }
}

function* updateTemplateCompTableDisplay(action) {
  const { display, clearModel } = action
  const { CascadeReducer: { SignOffTemplate: { data } } } = yield select();
  const prevDisplay = data.componentsTableDisplay
  if (!prevDisplay || prevDisplay !== display) {
    yield put(updateTemplateContentLoading(true))
    try {
      yield put(updateTemplateContent({ componentsTableDisplay: display }));
      if (prevDisplay === COMPONENTBASED && display === PARTBASED && clearModel) {
        let components = data.components || []
        components.forEach(item => {
          delete item.models
        })
        yield put(updateTemplateContent({ components }));
      }
      yield delay(100);
      yield put(updateTemplateSetup(true));
      yield put(saveTemplateContent());
      yield put(updateTemplateContentLoading(false))
    } catch (error) {
      yield put(updateTemplateContentLoading(false))
      console.error(error)
    }
  }
}

function* updateDecapModel(action) {
  const { file, display } = action;
  const { CascadeReducer: { project: { openProjectId, applyModelList }, library: { DecapList }, SignOffTemplate: { data, designId, verificationId } } } = yield select();
  let components = data.components || []
  yield put(updateTemplateContentLoading(true));
  const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, openProjectId);
  const currentItem = list.find(i => i.id === verificationId);
  yield put(openTabFooter());
  yield put(changeTabMenu({
    tabSelectKeys: ["detail"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : SIGN_OFF_TEMPLATE,
    menuType: "simulation"
  }));
  try {
    if (file) {
      const res = yield call(uploadDecapModelExcel, { file, type: display });
      if (!res) {
        yield put(saveTemplateLogs([{ type: 'error', text: `Parse file failed!` }]))
        return
      }
      const subcktsMap = new Map();// key - file, value :[ { name, ports }... ]
      const capModelMap = new Map();// key - component name, value :{ name, subcktName, id, type, libraryType }
      const partModelMap = new Map();// key - component part, value :{ name, subcktName, id, type, libraryType }
      for (let item of res) {
        const { componentName, fileName: parseFileName, subckt, part } = item;
        const matchParseName = parseFileName.replace(/\.s(\d*p|np)$/, '')
        let fileInfo
        for (let decap of DecapList) {
          if (decap.format === 'Folder') {
            if (decap.children && decap.children.length) {
              fileInfo = decap.children.find(d => {
                const matchDecapName = d.name.replace(/\.s(\d*p|np)$/, '')
                const isFileNameMatch = d.name === parseFileName || matchDecapName === parseFileName || matchDecapName.includes(matchParseName)
                return d.type === (subckt ? DECAP_SPICE : DECAP_TOUCHSTONE) && isFileNameMatch
              })
              if (fileInfo) {
                break
              }
            }
          } else {
            const matchDecapName = decap.name.replace(/\.s(\d*p|np)$/, '')
            const isFileNameMatch = decap.name === parseFileName || matchDecapName === parseFileName || matchDecapName.includes(matchParseName)
            if (decap.type === (subckt ? DECAP_SPICE : DECAP_TOUCHSTONE) && isFileNameMatch) {
              fileInfo = decap
              break
            }
          }
        }
        if (fileInfo) {
          let model;
          const fileName = fileInfo.name
          if (fileInfo.dataType === DECAP_SPICE) {
            if (!subcktsMap.has(fileName)) {
              const res = yield call(getLibraryFileInfo, fileInfo.id);
              const { models: subckts } = parseSPModelSelector(res);
              subcktsMap.set(fileName, subckts)
            }
            const subkctInfo = subcktsMap.get(fileName).find(_subckt => _subckt.name === subckt)
            if (subkctInfo) {
              model = {
                name: fileName,
                subcktName: subckt,
                id: fileInfo.id,
                type: 'Decap',
                libraryType: DECAP_SPICE
              }
            }
          } else {
            model = {
              name: fileName,
              subcktName: '',
              id: fileInfo.id,
              type: fileInfo.dataType,
              libraryType: fileInfo.dataType
            }
          }
          if (model) {
            if (display === COMPONENTBASED) {
              capModelMap.set(componentName, { ...model })
            } else if (display === PARTBASED) {
              partModelMap.set(part, { ...model })
            }
          }
        } else {
          yield put(saveTemplateLogs([{ type: 'normal', text: `${parseFileName}${subckt ? ` - ${subckt}` : ''} does not exist in decap library.` }]))
        }
      }

      let _applyModelList = [...applyModelList]

      if (display === COMPONENTBASED) {
        components.forEach(item => {
          if (capModelMap.has(item.name)) {
            item.models = [{ ...capModelMap.get(item.name), apply: false }]
          }
        })
      } else if (display === PARTBASED) {
        components.forEach(item => {
          const { part } = item
          const partNumber = auroraDBJson.getPartNumberByPartName(designId, part);
          const _part = partModelMap.has(partNumber) ? partNumber : (partModelMap.has(part) ? part : null);
          if (_part) {
            const model = partModelMap.get(_part)
            const findIndex = applyModelList.findIndex(m => m.partName === _part);
            let applyModel = { model, partName: _part };
            if (findIndex > -1) {
              _applyModelList[findIndex] = { ...applyModel }
            } else {
              _applyModelList = [..._applyModelList, { ...applyModel }]
            }
            item.models = [{ ...model, apply: true }];
          } else {
            delete item.models
          }
        })
      }
      yield call(updateLibSetting, { librarySettings: _applyModelList, projectId: openProjectId })
      yield put(updateModelSetting(_applyModelList))
      yield put(updateTemplateContent({ components }));
      yield put(updateTemplateSetup(true));
      yield put(saveTemplateContent());
      yield put(updateTemplateContentLoading(false));
    }
  } catch (e) {
    yield put(updateTemplateContentLoading(false));
    yield put(saveTemplateLogs([{ type: 'error', text: `Upload file failed!` }]))
    message.error('Upload file failed!');
    console.error(e)
  }
}

function* createSignOffTaskByPDF(action) {
  const { res } = action;
  const { verificationId, taskId } = res;
  if (verificationId && taskId) {
    yield put(updateTemplatePDFPanel({ open: true, loading: true, loadingTip: "Prepare to parse PDF...", verificationInfo: { ...action } }, true))
    while (true) {
      try {
        yield delay(3000);
        const promise = yield call(checkVerificationStatus, verificationId);
        const verifyStatus = promise ? promise.status : null;
        //if never run simulation, return
        if (verifyStatus === VERIFY_SUCCESS) {
          yield put(updateTemplatePDFPanel({ loadingTip: "Parsing PDF successfully..." }))
          yield delay(500);
          break;
        } else if (verifyStatus === VERIFY_FAILED) {
          yield put(updateTemplatePDFPanel({ loadingTip: "Failed to parse PDF...", failed: true }))
          yield delay(500);
          break;
        } else if (verifyStatus === WAITING) {
          yield put(updateTemplatePDFPanel({ loadingTip: "Waiting to parse PDF..." }))
        } else {
          yield put(updateTemplatePDFPanel({ loadingTip: "Parsing PDF..." }))
        }
      } catch (e) {
        console.error(e)
      }
    }
    try {
      yield put(updateTemplatePDFPanel({ loading: false }))
      const data = yield call(chooseTemplateTable, verificationId);
      yield put(updateTemplatePDFPanel({ tables: data }))
    } catch (e) {
      console.error(e)
      yield put(updateTemplatePDFPanel({ tables: [], failed: true, error: e }))
    }
  }
}

function* choosePDFTable(action) {
  const { value } = action;
  const { CascadeReducer: { SignOffTemplate: { templatePDFPanel: { verificationInfo } } } } = yield select();
  const { res, name, projectId } = verificationInfo;
  const { verificationId } = res;
  if (!verificationId) {
    return;
  }
  try {
    yield put(updateTemplatePDFPanel({ loadingTip: "Saving..." }))
    const res = yield call(selectTemplateTable, { verificationId, sheetNumber: value });
    yield put(updateTemplatePDFPanel({}, true))
    yield call(updateTreeAfterChangeVerification, { ...verificationInfo, res });
    const logContent = yield call(getSignOffTempalteUploadLog, { projectId, verificationId: res.verificationId });
    yield put(updateTemplateLog([logContent]));
    yield put(changeTabMenu({
      tabSelectKeys: ['monitor'],
      currentVerificationId: res.verificationId,
      verificationName: name ? name : SIGN_OFF_TEMPLATE,
      menuType: 'simulation'
    }))
    yield put(openTabFooter())
  } catch (error) {
    console.error(error);
    yield put(updateTemplatePDFPanel({ loadingTip: "Failed to parse PDF...", failed: true, error: error }))
    message.error("Set sign-off template failed!" + error);
  }
}

function* SignOffTemplateSaga() {
  yield takeEvery(PARSE_FILE_AND_CREATE_SIGN_OFF_TASK, _createSignOffTask);
  yield takeEvery(GET_SIGN_OFF_CONTENT, _getContent);
  yield takeEvery(CHANGE_SIGN_OFF_PCB, _updatePCB);
  yield takeEvery(CREATE_AND_RUN_IMPEDANCE_TASK, _createRunImpedanceTask);
  yield takeLatest(UPDATE_SIGN_OFF_TEMPLATE_LIST_STATUS, updateRunningList);
  yield takeEvery(UPDATE_TEMPLATE_PORTS, _updatePorts);
  yield takeEvery(SAVE_TEMPLATE_CAP_MODEL, _saveCapModel);
  yield takeEvery(UPDATE_TEMPLATE_SOC, _updateSoc);
  yield takeEvery(UPDATE_TEMPLATE_COMP_SETTING, updateTemplateByCompSettings);
  yield takeEvery(UPDATE_TEMPLATE_GND, _updateGnd);
  yield takeEvery(SAVE_SIGN_OFF_CONTENT, saveSignOffContent);
  yield takeEvery(UPDATE_SIGN_EXTRACTION, updateSignExtraction)
  yield takeEvery(UPDATE_TEMPLATE, updateTemplateInfo);
  yield takeEvery(SAVE_TEMPLATE_CONFIG, _saveConfig);
  yield takeEvery(RE_ASSIGN_TEMPLATE_DECAP_MODEL, _reAssignModel);
  yield takeEvery(DOWNLOAD_TEMPLATE_S1P_FILE, _downloadS1PFile);
  yield takeEvery(SAVE_IMPEDANCE_VALUE, _saveImpedanceSpecValue);
  yield takeEvery(SAVE_TEMPLATE_VALUE, _saveTemplateValue);
  yield takeEvery(CREATE_NEW_POWER, _createTemplatePower);
  yield takeEvery(DELETE_TEMPLATE_PWR_DOMAIN, _delPwrDomain);
  yield takeEvery(SAVE_IMP_FREQ_LIST, _saveImpedanceFreq);
  yield takeEvery(DELETE_IMPEDANCE_FREQ, _delImpFreq);
  yield takeEvery(UPDATE_DCR_EXTRACTION, saveDCRExtraction);
  yield takeEvery(SAVE_ADDITIONAL_NETS, saveAdditionalNets)
  yield takeEvery(UPDATE_TEMPLATE_COMP_TABLE_DISPLAY, updateTemplateCompTableDisplay)
  yield takeEvery(UPDATE_TEMPLATE_DECAP_MODEL, updateDecapModel)
  yield takeEvery(CHOOSE_PDF_TABLE, choosePDFTable)
}

export default SignOffTemplateSaga;