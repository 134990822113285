import React, { Component, Fragment } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip, Input } from 'antd';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import debounce from '@/services/helper/debounceFn';
import '../../publicCss/style.css';
import "../../publicCss/aurora.css";
import "../../publicCss/tree.css";

class CreateItem extends Component {
  constructor(props) {
    super(props);
    const { itemData } = props;
    this.state = {
      name: itemData.name,
      errorMsg: null
    }
  }

  nameChange = (e) => {
    this.setState({
      errorMsg: null,
      name: e.target.value
    })
  }

  createConfirm = (e, itemData) => {
    e.stopPropagation();
    debounce(() => {
      this.createItemSave(itemData);
    }, 1000, true, 'createTimer')();
  }

  createItemSave = (itemData) => {
    let name = this.state.name;
    //check name format
    let errorMsg = checkNameFormat(name);
    if (errorMsg) {
      this.setState({
        errorMsg: errorMsg
      });
      return;
    }

    const { projectList } = this.props;
    let _filter = projectList.filter(i => i.id !== itemData.id);
    let names = _filter.map(item => item.name);
    //check name repeated
    if (names.includes(name)) {
      this.setState({
        errorMsg: `${name} already exists!`
      });
      return;
    }

    //create project
    this.props.createConfirm({ ...itemData, name });
    this.setState({
      errorMsg: null,
      autoFocus: false,
      name
    });
  }

  cancelCreate = (e) => {
    e.stopPropagation();
    this.setState({
      errorMsg: null,
      name: null
    });
    this.props.cancelCreate();
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { name, errorMsg } = this.state;
    const { createNodeId, cancelNodeId, itemData, getPopupContainer } = this.props;
    return (
      <span className='aurora-project-create-name' key={itemData.key} onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={errorMsg ? errorMsg : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={errorMsg ? true : false}
          getPopupContainer={getPopupContainer ? getPopupContainer : () => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            value={name}
            className='aurora-project-name-input'
            onChange={(e) => this.nameChange(e)}
            ref={(input) => { this.inputRef = input; }}
            onPressEnter={(e) => { e.stopPropagation(); this.createConfirm(e, itemData) }}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                id={createNodeId}
                onClick={(e) => { e.stopPropagation(); this.cancelCreate(e, itemData) }} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                id={cancelNodeId}
                onClick={(e) => { e.stopPropagation(); this.createConfirm(e, itemData) }} />
            </Fragment>}
          />
        </Tooltip>
      </span>
    );
  }
}

export default CreateItem;