import StringList from '../utility/StringList';
import CsUnits from '../utility/CsUnits';
import { FASTPI } from '../auroraVersion';

//  class CeElectricLayer{
//  private:
//      string mLayerName;                  //Metal layer name must be consistent with the layout
//      string mMaterialName;               //Material name such FR4
//      string mMaterialVendor;             //Material vendor
//      string mLayerType;                  //"Conductor", "Dielectric"
//      CeLayerMetal *mLayoutLayer;         //Pointer to the layout layer for the metal layer
//      double mThickness;                  //in mils
//      double mConductivity;               //Conductivity for the metal layer in S/m
//      double mPermittivity;               //Dielectric constatn for the dielectric layer
//      double mLossTangent;                 //Loss tangent for the diectric layer
//  ...
//  CeElectricLayer::CeElectricLayer
//  CeElectricLayer::CeElectricLayer(string name)
var CeElectricLayer = function (strName) {

  this.mLayerName = strName != undefined ? strName : "";
  this.mMaterialName = "Unknown";
  this.mMaterialVendor = "Generic";
  this.mLayerType = "Dielectric";
  this.mLayoutLayer = null;
  this.mThickness = 0;
  this.mConductivity = 0;
  this.mPermittivity = 1;
  this.mLossTangent = 0.02;

};

// void CeElectricLayer::SetLayerType(string type)
CeElectricLayer.prototype.setLayerType = function (strType) {
  this.mLayerType = strType;
};

// void CeElectricLayer::setThickness(double t)
CeElectricLayer.prototype.setThickness = function (dThickness) {
  this.mThickness = dThickness;
};

// void CeElectricLayer::SetPermittivity(double er)
CeElectricLayer.prototype.setPermittivity = function (dEr) {
  this.mPermittivity = dEr;
};

// Note that the definition of the loss tangent is different from that in the C++ code
CeElectricLayer.prototype.setLossTangent = function (dDelta) {
  this.mLossTangent = dDelta;
};

// void CeElectricLayer::SetConductivity(double c)
CeElectricLayer.prototype.setConductivity = function (dConductivity) {
  this.mConductivity = dConductivity;
};

// void CeElectricLayer::SetLayoutLayer(CeLayerMetal *layoutLayer)
CeElectricLayer.prototype.setLayoutLayer = function (ceLayerMetal) {
  this.mLayoutLayer = ceLayerMetal;
};

// string CeElectricLayer::GetLayerName()
CeElectricLayer.prototype.getLayerName = function () {
  return this.mLayerName;
};

// string CeElectricLayer::GetLayerType()
CeElectricLayer.prototype.getLayerType = function () {
  return this.mLayerType;
};

// double CeElectricLayer::GetLayerThickness()
CeElectricLayer.prototype.getLayerThickness = function () {
  return this.mThickness;
};

CeElectricLayer.prototype.getPermittivity = function () {
  return this.mPermittivity;
};
////////////////////////////////////////////////////////////////////////////
//
// class CeLayerStack{
// private:
//     int mBoardThickness;                //62mils(1.6mm) by default
//     vector<CeElectricLayer*> mLayers;   //mLayer[0] is the top layer (usually is the top metal)
//     CeLayerManager *mLayoutLayerMgr;
// ...    
// CeLayerStack::CeLayerStack()
// CeLayerStack::CeLayerStack(CeLayerManager *layoutLayers)
var CeLayerStack = function (ceLayerManager) {
  this.mUnit = "Unknown";
  this.mBoardThickness = 0;
  this.mLayers = [];
  this.mLayoutLayerMgr = ceLayerManager || null;
};

CeLayerStack.prototype.getUnit = function () {
  return this.mUnit;
};

CeLayerStack.prototype.setUnit = function (csUnit) {
  return this.mUnit = csUnit;
};

CeLayerStack.prototype.getUnitString = function () {
  return CsUnits.UnitsToString(this.mUnit);
};

CeLayerStack.prototype.setUnitFromString = function (strUnitName) {
  this.mUnit = CsUnits.StringToUnits(strUnitName);
};

// vector<CeElectricLayer*> CeLayerStack::GetLayers()
CeLayerStack.prototype.getLayers = function () {
  return this.mLayers;
};

// int CeLayerStack::GetLayerIndexByName(string name)
CeLayerStack.prototype.getLayerIndexByName = function (strName) {
  var layerName = strName.toUpperCase();
  for (var iLayer in this.mLayers) {
    if (this.mLayers[iLayer].name.toUpperCase() == layerName)
      return iLayer;
  }
  return -1;
}; // CeLayerStack.prototype.GetLayerIndexByName

// double CeLayerStack::GetLayerThicknessByName(string lyrName)
CeLayerStack.prototype.getLayerThicknessByName = function (strLyrName, csUnit) {
  var layerName = strLyrName.toUpperCase();
  for (var iLayer in this.mLayers) {
    var layer = this.mLayers[iLayer];
    if (layer.toUpperCase() == layerName) {
      var t = layer.getLayerThickness();
      if (csUnit)
        t = CsUnits.Convert(t, csUnit, "Length_m");
      return t;
    }
  }
  console.log('Cannot find the layer ' + strLyrName + 'in Layer Stackup file!');
  return 0;

}; // CeLayerStack.prototype.getLayerThicknessByName

// double CeLayerStack::GetDielectricConstant()
CeLayerStack.prototype.getDielectricConstant = function () {
  for (var iLayer in this.mLayers) {
    if (this.mLayers.GetLayerType() == "Dielectric")
      return this.mLayers.getPermittivity(); // assume all the layers have the same permittivity
  }
}; // CeLayerStack.prototype.GetDielectricConstant

// double CeLayerStack::GetLayerDistance(CeLayerMetal *metal1, CeLayerMetal *metal2, CsUnitList unit){
CeLayerStack.prototype.getLayerDistance = function (ceLayerMetal1, ceLayerMetal2, csUnit) {
  var dist = 0;
  var layerIndex1 = this.getLayerIndexByName(ceLayerMetal1.GetName());
  var layerIndex2 = this.getLayerIndexByName(ceLayerMetal2.GetName());

  if (layerIndex1 > layerIndex2) {
    var tmp = layerIndex1;
    layerIndex1 = layerIndex2;
    layerIndex2 = tmp;
  }

  for (var i = layerIndex1 + 1; i < layerIndex2; i++) {
    dist += this.mLayers[i].GetLayerThickness();
  }

  if (csUnit && csUnit != "Length_m") {
    dist = CsUnits.Convert(dist, csUnit, "Length_m");
  }

  return dist;
}; // CeLayerStack.prototype.getLayerDistance

// bool CeLayerStack::ReadLayerStackFromFile(string filePath)
CeLayerStack.prototype.ReadFromIODataNode = function (stackupBlock, csUnit) {

  var layerItemList = stackupBlock.GetAllBlockItems();
  if (!layerItemList)
    return false;

  for (var iLayer in layerItemList) {

    var itemName = layerItemList[iLayer].GetName().toUpperCase();

    if (itemName == "UNIT") {
      this.mUnit = CsUnits.StringToUnits(layerItemList[iLayer].GetItemValue().get(0));
    } else if (itemName == "DIELECTRIC") {

      // dielectric <name> <thickness in mil> <eps_r>
      var layerParams = layerItemList[iLayer].GetItemValue();
      var layer = new CeElectricLayer(layerParams.get(0));
      layer.setLayerType("Dielectric");
      layer.mThickness = layerParams.size() > 1 ? Number(layerParams.get(1)) : 37;
      layer.mPermittivity = layerParams.size() > 2 ? layerParams.get(2) : 4.2;
      if (layerParams.size() > 3) {
        var param3 = layerParams.get(3);
        var param3Num = Number(param3);
        if (isNaN(param3Num)) {
          // backward compatible with old format where the loss tangent is not assigned
          layer.mLossTangent = 0.02;
          layer.mMaterialName = param3;
          layer.mMaterialVendor = layerParams.size() > 4 ? layerParams.get(4) : "Generic";
        } else {
          layer.mLossTangent = param3Num;
          layer.mMaterialName = layerParams.size() > 4 ? layerParams.get(4) : "FR-4";
          layer.mMaterialVendor = layerParams.size() > 5 ? layerParams.get(5) : "Generic";
        }
      }

      this.mLayers.push(layer);
    } else if (itemName == "METAL") {

      // metal <name> <thickness in mil> 
      var layerParams = layerItemList[iLayer].GetItemValue();
      var layer = new CeElectricLayer(layerParams.get(0));
      layer.setLayerType("Metal");
      layer.mThickness = layerParams.size() > 1 ? Number(layerParams.get(1)) : 1.4;
      layer.mMaterialName = "Copper";
      layer.mMaterialVendor = "Generic";
      layer.mConductivity = layerParams.size() > 2 ? Number(layerParams.get(2)) : 5.8e7;
      var layoutLayer = this.mLayoutLayerMgr.GetMetalLayer(layer.getLayerName());
      if (layoutLayer)
        layer.setLayoutLayer(layoutLayer);
      else
        return false;

      this.mLayers.push(layer);
    }

  } // for (var iLayer in layerItemList)

  if (csUnit && csUnit != this.mUnit && this.mUnit != "Unknown") {
    for (var iLayer in this.mLayers) {
      var t = this.mLayers[iLayer].getLayerThickness();
      t = Math.round(CsUnits.Convert(t, this.mUnit, csUnit) * 100) / 100;
      this.mLayers[iLayer].setThickness(t);
    }
    this.mUnit = csUnit;
  }

  return true;

}; // CeLayerStack.prototype.ReadFromIODataNode

CeLayerStack.prototype.WriteIntoIODataNode = function (stackupBlock) {

  if (this.mUnit != "Unknown") {
    stackupBlock.AddDataItem("Unit", CsUnits.UnitsToString(this.mUnit));
  }

  for (var iLayer in this.mLayers) {

    var layerLine = new StringList();
    layerLine.add(this.mLayers[iLayer].getLayerName());
    layerLine.add(this.mLayers[iLayer].getLayerThickness());
    var layerType = this.mLayers[iLayer].getLayerType();
    if (layerType == "Metal") {
      layerLine.add(this.mLayers[iLayer].mConductivity);
      stackupBlock.AddDataItem("Metal", layerLine);
    } else if (layerType == "Dielectric") {
      layerLine.add(this.mLayers[iLayer].mPermittivity);
      layerLine.add(this.mLayers[iLayer].mLossTangent);
      layerLine.add(this.mLayers[iLayer].mMaterialName);
      layerLine.add(this.mLayers[iLayer].mMaterialVendor);
      stackupBlock.AddDataItem("Dielectric", layerLine);
    }
  }

  return true;

}; // CeLayerStack.prototype.WriteIntoIODataNode

// bool CeLayerStack::CreateLayerStackFromLayout(int boardThickness)
/** Create the default stackup based on the total board thickness 
 *  @param boardThickness  Total thickness of the board in mil
 *  @param strUnitName     The thickness unit in the stackup file. If not set, will use by default.
 *
 *  TODO - right now the unit is passed as a string instead of a CsUnits.CsUnitList constant. This
 *         is because the CeLayoutDB unit is saved as a string. It needs to be changed into CsUnits
 *         definitions later.
 */
CeLayerStack.prototype.createLayerStackFromLayout = function (boardThickness, strUnitName, version) {

  this.mBoardThickness = boardThickness;
  this.mLayers = [];

  // create the layers
  var metalLayerNames = this.mLayoutLayerMgr.GetAllMetalLayers();
  for (var iLayer = 0, numLayer = metalLayerNames.size(); iLayer < numLayer; iLayer++) {

    // add the metal layer
    var layerName = metalLayerNames.get(iLayer);
    var layer = new CeElectricLayer(layerName);
    layer.setLayerType("Metal");
    layer.setThickness(1.4); // 1oz copper
    //version === FASTPI => 4.6e7
    const conductivityValue = version === FASTPI ? 4.6e7 : 5.8e7;
    layer.setConductivity(conductivityValue);
    layer.mMaterialName = "Copper";
    var layoutLayer = this.mLayoutLayerMgr.GetMetalLayer(layerName);
    layer.setLayoutLayer(layoutLayer);

    this.mLayers.push(layer);

    // create a dielectric layer except for the last metal layer
    if (iLayer < numLayer - 1) {
      var layer = new CeElectricLayer("LD_" + layerName);
      layer.setLayerType("Dielectric");
      layer.setThickness(6.0); // set a default thickness in case the table lookup fails
      layer.setPermittivity(4.2);
      layer.setLossTangent(0.02);
      layer.mMaterialName = "FR-4";
      this.mLayers.push(layer);
    }
  } // for (var iLayer = 0, numLayer = metalLayerNames.size(); iLayer < numLayer; iLayer++) {

  //Now we need to set the thickness of the inserted dielectric layers
  //4-layer: 0.020", 0.031", 0.040", 0.047", 0.062", 0.093" and 0.125"
  //6-layer: 0.031", 0.040", 0.047", 0.062", 0.093" and 0.125"
  //8-layer: 0.062", 0.093" and 0.125"
  //10-layer: 0.062", 0.093" and 0.125"

  switch (this.mBoardThickness) {
    // 62 mil board
    case 62:
      switch (metalLayerNames.size()) {
        case 2:
          this.mLayers[1].setThickness(57);
          this.mLayers[1].mMaterialName = "FR-4";
          break;
        case 4:
          this.mLayers[1].setThickness(9.1);
          this.mLayers[3].setThickness(37);
          this.mLayers[5].setThickness(9.1);
          break;
        case 6:
          this.mLayers[1].setThickness(8.1);
          this.mLayers[3].setThickness(13);
          this.mLayers[5].setThickness(9.1);
          this.mLayers[7].setThickness(13);
          this.mLayers[9].setThickness(8.1);
          break;
        case 8:
          this.mLayers[1].setThickness(5.9);
          this.mLayers[3].setThickness(9);
          this.mLayers[5].setThickness(7.3);
          this.mLayers[7].setThickness(5.1);
          this.mLayers[9].setThickness(7.3);
          this.mLayers[11].setThickness(9);
          this.mLayers[13].setThickness(5.9);
          break;
        case 10:
          this.mLayers[1].setThickness(5.9);
          this.mLayers[3].setThickness(5.1);
          this.mLayers[5].setThickness(4.5);
          this.mLayers[7].setThickness(5.1);
          this.mLayers[9].setThickness(5.9);
          this.mLayers[11].setThickness(5.1);
          this.mLayers[13].setThickness(4.5);
          this.mLayers[15].setThickness(5.1);
          this.mLayers[17].setThickness(5.9);
          break;

        default:
          console.log("Error: " + metalLayerNames.size() + " layer board is not supported!");
          return false;

      } // switch (metalLayers.size())

      break;

    // 93 mil board
    case 93:
      switch (metalLayerNames.size()) {
        case 4:
          this.mLayers[1].setThickness(13);
          this.mLayers[3].setThickness(59);
          this.mLayers[5].setThickness(13);
          break;
        case 6:
          this.mLayers[1].setThickness(9.1);
          this.mLayers[3].setThickness(28);
          this.mLayers[5].setThickness(9.1);
          this.mLayers[7].setThickness(28);
          this.mLayers[9].setThickness(9.1);
          break;
        case 8:
          this.mLayers[1].setThickness(9.1);
          this.mLayers[3].setThickness(14);
          this.mLayers[5].setThickness(9.1);
          this.mLayers[7].setThickness(14);
          this.mLayers[9].setThickness(9.1);
          this.mLayers[11].setThickness(14);
          this.mLayers[13].setThickness(9.1);
          break;
        case 10:
          this.mLayers[1].setThickness(9.1);
          this.mLayers[3].setThickness(8);
          this.mLayers[5].setThickness(9.1);
          this.mLayers[7].setThickness(8);
          this.mLayers[9].setThickness(9.1);
          this.mLayers[11].setThickness(8);
          this.mLayers[13].setThickness(9.1);
          this.mLayers[15].setThickness(8);
          this.mLayers[17].setThickness(9.1);
          break;

        default:
          console.log("Error: " + metalLayerNames.size() + " layer board is not supported!");
        // even though we report an error, the default stackup is returned as a last resort

      } // switch (metalLayers.size())

      break;

    // unrecognized board thickness
    default:
      console.log("Error: Unsupported board thickness " + this.mBoardThickness);
      return false;

  } // switch(this.mBoardThickness)

  this.mUnit = "Length_mils";

  // convert the thickness into the a given unit
  if (strUnitName) {

    var csUnit = CsUnits.StringToUnits(strUnitName);

    if (csUnit != this.mUnit && csUnit != "Unknown") {
      for (var iLayer in this.mLayers) {
        var t = this.mLayers[iLayer].getLayerThickness();
        t = Math.round(CsUnits.Convert(t, this.mUnit, csUnit) * 100) / 100;
        this.mLayers[iLayer].setThickness(t);
      }
      this.mUnit = csUnit;
    }
  }

  return true;

}; // CeLayerStack.prototype.createLayerStackFromLayout


export default CeLayerStack;
export { CeElectricLayer };