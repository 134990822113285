function IbisModel(name, type = 'NA', enable = 'NA', vcc = 'NA') {
  this.name = name; // String, model name
  this.type = type;
  this.enable = enable;
  this.deviceVcc = vcc;
}

function selectorModel(selector) {
  this.selector = selector; // String, selector name
  this.models = []; //[{name:'model name', description, info:'name description'}]
}

function PackageModel(component) {
  this.component = component; // String, component name
  this.generic = []; // generic [ { R_pkg, typ, min, max },{ L_pkg, typ, min, max } ,{ C_pkg, typ, min, max }  ]
  this.packageModel = null;// String, package model name "XXXX"
  this.pinSection = [] //pin section
  this.diffPins = [];//diff pins [ {pin:"", invPin: ""} ]
}

function parseIBISModelSelector(fileContent) {
  // convert the input into an Array of Strings
  let models = [], selectorModels = [], packages = [];
  if (!fileContent) {
    return { models, selectorModels, packages };
  }
  const lines = fileContent.match(/[^\r\n]+/g);
  let inBLock = false;
  let selectorName = null, selectors = [];
  if (lines && lines.length > 0) {
    for (const line of lines) {
      //model selector
      if (line.match(/^\[Model Selector\]/i)) {
        inBLock = true;
        let words = line.match(/[^\s\t]+/g);
        selectorName = words[2];
        if (selectors.indexOf(selectorName) < 0) {
          const model = new selectorModel(selectorName);
          selectorModels.push(model);
        }
        selectors.push(selectorName);
      } else if (line.match(/^\*Package/i)) {
        inBLock = false;
        break;
      } else if (inBLock) {
        //model selector item
        let words = line.match(/[^\s\t]+/g);
        const index = selectorModels.findIndex(item => item.selector === selectorName);
        let _words = words;
        const modelName = _words[0];//model name
        const description = _words.slice(1, _words.length).join(" ");// description
        const info = line;//name description
        selectorModels[index].models.push({ name: modelName, description, info });
      } else {
        // all models
        selectorName = null;
        inBLock = false;
        let words = line.match(/[^\s\t]+/g);
        if (words.length >= 4) {
          const model = new IbisModel(...words);
          models.push(model);
        }
        continue;
      }
    }
  }

  //parse component package model
  const packageIndex = lines.findIndex(line => line.match(/^\*Package/i));
  const packageLines = packageIndex > -1 ? lines.filter((item, index) => index > packageIndex) : [];
  if (packageLines && packageLines.length > 0) {
    packages = getIBISPackageModels(packageLines);
  }
  // return models;
  return { models, selectorModels, packages };
}

//parse file content package
function getIBISPackageModels(packageLines) {
  let packages = [];
  let packageInBlock = false, componentInBlock = false, pinInBlock = false, diffPinBlock = false;
  let components = [], compName = null, packageItems = [], pinItems = [], diffPinItems = [];
  if (packageLines && packageLines.length > 0) {
    for (const line of packageLines) {
      if (line.match(/^\[Component\]/i)) {
        componentInBlock = true;
        let words = line.match(/[^\s\t]+/g);
        compName = words[1];
        if (components.indexOf(compName) < 0) {
          const packageModel = new PackageModel(compName);
          packages.push(packageModel);
        }
        components.push(compName);
      } else if (componentInBlock) {
        if (line.match(/^\[Package Model\]/i)) {
          //package model name
          let words = line.match(/[^\s\t]+/g);//[Package model] 'model name'
          const index = packages.findIndex(item => item.component === compName);
          let _words = words;
          const modelName = _words[2] ? _words[2] : null;//package model name
          if (index > -1) {
            packages[index].packageModel = modelName ? modelName : null;
          }
        } else if (line.match(/^\[Package\]/i)) {
          //package
          packageInBlock = true;
          let words = line.match(/[^\s\t]+/g);//[Package]  / [Package] | typ  min  max
          let _words = words;
          if (_words.length > 1) {
            for (let item of _words) {
              if (!item || item === _words[0] || item.match(/^\|/i)) {
                continue;
              } else {
                packageItems = [];
                packageItems.push(item);//['typ','min','max']
              }
            }
          }
        } else if (line.match(/^\[Pin\]/i)) {
          pinInBlock = true;
          let words = line.match(/[^\s\t]+/g);//[Pin]  / [Pin] signal_name model_name R_pin L_pin C_pin | TD_pin(ps) Z_pin
          let _words = words;
          if (_words.length > 1) {
            for (let item of _words) {
              if (!item || item === _words[0]) {
                continue;
              } else {
                pinItems = ['pin'];
                if (item.match(/^\|/i)) {
                  pinItems.push('|');
                  const newValue = item.replace("|", "");
                  pinItems.push(newValue);//['signal_name','model_name','R_pin','L_pin','C_pin','|', 'TD_pin(ps)' ,'Z_pin']
                } else {
                  pinItems.push(item);//['signal_name','model_name','R_pin','L_pin','C_pin','|', 'TD_pin(ps)' ,'Z_pin']
                }
              }
            }
          } else {
            pinItems = ['pin', 'signal_name', 'model_name', 'R_pin', 'L_pin', 'C_pin', '|', 'TD_pin(ps)', 'Z_pin'];
          }
        } else if (line.match(/^\[Pin end\]/i)) {
          pinInBlock = false;
          continue;
        } else if (line.match(/^\[Diff Pin\]/i)) {
          diffPinBlock = true;
          diffPinItems = ["diff_Pin", "inv_pin"];
          continue;
        } else if (line.match(/^\[Diff Pin end\]/i)) {
          diffPinBlock = false;
          continue;
        } else if (line.match(/^\[Package end\]/i)) {
          packageInBlock = false;
          continue;
        } else if (pinInBlock) {
          //pin section parse
          //eg:
          // pin signal_name model_name        R_pin     L_pin     C_pin     | TD_pin(ps) Z_pin
          // 12  SDRV_TX9_P  IOCAL2OT1RA00_EXP 4.359e-01 7.170e-09 1.320e-12 | xxx        xxx
          let words = line.match(/[^\s\t]+/g);// Pin signal_name model_name R_pin L_pin C_pin | TD_pin(ps) Z_pin
          let _words = words;
          if (_words.length > 0) {
            let pinName = _words[0];
            const index = packages.findIndex(pac => pac.component === compName);
            if (index > -1) {
              packages[index].pinSection.push({
                pin: pinName
              })
            }
            for (let i = 1; i < _words.length; i++) {
              let item = _words[i];
              let pinIndex = packages[index].pinSection.findIndex(pin => pin.pin === pinName);
              if (pinIndex > -1 && pinItems.length > 0 && item && item !== "|") {
                packages[index].pinSection[pinIndex][pinItems[i]] = item;
              }
            }
          }
        } else if (packageInBlock) {
          //package parse
          //eg:
          // R_pkg 3.704e-01 2.894e-01 4.514e-01
          // L_pkg 5.600e-09 4.020e-09 7.170e-09
          // C_pkg 1.300e-12 9.412e-13 1.660e-12
          let words = line.match(/[^\s\t]+/g);// [ R_pkg, typ, min, max ]
          if (words.length > 0) {
            const index = packages.findIndex(pac => pac.component === compName);
            let pkgObj = {
              name: words[0] ? words[0] : null,
              typ: words[1] ? words[1] : null,
              min: words[2] ? words[3] : null,
              max: words[3] ? words[3] : null,
            };

            if (index > -1) {
              packages[index].generic.push(pkgObj);
            }
          }
        } else if (diffPinBlock) {
          //diff pin section parse
          //eg:
          // [Diff_Pin] inv_pin vdiff tdelay_typ tdelay_min tdelay_max
          //     1        2       NA      NA         NA        NA
          let words = line.match(/[^\s\t]+/g);
          if (words.length > 0) {
            const index = packages.findIndex(it => it.component === compName);
            if (index > -1) {
              packages[index].diffPins.push({
                pin: words[0],
                invPin: words[1] ? words[1] : ""
              })
            }
          }
        }
      }
    }
  }
  return packages;
}

export {
  parseIBISModelSelector,
  getIBISPackageModels
}