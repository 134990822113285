import { numFormatConversion } from "../../helper/numberHelper";
import { getDefaultName } from "../../helper/setDefaultName";
import { DIELECTRIC } from "../stackupConstants";
import { DielectricMaterial } from "./IntegratedMaterial";
import { FREQUENCY, USE_DC_EPS, FREQ_CORNER, EPS_DC, SIGMA } from './materialConstants';

function getDefaultMaterialName({ materialType, materialList }) {
  let defaultName = "";
  if (materialType === DIELECTRIC) {
    defaultName = "Dielectric";
  } else {
    defaultName = "Metal";
  }

  const materialTypeList = materialList.filter(item => item.type === materialType);
  return getDefaultName({
    nameList: materialList,
    defaultKey: defaultName,
    firstIndex: materialTypeList.length + 1
  })
}

function getFreqDepOption(type) {
  if ([FREQUENCY, USE_DC_EPS, FREQ_CORNER, EPS_DC, SIGMA].includes(type)) {
    return true;
  }
}

function getDielectricMaterial(config) {
  let material = new DielectricMaterial({ ...config });
  material = materialUnitSplit(material);
  return material;
}

function materialUnitSplit(material) {
  let _config = {};
  for (let item in material) {
    if ([FREQUENCY, FREQ_CORNER].includes(item)) {
      if (material[item].match('Hz')) {
        const value = material[item];
        _config[`${item}Unit`] = value.slice(-3);
        _config[item] = value.slice(0, -3);
      } else {
        const value = numFormatConversion(material[item], -9) //Hz -> GHz
        _config[`${item}Unit`] = "GHz";
        _config[item] = value;
      }
    } else {
      _config[item] = material[item];
    }
  }
  return _config;
}

export {
  getDefaultMaterialName,
  getFreqDepOption,
  getDielectricMaterial,
  materialUnitSplit
}