import React from 'react';
import { Descriptions, Tag } from 'antd';
import '@/publicCss/style.css';
import './index.css';

const DescItem = Descriptions.Item;
const descDom = (props) => {
  const { descData, show, selectTags, maxPinLen } = props;
  const width = maxPinLen * 7.5 + 14
  return <div className='margin-top-20 port-setup-desc-gorup'>
    {descData.map(item => {
      const descItem = [{ label: 'Selected', list: item.used.sort() }, { label: 'Available', list: item.unused.sort() }]
      const color = item.netType === 'PowerNet' ? 'volcano' : 'geekblue'
      return <div className={`port-setup-border port-setup-desc port-border-${item.netType}`} key={item.title}>
        <span className="font-bold port-setup-title-color">{item.title}</span>
        <Descriptions layout="vertical" column={1} size="small" className="desc-body" bordered>
          {descItem.map(d => {
            return <DescItem label={d.label} key={d.label}>
              {d.list.map(pin => {
                return <Tag
                  color={show.includes(pin) ? 'magenta' : color}
                  className="port-setup-tag"
                  onClick={() => selectTags([pin], item.netType)}
                  style={{ width }}
                  key={pin}
                >{pin}</Tag>
              })}
            </DescItem>
          })}
        </Descriptions>
      </div>
    })}
  </div>
}

export default descDom