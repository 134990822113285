import React, { Component } from 'react';
import UploadFile from '@/components/UploadFile';
import { TARGET_TOUCHSTONE } from '@/services/Cascade/constants';
import { uploadTargetTouchstone } from '@/services/Cascade/Impedance';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';

class TargetUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      errorFileList: [],
      checking: false,
      uploading: false,
      errorMsg: '',
      uploadProgress: -1,
      errorName: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  onUploadFile = (fileList, errorFileList) => {
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
      let _checkName = file.fileName.replace(/(.s1p)/g, '');
      let errorMsg = '';
      //check name format
      errorMsg = checkNameFormat(_checkName);
      this.setState({
        errorMsg,
        checking: false,
        errorName: [file.fileName]
      });
    });
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    });
  }

  delFile = (fileList, errorFileList) => {
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    })
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    let _fileList = [...fileList];
    const findIndex = _fileList.findIndex(item => item.name === prevName);
    const fileSuffix = _fileList[findIndex].fileSuffix;
    _fileList[findIndex].name = name;
    _fileList[findIndex].fileName = `${name}${fileSuffix}`;
    _fileList[findIndex].file.fileName = `${name}${fileSuffix}`;
    this.setState({
      fileList: [..._fileList]
    });
    let _checkName = name.replace(/(.s1p)/g, '');
    let errorMsg = checkNameFormat(_checkName), errorName = [];
    if (errorMsg) {
      errorName = [name];
    }
    this.setState({
      errorMsg: errorMsg,
      checking: false,
      errorName: errorName
    })
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          compressProgress: 100,
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  saveTemplateFile = () => {
    const { fileList, uploadProgress } = this.state;
    if (uploadProgress === -1) {
      this.setState({
        checking: true,
        errorMsg: '',
      });
      if (!fileList.length) {
        this.props.closeTemplatePanel(false);
        return;
      }
      let name = fileList[0].fileName.replace(/(.s1p)/g, '');
      const errorMsg = checkNameFormat(name);
      if (errorMsg) {
        this.setState({
          errorMsg,
          checking: false,
          errorName: [name]
        });
        return;
      }
      this.setState({
        checking: false,
        uploading: true
      })
      let libraries = [];
      let files = [];
      fileList.forEach(file => {
        libraries.push({
          name: file.fileName,
          fileName: file.fileName,
          libraryData: {}
        });
        files.push(file.file);
      });
      uploadTargetTouchstone({
        files,
        name: `${name}.s1p`,
        metaData: this.metadata,
        config: this.config,
        targetName: name
      }).then(res => {
        if (res) {
          const { targetIndex } = this.props;
          this.props.updateTarget(res, targetIndex);
          this.closeUpload(100);
        } else {
          this.closeUpload(-1, "Upload target failed.");
        }
      }, error => {
        console.error(error);
        this.closeUpload(-1, `${error} Upload target failed.`);
      });
    }
  }

  closeUpload = (progress, error, name) => {
    this.setState({
      uploading: false,
      repeatedName: [],
      uploadProgress: progress,
      errorMsg: error
    });
    if (!error) {
      setTimeout(() => {
        this.props.changePrevType(TARGET_TOUCHSTONE);
      }, 500);
    }
  }

  render() {
    const { errorMsg, checking, errorName, uploading } = this.state;
    const accept = '.s1p';
    return <UploadFile
      text={`Upload Target File`}
      uploading={uploading}
      accept={accept}
      onUploadFile={this.onUploadFile}
      allowMulti={false}
      errorMsg={errorMsg}
      checking={checking}
      errorName={errorName}
      changeFileName={this.changeFileName}
      saveFile={this.saveTemplateFile}
      delFile={this.delFile}
    />
  }
}

export default TargetUpload;