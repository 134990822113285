import { getFileInPinToPinProject } from './apiCtrl';
import { getFileInSierraPinToPinProject } from '../../api/sierra';
import { getExperimentResultPromise } from '../projectCtrl';
import { CHANNELSKEW_DAT } from '../../../pages/Sierra/constants';
import { getPCBCompAndPin } from '../../helper/resultPublic';
import { ResultData, SweepResultData } from '@/services/Sierra';
import { splitComponentName } from '../../helper/splitComponent';


/* const projectId = window.location.search.split('=')[1]; */
var factory = {};
factory.getSignalDelayData = getSignalDelayData;
factory.getSweepDelayData = getSweepDelayData;

function SignalDelayItem() {
  this.netName = null;
  this.driver = null;
  this.receiver = null;
  this.delay = null;
  this.skew = null;
}

SignalDelayItem.prototype.setDriver = function (name, pin) {
  this.driver = {
    name: name,
    pin: pin
  }
};

SignalDelayItem.prototype.setReceiver = function (name, pin) {
  this.receiver = {
    name: name,
    pin: pin
  }
}

SignalDelayItem.prototype.setDelayFromStr = function (str) {
  this.delay = parseFloat((Number(str) * 1e12).toFixed(3));
}

SignalDelayItem.prototype.setSkew = function (skew) {
  this.skew = skew;
};

function getSignalDelayData(interfaceId, PCBIDMap, projectId, verificationSubId) {
  return new Promise((resolve, reject) => {
    //Delay - path={verification_subid}/result/models/CHANNELSkew.dat
    const pathID = `${interfaceId}/result/models`;
    const fileName = `CHANNELSkew.dat`;
    if (projectId) {
      getFileInSierraPinToPinProject(projectId, `${pathID}/${fileName}`).then(response => {
        ResultData.getVerificationJsonPromise(verificationSubId, interfaceId, projectId).then(res => {
          const content = res && res.Interfaces && res.Interfaces.length ? res.Interfaces : [];
          const data = parseModelSelectorFile(response.data, PCBIDMap, content);
          resolve(data);
        })
      }, error => {
        reject(error)
      });
    } else {
      getFileInPinToPinProject(`${pathID}/${fileName}`).then(response => {
        ResultData.getVerificationJsonPromise(verificationSubId, interfaceId, projectId).then(res => {
          const content = res && res.Interfaces && res.Interfaces.length ? res.Interfaces : [];
          const data = parseModelSelectorFile(response.data, PCBIDMap, content);
          resolve(data);
        })
      }, error => {
        reject(error)
      });
    }
  })
}

function getSweepDelayData(experimentId, PCBIDMap, projectId) {
  return new Promise((resolve, reject) => {
    return getExperimentResultPromise({ experimentId, option: CHANNELSKEW_DAT }).then(response => {
      SweepResultData.getVerificationJsonPromise(experimentId, projectId).then(content => {
        const data = parseModelSelectorFile(response, PCBIDMap, content);
        resolve(data);
      })
    }, error => {
      reject(error);
    })
  })
}

function parseModelSelectorFile(fileContent, PCBIDMap, content) {
  let signalDelayList = [];
  let fileArray = fileContent.match(/[^\r\n]+/g);
  let netName;
  let nameInfo = [];
  if (content && content.length) {
    for (let info of content) {
      if (!info.content || !info.content.components) { continue }
      for (let comp of info.content.components) {
        nameInfo.push(...comp.pins.map(pin => ({ comp: splitComponentName(comp.name), pin: pin.pin, name: `${splitComponentName(comp.name)}_${pin.pin}`, compName: comp.name })));
      }
    }
  }
  for (let i = 0, len = fileArray.length; i < len; i++) {
    let words = fileArray[i].trim().split(" ");
    if (words.length === 1) {
      netName = words[0];
    } else if (words.length >= 3) {
      let item = getSignalDelayItem(words, netName, PCBIDMap, nameInfo);
      signalDelayList.push(item);
    } else {
      console.error('can\'t parse string: ' + fileArray[i]);
    }
  }

  signalDelayList.forEach(item => {

  });

  let clkDelay = null;
  for (let item of signalDelayList) {
    if (item.netName === 'SCL' || item.netName === 'SCLK') {
      item.skew = 0;
      clkDelay = item.delay;
    }
  }

  for (let item of signalDelayList) {
    // if (item.netName === 'SDA') {
    item.skew = Math.abs((item.delay - clkDelay).toPrecision(12));
    // }
  }

  return signalDelayList;
}

function getSignalDelayItem(list, netName, PCBIDMap, nameInfo) {
  let item = new SignalDelayItem();
  item.netName = netName;
  let DriverInfo = getPCBCompAndPin(list[0]);
  let driver = nameInfo.find(item => item.name === DriverInfo.compPinName);
  const designName = getDesignName(DriverInfo.pcbInfo, PCBIDMap);
  if (driver) {
    item.setDriver((designName ? designName : DriverInfo.pcbInfo) + '::' + driver.comp, driver.pin);
  } else {
    item.setDriver('', '');
  }

  let ReceiverInfo = getPCBCompAndPin(list[1]);
  let receiver = nameInfo.find(item => item.name === ReceiverInfo.compPinName);
  const ReceiverDesignName = getDesignName(ReceiverInfo.pcbInfo, PCBIDMap);
  if (receiver) {
    item.setReceiver((ReceiverDesignName ? ReceiverDesignName : ReceiverInfo.pcbInfo) + '::' + receiver.comp, receiver.pin);
  } else {
    item.setReceiver('', '');
  }
  item.setDelayFromStr(list[2]);
  return item;
}

function getDesignName(pcb, PCBIDMap) {
  const ID = pcb.substring(3);
  const index = Object.values(PCBIDMap).indexOf(ID);
  if (index > -1) {
    return Object.keys(PCBIDMap)[index];
  } else {
    return false;
  }
}



export default factory;