import { ModelPin } from '../../helper/IntegratedInterface';
import { parseIBISModelSelector } from '../../LibraryHelper/IBISModelHelper';
import { TX_MODEL_TYPES, RX_MODEL_TYPES, AUTO } from '../../LibraryHelper';
class IbisModelHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getIbisFiles() {
    return Object.keys(this);
  }

  /*get model list by filename and type(Rx, Tx)*/
  getIbisModelList(id, type) {
    const models = this[id];
    const modelTypes = type === 'Tx' ? [...TX_MODEL_TYPES] :
      type === 'Rx' ? RX_MODEL_TYPES : null;
    if (modelTypes === null || !models) {
      const _models = models || {};
      return !models ? null : { models: _models.models || [], selectorModels: _models.selectorModels || [], packages: _models.packages || [] };
    } else {
      return { models: models.models.filter(model => modelTypes.includes(model.type)), selectorModels: models.selectorModels, packages: models.packages }
    }
  }

  parseModelSelector(id, fileContent) {
    const models = parseIBISModelSelector(fileContent);
    //models : { models, selectorModels, packages }
    this[id] = models;
  }

  /*   
  //only parse ibis model,not parse package and model selector
  parseModelSelector(fileName, fileContent) {
    // convert the input into an Array of Strings
    const lines = fileContent.match(/[^\r\n]+/g);
    const models = [];
    for (const line of lines) {
      const model = new IbisModel(...line.split(' '))
      models.push(model);
    }
    this[fileName] = models;
  } */

  getIbisPackageList(id) {
    const models = this[id];
    if (!models || !models.packages) {
      return null;
    } else {
      return models.packages;
    }
  }

  deleteModel(id) {
    delete this[id];
  }
}

// Usage - Driver/Receiver
function getPins({ type, usage }) {
  let pins = [];
  const _type = typeof (type) === 'string' ? type.toUpperCase() : type;
  if (!type || usage === 'Receiver' || _type === 'INPUT') { return pins }
  pins = AUTO;
  // ModelPin
  const modelPins = pins.map(pinName => new ModelPin({ pinName }));
  return modelPins;
}

export default IbisModelHelper;
export { getPins };