import CeIODataNode from './CeIODataNode';
import StringList from '../utility/StringList';
/**
 * class CeIODataItem extends CeIODataNode
 */

// public CeIODataItem(String name, String value)
// public CeIODataItem(String name, StringList dataItems)
var CeIODataItem = function (name, value) {
    CeIODataNode.call(this, name);
    ///  StringList mItemValues;
    this.mItemValues = value;
};

// inherits from CeIODataNode
CeIODataItem.prototype = Object.create(CeIODataNode.prototype);
CeIODataItem.prototype.constructor = CeIODataItem;

// public StringList GetItemValue()
CeIODataItem.prototype.GetItemValue = function () {
    return this.mItemValues;
};

CeIODataItem.prototype.GetOneLineString = function () {
    var retStrLine = this.GetName() + " " + this.mItemValues.Join(" ");
    return retStrLine;
};

// public void WriteBlockItem(BufferedWriter output, int tabNum) throws IOException 
CeIODataItem.prototype.WriteBlockItem = function(intTabNum) {

    var strOutput = CeIODataNode.prototype.WriteBlockItem.call(this, intTabNum);

    strOutput += this.mName + " ";
    if (this.mItemValues == null) {
        console.log("Null pointer when writing data: " + this.mName);
    }
    if (this.mItemValues.isEmpty() == false) {
        var itemval = this.mItemValues.Join(' ');
        if (itemval != null)
            strOutput += itemval;
    }
    strOutput += '\n';

    return strOutput;
};

///  public void WriteBlockItemToBytes(ByteArrayOutputStream out, int tabNum) throws IOException {
// TODO

// public void AppendData(String val)
// public void AppendData(StringList vals)
CeIODataItem.prototype.appendData = function (valueOrValues) {
    this.mItemValues.push(valueOrValues);
};

export default CeIODataItem;
