import StringList from '../utility/StringList';

const CeNetPin = function (lyrName, compName, mPinNum) {
  this.mLayerName = lyrName;
  this.mCompName = compName;
  this.mPinNum = mPinNum;
};

CeNetPin.prototype.GetDataStrings = function () {
  var vals = new StringList();
  vals.add(this.mLayerName);
  vals.add(this.mCompName);
  vals.add(this.mPinNum);
  return vals;
}

CeNetPin.prototype.UpdateByString = function (dataString) {
  var size = dataString.size();
  if (size < 3) return false;

  this.mLayerName = dataString.get(0);
  this.mCompName = dataString.get(1);
  this.mPinNum = dataString.get(2);

  if (size === 4) {
    this.mMetalLayerName = dataString.get(3);
  }

  return true;
}

export default CeNetPin;