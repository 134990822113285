import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'antd';
import Panel from '../Panel';
import ProbePinsPoint from './probePinsPoint';
import './index.css';
class ProbePinsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      probePinsList: []
    }
    this.dialogRoot = document.getElementById('root');
    this.maxHeight = this.getMaxHeight();
  }

  getMaxHeight = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { height } = offset;
      if (!height) return 800;
      const _height = (height * 0.8) - 100;
      return _height > 1200 ? 1200 : _height;
    }
  }

  closeModal = () => {
    const { record } = this.props;
    const { probePinsList } = this.state;
    this.props.closeModal(probePinsList, record);
    this.props.save();
  }

  componentDidUpdate = (prevProps) => {
    const { record, verificationId } = this.props;
    const { record: { type } } = prevProps;
    //update type ==> probePins =[], close panel
    if (record.type !== type) {
      this.props.closeModal([], record);
      this.props.save();
    }

    //update verification ==> probePins =[], close panel
    if (verificationId !== prevProps.verificationId) {
      this.props.save();
    }
  }

  componentDidMount = () => {
    const { components } = this.props;
    let probePinsList = [];
    if (components && components.length > 0) {
      components.forEach(comp => {
        //add probe pins list
        let pins = [];
        comp.pins.forEach(item => {
          const findPinIndex = pins.findIndex(pin => pin.pin === item.pin);
          if (findPinIndex > -1) {
            pins[findPinIndex].net = pins[findPinIndex].net ? pins[findPinIndex].net : item.net;
            pins[findPinIndex].signal = pins[findPinIndex].signal ? pins[findPinIndex].signal : item.signal;
          } else {
            pins.push({
              pin: item.pin,
              selected: comp.probePins && comp.probePins.includes(item.pin),
              net: item.net,
              signal: item.signal
            })
          }
        })
        probePinsList.push({
          component: comp.name,
          pins: JSON.parse(JSON.stringify(pins))
        })
      })
    }

    this.setState({
      probePinsList
    })
  }

  selectProbePin = ({ pin, component }) => {
    const { probePinsList } = this.state;
    let _probePinsList = JSON.parse(JSON.stringify(probePinsList));
    const index = _probePinsList.findIndex(item => item.component === component);
    _probePinsList[index].pins.forEach(item => {
      if (pin === item.pin) {
        item.selected = !item.selected;
      }
    });

    this.setState({
      probePinsList: _probePinsList
    })
  }

  getProbePinsDisplay = (probePinsList) => {
    let probePinDisplayList = [], leftNets = [], rightNets = [];
    for (let comp of probePinsList) {
      let leftPins = [], rightPins = [];
      if (!comp.pins || !Array.isArray(comp.pins)) {
        continue;
      }
      for (let item of comp.pins) {
        if (leftNets.includes(item.net) && (leftPins.length - rightPins.length) <= 0) {
          leftPins.push(item);
          continue;
        }

        if (rightNets.includes(item.net) && (rightPins.length - leftPins.length) <= 0) {
          rightPins.push(item);
          continue;
        }

        if (leftPins.length <= rightPins.length) {
          leftPins.push(item);
          if (!leftNets.includes(item.net)) {
            leftNets.push(item.net)
          }
        } else {
          rightPins.push(item);
          if (!rightNets.includes(item.net)) {
            rightNets.push(item.net)
          }
        }
      }

      probePinDisplayList.push({
        component: comp.component,
        pins: JSON.parse(JSON.stringify(comp.pins)),
        leftPins,
        rightPins
      })
    }
    return probePinDisplayList;
  }

  renderDialog() {
    const { probePinsList } = this.state;
    const probePinDisplayList = this.getProbePinsDisplay(probePinsList);
    const content = (
      <Fragment>
        <Panel
          className={`probe-pins-panel ${(this.props.product || "").toLowerCase()}-panel`}
          title="Probes"
          onCancel={this.closeModal}
          zIndex={2000}
          width={800}
          position='panel-center-left'
          draggable
          minHeight={220}
          minWidth={400}
          maxHeight={this.maxHeight}
          overflow={"hidden"}
        >
          <div className='probe-pins-main' style={{ maxHeight: this.maxHeight - 50 }}>
            <div className='probe-pins-main-div'>
              {probePinsList && probePinsList.length > 0 ? <ProbePinsPoint
                probePinsList={probePinDisplayList}
                selectProbePin={this.selectProbePin}
                deleteComp={this.deleteComp}
              /> :
                <div className='probe-pins-main-no-data'>No Probe Pins</div>
              } </div>
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { inputRef } = this;
    const { text, className } = this.props;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          <Tooltip
            overlayClassName='aurora-tooltip'
            title={text}
          >
            <span className={className}>{text}</span>
          </Tooltip>
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default ProbePinsPanel;