import React, { Component, createRef } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Spin, Checkbox, Tooltip, Divider, ColorPicker } from "antd";
import totalTraceCapacitance from '../../../../../services/Sierra/results/totalTraceCapacitance/capacitanceConstructor';
import ResultLayout from '../../../../../services/Result/Public/resultLayout';
import getIndex from '../../../../../services/helper/insertionSearch';
import WaveformSetting from '@/services/Result/Public/waveform/waveformSetting';
import NP from 'number-precision';
import { numConversion } from '../../../../../services/helper/dataProcess';
import { scaleConversion } from '../../../../../services/helper/numberHelper';
import photo from '@/components/PublicSvg/photo.svg';
import saveSvg from 'save-svg-as-png';

class TotalCapacitanceCurve extends Component {
  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.state = {
      mouseCapacitance: {},
      pcbShowObj: {},
      signalShowObj: {}
    }
    this.curveSetting = null;
  }

  componentDidMount = () => {
    this.screenChange()
    const { reload } = this.props;
    if (reload) {
      this.props.updateReloadStatus(false);
      this.initPlot('reload');
    }
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateCurve } = this.props;
    const _update = isUpdateCurve && isUpdateCurve !== prevProps.isUpdateCurve;
    if (_update) {
      this.props.updateCurveStatus(false);
      this.initPlot("update");
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
    this.curveSetting = null;
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    if (this.props.capacitancePlot && this.props.capacitancePlot.plot) {
      this.props.capacitancePlot.plot.redrawPlot(this.svgRef.current);
    }
  }

  initPlot = (type) => {
    const { axis, portSelect } = this.props;
    const selected = !portSelect || !Object.keys(portSelect).length;
    const { caId, allValues } = this.initData(type, selected);
    if (this.curveSetting) {
      this.curveSetting.setAxis(axis);
    }

    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis
    }
    this.props.capacitancePlot.plotCapacitance({
      svgElement: this.svgRef.current,
      events,
      axis,
      id: "sierra_total_capacitance"
    });

    if (caId && allValues.length && (type === "update" || selected)) {
      this.changeAllPorts(allValues, caId, true, true)
    }
  }

  initData = (type, selected) => {
    let allValues = [], caId = null;
    const { designs } = this.props;
    let pcbShowObj = {}, signalShowObj = {};
    for (let design of designs || []) {
      pcbShowObj[design.id] = true;
      for (let caItem of design.curveContent || []) {
        signalShowObj[caItem.id] = true;
        if (!caId && (type === "update" || selected)) {
          caId = caItem.id;
          allValues = (caItem.children || [])
        }
      }
    }
    this.setState({
      pcbShowObj,
      signalShowObj
    })
    return { allValues, caId };
  }

  changeMouse = (frequency, yPrefix) => {
    if (!this.props.capacitancePlot) {
      return;
    }
    const curves = this.props.capacitancePlot.getCurves();
    let mouseCapacitance = {};
    for (let curve of curves || []) {
      if (!curve.visible) {
        continue;
      }
      const index = this._getIndex(curve.x, frequency);
      const current = index > -1 ? yPrefix(curve.y[index]) + 'F' : '';
      mouseCapacitance[curve.name] = current;
    }
    this.setState({
      mouseCapacitance
    })
  }

  _getIndex(xPoints, freq) {
    return getIndex(xPoints, freq, 0, xPoints.length - 1);
  }

  cancelMove = () => {
    this.setState({
      mouseCapacitance: {}
    })
  }

  changeAxis = (axis) => {
    if (this.curveSetting) {
      this.curveSetting.setAxis(axis);
    }
    this.props.updateAxis(axis)
  }

  changePort = async (sigData, caId) => {
    const { verificationId, pathObj, portSelect } = this.props;
    if (!pathObj[sigData.id]) {
      return
    }
    let _portSelect = { ...(portSelect || {}) };
    //remove
    if (_portSelect[caId] && _portSelect[caId].includes(sigData.id)) {
      _portSelect[caId] = _portSelect[caId].filter(item => item !== sigData.id);
      const curve = this.props.capacitancePlot.getCurve(sigData.id) || {};
      curve.visible = false;
      this.props.capacitancePlot.plotCurves();
      this.props.updatePortSelect(_portSelect);
      return;
    }
    //add
    let curve = this.props.capacitancePlot.getCurve(sigData.id);

    if (!curve) {
      const [verId, path] = pathObj[sigData.id].split("::");
      if (verId !== verificationId) {
        return;
      }
      let curveObj = await totalTraceCapacitance.getVerInfo(verificationId, path);
      if (!curveObj || !curveObj.curveData) {
        return;
      }

      curve = this.props.capacitancePlot.setCurve({
        name: sigData.id,
        x: curveObj.frequencies,
        y: curveObj.curveData[sigData.signal],
        visible: true,
        color: sigData.color
      })
    }
    curve.visible = true;
    this.props.capacitancePlot.plotCurves([curve]);
    if (!_portSelect[caId]) {
      _portSelect[caId] = []
    }
    _portSelect[caId].push(sigData.id)
    this.props.updatePortSelect(_portSelect);
  }

  changeAllPorts = async (allValues, caId, checked, isUpdate) => {
    const { verificationId, pathObj, portSelect } = this.props;
    let _portSelect = { ...(portSelect || {}) };
    let prevSelect = isUpdate ? [] : portSelect[caId] || [];

    let newSelect = [], delSelect = []
    const values = allValues.map(item => item.id);

    if (!checked) {
      delSelect = [...values];
      _portSelect[caId] = [];
    } else {
      newSelect = values.filter(item => !prevSelect.includes(item));
      _portSelect[caId] = values;
    }

    let newCurves = [];
    for (let item of newSelect) {
      const [pcbId, signal, segment] = item.split("::");
      let curve = this.props.capacitancePlot.getCurve(item);

      if (!curve) {
        if (!pathObj[item]) {
          continue;
        }
        const [verId, path] = pathObj[item].split("::");
        if (verId !== verificationId) {
          return;
        }
        let curveObj = await totalTraceCapacitance.getVerInfo(verificationId, path);
        if (!curveObj || !curveObj.curveData) {
          continue;
        }
        const findCurve = allValues.find(it => it.id === item) || {};
        curve = this.props.capacitancePlot.setCurve({
          name: item,
          x: curveObj.frequencies,
          y: curveObj.curveData[signal],
          visible: true,
          color: findCurve.color
        })
        newCurves.push(curve)
      }
      curve.visible = true;
    }

    for (let item of delSelect) {
      const curve = this.props.capacitancePlot.getCurve(item) || {};
      curve.visible = false;
    }

    this.props.capacitancePlot.plotCurves(newCurves);
    this.props.updatePortSelect(_portSelect);
  }

  colorChange = (e, curveId, signalId, designId) => {
    const value = e.toHexString()
    if (value) {
      const { designs } = this.props;
      let _designs = [...designs];
      const index = _designs.findIndex(item => item.id === designId);
      if (index < 0) {
        return;
      }
      const signalIndex = (_designs[index].curveContent || []).findIndex(item => item.id === signalId);
      if (index < 0) {
        return;
      }

      const curveIndex = (_designs[index].curveContent[signalIndex].children || []).findIndex(item => item.id === curveId);
      if (curveIndex < 0) {
        return;
      }
      _designs[index].curveContent[signalIndex].children[curveIndex].color = value;
      let curve = this.props.capacitancePlot.getCurve(curveId) || {};
      curve.color = value;
      this.props.capacitancePlot.updateCurve(curveId, curve);
      this.reloadAxisCB()
      this.props.updateDesignData(_designs)
    }
  }

  changeWidthCB = () => {
    if (this.props.capacitancePlot && this.props.capacitancePlot.plot) {
      this.props.capacitancePlot.plot.redrawPlot(this.svgRef.current);
    }
  }

  reloadAxisCB = () => {
    if (!this.props.capacitancePlot || !this.props.capacitancePlot.plot) {
      return
    }
    this.props.capacitancePlot.plot.updatePlot();
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax, xUnit, yUnit }) => {
    if (!this.props.capacitancePlot || !this.props.capacitancePlot.plot) {
      return
    }
    const plot = this.props.capacitancePlot.plot;
    if (type === 'xMin' || type === 'xMax') {
      const scale = scaleConversion("Hz", xUnit);
      const xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = NP.times(_xMin, scale),
        end = NP.times(_xMax, scale);
      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      const xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = NP.times(NP.divide(NP.minus(xrange[0], xMin), NP.minus(xMax, xMin)), width),
        endX = NP.times(NP.divide(NP.minus(xrange[1], xMin), NP.minus(xMax, xMin)), width);
      plot.updateRange(startX, endX);
    } else {
      // y axis
      const _yUnit = yUnit.replace("F", "")
      const scale = scaleConversion("F", _yUnit);
      const yStart = NP.times(numConversion(parseFloat(_yMin)), scale),
        yEnd = NP.times(numConversion(parseFloat(_yMax)), scale);
      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB()
    this.props.updateAxis({ xMin: _xMin, xMax: _xMax, yMin: _yMin, yMax: _yMax, xUnit, yUnit })
  }

  scaleChangeCB = (type, scale) => {
    this.props.updateAxis({ [`${type}Scale`]: scale });
    if (!this.props.capacitancePlot || !this.props.capacitancePlot.plot) {
      return
    }
    this.props.capacitancePlot.plot.changeScale(type, scale);
  }

  printResult = (e) => {
    e.preventDefault();
    const options = {
      backgroundColor: '#ffffff',
      encoderOptions: 1
    }
    document.querySelector(".plot") && saveSvg.saveSvgAsPng(document.querySelector(".plot"), "Total_trace_capacitance_result.png", options);
  }

  resultLeft = () => {
    const { loading } = this.props;
    return <div className='sierra-total-capacitance-left'>
      <Spin spinning={loading} size='large'>
        <div className='total-capacitance-svg'>
          <svg ref={this.svgRef}></svg>
          <div className='sierra-result-curve-right'>
            <div className='photo-box' onClick={this.printResult}>
              <img src={photo} alt="" className='sierra-photo' />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  }

  onRef = (ref) => {
    this.curveSetting = ref;
  }

  expandPCBAndSignal = (id, type) => {
    this.setState((prevState) => {
      return {
        [type]: { ...prevState[type], [id]: !prevState[type][id] }
      }
    })
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  resultRight = () => {
    const { designs, portSelect } = this.props;
    const { mouseCapacitance, pcbShowObj, signalShowObj } = this.state;
    return (
      <div className='sierra-total-capacitance-curve-right'>
        <div className='sierra-total-capacitance-curve-right-result'>
          {designs.map(pcbItem => {
            return (
              <div className='sierra-total-capacitance-curve-design-item' key={pcbItem.id}>
                <div
                  className='total-capacitance-curve-design-title'
                  onClick={() => this.expandPCBAndSignal(pcbItem.id, "pcbShowObj")}>
                  {pcbShowObj[pcbItem.id] ? <DownOutlined className="sierra-title-expand-icon" /> : <RightOutlined className="sierra-title-expand-icon" />}
                  <Tooltip
                    overlayClassName='aurora-tooltip'
                    title={pcbItem.name}>
                    <span>{pcbItem.name}</span>
                  </Tooltip>
                </div>
                {pcbShowObj[pcbItem.id] && pcbItem.curveContent && pcbItem.curveContent.length ?
                  pcbItem.curveContent.map(caItem => {
                    const selectList = portSelect[caItem.id] || [];
                    const checkedAll = selectList.length === (caItem.children || []).length;
                    const children = (caItem.children || []);
                    const singleSignal = children[0];
                    return (
                      <div className='sierra-total-capacitance-curve-item' key={caItem.id}>
                        {children.length === 1 && singleSignal ?
                          <div className='total-capacitance-curve-title total-capacitance-curve-single-title'>
                            <Checkbox
                              indeterminate={selectList.length && selectList.length !== (caItem.children || []).length}
                              checked={checkedAll}
                              onChange={(e) => this.changeAllPorts(caItem.children || [], caItem.id, checkedAll ? false : true)}
                            />
                            <ColorPicker
                              value={singleSignal.color}
                              className='aurora-color-picker-small'
                              onChange={(value) => this.colorChange(value, singleSignal.id, caItem.id, pcbItem.id)}
                              size='small'
                              style={{ overflow: 'visible' }}
                              onClick={(e) => this.stopPropagation(e)}
                            />
                            <Tooltip
                              overlayClassName='aurora-tooltip'
                              title={caItem.signal}>
                              <div className='total-capacitance-curve-title-span'>{caItem.signal}</div>
                            </Tooltip>
                            {mouseCapacitance && mouseCapacitance[singleSignal.id] ? <span
                              className='result-curve-current'
                              style={{ color: singleSignal.color }}
                            >{!isNaN(parseFloat(mouseCapacitance[singleSignal.id])) ? mouseCapacitance[singleSignal.id] : ""}</span> : null}
                          </div>
                          : <div className='total-capacitance-curve-title'>
                            {
                              signalShowObj[caItem.id] ? <DownOutlined onClick={() => this.expandPCBAndSignal(caItem.id, "signalShowObj")} className="sierra-title-expand-icon" /> : <RightOutlined onClick={() => this.expandPCBAndSignal(caItem.id, "signalShowObj")} className="sierra-title-expand-icon" />
                            }
                            <Checkbox
                              indeterminate={selectList.length && selectList.length !== (caItem.children || []).length}
                              checked={checkedAll}
                              onChange={(e) => this.changeAllPorts(caItem.children || [], caItem.id, checkedAll ? false : true)}
                            />
                            <Tooltip
                              overlayClassName='aurora-tooltip'
                              title={caItem.signal}>
                              <div className='total-capacitance-curve-title-span'>{caItem.signal}</div>
                            </Tooltip>
                          </div>}
                        {signalShowObj[caItem.id] && children.length > 1 ? (caItem.children || []).map(sigItem =>
                          <div className='total-capacitance-curve-signal-item' key={sigItem.id}>
                            <Checkbox
                              value={sigItem.id}
                              className='total-capacitance-curve-checkbox'
                              checked={selectList.includes(sigItem.id)}
                              onChange={() => this.changePort(sigItem, caItem.id)}
                            />
                            <ColorPicker
                              value={sigItem.color}
                              className='aurora-color-picker-small'
                              onChange={(value) => this.colorChange(value, sigItem.id, caItem.id, pcbItem.id)}
                              size='small'
                              style={{ overflow: 'visible' }}
                              onClick={(e) => this.stopPropagation(e)}
                              id={`color::${sigItem.id}`}
                            />
                            <Tooltip
                              overlayClassName='aurora-tooltip'
                              title={sigItem.comps.join(", ")}>
                              <label>Segment {sigItem.segment}</label>
                            </Tooltip>
                            {mouseCapacitance && mouseCapacitance[sigItem.id] ? <span
                              className='result-curve-current'
                              style={{ color: sigItem.color }}
                            >{!isNaN(parseFloat(mouseCapacitance[sigItem.id])) ? mouseCapacitance[sigItem.id] : ""}</span> : null}
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                  : null}
              </div>
            );
          })}
        </div>
        <div className='sierra-total-capacitance-curve-right-setting'>
          <Divider orientation="left" className='waveform-setting-title'>Setting</Divider>
          <div className='waveform-setting-box-setup'>
            <WaveformSetting
              onRef={this.onRef}
              currentResultKey={"CAPACITANCE"}
              plot={this.props.capacitancePlot && this.props.capacitancePlot.plot ? this.props.capacitancePlot.plot : null}
              axisChangeCB={this.axisChangeCB}
              reloadAxisCB={this.reloadAxisCB}
              scaleChangeCB={this.scaleChangeCB}
              showScale={true}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <ResultLayout
      className="sierra-total-capacitance-curve"
      resultLeft={this.resultLeft}
      resultRight={this.resultRight}
      changeWidthCB={this.changeWidthCB}
    />
  }
}

export default TotalCapacitanceCurve;