import Layouts from '../LayoutCanvas'
import LayoutData from '../data/LayoutData';
import { getConnectedPinNumber, isPowerGND } from './net.js'

class NetGroupSelection {
  constructor() {
    this.setup = {} // 
    this.selectDataGroups = {}; // [nets:[],comp:[],pinsObj:[]]
  }

  selectNetGroup({ pinsObj = {}, comps = [], nets = [], pcbId, resComp = [], isPower = false }) {
    this.pcbId = pcbId;
    this.selectDataGroups = [];
    let updateCompsInfo = [];
    if (comps.length) {
      const compPinsNets = LayoutData.getCompPinsNets(pcbId);
      const layout = LayoutData.getLayout(pcbId) || {};
      let _symbol = layout.mSymbolMgr;

      for (let comp of comps) {
        let powerPins = {}, type = '';
        const compPinData = compPinsNets[comp];
        if (!compPinData) { continue }

        if (resComp.includes(comp)) {
          type = 'dampRes'
          const keys = Object.keys(compPinData);
          const keysLength = keys.length;
          if (keysLength === 2) {
            for (let key of keys) {
              const isPowerNet = isPowerGND(compPinData[key], _symbol, pcbId)
              if (isPowerNet) {
                type = 'pullRes';
                powerPins[key] = compPinData[key]
              }
            }

          } else if (keysLength > 2) {
            const pinLength = keysLength;
            if (pinsObj[comp] && pinsObj[comp].length) {
              for (let pinNum of pinsObj[comp]) {
                const connectPin = getConnectedPinNumber({ pinNum, pinLength })
                if (connectPin) {
                  const pinInfo = compPinData[connectPin];
                  if (!pinInfo) { continue };
                  const isPowerNet = isPowerGND(pinInfo, _symbol, pcbId)
                  if (isPowerNet) {
                    type = 'pullRes';
                    powerPins[connectPin] = pinInfo
                  }
                }
              }
            }
          }
        } else {
          type = 'master'
        }
        updateCompsInfo.push({ type, powerPins, comp })
      }
    }

    this.selectDataGroups = { nets, comp: updateCompsInfo, pinsObj }
    this.crossProbing(isPower)
  }

  crossProbing(isPower) {
    const layout = Layouts.getLayout(this.pcbId);
    if (layout) {
      layout.selectSignalNetGroup(this.selectDataGroups, isPower, this.pcbId);
    }
  }

}


export default NetGroupSelection;