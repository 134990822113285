import React, { Component, createRef } from 'react';
import NetListModel from './NetListModel';
import {
  getRepeaterPinList,
  getBufferPinList,
  getStimulusPinList
} from '../../services/helper/pinNodeMapping';
import { strDelimited } from '@/services/helper/split';
import './index.css';

const MULTI_PIN_SPICE = 'MultiPinSPICE';
const STIMULUS = 'Stimulus';
const REPEATER = 'Repeater';
class SetSPNodeModel extends Component {
  constructor(props) {
    super(props);
    const { files, pairs, modelType, compName } = props;
    this.state = {
      type: modelType,// Repeater / MULTI_PIN_SPICE
      files: Array.isArray(files) ? files : [],
      pairs: pairs,
      pinList: this.getPins(pairs, compName),
      subcktList: [], //[ { libraryId:"", fileName, models:[{ name:"", ports:[ port:"", info:"" , ...] } ], } ]
      portsObj: {}, //{ libraryId: [{ fileName, type, subckt, ports:[{ port:"",info:"", select: true/false}] } }] }
      errorMsg: null
    };
    this.inputRef = createRef();
  }

  getPins = (pairs, compName) => {
    const { pins, modelPinType, record, componentName } = this.props;
    let _pinList = [];
    switch (modelPinType) {
      case REPEATER:
        _pinList = getRepeaterPinList({ pins, pairs, compName });
        break;
      case MULTI_PIN_SPICE:
        const { usage } = record;
        _pinList = getBufferPinList({ usage, pairs, pins, componentName });
        break;
      case STIMULUS:
        _pinList = getStimulusPinList({ pairs, pins, componentName });
        break;
      default: break;
    }
    return _pinList;
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { files } = this.props;
    const _files = Array.isArray(files) ? files : [];
    //files [ { fileName:"", libraryId:"", type:"", subckt:"" }]
    if (_files && _files.length > 0) {
      this.getFileParse(_files);
    }
  }

  saveModel = () => {
    const { files, pairs } = this.state;
    return {
      files,
      pairs
    }
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  getFileParse = (files, isSelect) => {
    if (!files || files.length === 0) {
      return;
    }
    //filter libraryId not exist files
    const _files = files.filter(item => !!item.libraryId);
    const { pairs, pinList, portsObj, subcktList } = this.state;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let _pairs = [...pairs], _pinList = [...pinList];
    const { componentSetting, designId, autoAssignRepeaterPorts, record } = this.props;
    //get files ports
    this.getFilesParseList(files).then(async res => {
      if (res && Array.isArray(res)) {
        _files.map(async file => {
          const libraryId = file.libraryId, folder = file.folder, fileName = file.fileName;
          let models = [], _ports = [], findIndex = -1;
          const currentFileInfo = res.find(item => item.libraryId === file.libraryId);
          if (folder) {
            //folder
            const folderFiles = currentFileInfo && Array.isArray(currentFileInfo.files) ? [...currentFileInfo.files] : [];
            const currentFile = Array.isArray(folderFiles) ? folderFiles.find(item => item.fileName === fileName) : {};
            models = currentFile && Array.isArray(currentFile.models) ? [...currentFile.models] : [];
            findIndex = _subcktList.findIndex(it => it.libraryId === libraryId && it.fileName === fileName);
          } else {
            //file
            models = currentFileInfo && Array.isArray(currentFileInfo.models) ? [...currentFileInfo.models] : [];
            findIndex = _subcktList.findIndex(it => it.libraryId === libraryId);
          }

          if (findIndex > -1) {
            _subcktList[findIndex] = {
              libraryId,
              fileName: file.fileName,
              models
            }
          } else {
            _subcktList.push({
              libraryId,
              fileName: file.fileName,
              models
            });
          }

          if (!file.subckt && models[0]) {
            file.subckt = models[0].name || "";
          }

          const findModel = models.find(item => file.subckt && item.name === file.subckt);
          const modelPorts = findModel && findModel.ports ? findModel.ports : [];
          const findNode = _pairs.find(item => !item.node);
          if (modelPorts.length === 2 && autoAssignRepeaterPorts && (isSelect || findNode)) {
            this.setState({
              loadingNode: true
            })
            const info = await autoAssignRepeaterPorts({
              componentSetting,
              designId,
              _pairs,
              _pinList,
              modelPorts,
              file,
              component: record.component,
              part: record.part
            });
            _pairs = info._pairs;
            _pinList = info._pinList;
            this.setState({
              pairs: _pairs,
              pinList: _pinList,
              loadingNode: false
            })
          }

          if (file.subckt) {
            let selectedPairs = pairs.filter(item => item.node && item.libraryId === libraryId && item.fileName === fileName && item.subckt === file.subckt);
            let selectedPorts = selectedPairs.map(item => item.node);
            //update ports
            if (modelPorts && Array.isArray(modelPorts)) {

              modelPorts.forEach(item => {
                _ports.push({
                  port: item,
                  info: item,
                  subckt: file.subckt,
                  select: selectedPorts.includes(item) ? true : false
                })
              });
            }
            if (_portsObj[libraryId]) {
              //find current subckt ports
              const index = _portsObj[libraryId].findIndex(item => (item.fileName === fileName && item.subckt === file.subckt));
              if (index > -1) {
                //update current subckt ports
                _portsObj[libraryId][index] = { fileName: file.fileName, type: file.type, subckt: file.subckt, ports: _ports };
              } else {
                //Add current subckt ports
                _portsObj[libraryId] = [..._portsObj[libraryId], { fileName: file.fileName, type: file.type, subckt: file.subckt, ports: _ports }];
              }
            } else {
              //Add current file and current subckt ports
              _portsObj[libraryId] = [{ fileName: file.fileName, type: file.type, subckt: file.subckt, ports: _ports }];
            }
          }
        });
        this.setState({
          pairs: _pairs,
          pinList: _pinList,
          portsObj: _portsObj,
          subcktList: _subcktList
        })
      }
    })
  }

  getFilesParseList = (files) => {
    const { getFolderFileDetail, getLibraryFile } = this.props;
    let _files = [];
    //file filter
    //_files  folder {libraryId, folder, fileNames:[fileName1,fileName2, ... ]}
    //_files file {libraryId, folder:"", fileNames:[fileName]}
    files.forEach(file => {
      const index = _files.findIndex(item => item.libraryId === file.libraryId);
      if (index > -1) {
        const findFile = _files[index].fileNames.find(item => item === file.fileName);
        if (file.folder && !findFile) {
          _files[index].fileNames.push(file.fileName);
        }
      } else {
        _files.push({ libraryId: file.libraryId, folder: file.folder, fileNames: [file.fileName] })
      }
    })
    const promiseList = _files.map(item => {
      if (item.folder) {
        return getFolderFileDetail({ libraryId: item.libraryId, fileNames: item.fileNames });
      } else {
        return getLibraryFile({ libraryId: item.libraryId });
      }
    });
    return Promise.all(promiseList);
  }

  selectNodes = ({ pinList, pairs, portsObj = this.state.portsObj }) => {
    this.setState({
      pinList,
      pairs,
      portsObj
    })
  }

  addNewFileSelect = () => {
    const { files } = this.state;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    if (_files.length === 0) {
      //default one select
      _files.push({
        folder: "",
        fileName: "",
        libraryId: "",
        subckt: "",
        type: ""
      });
    }
    //add new file select
    _files.push({
      folder: "",
      fileName: "",
      libraryId: "",
      subckt: "",
      type: ""
    })
    this.setState({
      files: _files,
      errorMsg: null
    }, () => {
      //update panel body height
      this.props.setContentHeight(0);
    });
  }

  selectFile = (key, fileIndex) => {
    if (!key) {
      return;
    }
    const { key: name } = key;
    const { libraryList, modelType } = this.props;
    const { files } = this.state;
    let delFile = fileIndex > -1 ? files[fileIndex] : null;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let newFile = null;
    //folder file  32433564::fileName
    //file fileName
    const [name_id, _fileName] = strDelimited(name, "::");
    let findFile = null, findFolder = null;

    findFile = libraryList.find(it => it.id === name_id);

    if (_fileName) {
      findFolder = findFile;
      findFile = findFolder && findFolder.children ? findFolder.children.find(it => it.name === _fileName) : null;
    }

    if (findFile) {
      const libraryId = findFolder ? findFolder.id : findFile.id;
      newFile = {
        type: modelType,
        folder: findFolder ? findFolder.name : "",
        fileName: findFile.name,
        libraryId,
        subckt: "",
      }
    }
    if (!newFile) {
      return;
    }

    //Add new file to files
    if (fileIndex > -1) {
      _files[fileIndex] = newFile;
    } else {
      _files.push(newFile);
    }

    this.setNewFile(newFile, delFile, _files);
  }

  selectByFolder = (file, fileIndex) => {
    const { modelType } = this.props;
    const { files } = this.state;
    let delFile = fileIndex > -1 ? files[fileIndex] : null;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    const isFolder = file.childFile ? true : false;
    const newFile = {
      type: modelType,
      folder: isFolder ? file.name : "",
      fileName: isFolder ? file.childFile : file.name,
      libraryId: file.id,
      subckt: "",
      version: file.version
    }
    if (fileIndex > -1) {
      _files[fileIndex] = newFile;
    } else {
      _files.push(newFile);
    }
    this.setNewFile(newFile, delFile, _files);
  }

  setNewFile = (newFile, delFile, _files) => {
    const { portsObj, subcktList } = this.state;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    //delete prev select file portsObj and subcktList
    if (delFile && delFile.libraryId && _portsObj[delFile.libraryId]) {
      const findList = _files.filter(item => item.libraryId === delFile.libraryId);
      if (findList.length === 0) {
        //delete portsObj
        delete _portsObj[delFile.libraryId];
        //delete subckt
        _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
      } else {
        //delete current delete file current subckt ports
        _portsObj[delFile.libraryId] = _portsObj[delFile.libraryId].filter(item => ((delFile.subckt && item.subckt !== delFile.subckt) || (delFile.fileName !== item.fileName)));
      }
    }
    this.setState({
      files: _files,
      portsObj: _portsObj,
      subcktList: _subcktList,
      errorMsg: null
    }, () => {
      this.clearPinNode({ delFile, newFile });
      //update panel body height
      this.props.setContentHeight(0);
    });
  }

  delFileSelect = (type, fileIndex, deleteLine) => {
    const { portsObj, files, subcktList } = this.state;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    if (fileIndex < 0) {
      return;
    }

    //delete file or delete subckt file
    const delFile = _files.find((item, index) => index === fileIndex);
    if (delFile) {
      //delete current file select node
      this.clearPinNode({ delFile: { ...delFile } });
    }
    if (type !== "Subckt") {

      if (delFile && delFile.libraryId) {
        const otherFiles = _files.filter((item, index) => index !== fileIndex && item.libraryId === delFile.libraryId);
        if (otherFiles.length === 0) {
          delete _portsObj[delFile.libraryId];
          _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
        } else {
          _portsObj[delFile.libraryId] = (_portsObj[delFile.libraryId] || []).filter(item => item.fileName !== delFile.fileName || item.subckt !== delFile.subckt);
        }
      }
      if (deleteLine) {
        //delete current file line
        _files = _files.filter((item, index) => index !== fileIndex);
      } else {
        //delete current file
        _files[fileIndex] = {
          type: "",
          folder: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        };
      }
      this.setState({
        files: _files,
        portsObj: _portsObj,
        subcktList: _subcktList,
        errorMsg: null
      }, () => {
        //update panel body height
        deleteLine && this.props.setContentHeight(0);
      })
    } else {
      //delete current file subckt
      const libraryId = _files[fileIndex].libraryId;
      _portsObj[libraryId] = _portsObj && _portsObj[libraryId].filter(item => (item.fileName !== _files[fileIndex].fileName) || (item.subckt !== _files[fileIndex].subckt));
      _files[fileIndex].subckt = "";
      this.setState({
        files: _files,
        portsObj: _portsObj,
        errorMsg: null
      })
    }
  }

  clearPinNode = async (params) => {
    const { delFile, newFile, newSubckt, ports, file } = params ? params : {};
    const { pairs, pinList } = this.state;
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];
    for (let i = 0; i < _pairs.length; i++) {
      let item = _pairs[i];
      if (!item.node) {
        continue;
      }
      //param is undefined,clear all nodes
      //delFile exist, clear delFile selected nodes
      if (!params || (delFile && delFile.libraryId === item.libraryId && delFile.fileName === item.fileName && delFile.subckt === item.subckt)) {
        item.node = "";
        item.libraryId = "";
        item.subckt = "";
        item.fileName = "";
        item.modelKey = "";
      }
      _pairs[i] = item;
    }

    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : this.getPins();

    for (let i = 0; i < _pinList.length; i++) {
      let item = _pinList[i];
      if (!item.pinLValue && !item.pinRValue) {
        continue;
      }
      //params is undefined,clear all nodes
      //delFile exist, clear delFile selected nodes
      if (!params || (delFile && delFile.libraryId === item.pinLLibraryId && delFile.fileName === item.pinLFileName && delFile.subckt === item.pinLSubckt)) {
        item.pinLValue = "";
        item.pinLLibraryId = "";
        item.pinLFileName = "";
        item.pinLSubckt = "";
        item.pinLModelKey = "";
      }
      //params is undefined,clear all nodes
      //delFile exist, clear delFile selected nodes
      if (!params || (delFile && delFile.libraryId === item.pinRLibraryId && delFile.fileName === item.pinRFileName && delFile.subckt === item.pinRSubckt)) {
        item.pinRValue = "";
        item.pinRLibraryId = "";
        item.pinRFileName = "";
        item.pinRSubckt = "";
        item.pinRModelKey = "";
      }
      _pinList[i] = item;
    }
    if (newSubckt && ports && file) {
      const { files } = this.state;
      const { componentSetting, designId, autoAssignRepeaterPorts, record } = this.props;
      if (files.length === 1 && ports.length === 2 && autoAssignRepeaterPorts) {
        this.setState({
          loadingNode: true
        })
        const info = await autoAssignRepeaterPorts({
          componentSetting,
          designId,
          _pairs,
          _pinList,
          modelPorts: ports,
          file,
          component: record.component,
          part: record.part
        });
        _pairs = info._pairs;
        _pinList = info._pinList;
      }
    }

    this.setState({
      pairs: _pairs,
      pinList: _pinList,
      loadingNode: false
    }, () => {
      if (newFile && newFile.libraryId) {
        this.getFileParse([newFile], true);
      }
    })
  }

  selectSpiceSubckt = (_key, fileIndex) => {
    if (!_key) {
      return;
    }
    const { label: name, key } = _key;
    const { subcktList, files, portsObj } = this.state;
    const { modelType } = this.props;
    let _portsObj = { ...portsObj };
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let currentFile = fileIndex > -1 && _files[fileIndex] ? { ..._files[fileIndex] } : null;
    if (!currentFile) {
      return;
    }

    const libraryId = currentFile.libraryId, fileName = currentFile.fileName, prevSubckt = currentFile.subckt;
    const find = _files.find(item => item.libraryId === libraryId && item.fileName === fileName && item.subckt && item.subckt === key);
    if (find) {
      this.setState({
        errorMsg: "The current subckt has been selected."
      }, () => {
        //update panel body height
        this.props.setContentHeight(0);
      })
      return;
    }
    _files[fileIndex].subckt = name;
    // add current subckt ports to portsObj
    const findSubckt = subcktList.find(it => it.libraryId === libraryId && it.fileName === fileName);
    const models = findSubckt && findSubckt.models ? findSubckt.models : [];
    const subcktFind = models.find(it => it.name === name);
    const ports = subcktFind && Array.isArray(subcktFind.ports) ? subcktFind.ports : [];
    let _ports = [];
    ports.forEach(port => {
      _ports.push({
        port,
        info: port,
        subckt: name,
        fileName: fileName
      });
    });
    //filter prev subckt ports
    _portsObj[libraryId] = _portsObj[libraryId] ? _portsObj[libraryId].filter(it => (fileName !== it.fileName) || (it.subckt !== prevSubckt)) : [];
    //add current select subckt ports
    if (name) {
      _portsObj[libraryId] = [..._portsObj[libraryId], { fileName, type: modelType, subckt: name, ports: _ports }];
    }

    this.setState({
      files: _files,
      portsObj: _portsObj,
      errorMsg: null
    }, () => {
      prevSubckt && this.clearPinNode({ delFile: { ...currentFile }, newSubckt: _files[fileIndex].subckt, ports, file: _files[fileIndex] });
      //update panel body height
      this.props.setContentHeight(0);
    })
  }

  render() {
    const { files, pinList, pairs, portsObj, subcktList, errorMsg, loadingNode } = this.state;
    const { record, libraryList, modelType, displayType, modelPinType, scrollId, maxWidth, maxHeight, product } = this.props;
    return (
      <NetListModel
        onRef={this.onRef}
        displayType={displayType}
        modelType={modelType}
        modelPinType={modelPinType}
        files={files}
        pinList={pinList}
        pairs={pairs}
        fileList={libraryList}
        record={record}
        portsObj={portsObj}
        subcktList={subcktList}
        errorMsg={errorMsg}
        scrollId={scrollId}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        product={product}
        loadingNode={loadingNode}
        {...this.actions}
      />
    )
  }

  actions = {
    _addNewFileSelect: this.addNewFileSelect,
    _selectFile: this.selectFile,
    _delFileSelect: this.delFileSelect,
    _clearPinNode: this.clearPinNode,
    _selectSpiceSubckt: this.selectSpiceSubckt,
    _selectNodes: this.selectNodes,
    _selectByFolder: this.selectByFolder
  }
}

export default SetSPNodeModel;