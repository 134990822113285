import React, { Fragment } from 'react';
import { Tabs, Table, Tooltip, Select } from 'antd';

const Option = Select.Option;
const UNIT = ['mil', 'um', 'mm'];

let impColumnsByLayer = [{
  title: 'Layer',
  dataIndex: 'layer',
  key: 'layer',
  width: '15%'
}, {
  title: 'Thickness',
  dataIndex: 'thickness',
  key: 'thickness',
  width: '15%'
}, {
  title: 'Line Width',
  dataIndex: 'w',
  key: 'lineWidth',
  width: '15%'
}, {
  title: 'Length',
  dataIndex: 'length',
  key: 'lengthOfAllLayer',
  width: '15%'
}, {
  title: 'Impedance (Ω)',
  dataIndex: 'Z0',
  key: 'Z0',
  width: '15%'
}];

let impColumnsByNet = [{
  title: 'Net',
  dataIndex: 'name',
  key: 'netName',
  width: "15%"
}, {
  title: 'Signal',
  dataIndex: 'signal',
  width: "15%",
}, {
  title: 'Layer',
  dataIndex: 'layer',
  key: 'layerNum',
  width: "15%"
}, {
  title: 'Min Z (Ω)',
  dataIndex: 'minZ0',
  key: 'minZ0',
  width: "15%"
}, {
  title: 'Max Z (Ω)',
  dataIndex: 'maxZ0',
  key: 'maxZ0',
  width: "15%"
}, {
  title: 'Length',
  dataIndex: 'length',
  key: 'totalLength',
  width: "15%"
}/* , {
  title: 'Spec',
  dataIndex: 'spec',
  key: 'spec',
  width: "15%",
  render: () => {
    return <span>45~55</span>
  },
} */];

let impChildColumnsByNet = [{
  title: 'Trace',
  dataIndex: 'trace',
  key: 'trace',
  width: "17%"
},
{
  title: 'Trace Width',
  dataIndex: 'width',
  key: 'width',
  width: "17%"
},
{
  title: 'Trace Thickness',
  dataIndex: 'thickness',
  key: 'thickness',
  width: "20%"
}, {
  title: 'Impedance (Ω)',
  dataIndex: 'impedance',
  key: 'impedance',
  width: "17%"
}, {
  title: 'Length',
  dataIndex: 'length',
  key: 'totalLength',
  width: "17%"
},
{
  title: 'Zdiff (Ω)',
  dataIndex: 'Zdiff',
  key: 'Zdiff',
  width: "12%"
}];

// const expandedRowRender = (data) => {
//   const columns = [{
//     title: 'Net',
//     dataIndex: 'name',
//     key: 'NetName',
//     width: "15%",
//   }, {
//     title: 'Signal',
//     dataIndex: 'signal',
//     width: "15%",
//   }, {
//     title: 'Layer',
//     dataIndex: 'layer',
//     key: 'layerOfNet',
//     width: "15%",
//   }, {
//     title: 'Min Z',
//     dataIndex: 'minZ0',
//     key: 'minZ0Extended',
//     width: "15%",
//     render: (text) => {
//       if (text) {
//         return <span>{text.toFixed(1)}</span>
//       }
//     }
//   }, {
//     title: 'Max Z',
//     dataIndex: 'maxZ0',
//     key: 'maxZ0Extended',
//     width: "15%",
//     render: (text) => {
//       if (text) {
//         return <span>{text.toFixed(1)}</span>
//       }
//     }
//   }, {
//     title: 'Length',
//     dataIndex: 'length',
//     key: 'length',
//     width: "15%",
//     render: (text) => {
//       if (text) {
//         return <span>{text.toFixed(1)}</span>
//       }
//     }
//   }/* , {
//     title: 'Spec',
//     dataIndex: 'spec',
//     key: 'specExtended',
//     width: "15%",
//     render: () => {
//       return <span>45~55</span>
//     },
//   } */];
//   return (
//     <Table
//       columns={columns}
//       dataSource={data.layerStats}
//       pagination={false}
//       showHeader={false}
//       size="small"
//       rowKey={record => record.layer}
//       className='impedance-expand-table'
//     />
//   );
// };

function unitRender(unit, type, settingUnitChange) {
  return <Select
    value={unit}
    onChange={(key) => settingUnitChange(key, type)}
    className='impedance-unit-selection'
    popupClassName='impedance-unit-option'
    popupMatchSelectWidth={false}
  >
    {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
  </Select>
}

function getLayerColumns(impedanceData, units, settingUnitChange) {
  const layerColumns = JSON.parse(JSON.stringify(impColumnsByLayer));
  if (impedanceData) {
    layerColumns[1].title = <div>
      <span>Thickness</span>
      {unitRender(units ? units.stackupUnit : "", "stackupUnit", settingUnitChange)}
    </div>;
    layerColumns[2].title = <div>
      <span>Line Width</span>
      {unitRender(units ? units.widthUnit : "", "widthUnit", settingUnitChange)}
    </div>;
    layerColumns[3].title = <div>
      <span>Length</span>
      {unitRender(units ? units.lengthUnit : "", "lengthUnit", settingUnitChange)}
    </div>;
  }
  layerColumns[3].render = (text) => {
    if (text) {
      return <span>{text.toFixed(1)}</span>
    }
  }
  layerColumns[4].render = (text) => {
    if (text) {
      return <span>{text.toFixed(1)}</span>
    }
  }
  return layerColumns
}

function getNetColumns(impedanceData, units, settingUnitChange) {
  const netColumns = JSON.parse(JSON.stringify(impColumnsByNet));
  if (impedanceData) {
    netColumns[5].title = <div>
      <span>Length</span>
      {unitRender(units ? units.lengthUnit : "", "lengthUnit", settingUnitChange)}
    </div>;
  }
  netColumns[0].render = (text) => {
    return <Tooltip placement="topLeft" content={text}><span>{text}</span></Tooltip>
  };
  netColumns[1].render = (text, record) => {
    return <span>{(record && record.signals && record.signals.length) ? record.signals.join(", ") : text}</span>
  };
  netColumns[2].render = (text) => {
    return <span>{text}</span>
  };
  netColumns[3].render = (text) => {
    if (text) {
      return <span>{text.toFixed(1)}</span>
    }
  };
  netColumns[4].render = (text) => {
    if (text) {
      return <span>{text.toFixed(1)}</span>
    }
  };
  netColumns[5].render = (text) => {
    if (text) {
      return <span>{text.toFixed(1)}</span>
    }
  };
  return netColumns
}

function getPreNetColumns(impedanceData, units = {}, settingUnitChange, isChild) {
  let netColumns = JSON.parse(JSON.stringify(isChild ? impChildColumnsByNet : impColumnsByNet));
  const findZdiff = (impedanceData || []).find(item => !!item.Zdiff);
  if (!findZdiff) {
    netColumns = netColumns.filter(item => item.dataIndex !== "Zdiff");
  }
  for (let column of netColumns) {

    if (column.dataIndex === "name") {
      column.render = (text, record) => {
        return <Tooltip placement="topLeft" content={record.net}><span>{record.net}</span></Tooltip>
      };
    }

    if (column.dataIndex === "layer") {
      column.title = <span>Trace</span>;

      column.render = (text, record) => {
        return <span>{record.trace}</span>
      };
    }

    if (["length", "width", "thickness"].includes(column.dataIndex)) {
      const title = column.dataIndex === "width" ? "Trace Width" : (column.dataIndex === "thickness" ? "Trace Thickness" : "Length");
      column.title = <div>
        <span>{title}</span>
        {unitRender(units ? units[column.dataIndex] : "", column.dataIndex, settingUnitChange)}
      </div>;

      column.render = (text, record) => {
        if (text) {
          return <span>{text.toFixed ? text.toFixed(1) : text}</span>
        }
      }
    }

    if (["minZ0", "maxZ0", "impedance", "Zdiff"].includes(column.dataIndex)) {
      column.render = (text, record) => {
        if (text) {
          return <span>{text.toFixed ? text.toFixed(1) : text}</span>
        }
      }
    }
  }
  return netColumns
}

const ImpedanceComp = (props) => {
  const { designList, selectedKey, impedanceData, handleChange, key, experimentName, units, settingUnitChange } = props;
  const LayerColumns = getLayerColumns(impedanceData, units, settingUnitChange);
  const NetColumns = getNetColumns(impedanceData, units, settingUnitChange);
  const layerData = impedanceData.layerStats || [];
  const netData = (impedanceData ? impedanceData.netStats || [] : []).map(item => {
    return {
      ...item,
      key: `${item.name}-${item.layerStats ? item.layerStats.length : 0}`,
      children: item.layerStats.map((i, index) => ({ ...i, key: `${item.name}-${i.layer}-child-${index}` })),
      layer: item.layerStats ? item.layerStats.length : 0
    }
  })

  return (
    <div className='sierra-result-content-box' key={key}>
      {experimentName && <Fragment>
        <label className='font-bold' style={{ marginRight: 14 }}>Name:</label>
        <label style={{ marginRight: 30 }}>{experimentName}</label>
      </Fragment>}
      <label className='font-bold' style={{ marginRight: 14 }}>PCB:</label>
      <Select value={selectedKey} style={{ marginBottom: 10, minWidth: 100 }} onChange={(value) => handleChange(value, key)}>
        {designList.map((item, i) => (
          <Option key={i} value={i}>{item.name}</Option>
        ))}
      </Select>
      <Tabs
        defaultActiveKey="VbL"
        className="impedence"
        items={
          [
            {
              key: "VbL",
              label: "View by Layer",
              children: <Table
                columns={LayerColumns}
                dataSource={layerData}
                pagination={false}
                scroll={impedanceData && impedanceData.layerStats && impedanceData.layerStats.length > 10 ? { y: 400 } : {}}
                size="small"
                rowKey={record => record.layer}
              />
            },
            {
              key: "VbN",
              label: "View by Net",
              children: <Table
                columns={NetColumns}
                dataSource={netData}
                pagination={false}
                scroll={impedanceData && impedanceData.netStats && impedanceData.netStats.length > 10 ? { y: 400 } : {}}
                size="small"
                expandable={{
                  expandedRowClassName: () => 'sierra-impedance-expand-row'
                }}
                rowKey={record => record.key}
              />
            }
          ]
        }
      />
    </div>
  );
}

const PreLayoutImpedanceComp = (props) => {
  const { designList, selectedKey, impedanceData, handleChange, key, experimentName, units, settingUnitChange } = props;
  const NetColumns = getPreNetColumns([], units, settingUnitChange);

  return (
    <div className='sierra-result-content-box' key={key}>
      {experimentName && <Fragment>
        <label className='font-bold' style={{ marginRight: 14 }}>Name:</label>
        <label style={{ marginRight: 30 }}>{experimentName}</label>
      </Fragment>}
      <label className='font-bold' style={{ marginRight: 14 }}>PCB:</label>
      <Select value={selectedKey} style={{ marginBottom: 10, minWidth: 100 }} onChange={(value) => handleChange(value, key)}>
        {designList.map((item, i) => (
          <Option key={i} value={i}>{item.name}</Option>
        ))}
      </Select>
      <Table
        columns={NetColumns}
        dataSource={impedanceData ? impedanceData.preLayoutImpData || [] : []}
        pagination={false}
        scroll={impedanceData && impedanceData.netStats && impedanceData.netStats.length > 10 ? { y: 400 } : {}}
        size="small"
        expandable={{
          expandedRowRender: (record) => {
            const NetChildColumns = getPreNetColumns(record.traceList || [], units, settingUnitChange, true);
            return expandedRowRender(record, NetChildColumns)
          }
        }}
        rowKey={record => record.key}
      />
    </div>
  );
}

function expandedRowRender(record, NetChildColumns) {
  return <Table
    columns={NetChildColumns}
    dataSource={record.traceList || []}
    pagination={false}
    size="small"
    rowKey={(record) => record.key}
    rowClassName={() => 'sierra-impedance-expand-row'}
  />
}

export default ImpedanceComp;
export {
  PreLayoutImpedanceComp
}