import React, { PureComponent, Fragment, } from 'react';
import './index.css';

class ErrorCheck extends PureComponent {

  render() {
    const { errorCheck } = this.props;
    return (
      <Fragment>
        {errorCheck &&
          <Fragment>
            <pre className="sierra-warning-dialog">
              {errorCheck && errorCheck.error && errorCheck.error.length > 0 && errorCheck.error.map((item, index) => {
                return <Fragment key={index}>{item}</Fragment>
              })}
            </pre>
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default ErrorCheck;