import _ from 'lodash';


function getNetsData(designData, signals, components) {
  const newData = _.cloneDeep(designData);
  const signalNets = signals.map(item => item.nets).flat(2);

  // filter mNetManager
  const netObj = netManagerHandle(newData, signalNets);
  if (netObj.mNetManager) {
    newData.mNetManager = netObj.mNetManager;
  }
  const { nets, comps } = netObj;

  // filter mLayerMgr
  const pinList = components.map(item => { return item.pins.map(it => { return { ...it, name: item.name, part: item.part } }) }).flat(2);
  const layerObj = mLayerMgrHandle(newData, components, pinList, signalNets);
  const { partList, layerList, compPartObj } = layerObj;
  newData.mLayerMgr = layerObj.mLayerMgr;
  // filter mPartMgr
  newData.mPartMgr = mPartMgrHandle({ data: newData, pinList, partList, compPartObj });

  return { data: newData, layerList, nets, comps, pinList };
}

function netManagerHandle(data, signalNets) {
  const mNetManager = _.cloneDeep(data.mNetManager);
  const indexList = [], nets = {}, comps = {};
  if (mNetManager && mNetManager.mNetList) {
    mNetManager.mNetList.mKeys.forEach((item, index) => {
      if ([...signalNets].includes(item)) {
        indexList.push(index);
      }
    })
    mNetManager.mNetList.mKeys = mNetManager.mNetList.mKeys.filter((key, index) => indexList.includes(index));
    mNetManager.mNetList.mVals.forEach(item => {
      if (signalNets.includes(item.mName)) {
        nets[item.mName] = item;
      }
    })
    const mVals = mNetManager.mNetList.mVals.filter((key, index) => indexList.includes(index));
    mNetManager.mNetList.mVals = mVals;
    return { mNetManager, nets, comps }
  }
  return { mNetManager: null, nets: {}, comps: {} }
}

function mLayerMgrHandle(designData, components, pinList, signalNets) {
  const newMetalLayers = [], layerList = [], partList = [];
  const CompList = components.map(item => item.name);
  const mLayerMgr = _.cloneDeep(designData.mLayerMgr);
  let compPartObj = {};
  for (let layer of mLayerMgr.mMetalLayers) {
    const mComponentLayer = layer.mComponentLayer;
    const mNetGeomList = layer.mNetGeomList;
    if (mComponentLayer && mComponentLayer.mComponents) {
      const mComponents = mComponentLayer.mComponents.filter(item => CompList.includes(item.mName));
      mComponents.forEach(item => {
        // item.mPinsLocationList = item.mPinsLocationList.filter(pin => !!pinList.find(pinItem => pinItem.name === item.mName && pinItem.pin === pin.pinNumber))
        partList.push(item.mPart.mInfo.mPartName);
        // item.mPart.mPinList = item.mPart.mPinList.filter(pin => !!pinList.find(pinItem => pinItem.name === item.mName && pinItem.pin === pin.mNumber))
        compPartObj[item.mName] = item.mPart.mInfo.mPartName;
      })
      mComponentLayer.mComponents = mComponents
    }
    let indexList = [];
    if (mNetGeomList && mNetGeomList.mKeys) {
      mNetGeomList.mKeys.forEach((item, index) => {
        if (signalNets.includes(item)) {
          indexList.push(index);
        }
      })
      mNetGeomList.mKeys = mNetGeomList.mKeys.filter((item, index) => indexList.includes(index));
      mNetGeomList.mVals = mNetGeomList.mVals.filter((item, index) => indexList.includes(index));
    }

    if ((mComponentLayer && mComponentLayer.mComponents && mComponentLayer.mComponents.length) || indexList.length) {
      mComponentLayer && mComponentLayer.mName && layerList.push(mComponentLayer.mName);
      layerList.push(layer.mName);
      newMetalLayers.push(layer);
    }
  }
  mLayerMgr.mMetalLayers = newMetalLayers;

  const newNameMap = _.cloneDeep(mLayerMgr.mLayerIDNameMap);
  let indexList = [];
  mLayerMgr.mLayerIDNameMap.mVals.forEach((item, index) => {
    if (layerList.includes(item)) {
      indexList.push(index);
    }
  })
  newNameMap.mVals = mLayerMgr.mLayerIDNameMap.mVals.filter((item, index) => indexList.includes(index));
  newNameMap.mKeys = mLayerMgr.mLayerIDNameMap.mKeys.filter((item, index) => indexList.includes(index));
  mLayerMgr.mLayerIDNameMap = newNameMap;
  return { mLayerMgr, layerList, partList, compPartObj }
}

function mPartMgrHandle({ data, pinList, partList, compPartObj }) {
  const mPartMgr = _.cloneDeep(data.mPartMgr);
  const newPartList = [], newFootList = [], mFutprtList = [], partObj = {};
  mPartMgr.mPartList.forEach(item => {
    if (partList.includes(item.mInfo.mPartName)) {
      // item.mPinList = item.mPinList.filter(pin => !!pinList.find(pinItem => item.mInfo.mPartName === compPartObj[pinItem.name] && pinItem.pin === pin.mNumber));
      mFutprtList.push(...item.mFutprtList.mValues)
      partObj[item.mInfo.mPartName] = [...item.mFutprtList.mValues];
      newPartList.push(item);
    }
  })
  mPartMgr.mPartList = newPartList;
  mPartMgr.mFootprintList.forEach(item => {
    if (mFutprtList.includes(item.mID)) {
      const mMetalLayerList = item.mMetalLayerList;
      mMetalLayerList.forEach(m => {
        //  m.mPadList = m.mPadList.filter(pad => !!pinList.find(pin => partObj[compPartObj[pin.name]] && partObj[compPartObj[pin.name]].includes(item.mID) && pin.pin === pad.mPadID));
      })
      item.mMetalLayerList = mMetalLayerList;
      newFootList.push(item)
    }
  })
  mPartMgr.mFootprintList = newFootList;
  return mPartMgr
}

export {
  getNetsData
}