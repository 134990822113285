/* tree */
export const LIBRARY = "library",
  /* system library */
  SYS_LIBRARY = "system_library",
  CUSTOM_LIBRARY = "Custom",
  CUSTOM_LIBRARY_FOLDER = "custom_library_folder",
  CUSTOM_LIBRARY_FILE = "custom_library_file",
  CUSTOM_LIBRARY_DATA = "custom_library_data",
  SYS_DECAP = "system_decap",
  SYS_LIBRARY_FOLDER = "system_library_folder",
  SYS_LIBRARY_FILE = "system_library_file",
  /* library */
  MY_LIBRARY = "my_library",
  LIBRARYS = "librarys",
  LIBRARY_DATA = "library_data",
  LIBRARY_FILE = "library_file",
  LIBRARY_OPEN = 'library_open',
  /* trash */
  TRASH = "trash",
  MY_TRASH = "my_trash",
  /* project */
  PROJECTS = "projects",
  PROJECT = "project",
  PROJECT_CREATE = "project_create",
  PCBS = "PCBs",
  PCB = "PCB",
  VERIFICATIONS = "verifications",
  VERIFICATION = "verification",
  CHANNEL = "channel",
  RESULT = "result",
  PCB_PRE_LAYOUT = "PCB_pre_layout",
  PCB_CHANNEL = "PCB_channel",
  PCB_CHANNEL_RESULT = 'PCB_channel_result',
  END_TO_END_CHANNELS = "end_to_end_channels",
  END_TO_END_CHANNEL = "end_to_end_channel",
  END_TO_END_CHANNEL_RESULT = "end_to_end_channel_result",
  ROCKY_CHANNEL = "rocky_channel",
  VERIFICATION_CREATE = 'Verification_create',
  LIBRARY_FOLDER_FILE = 'library_folder_file',
  LIBRARY_FOLDER = 'library_folder',
  LIBRARY_FOLDER_CREATE = 'library_folder_create',
  COPYING_PROJECT = 'copying_project',
  PDNS = 'PDNs',
  PDN = 'PDN',
  CASCADE_PCBS = 'cascade_PCBs',
  CASCADE_PCB = 'cascade_PCB',
  CASCADE_PDNS = 'cascade_PDNs',
  CASCADE_PDN = 'cascade_PDN',
  DCR = 'DCR',
  PATH_R = "path_r",
  ADD_PCB_CHANNEL = "add_pcb_channel",
  EXPERIMENTS = 'experiments',
  EXPERIMENTS_CREATE = 'experiments_create',
  EXPERIMENTS_RESULT = 'experiments_result',
  PACKAGE = 'package',
  PACKAGE_VERIFICATION = 'package_verification',
  PACKAGE_VERIFICATION_CREATE = 'package_verification_create',
  PACKAGE_CHANNEL_CREATE = 'package_channel_create',
  PACKAGE_RESULT = 'package_result',
  LIB_PKG = 'library_package',
  PACKAGES = 'packages',
  IR_EXPLORER = 'ir_explorer',
  DEVICE = 'device',
  IR_EXPLORER_RESULT = 'ir_explorer_result',
  IMPEDANCE = 'impedance_explorer',
  IMPEDANCE_RESULT = 'impedance_explorer_result',
  ADD_INTERFACE = 'add_interface_identification',
  POWER_TREE = 'power_tree', // Multi PCB Power Tree
  SIGN_OFF_TEMPLATES = "sign_off_templates",
  SIGN_OFF_TEMPLATE = "sign_off_template",
  TREE_SIGN_OFF_TEMPLATE = "tree-sign_off_template",
  DESIGN_TREE = 'design_tree', // Full PCB Power Tree
  SINGLE_TREE = 'single_tree', // Single PCB Power Tree
  CASCADE_IMPEDANCE = "Cascade_Impedance",
  CASCADE_SIGN_OFF = "Cascade_Sign_Off",
  CARD = "card",
  CARD_VERIFICATION = "card_verification",
  CARD_RESULT = "card_result",
  SPICE_CARD_TEMPLATES = "Spice_card_Templates",
  SPICE_CARD_TEMPLATE = "Spice_card_Template",
  IO_SIMS = 'IO_SIMs',
  IO_SIM = "IO_SIM",
  IO_SIM_GROUPS = "IO_SIM_GROUPS",
  MULTI_INTERFACE_SETUP = "multi_interface_setup",
  VERIFICATION_GROUP = "verification_group",
  SSN_RESULT = "ssn_result",
  SSN_VERIFICATION = "ssn_verification",
  PACKAGE_PDN = "package_PDN",
  PACKAGE_PDN_RESULT = "package_PDN_result",
  MULTIPLE_RESULT = "multiple_result",
  MULTIPLE_CHANNEL = "multiple_channel",
  HIMALAYAS_WRITE = "WRITE",
  HIMALAYAS_READ = "READ",
  PACKAGE_PRE_LAYOUT = "package_pre_layout",
  PCB_PDN = "PCB_PDN",
  PCB_PDN_RESULT = "PCB_PDN_RESULT",
  CASCADE_PACKAGES = "Cascade_packages",
  AC = "AC",
  DC = "DC",
  BMA_CHANNEL = "BMA_channel",
  BMA_CHANNEL_RESULT = "BMA_channel_result",
  PCB_PRE_LAYOUT_RESULT = "PCB_pre_layout_result",
  PACKAGE_PRE_LAYOUT_RESULT = "Package_pre_layout_result";

/* ICON */
export const ADD = "add",
  DELETE = "delete",
  CLEAN = "clean",
  VERIFY = "verify",
  SIMULATE = "simulate",
  STATUS = "status",
  UPLOAD_PCB = "upload_pcb",
  REPLACE_PCB = "replace_pcb",
  RENAME = "rename",
  COPY = "copy",
  DOWNLOAD = 'download',
  LOADING = 'loading',
  MACRO_MODELING = 'macro_modeling',
  DEFAULT = 'default',
  PCB_SETTING = 'pcb_setting',
  UPLOAD = "upload",
  IMPORTEXPORT = 'import_export',
  UPDATE = "update",
  SAVE = "save",
  EDIT = "edit",
  ADD_VDE = "add_vde",
  ADD_PROJECT = "add_project",
  LAYOUT_CHECKOUT = "layout_checkout",
  SEARCH = "search",
  TAG = 'tag',
  MORE = 'more',
  COMBINE_INTERFACE = 'combine_interface',
  FILE_SEARCH = 'file-search'
