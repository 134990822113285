import CeGeomTools from './CeGeomTools';
import CeVertex from './CeVertex';

const GCPolygon = function () {
  this.mClosed = true;
  this.mNumPnts = 0;
  this.mXs = [];
  this.mYs = [];
}

GCPolygon.prototype.CopyFrom = function (fromObj) {
  this.mClosed = fromObj.mClosed;
  this.mNumPnts = fromObj.mNumPnts;
  this.mXs = [];
  this.mYs = [];
  for (var i = 0; i < fromObj.mNumPnts; i++) {
    this.mXs.push(fromObj.mXs[i]);
    this.mYs.push(fromObj.mYs[i]);
  }
}

GCPolygon.prototype.Clone = function () {
  var clnObj = new GCPolygon();
  clnObj.CopyFrom(this);
  return clnObj;
}

GCPolygon.prototype.SetData = function (numPnts, Xs, Ys, closed) {
  if (closed == null || closed == undefined)
    closed = true;
  this.mClosed = closed;
  this.mNumPnts = numPnts;
  this.mXs = Xs;
  this.mYs = Ys;
}

GCPolygon.prototype.GetNumPoints = function () {
  return this.mNumPnts;
}

GCPolygon.prototype.GetSvgPath = function (csConvertor, reverseDir) {
  var svgPath = "";

  if (reverseDir != undefined && reverseDir != null && reverseDir == true) {
    for (var i = this.mNumPnts - 1; i >= 0; i--) {
      var pnt;
      if (csConvertor != null)
        pnt = csConvertor.PhysicalToScreenPoint(this.mXs[i], this.mYs[i]);
      else {
        pnt = new CeVertex();
        pnt.SetData(this.mXs[i], this.mYs[i]);
      }
      if (i == this.mNumPnts - 1)
        svgPath = 'M ' + pnt.mX + " " + pnt.mY;
      else
        svgPath += ' L ' + pnt.mX + " " + pnt.mY;
    }
  } else {
    for (var i = 0; i < this.mNumPnts; i++) {
      var pnt;
      if (csConvertor != null)
        pnt = csConvertor.PhysicalToScreenPoint(this.mXs[i], this.mYs[i]);
      else {
        pnt = new CeVertex();
        pnt.SetData(this.mXs[i], this.mYs[i]);
      }
      if (i == 0)
        svgPath = 'M ' + pnt.mX + " " + pnt.mY;
      else
        svgPath += ' L ' + pnt.mX + " " + pnt.mY;
    }
  }

  svgPath += " Z";

  return svgPath;
}

GCPolygon.prototype.GetSvgPolygon = function (csConvertor) {
  var svgPolygon = "";

  for (var i = 0; i < this.mNumPnts; i++) {
    var pnt;
    if (csConvertor) {
      pnt = csConvertor.PhysicalToScreenPoint(this.mXs[i], this.mYs[i]);
    } else {
      pnt = new CeVertex();
      pnt.SetData(this.mXs[i], this.mYs[i]);
    }
    svgPolygon += pnt.mX + ", " + pnt.mY;
    if (i < this.mNumPnts - 1)
      svgPolygon += " ";
  }

  return svgPolygon;
}

GCPolygon.prototype.Transform = function (celocation) {
  var radian = celocation.mRotAngle * Math.PI / 180.0;
  if (celocation.IsClockwise() && CeGeomTools.TwoNumberEqual(radian, 0.0) == false)
    radian = 2.0 * Math.PI - radian;

  if (CeGeomTools.TwoNumberEqual(radian, 0.0) == true) {
    for (var i = 0; i < this.mNumPnts; i++) {
      this.mXs[i] += celocation.mPosition.mX;
      this.mYs[i] += celocation.mPosition.mY;
    }
    return;
  }
  var cosAngle = Math.cos(radian);
  var sinAngle = Math.sin(radian);
  var x, y;
  for (var i = 0; i < this.mNumPnts; i++) {
    x = this.mXs[i];
    y = this.mYs[i];
    this.mXs[i] = cosAngle * x - sinAngle * y + celocation.mPosition.mX;
    this.mYs[i] = sinAngle * x + cosAngle * y + celocation.mPosition.mY;
  }
}

export default GCPolygon;