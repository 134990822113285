import { getPDNResultFile, getPDNFile } from '../api/pdn/PDNAPICtrl';
import { getUsage } from '@/services/helper/setup/setupData'
import { getOptCfg } from './optimization';

class HistoryData {

  constructor(verificationId) {
    this.verificationId = verificationId;
    this.historyInfo = {};
  }

  getContent = ({ projectId, pdnId, time, pdnName }) => {
    return new Promise((resolve, reject) => {
      const content = this.existInfo(time, 'info');
      if (content) {
        resolve(content);
      } else {
        if (time === 'current') {
          getPDNFile(projectId, 'PDN', `${pdnName}/verification.json`).then(res => {
            if (res.data) {
              let Components = this.getComponents(res.data.Components);
              let VRM = this.getVRM(res.data.VRM);
              const info = { ...res.data, Components, VRM };
              if (!this.historyInfo[time]) {
                this.historyInfo[time] = {}
              }
              this.historyInfo[time].info = info;
              resolve(info);
            } else {
              resolve(null);
            }
          }, error => {
            resolve(null);
          })
        } else {
          getPDNResultFile(projectId, pdnId, `history/${time}/verification.json`).then(res => {
            if (res.data) {
              let Components = this.getComponents(res.data.Components);
              let VRM = this.getVRM(res.data.VRM);
              const info = { ...res.data, Components, VRM };
              if (!this.historyInfo[time]) {
                this.historyInfo[time] = {}
              }
              this.historyInfo[time].info = info;
              resolve(info);
            } else {
              resolve(null);
            }
          }, error => {
            resolve(null);
          })
        }
      };
    })
  }

  getOptCfg = ({ projectId, pdnId, time }) => {
    return new Promise((resolve, reject) => {
      const content = this.existInfo(time, 'optCfg');
      if (content) {
        resolve(content);
      } else {
        getOptCfg({ projectId, pdnId, time }).then(res => {
          if (res) {
            if (!this.historyInfo[time]) {
              this.historyInfo[time] = {}
            }
            this.historyInfo[time].optCfg = res;
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          resolve(null);
        })
      }
    })
  }

  existInfo = (time, type) => {
    if (this.historyInfo[time] && this.historyInfo[time][type]) {
      return this.historyInfo[time][type];
    } else {
      return null
    }
  }

  removeContent = (time) => {
    delete this.historyInfo[time];
  }

  getComponents = (Components) => {
    let _partComps = [], // [{part, comps, value, usage}]
      partList = [];
    /* const { DecapNames } = this.props; */
    const _SortComponents = Components.sort(function (a, b) {
      if (a.name.length === b.name.length) {
        return a.name.localeCompare(b.name);
      } else {
        return a.name.length - b.name.length
      }
    });
    _SortComponents.forEach((comp) => {
      const { part, usage, name, pins } = comp;
      if (usage === 'Cap') {
        if (partList.includes(part)) {
          const _findIndex = _partComps.findIndex(item => item.part === part);
          _partComps[_findIndex].comps.push({ name, pins, usage });
          _partComps[_findIndex].selectComps.push(name);
        } else {
          partList.push(part);
          _partComps.push({
            part,
            comps: [{ name, pins, usage }],
            usage,
            value: comp.value || {},
            model: comp.model || {},
            models: comp.models,
            selectComps: [name],
            unusedComps: [],
            modelName: null
          });
        }
      } else if (usage === 'Unused') {
        let type = getUsage(name);
        if (type === 'Cap') {
          if (partList.includes(part)) {
            const _findIndex = _partComps.findIndex(item => item.part === part);
            _partComps[_findIndex].comps.push({ name, pins, usage });
            _partComps[_findIndex].unusedComps.push(name);
          } else {
            partList.push(part);
            _partComps.push({
              part,
              comps: [{ name, pins, usage }],
              usage: 'Cap',
              value: comp.value || {},
              model: comp.model || {},
              models: comp.models,
              unusedComps: [name],
              selectComps: []
            });
          }
        }
      }
    });
    const _Filter = _partComps.filter(item => item.selectComps.length > 0);
    return _Filter;
  }

  getVRM = (vrm) => {
    if (Array.isArray(vrm)) {
      let data = [];
      vrm.forEach((item, index) => {
        const powerPin = item.powerPin ? item.powerPin.map(pin => pin.comp) : [];
        const groundPin = item.groundPin ? item.groundPin.map(pin => pin.comp) : [];
        const itemData = {
          powerPin: powerPin,
          groundPin: groundPin,
          vrm: item.model ? item.model.name : '',
          index: index + 1
        };
        data.push(itemData);
      });
      return data;
    } else {
      const powerPin = vrm.powerPin ? vrm.powerPin.map(item => item.comp) : [];
      const groundPin = vrm.groundPin ? vrm.groundPin.map(item => item.comp) : [];
      const data = [{
        powerPin: powerPin,
        groundPin: groundPin,
        vrm: vrm.model ? vrm.model.name : ''
      }];
      return data;
    }
  }

  getVRMModel = ({ projectId, pdnId, time }) => {
    return new Promise((resolve, reject) => {
      const content = this.existInfo(time, 'vrmTxt');
      if (content) {
        resolve(content);
      } else {
        if (projectId && pdnId) {
          let path = 'VRM.txt';
          if (time && time !== 'current') {
            path = `history/${time}/VRM.txt`;
          }
          getPDNResultFile(projectId, pdnId, path).then(res => {
            if (res.data) {
              if (!this.historyInfo[time]) {
                this.historyInfo[time] = {};
              }
              this.historyInfo[time].vrmTxt = res.data;
              resolve(res.data);
            } else {
              resolve(null);
            }
          }, error => {
            resolve(null);
          })
        } else {
          resolve(null);
        }
      }
    })
  }

  getVRMOpt = ({ projectId, pdnId, time }) => {
    return new Promise((resolve, reject) => {
      const content = this.existInfo(time, 'optVrmTxt');
      if (content) {
        resolve(content);
      } else {
        if (projectId && pdnId) {
          let path = 'VRM_opt.txt';
          if (time && time !== 'current') {
            path = `history/${time}/VRM_opt.txt`;
          }
          getPDNResultFile(projectId, pdnId, path).then(res => {
            if (res.data) {
              if (!this.historyInfo[time]) {
                this.historyInfo[time] = {};
              }
              this.historyInfo[time].optVrmTxt = res.data;
              resolve(res.data);
            } else {
              resolve(null);
            }
          }, error => {
            resolve(null);
          })
        } else {
          resolve(null);
        }
      }
    })
  }
}

export default HistoryData;