import {
  UPDATE_SIMULATION_INFO,
  INIT_SIMULATION_INFO,
  GET_WORKFLOW_MONITOR,
  CANCEL_WORKFLOW,
  GET_SIMULATION_LOG,
  GET_VERIFICATION_WORKFLOW
} from "./actionTypes";

export const updateSimulationInfo = (verificationId, data) => ({
  type: UPDATE_SIMULATION_INFO,
  verificationId,
  data
});

export const initSimulationInfo = (verificationId) => ({
  type: INIT_SIMULATION_INFO,
  verificationId
});

export const getWorkflowMonitor = (workflowId, verificationId) => ({
  type: GET_WORKFLOW_MONITOR,
  workflowId,
  verificationId,
});

export const cancelWorkflow = (verificationId) => ({
  type: CANCEL_WORKFLOW,
  verificationId
});

export const getVerificationLog = (verificationId) => ({
  type: GET_SIMULATION_LOG,
  verificationId
});

export const getVerificationWorkflow = ({ channelId, verificationId, workType }) => ({
  type: GET_VERIFICATION_WORKFLOW,
  workType,
  channelId,
  verificationId
})