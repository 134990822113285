import { parseSpiceModelSelector } from "../../LibraryHelper/SpiceModelHelper";

class SpiceModelHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getSpiceFiles() {
    return Object.keys(this);
  }

  deleteModel(id) {
    delete this[id];
  }

  parseModelSelector(id, fileContent) {
    this[id] = parseSpiceModelSelector(fileContent);
  }

  /*get model list by filename and type(Rx, Tx)*/
  getSpiceModelList(id, type) {
    const models = this[id];
    return models
  }
}

export default SpiceModelHelper;