import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import React, { Component, Fragment } from 'react';
import {
  getDisplayPinList,
  addConnectorPinsByComp,
  deleteConnectorComp,
  getConnSelectComps,
  ConnectorPin,
  getConnectorModels,
  getModelListLength,
  getConnectorModelsByType,
  deleteUnUsedCableModels,
  updateConnectorAllPinsPort,
  getConnSignalType,
  getAutoMatchFile,
  autoMatchPort
} from '@/services/helper/connectorHelper';
import PinPortRender from '@/components/PinPortRender_v1';
import { SPICE } from '@/constants/libraryConstants';
import { SIERRA } from '@/constants/pageType';
import ConnectorModelSelect from '../../../ConnectorModelSelect';
import CircleTag from '../../../CircleTag';
import AutomaticPanel from '../../../PortMatch';
import './index.css';
import { TOUCHSTONE } from '../../../../constants/libraryConstants';
import { getDefaultIndex } from '../../../../services/helper/setDefaultName';

const { Option } = Select;
class ModelAssign extends Component {

  constructor(props) {
    super(props);
    this.state = {
      top: null
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.updateModelFileHeight();
  }

  componentDidUpdate = (prevProps) => {
    const { connector1, connector2, cableModels } = this.props;
    const connector1Models = connector1.models ? connector1.models : [];
    const connector2Models = connector2.models ? connector2.models : [];
    const { connector1: prevConn1, connector2: prevConn2, cableModels: prevCableModels } = prevProps;
    const prev1Models = prevConn1.models ? prevConn1.models : [];
    const prev2Models = prevConn2.models ? prevConn2.models : [];

    if (prev1Models.length !== connector1Models.length
      || prev2Models.length !== connector2Models.length
      || prevCableModels.length !== cableModels.length) {
      this.updateModelFileHeight();
    }
  }

  selectComp = (key, type, anotherType) => {
    if (!key) {
      this.deleteConnectionComp(type, anotherType);
      return;
    }
    const newConnectorObj = addConnectorPinsByComp({
      key,
      type,
      anotherType,
      propsInfo: this.props,
      ConnectorPin: ConnectorPin
    })

    this.props.updateConnector(newConnectorObj);
  }

  deleteConnectionComp = (type, anotherType) => {
    const newConnectorObj = deleteConnectorComp(type, anotherType, this.props);
    newConnectorObj.cableModels = deleteUnUsedCableModels(newConnectorObj.connector1, newConnectorObj.connector2, this.props.cableModels);
    this.props.updateConnector(newConnectorObj);
  }

  getModelBoxTop = (connector1Models, connector2Models) => {
    const { cableModels = [] } = this.props;
    let connector1Length = getModelListLength(getConnectorModels(connector1Models), "left"),
      connector2Length = getModelListLength(getConnectorModels(connector2Models), "right"),
      cableLength = getModelListLength(getConnectorModels(cableModels), "cable");
    const maxLength = Math.max(connector1Length, connector2Length, cableLength) || 0;
    return (maxLength) * 34;
  }

  connectorSignalClick = ({ e, modelType, pinList, signalInfo, signal, signalType, type }) => {
    e && e.stopPropagation();
    let modelPanelInfo = {
      signal,
      signalInfo,
      type,
      signalType
    }
    if (modelType) {
      const signalItem = pinList && pinList[0] ? pinList[0] : null;
      if (!signalItem) {
        return;
      }
      const { _signalType, _type } = getConnSignalType(modelType);
      modelPanelInfo = {
        signal: signalItem[_signalType],
        signalInfo: signalItem,
        type: _type,
        signalType: _signalType
      }
    }
    this.setState({
      modelPanelInfo: {
        ...modelPanelInfo
      }
    })
  }

  clearModels = (e, type) => {
    e && e.stopPropagation();
    const { connector1, connector2, cableModels } = this.props;
    const { connector1: _connector1, connector2: _connector2, cableModels: _cableModels } = updateConnectorAllPinsPort({
      type,
      connector1,
      connector2,
      cableModels
    })
    this.props.updateConnector({
      connector1: _connector1,
      connector2: _connector2,
      cableModels: _cableModels
    });
  }

  closeModal = ({ connector1, connector2, cableModels }) => {
    this.setState({
      modelPanelInfo: null
    }, () => {
      this.updateModelFileHeight()
    });
    const info = cableModels ? {
      connector1,
      connector2,
      cableModels
    } : {
      connector1,
      connector2,
    }
    this.props.updateConnector(info);
  }

  automaticMatchPort = (fileInfo, type, save) => {
    if (fileInfo.libraryId && save) {
      const { automaticInfo: { connectorType } } = this.state;
      const { connector1, connector2, portsObj } = this.props;
      const oldConnector = connectorType === "connector1" ? connector1 : connector2;
      const portsList = portsObj[fileInfo.libraryId];

      const connectorModels = oldConnector.models;
      let modelFile = connectorModels.find(item => item.libraryId === fileInfo.libraryId);
      if (!modelFile) {
        const modelKeys = connectorModels.map(item => item.modelKey);
        const modelKey = getDefaultIndex(connectorModels.length, modelKeys);
        modelFile = {
          file: fileInfo.fileName,
          libraryId: fileInfo.libraryId,
          type: TOUCHSTONE,
          modelKey,
          subckt: ""
        };
      }

      if (type !== 'manual' && portsList && portsList.length > 0) {
        const newConnector = autoMatchPort({ oldConnector, portsList, matchType: type, modelFile, isMatchPin: true });
        this.closeModal({
          connector1: connector1,
          connector2: connector2,
          [connectorType]: {
            ...newConnector,
            models: [modelFile]
          }
        });
      }
    }

    this.setState({ automaticInfo: null });
  }

  render() {
    const {
      connector1, connector2,
      channel1Comps, channel2Comps,
      maxWidth, maxHeight, cableModels,
      portsObj, signal_connections_map,
      connectorFileList, cableFileList,
      pcbConnections, connections, CONNECTION_ID,
      channel1Info, channel2Info,
      product, connectorCompList = {}, serdesType } = this.props;
    const { top, modelPanelInfo, automaticInfo } = this.state;

    const connector1Models = getConnectorModels(connector1.models ? connector1.models : [], connectorFileList);
    const connector2Models = getConnectorModels(connector2.models ? connector2.models : [], connectorFileList);
    const maxPinsLength = connector1.pins.length > connector2.pins.length ? connector1.pins.length : connector2.pins.length;
    const channelHeight = maxPinsLength * 66 + top + 40;
    const channel_signal = product === SIERRA ? false : true;
    const { leftCompList, rightCompList } = connectorCompList;

    const _cableModels = getConnectorModels(cableModels, cableFileList);

    const pinList = getDisplayPinList({
      connector1,
      channel1Comps,
      connector2,
      channel2Comps,
      signal_connections_map,
      channel_signal,
      channel1Info,
      channel2Info,
      connectorFileList: [...cableFileList, ...connectorFileList]
    });
    return (
      <div className='connection-v1-model-assign-content'>
        {modelPanelInfo && modelPanelInfo.type ?
          <ConnectorModelSelect
            modelInfo={modelPanelInfo}
            portType={modelPanelInfo.type}
            signalType={modelPanelInfo.signalType}
            signal={modelPanelInfo.signal}
            fileList={modelPanelInfo.type === "cable" ? cableFileList : connectorFileList}
            connectorModels={getConnectorModelsByType(modelPanelInfo.type, { connector1Models, connector2Models, cableModels })}
            closeModal={this.closeModal}
            getFileParse={this.props.getFileParse}
            portsObj={portsObj}
            connector1={connector1}
            connector2={connector2}
            channel1Comps={channel1Comps}
            channel2Comps={channel2Comps}
            channel1Info={channel1Info}
            channel2Info={channel2Info}
            signal_connections_map={signal_connections_map}
            channel_signal={channel_signal}
            serdesType={serdesType}
          />
          : null
        }
        <div className='connection-v1-left-channel' style={{ height: channelHeight }}>
          <div id="connector-left-models">
            {connector1Models.length > 0 ? this.getModelList({
              models: connector1Models,
              type: "left",
              anotherType: "right",
              fileList: connectorFileList,
              title: "Connector Model",
              pinList
            }) : null}
          </div>
          <div className="connection-v1-model-component-select-content" style={{ top: top + 6 }}>
            <span>Connector</span>
            {this.getCompSelect({
              value: connector1.component,
              selectChange: this.selectComp,
              type: "left",
              anotherType: "right",
              list: leftCompList ? leftCompList.map(comp => ({ name: comp.name })) : getConnSelectComps({ channel1Comps, pcbConnections, connections, CONNECTION_ID }),
              selectType: "component"
            })}
          </div>
          {/* auto  */}
          <div className='connection-v1-model-auto-match-icon' style={{ top: top + 6 + 40 }}>
            <span>Connector</span>
            <span
              title="Ports Match"
              className='iconfont icon-xinpian_chip'
              onClick={() => this.setState({ automaticInfo: { modelType: "Connector", modelFileKey: "Connector", connectorType: "connector1" } })}>
            </span>
          </div>
        </div>
        <div
          className={cableModels && cableModels.length ? "connection-v1-cable-model-content" : "connection-v1-cable-model-content connection-v1-cable-model-none-content"}>
          <div id="connector-cable-models">
            {cableModels.length ? this.getModelList({
              models: _cableModels,
              type: "cable",
              anotherType: "",
              fileList: cableFileList,
              title: "Cable Model",
              pinList
            }) : null
            }
          </div>
        </div>
        <div className='connection-v1-right-channel' style={{ height: channelHeight }}>
          <div id="connector-right-models">
            {connector2Models.length ? this.getModelList({
              models: connector2Models,
              type: "right",
              anotherType: "left",
              fileList: connectorFileList,
              title: "Connector Model",
              pinList
            }) : null}
          </div>
          <div className="connection-v1-model-component-select-content" style={{ top: top + 6 }}>
            <span>Connector</span>
            {this.getCompSelect({
              value: connector2.component,
              selectChange: this.selectComp,
              type: "right",
              anotherType: "left",
              list: rightCompList ? rightCompList.map(comp => ({ name: comp.name })) : getConnSelectComps({ channel2Comps, pcbConnections, connections, CONNECTION_ID }),
              selectType: "component"
            })}
          </div>
          {/* auto  */}
          <div className='connection-v1-model-auto-match-icon' style={{ top: top + 6 + 40 }}>
            <span>Connector</span>
            <span
              title="Ports Match"
              className='iconfont icon-xinpian_chip'
              onClick={() => this.setState({ automaticInfo: { modelType: "Connector", modelFileKey: "Connector", connectorType: "connector2" } })}>
            </span>
          </div>
        </div>
        <div>
          <PinPortRender
            top={top ? top + 44 : 44}
            displayType={'model'}
            connector1={connector1}
            connector2={connector2}
            updateConnector={this.props.updateConnector}
            pinList={pinList}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            connector1Models={connector1Models}
            connector2Models={connector2Models}
            cableModels={_cableModels}
            portsObj={portsObj}
            product={product}
            connectorSignalClick={this.connectorSignalClick}
            connectorFileList={connectorFileList}
          />
        </div>
        {automaticInfo && automaticInfo.modelType ? <AutomaticPanel
          automaticInfo={automaticInfo}
          closePanel={this.automaticMatchPort}
          libraryListObj={{ [`${automaticInfo.modelType.toLowerCase()}List`]: connectorFileList }}
          getFileParse={this.props.getFileParse}
          modelType="Connector"
          files={getAutoMatchFile(automaticInfo.connectorType, { connector1Models, connector2Models })}
          allowManual={true}
        /> : null}
      </div>
    )
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  updateModelFileHeight() {
    let top = 0;
    const { connector1, connector2 } = this.props;
    const connector1Models = connector1.models ? connector1.models : [];
    const connector2Models = connector2.models ? connector2.models : [];
    top = this.getModelBoxTop(connector1Models, connector2Models)
    this.setState({
      top
    })
  }

  getModelSelect = ({
    index,
    title,
    value,
    list,
    type,
    anotherType,
    className,
    selectChange,
    fileExist,
    selectType,
    model,
    pinList
  }) => {
    const { portsObj } = this.props;
    return (
      <div className={`connection-v1-component-select-content ${!fileExist ? "connection-file-error" : ""}`}>
        {/* model file select  */}
        <div className="connection-v1-model-select-content">
          <span className="connection-v1-model-select-title">{index === 0 ? title : ""}</span>
          <div
            className="connection-v1-model-select-div"
            onClick={(e) => {
              this.connectorSignalClick({
                e,
                modelType: type,
                pinList
              })
            }}>
            {this.getCompSelect({
              value,
              selectChange,
              modelKey: model.modelKey,
              type,
              anotherType,
              className,
              list,
              libraryId: model.libraryId,
              selectType,
              disabled: true
            })}
          </div>
          {/* model circle tag */}
          {model.children && model.children.length && fileExist ? <CircleTag text={model.children.length} /> : null}
        </div>
        {/* model file exist check icon */}
        {
          !fileExist ? <Tooltip
            title={`File ${value} has been deleted, the model is invalid.`}
            overlayClassName='aurora-tooltip'
          ><QuestionCircleOutlined className='aurora-file-check-icon' onClick={(e) => this.spanClick(e)} />
          </Tooltip> : null
        }
        {model.type === SPICE &&
          <div className="connection-v1-model-select-subckt-div"
            onClick={(e) => {
              this.connectorSignalClick({
                e,
                modelType: type,
                pinList
              })
            }}
          >
            {this.getCompSelect({
              value: model.subckt,
              selectChange: this.selectSubckt,
              modelKey: model.modelKey,
              type,
              anotherType,
              list: portsObj[model.libraryId] ? portsObj[model.libraryId].map(subckt => ({ name: subckt.name })) : [],
              libraryId: model.libraryId,
              selectType: "subckt",
              disabled: true
            })}
          </div>}
        {index === 0 ? <Tooltip
          title={`Delete models`}
          overlayClassName='aurora-tooltip'
        ><CloseOutlined
            className='connector-model-delete-icon'
            onClick={(e) => this.clearModels(e, type, true)} />
        </Tooltip> : null}
      </div>
    );
  }

  getCompSelect = ({
    value,
    title,
    selectChange,
    modelKey,
    type,
    anotherType,
    className,
    list,
    disabled
  }) => {
    return <Select
      value={value}
      placeholder={title}
      allowClear
      showSearch
      disabled={disabled}
      onChange={selectChange ? (key) => selectChange(key, type, anotherType, modelKey) : null}
      popupMatchSelectWidth={false}
      className={`aurora-select connection-v1-select ${className}`}
      popupClassName="connector-select-dropdown-menu"
      getPopupContainer={() => document.getElementById('root')}
      size='small'
    >
      {list.map((item) =>
        <Option
          key={item.name}
          value={item.name}
          title={item.name}
        >{item.name}</Option>)}
    </Select>
  }

  getModelList({
    models,
    type,
    anotherType,
    fileList,
    className,
    title,
    pinList
  }) {
    return models.map((model, index) => (
      <Fragment key={index}>
        {this.getModelSelect({
          title,
          index,
          model,
          type,
          anotherType,
          selectType: "model",
          value: model.file,
          list: fileList,
          fileExist: model.isExist,
          className,
          pinList
        })}
      </Fragment>
    ))
  }
}

export default ModelAssign;