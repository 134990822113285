import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { InfoCircleOutlined, VerticalLeftOutlined } from '@ant-design/icons';
// import { cancelVerificationWorkflow, getInterfaceMonitor, getVerificationLog } from '../../store/simulation/action';
import { Progress, Col, Tooltip, Row, Select } from 'antd';
import { cancelWorkflow, getVerificationWorkflow } from '../../store/simulation/action';
import { getWaitingMsg } from '@/services/workflow/workflowHelper';
import { DCR, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE, IR_EXPLORER } from '../../../../constants/treeConstants';
import CascadeChannels from '../../../../services/Cascade/DB/cascadeChannels';
import { selectSignOffTask } from '../../store/SignOffTemplate/action';
import { SIGN_OFF_RUNNING, SIGN_OFF_UPDATE } from '../../../../constants/signOffTemplateStatus';
import { getMessageByStatus } from '../../../../services/workflow/workflowHelper';
import { getTextWidth } from '../../../../services/helper/getTextWidth';
import { getTemplatePowerTasks, getTemplatePathRTasks } from '../../../../services/Cascade/SignOffTemplate';
import { displayStackupError } from '../../../../components/Monitor/SimulationMonitor/MonitorErrorCheck';
import { DCR_TITLE, IMPEDANCE_TITLE, IR_TITLE, TREE_TITLE } from '../../../../services/Cascade/constants';

class SimulationMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topWidth: 666,
      lockScroll: true
    };
    this.lockRef = createRef();
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible } = this.props;
    if (tabVisible && this.scrollRef) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { topWidth, lockScroll } = this.state;
    const { tabVisible, log, startMsg } = this.props;
    const { log: prevlog, startMsg: prevstartMsg } = prevProps;
    if (tabVisible && this.scrollRef && !lockScroll) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
    if ((log !== prevlog) || (startMsg && startMsg !== prevstartMsg)) {
      this.hasLockScroll();
    }
  }

  cancelProgress = (e) => {
    this.props._cancelWorkflow(this.props.verificationId);
  }

  hasLockScroll = () => {
    if (this.lockRef) {
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5) { this.setState({ lockScroll: false }); }
      else { this.setState({ lockScroll: true }); }
    }
  }

  getVerificationTitle = (name) => {
    switch (name) {
      case IMPEDANCE:
        return IMPEDANCE_TITLE;
      case IR_EXPLORER:
        return IR_TITLE;
      case 'PathR':
        return DCR_TITLE;
      case POWER_TREE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        return TREE_TITLE;
      default: return name;
    }
  }

  selectSignOffTask = (key) => {
    let _key = key;
    if (key === "template") {
      _key = null;
    } else {
      this.props._getVerificationWorkflow(_key);
    }
    this.props._selectSignOffTaskId(_key);
  }

  render() {
    const { startMsg, endMsg, log, monitor, progress, projectName,
      waitingIndex, errorMsg, waitingTime, verificationName, warningMsg,
      updatePCBLog, updateTemplateLog, monitorType, signOffTaskList, signOffPathRList, signOffTaskId,
      signOffStatus, templateErrors, templatePowerErrorMap, taskMapping, pathRTaskMapping, stackupError,
      templateTaskErrorMap, templatePathTaskErrorMap } = this.props;
    const { topWidth, lockScroll } = this.state;
    const { scrollHeight, clientHeight } = this.lockRef;
    const waitingMsg = getWaitingMsg(waitingIndex);
    const verificationTitle = this.getVerificationTitle(verificationName);

    const signOffSelectWidth = monitorType === SIGN_OFF_TEMPLATE ? 200 : 26;
    const titleWidth = topWidth - 86;//86 is close and cancel icon width
    const textWidth = getTextWidth(projectName + '    ' + verificationTitle, 14) + signOffSelectWidth;
    const progressWaitMinWidth = waitingIndex >= 0 ? 95 : (progress > -1 ? 34 : 0);//95 is waiting msg min width, 34 is progress min width
    const progressWidth = titleWidth < (textWidth + progressWaitMinWidth) ? titleWidth - 44 : titleWidth - textWidth - 10; //44 is title tooltip icon width
    const signOffAllList = [...signOffPathRList, ...signOffTaskList]

    return (
      <Fragment>
        <div className='aurora-simulation-title cascade-simulation-title'
          id="aurora-simulation-title-id">
          <Row className='aurora-simulation-holygrail' span={24} style={{ width: topWidth - 36 }}>
            <div>
              {titleWidth < (textWidth + progressWaitMinWidth) ?
                <Tooltip
                  overlayClassName='icon-tooltip'
                  placement="topLeft"
                  title={
                    this.getTitle({
                      projectName,
                      verificationTitle,
                      monitorType,
                      signOffAllList,
                      signOffTaskId
                    })
                  }
                >
                  <div className='span-msg-icon-div'>
                    <InfoCircleOutlined className='span-msg-icon' />
                  </div>
                </Tooltip>
                :
                this.getTitle({
                  projectName,
                  verificationTitle,
                  monitorType,
                  signOffAllList,
                  signOffTaskId
                })
              }
            </div>
            {progress > -1 && waitingIndex < 0 ?
              <div className='aurora-simulation-middle'
                style={{ width: progressWidth }}
              >
                <Col className='aurora-simulation-progress' span={24}>
                  <Progress
                    type={progressWidth < 130 ? "circle" : "line"}
                    size={{ height: 14 }}
                    strokeColor={'#1890ff'}
                    percent={progress}
                    className="simulation-progress-bar"
                  />
                </Col>
              </div>
              :
              ((waitingIndex >= 0) &&
                <div className='aurora-simulation-middle'
                  style={{ width: progressWidth - 10 }}
                >
                  {progressWidth > 210 && <span className='simulation-waiting-title'> Simulation waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
                  {progressWidth <= 210 && progressWidth > 150 && <span className='simulation-waiting-title'>Waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
                  {progressWidth <= 150 && <span className='simulation-waiting-title'>Waiting: {waitingIndex}</span>}
                </div>)}
          </Row>
          {progress > -1 || waitingIndex >= 0 ?
            <div className='aurora-simulation-btn'>
              <div className='aurora-cancel-button'>
                <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
                  <span className='iconfont icon-cancel9' onClick={(e) => this.cancelProgress(e)}></span>
                </Tooltip>
              </div>
            </div>
            : null}
        </div>
        <div className="my-aurora-monitor aurora-simulation-monitor" onScroll={() => this.hasLockScroll()} ref={c => { this.lockRef = c; }}>
          <pre className="my-aurora-monitor-pre">
            {monitorType === SIGN_OFF_TEMPLATE && (!signOffTaskId || signOffTaskId === "template") ?
              <Fragment>
                {templateErrors && templateErrors.length ? templateErrors.map((item, i) =>
                  <div key={i}>
                    <span className="monitor-error-title">{item.title}</span>
                    <span className="monitor-error-msg">{item.error}</span>
                  </div>) : null}
                {this.templateErrorMapRender(templatePowerErrorMap)}
                {this.templateTaskErrorMapRender(templatePathTaskErrorMap, "Path-R")}
                {this.templateTaskErrorMapRender(templateTaskErrorMap, "Impedance")}
              </Fragment> : null}
            {stackupError && stackupError.length ? displayStackupError(stackupError) : null}
            {monitorType === SIGN_OFF_TEMPLATE && (!signOffTaskId || signOffTaskId === "template") && [SIGN_OFF_RUNNING, SIGN_OFF_UPDATE].includes(signOffStatus) ?
              this.templateRunningRender(taskMapping, pathRTaskMapping, signOffTaskList, signOffPathRList)
              : null}
            {updatePCBLog && updatePCBLog.length ? updatePCBLog.map((item, index) => { return <div key={index}>{item}</div> }) : null}
            {updateTemplateLog && updateTemplateLog.length ? updateTemplateLog.map((item, index) => { return <div key={index}>{item}</div> }) : null}
            {
              (errorMsg && errorMsg.length) ?
                errorMsg.map((item, index) => { return <div className="monitor-error-msg" key={index}>{item}</div> })
                : null
            }
            {
              (warningMsg && warningMsg.length) ?
                warningMsg.map((item, index) => { return <div className="monitor-warning-msg" key={index}>{item}</div> })
                : null
            }
            {startMsg}
            {log}
            {waitingIndex >= 0 ? <span className="debug-mes-span">{waitingMsg}</span> : null}
            {(monitor && monitor.length) ? monitor.map((item, index) => { return <div key={index}>{item.log}</div> }) : null}
            {endMsg}
          </pre >
          {(progress > -1) ? <div ref={(el) => { this.scrollRef = el; }}></div> : null
          }
        </div>
        {lockScroll && scrollHeight !== clientHeight ? <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'><VerticalLeftOutlined className="aurora-monitor-backbottom" onClick={this.scrollToBottom} /></Tooltip> : null}
      </Fragment >
    );
  }

  createTemplateMsg = (title, msgMap) => {
    return <Fragment>
      <span className="monitor-message-title font-bold">{title}:</span>
      {Array.from(msgMap.keys()).map((key, index) =>
        <div key={index}>
          <span className="monitor-message-title font-bold">{key}</span>
          {(msgMap.get(key) || []).map((item, i) => (
            getMessageByStatus(item.status) ?
              <div key={i} className="monitor-error-sub-content">
                <div className="cascade-message-title" key={item.id}>
                  <span>{item.name}</span> - {getMessageByStatus(item.status)}
                </div>
              </div>
              : null))}
        </div>)}
      <br />
    </Fragment>
  }

  templateRunningRender = (taskMapping, pathRTaskMapping, signOffTaskList, signOffPathRList) => {

    const taskExist = taskMapping && Object.keys(taskMapping).length > 0, pathRExist = pathRTaskMapping && Object.keys(pathRTaskMapping).length > 0;
    if (taskExist || pathRExist) {
      const newTaskMap = getTemplatePowerTasks(taskMapping, signOffTaskList);
      const newPathRMap = getTemplatePathRTasks(pathRTaskMapping, signOffPathRList);
      return <Fragment>
        {pathRExist && this.createTemplateMsg('Path-R', newPathRMap)}
        {taskExist && this.createTemplateMsg('Impedance', newTaskMap)}
      </Fragment>
    }
    return <div>
      {
        signOffTaskList.map(item =>
          getMessageByStatus(item.status) ?
            <div className="cascade-message-title" key={item.id}>
              <span className='font-bold'>{item.name}</span> - {getMessageByStatus(item.status)}
            </div>
            : null)
      }
    </div>
  }

  templateErrorMapRender = (templatePowerErrorMap) => {
    if (!templatePowerErrorMap || !templatePowerErrorMap.keys) {
      return null;
    }
    return Array.from(templatePowerErrorMap.keys()).map((key, index) =>
      <div key={index}>
        <span className="monitor-error-title">{key}</span>
        {(templatePowerErrorMap.get(key) || []).map((item, i) =>
          <div key={i} className="monitor-error-sub-content">
            <span className="monitor-error-title">{item.title}</span>
            <span className="monitor-error-msg">{item.error}</span>
          </div>)}
      </div>)

  }

  templateTaskErrorMapRender = (signOffTaskErrorMap, title) => {
    if (!signOffTaskErrorMap || !signOffTaskErrorMap.keys || !Array.from(signOffTaskErrorMap.keys()).length) {
      return null;
    }
    return <div className='monitor-error-main'>
      <span className="monitor-error-title">{"==>"} {title}</span>
      {Array.from(signOffTaskErrorMap.keys()).map((key, index) =>
        <div key={index}>
          <span className="monitor-error-title">{key}</span>
          {(signOffTaskErrorMap.get(key) || []).map((item, i) =>
            (item.error && item.error.length) ?
              <div key={i} className="monitor-error-sub-content">
                <span className="monitor-error-title">Task - {item.name}</span>
                <div className="monitor-error-sub-content" key={item.id}>
                  {item.error.map((it, _i) => {
                    return <div className="monitor-error-msg monitor-error-sub-content" key={_i}>{it}</div>
                  })}
                </div>
              </div> : null)}
        </div>)}
    </div>
  }

  getTitle = ({
    projectName,
    verificationTitle,
    monitorType,
    signOffAllList,
    signOffTaskId
  }) => {
    return <div className="cascade-monitor-title-box">
      {projectName && <div className='aurora-simulation-span'>
        <span className='aurora-span-msg'>
          {verificationTitle ? projectName + '    ' + verificationTitle : projectName}
        </span>
      </div>}
      {monitorType === SIGN_OFF_TEMPLATE && signOffAllList.length ?
        <div className="aurora-simulation-selection">
          <Select
            popupMatchSelectWidth={false}
            value={signOffTaskId || "template"}
            style={{ width: "100%" }}
            onChange={(key) => this.selectSignOffTask(key)}
            popupClassName="aurora-simulation-selection-dropdown"
          >
            <Select.Option key="template" value="template">
              Template
            </Select.Option>
            {signOffAllList.map(item => {
              if (item.dataType === 'DCR') {
                item.dataType = 'Path-R'
              } else if (item.dataType === IMPEDANCE) {
                item.dataType = 'Impedance'
              }
              return <Select.Option key={item.id} value={item.id}>
                {`[ ${item.dataType} ] : ` + item.name}
              </Select.Option>
            })
            }
          </Select>
        </div> : null
      }
    </div>
  }

}

const mapState = (state) => {
  const { simulation, project: { viewList, updatePCBLog, updateTemplateLog },
    DCR: { errorMessage, warningMessage },
    Impedance: { errorMsg: ImpError, warningMsg: ImpWarning },
    SignOffTemplate: { signOffTaskErrorMap, signOffPathTaskErrorMap, signOffTaskId,
      templateError, data: { taskMapping, pathRTaskMapping }, templateStackupErrors },
    DesignTree: { error: treeError } } = state.CascadeReducer;
  const { monitorScreenInfo: {
    currentProjectId,
    currentVerificationId,
    projectName,
    verificationName },
    tabVisible
  } = state.MonitorInfoReducer;

  const view = viewList.find(item => [DCR, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE].includes(item));

  let endMsg = null, log = null, startMsg = null,
    progress = -1, monitor = [], errorMsg = [],
    waitingIndex = -1, waitingTime = null, warningMsg = [],
    signOffTaskList = [], signOffPathRList = [], monitorType = null, signOffStatus = 0, templateErrors = null,
    templatePowerErrorMap = null, templateTaskErrorMap = null, templatePathTaskErrorMap = null, stackupError = null;
  let _verificationId = view === SIGN_OFF_TEMPLATE && signOffTaskId ? signOffTaskId : currentVerificationId;
  if (_verificationId && simulation[_verificationId]) {
    endMsg = simulation[_verificationId].endMsg;
    log = simulation[_verificationId].log;
    startMsg = simulation[_verificationId].startMsg;
    progress = simulation[_verificationId].progress;
    monitor = simulation[_verificationId].monitor;
    waitingIndex = simulation[_verificationId].waitingIndex;
    waitingTime = simulation[_verificationId].waitingTime;
    stackupError = simulation[_verificationId].stackupError;
  }

  switch (view) {
    case DCR:
      errorMsg = [...errorMessage];
      warningMsg = [...warningMessage];
      break;
    case IMPEDANCE:
      errorMsg = [...ImpError];
      warningMsg = [...ImpWarning]
      break;
    case SIGN_OFF_TEMPLATE:
      const taskList = CascadeChannels.getList(IMPEDANCE, currentProjectId);
      signOffTaskList = taskList.filter(item => item.signOffId === currentVerificationId);
      const pathRList = CascadeChannels.getList(DCR, currentProjectId);
      signOffPathRList = pathRList.filter(item => item.signOffId === currentVerificationId)
      monitorType = SIGN_OFF_TEMPLATE;
      const currSignOff = CascadeChannels.getVerification(SIGN_OFF_TEMPLATE, currentProjectId, currentVerificationId) || {};
      signOffStatus = currSignOff.status;
      templateErrors = templateError && templateError.id === currentVerificationId && templateError.errors ? templateError.errors : [];
      templatePowerErrorMap = templateError && templateError.id === currentVerificationId && templateError.powerErrorMap ? templateError.powerErrorMap : null;
      stackupError = templateStackupErrors ? (templateStackupErrors.find(item => item.verificationId === currentVerificationId) || {}).stackupError : null;
      templateTaskErrorMap = signOffTaskErrorMap && signOffTaskErrorMap[currentVerificationId] ? signOffTaskErrorMap[currentVerificationId] : null;
      templatePathTaskErrorMap = signOffPathTaskErrorMap && signOffPathTaskErrorMap[currentVerificationId] ? signOffPathTaskErrorMap[currentVerificationId] : null;
      break;
    case DESIGN_TREE:
    case POWER_TREE:
    case SINGLE_TREE:
      errorMsg = [...treeError];
      break;
    default: break;
  }

  return {
    verificationName,
    verificationId: _verificationId,
    startMsg,
    endMsg,
    log,
    monitor,
    progress,
    projectName,
    waitingIndex,
    errorMsg,
    warningMsg,
    viewList,
    tabVisible,
    waitingTime,
    updatePCBLog,
    updateTemplateLog,
    signOffTaskList,
    signOffPathRList,
    monitorType,
    signOffTaskId,
    signOffStatus,
    templateTaskErrorMap,
    templatePathTaskErrorMap,
    templateErrors,
    templatePowerErrorMap,
    taskMapping,
    pathRTaskMapping,
    stackupError
  }
}

const mapDispatch = (dispatch) => ({
  _cancelWorkflow(verificationId) {
    dispatch(cancelWorkflow(verificationId))
  },
  _selectSignOffTaskId(id) {
    dispatch(selectSignOffTask(id))
  },
  _getVerificationWorkflow(id) {
    dispatch(getVerificationWorkflow(id))
  }
})

export default connect(mapState, mapDispatch)(SimulationMonitor);
