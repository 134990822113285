import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  OPEN_IO_SIM_PAGE,
  SAVE_SIM_OPTIONS
} from './actionTypes';
import {
  updateIoSimInfo
} from "./action";
import ioSimsConstructor from "../../../../services/Himalayas/ioSim/ioSimsConstructor";
import { IO_SIM, HIMALAYAS_WRITE, HIMALAYAS_READ } from "../../../../constants/treeConstants";
import { getVerificationWorkflow } from "../simulation/action";
import {
  // class
  SimulationOptions,

  // api
  getSimulationOptions,
  updateSimulationOptions,
  getIoSimContentPromise
} from "../../../../services/Himalayas/project/channel";

function* _getContent(action) {
  const { id } = action;
  try {

    const ioSim = ioSimsConstructor.get(id) || {};

    const backendSimOptions = yield call(getSimulationOptions, id);
    //TODO: get content
    const res = yield call(getIoSimContentPromise, id);
    let _mode = null;
    if (res && res.content && res.content.mode) {
      _mode = res.content.mode;
      _mode = _mode !== HIMALAYAS_WRITE ? HIMALAYAS_READ : HIMALAYAS_WRITE
    }
    const mode = _mode || ioSim.mode || HIMALAYAS_READ;

    yield put(updateIoSimInfo({
      verificationId: ioSim.verificationId,
      channelId: id,
      simOptions: new SimulationOptions(backendSimOptions),
      mode
    }))
    //get verification workflow
    yield put(getVerificationWorkflow({
      channelId: id,
      workType: IO_SIM,
      verificationId: ioSim.verificationId
    }));
  } catch (error) {
    yield put(updateIoSimInfo({
      simOptions: new SimulationOptions()
    }))
    console.error(error);
  }
}

function* updateSimOptions(action) {
  const { id, options, allInterfaces } = action;
  const _options = new SimulationOptions(options);

  try {
    yield call(updateSimulationOptions, { channelId: id, allInterfaces, options: _options });
    const { HimalayasReducer: { ioSim: { channelId } } } = yield select();

    if (channelId === id) {
      yield put(updateIoSimInfo({ simOptions: _options }))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* ioSimSaga() {
  yield takeEvery(OPEN_IO_SIM_PAGE, _getContent);
  yield takeEvery(SAVE_SIM_OPTIONS, updateSimOptions);
}