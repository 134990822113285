import React, { Component } from 'react';
import StackupData, { UNIT } from '@/services/data/newStackupData';
import StackupTable from '@/components/EditableTable';
import { CloseOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { message, Select, Tooltip } from 'antd';
import LayoutData from '@/services/data/LayoutData';
import { numberCheck } from '@/services/helper/dataProcess';
import DelConfirm from '@/components/DelConfirm';
import { getThicknessScale, updateThicknessByUnit } from '../../../../services/Stackup';
import './index.css';

const Option = Select.Option;

class Stackup extends Component {
  constructor(props) {
    super(props);
    let StackupColums = [{
      title: 'Index',
      dataIndex: 'indexStackup',
      width: '70px',
      render: (data, record) => {
        const edit = <div className="stackup-table-edit">
          <div onClick={() => this.updateDielectric(record, 'add', 0)}>
            <Tooltip title="Add a dielectric layer above" placement="top" overlayClassName="aurora-tooltip">
              <VerticalAlignTopOutlined />
            </Tooltip>
          </div>
          {record.type === 'Dielectric' && <div onClick={() => this.updateDielectric(record, 'delete', 0)}>
            <Tooltip title={`Delete the dielectric layer ${record.name}`} placement="top" overlayClassName="aurora-tooltip">
              <CloseOutlined />
            </Tooltip>
          </div>}
          <div onClick={() => this.updateDielectric(record, 'add', 1)}>
            <Tooltip title="Add a dielectric layer blow" placement="top" overlayClassName="aurora-tooltip">
              <VerticalAlignBottomOutlined />
            </Tooltip>
          </div>
        </div>
        return <Tooltip
          title={edit}
          trigger='hover'
          placement="left"
          overlayClassName="stackup-table-edit-tooltip aurora-tooltip">
          <div>{data + 1}</div>
        </Tooltip>
      }
    }, {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      onCell: record => ({
        record,
        edit: (record.type === 'Dielectric') ? true : false,
        title: 'Name',
        dataIndex: 'name',
        handleSave: (row) => this.saveData(row, 'name')
      })
    }, {
      title: 'Type',
      dataIndex: 'type',
      width: '15%',
    }, {
      title: 'Thickness',
      dataIndex: 'thickness',
      width: '15%',
      onCell: record => ({
        record,
        edit: true,
        title: 'Thickness',
        dataIndex: 'thickness',
        handleSave: (row) => this.saveData(row, 'thickness')
      })
    }, {
      title: 'Dielectric Constant',
      dataIndex: 'epsilon',
      width: '15%',
      onCell: record => ({
        record,
        edit: (record.epsilon || record.epsilon === 0) ? true : false,
        title: 'Dielectric Constant',
        dataIndex: 'epsilon',
        handleSave: (row) => this.saveData(row, 'epsilon'),
      })
    }, {
      title: 'Loss Tangent',
      dataIndex: 'delta',
      width: '15%',
      onCell: record => ({
        record,
        edit: (record.delta || record.delta === 0) ? true : false,
        title: 'Loss Tangent',
        dataIndex: 'delta',
        handleSave: (row) => this.saveData(row, 'delta'),
      })
    }, {
      title: 'Conductivity (S/m)',
      dataIndex: 'conductivity',
      width: '15%',
      onCell: (record) => {
        return {
          record,
          edit: record.type === "Metal" ? true : false,
          title: 'Conductivity (S/m)',
          dataIndex: 'conductivity',
          handleSave: (row) => this.saveData(row, 'conductivity')
        }
      }
    }];
    this.state = {
      loading: false,
      StackupColums,
      errorDisplay: false,
      errorMsg: null,
      newInsertIndex: null,
      addRowLightClass: false,
    }
  }

  saveData = (row, dataType) => {
    const { data, unit, designID, _reCheckSimulation } = this.props;
    /* const newData = [].concat(data); */
    let newData = [];//Deep copy
    for (let i = 0; i < data.length; i++) {
      let item = { ...data[i] };
      newData.push(item);
    }
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].indexTable === row.indexTable) {
        //thickness value check
        if (dataType === 'thickness') {
          if (!row.thickness) {
            message.error('Thickness cannot be empty.');
            return;
          } else {
            let numError = numberCheck(row.thickness);
            if (numError) {
              message.error('Thickness must be a number.');
              return;
            } else if (parseFloat(row.thickness) <= 0) {
              message.error('Thickness should be greater than 0.');
              return;
            } else {
              newData[i].thickness = parseFloat(row.thickness);
            }
          }
        }

        //check metal
        if (row.type === 'Metal' && dataType === 'conductivity') {
          if (!row.conductivity) {
            message.error('Conductivity cannot be empty.');
            return;
          } else {
            let numError = numberCheck(row.conductivity);
            if (numError) {
              message.error('Conductivity must be a number.');
              return;
            } else if (parseFloat(row.conductivity) < 1.0e5 || parseFloat(row.conductivity) > 1.0e10) {//range 1.0e5 ~ 1.0e10
              message.error('Conductivity should be between 1.0e5 ~ 1.0e10.');
              return;
            } else {
              newData[i].conductivity = parseFloat(row.conductivity);
            }
          }
        }

        //check Dielectric
        if (row.type === 'Dielectric') {
          if (dataType === 'epsilon') {
            if (!row.epsilon) {
              message.error('Dielectric Constant cannot be empty.');
              return;
            } else {
              let numError = numberCheck(row.epsilon);
              if (numError) {
                message.error('Dielectric Constant must be a number.');
                return;
              } else if (parseFloat(row.epsilon) < 1 || parseFloat(row.epsilon) > 10) {//range 1-10
                message.error('Dielectric Constant should be between 1 ~ 10.');
                return;
              } else {
                newData[i].epsilon = parseFloat(row.epsilon);
              }
            }
          }

          if (dataType === 'delta') {
            if (!row.delta) {
              message.error('Loss Tangent cannot be empty.');
            } else {
              let numError = numberCheck(row.delta);
              if (numError) {
                message.error('Loss Tangent must be a number.');
              } else if (parseFloat(row.delta) < 0 || parseFloat(row.delta) > 0.1) { //range 0-0.1
                message.error('Loss Tangent should be between 0 ~ 0.1.');
              } else {
                newData[i].delta = parseFloat(row.delta);
              }
            }
          }
        }

        // check name
        if (dataType === 'name') {
          if (row.name.length < 1) {
            message.error('Name can not be empty.');
            return;
          } else if (/[^0-9a-zA-Z_-]/i.test(row.name)) {
            message.error('Name may only contain the following characters: number, letters, underscores, minus.');
            return;
          } else {
            const find = newData.find(item => item.name === row.name);
            if (find) {
              message.error('Name already exists.');
              return;
            } else {
              newData[i].name = row.name;
            }
          }
        }
        break;
      }
    }
    this.props.changeTable(newData, unit, designID);
    StackupData.saveLayer(newData, unit, designID).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
      if (_reCheckSimulation) {
        _reCheckSimulation(newData);
      }
    })
  }

  InitializeData = (Unit) => {
    const { designID } = this.props;
    StackupData.getLayer(true, false, true, designID, true).then(res => {
      const data = JSON.parse(JSON.stringify(res));
      const totalHight = this.props.getRealHeight(data);
      this.setState({ totalHight })
      const unit = Unit ? Unit : this.props.unit;
      this.props.setStackupData(data, unit, designID);
    })
  }

  settingUnitChange = (value) => {
    const { data, designID, unit, _reCheckSimulation } = this.props;
    this.props.settingUnit(value, designID);
    const scale = getThicknessScale(value, unit);
    let updateLayers = [...data];
    updateLayers.forEach(layer => {
      layer.thickness = updateThicknessByUnit(scale, layer.thickness)
    })
    this.props.changeTable(updateLayers, value, designID);
    StackupData.saveLayer(updateLayers, value, designID).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
      if (_reCheckSimulation) {
        _reCheckSimulation(updateLayers);
      }
    })
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    };
    const { designID } = this.props;
    LayoutData.getStackup(designID, null, null, true).then(response => {
      if (!response) {
        return;
      }
      const unit = response.getUnitString();
      this.InitializeData(unit);
      this.setState((prevState) => {
        prevState.StackupColums[3].title = () => {
          return <span>
            Thickness <Select
              value={unit}
              onChange={this.settingUnitChange}
              className='stackup-unit-selection'
            >
              {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
            </Select>
          </span>
        };
        return {
          StackupColums: prevState.StackupColums,
        }
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unit && this.props.unit !== prevProps.unit) {
      this.setState((prevState) => {
        prevState.StackupColums[3].title = () => {
          return <span>
            Thickness <Select
              value={this.props.unit}
              onChange={this.settingUnitChange}
              className='stackup-unit-selection'
            >
              {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
            </Select>
          </span>
        };
        return {
          StackupColums: prevState.StackupColums,
        }
      })
    }
  }

  getTotalThickness(data) {
    if (data.length === 0) {
      return null;
    }
    let sum = 0;
    data.forEach(function (item) {
      sum += parseFloat(item.thickness);
    });
    return sum.toFixed(3);
  }

  closeStackupPanel = () => {
    const { data, designID, _closePanel } = this.props;
    const error = StackupData.checkData(data);
    if (error) {
      this.setState({
        errorDisplay: true,
        errorMsg: error
      });
      this.closePanel = _closePanel;
    } else {
      LayoutData.saveStackup(designID);
      _closePanel();
    }
  }

  close = (e, _close) => {
    // _close - false: change, true: ignore
    if (_close) {
      this.setState({
        errorDisplay: false,
        errorMsg: null
      });
      this.closePanel();
    } else {
      this.setState({
        errorDisplay: false,
        errorMsg: null
      });
      return;
    }
  }

  // action: add or delete
  // fix: Adjust the position of adding or deleting, 0 or 1
  updateDielectric = (dielectric, action, fix) => {
    // deep copy data array
    let newData = this.props.data.map(item => {
      return { ...item }
    })
    const startStackup = newData[0].indexStackup, startTable = newData[0].indexTable;
    // targetLayer: Determine where to add
    // insertLayer: The layer to be inserted
    let targetLayer = newData[dielectric.indexStackup] || newData[newData.length - 1], insertLayers = [];
    if (!targetLayer) {
      return message.error(`${action} failed.`);
    }
    if (action === 'add') {
      // DielectricCount: dielectric layer total
      // DielectricName: exist dielectric layer name
      // newLayerName: will insert layer name
      let DielectricCount = 2, DielectricName = [], prevMetalName, nextMetalName, newLayerName;
      for (let i = targetLayer.indexStackup + fix - 1; i >= 0; i--) {
        if (newData[i].type === 'Metal') {
          prevMetalName = newData[i].name;
          break;
        }
      }
      for (let i = targetLayer.indexStackup + fix; i < newData.length; i++) {
        if (newData[i].type === 'Metal' && newData[i].name !== prevMetalName) {
          nextMetalName = newData[i].name;
          break;
        }
      }
      if (prevMetalName && nextMetalName) {
        newLayerName = `D_${prevMetalName}_${nextMetalName}`;
      } else {
        newLayerName = `D_${prevMetalName ? prevMetalName : nextMetalName}`;
      }
      // filter similar name
      const similarNameRegExp = new RegExp(newLayerName);
      const similarName = newData.filter(item => similarNameRegExp.test(item.name)).map(item => item.name.slice(newLayerName.length + 1));
      if (similarName.length > 0) {
        let updateName = false;
        similarName.forEach(item => {
          if (!isNaN(Number(item))) {
            DielectricName[item] = true;
            updateName = true;
          }
        })
        if (updateName) {
          while (DielectricName[DielectricCount]) { DielectricCount++ };
          newLayerName = `${newLayerName}_${DielectricCount}`;
        }
      }
      insertLayers[0] = {
        conductivity: null,
        delta: 0.02,
        epsilon: 4.2,
        indexMetal: null,
        material: "FR-4",
        name: newLayerName,
        thickness: 6.0,
        type: "Dielectric"
      }
    }
    // update data and index
    newData.splice(dielectric.indexStackup + fix, action === 'add' ? 0 : 1, ...insertLayers);
    for (let newIndex = 0; newIndex < newData.length; newIndex++) {
      newData[newIndex].indexStackup = newIndex + startStackup;
      newData[newIndex].indexTable = newIndex + startTable;
    }
    // save data
    const { unit, designID, _reCheckSimulation } = this.props;
    this.props.changeTable(newData, unit, designID);
    StackupData.updateDielectricLayer(targetLayer, action === 'add' ? 0 : 1, insertLayers, fix);
    StackupData.saveLayer(newData, unit, designID).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
      if (_reCheckSimulation) {
        _reCheckSimulation(newData);
      }
    })
    if (action === 'add') {
      setTimeout(() => {
        const newInsertIndex = newData.findIndex(item => item.name === insertLayers[0].name);
        this.setState({ addRowLightClass: true, newInsertIndex });
        setTimeout(() => {
          this.setState({ addRowLightClass: false, newInsertIndex: null });
        }, 3000);
      }, 0)
    }
  }

  render() {
    const { data, loading, scrollY, width } = this.props;
    const { StackupColums, errorMsg, errorDisplay, totalHight, newInsertIndex, addRowLightClass } = this.state;
    const totalThickness = this.getTotalThickness(data);
    let scroll = { y: totalHight ? totalHight > scrollY ? scrollY : false : scrollY, x: width && width < 1200 ? 1200 : false };
    return (
      <div className="stackup-table-prev">
        <StackupTable
          loading={loading}
          dataSource={data}
          columns={StackupColums}
          scroll={scroll}
          rowKey={record => record.name}
          footer={() => <TotalThickness thickness={totalThickness} />}
          size="small"
          rowClassName={(record, index) => {
            if (record.type === "Dielectric" && index === newInsertIndex && addRowLightClass) {
              return "stackup-table-row-light stackup-all-layer"
            } else if (record.type === "Dielectric" && (index === 0 || index === data.length - 1)) {
              return "stackup-out-dielectric-color stackup-all-layer"
            } else if (record.type === 'Dielectric') {
              return "stackup-in-dielectric-color stackup-all-layer"
            } else if (record.type === 'Metal') {
              return "stackup-all-metal-color stackup-all-layer"
            }
          }}
        />
        {errorDisplay ? <DelConfirm
          message={errorMsg}
          type={'change'}
          onChange={(e) => this.close(e, false)}
          onIgnore={(e) => this.close(e, true)}
        /> : null}
      </div>
    )
  }
}

const TotalThickness = (props) => {
  return (
    <div className='stackup-total-thickness-content'>
      <div>Total Thickness : </div>
      <div>{props.thickness}</div>
    </div>
  )
}

export default Stackup;