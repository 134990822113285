import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Select, message } from 'antd';
import {
  editChannelType,
  /*   saveReExtraction */
  saveExtraction
} from '../../store/andes/action';
import ExtractionOptions from './extractioOptions';
import FileSaver from 'file-saver';
import { getResBlob } from '@/services/helper/downloadHelper';
import '../index.css';

const CHANNEL = ["SIwave", "HFSS"];
const { Option } = Select;

class ChannelSelect extends PureComponent {

  editChannel = (type) => {
    const { current, Interfaces } = this.props;
    if (!Interfaces || Interfaces.length === 0) {
      message.error('Does not set signal.');
      return;
    }
    this.props.editChannelType({ pcb: current, channel: type });
  };

  getChannel(channel) {
    if (!channel) return;
    const keys = Object.keys(channel);
    if (keys.length === 0) return;
    const { current } = this.props;
    let list = {};
    if (keys.includes(current)) {
      list = { pcb: current, channel: channel[current].type };
    }
    return list;
  }

  SIWEDownload = () => {
    const { currentVerificationId, designName, verificationName } = this.props;
    const token = localStorage.getItem('token');
    const url = `/api/v3/project/verification/${currentVerificationId}/andes/download/SIWE?&access_token=${token}`;
    getResBlob(url).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, `${designName}_${verificationName}.zip`);
      } else {
        message.error('Download failed!');
      }
    })
  }

  isHFSS = (channel) => {
    if (!channel) return false;
    let _isHFSS = false;
    Object.keys(channel).forEach(key => {
      if (channel[key].type === 'HFSS') {
        _isHFSS = true;
      }
    });
    return _isHFSS;
  }

  render() {
    const { channel, Interfaces, current } = this.props;
    let _channel = Object.keys(channel).length > 0 ? this.getChannel(channel) : {};
    let currentInterface = Interfaces.find(item => item.pcb === current);
    const extraction = (currentInterface && currentInterface.content && currentInterface.content.extraction) || null;
    const simulateHFSS = this.isHFSS(channel);
    return (
      <Fragment>
        <Select
          value={Object.keys(_channel).length > 0 ? _channel.channel : ''}
          onChange={this.editChannel}
          className="andes-channel-select"
          getPopupContainer={() => document.getElementById('andes-content-main')}
        >
          {
            CHANNEL.map(item =>
              <Option value={item} key={item}>{item}</Option>)
          }
        </Select>
        <ExtractionOptions
          saveExtraction={(extraction) => this.props.saveExtraction(extraction, current)}
          extraction={extraction}
          simulate={simulateHFSS}
        />
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { AndesReducer: { andes, project: { channelList = [] }, simulationReducer: { existResult } } } = state;
  const { currentVerificationId } = state.TabMonitorReducer;
  const { andesInfo } = andes;
  let channel = {}, Interfaces = [];
  if (andesInfo) {
    Interfaces = andesInfo.Interfaces;
    channel = andesInfo.info.channel;
  }

  return {
    Interfaces,
    channelList,
    channel,
    currentVerificationId,
    existResult
  }
};


const mapDispatch = (dispatch) => ({
  editChannelType(info) {
    dispatch(editChannelType(info));
  },
  /*   saveReExtraction(interfaceId, ifDoExtraction) {
      dispatch(saveReExtraction(interfaceId, ifDoExtraction))
    } */
  saveExtraction(extraction, pcb) {
    dispatch(saveExtraction(extraction, pcb))
  }

})

export default connect(mapState, mapDispatch)(ChannelSelect);