import designConstructor from "../../helper/designConstructor";
import { getDefaultIndex } from "../../helper/setDefaultName";
import { CONNECTOR } from "../../PCBHelper";
import channelConstructor from "../channel/channelConstructor";
import channelSetupInfo from "../channel/channelInfo";
import { findPinMapByNet } from "../../helper/connectorHelper/connectionModelHelper";
import { Connection, ConnectionChannel, ConnectionItem, Connector, PCBConnection, SignalConnectionMap } from "./IntegratedEndToEndChannel";
import { TOUCHSTONE, CABLE_TOUCHSTONE } from "../../../constants/libraryConstants";

function getPCBConnectionsColumns(pcbConnections) {
  let list = [];
  const width = `${100 / pcbConnections.length}%`;
  for (let i = 0; i < pcbConnections.length; i++) {
    const key = `PCB_${i}`;
    list.push({
      title: `PCB ${i + 1}`,
      name: `PCB ${i + 1}`,
      dataIndex: key,
      key: key,
      width,
      pcbChannel: pcbConnections[i]
    })
  }
  return list;
}

function getEndToEndPCBConnections(pcbConnections) {
  let pcbConnection = {};
  for (let i = 0; i < pcbConnections.length; i++) {
    const item = pcbConnections[i];
    const key = `PCB_${i}`;
    pcbConnection[key] = item;
  }
  return [pcbConnection];
}

/**
 * update connection when pcb connection change
 * @param {Array} connections
 * @param {Object} currentPCB {designId,designName,channelId,channelName, prevConnectionId,nextConnectionId}
 *  */
function updateConnectionByPCB({
  connections,
  currentPCB = {},
  pcbConnections = []
}) {
  const prevConnectionId = currentPCB.prevConnectionId;
  const nextConnectionId = currentPCB.nextConnectionId;
  const signals = channelSetupInfo.getSelectedSignalNames(currentPCB.channelId);
  let newSignals = [];
  const prevSignalMap = connections[0].connection.signal_connections_map;
  //create new signal map
  if (!prevSignalMap || !prevSignalMap.length) {
    signals.forEach((item, index) => {
      newSignals.push(new SignalConnectionMap({
        name: `Signal${index + 1}`,
        channel1_signal: "",
        channel2_signal: ""
      }))
    })
  }


  for (let conn of connections) {
    //add new signal to signal map
    conn.connection.signal_connections_map.push(...JSON.parse(JSON.stringify(newSignals)));

    if (![prevConnectionId, nextConnectionId].includes(conn.CONNECTION_ID)) {
      continue;
    }

    if (conn.CONNECTION_ID === prevConnectionId) {
      conn.channel2 = {
        designId: currentPCB.designId,
        designName: currentPCB.designName,
        channelId: currentPCB.channelId,
        channelName: currentPCB.channelName,
      };

      conn.connection.connector2 = new Connector({});
      conn.connection.signal_connections_map.forEach((item, index) => {
        item.channel2_signal = signals[index] ? signals[index] : "";
      })
    }

    if (conn.CONNECTION_ID === nextConnectionId) {
      conn.channel1 = {
        designId: currentPCB.designId,
        designName: currentPCB.designName,
        channelId: currentPCB.channelId,
        channelName: currentPCB.channelName,
      };
      conn.connection.connector1 = new Connector({});
      conn.connection.signal_connections_map.forEach((item, index) => {
        item.channel1_signal = signals[index] ? signals[index] : "";
      })
    }
  }
  const findChannel = pcbConnections.find(item => !!item.channelId);
  //clear all cable models and signals
  if (!findChannel) {
    for (let conn of connections) {
      conn.connection.signal_connections_map = [];
      conn.connection.cableModels = [];
    }
  }


  return connections;
}

function updateConnectionsByPCB({ type, pcbConnections, addIndex, delIndex, connections }) {
  if (type === "delete") {
    return delConnByDeletedPCB(connections, pcbConnections, delIndex)
  } else if (type === "add") {
    return addNewConnectionByPCB(connections, pcbConnections, addIndex)
  }
}

/**
 * add new pcb in pcb connections list, and update connections 
 * @param {Array} connections
 * @param {Array} pcbConnections
 * @param {String} addIndex current pcb index in pcb connections
 *  */
function addNewConnectionByPCB(connections, pcbConnections, addIndex) {
  const _addIndex = parseInt(addIndex);
  if (_addIndex === pcbConnections.length - 1) {
    //get connection id 
    const CONNECTION_ID = getDefaultIndex(connections.length, connections.map(item => item.CONNECTION_ID));
    const lastPCB = pcbConnections[pcbConnections.length - 1];
    pcbConnections[pcbConnections.length - 1].nextConnectionId = CONNECTION_ID;
    pcbConnections.push(new PCBConnection({
      prevConnectionId: CONNECTION_ID
    }))
    //find prev connection,channel1,connection1
    const prevConnection = connections.find(item => lastPCB.prevConnectionId === item.CONNECTION_ID);
    const signal_connections_map = prevConnection && prevConnection.connection ? prevConnection.connection.signal_connections_map : [];
    const newConnectionItem = new ConnectionItem({
      signal_connections_map: signal_connections_map.map(item => {
        return new SignalConnectionMap({
          name: item.name,
          channel1_signal: item.channel2_signal
        })
      }),
    })
    connections.push(new Connection({
      CONNECTION_ID,
      channel1: new ConnectionChannel({
        ...lastPCB
      }),
      connection: newConnectionItem
    }));
    connections = updateConnectionByPCB({
      connections,
      currentPCB: {
        designId: "",
        designName: "",
        channelId: "",
        channelName: "",
        prevConnectionId: CONNECTION_ID
      },
      pcbConnections
    })
  } else {
    //get connection id 
    const prevConnectionId = getDefaultIndex(connections.length, connections.map(item => item.CONNECTION_ID));
    const nextConnectionId = getDefaultIndex(connections.length, [...connections.map(item => item.CONNECTION_ID), prevConnectionId]);

    const prevPCB = pcbConnections[_addIndex];
    const nextPCB = JSON.parse(JSON.stringify(pcbConnections[_addIndex + 1]));

    const splitConnection = connections.find(item => prevPCB.nextConnectionId === item.CONNECTION_ID);
    const splitConnectionItem = splitConnection ? splitConnection.connection : {};
    const signal_connections_map = splitConnectionItem.signal_connections_map || [];
    const newConnector1 = splitConnectionItem.connector1 || new Connector({});
    const newConnector2 = splitConnectionItem.connector2 || new Connector({});

    newConnector1.pins.forEach(item => {
      item.pin_map = "";
      if (item.external_map) {
        item.external_map.cableModelId = "";
        item.external_map.cablePort = "";
      }
    })
    newConnector2.pins.forEach(item => {
      if (item.external_map) {
        item.external_map.cableModelId = "";
        item.external_map.cablePort = "";
      }
    })
    //add prev connection
    const newConnectionItem = new ConnectionItem({
      signal_connections_map: signal_connections_map.map(item => {
        return new SignalConnectionMap({
          name: item.name,
          channel1_signal: item.channel1_signal
        })
      }),
      connector1: JSON.parse(JSON.stringify(newConnector1))
    })
    connections.push(new Connection({
      CONNECTION_ID: prevConnectionId,
      channel1: new ConnectionChannel({
        ...prevPCB
      }),
      connection: newConnectionItem
    }));
    connections = updateConnectionByPCB({
      connections,
      currentPCB: {
        designId: "",
        designName: "",
        channelId: "",
        channelName: "",
        prevConnectionId
      },
      pcbConnections
    })
    //add next connection
    const newNextConnectionItem = new ConnectionItem({
      signal_connections_map: signal_connections_map.map(item => {
        return new SignalConnectionMap({
          name: item.name,
          channel2_signal: item.channel2_signal
        })
      }),
      connector2: JSON.parse(JSON.stringify(newConnector2))
    })
    connections.push(new Connection({
      CONNECTION_ID: nextConnectionId,
      channel2: new ConnectionChannel({
        ...nextPCB
      }),
      connection: newNextConnectionItem
    }));
    connections = updateConnectionByPCB({
      connections,
      currentPCB: {
        designId: "",
        designName: "",
        channelId: "",
        channelName: "",
        nextConnectionId
      },
      pcbConnections
    });
    //Delete split connection
    connections = connections.filter(item => item.CONNECTION_ID !== prevPCB.nextConnectionId);
    pcbConnections[_addIndex].nextConnectionId = prevConnectionId;
    pcbConnections.splice(_addIndex + 1, 0, new PCBConnection({
      prevConnectionId,
      nextConnectionId
    }));
    pcbConnections[_addIndex + 2].prevConnectionId = nextConnectionId;
  }
  return { pcbConnections, connections }
}

/**
 * Delete pcb in pcb connections list, and update connections 
 * @param {Array} connections
 * @param {Array} pcbConnections
 * @param {String} delIndex Deleted pcb index in pcbConnections
 *  */
function delConnByDeletedPCB(connections, pcbConnections, delIndex) {
  let _connections = [...connections];
  const delPCB = pcbConnections.find((it, i) => i === parseInt(delIndex));
  const deletedChannelId = delPCB.channelId;
  const prevConnectionId = delPCB.prevConnectionId;
  const nextConnectionId = delPCB.nextConnectionId;
  const prevIndex = _connections.findIndex(item => item.CONNECTION_ID === prevConnectionId);
  const nextIndex = _connections.findIndex(item => item.CONNECTION_ID === nextConnectionId);
  let channel2Comps = [], channel2Signals = [], channel1Comps = [], channel1Signals = [];

  //delete end pcb
  if (prevConnectionId && !nextConnectionId) {
    pcbConnections = pcbConnections.filter((item, i) => i !== parseInt(delIndex));
    //end pcb deleted
    pcbConnections[pcbConnections.length - 1].nextConnectionId = "";
    _connections = _connections.filter(item => item.CONNECTION_ID !== prevConnectionId);
    return { pcbConnections, connections: _connections, deletedChannelId }
  }

  //delete first pcb
  if (nextConnectionId && !prevConnectionId) {
    pcbConnections = pcbConnections.filter((item, i) => i !== parseInt(delIndex));
    //deleted first pcb prev connection
    _connections = _connections.filter(item => item.CONNECTION_ID !== nextConnectionId);
    pcbConnections[0].prevConnectionId = "";
    return { pcbConnections, connections: _connections, deletedChannelId }
  }

  //delete middle pcb
  if (prevConnectionId && prevIndex > -1 && nextConnectionId && nextIndex > -1) {
    channel1Comps = channelSetupInfo.getComponents(_connections[prevIndex].channel1.channelId);
    channel1Signals = channelSetupInfo.getSignals(_connections[prevIndex].channel1.channelId);
    //remove current channel2 info and add next channel2 to current channel2
    _connections[prevIndex].channel2 = { ..._connections[nextIndex].channel2 };
    pcbConnections[delIndex - 1].nextConnectionId = "";

    //update signal_connections_map(next to prev)
    _connections[prevIndex].connection.signal_connections_map.forEach(item => {
      const nextSignal = _connections[nextIndex].connection.signal_connections_map.find(it => it.name === item.name);
      item.channel2_signal = nextSignal ? nextSignal.channel2_signal : ""
    })
    //update connector2
    _connections[prevIndex].connection.connector2 = new Connector({});

    const nextConnector2 = _connections[nextIndex].connection.connector2;
    //delete cable model port
    nextConnector2.pins.forEach(item => {
      if (item.external_map) {
        item.external_map.cableModelId = "";
        item.external_map.cablePort = "";
      }
    })
    _connections[prevIndex].connection.connector2 = { ...nextConnector2 };
    _connections[prevIndex].connection.cableModels = [];
    //get channel component pins
    channel2Comps = channelSetupInfo.getComponents(_connections[nextIndex].channel2.channelId);
    channel2Signals = channelSetupInfo.getSignals(_connections[nextIndex].channel2.channelId);
    const channel1Comp = channel1Comps.find(item => item.name === _connections[prevIndex].connection.connector1.component) || { pins: [] };
    const channel2Comp = channel2Comps.find(item => item.name === nextConnector2.component) || { pins: [] };
    //update pin map and cable
    _connections[prevIndex].connection.connector1.pins.forEach(item => {
      const pinObj = channel1Comp.pins.find(it => it.pin === item.pin) || {};
      const signalMap = _connections[prevIndex].connection.signal_connections_map.find(it => it.channel1_signal === pinObj.signal);
      //find pin map
      item.pin_map = findPinMapByNet({
        pinObj,
        channel1Signals,
        channel2Signals,
        signalMap,
        channel2Pins: channel2Comp.pins
      })
      //delete cable port
      if (item.external_map) {
        item.external_map.cableModelId = "";
        item.external_map.cablePort = "";
      }
    })

    //remove next connection 
    pcbConnections[delIndex - 1].nextConnectionId = pcbConnections[parseInt(delIndex) + 1].prevConnectionId;
    _connections[prevIndex].CONNECTION_ID = pcbConnections[parseInt(delIndex) + 1].prevConnectionId;
    _connections = _connections.filter((item, i) => i !== nextIndex);
  }

  pcbConnections = pcbConnections.filter((item, i) => i !== parseInt(delIndex));

  const findChannel = pcbConnections.find(item => !!item.channelId);

  //clear all cable models and signals
  if (!findChannel) {
    for (let conn of _connections) {
      conn.connection.signal_connections_map = [];
      conn.connection.cableModels = [];
    }
  }

  return { pcbConnections, connections: _connections, deletedChannelId }
}

function deletePCBInPCBConns({
  save,
  updateMsgList,
  pcbConnections,
  index,
  connections
}) {
  save = true;
  updateMsgList.push(`"PCB ${index + 1} ${pcbConnections[index].designName}" is not exist, design "${pcbConnections[index].designName}" has been deleted.`)
  pcbConnections[index] = new PCBConnection({
    prevConnectionId: pcbConnections[index].prevConnectionId,
    nextConnectionId: pcbConnections[index].nextConnectionId
  })
  connections = updateConnectionByPCB({
    connections,
    currentPCB: {
      ...pcbConnections[index]
    },
    pcbConnections
  })
  return { pcbConnections, connections, save, updateMsgList }
}

/**
 * update pcb connections and connections by deleted pcb id
 *  */
function updateConnByDeletedPCB({
  endToEndChannelInfo,
  deletedDesignId,
  pcbConnections,
  save,
  updateMsgList,
  connections
}) {
  const index = pcbConnections.findIndex(item => item.designId === deletedDesignId);
  if (index < 0) {
    return { endToEndChannelInfo, save, updateMsgList };
  }
  const info = deletePCBInPCBConns({
    save,
    updateMsgList,
    pcbConnections,
    index,
    connections
  });
  endToEndChannelInfo.content.pcbConnections = info.pcbConnections;
  endToEndChannelInfo.content.connections = info.connections;
  return { endToEndChannelInfo, save: info.save, updateMsgList: info.updateMsgList }
}

/**
 * update pcb connections and connections by update channel name
 *  */
function updateConnByModifiedChannel({
  endToEndChannelInfo,
  updateChannel,
  pcbConnections,
  save,
  updateMsgList,
  connections
}) {
  const index = pcbConnections.findIndex(item => item.channelId === updateChannel.id);
  if (index < 0) {
    return { endToEndChannelInfo, save, updateMsgList };
  }
  save = true;
  updateMsgList.push(`"PCB ${index + 1} ${pcbConnections[index].designName}": The channel name is updated from "${pcbConnections[index].channelName}" to "${updateChannel.name}".`)
  pcbConnections[index].channelName = updateChannel.name;
  if (pcbConnections[index].prevConnectionId) {
    const prevIndex = connections.findIndex(item => item.CONNECTION_ID === pcbConnections[index].prevConnectionId);
    //check channel name
    connections[prevIndex].channel2.channelName = updateChannel.name;
  }
  if (pcbConnections[index].nextConnectionId) {
    const nextIndex = connections.findIndex(item => item.CONNECTION_ID === pcbConnections[index].nextConnectionId);
    //check updateChannel name
    connections[nextIndex].channel1.channelName = updateChannel.name;
  }
  endToEndChannelInfo.content.connections = connections;
  endToEndChannelInfo.content.pcbConnections = pcbConnections;
  return { endToEndChannelInfo, save, updateMsgList }
}

// Modify the designName present in the endToEndChannelInfo
export function handleEndToEndChannelInfo(endToEndChannelInfo) {
  if (!endToEndChannelInfo || !endToEndChannelInfo.content) {
    return endToEndChannelInfo
  }

  const { content: { pcbConnections = [], connections = [] } } = endToEndChannelInfo
  const new_pcbConnections = pcbConnections.map((item) => {
    if (item.designId && designConstructor.getDesignName(item.designId)) {
      item.designName = designConstructor.getDesignName(item.designId)
    }
    return item
  })
  const new_connections = connections.map((item) => {
    if (item.channel1 && item.channel1.designId) {
      item.channel1 = { ...item.channel1, designName: designConstructor.getDesignName(item.channel1.designId) || item.channel1.designName }
    }
    if (item.channel2 && item.channel2.designId) {
      item.channel2 = { ...item.channel2, designName: designConstructor.getDesignName(item.channel2.designId) || item.channel2.designName }
    }
    return item
  })
  if (endToEndChannelInfo.adsConfig) {
    const { adsConfig: { controllerChannel = {}, deviceChannel = {} } } = endToEndChannelInfo
    if (controllerChannel.designId) {
      endToEndChannelInfo.adsConfig.controllerChannel.designName = designConstructor.getDesignName(controllerChannel.designId) || endToEndChannelInfo.adsConfig.controllerChannel.designName
    }
    if (deviceChannel.designId) {
      endToEndChannelInfo.adsConfig.deviceChannel.designName = designConstructor.getDesignName(deviceChannel.designId) || endToEndChannelInfo.adsConfig.deviceChannel.designName
    }
  }

  return {
    ...endToEndChannelInfo,
    content: {
      pcbConnections: new_pcbConnections,
      connections: new_connections
    }
  }
}

/**
 * update end to end channel setup by selected channels setup
 *  */
function endToEndSetupUpdate({
  endToEndChannelInfo,
  channelSetupList,
  deletedDesignId,
  updateChannel,
  addModelKey,
  isUpdatePCBName = false
}) {
  let save = false, updateMsgList = [];
  if (!endToEndChannelInfo || !endToEndChannelInfo.content) {
    return { endToEndChannelInfo, save, updateMsgList };
  }

  if (isUpdatePCBName) {
    endToEndChannelInfo = handleEndToEndChannelInfo(endToEndChannelInfo)
  }

  let pcbConnections = endToEndChannelInfo.content.pcbConnections,
    connections = endToEndChannelInfo.content.connections;

  //after design is deleted,update end to end
  if (deletedDesignId) {
    return updateConnByDeletedPCB({
      endToEndChannelInfo,
      deletedDesignId,
      pcbConnections,
      save,
      updateMsgList,
      connections
    })
  }

  //after channel name is update, update end to end
  if (updateChannel && updateChannel.id) {
    return updateConnByModifiedChannel({
      endToEndChannelInfo,
      updateChannel,
      pcbConnections,
      save,
      updateMsgList,
      connections
    });
  }

  //open end to end ,and before end to end simulation
  for (let i = 0; i < pcbConnections.length; i++) {
    const pcb = pcbConnections[i];
    if (!pcb.channelId) {
      continue;
    }
    const design = designConstructor.getDesign(pcb.designId);
    if (!design) {
      const info = deletePCBInPCBConns({
        save,
        updateMsgList,
        pcbConnections,
        index: i,
        connections
      })
      save = info.save;
      continue;
    }

    const channel = channelConstructor.getChannel(pcb.channelId);
    let nameUpdate = false;
    if (!channel) {
      save = true;
      updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}" is not exist, channel "${pcb.channelName}" has been deleted.`)
      pcbConnections[i].channelName = "";
      pcbConnections[i].channelId = "";
      connections = updateConnectionByPCB({
        connections,
        currentPCB: {
          ...pcbConnections[i]
        },
        pcbConnections
      })
      continue;
    } else if (channel.name !== pcbConnections[i].channelName) {
      //check channel name update
      save = true;
      nameUpdate = true;
      updateMsgList.push(`"PCB ${i + 1} ${pcb.designName}": The channel name is updated from "${pcb.channelName}" to "${channel.name}".`)
      pcbConnections[i].channelName = channel.name;
      pcb.channelName = channel.name;
    }


    const channelSetup = channelSetupList.find(item => item.id === pcb.channelId) || {};

    if (design.designVersion !== channelSetup.designVersion) {
      //pcb replace, channel not update
      updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": The design "${pcb.designName}" has been updated, please click on the channel page to update the channel "${pcb.channelName}".`)
      continue;
    }

    const channelContent = channelSetup.content || {};
    const channelComps = channelContent.components || [];
    const channelSignals = channelContent.signals || [];
    if (!Object.keys(channelSetup.content)) {
      continue;
    }

    if (pcb.prevConnectionId) {
      const prevIndex = connections.findIndex(item => item.CONNECTION_ID === pcb.prevConnectionId);
      //check channel name
      if (nameUpdate) {
        connections[prevIndex].channel2.channelName = channel.name;
      }
      const conn = connections[prevIndex].connection;

      if (conn.cableModels && conn.cableModels.length && conn.cableModels.filter(item => item.type === CABLE_TOUCHSTONE).length) {
        save = true;
        conn.cableModels = conn.cableModels.map(item => {
          if (item.type === CABLE_TOUCHSTONE) {
            item.type = TOUCHSTONE;
          }
          return item;
        });
      }
      let comp = channelComps.find(item => item.name === conn.connector2.component);
      if (!comp && conn.connector2.component) {
        //component is not exist
        save = true;
        updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": Component "${conn.connector2.component}" is not exist, "${conn.connector2.component}" has been deleted.`)
        conn.connector2.component = "";
        conn.connector2.pins = [];
        conn.connector1.pins.forEach(it => { it.pin_map = "" });
      } else if (conn.connector2.component && comp && comp.type !== CONNECTOR) {
        //component type is not "connector"
        save = true;
        updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": Component "${conn.connector2.component}" type is not 'Connector", "${conn.connector2.component}" has been deleted.`)
        conn.connector2.component = "";
        conn.connector2.pins = [];
        conn.connector1.pins.forEach(it => { it.pin_map = "" });
      }

      if (addModelKey) {
        const modelInfo = addModelKeyInModels({
          cableModels: conn.cableModels,
          models: conn.connector2.models,
          connector: conn.connector2
        });
        conn.cableModels = modelInfo.cableModels;
        conn.connector2.models = modelInfo.models;
        conn.connector2 = modelInfo.connector;
        save = true;
      }
      comp = comp || { pins: [] };
      //signal name check
      for (let signal of conn.signal_connections_map) {
        if (!signal.channel2_signal) {
          continue;
        }
        const findSignal = channelSignals.find(item => item.name === signal.channel2_signal);
        if (!findSignal) {
          save = true;
          updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": Signal "${signal.channel2_signal}" is not exist, "${signal.channel2_signal}" has been deleted.`)
          signal.channel2_signal = "";
        }
      }
      //check component exist and comp type is connector
      //check component pins
      const channel2Signals = conn.signal_connections_map.map(item => item.channel2_signal);
      let deletedPins = [];
      for (let pin of conn.connector2.pins) {
        const findPin = comp.pins.find(item => item.pin === pin.pin);
        const pinMapIndex = conn.connector1.pins.findIndex(item => item.pin_map === pin.pin);
        if (!findPin || !channel2Signals.includes(findPin.signal)) {
          save = true;
          deletedPins.push(pin.pin);
          if (pinMapIndex > -1) {
            conn.connector1.pins[pinMapIndex].pin_map = "";
          }
        }
      }
      conn.connector2.pins = conn.connector2.pins.filter(item => !deletedPins.includes(item.pin));
      connections[prevIndex].connection = conn;
    }

    if (pcb.nextConnectionId) {
      const nextIndex = connections.findIndex(item => item.CONNECTION_ID === pcb.nextConnectionId);
      //check channel name
      if (nameUpdate) {
        connections[nextIndex].channel1.channelName = channel.name;
      }
      const conn = connections[nextIndex].connection;
      if (conn.cableModels && conn.cableModels.length && conn.cableModels.filter(item => item.type === CABLE_TOUCHSTONE).length) {
        save = true;
        conn.cableModels = conn.cableModels.map(item => {
          if (item.type === CABLE_TOUCHSTONE) {
            item.type = TOUCHSTONE;
          }
          return item;
        })
      }
      let comp = channelComps.find(item => item.name === conn.connector1.component);
      if (!comp && conn.connector1.component) {
        //component is not exist
        save = true;
        updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": Component "${conn.connector1.component}" is not exist, "${conn.connector1.component}" has been deleted.`)
        conn.connector1.component = "";
        conn.connector1.pins = [];
      } else if (conn.connector1.component && comp && comp.type !== CONNECTOR) {
        //component type is not "connector"
        save = true;
        updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": Component "${conn.connector1.component}" type is not 'Connector", "${conn.connector1.component}" has been deleted.`)
        conn.connector1.component = "";
        conn.connector1.pins = [];
      }

      if (addModelKey) {
        const modelInfo = addModelKeyInModels({
          cableModels: conn.cableModels,
          models: conn.connector1.models,
          connector: conn.connector1
        });
        conn.cableModels = modelInfo.cableModels;
        conn.connector1.models = modelInfo.models;
        conn.connector1 = modelInfo.connector;
        save = true;
      }

      comp = comp || { pins: [] };

      for (let signal of conn.signal_connections_map) {
        if (!signal.channel1_signal) {
          continue;
        }
        const findSignal = channelSignals.find(item => item.name === signal.channel1_signal);
        if (!findSignal) {
          save = true;
          updateMsgList.push(`"PCB ${i + 1} ${pcb.designName} ${pcb.channelName}": Signal "${signal.channel1_signal}" is not exist, "${signal.channel1_signal}" has been deleted.`)
          signal.channel1_signal = "";
        }
      }

      //check component pins
      const channel1Signals = conn.signal_connections_map.map(item => item.channel1_signal);
      let deletedPins = [];
      for (let pin of conn.connector1.pins) {
        const findPin = comp.pins.find(item => item.pin === pin.pin);
        if (!findPin || !channel1Signals.includes(findPin.signal)) {
          save = true;
          deletedPins.push(pin.pin);
        }
      }
      conn.connector1.pins = conn.connector1.pins.filter(item => !deletedPins.includes(item.pin));
      connections[nextIndex].connection = conn;
    }
  }
  endToEndChannelInfo.content.pcbConnections = pcbConnections;
  endToEndChannelInfo.content.connections = connections;
  updateMsgList = [...new Set(updateMsgList)];

  return { endToEndChannelInfo, save, updateMsgList }
}

function addModelKeyInModels({ cableModels, models, connector }) {
  cableModels.forEach((item, index) => {
    item.modelKey = index.toString();
  });
  if (models.length) {
    models.forEach((item, index) => {
      item.modelKey = index.toString();
    });
    connector.pins.forEach(pin => {
      if (pin.port) {
        const model = models.find(it => it.libraryId === pin.modelId);
        pin.modelKey = model ? model.modelKey : "";
      }
      if (pin.external_map && pin.external_map.port) {
        const model = models.find(it => it.libraryId === pin.external_map.modelId);
        pin.external_map.modelKey = model ? model.modelKey : "";
      }
      if (pin.external_map && pin.external_map.cablePort) {
        const model = cableModels.find(it => it.libraryId === pin.external_map.cableModelId);
        pin.external_map.cableModelKey = model ? model.modelKey : "";
      }
    })
  }
  return { connector, models, cableModels }
}

/**
 * get pcb schematic connection list and signals
 *  */
function getPCBSchematicList(pcbConnections, connections) {
  let pcbSchematicList = [];
  for (let i = 0; i < pcbConnections.length; i++) {
    const pcb = pcbConnections[i];
    const nextIndex = connections.findIndex(item => item.CONNECTION_ID === pcb.nextConnectionId);
    let signals = [];
    if (nextIndex > -1) {
      const signal_connections_map = connections[nextIndex].connection.signal_connections_map || [];
      for (let item of signal_connections_map) {
        //if signal1 or signal2 exist, push
        if (item.channel2_signal || item.channel1_signal) {
          signals.push(item.name);
        }
      }
    }
    pcbSchematicList.push({
      ...pcb,
      signals
    })
  }
  return pcbSchematicList;
}

function getChannelInfo(channelId) {
  return new Promise((resolve, reject) => {
    if (!channelId) {
      resolve({});
      return;
    }
    channelSetupInfo.get(channelId).then(res => {
      if (!res) {
        resolve({});
        return;
      }
      resolve(res);
    });
  })
}

function updateEndToEndConnectionsPinMap({
  component,
  prevPin,
  newPin,
  channelId,
  connections }) {
  for (let conn of connections || []) {
    let connKey = null;
    if (conn.channel1 && conn.channel1.channelId === channelId) {
      connKey = "connector1";
    }
    if (conn.channel2 && conn.channel2.channelId === channelId) {
      connKey = "connector2";
    }

    if (!connKey || !conn.connection[connKey] || conn.connection[connKey].component !== component || !conn.connection[connKey].pins) {
      continue;
    }
    conn.connection[connKey].pins.forEach(item => {
      if (item.pin === prevPin.pin) {
        item.pin = newPin.pin
      }
    })
    if (connKey === "connector2" && conn.connection.connector1 && conn.connection.connector1.pins) {
      conn.connection.connector1.pins.forEach(item => {
        if (item.pin_map === prevPin.pin) {
          item.pin_map = newPin.pin
        }
      })
    }
  }
  return connections;
}

export {
  getPCBConnectionsColumns,
  getEndToEndPCBConnections,
  updateConnectionByPCB,
  updateConnectionsByPCB,
  endToEndSetupUpdate,
  getPCBSchematicList,
  getChannelInfo,
  updateEndToEndConnectionsPinMap
}