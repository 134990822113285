import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { TRANSLATION_FLOW, CANCEL_UPLOAD_WORKFLOW, GET_TRANSLATION_MONITOR } from './actionType';
import {
  updateUploadWorkflow,
  updateMsg,
  cleanUploadPCBStatus,
  translationMonitor,
  translationDebug,
  updateTranslationProgress,
  updateEndMsg,
  getTranslationMonitor
} from './action';
import { getWorkFlow, cancelWorkflow } from '@/services/api/v2/workflowCtrl';
import { RUNNING, SUCCEED, FAILED, CANCEL } from '@/constants/workflowStatus';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import { getMonitor } from '@/services/workflow/workflow';
import { openProject, expandMenu, updateProjectMenu } from '../../store/project/action';
import { replacePCB } from '../../store/project/saga';
import { AURORA_DB } from '../../../../constants/designVendor';
import { DESIGN_SUCCESS } from '../../../../constants/designCategory';
import { PROJECT_V2 } from '../../../../constants/projectVersion';


function* pcbTranslationFlow(action) {
  const { response, uploadType } = action;

  // Translation
  const { RockyReducer: { RockyUploadReducer: { uploadProjectId } } } = yield select();
  if (response && response.workflowId) {
    const { workflowId, designId } = response;
    yield put(updateUploadWorkflow(designId, workflowId));
    // begin translate
    const name = uploadType ? uploadType : "PCB";
    yield put(updateMsg(`==> Finish uploading ${name}.\n==> Start compiling ${name}.`));
    yield call(beginTranslation, { workflowId, designId, name });
  } else if (response.category === DESIGN_SUCCESS && response.id) {
    yield put(updateEndMsg(`==> Upload completed.`));
    const succeeded = updateTranslationProgress({ progress: 100 });
    yield put(succeeded);

    const { RockyReducer: { project: { expandedKeys, currentProjectId, projectVersion }, RockyUploadReducer: { currentPCBKey } } } = yield select();
    if (currentProjectId === uploadProjectId) {
      // replace PCB
      if (currentPCBKey === 'replace') {
        yield call(replacePCB, { designId: response.id });
      }

      const isUploadPackage = projectVersion === PROJECT_V2 && uploadType === "Package" ? true : false;
      yield put(openProject({ id: uploadProjectId, afterTranslation: true, isUploadPackage }));
      //expand pcb tree
      let Keys = [...expandedKeys];
      if (currentPCBKey && currentPCBKey !== 'replace' && !Keys.includes(currentPCBKey)) {
        Keys.push(currentPCBKey);
      }
      yield put(expandMenu([...Keys]));
    }
    yield delay(800);
    // clean upload status
    yield put(cleanUploadPCBStatus());
  } else {
    let msg = response.msg ? response.msg : 'Unknown reason.'
    yield put(updateEndMsg(msg));
    // clean upload status
    yield put(cleanUploadPCBStatus());
    const { RockyReducer: { project: { currentProjectId } } } = yield select();
    if (currentProjectId === uploadProjectId) {
      yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
    }
  }
}

function* beginTranslation(action) {
  const { workflowId, designId, name } = action; //action = { designId, workflowId }

  const { RockyReducer: { RockyUploadReducer: { uploadProjectId } } } = yield select();
  try {
    let res = yield call(getWorkFlow, workflowId);
    if (!res || !res.data || res.data.code === CHECK_FAIL) {
      // yield put(updateMsg(`==> Failed compiling PCB. \n ${res.data.msg} \n ==> Upload failed!`));
      yield put(updateMsg(`==> ${res.data.msg}`));
      // clean upload status
      yield put(cleanUploadPCBStatus());
      const { RockyReducer: { project: { currentProjectId } } } = yield select();
      if (currentProjectId === uploadProjectId) {
        yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
      }
      yield delay(3000);
      yield put(getTranslationMonitor(workflowId));
      return;
    }
    let { code, data } = res.data;
    let { status, currentTaskIndex } = data;
    let prevCurrentTaskIndex = null;
    while (status === RUNNING) {

      yield delay(3000);
      res = yield call(getWorkFlow, workflowId);
      if (!res || !res.data || res.data.code === CHECK_FAIL) {
        //yield put(updateEndMsg(`==> Failed compiling PCB. \n ${res.data.msg} \n ==> Upload failed!`));
        yield put(updateMsg(`==> ${res.data.msg}`));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        const { RockyReducer: { project: { currentProjectId } } } = yield select();
        if (currentProjectId === uploadProjectId) {
          yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
        }
        break;
      }
      code = res.data.code;
      data = res.data.data;
      status = data.status;
      currentTaskIndex = data.currentTaskIndex;
      //get monitor
      yield put(getTranslationMonitor(workflowId));
      //finished "Translation", update current project list
      if (prevCurrentTaskIndex === 1 && currentTaskIndex === 2) {
        const { RockyReducer: { project: { expandedKeys, currentProjectId }, RockyUploadReducer: { currentPCBKey } } } = yield select();
        if (currentProjectId === uploadProjectId && currentPCBKey !== 'replace') {
          yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
          //expand pcb tree
          let Keys = [...expandedKeys];
          if (currentPCBKey && currentPCBKey !== 'replace' && !Keys.includes(currentPCBKey)) {
            Keys.push(currentPCBKey);
          }
          yield put(expandMenu([...Keys]));
        }
      }
      prevCurrentTaskIndex = data.currentTaskIndex;
      if (code === SUCCESS) {
        const progress = parseInt(data.progress);
        const running = updateTranslationProgress({ progress });
        yield put(running);
      } else if (code === CHECK_FAIL) {
        /* yield put(updateMsg(`==> Failed compiling PCB. \n ${res.data.msg} \n ==> Upload failed!`));
        // clean upload status
        yield put(cleanUploadPCBStatus()); */
        break;
      }
    }
    if (code === SUCCESS) {

      if (status === SUCCEED) {
        const succeeded = updateTranslationProgress({ progress: 100 });
        yield put(succeeded);
        const { RockyReducer: { project: { expandedKeys, currentProjectId, projectVersion }, RockyUploadReducer: { currentPCBKey } } } = yield select();
        if (currentProjectId === uploadProjectId) {
          // replace PCB
          if (currentPCBKey === 'replace') {
            yield call(replacePCB, { designId });
          }
          const isUploadPackage = projectVersion === PROJECT_V2 && name === "Package" ? true : false;
          yield put(openProject({ id: uploadProjectId, afterTranslation: true, isUploadPackage }));

          //expand pcb tree
          let Keys = [...expandedKeys];
          if (currentPCBKey && currentPCBKey !== 'replace' && !Keys.includes(currentPCBKey)) {
            Keys.push(currentPCBKey);
          }
          yield put(expandMenu([...Keys]));
        }
        yield delay(800);
        // clean upload status
        yield put(cleanUploadPCBStatus());
      }

      if (status === FAILED) {
        const msg = `==> ${data.message}`;
        yield put(updateEndMsg(msg));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        const { RockyReducer: { project: { currentProjectId } } } = yield select();
        if (currentProjectId === uploadProjectId) {
          yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
        }
      }

      if (status === CANCEL) {
        const msg = `==> Upload PCB closed.`;
        yield put(updateEndMsg(msg));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        const { RockyReducer: { project: { currentProjectId } } } = yield select();
        if (currentProjectId === uploadProjectId) {
          yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
        }
      }

      yield delay(3000);
      yield put(getTranslationMonitor(workflowId));
    } else if (code === CHECK_FAIL) {
      yield put(updateEndMsg(`==> Failed compiling PCB. \n ${res.data.msg} \n ==> Upload failed!`));
      // clean upload status
      yield put(cleanUploadPCBStatus());
      const { RockyReducer: { project: { currentProjectId } } } = yield select();
      if (currentProjectId === uploadProjectId) {
        yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
      }
      yield delay(3000);
      yield put(getTranslationMonitor(workflowId));
      return;
    }

  } catch (error) {
    const msg = `==> Failed compiling PCB.`;
    yield put(updateEndMsg(msg));
    // clean upload status
    yield put(cleanUploadPCBStatus());
    const { RockyReducer: { project: { currentProjectId } } } = yield select();
    if (currentProjectId === uploadProjectId) {
      yield put(openProject({ id: uploadProjectId, afterTranslation: true }));
    }
    console.error(error);
  }
}

const KEYARR = ['Statistics', 'Total counts', 'Layers:', 'Trace length',
  'Net histograms', 'Polygon edges', 'Pins:', 'Vias:'];
function filterContent(log) {
  try {
    if (KEYARR === 'undefined' || !(KEYARR instanceof Array) || KEYARR.length < 1) return false;
    const arrTxt = KEYARR.join('|'),
      regObj = new RegExp(arrTxt, 'ig');
    const _match = log.match(regObj);
    if (_match && _match.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
}

function* cancelUploadPCBWorkflow(action) {
  const { workflowId } = action;
  yield call(cancelWorkflow, workflowId);
  yield put(cleanUploadPCBStatus());
}

function* _getTranslationMonitor(action) {
  const { workflowId } = action;
  let monitor = yield call(getMonitor, workflowId);
  let _monitor = [], _debugMonitor = [];

  const progress = /[0-9]+[%]/g;
  for (let item of monitor) {

    // Filter progress bar
    const _match = item.log.match(progress);
    if (_match && _match.length > 0) {
      continue;
    }

    // Filter '' string
    if (!item.log) {
      continue;
    }

    // Filter debug log
    if (filterContent(item.log)) {
      _debugMonitor.push(item);
      continue;
    }

    _monitor.push(item);
  };
  yield put(translationMonitor(_monitor));
  yield put(translationDebug(_debugMonitor));
}

function* uploadSaga() {
  yield takeEvery(TRANSLATION_FLOW, pcbTranslationFlow);
  yield takeEvery(CANCEL_UPLOAD_WORKFLOW, cancelUploadPCBWorkflow);
  yield takeEvery(GET_TRANSLATION_MONITOR, _getTranslationMonitor)
}

export default uploadSaga;