import React, { Component, Fragment } from 'react';
import SimulationHeader from './SimulationHeader';
import SimulationContent from './SimulationContent';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';
class SimulationMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      topWidth: 666,
      verifications: [],
      monitorMenu: null
    };
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { topWidth } = this.state;
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }

    const { tabVisible, currentVerificationId } = this.props;
    if (tabVisible && currentVerificationId !== prevProps.currentVerificationId) {
      this.setState({ monitorMenu: currentVerificationId });
    }
  }

  cancelProgress = (e) => {
    e.stopPropagation();
  }

  changeVerification = (key, verificationKey = "name") => {
    const { verifications = [] } = this.props;
    const find = verifications.find(item => item[verificationKey] === key);
    if (!find) {
      return;
    }
    //if verificationKey is not name, change name is verificationKey value
    //eg: multi pcb channel children single channel, name change to pcb name
    this.props.updateVerification({
      ...find,
      name: find[verificationKey]
    });
  }

  cancelProgress = (e) => {
    e.stopPropagation();
    //cancel simulation
    const { currentVerificationId } = this.props;
    this.props._cancelVerificationWorkflow(currentVerificationId);
  }

  changeMonitorMenu = (params) => {

    this.setState({
      monitorMenu: params ? params.key : ""
    })
  }

  getMonitorList = (monitorMenu) => {
    const { monitorList, logList, currentVerificationId } = this.props;

    const _monitorList = monitorList && monitorList.length ? monitorList : logList || [];
    const ids = _monitorList.map(item => item.verificationId);
    const _monitorMenu = monitorMenu && ids.includes(monitorMenu) ? monitorMenu : currentVerificationId;
    return { _monitorMenu, _monitorList: _monitorList || [] };
  }

  render() {
    const { topWidth, monitorMenu } = this.state;

    const { _monitorMenu, _monitorList } = this.getMonitorList(monitorMenu);

    return (
      <Fragment>
        <SimulationHeader
          {...this.props}
          topWidth={topWidth}
          changeVerification={this.changeVerification}
          cancelProgress={this.cancelProgress}
          monitorMenu={_monitorMenu}
          displayMonitorList={_monitorList}
          changeMonitorMenu={this.changeMonitorMenu}
        />
        <SimulationContent
          {...this.props}
          topWidth={topWidth}
          monitorMenu={_monitorMenu}
          displayMonitorList={_monitorList}
        />
      </Fragment>
    )
  }
}

export default SimulationMonitor;