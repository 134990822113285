export
  const DDR = "DDR",
  PCIE = "PCIe",
  HDMI = "HDMI",
  DP = "DP",
  FLASH = "FLASH",
  LED = "LED",
  ETHERNET = "Ethernet",
  MIPI = "MIPI",
  USB = "USB",
  GENERIC = "Generic",
  CPHY = 'CPHY',
  NET_TYPES = [DDR, PCIE, HDMI, DP, FLASH, LED, ETHERNET, MIPI, USB, CPHY];

export
  const I2CI3C = 'I2C/I3C',
  SPIQSPIeSPI = 'SPI/QSPI/eSPI',
  UART = 'UART',
  I2S = 'I2S',
  CLKTYPE = 'CLK',
  CUSTOM = 'Custom',
  COMPSETTING = 'compSetting',
  SPI_TYPES = ['SPI', 'QSPI', 'ESPI'],
  SPI = "SPI",
  QSPI = "QSPI",
  ESPI = "ESPI",
  I2C = 'I2C',
  I3C = 'I3C',
  SPMI = "SPMI",
  INTERFACE_TYPES = [I2CI3C, SPIQSPIeSPI, UART, I2S, CLKTYPE, SPMI, CUSTOM, COMPSETTING],
  INTERFACE_TYPE_LIST = [I2C, I3C, SPI, QSPI, ESPI, UART, I2S, CLKTYPE, SPMI, CUSTOM];