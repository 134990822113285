import { IBIS, SPICE } from "../../../constants/libraryConstants";
import { END_TO_END_CHANNEL, PCB_CHANNEL } from "../../../constants/treeConstants";
import { getAMIComponents } from "../channel/AMIModelHelper";
import libraryConstructor from "../library/libraryConstructor";

class HspiceConfig {
  constructor({
    controller,
    device,
    signals,
    interfaceType = PCB_CHANNEL,
    controllerChannel = "",
    deviceChannel = "",
    symbolRate = "1 Gsps",
    initialDelay = "0 nsec",
    timeStep = "0.01 nsec",
    cycles = 200,
    hspiceCores = 2,
    eyeMask = { libraryId: "" },
    userDefinedNetlist = { libraryId: "" },
    testMode
  }) {
    this.symbolRate = symbolRate;
    this.initialDelay = initialDelay;
    this.timeStep = timeStep;
    this.cycles = cycles;
    this.hspiceCores = hspiceCores;
    this.eyeMask = eyeMask;
    this.userDefinedNetlist = userDefinedNetlist;
    this.testMode = typeof testMode === 'boolean' ? testMode : false;
    this.controller = controller;
    this.device = device;
    if (interfaceType === END_TO_END_CHANNEL) {
      this.controllerChannel = controllerChannel || { channelId: "", designId: "", designName: "" };
      this.deviceChannel = deviceChannel || { channelId: "", designId: "", designName: "" };
    }
    this.signals = (signals || []).map(signal => {
      return new HspiceSignalConfig({ ...signal })
    })
  }

  setTerminationValue(signalName, pin, value, isCap) {
    const index = this.signals.findIndex(item => item.signalName === signalName);
    if (index < 0) return this;
    this.signals[index].setTerminationValue(pin, value, isCap);
    return this;
  }

  // The replacement target is another comp exchange of controller and post-processing of device, replacing the data inside txModel, rxModel, ctleModel
  handleCompnentSwap(controllerComp, deviceComp) {
    this.controller = controllerComp.name;
    this.device = deviceComp.name;

    this.signals.forEach(signal => {
      if (controllerComp && controllerComp.pins) {
        signal.replacePinInModel('txModel', controllerComp);
      }
      if (deviceComp && deviceComp.pins) {
        signal.replacePinInModel('rxModel', deviceComp);
      }
    });

    return this;
  }

  // The replacement object is not another comp
  handleComponentChange(currType, resComp) {
    this[currType] = resComp.name
    const eleName = currType === 'controller' ? 'txModel' : 'rxModel'
    this.signals.forEach(signal => {
      signal.replacePinInModel(eleName, resComp);
    });

    return this;
  }

  setModelBySignalName(modelType, signalName, model) {
    const index = this.signals.findIndex(item => item.signalName === signalName);
    if (index < 0) return this;
    // Filter the files element as an empty object {}
    const files = [...model.files].filter(item => {
      return Object.keys(item).length > 0;
    })
    this.signals[index][modelType] = { ...model, files };
    return this;
  }

  setTxModelNode(signalName, pin, node) {
    const index = this.signals.findIndex(item => item.signalName === signalName);
    if (index < 0) return this;
    const pairIndex = this.signals[index].txModel.pairs.findIndex(item => item.pin === pin);
    this.signals[index].txModel.pairs[pairIndex].node = node;
    return this;
  }

  setCtleModelNode(signalName, pin, component, node) {
    const index = this.signals.findIndex(item => item.signalName === signalName);
    if (index < 0) return this;
    const pairIndex = this.signals[index].ctleModel.pairs.findIndex(item => item.pin === pin && item.component === component);
    this.signals[index].ctleModel.pairs[pairIndex].node = node;
    return this;
  }

  applyToAllSignals(modelType, model) {
    // Except that the pin and the net are all the same
    this.signals.forEach(signal => {
      signal = signal.setModel(modelType, model)
    });
    return this;
  }

  // add or delete rxCpad or ctle
  setModelType(modelType, isShow) {
    this.signals.forEach(signal => {
      signal = signal.setModelType(modelType, isShow)
    });
    return this;
  }
}

class HspiceSignalConfig {
  constructor({
    interfaceType = PCB_CHANNEL,
    signalName,
    controller = "",
    controllerChannelId = "",
    device = "",
    deviceChannelId = "",
    txModel,
    rxModel,
    rxCpadModel,
    ctleModel,
    termination,
    components = []
  }) {
    this.signalName = signalName || "";
    const modelList = { txModel, rxModel, rxCpadModel, ctleModel, termination };

    const controllerPins = (components.find(item => item.name === controller) || {}).pins || [];
    const devicePins = (components.find(item => item.name === device) || {}).pins || [];
    for (const eleName of ['txModel', 'rxModel', 'rxCpadModel', 'ctleModel', 'termination']) {
      if (modelList[eleName]) {
        this[eleName] = modelList[eleName];
      } else {
        const _channelId = eleName === 'txModel' ? controllerChannelId : deviceChannelId;
        const pins = eleName === 'txModel' ? controllerPins
          : ['rxModel', 'rxCpadModel', 'termination'].includes(eleName) ? devicePins
            : [...controllerPins, ...devicePins];
        this.initModel({ interfaceType, channelId: _channelId, eleName, pins });
      }
    }
  }

  initModel({ interfaceType, channelId, eleName, pins = [] }) {
    switch (eleName) {
      case 'txModel':
        this[eleName] = {
          // {fileName, libraryId, subckt}
          files: [],
          pairs: pins.map(pinInfo => ({ node: "", pin: pinInfo.pin, net: pinInfo.net })),
          type: SPICE // txModel: spice
        }
        break;
      case 'rxModel':
        this[eleName] = {
          // {fileName, libraryId, subckt, modelKey, path}
          files: [],
          // libraryId, fileName, subckt, Locate file from the files
          pairs: pins.map(pinInfo => ({ node: "", pin: pinInfo.pin, net: pinInfo.net })),
          // libraryId，fileName, Locate file from the files
          pinModels: pins.map(pinInfo => ({
            pin: pinInfo.pin,
            net: pinInfo.net,
            corner: "typ",
            pullUpVoltage: "",
            pullDownVoltage: "0",
            libraryId: "",
            fileName: "",
            modelName: "",
            modelType: "",
            powerOff: false
          })),
          type: IBIS // spice / ibis
        }
        break;
      case 'rxCpadModel':
        this[eleName] = {
          // {fileName, libraryId, subckt, modelKey}
          files: [],
          pairs: pins.map(pinInfo => ({ node: "", pin: pinInfo.pin, net: pinInfo.net })),
          pinModels: pins.map(pinInfo => ({ cpadValue: "1 pF", pin: pinInfo.pin, net: pinInfo.net })),
          type: 'value' // txModel: spice / ibis, rxCapdModel: spice / value
        }
        break;
      case 'ctleModel':
        this[eleName] = {
          // {fileName, libraryId, subckt, modelKey}
          files: [],
          pairs: pins.map(pinInfo => ({ node: "", pin: pinInfo.pin, component: pinInfo.component, net: pinInfo.net })),
          type: SPICE// spice / ibis
        }
        break;
      case 'termination':
        this[eleName] = {
          // {fileName, libraryId, subckt}
          files: [],
          pinModels: pins.map(pinInfo => ({ impedance: "50 Ohm", pin: pinInfo.pin, net: pinInfo.net })),
          terminationCap: "22 pF",
          type: 'value' //  spice / value
        }
        break;
      default:
        break;
    }
    if (interfaceType === END_TO_END_CHANNEL) {
      this[eleName].channelId = channelId || "";
    }
  }

  setTerminationValue(pin, value, isCap) {
    if (isCap) {
      this.termination.terminationCap = value;
    } else {
      this.termination.pinModels = this.termination.pinModels.map(pinModel => {
        if (pinModel.pin === pin) {
          return {
            ...pinModel,
            impedance: value
          }
        }
        return pinModel
      })
    }
  }

  replacePinInModel(eleName, component) {
    if (this[eleName].pinModels) {
      this[eleName].pinModels.forEach(pinModel => {
        const pin = component.pins.find(pinInfo => pinInfo.net === pinModel.net) || {};
        pinModel.pin = pin.pin;
      })
    }

    if (this[eleName].pairs) {
      this[eleName].pairs.forEach(pair => {
        const pin = component.pins.find(pinInfo => pinInfo.net === pair.net) || {};
        pair.pin = pin.pin;
      })
    }
  }

  // apply to all set model
  setModel(eleName, model) {
    const { files, pairs, type, pinModels } = model;
    this[eleName].files = [...files];
    this[eleName].type = type;

    switch (eleName) {
      case 'rxCpadModel':
      case 'rxModel':
        this[eleName].pinModels = this[eleName].pinModels.map((pinModel, index) => ({
          ...pinModels[index],
          pin: pinModel.pin,
          net: pinModel.net
        }))
        this[eleName].pairs = this[eleName].pairs.map((pair, index) => ({
          ...pairs[index],
          pin: pair.pin,
          net: pair.net,
        }))
        break;
      case 'txModel':
        this[eleName].pairs = this[eleName].pairs.map((pair, index) => ({
          ...pairs[index],
          pin: pair.pin,
          net: pair.net,
        }))
        break;
      case 'ctleModel':
        this[eleName].pairs = this[eleName].pairs.map((pair, index) => ({
          ...pairs[index],
          pin: pair.pin,
          net: pair.net,
          component: pair.component
        }))
        break;
      default:
        break;
    }

    return this;
  }

  // rxCpad and ctle add or remove
  setModelType(eleName, value) {
    if (!value) {
      this[eleName].type = 'None';
    } else {
      switch (eleName) {
        case 'rxCpadModel':
          this[eleName].type = this[eleName].files.length > 0 ? SPICE : 'value';
          break;
        case 'rxModel':
          if (this[eleName].files.length > 0) {
            this[eleName].type = libraryConstructor.checkFile(IBIS, this[eleName].files[0].libraryId) ? IBIS : SPICE;
          } else {
            this[eleName].type = IBIS;
          }
          break;
        case 'ctleModel':
          this[eleName].type = SPICE;
          break;
        default:
          break;
      }
    }
    return this;
  }

  checkModel(controller, device, components = []) {
    // Check if pin and net are consistent with those in components
    const controllerPins = (components.find(item => item.name === controller) || {}).pins || [];
    const devicePins = (components.find(item => item.name === device) || {}).pins || [];

    for (const eleName of ['txModel', 'rxModel', 'rxCpadModel', 'ctleModel', 'termination']) {
      const pins = eleName === 'txModel' ? controllerPins
        : ['rxModel', 'rxCpadModel', 'termination'].includes(eleName) ? devicePins
          : [...controllerPins, ...devicePins];
      const { pairs, pinModels } = this[eleName] || {};
      if (this[eleName] && ((pairs && pairs.length > 0) || (pinModels && pinModels.length > 0))) {
        if (pairs && pairs.length > 0) {
          const _pairs = pins.map(pinInfo => {
            const pairInfo = pairs.find(pair => pair.pin === pinInfo.pin && pair.net === pinInfo.net);
            if (!pairInfo) {
              if (eleName === 'ctleModel') {
                return { node: "", pin: pinInfo.pin, net: pinInfo.net, component: pinInfo.component }
              } else {
                return { node: "", pin: pinInfo.pin, net: pinInfo.net }
              }
            }
            return pairInfo;
          })
          this[eleName].pairs = _pairs;
        }
        if (pinModels && pinModels.length > 0) {
          const _pinModels = pins.map(pinInfo => {
            const pinModelInfo = pinModels.find(item => item.pin === pinInfo.pin && item.net === pinInfo.net);
            if (!pinModelInfo) {
              if (eleName === 'termination') {
                return { impedance: "50 Ohm", pin: pinInfo.pin, net: pinInfo.net };
              } else if (eleName === 'rxCpadModel') {
                return { cpadValue: "1 pF", pin: pinInfo.pin, net: pinInfo.net };
              } else {
                return { node: "", pin: pinInfo.pin, net: pinInfo.net };
              }
            }
            return pinModelInfo;
          })
          this[eleName].pinModels = _pinModels;
        }
      } else {
        this.initModel({ eleName, pins });
      }
    }
    return this;
  }

  checkPin(newPin, prevPin) {
    for (const eleName of ['txModel', 'rxModel', 'rxCpadModel', 'ctleModel', 'termination']) {
      if (this[eleName]) {
        const { pairs, pinModels } = this[eleName];
        if (pairs && pairs.length > 0) {
          const _pairs = pairs.map(pair => {
            if (pair.pin === prevPin.pin && pair.net === prevPin.net) {
              if (eleName === 'ctleModel') {
                return { node: "", pin: newPin.pin, net: newPin.net, component: newPin.component };
              } else {
                return { node: "", pin: newPin.pin, net: newPin.net };
              }
            }
            return pair;
          })
          this[eleName].pairs = _pairs;
        }
        if (pinModels && pinModels.length > 0) {
          const _pinModels = pinModels.map(pinModel => {
            if (pinModel.pin === prevPin.pin && pinModel.net === prevPin.net) {
              if (eleName === 'termination') {
                return { impedance: "50 Ohm", pin: newPin.pin, net: newPin.net };
              } else if (eleName === 'rxCpadModel') {
                return { cpadValue: "1 pF", pin: newPin.pin, net: newPin.net };
              } else {
                return { node: "", pin: newPin.pin, net: newPin.net };
              }
            }
            return pinModel;
          })
          this[eleName].pinModels = _pinModels;
        }
      }
    }
    return this;
  }

  checkComp(controller, device, components = [], replaceController, replaceDevice) {
    const controllerPins = (components.find(item => item.name === controller) || {}).pins || [];
    const devicePins = (components.find(item => item.name === device) || {}).pins || [];
    if (replaceController && controllerPins.length > 0) {
      for (const eleName of ['txModel', 'ctleModel']) {
        this.initModel({ eleName, pins: controllerPins })
      }
    }

    if (replaceDevice && devicePins.length > 0) {
      for (const eleName of ['rxModel', 'rxCpadModel', 'ctleModel', 'termination']) {
        this.initModel({ eleName, pins: devicePins })
      }
    }

    return this;
  }
}

// interfaceType: PCB_CHANNEL
function initChannelHspiceConfig({ components, signals, interfaceType }) {
  const { controller, device } = getAMIComponents(components);
  const adsSignals = initDefaultHspiceSignals({
    signals: signals,
    controller,
    device,
    components
  });
  const hspiceConfig = new HspiceConfig({
    signals: adsSignals,
    controller,
    device
  });
  return hspiceConfig;
}

function initDefaultHspiceSignals({
  signals,
  controller,
  device,
  components
}) {
  let hspiceSignals = [];
  // const _signals = signals.filter(item => selectedSignals.includes(item.name));
  for (let signal of signals) {
    const _components = handleComponents(components, signal);
    hspiceSignals.push(
      new HspiceSignalConfig({
        signalName: signal.name,
        controller,
        device,
        components: _components
      })
    )
  }
  return hspiceSignals;
}

function packageHspiceConfig(hspiceConfig, interfaceType = PCB_CHANNEL) {
  return new HspiceConfig({ ...hspiceConfig, interfaceType });
}

function handleComponents(components = [], signal = {}) {
  const { nets_A, nets_B, nets_C } = signal;

  const _components = components.map(comp => {
    let a_pin, b_pin, c_pin;
    for (let item of comp.pins) {
      if (nets_A.includes(item.net) && !a_pin) {
        a_pin = { ...item, component: comp.name }
      }
      if (nets_B.includes(item.net) && !b_pin) {
        b_pin = { ...item, component: comp.name };
      }
      if (nets_C.includes(item.net) && !c_pin) {
        c_pin = { ...item, component: comp.name };
      }

      if (a_pin && b_pin && c_pin) {
        break;
      }
    }
    return {
      name: comp.name,
      pins: [a_pin || {}, b_pin || {}, c_pin || {}]
    }
  })

  return _components;
}

export {
  HspiceConfig,
  HspiceSignalConfig,
  initChannelHspiceConfig,
  initDefaultHspiceSignals,
  packageHspiceConfig,
  handleComponents
}