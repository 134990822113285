import React, { Component, Fragment, createRef } from 'react';
import { Switch, Input } from 'antd';
import * as portCanvas from '@/services/LayoutCanvas/PortsCanvas';
import LayoutData from '@/services/data/LayoutData';
import makeCancelable from '@/services/api/makeCancelable';
import { getPortData } from '@/services/helper/portCanvasHelper';
import { nodesCanvas, getTransformByTriMapping, getPinNodeMapping, downloadTransFromSpiceFile } from '../../../services/LayoutCanvas/SpiceNodesCanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus, faArrowsAlt, faDownload, /* faSyncAlt, */ } from '@fortawesome/free-solid-svg-icons';
import TriMapping from './triMapping';
import { SortFn } from '../../../services/helper/sort';
import DownloadSpicePanel from './DownloadSpicePanel';
import getDevelopMode from '@/services/api/cookies';
import MatchErrorPanel from './matchErrorPanel';
import _ from "lodash";
import './index.css';
import { getPinLocation } from '../../../services/LayoutCanvas/PortsCanvas';
import { numberCheck } from '../../../services/helper/dataProcess';
import auroraDBJson from '../../../services/Designs/auroraDbData';

class CPMCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNames: true,
      showNodes: true,
      DesignData: null,
      layerList: [],
      powerPins: {},
      referencePins: {},
      TriMappingVisible: false,
      triPairs: this.getDefaultPairs(),
      isUpdateTriPairs: false,
      matchErrorVisible: false,
      nodeRadius: null,
      nodeRadiusInput: null,
      signalPins: {}
    }
    this.locationRef = createRef();
    this.designSvgRef = createRef();
    this.modelSvgRef = createRef();
    this.modelLocationRef = createRef();
    this.develop = getDevelopMode();
  }

  getDefaultPairs = () => {
    return ["A", "B", "C"].map(item => {
      return {
        index: item,
        pin: {},
        node: {}
      }
    })
  }

  componentDidMount = () => {
    window.addEventListener("contextmenu", this.rightMouseContextmenu, true)
    this.props.onRef && this.props.onRef(this)
    this.renderDesign();
    this.setState({
      triPairs: this.props.triMatchPairs ? JSON.parse(JSON.stringify(this.props.triMatchPairs)) : this.getDefaultPairs()
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('contextmenu', this.rightMouseContextmenu, true);
  }

  rightMouseContextmenu = (event) => {
    if (event && event.target) {
      const parentModelEle = document.getElementById("cpm-setup-model-canvas-main");
      const parentDesignEle = document.getElementById("cpm-setup-design-canvas-main");

      if (parentModelEle.contains(event.target) || parentDesignEle.contains(event.target)) {
        event.preventDefault();
        return false;
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { clearMappingStatus, pinMapping, component, isShow, showPinNodeObj, transforms, isUpdateSsnPackageChannel } = this.props;
    const { powerPins } = this.state;
    if (clearMappingStatus && clearMappingStatus !== prevProps.clearMappingStatus) {
      this.props.updateClearMappingStatus(false);
      if (!pinMapping.length) {
        portCanvas.removeModelPoints(component);
      }
    }
    if (isShow && isShow !== prevProps.isShow) {
      this.props.updateShowStatus(false);
      const prevPins = prevProps.showPinNodeObj ? prevProps.showPinNodeObj.pins || [] : [];
      let prevPowerPins = [], prevRefPins = [];
      if (prevPins.length) {
        const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
        prevPins.forEach(item => { return power.includes(item) ? prevPowerPins.push(item) : prevRefPins.push(item) })
      }

      portCanvas.highlightPins(showPinNodeObj.pins || [], component, prevPowerPins, prevRefPins);
      nodesCanvas.highlightPoints(showPinNodeObj.nodes || []);
    }

    const { drawPortTransforms, drawNodeTransforms } = this.state;
    //default open, draw points, nodesCanvas and portsCanvas have been initialized
    if (drawPortTransforms && drawNodeTransforms && transforms && Object.keys(transforms).length) {
      this.setState({
        drawPortTransforms: false,
        drawNodeTransforms: false
      })
      this.drawTransformsPoints()
    }

    if (isUpdateSsnPackageChannel && isUpdateSsnPackageChannel !== prevProps.isUpdateSsnPackageChannel) {
      this.renderDesign()
    }
  }

  renderDesign = async () => {
    const { designId, PowerNets, ReferenceNets, component, SignalNets } = this.props;
    const compInfo = auroraDBJson.getComponent(designId, component);
    const load = compInfo && compInfo.layer ? [compInfo.layer.replace('COMP_', '')] : true;
    try {
      await LayoutData.LoadLayoutDB(designId, load);
    } catch (error) {
      console.error(error);
      return;
    }
    let DesignData = LayoutData.getLayout(designId);
    if (this.props.DesignInfo) {
      this.pcbInfo = this.props.DesignInfo.getPCBInfo(designId) || {};
    }
    let Signal = SignalNets && SignalNets.length ? SignalNets.map(item => item.nets).flat(2) : [];
    let filterData = getPortData(DesignData, component, PowerNets, ReferenceNets, Signal);

    if (!filterData || !filterData.layerList || !filterData.layerList.length) {
      try {
        await LayoutData.LoadLayoutDB(designId, false);
      } catch (error) {
        console.error(error);
        return;
      }
      DesignData = LayoutData.getLayout(designId);
      Signal = SignalNets && SignalNets.length ? SignalNets.map(item => item.nets).flat(2) : [];
      filterData = getPortData(DesignData, component, PowerNets, ReferenceNets, Signal);
    }

    const { layerList, powerPins, referencePins, data, signalPins } = filterData;
    const layoutUnit = DesignData.mUnits === "mils" ? "mil" : DesignData.mUnits;
    this.designUnit = layoutUnit;
    const layoutComponent = LayoutData.getComponent(designId, component) || {};
    const pinList = layoutComponent.mPinsLocationList || [];
    const compPinsNets = LayoutData.getCompPinsNets(designId) || {};
    const diePinsNets = compPinsNets[component] || {};
    const powerPinList = pinList.map(item => {
      const net = diePinsNets[item.pinNumber] ? diePinsNets[item.pinNumber].mName : null;
      if (net && [...PowerNets, ...ReferenceNets, ...Signal].includes(net)) {
        let location = item.mLocation && (!item.mLocation.hasOwnProperty("xc") || !item.mLocation.xc) ? getPinLocation(item.canvasObj) : item.mLocation;
        if (!location) {
          location = item.mLocation
        }
        return { ...item, mLocation: { xc: location.xc || location.x, yc: location.yc || location.y, r: location.r }, net, netType: PowerNets.includes(net) ? "Power" : Signal.includes(net) ? "Signal" : "Ground" }
      }
      return false
    }).filter(item => !!item)

    const findPinLocation = powerPinList[0];
    this.r = findPinLocation && findPinLocation.mLocation && findPinLocation.mLocation.r ? findPinLocation.mLocation.r : 0;
    this.setState({
      DesignData: data,
      layerList,
      powerPins,
      referencePins,
      layoutUnit,
      powerPinList,
      compPinsNets,
      signalPins
    }, () => {
      this.renderLayout()
      this.initModel()
    })
  }

  canvasSelect = (canvasObj) => {
  }

  changeMouse = (x, y, type) => {
    this.setState({ [type]: x.toFixed(2) + ', ' + y.toFixed(2) });
  }

  renderLayout() {
    const { DesignData, layerList } = this.state;
    if (!DesignData) {
      return;
    }
    const events = {
      click: this.canvasSelect,
      mousemove: (x, y) => this.changeMouse(x, y, "location"),
      selectPin: (point, type) => this.selectPinAndNode(point, type, "pin"),
      removePin: (point, type) => this.removePinAndNode(point, type, "pin"),
      matchClick: this.matchClick
    }

    if (this.designSvgRef && this.designSvgRef.current && this.designSvgRef.current.children && this.designSvgRef.current.children[0]) {
      this.designSvgRef.current.removeChild(this.designSvgRef.current.children[0])
    }
    portCanvas.initLayout(this.designSvgRef.current, DesignData, this.locationRef.current, { ...events }, true);
    portCanvas.updateLayers(layerList, []);
    this.setPinColor();
    this.showPinNames(true);
    portCanvas.updatePortSetupEventFn({ ...events });
    //draw default mapping
    const { triMatchPairs, component, pinMapping, SignalNets } = this.props;
    let _triMatchPairs = triMatchPairs ? JSON.parse(JSON.stringify(triMatchPairs)) : [];
    const notDisplayMatch = pinMapping && pinMapping.length ? true : false;
    _triMatchPairs.length && portCanvas.reDrawAllTriPoints(_triMatchPairs, component, notDisplayMatch);
    if (SignalNets && SignalNets.length && pinMapping && pinMapping.length && component) {
      const pinList = pinMapping.filter(item => item.pin).map(item => item.pin)
      // zoom in pin
      portCanvas.zoomInFromPins(component, pinList);
    }


    this.setState({
      triPairs: _triMatchPairs || this.getDefaultPairs(),
      TriMappingVisible: triMatchPairs.length ? false : true,
      drawPortTransforms: true
    })
  }

  drawTransformsPoints = () => {
    const { component, transforms, pinMapping, triMatchPairs } = this.props;
    if (transforms && triMatchPairs && triMatchPairs.length) {
      const newPoints = nodesCanvas.getNewModelPointsByTransForm(transforms);
      let points = this.getNewPoints(newPoints, pinMapping)
      newPoints.length && portCanvas.resetModelPoints(points, this.r, component, pinMapping && pinMapping.length ? true : false);
    }
  }

  setPinColor() {
    const { component, PowerNets } = this.props;
    const { powerPins, referencePins, signalPins } = this.state;
    portCanvas.setPinColorByNets({
      PowerPins: powerPins,
      ReferencePins: referencePins,
      chip: component,
      SignalPins: signalPins
    });
    portCanvas.setPowerNetsColor(PowerNets);
  }

  changeShowPinName = (bool, e) => {
    e && e.stopPropagation();
    this.setState({
      showNames: bool
    })
    this.showPinNames(bool);
  }

  showPinNames = (bool) => {
    if (bool) {
      const { component } = this.props;
      const { powerPins, referencePins, signalPins } = this.state;
      const _power = [], _refe = [], _signal = [];
      Object.values(powerPins).forEach(p => _power.push(...p));
      Object.values(referencePins).forEach(p => _refe.push(...p));
      Object.values(signalPins).forEach(p => _signal.push(...p));
      portCanvas.showPinsName({ [component]: [..._power, ..._refe, ..._signal] }, true)
    } else {
      portCanvas.showPinsName({}, false)
    }
  }

  initModel = async () => {
    const { selectFile, designId, component, PowerNets, ReferenceNets, triMatchPairs, pinMapping, SignalNets, writeSelectFile, readSelectFile, product } = this.props;
    if (!selectFile || !selectFile.libraryId || !selectFile.fileName) {
      return;
    }
    const model = await this.props.getCSMCPMSpiceModelList({ libraryId: selectFile.libraryId, fileName: selectFile.fileName });
    if (!model || !model.model || !model.model.CPM || !model.model.CPM.models) {
      return;
    }

    let portList = await this.props.getPortList(writeSelectFile, readSelectFile, product);
    portList = portList.filter(item => item.net)

    let list = portList.length ? [...model.model.CPM.models, ...portList] : [...model.model.CPM.models];
    let nodeList = JSON.parse(JSON.stringify(list))
    this.props.updatePortList(portList, nodeList);

    this.setState({
      model
    })
    const events = {
      mousemove: (x, y) => this.changeMouse(x, y, "modelLocation"),
      selectNode: (point, type) => this.selectPinAndNode(point, type, "node"),
      removeNode: (point, type) => this.removePinAndNode(point, type, "node"),
      matchClick: this.matchClick
    }
    const { DesignData, powerPins, powerPinList } = this.state;
    if (this.modelSvgRef && this.modelSvgRef.current && this.modelSvgRef.current.children && this.modelSvgRef.current.children[0]) {
      this.modelSvgRef.current.removeChild(this.modelSvgRef.current.children[0])
    }
    const compPinsNets = LayoutData.getCompPinsNets(designId);
    const powerPin = powerPins[PowerNets[0]] && powerPins[PowerNets[0]][0] ? powerPins[PowerNets[0]][0] : "";
    const diePinsNets = compPinsNets[component] || {};
    const findPinLocation = powerPinList.find(item => powerPin && item.pinNumber === powerPin);
    this.r = findPinLocation && findPinLocation.mLocation && findPinLocation.mLocation.r ? findPinLocation.mLocation.r : 0;
    this.setState({
      nodeRadius: this.r,
      nodeRadiusInput: this.r
    })
    let points = this.getNewPoints(nodeList || [], pinMapping);
    nodesCanvas.initLayout({
      svgEle: this.modelSvgRef.current,
      layoutDB: DesignData,
      locationSvg: this.modelLocationRef.current,
      events,
      points: points,
      unit: model.model.CPM.unit,
      r: this.r,
      designUnit: DesignData.mUnits,
      pinList: powerPinList,
      diePinsNets,
      PowerNets,
      ReferenceNets,
      SignalNets
    });
    const compModelUnit = nodesCanvas.getCanvasUnit();
    const _nodeList = nodesCanvas.getCanvasPointsData();
    this.changeShowNodeName(true);
    const notDisplayMatch = pinMapping && pinMapping.length ? true : false;
    triMatchPairs.length && nodesCanvas.reDrawAllTriPoints(triMatchPairs || [], notDisplayMatch);

    this.setState({
      drawNodeTransforms: true,
      nodeList: _nodeList,
      cpmModelUnit: compModelUnit
    })
  }

  zoomIn = (type) => {
    if (type) {
      nodesCanvas.zoomIn()
      return
    }
    portCanvas.zoomIn()
  }

  zoomOut = (type) => {
    if (type) {
      nodesCanvas.zoomOut()
      return
    }
    portCanvas.zoomOut()
  }

  fitView = (type) => {
    if (type) {
      nodesCanvas.fitView()
      return
    }
    portCanvas.fitView()
  }

  downloadSpiceFile = () => {
    this.setState({
      downloadSpice: true
    })
  }

  rotate = () => {

  }

  flip = () => {

  }

  matchClick = () => {
    this.matchTriMapping(this.state.triPairs)
  }

  getNewCsmMapping = (signalMapping, csmMappingInfo) => {
    let _csmMappingInfo = signalMapping.map(item => {
      const { nets } = item;
      const findInfo = csmMappingInfo.find(it => it.nets && it.nets[0] && nets.includes(it.nets[0]))
      if (findInfo) {
        return {
          ...findInfo,
          nets: item.nets || [],
          pcbNets: item.pcbNets || [],
          pkgSignal: item.pkgSignal || "",
          pkgPin: item.pkgPin || ""
        }
      }
      return { ...item }
    })
    return _csmMappingInfo;
  }

  matchTriMapping = (triPairs) => {
    const { cpmModelUnit, DesignData, powerPinList } = this.state;
    const { triMatchPairs, component, signalMapping } = this.props;
    let log = [];
    const _triPairs = triPairs.filter(item => item.pin && item.pin.pinNumber && item.node && item.node.node);
    let msg = ""
    if (!_triPairs.length || _triPairs.length !== 3 || _.isEqual(triMatchPairs, _triPairs)) {
      log.push("Die pins and package mapping are the same as last time.");
      return;
    }
    const transforms = getTransformByTriMapping({ triPairs, nodeUnit: cpmModelUnit, designUnit: DesignData.mUnits, log })
    if (transforms.pinIsNotTriangle || transforms.nodeIsNotTriangle) {
      let _msg = transforms.pinIsNotTriangle && transforms.nodeIsNotTriangle ? `Die pins and Package nodes` : (transforms.pinIsNotTriangle ? "Die pins" : "Package nodes");
      msg = `The points of ${_msg} cannot form a triangle.`;
      this.setState({
        msg,
        log,
        matchErrorVisible: true
      })
      this.clearMsg()
      return;
    }
    const newTriMatchPairs = JSON.parse(JSON.stringify(_triPairs));
    log.push(`Pin Circle Radius - ${this.r}`);
    if (transforms) {
      const newPoints = nodesCanvas.getNewModelPointsByTransForm(transforms, log);
      //get pin mapping
      const { pinMapping, isMapping, csmMappingInfo } = getPinNodeMapping({ pinPoints: powerPinList, pkgPoints: newPoints, r: this.r, log, signalMapping });
      if (isMapping) {
        const newCsmMapping = this.getNewCsmMapping(signalMapping, csmMappingInfo)
        this.props.updatePinMapping(pinMapping, newTriMatchPairs, transforms, newCsmMapping);
        let points = this.getNewPoints(newPoints, pinMapping)

        portCanvas.resetModelPoints(points, this.r, component, pinMapping && pinMapping.length ? true : false);
        nodesCanvas.clearMatchIcon()
        msg = "Match pins and nodes successfully!";
      } else {
        this.props.updatePinMapping([], [], transforms);
        portCanvas.removeModelPoints(component);
        msg = "Match pins and nodes failed!";
      }
    } else {
      portCanvas.removeModelPoints(component);
      msg = "Match pins and nodes failed!";
      this.props.updatePinMapping([], [], null);
    }
    this.setState({
      msg,
      log,
      matchErrorVisible: msg.includes("failed") ? true : false
    })
    this.clearMsg()
  }

  clearMsg = () => {
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 4000)
  }

  getNewPoints = (newPoints, pinMapping = []) => {
    let points = [...newPoints]
    if (pinMapping && pinMapping.length) {
      points = points.map(item => {
        const findPin = pinMapping.find(it => it.node === item.node && it.net === item.net);
        return {
          ...item,
          packagePin: findPin && findPin.pin ? findPin.pin : ""
        }
      })
    }
    return points;
  }

  openTriMapping = (e) => {
    e && e.stopPropagation();
    this.setState({
      TriMappingVisible: true
    })
  }

  closeTriMapping = () => {
    this.setState({
      TriMappingVisible: false
    })
  }

  updateTriPairsStatus = (isUpdateTriPairs) => {
    this.setState({
      isUpdateTriPairs
    })
  }

  selectPinAndNode = (point, type, key) => {
    const { triPairs, powerPinList } = this.state;
    let _triPairs = [...triPairs];
    let _point = { ...point };
    if (key === "pin") {
      _point = powerPinList.find(item => item.pinNumber === point.pinNumber);
      if (!_point) {
        return
      }
      _point = JSON.parse(JSON.stringify(_point))
      delete _point.canvasObj;
    }
    const index = _triPairs.findIndex(item => item.index === type);
    if (index > -1) {
      _triPairs[index][key] = { ..._point }
    } else {
      _triPairs.push({
        index: type,
        pin: {},
        node: {},
        [key]: { ..._point }
      })
    }
    SortFn(_triPairs, ["A", "B", "C"], "index")
    this.setState({
      triPairs: _triPairs,
      isUpdateTriPairs: true,
      TriMappingVisible: true
    }, () => {
      nodesCanvas.updateTriPairs(_triPairs);
      portCanvas.updateTriPairs(_triPairs)
    })
  }

  removePinAndNode = (point, type, key) => {
    const { triPairs } = this.state;
    let _triPairs = [...triPairs];
    const index = _triPairs.findIndex(item => item.index === type);
    if (index < 0) {
      return
    }
    _triPairs[index] = { ..._triPairs[index], [key]: {} }
    this.setState({
      triPairs: _triPairs,
      isUpdateTriPairs: true
    }, () => {
      nodesCanvas.updateTriPairs(_triPairs);
      portCanvas.updateTriPairs(_triPairs)
    })
  }

  changeShowNodeName = (bool, e) => {
    e && e.stopPropagation();
    this.setState({
      showNodes: bool
    })
    this.showNodeNames(bool);
  }

  showNodeNames = (bool) => {
    if (bool) {
      nodesCanvas.showNodesName(true)
    } else {
      nodesCanvas.showNodesName(false)
    }
  }

  updateTriPairs = (pairs, clearAll) => {
    this.setState({
      triPairs: pairs
    }, () => {
      nodesCanvas.updateTriPairs(pairs);
      portCanvas.updateTriPairs(pairs)
    })
    if (clearAll) {
      const { component, transforms } = this.props;
      portCanvas.removeAllTriPoints();
      nodesCanvas.removeAllTriPoints();
      portCanvas.removeModelPoints(component);
      portCanvas.removeModelPoints(component);
      this.props.updatePinMapping([], [], transforms);
    } else {
      const { component } = this.props;
      portCanvas.reDrawAllTriPoints(pairs, component);
      nodesCanvas.reDrawAllTriPoints(pairs);
    }
  }

  closeDownloadPanel = () => {
    this.setState({
      downloadSpice: false
    })
  }

  downloadLog = () => {
    const { log } = this.state;
    downloadTransFromSpiceFile(log || [], "match.log");
  }

  closeMatchErrorPanel = () => {
    this.setState({
      matchErrorVisible: false
    })
  }

  changeNodeRadius = (e) => {
    this.setState({
      nodeRadiusInput: e.target.value
    })
  }

  nodeRadiusBlur = (e) => {
    const value = e.target.value;
    if (numberCheck(value)) {
      this.setState({
        nodeRadiusInput: this.state.nodeRadius
      })
      return;
    }
    const { triPairs, showNodes } = this.state;
    this.setState({
      nodeRadius: value,
      nodeRadiusInput: value
    }, () => {
      const { pinMapping } = this.props;
      const notDisplayMatch = pinMapping && pinMapping.length ? true : false;
      nodesCanvas.renderPoints(value)
      nodesCanvas.reDrawAllTriPoints(triPairs, notDisplayMatch);
      showNodes && nodesCanvas.showNodesName(true)
    })
  }

  removeCanvas = () => {
    portCanvas.removeCanvas('cpmCanvas')
  }

  autoMatchPairs = () => {
    const { nodeList = [], powerPinList, triPairs = [] } = this.state;
    const { component } = this.props;
    let _triPairs = [...triPairs], triCounter = 0, lastLocation = null;

    if (!nodeList.length) {
      return;
    }

    for (let node of nodeList) {
      const nodeName = node.node;
      const location = node.location

      if (lastLocation) {
        if ((lastLocation[0] && location[0] && lastLocation[0] === location[0]) || (lastLocation[1] && location[1] && lastLocation[1] === location[1])) {
          continue;
        }
      }
      const pin = portCanvas.getPinInfo(component, nodeName);
      if (!pin) {
        continue;
      }

      let point = powerPinList.find(item => item.pinNumber === pin.pinNumber);
      if (!point) {
        return
      }
      point = JSON.parse(JSON.stringify(point))
      delete point.canvasObj;
      triCounter = triCounter + 1;
      lastLocation = JSON.parse(JSON.stringify(location))
      const type = triCounter === 1 ? 'A' : triCounter === 2 ? 'B' : 'C';
      const index = _triPairs.findIndex(item => item.index === type);
      if (index > -1) {
        _triPairs[index]['pin'] = { ...point }
        _triPairs[index]['node'] = { ...node }
      } else {
        _triPairs.push({
          index: type,
          pin: { ...point },
          node: JSON.parse(JSON.stringify(node)),
        })
      }
      if (triCounter >= 3) {
        break;
      }
    }
    SortFn(_triPairs, ["A", "B", "C"], "index")
    this.setState({
      triPairs: _triPairs,
      isUpdateTriPairs: true,
      TriMappingVisible: true
    }, () => {
      this.updateTriPairs(_triPairs, false)
    })
  }

  render() {
    const { showNames, showNodes, location, modelLocation, msg, layoutUnit, TriMappingVisible,
      nodeList, powerPinList, triPairs, isUpdateTriPairs, downloadSpice, compPinsNets, matchErrorVisible, log, nodeRadiusInput } = this.state;
    const { PowerNets, ReferenceNets, component, maxHeight: propsMaxHeight, SignalNets, modelType, autoMatch } = this.props;
    const maxHeight = msg ? propsMaxHeight - 40 : propsMaxHeight;
    return <Fragment>
      <div id="cpm-setup-canvas-main" style={{ maxHeight }}>
        <div id="cpm-setup-model-canvas-main" style={{ height: maxHeight }}>
          <svg id='cpm-setup-model-canvas' className='canvas cpm-setup-canvas' ref={this.modelSvgRef} style={{ maxHeight }} />
          <div className="cpm-setup-canvas-pin-name">
            <span className="cpm-setup-canvas-pin-title">Node Name</span>
            <Switch className={`cpm-setup-canvas-pin-switch ${showNodes ? {} : 'unshow-switch'}`} checked={showNodes} onChange={this.changeShowNodeName} />
          </div>
          <div className="cpm-setup-canvas-node-radius-main">
            <span className="cpm-setup-canvas-pin-title">Node Radius</span>
            <Input
              value={nodeRadiusInput}
              onChange={this.changeNodeRadius}
              onBlur={this.nodeRadiusBlur}
              addonAfter={this.designUnit}
              className='aurora-input'
            />
          </div>
          <div className="node-canvas-btn-group-vertical">
            <button className="node-canvas-layBtn" title="Mapping">
              <span className='iconfont icon-sanjiaoxing3' onClick={(e) => this.openTriMapping(e)} ></span>
            </button>
            <button className="node-canvas-layBtn" title="Zoom In" onClick={() => this.zoomIn("model")}>
              <FontAwesomeIcon icon={faSearchPlus} />
            </button>
            <button className="node-canvas-layBtn" title="Zoom Out" onClick={() => this.zoomOut("model")}>
              <FontAwesomeIcon icon={faSearchMinus} />
            </button>
            <button className="node-canvas-layBtn" title="Fit View" onClick={() => this.fitView("model")}>
              <FontAwesomeIcon icon={faArrowsAlt} />
            </button>
            {/* <button className="node-canvas-layBtn" title="Download the match mapping log" >
              <FontAwesomeIcon icon={faDownload} onClick={() => this.downloadLog()} />
            </button> */}
            {/* <button className="node-canvas-layBtn" title="Rotate" onClick={this.rotate}>
            <FontAwesomeIcon icon={faSyncAlt} />
          </button>
          <button className="node-canvas-span-layBtn" title="Flip" onClick={this.flip}>
            <span className='iconfont icon-jingxiangfanzhuan1'></span>
          </button> */}
          </div>
          <div className='node-layout-canvas-coord'>{modelLocation ? (`( ${modelLocation} ) ${layoutUnit} `) : modelLocation}</div>
        </div>
        <div id="cpm-setup-design-canvas-main" style={{ height: maxHeight }}>
          <div className="cpm-setup-canvas-pin-name">
            <span className="cpm-setup-canvas-pin-title">Pin Number</span>
            <Switch className={`cpm-setup-canvas-pin-switch ${showNames ? {} : 'unshow-switch'}`} checked={showNames} onChange={this.changeShowPinName} />
          </div>
          <div className="node-canvas-btn-group-vertical">
            <button className="node-canvas-layBtn" title="Zoom In" onClick={() => this.zoomIn()}>
              <FontAwesomeIcon icon={faSearchPlus} />
            </button>
            <button className="node-canvas-layBtn" title="Zoom Out" onClick={() => this.zoomOut()}>
              <FontAwesomeIcon icon={faSearchMinus} />
            </button>
            <button className="node-canvas-layBtn" title="Fit View" onClick={() => this.fitView()}>
              <FontAwesomeIcon icon={faArrowsAlt} />
            </button>
            {this.develop ? <button className="node-canvas-layBtn" title="Download" >
              <FontAwesomeIcon icon={faDownload} onClick={() => this.downloadSpiceFile()} />
            </button> : null}
            {/* <button className="node-canvas-layBtn" title="Rotate" onClick={this.rotate}>
            <FontAwesomeIcon icon={faSyncAlt} />
          </button>
          <button className="node-canvas-span-layBtn" title="Flip" onClick={this.flip}>
            <span className='iconfont icon-jingxiangfanzhuan1'></span>
          </button> */}
          </div>
          <svg id='cpm-setup-design-canvas' className='canvas cpm-setup-canvas' ref={this.designSvgRef} style={{ maxHeight }} />
          <div className='node-layout-canvas-coord'>{location ? (`( ${location} ) ${layoutUnit} `) : location}</div>
        </div>
        {TriMappingVisible ? <TriMapping
          nodeList={nodeList}
          powerPinList={powerPinList}
          pairs={triPairs}
          isUpdateTriPairs={isUpdateTriPairs}
          updateTriPairsStatus={this.updateTriPairsStatus}
          closePanel={this.closeTriMapping}
          matchTriMapping={this.matchTriMapping}
          getDefaultPairs={this.getDefaultPairs}
          updateTriPairs={this.updateTriPairs}
          autoMatchPairs={this.autoMatchPairs}
          modelType={modelType}
          autoMatch={autoMatch}
        /> : null}
        {downloadSpice ? <DownloadSpicePanel
          powerPinList={powerPinList}
          compPinsNets={compPinsNets}
          component={component}
          PowerNets={PowerNets}
          ReferenceNets={ReferenceNets}
          closePanel={this.closeDownloadPanel}
          SignalNets={SignalNets}
        /> : null}
        {matchErrorVisible ? <MatchErrorPanel
          msg={msg}
          log={log}
          downloadLog={this.downloadLog}
          closePanel={this.closeMatchErrorPanel}
        /> : null}
      </div>
      {msg ? <div className={msg.match("successfully") ? "aurora-success-msg" : "aurora-model-name-error-msg"}>{msg}</div> : null}
    </Fragment>
  }
}

export default CPMCanvas;