import { USER_LOGOUT } from "../../../Login/actionTypes";
import { CHANGE_RESULT_TYPE, OPEN_PCB_CHANNEL_RESULT, UPDATE_HISTORY_LIST, OPEN_END_TO_END_CHANNEL_RESULT } from "./actionType";
import { OPEN_END_TO_END_CHANNEL } from '../endToEndChannel/actionTypes';

const defaultState = {
  resultKey: 'channel',
  channelId: null,
  historyList: []
}

export const AndesChannelResultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_RESULT_TYPE:
      return {
        ...state,
        resultKey: action.resultType !== "setup" ? action.resultType : state.resultKey
      }
    case OPEN_PCB_CHANNEL_RESULT:
      return {
        ...state,
        channelId: action.id,
        historyList: []
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_HISTORY_LIST:
      return {
        ...state,
        historyList: action.historyList
      }
    case OPEN_END_TO_END_CHANNEL:
    case OPEN_END_TO_END_CHANNEL_RESULT:
      return {
        ...state,
        endToEndChannelId: action.id,
        historyList: []
      }
    default: return state;
  }
}