const TYPE = "Cascade/Impedance";

export const SAVE_VERIFICATION_ID = `${TYPE}/save_verification_id`;
export const GET_IMPEDANCE_CONTENT = `${TYPE}/get_impedance_content`;
export const UPDATE_LOADING = `${TYPE}/update_loading`;
export const UPDATE_IMPEDANCE_CONTENT = `${TYPE}/update_impedance_content`;
export const UPDATE_TARGET_IC = `${TYPE}/update_target_ic`;
export const UPDATE_TABLE_STATUS = `${TYPE}/update_table_status`;
export const REFIND_DOMAIN_INFO = `${TYPE}/refind_domains_info`;
export const ADD_ROW = `${TYPE}/add_row`;
export const CHANGE_NETS = `${TYPE}/change_nets`;
export const CHANGE_EXTRA_NETS = `${TYPE}/change_extra_nets`;
export const DELETE_IMPEDANCE_ROW = `${TYPE}/delete_impedance_row`;
export const SAVE_VRM = `${TYPE}/save_vrm`;
export const UPDATE_IMP_RESULT_EXIST = `${TYPE}/update_impedance_result_exist`;
export const SAVE_RLC_VALUE = `${TYPE}/save_rlc_value`;
export const SAVE_RL_MODEL = `${TYPE}/save_rl_model`;
export const SAVE_CAP_MODEL = `${TYPE}/save_cap_model`;
export const RESET_SETUP = `${TYPE}/reset_setup`;
export const UPDATE_EXTENDED = `${TYPE}/update_extended`;
export const MERGE_PART = `${TYPE}/merge_part`;
export const UPDATE_COMP_USAGE = `${TYPE}/update_comp_usage`;
export const SPLIT_COMP = `${TYPE}/split_comp`;
export const UPDATE_COMP_PREFIX = `${TYPE}/update_comp_prefix`;
export const UPDATE_EXTRACTION = `${TYPE}/update_extraction`;
export const UPDATE_OPTIMIZATION = `${TYPE}/update_optimization`;
export const REFRESH = `${TYPE}/refresh_imprdance`;
export const UPDATE_ERROR_MSG = `${TYPE}/update_error_msg`;
export const UPDATE_WARNING_MSG = `${TYPE}/update_warning_msg`;
export const REMOVE_COMP = `${TYPE}/remove_comp`;
export const POWER_NET_SELECT_PANEL = `${TYPE}/power_net_select_panel`;
export const SAVE_IMPEDANCE_SIGN_OFF_ID = `${TYPE}/save_impedance_sign_off_id`;
export const SAVE_POWER_DOMAIN = `${TYPE}/save_power_domain`;
export const UPDATE_OPEN_TARGET_IC_LOADING = `${TYPE}/update_open_target_ic_loading`;
export const RE_ASSIGN_DECAP_MODEL = `${TYPE}/re_assign_decap_model`;
export const DELETE_SENSE_PORT = `${TYPE}/delete_sense_port`;
export const UPDATE_OPTIONS = `${TYPE}/update_options`;
export const UPDATE_POWER_NET_SELECT_PANEL = `${TYPE}/update_power_net_select_panel`;
export const UPDATE_DECAP_GROUP = `${TYPE}/update_decap_group`;
export const UPDATE_DECAP_MODEL = `${TYPE}/update_decap_model`;
export const CHANGE_USAGE = `${TYPE}/change_usage`;
export const SAVE_IMPEDANCE_DISPLAY = `${TYPE}/save_impedance_display`;
export const SAVE_COMP_TABLE_DISPLAY = `${TYPE}/save_comp_table_display`;

// Target
export const SAVE_TARGET = `${TYPE}/save_target`;
export const SAVE_MULTI_PCB_TARGET = `${TYPE}/ save_multi_pcb_target`;

// Ports
export const SAVE_PORTS = `${TYPE}/save_ports`;
export const SAVE_LOAD_PORTS = `${TYPE}/save_load_ports`;

// PCB
export const SELECT_PCB = `${TYPE}/select_pcb`;
export const CHANGE_PCB = `${TYPE}/change_pcb`;
export const COMPONENT_SETTING_UPDATE = `${TYPE}/component_setting_update`;

// Package
export const SAVE_PACKAGE_PORTS = `${TYPE}/save_package_ports`;
export const UPDATE_PACKAGE_NETS = `${TYPE}/update_package_nets`;
export const SAVE_DOMAIN_BY_NEW_NETS = `${TYPE}/save_domain_by_new_nets`;
export const SWITCH_PACKAGE_PORTS_TYPE = `${TYPE}/switch_package_ports_type`;
export const SAVE_BALL_SIZE = `${TYPE}/save_ball_size`;
export const REFRESH_PACKAGE = `${TYPE}/refresh_package`;
export const SWITCH_TARGET_DIE = `${TYPE}/switch_target_die`;
export const SAVE_WIRE_BOND = `${TYPE}/save_wire_bond`;

// Advanced
export const SET_ADDITIONAL_NETS = `${TYPE}/set_additional_nets`;

// Overview
export const CHANGE_PAGE = `${TYPE}/change_page`;
export const CREATE_NEW_LAYOUT = `${TYPE}/create_new_layout`;
export const DELETE_LAYOUT = `${TYPE}/delete_layout`;
export const DELETE_LAYOUT_CONNECTOR = `${TYPE}/delete_layout_connector`;
export const TRACE_OVERVIEW = `${TYPE}/trace_overview`;
export const DELETE_SHIP = `${TYPE}/delete_ship`;
export const CREATE_DIE = `${TYPE}/create_die`;
export const SAVE_ON_DIE_MODEL = `${TYPE}/save_on_die_model`;
export const UPDATE_IMPEDANCE_LOGS = `${TYPE}/update_impedance_logs`;
export const SAVE_DIE_CURRENT = `${TYPE}/save_die_current`;
export const SAVE_PMIC_VOLTAGE = `${TYPE}/save_pmic_voltage`;
export const SAVE_RLEAK_RRDL = `${TYPE}/save_rleak_rrdl`;
export const SWITCH_PACKAGE = `${TYPE}/switch_package`;
export const SAVE_INTERPOSER = `${TYPE}/save_interposer`
export const SAVE_INTERPOSER_NODE = `${TYPE}/save_interposer_node`
export const SAVE_HAS_INTERPOSER = `${TYPE}/save_has_interposer`
export const UPDATE_IC_CURRENT_STATUS = `${TYPE}/update_ic_current_status`
export const SAVE_TRANSIENT_TIME_SETTING = `${TYPE}/save_transient_time_setting`