import {
  GET_VERIFICATION_CONTENT,
  UPDATE_SIERRA_CONTENT,
  SELECT_NETS,
  UPDATE_SIERRA_INTERFACE,
  VOLTAGE_CHANGE,
  EXPAND_MENU,
  SAVE_COMPONENTS,
  ADD_SIGNAL,
  ADD_PCBS_IN_SIGNAL,
  UPDATE_SIERRAINFO,
  EDIT_SIGNAL,
  UPDATE_INTERFACES,
  ASSIGN_MODEL,
  SAVE_MODEL_PINS,
  ADD_POWER_GROUND_NETS,
  ADD_PCBS_IN_POWER_NETS,
  PG_NETS_SELECTION,
  NET_VOLTAGE_UPDATE,
  CREATE_CONFIG,
  EDIT_CHANNEL_TYPE,
  UPDATE_CHANNEL_MODEL,
  UPDATE_OPTIONS,
  SAVE_CONNECTOR,
  EDIT_COMPONENT_TYPE,
  EDIT_SIGNAL_NAME,
  DELETE_SIGNAL,
  DELETE_PG_NETS,
  SAVE_REPEATER_MODEL,
  SAVE_POWER_OFF,
  SAVE_CHIP_MODEL,
  SAVE_EXTRACTION,
  SAVE_RE_EXTRACTION,
  UPDATE_NETS_COMPS,
  SAVE_CURRENT_VERIFICATION,
  CURRENT_VERIFICATION_DEBUG_VERIFY,
  UPDATE_CURRENT_CONFIG,
  UPDATE_INTERFACE_LIBRARY_MSG,
  UPDATE_INTERFACE_LIBRARY,
  UPDATE_OPTIONS_BY_CLOSE,
  SAVE_RLC_MODEL,
  SAVE_COMP_PACKAGE_MODEL,
  UPDATE_TOUCHSTONE_STATUS_LIST,
  UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS,
  SAVE_PIN_STIMULUS_MODEL,
  UPDATE_COMPONENTS,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  UPDATE_PROBE_PINS,
  UPDATE_REFERENCE_NETS,
  UPDATE_GET_REFERENCE_NETS_LOADING,
  UPDATE_INTERFACE_REFERENCE_NETS,
  FIND_INTERFACE_REFERENCE_NETS,
  UPDATE_USAGE_APPLY_ALL,
  UPDATE_INTERFACE_AFTER_LIBRARY_UPDATE,
  CHANGE_PCB_LOADING,
  UPDATE_PORT_SETUPS_TO_SERVER,
  CONNECTOR_STATUS,
  SAVE_CONNECTOR_GROUP,
  CHANGE_IDENTIFICATION_CREATE_VISIBLE,
  CREATE_INTERFACE_IDENTIFICATION,
  UPDATE_INTERFACE_IDENTIFICATION_MASSAGE,
  UPDATE_VERIFICATION_SETTING,
  INTERFACE_PCB_REPLACE,
  UPDATE_PCB_REPLACE_INFO,
  UPDATE_ASSIGN_STATUS,
  UPDATE_COMP_PREFIX_STATUS,
  UPDATE_COMP_SETTING_SETUP,
  COMPONENT_SETTING_UPDATE,
  SAVE_VIRTUAL_COMPONENT,
  UPDATE_CREATE_PANEL_LOADING,
  UPDATE_SETUP_AFTER_CLOSE_SETTINGS,
  UPDATE_SETUP_AFTER_CLOSE_PART_LIBRARY,
  UPDATE_COMP_SETTINGS_STATUS,
  UPDATE_PART_REPEATER,
  CLOSE_PART_REPEATER_PANEL,
  UPDATE_PART_REPEATER_LOG,
  UPDATE_COMPONENT_PIN_USAGE,
  UPDATE_BUFFER_MODEL_STATUS,
  SAVE_VERIFICATION_CONTENT_TO_SERVER,
  UPDATE_MEASURE_CONFIG_STATUS,
  UPDATE_DUPLICATION_VERIFICATIONS,
  RECREATE_DUPLICATION_VERIFICATIONS,
  START_IDENTIFICATION_CREATING,
  UPDATE_CREATE_INTERFACE_STATUS,
  CREATE_EXPS_BY_TRACE,
  UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO,
  UPDATE_INTERFACE_DESIGN_MESSAGE,
  UPDATE_PRE_INFO_REPLACE_INFO,
  UPDATE_PRE_LAYOUT_REPLACE
} from './actionTypes';

export const getVerificationContent = (verificationID, loadPCB) => ({
  type: GET_VERIFICATION_CONTENT,
  verificationID,
  loadPCB
});

export const updateSierraContent = (info) => ({
  type: UPDATE_SIERRA_CONTENT,
  info
});

export const selectNets = (type, nets) => ({
  type: SELECT_NETS,
  powerType: type,
  nets
});

export const updateSierraInterface = (data) => ({
  type: UPDATE_SIERRA_INTERFACE,
  data
});

export const voltageChange = (voltage) => ({
  type: VOLTAGE_CHANGE,
  voltage
});

export const expandMenu = (expandKeys) => ({
  type: EXPAND_MENU,
  expandKeys
});

export const saveComponents = ({ part, model, value }) => ({
  type: SAVE_COMPONENTS,
  part,
  model,
  value
});

export const addSignal = (addPcbName) => ({
  type: ADD_SIGNAL,
  addPcbName
});

export const addPCBInSignals = (pcbs, name, pcbId, addSignal) => ({
  type: ADD_PCBS_IN_SIGNAL,
  pcbs,
  name,
  pcbId,
  addSignal
});

export const updateSierraInfo = (data) => ({
  type: UPDATE_SIERRAINFO,
  data
});

export const editSignal = ({ nets, pcbId, signalName }) => ({
  type: EDIT_SIGNAL,
  nets,
  pcbId,
  signalName
});

export const updateInterfaces = ({ Interfaces, pcb, pcbId, needMerge }) => ({
  type: UPDATE_INTERFACES,
  Interfaces,
  pcb,
  pcbId,
  needMerge
});

export const assignModel = ({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }) => ({
  type: ASSIGN_MODEL,
  record,
  model,
  deviceVcc,
  pinModelsInfo,
  applyAll,
  applySignal,
  powerOff
});

export const saveModelPins = ({ record, pinModels, applyAll, applyAllDriver }) => ({
  type: SAVE_MODEL_PINS,
  record,
  pinModels,
  applyAll,
  applyAllDriver
});

export const addPowerGrounds = () => ({
  type: ADD_POWER_GROUND_NETS,
});

export const addPCBInPowerNets = (pcb, net) => ({
  type: ADD_PCBS_IN_POWER_NETS,
  pcb,
  net
});

export const pgNetSelection = (pcb, pcbId, netSelect, name) => ({
  type: PG_NETS_SELECTION,
  pcb,
  pcbId,
  netSelect,
  name
});

export const netVoltageUpdate = ({ pcb, pcbId, name, value }) => ({
  type: NET_VOLTAGE_UPDATE,
  pcb,
  pcbId,
  name,
  value
});

export const createSimulationConfig = (verificationId) => ({
  type: CREATE_CONFIG,
  verificationId
});

export const editChannelType = (info) => ({
  type: EDIT_CHANNEL_TYPE,
  info
});

export const updateChannelModel = ({ libraryId, model, pcb }) => ({
  type: UPDATE_CHANNEL_MODEL,
  libraryId,
  model,
  pcb
});

export const updateOptions = (optType, value, update, fixTimeStep) => ({
  type: UPDATE_OPTIONS,
  optType,
  value,
  update,
  fixTimeStep
});

export const updateOptionsByClose = () => ({
  type: UPDATE_OPTIONS_BY_CLOSE
});

export const saveConnector = (connection, prevConnection) => ({
  type: SAVE_CONNECTOR,
  connection,
  prevConnection
});

export const editComponentType = ({ type, comps, pcbId }) => ({
  type: EDIT_COMPONENT_TYPE,
  compType: type,
  comps,
  pcbId
});

export const editSignalName = (name, prevName) => ({
  type: EDIT_SIGNAL_NAME,
  name,
  prevName
});

export const deleteSignal = ({ name }) => ({
  type: DELETE_SIGNAL,
  name
});

export const deletePGNets = ({ pcb, pcbId, name }) => ({
  type: DELETE_PG_NETS,
  pcb,
  pcbId,
  name
});

export const saveRepeater = ({ model, name, pcb, pcbId }) => ({
  type: SAVE_REPEATER_MODEL,
  model,
  name,
  pcb,
  pcbId
});

export const savePowerOff = (record, powerOff, applyAll) => ({
  type: SAVE_POWER_OFF,
  record,
  powerOff,
  applyAll
});

export const saveChip = ({ files, libType, pairs, pcb, pcbId, name, pkg, applyModelObj }) => ({
  type: SAVE_CHIP_MODEL,
  files,
  libType,
  pairs,
  pcb,
  pcbId,
  name,
  pkg,
  applyModelObj
});

export const saveRLC = ({ model, pcb, pcbId, value, part, comps }) => ({
  type: SAVE_RLC_MODEL,
  model,
  pcb,
  pcbId,
  value,
  part,
  comps
})

export const saveExtraction = (extraction, pcb, channel, apply) => ({
  type: SAVE_EXTRACTION,
  extraction,
  pcb,
  channel,
  apply
});

export const saveReExtraction = (interfaceId, ifDoExtraction) => ({
  type: SAVE_RE_EXTRACTION,
  interfaceId,
  ifDoExtraction
});

export const updateNetsComps = (updateNetsComponents) => ({
  type: UPDATE_NETS_COMPS,
  updateNetsComponents
});

export const saveCurrentVerification = (verificationIds, currentVerificationId) => ({
  type: SAVE_CURRENT_VERIFICATION,
  verificationIds,
  currentVerificationId
});

export const currentVerificationDebugVerify = (step, verificationId) => ({
  type: CURRENT_VERIFICATION_DEBUG_VERIFY,
  step,
  verificationId
});

export const updateCurrentConfig = (currentConfig) => ({
  type: UPDATE_CURRENT_CONFIG,
  currentConfig
});

export const updateInterfaceLibraryMsg = (interfaceLibraryError) => ({
  type: UPDATE_INTERFACE_LIBRARY_MSG,
  interfaceLibraryError
});

export const updateInterfaceLibrary = (libraryType, libraryFile) => ({
  type: UPDATE_INTERFACE_LIBRARY,
  libraryType,
  libraryFile
});

export const saveCompPackageModel = (pkg, component, pcb, pcbId) => ({
  type: SAVE_COMP_PACKAGE_MODEL,
  pkg,
  component,
  pcb,
  pcbId
})

export const updateTouchstoneStatusList = (list) => ({
  type: UPDATE_TOUCHSTONE_STATUS_LIST,
  list
});

export const updateTouchstoneMacroModelingStatus = ({ Components, verificationId }) => ({
  type: UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS,
  Components,
  verificationId
});

export const savePinStimulusModel = (model, record) => ({
  type: SAVE_PIN_STIMULUS_MODEL,
  model,
  record
})

export const updateCompComponents = ({ part, chips, checked, _type, pcb, pcbId, isPower = false }) => ({
  type: UPDATE_COMPONENTS,
  part,
  chips,
  checked,
  _type,
  pcb,
  pcbId,
  isPower
})

export const saveSplitComponents = ({ part, splitPart, comps, pcb, pcbId, isPower = false }) => ({
  type: SAVE_SPLIT_COMPONENTS,
  part,
  splitPart,
  comps,
  pcb,
  pcbId,
  isPower
});

export const saveMergeComponents = ({ part, partList, pcb, pcbId, isPower = false }) => ({
  type: SAVE_MERGE_COMPONENTS,
  part,
  partList,
  pcb,
  pcbId,
  isPower
});

export const updateProbePins = ({ probePinsList, part, pcbId }) => ({
  type: UPDATE_PROBE_PINS,
  probePinsList,
  part,
  pcbId
});

export const updateReferenceNets = () => ({
  type: UPDATE_REFERENCE_NETS
});

export const updateGetRefNetsLoading = ({ loading }) => ({
  type: UPDATE_GET_REFERENCE_NETS_LOADING,
  loading
});

export const updateInterfaceRefNets = ({ pcbIds, data }) => ({
  type: UPDATE_INTERFACE_REFERENCE_NETS,
  pcbIds,
  data
});

export const findInterfaceReferenceNets = (newReferenceNets) => ({
  type: FIND_INTERFACE_REFERENCE_NETS,
  newReferenceNets
});

export const updateApplyUsage = (usageApplyAll) => ({
  type: UPDATE_USAGE_APPLY_ALL,
  usageApplyAll
});

export const updateInterfaceAfterLibraryUpdate = () => ({
  type: UPDATE_INTERFACE_AFTER_LIBRARY_UPDATE
})

export const updatePCBLoading = (pcbLoading) => ({
  type: CHANGE_PCB_LOADING,
  pcbLoading
})

export const updatePortSetupsToServer = (data, current) => ({
  type: UPDATE_PORT_SETUPS_TO_SERVER,
  data,
  current
})

export const changeConnectorStatus = (bool) => ({
  type: CONNECTOR_STATUS,
  bool
})

export const saveConnectionGroup = (connections, havePrev, updatePCBIds) => ({
  type: SAVE_CONNECTOR_GROUP,
  connections,
  havePrev,
  updatePCBIds
})

export const changeIdentificationVisible = (visible) => ({
  type: CHANGE_IDENTIFICATION_CREATE_VISIBLE,
  visible
})

export const createInterfaceIdentification = (info) => ({
  type: CREATE_INTERFACE_IDENTIFICATION,
  info
})

export const updateIdentificationMassage = (msg) => ({
  type: UPDATE_INTERFACE_IDENTIFICATION_MASSAGE,
  msg
})

export const updateVerificationSetting = (prevInfo) => ({
  type: UPDATE_VERIFICATION_SETTING,
  prevInfo
})

export const replaceInterfacePCB = (previousPCBId, newPCBId, isReplace) => ({
  type: INTERFACE_PCB_REPLACE,
  previousPCBId,
  newPCBId,
  isReplace
})

export const updatePcbReplaceInfo = (info) => ({
  type: UPDATE_PCB_REPLACE_INFO,
  info
})

export const updateAssignStatus = (status) => ({
  type: UPDATE_ASSIGN_STATUS,
  status
})

export const updateGetCompPrefixStatus = (status) => ({
  type: UPDATE_COMP_PREFIX_STATUS,
  status
});

export const updateCompRLCPrefix = (updateCompPrefix, update) => ({
  type: UPDATE_COMP_SETTING_SETUP,
  updateCompPrefix,
  update
});

export const needUpdateCompSetting = (updateCompPrefix) => ({
  type: COMPONENT_SETTING_UPDATE,
  updateCompPrefix
});

export const saveVirtualComponent = (virtualComponents, pcbId) => ({
  type: SAVE_VIRTUAL_COMPONENT,
  virtualComponents,
  pcbId
});

export const updateCreatePanelLoading = (loading) => ({
  type: UPDATE_CREATE_PANEL_LOADING,
  loading
});

export const updateSetupAfterCloseSettings = (view) => ({
  type: UPDATE_SETUP_AFTER_CLOSE_SETTINGS,
  view
});

export const updateSetupAfterClosePartLibrary = (libraryType) => ({
  type: UPDATE_SETUP_AFTER_CLOSE_PART_LIBRARY,
  libraryType
});

export const updateCompSettingsStatus = (status) => ({
  type: UPDATE_COMP_SETTINGS_STATUS,
  status
});

export const updatePartRepeater = (addRepeaterParts) => ({
  type: UPDATE_PART_REPEATER,
  addRepeaterParts
});

export const closePartRepeaterPanel = (closePartRepeater) => ({
  type: CLOSE_PART_REPEATER_PANEL,
  closePartRepeater
});

export const updatePartRepeaterLog = (log) => ({
  type: UPDATE_PART_REPEATER_LOG,
  log
});

export const updateComponentPinUsage = (record, usageApplyAll) => ({
  type: UPDATE_COMPONENT_PIN_USAGE,
  record,
  usageApplyAll
})

export const updateBufferModelStatus = (status) => ({
  type: UPDATE_BUFFER_MODEL_STATUS,
  status
});

export const saveVerificationContentToServer = ({ pcbs, isUpdateRefNets, isMeasureConfig, key }) => ({
  type: SAVE_VERIFICATION_CONTENT_TO_SERVER,
  pcbs,
  isUpdateRefNets,
  isMeasureConfig,
  key
});

export const updateMeasureConfigStatus = (key, status) => ({
  type: UPDATE_MEASURE_CONFIG_STATUS,
  key,
  status
});

export const updateDuplicationVerifications = (info) => ({
  type: UPDATE_DUPLICATION_VERIFICATIONS,
  info
});

export const reCreateDuplicationVerifications = ({ selectKeys, cancel = false }) => ({
  type: RECREATE_DUPLICATION_VERIFICATIONS,
  selectKeys,
  cancel
});

export const startIdentificationCreating = ({ createKey, verificationId, groupName }) => ({
  type: START_IDENTIFICATION_CREATING,
  createKey,
  verificationId,
  groupName
});

export const updateCreateInterfacesStatus = (info) => ({
  type: UPDATE_CREATE_INTERFACE_STATUS,
  info
});

export const updateSweepSchematicPreLayoutInfo = (schematicPreLayoutInfo) => ({
  type: UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO,
  schematicPreLayoutInfo
});

export const updateInterfaceDesignMessage = (message) => ({
  type: UPDATE_INTERFACE_DESIGN_MESSAGE,
  message
});

export const updatePreInfoReplaceInfo = (updatePreInfo) => ({
  type: UPDATE_PRE_INFO_REPLACE_INFO,
  updatePreInfo
});

export const updatePreLayoutReplace = (pcbIds) => ({
  type: UPDATE_PRE_LAYOUT_REPLACE,
  pcbIds
})
