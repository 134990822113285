import { getVerificationJson, getPTSPVerificationJsonPromise, getSSNVerificationJson } from './data';
import { unitToNumber } from '../../../helper/dataProcess';
import { LPDDR5 } from '../../constants';
import { strDelimited } from '../../../helper/split';
import NP from 'number-precision';

// Data shared in the result of a byte/addr/cmd
export function calculateSpec(ui, uiNum) {
  let uiNumber = uiNum;
  if (!uiNumber && typeof (uiNumber) !== 'number') {
    uiNumber = 0.02;
  }
  return NP.strip(parseFloat((ui * uiNumber * 1e12).toFixed(1)));
};

export function getUIbyClock(clock) {
  const speed = clock;
  const value = parseFloat(speed);
  const unit = unitToNumber(speed.slice(-1))
  const _speed = NP.strip(value * unit);
  const ui = NP.strip((1 / _speed) / 2);
  return ui;
};

export function getDefaultResultConfig(ddrType) {
  switch (ddrType) {
    case LPDDR5:
      return {
        singleEndedMin: "35",
        singleEndedMax: "50",
        diffMin: "70",
        diffMax: "90",
        delay: "0.02"
      }
    default:
      return {
        singleEndedMin: "40",
        singleEndedMax: "60",
        diffMin: "80",
        diffMax: "120",
        delay: "0.02"
      }
  }
}

class ResultData {
  constructor() {
    this.verificationId = null;
    this.verificationJson = null;
    this.ui = null;
    this.uiNum = 0.02;
  };

  cleanVerificationResultData(verificationId) {
    if (this.verificationId === verificationId) {
      this.verificationJson = null;
      this.ui = null;
      this.uiNum = 0.02;
    }
  };

  cleanVerificationJson() {
    this.verificationJson = null;
  }

  getVerificationJsonPromise({ verificationId, channelId, verificationSubId }) {
    return new Promise((resolve, reject) => {
      if (this && this.verificationId === verificationId && this.verificationJson) {
        resolve(this.verificationJson);
      } else {
        this.cleanVerificationJson();
        getVerificationJson(channelId, verificationSubId).then(response => {
          this.verificationJson = response;
          this.verificationId = verificationId;
          resolve(this.verificationJson);
        });
      };
    });
  };

  getSSNVerificationJsonPromise({ verificationId, verificationSubId }) {
    return new Promise((resolve, reject) => {
      if (this && this.verificationId === verificationSubId && this.verificationJson) {
        resolve(this.verificationJson);
      } else {
        this.cleanVerificationJson();
        getSSNVerificationJson(verificationSubId).then(response => {
          this.verificationJson = response;
          this.verificationId = verificationId;
          resolve(this.verificationJson);
        });
      };
    });
  }

  getPTSPVerificationJsonPromise(verificationId) {
    return new Promise((resolve, reject) => {
      if (this.verificationId === verificationId && this.verificationJson) {
        resolve(this.verificationJson);
      } else {
        this.cleanVerificationJson();
        getPTSPVerificationJsonPromise(verificationId).then(response => {
          this.verificationJson = response;
          this.verificationId = verificationId;
          resolve(this.verificationJson);
        });
      }
    });
  }

  // ui = period/2 (preiod = 1/speed)   ui = (1/speed/2)
  getDDRui({ verificationId, channelId, verificationSubId }) {
    return new Promise((resolve, reject) => {
      this.getVerificationJsonPromise({ verificationId, channelId, verificationSubId }).then(content => {
        if (content && content.Config && content.Config.clock) {
          const ui = getUIbyClock(content.Config.clock);
          resolve(ui);
        } else {
          resolve(0);
        }
      })
    })
  }


  // ui = period/2 (preiod = 1/speed)   ui = (1/speed/2)
  // if (netGroup == 'DQS') {
  //   skewSpec = (ui * 0.5 * 1e12 + 10000).toPrecision(5) - 10000;
  // } else {
  //   skewSpec = (ui * 0.02 * 1e12 + 10000).toPrecision(5) - 10000;  // skew specification in ps, use 2% of the unit interval
  // };
  getSkewSpec({ verificationId, channelId, verificationSubId }) {
    return new Promise((resolve, reject) => {
      if (verificationId === this.verificationId && this.ui !== null) {
        resolve(calculateSpec(this.ui, this.uiNum));
      } else {
        this.getDDRui({ verificationId, channelId, verificationSubId }).then(ui => {
          resolve(calculateSpec(ui, this.uiNum));
        })
      }
    })
  }

  updateUINum(uiNum) {
    this.uiNum = uiNum;
  }

};

const _ResultData = new ResultData();
export default _ResultData;