import { call, put, takeEvery, delay, select, take, fork, cancel } from 'redux-saga/effects';
import {
  ADD_PRE_LAYOUT,
  UPDATE_CONFIG,
  GET_PRE_LAYOUT,
  SAVE_PRE_LAYOUT,
  UPDATE_PRE_LAYOUT,
  AUTO_FILL,
  CHANGE_UNIT,
  CHANGE_DATA_BY_UNIT,
  EXPORT_EXCEL,
  IMPORT_EXCEL,
  SAVE_MODEL_PRE_LAYOUT,
  UPDATE_COMPONENTS,
  SAVE_PRE_LAYOUT_MODEL,
  ADD_RES_COMPONENTS,
  CHANGE_PRELAYOUT_TYPE,
  UPDATE_PRELAYOUT_PACKAGE_ID,
  SAVE_PRE_LAYOUT_COMPONENTS,
  CHANGE_INCLUDES_RES,
  RENAME_PRE_LAYOUT
} from './actionTypes';
import {
  updatePreLayoutContent,
  openPreLayout,
  changeLoadingType,
  updateLocation,
  changeCLKSectionLength,
  updateLayer,
  changeFillStatus,
  changeLibraryStatus,
  setPrelayoutLog,
  setPrelayoutError,
  updateModelPreLayoutContent,
  saveModelPreLayoutInfo,
  updateSchematicLoading
} from './action';
import {
  updateTreeSelectedKeys,
  updateViewList,
  expandMenu,
  openProject,
  updateLibraryMenu,
  clearChannelByProjectId,
  updateExportProgress,
  openSSNChannel
} from '../project/action';
import { openPage, savePackageContentAndClear } from '../rocky/action'
import { openTabFooter, changeTabMenu } from '../../../MonitorStore/action';
import {
  getDefaultPreLayout,
  addComponent,
  updatePreLayout,
  getPreLayoutInfoById,
  getDefaultByteGroup,
  getDefaultCLKGroup,
  getControllerPin,
  getMemoryPin,
  getCLKPins,
  changeByteGroup,
  getDefaultSectionLengthGroup,
  _updateLayer,
  calculateDistance,
  calculateMemoryDiscantance,
  getTerminationSection,
  exportRockyPrelayout,
  importRockyPrelayout,
  updateModelPreLayout,
  modelPreLayoutContentJson
} from '@/services/Rocky/preLayout';
import { createLibrary } from '@/services/Rocky/library';
import { MEMORY, CONTROLLER, X16, getLibraryData } from '@/services/Rocky/preLayout/preLayoutConfig';
import { getNewPolt } from '@/services/Rocky/preLayout/preLayoutSpecificationPlot';
import { PCB_PRE_LAYOUT, PCB, PACKAGES, PCBS, PACKAGE_PRE_LAYOUT, PACKAGE } from '@/constants/treeConstants';
import { SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import {
  DIFF,
  MICROSTRIP,
  STRIPLINE,
  VIA,
  TRACE
} from "@/constants/libraryConstants";
import { unitChange } from '@/services/helper/mathHelper';
import { getWhiteSpace } from '@/services/helper/split';
import { ROCKY_PRELAYOUT_VERSION, ROCKY_PACKAGE_PRELAYOUT_VERSION } from '@/version';
import FileSaver from 'file-saver';
import { getBlob } from '@/services/helper/downloadHelper';
import designConstructor from '@/services/helper/designConstructor';
import { message } from 'antd';
import { MODEL, SCHEMATIC } from '../../../../services/Rocky/preLayout/preLayoutConfig';
import _ from 'lodash';
import { MIL } from '../../../../services/PreLayout/PreLayoutLibrary';
import { getPageKeyList } from '../../../../services/helper/filterHelper';
import { strDelimited } from '../../../../services/helper/split';
import { SPICE } from "@/constants/libraryConstants";
import { BGA, DIE } from '../../../../constants/componentType';
import { getDefaultName } from '../../../../services/helper/setDefaultName';
import { renameDesignPromise } from '../../../../services/api/Design/design';
import { saveRockyPackageContentToServer } from '../rocky/saga';
import { PACKAGE_VERIFICATION, PCB_CHANNEL } from '../../../../constants/treeConstants';
import { PROJECT_V2 } from '../../../../constants/projectVersion';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import { getInterfaceMonitor } from '../simulation/action';
import preLayoutContentConstructor from '../../../../services/Rocky/prelayoutConstructor';
import { getCentricVerificationContent } from '../rockySSN/action';
import { getPackageConfig, openSSNChannelConfig } from '../project/saga';

let autoSaveTask = null;
function* createPreLayout(action) {
  const { projectId, DDRType, pcbType } = action;
  const { RockyReducer: { preLayout: { placement }, project: { layout } } } = yield select();
  let version = ROCKY_PRELAYOUT_VERSION, cCompType = CONTROLLER,
    keyType = PCBS, viewType = PCB_PRE_LAYOUT,
    viewPostType = PCB, pcbName = 'PreLayout';
  if (pcbType === PACKAGES) {
    version = ROCKY_PACKAGE_PRELAYOUT_VERSION;
    cCompType = BGA;
    keyType = PACKAGES;
    viewType = PACKAGE_PRE_LAYOUT;
    viewPostType = PACKAGE;
    const packages = designConstructor.getAvailablePackageDesignValues(projectId) || [];
    pcbName = packages.length ? getDefaultName({ nameList: packages, defaultKey: "PreLayout", firstIndex: 1 }) : "PreLayout1";
  }
  const content = getDefaultPreLayout(DDRType, pcbType);

  const preLayout = { projectId, content, pcbName, version, type: viewPostType };
  if (content.prelayout === SCHEMATIC) {
    let _placement = { ...placement };
    _placement.controller = content.components.find(comp => comp.type === cCompType).locations;
    if (pcbType === PACKAGES) {
      _placement.memorys = [
        { name: 'DIE1', locations: { x: '1300', y: '-700' } },
        { name: 'DIE2', locations: { x: '1300', y: '-200' } }
      ]
    } else {
      _placement.memorys = [
        { name: 'UM0', locations: { x: '1300', y: '-700' } },
        { name: 'UM1', locations: { x: '1300', y: '-200' } }
      ]
    }

    _placement = getNewPolt(_placement, 'mil');
    yield put(updateLocation(_placement));
  }

  let res = yield call(updatePreLayout, { preLayout, identify: false });
  if (res) {
    // Add the newly added design to the DesignConstructor after adding the pre layout
    designConstructor.addDesign(res.id, res);
    yield put(openPreLayout({ id: res.id, projectId, pcbName: res.pcbName, preLayout: { ...content }, designVersion: res.designVersion, version: res.version, first: true, pcbType: viewPostType }));
    yield put(openProject({ id: projectId }));
    const { RockyReducer: { project: { expandedKeys, selectedKeys } } } = yield select();
    let key = `${keyType}-${projectId}`;
    let newKeys = [...expandedKeys];
    if (!newKeys.includes(key)) {
      newKeys = [...newKeys, key];
      yield put(expandMenu(newKeys));
    }

    // let selectKeys = layout === SINGLE_PAGE_LAYOUT ? [] : selectedKeys.filter(item => !item.match(viewPostType));
    // yield put(updateTreeSelectedKeys([...selectKeys, `${viewType}-${res.id}`]));
    yield put(updateTreeSelectedKeys([`${viewType}-${res.id}`]));
    yield put(openProject({ id: projectId }));
    yield put(openPage({ id: res.id }));
    yield put(updateViewList([viewType]));
    yield put(changeTabMenu({ currentVerificationId: null, verificationName: null }));
    yield put(setPrelayoutLog([]));
    yield put(setPrelayoutError(''));
    // Save the data of the package channel and clear it
    yield call(saveRockyPackageContentToServer, { isClean: true })
    if (autoSaveTask) {
      yield cancel(autoSaveTask);
    }
    autoSaveTask = yield fork(autoSave);
  } else {
    message.error('create pre-layout failed!');
  }
}

function* savePreLayoutToServer() {
  yield put({ type: SAVE_PRE_LAYOUT });
}

export function* saveModelPreLayoutToServer(action) {
  const { updateImmediately, initConfig } = action;
  if (updateImmediately) {
    yield call(saveModelPreLayout, { update: true, initConfig })
  } else {
    yield put({ type: SAVE_MODEL_PRE_LAYOUT })
  }
}

export function* getPCBPrelayoutContent(action) {
  const { pcbPreLayoutId } = action;
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
    yield call(saveModelPreLayout, { update: false });
  }
  autoSaveTask = yield fork(autoSave);
  const res = yield call(getPreLayoutInfoById, pcbPreLayoutId);
  preLayoutContentConstructor.clearInfo()
  preLayoutContentConstructor.setInfo(pcbPreLayoutId, res)
  if (!res) {
    return;
  }
  const content = { ...res.content };
  yield put(openPreLayout({ ...res, preLayout: content, first: !content.width && content.memory === 0 ? true : false, pcbType: res.type }));
}

function* getPreLayoutByID(action) {
  const { id, refresh = false, view } = action;
  const { RockyReducer: { project: { selectedKeys, layout, viewList, projectVersion }, preLayout: { id: _id, placement, componentLayer } } } = yield select();
  let newLayer = { ...componentLayer };
  const pageList = getPageKeyList(view);

  if (autoSaveTask) {
    yield cancel(autoSaveTask);
    yield call(saveModelPreLayout, { update: false });
  }
  autoSaveTask = yield fork(autoSave);
  if (!viewList.includes(PACKAGE_VERIFICATION)) {
    yield call(saveRockyPackageContentToServer, { isClean: true })
  }

  if (id === _id && !refresh) {
    let selectKeys = layout === SINGLE_PAGE_LAYOUT ? [] : selectedKeys.filter(item => {
      if (item) {
        const [_key] = strDelimited(item, "-");
        if (!pageList.includes(_key)) {
          return item;
        }
      };
      return false;
    });
    yield put(updateTreeSelectedKeys([...selectKeys, `${view}-${_id}`]));
    return;
  }
  if (projectVersion !== PROJECT_V2) { yield put(changeTabMenu({ currentVerificationId: null, verificationName: null })); }
  yield put(setPrelayoutLog([]));
  yield put(setPrelayoutError(''));

  try {
    yield put(changeLoadingType(true));
    yield put(updateSchematicLoading(true))
    const res = yield call(getPreLayoutInfoById, id);
    preLayoutContentConstructor.clearInfo(true)
    preLayoutContentConstructor.setInfo(id, res)
    if (!res) {
      yield put(changeLoadingType(false));
      return;
    }

    const content = { ...res.content };
    yield put(openPreLayout({ ...res, id: id, preLayout: content, first: !content.width && content.memory === 0 ? true : false, pcbType: res.type }));

    if (projectVersion === PROJECT_V2 && res.verificationId) {
      yield put(openTabFooter())
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: res ? res.name : null,
        currentVerificationId: res.verificationId,
        // channelName: res ? res.name : null,
        channelId: res.channelId,
        dataType: res.type === PCB ? PCB_PRE_LAYOUT : PACKAGE_PRE_LAYOUT,
      }))
      yield put(getInterfaceMonitor(res.verificationId))
      // yield put(getCentricVerificationContent(id,null,true))
    }

    if (content.prelayout === SCHEMATIC) {
      const flybyTopology = res.flybyTopology ? res.flybyTopology : [];
      yield put(changeCLKSectionLength(flybyTopology));
      let defaultMemorys = [
        { name: 'UM0', locations: { x: '1300', y: '-700' } },
        { name: 'UM1', locations: { x: '1300', y: '-200' } }
      ], cCompType = CONTROLLER, memoryType = MEMORY;
      if (view === PACKAGE_PRE_LAYOUT) {
        defaultMemorys = [
          { name: 'DIE1', locations: { x: '1300', y: '-700' } },
          { name: 'DIE2', locations: { x: '1300', y: '-200' } }
        ];
        cCompType = BGA;
        memoryType = DIE;
      }

      let newPlacement = { ...placement }, components = [...content.components], newMemorys = [];
      newPlacement.controller = components.find(comp => comp.type === cCompType).locations;
      for (let comp of components) {
        newLayer[comp.type] = comp.layer
        if (comp.type === memoryType) {
          newMemorys.push({
            name: comp.name,
            locations: comp.locations
          })
        }
      }

      newPlacement.memorys = newMemorys.length > 0 ? newMemorys : defaultMemorys;
      newPlacement = getNewPolt(newPlacement, content.unit);
      yield put(updateLocation(newPlacement));
      yield put(updateLayer(newLayer));
    }

    const { RockyReducer: { project } } = yield select();

    let selectKeys = project.selectedKeys.filter(item => {
      if (item) {
        const [_key] = strDelimited(item, "-");
        if (!pageList.includes(_key)) {
          return item;
        }
      };
      return false;
    });
    yield put(updateTreeSelectedKeys([...selectKeys, `${view}-${res.id}`]));
    if (refresh) {
      yield put(changeFillStatus(true));
    }
  } catch (error) {
    yield put(changeLoadingType(false));
    yield put(updateSchematicLoading(false))
  }
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_PRE_LAYOUT, SAVE_MODEL_PRE_LAYOUT]);
    let delayTime = 3000;
    if (newAction.type === SAVE_PRE_LAYOUT) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, savePreLayout, newAction, delayTime);
    } else if (newAction.type === SAVE_MODEL_PRE_LAYOUT) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, saveModelPreLayout, newAction, delayTime);
    }
  }
}

function* debounce(callback, action, time = 0) {
  yield delay(time);
  yield call(callback, action);
  yield put(changeLoadingType(false));
}

function* saveModelPreLayout(action) {
  const { update, initConfig } = action;
  const { RockyReducer: { preLayout, project: { projectVersion } } } = yield select();
  if (!preLayout || !preLayout.id || !preLayout.content) { return }
  const { projectId, preLayoutLoading, designVersion, id, content, pcbType, verificationId } = preLayout;
  const _preLayout = { projectId, designVersion, id, content };
  let load = false;
  try {
    const response = yield call(updateModelPreLayout, { preLayout: { ..._preLayout, version: ROCKY_PRELAYOUT_VERSION, type: pcbType } });
    if (update) {
      // update front info
      if (!verificationId && projectVersion === PROJECT_V2) {
        // init data
        yield put(openPreLayout({ ...response, id: id, preLayout: response.content, pcbType: response.type }));
        yield put(changeTabMenu({
          menuType: "simulation",
          tabSelectKeys: ['monitor'],
          verificationName: response ? response.name : null,
          currentVerificationId: response.verificationId,
          // channelName: response ? response.name : null,
          channelId: response.channelId,
          dataType: response.type === PCB ? PCB_PRE_LAYOUT : PACKAGE_PRE_LAYOUT,
        }))
        load = true;
      } else {
        yield put(updateModelPreLayoutContent({ ...response.content }))
      }
    }
    yield put(changeLoadingType(false));
    if (initConfig) {
      if (response.type === PCB) {
        yield put(openSSNChannel(response.channelId))
      } else {
        yield call(getPackageConfig, id)
      }
    }
  } catch (error) {
    yield put(changeLoadingType(false));
    return
  }

  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  if (preLayoutLoading && currentProjectId === projectId) {
    yield put(openProject({ id: projectId, load }));
  }
}

function* savePreLayout(action) {
  const { RockyReducer: { preLayout } } = yield select();
  const { projectId, preLayoutLoading, designVersion, id, content, CLKTableSection } = preLayout;
  const _preLayout = { projectId, designVersion, id, content };
  const identify = preLayoutLoading;
  _preLayout['flybyTopology'] = [...CLKTableSection];
  yield put(changeLoadingType(false));
  try {
    yield call(updatePreLayout, { preLayout: { ..._preLayout, version: ROCKY_PRELAYOUT_VERSION }, identify });
  } catch (error) {
    return;
  }

  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  if (identify && currentProjectId === projectId) {
    yield put(openProject({ id: projectId }));
  }
}

function* updateConfig(action) {
  const { config, update, changeUnit } = action;
  const { RockyReducer: { preLayout, project: { projectVersion } } } = yield select();
  const { content, componentLayer, placement, channelId } = preLayout;
  const { prelayout: oldPreLayout } = content;
  const { memory, prelayout, unit, width, topology, layer, numberOfChannel, includeRes } = config;
  let prelayoutChange = oldPreLayout !== prelayout ? true : false;
  if (prelayout === MODEL) {
    if (prelayoutChange || update) {
      // Update the list when there are changes in layout and memory
      yield put(changeLoadingType(true));
    }
    const preLayoutInfo = new modelPreLayoutContentJson({ ...content, memory, prelayout, width, topology, numberOfChannel, includeRes })
    yield put(updateModelPreLayoutContent({ ...preLayoutInfo }))
    yield call(saveModelPreLayoutToServer, { updateImmediately: true, initConfig: projectVersion === PROJECT_V2 ? true : false })
  } else {
    const oldUnit = content.unit;
    let newContent = { ...content }, newLayer = { ...layer };
    if (prelayoutChange) {
      //Reset location and layer data in comp
      newContent.components.forEach(comp => {
        if (comp.type === CONTROLLER) {
          comp.layer = newLayer[CONTROLLER];
          comp.locations = placement.controller
        }
      })
    }

    if (componentLayer[CONTROLLER] !== newLayer[CONTROLLER]) {
      newContent = _updateLayer({ content: newContent, key: CONTROLLER, value: newLayer[CONTROLLER], isContent: true });
    }
    if (componentLayer[MEMORY] !== newLayer[MEMORY]) {
      newContent = _updateLayer({ content: newContent, key: MEMORY, value: newLayer[MEMORY], isContent: true });
    }
    yield put(updatePreLayoutContent({ ...newContent, memory, prelayout, unit, width, topology }));
    yield put(updateLayer(newLayer));
    if (update || prelayoutChange) {
      yield fork(updateByMemoryNumber, { width, memory, unit, oldUnit });
    }
    if (changeUnit) {
      yield fork(changeSignalGroupByUnit, { ...changeUnit, changeComp: false });
    }

  }
}

function* updateByMemoryNumber(action) {
  let { width, memory, unit, oldUnit } = action;
  if (!width || !memory) {
    return;
  }
  const { RockyReducer: { preLayout, project: { currentProjectId } } } = yield select();
  yield put(clearChannelByProjectId(currentProjectId));

  const { content, componentLayer, placement } = preLayout;
  let newContent = { ...content };
  const { components, signal_groups = [], type } = newContent;
  yield put(changeLoadingType(true));
  /*add/reduce signal_groups*/
  let byteGroups = [], CLKGroups = [];
  if (signal_groups.length === 0) {
    byteGroups = getDefaultByteGroup(memory, width, unit);
    CLKGroups = getDefaultCLKGroup(type, memory, unit, width);
    let CKLTableSection = getDefaultSectionLengthGroup(type, memory);
    yield put(changeCLKSectionLength(CKLTableSection));
  } else {
    let _byteGroups = signal_groups.filter(group => group.name.match('Byte'));
    _byteGroups.forEach(group => {
      group['signal_spacing'] = unitChange({ num: group['signal_spacing'], oldUnit, newUnit: unit, decimals: 4 }).number
    })
    byteGroups = changeByteGroup(memory, width, _byteGroups, unit);
    CLKGroups = getDefaultCLKGroup(type, memory, unit, width);
    let CKLTableSection = getDefaultSectionLengthGroup(type, memory);
    yield put(changeCLKSectionLength(CKLTableSection));
  }
  let newSignalGroups = [...byteGroups, ...CLKGroups];

  /*add/reduce components*/
  let memorys = components.filter(comp => comp.type === MEMORY);
  let controller = components.filter(comp => comp.type === CONTROLLER);
  let newMemorys = [], newPins = [];
  let memoryLength = memorys.length;

  // get CLK, ADR, CMD pins
  const CLKPins = getCLKPins(type, width);
  // add controller pin
  if (signal_groups.length === 0) {
    newPins = getControllerPin([], CLKPins, memory, width);
  } else {
    let bytePin = controller[0].pins.filter(item => item.pin.match(/DQ|DM/g));
    newPins = getControllerPin(bytePin, CLKPins, memory, width);
  }
  controller[0].pins = newPins;

  // add new memory pins
  if (memory >= memoryLength) {
    let addList = addComponent(memoryLength, memory);
    newMemorys = [...memorys, ...addList];
  } else {
    newMemorys = [...memorys.slice(0, memory)];
  }

  // add pins
  let newPlacement = { ...placement };
  newMemorys.forEach((item, index) => {
    item.pins = getMemoryPin({ name: item.name, CLKPins, width, memory, type, memoryIndex: index });
    item.locations = newPlacement.memorys.find(memory => memory.name === item.name).locations;
  })
  newPlacement = getNewPolt(newPlacement, newContent.unit);
  yield put(updateLocation(newPlacement));

  controller = _updateLayer({ content: controller, value: componentLayer[CONTROLLER], isContent: false });
  newMemorys = _updateLayer({ content: newMemorys, value: componentLayer[MEMORY], isContent: false });
  newContent.components = [...controller, ...newMemorys];
  newContent.signal_groups = newSignalGroups;
  yield put(updatePreLayoutContent(newContent));
  yield call(autoFill);
}

function* autoFill(notSave) {
  const { RockyReducer: { preLayout } } = yield select();
  const { content, placement, CLKTableSection } = preLayout;
  const { microstrip, stripline, via, microstrip_diff, stripline_diff, via_diff } = yield call(findLibraryFile, { unit: content.unit ? content.unit : MIL });
  let newContent = { ...content };
  let newSignal = newContent.signal_groups;
  const c = Number(placement.controller.width) / 2, w = Number(placement.controller.height) / 2;
  const distances = placement.memorys.map(memory => {
    const name = memory.name, location = memory.locations;
    return { name, distance: calculateDistance(c, w, location.x, location.y) };
  })
  const defautMicrostrip = unitChange({ num: 27, newUnit: newContent.unit, decimals: 4 }).number;
  const defautVia = unitChange({ num: 43.7, newUnit: newContent.unit, decimals: 4 }).number;
  const defaultTerminationDistance = unitChange({ num: 500, newUnit: newContent.unit, decimals: 4 }).number;
  newSignal.forEach(group => {
    if (group.name.match('Byte')) {
      let num = Number(group.name.replace('Byte', ''));
      if (content.width === X16 && num !== 0) {
        num = parseInt(num / 2);
      }
      const distance = distances.find(item => item.name === `UM${num}`).distance;
      group.signals = group.signals.map(sig => {
        let _signal = JSON.parse(JSON.stringify(sig));
        const _microstrip = sig.name.match('DQS') ? microstrip_diff : microstrip;
        const _stripline = sig.name.match('DQS') ? stripline_diff : stripline;
        const _via = sig.name.match('DQS') ? via_diff : via;
        _signal.sections = _signal.sections.map(section => {
          // microstrip - via - stripline - via - microstrip
          let newSection = { ...section };
          switch (section.id) {
            case '1':
            case '5':
              newSection = { ...section, ..._microstrip, length: defautMicrostrip };
              break;
            case '2':
            case '4':
              newSection = { ...section, ..._via, length: defautVia };
              break;
            case '3':
              // stripline = constant * distance * random(0.95 - 1.05)
              newSection = { ...section, ..._stripline, length: (1.6 * distance * (Math.random() * 0.1 + 0.95)).toFixed(4) };
              break;
            default: break;
          }
          return newSection;
        })
        return _signal;
      })
    } else {
      group.signals = group.signals.map(sig => {
        const _microstrip = sig.name.match('CLK') ? microstrip_diff : microstrip;
        const _stripline = sig.name.match('CLK') ? stripline_diff : stripline;
        const _via = sig.name.match('CLK') ? via_diff : via;
        let _signal = JSON.parse(JSON.stringify(sig));
        CLKTableSection.forEach((item, index) => {
          let id = item.firstSection;
          let section = _signal.sections.find(sec => sec.id === id);
          if (section) {
            if (index === 0) {
              for (let i = 1; i < 4; i++) {
                let newId = section.id;
                if (i === 1) {
                  section = { ...section, ..._microstrip, length: defautMicrostrip };
                } else if (i === 2) {
                  section = { ...section, ..._via, length: defautVia };
                } else {
                  const distance = distances.find(item => item.name === `UM${index}`).distance
                  // stripline = constant * distance * random(0.95 - 1.05)
                  section = { ...section, ..._stripline, length: (2.5 * distance * (Math.random() * 0.1 + 0.95)).toFixed(4) };
                }
                _signal.sections[_signal.sections.findIndex(item => item.id === newId)] = section;
                let next = section.next[0];
                section = _signal.sections.find(sec => sec.id === next);
              }
            } else if (index === CLKTableSection.length - 2) {
              // stripline = constant * 500 * random(0.95 - 1.05)
              section = { ...section, ..._stripline, length: (1.2 * defaultTerminationDistance * (Math.random() * 0.1 + 0.95)).toFixed(4) };
              _signal.sections[_signal.sections.findIndex(item => item.id === id)] = section;
            } else if (index === CLKTableSection.length - 1) {
              _signal.sections[_signal.sections.findIndex(item => item.id === id)] = getTerminationSection(section, newContent.type);
            } else {
              const memoryDistance = calculateMemoryDiscantance(index, placement.memorys);
              // stripline = constant * distance * random(0.95 - 1.05)
              section = { ...section, ..._stripline, length: (1.2 * memoryDistance * (Math.random() * 0.1 + 0.95)).toFixed(4) };
              _signal.sections[_signal.sections.findIndex(item => item.id === id)] = section;
            }
          }
        })
        let nextId = "";
        _signal.sections = _signal.sections.map(section => {
          if (!section.value && !section.length) {
            if (section.next.length === 0 || nextId === section.id) {
              section = { ...section, ..._microstrip, length: defautMicrostrip };
              nextId = "";
            } else {
              section = { ...section, ..._via, length: defautVia };
              nextId = section.next[0];
            }
          }
          return section;
        })
        return _signal
      })
    }
  });

  if (!notSave) {
    yield put(updatePreLayoutContent(newContent));
  }
  yield put(changeLibraryStatus(true));
  yield put(changeFillStatus(true));
}

function* findLibraryFile(action) {
  const { RockyReducer: { project } } = yield select();
  const { currentProjectId, projectList, traceList, viaList } = project;
  const name = projectList.find(item => item.id === currentProjectId).name;
  const { Via, Via_diff, Microstrip, Microstrip_diff, Stripline, Stripline_diff } = getLibraryData(action.unit)
  const microstrip = yield call(getLibraryFile, { type: MICROSTRIP, list: traceList, template: Microstrip, name });
  const microstrip_diff = yield call(getLibraryFile, { type: MICROSTRIP, list: traceList, template: Microstrip_diff, name, signalType: DIFF });
  const stripline = yield call(getLibraryFile, { type: STRIPLINE, list: traceList, template: Stripline, name });
  const stripline_diff = yield call(getLibraryFile, { type: STRIPLINE, list: traceList, template: Stripline_diff, name, signalType: DIFF });
  const via = yield call(getLibraryFile, { type: VIA, list: viaList, template: Via, name });
  const via_diff = yield call(getLibraryFile, { type: VIA, list: viaList, template: Via_diff, name, signalType: DIFF })
  yield put(updateLibraryMenu());
  return { microstrip, stripline, via, microstrip_diff, stripline_diff, via_diff };
}

function* getLibraryFile(action) {
  const { type, list, template, name, signalType } = action;
  let newName = type === VIA ? `${name}_via` : type === MICROSTRIP ? `${name}_u` : `${name}_s`;
  newName = signalType === DIFF ? `${newName}_diff` : newName;
  let libraryFile = list.filter(file => file.fileType === type).find(item => item.name === newName);
  if (!libraryFile) {
    let newTemplate = { ...template, name: newName };
    const res = yield call(createLibrary, { libraries: [newTemplate], typeName: type, libraryFormat: 'data' });
    if (type === VIA) {
      libraryFile = res.via.filter(item => item.typeName === type).find(item => item.name === newName);
    } else {
      libraryFile = res.trace.filter(item => item.typeName === type).find(item => item.name === newName);
    }
  }
  return {
    libId: libraryFile.id,
    libType: type,
    type: type === VIA ? VIA : TRACE,
    template: newName,
  }
}

function* changeLoactionByUnit(action) {
  const { oldUnit, newUnit } = action;
  const { RockyReducer: { preLayout: { placement } } } = yield select();
  let _placement = { ...placement };
  let { controller, memorys } = _placement;
  controller.width = unitChange({ num: controller.width, oldUnit, newUnit, decimals: 4 }).number.toString();
  controller.height = unitChange({ num: controller.height, oldUnit, newUnit, decimals: 4 }).number.toString();
  memorys.forEach(memory => {
    if (memory.locations) {
      memory.locations.x = unitChange({ num: memory.locations.x, oldUnit, newUnit, decimals: 4 }).number.toString();
      memory.locations.y = unitChange({ num: memory.locations.y, oldUnit, newUnit, decimals: 4 }).number.toString()
    }
  })
  yield put(updateLocation({ ..._placement, controller, memorys }));
}

function* changeSignalGroupByUnit(action) {
  const { oldUnit, newUnit, changeComp } = action;
  const { RockyReducer: { preLayout: { content } } } = yield select();
  const newComponents = [...content.components];
  if (changeComp) {
    newComponents.forEach(component => {
      component.locations.x = component.locations.x ? unitChange({ num: component.locations.x, oldUnit, newUnit, decimals: 4 }).number.toString() : null;
      component.locations.y = component.locations.y ? unitChange({ num: component.locations.y, oldUnit, newUnit, decimals: 4 }).number.toString() : null;
      component.locations.width = component.locations.width ? unitChange({ num: component.locations.width, oldUnit, newUnit, decimals: 4 }).number.toString() : null;
      component.locations.height = component.locations.height ? unitChange({ num: component.locations.height, oldUnit, newUnit, decimals: 4 }).number.toString() : null;
    })
  }
  const newSignalGroup = content.signal_groups ? [...content.signal_groups] : [];
  newSignalGroup.forEach(group => {
    if (group && group.signals) {
      group['signal_spacing'] = unitChange({ num: group['signal_spacing'], oldUnit, newUnit, decimals: 4 }).number;
      group.signals.forEach(signal => {
        if (signal && signal.sections) {
          signal.sections.forEach(section => {
            section['length'] = unitChange({ num: section['length'], oldUnit, newUnit, decimals: 4 }).number;
          })
        }
      })
    }
  })
  yield put(updatePreLayoutContent({ ...content, unit: newUnit, signal_groups: newSignalGroup, components: newComponents }));
  yield put(changeFillStatus(true));
}

function* downloadPrelayoutExcel(action) {
  const { designId, fileName } = action;
  yield fork(updatePrelayoutProgress);
  const url = yield call(exportRockyPrelayout, { designId });
  if (url) {
    const blob = yield call(getBlob, url);
    if (blob) {
      FileSaver.saveAs(blob, fileName);
    }
    yield put(updateExportProgress(100));
  } else {
    yield put(updateExportProgress(100));
    message.error(`Download pre-layout excel file failed!`);
  }
}

function* updatePrelayoutProgress() {
  while (true) {
    yield delay(2000);
    const { RockyReducer: { project: { prelayoutProgress } } } = yield select();
    if (prelayoutProgress >= 100) break;
    const progress = prelayoutProgress + 20 >= 100 ? prelayoutProgress : prelayoutProgress + 20;
    yield put(updateExportProgress(progress))
  }
  yield put(updateExportProgress(0))
}

function* uploadPrelayoutExcel(action) {
  const { designId, file } = action;
  try {
    const response = yield call(importRockyPrelayout, { designId, file });
    yield fork(getPreLayoutByID, { id: designId, refresh: true });
    yield delay(500);
    if (Array.isArray(response) && response.length) {
      const log = response.map(req => req.indexOf('==>') >= 0 ? req : `${getWhiteSpace(7)}${req}`)
      yield put(setPrelayoutLog(log));
      yield put(openTabFooter())
    }
  } catch (error) {
    yield put(setPrelayoutError(error));
    yield put(setPrelayoutLog([]));
    yield put(openTabFooter())
    console.error('Import prelayout excel error');
  }
}

function* savePreLayoutComponents(action) {
  const { components } = action;
  const { RockyReducer: { preLayout } } = yield select();
  const { components: prevComps } = preLayout;
  if (!_.isEqual(components, prevComps)) {
    yield put(saveModelPreLayoutInfo({ components }));
    yield put({ type: SAVE_MODEL_PRE_LAYOUT })
  }
}

function* saveModel(action) {
  const { model, components } = action;
  yield put(saveModelPreLayoutInfo({ components, model }));
  yield put({ type: SAVE_MODEL_PRE_LAYOUT })
}

function* saveComponents(action) {
  const { components } = action;
  const { RockyReducer: { preLayout: { content: { model } } } } = yield select()
  const modelType = model.modelType;

  const modelLibraries = []
  for (let comp of components) {
    const { pins } = comp;
    const filterPins = pins.filter(item => item.model && item.model.libraryId && item.model.port);
    if (!filterPins || !filterPins.length) { continue }
    for (let pinInfo of filterPins) {
      const { libraryId, subckt, fileName } = pinInfo.model;
      const findIndex = modelLibraries.findIndex(item => {
        if (item.libraryId === libraryId && (modelType !== SPICE || (modelType === SPICE && item.subckt === subckt))) {
          return true
        }
        return false
      })
      if (findIndex < 0) {
        modelLibraries.push({ libraryId, subckt, fileName })
      }
    }
  }
  yield put(saveModelPreLayoutInfo({ components, model: { modelType: model.modelType, libraries: modelLibraries } }));
  yield put({ type: SAVE_MODEL_PRE_LAYOUT })
}

function* addResComp(action) {
  // add res
  /*   const { signal } = action;
    const { RockyReducer: { preLayout } } = yield select(); */
}

function* changePrelayoutType(action) {
  let { width, memory, unit, prelayout } = action;
  if (!width || !memory) {
    return;
  }
  const { RockyReducer: { preLayout } } = yield select();
  const { content, placement } = preLayout;
  let newContent = { ...content };
  const { type } = newContent;
  if (prelayout === SCHEMATIC) {
    let _placement = { ...placement };
    _placement.controller = { x: "0", y: "0", width: "1000", height: "1000" };
    _placement.memorys = [
      { name: 'UM0', locations: { x: '1300', y: '-700' } },
      { name: 'UM1', locations: { x: '1300', y: '-200' } }
    ]
    _placement = getNewPolt(_placement, 'mil');
    yield put(updateLocation(_placement));

    let byteGroups = [], CLKGroups = [];

    byteGroups = getDefaultByteGroup(memory, width, unit);
    CLKGroups = getDefaultCLKGroup(type, memory, unit, width);
    let CKLTableSection = getDefaultSectionLengthGroup(type, memory);
    yield put(changeCLKSectionLength(CKLTableSection));
    let newSignalGroups = [...byteGroups, ...CLKGroups];
    yield put(saveModelPreLayoutInfo({ signal_groups: newSignalGroups }));
    yield call(autoFill, true);
  } else {
  }
}

let getChannelInfoStatus = null;

function* updatePreLayoutPackageId(action) {
  const { packageId } = action;
  const { RockyReducer: { preLayout, project: { projectVersion } } } = yield select();
  const { projectId, designVersion, id, content, pcbType } = preLayout;

  let load = false, channelId, verificationId;
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
  }
  autoSaveTask = yield fork(autoSave);
  try {
    yield put(updateSchematicLoading(true))
    const _preLayout = { projectId, designVersion, id, content: { ...content, packageId } };
    yield put(changeLoadingType(true));
    const response = yield call(updateModelPreLayout, { preLayout: { ..._preLayout, version: ROCKY_PRELAYOUT_VERSION, type: pcbType } });
    // update front info
    yield put(updateModelPreLayoutContent({ ...response.content }))
    yield put(changeLoadingType(false));
    if (projectVersion === PROJECT_V2) {
      yield put(openPreLayout({ ...response, id: id, preLayout: response.content, pcbType: response.type }));
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: response ? response.name : null,
        currentVerificationId: response.verificationId,
        // channelName: response ? response.name : null,
        channelId: response.channelId,
        dataType: response.type === PCB ? PCB_PRE_LAYOUT : PACKAGE_PRE_LAYOUT,
      }))
      load = true;
      channelId = response.id;
      verificationId = response.verificationId;
      preLayoutContentConstructor.setInfo(response.id, response)
      if (getChannelInfoStatus) {
        yield cancel(getChannelInfoStatus);
      }
      getChannelInfoStatus = yield fork(openSSNChannelConfig, { id: response.channelId });

      // yield put(openSSNChannel(response.channelId))
    }
  } catch (error) {
    yield put(updateSchematicLoading(false))
    message.error(error)
  }
  const { RockyReducer: { project: { currentProjectId }, preLayout: { preLayoutLoading } } } = yield select();
  if ((preLayoutLoading || load) && currentProjectId === projectId) {
    let obj = { id: projectId, load }
    if (projectVersion === PROJECT_V2) {
      obj = {
        ...obj,
        channelId,
        verificationId
      }
    }
    yield put(openProject(obj));
  }

  yield put(changeLoadingType(false));
}

function* changeIncludesRes(action) {
  const { checked } = action;
  const { RockyReducer: { preLayout } } = yield select();
  const { content } = preLayout;
  yield put(changeLoadingType(true));
  const preLayoutInfo = new modelPreLayoutContentJson({ ...content, includeRes: checked })
  yield put(updateModelPreLayoutContent({ ...preLayoutInfo }))
  yield call(saveModelPreLayoutToServer, { updateImmediately: true })
}

function* _preLayoutRename(action) {
  const { data } = action;
  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  if (data && data.id && data.name) {
    try {
      const res = yield call(renameDesignPromise, data.id, data.name);
      /*   if(data.dataType===PCB_PRE_LAYOUT){
  
        }else{ */
      designConstructor.delDesign(res.id);
      /* } */

      yield put(openProject({ id: currentProjectId }));
    } catch (error) {
      console.error(error);
    }
  }
}

function* RockyPreLayoutSaga() {
  yield takeEvery(ADD_PRE_LAYOUT, createPreLayout);
  yield takeEvery(UPDATE_CONFIG, updateConfig);
  yield takeEvery(GET_PRE_LAYOUT, getPreLayoutByID);
  yield takeEvery(UPDATE_PRE_LAYOUT, savePreLayoutToServer);
  yield takeEvery(AUTO_FILL, autoFill);
  yield takeEvery(CHANGE_UNIT, changeLoactionByUnit);
  yield takeEvery(CHANGE_DATA_BY_UNIT, changeSignalGroupByUnit);
  yield takeEvery(EXPORT_EXCEL, downloadPrelayoutExcel);
  yield takeEvery(IMPORT_EXCEL, uploadPrelayoutExcel);
  yield takeEvery(UPDATE_COMPONENTS, savePreLayoutComponents)
  yield takeEvery(SAVE_PRE_LAYOUT_MODEL, saveModel);
  yield takeEvery(ADD_RES_COMPONENTS, addResComp);
  yield takeEvery(CHANGE_PRELAYOUT_TYPE, changePrelayoutType);
  yield takeEvery(UPDATE_PRELAYOUT_PACKAGE_ID, updatePreLayoutPackageId);
  yield takeEvery(SAVE_PRE_LAYOUT_COMPONENTS, saveComponents);
  yield takeEvery(CHANGE_INCLUDES_RES, changeIncludesRes);
  yield takeEvery(RENAME_PRE_LAYOUT, _preLayoutRename);
}

export default RockyPreLayoutSaga;