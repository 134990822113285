import { CMC, getConnectedPinNumber, RLC_TYPES, getComponentByName } from "../../PCBHelper";

class ChannelConnection {
  constructor() {
    this.id = null;
    this.signals = [];
    this.components = [];
    this.designId = null;
    this.signalsConnection = new Map();
    this.compPins = [];
    this.netsList = [];
  }

  initChannel = (channelInfo = {}, netsList) => {
    this.signals = channelInfo.content.signals;
    this.components = channelInfo.content.components;
    this.id = channelInfo.id;
    this.designId = channelInfo.designId;
    this.compPins = [];
    this.netsList = netsList;
    this.signalsConnection = new Map();
    this.getAllCompPins();
    this.getSignals();
  }

  getAllCompPins = () => {
    for (let comp of this.components) {
      for (let pin of comp.pins) {
        if (!pin.signal) {
          continue;
        }
        this.compPins.push({ ...pin, compName: comp.name, type: comp.type, pinsConnection: comp.pinsConnection })
      }
    }
  }

  getSignals = () => {

    for (let signal of this.signals) {
      const pins = this.compPins.filter(item => item.signal === signal.name);
      let nets_P = [], nets_N = [];
      const positivePins = pins.filter(item => signal.nets_P.includes(item.net));
      const negativePins = pins.filter(item => signal.nets_N.includes(item.net));
      for (let pin of positivePins) {
        const findPin = nets_P.find(item => !!item.find(it => it.pin === pin.pin && it.compName === pin.compName));
        if (findPin) {
          continue;
        }
        let list = [{
          ...pin,
          index: 0
        }];
        let comps = [pin.compName]
        list = this.getSignalConnByPin({ pinInfo: pin, list, comps, index: 0 });
        nets_P.push(list);
      }
      for (let pin of negativePins) {
        const findPin = nets_N.find(item => !!item.find(it => it.pin === pin.pin && it.compName === pin.compName));
        if (findPin) {
          continue;
        }
        let list = [{
          ...pin,
          index: 0
        }];
        let prevNet = pin.net;
        list = this.getSignalConnByPin({ pinInfo: pin, list, prevNet, index: 0 });
        nets_N.push(list);
      }
      this.signalsConnection.set(signal.name, { nets_P, nets_N });
    }
  }

  getSignalConnByPin = ({ pinInfo, list, prevNet, index = 0 }) => {

    const net = pinInfo.net;
    const netInfo = this.netsList.find(item => item.mName === net) || {};
    const mPinList = netInfo.mPinList || [];
    const filterPinList = mPinList.filter(item => (pinInfo.compName !== item.mCompName || item.mPinNum !== pinInfo.pin)
      && this.compPins.find(it => it.compName === item.mCompName && it.pin === item.mPinNum));
    if (filterPinList.length) {
      for (let pin of filterPinList) {
        const findPin = this.compPins.find(item => item.compName === pin.mCompName && item.pin === pin.mPinNum);
        if (!findPin || !!list.find(it => it.compName === findPin.compName && it.pin === findPin.pin)) {
          continue;
        }
        if ([...RLC_TYPES, CMC].includes(findPin.type)) {
          const connectedPin = this.getPinByCompPin(findPin);
          if (!connectedPin || !!list.find(it => it.compName === connectedPin.compName && it.pin === connectedPin.pin)
            || prevNet === connectedPin.net) {
            continue;
          }
          list.push({
            ...findPin,
            index: index + 1
          });
          list.push({
            ...connectedPin,
            index: index + 2
          });
          connectedPin && this.getSignalConnByPin({ pinInfo: connectedPin, list, prevNet: net, index: index + 2 });
        } else {
          list.push({
            ...findPin,
            index: index + 1
          });
        }
      }
    } else {
      return list;
    }

    return list
  }

  getPinByCompPin = (pinInfo) => {
    if (pinInfo.type === CMC) {
      const connectedPin = getConnectedPinNumber({
        pinNum: pinInfo.pin,
        pinMap: pinInfo.pinsConnection,
        pinLength: 4
      }) || "";
      return this.compPins.find(item => item.compName === pinInfo.compName && item.pin === connectedPin.toString());
    }
    if (RLC_TYPES.includes(pinInfo.type)) {
      const findComp = getComponentByName(this.designId, pinInfo.compName);
      if (!findComp) {
        return;
      }
      const pinList = findComp.mPart.mPinList || [];
      const findPin = pinList.find(item => pinInfo.pin !== item.mNumber);
      return this.compPins.find(item => item.compName === pinInfo.compName && item.pin === findPin.mNumber);

    }
  }
}

const channelConnection = new ChannelConnection();
export default channelConnection;