import UploadMonitor from './UploadMonitor';
import SimulationMonitor from './SimulationMonitor';
import DetailMonitor from './detailMonitor';
import ProfileTable from './profileTable';
import { monitorChild } from '@/components/MonitorFooter';
import { HIMALAYAS } from '../../../../constants/pageType';
import SpecifyService from '../../../CCC/SpecifyService';

function _monitorChild({
  menuType,
  tabVisible,
  tabSelectKeys,
  projectId,
  getCCCVerifications,
  getCurrentVerification,
  width = undefined,
  pageType = HIMALAYAS,
  titleSelectedKeys,
  setupType }) {
  return monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, width, pageType, titleSelectedKeys, setupType },
    UploadMonitor,
    SimulationMonitor,
    DetailMonitor,
    ProfileTable,
    SpecifyService
  )
}

export default _monitorChild;