import React, { Component, createRef, Fragment } from "react";
import { createPortal } from 'react-dom';
import { Select, Input } from "antd";
import Panel from '@/components/Panel';
import { DECAP_SPICE } from "../../../constants/libraryConstants";
import '@/publicCss/style.css';
import './index.css';

const Option = Select.Option;
class PartSelect extends Component {
  constructor(props) {
    super(props)
    const { model = {} } = this.props;
    this.state = {
      model,
      subckts: []
    }
    this.dialogRoot = document.getElementById('root');

  }

  componentDidMount = async () => {
    const { model } = this.state;
    const { defaultDecap } = this.props;
    if (model.libraryId) {
      const subckts = await this.props.getSubcktList(model.libraryId);
      this.setState({
        subckts
      })
    } else if (defaultDecap && defaultDecap.libraryId && defaultDecap.libraryType === DECAP_SPICE) {
      const subckts = await this.props.getSubcktList(defaultDecap.libraryId);
      const fileName = defaultDecap.name;
      this.setState({
        subckts,
        model: {
          ...model,
          ...defaultDecap,
          fileName
        }
      })
    }
  }

  closeModal = () => {
    this.props.save()
  }

  selectFile = async (libraryId) => {
    const { DecapSpiceList } = this.props;
    const file = DecapSpiceList.find(item => item.id === libraryId);
    this.setState({
      model: {
        libraryId,
        fileName: file.name,
        subckt: ""
      }
    }, async () => {
      const subckts = await this.props.getSubcktList(libraryId);
      this.setState({
        subckts
      })
    })
  }

  selectSubckt = (name) => {
    const { model } = this.state
    this.setState({
      model: {
        ...model,
        subckt: name
      }
    }, () => {
      const { record } = this.props;
      this.props.changeDecap(record, this.state.model)
    })
  }

  renderDialog = () => {
    const { name, DecapSpiceList } = this.props;
    const { model, subckts } = this.state;
    const content = (
      <Panel
        title='Part Select'
        onCancel={this.closeModal}
        zIndex={2000}
        position='panel-center-left'
        draggable
        minHeight={220}
        minWidth={400}
        width={500}
        maxWidth={500}
      >
        <div className="cascade-prelayout-part-select">
          <div className="decap-group-item">
            <span className="decap-group-item-title">Part</span>
            <Input
              className='aurora-input decap-group-item-input'
              value={name}
              disabled={true}
            />
          </div>
          <div className="decap-group-item">
            <span className="decap-group-item-title">File</span>
            <Select
              className='aurora-select decap-group-item-input'
              value={model.libraryId || ""}
              popupClassName='aurora-select-dropdown'
              onChange={this.selectFile}
            >
              {DecapSpiceList.map(item => <Option key={item.id}>{item.name}</Option>)}
            </Select>
          </div>
          <div className="decap-group-item">
            <span className="decap-group-item-title">Subckt</span>
            <Select
              className='aurora-select decap-group-item-input'
              value={model.subckt || ""}
              popupClassName='aurora-select-dropdown'
              onChange={this.selectSubckt}
            >
              {subckts.map(item => <Option key={item.name}>{item.name}</Option>)}
            </Select>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { inputRef } = this;
    const { text } = this.props;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PartSelect;
