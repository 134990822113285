import { SUCCESS } from '../../../constants/returnCode';
import { IMPEDANCE_DAT } from '../../../pages/Sierra/constants';
import { getFileInSierraPinToPinProject } from '../../api/sierra';
import PinToPinVerify from '../PinToPinVerifyDatabase';
import { getExperimentResultPromise } from '../projectCtrl';

function getSchematicImpedance({
  verificationId,
  design,
  projectId,
  experimentId }) {
  if (!design || !design.pcbSubId) {
    return [];
  }
  const { pcbSubId } = design;

  return getSchematicImpedanceResult({ verificationId, pcbSubId, projectId, experimentId }).then(res => {
    return parseSchematicImpedanceResult(res)
  }, error => {
    console.error(error);
    return { impedanceData: [], unit: "" }
  })
}

function getPreLayoutInterfaceSignalNets({ Interfaces, verificationName, name }) {
  const setup = PinToPinVerify;
  const { signals } = setup.mergeInterfacesInfo(Interfaces, verificationName);

  let nets = []; // [{ net: "",signal:"",pcb:"" }]
  for (let item of signals) {
    for (let n of item.nets) {
      // name - pcb name
      // filter pcb
      if (item.pcb === name) {
        nets.push({
          net: n,
          pcb: item.pcb,
          signal: item.name
        });
      }
    };
  };
  return nets;
}

function parseSchematicImpedanceResult(response) {
  if (!response) {
    return { impedanceData: [], unit: "" };
  }
  /* Unit: mil/mm
trace Signal impedance length traceWidth traceThickness zDiff
Trace1  SCL  50  10  5  1.4 50
Trace2  SCL  50  20  5  1.4 50
Trace3  SCL  50  10  5  1.4
Trace1  SDA 49  10  5   1.4 50
Trace2  SDA 50  10  5   1.4 50
Trace3  SDA 48  10  5   1.4 */
  let lines = response.match(/[^\r\n]+/g);
  let unit = "mil", impedanceData = [];
  for (let line of lines) {
    const words = line.match(/[^\s\t]+/g);
    if (!words || !words.length) {
      continue;
    }

    if (words[0].match("Unit") && words.length === 2) {
      unit = words[1];
      continue;
    }
    if (line.match(/Impedance/ig)) {
      //title line
      continue;
    }
    const [traceName, signal, impedance, length, traceWidth, traceThickness, Zdiff] = words;
    impedanceData.push({
      traceName,
      signal,
      impedance,
      length,
      traceWidth,
      traceThickness,
      Zdiff
    })
  }
  return { impedanceData, unit }
}

function getPreImpedanceResultBySignals(impedanceData, signalNets) {
  if (!signalNets || !signalNets.length || !impedanceData || !impedanceData.length) {
    return []
  }
  let data = [], index = 0;
  for (let netItem of signalNets) {
    const impedances = impedanceData.filter(item => item.signal === netItem.net);
    if (!impedances.length) {
      continue;
    }
    let allLength = [], allImpedances = [];
    let children = [], childIndex = 0;
    for (let item of impedances) {
      const index = children.findIndex(it => it.trace === item.traceName);
      allImpedances.push(Number(item.impedance));
      allLength.push(item.length);
      if (index > -1) {
        children[index].length = Number(item.length) + children[index].length;
      } else {
        children.push({
          key: `${netItem.net}::${netItem.signal}::${childIndex}::child`,
          net: netItem.net,
          signal: netItem.signal,
          trace: item.traceName,
          length: Number(item.length),
          impedance: Number(item.impedance),
          width: Number(item.traceWidth),
          thickness: Number(item.traceThickness),
          Zdiff: item.Zdiff ? Number(item.Zdiff) : "",
        })
        childIndex += 1;
      }
    }
    const sumLength = allLength.reduce((sum, current) => sum + Number(current), 0);
    const minZ0 = Math.min(...allImpedances), maxZ0 = Math.max(...allImpedances);

    data.push({
      key: `${netItem.net}::${netItem.signal}::${index}`,
      net: netItem.net,
      signal: netItem.signal,
      trace: children.length,
      minZ0,
      maxZ0,
      length: sumLength,
      traceList: children
    })
    index += 1;
  }
  return data;
}

function getSchematicImpedanceResult({ projectId, verificationId, pcbSubId, experimentId }) {
  return new Promise((resolve, reject) => {
    if (experimentId) {
      getExperimentResultPromise({ experimentId, option: IMPEDANCE_DAT, designId: pcbSubId }).then(response => {
        if (response && typeof (response) === "string") {
          resolve(response)
        } else if (response && response.data && response.data.code !== SUCCESS) {
          console.error(response.data.msg)
          reject(response.data.msg)
        } else {
          reject(null)
        }
      }, error => {
        reject(error);
      })
    } else {
      const pathID = `${verificationId}/result/data`;
      const fileName = `PCB${pcbSubId}_Impedance.dat`;
      getFileInSierraPinToPinProject(projectId, `${pathID}/${fileName}`).then(response => {
        if (response.data && typeof (response.data) === "string") {
          resolve(response.data)
        } else if (response && response.data && response.data.code !== SUCCESS) {
          console.error(response.data.msg)
          reject(response.data.msg)
        } else {
          reject(null)
        }
      }, error => {
        reject(error)
      });
    }
  })
}

export {
  getSchematicImpedance,
  getPreLayoutInterfaceSignalNets,
  getPreImpedanceResultBySignals
}