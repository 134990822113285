import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import './style.css';

class DebugMsg extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null
    }
    this.scrollRef = createRef();
  }


  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }


  render() {
    const { uploadDebug, projectName, currentProjectId, uploadProjectId, msg, endMsg, verificationName } = this.props;
    return (
      <Fragment>
        <div className='andes-simulation-title'>
          <Row className='andes-simulation-holygrail' span={24}>
            <Col className='andes-simulation-msg'
              span={20}>
              {projectName && <div className='andes-simulation-span andes-simulation-project'>
                <span className='span-msg-project font-bold'>Project </span>
                <span className='span-msg'>{projectName}</span>
              </div>}
              {verificationName && <div className='andes-simulation-span'>
                <span className='font-bold'>Interface </span>
                <span className='span-msg'>{verificationName} </span>
              </div>}
            </Col>
          </Row>
        </div>
        <div className='my-andes-monitor'>
          <pre style={{ margin: 20, overflow: 'unset' }}>
            {uploadProjectId === currentProjectId ?
              (<Fragment>
                {msg}
                {uploadDebug && uploadDebug.length > 0 &&
                  <ul className='andes-monitor-ul' style={{ paddingLeft: 34, margin: 0 }}>
                    {uploadDebug.length && uploadDebug.map(item =>
                      <li key={item.id} style={{ listStyle: 'none', width: '100%' }}>
                        <p style={{ margin: 0 }}>{item.time}  {item.log}</p>
                      </li>)}
                  </ul>}
                {endMsg}
              </Fragment>) : null}
          </pre>
          <div ref={(el) => { this.scrollRef = el; }}></div>
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { project: { currentProjectId } } = state.AndesReducer;
  const { debug, uploadProjectId, msg, endMsg } = state.AndesReducer.upload;
  const { projectName, tabVisible, debugMonitor, verificationName } = state.TabMonitorReducer;
  return {
    projectName,
    uploadProjectId,
    currentProjectId,
    uploadDebug: debug,
    tabVisible,
    msg,
    endMsg,
    debugMonitor,
    verificationName
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(DebugMsg);