import React, { Component, Fragment } from 'react';
import { Checkbox } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Input from 'antd/es/input/Input';
import { numberCheck } from "@/services/helper/dataProcess";
import './index.css';

class RleakRdlPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      apply: false,
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    const { rleak, rdl } = this.props
    this.setState({
      rleak,
      rdl
    })
  }

  closeModal = () => {
    const { rleak, rdl, apply } = this.state
    this.props.closeModal(rleak, rdl, apply)
  }

  inputChange = (e, key) => {
    let value = e.target.value;
    this.setState({
      [key]: value
    })
  }

  valueBlur = (e, key) => {
    let value = e.target.value;
    if (value) {
      const error = numberCheck(value);
      if (error) {
        e.target.focus();
      }
    }
    this.setState({
      [key]: value
    })
  }

  changeApply = () => {
    this.setState({
      apply: !this.state.apply
    })
  }

  applyRender = () => {
    const { apply } = this.state;
    return <div className="apply-footer">
      <Checkbox
        checked={apply}
        onChange={this.changeApply}
      />
      <span>
        Apply R-rdl and R-leak value to all Ports
      </span>
    </div>
  }

  renderDialog = () => {
    const { rdl, rleak } = this.state
    const content = (
      <Panel
        className='rleak-rdl-panel'
        id='rleak-rdl-panel'
        title={'R-rdl & R-leak Setting'}
        onCancel={this.closeModal}
        zIndex={2000}
        width={400}
        minWidth={300}
        minHeight={200}
        position='panel-center-left'
        draggable
        overflow={"auto"}
        footer={this.applyRender()}
      >
        <div className='rleak-rdl-content'>
          <div className='rleak-rdl-body'>
            <span className='rleak-rdl-input-title'>R-rdl</span>
            <Input
              className='rleak-rdl-input'
              value={rdl}
              onChange={(e) => this.inputChange(e, 'rdl')}
              size="small"
              addonAfter={'mΩ'}
              onBlur={(e) => this.valueBlur(e, 'rdl')}
            />
          </div>
          <div className='rleak-rdl-body'>
            <span className='rleak-rdl-input-title'>R-leak</span>
            <Input
              className='rleak-rdl-input'
              value={rleak}
              onChange={(e) => this.inputChange(e, 'rleak')}
              size="small"
              addonAfter={'MΩ'}
              onBlur={(e) => this.valueBlur(e, 'rleak')}
            />
          </div>
        </div>
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return <Fragment>
      {this.renderDialog()}
    </Fragment>
  }
}

export default RleakRdlPanel;
