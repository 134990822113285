import { ANDES_V2, CASCADE, ROCKY } from "../../../constants/pageType";
import { DIELECTRIC, METAL } from "../stackupConstants";

function MetalMaterial({ name = "", sigma = "58000000", miu_r = '1' }, product) {
  this.name = name;
  this.type = METAL; //enum: [Dielectric, Metal]
  this.sigma = sigma;
  if (product === ANDES_V2 || product === CASCADE || product === ROCKY) {
    this.miu_r = miu_r;
  }
}

function DielectricMaterial({
  name = "",
  eps_r = "4",
  delta = "0.02",
  freq_dep = "no",
  sigma = "1e-12",
  frequency = "1e9",
  freq_corner = "150e9",
  use_dc_eps = false,
  eps_dc = "4"
}) {
  this.name = name;
  this.type = DIELECTRIC;
  this.eps_r = eps_r; // float, dielectric constant
  this.delta = delta; //float, loss tangent
  this.freq_dep = freq_dep; //enum: [no, djordjevic-sarkar]
  if (freq_dep === "djordjevic-sarkar") {
    const config = new DepMaterial({ sigma, frequency, freq_corner, use_dc_eps, eps_dc });
    this.sigma = config.sigma;
    this.frequency = config.frequency;
    this.freq_corner = config.freq_corner;
    this.use_dc_eps = config.use_dc_eps;
    this.eps_dc = config.eps_dc;
  }
}

function DepMaterial({ sigma, frequency, freq_corner, use_dc_eps, eps_dc }) {
  this.sigma = sigma; //float, conductivity for djordjevic-Sarkar model
  this.frequency = frequency; //float, default = 1GHz, djordjevic-Sarkar model only
  this.freq_corner = freq_corner; //float, default = 150GHz, djordjevic-Sarkar model only
  this.use_dc_eps = use_dc_eps; //Boolean, default = No, djordjevic-Sarkar model only
  this.eps_dc = eps_dc; //float, dielectric constant at DC, djordjevic-Sarkar model only
}
/*  "name": unique material name,

"type": enum: [dielectric, metal],

"eps_r": float, dielectric constant,

"delta": float, loss tangent,

"sigma": float, conductivity, or DC conductivity for djordjevic-Sarkar model

"freq_dep": enum: [no, djordjevic-sarkar],

"frequency": float, default = 1GHz, djordjevic-Sarkar model only

"freq_corner": float, default = 150GHz, djordjevic-Sarkar model only

"use_dc_eps": Boolean, default = No, djordjevic-Sarkar model only

"eps_dc": float, dielectric constant at DC, djordjevic-Sarkar model only

} */

export {
  MetalMaterial,
  DielectricMaterial
}