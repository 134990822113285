import React, { PureComponent } from 'react';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import "./index.css"

class Menu extends PureComponent {
  constructor(props) {
    super(props);
    const expanded = props.defaultExpanded || false;
    this.state = {
      status: Array(props.menu.length).fill(expanded)
    }
  }

  onExpand = (index) => (e) => {
    e.stopPropagation();
    this.setState((state) => {
      const status = [...state.status];
      status[index] = !status[index];
      return { status };
    })
  }
  messageClick = (e) => {
    e.stopPropagation();
    return message.info(`Not available`);
  }

  getMenuItem = (item, i) => {
    const { onItemClick } = this.props;
    const expanded = this.state.status[i];
    const { children, value, title, className = "" } = item;
    if (children) {
      return (
        <li key={i} onClick={this.onExpand(i)} className={className}>
          {expanded ? <CaretDownOutlined className="expand-icon" /> : <CaretRightOutlined className="expand-icon" />}
          <span className="menu-list-title">{title}</span>
          {expanded ? <Menu menu={children} onItemClick={onItemClick} /> : null}
        </li>
      );
    } else if (value) {
      return (
        <li key={i} onClick={onItemClick(value)} className={className}>
          <a>{title || value}</a>
        </li>
      )
    } else if (title) {
      return (
        <li key={i} onClick={e => this.messageClick(e)} className={className}>
          <a>{title || value}</a>
        </li>
      )
    } else {
      return null;
    }
  }

  render() {
    const { menu } = this.props;
    return (
      <ul>
        {menu.map(this.getMenuItem)}
      </ul>
    )
  }
}

export default Menu;