import { getLibraryDataInfo, updateLibraryData } from '@/services/Cascade/library';
import compPinMap from '@/services/Cascade/helper/compPinMap';
import { versionCompareSize } from '../../helper/dataProcess';
import {
  groupPinMapPinsByOutPutPinNet,
  AddPinNumberToPinMap,
  pinMapDataUpdateByDesign
} from '../../Designs/pinMapHelper';
import {
  getComponentByPartName,
  checkConnectComp,
  getComponentByComponentName
} from '../helper/setupData';
import { PIN_MAP } from '@/constants/libraryConstants';

class PinMapStore {
  constructor() {
    this.store = new Map(); // key: libraryId, value: {}
    this.senseMap = new Map();// key: designId, value :pinTableLibraryMap -> key: partName, value:pinTable
  }

  cleanCache = () => {
    this.store = new Map();
  }

  savePinMap = (item, designId, partNumber) => {
    this.store.set(item.id, item);
    // save senseMap
    let currDesignPinMap = this.senseMap.get(designId);
    if (!currDesignPinMap) {
      currDesignPinMap = new Map();
    }
    currDesignPinMap.set(partNumber, item.config.pinTable);
    this.senseMap.set(designId, currDesignPinMap);
  }

  getPinMapLibrary = async (libraryInfo, designId, returnType) => {
    const { libraryId, partNumber, type } = libraryInfo || {};
    try {
      let pinMapInfo = this.store.get(libraryId);
      let pinStore = [], save = false, libraryName = null;

      if (pinMapInfo && pinMapInfo.config && pinMapInfo.config.pinTable) {
        pinStore = designId ? await this.getPinStoreByComponent({
          type,
          partNumber,
          designId
        }) : [];
        libraryName = pinMapInfo.name;
      } else {
        pinMapInfo = await getLibraryDataInfo(libraryId);
        const { config, version, name } = pinMapInfo || {};
        libraryName = name;
        let pinTable = config ? config.pinTable || [] : [];
        const isUpdateOutputPinGroup = versionCompareSize(version, "0.0.2");
        const isAddPinNumber = versionCompareSize(version, "0.0.3");
        if (isUpdateOutputPinGroup || isAddPinNumber) {
          pinStore = designId ? await this.getPinStoreByComponent({
            type,
            partNumber,
            designId
          }) : []
        }
        const findString = pinTable.find(item => !!item.output.find(it => typeof it === "string"));
        if (isUpdateOutputPinGroup && designId && findString) {
          pinTable = groupPinMapPinsByOutPutPinNet({ pinTable, pinStore });
          save = true;
        }
        if (isAddPinNumber && findString) {
          const targetPins = pinTable.map(item => item["output"]).flat(2) || [];
          const notFindOutputPins = targetPins.filter(item => !pinStore.find(it => `${it.comp ? `${it.comp}_` : ""}${it.pinName}` === item));
          pinTable = AddPinNumberToPinMap({ pinTable, pinStore });
          if (notFindOutputPins.length && designId) {
            pinTable = groupPinMapPinsByOutPutPinNet({ pinTable, pinStore, type: "obj" });
          }
          save = true;
        }

        pinMapInfo.config.pinTable = pinTable;
        if (designId && pinTable && pinTable.length > 0) {
          this.store.set(libraryId, pinMapInfo);
        }
      }

      const _pinMapInfo = JSON.parse(JSON.stringify(pinMapInfo || {}));
      if (returnType === "pinName" && _pinMapInfo && _pinMapInfo.config && _pinMapInfo.config.pinTable) {
        _pinMapInfo.config.pinTable = pinMapDataUpdateByDesign(_pinMapInfo.config.pinTable, pinStore);
      }
      if (save && libraryName) {
        const data = {
          id: libraryId || '',
          name: libraryName,
          config: { pinTable: pinMapInfo.config.pinTable },
          version: pinMapLibraryVersion,
          type: PIN_MAP
        }

        await updateLibraryData({ data });
      }
      return _pinMapInfo;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  getPinStoreByComponent = async ({
    type,
    partNumber,
    designId
  }) => {
    let pinStore = [];
    if (type === 'buckConverter') {
      const _comps = partNumber.split(' - ');
      const comps = getComponentByComponentName(_comps, designId);
      for (let comp of comps) {
        const { name, pins } = comp;
        const _pinStore = await checkConnectComp(name, [...pins.values()], designId);
        pinStore.push(..._pinStore.map(p => ({ ...p, comp: name })))
      }
    } else {
      const comps = getComponentByPartName(partNumber, designId);
      if (comps.length) {
        const { name, pins } = comps[0];
        pinStore = await checkConnectComp(name, [...pins.values()], designId);
      }
    }
    return pinStore;
  }

  async getPinMapSense(designId, returnType) {
    const ans = await compPinMap.getPinMap(designId)
    const pinMaps = (ans && ans.map && ans.map.filter(item => !!item.libraryId)) || []

    let currDesignPinMap = this.senseMap.get(designId);
    if (!currDesignPinMap) {
      currDesignPinMap = new Map();
    }
    let returnPinMap = new Map();
    for (const item of pinMaps) {
      try {
        const partName = item.partNumber;
        let pinStore = [], _pinTable = [];
        if (returnType === "pinName") {
          pinStore = await this.getPinStoreByComponent({
            type: item.type,
            partNumber: partName,
            designId
          })
        }
        if (!currDesignPinMap.get(partName)) {
          let pinMapInfo = await this.getPinMapLibrary(item, designId);
          if (pinMapInfo && pinMapInfo.config) {
            const pinTable = pinMapInfo.config.pinTable;
            if (pinTable && pinTable.length > 0) {
              currDesignPinMap.set(partName, pinTable);
              this.senseMap.set(designId, currDesignPinMap);
              _pinTable = JSON.parse(JSON.stringify(pinTable))
            }

          }
        } else {
          const pinTable = currDesignPinMap.get(partName);
          _pinTable = JSON.parse(JSON.stringify(pinTable));
        }
        if (returnType === "pinName") {
          _pinTable = pinMapDataUpdateByDesign(_pinTable, pinStore);
        }
        returnPinMap.set(partName, _pinTable);
      } catch (error) {
        console.error(error)
      }
    }
    return returnPinMap
  }
}

const pinMapStore = new PinMapStore();
const pinMapLibraryVersion = "0.0.4";

export default pinMapStore;
export {
  pinMapLibraryVersion
}