import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { numberCheck } from '@/services/helper/dataProcess';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input, Select, Progress, Spin } from 'antd';
import { VRM, DECAP_RLC } from '../../../constants/libraryConstants';
import { getLibraryDataInfo, updateLibraryData } from '../../../services/Cascade/library'
import { changeLibraryFormat } from '@/services/helper/rlcValueFormat';
import NP from 'number-precision';

const { Option } = Select;
const DecapRUnit = ['Ω', 'KΩ', 'MΩ'];
const RUnit = ['mΩ', 'Ω'];
const LUnit = ['pH', 'nH', 'uH'];
const CUnit = ['pF', 'nF', 'uF'];

class VRMPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VRMDataList: { R: '', L: '' },
      RUnit: 'Ω',
      LUnit: 'nH',
      CUnit: 'nF',
      name: null,
      RValue: null,
      LValue: null,
      CValue: null,
      id: null,
      uploading: false,
      edit: false,
    };
    this.dialogRoot = document.getElementById('root');
  }

  selectAfter = (unit, units) => {
    return (
      <Select dropdownStyle={{ zIndex: 10000 }} className='unit-select' value={this.state[unit]} onChange={(e) => this.changeUnit(e, unit)}>
        {units.map(item =>
          <Option value={item} key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  changeUnit = (key, unit) => {
    this.setState({
      [unit]: key,
      edit: true
    })
  }

  saveInputValue = (e, type) => {
    this.setState({
      [`${type}Value`]: e.target.value,
      errorMsg: null,
      edit: true
    })
  }

  saveValue = (e, type) => {
    let value = e.target.value;
    //Check the value format
    let error = numberCheck(value);

    if (error) {
      let errorMsg = '';
      if (type === 'R') {
        errorMsg = `Resistance ${error}`;
      } else if (type === 'L') {
        errorMsg = `Inductance ${error}`;
      } else if (type === 'C') {
        errorMsg = `Capacitance ${error}`;
      }
      this.setState({
        errorMsg
      })
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { libraryId } = this.props;
    if (libraryId !== prevProps.libraryId) {
      this.getData();
    }
  }

  getData = () => {
    const { defaultVRMName, libraryId, name } = this.props;
    if (!libraryId) {
      this.setState({
        name: defaultVRMName,
        VRMDataList: { R: '', L: '', c: '' },
        RUnit: 'Ω',
        LUnit: 'nH',
        CUnit: 'nF',
        RValue: null,
        LValue: null,
        CValue: null
      })
      return;
    }
    getLibraryDataInfo(libraryId).then(res => {
      if (res && res.config && Object.keys(res.config).length > 0) {
        const libraryUnit = changeLibraryFormat(res.config);
        let data = { ...libraryUnit };
        let rUnit = data.RUnit;
        if (data.RUnit === 'KΩ') {
          rUnit = 'Ω';
          data.libraryData.R = NP.strip(NP.times(data.libraryData.R, 1000));
        }

        if (libraryUnit.RUnit === 'MΩ') {
          rUnit = 'Ω';
          data.libraryData.R = NP.strip(NP.times(data.libraryData.R, 1e6));
        }

        this.setState({
          name: res.name,
          id: res.id,
          VRMDataList: data.libraryData,
          RUnit: rUnit,
          LUnit: data.LUnit,
          CUnit: data.CUnit,
          RValue: data.libraryData.R,
          LValue: data.libraryData.L,
          CValue: data.libraryData.C
        })
      } else {
        this.setState({
          name,
          id: libraryId,
          VRMDataList: { R: '', L: '' },
          RUnit: 'Ω',
          LUnit: 'nH',
          CUnit: 'nF',
          RValue: null,
          LValue: null,
          CValue: null,
        })
      }
    });
  }


  changeName = (e) => {
    if (e.target.value) {
      this.setState({
        name: e.target.value,
        errorMsg: null,
        edit: true
      })
    } else {
      this.setState({
        name: null,
        errorMsg: null,
        edit: true
      })
    }
  }

  saveName = (e) => {
    const { libraryList, libraryId, title } = this.props;
    if (e.target.value) {
      const index = libraryList.findIndex(item => item.name === e.target.value && item.id !== libraryId);
      if (index > -1) {
        e.target.focus();
        this.setState({
          errorMsg: `${title} name already exists!`,
          name: null
        });
      } else {
        //Whether the file name is legal.
        const errorWords = e.target.value ? e.target.value.match(/[^0-9a-zA-Z_-]+/g) : null;
        if (errorWords && errorWords.length > 0) {
          this.setState({
            errorMsg: `${title} name may only contain the following characters: numbers, letters, underscores, minus.`
          });
        } else {
          this.setState({
            name: e.target.value,
            errorMsg: ''
          });
        }
      }
    } else {
      e.target.focus();
      this.setState({
        name: null,
        errorMsg: `${title} model name not set.`,
      })
    }
  }

  closeModal = () => {
    let { VRMDataList, name, RUnit, LUnit, RValue, LValue, edit, CValue, CUnit } = this.state;
    const { modelType, title } = this.props;
    let data = { ...VRMDataList };
    data.R = RValue;
    data.L = LValue;
    data.C = CValue;

    if (!edit) {
      this.props.closeLibraryPanel();
      return;
    }

    if (!name) {
      this.setState({
        errorMsg: `${title} model name not set.`
      })
      return;
    }

    //Whether the file name is legal.
    const errorWords = name ? name.match(/[^0-9a-zA-Z_-]+/g) : null;
    if (errorWords && errorWords.length > 0) {
      this.setState({
        errorMsg: `${title} name may only contain the following characters: numbers, letters, underscores, minus.`
      })
      return;
    }

    const { libraryList, libraryId } = this.props;
    const index = libraryList.findIndex(item => item.name === name && item.id !== libraryId);
    if (index > -1) {
      this.setState({
        errorMsg: `${title} name already exists!`
      })
      return;
    }

    this.setState({
      errorMsg: ''
    })
    if ((modelType === VRM && !RValue && !LValue) || (modelType === DECAP_RLC && !RValue && !LValue && !CValue)) {
      this.props.closeLibraryPanel();
      return;
    } else {

      let error = numberCheck(RValue);

      if (error) {
        this.setState({
          errorMsg: `Resistance ${error}`
        })
        return;
      }

      //Check the Inductance value format
      error = numberCheck(LValue);

      if (error) {
        this.setState({
          errorMsg: `Inductance ${error}`
        })
        return;
      }
      if (modelType === DECAP_RLC) {
        //Check the Capacitance value format
        error = numberCheck(CValue);

        if (error) {
          this.setState({
            errorMsg: `Capacitance ${error}`
          })
          return;
        }
      }
      this.saveVRMData({ name, VRMData: { ...data }, RUnit, LUnit, CUnit });
    }
  }

  saveVRMData = ({ name, VRMData, RUnit, LUnit, CUnit }) => {
    const { libraryId, modelType } = this.props;

    const { uploadProgress } = this.state;
    let libraryData = {
      // ...VRMData
      R: VRMData.R,
      L: VRMData.L
    }

    if (uploadProgress > -1) {
      return;
    }

    let runit = RUnit.slice(0, 1) === 'Ω' ? '' : RUnit.slice(0, 1);
    let lunit = LUnit.slice(0, 1);
    let cunit = CUnit.slice(0, 1);
    if (libraryData.R) {
      libraryData.R = `${libraryData.R}${runit}`;
    }
    if (libraryData.L) {
      libraryData.L = `${libraryData.L}${lunit}`;
    }
    if (VRMData.C) {
      libraryData.C = `${VRMData.C}${cunit}`;
    }

    this.setState({
      uploading: true,
      uploadProgress: 0
    })

    const data = {
      id: libraryId ? libraryId : "",
      name,
      type: modelType,
      config: libraryData,
      version: "0.0.1"
    }

    updateLibraryData({ data, config: this.config }).then(res => {
      const { expandedKeys } = this.props;
      let keys = [...expandedKeys];
      this.props.updateLibraryMenu();

      if (!keys.includes(modelType)) {
        keys.push(modelType);
      }
      this.props.expandMenu([...keys]);

      setTimeout(() => {
        this.setState({
          uploading: false
        });
        this.props.closeLibraryPanel();
      }, 500);

    })
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  getProgressFormat = (percent) => {
    if (percent === 100) {
      return <CheckCircleOutlined className='upload-success-icon' />;
    } else {
      return `${percent}%`;
    }
  };


  render() {
    const { title, modelType } = this.props;
    const { name, RValue, LValue, errorMsg, uploadProgress, uploading, CValue } = this.state;
    const content = (
      <Panel
        className='library-data-panel'
        position='panel-center-left'
        title={title}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={400}
        draggable
        minHeight={200}
      >
        <div className='library-data-panel-content-box'>
          {uploadProgress > -1 && <Progress
            size={{ height: 12 }}
            strokeColor={'#1890ff'}
            percent={uploadProgress}
            format={(percent) => this.getProgressFormat(percent)}
            className="library-edit-upload-progress-bar"
          />}
          <Spin spinning={uploading} tip='Uploading...'>
            <Input className='model-input' placeholder='Name' value={name} onChange={(e) => this.changeName(e)} onBlur={(e) => this.saveName(e)} />
            {modelType === VRM ? <Fragment>
              <Input className='rlc-input' value={RValue} addonAfter={this.selectAfter('RUnit', RUnit)} placeholder='Resistance' onChange={(e) => this.saveInputValue(e, "R")} onBlur={(e) => this.saveValue(e, 'R')} />
              <Input className='rlc-input' value={LValue} addonAfter={this.selectAfter('LUnit', LUnit)} placeholder='Inductance' onChange={(e) => this.saveInputValue(e, "L")} onBlur={(e) => this.saveValue(e, 'L')} />
            </Fragment> : <Fragment>
              <Input className='rlc-input' value={RValue} addonAfter={this.selectAfter('RUnit', DecapRUnit)} placeholder='Resistance' onChange={(e) => this.saveInputValue(e, "R")} onBlur={(e) => this.saveValue(e, 'R')} />
              <Input className='rlc-input' value={LValue} addonAfter={this.selectAfter('LUnit', LUnit)} placeholder='Inductance' onChange={(e) => this.saveInputValue(e, "L")} onBlur={(e) => this.saveValue(e, 'L')} />
              <Input className='rlc-input' value={CValue} addonAfter={this.selectAfter('CUnit', CUnit)} placeholder='Capacitance' onChange={(e) => this.saveInputValue(e, "C")} onBlur={(e) => this.saveValue(e, 'C')} />
            </Fragment>}

            {errorMsg && <span className='model-name-error-msg'>{errorMsg}</span>}
          </Spin>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default VRMPanel
