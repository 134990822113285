import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { updateServiceConfig, saveServiceOption } from '../store/project/action';
import { getServiceConfig } from "../../../services/Andes_v2/project";
import ServiceOption from "../../../components/ServiceOptions";
import { ANDES_V2 } from "../../../constants/pageType";

class ServiceOptionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  closeModal = () => {
    this.props._saveServiceOption();
    this.props.closePanel();
  }

  render() {
    const { serviceOptions, openProjectId } = this.props;
    return <ServiceOption
      adsOption={serviceOptions ? serviceOptions.adsOption : ""}
      ansysOption={serviceOptions ? serviceOptions.ansysOption : ""}
      hspiceOption={serviceOptions ? serviceOptions.hspiceOption : ""}
      optionsValue={serviceOptions ? serviceOptions.options : {}}
      projectId={openProjectId}
      closeModal={this.closeModal}
      getServiceOptionsConfig={getServiceConfig}
      product={ANDES_V2}
      _updateServiceConfig={this.props._updateServiceConfig}
    />
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { project: { openProjectId, serviceOptions } } } = state;
  return {
    openProjectId,
    serviceOptions
  }
}

const mapDispatch = (dispatch) => ({
  _updateServiceConfig(serviceOptions) {
    dispatch(updateServiceConfig(serviceOptions))
  },
  _saveServiceOption() {
    dispatch(saveServiceOption())
  }
})

export default connect(mapState, mapDispatch)(ServiceOptionPanel);