function checkNameFormat(name, setting = {}) {
  const { errorType = "Name", maxLength = 50, reg, allowEmpty = false } = setting;

  if (allowEmpty && !name) {
    return false
  }

  //name not set
  if (!name && !allowEmpty) {
    return checkNameErrorList(0, errorType);
  }

  //name format
  let errorWords = reg ? name.match(reg) : name.match(/[^0-9a-zA-Z_$-]+/g);
  if (errorWords && errorWords.length > 0) {
    return checkNameErrorList(1, errorType);
  }

  //name length
  if (name && name.length >= maxLength) {
    return checkNameErrorList(2, errorType, maxLength);
  }

  return false;
}

function checkNameErrorList(index, type, maxLength) {
  const list = [
    "cannot be empty.",
    "may only contain the following characters: numbers, letters, underscores, minus, dollar sign."
  ]
  if (index === 2) {
    return `The length of the ${type.toLowerCase()} must be less than ${maxLength} characters.`
  } else {
    return `${type} ${list[index]}`
  }
}

export {
  checkNameFormat,
  checkNameErrorList
}