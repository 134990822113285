import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '../../Panel';
import Table from '../../EditableTable';
import auroraDBJson from '../../../services/Designs/auroraDbData';
import '../index.css';
import PinConnectionSetting, { CLEAR, HEADTAIL, NEARBY } from '../../PMICPart/pinConnectionSetting';

const Columns = [{
  title: "Pin 1",
  dataIndex: 'pinsLeft',
  width: '50%'
}, {
  title: "Pin 2",
  dataIndex: 'pinsRight',
  width: '50%'
}]
const pinData = { pinsLeft: "", pinsRight: "" }
class PinConnection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 500,
      dataSource: [],
      pins: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.getData();
    this.getColumns();
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleHeight);
  }

  handleHeight = () => {
    const { height = 800 } = this.dialogRoot.getBoundingClientRect();
    this.setState({
      panelMaxHeight: height - 200
    });
  }

  getColumns = () => {
    Columns[0].render = (text) => {
      return <span>{text || ''}</span>
    }

    Columns[1].render = (text) => {
      return <span>{text || ''}</span>
    }

    const dataIndexs = [{ index: 0, dataIndex: 'pinsLeft' }, { index: 1, dataIndex: 'pinsRight' }];
    dataIndexs.forEach(item => {
      const { index, dataIndex } = item;
      Columns[index].onCell = (record) => {
        const { pins, dataSource } = this.state;
        const usedPins = dataSource.map(i => ([i['pinsLeft'], i['pinsRight']])).flat(2).filter(pin => !record[dataIndex] || !record[dataIndex].includes(pin));
        const options = pins.map(p => ({ value: p.pin, disabled: usedPins.includes(p.pin), title: `${p.pin}::${p.pinName} (${p.net})` })).sort((a, b) => !a.disabled && b.disabled ? -1 : 1)
        return {
          edit: 'select',
          record,
          options: options,
          dataIndex,
          // selectMode: 'multiple',
          allowClear: true,
          handleSave: this.savePins,
          dropdownMenuClassName: 'pin-connection-select-dropdown-menu',
          getPopupContainer: document.getElementById('components-setting-dialog'),
          handleClear: this.clearPins
        }
      }
    })
  }

  getData = () => {
    const { designId, partNumber, pinMap = [] } = this.props;
    const components = auroraDBJson.getComponents(designId) || {};
    const comps = [...components.values()];
    const component = comps.find(c => c.partName === partNumber);
    if (component) {
      let pinLength = component.pins.size;
      const pins = [...component.pins.values()] || []
      pinLength = (pinLength % 2 === 0 ? pinLength : pinLength + 1) / 2;
      let dataSource = [];
      for (let i = 0; i < pinLength; i++) {
        dataSource.push({ index: i, ...pinData });
      }
      pinMap.forEach((array, index) => {
        const pinsLeft = array[0] ? array[0] : '';
        const pinsRight = array[1] ? array[1] : '';
        dataSource[index] = { ...dataSource[index], pinsLeft, pinsRight }
      })
      this.setState({
        dataSource,
        pins
      })
    }
  }

  savePins = (record) => {
    const index = record.index;
    const { dataSource } = this.state;
    const _dataSource = [...dataSource];
    const findIndex = _dataSource.findIndex(i => i.index === index);
    if (findIndex > -1) {
      _dataSource[findIndex] = { ...record };
      this.setState({
        dataSource: _dataSource
      })
    }
  }

  clearPins = (record, selectType) => {
    const index = record.index;
    const { dataSource } = this.state;
    const _dataSource = [...dataSource];
    const findIndex = _dataSource.findIndex(i => i.index === index);
    if (findIndex > -1) {
      _dataSource[findIndex] = { ...record, [selectType]: '' };
      this.setState({
        dataSource: _dataSource
      })
    }
  }

  closeModal = () => {
    const { dataSource } = this.state;
    const { partNumber } = this.props;
    const pinMap = [];
    dataSource.forEach(item => {
      const { pinsLeft, pinsRight } = item;
      if (pinsLeft || pinsRight) {
        const left = pinsLeft ? pinsLeft : '';
        const right = pinsRight ? pinsRight : '';
        pinMap.push([left, right]);
      }
    })
    this.props.savePinMap(partNumber, pinMap)
    this.props.save()
  }

  autoSetConnection = (autoType) => {
    const { dataSource, pins } = this.state;
    const _dataSource = [...dataSource];
    const _pins = pins.sort((a, b) => Number(a.pin) - Number(b.pin));
    let loopNumber = 0;
    switch (autoType) {
      case HEADTAIL:
        const half = Math.floor(_pins.length / 2);
        _dataSource.forEach(item => {
          if (loopNumber < half) {
            item.pinsLeft = _pins[loopNumber] ? _pins[loopNumber].pin : ''
            item.pinsRight = _pins[half * 2 - 1 - loopNumber] ? _pins[half * 2 - 1 - loopNumber].pin : ''
            loopNumber = loopNumber + 1;
          }
        })
        break;
      case NEARBY:
        _dataSource.forEach(item => {
          item.pinsLeft = _pins[loopNumber] ? _pins[loopNumber].pin : ''
          item.pinsRight = _pins[loopNumber + 1] ? _pins[loopNumber + 1].pin : ''
          loopNumber = loopNumber + 2;
        })
        break;
      case CLEAR:
        _dataSource.forEach(item => {
          item.pinsLeft = ''
          item.pinsRight = ''
        })
        break;
      default:
        break;
    }
    this.setState({
      dataSource: _dataSource
    })
  }


  render() {
    const { panelMaxHeight = 800, width, dataSource } = this.state;
    const { partNumber } = this.props;
    const _dataSource = dataSource.map((record, index) => ({ ...record, index }))
    const content = (
      <Fragment>
        <Panel
          className='component-RLC-panel component-PMIC-panel'
          title={`Pin Map - ${partNumber}`}
          onCancel={this.closeModal}
          zIndex={2000}
          width={width}
          position='panel-center-left'
          draggable
          minHeight={220}
          minWidth={400}
          maxHeight={panelMaxHeight}
        >
          <PinConnectionSetting autoSetConnection={this.autoSetConnection} />
          <div className='component-pcb-pin-map-table'>
            <Table
              columns={Columns}
              dataSource={_dataSource}
              rowKey={record => record.index}
              size="small"
            />
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PinConnection;