import { getSierraResultDisplaySettings, saveSierraResultDisplaySettings } from "../../api/sierra";
import apiProcess from "../../api/utility";
import { userDefaultSettings } from "../../userDefaultSetting/userDefaultSettingCtrl";


/** 
 * Get result display setting 
 * 20240929
 * @param {string} verificationId
 * **/
function getResultDisplaySettings(verificationId) {
  return apiProcess(getSierraResultDisplaySettings, verificationId);
}

/** 
 * Get result display setting 
 * 20240929
 * @param {string} verificationId
 * @param {object} setting {waveform:{displayMode}, sParameter}
 * **/

function saveResultDisplaySettings({ verificationId, setting }) {
  return apiProcess(saveSierraResultDisplaySettings, { verificationId, setting });
}


async function getWaveformDisplayMode(verificationId, defaultDisplayMode) {
  let displayMode = defaultDisplayMode || "Hi-Re";
  const displayModes = ["Hi-Re", "Fast"];
  try {
    //get current verification setting
    const currSettings = await getResultDisplaySettings(verificationId);

    if (!currSettings || !currSettings.waveform || !currSettings.waveform.displayMode) {
      //get setting by sierra user settings
      return getDisplayModeByUserSettings(verificationId, displayMode)
    } else if (displayModes.includes(currSettings.waveform.displayMode)) {
      displayMode = currSettings.waveform.displayMode;
    }
  } catch (error) {
    console.error(error)
    return getDisplayModeByUserSettings(verificationId, displayMode)
  }
  return displayMode;
}

async function getDisplayModeByUserSettings(verificationId, displayMode) {
  const displayModes = ["Hi-Re", "Fast"];
  //get setting by sierra user settings
  const userDefaultSetting = await userDefaultSettings.getSettings();

  const resultSettings = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings.resultSettings : null;

  const _displayMode = resultSettings
    && resultSettings.waveform && resultSettings.waveform.displayMode
    ? resultSettings.waveform.displayMode : "Hi-Re";

  displayMode = displayModes.includes(_displayMode) ? _displayMode : displayMode;
  saveResultDisplaySettings({ verificationId, setting: { waveform: { displayMode } } });
  return displayMode;
}

export {
  getResultDisplaySettings,
  saveResultDisplaySettings,
  getWaveformDisplayMode
}