import {
  CHANGE_RESULT_KEY,
  GET_RESULT_LIST,
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  currentResultKey: 'returnLoss',
  currentResult: '',
}

export const resultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_RESULT_KEY:
      return {
        ...state,
        currentResultKey: action.key
      };
    case GET_RESULT_LIST:
      return {
        ...state,
        currentResult: action.currentResult,
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default: return state;
  }
};