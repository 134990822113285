import {
  UPDATE_MULTI_SIMULATION_INFO,
  UPDATE_SIMULATION_REDUCER,
  UPDATE_SIMULATION_INFO,
  DOES_SIMULATION_FAILED,
  CLEAR_SIMULATION_INFO
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  simulationFinishId: ""
};

export const CascadeSimulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SIMULATION_REDUCER:
      return {
        ...action.simulation
      }
    case UPDATE_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: state[action.verificationId] ?
          {
            ...state[action.verificationId],
            [action.item]: action.info
          } : { [action.item]: action.info }
      }
    case UPDATE_MULTI_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: state[action.verificationId] ? {
          ...state[action.verificationId],
          ...action.info
        } : { ...action.info }
      }
    case DOES_SIMULATION_FAILED:
      return {
        ...state,
        simulationFinishId: action.id
      }
    case CLEAR_SIMULATION_INFO:
      return {
        ...defaultState
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default: return state
  }
} 