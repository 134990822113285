import React, { Fragment } from 'react';
import { Input, Divider, Switch, Button, Radio } from 'antd';
import './index.css';

const MultiZoneOptions = (props) => {
  const {
    isMultiZone, multiZonePreview, isPowerSI, className, zoneOptions,
    changeBooleanSwitch, radioChange, changeValue, inputBlur, onPressEnter, previewMultiZone, cleanupFn, hideDivider, bottomDivider
  } = props;
  if (!isMultiZone) {
    return <Fragment></Fragment>;
  }
  const { multiZone, cleanup, alignZoneShape, distance, fixOverlapping, createZones, cleanupLoading, enableBends } = zoneOptions;
  // const cleanupPowerSI = cleanup === 'PowerSI' ? true : false
  const cleanupPowerSI = true
  return <Fragment>
    {hideDivider ? null : <Divider />}
    <div className={`comp-extraction-option-content ${className}`}>
      <span className={`comp-extraction-option-content-body`}>Multi-Zone</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={multiZone || false}
        onChange={(checked) => changeBooleanSwitch(checked, 'multiZone')}
      />
    </div>
    {
      multiZone && <Fragment>
        {
          isPowerSI && <Fragment>
            <div className={`comp-extraction-option-content ${className}`}>
              <span className={`comp-extraction-option-content-body comp-extraction-option-sub-span`}>Cleanup</span>
              {/* <span>
                <Radio.Group value={cleanup} onChange={(e) => radioChange(e, 'cleanup')}>
                  <Radio value={'PowerSI'}>PowerSI</Radio>
                  <Radio value={'Aurora'}>Aurora</Radio>
                </Radio.Group>
              </span> */}
            </div>

            <div className={`comp-extraction-option-content ${className}`}>
              <span className={`comp-extraction-option-content-body comp-extraction-option-next-sub-span`}>Align zone shape</span>
              {cleanupPowerSI && <Switch
                size="small"
                className="aurora-switch-small comp-extraction-option-distance-switch"
                checked={alignZoneShape || false}
                onChange={(checked) => changeBooleanSwitch(checked, 'alignZoneShape')}
              />}
            </div>
            {(!cleanupPowerSI || alignZoneShape !== false) && <div className={`comp-extraction-option-content ${className}`}>
              <span className={`comp-extraction-option-content-body comp-extraction-option-next-sub-span`} style={{ paddingLeft: 60 }}>distance smaller than</span>
              <Input
                className={`comp-extraction-option-content-input comp-extraction-option-input-addonAfter ${(!cleanupPowerSI || !isPowerSI) ? 'comp-extraction-option-distance-input' : ''}`}
                value={distance}
                addonAfter={'mm'}
                onChange={(e) => changeValue(e, 'distance')}
                onBlur={(e) => inputBlur(e, 'distance')}
                onPressEnter={onPressEnter}
              />
              {/* {(!cleanupPowerSI || !isPowerSI) && <Fragment>
                <Button
                  className={'comp-extraction-option-multi-zone-button'}
                  type={multiZonePreview ? "primary" : ""}
                  onClick={(e) => multiZonePreview ? null : previewMultiZone(e)}
                >{multiZonePreview ? <Icon type="loading" /> : 'Preview'}</Button>
                <Button
                  className={'comp-extraction-option-multi-zone-button'}
                  type={cleanupLoading ? "primary" : ""}
                  onClick={(e) => cleanupLoading ? null : cleanupFn(e)}
                >{cleanupLoading ? <Icon type="loading" /> : 'Cleanup'}</Button>
              </Fragment>} */}
            </div>}
          </Fragment>
        }
        {
          isPowerSI && cleanupPowerSI && <Fragment>
            <div className={`comp-extraction-option-content ${className}`}>
              <span className={`comp-extraction-option-content-body comp-extraction-option-next-sub-span`} style={{ width: '60%' }}>Fix shape overlapping between zone shapes</span>
              <Switch
                size="small"
                className="aurora-switch-small comp-extraction-option-distance-switch"
                checked={fixOverlapping || false}
                onChange={(checked) => changeBooleanSwitch(checked, 'fixOverlapping')}
              />
            </div>
            {/* <div className={`comp-extraction-option-content ${className}`}>
              <span className={`comp-extraction-option-content-body comp-extraction-option-next-sub-span`} style={{ width: '60%' }}>Create zone(s) for undefined area(s) using primary stackup</span>
              <Switch
                size="small"
                className="aurora-switch-small comp-extraction-option-distance-switch"
                checked={createZones || false}
                onChange={(checked) => changeBooleanSwitch(checked, 'createZones')}
              />
            </div> */}
          </Fragment>
        }
        {
          !isPowerSI && <Fragment>
            {
              <div className={`comp-extraction-option-content ${className}`}>
                <span className={`comp-extraction-option-content-body comp-extraction-option-next-sub-span`}>Enable Bends</span>
                <Switch
                  size="small"
                  className="aurora-switch-small"
                  checked={enableBends || false}
                  onChange={(checked) => changeBooleanSwitch(checked, 'enableBends')}
                />
              </div>
            }
          </Fragment>
        }
      </Fragment>
    }
    {bottomDivider && <Divider />}
  </Fragment>
}

export default MultiZoneOptions;