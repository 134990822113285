function changeEffectToTableData(ports, barList) {
  let childColumns = [], dataSource = [];
  let _barList = barList.map(id => ({ id, res: [], ind: [] }))
  for (let index in ports) {
    const { port, powerPins, referencePins, value } = ports[index];
    _barList.forEach(bar => {
      const findIndex = value.findIndex(v => bar.id === v.id);
      if (findIndex > -1) {
        bar.res.push(value[findIndex].res);
        bar.ind.push(value[findIndex].ind);
        bar.name = value[findIndex].name;
      } else {
        bar.res.push(undefined);
        bar.ind.push(undefined);
      }
    })
    dataSource.push({ port, powerPins, referencePins })
  }

  childColumns = _barList.filter(item => item.name)
  for (let index in dataSource) {
    childColumns.forEach(child => {
      dataSource[index][`res_${child.id}`] = child.res[index] ? child.res[index].toFixed(2) : undefined;
      dataSource[index][`ind_${child.id}`] = child.ind[index] ? child.ind[index].toFixed(2) : undefined;
    })
  }

  return {
    childColumns: childColumns.map(item => ({ id: item.id, name: item.name })),
    dataSource
  }
}

export {
  changeEffectToTableData
}