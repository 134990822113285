import {
  CHANGE_RESULT_UPLOAD_PROGRESS,
  CHANGE_FILE_STATUS,
  CHANGE_RESULT_TYPE,
  GET_DECAP_INFO_SUCCESS,
  UPDATE_DC_SELECTED_KEY,
  SAVE_DC_VALUE,
  GET_DC_INFO_SUCCESS,
  CHANGE_DC_RESULT_TYPE,
  UPDATE_DC_RESULT_TABLE_LOADING,
  UPDATE_DC_RESULT_STATUS,
  CLEAR_DC_RESULT_DATA
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  progress: [],
  importStatus: false,
  resultKey: 'channel',
  decapData: [],
  DCSelectedKey: '',
  DCMenuList: [],
  DCResultKey: 'pin',
  DCResultData: [],
  resultPowerDomainData: [],
  DCResultTableLoading: false,
  showDC: false,
}

export const resultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_RESULT_UPLOAD_PROGRESS:
      const { progress } = state;
      let newProgress = [...progress];
      const index = newProgress.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (index) !== 'boolean' && index > -1) {
        newProgress[index].progress = action.progress;
      } else {
        newProgress.push({
          progress: action.progress,
          verificationId: action.verificationId
        });
      }
      return {
        ...state,
        progress: [...newProgress]
      };
    case CHANGE_FILE_STATUS:
      return {
        ...state,
        importStatus: action.status
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CHANGE_RESULT_TYPE:
      return {
        ...state,
        resultKey: action.resultKey
      }
    case GET_DECAP_INFO_SUCCESS:
      return {
        ...state,
        decapData: action.decapData
      }
    case UPDATE_DC_SELECTED_KEY:
      return {
        ...state,
        DCSelectedKey: action.selectedKey
      }
    case SAVE_DC_VALUE:
      return {
        ...state,
        DCMenuList: action.list,
        resultPowerDomainData: action.resultPowerDomainData
      }
    case GET_DC_INFO_SUCCESS:
      return {
        ...state,
        DCResultData: action.DCResultData
      }
    case CHANGE_DC_RESULT_TYPE:
      return {
        ...state,
        DCResultKey: action.DCResultKey,
        DCResultTableLoading: true
      }
    case UPDATE_DC_RESULT_TABLE_LOADING:
      return {
        ...state,
        DCResultTableLoading: action.loading
      }
    case UPDATE_DC_RESULT_STATUS:
      return {
        ...state,
        showDC: action.status
      }
    case CLEAR_DC_RESULT_DATA:
      return {
        ...state,
        DCSelectedKey: '',
        DCMenuList: [],
        DCResultKey: 'via',
        DCResultData: [],
        resultPowerDomainData: [],
        DCResultTableLoading: false,
        showDC: false,
      }
    default: return state;
  }
};