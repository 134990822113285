import React, { Component, Fragment } from 'react';
import { DeleteOutlined, LineChartOutlined } from '@ant-design/icons';
import { Radio, Tabs } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { TARGET_TOUCHSTONE, TARGET_TABLE, TARGET_RL_VALUE } from '@/services/Cascade/constants';
import TargetUpload from './TargetUpload';
import FrequencyData from './FrequencyTable';
import RLValue from './RLValue';
import TargetFileContent from './TargetFileContent';
import { deleteTargetTouchstoneFile, updateTargetFrequency } from '@/services/Cascade/Impedance';
import DelConfirm from '@/components/DelConfirm';
import TargetPlot from './TargetPlot';
import ElementListener from '@/services/helper/elementListener';
import { getImpTargetName } from '../../../../services/Cascade/Impedance';
import '../index.css';

class TargetPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    const { target } = props;
    const _target = target.filter(item => !!item);
    const targetType = _target && _target.length ? _target[0].targetType : TARGET_TABLE;
    this.state = {
      target: _target,
      show: targetType,
      prevType: targetType,
      confirmVisible: false,
      plot: false,
      panelWidth: '500px',
      panelHeight: '400px',
      panelX: 0,
      panelY: 0,
      points: [],
      activeKey: _target && _target.length ? '0' : '-1'
    }
    this.panelRef = null;
  }

  componentDidMount() {
    this.panelRef = document.getElementById('imp-target-panel');
    this.panelListener = new ElementListener({ targetNode: this.panelRef, attributesHandle: this.attributesHandle });
    this.panelListener.observe();
    this.attributesHandle();
    this.props.onTargetRef && this.props.onTargetRef(this);
    if (!this.props.target || !this.props.target.length) {
      this.onEdit('', 'add')
    }
  }

  componentDidUpdate = (prevProps) => {
    const { target } = this.props;
    if (prevProps.target.length && !target.length) {
      this.freqRef && this.freqRef.initState();
      this.RLRef && this.RLRef.initState();
    }
  }

  componentWillUnmount = () => {
    this.panelListener.disconnect()
  }

  attributesHandle = (mutation) => {
    const targetNode = document.getElementById(`imp-target-panel`);
    const panelBody = targetNode.querySelector(`.panel-ant-modal-body`);
    const { width = '500px', height = '400px' } = panelBody.style;
    const { x, y } = panelBody.getBoundingClientRect();
    const { panelWidth, panelHeight, panelX, panelY } = this.state;
    if (width !== panelWidth || height !== panelHeight) {
      this.setState({
        panelWidth: width,
        panelHeight: height,
      })
    }

    if (x !== panelX || y !== panelY) {
      this.setState({
        panelX: x,
        panelY: y,
      })
    }
  }

  closeModal = () => {
    const { show, prevType, target } = this.state;
    if (target && target.length) {
      if (show === TARGET_TABLE && this.freqRef) {
        this.freqRef.saveTarget(prevType, true);
      } else if (show === TARGET_RL_VALUE && this.RLRef) {
        this.RLRef.saveTarget(prevType, true);
      } else if (show === TARGET_TOUCHSTONE) {
        this.filterEmptyTarget()
      }
    } else {
      this.filterEmptyTarget()
    }
  }

  filterEmptyTarget = () => {
    const { target } = this.state;
    let _target = target.filter(item => !(item.targetType === TARGET_TABLE && (!item.frequencyPoints || !item.frequencyPoints.length))
      && !(item.targetType === TARGET_TOUCHSTONE && !item.fileName));
    this.props.updateTarget(_target);
    this.props.save && this.props.save();
    this.props.closeTargetPanel && this.props.closeTargetPanel(null, false)
  }

  onChangeType = (e) => {
    e.stopPropagation();
    this.setState({
      show: e.target.value
    }, () => {
      const { show, prevType, plot } = this.state;
      if (show === TARGET_TOUCHSTONE && prevType !== TARGET_TOUCHSTONE && plot) {
        this.getChildFrequency()
      }
    })
  }

  getTargetContent = (fileName) => {
    const { show, prevType, panelHeight, activeKey, target } = this.state;
    if (show === TARGET_TOUCHSTONE) {
      return fileName && prevType === TARGET_TOUCHSTONE ?
        <TargetFileContent
          {...this.props}
          target={target}
          targetIndex={Number(activeKey)}
          panelHeight={panelHeight}
          prevType={prevType}
          onRef={this.onFileRef}
          savePoints={this.savePoints}
          updateTarget={this.updateTarget}
        />
        :
        <TargetUpload
          {...this.props}
          target={target}
          updateTarget={this.updateTarget}
          targetIndex={Number(activeKey)}
          changePrevType={this.changePrevType}
        />
    } else if (show === TARGET_RL_VALUE) {
      return <RLValue
        {...this.props}
        target={target}
        targetIndex={Number(activeKey)}
        onRef={this.onRLRef}
        prevType={prevType}
        savePoints={this.savePoints}
        refreshPlot={this.getChildFrequency}
        updateTarget={this.updateTarget}
        filterEmptyTarget={this.filterEmptyTarget} />
    } else {
      return <FrequencyData
        {...this.props}
        target={target}
        targetIndex={Number(activeKey)}
        onRef={this.onFrequencyRef}
        prevType={prevType}
        savePoints={this.savePoints}
        refreshPlot={this.getChildFrequency}
        updateTarget={this.updateTarget}
        filterEmptyTarget={this.filterEmptyTarget}
      />
    }
  }

  getChildFrequency = () => {
    const { show, prevType } = this.state;
    let points = []
    if (show === TARGET_TOUCHSTONE && this.FileRef) {
      points = prevType === TARGET_TOUCHSTONE ? this.FileRef.getFrequencyPoints() : [];
    } else if (show === TARGET_RL_VALUE && this.RLRef) {
      points = this.RLRef.getFrequencyPoints();
    } else if (show === TARGET_TABLE && this.freqRef) {
      points = this.freqRef.getFrequencyPoints();
      points = updateTargetFrequency(points, this.props.maxFreq);
    }
    this.savePoints(points);
  }

  savePoints = (points) => {
    this.setState({ points })
  }

  onFrequencyRef = (ref) => {
    this.freqRef = ref;
  }

  onRLRef = (ref) => {
    this.RLRef = ref;
  }

  onFileRef = (ref) => {
    this.FileRef = ref;
  }

  onPlotRef = (ref) => {
    this.plotRef = ref;
  }

  changePrevType = (type) => {
    this.setState({ prevType: type });
  }

  changeConfirmShow = (bool) => {
    this.setState({ confirmVisible: bool })
  }

  deleteTarget = (e) => {
    e.stopPropagation();
    this.changeConfirmShow(false);
    const { prevType } = this.state;
    if (prevType === TARGET_TOUCHSTONE) {
      const { powerDomainId } = this.props;
      const { target } = this.state;
      const { activeKey } = this.state;
      const currentTarget = target[Number(activeKey)];
      if (!currentTarget) {
        return;
      }
      const { libraryId, targetName } = currentTarget;
      deleteTargetTouchstoneFile(libraryId ? { libraryId } : { powerDomainId });
      const _target = [...target];
      _target[Number(activeKey)] = { targetType: TARGET_TOUCHSTONE, targetName };
      this.props.updateTarget(_target);
      this.setState({
        target: _target
      })
    }
  }

  showPlot = (e) => {
    e.stopPropagation();
    const { plot } = this.state;
    this.setState({
      plot: !plot
    })
  }

  updateTarget = (tar, targetIndex, closePanel) => {
    const { target } = this.state;
    let _target = [...target];
    if (!_target[targetIndex]) {
      return
    }
    _target[targetIndex] = tar;
    _target = _target.filter(t => !!t);
    this.setState({
      target: _target
    }, () => {
      if (closePanel) {
        this.filterEmptyTarget()
      }
    })
  }

  changeTab = (tab) => {
    const { show, prevType, activeKey, target } = this.state;
    if (target[Number(activeKey)]) {
      let noError = true;
      if (show === TARGET_TABLE && this.freqRef) {
        noError = this.freqRef.saveTarget(prevType);
      } else if (show === TARGET_RL_VALUE && this.RLRef) {
        noError = this.RLRef.saveTarget(prevType);
      }
      if (!noError) {
        return;
      }
    }
    const tar = target[tab];
    const targetType = tar ? tar.targetType : TARGET_TABLE;
    this.setState({
      show: targetType,
      prevType: targetType,
      activeKey: String(tab)
    }, () => {
      const { show, plot } = this.state;
      if (show === TARGET_TOUCHSTONE && prevType !== TARGET_TOUCHSTONE && plot) {
        this.getChildFrequency()
      }
    })
  }

  onEdit = (targetKey, action) => {
    if (action === 'add') {
      const { record, targetType, PowerNets } = this.props;
      const { target } = this.state;
      let _target = [...target];
      let name = getImpTargetName({
        targetName: "",
        record,
        targetType,
        PowerNets,
      });
      const targets = _target.map(item => item.targetName);
      while (targets.includes(name)) {
        name = `${name}_1`;
      }
      let tar = {
        frequencyPoints: [],
        targetName: name,
        targetType: TARGET_TABLE
      }
      _target.push(tar);
      this.setState({
        target: _target
      }, () => {
        this.changeTab(_target.length - 1);
      })
    } else if (action === 'remove') {
      const { target } = this.state;
      let _target = [...target];
      _target.splice(Number(targetKey), 1)
      this.setState({
        target: _target
      }, () => {
        const { activeKey } = this.state;
        if (targetKey === activeKey) {
          this.setState({
            activeKey: '-1'
          }, () => {
            if (_target.length) {
              this.changeTab(0)
            }
          })
        }
      })
    }
  }

  tabTargetRender = () => {
    const { activeKey, show, prevType } = this.state;
    const { target } = this.state;
    const currentTarget = target.find((item, index) => String(index) === activeKey);
    const fileName = currentTarget ? currentTarget.fileName : null;
    return (
      <Fragment>
        <Tabs
          activeKey={activeKey}
          onChange={this.changeTab}
          type="editable-card"
          onEdit={this.onEdit}
          items={target.map((tar, index) => ({ key: String(index), label: tar.targetName, closable: true }))}
        />
        {
          target && currentTarget ? <div className='cascade-position-relative'>
            <Radio.Group
              onChange={(e) => this.onChangeType(e)}
              value={show}
              className='imp-target-radio'
            >
              <Radio value={TARGET_TABLE}>Table</Radio>
              <Radio value={TARGET_RL_VALUE}>RL Value</Radio>
              <Radio value={TARGET_TOUCHSTONE}>Touchstone</Radio>
            </Radio.Group>
            <div className={'imp-target-action-group'}>
              <LineChartOutlined title='Show/Hide Plot' onClick={this.showPlot} />
              {prevType === TARGET_TOUCHSTONE && show === TARGET_TOUCHSTONE && fileName
                && <DeleteOutlined
                  title="Delete Current Target"
                  onClick={() => this.changeConfirmShow(true)} />}
            </div>
            {this.getTargetContent(fileName)}
          </div> : null
        }
      </Fragment>
    );
  }

  renderDialog() {
    const { title } = this.props;
    const { show, plot, points, panelWidth, panelHeight, panelY, panelX } = this.state;
    const content = (
      <Fragment>
        <Panel
          id='imp-target-panel'
          className='imp-target-panel'
          position='panel-center-left'
          title={title}
          zIndex={2000}
          onCancel={this.closeModal}
          width={plot ? 800 : 500}
          draggable
          minWidth={plot ? 800 : 500}
          minHeight={200}
          maxHeight={900}
        >
          <div className={plot ? "impedance-target-setting" : "impedance-target-setting-single"}>
            {this.tabTargetRender()}
          </div>
          {plot && <div className="impedance-target-plot">
            <TargetPlot
              {...this.props}
              points={points}
              onRef={this.onPlotRef}
              showType={show}
              width={panelWidth}
              height={panelHeight}
              x={panelX}
              y={panelY}
            />
          </div>}
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { text, multiPCB } = this.props;
    const { confirmVisible } = this.state;
    return <Fragment>
      {
        !multiPCB ? <div className="editable-cell-value-wrap" ref={this.inputRef}>
          {text}
        </div> : null
      }
      {this.renderDialog()}
      {confirmVisible ? <DelConfirm
        data={null}
        maskStyle={true}
        deleteConfirm={this.deleteTarget}
        cancelDel={() => this.changeConfirmShow(false)}
        message={'Can not be restored after deleting the Target. Are you sure you want to delete it?'}
      /> : null}
    </Fragment>
  }
}

export default TargetPanel;