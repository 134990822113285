import React, { Component, createRef, Fragment } from 'react';
import ResultLayout from '@/services/Result/Public/resultLayout';
import WaveformSetting from '@/services/Result/Public/waveform/waveformSetting';
import { getVerificationJson, getHistoryVerificationJson, getPreLayoutJson, getExperimentVerificationJson } from '@/services/Andes_v2/results'
import { getWaveformResult, getHistoryWaveformResult, getSBRResultList, getTDRResultList, getFreqByPreLayoutSetup, getExperimentWaveformResult } from '@/services/Andes_v2/results/waveform';
import WaveformPlot from '@/services/Andes/results/waveformPlot';
import ResultRightMenu from '@/services/Result/Public/resultRightMenu';
import ResultList, { RESULT, HISTORY } from '@/components/Sparameter/resultList';
import { rowDisplayChange } from '@/services/Result/Public/sparameter/resultsMenuHelper';
import { selectedChange, cancelMove } from '@/services/Result/Public/sparameter/dataHelper';
import { Spin, Divider, Tabs } from 'antd';
import getIndex from '@/services/helper/insertionSearch';
import TDRSBRSetting from './TDRSBRSetting';
import { strDelimited } from '@/services/helper/split';
import { changeCurveColor } from '../../../../services/Result/Public/sparameter/dataHelper';
import { checkResultStatus, GET, CLEAN } from '@/services/helper/resultStatus';
import { isPreLayout } from '../../../../services/Andes_v2';
import { getChannelExtractionResult, getEndToEndChannelResult } from '@/services/api/Andes_v2/result';
import { npiFileParseV2 } from '@/services/Result/Andes/dataHelper';
import { endToEndNpiParse } from '../../../../services/Andes_v2/results/sparameter/endToEnd';
import { VERIFY_SUCCESS } from '../../../../constants/verificationStatus';
import { EXPERIMENTS } from '../../../../constants/treeConstants';
import { CPHY } from '../../../../services/PCBHelper/constants';

class WaveformResult extends Component {

  constructor() {
    super()
    this.svgRef = createRef();
    this.state = {
      loading: false,
      resultsObj: {}, //  { TDR: [], SBR: { list: [{ rowName: "", children: [{ id, name, color, value }] }], show: ["rowName"] } }
      portSelect: {}, //  { { TDR: { id: { "rowName": [] } } , SBR: { id: { "rowName": [] } } } }
      maxFreq: -1,
      historyObj: {}, //  { id: { TDR, SBR } }
      historyFiles: [],
      experimentObj: {},
      experimentFiles: [],
      modeActive: "default"
    }
    this.historyContent = new Map();
    this.experimentContent = new Map();
    this.isPreLayout = false;
    // Solve Aynchronous causes memory leakage and updates after component removal
    this.isUpdate = false;
  }

  getHistoryIds = () => {
    const { historyList } = this.props;
    return historyList.map(d => d.id);
  }

  getExperimentIds = () => {
    const { id, experimentList, status } = this.props;
    const experimentIds = experimentList ? experimentList.map(d => d.id) : []
    if (status === VERIFY_SUCCESS) {
      experimentIds.unshift(id);
    }
    return experimentIds;
  }

  onRef = (ref) => {
    this.waveformSetting = ref;
  }

  componentDidMount() {
    this.isUpdate = true;
    if (this.props.resultPageType) {
      this.renderResultExperiment();
    } else if (this.props.id) {
      this.getWaveform();
      this.renderResultHistory();
    }
  }

  componentDidUpdate(prevProps) {
    const { id, resultKey, historyList, status, sweepId, sweepStatus, resultPageType, updateExperimentListStatus } = this.props;
    if (id !== prevProps.id || resultKey !== prevProps.resultKey) {
      if (resultPageType === EXPERIMENTS) {
        this.renderResultExperiment();
      } else {
        this.getWaveform();
        this.renderResultHistory();
      }
    }

    const historyListIds = this.getHistoryIds();
    const preHistoryListsIds = prevProps.historyList.map(d => d.id);
    const historyListsNames = historyList.map(d => d.name);
    const preHistoryListsNames = prevProps.historyList.map(d => d.name);

    if (historyListIds.length !== preHistoryListsIds.length ||
      historyListIds.some(id => !preHistoryListsIds.includes(id))
    ) {
      this.renderResultHistory();
    }

    // Rename
    if (historyListIds.length === preHistoryListsIds.length &&
      historyListIds.every(id => preHistoryListsIds.includes(id)) &&
      historyListsNames.some(id => !preHistoryListsNames.includes(id))
    ) {
      this.refreshHistoryResultList();
    }

    // status
    if (id === prevProps.id && prevProps.status !== status) {
      const updateStatus = checkResultStatus(prevProps.status, status);
      switch (updateStatus) {
        case GET:
          // reload result data
          this.getWaveform();
          this.renderResultHistory();
          break;
        case CLEAN:
          this.initResult();
          break;
        default: break;
      }
    }

    if (sweepId === prevProps.sweepId && prevProps.sweepStatus !== sweepStatus) {
      const updateStatus = checkResultStatus(prevProps.sweepStatus, sweepStatus);
      switch (updateStatus) {
        case GET:
          // reload result data
          this.renderResultExperiment();
          break;
        case CLEAN:
          this.initResult();
          break;
        default: break;
      }
    }

    if ((prevProps.updateExperimentListStatus !== updateExperimentListStatus && updateExperimentListStatus) || updateExperimentListStatus === 'first') {
      this.renderResultExperiment();
      this.props.changeUpdateExperimentListStatus(false);
    }
  }

  componentWillUnmount() {
    this.initResult();
    this.isUpdate = false;
  }

  refreshHistoryResultList = () => {
    const { historyList } = this.props;
    let { historyFiles } = this.state;
    historyList.forEach(his => {
      const index = historyFiles.findIndex(f => f.id === his.id);
      if (index > -1) {
        historyFiles[index].rowName = his.name;
      }
    });
    this.setState({
      historyFiles
    })
  }

  getCurves = () => {
    return WaveformPlot.getCurves();
  }

  getList = (curve, type) => {
    const { resultsObj, historyObj, experimentObj } = this.state;
    const displayMode = this.getDisplayMode();
    if (type === 'history') {
      return historyObj[curve.id] && historyObj[curve.id][displayMode] ? (historyObj[curve.id][displayMode].list || []) : [];
    } else if (type === 'experiment') {
      return experimentObj[curve.id] && experimentObj[curve.id][displayMode] ? (experimentObj[curve.id][displayMode].list || []) : [];
    } else {
      return resultsObj[displayMode] ? (resultsObj[displayMode].list || []) : [];
    }
  }

  changeMouse = (time, yPrefix) => {
    const curves = this.getCurves();
    const { resultKey, id } = this.props;
    let { resultsObj, historyObj, experimentObj } = this.state;
    const unit = resultKey === 'TDR' ? 'Ω' : 'V';
    if (!curves || !curves.length) return;
    const displayMode = this.getDisplayMode();
    for (const curve of curves) {
      let type = '';
      if (historyObj[curve.id]) {
        type = 'history';
      } else if (experimentObj[curve.id]) {
        type = 'experiment';
      } else if (curve.id === id) {
        type = 'channel';
      }
      const { listIndex, childIndex } = this.getListChildIndex(curve, this.getList(curve, type));
      if (listIndex < 0) return;
      if (curve.visible && curve.y && curve.y.length) {
        const index = this._getIndex(curve.x, time);
        const value = index > -1 ? yPrefix(curve.y[index]) + unit : '';
        if (type === 'history') {
          historyObj[curve.id][displayMode].list[listIndex].children[childIndex].value = value;
        } else if (type === 'experiment') {
          experimentObj[curve.id][displayMode].list[listIndex].children[childIndex].value = value;
        } else if (type === 'channel') {
          resultsObj[displayMode].list[listIndex].children[childIndex].value = value;
        }
      } else {
        if (type === 'history') {
          historyObj[curve.id][displayMode].list[listIndex].children[childIndex].value = '';
        } else if (type === 'experiment') {
          experimentObj[curve.id][displayMode].list[listIndex].children[childIndex].value = '';
        } else if (type === 'channel') {
          resultsObj[displayMode].list[listIndex].children[childIndex].value = '';
        }
      }
    };

    this.setState({
      resultsObj,
      historyObj,
      experimentObj
    })
  }

  getListChildIndex(curve, list) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].children.length; j++) {
        if (list[i].children[j].id === curve.name) {
          return {
            listIndex: i,
            childIndex: j
          }
        }
      }
    }
    return {
      listIndex: -1,
      childIndex: -1
    }
  }


  _getIndex(xPoints, time) {
    return getIndex(xPoints, time, 0, xPoints.length - 1);
  }

  getCurrentSelects = () => {
    let { portSelect } = this.state;
    const displayMode = this.getDisplayMode();
    if (!portSelect || !portSelect[displayMode]) {
      return [];
    }
    return Object.values(portSelect[displayMode]).reduce((a, b) => {
      return Object.values(a).concat(...Object.values(b));
    }, []);
  }

  cancelMove = () => {
    const { resultsObj, historyObj, experimentObj } = this.state;
    const selectArr = this.getCurrentSelects();
    const displayMode = this.getDisplayMode();
    let _historyObj = { ...historyObj }, _experimentObj = { ...experimentObj };
    Object.keys(historyObj).forEach(id => {
      _historyObj[id] = cancelMove({ resultsObj: _historyObj[id], displayMode, selectArr })
    })
    Object.keys(experimentObj).forEach(id => {
      _experimentObj[id] = cancelMove({ resultsObj: _experimentObj[id], displayMode, selectArr })
    })
    this.setState({
      resultsObj: cancelMove({ resultsObj, displayMode, selectArr }),
      historyObj: _historyObj,
      experimentObj: _experimentObj
    })
  }

  changeAxis = (axis) => {
    this.waveformSetting.setAxis(axis);
  }

  initResult = () => {
    this.setState({
      resultsObj: {},
      portSelect: {},
      modeActive: "default"
    })
    WaveformPlot.cleanWaveformPlot();
    if (this.plot2D) {
      if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
        this.svgRef.current.removeChild(this.svgRef.current.children[0]);
      }
    }
    this.plot2D = null;
  }

  getDisplayMode = (paramModeActive) => {
    const { resultKey } = this.props;
    const { modeActive: stateModeActive } = this.state;
    const modeActive = paramModeActive || stateModeActive;
    return modeActive === 'default' ? resultKey.toUpperCase() : `${modeActive}_${resultKey.toUpperCase()}`;
  }

  getWaveform = async (ifInitResult = true, modeActive = "default") => {
    this.interfaceContent = null;
    if (ifInitResult) {
      this.initResult();
    }
    const { id, isEndToEnd, setupInfo, interfaceType } = this.props;
    const displayMode = this.getDisplayMode(modeActive);
    this.interfaceContent = setupInfo || await getVerificationJson(id, isEndToEnd);
    if (!this.interfaceContent || !this.isUpdate) return;
    if (isEndToEnd) {
      this.setState({
        maxFreq: Math.min(...await Promise.all(this.interfaceContent.channels.map(async d => await this.getMaxFreq(d))))
      })
    } else {
      this.setState({
        maxFreq: await this.getMaxFreq(this.interfaceContent)
      })
    }

    let resultObj = {};
    if (interfaceType === CPHY && !["SE_TDR", "SE_SBR"].includes(displayMode)) {
      const resultObjList = [], curvesList = [];
      const signals = isEndToEnd ? this.interfaceContent.signals : this.interfaceContent.content.signals;
      for (const signal of signals) {
        for (const pair of ['AB', 'BC', 'CA']) {
          const waveform = await getWaveformResult({ id, type: displayMode, isEndToEnd, pair, signal: signal.name });
          const res = await this.waveformDataProcess({ waveform, id, pair, colorIndex: curvesList.length, signalName: signal.name });
          const curves = this.getCurves().filter(d => d.id === id);
          curvesList.push(...curves);
          resultObjList.push(res);
        }
      }
      const list = resultObjList.map(item => item.list).flat();
      const show = resultObjList.map(item => item.show).flat();
      resultObj = { list, show };
      const historyCurves = this.getCurves().filter(d => d.id !== id);
      historyCurves.push(...curvesList);
      WaveformPlot.setCurves(historyCurves);
    } else {
      const waveform = await getWaveformResult({ id, type: displayMode, isEndToEnd });
      resultObj = await this.waveformDataProcess({ waveform, id });
    }
    let portSelect = { ...this.state.portSelect };
    if (portSelect[displayMode] && portSelect[displayMode][id]) {
      portSelect[displayMode][id] = {};
    }

    if (id === this.props.id && this.isUpdate) {
      this.setState((prevState) => {
        return {
          resultsObj: { ...prevState.resultsObj, [displayMode]: resultObj },
          portSelect: portSelect
        }
      })
      this.initSelect(resultObj, id);
    }
  }

  getMaxFreq = async (channel) => {
    const content = channel.content;
    const isPreLayoutChannel = isPreLayout(channel.designId);
    if (isPreLayoutChannel) {
      this.isPreLayout = true;
      // Set the maximum coordinate value of the network as MaxFreq;
      const { isEndToEnd, id, interfaceType } = this.props;
      let data = [], parseData = null;
      try {
        if (isEndToEnd) {
          data = this.interfaceContent;
          const signal = (this.interfaceContent.signals || []).length > 0 ? this.interfaceContent.content.signals[0].name : '';
          const info = interfaceType === CPHY ? await getEndToEndChannelResult({ endToEndChannelId: id, type: 'RETURN_LOSS', pair: 'AB', signal })
            : await getEndToEndChannelResult({ endToEndChannelId: id, type: 'RETURN_LOSS' });
          parseData = endToEndNpiParse(info.data, data)
        } else {
          data = this.interfaceContent.content.components || [];
          const signal = (this.interfaceContent.content.signals || []).length > 0 ? this.interfaceContent.content.signals[0].name : '';
          const info = interfaceType === CPHY ? await getChannelExtractionResult({ channelId: id, type: 'RETURN_LOSS', pair: 'AB', signal })
            : await getChannelExtractionResult({ channelId: id, type: 'RETURN_LOSS' })
          parseData = npiFileParseV2(info.data, data, interfaceType)
        }
        if (parseData && parseData.freq && parseData.freq.length) {
          return Math.max(...parseData.freq)
        }
      } catch (error) { }
      this.preLayoutContent = await getPreLayoutJson(channel.id);
      return getFreqByPreLayoutSetup(this.preLayoutContent);
    }
    const extraction = content.extraction;
    if (extraction && extraction.maxFreq && extraction.maxFreq > 0) {

      return extraction.maxFreq;
    }
    return extraction ? extraction.linearSweepMax : 5e9;
  }

  renderResultHistory = async (modeActive) => {
    const { historyList, isEndToEnd, interfaceType } = this.props;
    // historyList - [{ id, name, channelId }], id: historyId
    let lists = [];
    const displayMode = this.getDisplayMode(modeActive);
    for (const history of historyList) {
      if (!this.isUpdate) return;
      const historyJson = await getHistoryVerificationJson(history.id, isEndToEnd);
      if (historyJson) {
        this.historyContent.set(history.id, historyJson);
        if (interfaceType === CPHY && !["SE_TDR", "SE_SBR"].includes(displayMode)) {
          const resultObjList = [], curvesList = [];
          const signals = isEndToEnd ? historyJson.signals : historyJson.content.signals;
          for (const signal of (signals || [])) {
            for (const pair of ['AB', 'BC', 'CA']) {
              const waveform = await getHistoryWaveformResult({ historyId: history.id, type: displayMode, name: history.name, isEndToEnd, pair, signal: signal.name });
              const res = await this.waveformDataProcess({ waveform, id: history.id, isHistory: true, pair, colorIndex: curvesList.length, signalName: signal.name });
              const curves = this.getCurves().filter(d => d.id === history.id);
              curvesList.push(...curves);
              resultObjList.push(res);
            }
          }
          const list = resultObjList.map(item => item.list).flat();
          const show = resultObjList.map(item => item.show).flat();
          const resultObj = { list, show };
          lists.push({ resultObj, id: history.id })
          const historyCurves = this.getCurves().filter(d => d.id !== history.id);
          historyCurves.push(...curvesList);
          WaveformPlot.setCurves(historyCurves)
        } else {
          const waveform = await getHistoryWaveformResult({ historyId: history.id, type: displayMode, name: history.name, isEndToEnd });
          const resultObj = await this.waveformDataProcess({ waveform, id: history.id, isHistory: true });
          lists.push({ resultObj, id: history.id });
        }
      }
    }

    let _historyObj = {};
    lists.forEach(d => {
      _historyObj[d.id] = {};
      _historyObj[d.id][displayMode] = d.resultObj;
    });
    let historyFiles = historyList.map(d => ({ id: d.id, show: false, rowName: d.name, rowId: d.id }))
    if (!this.isUpdate) return;
    this.setState({
      historyFiles: historyFiles,
      historyObj: _historyObj
    })
  }

  renderResultExperiment = async (ifInitResult = true) => {
    if (ifInitResult) {
      this.initResult();
    }
    const { experimentList, resultKey, isEndToEnd, status, id, setupInfo } = this.props;
    const list = experimentList.map(async experiment => {
      if (!this.isUpdate) return;
      const interfaceContent = await getExperimentVerificationJson(experiment.id, isEndToEnd);
      if (interfaceContent) {
        this.experimentContent.set(experiment.id, interfaceContent);
      }
      const waveform = await getExperimentWaveformResult({ experimentId: experiment.id, type: resultKey.toUpperCase(), name: experiment.name });
      const resultObj = await this.waveformDataProcess({ waveform, id: experiment.id, isHistory: false, isExperiment: true });
      return { resultObj, id: experiment.id };
    })
    let defaultFile = null;
    if (status === VERIFY_SUCCESS) {
      try {
        const interfaceContent = setupInfo || await getVerificationJson(id, isEndToEnd);
        if (interfaceContent) {
          this.experimentContent.set(id, interfaceContent);
        }
        const waveform = await getWaveformResult({ id, type: resultKey.toUpperCase(), isEndToEnd });
        const resultObj = await this.waveformDataProcess({ waveform, id, isHistory: false, isExperiment: true });
        list.unshift({ resultObj, id });
        defaultFile = { id: id, show: true, rowName: 'Default', rowId: id }
      } catch (error) {
        console.error(error);
      }
    }
    const lists = await Promise.all(list);
    let _experimentObj = {};
    lists.forEach(d => {
      _experimentObj[d.id] = {};
      _experimentObj[d.id][resultKey] = d.resultObj;
    });
    let experimentFiles = experimentList.map((d, index) => ({ id: d.id, show: index === 0 && status !== VERIFY_SUCCESS ? true : false, rowName: d.name, rowId: d.id }))
    if (defaultFile) {
      experimentFiles.unshift(defaultFile);
    }
    if (!this.isUpdate) return;
    this.setState({
      experimentFiles,
      experimentObj: _experimentObj,
      maxFreq: Math.min(...await Promise.all(this.experimentContent.values().map(async d => await this.getMaxFreq(d))))
    }, () => {
      if (experimentFiles && experimentFiles[0] && experimentFiles[0].id) {
        this.initSelect(_experimentObj[experimentFiles[0].id][resultKey], experimentFiles[0].id)
      }
    })
  }

  waveformDataProcess = async ({ waveform, id, isHistory = false, isExperiment = false, pair, colorIndex, signalName }) => {
    const { resultKey } = this.props;
    if (!this.isUpdate) return;
    let axis = null;
    if (resultKey === 'SBR') {
      axis = { yUnit: 'V', yLabel: 'Voltage' };
    } else if (resultKey === 'TDR') {
      axis = { yUnit: 'Ω', yLabel: 'Impedance' };
    } else {
      return;
    }
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis,
    };
    this.plot2D = WaveformPlot.plotWaveform({ svgElement: this.svgRef.current, events, waveform, axis, id, colorIndex, pair });
    // TODO - set default xMin and xMax
    const curves = this.getCurves().filter(d => d.id === id);
    return await this.getResultList({ curves, resultKey, id, isHistory, isExperiment, pair, signalName })
  }

  initSelect = (resultObj, id) => {
    if (resultObj.list.length > 0) {
      const values = resultObj.list[0].children.map(d => d.id)
      this.changePort(values, resultObj.list[0].rowName, id, []);
    }
  }

  updateCureves = (values, key, id, prevSelects) => {
    let _prevSelects = prevSelects;
    const { portSelect } = this.state;
    const displayMode = this.getDisplayMode();
    try {
      let curves = this.getCurves();
      // set state
      if (!_prevSelects) {
        _prevSelects = (portSelect[displayMode] && portSelect[displayMode][id] && portSelect[displayMode][id][key]) || [];
      }
      const { unchecks, newChecks } = selectedChange({ prevSelects: _prevSelects, values })
      unchecks.forEach(un => {
        const curveIndex = curves.findIndex(d => d.name === un);
        curves[curveIndex].visible = false;
      })
      newChecks.forEach(ne => {
        const curveIndex = curves.findIndex(d => d.name === ne);
        curves[curveIndex].visible = true;
      })
      this.updatePortSelect(key, values, id)
    } catch (error) {
      console.error(error);
    }
  }
  // values: []
  changePort = (values, key, id, prevSelects) => {
    this.updateCureves(values, key, id, prevSelects);
    this.plot2D.updatePlot();
  }

  updatePortSelect = (rowName, values, id) => {
    const displayMode = this.getDisplayMode();
    this.setState((prevState) => {
      let _portSelect = prevState.portSelect;
      if (!_portSelect[displayMode]) {
        _portSelect[displayMode] = {};
      }
      if (!_portSelect[displayMode][id]) {
        _portSelect[displayMode][id] = {};
      }
      _portSelect[displayMode][id][rowName] = values;
      return {
        portSelect: _portSelect
      }
    });
  }

  getResultList = async ({ curves, resultKey, id, isHistory, isExperiment, pair, signalName }) => {
    const interfaceContent = isHistory ? this.historyContent.get(id) : isExperiment ? this.experimentContent.get(id) : this.interfaceContent;
    const { isEndToEnd } = this.props;
    const displayMode = this.getDisplayMode();
    if (resultKey === 'SBR') {
      return getSBRResultList({ curves, isEndToEnd, interfaceContent, pair, signalName, displayMode });
    } else {
      return await getTDRResultList({ curves, isEndToEnd, interfaceContent, pair, displayMode });
    }
  }

  changeWidthCB = () => {
    if (this.plot2D) {
      this.plot2D.redrawPlot(this.svgRef.current);
    }
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax }) => {
    let plot = this.plot2D;
    if (type === 'xMin' || type === 'xMax') {
      let xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = parseFloat(_xMin) * 1e-9,
        end = parseFloat(_xMax) * 1e-9;

      if (parseFloat(start).toString() === 'NaN') {
        start = 0;
      };
      if (parseFloat(end).toString() === 'NaN') {
        end = 0;
      };

      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      const xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = (xrange[0] - xMin) / (xMax - xMin) * width,
        endX = (xrange[1] - xMin) / (xMax - xMin) * width;
      plot.updateRange(startX, endX);
    } else {
      // y axis
      let yStart = parseFloat(_yMin),
        yEnd = parseFloat(_yMax);

      if (parseFloat(yStart).toString() === 'NaN') {
        yStart = 0;
      };
      if (parseFloat(yEnd).toString() === 'NaN') {
        yEnd = 0;
      };

      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB();
  }

  reloadAxisCB = () => {
    this.plot2D.updatePlot();
  }

  colorChange = (e, key) => {
    const value = e.toHexString()
    if (value) {
      const curves = WaveformPlot.getCurves();
      const curveIndex = curves.findIndex(d => d.name === key);
      curves[curveIndex].color = value;
      this.reloadAxisCB()
      this.setResultObjColor(e, key);
    }
  }

  setResultObjColor = (e, key) => {
    const { resultsObj, historyObj, experimentObj } = this.state;
    const { resultPageType } = this.props;
    let _resultsObj = { ...resultsObj }, _historyObj = { ...historyObj }, _experimentObj = { ...experimentObj };
    const fileId = strDelimited(key, "::")[0];
    const displayMode = this.getDisplayMode();

    if (this.getExperimentIds().includes(fileId) && resultPageType === EXPERIMENTS) {
      const { resultList } = changeCurveColor({ e, key, resultList: _experimentObj[fileId], displayMode });
      _experimentObj[fileId] = resultList;
    } else if (this.getHistoryIds().includes(fileId)) {//history
      const { resultList } = changeCurveColor({ e, key, resultList: _historyObj[fileId], displayMode });
      _historyObj[fileId] = resultList;
    } else {
      //result
      const { resultList } = changeCurveColor({ e, key, resultList: _resultsObj, displayMode })
      _resultsObj = resultList;
    }
    this.setState({
      resultsObj: _resultsObj,
      historyObj: _historyObj,
      experimentObj: _experimentObj
    })
  }

  rowNameClick = (displayMode, value) => {
    this.setState({
      resultsObj: rowDisplayChange(this.state.resultsObj, displayMode, value)
    })
  };

  _historyRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.historyObj[rowId] = rowDisplayChange(prevState.historyObj[rowId], displayMode, value)
      return {
        historyObj: prevState.historyObj
      }
    })
  }

  _experimentRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.experimentObj[rowId] = rowDisplayChange(prevState.experimentObj[rowId], displayMode, value)
      return {
        experimentObj: prevState.experimentObj
      }
    })
  }

  _fileNameClick = (id) => {
    const { historyFiles } = this.state;
    const index = historyFiles.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.historyFiles[index].show = !prevState.historyFiles[index].show;
      return {
        historyFiles: [...prevState.historyFiles]
      }
    })
  }

  _experimentfileNameClick = (id) => {
    const { experimentFiles } = this.state;
    const index = experimentFiles.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.experimentFiles[index].show = !prevState.experimentFiles[index].show;
      return {
        experimentFiles: [...prevState.experimentFiles]
      }
    })
  }

  changeSelectAll = (e, group, id) => {
    const check = e.target.checked;
    if (check) {
      this.changePort(group.children.map(d => d.id), group.rowName, id)
    } else {
      this.changePort([], group.rowName, id)
    }
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div
          className='waveform-svg'
          style={{ width: "100%", height: "100%" }}>
          <svg ref={this.svgRef}></svg>
          {/*  {ResultZoom({
            zoomIn: this.plot2D ? this.plot2D.zoomIn : null,
            zoomOut: this.plot2D ? this.plot2D.zoomOut : null,
            fitView: this.plot2D ? this.plot2D.fitView : null,
            plot: this.plot2D ? this.plot2D : null
          })} */}
        </div>
      </Spin>
    )
  }

  customizeTitle = (item) => {
    const { isEndToEnd } = this.props;
    const displayMode = this.getDisplayMode();
    if (!isEndToEnd || item.name === "pulse") {
      if (displayMode === "TDR" || item.name === "pulse") {
        return <span className='andes-customized-title'>{item.name}</span>
      } else if (displayMode === "SBR") {
        return <span className='andes-customized-title'>
          <span className='component-pin-name'>{item.comp1Pins}</span>
          <span>,&nbsp;</span>
          <span className='component-pin-name'>{item.comp2Pins}</span>
        </span>
      } else if (displayMode === "SE_TDR") {
        return <span className='andes-customized-title'>{item.name}::{item.net}</span>
      } else if (displayMode === "SE_SBR") {
        return <span className='andes-customized-title'>
          <span className='component-pin-name'>{item.comp1Pin}</span>
          <span>,&nbsp;</span>
          <span className='component-pin-name'>{item.comp2Pin}</span>
        </span>
      }
    }

    if (displayMode === "TDR") {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.displayDesign}</span>
        <span className='component-pin-name'>&nbsp;{item.displayCompPins}</span>
      </span>
    } else if (displayMode === "SE_TDR") {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.displayDesign}</span>
        <span className='component-pin-name'>&nbsp;{item.dispalyCompPin}</span>
      </span>
    }

    if (displayMode === "SBR") {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.design1}&nbsp;</span>
        <span className='component-pin-name'>{item.design1CompPins}</span>
        <span>,&nbsp;</span>
        <span className='design-name'>{item.design2}&nbsp;</span>
        <span className='component-pin-name'>{item.design2CompPins}</span>
      </span>
    } else if (displayMode === "SE_SBR") {
      return <span className='andes-customized-title'>
        <span className='design-name'>{item.design1}&nbsp;</span>
        <span className='component-pin-name'>{item.design1CompPin}</span>
        <span>,&nbsp;</span>
        <span className='design-name'>{item.design2}&nbsp;</span>
        <span className='component-pin-name'>{item.design2CompPin}</span>
      </span>
    }
  }

  resultListRender = () => {
    const { portSelect, resultsObj, historyObj, historyFiles, experimentObj, experimentFiles } = this.state;
    const { id, resultPageType } = this.props;
    const displayMode = this.getDisplayMode();
    return (
      <div>
        {resultPageType === EXPERIMENTS ? <ResultList
          title='Current Result'
          type={HISTORY}
          multiFiles={true}
          displayMode={displayMode}
          portSelect={portSelect[displayMode] || {}}
          portSelectMultiFile={true}
          changePort={this.changePort}
          colorChange={this.colorChange}
          resultList={experimentObj}
          rowNameClick={this._experimentRowNameClick}
          changeSelectAll={this.changeSelectAll}
          files={experimentFiles}
          fileNameClick={this._experimentfileNameClick}
          customizeTitle={this.customizeTitle}
        /> : <Fragment>
          {/* Result list */}
          <ResultList
            type={RESULT}
            fileId={id}
            rowId={id}
            displayMode={displayMode}
            portSelect={(portSelect[displayMode] && portSelect[displayMode][id]) || {}}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={resultsObj}
            rowNameClick={this.rowNameClick}
            changeSelectAll={this.changeSelectAll}
            customizeTitle={this.customizeTitle}
          />
          {/* History list */}
          <ResultList
            type={HISTORY}
            multiFiles={true}
            displayMode={displayMode}
            portSelect={portSelect[displayMode] || {}}
            portSelectMultiFile={true}
            changePort={this.changePort}
            colorChange={this.colorChange}
            resultList={historyObj}
            rowNameClick={this._historyRowNameClick}
            changeSelectAll={this.changeSelectAll}
            files={historyFiles}
            fileNameClick={this._fileNameClick}
            customizeTitle={this.customizeTitle}
          />
        </Fragment>}
      </div>
    )
  }

  settingRender = () => {
    return (
      <div>
        <Divider
          orientation="left"
          className='waveform-setting-title'
          style={{
            margin: "6px 0px"
          }}
        >Setting</Divider>
        <div className='andes-v2-waveform-setting-css'>
          <WaveformSetting
            onRef={this.onRef}
            plot={this.plot2D}
            axisChangeCB={this.axisChangeCB}
            reloadAxisCB={this.reloadAxisCB}
            currentResultKey={this.props.resultKey}
          />
        </div>
      </div>
    )
  }

  resultModeChange = (key) => {
    this.setState({ modeActive: key }, () => {
      this.getWaveform(false, key);
      this.renderResultHistory(key);
    })
  }

  rightTopRender = () => {
    const { maxFreq, modeActive } = this.state;
    const { resultKey, id, isEndToEnd, status, sweepId, resultPageType } = this.props;
    return <>
      <TDRSBRSetting
        channelId={id}
        maxFreq={maxFreq}
        isEndToEnd={isEndToEnd}
        recalculateTDRSBRWorkflow={this.props.recalculateTDRSBRWorkflow}
        status={status}
        resultKey={resultKey}
        isPreLayout={this.isPreLayout}
        sweepId={sweepId}
      />
      {resultPageType !== EXPERIMENTS ? <Tabs
        onChange={this.resultModeChange}
        type="card"
        className='andes-result-mode-tab'
        activeKey={modeActive}
        items={[{
          key: "default",
          label: "Differential Pair",
          children: ""
        }, {
          key: "SE",
          label: "Single-Ended",
          children: ""
        }]} /> : null}
    </>
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        onRef={this.resultRightMenuOnRef}
        resultList={this.resultListRender}
        setting={this.settingRender}
        top={this.rightTopRender}
      />
    )
  }

  render() {
    return (
      <ResultLayout
        resultLeft={this.resultLeft}
        resultRight={this.resultRight}
        changeWidthCB={this.changeWidthCB}
      />
    )
  }
}

export default WaveformResult