import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import MaskSetup from './MaskSetup';
import { createPortal } from 'react-dom';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { eyeMaskInfoConversion } from '@/services/Andes_v2/library/EyeMask';
import { getFileContent } from '@/services/Andes_v2/library';
import DelConfirm from '@/components/DelConfirm';
import ElementListener from '@/services/helper/elementListener';
import hash from 'object-hash';
import './index.css';
import { getDefaultConfigData } from '../../../../services/Andes_v2/library/EyeMask';
import { CPHY_EYE_MASK, EYE_MASK } from '../../../../constants/libraryConstants';
import libraryConstructor from '../../../../services/Andes_v2/library/libraryConstructor';

const FILE = 'File';
const confirmErrorMsg = "No data added, eye mask will not be added."

class MaskPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      id: props.id,
      name: props.defaultLibraryName,
      pointsList: [],
      fileInfo: '',
      saveMsgVisible: false
    }
    this.panelRef = null;
    this.dialogRoot = document.getElementById('root');
    this.FileInfo = {}
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.panelRef = document.getElementById('mask-panel');
    this.panelListener = new ElementListener({ targetNode: this.panelRef, attributesHandle: this.attributesHandle });
    this.panelListener.observe();
    this.attributesHandle();

    let { type } = this.props;
    if (type === FILE) {
      this.getFileData()
    } else {
      this.getJsonData()
    }
    this.resize();
    this.setContentHeight(0);
  }

  setContentHeight = (time) => {
    let _time = 300;
    if (time !== undefined) {
      _time = time;
    }
    const prevHeight = this.state.height;
    const defaultHeight = 400;

    setTimeout(() => {
      //set panel height
      let content = document.getElementById(`eye-mask-content-box`);
      let height = content ? content.offsetHeight + 78 : prevHeight && defaultHeight;
      height = height + 30
      this.setState({
        height
      })
    }, _time);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      maxHeight: this.getMaxHeight(),
      maxWidth: this.getMaxWidth()
    })
  }

  getMaxHeight = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { height } = offset;
      if (!height) return 800;
      const _height = (height - 100) * 0.77;
      return _height > 1200 ? 1200 : _height;
    }
  }

  getMaxWidth = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { width } = offset;
      if (!width) return 690;
      const _width = width * 0.8;
      return _width > 690 ? 690 : _width;
    }
  }

  attributesHandle = (mutation) => {
    const targetNode = document.getElementById(`mask-panel`);
    const panelBody = targetNode.querySelector(`.panel-ant-modal-body`);
    const { width = '500px', height = '400px' } = panelBody.style;
    const { x, y } = panelBody.getBoundingClientRect();
    const { panelWidth, panelHeight, panelX, panelY } = this.state;
    if (width !== panelWidth || height !== panelHeight) {
      this.setState({
        panelWidth: width,
        panelHeight: height,
      })
    }

    if (x !== panelX || y !== panelY) {
      this.setState({
        panelX: x,
        panelY: y,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { libraryId, type } = this.props;
    if (libraryId !== prevProps.libraryId) {
      if (type === FILE) {
        this.getFileData()
      } else {
        this.getJsonData()
      }
    }
  }

  getJsonData = () => {
    const { libraryId, defaultLibraryName, getLibraryDetail, eyeMaskType } = this.props;
    if (libraryId) {
      getLibraryDetail(libraryId).then(res => {
        if (res) {
          const { config } = res;
          this.FileInfo = JSON.parse(JSON.stringify(res));
          this.setState({
            name: res.name,
            unitValue: config.unitValue || 'UI',
            pointsList: [{ data: config.data, verticesNum: config.data.length }] || []
          }, () => {
            this.eyeMaskRef && this.eyeMaskRef.initTableState()
          })
        }
      })
    } else {
      this.setState({
        name: defaultLibraryName,
        unitValue: 'UI',
        pointsList: getDefaultConfigData(eyeMaskType)
      }, () => {
        this.eyeMaskRef && this.eyeMaskRef.initTableState()
      })
    }
  }

  getFileData = () => {
    const { libraryId, name } = this.props;
    getFileContent(libraryId).then(res => {
      if (res) {
        const parsedData = eyeMaskInfoConversion(res);
        this.setState({
          name: name,
          unitValue: 'UI',
          pointsList: parsedData,
          fileInfo: res
        }, () => {
          this.eyeMaskRef && this.eyeMaskRef.initTableState()
        })
      }
    });
  }

  closeModel = () => {
    const { type, eyeMaskType } = this.props;
    const { unitValue, error } = this.state;
    if (error) {
      return;
    }
    if (type === FILE) {
      // Cannot be modified, closing does not require saving data
      this.props.closePanel()
    } else {
      const points = this.eyeMaskRef.savePoints();
      if ((Array.isArray(points) && !points.length) || !points) {
        this.setState({
          saveMsgVisible: true
        })
        return;
      }
      const { libraryId } = this.props;
      const { name } = this.state;
      const libraryType = eyeMaskType;
      const data = {
        name: name,
        id: libraryId,
        config: {
          unitValue: unitValue,
          data: points
        },
        type: libraryType,
        version: "0.0.1",
      }
      // update library
      // Changes are only necessary if the data has been modified
      const { name: prevName, config } = this.FileInfo;
      if (!libraryId || name !== prevName || config.unitValue !== unitValue || hash(points) !== hash(config.data)) {
        this.props._updateLibraryData(libraryType, data);
      }
      this.props.closePanel()
    }
  };

  onRef = (ref) => {
    this.eyeMaskRef = ref;
  }

  savePoints = (points) => {
    let _pointsList = [...this.state.pointsList];
    if (_pointsList && _pointsList.length) {
      _pointsList[0].data = points;
    } else {
      _pointsList = [{
        data: points
      }]
    }
    this.setState({
      pointsList: _pointsList
    }, () => {
      this.eyeMaskRef && this.eyeMaskRef.initPlotState()
    })
  }

  unitChange = (unit) => {
    // to do
    // Change the unit. Do you want to modify the Time data？
    this.setState({
      unitValue: unit
    }, () => {
      this.eyeMaskRef && this.eyeMaskRef.initPlotState()
    })
  }

  updateEyeMaskName = (e) => {
    const name = e.target.value;
    let error = checkNameFormat(name);
    if (!error) {
      const { name: prevName } = this.props;
      const maskList = libraryConstructor.getLibraryValues(EYE_MASK);
      const cphyEaskList = libraryConstructor.getLibraryValues(CPHY_EYE_MASK);
      const libraryNames = [...maskList, ...cphyEaskList].map(item => item.name);
      if (libraryNames.includes(name) && name !== prevName) {
        error = "The name already exists, please enter another name."
      }
    }

    this.setState({
      name,
      error
    })
  }

  render() {
    const { title, type, libraryId, eyeMaskType } = this.props;
    const { name, pointsList, unitValue, height, panelWidth, panelHeight, panelY, panelX, fileInfo, saveMsgVisible, error } = this.state;
    const disableModified = type === FILE ? true : false;
    const content = (
      <Fragment>
        <Panel
          className='mask-panel aurora-box-shadow'
          position='panel-center-left'
          id='mask-panel'
          title={title}
          zIndex={2000}
          onCancel={() => { this.closeModel() }}
          width={eyeMaskType === CPHY_EYE_MASK ? 1100 : 850}
          minWidth={700}
          draggable
          minHeight={200}
          height={height}
        >
          <div className='eye-mask-content-box' id='eye-mask-content-box'>
            <MaskSetup
              id={libraryId}
              disableModified={disableModified}
              onRef={this.onRef}
              pointsList={pointsList}
              unitValue={unitValue}
              name={name}
              savePoints={this.savePoints}
              unitChange={this.unitChange}
              updateEyeMaskName={this.updateEyeMaskName}
              type={type}
              fileInfo={fileInfo}
              panelWidth={panelWidth}
              panelHeight={panelHeight}
              panelX={panelX}
              panelY={panelY}
              eyeMaskType={eyeMaskType}
              error={error}
            />
          </div>
        </Panel>
      </Fragment>
    )
    return <Fragment>
      {createPortal(content, this.dialogRoot)}
      {saveMsgVisible ? <DelConfirm
        type="close"
        closePanel={this.close}
        maskStyle={true}
        onFix={this.onFix}
        message={confirmErrorMsg}
      /> : null}
    </Fragment>
  }

  close = () => {
    this.props.closePanel();
  }

  onFix = () => {
    this.setState({
      saveMsgVisible: false,
      loading: false
    })
  }
}

export default MaskPanel;