import {
  uploadSpiceCard,
  delSpiceCardTemplate,
  getSpiceCardTemplateContent,
  runSpiceCardTemplate
} from "../../api/Himalayas/spiceCardTemplate";
import apiProcess from "../../api/utility";

/**
 * Upload spice card template ".xlsm" file
 * 2023/05/29
 * @param {String} fileName 
 * @param {File} file 
 * @param {String} name - Template name
 * @param {String} projectId
 *  */
function uploadSpiceCardTemplate({ fileName, file, name, projectId }) {
  let formData = new FormData();
  formData.append('file', file, fileName);
  formData.append('projectId', projectId);
  formData.append('name', name);
  return apiProcess(uploadSpiceCard, { formData });
}

/**
 * delete spice card template by ids
 * 2023/05/29
 * @param {Array} channelIds  ->spice card template id list 
 * */
function deleteSpiceCardTemplate(channelIds) {
  return apiProcess(delSpiceCardTemplate, channelIds);
}

/**
 * get spice card template content by id (not verificationId)
 * 2023/05/29
 * @param {string} id  ->spice card template id (channelId)
 * */
function getSpiceCardContent(id) {
  return apiProcess(getSpiceCardTemplateContent, id);
}

/**
 * run spice card template by id (not verificationId)
 * 2023/05/30
 * @param {string} id  ->spice card template id (channelId)
 * */
function runSpiceCard(channelId) {
  return apiProcess(runSpiceCardTemplate, channelId);
}

export {
  uploadSpiceCardTemplate,
  deleteSpiceCardTemplate,
  getSpiceCardContent,
  runSpiceCard
}