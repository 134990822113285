import { RockyProjectSaga } from '../store/project';
import RockyDesignUploadSaga from '../RockySider/UploadPCB/sagas';
import { rockySaga, dimmSaga } from './rocky';
import { rockySimulationSaga } from './simulation';
import rockyResultSaga from './result/sagas';
import { rockyPDNSaga } from './rockyPdn';
import { RockyPreLayoutSaga } from './prelayout';
import { RockyCardSaga } from './card';
import cardSaga from './simulation/card/saga';
import rockySSNSaga from './rockySSN/saga';
import SSNSimulationSaga from './simulation/ssn/saga';
import SSNResultSaga from './result/ssn/saga'
import multipleSaga from './multiple/saga';
import modelingSimulationSaga from './simulation/multiple/saga';

export default [
  rockyResultSaga,
  RockyProjectSaga,
  RockyDesignUploadSaga,
  rockySaga,
  rockySimulationSaga,
  rockyPDNSaga,
  RockyPreLayoutSaga,
  RockyCardSaga,
  cardSaga,
  dimmSaga,
  rockySSNSaga,
  SSNSimulationSaga,
  SSNResultSaga,
  multipleSaga,
  modelingSimulationSaga
]