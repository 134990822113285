import React, { Fragment, Component } from 'react';
import { Input } from 'antd';
import EyeMaskTable from './EyeMaskTable';
import EyeMaskPlot from './EyeMaskPlot';
import CPHYEyeMaskTable from './CPHYEyeMaskTable';
import { CPHY_EYE_MASK } from '../../../../constants/libraryConstants';
import CPHYEyeMaskSvg from './CPHYEyeMaskSvg';

class MaskSetup extends Component {
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    };
  };

  unitChange = (value) => {
    this.setState({
      unitValue: value
    })
  }

  savePoints = () => {
    let points = [];
    if (this.timeRef) {
      points = this.timeRef.getTimePoints('Error');
    }
    if (points === 'Error') {
      return false;
    }
    return points;
  }

  refreshPlot = () => {
    let points = [];
    if (this.timeRef) {
      points = this.timeRef.getTimePoints();
    }
    this.props.savePoints(points);
  }

  onTimeRef = (ref) => {
    this.timeRef = ref;
  }

  onPlotRef = (ref) => {
    this.plotRef = ref;
  }

  initPlotState = () => {
    this.plotRef && this.plotRef.initPlot()
  }

  initTableState = () => {
    this.timeRef && this.timeRef.initState()
  }

  render() {
    const { disableModified, name, unitValue, pointsList, type, fileInfo, panelWidth, panelHeight, panelY, panelX, eyeMaskType, error } = this.props;
    const tablePointsList = pointsList && pointsList.length ? pointsList[0].data : null;

    return (<Fragment>
      <div className='eye-mask-panel-section'>
        <span className='eye-mask-panel-left'>
          <label htmlFor='eye-mask-panel-name'>Name</label>
          <Input
            className={disableModified ? 'aurora-input eye-mask-panel-name-input aurora-input-disable' : 'aurora-input eye-mask-panel-name-input'}
            value={name}
            disabled={disableModified}
            onChange={this.props.updateEyeMaskName}
          />
          {error && <span className='eye-mask-panel-error'>{error}</span>}
        </span>
        <span className='eye-mask-panel-right'>
          <label>Unit</label>
          {/* <Select
            className='aurora-select'
            value={unitValue}
            onChange={(value) => this.props.unitChange(value)}
            dropdownStyle={{ zIndex: 10000 }}
            disabled={disableModified}
          >
            {['UI', 'ps'].map(opt => <Option key={opt} disabled={opt === 'ps' ? true : false}>{opt}{this.unit}</Option>)}
          </Select> */}
          <Input
            className={'aurora-input eye-mask-panel-name-input aurora-input-disable'}
            value={unitValue}
            disabled={true}
            onChange={this.props.unitChange}
          />
        </span>
      </div>
      <div className='eye-mask-panel-section'>
        <span className='eye-mask-panel-left'>
          {type === 'File'
            ? <pre className='file-content'>
              {fileInfo}
            </pre>
            : eyeMaskType !== CPHY_EYE_MASK
              ? <EyeMaskTable
                {...this.props}
                refreshPlot={this.refreshPlot}
                onRef={this.onTimeRef}
                pointsList={tablePointsList || []}
                unitValue={unitValue}
              />
              : <CPHYEyeMaskTable
                refreshPlot={this.refreshPlot}
                ref={(ref) => this.timeRef = ref}
                unitValue={unitValue}
                configData={tablePointsList}
              />
          }
        </span>
        <span className='eye-mask-panel-right'>
          <div className="eye-mask-time-voltage-plot">
            {
              eyeMaskType === CPHY_EYE_MASK
                ? <CPHYEyeMaskSvg
                  symbolRate={eyeMaskType === CPHY_EYE_MASK ? (tablePointsList || {}).symbol_rate : null}
                />
                : <EyeMaskPlot
                  {...this.props}
                  pointsList={pointsList}
                  onRef={this.onPlotRef}
                  unitValue={unitValue}
                  width={panelWidth}
                  height={panelHeight}
                  x={panelX}
                  y={panelY}
                />
            }
          </div>
        </span>
      </div>
    </Fragment >)
  }
}

export default MaskSetup;