import { isDefault } from '../../EyeMask/eyeMask';
import {
  MASK,
  MASK_DEFAULT,
  MASK_CUSTOMIZED
} from '../constants';
import {
  PROJECT,
  MY_LIBRARY,
  LIBRARY,
  PROJECTS,
  TRASH,
  LIBRARY_OPEN,
  SYS_LIBRARY,
  SYS_DECAP
} from '@/constants/treeConstants'
import {
  TRACE,
  VIA,
  VRM,
  PKG_TOUCHSTONE,
  PKG_SPICE,
  IBIS,
  SPICE,
  BUFFER,
  VECTOR,
  EBD,
  LIB_PKG,
  SOCKET_SPICE,
  SOCKET_TOUCHSTONE,
  SOCKET,
  WIRE_BOND_XML,
  CUSTOM_TOUCHSTONE,
  CUSTOM_SPICE,
  CUSTOM_COMPONENT,
  LIBRARY_PCB_MODEL,
  ON_DIE_TOUCHSTONE,
  PCB_PDN_TOUCHSTONE,
  ON_DIE_MODEL,
  PCB_PDN_MODEL,
  EXTRACTION_OPTIONS,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  CARD_LIBRARY,
  ON_DIE_SPICE,
  PCB_PDN_SPICE,
  PCB_SPICE,
  PCB_TOUCHSTONE,
  DECAP,
  DECAP_SPICE,
  DECAP_TOUCHSTONE,
  PATTERN_LIBRARY,
  USER_DEFINED_NETLIST,
  CUSTOM_POST_PROCESS,
  IBIS_AMI
} from '@/constants/libraryConstants';
import treeItem from '../../tree/treeItem';
import { getEyeMaskType, getEyeMaskList } from './projectTree'
import { SHARED_BY_ME, SHARED_WITH_ME } from '../../share/constants';
import { getDataTypePrefix } from '../../tree/treeItem';

function getDefaultTreeItems() {
  // const sharedTree = getShareTree();
  return [
    {
      name: 'System Library',
      key: SYS_LIBRARY,
      dataType: SYS_LIBRARY,
      nodeClass: 'expand-icon-large',
      children: [{
        name: 'Decap',
        key: SYS_DECAP,
        dataType: SYS_LIBRARY,
        children: [{
          name: 'Generic',
          key: 'Generic',
          dataType: SYS_LIBRARY,
          children: []
        }, {
          name: 'Vendor',
          key: 'Vendor',
          dataType: SYS_LIBRARY,
          children: []
        }]
      }]
    },
    {
      name: 'Library',
      key: LIBRARY,
      dataType: MY_LIBRARY,
      nodeClass: 'expand-icon-large',
      children: [
        {
          name: 'Buffer',
          key: BUFFER,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [{ name: 'IBIS', key: IBIS }, { name: 'IBIS AMI', key: IBIS_AMI }, { name: 'SPICE', key: SPICE }].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'Vector',
          key: VECTOR,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }, {
          name: 'VRM',
          key: VRM,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }, {
          name: 'Package',
          key: LIB_PKG,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: PKG_TOUCHSTONE },
            { name: 'SPICE', key: PKG_SPICE },
            { name: 'EBD', key: EBD }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'Eye Mask',
          key: MASK,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: getEyeMaskType().map(ddrType => {
            let ddrMask = new treeItem({ name: ddrType, key: MASK + '_' + ddrType, dataType: LIBRARY });
            ddrMask.children = getEyeMaskList(ddrType).map(item => {
              const id = MASK + '_' + ddrType + '_' + item;
              // Mask_{DDR_type}_[Standard|Aurora]
              return new treeItem({ name: item, key: id, dataType: MASK_DEFAULT, id: id });
            });
            return ddrMask;
          })
        }, {
          name: 'Trace Template',
          key: TRACE,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }, {
          name: 'Via Template',
          key: VIA,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        },
        {
          name: 'Socket',
          key: SOCKET,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: SOCKET_TOUCHSTONE },
            { name: 'SPICE', key: SOCKET_SPICE }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        },
        {
          name: 'Card',
          key: CARD_LIBRARY,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: []
        },
        {
          name: 'Custom Component',
          key: CUSTOM_COMPONENT,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: CUSTOM_TOUCHSTONE },
            { name: 'SPICE', key: CUSTOM_SPICE }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'Wire Bond Profile',
          key: WIRE_BOND_XML,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }, {
          name: 'Extraction Options',
          key: EXTRACTION_OPTIONS,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'HFSS', key: HFSS_OPTIONS },
            { name: "SIwave", key: SIWAVE_OPTIONS }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'PCB / PKG Signal Channel Model',
          key: LIBRARY_PCB_MODEL,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: PCB_TOUCHSTONE },
            { name: 'SPICE', key: PCB_SPICE },
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'On-Die PDN Model',
          key: ON_DIE_MODEL,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'SPICE', key: ON_DIE_SPICE },
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'PCB / Package PDN Model',
          key: PCB_PDN_MODEL,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: PCB_PDN_TOUCHSTONE },
            { name: 'SPICE', key: PCB_PDN_SPICE },
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY }))
        }, {
          name: 'Decap',
          key: DECAP,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Touchstone', key: DECAP_TOUCHSTONE, dataType: LIBRARY },
            { name: 'SPICE', key: DECAP_SPICE, dataType: LIBRARY }
          ]
        }, {
          name: 'Pattern',
          key: PATTERN_LIBRARY,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }, {
          name: 'User Defined NetList',
          key: USER_DEFINED_NETLIST,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }, {
          name: 'Custom Post-Processing',
          key: CUSTOM_POST_PROCESS,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }]
    }, {
      name: 'Project',
      key: PROJECTS,
      dataType: PROJECTS,
      nodeClass: 'expand-icon-large',
      children: []
    }, {
      name: 'Trash',
      key: TRASH,
      nodeClass: 'expand-icon-large',
      dataType: TRASH
    },
    // ...sharedTree
  ];
};

export function getTreeIndex(key) {
  const _key = getDataTypePrefix(key) ? getDataTypePrefix(key) : PROJECT;
  const obj = {
    [PROJECT]: 2,
    [SHARED_WITH_ME]: 3,
    [SHARED_BY_ME]: 4
  };
  return obj[_key];
};

export function getDataType(key) {
  const arr = [SHARED_BY_ME, SHARED_WITH_ME].map(d => d + '_');
  if (!key) return null;
  const splitString = key.split(new RegExp(`${arr.join('|')}`));
  if (!splitString) return null;
  if (splitString.length > 1) {
    return splitString[1];
  } else if (splitString.length === 1) {
    return splitString[0];
  };
  return null;
}

export function getMaskList(info, ddr) {
  if (!info) return [];
  try {
    const key = MASK + '_' + ddr + '_';
    const list = info.map(item => {
      const id = isDefault(item.origin) ? item.name : item.id;
      const dataType = isDefault(item.origin) ? MASK_DEFAULT : MASK_CUSTOMIZED;
      // TODO
      return new treeItem({ ...item, key: key + id, id: key + id, dataType: dataType })
    });
    return list;
  } catch (error) {
    console.error(error)
    return []
  }
}

export const defaultTreeItems = getDefaultTreeItems();