import React, { Component } from 'react';
import IRMenu from './IRMenu';
import IRTables from './IRResultContent';
import { pinColumns } from '@/services/Result/Public/DCTable/tableData';

function PinResult() {
  return (
    <div className="my-aurora-content">
      <IRMenu />
      <IRTables
        tableColumns={pinColumns}
        chartXType='voltageDrop'
        chartYType='pin'
        svgHeight='500'
        compareTypes={['I', 'voltageDrop']}
        rowKey='components'
        chartVisible={true}
      />
    </div>
  )
}

export default PinResult