import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { SettingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { createPortal } from 'react-dom';
import SchematicAdvanced from './schematicAdvanced';
import { transToSchematic, SchematicPlot } from '../../../../services/Cascade/PowerTree/schematic';
import { downloadFile } from '../../../../services/helper/downloadHelper';
import designConstructor from '../../../../services/helper/designConstructor';
import '../index.css';

class CascadeSchematic extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.state = {
      panelWidth: 800,
      panelHeight: 800,
      canvasWidth: 400,
      canvasHeight: 400,
      maxHeight: 1000,
      loading: true,
      font: 14,
      grid: false,
      advanced: false,
      left: 300,
      top: 150,
      tip: ''
    };
    this.schematicData = [];
    this.timeout = null;
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width = 1000 } = offset;
    const maxHeight = document.body.clientHeight;
    this.setState({
      panelWidth: width * 0.8,
      canvasWidth: width * 0.8 - 25,
      maxHeight: maxHeight - 200
    }, () => {
      this.resetPlot()
    })
  }

  resizeCanvas = () => {
    const content = document.getElementById('cascade-schematic');
    const contentOffset = content.getBoundingClientRect();
    const { width: canvasWidth = 800 } = contentOffset;
    this.setState({
      canvasWidth: canvasWidth - 25
    }, () => {
      this.resetPlot()
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.getSchematicData();
    this.resize();
  }

  componentDidUpdate = (prevProps) => {
    const { treeVerificationId, powerVerificationId } = this.props;
    if (treeVerificationId !== prevProps.treeVerificationId
      || powerVerificationId !== prevProps.powerVerificationId) {
      this.getSchematicData()
    }
  }

  getSchematicData = () => {
    this.setState({
      loading: true
    }, () => {
      const { powerTrees = [] } = this.props;
      this.schematicData = transToSchematic(powerTrees);
      const { height } = this.schematicData;
      this.setState({
        canvasHeight: height + 15,
        panelHeight: height + 80
      }, () => {
        this.initPlot();
      })
    })
  }

  initPlot = () => {
    const { canvasWidth, canvasHeight, font } = this.state;
    this.brush = new SchematicPlot({ id: `cascade-schematic-canvas`, width: canvasWidth, height: canvasHeight, data: this.schematicData, font, updateTip: this.updateTip });
    this.draw();
    this.setState({
      loading: false
    })
  }

  resetPlot = () => {
    if (!this.brush) {
      this.initPlot()
    } else {
      const { canvasWidth, canvasHeight } = this.state;
      this.brush.reset({ width: canvasWidth, height: canvasHeight });
      this.draw();
    }
  }

  updateTip = (tip) => {
    this.setState({
      tip
    })
  }

  draw = () => {
    this.brush.clear();
    this.brush.draw();
  }

  closeModal = () => {
    this.props.closeModal()
  }

  settingClick = (e, boolean) => {
    e && e.stopPropagation()
    let left = this.state.left;
    let top = this.state.top;
    if (boolean) {
      const ele = document.getElementById('cascade-schematic');
      const { x, width, y } = ele.getBoundingClientRect();
      let innerWidth = window.innerWidth;
      left = x - 300 > 0 ? x - 150 : x + width + 300 >= innerWidth ? left : x + width + 150;
      top = y;
    }
    this.setState({
      advanced: boolean,
      left,
      top
    })
  }

  changeGrid = (checked) => {
    this.setState({
      grid: checked
    }, () => {
      this.brush.set('grid', checked);
      this.brush.redraw()
    })
  }

  changeFont = (e, font) => {
    e && e.stopPropagation()
    this.setState({
      font
    }, () => {
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.brush.set('font', font);
        this.brush.redraw()
      }, 200)
    })
  }

  downloadCanvas = (e) => {
    e && e.stopPropagation();
    const ele = document.getElementById(`cascade-schematic-canvas`);
    const { pcbId } = this.props;
    const name = designConstructor.getDesignName(pcbId) || 'Schematic';
    downloadFile(ele, name, 'canvas');
  }

  render() {
    const { panelWidth, panelHeight, loading, canvasWidth, canvasHeight, advanced, font, grid, left, top, maxHeight, tip } = this.state;
    const content = (
      <Fragment>
        <Panel
          position='panel-center-left'
          id="cascade-schematic"
          title={<Fragment>
            <span>Power Tree Schematic</span>
            <SettingOutlined
              className='cascade-schematic-setting-icon'
              onClick={(e) => this.settingClick(e, true)}
              title="Advanced" />
          </Fragment>}
          zIndex={2000}
          onCancel={this.closeModal}
          width={panelWidth}
          height={panelHeight}
          draggable
          minWidth={600}
          maxHeight={maxHeight}
          resize={this.resizeCanvas}
        >
          {(loading || tip) && <Spin spinning={loading || tip ? true : false} size="large" tip={tip || "Generating Schematic..."} wrapperClassName='cascade-schematic-loading'>
            <div style={{ width: "100%", height: 600, position: 'absolute' }}></div>
          </Spin>}
          <div className="cascade-schematic-content" style={{ height: panelHeight - 63 }}>
            <canvas id={`cascade-schematic-canvas`} className="power-tree-canvas" widht={canvasWidth} height={canvasHeight} />
          </div>
        </Panel>
        {advanced && <SchematicAdvanced
          font={font}
          grid={grid}
          changeGrid={this.changeGrid}
          settingClick={this.settingClick}
          changeFont={this.changeFont}
          downloadCanvas={this.downloadCanvas}
          left={left}
          top={top}
        />}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default CascadeSchematic;