import CeDonutRound from './CeDonutRound';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeDonutSquare = function () {
  CeDonutRound.call(this, "DonutSquare");
}

// subclass extends superclass
CeDonutSquare.prototype = Object.create(CeDonutRound.prototype);
CeDonutSquare.prototype.constructor = CeDonutSquare;

CeDonutSquare.prototype.Clone = function () {
  var clnObj = new CeDonutSquare();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeDonutSquare.prototype.WriteIntoIODataNode = function () {
  var dataVals = CeDonutRound.prototype.GetDataString.call(this);
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

export default CeDonutSquare;