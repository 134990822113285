import { getEndToEndChannelContent } from "./endToEndChannelCtrl";

class EndToEndSetup {
  constructor() {
    this.endToEndSetupList = new Map(); //key - end to end channel id, value :{ id ,name, content }
  }

  get = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(null);
      }
      const info = this.endToEndSetupList.get(id);
      if (info) {
        resolve(info);
      } else {
        getEndToEndChannelContent(id).then(res => {
          res && this.endToEndSetupList.set(id, res);
          resolve(res);
        });
      }
    })
  }

  set = (id, data) => {
    this.endToEndSetupList.set(id, data);
  }

  delete = (id) => {
    this.endToEndSetupList.delete(id);
  }

  clearCache = () => {
    this.endToEndSetupList = new Map();
  }

  getContent = (id) => {
    const info = this.endToEndSetupList.get(id);
    if (!info || !info.content) {
      return [];
    }
    return info.content;
  }

  getPCBConnections = (id) => {
    const info = this.endToEndSetupList.get(id);
    if (!info || !info.content || !info.content.pcbConnections) {
      return [];
    }
    return info.content.pcbConnections;
  }
};

let endToEndSetup = new EndToEndSetup();
export default endToEndSetup;