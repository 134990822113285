import React, { PureComponent } from "react";
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip, Menu } from "antd";
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import EditableTable from "../../../components/EditableTable";
import StackupZonesCanvas from "../../../services/LayoutCanvas/StackupZonesCanvas";
import '../multiZone.css';

const columns = [
  {
    title: "Zone",
    dataIndex: "name"
  },
  {
    title: "Stackup",
    dataIndex: "stackup"
  }
]

const bendColumns = [
  {
    title: "Bend",
    dataIndex: "bend",
    width: 400
  },
  {
    title: "Side",
    dataIndex: "side",
    width: 100
  },
  {
    title: "Angle",
    dataIndex: "angle",
    width: 100
  },
  {
    title: 'Radius',
    dataIndex: 'radius',
    width: 100
  }
]


class StackupMultiZones extends PureComponent {

  constructor(props) {
    super(props);
    this.root = document.getElementById(`PCB-${this.props.designID}`);
    const { zones = [] } = this.props;
    this.state = {
      selectedBends: [],
      showKeys: ['Zones'],
      height: zones.length * 40 + 170
    }
  }

  setZoneColumn = () => {
    columns[0].title = () => {
      const { selectedZones = [], zones } = this.props;
      const notShowZone = selectedZones && zones ? zones.find(item => !selectedZones.includes(item.name)) : true;
      return (
        <div>
          <span>Zone</span>
          <EyeOutlined
            className={!notShowZone ? "stackup-zones-display-icon stackup-zones-display-highlight-icon" : "stackup-zones-display-icon"}
            onClick={(e) => this.showZone(e, "all")} />
        </div>
      );
    }
    columns[0].render = (name, record) => {
      const { selectedZones = [] } = this.props;
      return (
        <div>
          <EyeOutlined
            className={selectedZones && selectedZones.includes(name) ? "stackup-zones-display-icon stackup-zones-display-highlight-icon" : "stackup-zones-display-icon"}
            onClick={(e) => this.showZone(e, record)} />
          <span>{name}</span>
        </div>
      );
    }
  }

  setBendColumn = () => {
    bendColumns[0].title = () => {
      const { bends = [] } = this.props;
      const { selectedBends = [] } = this.state;
      const showBends = selectedBends.length === bends.length ? true : false;
      return (
        <div>
          <span>Bend</span>
          <EyeOutlined
            className={showBends ? "stackup-zones-display-icon stackup-zones-display-highlight-icon" : "stackup-zones-display-icon"}
            onClick={(e) => this.changeBendShow(e, "all")} />
        </div>
      );
    }

    bendColumns[0].render = (name, record, index) => {
      const { selectedBends = [] } = this.state;
      const { x1, x2, y1, y2 } = record;
      return (
        <div>
          <EyeOutlined
            className={selectedBends && selectedBends.includes(index) ? "stackup-zones-display-icon stackup-zones-display-highlight-icon" : "stackup-zones-display-icon"}
            onClick={(e) => this.changeBendShow(e, index)} />
          <span>([{x1}, {y1}], [{x2}, {y2}])</span>
        </div>
      );
    }

    bendColumns[3].title = () => {
      const { bends = [] } = this.props;
      if (!bends.length) {
        return <span>Radius</span>
      }
      const unit = bends[0].unit
      return <span>Radius ({unit})</span>
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
    const { designID } = this.props;
    this.stackupZonesCanvas = designID ? new StackupZonesCanvas({ designID }) : null;
    this.setZoneColumn();
    this.setBendColumn();
  }

  componentDidUpdate = (prevProps) => {
    const { designID } = this.props;
    if (prevProps.designID !== designID && designID) {
      this.stackupZonesCanvas = new StackupZonesCanvas({ designID: designID });
    }
  }

  showZone = (e, record) => {
    e && e.stopPropagation();
    let { selectedZones = [], zones, designID } = this.props;
    let newSelectedZones = [], deletedZones = [];
    if (!selectedZones) {
      selectedZones = [];
    }
    if (record === "all") {
      const prevNotShowZone = zones.find(item => !selectedZones.includes(item.name));
      if (prevNotShowZone) {
        selectedZones = zones.map(item => item.name);
        newSelectedZones = [...selectedZones];
      } else {
        selectedZones = [];
        newSelectedZones = [];
        deletedZones = zones.map(item => item.name);
      }
    } else {
      if (selectedZones.includes(record.name)) {
        selectedZones = selectedZones.filter(item => item !== record.name);
        deletedZones.push(record.name)
      } else {
        selectedZones.push(record.name);
        newSelectedZones.push(record.name);
      }
    }

    this.props._updateSelectedZones(selectedZones, designID);
    if (this.stackupZonesCanvas) {
      this.stackupZonesCanvas.updateShowZones({ selectedZones: newSelectedZones, deletedZones, zones });
    }
  }

  onCancel = () => {
    this.clearShowZones();
    this.props.cancel();
  }

  clearShowZones = () => {
    const { selectedZones, zones, designID } = this.props;
    this.props._updateSelectedZones([], designID);
    if (this.stackupZonesCanvas) {
      this.stackupZonesCanvas.updateShowZones({ selectedZones: [], deletedZones: selectedZones, zones });
    }
  }

  _openStackupPanel = (e) => {
    e && e.stopPropagation();
    //open stackup panel
    this.props.openStackupPanel({ key: "Stackup" });
  }

  changeBendShow = (e, show) => {
    e && e.stopPropagation()
    const { selectedBends } = this.state;
    const { bends = [] } = this.props;
    this.setState({
      selectedBends: show === 'all' ?
        bends.length === selectedBends.length ? [] : bends.map((item, index) => index) :
        selectedBends.includes(show) ? selectedBends.filter(i => i !== show) : [...selectedBends, show]
    }, () => {
      const { selectedBends } = this.state;
      if (this.stackupZonesCanvas) {
        this.stackupZonesCanvas.updateShowBends({ bends, selectedBends });
      }
    })
  }

  selectMenu = ({ key }) => {
    const { showKeys } = this.state;
    const newKeys = showKeys.includes(key) ? showKeys.filter(k => k !== key) : [...showKeys, key]
    this.setState({
      showKeys: !newKeys.length ? ['Zones'] : newKeys
    })
  }

  menuRender = () => {
    const { showKeys } = this.state;
    return <Menu
      onClick={this.selectMenu}
      selectedKeys={showKeys}
      multiple={true}
      items={['Zones', 'Bends'].map(key => ({ key, label: key }))}
    />
  }

  resize = ({ width, height, x, y }) => {
    this.setState({
      height
    })
  }

  render() {
    const { defaultLeft, defaultTop, zones, width, maxHeight, maxWidth, leftWidth, bends = [] } = this.props;
    const { showKeys, height } = this.state;
    if (!this.root) return null;
    const _zones = zones.map((record, index) => ({ ...record, index }));
    const _bends = bends.map((record, index) => ({ ...record, index }));
    const content = (
      <Panel
        title={<div>
          <span>Stackup</span>
          <Tooltip title="Open Stackup Panel" overlayClassName="aurora-tooltip">
            <EditOutlined
              className="stackup-zones-edit-icon"
              onClick={(e) => this._openStackupPanel(e)} />
          </Tooltip>
        </div>}
        onCancel={this.onCancel}
        position='panel-bottom-right'
        className='stackup-multi-zone-panel'
        left={width ? leftWidth : defaultLeft}
        top={defaultTop}
        draggable
        minWidth={256}
        minHeight={80}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        redirectDom='aurora-content'
        overflow="auto"
        resize={this.resize}
      >
        <div className='stackup-multi-zone-content'>
          {this.menuRender()}
          {showKeys.includes('Zones') && <div className='stackup-multi-zone-body'>
            <span>Zones</span>
            <EditableTable
              columns={columns}
              rowKey={(record) => record.index}
              size="small"
              dataSource={_zones}
            />
          </div>}
          {showKeys.includes('Bends') && <div className='stackup-multi-zone-body'>
            <span>Bends</span>
            <EditableTable
              columns={bendColumns}
              rowKey={(record) => record.index}
              size="small"
              dataSource={_bends}
              // scroll={bends.length > zones.length ? { y: zones.length * 40 } : {}}
              scroll={bends && zones && bends.length > zones.length && height < bends.length * 40 ? { y: height - 170 } : {}}
              tableLayout='fixed'
              style={{ width: 750 }}
            />
          </div>}
        </div>
      </Panel>
    )
    return createPortal(content, this.root)
  }
}

export default StackupMultiZones;