import React, { Fragment, PureComponent } from 'react';
import { Radio, Switch, Divider } from 'antd';
import ExtractionOptions from './extractionOptions';
import { numFormatConversion, scaleConversion, numExponentialFormat } from '@/services/helper/numberHelper';
import NP from 'number-precision';
import { SIERRA } from '@/constants/pageType';
import { checkSweepValue } from '@/services/helper/valueCheck';
import { HPCOptions } from '@/services/Sierra/IntegratedInterface';
import designConstructor from '../../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import PowerSIOptions from './PowerSIOptions';
import '../index.css';

const DEFAULT = 'Default', ANSYS = 'Ansys', SIWAVE = 'SIwave', HFSS = 'HFSS', POWERSI = 'PowerSI';
class ExtractionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      channelType: 'Default',
      extraction: {},
      units: {
        logSweepMinUnit: 'Hz',
        logSweepMaxUnit: "MHz",
        linearSweepMaxUnit: "GHz",
        linearSweepFreqStepUnit: "MHz",
        maxFreqUnit: "GHz"
      },
      clipSizeUnit: "ratio",
      error: null,
      ifDoExtraction: 0,
      xTalk: "1",
      maxHeight: 600,
      maxWidth: 830,
      isMultiZone: false,
      isPreLayout: false,
      hpcOptionSwitch: false,
    }
    this.dialogRoot = document.getElementById('root');
  };

  componentDidMount() {
    this.props.onRef(this, this.props.current)
    this.getExtractionValue()
  }

  componentDidUpdate(prevProps) {
    const { update, current, updateStatus } = this.props;

    const isUpdate = prevProps.update !== update;
    if (isUpdate || prevProps.current !== current) {
      this.getExtractionValue();
      isUpdate && updateStatus && updateStatus()
    }
  }

  getExtractionValue = () => {
    const { extraction, pcbId, channelType,
      ifDoExtraction, currentConfig: { xTalk },
      portImpedance: propsPortImpedance } = this.props;

    //multi-zone
    const isMultiZone = pcbId ? designConstructor.getDesignMultiZone(pcbId) : false;
    //pre-layout
    const isPreLayout = pcbId ? designConstructor.getDesignVendor(pcbId) === PRE_LAYOUT ? true : false : false;

    if (extraction) {
      let { clipping, logSweepMin, logSweepMax,
        logSweepSAPD, linearSweepMax,
        linearSweepFreqStep, maxFreq,
        clipSize, includeDC, exactDC, discreteSweep, meshFrequency, maxPasses, maxDelta,
        portImpedance, multiZone, enablePassivePort,
        // powersi
        numCores, corePercent,
        pwsiMaxPadSize, pwsiMinShapeSize, pwsiDogleg,
        pwsiThermal, pwsiHole, pwsiViaHole,
        pwsiSlenderHoleSize,
        pwsiAutoSpecialVoid,
        bottomFloating = false,
        bottomDistance, topFloating = false,
        topDistance,
        pwsiSlenderHoleSizeThreshold, pwsiSlenderHoleAreaThreshold,
        pwsiAutoSpecialVoidSetting = false
      } = extraction;

      let clipSizeUnit = 'ratio';
      if (clipSize && clipSize.toString().includes('mm')) {
        clipSizeUnit = 'mm';
        clipSize = parseFloat(clipSize).toString();
      }
      let _portImpedance = portImpedance;
      if (!_portImpedance) {
        _portImpedance = propsPortImpedance || "50";
      }
      let _extraction = {
        clipping: clipping,
        logSweepMin: numFormatConversion(logSweepMin) || "1000",
        logSweepMax: numFormatConversion(logSweepMax, -6) || "10",
        logSweepSAPD: logSweepSAPD || "10",
        linearSweepMax: numFormatConversion(linearSweepMax, -9) || "5",
        linearSweepFreqStep: numFormatConversion(linearSweepFreqStep, -6) || "5",
        maxFreq: numFormatConversion(maxFreq, -9) || "5",
        clipSize: clipSize || "0.3",
        includeDC: includeDC || false,
        exactDC: exactDC || false,
        discreteSweep: discreteSweep || false,
        meshFrequency: meshFrequency || "5",
        maxPasses: maxPasses || "10",
        maxDelta: maxDelta || "0.02",
        portImpedance: _portImpedance,
        multiZone: multiZone || { stackupMode: 'Laminate', enableBends: true },
        enablePassivePort: enablePassivePort || false,
        // powersi
        numCores: numCores || "4",
        corePercent: corePercent || "50",
        pwsiMaxPadSize: pwsiMaxPadSize === "-1" ? "" : pwsiMaxPadSize || "",
        pwsiMinShapeSize: pwsiMinShapeSize === "-1" ? "" : pwsiMinShapeSize || "",
        pwsiDogleg: pwsiDogleg || "1.5",
        pwsiThermal: pwsiThermal || "1.5",
        pwsiHole: pwsiHole || "1.5",
        pwsiViaHole: pwsiViaHole || "1.5",
        pwsiSlenderHoleSize: pwsiSlenderHoleSize || "1",
        pwsiAutoSpecialVoid: pwsiAutoSpecialVoid === undefined ? pwsiAutoSpecialVoidSetting : pwsiAutoSpecialVoid,
        bottomFloating: bottomFloating,
        bottomDistance: bottomDistance || "0.01",
        topFloating: topFloating,
        topDistance: topDistance || "0.01",
        pwsiSlenderHoleAreaThreshold: pwsiSlenderHoleAreaThreshold || "0",
        pwsiSlenderHoleSizeThreshold: pwsiSlenderHoleSizeThreshold || "0"
      }

      let hpcOptionSwitch = false;
      //hpc options
      if (extraction.hasOwnProperty("hpc_options")) {
        hpcOptionSwitch = true;
        _extraction.hpc_options = { ...extraction.hpc_options };
      }
      this.setState({
        extraction: { ..._extraction },
        clipSizeUnit: clipSizeUnit,
        channelType,
        ifDoExtraction,
        xTalk,
        isMultiZone,
        hpcOptionSwitch,
        isPreLayout
      })
    }
  }

  handleChange = (checked) => {
    this.setState({
      ifDoExtraction: checked ? 1 : 0
    })
  }

  getChannelRadio = (channelType) => {
    if (channelType === 'Default') {
      return 'Default';
    } else if (channelType === 'SIwave' || channelType === 'HFSS') {
      return 'Ansys';
    } else if (channelType === "PowerSI") {
      return "PowerSI"
    }
  }

  changeChannelType = (e) => {
    let value = e.target.value;
    const { extraction } = this.state;
    let _extraction = { ...extraction };
    if (value === ANSYS) {
      value = "SIwave";
    }
    /*  if (value === POWERSI) {
       _extraction['includeDC'] = true;
     } */
    this.setState({
      channelType: value,
      extraction: _extraction
    })
  }

  changeAnsysType = (e) => {
    this.setState({
      channelType: e.target.value
    })
  }

  _updateError = (error) => {
    this.setState({
      error
    })
  }

  updateUnits = (units, type) => {
    //units / clipSizeUnit
    this.setState({
      [type]: units
    })
  }

  updateCurrentExtraction = (config, type) => {
    //extraction / portReference
    this.setState({
      [type]: { ...config }
    })
  }

  changeXTalk = (checked, type) => {
    let xTalk = this.state.xTalk;
    if (type === "xTalkAccuracy") {
      xTalk = checked ? 2 : 1;
    } else if (type === "xTalk") {
      xTalk = checked ? 1 : 0;
    }
    this.setState({
      xTalk
    })
  }

  closeModal = (closePanel) => {
    const { error, units, extraction, channelType, clipSizeUnit, ifDoExtraction, xTalk, hpcOptionSwitch } = this.state;
    const { currentConfig, channelType: prevChannelType, ifDoExtraction: prevIfDoExtraction, apply, current } = this.props;

    const _prevChannelType = prevChannelType || 'Default';
    let update = false;

    if (error && error.type !== "judge") {
      return;
    }
    let config = { ...extraction };

    let unitTypes = ["logSweepMin", "logSweepMax", "maxFreq", "linearSweepMax", "linearSweepFreqStep"]

    //update extraction config by unit
    unitTypes.forEach(item => {
      const unit = `${item}Unit`;
      const scale = scaleConversion("Hz", units[unit]);
      config[item] = config[item] ? NP.times(config[item], scale).toString() : config[item];
      config[item] = numExponentialFormat(config[item]);
    })

    config.clipSize = clipSizeUnit === 'mm' ? `${config.clipSize}${clipSizeUnit}` : config.clipSize;
    const errorMsg = checkSweepValue({
      logSweepMin: config["logSweepMin"],
      logSweepMax: config["logSweepMax"],
      linearSweepMax: config["linearSweepMax"],
      maxFreq: config["maxFreq"],
      version: SIERRA
    });
    if (errorMsg) {
      this.setState({
        error: { error: errorMsg, type: "judge" }
      });
      return;
    }

    if (!hpcOptionSwitch) {
      delete config.hpc_options;
    }

    //save xTalk
    if (currentConfig && currentConfig.xTalk !== xTalk) {
      update = true;
      this.props._updateOptions('coupling', xTalk, 'coupling');
    }

    //save channel type
    if (_prevChannelType !== channelType) {
      update = true;
      this.props.editChannelType({ pcb: current, channel: channelType });
    }

    //save ifDoExtraction
    if (prevIfDoExtraction !== ifDoExtraction) {
      update = true;
      this.props.updateReExtraction({ ifDoExtraction, closePanel });
    }

    //save extraction config
    this.props.saveExtraction({ ...config }, current, channelType, apply, closePanel, { xTalk, ifDoExtraction, update });
    closePanel && this.props.closeModal && this.props.closeModal();
  }

  changeSwitch = (checked, type) => {
    const { extraction } = this.state;
    let _extractionConfig = { ...extraction };
    if (type === 'hpcOptionSwitch') {
      if (checked) {
        _extractionConfig = {
          ..._extractionConfig,
          hpc_options: {
            ...new HPCOptions()
          }
        }
      } else {
        delete _extractionConfig.hpc_options;
      }
      this.setState({
        extraction: { ..._extractionConfig },
        [type]: checked
      })
    }
  }


  switchChange = (checked, type) => {
    const { extraction } = this.state;
    let config = { ...extraction };
    config[type] = checked;
    this.setState({
      extraction: { ...config }
    })
  }

  getDefaultRender = () => {
    const { channelType, xTalk } = this.state;
    return <div className='sierra-extraction-option-default-content'>
      <Divider />
      <div className='sierra-extraction-option-crosstalk-item'>
        <label
          className={channelType === 'Default' ? "sierra-extraction-option-name" : "sierra-extraction-option-name sierra-extraction-gray"}
          style={{ width: 110 }}>Enable coupling</label>
        <span className='sierra-exc-options-crosstalk'>
          <Switch
            size="small"
            className='aurora-switch-small'
            checked={xTalk === 1 || xTalk === 2 ? true : false}
            onChange={(checked) => this.changeXTalk(checked, "xTalk")}
            disabled={channelType !== 'Default'}
          />
        </span>
      </div >
      {xTalk === 1 || xTalk === 2 ? <div className='sierra-extraction-option-sub-item'>
        <label className={channelType === 'Default' ? "sierra-extraction-option-name" : "sierra-extraction-option-name sierra-extraction-gray"}
          style={{ width: 105 }}>High Accuracy</label>
        <span className='sierra-exc-options-crosstalk'>
          <Switch
            size="small"
            className='aurora-switch-small'
            checked={xTalk === 2 ? true : false}
            onChange={(checked) => this.changeXTalk(checked, "xTalkAccuracy")}
            disabled={channelType !== 'Default'}
          />
        </span>
      </div> : null
      }
    </div>
  }

  getAnsysRender = () => {
    const { currentInterface, isMultiSetup } = this.props;
    const { extraction, channelType,
      error, ifDoExtraction, units, clipSizeUnit, isMultiZone,
      hpcOptionSwitch
    } = this.state;

    const simulateHFSS = channelType === "HFSS" ? true : false;
    const radioValue = this.getChannelRadio(channelType);
    const ansysType = radioValue === 'Ansys' ? channelType : 'SIwave';
    return <Fragment>
      <Divider />
      <Radio.Group onChange={this.changeAnsysType} value={ansysType} className='extraction-radio-extraction-group'>
        <Radio
          value={SIWAVE}
          className='extraction-radio-group-item'>
          SIwave</Radio>
        <Radio
          value={HFSS}
          className='extraction-radio-group-item'>
          HFSS</Radio>
      </Radio.Group>
      <Divider />
      <div className='sierra-re-extraction'>
        <span>Force Re-Extraction</span>
        <Switch
          size="small"
          disabled={(currentInterface || isMultiSetup) ? false : true}
          className='sierra-re-extraction-switch aurora-switch-small'
          checked={ifDoExtraction === 1 ? true : false}
          onChange={this.handleChange}
        />
      </div>
      <Divider />
      <ExtractionOptions
        updateCurrentExtraction={this.updateCurrentExtraction}
        updateError={this._updateError}
        updateUnits={this.updateUnits}
        error={error}
        units={units}
        clipSizeUnit={clipSizeUnit}
        extraction={extraction}
        simulateHFSS={simulateHFSS}
        isMultiZone={isMultiZone}
        changeSwitch={this.changeSwitch}
        hpcOptionSwitch={hpcOptionSwitch}
        passivePortContent={this.passivePortContent}
      />
    </Fragment>
  }

  passivePortContent = () => {
    const { extraction = {} } = this.state;
    const { enablePassivePort } = extraction;
    return <div className='sierra-extraction-content extraction-content-passive'>
      <span className='sierra-extraction-content-body'>Set ports to all passive components</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={enablePassivePort}
        onChange={(e) => this.switchChange(e, "enablePassivePort")}
      />
    </div>
  }

  getPowersiRender = () => {
    const { currentInterface, isMultiSetup } = this.props;
    const { extraction, error, channelType, units, ifDoExtraction } = this.state;
    return <PowerSIOptions
      updateExtractionConfig={(config) => this.updateCurrentExtraction(config, "extraction")}
      channelType={channelType}
      extraction={extraction}
      error={error}
      units={units}
      currentInterface={currentInterface}
      isMultiSetup={isMultiSetup}
      ifDoExtraction={ifDoExtraction}
      updateError={this._updateError}
      updateUnits={this.updateUnits}
      passivePortContent={this.passivePortContent}
      handleChange={this.handleChange}
    />
  }

  optionRender = (radioValue) => {
    switch (radioValue) {
      case ANSYS:
        return this.getAnsysRender();
      case POWERSI:
        return this.getPowersiRender();
      case DEFAULT:
      default:
        return this.getDefaultRender();
    }
  }

  render() {
    const { channelType, isPreLayout } = this.state;

    const radioValue = this.getChannelRadio(channelType);
    return <div className='sierra-extraction-panel-content'>
      <Radio.Group onChange={this.changeChannelType} className='extraction-radio-group' value={radioValue}>
        {isPreLayout ? null : <Fragment>
          <Radio
            className='extraction-radio-group-item'
            value={ANSYS}>
            Ansys</Radio>
          <Radio
            className='extraction-radio-group-item'
            value={POWERSI}>
            Cadence PowerSI</Radio></Fragment>}
        {<Radio
          className='extraction-radio-group-item'
          value={DEFAULT}>
          Built-in</Radio>}
        {this.optionRender(radioValue)}
      </Radio.Group>
    </div>
  }
}


export default ExtractionPanel;