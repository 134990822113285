function resProcessing(str) {
  if (str) {
    return str.replace(/(H)|(vr)|(Ohm)|[\n\s]/g, "")
      .replace(',', ', ')
      .replace(/[=]/g, ' = ');
  } else {
    return '';
  }
}

function getVRMData(str) {
  if (str) {
    const data = str.replace(/[\s]/g, "").split(',');
    let obj = {};
    data.forEach(item => {
      const dat = item.split('=');
      obj[dat[0]] = dat[1];
    })
    return [obj];
  }
  else {
    return [];
  }
}

export { resProcessing, getVRMData };