import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { message, Select } from "antd";
import Panel from "@/components/Panel";
import ReportTemplate from '@/components/ReportTemplate/reportTemplate'
import DelConfirm from '@/components/DelConfirm';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { reportProgressRender } from "../../../../components/Report";
import { updateReportInfo, saveReportConfig, getReport, clearReportInfo } from "../../store/project/action";
import cascadeChannels from "../../../../services/Cascade/DB/cascadeChannels";
import { IMPEDANCE, SIGN_OFF_TEMPLATE, CASCADE_SIGN_OFF, CASCADE_IMPEDANCE } from "../../../../constants/treeConstants";
import designsDB from '@/services/helper/designConstructor';
import ReportAdvance from '@/components/reportAdvance';
import { CASCADE } from "../../../../constants/pageType";
import { timeFormat } from '@/services/helper/timeFormat';
import { generateReport } from "../../../../services/Cascade/project";
import cascadeProjects from '@/services/Cascade/DB/cascadeProject';

const { Option, OptGroup } = Select;

const referenceLinesColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%'
  },
  {
    title: 'Frequency (MHz)',
    dataIndex: 'x',
    key: 'x',
    width: '35%'
  },
  {
    title: 'Impedance (Ω)',
    dataIndex: 'y',
    key: 'y',
    width: '35%'
  },
];

class ReportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeVisible: false,
      maxWidth: 700,
      maxHeight: 320,
      treeData: [],
      downloadLoading: true
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 320),
      maxWidth: getPanelMaxWidth(offset, 700)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getDefaultList();
  }

  componentDidUpdate = async (prevProps) => {
    const { projectId, /* viewList, reportInfo */ } = this.props;
    if (prevProps.projectId !== projectId) {
      //clear prev project report info
      this.props._clearReportInfo();
      this.props.closePanel();
    }

    /* const typeName = viewList.includes(IMPEDANCE) ? CASCADE_IMPEDANCE : (viewList.includes(SIGN_OFF_TEMPLATE) ? CASCADE_SIGN_OFF : null);
    if (typeName !== reportInfo.reportTypeName && !reportInfo.reportVisible) {
      await this.props.changeReportConfig(typeName);
    } */
  }

  getDefaultList = (_typeName) => {
    const { reportTypeName, projectId } = this.props;
    let typeName = _typeName || reportTypeName;
    let list = [], verificationList = [];
    if (typeName === CASCADE_SIGN_OFF) {
      verificationList = cascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId) || [];
      list = verificationList.map(item => {
        return {
          title: item.name,
          value: item.id,
          key: item.id
        }
      })
    } else {
      const templateList = cascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId) || [];
      verificationList = cascadeChannels.getList(IMPEDANCE, projectId) || [];
      for (let item of verificationList) {

        if (item.signOffId) {
          const index = list.findIndex(it => it.key === item.signOffId);
          if (index > -1) {
            list[index].children.push({
              title: item.name,
              value: item.id,
              key: item.id
            })
          } else {
            const template = templateList.find(it => it.id === item.signOffId) || {};
            list.push({
              title: template.name,
              value: template.id,
              key: template.id,
              type: SIGN_OFF_TEMPLATE,
              children: [{
                title: item.name,
                value: item.id,
                key: item.id
              }]
            })
          }
        } else {
          list.push({
            title: item.name,
            value: item.id,
            key: item.id
          })
        }
      }
    }
    this.setState({ treeData: list });
  }

  closeModalPanel = () => {
    const { reportVisible } = this.props;
    if (reportVisible) {
      this.setState({
        closeVisible: true
      })
    } else {
      this.props._clearReportInfo()
      this.props.closeModal()
    }
  }

  onMinimize = () => {
    this.props.closeModal();
    this.props._saveReportConfig()
  }

  deleteConfirm = () => {
    this.props.closeModal();
    this.props._clearReportInfo();
  }

  cancelDel = () => {
    this.setState({
      closeVisible: false
    })
  }

  onTreeChange = (value) => {
    const { reportConfig, reportTypeName } = this.props;
    let config = { ...reportConfig };
    if (reportTypeName === CASCADE_SIGN_OFF) {
      config.verificationIds = [value];
    } else {
      config.verificationIds = value;
    }
    this.props._updateReportInfo({ reportConfig: config, reportMessage: "" })
  }

  changeReportType = (value) => {
    const { reportConfig } = this.props;
    let _reportConfig = { ...reportConfig };
    _reportConfig.format = value;
    this.props._updateReportInfo({ reportConfig: _reportConfig, reportMessage: "" })
  }

  downloadReport = () => {
    const { reportConfig, projectId, reportTypeName } = this.props;
    if (!reportConfig || !reportConfig.verificationIds || !reportConfig.verificationIds.length || !reportConfig.templateId) { return; }
    this.props._updateReportInfo({ reportVisible: true, reportProgress: 0 });
    const findProject = cascadeProjects.getProject(projectId);
    let projectName = findProject ? findProject.name : "";

    let mime, fileName;
    const _fileName = `${projectName}_${timeFormat('YYYYMMDD_HHmmss')}`;

    const _config = reportConfig ? reportConfig : {
      verificationIds: [],
      language: 'english',
      templateId: "",
      format: "PPTX",
      fileName: ""
    };

    if (!_config.format || typeof _config.format !== "string") {
      _config.format = "PPTX";
    }
    if (_config.format.toUpperCase() === 'PPTX') {
      mime = 'application/vnd.ms-powerpoint';
      fileName = `${_fileName}.zip`;
    } else if (_config.format.toUpperCase() === 'PDF') {
      mime = 'application/pdf';
      fileName = `${_fileName}.zip`;
    }


    const config = {
      ..._config,
      fileName: _fileName
    };
    if (reportTypeName === CASCADE_SIGN_OFF) {
      fileName = `${_fileName}.zip`;
    }
    this.props._updateReportInfo({ reportConfig: _config });
    generateReport(projectId, { ...config }).then(res => {
      this.props._getReport({
        projectId,
        format: _config.format,
        mime,
        fileName,
        reportTypeName
      });
    }, error => {
      message.error(`Download report in ${config.format} format failed! ` + error)
      this.props._updateReportInfo({ reportVisible: false });
    })
    this.props._saveReportConfig(projectId, reportTypeName);
  }

  updateReportConfig = ({ templateId }) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.templateId = templateId;
    this.props._updateReportInfo({ reportConfig: config })

    if (this.state.downloadLoading) {
      this.setState({
        downloadLoading: false
      })
    }
  }

  updateReportMessage = (msg) => {
    this.props._updateReportInfo({ reportMessage: msg })
  }

  changeReferenceLineData = (data, isXTable) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.custom.guideValue = data;
    config.custom.isXTable = isXTable
    this.props._updateReportInfo({ reportConfig: config });
  }

  changeIsXTable = (data) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.custom.isXTable = data;
    this.props._updateReportInfo({ reportConfig: config });
  }

  typeChange = (key) => {
    this.props.changeReportConfig(key);
    this.getDefaultList(key);
    this.props._updateReportInfo({ reportTypeName: key })
  }

  render() {
    const { closeVisible, maxWidth, maxHeight, treeData, downloadLoading } = this.state;
    const { reportVisible, reportConfig, reportProgress, reportMessage, reportTypeName, designId } = this.props;
    const selectMode = reportTypeName === CASCADE_SIGN_OFF ? "single" : "multiple";
    const { custom, verificationIds, format = "PPTX" } = reportConfig || {};

    const content = (
      <Fragment>
        <Panel
          className='cascade-top-bar-report-panel'
          title={<div className='cascade-top-bar-report-title'>Report Generation Options</div>}
          onCancel={this.closeModalPanel}
          onMinimize={this.onMinimize}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { minWidth: 270, defaultWidth: 700 })}
          minWidth={270}
          position='panel-center'
          draggable
          mask={true}
          minHeight={200}
          minimizeVisible={reportVisible ? true : false}
          maxHeight={maxHeight}
          overflow={"auto"}
        >
          <div className='top-bar-report-content clear'>
            <div>
              <Select
                className="aurora-select cascade-report-title-select"
                placeholder="Please select"
                value={reportTypeName}
                onChange={this.typeChange}
                disabled={reportVisible}
                popupClassName='cascade-report-select-dropdown'
                size='small'
              >
                <Option key={CASCADE_SIGN_OFF} value={CASCADE_SIGN_OFF}>Template</Option>
                <Option key={CASCADE_IMPEDANCE} value={CASCADE_IMPEDANCE}>Z</Option>
              </Select>
              <Select
                mode={selectMode}
                className="cascade-report-select aurora-select"
                placeholder="Please select"
                value={selectMode === "single"
                  ? (verificationIds ? (verificationIds[0] || null) : null)
                  : (verificationIds || [])}
                onChange={this.onTreeChange}
                popupClassName='cascade-report-select-dropdown'
                size="small"
              >
                {treeData.map(item =>
                (item.children && item.children.length ?
                  <OptGroup key={item.key} label={item.title}>
                    {item.children.map(it => <Option
                      key={it.key}
                      value={it.value}
                      title={it.title}
                    >{it.title}</Option>)}
                  </OptGroup>
                  :
                  <Option
                    key={item.key}
                    value={item.value}
                  >
                    {item.title}
                  </Option>))}
              </Select>
            </div>
            <div>
              <label className="report-item-title">File Type</label>
              <Select
                value={format}
                onChange={(value) => this.changeReportType(value)}
                className="cascade-report-select aurora-select"
                popupClassName="cascade-report-select-dropdown"
                size="small"
              >
                <Option key="PPTX">PPTX</Option>
                <Option key="PDF">PDF</Option>
              </Select>
            </div>
            {this.ReportAdvance(custom, designId)}
            <ReportTemplate
              reportConfig={reportConfig}
              updateReportConfig={this.updateReportConfig}
              updateReoprtMessage={this.updateReportMessage}
              type={CASCADE}
            />
            {reportProgressRender({
              reportVisible,
              reportMessage,
              reportProgress,
              downloadReport: this.downloadReport,
              downloadLoading
            })}
          </div>
        </Panel>
        {
          closeVisible ? <DelConfirm
            data={null}
            maskStyle={true}
            deleteConfirm={this.deleteConfirm}
            cancelDel={this.cancelDel}
            message={'After closing the panel, the download report will end. Are you sure you want to close it?'}
          /> : null
        }
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }

  ReportAdvance = (custom, reportDesignId) => {
    const guideValue = custom && custom.guideValue ? custom.guideValue : [];
    const isXTable = custom ? custom.isXTable : false;
    const _vendor = (designsDB.getDesign(reportDesignId) || {}).vendor;
    return (<div>
      <ReportAdvance
        referenceLineData={guideValue}
        changeReferenceLineData={this.changeReferenceLineData}
        isXTable={isXTable}
        changeIsXTable={this.changeIsXTable}
        referenceLinesColumns={referenceLinesColumns}
        type={CASCADE}
        reportDesignId={reportDesignId}
        vendor={_vendor}
      />
    </div>)
  }
}

const reportState = (state) => {
  const { CascadeReducer: {
    project: {
      openProjectId,
      reportInfo = {},
      viewList
    } } } = state;
  const {
    reportConfig = {},
    reportMessage,
    reportVisible,
    reportProgress,
    reportTypeName,
    designId } = reportInfo;
  return {
    projectId: openProjectId,
    reportConfig,
    reportVisible,
    reportMessage,
    reportProgress,
    reportTypeName,
    reportInfo,
    designId,
    viewList
  }
}

const reportDispatch = (dispatch) => ({
  _updateReportInfo(reportInfo) {
    dispatch(updateReportInfo(reportInfo))
  },
  _clearReportInfo() {
    dispatch(clearReportInfo())
  },
  _saveReportConfig() {
    dispatch(saveReportConfig())
  },
  _getReport({ projectId, format, mime, fileName, reportTypeName }) {
    dispatch(getReport({ projectId, format, mime, fileName, reportTypeName }))
  }
})

export default connect(reportState, reportDispatch)(ReportPanel);