import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { updateServiceConfig, saveServiceOption } from '../store/project/action';
import ServiceOption from "../../../components/ServiceOptions";
import { getProjectConfig } from "../../../services/Himalayas";
import { HIMALAYAS } from "../../../constants/pageType";

class ServiceOptionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  closeModal = () => {
    this.props._saveServiceOption();
    this.props.closePanel();
  }

  render() {
    const { hspiceOption, openProjectId } = this.props;
    return <ServiceOption
      product={HIMALAYAS}
      hspiceOption={hspiceOption}
      projectId={openProjectId}
      closeModal={this.closeModal}
      getServiceOptionsConfig={getProjectConfig}
      _updateServiceConfig={this.props._updateServiceConfig}
    />
  }
}

const mapState = (state) => {
  const { HimalayasReducer: { project: { hspiceOption, openProjectId } } } = state;
  return {
    hspiceOption,
    openProjectId
  }
}

const mapDispatch = (dispatch) => ({
  _updateServiceConfig(hspiceOption, ansysOption) {
    dispatch(updateServiceConfig(hspiceOption, ansysOption))
  },
  _saveServiceOption() {
    dispatch(saveServiceOption())
  }
})

export default connect(mapState, mapDispatch)(ServiceOptionPanel);