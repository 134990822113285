import React, { PureComponent, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Input, Select } from 'antd';
import { Version } from '@/services/serdes/serdes';
import { updateConfig } from '../../store/actionCreators';
class ConfigSetting extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      version: null,
      // showInput: false,
      open: false,
      // showInput: false,
      // inputVersion: null,
      name: null,
      datarate: null,
    }

    this.datarateRef = createRef();
    this.nameRef = createRef();
    this.versionRef = createRef();
    this.lock = null;
  }

  componentDidMount() {
    let { Name, SerdesType } = this.props.config;
    let version;
    if (SerdesType.type) {
      version = Version[SerdesType.type];
    }
    this.setState({
      name: Name,
      datarate: SerdesType.datarate,
      version
    })
  }

  render() {

    const { name } = this.state;

    return (
      <Fragment>
        <Row className='serdes-config-row' type="flex" justify="space-around">
          <Col span={24}>
            <span className="font-bold">Name</span>
            <Input
              ref={this.nameRef}
              className='serdes-name-input'
              value={name}
              onChange={this.nameChange}
              onBlur={this.saveName}
              onPressEnter={this.inputNameKeyDown}
            />
          </Col>
          {/* <Col span={8}>
            <span className="font-bold">Version</span>
            {version ?
              <Select
                ref={this.versionRef}
                showSearch
                className='serdes-select-version'
                value={SerdesType.version}
                onChange={this.versionChange}
                filterOption
                open={open}
                onDropdownVisibleChange={this.onDropdownVisibleChange}
              // dropdownRender={menu => (
              //   <div>
              //     {menu}
              //     <Divider style={{ margin: '4px 0' }} />
              //     <div style={{ padding: '8px', cursor: 'pointer' }}
              //       onMouseDown={this.lockClose} onMouseUp={this.lockClose}
              //       onClick={this.showInput}
              //     >
              //       {showInput ?
              //         <Input
              //           onBlur={this.inputVersionBlur}
              //           value={inputVersion}
              //           onChange={this.inputVersionChange}
              //           onKeyDown={(e) => this.inputVersionKeyDown(e)}
              //         /> : <span><Icon type="plus" /> Version</span>}
              //     </div>
              //   </div>
              // )}
              >
                {version.map(ver => <Option key={ver.type}>{ver.type}</Option>)}
              </Select>
              : null}
          </Col>
          <Col span={8}>
            <span className="font-bold">Per-lane data rate</span>
            <Input
              ref={this.datarateRef}
              value={datarate ? datarate : SerdesType.datarate}
              onChange={this.dataRateChange}
              onBlur={this.saveDataRate}
              onPressEnter={this.inputDataRateKeyDown}
              className='serdes-datarate-input'
            />
          </Col> */}
        </Row>
      </Fragment>
    )
  }

  nameChange = (e) => {
    this.setState({
      name: e.target.value
    })
  }

  saveName = () => {
    this.props.updateConfig({ Name: this.state.name });
  }

  inputNameKeyDown = () => {
    this.saveName();
    this.nameRef.current.blur();
  }

  versionChange = (value) => {
    let { SerdesType } = this.props.config;
    SerdesType.version = value;
    const index = Version[SerdesType.type].map(ver => ver.type).indexOf(value);
    if (index > 0) {
      SerdesType.datarate = Version[SerdesType.type][index].speed
    }
    this.props.updateConfig({ SerdesType });
    this.versionRef.current.blur();
    this.setState({
      datarate: null
    })
  }

  // lockClose = e => {
  //   clearTimeout(this.lock);
  //   this.lock = setTimeout(() => {
  //     this.lock = null;
  //   }, 100);
  // }

  onDropdownVisibleChange = (open) => {
    if (this.lock) return;
    this.setState({ open });
  }

  dataRateChange = (e) => {
    this.setState({
      datarate: e.target.value
    })
  }

  saveDataRate = () => {
    let { SerdesType } = this.props.config;
    SerdesType.datarate = this.state.datarate;
    this.props.updateConfig({ SerdesType });
    this.setState({
      datarate: null
    })
  }

  inputDataRateKeyDown = () => {
    this.saveDataRate();
    this.datarateRef.current.blur();
  }
}

const mapState = (state) => {
  const { config } = state.explorerSerdes;
  return {
    config
  }
}

const mapDispatch = (dispatch) => ({
  updateConfig(obj) {
    dispatch(updateConfig(obj))
  }
})

export default connect(mapState, mapDispatch)(ConfigSetting);

