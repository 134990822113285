import CeLine from '../geometry/CeLine';
import { componentFilter } from '../helper/componentsHelper';
import { getComponents } from '../helper/setup/setupData';
import { getComponentsWithNetList } from '@/services/helper/setup/setupData'
import designConstructor from '../helper/designConstructor';
import { AEDB, SPD, CADENCE_BRD, AURORA_DB } from '../../constants/designVendor';
import { FASTPI } from "@/constants/pageType";
import { defaultGroundNets } from '../../constants/defaultGroundNets';

function deleteCompsConnectWithNets({ deletedComps, Components }) {
  for (let delComp of deletedComps) {
    const compIndex = Components.findIndex(
      (item) => item.name === delComp.name
    );
    if (compIndex < 0) continue;
    if (Components[compIndex].pins.length === 1) {
      // Delete component
      Components.splice(compIndex, 1);
    } else {
      const pinIndex = Components[compIndex].pins.findIndex(
        (item) => item.pin === delComp.pin && item.net === delComp.net
      );
      // Delete pin
      Components[compIndex].pins.splice(pinIndex, 1);
    }
  }
  return {
    Components,
  };
}

function checkExistNets(nets, netNamelist) {
  if (netNamelist.length > 0) {
    const checkNets = nets.filter((net) => netNamelist.includes(net));
    return checkNets;
  }
  return [];
}

function getPinList(netName, layout) {
  const netObj = layout.mNetManager.GetNetFromName(netName);
  if (!netObj) {
    return null;
  }
  return netObj.mPinList; // mLayerName, mCompName, mPinNum, mMetalLayerName
}

function getPowerReferenceNets(connections, { mVals }, chip) {
  // connectName - in dat: BGA_Connection_Name
  let pwrNets = [],
    gndNets = [],
    connectName = null;
  if (!mVals || !mVals.length) {
    return {
      pwrNets,
      gndNets,
      BGAConnectionName: connectName,
    };
  }
  const NetList = mVals.filter((net) =>
    net.mPinList.map((item) => item.mCompName).includes(chip)
  );
  const _connections = [...connections];
  try {
    _connections.sort((a, b) => {
      const _a = a.name.replace(/[^A-Za-z0-9]/g, '');
      const _b = b.name.replace(/[^A-Za-z0-9]/g, '');
      if (_a.toUpperCase() === 'PKGA1') {
        return -1;
      } else if (_b.toUpperCase() === 'PKGA1') {
        return 1;
      }
      return _a.match('PKG') && !_b.match('PKG') ? -1 : 1;
    })
  } catch (error) {
    console.error(error)
  }
  for (let connect of _connections) {
    // 2020/04/26 Remove filter: ""'connect.name.match(/U[0-9]+|OB/)' continue", PGA name can be arbitrary

    // pin number: letter + number, isNaN: not number
    const pwrs = connect.pwr_pins
      .map((item) => item.pin)
      .filter((pin) => isNaN(pin));
    const gnds = connect.gnd_pins
      .map((item) => item.pin)
      .filter((pin) => isNaN(pin));
    if (pwrs.length > 0 && gnds.length > 0) {
      const pwrPins = [...pwrs];
      const gndPins = [...gnds];

      const chipPins = NetList.map(net => net.mPinList.filter(pin => pin.mCompName === chip).map(pin => pin.mPinNum)).flat(2);
      const pwrIncludes = pwrPins.filter(pin => chipPins.includes(pin));
      const gndIncludes = gndPins.filter(pin => chipPins.includes(pin));
      if ((pwrIncludes.length < 10 && pwrPins.length !== pwrIncludes.length) || (gndIncludes.length < 10 && gndPins.length !== gndIncludes.length)) {
        continue;
      }

      if (pwrPins && pwrPins.length > 0) {
        const _NetList = NetList.filter(item => !defaultGroundNets.includes(item.mName.toUpperCase()))
        pwrNets = getNets(pwrPins, _NetList, chip);
      }
      if (gndPins && gndPins.length > 0) {
        gndNets = getNets(gndPins, NetList, chip);
      }

      if (pwrNets.length > 0 && gndNets.length > 0) {
        connectName = connect.name;
        return {
          pwrNets,
          gndNets,
          BGAConnectionName: connectName,
        };
      }
    }
  }
  return {
    pwrNets,
    gndNets,
    BGAConnectionName: connectName,
  };
}

function getNets(Pins, NetList, chip) {
  // Pins -  [pin], If pin is a number, continue
  // NetList - [{ mName, mPinList }]
  let nets = [];
  const pins = Pins;
  for (let pin of pins) {
    let findNet = false;
    for (let net of NetList) {
      if (net.mName === "NONET") {
        continue;
      }
      const pinFilter = net.mPinList.filter(({ mPinNum, mCompName }) =>
        isNaN(mPinNum)
      );
      if (!pinFilter.length) {
        continue;
      }
      for (let pinInfo of pinFilter) {
        if (pinInfo.mCompName === chip && pinInfo.mPinNum === pin) {
          if (!nets.includes(net.mName)) {
            nets.push(net.mName);
          }
          findNet = true;
          break;
        }
      }
      if (findNet) {
        break;
      }
    }
  }
  return nets;
}

function autoFindPowerGNDNets(netList) {
  const { mVals } = netList;
  let nets = [];
  let matchV = [],
    matchGeos = [],
    matchVias = [];
  for (let net of mVals) {
    if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
      continue;
    }
    if (net.mName.match(/^NONE/i)) {
      continue;
    }
    if (net.mName.match(/^(v|\+v)/i)) {
      matchV.push(net.mName);
      continue;
    }
    if (net.mGeomList.length > 0) {
      const geomList = net.mGeomList
        .map((geom) => geom.mRefGeom.mGeometry)
        .filter((item) => !!item);
      // Any geometry that is not CeLine, returns true
      const typeList = geomList.map((item) => item instanceof CeLine);
      if (typeList.includes(false)) {
        matchGeos.push(net.mName);
        continue;
      }
    }
    if (net.mViaList.length > 10) {
      matchVias.push(net.mName);
      continue;
    }
  }
  nets = [...matchV, ...matchGeos, ...matchVias];
  return nets;
}

function getPDNComponentsByNets({
  ReferenceNets,
  PowerNets,
  pcbId,
  COMP_PREFIX_LIB,
}) {
  const { Components, findVRMComps, findCaps } = getComponents(
    [...ReferenceNets, ...PowerNets],
    pcbId,
    COMP_PREFIX_LIB,
    FASTPI
  );
  // Components filter
  const new_Components = componentFilter({
    Components,
    ReferenceNets,
    PowerNets,
  });
  return { Components: new_Components, findVRMComps, findCaps };
}

// This VRM is automatically recognized, not added
/**
 *
 *
 * @param {*} vrms
 * @returns true - display, false - hidden
 */
function prefixCheckByVRM(vrms) {
  if (!vrms.length) return true;
  const groundPins = vrms.map((item) =>
    item.groundPin ? item.groundPin.length : 0
  );
  const powerPins = vrms.map((item) =>
    item.powerPin ? item.powerPin.length : 0
  );
  if (powerPins.some((len) => len === 0)) {
    return true;
  }
  if (groundPins.some((len) => len === 0)) {
    return true;
  }
  return false;
}

// Get the cap and VRM components connected to reference nets
function getReferenceComps({
  VRM_COMPS,
  findCaps,
  ReferenceNets,
  pcbId,
  COMP_PREFIX_LIB,
}) {
  let VRMComponents = [];
  let referenceComps = [...findCaps];
  if (VRM_COMPS.length) {
    const _Components = getComponentsWithNetList(
      [...ReferenceNets],
      pcbId,
      COMP_PREFIX_LIB
    );
    for (const compName of VRM_COMPS) {
      const VRM_Components = _Components.filter(
        (item) => item.name === compName
      );
      const pins = VRM_Components.map((it) => ({ pin: it.pin, net: it.net }));
      VRMComponents.push({ pins: pins, name: compName });
    }
    referenceComps.push(...VRMComponents);
  }
  return { referenceComps, VRMComponents };
}

function changeDecapModelToMulit(components) {
  if (!components || components.models) {
    return;
  }
  for (let comp of components) {
    if (comp.COMP_TYPE === "Cap") {
      const model = comp.model,
        value = comp.value;
      //todo model format update -> fn
      if (model && model.id && model.type && !comp.models) {

        model.type = typeFormatConversion(model.type, model.libraryType);
        //add model to models
        comp.models = [model];
      } else if (!comp.models && value && (value.r || value.l || value.c)) {
        comp.models = [];
      }
      delete comp.model;
      delete comp.value;
    }
  }
  return components;
}

function typeFormatConversion(type, libraryType) {
  switch (type) {
    case "decap_touchstone":
      return "touchstone";
    case "Decap":
      return libraryType === "file" ? "spice" : "rlc";
    default:
      return;
  }
}

function judgeDesignIsSpd(designId) {
  const design = designConstructor.getDesign(designId);
  if (design && design.vendor === SPD) {
    return true;
  }
  return false;
}

function getDefaultSolverByDesignVendor(designId) {
  const design = designConstructor.getDesign(designId);
  // PowerSI -> brd and spd support
  // SIwave -> brd, aedb, anf, ...(all pcb by ansys translation)
  // Aurora DB -> not support extraction, allow all solver

  if (design && design.vendor === SPD) {
    return "PowerSI";
  }

  if (design && [CADENCE_BRD, AURORA_DB].includes(design.vendor)) {
    return null;
  }
  return "SIwave";
}

function judgePowerSIDisabledByDesign(designId) {
  const design = designConstructor.getDesign(designId);
  //only brd and spd support PowerSI
  if (design && ![SPD, CADENCE_BRD, AURORA_DB].includes(design.vendor)) {
    return true;
  }
  return false;
}

// Compare the old and the new components to generate the new，change model and usage
function compareComponents(oldComponents, newComponents) {
  const finalComponents = []
  newComponents.forEach(newElement => {
    // Modify models and usage if the same name
    let foundElement = oldComponents.find(oldElement => oldElement.name === newElement.name);
    if (foundElement) {
      let newComp = {
        ...newElement,
        models: foundElement.models && foundElement.models.length > 0 ? [...foundElement.models] : foundElement.models,
        usage: foundElement.usage
      }
      finalComponents.push(newComp)
    } else {
      const samePart = oldComponents.find(oldElement => oldElement.part === newElement.part);
      // Modify models and user if the name is not the same but part the same
      if (samePart) {
        let newComp = {
          ...newElement,
          models: samePart.models && samePart.models.length > 0 ? [...samePart.models] : samePart.models,
        }
        finalComponents.push(newComp)
      } else {
        finalComponents.push(newElement)
      }
    }
  });
  return finalComponents
}

export {
  deleteCompsConnectWithNets,
  checkExistNets,
  getPowerReferenceNets,
  autoFindPowerGNDNets,
  getPDNComponentsByNets,
  prefixCheckByVRM,
  getReferenceComps,
  judgeDesignIsSpd,
  getDefaultSolverByDesignVendor,
  judgePowerSIDisabledByDesign,
  changeDecapModelToMulit,
  typeFormatConversion,
  compareComponents
};
