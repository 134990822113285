export {
  getBMAChannelListPromise,
  createBMAFilesPromise,
  getBMAFilesInfoPromise,
  createBMASpecPromise,
  getBMADataFromSelectFilePromise,
  getDataFromSelectFilesPromise,
  getBMAChannelInfoPromise,
  updateBMAChannelInfoPromise,
  getSpecContentPromise,
  updateSpecContentPromise,
  renameBMAFilePromise,
  createBMAFolderPromise,
  getBMALayerColorConfigPromise,
  updateBMALayerColorConfigPromise
} from './apiPromise/channel';

export {
  runBMAChannelInfoPromise,
  geSimulationLogPromise
} from './apiPromise/simulation';

export {
  getBMAResultJsonPromise,
  getBMAResultJsonFilePromise,
  downloadBMAResultPromise,
  deleteBMAResultPromise
} from './apiPromise/result'