import {
  UPDATE_SIMULATION_INFO,
  INIT_SIMULATION_INFO
} from './actionTypes';

const defaultState = {

}

export const HimalayasSimulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: state[action.verificationId] ? {
          ...state[action.verificationId],
          ...action.data
        } : { ...action.data }
      }
    case INIT_SIMULATION_INFO:
      return {
        ...state,
        [action.verificationId]: {}
      }
    default:
      return {
        ...state
      }
  }
}