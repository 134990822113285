import Panel from '@/components/Panel'
import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { EyeOutlined } from '@ant-design/icons';
import { Tooltip, Input, Collapse, Divider, Select } from 'antd';
import EditableTable from '@/components/EditableTable';
import { numberCheck } from '@/services/helper/dataProcess';
import MaskInput from '@/components/MaskInput';
import TagsInput from '@/components/TagsInput';
import designConstructor from '@/services/helper/designConstructor';
import endToEndChannelConstructor from '@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor'
import channelConstructor from "@/services/Andes_v2/channel/channelConstructor";
import './index.css';
import { SortFn } from '../../../../../services/helper/sort';
import { DriverPoints, getDefaultList, ReceiverPoints } from '../../../../../services/Andes_v2/constants.js';
import { PCIE, CPHY } from '../../../../../services/PCBHelper/constants';
import { getReportGuideKeys, getValue, INSERTIONLOSS, RETURNLOSS, COMRETURN, DIFFTOCOM, CROSSCOUPLING } from '../../../../../services/helper/reportHelper';

const { Option } = Select;
const Driver = "Driver", Receiver = "Receiver";
const referenceLinesColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    ellipsis: true
  },
  {
    title: 'Channel Spec (dB@GHz)',
    dataIndex: 'points',
    key: 'points',
    width: 170,
    ellipsis: true
  },
  {
    title: 'Driver Spec (dB@GHz)',
    dataIndex: DriverPoints,
    key: DriverPoints,
    width: 170,
    ellipsis: true
  },
  {
    title: 'Receiver Spec (dB@GHz)',
    dataIndex: ReceiverPoints,
    key: ReceiverPoints,
    width: 170,
    ellipsis: true,
  },
  {
    title: 'Magnitude (dB)',
    dataIndex: 'y',
    key: 'y',
    width: 100,
    ellipsis: true
  },
  {
    title: 'Frequency (GHz)',
    dataIndex: 'x',
    key: 'x',
    width: 100,
    ellipsis: true
  }
]

const defaultGuideKey = getReportGuideKeys('andes_v2_reference_line');
const defaultCPHYGuideKey = getReportGuideKeys('andes_v2_CPHY_reference_line');

class ResultReference extends Component {
  constructor(props) {
    super(props);
    const { reportConfig } = props;
    const { symbolRate = 2.5, fh, fMax, fINTMin = 0.45, fLPMax = 0.01, driver = [], receiver = [] } = reportConfig && reportConfig.custom ? reportConfig.custom : {};
    this.state = {
      tableData: [],
      error: '',
      currentChannel: null,
      errorMsg: {},
      updateMaskTypes: [],
      symbolRate,
      frequency: {
        fh: fh || symbolRate * 0.5,
        fMax: fMax || symbolRate * 0.75,
        fINTMin,
        fLPMax
      },
      driver,
      receiver
    }

    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    const { defaultTableData } = this.props;
    this.getColumns();
    this.selectChannel();
    this.setState({ tableData: defaultTableData })
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.id !== this.props.id) {
      this.selectChannel();
    }
    if (prevProps.projectId !== this.props.projectId) {
      //clear prev project report info
      this.props.clearReportInfo();
    }
  }

  selectChannel = () => {
    const { id, projectId, isEndToEnd, reportConfig } = this.props
    let findSelect = null, isMultiPCB = false;
    if (isEndToEnd) {
      findSelect = endToEndChannelConstructor.getEndToEndChannel(id);
      isMultiPCB = true;
    } else {
      findSelect = channelConstructor.getChannel(id);
    }

    const { currentChannel } = this.state;
    let _currentChannel = { ...currentChannel };

    if (findSelect) {
      _currentChannel = { id: findSelect.id, name: findSelect.name, design: findSelect.pacbName }
    } else {
      const designs = designConstructor.getDesignValues(projectId).map(item => { return { ...item, children: channelConstructor.getChannelValues(item.id) } });
      const design = designs.find(item => item.children && item.children.length);
      if (design && design.children && design.children[0]) {
        const findC = design.children[0];
        _currentChannel = { id: findC.id, name: findC.name, design: findC.pcbName }
      }
      isMultiPCB = false;
    }
    this.setState({
      currentChannel: _currentChannel
    }, () => {
      if (reportConfig) {
        let config = { ...reportConfig };
        config.isMultiPCB = isMultiPCB;
        this.props.updateReportInfo({ reportConfig: config });
      }
    })
  }

  getColumns = () => {
    const { updateMaskTypes, symbolRate, frequency } = this.state;
    referenceLinesColumns.forEach((column) => {
      if (column.key === 'name') {
        column.render = (value, record) => {
          const name = getValue(value)
          return <span>{name}</span>;
        }
      } else if (column.key === 'x') {
        column.render = (value, record) => {
          return this.InputValueRender(record, value ? value : [], 'x');
        }
      } else if (column.key === 'y') {
        column.render = (value, record) => {
          return this.InputValueRender(record, value ? value : [], 'y');
        }
      } else if (column.key === 'points') {
        column.render = (value, record) => {
          return (
            <MaskInput
              maskList={value}
              typeName={getValue(record.name)}
              defaultDataRate={record.defaultDataRate && record.defaultDataRate.match(/[-+]?\d+(?:\.\d+)?/g)[0]}
              dataRate={record.dataRate}
              width='100%'
              type={record.name}
              PCIeType={record.PCIeType}
              changeMaskList={(params) => this.changeList({ inputType: 'points', ...params })}
              symbolRate={symbolRate}
              frequency={frequency}
              cphyInsertionLossSpec={record.cphyInsertionLossSpec || 'Short'}
              updateMaskTypes={updateMaskTypes}
              changeUpdateMaskTypes={this.changeUpdateMaskTypes}
            />
          );
        }
      } else if (column.key === DriverPoints || column.key === ReceiverPoints) {
        column.render = (value, record) => {
          return <MaskInput
            maskList={value}
            typeName={getValue(record.name)}
            width='100%'
            type={record.name}
            PCIeType={record.PCIeType}
            changeMaskList={(params) => this.changeList({ inputType: column.key, ...params })}
            symbolRate={symbolRate}
            frequency={frequency}
            pointType={column.key}
            updateMaskTypes={updateMaskTypes}
            changeUpdateMaskTypes={this.changeUpdateMaskTypes}
          />
        }
      }
    })
  }

  InputValueRender = (record, value, inputType) => {
    return <TagsInput
      tagList={value}
      className="result-reference-line-tags"
      type={`${record.name}_${inputType}`}
      allowedDuplicates={true}
      width='100%'
      changeTagList={(type, list, focus) => this.changeList({ list, inputType, type: record.name })}
      displayInput={true}
      tagInputBlur={(type) => this.tagInputBlur(inputType, record.name)}
    />
  }

  changeList = ({ list, inputType, type, dataRate, isChangeDataRate = false, cphyInsertionLossSpec, isChangeCPHYInsertionLossSpec = false }) => {
    if (isChangeDataRate) {
      this.changeDataRate(dataRate);
      return;
    }

    if (isChangeCPHYInsertionLossSpec) {
      this.changeCPHYInsertionLossSpec(type, list, cphyInsertionLossSpec);
      return;
    }

    let error = '', _list = [];
    if (['points', DriverPoints, ReceiverPoints].includes(inputType)) _list.push(dataRate)
    list.forEach(item => {
      if (['points', DriverPoints, ReceiverPoints].includes(inputType)) {
        const data = item
        if (data) {
          _list.push(data)
        }
      } else if (!numberCheck(item)) {
        const numberData = Number(item);
        if ((inputType === 'x' && numberData < 0) || (inputType === 'y' && numberData > 0)) {
          error = 'Data needs to be positive.';
        } else {
          const data = (parseFloat(numberData.toFixed(2))).toString()
          if (data) {
            _list.push(data)
          }
        }
      } else {
        error = 'The value entered must be a number.'
      }
    })

    _list = [...new Set(_list)];

    if (!error) {
      if (['points', DriverPoints, ReceiverPoints].includes(inputType) || _list.length < 6) {
        this.changeResultData(inputType, type, _list)
      } else {
        error = 'Input data cannot exceed five.'
      }
    }
    if (error) {
      this.setState({
        error
      })
      setTimeout(() => {
        this.setState({
          error: null
        })
      }, 3000)
    }
  }

  changeDataRate = (dataRate) => {
    let _tableData = [...this.state.tableData];
    _tableData.forEach(item => {
      if (item.PCIeType === PCIE && (item.name === INSERTIONLOSS || item.name === RETURNLOSS || item.name === COMRETURN)) {
        const defaultList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, dataRate });
        item.points = [...defaultList];
      }
      item.dataRate = dataRate;
    })

    this.setState({
      tableData: _tableData
    })
  }

  changeCPHYInsertionLossSpec = (type, list, cphyInsertionLossSpec) => {
    let _tableData = [...this.state.tableData];
    const index = _tableData.findIndex(item => item.name === type);
    if (index > -1) {
      _tableData[index].cphyInsertionLossSpec = cphyInsertionLossSpec;
      _tableData[index].points = list;

      this.setState({
        tableData: _tableData
      })
    }
  }

  tagInputBlur = (inputType, type) => {
    const { reportConfig = {} } = this.props;
    const { custom } = reportConfig;
    const guideValue = custom && custom.guideValue ? custom.guideValue : [];
    const _guideValue = [...guideValue]
    const list = _guideValue.find(item => item.name === type)[inputType]
    if (list.length) {
      const errors = list.filter(item => !!numberCheck(item))
      if (errors && errors.length) {
        const _list = list.filter(item => !numberCheck(item))
        this.changeResultData(inputType, type, _list)
      }
    }
  }

  changeResultData = (inputType, type, list) => {
    const { reportConfig = {} } = this.props;
    const { custom } = reportConfig;
    const isXTable = custom ? custom.isXTable : false;
    let _isXTable = isXTable;
    let _tableData = [...this.state.tableData], disabled = true;
    _tableData.forEach(item => {
      if (item.name === type) {
        if (['points', DriverPoints, ReceiverPoints].includes(inputType)) {
          item.dataRate = list[0];
          list.shift();
        }
        item[inputType] = list
      }
      if (item.x.length) {
        disabled = false
      }
    })

    if (disabled && _isXTable) {
      _isXTable = false
    }
    this.setState({
      tableData: _tableData,
    })
    this.changeReferenceLineData(_tableData, _isXTable)
    this.props.crossReferenceLine(_tableData, inputType);
  }

  closePanel = () => {
    const { projectId } = this.props;
    const { currentChannel, errorMsg } = this.state;
    if (errorMsg && Object.keys(errorMsg)) {
      for (const key of Object.keys(errorMsg)) {
        if (errorMsg[key]) return;
      }
    }
    const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
    let isMultiPCB = false;
    if (endToEndList.find(item => item.id === currentChannel.id)) {
      isMultiPCB = true;
    }
    if (!currentChannel || !currentChannel.id) { return }
    this.props.saveReportConfig(currentChannel.id, isMultiPCB);
    this.props.closePanel();
  }

  changeReferenceLineData = (data, isXTable) => {
    const { symbolRate, frequency } = this.state;
    const { reportConfig, interfaceType } = this.props;
    let config = { ...reportConfig };
    const _defaultGuideKey = interfaceType === CPHY ? defaultCPHYGuideKey : defaultGuideKey
    if (config && config.custom) {
      config.custom.guideValue = config.custom.guideValue.map(item => {
        if (_defaultGuideKey.indexOf(item.name) !== -1) {
          return data[_defaultGuideKey.indexOf(item.name)]
        } else {
          return item
        }
      })
      config.custom.isXTable = isXTable;
      if (interfaceType === CPHY) {
        config.custom.symbolRate = symbolRate;
        for (const key of Object.keys(frequency)) {
          config.custom[key] = frequency[key];
        }
      }
    }
    this.props.updateReportInfo({ reportConfig: config });
  }

  titleRender = (tableData) => {
    const { isShowReferenceLine, changeIsShowReferenceLine } = this.props;
    return (
      <div className='result-reference-header'>
        <span>
          <span className='result-reference-title'>Reference Lines</span>
          <Tooltip title="Display line and mask" overlayClassName='aurora-tooltip'>
            <EyeOutlined
              className='result-reference-icon'
              style={{ color: isShowReferenceLine ? '#1890ff' : undefined }}
              onClick={() => changeIsShowReferenceLine(tableData)} />
          </Tooltip>
        </span>
      </div>
    );
  }

  changeUpdateMaskTypes = (updateMaskTypes) => {
    this.setState({ updateMaskTypes })
  }

  changeConfigs = (changeType, pointType = "points") => {
    const { symbolRate, frequency, tableData, driver, receiver } = this.state;
    const { reportConfig } = this.props;

    let _tableData = [...tableData];
    let pointTypes = [pointType];
    _tableData.forEach(item => {
      if ((['fh', 'symbolRate'].includes(changeType) && item.name === COMRETURN) || (['fMax', 'symbolRate'].includes(changeType) && [CROSSCOUPLING, DIFFTOCOM].includes(item.name)) || (changeType === 'fLPMax' && item.name === CROSSCOUPLING)) {
        const defaultList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, symbolRate, cphyInsertionLossSpec: item.cphyInsertionLossSpec, ...frequency });
        item.points = [...defaultList];
      }
      if (['fh', 'fMax', 'fLPMax', 'symbolRate'].includes(changeType) && item.name === RETURNLOSS) {
        const defaultDriverList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, symbolRate, pointType: 'driverPoints', ...frequency });
        item.driverPoints = [...defaultDriverList];
        !pointTypes.includes(DriverPoints) && pointTypes.push(DriverPoints);
      }
      if (
        (['fh', 'symbolRate'].includes(changeType) && item.name === RETURNLOSS)
        || (['fMax', 'symbolRate'].includes(changeType) && [RETURNLOSS, COMRETURN, DIFFTOCOM].includes(item.name))
        || (changeType === 'fINTMin' && item.name === COMRETURN)
        || (changeType === 'fLPMax' && item.name === RETURNLOSS)) {
        const defaultReceiverList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, symbolRate, pointType: 'receiverPoints', ...frequency });
        item.receiverPoints = [...defaultReceiverList];
        !pointTypes.includes(ReceiverPoints) && pointTypes.push(ReceiverPoints);
      }
    })
    this.setState({ tableData: _tableData, updateMaskTypes: [RETURNLOSS, COMRETURN, DIFFTOCOM, CROSSCOUPLING] });

    let config = { ...reportConfig };
    if (config && config.custom) {
      config.custom.symbolRate = Number(symbolRate);
      config.custom.driver = driver;
      config.custom.receiver = receiver;
      for (const key of Object.keys(frequency)) {
        config.custom[key] = Number(frequency[key]);
      }
    }
    this.props.updateReportInfo({ reportConfig: config });
    this.props.crossReferenceLine(_tableData, pointType === 'points' && pointTypes.length > 1 ? pointTypes : pointType);
  }

  changeSymbolRate = (value) => {
    const { frequency, errorMsg } = this.state;
    // 0.08~6Gpsp
    if (!!numberCheck(value) || value < 0.08 || value > 6) {
      this.setState({ errorMsg: { ...errorMsg, symbolRate: 'Please enter 0.08 ~ 6.' }, symbolRate: value })
      return;
    } else {
      // reset fMax，fh
      this.setState({
        errorMsg: { ...errorMsg, fh: null, fMax: null, symbolRate: null },
        symbolRate: value,
        frequency: {
          ...frequency,
          fh: parseFloat((value * 0.5).toFixed(3)),
          fMax: parseFloat((value * 0.75).toFixed(3))
        }
      }, () => {
        this.changeConfigs('symbolRate');
      })
    }
  }

  changeFrequency = (value, type) => {
    const { frequency, errorMsg } = this.state;
    const error = numberCheck(value, true);
    if (error) {
      this.setState({ errorMsg: { ...errorMsg, [type]: error }, frequency: { ...frequency, [type]: value } })
      return;
    } else {
      this.setState({ errorMsg: { ...errorMsg, [type]: null }, frequency: { ...frequency, [type]: value } }, () => {
        this.changeConfigs(type);
      })
    }
  }

  getFrequencyLabel = (name) => {
    let prefix = "", subscript = "";
    switch (name) {
      case 'fh':
        prefix = "fh";
        break;
      case 'fMax':
        prefix = "f";
        subscript = "Max"
        break;
      case 'fINTMin':
        prefix = "f";
        subscript = "INT,MIN"
        break;
      case 'fLPMax':
        prefix = "f";
        subscript = "LP,MAX"
        break;
      default:
        break;
    }
    return <Fragment>
      <span className='result-reference-frequency-title-prefix'>{prefix}</span>
      <span className='result-reference-frequency-title-subscript'>{subscript}</span>
    </Fragment>
  }

  changeDriverOrReceiver = (value, type) => {
    const key = type === Driver ? 'driver' : 'receiver';
    this.setState({ [key]: value }, () => {
      this.changeConfigs(type, type === Driver ? DriverPoints : ReceiverPoints);
    })
  }

  CPHYConfigRender = () => {
    const { symbolRate, frequency, errorMsg, driver, receiver } = this.state;
    const { components } = this.props;
    return <Fragment>
      <div className='result-reference-input-box'>
        <label className='result-reference-label'>Symbol Rate</label>
        <Tooltip
          className='aurora-error-tooltip'
          title={errorMsg.symbolRate ? errorMsg.symbolRate : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={errorMsg.symbolRate ? true : false}>
          <Input size='small' value={symbolRate} addonAfter="Gsps" onChange={(e) => this.changeSymbolRate(e.target.value)} className='result-reference-flex-item-select' />
        </Tooltip>
      </div>
      <div className='result-reference-select-box'>
        {
          [Driver, Receiver].map((item, index) => {
            return <div className='result-reference-flex-item' style={index % 2 === 0 ? { marginRight: '20px' } : { marginLeft: '20px' }} key={item}>
              <label className='result-reference-label'>{item}</label>
              <Select
                size='small'
                mode="multiple"
                className='result-reference-flex-item-select'
                dropdownStyle={{ zIndex: 100000 }}
                value={item === Driver ? driver : receiver}
                onChange={(value) => this.changeDriverOrReceiver(value, item)}
              >
                {components.map(comp => (
                  <Option
                    key={comp.name}
                    value={comp.name}
                    disabled={item === Driver ? receiver.includes(comp.name) : driver.includes(comp.name)}
                  >
                    {comp.name}
                  </Option>
                ))}
              </Select>
            </div>
          })
        }
      </div>
      <Collapse
        className='result-reference-collapse'
        items={[{
          label: "Frequency",
          key: "Frequency",
          children: [['fh', 'fMax'], ['fINTMin', 'fLPMax']].map((list, i) => {
            return <div key={i} style={i === 0 ? { marginBottom: '10px', display: 'flex' } : { display: 'flex' }}>
              {
                list.map((item, index) => <div className='result-reference-flex-item' style={index % 2 === 0 ? { marginRight: '20px' } : { marginLeft: '20px' }} key={item}>
                  <label className="result-reference-frequency-title">{this.getFrequencyLabel(item)}</label>
                  <Tooltip
                    className='aurora-error-tooltip'
                    title={errorMsg[item] ? errorMsg[item] : null}
                    overlayClassName='aurora-error-msg-tooltip'
                    open={errorMsg[item] ? true : false}>
                    <Input size='small' className='result-reference-frequency-flex-item-input' value={frequency[item]} addonAfter="GHz" onChange={(e) => this.changeFrequency(e.target.value, item)} />
                  </Tooltip>
                </div>)
              }
            </div>
          })
        }]}
      />
      <Divider className='result-reference-divider' />
    </Fragment>
  }

  render() {
    const { tableData, error } = this.state;
    const { interfaceType } = this.props;
    let width = 800, key = defaultGuideKey;
    if (interfaceType === CPHY) {
      width = 1200;
      // columns = columns.concat(CPHYOtherColumns);
      key = defaultCPHYGuideKey;
    }
    const sortedTableData = SortFn(tableData, key, 'name');
    const content = (<Panel
      className='result-reference-panel'
      title={this.titleRender(tableData)}
      width={width}
      draggable
      minHeight={500}
      zIndex={3000}
      position='panel-center'
      onCancel={() => { this.closePanel() }}
    >
      {interfaceType === CPHY && this.CPHYConfigRender()}
      <EditableTable
        rowKey="name"
        columns={interfaceType === CPHY ? referenceLinesColumns : referenceLinesColumns.filter(item => ![DriverPoints, ReceiverPoints].includes(item.key))}
        dataSource={sortedTableData}
        size='small'
        className='result-reference-table'
      />
      {error ? <span className={"aurora-error-msg-span"}>{error}</span> : null}
    </Panel>)
    return createPortal(content, this.dialogRoot);
  }
}

export default ResultReference;