import { _getIndex, yAxisValueFormat } from "../../Result/Public/sparameter/dataHelper";
import { db, radiansToDegrees } from "../../helper/sparameter/utility";
import { strDelimited } from "../../helper/split";

export function sparameterShowCurves({ parameters, curves, param, value, sweep, files }) {
  return new Promise((resolve, reject) => {
    parameters.showCurves({ curves, param, value, sweep }).then(resCurves => {
      if (resCurves) {
        resCurves.forEach(cur => {
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          files[_fileIndex].matrix[cur.row][cur.col][cur.type] = cur.y;
          cur.visible = true;
        })
        resolve(resCurves);
      };
      resolve([])
    },
      error => {
        resolve([])
        console.error(error)
      });
  })
}

export function getVCCompsPCB(Interfaces) {
  let hasVcPCBs = [], hideIL = false;
  for (let item of Interfaces || []) {
    if (item.content && item.content.virtualComps && item.content.virtualComps.length) {
      hasVcPCBs.push(item.pcb);
    }
  }
  if (Interfaces && hasVcPCBs.length === Interfaces.length) {
    hideIL = true;
  }
  return { hasVcPCBs, hideIL };
}

function parseEndToEndResult(endToEndList) {
  if (!endToEndList || !endToEndList.length) {
    return null
  }
  let results = {};
  for (let itemData of endToEndList) {
    /* itemData:{
        "driver": {component,pin, pcb, pcbId},
        "receiver": {component,pin, pcb, pcbId},
        "signal":"signalName"
       } 
       */
    const pcbTitle = `${itemData.driver.pcb}@${itemData.receiver.pcb}`;
    if (!results[pcbTitle]) {
      results[pcbTitle] = {}
    }
    if (!results[pcbTitle][itemData.signal]) {
      results[pcbTitle][itemData.signal] = []
    }
    const driver = `${itemData.driver.component}_${itemData.driver.pin}`,
      receiver = `${itemData.receiver.component}_${itemData.receiver.pin}`;

    const driverPcb = itemData.driver.pcbId || itemData.driver.pcb,
      receiverPcb = itemData.receiver.pcbId || itemData.receiver.pcb;

    let signalInfo = {
      RL: [],
      IL: [],
      ports: [],
      driverComp: itemData.driver.component,
      receiverComp: itemData.receiver.component
    };
    signalInfo.RL.push({
      title: "S(1,1)",
      driver: driver,
      receiver: driver,
      row: 0,
      col: 0,
      folderName: `${driverPcb}_${driver}_${receiverPcb}_${receiver}`,
      PCB1: itemData.driver.pcb,
      PCB2: itemData.driver.pcb,
      pcb1Id: itemData.driver.pcbId,
      pcb2Id: itemData.driver.pcbId
    },
      {
        title: "S(2,2)",
        driver: receiver,
        receiver: receiver,
        row: 1,
        col: 1,
        folderName: `${driverPcb}_${driver}_${receiverPcb}_${receiver}`,
        PCB1: itemData.receiver.pcb,
        PCB2: itemData.receiver.pcb,
        pcb1Id: itemData.receiver.pcbId,
        pcb2Id: itemData.receiver.pcbId
      });
    signalInfo.IL.push({
      title: "S(1,2)",
      driver: driver,
      receiver: receiver,
      row: 0,
      col: 1,
      folderName: `${driverPcb}_${driver}_${receiverPcb}_${receiver}`,
      PCB1: itemData.driver.pcb,
      PCB2: itemData.receiver.pcb,
      pcb1Id: itemData.driver.pcbId,
      pcb2Id: itemData.receiver.pcbId
    });
    signalInfo.ports.push(
      {
        index: 1,
        name: driver,
        comp: itemData.driver.component,
        pin: itemData.driver.pin,
        pcb: itemData.driver.pcb,
        pcbId: itemData.driver.pcbId,
        signal: itemData.signal
      }, {
      index: 2,
      name: receiver,
      comp: itemData.receiver.component,
      pin: itemData.receiver.pin,
      pcb: itemData.receiver.pcb,
      pcbId: itemData.receiver.pcbId,
      signal: itemData.signal
    })
    results[pcbTitle][itemData.signal].push(signalInfo)
  }
  const resultList = Object.keys(results).map(key => {
    const [PCB1, PCB2] = key.split("@");
    return {
      PCB1,
      PCB2,
      rowName: key,
      isSinglePCB: PCB1 === PCB2,
      signals: [...Object.keys(results[key] || {}).map(signal => {
        let index = 0;
        let sigList = [];
        const signals = results[key][signal] || [];
        for (let sigItem of signals) {
          const obj = {
            signal,
            fileName: `${key}@${signal}@${index}`,
            id: `end-to-end@${key}@${signal}@${index}`,
            type: "end-to-end",
            RL: sigItem.RL,
            IL: sigItem.IL,
            ports: sigItem.ports,
            driverComp: sigItem.driverComp,
            receiverComp: sigItem.receiverComp,
            isShowComp: signals.length > 1
          }
          index++;
          sigList.push(obj)
        }
        return sigList;
      }).flat(2)]
    }
  });
  return resultList;
}

function setEndToEndParameters({
  endToEndList,
  Parameters,
  verificationId,
  verificationSubId,
  projectId,
  files }) {
  let index = 0;
  const freq = getEndToEndResultFrequencies(files);
  for (let endToEnd of endToEndList || []) {
    let signalIndex = 0;
    for (let signal of endToEnd.signals || []) {
      Parameters.setParametersInfo({
        param: {
          fileName: signal.fileName,
          name: signal.id,
          id: signal.id,
          verificationId,
          verificationSubId,
          projectId,
          type: signal.type,
          ports: signal.ports
        },
        groupName: signal.type
      });
      const parameter = Parameters.getParameter(signal.id);
      parameter.initMatrix(index + signalIndex);
      parameter.setFreq(freq);
      const matrix = parameter.matrix || [];
      signal.RL.forEach(item => {
        item.color = matrix[item.row] && matrix[item.row][item.col] ? matrix[item.row][item.col].color : ""
      })
      signal.IL.forEach(item => {
        item.color = matrix[item.row] && matrix[item.row][item.col] ? matrix[item.row][item.col].color : ""
      })
      files.push(parameter)
      signal.Parameter = parameter;
      signalIndex++;
    }
    index++;
  }
  return { endToEndList, files };
}

function getEndToEndResultFrequencies(files) {
  const file = files.find(item => item.type === "simulation");
  if (file && file.freq) {
    return [...file.freq]
  }
  return []
}

function getEndToEndMouseMoveYAxisValue({ displayMode, selectArr, setting, endToEndList, files, x, yScale }) {
  if (!["RL", "IL"].includes(displayMode)) {
    return endToEndList
  }
  const selectKeys = selectArr.map(item => {
    let [id, row, col] = strDelimited(item, "::");
    const [curveType, pcb1, pcb2, signal] = strDelimited(id, "@");
    return {
      id, curveType, pcb1, pcb2, signal, row, col, rowName: `${pcb1}@${pcb2}`
    }
  })
  let indexObj = {};
  const { parameter, value } = setting;
  for (let item of endToEndList) {
    for (let signal of item.signals) {
      if (!signal[displayMode] || !signal[displayMode].length) {
        continue;
      }
      let fileIndex = files.findIndex(item => item.id === signal.id);
      if (!indexObj[fileIndex] && files[fileIndex]) {
        indexObj[fileIndex] = _getIndex(files[fileIndex].freq, x);
      }

      signal[displayMode].forEach(it => {
        const findSignalKey = selectKeys.find(_item => _item.id === signal.id && it.row === Number(_item.row) && it.col === Number(_item.col));
        if (findSignalKey) {
          const fileData = files[fileIndex].matrix[it.row][it.col][parameter + value];
          if (fileData) {
            let yAxisValue = fileData[indexObj[fileIndex]]
            if (value === 'db') {
              yAxisValue = db(yAxisValue);
            }
            if (yScale === 'degrees') {
              yAxisValue = radiansToDegrees(yAxisValue);
            }
            it.value = yAxisValueFormat(yAxisValue);
          } else {
            it.value = '';
          }
        } else {
          it.value = ""
        }
      })
    }
  }
  return endToEndList;
}

function cancelEndToEndMove({ displayMode, endToEndList }) {
  if (!["RL", "IL"].includes(displayMode)) {
    return endToEndList
  }
  for (let item of endToEndList) {
    for (let signal of item.signals) {
      if (!signal[displayMode] || !signal[displayMode].length) {
        continue;
      }

      signal[displayMode].forEach(it => {
        it.value = ""
      })
    }
  }
  return endToEndList;
}

function getSweepMouseMoveYAxisValue({ resultsObj, displayMode, selectArr, files, x, setting, yScale }) {
  let indexObj = {};
  const { parameter, value } = setting;
  let _resultsObj = { ...resultsObj };
  let list = _resultsObj && _resultsObj[displayMode] && _resultsObj[displayMode].list ? _resultsObj[displayMode].list : [];
  for (let group of list) {
    for (let child of group.children) {
      const [_name, file] = strDelimited(child.id, "@");
      const [fileId, row, col] = strDelimited(file, "::");

      if (!child.currentValue) {
        child.currentValue = {}
      }
      for (let groupName of group.groupName) {
        const _id = `${groupName}@${file}`;
        if (selectArr.includes(_id)) {
          let fileIndex = files.findIndex(item => item.id === `${groupName}@${fileId}`);
          if (!indexObj[fileIndex] && files[fileIndex]) {
            indexObj[fileIndex] = _getIndex(files[fileIndex].freq, x);
          }
          const fileData = files[fileIndex].matrix[row][col][parameter + value];
          if (fileData) {
            let yAxisValue = fileData[indexObj[fileIndex]]
            if (value === 'db') {
              yAxisValue = db(yAxisValue);
            }
            if (yScale === 'degrees') {
              yAxisValue = radiansToDegrees(yAxisValue);
            }
            child.currentValue[groupName] = yAxisValueFormat(yAxisValue);
          } else {
            child.currentValue[groupName] = '';
          }
        } else {
          child.currentValue[groupName] = '';
        }
      }

    }
  }
  return _resultsObj;
}

function cancelSweepMove({ resultsObj, displayMode }) {
  let _resultsObj = { ...resultsObj };
  if (!_resultsObj || !_resultsObj[displayMode] || !_resultsObj[displayMode].list) {
    return _resultsObj;
  }
  for (let group of _resultsObj[displayMode].list) {
    for (let child of group.children) {
      child.currentValue = {}
    }
  }
  return _resultsObj;
}

export {
  parseEndToEndResult,
  setEndToEndParameters,
  getEndToEndMouseMoveYAxisValue,
  cancelEndToEndMove,
  getSweepMouseMoveYAxisValue,
  cancelSweepMove
}