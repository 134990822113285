function getVRM(vrm, model = '') {
  if (Array.isArray(vrm)) {
    let data = [];
    vrm.forEach((item, index) => {
      const powerPin = item.powerPin ? item.powerPin.map(pin => pin.comp) : [];
      const groundPin = item.groundPin ? item.groundPin.map(pin => pin.comp) : [];
      const itemData = {
        powerPin: powerPin,
        groundPin: groundPin,
        vrm: item.model ? item.model.name : '',
        index: index + 1,
        VRM_COMP: (Array.isArray(item.VRM_COMP) ? item.VRM_COMP.join(', ') : item.VRM_COMP) || null
      };
      if (model) { itemData['calc'] = model }
      data.push(itemData);
    });
    return data;
  } else {
    const powerPin = vrm.powerPin ? vrm.powerPin.map(item => item.comp) : [];
    const groundPin = vrm.groundPin ? vrm.groundPin.map(item => item.comp) : [];
    const data = [{
      powerPin: powerPin,
      groundPin: groundPin,
      vrm: vrm.model ? vrm.model.name : '',
      VRM_COMP: null
    }];
    if (model) { data['calc'] = model }
    return data;
  }
};

export { getVRM };