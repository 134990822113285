import http from './http';
import axios from 'axios';
import JSZipUtils from 'jszip-utils';
import apiProcess from './utility';

export default function getDesignFile(id, path) {
  return http.get(`/designs/${id}/file?path=${path}`);
}

export function uploadStackup(id, file) {
  const formData = new FormData();
  formData.append('file', file, 'stackup.dat');
  return http.post(`/explorer/upload/stackup/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function XLSXTOCSV(file) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return http.post(`/stackup/csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getLocalFile(path) {
  return axios.get(path);
}

/**
 * All interface/pdns use current pcb in a project need to do re-extraction (SIERRA,FASTPI)
 *  2020/03/03
 * 
 * @export
 * @param {string} designId designId
 * @returns Promise
 */
export function ReExtractionByDesign(designId) {
  return http.post(`/my-project/project/design/${designId}/reExtraction`);
}

/**
 * All interface use current pcb in a project need to do re-extraction (ROCKY,ANDES_V2)
 *  2021/09/24
 * 
 * @export
 * @param {string} designId designId
 * @returns Promise
 */
export function ReExtractionByDesignNew(product, designId) {
  return http.post(`/my-project/${product}/design/reExtraction?designId=${designId}`);
}

/**
 * Get AuroraDB zip file 
 * 
 * @export
 * @param {*} designId design id
 * @param {String} path - layers|parts|layout
 * @returns
 */
export function getAuroraDBZipFile(designId, path) {
  const token = localStorage.getItem('token');
  return JSZipUtils.getBinaryContent(`api/v3/design/${designId}/zipFile?path=${path}&access_token=${token}`)
}

/**
 * get design stackup json
 * 2021.06.17
 * @param {string} designId 
 *  */
function getStackupJson(designId) {
  return http.get(`/stackup/detail?designId=${designId}`);
}

/**
 * edit design stackup
 * 2021.06.17
 * @param {string} designId 
 * @param {Object} stackup
 *  */
function editStackupJson({ designId, stackup }) {
  return http.post(`/stackup/edit`, {
    designId,
    stackup
  });
}

/**
 * upload stackup file (.json,.dat,.csv)
 * 2021.06.23
 * @param {file} file 
 * @param {string} fileName
 *  */
function _parseStackupFile({ file, fileName }) {
  const formData = new FormData();
  formData.append('file', file, fileName);
  return http.post(`/stackup/parse`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * get csv stackup file by stackup json
 * 2021.08.18
 * @param {Object} stackupData 
 * @exports
 *  */
function _getStackupCSVFile(stackupData) {
  return http.post(`/stackup/csv/export`, {
    ...stackupData
  })
}

/**
 * get design stackup json
 * 2021.06.17
 * @param {string} designId 
 * @exports
 *  */
export function getDesignStackupJson(designId) {
  return apiProcess(getStackupJson, designId);
}

/**
 * edit design stackup
 * 2021.06.17
 * @param {string} designId 
 * @param {Object} stackup
 *  */
export function editDesignStackup({ designId, stackup }) {
  return apiProcess(editStackupJson, { designId, stackup });
}

/**
 * upload stackup file (.json,.dat,.csv)
 * 2021.06.23
 * @param {file} file 
 * @param {string} fileName
 * @exports
 *  */
export function parseStackupFile({ file, fileName }) {
  return apiProcess(_parseStackupFile, { file, fileName });
}


/**
 * get csv stackup file by stackup json
 * 2021.08.18
 * @param {Object} stackupData 
 * @exports
 *  */
export function getStackupCSVFile(stackupData) {
  return new Promise((resolve, reject) => {
    _getStackupCSVFile(stackupData).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.log(error);
      resolve(null)
    })
  })
}

export function getDesignLayers({ designId, layers }) {
  const token = localStorage.getItem('token');
  return JSZipUtils.getBinaryContent(`api/v3/my-project/design/layers/files?designId=${designId}&layers=${layers.join(',')}&access_token=${token}`)
}

export function getStackupFile({ fileType, stackupData }) {
  return http.post(`/stackup/${fileType}/export`, { ...stackupData });
}

export function getStackupFileByType({ fileType, stackupData }) {
  return apiProcess(getStackupFile, { fileType, stackupData },false,false,true);
}