import {
  CHANGE_VIEW, SAVE_OPEN_PROJECT_INFO,
  PROJECT_MENU, CHANGE_LAYOUT, CLEAR_CURRENT_PROJECT,
  TRASH_MENU, CHANGE_VIEW_LIST,
  UPDATE_PCBS_LAYOUTDB,
  CHANGE_TREE_SELECTED_KEY,
  UPDATE_VIEW_STATUS,
  REFRESH_PCB,
  UPDATE_CURRENT_VERIFICATION_STATUS
} from './actionTypes';
import {
  PROJECTS,
  TRASH,
} from '../../constants';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  view: null,
  currentProjectId: null,
  treeItems: [{
    name: 'Project',
    key: PROJECTS,
    dataType: PROJECTS,
    children: []
  }, {
    name: 'Trash',
    key: TRASH,
    dataType: TRASH
  }
  ],
  verificationId: null,
  layout: 3,
  viewList: [],
  pcbComponentsNets: [],
  selectedKeys: [],
  projectList: [],
  currentProjectVerifications: [],
  currentProjectDesigns: [],
  refreshStatus: false
}

export const AndesProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
        designID: action.designId || state.designID,
        verificationId: action.verificationId || state.verificationId,
        getStatus: action.getStatus || false,
        verificationName: action.verificationName || state.verificationName
      };
    case UPDATE_VIEW_STATUS:
      return {
        ...state,
        view: action.view,
        designID: action.designId || state.designID,
        verificationId: action.verificationId || state.verificationId,
        getStatus: action.getStatus || false,
        verificationName: action.verificationName || state.verificationName
      };
    case SAVE_OPEN_PROJECT_INFO:
      return {
        ...state,
        currentProjectId: action.id,
        currentProjectDesigns: action.designs,
        currentProjectVerifications: action.verifications,
        currentProjectName: action.name
      }
    case PROJECT_MENU:
      return {
        ...state,
        treeItems: action.treeItems,
        projectList: action.projectList ? action.projectList : state.projectList
      }
    case CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      }
    case CLEAR_CURRENT_PROJECT:
      if (action.status) {
        return {
          ...state,
          currentProjectId: null,
          verificationId: null,
          view: null,
          viewList: []
        }
      } else {
        return {
          ...state,
          verificationId: null,
        }
      }
    case TRASH_MENU:
      return {
        ...state,
        treeItems: [...action.treeItems]
      }
    case CHANGE_VIEW_LIST:
      if (action.viewList && action.viewList.length === 0) {
        return {
          ...state,
          viewList: [...action.viewList],
          view: null
        }
      } else {
        return {
          ...state,
          viewList: [...action.viewList]
        }
      }
    case UPDATE_PCBS_LAYOUTDB:
      return {
        ...state,
        pcbComponentsNets: action.pcbsLayoutDB
      }
    case CHANGE_TREE_SELECTED_KEY:
      return {
        ...state,
        selectedKeys: action.selectedKeys
      }
    case REFRESH_PCB:
      return {
        ...state,
        refreshStatus: action.refreshStatus
      }
    case USER_LOGOUT:
      return {
        ...defaultState,
        pcbComponentsNets: [],
        treeItems: [{
          name: 'Project',
          key: PROJECTS,
          dataType: PROJECTS,
          children: []
        }, {
          name: 'Trash',
          key: TRASH,
          dataType: TRASH
        }
        ]
      }
    case UPDATE_CURRENT_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: action.verificationStatus
      }
    default: return state
  }
};