import React, { Component } from 'react';
import { ResultData } from '@/services/Sierra';
import _ from 'lodash';
import MeasurementPanel from './measurementPanel';
import { PUSH_PULL } from '../../../../../services/Sierra/helper/measurementHelper';
import '../new.css';
import './index.css';

class MeasurementContent extends Component {
  constructor() {
    super();
    this.state = {
      tableData: []
    }
    this.interfaces = [];
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    const { compInfo } = this.props;
    if (compInfo && compInfo.length) {
      this.getPanelData()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { compInfo } = this.props;
    if (compInfo && compInfo.length && (!prevProps.compInfo || !_.isEqual(compInfo, prevProps.compInfo))) {
      this.getPanelData()
    }
  }

  getPanelData = async (compInfo) => {

    const { sweep } = this.props;
    if (sweep) {
      this.getSweepData()
    } else {
      this.getVerData()
    }

  }

  getVerData = async () => {
    let isMultiPCB = false;
    const resultVerificationId = ResultData.getVerificationId();
    let _info = ResultData.getInfo();
    let interfaces = ResultData.getInterfaces();

    const { verificationSubId, verificationId, currentProjectId, postProcessConfig, compInfo } = this.props;
    if (!_info || resultVerificationId !== verificationId) {
      const res = await ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId);
      if (res) {
        this.designs = [];
        _info = ResultData.getInfo();
        interfaces = ResultData.getInterfaces();
        this.interfaces = interfaces;
      } else {
        this.setState({
          tableData: []
        })
        return;
      }
    }
    if (!postProcessConfig || !Object.keys(postProcessConfig).length) {
      this.setState({
        tableData: []
      })
      return;
    }

    if (interfaces && interfaces.length > 1) {
      isMultiPCB = true;
    }
    const { components = [] } = _info
    const { measurementInfo, modelType } = this.getMeasurementInfo({ compInfo, components, postProcessConfig, isMultiPCB })

    this.setState({
      tableData: [{ measurementInfo, modelType, verificationId, postProcessConfig, isUpdate: false }]
    })
  }

  getSweepData = async () => {
    const { experimentList, compInfo } = this.props;
    let tableData = [];
    for (let exp of experimentList) {
      const { currentInterfaceInfo, designs, verificationId, id } = exp;
      const filterCompInfo = compInfo.filter(item => item.experiment === exp.name);
      if (!filterCompInfo || !filterCompInfo.length) {
        continue;
      }
      const { components } = currentInterfaceInfo || {};
      const postProcessConfig = filterCompInfo[0].postProcessConfig || {};
      const { measurementInfo, modelType } = this.getMeasurementInfo({ compInfo: filterCompInfo, components, postProcessConfig, isMultiPCB: designs.length > 1 });
      tableData.push({
        measurementInfo,
        modelType,
        id,
        verificationId,
        name: exp.name,
        postProcessConfig,
        isUpdate: false
      })
    }

    this.setState({
      tableData
    })
  }

  getMeasurementInfo = ({ compInfo, components, postProcessConfig, isMultiPCB }) => {
    const _postProcessConfig = postProcessConfig || {};
    let modelType = PUSH_PULL;
    let measurementInfo = [], compPinList = [];
    const _compInfo = compInfo.filter(item => item.usage)
    for (let info of _compInfo) {
      const { pcbId, component, pin, usage } = info;//pcbId is subId
      if (["Stimulus in", "Stimulus en"].includes(usage)) {
        continue;
      }
      const currentComponentsInfo = components.find(item => item.pcbId === pcbId && item.name === component);
      let currentPinInfo = {};
      if (currentComponentsInfo && currentComponentsInfo.pins) {
        currentPinInfo = currentComponentsInfo.pins.find(item => item.pin === pin)
      }

      //vinh percent and vinl precent
      let data = null;
      modelType = _postProcessConfig.modelType || PUSH_PULL;
      const pcbIndex = (_postProcessConfig.pcbs || []).findIndex(p => p.name === pcbId);
      if (pcbIndex > -1) {
        const compIndex = _postProcessConfig.pcbs[pcbIndex].components.findIndex(c => c.name === component);
        if (compIndex > -1) {
          const pinIndex = _postProcessConfig.pcbs[pcbIndex].components[compIndex].pins.findIndex(p => p.name === pin);
          if (pinIndex > -1) {
            data = _postProcessConfig.pcbs[pcbIndex].components[compIndex].pins[pinIndex];;
          }
        }
      }

      const pcb = currentComponentsInfo ? currentComponentsInfo.pcb : null;

      const pinName = pcb && isMultiPCB ? `${pcb}::${component}::${pin}` : `${component}::${pin}`;
      if (!compPinList.includes(pinName) && data) {
        /* const vdd = getPostProcessingDeviceVcc({ components, currentComponentsInfo, currentPinInfo }); */
        measurementInfo.push({
          ...data,
          pcb: pcbId,
          vinh: data.vih,
          vinl: data.vil,
          vinh_per: data.vih_per || data.vinh_per,
          vinl_per: data.vil_per || data.vinl_per,
          pin: pinName,
          usage,
          model: currentPinInfo && currentPinInfo.model && currentPinInfo.model.modelName ? currentPinInfo.model.modelName : "",
          vdd: data.vdd
        })
        compPinList.push(pinName)
      }
    }

    return {
      measurementInfo,
      modelType
    }
  }

  saveTableData = (tableData) => {
    this.setState({
      tableData
    })
  }

  render() {
    const { tableData } = this.state;
    const { progress, recalc, sweep, experimentList, singleProgress, sweepReCalc } = this.props;
    return <div className='waveFrom-post-process-content' id="waveFrom-post-process-content-id">
      {
        <MeasurementPanel
          tableDataList={tableData}
          // modelType={modelType}
          sweep={sweep}
          type="result"
          progress={progress}
          recalc={recalc}
          singleProgress={singleProgress}
          sweepReCalc={sweepReCalc}
          experimentList={experimentList}
          // postProcessConfig={postProcessConfig}
          changeMeasureVisible={this.props.changeMeasureVisible}
          recalcMeasure={this.props.recalcMeasure}
          saveTableData={this.saveTableData}
          _updateSweepReCalc={this.props._updateSweepReCalc}
          _updateProgress={this.props._updateProgress}
        />
      }
    </div>
  }
}

export default MeasurementContent;