import { connect } from 'react-redux';
import Profile from '@/components/Profile';

const mapState = (state) => {
  const { monitorScreenInfo: {
    currentVerificationId,
  } } = state.MonitorInfoReducer;
  const { simulation } = state.CascadeReducer;
  let profileLog = [];
  if (currentVerificationId && simulation[currentVerificationId]) {
    const currentInfo = simulation[currentVerificationId];
    profileLog = currentInfo.profileLog || [];
  }
  return {
    profileLog
  }
}

export default connect(mapState, null)(Profile);
