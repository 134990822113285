
import { LOAD } from "../../constants/componentType";
import { ANDES_V2, CASCADE, SIERRA } from "../../constants/pageType";
import { CAP, COMP_REPEATER, CONNECTOR, DIODE, IC, IGNORE, IND, JUMPER, RES, SWITCH } from "../PCBHelper";
import { getCompTypeByRepeaterPinMap } from "../Sierra";
import { getCompTypeByPrefixLib } from "./helper";

// const test = ['C1', 'c_1', 'c_f', 'C4D9', 'R5F54', 'L576',
//   'C123P_A_1', 'RA06_20', 'RL_20', 'VR789IA_16', 'R06_02', 'SML123_01'];
// TODO, check components type: component name, pin number, part name
function getCompType({ compName, COMP_PREFIX_LIB, pinLength, partName, product, compPinMap }) {
  let type = IGNORE;
  if (toString.call(compName) !== '[object String]') {
    return type;
  }
  const name = compName.toString();

  //CASCADE power switch
  if (product === CASCADE &&
    COMP_PREFIX_LIB &&
    COMP_PREFIX_LIB.powerSwitch &&
    COMP_PREFIX_LIB.powerSwitch.includes(partName)) {
    type = SWITCH;
    return type;
  }

  //sierra repeater
  if (compPinMap && compPinMap.repeater && compPinMap.repeater.length) {
    const repeaterList = [...new Set([...compPinMap.repeater.map(it => it.part)])];
    if (repeaterList.includes(partName) && compPinMap) {
      type = COMP_REPEATER;
      type = getCompTypeByRepeaterPinMap({ type, part: partName, pins: null, compPinMap, compName: name });
      return type;//repeater or IC
    }
  }

  //GET internal prefix by product
  const defaultPreFix = getDefaultCompPrefix(product, COMP_PREFIX_LIB || {});

  // GET type by custom component reference designator.
  const customPrefix = getCustomCompPrefix(COMP_PREFIX_LIB || {}, defaultPreFix, product);
  if (Object.keys(customPrefix).length) {
    //include part 
    type = getTypeFromPrefixPart(COMP_PREFIX_LIB, partName);
    if (type && type !== IGNORE) {
      return type;
    }
    //comp prefix
    const newType = getCompTypeByPrefixLib(name, customPrefix);
    if (newType && newType !== IGNORE) {
      return newType;
    }
  }

  if (partName) {
    //Get type by DB partName
    const _type = getTypeFromPartName(partName);
    if (_type && _type !== IGNORE) {
      return _type;
    }
  }

  if (COMP_PREFIX_LIB && Object.keys(COMP_PREFIX_LIB).length) {
    type = getCompTypeByPrefixLib(name, defaultPreFix);
  } else {
    const words = name.split(/^([A-Za-z]+)(([0-9]+)|-|_)/g);
    if (words.length < 2) {
      return type;
    }
    if (!words[1]) {
      return type;
    }
    if (words.length < 2) {
      return type;
    }
    if (!words[1]) {
      return type;
    }
    switch (words[1].toLowerCase()) {
      case "c":
      case "ct":
        type = CAP;
        break;
      case "r":
      case "rk":
      case "ra":
      case "rt":
      case "rz":
      case "rl":
      case "vr":
        type = RES;
        break;
      case "l":
        type = IND;
        break;
      case "d":
        type = product === ANDES_V2 ? DIODE : IGNORE;
        break;
      case "u":
      case "j":
      default:
        type = IGNORE;
        break;
    }
  }

  if (type === CAP && (pinLength > 10 || pinLength < 2) && pinLength !== 0) {
    type = IGNORE
  }

  if (type === JUMPER && pinLength > 2) {
    type = IGNORE
  }

  if (product === SIERRA && type === IGNORE) {
    //sierra IC component
    const words = name.split(/^([A-Za-z]+)(([0-9]+)|-|_)/g);
    if (words && words[1] && ["u", "ic"].includes(words[1].toLowerCase())) {
      type = IC;
    }
  }

  return type;
}

function getCustomCompPrefix(COMP_PREFIX_LIB, defaultPreFix, product) {
  const customPrefix = {};
  for (let key of Object.keys(COMP_PREFIX_LIB)) {
    if (key === COMP_REPEATER || (product === SIERRA && key === LOAD)) {
      continue;
    }
    if (!defaultPreFix[key] || !defaultPreFix[key].length) {
      customPrefix[key] = [...COMP_PREFIX_LIB[key]];
      continue;
    }
    customPrefix[key] = COMP_PREFIX_LIB[key].filter(item => !defaultPreFix[key].includes(item));
  }
  return customPrefix;
}

function getDefaultCompPrefix(product, COMP_PREFIX_LIB = {}) {
  //todo other product
  switch (product) {
    case SIERRA:
      return {
        [RES]: ["R", "PR", "XW", "SHRT"].filter(item => COMP_PREFIX_LIB[RES] && COMP_PREFIX_LIB[RES].includes(item)),
        [IND]: ["L", "PL", "FB", "FL"].filter(item => COMP_PREFIX_LIB[IND] && COMP_PREFIX_LIB[IND].includes(item)),
        [CAP]: ["C", "PC"].filter(item => COMP_PREFIX_LIB[CAP] && COMP_PREFIX_LIB[CAP].includes(item)),
        [CONNECTOR]: ["J"].filter(item => COMP_PREFIX_LIB[CONNECTOR] && COMP_PREFIX_LIB[CONNECTOR].includes(item)),
      }
    default:
      return {
        /* [RES]: ["R", "PR", "RK", "RA", "RT", "RZ", "RL", "VR"],
        [IND]: ["L"],
        [CAP]: ["C", "CT"], */
      }
  }
}

function getTypeFromPrefixPart(COMP_PREFIX_LIB, partName) {
  const TypeList = [JUMPER, CONNECTOR, IC, RES, IND, CAP];
  let _type = null;
  for (let txtType of TypeList) {
    const txtValue = COMP_PREFIX_LIB[txtType] || [];
    if (txtValue.includes(partName)) {
      return txtType
    }
  }
  return _type;
}

function getTypeFromPartName(part) {
  //part name prefix include type (CAP NP_C0603_39P370_DC_22UC_6.3)
  var partName = part.toUpperCase();
  let _type = null;
  if (partName.match(/^RES/ig)) {
    _type = RES;
  } else if (partName.match(/^CAP|^RF_CAP/ig)) {
    _type = CAP;
  } else if (partName.match(/^IND/ig)) {
    _type = IND;
  }
  return _type
}

export {
  getCompType,
  getTypeFromPartName,
  getTypeFromPrefixPart,
  getDefaultCompPrefix,
  getCustomCompPrefix
}