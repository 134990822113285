import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'antd';
import Panel from '@/components/Panel';
import Table from "@/components/EditableTable";
import { getComponentByName } from '../../../services/PCBHelper';
import './index.css';

const columns = [{
  key: "pin",
  dataIndex: "pin",
  title: "Input",
  width: "50%"
}, {
  key: "pinMap",
  dataIndex: "pinMap",
  title: "Output",
  width: "50%"
}]
class ConnectCMCPinsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allCompPins: [],
      selectedPins: [],
      pinMap: []
    }
    this.dialogRoot = document.getElementById('root');

    columns[1].onCell = (record) => {
      const { allCompPins } = this.state;
      const options = allCompPins.map(item => item.mNumber);
      return {
        record,
        edit: "select",
        options: options,
        dataIndex: "pinMap",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "cmc-components-pins-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "pinMap") },
      }
    }

    columns[1].render = (value, record) => {
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {value}</div>
          <div>Pin Name: {record.pinMapInfo.mName}</div>
        </div>}
      >
        <div className="cmc-components-pins-columns">{value}</div>
      </Tooltip>
    }

    columns[0].render = (value, record) => {
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {value}</div>
          <div>Pin Name: {record.pinInfo.mName}</div>
        </div>}
      >
        <div className="cmc-components-pins-columns">{value}</div>
      </Tooltip>
    }

    columns[0].onCell = (record) => {
      const { allCompPins } = this.state;
      const options = allCompPins.map(item => item.mNumber);
      return {
        record,
        edit: "select",
        options: options,
        dataIndex: "pin",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "cmc-components-pins-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "pin") },
      }
    }
  }

  editPinMap = (record, prevRecord, type) => {
    const { pinMap } = this.state;
    let _pinMap = [...pinMap];
    const otherType = type === 'pin' ? "pinMap" : 'pin';
    const prevPin = prevRecord[type];
    const prevIndex = _pinMap.findIndex(item => item[type] === prevRecord[type]);
    const newPin = record[type];
    const newIndex = _pinMap.findIndex(item => item[otherType] === newPin || item[type] === newPin);
    if (_pinMap[newIndex][type] === newPin) {
      _pinMap[newIndex][type] = prevPin;
    } else {
      _pinMap[newIndex][otherType] = prevPin;
    }
    _pinMap[prevIndex][type] = newPin;
    this.setState({
      pinMap: _pinMap
    })
  }

  closeModal = () => {
    const { cmcComp } = this.props;
    const { pinMap } = this.state;
    this.props._editCMCCompPinsConnect(cmcComp.part, pinMap);
    this.props.closeModal();
  }

  componentDidMount = () => {
    const { cmcComp, designId } = this.props;
    if (cmcComp && cmcComp.comps && cmcComp.comps[0]) {
      const pins = cmcComp.comps[0].pins.map(item => item.pin);
      const pcbComp = getComponentByName(designId, cmcComp.comps[0].name);
      const mPinList = pcbComp.mPart && pcbComp.mPart.mPinList ? pcbComp.mPart.mPinList : [];
      const allCompPins = JSON.parse(JSON.stringify(mPinList));
      let pinMap = cmcComp.pinsConnection || [];
      this.setState({ allCompPins, selectedPins: pins, pinMap })
    }
  }

  getTablePins = () => {
    const { pinMap, allCompPins } = this.state;
    let _pinMap = JSON.parse(JSON.stringify(pinMap));
    for (let pin of _pinMap) {
      const findPin = allCompPins.find(item => item.mNumber === pin.pin);
      const findPinMap = allCompPins.find(item => item.mNumber === pin.pinMap);
      pin.pinInfo = findPin;
      pin.pinMapInfo = findPinMap;
    }
    return _pinMap;
  }

  render = () => {
    const { cmcComp } = this.props;
    const { pinMap } = this.state;
    const dataList = this.getTablePins(pinMap)
    const content = (
      <Panel
        className='andes-create-channel-panel'
        position='panel-center-left'
        title={`Pins Connections - ${cmcComp.part}`}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={600}
        draggable
        minHeight={200}
      >
        <div className='create-channel-content'>
          <Table
            ref={(ref) => this.table = ref}
            columns={columns}
            dataSource={dataList}
            rowKey="pin"
          />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}


export default ConnectCMCPinsPanel;