import React, { PureComponent, Fragment } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  Select,
  Tooltip,
  message,
  Switch,
  Input,
  Checkbox,
  Spin,
  Button,
  Radio,
  Collapse
} from 'antd';
import { ROCKY, SIERRA } from '../../constants/pageType';
import TreeSelectComp from '@/components/TreeSelect';
import { strDelimited } from '@/services/helper/split';
import { getTableAmiParameters, getTableAmiParametersValue, amiParameterValueCheck, ibisAMIModelParamsParse } from '@/services/Rocky/AMIModelHelper/amiParameterHelper';
import EditableTable from '@/components/EditableTable';
import { BOOLEAN, LIST, CORNER, RANGE, STRING, INTEGER, FLOAT, TAP, GAUSSIAN, PARAMETER_DUAL_DIRAC, PARAMETER_DJRJ } from '@/services/Rocky/AMIModelHelper/constants';
import TagsInput from '../TagsInput';
import './index.css';

const Option = Select.Option;
const IBIS = 'IBIS', IBIS_AMI = "IBIS_AMI";
const OUTPUT = ['Output', 'Open_drain', 'Open_sink', 'Open_source'];
const IBIS_AMI_FILE_MODEL = 'IBIS_AMI_FILE_MODEL', AMI_OBJ_PARAM = 'AMI_OBJ_PARAM', IBIS_ODT = 'IBIS_ODT', CUSTOM_ODT = 'CUSTOM_ODT';
const RadioGroup = Radio.Group;
const DELAY_RISING = 'delayRise', DELAY_FALLING = 'delayFall', TAILS_RISING = 'tailsRise', TAILS_FALLING = 'tailsFall';
const ADV_DEFAULT = 'default', ADV_CUSTOM = 'custom';
const amiParametersColumns = [{
  key: "type",
  dataIndex: "type",
  title: "Type",
  width: "20%"
}, {
  key: "name",
  dataIndex: "name",
  title: "Name",
  width: "40%"
}, {
  key: "value",
  dataIndex: "value",
  title: "Value",
  width: "40%"
}];

class ModelIbis extends PureComponent {
  constructor(props) {
    super(props);
    /**
     * props 
     *       // libType - SPCIE/IBIS, modelType - 'I/O,Input,Output,Tristate'
     * origin: { enableVoltage, fileName, modelName, modelType, name, libType } // origin - Can not be modified 
     * editModel : { enableVoltage, fileName, modelName, modelType, name, libType } // editModel - Editable
     * record: { component, deviceVcc, model, pinModels, net, pin, signal, usage,... }
     * type - tx/rx
     */
    const { editModel } = props;
    this.state = {
      files: [],
      modelList: [],
      modelTypeList: [],
      selectedModelList: [],
      multipleModelList: [],
      modelSelector: '',
      loading: true,
      modelNameInput: this.getData(editModel, 'modelName'),
      ibisFile: this.getData(editModel, 'fileName'),
      modelType: this.getData(editModel, 'modelType'),
      modelName: this.getData(editModel, 'modelName'),
      libraryId: this.getData(editModel, 'libraryId'),
      folder: this.getData(editModel, "folder"),
      version: this.getData(editModel, "version"),
      ODTValueValidateStatus: true,
      amiValidate: true,
      amiValidateMessage: "",
      advancedValidate: true,
      advancedValidateMessage: "",
      amiParamError: null,
      amiFile: null,
    };

    amiParametersColumns[0].render = (name, record) => {
      return {
        children: <span>{name}</span>,
        props: {
          rowSpan: record.groupLength
        }
      }
    };
    amiParametersColumns[1].render = (name, record) => {
      return <Tooltip
        overlayClassName='aurora-tooltip'
        title={record.description ? `Description: ${record.description}` : null}
      >
        <span className="ami-parameters-title">{name}</span>
      </Tooltip>
    };
    amiParametersColumns[2].onCell = (record) => {
      if (record.disable && record.disable === "true") {
        return {
          edit: false
        }
      }
      if (record.valueType === BOOLEAN
        || record.valueType === LIST
        || record.valueType === CORNER
      ) {
        const options = record.valueType === CORNER
          ? record.valueList.map(item => { return { value: item.value, title: `${item.type}: ${item.value}` } })
          : record.valueList
        return {
          record,
          edit: "select",
          options: options || [],
          dataIndex: "value",
          handleSave: (_record) => { this._editParameterValue(_record, true) },
        }
      }

      if ([GAUSSIAN, PARAMETER_DUAL_DIRAC, PARAMETER_DJRJ].includes(record.valueType)) {
        return {
          edit: false
        }
      }

      if (record.valueType !== CORNER) {
        return {
          record: { ...record, value: getTableAmiParametersValue(record) },
          edit: true,
          dataIndex: 'value',
          handleSave: (_record) => { this._editParameterValue(_record) }
        }
      }
    }

    amiParametersColumns[2].render = (name, record) => {
      if ([GAUSSIAN, PARAMETER_DUAL_DIRAC, PARAMETER_DJRJ].includes(record.valueType)) {
        return <span className="IC-ami-parameters-value"
          title={record.displayValue}
        >{record.displayValue}</span>
      }
      return <span className="IC-ami-parameters-value" title={name}>{name}</span>
    }

  }

  getData(model, pram) {
    return model && [IBIS, IBIS_AMI].includes(model.libType) ? model[pram] : null;
  }

  getFileData = () => {
    const { ibisList, _compModel, editModel, product, libType, ibisAmiList, compAmiModel } = this.props;
    const { modelType, modelName, libraryId, ibisFile } = this.state;
    const files = libType === IBIS_AMI && ibisAmiList ? JSON.parse(JSON.stringify(ibisAmiList)) : libType !== IBIS_AMI && ibisList ? JSON.parse(JSON.stringify(ibisList)) : [];
    const newState = { files };
    if (!ibisFile) {
      let fileName = "", _libraryId = "", version = null, folder = "";
      if (compAmiModel && compAmiModel.fileName && libType === IBIS_AMI) {
        fileName = compAmiModel.fileName;
        _libraryId = compAmiModel.libraryId;
        folder = compAmiModel.folder;
      } else if (_compModel && _compModel.fileName && _compModel.libType === libType && libType === IBIS) {
        fileName = _compModel.fileName;
        _libraryId = _compModel.libraryId;
        folder = _compModel.folder;
        version = _compModel.version;
      } else if (files.length) {
        if (product === SIERRA) {
          for (let _file of files) {
            if (_file.type !== 'folder') {
              fileName = _file.name;
              _libraryId = _file.id;
              version = _file.version;
              break;
            }
          }
        } else if (libType === IBIS_AMI || (libType === IBIS && product === ROCKY)) {
          for (let _file of files) {
            if (_file.children && _file.children.length) {
              const currentFile = _file.children[0];
              fileName = currentFile.name;
              _libraryId = currentFile.id;
              folder = _file.name;
              break;
            } else if (libType === IBIS && _file.type !== "folder") {
              fileName = _file.name;
              _libraryId = _file.id;
              break;
            }
          }
        } else {
          if (!_libraryId) {
            fileName = files[0].name;
            _libraryId = files[0].id;
            version = files[0].version;
          }
        }
      }
      newState.ibisFile = fileName;
      newState.libraryId = _libraryId;
      newState.folder = folder;
      newState.version = version;
      this.props.saveChange({
        libType: libType,
        fileName,
        libraryId: _libraryId,
        modelType: "",
        modelName: "",
        enableVoltage: '',
        version,
        folder
      });
    } else {
      let findFile = null;
      if (editModel && editModel.folder) {
        const findFolder = files.find(item => item.name === editModel.folder);
        if (findFolder && findFolder.children) {
          findFile = findFolder.children.find(item => item.name === ibisFile);
        }
      } else {
        findFile = files.find(item => item.name === ibisFile);
      }
      if (findFile && findFile.id !== libraryId) {
        newState.libraryId = findFile.id;
        newState.version = findFile.version || newState.version;
        this.props.saveChange({
          ...editModel,
          libraryId: findFile.id,
          version: newState.version
        });
      }
    }
    this.setState(newState, () => {
      const { libraryId, ibisFile, folder } = this.state;
      if (libraryId && ibisFile) {
        this.getIbisModels({ fileName: ibisFile, modelType, modelName, libraryId, folder });
        this.getLibraryDetail(libraryId, "ROCKY", ibisFile, modelName, false);
        this.setState({
          multipleModelList: modelName ? [modelName] : []
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }

  componentDidMount() {
    this.getFileData()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.createStatus === true && this.props.createStatus === false) {
      this.props.closeModal()
    }
    if (prevState.libraryId !== this.state.libraryId) {
      this.clearMultipleModeList()
    }
    if (prevProps.libType !== this.props.libType) {
      const { editModel } = this.props;
      this.setState({
        modelNameInput: this.getData(editModel, 'modelName'),
        ibisFile: this.getData(editModel, 'fileName'),
        modelType: this.getData(editModel, 'modelType'),
        modelName: this.getData(editModel, 'modelName'),
        libraryId: this.getData(editModel, 'libraryId'),
        folder: this.getData(editModel, "folder"),
      }, () => {
        this.getFileData()
      })
    }
  }

  selectUnit = () => {
    return (
      <Select
        value={'V'}
        className='IC-select-model-input-after'
        popupClassName='IC-select-model-dropdown'
        popupMatchSelectWidth={false}
        getPopupContainer={trigger => trigger.parentNode}>
        <Option key="V">V</Option>
      </Select>
    )
  }

  onIbisFileChange = (value) => {
    const { files } = this.state;
    let libraryId = '', name = value.name ? value.name : value, version = null, folder = "";
    if (value.id) {
      libraryId = value.id;
      version = value.version;
    } else {
      const findId = files.find(file => file.fileName === value);
      if (findId) {
        libraryId = findId.id;
        version = findId.version;
      }
    }

    const findIndex = files.findIndex(item => item.id === libraryId);
    if (findIndex > -1) {
      const isFolder = files[findIndex].type === "folder";
      folder = isFolder ? files[findIndex].name : "";
    }

    const model = { fileName: name, libraryId, modelType: "", modelName: "", enableVoltage: 0, folder };
    this.props.saveChange(version ? { ...model, version } : model);
    this.setState({
      ibisFile: name,
      loading: true,
      modelName: "",
      modelType: "",
      modelNameInput: '',
      libraryId,
      multipleModelList: [],
      folder: folder,
      version
    }, () => this.getIbisModels({ fileName: name, libraryId }))
  }

  getIbisModels({ fileName, modelType, modelName, libraryId }) {
    const { getIbisModel, type, libType } = this.props;
    const { files } = this.state;
    const findId = libraryId ? { id: libraryId } : files.find(item => item.name === fileName);
    const currentFile = files.find(item => item.id === libraryId)
    const _libType = libType === IBIS && currentFile && currentFile.type === "folder" && currentFile.fileType === "ibis_ami" ? IBIS_AMI : libType;
    if (findId) {
      getIbisModel(type, fileName, findId.id, _libType).then(models => {
        let modelList = this.getSortedList(models);
        let modelTypeList = this.getModelTypeList(models.models);
        let selectorList = this.getSelectorListList(models.selectorModels);
        let selectedModelList = [...modelList];
        let modelSelector = "";
        if (modelType || modelName) {
          if (modelName) {
            const find = modelList.find(item => item.name === modelName);
            if (find) {
              modelSelector = find.selector;
            }
          }
          selectedModelList = this.getSelectedModel({ modelList, modelType, modelSelector })
        }
        this.setState({
          modelList,
          selectedModelList,
          modelTypeList,
          selectorList,
          modelSelector,
          loading: false
        })
      }).catch(error => {
        this.setState({
          modelList: [],
          modelTypeList: [],
          selectorList: [],
          selectedModelList: [],
          modelSelector: "",
          loading: false
        })
      })
    } else {
      message.error(`Not found ${fileName} file.`);
      this.setState({
        loading: false
      })
    }
  }

  getSelectedModel = ({ modelList, modelType, modelSelector }) => {
    let list = JSON.parse(JSON.stringify(modelList));

    if (modelType) {
      list = list.filter(item => item.type === modelType);
    }

    if (modelSelector) {
      list = list.filter(item => item.selector === modelSelector);
    }
    return list;
  }


  //Add a new modelType to the list
  addModelTypeList(model, oldList, key) {
    let list = [...oldList];
    let models = [];
    model.key = key;
    models.push(model);
    let modelType = model.type;

    let obj = {
      models,
      modelType
    };
    list.push(obj);
    return list;
  }

  //get selector list
  getSelectorListList = (selectorModels) => {
    let list = [];
    selectorModels.forEach((model, key) => {
      if (!list.includes(model.selector)) {
        list.push(model.selector);
      }
    })
    return list;
  }

  //get modelType list
  getModelTypeList(modelList) {
    let list = [];
    modelList.forEach((model, key) => {
      if (!list.includes(model.type)) {
        list.push(model.type);
      }
    })
    return list;
  }

  getSortedList(modelsObj) {
    let list = [];
    if (modelsObj && modelsObj.models) {
      const modelList = JSON.parse(JSON.stringify(modelsObj.models));
      const selectorModels = modelsObj.selectorModels ? JSON.parse(JSON.stringify(modelsObj.selectorModels)) : [];
      let selectorModelList = []
      for (let item of selectorModels) {
        if (item.models) {
          for (let model of item.models) {
            selectorModelList.push({ selector: item.selector, ...model });
          }
        }
      }
      for (let item of modelList) {
        const find = selectorModelList.find(model => model.name === item.name);
        if (find) {
          list.push({ ...find, ...item });
        } else {
          list.push({ ...item });
        }
      }
    }
    return list;
  }

  onSelectModelSelector = (key) => {
    const { modelList, modelType } = this.state;
    const prevType = this.state.modelSelector;
    if (prevType !== key) {
      const selectedModelList = this.getSelectedModel({ modelList, modelType, modelSelector: key });
      this.setState({
        modelSelector: key,
        modelName: "",
        selectedModelList
      })
    }
  }

  onAmiModelSelector = (e) => {
    const { modelNameInput, modelList } = this.state;
    this.props.onAmiModelSelector(e);
    if (e && e === AMI_OBJ_PARAM && modelList) {
      const model = modelList.find(item => item.name === modelNameInput);
      if (!model) return;
      const { name, description } = model;
      const haveODT = this.checkODT([name, description]);
      if (!haveODT) {
        this.props.onAmiModelUseSelector({ target: { value: CUSTOM_ODT } });
      }
    }
  }

  onAmiModelUseSelector = (e) => {
    const { amiValidate } = this.state;
    this.props.onAmiModelUseSelector(e);
    this.props.setValidate(true);
    if (!amiValidate) {
      this.props.changeODTValue({ target: { value: '50' } }, true);
      this.setState({
        amiValidate: true,
        amiValidateMessage: ""
      })
    }
  }

  checkODT = (arr) => {
    const containsODT = /ODT/i;
    const noODTs = ['ODT disabled', 'no ODT', 'ODTOFF', 'ODT=OFF', 'DIS Ohm System ODT', 'ODTDIS', 'ODT OFF'];
    let haveODT = false;
    for (let e of arr) {
      if (!e) continue;
      if (!containsODT.test(e)) {
        haveODT = false;
        continue;
      }
      const noODTsPattern = new RegExp(noODTs.join('|'), 'i');
      if (noODTsPattern.test(e)) {
        haveODT = false;
      } else {
        haveODT = true;
        break;
      }
    }
    return haveODT;
  }

  onModelNameChange = (e, modelItem) => {
    const { modelList, libraryId, amiValidate, version } = this.state;
    const { libType } = this.props;
    if (modelList) {
      const model = modelList.find(item => item.name === modelItem.name);
      const { type, enable, deviceVcc, selector } = model;
      if (libType === IBIS_AMI) {
        const { name, description } = model;
        const haveODT = this.checkODT([name, description]);
        if (this.props.type === 'tx') {
          this.props.onAmiModelSelector(IBIS_AMI_FILE_MODEL);
        } else {
          this.props.onAmiModelSelector(AMI_OBJ_PARAM);
          if (!haveODT) {
            this.props.onAmiModelUseSelector({ target: { value: CUSTOM_ODT } });
          } else {
            this.props.onAmiModelUseSelector({ target: { value: IBIS_ODT } });
          }
          if (!amiValidate) {
            this.props.changeODTValue({ target: { value: '50' } }, true);
            this.setState({
              amiValidate: true
            })
          }
        }
      }
      const fileName = this.state.ibisFile;
      const selectedModelList = this.getSelectedModel({ modelList, modelType: type, modelSelector: selector });

      const _deviceVcc = deviceVcc && deviceVcc !== 'NA' ? parseFloat(deviceVcc).toString() : ""
      this.setState({
        modelName: modelItem.name,
        modelNameInput: modelItem.name,
        modelType: type,
        modelSelector: selector,
        selectedModelList
      })
      this.props.saveChange({
        libraryId,
        fileName,
        modelName: modelItem.name,
        modelType: type,
        enableVoltage: this.existEnableVoltage(type) ? (enable === 'NA' ? '1' : enable) : "",
        version
      },
        _deviceVcc);
      this.getLibraryDetail(libraryId, "ROCKY", fileName, modelItem.name, true);
    }
  }

  modelNameInputChange = (e) => {
    this.setState({
      modelNameInput: e.target.value
    });
    const { modelList, modelType, modelSelector, libraryId, ibisFile, version } = this.state;
    const selectedModelList = this.getSelectedModel({ modelList, modelType, modelSelector });
    let value = e.target.value;
    let valueList = [];
    strDelimited(value).forEach(item => {
      if (item.match(/(\[)|(\])|(\\)|(\/)|(\()|(\))|(\|)|(\?)/)) {
        item = `\\${item}`;
      }
      valueList.push(item)
    })
    value = valueList.join("");
    let reg = new RegExp(`(${value})`, 'i');
    let list = [];
    selectedModelList.forEach(item => {
      if (item.info) {
        if (item.info.match(reg)) {
          list.push(item)
        }
      } else if (item.name && item.name.match(reg)) {
        list.push(item)
      }
    })

    this.setState({
      selectedModelList: list
    })
    const find = modelList.find(item => item.name === e.target.value);
    if (e.target.value && find) {
      const { type, enable, deviceVcc, selector } = find;
      const selectedModelList = this.getSelectedModel({ modelList, modelType: type, modelSelector: selector });
      let reg = new RegExp(`(${e.target.value})`, 'i');
      let list = [];
      selectedModelList.forEach(item => {
        if (item.info) {
          if (item.info.match(reg)) {
            list.push(item)
          }
        } else if (item.name && item.name.match(reg)) {
          list.push(item)
        }
      })
      const _deviceVcc = deviceVcc && deviceVcc !== 'NA' ? parseFloat(deviceVcc).toString() : ""
      this.setState({
        modelName: find.name,
        modelType: type,
        modelSelector: selector,
        selectedModelList: list
      });
      this.props.saveChange({
        libraryId,
        fileName: ibisFile,
        modelName: find.name,
        modelType: type,
        enableVoltage: this.existEnableVoltage(type) ? (enable === 'NA' ? '1' : enable) : "",
        version
      },
        _deviceVcc);
    }
  }

  blurModelNameInput = (e) => {
    this.setState({
      modelNameInput: e.target.value
    });
    const { modelList, modelType, libraryId, ibisFile, version } = this.state;

    const find = modelList.find(item => item.name === e.target.value);
    if (e.target.value && find) {
      const { type, enable, deviceVcc, selector } = find;
      const selectedModelList = this.getSelectedModel({ modelList, modelType: type, modelSelector: selector });
      this.setState({
        modelName: find.name,
        modelType: type,
        modelSelector: selector,
        selectedModelList
      });
      const _deviceVcc = deviceVcc && deviceVcc !== 'NA' ? parseFloat(deviceVcc).toString() : ""
      this.props.saveChange({
        libraryId,
        fileName: ibisFile,
        modelName: find.name,
        modelType: type,
        enableVoltage: this.existEnableVoltage(type) ? (enable === 'NA' ? '1' : enable) : "",
        version
      },
        _deviceVcc);
    } else if (!e.target.value) {
      this.props.saveChange({
        libraryId,
        fileName: ibisFile,
        modelName: "",
        modelType,
        version
      })
    }
  }

  existEnableVoltage(type) {
    const _type = type.toUpperCase();
    if (_type.includes('I/O') || _type.includes('IO') || _type === 'TRISTATE' || _type === '3-STATE' || _type === '3STATE') {
      return true;
    };
    return false;
  }

  getName = (name) => {
    if (this.modelNameListRef.current) {
      const width = this.modelNameListRef.current.clientWidth;
      if (name.length * 8 + 10 > width) {
        return <Tooltip placement="right" title={name}>{name}</Tooltip>
      } else {
        return name;
      }
    }
    return <Tooltip placement="right" title={name}>{name}</Tooltip>
  }

  onModelTypeChange = (key) => {
    const { libraryId, modelList, modelSelector, version } = this.state;
    const prevType = this.state.modelType;
    if (prevType !== key) {
      const selectedModelList = this.getSelectedModel({ modelList, modelType: key, modelSelector })
      this.props.saveChange({ modelType: key, modelName: "", enableVoltage: "", libraryId, version });
      this.setState({
        modelType: key,
        modelName: "",
        modelNameInput: "",
        selectedModelList
      })
    }
  }

  handleKeyDown = (e) => {
    e.target.blur();
  }

  cleanModelType = (e) => {
    e.stopPropagation();
    const { libraryId, modelList, modelSelector, version } = this.state;
    const selectedModelList = this.getSelectedModel({ modelList, modelType: "", modelSelector })
    this.props.saveChange({ modelType: "", modelName: "", enableVoltage: "", libraryId, version });
    this.setState({
      modelType: "",
      modelName: "",
      modelNameInput: '',
      selectedModelList
    })
  }

  cleanSelector = (e) => {
    e.stopPropagation();
    const { modelType, modelList } = this.state;
    const selectedModelList = this.getSelectedModel({ modelList, modelType, modelSelector: "" })
    this.setState({
      selectedModelList,
      modelSelector: ""
    })
  }

  cleanModelFile = (e) => {
    e.stopPropagation();
    this.props.saveChange({ fileName: "", libraryId: "", modelType: "", modelName: "", enableVoltage: 0, folder: "", version: "" });
    this.setState({
      ibisFile: "",
      modelName: "",
      modelType: "",
      libraryId: "",
      modelList: [],
      modelTypeList: [],
      selectorList: [],
      selectedModelList: [],
      modelSelector: "",
      modelNameInput: '',
      folder: "",
      version: ""
    })
  }

  changeFile = (files) => {
    let _files = [];
    for (let file of files) {
      const children = file && file.children ? file.children.map(item => {
        return {
          title: item.name,
          value: item.key,
          key: item.key
        }
      }) : []
      _files.push({
        title: file.name,
        value: file.key,
        key: file.key,
        children
      })
    }
    return _files
  }

  getSelectComponents = (ibisFile, libraryId, files, libType) => {
    const { product } = this.props;
    return libType === IBIS_AMI || (libType === IBIS && product === ROCKY) ?
      <TreeSelectComp
        value={{
          value: libraryId,
          label: ibisFile,
          key: libraryId,
        }
        }
        labelInValue
        onSelectItem={this.onIbisFileChange}
        size='small'
        showSearch
        popupClassName='IC-select-model-select-dropdown'
        className='IC-model-type-select'
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
        fileList={files}
        getPopupContainer={() => document.getElementById('root')}
        selected={libraryId && ibisFile ? `${libraryId}::${ibisFile}` : libraryId}
      />
      : product === SIERRA ? <TreeSelectComp
        value={ibisFile || undefined}
        onSelectItem={this.onIbisFileChange}
        size='small'
        showSearch
        popupClassName='IC-select-model-select-dropdown'
        className='IC-model-type-select'
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
        fileList={files}
        getPopupContainer={() => document.getElementById('root')}
        selected={libraryId}
      />
        : <Select
          value={ibisFile || undefined}
          onChange={this.onIbisFileChange}
          size='small'
          showSearch
          popupClassName='IC-select-model-select-dropdown'
          className='IC-model-type-select'
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelFile(e)} /> }}
          getPopupContainer={() => document.getElementById('root')}
        >
          {files.map((file, index) => <Option key={index} value={file.name} title={file.name}>{file.name}</Option>)}
        </Select>;
  }

  updateMultipleModeList = (e, value) => {
    const checked = e.target.checked
    let multipleModelList = this.state.multipleModelList
    if (checked) {
      multipleModelList.push(value)
    } else {
      multipleModelList = multipleModelList.filter(item => item !== value)
    }
    this.setState({
      multipleModelList: [...multipleModelList]
    })
  }

  createExpsByModel = () => {
    const { multipleModelList, modelList, ibisFile, libraryId } = this.state
    const { record: { component, pin, usage, model: { modelName }, pinModels: recordPinModels }, getPins, interfaceIndex, updateCreateStatus, multipleModels } = this.props

    const baseModelName = multipleModels ? this.getBaseModel(this.props.record, libraryId) : null;

    const rowModelsList = modelList.filter(item => multipleModelList.includes(item.name) && baseModelName !== item.name)
    if (!ibisFile || !libraryId) {
      return;
    }

    let modelsList = rowModelsList.map(item => {
      if (modelName !== item.name) {
        let pinModels = getPins({ type: item.type, usage });

        for (let pinModel of recordPinModels) {
          const index = pinModels.findIndex(it => it.pinName === pinModel.pinName);

          if (pinModel.pinName === 'nd_pu' && index > -1) {
            pinModels[index].voltage = parseFloat(item.deviceVcc).toString()
          }
          if (pinModel.pinName === 'nd_pd' && index > -1) {
            pinModels[index].voltage = "0"
          }
          if (pinModel.pinName === 'nd_in' && index > -1) {
            pinModels[index].type = pinModel.type;
            pinModels[index].stimulus = pinModel.stimulus;
            if (pinModel.tabs) {
              pinModels[index].tabs = pinModel.tabs;
              pinModels[index].slew = pinModel.slew;
              pinModels[index].delay = pinModel.delay;
            }
          }

          if (pinModel.pinName === 'nd_en' && !OUTPUT.includes(item.modelType)) {
            //OUTPUT has no enable pin
            pinModels.push({ ...pinModel });
          }
        }

        return {
          component,
          pinInfo: [{
            deviceVcc: parseFloat(item.deviceVcc).toString(),
            pin,
            // usage,
            pinModels: pinModels,
            model: {
              enableVoltage: this.existEnableVoltage(item.type) ? (item.enable === 'NA' ? '1' : item.enable) : "",
              modelName: item.name,
              fileName: ibisFile,
              libType: IBIS,
              libraryId,
              modelType: item.type
            }
          }]
        }
      }

      else return null
    })
    this.props.createExpsByModel(modelsList, interfaceIndex)
    updateCreateStatus(true)
  }

  changeMultipleModeList = (type, list) => {
    this.setState({
      multipleModelList: [...list]
    })
  }

  clearMultipleModeList = () => {
    this.setState({
      multipleModelList: []
    })
  }

  getBaseModel = (record, libraryId) => {
    if (!record || !record.model) { return null }
    if (record.model.libraryId === libraryId) {
      return record.model.modelName;
    }
    return null
  }

  changeODTValue = (e) => {
    let validateStatus = this.amiValidateCheck(e, 'ODTValue');
    this.props.changeODTValue(e, validateStatus)
  }

  ODTBlur = (e, type) => {
    let validateStatus = this.amiValidateCheck(e, type);
    if (!validateStatus) {
      e.target.focus();
    }
  }

  changeIgnoreBits = (e) => {
    let validateStatus = this.amiValidateCheck(e, 'IgnoreBits');
    this.props.changeIgnoreBits(e, validateStatus)
  }

  //check the value of ODT and IgnoreBits
  amiValidateCheck = (e, type) => {
    const value = Number(e.target.value);
    switch (type) {
      case 'ODTValue':
        if (value >= 0 && Number.isInteger(value) && (e.target.value !== '') && value <= 251) {
          this.setState({
            amiValidate: true
          })
          return true;
        } else {
          this.setState({
            amiValidate: false,
            amiValidateMessage: "ODT value must be an integer between 0 and 251."
          })
          return false;
        }
      case 'IgnoreBits':
        if (value >= 0 && Number.isInteger(value) && (e.target.value !== '')) {
          this.setState({
            amiValidate: true
          })
          return true;
        } else {
          this.setState({
            amiValidate: false,
            amiValidateMessage: "Ignore bits must be an integer greater than or equal to 0."
          })
          return false;
        }
      default:
        break;
    }
  }

  selectAdvOption = (type, unit) => {
    return (
      (<Select
        value={unit}
        onChange={(value) => this.editOptionsUnitChange(value, type)}
        className='IC-adv-option-input-after'
        popupClassName='IC-adv-option-input-after-dropdown'
        getPopupContainer={trigger => trigger.parentNode}>
        <Option key={'ps'} value={'ps'}>ps</Option>
        <Option key={'ns'} value={'ns'}>ns</Option>
        <Option key={'us'} value={'us'}>us</Option>
        <Option key={'ms'} value={'ms'}>ms</Option>
        <Option key={'s'} value={'s'}>s</Option>
      </Select>)
    );
  }

  editOptionsUnitChange = (value, type) => {
    this.props.editOptionsUnitChange(value, type)
  }

  onAdvOptionDefaultChange = (e, type) => {
    const { ibisAdvancedOptions } = this.props;
    const value = ibisAdvancedOptions[type].value;
    if (e.target.value === ADV_DEFAULT) {
      const check = Number.isInteger(Number(value)) && value !== '' ? true : false;
      if (!check) {
        this.props.onAdvOptionValueChange({ target: { value: '0' } }, type)
      }
    }
    this.props.onAdvOptionDefaultChange(e, type)
  }

  modelParametersRender = () => {
    const { amiParamError } = this.state;
    const { amiParameters } = this.props;
    const items = [
      {
        label: <div className="IC-ami-model-collapse-title">
          <span>AMI Parameters</span>
        </div>,
        key: "parameters",
        children: <Fragment>
          {amiParamError ? <span className='aurora-model-name-error-msg'>{amiParamError}</span> : null}
          <EditableTable
            rowKey={(record) => record.name}
            columns={amiParametersColumns}
            dataSource={this.getAmiParameters(amiParameters) || []}
            size="small"
            className='IC-ami-parameters-table'
          />
        </Fragment>
      }
    ]
    return <div className='IC-select-dialog IC-select-dialog-ami-model clear'>
      <Collapse className='IC-ami-model-collapse' bordered={false} items={items} />
    </div>
  }

  getAmiParameters = (amiParameters) => {
    const { type } = this.props;
    const { modelName, amiFile } = this.state;
    if (!amiParameters || !amiParameters.length || !modelName || !amiFile) return []
    const tableData = getTableAmiParameters(amiParameters, true, modelName, amiFile, type);
    return tableData;
  }

  //get library detail
  getLibraryDetail = async (libraryId, productType, fileName, modelName, apply) => {
    const { getIBISFolderFileDetail, type, amiParameters } = this.props;
    if (!libraryId || !getIBISFolderFileDetail) return;
    const fileList = await getIBISFolderFileDetail({ libraryId, productType });
    const amiFile = fileList.find(item => item.fileName === fileName);
    let ibisAmi = amiFile && amiFile.ibisAmi ? amiFile.ibisAmi : null;
    ibisAmi = ibisAMIModelParamsParse(ibisAmi);
    const _amiFile = {
      ...amiFile,
      ibisAmi
    }
    const model = ibisAmi && ibisAmi.models ? ibisAmi.models.find(item => item.name === modelName) : null;
    const modelInfo = model && model.algorithmicModels ? modelName.toLowerCase().includes(type) ? model.algorithmicModels.find(item => item.parameterFile.toLowerCase().includes(type)) : model.algorithmicModels[0] : {};
    const parameterFileName = modelInfo ? modelInfo.parameterFile : null;
    const parameterFile = ibisAmi && ibisAmi.amis ? ibisAmi.amis.find(item => item.name === parameterFileName) : null;
    const parameters = parameterFile && parameterFile.parameters ? parameterFile.parameters : null;
    const tableData = getTableAmiParameters(parameters, true, modelName, _amiFile, type);
    this.setState({
      amiFile: _amiFile
    })
    const _amiParameters = tableData.map(item => {
      return {
        name: item.name,
        value: item.value,
        sort: item.sort,
        type: item.type,
        defaultValue: item.Value,
      }
    })
    if (Object.keys(amiParameters).length === 0 || apply) {
      this.props.saveAmiParameters(_amiParameters);
    } else {
      try {
        const originalDataMap = new Map(tableData.map(item => [item.name, item.Value]));
        let _originalAmiParameters = [...amiParameters];
        _originalAmiParameters.forEach(item => {
          if (originalDataMap.has(item.name)) {
            item.defaultValue = originalDataMap.get(item.name) || item.value;
          }
        })
        this.props.saveAmiParameters(_originalAmiParameters);
      } catch (error) {
        console.error(error)
      }
    }
  }

  _editParameterValue = (record, isSelection) => {
    const { amiParameters } = this.props;
    let _amiParameters = [...amiParameters];
    const { name } = record;
    const index = _amiParameters.findIndex(item => item.name === name);
    if (record.valueType === RANGE || (!isSelection && [INTEGER, FLOAT, TAP].includes(record.valueFormat))) {
      const error = amiParameterValueCheck(record.value, record.valueList, record.name, record);
      if (error) {
        this.updateAmiParamError(error)
        return;
      }
    }
    if (!isSelection && record.valueFormat === STRING) {
      //6.0 -> "6.0"
      record.value = `"${record.value}"`;
    }
    _amiParameters[index].value = record.value;
    this.props.saveAmiParameters(_amiParameters);
  }

  updateAmiParamError = (error) => {
    this.setState({
      amiParamError: error
    })
    setTimeout(() => {
      this.setState({
        amiParamError: null
      })
    }, 3000)
  }

  checkAllModel = (e) => {
    const checked = e.target.checked;
    const { multipleModelList, selectedModelList } = this.state;
    let _multipleModelList = [...multipleModelList];
    if (checked) {
      _multipleModelList = selectedModelList.map(item => item.name);
    } else {
      _multipleModelList = [];
    }
    this.setState({
      multipleModelList: _multipleModelList
    })
  }

  render() {
    const { ibisFile, files, selectedModelList, modelNameInput, modelSelector, selectorList, modelTypeList, modelType, loading, libraryId, multipleModelList, amiValidate, amiValidateMessage, amiParamError, modelName, modelList } = this.state;
    const { type, pu, pd, applyAll, applySignal, applyAllMemory, clkMemory, product, powerOff, libType, multipleModels = false,
      createStatus = false, record, isMultiSetup, amiModel, amiModelUse, amiODTValue, dataIndex, ifIbisAdvancedOpen, ibisAdvancedOptions, advancedValidate, advancedValidateMessage, isSev } = this.props;
    const { delayRise, delayFall, tailsRise, tailsFall } = ibisAdvancedOptions
    const _amiModel = amiModel ? amiModel : (libType === IBIS_AMI ? (dataIndex === 'receiver' ? AMI_OBJ_PARAM : IBIS_AMI_FILE_MODEL) : '');
    const text = product === ROCKY ? "Apply to all same-type pins" : "Apply the same model to all the pins in the component";
    const className = product === ROCKY ? "IC-apply-model-types-title" : "IC-apply-model-component-title";
    const baseModelName = multipleModels ? this.getBaseModel(record, libraryId, libType) : null;
    const allModels = selectedModelList.map(item => item.name).filter(item => item !== baseModelName);
    const checkedModels = [...new Set(multipleModelList.filter(item => item !== baseModelName))];
    const allChecked = checkedModels.length && allModels.length === checkedModels.length;
    const indeterminate = checkedModels.length && checkedModels.length < allModels.length;
    const liClassName = multipleModels ? "IC-model-select-allow-checked-li" : "";

    return (
      <Fragment>
        <div className={product === ROCKY ? 'IC-select-dialog clear' : 'IC-select-dialog IC-multi-spice-select-dialog clear'}>
          <div className='IC-select-model-title-item'>{product === ROCKY ? 'File' : 'Model File'}</div>
          {this.getSelectComponents(ibisFile, libraryId, files, libType)}
        </div>
        <Spin spinning={loading}>
          <div className='IC-select-dialog clear'>
            <div className='IC-select-model-title-item'>Model Type</div>
            <Select
              value={modelType || undefined}
              onChange={this.onModelTypeChange}
              size='small'
              showSearch
              className='IC-model-type-select'
              popupClassName='IC-select-model-select-dropdown'
              popupMatchSelectWidth={false}
              allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanModelType(e)} /> }}
              getPopupContainer={() => document.getElementById('root')}
            >
              {modelTypeList && modelTypeList.map((item, i) => (
                <Option
                  key={item}
                  value={item}
                  title={item}
                >
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <div className='IC-select-dialog clear'>
            <div className='IC-select-model-title-item'>Model Selector</div>
            <Select
              value={modelSelector || undefined}
              onChange={this.onSelectModelSelector}
              showSearch
              size='small'
              className='IC-model-type-select'
              popupMatchSelectWidth={false}
              allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.cleanSelector(e)} /> }}
              popupClassName='IC-select-model-select-dropdown'
              getPopupContainer={() => document.getElementById('root')}
            >
              {selectorList && selectorList.map((item) => (
                <Option key={item} value={item} title={item}>{item}</Option>))}
            </Select>
          </div>
          <div className='IC-apply-dialog clear' style={{ marginBottom: 6 }}>
            <div className='IC-model-title-item'>Model Name</div>
            {multipleModels ?
              <TagsInput
                className='IC-model-type-select'
                tagList={multipleModelList}
                changeTagList={this.changeMultipleModeList}
                customInput={<input type='text'
                  placeholder='Search...'
                  className='IC-model-search-input'
                  onChange={this.modelNameInputChange}
                  onBlur={(e) => this.blurModelNameInput(e)}
                />}
              />
              :
              <Input
                size='small'
                onChange={this.modelNameInputChange}
                value={modelNameInput || ""}
                className='IC-select-model-name-input'
                onBlur={(e) => this.blurModelNameInput(e)}
              />}
          </div>
          <div className='IC-select-model-name-box clear'>
            <div className='IC-model-select-ul'>
              {multipleModels ? <span
                className={allChecked ? `IC-model-select-li IC-model-select-li-selected` : `IC-model-select-li`}
                key={"All"}
                title={"All Models"}
              >
                <label className='IC-model-label'>
                  <Checkbox
                    className='IC-model-checkbox-group'
                    onChange={(e) => this.checkAllModel(e)}
                    checked={allChecked}
                    indeterminate={indeterminate}
                  >
                    All Models
                  </Checkbox>
                </label>
              </span> : null}
              {selectedModelList && selectedModelList.map(item => {
                const checked = multipleModels ? multipleModelList.includes(item.name) : item.name === modelNameInput
                const disabledClassName = baseModelName && baseModelName === item.name ? "IC-model-select-li-selected-disable" : false;
                return <span
                  className={checked ? `IC-model-select-li IC-model-select-li-selected ${disabledClassName} ${liClassName}` : `IC-model-select-li ${disabledClassName} ${liClassName}`}
                  key={item.name}
                  title={item.info}
                  onClick={!disabledClassName ? (e) => this.onModelNameChange(e, item) : null} >
                  {multipleModels ?
                    <label className='IC-model-label'>
                      <Checkbox
                        className='IC-model-checkbox'
                        checked={checked}
                        onChange={!disabledClassName ? (e) => this.updateMultipleModeList(e, item.name) : null}
                        disabled={disabledClassName ? true : false}
                      />
                      {/*  <input
                        type="checkbox"
                        className='IC-model-checkbox'
                        onClick={!disabledClassName ? (e) => this.updateMultipleModeList(e, item.name) : null}
                        readOnly
                        checked={checked}
                        disabled={disabledClassName ? true : false}
                      /> */}
                      <span>{item.name}</span>{item.description ? `,\xa0\xa0\xa0\xa0${item.description}` : ""}{disabledClassName ? " (Base)" : ""}
                    </label>
                    : <Fragment>
                      <span>{item.name}</span>{item.description ? `,\xa0\xa0\xa0\xa0${item.description}` : ""}
                    </Fragment>}
                </span>
              })}
            </div>
          </div>
          {type === 'tx' && <Fragment>
            <div className='IC-select-power-dialog clear' style={{ paddingLeft: 2 }}>
              <div className='IC-model-title-item'>Power Off</div>
              <Switch
                size="small"
                className="IC-power-off-switch"
                checked={powerOff === '1'}
                onChange={(checked) => this.props.powerOffChange(checked, modelList)}
              />
            </div>
            {powerOff === "1" ? <Fragment> <div className='IC-select-power-dialog clear'>
              <div className='IC-model-title-item'>V_PU</div>
              <Input
                disabled={powerOff === '0'}
                size='small'
                onChange={(e) => { this.props.changeVoltage(e, 'pu') }}
                value={(powerOff === '1' && pu) || ""}
                className='IC-select-model-input'
                onPressEnter={e => this.handleKeyDown(e)}
                addonAfter={this.selectUnit()}
              />
            </div>
              <div className='IC-select-power-dialog clear'>
                <div className='IC-model-title-item'>V_PD</div>
                <Input
                  disabled={powerOff === '0'}
                  size='small'
                  onChange={(e) => { this.props.changeVoltage(e, 'pd') }}
                  value={(powerOff === '1' && pd) || ""}
                  className='IC-select-model-input'
                  onPressEnter={e => this.handleKeyDown(e)}
                  addonAfter={this.selectUnit()}
                />
              </div></Fragment> : null}
          </Fragment>}
        </Spin>
        {product === ROCKY && libType === IBIS_AMI && <div className='IC-select-dialog IC-select-dialog-ami-model clear'>
          <div className='IC-select-model-title-item IC-select-ami-model-instantiation'>AMI Model Instantiation</div>
          <Select
            value={_amiModel}
            onChange={(e) => this.onAmiModelSelector(e)}
            size='small'
            className='IC-model-type-select IC-ami-model-instantiation-select'
            popupMatchSelectWidth={false}
            popupClassName='IC-select-model-select-dropdown'
            getPopupContainer={() => document.getElementById('root')}
          >
            <Option key={IBIS_AMI_FILE_MODEL} value={IBIS_AMI_FILE_MODEL}>IBIS_AMI_FILE / IBIS_AMI_MODEL</Option>
            <Option key={AMI_OBJ_PARAM} value={AMI_OBJ_PARAM}>AMI_OBJ / AMI_PARAM</Option>
          </Select>
        </div>
        }

        {product === ROCKY && libType === IBIS_AMI && _amiModel === AMI_OBJ_PARAM && <div className='IC-select-dialog IC-select-dialog-ami-model clear'>
          <RadioGroup onChange={this.onAmiModelUseSelector} value={amiModelUse}>
            <Radio key={IBIS_ODT} value={IBIS_ODT}>Use V/I table in .ibs</Radio>
            <Radio key={CUSTOM_ODT} value={CUSTOM_ODT}>
              Use custom {type === 'rx' ? 'ODT' : 'Rout'}
              <Input size='small' addonAfter="Ohm" value={amiODTValue} className='IC-select-model-input-odt'
                onChange={(e) => this.changeODTValue(e)} disabled={amiModelUse !== CUSTOM_ODT}
                onBlur={(e) => this.ODTBlur(e, 'ODTValue')} />
            </Radio>
          </RadioGroup>
        </div>
        }

        {product === ROCKY && libType === IBIS && !isSev &&
          <div className="IC-advanced-wrap">
            <div className="IC-select-dialog IC-model-select-advanced clear">
              <div className='IC-model-select-advanced-title'>Advanced Options</div>
              <Switch
                size="small"
                className="IC-advanced-off-switch"
                checked={ifIbisAdvancedOpen === '1'}
                onChange={this.props.advanceOffChange}
              />
            </div>
            {ifIbisAdvancedOpen === '1' ?
              <div className="IC-model-select-advanced-options clear">
                <div className='IC-model-select-advanced-item'>
                  <div className='IC-model-select-advanced-item-title'>Remove initial delay from Rising Waveform</div>
                  <RadioGroup onChange={(e) => this.onAdvOptionDefaultChange(e, DELAY_RISING)} value={delayRise && delayRise.isDefault ? ADV_DEFAULT : ADV_CUSTOM}>
                    <Radio value={ADV_DEFAULT}>Default</Radio>
                    <Radio value={ADV_CUSTOM}>
                    </Radio>
                    <Input size='small' value={delayRise && delayRise.value ? delayRise.value : ''}
                      onChange={(e) => this.props.onAdvOptionValueChange(e, DELAY_RISING)}
                      onBlur={(e) => this.props.advancedValueCheck()}
                      disabled={delayRise && delayRise.isDefault ? true : false}
                      className='IC-model-select-advanced-item-input'
                      addonAfter={this.selectAdvOption(DELAY_RISING, delayRise && delayRise.unit ? delayRise.unit : 'ps')} />
                  </RadioGroup>
                </div>
                <div className='IC-model-select-advanced-item'>
                  <div className='IC-model-select-advanced-item-title'> Remove initial delay from Falling Waveform</div>
                  <RadioGroup onChange={(e) => this.onAdvOptionDefaultChange(e, DELAY_FALLING)} value={delayFall && delayFall.isDefault ? ADV_DEFAULT : ADV_CUSTOM}>
                    <Radio value={ADV_DEFAULT}>Default</Radio>
                    <Radio value={ADV_CUSTOM}>
                    </Radio>
                    <Input size='small' value={delayFall && delayFall.value ? delayFall.value : ''}
                      onChange={(e) => this.props.onAdvOptionValueChange(e, DELAY_FALLING)}
                      onBlur={(e) => this.props.advancedValueCheck()}
                      disabled={delayFall && delayFall.isDefault ? true : false}
                      className='IC-model-select-advanced-item-input'
                      addonAfter={this.selectAdvOption(DELAY_FALLING, delayFall && delayFall.unit ? delayFall.unit : 'ps')} />
                  </RadioGroup>
                </div>
                <div className='IC-model-select-advanced-item'>
                  <div className='IC-model-select-advanced-item-title'>Remove flat tails from Rising Waveform</div>
                  <RadioGroup onChange={(e) => this.onAdvOptionDefaultChange(e, TAILS_RISING)} value={tailsRise && tailsRise.isDefault ? ADV_DEFAULT : ADV_CUSTOM}>
                    <Radio value={ADV_DEFAULT}>Default</Radio>
                    <Radio value={ADV_CUSTOM}>
                    </Radio>
                    <Input size='small' value={tailsRise && tailsRise.value ? tailsRise.value : ''}
                      onChange={(e) => this.props.onAdvOptionValueChange(e, TAILS_RISING)}
                      onBlur={(e) => this.props.advancedValueCheck()}
                      disabled={tailsRise && tailsRise.isDefault ? true : false}
                      className='IC-model-select-advanced-item-input'
                      addonAfter={this.selectAdvOption(TAILS_RISING, tailsRise && tailsRise.unit ? tailsRise.unit : 'ps')} />
                  </RadioGroup>
                </div>
                <div className='IC-model-select-advanced-item'>
                  <div className='IC-model-select-advanced-item-title'>Remove flat tails from Falling Waveform</div>
                  <RadioGroup onChange={(e) => this.onAdvOptionDefaultChange(e, TAILS_FALLING)} value={tailsFall && tailsFall.isDefault ? ADV_DEFAULT : ADV_CUSTOM}>
                    <Radio value={ADV_DEFAULT}>Default</Radio>
                    <Radio value={ADV_CUSTOM}>
                    </Radio>
                    <Input size='small' value={tailsFall && tailsFall.value ? tailsFall.value : ''}
                      onChange={(e) => this.props.onAdvOptionValueChange(e, TAILS_FALLING)}
                      onBlur={(e) => this.props.advancedValueCheck()}
                      disabled={tailsFall && tailsFall.isDefault ? true : false}
                      className='IC-model-select-advanced-item-input'
                      addonAfter={this.selectAdvOption(TAILS_FALLING, tailsFall && tailsFall.unit ? tailsFall.unit : 'ps')} />
                  </RadioGroup>
                </div>
              </div> : null}
          </div>
        }
        {/* {product === ROCKY && libType === IBIS_AMI && <div className='IC-select-dialog IC-select-dialog-ami-model IC-select-dialog-ami-ignore-bits clear'>
          Ignore Bits
          <Input size='small' value={amiIgnoreBits} className='IC-select-model-input-odt'
            onChange={(e) => this.changeIgnoreBits(e)} disabled={!amiIgnoreBitsStatus} />
          <Switch className='IC-select-dialog-ami-ignore-bits-switch' size="small" checked={amiIgnoreBitsStatus} onChange={this.changeIgnoreBitsStatus} />
        </div>
        } */}

        {product === ROCKY && libType === IBIS_AMI && modelName ? this.modelParametersRender() : null
        }

        {product === SIERRA && libType === IBIS && <Fragment> {
          !multipleModels ?
            <Fragment>
              {!isMultiSetup ? <div className='IC-apply-dialog clear'>
                <div className={`IC-apply-model-title IC-apply-signal-title`}>Apply model to all the component pin in the same signal</div>
                <Checkbox
                  className='IC-model-apply-checkbox'
                  checked={applySignal}
                  onChange={(e) => this.props.onApplySignalChange(e)}
                />
              </div> : null}
              <div className='IC-apply-dialog clear'>
                <div className={`IC-apply-model-title ${className}`}>{text}</div>
                <Checkbox
                  className='IC-model-apply-checkbox'
                  checked={applyAll}
                  onChange={(e) => this.props.onApplyChange(e)}
                />
              </div>
            </Fragment>
            :
            <div className='rlc-sweep-create'>
              <Button type="primary" className='rlc-sweep-create-btn' loading={createStatus} onClick={() => this.createExpsByModel()}>Create</Button>
            </div>}
        </Fragment>
        }
        {product === ROCKY ?
          <div className='IC-apply-dialog clear'>
            <div className={`IC-apply-model-title ${className}`}>{text}</div>
            <Checkbox
              className='IC-model-apply-checkbox'
              checked={applyAll}
              onChange={(e) => this.props.onApplyChange(e)}
            />
          </div> : null
        }
        {product === ROCKY && clkMemory && applyAll ?
          <div className='IC-apply-sub-dialog clear'>
            <span className='IC-sub-apply-box'></span>
            <div className='IC-apply-memory-model-title'>Apply to all same-type pins in components of the same part</div>
            <Checkbox
              className='IC-model-apply-checkbox'
              checked={applyAllMemory}
              onChange={(e) => this.props.onApplyMemoryChange(e)}
            />
          </div>
          : null}

        {product === ROCKY && libType === IBIS_AMI && !amiValidate && <span className='odt-value-validate'>{amiValidateMessage}</span>}
        {product === ROCKY && libType === IBIS && !advancedValidate && <span className='odt-value-validate'>{advancedValidateMessage}</span>}
      </Fragment>
    );
  }
}

export default ModelIbis;