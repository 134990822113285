import http from './http';

export function uploadLicense(file) {
  let formData = new FormData();
  formData.append('file', new File([file], 'license.json'));
  return http.post('/license/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getLicenses() {
  return http.get('/license');
}

export function getLicenseFeatures() {
  return http.get('/license/features');
}

export function licenseHeartbeat(featureName) {
  let formData = new FormData();
  formData.append('featureName', featureName);
  return http.post('/license/heartbeat', formData)
}

export function licenseCheckout(featureName) {
  let formData = new FormData();
  formData.append('featureName', featureName);
  return http.post('/license/checkout', formData)
}

export function licenseUsage() {
  return http.get('/license/usage');
}

export function licenseCheckin(featureName) {
  return http.delete('/license/checkin', { params: { featureName } })
}

export function licenseUsageHistory(params) {
  return http.get('/license/history', { params: { ...params } })
}

export function licenseMode() {
  return http.get('/license/mode');
}

export function licenseSwitch({ mode, url }) {
  return http.post(`/license/switch?mode=${mode}&url=${url}`);
}

export function checkInLicenseDirectly(checkoutId) {
  return http.delete(`/license/checkinDirectly/${checkoutId}`)
}