import http from '../http';
import JSZip from 'jszip';
import { getTransSwitch, getVendor } from '../Design/design';

/**
 * upload design in rocky project
 * 2020/06/02
 * @export
 * @param  files(binary)
 * @param  isZip(true/false)
 * @param  name(design name)
 * @param  projectId(string)
 * @param  type(string)
 * @param  vendor(design vendor)
 * @param  metadata(compress progress)
 * @param  config(uplaod progress)
 * 
 * @returns
 */
export function uploadPCB({ files, isZip, name, projectId, type, vendor, metadata, config }) {
  const _vendor = getVendor(vendor);
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    if (isZip) {
      for (const file of files) {
        formData.append('file', file);
      }
      formData.append('name', name);
      formData.append('projectId', projectId);
      formData.append('type', type);
      formData.append('vendor', _vendor);
      formData.append('transSwitch', getTransSwitch(vendor));
      resolve(http.post('/design/rocky', formData, config, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      )
    } else {
      let zip = new JSZip();
      let fileObj;
      if (files.length === 1) {
        zip.file(files[0].name, files[0]);
      } else {
        for (const file of files) {
          zip.file(file.webkitRelativePath, file);
        }
      }
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, metadata).then(blob => {
        fileObj = new File([blob], "design.zip");
        formData.append('file', fileObj);
        formData.append('name', name);
        formData.append('projectId', projectId);
        formData.append('type', type);
        formData.append('vendor', _vendor);
        formData.append('transSwitch', getTransSwitch(vendor));
        resolve(http.post('/design/rocky', formData, config, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
        )
      })
    }
  })
}

export function getUnitFromLayoutDB(pcbId) {
  return http.get(`/design/rocky/${pcbId}/unit`);
}

/**
 * Edit/Update pre layout
 *
 * @export
 * @param {*} prelayout{id, projectid, pcbName, designVersion, version, content}
 */
export function updateRockyPreLayout({ preLayout, identify }) {
  return http.post(`/design/rocky/pre-layout?identify=${identify}`, preLayout);
}

export function getRockyPreLayoutDetail({ id }) {
  return http.get(`/design/rocky/pre-layout/detail?id=${id}`);
}

export function updateRockyModelPreLayout({ preLayout }) {
  return http.post(`/design/rocky/auto/pre-layout`, preLayout)
}