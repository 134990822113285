import React, { PureComponent } from 'react';
import { VERIFICATION, RESULT, PCB, EXPERIMENTS, EXPERIMENTS_RESULT, PCB_PRE_LAYOUT, MULTI_INTERFACE_SETUP } from '@/constants/treeConstants';
import PinToPinSetting from './PinToPinSetting';
import Result from '../result';
import TopBar from './PinToPinSetting/TopBar';
import { topBarResize } from '@/services/helper/topBarResize';
import Sweeping from './Sweeping';
import PCBLayout from './pcbLayout';
import ContentLayoutTB from '@/components/ContentLayout/layoutTB';
import { PCB_TOP_BOTTOM, TOP_BOTTOM_LAYOUT } from '../../../constants/layoutConstants';
import { connect } from 'react-redux';
import MultiInterfaceSetup from '../MultiInterfaceSetup';

class LayoutTB extends PureComponent {

  getBottomSetup = (width) => {
    const { viewList, siderWidth } = this.props;
    const views = viewList.filter(item => ![PCB, PCB_PRE_LAYOUT].includes(item));
    let content = null, className = '';
    switch (views[0]) {
      case RESULT:
      case EXPERIMENTS_RESULT:
        content = <Result siderLeftWidth={siderWidth} viewList={views} />
        className = 'sierra-tb-result-main';
        break;
      case VERIFICATION:
        content = <div id='sierra-content-main'>
          <PinToPinSetting />
        </div>
        className = 'sierra-tb-setting-main';
        break;
      case EXPERIMENTS:
        content = <Sweeping />
        className = 'sierra-tb-setting-main';
        break;
      case MULTI_INTERFACE_SETUP:
        content = <div id='sierra-content-main'>
          <MultiInterfaceSetup />
        </div>
        className = 'sierra-tb-setting-main';
        break;
      default: return null;
    }
    setTimeout(() => {
      this.topBarResize();
    }, 100)
    return <div
      className={className}
      style={{ width }}
    >
      {content}
    </div>
  }

  topBarResize = () => {
    topBarResize('sierra-top-bar', this.props.getTopBarWidth());
    topBarResize('sierra-pre-layout-top-bar', this.props.getTopBarWidth());
  }

  getTop = (height) => {
    const { leftWidth } = this.props;
    return <PCBLayout siderWidth={leftWidth} bottomHeight={height} topBarResize={this.topBarResize} />
  }

  topBar = (width) => {
    return <TopBar width={width} />
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PCB_PRE_LAYOUT].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) {
      return 1;
    } else if (pcbs.length > 1 && pcbLayout === PCB_TOP_BOTTOM && layout === TOP_BOTTOM_LAYOUT) {
      return 3;
    } else {
      return 2;
    }
  }

  render() {
    const splitSize = this._getSplitSize();
    return (
      <ContentLayoutTB
        {...this.props}
        bottomClassName={"sierra-content-bottom"}
        topBarResize={this.topBarResize}
        getTop={this.getTop}
        getBottomSetup={this.getBottomSetup}
        topBar={this.topBar}
        splitSize={splitSize}
      />
    )
  }
};

const mapState = (state) => {
  const { SierraReducer } = state;
  const { project: { layout, selectedKeys, pcbLayout } } = SierraReducer;
  return {
    layout,
    selectedKeys,
    pcbLayout
  };
}

export default connect(mapState, null)(LayoutTB);
