import Polygon from "./Polygon";
function Rectangle({ mHeight, mWidth, mX, mY }) {
  this.mHeight = mHeight;
  this.mWidth = mWidth;
  this.mX = mX;
  this.mY = mY;
}

Rectangle.prototype.toPoints = function (distance, pointDensity) {
  // TODO
  return this.toPolygon().toPoints(distance, pointDensity);
};

Rectangle.prototype.toPolygon = function () {
  const { mHeight, mWidth, mX, mY } = this;
  const h = mHeight / 2;
  const w = mWidth / 2;

  const mVertices = [
    new Vertice(-w, h),
    new Vertice(w, h),
    new Vertice(w, -h),
    new Vertice(-w, -h),
    new Vertice(-w, h)
  ];
  return new Polygon({ mVertices, mX, mY });
}

function Vertice(mX, mY) {
  this.mGeomType = 'Pnt';
  this.mX = mX;
  this.mY = mY;
}
export default Rectangle;