import { calcCirclePoints, keepTwoDecimal } from '../math';
function Circle({ mDiameter, mX, mY }) {
  this.mDiameter = mDiameter;
  this.mX = mX;
  this.mY = mY;
}

Circle.prototype.toPoints = function (distance, pointDensity) {
  const { mDiameter, mX, mY } = this;
  let _mDiameter = distance ? 2 * distance + mDiameter : mDiameter;
  const r = _mDiameter / 2;
  let angle = 0;
  let arr = [];
  if (r === 0) {
    return [[mX, mY]]
  }
  const times = Math.ceil(Math.PI * r * 2 / pointDensity);
  const dAngle = 360 / times;
  for (let i = 0; i < times; i++) {
    const p = calcCirclePoints({ x0: mX, y0: mY, r, angle })
    if (isNaN(p.x) || isNaN(p.y)) {
      continue;
    }
    arr.push([p.x, p.y])
    angle += dAngle;
  };
  return arr.map(d => [keepTwoDecimal(d[0]), keepTwoDecimal(d[1])]);
};

export default Circle;