import _ from 'lodash';
import { getPinLocation } from '../LayoutCanvas/PortsCanvas';
import _LayoutData from '../data/LayoutData';

function getPortData(designData, chip, PowerNets, ReferenceNets, SignalNets = [], notDisplaySignal) {
  const newData = _.cloneDeep(designData);
  SignalNets = SignalNets ? SignalNets : [];

  // filter mNetManager
  const netObj = netManagerHandle(newData, chip, PowerNets, ReferenceNets, SignalNets, notDisplaySignal);
  if (netObj.mNetManager) {
    newData.mNetManager = netObj.mNetManager;
  }
  const { pinList, powerPins, referencePins, signalPins } = netObj;

  // filter mLayerMgr
  const layerObj = mLayerMgrHandle(newData, chip, PowerNets, ReferenceNets, pinList, SignalNets, { limit: 500, referencePins, powerPins });
  const { partList, layerList, showPinList } = layerObj;
  newData.mLayerMgr = layerObj.mLayerMgr;

  // filter mPartMgr
  newData.mPartMgr = mPartMgrHandle(newData, pinList, partList);
  return { data: newData, layerList, powerPins, referencePins, signalPins, showPinList };
}

function netManagerHandle(data, chip, PowerNets, ReferenceNets, SignalNets, notDisplaySignal) {
  const mNetManager = _.cloneDeep(data.mNetManager);
  const indexList = [], powerPins = {}, referencePins = {}, pinList = [], signalPins = {};
  if (mNetManager && mNetManager.mNetList) {
    let displayNetList = notDisplaySignal ? [] : [...PowerNets, ...SignalNets]
    mNetManager.mNetList.mKeys.forEach((item, index) => {
      if ([...displayNetList].includes(item)) {
        indexList.push(index);
      }
    })
    mNetManager.mNetList.mKeys = mNetManager.mNetList.mKeys.filter((key, index) => indexList.includes(index));
    mNetManager.mNetList.mVals.forEach(item => {
      if (PowerNets.includes(item.mName)) {
        const pins = item.mPinList.filter(pin => pin.mCompName === chip).map(p => p.mPinNum);
        pinList.push(...pins);
        powerPins[item.mName] = [...pins];
      } else if (ReferenceNets.includes(item.mName)) {
        const pins = item.mPinList.filter(pin => pin.mCompName === chip).map(p => p.mPinNum);
        pinList.push(...pins);
        referencePins[item.mName] = [...pins];
      } else if (SignalNets.includes(item.mName)) {
        const pins = item.mPinList.filter(pin => pin.mCompName === chip).map(p => p.mPinNum);
        pinList.push(...pins);
        signalPins[item.mName] = [...pins];
      }
    })
    // const mVals = mNetManager.mNetList.mVals.filter((key, index) => indexList.includes(index));
    // mNetManager.mNetList.mVals = mVals;
    mNetManager.mNetList.mKeys = [];
    mNetManager.mNetList.mVals = [];
    return { mNetManager, pinList, powerPins, referencePins, signalPins }
  }
  return { mNetManager: null, powerPins: {}, referencePins: {}, pinList: [], signalPins: {} }
}

function mLayerMgrHandle(designData, chip, PowerNets, ReferenceNets, pinList, SignalNets, pinLimit = {}) {
  const newMatalLayers = [], layerList = [], partList = [];
  const CompList = [chip, ...PowerNets, ...ReferenceNets, ...SignalNets];
  const { limit = 500, referencePins, powerPins } = pinLimit;
  let showPinList = [];
  const mLayerMgr = _.cloneDeep(designData.mLayerMgr);
  for (let layer of mLayerMgr.mMetalLayers) {
    const mComponentLayer = layer.mComponentLayer;
    const mNetGeomList = layer.mNetGeomList;
    if (mComponentLayer && mComponentLayer.mComponents) {
      const mComponents = mComponentLayer.mComponents.filter(item => CompList.includes(item.mName));
      mComponents.forEach(item => {
        if (item.mName === chip) {
          item.mPinsLocationList = item.mPinsLocationList.filter(pin => pinList.includes(pin.pinNumber));
          if (item.mPinsLocationList.length > 2 * limit) {
            const keepPowerPins = PowerNets.map(net => item.mPinsLocationList.filter(pin => powerPins[net].includes(pin.pinNumber)).slice(0, Math.floor(limit / PowerNets.length))).flat(2)
            const keepReferencePins = ReferenceNets.map(net => item.mPinsLocationList.filter(pin => referencePins[net].includes(pin.pinNumber)).slice(0, Math.floor(limit / ReferenceNets.length))).flat(2)
            showPinList.push(...[...keepPowerPins, ...keepReferencePins].map(item => ({ name: chip, pin: item.pinNumber })))
          }
        }
        partList.push(item.mPart.mInfo.mPartName);
        item.mPart.mPinList = item.mPart.mPinList.filter(item => pinList.includes(item.mNumber))
      })
      mComponentLayer.mComponents = mComponents
      if (mComponentLayer.mComponents.length) {
        layerList.push(mComponentLayer.mName);
        layerList.push(layer.mName);
        let indexList = [];
        mNetGeomList.mKeys.forEach((item, index) => {
          if (CompList.includes(item)) {
            indexList.push(index);
          }
        })
        // mNetGeomList.mKeys = mNetGeomList.mKeys.filter((item, index) => indexList.includes(index));
        // mNetGeomList.mVals = mNetGeomList.mVals.filter((item, index) => indexList.includes(index));
        mNetGeomList.mKeys = [];
        mNetGeomList.mVals = [];
        newMatalLayers.push(layer)
      }
    }
  }
  mLayerMgr.mMetalLayers = newMatalLayers;

  const newNameMap = _.cloneDeep(mLayerMgr.mLayerIDNameMap);
  let indexList = [];
  mLayerMgr.mLayerIDNameMap.mVals.forEach((item, index) => {
    if (layerList.includes(item)) {
      indexList.push(index);
    }
  })
  newNameMap.mVals = mLayerMgr.mLayerIDNameMap.mVals.filter((item, index) => indexList.includes(index));
  newNameMap.mKeys = mLayerMgr.mLayerIDNameMap.mKeys.filter((item, index) => indexList.includes(index));
  mLayerMgr.mLayerIDNameMap = newNameMap;
  return { mLayerMgr, layerList, partList, showPinList }
}

function mPartMgrHandle(data, pinList, partList) {
  const mPartMgr = _.cloneDeep(data.mPartMgr);
  const newPartList = [], newFootList = [], mFutprtList = [];
  mPartMgr.mPartList.forEach(item => {
    if (partList.includes(item.mInfo.mPartName)) {
      item.mPinList = item.mPinList.filter(pin => pinList.includes(pin.mNumber));
      mFutprtList.push(...item.mFutprtList.mValues)
      newPartList.push(item);
    }
  })
  mPartMgr.mPartList = newPartList;
  mPartMgr.mFootprintList.forEach(item => {
    if (mFutprtList.includes(item.mID)) {
      const mMetalLayerList = item.mMetalLayerList;
      mMetalLayerList.forEach(m => {
        m.mPadList = m.mPadList.filter(pad => pinList.includes(pad.mPadID));
      })
      item.mMetalLayerList = mMetalLayerList;
      newFootList.push(item)
    }
  })
  mPartMgr.mFootprintList = newFootList;
  return mPartMgr
}

function autoGrouping({ pinInfo = [], rate = 5, extra = false }) {
  const groups = [];
  let pinSort = _.cloneDeep(pinInfo);
  // for pin group
  while (pinSort.length) {
    let groupNumber = [];
    let pinMain = pinSort.shift();
    groupNumber.push({ ...pinMain });
    if (!pinSort.length) {
      groups.push([pinMain].map(item => ({ ...item, groupIndex: groups.length + 1 })));
    } else {
      let aroundPins = getPinsByDistance({ pinMains: [pinMain], pinList: pinSort, rate });
      let aroundInfo = pinSort.filter(item => aroundPins.includes(item.pinNumber));
      groupNumber.push(...aroundInfo);
      if (extra && aroundPins.length) {
        pinSort = pinSort.filter(item => !aroundPins.includes(item.pinNumber));
        while (aroundPins.length && pinSort.length) {
          let aroundPins = getPinsByDistance({ pinMains: [...aroundInfo], pinList: pinSort, rate });
          if (!aroundPins.length) break;
          aroundInfo = pinSort.filter(item => aroundPins.includes(item.pinNumber));
          groupNumber.push(...aroundInfo);
          pinSort = pinSort.filter(item => !aroundPins.includes(item.pinNumber));
          if (!pinSort.length) break;
        }
      }

      groups.push(groupNumber.map(item => ({ ...item, groupIndex: groups.length + 1 })));
    }
  }

  return groups
}

/**
 * 
 * @param {array} pinMains [{pinInfo}]
 * @param {array} pinList [{pinInfo}]
 * @param {number} rate expand range
 * @return {array} distGroup ? [{pinInfo}] : [pinNumber]
 */

function getPinsByDistance({ pinMains, pinList, rate = 5, distGroup = false }) {
  let _pinList = _.cloneDeep(pinList);
  let pins = [], distGroups = [];
  for (let pinMain of pinMains) {
    // group pin rate * r
    const { xc, yc, r } = pinMain;
    const x = Number(xc), y = Number(yc);
    let _location = _pinList.map(item => {
      let distance = Infinity;
      const _x = Number(item.xc), _y = Number(item.yc);
      distance = Math.sqrt(Math.pow(x - _x, 2) + Math.pow(y - _y, 2))
      return { distance, ...item }
    }).sort((a, b) => a.distance > b.distance ? 1 : -1);
    if (distGroup) {
      distGroups.push(_location.map(l => ({ distance: l.distance, pinNumber: l.pinNumber })));
    } else {
      pins.push(..._location.filter(item => item.distance < rate * r).map(item => item.pinNumber));
      _pinList = _pinList.filter(item => !pins.includes(item.pinNumber));
    }
  }
  return distGroup ? distGroups : pins
}

function getReferenceArea(powerGroup, refeInfo, rate) {
  let splitPin = [], refeGroups = [];

  let refePins = _.cloneDeep(refeInfo)

  for (let power of powerGroup) {
    const xPower = power.sort((a, b) => a.xc > b.xc ? 1 : -1);
    const xMin = xPower[0].xc - xPower[0].r * rate, xMax = xPower[xPower.length - 1].xc + xPower[xPower.length - 1].r * rate;
    const yPower = power.sort((a, b) => a.yc > b.yc ? 1 : -1);
    const yMin = yPower[0].yc - yPower[0].r * rate, yMax = yPower[yPower.length - 1].yc + yPower[yPower.length - 1].r * rate;
    // find midpoint
    const xc = (xMin + xMax) / 2, yc = (yMin + yMax) / 2;
    let main = { xc: xc, yc: yc, r: xMax - xc > yMax - yc ? xMax - xc : yMax - yc }
    let refeGroup = getPinsByDistance({ pinMains: [main], pinList: refePins, rate: 1 });
    let _rate = 2;
    while (!refeGroup.length && _rate < 5) {
      refeGroup = getPinsByDistance({ pinMains: [main], pinList: refePins, rate: _rate });
      _rate = _rate + 1;
    }
    refeGroup = refeGroup.filter(pin => !splitPin.includes(pin))
    splitPin.push(...refeGroup);
    refeGroups.push(refeInfo.filter(pin => refeGroup.includes(pin.pinNumber)));
  }
  return refeGroups;
}

function addNewPinToUsedGroup(pins, usedRefe) {
  let _usedRefe = [...usedRefe], findIndexs = [], exist = false;
  for (let pin of pins) {
    let findIndex = _usedRefe.findIndex(pinList => pinList.includes(pin));
    if (findIndex > -1) {
      exist = true;
      if (_usedRefe[findIndex].length >= 2) {
        findIndex = 'not add new';
      }
    }
    findIndexs.push(findIndex);
  }
  if (!exist) {
    _usedRefe.push(pins);
  } else {
    let newGroup = [], isDeleted = [];
    findIndexs.forEach((find, index) => {
      if (find !== 'not add new') {
        const _find = find - isDeleted.length;
        if (_find > -1) {
          if (!isDeleted.includes(_find)) {
            newGroup.push(..._usedRefe[_find]);
            _usedRefe.splice(_find, 1);
            isDeleted.push(_find)
          }
        } else {
          newGroup.push(pins[index])
        }
      }
    })
    newGroup = [...new Set(newGroup)];
    if (newGroup.length) {
      _usedRefe.push(newGroup);
    }
  }
  return _usedRefe;
}

function autoSplitRefeByPower(powerInfo, refeInfo, refeGroup, findPinLocation = true) {
  // get location
  let _powerInfo = powerInfo.map(pin => {
    const info = findPinLocation ? getPinLocation(pin) : undefined;
    if (!info) return { xc: null, yc: null, r: null, ...pin }
    const { x, y, r } = info;
    return { ...pin, xc: x, yc: y, r }
  })
  let _refeInfo = refeInfo.map(pin => {
    const info = findPinLocation ? getPinLocation(pin) : undefined;
    if (!info) return { xc: null, yc: null, r: null, ...pin }
    const { x, y, r } = info;
    return { ...pin, xc: x, yc: y, r }
  })

  const powerGroup = autoGrouping({ pinInfo: _powerInfo, extra: true, rate: 10 });
  for (let i = 0; i < powerGroup.length; i++) {
    const refePins = getReferenceArea([powerGroup[i]], _refeInfo, 15)[0];
    let usedRefe = [...refeGroup];
    if (!refePins.length) {
      powerGroup[i].forEach(pin => pin.refePins = []);
      continue;
    }
    const eachLocation = getPinsByDistance({ pinMains: powerGroup[i], pinList: refePins, distGroup: true });
    powerGroup[i].forEach((pin, index) => {
      const curLocation = eachLocation[index];
      const minDistance = curLocation[0].distance;
      const minPins = curLocation.filter(item => item.distance === minDistance).map(item => item.pinNumber);
      pin.minPin = minPins[0];
      usedRefe = addNewPinToUsedGroup(minPins, usedRefe);
    })
    powerGroup[i].forEach((pin, index) => {
      pin.refePins = usedRefe.find(pinList => pinList.includes(pin.minPin));
    })
    const allUsed = usedRefe.flat(2);
    _refeInfo = _refeInfo.filter(item => !allUsed.includes(item.pinNumber))
  }
  const _powerGroup = powerGroup.flat(2);
  return _powerGroup;
}

function getDiePortsByPloc({
  packageId,
  component,
  PowerNets,
  ReferenceNets,
  cpmPins
}) {
  const DesignData = _LayoutData.getLayout(packageId);
  const filterData = getPortData(DesignData, component, PowerNets, ReferenceNets)
  const { powerPins, referencePins, data: PCBData } = filterData;
  const powers = Object.values(powerPins).flat(2), refe = Object.values(referencePins).flat(2);
  const pinsInfo = PCBData.getComponent(component).mPinsLocationList.map(pin => ({ ...pin, ...pin.mLocation }));
  const powerGroups = {}
  const referenceGroups = {}
  cpmPins.forEach(item => {
    const { net, csmNet, pin, portName } = item
    if (PowerNets.includes(net) && powers.includes(pin)) {
      if (!powerGroups[portName]) {
        powerGroups[portName] = []
      }
      powerGroups[portName].push(pin)
    } else if (ReferenceNets.includes(net) && refe.includes(pin)) {
      if (!referenceGroups[portName]) {
        referenceGroups[portName] = []
      }
      referenceGroups[portName].push(pin)
    }
  });

  const ports = [];
  let portId = 1;
  const pinMap = new Map(pinsInfo.map(pin => [pin.pin, { xc: Number(pin.xc), yc: Number(pin.yc) }]));//key: pin, value: {xc, yc}
  for (const powerGroupName in powerGroups) {
    const powerPins = powerGroups[powerGroupName];
    let nearestReferenceGroup = null;
    let minDistance = Infinity;
    for (const referenceGroupName in referenceGroups) {
      const referencePins = referenceGroups[referenceGroupName];
      for (const powerPin of powerPins) {
        const { xc: powerX, yc: powerY } = pinMap.get(powerPin);
        for (const referencePin of referencePins) {
          const { xc: refX, yc: refY } = pinMap.get(referencePin);
          const distance = Math.sqrt(Math.pow(powerX - refX, 2) + Math.pow(powerY - refY, 2))
          if (distance < minDistance) {
            minDistance = distance;
            nearestReferenceGroup = referenceGroupName;
          }
        }
      }
    }
    ports.push({
      port: portId++,
      portName: powerGroupName,
      powerPins: [...powerPins],
      referencePins: [...referenceGroups[nearestReferenceGroup]]
    });
  }
  return ports;
}

export {
  getPortData,
  autoGrouping,
  autoSplitRefeByPower,
  getDiePortsByPloc
}