import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import IconCollection from '@/components/TreeIconCollection';
import EditableTable from "@/components/EditableTable";
import InputOrSelect from './InputOrSelect';
import { InfoCircleOutlined, PlusCircleOutlined, SettingOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Spin, Input, Divider, message, Radio } from 'antd';
import {
  addExperimentData,
  changeVariables,
  handleSignalExperimentData,
  handleTraceSetting,
  updateExperimentData,
  updateExperimentIds,
  updateIsUpdateExperimentData,
  updateNotExistNets
} from '../store/sweep/action';
import { getSweepColumns, getExperimentTableData, getExperimentStatus, ONLY, MULTI, PERCENTAGE, TOLERANCE } from '../../../services/Andes_v2/sweep';
import { checkNameFormat } from '../../../services/helper/nameFormatCheck';
import { numberCheck } from '../../../services/helper/dataProcess';
import _ from 'lodash';
import './index.css';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 100,
    textWrap: 'word-break',
    ellipsis: true,
  },
  {
    title: 'Trace Width',
    key: 'width'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    fixed: "right"
  }
]

const TRACE = 'trace';

function firstToUpperCase(str) {
  if (!str) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function firstToLowerCase(str) {
  if (!str) {
    return ''
  }

  return str.charAt(0).toLowerCase() + str.slice(1)
}

class SweepContent extends Component {
  constructor(props) {
    super(props)
    const { traceSetting } = props;
    this.state = {
      sweepColumns: JSON.parse(JSON.stringify(columns)),
      selectTraceVariable: [],
      selectNets: [],
      allTraceVariable: [],
      dropdownType: '',
      variablesTables: [],
      renameExp: {},
      applyAll: false,
      traceSettingVisible: false,
      typeTrace: traceSetting.type || ONLY,
      minTrace: traceSetting.min || '90',
      maxTrace: traceSetting.max || '110',
      changeWayTrace: traceSetting.changeWay || PERCENTAGE,
      errorInfo: {},
    }

    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.initVariableTableData();
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('click', this.renameClickOutside, true);
  }

  componentDidUpdate(prevProps) {
    const { isUpdateExperimentData, traceSetting } = this.props;

    if (isUpdateExperimentData && isUpdateExperimentData !== prevProps.isUpdateExperimentData) {
      this.initVariableTableData();
      this.props._updateIsUpdateExperimentData(false);
    }

    if (!_.isEqual(traceSetting, prevProps.traceSetting)) {
      this.setState({
        typeTrace: traceSetting.type,
        minTrace: traceSetting.min,
        maxTrace: traceSetting.max,
        changeWayTrace: traceSetting.changeWay
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('click', this.renameClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { dropdownType } = this.state;
    const { target } = e;
    let inCompTitle = false;
    if (dropdownType === TRACE) {
      if (document.getElementsByClassName('andes-v2-sweep-title-box')) {
        for (let item of document.getElementsByClassName('andes-v2-sweep-title-box')) {
          if (item.contains(target)) {
            inCompTitle = true;
            break;
          }
        }
      }

      if (!inCompTitle) {
        this.setDropDown(e, dropdownType);
      }
    }
  }

  renameClickOutside = (e) => {
    const { target } = e;
    const { renameExp: { id = "" } } = this.state;
    if (id) {
      if (!document.getElementById(`experiment-name-${id}`).contains(target)) {
        this.renameExperiment(e)
      }
    }
  }

  initVariableTableData = () => {
    const { experimentData: { variables = [] }, traceSetting, notExistNets } = this.props;
    let _selectTraceVariable = [], _sweepColumns = JSON.parse(JSON.stringify(columns)), _selectNet = [];

    _selectTraceVariable = variables.filter(item => item.mode === 'line').map(it => it.name);
    _selectNet = variables.filter(item => item.mode === 'line').map(it => it.net);
    _sweepColumns = getSweepColumns(_sweepColumns, variables, traceSetting.type, notExistNets);
    this.setState({
      selectTraceVariable: _selectTraceVariable,
      sweepColumns: [..._sweepColumns._sweepColumns],
      selectNets: _selectNet,
      // tableWidth: _sweepColumns.tableWidth
    }, () => {
      this.columnsCell();
    })
  }

  columnsCell = () => {
    const { notExistNets } = this.props;
    const { dropdownType, sweepColumns, selectTraceVariable } = this.state;
    const _sweepColumns = [...sweepColumns];
    _sweepColumns.forEach((column) => {
      if (column.dataIndex === 'name') {
        column.render = (text, record) => {
          const { id, name } = record;
          const { renameExp } = this.state;
          return renameExp.id === id ? <Input
            id={`experiment-name-${id}`}
            className='aurora-input'
            value={renameExp.name}
            // ref={node => (this.input = node)}
            onChange={(e) => this.setState({ renameExp: { ...renameExp, name: e.target.value } })}
            onPressEnter={(e) => { this.renameExperiment(e) }}
          /> : <div className="andes-v2-sweeping-column-name" id={`experiment-name-${id}`}>
            <div className="andes-v2-sweeping-column-name-text" onDoubleClick={(e) => { this.setRenameExperiment(e, id, { name }) }}>
              <Tooltip title={text} overlayClassName='aurora-tooltip'>{text}</Tooltip>
            </div>
            <IconCollection
              menu={this.collectionMenu(name)}
              data={id}
            />
          </div>
        }
      } else if (column.key === 'width') {
        column.title = () => {
          return this.traceWidthTitle(dropdownType);
        }

        column.children && column.children.forEach((c, index) => {
          if (notExistNets.includes(c.key)) {
            const title = c.title;
            c.title = () => {
              return (
                <span className='andes-v2-sweep-not-exist-net'>
                  {title}
                  <Tooltip overlayClassName="aurora-tooltip" title="This net was deleted in the channel">
                    <InfoCircleOutlined className='andes-v2-sweep-icon-info-circle' />
                  </Tooltip>
                </span>
              );
            }
          }

          if (!c.children && selectTraceVariable.includes(c.dataIndex)) {
            c.render = (text, record) => {
              return <div onClick={() => this.setState({ applyAll: false })} className='andes-v2-sweep-value-text'>
                {this.getValueText(text, record, c.dataIndex)}
              </div>
            }
            c.onCell = (record) => {
              return {
                record: { ...record, [`${c.dataIndex}`]: firstToUpperCase(record[c.dataIndex]) },
                edit: true,
                dataIndex: c.dataIndex,
                typValue: c.typValue,
                saveTraceWidth: (params) => this.saveTraceWidth(params),
                changeApplyAll: this.changeApplyAll,
                customInput: InputOrSelect,
                tdClassName: 'andes-v2-sweep-table-tdClass'
              }
            }
          } else {
            c.children && c.children.forEach((section) => {
              if (selectTraceVariable.includes(section.dataIndex)) {
                section.render = (text, record) => {
                  return <div onClick={() => this.setState({ applyAll: false })} className='andes-v2-sweep-value-text'>
                    {this.getValueText(text, record, section.dataIndex)}
                  </div>
                }
                section.onCell = (record) => {
                  return {
                    record: { ...record, [`${section.dataIndex}`]: firstToUpperCase(record[section.dataIndex]) },
                    edit: true,
                    dataIndex: section.dataIndex,
                    typValue: section.typValue,
                    saveTraceWidth: (params) => this.saveTraceWidth(params),
                    changeApplyAll: this.changeApplyAll,
                    customInput: InputOrSelect,
                    tdClassName: 'andes-v2-sweep-table-tdClass'
                  }
                }
              }
            })
          }
        })
      } else if (column.dataIndex === 'status') {
        column.render = (text, record) => {
          const { title, color, tooltip = null } = getExperimentStatus(text);
          return <Tooltip title={tooltip} overlayClassName='aurora-tooltip'>
            <span style={{ color, fontWeight: 'bold' }}>{title}</span>
          </Tooltip>
        }
      }
    })
    this.setState({ sweepColumns: _sweepColumns });
  }

  getValueText = (text, record, dataIndex) => {
    if (text === 'typ') {
      return '';
    }
    return `${record[dataIndex] === 'userDefined' ? '' : firstToUpperCase(record[dataIndex])} ${record[`${dataIndex}_value`] ? `[${record[`${dataIndex}_value`].join(', ')}]` : ''}`
  }

  getTraceConfigsUnit = (traceConfigs) => {
    return traceConfigs && traceConfigs.length > 0 ? traceConfigs[0].unit : ''
  }

  traceWidthTitle = (dropdownType) => {
    const { traceConfigs } = this.props;
    return (
      <div className='andes-v2-sweep-title-box'>
        <div onClick={(e) => this.setDropDown(e, TRACE)} className='andes-v2-sweep-title-name'>
          {dropdownType === TRACE ? <DownOutlined className="andes-v2-sweep-dropdown-icon" /> : <RightOutlined className="andes-v2-sweep-dropdown-icon" />}
          <span>Trace Width ({this.getTraceConfigsUnit(traceConfigs)})</span>
          <SettingOutlined
            onClick={(e) => this.openTraceSettingModel(e)}
            className="andes-v2-sweep-variable-setting-icon" />
        </div>
      </div>
    );
  }

  traceWidthDropDownRender = () => {
    const { traceConfigs, notExistNets } = this.props;
    const { selectNets } = this.state;
    return <div className='andes-v2-sweep-title-checkbox'>
      <ul>
        {traceConfigs.map(item => {
          return <li key={item.net}>
            <Tooltip overlayClassName='aurora-tooltip' title={item.net}>
              <Checkbox checked={selectNets.includes(item.net)} onChange={(e) => this.changeVariable(e, item)}>{item.net}</Checkbox>
            </Tooltip>
          </li>
        })}
        {notExistNets.map((item, index) => {
          return <li key={`${item}_${index}`}>
            <Tooltip overlayClassName='aurora-tooltip' title={item}>
              <Checkbox checked={true} onChange={(e) => this.changeVariable(e, { net: item })}>
                <span className='andes-v2-sweep-not-exist-net'>
                  {item}
                </span>
              </Checkbox>
            </Tooltip>
          </li>
        })}
      </ul>
    </div>
  }

  openTraceSettingModel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ traceSettingVisible: true })
  }

  closeTraceSettingModel = () => {
    const { minTrace, maxTrace, typeTrace, errorInfo, changeWayTrace } = this.state;
    if (!errorInfo.type && !errorInfo.error) {
      this.setState({ traceSettingVisible: false });
      this.props._handleTraceSetting({ min: Number(minTrace), max: Number(maxTrace), type: typeTrace, changeWay: changeWayTrace });
    }
  }

  // change min or max
  onChangeMinOrMax = (value, type) => {
    this.setState({ [type]: value, errorInfo: {} });
  }

  checkData = (e, value, type) => {
    const error = numberCheck(value, true);
    if (error) {
      e.target.focus();
      this.setState({ errorInfo: { type, error } });
    } else {
      this.setState({ errorInfo: {} })
    }
  }

  inputRender = ({ title, type, value }, unit) => {
    const { errorInfo, changeWayTrace } = this.state;
    return <div className="sweep-config-item" key={type}>
      <span>{title}</span>
      <Tooltip
        className='aurora-error-tooltip'
        overlayClassName='aurora-error-msg-tooltip'
        title={errorInfo.error}
        open={errorInfo.type === type ? true : false}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <Input
          prefix={changeWayTrace === PERCENTAGE ? '' : type === 'minTrace' ? '-' : '+'}
          addonAfter={changeWayTrace === PERCENTAGE ? '%' : unit}
          defaultValue="90"
          className="aurora-input"
          value={value}
          onChange={(e) => this.onChangeMinOrMax(e.target.value, type)}
          onBlur={(e) => this.checkData(e, value, type)}
          onPressEnter={(e) => e.target.blur()}
          size='small'
        />
      </Tooltip>
    </div>
  }

  // click dropdown
  setDropDown = (e, type) => {
    if (e && e.target && e.target.classList && e.target.classList.contains('ant-checkbox-input')) {
      return;
    }
    e && e.stopPropagation && e.stopPropagation();
    const { dropdownType, sweepColumns } = this.state;
    let _type = dropdownType === TRACE ? "" : type;
    const _sweepColumns = [...sweepColumns];
    const widthIndex = sweepColumns.findIndex(item => item.key === 'width');
    _sweepColumns[widthIndex].title = () => {
      return this.traceWidthTitle(_type);
    }
    this.setState({ dropdownType: _type, sweepColumns: _sweepColumns });
  }

  // tools menu for name
  collectionMenu = (name) => {
    return [
      { title: "Rename", icon: 'edit', func: this.setRenameExperiment, params: { name } },
      // { title: "Copy", icon: 'copy', func: this.copyExperiment },
      { title: "Delete", icon: 'close', func: this.deleteExperiment }
    ]
  }

  changeVariable = (e, config) => {
    this.props._changeVariables(config, e.target.checked);
  }

  setRenameExperiment = (e, id, { name }) => {
    e.stopPropagation()
    this.setState({ renameExp: { id, name } }, () => {
      const input = document.getElementById(`experiment-name-${id}`);
      input && input.focus();
    });
  }

  renameExperiment = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    const { experimentData: { variablesTables } } = this.props;
    const { renameExp: { id = "", name } } = this.state;
    const oldName = variablesTables.find(item => item.id === id).name;
    if (name === oldName) {
      this.setState({ renameExp: {} })
      return;
    }

    const nameList = variablesTables.filter(item => item.id !== id).map(item => item.name);
    let error = null;
    if (nameList.includes(name)) {
      error = "There is an experiment with the same name";
    } else {
      error = checkNameFormat(name);
    }
    if (error) {
      message.error(error);
      const input = document.getElementById(`experiment-name-${id}`);
      input && input.focus();
    } else {
      this.props._handleSingleExperimentData({ id, name }, 'rename')
      this.setState({ renameExp: {} })
    }
  }

  // delete signal experiment
  deleteExperiment = (e, id) => {
    e.stopPropagation()
    this.props._handleSingleExperimentData({ id }, 'delete');
  }

  // switch to Min，Max，Typ
  saveSelectedValue = (record, dataIndex) => {
    const { applyAll } = this.state;
    const type = record[dataIndex] ? firstToLowerCase(record[dataIndex]) : 'typ';
    this.props._handleSingleExperimentData({
      id: record.id,
      type,
      variable: dataIndex,
      applyAll,
      inputValue: record[`${dataIndex}_value`]
    }, 'changeValue')
  }

  // input or select
  saveTraceWidth = ({ type, record, dataIndex, inputValue }) => {
    const { applyAll } = this.state;
    if (type === 'input') {
      this.props._handleSingleExperimentData({
        id: record.id,
        type: 'userDefined',
        variable: dataIndex,
        inputValue,
        applyAll
      }, 'changeValue')
    } else if (type === 'select') {
      this.saveSelectedValue(record, dataIndex);
    }
  }

  changeApplyAll = ({ e, record, dataIndex, isOnlyUpdateApplyAll }) => {
    this.setState({ applyAll: e.target.checked }, () => {
      !isOnlyUpdateApplyAll && this.saveSelectedValue(record, dataIndex)
    })
  }

  changeChangeWayTrace = (e) => {
    const value = e.target.value;
    this.setState({
      changeWayTrace: value,
      minTrace: value === PERCENTAGE ? '90' : '',
      maxTrace: value === PERCENTAGE ? '110' : '',
      errorInfo: {}
    })
  }

  settingPanel = () => {
    const { traceConfigs } = this.props;
    const { minTrace, maxTrace, typeTrace, changeWayTrace } = this.state;
    const unit = this.getTraceConfigsUnit(traceConfigs)
    const content = (
      <Panel
        className="sweep-config-panel"
        draggable
        title="Trace Setting"
        onCancel={this.closeTraceSettingModel}
        position='panel-center-left'
        zIndex={2200}
        maxWidth={500}
      >
        <div className='sweep-config-panel-content'>
          <div className="sweep-config-item">
            {/* <span></span> */}
            <Radio.Group onChange={this.changeChangeWayTrace} value={changeWayTrace}>
              <Radio value={PERCENTAGE}>Percentage</Radio>
              <Radio value={TOLERANCE}>Tolerance</Radio>
            </Radio.Group>
          </div>
          {
            [{ title: 'Min', type: 'minTrace', value: minTrace }, { title: 'Max', type: 'maxTrace', value: maxTrace }].map((item) => {
              return this.inputRender(item, unit)
            })
          }
          <Divider className='andes-v2-sweep-select-dropdown-divider' />
          <div className="sweep-config-item">
            <Radio.Group onChange={(e) => this.setState({ typeTrace: e.target.value })} value={typeTrace}>
              <Radio value={MULTI} className="sweep-config-radio">Create variables for the different trace widths of each net</Radio>
              <Radio value={ONLY} className="sweep-config-radio">Create variables for each net</Radio>
            </Radio.Group>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { sweepLoading, experimentData, experimentIds } = this.props;
    const { sweepColumns, traceSettingVisible, dropdownType } = this.state;
    const variablesTables = getExperimentTableData(experimentData.variablesTables);
    const rowSelection = {
      selectedRowKeys: experimentIds,
      fixed: true,
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(selectedRowKeys, typeof selectedRowKeys, String(selectedRowKeys).split(','));
        this.props._updateExperimentIds(selectedRowKeys);
      }
    };

    return (
      <div className="andes-v2-content-setting" style={{ minWidth: '100%', width: 'max-content' }}>
        <Spin spinning={sweepLoading}>
          <div className='aurora-setup-border'>
            <span className="font-bold out-title-color">Experiments</span>
            <PlusCircleOutlined
              onClick={this.props._addExperimentData}
              className="andes-v2-sweep-add-icon" />
            <EditableTable
              className='space-10 andes-v2-table andes-v2-sweep-table'
              size='small'
              columns={sweepColumns}
              dataSource={variablesTables || []}
              rowKey={(record) => record.id}
              // tableLayout="fixed"
              rowSelection={rowSelection}
              scroll={variablesTables.length ? { x: 'max-content' } : { x: 'min-content' }}
            />
            {traceSettingVisible && this.settingPanel()}
          </div>
        </Spin>
        {dropdownType === TRACE ? this.traceWidthDropDownRender() : null}
      </div>
    );
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { sweep: {
    sweepLoading,
    traceConfigs,
    sweepId,
    experimentData,
    isUpdateExperimentData,
    traceSetting,
    notExistNets,
    experimentIds
  } } } = state;
  return {
    sweepLoading,
    traceConfigs,
    sweepId,
    experimentData,
    isUpdateExperimentData,
    traceSetting,
    notExistNets,
    experimentIds
  }
}

const mapDispatch = (dispatch) => ({
  _updateExperimentData(experimentData) {
    dispatch(updateExperimentData(experimentData))
  },
  _updateIsUpdateExperimentData(experimentData) {
    dispatch(updateIsUpdateExperimentData(experimentData));
  },
  _addExperimentData() {
    dispatch(addExperimentData());
  },
  _handleSingleExperimentData(params, handleType) {
    dispatch(handleSignalExperimentData(params, handleType))
  },
  _changeVariables(itemData, checked) {
    dispatch(changeVariables(itemData, checked));
  },
  _updateExperimentIds(experimentIds) {
    dispatch(updateExperimentIds(experimentIds))
  },
  _updateNotExistNets(notExistNets) {
    dispatch(updateNotExistNets(notExistNets))
  },
  _handleTraceSetting(traceSetting) {
    dispatch(handleTraceSetting(traceSetting))
  }
})

export default connect(mapState, mapDispatch)(SweepContent);