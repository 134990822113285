export const UPDATE_MULTI_INTERFACE_SETUP_STATUS = "SIERRA/update_multi_interface_setup_status";
export const CLEAR_MULTI_SETUP_INFO = "SIERRA/clear_multi_setup_info";
export const UPDATE_MULTI_SETUP_LOADING = "SIERRA/update_multi_setup_loading";
export const UPDATE_MULTI_SETUP_PAGE_DATA = "SIERRA/update_multi_setup_page_data";
export const CHANGE_MULTI_SETUP_PAGE = `SIERRA/change_multi_setup_page`;
export const GET_MULTI_SETUP_BUFFER_DATA = `SIERRA/get_multi_setup_buffer_data`;
export const GET_MULTI_SETUP_PASSIVE_DATA = `SIERRA/get_multi_setup_passive_data`;
export const UPDATE_MULTI_SETUP_BUFFER_DATA = "SIERRA/update_multi_setup_buffer_data";
export const UPDATE_MULTI_SETUP_PASSIVE_DATA = "SIERRA/update_multi_setup_passive_data";
export const SAVE_IC_COMP_MODEL = "SIERRA/MULTI_BUFFER/save_ic_comp_model";
export const SAVE_IC_PIN_MODEL = "SIERRA/MULTI_BUFFER/save_ic_pin_model";
export const SAVE_PASSIVE_MODEL = "SIERRA/MULTI_PASSIVE/save_passive_model";
export const SAVE_PIN_VALUE = "SIERRA/MULTI_BUFFER/save_pin_value";
export const SAVE_POWER_OFF = "SIERRA/MULTI_BUFFER/save_power_off";
export const UPDATE_COMPONENT_PIN_USAGE = "SIERRA/MULTI_BUFFER/save_component_pin_usage";
export const UPDATE_PASSIVE_COMPONENTS = "SIERRA/MULTI_PASSIVE/save_passive_components";
export const SAVE_SPLIT_COMPONENTS = "SIERRA/MULTI_PASSIVE/save_split_components";
export const SAVE_MERGE_COMPONENTS = "SIERRA/MULTI_PASSIVE/save_merge_components";
export const APPLY_MULTI_BUFFER_USAGE_CHANGE = "SIERRA/MULTI_BUFFER/apply_multi_buffer_usage_change";
export const CHECK_SIM_INTERFACES = "SIERRA/MULTI_SETUP/check_sim_interfaces";
export const START_MULTI_SETUP_SIM = "SIERRA/MULTI_SETUP/start_multi_setup_sim";
export const SAVE_SIM_CONFIG = "SIERRA/MULTI_SETUP/save_sim_config";
export const CHANGE_MULTI_SETUP_GROUP = "SIERRA/MULTI_SETUP/change_multi_setup_group";
export const UPDATE_MULTI_SETUP_GROUP = "SIERRA/MULTI_SETUP/update_multi_setup_group";
export const CHANGE_MULTI_BUFFER_PAGE = "SIERRA/MULTI_SETUP/change_multi_buffer_page";
export const CHANGE_MULTI_PASSIVE_PAGE = "SIERRA/MULTI_SETUP/change_multi_passive_page";
export const GET_MULTI_INTERFACE_SETUP = "SIERRA/MULTI_SETUP/get_multi_interface_setup";
export const RE_ASSIGN_BUFFER_MODEL = "SIERRA/MULTI_SETUP/re_assign_buffer_model";
export const CLEAR_BUFFER_DATA = "SIERRA/MULTI_SETUP/clear_buffer_data";
export const UPDATE_MULTI_SETUP_SIM_ERROR_MSG = "SIERRA/update_multi_setup_sim_error_msg";
export const SAVE_COPY_STIMULUS = "SIERRA/save_copy_stimulus";
export const GET_MULTI_SETUP_EXTRACTION_DATA = "SIERRA/get_multi_setup_extraction_data";
export const UPDATE_MULTI_SETUP_EXTRACTION = "SIERRA/update_multi_setup_extraction";
export const UPDATE_MULTI_SETUP_EXTRACTION_PORTS = "SIERRA/update_multi_setup_extraction_ports";
export const SAVE_MULTI_SETUP_EXTRACTION = "SIERRA/save_multi_setup_extraction";
export const SAVE_MULTI_SETUP_EXTRACTION_PORTS = "SIERRA/save_multi_setup_extraction_ports";
export const UPDATE_PCB_LOADING_STATUS = "SIERRA/update_pcb_loading_status";
export const UPDATE_MULTI_SETUP_EXTRACTION_STATUS = "SIERRA/update_multi_setup_extraction_status";
export const CHANGE_MULTI_SETUP_RESULT = "SIERRA/change_multi_setup_result";
export const CLEAR_PASSIVE_DATA = "SIERRA/MULTI_SETUP/clear_passive_data";
export const UPDATE_MULTI_SETUP_BUFFER_MODEL_LOADING = "SIERRA/MULTI_SETUP/update_multi_setup_buffer_model_loading";
export const CLEAR_MULTI_SETUP_EXTRACTION = "SIERRA/clear_multi_setup_extraction";
export const CLEAR_MULTI_SETUP_EXTRACTION_PORTS = "SIERRA/clear_multi_setup_extraction_ports";
export const UPDATE_MULTI_SETUP_EXTRACTION_PORTS_LOG = "SIERRA/update_multi_setup_extraction_ports_log";
export const UPDATE_MULTI_SETUP_EXTRACTION_OPTIONS_LOG = "SIERRA/update_multi_setup_extraction_options_log";
export const UPDATE_MULTI_SETUP_EXTRACTION_APPLY = ` SIERRA/update_multi_setup_extraction_apply`;
export const UPDATE_MULTI_SETUP_EXTRACTION_PCB_SELECT_STATUS = `SIERRA/update_multi_setup_extraction_pcb_select_status`;
