import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Select, Switch, Collapse, Input, Divider, Tooltip, TreeSelect } from 'antd';
import { getUnitConversionValue } from '@/services/helper/numberHelper';
import {
  updateEyeDiagramConfig,
  saveEyeUnitValue,
  updateEyeDiagramClocks
} from '../store/result/action';
import {
  getEyeDefaultUI,
  getEyeDefaultVRef
} from '@/services/Sierra/results';
import { eyeDiagramErrorCheck } from '@/services/helper/eyeDiagramErrorCheck';
import {
  DELAY,
  T_SHIFT,
  TIME_SCALE,
  CLOCKS,
  FRAME_SIZE,
  EYE_CONFIG,
  PLOT_CONFIG,
  CLOCK_POSITIVE,
  CLOCK_NEGATIVE,
  V_MIN,
  V_MAX,
  SINGLE,
  DIFFERENTIAL,
  eyeUnitList,
  eyeClockTypeList,
  NO_CLOCK,
  TRIGGER,
  RISING_EDGE,
  timeScaleList,
  eyeScaleList,
  noClockTriggerList,
  clockTriggerList,
  plotTypeList,
  SINGLE_DATA_RATE,
  DOUBLE_DATA_RATE,
  DOUBLE_EDGE,
  UI,
  advancedList,
  clockFrequencyList,
  CLOCK_FREQUENCY,
  V_REF,
  clockDiffTriggerList,
  CLOCK_PINS,
  SIGNAL_PINS
} from '../constants';
import './index.css';

const Option = Select.Option;
const { SHOW_PARENT } = TreeSelect;
const ADVANCED_OPTIONS = 'advanced_options';
class EyeDiagramConfig extends Component {
  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.state = {
      activeKey: []
    }
  }

  selectAfter = (type) => {
    const { eyeDiagramUnitValue } = this.props;
    const value = eyeDiagramUnitValue[type];
    const unit = value.unit;
    const options = type === CLOCK_FREQUENCY ? clockFrequencyList : eyeScaleList;
    return (
      <Select
        value={unit}
        onChange={(value) => this.unitChange(value, type)}
        popupMatchSelectWidth={false}
        className='sierra-eye-diagram-down-select'>
        {options.map(item => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    )
  }

  selectChange = (key, type, configType) => {
    this.props.updateErrorMsg(null);

    let _key = key;
    const { eyeDiagramConfig, verificationId, clockPositive, clockNegative, allSignalPins, clockPPin, clockNPin } = this.props;
    let _clockPositive = clockPositive, _clockNegative = clockNegative,
      _clockPPin = clockPPin, _clockNPin = clockNPin;
    let _eyeDiagramConfig = JSON.parse(JSON.stringify(eyeDiagramConfig));

    //single CLOCKS
    if (type === CLOCKS && key && !Array.isArray(key)) {
      _key = [key];
      /*  const clockPin = allSignalPins.find(item => item.signal === key);
       const clockPins = clockPin ? [{
         component: clockPin.component,
         pin: clockPin.pin,
         pcbId: clockPin.pcbId,
         signal: key
       }] : []; */
      _eyeDiagramConfig.eye_config.clockPins = [] /* clockPins; */
    }

    if (type === CLOCKS && !key) {
      _key = [];
      _eyeDiagramConfig.eye_config.clockPins = [];
    }

    //Trigger, Set UI according to trigger
    if (type === TRIGGER) {
      const clock = _eyeDiagramConfig[EYE_CONFIG][CLOCK_FREQUENCY];
      const ui = getEyeDefaultUI(clock, _key);
      _eyeDiagramConfig[EYE_CONFIG][UI] = ui;
    }

    if (type === TIME_SCALE) {
      //set x_label by time_scale
      const findTimeScale = timeScaleList.find(item => item.name === _key);
      if (findTimeScale && findTimeScale.display) {
        _eyeDiagramConfig.plot_config.x_label = findTimeScale.display;
      }
    }

    if (type === CLOCK_PINS) {
      const clockPin = key ? allSignalPins.find(item => item.name === key) : null;
      _key = clockPin ? [{
        component: clockPin.component,
        pin: clockPin.pin,
        pcbId: clockPin.pcbId,
        signal: clockPin.signal
      }] : [];
    }

    if (type === "clockPPin") {
      const clockPin = key ? allSignalPins.find(item => item.name === key) : null;
      _clockPPin = {
        component: clockPin.component,
        pin: clockPin.pin,
        pcbId: clockPin.pcbId,
        signal: clockPin.signal
      };
      _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS].push({ ..._clockPPin });
    }

    if (type === "clockNPin") {
      const clockPin = key ? allSignalPins.find(item => item.name === key) : null;
      _clockNPin = {
        component: clockPin.component,
        pin: clockPin.pin,
        pcbId: clockPin.pcbId,
        signal: clockPin.signal
      };
      _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS].push({ ..._clockNPin });
    }

    if (type === "signals") {
      const delSignals = _eyeDiagramConfig[configType][type].filter(item => !_key.includes(item));
      _eyeDiagramConfig[EYE_CONFIG][SIGNAL_PINS] = _eyeDiagramConfig[EYE_CONFIG][SIGNAL_PINS].filter(item => !delSignals.includes(item.signal))
    }

    if (!["clockPPin", "clockNPin"].includes(type)) {
      _eyeDiagramConfig[configType][type] = _key;
    }

    this.props._updateEyeDiagramClocks({ clockPositive: _clockPositive, clockNegative: _clockNegative, clockPPin: _clockPPin, clockNPin: _clockNPin });
    this.props._updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig, isSave: true, verificationId });
  }

  onTreeChange = (keys, type, configType) => {
    //signal pins
    this.props.updateErrorMsg(null);

    const { eyeDiagramConfig, verificationId, allSignalPins } = this.props;
    let _eyeDiagramConfig = JSON.parse(JSON.stringify(eyeDiagramConfig));
    const signal = allSignalPins.map(item => item.signal)
    const _keys = keys.map(key => {
      if (signal.includes(key)) {
        return allSignalPins.filter(item => item.signal === key).map(item => item.name);
      } else {
        return [key];
      }
    }).flat(2);
    const signalPins = allSignalPins.filter(item => _keys.includes(item.name)).map(item => {
      return {
        component: item.component,
        pin: item.pin,
        pcbId: item.pcbId,
        signal: item.signal
      }
    })

    _eyeDiagramConfig[configType][type] = signalPins;
    this.props._updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig, isSave: true, verificationId });
  }

  unitChange = (unit, type) => {
    this.props.updateErrorMsg(null);
    const { eyeDiagramUnitValue } = this.props;
    let _value = JSON.parse(JSON.stringify(eyeDiagramUnitValue));

    _value[type].value = getUnitConversionValue(_value[type].value, unit, _value[type].unit);

    _value[type].unit = unit;
    this.props._saveEyeUnitValue(_value);
  }

  inputChange = (e, type, configType) => {
    this.props.updateErrorMsg(null);
    let value = e.target.value;
    if (eyeUnitList.includes(type)) {
      const { eyeDiagramUnitValue } = this.props;
      let newValue = JSON.parse(JSON.stringify(eyeDiagramUnitValue));
      newValue[type].value = value;
      this.props._saveEyeUnitValue(newValue);
    } else {
      const { eyeDiagramConfig } = this.props;
      let _eyeDiagramConfig = JSON.parse(JSON.stringify(eyeDiagramConfig));
      _eyeDiagramConfig[configType][type] = value;
      this.props._updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig });
    }
  }

  inputBlur = (e, type, configType) => {
    let value = e.target.value;

    let error = eyeDiagramErrorCheck({ value, inputType: type });
    //check number and range
    if (value && error) {
      e.target.focus();
      this.props.updateErrorMsg({ type, error });
      return;
    } else {
      this.props.updateErrorMsg(null);
    }

    const { eyeDiagramConfig, verificationId, eyeDiagramUnitValue } = this.props;
    let _eyeDiagramConfig = JSON.parse(JSON.stringify(eyeDiagramConfig));
    const newValue = JSON.parse(JSON.stringify(eyeDiagramUnitValue));

    if (eyeUnitList.includes(type) && value) {
      const _unit = type === CLOCK_FREQUENCY ? "Hz" : "s";
      value = getUnitConversionValue(value, _unit, newValue[type].unit);
      if (type === CLOCK_FREQUENCY) {
        const ui = getEyeDefaultUI(value, _eyeDiagramConfig[EYE_CONFIG][TRIGGER]);
        _eyeDiagramConfig[EYE_CONFIG][UI] = ui;
      }
    }

    _eyeDiagramConfig[configType][type] = value;
    this.props._updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig, isSave: true, verificationId });
  }

  selectClocks = (key, type) => {
    this.props.updateErrorMsg(null);

    const { eyeDiagramConfig, verificationId, clockPositive, clockNegative, clockPPin, clockNPin } = this.props;
    let _eyeDiagramConfig = JSON.parse(JSON.stringify(eyeDiagramConfig));
    const clocks = _eyeDiagramConfig[EYE_CONFIG][CLOCKS] ? eyeDiagramConfig[EYE_CONFIG][CLOCKS] : [];
    const otherSignals = clocks.filter(item => item !== key);
    let _clockPositive = clockPositive, _clockNegative = clockNegative,
      _clockPPin = clockPPin, _clockNPin = clockNPin;

    if (type === CLOCK_POSITIVE) {
      _eyeDiagramConfig[EYE_CONFIG][CLOCKS] = key ? [...new Set([key, ...otherSignals])] : clocks.filter(item => item !== clockPositive);
      _clockPositive = key ? key : "";
      _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS] = _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS].filter(item =>
        !(item.signal === _clockPPin.signal
          && item.component === _clockPPin.component
          && item.pin === _clockPPin.pin
          && item.pcbId === _clockPPin.pcbId
        ));
      _clockPPin = {};
    }

    if (type === CLOCK_NEGATIVE) {
      _eyeDiagramConfig[EYE_CONFIG][CLOCKS] = key ? [...new Set([...otherSignals, key])] : clocks.filter(item => item !== clockNegative);;
      _clockNegative = key ? key : "";
      _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS] = _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS].filter(item =>
        !(item.signal === _clockNPin.signal
          && item.component === _clockNPin.component
          && item.pin === _clockNPin.pin
          && item.pcbId === _clockNPin.pcbId
        ));
      _clockNPin = {};
    }

    this.props._updateEyeDiagramClocks({ clockPositive: _clockPositive, clockNegative: _clockNegative, clockPPin: _clockPPin, clockNPin: _clockNPin });
    this.props._updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig, isSave: true, verificationId });
  }

  selectClockType = (key) => {
    this.props.updateErrorMsg(null, true);

    const { eyeDiagramConfig, verificationId, eyeClockType, DDRSignals, currentInterfaceInfo } = this.props;
    if (eyeClockType && eyeClockType === key) {
      return;
    }

    let _eyeDiagramConfig = JSON.parse(JSON.stringify(eyeDiagramConfig));
    _eyeDiagramConfig[EYE_CONFIG][CLOCKS] = [];
    _eyeDiagramConfig[EYE_CONFIG][CLOCK_PINS] = [];

    if (key === NO_CLOCK) {
      _eyeDiagramConfig[EYE_CONFIG][TRIGGER] = DDRSignals && DDRSignals.length ? DOUBLE_DATA_RATE : SINGLE_DATA_RATE;
      _eyeDiagramConfig[EYE_CONFIG][T_SHIFT] = "0";

    } else if ([DOUBLE_DATA_RATE, SINGLE_DATA_RATE].includes(_eyeDiagramConfig[EYE_CONFIG][TRIGGER])) {
      _eyeDiagramConfig[EYE_CONFIG][TRIGGER] = DDRSignals && DDRSignals.length ? DOUBLE_EDGE : RISING_EDGE;
    }
    if (key === NO_CLOCK || key === DIFFERENTIAL) {
      _eyeDiagramConfig[EYE_CONFIG][V_REF] = "0";
    } else {
      _eyeDiagramConfig[EYE_CONFIG][V_REF] = getEyeDefaultVRef(currentInterfaceInfo);
    }

    this.props._updateEyeDiagramClocks({ eyeClockType: key, clockPositive: "", clockNegative: "", clockPPin: null, clockNPin: null });
    this.props._updateEyeDiagramConfig({ eyeDiagramConfig: _eyeDiagramConfig, isSave: true, verificationId });

  }

  getTriggerList = (eyeClockType) => {
    let _triggerList = [];
    switch (eyeClockType) {
      case NO_CLOCK:
        _triggerList = JSON.parse(JSON.stringify(noClockTriggerList));
        break;
      case DIFFERENTIAL:
        _triggerList = JSON.parse(JSON.stringify(clockDiffTriggerList));
        break;
      case SINGLE:
        _triggerList = JSON.parse(JSON.stringify(clockTriggerList));
        break;
      default: _triggerList = JSON.parse(JSON.stringify(clockTriggerList));
        break;
    }
    return _triggerList;
  }

  changeAdvancedCollapse = (keys) => {
    const { errorMsg } = this.props;
    const { activeKey } = this.state;
    const closeKeys = activeKey.filter(item => !keys.includes(item));
    //if error msg exist, not allow to close current active key
    if (closeKeys && closeKeys.length) {
      const closeType = closeKeys[0];
      let closeTypeChildren = [];
      if (closeType === ADVANCED_OPTIONS) {
        closeTypeChildren = [...advancedList];
      }

      if (errorMsg && errorMsg.type && closeTypeChildren.includes(errorMsg.type)) {
        return;
      }
    }

    this.setState({
      activeKey: keys
    })
  }

  timeScaleDisplay = (time_scale) => {
    let _time_scale = "";
    if (typeof (time_scale) !== "string") {
      const current = timeScaleList.find(item => parseFloat(item.name) === parseFloat(time_scale));
      if (current) {
        _time_scale = current.name;
      }
    } else {
      _time_scale = time_scale;
    }
    return _time_scale
  }

  getClockPinInfo = ({ clocks, clockPins }) => {
    const { eyeClockType, allSignalPins, clockPPin, clockNPin, clockPositive, clockNegative } = this.props;

    let clockPin = "", clkPins = [];
    if (eyeClockType === SINGLE) {
      clkPins = allSignalPins.filter(item => clocks && clocks.includes(item.signal));
      const clockPinObj = clockPins && clockPins.length ? clockPins[0] : {};
      clockPin = clockPinObj.pin ? `${clockPinObj.component}-${clockPinObj.pin}::${clockPinObj.pcbId}` : ""
    }

    let _clockPPin = "", _clockNPin = "", clkPPins = [], clkNPins = [];
    if (eyeClockType === DIFFERENTIAL) {
      _clockPPin = clockPPin && clockPPin.pin ? `${clockPPin.component}-${clockPPin.pin}::${clockPPin.pcbId}` : "";
      _clockNPin = clockNPin && clockNPin.pin ? `${clockNPin.component}-${clockNPin.pin}::${clockNPin.pcbId}` : "";
      clkPPins = clockPositive ? allSignalPins.filter(item => item.signal === clockPositive) : [];
      clkNPins = clockNegative ? allSignalPins.filter(item => item.signal === clockNegative) : [];
    }
    return { clockPin, clkPins, _clockPPin, _clockNPin, clkPPins, clkNPins };
  }

  render() {
    const { activeKey } = this.state;
    const { eyeDiagramConfig, signalList, eyeDiagramUnitValue, clockPositive, clockNegative, eyeClockType, allSignalPins } = this.props;
    let {
      eye_config: { clocks, signals, v_ref, trigger, frame_size, clockPins, signalPins },
      plot_config: { type, plot_clk, diff_clk, v_min, v_max, time_scale }
    } = eyeDiagramConfig;

    const _delay = eyeDiagramUnitValue[DELAY].value;
    const _t_shift = eyeDiagramUnitValue[T_SHIFT].value;
    const _clockFrequency = eyeDiagramUnitValue[CLOCK_FREQUENCY].value;

    const signalSelectList = signalList && signalList.length ? signalList.filter(item => !clocks || !clocks.includes(item)) : [];
    const clockSelectList = signalList && signalList.length ? signalList.filter(item => !signals || !signals.includes(item)) : [];
    const clockPositiveList = clockSelectList && clockSelectList.length ? clockSelectList.filter(item => item !== clockNegative) : [];
    const clockNegativeList = clockSelectList && clockSelectList.length ? clockSelectList.filter(item => item !== clockPositive) : [];
    const _triggerList = this.getTriggerList(eyeClockType);
    const _time_scale = this.timeScaleDisplay(time_scale);
    const { clockPin, clkPins, _clockPPin, _clockNPin, clkPPins, clkNPins } = this.getClockPinInfo({ clocks, clockPins });
    const signalSelectPins = allSignalPins.filter(item => signals && signals.includes(item.signal));

    return (
      <div className='sierra-eye-diagram-config-main'>
        <Collapse
          bordered={false}
          defaultActiveKey={["signals"]}
          className='sierra-eye-diagram-config-content'
          items={[
            {
              key: "signals",
              label: "Signals",
              children: <Fragment>
                {/* Signals */}
                {this.getSelectComponent({
                  value: signals,
                  title: "Signals",
                  options: signalSelectList,
                  mode: "multiple",
                  type: "signals",
                  configType: EYE_CONFIG
                })}
                {signals && signals.length ? this.getSignalPinsTreeSelect({
                  value: signalPins,
                  title: "Signals Pins",
                  options: signalSelectPins,
                  type: SIGNAL_PINS,
                  configType: EYE_CONFIG,
                  tooltip: "Allow select any signal's receiver pins to generate the eye diagrams. If no pins are selected for a signal, all its receiver pins are used."
                }) : null}
              </Fragment>
            }
          ]}
        />
        <Divider orientation="left" className='sierra-eye-diagram-config-divider'></Divider>
        <Collapse
          bordered={false}
          defaultActiveKey={["trigger"]}
          className='sierra-eye-diagram-config-content'
          items={[
            {
              key: "trigger",
              label: "Trigger",
              children: <Fragment>
                {this.getSelectComponent({
                  value: eyeClockType,
                  title: "Clock Type",
                  options: eyeClockTypeList,
                  type: "clockType",
                  configType: EYE_CONFIG,
                  changeSelect: this.selectClockType
                })}
                {/* Clocks */}
                {eyeClockType === SINGLE ?
                  <Fragment>
                    {this.getSelectComponent({
                      value: clocks && clocks.length ? clocks[0] : "",
                      title: "Clock Signal",
                      options: clockSelectList,
                      type: CLOCKS,
                      configType: EYE_CONFIG,
                      allowClear: true,
                      className: "sierra-eye-diagram-config-clock-item"
                    })}
                    {clkPins && clkPins.length > 1 ? this.getSelectComponent({
                      value: clockPin,
                      title: "Clock Pin",
                      options: clkPins,
                      type: CLOCK_PINS,
                      configType: EYE_CONFIG,
                      allowClear: true,
                      className: "sierra-eye-diagram-config-clock-item",
                      tooltip: "Allow select a receiver pin as the clock. By default, the first receiver pin of the clock signal is selected."
                    }) : null}
                  </Fragment>
                  : null}
                {eyeClockType === DIFFERENTIAL ?
                  <Fragment>
                    {/* Clocks positive */}
                    {this.getSelectComponent({
                      value: clockPositive,
                      title: "Clock Positive",
                      options: clockPositiveList,
                      type: CLOCK_POSITIVE,
                      configType: EYE_CONFIG,
                      changeSelect: this.selectClocks,
                      allowClear: true,
                      className: "sierra-eye-diagram-config-clock-item"
                    })}
                    {/* Clocks negative */}
                    {this.getSelectComponent({
                      value: clockNegative,
                      title: "Clock Negative",
                      options: clockNegativeList,
                      type: CLOCK_NEGATIVE,
                      configType: EYE_CONFIG,
                      changeSelect: this.selectClocks,
                      allowClear: true,
                      className: "sierra-eye-diagram-config-clock-item"
                    })}
                    {clkPPins && clkPPins.length > 1 ? this.getSelectComponent({
                      value: _clockPPin,
                      title: "Positive Pin",
                      options: clkPPins,
                      type: "clockPPin",
                      configType: EYE_CONFIG,
                      allowClear: true,
                      className: "sierra-eye-diagram-config-clock-item",
                      tooltip: "Allow select a receiver pin as the positive clock. By default, the first receiver pin of the positive clock signal is selected."
                    }) : null}
                    {clkNPins && clkNPins.length > 1 ? this.getSelectComponent({
                      value: _clockNPin,
                      title: "Negative Pin",
                      options: clkNPins,
                      type: "clockNPin",
                      configType: EYE_CONFIG,
                      allowClear: true,
                      className: "sierra-eye-diagram-config-clock-item",
                      tooltip: "Allow select a receiver pin as the negative clock. By default, the first receiver pin of the negative clock signal is selected."
                    }) : null}
                  </Fragment>
                  : null}
                {/* trigger */}
                {this.getSelectComponent({
                  value: trigger,
                  title: "Trigger Type",
                  options: _triggerList,
                  type: TRIGGER,
                  configType: EYE_CONFIG
                })}
                {/* v_ref */}
                {eyeClockType !== NO_CLOCK && eyeClockType !== DIFFERENTIAL ? this.getInputComponent({
                  value: v_ref,
                  title: "Reference Voltage",
                  type: "v_ref",
                  configType: EYE_CONFIG,
                  addonAfter: "V",
                  className: "sierra-eye-diagram-config-input-padding"
                }) : null}

              </Fragment>
            }
          ]}
        />
        <Divider orientation="left" className='sierra-eye-diagram-config-divider'></Divider>
        <Collapse
          bordered={false}
          defaultActiveKey={["options"]}
          className='sierra-eye-diagram-config-content'
          items={[
            {
              key: "options",
              label: "Options",
              children: <Fragment>
                {/* Clock Frequency */}
                {this.getInputComponent({
                  value: _clockFrequency,
                  title: "Clock Frequency",
                  type: CLOCK_FREQUENCY,
                  configType: EYE_CONFIG,
                  addonAfter: this.selectAfter(CLOCK_FREQUENCY)
                })}
                {/* type */}
                {this.getSelectComponent({
                  value: type,
                  title: "Plot Type",
                  options: plotTypeList,
                  type: "type",
                  configType: PLOT_CONFIG
                })}
              </Fragment>
            }
          ]}
        />
        <Divider orientation="left" className='sierra-eye-diagram-config-divider'></Divider>
        <Collapse
          bordered={false}
          activeKey={activeKey}
          defaultActiveKey={[]}
          onChange={this.changeAdvancedCollapse}
          className='sierra-eye-diagram-config-content'
          items={[
            {
              key: ADVANCED_OPTIONS,
              label: "Advanced​ Options",
              children: <Fragment>
                {/* delay */}
                {this.getInputComponent({
                  value: _delay,
                  title: "Starting Time",
                  tooltip: "Waveform before the Starting Time is not included in the eye diagram. For Periodic clock type, this is used as the first trigger point​.",
                  type: DELAY,
                  configType: EYE_CONFIG,
                  addonAfter: this.selectAfter(DELAY)
                })}
                {/* t_shift */}
                {eyeClockType !== NO_CLOCK ? this.getInputComponent({
                  value: _t_shift,
                  title: "Sampling Adjustment",
                  type: T_SHIFT,
                  configType: EYE_CONFIG,
                  tooltip: "The default sampling point is at the trigger point + 0.5/datarate. An additional 'Sampling Adjustment' can be used to fine tune the sampling point.",
                  addonAfter: this.selectAfter(T_SHIFT)
                }) : null}
                <Divider orientation="left" className='sierra-eye-diagram-config-advanced'></Divider>
                {/* frame_size */}
                {this.getInputComponent({
                  value: frame_size,
                  title: "Plot Width",
                  type: FRAME_SIZE,
                  configType: EYE_CONFIG,
                  addonAfter: "1 / datarate",
                  className: "sierra-eye-diagram-config-input-padding"
                })}
                {/* plot_clk */}
                <div className='sierra-eye-diagram-config-item'>
                  <span className='sierra-eye-diagram-config-item-span'>Clock Overlay</span>
                  <Switch
                    size='small'
                    className='sierra-eye-diagram-config-item-switch aurora-switch-small'
                    checked={plot_clk}
                    onChange={checked => this.selectChange(checked, 'plot_clk', PLOT_CONFIG)}
                  />
                </div>
                {/* diff_clk */}
                <div className='sierra-eye-diagram-config-item sierra_eye-diagram-config-sub-item'>
                  <span className='sierra-eye-diagram-config-item-span sierra_eye-diagram-config-sub-span'>Plot Differential Clock </span>
                  <Switch
                    size='small'
                    checked={diff_clk}
                    disabled={!plot_clk}
                    className='sierra-eye-diagram-config-item-switch aurora-switch-small'
                    onChange={checked => this.selectChange(checked, 'diff_clk', PLOT_CONFIG)}
                  />
                </div>
                {/* time_scale */}
                {this.getSelectComponent({
                  value: _time_scale,
                  title: "Time Unit",
                  type: TIME_SCALE,
                  options: timeScaleList,
                  configType: PLOT_CONFIG
                })}
                {/* v_min, v_max */}
                <div className='sierra-eye-diagram-config-item'>
                  <span className='sierra-eye-diagram-config-item-span'>Dynamic Range</span>
                  <Input
                    className='sierra-eye-diagram-config-item-sub-input sierra-eye-diagram-config-input-padding'
                    placeholder='Min'
                    size='small'
                    addonAfter={"V"}
                    value={v_min}
                    onChange={e => this.inputChange(e, V_MIN, PLOT_CONFIG)}
                    onBlur={e => this.inputBlur(e, V_MIN, PLOT_CONFIG)}
                  />
                  <span className='sierra-eye-diagram-config-item-line'></span>
                  <Input
                    className='sierra-eye-diagram-config-item-sub-input sierra-eye-diagram-config-input-padding'
                    placeholder='Max'
                    addonAfter={"V"}
                    size='small'
                    value={v_max}
                    onChange={e => this.inputChange(e, V_MAX, PLOT_CONFIG)}
                    onBlur={e => this.inputBlur(e, V_MAX, PLOT_CONFIG)}
                  />
                </div>
              </Fragment>
            }
          ]}
        />
      </div >
    )
  }

  getSelectComponent = ({ value, title, options, mode, type, configType, changeSelect, allowClear, className, tooltip }) => {
    const selectChange = changeSelect ? changeSelect : this.selectChange;
    return <div className={`sierra-eye-diagram-config-item ${className}`}>
      <Tooltip title={tooltip ? tooltip : ""} placement="left">
        <span className='sierra-eye-diagram-config-item-span'>{title}</span>
      </Tooltip>
      <Select
        className='sierra-eye-diagram-config-item-select'
        mode={mode ? mode : ""}
        size='small'
        value={value}
        allowClear={allowClear}
        onChange={(key) => selectChange(key, type, configType)}
        popupMatchSelectWidth={true}
      >
        {options.map(item => (
          ([TRIGGER, "type", TIME_SCALE, CLOCK_PINS, "clockPPin", "clockNPin"].includes(type)) ?

            <Option key={item.name} title={item.display}>
              <Tooltip title={item.tooltip ? item.tooltip : ""} placement="left">
                {item.display}
              </Tooltip>
            </Option>
            :
            <Option key={item} title={item}>{item}</Option>
        ))}
      </Select>
    </div >
  }

  getInputComponent = ({ value, title, type, configType, addonAfter, className, tooltip }) => {
    return <div className='sierra-eye-diagram-config-item'>
      <Tooltip title={tooltip ? tooltip : ""} placement="left">
        <span className='sierra-eye-diagram-config-item-span'>{title}</span>
      </Tooltip>
      <Input
        className={`sierra-eye-diagram-config-item-input ${className}`}
        addonAfter={addonAfter}
        size='small'
        value={value}
        onChange={e => this.inputChange(e, type, configType)}
        onBlur={e => this.inputBlur(e, type, configType)}
      />
    </div>
  }

  getSignalPinsTreeSelect = ({ value, title, options, type, configType, tooltip }) => {
    const valueList = value.map(item => `${item.component}-${item.pin}::${item.pcbId}`);
    const { data, isMulti } = this.getSignalTreeData(options);
    if (!isMulti) {
      return;
    }

    return (
      <div className={`sierra-eye-diagram-config-item`}>
        <Tooltip title={tooltip ? tooltip : ""} placement="left">
          <span className='sierra-eye-diagram-config-item-span'>{title}</span>
        </Tooltip>
        <TreeSelect
          treeData={data}
          value={valueList}
          onChange={(keys) => this.onTreeChange(keys, type, configType)}
          treeCheckable={true}
          allowClear
          treeDefaultExpandAll={true}
          showCheckedStrategy={SHOW_PARENT}
          dropdownMatchSelectWidth={true}
          className='sierra-eye-diagram-config-item-select sierra-eye-diagram-config-item-tree-select'
          getPopupContainer={() => document.getElementById('root')}
          popupClassName='sierra-eye-diagram-select-dropdown'
        />
      </div>
    );
  }

  getSignalTreeData = (options) => {
    let data = [], dataObj = {}, isMulti = false;
    for (let item of options) {
      if (!dataObj[item.signal]) {
        dataObj[item.signal] = []
      }
      dataObj[item.signal].push({
        title: item.display,
        key: item.name,
        value: item.name
      })
    }
    data = Object.keys(dataObj).map(item => {
      if (dataObj[item].length > 1) {
        isMulti = true;
      }
      return {
        title: item,
        key: item,
        value: item,
        children: dataObj[item]
      }
    })
    return { data, isMulti };
  }

}


const mapState = (state) => {
  const { resultReducer, project } = state.SierraReducer;
  const { selectedClocks, selectedSignals, clocks, signals,
    resultSwitchStatus, eyeDiagramConfig, eyeDiagramUnitValue,
    clockPositive, clockNegative, eyeClockType, DDRSignals, allSignalPins, clockPPin, clockNPin } = resultReducer;
  const { verificationId, currentProjectId } = project;
  return {
    verificationId,
    currentProjectId,
    selectedClocks,
    selectedSignals,
    clockList: clocks,
    signalList: signals,
    resultSwitchStatus,
    eyeDiagramConfig,
    eyeDiagramUnitValue,
    clockPositive,
    clockNegative,
    eyeClockType,
    DDRSignals,
    allSignalPins,
    clockPPin,
    clockNPin
  }
}

const mapDispatch = (dispatch) => ({
  _updateEyeDiagramConfig({ eyeDiagramConfig, clockPositive, clockNegative, isSave, verificationId }) {
    dispatch(updateEyeDiagramConfig({ eyeDiagramConfig, clockPositive, clockNegative, isSave, verificationId }))
  },
  _updateEyeDiagramClocks({ clockPositive, clockNegative, eyeClockType, clockPPin, clockNPin }) {
    dispatch(updateEyeDiagramClocks({ clockPositive, clockNegative, eyeClockType, clockPPin, clockNPin }))
  },
  _saveEyeUnitValue(eyeDiagramUnitValue) {
    dispatch(saveEyeUnitValue(eyeDiagramUnitValue))
  }
})

export default connect(mapState, mapDispatch)(EyeDiagramConfig);