import { call, put, takeEvery, select, delay, takeLatest } from 'redux-saga/effects';
import { UPDATE_PROJECT_MENU, OPEN_PROJECT, SHOW_RESULT, UPDATE_TRASH_MENU, SAVE_COMPONENTS_NETS_IN_PROJECT, CHANGE_VIEW_LIST, AUTO_GET_VERIFICATION_LIST, CLEAR_CURRENT_PROJECT } from './actionTypes';
import { projectMenu, saveOpenProjectInfo, changeView, trashMenu, updatePCBComponentsNets, changeTreeSelected, autoGetVerificationList, refreshPCB, updateCurrentVerificationStatus } from './action';
import { PROJECT, PCBs, PCB, RESULT, VERIFICATIONS, VERIFICATION, My_TRASH } from '../../constants';
import { VERIFY_SUCCESS } from '@/constants/verificationStatus';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import { changeProject, changeVerification, closeTabFooter, openTabFooter, changeTabMenu } from '../../../tabMonitor/action';
import {
  changeVerificationList,
  existResult
} from '../simulation/action';
import { expandMenu } from '../andes/action';
import { getProjectList, getAndesProjectPromise, getAndesTrashList } from '@/services/Andes';
import LayoutData from '@/services/data/LayoutData';
import DesignInfo from '@/services/Andes/pcbInfo';
import { getCurrentLog, cancelShow } from '../simulation/action';
import { strDelimited } from '@/services/helper/split';
import { ANDES } from '../../../../constants/pageType';
import { cleanStatus } from '../../../LayoutExplorer/store/Andes/actionCreators';

function* updateProjectMenu(action) {
  const { AndesReducer: { project, andes } } = yield select();
  let { treeItems } = project;
  const res = yield call(getProjectList);
  // treeItems[0] - 'projects'
  treeItems[0].children = res.map(item => ({
    id: item.id,
    name: item.name,
    key: PROJECT + '-' + item.id,
    dataType: PROJECT,
    children: [{
      name: 'PCB',
      key: PCBs + '-' + item.id,
      dataType: PCBs
    },
    {
      name: 'Interface',
      key: VERIFICATIONS + '-' + item.id,
      dataType: VERIFICATIONS
    }]
  }));
  let projectList = res.map(item => ({ name: item.name, id: item.id }))
  const { obj } = action;

  if (obj && obj.openProjectId) {
    yield call(openProject, { id: obj.openProjectId, verificationName: obj.verificationName, verificationId: obj.verificationId });

    let { expandedKeys } = andes;
    const key = `${PROJECT}-${obj.openProjectId}`;

    if (!expandedKeys.includes(key)) {
      expandedKeys = expandedKeys.filter(item => {
        const [_key, _id] = strDelimited(item, "-");

        if (!(_key === PROJECT && _id !== obj.openProjectId)) {
          return item;
        }
        return false;
      });
      expandedKeys.push(key);
    }
    yield put(expandMenu(expandedKeys));
  } else {
    yield put(projectMenu({ treeItems: [...treeItems], projectList }));
  }
}

function* openProject(action) {
  const { AndesReducer: { project } } = yield select();
  let { treeItems } = project;
  let { id, verificationId, verificationName } = action;

  let _treeItems = [...treeItems];
  // treeItems[0] - 'projects'
  const findIndex = _treeItems[0].children.findIndex(item => item.id === id);
  const res = yield call(getAndesProjectPromise, id);
  const designs = res.designs.map(item => ({
    id: item.id,
    key: PCB + '-' + item.id,
    name: item.name,
    dataType: PCB,
    designVersion: item.designVersion,
    projectId: item.projectId,
    nodeClass: 'tree-node-pcb-name',
  }));
  const verifications = res.verifications.map(item => ({
    id: item.id,
    key: VERIFICATION + '-' + item.id,
    name: item.name,
    dataType: VERIFICATION,
    /*  verificationId: item.verificationId, */
    nodeClass: 'tree-node-interface-name',
    children: [{
      name: 'Result',
      key: RESULT + '-' + item.id /* + '-' + item.verificationId */,
      dataType: RESULT,
      nodeClass: 'tree-node-interface-result-name',
    }]
  }));
  if (findIndex > -1) {
    _treeItems[0].children[findIndex].children = [{
      name: 'PCB',
      key: PCBs + '-' + id,
      dataType: PCBs,
      children: designs,
      iconDisabled: designs.length >= 1 ? true : false
    },
    {
      name: 'Interface',
      key: VERIFICATIONS + '-' + id,
      dataType: VERIFICATIONS,
      children: verifications
    }];
  }

  yield put(projectMenu({ treeItems: [..._treeItems] }));
  let name = "";
  if (findIndex > -1) {
    name = _treeItems[0].children[findIndex].name;
  }
  yield put(changeProject(name));
  const { AndesReducer: { upload: { disabled, uploadProjectId } } } = yield select();
  if (disabled && uploadProjectId === id) {
    yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'upload', verificationId: null, projectId: id }));
    yield put(openTabFooter());
  }
  verifications.forEach(i => {
    if (i.id === verificationId) {
      verificationName = i.name
    }
  });

  if (verificationName && verificationId) {
    yield put(changeVerification(verificationName, verificationId));
  }
  yield put(changeVerificationList([]));
  yield put(saveOpenProjectInfo({ id, name, verifications: verifications, designs: designs }));
  yield put(autoGetVerificationList(id));
}

function* showAndesResult(action) {
  const { view, verificationId, getStatus, verificationName } = action;
  const promise = yield call(checkVerificationStatus, verificationId);
  let resultExist = false;
  if (promise && promise.status) {
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    }
  };
  yield put(existResult(resultExist));
  yield put(changeView(view, { verificationId, getStatus, verificationName }))
}

function* updateTrashMenu() {
  const { AndesReducer: { project } } = yield select();
  const { treeItems } = project;
  let newData = [...treeItems];
  const res = yield call(getAndesTrashList);
  newData[1].children = [];

  if (res.length < 1) {
    newData[1].children = [];
  } else {
    res.forEach(item => {
      newData[1].children.push({
        name: item.name,
        key: `project-${item.id}`,
        id: item.id,
        dataType: My_TRASH
      });
    });
  }
  yield put(trashMenu({ treeItems: [...newData] }));
};

function* saveComponentsNets(action) {
  const { pcbIds, status } = action;
  let { AndesReducer: { project: { pcbComponentsNets } } } = yield select();
  const _filter = pcbIds.filter(id => !pcbComponentsNets.includes(id));
  yield* _filter.map(function* (id) {
    try {
      yield call(getLayoutDB, id);
      const _DesginData = LayoutData.getLayout(id);
      const info = {
        netsList: [..._DesginData.mNetManager.mNetList.mVals],
        layers: [..._DesginData.mLayerMgr.mMetalLayers]
      };
      pcbComponentsNets.push(id);
      DesignInfo.savePCBInfo(id, info);
    } catch (error) {
      console.error(error)
    };
  });
  yield put(updatePCBComponentsNets(pcbComponentsNets));
  if (status) {
    const { AndesReducer: { project: { selectedKeys, currentProjectId } } } = yield select();
    yield put(autoGetVerificationList(currentProjectId));
    yield put(refreshPCB(true));
    if (selectedKeys.length > 0) {
      yield* selectedKeys.map(function* (item) {
        if (item) {
          const [key, id] = strDelimited(item, "-");
          if (key === 'Verification') {
            yield put(changeView('Verification', { verificationId: id }));
          } else if (key === 'result') {
            yield put(changeView('result', { verificationId: id }));
          }
        }
      })
    }
  }
}

function getLayoutDB(id) {
  return new Promise((resolve, reject) => {
    LayoutData.LoadLayoutDB(id).then(res => {
      resolve(true);
    }, error => {
      resolve(true);
    })
  })
}

function* changeViewList(action) {
  if (!action.viewType) {
    return;
  }
  const { viewList, viewType } = action;
  const { AndesReducer: { project, andes: { expandedKeys } } } = yield select();
  const { verificationId, designID, selectedKeys, currentProjectDesigns, currentProjectVerifications, currentProjectId } = project;
  if (viewList.includes(viewType)) {
    let newKeys = [...selectedKeys];

    if (viewType === PCB) {
      if (!expandedKeys.includes(`${PCBs}-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `${PCBs}-${currentProjectId}`]));
      }
      if (currentProjectDesigns.length === 0) {
        if (viewList.length === 1) {
          yield put(closeTabFooter());
        }

        newKeys = newKeys.filter(item => item !== 'PCB')
        yield put(changeTreeSelected([...newKeys]));
        yield put(changeView(PCB));
        return;
      }
      const current = currentProjectDesigns.find(item => item.id === designID);
      let pcbId;
      if (current) {
        pcbId = designID;
      } else {
        pcbId = currentProjectDesigns[0].id;
      }
      if (viewList.length === 1) {
        yield put(closeTabFooter());
        newKeys = [`${PCB}-${pcbId}`];
      } else {
        newKeys.push(`${PCB}-${pcbId}`);
      }
      yield put(changeTreeSelected([...newKeys]));
      yield put(changeView(PCB, { designId: pcbId }));

    } else {
      if (viewType === RESULT) {
        yield put(changeView(RESULT, { verificationId }));
      }
      if (viewType === VERIFICATION) {
        if (!expandedKeys.includes(`${VERIFICATIONS}-${currentProjectId}`)) {
          yield put(expandMenu([...expandedKeys, `${VERIFICATIONS}-${currentProjectId}`]));
        }

        if (currentProjectVerifications.length === 0) {
          if (viewList.length === 1) {
            newKeys = [];
          }
          yield put(changeTreeSelected([...newKeys]));
          yield put(changeView(VERIFICATION));
          return;
        }

        let openVerificationId, verificationName;
        if (verificationId) {
          const current = currentProjectVerifications.find(item => item.id === verificationId);
          if (current) {
            openVerificationId = verificationId;
            verificationName = current.name;
          } else {
            openVerificationId = currentProjectVerifications[0].id;
            verificationName = currentProjectVerifications[0].name;
          }
        } else {
          openVerificationId = currentProjectVerifications[0].id;
          verificationName = currentProjectVerifications[0].name;
        }

        if (viewList.length === 1) {
          newKeys = [`${VERIFICATION}-${openVerificationId}`];
        } else {
          newKeys.push(`${VERIFICATION}-${openVerificationId}`);
        }
        yield put(changeTreeSelected([...newKeys]));
        yield put(changeView(VERIFICATION, { verificationId: openVerificationId }));
        yield put(changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: openVerificationId, projectId: currentProjectId }));
        yield put(changeVerification(verificationName));
        yield put(openTabFooter());
        yield put(cancelShow(false));
        yield put(getCurrentLog(openVerificationId));
      }
    }
  } else {
    let newKeys = [...selectedKeys];

    newKeys.forEach((item, index) => {
      const [key] = strDelimited(item, "-");

      if (key === viewType) {
        newKeys.splice(index, 1);
      }
    });
    yield put(changeTreeSelected([...newKeys]));

    if (viewList.length < 1) {
      yield put(changeView('', {}));
      yield put(closeTabFooter());
    } else {

      if (viewType === PCB) {

        if (viewList[0] === VERIFICATION) {
          yield put(changeView(VERIFICATION, { verificationId }));
        } else if (viewList[0] === RESULT) {
          yield put(changeView(RESULT, { verificationId, getStatus: true }));
        }
      } else {
        yield put(changeView(PCB, { designId: designID }));
        yield put(closeTabFooter());
      }
    }
  }
}

function* getVerifications(action) {
  const { projectId, currentSimInfo } = action;
  let delayTime = 30000;
  if (!currentSimInfo) {
    let first = true;
    while (true) {
      if (!first) {
        yield delay(delayTime);
      }
      first = false;
      const { AndesReducer: { project }, LoginReducer: { pageType } } = yield select();
      const currentProjectId = project.currentProjectId;
      const verificationId = project.verificationId;
      // If no project is expanded, stop polling
      if (!currentProjectId || projectId !== currentProjectId) {
        return;
      }

      if (pageType !== ANDES) {
        return;
      }
      let { treeItems } = project;

      let _treeItems = [...treeItems];
      // treeItems[0] - 'projects'
      const findIndex = _treeItems[0].children.findIndex(item => item.id === projectId);
      const res = yield call(getAndesProjectPromise, projectId);

      if (findIndex > -1 && res && res.verifications) {
        let statusList = res.verifications.map(item => item.status);

        if (statusList.includes(2) || statusList.includes(6)) {
          delayTime = 4000;
        } else {
          delayTime = 30000;
        }

        const verificationIndex = res.verifications.findIndex(i => i.id === verificationId);
        if (verificationId && verificationIndex > -1) {
          //update current verification status
          yield put(updateCurrentVerificationStatus(res.verifications[verificationIndex].status))
        }

        let verifications = _treeItems[0].children[findIndex].children.find(item => item.name === 'Interface').children || [];
        verifications.map(item => {
          const index = res.verifications.findIndex(i => i.id === item.id);
          if (index > -1 && (res.verifications[index].status !== item.status || res.verifications[index].readyForSim !== item.readyForSim)) {
            item.status = res.verifications[index].status;
            item.readyForSim = res.verifications[index].readyForSim;
            item.simStatus = null;
          }
          item.simStatus = null;
          return item;
        })
      }
      yield put(projectMenu({ treeItems: [..._treeItems] }));
    }
  } else {
    const { AndesReducer: { project } } = yield select();
    let { treeItems } = project;

    let _treeItems = [...treeItems];
    // treeItems[0] - 'projects'
    const findIndex = _treeItems[0].children.findIndex(item => item.id === projectId);
    if (findIndex > -1) {
      let verifications = _treeItems[0].children[findIndex].children.find(item => item.name === 'Interface').children || [];
      verifications.map(item => {
        const index = currentSimInfo.findIndex(i => i.verificationId === item.id);
        if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo.status !== item.simStatus))) {
          item.simStatus = currentSimInfo[index].status;
        }
        return item;
      })
    }
    yield put(projectMenu({ treeItems: [..._treeItems] }));
  }
}

function* cleanCurrentProject() {
  yield put(cleanStatus());
}

function* projectSaga() {
  yield takeEvery(UPDATE_PROJECT_MENU, updateProjectMenu);
  yield takeEvery(OPEN_PROJECT, openProject);
  yield takeEvery(SHOW_RESULT, showAndesResult);
  yield takeEvery(UPDATE_TRASH_MENU, updateTrashMenu);
  yield takeEvery(SAVE_COMPONENTS_NETS_IN_PROJECT, saveComponentsNets);
  yield takeEvery(CHANGE_VIEW_LIST, changeViewList);
  yield takeLatest(AUTO_GET_VERIFICATION_LIST, getVerifications);
  yield takeEvery(CLEAR_CURRENT_PROJECT, cleanCurrentProject)
}

export default projectSaga;