import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import LayoutLR from './layoutLR';
import LayoutTB from './layoutTB';
import LayoutContent from './layoutContent';
import { closeTabFooter, changeTabMenu } from '../../tabMonitor/action';
import MonitorChild from './Monitor';
import { getCCCVerifications, getCCCSpecifyServiceVerification } from '../../../services/CCC/cccHelper';
import { getServiceTitleList } from '../../../services/Sierra/helper/monitorHelper';
import ContentLayout from '@/components/ContentLayout';
import { SIERRA } from '@/constants/pageType';
import { PCB, RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, VERIFICATION, PCB_PRE_LAYOUT } from '@/constants/treeConstants';

class SierraContent extends PureComponent {

  _monitorChild = (width) => {
    const { menuType, tabVisible, tabSelectKeys, currentProjectId, currentProjectVerifications, verificationId, viewList,
      projectName, verificationName, experimentInfo = {} } = this.props;
    const isExperiment = viewList.includes(EXPERIMENTS) ? true : false;
    return MonitorChild({
      menuType,
      tabVisible,
      tabSelectKeys,
      projectId: currentProjectId,
      getCCCVerifications: () => getCCCVerifications(currentProjectVerifications),
      getCurrentVerification: () => getCCCSpecifyServiceVerification(verificationId, currentProjectVerifications),
      width,
      titleSelectedKeys: getServiceTitleList({
        isExperiment,
        projectName,
        verificationName,
        sweepVerificationName: experimentInfo.verificationName,
        sweepName: experimentInfo.sweepName
      })
    })
  }

  _changeMonitorTabMenu = ({ selectKeys }) => {
    const { verificationId, currentProjectId, menuType, viewList, verificationName, experimentInfo } = this.props;
    //experiment verification monitor name
    let _verificationName = verificationName, id = verificationId;
    if (viewList.includes(EXPERIMENTS) && experimentInfo && experimentInfo.id) {
      let findExp = null;
      if (_verificationName === "Base" && experimentInfo.base) {
        findExp = { name: _verificationName, id: experimentInfo.base.id }
      } else {
        findExp = (experimentInfo.experiments || []).find(item => item.name === verificationName);
      }
      const firstExp = experimentInfo.experiments[0];
      id = findExp ? findExp.id : (firstExp ? firstExp.id : id);
      _verificationName = findExp ? findExp.name : (firstExp ? firstExp.name : _verificationName);
    }

    this.props._changeTabMenu({
      selectKeys,
      menuType,
      verificationId: id,
      projectId: currentProjectId,
      verificationName: _verificationName
    });
  }

  _getTopMenuVisible = () => {
    const { viewList, currentProjectId } = this.props;
    if (!currentProjectId) {
      return false;
    }

    if (viewList && viewList.length && viewList.every(v => [RESULT, PCB, EXPERIMENTS_RESULT].includes(v))) {
      return false;
    }

    if (viewList && viewList.length > 1 && (viewList.includes(RESULT) || viewList.includes(EXPERIMENTS_RESULT)) && viewList.includes(PCB_PRE_LAYOUT)) {
      return false;
    }

    return true;
  }

  _getTopBarWidth = () => {
    const { viewList } = this.props;
    const view = viewList.filter(item => ![PCB].includes(item));
    if (view.length) {
      switch (view[0]) {
        case VERIFICATION:
          return 1328;
        case EXPERIMENTS:
          return 300;
        case PCB_PRE_LAYOUT:
          return 200;
        default: return 548;
      }
    }
    return 538;
  }

  layoutSingle = () => {
    return <LayoutContent  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this._getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={() => this._getTopBarWidth()}
    />
  }

  layoutLR = () => {
    return <LayoutLR  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this._getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={() => this._getTopBarWidth()}
    />
  }

  layoutTB = () => {
    return <LayoutTB  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this._getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={() => this._getTopBarWidth()}
    />
  }

  render() {
    const { layout, viewList } = this.props;
    return <ContentLayout
      {...this.props}
      pageType={SIERRA}
      layout={layout}
      viewList={viewList}
      layoutLR={this.layoutLR}
      layoutTB={this.layoutTB}
      layoutSingle={this.layoutSingle}
    />;
  }

}

const mapState = (state) => {
  const { SierraReducer } = state;
  const { project: { layout, currentProjectId, verificationId, viewList, currentProjectVerifications },
    sweep: { experimentInfo } } = SierraReducer;
  const { tabVisible, tabSelectKeys, menuType, projectName, verificationName } = state.TabMonitorReducer;
  return {
    layout,
    verificationId,
    viewList,
    tabVisible,
    tabSelectKeys,
    menuType,
    currentProjectId,
    currentProjectVerifications,
    projectName, verificationName,
    experimentInfo
  };
}

const mapDispatch = (dispatch) => ({
  _closeTabFooter() {
    dispatch(closeTabFooter());
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }));
  },
})

export default connect(mapState, mapDispatch)(SierraContent);