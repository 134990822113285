import React, { Component, Fragment } from "react";
import { createPortal } from "react-dom";
import { CloseOutlined, CopyOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Collapse, Layout, Menu, Input, Spin, Tooltip, Checkbox } from 'antd';
import Panel from "../Panel";
import { splitArrayToArrays } from "../../services/helper/arrayHelper";
import PinMapTable from "./pinTable";
import { PIN_CONNECTION, PIN_MAP, POWER_SWITCH_PART } from "../../constants/libraryConstants";
import { PIN_MAP_VERSION } from "../../version";
import DelConfirm from "../DelConfirm";
import PinConnection from "./pinConnection";
import Line from "../Line";
import { POWER_SWITCH } from "../../constants/componentType";
import './index.css';

const { Sider, Content } = Layout;
class PMICPart extends Component {

  constructor() {
    super();
    this.state = {
      partList: [],
      selectedKey: '',
      searchPart: '',
      activeKey: '',
      width: 200,
      loading: true,
    }
    this.dialogRoot = document.getElementById('root');
    this.pinMaps = {};
  }

  componentDidMount() {
    this.getPinMapList(true);
  }

  componentDidUpdate = (prevProps) => {
    const { partType } = this.props;
    if (prevProps.partType !== partType) {
      this.getPinMapList(true);
    }
  }

  setDefaultSelected = () => {
    const { partVisible } = this.props;
    if (partVisible && typeof partVisible === 'string') {
      this.selectKey({ key: partVisible })
    }
  }

  getPinMapList = async (mount = false) => {
    const pinMaps = await this.props.actions.getPinMap();
    const { partType } = this.props;
    this.pinMaps = pinMaps;
    const partList = Object.values(pinMaps).filter(item => !['buckConverter', 'driver', 'multiPortRes'].includes(item.type)).filter(item => partType === POWER_SWITCH_PART ? item.type === POWER_SWITCH : item.type !== POWER_SWITCH).map(item => item.partName);
    this.setState({
      partList: partList,
      selectedKey: mount ? '' : this.state.selectedKey,
      loading: false
    }, () => {
      if (mount) {
        this.setDefaultSelected();
      }
    })
  }

  selectKey = ({ key }) => {
    this.setState({
      selectedKey: key
    }, () => {
      const part = this.pinMaps[key];
      if (!part || !part.pinMap) {
        return;
      }
      const pinMap = Object.values(part.pinMap || {})
      const defaultItem = pinMap.find(item => item.name === key);
      this.setState({
        activeKey: defaultItem ? defaultItem.libraryId : pinMap[0] ? pinMap[0].libraryId : ''
      })
    })
  }

  changeSearchPart = (e) => {
    this.setState({
      searchPart: e.target.value
    })
  }

  changePanel = (value) => {
    this.setState({
      activeKey: value && value[0] ? value[0] : ''
    })
  }

  addNewPinMap = async () => {
    const { selectedKey } = this.state;
    const part = this.pinMaps[selectedKey];
    if (!part || !part.pinMap) {
      return;
    }
    const _name = this.checkName(selectedKey);
    const { partType } = this.props;

    const data = {
      id: '',
      name: _name,
      config: { pinTable: [] },
      partName: selectedKey,
      version: PIN_MAP_VERSION,
      type: partType === POWER_SWITCH_PART ? PIN_CONNECTION : PIN_MAP
    }

    try {
      const res = await this.props.actions.updateLibraryData({ data });
      await this.getPinMapList()
      if (res) {
        const { id } = res;
        this.setState({
          activeKey: id
        }, () => {
          this.props.checkbox && this.props.selectPinMapFile({ target: { checked: true } }, id)
        })
      }
    } catch (error) {
      console.error(error);
    }
  }

  openDelLibrary = (e, libraryId) => {
    e && e.stopPropagation()
    this.setState({
      delLibraryId: libraryId,
      delPart: false
    })
  }

  openDelPart = (e) => {
    e && e.stopPropagation();
    this.setState({
      delPart: true,
      delLibraryId: false,
    })
  }

  cancelDel = () => {
    this.setState({
      delLibraryId: false,
      delPart: false
    })
  }

  deletePinMap = async () => {
    try {
      const { delLibraryId, selectedKey } = this.state;
      const { checkedPinMap } = this.props;
      await this.props.actions.deleteLibrary(delLibraryId, selectedKey);
      await this.getPinMapList();
      if (delLibraryId === checkedPinMap) {
        this.props.selectPinMapFile(null, '')
      }
      this.setState({
        delLibraryId: false
      })
    } catch (e) {
      this.setState({
        delLibraryId: false
      })
      console.error(e)
    }
  }

  openEditLibraryName = (e, libraryId) => {
    e && e.stopPropagation()
    this.setState({
      editLibraryId: libraryId
    })
  }

  blurEditName = (e) => {
    const { editLibraryId, selectedKey } = this.state;
    const { partType } = this.props;
    const value = e.target.value;
    if (!value || value.match(/[^0-9a-zA-Z_-]+/g)) {
      this.setState({
        editLibraryId: false
      })
      return
    }
    const part = this.pinMaps[selectedKey];
    if (!part || !part.pinMap || !part.pinMap[editLibraryId]) {
      this.setState({
        editLibraryId: false
      })
      return;
    }
    const pinMap = part.pinMap[editLibraryId];
    const { libraryId, pinTable } = pinMap;
    const _name = this.checkName(value.replace(' ', ''), editLibraryId);

    const data = {
      id: libraryId,
      name: _name,
      config: { pinTable: pinTable },
      partName: selectedKey,
      version: PIN_MAP_VERSION,
      type: partType === POWER_SWITCH_PART ? PIN_CONNECTION : PIN_MAP
    }

    try {
      this.props.actions.updateLibraryData({ data });
      this.pinMaps[selectedKey].pinMap[editLibraryId].name = _name;
      this.setState({
        editLibraryId: false
      })
    } catch (e) {
      this.setState({
        editLibraryId: false
      })
      console.error(e)
    }
  }

  copyLibrary = async (e, libraryId) => {
    e && e.stopPropagation();
    const { selectedKey } = this.state;
    const { partType } = this.props;
    const part = this.pinMaps[selectedKey];
    if (!part || !part.pinMap || !part.pinMap[libraryId]) {
      return;
    }
    const pinMap = part.pinMap[libraryId];
    const { name, pinTable } = pinMap;
    const _name = this.checkName(`${name}_COPY`);
    const data = {
      id: "",
      name: _name,
      config: { pinTable: pinTable },
      partName: selectedKey,
      version: PIN_MAP_VERSION,
      type: partType === POWER_SWITCH_PART ? PIN_CONNECTION : PIN_MAP
    }

    try {
      const res = await this.props.actions.updateLibraryData({ data });
      await this.getPinMapList()
      if (res) {
        const { id } = res;
        this.setState({
          activeKey: id
        })
      }
    } catch (e) {
      this.setState({
        editLibraryId: false
      })
      console.error(e)
    }
  }

  onPressEnter = (e) => {
    e && e.stopPropagation()
    e.target.blur();
  }

  checkName = (name, filterLibraryId) => {
    const { selectedKey } = this.state;
    const part = this.pinMaps[selectedKey];
    if (!part || !part.pinMap) {
      return;
    }
    const pinMap = Object.values(part.pinMap || {})
    const nameList = pinMap.filter(p => p.libraryId !== filterLibraryId).map(p => p.name);
    let index = 1, _name = name;
    while (nameList.includes(_name)) {
      _name = `${name}_${index}`;
      index = index + 1
    }
    return _name
  }

  savePinTable = (libraryId, pinTable) => {
    const { selectedKey } = this.state;
    const part = this.pinMaps[selectedKey];
    if (!part || !part.pinMap || !part.pinMap[libraryId]) {
      return;
    }
    this.pinMaps[selectedKey].pinMap[libraryId].pinTable = pinTable;
  }

  changeWidth = (_width) => {
    const { width } = this.state;
    this.setState({
      width: width + _width > 800 ? 800 : width + _width < 100 ? 100 : width + _width
    })
  }

  deletePMICPartNumber = async () => {
    const { selectedKey } = this.state;
    const part = this.pinMaps[selectedKey];
    this.setState({
      selectedKey: "",
      delPart: false
    }, async () => {
      if (!part || !part.id) {
        return;
      }
      await this.props.actions.deletePart(part.id);
      await this.getPinMapList()
    })
  }

  partNameRender = () => {
    const { selectedKey } = this.state;
    return <div className="pmic-part-search-border pmic-part-search-partname">
      <span className="pmic-part-search-partname-title">Part Name:</span>
      <span className="pmic-part-search-partname-item">{selectedKey}</span>
      <span><CloseOutlined className="pmic-part-search-partname-delete-icon" onClick={this.openDelPart} /></span>
    </div>
  }

  pinMapRender = () => {
    const { selectedKey, activeKey } = this.state;
    const part = this.pinMaps[selectedKey] || {};
    if (!part) {
      return;
    }
    const pinMap = Object.values(part.pinMap || {})
    const designList = part.designList || [];
    const type = part.type || '';
    const { trueArray, falseArray } = splitArrayToArrays({ array: pinMap, rule: (item) => item.name === selectedKey })
    const collapse = [...trueArray, ...falseArray].map(map => {
      const { libraryId, name, pinTable } = map;
      return {
        key: libraryId,
        label: this.headerRender(name, libraryId, selectedKey),
        extra: this.extraRender(libraryId),
        children: activeKey === libraryId && (
          [POWER_SWITCH].includes(type) ?
            <PinConnection
              pinTable={pinTable}
              libraryId={libraryId}
              name={name}
              designList={designList}
              type={type}
              partName={selectedKey}
              updateLibraryData={this.props.actions.updateLibraryData}
              componentSetting={this.props.componentSetting}
              savePinTable={this.savePinTable}
            />
            : <PinMapTable
              pinTable={pinTable}
              libraryId={libraryId}
              name={name}
              designList={designList}
              type={type}
              partName={selectedKey}
              updateLibraryData={this.props.actions.updateLibraryData}
              componentSetting={this.props.componentSetting}
              savePinTable={this.savePinTable}
            />
        )
      }
    })
    return (
      <div className="pmic-part-search-border pmic-part-search-pinmap" >
        <div className="pmic-part-search-partname-title pmic-part-search-pinmap-title">Pin Map</div>
        <PlusCircleOutlined className="pmic-part-add-pinmap-icon" onClick={this.addNewPinMap} />
        {collapse && collapse.length ? <Collapse className="pmic-part-search-collapse" accordion activeKey={activeKey} onChange={this.changePanel} items={collapse} /> : null}
      </div>
    );
  }

  headerRender = (name, libraryId, selectedKey) => {
    const { editLibraryId } = this.state;
    const { checkedPinMap, checkbox, partVisible } = this.props;
    if (editLibraryId !== libraryId) {
      const disabled = checkbox ? partVisible && typeof partVisible === 'string' && partVisible === selectedKey ? false : true : true;
      return <span>
        {checkbox && <Checkbox
          checked={checkedPinMap === libraryId ? true : false}
          className="pmic-part-select-map-checkbox"
          disabled={disabled}
          onClick={(e) => e && e.stopPropagation()}
          onChange={(e) => this.props.selectPinMapFile(e, libraryId)}
        />}
        {name}
      </span>
    }
    return <Input defaultValue={name} autoFocus onBlur={this.blurEditName} onPressEnter={this.onPressEnter} size="small" className="pmic-part-panel-header-input" />
  }

  extraRender = (libraryId) => {
    return (
      <div className="pmic-part-panel-extra-icon">
        <EditOutlined onClick={(e) => this.openEditLibraryName(e, libraryId)} />
        <CopyOutlined onClick={(e) => this.copyLibrary(e, libraryId)} />
        <CloseOutlined onClick={(e) => this.openDelLibrary(e, libraryId)} />
      </div>
    );
  }

  render() {
    const { partList, selectedKey, searchPart, delLibraryId, width, loading, delPart } = this.state;
    const _partList = searchPart ? partList.filter(key => key.toLowerCase().includes(searchPart.toLowerCase())).sort() : partList.sort();
    const { partType, partVisible } = this.props;
    const content = (
      <Panel
        className='pmic-part-search-panel'
        title={partType === POWER_SWITCH_PART ? `Power Switch` : `PMIC`}
        zIndex={2000}
        width={1000}
        height={1000}
        draggable
        onCancel={this.props.close}
      // height={210}
      >
        {loading ? <Spin spinning={loading} wrapperClassName="pmic-part-search-loading" size='large'>
          <div></div>
        </Spin> :
          <Layout className="pmic-part-search-layout">
            {(!partVisible || typeof partVisible !== 'string') ? <Sider className="pmic-part-search-sider" width={width}>
              <Input.Search className="pmic-part-search-input-search" onChange={(e) => this.changeSearchPart(e)} placeholder="Search Part Name" />
              <Menu mode="inline" className="pmic-part-search-menu" selectedKeys={[selectedKey]} onSelect={this.selectKey} items={_partList.map(key => ({
                key, label: <Tooltip
                  title={key}
                  classNames={{ root: "aurora-tooltip" }}
                  zIndex={2002}
                  placement="top"
                  autoAdjustOverflow={false}
                >
                  <div className="pmic-part-search-menu-label">
                    <span>{key}</span>
                  </div>
                </Tooltip>
              }))} />
            </Sider> : null}
            <Content className="pmic-part-search-content">
              {(!partVisible || typeof partVisible !== 'string') ? <Line
                position={{ position: 'absolute', left: 0, top: 0, bottom: 0 }}
                resize={(width) => this.changeWidth(width)}
                width={1}
                showLine={true}
              /> : null}
              {selectedKey ? <div className="pmic-part-search-body">
                {this.partNameRender()}
                {this.pinMapRender()}
              </div> : null}
            </Content>
          </Layout>}
        {(delLibraryId || delPart) ? <DelConfirm
          deleteConfirm={delPart ? this.deletePMICPartNumber : this.deletePinMap}
          cancelDel={this.cancelDel}
          mask={false}
          message={`Can not be restored after deleting the ${delPart ? 'Part' : 'Pin Map'}. Are you sure you want to delete it?`}
        /> : null}
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PMICPart