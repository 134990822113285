import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIRValueFile } from '../../store/result/IR/action.js';
import { Spin } from 'antd';
import ResultTable from '@/components/ResultTable';

class IRTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    const { IRSelectedKey, resultKey, verificationId } = this.props;
    this.props.getIRValueFile(IRSelectedKey, resultKey, verificationId);
  }

  getScroll = () => {
    const content = document.getElementById("cascade-content-main")
    if (content) {
      const height = content.offsetHeight - 250 || 500;
      const width = content.offsetWidth - 50 || 1000;
      return { y: height > 500 ? height : 500, x: width };
    }
    return { y: 500, x: 1000 }
  }

  render() {
    const { IRResultTableLoading, irData, IRSelectedKey, IRTableData, resultKey, resultPowerDomainData, verificationId, tableColumns, chartXType, chartYType, svgHeight, compareTypes, rowKey, chartVisible } = this.props;
    const scroll = this.getScroll()
    return (
      <div className="ir-table-content">
        <Spin spinning={IRResultTableLoading}>
          <ResultTable
            tableColumns={tableColumns}
            selectedKey={IRSelectedKey}
            tableData={irData}
            scroll={scroll}
            resultPowerDomainData={resultPowerDomainData}
            compareTypes={compareTypes}
            resultKey={resultKey}
            verificationId={verificationId}
            chartXType={chartXType}
            chartYType={chartYType}
            svgHeight={svgHeight}
            rowKey={rowKey}
            chartVisible={chartVisible}
          />
        </Spin>
      </div >
    )
  }
}

const mapState = (state) => {
  const { IRResult: { IRSelectedKey, resultKey, irData, IRResultTableLoading, resultPowerDomainData }, IR: { IRExplorerInfo: { IRTableData = [] }, verificationId } } = state.CascadeReducer
  return {
    verificationId,
    IRSelectedKey,
    resultKey,
    irData,
    IRResultTableLoading,
    IRTableData,
    resultPowerDomainData
  }
}

const mapDispatch = (dispatch) => ({
  getIRValueFile(resultId, resultType, verificationId) {
    dispatch(getIRValueFile(resultId, resultType, verificationId))
  },
})

export default connect(mapState, mapDispatch)(IRTable)