import React, { Component, createRef } from 'react';
import { CloseCircleFilled, ArrowDownOutlined } from '@ant-design/icons';
import { Select, Tooltip } from "antd";
import EditableTable from '../EditableTable';
import { getPortByTouchstone } from '@/services/helper/getPortByTouchstone';
import { ANDES_V2, CASCADE, ROCKY, SIERRA } from "../../constants/pageType";
import TreeSelect from '@/components/TreeSelect';
import SchematicPairs from './schematicPairs';
import './index.css';

const { Option } = Select;

const tableColumns = [{
  title: 'Components Pins',
  dataIndex: 'pin',
  width: '50%'
}, {
  title: 'Touchstone Ports / SPICE Subckt Nodes',
  dataIndex: 'node',
  width: '50%',
}];

class NetListModel extends Component {
  constructor(props) {
    super(props);
    const { model } = this.props;
    this.state = {
      fileName: model.fileName || '',
      libraryId: model.libraryId || '',
      subckt: model.subckt || '',
      pairs: model.pairs && model.pairs.length > 0 ? model.pairs : this.initPairs(),
      subcktList: [],
      nodes: [],
      version: null,
      type: model.type,
      touchstonePorts: []
    }
    this.connectedRef = createRef();

    tableColumns[1].onCell = (record) => {
      const options = this.getTableNodes();
      return {
        edit: 'select',
        options,
        record,
        dataIndex: 'node',
        allowClear: true,
        dropdownMenuClassName: 'netlist-model-dropdown',
        getPopupContainer: document.getElementById("root"),
        handleSave: this.connectPinAndNode,
        handleClear: this.splitPinAndNodes
      }
    }
  }

  getTableNodes = () => {
    return this.state.nodes;
  }

  componentWillMount() {
    this.getValue()
  }

  componentDidUpdate = (prevProps) => {
    const { model, modelType, displayModel } = this.props;
    if (prevProps.modelType !== modelType
      || (!prevProps.model && model && model.libraryId)
      || (prevProps.model && model && prevProps.model.libraryId !== model.libraryId)) {
      this.getValue();
    }

    if (displayModel !== prevProps.displayModel) {
      this.resetPairs()
    }
  }

  getValue = () => {
    const { model, modelType, type } = this.props;
    this.setState({
      fileName: model.fileName || '',
      libraryId: model.libraryId || '',
      subckt: model.subckt || '',
      version: null,
      pairs: model.pairs && model.pairs.length > 0 ? model.pairs : this.initPairs(),
      type: model.type
    }, () => {
      this.changeColTitle(modelType, type);
      this.getFileAndParse(model)
    })
  }

  getFileAndParse = (model) => {
    const { getFileContent, parseModelSelector, modelType, getTouchstoneParse, type } = this.props;
    let nodes = []
    if ((['SPICE', 'Termination'].includes(model.type)
      || (modelType === 'Circuit' && model.type === "SPICE")) && model.libraryId) {
      getFileContent(model.libraryId, model.fileName, model.type).then(res => {
        if (res) {
          let subcktList = [];
          if (type === "ssn_pdn") {
            subcktList = res && res.models && Array.isArray(res.models) ? [...res.models] : [];
          } else {
            subcktList = parseModelSelector(res)
          }
          this.setState({
            subcktList: subcktList
          })
          if (model.subckt) {
            let _subcktList = [...this.state.subcktList];
            this.setState({
              subcktList: _subcktList
            })
            const _subcktIndex = _subcktList.findIndex(sub => sub.name === model.subckt);
            if (_subcktList[_subcktIndex] && _subcktList[_subcktIndex].ports) {
              nodes = _subcktList[_subcktIndex].ports;
            }
            this.firstSetPairsAndNodes(nodes);
          }
        }
      });
    } else if (modelType === 'Circuit' && model.libraryId) {
      getTouchstoneParse(model.libraryId, model.fileName).then(res => {
        this.setState({
          touchstonePorts: res.ports || []
        })
        this.firstSetPairsAndNodes(res && res.ports ? res.ports.map(item => item.port) : []);
      })
    } else if (model.type === 'Touchstone' && model.libraryId) {
      this.firstSetPairsAndNodes(getPortByTouchstone(model.fileName));
    }
  }

  resetPairs = () => {
    const { subckt, subcktList, libraryId, touchstonePorts, type, fileName } = this.state;
    const { model, modelType } = this.props;
    let nodes = [];
    if ((['SPICE', 'Termination'].includes(model.type)
      || (modelType === 'Circuit' && type === "SPICE")) && libraryId) {
      let _subcktList = [...subcktList];

      const _subcktIndex = _subcktList.findIndex(sub => sub.name === subckt);
      if (_subcktList[_subcktIndex] && _subcktList[_subcktIndex].ports) {
        nodes = _subcktList[_subcktIndex].ports;
      }
    } else if (modelType === 'Circuit' && type === "touchstone" && libraryId) {
      nodes = (touchstonePorts || []).map(item => item.port);
    } else if (model.type === 'Touchstone' && libraryId) {
      nodes = getPortByTouchstone(fileName);
    }

    this.setState({
      pairs: this.initPairs(),
      nodes,
    })
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.changeColTitle(this.props.modelType, this.props.type);
  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }

  firstSetPairsAndNodes(nodes) {
    const { model } = this.props;
    // const originPairs = model && model.pairs ? [...model.pairs] : [];
    const originPairs = model && model.pairs && model.pairs.length > 0 ? model.pairs : this.initPairs();
    let pairs = [];
    if (originPairs.length < 0) {
      pairs = this.initPairs();
    } else {
      pairs = originPairs.map(pair => {
        if (pair.node && nodes.includes(pair.node)) {
          nodes = nodes.filter(node => node !== pair.node);
        }
        return pair;
      })
    }
    this.setState({
      pairs,
      nodes,
      fileName: model.fileName || '',
      libraryId: model.libraryId || '',
      subckt: model.subckt || '',
    })
  }

  resetModel() {
    this.changeColTitle(this.props.modelType, this.props.type);
    this.setState({
      fileName: '',
      subckt: '',
      libraryId: '',
      pairs: this.initPairs(),
      nodes: []
    })
  }

  changeColTitle(modelType, type) {
    const { product, showCurrent } = this.props;
    const { pairs } = this.state
    if (product === ANDES_V2) {
      tableColumns[1].title = 'Touchstone Ports / SPICE Subckt Nodes';
      return;
    }

    if (type === "ssn_pdn") {
      tableColumns[0].title = product === CASCADE ? "Port / Net" : "Pin";
      tableColumns[1].title = [ROCKY, CASCADE].includes(product) ? "Node" : "Port / Node";

      if (product === CASCADE) {
        tableColumns[0].render = (text, record, index) => {
          const title = !isNaN(text) ? `Port ${text}` : text
          return <div>
            {title}
            {showCurrent && index !== pairs.length - 1 && <Tooltip
              title={`Set die current for ${title}`}
              overlayClassName="aurora-tooltip"
            >
              <div
                className='netlist-node-current'
                onClick={(e) => { this.props.openCurrentPanel(e, index, title) }}
              >
                <ArrowDownOutlined style={{ fontSize: '10px', color: '#798DA6' }} />
              </div>
            </Tooltip>}
          </div>
        }
      }
    } else {
      tableColumns[1].title = modelType.toUpperCase() === "SPICE" ? 'SPICE Subckt Nodes' : 'Touchstone Ports';
    }
  }

  initPairs() {
    const { pins, displayModel, getInitPairs } = this.props;
    if (Array.isArray(pins)) {
      let _pairs = displayModel === "schematic"
        ? getInitPairs()
        : pins.map(pin => {
          return { pin, node: '' }
        })
      return _pairs;
    } else {
      return [];
    }
  }

  selectTouchstoneFile = (key) => {
    const { touchstoneList } = this.props;
    let touchstone = [];
    const name = key.id ? key.name : key;
    if (key.id) {
      touchstone = { ...key };
    } else {
      if (Array.isArray(touchstoneList)) {
        touchstone = touchstoneList.find(item => item.name === name);
      }
    }
    let nodesList = getPortByTouchstone(name);
    this.setState({
      fileName: name,
      subckt: '',
      libraryId: touchstone ? touchstone.id : '',
      pairs: this.initPairs(),
      nodes: nodesList,
      version: key.id ? key.version : null
    })
  }

  selectSpiceFile = (key) => {
    const { spiceList, getFileContent, parseModelSelector, type } = this.props;
    let spice = [];
    const name = key.id ? key.name : key;
    if (key.id) {
      spice = { ...key }
    } else {
      if (Array.isArray(spiceList)) {
        spice = spiceList.find(item => item.name === name);
      }
    }
    if (spice) {
      this.setState({
        fileName: name,
        subckt: '',
        libraryId: spice.id,
        pairs: this.initPairs(),
        version: key.id ? key.version : null
      })
      getFileContent(spice.id, spice.name, 'SPICE').then(res => {
        let subcktList = [], defaultSubckt = {};
        if (type === "ssn_pdn") {
          subcktList = res && res.models && Array.isArray(res.models) ? [...res.models] : [];
          if (subcktList && subcktList.length === 1) {
            defaultSubckt = subcktList[0]
          }
        } else {
          subcktList = parseModelSelector(res)
        }
        if (res) {
          this.setState({
            subcktList: subcktList
          })
        }
        if (defaultSubckt && defaultSubckt.name) {
          this.setState({
            subckt: defaultSubckt.name,
            nodes: defaultSubckt.ports,
            pairs: this.initPairs()
          })
        }
      });
    }
  }

  selectCircuitFile = (key) => {
    const { spiceList, touchstoneList } = this.props;
    if (!key) {
      this.setState({
        fileName: "",
        subckt: '',
        libraryId: "",
        pairs: this.initPairs(),
        version: null,
        subcktList: [],
        nodes: [],
        type: "SPICE",
        touchstonePorts: []
      })
      return;
    }
    let file = spiceList.find(item => item.name === key);
    if (file) {
      this.selectSpice(key);
      return;
    }
    file = (touchstoneList || []).find(item => item.name === key);
    if (file) {
      this.selectCircuitTouchstone(file);
      return;
    }
  }

  selectSpice = (key) => {
    const { spiceList, getFileContent, parseModelSelector } = this.props;

    let file = spiceList.find(item => item.name === key);

    if (file) {
      this.setState({
        fileName: key,
        subckt: '',
        libraryId: file.id,
        pairs: this.initPairs(),
        version: null,
        subcktList: [],
        nodes: [],
        type: "SPICE"
      })
      getFileContent(file.id).then(res => {
        if (res) {
          this.setState({
            subcktList: parseModelSelector(res)
          })
        }
      })
    }
  }

  selectCircuitTouchstone = (file) => {
    const { getTouchstoneParse } = this.props;
    if (file) {
      this.setState({
        fileName: file.name,
        subckt: '',
        libraryId: file.id,
        pairs: this.initPairs(),
        version: null,
        subcktList: [],
        nodes: [],
        type: "touchstone"
      })
      getTouchstoneParse(file.id, file.name).then(res => {
        if (res && res.ports) {
          this.setState({
            nodes: res.ports.map(item => item.port),
            touchstonePorts: res.ports || []
          })
        }
      })
    }
  }

  selectSpiceSubckt = (key) => {
    const { subcktList } = this.state;
    const subckt = subcktList.find(item => item.name === key);
    this.setState({
      subckt: key,
      nodes: subckt ? subckt.ports : [],
      pairs: this.initPairs()
    })
  }

  connectPinAndNode = (record, prevRecord) => {
    const { pairs, nodes, subcktList, subckt, type, touchstonePorts } = this.state;
    const { modelType, product } = this.props;
    let _pairs = [...pairs], _nodes = [...nodes];
    const pinIndex = pairs.findIndex(item => item.pin === record.pin);
    if (_pairs[pinIndex].node) {
      _nodes.push(_pairs[pinIndex].node);
    }

    _pairs[pinIndex].node = record.node;
    if (product === ANDES_V2 && (modelType === "Termination" || (modelType === "Circuit" && type === "SPICE"))) {
      const subcktInfo = subcktList.find(item => item.name === subckt);
      const findIndex = subcktInfo.ports.findIndex(item => item === record.node);
      _pairs[pinIndex].index = findIndex;
    } else if (product === ANDES_V2 && modelType === "Circuit" && type === "touchstone") {
      const findIndex = touchstonePorts.findIndex(item => item.port === record.node);
      _pairs[pinIndex].index = findIndex;
    }
    this.setState({
      pairs: _pairs,
      nodes: _nodes.filter(item => item !== record.node)
    })
  }

  splitPinAndNodes = (record) => {
    const { pairs, nodes } = this.state;
    let _pairs = [...pairs], _nodes = [...nodes];
    const splitIndex = _pairs.findIndex(item => item.pin === record.pin);
    if (_pairs[splitIndex].node) {
      _nodes.push(_pairs[splitIndex].node);
      _pairs[splitIndex].node = '';
      delete _pairs[splitIndex].index
      this.setState({
        pairs: _pairs,
        nodes: _nodes.sort()
      })
    }
  }

  getNetListModel() {
    const { modelType } = this.props;
    const { fileName, libraryId, subckt, pairs, version, type } = this.state;
    return modelType === "Circuit" ?
      { fileName, libraryId, subckt, pairs: [...pairs], version, type }
      : { fileName, libraryId, subckt, pairs: [...pairs], version };
  }

  deleteFileSelect = (target) => {
    if (['touchstone', 'spice', 'termination', "circuit"].includes(target)) {
      this.setState({
        nodes: [],
        fileName: '',
        libraryId: '',
        subckt: '',
        pairs: this.initPairs(),
        subcktList: [],
        touchstonePorts: [],
        type: this.state.type || "SPICE"
      })
    } else if (target === 'subckt') {
      this.setState({
        nodes: [],
        subckt: '',
        pairs: this.initPairs()
      })
    }
  }

  getSelectList(type) {
    const { spiceList, touchstoneList, product, modelType, type: dataType } = this.props;
    const { subcktList, fileName, subckt, libraryId } = this.state;
    let _subcktList = product === ANDES_V2 && modelType === "Termination" ? subcktList.map(item => { return { ...item, disabled: item.ports.length !== 2 } }) : subcktList;
    const tree = product === SIERRA || (product === ROCKY && dataType === "ssn_pdn") || (product === CASCADE && type === 'spice') ? true : false;
    let cascadeSpiceList = [];
    if (product === CASCADE) {
      cascadeSpiceList = spiceList.map(item => {
        if (item.format && item.format === 'Folder') {
          item.children = item.children.map(item => {
            return { ...item, type: 'file' }
          })
          return { ...item, type: 'folder' }
        } else {
          return { ...item, type: 'file' }
        }
      })
    }
    switch (type) {
      case 'spice':
        return this.getSelectComponent(type, 'SPICE File', fileName, libraryId, this.selectSpiceFile, product === CASCADE ? cascadeSpiceList : spiceList, tree, dataType);
      case 'touchstone':
        return this.getSelectComponent(type, 'Touchstone File', fileName, libraryId, this.selectTouchstoneFile, touchstoneList, tree);
      case 'subckt':
        return this.getSelectComponent(type, 'Subckt', subckt, libraryId, this.selectSpiceSubckt, _subcktList);
      case 'termination':
        return this.getSelectComponent(type, 'Termination', fileName, libraryId, this.selectSpice, spiceList)
      case 'circuit':
        return this.getSelectComponent(type, 'Circuit', fileName, libraryId, this.selectCircuitFile, [...spiceList, ...(touchstoneList || [])])
      default: break;
    }
  }

  getSelectComponent(type, placeholder, value, libraryId, selectHandler, list, tree = false, dataType) {
    return tree ? <TreeSelect
      showSearch
      placeholder={placeholder}
      value={value}
      onSelectItem={selectHandler}
      className='netlist-model-selection'
      popupClassName='netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type) }} /> }}
      getPopupContainer={() => document.getElementById('root')}
      fileList={list}
      selected={dataType === "ssn_pdn" ? `${libraryId}-${value}` : libraryId}
      selectedkey={dataType === "ssn_pdn" ? "key" : null}
    />
      : <Select
        placeholder={placeholder}
        value={value}
        onSelect={selectHandler}
        className='netlist-model-selection'
        popupClassName='netlist-model-dropdown'
        popupMatchSelectWidth={false}
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type) }} /> }}
        getPopupContainer={() => document.getElementById('root')}
      >
        {list.map(item => <Option
          key={item.name}
          value={item.name}
          title={item.disabled ? 'The number of ports should be two' : item.name}
          disabled={item.disabled}
        >{item.name}</Option>)}
      </Select>;
  }

  getTable() {
    const { pairs } = this.state;
    return <EditableTable
      rowKey="pin"
      columns={tableColumns}
      size="small"
      selectType="node"
      dataSource={JSON.parse(JSON.stringify(pairs))}>
    </EditableTable>
  }

  savePairsAndNodes = (pairs, nodes) => {
    this.setState({
      pairs,
      nodes
    })
  }

  getPinPairsRender = () => {
    const { pairs, nodes, subcktList, subckt, type, touchstonePorts } = this.state;
    const { modelType, product, dirType, pins } = this.props;
    return <SchematicPairs
      pairs={pairs}
      nodes={nodes}
      product={product}
      modelType={modelType}
      subcktList={subcktList}
      subckt={subckt}
      dirType={dirType}
      pins={pins}
      type={type}
      touchstonePorts={touchstonePorts}
      savePairsAndNodes={this.savePairsAndNodes}
      getPinListByPairs={this.props.getPinListByPairs}
    />;
  }

  render() {
    const { modelType, displayModel } = this.props;
    const { type } = this.state;
    const isSpice = modelType === "Circuit" && type === "SPICE";
    const isTouchstone = modelType === "Circuit" && type === "touchstone";
    return (
      <div className='netlist-model-content'>
        {['SPICE', 'TERMINATION'].includes(modelType.toUpperCase()) || isSpice ? <div>
          <div className='netlist-model-simall-row'>
            <div className='netlist-model-row'>
              <span>File</span>
              {this.getSelectList(modelType.toLowerCase())}
            </div>
          </div>
          <div className='netlist-model-simall-row'>
            <div className='netlist-model-row'>
              <span>Subckt</span>
              {this.getSelectList('subckt')}
            </div>
          </div>
        </div> : (modelType.toUpperCase() === 'TOUCHSTONE' || isTouchstone) && <div className='netlist-model-row'>
          <span>File</span>
          {this.getSelectList(isTouchstone ? "circuit" : 'touchstone')}
        </div>}
        <div className="netlist-model-table" ref={this.connectedRef}>
          <div className="netlist-model-table-rows">
            {displayModel === "schematic" ? this.getPinPairsRender() : this.getTable()}
          </div>
        </div>
      </div>
    )
  }
}

export default NetListModel;