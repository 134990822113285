import http from '../http';

/**
 * Create or update eye mask
 * 2020/12/15
 *
 * @param {*} libraryId library id - When creating eye mask, the library id is an empty string
 * @param {*} type - DDR type, DDR3|DDR4|LPDDR4|DD3L
 * @param {*} data - Eye mask data
 * @param {String} name - eye mask name
 */
function createUpdateEyeMask({ libraryId, type, content, name, dataRate, caDataRate, csDataRate }) {
  return http.post(`/library/rocky/eyemask/edit`, { libraryId, type, content, name, dataRate, caDataRate, csDataRate });
};

/**
 * Get eye mask list
 * 2020/12/15
 *
 * @param {*} type - DDR type, DDR3|DDR4|LPDDR4|DD3L
 * @returns
 */
function getEyeMaskList(type) {
  return http.get(`/library/rocky/eyemask/list`, {
    params: { type }
  });
};

/**
 * Get eye mask data
 * 2020/12/15
 *
 * @param {*} libraryId
 * @param {*} origin - Standard|Aurora|Customized
 * @param {*} type - DDR type, DDR3|DDR4|LPDDR4|DD3L
 * @returns
 */
function getEyeMaskDetail({ libraryId, origin, type }) {
  return http.get(`/library/rocky/eyemask/detail`, {
    params: {
      libraryId, origin, type
    }
  });
};

/**
 * Get eye mask parameters
 *
 * @param {*} type - DDR type, DDR3|DDR4|LPDDR4|DD3L
 * @returns
 */
function getEyeMaskParameters(type) {
  return http.get(`/library/rocky/eyemask/parameters`, { params: { type } });
};

function deleteEyeMask(libraryId) {
  return http.post(`/library/rocky/eyemask/delete`, {
    libraryId
  });
};

function getVixSpec(type) {
  return http.get(`/rocky/vix/spec`, { params: { type } }); //DDR3/DDR4/LPDDR4/DDR3L
}

export { createUpdateEyeMask, getEyeMaskList, getEyeMaskDetail, getEyeMaskParameters, deleteEyeMask, getVixSpec };