import getIndex from '../../../../helper/insertionSearch';
import { FALLING, HIGH, LOW, HOLD, SETUP, RISING, VHIGH, VLOW, VINH, VINL, OVERSHOOT, UNDERSHOOT } from './markConstant';
import MarkNode from './markNode';
import { event } from 'd3-selection'

class MarkLine {
  constructor(props) {
    this.id = props.id;
    this.prevX = props.prevX;
    this.nodes = new Map();
    this.lineRootRoot = null;
    this.removeNode = this.removeNode.bind(this);
    this.delMarkLine = props.delMarkLine;
    this.getYValue = props.getYValue;
    this.getXValue = props.getXValue;
    this.targetX = props.targetX;
    this.xValue = props.xValue;

    this.x = props.x;
    this.anotherX = props.anotherX;
    this.padding = props.padding;
    this.height = props.height;
    this.type = props.type;

    this.y = props.y;
    this.yValue = props.yValue;
    this.targetY = props.targetY;
    this.width = props.width;
    this.anotherY = props.anotherY;

    this.extra = props.extra;
    this.usage = props.usage;//Driver/Receiver/Repeater/Stimulus in/Stimulus en
  }

  drawCrossNode(root, curves) {
    switch (this.type) {
      case FALLING:
      case RISING:
        this.drawTimePoint(root, curves);
        break;
      case SETUP:
      case HOLD:
      case HIGH:
      case LOW:
        this.drawTimeLine(root, curves);
        break;
      case VHIGH:
      case VLOW:
      case VINH:
      case VINL:
        this.drawMeasureLine(root, curves);
        break;
      case OVERSHOOT:
      case UNDERSHOOT:
        this.drawShootLine(root, curves);
        break;
      default:
        return;
    }
  }

  drawTimePoint(root, curves) {
    this.lineRoot = root.append('g')
      .attr('id', 'l-' + this.id)

    for (let curve of curves) {
      const color = curve.color;
      let xCurves = curve.x, yCurves = curve.y;
      const i = getIndex(xCurves, this.targetX, 0, xCurves.length - 1);

      //If no point is found, exit this loop
      if (isNaN(i)) {
        continue;
      }
      const { ym, targetY } = this.findCoordinate(i, xCurves, yCurves, this.targetX);
      // xValue
      const markNode = new MarkNode({
        hashId: curve.hashId,
        prevX: this.prevX,
        ym,
        yValue: `${this.xValue}`,
        color,
        x: this.x,
        targetX: this.targetX,
        targetY: targetY,
        getYValue: this.getYValue,
        getXValue: this.getXValue,
        type: this.type,
        extra: this.extra
      });
      this.nodes.set(curve.hashId, markNode);
      markNode.drawNode(this.lineRoot, this.removeNode)
    }
  }

  drawTimeLine(root, curves) {
    this.lineRoot = root.append('g')
      .attr('id', 'l-' + this.id)

    for (let curve of curves) {
      const color = curve.color;
      let xCurves = curve.x, yCurves = curve.y;
      const i = getIndex(xCurves, this.anotherX.targetX, 0, xCurves.length - 1);

      //If no point is found, exit this loop
      if (isNaN(i)) {
        continue;
      }
      const { yValue, ym, targetY } = this.findCoordinate(i, xCurves, yCurves, this.anotherX.targetX);
      // xValue
      const markNode = new MarkNode({
        hashId: curve.hashId,
        prevX: this.prevX,
        ym,
        yValue: `${this.xValue} ${yValue}V `,
        color,
        x: this.x,
        targetX: this.targetX,
        targetY: targetY,
        getYValue: this.getYValue,
        getXValue: this.getXValue,
        type: this.type,
        anotherX: this.anotherX,
        height: this.height,
        extra: this.extra
      });
      this.nodes.set(curve.hashId, markNode);
      markNode.drawArrowLine(this.lineRoot, this.removeNode)
    }
  }

  drawMeasureLine(root, curves) {
    this.lineRoot = root.append('g')
      .attr('id', 'l-' + this.id)

    for (let curve of curves) {
      const color = curve.color;

      // xValue
      const markNode = new MarkNode({
        hashId: curve.hashId,
        prevX: this.prevX,
        ym: this.y,
        yValue: this.yValue,
        color,
        targetY: this.targetY,
        getYValue: this.getYValue,
        getXValue: this.getXValue,
        type: this.type,
        width: this.width,
        extra: this.extra,
        usage: this.usage
      });
      this.nodes.set(curve.hashId, markNode);
      markNode.drawMeasure(this.lineRoot, this.removeNode)
    }
  }

  drawShootLine(root, curves) {
    this.lineRoot = root.append('g')
      .attr('id', 'l-' + this.id)

    for (let curve of curves) {
      const color = curve.color;
     /*  let xCurves = curve.x, yCurves = curve.y;
      const i = getIndex(xCurves, this.x, 0, xCurves.length - 1);

      //If no point is found, exit this loop
      if (isNaN(i)) {
        continue;
      } */
      // xValue
      const markNode = new MarkNode({
        hashId: curve.hashId,
        prevX: this.prevX,
        ym: this.y,
        yValue: this.yValue,
        color,
        x: this.x,
        targetX: this.targetX,
        targetY: this.targetY,
        getYValue: this.getYValue,
        getXValue: this.getXValue,
        type: this.type,
        anotherY: this.anotherY,
        extra: this.extra
      });
      this.nodes.set(curve.hashId, markNode);
      markNode.drawShootLine(this.lineRoot, this.removeNode)
    }
  }

  removeMarkLine = () => {
    this.lineRoot.remove();
    this.delMarkLine(this.id);
    event && event.stopPropagation();
  }

  findCoordinate(i, xCurves, yCurves, targetX) {
    let pointX = null, pointY = null, prevPointX = null, prevPointY = null, targetY = null, yValue = null;
    pointX = xCurves[i]; pointY = yCurves[i]; targetY = yCurves[i];
    if (i > 0 && i < xCurves.length - 1) {
      if (targetX === pointX) {
        targetY = yCurves[i];
      } else if (pointX > targetX) {
        prevPointX = xCurves[i - 1];
        prevPointY = yCurves[i - 1]
      } else if (pointX < targetX) {
        prevPointX = xCurves[i];
        prevPointY = yCurves[i];
        pointX = xCurves[i + 1];
        pointY = yCurves[i + 1]
      }
    } else if (i === 0 && xCurves.length >= 2) {
      if (targetX === pointX) {
        targetY = yCurves[i];
      } else if (pointX < targetX) {
        prevPointX = xCurves[i];
        prevPointY = yCurves[i];
        pointX = xCurves[i + 1];
        pointY = yCurves[i + 1]
      }
    } else if (i === xCurves.length - 1 && xCurves.length >= 2) {
      if (targetX === pointX) {
        targetY = yCurves[i];
      } else if (pointX > targetX) {
        prevPointX = xCurves[i - 1];
        prevPointY = yCurves[i - 1]
      }
    }

    //curves Only one point
    if (xCurves.length < 2) {
      targetY = yCurves[i]
    }

    let _targetY_ = [targetY].slice(0)[0];
    //InterpolateLinear
    if (xCurves.length >= 2) {
      _targetY_ = prevPointY + (pointY - prevPointY) / (pointX - prevPointX) * (targetX - prevPointX);
    }

    // Calculate the position based on the value of y
    let ym = this.getYValue(_targetY_);
    // yValue = numberToScientific(_targetY_);
    yValue = Number(_targetY_.toPrecision(3));

    return { yValue, ym, targetY: _targetY_ }
  }

  redrawLine = ({ padding, height }) => {
    this.padding = padding;
    this.height = height;
    const x = this.getXValue(this.targetX) + padding.left;
    this.x = x;

    if ([VHIGH, VLOW, VINH, VINL].includes(this.type)) {
      this.x = 0 + padding.left;
    }

    this.draw();
  }

  draw() {
    const pdTop = this.padding.top
    this.lineRoot.select('line')
      .attr('x1', this.x)
      .attr('x2', this.x)
      .attr('y1', pdTop)
      .attr('y2', this.height + pdTop)

    this.lineRoot.select('rect')
      .attr('x', this.x - 16)
      .attr('y', pdTop - 20)

    this.lineRoot.select('text')
      .attr('x', this.x - 14)
      .attr('y', pdTop - 4)
  }

  redrawNodes = ({ padding, yMin, yMax, height }) => {
    this.nodes.forEach(node => node.redrawNode({ padding, height, yMin, yMax }))
  }

  removeNode(hashId) {
    const node = this.nodes.get(hashId);
    if (node) {
      node.deleteNode();
      this.nodes.delete(hashId);
      if (!this.nodes.size) {
        this.removeMarkLine()
      }
    }
  }
}

export default MarkLine;