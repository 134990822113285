function getReportPdnIds(verificationSelect, currentInfo) {
  if (!verificationSelect.includes('all')) {
    return currentInfo.filter(obj => verificationSelect.some(obj1 => obj1 === obj.name)).map(d => d.id)
  } else {
    return currentInfo ? currentInfo.map(d => d.id).filter(id => id !== 'PDN') : [];
  }
}

export {
  getReportPdnIds
}