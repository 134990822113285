import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Select, Radio } from 'antd';
import UnitAddonAfter from "../../UnitAddonAfter";
import { numberCheck } from '../../../services/helper/dataProcess';
import { getPinsWithNetListAndComp } from '../../../services/PCBHelper';
import { getRefPinsByDistance } from '../../../services/ExtractionPortsHelper';
import '../../../publicCss/aurora.css';
import './index.css';

const Option = Select.Option;

class ReferenceComponentPin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectReferenceNets: [...props.referenceNets],
      referencePinType: props.pinType || "all",
      distance: "10",
      distanceUnit: "mm",
      maxPins: 10,
      errorMsg: null,
      pins: [],
      nets: [],
      allReferencePinList: []
    }
    this.dialogRoot = document.getElementById('root');
  }


  addonAfter = (type) => {
    const unit = this.state[`${type}Unit`];
    return UnitAddonAfter({
      unit,
      changeUnit: (key) => this.changeUnit(key, type),
      list: ["mm", "mil"]
    });
  }

  changeUnit = (unit, type) => {
    this.setState({
      [`${type}Unit`]: unit
    }, () => {
      const { allRefPins } = this.state;
      this.getPinsByRefNets(allRefPins);
    })
  }

  componentDidMount = () => {
    const allCompPins = this.getAllRefPins(this.props.referenceNets);
    this.getPinsByRefNets(allCompPins, true);
  }

  getAllRefPins = (referenceNets) => {
    const { findComponent, pcbInfo } = this.props;
    const compPins = getPinsWithNetListAndComp({
      netList: referenceNets,
      pcbNetsList: pcbInfo.netsList,
      layers: pcbInfo.layers,
      compName: findComponent.name
    });
    this.setState({
      allRefPins: [...compPins]
    });
    return compPins;
  }

  getPinsByRefNets = (allCompRefPins = [], defaultGet) => {
    const { reference: { pins = [], nets = [] }, componentPinInfo } = this.props;
    const { referencePinType, distance, distanceUnit, maxPins } = this.state;
    let allReferencePinList = [];
    if (referencePinType === "all") {
      allReferencePinList = [...allCompRefPins]
    } else {
      allReferencePinList = getRefPinsByDistance({
        componentPinInfo,
        maxPins,
        distance,
        distanceUnit,
        allCompRefPins
      })
    }
    this.setState({
      pins: pins.length && defaultGet ? [...pins] : allReferencePinList.map(item => item.pin),
      nets: nets.length && defaultGet ? [...nets] : [...new Set(allReferencePinList.map(item => item.net))],
      allReferencePinList
    })
  }

  closeModal = () => {
    const { pins, nets, referencePinType } = this.state;
    let _pins = [], _nets = [];
    if (referencePinType === "all") {
      _nets = nets;
    } else {
      _pins = pins;
    }
    this.props.closePanel(_pins, _nets);
  }

  updateRefNets = (keys) => {
    this.setState({
      selectReferenceNets: keys
    });
    const allCompPins = this.getAllRefPins(keys);
    this.getPinsByRefNets(allCompPins);
  }

  refPinTypeChange = (e) => {
    this.setState({
      referencePinType: e.target.value
    }, () => {
      const { allRefPins } = this.state;
      this.getPinsByRefNets(allRefPins);
    })
  }

  updateReferencePins = (keys) => {
    const { allReferencePinList } = this.state;
    const nets = [...new Set(allReferencePinList.filter(item => keys.includes(item.pin)).map(item => item.net))]
    this.setState({
      pins: keys,
      nets
    })
  }

  updateValue = (e, type) => {
    this.setState({
      [type]: e.target.value
    });
    const { errorMsg } = this.state;
    if (errorMsg && errorMsg.type === type) {
      this.updateError(null);
    }
  }

  valueOnBlur = (e, type) => {
    const value = e.target.value;
    const error = numberCheck(value);
    if (error) {
      this.updateError({ type, error: `${this.props.getLabel(type)} ${error}` });
    } else {
      const { allRefPins } = this.state;
      this.getPinsByRefNets(allRefPins);
    }
  }

  updateError = (errorMsg) => {
    this.setState({
      errorMsg
    })
  }

  render() {
    const { selectReferenceNets, referencePinType, pins, distance, maxPins, errorMsg, allReferencePinList } = this.state;
    const { referenceNets = [], getInputComponent } = this.props;
    const content = (
      <Panel
        className='extraction-ports-panel panel-x-scroll-hidden'
        title={<div className='extraction-ports-title'>Reference Pins</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={500}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={500}
      >
        <div className='extraction-ports-content'>
          <div className='signal-port-edit-item'>
            <label>Reference Nets</label>
            <Select
              className='aurora-select'
              mode={"multiple"}
              showSearch
              value={selectReferenceNets}
              onChange={(key) => this.updateRefNets(key)}
              popupMatchSelectWidth={false}
              popupClassName='aurora-select-dropdown'
            >
              {referenceNets.map(item =>
                <Option key={item} title={item}>{item}</Option>
              )}
            </Select>
          </div>
          <div className='signal-port-edit-item signal-port-edit-ball-item'>
            <label>Reference Pins​</label>
            <div className='signal-port-edit-ball-sub-item'>
              <div className='signal-port-edit-reference-sub-item'>
                <Radio.Group onChange={this.refPinTypeChange} value={referencePinType}>
                  <Radio
                    value="all"
                    className='extraction-ports-radio'
                  >All Pins on the reference nets​</Radio>
                  <Radio
                    value="specify"
                    className='extraction-ports-radio'
                  >Specified pins</Radio>
                </Radio.Group>
                {referencePinType === "specify" ? <Fragment>
                  <Select
                    className='aurora-select'
                    mode={"multiple"}
                    showSearch
                    value={pins}
                    onChange={(keys) => this.updateReferencePins(keys)}
                    popupMatchSelectWidth={false}
                    popupClassName='aurora-select-dropdown'
                  >
                    {allReferencePinList.map(item => (
                      <Option key={item.pin} title={item.pin}>{item.pin}</Option>))}
                  </Select>
                  {getInputComponent({
                    value: distance,
                    className: "signal-port-edit-sub-item",
                    type: "distance",
                    updateValue: this.updateValue,
                    valueOnBlur: this.valueOnBlur,
                    addonAfter: this.addonAfter
                  })}
                  {getInputComponent({
                    value: maxPins,
                    className: "signal-port-edit-sub-item",
                    type: "maxPins",
                    updateValue: this.updateValue,
                    valueOnBlur: this.valueOnBlur,
                  })}
                </Fragment> : null
                }
                {errorMsg ? <span className="aurora-model-name-error-msg ">{errorMsg.error}</span> : null}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default ReferenceComponentPin;