import Plot2D from '../../Result/Public/waveform/Plot2D';
class WaveFormPlot {
  constructor() {
    this.plot = null;
  }

  getCurves() {
    if (this.plot && this.plot.curves) {
      return this.plot.curves;
    }
  }

  setCurves(curves) {
    if (curves && curves.length > 0) {
      this.plot.plotCurves(curves, false, null, null, null);
      this.plot.redrawPlot(this.plot.svgElement, null);
    }
  }

  /** ------ implementation of WaveformResultHelper.plotWaveform ------
 *  Draw the raw curves
 *  @param  svgElement   - the SVG element where the plot is added
 *  @param  rawCurveData - a WaveformResultHelper.WaveformData object, containing the curve data of a byte
 *  @return a WaveformResultHelper.Plot object with a handle to the plot
 */
  plotWaveform = ({ svgElement, ui = 1e-9, events, waveform, axis, id = null, padding = null, colorIndex = 0, pair }) => {
    let nameElement = [];
    if (id) nameElement.push(id);
    if (pair) nameElement.push(pair);
    if (this.plot === null) {
      const { PlotOptions, CurveItem } = Plot2D;
      // plot options
      const plotOptions = new PlotOptions();
      plotOptions.xUnit = axis.xUnit || 's';
      plotOptions.yUnit = axis.yUnit;
      plotOptions.grid = 'xy';
      plotOptions.zoom = 'x';
      plotOptions.xLabel = axis.xLabel || 'Time';
      plotOptions.yLabel = axis.yLabel;
      plotOptions.ui = ui; //unit interval - approximate data cycle length
      this.plot = new Plot2D(svgElement, plotOptions, events, 'andes');
      const { signals, time, curveData, historyTime } = waveform;
      // prepare the curve data
      let curves = [];
      for (let i = 0; i < signals.length; i++) {
        const signal = signals[i];
        if (signal.name) {
          const _newElement = [...nameElement];
          _newElement.splice(1, 0, signal.name);
          let newCurve = new CurveItem();
          newCurve.x = time;
          newCurve.y = curveData[i];
          newCurve.name = _newElement.join('::');
          newCurve.time = historyTime;
          newCurve.curveId = `${historyTime}-${i + colorIndex}`;
          newCurve.curveIndex = i + colorIndex;
          newCurve.id = id;
          curves.push(newCurve);
        }
      }
      // display the curves
      this.plot.type = "andes";
      this.plot.plotCurves(curves, false, padding);
    } else {
      // TODO - re generate tdr,sbr
      if (id) {
        const curves = this.plot.curves;
        const findIndex = curves.findIndex(c => c.id === id);
        const len = curves.filter(c => c.id === id).length;
        this.plot.removeCurves(findIndex, len);
      }
      const { CurveItem } = Plot2D;
      const { signals, time, curveData, historyTime } = waveform;
      // prepare the curve data
      let curves = [];
      for (let i = 0; i < signals.length; i++) {
        const signal = signals[i];
        if (signal.name) {
          const _newElement = [...nameElement];
          _newElement.splice(1, 0, signal.name)
          let newCurve = new CurveItem();
          newCurve.x = time;
          newCurve.y = curveData[i];
          newCurve.name = _newElement.join('::');
          newCurve.time = historyTime;
          newCurve.curveId = `${historyTime}-${i + colorIndex}`;
          newCurve.curveIndex = i + colorIndex;
          newCurve.id = id;
          curves.push(newCurve);
        }
      }
      this.plot.plotCurves(curves, true, padding, null, null, colorIndex);
      this.plot.redrawPlot(svgElement, padding);
    }
    return this.plot;
  } // plotWaveform


  updataRawCurvePlot = () => {
    this.plot.updatePlot();
  }

  getCurvesData = () => {
    const { signals, time, curveData } = this;
    const { CurveItem } = Plot2D;
    // prepare the curve data
    var curves = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      if (signal.name) {
        let newCurve = new CurveItem();
        newCurve.x = time;
        newCurve.y = curveData[i];
        newCurve.name = signal.name;
        newCurve.curveId = `${time}-${i}`;
        curves.push(newCurve);
      }
    }

    return curves;
  }

  cleanWaveformPlot = () => {
    this.plot = null;
  }
};

const waveFormPlot = new WaveFormPlot();
export default waveFormPlot;
export { WaveFormPlot }