import React, { Component, Fragment, createRef } from "react";
import { Select, List, Tooltip } from "antd";
import {
  DECAP_TOUCHSTONE,
  DECAP_SPICE,
  DECAP_RLC,
} from "@/constants/libraryConstants";
import "./index.css";
import { DECAP_DATA, DECAP_FILE } from "../../pages/PDN/constants";
import { parseSPModelSelector } from "@/services/Library";

const Option = Select.Option;

class DecapModelSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subcktOpen: false,
      subcktType: "",
      selectCkts: [],
      params: {},
      hasParam: -1,
      decapModelOpen: false,
      search: {
        model: "",
        subckt: "",
      },
    };
    this.selectRef = createRef();
    this.selectDecapRef = createRef();
    this.selectDecapTypeRef = createRef();
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { models } = this.props;
    if (models && models.length) {
      const model = models[0];
      this.getFile(model, 0);
    }
  }

  getFile = (model, index, _models) => {
    if (!model || !model.id) {
      return;
    }
    const models = _models ? _models : this.props.models;
    if (model.type === "spice") {
      this.getSubckts({ modelId: model.id, model, models, index });
    } else if (model.type === "touchstone" && model.libraryType === "folder") {
      const { decapList } = this.props;
      const findItem = decapList.find((item) => item.id === model.id);
      const isOpen = model.name ? false : true;
      this.getTouchstoneFiles(findItem, isOpen);
    }
  }

  valueSearch = (value, type) => {
    this.setState({
      search: {
        ...this.state.search,
        [type]: value,
      },
    });
  };

  selectDecap = (decapType, modelKey) => {
    const { models } = this.props;
    let _models = [...models];
    const index = _models.findIndex((item) => item.key === modelKey);
    if (index < 0) {
      return;
    }
    _models[index] = { ...models[index], type: decapType, libraryType: "", subcktName: "", id: "", name: "" };
    _models[index] = this.autoAddFile(_models[index]);
    this.props.updateModels(_models);

    this.setState({
      decapModelOpen: _models[index].id ? false : true,
      subcktOpen: false,
      selectCkts: [],
    }, () => {
      this.getFile(_models[index], index, _models);
      this.selectDecapTypeRef.current.blur();
    })
  };

  autoAddFile = (model) => {
    const { decapList } = this.props;
    const list = this.getDecapLists(decapList, model.type);
    if (!model.id && list.length === 1) {
      const modelFile = list[0];
      if (model.type === "spice") {
        model = {
          ...model,
          id: modelFile.id,
          name: modelFile.name,
          subcktName: "",
          libraryType: "file"
        }
      }
      else if (model.type === "touchstone") {
        model = {
          ...model,
          id: modelFile.id,
          subcktName: "",
          libraryType: modelFile.type
        }
        if (modelFile.type === "file") {
          model.name = modelFile.name;
        } else if (modelFile.type === "folder") {
          model.folderName = modelFile.name;
          if (modelFile.children.length === 1) {
            model.name = modelFile.children[0].name;
          }
        }
      } else if (model.type === "rlc") {
        model = {
          ...model,
          id: modelFile.id,
          name: modelFile.name,
          subcktName: "",
          libraryType: "data"
        }
      }
    }
    return model;
  }

  changeMenu = ({ model, index }) => {
    const { models } = this.props;
    let _models = [...models];
    _models[index] = this.autoAddFile(model);;
    this.props.updateModels(_models);
    let _subcktOpen = false;
    if (models[index].id) {
      if ((_models[index].type === "spice" && !_models[index].subcktName)
        || (_models[index].type === "touchstone" && models[index].id && !_models[index].name)) {
        _subcktOpen = true;
      }
    }
    this.setState({
      decapModelOpen: _models[index].id ? false : true,
      subcktOpen: _subcktOpen,
      selectCkts: []
    }, () => {
      this.selectDecapRef && this.selectDecapRef.current && this.selectDecapRef.current.blur();
      this.selectRef && this.selectRef.current && this.selectRef.current.blur();
      this.getFile(_models[index], index, _models);
    })
  }

  selectModel = (modelId, key) => {
    const { models, decapList } = this.props;
    let _models = [...models];
    const index = _models.findIndex((item) => item.key === key);
    if (index < 0) {
      return;
    }
    let model = _models[index];
    // Save editname
    const editName = model.editName;
    const findItem = decapList.find((item) => item.id === modelId);
    if (
      modelId === model.id &&
      (findItem.dataType !== DECAP_TOUCHSTONE ||
        findItem.dataType !== DECAP_FILE ||
        findItem.dataType !== DECAP_DATA)
    ) {
      this.setState({
        decapModelOpen: false,
      });
      this.selectDecapRef.current.blur();
      return;
    }
    let type = "";
    if (findItem.dataType === DECAP_TOUCHSTONE) {
      type = "touchstone";
    } else if (findItem.dataType === DECAP_FILE) {
      type = "spice";
    } else {
      type = "rlc";
    }
    const _model = {
      id: findItem.id,
      name:
        findItem.dataType === DECAP_TOUCHSTONE && findItem.type === "folder"
          ? ""
          : findItem.name,
      subcktName: "",
      type: type,
      libraryType: findItem.type,
      folderName:
        findItem.dataType === DECAP_TOUCHSTONE && findItem.type === "folder"
          ? findItem.name
          : "",
      key: key,
      editName: editName,
    };
    _models[index] = _model;
    this.props.updateModels(_models);

    if (type === "spice") {
      this.setState(
        {
          decapModelOpen: false,
        },
        () => {
          this.getSubckts({
            modelId,
            modelKey: key,
            models: _models,
            model: _model,
            index,
          });
          this.selectDecapRef.current.blur();
        }
      );
    } else if (type === "touchstone") {
      this.setState(
        {
          decapModelOpen: false,
        },
        () => {
          this.getTouchstoneFiles(findItem);
          this.selectDecapRef.current.blur();
        }
      );
    }
    else {
      this.setState({
        decapModelOpen: false,
      });
    }
    this.setState({
      subcktOpen: true
    })
    this.selectDecapRef.current.blur();
  };

  selectSubckt = (name, key) => {
    const { models } = this.props;
    let _models = [...models];
    const index = _models.findIndex((item) => item.key === key);
    if (index < 0) {
      return;
    }
    let model = _models[index];
    if (model.type === "touchstone" && model.libraryType === "folder") {
      model.name = name;
    } else {
      model.subcktName = name;
    }
    _models[index] = model;
    this.props.updateModels(_models);
    this.setState({
      subcktOpen: false,
      search: {
        model: "",
        subckt: "",
      },
    });
    this.selectDecapRef.current.blur();
    this.selectRef.current.blur();
  };

  getSubckts = ({ modelId, model, index, models }) => {
    this.props.getLibraryFile(modelId).then(
      (res) => {
        const { models: subckts, type } = parseSPModelSelector(res);
        if (subckts.length === 1) {
          model.subcktName = subckts[0].name;
          this.setState({
            subcktOpen: false,
            subcktType: type,
          });
          models[index] = model;
          this.props.updateModels(models);
        }

        if (subckts.length > 1 && !model.subcktName) {
          this.setState({
            subcktOpen: true,
            subcktType: type,
          });
        }
        this.setState({
          selectCkts: subckts,
          subcktType: type,
          decapModelOpen: false,
        });
      },
      (error) => {
        this.setState({
          selectCkts: [],
        });
      }
    );
  };

  getTouchstoneFiles = (findItem, isOpen) => {
    setTimeout(() => {
      this.setState({
        selectCkts: (findItem && findItem.children) ? findItem.children : [],
        decapModelOpen: false,
        subcktOpen: isOpen,
      });
    }, 100);
  };

  getDecapLists = (decapList, decapType) => {
    if (decapType === "touchstone") {
      return decapList.filter((item) => item.dataType === DECAP_TOUCHSTONE);
    } else if (decapType === "spice") {
      return decapList.filter((item) =>
        ["decap_file", DECAP_SPICE].includes(item.dataType)
      );
    } else {
      return decapList.filter((item) =>
        ["decap_data", DECAP_RLC].includes(item.dataType)
      );
    }
  };

  subcktSelect = (value, key) => {
    const { models } = this.props;
    let _models = [...models];
    const index = _models.findIndex((item) => item.key === key);
    if (index < 0) {
      return;
    }
    const model = _models[index];
    this.setState({
      subcktOpen: false,
    });
    this.selectRef.current.blur();
    if (model.subcktName === value) {
      this.saveModel();
    }
  };

  subcktSelectList = (model) => {
    const { subcktType, params, hasParam, selectCkts, search } = this.state;
    const { trigger = "root" } = this.props;
    return (
      <div className="pdn-model-selection-dropdown">
        <ul className="menu-list">
          {subcktType === "normal"
            ? selectCkts
              .filter((item) =>
                item.name.toLowerCase().includes(search.subckt.toLowerCase())
              )
              .map(({ name, ports }) => (
                <li
                  key={name}
                  title={name}
                  value={name}
                  className={name === model.subcktName ? "menu-selected-item" : ""}
                  onMouseDown={() => this.selectSubckt(name, model.key)}
                >
                  {name}
                </li>
              ))
            : selectCkts
              .filter((item) =>
                item.name.toLowerCase().includes(search.subckt.toLowerCase())
              )
              .map(({ name, ports, parameter }, index) => (
                <li
                  key={name}
                  value={name}
                  className={name === model.subcktName ? `menu-selected-item` : ""}
                  onMouseEnter={() => {
                    this.displayTooltip(parameter, index);
                  }}
                  onMouseLeave={() => {
                    this.unDisplayTooltip();
                  }}
                  onMouseDown={() => this.selectSubckt(name, model.key)}
                >
                  {hasParam === index && (
                    <Tooltip
                      title={this.parameterTooltip(params)}
                      overlayClassName="aurora-tooltip system-library-tooltip"
                      placement="left"
                      defaultOpen={true}
                      getPopupContainer={() =>
                        document.getElementById(trigger)
                      }
                    />
                  )}
                  {name}
                </li>
              ))}
        </ul>
      </div>
    );
  };

  modelSelectList = (decapType, modelKey, modelId) => {
    const { decapList } = this.props;
    const { search } = this.state;
    const _decapList = this.getDecapLists(decapList, decapType);
    return _decapList && _decapList.length ? (
      <div className="pdn-model-selection-dropdown">
        <ul className="menu-list">
          {_decapList.filter((decap) =>
            decap.name.toLowerCase().includes(search.model.toLowerCase())
          )
            .map((decap) => (
              <li
                key={decap.id}
                title={decap.name}
                className={decap.id === modelId ? "menu-selected-item" : ""}
                onMouseDown={() => this.selectModel(decap.id, modelKey)}
              >
                {decap.name}
              </li>
            ))}
        </ul>
      </div>
    ) : (
      <Fragment></Fragment>
    );
  };

  decapModelFocus = (e) => {
    this.setState({
      subcktOpen: false,
      decapModelOpen: true,
    });
  };

  subcktFocus = (e) => {
    this.setState({
      subcktOpen: true,
      decapModelOpen: false,
    }, () => {
      this.selectDecapRef.current.blur();
    });
  };

  decapTypeFocus = (e) => {
    this.setState({
      decapModelOpen: false,
      subcktOpen: false,
    });
    this.selectDecapRef && this.selectDecapRef.current && this.selectDecapRef.current.blur();
    this.selectRef && this.selectRef.current && this.selectRef.current.blur();
  };

  displayTooltip = (params, index) => {
    this.setState({
      hasParam: index,
      params: params,
    });
  };

  unDisplayTooltip = () => {
    this.setState({
      hasParam: -1,
      params: {},
    });
  };

  parameterTooltip = (param) => {
    if (!param) {
      return;
    }
    let ModelName = param.ModelName ? param.ModelName : "",
      Manuf = param.Manuf ? param.Manuf : "",
      MfgPartNumber = param.MfgPartNumber ? param.MfgPartNumber : "",
      Size = param.Size ? param.Size : "",
      Cnom = param.Cnom ? param.Cnom : "",
      Volt = param.Volt ? param.Volt : "";
    return (
      <div>
        <span>ModelName = {ModelName}</span>
        <br />
        <span>Manuf = {Manuf}</span>
        <br />
        <span>MfgPartNumber = {MfgPartNumber}</span>
        <br />
        <span>Size = {Size}</span>
        <br />
        <span>Cnom = {Cnom}</span>
        <br />
        <span>Volt = {Volt}</span>
        <br />
      </div>
    );
  };

  render() {
    const { selectedKeys } = this.props;

    return (
      <List
        itemLayout="horizontal"
        dataSource={selectedKeys}
        renderItem={(item) => (
          <List.Item>{this.modelItemRender(item)}</List.Item>
        )}
      />
    );
  }

  modelItemRender = (key) => {
    const {
      decapModelOpen,
      subcktOpen,
    } = this.state;
    const { models, trigger } = this.props;
    const model = models.find((item) => item.key === key);
    if (!model) {
      return null;
    }
    let name = model.name;
    let defaultName = model.subcktName;
    if (model.type === "touchstone" && model.libraryType === "folder") {
      name = model.folderName;
      defaultName = model.name;
    }
    return (
      <div className="pdn-model-box">
        <div className="pdn-model-box-title">
          <h4
            className="pdn-model-box-title-content"
            title={model.editName}
          >
            {model.editName ? model.editName : `${key * 1 + 1}`}</h4>
        </div>
        <Fragment>
          <div className="pdn-model clear">
            <Select
              value={model.type}
              placeholder="Type"
              onChange={(_key) => this.selectDecap(_key, key)}
              onFocus={this.decapTypeFocus}
              ref={this.selectDecapTypeRef}
              className="pdn-model-selection"
              showSearch
              getPopupContainer={() =>
                document.getElementById(trigger)
              }
              dropdownStyle={{ zIndex: 10000000 }}
            >
              <Option key="touchstone">Touchstone</Option>
              <Option key="spice">SPICE</Option>
              <Option key="rlc">RLC</Option>
            </Select>
          </div>
          <div className="pdn-model clear">
            <Select
              onRef={this.onRef}
              value={name || undefined}
              placeholder="File/Folder"
              className="pdn-model-selection"
              ref={this.selectDecapRef}
              open={decapModelOpen}
              onFocus={this.decapModelFocus}
              //could search
              showSearch
              onSearch={(value) => this.valueSearch(value, "model")}
              getPopupContainer={() =>
                document.getElementById(trigger)
              }
              dropdownStyle={{ zIndex: 10000000 }}
              dropdownRender={() => this.modelSelectList(model.type, key, model.id)}
            />
          </div>
          {(model.type === "spice" ||
            (model.type === "touchstone" && model.libraryType === "folder")) && (
              <div className="pdn-model  clear">
                <Select
                  value={defaultName || undefined}
                  placeholder={model.type === "touchstone" ? "File" : "Subckt"}
                  open={subcktOpen}
                  onFocus={this.subcktFocus}
                  className="pdn-model-selection"
                  ref={this.selectRef}
                  showSearch
                  onSearch={(value) => this.valueSearch(value, "subckt")}
                  getPopupContainer={() =>
                    document.getElementById(trigger)
                  }
                  dropdownStyle={{ zIndex: 10000000 }}
                  dropdownRender={() => this.subcktSelectList(model)}
                  popupMatchSelectWidth={false}
                ></Select>
              </div>
            )}
        </Fragment>
      </div>
    );
  };
}
export default DecapModelSelection;
