import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutData from '@/services/data/LayoutData';
import * as canvas from '@/services/LayoutCanvas/oldCanvas';
import {
  selectLayer,
  canvasSelect,
  openPanel,
  closePanel,
  updateLocation,
  cleanStatus,
  cancelSelectedAll,
  changeDisplaySelected
} from '../store/Andes/actionCreators';
import ContextMenu from '@/components/ContextMenu';
import SearchBox from './SearchBox';
import LayerTab from './LayerTab';
import Coord from '../Coord';
import Stackup from './stackup';
import ComponentTable from './DesignInfo/ComponentTable';
import NetTable from './DesignInfo/NetTable';
import { Spin } from 'antd';
import makeCancelable from '@/services/api/makeCancelable';
import { refreshPCB } from '../../Andes/store/project/action';
import menu from '../../../components/LayoutExplorer/menu';
import { getMetalCompLayers } from '@/services/PCBHelper';
import { equal } from '../../../services/utility/ArrayHelper';
import '../index.css';
import '../pdnLayoutStyle.css';

class LayoutExplorer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.locationMark = React.createRef();
    this.state = {
      metalLayers: [],
      contextMenu: {},
      searchData: [],
      loading: true,
      maxWidth: null
    }
  }

  changeMouse = (x, y) => {
    this.props.updateLocation(x.toFixed(2) + ', ' + y.toFixed(2));
  }

  canvasSelect = (canvasObj) => {
    if (canvasObj && canvasObj.nets && canvasObj.comps) {
      const { leftWidth } = this.props;
      const { nets, comps, x, y, multi } = canvasObj;
      this.setState({
        contextMenu: {
          style: {
            display: 'block',
            top: y - 64,
            left: x - leftWidth
          },
          items: [...nets, ...comps],
          onClick: (e) => {
            const name = e.target.innerText;
            const obj = { multi };
            if (nets.includes(name)) {
              obj.nets = [name];
            } else {
              obj.comps = [name];
            }
            this.props.select(obj);
            if (!this.props.displayBoxShow) {
              this.props.changeDisplaySelected(true)
            }
            this.setState({
              contextMenu: {}
            })
          }
        }
      })
    } else {
      this.props.select(canvasObj);
      this.setState({
        contextMenu: {}
      })
    }
  }

  fitView(e) {
    e.stopPropagation();
    canvas.fitView();
  }

  zoomIn(e) {
    e.stopPropagation();
    canvas.zoomIn();
  }

  zoomOut(e) {
    e.stopPropagation();
    canvas.zoomOut();
  }

  getComponentLength(pcbId) {
    const metalLayers = LayoutData.getLayout(pcbId).GetLayerManager().mMetalLayers;
    let length = 0;
    for (const layer of metalLayers) {
      const { mComponentLayer } = layer;
      if (!mComponentLayer) continue;
      const { mComponents } = mComponentLayer;
      length += mComponents.length;
    };
    return length;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.designID) return;
    this.props.closePanel(this.props.designID);
    this.setState({
      loading: true,
    })
    this.props.cleanStatus();
    let _vendor = null;
    const pcbId = this.props.designID;
    const getLayoutInfoPromise = LayoutData.getLayoutInfo(pcbId)
    this.cancelable = makeCancelable(getLayoutInfoPromise);
    this.cancelable.promise.then(res => {
      const { vendor } = res;
      _vendor = vendor;
    });

    const loadLayoutDBPromise = LayoutData.LoadLayoutDB(pcbId);
    this.cancelableLoadLayout = makeCancelable(loadLayoutDBPromise);
    this.cancelableLoadLayout.promise.then(res => {
      LayoutData.saveNetsList(pcbId);
      const events = {
        click: this.canvasSelect,
        mousemove: this.changeMouse
      };
      const _DesignData = LayoutData.getLayout(pcbId);
      canvas.initLayout(this.svgRef.current, _DesignData, this.locationMark.current, events);
      if (_vendor === 'AURORA_DB' || _vendor === 'AURORA_AAF') {
        this.generateStackup(pcbId);
      }
      const _LayerManager = _DesignData.GetLayerManager();
      const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;
      const topComp = _LayerManager.GetMetalCompLayer(metalLayerNames[0]);
      if (this._isMounted) {
        this.setState({
          metalLayers: getMetalCompLayers(metalLayerNames, _LayerManager),
          unit: _DesignData.GetLayoutUnits()
        });
        // set the first layer's component layer to be visible
        if (this.getComponentLength(pcbId) < 1000) {
          if (topComp) {
            this.props.selectLayer([topComp.GetName(), metalLayerNames[0]]);
          } else {
            this.props.selectLayer([metalLayerNames[0]]);
          }
        }
        this.setState({
          loading: false,
        })
      }
    }, error => {
      console.log(error);
    });

    window.addEventListener('resize', this.resize);
    this.getPanelWidthHeight()
  }

  componentDidUpdate(prevProps, prevState) {
    const { refreshStatus, designID } = this.props;
    if (prevProps.designID !== this.props.designID || (refreshStatus && refreshStatus !== prevProps.refreshStatus)) {
      this.setState({
        loading: true,
        contextMenu: {}
      })
      this.props.closePanel(designID);
      this.props.refreshPCB(false);
      let _vendor = null;
      const pcbId = this.props.designID;
      const getLayoutInfoPromise = LayoutData.getLayoutInfo(pcbId);
      this.cancelable = makeCancelable(getLayoutInfoPromise);
      this.props.cleanStatus();
      this.cancelable.promise.then(res => {
        const { vendor } = res;
        _vendor = vendor;
      });

      const loadLayoutDBPromise = LayoutData.LoadLayoutDB(pcbId);
      this.cancelableLoadLayout = makeCancelable(loadLayoutDBPromise);
      this.cancelableLoadLayout.promise.then(res => {
        LayoutData.saveNetsList(pcbId);
        const events = {
          click: this.canvasSelect,
          mousemove: this.changeMouse
        };
        this.svgRef.current.removeChild(this.svgRef.current.children[0])
        const _DesignData = LayoutData.getLayout(pcbId);
        canvas.initLayout(this.svgRef.current, _DesignData, this.locationMark.current, events);
        if (_vendor === 'AURORA_DB' || _vendor === 'AURORA_AAF') {
          this.generateStackup(pcbId);
        }
        const _LayerManager = _DesignData.GetLayerManager();
        const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;
        const topComp = _LayerManager.GetMetalCompLayer(metalLayerNames[0]);
        if (this._isMounted) {
          this.setState({
            metalLayers: getMetalCompLayers(metalLayerNames, _LayerManager),
            unit: _DesignData.GetLayoutUnits()
          });
          // set the first layer's component layer to be visible
          if (this.getComponentLength(pcbId) < 1000) {
            if (topComp) {
              this.props.selectLayer([topComp.GetName(), metalLayerNames[0]]);
            } else {
              this.props.selectLayer([metalLayerNames[0]]);
            }
          }
          this.setState({
            loading: false,
          })
        }
      }, error => {
        console.log(error);
      });
    }

    this.getPanelWidthHeight(prevState);
  }

  getPanelWidthHeight = (prevState) => {
    this.layoutRef = document.getElementsByClassName('aurora-PCB-layout-content')[0];
    if (this.layoutRef) {

      const maxWidth = this.layoutRef.offsetWidth;
      const maxHeight = this.layoutRef.offsetHeight;
      if (!prevState || (maxWidth && (maxWidth - 100) !== prevState.maxWidth)) {
        this.setState({
          maxWidth: maxWidth - 100
        });
      }
      if (!prevState || (maxHeight && (maxHeight - 84) !== prevState.maxHeight)) {
        this.setState({
          maxHeight: maxHeight - 84
        });
      }
    }
  }

  generateStackup(pcbId) {
    LayoutData.getStackup(pcbId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { checkedlayers: prevLayers, selectedNets: prevNets, selectedComps: prevComps, colorBy: prevColorBy } = this.props;
    const { checkedlayers: layers, selectedNets, selectedComps, colorBy } = nextProps;
    if (layers !== prevLayers) {
      canvas.updateLayers(layers, prevLayers);
      // return true;
    }

    if (colorBy !== prevColorBy) {
      canvas.changeColorMode(colorBy);
      return false;
    }

    if (!equal(selectedComps, prevComps) || !equal(selectedNets, prevNets)) {
      let selectLayer = canvas.findCurrentLayer(selectedComps);
      let newLayer = Array.from(new Set([...layers, ...selectLayer]));
      if (newLayer.length !== layers.length) {
        this.props.selectLayer(newLayer);
        setTimeout(() => {
          canvas.selectNetCompPin({ nets: selectedNets, comps: selectedComps })
        }, 500);
      } else {
        canvas.selectNetCompPin({ nets: selectedNets, comps: selectedComps })
      }
      // return false;
    }
    return true;
  }

  onSearch = (value) => {
    return canvas.search(value);
  }

  stackupRef = (ref) => {
    this.stackupChild = ref;
  };

  getPanel = () => {
    const { panelType, leftWidth, designID } = this.props;
    const { maxWidth, maxHeight } = this.state;
    const _maxWidth = maxWidth > 50 ? maxWidth : 50;
    switch (panelType) {
      case 'Stackup':
        return <Stackup
          {...this.props}
          maxHeight={maxHeight}
          maxWidth={_maxWidth}
          leftWidth={leftWidth}
          _closePanel={() => this.props.closePanel(designID)}
        />
      case 'Components':
        return <ComponentTable maxHeight={maxHeight} maxWidth={_maxWidth} leftWidth={leftWidth} />
      case 'Nets':
        return <NetTable maxHeight={maxHeight} maxWidth={_maxWidth} leftWidth={leftWidth} />
      default: return null;
    }
  }

  menuSelect = ({ item, key, keyPath, domEvent }) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();
    this.props.openPanel(key);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.cancelable) {
      this.cancelable.cancel();
    };
    if (this.cancelableLoadLayout) {
      this.cancelableLoadLayout.cancel();
    }
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getPanelWidthHeight()
  }

  select = (canvasObj) => {
    this.props.select(canvasObj);
    if (!this.props.displayBoxShow) {
      this.props.changeDisplaySelected(true)
    }
  }

  render() {
    const { unit, metalLayers, contextMenu } = this.state;
    const { width, leftWidth } = this.props;

    const { loading } = this.state;

    return (
      <React.Fragment>
        <Spin size='large' spinning={loading} delay={400}>
          <svg className='canvas' ref={this.svgRef} />
        </Spin>
        <svg className="location-mark" viewBox="0 0 24 24" ref={this.locationMark} >
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <div className="btn-group-vertical">
          {menu(this.menuSelect)}
        </div>
        <Coord unit={unit} className='canvas-coord' />
        {this.getPanel()}
        <SearchBox className="global-search"
          onSearch={this.onSearch}
          onSelect={this.select}
        />
        <LayerTab metalLayers={metalLayers} getColor={canvas.getLayerColor} width={width} leftWidth={leftWidth} />
        <ContextMenu {...contextMenu} />
      </React.Fragment>
    )
  }
}

const mapState = (state) => {
  const {
    selectedNets,
    selectedComps,
    layers: checkedlayers,
    panelType,
    colorBy,
    simulation,
    displayBoxShow
  } = state.AndesReducer.explorer;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  const { designID, refreshStatus } = state.AndesReducer.project;
  return {
    selectedNets, checkedlayers, selectedComps, panelType, colorBy, simulation, designID, defaultLeft,
    defaultTop, displayBoxShow, refreshStatus
  }
}


const mapDispatch = (dispatch) => ({
  updateLocation(location) {
    dispatch(updateLocation(location))
  },
  select(canvasObj) {
    dispatch(canvasSelect(canvasObj))
  },
  selectLayer(checkedList) {
    dispatch(selectLayer(checkedList));
  },
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
  cleanStatus() {
    dispatch(cleanStatus())
  },
  cancelSelectedAll() {
    dispatch(cancelSelectedAll())
  },
  changeDisplaySelected(show) {
    dispatch(changeDisplaySelected(show))
  },
  refreshPCB(refreshStatus) {
    dispatch(refreshPCB(refreshStatus))
  }
})

export default connect(mapState, mapDispatch)(LayoutExplorer);