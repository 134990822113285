import {
  Model
} from './IntegratedInterface';
import { ICTypes, MultiPinSPICE } from '../../pages/Sierra/constants';
import { getPins } from '@/services/Sierra/library/IbisModelHelper';
import { getIbisModelList } from '@/services/Sierra/library';
import { ONLY_DRIVER_MODEL_TYPES, INPUT } from '@/services/LibraryHelper'
import { CAP, COMP_REPEATER, CONNECTOR, IC, IND, JUMPER, RES, UNUSED, getComponentNeedInfoByName } from '../PCBHelper';
import { BGA, DIE, IGNORE } from '../../constants/componentType';
import { PRE_LAYOUT } from '../../constants/designVendor';
import designConstructor from '../helper/designConstructor';
import { getSpiceModelList } from './library';
import { OUTPUT } from '../LibraryHelper';
import sierraLibrary from './library/libraryStorage';
import { SPICE } from '../../constants/libraryConstants';
import { getCompType } from '../Designs/compTypeHelper';
import { SIERRA } from '../../constants/pageType';


const ND_IN = "nd_in", ND_EN = "nd_en";
function updateInterfaceContent({ Interfaces }) {
  let _Interfaces = Array.isArray(Interfaces) ? JSON.parse(JSON.stringify(Interfaces)) : [];
  if (Interfaces.length === 0) {
    return Interfaces;
  }

  _Interfaces.forEach(info => {
    let content = info.content;
    let components = content.components ? content.components : [];
    let models = content.models ? content.models : [];
    let stimuli = content.stimuli ? content.stimuli : [];
    components.forEach(comp => {
      if (ICTypes.includes(comp.type)) {
        let files = [];
        comp.pins.forEach(pin => {
          //update pin model
          if (pin.model && typeof (pin.model) === 'string') {
            const model = models.find(item => item.name === pin.model);
            if (files.length === 0) {
              files.push({
                type: model ? model.modelType : "IBIS",
                libraryId: model && model.libraryId ? model.libraryId : "",
                fileName: model && model.fileName ? model.fileName : "",
              })
            }
            if (model) {
              const newModel = new Model({
                libType: model.modelType,
                libraryId: model.libraryId,
                fileName: model.fileName,
                modelType: model.type,
                modelName: model.modelName,
                enableVoltage: model.enableVoltage
              });
              pin.model = newModel;
            } else {
              pin.model = {}
            }
          }

          //update stimulus
          if (pin.usage === 'Driver' && pin.pinModels) {
            pin.pinModels.forEach(pinModel => {
              if ((pinModel.pinName === ND_IN || pinModel.pinName === ND_EN) && pinModel.type === 'VEC' && pinModel.stimulus) {
                const _stimulus = stimuli.find(item => item.name === pinModel.stimulus);
                const newStimulus = _stimulus ? {
                  libraryId: _stimulus.libraryId,
                  fileName: _stimulus.fileName
                } : "";
                pinModel.stimulus = newStimulus;
              }
            })
          }
        });
        //update comp model
        comp.model = { files };
      }
    })
    info.content.components = components;
    delete info.content.models;
    delete info.content.stimuli;
  });
  return _Interfaces;
}


function updateComponentPinSignal({ comp, sameSignalNets, otherSignalNets, otherSignalAndNetList, signalName }) {
  //update pin signal (The net deleted by the current signal and the net that other signals exist)
  if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
    comp.pins.forEach(pin => {
      if (sameSignalNets.includes(pin.net) && otherSignalNets.includes(pin.net) && pin.signal === signalName) {
        const find = otherSignalAndNetList.find(item => item.net === pin.net);
        pin.signal = find && find.signal ? find.signal : "";
      }
    })
  }
  return comp.pins;
}


/* signal nets format */
function signalNetsFormat(signals, signalName) {
  const otherSignal = signals.filter(item => item.name !== signalName);
  //otherSignalNets [net1,net2,...]
  //otherSignalAndNetList [{ net:net1, signal:signal1 }, { net:net2, signal:signal2 }, ...]
  let otherSignalNets = [], otherSignalAndNetList = [];
  otherSignal.forEach(item => {
    if (item.nets) {
      otherSignalNets.push(...item.nets);
      const nets = item.nets.map(net => { return { net, signal: item.name } });
      otherSignalAndNetList.push(...nets);
    }
  });
  return { otherSignalNets, otherSignalAndNetList };
}

async function updateAllComponentPinsUsage({ pcbId, components, component, usage, signals, compList }) {
  if (!components || !components.length) {
    return components;
  }
  let _components = components ? JSON.parse(JSON.stringify(components)) : [];
  const compIndex = _components.findIndex(item => item.name === component);
  if (compIndex < 0 || !components[compIndex] || !Array.isArray(components[compIndex].pins)) {
    return components;
  }

  let model = _components[compIndex].model, libType = "", libraryId = "", fileName = "";
  if (model && model.files && model.files[0]) {
    libType = model.files[0].type;
    libraryId = model.files[0].libraryId;
    fileName = model.files[0].fileName;
  }

  let modelObj = {};

  for (let pinItem of _components[compIndex].pins) {

    const prevUsage = pinItem.usage;
    pinItem.usage = usage;
    if (!pinItem.model) {
      pinItem.model = {
        libType,
        libraryId,
        fileName,
        modelType: "",
        modelName: "",
        enableVoltage: ""

      }
    }
    // Delete model or not, stimulus
    const prevModelType = pinItem.model.modelType
    const newUsage = pinItem.usage
    const shouldClear = (prevModelType && ONLY_DRIVER_MODEL_TYPES.includes(prevModelType) && newUsage === 'Receiver')
      || (prevModelType && INPUT.includes(prevModelType) && newUsage === 'Driver')

    if (pinItem.model && pinItem.model.libraryId && shouldClear) {
      pinItem.model = {
        ...pinItem.model,
        modelName: "",
        modelType: "",
        enableVoltage: ""
      }
    }

    if (pinItem.usage === "Receiver") {
      pinItem.pinModels = []
    }

    if (pinItem.usage === "Driver" && (!pinItem.pinModels || !pinItem.pinModels.length)) {
      let deviceVcc = ""

      if (pinItem.model.libType === "IBIS" && pinItem.model.libraryId) {
        const id = pinItem.model.libraryId;
        modelObj[id] = modelObj[id] ? modelObj[id] : await getIbisModelList({ libraryId: id, usage: '' })
        const models = modelObj[id];
        const modelInfo = models ? (models.models || []).find(item => item.name === pinItem.model.modelName) : null;
        deviceVcc = modelInfo && modelInfo.deviceVcc && modelInfo.deviceVcc !== "NA" ? parseFloat(modelInfo.deviceVcc).toString() : "";
        _components[compIndex].deviceVcc = deviceVcc || "";
      }

      pinItem.pinModels = updateICPinModelsByBuffer({
        pinModels: pinItem.pinModels,
        usage: pinItem.usage,
        libType: pinItem.model.libType,
        modelType: pinItem.model.modelType || "I/O",
        pu: deviceVcc,
        pd: "0",
        isUsageUpdate: true
      })
    }

    pinItem.powerOff = "";

    // if libType === MultiPinSPICE, update model pairs pin port
    if (libType === MultiPinSPICE) {
      const pin = pinItem.pin;
      pinItem.model = { libType: MultiPinSPICE };
      if (model && model.pairs && model.pairs.length) {
        if (prevUsage !== usage) {
          const pinInIndex = model.pairs.findIndex(item => item.pin === `${pin}_in`);
          const pinDieIndex = model.pairs.findIndex(item => item.pin === `${pin}_u`);
          if (pinInIndex > -1 && usage === 'Driver') {
            model.pairs[pinInIndex].node = "";
            model.pairs[pinInIndex].libraryId = "";
            model.pairs[pinInIndex].fileName = "";
            model.pairs[pinInIndex].subckt = "";
          } else if (usage === 'Driver') {
            model.pairs.push({
              pin: `${pin}_in`,
              node: "",
              libraryId: "",
              fileName: "",
              subckt: ""
            })
          }

          if (pinDieIndex > -1 && (usage === 'Driver' || usage === 'Receiver')) {
            model.pairs[pinDieIndex].node = "";
            model.pairs[pinDieIndex].libraryId = "";
            model.pairs[pinDieIndex].fileName = "";
            model.pairs[pinDieIndex].subckt = "";
          } else if (usage === 'Driver' || usage === 'Receiver') {
            model.pairs.push({
              pin: `${pin}_u`,
              node: "",
              libraryId: "",
              fileName: "",
              subckt: ""
            })
          }

          if (usage === 'Receiver' && pinInIndex > -1) {
            model.pairs = model.pairs.filter(item => item.pin !== `${pin}_in`);
          }
        }
      }
      _components[compIndex].model = model;
      const index = pinItem.pinModels.findIndex(item => item.pinName === "nd_in");
      if (index > -1) {
        pinItem.pinModels[index].type = MultiPinSPICE;
      }
    }

    if (usage === "Driver") {
      signals.push(pinItem.signal);
      compList.push(`${pcbId}::${component}`)
    }
  };

  return _components
}

function getCompareSettingInfo(prevInfo, newInfo) {
  if (!prevInfo || !newInfo || !Object.keys(prevInfo).length) {
    return null;
  }
  //compPrefixLib, compPinMap, doNotStuff
  const prefixKeys = Object.keys(newInfo.compPrefixLib)
  let updateInfo = {
    doNotStuff: {
      addComps: newInfo.doNotStuff.filter(item => !prevInfo.doNotStuff.includes(item)),
      deleteComps: prevInfo.doNotStuff.filter(item => !newInfo.doNotStuff.includes(item))
    },
    compPrefixLib: {
    }
  }

  for (let key of prefixKeys) {
    if (!newInfo.compPrefixLib[key] || !prevInfo.compPrefixLib[key]) {
      continue;
    }
    updateInfo.compPrefixLib[key] = {
      addParts: newInfo.compPrefixLib[key].filter(item => !prevInfo.compPrefixLib[key].includes(item)),
      deleteParts: prevInfo.compPrefixLib[key].filter(item => !newInfo.compPrefixLib[key].includes(item))
    }
  }
  return updateInfo;
}

function getCompTypeChange({
  updateInfo,
  pcbId,
  type,
  component
}) {
  let update = false;
  if (
    updateInfo && updateInfo[pcbId] && updateInfo[pcbId].prevInfo &&
    ((type === COMP_REPEATER || component.type === COMP_REPEATER)
      || (type === CONNECTOR || component.type === CONNECTOR)
      || (type === JUMPER || component.type === JUMPER)
      || (type === RES || component.type === RES)
      || (type === IND || component.type === IND)
      || (type === CAP || component.type === CAP))) {

    let prevType = "";
    const findPassive = (updateInfo[pcbId].prevInfo.passiveTable || []).find(item => item.part === component.part);
    if ((updateInfo[pcbId].prevInfo.doNotStuff || []).includes(component.name)) {
      prevType = UNUSED;
    } else if (findPassive && findPassive.name) {
      prevType = findPassive.name.includes(component.name) ? findPassive.usage : UNUSED;
    } else {
      const { pinLength: allPinsLength } = getComponentNeedInfoByName({ designID: pcbId, compName: component.name })
      prevType = getCompType({
        compName: component.name,
        pinLength: allPinsLength,
        partName: component.part,
        COMP_PREFIX_LIB: updateInfo[pcbId].prevInfo.compPrefixLib,
        compPinMap: updateInfo[pcbId].prevInfo.compPinMap,
        product: SIERRA
      });
    }

    if (prevType && prevType !== type) {
      update = true;
    }
  } else {
    return true;
  }
  return update;
}

function getCompUpdateInfo({ components, compPrefixLib, doNotStuff, pcbId, compPinMap, isSinglePcb, passiveTable }) {

  let updateInfo = [];
  for (let comp of components || []) {
    const findPassive = passiveTable.find(item => item.part === comp.part);
    let newType = null;
    if (findPassive && findPassive.name) {
      newType = findPassive.name.includes(comp.name) ? findPassive.usage : UNUSED;
    } else {
      const { pinLength: allPinsLength } = getComponentNeedInfoByName({ designID: pcbId, compName: comp.name })
      newType = getCompType({
        compName: comp.name,
        pinLength: allPinsLength,
        partName: comp.part,
        COMP_PREFIX_LIB: compPrefixLib,
        compPinMap,
        product: SIERRA
      });
    }

    newType = newType === IGNORE ? UNUSED : newType;

    if (newType === COMP_REPEATER && compPinMap) {
      newType = getCompTypeByRepeaterPinMap({ type: newType, part: comp.part, pins: comp.pins, compPinMap, compName: comp.name })
    }
    //filter connector component
    const isConn = newType === IC && comp.type === CONNECTOR && comp.connect && comp.connect.pcbId;
    if (doNotStuff.includes(comp.name)) {
      comp.type !== UNUSED && updateInfo.push({
        title: `${comp.name} `,
        info: `has been set to "Do Not Stuff".`
      })
    } else if (newType !== comp.type && !(isSinglePcb && newType === CONNECTOR) && !isConn) {
      updateInfo.push({
        title: `${comp.name} `,
        info: `usage changed from "${comp.type}" to "${newType}".`
      })
    }
  }
  return updateInfo;
}

function updateConnCompToUnused(interfaces) {
  for (let info of interfaces) {
    if (!info.content || !info.content.components) {
      continue
    }

    let deletedConn = [];

    for (let conn of info.content.components) {
      if (conn.type !== CONNECTOR) {
        continue
      }
      if (!conn.connect || !conn.connect.componentName) {
        deletedConn.push(conn.name)
        conn.type = UNUSED;
        delete conn.connect;
        delete conn.models;
        delete conn.cableModels;
        continue;
      }

      const findConn = conn.connect && conn.connect.pcbId ? interfaces.find(item => item.pcbId === conn.connect.pcbId && item.pcbId !== info.pcbId) : null;
      const connComponent = findConn && findConn.content && findConn.content.components ? findConn.content.components.find(item => item.name === conn.connect.componentName && item.type === CONNECTOR) : null
      if (!findConn || !connComponent) {
        deletedConn.push(conn.name)
        conn.type = UNUSED;
        delete conn.connect;
        delete conn.models;
        delete conn.cableModels;
      }
    }

    const vendor = designConstructor.getDesignVendor(info.pcbId);
    if (vendor === PRE_LAYOUT) {
      continue;
    }

    if (deletedConn && deletedConn.length) {
      //remove unused comp ports
      info.content.port_setups = (info.content.port_setups || []).filter(item => {
        if (item.positive && item.positive.component && deletedConn.includes(item.positive.component)) {
          return false;
        }
        return true;
      });
      info.content.ports_generate_setup_list = (info.content.ports_generate_setup_list || []).filter(item => {
        if (item.component && deletedConn.includes(item.component)) {
          return false;
        }
        return true;
      })
    }
  }
  return interfaces
}

function removeUnusedCompsPorts(Interfaces) {
  let updateInfos = false;

  for (let info of Interfaces || []) {
    if (!info.content || !info.content.components) {
      continue
    }

    const vendor = designConstructor.getDesignVendor(info.pcbId);
    if (vendor === PRE_LAYOUT) {
      continue;
    }

    const icComps = info.content.components
      .filter(comp => [IC, CONNECTOR, BGA, DIE].includes(comp.type))
      .map(item => item.name);

    let updateInfo = false;
    if (icComps && icComps.length) {
      //remove unused comp ports
      info.content.port_setups = (info.content.port_setups || []).filter(item => {
        if (item.positive && item.positive.component && icComps.includes(item.positive.component)) {
          return true;
        }
        updateInfo = true;
        return false;
      });
      info.content.ports_generate_setup_list = (info.content.ports_generate_setup_list || []).filter(item => {
        if (item.component && icComps.includes(item.component)) {
          return true;
        }
        return false;
      })
    }
    updateInfos = updateInfo ? true : updateInfos;
  }
  return { Interfaces, updateInfo: updateInfos }
}

function applySignalModels(components, pinInfo, IBISComponent, deviceVcc, pinModelsInfo, isModelUpdate) {
  const _components = components;
  const { pu, pd } = pinModelsInfo || {};

  _components.forEach((component, index) => {
    const pins = component.pins
    if (pins && pins.length > 0) {
      pins.forEach((pin, p_index) => {
        if (Object.keys(pin).includes('usage') && pin.signal === pinInfo.signal && pin.usage === pinInfo.usage) {
          const prevFile = components[index].model.files && components[index].model.files[0]
          const { fileName, libraryId, libType } = pinInfo.model
          if (!prevFile || prevFile.libraryId !== libraryId || prevFile.fileName !== fileName) {
            components[index].model.files = [{
              component: IBISComponent,
              fileName: fileName,
              libraryId: libraryId,
              type: libType
            }]
            components[index].pkg = { type: 'None' }
          }

          components[index].pins[p_index].powerOff = pinInfo.powerOff;
          components[index].pins[p_index].model = { ...pinInfo.model }

          if (pin.usage === "Driver" && (!components[index].pins[p_index].pinModels || !components[index].pins[p_index].pinModels.length)) {
            components[index].pins[p_index].pinModels = getPins({ type: pinInfo.model.modelType || "I/O", usage: pin.usage });
          }

          const findNewPU = (pinInfo.pinModels || []).find(item => item.pinName === "nd_pu");
          components[index].deviceVcc = deviceVcc || (findNewPU && findNewPU.voltage ? findNewPU.voltage : pu);
          const findNewPD = (pinInfo.pinModels || []).find(item => item.pinName === "nd_pd");

          const findPU = components[index].pins[p_index].pinModels.findIndex(item => item.pinName === 'nd_pu');
          const findPD = components[index].pins[p_index].pinModels.findIndex(item => item.pinName === 'nd_pd');
          if (findPU > -1) {
            components[index].pins[p_index].pinModels[findPU].voltage = findNewPU && findNewPU.voltage ? findNewPU.voltage : components[index].pins[p_index].pinModels[findPU].voltage;
          }
          if (findPD > -1) {
            components[index].pins[p_index].pinModels[findPD].voltage = findNewPD && (findNewPD.voltage || findNewPD.voltage === 0) ? findNewPD.voltage : components[index].pins[p_index].pinModels[findPD].voltage;
          }

          if (pinInfo.powerOff === "1") {
            if (findPU > -1) {
              components[index].pins[p_index].pinModels[findPU].voltage = pu || components[index].pins[p_index].pinModels[findPU].voltage;
            }
            if (findPD > -1) {
              components[index].pins[p_index].pinModels[findPD].voltage = pd || components[index].pins[p_index].pinModels[findPD].voltage;
            }
          }
        }
      })
    }
  })
  return components
}

function getCompTypeByRepeaterPinMap({ type, part, pins, compPinMap, compName }) {
  if (!compPinMap || !compPinMap.repeater || !compPinMap.repeater.length) {
    return !pins ? IC : type;
  }
  const findPart = compPinMap.repeater.find(item => item.part === part);
  if (!findPart || !findPart.pinMap.length) {
    return IC
  }

  const findComp = compPinMap.repeater.find(item => item.part === part && (!item.components || item.components.includes(compName)));
  if (!findComp || !findComp.pinMap.length) {
    return IC;
  }

  const findPartList = compPinMap.repeater.filter(item => item.part === part && (!item.components || item.components.includes(compName)));

  if (!pins) {
    return findPartList.length ? COMP_REPEATER : IC;
  }
  const pinNumbers = pins ? pins.map(item => item.pin) : [];
  const findPinMap = findPartList.find(it => !!it.pinMap.find(item => item.input.find(it => pinNumbers.includes(it))
    && item.output.find(it => pinNumbers.includes(it))));

  if (!findPinMap) {
    return IC
  }
  return type
}

function setDefaultRepeaterModel({ part, pairs, files, pins, compName, compPinMap }) {
  if (!compPinMap || !compPinMap.repeater || !compPinMap.repeater.length) {
    return { pairs, files };
  }
  const findPartList = compPinMap.repeater.filter(item => item.part === part);
  if (!findPartList || !findPartList.length
    || findPartList.every(item => !item.pinMap || !item.model || !item.model.pairs || !item.model.pairs.length)) {
    return { pairs, files }
  }

  if (findPartList.every(item => item.components && item.components.length && !item.components.includes(compName))) {
    return { pairs, files }
  }

  let findModelList = findPartList;

  if (findPartList.length > 1) {
    findModelList = findPartList.filter(item => (!item.components || item.components.includes(compName))
      && item.pinMap && item.pinMap.find(_it => _it.input.find(it => pins.includes(it))
        && _it.output.find(it => pins.includes(it)))
      && item.model && item.model.pairs && item.model.pairs.length);
  } else {
    findModelList = findPartList.filter(item => !item.components || item.components.includes(compName))
  }

  let findModel = findModelList[0] || null;
  if (!findModel) {
    return { pairs, files }
  }

  if (findModelList.length > 1) {
    findModel = findPartList.find(item =>
      item.model.files
      && item.model.files.length
      && item.model.pairs.find(it => pins.includes(it.pin) && it.node)) || findModelList[0];

  }
  if (findModel.model && findModel.model.files && findModel.model.files.length) {
    files = JSON.parse(JSON.stringify(findModel.model.files.map(item => { item.type = "Repeater"; return item })))
  }

  for (let item of pairs) {
    const findPin = findModel.model.pairs.find(it => it.pin === item.pin);
    if (!findPin || !findPin.node) {
      continue;
    }
    item.node = findPin.node;
    item.libraryId = findPin.libraryId;
    item.fileName = findPin.fileName;
    item.subckt = findPin.subckt;
    item.modelKey = findPin.modelKey;
  }

  return { files, pairs }
}

async function setDefaultSpiceBufferModel({ defaultBufferSpice, usage }) {
  if (!defaultBufferSpice || !defaultBufferSpice.libraryId) {
    return null;
  }
  const model = await getSpiceModelList({ libraryId: defaultBufferSpice.libraryId, usage });

  if (!model || !model.models || !model.models.length) {
    return null;
  }
  const spiceFileList = sierraLibrary.getTree(SPICE) || [];
  const spiceFile = spiceFileList.find(item => item.id === defaultBufferSpice.libraryId) || {};
  const version = spiceFile.version || "1"
  const modelName = model.models.length === 1 ? model.models[0].name : model.models.map(item => item.name).find(item => item.match(/input/ig));
  return {
    libType: "SPICE",
    libraryId: defaultBufferSpice.libraryId,
    fileName: defaultBufferSpice.name,
    modelType: "Input",
    modelName: modelName || model.models[0].name,
    enableVoltage: "",
    version
  }
}

/**
 * 
 * @param {array} compList [pcbId::component::pin]
 * @param {array} signals :[signalName]
 * @param {object} model receiver pin model
 *  */
async function setDefaultUsageByDriver({ _Interfaces, compList, signals, model }) {
  if (!_Interfaces || !_Interfaces.length) {
    return null;
  }

  for (let info of _Interfaces) {
    if (!info.content || !info.content.components) {
      continue;
    }
    for (let comp of info.content.components) {
      if (comp.type !== IC) {
        continue;
      }
      const isSetDefault = comp.model && comp.model.files && Array.isArray(comp.model.files)
        && comp.model.files[0] &&
        comp.model.files[0].libraryId && comp.model.files[0].fileName && model && comp.model.files[0].libraryId !== model.libraryId ? false : true;
      //apply usage to receiver of same signal 
      comp.pins.forEach(pinItem => {
        if (signals.includes(pinItem.signal)
          && !compList.includes(`${info.pcbId}::${comp.name}`)) {
          pinItem.usage = "Receiver";
          pinItem.pinModels = [];
          //remove output model
          if (pinItem.model && ONLY_DRIVER_MODEL_TYPES.includes(pinItem.model.modelType)) {
            pinItem.model = {
              ...(pinItem.model || {}),
              modelName: "",
              modelType: "",
              enableVoltage: ""
            }
          }
          //apply default receiver spice model
          if (isSetDefault &&
            (!pinItem.model || !Object.keys(pinItem.model).length || !pinItem.model.modelName
              || (pinItem.model && pinItem.model.modelType && ONLY_DRIVER_MODEL_TYPES.includes(pinItem.model.modelType)))) {
            pinItem.model = JSON.parse(JSON.stringify(model || {}))
            if (model && model.libraryId && (!comp.model || !comp.model.files || !comp.model.files.length || !comp.model.files.find(it => !!it.libraryId))) {
              comp.model = {
                files: [{
                  type: "SPICE",
                  libraryId: pinItem.model.libraryId,
                  component: "",
                  fileName: pinItem.model.fileName,
                  version: pinItem.model.version
                }]
              }
            }
          }
        }
      })
    }
  }
  return _Interfaces;
}

function judgeModelUpdate(prevModel, model = {}) {
  if ((!prevModel || !Object.keys(prevModel).length)
    && model && model.libType) {
    return true;
  }

  if (prevModel.libType !== model.libType
    || prevModel.libraryId !== model.libraryId
    || prevModel.fileName !== model.fileName
    || prevModel.modelType !== model.modelType
    || prevModel.modelName !== model.modelName
    || prevModel.enableVoltage !== model.enableVoltage
  ) {
    return true;
  }

  return false;
}

function updateICPinModels({ pinModels, libType, usage, modelType, newPinModels }) {
  let nd_in = newPinModels ? newPinModels.find(item => item.pinName === "nd_in") : null;
  nd_in = nd_in ? JSON.parse(JSON.stringify(nd_in)) : {};
  let nd_en = newPinModels ? newPinModels.find(item => item.pinName === "nd_en") : null;
  nd_en = nd_en ? JSON.parse(JSON.stringify(nd_en)) : null;

  if (!pinModels || !pinModels.length) {
    const type = usage === "Driver" ? modelType || "I/O" : modelType;
    pinModels = getPins({ type, usage });
  }

  const pinIndex = pinModels.findIndex(item => item.pinName === "nd_in");
  pinModels[pinIndex].type = nd_in.type || "";
  pinModels[pinIndex].stimulus = nd_in.stimulus ? { ...nd_in.stimulus } : "";

  if (nd_in.type.includes('PRBS')) {
    pinModels[pinIndex].tabs = nd_in.tabs && nd_in.tabs.length ? [...nd_in.tabs] : ['7', '6'];
  } else {
    delete pinModels[pinIndex].tabs;
  }

  if (nd_in.type.includes('CLK')) {
    pinModels[pinIndex].delay = nd_in.delay ? { ...nd_in.delay } : { type: 'percent', value: '10' };
    pinModels[pinIndex].slew = nd_in.slew ? { ...nd_in.slew } : { type: 'percent', value: '5' };
  } else {
    delete pinModels[pinIndex].delay;
    delete pinModels[pinIndex].slew;
  }

  const pinEnIndex = pinModels.findIndex(item => item.pinName === "nd_en");

  //output not support nd_en
  if (OUTPUT.includes(modelType) || libType === "SPICE") {
    pinEnIndex > -1 && pinModels.splice(pinEnIndex, 1);
    return pinModels;
  }

  if (nd_en) {
    if (pinEnIndex > -1) {
      pinModels[pinEnIndex].type = nd_en.type || "";
      pinModels[pinEnIndex].stimulus = nd_en.stimulus ? { ...nd_en.stimulus } : "";
    } else {
      pinModels.push(JSON.parse(JSON.stringify(nd_en)));
    }
  } else if (pinEnIndex > -1) {
    pinModels.splice(pinEnIndex, 1)
  }
  return pinModels;
}

function updateICPinModelsByBuffer({ pinModels, usage, libType, modelType, pu, pd, newPinModels, isUsageUpdate = false }) {
  if (usage === "Receiver") {
    return []
  }
  if (!pinModels || !pinModels.length) {
    pinModels = getPins({ type: modelType || "I/O", usage: usage });
  }

  if (OUTPUT.includes(modelType) || libType === "SPICE") {
    const findEnableIndex = pinModels ? pinModels.findIndex(item => item.pinName === 'nd_en') : -1;
    if (findEnableIndex > -1) {
      pinModels.splice(findEnableIndex, 1)
    }
  }

  const findPU = pinModels.findIndex(item => item.pinName === 'nd_pu');
  const findNewPU = (newPinModels || []).find(item => item.pinName === 'nd_pu')
  if (findPU > -1) {
    pinModels[findPU].voltage = isUsageUpdate ? pu : findNewPU && findNewPU.voltage ? findNewPU.voltage : pinModels[findPU].voltage;
  };
  const findPD = pinModels.findIndex(item => item.pinName === 'nd_pd');
  const findNewPD = (newPinModels || []).find(item => item.pinName === 'nd_pd')

  if (findPU > -1) {
    pinModels[findPD].voltage = isUsageUpdate ? pd : findNewPD && findNewPD.voltage ? findNewPD.voltage : pinModels[findPD].voltage;
  }

  return pinModels;
}

export {
  updateInterfaceContent,
  updateComponentPinSignal,
  signalNetsFormat,
  updateAllComponentPinsUsage,
  getCompareSettingInfo,
  getCompTypeChange,
  getCompUpdateInfo,
  updateConnCompToUnused,
  removeUnusedCompsPorts,
  applySignalModels,
  getCompTypeByRepeaterPinMap,
  setDefaultRepeaterModel,
  setDefaultSpiceBufferModel,
  setDefaultUsageByDriver,
  judgeModelUpdate,
  updateICPinModels,
  updateICPinModelsByBuffer
};