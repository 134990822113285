const TYPE = 'Cascade';
export const CHANGE_RESULT_TYPE = `${TYPE}/change_result_type`;
export const GET_IR_LIST_VALUE = `${TYPE}/get_ir_list_value`;
export const GET_IR_RESULT_VALUE = `${TYPE}/get_IR_Result_Value`;
export const SAVE_IR_VALUE = `${TYPE}/save_ir_value`;
export const GET_IR_VALUE_FILE = `${TYPE}/get_ir_value_file`;
export const GET_IR_INFO_SUCCESS = `${TYPE}/get_ir_info_success`;
export const UPDATE_IR_SELECTED_KEY = `${TYPE}/update_ir_selected_key`;
export const UPDATE_IR_RESULT_TABLE_LOADING = `${TYPE}/update_result_table_loading`;
export const CLEAR_IR_RESULT_DATA = `${TYPE}/clear_ir_result_data`
