import { PASSIVE_SPICE, PASSIVE_TOUCHSTONE } from "../../../constants/libraryConstants";
import libraryConstructor from "../../../services/Andes_v2/library/libraryConstructor";
import {
  PORT_REFERENCE_COMPONENT,
  PORT_REFERENCE_PIN,
  PORT_REFERENCE_PIN_GROUP
} from "../../../services/ExtractionPortsHelper/portsSetup";
import { checkRLCValueFormat } from "../../../services/helper/dataProcess";
import { CAP, CMC, DIODE, IND, RES, RLC_TYPES, SERDES_TYPES } from "../../../services/PCBHelper";
import { isPreLayout } from '../../../services/Andes_v2';
import { connectorPkgErrorCheck } from '../../../services/helper/connectorPkgModelCheck';
import { BALL_TYPE_NONE, USE_BALL_LIST } from "../../../services/ExtractionPortsHelper/portTableHelper";
import { CPHY } from "../../../services/PCBHelper/constants";

function channelErrorCheck(channelInfo, checkedSignals, hybrid_regions, materialList) {
  const channelContent = channelInfo.content || {};
  const isPreLayoutChannel = isPreLayout(channelInfo.designId);
  const { signals, components, powerNets, port_setups = [], ports_generate_setup_list = [], extraction } = channelContent;
  const selectedSignals = checkedSignals ? checkedSignals : channelContent.selectedSignals;

  let error = [];

  if (signals.length === 0) {
    error.push({
      title: "[Signals]",
      errorMsg: "Signals is not set."
    })
  }

  if (selectedSignals.length === 0) {
    error.push({
      title: "[Signals]",
      errorMsg: "Choose at least one signal for simulation."
    })
  }

  //signal error check
  const noGroupNameSignals = signals.filter(item => selectedSignals.includes(item.name) && !item.group);
  if (noGroupNameSignals.length && channelInfo.type !== CPHY) {
    noGroupNameSignals.forEach(item => {
      error.push({
        title: "[Signals]",
        boldKey: item.name,
        errorMsg: "Group name is not set."
      })
    })
  }


  if (signals && signals.length > 0 && signals[0].nets_A) {
    const noNetsSignals = signals.filter(item => selectedSignals.includes(item.name) && (!item.nets_A.length || !item.nets_B.length || !item.nets_C.length));

    if (noNetsSignals.length) {
      noNetsSignals.forEach(item => {
        const msg = getSignalCheckMsg({ type: CPHY, nets_A: item.nets_A, nets_B: item.nets_B, nets_C: item.nets_C });
        error.push({
          title: "[Signals]",
          boldKey: item.name,
          errorMsg: msg
        })
      })
    }
  } else {
    const noNetsSignals = signals.filter(item => selectedSignals.includes(item.name) && (!item.nets_P.length || !item.nets_N.length));

    if (noNetsSignals.length) {
      noNetsSignals.forEach(item => {
        const msg = getSignalCheckMsg({ nets_P: item.nets_P, nets_N: item.nets_N });
        error.push({
          title: "[Signals]",
          boldKey: item.name,
          errorMsg: msg
        })
      })
    }
  }

  //empty power /gnd nets find
  const findEmpty = powerNets.find(item => !item.name);
  if (findEmpty && !isPreLayoutChannel) {
    error.push({
      title: "[Power / Ground Nets]",
      errorMsg: "Power / Ground net is not set."
    })
  }

  //gnd nets find
  const findGND = powerNets.filter(item => parseFloat(item.value) === 0);
  if (!findGND.length && !isPreLayoutChannel) {
    error.push({
      title: "[Power / Ground Nets]",
      errorMsg: "Ground net is not set."
    })
  }

  /*  if (findGND.length > 1) {
     error.push({
       title: "[Power / Ground Nets]",
       errorMsg: "Ground net (voltage is 0) can only have one."
     })
   } */

  const RLCComps = components.filter(item => RLC_TYPES.includes(item.type));
  for (let comp of RLCComps) {
    const model = comp.model;
    if (model.type === "value") {
      const valueList = getRLCValueList(comp.type, model.value);
      for (let item of valueList) {
        //Check the value format
        const type = item.type === "Capacitance" ? CAP : null;
        const check = checkRLCValueFormat(item.value, type);
        if (!check) {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: `${item.type} value format error.`
          })
        } else if (check && check === "0") {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: `${item.type} value cannot be 0.`
          })
        }
      }
    } else if (model && (model.type === 'spice' || model.type === 'touchstone')) {
      //passive model error check
      const passiveModel = model.passiveModel || {};
      if (!passiveModel.libraryId || !passiveModel.fileName) {
        error.push({
          title: "[Components]",
          boldKey: comp.name,
          errorMsg: `model is not set.`
        })
      } else {
        let fileExist = model.type === 'spice' ? libraryConstructor.checkFile(PASSIVE_SPICE, passiveModel.libraryId) : (model.type === 'touchstone' ? libraryConstructor.checkFile(PASSIVE_TOUCHSTONE, passiveModel.libraryId) : true);
        if (!fileExist) {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: `model file ${passiveModel.fileName} is not exist.`
          })
        }
      }

      if (model.type === 'spice' && !passiveModel.subckt) {
        error.push({
          title: "[Components]",
          boldKey: comp.name,
          errorMsg: `spice subckt is not set.`
        })
      }

      let errorTypeNP = model.type === 'spice' ? 'node' : 'port';
      for (let pair of passiveModel.pairs) {
        if (pair.pin && !pair.node) {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: `passive model pin(${pair.pin}) is not connected to ${errorTypeNP}.`
          })
        } else if (!pair.pin && pair.node) {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: `passive model ${errorTypeNP}(${pair.node}) is not connected to pin.`
          })
        }
      }
    }
  }

  if (extraction && extraction.hybrid && hybrid_regions && extraction.siwave && !extraction.siwave.suggestHFSSRegions) {
    if (!hybrid_regions.length) {
      error.push({
        title: "[Extraction]",
        errorMsg: "No zone definitions, please set zone."
      })
    } else if (!hybrid_regions.find(item => item.extraction === "HFSS")) {
      error.push({
        title: "[Extraction]",
        errorMsg: 'Zone definitions must have at least one zone set to "HFSS"'
      })
    }
  }

  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
  for (let comp of ICComps) {
    const selectedPinList = comp.pins.filter(item => selectedSignals.includes(item.signal));
    let selectedPins = selectedPinList.map(item => item.pin);
    selectedPins = [...selectedPins, ...selectedPins.map(item => `${item}_u`)];
    if (comp.pkg && comp.pkg.type === 'Touchstone / SPICE') {
      error = connectorPkgErrorCheck({
        model: comp.pkg,
        comp,
        type: "pkg",
        error,
        libraryConstructor
      });
    }

    if (comp.connectorModel && comp.connectorModel.files && comp.connectorModel.files.length) {
      error = connectorPkgErrorCheck({
        model: comp.connectorModel,
        comp,
        type: "connector",
        error,
        libraryConstructor
      });
    }

    if (comp.pcbModel && comp.pcbModel.files && comp.pcbModel.files.length) {
      error = connectorPkgErrorCheck({
        model: comp.pcbModel,
        comp,
        type: "pcb",
        error,
        libraryConstructor
      });
    }


    if (comp.cableModel && comp.cableModel.files && comp.cableModel.files.length) {
      error = connectorPkgErrorCheck({
        model: comp.cableModel,
        comp,
        type: "cable",
        error,
        libraryConstructor
      });
    }

    if (comp.modelList && comp.modelList.length) {
      // Verification of adding file reuse
      for (let modelInfo of comp.modelList) {
        const { files, modelType } = modelInfo;
        if (!files || !files.length) { continue }
        error = connectorPkgErrorCheck({
          model: modelInfo,
          comp,
          type: modelType === 'Package' ? 'pkg' : modelType.toLowerCase(),
          error,
          libraryConstructor,
          isMultiModel: true
        })
      }
    }

    if (!port_setups.length) {
      continue;
    }

    const findCompSetup = ports_generate_setup_list.find(it => it.component === comp.name) || {};
    const findPortSetup = findCompSetup.setup || {};
    const isHfss = extraction.type === "HFSS" || extraction.hybrid;
    if (findPortSetup && findPortSetup.portType === "gap" && !comp.gap_size) {
      error.push({
        title: "[Components]",
        boldKey: `${comp.name}`,
        errorMsg: `extraction port reference plane distance is not set.`
      });
    }

    if (isHfss && findPortSetup && findPortSetup.portType === "gap" && comp.gap_size && parseFloat(comp.gap_size) <= 0) {
      error.push({
        title: "[Components]",
        boldKey: `${comp.name}`,
        errorMsg: `when the extraction type is "HFSS", the extraction port reference plane distance should be greater than 0.`
      });
    }

    const ball_height = comp.ball_height && comp.ball_height.replace(/um|mm|mil/g, ''), ball_size = comp.ball_size && comp.ball_size.replace(/um|mm|mil/g, '')
    if (findPortSetup && [...USE_BALL_LIST].includes(findPortSetup.portType) && (comp.ball_type && comp.ball_type !== BALL_TYPE_NONE && (!ball_size || !ball_height))) {
      error.push({
        title: "[Components]",
        boldKey: `${comp.name}`,
        errorMsg: `extraction port (ball size / ball height) is not set.`
      });
    }

    if (findPortSetup && [...USE_BALL_LIST].includes(findPortSetup.portType) && (comp.ball_material && materialList && materialList.findIndex(item => item.name === comp.ball_material) < 0)) {
      error.push({
        title: "[Components]",
        boldKey: `${comp.name}`,
        errorMsg: `extraction port ball material "${comp.ball_material}" is not found in the stackup material list.`
      });
    }

    for (let pin of comp.pins) {
      const find = port_setups.find(item => item.positive && item.positive.component === comp.name && item.positive.pin === pin.pin);
      if (!find || !find.negative || !find.negative.type) {
        error.push({
          title: "[Components]",
          boldKey: `${comp.name}::${pin.pin}`,
          errorMsg: `port is not set.`
        });
        continue;
      }
      if ([PORT_REFERENCE_PIN_GROUP, PORT_REFERENCE_COMPONENT].includes(find.negative.type) && !find.negative.pins && !find.negative.nets) {
        error.push({
          title: "[Components]",
          boldKey: `${comp.name}::${pin.pin}`,
          errorMsg: `port is not set.`
        });
        continue;
      }

      if (find.negative.type === PORT_REFERENCE_PIN && (!find.negative.pin || !find.negative.component)) {
        error.push({
          title: "[Components]",
          boldKey: `${comp.name}::${pin.pin}`,
          errorMsg: `port is not set.`
        });
        continue;
      }
      //TODO
      /* if (findPortSetup.portType === "gap" && isHfss
        && (find.negative.type === PORT_REFERENCE_PIN
          || (find.negative.type === PORT_REFERENCE_COMPONENT && find.negative.pins && find.negative.pins.length <= 1))
      ) {
        error.push({
          title: "[Components]",
          boldKey: `${comp.name}::${pin.pin}`,
          errorMsg: `Since extraction is HFSS, it is better not to use a single pin reference.`
        })
      } */
    }
  }

  const CMCComps = components.filter(item => item.type === CMC);
  for (let comp of CMCComps) {
    const selectedPinList = comp.pins.filter(item => selectedSignals.includes(item.signal));
    const filterPairs = comp.model && comp.model.pairs ? comp.model.pairs.filter(item => !!item.node) : [];

    if (!selectedPinList.length || !filterPairs.length) {
      continue;
    }

    if (comp.model && comp.model.files && comp.model.files.length) {
      const existFiles = comp.model.files.filter(item => item.fileName && item.type);
      for (let _file of existFiles) {
        if (!_file.libraryId) {
          continue;
        }
        const fileExist = libraryConstructor.checkFile(PASSIVE_TOUCHSTONE, _file.libraryId);
        if (!fileExist) {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: `Touchstone file ${_file.fileName} not exist.`
          })
        }
      }

      for (let pin of selectedPinList) {
        const findPin = comp.model.pairs.find(item => item.pin === pin.pin);
        if (findPin && !findPin.node) {
          error.push({
            title: "[Components]",
            boldKey: comp.name,
            errorMsg: ` pin ${pin.pin} port is not set.`
          })
        }
      }
    }
  }

  const diodeComps = components.filter(item => item.type === DIODE);
  for (let comp of diodeComps) {

    if (!comp.model || !comp.model.id) {
      continue;
    }

    const fileExist = libraryConstructor.checkFile(PASSIVE_TOUCHSTONE, comp.model.id);
    if (!fileExist) {
      error.push({
        title: "[Components]",
        boldKey: comp.name,
        errorMsg: `Touchstone file ${comp.model.name} not exist.`
      })
    }
  }


  for (let signal of selectedSignals) {
    const findComps = ICComps.filter(item => item.pins.find(item => item.signal === signal));
    if (findComps.length < 2) {
      error.push({
        title: "[Signals]",
        boldKey: signal,
        errorMsg: `the signal is connected to the pins of at least two devices.`
      })
    }
  }

  return error.length ? [...error] : null;
}

function getRLCValueList(type, valueObj) {
  let list = [];
  switch (type) {
    case RES:
      list.push({
        type: "Resistance",
        value: valueObj.r
      });
      return list;
    case IND:
      list.push({
        type: "Inductance",
        value: valueObj.l
      });
      return list;
    case CAP:
      list = [{
        type: "Resistance",
        value: valueObj.r
      },
      {
        type: "Inductance",
        value: valueObj.l
      },
      {
        type: "Capacitance",
        value: valueObj.c
      }]
      return list;
    default: return [];
  }
}

function getSignalCheckMsg({ type, nets_P, nets_N, nets_A, nets_B, nets_C }) {
  if (type === CPHY) {
    const lineMsg = [];

    if (!nets_A.length) {
      lineMsg.push('A');
      return "A Line nets is not set.";
    }

    if (!nets_B.length) {
      lineMsg.push('B');
      return "B Line nets is not set.";
    }

    if (!nets_C.length) {
      lineMsg.push('C');
      return "C Line nets is not set.";
    }

    if (lineMsg.length > 0) {
      return `${lineMsg.join(', ')} Line nets is not set.`
    }
  } else {
    if (!nets_P.length && !nets_N.length) {
      return "Positive and negative nets are not set.";
    }

    if (!nets_P.length) {
      return "Positive nets is not set.";
    }

    if (!nets_N.length) {
      return "Negative nets is not set.";
    }
  }
}

export {
  channelErrorCheck
}