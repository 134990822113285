import { filterPorts } from "../../LibraryHelper/SpiceModelHelper";
import { getDDRSignalType, getNum } from "../../helper/getSignalType";
import { getPortInfoSplitList } from "../../helper/touchstoneHelper";
import { getCSMCPMSpiceModelList } from ".";
import { PRE_LAYOUT } from "../../../constants/designVendor";
import { CASCADE } from "../../../constants/pageType";

function CPM({ net = "", pad = "", type = "", node = "", location = "", portName = "" }) {
  this.net = net;
  this.pad = pad;
  this.type = type;
  this.node = node;
  this.location = location;
  this.portName = portName;
}

function CSM(name, ports = []) {
  this.name = name; // String, model name
  this.ports = ports;
}

function newPorts(name, { IN, PAD, type }, { net, location, pinType = "Signal" }) {
  this.net = net;
  this.pad = name;
  this.type = pinType;
  this.node = name;
  this.location = location;

  this.name = name;
  this.port = { IN, PAD, type };
}

function Port(name, { IN, PAD, type }) {
  this.name = name; // String, model name
  this.IN = IN;
  this.PAD = PAD;
  this.type = type;
}
class CPMCSMSpice {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  parseSpice = (id, fileContent, fileType, product) => {
    if (!this[id]) {
      this[id] = {}
    }
    this[id].CSM = this.parseCSMModelSelector(fileContent);
    this[id].CPM = product === CASCADE ? (fileType === 'ploc' ? this.parsePlocModel(fileContent) : this.parseCascadeCPMModel(fileContent)) : this.parseCPMModel(fileContent)
  }

  parsePlocModel = (fileContent) => {
    if (!fileContent) {
      return [];
    }
    const lines = fileContent.match(/[^\r\n]+/g) || [];
    const models = [];
    let inBlock = false, inNodeBlock = false, unitBlock = false;
    let unit = "mil";
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      //VSSvsrc37 943.072000 604.500000 AP GROUND VSSvsrc37
      if (line.match(/Begin Chip Package Protocol/ig)) {
        inBlock = true;
        continue;
      }
      //unit
      if (line.match(/Start Units/ig)) {
        unitBlock = true;
        continue;
      }
      if (line.match(/End Units/ig)) {
        unitBlock = false;
        inNodeBlock = true;
        continue;
      }
      if (unitBlock && line.match(/Length/ig)) {
        let words = line.match(/[^\s\t]+/g);
        unit = words[2] ? words[2] : null;
        continue;
      }
      if (line.match(/End Chip Package Protocol/ig)) {
        inBlock = false;
        inNodeBlock = false;
        break;
      }

      if (inNodeBlock) {
        let newLine = line.trim().split(/\s+/);
        const pad = newLine[0] ? newLine[0] : null;
        if (!pad) {
          continue;
        }

        const type = newLine[4] ? newLine[4].match(/Power/ig) ? "Power" : "Ground" : null;

        const node = pad;
        if (!node) {
          continue;
        }

        const net = newLine[5];
        if (!net) {
          continue;
        }

        const pointX = newLine[1];
        const pointY = newLine[2];
        const point = [Number(pointX), Number(pointY)];
        const data = new CPM({
          net,
          pad,
          type,
          node,
          location: point
        });
        models.push(data)
      };
    }

    if (unit === "mils") {
      unit = "mil"
    }
    return { models, unit };
  }

  parseCPMModel = (fileContent) => {
    if (!fileContent) {
      return [];
    }
    // convert the input into an Array of Strings
    const lines = fileContent.match(/[^\r\n]+/g) || [];
    const models = [];
    let inBlock = false, inNodeBlock = false, unitBlock = false;
    let unit = "mil";
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      //* VDDQ_DDR1 : ( 112.406894 , -52.781559 ) : Power : VDDQ_DDR1 : VDDQ_DDR 23 
      //* VSS22 : ( -10.935272 , -40.94691300000001 ) : Ground : VSS22 : VSS 85
      if (line.match(/Begin Chip Package Protocol/ig)) {
        inBlock = true;
        continue;
      }
      //unit
      if (line.match(/Start Units/ig)) {
        unitBlock = true;
        continue;
      }
      if (line.match(/End Units/ig)) {
        unitBlock = false;
        continue;
      }
      if (unitBlock && line.match(/Length/ig)) {
        let words = line.match(/[^\s\t]+/g);
        unit = words[2] ? words[2] : null;
        continue;
      }
      //power pad
      if (line.match(/Begin Power Ground/ig)) {
        inNodeBlock = true;
        continue;
      }
      if (line.match(/End Power Ground/ig)) {
        inBlock = false;
        inNodeBlock = false;
        break;
      }

      if (inNodeBlock) {
        let newLine = line.split(":");
        newLine = newLine.map((item, index) => index > 0 ? item.replace(/[\s\t]+/g, "") : item)
        if (!newLine[2] || !newLine[2].match(/Power|Ground/ig)) {
          continue;
        }

        const pads = newLine[0] ? newLine[0].match(/[^\s\t]+/g) : null;
        const pad = pads && pads[1] ? pads[1] : null;
        if (!pad) {
          continue;
        }

        const type = newLine[2].match(/Power/ig) ? "Power" : "Ground";

        const node = newLine[3] || "";
        if (!node) {
          continue;
        }

        const net = newLine[4];
        if (!net) {
          continue;
        }

        const points = newLine[1] ? newLine[1].split(",").map(item => item.replace(/\(|\)/g, "")) : null;

        const pointX = points && points[0] ? points[0] : null;
        const pointY = points && points[1] ? points[1] : null;
        const point = [Number(pointX), Number(pointY)];
        const data = new CPM({
          net,
          pad,
          type,
          node,
          location: point
        });
        models.push(data)
      };
    }

    if (unit === "mils") {
      unit = "mil"
    }
    return { models, unit };
  }

  parseCascadeCPMModel = (fileContent) => {
    if (!fileContent) {
      return [];
    }
    // convert the input into an Array of Strings
    const lines = fileContent.match(/[^\r\n]+/g) || [];
    const models = [];
    let inBlock = false, inNodeBlock = false, unitBlock = false;
    let unit = "mil";
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line.match(/Begin Chip Package Protocol/ig)) {
        inBlock = true;
        continue;
      }
      //unit
      if (line.match(/Start Units/ig)) {
        unitBlock = true;
        continue;
      }
      if (line.match(/End Units/ig)) {
        unitBlock = false;
        inNodeBlock = true;
        continue;
      }
      if (unitBlock && line.match(/Length/ig)) {
        let words = line.match(/[^\s\t]+/g);
        unit = words[2] ? words[2] : null;
        continue;
      }
      if (line.match(/End Chip Package Protocol/ig)) {
        inBlock = false;
        inNodeBlock = false
        break;
      }

      if (inNodeBlock) {
        // * FCHIP-A10	 :   (2612.000000 4412.000000)	 :   p1	  = PAR_0_0_VDD_1
        let newLine = line.split(":");
        newLine = newLine.map((item, index) => {
          if (index === 1) {
            return item.replace(/^\s*\(\s*(.*?)\s*\)\s*$/, '($1)');
          } else if (index > 0) {
            return item.replace(/[\s\t]+/g, "")
          } else {
            return item
          }
        })
        const pads = newLine[0] ? newLine[0].match(/[^\s\t]+/g) : null;
        const pad = pads && pads[1] ? pads[1] : null;
        if (!pad) {
          continue;
        }

        const node = pad;
        if (!node) {
          continue;
        }

        const nets = newLine[2] ? newLine[2].split('=') : null
        const portName = nets && nets[0] ? nets[0] : null
        if (!portName) {
          continue
        }
        const net = nets && nets[1] ? nets[1] : null;
        if (!net) {
          continue;
        }
        const netName = net ? net.replace(/^PAR_\d+_\d+_/, '') : null
        const type = netName ? (netName.match(/^VDD/) ? "Power" : "Ground") : null

        const points = newLine[1] ? newLine[1].split(" ").map(item => item.replace(/\(|\)/g, "")) : null;
        const pointX = points && points[0] ? points[0] : null;
        const pointY = points && points[1] ? points[1] : null;
        const point = [Number(pointX), Number(pointY)];
        const data = new CPM({
          net,
          pad,
          type,
          node,
          portName,
          location: point
        });
        models.push(data)
      }
    }

    if (unit === "mils") {
      unit = "mil"
    }
    return { models, unit };
  }

  getFiles() {
    return Object.keys(this);
  }

  deleteModel(id) {
    delete this[id];
  }

  parseCSMModelSelector(fileContent) {
    if (!fileContent) {
      return [];
    }
    // convert the input into an Array of Strings
    const lines = fileContent.match(/[^\r\n]+/g) || [];
    const models = [], signalList = [];
    let modelNames = [];
    let inBLock = false, isSignal = false;
    let name = null, subcktIndex = null;

    const rule = /[^\s\t]+/g, replaceRule = /[(|)|{|}|,|*]+/g;
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line.match(/Begin Signal/ig)) {
        isSignal = true;
        continue;
      }
      if (line.match(/End Signal/ig)) {
        isSignal = false;
        break;
      }

      if (isSignal) {
        let words = line.match(/[^:|*]+/g);
        if (words && words.length > 5) {
          const nets = words[0].replace(replaceRule, "").match(rule)
          const location = words[1].replace(replaceRule, "").match(rule)
          const ports = words[4].replace(replaceRule, "").match(rule);
          let _location = null;
          if (location && location.length) {
            _location = location.map(item => parseFloat(item))
          }
          signalList.push({
            net: nets && nets.length ? nets[0] : "",
            location: _location,
            ports: ports || []
          })
        }
      }
    }

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line.match(/^\.subckt/ig)) {

        let words = line.match(/[^\s\t]+/g);
        name = words[1] ? words[1] : null;
        if (!name) {
          subcktIndex = i + 1;
          continue;
        }
        subcktIndex = null;
        inBLock = true;
        // .subckt subcktName  port1 port2 ...
        let _ports = words.slice(2, words.length);
        _ports = filterPorts(_ports);
        if (modelNames.indexOf(name) < 0) {
          const model = new CSM(name, _ports);
          models.push(model);
        }
        modelNames.push(name);
      } else if (subcktIndex === i && line.startsWith('+')) {
        // .subckt
        // + subcktName port1 port2 ...
        subcktIndex = null;
        let words = line.match(/[^\s\t]+/g);
        name = words[1] ? words[1] : null;
        if (!name) {
          continue;
        }
        inBLock = true;
        let _ports = words.slice(2, words.length);
        _ports = filterPorts(_ports);
        const ports = this.getPort(_ports, signalList);
        if (modelNames.indexOf(name) < 0) {
          const model = new CSM(name, [...(ports || [])]);
          models.push(model);
        }
        modelNames.push(name);
      } else if (line.match(/^\.end/ig)) {
        name = null;
        inBLock = false;
        continue;
      } else if (inBLock && line.startsWith('+')) {
        let words = line.match(/[^\s\t]+/g);
        const index = models.findIndex(item => item.name === name);
        let _words = words;
        if (words[0] === '+') {
          _words = words.slice(1, words.length);
        } else {
          _words[0] = _words[0].substr(1);
        }
        _words = filterPorts(_words);
        const ports = this.getPort(_words, signalList);
        models[index].ports.push(...(ports || []));
      } else {
        name = null;
        inBLock = false;
        continue;
      }
    };
    return models
  }

  getSpiceModelList(id) {
    const model = this[id];
    return model
  }

  getPort = (words, signalList) => {
    if (words.length === 2) {
      //PAD SIGNAL
      const signal = words[1];
      if (signal.match(/VDDQ|VSS/ig)) {
        return null;
      }
      const findInfo = signalList.find(item => item.ports.includes(words[0]))
      return [new newPorts(signal, { IN: null, PAD: words[0] }, findInfo || {})]
    } else if (words.length === 3) {
      //PAD,IN , SIGNAL
      const signal = words[2];
      if (signal.match(/VDDQ|VSS/ig)) {
        return null;
      }
      const { padList, inList } = this.getINOUT([words[0], words[1]]);
      const findInfo = signalList.find(item => ![inList[0], padList[0]].find(it => !item.ports.includes(it)))
      return [new newPorts(signal, { IN: inList[0], PAD: padList[0] }, findInfo || {})]
    } else if (words.length === 5) {
      //PAD_N, PAD_P,IN_N, IN_P,SIGNAL
      const signal = words[4];
      if (signal.match(/VDDQ|VSS/ig)) {
        return null;
      }
      const { padList, inList } = this.getINOUT(words.filter((item, index) => index < words.length - 1));
      const { pList, nList } = this.getPositiveAndNegative(padList, inList);
      const findInfo = signalList.find(item => ![pList[0], pList[1], nList[0], nList[1]].find(it => !item.ports.includes(it)))
      return [
        new newPorts(signal, { IN: pList[1], PAD: pList[0], type: "P" }, findInfo || {}),
        new newPorts(signal, { IN: nList[1], PAD: nList[0], type: "N" }, findInfo || {})]
    }
    return null;
  }

  getINOUT = (words) => {
    const padReg = /(PAD)|(OUT)/ig, inReg = /(IN)/ig;
    const padList = words.filter(item => item.match(padReg));
    const inList = words.filter(item => item.match(inReg) && !padList.includes(item));

    if (padList.length && padList.length === inList.length) {
      return { padList, inList }
    }
    const length = words.length / 2;
    return {
      padList: words.filter((item, index) => index < length),
      inList: words.filter((item, index) => index >= length),
    }
  }

  getPositiveAndNegative = (padList, inList) => {

    let pList = [], nList = [];
    let p_net = padList[0], n_net = padList[1];
    const pad_list = matchType({ p_net, n_net });
    pList.push(pad_list[0]);
    nList.push(pad_list[1]);

    let in_p_net = inList[0], in_n_net = inList[1];
    const in_list = matchType({ p_net: in_p_net, n_net: in_n_net });
    pList.push(in_list[0]);
    nList.push(in_list[1]);
    return { pList, nList };
  }
}


function matchType({ p_net, n_net }) {
  let p_arr = [], n_arr = [], pNet = p_net, nNet = n_net;
  const pReg = /(P)|(\\+)/ig, nReg = /(N)|(\\-)/ig;
  //find difference characters
  for (let i = 0; i < p_net.length; i++) {
    const p_character = p_net[i], n_character = n_net[i] || null;

    if (p_character === n_character) {
      continue;
    }
    p_arr.push({ str: p_character, index: i });
    n_arr.push({ str: n_character, index: i });
  }

  if (!p_arr.length || !n_arr.length) {
    if (p_net.match(nReg) && n_net.match(pReg)) {
      return [nNet, pNet];
    }
    return [pNet, nNet];
  }

  if (p_arr.length === 1 && n_arr.length === 1) {
    //if difference is only one character
    /* PAD_DQSP, PAD_DQSN ,  IN_DQSP, IN_DQSN*/
    if (p_arr[0].str.match(nReg) && n_arr[0].str.match(pReg)) {
      pNet = n_net;
      nNet = p_net
    }
    return [pNet, nNet];
  } else {
    //PAD_DQSP, OUT_DQSN
    let p_number = 0, n_number = 0;
    for (let i = 0; i < p_arr.length; i++) {
      if (p_arr[i].str.match(pReg) && n_arr[i].str.match(nReg)) {
        p_number += 1;
        continue;
      }
      if (p_arr[i].str.match(nReg) && n_arr[i].str.match(pReg)) {
        n_number += 1;
        continue;
      }
    }
    if (n_number > p_number) {
      pNet = n_net;
      nNet = p_net
    }
  }

  return [pNet, nNet];
}

function autoMatchCSMPorts(signalMapping, portList) {
  let selectedPorts = []
  for (let signalItem of signalMapping) {
    if (!signalItem.pkgSignal) {
      continue;
    }
    const pinPort = getPortBySignal({ signalItem, portList, selectedPorts });

    if (pinPort) {
      signalItem.csmSignal = pinPort.name;
      signalItem.pad = pinPort.PAD;
      signalItem.in = pinPort.IN;
      signalItem.readPad = pinPort.READ_PAD
      selectedPorts.push(pinPort.type ? `${pinPort.name}::${pinPort.type}` : pinPort.name)
    }
  }
  return signalMapping;
}

function getPortBySignal({ signalItem, portList, selectedPorts }) {
  //DQ1 -> DQ, DQSP -> DQS -> positive: "T", negative: "C"
  const signalType = getDDRSignalType(signalItem.pkgSignal);
  let signalNumber = getNum(signalItem.pkgSignal);
  const netName = signalItem.nets && signalItem.nets[0] ? signalItem.nets[0].match(/DQ[0-9]+/ig) : (signalItem.nets ? signalItem.nets[0] : null);
  let netNumber = netName ? netName[0].match(/[0-9]+/ig)[0] : null;

  //([^a-zA-Z]|$)
  const typeRegList = signalType ? signalType.portTypeList.map(item => {
    if (signalItem.pkgSignal.match(/(DQSP)|(CLKP)|(WCK_T)|(RDQS_T)|(DQS[0-9]+P)|(DQSN)|(CLKN)|(WCK_C)|(RDQS_C)|(DQS[0-9]+N)|([RDQS|WCK]+[0-9]+(T|C))/ig)) {
      return new RegExp(`${item}`, "ig");
    } else {
      return new RegExp(`${item}([^a-zA-Z]|$)`, "ig");
    }
  }) : null;

  const findPorts = portList.filter(p => typeRegList && typeRegList.filter(it => p && p.name && p.name.match(it)).length);
  let pinPorts = []
  for (let i = 0; i < findPorts.length; i++) {
    const item = findPorts[i];
    const name = item.type ? `${item.name}::${item.type}` : item.name;
    if (selectedPorts.includes(name)) {
      continue;
    }
    const _name = item.name.replace(/[^a-zA-Z0-9]/ig, "")
    if (_name.toLowerCase() === signalItem.pkgSignal.toLowerCase()) {
      pinPorts.push(item);
      break;
    }

    const portInfoArr = getPortInfoSplitList(signalType.portTypeList, item.name);
    //info -> $DQ0 $DQSP1 $DQS1P
    //arr -> [ "DQ",  "0" ] , [ "DQS",  "1P" ]
    const str = portInfoArr.length ? portInfoArr[portInfoArr.length - 1] : null;
    let number = str ? getNum(str) : null;
    if (number === "00") {
      number = "0"
    }
    item.number = number;

    if (signalItem.pkgSignal.match(/DQS[0-9]+P|([RDQS|WCK]+[0-9]+T)/ig)
      && item.name.match(/DQS[0-9]+|([RDQS|WCK]+[0-9]+)/ig) && item.type === "P") {
      if (number && (number === signalNumber || number === netNumber)) {
        pinPorts.push(item);
        break;
      }
    }

    if (signalItem.pkgSignal.match(/DQS[0-9]+N|([RDQS|WCK]+[0-9]+C)/ig)
      && item.name.match(/DQS[0-9]+|([RDQS|WCK]+[0-9]+)/ig) && item.type === "N") {
      if (number && (number === signalNumber || number === netNumber)) {
        pinPorts.push(item);
        break;
      }
    }
    //DQSP
    if (signalItem.pkgSignal.match(/(DQSP)|(DQS[0-9]+P)|(RDQS_T)|([RDQS|WCK]+[0-9]+T)/ig)
      && item.name.match(/(DQS)|(RDQS)|(WCK)/ig) && item.type === "P") {
      pinPorts.push(item);
      continue;
    }
    //DQSN
    if (signalItem.pkgSignal.match(/(DQSN)|(DQS[0-9]+N)|(RDQS_C)|([RDQS|WCK]+[0-9]+C)/ig)
      && item.name.match(/(DQS)|(RDQS)|(WCK)/ig) && item.type === "N") {
      pinPorts.push(item);
      continue;
    }
    //CLK0P
    if (signalItem.pkgSignal.match(/CLK[0-9]+P|CLKP[0-9]+/ig)
      && item.name.match(/CLK[0-9]+/ig) && item.type === "P") {
      if (number && (number === signalNumber || number === netNumber)) {
        pinPorts.push(item);
        break;
      }
    }
    //CLK0N
    if (signalItem.pkgSignal.match(/CLK[0-9]+N|CLKN[0-9]+/ig)
      && item.name.match(/CLK[0-9]+/ig) && item.type === "N") {
      if (number && (number === signalNumber || number === netNumber)) {
        pinPorts.push(item);
        break;
      }
    }
    //CLKP
    if (signalItem.pkgSignal.match(/CLKP/ig) && item.name.match(/(CLK)|(CK)/ig) && item.type === "P") {
      pinPorts.push(item);
      continue;
    }
    //CLKN
    if (signalItem.pkgSignal.match(/CLKN/ig) && item.name.match(/(CLK)|(CK)/ig) && item.type === "N") {
      pinPorts.push(item);
      continue;
    }

    //DQ [0-9], CA[0-9], DM[0-9]...
    //DQ0 - DQ7 / DQ8 - DQ15 / DQ16 - DQ24
    const DQSReg = /(DQS[0-9]+[P|N])|([RDQS|WCK]+[0-9]+[T|C])/ig;
    if (number && ((number === signalNumber && !signalItem.pkgSignal.match(DQSReg)) || number === netNumber)) {
      pinPorts.push(item);
      break;
    }
    /* let _signalNumber = signalNumber, _netNumber = netNumber; */

    const findItem = getDQPort({ signalNumber, num: 8, netNumber, number, signalItem, item, DQSReg });
    if (findItem) {
      pinPorts.push(item);
      continue;
    }

    const findPortItem = getDQPortByPortNumber({ signalNumber, num: 8, netNumber, number, signalItem, item, DQSReg });
    if (findPortItem) {
      pinPorts.push(item);
      continue;
    } else {
      const findPortItem = getDQPortByPortNumber({ signalNumber, num: 8 * 2, netNumber, number, signalItem, item, DQSReg });
      if (findPortItem) {
        pinPorts.push(item);
        continue;
      }
    }

    //DM , RAS, WE, ...
    if (!signalNumber && !signalItem.pkgSignal.match(/(DQSP)|(CLKP)|(WCK_T)|(RDQS_T)|(DQS[0-9]+P)|(DQSN)|(CLKN)|(WCK_C)|(RDQS_C)|(DQS[0-9]+N)|([RDQS|WCK]+[0-9]+[T|C])/ig)) {
      pinPorts.push(item);
      continue;
    }

    if ((!number || (number && (number === signalNumber || number === netNumber)))) {
      pinPorts.push(item);
      continue;
    }

    if (!signalItem.pkgSignal.match(/(DQ)|(DQS)|(CLK)|(WCK)|(BA[0-9]+)|(A[0-9]+)|(CA[0-9]+)/ig) && number && signalNumber && number !== signalNumber) {
      pinPorts.push(item);
      continue;
    }
  }

  if (pinPorts.length > 1) {
    const filterPorts = pinPorts.filter(item => (item.number === signalNumber) || (item.number === netNumber));
    if (filterPorts.length) {
      return filterPorts[0]
    }
    return pinPorts[0]
  }
  return pinPorts.length ? pinPorts[0] : null;
}

function getDQPort({ signalNumber, num = 8, netNumber, number, signalItem, item, DQSReg }) {
  let _signalNumber = signalNumber, _netNumber = netNumber;
  if (signalNumber > 7) {
    _signalNumber = signalNumber % num;
    _signalNumber = signalNumber.toString()
  }
  if (netNumber > 7) {
    _netNumber = netNumber % num;
    _netNumber = netNumber.toString()
  }
  if (number && ((number === _signalNumber && !signalItem.pkgSignal.match(DQSReg)) || number === _netNumber)) {
    return item
  }
  return null;
}

function getDQPortByPortNumber({ signalNumber, num = 8, netNumber, number, signalItem, item, DQSReg }) {
  let _number = number;
  if (number > 7) {
    _number = number % num;
    _number = _number.toString()
  }
  if (_number && ((_number === signalNumber && !signalItem.pkgSignal.match(DQSReg)) || _number === netNumber)) {
    return item
  }
  return null;
}

async function getWriteAndReadPortList(writeSelectFile, readSelectFile, product) {
  let writePortList = [], readPortList = [];
  if (writeSelectFile && writeSelectFile.libraryId && writeSelectFile.fileName && writeSelectFile.subckt) {
    const model = await getCSMCPMSpiceModelList({ libraryId: writeSelectFile.libraryId, fileName: writeSelectFile.fileName });
    if (model && model.model && model.model.CSM) {
      writePortList = (model.model.CSM.find(item => item.name === writeSelectFile.subckt) || {}).ports || [];
    }
  }

  if (readSelectFile && readSelectFile.libraryId && readSelectFile.fileName && readSelectFile.subckt) {
    const model = await getCSMCPMSpiceModelList({ libraryId: readSelectFile.libraryId, fileName: readSelectFile.fileName });
    if (model && model.model && model.model.CSM) {
      readPortList = (model.model.CSM.find(item => item.name === readSelectFile.subckt) || {}).ports || [];
    }
  }

  const writeNames = writePortList.filter(item => item.name).map(item => { return item.port && item.port.type ? `${item.name}-${item.port.type}` : item.name });
  const readNames = readPortList.filter(item => item.name).map(item => { return item.port && item.port.type ? `${item.name}-${item.port.type}` : item.name });
  let names = [...new Set([...writeNames, ...readNames])];
  const notWriteInfo = writeSelectFile && writeSelectFile.libraryId && writeSelectFile.fileName && writeSelectFile.subckt ? false : true;

  let _ports = [], typeList = [];
  for (let nameInfo of names) {
    const [name, type] = nameInfo.split("-")
    if (typeList.includes(name) && !type) { continue }
    if (type) { typeList.push(name) }
    const findWritePortInfo = writePortList.find(item => item.name === name && (!type || (item.port && item.port.type === type)));
    // type = ['P','N']
    const findReadPortInfo = readPortList.find(item => item.name === name);

    if (notWriteInfo && findReadPortInfo && findReadPortInfo.port.PAD && findReadPortInfo.port.IN && !type) {
      // When only reading, there is no division into p/n
      const { pList, nList } = getPositiveAndNegative([findReadPortInfo.port.PAD, findReadPortInfo.port.IN], []);
      _ports.push({
        ...findReadPortInfo,
        port: {
          type: "P",
          PAD: "",
          IN: "",
          READ_PAD: pList[0]
        }
      }, {
        ...findReadPortInfo,
        port: {
          type: "N",
          PAD: "",
          IN: "",
          READ_PAD: nList[0]
        }
      })

      continue
    }
    let READ_PAD = findReadPortInfo && findReadPortInfo.port ? findReadPortInfo.port.PAD : "";
    if (findReadPortInfo && type) {
      const { pList, nList } = getPositiveAndNegative([findReadPortInfo.port.PAD, findReadPortInfo.port.IN], []);
      if (type === "P") {
        READ_PAD = pList[0]
      } else {
        READ_PAD = nList[0]
      }
    }

    const findInfo = findWritePortInfo ? findWritePortInfo : findReadPortInfo

    let info = {
      ...findInfo,
      port: {
        type: findWritePortInfo && findWritePortInfo.port ? findWritePortInfo.port.type : findReadPortInfo && findReadPortInfo.port ? findReadPortInfo.port.type : "",
        PAD: findWritePortInfo && findWritePortInfo.port ? findWritePortInfo.port.PAD : "",
        IN: findWritePortInfo && findWritePortInfo.port ? findWritePortInfo.port.IN : "",
        READ_PAD: READ_PAD
      }

    }
    _ports.push(info)
  }
  return _ports;
}

function getPositiveAndNegative(padList, inList) {
  let pList = [], nList = [];
  let p_net = padList[0], n_net = padList[1];
  const pad_list = matchType({ p_net, n_net });
  pList.push(pad_list[0]);
  nList.push(pad_list[1]);

  if (inList.length) {
    let in_p_net = inList[0], in_n_net = inList[1];
    const in_list = matchType({ p_net: in_p_net, n_net: in_n_net });
    pList.push(in_list[0]);
    nList.push(in_list[1]);
  }

  return { pList, nList };
}

function getPcbSignals(content, pcbChannelInfo, pcb) {
  let signals = []
  if (pcb && pcb.vendor === PRE_LAYOUT) {
    if (content && content.components && content.components.length) {
      const controllerComp = content.components.find(item => item.type === "Controller")
      if (controllerComp && controllerComp.pins && controllerComp.pins.length) {
        signals = controllerComp.pins.map(item => { return { name: item.net, nets: [item.signal], pkgPin: item.pin } })
      }
    }
  } else if (pcb) {
    signals = pcbChannelInfo && pcbChannelInfo.contentDTO && pcbChannelInfo.contentDTO.Signals ? pcbChannelInfo.contentDTO.Signals : []
  }
  return signals
}

function autoReadOrWriteSignalMapping(signalMapping, portList, type) {
  let autoType = type;
  if (!type) {
    let csmSignalList = [], readPadList = [], padList = [];
    signalMapping.forEach(it => {
      const { csmSignal, pad, readPad } = it;
      csmSignal && csmSignalList.push(csmSignal)
      pad && padList.push(pad)
      readPad && readPadList.push(readPad)
    });
    if (csmSignalList.length && padList.length && !readPadList.length) {
      autoType = 'read'
    } else if (csmSignalList.length && readPadList.length && !padList.length) {
      autoType = 'write'
    }
  }

  if (autoType) {
    let _signalMapping = []
    for (let info of signalMapping) {
      const { csmSignal, pad, readPad } = info;
      let _info = { ...info }
      const filterPort = portList.filter(item => csmSignal === item.name);
      _info.csmInstanceSignal = filterPort && filterPort[0] && filterPort[0].net ? filterPort[0].net : _info.csmInstanceSignal;
      if (filterPort.length) {
        if (autoType === "read") {
          const findCurrentPort = filterPort.find(item => item.port.PAD === pad && item.port.IN === info.in);
          if (findCurrentPort && findCurrentPort.port) {
            _info.readPad = findCurrentPort.port.READ_PAD;
          }
        } else {
          const findCurrentPort = filterPort.find(item => item.port.READ_PAD === readPad);
          if (findCurrentPort && findCurrentPort.port) {
            _info.pad = findCurrentPort.port.PAD;
            _info.in = findCurrentPort.port.IN;
          }
        }
      }
      _signalMapping.push({
        ..._info,
      })
    }
    return { newSignalMapping: _signalMapping, isUpdate: true }
  } else {
    const obj = getUpdateSignalMapping(signalMapping, portList)
    if (obj.isUpdate) {
      return { newSignalMapping: obj.signalMapping, isUpdate: true }
    }
  }
  return { newSignalMapping: signalMapping, isUpdate: false };
}

function getUpdateSignalMapping(signalMapping, portList) {
  let _signalMapping = JSON.parse(JSON.stringify(signalMapping)), isUpdate = false
  if (signalMapping.find(item => item.csmSignal && !item.csmInstanceSignal)) {
    _signalMapping = _signalMapping.map(item => {
      const { csmInstanceSignal, csmSignal } = item;
      if (csmSignal && !csmInstanceSignal) {
        const findPortInfo = portList.find(item => item.name === csmSignal)
        if (findPortInfo && findPortInfo.net) {
          return { ...item, csmInstanceSignal: findPortInfo.net }
        }
      }
      return { ...item }
    })
    isUpdate = true;
  }
  return { signalMapping: _signalMapping, isUpdate }
}

export default CPMCSMSpice;
export {
  autoMatchCSMPorts,
  getWriteAndReadPortList,
  getPcbSignals,
  autoReadOrWriteSignalMapping,
  getUpdateSignalMapping
}