import { CASCADE } from "../../constants/pageType";

function getBodySize(pageType) {
  let bodyClientHeight = document.body.clientHeight;
  let bodyClientWidth = document.body.clientWidth;
  let height = 250, linePosition = { position: "absolute", bottom: 250 };
  if (pageType === CASCADE) {
    height = 290;
    linePosition = { position: "absolute", bottom: 290 };
  }
  if (bodyClientHeight <= 300) {
    height = 100; linePosition.bottom = 100;
  }

  return { bodyClientWidth, bodyClientHeight, height, linePosition };

}

function getReSizeLeftRight(className, splitSize = 2) {

  const parentEle = document.getElementsByClassName(className)[0];

  if (parentEle) {
    const width = parentEle.offsetWidth / splitSize;
    return { width, linePosition: { position: "absolute", left: width } }
  }
}

function getReSizeTopBottom(className, bottomClassName, splitSize = 2) {

  const parentEle = document.getElementsByClassName(className)[0];
  const parentEleBottom = document.getElementsByClassName(bottomClassName)[0];
  if (parentEle && parentEleBottom) {
    const height = parentEle.offsetHeight / splitSize;
    const width = parentEleBottom.offsetWidth / splitSize;

    return {
      height,
      width,
      tabWidth: parentEleBottom.offsetWidth - width,
      topHeight: parentEle.offsetHeight - height,
      linePosition: {
        position: "absolute",
        left: height,
      },
      leftLinePosition: {
        position: "absolute",
        left: width,
      }
    }
  }
}

function updateContentHeight(height, heightLimit) {
  const { bodyClientHeight } = getBodySize();
  if (height <= 20) {
    height = 20;
  } else if (height >= bodyClientHeight - heightLimit) {
    height = bodyClientHeight - heightLimit
  }
  if (height < 20) {
    height = 20;
  }
  return height;
}

export {
  getBodySize,
  getReSizeLeftRight,
  getReSizeTopBottom,
  updateContentHeight
}
