import React from 'react';
import { connect } from 'react-redux';
import { SIERRA, FASTPI, ROCKY, ANDES, ANDES_V2, CASCADE } from '@/constants/pageType';
import './index.css';

function Coord(props) {
  const { unit, location, className, designID } = props;
  let _l = '';
  if (Object.prototype.toString.call(location) === '[object Object]') {
    _l = location[designID] ? location[designID].location : ''
  } else {
    _l = location;
  }
  const displayLocation = _l ? (`( ${_l} ) ${unit} `) : _l;
  return <div className={className}>{displayLocation}</div>;
}

function getLocation(state) {
  const LoginReducer = state.LoginReducer;
  let location = null;
  if (LoginReducer && LoginReducer.pageType) {
    switch (LoginReducer.pageType) {
      case FASTPI:
        location = state.PDNReducer.explorer.selections;
        break;
      case SIERRA:
        location = state.SierraReducer.explorer.selections;
        break;
      case ROCKY:
        location = state.RockyReducer.explorer.selections;
        break;
      case ANDES:
        location = state.AndesReducer.explorer.location;
        break;
      case ANDES_V2:
        location = state.AndesV2Reducer.explorer.selections;
        break;
      case CASCADE:
        location = state.CascadeReducer.explorer.selections;
        break;
      default: break;
    }
  } else {
    location = state.default ? state.default.location : null;
  }
  return location;
}

const mapState = (state) => {
  return {
    location: getLocation(state)
  };
}

export default connect(mapState)(Coord);