import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import StackupData from '@/services/data/stackupData';
import { saveData } from './store/actionCreators';
import StackupTable from '@/components/EditableTable';
import { changeStackupTable } from './store/actionCreators';
import { message } from 'antd';
import './index.css';
import LayoutData from '@/services/CeLayoutDB/newCeLayoutData';
import { SIERRA, FASTPI } from '@/constants/pageType';

class Stackup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      loading: false,
      StackupColums: [{
        title: 'Index',
        dataIndex: 'indexMetal',
        width: '60px',
      }, {
        title: 'Name',
        dataIndex: 'name',
        width: '180px',
      }, {
        title: 'Type',
        dataIndex: 'type',
        width: '140px',
      }, {
        title: 'Thickness',
        dataIndex: 'thickness',
        width: '160px',
        onCell: record => ({
          record,
          edit: true,
          title: 'Thickness',
          dataIndex: 'thickness',
          handleSave: this.saveData
        })
      }, {
        title: 'Dielectric Constant',
        dataIndex: 'epsilon',
        width: '160px',
        onCell: record => ({
          record,
          edit: record.epsilon ? true : false,
          title: 'Dielectric Constant',
          dataIndex: 'epsilon',
          handleSave: this.saveData
        })
      }, {
        title: 'Loss Tangent',
        dataIndex: 'delta',
        width: '160px',
        onCell: record => ({
          record,
          edit: record.delta ? true : false,
          title: 'Loss Tangent',
          dataIndex: 'delta',
          handleSave: this.saveData
        })
      }],
      scrollY: 390
    }
  }

  saveData = (row) => {
    const { data, unit } = this.props;
    const newData = [].concat(data);
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].indexTable === row.indexTable) {
        if (row.thickness) {
          newData[i].thickness = parseFloat(row.thickness);
        }

        if (row.epsilon) {
          newData[i].epsilon = parseFloat(row.epsilon);
        }

        if (row.delta) {
          newData[i].delta = parseFloat(row.delta)
        }
        break;
      }
    }
    this.props.changeTable(newData, unit);
    StackupData.saveLayer(newData, unit).then(res => {
      if (res.data.code !== 0) {
        message.error('Update stackup failed!');
      }
    })
  }

  InitializeData = (Unit) => {
    StackupData.getLayer(true, false, true).then(res => {
      const data = JSON.parse(JSON.stringify(res));
      const unit = Unit ? Unit : this.props.unit;
      this.props.setStackupData(data, unit);
      this.setState({
        pagination: {
          total: res.length,
          pageSize: res.length,
          hideOnSinglePage: true
        },
      })
    })
  }

  componentDidMount() {
    LayoutData.getStackup().then(response => {
      const unit = response.getUnitString();
      this.InitializeData(unit);
      this.setState((prevState) => {
        prevState.StackupColums[3].title = `Thickness (${unit})`;
        return {
          StackupColums: prevState.StackupColums,
        }
      })
    })
    window.addEventListener('resize', this.resize);
    this.layoutRef = document.getElementsByClassName('aurora-content')[0];
    this.layoutTop = document.getElementsByClassName('aurora-content-top')[0];
    if (this.layoutTop) {
      const maxHeight = this.layoutTop.offsetHeight;
      this.setState({
        scrollY: maxHeight - 260
      });
    } else if (this.layoutRef) {
      const maxHeight = this.layoutRef.offsetHeight;
      this.setState({
        scrollY: maxHeight - 260
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.layoutRef = document.getElementsByClassName('aurora-content')[0];
    this.layoutTop = document.getElementsByClassName('aurora-content-top')[0];
    if (this.layoutTop) {
      const maxHeight = this.layoutTop.offsetHeight;
      this.setState({
        scrollY: maxHeight - 260
      });
    } else if (this.layoutRef) {
      const maxHeight = this.layoutRef.offsetHeight;
      this.setState({
        scrollY: maxHeight - 260
      });
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.unit && this.props.unit !== prevProps.unit) {
      this.setState((prevState) => {
        prevState.StackupColums[3].title = `Thickness (${this.props.unit})`;
        return {
          StackupColums: prevState.StackupColums,
        }
      })
    }

    this.layoutRef = document.getElementsByClassName('aurora-content')[0];
    this.layoutTop = document.getElementsByClassName('aurora-content-top')[0];
    const { height } = this.props;
    if (height && height !== prevProps.height) {
      if (this.layoutTop) {
        const maxHeight = this.layoutTop.offsetHeight;
        this.setState({
          scrollY: maxHeight - 260
        });
      }
    }
  }

  getTotalThickness(data) {
    if (data.length === 0) {
      return null;
    }
    let sum = 0;
    data.forEach(function (item) {
      sum += parseFloat(item.thickness);
    });
    return sum.toFixed(3);
  }

  render() {

    const { data, loading } = this.props;
    const { StackupColums, pagination, scrollY } = this.state;
    const totalThickness = this.getTotalThickness(data);
    return (
      <div className="stackup-table">
        <StackupTable
          loading={loading}
          dataSource={data}
          pagination={pagination}
          columns={StackupColums}
          scroll={{ y: scrollY }}
          rowKey={record => record.indexTable}
          footer={() => <TotalThickness thickness={totalThickness} />}
          size="small"
        />
      </div>
    )
  }
}

const TotalThickness = (props) => {
  return (
    <div>
      Total Thickness : <span style={{ marginLeft: 250 }}>{props.thickness}</span>
    </div>
  )
}

const mapState = (state) => {
  const LoginReducer = state.LoginReducer;
  if (LoginReducer && LoginReducer.pageType === FASTPI) {
    return {
      data: [],
      unit: "",
      defaultLeft: "",
      defaultTop: ""
    }
  } else if (LoginReducer && LoginReducer.pageType === SIERRA) {
    return {
      data: [],
      unit: "",
      defaultLeft: "",
      defaultTop: ""
    }
  } else {
    const { stackup } = state;
    const { data, unit } = stackup;
    const { defaultLeft, defaultTop } = state.PanelStatus;
    return {
      data, unit, defaultLeft, defaultTop
    }
  }
}

const mapDispatch = (dispatch) => ({
  setStackupData(data, unit) {
    dispatch(saveData(data, unit));
  },
  changeTable(data, unit) {
    dispatch(changeStackupTable(data, unit));
  },
})

export default connect(mapState, mapDispatch)(Stackup);