import _ from 'lodash';
import { getTextWidthAndHeight } from '../../helper/getTextWidth';
import defaultColor from '../../../constants/defaultColors';

const colors = ['#f47188', '#f4b183', '#6495ed']
const singleGridHeight = 15
class SchematicPlot {
  constructor(props) {
    const { id, width, height, data, font, grid, updateTip } = props;
    this.id = id;
    this.width = width;
    this.height = height + 5;
    this.data = data;
    this.backArea = data.backArea || false;
    this.ctx = this.initCtx(id);
    this.gridWidth = 100;
    this.gridHeight = 100;
    this.netName = [];
    this.font = font || 12;
    this.grid = grid || false;
    this.updateTip = updateTip;
    this.virtualCanvas = document.createElement("canvas");

    // draw net params
    this.verticals = []; // [[x1, y1, x2, y2]]
    this.compAreas = [];
    this.xPosition = 0;
    this.usedxPosition = [];
    this.gridPosition = {};
    this.dashedLines = [];
  }

  initCtx = (id) => {
    const { width, height } = this;
    let canvas = document.getElementById(id);
    const virtualCanvas = document.createElement("canvas");
    virtualCanvas.width = width || canvas.width;
    virtualCanvas.height = height || canvas.height;
    this.virtualCanvas = virtualCanvas;
    const ctx = virtualCanvas.getContext('2d');
    ctx.lineWidth = 1;
    ctx.font = `normal ${this.font}px Arial`;
    ctx.fillStyle = '#000000';
    this.netName = [];
    this.verticals = [];
    this.compAreas = [];
    this.dashedLines = [];
    this.xPosition = 0;
    this.usedxPosition = [];
    this.gridPosition = {};
    return ctx;
  }

  clear = () => {
    const { ctx, width, height, id } = this;
    ctx.clearRect(0, 0, width, height);
    this.ctx = this.initCtx(id);
    let canvas = document.getElementById(id);
    canvas.width = width;
  }

  reset = ({ width, height }) => {
    this.clear();
    this.ctx = this.initCtx(this.id);
    this.width = width;
    this.height = height;
  }

  set = (param, value) => {
    this[param] = value;
  }

  redraw = () => {
    this.updateTip('Updating Schematic...');
    this.clear();
    this.draw();
    this.updateTip('');
  }

  draw = () => {
    const { width, data, grid } = this;
    const { max } = data;
    const gridWidth = (width - 40) / (2 * max + (this.backArea ? 1 : -1));
    this.gridWidth = gridWidth;
    this.xPosition = Math.ceil(gridWidth / 15) * (this.backArea ? 2 : 1) + 3;

    this.drawBackground();

    grid && this.drawGrid();

    this.getCompsLocations();

    this.drawNets();

    this.drawComps();

    this.drawNetNames();

    this.drawDashedLines();

    this.drawToTrueCanvas();
  }

  drawToTrueCanvas = () => {
    const { id, virtualCanvas } = this;
    let canvas = document.getElementById(id);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(virtualCanvas, 0, 0);
  }

  drawGrid = () => {
    const { ctx, width, height, } = this;
    // init gridlines ctx
    ctx.save();
    ctx.lineWidth = 1;
    ctx.translate(0.5, 0.5);
    ctx.beginPath();
    ctx.strokeStyle = '#d9d9d9';

    // draw columns
    // const gridWidth = Math.round(width / columns);
    let drawWidth = 15;
    while (drawWidth <= width) {
      ctx.moveTo(drawWidth, 0);
      ctx.lineTo(drawWidth, height);
      ctx.stroke();
      drawWidth = Math.round(drawWidth + 15);
    }

    // draw row
    // const gridHeight = Math.round(height / deep);
    let drawHeight = 15;
    while (drawHeight <= height) {
      ctx.moveTo(0, drawHeight);
      ctx.lineTo(width, drawHeight);
      ctx.stroke();
      drawHeight = drawHeight > height ? height : Math.round(drawHeight + 15);

    }

    ctx.closePath();
    ctx.translate(1, 1);
  }

  drawBackground = () => {
    const { ctx, width, height } = this;
    ctx.beginPath();
    ctx.fillStyle = '#ffffff';
    ctx.rect(0, 0, width, height);
    ctx.fill();
    ctx.closePath();
    ctx.fillStyle = '#000000';
  }

  getCompsLocations = () => {
    const { gridWidth, data } = this;
    const { max, comps } = data;

    for (let i = 1; i <= max; i++) {
      const filterComps = comps.filter(item => item.columnIndex === i);
      const gridX = 20 + (2 * i - (this.backArea ? 1 : 2)) * gridWidth;
      // rect width = 0.5 * grid width
      const startX = Math.ceil((gridX + 0.2 * gridWidth) / singleGridHeight) * singleGridHeight;
      const endX = Math.ceil((gridX + 0.8 * gridWidth) / singleGridHeight) * singleGridHeight;

      const background = i === 1 ? colors[0] : i === max ? colors[2] : colors[1];

      for (let comp of filterComps) {
        const { grid, name, rowIndex } = comp;
        // rect height = 0.3 * grid * grid height
        const gridY = 2 * singleGridHeight + (rowIndex - 1) * 3 * singleGridHeight;
        const startY = Math.ceil((gridY) / singleGridHeight) * singleGridHeight + (rowIndex - 1) * singleGridHeight;
        const endY = Math.ceil((gridY) / singleGridHeight) * singleGridHeight + grid * 3 * singleGridHeight + (rowIndex - 1) * singleGridHeight;
        this.compAreas.push({ name, startX, startY, endX, endY, background })
      }
    }
  }

  drawComps = () => {
    const { ctx, compAreas } = this;
    for (let compArea of compAreas) {
      const font = this.font + 2;
      const { name, startX, startY, endX, endY, background } = compArea;
      ctx.strokeStyle = background;
      ctx.fillStyle = `#ffffff`;
      ctx.lineWidth = 4;
      ctx.beginPath();
      ctx.roundRect(startX, startY + 5, endX - startX, endY - startY - 10, 10);
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
      const { width: nameWidth, height: nameHeight } = getTextWidthAndHeight(name, { fontSize: font, fontWeight: 'bold' });
      ctx.font = `bold ${font}px Arial`;
      ctx.lineWidth = 1
      ctx.fillStyle = `#000000`;
      const nameX = startX + 0.5 * (endX - startX - nameWidth);
      const nameY = startY + 0.5 * (endY - startY + 0.5 * nameHeight);
      ctx.beginPath();
      ctx.fillText(name, nameX, nameY);
      ctx.closePath();
      ctx.fillStyle = background;
      ctx.strokeStyle = `#000000`
    }
  }

  drawNets = () => {
    const { ctx, gridWidth, data, font, compAreas } = this;
    const { nets, colors, comps } = data;
    const netNames = Object.keys(nets);
    this.netName = []
    ctx.font = `normal ${font - 2}px Arial`;

    const getPoiont = ({ row, column, netIndex, type, fromIndex }) => {
      const gridX = 20 + (2 * column - (this.backArea ? 1 : 2)) * gridWidth;
      const gridY = 2 * singleGridHeight + (row - 1) * 3 * singleGridHeight;
      let x = 0;
      if (type === 'from') {
        x = Math.ceil((gridX + 0.8 * gridWidth) / singleGridHeight) * singleGridHeight;
      } else {
        if (fromIndex > column) {
          x = Math.ceil((gridX + 0.2 * gridWidth) / singleGridHeight) * singleGridHeight + gridWidth * 0.6;
        } else {
          x = Math.ceil((gridX + 0.2 * gridWidth) / singleGridHeight) * singleGridHeight;
        }
      }
      const y = Math.ceil(gridY / singleGridHeight) * singleGridHeight + (netIndex + (netIndex - 1) * 2) * singleGridHeight + (row - 1) * singleGridHeight;
      // ctx.beginPath()
      // ctx.arc(x, y, 5, 0, 2 * Math.PI)
      // ctx.fill()
      // ctx.closePath()
      return { x, y }
    }

    let interPoints = [], toInputPositions = {};
    for (let netName of netNames) {
      const netInfo = nets[netName];
      const { from, to } = netInfo;

      if (!from.length || !to.length) {
        continue;
      }
      // draw first line use from[0] as base from - to line
      const fromItem = from[0];
      const { rowIndex, columnIndex, netIndex, name } = fromItem;
      ctx.strokeStyle = colors[name] || '#000000';
      const fromPoint = getPoiont({ row: rowIndex, column: columnIndex, netIndex, type: 'from' })
      let firstGroupPoints = [];
      for (let i = 0; i < to.length; i++) {
        const { rowIndex: _rowIndex, columnIndex: _columnIndex, netIndex: _netIndex, name: _name } = to[i];
        const sameComp = _name === name ? true : false;
        let toPoint = getPoiont({ row: _rowIndex, column: _columnIndex, netIndex: _netIndex, type: 'to', fromIndex: columnIndex });
        let outputPoints = [];
        if (sameComp) {
          const x = 0.5 * (fromPoint.x + toPoint.x);
          const comp = compAreas.find(item => item.name === name);
          const currentComp = comps.find(item => item.name === name);
          const currentPath = currentComp.path.find(item => item.from === name && item.to === _name && item.net === netName);
          if (currentPath && currentPath.output) {
            for (let output of currentPath.output) {
              const outputNet = currentComp.nets.find(item => item.net === output);
              let outputPoint = getPoiont({ row: _rowIndex, column: _columnIndex, netIndex: outputNet.index, type: 'to' });
              outputPoints.push({ ...outputPoint, net: netName, yBottom: comp.endY + singleGridHeight - 7.5 })
            }
          } else {
            toPoint = { x, y: comp.endY }
          }
        }
        const { width } = ctx.measureText(netName);
        !sameComp && this.netName.push({ netName, width, toPoint: { y: toPoint.y, x: _columnIndex === columnIndex ? fromPoint.x : toPoint.x }, sameLevel: _columnIndex <= columnIndex ? true : false })
        if (!sameComp && fromPoint.y === toPoint.y) {
          firstGroupPoints.push([fromPoint, toPoint])
        } else if (sameComp) {
          const { x, y } = fromPoint;
          const endX_1 = this.getEndX(x, y, toPoint.x, toPoint.y);
          if (!endX_1) {
            continue;
          }
          if (!outputPoints.length) {
            // four-segment polyline

            // first
            let linePoint = [fromPoint, { x: endX_1, y }];

            // second
            linePoint.push({ x: endX_1, y: toPoint.y + singleGridHeight - 7.5 });

            // thrid
            linePoint.push({ x: toPoint.x, y: toPoint.y + singleGridHeight - 7.5 })

            // fourth
            linePoint.push({ x: toPoint.x, y: toPoint.y - 5 });

            firstGroupPoints.push(linePoint)
          } else {
            for (let outputPoint of outputPoints) {
              const { x: xo, y: yo, net: neto, yBottom } = outputPoint;
              let endX_2 = xo - 3 * singleGridHeight, endY_1 = yBottom + singleGridHeight;
              let _linePoint = [fromPoint, { x: endX_1, y }];
              if (toInputPositions[`${x}-${y}`]) {
                endX_2 = toInputPositions[`${x}-${y}`].endX_2;
                endY_1 = toInputPositions[`${x}-${y}`].endY_1;
              } else {
                while (this.judgeOverlap(endX_2, yo, endX_2, endY_1)) {
                  endX_2 = endX_2 - singleGridHeight;
                }
                while (this.judgeOverlap(endX_1, endY_1, endX_2, endY_1)) {
                  endY_1 = endY_1 + singleGridHeight;
                }
                this.verticals.push([endX_2, endY_1, endX_1, endY_1]);
                this.verticals.push([endX_2, yo, endX_2, endY_1]);
              }
              toInputPositions[`${x}-${y}`] = { endX_2, endY_1 }

              // five-segment polyline

              // second
              _linePoint.push({ x: endX_1, y: endY_1 });

              // thrid
              _linePoint.push({ x: endX_2, y: endY_1 })

              // fourth
              _linePoint.push({ x: endX_2, y: yo })

              // fifth
              _linePoint.push({ x: xo, y: yo });

              this.dashedLines.push({ x1: x, x2: xo, y: yo });

              firstGroupPoints.push(_linePoint);

              const { width } = ctx.measureText(neto);
              this.netName.push({ netName: neto, width, toPoint: { x: xo, y: yo } })
            }
          }
        } else {
          // three-segment polyline
          const { x, y } = fromPoint;
          // first
          let linePoint = [fromPoint]
          const endX_1 = this.getEndX(x, y, toPoint.x, toPoint.y);
          linePoint.push({ x: endX_1, y });

          // second
          linePoint.push({ x: endX_1, y: toPoint.y });

          // thrid
          linePoint.push(toPoint)

          firstGroupPoints.push(linePoint)
        }
      }

      // another from line to first from line;
      for (let i = 1; i < from.length; i++) {
        const _fromItem = from[i];
        if (!_fromItem) {
          break;
        }

        const { rowIndex: _rowIndex, columnIndex: _columnIndex, netIndex: _netIndex, name: _name } = _fromItem;
        ctx.strokeStyle = colors[_name] || '#000000';
        const _fromPoint = getPoiont({ row: _rowIndex, column: _columnIndex, netIndex: _netIndex, type: 'from' });
        const { width } = ctx.measureText(netName);
        this.netName.push({ netName, width, fromPoint: _fromPoint, sameLevel: _columnIndex <= columnIndex ? true : false })
        // two-segment polyline
        const { x, y } = _fromPoint;
        // first
        let linePoint = [_fromPoint]
        const endX_1 = this.getEndX(x, y, fromPoint.x, fromPoint.y);
        linePoint.push({ x: endX_1, y });

        // second
        linePoint.push({ x: endX_1, y: fromPoint.y })

        // thrid
        linePoint.push(fromPoint)

        firstGroupPoints.push(linePoint)
      }

      // draw firstPoint line
      for (let pointLine of firstGroupPoints) {
        for (let i = 0; i < pointLine.length - 1; i++) {
          const { x: x1, y: y1 } = pointLine[i];
          const { x: x2, y: y2 } = pointLine[i + 1];
          if (i > 0) {
            const intersection = checkInterSection(x1, y1, firstGroupPoints);
            if (intersection) {
              interPoints.push({ x: x1, y: y1 })
            }
          }
          ctx.beginPath()
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.stroke();
          ctx.closePath()
        }
      }

      // draw net name
      // const netsLength = comps.filter(item => item.columnIndex === columnIndex + 1).map(item => item.nets.map(net => net.net)).flat(3)
      // const maxWidth = from.length > 1 ? gridWidth / 2 : Math.floor(gridWidth / [...new Set(netsLength)].length);
      const { width } = ctx.measureText(netName);
      ctx.strokeStyle = '#000000';
      this.netName.push({ netName, width, fromPoint })
    }

    // draw intersection point 
    for (let interPoint of interPoints) {
      const { x, y } = interPoint;
      ctx.beginPath()
      ctx.arc(x, y, 3.5, 0, 2 * Math.PI);
      ctx.fill()
      ctx.closePath()
    }
  }

  drawNetNames = () => {
    const { ctx, font } = this;
    ctx.font = `normal ${font - 2}px Arial`;

    for (let net of this.netName) {
      const { netName, width, fromPoint, toPoint, sameLevel } = net;
      if (fromPoint) {
        ctx.beginPath()
        ctx.fillStyle = '#000000';
        // ctx.fillText(netName, fromPoint.x + 5, fromPoint.y + 3, width > maxWidth - 3 ? maxWidth - 3 : width)
        ctx.fillText(netName, fromPoint.x + 5, fromPoint.y + font * 1.5)
        ctx.closePath()
      }

      if (toPoint) {
        ctx.beginPath()
        ctx.fillStyle = '#000000';
        // ctx.fillText(netName, toPoint.x + 5, toPoint.y + 3, width > maxWidth - 3 ? maxWidth - 3 : width)
        if (sameLevel) {
          const _x = toPoint.x + 5
          const find = this.netName.find(item => item.netName === netName && item.fromPoint && item.fromPoint.y === toPoint.y
            && (_x < (item.fromPoint.x + 2 * width) || _x > (item.fromPoint.x - 2 * width)))
          !find && ctx.fillText(netName, _x, toPoint.y + font * 1.5)
        } else {
          ctx.fillText(netName, toPoint.x - width - 5, toPoint.y + font * 1.5)
        }
        ctx.closePath()
      }
    }
  }

  drawDashedLines = () => {
    const { dashedLines, ctx } = this;

    ctx.strokeStyle = '#999999';
    ctx.setLineDash([8, 8])
    for (let dashedLine of dashedLines) {
      const {x1, x2, y} = dashedLine;
      ctx.beginPath()
      ctx.moveTo(x1, y);
      ctx.lineTo(x2, y);
      ctx.stroke();
      ctx.closePath();
    }
    ctx.setLineDash([])
  }

  getEndX = (startX, startY, endX, endY) => {
    const { compAreas } = this;
    if (this.gridPosition[`${startX}-${startY}`]) {
      const posX = this.gridPosition[`${startX}-${startY}`];
      let _compAreas = compAreas.filter(item => {
        if (item.startX <= posX && item.endX >= posX) {
          // Determine whether two lines coincide
          if ((item.startY < startY && item.endY > startY) ||
            (item.startY < endY && item.endY > endY) ||
            (item.startY > startY && item.endY < endY) ||
            (item.startY > endY && item.endY < startY)) {
            return true;
          }
        }


        if (item.startY < endY && item.endY > endY) {
          // Determine whether two lines coincide
          if ((item.startX < posX && item.endX > posX) ||
            (item.startX < endX && item.endX > endX) ||
            (item.startX > posX && item.endX < endX) ||
            (item.startX > endX && item.endX < posX)) {
            return true;
          }
        }
        return false;
      });
      if (!_compAreas.length) {
        return posX
      }
    }

    let newX = this.xPosition;
    let newXPos = newX * 15;

    while (newXPos < (startX + 3 * singleGridHeight)) {
      newX = newX + 1;
      newXPos = newX * 15;
    }

    while (true) {
      const _newXPos = newXPos;
      let _compAreas = compAreas.filter(item => {
        if (item.startX <= _newXPos && item.endX >= _newXPos) {
          // Determine whether two lines coincide
          if ((item.startY < startY && item.endY > startY) ||
            (item.startY < endY && item.endY > endY) ||
            (item.startY > startY && item.endY < endY) ||
            (item.startY > endY && item.endY < startY)) {
            return true;
          }
        }

        if (item.startY < endY && item.endY > endY) {
          // Determine whether two lines coincide
          if ((item.startX < _newXPos && item.endX > _newXPos) ||
            (item.startX < endX && item.endX > endX) ||
            (item.startX > _newXPos && item.endX < endX) ||
            (item.startX > endX && item.endX < _newXPos)) {
            return true;
          }
        }
        return false;
      });
      if (_compAreas.length) {
        newX = newX + 1;
        while (this.usedxPosition.includes(newX)) {
          newX = newX + 1;
        }
        newXPos = newX * 15;
      } else {
        break;
      }
      if (newX > 300) {
        newX = this.xPosition;
        newXPos = newX * 15;
        break;
      }
    }

    this.usedxPosition.push(newX);
    this.xPosition = this.xPosition + 1
    while (this.usedxPosition.includes(this.xPosition)) {
      this.xPosition = this.xPosition + 1
    }
    this.gridPosition[`${startX}-${startY}`] = newXPos;
    return newXPos
  }

  judgeOverlap = (x1, y1, x2, y2) => {
    let lines = []
    if (x1 === x2) {
      lines = this.verticals.filter(item => {
        if (item[0] === x1 && item[2] === x2) {
          if ((item[1] < y1 && item[3] > y1) ||
            (item[1] < y2 && item[3] > y2) ||
            (item[1] > y1 && item[3] < y2) ||
            (item[1] > y2 && item[3] < y1)) {
            return true;
          }
        }
        return false
      });
    } else if (y1 === y2) {
      lines = this.verticals.filter(item => {
        if (item[1] === y1 && item[3] === y2) {
          if ((item[0] < x1 && item[2] > x1) ||
            (item[0] < x2 && item[2] > x2) ||
            (item[0] > x1 && item[2] < x2) ||
            (item[0] > x2 && item[2] < x1)) {
            return true;
          }
        }
        return false
      });
    }
    return lines.length ? true : false
  }
}

function transToSchematic(powerTrees = []) {
  let paths = [];
  for (let powerTree of powerTrees) {
    const { tree } = powerTree;
    const { branch = [] } = tree;
    for (let i = branch.length - 1; i > 0; i--) {
      const loads = branch[i].filter(item => !item.isGnd && (item.type === 'Load' || (item.type === 'VRM' && !item.middle)))
      for (let load of loads) {
        let path = [load], j = i - 1, child = load;
        while (j >= 0) {
          let _child = child;
          const _branch = branch[j] || [];
          const find = _branch.find(item => item.name === _child.prevComp && !item.isGnd
            && (!item.pcbKey || (item.connectionType === 'prev' && _child.connectionType === 'next') || item.pcbKey === _child.pcbKey)
            && (!item.nextNet || (item.nextNet === _child.prevNet)));
          if (find) {
            path = [find, ...path];
            child = find;
          } else {
            break;
          }

          j = j - 1;
        }

        if (j < 0) {
          const _path = path.filter(item => ['Load', 'VRM', 'root'].includes(item.type))
          j < 0 && paths.push(_path)
        }
      }
    }
  }

  if (!paths.length) {
    return { nets: {}, comps: [], max: 1, height: 100, backArea: false };
  }

  const maxIndex = _.max(paths.map(p => p.length));
  paths = setColumnIndex(paths, maxIndex);

  const schematicData = mergePath(paths, maxIndex);

  const plotData = setIndexForComps(schematicData);
  return plotData;
}

function setColumnIndex(paths, max) {
  return paths.map(path => {
    return path.map((p, index) => {
      const { name, rootNet, load, voltage, middle, nextNet } = p;
      return { name, load, voltage, rootNet, columnIndex: index === path.length - 1 ? max : index + 1, middle, nextNet }
    })
  })
}

function saveInNets(nets, net, from, to) {
  let _nets = { ...nets };
  if (!_nets[net]) {
    _nets[net] = { from: [from], to: [to] }
  } else {
    _nets[net].from = [...new Set([..._nets[net].from, from])]
    _nets[net].to = [...new Set([..._nets[net].to, to])]
  }
  return _nets
}

function mergePath(paths, max) {
  let nets = {}, comps = [];
  for (let path of paths) {
    for (let i = 0; i < path.length; i++) {
      const comp = path[i];
      const findIndex = comps.findIndex(item => item.name === comp.name);
      const prev = path[i - 1];
      const next = path[i + 1];
      if (findIndex < 0) {
        let _path = [];
        if (prev) {
          let p = { from: prev.name, to: comp.name, net: comp.rootNet };
          _path.push(p)
          nets = saveInNets(nets, comp.rootNet, prev.name, comp.name)
        }
        if (next) {
          let p = { from: comp.name, to: next.name, net: next.rootNet };
          if (next.name === comp.name && next.middle) {
            const _next = path[i + 2];
            p.output = _next ? [_next.rootNet] : [next.nextNet]
          }
          _path.push({ from: comp.name, to: next.name, net: next.rootNet })
          nets = saveInNets(nets, next.rootNet, comp.name, next.name)
        }
        comps.push({ ...comp, path: [..._path] })
      } else {
        if (comps[findIndex].columnIndex > comp.columnIndex) {
          comps[findIndex].columnIndex = comp.columnIndex
        }
        if (prev) {
          const _findIndex = comps[findIndex].path.findIndex(item => item.from === prev.name && item.to === comp.name && item.net === comp.rootNet);
          if (_findIndex < 0) {
            let p = { from: prev.name, to: comp.name, net: comp.rootNet };
            comps[findIndex].path.push(p)
            nets = saveInNets(nets, comp.rootNet, prev.name, comp.name)
          }
        }
        if (next) {
          const _findIndex = comps[findIndex].path.findIndex(item => item.from === comp.name && item.to === next.name && item.net === next.rootNet);
          if (_findIndex < 0) {
            let p = { from: comp.name, to: next.name, net: next.rootNet };
            if (next.name === comp.name && next.middle) {
              const _next = path[i + 2];
              p.output = _next ? [_next.rootNet] : [next.nextNet]
            }
            comps[findIndex].path.push(p)
            nets = saveInNets(nets, next.rootNet, comp.name, next.name)
          } else if (_findIndex > -1 && next.name === comp.name && next.middle) {
            const output = comps[findIndex].path[_findIndex].output || [];
            const _next = path[i + 2];
            comps[findIndex].path[_findIndex].output = [...new Set([...output, _next ? _next.rootNet : next.nextNet])]
          }
        }
      }
    }
  }

  comps.forEach(comp => {
    const nets = [...new Set(comp.path.map(p => p.net))]
    comp.grid = nets.length;
    comp.nets = nets.sort().map((item, index) => ({ net: item, index: index + 1 }))
  })

  return { comps, nets, max }
}

function setIndexForComps(schematicData) {
  const { comps, nets, max } = schematicData;
  let vrmIndex = 1, loadIndex = 1, compsByI = [], maxLength = 0;
  let _comps = [...comps];
  for (let i = 1; i <= max; i++) {
    const filterComps = comps.map((item, index) => item.columnIndex === i ? index : undefined).filter(item => item !== undefined);
    compsByI.push(filterComps);
    maxLength = maxLength > filterComps.length ? maxLength : filterComps.length
  }

  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < max; j++) {
      if (j + 1 === max) {
        const index = compsByI[j][i];
        if (!isNaN(index) && index > -1) {
          _comps[index].rowIndex = loadIndex;
          loadIndex = loadIndex + _comps[index].grid || 1
        }
      } else {
        const index = compsByI[j][i];
        if (!isNaN(index) && index > -1) {
          _comps[index].rowIndex = vrmIndex;
          vrmIndex = vrmIndex + _comps[index].grid || 1
        }
      }
    }
  }

  const height = (vrmIndex > loadIndex ? (vrmIndex - 1) : (loadIndex - 1)) * (4 * singleGridHeight) + 30;

  const getPosition = (nameList, netName) => {
    return nameList.map(name => {
      const comp = _comps.find(item => item.name === name);
      if (comp) {
        const { nets, rowIndex, columnIndex } = comp;
        const netIndexInfo = nets.find(n => n.net === netName);
        const netIndex = netIndexInfo ? netIndexInfo.index : 1;
        return { name, rowIndex, columnIndex, netIndex }
      }
      return undefined;
    }).filter(item => !!item).sort((a, b) => a.rowIndex - b.rowIndex)
  }

  let colors = {}, colorIndex = 0;
  const lastColumn = _.max(_comps.map(comp => comp.columnIndex));
  const netRow = _comps.filter(comp => comp.columnIndex === lastColumn).map(comp => {
    const { nets, rowIndex } = comp;
    return nets.map(n => ({ ...n, index: rowIndex + n.index - 1 }));
  }).flat(2);
  _comps = _comps.map(comp => {
    if (comp.columnIndex !== lastColumn) {
      const { nets, rowIndex, grid, name } = comp;
      let _nets = nets.map(n => ({ net: n.net }));
      _nets.forEach(net => {
        const findNet = netRow.find(n => net.net === n.net);
        if (findNet && findNet.index < (rowIndex + grid) && findNet.index > (rowIndex - 1)) {
          net.index = findNet.index + 1 - rowIndex;
        }
      })
      let _index = 1;
      _nets.forEach(net => {
        while (net.index === undefined) {
          let temp = _index
          const exist = _nets.find(net => net.index === temp);
          if (!exist) {
            net.index = _index;
          }
          _index = _index + 1;
        }
      })
      colors[name] = defaultColor[colorIndex];
      colorIndex = colorIndex + 1;
      return { ...comp, nets: _nets.sort((a, b) => a.index - b.index) }
    }
    return comp
  })

  let _nets = { ...nets };
  let netNames = Object.keys(_nets);
  for (let netName of netNames) {
    let netInfo = _nets[netName] || [];
    const { from, to } = netInfo;
    const _from = getPosition(from, netName);
    const _to = getPosition(to, netName);
    _nets[netName].from = _from;
    _nets[netName].to = _to;
  }

  const compIndex_1 = comps.filter(item => item.columnIndex === 1);
  const backComps = compIndex_1.filter(item => item.path.some(p => p.from === item.name && p.to === item.name && p.output));

  return { comps: _comps, nets, max, height: height || 100, colors, backArea: backComps.length ? true : false }
}

function checkInterSection(x, y, linePoints) {
  let minY = Infinity, maxY = -1, minX = Infinity, maxX = -1;
  for (let points of linePoints) {
    for (let point of points) {
      if (x === point.x) {
        minY = minY < point.y ? minY : point.y
        maxY = maxY > point.y ? maxY : point.y
      } else if (y === point.y) {
        minX = minX < point.x ? minX : point.x
        maxX = maxX > point.x ? maxX : point.x
      }
    }
  }

  if ((y < maxY && y > minY) || (x < maxX && x > minX)) {
    return true;
  }
  return false
}

export {
  transToSchematic,
  SchematicPlot
}