import React, { PureComponent, Fragment, createRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Select, Input } from 'antd';
import ModelDialog from '@/components/SelectModel/ModelDialog';
import { checkRLCValue } from '@/services/helper/dataProcess';

const Option = Select.Option;

const UNIT = {
  r: [{ unit: 'mΩ', num: "m" }, { unit: 'Ω', num: "1" }, { unit: 'KΩ', num: 'K' }, { unit: 'MΩ', num: 'M' }],
  l: [{ unit: 'pH', num: "p" }, { unit: 'nH', num: "n" }, { unit: 'uH', num: 'u' }, { unit: 'H', num: '1' }],
  c: [{ unit: 'pF', num: "p" }, { unit: 'nF', num: "n" }, { unit: 'uF', num: 'u' }, { unit: 'F', num: '1' }]
}

class RLCModel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rUnit: '1',
      lUnit: 'n',
      cUnit: 'n',
      rlcValue: props.record.value,
    }

    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('sierra-model-dialog');
  }

  getrlcValue = () => {
    const { record } = this.props;
    let value = record.value
    if (!record.value || toString.call(record.value) !== '[object Object]') {
      value = {
        r: "",
        l: "",
        c: ""
      }
    }
    const r = this.settingUnit(value.r, '1');
    const l = this.settingUnit(value.l, 'n');
    const c = this.settingUnit(value.c, 'n');
    this.setState({
      rUnit: r.unit,
      lUnit: l.unit,
      cUnit: c.unit,
      rlcValue: {
        r: r.value,
        l: l.value,
        c: c.value
      }
    });
  }

  settingUnit(param, defaultUnit) {
    let value, unit;
    // If param is a number, the number 1 is expressed as 1MHz.
    if (!isNaN(Number(param))) {
      return { value: param.toString(), unit: "1" }
    } else {
      value = parseFloat(param);
      unit = param.slice(-1);
      if ((unit && !isNaN(Number(unit))) || !unit) {
        // If the last character of param is still a number, set the default.
        unit = "1";
      }
      // If value is NaN
      if (isNaN(value)) {
        value = "";
      }
      value = value.toString()
      return { value, unit }
    }
  }

  componentDidMount() {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect()
    })
    document.addEventListener('mousedown', this.handleClickOutside, true);
    this.getrlcValue();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    if (this.inputRef !== null
      && this.inputRef.current !== target
      && !this.dialogRoot.contains(target)
      && target.classList[0] !== 'ant-select-item-option-content'
      && target.classList[0] !== 'ant-select-item-option'
    ) {
      this.saveModel();
    }
  }

  saveModel = () => {
    const { rlcValue, rUnit, lUnit, cUnit } = this.state;
    let value = { ...rlcValue };
    if (value.r || value.l || value.c) {
      if (value.r) {
        if (parseFloat(value.r) === 0) {
          value.r = "0";
        } else {
          value.r = rUnit !== '1' ? (value.r + rUnit) : value.r;
        }
      };
      if (value.l) {
        if (parseFloat(value.l) === 0) {
          value.l = "0";
        } else {
          value.l = lUnit !== "1" ? value.l + lUnit : value.l;
        }
      };
      if (value.c) {
        if (parseFloat(value.c) === 0) {
          value.c = "0";
        } else {
          value.c = cUnit !== "1" ? value.c + cUnit : value.c;
        }
      }
    };
    const { record } = this.props;
    value.r = checkRLCValue(value.r);
    value.l = checkRLCValue(value.l);
    value.c = checkRLCValue(value.c);
    this.props.saveDecapModel(record, value);
    this.props.save();
  }

  saveValue = (e, type) => {
    const value = e.target.value;
    let { rlcValue } = this.state;
    rlcValue[type] = value;
    this.setState({
      rlcValue: { ...rlcValue }
    })
  }

  selectAfter = (type) => {
    const value = this.state[type + 'Unit'];
    const selectList = UNIT[type];

    return (
      <Select
        dropdownStyle={{ zIndex: 10000 }}
        className='unit-select'
        value={value}
        getPopupContainer={() => document.getElementById('comp-model-dialog')}
        onChange={(key) => this.changeUnit(key, type)}>
        {selectList.map(item =>
          <Option key={item.num}>{item.unit}</Option>
        )}
      </Select>
    )
  }

  changeUnit = (key, type) => {
    this.setState({
      [type + 'Unit']: key
    })
  }

  closeModelSelect = (e) => {
    e.stopPropagation();
    this.saveModel();
  }

  valueBlur = (e, type) => {
    let value = e.target.value;
    let { rlcValue } = this.state;
    value = checkRLCValue(value);
    rlcValue[type] = value;
    this.setState({
      rlcValue: { ...rlcValue },
      errorMsg: null
    })
  }

  renderDialog() {
    const { offset } = this.state;
    if (offset === undefined) return null;
    const { rlcValue } = this.state;
    return (
      <ModelDialog offset={this.inputRef} root={this.dialogRoot} type='sierraModel'>
        <div className='sierra-decap-close' onClick={(e) => this.closeModelSelect(e)}><CloseOutlined className='sierra-decap-close-icon'></CloseOutlined></div>
        <div className='select-model-content'>
          <div className='sierra-model sierra-model-last'>
            <Input
              value={rlcValue.r || null}
              addonAfter={this.selectAfter('r')}
              placeholder='Resistance'
              onChange={(e) => this.saveValue(e, 'r')}
              onBlur={(e) => this.valueBlur(e, 'r')}
            />
            <Input
              value={rlcValue.l || null}
              addonAfter={this.selectAfter('l')}
              placeholder='Inductance'
              onChange={(e) => this.saveValue(e, 'l')}
              onBlur={(e) => this.valueBlur(e, 'l')}
              className='sierra-model-input'
            />
            <Input
              value={rlcValue.c || null}
              addonAfter={this.selectAfter('c')}
              placeholder='Capacitance'
              onChange={(e) => this.saveValue(e, 'c')}
              onBlur={(e) => this.valueBlur(e, 'c')}
              className='sierra-model-input'
            />
          </div>
        </div>
      </ModelDialog>
    );
  }

  render() {
    const { inputRef } = this;
    const { value } = this.state;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {value}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default RLCModel;