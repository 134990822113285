import { getCascadePreLayoutInfoById, updateCascadePreLayout } from "../../api/Cascade/preLayout"
import apiProcess from "../../api/utility"

function getCascadePreLayout(id) {
  return apiProcess(getCascadePreLayoutInfoById, id)
}

function saveCascadePreLayout(data) {
  return apiProcess(updateCascadePreLayout, data)
}

export {
  getCascadePreLayout,
  saveCascadePreLayout
}