import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChannelTopology from './ChannelTopology';
import ConnectorSetup from '@/components/Connector_v1/ConnectorSetup';
import SignalMappingTable from './SignalMappingTable';
import {
  updateEndToEndChannelContent,
  saveConnection,
  updateConnectionModelSetup
} from '../store/endToEndChannel/action';
import libraryConstructor from "@/services/Andes_v2/library/libraryConstructor";
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import { CONNECTOR_TOUCHSTONE, CABLE_TOUCHSTONE } from '@/constants/libraryConstants';
import { getTouchstoneParse } from "@/services/Andes_v2/library";
import channelSetupInfo from "@/services/Andes_v2/channel/channelInfo"

class EndToEndChannel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      CONNECTION_ID: "",
      signalMappingUpdate: false,
      connectorSetupUpdate: false,
      channelTopologyUpdate: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { connectionSetupStatus } = this.props;
    if (connectionSetupStatus && connectionSetupStatus !== prevProps.connectionSetupStatus) {
      this.setState({
        signalMappingUpdate: true,
        connectorSetupUpdate: true,
        channelTopologyUpdate: true
      })
      this.props._updateConnectionModelSetup(false);
    }
  }

  _setupUpdateStatus = (boolValue, type) => {
    this.setState({
      [type]: boolValue
    })
  }

  updateConnectionId = (id) => {
    this.setState({
      CONNECTION_ID: id
    })
  }

  render() {
    const { CONNECTION_ID, signalMappingUpdate, connectorSetupUpdate, channelTopologyUpdate } = this.state;
    const { pcbConnections, connections, openProjectId, endToEndChannelId, libraryStatus, channelTopologyLoading, serdesType } = this.props;
    const width = pcbConnections.length * 160;
    const connectorFileList = libraryConstructor.getLibraryValues(CONNECTOR_TOUCHSTONE) || [];
    const cableFileList = libraryConstructor.getLibraryValues(CABLE_TOUCHSTONE) || [];
    return <div className='andes-v2-content-setting pcb-channel-main' style={{ minWidth: width > 1350 ? width : 1350 }}>
      <ChannelTopology
        pcbConnections={pcbConnections}
        connections={connections}
        projectId={openProjectId}
        endToEndChannelId={endToEndChannelId}
        channelTopologyUpdate={channelTopologyUpdate}
        CONNECTION_ID={CONNECTION_ID}
        _setupUpdateStatus={this._setupUpdateStatus}
        channelTopologyLoading={channelTopologyLoading}
      />
      <div className='space-10'>
        <SignalMappingTable
          pcbConnections={pcbConnections}
          connections={connections}
          signalMappingUpdate={signalMappingUpdate}
          projectId={openProjectId}
          endToEndChannelId={endToEndChannelId}
          CONNECTION_ID={CONNECTION_ID}
          _setupUpdateStatus={this._setupUpdateStatus}
        />
      </div>
      <div className='space-10'>
        <ConnectorSetup
          pcbConnections={pcbConnections}
          connections={connections}
          projectId={openProjectId}
          endToEndChannelId={endToEndChannelId}
          connectorSetupUpdate={connectorSetupUpdate}
          CONNECTION_ID={CONNECTION_ID}
          updateConnectionId={this.updateConnectionId}
          libraryStatus={libraryStatus}
          _setupUpdateStatus={this._setupUpdateStatus}
          _updateConnectionModelSetup={this.props._updateConnectionModelSetup}
          _updateEndToEndChannelContent={this.props._updateEndToEndChannelContent}
          _saveConnection={this.props._saveConnection}
          connectorFileList={connectorFileList}
          cableFileList={cableFileList}
          channelConstructor={channelConstructor}
          getTouchstoneParse={getTouchstoneParse}
          channelSetupInfo={channelSetupInfo}
          serdesType={serdesType}
        />
      </div>
      <div id='end-to-end-channel-setup-dialog'></div>
    </div>
  }
}

const mapState = (state) => {
  const {
    endToEndChannel: { endToEndChannelInfo: { content = {}, id, type }, connectionSetupStatus, channelTopologyLoading },
    project: { viewList, layout, openProjectId },
    explorer,
    library: { libraryStatus }
  } = state.AndesV2Reducer;
  const {
    connections = [],
    pcbConnections = []
  } = content;
  const { layers } = explorer;
  return {
    endToEndChannelId: id,
    viewList,
    layout,
    pcbConnections,
    layers,
    connections,
    openProjectId,
    connectionSetupStatus,
    libraryStatus,
    channelTopologyLoading,
    serdesType: type
  }
}

const mapDispatch = (dispatch) => ({
  _updateConnectionModelSetup(status) {
    dispatch(updateConnectionModelSetup(status))
  },
  _updateEndToEndChannelContent(data) {
    dispatch(updateEndToEndChannelContent(data))
  },
  _saveConnection(connection, { CONNECTION_ID }) {
    dispatch(saveConnection(connection, CONNECTION_ID))
  }
})

export default connect(mapState, mapDispatch)(EndToEndChannel);