import canvas from '@/services/LayoutCanvas';

function preview(calc, designID) {
  /*  console.log(designID)
   const layout = canvas.getLayout(designID);
   layout.previewBoundary(calc.data, calc.vertices); */
  let times = 0;
  const timeout = setInterval(() => {
    times++;
    const layout = canvas.getLayout(designID);
    if (layout) {
      layout.previewBoundary(calc.data, calc.vertices);
      clearInterval(timeout)
    }
    if (times === 120) {
      clearInterval(timeout)
    }
  }, 1000)
  return calc;
}

export function cleanPreview(designID) {
  /* const layout = canvas.getLayout(designID);
  layout.cleanPreview(); */
  let times = 0;
  const timeout = setInterval(() => {
    times++;
    const layout = canvas.getLayout(designID);
    if (layout) {
      layout.cleanPreview();
      clearInterval(timeout)
    }
    if (times === 120) {
      clearInterval(timeout)
    }
  }, 1000)
}

export default preview;