import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import TableTag from '@/components/TableTag';
import { EditOutlined, InfoCircleOutlined, RedoOutlined, RetweetOutlined, ExperimentOutlined } from '@ant-design/icons';
import { Tooltip, Spin, Tabs, Popover, message, Descriptions, Upload } from 'antd';
import ModelSelect from '@/components/ModelSelect/ModelSelect';
import { generateCompTabs, generateCompDataByTabs, generateCompBasedDataByTabs } from '@/services/Cascade/Impedance';
import {
  updateImpedanceStatus,
  saveImpedanceRLCValue,
  saveCapModel,
  mergeCompByPart,
  updateCompUsage,
  splitComponentPart,
  removeComponents,
  reAssignDecapModel,
  updateDecapGroup,
  updateDecapModel,
  changeUsage,
  saveRLModel,
  saveCompTableDisplay
} from '../store/Impedance/action';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { getLibraryFileInfo, getSysLibraryFile, getCustomLibBySearch } from '@/services/Cascade/library';
import { selectChange } from '../../LayoutExplorer/store/Cascade/actionCreators';
import { getSplitPartName } from '@/services/helper/setDefaultName';
import SystemLibDetail from '@/services/Cascade/DB/systemLibDetail';
import { CUSTOM_LIBRARY } from '../../../constants/treeConstants';
import { getCustomLibraryPath } from '../../../services/Cascade/helper/match';
import SettingComponentData from '../../../services/Cascade/Impedance/componentSettingData';
import { DIODE, IGNORE, REMOVED, RES, IND, CAP, JUMPER, FERRITE, SWITCH, IPD, LOAD, CHIP, BGA, DIE } from '../../../constants/componentType';
import { IMPEDANCE_PACKAGE, MODEL_MATCH_WEIGHTS } from '../../../services/Cascade/constants';
import designConstructor from '../../../services/helper/designConstructor';
import DecapGroup from './decapGroup';
import { splitArrayToArrays } from '../../../services/helper/arrayHelper';
import RLModel from '../components/rlModelPanel';
import { CASCADE } from '@/constants/pageType';
import { formatRLValue, PassiveModel, RLModelInfo, getRLValue, getModelTypeName, getModelValue } from '../../../services/Cascade/helper/rlModel';
import { parseSpiceModelSelector } from '../../../services/LibraryHelper/SpiceModelHelper';
import { COMPONENTBASED, PARTBASED } from '../../../constants/resolution';
import DecapSettingPanel from '../components/decapSettingPanel';

const DescItem = Descriptions.Item;

const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '20%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'components',
  width: '35%',
}, {
  title: 'Usage',
  dataIndex: 'usage',
  width: '10%',
  sorter: (a, b) => a.usage.localeCompare(b.usage),
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '20%',
}];
const RLCType = [RES, IND, CAP, FERRITE, JUMPER, SWITCH, IPD], UNUSED = 'Unused';
class ComponentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: {},
      tabKey: '',
      tabs: [],
      mergeList: [],
      mergeType: '',
      mergePartName: null,
      splitPartName: null,
      selectedPartNumber: {},
      modelUpdate: false,
      lastTypeInfo: {
        vendor: "",
        libraryType: 'system',
        systeamLibraryType: 'vendor',
        userLibraryType: 'SPICE',
        fileType: 'sparameter',
        name: '',
        modelLibraryType: "",
        modelId: 0,
      },
      recordCount: 0,
      displayPartNumber: false,
      openDecapGroup: false,
      changeUsageLoading: false,
      decapSettingVisible: false
    }

    this.settingTableData = new SettingComponentData();
  }

  componentDidMount = () => {
    this.setDefaultColumns();
    this.getComponentsData();
  }

  componentDidUpdate = (prevProps) => {
    const { openProjectId, verificationId, status, noPCB, designStatus, page, componentsTableDisplay } = this.props;
    if ((openProjectId && openProjectId !== prevProps.openProjectId)
      || (verificationId && verificationId !== prevProps.verificationId)
      || (status && status !== prevProps.status)
      || (designStatus !== prevProps.designStatus && !designStatus)
      || page !== prevProps.page
      || componentsTableDisplay !== prevProps.componentsTableDisplay
    ) {
      this.getComponentsData();
    }

    if (prevProps.noPCB !== noPCB) {
      this.setDefaultColumns();
    }

    if (verificationId !== prevProps.verificationId) {
      this.setState({
        displayPartNumber: false
      })
    }
  }

  componentWillUnmount = () => {
    this.setState = () => false;
  }

  setDefaultColumns = () => {

    ComponentsColumns[0].title = () => {
      const { displayPartNumber, tabKey } = this.state;
      const tableDataList = this.settingTableData.get(tabKey) || [];
      const filterPartNumber = tableDataList.filter(item => !!item.partNumber);
      return (
        <div>
          <span>Part {displayPartNumber && filterPartNumber.length ? "Number" : "Name"}</span>
          {filterPartNumber.length ? <Tooltip
            title='Toggle to display part name or part number.'
            mouseLeaveDelay={0} mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >
            <RetweetOutlined
              className="impedance-part-switch-icon"
              onClick={(e) => this.changePartDisplay(e)} />
          </Tooltip> : null}
        </div>
      );
    }

    ComponentsColumns[0].render = (text, record) => {
      const { mergeList, displayPartNumber } = this.state;
      const { componentsTableDisplay } = this.props
      const partText = displayPartNumber && record.partNumber ? record.partNumber : record.part;
      if (componentsTableDisplay === COMPONENTBASED) {
        return <Tooltip title={record.partNumber ? this.displayPartRender(record) : null} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
          <span className='cascade-part-name'>{partText}</span>
        </Tooltip>
      } else if (RLCType.includes(record.usage) && mergeList && mergeList.length > 1 && mergeList[0] === record.part) {
        return <Popover
          placement="topLeft"
          overlayClassName='merge-part-popover'
          getPopupContainer={() => document.getElementById('cascade-content-main')}
          content={<Fragment>
            <Tooltip title='Merge Part' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className='merge-part-popover-span' onClick={(e) => this.MergePart(e)}>Merge</span>
            </Tooltip>
          </Fragment>}
          open={true}
        >
          <Tooltip title={record.partNumber ? this.displayPartRender(record) : null} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
            <span onClick={(e) => this.tableOnRow(e, record)} className='cascade-part-name'>{partText}</span>
          </Tooltip>
        </Popover>
      } else if (RLCType.includes(record.usage)) {
        return <Tooltip title={record.partNumber ? this.displayPartRender(record) : null} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
          <span className='cursor-pointer cascade-part-name'
            onClick={(e) => this.tableOnRow(e, record)}>{partText}</span>
        </Tooltip>

      } else {
        return <Tooltip title={record.partNumber ? this.displayPartRender(record) : null} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
          <span className='cascade-part-name'>{partText}</span>
        </Tooltip>
      }
    }

    ComponentsColumns[1].render = (text, record) => {
      const { isPreLayout, componentsTableDisplay } = this.props;
      return <div className={[...RLCType, 'Removed'].includes(record.usage) && !isPreLayout && componentsTableDisplay !== COMPONENTBASED ? '' : 'impedance-margin-left-3'}>
        {text.every(item => item.usage === UNUSED) ? 'Unstuffed'
          : text.filter(item => item.usage !== UNUSED).map(item => item.name).join(', ')}
      </div>;
    }

    ComponentsColumns[1].onCell = (record) => {
      const { components } = record;
      const { noPCB, isPreLayout, componentsTableDisplay } = this.props;
      let compsName = [];
      components.forEach(item => {
        if (item.usage !== UNUSED) {
          compsName.push({
            name: item.name,
            checked: true
          });
        } else {
          compsName.push({
            name: item.name,
            checked: false
          });
        }
      });
      return noPCB || isPreLayout || componentsTableDisplay === COMPONENTBASED ? {
        edit: false
      } : ['Removed', ...RLCType].includes(record.usage) ? {
        record,
        compsName,
        edit: true,
        customInput: TableTag,
        dataIndex: "selectComps",
        popupContainer: 'cascade-content-main',
        changeCompsChecked: this.changeCompsChecked,
        select: this.select,
        splitComps: this.splitComps,
        changeDecapRemove: this.changeDecapRemove,
        onClick: (e) => this.stopProps(e),
        isPCBOpened: this.isPCBOpened
      } : {
        edit: false
      }
    }

    ComponentsColumns[2].render = (text, record) => {
      let display = text;
      switch (text) {
        case 'Ferrite':
          display = 'Ferrite Bead';
          break;
        default: break;
      }
      const changeType = this.canUsageChange(record);
      const getClassName = () => {
        let className = changeType ? '' : 'impedance-margin-left-3';
        if (text === IGNORE && !changeType) className += ' cascade-ignore-color';
        return className;
      }
      return <div className={getClassName(record)}>{display}</div>
    }

    ComponentsColumns[2].onCell = (record) => {
      const { noPCB, isPreLayout } = this.props;
      return noPCB || isPreLayout ? {
        edit: false
      } : this.canUsageChange(record) ? {
        edit: 'select',
        options: [IGNORE, LOAD],
        record,
        dataIndex: 'usage',
        handleSave: (record) => this.changeUsage(record),
      } : {
        edit: false
      }
    }

    ComponentsColumns[3].title = () => {
      const { modelAssignLoading } = this.props;
      return (
        <Fragment>
          <span className="cascade-decap-model-title">Model</span>
          <Tooltip
            title='Re-assign decap model.'
            mouseLeaveDelay={0}
            mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >

            {modelAssignLoading ?
              <span className="cascade-decap-model-assigning">Assigning...</span>
              : <RedoOutlined
                className="cascade-decap-re-match-icon"
                onClick={this.props._reAssignDecapModel} />}
          </Tooltip>
        </Fragment>
      );
    }

    ComponentsColumns[3].render = (text, record) => {
      const { usage, value, model, models, applySweep } = record;
      if ([DIODE, LOAD, BGA, DIE].includes(usage)) {
        return null
      }
      if ([CAP, IPD].includes(usage)) {
        if (value && (value.r || value.l || value.c)) {
          return <span>
            R = {(value.r && (value.r + 'Ω')) || '0'}, L = {(value.l && (value.l + 'H')) || '0H'}, C = {(value.c && (value.c + 'F')) || '0F'}
          </span>
        } else {
          if (models && models.length) {
            if (models.length === 1) {
              const model = models[0]
              let matchWeights = model.matchWeights || null;
              let name = ""
              if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
                name = model.subcktName ? model.subcktName : ''
              } else {
                name = model.name ? model.name : ''
              }
              const modelPath = model.type === 'Custom' ? getCustomLibraryPath(model.path) : null;
              return (
                <span>
                  <Tooltip placement="left"
                    overlayClassName='aurora-tooltip system-library-tooltip'
                    title={model.type === CUSTOM_LIBRARY ? (modelPath || name) : this.modelContent(name)}
                    style={{ zIndex: 100000000 }}>
                    <span className="cascade-component-model-span"
                    >{name}</span>
                  </Tooltip>
                  {matchWeights && matchWeights <= MODEL_MATCH_WEIGHTS ? <Tooltip
                    placement="topRight"
                    overlayClassName='aurora-tooltip'
                    title="The matching similarity between the currently automatically selected Model and Part Number is low. It is recommended to reselect manually."
                  >
                    <InfoCircleOutlined className="cascade-component-model-info-icon" />
                  </Tooltip> : null}
                </span>
              );
            } else {
              const title = models.map(model => {
                let name = model.editName
                if (!name) {
                  if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
                    name = model.subcktName ? model.subcktName : ''
                  } else {
                    name = model.name ? model.name : ''
                  }
                }
                return name
              }).join(', ')
              return <Fragment>
                <Tooltip
                  title={title}
                  overlayClassName='aurora-tootip'
                >
                  <div className='cascade-decap-sweep-model-column'>{title} </div>
                </Tooltip>
                {applySweep &&
                  <Tooltip
                    title='Sweeping'
                    overlayClassName='aurora-tooltip'
                    placement='topRight'
                  >
                    <ExperimentOutlined className='cascade-component-model-info-icon' />
                  </Tooltip>}
              </Fragment>
            }
          }
          return ''
        }
      } else if (![IGNORE, REMOVED].includes(usage)) {
        if ([IND, JUMPER, FERRITE, SWITCH, RES].includes(usage)) {
          return <span>{formatRLValue(record.COMP_TYPE, value, record.model)}</span>;
        }
        return value ? value : model ? model.subcktName ? model.subcktName : model.name ? model.name : '' : '';
      }
    }

    ComponentsColumns[3].onCell = (record) => {
      const { usage } = record;
      const { DecapList, DecapGeneric, noPCB, isPreLayout, singleLayout } = this.props;
      const { selectedPartNumber } = this.state
      if (noPCB || isPreLayout) {
        return {
          edit: false
        }
      } else if ([CAP, IPD].includes(usage)) {
        return {
          record,
          edit: true,
          customInput: ModelSelect,
          decapList: DecapList,
          dataIndex: 'modelName',
          getLibraryFile: getLibraryFileInfo,
          getSystemLibraryFile: getSysLibraryFile,
          getSelectedModel: this.getSelectedModel,
          saveDecapModel: this.saveDecapModel,
          onClick: (e) => this.stopProps(e),
          product: CASCADE,
          DecapGeneric: DecapGeneric,
          selectedPartNumber: selectedPartNumber,
          changePartNumber: this.changePartNumber,
          lastTypeInfo: this.state.lastTypeInfo,
          getCustomLibraryPath: getCustomLibraryPath,
          getCustomLibBySearch: getCustomLibBySearch,
          lastTypeInfoChange: this.lastTypeInfoChange,
          supportSweep: singleLayout
        }
      } else if ([RES, IND, JUMPER, FERRITE, SWITCH].includes(usage)) {
        const { passiveSpiceList, passiveTouchstoneList } = this.props;
        return {
          edit: true,
          text: <span>{formatRLValue(record.COMP_TYPE, record.value, record.model)}</span>,
          record: { ...record, model: this.getRLModel(record) },
          customInput: RLModel,
          dataIndex: 'model',
          saveRLModel: this.saveRLModel,
          spiceList: passiveSpiceList,
          touchstoneList: passiveTouchstoneList,
          getFileContent: getLibraryFileInfo,
          parseModelSelector: parseSpiceModelSelector,
          product: CASCADE
        }
      }
    }
  }

  canUsageChange = (record) => {
    const { data, layoutDesignType } = this.props;
    if (record.COMP_TYPE === CHIP || ![LOAD, IGNORE].includes(record.usage) || layoutDesignType === IMPEDANCE_PACKAGE) {
      return false
    }
    const { components, id } = record;
    const domain = data.find(item => item.id === id)
    if (domain) {
      const { content: { VRM = [], ReferenceNets } } = domain;
      const compName = components.map(item => item.name);
      const compPinsNet = components.map(item => item.pins).flat(2).map(item => item.net);
      let VRMComps = [];
      VRM.forEach(item => {
        VRMComps.push(...item.VRM_COMP)
      });
      if (VRMComps.find(comp => compName.includes(comp)) || !ReferenceNets.find(net => compPinsNet.includes(net))) {
        return false
      }
    }
    return true;
  }

  changeUsage = (record) => {
    this.setState({ changeUsageLoading: true })
    const compNames = record.components.map(item => item.name)
    this.props._changeUsage(compNames, record.part, record.id, record.usage)
    setTimeout(() => {
      this.setState({ changeUsageLoading: false })
    }, 500);
  }

  getRLModel = (record) => {
    const { model = {}, value, COMP_TYPE } = record;
    let _value = getRLValue(COMP_TYPE, value);
    const passiveModel = new PassiveModel({ ...model });
    const _model = new RLModelInfo({ modelType: model.type ? model.type.toLowerCase() : model.type, value: _value, passiveModel });
    return { ..._model };
  }

  saveRLModel = (model, record) => {
    const { part, components } = record;
    const _model = { type: getModelTypeName(model.type), ...model.passiveModel };
    const value = getModelValue(record.COMP_TYPE, model.value);
    this.props._saveRLModel(_model, value, part, components);
  }

  displayPartRender = (record) => {
    const { displayPartNumber } = this.state;
    let partText = record.partNumber, partType = "Number";
    if (displayPartNumber) {
      partText = record.part;
      partType = "Name"
    }

    return <div>
      <span className="font-bold">Part {partType}: </span>
      <span>{partText}</span>
    </div>
  }

  changePartDisplay = (e) => {
    e && e.stopPropagation();
    this.setState({
      displayPartNumber: !this.state.displayPartNumber
    })
  }

  isPCBOpened = () => {
    const { selectedDesignIDs, pcbId } = this.props;
    return selectedDesignIDs.includes(pcbId)
  }

  stopProps = (e) => {
    e.stopPropagation();
  }

  lastTypeInfoChange = (vendor, libraryType, systeamLibraryType, userLibraryType, fileType, model) => {
    this.setState({
      lastTypeInfo: {
        vendor,
        libraryType,
        systeamLibraryType,
        userLibraryType,
        fileType,
        name: model.name,
        modelLibraryType: model.libraryType,
        modelId: model.id
      }
    })
  }

  modelContent = (name) => {
    let model = SystemLibDetail.getSystemModel(name);
    if (model) {
      return (
        <Descriptions size="small" column={2} bordered={true}>
          <DescItem label="Form Factor" span={2}>{model.formFactor}</DescItem>
          <DescItem label="Capacitance" span={2}>{model.capacitorValue}</DescItem>
          <DescItem label="Voltage Ratinge" span={2}>{model.voltageRating}</DescItem>
          <DescItem label="DC Bias Voltage" span={2}>{model.dcBiasVoltage}</DescItem>
          <DescItem label="Temperature" span={2}>{model.temperature}</DescItem>
        </Descriptions>
      )
    }
  }

  getInitModelList = async () => {
    let { tabKey, modelUpdate } = this.state;
    let settingTableData = this.settingTableData.get(tabKey);
    if (!settingTableData) {
      return;
    }
    let names = [];
    for (let item of settingTableData || []) {
      if (item.model && item.model.type === 'System' && item.model.name) {
        names.push(item.model.name);
      }
    }
    await SystemLibDetail.saveSystemModel([...new Set(names)]);
    this.setState({
      modelUpdate: !modelUpdate
    })
  }

  getComponentsData = () => {
    const { data, targetIC, pcbId, componentsTableDisplay, currentDesignId, connectors } = this.props;
    const { tabKey } = this.state;
    let { tabs } = generateCompTabs(data, targetIC, pcbId);
    const _tabKey = tabKey && tabs.includes(tabKey) ? tabKey : tabs && tabs.length ? tabs[0] : ''
    const compData = _tabKey ? (componentsTableDisplay === COMPONENTBASED ? generateCompBasedDataByTabs(data, targetIC, pcbId, _tabKey, currentDesignId, connectors) : generateCompDataByTabs(data, targetIC, pcbId, _tabKey, currentDesignId, connectors)) : []
    const rule = comp => {
      return comp.usage === IGNORE && !this.canUsageChange(comp);
    }
    const { trueArray, falseArray } = splitArrayToArrays({ array: compData, rule });
    const _compData = [...falseArray, ...trueArray];
    this.settingTableData = new SettingComponentData();
    _tabKey && this.settingTableData.set(_tabKey, _compData);

    this.setState({
      tabs: tabs ? tabs : [],
      tabKey: _tabKey,
    }, () => {
      this.props.updateImpedanceStatus(false);
      this.getInitModelList()
    })
  }

  openPanel = (e) => {
    e.stopPropagation();
    this.setState({
      visible: true
    })
  }

  changeTabKey = (key) => {
    const { data, targetIC, pcbId, componentsTableDisplay, currentDesignId, connectors } = this.props;
    const _compData = this.settingTableData.get(key);

    if (!_compData || !_compData.length) {
      const compData = key ? (componentsTableDisplay === COMPONENTBASED ? generateCompBasedDataByTabs(data, targetIC, pcbId, key, currentDesignId, connectors) : generateCompDataByTabs(data, targetIC, pcbId, key, currentDesignId, connectors)) : [];
      key && this.settingTableData.set(key, compData);
    }

    this.setState({
      tabKey: key
    }, () => {
      this.getInitModelList()
    })
  }

  getSelectedModel = () => {
    return null;
  }

  saveDecapModel = (record, value, models, apply, applySweep) => {
    const { applyModelList } = this.props;
    let _applyModelList = [...applyModelList]
    if (models && models.length === 1) {
      const findIndex = applyModelList.findIndex(m => m.partName === record.part);
      const applyModel = { model: models[0], partName: record.part };
      if (findIndex > -1) {
        _applyModelList[findIndex] = { ...applyModel }
      } else {
        _applyModelList = [...applyModelList, { ...applyModel }]
      }
    }
    this.props.saveCapModel(record, value, models, apply, _applyModelList, applySweep);
  }

  tableOnRow = (e, record) => {
    e.stopPropagation();
    const { part, usage } = record;
    const { mergeList, mergeType } = this.state;
    if (mergeList && mergeList.length > 0) {
      if (usage !== mergeType) {
        message.info("Please select components of the same type to merge")
        return;
      }
    }
    let partList = [...mergeList];
    if (!mergeList.includes(part)) {
      partList.push(part);
    } else {
      partList = partList.filter(item => item !== part);
    }
    this.setState({
      mergeList: partList,
      splitPartName: null,
      mergePartName: null,
      mergeType: usage
    })
  }

  MergePart = (e) => {
    e.stopPropagation();
    const { mergeList, tabKey } = this.state;
    let newTableData = this.settingTableData.get(tabKey) || [];
    let mergePartName = mergeList[0], findIndex = -1;
    const components = [];
    newTableData.forEach((data, index) => {
      if (mergeList.includes(data.part)) {
        components.push(...data.components)
      }
      if (mergePartName === data.part) {
        findIndex = index;
      }
    })
    if (findIndex > -1) {
      newTableData[findIndex].components = [...components];
    }
    newTableData = newTableData.filter(item => !mergeList.includes(item.part) || mergePartName === item.part);
    this.props.mergeCompByPart(mergeList, newTableData[findIndex])
    this.settingTableData.set(tabKey, newTableData);
    this.setState({
      mergeList: [],
      mergePartName
    }, () => {
      setTimeout(() => {
        this.setState({
          mergePartName: null
        })
      }, 2000)
    })
  }

  changeCompsChecked = (compsName, checked, record) => {
    this.props.updateCompUsage(record.id, compsName, record.part, checked);
  }

  changeDecapRemove = (compsNames, record) => {
    const removedComp = compsNames.filter(item => item.removed).map(item => item.name);
    this.props.removeComponents(record.id, removedComp, record.part)
  }

  changePartNumber = (item) => {
    this.setState({
      selectedPartNumber: item
    })
  }

  select = (record, names) => {
    const { pcbId } = this.props;
    if (this.isPCBOpened()) {
      this.props._selectChange({ comps: [...names] }, pcbId)
    }
  }

  splitComps = (compList, record) => {
    const { tabKey } = this.state;
    const { id, part } = record;
    let newTableData = this.settingTableData.get(tabKey) || [];
    const newPartName = getSplitPartName({ components: newTableData, partName: record.part });
    this.props.splitComponentPart(id, compList.map(item => item.name), part, newPartName);
    this.setState({
      splitPartName: newPartName
    })
    setTimeout(() => {
      this.setState({
        splitPartName: null
      });
    }, 3000);
  }

  decapGroupOpen = (open) => {
    this.setState({
      openDecapGroup: open
    })
  }

  decapRender = () => {
    const { powerDomainGroups } = this.props;
    return <span className={`cascade-imp-decap-group-span ${powerDomainGroups && powerDomainGroups.length ? '' : 'no-decap-group'}`}>
      <span onClick={() => this.decapGroupOpen(true)}>Decap Group</span>
    </span>
  }

  handleUpload = (option, display) => {
    const { page } = this.props
    this.props.updateDecapModel(option.file, page, display)
  }

  updateDecapSettingPanel = (boolean) => {
    this.setState({
      decapSettingVisible: boolean
    })
  }

  changeComponentsTableDisplay = (display, clearModel) => {
    const { pcbId } = this.props
    this.props.saveComponentsTableDisplay(display, pcbId, clearModel)
  }

  render() {
    const { loading, powerDomainGroups, data, componentsTableDisplay } = this.props;
    const { tabKey, tabs, mergeList, mergePartName, splitPartName, openDecapGroup, changeUsageLoading, decapSettingVisible } = this.state;
    const dataSource = this.settingTableData.get(tabKey) || [].map((item, rowIndex) => ({ ...item, rowIndex }))
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color">Components</span>
        <Tooltip
          title='Click to set components.'
          overlayClassName='icon-tooltip'
        >
          <EditOutlined
            className="cascade-imp-icon"
            onClick={() => this.updateDecapSettingPanel(true)} />
        </Tooltip>
        <div className="space-10">
          <Spin spinning={loading || changeUsageLoading ? true : false}>
            {tabs && tabs.length ? <Tabs defaultActiveKey={tabKey} onChange={this.changeTabKey} className='impedance-comp-tabs' items={tabs.map(item => ({ key: item, label: item }))} /> : null}
            <EditableTable
              rowKey={(record) => record.rowIndex}
              onRow={record => {
                let rowClassName = '';
                if (record.part.includes(splitPartName) && mergeList.length === 0 && !mergePartName) {
                  rowClassName = 'cascade-component-split-row'
                } else if (mergeList.length > 0 && mergeList.includes(record.part) && !mergePartName) {
                  rowClassName = 'cascade-component-select-row'
                } else if (mergePartName && record.part === mergePartName) {
                  rowClassName = 'cascade-component-merge-row'
                }
                return RLCType.includes(record.COMP_TYPE) && {
                  className: rowClassName
                };
              }}
              columns={ComponentsColumns}
              size="small"
              dataSource={dataSource}
              tableLayout="fixed"
              className="cascade-components-table"
            />
          </Spin>
        </div>
        {
          openDecapGroup && <DecapGroup powerDomainGroups={powerDomainGroups} decapGroupOpen={this.decapGroupOpen} updateDecapGroup={this.props.updateDecapGroup} data={data} />
        }
        {decapSettingVisible &&
          <DecapSettingPanel
            componentsTableDisplay={componentsTableDisplay}
            updateDecapSettingPanel={this.updateDecapSettingPanel}
            handleUpload={this.handleUpload}
            hasDecapGroup={true}
            powerDomainGroups={powerDomainGroups}
            decapGroupOpen={this.decapGroupOpen}
            changeComponentsTableDisplay={this.changeComponentsTableDisplay}
          />
        }
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, applyModelList, selectedKeys, designStatus },
    Impedance: { verificationId, loading, targetIC, data, status, designId, modelAssignLoading, page, powerDomainGroups, impedanceComponentsTableDisplay, componentsTableDisplay, designType },
    library: { DecapList, DecapGeneric }
  } } = state
  const layout = data.find(item => item.designId === page);
  let layoutDesignType = designType
  if (layout) {
    layoutDesignType = layout.type;
  }
  const isPreLayout = designConstructor.isPreLayout(page || designId);
  const componentsDisplay = componentsTableDisplay || impedanceComponentsTableDisplay
  const singleLayout = data.length > 1 ? false : true;
  return {
    loading,
    openProjectId,
    verificationId,
    targetIC,
    page,
    data: layout ? layout.powerDomains || [] : [],
    connectors: layout ? layout.connectors || [] : [],
    currentDesignId: layout ? layout.designId : null,
    status,
    DecapList,
    DecapGeneric,
    pcbId: designId,
    applyModelList,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    designStatus,
    modelAssignLoading,
    isPreLayout,
    powerDomainGroups,
    layoutDesignType,
    componentsTableDisplay: [COMPONENTBASED, PARTBASED].includes(componentsDisplay) ? componentsDisplay : PARTBASED,
    singleLayout
  }
}

const mapDispatch = (dispatch) => ({
  updateImpedanceStatus(status) {
    dispatch(updateImpedanceStatus(status))
  },
  saveImpedanceRLCValue(rowData, value) {
    dispatch(saveImpedanceRLCValue(rowData, value))
  },
  saveCapModel(record, value, models, apply, applyModelList, applySweep) {
    dispatch(saveCapModel(record, value, models, apply, applyModelList, applySweep))
  },
  _saveRLModel(model, value, part, components) {
    dispatch(saveRLModel(model, value, part, components))
  },
  mergeCompByPart(mergeList, data) {
    dispatch(mergeCompByPart(mergeList, data))
  },
  updateCompUsage(id, compNames, part, checked) {
    dispatch(updateCompUsage(id, compNames, part, checked))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  splitComponentPart(id, compList, part, newPart) {
    dispatch(splitComponentPart(id, compList, part, newPart))
  },
  removeComponents(id, comps, part) {
    dispatch(removeComponents(id, comps, part))
  },
  _reAssignDecapModel() {
    dispatch(reAssignDecapModel())
  },
  updateDecapGroup(file) {
    dispatch(updateDecapGroup(file))
  },
  updateDecapModel(file, pcbId, display) {
    dispatch(updateDecapModel(file, pcbId, display))
  },
  saveComponentsTableDisplay(display, pcbId, clearModel) {
    dispatch(saveCompTableDisplay(display, pcbId, clearModel))
  },
  _changeUsage(compNames, part, id, usage) {
    dispatch(changeUsage(compNames, part, id, usage))
  }
})

export default connect(mapState, mapDispatch)(ComponentsTable);