import {
  SAVE_VERIFICATION_ID,
  UPDATE_LOADING,
  UPDATE_IMPEDANCE_CONTENT,
  UPDATE_TABLE_STATUS,
  UPDATE_IMP_RESULT_EXIST,
  RESET_SETUP,
  UPDATE_ERROR_MSG,
  POWER_NET_SELECT_PANEL,
  SELECT_PCB,
  COMPONENT_SETTING_UPDATE,
  SAVE_IMPEDANCE_SIGN_OFF_ID,
  UPDATE_OPEN_TARGET_IC_LOADING,
  UPDATE_POWER_NET_SELECT_PANEL,
  UPDATE_WARNING_MSG,
  UPDATE_IMPEDANCE_LOGS,
  SAVE_IMPEDANCE_DISPLAY,
  UPDATE_HAS_INTERPOSER
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { IMPEDANCE_VERSION } from '@/version';
import { CLEAR_CURRENT_PROJECT } from '../project/actionType';
import { IMPEDANCE_PCB } from '../../../../services/Cascade/constants';
import { OVERVIEW } from '../../../../services/Cascade/Impedance';
import { DIE_PACKAGE_PCB, PARTBASED } from '../../../../constants/resolution';

const defaultSetup = {
  COMP_PREFIX_LIB: {
    version: '0.0.1'
  },
  Config: {},
  Optimization: {},
  Options: {},
  targetIC: "",
  targetDie: [],
  designType: IMPEDANCE_PCB,
  page: OVERVIEW,
  data: [],
  impedanceLogs: [],
  powerDomainGroups: undefined,

  powerNetSelect: {
    open: false,
    select: [],
    allPowerNets: [],
    allNets: []
  },
  designId: "",
  updateCompPrefix: false,
  designName: "",
  target: [],
  impedanceOverviewDisplay: DIE_PACKAGE_PCB,
  impedanceComponentsTableDisplay: PARTBASED
}

const defaultState = {
  verificationId: '',
  loading: false,

  // setup
  setupVersion: IMPEDANCE_VERSION,
  status: false,
  updateVersion: false,
  ...defaultSetup,

  //result
  resultExist: false,

  //error check
  errorMsg: [],
  warningMsg: [],
  signOffId: null,
  modelAssignLoading: false
};

export const CascadeImpedanceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_VERIFICATION_ID:
      return {
        ...state,
        verificationId: action.id,
      }
    case UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case UPDATE_IMPEDANCE_CONTENT:
      return {
        ...state,
        ...action.obj
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_TABLE_STATUS:
      return {
        ...state,
        status: action.status
      }
    case UPDATE_IMP_RESULT_EXIST:
      return {
        ...state,
        resultExist: {
          exist: action.resultExist,
          id: action.verificationId
        },
      }
    case RESET_SETUP:
      return {
        ...state,
        ...defaultSetup,
        status: true,
        signOffId: null
      }
    case UPDATE_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.error
      }
    case UPDATE_WARNING_MSG:
      return {
        ...state,
        warningMsg: action.warning
      }
    case POWER_NET_SELECT_PANEL:
    case UPDATE_POWER_NET_SELECT_PANEL:
      return {
        ...state,
        powerNetSelect: {
          ...state.powerNetSelect,
          ...action.obj
        }
      }
    case SELECT_PCB:
      return {
        ...state,
        designId: action.pcbId
      }
    case COMPONENT_SETTING_UPDATE:
      return {
        ...state,
        updateCompPrefix: action.update
      }
    case SAVE_IMPEDANCE_SIGN_OFF_ID:
      return {
        ...state,
        signOffId: action.signOffId
      }
    case UPDATE_OPEN_TARGET_IC_LOADING:
      return {
        ...state,
        openTargetICLoading: action.loading
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...defaultState
      }
    case UPDATE_IMPEDANCE_LOGS:
      return {
        ...state,
        impedanceLogs: action.clear ? [] : [...state.impedanceLogs, ...action.logs]
      }
    case SAVE_IMPEDANCE_DISPLAY:
      return {
        ...state,
        impedanceOverviewDisplay: action.overviewDisplay,
        impedanceComponentsTableDisplay: action.compTableDisplay
      }
    default: return state
  }
} 