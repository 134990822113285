import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spin, Tooltip, Popover, Checkbox } from 'antd';
import EditableTable from '@/components/EditableTable';
import { updateDCRData } from '../store/DCR/action';
import { getPathRLoopTableData } from '../../../services/Cascade/helper/DCRData';
import { numberCheck } from '../../../services/helper/dataProcess';
import { valueUnitSplit } from "../../../services/helper/valueUnitSplit";
import _ from 'lodash';

const ResistanceColumns = [{
  title: 'Index',
  dataIndex: 'index',
  key: 'index',
  width: '6%',
}, {
  title: 'Point 1',
  dataIndex: 'point1',
  key: 'point1',
  width: '20%',
}, {
  title: 'Point 2',
  dataIndex: 'point2',
  key: 'point2',
  width: '20%',
}, {
  title: 'Net',
  dataIndex: 'net',
  width: '18%'
},
{
  title: 'Spec',
  dataIndex: 'spec',
  width: '12%'
},
{
  title: 'Loop Resistance',
  dataIndex: 'loopResistance',
  width: '12%',
},
{
  title: 'Resistance',
  dataIndex: 'resistance',
  width: '12%'
}];

class Resistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsUpdate: false,
      selectedPathList: [],
      selectedIndexList: [],
      visible: false
    };

    ResistanceColumns[0].render = (_text, record) => {
      const { visible, selectedIndexList } = this.state;
      const text = record.groupIndex;
      return {
        children: selectedIndexList &&
          selectedIndexList.length > 0 &&
          selectedIndexList[0] === record.groupIndex ?
          <Popover
            placement="topLeft"
            overlayClassName="merge-path-popover"
            getPopupContainer={() =>
              document.getElementById("cascade-content-main")
            }
            content={
              <Fragment>
                <Tooltip
                  title="Remove Loop"
                  mouseLeaveDelay={0}
                  mouseEnterDelay={0.3}
                  overlayClassName="icon-tooltip"
                >
                  <span
                    className="merge-path-popover-span"
                    onClick={(e) => this.clickRemoveLoop(e)}
                  >
                    Remove Loop
                  </span>
                </Tooltip>
              </Fragment>
            }
            open={visible}
          >
            <div className="path-r-index-content cursor-pointer" onClick={(e) => this.clickTableRow(e, record)}>
              <Checkbox
                checked={selectedIndexList.includes(record.groupIndex)}
                onChange={(e) => this.clickTableRow(e, record)}
                className='path-r-index-checkbox'
              />
              <span>{text}</span>
            </div>
          </Popover>
          : <div className="path-r-index-content cursor-pointer" onClick={(e) => this.clickTableRow(e, record)}>
            <Checkbox
              checked={selectedIndexList.includes(record.groupIndex)}
              onChange={(e) => this.clickTableRow(e, record)}
              className='path-r-index-checkbox'
            />
            <span>{text}</span>
          </div>,
        props: {
          tdClassName: `path-r-index-td ${record.tdClassName}`,
          rowSpan: record.rowSpan
        }
      }
    }

    ResistanceColumns[1].render = (record, _record) => {
      const className = _record.error ? "resistance-table-error-span" : "";
      return <Tooltip title={this.props.loading ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
        <div className='path-r-pins-main'>
          {Array.isArray(_record.point1s) ? _record.point1s.map((item, index) => <span key={index} className={`${className}`}>
            {_record.point1s.length > 1 && item.comp ? "(" : null}
            {item.comp}
            {(item.pins && item.pins.length) ?
              <Fragment> - <span className="point-pin-span">{item.pins.join(', ')}</span></Fragment> : ""
            }
            {_record.point1s.length > 1 && item.comp ? ") " : null}
          </span>) : null}
        </div>
      </Tooltip>
    }

    ResistanceColumns[2].render = (record, _record) => {
      const className = _record.error ? "resistance-table-error-span" : ""
      return <Tooltip title={this.props.loading ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
        <div className='path-r-pins-main'>
          {Array.isArray(_record.point2s) ? _record.point2s.map((item, index) => <span key={index} className={`${className}`}>
            {_record.point2s.length > 1 && item.comp ? "(" : null}
            {item.comp}
            {(item.pins && item.pins.length) ?
              <Fragment> - <span className="point-pin-span">{item.pins.join(', ')}</span></Fragment> : ""
            }
            {_record.point2s.length > 1 && item.comp ? ") " : null}
          </span>) : null}
        </div>
      </Tooltip>
    }

    ResistanceColumns[3].render = (nets) => {
      let newNets = [...new Set(nets)];
      return <span>{newNets.join(', ')}</span>
    }

    ResistanceColumns[4].render = (data, record) => {
      const { value } = valueUnitSplit(record.spec || "");
      const spec = record.spec && value ? `${record.spec}Ω` : "";
      const obj = {
        children: <span className="cascade-sign-off-impedance-value-span">{spec}</span>,
        props: {
          rowSpan: record.rowSpan,
          tdClassName: record.tdClassName || ""
        },
      }
      return obj;
    }

    ResistanceColumns[4].onCell = (record) => {
      const { value, unit } = valueUnitSplit(record.spec || "");
      return {
        record: { ...record, value, unit },
        edit: 'input-with-select',
        dataIndex: 'value',
        allowEmpty: true,
        defaultUnit: "m",
        handleSave: this.resistanceSpecUpdate,
        unitAddonAfter: 'Ω',
        unitList: [{ key: "m", title: 'mΩ' }, { key: "", title: 'Ω' }, { key: "K", title: 'KΩ' }, { key: "M", title: 'MΩ' }]
      }
    }

    ResistanceColumns[5].render = (data, record) => {
      const { value } = valueUnitSplit(record.spec || "");
      const spec = record.spec && value ? `${record.spec}Ω` : "";
      const actualValue = record.actualValue ? `${record.actualValue}Ω` : "";
      const obj = {
        children: <span className={`cascade-target-actual-value-span ${spec ? record.className : ""}`}>{actualValue}</span>,
        props: {
          rowSpan: record.rowSpan,
          tdClassName: record.tdClassName || ""
        },
      }
      return obj;
    }

    ResistanceColumns[6].render = (data, record) => {
      let _resistance = record.resistance;
      return <div className="dcr-resistance">
        <span>{_resistance}</span>
      </div>
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId } = this.props;

    if (verificationId !== prevProps.verificationId) {
      this.setState({
        selectedPathList: [],
        visible: false,
        action: null,
        selectedIndexList: []
      })
    }
  }

  handleClickOutside = (e) => {
    /*     const { target } = e;
        const tagBox = document.getElementsByClassName("cascade-resistance-tables")[0];
        const panelBox = document.getElementsByClassName("dcr-point-select-table-panel")[0];
        if (panelBox && panelBox.contains(e.target)) {
          return;
        }
        if (
          (tagBox &&
            !tagBox.contains(target) &&
            target.classList[0] !== "merge-path-popover-span" &&
            target.classList[0] !== "merge-path-popover") ||
          target.classList[0] === "editable-cell-value-wrap"
        ) {
          this.setState({
            selectedPathList: [],
            action: null
          });
          return;
        } */
  };

  resistanceSpecUpdate = (record) => {
    const { value, unit, pairs: recordPairs } = record;
    if (value) {
      const error = numberCheck(value);
      if (error) {
        return;
      }
    }

    const { resistanceData, pairs } = this.props;
    const _pairs = [...pairs];
    const index = _pairs.findIndex(item => _.isEqual(item.ids, recordPairs));
    if (index > -1) {
      _pairs[index].spec = value ? `${value}${unit}` : "";
    }
    this.props._updateDCRData(resistanceData, _pairs)

  }

  componentDidMount = () => {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  clickTableRow = (e, record) => {
    e && e.stopPropagation();
    const { groupIndex, pairs: recordPairs } = record;
    const { selectedPathList, selectedIndexList, visible } = this.state;

    let pathList = [...selectedPathList], _selectedIndexList = [...selectedIndexList];
    let _visible = visible;
    if (!recordPairs.find(item => selectedPathList.includes(item))) {
      pathList.push(...recordPairs);
      _selectedIndexList.push(groupIndex);
    } else {
      pathList = pathList.filter((item) => !recordPairs.includes(item));
      _selectedIndexList = selectedIndexList.filter((item) => item !== groupIndex);
    }
    const { pairs } = this.props;
    const findSameLoop = pairs.find(it => it.ids && _.isEqual(it.ids, pathList));
    if (pathList.length > 1 && findSameLoop) {
      _visible = true;
    } else {
      _visible = false;
    }
    this.setState({
      selectedPathList: pathList,
      visible: _visible,
      selectedIndexList: _selectedIndexList
    });
  }

  clickRemoveLoop = (e) => {
    e && e.stopPropagation();
    const { selectedPathList } = this.state;
    if (selectedPathList.length === 0) {
      return;
    }
    const { resistanceData, pairs } = this.props;
    let _pairs = [...pairs];
    _pairs = pairs.filter(item => item.ids && !_.isEqual(item.ids, selectedPathList));
    this.setState({
      selectedPathList: [],
      selectedIndexList: [],
      action: null
    });
    this.props._updateDCRData(resistanceData, _pairs)
  }

  render() {
    const { resistanceData, pairs, resistanceTableLoading } = this.props;
    const data = getPathRLoopTableData(resistanceData, pairs);
    const { selectedIndexList } = this.state;
    return (
      <Fragment>
        <div className="cascade-setup-border space-16">
          <span className="font-bold cascade-setup-title-color">Loop Resistance</span>

          <div className="space-10">
            <Spin spinning={resistanceTableLoading}>
              <EditableTable
                rowKey="id"
                columns={ResistanceColumns}
                size="small"
                dataSource={data}
                className="cascade-resistance-tables"
                onRow={(record) => {
                  let rowClassName = record.rowClassName ? record.rowClassName : "";
                  if (
                    selectedIndexList && selectedIndexList.length > 0 &&
                    selectedIndexList.includes(record.groupIndex)
                  ) {
                    rowClassName = rowClassName + " aurora-column-select-row";
                  }
                  return (
                    {
                      className: rowClassName,
                    }
                  );
                }}
              />
            </Spin>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { CascadeReducer: { DCR: { resistanceData, pairs, resistanceTableLoading, verificationId } } } = state
  return {
    resistanceData,
    resistanceTableLoading,
    pairs,
    verificationId
  };
}

const mapDispatch = (dispatch) => ({
  _updateDCRData(resistanceData, pairs) {
    dispatch(updateDCRData({ resistanceData, pairs }))
  }
})

export default connect(mapState, mapDispatch)(Resistance);