import _ from 'lodash';
import { unitChange } from '../../helper/mathHelper';
import { calcFrequencyPoints } from '../Impedance';
import { TARGET_RL_VALUE, TARGET_TABLE } from '../constants';
import { numberCheck } from '../../helper/dataProcess';
import { valueUnitSplit } from '../../helper/valueUnitSplit';
import LayoutData from '../../data/LayoutData';

function getACSensePorts(sensePorts) {
  let impSensePorts = JSON.parse(JSON.stringify(sensePorts || []));
  for (let sense of impSensePorts) {
    if (sense.powerSensePort && sense.powerSensePort.length <= 2) {
      sense.powerSensePort = [];
    }
    if (sense.groundSensePort && sense.groundSensePort.length <= 2) {
      sense.groundSensePort = [];
    }
  }

  impSensePorts = impSensePorts.filter(item => item.powerSensePort && !!item.powerSensePort.length);
  return impSensePorts;
}

function getSensePath(VRMInfo) {
  //Find pmic comp by sense ports
  // first find pmic comp of pwr sense and gnd sense all exist
  let sensePath = VRMInfo.sensePorts ? VRMInfo.sensePorts.find(item => item.powerSensePort && item.powerSensePort.length && item.groundSensePort && item.groundSensePort.length) : null;
  if (!sensePath && VRMInfo.sensePorts) {
    //find only has pwr sense pmic comp
    sensePath = VRMInfo.sensePorts.find(item => item.powerSensePort && item.powerSensePort.length);
  }

  return sensePath;
}

function getDCPMICAndFilterVRM(VRMInfo, sensePath) {
  const senseVRM = sensePath ? [...new Set((sensePath.powerSensePort || []).map(item => {
    return item[item.length - 1] && item[item.length - 1].type === "comp" ? item[item.length - 1].name : null
  }).filter(item => !!item))] : [];

  const sensePMIC = senseVRM;//pmic comp name

  //filter vrm by sense comp, is sense not exist, get first vrm comp
  let filterVRMs = sensePMIC.length ? VRMInfo.VRM.filter(item => item.VRM_COMP && item.VRM_COMP[0] && sensePMIC.includes(item.VRM_COMP[0]))
    : VRMInfo.VRM.filter(item => item.VRM_COMP && item.VRM_COMP[0] && !item.VRM_COMP.includes(item.inductance));
  if (!filterVRMs.length) {
    const pmicComp = VRMInfo.VRM[0] && VRMInfo.VRM[0].VRM_COMP && VRMInfo.VRM[0].VRM_COMP[0] ? VRMInfo.VRM[0].VRM_COMP[0] : null;
    filterVRMs = VRMInfo.VRM.filter(item => item.VRM_COMP && item.VRM_COMP[0] && item.VRM_COMP[0] === pmicComp)
  }
  return filterVRMs;
}


function getDCPmicPortPins(pmicPortPins, VRMInfo, impedanceGroup, index) {
  let _newPmicPortPins = [], noSensePins = [];
  if (pmicPortPins.length) {
    for (let pmic of pmicPortPins) {
      if (!pmic.comp) {
        continue;
      }
      const findPmic = VRMInfo.VRM.find(it => it.VRM_COMP && it.VRM_COMP.includes(pmic.comp));
      if (!findPmic) {
        continue;
      }
      const { component } = findPmic;
      const pmicInfo = {
        comp: pmic.comp,
        powerList: [],
        gndList: [],
        sense: false
      }
      let gndNets = [];
      for (let compItem of (component || [])) {
        const { pwrComp, powerPins, groundNet, groundPins, extendNets: _extendNets } = compItem || {};
        pmicInfo.powerList.push({
          comp: pwrComp || pmic.comp,
          effectivePowerPins: powerPins,
          extendNet: _extendNets[0]
        });
        if (groundNet[0] && !gndNets.includes(groundNet[0])) {
          pmicInfo.gndList.push({
            comp: pmic.comp,
            effectiveGroundPins: groundPins,
            extendNet: groundNet[0]
          })
        }
      }
      if (pmicInfo.powerList.length && pmicInfo.gndList.length) {
        _newPmicPortPins.push(pmicInfo);
      }
    }
  }

  const sensePath = getSensePath(VRMInfo);
  if (_newPmicPortPins.length === 0) {
    //Filter vrms -> find one pmic comp
    const filterVRMs = getDCPMICAndFilterVRM(VRMInfo, sensePath)

    const pmicInfo = {
      comp: null,
      powerList: [],
      gndList: [],
      sense: false
    }
    let gndNets = [];
    for (let vrmItem of filterVRMs) {
      const { component, VRM_COMP } = vrmItem || {};
      pmicInfo.comp = VRM_COMP[0];

      for (let compItem of (component || [])) {
        const { pwrComp, powerPins, groundNet, groundPins, extendNets: _extendNets } = compItem || {};
        const pwrInfo = {
          comp: pwrComp,
          effectivePowerPins: powerPins,
          extendNet: _extendNets[0]
        }
        if (!pmicInfo.powerList.find(it => _.isEqual(it, pwrInfo))) {
          pmicInfo.powerList.push(pwrInfo);
        }

        if (groundNet[0] && !pmicInfo.gndList.find(item => item.comp === VRM_COMP[0])) {
          pmicInfo.gndList.push({
            comp: VRM_COMP[0],
            effectiveGroundPins: groundPins,
            extendNet: groundNet[0]
          })
          gndNets.push(groundNet[0])
        }
      }
    }
    _newPmicPortPins.push(pmicInfo);
  }

  noSensePins = JSON.parse(JSON.stringify(_newPmicPortPins))

  // have pwr sense
  if (sensePath) {
    const pwrSenseList = sensePath.powerSensePort || [];
    // const pwrSenseIndex = pwrSenseList.findIndex(item => item.sense);
    const pwrSenseIndex = pwrSenseList.length - 1; // 2024/08/15 - change sense port to vrm port.
    const pwrSenseNet = (pwrSenseList.find(item => item.type === 'net') || {}).name;
    const senseVRMComp = (pwrSenseList[pwrSenseList.length - 1] || {}).name;

    if (pwrSenseList.length && impedanceGroup.contentList[index].powerNets.includes(pwrSenseNet) && pwrSenseIndex > -1) {
      // power sense
      const sensePins = pwrSenseList[pwrSenseIndex].pins;
      const extendNet = pwrSenseList[pwrSenseIndex - 1].type === 'net' && pwrSenseList[pwrSenseIndex - 1].name;
      const powerPins = sensePins.filter(item => item.net === extendNet).map(item => item.pin)
      if (extendNet && powerPins && powerPins.length > 0) {

        _newPmicPortPins.forEach(item => {
          if (item.comp === senseVRMComp) {
            item.powerList.forEach(it => {
              it.powerSense = pwrSenseList[pwrSenseIndex].name
              it.effectivePowerPins = powerPins;
              it.extendNet = extendNet;
            });
            const newPowerList = [];
            //filter same pwr
            for (let itPwr of item.powerList) {
              const findItem = newPowerList.find(it => _.isEqual(it, itPwr));
              if (!findItem) {
                newPowerList.push(itPwr);
              }
            }
            item.powerList = newPowerList;
            item.sense = true;
          }
        })
      }
    }

    const gndSenseList = sensePath.groundSensePort || [];
    // const gndSenseIndex = gndSenseList.findIndex(item => item.sense);
    const gndSenseIndex = gndSenseList.length - 1; // 2024/08/15 - change sense port to vrm port.
    const gndSenseNet = (gndSenseList.find(item => item.type === 'net') || {}).name;

    if (gndSenseList.length && impedanceGroup.contentList[index].groundNets.includes(gndSenseNet) && gndSenseIndex > -1) {
      // ground sense
      const sensePins = gndSenseList[gndSenseIndex].pins
      const extendNet = gndSenseList[gndSenseIndex - 1].type === 'net' && gndSenseList[gndSenseIndex - 1].name
      const groundPins = sensePins.filter(item => item.net === extendNet).map(item => item.pin);
      const senseVRMComp = gndSenseList[gndSenseList.length - 1].name;
      if (extendNet && groundPins && groundPins.length > 0) {
        _newPmicPortPins.forEach(item => {
          if (item.comp === senseVRMComp) {
            item.gndList.forEach(it => {
              it.groundSense = gndSenseList[gndSenseIndex].name
              it.effectiveGroundPins = groundPins;
              it.extendNet = extendNet;
            });
            const newGndList = [];
            //filter same pwr
            for (let itGnd of item.gndList) {
              const findItem = newGndList.find(it => _.isEqual(it, itGnd));
              if (!findItem) {
                newGndList.push(itGnd);
              }
            }
            item.gndList = newGndList;
            item.sense = true;
          }
        })
      }
    }
  }

  //filter PMIC
  let newPmicPortPins = []
  for (let item of sensePath ? [..._newPmicPortPins, ...noSensePins] : [..._newPmicPortPins]) {
    const findItem = newPmicPortPins.find(it => _.isEqual(it, item));
    if (!findItem) {
      newPmicPortPins.push(item);
    }
  }

  impedanceGroup.contentList[index].pmicPortPins = newPmicPortPins;
  return impedanceGroup
}

/* Set ports to impedance by template power / gnd pins ,and set port target by template impedance spec*/
function setPortsToImpedanceTask({ socPortPins, powerNets }) {
  //set ports
  let ports = [], templateIndex = null, templatePortsList = [];
  for (let iIndex = 0; iIndex < socPortPins.length; iIndex++) {
    const portItem = socPortPins[iIndex];
    if (!portItem.powerPins.length && !portItem.groundPins.length) {
      templateIndex = !templateIndex ? portItem.templateIndex : templateIndex;
      continue;
    }
    //portItem {comp, groundPins, powerPins}
    //set all pins
    if (portItem.powerPins[0] && portItem.powerPins[0].match(/ALL/ig)) {
      portItem.powerPins = [...portItem.effectivePowerPins]/*  socPins.filter(it => powerNets.includes(it.net)).map(it => it.pin) */;
    }
    if (portItem.groundPins[0] && portItem.groundPins[0].match(/ALL/ig)) {
      portItem.groundPins = [...portItem.effectiveGroundPins]/*  socPins.filter(it => groundNets.includes(it.net)).map(it => it.pin) */;
    }
    //port target
    let target = [];
    // rl value
    const rlValueFind = portItem.impedance.find(it => it.res && it.ind && it.res.value && it.ind.value);
    if (rlValueFind) {
      const res = unitChange({ num: rlValueFind.res.value, oldUnit: rlValueFind.res.unit, newUnit: 'Ω' }).number;
      const ind = unitChange({ num: rlValueFind.ind.value, oldUnit: rlValueFind.ind.unit, newUnit: 'H' }).number;
      const frequencyPoints = calcFrequencyPoints(res, ind);
      target.push({
        frequencyPoints: frequencyPoints,
        inductance: { value: rlValueFind.ind.value, unit: rlValueFind.ind.unit || "pH" },
        powerDomainId: "",
        resistance: { value: rlValueFind.res.value, unit: rlValueFind.res.unit || "mΩ" },
        targetName: `Target_${powerNets[0]}_${iIndex + 1}_RL`,
        targetType: TARGET_RL_VALUE
      })
    }
    //unit: 'mΩ', value: '100.00', frequency: '25MHz'
    let frequencyPoints = portItem.impedance ? portItem.impedance.map((item, index) => {
      if (!item.value || numberCheck(item.value)) {
        return null;
      }
      const { value: frequency, unit: frequencyUnit } = valueUnitSplit(item.frequency || "");
      return {
        impedance: unitChange({ num: item.value, oldUnit: item.unit, newUnit: 'Ω' }).number,
        frequency: unitChange({ num: frequency, oldUnit: frequencyUnit || "Hz", newUnit: 'Hz' }).number,
        index
      }
    }).filter(item => !!item) : [];
    if (frequencyPoints.length) {
      target.push({
        targetType: TARGET_TABLE,
        targetName: `Target_${powerNets[0]}_${iIndex + 1}`,
        frequencyPoints
      })
    }

    ports.push({
      port: `${iIndex + 1}`,
      portName: portItem.portName,
      powerPins: [...portItem.powerPins],
      referencePins: [...portItem.groundPins],
      target
    });
    templatePortsList.push({ templateIndex: portItem.templateIndex, portIndex: `${iIndex + 1}` })
  }

  if (!ports || !ports.length) {
    templatePortsList.push({ templateIndex })
  }
  return { ports, templatePortsList }
}

/**check path-r data;
 * @param data:{net, point1s, point2s,index,id,...} 
 * point1s/point2s:[ { comp, net, pins} ]*/
function checkPathRData(data, type, powerNet) {
  let errors = [];
  //net check
  if (!data.net || !data.net.length) {
    errors.push(`${powerNet} - ${type} net does not set.`)
  }
  //point1s check
  errors = pathRPointsCheck(data.point1s, errors, 'Point1', type, powerNet);
  //point2s check
  errors = pathRPointsCheck(data.point2s, errors, 'Point2', type, powerNet);

  return errors;
}

function pathRPointsCheck(points, errors, title, type, powerNet) {
  if (!points || !points.length) {
    if (title === "Point2") {
      errors.push(`[${powerNet}] - PMIC ${type.toLowerCase()} pins is not exist.`);
    } else {
      errors.push(`[${powerNet}]- SOC ${type.toLowerCase()} pins not exist.`);
    }

  } else {
    for (let point of points) {
      const comp = title === "Point2" ? "PMIC" : "SOC";
      if (!point.comp) {
        errors.push(`[${powerNet}] - ${comp} component of does not exist.`)
        continue;
      }
      if (!point.pins || !point.pins.length) {
        errors.push(`[${powerNet}] - ${comp} component ${point.comp} is missing ${type.toLowerCase()} pins.`)
      }
    }
  }
  return errors;
}

function filterPowerNetsByIC(designId, soc, nets) {
  const _DesignData = LayoutData.getLayout(designId);
  if (_DesignData && _DesignData.mNetManager && _DesignData.mNetManager.mNetList && _DesignData.mNetManager.mNetList.mVals && _DesignData.mNetManager.mNetList.mVals.length) {

    const netList = _DesignData.mNetManager.mNetList.mVals;
    // Nets filter
    let _nets = [];
    for (let net of nets) {
      const findNet = netList.find(item => item.mName === net);
      if (!findNet) {
        continue;
      }
      const findComp = findNet.mPinList.find(item => item.mCompName === soc);
      if (findComp) {
        _nets.push(net);
      }
    }
    return _nets;
  }
  return nets;
}

export {
  getACSensePorts,
  getDCPMICAndFilterVRM,
  getSensePath,
  getDCPmicPortPins,
  setPortsToImpedanceTask,
  checkPathRData,
  filterPowerNetsByIC
}
