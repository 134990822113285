import React, { Component, Fragment, createRef } from 'react';
import Panel from '../../../components/Panel';
import { createPortal } from 'react-dom';
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  LineChartOutlined,
  RedoOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import { Progress, Row, Col, Checkbox, message, ColorPicker } from 'antd';
import { getDecapResponse, reDecapResponse, getLibraryFile } from '@/services/PDN/library';
import { parseSPModelSelector } from '@/services/Library';
import { RUNNING, SUCCEED, FAILED } from '@/constants/workflowStatus';
import { getWorkFlow } from '@/services/api/v2/workflowCtrl';
import Parameters from '@/services/PDN/decapResponse/parameter';
import Setting from './setting';
import Line from '@/components/Line';
import getIndex from '@/services/helper/insertionSearch';
import { scaleConversion } from '@/services/helper/numberHelper';
import NP from 'number-precision';
import { strDelimited } from '@/services/helper/split';
import { axisFormat } from '@/services/Result/Public/sparameter/dataHelper';
import '@/publicCss/waveform.css';
import './pdnPanel.css';

const CheckboxGroup = Checkbox.Group;
class DecapFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileContent: null,
      width: 280,
      linePosition: {
        position: "absolute",
        left: 280
      },
      progress: -1,
      subckts: [],
      setting: {
        parameter: 'z', // s / y / z
        value: 'am' // am / ph / re / im
      },
      files: [],
      portSelect: {},
      axis: {
        xShow: true,
        yShow: true,
        xMin: '',
        xMax: '',
        yMin: '',
        yMax: '',
        yUnit: '',
        xScale: 'log',
        yScale: 'log'
      },
      XSettingUnit: 'MHz',
      curveSettingAct: '',
      settingShow: true,
      disabled: false,
      leftWidth: "74%",
      rightWidth: "26%",
      fileListBottom: null,
      showCharacterize: false,
      showNetList: true,
      maximize: false,
    }
    this.dialogRoot = document.getElementById('root');
    this.svgRef = createRef();
    this.contentRef = createRef();
    this.settingRef = createRef();
  }

  _getIndex(xPoints, freq) {
    return getIndex(xPoints, freq, 0, xPoints.length - 1);
  }

  changeMouse = (frequence) => {
    let { files, portSelect, setting } = this.state;
    const { parameter, value } = setting;
    const list = Object.values(portSelect);
    for (const ports of list) {
      for (const port of ports) {
        const [fileId, row, col] = strDelimited(port, "::");
        let fileIndex = files.findIndex(item => item.id === fileId);
        if ((!fileIndex && fileIndex !== 0) || !row || !col) {
          continue;
        }
        const index = this._getIndex(files[fileIndex].freq, frequence);
        // When the mouse moves, the value of y is displayed.
        if (index > -1) {
          const fileData = files[fileIndex].matrix[row][col][parameter + value];
          if (fileData && fileData[index]) {
            if (parseInt(fileData[index]) < 1) {
              fileData[index] = parseFloat(fileData[index].toPrecision(3));
            }

            if (parseInt(fileData[index]) < 100 && parseInt(fileData[index]) >= 1) {
              fileData[index] = parseFloat(fileData[index].toFixed(2));
            }

            if (parseInt(fileData[index]) < 1000 && parseInt(fileData[index]) >= 100) {
              fileData[index] = parseFloat(fileData[index].toPrecision(4));
            }

            if (parseInt(fileData[index]) > 1000) {
              fileData[index] = parseInt(fileData[index]);
            };
            files[fileIndex].matrix[row][col].current = fileData[index];
          } else {
            files[fileIndex].matrix[row][col].current = '';
          }
        } else {
          files[fileIndex].matrix[row][col].current = '';
        }
      };
    }
    this.setState({
      files
    });
  }

  cancelMove = () => {
    let { files, portSelect } = this.state;
    const list = Object.values(portSelect);
    for (const ports of list) {
      for (const port of ports) {
        const [file, row, col] = strDelimited(port, "::");
        let fileIndex = files.findIndex(item => item.id === file);
        files[fileIndex].matrix[row][col].current = '';
      }
    };
    this.setState({
      files
    });
  }

  changeAxis = (axis) => {
    const { XSettingUnit } = this.state;
    this.setState((prevState) => ({
      axis: { ...prevState.axis, ...axisFormat(XSettingUnit, axis) }
    }))
  }

  getDefaultWidth = (status) => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    let _width = status ? width * 0.6 : width * 0.4;
    _width = _width < 600 ? 600 : _width;
    return _width;
  }

  componentDidMount() {
    const { decapFileInfo } = this.props;
    if (decapFileInfo.id && decapFileInfo.fileName) {
      getLibraryFile(decapFileInfo.id).then(res => {
        const { models: subckts } = parseSPModelSelector(res);
        this.setState({
          fileContent: res,
          subckts: subckts && subckts.length ? subckts.map(item => item.name) : []
        });
      })
    } else {
      this.setState({
        fileContent: null
      })
    }
    this.screenChange();
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { maximize } = this.state;
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (!maximize) {

      if (!width) {
        _width = 600
      };
      _width = width * 0.6;
      _width = _width < 600 ? 600 : _width;

      if (!height) {
        _height = 400;
      };
      _height = 478;
    } else {

      if (!width) {
        _width = 1000
      };
      _width = width - 40;
      _width = _width < 800 ? 800 : _width;

      if (!height) {
        _height = 1000
      };
      _height = height - 40;
    }

    this.setState({
      panelWidth: _width,
      panelHeight: _height,
      height: _height,
    }, () => {

      let contentWidth = this.contentRef.current.offsetWidth;
      this.setState({ contentWidth }, () => {
        let leftWidth = parseFloat(this.state.leftWidth) / 100 * contentWidth;
        this.changeWidth(leftWidth);
      });
    });

  }

  componentDidUpdate(prevProps) {
    const { decapFileInfo } = this.props;
    if (prevProps.decapFileInfo.id !== decapFileInfo.id) {
      getLibraryFile(decapFileInfo.id).then(res => {
        const { models: subckts } = parseSPModelSelector(res);
        this.setState({
          fileContent: res,
          subckts: subckts && subckts.length ? subckts.map(item => item.name) : [],
          files: [],
          portSelect: {},
          disabled: false,
        });
      })
    }

    const { fileListBottom } = this.state;
    if (this.settingRef.current && fileListBottom !== this.settingRef.current.offsetHeight) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }

  changePanelSize = (obj) => {
    let contentWidth = this.state.contentWidth;
    if (this.contentRef.current) {
      contentWidth = this.contentRef.current.offsetWidth;
    }
    this.setState({
      height: obj.height,
      contentWidth,
    }, () => {
      let leftWidth = this.getLeftWidth();
      this.changeWidth(leftWidth);
      const { fileListBottom } = this.state;
      if (this.settingRef.current && fileListBottom !== this.settingRef.current.offsetHeight) {
        this.setState({
          fileListBottom: this.settingRef.current.offsetHeight
        })
      }
    })
  }

  decapResponse = (disabled) => {
    if (disabled) {
      this.setState((prevState) => ({
        showCharacterize: !prevState.showCharacterize
      }), () => {
        if (this.state.showCharacterize && this.plot2d) {
          this.plot2d.resetPlot();
          const { axis, setting } = this.state;
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      })
      return;
    }
    this.setState({
      disabled: true
    })
    const { id } = this.props.decapFileInfo;
    getDecapResponse(id).then(res => {
      if (!res) {
        const { decapFileInfo } = this.props;
        this.changeParameter(decapFileInfo.id, this.state.subckts);
        return;
      }
      this.setState({
        progress: 0,
        disabled: true
      })
      const workflowId = res.id;
      if (res.status === RUNNING) {
        let state = res.status;
        let time = setInterval(() => {
          let progress = this.state.progress + 4;
          this.setState({
            progress: progress <= 98 ? progress : 98
          });
          if (state === SUCCEED) {
            this.setState({
              progress: 100
            });
            setTimeout(() => {
              this.setState({
                progress: -1,
                disabled: true
              });
            }, 800);
            const { decapFileInfo } = this.props;
            this.changeParameter(decapFileInfo.id, this.state.subckts);
            clearInterval(time);
            return;
          } else if (state === FAILED) {
            this.setState({
              progress: -1,
              disabled: false
            });
            clearInterval(time);
            message.error('Decap characterization failed.')
          }
          getWorkFlow(workflowId).then(response => {
            state = response.data.data.status;
          });
        }, 5000);
      }
    })
  }

  recharacterization = () => {
    const { id } = this.props.decapFileInfo;
    this.setState({
      portSelect: {},
      files: [],
      disabled: false,
      axis: {
        xShow: true,
        yShow: true,
        xMin: '',
        xMax: '',
        yMin: '',
        yMax: '',
        yUnit: '',
        xScale: 'log',
        yScale: 'log'
      },
      XSettingUnit: 'MHz',
    })
    reDecapResponse(id).then(res => {
      if (!res) {
        const { decapFileInfo } = this.props;
        this.changeParameter(decapFileInfo.id, this.state.subckts);
        return;
      }
      this.setState({
        progress: 0,
        disabled: true
      })
      const workflowId = res.id;
      if (res.status === RUNNING) {
        let state = res.status;
        let time = setInterval(() => {
          let progress = this.state.progress + 4;
          this.setState({
            progress: progress <= 98 ? progress : 98
          });
          if (state === SUCCEED) {
            this.setState({
              progress: 100
            });
            setTimeout(() => {
              this.setState({
                progress: -1,
                disabled: true
              });
            }, 800);
            const { decapFileInfo } = this.props;
            this.changeParameter(decapFileInfo.id, this.state.subckts);
            clearInterval(time);
            return;
          };
          getWorkFlow(workflowId).then(response => {
            state = response.data.data.status;
          });
        }, 2000);
      }
    });
  }

  changeParameter = (libraryId, subckts) => {
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis,
    }
    Parameters.cleanParameters();
    this.setState({
      portSelect: {},
    }, () => {
      const { parameter, value } = this.state.setting;
      this.plot2d = Parameters.createPlot(this.svgRef.current, { param: parameter, value }, events);
      Parameters.getParametersInfo(libraryId, subckts).then(params => {
        const parametersArr = Array.from(params.values());
        if (parametersArr.length > 0) {
          let files = Array.from(params.values()).map(item => ({ ...item, show: true }));
          this.getAllNpi(files).then(res => {
            if (res && res.length > 0 && res[0]) {
              this.setState({
                files: [...res.map(item => ({ ...item, show: true }))],
                disabled: true,
                showCharacterize: true
              }, () => {
                this.changePort([files[0].id + '::0::0'], files[0].id);
              })
            } else {
              this.setState({
                files: [],
                disabled: false
              });
            }
          }, error => {
            this.setState({
              files: [],
              disabled: false
            })
          })
        } else {
          this.setState({
            loading: false,
            files: [],
            disabled: false
          });
        }
      })
      if (this.settingRef.current) {
        this.setState({
          fileListBottom: this.settingRef.current.offsetHeight
        })
      }
    })
  }

  getAllNpi = (files) => {
    const promiseList = files.map((item, index) => {
      return item.getNpiFile(index, { libraryId: item.libraryId, path: `${item.name}/${item.name}.npi` });
    })
    return Promise.all(promiseList);
  }

  colorChange = (e, { id, portIndex, matrixIndex }) => {
    const { files } = this.state;
    let fileIndex = files.findIndex(item => item.id === id)
    const value = e.toHexString()
    if (value) {
      let { files } = this.state;
      files[fileIndex].matrix[portIndex][matrixIndex].color = value;
      this.setState({
        files
      }, () => {
        this.plot2d.resetColor({ id, row: portIndex, col: matrixIndex, color: value })
        this.plot2d.redrawCurves();
      })
    }
  }

  changePort = (values, id) => {
    let unSelect, newSelect = null;
    const { setting, files } = this.state;
    let { portSelect } = this.state;
    let pr = portSelect[id] || [];
    unSelect = pr.concat(values).filter(v => !values.includes(v));
    newSelect = values.filter(v => !pr.includes(v));
    let parameter = Parameters.getParameter(id);
    if (unSelect.length > 0) {
      const [row, col] = strDelimited(unSelect[0], "::", { startSubscript: 1 });
      Parameters.removeCurve({ row, col, id });
    } else {
      const [fileId, row, col] = strDelimited(newSelect[0], "::");
      let fileIndex = files.findIndex(item => item.id === fileId);
      Parameters.showCurve({ parameter, fileIndex, row, col, param: setting.parameter, value: setting.value, fileName: id }).then(curves => {
        files[fileIndex].matrix[row][col][curves.type] = curves.y;
        curves.visible = true;
        this.plot2d.updateCurves(curves, true);
        this.plot2d.resetPlot();
        if (!files[fileIndex].matrix || !files[fileIndex].matrix.length) {
          return;
        }
        const color = files[fileIndex].matrix[row][col].color;
        this.plot2d.resetColor({ id, row, col, color: color });
        const { axis, setting } = this.state;
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      });
    };
    portSelect[id] = [...values];

    if (unSelect && unSelect.length > 0 && values.length === 0) {
      delete portSelect[id];
    }
    this.setState({
      portSelect: portSelect
    })
  }

  showAxisSetting = (type) => {
    this.setState((prevState) => {
      prevState.axis[type] = !prevState.axis[type];
      return {
        axis: prevState.axis
      }
    })
  }

  settingChange = (type, val) => {
    let { files, axis, setting } = this.state;
    setting[type] = val;
    const { value, parameter } = setting;
    let { yScale } = axis;
    if (value === 'am' && parameter === 'S') {
      yScale = 'db';
    } else if (value === 'ph') {
      yScale = 'degrees';
    } else if (value === 're' || value === 'im') {
      yScale = 'linear';
    } else {
      yScale = 'log';
    }
    axis.yScale = yScale;
    this.setState({
      setting,
      axis
    }, () => {
      Parameters.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale });
    })
  }

  /**
   * axis -  x | y
   */

  axisChange = (axis, value) => {
    this.setState((prevState) => {
      prevState.axis[axis + 'Scale'] = value;
      return {
        axis: prevState.axis
      }
    }, () => {
      const { setting } = this.state;
      Parameters.changeAxis(axis, { param: setting.parameter, value: setting.value, scale: value })
    })
  }

  showCurveSetting = () => {
    this.setState((prevState) => ({
      curveSettingAct: prevState.curveSettingAct ? '' : 'setting-box-show'
    }))
  }

  changeRange = (type, value) => {
    if (parseFloat(value).toString() === 'NaN') return;
    this.setState((prevState) => {
      prevState.axis[type] = value;
      return {
        axis: prevState.axis
      }
    })
  }

  updateRange = (type) => {
    const { axis, XSettingUnit } = this.state;
    let _axis = { ...axis };
    let min = NP.strip(parseFloat(_axis.xMin)), max = NP.strip(parseFloat(_axis.xMax));
    if (XSettingUnit === 'KHz') {
      _axis.xMax = NP.times(max, 1e3);
      _axis.xMin = NP.times(min, 1e3);
    } else if (XSettingUnit === 'MHz') {
      _axis.xMax = NP.times(max, 1e6);
      _axis.xMin = NP.times(min, 1e6);
    } else if (XSettingUnit === 'GHz') {
      _axis.xMax = NP.times(max, 1e9);
      _axis.xMin = NP.times(min, 1e9);
    }
    if (type === 'xMax' || type === 'xMin') {
      Parameters.updateRange('x', { start: _axis['xMin'], end: _axis['xMax'] })
    } else if (type === 'yMax' || type === 'yMin') {
      Parameters.updateRange('y', { start: axis['yMin'], end: axis['yMax'], yScale: axis['yScale'] })
    }
  }

  axisRangeKeyDown = (e, type) => {
    if (e.keyCode === 13) {
      this.updateRange(type)
      e.target.blur();
    }
  }

  XUnitChange = (unit) => {
    const { axis, XSettingUnit } = this.state;
    let _axis = { ...axis }
    const scale = scaleConversion(unit, XSettingUnit);
    const xMin = _axis.xMin * scale;
    _axis.xMin = parseFloat(xMin.toPrecision(12));
    const xMax = _axis.xMax * scale;
    _axis.xMax = parseFloat(xMax.toPrecision(12));
    this.setState({
      XSettingUnit: unit,
      axis: { ..._axis }
    });
  }

  TargetChange = (checked) => {
    this.setState({
      targetShow: checked
    });
    const { portSelect, files } = this.state;
    if (!checked) {
      files.forEach((file, index) => {
        if (portSelect[file.id] && portSelect[file.id].length > 0 && file.type === 'target') {
          const [row, col] = strDelimited(portSelect[file.id][0], "::", { startSubscript: 1 });
          Parameters.removeCurve({ row, col, id: file.id });
          delete portSelect[file.id];
        }
      })
    } else {
      const targets = files.map(item => item.type === "target");
      targets.forEach((isSim, index) => {
        if (isSim) {
          this.changePort([files[index].id + '::0::0'], files[index].id);
        }
      });
    }
  }

  settingTitleClick = () => {
    const { settingShow } = this.state;
    this.setState({
      settingShow: !settingShow
    }, () => {
      if (this.settingRef.current) {
        this.setState({
          fileListBottom: this.settingRef.current.offsetHeight
        })
      }
    });
  }

  getLeftWidth = () => {
    if (this.contentRef.current) {
      let contentWidth = this.contentRef.current.offsetWidth;
      let leftWidth = parseFloat(this.state.leftWidth) / 100 * contentWidth;
      return leftWidth;
    }
    return this.state.leftWidth;
  }

  changeWidth = (leftWidth) => {
    const { contentWidth } = this.state;
    if (contentWidth) {
      let rightWidth = contentWidth - leftWidth;
      if (rightWidth < 100) {
        leftWidth = (contentWidth - 100) / contentWidth * 100 + "%";
        rightWidth = 100 / contentWidth * 100 + '%';
      } else if (leftWidth < 150) {
        leftWidth = 150 / contentWidth * 100 + "%";
        rightWidth = (contentWidth - 150) / contentWidth * 100 + '%';
      } else {
        leftWidth = leftWidth / contentWidth * 100 + "%";
        rightWidth = rightWidth / contentWidth * 100 + "%";
      }
      this.setState({
        rightWidth,
        leftWidth,
      }, () => {
        if (this.plot2d) {
          this.plot2d.resetPlot();
          const { axis, setting } = this.state;
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      });
    }
  }

  changeSize = (leftWidth) => {
    const contentWidth = this.contentRef.current.offsetWidth;
    this.setState({ contentWidth }, () => {
      this.changeWidth(leftWidth);
    });
  }

  netListShow = () => {
    this.setState((prevState) => ({
      showNetList: !prevState.showNetList
    }), () => {
      if (this.plot2d) {
        this.plot2d.resetPlot();
        const { axis, setting } = this.state;
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    })
  }

  changeSizeMax = (e) => {
    e.stopPropagation();
    const { maximize } = this.state;
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (maximize) {

      if (!width) {
        _width = 600
      };
      _width = width * 0.6;

      if (!height) {
        _height = 400;
      };
      _height = 478;
    } else {

      if (!width) {
        _width = 1000
      };
      _width = width - 40;

      if (!height) {
        _height = 1000
      };
      _height = height - 40;
    }


    this.setState({
      maximize: !maximize,
      panelWidth: _width,
      panelHeight: _height,
      height: _height,
    }, () => {
      let contentWidth = this.contentRef.current.offsetWidth;
      this.setState({ contentWidth }, () => {
        let leftWidth = parseFloat(this.state.leftWidth) / 100 * contentWidth;
        this.changeWidth(leftWidth);
      });
    });
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  getWidth = (nameLenth) => {
    const w = nameLenth * 10 + 70;
    return `calc(100% - ${w}px)`;
  }

  render() {
    const { decapFileInfo, closeModal } = this.props;
    const { fileContent, height, progress, files, portSelect, settingShow, XSettingUnit, axis, setting, disabled,
      rightWidth, fileListBottom, showCharacterize, showNetList, maximize, panelWidth, panelHeight } = this.state;
    const FolderIcon = showNetList ? MenuFoldOutlined : MenuUnfoldOutlined
    const content = (
      <Panel
        className='decap-file-panel'
        id='decap-file-view'
        position={maximize ? 'panel-center-max' : 'panel-center-left'}
        title={<Fragment>
          <span className='decap-file-info-title'>{decapFileInfo.fileName}</span>
          <RedoOutlined
            title='Re-characterization'
            className='decap-response-icon'
            onClick={() => this.recharacterization()} />
          <div style={{ width: this.getWidth(decapFileInfo.fileName.length), display: "inline-block" }}>
            {progress > -1 && <Progress
              size={{ height: 14 }}
              strokeColor={'#1890ff'}
              percent={progress}
              className="decap-res-progress-bar"
            />}
          </div>
          {!maximize ? <FullscreenOutlined
            title='maximize'
            className='decap-graph-maximize'
            onClick={(e) => { this.changeSizeMax(e) }} />
            : <FullscreenExitOutlined
              title='minimize'
              className='decap-graph-maximize'
              onClick={(e) => { this.changeSizeMax(e) }} />}
        </Fragment>}
        zIndex={2000}
        onCancel={() => { closeModal('decapFile') }}
        width={maximize ? panelWidth : (files.length ? this.getDefaultWidth(true) : this.getDefaultWidth())}
        height={panelHeight ? panelHeight : 478}
        draggable
        resize={this.changePanelSize}
        mask={maximize}
        minWidth={430}
        minHeight={300}
        overflow='hidden'
      >
        <FolderIcon
          title={showNetList ? 'Hide Netlist' : 'Show Netlist'}
          onClick={this.netListShow}
          className='netListFold'
        />
        <LineChartOutlined
          title='Show/Hide Plot'
          className='show-hide-plot'
          onClick={() => this.decapResponse(disabled)} />
        <div
          style={{ margin: 16 }}
          className='clear'
        >
          <div
            className='decap-file-display'
            style={!showNetList ? { width: '0%' }
              : (files.length && showCharacterize ? { width: '30%' } : { width: '100%' })}
          >
            <pre
              style={{
                overflow: 'auto',
                height: height ? height - 76 : 400,
                display: showNetList ? 'block' : 'none'
              }}
              className='decap-file-content'>
              {fileContent}
            </pre>
          </div>
          <div className={showNetList ? 'decap-graph-right' : 'decap-graph-right-100'} style={files.length && showCharacterize ? {} : { display: 'none' }} ref={this.contentRef}>
            <div className='decap-file-svg' style={{ right: rightWidth }}>
              <svg ref={this.svgRef}></svg>
            </div>
            <Line
              position={{ position: "absolute", left: this.getLeftWidth.bind(this)() }}
              changeWidth={(leftWidth) => this.changeWidth(leftWidth)}
              width={this.getLeftWidth.bind(this)()}
              resize={this.changeSize}
            />
            <div className='decap-graph-subckts-list' style={{ height: height ? height - 76 : 400, width: rightWidth }}>
              <div className='decap-graph-right-content'>
                <div className='decap-graph-file-list' style={{ bottom: settingShow && fileListBottom ? fileListBottom : '50%' }}>
                  {files.length > 0 ? files.map((file, fileIndex) =>
                    <div key={file.id} style={{ display: 'block', minWidth: 240 }}>
                      <Row key={file.id} className='decap-graph-file-name'>
                        <Col span={24}>
                          <CheckboxGroup
                            value={portSelect[file.name]}
                            key={file.name}
                            onChange={(values) => this.changePort(values, file.name)}
                          >
                            {file.ports ? file.ports.map((port, portIndex) =>
                              <Fragment key={port.name}>
                                {
                                  file.matrix[portIndex].map((matrix, matrixIndex) =>
                                    <Checkbox
                                      key={`${file.id}::${portIndex}::${matrixIndex}`}
                                      value={`${file.id}::${portIndex}::${matrixIndex}`}
                                      className='decap-graph-checkbox-group'
                                    >
                                      <span className='decap-graph-checkbox-group-name'>
                                        <ColorPicker
                                          value={matrix.color}
                                          className='aurora-color-picker-small'
                                          onChange={(value) => this.colorChange(value, { id: file.name, portIndex, matrixIndex })}
                                          size='small'
                                          onClick={(e) => this.stopPropagation(e)}
                                          id={`col:: ${file.id}::${portIndex}::${matrixIndex}`}
                                        />
                                        <span className='decap-subckt-name' style={{ width: matrix.current ? 120 : 150 }} title={file.name}>{file.name}</span>
                                        <span className='decap-graph-curve-current' style={{ display: matrix.current ? 'inline-block' : 'none', color: matrix.color }}>{matrix.current}</span>
                                      </span>
                                    </Checkbox>
                                  )
                                }
                              </Fragment>
                            ) : null
                            }
                          </CheckboxGroup>
                        </Col>
                      </Row>
                    </div>) : null}
                </div>
                {
                  files.length > 0 ?
                    <div ref={this.settingRef} className='decap-graph-setting' style={settingShow ? { top: `50%`, overflow: 'auto' } : { overflow: 'hidden' }}>
                      <Row className='decap-graph-setting-content parameter-right-toolbar'>
                        <Col>
                          <Setting
                            actions={{
                              showAxisSetting: this.showAxisSetting,
                              settingChange: this.settingChange,
                              axisChange: this.axisChange,
                              changeRange: this.changeRange,
                              axisRangeKeyDown: this.axisRangeKeyDown,
                              XUnitChange: this.XUnitChange,
                              updateRange: this.updateRange,
                              TargetChange: this.TargetChange
                            }
                            }
                            axis={axis}
                            setting={setting}
                            XSettingUnit={XSettingUnit}
                            settingShow={settingShow}
                            settingTitleClick={this.settingTitleClick}
                          />
                        </Col>
                      </Row>
                    </div>
                    : null}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default DecapFile;