import React, { Component } from 'react';
import { connect } from 'react-redux';
import Monitor from '@/components/Monitor/UploadMonitor';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class UploadMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Monitor
        {...this.props}
      />
    )
  }
}

const mapState = (state) => {
  const { LoginReducer: { pageType } } = state;
  const { monitorScreenInfo: {
    projectName,
    currentProjectId
  }, tabVisible } = state.MonitorInfoReducer;

  return {
    tabVisible,
    projectName,
    openProjectId: currentProjectId,
    pageType
  }
}

export default connect(mapState, null)(UploadMonitor);