import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ResultTopMenu from '@/components/ResultTopMenu';
import { saveAndesResult } from '../../store/result/action';
import SaveResult from './save';
import { ANDES_V2 } from '../../../../constants/pageType';
import { CPHY, PCIE } from '../../../../services/PCBHelper/constants';
import './index.css'

function getMenu(interfaceType, runSeasim, runAds, runHspice, isMultiPCB) {
  let list = [
    {
      name: 'Network Parameter',
      key: 'channel'
    },
    {
      name: 'TDR',
      key: 'TDR'
    },
    {
      name: 'SBR',
      key: 'SBR'
    },
    {
      name: 'Power Sum',
      key: 'MM_POWERSUM'
    },
    {
      name: 'Delay',
      key: 'delay'
    }];

  if (interfaceType === PCIE && runSeasim) {
    list.push({
      name: 'Compliance',
      key: 'compliance'
    })
  }

  if (runHspice && !isMultiPCB) {
    list.push({
      name: 'Waveform',
      key: 'waveform'
    })
  }

  if (runAds || runHspice) {
    list.push({
      name: 'Simulation',
      key: runAds ? 'ads' : 'hspice'
    })
  }

  if (interfaceType === CPHY) {
    list = list.filter(d => d.key !== 'MM_POWERSUM');
  }

  return list;
}

class TopMenu extends PureComponent {
  render() {
    const { selectedKey, changeResultType, currentResultTime, interfaceType, isMultiPCB = false, runSeasim, runAds, leftWidth, layoutLeftWidth, isSweep, runHspice } = this.props;
    return (
      <ResultTopMenu
        selectedKey={selectedKey}
        changeResultType={changeResultType}
        menu={getMenu(interfaceType, runSeasim, runAds, runHspice, isMultiPCB)}
        backSetup={{
          name: 'Setup',
          key: 'setup'
        }}
        leftWidth={leftWidth}
        layoutLeftWidth={layoutLeftWidth}
        product={ANDES_V2}
        selectedChildren={this.props.getExperimentShowList}
        isSweep={isSweep}
      >
        {currentResultTime && <SaveResult
          currentResultTime={currentResultTime}
          saveResult={(name) => this.props.saveResult(name, isMultiPCB)}
        />}
      </ResultTopMenu>
    )
  }
}

const mapDispatch = (dispatch) => ({
  saveResult(name, isMultiPCB) {
    dispatch(saveAndesResult(name, isMultiPCB))
  }
})

export default connect(null, mapDispatch)(TopMenu);