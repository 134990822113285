import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { range } from 'd3-array';
import { select } from 'd3-selection';

class RdieCdieBarCanvas {
  constructor(props) {
    this.svgEle = props.svgEle;
    this.title = props.title;
    this.data = 0;
    this.color = '';
    this.yTitle = props.yTitle;
    this.key = props.key;
    this.die = props.die
  }

  setColor = (color) => {
    this.color = color;
  }

  setData = (data) => {
    this.data = data;
  }

  initSvg = () => {
    const svg = select(this.svgEle)
      .attr('class', 'plot full-fill');
    svg.selectAll('g').remove();
  }

  draw = () => {
    if (!this.svgEle) return;

    this.initSvg();

    const svg = select(this.svgEle).attr('class', 'plot full-fill');
    const svgWidth = this.svgEle.clientWidth;
    const svgHeight = this.svgEle.clientHeight;
    const plotWidth = svgWidth - 100;
    const plotHeight = svgHeight - 50;
    const key = this.key
    const die = this.die

    const value = this.data || 0;
    const xDomain = [die];

    // 定义比例尺
    const xScale = scaleBand()
      .domain(xDomain)
      .range([0, plotWidth])
      .paddingInner(0.4)
      .paddingOuter(0.2);

    const xGroupScale = scaleBand()
      .domain(range(1))
      .range([0, xScale(die)])

    const yScale = scaleLinear().domain([0, value]).range([plotHeight, 0]);

    const xAxis = axisBottom(xScale)
      .ticks(10)
      .tickValues(xDomain)

    const yAxis = axisLeft(yScale)
      .ticks(10)

    const gRoot = svg.append("g").attr("transform", "translate(80, 10)");

    xScale.domain();

    let maxData = value;
    let minData = value;
    let multiple = 10;
    if (maxData && maxData > 1) {
      let _maxData = JSON.parse(JSON.stringify(maxData));
      let number = 0;
      while (_maxData > 1) {
        _maxData = _maxData / 10;
        number++;
      }
      multiple = Math.pow(10, number - 1);
    }

    let minY = Math.floor(minData * multiple) / multiple;
    if (minY < 0) {
      minY = 0;
    }

    let _maxY = Math.ceil(maxData);
    let absY = Math.abs(_maxY);
    let negative = _maxY < 0 ? true : false;

    if (absY < 15) {
      _maxY = negative ? absY + 1 : _maxY;
    } else if (absY < 32) {
      _maxY = Math.ceil(Math.abs(maxData) / 2) * 2;
    } else if (absY < 50) {
      _maxY = Math.ceil(Math.abs(maxData) / 5) * 5;
    } else if (absY < 100) {
      _maxY = Math.ceil(Math.abs(maxData) / 10) * 10;
    } else {
      _maxY = Math.ceil(Math.abs(maxData) / multiple) * multiple;
    }

    if (negative) {
      _maxY = -(_maxY);
    }

    yScale.domain([0, _maxY]);

    gRoot.append("g")
      .attr("class", "axis effect-bar-chart-xAxis")
      .attr("transform", `translate(0, ${plotHeight})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", '10px')
      .attr('dy', "1.3em")
      .attr('font-size', 12);

    gRoot.append("g")
      .attr("class", "axis effect-bar-chart-yAxis")
      .call(yAxis)
      .attr('font-size', 13);

    const barWidth = xGroupScale.bandwidth();
    const barX = xScale(die) + (xScale.bandwidth() - barWidth) / 2;

    gRoot.append("rect")
      .style("fill", this.color || "#3498db")
      .attr("class", `bar-${key}`)
      .attr("x", barX)
      .attr("width", barWidth)
      .attr("y", yScale(value))
      .attr("height", plotHeight - yScale(value))
      .on('mouseover', (d, i) => {
        svg.selectAll(`.bar-${key}`)
          .style('stroke-width', '4')
          .style('stroke', 'red')
          .style('stroke-linejoin', 'round');
        gRoot.append('text')
          .attr('font-family', 'sans-serif')
          .attr('font-size', 13)
          .attr('text-anchor', 'middle')
          .attr('class', 'divergence')
          .attr('x', function (x, y) {
            if (xDomain.length < 9) {
              return ((plotWidth / (xDomain.length * 2)) * (2 * i + 1))
            }
            return xScale(d.x);
          })
          .attr('y', yScale(value) - 20)
          .attr('dx', '8px')
          .text(`${value.toFixed(2)} (Current)`);
      })
      .on('mouseout', (d, i) => {
        svg.selectAll('.divergence').remove();
        svg.selectAll(`.bar-${key}`)
          .style('stroke-width', '0')
          .style('stroke', 'white');
      });

    const yLabel = `translate(-55, ${plotHeight / 2}) rotate(-90)`;
    gRoot.append('text')
      .text(this.yTitle || 'Y Axis')
      .attr('class', 'effect-bar-chart-yAxis-name')
      .style('text-anchor', 'middle')
      .attr('font-size', 14)
      .attr('fill', '#0000a6')
      .attr('transform', yLabel);
  };
}

export {
  RdieCdieBarCanvas
};
