import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { PCB_PRE_LAYOUT } from "@/constants/treeConstants";
import {
  updateCanvasStatus,
  updateComponents,
  updateSchematicLoading,
  updateCanvasConfig,
  updatePreLayoutData
} from "../../../store/prelayout/action";
import Canvas from "./schematicCanvas";
import './index.css'


class SchematicSetup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 1000,
      height: 800,
      isReset: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.getCanvasWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getCanvasWidth);
  }

  componentDidUpdate = (prevProps) => {
    const { id, loading, viewList } = this.props;
    if (id !== prevProps.id || ((!prevProps.viewList || !prevProps.viewList.includes(PCB_PRE_LAYOUT)) && viewList.includes(PCB_PRE_LAYOUT))) {
      this.getCanvasWidth(true);
    }

    if (loading && loading !== prevProps.loading) {
      this.getCanvasWidth(true)
    }
  }

  getCanvasWidth = (isReset, config) => {
    const { canvasConfig } = this.props;
    const _config = config ? config : canvasConfig;
    const actualHeightNum = _config && _config.height ? Number(_config.height) : 16;
    const actualHeight = (actualHeightNum + 3) * 36;
    const actualWidthNum = _config && _config.width ? Number(_config.width) : 22;
    const actualWidth = (actualWidthNum + 7) * 36;

    this.setState({
      width: actualWidth > 1350 ? actualWidth : 1350,
      height: actualHeight,
      isReset
    })
  }

  render() {
    const { loading, components, canvasUpdate, signals, id, canvasConfig, signalNumber, GPIO } = this.props;
    const { width, height, isReset } = this.state;

    return <Fragment>
      <span className="font-bold sierra-setup-title-color">Setup</span>
      <Spin spinning={loading}>
        <Canvas
          id={id}
          canvasUpdate={canvasUpdate}
          loading={loading}
          width={width}
          height={height}
          isReset={isReset}
          signals={signals}
          components={components}
          signalNumber={signalNumber}
          GPIO={GPIO}
          canvasConfig={canvasConfig}
          updateSchematicLoading={this.props.updateSchematicLoading}
          updateComponents={this.props.updateComponents}
          updatePreLayoutData={this.props.updatePreLayoutData}
          updateCanvasStatus={this.props.updateCanvasStatus}
          updateCanvasConfig={this.props.updateCanvasConfig}
          getCanvasWidth={this.getCanvasWidth}
        />
      </Spin>
    </Fragment>
  }
}

const mapState = (state) => {
  const { SierraReducer: {
    project: { viewList },
    prelayout: { components, schematicLoading, canvasUpdate, signals, id, canvasConfig, signalNumber, GPIO }
  } } = state;
  return {
    components,
    loading: schematicLoading,
    canvasUpdate,
    signals,
    id,
    viewList,
    canvasConfig,
    signalNumber,
    GPIO
  }
}

const mapDispatch = (dispatch) => ({
  updateComponents(components) {
    dispatch(updateComponents(components))
  },
  updatePreLayoutData(info) {
    dispatch(updatePreLayoutData(info))
  },
  updateSchematicLoading(boolean) {
    dispatch(updateSchematicLoading(boolean))
  },
  updateCanvasStatus(boolean) {
    dispatch(updateCanvasStatus(boolean))
  },
  updateCanvasConfig(config) {
    dispatch(updateCanvasConfig(config))
  },
})

export default connect(mapState, mapDispatch)(SchematicSetup);