import { getMaxTextWidth, getTextWidth } from "../helper/getTextWidth";
const PIN_T = "pinT", PIN_B = "pinB", PIN_R = "pinR", PIN_L = "pinL";

/* get pin text width by pin name length */
function getPinSpiceListWidth(pinList, type = "pin") {
  const { leftKey, rightKey, topKey, bottomKey } = getListKey(type);
  let leftPins = [], rightPins = [], topPins = [], bottomPins = [];
  for (let item of pinList) {

    item.pinLData && item.pinLData[leftKey] && leftPins.push(item.pinLData[leftKey]);
    item.pinRData && item.pinRData[rightKey] && rightPins.push(item.pinRData[rightKey]);
    item.pinTData && item.pinTData[topKey] && topPins.push(item.pinTData[topKey]);
    item.pinBData && item.pinBData[bottomKey] && bottomPins.push(item.pinBData[bottomKey]);
  }

  let leftMin = null, rightMin = null, topMin = null, bottomMin;
  if (type === "value" && leftPins.length < pinList.length) {
    leftMin = 66;
  }

  if (type === "value" && rightPins.length < pinList.length) {
    rightMin = 66;
  }

  if (type === "value" && topPins.length < pinList.length) {
    topMin = 66;
  }

  if (type === "value" && bottomPins.length < pinList.length) {
    bottomMin = 66;
  }

  if (!leftPins.length) {
    leftPins = [...rightPins];
  }

  if (!rightPins.length) {
    rightPins = [...leftPins];
  }

  if (!topPins.length) {
    topPins = [...bottomPins];
  }

  if (!bottomPins.length) {
    bottomPins = [...topPins];
  }


  const leftPinList = leftPins.sort((a, b) => { return b.length - a.length });
  const rightPinList = rightPins.sort((a, b) => { return b.length - a.length });
  const topPinList = topPins.sort((a, b) => { return b.length - a.length });
  const bottomPinList = bottomPins.sort((a, b) => { return b.length - a.length });


  let leftPinWidth = leftPinList.length ? getMaxTextWidth(leftPinList, 12, 110) : null,
    rightPinWidth = rightPinList.length ? getMaxTextWidth(rightPinList, 12, 110) : null,
    topPinWidth = topPinList.length ? getMaxTextWidth(topPinList, 12, 110) : null,
    bottomPinWidth = bottomPinList.length ? getMaxTextWidth(bottomPinList, 12, 110) : null;

  if (leftMin && leftPinWidth < leftMin) {
    leftPinWidth = leftMin
  }

  if (rightMin && rightPinWidth < rightMin) {
    rightPinWidth = rightMin
  }

  if (topMin && topPinWidth < topMin) {
    topPinWidth = topMin
  }

  if (bottomMin && bottomPinWidth < bottomMin) {
    bottomPinWidth = bottomMin
  }
  return {
    leftPinWidth,
    rightPinWidth,
    topPinWidth,
    bottomPinWidth
  }
}

function getListKey(type = "pin") {
  let leftKey = "pinL", rightKey = "pinR", topKey = "pinT", bottomKey = "pinB";
  if (type === "value") {
    leftKey = "pinLValue";
    rightKey = "pinRValue";
    topKey = "pinTValue";
    bottomKey = "pinBValue"
  }
  return { leftKey, rightKey, topKey, bottomKey }
}

function getNextPinEdit({ pin, pinType, pins, type, isSetVDDQVSS }) {
  let pinIndex = 0;
  if ([PIN_T, PIN_B].includes(pinType)) {
    pinIndex = 0;
    const TBInfo = getTBInfo(type, pinType, pins);
    if (TBInfo) {
      return TBInfo
    }
  } else {
    pinIndex = pins.findIndex(item => item.pin === pin);
  }

  const RLInfo = getRLInfo(type, pinType, pins, pinIndex)
  if (RLInfo) {
    return RLInfo;
  }

  const findNext = (_index) => {
    const find = pins.find((item, i) => i === _index);
    return find;
  }

  const index = pins.findIndex(item => item.pin === pin);
  let nextIndex = index + 1;
  if (nextIndex >= pins.length) {
    if (isSetVDDQVSS) {
      const TBInfo = getTBInfo(type, pinType, pins);
      if (TBInfo) {
        return TBInfo
      }
    }
    return null;
  }

  while (nextIndex < pins.length) {
    const nextPin = findNext(nextIndex);
    let editNode = getEditNode(type, nextPin);
    if (editNode) {
      return editNode
    }
    nextIndex += 1;
  }

  if (isSetVDDQVSS) {
    const TBInfo = getTBInfo(type, pinType, pins);
    if (TBInfo) {
      return TBInfo
    }
  }

  return null;
}
function getEditNode(type, nextPin) {

  if (!nextPin || !nextPin.model || !nextPin.model.node) {
    return null;
  }

  if ((type === "tx" && !nextPin.model.node.in)
    || (type === "rx" && !nextPin.model.node.out)) {
    return `${nextPin.pin}::pinL`;

  }

  if (type === "tx" && !nextPin.model.node.out) {
    return `${nextPin.pin}::pinR`;
  }
  return null;
}


function getRLInfo(type, pinType, pins, index) {
  let anotherPinNodeTypes = [];
  if (type === 'tx') {
    anotherPinNodeTypes = [PIN_T, PIN_B].includes(pinType) ? ["in", "out"] : pinType === PIN_L ? ["out"] : ["in"]
  } else {
    anotherPinNodeTypes = [PIN_T, PIN_B].includes(pinType) ? ["out"] : []
  }
  for (let i = 0; i < anotherPinNodeTypes.length; i++) {
    const anotherPinNodeType = anotherPinNodeTypes[i];
    const findPin = pins[index] && pins[index].model && pins[index].model.node && !pins[index].model.node[anotherPinNodeType] ? pins[index] : null;
    const findPinType = getPinType(anotherPinNodeType, type);
    if (findPin && findPinType) {
      return `${findPin.pin}::${findPinType}`
    }
  }
}

function getTBInfo(type, pinType, pins) {
  let anotherPinNodeTypes = pinType === PIN_T ? ["vss"] : pinType === PIN_B ? ["vddq"] : ["vddq", "vss"];
  for (let i = 0; i < anotherPinNodeTypes.length; i++) {
    const anotherPinNodeType = anotherPinNodeTypes[i];
    let index = anotherPinNodeType === 'vddq' ? 0 : pins.length - 1;
    const findPin = pins[index] && pins[index].model && pins[index].model.node && !pins[index].model.node[anotherPinNodeType] ? pins[index] : null;
    const findPinType = getPinType(anotherPinNodeType, type);
    if (findPin && findPinType) {
      return `${findPin.pin}::${findPinType}`
    }
  }
}

function getPinType(pinNodeType, type) {
  switch (pinNodeType) {
    case "in":
      return PIN_L;
    case "out":
      return type === "tx" ? PIN_R : PIN_L;
    case "vddq":
      return PIN_T;
    case "vss":
      return PIN_B;
    default: return null;
  }
}

export {
  getPinSpiceListWidth,
  getNextPinEdit
}