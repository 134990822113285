import { OPEN_PCB_CHANNEL_RESULT, CHANGE_RESULT_TYPE, SAVE_ANDES_RESULT, GET_HISTORY_LIST, UPDATE_HISTORY_LIST, OPEN_END_TO_END_CHANNEL_RESULT, OPEN_EXPERIMENT_RESULT } from './actionType';

export const openPCBChannelResult = (id, isOpenSetup) => ({
  type: OPEN_PCB_CHANNEL_RESULT,
  id,
  isOpenSetup
})

export const changeResultType = (resultType, id, setupType) => ({
  type: CHANGE_RESULT_TYPE,
  resultType,
  id,
  setupType
})

export const saveAndesResult = (name, isMultiPCB) => ({
  type: SAVE_ANDES_RESULT,
  name,
  isMultiPCB
})

export const getCurrentHistoryList = () => ({
  type: GET_HISTORY_LIST
})

export const updateHistoryList = (historyList) => ({
  type: UPDATE_HISTORY_LIST,
  historyList
})

export const openEndToEndChannelResult = (id, isOpenSetup) => ({
  type: OPEN_END_TO_END_CHANNEL_RESULT,
  id,
  isOpenSetup
})

export const openExperimentResult = (id, isOpenSetup) => ({
  type: OPEN_EXPERIMENT_RESULT,
  id,
  isOpenSetup
})