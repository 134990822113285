import ImpedanceResultHelper from './helper/ImpedanceResultHelper';
import { getStackupByPCBId } from '../../data/LayoutData';
import { getDesignUnitFromLayoutDB } from '../projectCtrl';
import ResultData from './helper/resultPublic';
import { SortFn } from '../../helper/sort';

function getImpedanceData({ signals, verificationSubId, designSubId, channelId, interfaceName }) {
  return new Promise((resolve, reject) => {
    let signalList = [];
    for (const signal of signals) {
      let signalInfo = new ImpedanceResultHelper.SignalInfo(signal.name);
      signalInfo.netList = signal.nets.map(net => { return { pcb: net.pcb, net: net.net } });
      signalList.push(signalInfo);
    }
    let impedance = new ImpedanceResultHelper(signalList);
    impedance.getImpedanceAsPromise({ verificationSubId, designSubId, channelId, interfaceName }).then(impedance => {
      resolve(impedance);
    }, error => {
      let impedance = new ImpedanceResultHelper.ImpedanceData();
      reject(impedance);
      console.error(error);
    });
  })
}

function Signal(name) {
  this.name = name || null;
  this.nets = []; // [{ net: "", pcb: "" }]
};

function Net(net) {
  this.net = net;
}

// In a DDR byte, there are 2(CAC has only one interface) interfaces, The signals in each interface are the same.
function getSignals(Interfaces) {
  if (Array.isArray(Interfaces) && Interfaces.length > 0 && Interfaces[0].Content && Interfaces[0].Content.Signals) {
    const signals = Interfaces[0].Content.Signals;
    const _signals = signals.map(item => {
      let signal = new Signal(item.name);
      signal.nets = item.nets.map(net => new Net(net));
      return signal;
    });
    return _signals;
  }
  return [];
}

function getImpedance({ verificationId, verificationSubId, designSubId, designID, channelId, interfaceName }) {
  // get interfaces from verification.json 
  return new Promise((resolve, reject) => {
    ResultData.getVerificationJsonPromise({ verificationId, channelId, verificationSubId }).then(response => {
      const _Interfaces = response ? response.Interfaces : null;
      if (!_Interfaces) {
        resolve(null);
        return;
      };
      const signals = getSignals(_Interfaces);  // [{ name: "", nets: [{ net: "", pcb: "" }] }]
      getStackupByPCBId(designID).then(stackup => {
        if (!stackup) {
          resolve(null);
          return;
        }
        // get layout unit
        getImpedanceData({ signals, verificationSubId, designSubId, channelId, interfaceName }).then(res => {
          getDesignUnitFromLayoutDB(designID).then(unit => {
            const designUnit = unit || stackup.unit;
            const result = JSON.parse(JSON.stringify(res));
            const layers = stackup.metalLayerList.map(item => item.name);
            result.single.layerStats.forEach(layerItem => {
              const index = layers.indexOf(layerItem.layer);
              layerItem.thickness = stackup.metalLayerList[index].mThickness;
            });
            const SORT_RULES = stackup.metalLayerList.map(item => item.name);
            result.single.layerStats = SortFn(result.single.layerStats, SORT_RULES, 'layer');
            result.single.netStats = [...result.single.netStats].sort(function (a, b) {
              if (a.signal.match(/^(DQS)/ig) && b.signal.match(/^(DQS)/ig)) {
                return 0;
              } else if (a.signal.match(/^(DQS)/ig) || b.signal.match(/^(DQS)/ig)) {
                return -1;
              } else {
                return 0;
              }
            });

            result.single.netStats.forEach(item => {
              item.layerStats = SortFn(item.layerStats, SORT_RULES, 'layer');
            });

            if (result.diff && result.diff.netStats) {
              result.diff.netStats.forEach(item => {
                item.layerStats = SortFn(item.layerStats, SORT_RULES, 'layer');
              });
            }

            result.unit = unitConvert[StringToUnits(designUnit)];
            result.stackupUnit = unitConvert[StringToUnits(stackup.unit)];
            resolve(result);
          });
        }, error => {
          console.error(error)
          resolve(null)
        });
      });
    });
  });
};

const unitConvert = {
  Length_m: "m",
  Length_cm: "cm",
  Length_mm: "mm",
  Length_um: "um",
  Length_inch: "inch",
  Length_mil: "mil",
  Length_mils: "mil",
  Unknown: "Unknown"
};

const StringToUnits = function (strUnit) {
  if (strUnit === "M") {
    return "Length_um";
  }

  switch (strUnit.toLowerCase()) {
    case "i":
    case "mil":
    case "mils":
      return "Length_mils";
    case "inch":
    case "inches":
      return "Length_inch";
    case "u":
    case "um":
      return "Length_um";
    case "mm":
      return "Length_mm";
    case "cm":
      return "Length_cm";
    case "m":
      return "Length_m";
    default:
      return "Unknown";
  }
};

export default getImpedance;
