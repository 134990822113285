import { getEyeMaskDetail, createUpdateEyeMask, getEyeMaskParameters, getEyeMaskList, deleteEyeMask } from '../api/Rocky/mask';
import apiProcess from '../api/utility';
import { message } from 'antd';

function getEyeMaskDetailPromise(libraryId, origin, type) {
  return apiProcess(getEyeMaskDetail, { libraryId, origin, type })
};

function createUpdateEyeMaskPromise({ libraryId, type, content, name, dataRate, caDataRate, csDataRate }) {
  return new Promise((resolve, reject) => {
    apiProcess(createUpdateEyeMask, { libraryId, type, content: content, name, dataRate, caDataRate, csDataRate }).then(res => {
      resolve(res);
    }, error => {
      message.error((libraryId ? 'Update' : 'Create') + ' eye mask failed! ' + error)
      resolve(null);
    });
  })
};

function getEyeMaskParametersPromise(type) {
  return apiProcess(getEyeMaskParameters, type)
};

function getEyeMaskListPromise(type = null) {
  return apiProcess(getEyeMaskList, type)
};

function deleteEyeMaskPromise(libraryId) {
  return apiProcess(deleteEyeMask, libraryId)
}

export {
  getEyeMaskDetailPromise,
  createUpdateEyeMaskPromise,
  getEyeMaskParametersPromise,
  getEyeMaskListPromise,
  deleteEyeMaskPromise
}