import React, { Component, Fragment } from "react";
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Input } from "antd";
/* import "../../publicCss/netsList.css" */

const { Option } = Select;
class NodeSelection extends Component {


  closeNodesSelection = () => {
    this.props.closeNodesSelection()
  }

  selectNodesFile = (key) => {
    this.props.selectNodesFile(key)
  }

  searchNode = (e) => {
    this.props.searchNode(e)
  }

  selectPinNode = ({
    nodeItem,
    pinItem,
    pinType,
    selectFile
  }) => {
    this.props.selectPinNode({
      nodeItem,
      pinItem,
      pinType,
      selectFile
    })
  }

  render = () => {
    const { pinItem, pinType, searchValue, selectFile, modelGroupKeys, modelGroup, selectFileList, maxWidth, maxHeight, displayPin } = this.props;
    return (
      <div className='nodes-content' style={{
        maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200,
        maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100
      }}>
        <div className='nodes-content-header'>
          <span className='nodes-content-header-span'>{displayPin ? `Component Pin ${displayPin}` : `Pin ${pinItem.pin}`}</span>
        </div>
        <div className='node-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='node-content-close-icon' />
        </div>
        <div className="node-list-body-with-search">
          <div className="node-list-body-search-wrapper">
            <Select
              placeholder={`Select File/Subckt`}
              className='nodes-list-file-select'
              allowClear
              value={selectFile ? `${selectFile.libraryId}::${selectFile.subckt}::${selectFile.fileName}` : ""}
              popupClassName='nodes-list-file-dropdown'
              popupMatchSelectWidth={false}
              onChange={(key) => this.selectNodesFile(key)}
            >
              {selectFileList.map(item => (
                <Option
                  key={item.key}
                  title={item.value}
                  className='spice-nodes-content-file'
                >
                  {item.value}
                </Option>
              ))
              }
            </Select>
            <Input
              placeholder={`Search Node`}
              allowClear
              suffix={!searchValue ? <SearchOutlined className="spice-search-node-icon" /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='node-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {modelGroupKeys.map((groupKey) =>
                <Fragment key={groupKey}>
                  <div className="node-list-group-title">{groupKey === "New" ? "New Group" : "Group"}</div>
                  <ul className='node-list-ul'>
                    {(modelGroup.get(groupKey)).map(item =>
                      <li
                        key={`${item.node}::${item.modelKey}`}
                        className={item.node === pinItem.node && item.modelKey === pinItem.modelKey && item.select
                          ? 'current-pin-selected-node-li'
                          : (item.select ? 'node-li-selected' : '')}
                        onClick={!item.select ? () => this.selectPinNode({
                          nodeItem: item,
                          pinItem,
                          pinType,
                          selectFile
                        }) : null}
                      >
                        {item.selectPin ? <Fragment>{item.node}{" <---> "}{item.selectPin}</Fragment> : item.node}
                      </li>
                    )}
                  </ul>
                </Fragment>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default NodeSelection;