import React, { Component, Fragment } from 'react';
import { Divider, Checkbox, Tooltip, ColorPicker } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { getTextWidth } from '../../services/helper/getTextWidth';
import "./style.css";

const CheckboxGroup = Checkbox.Group;
class EndToEndResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRows: [],
      showSignals: []
    }
  }

  resultTitleClick = () => {
    this.setState({
      resultShow: !this.state.resultShow
    })
  }

  rowNameClick = (rowName) => {
    let showRows = this.state.showRows;
    if (showRows.includes(rowName)) {
      showRows = showRows.filter(item => item !== rowName);
    } else {
      showRows.push(rowName);
    }
    this.setState({
      showRows
    })
  }

  rowSignalNameClick = (name) => {
    let showSignals = this.state.showSignals;
    if (showSignals.includes(name)) {
      showSignals = showSignals.filter(item => item !== name);
    } else {
      showSignals.push(name);
    }
    this.setState({
      showSignals
    })
  }

  changePort = (values, id) => {
    const { portSelect } = this.props;
    let _portSelect = { ...portSelect };
    const prevSelect = [...(portSelect[id] || [])];
    _portSelect[id] = values;
    const deletePorts = prevSelect.filter(item => !values.includes(item));
    const newPorts = values.filter(item => !prevSelect.includes(item));
    this.props.changeEndToEndPorts(_portSelect, newPorts, deletePorts)
  }

  changeSelectAll = (e, id, /* sigItem, */ values) => {
    const { portSelect, /* displayMode */ } = this.props;
    let _portSelect = { ...portSelect };
    const prevSelect = [...(portSelect[id] || [])];
    let newPorts = [], deletePorts = [];
    if (prevSelect.length) {
      _portSelect[id] = [];
      deletePorts = [...prevSelect];
    } else {
      const _values = (values || []).map(item => `${id}::${item.row}::${item.col}`);
      _portSelect[id] = _values;
      newPorts = [..._values];
    }
    this.props.changeEndToEndPorts(_portSelect, newPorts, deletePorts)
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  getResultCheckbox = ({ signalId, child, index, file, isSinglePCB }) => {
    const currentValueWidth = child.value ? getTextWidth(child.value, 14, 100, "bold") + 10 : 10;
    const labelWidth = `calc(100% - ${(20 + currentValueWidth)}px)`;
    const id = `${signalId}::${child.row}::${child.col}`;
    const { displayMode } = this.props;
    const color = file.matrix && file.matrix[child.row] && file.matrix[child.row][child.col] ? file.matrix[child.row][child.col].color : "";
    let portNameClassName = (displayMode === "RL" && index === 0) || isSinglePCB ? "sparameter-result-curve-title-rl-pcb1" : "";
    portNameClassName = displayMode === "RL" && index === 1 && !isSinglePCB ? "sparameter-result-curve-title-rl-pcb2" : portNameClassName;
    return <div key={index}>
      <div className="result-checkbox-group-name">
        <Checkbox
          value={`${signalId}::${child.row}::${child.col}`}
          className='sparameter-result-checkbox-group'
        >
          <span className='sparameter-result-checkbox-group-name'>
            <ColorPicker
              value={color}
              className='aurora-color-picker-small'
              onChange={(value) => this.props.colorChange(value, id)}
              size='small'
              onClick={(e) => this.stopPropagation(e)}
              id={`col::${id}`}
            />
            <label htmlFor={`col::${id}`}
              style={{ width: labelWidth }}
            >
              <Tooltip
                title={isSinglePCB ? `S(${child.driver}, ${child.receiver})` : `S(${child.driver}, ${child.receiver}) (${child.PCB1}, ${child.PCB2})`}
                overlayClassName='aurora-tooltip'
              >
                <span>S(</span>
                <span className={`sparameter-result-curve-title-pcb1 ${portNameClassName}`}>{child.driver}</span>
                <span>, </span>
                <span className={`sparameter-result-curve-title-pcb2 ${portNameClassName}`}>{child.receiver}</span>
                <span>)</span>
              </Tooltip>
            </label>
            <span className='result-curve-current' style={{ color }}>{child.value}</span>
          </span>
        </Checkbox>
      </div>
    </div>
  }

  getResultCheckboxGroup = () => {
    const { endToEndList, portSelect = {}, displayMode, files } = this.props;
    const { showRows, showSignals } = this.state;
    if (!endToEndList || !endToEndList.length) {
      return null;
    }
    return (
      <Fragment>
        {endToEndList.map(group => {
          if (!group.signals || !group.signals.length) {
            return null;
          }
          const isShow = showRows.includes(group.rowName);
          const isSinglePCB = group.isSinglePCB;

          return (
            <Fragment key={group.rowName}>
              {/* title */}
              <div
                onClick={() => this.rowNameClick(group.rowName)}
                className='sparameter-channel-display-row'
              >
                {isShow ? <DownOutlined className='sparameter-file-expand-icon' /> : <RightOutlined className='sparameter-file-expand-icon' />}
                {isSinglePCB
                  ? <span
                    className='sparameter-result-curve-title-pcb1'
                    title={group.PCB1}
                  >{group.PCB1}</span>
                  : <span title={`${group.PCB1} - ${group.PCB2}`}>
                    <span className='sparameter-result-curve-title-pcb1'>{group.PCB1}</span>
                    <span> - </span>
                    <span className='sparameter-result-curve-title-pcb2'>{group.PCB2}</span>
                  </span>}
              </div>
              {isShow ? <div
                className='sparameter-result-ports-list'
              >
                {group.signals.map((item, _index) => {
                  const { signal, id, isShowComp, driverComp, receiverComp } = item;
                  const isShowSignal = showSignals.includes(id);
                  const file = files.find(item => item.id === id);
                  if (!file) {
                    return null;
                  }

                  const allCurves = item[displayMode] || [];
                  return (
                    <div key={id}>
                      <Checkbox
                        key={id}
                        checked={portSelect[id] && portSelect[id].length === allCurves.length}
                        indeterminate={portSelect[id] && portSelect[id].length > 0 && portSelect[id].length < allCurves.length}
                        onChange={(e) => this.changeSelectAll(e, id, item[displayMode])}
                        className='sparameter-channel-port-name-checkbox'
                      />
                      <div
                        onClick={() => this.rowSignalNameClick(id)}
                        className='sparameter-channel-display-row sparameter-channel-port-name'
                      >
                        {isShowSignal ? <DownOutlined className='sparameter-file-expand-icon' /> : <RightOutlined className='sparameter-file-expand-icon' />}
                        <span title={`${signal}${isShowComp ? `(${driverComp}, ${receiverComp})` : null}`}>
                          {signal}
                          {isShowComp ?
                            <Fragment>
                              <span> (</span>
                              <span className='sparameter-result-curve-title-pcb1'>{driverComp}</span>
                              <span>, </span>
                              <span className={`sparameter-result-curve-title-pcb${isSinglePCB ? "1" : "2"}`}>{receiverComp}</span>
                              )
                            </Fragment> : null}</span>
                      </div>
                      {isShowSignal ? <CheckboxGroup
                        value={portSelect[id]}
                        onChange={(values) => this.changePort(values, id)}
                        style={{ width: "100%" }}
                      >
                        {(item[displayMode] || []).map((child, index) =>
                          this.getResultCheckbox({
                            signalId: id,
                            child,
                            index,
                            file,
                            isSinglePCB,
                            isShowComp
                          })
                        )}
                      </CheckboxGroup> : null}
                    </div>
                  );
                })}
              </div>
                : null}
            </Fragment>
          );
        })}
      </Fragment>
    );

  }

  render = () => {
    const { resultShow } = this.state;
    const { className } = this.props;
    return (
      <div className={`sparameter-channel-result-content ${className} end-to-end-sparameter-result`}>
        <div>
          <Divider
            orientation="left"
            className='sparameter-channel-result-margin'
          >
            <div className='result-import-title' onClick={this.resultTitleClick}>
              {resultShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
              <span className='sparameter-result-menu-title'>End-to-End</span>
            </div>
          </Divider>
          <div className='sparameter-result-file-content' style={{ display: resultShow ? 'block' : 'none' }}>
            {this.getResultCheckboxGroup()}
          </div>
        </div>
      </div>
    );
  }
}

export default EndToEndResult;