import {
  UPDATE_MULTI_INTERFACE_SETUP_STATUS,
  CLEAR_MULTI_SETUP_INFO,
  UPDATE_MULTI_SETUP_LOADING,
  UPDATE_MULTI_SETUP_PAGE_DATA,
  CHANGE_MULTI_SETUP_PAGE,
  GET_MULTI_SETUP_BUFFER_DATA,
  UPDATE_MULTI_SETUP_BUFFER_DATA,
  SAVE_IC_COMP_MODEL,
  SAVE_IC_PIN_MODEL,
  SAVE_PIN_VALUE,
  SAVE_POWER_OFF,
  UPDATE_COMPONENT_PIN_USAGE,
  APPLY_MULTI_BUFFER_USAGE_CHANGE,
  CHECK_SIM_INTERFACES,
  START_MULTI_SETUP_SIM,
  SAVE_SIM_CONFIG,
  CHANGE_MULTI_SETUP_GROUP,
  UPDATE_MULTI_SETUP_GROUP,
  CHANGE_MULTI_BUFFER_PAGE,
  GET_MULTI_INTERFACE_SETUP,
  RE_ASSIGN_BUFFER_MODEL,
  CLEAR_BUFFER_DATA,
  UPDATE_MULTI_SETUP_SIM_ERROR_MSG,
  SAVE_COPY_STIMULUS,
  GET_MULTI_SETUP_EXTRACTION_DATA,
  UPDATE_MULTI_SETUP_EXTRACTION,
  UPDATE_MULTI_SETUP_EXTRACTION_PORTS,
  SAVE_MULTI_SETUP_EXTRACTION,
  SAVE_MULTI_SETUP_EXTRACTION_PORTS,
  UPDATE_PCB_LOADING_STATUS,
  UPDATE_MULTI_SETUP_EXTRACTION_STATUS,
  CHANGE_MULTI_SETUP_RESULT,
  GET_MULTI_SETUP_PASSIVE_DATA,
  SAVE_PASSIVE_MODEL,
  CHANGE_MULTI_PASSIVE_PAGE,
  CLEAR_PASSIVE_DATA,
  UPDATE_MULTI_SETUP_PASSIVE_DATA,
  UPDATE_PASSIVE_COMPONENTS,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  UPDATE_MULTI_SETUP_BUFFER_MODEL_LOADING,
  CLEAR_MULTI_SETUP_EXTRACTION,
  CLEAR_MULTI_SETUP_EXTRACTION_PORTS,
  UPDATE_MULTI_SETUP_EXTRACTION_PORTS_LOG,
  UPDATE_MULTI_SETUP_EXTRACTION_OPTIONS_LOG,
  UPDATE_MULTI_SETUP_EXTRACTION_APPLY,
  UPDATE_MULTI_SETUP_EXTRACTION_PCB_SELECT_STATUS
} from "./actionTypes";

export const updateMultiInterfaceSetupStatus = (group, close) => ({
  type: UPDATE_MULTI_INTERFACE_SETUP_STATUS,
  group,
  close
});

export const clearMultiSetupInfo = () => ({
  type: CLEAR_MULTI_SETUP_INFO
});

export const updateMultiSetupLoading = (loading) => ({
  type: UPDATE_MULTI_SETUP_LOADING,
  loading
});

export const updateMultiSetupPageData = (setupData) => ({
  type: UPDATE_MULTI_SETUP_PAGE_DATA,
  setupData
});

export const changeMultiSetupPage = (page) => ({
  type: CHANGE_MULTI_SETUP_PAGE,
  page
});

export const getMultiSetupBufferData = (verificationId) => ({
  type: GET_MULTI_SETUP_BUFFER_DATA,
  verificationId
});

export const updateMultiSetupBufferData = (bufferData) => ({
  type: UPDATE_MULTI_SETUP_BUFFER_DATA,
  bufferData
});

export const updateMultiSetupBufferModelLoading = (modelAssignLoading) => ({
  type: UPDATE_MULTI_SETUP_BUFFER_MODEL_LOADING,
  modelAssignLoading
});

export const saveICCompModel = ({ files, pcb, component }) => ({
  type: SAVE_IC_COMP_MODEL,
  files,
  pcb,
  component
});

export const saveICPinModel = ({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }) => ({
  type: SAVE_IC_PIN_MODEL,
  record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff
});

export const savePinValue = ({ record, pinModels, applyAll, applyAllDriver }) => ({
  type: SAVE_PIN_VALUE,
  record,
  pinModels,
  applyAll,
  applyAllDriver
});

export const savePowerOff = (record, powerOff, applyAll) => ({
  type: SAVE_POWER_OFF,
  record,
  powerOff,
  applyAll
});

export const updateComponentPinUsage = (record) => ({
  type: UPDATE_COMPONENT_PIN_USAGE,
  record
});

export const applyMultiBufferUsageChange = (usageApplyAll) => ({
  type: APPLY_MULTI_BUFFER_USAGE_CHANGE,
  usageApplyAll
});

export const checkSimInterfaces = (checked, key) => ({
  type: CHECK_SIM_INTERFACES,
  checked,
  key
});

export const startMultiSetupSim = () => ({
  type: START_MULTI_SETUP_SIM
});

export const saveSimConfig = (config, verificationIds, prevConfig) => ({
  type: SAVE_SIM_CONFIG,
  config,
  verificationIds,
  prevConfig
});

export const changeMultiSetupGroup = (group) => ({
  type: CHANGE_MULTI_SETUP_GROUP,
  group
});

export const updateMultiSetupGroup = (group) => ({
  type: UPDATE_MULTI_SETUP_GROUP,
  group
});

export const changeMultiBufferPage = (page) => ({
  type: CHANGE_MULTI_BUFFER_PAGE,
  page
});

export const getMultiInterfaceSetup = () => ({
  type: GET_MULTI_INTERFACE_SETUP
});

export const reAssignBufferModel = () => ({
  type: RE_ASSIGN_BUFFER_MODEL
});

export const clearBufferData = () => ({
  type: CLEAR_BUFFER_DATA
});

export const updateMultiSetupSimErrorMsg = (logs, group) => ({
  type: UPDATE_MULTI_SETUP_SIM_ERROR_MSG,
  logs,
  group
});

export const saveCopyStimulus = (copyStimulus) => ({
  type: SAVE_COPY_STIMULUS,
  copyStimulus
});

export const getExtractionData = (info = {}) => ({
  type: GET_MULTI_SETUP_EXTRACTION_DATA,
  ...info
});

export const updateMultiSetupExtraction = (extractionInfo) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION,
  extractionInfo
});

export const clearMultiSetupExtraction = () => ({
  type: CLEAR_MULTI_SETUP_EXTRACTION
});

export const updateMultiSetupExtractionApply = ({ applyType, config }) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION_APPLY,
  applyType,
  config
});

export const updateMultiSetupExtractionPorts = (portsInfo) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION_PORTS,
  portsInfo
});

export const clearMultiSetupExtractionPorts = () => ({
  type: CLEAR_MULTI_SETUP_EXTRACTION_PORTS
})

export const saveMultiSetupExtraction = (info = {}) => ({
  type: SAVE_MULTI_SETUP_EXTRACTION,
  ...info
});

export const saveMultiSetupExtractionPorts = (info = {}) => ({
  type: SAVE_MULTI_SETUP_EXTRACTION_PORTS,
  ...info
});

export const updatePCBLoadingStatus = (status) => ({
  type: UPDATE_PCB_LOADING_STATUS,
  status
});

export const updateMultiSetupExtractionStatus = (status) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION_STATUS,
  status
});

export const changeMultiSetupResult = (multiSetupResult) => ({
  type: CHANGE_MULTI_SETUP_RESULT,
  multiSetupResult
});

export const getMultiSetupPassiveData = (verificationId) => ({
  type: GET_MULTI_SETUP_PASSIVE_DATA,
  verificationId
});

export const clearPassiveData = () => ({
  type: CLEAR_PASSIVE_DATA
});

export const changeMultiPassivePage = (page) => ({
  type: CHANGE_MULTI_PASSIVE_PAGE,
  page
});

export const savePassiveModel = ({ model, pcb, pcbId, value, part }) => ({
  type: SAVE_PASSIVE_MODEL,
  model, pcb, pcbId, value, part
});

export const updateMultiSetupPassiveData = (passiveData) => ({
  type: UPDATE_MULTI_SETUP_PASSIVE_DATA,
  passiveData
});

export const updatePassiveComps = ({ part, compsName, checked, _type: type, pcb, pcbId }) => ({
  type: UPDATE_PASSIVE_COMPONENTS,
  part, compsName, checked, _type: type, pcb, pcbId
});

export const saveSplitComponents = ({ part, splitPart, comps, pcb, pcbId }) => ({
  type: SAVE_SPLIT_COMPONENTS,
  part, splitPart, comps, pcb, pcbId
});

export const saveMergeComponents = ({ part, partList, pcb, pcbId }) => ({
  type: SAVE_MERGE_COMPONENTS,
  part, partList, pcb, pcbId
});

export const updateMultiSetupExtractionPortsLog = (logs, status) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION_PORTS_LOG,
  logs,
  status
});

export const updateMultiSetupExtractionOptionsLog = (logs, status) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION_OPTIONS_LOG,
  logs,
  status
});

export const updateMultiExtrPCBSelectStatus = (status) => ({
  type: UPDATE_MULTI_SETUP_EXTRACTION_PCB_SELECT_STATUS,
  status
})