import CanvasObject from './CanvasObject';

const CanvasPolygon = function () {
  CanvasObject.call(this);
  this.polyData = "";
};

CanvasPolygon.prototype = Object.create(CanvasObject.prototype);
CanvasPolygon.prototype.constructor = CanvasPolygon;

export default CanvasPolygon;