import React, { Component, Fragment, createRef } from "react";
import { Spin, Divider } from "antd";
import { getImpedanceJson, getTransientResult } from "../../../../../services/Cascade/Impedance";
import WaveformResultHelper from '@/services/helper/WaveformResultHelper';
import ResultLayout from "../../../../../services/Result/Public/resultLayout";
import ResultRightMenu from "../../../../../services/Result/Public/resultRightMenu";
import WaveformSetting from '../../../../../services/Result/Public/waveform/waveformSetting';
import WaveformSelection from "./waveformSelection";
import '../index.css';
import getIndex from "../../../../../services/helper/insertionSearch";

class Transient extends Component {
  constructor(props) {
    super(props)
    this.svgRef = createRef();
    this.state = {
      loading: true,
      compInfo: []
    }
    this.changeAxis = this.changeAxis.bind(this);
  }

  onRef = (ref) => {
    this.waveformSetting = ref;
  }

  componentDidMount = () => {
    window.removeEventListener('resize', this.resize);
    this.getTransientData()
  }

  initPorts = (impedanceResultContent) => {
    const { ImpedanceContent } = this.props;
    let portList = []
    const content = impedanceResultContent ? impedanceResultContent.layouts : null;
    for (let tar of (content || ImpedanceContent || [])) {
      for (let domain of (tar.powerDomains || [])) {
        const { id, content } = domain;
        const { PowerNets = [], ports = [], diePorts = [], bgaPorts = [] } = content || {};
        if (ports && ports.length) {
          portList = [...portList, ...ports.map(item => {
            return {
              powerDomainId: id,
              port: item.port,
              portName: item.portName,
              powerPins: [...item.powerPins],
              PowerNets: [...PowerNets]
            }
          })]
        }
        if (diePorts && diePorts.length) {
          portList = [...portList, ...diePorts.map(item => {
            return {
              powerDomainId: id,
              port: item.port,
              portName: item.portName,
              powerPins: [...item.powerPins],
              PowerNets: [...PowerNets]
            }
          })]
        }
        if (bgaPorts && bgaPorts.length) {
          portList = [...portList, ...bgaPorts.map(item => {
            return {
              powerDomainId: id,
              port: item.port,
              portName: item.portName,
              powerPins: [...item.powerPins],
              PowerNets: [...PowerNets]
            }
          })]
        }
      }
    }
    return portList;
  }

  getTransientData = async () => {
    const { id } = this.props
    let waveform = new WaveformResultHelper()
    try {
      const res = await getTransientResult(id)
      const impedanceResultContent = await getImpedanceJson(id);
      if (res.code === 1) {
        this.setState({ loading: false })
        return
      }
      const portList = this.initPorts(impedanceResultContent);
      waveform.parseRawCurve(res);
      const events = {
        changeMouse: this.changeMouse,
        cancelMove: this.cancelMove,
        changeAxis: this.changeAxis,
      };
      waveform.plotWaveform({ svgElement: this.svgRef.current, events, product: 'cascade_transient_result' });
      this.waveform = waveform;
      const curves = this.waveform.getCurves()
      curves.forEach(curve => {
        curve.visible = true
      });
      let powerIndex = 0
      let bgaIndex = 0
      const compInfo = curves.map((curve, index) => {
        const regex = /\(([^)]+)\)/;
        const info = curve.name.match(regex);
        const parts = info.length && info[1] ? info[1].split('_') : [];
        const startIndex = isNaN(parts[0]) ? 0 : 1;
        let [power, comp, ...pin] = parts.slice(startIndex)
        const powerPin = pin && pin.length ? pin.join('_').toUpperCase() : "";
        const findPort = portList.find(it => powerPin && it.powerPins && it.powerPins.includes(powerPin));
        let portName = "";
        if (findPort) {
          portName = findPort.portName || "";
        }
        const portInfo = portName ? portName : powerPin
        if (power.includes('power')) {
          curve.title = `Port ${++powerIndex} - ${comp.toUpperCase()}${portInfo ? ` - ${portInfo}` : ''}`
        } else {
          curve.title = `Port ${++bgaIndex} - ${comp.toUpperCase()}${portInfo ? ` - ${portInfo}` : ''}`
        }
        return { ...curve, curveIndex: index }
      })
      this.plot2D = this.waveform.plot
      setTimeout(() => {
        this.waveform.updataRawCurvePlot()
      }, 100)
      this.setState({
        loading: false,
        compInfo,
        selectedKeys: curves.map(item => item.name)
      })
    } catch (e) {
      this.setState({ loading: false })
      console.error(e)
    }
  }

  resultRightMenuOnRef = (ref) => {
    this.resultRightMenu = ref;
  }

  changeAxis = (axis) => {
    this.waveformSetting.setAxis(axis);
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax }) => {
    let plot = this.plot2D;
    if (type === 'xMin' || type === 'xMax') {
      let xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = parseFloat(_xMin) * 1e-9,
        end = parseFloat(_xMax) * 1e-9;

      if (parseFloat(start).toString() === 'NaN') {
        start = 0;
      };
      if (parseFloat(end).toString() === 'NaN') {
        end = 0;
      };

      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      var xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = (xrange[0] - xMin) / (xMax - xMin) * width,
        endX = (xrange[1] - xMin) / (xMax - xMin) * width;
      plot.updateRange(startX, endX);
    } else {
      // y axis
      let yStart = parseFloat(_yMin),
        yEnd = parseFloat(_yMax);

      if (parseFloat(yStart).toString() === 'NaN') {
        yStart = 0;
      };
      if (parseFloat(yEnd).toString() === 'NaN') {
        yEnd = 0;
      };

      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB();
  }

  reloadAxisCB = () => {
    this.plot2D.updatePlot();
  }

  _getIndex = (xPoints, time) => {
    return getIndex(xPoints, time, 0, xPoints.length - 1);
  }

  colorChange = (e, curveIndex) => {
    const value = e.toHexString();
    if (value) {
      const curves = this.waveform.getCurves()
      const { compInfo } = this.state;
      const findIndex = compInfo.findIndex(item => item.curveIndex === curveIndex)
      if (findIndex > -1) {
        compInfo[findIndex].color = value;
      }
      curves[curveIndex].color = value;
      this.setState({
        compInfo
      }, () => {
        this.reloadAxisCB()
      });
    }
  }

  changeMouse = (time, yPrefix) => {
    const curves = this.waveform.getCurves()
    const { compInfo } = this.state;
    let index = null;
    for (let k = 0; k < curves.length; k++) {
      const compInfoIndex = compInfo.findIndex(item => item.curveIndex === k);
      if (compInfoIndex < 0) { continue }
      if (curves[k].visible) {
        if (index === null) {
          index = this._getIndex(curves[0].x, time);
        }

        if (curves[k].y.length === 0) {
          compInfo[compInfoIndex].current = '';
        } else {
          compInfo[compInfoIndex].current = index > -1 ? yPrefix(curves[k].y[index]) + 'V' : '';
        }
      } else {
        compInfo[compInfoIndex].current = '';
      }
    };

    this.setState({
      compInfo
    });
  }

  cancelMove = () => {
    const curves = this.waveform.getCurves()
    const { compInfo } = this.state;
    for (let k = 0; k < curves.length; k++) {
      const compInfoIndex = compInfo.findIndex(item => item.curveIndex === k);
      if (compInfoIndex < 0) { continue }
      if (curves[k].visible) {
        compInfo[compInfoIndex].current = '';
      }
    };
    this.setState({
      compInfo
    })
  }

  changeSelectedKeys = (keys) => {
    let curves = this.waveform.getCurves()
    curves.forEach((curve, i) => {
      curve.visible = keys.includes(curve.name)
    });
    this.setState({
      selectedKeys: keys
    }, () => {
      this.reloadAxisCB()
    })
  }

  resultListRender = () => {
    const { compInfo, selectedKeys } = this.state;
    return (
      <WaveformSelection
        compInfo={compInfo}
        colorChange={this.colorChange}
        selectedKeys={selectedKeys}
        changeSelectedKeys={this.changeSelectedKeys}
      />
    )
  }

  settingRender = () => {
    return <div className='cascade-waveform-setting-box'>
      <Divider orientation="left" className='waveform-setting-title'>Setting</Divider>
      <WaveformSetting
        onRef={this.onRef}
        plot={this.plot2D}
        axisChangeCB={this.axisChangeCB}
        reloadAxisCB={this.reloadAxisCB}
      />
    </div>
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div className='cascade-result-parameter-left'>
          <svg ref={this.svgRef}></svg>
        </div>
      </Spin>
    )
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        onRef={this.resultRightMenuOnRef}
        resultList={this.resultListRender}
        setting={this.settingRender}
      />
    )
  }

  changeWidthCB = () => {
    if (this.plot2D) {
      this.plot2D.redrawPlot(this.svgRef.current);
    }
  }

  render() {
    return (
      <ResultLayout
        defaultRightWidth={330}
        resultLeft={this.resultLeft}
        resultRight={this.resultRight}
        changeWidthCB={this.changeWidthCB}
      />
    )
  }
}
export default Transient;