import {
  UPDATE_LIBRARY_DATA,
  UPDATE_LIBRARY_MENU,
  DELETE_LIBRARY,
  INIT_SYS_LIBRARY,
  UPDATE_SYS_LIBRARY,
  SAVE_DECAP_GENERIC,
  UPDATE_LIBRARY_STATUS,
  UPDATE_CUSTOM_LIBRARY,
  AFTER_IMPORT_LIBRARY,
  SAVE_LIBRARY_LIST,
  SET_OPEN_PART_PANEL,
  UPDATE_PIN_MAP_LIST
} from './actionType';


export const updateLibraryData = (libraryType, data) => ({
  type: UPDATE_LIBRARY_DATA,
  libraryType,
  data
});

export const updateLibraryMenu = (firstLoad) => ({
  type: UPDATE_LIBRARY_MENU,
  firstLoad
})

export const deleteLibrary = (data) => ({
  type: DELETE_LIBRARY,
  data
})

export const initSystemLibrary = () => ({
  type: INIT_SYS_LIBRARY
})

export const updateSystemLibrary = (folderId, path) => ({
  type: UPDATE_SYS_LIBRARY,
  folderId,
  path
})

export const updateCustomLibrary = (folderId, path) => ({
  type: UPDATE_CUSTOM_LIBRARY,
  folderId,
  path
})

export const saveDecapGeneric = (DecapGeneric) => ({
  type: SAVE_DECAP_GENERIC,
  DecapGeneric
})

export const updateLibraryStatus = () => ({
  type: UPDATE_LIBRARY_STATUS
})

export const afterImportLibrary = () => ({
  type: AFTER_IMPORT_LIBRARY,
})

export const saveLibraryList = (libraries) => ({
  type: SAVE_LIBRARY_LIST,
  libraries
})

export const setPMICPartVisible = (visible) => ({
  type: SET_OPEN_PART_PANEL,
  visible
})

export const updatePinMapList = () => ({
  type: UPDATE_PIN_MAP_LIST
})