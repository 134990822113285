import React, { Component, Fragment } from 'react';
import { Input, Switch, Select } from 'antd';
import TagsInput from '../TagsInput';
import { emailCheck } from '../../services/helper/emailCheck';

class EmailSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      receiveEmailValue: props.receiveEmail,
      error: null
    };
  }

  inputChange = (e) => {
    this.props.updateEmailValue(e.target.value);
  }

  inputBlur = (e) => {
    const value = e.target.value;
    const error = this.emailErrorCheck(value);
    if (!error) {
      this.props.updateEmailNotification({ ...this.props.emailNotification, receiveEmail: value })
    }
  }

  receiveEmailSwitch = (checked) => {
    this.props.updateEmailNotification({
      ...this.props.emailNotification,
      notification: checked ? 1 : 0
    })
  }

  updateCCList = (type, list, className, value) => {
    let _list = [...list];
    if (value !== undefined) {
      const error = this.emailErrorCheck(value);
      if (error) {
        _list = _list.filter(item => item !== value);
      }
    }

    this.props.updateEmailNotification({ ...this.props.emailNotification, ccList: _list })
  }

  updateProductList = (list) => {
    this.props.updateEmailNotification({
      ...this.props.emailNotification,
      products: list
    })
  }

  emailErrorCheck = (value) => {
    clearTimeout(this.timer);
    const error = emailCheck(value);
    this.setState({
      error
    });
    this.timer = setTimeout(() => {
      this.setState({
        error: null
      })
    }, 3000)
    return error;
  }

  render = () => {
    const { emailNotification: { notification, ccList, products }, notificationProducts = [], receiveEmailValue } = this.props;
    const { error } = this.state;
    return <div className="email-setting-content">
      <div className="email-setting-item email-setting-notification">
        <label className="email-setting-label">Email Notification</label>
        <div><Switch
          size="small"
          className="email-setting-switch aurora-switch-small"
          checked={notification === 1 ? true : false}
          onChange={this.receiveEmailSwitch}
        ></Switch>
        </div>
      </div>
      {notification === 1 ? <Fragment><div className="email-setting-item">
        <label className="email-setting-label">To</label>
        <Input
          placeholder="To"
          className='email-setting-input aurora-input'
          value={receiveEmailValue}
          onChange={(e) => this.inputChange(e)}
          onBlur={(e) => this.inputBlur(e)}
        >
        </Input>
      </div>
        <div className="email-setting-item">
          <label className="email-setting-label">CC</label>
          <TagsInput
            type="email"
            tagList={ccList}
            className="email-setting-input"
            changeTagList={this.updateCCList}
          />
        </div>
        <div className="email-setting-item">
          <label className="email-setting-label">Products</label>
          <Select
            className='aurora-select email-setting-select'
            mode={"multiple"}
            showSearch
            value={products}
            onChange={this.updateProductList}
            popupMatchSelectWidth={false}
            popupClassName='aurora-select-dropdown'
          >
            {notificationProducts.map(item =>
              <Select.Option key={item}>{item}</Select.Option>)}
          </Select>
        </div>
        {error ? <span className="aurora-model-name-error-msg">{error}</span> : null}
      </Fragment> : null}
    </div>
  }
}

export default EmailSetting;