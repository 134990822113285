import {
    COMP_INFO, WAVE_FORM_LIST, EXIST_COLOR, HAVE_EXPANDED_SIGNAL, SELECTED_COMPONENT_KEYS,
    SELECTED_PATTERN_KEYS, PATTERN_SWEEP_LIST, DIFF_SIGNAL_NAME, PATTERN_INFO
} from "../constant";

class WaveFormDataHelper {
    constructor() {
        this.compInfo = new Map();
        this.waveFormList = new Map();
        this.existColor = new Map();
        this.haveExpandedSignal = new Map();
        this.selectedComponentKeys = new Map();
        this.selectedPatternKeys = new Map();
        this.patternSweep = new Map();
        this.diffSignalName = new Map();
        this.patternInfo = new Map();
    }

    getWaveFormInfo = (key, type) => {
        switch (type) {
            case COMP_INFO:
                return this.compInfo.get(key);
            case WAVE_FORM_LIST:
                const waveForm = this.waveFormList.get(key);
                return waveForm;
            case EXIST_COLOR:
                return this.existColor.get(key);
            case HAVE_EXPANDED_SIGNAL:
                return this.haveExpandedSignal.get(key);
            case SELECTED_COMPONENT_KEYS:
                return this.selectedComponentKeys.get(key);
            case SELECTED_PATTERN_KEYS:
                return this.selectedPatternKeys.get(key);
            case PATTERN_SWEEP_LIST:
                return this.patternSweep.get(key);
            case DIFF_SIGNAL_NAME:
                return this.diffSignalName.get(key);
            case PATTERN_INFO:
                return this.patternInfo.get(key);
            default:
                return null;
        }
    }

    saveWaveFormInfo = (key, value, type) => {
        let copyValue = null;
        if (type !== WAVE_FORM_LIST) {
            copyValue = JSON.parse(JSON.stringify(value));
        }
        switch (type) {
            case COMP_INFO:
                this.compInfo.set(key, copyValue);
                break;
            case WAVE_FORM_LIST:
                this.waveFormList.set(key, value);
                break;
            case EXIST_COLOR:
                this.existColor.set(key, copyValue);
                break;
            case HAVE_EXPANDED_SIGNAL:
                this.haveExpandedSignal.set(key, copyValue);
                break;
            case SELECTED_COMPONENT_KEYS:
                this.selectedComponentKeys.set(key, copyValue);
                break;
            case SELECTED_PATTERN_KEYS:
                this.selectedPatternKeys.set(key, copyValue);
                break;
            case PATTERN_SWEEP_LIST:
                this.patternSweep.set(key, copyValue);
                break;
            case DIFF_SIGNAL_NAME:
                this.diffSignalName.set(key, copyValue);
                break;
            case PATTERN_INFO:
                this.patternInfo.set(key, copyValue);
            default:
                break;
        }

    }

    cleanAll = () => {
        this.compInfo.clear();
        this.waveFormList.clear();
        this.existColor.clear();
        this.haveExpandedSignal.clear();
        this.selectedComponentKeys.clear();
        this.selectedPatternKeys.clear();
        this.patternSweep.clear();
        this.diffSignalName.clear();
        this.patternInfo.clear();
    }
}

const waveFormDataHelper = new WaveFormDataHelper();
export default waveFormDataHelper;