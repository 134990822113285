import { newNanoId } from "../../helper/idHelper";

class PreLayoutContent {
  constructor(props) {
    const { powerNets, referenceNets, components, decapGroups = [], ball, nets = [] } = props;
    this.powerNets = powerNets;
    this.referenceNets = referenceNets;
    this.components = components;
    this.decapGroups = decapGroups;
    this.ball = ball || new PreLayoutBall({});
    this.nets = nets;
  }
}

class PreLayoutComponent {
  constructor(props) {
    const { name, type, pins, model } = props;
    this.name = name;
    this.type = type;
    this.pins = pins;
    this.model = model || {};
    this.voltage = "";
  }
}

class DCPreComponent {
  constructor(props) {
    const { name, type, pins, value, id, row, column } = props;
    this.id = id
    this.name = name;
    this.type = type;
    this.pins = pins;
    this.value = value || "";
    this.row = row;
    this.column = column;
  }
}

class DecapGroup {
  constructor(props) {
    const { name = "", powerNet = "", referenceNet = "", l_group = "", r_group = "", decaps = [] } = props;
    this.name = name;
    this.powerNet = powerNet;
    this.referenceNet = referenceNet;
    this.l_group = l_group;
    this.r_group = r_group;
    this.decaps = decaps
  }
}

class Decap {
  constructor(props) {
    const { name, number, l_layout = "", r_layout = "", model = {} } = props;
    this.id = newNanoId(8);
    this.name = name;
    this.number = number;
    this.l_layout = l_layout;
    this.r_layout = r_layout;
    this.model = model
  }
}

class PreLayoutBall {
  constructor(props) {
    const { l = "", r = "", c = "" } = props;
    this.l = l;
    this.r = r;
    this.c = c
  }
}

export {
  PreLayoutContent,
  PreLayoutComponent,
  DecapGroup,
  Decap,
  PreLayoutBall,
  DCPreComponent
}