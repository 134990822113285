import { CHANGE_RESULT_TYPE, GET_IR_LIST_VALUE, SAVE_IR_VALUE, GET_IR_VALUE_FILE, GET_IR_INFO_SUCCESS, UPDATE_IR_SELECTED_KEY, UPDATE_IR_RESULT_TABLE_LOADING, CLEAR_IR_RESULT_DATA } from './actionType';
export const changeResultType = (resultKey, setupType) => ({
  type: CHANGE_RESULT_TYPE,
  resultKey,
  setupType
})

export const getIRValue = (verificationId) => ({
  type: GET_IR_LIST_VALUE,
  verificationId,
})

export const saveIRValue = (list, resultPowerDomainData) => ({
  type: SAVE_IR_VALUE,
  list,
  resultPowerDomainData
})

export const getIRValueFile = (resultId, resultKey, verificationId) => ({
  type: GET_IR_VALUE_FILE,
  resultId,
  resultKey,
  verificationId
})

export const getIRInfoSuccess = (irData) => ({
  type: GET_IR_INFO_SUCCESS,
  irData
})

export const updateIRSelectedKey = (IRSelectedKey) => ({
  type: UPDATE_IR_SELECTED_KEY,
  IRSelectedKey
})

export const updateIRResultTableLoading = (loading) => ({
  type: UPDATE_IR_RESULT_TABLE_LOADING,
  loading
})

export const clearIRResultData = () => ({
  type: CLEAR_IR_RESULT_DATA
})