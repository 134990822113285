import { deleteTemplateList, getTemplateList, uploadTemplate } from '../api/template';
import apiProcess from '../api/utility';

/**
* Delete report template file
* 2021/03/15
* @export Array templateIds
* @returns
*/
export function deleteReportTemplate(templateIds) {
  return apiProcess(deleteTemplateList, templateIds)
}

/**
 * Get report template list
 * 2021/03/15
 * @export
 * @returns
 */
export function getReportTemplate(type) {
  return apiProcess(getTemplateList, type)
}

/**
 * Upload report template file
 * 2021/03/15
 * @export
 * @returns
 */
export function uploadReportTemplate({ files, name, metaData, config }) {
  return apiProcess(uploadTemplate, { files, name, metaData, config });
}