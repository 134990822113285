import { strDelimited } from "../split";
import { strFormatChange } from "../stringHelper";

const PIN_L = "pinL",
  PIN_R = "pinR",
  PIN_L_EXTERNAL = "pinLExternal",
  PIN_R_EXTERNAL = "pinRExternal",
  PIN_L_EXTERNAL_CABLE = "pinLExternalCable",
  PIN_R_EXTERNAL_CABLE = "pinRExternalCable";

/**
 * get port by connector type
 * @param {Object} pinInfo 
 * {
 *  pinLPort, pinLModelId,
 *  pinRPort, pinRModelId,
 *  pinLExternalMap:{ port, modelId, cablePort,cableModelId },
 *  pinRExternalMap:{ port, modelId, cablePort,cableModelId } 
 * }
 * @param {String} type port type "pinL" / "pinR" / "pinLExternal"/ "pinLExternalCable"/ ...
 * */
function getPortByType(pinInfo, type) {
  switch (type) {
    case PIN_L:
    case PIN_R:
      return {
        port: pinInfo[`${type}Port`],
        portLibraryId: pinInfo[`${type}ModelId`],
        portModelKey: pinInfo[`${type}ModelKey`]
      };
    case PIN_L_EXTERNAL:
    case PIN_R_EXTERNAL:
      return {
        port: pinInfo[`${type}Map`].port,
        portLibraryId: pinInfo[`${type}Map`].modelId,
        portModelKey: pinInfo[`${type}Map`].modelKey
      }
    case PIN_L_EXTERNAL_CABLE:
      return {
        port: pinInfo.pinLExternalMap.cablePort,
        portLibraryId: pinInfo.pinLExternalMap.cableModelId,
        portModelKey: pinInfo.pinLExternalMap.cableModelKey
      }
    case PIN_R_EXTERNAL_CABLE:
      return {
        port: pinInfo.pinRExternalMap.cablePort,
        portLibraryId: pinInfo.pinRExternalMap.cableModelId,
        portModelKey: pinInfo.pinRExternalMap.cableModelKey
      }
    default: return {};
  }
}

/**
 * get current model selected ports
 * @param {Array} pinList 
 * [{
 *  pinL, 
 *  pinR,
 *  pinLPort, pinLModelId,
 *  pinRPort, pinRModelId,
 *  pinLExternalMap:{ port, modelId, cablePort,cableModelId },
 *  pinRExternalMap:{ port, modelId, cablePort,cableModelId } 
 * }]
 * @param {String} libraryId touchstone file id
 * @param {String} type port type "pinL" / "pinR" / "pinLExternal"/ "pinLExternalCable"/ ...
 * */
function getSelectedPorts(type, libraryId, pinList, modelKey) {
  let _pinList = [];
  pinList.map(item => _pinList.push(...item.pins));
  let selectedPorts = [];
  switch (type) {
    case PIN_L:
    case PIN_L_EXTERNAL:
      selectedPorts = getConnectorSelectedPorts({ selectedPorts, pinList: _pinList, libraryId, modelKey, type: PIN_L })
      break;
    case PIN_R:
    case PIN_R_EXTERNAL:
      selectedPorts = getConnectorSelectedPorts({ selectedPorts, pinList: _pinList, libraryId, modelKey, type: PIN_R })
      break;
    case PIN_L_EXTERNAL_CABLE:
    case PIN_R_EXTERNAL_CABLE:
      _pinList.forEach(item => {
        if (item.pinLExternalMap.cablePort && item.pinLExternalMap.cableModelId === libraryId && (item.pinLExternalMap.cableModelKey === modelKey || (!item.pinLExternalMap.cableModelKey && !modelKey))) {
          selectedPorts.push(item.pinLExternalMap.cablePort)
        }

        if (item.pinRExternalMap.cablePort && item.pinRExternalMap.cableModelId === libraryId && (item.pinRExternalMap.cableModelKey === modelKey || (!item.pinRExternalMap.cableModelKey && !modelKey))) {
          selectedPorts.push(item.pinRExternalMap.cablePort)
        }
      });
      break;
    default: break;
  }
  return selectedPorts;
}

function getConnectorSelectedPorts({ selectedPorts, pinList, libraryId, modelKey, type }) {
  pinList.forEach(item => {
    if (item[`${type}Port`] && item[`${type}ModelId`] === libraryId && (item[`${type}ModelKey`] === modelKey || (!item[`${type}ModelKey`] && !modelKey))) {
      selectedPorts.push(item[`${type}Port`])
    }

    if (item[`${type}ExternalMap`].port && item[`${type}ExternalMap`].modelId === libraryId && (item[`${type}ExternalMap`].modelKey === modelKey || (!item[`${type}ExternalMap`].modelKey && !modelKey))) {
      selectedPorts.push(item[`${type}ExternalMap`].port)
    }
  });
  return selectedPorts;
}

/**
 * get current model selected ports
 * @param {Object} currentItem 
 * {
 *  pinLPort, pinLModelId,
 *  pinRPort, pinRModelId,
 *  pinLExternalMap:{ port, modelId, cablePort,cableModelId },
 *  pinRExternalMap:{ port, modelId, cablePort,cableModelId } 
 * }
 * @param {String} searchValue input value
 * @param {Object} portsObj ports info { [libraryId]:[ { port, portInfo }, ... ] }
 * @param {String} type port type "pinL" / "pinR" / "pinLExternal"/ "pinLExternalCable"/ ...
 * @param {Array} pinList display pin and ports list 
 * [{
 *  pinL, 
 *  pinR,
 *  pinLPort, pinLModelId,
 *  pinRPort, pinRModelId,
 *  pinLExternalMap:{ port, modelId, cablePort,cableModelId },
 *  pinRExternalMap:{ port, modelId, cablePort,cableModelId } 
 * }]
 * @param {Object} selectFile { key:libraryId, label: fileName }
 * */
function getDisplayPortList({
  currentItem,
  type,
  searchValue,
  portsObj,
  pinList,
  selectFile = {}
}) {
  if (!portsObj || Object.keys(portsObj).length === 0) {
    return [];
  }

  const [selectFileId, modelKey] = strDelimited(selectFile.key, "-");
  //current pin connected port libraryId
  const { port, portLibraryId, portModelKey } = getPortByType(currentItem, type);

  if (!selectFile.key) {
    return [];
  }
  //find current libraryId ports
  let ports = portsObj[selectFileId] ? portsObj[selectFileId] : [];

  if (selectFile.type === 'spice') {
    const current = ports.find(subckt => subckt.name === selectFile.subckt)
    ports = current ? current.ports.map(port => ({ port: port, info: port })) : [];
  }

  const selectedPorts = getSelectedPorts(type, selectFileId, pinList, modelKey);

  //Add escape characters to special characters
  const value = strFormatChange(searchValue);
  const reg = new RegExp(`(${value})`, 'i');

  //sort ports
  let _portList = [], selectedList = [], currentPort = [], searchList = [], notSelectedList = [];
  ports.forEach(item => {
    if (portLibraryId === selectFileId && port && item.port === port && portModelKey === modelKey) {
      // selected port of current pin
      currentPort.push({ ...item, libraryId: selectFileId, modelKey, select: true });
    } else if (searchValue && (portLibraryId !== selectFileId || port !== searchValue) && item.info.match(reg)) {
      //searched ports
      searchList.push({ ...item, libraryId: selectFileId, modelKey });
    } else if (selectedPorts.includes(item.port)) {
      //Ports that have been selected by other pins
      selectedList.push({ ...item, libraryId: selectFileId, modelKey, select: true });
    } else {
      //No selected ports
      notSelectedList.push({ ...item, libraryId: selectFileId, modelKey, select: false });
    }
  });

  const _searchSelectedList = searchList.filter(item => item.select);
  const _searchNotSelectedList = searchList.filter(item => !item.select);
  const _searchList = [..._searchNotSelectedList, ..._searchSelectedList];
  //current pin select port + searched ports + notSelected ports + selected ports
  _portList = [...currentPort, ..._searchList, ...notSelectedList, ...selectedList];
  return _portList;
}

/**
 * get current model selected ports
 * @param {Object} connector1
 * @param {Object} connector2
 * {
 *  component, 
 *  models,
 *  pins: [ { pin, port ,modelId, external_map:{ port, modelId, cablePort, cableModelId }}]
 * }
 * @param {String} port selected port
 * @param {Object} currentPortInfo ports info { port, portInfo,libraryId }
 * @param {Object} portsObj ports info { [libraryId]:[ { port, portInfo }, ... ] }
 * @param {String} type port type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
 * @param {String} pin 
 * */
function updateConnectorPinPort({
  port,
  pin,
  type,
  modelId,
  modelKey,
  connector1,
  connector2
}) {
  let _connector1 = { ...connector1 }, _connector2 = { ...connector2 };

  if (type.match(PIN_L)) {
    _connector1 = updatePinPortByType({
      connector: _connector1,
      type,
      pin,
      port,
      modelId,
      modelKey
    })
  }

  if (type.match(PIN_R)) {
    _connector2 = updatePinPortByType({
      connector: _connector2,
      type,
      pin,
      port,
      modelId,
      modelKey
    })
  }

  return {
    _connector1,
    _connector2
  }
}

/**
 * update pin port by type
 * @param {Object} connector
 * {
 *  component, 
 *  models,
 *  pins: [ { pin, port ,modelId, external_map:{ port, modelId, cablePort, cableModelId }}]
 * }
 * @param {String} port selected port
 * @param {String} modelId port library id
 * @param {String} type port type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
 * @param {String} pin 
 * */
function updatePinPortByType({ connector, type, pin, port, modelId, modelKey }) {
  const index = connector.pins.findIndex(item => item.pin === pin);
  if (index < 0) {
    return connector;
  }
  switch (type) {
    case PIN_L:
    case PIN_R:
      connector.pins[index].port = port;
      connector.pins[index].modelId = modelId;
      connector.pins[index].modelKey = modelKey;
      break;
    case PIN_L_EXTERNAL:
    case PIN_R_EXTERNAL:
      const prevMap = connector.pins[index].external_map || {};
      connector.pins[index].external_map = {
        ...prevMap,
        port,
        modelId,
        modelKey
      };
      break;
    case PIN_L_EXTERNAL_CABLE:
    case PIN_R_EXTERNAL_CABLE:
      const pinLCableMap = connector.pins[index].external_map || {};
      connector.pins[index].external_map = {
        ...pinLCableMap,
        cablePort: port,
        cableModelId: modelId,
        cableModelKey: modelKey
      };
      break;
    default: break;
  }
  return connector;
}

function getPortSelectFile({
  selectModels,
  libraryId,
  pinModelKey,
  selectFile = {},
}) {
  let fileIndex = selectModels.findIndex(item => item.libraryId === libraryId && (!pinModelKey || pinModelKey === item.modelKey));
  let file = fileIndex > 0 ? { ...selectModels[fileIndex], key: `${selectModels[fileIndex].libraryId}-${selectModels[fileIndex].modelKey}`, label: selectModels[fileIndex].file } : null;
  if (!file) {
    // if selectFile exist, file is select file
    const [selectId, selectKey] = strDelimited(selectFile.key, "-");
    const _selectFileIndex = selectModels.findIndex(item => item.libraryId === selectId && (!pinModelKey || pinModelKey === selectKey));

    if (selectFile.key && _selectFileIndex > -1) {
      file = { ...selectFile }
    } else {
      // select models first file
      file = selectModels.length ? { ...selectModels[0], key: `${selectModels[0].libraryId}-${selectModels[0].modelKey}`, label: selectModels[0].file }
        : { key: "", label: "", type: "", subckt: "", modelKey: "" }
    }
  }
  return file;
}

function updateConnectionPinMap({
  pinMap,
  signal,
  connector1,
  connector2
}) {
  let _connector1 = { ...connector1 }, _connector2 = { ...connector2 };
  for (let pinItem of _connector1.pins) {
    // pin_map: '29', pin: '42',signal
    if (pinItem.signal !== signal) {
      continue;
    }

    const findPin = pinMap.find(item => item.pinL === pinItem.pin);
    if (!findPin) {
      continue;
    }

    pinItem.pin_map = findPin.pinR;
  }

  return { _connector1, _connector2 };
}

export {
  getDisplayPortList,
  updateConnectorPinPort,
  getPortSelectFile,
  getPortByType,
  getSelectedPorts,
  updateConnectionPinMap
}