import { getFileInRockyVerification, getEyeParameter, getAMIFileInRockyVerification } from '../../../../api/Rocky/rockyCtrl';
import Parameter from '../../../../EyeMask/parameters';
import DDRVixParamSpec from '../../../../EyeMask/vixSpec';
import overUnderShootSpec from '../../../../EyeMask/overUnderShootSpec';
import NP from 'number-precision';
import { VIX_ID_LIST, OVER_UNDER_SHOOT_KEYS } from '../../constant';

function EyeParamDataItem() {
  this.name = null; // String, name of the signal, or All Nets for a byte
  this.height = null; // Number, eye height
  this.width = null; // Number, eye width
  this.jitter = null; // Number, jitter
  this.top_margin = null; // Number, top voltage margin
  this.bot_margin = null; // Number, bottom voltage margin
  this.setup_margin = null; // Number, setup margin
  this.hold_margin = null; // Number, hold margin
  this.skew_spec = null; // Number, signal skew specification
  this.mask = [];   // Array of points, eye mask boundary points
  this.setup_margin_box = null; // Array of points, setup margin box in front of the eye mask
  this.hold_margin_box = null; // Array of points, hold margin box after the eye mask
  this.parameters = null; // [Parameter] Array of parameter, the parameter names are: eye_width, eye_height, jitter, setup_margin, hold_margin, top_margin, bottom_margin(_t1,t2,v1,v2), tjit_dqs, tjit, v_cent_dq
  this.pngParam = null; // Object of eye diagram png config {eyeDiagramConfig}
  this.mask_width = null;//LPDDR5 mask width
};

function eyeDiagramConfig(obj) {
  this.t_scale = obj.t_scale;
  this.v_scale = obj.v_scale;
  this.t_min = obj.t_min;
  this.t_max = obj.t_max;
  this.v_min = obj.v_min;
  this.v_max = obj.v_max;
  this.t_min_pixel = obj.t_min_pixel;
  this.t_max_pixel = obj.t_max_pixel;
  this.v_min_pixel = obj.y_min_pixel;
  this.v_max_pixel = obj.y_max_pixel
}

export function getFile({ channelId, filePath }) {
  return new Promise((resolve) => {
    getFileInRockyVerification({ channelId, filePath }).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  })
}
export function getEyeDiagramFile({ channelId, victimNet, type, fileName, memory }) {
  return new Promise((resolve) => {
    getEyeParameter(channelId, victimNet, type, fileName, memory).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  })
}

function toNumber(string) {
  return NP.strip(Number(string));
}

/** Parse the eye parameter file and convert the content into an object
*  @param  fileContent - eye parameter file content as a string
*  @return a raw EyeParamDataItem object parsed from the file
*/
function parseEyeParamFile(name, fileContent) {
  function getMarginBox(words) {
    const coord = words[3].split(',')
    const x0 = toNumber(coord[0].replace('(', ''))
    const y0 = toNumber(coord[1].replace(')', ''))
    const w = toNumber(words[4].replace(',', ''))
    const h = toNumber(words[5].split('\t')[0]);
    return [[x0, y0], [x0 + w, y0], [x0 + w, y0 + h], [x0, y0 + h], [x0, y0]];
  }
  let eyeParam = new EyeParamDataItem();
  eyeParam.name = name;
  try {
    const lineBuffer = fileContent.match(/[^\r\n]+/g);
    let readingEyeMask = false;

    // parse the lines in the file
    for (let iLine of lineBuffer) {

      let words = iLine.trim().split(' ');

      if (readingEyeMask) {
        words = iLine.trim().split("\t");
        eyeParam.mask.push([toNumber(words[0]), toNumber(words[1])]);
        if (eyeParam.mask.length >= 6) {
          readingEyeMask = false;
        }
      } else {
        switch (words[0]) {
          case 'jitter:':
            eyeParam.jitter = toNumber(words[1]); break;
          case 'top':
            eyeParam.top_margin = toNumber(words[2]); break;
          case 'bottom':
            eyeParam.bot_margin = toNumber(words[2]); break;
          case 'setup':
            if (words[1] === 'margin:') {
              eyeParam.setup_margin = toNumber(words[2]);
            } else if (words[1] === 'margin' && words[2] === 'box:') {
              eyeParam.setup_margin_box = getMarginBox(words);
            }
            break;
          case 'hold':
            if (words[1] === 'margin:') {
              eyeParam.hold_margin = toNumber(words[2]);
            } else if (words[1] === 'margin' && words[2] === 'box:') {
              eyeParam.hold_margin_box = getMarginBox(words);
            }
            break;
          case 'skew':
            eyeParam.skew_spec = toNumber(words[4]); break;
          case 'eye':
            if (words[1] === 'height:') {
              eyeParam.height = toNumber(words[2]);
            } else if (words[1] === 'width:') {
              eyeParam.width = toNumber(words[2]);
            } else if (words[1] === 'mask:') {
              readingEyeMask = true;
            }
            break;
          default: break;
        }
      } // if (readingEyeMask) ... else ...
    } // for (let iLine of lineBuffer)
  } catch (error) {
    console.error(error);
  }
  return eyeParam;
} // parseEyeParamFile

function getParamValue(params, id, parameterClass, dataRate) {
  const find = params.find(d => d.id === id);
  if (find) {
    return find.definition.getDefinitionValue(parameterClass, dataRate);
  }
  return undefined;
}

function parseEyeParamJson(name, jsonContent, pngParamContent, waveJson) {
  let eyeParam = new EyeParamDataItem();
  eyeParam.name = name;
  const params = jsonContent.parameters.map(param => {
    const _param = new Parameter(param);
    _param.paramInit(param);
    return _param;
  });
  eyeParam.parameters = params;
  eyeParam.height = getParamValue(params, 'eye_height')
  eyeParam.width = getParamValue(params, 'eye_width') / 1e12;
  eyeParam.jitter = getParamValue(params, 'jitter') / 1e12;
  eyeParam.top_margin = getParamValue(params, 'top_margin');
  eyeParam.bot_margin = getParamValue(params, 'bottom_margin');
  eyeParam.setup_margin = getParamValue(params, 'setup_margin') / 1e12;
  eyeParam.hold_margin = getParamValue(params, 'hold_margin') / 1e12;
  eyeParam.pngParam = pngParamContent ? new eyeDiagramConfig(pngParamContent) : null;
  if (waveJson && waveJson.parameter && Object.keys(waveJson.parameter).length) {
    const vixParams = waveJson.parameter && waveJson.parameter.vix ? waveJson.parameter.vix : {};
    const overShootParams = waveJson.parameter && waveJson.parameter.overshoot ? waveJson.parameter.overshoot : null;
    const underShootParams = waveJson.parameter && waveJson.parameter.undershoot ? waveJson.parameter.undershoot : null;
    const diffOverShootParams = waveJson.parameter && waveJson.parameter.overshoot_differential_line ? waveJson.parameter.overshoot_differential_line : null;
    const diffUnderShootParams = waveJson.parameter && waveJson.parameter.undershoot_differential_line ? waveJson.parameter.undershoot_differential_line : null;
    //VIX
    eyeParam = setVixParamSpec(vixParams, eyeParam);
    //OverShoot
    eyeParam = setOvershootParamSpec(overShootParams, eyeParam);
    //UnderShoot
    eyeParam = setUnderShootParamSpec(underShootParams, eyeParam);
    //Differential OverShoot
    eyeParam = setDiffOverShootParamSpec(diffOverShootParams, eyeParam);
    //Differential UnderShoot
    eyeParam = setDiffUnderShootParamSpec(diffUnderShootParams, eyeParam);
  }
  return eyeParam;
}

function setVixParamSpec(vixParams, eyeParam) {
  VIX_ID_LIST.forEach(vix => {
    const vixParam = {
      definition: {
        measured: vixParams.vix_max_margin ? vixParams.vix_max_margin.value : null,
      },
      dimension: "voltage",
      id: vix,
      name: vix,
      unit: vixParams.vix_max_margin ? vixParams.vix_max_margin.unit : null,
      passStatus: vixParams.vix_pass ? vixParams.vix_pass.pass : false,
      description: vix === 'vix_dq' ? 'DQS Vix' : 'CLK Vix',
      pass: vixParams.vix_pass ? vixParams.vix_pass.pass : null,
      type: vix === 'vix_dq' ? 'byte' : 'ca',
      vixPass: vixParams.vix_pass ? vixParams.vix_pass : null,   //vixPass.vix_spec_type true means percentage, false means Decimal
      voltageAmplitude: vixParams.voltage_amplitude ? vixParams.voltage_amplitude : null
    }
    const _vixParam = new DDRVixParamSpec(vixParam);
    _vixParam.initSpec(vixParam);
    if (_vixParam) {
      eyeParam.parameters.push(_vixParam);
    }
  });
  return eyeParam;
}

function setOvershootParamSpec(shootParams, eyeParam) {
  if (!shootParams) {
    return eyeParam;
  }
  //OverShoot
  const overshootParam = new overUnderShootSpec(shootParams);
  overshootParam.initSpec(shootParams);
  OVER_UNDER_SHOOT_KEYS.forEach(key => {
    if (!overshootParam[key]) {
      return;
    }
    const name = 'Over ' + convertToTitleCase(key);
    const _key = 'over_' + key;
    const params = {
      id: _key,
      name: name,
      unit: overshootParam[key].unit,
      value: overshootParam[key].value,
      pass: overshootParam[key].pass,
      definition: {
        measured: overshootParam[key].value ? overshootParam[key].value : null,
      },
      description: name,
    }
    const _param = new overUnderShootSpec(params);
    _param.paramInit(params);
    if (_param) {
      eyeParam.parameters.push(_param);
    }
  });
  eyeParam.overshoot_margin = overshootParam.shoot_margin || null;
  eyeParam.overshoot_area_margin = overshootParam.area_margin || null;
  eyeParam.overshoot_area_margin2 = overshootParam.area_margin2 || null;
  return eyeParam;
}

function setUnderShootParamSpec(shootParams, eyeParam) {
  if (!shootParams) {
    return eyeParam;
  }
  const undershootParam = new overUnderShootSpec(shootParams);
  undershootParam.initSpec(shootParams);
  OVER_UNDER_SHOOT_KEYS.forEach(key => {
    if (!undershootParam[key]) {
      return;
    }
    const name = 'Under ' + convertToTitleCase(key);
    const _key = 'under_' + key;
    const params = {
      id: _key,
      name: name,
      unit: undershootParam[key].unit,
      value: undershootParam[key].value,
      pass: undershootParam[key].pass,
      definition: {
        measured: undershootParam[key].value ? undershootParam[key].value : null,
      },
      description: name,
    }
    const _param = new overUnderShootSpec(params);
    _param.paramInit(params);
    if (_param) {
      eyeParam.parameters.push(_param);
    }
  });
  eyeParam.undershoot_margin = undershootParam.shoot_margin || null;
  eyeParam.undershoot_area_margin = undershootParam.area_margin || null;
  eyeParam.undershoot_area_margin2 = undershootParam.area_margin2 || null;
  return eyeParam;
}

function setDiffOverShootParamSpec(shootParams, eyeParam) {
  if (!shootParams) {
    return eyeParam;
  }
  const diffOverShootParam = new overUnderShootSpec(shootParams);
  diffOverShootParam.initSpec(shootParams);
  OVER_UNDER_SHOOT_KEYS.forEach(key => {
    if (!diffOverShootParam[key]) {
      return;
    }
    const name = 'Differential Over ' + convertToTitleCase(key);
    const _key = 'diff_over_' + key;
    const params = {
      id: _key,
      name: name,
      unit: diffOverShootParam[key].unit,
      value: diffOverShootParam[key].value,
      pass: diffOverShootParam[key].pass,
      definition: {
        measured: diffOverShootParam[key].value ? diffOverShootParam[key].value : null,
      },
      description: name,
    }
    const _param = new overUnderShootSpec(params);
    _param.paramInit(params);
    if (_param) {
      eyeParam.parameters.push(_param);
    }
  });
  eyeParam.diff_over_shoot_margin = diffOverShootParam.shoot_margin || null;
  eyeParam.diff_over_shoot_area_margin = diffOverShootParam.area_margin || null;
  eyeParam.diff_over_shoot_area_margin2 = diffOverShootParam.area_margin2 || null;
  return eyeParam;
}

function setDiffUnderShootParamSpec(shootParams, eyeParam) {
  if (!shootParams) {
    return eyeParam;
  }
  const diffUnderShootParam = new overUnderShootSpec(shootParams);
  diffUnderShootParam.initSpec(shootParams);
  OVER_UNDER_SHOOT_KEYS.forEach(key => {
    if (!diffUnderShootParam[key]) {
      return;
    }
    const name = 'Differential Under ' + convertToTitleCase(key);
    const _key = 'diff_under_' + key;
    const params = {
      id: _key,
      name: name,
      unit: diffUnderShootParam[key].unit,
      value: diffUnderShootParam[key].value,
      pass: diffUnderShootParam[key].pass,
      definition: {
        measured: diffUnderShootParam[key].value ? diffUnderShootParam[key].value : null,
      },
      description: name,
    }
    const _param = new overUnderShootSpec(params);
    _param.paramInit(params);
    if (_param) {
      eyeParam.parameters.push(_param);
    }
  });
  eyeParam.diff_under_shoot_margin = diffUnderShootParam.shoot_margin || null;
  eyeParam.diff_under_shoot_area_margin = diffUnderShootParam.area_margin || null;
  eyeParam.diff_under_shoot_area_margin2 = diffUnderShootParam.area_margin2 || null;
  return eyeParam;
}

function convertToTitleCase(str) {
  try {
    const words = str.split('_');
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalizedWords.join(' ');
  } catch (error) {
    console.error(error);
    return str;
  }
}

function parseAMIEyeParamJson(name, jsonContent) {
  let eyeParam = new EyeParamDataItem();
  eyeParam.name = name;
  Object.keys(jsonContent).forEach(key => {
    switch (key) {
      case 'eyeHeight':
        eyeParam.height = parseFloat(jsonContent[key]) / 1000 + ' mV';
        break;
      case 'eyeWidth':
        eyeParam.width = parseFloat(jsonContent[key]) * 1e12 + ' ps';
        break;
      default:
        break;
    }
  });
  eyeParam.amiParameters = jsonContent;
  return eyeParam;
}

// One Byte/CLK_ADR/CLK_CMD class constructor
class DdrResultEyeParam {
  constructor() {
    // eye_parameter.txt;
    this.eyeParamDataList = new Map(); // key - name of the signal, value - EyeParamDataItem
  }

  getSize() {
    return this.eyeParamDataList.size;
  };

  getData() {
    return this.eyeParamDataList;
  }

  updateData(newData) {
    this.eyeParamDataList = newData;
  }
  // patterns
  readAllPatternEyeParamFiles(channelId, victimNets, type) {
    const _type = type === 'write' ? 'Write' : 'Read';
    return new Promise((resolve, reject) => {
      const allParam = Promise.all(victimNets.map((victimNet) => this.readPatternEyeParamFile({ channelId, type: _type, victimNet: victimNet.name, memory: victimNet.memory, victimNetName: victimNet.victimNet })));
      allParam.then(() => {
        resolve(this.eyeParamDataList);
      });
    });
  }

  //patterns
  readPatternEyeParamFile({ channelId, victimNet, type, memory, victimNetName }) {
    let fileName = 'eye_parameter.json', eye_parameter_png_json = 'eye_diagram.png.json'
    return new Promise(async (resolve, reject) => {
      const res = await getEyeDiagramFile({ channelId, victimNet: victimNetName, type, fileName, memory });
      if (res && res.parameters) {
        const pngJson = await getEyeDiagramFile({ channelId, victimNet: victimNetName, type, fileName: eye_parameter_png_json, memory });
        const eyeParamItem = parseEyeParamJson(victimNetName, res, pngJson);
        this.eyeParamDataList.set(victimNet, eyeParamItem);
        resolve(this.eyeParamDataList);
      } else {
        console.error('Can\'t get eyediagram parameter file');
        resolve(null)
      }
    });
  }


  // signals -[{ name, path }]
  readAllEyeParamFiles(channelId, signals = []) {
    return new Promise((resolve, reject) => {
      const allParam = Promise.all(signals.map((signal) => this.readEyeParamFile({ name: signal.name, path: signal.path, channelId, train: signal.train })));
      allParam.then(() => {
        resolve(this.eyeParamDataList);
      })
    });
  }

  // name - signal name or All
  getEyeParamData(name) {
    this.eyeParamDataList.get(name);
  }

  // name - signal name or All, path - /eye_parameter.txt;
  readEyeParamFile({ name, path, channelId, train }) {
    let fileName = '/eye_parameter.json', prevFileName = '/eye_parameter.txt', eye_parameter_png_json = '/eye_diagram.png.json', _name = name;
    let wave_parameter_json = '/wave_parameter.json';
    if (train) {
      // training FileName
      fileName = '/eye_parameter_adjusted.json';
      prevFileName = '/eye_parameter_adjusted.txt';
      eye_parameter_png_json = '/eye_diagram_adjusted.png.json';
      _name = 'train' + name;
      wave_parameter_json = '/wave_parameter_adjusted.json';
    }
    return new Promise(async (resolve, reject) => {
      const res = await getFile({ channelId, filePath: path + fileName });
      if (res) {
        const pngJson = await getFile({ channelId, filePath: path + eye_parameter_png_json });
        const waveJson = await getFile({ channelId, filePath: path + wave_parameter_json });
        const eyeParamItem = parseEyeParamJson(name, res, pngJson, waveJson);
        this.eyeParamDataList.set(name, eyeParamItem);
        resolve(this.eyeParamDataList)
      } else {
        const prevPath = path + prevFileName;
        const _res = await getFile({ channelId, filePath: prevPath });
        if (_res) {
          const eyeParamItem = parseEyeParamFile(name, _res);
          this.eyeParamDataList.set(name, eyeParamItem);
          resolve(this.eyeParamDataList)
        } else {
          console.error('Can\'t get eyediagram parameter file', prevPath);
          resolve(null)
        }
      }
    })
  };

  //ami
  readAllAMIEyeParamFiles({ signals, channelId, type, verificationId }) {
    return new Promise((resolve, reject) => {
      const allParam = Promise.all(signals.map((signal) => this.readAMIEyeParamFile({ signalName: signal.name, channelId, type, verificationId })));
      allParam.then(() => {
        resolve(this.eyeParamDataList);
      });
    });
  }

  //AMI
  readAMIEyeParamFile({ signalName, channelId, type, verificationId }) {
    const fileName = 'eye_parameter.json';
    return new Promise(async (resolve, reject) => {
      const res = await getAMIFileInRockyVerification(signalName, channelId, type, fileName, verificationId);
      if (res && res.data) {
        const eyeParamItem = parseAMIEyeParamJson(signalName, res.data);
        this.eyeParamDataList.set(signalName, eyeParamItem);
        resolve(this.eyeParamDataList);
      } else {
        console.error('Can\'t get eyediagram parameter file');
        resolve(null);
      }
    });
  }
};

export default DdrResultEyeParam;
export {
  parseEyeParamFile,
  parseEyeParamJson,
  getParamValue
}