import { getNetsListByComps, getComponentsByNets, _getNetsListByComp, findNetListByNetName } from '../componentData';
import LayoutData from '../../../data/LayoutData';
import { componentFilter } from '..';
import { matchPinName, getGndPinByLocationAndLayer } from '../getVRM';
import { RES, IND, SWITCH, FERRITE, JUMPER, TRANSISTOR, DIODE, IGNORE } from '../../../PCBHelper';
import { LINEAR, SWITCHING, SPECIALIZED, LINEAR_SWITCH } from '@/constants/componentType';
import { getComponentPinLocation } from '../../../ExtractionPortsHelper';
import CeLine from '../../../geometry/CeLine';
import CePolygon from '../../../geometry/CePolygon';
import { splitArrayToArrays } from '../../arrayHelper';
import { getComponentsWithNetList, } from '../../../helper/setup/setupData'
import hash from 'object-hash';
import _ from 'lodash';
import { userDefaultSettings } from '../../../userDefaultSetting/userDefaultSettingCtrl';

const SpaceFour = '\xa0\xa0\xa0\xa0';
const allCompsInfo = {};
const VRM_CHARACTERISTIC = ['SW', 'VOUT', 'OUT', 'BUCK'];
const VRM_SENSE_PIN = ['FB', 'FEEDBACK', 'VCCA'];
const VRM_NOT_CURRENT_PIN = ['VIN', 'IN', 'GND', 'GPIO', 'FSOB', 'EN', 'PGOOD', 'SCL', 'SDA', 'VSELECT', 'VDDIO'];
const SWITCH_CHARA = ['OUT', 'IN'];
const XWMatch = new RegExp(/^XW/, 'ig');

export class findVRMInfo {
  constructor() {
    this.NET_LIST = [];
    this.PART_INFO = [];
    this.VRMComps = [];
    this.VRMCompsName = [];
    this.debugMonitor = [];
    this.findExtendedNets = [];
    this.filterNets = [];
    this.allExtendedNets = [];

    this.prevConnInfo = '';
    this.connectsCompNetInfo = [];
    this.nets = [];
    this.component = [];
    this.netsInfo = [];
    this.compsList = [];
    this.filterCompName = ""
  }
}

class GetVRMs {
  constructor(
    { VRM, Components, ReferenceNets, PowerNets, pcbId, COMP_PREFIX_LIB,
      findVRMComps, findCaps, findExtend = true, isAC = false,
      connectInductance = false, connectNets = {}, ExistNets = [], chips = [],
      powerSwitch = [], PMIC = [], doNotStuff = [], buckConverter = [], pinMapSense,
      isTemplate = false, pinConnection = [], pmicAndNets = [], pinMap = [], specify = []
    },
    { getLayoutComponents, getPowerComponentsByNets, getReferenceComps, getAllComponents }
  ) {
    this.VRM = VRM;
    this.Components = Components;
    this.ReferenceNets = ReferenceNets;
    this.PowerNets = PowerNets;
    this.pcbId = pcbId;
    this.COMP_PREFIX_LIB = COMP_PREFIX_LIB;
    this.findVRMComps = findVRMComps;
    this.findExtend = findExtend;
    this.findCaps = findCaps;
    this.isAC = isAC;
    this.connectInductance = connectInductance;
    this.connectNets = connectNets;
    this.getPowerComponentsByNets = getPowerComponentsByNets;
    this.getLayoutComponents = getLayoutComponents;
    this.getReferenceComps = getReferenceComps;
    this.ExistNets = ExistNets;
    this.powerSwitch = powerSwitch;
    this.PMIC = PMIC;
    this.getAllComponents = getAllComponents;
    this.doNotStuff = doNotStuff;
    this.buckConverter = buckConverter;
    this.allComponents = getAllComponents({ pcbId, COMP_PREFIX_LIB })
    this.pinConnection = pinConnection;
    this.pinMap = pinMap;
    this.specify = specify;

    this.DEBUG_MONITOR = [];
    this.updateComponents = Components;
    this._findVRMComps = [];
    this.chips = chips;
    this.extendedNets = [];
    this.eqPowerPin = [];
    this.sensePort = [];
    this.vrms = null;
    this.findVRMInfo = new findVRMInfo();
    this.pinMapSense = pinMapSense;
    this.isTemplate = isTemplate;
    this.gndSensePort = [];
    this.sensePorts = [];
    this.pmicAndNets = pmicAndNets || []
  }

  getVRM = () => {
    const _compNames = this.Components.map(item => item.name);
    // List {Res, Ind}
    this._findVRMComps = this.findVRMComps.filter(comp => _compNames.indexOf(comp.name) > -1);

    // Cascade - Chip;
    // const chipTypes = ['Chip'];
    // this.chips = this.Components.filter(it => chipTypes.includes(it.usage)).map(d => d.name);

    try {
      if (this.PowerNets.length > 0) {
        this.AutoFindVRM();

        // find caps only conntect reference nets
        // 2021/10/29 Allow all the capacitors that are connected to the reference net of the power domain (November's release)
        const { extendedNets, pcbId, findExtend, isAC } = this;

        const { findCaps } = this.getPowerComponentsByNets({ ReferenceNets: this.ReferenceNets, PowerNets: [], pcbId: pcbId, COMP_PREFIX_LIB: this.COMP_PREFIX_LIB, powerSwitch: this.powerSwitch });

        if (extendedNets.length > 0) {
          // this.DEBUG_MONITOR.push(`==> Find extended nets: ${extendedNets.join(', ')}`)
          // this.DEBUG_MONITOR.push(`==> Add ${extendedNets.join(', ')} ${extendedNets.length === 1 ? 'net' : 'nets'} to the Power Nets`);
          if (findExtend) {
            this.PowerNets = [...new Set([...this.PowerNets, ...extendedNets])];
          }
          const { Components } = this.getPowerComponentsByNets({
            ReferenceNets: this.ReferenceNets,
            PowerNets: this.PowerNets,
            pcbId: pcbId,
            COMP_PREFIX_LIB: this.COMP_PREFIX_LIB,
            powerSwitch: this.powerSwitch
          });
          let newPowerComponents = componentFilter({ Components, PowerNets: this.PowerNets, ReferenceNets: this.ReferenceNets });
          // Update Components - Copy the usage/model/value of components
          this.getUpdateComponents(newPowerComponents)
        }

        if (isAC && findExtend) {
          const newComponentsNames = this.updateComponents.map(d => d.name);
          const _findVRMComps = this._findVRMComps.filter(d => newComponentsNames.includes(d.name));
          const __compNames = this.updateComponents.map(item => item.name);

          const __findVRMComps = _findVRMComps.filter(comp => __compNames.indexOf(comp.name) > -1);
          this._findVRMComps = __findVRMComps;
        }
        if (findCaps.length > 0 && this.eqPowerPin.length > 0) {
          this.autoFindVRMGnd({ findCaps })
        } else {
          // No eq ground pin
          this.vrms = this.eqPowerPin.map(item => ({ pwr: isAC && findExtend ? item.inductance : item.name, gnd: isAC && findExtend ? item.vrm : '', vrmComp: item.vrm, inductance: item.inductance, title: item.title }));
        }
      };
      if (Array.isArray(this.VRM)) {
        this.VRM = [{
          groundPin: [],
          model: { name: '', id: '' },
          powerPin: [],
          voltage: "",
          VRM_COMP: this.isAC ? [] : ''
        }]
      }
      const { vrms, ReferenceNets, pcbId, COMP_PREFIX_LIB, findCaps, isAC } = this;
      if (vrms && vrms.length > 0) {
        this.VRM = [];
        let newVrms = []
        for (const vrm of vrms) {
          const { pwr, gnd, vrmComp, inductance, title } = vrm;
          const _VRM_COMP = isAC && title ? title : vrmComp;
          let _gndPins = [], _pwrPins = [];
          if (gnd) {
            let gndCompInfo;
            const { referenceComps } = this.getReferenceComps({ ReferenceNets, pcbId, COMP_PREFIX_LIB, VRM_COMPS: [gnd], findCaps })
            gndCompInfo = referenceComps.find(comp => comp.name === gnd)
            if (gndCompInfo) {
              const gndInfo = gndCompInfo.pins.filter(pin => ReferenceNets.indexOf(pin.net) > -1)
              const gndPins = gndInfo.map(item => item.pin);
              const gndPinNets = [...new Set(gndInfo.map(item => item.net))];

              _gndPins.push({ comp: gnd, pins: gndPins, net: gndPinNets });
            };
          }
          if (pwr) {
            const pwrCompInfo = this.updateComponents.find(comp => comp.name === pwr);
            //filter pins by powerNets
            //findExtended === false : _PowerNets only includes main power nets
            //findExtended === true : _PowerNets includes main power nets and extended nets
            if (pwrCompInfo) {
              const pwrInfo = pwrCompInfo.pins.filter(item => item.pin && this.PowerNets.includes(item.net))
              const pwrPins = pwrInfo.map(item => item.pin);
              const pwrNets = [...new Set(pwrInfo.map(item => item.net))];
              _pwrPins.push({ comp: pwr, pins: pwrPins, net: pwrNets });
            };
          }
          if (_gndPins.length && _pwrPins.length) {
            let _model = { name: "", id: "" };
            const findItem = this.VRM.find(v => v.VRM_COMP === vrmComp && hash(_gndPins) === hash(v.groundPin) && hash(_pwrPins) === hash(v.powerPin))
            if (!findItem) {
              if (!isAC) {
                this.VRM.push({
                  groundPin: _gndPins,
                  model: _model,
                  powerPin: _pwrPins,
                  voltage: "",
                  VRM_COMP: _VRM_COMP,
                })
              } else {
                const findIndex = this.VRM.findIndex(v => hash(_gndPins) === hash(v.groundPin) && hash(_pwrPins) === hash(v.powerPin))
                if (findIndex > -1) {
                  this.VRM[findIndex].VRM_COMP = [...new Set([...this.VRM[findIndex].VRM_COMP, _VRM_COMP])];
                } else {
                  this.VRM.push({
                    groundPin: _gndPins,
                    model: _model,
                    powerPin: _pwrPins,
                    voltage: "",
                    VRM_COMP: [_VRM_COMP],
                    inductance: this.isAC ? inductance : null
                  })
                }
              }
            }
            newVrms.push(vrm)
          }
        }
        this.vrms = newVrms;
      }
    } catch (error) {
      console.error(error);
    };
    this.sensePortFilter(this.vrms);
    //find ground sense path by power sense path and pin map ground sense pins
    this.getGndSensePort();
    const DCobj = !this.isAC ? { vrms: this.vrms } : {};
    this.updateComponents.forEach(item => {
      if ([SWITCH, JUMPER, FERRITE, RES].includes(item.COMP_TYPE)) {
        item.pins = item.pins.filter(pin => !this.ReferenceNets.includes(pin.net))
        if (item.pins.length > 2) {
          const connection = this.pinConnection.find(p => p.partNumber === item.part) || { pinMap: [] };
          let pinNumbers = item.pins.map(pin => pin.pin);
          const _pins = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1] && (pinNumbers.includes(map[0]) || pinNumbers.includes(map[1]))).flat(2) : [];
          let pinMap = _pins.length ? _pins : [];
          if (!pinMap.length) {
            pinNumbers.sort((a, b) => a - b);
            for (let i = 0; i < Math.floor(pinNumbers.length / 2); i++) {
              if (pinNumbers[i]) {
                pinMap.push(pinNumbers[i])
              }
              if (pinNumbers[pinNumbers.length - 1 - i]) {
                pinMap.push(pinNumbers[pinNumbers.length - 1 - i])
              }
            }
          }
          item.pinMap = pinMap;
        }
      }
    })

    const _VRM = JSON.parse(JSON.stringify(this.VRM))
    _VRM.forEach(it => {
      if (it.VRM_COMP && it.VRM_COMP[0]) {
        const pmicComp = this.allComponents.find(item => item.name === it.VRM_COMP[0])

        const powerNets = this.DEBUG_MONITOR.map(_it => {
          const findPmic = _it.findLast(item => item.type === "comp");
          if (findPmic && findPmic.name === it.VRM_COMP[0]) {
            const net = _it.findLast(item => item.type === 'net') || {}
            return net.name
          } else {
            return false;
          }
        }).filter(item => !!item);

        if (it.inductance && it.powerPin[0]) {
          let groundNet = this.ReferenceNets;
          const groundPins = pmicComp ? pmicComp.pins.filter(_item => _item.net === groundNet[0]) : []
          it.component = [{
            pwrComp: it.inductance,
            powerPins: [...it.powerPin[0].pins],
            extendNets: it.powerPin[0].net ? [...it.powerPin[0].net] : [],
            groundNet: [groundNet[0]],
            groundPins: groundPins.map(item => item.pin),
          }]
        } else {
          it.component = powerNets.filter(item => !!item).map(item => {
            let powerNetPins = pmicComp ? pmicComp.pins.filter(_item => _item.net === item) : []
            let groundNet = this.ReferenceNets;
            const groundPins = pmicComp ? pmicComp.pins.filter(_item => _item.net === groundNet[0]) : []
            return {
              pwrComp: pmicComp.name,
              powerPins: powerNetPins.map(item => item.pin),
              extendNets: [item],
              groundNet: [groundNet[0]],
              groundPins: groundPins.map(item => item.pin)
            }
          })
        }
      }

    })
    return {
      DEBUG_MONITOR: this.chips && this.chips[0] ? this.DEBUG_MONITOR.map(monitor => [{ type: 'comp', name: this.chips[0] }, ...monitor]) : this.DEBUG_MONITOR,
      VRM: _VRM,
      PowerNets: this.PowerNets,
      Components: this.updateComponents,
      /*  sensePort: this.sensePort, */
      sensePorts: this.sensePorts,
      /* gndSensePort: this.gndSensePort, */
      ...DCobj
    }
  }

  /**
   *  From the VRM, find the gnd sense path through the gnd sense pin set in the pin map table.
   *  */
  getGndSensePort = () => {
    this.sensePorts = [];

    if (!this.vrms || !this.sensePort) {
      return;
    }
    const vrmCompList = [...new Set(this.vrms.map(vrm => vrm.vrmComp).filter(item => !!item))];

    for (let pwrSense of (this.sensePort || [])) {
      let pwrSensePins = [];
      const senseVrm = pwrSense && pwrSense.length && pwrSense[pwrSense.length - 1] ? pwrSense[pwrSense.length - 1] : null;

      if (!senseVrm || !vrmCompList.includes(senseVrm.name)) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }

      pwrSensePins = (senseVrm.pins || []).map(it => it.pinName);
      const vrmComp = senseVrm.name;
      this.gndSensePort = [];
      const findVrmComp = this.allComponents.find(item => item.name === vrmComp);
      if (!findVrmComp) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }

      //find gnd sense pin map by pmic part
      const partPinMap = this.pinMapSense.get(findVrmComp.part);
      if (!partPinMap) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }
      const sensePinMap = partPinMap ? partPinMap.filter(item => item.sense && item.sense.find(it => pwrSensePins.includes(it))) : [];
      const currentGndSensePins = sensePinMap.map(item => item.gndSense).flat().filter(item => !!item) || [];
      const gndSensePins = findVrmComp.pins.filter(item => currentGndSensePins.includes(item.pinName));
      if (!gndSensePins || !gndSensePins.length) {
        this.sensePorts.push({
          powerSensePort: [...pwrSense],
          groundSensePort: []
        })
        continue;
      }

      //Find the nets connected to the gnd sense pin
      for (let pin of gndSensePins) {

        this.gndSenseConnectors = [];
        if (this.ReferenceNets.includes(pin.net) && this.isTemplate) {
          //If it is directly connected to the sense of reference nets
          this.gndSenseConnectors.push({ name: pin.net, type: "net", sense: false })
          this.gndSenseConnectors.push({
            name: findVrmComp.name,
            type: "comp",
            compType: "Ignore",
            sense: true,
            pins: [{ net: pin.net, pin: pin.pin, pinName: pin.pinName }]
          })
          this.gndSensePort.push(this.gndSenseConnectors);
          continue;
        }
        //add pmic to sense port list
        this.gndSenseConnectors.push({
          name: findVrmComp.name,
          type: "comp",
          compType: "Ignore",
          sense: false,
          pins: [{ net: pin.net, pin: pin.pin, pinName: pin.pinName }]
        })
        const { isGndSense, nextNetList = [], findSenseAllPath } = this.gndSenseCheck({ net: pin.net, prev: findVrmComp.name });
        if (!isGndSense) {
          continue;
        }
        //find sense and gnd net
        if (findSenseAllPath) {
          this.gndSenseConnectors.filter(item => !item.allPins);
          this.gndSensePort.push(this.gndSenseConnectors.reverse());
          continue;
        }

        //find sense but gnd net not find
        if (nextNetList && nextNetList.length) {
          this.gndSensePortsList = [];
          this.gndSenseLevel = 1;
          this.existCheckNets = [];
          this.getGndSensePins({
            nets: nextNetList,
            prevList: []
          });
          let _sensePorts = [], allSensePins = [];

          for (let i = 0; i < this.gndSenseConnectors.length; i++) {
            //comp: item.name, type:"comp", sense:true,pins:[],allPins:[]
            const item = this.gndSenseConnectors[i];
            if (i < 2) {
              _sensePorts.push(item);
            } else {
              for (let pin of item.allPins) {
                //find current pin connected net and path connect to reference net
                const findConnNets = this.gndSensePortsList.find(it =>
                  it.length && it[0] && it[0].name === pin.net && it[0].type === "net"
                  && it[it.length - 1] && it[it.length - 1].isRef)
                if (!findConnNets || !findConnNets.length) {
                  continue;
                }
                delete findConnNets[findConnNets.length - 1].isRef;
                allSensePins.push([{
                  ...item,
                  pins: item.pins && item.pins.length >= 2 ? item.pins : [...item.pins, pin]
                },
                ...findConnNets
                ])
              }
            }
          }
          if (!allSensePins || !allSensePins.length) {
            continue;
          }
          if (allSensePins.length > 1) {
            allSensePins.sort((a, b) => { return a.length - b.length });
          }
          _sensePorts.push(...allSensePins[0]);
          _sensePorts.forEach(item => delete item.allPins);
          this.gndSensePort.push(_sensePorts.reverse());
        }
      }

      this.gndSensePort = this.filterAndGroupSensePort(this.gndSensePort);
      const groundSensePort = this.gndSensePort && this.gndSensePort.length ? [...this.gndSensePort[0]] : [];
      this.addSenseComponentAndPins([groundSensePort || []]);
      this.sensePorts.push({
        powerSensePort: [...pwrSense],
        groundSensePort: groundSensePort
      })
    }
  }

  filterAndGroupSensePort = (sensePorts) => {
    if (!sensePorts || !sensePorts.length) {
      return;
    }
    //filter repeater sense paths
    let filterSenseList = [];
    for (let item of sensePorts) {
      const findItem = filterSenseList.find(it => _.isEqual(it, item));
      if (!findItem) {
        filterSenseList.push(item);
      }
    }

    //group pmic sense pins
    let newSenseList = [], existSenseGroup = new Map();
    for (let item of filterSenseList) {
      const key = item.map(it => it.name).join("::");
      const findItem = existSenseGroup.get(key);
      const currVRMPins = item[item.length - 1].pins || []
      if (!findItem) {
        existSenseGroup.set(key, { sensePath: JSON.parse(JSON.stringify(item)), VRMPins: currVRMPins })
      } else {
        existSenseGroup.set(key, { sensePath: findItem.sensePath, VRMPins: [...findItem.VRMPins, ...currVRMPins] })
      }
    }

    for (let key of Array.from(existSenseGroup.keys())) {
      const item = existSenseGroup.get(key);
      item.sensePath[item.sensePath.length - 1].pins = item.VRMPins;
      newSenseList.push(item.sensePath);
    }

    sensePorts = newSenseList;
    return sensePorts;
  }

  gndSenseCheck = ({ net }) => {
    const netPinList = getComponentsWithNetList([net], this.pcbId, this.COMP_PREFIX_LIB);
    let isGndSense = false, nextNetList = [], findSenseAllPath = false;
    this.gndSenseConnectors.push({ name: net, type: "net", sense: false });
    for (let netPin of netPinList) {
      //netPin.name: sense component
      /*  if (this.doNotStuff.includes(netPin.name)) {
         continue;
       } */
      //get comp type
      const compType = netPin.type;
      if (![JUMPER, RES].includes(compType) && !netPin.name.match(XWMatch)) {
        continue;
      }
      // Find another net connected to the sense component
      const checkNets = _getNetsListByComp(netPin.name, [netPin.net], this.pcbId);
      if (!checkNets.length) {
        continue;
      }
      isGndSense = netPin;
      const findReference = checkNets.find(it => this.ReferenceNets.includes(it));
      const findSenseComp = this.allComponents.find(item => item.name === netPin.name);
      const gndSensePin = findSenseComp.pins.find(item => item.net === netPin.net);
      let gndPin = null, allPins = [];
      //find reference nets, return sense path
      if (findReference) {
        gndPin = findSenseComp.pins.find(item => item.net === findReference);
        this.gndSenseConnectors.push({
          name: netPin.name,
          type: "comp",
          compType,
          sense: true,
          pins: [
            { net: netPin.net, pin: gndSensePin.pin, pinName: gndSensePin.pinName },
            { net: findReference, pin: gndPin.pin, pinName: gndPin.pinName }
          ].filter(it => !!it)
        });
        this.gndSenseConnectors.push({ name: findReference, type: "net", sense: false })
        findSenseAllPath = true;
        break;
      } else {
        //exist multi nets,continue to find reference net
        gndPin = checkNets.length === 1 ? findSenseComp.pins.find(item => item.net === checkNets[0]) : null;
        nextNetList = [...nextNetList, ...checkNets.map(it => { return { net: it, comp: netPin.name } })];
        allPins = checkNets.map(item => { return findSenseComp.pins.find(it => it.net === item) }).filter(it => !!it);
      }
      const gndNetPin = gndPin ? { net: gndPin.net, pin: gndPin.pin, pinName: gndPin.pinName } : null;
      this.gndSenseConnectors.push({
        name: netPin.name,
        type: "comp",
        compType,
        sense: true,
        pins: [
          { net: netPin.net, pin: gndSensePin.pin, pinName: gndSensePin.pinName },
          gndNetPin
        ].filter(it => !!it),
        allPins: [...allPins.map(it => { return { net: it.net, pin: it.pin, pinName: it.pinName } })]
      });
    }
    return { isGndSense, nextNetList, findSenseAllPath };
  }

  getGndSensePins = ({ nets, prevList }) => {
    let netPinList = [];

    if (this.gndSenseLevel > 5) {
      return;
    }
    this.gndSenseLevel += 1;
    for (let item of nets) {
      if (this.existCheckNets.includes(item.net)) {
        continue
      }
      const compPinList = getComponentsWithNetList([item.net], this.pcbId, this.COMP_PREFIX_LIB);

      //Filter out the net connected to the vrm(pmic) or prev comp
      const _netCompPinList = compPinList.filter(it => it.name !== item.comp);
      netPinList.push(..._netCompPinList);
      this.existCheckNets.push(item.net);
    }

    for (let netPin of netPinList) {
      //netPin.name: sense component
      /* if (this.doNotStuff.includes(netPin.name)) {
        continue;
      } */
      //get comp type
      const compType = netPin.type;
      const typeList = this.isAC ? [RES, JUMPER, FERRITE, SWITCH, IND] : [RES, JUMPER, FERRITE, SWITCH, IND, DIODE];
      if (!typeList.includes(compType) && !netPin.name.match(XWMatch)) {
        continue;
      }
      let newList = [];
      // Find another net connected to the sense component
      let checkNets = _getNetsListByComp(netPin.name, [netPin.net], this.pcbId);
      const findReference = checkNets.find(it => this.ReferenceNets.includes(it));

      checkNets = [...new Set(checkNets)]

      if (findReference) {
        const findSenseComp = this.allComponents.find(item => item.name === netPin.name);
        const gndPin = findSenseComp.pins.find(item => item.net === findReference);
        const gndSensePin = findSenseComp.pins.find(item => item.net === netPin.net);
        if (!gndPin || !gndSensePin) {
          continue;
        }
        this.gndSensePortsList.push([
          ...prevList,
          {
            name: netPin.net,
            type: "net",
            sense: false
          },
          {
            name: netPin.name,
            type: "comp",
            compType,
            sense: false
          },
          {
            name: findReference,
            type: "net",
            sense: false,
            isRef: true
          }])
        continue;
      } else {
        newList = [...prevList, {
          name: netPin.net,
          type: "net",
          sense: false
        }, {
          name: netPin.name,
          type: "comp",
          compType,
          sense: false
        }];
        this.getGndSensePins({
          nets: checkNets.map(it => { return { net: it, comp: netPin.name } }),
          prevList: newList
        });
      }
    }
  }

  AutoFindVRM = () => {
    const { ReferenceNets, PowerNets, connectNets, isAC, powerSwitch, doNotStuff } = this;
    const VRMInfo = this.findVRMInfo;
    VRMInfo.NET_LIST = this.getLayoutNetManager();
    VRMInfo.PART_INFO = this.getLayoutPartManager();
    VRMInfo.filterNets = [...ReferenceNets, ...PowerNets];
    // VRMInfo.debugMonitor.push(`==> Tracing VRM component for \n${SpaceFour}${PowerNets.join(', ')}\n`)
    const _connectNets = connectNets && Object.values(connectNets).length > 0 && !isAC ? connectNets : getCascadeNetsListByComps(this._findVRMComps, VRMInfo.filterNets, this.pcbId, this.PMIC, PowerNets, this.specify);
    const filterComps = Object.keys(_connectNets);
    const powerNetsInfo = findNetListByNetName(PowerNets, this.pcbId);
    if (filterComps.length > 0) {
      for (let compName of filterComps) {
        /*   if (doNotStuff.includes(compName)) {
            continue;
          } */
        // net find comp
        let beginComp = this._findVRMComps.find(item => item.name === compName);
        const compType = beginComp.usage;
        if (compType === SWITCH) {
          beginComp.pins = this.SwitchPinCheck({ name: beginComp.name, pins: beginComp.pins, prevNets: PowerNets, pinKey: 'pin', nameKey: 'pinName' })
          if (!beginComp.pins.length) {
            continue;
          }
        }
        const netsA = _connectNets[compName];
        if (!netsA) {
          continue;
        }
        const BeginNetsName = [...new Set(beginComp.pins.map(pin => pin.net).filter(it => !ReferenceNets.includes(it)))];

        const beginConnInfo = [...BeginNetsName.map(name => ({ type: 'net', name })), { type: 'comp', name: compName, compType }];
        const connectsCompNetInfo = BeginNetsName.map(net => ({ net: net, comp: compName, level: 1 }));

        const components = getComponentsByNets({ netList: netsA, pcbId: this.pcbId, COMP_PREFIX_LIB: this.COMP_PREFIX_LIB, getLayoutComponents: this.getLayoutComponents, layers: {}, powerSwitch });
        const netsAInfo = netsA.map(net => VRMInfo.NET_LIST.getValue(net)); // Filtered
        if (!doNotStuff.includes(compName) && this.VRMCheck([beginComp], powerNetsInfo, false)) {
          const nets = [...new Set(beginComp.pinList.map(item => item.net))].filter(item => PowerNets.includes(item));
          this.indCheck({
            prevConnInfo: [],
            connectsCompNetInfo: [],
            comps: [{ ...beginComp, net: nets.length ? nets[0] : '' }],
            netsInfo: powerNetsInfo,
            compName,
            eqPowerPinComp: beginComp,
            level: 1,
            fromInd: false
          })

          if (this.isTemplate && this.senseVRMCheck([beginComp], powerNetsInfo)) {
            this.senseCheck({
              prevConnInfo: [],
              connectsCompNetInfo: [],
              comps: [{ ...beginComp, net: nets.length ? nets[0] : '' }],
              netsInfo: powerNetsInfo,
              compName,
              eqPowerPinComp: beginComp,
              level: 1,
              isVrmSense: true
            })
          }
        } else {
          this.findVRMFilterComps({
            compType,
            compsList: [],
            filterCompName: compName,
            comps: components,
            nets: netsA,
            level: 1,
            eqPowerPinComp: beginComp,
            netsInfo: netsAInfo,
            prevConnInfo: beginConnInfo,
            connectsCompNetInfo,
            compName,
            prevNets: netsA
          })
        }
      }
    }
    if (this.specify.length) {
      this.filterNetsBySpecify(VRMInfo);
    }
    //Filter vrm by sign-off template pmic components and pmic connector net
    if (this.pmicAndNets && this.pmicAndNets.length) {
      this.filterVRMBySignOffTemplatePmicNets(VRMInfo);
    }
    if (VRMInfo.debugMonitor && VRMInfo.debugMonitor.length && VRMInfo.debugMonitor.length > 1) {
      // Keep the path have Ind, if all path without Ind, keep all.
      this.filterNetsByInd(VRMInfo);
    }
    // If have discrete buck converter, Keep only discrete buck converter.
    this.filterNetsByBuckConverter(VRMInfo);
    // Filter nets by connect VRM symbol
    this.filterNetsBySymbol(VRMInfo);
    // Remove power nets, filterNets - power nets
    const _level = VRMInfo.VRMComps.reduce((prev, curr) => {
      return curr.level < prev ? curr.level : prev;
    }, Infinity)
    let _vrmComps = VRMInfo.VRMComps.filter(d => d.level === _level);
    let filterPowerNets = VRMInfo.allExtendedNets.filter(item => item.level === _level && !VRMInfo.filterNets.includes(item.net)).map(d => d.net);
    let _extendedNets = [...new Set(filterPowerNets)];
    let vrmNames = [...new Set(_vrmComps.map(item => item.vrm))];
    let pahtMinLength = {}
    // has extendedNets
    if (_extendedNets.length && this.findExtend && this.isAC) {
      VRMInfo.debugMonitor = VRMInfo.debugMonitor.filter(topologys => {
        let vrmNames = [...new Set(_vrmComps.map(item => item.vrm))];
        //Reserve the path from power net directly to vrm
        if (topologys.length === 2 && vrmNames.includes(topologys[1].name)) {
          return true;
        }
        return topologys.map(item => item.name).some(item => _extendedNets.includes(item));
      })
    }
    filterPowerNets = VRMInfo.findExtendedNets.filter(item => item.level === _level && !VRMInfo.filterNets.includes(item.net)).map(d => d.net);
    _extendedNets = [...new Set(filterPowerNets)];
    let Topologys = VRMInfo.debugMonitor.filter(topologys => {
      const vrmName = topologys[topologys.length - 1].name;
      if (pahtMinLength[vrmName]) {
        pahtMinLength[vrmName] = pahtMinLength[vrmName] > topologys.length ? topologys.length : pahtMinLength[vrmName];
      } else {
        pahtMinLength[vrmName] = topologys.length;
      }
      return vrmNames.includes(vrmName) ? true : false;
    });
    this.DEBUG_MONITOR = Topologys.filter(item => {
      const vrmName = item[item.length - 1].name;
      return item.find(it => !!it.isTemplate) ? true : pahtMinLength[vrmName] === item.length;
    })
    _extendedNets = _extendedNets.filter(net => this.DEBUG_MONITOR.find(item => item.find(it => it.type === "net" && it.name === net)));
    this.eqPowerPin = _vrmComps;
    this.extendedNets = _extendedNets;
  }

  indCheck = (options) => {
    // filter chip components and find ignore components
    const isVRMs = this.chipFilter(options.comps, this.chips);
    const isTransistor = this.transistorFilter(options.comps, isVRMs);
    const isSpecify = this.specifyFilter(options.comps, this.specify, isVRMs);
    // Determine if the pinName of components matches
    this.findVRM({ isVRMs: [...isVRMs, ...isTransistor, ...isSpecify], ...options });
  }

  findVRM = (options) => {
    // Determine if the pinName of components matches
    // vrm - comp { name, net, type, part, pinList }
    let VRMs = [];
    const { isVRMs, netsInfo } = options;
    //filter VRMs by pin name length
    // VRMs : pin name length >= 6 and pin name length <= 200
    for (let vrm of isVRMs) {
      //filter vrm by pin name length
      if (this.PMIC.map(pmic => pmic.partName).includes(vrm.part)) {
        const part = this.PMIC.find(item => item.partName === vrm.part);
        if (part.comps && !part.comps.includes(vrm.name)) {
          continue;
        }
        const vrmPinCheck = this.VRMPinCheck(vrm, netsInfo);
        if (vrmPinCheck) {
          VRMs.push(vrm);
        } else if (!VRMs.length && isVRMs.length - 1 === isVRMs.findIndex(v => v.name === vrm.name)) {
          VRMs.push(vrm);
        }
      } else if (this.specify.find(item => !this.ReferenceNets.includes(vrm.net) && item.name === vrm.name && (item.nets && (!item.nets.length || item.nets.includes(vrm.net))))) {
        VRMs.push(vrm);
      }
      else if (vrm.type === TRANSISTOR) {
        const _vrm = this.transistorCheck(vrm);
        _vrm && VRMs.push(_vrm)
      } else {
        continue;
      }
    }
    //Find VRM by VRMs with pin name length >= 5 && <= 24
    this._findVRM({ ...options, VRMs });
  }

  _findVRM = ({
    prevConnInfo,
    netsInfo,
    connectsCompNetInfo,
    eqPowerPinComp,
    level,
    compName,
    VRMs,
    fromInd
  }) => {
    const { connectInductance, pcbId, specify } = this;
    const { VRMComps } = this.findVRMInfo;
    const VRMInfo = this.findVRMInfo;
    for (let vrm of VRMs) {
      // pin name
      const pinNames = vrm.pinList.map(item => item.mName);

      // Rule A - pin > 5 - Deprecated

      const findNetInfo = netsInfo.find(it => it.mName === vrm.net);
      let compConnectNetPins = [];
      if (findNetInfo) {
        // Rule C I  - multiple pins
        compConnectNetPins = findNetInfo.mPinList.filter(item => item.mCompName === vrm.name).map(item => item.mPinNum);
      }
      if (!compConnectNetPins.length) { continue; }

      // Rule D - notReasonableDistance(PART_INFO, eqPowerPinComp, vrm)
      // if (fromInd && notReasonableDistance(PART_INFO, eqPowerPinComp, vrm)) {
      //   continue;
      // }

      // Rule B
      const ruleB = pinNames.some(name => matchPinName(name, VRM_CHARACTERISTIC));

      let ruleCI = false, ruleCII = false;
      if (compConnectNetPins.length >= 2) {
        ruleCI = true;
      } else if (compConnectNetPins.length === 1) {
        // There are multiple pins connected to the same net
        const checkNets = _getNetsListByComp(vrm.name, [], pcbId);
        const setCheckNets = [...new Set(checkNets)];
        if (checkNets.length !== setCheckNets.length) {
          ruleCII = true;
        };
      }

      // ruleS
      let ruleS = false;
      const specifyNames = specify.map(item => item.name)
      ruleS = specifyNames.includes(vrm.name)

      // Rule E
      let ruleE = !this.ExistNets.length || this.ExistNets.includes(vrm.net) ? true : false;
      if ((!fromInd || ruleCI || ruleCII || ruleB || ruleS) && ruleE) {
        const connectInd = eqPowerPinComp.type === IND || eqPowerPinComp.usage === IND ? true : false;
        const inductance = connectInd ? eqPowerPinComp.name : vrm.name;
        VRMComps.push({ name: compName, vrm: vrm.name, connectsCompNetInfo, level, inductance, title: vrm.title })
        // const _log = `${SpaceFour}* VRM${VRMCompsName.length} - ${vrm.name}\n${prevConnInfo}\t[Net] ${vrm.net} -> [Dev] ${vrm.name} \n`;
        const vrmInfo = this.allComponents.find(comp => comp.name === vrm.name);
        const currentPins = vrmInfo ? vrmInfo.pins.filter(item => item.net === vrm.net) : []
        const Info = [...prevConnInfo, { type: 'net', name: vrm.net }, { type: 'comp', name: vrm.name, title: vrm.type === TRANSISTOR ? vrm.title : vrm.name, pins: currentPins }];
        VRMInfo.debugMonitor.push(Info)

        // extendedNets = extendedNets + power nets, There will be duplicate nets
        const extendedNets = connectsCompNetInfo.map(item => ({ net: item.net, level }));
        if (connectInductance || (this.findExtend && !fromInd)) {
          // extendedNets includes transition net
          VRMInfo.findExtendedNets.push(...extendedNets, { net: vrm.net, level: level });
        } else {
          VRMInfo.findExtendedNets.push(...extendedNets);
        }
        VRMInfo.allExtendedNets.push(...extendedNets, { net: vrm.net, level: level })
      }
      // else {
      //   const log = `${SpaceFour}* Unrecognized VRM device - ${vrm.name}\n${prevConnInfo}\t[Net] ${vrm.net} -> [Dev] ${vrm.name} \n`;
      //   const Info = [...prevConnInfo, { type: 'net', name: vrm.net }, { type: 'comp', name: vrm.name }];
      //   VRMInfo.debugMonitor.push(Info);
      // }
    }
    return VRMComps;
  }

  autoFindVRMGnd = ({ findCaps }) => {
    const { pcbId, eqPowerPin, getLayoutComponents, isAC, findExtend, updateComponents, PowerNets, ReferenceNets, COMP_PREFIX_LIB } = this;
    const layers = {};

    if (!allCompsInfo[pcbId]) {
      allCompsInfo[pcbId] = getLayoutComponents({ pcbId, layers }); // { Comp: { name, part, value, type} }
    };

    let CompsInfo = allCompsInfo[pcbId];
    const allComp = this.getAllComponents({ pcbId, COMP_PREFIX_LIB });
    // this.DEBUG_MONITOR.push('\t==> Automatically find Eq. Gnd Pin.\n');
    let comps = isAC && findExtend ? eqPowerPin.map(comp => ({ pwr: comp.inductance, gnd: null, vrmComp: comp.vrm, inductance: comp.inductance, title: comp.title }))
      : eqPowerPin.map(comp => ({ pwr: comp.name, gnd: null, vrmComp: comp.vrm, inductance: comp.inductance, title: comp.title }));
    for (let item of comps) {
      if (item.pwr === item.vrmComp) {
        const currentComp = allComp.find(c => c.name === item.vrmComp);
        if (currentComp && currentComp.pins.some(c => ReferenceNets.includes(c.net))) {
          item.gnd = item.vrmComp;
          continue;
        }
      }
      let pwrLocation = [{ x: CompsInfo[item.pwr].location.mX, y: CompsInfo[item.pwr].location.mY }]
      const pwrCompInfo = updateComponents.find(comp => comp.name === item.pwr);
      //filter pins by powerNets
      //findExtended === false : _PowerNets only includes main power nets
      //findExtended === true : _PowerNets includes main power nets and extended nets
      if (pwrCompInfo) {
        const pwrPins = pwrCompInfo.pins.filter(item => item.pin && PowerNets.includes(item.net)).map(item => item.pin);
        const db = LayoutData.getLayout(pcbId);
        const vrmComponent = this.allComponents.find(it => it.name === item.vrmComp)
        const vrmPart = vrmComponent && vrmComponent.part
        const partPinMap = this.pinMapSense && this.pinMapSense.get(vrmPart)
        const currentSensePin = (partPinMap && partPinMap.map(item => item.sense).flat().filter(item => !!item)) || []

        const compPins = getComponentPinLocation({
          scaling: 1,
          component: db.getComponent(pwrCompInfo.name),
          compName: pwrCompInfo.comp,
          mPartMgr: db.mPartMgr,
          savePad: true
        }).filter(item => pwrPins.includes(item.number) && ![...currentSensePin, ...VRM_NOT_CURRENT_PIN].includes(item.name))
        if (compPins.length) {
          pwrLocation = compPins.map(item => { return { x: item.x, y: item.y } })
        }
      }

      let vrmComponents = [];
      if (Array.isArray(item.vrmComp)) {
        item.vrmComp.forEach(it => {
          vrmComponents.push(CompsInfo[it])
        })
      } else {
        vrmComponents = CompsInfo[item.vrmComp]
      }

      const referenceComps = findCaps.concat(vrmComponents).filter(cap => cap.COMP_TYPE === 'Cap');
      const layer = CompsInfo[item.pwr].layer;
      //find Eq.Gnd pin of same layer
      item.gnd = getGndPinByLocationAndLayer({
        referenceComps,
        layer,
        CompsInfo,
        pwrLocation,
        isSameLayer: true,
        ReferenceNets,
        pcbId
      });
      if (!item.gnd) {
        item.gnd = getGndPinByLocationAndLayer({
          referenceComps,
          layer,
          CompsInfo,
          pwrLocation,
          isSameLayer: false,
          ReferenceNets,
          pcbId
        })
      }
      // this.DEBUG_MONITOR.push(`\t${SpaceFour}Eq. Pwr Pin: ${item.pwr}, Eq. Gnd Pin: ${item.gnd}.\n`);
    }
    // this.DEBUG_MONITOR.push(`\n`);
    this.vrms = comps
  }

  chipFilter = (comps, chips) => {
    return comps.filter(item => !chips.includes(item.name));
  }

  transistorFilter = (comps, isVRMs) => {
    let isTransistor = comps.filter(c => c.type === TRANSISTOR);
    isTransistor = isTransistor.filter(trans => this.buckConverter.some(buck => buck.includes(trans.name)));
    isTransistor = isTransistor.filter(tran => !isVRMs.some(vrm => vrm.name === tran.name))
    return isTransistor;
  }

  specifyFilter = (comps, specify, isVRMs = []) => {
    return comps.filter(item => specify.find(sp =>
      sp.name === item.name &&
      !isVRMs.find(vrm => vrm.name === item.name) &&
      (sp.nets && (!sp.nets.length || sp.nets.includes(item.net) || sp.nets.some(n => Array.isArray(item.net) ? item.net.includes(n) : false))))
    );
  }

  VRMCheck = (comps, netsInfo, fromInd) => {
    const PMICRule = fromInd ? [SWITCHING, LINEAR_SWITCH, SPECIALIZED] : [LINEAR, SPECIALIZED, LINEAR_SWITCH];
    const PMICFilter = this.PMIC.filter(item => PMICRule.includes(item.type)).map(pmic => pmic.partName);
    let isVRMs = this.chipFilter(comps, this.chips).filter(item => PMICFilter.includes(item.part));
    isVRMs = isVRMs.filter(item => this.VRMPinCheck(item, netsInfo));

    const isTransistor = this.transistorFilter(comps, isVRMs);

    const isSpecify = this.specifyFilter(comps, this.specify, isVRMs);
    return [...isVRMs, ...isTransistor, ...isSpecify].length > 0 ? true : false;
  }

  senseVRMCheck = (comps, netsInfo) => {
    const PMICRule = [SWITCHING, LINEAR_SWITCH, SPECIALIZED, LINEAR];
    const PMICFilter = this.PMIC.filter(item => PMICRule.includes(item.type)).map(pmic => pmic.partName);
    let isVRMs = this.chipFilter(comps, this.chips).filter(item => PMICFilter.includes(item.part));
    isVRMs = isVRMs.filter(item => this.sensePinCheck(item, netsInfo));
    return [...isVRMs].length > 0 ? true : false;
  }

  sensePinCheck = (vrm, netsInfo) => {
    const { net, name, pinList } = vrm;
    let _net = Array.isArray(net) ? net : [net];
    const currentNets = netsInfo.find(item => _net.includes(item.mName));
    if (!currentNets) {
      return false;
    }
    const { mPinList } = currentNets;
    let _pinList = mPinList.filter(item => item.mCompName === name).map(item => item.mPinNum);
    // get connect pin name
    let connectPinName = pinList.filter(item => _pinList.includes(item.mNumber) || _pinList.includes(item.pin)).map(item => item.mName);
    if (connectPinName.length) {
      // get sensePin
      const partPinMap = this.pinMapSense && this.pinMapSense.get(vrm.part)
      const currentSensePin = (partPinMap && partPinMap.map(item => item.sense).flat().filter(item => !!item)) || []
      const currentPin = connectPinName.filter(pinName => currentSensePin.some(sense => pinName.toUpperCase().includes(sense)));
      //const unknowPin = connectPinName.filter(pinName => !([...currentSensePin, ...VRM_CHARACTERISTIC].some(not => pinName.toUpperCase().includes(not))))
      if (currentPin.length) {
        return true;
      }
    }
    return false;
  }

  SwitchPinCheck = ({ name, pins, prevNets, pinKey = 'pin', nameKey = 'pinName' }) => {
    const { pinConnection, allComponents } = this;
    let comp = allComponents.find(item => item.name === name);
    if (!comp) {
      return pins;
    }
    const prevPins = comp.pins.filter(p => prevNets.includes(p.net)).map(p => p.pin);
    const connection = pinConnection.find(p => p.partNumber === comp.part) || { pinMap: [] };
    const pinNumbers = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1] && (prevPins.includes(map[0]) || prevPins.includes(map[1]))).flat(2) : [];
    return pinNumbers.length ? pins.filter(pin => pinNumbers.includes(pin[pinKey]))
      : pins.filter(pin => {
        for (let rule of SWITCH_CHARA) {
          if (pin[nameKey].toUpperCase().includes(rule)) {
            return true;
          }
        }
        return false;
      })
  }

  VRMPinCheck = (vrm, netsInfo) => {
    const { net, name, pinList, part } = vrm;
    let _net = Array.isArray(net) ? net : [net];
    const currentNets = netsInfo.find(item => _net.includes(item.mName));
    if (!currentNets) {
      return false;
    }
    const { mPinList } = currentNets;
    let _pinList = mPinList.filter(item => item.mCompName === name).map(item => item.mPinNum);
    // get connect pin name
    let connectPinName = pinList.filter(item => _pinList.includes(item.mNumber) || _pinList.includes(item.pin)).map(item => item.mName);
    const pinJudge = (RuleList, pinName) => {
      for (let pinRule of RuleList) {
        if (pinName.includes(pinRule)) {
          return true;
        }
      }
      return false
    }

    const currentPinMap = this.pinMap.find(item => item.partNumber === part);
    if (currentPinMap) {
      if (currentPinMap.data && currentPinMap.data.length) {
        const output = currentPinMap.data.map(item => item.output).flat(2);
        if (output.some(item => connectPinName.includes(item))) {
          return true;
        }
        return false
      }
    }

    if (connectPinName.length) {
      const partPinMap = this.pinMapSense && this.pinMapSense.get(part)
      const currentSensePin = (partPinMap && partPinMap.map(item => item.sense).flat().filter(item => !!item)) || []
      const notCurrentPin = connectPinName.filter(pinName => pinJudge([...currentSensePin, ...VRM_SENSE_PIN, ...VRM_NOT_CURRENT_PIN], pinName));
      const currentPin = connectPinName.filter(pinName => !notCurrentPin.includes(pinName) && pinJudge(VRM_CHARACTERISTIC, pinName));
      const unknowPin = connectPinName.filter(pinName => !(pinJudge([...currentSensePin, ...VRM_SENSE_PIN, ...VRM_NOT_CURRENT_PIN], pinName) || pinJudge(VRM_CHARACTERISTIC, pinName)));
      if (!notCurrentPin.length || currentPin.length || (unknowPin && unknowPin.length)) {
        return true;
      }
    }
    return false;
  }

  findVRMFilterComps = (options) => {
    const { pcbId, COMP_PREFIX_LIB, getLayoutComponents, powerSwitch, doNotStuff, isAC, pinConnection } = this;
    const { compType, compsList, filterCompName, comps, nets, level, eqPowerPinComp, netsInfo, prevConnInfo, connectsCompNetInfo, compName, prevNets } = options
    const { NET_LIST, filterNets } = this.findVRMInfo;

    const userSetting = userDefaultSettings.getLocalSetting();
    const { cascadeSettings = {} } = userSetting;
    const { impedanceLevel = 5 } = cascadeSettings;

    // filter Switch pin
    comps.forEach(comp => {
      if (comp.type === SWITCH) {
        comp.pinList = this.SwitchPinCheck({ name: comp.name, pins: comp.pinList, prevNets, pinKey: 'mNumber', nameKey: 'mName' })
      }
    })
    const typeList = isAC ? [RES, JUMPER, FERRITE, SWITCH, IND] : [RES, JUMPER, FERRITE, SWITCH, IND, DIODE];
    const includeStuff = !doNotStuff.includes(filterCompName);
    if ((typeList.includes(compType) || filterCompName.match(XWMatch)) && impedanceLevel >= level) {

      const fromInd = compType === IND ? true : false;
      if (includeStuff && this.VRMCheck(comps, netsInfo, fromInd)) {
        let connInfo = [], _connectsCompNetInfo = [];
        if (compsList.length > 0) {
          compsList.forEach((item, index) => {
            const connAInfo = [{ type: 'net', name: item.net }, { type: 'comp', name: item.name, compType: item.type }];
            connInfo.push(...connAInfo);
            _connectsCompNetInfo.push({ net: item.net, comp: item.name, level: index + 2 });
          })
        }
        const new_prevConnInfo = [...prevConnInfo, ...connInfo];
        const new_connectsCompNetInfo = [...connectsCompNetInfo, ..._connectsCompNetInfo];
        this.indCheck({
          prevConnInfo: new_prevConnInfo,
          connectsCompNetInfo: new_connectsCompNetInfo,
          comps,
          netsInfo,
          compName,
          eqPowerPinComp,
          level,
          fromInd: this.isAC ? fromInd : false,
        })
      } else if (includeStuff) {

        if (([RES, JUMPER].includes(compType) || filterCompName.match(XWMatch)) && this.senseVRMCheck(comps, netsInfo)) {
          let connInfo = [], _connectsCompNetInfo = [];
          if (compsList.length > 0) {
            compsList.forEach((item, index) => {
              const connAInfo = [{ type: 'net', name: item.net }, { type: 'comp', name: item.name, compType: item.type }];
              connInfo.push(...connAInfo);
              _connectsCompNetInfo.push({ net: item.net, comp: item.name, level: index + 2 });
            })
          }
          const new_prevConnInfo = [...prevConnInfo, ...connInfo];
          const new_connectsCompNetInfo = [...connectsCompNetInfo, ..._connectsCompNetInfo];
          this.senseCheck({
            prevConnInfo: new_prevConnInfo,
            connectsCompNetInfo: new_connectsCompNetInfo,
            comps,
            netsInfo,
            compName,
            eqPowerPinComp,
            level,
          })
        }

        const _compsRes = comps.filter(comp => comp.name !== filterCompName && (typeList.includes(comp.type) || comp.name.match(XWMatch)));
        for (let _compR of _compsRes) {
          let _nets = _getNetsListByComp(_compR.name, [...filterNets, ...nets], pcbId, 'net');
          if (_compR.type === SWITCH || (_compR.type === RES && _compR.pinList.length > 2)) {
            // find pin in Switch is OUT
            const currentNet = netsInfo.find(net => net.mName === _compR.net);
            const connection = pinConnection.find(p => p.partNumber === _compR.part) || { pinMap: [] };
            const _pins = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1]).flat(2) : [];
            if (!currentNet) { continue; }
            const compPinNames = {};
            _compR.pinList.forEach(pin => {
              if (pin.mNumber) {
                compPinNames[pin.mNumber] = pin.mName
              } else if (pin.pin) {
                compPinNames[pin.pin] = pin.mName
              }
            });
            let netPins = currentNet.mPinList.filter(p => p.mCompName === _compR.name).filter(p => !_pins.length || _pins.includes(p.mPinNum))
            if (!_pins.length) {
              netPins = netPins.filter(p => compPinNames[p.mPinNum] && (!compPinNames[p.mPinNum].toUpperCase().match(/(IN)|(EN)|(ON)|(GND)/g) || Number(compPinNames[p.mPinNum])));
            }
            if (!netPins.length) { continue; };
            const netPinNumbers = netPins.map(item => item.mPinNum);

            // filter net by switch pin
            let pinNumbers = connection.pinMap ? connection.pinMap.filter(map => map && map[0] && map[1] && map.some(pin => netPinNumbers.includes(pin))).flat(2) : []
            if (!pinNumbers.length) {
              pinNumbers = _compR.pinList.map(pin => { return pin.mNumber || pin.pin });
            }
            _nets = _nets.filter(net => {
              const { mPinList } = net;
              let _pins = mPinList.filter(p => p.mCompName === _compR.name).map(p => p.mPinNum);
              if (_pins.some(p => pinNumbers.includes(p))) {
                return true;
              }
              return false;
            }).map(net => net.mName);
            _nets = [...new Set(_nets)]
          } else {
            _nets = _nets.map(net => net.mName)
          }

          const _components = getComponentsByNets({ netList: _nets, pcbId, COMP_PREFIX_LIB, layers: {}, getLayoutComponents, powerSwitch })
          const netsCInfo = _nets.map(net => NET_LIST.getValue(net));
          if (!_nets) {
            continue;
          };
          const new_compsList = [...compsList, _compR];
          const new_nets = [...nets, ..._nets];
          this.findVRMFilterComps({
            ...options,
            compType: _compR.type,
            eqPowerPinComp: _compR,
            level: level + 1,
            netsInfo: netsCInfo,
            comps: _components,
            compsList: new_compsList,
            nets: new_nets,
            prevNets: _nets,
            filterCompName: _compR.name,
          })
        }
      }
    }
  }

  getLayoutNetManager = () => {
    const layout = LayoutData.getLayout(this.pcbId);
    if (layout && layout.mNetManager) {
      return layout.mNetManager.mNetList;
    } else {
      return [];
    }
  }
  getLayoutPartManager = () => {
    const layout = LayoutData.getLayout(this.pcbId);
    if (layout) {
      return layout.mPartMgr;
    } else {
      return null;
    }
  }

  getUpdateComponents = (newPowerComponents) => {
    this.updateComponents = newPowerComponents.map(d => {
      const findInComponents = this.Components.find(c => c.name === d.name);
      if (findInComponents) {
        // Unused or Removed Component
        if (['Unused', 'Removed'].includes(findInComponents.usage)) {
          d.usage = findInComponents.usage;
        }
        if (findInComponents.model !== undefined) {
          d.model = findInComponents.model;
        };
        if (findInComponents.value !== undefined) {
          d.value = findInComponents.value;
        };
        if (findInComponents.pkg !== undefined) {
          d.pkg = findInComponents.pkg;
        };
        if (findInComponents.die !== undefined) {
          d.die = findInComponents.die;
        }
      } else {
        const findValueInSamePart = this.Components.find(comp => comp.part === d.part);
        if (findValueInSamePart) {
          if (d.value !== undefined && findValueInSamePart.value !== undefined) {
            d.value = findValueInSamePart.value;
          };
          if (d.model !== undefined && findValueInSamePart.model !== undefined) {
            d.model = findValueInSamePart.model;
          };
        }
      };
      return d;
    });
  }

  filterVRMBySignOffTemplatePmicNets = (vrmInfo) => {
    const pmicList = this.pmicAndNets.map(item => item.pmic);
    const netList = this.pmicAndNets.map(item => item.net);
    const prevDebugMonitor = JSON.parse(JSON.stringify(vrmInfo.debugMonitor));
    const newDebugMonitor = vrmInfo.debugMonitor.filter(item => {
      if (!item || !item.length) {
        return false;
      }
      if (!(item[item.length - 1].type === "comp" && pmicList.includes(item[item.length - 1].name))) {
        return false;
      }
      if (!(item.find(it => it.type === "net" && netList.includes(it.name)))) {
        return false;
      }
      if (this.pmicAndNets.find(it => item[item.length - 1].name === it.pmic && item[item.length - 2].name)) {
        return true;
      }
      return false;
    })

    if (!newDebugMonitor.length) {
      const newDebugMonitor = vrmInfo.debugMonitor.filter(item => item[item.length - 1].type === "comp" && pmicList.includes(item[item.length - 1].name))
      if (!newDebugMonitor.length) return;
    }
    newDebugMonitor.forEach(item => {
      item.forEach(it => { it.isTemplate = true })
    })
    this.filterVRMfromInfo(vrmInfo, newDebugMonitor, prevDebugMonitor.filter(item => !newDebugMonitor.find(it => _.isEqual(it, item))))
  }

  filterNetsBySymbol = (vrmInfo) => {
    const { debugMonitor } = vrmInfo;
    let newMonitor = [], filterItem = [];
    let vrms = debugMonitor.map(monitor => monitor[monitor.length - 1].name);
    vrms = [...new Set(vrms)];
    const db = LayoutData.getLayout(this.pcbId);
    for (let vrm of vrms) {
      const _filter = [];
      let currentDebugs = debugMonitor.filter(monitor => monitor[monitor.length - 1].name === vrm);
      if (currentDebugs.length > 1) {
        const compPins = getComponentPinLocation({
          scaling: 1,
          component: db.getComponent(vrm),
          compName: vrm,
          mPartMgr: db.mPartMgr,
          savePad: true
        })

        const _currentDebugs = currentDebugs.filter(monitor => {
          const net = monitor[monitor.length - 2].name;
          const netInfo = findNetListByNetName([net], this.pcbId);
          if (!netInfo || !netInfo.length || !netInfo[0]) {
            _filter.push(...monitor.map(item => item.name));
            return false;
          }

          const netGemoLocation = getNetGeomLocation(netInfo);
          const pins = netInfo[0].mPinList.filter(pin => pin.mCompName === vrm).map(pin => pin.mPinNum)
          const pinsInfo = compPins.filter(pin => pins.includes(pin.number));
          for (let aPinInfo of pinsInfo) {
            const closeLoc = findMinDistance(aPinInfo, netGemoLocation);
            if (closeLoc && closeLoc.type === 'Polygon') {
              return true;
            }
          }
          _filter.push(...monitor.map(item => item.name));
          return false;
        })
        if (_currentDebugs.length) {
          newMonitor.push(..._currentDebugs);
          filterItem.push(..._filter)
        } else {
          const __filter = [];
          const _currentDebugs = currentDebugs.filter(monitor => {
            const net = monitor[monitor.length - 2].name;
            const netInfo = findNetListByNetName([net], this.pcbId);
            if (!netInfo || !netInfo.length || !netInfo[0]) {
              __filter.push(...monitor.map(item => item.name));
              return false;
            }

            const netGemoLocation = getNetGeomLocation(netInfo);
            if (!netGemoLocation.filter(item => item.type !== 'Line').length) {
              __filter.push(...monitor.map(item => item.name));
              return false;
            }
            return true;
          })
          if (_currentDebugs.length) {
            newMonitor.push(..._currentDebugs);
            filterItem.push(...__filter)
          } else {
            newMonitor.push(...currentDebugs);
          }
        }
      } else {
        newMonitor.push(...currentDebugs)
      }
    }
    filterItem = [...new Set(filterItem)];
    newMonitor.forEach(item => {
      item.forEach(m => {
        const findIndex = filterItem.findIndex(f => f === m.name);
        if (findIndex > -1) {
          filterItem.splice(findIndex, 1)
        }
      })
    })
    vrmInfo.debugMonitor = newMonitor;
    vrmInfo.VRMComps = vrmInfo.VRMComps.filter(comp => !filterItem.includes(comp.name));
    vrmInfo.allExtendedNets = vrmInfo.allExtendedNets.filter(item => !filterItem.includes(item.net));
    vrmInfo.findExtendedNets = vrmInfo.findExtendedNets.filter(item => !filterItem.includes(item.net));
  }

  filterNetsByInd = (vrmInfo) => {
    const { debugMonitor } = vrmInfo;
    const rule = (array) => {
      const items = array.filter(i => i.type === 'comp').map(i => i.compType);

      return items.includes(IND) ? true : false
    }

    const { trueArray: indArray, falseArray: otherArray } = splitArrayToArrays({ array: debugMonitor, rule });
    if (indArray.length) {
      this.filterVRMfromInfo(vrmInfo, indArray, otherArray, true)
    }
  }

  filterNetsBySpecify = (vrmInfo) => {
    const { debugMonitor } = vrmInfo;
    const { specify } = this;

    const rule = (array) => {
      const vrm = array[array.length - 1];
      const net = array[array.length - 2];
      if (vrm.type !== 'comp' || net.type !== 'net') {
        return false;
      }
      return specify.some(sp => sp.name === vrm.name && sp.nets.includes(net.name) && (!sp.pins || !sp.pins.length || sp.pins.some(pin => vrm.pins.find(_pin => _pin.pin === pin)))) ? true : false
    }

    const { trueArray: specifyArray, falseArray: otherArray } = splitArrayToArrays({ array: debugMonitor, rule });
    if (specifyArray.length) {
      const _specifyArray = specifyArray.map(array => {
        const _array = [...array];
        const vrm = _array[_array.length - 1];
        const net = _array[_array.length - 2];
        if (vrm.type === 'comp' || net.type === 'net') {
          const sp = specify.find(s => s.name === vrm.name && s.nets.includes(net.name));
          if (sp && sp.pins && sp.pins.length) {
            _array[_array.length - 1].pins = _array[_array.length - 1].pins.filter(pin => sp.pins.includes(pin.pin))
          }
        }
        return _array
      })
      this.filterVRMfromInfo(vrmInfo, _specifyArray, otherArray);
    }
  }

  filterNetsByBuckConverter = (vrmInfo) => {
    const { debugMonitor } = vrmInfo;
    const rule = (array) => {
      const vrm = array[array.length - 1];

      return this.buckConverter.find(buck => buck.includes(vrm.name)) ? true : false
    }

    const { trueArray: converterArray, falseArray: otherArray } = splitArrayToArrays({ array: debugMonitor, rule });
    if (converterArray.length) {
      this.filterVRMfromInfo(vrmInfo, converterArray, otherArray)
    }
  }

  filterVRMfromInfo = (vrmInfo, keepArray, otherArray, indCheck = false) => {
    let filterItem = otherArray.flat(2).map(item => item.name);
    filterItem = [...new Set(filterItem)];
    let notFilter = keepArray.flat(2).map(item => item.name);
    let mustFilterItem = filterItem.filter(item => !notFilter.includes(item));
    const indLevel = keepArray.map(item => (item.length / 2) - 1);
    //set level 0 to 1
    let filterLevel = [...new Set(otherArray.map(item => (item.length / 2) - 1).filter(item => !indLevel.includes(item)))].map(item => item <= 0 ? 1 : item);
    vrmInfo.debugMonitor = keepArray;
    vrmInfo.VRMComps = vrmInfo.VRMComps.filter(comp => !(mustFilterItem.includes(comp.vrm) || mustFilterItem.includes(comp.name))
      && (!indCheck || (indCheck && indLevel.includes(comp.level))));
    vrmInfo.allExtendedNets = vrmInfo.allExtendedNets.filter(item => !(filterItem.includes(item.net) && filterLevel.includes(item.level)) && !mustFilterItem.includes(item.net));
    vrmInfo.findExtendedNets = vrmInfo.findExtendedNets.filter(item => !(filterItem.includes(item.net) && filterLevel.includes(item.level)) && !mustFilterItem.includes(item.net));
    // if (!keepArray.find(item => item.length <= 2)) {
    //filter vrmComps by connector directly power net
    /* eg:
    keepArray:[{name:"VPH_PWR",type:"net"},{name:"L2900",type:"comp",compType:"Ind"},{name:"PMB_VSW_CHG",type:"net"},{name:"U2900",type:"comp"}]
     vrmInfo.VRMComps:[{name:"U2900",vrm:"U2900",inductance:"U2900"},{name:"L2900",vrm:"U2900",inductance:"L2900"}]
     remove -> {name:"U2900",vrm:"U2900",inductance:"U2900"},[{name:"VPH_PWR",type:"net"},{name:"U2900",type:"comp"}]
     */
    //   vrmInfo.VRMComps = vrmInfo.VRMComps.filter(item => !(item.name === item.vrm));
    // }
  }

  transistorCheck = (vrm) => {
    const findBuck = this.buckConverter.find(buck => buck.includes(vrm.name));
    if (findBuck) {
      const component = this.allComponents.find(comp => comp.name === vrm.name);
      if (component && component.pins.some(pin => this.ReferenceNets.includes(pin.net))) {
        return {
          ...vrm, title: findBuck.join(' - ')
        }
      }
    }
    return false
  }

  senseCheck = (options) => {
    const { comps, netsInfo, prevConnInfo, isVrmSense } = options;
    const isVRMs = this.chipFilter(comps, this.chips);
    let VRMs = [];
    for (let vrm of isVRMs) {
      // pin name
      // filter vrm by pin name length
      if (this.PMIC.map(pmic => pmic.partName).includes(vrm.part)) {
        const vrmPinCheck = this.sensePinCheck(vrm, netsInfo);
        if (vrmPinCheck) {
          VRMs.push(vrm);
        }
      } else {
        continue;
      }
    }

    const sensePath = [...prevConnInfo];
    const lastComp = sensePath.length - 1 >= 0 ? this.allComponents.find(item => item.name === sensePath[sensePath.length - 1].name) : null;
    if (lastComp || isVrmSense) {
      if (lastComp) {
        sensePath[sensePath.length - 1] = { ...sensePath[sensePath.length - 1], sense: true, pins: lastComp.pins };
      }
      for (let vrm of VRMs) {
        const netInfo = netsInfo.find(n => n.mName === vrm.net);
        if (netInfo) {
          const netPins = netInfo.mPinList.filter(item => item.mCompName === vrm.name).map(item => item.mPinNum);
          const connectPins = vrm.pinList.filter(item => netPins.includes(item.mNumber) || netPins.includes(item.pin)).map(item => ({ pin: item.mNumber || item.pin, pinName: item.mName, net: netInfo.mName, mName: item.mName }));
          this.sensePort.push([...sensePath,
          { type: 'net', name: netInfo.mName },
          {
            type: 'comp',
            name: vrm.name,
            compType: vrm.type,
            pins: connectPins,
            sense: isVrmSense && !lastComp ? true : false
          }]
          );
        }
      }
    }
  }

  sensePortFilter = (vrms) => {
    if (!vrms) {
      this.sensePort = [];
      return;
    }
    let newSensePort = [...this.sensePort];
    const vrmComps = vrms.map(vrm => vrm.vrmComp);
    newSensePort = newSensePort.filter(item => vrmComps.includes(item[item.length - 1].name));
    if (newSensePort.length <= 1) {
      this.sensePort = [...newSensePort];
    } else {
      let minPathNote = [];
      //find min path
      newSensePort.forEach((array, index) => {
        const sense = array.find(item => item.sense);
        const pathLength = array.length;
        const vrm = array[pathLength - 1];
        const findIndex = minPathNote.findIndex(item => item.sense === sense.name && item.vrm === vrm.name);
        if (findIndex > -1) {
          if (minPathNote[findIndex].pathLength > pathLength) {
            minPathNote[findIndex].pathLength = pathLength;
            minPathNote[findIndex].index = index;
          }
        } else {
          minPathNote.push({ sense: sense.name, vrm: vrm.name, index, pathLength });
        }
      })
      const keepIndex = minPathNote.map(item => item.index);
      this.sensePort = newSensePort.filter((array, index) => keepIndex.includes(index))
    }
    //update components type(usage)
    this.sensePort = this.filterAndGroupSensePort(this.sensePort);
    this.addSenseComponentAndPins(this.sensePort || []);
  }

  addSenseComponentAndPins = (sensePort) => {
    const senseComps = sensePort.map(array => array.filter(item => item.type === 'comp')).flat(2);
    const senseNets = sensePort.map(array => array.filter(item => item.type === 'net')).flat(2).map(item => item.name);
    for (let senseComp of senseComps) {
      const comp = this.allComponents.find(item => item.name === senseComp.name);
      const findIndex = this.updateComponents.findIndex(item => item.name === senseComp.name);
      let sensePins = senseComp.pins ? senseComp.pins.map(it => it.pin) : [];

      const netPins = comp.pins.filter(item =>
        (this.PowerNets && this.PowerNets.includes(item.net))
        || (this.ReferenceNets && this.ReferenceNets.includes(item.net))
        || (this.extendNets && this.extendNets.includes(item.net))
        || (senseNets && senseNets.includes(item.net))
      ).map(item => item.pin);

      let pins = comp.pins.filter(item => sensePins.includes(item.pin) || netPins.includes(item.pin));

      if (findIndex < 0) {
        this.updateComponents.push({ ...comp, pins, usage: senseComp.sense ? IGNORE : comp.COMP_TYPE })
      } else if (findIndex >= 0 && !senseComp.sense) {
        const prevPins = this.updateComponents[findIndex].pins.map(it => it.pin);
        pins = pins.filter(item => !prevPins.includes(item.pin));
        const newPins = [...this.updateComponents[findIndex].pins, ...pins];
        this.updateComponents[findIndex].usage = this.updateComponents[findIndex].COMP_TYPE;
        this.updateComponents[findIndex].pins = newPins;
      }
    }
  }
}

function getNetGeomLocation(netInfo) {
  const { mGeomList } = netInfo[0];
  const locationList = [];
  for (let geom of mGeomList) {
    const { mRefGeom = {} } = geom;
    const { mGeometry } = mRefGeom;
    if (mGeometry instanceof CeLine) {
      const { mEnd, mStart } = mGeometry;
      locationList.push({ type: 'Line', x: mStart.mX, y: mStart.mY })
      locationList.push({ type: 'Line', x: mEnd.mX, y: mEnd.mY })
    } else if (mGeometry instanceof CePolygon) {
      const { mVertices = [] } = mGeometry;
      const parc = mVertices.filter(v => v.mGeomType === 'Parc');
      if (mVertices.length !== 6 && parc.length !== 3) {
        mVertices.forEach(item => {
          locationList.push({ type: 'Polygon', x: item.mX, y: item.mY })
        })
      }
    }
  }
  return locationList
}

function findMinDistance(pin, locations) {
  const { x, y } = pin;
  let distance = locations.map(l => {
    const d = Math.pow(Math.abs(x) - Math.abs(Number(l.x)), 2)
      + Math.pow(Math.abs(y) - Math.abs(Number(l.y)), 2);
    return { d, type: l.type }
  })
  distance = distance.filter(dist => dist.d !== 0).sort((a, b) => a.d - b.d)

  return distance.length ? distance[0] : {}
}

function getCascadeNetsListByComps(comps, filterNets, pcbId, PMIC, powerNets, specify) {

  let compsInfo = {};

  const PartName = PMIC.filter(item => item.type !== SWITCHING).map(item => item.partName);

  compsInfo = getNetsListByComps(comps, filterNets, pcbId);
  for (let power of powerNets) {
    const _comps = comps.filter(comp => comp.net && comp.net.includes(power) && (PartName.includes(comp.part) || specify.find(item => item.name === comp.name)));
    if (_comps.length) {
      for (let _comp of _comps) {
        if (!Object.keys(compsInfo).includes(_comp.name)) {
          compsInfo[_comp.name] = [power];
        }
      }
    }
  }
  return compsInfo;
}

export default GetVRMs