import React, { Fragment } from 'react';
import { CloseOutlined, DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Tooltip, Dropdown } from 'antd';
import {
  ADD,
  MY_LIBRARY,
  PROJECTS,
  DELETE,
  CLEAN,
  TRASH,
  PROJECT_CREATE,
  LIBRARY,
  LIBRARY_OPEN,
  PROJECT,
  SPICE_CARD_TEMPLATE,
  LIBRARY_FILE,
  LIBRARY_FOLDER,
  IO_SIM,
  SPICE_CARD_TEMPLATES,
  IO_SIMS,
  RENAME,
  EDIT,
  ADD_VDE
} from "../../../constants/treeConstants";
import CreateItem from '../../../components/CreateItem';
import himalayasProjects from '../project/projectConstructor';
import himalayasChannels from '../project/channelsConstructor';
import projectConstructor from '../project/projectConstructor';
import { PCB_SUBCKT, SOC_SPICE, SOC_WRAPPER } from '../../../constants/libraryConstants';

const IconKey = {
  1: [ADD],
  2: [DELETE],
  3: [CLEAN],
  4: [DELETE, RENAME],
  5: [DELETE, EDIT],
  6: [ADD_VDE],
  0: []
}

function getIconKey(data) {
  switch (data.dataType) {
    case PROJECTS:
    case SPICE_CARD_TEMPLATES:
      return 1;
    case LIBRARY_FILE:
      return [PCB_SUBCKT, SOC_SPICE, SOC_WRAPPER].includes(data.type) ? 5 : 2;
    case TRASH:
      return 3;
    case PROJECT:
    case SPICE_CARD_TEMPLATE:
      return 4;
    case LIBRARY:
      return [SOC_SPICE, SOC_WRAPPER, PCB_SUBCKT].includes(data.key) ? 6 : 1;
    case LIBRARY_FOLDER:
      return 2;
    default:
      return 0;
  }
}

function renderTitle(itemData, actions, props) {
  switch (itemData.dataType) {
    case MY_LIBRARY:
    case PROJECTS:
    case TRASH:
    case LIBRARY:
    case LIBRARY_OPEN:
    case SPICE_CARD_TEMPLATES:
    case IO_SIMS:
      return outTitle({ itemData, actions, props });
    case PROJECT_CREATE:
      return projectCreate({ itemData, actions });
    case LIBRARY_FILE:
    case LIBRARY_FOLDER:
      return libraryTitle({ itemData, actions, props, className: "himalayas-library-title" });
    case PROJECT:
    default:
      return defaultTitle({ itemData, actions, props });
  }
}

function libraryTitle({ itemData, actions, props, className }) {
  const Keys = IconKey[getIconKey(itemData)];
  const { downloadLibraryInfo, downloadProgress } = actions;
  const spanClassName = Keys.length > 1 ? "himalayas-library-edit-title" : "";
  return <span
    className={downloadLibraryInfo && downloadLibraryInfo.id === itemData.id ?
      (downloadProgress === 100 ? 'download-progress-bar download-progress-bar-success' : 'download-progress-bar')
      : ""}
    style={{ "--percent": downloadProgress }}>
    <div>
      <Tooltip
        key={itemData.key}
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={itemData.vdeNum ? `${itemData.name} - Vde Number( ${itemData.vdeNum} )` : itemData.name}
        className={`aurora-icon-color aurora-tooltip-span ${className} ${spanClassName}`}
        overlayClassName='aurora-tooltip'
        onClick={(e) => actions.editItem(e, itemData)}
      >{itemData.name}
      </Tooltip>
      <Fragment>{Keys.map((key, index) => { return getIcon({ index, key, itemData, actions, props }) })}</Fragment>
    </div>
  </span>
}

function defaultTitle({ itemData, actions, props }) {
  const Keys = IconKey[getIconKey(itemData)];
  //rename project or channel
  if (isRename(itemData, actions)) {
    return renameRender({ itemData, actions, props })
  }
  return <span>
    <Tooltip
      key={itemData.key}
      placement="topLeft"
      mouseEnterDelay={0.8}
      mouseLeaveDelay={0}
      title={itemData.name}
      className={`aurora-default-tree-title aurora-tooltip-span`}
      overlayClassName='aurora-tooltip'
    >{itemData.name}</Tooltip>
    <Fragment>{Keys.map((key, index) => { return getIcon({ index, key, itemData, actions, props }) })}</Fragment>
  </span>
}

function outTitle({ itemData, actions, props }) {
  const Keys = IconKey[getIconKey(itemData)];
  return <span>
    <span className="out-title-color">{itemData.name}</span>
    <Fragment>{Keys.map((key, index) => {
      return getIcon({
        index,
        key,
        itemData,
        actions,
        props
      })
    })}</Fragment>
  </span>
}

function projectCreate({ itemData, actions }) {
  return <CreateItem
    itemData={itemData}
    createNodeId="himalayas-check-project-icon"
    cancelNodeId="himalayas-close-project-icon"
    createConfirm={actions.createProject}
    projectList={himalayasProjects.getProjectValues()}
    cancelCreate={actions.cancelCreateProject}
  />
}

function getIcon({
  key,
  itemData,
  actions,
}) {
  const { addItem, delItem, deleteClick, renameClick, editClick, addVdeItem } = actions;
  switch (key) {
    case ADD:
      return (
        <PlusSquareOutlined
          className={`aurora-tree-add-icon`}
          id={`himalayas-add-${itemData.key}-icon`}
          key={`${itemData.key}-add`}
          onClick={(e) => addItem(e, itemData)} />
      );
    case DELETE:
      const type = getType(itemData.dataType);
      return (
        <CloseOutlined
          className="aurora-tree-del-icon"
          id={`himalayas-delete-${itemData.key}-icon`}
          key={`${itemData.key}-delete`}
          onClick={(e) => type ? deleteClick(e, itemData) : delItem(e, itemData)} />
      );
    case CLEAN:
      return (
        <DeleteOutlined
          key={itemData.key}
          className='aurora-tree-del-icon'
          onClick={(e) => deleteClick(e, itemData)} />
      );
    case RENAME:
      return (
        <Tooltip
          key={key}
          title='Rename'
          overlayClassName='aurora-tooltip'>
          <EditOutlined
            className='aurora-tree-project-rename-icon himalayas-rename-icon'
            onClick={(e) => renameClick(e, itemData)} />
        </Tooltip>
      );
    case EDIT:
      return (
        <Tooltip
          key={key}
          title='Edit'
          overlayClassName='aurora-tooltip'>
          <EditOutlined
            className='aurora-tree-project-edit-icon himalayas-edit-icon'
            onClick={(e) => editClick(e, itemData)} />
        </Tooltip>
      );
    case ADD_VDE:
      return (
        <Dropdown menu={{ items: vdeMenu(addVdeItem, itemData), className: "upload-dropdown-button" }} trigger={['click']} key={key}>
          <PlusSquareOutlined
            className={'aurora-tree-add-icon'}
            key={key}
            onClick={(e) => stopPropagation(e)} />
        </Dropdown>
      );
    default: return;
  }
}

function stopPropagation(e) {
  e && e.stopPropagation();
}

function vdeMenu(addVdeItem, itemData) {
  return [
    { key: 'vde_1', label: 'Vde Number: 1', onClick: () => addVdeItem(itemData, 1) },
    { key: 'vde_2', label: 'Vde Number: 2', onClick: () => addVdeItem(itemData, 2) },
  ]
}

function getType(dataType) {
  const list = [PROJECT, SPICE_CARD_TEMPLATE, LIBRARY_FILE, IO_SIM, LIBRARY_FOLDER];
  if (list.includes(dataType)) {
    return dataType;
  }
  return null;
}

function renameRender({ itemData, actions, props }) {
  let list = [], type = "";
  if (itemData.dataType === PROJECT) {
    type = "Project";
    list = projectConstructor.getProjectValues() || [];
  } else if (itemData.dataType === SPICE_CARD_TEMPLATE) {
    type = "Channel";
    list = himalayasChannels.getList(itemData.dataType, props.openProjectId).filter(item => item.id !== itemData.id)
  }
  return <CreateItem
    itemData={itemData}
    createNodeId="himalayas-check-project-icon"
    cancelNodeId="himalayas-close-project-icon"
    createConfirm={(data) => actions.saveRename(data, type)}
    projectList={list}
    cancelCreate={() => actions.cancelRename(type)}
  />
}

function isRename(itemData, action) {
  switch (itemData.dataType) {
    case PROJECT:
      if (action.renameProjectInfo && action.renameProjectInfo.id === itemData.id) {
        return true;
      }
      return false;
    case SPICE_CARD_TEMPLATE:
    case IO_SIM:
      if (action.renameChannelInfo && action.renameChannelInfo.id === itemData.id) {
        return true;
      }
      return false;
    default: return false
  }
}

export {
  renderTitle
}