import hull from 'hull.js';

function calcBoundary(verticesArr, clipSize, unit, { maxX, maxY, minX, minY }, vertices = []) {
  // um to mil - In order to solve the memory overflow when the number is large
  let _verticesArr = [...verticesArr];
  if (unit === 'um') {
    _verticesArr.forEach(d => {
      d[0] = (d[0] / 25.4)
      d[1] = (d[1] / 25.4)
    })
  }
  let concavity = 20;
  if (clipSize > 10) {
    concavity = concavity + clipSize * 2;
  }
  let boundaryData = hull(_verticesArr, concavity);

  if (unit === 'um') {
    boundaryData.forEach(d => {
      d[0] = (d[0] * 25.4)
      d[1] = (d[1] * 25.4)
    })
  }
  maxX = maxX + clipSize * 2;
  minX = minX - clipSize * 2;
  maxY = maxY + clipSize * 2;
  minY = minY - clipSize * 2;
  //filter points far from the border of the pcb
  boundaryData = filterBoundaries(boundaryData, unit, { maxX, minX, maxY, minY });
  // calc distance
  return new BoundaryData(boundaryData, vertices, unit);
}

//filter points far from the border of the pcb
function filterBoundaries(boundaryData, unit, { maxX, minX, maxY, minY }) {
  const spacing = getSpacingByUnit(unit);
  boundaryData = boundaryData.filter((d, index) => {
    if (!d || !d[0] || !d[1]) {
      return false;
    }

    if (d[0] > maxX || d[0] < minX || d[1] > maxY || d[1] < minY) {
      //If the difference between adjacent points x, y is greater than spacing(500mil), and the point is outside the pcb boundary, remove the point
      const prevPoint = index - 1 >= 0 ? boundaryData[index - 1] : boundaryData[boundaryData.length - 1];
      const nextPoint = index + 1 < boundaryData.length ? boundaryData[index + 1] : boundaryData[0];
      if (Math.abs(prevPoint[0] - d[0]) > spacing
        || Math.abs(nextPoint[0] - d[0]) > spacing
        || Math.abs(prevPoint[1] - d[1]) > spacing
        || Math.abs(nextPoint[1] - d[1]) > spacing) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  })
  return boundaryData;
}

function getSpacingByUnit(unit) {
  //500mil is an arbitrary number,
  //If the difference between adjacent points x, y is greater than 500, and the point is outside the pcb boundary, remove the point
  switch (unit) {
    case 'mil':
    case 'mils':
      return 500;
    case "um":
      return 500 * 25.4;
    case "mm":
      return 500 * 0.0254;
    default:
      return 500;
  }
}

function BoundaryData(boundaryData, vertices, unit) {
  this.data = boundaryData;
  this.vertices = vertices;
  this.unit = unit;
}

BoundaryData.prototype.toDesignClipObject = function () {
  let designClip = new DesignClip();
  designClip.unit = this.unit;
  for (const item of this.data) {
    designClip.x.push(item[0]);
    designClip.y.push(item[1]);
  };
  return designClip;
}

// "DesignClip": {
//   "unit": "mm", // any supported unit supported by frontend, but 'mils' needs to be 'mil'
//   "x": [ // polygon point x coordinates ],
//   "y": [ // y coordinates ]
// }
function DesignClip() {
  this.unit = 'mil';
  this.x = [];
  this.y = [];

}

export default calcBoundary;
export {
  filterBoundaries
}