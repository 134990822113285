import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, DeleteOutlined, PlusCircleOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Input, message } from 'antd';
import Table from "@/components/EditableTable";
import PackageModel from '@/components/PackageModel';
import RLCCompModel from '@/components/RLCCompModel';
import PinModelPanel from './pinModelPanel';
import { SIERRA } from '@/constants/pageType';
import { DESIGN_SUCCESS } from '@/constants/designCategory';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { IBIS, SPICE, CONNECTOR_SPICE_SIERRA, CONNECTOR_TOUCHSTONE, VECTOR, PASSIVE_TOUCHSTONE, PASSIVE_SPICE, REPEATER, PKG_TOUCHSTONE, PKG_SPICE } from '@/constants/libraryConstants';
import { updateLibraryTree } from '../../store/library/action';
import { getLibraryIndex } from '@/services/Sierra/library/libraryData';
import {
  addExperiment, addExperimentsByPassive, updateSingleExperiment, updateExperimentInfo, updateSweepCreateStatus, updateExperimentComp, reduceSweepColumn,
  createExpsByModel, createExpByTrace, updateSweepSchematicPreLayoutInfo
} from '../../store/sweep/action';
import {
  getExperimentColumns,
  getExperimentData,
  getIBISPackageText,
  getSpicePackageText,
  PackageModelInfo,
  PackageModelFileInfo,
  getPkgData,
  getCompGroup,
  getExperimentStatus,
  DEFAULT_EXPERIMENT_COLUMNS,
  saveSweepColumns,
  getModelText
} from '@/services/Sierra';
import { parseSpiceModelSelector } from '@/services/LibraryHelper/SpiceModelHelper';
import { getTouchstoneParse, getPkgSpiceModelList, getIbisPackageList, getLibraryFileContent } from '@/services/Sierra/library';
import { autoSelectTouchstonePorts } from '@/services/helper/touchstoneHelper';
import { RLCModelInfo, getRLCValue, PassiveModel, getModelValue, getModelTypeName } from '@/services/RLCModel';
import IconCollection from '@/components/TreeIconCollection';
import projectDesigns from '@/services/helper/projectDesigns';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { formatRLCValue } from '../../../../services/RLCModel';
import MeasurementPanel from '../PinToPinSetting/TopBar/measurement';
import { SweepData } from '../../../../services/Sierra';
import PreLayoutSchematicSetup from './preLayoutSchematicSweep';
import designConstructor from '../../../../services/helper/designConstructor';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import { getPreLayoutSignalsSections } from '../../../../services/Sierra/prelayout/Schematic';
import './index.css';
import '../index.css';

const baseName = 'Base';
const PCB = 'pcb', PKG = 'pkg', PASSIVE = 'passive', PIN = 'pins', VIRTUAL_PASSIVE = "virtualPassive", MEASUREMENT = "measurement";
class Sweeping extends PureComponent {
  constructor(props) {
    super(props);
    const ibisList = sierraLibrary.getTree(IBIS);
    const spiceList = sierraLibrary.getTree(SPICE);
    const repeaterList = sierraLibrary.getTree(REPEATER);
    const passiveSpiceList = sierraLibrary.getTree(PASSIVE_SPICE);
    const passiveTouchstoneList = sierraLibrary.getTree(PASSIVE_TOUCHSTONE);
    const pkgSpiceList = sierraLibrary.getTree(PKG_SPICE);
    const pkgTouchstoneList = sierraLibrary.getTree(PKG_TOUCHSTONE);
    const connSpiceList = sierraLibrary.getTree(CONNECTOR_SPICE_SIERRA);
    const connTouchstoneList = sierraLibrary.getTree(CONNECTOR_TOUCHSTONE);
    const vectorList = sierraLibrary.getTree(VECTOR);
    this.state = {
      ibisList,
      spiceList,
      repeaterList,
      passiveSpiceList,
      passiveTouchstoneList,
      pkgSpiceList,
      pkgTouchstoneList,
      connSpiceList,
      connTouchstoneList,
      vectorList,
      updateColumns: true,
      displayComp: { type: false, parentIndex: null },
      renameExp: {},
      columns: []
    }
  }

  componentDidMount = () => {
    const { libraryTreeInfo } = this.props;

    if (libraryTreeInfo && libraryTreeInfo.length) {
      this.getLibraryList(libraryTreeInfo)
      this.props._updateLibraryTree(null);
    }

    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('click', this.renameClickOutside, true);

    this.initColumns();
  }

  componentDidUpdate = (prevProps) => {
    const { libraryTreeInfo, id, update, experiments, hasSchematic } = this.props;

    if (libraryTreeInfo && libraryTreeInfo.length && (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length)) {
      this.getLibraryList(libraryTreeInfo)
      this.props._updateLibraryTree(null);
    }

    if ((id && id !== prevProps.id) || (experiments && experiments.length !== prevProps.experiments.length) || (hasSchematic !== prevProps.hasSchematic)) {
      this.initColumns();
    }

    if (update) {
      this.initColumns();
      this.props.updateExperimentInfo(false, 'update');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('click', this.renameClickOutside, true);
  }

  initColumns = () => {
    const { base, experiments, tableColumns } = this.props;
    let columns = getExperimentColumns([base, ...experiments], tableColumns);
    // checkbox column
    columns[0].title = () => {
      const { experiments, forSim, experimentQueue } = this.props;
      return <Checkbox
        className="sweeping-columns-checkbox"
        onChange={(e) => this.changeSimList(e, 'all')}
        checked={forSim.length + experimentQueue.length === experiments.length + 1}
        indeterminate={forSim.length && forSim.length + experimentQueue.length < experiments.length + 1}
      />
    }

    columns[0].render = (text, record) => {
      const { forSim, experimentQueue } = this.props;
      return <Checkbox
        className="sweeping-columns-checkbox"
        onChange={(e) => this.changeSimList(e, record.id)}
        checked={forSim.includes(record.id)}
        disabled={experimentQueue.includes(record.id)}
      />
    }

    columns[1].render = (text, record) => {
      const { id, name } = record;
      const { renameExp } = this.state;
      return renameExp.id === id ? <Input
        id={`experiment-name-${id}`}
        className='aurora-input'
        value={renameExp.name}
        ref={node => (this.input = node)}
        onChange={(e) => this.changeExperimentName(e)}
        onPressEnter={(e) => { this.renameExperiment(e) }}
      /> : <div className="sierra-sweeping-column-name" id={`experiment-name-${id}`}>
        <div className="sierra-sweeping-column-name-text" onDoubleClick={(e) => { this.setRenameExperiment(e, id, { name }) }}>
          <Tooltip title={text} overlayClassName='aurora-tooltip'>{text}</Tooltip>
        </div>
        {name !== baseName && <IconCollection
          menu={this.collectionMenu(name)}
          data={id}
        />}
      </div>
    }

    if (columns[columns.length - 1]) {
      columns[columns.length - 1].render = (text, record) => {
        const { title, color, tooltip = null } = getExperimentStatus(text);
        return <Tooltip title={tooltip} overlayClassName='aurora-tooltip'>
          <span style={{ color, fontWeight: 'bold' }}>{title}</span>
        </Tooltip>
      }
    }

    if (columns[columns.length - 2]) {
      let measureIndex = columns.length - 2;
      if (measureIndex > -1) {
        columns[measureIndex].render = (_txt, record) => {
          const { sweepMeasurementInfo, base } = this.props;
          return this.getMeasurementTitle(record, sweepMeasurementInfo, base)
        }

        columns[measureIndex].onCell = (record) => {
          const { base, currentProjectVerifications, experiments, sweepMeasurementInfo } = this.props;
          const currExp = [base, ...experiments].find(item => item.id === record.id) || {};
          const text = this.getMeasurementTitle(record, sweepMeasurementInfo, base);
          return {
            edit: true,
            record,
            text,
            customInput: MeasurementPanel,
            verificationId: record.verificationId,
            rootVerificationId: SweepData.getExpRootVerificationId(record.id),
            isSweep: true,
            currExp,
            base,
            currentProjectVerifications,
            isBase: record.name === baseName,
            isMultiPCB: base && base.Interfaces && base.Interfaces.length > 1,
            experiments,
            tdClassName: "sierra-measurement-title-td"
          }
        }
        columns[measureIndex].width = 120
      }
    }

    //get columns by Interface
    if (base.Interfaces && base.Interfaces.length > 1) {
      for (let i = 0; i < base.Interfaces.length; i++) {
        const columnsKey = tableColumns[i];
        columns[i + 2].children = this.initCompairColumns(columns[i + 2].children, i, columnsKey)
      }
    } else {
      const columnsKey = tableColumns[0];
      columns = this.initCompairColumns(columns, -1, columnsKey)
    }
    this.setState({ updateColumns: false, columns })
  }

  initCompairColumns = (columns, parentIndex = -1, columnsKey = []) => {
    let lastItem = columnsKey && columnsKey.length ? columnsKey[columnsKey.length - 1] : PIN;
    //pcb columns
    let pcbIndex = columns.findIndex(column => column.key.includes(PCB));
    if (pcbIndex > -1) {
      columns[pcbIndex].title = () => {
        return (
          <Fragment>
            {lastItem === PCB && this.addColumnsMenu(columnsKey, parentIndex)}
            <div className="sierra-sweeping-table-title">
              <div className="sierra-sweeping-title-value">
                <span>PCB</span>
                {columnsKey.length > 1 && <CloseOutlined
                  className="sierra-sweep-close-icon"
                  onClick={(e) => this.deleteColumn(e, PCB, parentIndex)} />}
              </div>
            </div>
          </Fragment>
        );
      }
      columns[pcbIndex].render = (text, record) => {
        const { pcbsList, basePCB, currentPCB } = this.getPCBLists(record, parentIndex);
        const isPreLayout = designConstructor.isPreLayout(currentPCB.id);
        const isSchematic = isPreLayout ? preLayoutData.isSchematic(currentPCB.id) : false;
        const textSpan = <span style={text && text.notExist ? { color: '#cb2431' } : {}}>
          <Tooltip title={text && text.notExist} overlayClassName='aurora-error-tooltip'>
            {text && text.name}
          </Tooltip>
        </span>
        if (isSchematic) {
          const traceText = this.getTraceText(currentPCB);
          return <div
            className='editable-cell-value-wrap'
            onClick={(e) => this.schematicPreLayoutClick({ e, record, basePCB, currentPCB, pcbsList, parentIndex })}
          >
            {textSpan}{traceText}
          </div>
        }
        return textSpan
      }
      columns[pcbIndex].onCell = (record) => {
        const { currentProjectId } = this.props;
        const { pcbsList, basePCB, currentPCB = {} } = this.getPCBLists(record, parentIndex);
        const _optionList = projectDesigns.getAvailableDesigns(currentProjectId).filter(item => item.category === DESIGN_SUCCESS)
          .filter(item => !pcbsList.includes(item.name));
        const optionList = _optionList.map(item => item.name)
        const isPreLayout = designConstructor.isPreLayout(currentPCB.id);
        const isSchematic = isPreLayout ? preLayoutData.isSchematic(currentPCB.id) : false;

        return record.name !== baseName && !isSchematic ? {
          edit: 'select',
          record: { ...currentPCB, interfaceIndex: parentIndex > -1 ? parentIndex : 0, experimentId: record.id, basePCB },
          options: optionList,
          dataIndex: 'name',
          dropdownMenuClassName: 'sierra-sweeping-select-dropdown-menu',
          handleSave: this.changeExperimentPCB,
          tdClassName: record.name !== baseName && currentPCB ? "" : "sweeping-table-td-not-used"
        } : {
          edit: false,
          tdClassName: isSchematic ? "" : "sweeping-table-td-padding"
        }
      }
      columns[pcbIndex].width = this.props.hasSchematic ? 220 : 120;
    }

    //package columns
    let pkgIndex = columns.findIndex(column => column.key.includes(PKG));
    if (pkgIndex > -1) {
      columns[pkgIndex].title = () => {
        const { displayComp } = this.state;
        const { base, experiments } = this.props;
        return (
          <Fragment>
            {lastItem === PKG && this.addColumnsMenu(columnsKey, parentIndex)}
            <div className="sierra-sweeping-table-title sierra-sweeping-table-title-pkg" >
              <div className="sierra-sweeping-title-value" onClick={(e) => this.setDisplayComp(e, PKG, parentIndex)}>
                {displayComp.type === PKG && displayComp.index === parentIndex ? <DownOutlined /> : <RightOutlined />}
                <span>Package</span>
                {columnsKey.length > 1 && <CloseOutlined
                  className="sierra-sweep-close-icon"
                  onClick={(e) => this.deleteColumn(e, PKG, parentIndex)} />}
              </div>
              {displayComp.type === PKG && displayComp.index === parentIndex && <div
                className="sierra-sweeping-display-comp"
                style={{ marginTop: 9 }}
              >
                <ul>
                  {
                    getCompGroup(PKG, [base, experiments])[parentIndex > -1 ? parentIndex : 0].map(comp => {
                      return <li key={comp.component}>
                        <Tooltip overlayClassName='aurora-tooltip' title={comp.component}>
                          <Checkbox checked={comp.visible} onChange={(e) => this.changeSelectedTitle(e, comp, parentIndex, PKG)}>{comp.component}</Checkbox>
                        </Tooltip>
                      </li>
                    })
                  }
                </ul>
              </div>}
            </div>
          </Fragment>
        );
      }
      if (columns[pkgIndex] && (!columns[pkgIndex].children || !columns[pkgIndex].children.length)) {
        columns[pkgIndex].width = 180
      }
      if (columns[pkgIndex].children && columns[pkgIndex].children.length) {
        columns[pkgIndex].children.forEach(comp => {
          comp.render = (info, record) => {
            let children = <span></span>;
            const pkg = info ? info.pkgInfo : null;
            if (info && info.exist === false) {
              children = <Tooltip title="The component does not exist on the current PCB" overlayClassName='aurora-tooltip'>
                <span className="aurora-error-color">Not Exist</span>
              </Tooltip>
            } else if (!pkg || !pkg.type) {
              children = <span></span>
            } else if (pkg.type === 'None') {
              children = <span>{pkg.type}</span>
            } else if (pkg.type === 'IBIS') {
              const text = getIBISPackageText(pkg);
              children = <span>{text}</span>
            } else if (pkg.type === "SPICE" || pkg.type === "Touchstone" || pkg.type === "Touchstone / SPICE") {
              const { text } = getSpicePackageText(pkg);
              children = <span>{text}</span>
            } else {
              children = <span></span>
            }
            return children;
          }
          comp.onCell = (record) => {
            const { pkgSpiceList, pkgTouchstoneList } = this.state;
            const { base } = this.props;
            const baseSource = getExperimentData([base]);
            const baseInfo = baseSource.length ? baseSource[0] : {};
            const pinList = this.getPinList(comp.title, record, baseInfo, parentIndex);
            const text = record[comp.dataIndex] ? getIBISPackageText(record[comp.dataIndex].pkgInfo) : '';
            const _record = getPkgData(comp.dataIndex, record, baseInfo, parentIndex);
            const pkgInfo = record[comp.dataIndex] ? record[comp.dataIndex].pkgInfo : null;
            const tdClassName = record.name !== baseName && ((pkgInfo && pkgInfo.type) || (record[comp.dataIndex] && record[comp.dataIndex].exist === false)) ? "" : "sweeping-table-td-not-used";
            return record.name !== baseName && (!record[comp.dataIndex] || (record[comp.dataIndex] && record[comp.dataIndex].exist !== false)) ? {
              edit: true,
              record: { ..._record, interfaceIndex: parentIndex > -1 ? parentIndex : 0, comp: comp.title, id: record.id },
              componentName: comp.title,
              pinList,
              text,
              customInput: PackageModel,
              dataIndex: PKG,
              product: SIERRA,
              spiceList: pkgSpiceList,
              touchstoneList: pkgTouchstoneList,
              PackageModelInfo: PackageModelInfo,
              PackageModelFileInfo: PackageModelFileInfo,
              getIbisPackageList: getIbisPackageList,
              getTouchstoneParse: getTouchstoneParse,
              getSPiceParse: getPkgSpiceModelList,
              autoSelectPorts: autoSelectTouchstonePorts,
              savePackageModel: this.savePackageModel,
              // verificationId,
              modelType: 'Package',
              displayType: 'model',
              tdClassName
            } : {
              edit: false,
              tdClassName: record.name === baseName ? `sweeping-table-td-padding ${tdClassName}` : tdClassName
            }
          }
          if (columns[pkgIndex].children.length === 1) {
            comp.width = 180
          } else {
            comp.width = 120
          }
        })
      } else {
        columns[pkgIndex].render = (text, record) => {
          return <span></span>;
        }

        columns[pkgIndex].onCell = (record) => {
          return { edit: false, tdClassName: "sweeping-table-td-not-exist" }
        }
      }
    }

    //passive columns
    let passiveIndex = columns.findIndex(column => column.key.includes(PASSIVE));
    if (passiveIndex > -1) {
      columns[passiveIndex].title = () => {
        const { displayComp } = this.state;
        const { base, experiments } = this.props;
        return (
          <Fragment>
            {lastItem === PASSIVE && this.addColumnsMenu(columnsKey, parentIndex)}
            <div className="sierra-sweeping-table-title sierra-sweeping-table-title-passive">
              <div className="sierra-sweeping-title-value" onClick={(e) => this.setDisplayComp(e, PASSIVE, parentIndex)}>
                {displayComp.type === PASSIVE && displayComp.index === parentIndex ? <DownOutlined /> : <RightOutlined />}
                <span>Passive</span>
                {columnsKey.length > 1 && <CloseOutlined
                  className="sierra-sweep-close-icon"
                  onClick={(e) => this.deleteColumn(e, PASSIVE, parentIndex)} />}
              </div>
              {displayComp.type === PASSIVE && displayComp.index === parentIndex && <div
                className="sierra-sweeping-display-comp"
                style={{ marginTop: 9 }}
              >
                <ul>
                  {
                    getCompGroup(PASSIVE, [base, experiments])[parentIndex > -1 ? parentIndex : 0].map(comp => {
                      return <li key={comp.component}>
                        <Tooltip overlayClassName='aurora-tooltip' title={comp.component}>
                          <Checkbox checked={comp.visible} onChange={(e) => this.changeSelectedTitle(e, comp, parentIndex, PASSIVE)}>{comp.component}</Checkbox>
                        </Tooltip>
                      </li>
                    })
                  }
                </ul>
              </div>}
            </div>
          </Fragment>
        );
      }
      if (columns[passiveIndex] && (!columns[passiveIndex].children || !columns[passiveIndex].children.length)) {
        columns[passiveIndex].width = 180
      }
      if (columns[passiveIndex].children && columns[passiveIndex].children.length) {
        columns[passiveIndex].children.forEach(comp => {
          comp.render = (info, record) => {
            let children = <span></span>;
            if (info) {
              if (info.exist === false) {
                children = <Tooltip title="The component does not exist on the current PCB" overlayClassName='aurora-tooltip'>
                  <span className="aurora-error-color">Not Exist</span>
                </Tooltip>
              } else {
                const { type, value, model } = info;
                children = type ? <span>{formatRLCValue(type, value, model)}</span> : <span></span>
              }
            }
            return children;
          }
          comp.onCell = (record) => {
            const { passiveSpiceList, passiveTouchstoneList } = this.state;
            let info = record[comp.dataIndex];
            const { base, sweepCreateStatus, updateSweepCreateStatus } = this.props;
            const baseSource = getExperimentData([base]);
            const baseRecord = baseSource.length ? baseSource[0] : {};
            let signals = baseRecord[parentIndex > -1 ? `signals_${parentIndex}` : 'signals'];
            let _powerNets = baseRecord[parentIndex > -1 ? `powerNets_${parentIndex}` : 'powerNets'];
            const tdClassName = record.name !== baseName && info && (info.type || info.exist === false) ? "" : "sweeping-table-td-not-used";
            if (!info) {
              info = baseRecord[comp.dataIndex] ? baseRecord[comp.dataIndex] : {};
            }
            const edit = !(record.name === baseName && info.type === 'Cap' && info.subType !== VIRTUAL_PASSIVE);
            const text = record[comp.dataIndex] ? formatRLCValue(info.type, info.value, info.model) : "";
            return !record[comp.dataIndex] || (record[comp.dataIndex] && record[comp.dataIndex].exist !== false) ? {
              edit,
              text: <span>{text}</span>,
              record: { ...info, comps: [{ pins: info ? info.pins : [] }], model: this.getRLCModel(info), interfaceIndex: parentIndex > -1 ? parentIndex : 0, comp: comp.title, id: record.id },
              customInput: RLCCompModel,
              isSweepBase: record.name === baseName ? true : false,
              sweepCreateStatus,
              title: comp.title,
              dataIndex: 'model',
              powerNets: this.getNetsOptions(signals, _powerNets),
              saveRLCModel: this.saveRLCModel,
              spiceList: passiveSpiceList,
              touchstoneList: passiveTouchstoneList,
              getFileContent: getLibraryFileContent,
              parseModelSelector: parseSpiceModelSelector,
              updateCreateStatus: updateSweepCreateStatus,
              createExpsByPassive: this.createExpsByPassive,
              product: SIERRA,
              tdClassName
            } : { edit: false, tdClassName }
          }
          if (columns[passiveIndex].children.length === 1) {
            comp.width = 180
          } else {
            comp.width = 120
          }
        })
      } else {
        columns[passiveIndex].render = (text, record) => {
          return <span></span>;
        }
        columns[passiveIndex].onCell = (record) => {
          return { tdClassName: "sweeping-table-td-not-exist" }
        }
      }
    }

    //pin model columns
    let modelIndex = columns.findIndex(column => column.key.includes(PIN));
    if (modelIndex > -1) {
      columns[modelIndex].title = () => {
        return (
          <Fragment>
            {lastItem === PIN && this.addColumnsMenu(columnsKey, parentIndex)}
            <div className="sierra-sweeping-table-title">
              <div className="sierra-sweeping-title-value">
                <span>Pin Buffer Model</span>
                {columnsKey.length > 1 && <CloseOutlined
                  className="sierra-sweep-close-icon"
                  onClick={(e) => this.deleteColumn(e, PIN, parentIndex)} />}
              </div>
            </div>
          </Fragment>
        );
      }
      columns[modelIndex].render = (text, record) => {
        const { base, experiments } = this.props;
        const dataSource = getExperimentData([base, ...experiments]);
        const { children } = getModelText(record, text, parentIndex, base, dataSource, columns[modelIndex].key);
        return children;
      }

      columns[modelIndex].onCell = (record) => {
        const { ibisList, spiceList, vectorList } = this.state;
        const { base, experiments, sweepCreateStatus, createExpsByModel, updateSweepCreateStatus } = this.props;
        const dataSource = getExperimentData([base, ...experiments]);
        const baseSource = dataSource.filter(item => item.name === 'Base')
        const baseInfo = baseSource.length ? baseSource[0] : {};
        let pinsInfo = [];
        if (((record.pins && record.pins.length) || (record[`pins_${parentIndex}`] && record[`pins_${parentIndex}`].length))) {
          pinsInfo = parentIndex > -1 ? record[`pins_${parentIndex}`] : record.pins;
        }
        let modelInfo = [];
        if (((record.model && record.model.length) || (record[`model_${parentIndex}`] && record[`model_${parentIndex}`].length))) {
          modelInfo = parentIndex > -1 ? record[`model_${parentIndex}`] : record.model;
        }
        const { children, info } = getModelText(record, pinsInfo, parentIndex, base, dataSource, columns[modelIndex].key);
        const tdClassName = record.name !== baseName && pinsInfo && info.length ? "" : "sweeping-table-td-not-used";
        return {
          edit: true,
          record: baseInfo,
          customInput: PinModelPanel,
          text: children,
          dataIndex: 'name',
          experimentId: record.id,
          rowName: record.name,
          pinsInfo,
          modelInfo,
          parentIndex,
          ibisList,
          spiceList,
          vectorList,
          clock: this.props.clock,
          sweepCreateStatus: sweepCreateStatus,
          createExpsByModel: createExpsByModel,
          updateSweepCreateStatus: updateSweepCreateStatus,
          updateSingleExperiment: this.props.updateSingleExperiment,
          tdClassName
        }
      }
      columns[modelIndex].width = 280
    }
    return columns;
  }

  getMeasurementTitle = (record, sweepMeasurementInfo, base) => {
    const isMultiPCB = base && base.Interfaces && base.Interfaces.length > 1;
    const displayInfo = sweepMeasurementInfo ? sweepMeasurementInfo[record.id] || [] : [];
    const text = displayInfo.length && displayInfo.find(item => item.displayList && item.displayList.length) ? displayInfo.map(item => {
      if (!item.displayList || !item.displayList.length) {
        return null;
      }
      return <div className='sierra-sweep-measurement-tooltip-content' key={item.pcb}>
        {isMultiPCB ? <div className='sierra-sweep-measurement-tooltip-title'>{item.pcb || ""}:</div> : null}
        {item.displayList.map((it, index) => <div key={index} className={isMultiPCB ? 'sierra-sweep-measurement-tooltip-item' : ""}>{it};</div>)}
      </div>
    }) : null;
    if (!text) {
      return null
    }
    return <Tooltip
      overlayClassName='aurora-tooltip sweep-measurement-tooltip'
      placement='leftTop'
      title={text || ""}
    >
      <div className='sierra-sweep-measurement-title'>
        {text}
      </div>
    </Tooltip>
  }

  schematicPreLayoutClick = ({ e, record, basePCB, currentPCB, parentIndex }) => {
    e && e.stopPropagation();
    const { schematicPreLayoutInfo } = this.state;
    if (schematicPreLayoutInfo && schematicPreLayoutInfo.name === record.name) {
      return;
    }
    this.props._updateSweepSchematicPreLayoutInfo({
      id: record.id,
      name: record.name,
      basePCB,
      currentPCB,
      isSweepBase: record.name === baseName ? true : false,
      interfaceIndex: parentIndex > -1 ? parentIndex : 0,
    })
  }

  getLibraryList = (typeList) => {
    for (let type of typeList) {
      const { listName } = getLibraryIndex(type);
      this.setState({
        [listName]: sierraLibrary.getTree(type)
      })
    }
  }

  addColumn = (e, data, params) => {
    e.stopPropagation();
    const { key } = params;
    const parentIndex = data < 1 ? 0 : data;
    const { tableColumns, id } = this.props;
    let newColumns = [...tableColumns]
    if (newColumns[parentIndex]) {
      newColumns[parentIndex].push(key);
      this.props.updateExperimentInfo({ tableColumns: newColumns, update: true }, 'all');
      saveSweepColumns({ id, tableColumns: newColumns })
    }
  }

  addColumnsMenu = (columnsKey, parentIndex) => {
    const defaultKey = JSON.parse(JSON.stringify(DEFAULT_EXPERIMENT_COLUMNS));
    if (columnsKey && defaultKey.length === columnsKey.length) {
      return null;
    }
    const displayKey = defaultKey.filter(item => !columnsKey.includes(item));
    return <IconCollection
      data={parentIndex}
      trigger={['click']}
      icon="plus-square"
      menu={displayKey.map(key => this.getMenuKeyItem(key))}
      className="sierra-sweep-table-add-icon"
      placement="bottom"
    />
  }

  getPCBLists = (record, parentIndex) => {
    const { base, experiments } = this.props;
    const basePCBs = base && base.Interfaces ? base.Interfaces.map(i => i[PCB]) : [];
    const basePCB = parentIndex > -1 && basePCBs[parentIndex] ? basePCBs[parentIndex] : basePCBs[0];
    const currentPCB = record[parentIndex > -1 ? `pcb_${parentIndex}` : PCB] || {};
    const currentExp = experiments.find(exp => exp.id === record.id);
    let pcbsList = [];
    if (currentExp) {
      currentExp.Interfaces.forEach((item, index) => {
        if (item[PCB]) {
          pcbsList.push(item[PCB].name);
        } else {
          pcbsList.push(basePCBs[index].name);
        }
      })
    }
    pcbsList = [...new Set(pcbsList)]
    return { basePCB, currentPCB, pcbsList }
  }

  getTraceText = (pcb) => {
    if (!pcb || !pcb.id || !pcb.trace || !pcb.trace.length) {
      return "";
    }
    const preLayout = preLayoutData.getLocalPrelayout(pcb.id);
    const sections = getPreLayoutSignalsSections(preLayout.content.signals);
    let text = "";
    for (let secItem of pcb.trace) {
      const findItem = sections.find(item => item.id === secItem.id);
      if (findItem) {
        text = text ? `${text}, ${findItem.title} ${secItem.length}` : `${findItem.title} ${secItem.length}`;
      }
    }
    return text ? `(${text})` : "";
  }

  getMenuKeyItem = (key) => {
    const common = { func: this.addColumn, params: { key } }
    switch (key) {
      case PCB:
        return { title: 'PCB', ...common }
      case PKG:
        return { title: 'Package', ...common }
      case PASSIVE:
        return { title: 'Passive', ...common }
      case PIN:
        return { title: 'Pin Buffer Model', ...common }
      case MEASUREMENT:
        return { title: 'Measurement', ...common }
      default:
        return {}
    }
  }

  changeSimList = (e, id) => {
    const { forSim, experimentQueue } = this.props;
    let _forSim = [...forSim];
    if (id === 'all') {
      const { experiments, base } = this.props;
      _forSim = e.target.checked ? [base.id, ...experiments.map(item => item.id)] : [];
      _forSim = _forSim.filter(item => !experimentQueue.includes(item))
    } else {
      _forSim = e.target.checked ? [..._forSim, id] : _forSim.filter(item => item !== id);
    }
    this.props.updateExperimentInfo(_forSim, 'forSim')
  }

  getPinList = (compName, record, baseInfo, parentIndex) => {
    const key = parentIndex > -1 ? `pins_${parentIndex}` : PIN;
    let currentPin = baseInfo && baseInfo[key] ? baseInfo[key].find(pin => pin.component === compName) : null
    if (currentPin && currentPin.pinInfo) {
      return currentPin.pinInfo.map(item => ({
        pin: item.pin,
        net: item.net,
        signal: item.signal
      }))
    } else {
      return []
    }
  }

  savePackageModel = (pkg, record) => {
    this.props.updateSingleExperiment({ pkg, record }, PKG);
  }

  saveRLCModel = (model, record) => {
    const _model = { type: getModelTypeName(model.type), ...model.passiveModel };
    const value = getModelValue(record.type, model.value, record.subType);
    this.props.updateSingleExperiment({ model: _model, value, record }, PASSIVE);
  }

  getRLCModel = (record) => {
    if (!record) return {}
    const { model = {}, value, type, subType } = record;
    const _value = getRLCValue(type, value, subType === VIRTUAL_PASSIVE);
    const passiveModel = new PassiveModel({ ...model });
    const _model = new RLCModelInfo({ modelType: model.type ? model.type.toLowerCase() : model.type, value: _value, passiveModel });
    return { ..._model };
  }

  getNetsOptions = (signals, powerNets) => {
    const _signals = [];
    if (signals && signals.length) {
      signals.forEach(signal => {
        _signals.push(...signal.nets)
      });
      return [..._signals, ...powerNets.map(net => net.name)];
    }
    return _signals;
  }

  setDisplayComp = (e, type, index = null) => {
    e && e.stopPropagation && e.stopPropagation();
    const { updateColumns, displayComp } = this.state;
    if (displayComp !== type && updateColumns) {
      this.initColumns();
    }
    this.setState({
      displayComp: { type, index }
    })
  }

  handleClickOutside = (e) => {
    const { displayComp } = this.state;
    const { target } = e;
    let inCompTitle = false;
    if (displayComp.type) {
      if (document.getElementsByClassName('sierra-sweeping-table-title-pkg')) {
        for (let item of document.getElementsByClassName('sierra-sweeping-table-title-pkg')) {
          if (item.contains(target)) {
            inCompTitle = true;
            break;
          }
        }
      }
      if (!inCompTitle && document.getElementsByClassName('sierra-sweeping-table-title-passive')) {
        for (let item of document.getElementsByClassName('sierra-sweeping-table-title-passive')) {
          if (item.contains(target)) {
            inCompTitle = true;
            break;
          }
        }
      }
      if (!inCompTitle) {
        this.setDisplayComp(e, false);
      }
    }
  }

  renameClickOutside = (e) => {
    const { target } = e;
    const { renameExp: { id = "" } } = this.state;
    if (id) {
      if (!document.getElementById(`experiment-name-${id}`).contains(target)) {
        this.renameExperiment(e)
      }
    }
  }

  renameExperiment = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    const { renameExp: { id = "", name } } = this.state;
    const { base = {}, experiments = [] } = this.props;
    const exist = [base, ...experiments].filter(item => item.id !== id).map(item => item.name);
    let error = null;
    if (exist.includes(name)) {
      error = "There is an experiment with the same name";
    }
    error = checkNameFormat(name);
    if (error) {
      message.error(error);
      this.input && this.input.focus();
    } else {
      this.props.updateSingleExperiment({ id, name }, 'rename')
      this.setState({ renameExp: {} })
      this.input = null
    }
  }

  collectionMenu = (name) => {
    return [
      { title: "Rename", icon: 'edit', func: this.setRenameExperiment, params: { name } },
      { title: "Copy", icon: 'copy', func: this.copyExperiment },
      { title: "Delete", icon: 'close', func: this.deleteExperiment }
    ]
  }

  copyExperiment = (e, id) => {
    e.stopPropagation()
    this.props.updateSingleExperiment({ id }, 'copy')
  }

  deleteExperiment = (e, id) => {
    e.stopPropagation();
    const { forSim, base } = this.props
    this.props.updateSingleExperiment({ deleteArr: [id] }, 'delete');
    this.props.updateExperimentInfo((forSim || []).filter(item => item !== id), 'forSim');
  }

  deleteExperiments = () => {
    const { forSim, base } = this.props
    const deleteArr = forSim.filter(id => id !== base.id)
    this.props.updateSingleExperiment({ deleteArr }, 'delete')
    this.props.updateExperimentInfo([], 'forSim')
  }

  setRenameExperiment = (e, id, { name }) => {
    e.stopPropagation()
    if (name === baseName) {
      return;
    }
    this.setState({ renameExp: { id, name } }, () => { this.input && this.input.focus() });
  }

  changeExperimentName = (e) => {
    const name = e.target.value;
    const { renameExp: { id } } = this.state;
    this.setState({ renameExp: { id, name } })
  }

  changeSelectedTitle = (e, comp, parentIndex, type) => {
    const { base, experiments } = this.props;
    const { component, subType } = comp;
    let _type = type;
    if (subType && type === PASSIVE) {
      _type = subType;
    }
    const index = parentIndex > -1 ? parentIndex : 0;
    let newBase = JSON.parse(JSON.stringify(base));
    let baseChange = newBase.Interfaces[index][_type].find(item => item.component === component);
    let newExperiments = JSON.parse(JSON.stringify(experiments));
    if (baseChange) {
      baseChange.visible = e.target.checked;
    }
    newExperiments.forEach(exp => {
      let expChange = exp.Interfaces[index][_type] ? exp.Interfaces[index][_type].find(item => item.component === component) : null;
      if (expChange) {
        expChange.visible = e.target.checked;
      }
    })
    this.props.updateExperimentComp(newBase, newExperiments)
    this.setState({
      updateColumns: true
    })
  }

  changeExperimentPCB = (_new) => {
    const { currentProjectId } = this.props;
    this.props.updateSingleExperiment({ ..._new, projectId: currentProjectId }, PCB)
  }

  deleteColumn = (e, key, parentIndex) => {
    e.stopPropagation();
    this.props.reduceSweepColumn(key, parentIndex)
  }

  createExpsByPassive = (R_values, record) => {
    this.props.addExperimentsByPassive(R_values, record)
  }

  closeSchematicPanel = () => {
    this.props._updateSweepSchematicPreLayoutInfo(null);
  }

  render() {
    const { base, experiments, forSim, currentProjectId, schematicPreLayoutInfo, sweepCreateStatus } = this.props;
    return (
      <div className='sierra-content-setting sierra-sweep-content-setting' style={{ minWidth: base && base.Interfaces && base.Interfaces.length ? 300 + (base.Interfaces.length * 800) : 1000 }}>
        <div className='sierra-content-wrap'>
          <div className='sierra-content-block-border'>
            <span className="font-bold sierra-setup-title-color">Experiments</span>
            <PlusCircleOutlined
              className='signal-add-icon sierra-sweeping-add-icon'
              onClick={this.props.addExperiment} />
            {forSim && forSim.length > 0 && <DeleteOutlined
              className='signal-add-icon sierra-sweeping-delete-icon'
              onClick={this.deleteExperiments} />}
            <Table
              className='space-10 sierra-sweeping-table'
              size='small'
              columns={this.state.columns}
              dataSource={getExperimentData([base, ...experiments])}
              rowKey={(record) => record.name}
              tableLayout="fixed"
            />
          </div>
        </div>
        {schematicPreLayoutInfo && schematicPreLayoutInfo.id ?
          <PreLayoutSchematicSetup
            expId={schematicPreLayoutInfo.id}
            expName={schematicPreLayoutInfo.name}
            pcb={schematicPreLayoutInfo.currentPCB}
            isSweepBase={schematicPreLayoutInfo.isSweepBase}
            interfaceIndex={schematicPreLayoutInfo.interfaceIndex}
            basePCB={schematicPreLayoutInfo.basePCB}
            currentProjectId={currentProjectId}
            experiments={experiments}
            base={base}
            sweepCreateStatus={sweepCreateStatus}
            closePanel={this.closeSchematicPanel}
            _createExpByTrace={this.props._createExpByTrace}
            changeExperimentPCB={this.changeExperimentPCB}
            updateSweepCreateStatus={this.props.updateSweepCreateStatus}
            updateSingleExperiment={this.props.updateSingleExperiment}
            updateSchematicPreLayoutInfo={this.props._updateSweepSchematicPreLayoutInfo}
          />
          : null}
      </div>
    );
  }

}

const mapState = (state) => {
  const { SierraReducer: {
    project: { currentProjectId, currentProjectVerifications },
    sierra: { currentConfig, },
    simulationReducer: { experimentQueue = [] },
    sweep: { experimentInfo, sweepCreateStatus, schematicPreLayoutInfo, sweepMeasurementInfo },
    library: { libraryTreeInfo }
  } } = state;
  const { base = {}, experiments = [], update = false, forSim = [], id, tableColumns, hasSchematic } = experimentInfo;
  return {
    libraryTreeInfo,
    base,
    experiments,
    update,
    currentProjectId,
    clock: currentConfig && currentConfig.clock ? currentConfig.clock : {},
    forSim,
    id,
    tableColumns,
    experimentQueue,
    sweepCreateStatus,
    currentProjectVerifications,
    schematicPreLayoutInfo,
    hasSchematic,
    sweepMeasurementInfo
  };
}

const mapDispatch = (dispatch) => ({
  _updateLibraryTree(type) {
    dispatch(updateLibraryTree(type))
  },
  addExperiment() {
    dispatch(addExperiment())
  },
  addExperimentsByPassive(R_values, record) {
    dispatch(addExperimentsByPassive(R_values, record))
  },
  updateSingleExperiment(params, currentType) {
    dispatch(updateSingleExperiment({ currentType, params }))
  },
  updateExperimentInfo(info, currentType) {
    dispatch(updateExperimentInfo(info, currentType))
  },
  updateExperimentComp(base, experiments) {
    dispatch(updateExperimentComp({ base, experiments }))
  },
  reduceSweepColumn(key, parentIndex) {
    dispatch(reduceSweepColumn(key, parentIndex))
  },
  updateSweepCreateStatus(status) {
    dispatch(updateSweepCreateStatus(status))
  },
  createExpsByModel(modelsList, interfaceIndex) {
    dispatch(createExpsByModel(modelsList, interfaceIndex))
  },
  _createExpByTrace(newExps) {
    dispatch(createExpByTrace(newExps))
  },
  _updateSweepSchematicPreLayoutInfo(schematicPreLayoutInfo) {
    dispatch(updateSweepSchematicPreLayoutInfo(schematicPreLayoutInfo))

  }
})

export default connect(mapState, mapDispatch)(Sweeping);
