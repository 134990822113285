import { getSierraLayoutComponents } from "..";
import { getComponentsWithNetList } from "../../PCBHelper";
import { CLKTYPE, CUSTOM } from "../../PCBHelper/constants";
import { getDefaultIndex, getDefaultName } from "../../helper/setDefaultName";
import DesignInfo from '../pcbInfo';
import _ from 'lodash';

function getConnectionsInterfaces({ connections, pcbInterfaceInfo, interfaceType }) {
  let connInterfaces = new ConnInterfaces();
  connInterfaces.initData({ connections, pcbInterfaceInfo, interfaceType });
  return connInterfaces.connectorSignals();
}

function addSingleInterfaceToConn({ pcbInterfaceInfo, connInterfaces }) {
  const existKeys = connInterfaces.map(item => item.allConnKeys).flat(2);
  for (let pcbId of Object.keys(pcbInterfaceInfo || {})) {
    const allInterfaceCompNetData = pcbInterfaceInfo[pcbId].allInterfaceCompNetData;
    for (let info of allInterfaceCompNetData) {
      const content = info.content || []
      const interfaceKeys = content.map(item => `${pcbId}::${item.name}::${info.interfaceKey}`);
      const filterKeys = interfaceKeys.filter(item => !existKeys.includes(item))
      if (!filterKeys.length) {
        continue;
      }

      const index = getDefaultIndex(connInterfaces.length, connInterfaces.map(item => item.index));
      const _content = content.filter(item => filterKeys.includes(`${pcbId}::${item.name}::${info.interfaceKey}`));
      connInterfaces.push({
        index: index,
        allConnKeys: [...filterKeys],
        dataList: _content.map(item => {
          return {
            allConnKeys: [`${pcbId}::${item.name}::${info.interfaceKey}`],
            index: parseInt(index),
            pcbConnectionList: [pcbId],
            pcbConns: [pcbId],
            signal: item.name,
            signalName: item.signalMergeName,
            signalNetName: item.signalMergeName,
            signalList: { [pcbId]: [{ ...item, interfaceKey: info.interfaceKey, type: info.type }] }
          }
        })
      })
    }
  }
  return connInterfaces;
}

/**
 *  prevInterfaces { type, interfaceName, signalNames, pcbs:[],interfaces:[] }
 *  */
const NEW_INTERFACE = 2;
function initNewInterfaceBySignals({ selectedSignalKeys, connInterfaces, interfaceName, interfaceList, channelList = [] }) {
  const signals = connInterfaces.filter(item => selectedSignalKeys.includes(item.signalName));
  const interfaceNames = [...interfaceList.map(item => item.interfaceName), ...channelList.map(item => item.name)];
  const prevIndex = interfaceName === NEW_INTERFACE ? -1 : interfaceList.findIndex(item => item.interfaceName === interfaceName);
  const prevInterfaces = prevIndex > -1 ? interfaceList[prevIndex] : null;
  let interfaces = prevInterfaces && prevInterfaces.interfaces ? [...prevInterfaces.interfaces] : [],
    signalNames = prevInterfaces ? prevInterfaces.signalNames || [] : [];
  let pcbInterfaces = {}, verType = "", signalNameList = [];

  for (let signal of signals) {
    //update signal name
    let signalName = signal.signalName;
    if (signalNames.includes(signalName)) {
      signalName = getDefaultName({ nameList: signalNames, key: "", defaultKey: signalName, firstIndex: 1, delimiter: "_" })
    }
    //add interface by pcb
    for (let pcbId of signal.pcbConns) {
      const signalList = signal.signalList[pcbId] || [];

      if (!pcbInterfaces[pcbId]) {
        pcbInterfaces[pcbId] = {
          type: signalList[0].type,
          signals: [{
            name: signalName,
            nets: [...new Set(signalList.map(it => it.nets).flat(2))]
          }]
        }
        verType = !verType ? signalList[0].type : verType;
      } else {
        pcbInterfaces[pcbId].signals.push({
          name: signalName,
          nets: [...new Set(signalList.map(it => it.nets).flat(2))]
        })
      }
    }
    signalNameList.push(signalName);
  }

  let newInterfaceName = verType || "Interface";
  if (interfaceNames.includes(newInterfaceName)) {
    newInterfaceName = getDefaultName({ nameList: interfaceNames, key: "", defaultKey: verType || "Interface", firstIndex: 1 })
  }
  for (let pcbId of Object.keys(pcbInterfaces)) {
    const index = interfaces.findIndex(item => item.designId === pcbId);
    if (index > -1) {
      const filterSignals = pcbInterfaces[pcbId].signals.filter(item => !interfaces[index].content.find(it => _.isEqual(it.nets.sort(), item.nets.sort())));
      interfaces[index].content.push(...filterSignals);
    } else {
      interfaces.push({
        type: pcbInterfaces[pcbId].type,
        designId: pcbId,
        interfaceName: newInterfaceName,
        content: [...pcbInterfaces[pcbId].signals]
      })
    }
  }

  if (interfaceName === NEW_INTERFACE) {
    interfaceList.push({
      type: verType,
      interfaceName: newInterfaceName,
      interfaces,
      signalNames: signalNameList
    })
  } else {
    interfaceList[prevIndex].interfaces = interfaces;
  }
  return interfaceList;
}

class ConnInterfaces {
  constructor() {
    this.connections = [];//[{Aboard,Aconn,Bboard,Bconn}]
    this.pcbInterfaceInfo = {};
    this.netCompPins = {};//key->signalName,value:[{name:compName,pin,net,...}]
    this.pcbList = [];//pcbName list
    this.pcbIdInfo = {};//key -> pcbName,value -> pcbId
    this.signals = [];
    this.connectionSignals = [];
  }

  initData = ({ connections, pcbInterfaceInfo, interfaceType }) => {
    this.connections = connections || [];
    this.interfaceType = interfaceType;

    for (let item of Object.keys(pcbInterfaceInfo || {})) {
      const pcbName = pcbInterfaceInfo[item].allInterfaceCompNetData[0].pcbs[0];
      this.pcbList.push(pcbName);
      this.pcbIdInfo[pcbName] = item;
      if (!this.signals[pcbName]) {
        this.signals[pcbName] = []
      }
      for (let info of pcbInterfaceInfo[item].allInterfaceCompNetData) {
        this.signals[pcbName].push(...info.content.map(item => { return { ...item, type: info.type, interfaceKey: info.interfaceKey } }))
      }
    }
    //connections has pcb list
    let connPCBs = [];
    this.pcbConnections = [];
    for (let item of this.connections) {
      if (this.pcbList.includes(item.Aboard) && this.pcbList.includes(item.Bboard)) {

        const index = this.pcbConnections.findIndex(it => it.Aboard === item.Aboard && it.Bboard === item.Bboard);
        if (index > -1) {
          const findItem = this.pcbConnections[index].connections.find(it => _.isEqual(it.Aconn, item.Aconn) && _.isEqual(it.Bconn, item.Bconn))
          !findItem && this.pcbConnections[index].connections.push({ ...item });
        } else {
          this.pcbConnections.push({
            Aboard: item.Aboard,
            Bboard: item.Bboard,
            connections: [{ ...item }]
          });
        }
        connPCBs.push(item.Aboard, item.Bboard)
      }
    }
    connPCBs = [...new Set(connPCBs)]
    this.pcbInfo = {};

    for (let pcbName of connPCBs) {

      const pcbId = this.pcbIdInfo[pcbName];
      if (pcbInterfaceInfo[pcbId].allInterfaceCompNetData.length) {
        this.pcbInterfaceInfo[pcbName] = JSON.parse(JSON.stringify(pcbInterfaceInfo[pcbId].allInterfaceCompNetData));
        const pcbInfo = DesignInfo.getPCBInfo(pcbId);
        this.pcbInfo[pcbId] = {
          ...pcbInfo
        }
      }
    }
    this.searchNetCompPins()
  }

  searchNetCompPins = () => {
    for (let pcbName of Object.keys(this.pcbInterfaceInfo || {})) {

      const interfaceList = this.pcbInterfaceInfo[pcbName];
      const { netsList: pcbNetsList, layers } = this.pcbInfo[interfaceList[0].pcbId] || {};
      const CompsInfo = getSierraLayoutComponents(layers);
      for (let intItem of interfaceList || []) {
        for (let signalItem of intItem.content || []) {
          if (!this.netCompPins[pcbName]) {
            this.netCompPins[pcbName] = {}
          }
          const signalCompPins = getComponentsWithNetList({
            netList: [...signalItem.nets || []],
            pcbNetsList,
            layers,
            CompsInfo,
            findPinName: true
          });
          this.netCompPins[pcbName][`${signalItem.name}::${intItem.interfaceKey}`] = signalCompPins;
        }
      }
    }
  }

  connectorSignals = () => {
    this.connSignalsByConnectorComps();
    this.mergeConnSignals();
    this.groupConnSignals();//i2c/spi/...
    return /* this.targetConnSignals */this.targetInterfaces || [];
  }

  getConnPinList = ({ findPinList, connections }) => {
    let connList = []
    for (let pinInfo of findPinList) {
      let findConn = connections.find(item => item.Aconn.component === pinInfo.name && item.Aconn.pin && (item.Aconn.pin === pinInfo.pin || item.Aconn.pin === pinInfo.pinName));

      if (!findConn) {
        findConn = connections.find(item => item.Aconn.component === pinInfo.name && !item.Aconn.pin);
      }

      if (!findConn || (!findConn.Bconn || !findConn.Bconn.component)) {
        continue;
      }

      let conn = JSON.parse(JSON.stringify(findConn));

      if (!conn.Aconn.pin) {
        conn.Aconn.pin = pinInfo.pin;
        conn.Aconn.pinName = pinInfo.pinName;
      }

      if (!conn.Bconn.pin) {
        conn.Bconn.pin = pinInfo.pin;
        conn.Bconn.pinName = pinInfo.pinName;
      }

      connList.push(conn);
    }
    return connList;
  }

  connSignalsByConnectorComps = () => {
    this.connectionSignals = [];
    let index = 0;
    for (let connItem of this.pcbConnections) {
      //item -> { Aboard, Bboard, connections:[ {Aconn, Bconn } ] }
      const { Aboard, Bboard, connections = [] } = connItem;
      const ASignalList = this.signals[Aboard],
        BSignalList = this.signals[Bboard],
        ANetCompPins = this.netCompPins[Aboard],
        BNetCompPins = this.netCompPins[Bboard];
      const AConnComp = connections.map(item => item.Aconn.component);

      for (let signal of ASignalList) {
        const compPins = ANetCompPins[`${signal.name}::${signal.interfaceKey}`];
        const findPinList = compPins.filter(item => AConnComp.includes(item.name));
        if (!findPinList.length) {
          continue;
        }
        const connList = this.getConnPinList({ findPinList, connections });
        if (!connList.length) {
          continue;
        }
        let connSignals = this.getConnSignals({ BSignalList, connList, BNetCompPins, findPinList });
        if (!connSignals.length) {
          continue;
        }
        let ASignalKey = `${this.pcbIdInfo[Aboard]}::${signal.name}::${signal.interfaceKey}`,
          BSignalKeys = connSignals.map(item => `${this.pcbIdInfo[Bboard]}::${item.name}::${item.interfaceKey}`);

        if ([CUSTOM, CLKTYPE].includes(this.interfaceType)) {
          for (let connItem of connSignals) {
            const BSignalKey = `${this.pcbIdInfo[Bboard]}::${connItem.name}::${connItem.interfaceKey}`;
            this.connectionSignals.push({
              index,
              Aboard: { pcbId: this.pcbIdInfo[Aboard], pcbName: Aboard },
              Bboard: { pcbId: this.pcbIdInfo[Bboard], pcbName: Bboard },
              ASignal: JSON.parse(JSON.stringify(signal)),
              BSignal: JSON.parse(JSON.stringify(connItem)),
              ASignalKey,
              BSignalKey,
              BAllSignalKeys: [BSignalKey],
              allConnKeys: [ASignalKey, BSignalKey]
            });
            index++;
          }
        } else {
          if (connSignals.length > 1) {
            connSignals = [{
              interfaceKey: connSignals[0].interfaceKey,
              name: connSignals[0].name,
              nets: [...new Set(connSignals.map(item => item.nets).flat(2))],
              signalMergeName: connSignals[0].signalMergeName,
              type: connSignals[0].name
            }];
            BSignalKeys = connSignals.map(item => `${this.pcbIdInfo[Bboard]}::${item.name}::${item.interfaceKey}`)
          }
          this.connectionSignals.push({
            index,
            Aboard: { pcbId: this.pcbIdInfo[Aboard], pcbName: Aboard },
            Bboard: { pcbId: this.pcbIdInfo[Bboard], pcbName: Bboard },
            ASignal: JSON.parse(JSON.stringify(signal)),
            BSignal: JSON.parse(JSON.stringify(connSignals[0])),
            ASignalKey,
            BSignalKey: BSignalKeys[0],
            BAllSignalKeys: BSignalKeys,
            allConnKeys: [ASignalKey, ...BSignalKeys]
          });
          index++;
        }
      }
    }
  }

  /** PCB Connection
   * A - B - C - D
   * A - E
   * A - B - F
   * B - C - G
   *
   *  */
  mergeConnSignals = () => {
    //A - B - C - D
    this.combineSignalsByPcbConns();
    //A - B - D
    //A - C
    //B - E
    this.combineSignalsBySamePCBSignal();
    //Signal name check
    let nameList = [];
    for (let item of this.targetConnSignals) {
      const samePcbList = findSameConnPCB(item.pcbConnectionList, item.pcbConns);
      let signals = [];
      for (let pcbId of samePcbList) {
        signals.push(...item.signalList[pcbId].map(it => { return { name: it.signalMergeName, nets: [...it.nets] } }));
      }
      //Take the name of the signal with the largest number of nets as the signal name
      /*  signals = signals.sort((a, b) => b.nets.length - a.nets.length); */

      let signalName = signals[0].name;
      //When the number of nets is equal, choose the name with the shortest length
      /*    if (signals[0] & signals[1] && signals[1].nets.length === signals[0].nets.length) {
           const filterSignals = signals.filter(it => it.nets.length === signals[0].nets.length).sort((a, b) => a.name.length - b.name.length);
           signalName = filterSignals[0] && filterSignals[0] ? filterSignals[0].name : signalName
         } */
      if (nameList.includes(signalName)) {
        item.signalName = getDefaultName({ nameList, key: "", defaultKey: signalName, firstIndex: 1, delimiter: "_" });
      } else {
        item.signalName = signalName;
      }
      item.signal = item.signalName;
      item.signalNetName = item.signalName;
      item.interfaceKeys = [...item.allConnKeys.map(item => {
        const [pcbId, /* type */, key] = item.split("::");
        return `${pcbId}::${key}`;
      })]
      nameList.push(item.signalName);
    }
  }

  combineSignalsByPcbConns = () => {
    //A - B - C - D
    const connKeys = this.connectionSignals.map(item => [...item.allConnKeys]).flat(2);
    let connKeyObj = {};
    for (let item of connKeys) {
      if (connKeyObj[item]) {
        connKeyObj[item] += 1
      } else {
        connKeyObj[item] = 1;
      }
    }
    this.multiConnKeys = Object.keys(connKeyObj).filter(item => connKeyObj[item] > 1);
    //name, interfaceKey
    this.connectionInterfaces = [];
    this.connSignalList = [];
    this.existIndexList = [];
    //{pcbList:[1,2,3],signalList:[1,2,3]}
    //A - B - C - D
    for (let signal of this.connectionSignals) {
      const { index, Aboard, Bboard, ASignal, BSignal, allConnKeys } = signal;
      if (this.existIndexList.includes(index)) {
        continue;
      }
      let connObj = {
        signal: ASignal.name,
        signalNetName: ASignal.signalMergeName,
        signalList: {
          [Aboard.pcbId]: [{ ...ASignal }],
          [Bboard.pcbId]: [{ ...BSignal }],
        },
        pcbConns: [Aboard.pcbId, Bboard.pcbId],
        allConnKeys: [...allConnKeys],
        index
      };
      this.existIndexList.push(index)
      this.mergeSignal({ ...signal, connObj });
    }
  }

  mergeSignal = ({
    allConnKeys,
    index,
    ASignalKey,
    connObj,
    BAllSignalKeys,
  }) => {
    //A - B
    const multiConnSignalKeys = allConnKeys.filter(item => this.multiConnKeys.includes(item));
    if (multiConnSignalKeys.length) {
      const findConnSignals = this.connectionSignals.filter(item => !this.existIndexList.includes(item.index) && item.index !== index && item.allConnKeys.find(it => multiConnSignalKeys.includes(it)))
      if (!findConnSignals.length) {
        this.connSignalList.push({ ...connObj });
        return;
      }
      const BConnSignals = findConnSignals.filter(item => BAllSignalKeys.includes(item.ASignalKey));
      const AConnSignals = findConnSignals.filter(item => item.BAllSignalKeys.includes(ASignalKey));

      if (!BConnSignals.length && !AConnSignals.length) {
        this.connSignalList.push({ ...connObj });
        return;
      }

      for (let sigItem of BConnSignals) {
        // A - B - C
        connObj.signalList[sigItem.Bboard.pcbId] = [{ ...sigItem.BSignal }];
        connObj.pcbConns.push(sigItem.Bboard.pcbId);
        connObj.allConnKeys.push(...sigItem.allConnKeys);
        this.existIndexList.push(sigItem.index);
        this.mergeSignal({ ...sigItem, connObj });
      }

      for (let sigItem of AConnSignals) {
        // H - A - B 
        connObj.signalList[sigItem.Aboard.pcbId] = [{ ...sigItem.ASignal }];
        connObj.pcbConns.unshift(sigItem.Aboard.pcbId);
        connObj.allConnKeys.push(...sigItem.allConnKeys);
        const filterBKeys = sigItem.BAllSignalKeys.filter(it => it !== ASignalKey);
        if (filterBKeys.length) {
          if (!connObj.signalList[sigItem.Bboard.pcbId]) {
            connObj.signalList[sigItem.Bboard.pcbId] = []
          }
          connObj.signalList[sigItem.Bboard.pcbId].push({ ...sigItem.BSignal });
        }
        this.existIndexList.push(sigItem.index);
        this.mergeSignal({ ...sigItem, connObj });
      }
      //A - B / A - C 
    } else {
      this.connSignalList.push({ ...connObj })
    }
  }

  getConnSignals = ({ BSignalList, connList, BNetCompPins }) => {
    let connSignals = []
    for (let signal of BSignalList) {
      const compPins = BNetCompPins[`${signal.name}::${signal.interfaceKey}`] || [];
      //find pin number
      const filterPins = compPins.filter(item => connList.find(it => it.Bconn.component === item.name && item.pin === it.Bconn.pin))
      if (filterPins.length) {
        connSignals.push(JSON.parse(JSON.stringify(signal)));
        continue;
      }

      const filterPinNames = compPins.filter(item => connList.find(it => it.Bconn.component === item.name && item.pinName && (item.pinName === it.Bconn.pin || item.pinName === it.Bconn.pinName)))
      if (filterPinNames.length) {
        connSignals.push(JSON.parse(JSON.stringify(signal)));
        continue;
      }
    }
    return connSignals;
  }

  combineSignalsBySamePCBSignal = () => {
    this.targetConnSignals = [];
    this.existSignalsIndexList = [];
    if ([CUSTOM, CLKTYPE].includes(this.interfaceType)) {
      this.targetConnSignals = JSON.parse(JSON.stringify(this.connSignalList || []))
    } else {
      for (let info of this.connSignalList || []) {
        if (this.existSignalsIndexList.includes(info.index)) {
          continue;
        }
        this.existSignalsKeyList = [];
        this.findSameKeysConns = [];
        this.findSameSignalsConn([{ ...info }], this.findSameKeysConns);
        if (!info.pcbConnectionList || !info.pcbConnectionList.length) {
          info.pcbConnectionList = [[...info.pcbConns]];
        }
        this.existSignalsIndexList.push(info.index);
        if (!this.findSameKeysConns.length) {
          this.targetConnSignals.push(info);
          continue;
        }

        for (let conn of this.findSameKeysConns) {
          info.pcbConns = [...new Set([...info.pcbConns, ...conn.pcbConns])];
          if (!info.pcbConnectionList.find(item => _.isEqual(item, conn.pcbConns))) {
            info.pcbConnectionList.push([...conn.pcbConns]);
          }

          info.allConnKeys.push(...conn.allConnKeys);
          for (let pcbId of conn.pcbConns) {
            if (!conn.signalList[pcbId] || !conn.signalList[pcbId][0] || !conn.signalList[pcbId][0].nets) {
              continue;
            }

            if (info.signalList[pcbId] && info.signalList[pcbId][0] && info.signalList[pcbId][0].nets) {
              info.signalList[pcbId][0].nets.push(...conn.signalList[pcbId][0].nets)
              info.signalList[pcbId][0].nets = [...new Set(info.signalList[pcbId][0].nets)]
            } else {
              info.signalList[pcbId] = JSON.parse(JSON.stringify(conn.signalList[pcbId]));
            }
          }
          this.existSignalsIndexList.push(conn.index);
        }
        this.targetConnSignals.push(info);
      }
    }
  }

  findSameSignalsConn = (sameKeysConns) => {
    const notCombineFind = this.connSignalList.find(item => !this.existSignalsKeyList.includes(item.index));
    if (!notCombineFind) {
      return this.findSameKeysConns;
    }

    for (let info of sameKeysConns || []) {

      if (this.existSignalsKeyList.includes(info.index)) {
        continue;
      }
      this.existSignalsKeyList.push(info.index);
      const findSameKeysConns = this.connSignalList.filter(item =>
        item.index !== info.index
        && !this.existSignalsKeyList.includes(item.index)
        && !!item.allConnKeys.find(it => info.allConnKeys.includes(it)));

      if (findSameKeysConns.length) {
        this.findSameKeysConns.push(...findSameKeysConns);
        this.findSameSignalsConn(findSameKeysConns)
      } else {
        continue;
      }
    }
  }

  groupConnSignals = () => {
    this.targetInterfaces = [];
    let existKeys = [], noGroupItemList = [];
    for (let info of this.targetConnSignals) {
      if (existKeys.includes(info.allConnKeys[0])) {
        continue;
      }
      const currKeys = info.interfaceKeys;
      const filterInfo = this.targetConnSignals.filter(item => item.index !== info.index && item.interfaceKeys.includes(currKeys[0]));
      if (!filterInfo || !filterInfo.length || [CUSTOM, CLKTYPE].includes(this.interfaceType)) {
        noGroupItemList.push(JSON.parse(JSON.stringify(info)));
        continue;
      }
      const allConnKeys = [...info.allConnKeys, ...filterInfo.map(item => item.allConnKeys).flat(2)]
      existKeys.push(...allConnKeys)
      const indexList = [info.index, ...filterInfo.map(item => item.index)]
      noGroupItemList = noGroupItemList.filter(item => !indexList.includes(item.index));
      this.targetInterfaces.push({
        index: this.targetInterfaces.length.toString(),
        dataList: [info, ...filterInfo],
        allConnKeys: [...allConnKeys]
      })
    }
    if (noGroupItemList.length) {
      this.targetInterfaces.push(...noGroupItemList.map((item, index) => ({
        index: (this.targetInterfaces.length + index).toString(),
        dataList: [item],
        allConnKeys: [...item.allConnKeys]
      })))
    }
  }
}

function findSameConnPCB(pcbConnectionList, pcbConns) {
  if (!pcbConnectionList || !pcbConnectionList.length || !pcbConnectionList.length === 1) {
    return [...pcbConns];
  }
  let filterList = pcbConns.filter(item => pcbConnectionList.filter(it => it.includes(item)).length === pcbConnectionList.length)
  if (filterList.length) {
    return filterList;
  }
  filterList = pcbConns.map(item => { return { pcbId: item, length: pcbConnectionList.filter(it => it.includes(item)).length } })
  filterList = filterList.sort((a, b) => b.length - a.length);

  if (filterList[0] && filterList[0].length > 1) {
    return [filterList[0]];
  }
  return [...pcbConns];
}

export {
  getConnectionsInterfaces,
  initNewInterfaceBySignals,
  addSingleInterfaceToConn
}