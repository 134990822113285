import React, { Component, Fragment } from 'react';
import { Select } from 'antd';
import { ROCKY } from '../../constants/pageType';

const { Option } = Select;

class ExtractionYaml extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  extractionOptionSelect = ({ value, title, selectOptions, changeExtractionOptions }) => {
    const { product, allowModify } = this.props;
    return <div className='extraction-content'>
      <span className='extraction-content-title extraction-content-hfss-title'>{title}</span>
      <Select
        placeholder={title}
        value={value || ''}
        onChange={changeExtractionOptions}
        className={"aurora-select extraction-input extraction-hfss-input-width"}
        popupClassName='aurora-select-dropdown'
        allowClear
        disabled={product === ROCKY && !allowModify}
      >
        {selectOptions.map(item => <Option
          key={item.id}
          value={item.id}
          title={item.name}
        >{item.name}</Option>)}
      </Select>
    </div>
  }

  changeExtractionOptions = (value, key, subKey) => {
    let config = { ...this.props.extraction };
    if (!config || !config[key]) { return }
    if (value) {
      config[key][subKey] = value;
    } else {
      delete config[key][subKey];
    }
    this.props.updateChannelExtractionConfig(config);
  }

  render = () => {
    const { extraction = {}, simulateHFSS, ansysType, extractionHfssOptionsList, extractionSiwaveOptionsList } = this.props;

    return <Fragment>
      {ansysType === 'SIwave' ? this.extractionOptionSelect({
        value: extraction.siwave && extraction.siwave.siwaveOptionLibraryId ? extraction.siwave.siwaveOptionLibraryId : '',
        title: 'SIwave Option File',
        selectOptions: extractionSiwaveOptionsList || [],
        changeExtractionOptions: (value) => this.changeExtractionOptions(value, "siwave", "siwaveOptionLibraryId")
      }) : null}
      {simulateHFSS ? this.extractionOptionSelect({
        value: extraction.hfss && extraction.hfss.hfssOptionLibraryId ? extraction.hfss.hfssOptionLibraryId : '',
        title: 'HFSS Option File',
        selectOptions: extractionHfssOptionsList || [],
        changeExtractionOptions: (value) => this.changeExtractionOptions(value, "hfss", "hfssOptionLibraryId")
      }) : null}
    </Fragment>
  }
}

export default ExtractionYaml;