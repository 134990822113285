export const
  CAPACITOR = "capacitor",
  RESISTOR = "resistor",
  INDUCTOR = "inductor",
  Capacitor = "Capacitor",
  Resistor = "Resistor",
  Inductor = "Inductor",
  RLCDisplayList = [{ type: CAPACITOR, title: Capacitor }, { type: RESISTOR, title: Resistor }, { type: INDUCTOR, title: Inductor }];

/* Apply signal settings cons */
const APPLY_SECTION_ALL = "applySectionAll",
  APPLY_RLC_SECTION_ALL = "applyRLCSectionAll",
  APPLY_INSERTION_LOSS_ALL = "applyInsertionLossAll",
  PRE_LAYOUT_SIGNAL_SETUP_CONFIG = {
    [APPLY_SECTION_ALL]: true,
    [APPLY_RLC_SECTION_ALL]: true,
    [APPLY_INSERTION_LOSS_ALL]: true
  },
  SETUP_CONFIG_LIST = [{
    title: "Apply insertion loss to all selected signals",
    key: APPLY_INSERTION_LOSS_ALL
  }, {
    title: "Apply RLC section setup to all selected signals",
    key: APPLY_RLC_SECTION_ALL
  }, {
    title: "Apply trace and via section setup to all selected signals",
    key: APPLY_SECTION_ALL
  }];

export {
  APPLY_SECTION_ALL,
  APPLY_RLC_SECTION_ALL,
  APPLY_INSERTION_LOSS_ALL,
  SETUP_CONFIG_LIST,
  PRE_LAYOUT_SIGNAL_SETUP_CONFIG
}

export {
  PreLayoutSignal,
  TemplateSections,
  PreLayoutComponent,
  RLCSections,
  PreLayoutSignalGroup,
  PreLayoutPin,
  preLayoutModelInfo,
  PreLayoutSignalCPHY
} from './IntegratedPreLayout';

export {
  numSplit,
  getRLCText,
  SectionColumn
} from './preLayoutHelper';
