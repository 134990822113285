import React, { Component, Fragment } from 'react';
import Panel from '../../../components/Panel';
import { createPortal } from 'react-dom';
import { getLibraryFileByPath } from '@/services/api/pdn/library';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { POWER_DOMAIN_FILE, POWER_DOMAIN_TOUCHSTONE } from '../constants';
import { getLibraryTouchstoneFilePromise } from '../../../services/PDN/library';
import './pdnPanel.css';
class PowerDomainFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileContent: null,
      maximize: false,
      panelHeight: 478,
      height: 478
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const { powerdomainFileInfo } = this.props;
    this.getLibrary(powerdomainFileInfo);
    this.screenChange();
  }

  componentDidUpdate(prevProps) {
    const { powerdomainFileInfo } = this.props;
    if (prevProps.powerdomainFileInfo &&
      prevProps.powerdomainFileInfo.name !== powerdomainFileInfo.name) {
      this.getLibrary(powerdomainFileInfo)
    }
  }

  getLibrary = (info) => {
    if (info.dataType === POWER_DOMAIN_TOUCHSTONE) {
      getLibraryTouchstoneFilePromise(info.id, `${info.folderName ? `${info.folderName}/` : ''}${info.name}`).then(res => {
        this.setState({
          fileContent: res
        })
      }, error => {
        this.setState({
          fileContent: ""
        })
      })
    } else if (info.dataType === POWER_DOMAIN_FILE) {
      getLibraryFileByPath({ libraryId: info.id, path: `${info.folderName ? `${info.folderName}/` : ''}${info.name}` }).then(res => {
        if (res && res.data) {
          this.setState({
            fileContent: res.data
          })
        } else {
          this.setState({
            fileContent: ""
          })
        }
      })
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { maximize } = this.state;
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (!maximize) {

      if (!width) {
        _width = 600
      };
      _width = width * 0.6;
      _width = _width < 600 ? 600 : _width;

      if (!height) {
        _height = 400;
      };
      _height = 478;
      _height = _height;
    } else {

      if (!width) {
        _width = 1000
      };
      _width = width - 40;
      _width = _width < 800 ? 800 : _width;

      if (!height) {
        _height = 1000
      };
      _height = height - 40;
      _height = _height;
    }

    this.setState({
      panelWidth: _width,
      panelHeight: _height,
      height: _height,
    });
  }

  getDefaultWidth = (status) => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    let _width = width * 0.6;
    _width = _width < 600 ? 600 : _width;
    return _width;
  }

  changeSizeMax = (e) => {
    e.stopPropagation();
    const { maximize } = this.state;
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (maximize) {

      if (!width) {
        _width = 600
      };
      _width = width * 0.6;
      _width = _width;

      if (!height) {
        _height = 400;
      };
      _height = 478;
      _height = _height;
    } else {

      if (!width) {
        _width = 1000
      };
      _width = width - 40;
      _width = _width;

      if (!height) {
        _height = 1000
      };
      _height = height - 40;
      _height = _height;
    }

    this.setState({
      maximize: !maximize,
      panelWidth: _width,
      panelHeight: _height,
      height: _height,
    });
  }

  changePanelSize = (obj) => {
    this.setState({
      height: obj.height,
      panelHeight: obj.height,
    })
  }

  render() {
    const { powerdomainFileInfo, closeModal } = this.props;
    const { fileContent, maximize, panelHeight, panelWidth, height } = this.state;
    const content = (
      <Panel
        className='decap-file-panel'
        id='powerdomain-file-view'
        position={maximize ? 'panel-center-max' : 'panel-center-left'}
        title={<Fragment>
          <span>{powerdomainFileInfo.name}</span>
          {!maximize ? <FullscreenOutlined
            title='maximize'
            className='decap-graph-maximize'
            onClick={(e) => { this.changeSizeMax(e) }} />
            : <FullscreenExitOutlined
            title='minimize'
            className='decap-graph-maximize'
            onClick={(e) => { this.changeSizeMax(e) }} />}
        </Fragment>}
        zIndex={2000}
        onCancel={() => (closeModal('powerdomainFile'))}
        width={maximize ? panelWidth : this.getDefaultWidth()}
        height={panelHeight ? panelHeight : 478}
        draggable
        mask={maximize}
        minWidth={400}
        minHeight={200}
        resize={this.changePanelSize}
        overflow='hidden'
      >
        <div
          style={{ margin: 16 }}
        >
          <pre
            style={{
              overflow: 'auto',
              height: height ? height - 76 : 400,
            }}
            className='decap-file-content'
          >
            {fileContent}
          </pre>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PowerDomainFile;