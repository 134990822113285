import React from 'react';
import { Table } from 'antd';

const Columns = [{
  title: 'Signal',
  dataIndex: 'SignalName',
  width: '33%'
}, {
  title: 'Delay (ps)',
  dataIndex: 'Delay',
  width: '33%'
}, {
  title: 'Skew (ps)',
  dataIndex: 'PNSkew',
  width: '33%'
}];

function delayTable(data) {
  return (
    <Table
      columns={Columns}
      dataSource={data ? data.result : []}
      pagination={false}
      size="small"
      rowKey={(record, index) => {
        return index
      }}
    />
  )
}

export default delayTable;