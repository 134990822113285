import Parameter from './parameters';

class overUnderShootSpec extends Parameter {
  constructor(props) {
    super(props);
    this.shoot_margin = props.shoot_margin || null;
    this.area_margin = props.area_margin || null;
    this.area_margin2 = props.area_margin2 || null;
  };

  initSpec(param) {
    this.shoot_margin = { ...param.shoot_margin, pass: param.value_pass.pass === "pass" ? true : false } || null;
    this.area_margin = { ...param.area_margin, pass: param.area_pass.pass === "pass" ? true : false } || null;
    this.area_margin2 = param.area_margin2 ? { ...param.area_margin2, pass: param.area2_pass.pass === "pass" ? true : false } : null;
    this.paramInit(param);
  };
};

export default overUnderShootSpec;