import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateOptions, updateCurrentConfig } from '../../../store/sierra/action';
import OptionPanel from './optionPanel';

class SetupOptionPanel extends Component {

  render() {
    const { currentConfig } = this.props;
    return <OptionPanel
      {...this.props}
      currentConfig={currentConfig}
      _updateOptions={this.props._updateOptions}
      _updateCurrentConfig={this.props._updateCurrentConfig}
    />
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra } } = state;
  return {
    currentConfig: sierra.currentConfig
  };
}

const mapDispatch = (dispatch) => ({
  _updateOptions(type, value, update, fixTimeStep) {
    dispatch(updateOptions(type, value, update, fixTimeStep));
  },
  _updateCurrentConfig(currentConfig) {
    dispatch(updateCurrentConfig(currentConfig))
  }
})

export default connect(mapState, mapDispatch)(SetupOptionPanel)