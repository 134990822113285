import NP from 'number-precision';
import { numberCheck, unitConversion } from './dataProcess';

const Hz = 0, KHz = 1, MHz = 2, GHz = 3, K = 1, M = 2, G = 3, T = 4, Reg = 0, s = 0, m = -1, u = -2, n = -3, p = -4, dHz = -1, f = -5;
const scaleKeys = {
  Hz: Hz,
  KHz: KHz,
  MHz: MHz,
  GHz: GHz,
  K: K,
  M: M,
  G: G,
  T: T,
  reg: Reg,
  s: s,
  m: m,
  u: u,
  n: n,
  p: p,
  ms: m,
  us: u,
  ns: n,
  ps: p,
  mm: m,
  um: u,
  F: s,
  dHz: dHz,
  f
};
const freqUnitSupport = ['Hz', 'KHz', 'MHz', 'GHz', 'K', 'M', 'G', 'T', 'm', 'u', "reg", "n", "p", "F", "dHz", "f"];

function NPTimes(...nums) {
  const _nums = nums.map(item => parseFloat(item))
  return NP.times(..._nums)
}

function getScale(scale, prevScale, number) {
  // ['Hz' = 0, 'KHz' = 1, 'MHz' = 2, 'GHz' = 3];
  return Math.pow(number, prevScale - scale);
};

/**
 * Get frequency unit conversion
 *
 * @param {'Hz'|'KHz'|'MHz'|'GHz'|'K'|'M'|'G'} unit
 * @param {'Hz'|'KHz'|'MHz'|'GHz'|'K'|'M'|'G'} prevUnit
 * @param {number|1e3} scale
 * @returns
 */
function scaleConversion(unit, prevUnit, scale = 1e3) {
  if (!prevUnit) {
    prevUnit = 'Hz';
  }
  if (!freqUnitSupport.includes(unit) || !freqUnitSupport.includes(prevUnit)) {
    console.error('Error: scaleConversion', unit, prevUnit);
    return 1;
  }

  if (prevUnit === 'dHz' || unit === 'dHz') {
    return getScale(scaleKeys[unit], scaleKeys[prevUnit], 1e1);
  }
  return getScale(scaleKeys[unit], scaleKeys[prevUnit], scale);
};

/**
 * number conversion
 *
 * @param {'5e9'|'10000'|'302324'|'1e-9'}} number 
 * @param { 3 | -3 | 6 | -6 | 9 | -9 } scale
 * @returns
 */
function numFormatConversion(number, scale) {
  const error = numberCheck(number);
  if (error) {
    return '';
  }
  let _number = number.toString();
  let _scale = scale ? scale : 0;

  if (_number.indexOf('e') > -1) {
    let [num, digit] = _number.split('e');
    _number = NP.strip(NP.times(parseFloat(num), Math.pow(10, (parseInt(digit) + _scale))));
  } else {
    _number = NP.strip(NP.times(parseFloat(_number), Math.pow(10, _scale)));
  }

  //10000 ==> 1e4
  //0.0001 ==> 1e-4
  //0.10232 ==> 1.0232e-1
  _number = numExponentialFormat(_number);
  return _number;
}

function numExponentialFormat(number) {
  //10000 ==> 1e4
  //0.0001 ==> 1e-4
  //0.10232 ==> 1.0232e-1
  if (typeof (number) !== 'string' && typeof (number) !== 'number') {
    return number;
  }

  let index = -1;
  if (number.toString().indexOf('.') > -1) {
    index = number.toString().substring(number.toString().indexOf('.') + 1).length;
  }

  //index>==4 todo (eg: 1.234536 ==> 1.234536e0 )
  if (number >= 10000 || (number > 0 && number < 0.001) /* || index.length >= 4 */) {
    number = NP.times(parseFloat(number), 1).toExponential();

  }

  let _index = number.toString().indexOf('+');

  if (_index > -1) {//1e+3 ==> 1e3
    number = number.toString().replace('+', '');
  }
  number = number.toString();
  return number;
}

//Scientific notation to numbers
/* console.log(toNonExponential(3.3e-7))     // "0.00000033"
console.log(toNonExponential(3e-7))      // "0.0000003"
console.log(toNonExponential(1.401e10))  // "14010000000"
console.log(toNonExponential(0.0004))    // "0.0004" */
function toNonExponential(num) {
  let targetNum = num;
  if (numberCheck(num)) {
    return num;
  };
  const string = num.toString();
  if (string.indexOf("e")) {
    num = Number(num);
    const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    targetNum = num.toFixed(Math.max(0, (m[1] || '').length - m[2]))
  }
  return targetNum;
}

// Verification figures, converted into normative figures
function formatNumber(number) {
  if (isNaN(Number(number)) || !number) {
    return '0';
  } else {
    return toNonExponential(number);
  }
}

/**
@param {string} str num + unit : 0.01m / 1e2u / 1
 */
function splitNumUnit(str) {
  let num = "", unit = "";

  if (typeof (str) !== 'string' && typeof (str) !== 'number') {
    return { num, unit };
  }

  str = str.toString();
  //num reg
  const numReg = /^[0-9]\d*(\.\d+)?e(\-|\+)?\d+|^[0-9]\d*(\.\d+)?/gi;
  //find num
  num = str.match(numReg) ? str.match(numReg)[0] : "";
  if (!num) {
    return { num: "", unit };
  }
  //slice unit by num length
  unit = num.length >= 0 ? str.slice(num.length) : "";
  return { num, unit };
}

function resetDefaultValue(str) {
  //split str to num ,unit
  let { num, unit } = splitNumUnit(str);

  if (num.toString()) {//num exist
    // REG / Reg ==> reg
    const _unit = unit.length > 1 ? unit.toLowerCase() : (unit ? unit : 'Hz');
    //unit to Hz
    const scale = scaleConversion('Hz', _unit);
    //unit error unit format error
    if (scale.toString().indexOf('Error') < 0) {
      const newNum = num * scale;
      //value to 0 that is smaller than 0.01 Ohm
      //unit to ""
      if (newNum < 0.01) {
        num = 0;
        unit = "";
      }
    }
    return `${num}${unit}`;
  } else {
    return "";
  }
}

function getUnitConversionValue(value, unit, prevUnit) {
  const error = numberCheck(value);
  if (error) {
    return '';
  }
  const scale = unitConversion(scaleKeys[unit], scaleKeys[prevUnit]);
  let _value = NP.strip(NP.times(value, scale));
  _value = numExponentialFormat(_value);
  return _value;
}

/* Get a list of integers in a string */
function getIntegersInStr(str) {
  if (typeof str !== "string") {
    return [];
  }
  let pattern = new RegExp("[0-9]+", "g");
  const numList = str.match(pattern);
  return numList ? numList : [];
}

// 3.00e-10 -> 3e-10
function formatExponential(num) {
  let exponentialForm = num.toExponential();
  const [coefficient, exponent] = exponentialForm.split('e');
  const coefficientArr = parseFloat(Number(coefficient).toFixed(2))
  const coefficientStr = coefficientArr + 'e' + exponent
  return coefficientStr;
}

/*  */
function splitValueUnitByUnits(value, units) {
  if (!value) {
    return { value: "", unit: "" };
  }
  const _units = units || ['mil', 'mm', 'um'];

  const unit = _units.find(u => value.includes(u));

  if (unit) {
    return {
      value: value.replace(unit, ''),
      unit
    };
  }

  return { value: value, unit: "" };
}

export {
  NPTimes,
  scaleConversion,
  numFormatConversion,
  numExponentialFormat,
  toNonExponential,
  formatNumber,
  splitNumUnit,
  resetDefaultValue,
  getUnitConversionValue,
  getScale,
  getIntegersInStr,
  formatExponential,
  splitValueUnitByUnits
};
