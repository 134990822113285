/*
* Model_type must be one of the following:
| Input, Output, I/O, 3-state, Open_drain, I/O_open_drain,
| Open_sink, I/O_open_sink, Open_source, I/O_open_source,
| Input_ECL, Output_ECL, I/O_ECL, 3-state_ECL, Terminator,
| Series, and Series_switch
* For true differential models documented under Section 6b, Model_type must be one of the following:
| Input_diff, Output_diff, I/O_diff, and 3-state_diff
*/
// const TX_MODEL_TYPES = ['Output', 'I/O', 'Open_drain', 'I/O_open_drain', 'Open_sink',
//   'I/O_open_sink', 'Open_source', 'I/O_open_source', 'Output_ECL', 'I/O_ECL', '3-state', '3-state_ECL'];
// const RX_MODEL_TYPES = ['Input', 'I/O', 'I/O_open_drain', 'I/O_open_sink',
//   'I/O_open_source', 'Terminator', 'Input_ECL', 'I/O_ECL'];
// const DIFFERENTIAL_MODEL_TYPES = ['Input_diff', 'Output_diff', 'I/O_diff', '3-state_diff']
const INPUT = ['Input', /* "Terminator" */];
const OUTPUT = ['Output', 'Open_drain', 'Open_sink', 'Open_source'];
const TRISTATE = ['Tristate', "3-state"];
// Tristate = 3-state
const IO = ['I/O', 'I/O_open_drain', 'I/O_open_sink', 'I/O_open_source'];

const PU = 'nd_pu',
  PD = 'nd_pd',
  IN = 'nd_in',
  EN = 'nd_en';

// Driver
const AUTO = [PU, PD, IN];
const OUTPUT_PINS = [PU, PD, IN];
const TRISTATE_PINS = [PU, PD, IN, EN];
const IO_PINS = [PU, PD, IN, EN];

const TX_MODEL_TYPES = [...OUTPUT, ...TRISTATE, ...IO];
const RX_MODEL_TYPES = [...INPUT, ...IO];

const ONLY_DRIVER_MODEL_TYPES = [...OUTPUT, ...TRISTATE];


function getPinsNumber(type) {
  if (!type) return [];
  if ([...INPUT, ...OUTPUT].includes(type)) {
    return [1, 2];
  } else if (TRISTATE.includes(type)) {
    return [3];
  } else if (IO.includes(type)) {
    return [4];
  } else {
    return [];
  }
}

export {
  INPUT,
  OUTPUT,
  TX_MODEL_TYPES,
  RX_MODEL_TYPES,
  AUTO,
  OUTPUT_PINS,
  TRISTATE_PINS,
  IO_PINS,
  getPinsNumber,
  ONLY_DRIVER_MODEL_TYPES,
  IO
};