import React, { Component, Fragment } from 'react';
import { FormOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import PowerSelectPanel from './powerSelectPanel';


const { Option } = Select;

class TargetICContent extends Component {

  ICSelect = (value) => {
    setTimeout(() => {
      this.props._changeTargetIC(value)
    }, 50)
  }

  closePanel = () => {
    this.changePanelStatus(false);
  }

  changePanelStatus = (bool, e, isLoadingNets) => {
    e && e.preventDefault() && e.stopPropagation();
    const { updateOpenTargetICLoading, updateSelectPowerNets } = this.props;
    bool && updateOpenTargetICLoading && updateOpenTargetICLoading(true);
    updateSelectPowerNets({ open: bool, isLoadingNets });
  }

  render() {
    const { loadComponents, loadSelect, powerNetSelect, loading, openTargetICLoading } = this.props;
    const { open = false, select = [], allPowerNets = [], allNets = [] } = powerNetSelect;
    const value = loadSelect.length ? loadSelect[0].comp : '';
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color-only">Target IC</span>
        <Select
          value={value}
          style={{ width: 240, marginLeft: 20 }}
          onChange={this.ICSelect}
          getPopupContainer={((trigger) => trigger.parentNode || document.getElementById('root'))}
          dropdownStyle={{ zIndex: 200 }}
          showSearch={true}
        >
          {loadComponents.map(option => {
            return <Option key={option.name} value={option.name}>{option.name}</Option>
          })}
        </Select>
        {value && <FormOutlined
          className="cascade-refresh-icon"
          style={open || loading ? { color: "rgba(0,0,0,0.65)", cursor: "not-allowed" } : {}}
          title="Select Power Nets"
          onClick={(e) => open || loading ? null : this.changePanelStatus(true, e, true)} />}
        {open ? <PowerSelectPanel
          closePanel={this.closePanel}
          select={select}
          openTargetICLoading={openTargetICLoading || false}
          allPowerNets={allPowerNets}
          refresh={this.props.refresh}
          targetIC={value}
          allNets={allNets}
        /> : null}
      </Fragment>
    );
  }
}

export default TargetICContent;