import React, { Component, Fragment } from 'react';
import { Switch, Input, Select } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { numFormatConversion, scaleConversion, numExponentialFormat } from '@/services/helper/numberHelper';
import { valueCheck, checkSweepValue } from '@/services/helper/valueCheck';
import NP from 'number-precision';
import { ANDES } from '../../../../constants/pageType';

const { Option } = Select;
class AdvancePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clipping: true,
      logSweepMin: "1000",
      logSweepMax: "10",
      logSweepMinUnit: 'Hz',
      logSweepMaxUnit: "MHz",
      logSweepSAPD: "10",
      linearSweepMax: "5",
      linearSweepMaxUnit: "GHz",
      linearSweepFreqStep: "5",
      linearSweepFreqStepUnit: "MHz",
      portImpedance: "50",
      maxFreq: "",
      maxFreqUnit: "GHz",
      clipSize: "3",
      clipSizeUnit: "mm",
      includeDC: false,
      exactDC: false,
      discreteSweep: false,
      meshFrequency: "5",
      maxPasses: "10",
      maxDelta: "0.02",
      error: null
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const { extraction } = this.props;
    if (extraction) {
      let { clipping, logSweepMin, logSweepMax,
        logSweepSAPD, linearSweepMax,
        linearSweepFreqStep, portImpedance, maxFreq,
        clipSize, includeDC, exactDC, discreteSweep, meshFrequency, maxPasses, maxDelta
      } = extraction;

      let clipSizeUnit = 'ratio';
      if (clipSize && clipSize.includes('mm')) {
        clipSizeUnit = 'mm';
        clipSize = parseFloat(clipSize);
      }

      if (!clipSize) {
        clipSizeUnit = "mm";
        clipSize = "3";
      }

      this.setState({
        clipping: clipping,
        logSweepMin: numFormatConversion(logSweepMin) || "1000",
        logSweepMax: numFormatConversion(logSweepMax, -6) || "10",
        logSweepSAPD: logSweepSAPD || "10",
        linearSweepMax: numFormatConversion(linearSweepMax, -9) || "5",
        linearSweepFreqStep: numFormatConversion(linearSweepFreqStep, -6) || "5",
        portImpedance: portImpedance || "50",
        maxFreq: maxFreq ? numFormatConversion(maxFreq, -9) : "",
        clipSize: clipSize,
        clipSizeUnit: clipSizeUnit,
        includeDC: includeDC || false,
        exactDC: exactDC || false,
        discreteSweep: discreteSweep || false,
        meshFrequency: meshFrequency || "5",
        maxPasses: maxPasses || "10",
        maxDelta: maxDelta || "0.02",
      })
    }
  }

  LogSweepMin = () => {
    const { logSweepMinUnit } = this.state;
    return <Select dropdownStyle={{ zIndex: 100000 }} value={logSweepMinUnit} style={{ width: 68 }} onChange={(e) => this.changeLogSweepMinUnit(e)}>
      <Option value="Hz">Hz</Option>
      <Option value="KHz">KHz</Option>
    </Select>
  }

  LogSweepMax = () => {
    const { logSweepMaxUnit } = this.state;
    return <Select dropdownStyle={{ zIndex: 100000 }} value={logSweepMaxUnit} style={{ width: 68 }} onChange={(e) => this.changeLogSweepMaxUnit(e)}>
      <Option value="Hz">Hz</Option>
      <Option value="KHz">KHz</Option>
      <Option value="MHz">MHz</Option>
      <Option value="GHz">GHz</Option>
    </Select>
  }

  LinearSweepMax = () => {
    const { linearSweepMaxUnit } = this.state;
    return <Select dropdownStyle={{ zIndex: 100000 }} value={linearSweepMaxUnit} style={{ width: 68 }} onChange={(e) => this.changeLinearSweepMaxUnit(e)}>
      <Option value="Hz">Hz</Option>
      <Option value="KHz">KHz</Option>
      <Option value="MHz">MHz</Option>
      <Option value="GHz">GHz</Option>
    </Select>
  }

  LinearFreStep = () => {
    const { linearSweepFreqStepUnit } = this.state;
    return <Select dropdownStyle={{ zIndex: 100000 }} value={linearSweepFreqStepUnit} style={{ width: 68 }} onChange={(e) => this.changeLinearFreStepUnit(e)} >
      <Option value="Hz">Hz</Option>
      <Option value="KHz">KHz</Option>
      <Option value="MHz">MHz</Option>
      <Option value="GHz">GHz</Option>
    </Select>
  };

  maxFreqSelect = () => {
    const { maxFreqUnit } = this.state;
    return <Select dropdownStyle={{ zIndex: 100000 }} value={maxFreqUnit} style={{ width: 68 }} onChange={(e) => this.changeMaxFreqUnit(e)}>
      <Option value="Hz">Hz</Option>
      <Option value="KHz">KHz</Option>
      <Option value="MHz">MHz</Option>
      <Option value="GHz">GHz</Option>
    </Select>
  };

  clipSizeUnit = () => {
    const { clipSizeUnit } = this.state;
    return <Select dropdownStyle={{ zIndex: 100000 }} value={clipSizeUnit} style={{ width: 68 }} onChange={this.changeclipSizeUnit}>
      <Option value="mm">mm</Option>
      <Option value="ratio">ratio</Option>
    </Select>
  }

  changeclipSizeUnit = (key) => {
    const { clipSizeUnit } = this.state;
    if (clipSizeUnit === key) {
      return;
    }
    let value = '';
    if (key === 'ratio') {
      value = '0.3';
    } else if (key === 'mm') {
      value = '3';
    }
    this.setState({
      clipSize: value,
      clipSizeUnit: key
    })
  }

  changeLogSweepMinUnit = (key) => {
    const { logSweepMinUnit, logSweepMin } = this.state;
    let min = logSweepMin;

    if (logSweepMinUnit === key) {
      return;
    }
    min = this.UnitConversion(key, logSweepMinUnit, logSweepMin);
    this.setState({
      logSweepMinUnit: key,
      logSweepMin: min
    });
  }

  changeLogSweepMaxUnit = (key) => {
    const { logSweepMaxUnit, logSweepMax } = this.state;
    let max = logSweepMax;

    if (logSweepMaxUnit === key) {
      return;
    }
    max = this.UnitConversion(key, logSweepMaxUnit, logSweepMax);
    this.setState({
      logSweepMaxUnit: key,
      logSweepMax: max
    });
  }

  changeMaxFreqUnit = (key) => {
    const { maxFreqUnit, maxFreq } = this.state;
    let max = maxFreq;

    if (maxFreqUnit === key) {
      return;
    }
    max = maxFreq ? this.UnitConversion(key, maxFreqUnit, maxFreq) : "";
    this.setState({
      maxFreqUnit: key,
      maxFreq: max
    });
  }

  changeLinearFreStepUnit = (key) => {
    const { linearSweepFreqStepUnit, linearSweepFreqStep } = this.state;
    let freq = linearSweepFreqStep;

    if (linearSweepFreqStepUnit === key) {
      return;
    }
    freq = this.UnitConversion(key, linearSweepFreqStepUnit, linearSweepFreqStep);
    this.setState({
      linearSweepFreqStepUnit: key,
      linearSweepFreqStep: freq
    });
  }

  changeLinearSweepMaxUnit = (key) => {
    const { linearSweepMaxUnit, linearSweepMax } = this.state;
    let max = linearSweepMax;

    if (linearSweepMaxUnit === key) {
      return;
    }
    max = this.UnitConversion(key, linearSweepMaxUnit, linearSweepMax);
    this.setState({
      linearSweepMaxUnit: key,
      linearSweepMax: max
    });
  }

  UnitConversion = (unit, prevUnit, value) => {
    if (unit === 'Hz') {
      switch (prevUnit) {
        case 'KHz': value = parseFloat((value * Math.pow(10, 3)).toPrecision(12));
          break;
        case 'MHz': value = parseFloat((value * Math.pow(10, 6)).toPrecision(12));
          break;
        case 'GHz': value = parseFloat((value * Math.pow(10, 9)).toPrecision(12));
          break;
        default: break;
      }
    }

    if (unit === 'KHz') {
      switch (prevUnit) {
        case 'Hz': value = parseFloat((value * Math.pow(10, -3)).toPrecision(12));
          break;
        case 'MHz': value = parseFloat((value * Math.pow(10, 3)).toPrecision(12));
          break;
        case 'GHz': value = parseFloat((value * Math.pow(10, 6)).toPrecision(12));
          break;
        default: break;
      }
    }

    if (unit === 'MHz') {
      switch (prevUnit) {
        case 'Hz': value = parseFloat((value * Math.pow(10, -6)).toPrecision(12));
          break;
        case 'KHz': value = parseFloat((value * Math.pow(10, -3)).toPrecision(12));
          break;
        case 'GHz': value = parseFloat((value * Math.pow(10, 3)).toPrecision(12));
          break;
        default: break;
      }
    }

    if (unit === 'GHz') {
      switch (prevUnit) {
        case 'Hz': value = parseFloat((value * Math.pow(10, -9)).toPrecision(12));
          break;
        case 'KHz': value = parseFloat((value * Math.pow(10, -6)).toPrecision(12));
          break;
        case 'MHz': value = parseFloat((value * Math.pow(10, -3)).toPrecision(12));
          break;
        default: break;
      }
    }

    return value;
  }

  changeClip = (checked) => {
    this.setState({
      clipping: checked
    });
    if (!checked) {
      this.setState({
        clipSize: "3",
        clipSizeUnit: "mm"
      })
    }
  }

  changeInputValue = (e, type) => {
    this.setState({
      [type]: e.target.value,
      error: null
    });
  }

  numberFormat = (value, unit) => {
    const scale = scaleConversion("Hz", unit);
    value = value ? NP.times(parseFloat(value), scale).toString() : value;
    return numExponentialFormat(value);
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let error = null;
    const unit = this.state[`${inputType}Unit`];

    if (inputType !== "maxFreq" || value) {
      error = valueCheck({ value, inputType, unit, version: ANDES });
    }

    if (error) {
      e.target.focus();
      this.setState({
        error: error
      });
    } else {
      this.setState({
        error: ''
      });
    }
  }

  closeModal = () => {
    let { clipping, logSweepMin, logSweepMax,
      logSweepSAPD, linearSweepMax,
      linearSweepFreqStep, portImpedance, maxFreq, maxFreqUnit, logSweepMaxUnit,
      logSweepMinUnit, linearSweepMaxUnit, linearSweepFreqStepUnit,
      clipSize, clipSizeUnit, includeDC, exactDC, discreteSweep, meshFrequency, maxPasses, maxDelta,
      error
    } = this.state;

    if (error) {
      return;
    }

    logSweepMin = this.numberFormat(logSweepMin, logSweepMinUnit);
    logSweepMax = this.numberFormat(logSweepMax, logSweepMaxUnit);
    maxFreq = this.numberFormat(maxFreq, maxFreqUnit);
    linearSweepMax = this.numberFormat(linearSweepMax, linearSweepMaxUnit);
    linearSweepFreqStep = this.numberFormat(linearSweepFreqStep, linearSweepFreqStepUnit);
    clipSize = clipSizeUnit === 'mm' ? `${clipSize}${clipSizeUnit}` : clipSize;

    const errorMsg = checkSweepValue({ logSweepMin, logSweepMax, linearSweepMax, maxFreq, version: ANDES });
    if (errorMsg) {
      this.setState({
        error: errorMsg
      });
      return;
    }
    this.props.saveExtraction({
      clipping: clipping,
      logSweepMin: logSweepMin ? logSweepMin : "1000",
      logSweepMax: logSweepMax ? logSweepMax : "1e7",
      logSweepSAPD: logSweepSAPD ? logSweepSAPD : "10",
      linearSweepMax: linearSweepMax ? linearSweepMax : "5e9",
      linearSweepFreqStep: linearSweepFreqStep ? linearSweepFreqStep : "5e6",
      portImpedance: portImpedance ? portImpedance : "50",
      maxFreq: maxFreq ? maxFreq : "",
      // new extraction options
      clipSize: clipSize,
      includeDC: includeDC,
      exactDC: exactDC,
      discreteSweep: discreteSweep,
      meshFrequency: meshFrequency,
      maxPasses: maxPasses,
      maxDelta: maxDelta
    })
    this.props.closeModal();
  }

  includeDCChange = (checked) => {
    this.setState({
      includeDC: checked
    });
    if (!checked) {
      this.setState({
        exactDC: false
      })
    }
  }

  exactDCCChange = (checked) => {
    this.setState({
      exactDC: checked
    });
  }

  discreteSweepChange = (checked) => {
    this.setState({
      discreteSweep: checked
    })
  }

  render() {
    const { clipping, logSweepMin, logSweepMax, logSweepSAPD,
      linearSweepMax, linearSweepFreqStep, portImpedance, maxFreq,
      clipSize,/*  includeDC, exactDC, discreteSweep, */
      meshFrequency, maxPasses, maxDelta,
      error } = this.state;
    const { simulate } = this.props;
    const content = (
      <Panel
        className='andes-advance-panel'
        title={<div className='extraction-name'>Extraction options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={800}
        position='panel-center'
        mask={true}
        draggable
        minHeight={250}
      >
        <div className='extraction-main'>
          {/* Design Clipping */}
          <div className='extraction-design-clipping'>
            <div className='extraction-sub-title'>Design Clipping</div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>Clip design</span>
              <Switch
                size="small"
                className="aurora-switch-small"
                checked={clipping}
                onChange={this.changeClip}
              />
            </div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>Clipping size</span>
              {/* value > 0 */}
              <Input
                value={clipSize}
                style={{ width: 409 }}
                addonAfter={this.clipSizeUnit()}
                className='extraction-input'
                onChange={(e) => this.changeInputValue(e, 'clipSize')}
                onBlur={(e) => this.inputBlur(e, 'clipSize')}
                disabled={!clipping}
              />
            </div>
          </div>
          {/* Frequency Sweep */}
          <div className='extraction-frequency-sweep'>
            <div className='extraction-sub-title'>Frequency Sweep</div>
            {/*  <div className='extraction-content'>
              <span className='extraction-content-body'>Include DC</span>
              <Switch
                size="small"
                className="aurora-switch-small"
                checked={includeDC}
                onChange={this.includeDCChange}
              />
            </div>
            <div className='extraction-content'>
              <span className='sub-extraction-content-body'>
                <span className='sub-extraction-box andes-sub-extraction-box'></span>Exact DC Solver
              </span>
              <Switch
                size="small"
                checked={exactDC}
                className="aurora-switch-small"
                onChange={this.exactDCCChange}
                disabled={!includeDC}
              />
            </div> */}
            <div className='extraction-content'>
              {/* 1K - 10M */}
              <span className='extraction-content-body'>Log Sweep</span>
              <span style={{ marginRight: 6 }}>From</span>
              <Input
                value={logSweepMin}
                className='extraction-input'
                style={{ width: 170, marginRight: 7 }}
                addonAfter={this.LogSweepMin()}
                onChange={(e) => this.changeInputValue(e, "logSweepMin")}
                onBlur={(e) => this.inputBlur(e, 'logSweepMin')}
              />
              To
              <Input
                value={logSweepMax}
                className='extraction-input'
                style={{ width: 170, marginLeft: 6 }}
                addonAfter={this.LogSweepMax()}
                onChange={(e) => this.changeInputValue(e, "logSweepMax")}
                onBlur={(e) => this.inputBlur(e, 'logSweepMax')}
              />
            </div>
            <div className='extraction-content'>
              <span className='sub-extraction-content-body'>
                <span className='sub-extraction-box andes-sub-extraction-box'></span>Log Sweep Samples Per Decade
              </span>
              <Input
                className='extraction-input'
                style={{ width: 409 }}
                value={logSweepSAPD}
                onChange={(e) => this.changeInputValue(e, "logSweepSAPD")}
                onBlur={(e) => this.inputBlur(e, 'logSweepSAPD')}
              />
            </div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>Linear Sweep</span>
              <span style={{ marginRight: 6 }}>From</span>
              <Input
                value={logSweepMax}
                className='extraction-input'
                style={{ width: 170, marginRight: 7 }}
                addonAfter={this.LogSweepMax()}
                onChange={(e) => this.changeInputValue(e, "logSweepMax")}
                onBlur={(e) => this.inputBlur(e, 'logSweepMax')}
              />
              To
              <Input
                value={linearSweepMax}
                className='extraction-input'
                style={{ width: 170, marginLeft: 6 }}
                addonAfter={this.LinearSweepMax()}
                onChange={(e) => this.changeInputValue(e, "linearSweepMax")}
                onBlur={(e) => this.inputBlur(e, 'linearSweepMax')}
              />
            </div>
            <div className='extraction-content'>
              <span className='sub-extraction-content-body'>
                <span className='sub-extraction-box andes-sub-extraction-box'></span>Linear Sweep Frequency Step
              </span>
              <Input
                className='extraction-input'
                value={linearSweepFreqStep}
                style={{ width: 409 }}
                onChange={(e) => this.changeInputValue(e, "linearSweepFreqStep")}
                onBlur={(e) => this.inputBlur(e, 'linearSweepFreqStep')}
                addonAfter={this.LinearFreStep()}
              />
            </div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>Maximum Frequency</span>
              <Input
                className='extraction-input'
                style={{ width: 409 }}
                value={maxFreq}
                addonAfter={this.maxFreqSelect()}
                onChange={(e) => this.changeInputValue(e, "maxFreq")}
                onBlur={(e) => this.inputBlur(e, 'maxFreq')}
              />
            </div>
            {/*  <div className='extraction-content'>
              <span className='extraction-content-body'>Discrete Sampling</span>
              <Switch
                size="small"
                className="aurora-switch-small"
                checked={discreteSweep}
                onChange={this.discreteSweepChange}
              />
            </div> */}
          </div>
          {/* Extraction Setup */}
          <div className='extraction-ex-setup'>
            <div className='extraction-sub-title'>Extraction Setup</div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>Port Reference Impedance</span>
              {/* (0-1000) */}
              <Input
                className='extraction-input'
                style={{ width: 409 }}
                value={portImpedance}
                onChange={(e) => this.changeInputValue(e, "portImpedance")}
                onBlur={(e) => this.inputBlur(e, 'portImpedance')}
                addonAfter={'Ohm'}
              />
            </div>
            <div className='extraction-content'>
              <span className='extraction-content-body'>Meshing Frequency</span>
              {/* >1 */}
              <Input
                className='extraction-input'
                style={{ width: 409 }}
                value={meshFrequency}
                addonAfter={'GHz'}
                onChange={(e) => this.changeInputValue(e, 'meshFrequency')}
                onBlur={(e) => this.inputBlur(e, 'meshFrequency')}
              />
            </div>
            {simulate && <Fragment>
              <div className='extraction-content'>
                <span className='extraction-content-body'>Adaptive Meshing Max Number of Passes</span>
                {/* >2 only show if modeling with HFSS */}
                <Input
                  className='extraction-input'
                  style={{ width: 409 }}
                  value={maxPasses}
                  onChange={(e) => this.changeInputValue(e, 'maxPasses')}
                  onBlur={(e) => this.inputBlur(e, 'maxPasses')}
                />
              </div>
              <div className='extraction-content'>
                <span className='extraction-content-body'>Adaptive Meshing Max Delta</span>
                {/* 0.001~1 only show if modeling with HFSS */}
                <Input
                  className='extraction-input'
                  style={{ width: 409 }}
                  value={maxDelta}
                  onChange={(e) => this.changeInputValue(e, 'maxDelta')}
                  onBlur={(e) => this.inputBlur(e, 'maxDelta')}
                />
              </div>
            </Fragment>}
          </div>
          {error && <span className='extraction-option-error-msg'>Error: {error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default AdvancePanel;