import {
  SAVE_DCR_RESISTANCE_DATA,
  CHANGE_RESISTANCE_TABLE_LOADING,
  UPLOAD_DCR_MSG,
  SAVA_VERIFICATION_ID,
  RESISTANCE_SELECTED_LIST,
  CLEAR_CURRENT_PATHR_DATA,
  UPDATE_PCB_ID,
  UPDATE_WARNING_MSG
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  resistanceData: [],
  resistanceTableLoading: false,
  errorMessage: [],
  verificationId: null,
  designId: null,
  pairs: [],
  extraction: {},
  warningMessage: []
};

export const CascadeDCRReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_DCR_RESISTANCE_DATA:
      return {
        ...state,
        resistanceData: action.dataList ? action.dataList : state.dataList,
        pairs: action.pairs ? action.pairs : state.pairs,
        designName: action.designName ? action.designName : state.designName,
        extraction: action.extraction ? action.extraction : state.extraction
      }
    case CHANGE_RESISTANCE_TABLE_LOADING:
      return {
        ...state,
        resistanceTableLoading: action.loading
      }
    case UPLOAD_DCR_MSG:
      return {
        ...state,
        errorMessage: action.msg,
      }
    case SAVA_VERIFICATION_ID:
      return {
        ...state,
        verificationId: action.id
      }
    case RESISTANCE_SELECTED_LIST:
      return {
        ...state,
        selectedList: action.selectList
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CLEAR_CURRENT_PATHR_DATA:
      return {
        ...defaultState
      }
    case UPDATE_PCB_ID:
      return {
        ...state,
        designId: action.designId
      }
    case UPDATE_WARNING_MSG:
      return {
        ...state,
        warningMessage: action.msg
      }
    default: return state
  }
} 