import { takeEvery, select, put, cancel, call, delay, fork } from 'redux-saga/effects';
import {
  START_MULTIPLE_MODELING,
} from './actionTypes';
import { verificationWorkFlow, getWorkflowTask, updateWaitingStartMsg } from '../saga';
import {
  updateSimulationReducer,
  updateCurrentSimulationInfo,
  getVerificationLog,
} from '../action';
import { doRockySimulation } from '../../../../../services/Rocky';
import { API_RES_MESSAGE } from '../../../../../constants/returnCode';
import { checkVerificationStatus, getVerificationWorkFlow } from '../../../../../services/workflow/workflow';
import { VERIFY_SUCCESS } from '../../../../../constants/verificationStatus';
import { MULTIPLE_CHANNEL } from '@/constants/treeConstants';
import dayjs from 'dayjs';

let workflowTask = getWorkflowTask();

function* _startMultiple(action) {
  let { verificationIds, verificationId } = action;
  if (verificationIds.length === 0) {
    return
  }

  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  let reducer = yield select();

  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let currentSimInfo = [];
  for (let item of verificationIds) {
    currentSimInfo.push({
      verificationId: item,
      status: 'checking'
    });
    //clean prev simulation info by verificationId
    delete newSimulationReducer[item];
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    newSimulationReducer[item] = {
      startMsg: `==> ${waitingTime} Checking setup...`
    }
  }
  yield put(updateSimulationReducer(newSimulationReducer));

  reducer = yield select();
  simulationReducer = reducer.RockyReducer.simulationReducer;
  let waitingTask = null;
  try {
    waitingTask = yield fork(updateWaitingStartMsg, { verificationIds, _simulation: simulationReducer })
    const channels = [{ verificationIds: [...verificationIds], pdnId: null }];
    yield call(doRockySimulation, channels);
    yield cancel(waitingTask);
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    yield* verificationIds.map(function* (item) {
      simulationReducer[item] = {};
      let msg = ""
      if (error && error.code === API_RES_MESSAGE) {
        msg = `==> ${error.msg}`;
        // get log
        yield put(getVerificationLog(item));
        simulationReducer[item].simulationMsg = msg;
      } else {
        msg = `==> ${error}`;
        simulationReducer[item].endMsg = msg;
      }
    })
    yield put(updateSimulationReducer(simulationReducer));
    yield delay(1000);
    if (!verificationId) { return }
    yield put(updateCurrentSimulationInfo({ verificationId, item: "startMsg", info: null }));
    yield put(updateCurrentSimulationInfo({ verificationId, item: "progress", info: -1 }));

    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "resultExist", info: resultExist }));
    return;
  }

  // fake progress
  // id: workflowId
  // yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  if (!verificationId) { return }
  const verificationStatus = yield call(getVerificationWorkFlow, verificationId);
  // update status
  if (verificationStatus && verificationStatus.length > 0) {
    if (workflowTask) {
      yield cancel(workflowTask);
    };
    workflowTask = yield fork(verificationWorkFlow, { verificationId, verificationWork: verificationStatus, simulationType: MULTIPLE_CHANNEL });
    return;
  };
}

function* multipleSaga() {
  yield takeEvery(START_MULTIPLE_MODELING, _startMultiple);
}

export default multipleSaga;