import { CAP, IND, RES } from "../PCBHelper";
import _ from 'lodash';

function getVirtualComponentList(virtualComponents) {
  if (!virtualComponents || !virtualComponents.length) {
    return [];
  }
  let compList = [];

  for (let comp of virtualComponents) {
    compList.push(...new VirtualComponentItem({ ...comp }));
  }
  return compList;
}

function VirtualComponentItem({ name, layer, location, pins, files, type, cutLength, unit, layoutUnit, value }) {
  this.name = name || "";
  this.layer = layer || "";
  this.type = type || "";
  this.location = location || [];
  this.cutLength = cutLength || "";
  this.unit = unit || "mil";
  this.pins = pins || [];
  this.files = files || [];
  this.layoutUnit = layoutUnit || "mil";
  this.value = value || "";
}

function VcCompPin({ pin, location, net, signal, modelKey, node, libraryId }) {
  this.pin = pin;
  this.location = location;
  this.net = net;
  this.signal = signal;
  this.modelKey = modelKey || "";
  this.node = node || "";
  this.libraryId = libraryId || "";
}

const N_PORT = "N-Port", SHUNT_C = "Shunt-Cap", SHUNT_R = "Shunt-Res", SHUNT_L = "Shunt-Ind";
const VC_RLC_TYPES = ["Res", "Ind", "Cap", SHUNT_R, SHUNT_L, SHUNT_C];//can not set Res/Ind/Cap from PCBHelper
const VC_SHUNT_TYPES = [SHUNT_R, SHUNT_L, SHUNT_C];


function getVCompModelText(record) {
  if (!record.files || !record.files.length) {
    return "";
  }
  return record.files.map(item => item.fileName).filter(item => !!item).join(", ");
}

function getVCompModelPinList(pins) {
  let pinList = [];
  if (!pins || !pins.length) {
    return [];
  }
  pins.forEach(item => {
    let pinItem = {
      pin: item.pin,
      net: item.net,
      signal: getVCSignalName(item.pin),
      type: item.pin.match(/_1$/) ? "in" : "out"
    };
    pinList.push(pinItem)
  })

  return pinList;
}

function getVCSignalName(pin) {
  if (pin.match(/_1$/)) {
    return pin.replace(/_1$/, "")
  }
  if (pin.match(/_2$/)) {
    return pin.replace(/_2$/, "")
  }
  return ""
}

function getVCDefaultModel(record) {
  return {
    type: "Touchstone / SPICE",
    files: record.files || [],
    pairs: record.pins.map(item => {
      return {
        pin: item.pin,
        node: item.node,
        libraryId: item.libraryId,
        modelKey: item.modelKey,
        subckt: item.subckt
      }
    })
  }
}

function updateViewVComps({ viewVirtualComps, virtualComps, comps, openedVirtualComps }) {
  let _openedVirtualComps = [...openedVirtualComps];

  const findItems = virtualComps.filter(item => comps.includes(item.name));
  if (findItems && findItems.find(item => VC_RLC_TYPES.includes(item.type))) {
    comps = virtualComps.filter(item => findItems.find(it => _.isEqual(it.location, item.location))).map(item => item.name);
  }

  const addedComps = comps.filter(item => !viewVirtualComps.includes(item));
  const removedComps = viewVirtualComps.filter(item => !comps.includes(item));
  _openedVirtualComps = _openedVirtualComps.filter(item => comps.includes(item));

  return {
    _comps: comps,
    _openedVirtualComps,
    addedComps,
    removedComps
  }
}

function updateViewVCompsPanel({ viewVirtualComps, virtualComps, comps, openedVirtualComps }) {
  let _viewVirtualComps = [...viewVirtualComps];

  const findItems = virtualComps.filter(item => comps.includes(item.name));
  if (findItems && findItems.find(item => VC_RLC_TYPES.includes(item.type))) {
    comps = virtualComps.filter(item => findItems.find(it => _.isEqual(it.location, item.location))).map(item => item.name);
  }

  const addedComps = comps.filter(item => !openedVirtualComps.includes(item));
  const removedComps = openedVirtualComps.filter(item => !comps.includes(item));

  _viewVirtualComps.push(...comps);
  _viewVirtualComps = [...new Set(_viewVirtualComps)];
  return {
    _comps: comps,
    _viewVirtualComps,
    addedComps,
    removedComps
  }
}

function getShuntRLCType(type) {
  switch (type) {
    case SHUNT_R:
      return RES;
    case SHUNT_L:
      return IND;
    case SHUNT_C:
      return CAP;
    default: return type || "";
  }
}

export {
  getVirtualComponentList,
  VcCompPin,
  N_PORT,
  VC_RLC_TYPES,
  VC_SHUNT_TYPES,
  SHUNT_R,
  SHUNT_L,
  SHUNT_C,
  VirtualComponentItem,
  getVCompModelText,
  getVCompModelPinList,
  getVCSignalName,
  getVCDefaultModel,
  updateViewVComps,
  updateViewVCompsPanel,
  getShuntRLCType
}