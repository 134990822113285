import {
  START_PDN_VERIFICATION,
  CHANGE_VERIFICATION_LIST,
  UPDATE_PDN_PROGRESS,
  GET_SINGLE_MONITOR,
  SINGLE_VERIFY_INFO,
  CLEAN_SINGLE_PROGRESS,
  UPDATE_SINGLE_MONITOR,
  CLEAN_MONITOR,
  CANCEL_WORKFLOW,
  CHANGE_UPLOAD_MES,
  DEBUG_VERIFY,
  RESULT_EXIST,
  UPDATE_END_MSG,
  PDN_CHECK_ERROR,
  WAITING_INDEX,
  GET_CURRENT_LOG,
  SAVE_CURRENT_LOG,
  PDN_STACKUP_CHECK_ERROR,
  VRM_ERROR_INFO,
  DECAP_ERROR_INFO,
  SAVE_CURRENT_CHECK_ID,
  UPDATE_LIBRARY_DATA_CHECK,
  CLEAR_SIMULATION_INFO,
  RE_CHECK_SIMULATION_STACKUP,
  GET_VRM_MODEL,
  UPDATE_SIMULATION_MESSAGE,
  UPDATE_START_MSG,
  GET_CURRENT_PROFILE,
  SAVE_CURRENT_PROFILE,
  GET_PDN_WORKFLOW
} from './actionTypes';

export const startPDNVerification = (verificationIds) => ({
  type: START_PDN_VERIFICATION,
  verificationIds
});

export const changeVerificationList = (selectedKeys) => ({
  type: CHANGE_VERIFICATION_LIST,
  selectedKeys
});

export const updateProgress = ({ verificationId, progress }) => ({
  type: UPDATE_PDN_PROGRESS,
  verificationId,
  progress
});

export const getSingleMonitor = (workflowId, verificationId) => ({
  type: GET_SINGLE_MONITOR,
  workflowId,
  verificationId
});

export const singleVerifyInfo = ({ workflowId, verificationId }) => ({
  type: SINGLE_VERIFY_INFO,
  workflowId,
  verificationId
});

export const cleanSingleProgress = (verificationId) => ({
  type: CLEAN_SINGLE_PROGRESS,
  verificationId,
});

export const updateSingleMonitor = ({ workflowId, monitor, verificationId }) => ({
  type: UPDATE_SINGLE_MONITOR,
  workflowId,
  monitor,
  verificationId
});

export const cleanMonitor = (verificationId) => ({
  type: CLEAN_MONITOR,
  verificationId,
});

export const updateStartMsg = (msg) => ({
  type: UPDATE_START_MSG,
  msg,
});

export const cancelWorkflow = (verificationId) => ({
  type: CANCEL_WORKFLOW,
  verificationId,
});

export const changeUploadMes = (mes) => ({
  type: CHANGE_UPLOAD_MES,
  mes,
});

export const debugVerify = (status, verificationId) => ({
  type: DEBUG_VERIFY,
  status,
  verificationId
});

export const existResult = (existResult) => ({
  type: RESULT_EXIST,
  existResult
});

export const updateEndMsg = (msg) => ({
  type: UPDATE_END_MSG,
  msg
});

export const pdnCheckError = (errorCheck) => ({
  type: PDN_CHECK_ERROR,
  errorCheck
});

export const getCurrentPDNLog = (verificationId) => ({
  type: GET_CURRENT_LOG,
  verificationId
});

export const saveCurrentLog = (log) => ({
  type: SAVE_CURRENT_LOG,
  log
});

export const waitingIndexAction = (waitingQueue) => ({
  type: WAITING_INDEX,
  waitingQueue
});

export const pdnStackupCheckError = (stackupError) => ({
  type: PDN_STACKUP_CHECK_ERROR,
  stackupError
});

export const vrmErrorInfo = (errorCheck, verificationId) => ({
  type: VRM_ERROR_INFO,
  errorCheck,
  verificationId
});

export const decapErrorInfo = (errorCheck, verificationId) => ({
  type: DECAP_ERROR_INFO,
  errorCheck,
  verificationId
});

export const saveCurrentCheckId = (verificationId) => ({
  type: SAVE_CURRENT_CHECK_ID,
  verificationId
});

export const updateLibraryDataCheck = ({ dataType, verificationId }) => ({
  type: UPDATE_LIBRARY_DATA_CHECK,
  dataType,
  verificationId
});

export const clearSimulationInfo = (verificationId) => ({
  type: CLEAR_SIMULATION_INFO,
  verificationId
})

export const reCheckSimulationStackup = (data) => ({
  type: RE_CHECK_SIMULATION_STACKUP,
  data
})

export const getVRMModelUpdate = ({ currentProjectId, PDNID }) => ({
  type: GET_VRM_MODEL,
  currentProjectId,
  PDNID
});

export const updateStimulationMessage = (verificationId) => ({
  type: UPDATE_SIMULATION_MESSAGE,
  verificationId
});

export const getCurrentProfile = (verificationId) => ({
  type: GET_CURRENT_PROFILE,
  verificationId
});

export const saveCurrentProfileLog = (profile) => ({
  type: SAVE_CURRENT_PROFILE,
  profile
});

export const _getPDNWorkflow = (verificationId) => ({
  type: GET_PDN_WORKFLOW,
  verificationId
})
