import React, { Component, Fragment } from 'react';
import { Switch, Input, Select, Divider, Radio } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { scaleConversion, numFormatConversion, numExponentialFormat } from '@/services/helper/numberHelper';
import { fastPIValueCheck } from '@/services/helper/fastPIValueCheck';
import NP from 'number-precision';
import { numberCheck } from '@/services/helper/dataProcess';
import { getFastPISIWEMode } from '@/services/api/cookies';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import {
  ExtractionOptions,
  SIWAVEOptions,
  PowerSIOptions,
  PowerSIParams,
  getDefaultSolverByDesignVendor,
  judgeDesignIsSpd,
  judgePowerSIDisabledByDesign
} from '@/services/PDN';
import permissionData from '@/services/helper/data/permissionData';
import PreviewBtn from '@/components/ClipDesign/previewBtn';
import { cleanPreview } from '@/services/helper/cutDesign/preview';
import HPCOptions from '../../../../../components/ExtractionOptions/HPCOptions';
import './new.css'

const { Option } = Select;
const POWERSI = 'PowerSI', SIWAVE = 'SIwave';
NP.enableBoundaryChecking(false);// default param is true
class ExtractionPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      SAPD: 100,
      FMAX: '', // v0.1.15 update default value to ''
      FMAXUnit: 'MHz',
      CLIP: 1,
      RESA: 1,
      DISC: 0,
      REFR: '',
      PKGR: 1,
      VOID: '1e-6', //v0.1.17 update default value to 1e-6
      IPC: 1, // v0.1.14 update default value
      PADM: '1e-6', //V0.1.12 new field => v0.1.17 update default value to 1e-6
      PLNM: '1e-6', //v0.1.13 new field => v0.1.17 update default value to 1e-6
      VRTC: 1, //v0.1.13 new field => v0.1.17 update default value to 1,
      engine: SIWAVE,
      maxWidth: 862,
      maxHeight: 700,
      ratio: 0.3,
      advanced: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 700),
      maxWidth: getPanelMaxWidth(offset, 862)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { Config, designId } = this.props;
    if (Config) {
      const engine = getDefaultSolverByDesignVendor(designId) || Config.SOLVER || SIWAVE;
      let _Config = new ExtractionOptions(Config);
      const _FMAX = numFormatConversion(_Config.FMAX, -6);
      const _REFR = numFormatConversion(_Config.REFR);
      this.setState({
        ..._Config,
        engine,
        SAPD: numFormatConversion(_Config.SAPD) || 100,
        FMAX: (_FMAX === '0' ? '' : _FMAX) || '', // v0.1.15 update default value to 0， display ''
        REFR: (_REFR === '0' ? '' : _REFR) || '', // v0.1.15 update default value to 0， display ''
      })
    }
  }

  fMaxSelectAfter = () => {
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      defaultValue={this.state.FMAXUnit}
      onChange={(e) => this.changeMaxUnit(e)}
      style={{ width: 65 }}
    >
      <Option value="MHz">MHz</Option>
      <Option value="GHz">GHz</Option>
    </Select>
  };

  changeMaxUnit = (unit) => {
    const { FMAXUnit, FMAX } = this.state;

    if (FMAXUnit === unit) {
      return;
    }
    const scale = scaleConversion(unit, FMAXUnit);
    let _FMAX = FMAX && !numberCheck(FMAX) ? NP.times(FMAX, scale) : FMAX;
    this.setState({
      FMAX: _FMAX,
      FMAXUnit: unit
    });
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { FMAXUnit, error } = this.state;
    const unit = inputType === 'FMAX' ? FMAXUnit : null;

    if (inputType === 'REFR' || inputType === 'FMAX') {
      if (value) {
        _check = fastPIValueCheck({ value, inputType, unit });
      }
    } else {
      _check = fastPIValueCheck({ value, inputType, unit });
    }

    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
      }
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: _value,
        error: newError
      });
      if (inputType === 'ratio') {
        this.saveAndShow();
      }
    }
  }

  changeValue = (e, type) => {
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    });
  }

  changeSwitch = (checked, type) => {
    cleanPreview(this.props.designId);
    this.props.changePreviewShowState(false);
    let value = checked ? 1 : 0;
    this.setState({
      [type]: value
    }, () => {
      const { engine, REFR, SAPD } = this.state;
      if (engine === POWERSI && value === 0) {
        if (type === 'PKGR' && !REFR) {
          this.setState({ REFR: 10 })
        } else if (type === 'RESA' && !SAPD) {
          this.setState({ REFR: 10 })
        }
      }
    });
  }

  showAdvanced = (e) => {
    e && e.stopPropagation();
    this.setState({
      advanced: !this.state.advanced
    })
  }

  changeBooleanSwitch = (checked, type) => {
    this.setState({
      [type]: checked
    });
  }

  FMaxFormat = (fMax, unit) => {
    //update fMax by unit
    // "MHz" / "GHz" ==> "Hz"
    const scale = scaleConversion("Hz", unit);
    const _fMax = fMax ? NP.times(fMax, scale).toString() : fMax;
    const _format = numExponentialFormat(_fMax)
    return _format ? _format : 0;
  }

  closeModal = () => {
    const { error } = this.state;
    if (error) {
      return;
    }
    this.saveConfig();
    cleanPreview(this.props.designId)
    this.props.closeModal();
  }

  saveConfig = () => {
    const { FMAX, FMAXUnit, CLIP, SAPD, REFR, DISC, RESA, PKGR, IPC, engine, ratio, CVRM_MODEL, numCores, corePercent, ramPercent } = this.state;
    //update FMAX by unit
    let _FMAX = this.FMaxFormat(FMAX, FMAXUnit);
    const propConfig = this.props.Config;
    const SIWE = propConfig && typeof (propConfig.SIWE) === "number" ? propConfig.SIWE : getFastPISIWEMode();
    const extendConfig = engine === SIWAVE ? new SIWAVEOptions({ ...this.state, SIWE, FMAX: _FMAX, DISC }) : new PowerSIOptions({ ...this.state, ratio });
    let config = {
      SAPD: SAPD,
      CLIP: CLIP,
      REFR: REFR ? REFR : 0,
      RESA,
      PKGR,
      IPC, // v0.1.14 defualt value change to 1
      SOLVER: engine,
      CVRM_MODEL,
      numCores,
      corePercent,
      ramPercent,
      ...extendConfig
    };
    this.props.saveConfig(config);
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  changeEngineRadio = (e) => {
    const engine = e.target.value;
    const { CVRM_MODEL, VR_FSW } = this.state;
    const newSetting = new ExtractionOptions({ SOLVER: engine, CVRM_MODEL, VR_FSW });
    if (engine === POWERSI) {
      this.props.disableDCFlow();
    } else {
      cleanPreview(this.props.designId);
    }
    this.setState({
      engine,
      error: null,
      ...newSetting
    })
  }

  SIWAVERender = () => {
    const { VOID, PADM, PLNM, VRTC } = this.state;
    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Do not mesh the void smaller than</span>
        <span><Input
          className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
          addonAfter={'mm^2'}
          value={VOID}
          onChange={(e) => this.changeValue(e, 'VOID')}
          onBlur={(e) => this.inputBlur(e, 'VOID')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Explicitly mesh pads larger than</span>
        <span><Input
          className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
          addonAfter={'mm^2'}
          value={PADM}
          onChange={(e) => this.changeValue(e, 'PADM')}
          onBlur={(e) => this.inputBlur(e, 'PADM')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Ignore planes smaller than</span>
        <span><Input
          className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
          addonAfter={'mm^2'}
          value={PLNM}
          onChange={(e) => this.changeValue(e, 'PLNM')}
          onBlur={(e) => this.inputBlur(e, 'PLNM')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Snap vertices separated by less</span>
        <span><Input
          className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
          addonAfter={'um'}
          value={VRTC}
          onChange={(e) => this.changeValue(e, 'VRTC')}
          onBlur={(e) => this.inputBlur(e, 'VRTC')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
    </Fragment>
  }

  PowerSIRender = () => {
    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Special Void Settings</span>
      </div>
      {PowerSIParams.map(item => {
        return <div className='pdn-top-option-panel-content' key={item.key}>
          <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body  pdn-extraction-panel-sub-span'>{item.title}</span>
          <span><Input
            className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
            addonAfter={item.unit}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            onBlur={(e) => this.inputBlur(e, item.key)}
            onPressEnter={this.onPressEnter}
            disabled={false}
          />
          </span>
        </div>
      })}
    </Fragment>
  }

  saveAndShow = () => {
    const { ratio } = this.state;
    this.saveConfig();
    this.props.showPreview(ratio);
  }

  ClipRender = () => {
    const { CLIP, IPC, engine, ratio } = this.state;
    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Clipping</span>
        <Switch
          size="small"
          className="aurora-switch-small fastpi-clipping-btn"
          checked={CLIP === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'CLIP')}
        />
        {
          engine === POWERSI &&
          <Fragment>
            <Input
              className='pdn-top-option-panel-content-input fastpi-pdn-option-input-addonAfter fastpi-extraction-option-clipping-input'
              addonAfter={'Ratio'}
              disabled={CLIP === 0}
              value={ratio}
              onChange={(e) => this.changeValue(e, 'ratio')}
              onBlur={(e) => this.inputBlur(e, 'ratio')}
              onPressEnter={this.onPressEnter}
            />
            <PreviewBtn
              disabled={CLIP === 0}
              savePreviewStatus={this.props.savePreviewStatus}
              saveAndShow={this.saveAndShow}
              onRef={this.props.previewBtnOnRef}
            />
          </Fragment>
        }
      </div>
      {engine === SIWAVE && <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>{engine === SIWAVE ? 'Intra-plane Coupling' : 'Inter-plane Coupling'}</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={IPC === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'IPC')}
        />
      </div>}
    </Fragment>
  }

  PadShapeRender = () => {
    const { PADM, SHPM } = this.state;
    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Pad &lt;===&gt; Shape Conversion</span>
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body pdn-extraction-panel-sub-span'>Max pad size</span>
        <span><Input
          className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
          addonAfter={'mm'}
          value={PADM}
          onChange={(e) => this.changeValue(e, 'PADM')}
          onBlur={(e) => this.inputBlur(e, 'PADM')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body pdn-extraction-panel-sub-span'>Min shape size</span>
        <span><Input
          className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input fastpi-pdn-option-input-addonAfter'
          addonAfter={'mm'}
          value={SHPM}
          onChange={(e) => this.changeValue(e, 'SHPM')}
          onBlur={(e) => this.inputBlur(e, 'SHPM')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
    </Fragment>
  }

  SIwavePortRender = () => {
    const { SAPD, FMAX, REFR, DISC, RESA, PKGR } = this.state;
    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Port Impedance</span>
        <Input className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input'
          placeholder='Use Package Model Port Impedance'
          value={REFR || ''}
          onChange={(e) => this.changeValue(e, 'REFR')}
          onBlur={(e) => this.inputBlur(e, 'REFR')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-sub-content-body pdn-top-option-panel-sub-content-extraction-body'><span className='pdn-top-option-panel-sub-box'></span>Export with Package Model Port Impedance</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={PKGR === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'PKGR')}
        />
      </div>
      <Divider />
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Samples Per Decade</span>
        <Input className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input'
          value={SAPD}
          onChange={(e) => this.changeValue(e, 'SAPD')}
          onBlur={(e) => this.inputBlur(e, 'SAPD')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-sub-content-body pdn-top-option-panel-sub-content-extraction-body'><span className='pdn-top-option-panel-sub-box'></span>Re-sample at the Package Model Frequencies</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={RESA === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'RESA')}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Discrete Sampling at the Package Model Frequencies</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={DISC === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'DISC')}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Maximum Frequency</span>
        <Input className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input'
          addonAfter={this.fMaxSelectAfter()}
          placeholder='Use Package Model Max Frequency'
          value={FMAX || ''}
          onChange={(e) => this.changeValue(e, 'FMAX')}
          onBlur={(e) => this.inputBlur(e, 'FMAX')}
          onPressEnter={this.onPressEnter}
        />
      </div>
    </Fragment>
  }

  PowerSIPortRender = () => {
    const { PKGR, REFR, RESA, SAPD } = this.state;
    return <Fragment>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Use Package Model Port Impedance</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={PKGR === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'PKGR')}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body pdn-extraction-panel-sub-span'>Port Impedance</span>
        <Input className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input'
          placeholder='Use Package Model Port Impedance'
          disabled={PKGR === 0 ? false : true}
          value={PKGR === 0 ? REFR : ''}
          onChange={(e) => this.changeValue(e, 'REFR')}
          onBlur={(e) => this.inputBlur(e, 'REFR')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Use Package Frequency Sampling</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={RESA === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'RESA')}
        />
      </div>
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body pdn-extraction-panel-sub-span'>Samples Per Decade</span>
        <Input className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input'
          disabled={RESA === 0 ? false : true}
          value={RESA === 0 ? SAPD : ''}
          onChange={(e) => this.changeValue(e, 'SAPD')}
          onBlur={(e) => this.inputBlur(e, 'SAPD')}
          onPressEnter={this.onPressEnter}
        />
      </div>
    </Fragment>
  }

  HPCRender = (type) => {
    const { numCores, corePercent, ramPercent } = this.state;
    return <HPCOptions
      numCores={numCores}
      corePercent={corePercent}
      ramPercent={ramPercent}
      type={type}
      ramDisplay={[SIWAVE].includes(type) ? true : false}
      changeValue={this.changeValue}
      inputBlur={this.inputBlur}
      onPressEnter={this.onPressEnter}
      className={`pdn-top-option-panel-content`}
    />
  }

  fitRender = () => {
    const { acDcFit } = this.state;
    return <Fragment>
      <Divider />
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body'>Use Aurora Low Frequency Enhancement</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={acDcFit}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'acDcFit')}
        />
      </div>
    </Fragment>
  }

  getAdvancedOptions = () => {
    const { engine } = this.state;
    switch (engine) {
      case POWERSI:
        return [
          { title: 'Special Circuit Element Support', key: 'Special Circuit Element Support' },
          { title: 'Add Top floating patch at a distance of', key: 'topFloating', child: true, optionType: 'mix', mixKey: 'topDistance', addonAfter: 'mm' },
          { title: 'Add Bottom floating patch at a distance of', key: 'bottomFloating', child: true, optionType: 'mix', mixKey: 'bottomDistance', addonAfter: 'mm' }
        ];
      default:
        return []
    }
  }

  advancedRender = () => {
    const advancedOptions = this.getAdvancedOptions();
    const { advanced } = this.state;
    return advancedOptions.length ? <Fragment>
      <Divider />
      <div className='pdn-top-option-panel-content'>
        <span className='pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body fastpi-top-option-panel-advanced-body' onClick={this.showAdvanced}>
          {advanced ? <DownOutlined className='advanced-arrow-icon' /> : <RightOutlined className='advanced-arrow-icon' />}
          Advanced
        </span>
      </div>
      {advanced && advancedOptions.map(option => {
        return this.advancedOptionsRender(option)
      })}
    </Fragment> : null;
  }

  advancedOptionsRender = (option) => {
    const { key, title, optionType, child, mixKey, addonAfter } = option;
    const className = child ? 'fastpi-extraction-panel-next-sub-span' : 'pdn-extraction-panel-sub-span'
    switch (optionType) {
      case 'mix':
        return <div className='pdn-top-option-panel-content' key={key}>
          <span className={`pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body ${className}`}>{title}</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={this.state[key] || false}
            onChange={(checked) => this.changeBooleanSwitch(checked, key)}
          />
          {
            this.state[key] ?
              <Input
                className='pdn-top-option-panel-content-input pdn-top-option-panel-content-extraction-input pdn-option-input-addonAfter fastpi-option-advanced-mix-input'
                addonAfter={addonAfter}
                value={this.state[mixKey]}
                onChange={(e) => this.changeValue(e, mixKey)}
                onBlur={(e) => this.inputBlur(e, mixKey)}
                onPressEnter={this.onPressEnter}
              /> : null
          }
        </div>
      default:
        return <div className='pdn-top-option-panel-content' key={key}>
          <span className={`pdn-top-option-panel-content-body pdn-top-option-panel-content-extraction-body ${className}`}>{title}</span>
        </div>
    }
  }

  render() {
    const { error, engine, maxWidth, maxHeight } = this.state;
    const { designId } = this.props;
    const height = error ? 554 : 523;
    const content = (
      <Panel
        className='pdn-top-option-panel fastpi-extraction-option-panel'
        title={<div className='pdn-top-option-panel-name'>Extraction Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 862 })}
        position='panel-center'
        // mask={true}
        draggable
        minWidth={200}
        minHeight={200}
        height={maxHeight > height ? null : height}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='pdn-top-option-panel-main pdn-top-extraction-panel-main'>
          {permissionData.hasAOI() ? <Fragment>
            <div className='pdn-top-option-panel-content'>
              <span className='pdn-top-option-panel-engine-option pdn-top-option-panel-content-body'>Extraction Engine</span>
              <Radio.Group onChange={this.changeEngineRadio} value={engine}>
                <Radio value={SIWAVE} disabled={judgeDesignIsSpd(designId)} >Ansys SIwave</Radio>
                <Radio value={POWERSI} disabled={judgePowerSIDisabledByDesign(designId)} >Cadence PowerSI/PowerDC</Radio>
              </Radio.Group>
            </div>
            <Divider />
          </Fragment> : null}
          {engine === SIWAVE ? this.SIwavePortRender() : this.PowerSIPortRender()}
          <Divider />
          {this.ClipRender()}
          {engine === SIWAVE && this.fitRender()}
          {engine === POWERSI && <Divider />}
          {engine === POWERSI && this.PadShapeRender()}
          <Divider />
          {engine === SIWAVE ? this.SIWAVERender() : this.PowerSIRender()}
          {this.HPCRender(engine)}
          {this.advancedRender()}
          {error && <span className='pdn-top-option-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ExtractionPanel;