import React, { Component, Fragment } from 'react';
import { Input, Tag } from 'antd';
import Panel from '../Panel';
import { createPortal } from 'react-dom';
import { CASCADE, FASTPI } from '@/constants/pageType';
import './index.css';

class CustomRLC extends Component {

  constructor(props) {
    super(props);
    let { Res, Ind, Cap, Jumper = [], Ferrite = [] } = props.COMP_PREFIX_LIB;
    this.state = {
      error: null,
      resValue: '',
      indValue: '',
      capValue: '',
      jumperValue: '',
      ferriteValue: '',
      className: null,
      COMP_PREFIX_LIB: {
        Res: [...Res],
        Ind: [...Ind],
        Cap: [...Cap],
        Jumper: [...Jumper],
        Ferrite: [...Ferrite]
      }
    }
    this.dialogRoot = document.getElementById('root');
  }

  inputClick = (e, type, ref) => {
    e.stopPropagation();
    if (ref) {
      ref.focus();
    }
    this.setState({
      className: type
    })
  }

  inputChange = (e, valueType) => {
    e.stopPropagation();
    let value = e.target.value;
    if (value === ',') {
      this.setState({
        resValue: '',
        indValue: '',
        capValue: '',
        jumperValue: '',
        ferriteValue: '',
        error: ''
      })
      return;
    }
    this.setState({
      error: '',
      [valueType]: value
    })
  }

  onPressEnter = (e, type) => {
    e.stopPropagation();
    const { COMP_PREFIX_LIB } = this.state;
    let value = e.target.value;
    const prev = COMP_PREFIX_LIB[type] ? COMP_PREFIX_LIB[type] : []
    let prevList = [...prev]

    if (!value) {
      return;
    }

    if (value) {
      //Whether the value is legal.
      const errorWords = value.match(/[^0-9a-zA-Z_-]+/g);
      if (errorWords && errorWords.length > 0) {
        this.setState({
          error: 'Value may only contain the following characters: numbers, letters, underscores, minus.',
          resValue: '',
          indValue: '',
          capValue: '',
          jumperValue: '',
          ferriteValue: ''
        });
        return;
      }
    }

    if (prevList.includes(value)) {
      return;
    }
    let newList = [...prevList, value];
    let compPrefixLib = this.state.COMP_PREFIX_LIB;
    compPrefixLib[type] = newList;
    this.setState({
      resValue: '',
      indValue: '',
      capValue: '',
      jumperValue: '',
      ferriteValue: '',
      error: '',
      COMP_PREFIX_LIB: compPrefixLib,
    })
  }

  handleDelete = (value, type) => {//delete item
    const { COMP_PREFIX_LIB } = this.state;
    const prev = COMP_PREFIX_LIB[type] ? COMP_PREFIX_LIB[type] : []
    let prevList = [...prev]

    if (!value) {
      return;
    }

    let newList = prevList.filter(item => item !== value);
    let compPrefixLib = this.state.COMP_PREFIX_LIB;
    compPrefixLib[type] = newList;
    this.setState({
      COMP_PREFIX_LIB: compPrefixLib
    })
  }

  onKeyDown = (e, type) => {
    if (e.keyCode === 188) {
      this.onPressEnter(e, type);
    }
  }

  inputBlur = (e, type) => {
    this.setState({
      className: null
    })
    this.onPressEnter(e, type);
  }

  closeModal = () => {
    const { COMP_PREFIX_LIB } = this.state;
    const { Res, Ind, Cap } = COMP_PREFIX_LIB;
    const { product = FASTPI } = this.props;
    const new_COMP_PREFIX = product === CASCADE ? { ...COMP_PREFIX_LIB } : { Res: [...Res], Ind: [...Ind], Cap: [...Cap] }
    this.props.closeModal({ ...new_COMP_PREFIX })
  }

  customArray = () => {
    const { COMP_PREFIX_LIB: { Res, Ind, Cap, Jumper, Ferrite } } = this.state;
    const { resValue, indValue, capValue, jumperValue, ferriteValue } = this.state;
    const { product = FASTPI } = this.props;
    let array = [
      { type: 'Res', data: Res, value: resValue, valueType: 'resValue', name: 'Resistor', currentRef: 'resRef', key: 'resInput' },
      { type: 'Ind', data: Ind, value: indValue, valueType: 'indValue', name: 'Inductor', currentRef: 'indRef', key: 'indInput' },
      { type: 'Cap', data: Cap, value: capValue, valueType: 'capValue', name: 'Capacitor', currentRef: 'capRef', key: 'capInput' },
    ]
    if (product === CASCADE) {
      array.push({ type: 'Jumper', data: Jumper, value: jumperValue, valueType: 'jumperValue', name: 'Jumper', currentRef: 'jumperRef', key: 'jumperInput' });
      array.push({ type: 'Ferrite', data: Ferrite, value: ferriteValue, valueType: 'ferriteValue', name: 'Ferrite', currentRef: 'ferriteRef', key: 'ferriteInput' })
    }
    return array;
  }

  customRLCItem = () => {
    const { error, className } = this.state;
    const RLCArray = this.customArray();
    let boxShadow = 'rgba(9, 128, 247, 0.14) 0px 0px 0px 2px, rgba(13, 151, 249, 0.1) 0px 0px 7px 0px';
    return RLCArray.map(rlcItem => {
      let { type, data = [], value, name, currentRef, key, valueType } = rlcItem;
      return <div className='custom-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
        <span className='custom-RLC-title'>{name} </span>
        <div className='custom-RLC-item-content' onClick={(e) => this.inputClick(e, type, this[currentRef])} style={className === type ? { borderColor: '#40a9ff', boxShadow: boxShadow } : {}}>
          <ul className='custom-RLC-tags'>
            {data.map((item, index) =>
              <li className='custom-RLC-item-tag' key={item}>
                <Tag
                  key={item}
                  closable={true}
                  onClose={() => this.handleDelete(item, type)}
                >
                  {item}
                </Tag>
              </li>
            )}
            <li className='custom-RLC-item-input-box' key={key}>
              <Input
                className='custom-RLC-item-input'
                value={value}
                style={{ width: value ? value.length * 12 : '.75em' }}
                ref={(input) => { this[currentRef] = input; }}
                onChange={(e) => this.inputChange(e, valueType)}
                onPressEnter={(e) => this.onPressEnter(e, type)}
                onBlur={(e) => this.inputBlur(e, type)}
                onKeyDown={(e) => this.onKeyDown(e, type)}
              />
            </li>
          </ul>
        </div>
      </div>
    })
  }

  render() {
    const { error } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='custom-RLC-panel'
          title='Custom Reference Designator'
          onCancel={this.closeModal}
          zIndex={2000}
          width={400}
          position='panel-center-left'
          draggable
          minHeight={220}
          minWidth={400}
        >
          <div className='custom-RLC-content'>
            {this.customRLCItem()}
            {error && <div className='custom-RLC-item' style={{ height: '25%' }}>
              <span className='model-name-error-msg'>{error}</span>
            </div>}
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default CustomRLC;