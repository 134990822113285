import { call, put, takeEvery, delay, select, takeLatest, fork, cancel } from 'redux-saga/effects';
import {
  UPDATE_LIBRARY_MENU,
  UPDATE_PROJECT_MENU,
  OPEN_PROJECT,
  OPEN_CHANNEL,
  SAVE_COMPONENTS_NETS_IN_PROJECT,
  AUTO_GET_VERIFICATION_LIST,
  UPDATE_VIEW_LIST,
  UPDATE_TRASH_MENU,
  GET_REPORT,
  GET_REPORT_FILE,
  SAVE_REPORT_CONFIG,
  CLEAR_REPORT_INFO,
  DEFAULT_EXTRACTION_CONFIG,
  DEFAULT_CHANNEL_CONFIG,
  SAVE_CHANNEL_EXTRACTION_CONFIG,
  UPDATE_MASK_LIBRARY_LIST,
  UPDATE_SHARE_MENU,
  SAVE_PRE_LAYOUT_TEMPLATE,
  CLEAN_CHANNEL_BY_PROJECTID,
  REPLACE_PCB,
  UPDATE_PCB_LAYOUT,
  SAVE_PACKAGE_VERIFICATION_EXTRACTION_CONFIG,
  DELETE_CARD,
  OPEN_PACKAGE_TREE,
  UPDATE_CARD_LIBRARY_LIST,
  CARD_LIBRARY_RENAME,
  SAVE_WAVEFORM_CONFIG,
  SET_DEFAULT_WAVEFORM_CONFIG,
  SAVE_WIRE_BOND_PROFILE,
  OPEN_SSN_CHANNEL,
  GET_LIBRARY_FOLDER_CHILDREN,
  EDIT_PROJECT_NAME,
  LIBRARY_DEFAULT,
  INIT_SYS_LIBRARY,
  UPDATE_SYS_LIBRARY
} from './actionTypes';
import {
  CHANNEL,
  CHANNELS,
  ROCKY_PDN,
  PACKAGE_CHANNEL
} from '@/services/Rocky/constants';
import {
  PKG_SPICE,
  PKG_TOUCHSTONE,
  TRACE,
  VIA,
  EBD,
  SOCKET_TOUCHSTONE,
  SOCKET_SPICE,
  WIRE_BOND_XML,
  CUSTOM_TOUCHSTONE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  CUSTOM_SPICE
} from '@/constants/libraryConstants';
import {
  MY_TRASH,
  PCB_PRE_LAYOUT,
  PCB,
  PROJECT,
  PCBS,
  VERIFICATION,
  RESULT,
  PACKAGE,
  PACKAGE_VERIFICATION,
  CARD,
  PCB_CHANNEL,
  PCB_PDN,
} from '@/constants/treeConstants';
import {
  getLibraryList,
  getLibraryArray,
  getLibraryMacroModelingStatus,
  createLibrary,
  updateLibraryData,
  renameLibrary,
  getLibraryFolderChildren,
  libraryItem,
  setDefaultDecap,
  getDefaultDecap
} from '@/services/Rocky/library';
import {
  getProjectList,
  getRockyProjectPromise,
  getDefaultProjectTree,
  getDesignTree,
  getChannelTree,
  updateProjectChild,
  getRockyTrashList,
  getChannelConfig,
  changeConfigFormat,
  getRockyReportData,
  saveReportConfigPromise,
  getPowerComponents,
  PDNExtraction,
  CreateRockyChannelPDN,
  pdnPKG,
  pdnDie,
  getExtendedNet,
  getRockyVerificationsInChannel,
  updateChannelItem,
  filterComps,
  getVerificationTree,
  getChannelPDNContentPromise,
  updateRockyPDNContentPromise,
  updateVerificationContent,
  cleanCtrlInfo,
  judgeUpdateGapPortSetup,
  updateExtractionGapPortsSetup,
  getEyeMaskType,
  getDefaultClockAndTimeStep,
  getDefaultPortsGenerateSetupList,
  getReportStatus,
  Extraction,
  judgeExtraction,
  updateChannelExtractionConfig,
  updateChannelConfig,
  ConfigInfo,
  getVerificationContentPromise,
  getProjectEyeMaskOptionPromise,
  editProjectEyeMaskOptionPromise,
  getPackageVerificationListPromise,
  getPackagesTree,
  getPackageExtractionPromise,
  updatePackageExtractionPromise,
  updatePackageVerificationPromise,
  PROJECT_INDEX,
  PACKAGE_INDEX,
  getCardTree,
  channelItem,
  deleteDesignInProject,
  verificationItem,
  CARD_INDEX,
  savePkgWireBondProfile,
  getSSNChannelTree,
  getRockyPackagePDN,
  getSSNPackagesTree,
  DESIGN_INDEX,
  changeProjectName,
  TRASH_INDEX
} from '@/services/Rocky';
import {
  libraryMenu,
  expandMenu,
  projectMenu,
  saveOpenProjectInfo,
  saveCurrentChannelInfo,
  updatePCBComponentsNets,
  updateTreeSelectedKeys,
  openPageInfo,
  trashMenu,
  openChannel,
  updateReportProgress,
  updateReportVisible,
  updateReportMessage,
  updateExtractionConfig,
  defaultExtractionConfig,
  defaultChannelConfig,
  updateChannelComponentList,
  debugMonitorAction,
  updateProjectInterfacesInfo,
  shareMenu,
  autoGetVerificationList,
  updateReportConfig,
  updateViewList,
  updatePackageExtractionConfig,
  updateCardLibraryList,
  updateCurrentLoadingPCBs,
  updateWaveformConfig,
  defaultWaveformConfig,
  getSpiceFolderChildren,
  updateDefaultDecap,
} from './action';
import {
  openPage,
  updateCurrentConfig,
  getLibraryFileParse,
  updateRockyInterfaces,
  updateRockyContentInfo,
  updateRockyPackageLayoutInfo,
  updateChannelByVersion,
  updatePackagePortAndBall,
  updateUserDefinedList
} from '../rocky/action';
import { splitComponentName } from '@/services/helper/splitComponent';
import { changeTabMenu, openTabFooter, cleanTabMonitorStatus } from '../../../MonitorStore/action';
import LayoutData from '@/services/data/LayoutData';
import DesignInfo from '@/services/Rocky/pcbInfo';
import { openResultPageInfo, cleanResultPageInfo, defaultResultConfig, updateResultConfig } from '../result/action';
import { changeLibraryStatus } from '../prelayout/action';
import { updateRockyPDNContent } from '../rockyPdn/action';
import { getDefaultResultConfig } from '@/services/Rocky/result';
import { LPDDR5, portSavePath } from '@/services/Rocky/constants';
import FileSaver from 'file-saver';
import { ROCKY_PDN_VERSION } from '@/version';
import { componentFilter, getVRMs } from '@/services/helper/componentsHelper';
import { getBlob } from '@/services/helper/downloadHelper';
import VerificationInfo from '@/services/Rocky/verificationHelper';
import { getDefaultRLCValue } from '@/services/helper/RLCValue';
import ChannelComponentsInfo from '@/services/Rocky/helper/ChannelComponentsInfo';
import CompRLCPrefixLib from '@/services/helper/componentsHelper/componentRLCPrefix';
import * as taskStatus from '@/constants/workflowStatus';
import PCBInterfacesInfo from '@/services/Rocky/helper/PCBInterfacesInfo';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { LIBRARY_INDEX, SYSTEM_LIBRARY_INDEX, SYS_DECAP_INDEX, SYS_DECAP_VENDOR_INDEX, SYS_DECAP_GENERIC_INDEX, SYS_CUSTOM_INDEX } from '@/services/Rocky/library/libraryData';
import { getEyeMaskListPromise } from '@/services/EyeMask/maskCtrl';
import { getMaskList, getTreeIndex } from '@/services/Rocky/helper/rockyTree';
import { isRockyPuppeteer } from '@/services/helper/browser';
import { getShareTreeList, } from '@/services/share/shareTree';
import { ROCKY } from '@/constants/pageType';
import { PRE_LAYOUT } from '@/constants/designVendor';
import { getDataTypePrefix } from '@/services/tree/treeItem';
import { getLayoutDBInfo, getLayoutComponents, checkCompsType } from "@/services/PCBHelper";
// Data
import channelVerifications from '@/services/Rocky/helper/channelVerifications';
import projectChannels from '@/services/Rocky/helper/projectChannels';
import rockyChannels from '@/services/Rocky/helper/channels';
import projectDesigns from '@/services/helper/projectDesigns';
import rockyDesigns from '@/services/helper/designs';
import { strDelimited } from '@/services/helper/split';
import permissionData from '@/services/helper/data/permissionData';
import PreLayoutData from '@/services/Rocky/helper/PreLayoutLibraryData';
import { timeFormat } from '../../../../services/helper/timeFormat';
import designConstructor from '@/services/helper/designConstructor';
import { message } from 'antd';
import {
  judgeUpdateGapPortGapSizeSetup,
  updateHFSSExtractionCompsGapSize
} from "@/services/ExtractionPortsHelper";
import RockySetup from '@/services/Rocky/helper/rockyDatabase';
import { getProjectType } from '../rocky/saga';
import { SAVE_CONTENT_TO_SERVER } from '../rocky/actionTypes';
import { getComponentNeedInfoByName } from '@/services/PCBHelper/components'
import { cleanStatus, updatePCBRefreshStatus } from '../../../LayoutExplorer/store/Rocky/actionCreators';
import { PCB_ONLY, PCB_TOP_BOTTOM, PCB_LEFT_RIGHT } from '../../../../constants/layoutConstants';
import { updateTreeAfterChangePCBLayout } from '../../../../services/helper/filterHelper';
import packageVerificationConstructor from '../../../../services/Rocky/PackageHelper'
import { CARDS, CARD_CHANNEL, CARD_RESULT, CARD_VERIFICATION, DIMM_DDR_TYPES, PACKAGE_PDN, SINGLE_PATTERN, SSN_CHANNEL } from '../../../../services/Rocky/constants';
import cardChannelsConstructor from '../../../../services/Rocky/cardHelper';
import { openCardChannel, updateCardExtractionConfig } from '../card/action';
import { getCardLibraryTree } from '../../../../services/Rocky/library/cardLibraryData';
import { getDefaultWaveformConfig } from '../../../../services/Rocky/helper/setupData';
import { PCB_SPICE, PCB_TOUCHSTONE, ON_DIE_TOUCHSTONE, PCB_PDN_TOUCHSTONE, DECAP_SPICE, DECAP_TOUCHSTONE, SPICE, ON_DIE_SPICE, PCB_PDN_SPICE, PATTERN_LIBRARY, USER_DEFINED_NETLIST, CUSTOM_POST_PROCESS, CSM_CPM_SPICE, IBIS_AMI } from '../../../../constants/libraryConstants';
import { PROJECT_BMA, PROJECT_V1, PROJECT_V2 } from '../../../../constants/projectVersion';
import RockySSNChannelInfo from '../../../../services/Rocky/SSN/channelsInfo';
import { savePackageWireBondProfile } from '../../../../services/api/Design/design';
import { BMA_CHANNEL, MULTIPLE_CHANNEL, PACKAGE_PRE_LAYOUT, SSN_RESULT, SSN_VERIFICATION } from '../../../../constants/treeConstants';
import pcbChannelConstructor from '../../../../services/Rocky/pcbChannelConstructor';
import { DESIGN_SUCCESS } from '../../../../constants/designCategory';
import SystemLibrary, { SysLibraryItem } from '../../../../services/Library/systemLibraryStore';
import { SYS_LIBRARY } from '@/constants/treeConstants';
import { getSysGeneric } from '../../../../services/Library';
import { getFolderDetail } from '../../../../services/Andes_v2/library';
import { getInterfaceMonitor } from '../simulation/action';
import { VERIFY_SUCCESS } from '../../../../constants/verificationStatus';

let reportTask = null, createPDNTask = null, touchstoneStatusTask = null;

const CARD_LIBRARY_INDEX = 8;
function* updateLibraryMenu(action) {
  const { isRetrieveFileInFolder } = action;
  let res;
  try {
    res = yield call(getLibraryList);
  } catch (error) {
    message.error('Cannot get library list! ' + error)
    return;
  }
  yield call(_updateMaskList)
  if (touchstoneStatusTask) {
    yield cancel(touchstoneStatusTask);
  }

  const { RockyReducer: { project } } = yield select();
  const { treeItems, spiceList: oldSpiceList, pcbSpiceList: oldPCBSpiceList, onDieSpiceList: oldOnDieSpiceList, pdnSpiceList: oldPdnSpiceList, userDefinedNetListList: oldUserDefinedNetList, customPostProcessList: oldCustomPostProcessList, ibisAmiList: oldIbisAmiList } = project;
  let _treeItems = [...treeItems];
  const BUFFERIndex = 0, IBISIndex = 0, IBISAMIIndex = 1, SPICEIndex = 2, vectorIndex = 1, VRMIndex = 2, PACKAGEIndex = 3, TRACEINDEX = 5, VIAINDEX = 6, pkgTouchstoneIndex = 0, pkgSPiceIndex = 1, pkgEBDIndex = 2, SOCKET_INDEX = 7,
    CUSTOM_INDEX = 9, WIRE_BOND_INDEX = 10,
    EXTRACTION_OPTIONS_INDEX = 11,
    HFSS_OPTIONS_INDEX = 0,
    SIWAVE_OPTIONS_INDEX = 1,
    PCB_MODEL_INDEX = 12, pcbTouchstoneIndex = 0, pcbSPiceIndex = 1,
    ON_DIE_MODEL_INDEX = 13, onDieTouchstoneIndex = 0, onDieSpiceIndex = 0,
    PDN_MODEL_INDEX = 14, pdnTouchstoneIndex = 0, pdnSpiceIndex = 1,
    DECAP_INDEX = 15, decapTouchstoneIndex = 0, decapSpiceIndex = 1,
    PATTERN_LIBRARY_INDEX = 16, USER_DEFINED_LIBRARY_INDEX = 17, CUSTOM_POST_PROCESS_INDEX = 18;
  let ibisList = [], ibisAmiList = [], spiceList = [], vectorList = [], VRMList = [], pkgSpiceList = [],
    pkgTouchstoneList = [], traceList = [], viaList = [], ebdList = [], socketTouchstoneList = [], socketSpiceList = [], cardLibraryList = [],
    customTouchstoneList = [], customSpiceList = [], wireBondProfileList = [], decapSpiceList = [], decapTouchstoneList = [], extractionHfssOptionsList = [], extractionSiwaveOptionsList = [], pcbTouchstoneList = [], pcbSpiceList = [],
    onDieTouchstoneList = [], pdnTouchstoneList = [], onDieSpiceList = [], pdnSpiceList = [], patternLibraryList = [], userDefinedNetListList = [], customPostProcessList = [];

  if (_treeItems[LIBRARY_INDEX] && _treeItems[LIBRARY_INDEX].children) {
    //update ibis library list
    ibisList = getLibraryArray('IBIS', res.ibis);
    _treeItems[LIBRARY_INDEX].children[BUFFERIndex].children[IBISIndex].children = ibisList;

    //update ibis ami library list
    ibisAmiList = getLibraryArray(IBIS_AMI, res.ibis_ami);
    if (!isRetrieveFileInFolder) {
      ibisAmiList = yield call(addFileInFolder, ibisAmiList, oldIbisAmiList, IBIS_AMI)
    }
    _treeItems[LIBRARY_INDEX].children[BUFFERIndex].children[IBISAMIIndex].children = ibisAmiList;

    //update spice library list
    spiceList = getLibraryArray('SPICE', res.spice).filter(item => item.name.match(/.sp$/i) || item.type === "folder");
    if (!isRetrieveFileInFolder) {
      spiceList = yield call(addFileInFolder, spiceList, oldSpiceList, SPICE)
    }
    _treeItems[LIBRARY_INDEX].children[BUFFERIndex].children[SPICEIndex].children = spiceList;

    //update vector library list
    vectorList = getLibraryArray('vector', res.vector);
    _treeItems[LIBRARY_INDEX].children[vectorIndex].children = vectorList;

    //update VRM library list
    VRMList = getLibraryArray('VRM', res.VRM);
    _treeItems[LIBRARY_INDEX].children[VRMIndex].children = VRMList;

    //update pkg touchstone library list
    pkgTouchstoneList = getLibraryArray(PKG_TOUCHSTONE, res.pkg_touchstone);
    _treeItems[LIBRARY_INDEX].children[PACKAGEIndex].children[pkgTouchstoneIndex].children = pkgTouchstoneList;

    //update pkg spice library list
    pkgSpiceList = getLibraryArray(PKG_SPICE, res.pkg_spice);
    _treeItems[LIBRARY_INDEX].children[PACKAGEIndex].children[pkgSPiceIndex].children = pkgSpiceList;

    //update ebd library list
    ebdList = getLibraryArray(EBD, res.ebd);
    _treeItems[LIBRARY_INDEX].children[PACKAGEIndex].children[pkgEBDIndex].children = ebdList;

    //update tract library list
    traceList = getLibraryArray(TRACE, res.trace);
    _treeItems[LIBRARY_INDEX].children[TRACEINDEX].children = traceList;

    //update via library list
    viaList = getLibraryArray(VIA, res.via);
    _treeItems[LIBRARY_INDEX].children[VIAINDEX].children = viaList;

    //update socket library list
    socketTouchstoneList = getLibraryArray(SOCKET_TOUCHSTONE, res.socket_touchstone);
    _treeItems[LIBRARY_INDEX].children[SOCKET_INDEX].children[pkgTouchstoneIndex].children = socketTouchstoneList;
    //update socket library list
    socketSpiceList = getLibraryArray(SOCKET_SPICE, res.socket_spice);
    _treeItems[LIBRARY_INDEX].children[SOCKET_INDEX].children[pkgSPiceIndex].children = socketSpiceList;
    //update card library list
    cardLibraryList = getCardLibraryTree(res.card);
    _treeItems[LIBRARY_INDEX].children[CARD_LIBRARY_INDEX].children = cardLibraryList;
    //update wire bond profile
    wireBondProfileList = getLibraryArray(WIRE_BOND_XML, res.wire_bond_xml);
    _treeItems[LIBRARY_INDEX].children[WIRE_BOND_INDEX].children = wireBondProfileList;

    //update custom component library list
    customTouchstoneList = getLibraryArray(CUSTOM_TOUCHSTONE, res.custom_touchstone);
    _treeItems[LIBRARY_INDEX].children[CUSTOM_INDEX].children[pkgTouchstoneIndex].children = customTouchstoneList;
    customSpiceList = getLibraryArray(CUSTOM_SPICE, res.custom_spice);
    _treeItems[LIBRARY_INDEX].children[CUSTOM_INDEX].children[pkgSPiceIndex].children = customSpiceList;

    // update hfss extraction options library list
    extractionHfssOptionsList = getLibraryArray(HFSS_OPTIONS, res[HFSS_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[HFSS_OPTIONS_INDEX].children = extractionHfssOptionsList;

    // update siwave extraction options library list
    extractionSiwaveOptionsList = getLibraryArray(SIWAVE_OPTIONS, res[SIWAVE_OPTIONS]);
    _treeItems[LIBRARY_INDEX].children[EXTRACTION_OPTIONS_INDEX].children[SIWAVE_OPTIONS_INDEX].children = extractionSiwaveOptionsList;

    //update pcb touchstone library list
    pcbTouchstoneList = getLibraryArray(PCB_TOUCHSTONE, res.pcb_touchstone);
    _treeItems[LIBRARY_INDEX].children[PCB_MODEL_INDEX].children[pcbTouchstoneIndex].children = pcbTouchstoneList;

    //update pcb spice library list
    pcbSpiceList = getLibraryArray(PCB_SPICE, res.pcb_spice);
    if (!isRetrieveFileInFolder) {
      pcbSpiceList = yield call(addFileInFolder, pcbSpiceList, oldPCBSpiceList, PCB_SPICE)
    }
    _treeItems[LIBRARY_INDEX].children[PCB_MODEL_INDEX].children[pcbSPiceIndex].children = pcbSpiceList;

    // onDieTouchstoneList = getLibraryArray(ON_DIE_TOUCHSTONE, res.on_die_touchstone);
    // _treeItems[LIBRARY_INDEX].children[ON_DIE_MODEL_INDEX].children[onDieTouchstoneIndex].children = onDieTouchstoneList;

    onDieSpiceList = getLibraryArray(ON_DIE_SPICE, res.on_die_spice).filter(item => item.name.match(/.sp$/i) || item.type === "folder");;
    if (!isRetrieveFileInFolder) {
      onDieSpiceList = yield call(addFileInFolder, onDieSpiceList, oldOnDieSpiceList, ON_DIE_SPICE)
    }
    _treeItems[LIBRARY_INDEX].children[ON_DIE_MODEL_INDEX].children[onDieSpiceIndex].children = onDieSpiceList;

    pdnTouchstoneList = getLibraryArray(PCB_PDN_TOUCHSTONE, res.pcb_pdn_touchstone);
    _treeItems[LIBRARY_INDEX].children[PDN_MODEL_INDEX].children[pdnTouchstoneIndex].children = pdnTouchstoneList;

    pdnSpiceList = getLibraryArray(PCB_PDN_SPICE, res.pcb_pdn_spice).filter(item => item.name.match(/.sp$/i) || item.type === "folder");
    if (!isRetrieveFileInFolder) {
      pdnSpiceList = yield call(addFileInFolder, pdnSpiceList, oldPdnSpiceList, PCB_PDN_SPICE)
    }
    _treeItems[LIBRARY_INDEX].children[PDN_MODEL_INDEX].children[pdnSpiceIndex].children = pdnSpiceList;

    //update decap component library list
    decapTouchstoneList = getLibraryArray(DECAP_TOUCHSTONE, res.decap_touchstone);
    _treeItems[LIBRARY_INDEX].children[DECAP_INDEX].children[decapTouchstoneIndex].children = decapTouchstoneList;
    //update decap component library list
    decapSpiceList = getLibraryArray(DECAP_SPICE, res.decap_spice);
    _treeItems[LIBRARY_INDEX].children[DECAP_INDEX].children[decapSpiceIndex].children = decapSpiceList;

    //update pattern library list
    patternLibraryList = getLibraryArray(PATTERN_LIBRARY, res.pattern);
    _treeItems[LIBRARY_INDEX].children[PATTERN_LIBRARY_INDEX].children = patternLibraryList;

    //update pattern library list
    userDefinedNetListList = getLibraryArray(USER_DEFINED_NETLIST, res.user_defined_netlist).filter(item => item.name.match(/.sp$/i) || item.type === "folder");
    if (!isRetrieveFileInFolder) {
      userDefinedNetListList = yield call(addFileInFolder, userDefinedNetListList, oldUserDefinedNetList, USER_DEFINED_NETLIST)
    }
    _treeItems[LIBRARY_INDEX].children[USER_DEFINED_LIBRARY_INDEX].children = userDefinedNetListList;

    // custom post-precessing library list
    customPostProcessList = getLibraryArray(CUSTOM_POST_PROCESS, res.custom_post_process);
    if (!isRetrieveFileInFolder) {
      customPostProcessList = yield call(addFileInFolder, customPostProcessList, oldCustomPostProcessList, CUSTOM_POST_PROCESS)
    }
    _treeItems[LIBRARY_INDEX].children[CUSTOM_POST_PROCESS_INDEX].children = customPostProcessList;

    yield put(libraryMenu({
      treeItems: [..._treeItems],
      ibisList,
      spiceList,
      vectorList,
      VRMList,
      pkgTouchstoneList,
      pkgSpiceList,
      traceList,
      viaList,
      ebdList,
      socketTouchstoneList,
      socketSpiceList,
      cardLibraryList,
      wireBondProfileList,
      customTouchstoneList,
      customSpiceList,
      pcbTouchstoneList,
      pcbSpiceList,
      onDieTouchstoneList,
      pdnTouchstoneList,
      onDieSpiceList,
      pdnSpiceList,
      decapTouchstoneList,
      decapSpiceList,
      extractionHfssOptionsList,
      extractionSiwaveOptionsList,
      patternLibraryList,
      userDefinedNetListList,
      customPostProcessList,
      ibisAmiList
    }));

    const defaultLibrary = yield call(getDefaultDecap);
    yield put(updateDefaultDecap(defaultLibrary || {}))

    //get touchstone status list
    const runningFileList = pkgTouchstoneList.filter(item => item.status === taskStatus.RUNNING || item.status === taskStatus.WAITING);
    if (runningFileList && runningFileList.length) {
      if (touchstoneStatusTask) {
        yield cancel(touchstoneStatusTask);
      }
      touchstoneStatusTask = yield fork(_getTouchstoneFileStatus, { runningFileList });
    }
    //setup library file check
    yield put(getLibraryFileParse());

    if (isRetrieveFileInFolder) {
      // loading folder children
      const folderInfo = [{
        list: spiceList,
        libraryType: SPICE,
        index: BUFFERIndex,
        childrenIndex: SPICEIndex,
        key: "spiceList"
      }, {
        list: pcbSpiceList,
        libraryType: PCB_SPICE,
        index: PCB_MODEL_INDEX,
        childrenIndex: pcbSPiceIndex,
        key: "pcbSpiceList"
      }, {
        list: onDieSpiceList,
        libraryType: ON_DIE_SPICE,
        index: ON_DIE_MODEL_INDEX,
        childrenIndex: onDieSpiceIndex,
        key: "onDieSpiceList"
      }, {
        list: pdnSpiceList,
        libraryType: PCB_PDN_SPICE,
        index: PDN_MODEL_INDEX,
        childrenIndex: pdnSpiceIndex,
        key: "pdnSpiceList"
      }, {
        list: userDefinedNetListList,
        libraryType: USER_DEFINED_NETLIST,
        index: USER_DEFINED_LIBRARY_INDEX,
        key: "userDefinedNetListList"
      }, {
        list: ibisAmiList,
        libraryType: IBIS_AMI,
        index: IBISIndex,
        childrenIndex: IBISAMIIndex,
        key: "ibisAmiList"
      }, {
        list: customPostProcessList,
        libraryType: CUSTOM_POST_PROCESS,
        index: CUSTOM_POST_PROCESS_INDEX,
        key: "customPostProcessList"
      }]
      yield put(getSpiceFolderChildren(folderInfo))
    }
  }
}


function* addFileInFolder(dataArr, oldDataArr, libraryType) {
  if (!Array.isArray(dataArr) || !dataArr.length) {
    return [];
  }

  let newDataArr = []
  for (let file of dataArr) {
    if (file && file.type === "folder") {
      const { id } = file;
      const findInfo = oldDataArr && oldDataArr.length ? oldDataArr.find(it => it.id === id) : null;
      let children = [];
      if (findInfo && findInfo.children && findInfo.children.length) {
        children = [...findInfo.children]
      } else {
        let _children = [];
        if (libraryType === IBIS_AMI) {
          _children = yield call(getFolderDetail, id, ROCKY);
        } else {
          _children = yield call(getLibraryFolderChildren, { libraryType: libraryType, libraryId: id });
          _children = _children.filter(item => item.type === "file")
        }
        if (_children && _children.length) {
          children = _children.map(item => new libraryItem(libraryType, {
            name: item.fileName,
            type: 'file',
            id: id,
            fileName: item.fileName,
            typeName: libraryType,
            nodeClass: 'tree-library-file',
            status: null,
            folderChildren: true
          }));
        }
      }
      newDataArr.push({ ...file, children })
    } else {
      newDataArr.push(file)
    }
  }
  return newDataArr;
}

function* updateProjectMenu(action) {
  const { RockyReducer: { project } } = yield select();
  let { treeItems } = project;
  let res;
  try {
    res = yield call(getProjectList);
  } catch (error) {
    message.error('Get project list error! ' + error)
    return;
  }
  // PROJECT_INDEX - 'projects'
  const { projects, projectList } = getDefaultProjectTree(res);
  treeItems[PROJECT_INDEX].children = projects;
  yield put(projectMenu({ treeItems: [...treeItems], projectList }));

  //open current project
  const { obj } = action;
  if (obj && obj.openProjectId) {
    let { expandedKeys } = project;
    const key = `${PROJECT}-${obj.openProjectId}`;

    if (!expandedKeys.includes(key)) {
      //close other open project
      expandedKeys = expandedKeys.filter(item => {
        const [_key, _id] = strDelimited(item, "-");

        if (!(_key === PROJECT && _id !== obj.openProjectId)) {
          return item;
        }
        return false;
      });
      //expand current project
      expandedKeys.push(key);
    }

    yield put(expandMenu(expandedKeys));
    yield call(openProjectByID, { id: obj.openProjectId, channelId: obj.channelId, verificationId: obj.verificationId, isResultPage: obj.isResultPage, key: PROJECT, load: obj.load, isCreate: obj.isCreate, afterTranslation: obj.afterTranslation });
  }
};

function* openProjectByID(action) {
  let { RockyReducer: { project, RockyUploadReducer } } = yield select();
  let { treeItems, expandedKeys } = project;
  // id - project id, key - PROJECT
  let { id, channelId, verificationId, afterTranslation, load, isResultPage, key, isCreate, isUploadPackage } = action;
  const dataTypePrefix = getDataTypePrefix(key);
  // init Eye Mask
  yield put({ type: 'initEyeMask', id });

  if (!afterTranslation && !load) {
    //clean monitor box info
    yield put(cleanTabMonitorStatus());
  }
  //clear prev project verification list
  VerificationInfo.deleteVerificationInfo();
  //clear prev project interface.json info
  PCBInterfacesInfo.deletePCBInterfaces(id);

  //delete current project channel dat info(componentList)
  if (!channelId) {
    ChannelComponentsInfo.deleteChannelComponents(id);
  }

  let _treeItems = [...treeItems];

  let findIndex = _treeItems[getTreeIndex(key)].children.findIndex(item => item.id === id);

  if (isUploadPackage) {
    // After uploading the sev package, you need to update the tree and add multiple projects.
    try {
      let treeRes = yield call(getProjectList);
      const { projects, projectList } = getDefaultProjectTree(treeRes);
      let _projects = [...projects];
      if (findIndex > -1) {
        _projects = _projects.map(item => {
          if (item.id === id) {
            return _treeItems[getTreeIndex(key)].children[findIndex]
          }
          return item;
        })
      }
      _treeItems[PROJECT_INDEX].children = _projects;
      findIndex = _treeItems[getTreeIndex(key)].children.findIndex(item => item.id === id);
      yield put(projectMenu({ treeItems: [..._treeItems], projectList }));
    } catch (error) {
      message.error('Get project list error! ' + error)
      return;
    }
  }

  if (findIndex < 0) {
    return;
  }

  let name = "", ddrType = "", version = '1.0';
  const projectItem = _treeItems[getTreeIndex(key)].children[findIndex];
  name = projectItem.name;
  ddrType = projectItem.type;
  version = projectItem.projectVersion;
  // Update Project ID and Name
  if (!load) {
    yield put(saveOpenProjectInfo({ id, name, version }));
  }

  // Get Channels and designs in Project
  let res;
  try {
    res = yield call(getRockyProjectPromise, id);
    if (!res) {
      throw new Error('')
    }
  } catch (error) {
    message.error('Get designs and interfaces error! ' + error);
    return;
  }

  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  if (currentProjectId !== id) {
    //removed prev project
    return;
  };

  const { uploadProjectId, uploadWorkflowId, visible, uploadType } = RockyUploadReducer;

  let designs = [], channels = [], cards = [];

  const isUpload = uploadProjectId === id && uploadWorkflowId && !afterTranslation && uploadType === PCBS;
  // Update channel
  const pcbChannel = version === PROJECT_V2 ? [...res.channels] : [];
  let pcbPdn = [];
  if (version === PROJECT_V2) {
    yield* (res.designs || []).map(function* (design) {
      if (design.vendor !== PRE_LAYOUT) {
        const pdn = yield call(getRockyPackagePDN, design.id);
        pcbPdn.push(pdn)
      }
    })
  }
  designs = getDesignTree(res.designs, dataTypePrefix, PCB, pcbChannel, pcbPdn, version);
  designConstructor.addDesigns(designs);
  projectDesigns.set(currentProjectId, designs);
  pcbChannelConstructor.add([...pcbChannel, pcbPdn]);
  let isPreLayout = projectDesigns.getAvailableDesignsVendor(currentProjectId) === PRE_LAYOUT ? true : false;

  channels = isUpload ? [] : getChannelTree(res.channels, dataTypePrefix, isPreLayout);
  // Upload Package
  let packages = getDesignTree(res.packages, dataTypePrefix, PACKAGE, [], [], version);
  designConstructor.addDesigns(packages);

  //cards
  cards = res.cards && res.cards.length ? getCardTree(res.cards, dataTypePrefix, res.cardChannels) : [];
  designConstructor.addDesigns(cards);
  cards.forEach(item => cardChannelsConstructor.setCardChannels(item.id, item.children));

  // Update Project designs

  res.designs.forEach(d => rockyDesigns.set(d.id, d));
  const children = DIMM_DDR_TYPES.includes(ddrType) ? [designs, packages, cards, channels] : [designs, packages, channels];
  _treeItems[getTreeIndex(key)].children[findIndex].children = updateProjectChild({ arr: children, projectId: id, dataTypePrefix: dataTypePrefix, ddrType, version });
  // Update project channel list in memu
  yield put(projectMenu({ treeItems: [..._treeItems], packageLayoutList: packages }));

  // Update verification
  let resChannels = isUpload ? [] : res.channels;
  yield* res.channels.map(function* (channel, index) {
    const _channelId = channel.id;
    let verifications;

    try {
      let _verifications = [];
      if (version !== PROJECT_V2) {
        verifications = yield call(getRockyVerificationsInChannel, _channelId);
        _verifications = (verifications && verifications.rockyVerifications) || [];
        if (!isPreLayout) {
          yield put(updateChannelByVersion({ designVersion: designs[0].designVersion, verifications }));
        }

        resChannels[index].verifications = _verifications;
        // verification - { channelId, channelName, children dataType, designId, designSubId, id, interfaces, key, name, nodeClass, readyForSim, rockyPdnId, status, subId, type, version, designVersion }
        // channels - [{ children: [verification], dataType: 'channel', designId, id, key, name, nodeClass }]
        // const findIsSSN = _verifications.find(item => ['Data', 'CA'].includes(item.name))
        channels = getChannelTree(resChannels, dataTypePrefix, isPreLayout);
      } else {
        channels = getSSNChannelTree(resChannels, dataTypePrefix)
      }

      // Update verifications in one channel
      const _children = DIMM_DDR_TYPES.includes(ddrType) ? [designs, packages, cards, channels] : [designs, packages, channels];
      _treeItems[getTreeIndex(key)].children[findIndex].children = updateProjectChild({ arr: _children, projectId: id, dataTypePrefix: dataTypePrefix, ddrType, version });
      yield put(projectMenu({ treeItems: [..._treeItems] }));
    } catch (error) {
      message.error(`Cannot get verifications in ${channel.name}! ` + error)
    }
  });
  //save current project all verification list { verificationId : channelId }
  VerificationInfo.saveVerificationInfo(channels);
  let _channels = [];
  if (version === PROJECT_V2) {
    for (let channelInfo of channels) {
      const verifications = yield call(RockySSNChannelInfo.getVerifications, channelInfo.id)
      if (verifications && verifications.length) {
        _channels.push({ ...channelInfo, children: verifications })
      } else {
        _channels.push({ ...channelInfo, children: [] })
      }
    }
  } else {
    _channels = [...channels]
  }

  projectChannels.set(id, channels.map(d => d.id));
  _channels.forEach(d => {
    // rockyChannels.set(item.id, { ...item, children: verifications, statusObj: { pcbChannelStatus: findC.pcbChannelStatus, ssnMergeStatus: findC.ssnMergeStatus, ssnChannelStatus: findC.ssnChannelStatus } });
    rockyChannels.set(d.id, d);
    channelVerifications.setChannel(d.id, d.children);
  });

  // Update verification
  let resCardChannels = res.cardChannels || [];
  yield* (res.cardChannels || []).map(function* (channel, index) {
    const _channelId = channel.id;
    let verifications;

    try {
      verifications = yield call(getRockyVerificationsInChannel, _channelId);

      resCardChannels[index].verifications = (verifications && verifications.rockyVerifications) || [];
      // verification - { channelId, channelName, children dataType, designId, designSubId, id, interfaces, key, name, nodeClass, readyForSim, rockyPdnId, status, subId, type, version, designVersion }
      // channels - [{ children: [verification], dataType: 'channel', designId, id, key, name, nodeClass }]
      cards = res.cards && res.cards.length ? getCardTree(res.cards, dataTypePrefix, resCardChannels) : [];
      //set channels and verifications to cache
      const itemChannel = new channelItem({
        id: channel.id,
        name: channel.name,
        designId: channel.designId,
        dataTypePrefix,
        cardType: "card"
      });
      cardChannelsConstructor.setChannelInfo(channel.id, itemChannel);
      cardChannelsConstructor.setChannelVerifications(channel.id,
        resCardChannels[index].verifications.map(ve => new verificationItem({
          ...ve,
          channelName: channel.name,
          channelId: channel.id,
          type: ve.typeName,
          dataTypePrefix,
          cardType: "card"
        })));

      // Update verifications in one channel
      const _children = DIMM_DDR_TYPES.includes(ddrType) ? [designs, packages, cards, channels] : [designs, packages, channels];
      _treeItems[getTreeIndex(key)].children[findIndex].children = updateProjectChild({ arr: _children, projectId: id, dataTypePrefix: dataTypePrefix, ddrType, version });
      yield put(projectMenu({ treeItems: [..._treeItems] }));
    } catch (error) {
      message.error(`Cannot get verifications in ${channel.name}! ` + error)
    }
  });

  yield* packages.map(function* (packageInfo, index) {
    // to do  get upload pcb and package log
    try {
      if (packageInfo.dataType !== PACKAGE_PRE_LAYOUT) {

        const packageVerificationList = yield call(getPackageVerificationListPromise, packageInfo.id)
        let packagePDN = null;
        if (version === PROJECT_V2) {
          packagePDN = yield call(getRockyPackagePDN, packageInfo.id);
          packageVerificationConstructor.addPackagePDN(packageInfo.id, packagePDN)
        }

        const packageChildren = _treeItems[getTreeIndex(key)].children[findIndex].children[PACKAGE_INDEX].children[index].children;

        if (version === PROJECT_V2) {
          let list = []
          for (let packageChannel of packageVerificationList) {
            if (packageChannel && packageChannel.channels) {
              list = [...list, ...packageChannel.channels]
            }
          }

          packageVerificationConstructor.addPackageVerifications(packageInfo.id, list, packagePDN)
          _treeItems[getTreeIndex(key)].children[findIndex].children[PACKAGE_INDEX].children[index].children = getSSNPackagesTree(list, packagePDN, packageChildren, false)
        } else {
          // list = [...packageVerificationList];
          packageVerificationConstructor.addPackageVerifications(packageInfo.id, packageVerificationList, packagePDN)
          _treeItems[getTreeIndex(key)].children[findIndex].children[PACKAGE_INDEX].children[index].children = getPackagesTree(packageVerificationList, packagePDN, packageInfo.id, packageChildren, false, true)
        }

        yield put(projectMenu({ treeItems: [..._treeItems] }));
      } else if (version === PROJECT_V2) {
        const packageVerificationList = yield call(getPackageVerificationListPromise, packageInfo.id)
        let list = []
        for (let packageChannel of packageVerificationList) {
          if (packageChannel && packageChannel.channels) {
            list = [...list, ...packageChannel.channels]
          }
        }
        packageVerificationConstructor.addPackageVerifications(packageInfo.id, list)
      }
    } catch (error) {
      message.error(`Cannot get packages in ${packageInfo.name}` + error)
    }
  })

  // monitor box info
  let info = {
    projectName: name,
    currentProjectId: id
  }

  if (visible && uploadProjectId === id) {
    info.menuType = 'upload';
    yield put(openTabFooter());
  }
  //update monitor box info
  yield put(changeTabMenu(info));
  //yield put(changeVerificationList([]));

  yield put(saveOpenProjectInfo({ id, name, version }));
  if (version === PROJECT_BMA && isCreate) {
    yield put(openPage({ pageType: BMA_CHANNEL, id: id }));
    yield put(updateViewList([BMA_CHANNEL]));
    yield put(updateTreeSelectedKeys([`${PROJECT}-${id}`]));

  }

  //get Current project pcb interfaces.json
  if (channels.length > 0) {
    const currentProjectInterfaceInfo = yield call(getPCBInterfaces, { id, channels });
    //currentProjectInterfaceInfo:{ projectId:id, channelId1:{ PowerNets:[], GroundNets,Name, PowerComponents, VTTNets,DDR, ... }, channelId2:{...} }
    if (currentProjectInterfaceInfo && currentProjectInterfaceInfo.id === id) {
      yield put(updateProjectInterfacesInfo(currentProjectInterfaceInfo));
    }
  }

  //open channel
  if (channelId) {
    let findChannel = channels.find(item => item.id === channelId);
    if (findChannel) {
      // Open channel Page, not the result page
      yield call(openChannelByID, { channelId, verificationId, load, isResultPage, key });
    };
  } else {
    // Refresh the project, channel list (Switching product)
    yield* expandedKeys.map(function* (item) {
      const [key, subId] = strDelimited(item, "-");
      if (key === CHANNEL) {
        let channelFind = channels.find(channel => channel.id === subId);
        if (channelFind) {
          let currentChannelId = channelFind.id;
          yield put(openChannel(currentChannelId, key));
        }
      }
    })
    yield call(updateMonitorTitle)
  }
};


function* updateMonitorTitle() {
  let { RockyReducer: { project, result: { resultInfo } } } = yield select();
  let { selectedKeys, viewList } = project;
  const view = viewList.find(item => [SSN_VERIFICATION, VERIFICATION, PACKAGE_VERIFICATION, CARD_VERIFICATION, PCB_CHANNEL, PCB_PDN, PACKAGE_PDN, SSN_RESULT].includes(item))
  const keyValue = view === SSN_VERIFICATION ? SSN_CHANNEL : view;
  const findSelected = selectedKeys.find(item => { const [key, id] = item.split("-"); return key === keyValue ? item : null })
  if (!findSelected) {
    return;
  }
  const [key, id] = findSelected.split("-");
  if (!id) {
    return;
  }
  switch (view) {
    case SSN_VERIFICATION:
      const ssnCurrent = rockyChannels.get(id);
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        channelName: ssnCurrent ? ssnCurrent.name : null,
        currentChannelId: ssnCurrent ? ssnCurrent.id : null,
        verificationName: ssnCurrent ? ssnCurrent.name : null,
        currentVerificationId: ssnCurrent ? ssnCurrent.verificationId : null,
        dataType: SINGLE_PATTERN
      }))
      break;
    case SSN_RESULT:
      if (resultInfo && resultInfo.channelId === id && resultInfo.verificationName) {
        yield put(changeTabMenu({
          menuType: "simulation",
          tabSelectKeys: ['monitor'],
          channelName: resultInfo ? resultInfo.channelName : null,
          currentChannelId: resultInfo ? resultInfo.channelId : null,
          verificationName: resultInfo ? resultInfo.verificationName : null,
          currentVerificationId: resultInfo ? resultInfo.verificationId : null,
          dataType: SSN_RESULT
        }))
        yield put(getInterfaceMonitor(resultInfo.verificationId, null, true));
      } else {
        let dataList = yield call(RockySSNChannelInfo.getVerifications, id);
        if (dataList && dataList.length) {
          const findInfo = dataList[0];
          yield put(changeTabMenu({
            menuType: "simulation",
            tabSelectKeys: ['monitor'],
            verificationName: findInfo ? findInfo.name : null,
            currentVerificationId: findInfo.id,
            dataType: SSN_RESULT
          }))
          yield put(getInterfaceMonitor(findInfo.id, null, true));
        }
      }
      break;
    case VERIFICATION:
      const channelId = VerificationInfo.getVerificationInfo(id);
      const _verifications = channelVerifications.getVerificationsByChannelId(channelId) || [];
      const findV = _verifications.find(item => item.id === id);
      if (!findV) {
        return;
      }
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: findV ? findV.name : null,
        currentVerificationId: id,
        channelName: findV ? findV.channelName : null,
        currentChannelId: findV ? findV.channelId : null,
        dataType: VERIFICATION
      }))
      break;
    case CARD_VERIFICATION:
      const findCardV = cardChannelsConstructor.getVerification(id);
      if (!findCardV) {
        return;
      }
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: findCardV ? findCardV.name : null,
        currentVerificationId: id,
        channelName: findCardV ? findCardV.channelName : null,
        currentChannelId: findCardV ? findCardV.channelId : null,
        dataType: view
      }))
      break;
    case PACKAGE_VERIFICATION:
      const packageV = packageVerificationConstructor.getPackageVerification(id);
      if (!packageV) {
        return
      }
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: packageV ? packageV.name : null,
        currentVerificationId: packageV.verificationId,
        channelName: packageV ? packageV.channelName : null,
        currentChannelId: packageV ? packageV.channelName : null,
        dataType: view
      }))
      break;
    case PACKAGE_PDN:
      const packagePDN = packageVerificationConstructor.getPackageVerification(id);
      if (!packagePDN) {
        return
      }
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: packagePDN ? packagePDN.name : null,
        currentVerificationId: packagePDN.verificationId,
        channelName: packagePDN ? packagePDN.channelName : null,
        currentChannelId: packagePDN ? packagePDN.channelName : null,
        dataType: view
      }))
      break;
    case PCB_CHANNEL:
      const pcbChannelItem = pcbChannelConstructor.get(id)
      if (!pcbChannelItem) {
        return
      }
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: pcbChannelItem ? pcbChannelItem.name : null,
        currentVerificationId: pcbChannelItem ? pcbChannelItem.pcbChannelVerificationId : null,
        channelName: pcbChannelItem ? pcbChannelItem.name : null,
        currentChannelId: pcbChannelItem ? pcbChannelItem.id : null,
        dataType: view
      }));
      break;
    case PCB_PDN:
      yield put(openTabFooter());
      const findPCBPDN = pcbChannelConstructor.get(id);
      if (!findPCBPDN) {
        return
      }
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: findPCBPDN ? findPCBPDN.name : null,
        currentVerificationId: findPCBPDN ? findPCBPDN.verificationId : null,
        channelName: findPCBPDN ? findPCBPDN.name : null,
        currentChannelId: findPCBPDN ? findPCBPDN.verificationId : null,
        dataType: view
      }))
      break;
    default: break;

  }


}

function* getChannelList({ treeItems, projectId, channelId, packageId, projectVersion, isUpdateDiagram }) {
  let _treeItems = [...treeItems], allSimulationChannelIds = [];

  const projectIndex = _treeItems[PROJECT_INDEX].children.findIndex(item => item.id === projectId)

  if (!projectIndex < 0) {
    return allSimulationChannelIds
  }

  try {
    // tree current project channel and package channel list
    const _project = _treeItems[PROJECT_INDEX].children[projectIndex];
    const channel_index = DIMM_DDR_TYPES.includes(_project.type) ? 3 : 2;

    const channels = _treeItems[PROJECT_INDEX].children[projectIndex].children[channel_index].children;
    const isPreLayout = projectDesigns.getAvailableDesignsVendor(projectId) === PRE_LAYOUT ? true : false;
    const findChannelIndex = channels.findIndex(item => item.id === channelId)

    const packages = treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children;
    const findPackageIndex = packages.findIndex(item => item.id === packageId)

    const designs = treeItems[PROJECT_INDEX].children[projectIndex].children[DESIGN_INDEX].children || [];
    const findDesignIndex = designs.findIndex(item => item.category === DESIGN_SUCCESS)

    if (channels && channels.length) {
      // channel status
      let _verifications = [];
      if (projectVersion !== PROJECT_V2 && findChannelIndex > -1) {
        const verifications = yield call(getRockyVerificationsInChannel, channelId)
        _verifications = (verifications && verifications.rockyVerifications) || [];
        const updatedChannelItem = updateChannelItem(_verifications, channels[findChannelIndex], null, isPreLayout);
        // TODO _treeItems[]
        _treeItems[PROJECT_INDEX].children[projectIndex].children[channel_index].children[findChannelIndex] = updatedChannelItem;

        // update current channel info
        rockyChannels.set(channelId, updatedChannelItem);
        channelVerifications.setChannel(channelId, updatedChannelItem.children);
        yield put(saveCurrentChannelInfo({ currentChannelId: channelId }));

        const simVerifications = _verifications.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status))
        allSimulationChannelIds = [...allSimulationChannelIds, ...simVerifications]
      }
    }

    if (projectVersion === PROJECT_V2 && designs && findDesignIndex > -1 && designs[findDesignIndex]) {
      try {
        //update pcb channel and ssn channel
        const res = yield call(getRockyProjectPromise, projectId);
        const resChannels = res.channels || [];
        //ssn channels
        for (let item of channels) {
          const findC = resChannels.find(it => it.ssnChannelVerificationId === item.verificationId);
          if (findC) {
            item.status = findC.ssnChannelStatus;
            if ([VERIFY_RUNNING, WAITING].includes(findC.ssnMergeStatus)) {
              item.status = findC.ssnMergeStatus;
              allSimulationChannelIds.push(findC.ssnChannelVerificationId)
            }

            if ([VERIFY_RUNNING, WAITING].includes(findC.ssnChannelStatus)) {
              allSimulationChannelIds.push(findC.ssnChannelVerificationId)
            }
          }
          const verifications = yield call(RockySSNChannelInfo.getVerifications, item.id, isUpdateDiagram);
          rockyChannels.set(item.id, { ...item, children: verifications, statusObj: { pcbChannelStatus: findC.pcbChannelStatus, ssnMergeStatus: findC.ssnMergeStatus, ssnChannelStatus: findC.ssnChannelStatus } });
          channelVerifications.setChannel(item.id, verifications);
          item.simStatus = null
        }
        _treeItems[PROJECT_INDEX].children[projectIndex].children[channel_index].children = channels;

        if (designs[findDesignIndex].vendor !== PRE_LAYOUT) {
          const pdn = yield call(getRockyPackagePDN, designs[findDesignIndex].id);
          designs[findDesignIndex].children.forEach(item => {
            if (item.dataType === PCB_PDN && item.id === pdn.id) {
              if ([VERIFY_RUNNING, WAITING].includes(pdn.status)) {
                allSimulationChannelIds.push(pdn.id)
              }
              item.status = pdn.status
              item.simStatus = null
            }
            if (item.dataType === PCB_CHANNEL) {
              const findC = resChannels.find(it => it.pcbChannelVerificationId === item.verificationId);
              if (findC) {
                item.status = findC.pcbChannelStatus
                if ([VERIFY_RUNNING, WAITING].includes(findC.pcbChannelStatus)) {
                  allSimulationChannelIds.push(findC.pcbChannelVerificationId)
                }
              }
              item.simStatus = null
            }
          })
        }
        treeItems[PROJECT_INDEX].children[projectIndex].children[DESIGN_INDEX].children = designs;
      } catch (error) {
        console.error(error)
      }
    }

    if (findPackageIndex > -1 && packages[findPackageIndex].dataType !== PACKAGE_PRE_LAYOUT) {
      try {
        // package status
        const packageVerificationList = yield call(getPackageVerificationListPromise, packageId)
        let packagePDN = null
        if (projectVersion === PROJECT_V2) {
          packagePDN = yield call(getRockyPackagePDN, packageId);
          packageVerificationConstructor.addPackagePDN(packageId, packagePDN)
        }

        const packageChildren = _treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children[findPackageIndex].children || [];

        if (projectVersion === PROJECT_V2) {
          let list = [];
          for (let packageChannel of packageVerificationList) {
            if (packageChannel && packageChannel.channels) {
              list = [...list, ...packageChannel.channels]
            }
          }
          packageVerificationConstructor.addPackageVerifications(packageId, list, packagePDN)
          if (packagePDN && [VERIFY_RUNNING, WAITING].includes(packagePDN.status)) {
            allSimulationChannelIds.push(packagePDN.id)
          }
          _treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children[findPackageIndex].children = getSSNPackagesTree(list, packagePDN, packageChildren, true)
        } else {
          packageVerificationConstructor.addPackageVerifications(packageId, packageVerificationList, packagePDN)
          _treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children[findPackageIndex].children = getPackagesTree(packageVerificationList, packagePDN, packageId, packageChildren, true, true)
        }

        yield put(projectMenu({ treeItems: [..._treeItems] }));

        for (let packageChannel of packageVerificationList) {
          const { channels: packageChannels } = packageChannel;
          let newSimVerifications = packageChannels.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status))
          allSimulationChannelIds = [...allSimulationChannelIds, ...newSimVerifications]
        }
      } catch (error) {
        console.error(error)
      }
    } else if (findPackageIndex > -1 && projectVersion === PROJECT_V2) {
      const packageVerificationList = yield call(getPackageVerificationListPromise, packageId)
      let list = [];
      for (let packageChannel of packageVerificationList) {
        if (packageChannel && packageChannel.channels) {
          list = [...list, ...packageChannel.channels]
        }
      }
      packageVerificationConstructor.addPackageVerifications(packageId, list)
    }
  } catch (error) {
    console.error(error)
  }

  yield put(projectMenu({ treeItems: [..._treeItems] }));
  return allSimulationChannelIds;
}

// Update verification status
function* autoGetVerifications(action) {
  if (isRockyPuppeteer()) return;
  const { projectId, channelId, currentSimInfo, currentPkgDesignId, verificationType, isUpdateDiagram } = action;
  let delayTime = 60000;

  const { RockyReducer: { project: { currentPackageDesignId, projectVersion, contentType } } } = yield select();
  // currentSimInfo - change simulation status, from checking -> error / success
  const _currentPackageDesignId = currentPkgDesignId ? currentPkgDesignId : currentPackageDesignId;
  if (!currentSimInfo) {
    let first = true;
    let isWhile = true;
    while (isWhile) {
      const { LoginReducer: { pageType } } = yield select();
      if (pageType !== ROCKY) {
        return;
      };
      if (!first) {
        yield delay(delayTime);//4000
      }
      first = false;
      const { RockyReducer: { project: { treeItems, projectVersion } } } = yield select();
      // let _treeItems = [...treeItems];

      const _isCurrentChannel = yield call(isCurrentChannel, projectId, channelId, _currentPackageDesignId);
      if (!_isCurrentChannel) {
        return;
      }

      const _allSimChannelIds = yield call(getChannelList, { treeItems, projectId, channelId, packageId: _currentPackageDesignId, projectVersion, isUpdateDiagram });
      if (_allSimChannelIds && _allSimChannelIds.length) {
        delayTime = 5000;
      } else {
        isWhile = false;
      }
    }
  } else {
    // update once
    const { RockyReducer: { project } } = yield select();
    let { treeItems } = project;

    let _treeItems = [...treeItems];
    // treeItems[1] - 'projects'
    const findIndex = _treeItems[PROJECT_INDEX].children.findIndex(item => item.id === projectId);
    if (findIndex > -1) {

      const designs = treeItems[PROJECT_INDEX].children[findIndex].children[DESIGN_INDEX].children || [];
      const findDesignIndex = designs.findIndex(item => item.category === DESIGN_SUCCESS)
      // tree channel list
      if (verificationType === PACKAGE && projectVersion === PROJECT_V2 && designs && findDesignIndex > -1 && designs[findDesignIndex] && designs[findDesignIndex].vendor !== PRE_LAYOUT) {
        try {
          /* const res = yield call(getRockyProjectPromise, projectId); */
          const pdn = yield call(getRockyPackagePDN, designs[findDesignIndex].id);
          designs[findDesignIndex].children.forEach(item => {
            if (item.dataType === PCB_PDN && item.id === pdn.id) {
              const index = currentSimInfo.findIndex(i => i.verificationId === item.verificationId);
              if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo[index].status !== item.simStatus))) {
                item.simStatus = currentSimInfo[index].status;
              }
            }
          })
          treeItems[PROJECT_INDEX].children[findIndex].children[DESIGN_INDEX].children = designs;
        } catch (error) {
          console.error(error)
        }
      }

      if (verificationType === PACKAGE) {
        let channels = (_treeItems[PROJECT_INDEX].children[findIndex].children[PACKAGE_INDEX].children.find(item => item.id === _currentPackageDesignId) || {}).children || [];
        let Index = _treeItems[PROJECT_INDEX].children[findIndex].children[PACKAGE_INDEX].children.findIndex(item => item.id === _currentPackageDesignId)
        if (channels.length && Index > -1) {
          if (projectVersion === PROJECT_V2) {
            channels.forEach(channel => {
              const index = currentSimInfo.findIndex(i => i.verificationId === channel.verificationId);
              if (index > -1 && (!channel.simStatus || (channel.simStatus && currentSimInfo[index].status !== channel.simStatus))) {
                channel.simStatus = currentSimInfo[index].status;
              }
            })
          } else {
            channels.forEach(channel => {
              if (channel.children && channel.children.length) {
                channel.children.forEach(item => {
                  const index = currentSimInfo.findIndex(i => i.verificationId === item.id);
                  if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo[index].status !== item.simStatus))) {
                    item.simStatus = currentSimInfo[index].status;
                  }
                })
              }
            })
          }

          _treeItems[PROJECT_INDEX].children[findIndex].children[PACKAGE_INDEX].children[Index].children = channels;
          yield put(projectMenu({ treeItems: [..._treeItems] }));
          // const verifications
        }
      } else {
        let channels = (_treeItems[PROJECT_INDEX].children[findIndex].children.find(item => item.name === 'Interface') || {}).children || [];
        // // tree Interface index
        let Index = _treeItems[PROJECT_INDEX].children[findIndex].children.findIndex(item => item.name === "Interface") || -1;
        if (channels && channels.length && Index > -1) {
          if (projectVersion === PROJECT_V2) {
            for (let item of channels) {
              const index = currentSimInfo.findIndex(i => i.verificationId === item.verificationId);
              if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo[index].status !== item.simStatus))) {
                item.simStatus = currentSimInfo[index].status
              }
            }
            _treeItems[PROJECT_INDEX].children[findIndex].children[Index].children = channels;
          } else {
            let channelIndex = channels.findIndex(item => item.id === channelId);
            // tree current channel verifications
            if (channelIndex > -1) {
              let verifications = channelIndex > -1 ? channels[channelIndex].children || [] : [];
              verifications.forEach(item => {
                const index = currentSimInfo.findIndex(i => i.verificationId === item.id);
                if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo[index].status !== item.simStatus))) {
                  item.simStatus = currentSimInfo[index].status;
                }
              });
              _treeItems[PROJECT_INDEX].children[findIndex].children[Index].children[channelIndex].children = verifications;
            }

          }
        }
        yield put(projectMenu({ treeItems: [..._treeItems] }));
      }
    };
  }
}

export function* getVerificationsByChannel({ channelIds, projectId, key = PROJECT }) {
  const { RockyReducer: { project: { treeItems, projectVersion } } } = yield select();
  let _treeItems = [...treeItems];
  const findIndex = _treeItems[getTreeIndex(key)].children.findIndex(item => item.id === projectId);
  if (findIndex < 0) {
    return;
  }
  const _project = _treeItems[getTreeIndex(key)].children[findIndex];
  const channel_index = DIMM_DDR_TYPES.includes(_project.type) ? 3 : 2;
  const channels = _treeItems[getTreeIndex(key)].children[findIndex].children[channel_index].children;
  yield* channelIds.map(function* (id, index) {
    let verification;

    const channelIndex = channels.findIndex(item => item.id === id);
    if (channelIndex < 0) {
      return;
    }
    try {
      let verifications = [];
      if (projectVersion !== PROJECT_V2) {
        verification = yield call(getRockyVerificationsInChannel, id)
        verifications = (verification && verification.rockyVerifications) || [];
        // verification - { channelId, channelName, childrendataType, designId, designSubId, id, interfaces, key, name, nodeClass, readyForSim, rockyPdnId, status, subId, type, version }
        // Update verifications in one channel
        channels[channelIndex].children = getVerificationTree(verifications, channels[channelIndex], channels[channelIndex].dataTypePrefix);
      }
      yield put(projectMenu({ treeItems: [..._treeItems] }));
    } catch (error) {
      message.error(`Cannot get verifications in ${channels[channelIndex].name}! ` + error)
    }
  });
  //save current project all verification list { verificationId : channelId }
  VerificationInfo.saveVerificationInfo(channels);
}

function* openChannelByID(action) {
  const { channelId, load, isResultPage, key } = action;
  const { RockyReducer: { project } } = yield select();
  let { expandedKeys, currentProjectId, projectList, projectVersion } = project;
  if (key && key !== PROJECT) {
    expandedKeys = [...new Set([...expandedKeys, `${key}-${channelId}`])];
  }
  yield put(expandMenu(expandedKeys));
  // clean result page information
  if (!isResultPage) {
    yield put(cleanResultPageInfo());
  }
  //cancel create pdn
  if (!load && createPDNTask) {
    yield cancel(createPDNTask);
  }

  if (!load) {
    yield put(updateExtractionConfig({}, false));
  }

  const name = rockyChannels.get(channelId) ? rockyChannels.get(channelId).name : null;
  const channels = projectChannels.get(currentProjectId).map(id => rockyChannels.get(id));
  const verifications = channelVerifications.getVerificationsByChannelId(channelId) || [];
  //get channel dat to get components list
  const ChannelInfo = yield call(getChannelComponents, { projectId: currentProjectId, channelId, channelList: channels });

  const designVersion = projectDesigns.getAvailableDesigns(currentProjectId)[0].designVersion;
  const { componentList } = ChannelInfo;

  if (projectVersion === PROJECT_V1) {
    // init pdn
    yield fork(initPDN, { verifications, channelId, componentList, designVersion });
  }

  if (Array.isArray(componentList) && componentList.length > 0) {
    yield put(updateChannelComponentList(componentList));
  }
  // save channelVerifications
  yield put(saveCurrentChannelInfo({ currentChannelId: channelId }));

  /*  const findVerification = verifications.find(item => item.id === verificationId); */

  /*  if (findVerification && (selectedKeys.includes(`${VERIFICATION}-${verificationId}`) || selectedKeys.includes(`${RESULT}-${verificationId}`))) {
     if (selectedKeys.includes(`${VERIFICATION}-${verificationId}`)) {
       yield put(openTabFooter());
     }
     yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], currentChannelId: channelId, channelName: name, currentVerificationId: verificationId, verificationName: findVerification.name }));
     yield put(getInterfaceMonitor(verificationId));
   } else {
     yield put(changeTabMenu({ currentChannelId: channelId, channelName: name }));
   } */
  yield put(changeTabMenu({ currentChannelId: channelId, channelName: name }));
  yield call(updateMonitorTitle)

  if (!load) {
    //GET channel config (simulation config and result config)
    //get channel config
    try {
      // new   Config:{id:'', config:{ config:{}, resultConfig:{}, extraction: {} }}
      // prev  Config:{id:'', config:{ clock:'' , timeStep:'',corners:[], simulate:'' }}
      let Config = yield call(getChannelConfig, channelId);
      let _currentConfig = {}, _resultConfig = {}, _extraction = {}, isDefault = false;
      if (Config.config.config && Object.keys(Config.config.config).length) {
        // Config:{id:'', config:{ config:{}, resultConfig:{}, extraction: {} }}
        _currentConfig = new ConfigInfo({ ...Config.config.config, isSev: projectVersion === PROJECT_V2 ? true : false });
      } else if (Config.config.timeStep) {
        // Config:{id:'', config:{ clock:'' , timeStep:'',corners:[], simulate:'' }}
        _currentConfig = new ConfigInfo({ ...Config.config, isSev: projectVersion === PROJECT_V2 ? true : false });
      } else {
        isDefault = true;
        yield put(defaultChannelConfig(channelId, componentList));
      }

      const _config = changeConfigFormat({ ..._currentConfig });

      yield put(updateCurrentConfig(_config));
      if (Config.config.resultConfig) {
        _resultConfig = { ...Config.config.resultConfig };
        yield put(updateResultConfig(_resultConfig));
      } else if (!isDefault) {
        yield delay(500);
        yield put(defaultResultConfig());
      }
      if (Config.config.extraction) {
        _extraction = judgeExtraction(Config.config.extraction);

        let save = false;
        //V0.0.3 Add new extraction config option, portLimit default 150, [30,300] must be integer
        if (_extraction.extractionConfig && !_extraction.extractionConfig.portLimit) {
          _extraction.extractionConfig.portLimit = "150";
          save = true;
        }

        const keys = Object.keys(_extraction.extractionConfig);
        if (!keys.includes("backdrillVias") || !keys.includes("backdrillStubSize")) {
          _extraction.extractionConfig.backdrillVias = false;
          _extraction.extractionConfig.backdrillStubSize = "8mil"
          save = true;
        }
        // Add siwave and hfss to extraction config,default is {}, support yml file selection
        if (_extraction.extractionConfig && !_extraction.extractionConfig.siwave) {
          _extraction.extractionConfig.siwave = {};
          save = true;
        }
        if (_extraction.extractionConfig && !_extraction.extractionConfig.hfss) {
          _extraction.extractionConfig.hfss = {};
          save = true;
        }
        if (save) {
          yield call(updateChannelExtractionConfig, channelId, _extraction);
        }

        //true: Whether to allow opening of the extraction panel
        yield put(updateExtractionConfig(_extraction, true));
      } else if (!isDefault) {
        yield delay(500);
        let xTalk = '1';
        if (_currentConfig && _currentConfig.xTalk !== undefined) {
          xTalk = _currentConfig.xTalk;
        }
        yield put(defaultExtractionConfig(channelId, xTalk));
      }

      if (Config.config.waveformConfig && Object.keys(Config.config.waveformConfig).length) {
        yield put(updateWaveformConfig(Config.config.waveformConfig));
      } else if (!isDefault) {
        yield delay(500);
        const currentProject = projectList.find(item => item.id === currentProjectId);
        let ddrType = currentProject ? currentProject.type : "";
        const isPrevCase = Config.config.config && Object.keys(Config.config.config).length;
        yield put(defaultWaveformConfig(channelId, ddrType, isPrevCase));
      }
    } catch (error) {
      yield put(defaultChannelConfig(channelId, componentList));
    }
  }

  yield put(autoGetVerificationList({ projectId: project.currentProjectId, channelId }));
}

function* initPDN(action) {
  const { verifications, channelId, componentList, designVersion } = action;
  let { RockyReducer: { project: { currentProjectId } } } = yield select();
  const isPreLayout = projectDesigns.getAvailableDesignsVendor(currentProjectId) === PRE_LAYOUT ? true : false;

  let findPDN = verifications.find(item => item.type === ROCKY_PDN);
  let findSSN = verifications.find(item => ['Data', 'CA'].includes(item.name));
  if (!isPreLayout && !findSSN) {
    //create pdn
    if (!findPDN || !findPDN.id) {
      createPDNTask = yield call(_createChannelPDN, { channelId, componentList });
    }

    if (findPDN && (findPDN.designVersion !== designVersion)) {
      yield call(updateChannelPDN, { findPDN, channelId, componentList, designVersion })
    }
  }
}

export function* getLoadingDBStatus({ pcbId }) {
  let { RockyReducer: { project: { currentLoadingPCBs = [] } } } = yield select();
  if (currentLoadingPCBs.includes(pcbId)) {
    return true;
  } else {
    yield put(updateCurrentLoadingPCBs(currentLoadingPCBs.filter(item => item !== pcbId)))
  }
  return false;
}

function* saveComponentsNets(action) {
  const { pcbIds } = action;
  let { RockyReducer: { project: { pcbComponentsNets } } } = yield select();
  const _filter = pcbIds.filter(id => !pcbComponentsNets.includes(id));
  yield* _filter.map(function* (id) {
    const design = designConstructor.getDesign(id)
    if (design && design.vendor !== PRE_LAYOUT) {
      pcbComponentsNets = yield call(_getLayoutDB, { pcbComponentsNets, id, save: true, fullLayers: false });

    }
  });
  yield put(updatePCBComponentsNets(pcbComponentsNets));
}

export function* _getLayoutDB({ id, pcbComponentsNets, save, fullLayers }) {
  try {
    let loadingPCB = yield call(getLoadingDBStatus, { pcbId: id });
    let index = 0
    while (loadingPCB) {
      yield delay(800);
      loadingPCB = yield call(getLoadingDBStatus, { pcbId: id });
      index += 1;
      if (index > 10) {
        break;
      }
    }
    let { RockyReducer: { project: { currentLoadingPCBs = [] } } } = yield select();
    yield put(updateCurrentLoadingPCBs([...currentLoadingPCBs, id]));
    const design = designConstructor.getDesign(id)
    if (design && design.vendor === PRE_LAYOUT) {
      return pcbComponentsNets;
    }
    fullLayers = design && design.dataType === PACKAGE ? true : fullLayers;
    yield call(getLayoutDB, id, fullLayers ? false : true);
    let { RockyReducer: { project: { currentLoadingPCBs: _currentLoadingPCBs } } } = yield select();
    yield put(updateCurrentLoadingPCBs(_currentLoadingPCBs.filter(item => item !== id)));
    if (save) {
      const _DesginData = LayoutData.getLayout(id);
      const info = {
        netsList: [..._DesginData.mNetManager.mNetList.mVals],
        layers: [..._DesginData.mLayerMgr.mMetalLayers]
      };
      pcbComponentsNets.push(id);
      DesignInfo.savePCBInfo(id, info);
    }
  } catch (error) {
    console.error(error)
    yield put(debugMonitorAction([timeFormat() + ' [Error] Failed to parse PCB. ' + error]));
    let { RockyReducer: { project: { currentLoadingPCBs = [] } } } = yield select();
    yield put(updateCurrentLoadingPCBs(currentLoadingPCBs.filter(item => item !== id)));
  };
  return pcbComponentsNets;
}

function* isCurrentChannel(projectId, channelId, packageDesignId) {
  const { RockyReducer } = yield select();
  const currentProjectId = RockyReducer.project.currentProjectId;
  const currentChannelId = RockyReducer.project.currentChannelId;
  const currentPackageDesignId = RockyReducer.project.currentPackageDesignId;
  const projectVersion = RockyReducer.project.projectVersion;
  // If current project/ current channel !== project/channel, stop polling
  if (!currentProjectId || projectId !== currentProjectId
    || (projectVersion === PROJECT_V1 &&
      ((!currentChannelId || channelId !== currentChannelId) && (!currentPackageDesignId || packageDesignId !== currentPackageDesignId)))) {
    // if (!currentProjectId || !currentChannelId || projectId !== currentProjectId || channelId !== currentChannelId) {
    return false;
  };
  return true;
};

// Open page after change view layout
function* changeViewList(action) {
  if (!action.viewType) {
    return;
  }
  const { viewType } = action;

  const { RockyReducer: { project, card: { cardChannelId, cardInfo: { id: cardVerificationId } } } } = yield select();
  const { openVerificationId, selectedKeys, projectList, currentProjectId, expandedKeys, currentChannelId, currentPackageDesignId, projectVersion } = project;
  const channelIds = projectChannels.get(currentProjectId);
  let PDNID = null, contentType = null;

  let verifications = channelVerifications.getVerificationsByChannelId(currentChannelId) || [];
  const findP = projectList.find(item => item.id === currentProjectId);
  let newKeys = [...selectedKeys], _expandedKeys = [...expandedKeys];;
  switch (viewType) {
    case PCB:
    case PCB_PRE_LAYOUT:
      //expand tree pcb
      if (!expandedKeys.includes(`${PCBS}-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `${PCBS}-${currentProjectId}`]));
      };
      const designIDs = projectDesigns.getAvailableDesignIds(currentProjectId)
      if (!designIDs.length) return;
      if (designIDs.length > 0) {
        newKeys.push(`${viewType}-${designIDs[0]}`);
        yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      }
      return;
    case VERIFICATION:
      let channelId = currentChannelId ? currentChannelId : null;
      if (!channelId && channelIds.length > 0) {
        channelId = channelIds[0];
      }

      //expand interface tree
      if (!expandedKeys.includes(`${CHANNELS}-${currentProjectId}`)) {
        _expandedKeys.push(`${CHANNELS}-${currentProjectId}`)
      }
      if (!currentChannelId && channelIds.length > 0) {
        //expand channel
        if (!expandedKeys.includes(`${CHANNEL}-${channelIds[0]}`)) {
          _expandedKeys.push(`${CHANNEL}-${channelIds[0]}`)
        }
      }
      yield put(expandMenu([..._expandedKeys]));

      let verificationId, verificationName;
      if (!verifications.length) {
        verifications = channelVerifications.getVerificationsByChannelId(channelId) || [];
        if (!verifications.length) {
          return;
        }
      }

      let find = verifications.find(item => item.id === openVerificationId);

      if (find) {
        verificationId = openVerificationId;
        verificationName = find.name;
      } else {
        const filterVerification = verifications.filter(item => item.type !== ROCKY_PDN)
        find = filterVerification[0];
        verificationId = filterVerification[0].id;
        verificationName = filterVerification[0].name;
      }

      if (find && find.type === ROCKY_PDN) {
        PDNID = find.rockyPdnId;
        contentType = ROCKY_PDN;
      } else {
        contentType = VERIFICATION;
      }
      yield put(openPageInfo({
        verificationId,
        openVerificationSubId: find.subId,
        contentType,
        PDNID,
        currentChannelId: channelId
      }));
      yield put(openPage({ pageType: contentType, id: verificationId, PDNID }));
      // update result info
      yield put(openResultPageInfo({
        verificationId: openVerificationId,
        verificationSubId: find.subId,
        designSubId: find.designSubId,
        designID: find.designId,
        interfaces: find.interfaces,
        channelId: find.channelId,
        channelName: find.channelName,
        ddrType: findP.type
      }));
      let selectKey = contentType === ROCKY_PDN && PDNID ? `${VERIFICATION}-${verificationId}-${PDNID}` : `${VERIFICATION}-${verificationId}`
      newKeys.push(selectKey);
      yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      let tabInfo = {
        tabSelectKeys: ['monitor'],
        menuType: 'simulation',
        currentVerificationId: verificationId,
        currentProjectId,
        projectName: findP ? findP.name : null,
        verificationName,
        currentChannelId: channelId,
        channelName: rockyChannels.getName(channelId)
      };
      yield put(changeTabMenu(tabInfo));
      yield put(openTabFooter());
      return;
    case PACKAGE:
      const packageDesignId = currentPackageDesignId ? currentPackageDesignId : designConstructor.getAvailablePackageDesignValues(currentProjectId)[0]
      if (!packageDesignId) { return }
      newKeys.push(`${viewType}-${packageDesignId}`);
      yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      return;
    case PACKAGE_VERIFICATION:
      //expand interface tree
      /*   const _currentPackageDesignId = currentPackageDesignId ? currentPackageDesignId : null
        const packageKey = selectedKeys.find(item => item.match(PACKAGE_VERIFICATION)); */
      /*    if (!_currentPackageDesignId && packageKey) {
           const [key, id] = packageKey.split("-")
         } */
      let packageInfo = packageVerificationConstructor.getPackageInfo(currentPackageDesignId)
      let packageVerificationInfo = packageVerificationConstructor.getPackageVerifications(currentPackageDesignId, openVerificationId)
      let channelIndex = 0;
      if (!packageVerificationInfo || !Object.keys(packageVerificationInfo).length) {
        packageVerificationInfo = projectVersion === PROJECT_V2 ? packageInfo[0] : packageInfo[0].channels[0]
      } else {
        channelIndex = packageInfo.findIndex(item => item.groupName === packageVerificationInfo.channelName)
      }

      //expand interface tree
      let new__expandedKeys = [...expandedKeys];
      if (!expandedKeys.includes(`${PACKAGE}-${currentPackageDesignId}`)) {
        new__expandedKeys.push(`${PACKAGE}-${currentPackageDesignId}`)
      }
      if (!expandedKeys.includes(`${PACKAGE_CHANNEL}-${channelIndex}-${currentPackageDesignId}`)) {
        new__expandedKeys.push(`${PACKAGE_CHANNEL}-${channelIndex}-${currentPackageDesignId}`)
      }
      yield put(expandMenu([...new__expandedKeys]));

      let _selectKey = `${viewType}-${packageVerificationInfo.id}`
      newKeys.push(_selectKey);
      yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      yield put(openPageInfo({
        verificationId: packageVerificationInfo.id,
        contentType: PACKAGE_VERIFICATION,
      }));
      yield put(openPage({ pageType: PACKAGE_VERIFICATION, id: packageVerificationInfo.id }));
      return;
    case PACKAGE_PDN:
      let packagePDN = packageVerificationConstructor.getPackagePDN(currentPackageDesignId);
      if (!packagePDN || !packagePDN.id) {
        return;
      }

      //expand interface tree
      let new_expandedKeys = [...expandedKeys];
      if (!expandedKeys.includes(`${PACKAGE_PDN}-${packagePDN.id}`)) {
        new_expandedKeys.push(`${PACKAGE_PDN}-${packagePDN.id}`)
      }
      yield put(expandMenu([...new_expandedKeys]));

      let package_pdn_selectKey = `${viewType}-${packagePDN.id}`
      newKeys.push(package_pdn_selectKey);
      yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      yield put(openPageInfo({
        verificationId: packagePDN.id,
        contentType: PACKAGE_PDN,
      }));
      yield put(openPage({ pageType: PACKAGE_PDN, id: packagePDN.id }));
      return;
    case CARD:
      //expand tree cards
      if (!expandedKeys.includes(`${CARDS}-${currentProjectId}`)) {
        yield put(expandMenu([...expandedKeys, `${CARDS}-${currentProjectId}`]));
      };
      let cardDesignId = null;
      if (cardVerificationId) {
        const cardVerification = cardChannelsConstructor.getVerification(cardVerificationId) || {};
        cardDesignId = cardVerification.designId;
      }
      if (!cardDesignId && cardChannelId) {
        const cardChannel = cardChannelsConstructor.getChannel(cardChannelId) || {};
        cardDesignId = cardChannel.designId;
      }

      if (cardDesignId) {
        newKeys.push(`${viewType}-${cardDesignId}`);
        yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      }
      return;
    case CARD_VERIFICATION:
      let cardVerificationInfo = null;
      if (cardChannelId) {
        const cardVerifications = cardChannelsConstructor.getVerifications(cardChannelId) || [];
        cardVerificationInfo = cardVerifications[0] ? cardVerifications[0] : null;
      }
      if (!cardChannelId) {
        const pcbKey = selectedKeys.find(d => d.split("-")[0] && d.split("-")[0] === CARD);
        const [, _cardDesignId] = pcbKey.split("-");
        const cardChannels = cardChannelsConstructor.getChannels(_cardDesignId) || [];
        const _cardChannelId = cardChannels[0] ? cardChannels[0].id : null;
        const _cardVerifications = _cardChannelId ? cardChannelsConstructor.getVerifications(_cardChannelId) : [];
        cardVerificationInfo = _cardVerifications[0] ? _cardVerifications[0] : null;
        //expand card channel
        if (!expandedKeys.includes(`${CARD_CHANNEL}-${_cardChannelId}`)) {
          _expandedKeys.push(`${CARD_CHANNEL}-${_cardChannelId}`);
          yield put(expandMenu([..._expandedKeys]));
          yield put(openCardChannel(_cardChannelId));
        }
      }

      if (!cardVerificationInfo) {
        return;
      }
      if (!expandedKeys.includes(`${CARD}-${cardVerificationInfo.designId}`)) {
        _expandedKeys.push(`${CARD}-${cardVerificationInfo.designId}`);
        yield put(expandMenu([..._expandedKeys]));
      }
      yield put(openPage({
        pageType: CARD_VERIFICATION,
        id: cardVerificationInfo.id
      }));
      newKeys.push(`${CARD_VERIFICATION}-${cardVerificationInfo.id}`);
      yield put(updateTreeSelectedKeys([...new Set(newKeys)]));

      let cardTabInfo = {
        tabSelectKeys: ['monitor'],
        menuType: 'simulation',
        currentVerificationId: cardVerificationInfo.id,
        currentProjectId,
        projectName: cardVerificationInfo.name || null,
        verificationName: cardVerificationInfo.name,
        currentChannelId: cardChannelId,
        channelName: cardVerificationInfo.channelName
      };
      yield put(changeTabMenu(cardTabInfo));
      yield put(openTabFooter());
      return;
    case SSN_VERIFICATION:
      //expand interface tree
      let __expandedKeys = [...expandedKeys];
      if (!expandedKeys.includes(`${CHANNELS}-${currentProjectId}`)) {
        __expandedKeys.push(`${CHANNELS}-${currentProjectId}`)
      }
      yield put(expandMenu([...__expandedKeys]));
      let _channelId = currentChannelId && channelIds.find(item => item === currentChannelId) ? currentChannelId : channelIds[0]
      const currentInfo = rockyChannels.get(_channelId)
      let __selectKey = `${SSN_CHANNEL}-${_channelId}`
      newKeys.push(__selectKey);
      yield put(updateTreeSelectedKeys([...new Set(newKeys)]));
      yield put(openPage({ pageType: SSN_VERIFICATION, id: _channelId }));
      yield put(openTabFooter());
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        channelName: currentInfo ? currentInfo.name : null,
        currentChannelId: currentInfo ? currentInfo.id : null,
        verificationName: currentInfo ? currentInfo.name : null,
        currentVerificationId: currentInfo ? currentInfo.verificationId : null,
      }))
      return;
    default: return;
  }
};

function* _trashMenu() {
  const { RockyReducer: { project } } = yield select();
  const { treeItems } = project;
  let newData = [...treeItems];
  const res = yield call(getRockyTrashList);
  newData[TRASH_INDEX].children = [];

  if (res.length < 1) {
    newData[TRASH_INDEX].children = [];
  } else {
    res.forEach(item => {
      newData[TRASH_INDEX].children.push({
        name: item.name,
        key: `project-${item.id}`,
        id: item.id,
        ddrType: item.ddrType,
        dataType: MY_TRASH
      });
    });
  }
  yield put(trashMenu({ treeItems: [...newData] }));
}

function* _getRockyReport(action) {
  const { projectId, format, mime, fileName } = action;
  reportTask = yield fork(_getRockyReportFlow, { projectId, format, mime, fileName });
}

function* _getRockyReportFlow(action) {
  const { projectId, format, mime, fileName } = action;
  let status = 'running'/* , first = true */;
  let progress = 0;
  while (status === 'running') {
    /*   if (!first) { */
    yield delay(3000);
    /*  } */
    try {
      const res = yield call(getReportStatus, projectId);
      status = res.status;
      progress = res.progress;
      if (progress <= 97) {
        yield put(updateReportProgress(progress));
      }
    } catch (error) {
      status = 'failed';
    }
    //if first status is none, delay 3s,get status again
    /*     if (first && status === "none") {
          status = "running";
        } */
    /*  first = false; */
  }

  yield put(updateReportProgress(98));
  yield call(_getReportFileByFormat, { projectId, format, mime, fileName });
}

function* _getReportFileByFormat(action) {
  const { projectId, format, mime, fileName } = action;
  const url = yield call(getRockyReportData, { projectId, format, mime });
  if (url) {
    const blob = yield call(getBlob, url);
    const { RockyReducer: { project: { currentProjectId } } } = yield select();
    if (currentProjectId !== projectId) {//open other project
      return;
    }
    if (blob) {
      FileSaver.saveAs(blob, fileName);
    }
    yield put(updateReportProgress(100));
    yield put(updateReportMessage(`Download report in ${format.toUpperCase()} format successfully!`));
  } else {
    yield put(updateReportMessage(`Download report in ${format.toUpperCase()} format failed!`));
  }
  yield delay(1000);
  yield put(updateReportVisible(false));
  yield put(updateReportProgress(0));
}

function* _saveReportConfigToServer() {
  const { RockyReducer: { project: { currentProjectId, reportConfig, currentProjectInterfaceInfo } } } = yield select();
  let config = {};

  const channelIds = projectChannels.get(currentProjectId).length ? projectChannels.get(currentProjectId) : ["0"];
  let _verificationNames = {}
  if (reportConfig.rockyReportType === 'Rocky_Package') {
    _verificationNames = reportConfig.verificationNames;
  } else {
    _verificationNames[channelIds[0]] = []
    if (reportConfig && reportConfig.verificationNames && reportConfig.verificationNames[channelIds[0]]) {
      _verificationNames = reportConfig.verificationNames
      if (reportConfig.verificationNames[channelIds[0]][0] === "all") {
        _verificationNames[channelIds[0]] = currentProjectInterfaceInfo[channelIds[0]].DDR.map(item => item.Name)
      }
    }
  }

  const _language = reportConfig && reportConfig.language ? reportConfig.language : 'english'
  if (!reportConfig || Object.keys(reportConfig).length === 0) {
    config = {
      channelIds: channelIds,
      language: _language,
      templateId: '',
      verificationNames: _verificationNames,
      rockyReportType: 'DDR'
    }
  } else {
    if ((!reportConfig.channelIds || (reportConfig.channelIds.length && reportConfig.channelIds[0] === '0')) && reportConfig.rockyReportType !== 'Rocky_Package') {
      config = {
        ...reportConfig,
        channelIds,
        language: _language,
        verificationNames: _verificationNames
      }
    } else {
      config = {
        ...reportConfig,
        verificationNames: _verificationNames
      }
    }
  }
  // save report config to server
  try {
    yield call(saveReportConfigPromise, currentProjectId, config);
  } catch (error) {
    console.error(error)
    return;
  }
}

function* _clearProjectReportInfo() {
  //cancel prev report task
  if (reportTask) {
    yield cancel(reportTask);
  }
  yield put(updateReportVisible(false));
  yield put(updateReportProgress(0));
  yield put(updateReportMessage(""));
  yield put(updateReportConfig(""));
}

export function* setSelectDefaultChannelConfig(action) {
  const { channelId } = action;
  let { RockyReducer: { project: { projectList, currentProjectId, projectVersion } } } = yield select();
  const currentProject = projectList.find(item => item.id === currentProjectId);
  let projectType = currentProject ? currentProject.type : "";

  let { clock, timeStep } = getDefaultClockAndTimeStep(projectType);
  let caClock = "", caTimeStep = "";
  if (projectType === LPDDR5) {
    caClock = "800M";
    caTimeStep = "15p"
  }

  const simulate = projectVersion === PROJECT_V2 ? 'HSPICE' : permissionData.getRockySimulator() === 'HSPICE' ? 'HSPICE' : 'Default';
  let config = new ConfigInfo({ clock, caClock, timeStep, caTimeStep, simulate, isSev: projectVersion === PROJECT_V2 ? true : false });
  //result config
  let _resultConfig = getDefaultResultConfig(projectType)

  const waveformConfig = getDefaultWaveformConfig({ ddrType: projectType });

  const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId);
  let channelType = vendor !== PRE_LAYOUT ? permissionData.getRockyExtraction() : "SIwave";

  if (channelType === 'Default') {
    channelType = "SIwave"
  }

  let _extraction = {
    channelType: channelType,
    defaultConfig: {
      "xTalk": projectVersion === PROJECT_V2 ? "0" : "1"
    },
    extractionConfig: new Extraction(projectType, null, projectVersion)
  };
  yield call(updateChannelConfig, { channelId, config, resultConfig: _resultConfig, waveformConfig });
  yield call(updateChannelExtractionConfig, channelId, _extraction);
}

function* setDefaultChannelConfig(action) {
  const { channelId, componentList } = action;
  //set default channel config
  let { RockyReducer: { project: { currentChannelId, projectList, currentProjectId, currentChannelComponents, projectVersion }, result: { resultConfig } } } = yield select();
  const currentProject = projectList.find(item => item.id === currentProjectId);
  let projectType = currentProject ? currentProject.type : "";

  const components = Array.isArray(componentList) && componentList.length > 0 ? componentList : currentChannelComponents;
  if (Array.isArray(components) && components.length > 0) {
  } else {
    const reg = new RegExp(`^(CLK_ADR)`, 'ig');
    let compNames = [];
    const verifications = channelVerifications.getVerificationsByChannelId(currentChannelId) || [];
    const adrFind = verifications.find(item => item.name.match(reg));
    if (adrFind) {
      const res = yield call(getVerificationContentPromise, adrFind.id);
      if (res) {
        res.interfaces.forEach(item => {
          item.Content.Components.forEach(comp => {
            const name = splitComponentName(comp.name);
            if ((comp.type === 'Controller' || comp.type === 'Memory') && !compNames.includes(name)) {
              compNames.push(name);
            }
          });
        })
      }
    }
  }

  let { clock, timeStep } = getDefaultClockAndTimeStep(projectType);
  let caClock = "", caTimeStep = "";
  if (projectType === LPDDR5) {
    caClock = "800M";
    caTimeStep = "15p"
  }
  // const simulate = projectVersion === PROJECT_V2 ? 'HSPICE' : permissionData.getRockySimulator() === 'HSPICE' ? 'HSPICE' : 'Default';
  let simulate = "HSPICE";
  if (projectVersion !== PROJECT_V2) {
    if (permissionData.getRockySimulator() === 'ANSYSDesigner') {
      simulate = "ANSYSDesigner";
    } else if (permissionData.getRockySimulator() === 'HSPICE') {
      simulate = "HSPICE";
    } else {
      simulate = 'Default';
    }
  };
  let config = new ConfigInfo({ clock, caClock, timeStep, caTimeStep, simulate, isSev: projectVersion === PROJECT_V2 ? true : false });
  //result config
  let _resultConfig = {};
  if (!resultConfig || Object.keys(resultConfig).length === 0) {
    _resultConfig = getDefaultResultConfig(projectType)
  } else {
    _resultConfig = { ...resultConfig }
  }

  yield call(updateChannelConfig, { channelId, config, resultConfig: _resultConfig, userDefinedNetlist: [] });
  let _config = changeConfigFormat(JSON.parse(JSON.stringify(config)));

  yield put(updateCurrentConfig(_config));
  yield put(updateResultConfig(_resultConfig));
  yield put(updateUserDefinedList([]))

  yield call(_createExtractionConfig, { channelId, xTalk: projectVersion === PROJECT_V2 ? "0" : "1" });
  yield call(_createWaveformConfig, { channelId, ddrType: projectType });
}

function* _createExtractionConfig(action) {
  try {
    const { channelId, xTalk, isCard } = action;
    const Config = yield call(getChannelConfig, channelId);
    const _Config = Config.config;
    if (_Config && _Config.extraction && _Config.extraction.channelType
      && _Config.extraction.defaultConfig && _Config.extraction.extractionConfig) {
      return;
    }
    let { RockyReducer: { project: { currentProjectId, projectList, projectVersion } } } = yield select();
    const currentProject = projectList.find(item => item.id === currentProjectId);
    let projectType = currentProject ? currentProject.type : "";
    //set default channel extraction config
    let channelType = "Default";
    if (isCard) {
      channelType = "SIwave";
    } else {
      const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId);
      channelType = vendor !== PRE_LAYOUT ? permissionData.getRockyExtraction() : "Default";
      if (projectVersion === PROJECT_V2 && vendor !== PRE_LAYOUT && channelType === "Default") {
        channelType = "SIwave"
      }
    }
    let _extraction = {
      channelType: channelType,
      defaultConfig: {
        "xTalk": xTalk !== undefined ? xTalk : "1"
      },
      extractionConfig: new Extraction(projectType, null, projectVersion)
    };
    yield call(updateChannelExtractionConfig, channelId, _extraction);
    //true: Whether to allow opening of the extraction panel
    if (isCard) {
      yield put(updateCardExtractionConfig(_extraction, true));
    } else {
      yield put(updateExtractionConfig(_extraction, true));
    }
  } catch (error) {
    console.log(error)
  }
}

function* _saveExtractionConfig(action) {
  const { extraction, prevExtraction, apply } = action;
  const { RockyReducer: { project: { currentChannelId, currentProjectId, contentType }, rocky: { rockyInfo }, rockyMultiple: { channelId } } } = yield select();
  // If the fields or values in the Object are inconsistent
  // version v0.0.2
  // when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  // re generate port setups by port type is GAP, reference type is All pins
  if (rockyInfo && rockyInfo.Interfaces && rockyInfo.Interfaces.length) {
    let _Interfaces = rockyInfo.Interfaces;
    const interfaceIndex = _Interfaces.findIndex(i => portSavePath.find(p => i.name.match(p))) || 0;
    let { ReferenceNets, Port_setups, Ports_generation_setup, Ports_generate_setup_list, Components } = _Interfaces[interfaceIndex].Content;
    let saveInterface = false;
    if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
      Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
      _Interfaces[interfaceIndex].Content.Ports_generate_setup_list = Ports_generate_setup_list;
      saveInterface = true;
    }
    let data = { ReferenceNets, Port_setups, Ports_generate_setup_list }
    if (extraction.channelType !== prevExtraction.channelType && judgeUpdateGapPortSetup(data, extraction)) {
      data = updateExtractionGapPortsSetup(data, rockyInfo.designId);
      saveInterface = true;
      _Interfaces[interfaceIndex].Content = { ..._Interfaces[interfaceIndex].Content, ...data.newData };
    }

    if (judgeUpdateGapPortGapSizeSetup({
      components: Components,
      extractionType: extraction.channelType,
      prevExtractionType: prevExtraction.channelType,
      ports_generate_setup_list: Ports_generate_setup_list
    })) {
      _Interfaces.forEach(info => {
        info.Content.Components = updateHFSSExtractionCompsGapSize(info.Content.Components, Ports_generate_setup_list)
      });
      saveInterface = true;
    }

    if (saveInterface) {
      yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
      const SETUP = RockySetup;
      const newInfo = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType));
      yield put(updateRockyContentInfo(newInfo));
      yield put({ type: SAVE_CONTENT_TO_SERVER });
    }
  }

  yield put(updateExtractionConfig(extraction));
  if (apply) { // apply extraction to all channels
    const channels = projectChannels.get(currentProjectId);
    for (let channelId of channels) {
      yield call(updateChannelExtractionConfig, channelId, extraction);
    }
  } else {
    const _currentId = contentType === MULTIPLE_CHANNEL ? channelId : currentChannelId;
    yield call(updateChannelExtractionConfig, _currentId, extraction);
  }
}

function* _createChannelPDN(action) {
  const { channelId, componentList } = action;
  const { RockyReducer: { project: { currentProjectId, viewList }, rockyMultiple: { channelId: multiChannelId } } } = yield select();
  const designId = projectDesigns.getAvailableDesignIds(currentProjectId).length > 0 ? projectDesigns.getAvailableDesignIds(currentProjectId)[0] : null;
  let pdnContent;
  try {
    if (componentList && designId) {
      pdnContent = yield call(getPDNContent, { componentList, designId, channelId });
    }
  } catch (error) {
    yield put(debugMonitorAction([timeFormat() + ' [Error] Failed to parse PDN automatically. ' + error]))
  }
  if (!pdnContent) {
    const COMP_PREFIX_LIB = new CompRLCPrefixLib();
    pdnContent = {
      PowerComponents: [],
      VRM: [],
      PowerNets: [],
      MAIN_POWER_NETS: [],
      MAIN_REFERENCE_NETS: [],
      ReferenceNets: [],
      Config: new PDNExtraction(),
      COMP_PREFIX_LIB
    }
  }
  yield call(CreateRockyChannelPDN, { channelId, designId, projectId: currentProjectId, pdnContent, version: ROCKY_PDN_VERSION });

  // Refresh project
  const { RockyReducer: { project } } = yield select();
  const { currentChannelId, openVerificationId } = project;
  const _ProjectId = project.currentProjectId;
  if (currentChannelId === channelId || _ProjectId === currentProjectId) {
    let isResultPage = viewList.includes(MULTIPLE_CHANNEL) && multiChannelId === channelId ? true : false;
    yield call(openProjectByID, { id: currentProjectId, channelId, verificationId: openVerificationId, load: true, key: PROJECT, isResultPage: isResultPage });
  }
}

function* getPDNContent(action) {
  const { componentList, designId, channelId, selectPower, selectGround } = action;
  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  const COMP_PREFIX_LIB = new CompRLCPrefixLib();
  let pdnContent = {
    PowerComponents: [],
    VRM: [],
    PowerNets: [],
    MAIN_POWER_NETS: [],
    MAIN_REFERENCE_NETS: [],
    ReferenceNets: [],
    Config: new PDNExtraction(),
    COMP_PREFIX_LIB
  }

  let memoryComponentList = componentList.filter(item => item.type === 'Memory');
  let controllerComponentList = componentList.filter(item => item.type === 'Controller');
  //load pcb
  yield put(debugMonitorAction([timeFormat() + ' [PDN] Start loading PCB...']));
  yield call(saveComponentsNets, { pcbIds: [designId] });
  yield put(debugMonitorAction([timeFormat() + ' [PDN] Finish loading PCB.']))
  //getComponentsList
  const PCBComponentList = LayoutData.getComponentsList(designId);
  let targetComponentList = [];
  memoryComponentList.forEach(comp => {
    const find = PCBComponentList.find(item => item.name === comp.name && item.part === comp.part);
    if (find) {
      let findExist = targetComponentList.find(item => item.part === find.part);
      if (!findExist) {
        targetComponentList.push(find); //find { name, part, pin:pinLength }
      }
    }
  })

  const pcbInfo = DesignInfo.getPCBInfo(designId);
  const { netsList, layers } = pcbInfo;
  //find pwr and gnd nets
  const { GroundNets, PowerNets } = yield call(getPowerComponentsInfo, { id: currentProjectId, channelId });
  if ((!PowerNets || !PowerNets.length)) {
    pdnContent.ReferenceNets = GroundNets ? GroundNets : [];
    pdnContent.MAIN_REFERENCE_NETS = GroundNets ? [...GroundNets] : [];
    return pdnContent;

  }
  //Find extended nets by pwrNetList
  ////power net --> components --> res components --> nets (connect controller)
  const controllerComponents = controllerComponentList.map(item => item.name);
  const extendedNets = getExtendedNet({
    PowerNets: PowerNets,
    pcbId: designId,
    getLayoutComponents,
    layers,
    controllerComponent: controllerComponents[0] ? controllerComponents[0] : ""
  });
  const _pwrNetList = Array.from(new Set([...PowerNets, ...extendedNets]));
  pdnContent.MAIN_POWER_NETS = [..._pwrNetList];
  pdnContent.MAIN_REFERENCE_NETS = [...GroundNets];

  const newPwrNetList = selectPower ? Array.from(new Set([...selectPower, ..._pwrNetList])) : [..._pwrNetList];
  const newGroundNets = selectGround ? Array.from(new Set([...selectGround, ...GroundNets])) : [...GroundNets];
  let _DesignData = LayoutData.getLayout(designId);
  //controller/memory
  const { Components, findVRMComps, findCaps } = getPowerComponents({
    ReferenceNets: newGroundNets,
    PowerNets: newPwrNetList,
    pcbInfo: { layers, pcbNetsList: netsList },
    pcbId: designId
  });
  //power components filter
  let newPowerComponents = componentFilter({ Components, PowerNets: newPwrNetList, ReferenceNets: newGroundNets });
  //controller/memory
  newPowerComponents.forEach(item => {
    let find = componentList.find(comp => comp.name === item.name);
    if (find) {
      item.usage = find.type;
      item.pkg = new pdnPKG();
      item.die = new pdnDie();
      item.COMP_TYPE = find.type;
    } else {
      if (!item.COMP_TYPE) {
        const { pinLength: length } = getComponentNeedInfoByName({ compName: item.name, designData: _DesignData })
        const _usage = checkCompsType(item.name, COMP_PREFIX_LIB, length, item.part);
        item.COMP_TYPE = _usage;
      }
    }
  })

  // TODO, refactor getPowerComponents, componentFilter logic, merge function getNewComponents()
  const _filterComps = filterComps(findVRMComps, findCaps, newPowerComponents);
  //find vrm
  const getVRM = getVRMs({
    VRM: [],
    pcbInfo: { layers, pcbNetsList: netsList },
    Components: newPowerComponents,
    ReferenceNets: [...newGroundNets],
    PowerNets: [...newPwrNetList],
    findVRMComps: _filterComps._findVRMComps,
    findCaps: _filterComps._findCaps,
    pcbId: designId,
    findExtend: true,
    getLayoutComponents,
    getPowerComponentsByNets: getPowerComponents
  });
  //find vrm
  const DEBUG_MONITOR = getVRM.DEBUG_MONITOR;
  yield put(debugMonitorAction(DEBUG_MONITOR));

  pdnContent.VRM = getVRM.VRM;
  pdnContent.PowerNets = getVRM.PowerNets || newPwrNetList;
  pdnContent.ReferenceNets = newGroundNets;
  let new_Component = getVRM.Components;
  //Set Res, Ind default value by Cap value
  new_Component.forEach(comp => {
    let find = componentList.find(item => comp.name === item.name);
    if (find) {
      comp.usage = find.type;
      comp.pkg = new pdnPKG();
      comp.die = new pdnDie();
      comp.COMP_TYPE = find.type;
    } else {
      if (!comp.COMP_TYPE) {
        const { pinLength: length } = getComponentNeedInfoByName({ compName: comp.name, designData: _DesignData })
        const _usage = checkCompsType(comp.name, COMP_PREFIX_LIB, length, comp.part);
        comp.COMP_TYPE = _usage;
      }
      if (comp.usage === 'Cap') {
        //set default res value by cap value
        if (!comp.value.r || comp.value.r === 0 || comp.value.r === '0') {
          comp.value.r = getDefaultRLCValue(comp.value.c, 'Res');
        }

        //set default ind value by cap value
        if (!comp.value.l || comp.value.l === 0 || comp.value.l === '0') {
          comp.value.l = getDefaultRLCValue(comp.value.c, 'Ind');
        }
      }
    }
  })

  pdnContent.PowerComponents = new_Component;

  return pdnContent;
}

function* _getTouchstoneFileStatus(action) {
  let { runningFileList } = action;
  if (!runningFileList || !runningFileList.length) {
    return;
  }
  const PACKAGEIndex = 3, pkgTouchstoneIndex = 0;
  while (true) {
    yield delay(8000);
    const { RockyReducer: { project }, LoginReducer: { pageType } } = yield select();
    if (pageType !== ROCKY) {
      return;
    }
    const { treeItems } = project;
    let _treeItems = [...treeItems];
    let pkgTouchstoneList = _treeItems[LIBRARY_INDEX].children[PACKAGEIndex].children[pkgTouchstoneIndex].children;
    yield* runningFileList.map(function* (item) {
      try {
        const statusObj = yield call(getLibraryMacroModelingStatus, item.id);
        if (statusObj && statusObj.status) {
          item.status = statusObj.status;
        }
      } catch (error) {
        console.error(error);
      }
      const index = pkgTouchstoneList.findIndex(it => it.id === item.id);

      if (index > -1) {
        pkgTouchstoneList[index].status = item.status;
      }
    });
    runningFileList = runningFileList.filter(item => item.status === taskStatus.RUNNING || item.status === taskStatus.WAITING);

    _treeItems[LIBRARY_INDEX].children[PACKAGEIndex].children[pkgTouchstoneIndex].children = pkgTouchstoneList;
    yield put(libraryMenu({ treeItems: [..._treeItems], pkgTouchstoneList }));
  }
}

function getLayoutDB(id, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    try {
      if (!id) {
        reject("error");
      }
      LayoutData.LoadLayoutDB(id, onlyCompLayer).then(res => {
        resolve(true);
      }, error => {
        reject(error);
      })
    } catch (error) {
      reject(error);
    }
  })
}

export function getChannelComponents({ projectId, channelId, channelList }) {
  return new Promise((resolve, reject) => {
    ChannelComponentsInfo.getChannelComponents(projectId, channelId, channelList).then(res => {
      resolve(res);
    }, error => {
      resolve([]);
    })
  })
}

export function getPCBInterfaces({ id, channels, type }) {
  return new Promise((resolve, reject) => {
    PCBInterfacesInfo.getPCBInterfaces(id, channels, type).then(res => {
      resolve(res);
    }, error => {
      resolve([]);
    })
  })
}

export function getPowerComponentsInfo({ id, channelId }) {
  return new Promise((resolve, reject) => {
    PCBInterfacesInfo.getPowerComponentsInfo(id, channelId).then(res => {
      resolve(res);
    }, error => {
      resolve([]);
    })
  })
}

function* _updateMaskList() {
  let eyeMask = null;
  try {
    eyeMask = yield call(getEyeMaskListPromise);
  } catch (error) {
    console.error('Get eye mask list error! ' + error)
    return;
  }
  let { RockyReducer: { project } } = yield select();
  let { treeItems } = project;

  let _treeItems = [...treeItems];
  const EYEMASKIndex = 4;
  let eyeMaskList = [];
  try {
    getEyeMaskType().forEach((ddr, index) => {
      try {
        const list = getMaskList(eyeMask[ddr], ddr);
        if (_treeItems[LIBRARY_INDEX].children[EYEMASKIndex].children[index] && _treeItems[LIBRARY_INDEX].children[EYEMASKIndex].children[index].children) {
          _treeItems[LIBRARY_INDEX].children[EYEMASKIndex].children[index].children = list;
        }
        eyeMaskList.push(...list);
      } catch (error) {
        console.error(error);
      }
    });
  } catch (error) {
    console.error(error);
  }
  yield put(libraryMenu({
    treeItems: [..._treeItems],
    eyeMaskList
  }));
}

function* _initEyeMask(action) {
  try {
    const projectId = action.id;
    const res = yield call(getProjectEyeMaskOptionPromise, projectId);
    if (!res || !res.origin) {
      const defaultEye = permissionData.getEyeMask();
      const data = { eyemaskId: null, eyemaskName: defaultEye, origin: defaultEye, projectId: projectId };
      yield call(editProjectEyeMaskOptionPromise, data);
    }
  } catch (error) {
    console.error(error);
    return;
  }
}

function* updateShareMenu(action) {
  const shareList = yield call(getShareTreeList, ROCKY);
  const { RockyReducer: { project } } = yield select();
  let { treeItems } = project;
  treeItems[3] = shareList[0];
  treeItems[4] = shareList[1];
  yield put(shareMenu({ treeItems }));
}

function* updatePreLayoutLibrary(action) {
  const { dataType, data } = action;
  const { id, name, config, type } = data;
  const { RockyReducer: { project: { expandedKeys } } } = yield select();
  const libraries = [{ id, name, fileName: "", libraryData: config }];
  let res = {};
  try {
    if (id) {
      res = yield call(updateLibraryData, { libraryId: id, libraries, typeName: type, libraryFormat: 'data' });
    } else {
      res = yield call(createLibrary, { libraries, typeName: type, libraryFormat: 'data' });
    }
    if (res) {
      let keys = [...expandedKeys];
      yield call(updateLibraryMenu, {});
      if (!keys.includes(dataType)) {
        keys.push(dataType);
      }
      yield put(expandMenu(keys));
    }
    yield put(changeLibraryStatus(true));
    PreLayoutData.updateLibraryConfig(id, type);
  } catch (error) {
    console.error(error)
  }
}

function* clearChannelByProjectId(action) {
  const { RockyReducer: { project } } = yield select();
  let { treeItems, projectVersion } = project;
  const { projectId } = action;
  let _treeItems = [...treeItems];
  const index = _treeItems[PROJECT_INDEX].children.findIndex(item => item.id === projectId);
  const _project = _treeItems[PROJECT_INDEX].children[index];
  const channel_index = DIMM_DDR_TYPES.includes(_project.type) ? 3 : 2;
  if (projectVersion === PROJECT_V2) {
    _treeItems[PROJECT_INDEX].children[index].children[0].children.forEach(item => item.children = [])
  }
  _treeItems[PROJECT_INDEX].children[index].children[channel_index].children = [];
  // Clear project channel list in menu
  yield put(projectMenu({ treeItems: [..._treeItems] }));
}

export function* replacePCB(action) {
  const { designId } = action;
  if (!designId) {
    return;
  }
  LayoutData.cleanLayoutInfo(designId);
  cleanCtrlInfo();
  yield call(reloadPCBInfo, { designId });
  //clean pcb state
  yield put(cleanStatus(designId));
  yield put(updatePCBRefreshStatus(true, designId));
}

function* reloadPCBInfo({ designId }) {
  const { RockyReducer: { project: { pcbComponentsNets } } } = yield select();
  let newComponentsNets = [...pcbComponentsNets];
  const pcbInfo = yield call(getLayoutDBInfo, designId);
  DesignInfo.savePCBInfo(designId, pcbInfo);
  newComponentsNets = [...new Set([...newComponentsNets, designId])];
  yield put(updatePCBComponentsNets(newComponentsNets));
}

function* updateChannelPDN(action) {
  const { findPDN, channelId, componentList, designVersion } = action;

  if (!findPDN.rockyPdnId) {
    return;
  }

  const { RockyReducer: { project: { currentProjectId }, RockyPDN: { rockyPDNInfo } } } = yield select();
  const designId = projectDesigns.getAvailableDesignIds(currentProjectId).length > 0 ? projectDesigns.getAvailableDesignIds(currentProjectId)[0] : null;
  let newPDNContent;
  if (componentList && designId) {
    newPDNContent = yield call(getPDNContent, { componentList, designId, channelId });
  }
  if (findPDN.rockyPdnId) {
    const pdnInfo = yield call(getChannelPDNContentPromise, findPDN.rockyPdnId);
    const oldPDNContent = pdnInfo ? pdnInfo.pdnContent : {};
    if (oldPDNContent) {
      const selectPower = oldPDNContent['MAIN_POWER_NETS'] ? oldPDNContent.PowerNets.filter(item => !oldPDNContent['MAIN_POWER_NETS'].includes(item)) : [];
      const selectGround = oldPDNContent['MAIN_REFERENCE_NETS'] ? oldPDNContent.ReferenceNets.filter(item => !oldPDNContent['MAIN_REFERENCE_NETS'].includes(item)) : [];
      newPDNContent = yield call(getPDNContent, { componentList, designId, channelId, selectPower, selectGround });
      newPDNContent.VRM = newPDNContent.VRM.map(item => {
        const vrm = oldPDNContent.VRM.find(v => v['VRM_COMP'] === item['VRM_COMP']);
        return vrm ? vrm : item;
      })
      newPDNContent.PowerComponents = newPDNContent.PowerComponents.map(item => {
        const comp = oldPDNContent.PowerComponents.find(p => p.name === item.name && p['COMP_TYPE'] === item['COMP_TYPE']);
        const { COMP_TYPE, name, pins, part } = item;
        return comp ? { ...comp, COMP_TYPE, name, pins, part } : item;
      })
    }
  }

  // save pdn
  if (rockyPDNInfo && rockyPDNInfo.PDNID === findPDN.rockyPdnId) {
    const newRockyPDNInfo = {
      PDNID: findPDN.rockyPdnId,
      pdnContent: newPDNContent,
      verificationId: findPDN.verificationId,
      designId: findPDN.designId,
      version: findPDN.version,
      name: findPDN.name,
      readyForSim: findPDN.readyForSim,
      ifDoExtraction: findPDN.ifDoExtraction
    };
    yield put(updateRockyPDNContent(newRockyPDNInfo));
  }
  yield fork(updateRockyPDNContentPromise, { pdnContent: { ...newPDNContent }, PDNID: findPDN.rockyPdnId, readyForSim: findPDN.readyForSim, version: ROCKY_PDN_VERSION });

  // save verification info
  yield fork(updateVerificationContent, { ...findPDN, designVersion });
}

function* _updatePCBLayout(action) {
  const { pcbLayout, prePcbLayout } = action;
  // prePcbLayout b,c -> a: close PCB
  const { RockyReducer: { project } } = yield select();
  let { viewList, selectedKeys } = project;
  if (pcbLayout === PCB_ONLY && [PCB_TOP_BOTTOM, PCB_LEFT_RIGHT].includes(prePcbLayout)) {
    const data = updateTreeAfterChangePCBLayout(selectedKeys, viewList);
    if (data) {
      const { _selectedKeys, _viewList } = data;
      yield put(updateTreeSelectedKeys(_selectedKeys));
      yield put(updateViewList(_viewList));
    }
  }
}

function* openPackageByID(action) {
  const { packageDesignId, key } = action;

  const { RockyReducer: { project } } = yield select();
  let { expandedKeys, currentProjectId } = project;
  expandedKeys = [...new Set([...expandedKeys, `${key}-${packageDesignId}`])];
  yield put(expandMenu(expandedKeys));

  try {
    let Config = yield call(getPackageExtractionPromise, packageDesignId)

    let save = false;
    if (!Config || !Object.keys(Config)) {
      // When there is no extraction, give the default value
      Config = new Extraction('', PACKAGE);
      save = true;
    }

    // Add siwave and hfss to extraction config,default is {}, support yml file selection
    if (Config && !Config.siwave) {
      Config.siwave = {};
      save = true;
    }
    if (Config && !Config.hfss) {
      Config.hfss = {};
      save = true;
    }
    if (save) {
      yield call(updatePackageExtractionPromise, { designId: packageDesignId, extractionConfig: Config })
    }

    yield put(updatePackageExtractionConfig(Config));

    // update verification status list
    yield put(autoGetVerificationList({
      projectId: currentProjectId,
      currentPkgDesignId: packageDesignId,
      verificationType: PACKAGE
    }));
  } catch (error) {
    console.error(error)
  }
}

function* savePackageExtractionConfig(action) {
  const { extractionConfig, prevExtraction } = action;
  const { RockyReducer: { project: { currentPackageDesignId, currentProjectId }, rocky: { rockyPackageInfo } } } = yield select();

  // when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  // re generate port setups by port type is GAP, reference type is All pins
  if (rockyPackageInfo && Object.keys(rockyPackageInfo).length) {
    let content = rockyPackageInfo.content;
    let { ReferenceNets, Port_setups, Ports_generation_setup, Ports_generate_setup_list, Components } = content
    let savePackageContent = false;

    if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
      Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
      content.Ports_generate_setup_list = Ports_generate_setup_list;
      savePackageContent = true;
    }

    let data = { ReferenceNets, Port_setups, Ports_generate_setup_list };
    if (extractionConfig.type !== prevExtraction.type && judgeUpdateGapPortSetup(data, { ...extractionConfig, channelType: extractionConfig.type })) {
      data = updateExtractionGapPortsSetup(data, currentPackageDesignId);
      savePackageContent = true;
      content = { ...content, ...data.newData }
    }

    if (judgeUpdateGapPortGapSizeSetup({
      components: Components,
      extractionType: extractionConfig.type,
      prevExtractionType: prevExtraction.type,
      ports_generate_setup_list: Ports_generate_setup_list
    })) {
      content.Components = updateHFSSExtractionCompsGapSize(content.Components, Ports_generate_setup_list)
      savePackageContent = true;
    }

    if (savePackageContent) {
      yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content }));
      const data = [{ ...rockyPackageInfo, projectId: currentProjectId, content }]
      try {
        yield call(updatePackageVerificationPromise, data)
      } catch (error) {
        console.error(error)
      }
    }
  }
  yield put(updatePackageExtractionConfig(extractionConfig));
  try {
    yield call(updatePackageExtractionPromise, { designId: currentPackageDesignId, extractionConfig })
  } catch (error) {
    console.error(error)
  }
}

function* _delCard(action) {
  const { itemData } = action;
  const { RockyReducer: { project: { currentProjectId, treeItems, expandedKeys, selectedKeys, viewList } } } = yield select();

  try {
    yield call(deleteDesignInProject, itemData.id);
    const cardChannels = cardChannelsConstructor.getChannels(itemData.id);
    const cardVerifications = cardChannelsConstructor.getVerificationsByDesignId(itemData.id);

    let _viewList = [...viewList];

    //update selectedKeys
    if (selectedKeys.length) {
      let newKeys = [];
      selectedKeys.forEach((item, index) => {
        const [key, id] = strDelimited(item, "-");
        if (
          ((key !== CARD_VERIFICATION && key !== CARD_RESULT) || !cardVerifications.find(it => it.id === id))
          && (key !== CARD || id !== itemData.id)) {
          newKeys.push(item);
        } else {
          _viewList = viewList.filter(item => item !== key);
        }
      });
      yield put(updateTreeSelectedKeys([...newKeys]));
      yield put(updateViewList(_viewList));
    }
    //update expandedKeys
    if (expandedKeys.length) {
      let newExpandKeys = [];
      expandedKeys.forEach((item, index) => {
        const [key, id] = strDelimited(item, "-");
        if (
          (key !== CARD_CHANNEL && key !== CARD_VERIFICATION && key !== CARD)
          || (key === CARD_CHANNEL && !cardChannels.find(it => it.id === id))
          || (key === CARD_VERIFICATION && !cardVerifications.find(it => it.id === id))
          || (key === CARD && id !== itemData.id)
        ) {
          newExpandKeys.push(item);
        }
      });
      yield put(expandMenu([...newExpandKeys]));
    }
    //clear cache
    designConstructor.delDesign(itemData.id);
    cardChannelsConstructor.delCard(itemData.id);
    //update treeItems
    let _treeItems = [...treeItems];
    const projectIndex = _treeItems[getTreeIndex(PROJECT)].children.findIndex(item => item.id === currentProjectId);
    if (projectIndex < 0) {
      return;
    }
    const dataTypePrefix = getDataTypePrefix(PROJECT);
    const res = yield call(getRockyProjectPromise, currentProjectId);
    const cards = res.cards && res.cards.length ? getCardTree(res.cards, dataTypePrefix, res.cardChannels) : [];
    _treeItems[getTreeIndex(PROJECT)].children[projectIndex].children[CARD_INDEX].children = cards;
    yield put(projectMenu({ treeItems: [..._treeItems] }));
  } catch (error) {
    console.error(error);
  }
}

function* _updateCardLibraryList(action) {
  try {
    const { libraryObj } = action;
    let res = null;
    if (libraryObj) {
      res = libraryObj;
    } else {
      res = yield call(getLibraryList);
    }
    const { RockyReducer: { project } } = yield select();
    const { treeItems } = project;
    let _treeItems = [...treeItems];
    //update card library list
    const cardLibraryList = getCardLibraryTree(res.card);
    _treeItems[LIBRARY_INDEX].children[CARD_LIBRARY_INDEX].children = cardLibraryList;

    yield put(libraryMenu({
      treeItems: [..._treeItems],
      cardLibraryList
    }));
  } catch (error) {
    message.error('Cannot get library list! ' + error)
    return;
  }
}

function* _renameCardLibrary(action) {
  const { data } = action;
  try {
    //call api to rename
    const res = yield call(renameLibrary, { id: data.id, name: data.name });
    //get library list to update name
    yield put(updateCardLibraryList(res));
  } catch (error) {
    message.error("Card library rename failed!");
    console.error("Card library rename failed " + error);
  }
}

function* _createWaveformConfig(action) {
  const { channelId, ddrType, isPrevCase } = action;
  const waveformConfig = getDefaultWaveformConfig({ ddrType, isPrevCase });
  yield put(updateWaveformConfig(waveformConfig));
  yield call(updateChannelConfig, { channelId, waveformConfig });
}

function* _saveWaveformConfig(action) {
  const { channelId, waveformConfig } = action;
  try {
    yield put(updateWaveformConfig(waveformConfig));
    yield call(updateChannelConfig, { channelId, waveformConfig });
  } catch (error) {
    console.error(error)
  }
}

function* _saveWireBondFile(action) {
  const { libraryId, designId, oldLibraryId } = action;
  if (!designId) {
    return;
  }
  try {
    if ((!oldLibraryId && libraryId) || (oldLibraryId && !libraryId)) {
      yield put(updatePackagePortAndBall(oldLibraryId, libraryId));
    }
    yield call(savePackageWireBondProfile, { libraryId: libraryId || null, designId });
  } catch (error) {
    console.error(error)
  }
}

export function* openSSNChannelConfig(action) {
  const { id: channelId } = action;
  const { RockyReducer: { project: { currentProjectId, projectList, projectVersion } } } = yield select();
  //Clear extraction data
  yield put(updateExtractionConfig({}, false));

  /*  const channels = projectChannels.get(currentProjectId).map(id => rockyChannels.get(id)); */
  // const ChannelInfo = yield call(getChannelComponents, { projectId: currentProjectId, channelId, channelList: channels });
  // const { componentList } = ChannelInfo;

  //GET channel config (simulation config and result config)
  //get channel config
  try {
    let Config = yield call(getChannelConfig, channelId);
    let _currentConfig = {}, _resultConfig = {}, _extraction = {}, isDefault = false;
    if (Config.config.config && Object.keys(Config.config.config).length) {
      _currentConfig = new ConfigInfo({ ...Config.config.config, isSev: projectVersion === PROJECT_V2 ? true : false });
    } else if (Config.config.timeStep) {
      _currentConfig = new ConfigInfo({ ...Config.config, isSev: projectVersion === PROJECT_V2 ? true : false });
    } else {
      isDefault = true;
      yield put(defaultChannelConfig(channelId));
    }

    const _config = changeConfigFormat({ ..._currentConfig });

    yield put(updateCurrentConfig(_config));
    yield put(updateUserDefinedList(Config.config.userDefinedNetlist))
    if (Config.config.resultConfig) {
      _resultConfig = { ...Config.config.resultConfig };
      yield put(updateResultConfig(_resultConfig));
    } else if (!isDefault) {
      yield delay(500);
      yield put(defaultResultConfig());
    }
    if (Config.config.extraction) {
      _extraction = judgeExtraction(Config.config.extraction);

      //true: Whether to allow opening of the extraction panel
      yield put(updateExtractionConfig(_extraction, true));
    } else if (!isDefault) {
      yield delay(500);
      let xTalk = '1';
      if (_currentConfig && _currentConfig.xTalk !== undefined) {
        xTalk = _currentConfig.xTalk;
      }
      yield put(defaultExtractionConfig(channelId, xTalk));
    }

    if (Config.config.waveformConfig && Object.keys(Config.config.waveformConfig).length) {
      yield put(updateWaveformConfig(Config.config.waveformConfig));
    } else if (!isDefault) {
      yield delay(500);
      const currentProject = projectList.find(item => item.id === currentProjectId);
      let ddrType = currentProject ? currentProject.type : "";
      const isPrevCase = Config.config.config && Object.keys(Config.config.config).length;
      yield put(defaultWaveformConfig(channelId, ddrType, isPrevCase));
    }
  } catch (error) {
    yield put(defaultChannelConfig(channelId));
  }
}

export function* getPackageConfig(packageId) {
  let Config = yield call(getPackageExtractionPromise, packageId)

  if (!Config || !Object.keys(Config)) {
    // When there is no extraction, give the default value
    Config = new Extraction('', PACKAGE)
    yield call(updatePackageExtractionPromise, { designId: packageId, extractionConfig: Config })
  }
  yield put(updatePackageExtractionConfig(Config));
}

function* _getLibraryFolderChildren(action) {
  const { folderInfo } = action;
  const { RockyReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems], updateList = {};

  for (let info of folderInfo) {
    const { list = [], libraryType, index, childrenIndex, key } = info;

    const filterList = list.filter(item => item.type === "folder");
    const tree = !childrenIndex && childrenIndex !== 0 ? _treeItems[LIBRARY_INDEX].children[index].children : _treeItems[LIBRARY_INDEX].children[index].children[childrenIndex].children;
    for (let file of filterList) {
      try {
        const index = tree.findIndex(item => item.id === file.id);
        if (index < 0) {
          continue;
        }
        let children = [];
        if (libraryType === IBIS_AMI) {
          children = yield call(getFolderDetail, file.id, ROCKY);
        } else {
          children = yield call(getLibraryFolderChildren, { libraryType: libraryType, libraryId: file.id });
          children = children.filter(item => item.type === "file")
        }
        if (!children || !children.length) {
          continue;
        }
        children = children.map(item => new libraryItem(libraryType, {
          name: item.fileName,
          type: 'file',
          id: file.id,
          fileName: item.fileName,
          typeName: libraryType,
          nodeClass: 'tree-library-file',
          status: null,
          folderChildren: true
        }));
        tree[index].children = children;
      } catch (error) {
        console.error(error)
      }
    }

    updateList[key] = tree;
    if (!childrenIndex && childrenIndex !== 0) {
      _treeItems[LIBRARY_INDEX].children[index].children = tree;
    } else {
      _treeItems[LIBRARY_INDEX].children[index].children[childrenIndex].children = tree;
    }
  }

  yield put(libraryMenu({
    treeItems: [..._treeItems],
    ...updateList
  }));
}

function* _updateProjectName(action) {
  const { name, itemData } = action;
  const { RockyReducer: { project: { treeItems, currentProjectId } } } = yield select();
  try {
    const res = yield call(changeProjectName, { projectId: itemData.id, projectName: name });
    if (!res || !res.id) {
      message.error('Change project name failed!')
      return
    }
    let _treeItems = [...treeItems];
    const index = getTreeIndex(PROJECT);
    const findIndex = _treeItems[index].children.findIndex(item => item.id === itemData.id);
    if (findIndex < 0) {
      return;
    }
    let version = '1.0';
    const projectItem = _treeItems[index].children[findIndex];
    projectItem.name = name;
    version = projectItem.projectVersion;
    _treeItems[index].children[findIndex] = projectItem;
    const projectList = _treeItems[index].children;
    yield put(projectMenu({ treeItems: [..._treeItems], projectList }));
    if (currentProjectId === itemData.id) {
      // Update Project ID and Name
      yield put(saveOpenProjectInfo({ id: itemData.id, name, version }));
      //update monitor box info
      yield put(changeTabMenu({ projectName: name }));
    }
  } catch (error) {
    message.error('Change project name failed!')
  }
}

function* _setDefaultDecap(action) {
  const { info } = action;
  const { RockyReducer: { project: { treeItems } } } = yield select();
  try {
    const { id, name, fileType, dataType } = info;
    let deafultDecapInfo = { libraryType: DECAP_SPICE, libraryId: "" };
    let _libraryId = id, _libraryType = fileType && fileType === "decap_spice" ? fileType : dataType, _name = name;
    if (!id) {
      const sysTree = treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_VENDOR_INDEX].children;
      if (sysTree.length) {
        _libraryId = sysTree[0].id;
        _libraryType = sysTree[0].dataType;
        _name = sysTree[0].name
      }
    }

    if (_libraryId) {
      deafultDecapInfo = {
        libraryId: _libraryId,
        libraryType: _libraryType,
        name: _name
      }
    }

    yield put(updateDefaultDecap(deafultDecapInfo))
    yield call(setDefaultDecap, { ...deafultDecapInfo, product: ROCKY });
  } catch (error) {
    console.error(error)
    message.error("Set default decap error");
    return
  }
}

function* getLevelOneLibrary() {
  const { RockyReducer: { project: { treeItems } } } = yield select();
  let _treeItems = [...treeItems], genericList = [];
  try {
    const { data, customData } = yield call(SystemLibrary.initStore);
    _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_VENDOR_INDEX].children = data;

    const generic = yield call(getSysGeneric);
    genericList = generic.map(ge => new SysLibraryItem(ge));
    _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_GENERIC_INDEX].children = genericList;
  } catch (error) {
    console.error(error);
  }
  yield put(libraryMenu({ treeItems: [..._treeItems], DecapGeneric: genericList }));

  const { RockyReducer: { project: { expandedKeys } } } = yield select();
  let newExpandedKeys = expandedKeys.filter(key => !key.includes(SYS_LIBRARY));
  yield put(expandMenu(newExpandedKeys));
}

function* updateSysLibrary(action) {
  const { folderId, path } = action;
  const { RockyReducer: { project: { treeItems } } } = yield select();
  const tree = yield call(SystemLibrary.getChildren, { folderId });
  let _treeItems = [...treeItems];
  let currentTree = _treeItems[SYSTEM_LIBRARY_INDEX].children[SYS_DECAP_INDEX].children[SYS_DECAP_VENDOR_INDEX];
  path.split('/').forEach(name => {
    let findIndex = currentTree.children.findIndex(item => item.name === name);
    if (findIndex > -1) {
      currentTree = currentTree.children[findIndex];
    }
  })
  currentTree.load = true;
  currentTree.children = tree;
  yield put(projectMenu({ treeItems: [..._treeItems] }));
}

function* projectSaga() {
  yield takeEvery(UPDATE_LIBRARY_MENU, updateLibraryMenu);
  yield takeEvery(UPDATE_PROJECT_MENU, updateProjectMenu);
  yield takeEvery(OPEN_PROJECT, openProjectByID);
  yield takeEvery(OPEN_CHANNEL, openChannelByID);
  yield takeEvery(SAVE_COMPONENTS_NETS_IN_PROJECT, saveComponentsNets);
  yield takeLatest(AUTO_GET_VERIFICATION_LIST, autoGetVerifications);
  yield takeEvery(UPDATE_VIEW_LIST, changeViewList);
  yield takeEvery(UPDATE_TRASH_MENU, _trashMenu);
  yield takeEvery(GET_REPORT, _getRockyReport);
  yield takeEvery(GET_REPORT_FILE, _getReportFileByFormat);
  yield takeEvery(SAVE_REPORT_CONFIG, _saveReportConfigToServer);
  yield takeEvery(CLEAR_REPORT_INFO, _clearProjectReportInfo);
  yield takeEvery(DEFAULT_EXTRACTION_CONFIG, _createExtractionConfig);
  yield takeEvery(DEFAULT_CHANNEL_CONFIG, setDefaultChannelConfig);
  yield takeEvery(SAVE_CHANNEL_EXTRACTION_CONFIG, _saveExtractionConfig);
  yield takeEvery(CLEAN_CHANNEL_BY_PROJECTID, clearChannelByProjectId);
  yield takeEvery(REPLACE_PCB, replacePCB);
  yield takeLatest(UPDATE_MASK_LIBRARY_LIST, _updateMaskList);
  yield takeLatest('initEyeMask', _initEyeMask);
  // yield takeEvery(UPDATE_SHARE_MENU, updateShareMenu);
  yield takeEvery(SAVE_PRE_LAYOUT_TEMPLATE, updatePreLayoutLibrary);
  yield takeLatest(UPDATE_PCB_LAYOUT, _updatePCBLayout);
  yield takeEvery(DELETE_CARD, _delCard);
  yield takeEvery(OPEN_PACKAGE_TREE, openPackageByID)
  yield takeEvery(SAVE_PACKAGE_VERIFICATION_EXTRACTION_CONFIG, savePackageExtractionConfig);
  yield takeEvery(UPDATE_CARD_LIBRARY_LIST, _updateCardLibraryList);
  yield takeEvery(CARD_LIBRARY_RENAME, _renameCardLibrary);
  yield takeEvery(SAVE_WAVEFORM_CONFIG, _saveWaveformConfig);
  yield takeEvery(SET_DEFAULT_WAVEFORM_CONFIG, _createWaveformConfig);
  yield takeEvery(SAVE_WIRE_BOND_PROFILE, _saveWireBondFile);
  yield takeEvery(OPEN_SSN_CHANNEL, openSSNChannelConfig);
  yield takeEvery(GET_LIBRARY_FOLDER_CHILDREN, _getLibraryFolderChildren);
  yield takeEvery(EDIT_PROJECT_NAME, _updateProjectName);
  yield takeEvery(LIBRARY_DEFAULT, _setDefaultDecap);
  yield takeEvery(INIT_SYS_LIBRARY, getLevelOneLibrary);
  yield takeEvery(UPDATE_SYS_LIBRARY, updateSysLibrary);
}

export default projectSaga;