import { call, put, takeEvery, select, cancel, fork, delay } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { CANCEL_LAYOUT_CHECK, START_LAYOUT_CHECK, CHANGE_DESIGN } from './actionType';
import { saveCurrentDesign, saveLayoutInfo, saveSimDesigns, updateDesignMonitor, updateLayoutResult } from './action';
import { CANCEL, FAILED, LAYOUT_NO_CHECK, LAYOUT_RUNNING, LAYOUT_SUCCEED, RUNNING, SUCCEED, WAITING } from '../../../constants/workflowStatus';
import { getMonitor, workflowWaitingIndex } from '../../../services/workflow/workflow';
import monitorLog from '../../../services/helper/monitorLog';
import { getWorkFlow, cancelWorkflow } from '../../../services/api/v2/workflowCtrl';
import { fakeProgress } from '../../../services/helper/dataProcess';
import { getLayoutCheckLog, getLayoutCheckResult, getLayoutWorkflowInfo, layoutSimulation } from '../../../services/api/Design/design';

function* startLayoutSimulation(action) {
  const { designId, product } = action;
  yield put(saveCurrentDesign(designId));
  yield put(saveLayoutInfo({ product }))

  const checkoutInfo = {
    designId,
    status: RUNNING,
    progress: 0,
    startMsg: "==> Start simulating...",
    endMsg: null,
    monitor: null,
    log: [],
    product
  }
  yield put(updateDesignMonitor({ designId, info: checkoutInfo }))
  yield put(updateLayoutResult(designId, null))
  try {
    const res = yield call(layoutSimulation, designId);
    const { LayoutReducer: { simulationDesigns } } = yield select();
    yield put(saveSimDesigns(new Set([...simulationDesigns, designId])));
    yield fork(startNewWorkflow, { designId, interfaceStatus: res })
  } catch (e) {
    yield put(updateDesignMonitor({ designId, info: { ...checkoutInfo, progress: -1, status: FAILED, startMsg: `==> Simulation failed!\n==> ${e}` } }))
    console.error(e)
  }
}

// workflow
let workflowTask = null;
function* startNewWorkflow(action) {
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  workflowTask = yield fork(designWorkFlow, { ...action });
}

function* doesStopWorkflowPolling() {
  const { LayoutReducer: { product, runningDesigns, checkout } } = yield select();
  // Switch pages
  if (runningDesigns && checkout[runningDesigns] && checkout[runningDesigns].product === product) {
    return false;
  }
  yield put(saveLayoutInfo({ runningDesigns: "" }))
  return true;
}

function* designWorkFlow(action) {
  const { designId, interfaceStatus } = action;
  const { LayoutReducer: { simulationDesigns } } = yield select();
  yield put(saveLayoutInfo({ runningDesigns: designId }))
  if (!interfaceStatus || !interfaceStatus.length) {
    yield put(saveSimDesigns(simulationDesigns.filter(id => id !== designId)));
    return
  }
  let { id, status, progress } = interfaceStatus[0];
  yield put(updateDesignMonitor({
    designId,
    info: {
      workflowId: id
    }
  }));

  try {
    const stopWaiting = yield call(getWaitingIndex, { designId, id });
    if (stopWaiting) {
      yield put(saveSimDesigns(simulationDesigns.filter(id => id !== designId)));
      return;
    }
    if (progress === 0) {
      progress += 2;
    }
    yield put(updateDesignMonitor({
      designId,
      info: {
        startMsg: "==> Simulation running...",
        progress
      }
    }));

    // simulation running
    const stopWorkflowPolling = yield call(getSimulationRunning, { status, designId, id, progress })

    if (stopWorkflowPolling) {
      yield put(saveSimDesigns(simulationDesigns.filter(id => id !== designId)));
      return;
    }

    yield put(updateDesignMonitor({
      designId,
      info: {
        progress: status === SUCCEED ? 100 : -1
      }
    }));

    yield call(_getWorkflowMonitor, { workflowId: id, designId });

    // simulation complate
    yield put(updateDesignMonitor({
      designId,
      info: {
        progress: -1,
        startMsg: null,
        endMsg: null
      }
    }));
    yield put(saveSimDesigns(simulationDesigns.filter(id => id !== designId)));
    yield fork(getLayoutLog, { designId });

    try {
      const { LayoutReducer: { results } } = yield select();
      if (!results[designId]) {
        const result = yield call(getLayoutCheckResult, designId)
        yield put(updateLayoutResult(designId, result))
      }
    } catch (error) {
      console.error("Get layout error result failed: ", error)
    }
  } catch (error) {
    yield put(saveSimDesigns(simulationDesigns.filter(id => id !== designId)));
    yield put(updateDesignMonitor({
      designId,
      info: {
        designId,
        status: FAILED,
        progress: -1,
        startMsg: null,
        endMsg: "==> Interface request failed.",
        monitor: null,
        log: []
      }
    }));
    console.error(error)
  }
}

function* getSimulationRunning(action) {
  let { status, designId, id, progress } = action
  let indexNum = 0;
  while (status === RUNNING) {
    const stopWorkflowPolling = yield call(doesStopWorkflowPolling);
    if (stopWorkflowPolling) {
      return true;
    }
    yield call(_getWorkflowMonitor, { workflowId: id, designId });

    yield delay(5000);
    let _progress = progress;
    try {
      const { data: { data } } = yield call(getWorkFlow, id);
      if (!data) {
        status = FAILED;
      } else {
        status = data.status;
        _progress = data.progress;
      }
    } catch (error) {
      console.error(error)
    }
    indexNum += 1;
    progress = fakeProgress(progress, indexNum, _progress);
    yield put(updateDesignMonitor({
      designId,
      info: {
        progress,
        status
      }
    }));
  }
}

function* getWaitingIndex(action) {
  const { designId, id } = action;
  let waitingIndex = -1, time;
  waitingIndex = yield call(workflowWaitingIndex, id);
  while (waitingIndex >= 0) { // wait
    const stopWorkflowPolling = yield call(doesStopWorkflowPolling)
    if (stopWorkflowPolling) {
      return true;
    }
    time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    yield put(updateDesignMonitor({
      designId,
      info: {
        waitingIndex, waitingTime, status: WAITING
      }
    }));

    yield delay(3000);
    waitingIndex = yield call(workflowWaitingIndex, id);
  }

  while (waitingIndex === -2) { //simulation cancel
    yield put(updateDesignMonitor({
      designId,
      info: {
        waitingIndex: -1,
        waitingTime: null,
        startMsg: null,
        progress: -1,
        endMsg: null
      }
    }))
    yield delay(3000);
    yield call(_getWorkflowMonitor, { workflowId: id, designId });
    return;
  }

  while (waitingIndex === -3) { //simulation complate
    // yield call(updateResultExistStatus, { verificationId });
    // const promise = yield call(checkVerificationStatus, verificationId);
    return;
  }

  yield put(updateDesignMonitor({
    designId,
    info: {
      waitingIndex: -1,
      waitingTime: null
    }
  }));
}

function* _getWorkflowMonitor(action) {
  const { workflowId, designId } = action;
  try {
    const log = yield call(getMonitor, workflowId)
    if (log.length > 0) {
      const { LayoutReducer: { checkout } } = yield select();
      const _prevInfo = checkout[designId] || {}
      yield put(updateDesignMonitor({
        designId,
        info: {
          ..._prevInfo,
          monitor: monitorLog(log)
        }
      }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLayoutWorkflow(action) {
  const { designId, product } = action;
  try {
    const verificationStatus = yield call(getLayoutWorkflowInfo, designId);
    if (product) {
      yield put(updateDesignMonitor({ designId, info: { designId, product, progress: -1, log: [] } }))
    }
    if (verificationStatus && verificationStatus.id) {
      yield call(startNewWorkflow, {
        designId,
        interfaceStatus: [verificationStatus]
      })
    }
  } catch (error) {
    console.error(error);
    yield put(updateDesignMonitor({
      designId,
      info: {
        designId,
        status: FAILED,
        progress: -1,
        startMsg: null,
        endMsg: "==> Interface request failed.",
        monitor: null,
        log: []
      }
    }));
  }
}

function* getLayoutLog(action) {
  const { designId } = action;
  try {
    let log = yield call(getLayoutCheckLog, designId);
    yield put(updateDesignMonitor({
      designId,
      info: {
        designId,
        status: SUCCEED,
        progress: -1,
        startMsg: null,
        endMsg: null,
        monitor: null,
        log: log
      }
    }));
  } catch (error) {
    console.error(error)
    yield put(updateDesignMonitor({
      designId,
      info: {
        designId,
        status: FAILED,
        progress: -1,
        startMsg: null,
        endMsg: "==> Interface request failed.",
        monitor: null,
        log: []
      }
    }));
  }
}

function* _cancelWorkflow(action) {
  const { designId } = action;
  try {
    const { LayoutReducer: { checkout } } = yield select();
    const info = checkout[designId];
    if (!info || !info.workflowId) {
      return
    }
    yield call(cancelWorkflow, info.workflowId);
    yield put(updateDesignMonitor({
      designId,
      info: {
        startMsg: null,
        endMsg: "==> Simulation cancelled!",
        progress: -1,
        status: CANCEL
      }
    }));
  } catch (error) {
    console.error(error);
  }
}

function* switchLayoutDesign(action) {
  const { designId } = action;
  try {
    const { LayoutReducer: { projectLayout = [], simulationDesigns, product } } = yield select();
    const current = projectLayout.find(item => item.id === designId);
    if (!current) {
      return;
    }
    yield put(saveCurrentDesign(designId));
    if (simulationDesigns.includes(designId)) {
      yield fork(getLayoutWorkflow, { designId, product });
      return;
    }
    switch (current.layoutCheck) {
      case LAYOUT_RUNNING:
        yield fork(getLayoutWorkflow, { designId, product })
        break;
      case LAYOUT_SUCCEED:
        yield fork(getLayoutLog, { designId })
        break;
      case LAYOUT_NO_CHECK:
      default:
        break;
    }
  } catch (error) {
    console.error(error)
  }
}

function* LayoutSaga() {
  yield takeEvery(START_LAYOUT_CHECK, startLayoutSimulation)
  yield takeEvery(CANCEL_LAYOUT_CHECK, _cancelWorkflow)
  yield takeEvery(CHANGE_DESIGN, switchLayoutDesign)
}

export default LayoutSaga