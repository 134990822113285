import { takeEvery, put, call } from "redux-saga/effects";
import { START_IO_SIM_RUN } from "../actionTypes";
import {
  updateSimulationInfo,
  initSimulationInfo
} from "../action";
import { runIoSim } from "../../../../../services/Himalayas";
import { checkVerificationStatus } from "../../../../../services/workflow/workflow";
import { VERIFY_RUNNING, WAITING } from "../../../../../constants/verificationStatus";
import { getInterfaceWorkStatus } from "../../../../../services/project";
import { IO_SIM } from "../../../../../constants/treeConstants";
import { startNewWorkflow } from "../saga";

function* _startRun(action) {
  const { verificationId, workflowTypes } = action;
  try {
    yield put(initSimulationInfo(verificationId));
    yield put(updateSimulationInfo(verificationId, {
      runIconLoading: true,
      startMsg: "==> Start simulating..."
    }));

    // get verification status,if running or waiting, return
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        yield put(updateSimulationInfo(verificationId, {
          runIconLoading: false,
          startMsg: null
        }));
        return;
      }
    }
    //call run api
    const res = yield call(runIoSim, { verificationId, workflowTypes: workflowTypes.map(item => `Himalayas_${item}`) });
    if (!res) {
      yield put(initSimulationInfo(verificationId));
      yield put(updateSimulationInfo(verificationId, {
        endMsg: "==> Simulation failed!"
      }));
      return;
    }
    yield put(updateSimulationInfo(verificationId, {
      runIconLoading: false
    }));
    //get verification workflow info
    const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
    if (interfaceStatus && interfaceStatus.length > 0) {
      //get workflow
      yield call(startNewWorkflow, {
        verificationId,
        interfaceStatus,
        key: IO_SIM
      })
      return
    } else {
      // _initSimulationInfo 
      yield put(initSimulationInfo(verificationId))
      yield put(updateSimulationInfo(verificationId, {
        endMsg: "==> Simulation failed!"
      }));
      return;
    }
  } catch (error) {
    console.error(error);
    yield put(initSimulationInfo(verificationId));
    yield put(updateSimulationInfo(verificationId, {
      endMsg: `==> ${error}`
    }));
  }
}

export function* SimulationIoSimSaga() {
  yield takeEvery(START_IO_SIM_RUN, _startRun);
}