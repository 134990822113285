import { ROCKY } from "../../../constants/pageType";
import _LayoutData from "../../data/LayoutData";
import { autoSplitRefeByPower, getPortData } from "../../helper/portCanvasHelper";
import { getComponents } from "../../helper/setup/setupData";
import { IGNORE } from "../../PCBHelper";
import { RockyPackageCompRLCPrefixLib } from "./setupClass";
import { _getNetsListByComp } from "./setupData";

function getPackageComponent(pcbId) {
  const layout = _LayoutData.getLayout(pcbId);
  const _netList = layout && layout.mNetManager ? layout.mNetManager.GetAllNets().mValues : [];
  const { Components } = getComponents(_netList, pcbId, new RockyPackageCompRLCPrefixLib(), ROCKY);
  const IC = Components.filter(item => item.COMP_TYPE === IGNORE);
  const DIE = IC.find(comp => (comp.name && comp.name.match(/(DIE)/ig)) || (comp.part && comp.part.match(/(DIE)/ig))) || {};
  const BGA = IC.find(comp => (comp.name && comp.name.match(/(BGA)/ig)) || (comp.part && comp.part.match(/(BGA)/ig))) || {};

  return { DIE, BGA }
}

function getDiePortsByCPM({
  packageId,
  component,
  PowerNets,
  ReferenceNets,
  cpmPins
}) {
  const powerCpmPins = cpmPins.filter(item => PowerNets.includes(item.net)).map(item => item.pin);
  const referCpmPins = cpmPins.filter(item => ReferenceNets.includes(item.net)).map(item => item.pin);
  const DesignData = _LayoutData.getLayout(packageId);
  const filterData = getPortData(DesignData, component.name, PowerNets, ReferenceNets);
  const { powerPins, referencePins, data: PCBData } = filterData;
  const powers = Object.values(powerPins).flat(2), refe = Object.values(referencePins).flat(2);
  const pinsInfo = PCBData.getComponent(component.name).mPinsLocationList;

  const powerInfo = pinsInfo.filter(pin => powers.includes(pin.pin) && powerCpmPins.includes(pin.pin))
    .map(pin => ({ ...pin, ...pin.mLocation })),
    refeInfo = pinsInfo.filter(pin => refe.includes(pin.pin) && referCpmPins.includes(pin.pin)).map(pin => ({ ...pin, ...pin.mLocation }));

  const powerInfoWithRefe = autoSplitRefeByPower(powerInfo, refeInfo, [], false);
  let usedReferencePins = []
  let portsTable = powerInfo.map((p, index) => {
    const find = powerInfoWithRefe.find(item => item.pinNumber === p.pin);
    find && usedReferencePins.push(...find.refePins);
    return { port: String(index + 1), powerPins: [p.pin], referencePins: find ? [...find.refePins] : [] }
  });
  let unusedRefPins = refeInfo.filter(item => !usedReferencePins.includes(item.pin));
  let usedRefe = []
  for (let refPin of unusedRefPins) {
    const { xc, yc } = refPin;
    const x = Number(xc), y = Number(yc);
    const location = powerInfo.map(item => {
      let distance = Infinity;
      const _x = Number(item.xc), _y = Number(item.yc);
      distance = Math.sqrt(Math.pow(x - _x, 2) + Math.pow(y - _y, 2))
      return { distance, ...item }
    }).sort((a, b) => a.distance > b.distance ? 1 : -1);
    const powerPin = location.length ? location[0].pin : "";
    const index = portsTable.findIndex(item => item.powerPins.includes(powerPin));
    if (index > -1) {
      const _index = usedRefe.findIndex(item => item.find(it => portsTable[index].referencePins.includes(it)));
      if (_index > -1) {
        usedRefe[_index] = [...new Set([...usedRefe[_index], refPin.pin])];
      } else {
        usedRefe.push([...portsTable[index].referencePins, refPin.pin])
      }
    }
  }
  portsTable.forEach((pin, index) => {
    const findRef = usedRefe.find(item => item.find(it => pin.referencePins.includes(it)));
    if (findRef) {
      pin.referencePins = [...findRef];
    }
  })
  return portsTable;
}

export {
  getPackageComponent,
  getDiePortsByCPM
}