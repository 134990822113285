import React, { PureComponent, Fragment } from 'react';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Tooltip, Popover, Input } from 'antd';
import dayjs from 'dayjs';

class SaveResult extends PureComponent {

  constructor(props) {
    super()
    this.state = {
      saveInputVisible: false,
      currentResultTime: props.currentResultTime ? props.currentResultTime : this.getCurrentTime()
    }
  }

  cancelSaveResult = (e) => {
    e.stopPropagation();
    this.setState({
      saveInputVisible: false,
      saveHistoryError: null
    })
  }

  resultSaveClick = (e) => {
    e.stopPropagation();
    const { currentResultTime } = this.state;
    this.props.saveResult(currentResultTime);
    this.setState({
      saveInputVisible: false,
      currentResultTime: this.props.currentResultTime ? this.props.currentResultTime : this.getCurrentTime()// restore
    })
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  getCurrentTime = () => {
    return dayjs((new Date()).toUTCString()).format('YYYY-MM-DD HH:mm:ss');
  }

  cancelSaveResult = (e) => {
    e.stopPropagation();
    this.setState({
      saveInputVisible: false,
      saveHistoryError: null
    })
  }

  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({
        saveHistoryError: null
      })
    }
    const currentResultTime = this.props.currentResultTime ? this.props.currentResultTime : this.getCurrentTime()
    this.setState({ saveInputVisible: visible, currentResultTime });
  };

  inputNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      currentResultTime: e.target.value,
      saveHistoryError: null
    })
  }

  content = () => {
    const { saveHistoryError, currentResultTime } = this.state;
    return (
      <Fragment>
        <Input
          value={currentResultTime}
          className='save-result-name'
          onChange={(e) => this.inputNameChange(e)}
          ref={(input) => { this.inputRef = input; }}
          onPressEnter={(e) => this.resultSaveClick(e)}
          onClick={(e) => this.inputClick(e)}
          addonAfter={<Fragment>
            <CloseOutlined
              className='aurora-tree-create-icon'
              onClick={(e) => this.cancelSaveResult(e)} />
            <CheckOutlined
              className='aurora-tree-create-icon mg-r-8'
              onClick={(e) => this.resultSaveClick(e)} />
          </Fragment>}
        />
        {saveHistoryError && <span className='save-result-history-error' onClick={(e) => this.inputClick(e)}>{saveHistoryError}</span>}
      </Fragment>
    );
  }


  render() {
    const { saveInputVisible } = this.state;
    return (
      <div className='result-save-div'>
        <span className='aurora-top-bar-vertical-line'></span>
        <Tooltip title='Save To History' overlayClassName='aurora-tooltip' onClick={this.inputClick}>
          <Popover
            content={this.content()}
            trigger="click"
            onClick={this.inputClick}
            open={saveInputVisible}
            onOpenChange={this.handleVisibleChange}
            overlayClassName='save-result-popover'>
            <SaveOutlined className="result-save-icon" />
          </Popover>
        </Tooltip>
      </div>
    );
  }

}

export default SaveResult;
