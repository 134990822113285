const hLineLength1 = 50, polyInfo = { hGap: 5, vGap: 5 }, vLineLength = 15;

function getPolyLinePoints(startX, startY, { hGap, vGap }) {
  let points = [`${startX},${startY}`]
  for (let i = 0; i < 7; i++) {
    const x = startX + hGap * (i + 1)
    const y = startY + (i % 2 ? vGap : -vGap)
    if (i === 6) {
      points.push(`${x},${startY}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   `)
    } else {
      points.push(`${x},${y}`)
    }
  }

  return points.join(" ")
}

function getCoordinateInfo(startX, startY) {
  const otherStartX = startX + 2 * hLineLength1 + 7 * polyInfo.hGap;
  return {
    circleData: {
      cx: startX,
      cy: startY,
      r: 4,
      fill: "none",
      stroke: "black",
      strokeWidth: "1"
    },
    lineData: [
      {
        x1: startX + 4,
        y1: startY,
        x2: startX + hLineLength1,
        y2: startY,
        stroke: "black",
        strokeWidth: "1"
      },
      {
        x1: startX + hLineLength1 + 7 * polyInfo.hGap,
        y1: startY,
        x2: otherStartX,
        y2: startY,
        stroke: "black",
        strokeWidth: "1"
      },
      {
        x1: otherStartX,
        y1: startY,
        x2: otherStartX,
        y2: startY + vLineLength,
        stroke: "black",
        strokeWidth: "1"
      },
    ],
    polylineData: {
      points: getPolyLinePoints(startX + hLineLength1, startY, polyInfo),
      fill: "none",
      stroke: "black",
      strokeWidth: "1"
    },
    polygonData: {
      points: `${otherStartX - 7},${startY + vLineLength} ${otherStartX + 7},${startY + vLineLength} ${otherStartX},${startY + vLineLength + 14}`,
      fill: "none",
      stroke: "black",
      strokeWidth: "1"
    }
  }
}

export { getCoordinateInfo };