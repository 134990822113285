export function rowDisplayChange(resultList, displayMode, value) {
  let _resultList = { ...resultList };
  if (_resultList[displayMode]) {
    let _show = [..._resultList[displayMode].show];
    if (_show.includes(value)) {
      _show = _show.filter(item => item !== value);
    } else {
      _show.push(value);
    }
    _resultList[displayMode].show = _show;
  }
  return _resultList;
}