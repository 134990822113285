import {
  PKG_TOUCHSTONE, PASSIVE_TOUCHSTONE, PASSIVE_SPICE, TRACE, VIA, CONNECTOR_TOUCHSTONE, CABLE_TOUCHSTONE, IBIS_AMI, IBIS, PCB_TOUCHSTONE, SPICE, HFSS_OPTIONS, SIWAVE_OPTIONS, EYE_MASK, GENERIC_TOUCHSTONE, LEQ_RESPONSE,
  PASSIVE,
  LIBRARY_PACKAGE,
  BUFFER,
  EXTRACTION_OPTIONS,
  GENERIC_LIBRARY,
  STRIPLINE,
  MICROSTRIP,
  ADS_OPTIONS,
  BUFFER_SPICE,
} from "../../../constants/libraryConstants";
import { CPHY } from "../../PCBHelper/constants";
import libraryConstructor from './libraryConstructor';
import { getLibraryArray } from "./libraryTree";


function getIBISModelList(libraryModels = {}, model, serdesType) {
  const models = libraryModels.models || [];
  const packages = libraryModels.packages || [];
  const selectorModels = libraryModels.selectorModels || [];

  if (serdesType === CPHY) {
    const pinsGroup = packages.map(item => item.pinSection)
    return getCPHYIBISModelList(pinsGroup)
  }
  /*  component: "HDMI_TX"
      generic:[]
      packageModel: ""
      pinSection: [
        {
      model_name: "HDMI_TX"
      pin: "1"
      signal_name: "PADP"
        }] */
  let pinModelList = [];
  for (let pkgItem of packages) {

    for (let pinItem of pkgItem.diffPins) {
      const findPin = pkgItem.pinSection.find(it => it.pin === pinItem.pin);
      const findInvPin = pkgItem.pinSection.find(it => it.pin === pinItem.invPin);

      const pinModels = getModelsByPin({
        models,
        selectorModels,
        pinInfo: findPin
      });
      const invPinModels = getModelsByPin({
        models,
        selectorModels,
        pinInfo: findInvPin
      });
      if (!pinModels.length || !invPinModels.length) {
        continue;
      }

      let pinModel = pinModels[0].name, invPinModel = invPinModels[0].name;
      if (model && model.pin === findPin.pin) {
        pinModel = model.modelName;
      }

      if (model && model.invPin === findInvPin.pin) {
        invPinModel = model.invModelName;
      }

      pinModelList.push({
        pin: { ...findPin },
        invPin: { ...findInvPin },
        pinModels,
        invPinModels,
        pinModel,
        invPinModel
      })
    }
  }
  return pinModelList;
}

function getCPHYIBISModelList(pinsGroup) {

  const modelGroup = []
  for (const pins of pinsGroup) {
    const signalNames = { A: [], B: [], C: [] }, pinInfoAMap = new Map(), pinInfoBMap = new Map(), pinInfoCMap = new Map();
    // pinInfo : {signal_name,model_name}/{signalName,modelName}
    for (const pinInfo of pins) {
      const signalName = pinInfo.signal_name || pinInfo.signalName || "";
      const modelName = pinInfo.model_name || pinInfo.modelName || "";
      const _pinInfo = { ...pinInfo, signal_name: signalName, model_name: modelName };
      if (signalName.match(/_A\d_/)) {
        pinInfoAMap.set(signalName, _pinInfo)
        signalNames.A.push(signalName)
      } else if (signalName.match(/_B\d_/)) {
        pinInfoBMap.set(signalName, _pinInfo)
        signalNames.B.push(signalName)
      } else if (signalName.match(/_C\d_/)) {
        pinInfoCMap.set(signalName, _pinInfo)
        signalNames.C.push(signalName)
      }
    }

    for (const [key, value] of pinInfoAMap) {
      // _A0_ prefix
      const prefix = key.split(/_A\d_/)[0];
      // cut _A0_
      const index = key.match(/_A\d/)[0].replace(/_A/, '');

      const BPinName = signalNames.B.find(name => name.split(`_B${index}_`)[0] === prefix);
      const CPinName = signalNames.C.find(name => name.split(`_C${index}_`)[0] === prefix);
      const BInfo = pinInfoBMap.get(BPinName);
      const CInfo = pinInfoCMap.get(CPinName);
      if (value && BInfo && CInfo) {
        modelGroup.push({ AInfo: value, BInfo, CInfo })
      }
    }

    return modelGroup;
  }
}

function getModelsByPin({
  models,
  selectorModels,
  pinInfo
}) {
  let pinModels = JSON.parse(JSON.stringify(models.filter(item => item.name === pinInfo.model_name)));
  if (!pinModels.length) {
    const selectors = selectorModels.filter(item => item.selector === pinInfo.model_name);
    for (let sec of selectors) {
      pinModels.push(...JSON.parse(JSON.stringify(sec.models)));
    }
  }
  return pinModels;
}

function getIbisAmiFileList(ibsInfo, fileNames = []) {
  if (!ibsInfo.ibisAmi || !ibsInfo.ibisAmi.amis || !ibsInfo.ibisAmi.amis.length) {
    return []
  }
  return ibsInfo.ibisAmi.amis.map(item => { return { fileName: item.name } }).filter(item => !fileNames.includes(item.fileName));
}

function decapDataType(libraryType) {
  switch (libraryType) {
    case IBIS_AMI:
      return "IBIS AMI"
    case IBIS:
      return "IBIS"
    case HFSS_OPTIONS:
      return "HFSS"
    case SIWAVE_OPTIONS:
      return "SIwave"
    case GENERIC_TOUCHSTONE:
    case PASSIVE_TOUCHSTONE:
    case PKG_TOUCHSTONE:
      return "Touchstone"
    case PASSIVE_SPICE:
    case SPICE:
    case BUFFER_SPICE:
      // case PKG_SPICE:
      return "SPICE"
    default: return;
  }
}

function getPanelLibraryList() {
  const types = [
    TRACE,
    VIA,
    PASSIVE_TOUCHSTONE,
    PASSIVE_SPICE,
    PKG_TOUCHSTONE,
    // PKG_SPICE,
    PCB_TOUCHSTONE,
    CONNECTOR_TOUCHSTONE,
    CABLE_TOUCHSTONE,
    IBIS_AMI,
    IBIS,
    BUFFER_SPICE,
    HFSS_OPTIONS,
    SIWAVE_OPTIONS,
    ADS_OPTIONS,
    EYE_MASK,
    GENERIC_TOUCHSTONE,
    SPICE,
    LEQ_RESPONSE,
  ];
  const libraryList = types.map((item) => {
    return libraryConstructor.getLibraryValues(item); // dataArr
  });
  let newList = []; // new libraryList
  let passiveChildren = [], pkgChildren = [], bufferChildren = [], optionsChildren = [], genericChildren = [];
  for (let item of libraryList) {
    if (item && item.length) {
      switch (item[0].type) {
        case MICROSTRIP:
        case STRIPLINE:
          newList.push({
            key: TRACE,
            name: 'Trace Template',
            children: getLibraryArray(item[0].type, item).map((it) => {
              return { ...it, name: `${it.name} - ${it.type === MICROSTRIP ? 'Microstrip' : 'Stripline'}` }
            })
          });
          break;
        case VIA:
          newList.push({
            key: VIA,
            name: 'Via Template',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case PASSIVE_TOUCHSTONE:
        case PASSIVE_SPICE:
          passiveChildren.push({
            key: item[0].type,
            name: decapDataType(item[0].type),
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case PKG_TOUCHSTONE:
          // case PKG_SPICE:
          pkgChildren.push({
            key: item[0].type,
            name: decapDataType(item[0].type),
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case PCB_TOUCHSTONE:
          newList.push({
            key: PCB_TOUCHSTONE,
            name: 'PCB Model',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case CONNECTOR_TOUCHSTONE:
          newList.push({
            key: CONNECTOR_TOUCHSTONE,
            name: 'Connector Model',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case CABLE_TOUCHSTONE:
          newList.push({
            key: CABLE_TOUCHSTONE,
            name: 'Cable Model',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case IBIS_AMI:
        case IBIS:
        case BUFFER_SPICE:
          bufferChildren.push({
            key: item[0].type,
            name: decapDataType(item[0].type),
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case HFSS_OPTIONS:
        case SIWAVE_OPTIONS:
          optionsChildren.push({
            key: item[0].type,
            name: decapDataType(item[0].type),
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case ADS_OPTIONS:
          newList.push({
            key: ADS_OPTIONS,
            name: 'ADS Options',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case EYE_MASK:
          newList.push({
            key: EYE_MASK,
            name: 'Eye Mask',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        case GENERIC_TOUCHSTONE:
        case SPICE:
          genericChildren.push({
            key: item[0].type,
            name: decapDataType(item[0].type),
            children: getLibraryArray(item[0].type, item)
          })
          break;
        case LEQ_RESPONSE:
          newList.push({
            key: LEQ_RESPONSE,
            name: 'LEQ Response',
            children: getLibraryArray(item[0].type, item)
          });
          break;
        default:
          break;
      }
    }
  }
  newList.push({
    key: PASSIVE,
    name: "Passive Model",
    children: passiveChildren
  });
  newList.push({
    key: LIBRARY_PACKAGE,
    name: "Package Model",
    children: pkgChildren
  })
  newList.push({
    key: BUFFER,
    name: "Buffer Model",
    children: bufferChildren
  })
  newList.push({
    key: EXTRACTION_OPTIONS,
    name: "Extraction Options",
    children: optionsChildren
  })
  newList.push({
    key: GENERIC_LIBRARY,
    name: "Generic Model",
    children: genericChildren
  })
  const addKey = arr => arr.map(item => ({
    ...item,
    title: item.name,
    showTitle: item.name,
    value: item.children ? item.key : item.id,
    isDownload: item.children ? false : true,
    children: item.children ? addKey(item.children) : null
  }))
  let _newList = addKey(newList);

  // check file
  if (_newList[0].children.length) {
    let res = {
      title: 'All',
      value: 'All',
      key: 'All',
      typeName: "Library",
      isDownload: false,
      children: _newList,
    };
    return [res];
  } else {
    return [];
  }
}

function deleteFolderChild(children, deleteId, pathIds) {
  const targetIndex = children.findIndex(item => item.id === deleteId);
  if (targetIndex > -1) {
    return children.filter(item => item.id !== deleteId);
  } else {
    const newChildren = children;
    const childrenIndex = children.findIndex(item => pathIds.includes(item.id));
    if (childrenIndex > -1) {
      newChildren[childrenIndex].children = deleteFolderChild(children[childrenIndex].children, deleteId, pathIds);
      return newChildren;
    } else {
      return children;
    }
  }
}

export {
  getIBISModelList,
  getIbisAmiFileList,
  getPanelLibraryList,
  getCPHYIBISModelList,
  deleteFolderChild
}