import NP from 'number-precision';
import { INSERTIONLOSS, RETURNLOSS, NEXT, FEXT, DIFFTOCOM, COMINSERTION, COMRETURN, CROSSCOUPLING } from '../../../../helper/reportHelper';
import { select } from 'd3-selection';
import { DriverPoints, ReceiverPoints, DriverOrReceiver } from '../../../../Andes_v2/constants';
import { getTextWidth } from '../../../../helper/getTextWidth';

const nameObject = {
  'IL': INSERTIONLOSS,
  'RL': RETURNLOSS,
  'NEXT': NEXT,
  'FEXT': FEXT,
  'DiffToComm': DIFFTOCOM,
  'COMMIL': COMINSERTION,
  'COMMRL': COMRETURN,
  'CrossCoupling': CROSSCOUPLING
}

function checkData(newData, oldData) {
  if (newData === undefined || newData === null) {
    return oldData;
  } else {
    return newData;
  }
}

class ReferenceLine {
  constructor() {
    this.plotId = '';
    this.lineDatas = [];
    this.displayMode = '';
    this.points = [];  // [{x:[],y:[]}]
    this.driverPoints = [];  // [{x:[],y:[]}]
    this.receiverPoints = [];  // [{x:[],y:[]}]
    this.x = [];
    this.y = [];
    this.drawTypes = ['x', 'y', 'points'];
  }

  setValue(props) {
    this.plotId = checkData(props.plotId, this.plotId);
    this.displayMode = checkData(props.displayMode, this.displayMode);
    this.height = checkData(props.height, this.height);
    this.width = checkData(props.width, this.width);
    this.padding = checkData(props.padding, this.padding);
    this.getXValue = checkData(props.getXValue, this.getXValue);
    this.getYValue = checkData(props.getYValue, this.getYValue);
    this.xMin = checkData(props.xMin, this.xMin);
    this.xMax = checkData(props.xMax, this.xMax);
    this.yMin = checkData(props.yMin, this.yMin);
    this.yMax = checkData(props.yMax, this.yMax);
    this.drawTypes = checkData(props.drawTypes, this.drawTypes);

    if (props.isShow !== undefined) {
      this.isShow = props.isShow;
    }

    // control driver/receiver point
    if (props.showPointType !== undefined) {
      this.showPointType = props.showPointType;
    }

    if (props.lineDatas && props.lineDatas.length > 0) {
      this.lineDatas = props.lineDatas;
    }

    if (this.displayMode !== "Default" && this.lineDatas && this.lineDatas.length > 0) {
      const lineData = this.lineDatas.find((item) => item.name === nameObject[this.displayMode])
      if (lineData) {
        const { x = [], y = [], points = [], driverPoints = [], receiverPoints = [] } = lineData;
        this.x = x;
        this.y = y;
        this.points = points;
        this.driverPoints = driverPoints;
        this.receiverPoints = receiverPoints;
      }
    }
  }

  drawLine(root, redrawType) {
    // When switching over the displayMode, the current selection is not displayed
    if (!this.isShow) {
      return;
    }
    // cross line
    if (this.displayMode !== "Default") {
      if (!redrawType) {
        this.plotId && this.removeLine(`${this.plotId}-reference-line`);
        if (!root) {
          return;
        }
        this.lineRoot = root.append('g')
          .attr('id', `${this.plotId}-reference-line`);
        this.drawXLine();
        this.drawYLine();
        this.drawPointsLine();
        this.drawDriverOrReceiverPointsLine([DriverPoints, ReceiverPoints]);
      } else {
        if (typeof redrawType === 'string') {
          this.redrawLine(redrawType);
        } else {
          for (const type of redrawType) {
            this.redrawLine(type);
          }
        }
      }

      this.drawPointsLabelBox();
    }
  }

  redrawLine(redrawType) {
    switch (redrawType) {
      case 'x':
        this.drawXLine();
        break;
      case 'y':
        this.drawYLine();
        break;
      case 'points':
        this.drawPointsLine();
        break;
      case DriverPoints:
      case ReceiverPoints:
        this.drawDriverOrReceiverPointsLine([redrawType]);
        break;
      case DriverOrReceiver:
        this.drawDriverOrReceiverPointsLine([DriverPoints, ReceiverPoints]);
        break;
      default:
        break;
    }
  }

  drawXLine() {
    if (!this.x || this.x.length < 1 || !this.drawTypes.includes('x')) {
      this.removeLine(`${this.plotId}-reference-x`)
      return;
    }

    const { top, left } = this.padding;
    this.removeLine(`${this.plotId}-reference-x`)
    const xRoot = this.lineRoot.append('g')
      .attr('id', `${this.plotId}-reference-x`);
    for (const item of this.x) {
      const realX = NP.strip(NP.times(item, 1e9));
      if (realX >= this.xMin && realX <= this.xMax) {
        const textWidth = getTextWidth(item, 12, null, 500);
        xRoot.append('line')
          .attr('x1', this.getXValue(realX) + left)
          .attr('y1', top)
          .attr('x2', this.getXValue(realX) + left)
          .attr('y2', this.height + top)
          .attr('stroke', '#7a4816')
          .attr('stroke-width', 1.5)
          .attr('stroke-dasharray', "3,3");
        xRoot.append('text')
          .text(item)
          .attr('x', this.getXValue(realX) + left - textWidth / 2)
          .attr('y', top - 6)
          .attr('font-size', '12px')
          .attr('font-weight', 500)
          .attr('fill', '#7a4816');
      }
    }
  }

  drawYLine() {
    if (!this.y || this.y.length < 1 || !this.drawTypes.includes('y')) {
      this.removeLine(`${this.plotId}-reference-y`);
      return;
    }

    const { top, left } = this.padding;
    this.removeLine(`${this.plotId}-reference-y`);
    const yRoot = this.lineRoot.append('g')
      .attr('id', `${this.plotId}-reference-y`);
    for (const item of this.y) {
      const _item = Number(item);
      if (_item >= this.yMin && _item <= this.yMax) {
        yRoot.append('line')
          .attr('x1', left)
          .attr('y1', this.getYValue(item) + top)
          .attr('x2', this.width + left)
          .attr('y2', this.getYValue(item) + top)
          .attr('stroke', '#7a4816')
          .attr('stroke-width', 1.5)
          .attr('stroke-dasharray', "3,3");
        yRoot.append('text')
          .text(item)
          .attr('x', this.width + left + 4)
          .attr('y', this.getYValue(item) + top + 4)
          .attr('font-size', '12px')
          .attr('font-weight', 500)
          .attr('fill', '#7a4816')
      }
    }
  }

  drawPointsLine() {
    if (!this.points || this.points.length < 1 || !this.drawTypes.includes('points')) {
      this.removeLine(`${this.plotId}-reference-points`)
      return;
    }

    const xPoints = [], yPoints = [];
    for (const point of this.points) {
      const { x, y } = point;
      xPoints.push(...x);
      yPoints.push(...y);
    }

    const { top, left } = this.padding;
    this.removeLine(`${this.plotId}-reference-points`)
    const pointsRoot = this.lineRoot.append('g')
      .attr('id', `${this.plotId}-reference-points`);
    for (const point of this.points) {
      const { x, y } = point;
      if (x && y && x.length === 2 && y.length === 2) {
        // Get the render coordinate values
        const realX1 = NP.strip(NP.times(x[0], 1e9)), realX2 = NP.strip(NP.times(x[1], 1e9));
        // Render points within the axis
        if (realX1 >= this.xMin && realX1 <= this.xMax && realX2 >= this.xMin && realX2 <= this.xMax
          && y[0] >= this.yMin && y[0] <= this.yMax && y[1] >= this.yMin && y[1] <= this.yMax) {
          pointsRoot.append('line')
            .attr('x1', this.getXValue(realX1) + left)
            .attr('x2', this.getXValue(realX2) + left)
            .attr('y1', this.getYValue(y[0]) + top)
            .attr('y2', this.getYValue(y[1]) + top)
            .attr('stroke', 'red')
            .attr('stroke-width', 1.5)
            .attr('stroke-dasharray', "3,3");
        }
      }
    }
  }

  getPointInfoByType(type) {
    if (type === DriverPoints) {
      return {
        pointData: this.driverPoints,
        id: `${this.plotId}-reference-driverPoints`,
        color: 'blue',
        pointType: type,
        displayModes: ['RL']
      }
    } else if (type === ReceiverPoints) {
      return {
        pointData: this.receiverPoints,
        id: `${this.plotId}-reference-receiverPoints`,
        color: 'green',
        pointType: type,
        displayModes: ['RL', 'COMMRL', 'DiffToComm']
      }
    }
  }

  drawDriverOrReceiverPointsLine(pointTypes) {
    for (const pointType of pointTypes) {
      const pointsInfo = this.getPointInfoByType(pointType);
      if (!pointsInfo.pointData || pointsInfo.pointData.length < 1 || !this.drawTypes.includes('points') || !this.showPointType || !this.showPointType.includes(pointType) || !pointsInfo.displayModes.includes(this.displayMode)) {
        this.removeLine(pointsInfo.id);
        continue;
      }

      const { top, left } = this.padding;
      this.removeLine(pointsInfo.id);
      const pointsRoot = this.lineRoot.append('g')
        .attr('id', pointsInfo.id);
      for (const point of pointsInfo.pointData) {
        const { x, y } = point;
        if (x && y && x.length === 2 && y.length === 2) {
          // Get the render coordinate values
          const realX1 = NP.strip(NP.times(x[0], 1e9)), realX2 = NP.strip(NP.times(x[1], 1e9));
          // Render points within the axis
          if (realX1 >= this.xMin && realX1 <= this.xMax && realX2 >= this.xMin && realX2 <= this.xMax
            && y[0] >= this.yMin && y[0] <= this.yMax && y[1] >= this.yMin && y[1] <= this.yMax) {
            pointsRoot.append('line')
              .attr('x1', this.getXValue(realX1) + left)
              .attr('x2', this.getXValue(realX2) + left)
              .attr('y1', this.getYValue(y[0]) + top)
              .attr('y2', this.getYValue(y[1]) + top)
              .attr('stroke', pointsInfo.color)
              .attr('stroke-width', 1.5)
              .attr('stroke-dasharray', "3,3");
          }
        }
      }
    }
  }

  getPointLabelInfo(pointsId) {
    switch (pointsId) {
      case `${this.plotId}-reference-points`:
        return { color: 'red', label: 'Channel Spec', labelLeft: 0 }
      case `${this.plotId}-reference-driverPoints`:
        return { color: 'blue', label: 'Driver Spec', labelLeft: -7 }
      case `${this.plotId}-reference-receiverPoints`:
        return { color: 'green', label: 'Receiver Spec', labelLeft: 0 }
      default:
        return {}
    }
  }

  /**
   * Add a black box for the points label tip
   */
  drawPointsLabelBox() {
    const { top, left } = this.padding;
    this.removeLine("reference-line-label-id");
    if (!this.lineRoot) {
      return;
    }

    const existPointsIds = [`${this.plotId}-reference-points`, `${this.plotId}-reference-driverPoints`, `${this.plotId}-reference-receiverPoints`].filter(id => !select(`#${id}`).empty());
    let index = 0
    for (const pointsId of existPointsIds) {
      const labelInfo = this.getPointLabelInfo(pointsId)
      if (select(`#${pointsId}-label`).empty()) {
        const labelRoot = select(`#${pointsId}`).append('g')
          .attr('id', `${pointsId}-label`);
        labelRoot.append("line")
          .attr("x1", this.getXValue(this.xMax) + left - 105)
          .attr("y1", this.getYValue(this.yMax) + top + 15 + index * 20)
          .attr("x2", this.getXValue(this.xMax) - 55)
          .attr("y2", this.getYValue(this.yMax) + top + 15 + index * 20)
          .attr('stroke', labelInfo.color)
          .attr('stroke-width', 1.5)
          .attr('stroke-dasharray', "3,3");
        labelRoot.append("text")
          .attr("x", this.getXValue(this.xMax) + 25 + labelInfo.labelLeft)
          .attr("y", this.getYValue(this.yMax) + top + 20 + index * 20)
          .attr("text-anchor", "middle")
          .attr("fill", "black")
          .attr("font-weight", "bold")
          .text(labelInfo.label);
      }
      index++;
    }

    if (existPointsIds.length > 0) {
      this.lineRoot.append("rect")
        .attr("id", "reference-line-label-id")
        .attr("x", this.getXValue(this.xMax) - 70)
        .attr("y", this.getYValue(this.yMax) + top)
        .attr("width", 150)
        .attr("height", 20 * existPointsIds.length + 10)
        .attr("stroke", "#535353")
        .attr("stroke-width", 1)
        .attr("fill", "none");
    }
  }

  removeLine(id) {
    if (id && !select(`#${id}`).empty()) {
      select(`#${id}`).remove();
    }
  }
}

export default ReferenceLine;