import http from "../http";

/** 
 * Get pre layout content
 * 2023.11.17
*/
export function getSierraPreLayoutInfoById(id) {
  return http.get(`/my-project/my-design/sierra/pre-layout/detail?id=${id}`);
}

/** 
 * update pre layout content
 * @param {object} data {id, name, version }
 * 2023.11.17
*/
export function updateSierraPreLayout(data) {
  return http.post(`/my-project/my-design/sierra/pre-layout`, { ...data });
}

/** 
 * get pre layout content (simulation json)
 * @param {string} verificationId
 * 2025.02.24
*/
export function getPreLayoutByVerificationId(verificationId) {
  return http.get(`/sierra/pre-layout/detail?verificationId=${verificationId}`);
}