var CeGeomTools = function () {}

CeGeomTools.TwoNumberEqual = function (n1, n2) {
  var vmax = Math.abs(n1) > Math.abs(n2) ? Math.abs(n1) : Math.abs(n2);
  var diff = Math.abs(n1 - n2);
  return diff <= vmax * 1.0e-6;
}

CeGeomTools.PointToXaxisAngle = function (x, y) {
  if (this.TwoNumberEqual(y, 0.0)) {
    return x >= 0.0 ? 0.0 : Math.PI;
  }
  if (this.TwoNumberEqual(x, 0.0)) {
    return y >= 0.0 ? 0.5 * Math.PI : 1.5 * Math.PI;
  }

  var angle = Math.atan(Math.abs(y / x));
  if (x < 0 && y > 0)
    angle = Math.PI - angle;
  else if (x < 0 && y < 0)
    angle = Math.PI + angle;
  else if (x > 0 && y < 0)
    angle = 2.0 * Math.PI - angle;

  return angle;
}

export default CeGeomTools;