import { connect } from 'react-redux';
import {
  changePercentAction,
  closeUploadProgressModal,
  addMonitorMsg,
  updateCompressProgress,
  uploadPCBStart,
  translationFlow,
  clearReplacePCBInfo,
  changeUploadDisableStatus
} from './store/action';
import { openTabFooter, changeTabMenu, cleanTabMonitorStatus } from '../../../tabMonitor/action';
import DesignUpload from '@/components/UploadPCB/designUpload'

const mapState = (state) => {
  const { SierraUploadReducer,
    project: {
      currentProjectId
    } } = state.SierraReducer;
  const { disabled, compressProgress } = SierraUploadReducer;

  return {
    disabled,
    compressProgress,
    currentProjectId
  }
}

const mapDispatch = (dispatch) => ({
  _cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus())
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _changeTabMenu({ tabSelectKeys: selectKeys, menuType, currentProjectId: projectId, projectName }) {
    dispatch(changeTabMenu({ selectKeys, menuType, projectId, projectName }))
  },
  _uploadPCBStart(projectId, uploadType) {
    dispatch(uploadPCBStart(projectId, uploadType))
  },
  _updateUploadProgress(progress) {
    dispatch(changePercentAction(progress))
  },
  _updateMsg(msg) {
    dispatch(addMonitorMsg(msg))
  },
  _translationFlow(response, designId) {
    dispatch(translationFlow(response, designId))
  },
  _cleanUploadPCBStatus(endMsg) {
    dispatch(closeUploadProgressModal(endMsg));
  },
  _updateCompressProgress(progress) {
    dispatch(updateCompressProgress(progress))
  },
  clearReplacePCBInfo(designId) {
    dispatch(clearReplacePCBInfo(designId))
  },
  _changeUploadDisableStatus(disabled) {
    dispatch(changeUploadDisableStatus(disabled))
  }

  /*   _updateDesignName(designName, designType) {
      dispatch(updateDesignName(designName, designType))
    } */
})

export default connect(mapState, mapDispatch)(DesignUpload);