import { call, put, takeEvery, delay, select, fork, cancel } from 'redux-saga/effects';
import {
  GET_WORKFLOW_MONITOR,
  GET_VERIFICATION_WORKFLOW,
  GET_VERIFICATION_LOG,
  INIT_SIMULATION_INFO,
  CANCEL_WORKFLOW
} from './actionType';
import monitorLog from '@/services/helper/monitorLog';
import {
  workflowWaitingIndex,
  getMonitor,
  checkVerificationStatus,
  getVerificationWorkFlow
} from '@/services/workflow/workflow';
import {
  updateSimulationReducer,
  updateMultiSimulationInfo,
  updateSimulationInfo,
  getWorkflowMonitor,
  getVerificationLog,
  doeSimulationFinish
} from './action';
import * as taskStatus from '@/constants/workflowStatus';
import { getWorkFlow, cancelVerificationWorkflow } from '@/services/api/v2/workflowCtrl';
import { VERIFY_NEVER, VERIFY_RUNNING, WAITING } from "@/constants/verificationStatus";
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';
import { getTreeResult, getMultiTreeResult } from '@/services/Cascade/PowerTree';
import { CASCADE } from '@/constants/pageType';
import dayjs from 'dayjs';
import { fakeProgress } from '@/services/helper/dataProcess';
import { getCascadeSimulationLog, isExistResult } from '@/services/Cascade/simulation/simulationCtrl'
import { IR_EXPLORER, IMPEDANCE, DESIGN_TREE, POWER_TREE, SINGLE_TREE } from '../../../../constants/treeConstants';
import { newSimulationChannel } from '../project/action';
import { updateIRResultExist } from '../IRExplorer/action';
import { updateImpedanceResultExist } from '../Impedance/action'
import { updateRunning, updateResults, setPowerTreeData, saveSpiceResults } from '../DesignTree/action';
import { saveMultiResults, saveMultiSpiceResults } from '../PowerTree/action';
import { getImpedanceHistorys } from '@/services/Cascade/Impedance';
import { getDesignStackupJson } from "@/services/api/designFile";
import { stackupErrorCheck } from "@/services/Stackup";
import { getMultiTreeSpiceResult, getPowerTreeSpiceResult, getTreeSpiceStatus } from '../../../../services/Cascade/PowerTree';

let workflowTask = null;
export function* startNewWorkflow(action) {
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  yield put(newSimulationChannel(action))
  workflowTask = yield fork(verificationWorkFlow, { ...action });
}

function* doesStopWorkflowPolling() {
  const { LoginReducer: { pageType } } = yield select();
  const { MonitorInfoReducer: { monitorScreenInfo: { currentVerificationId } } } = yield select();
  // The verification ID of the currently open page is not the ID of workflow polling
  // Switch pages
  if (pageType !== CASCADE) {
    return true;
  }
  return false;
}

function* verificationWorkFlow(action) {
  const { verificationId, interfaceStatus } = action;
  if (!interfaceStatus || !interfaceStatus.length) {
    return
  }
  let { id, status, progress, taskList } = interfaceStatus[0];
  const getProfile = shouldGetProfile(taskList);

  yield put(doeSimulationFinish(""))

  const { CascadeReducer: { simulation } } = yield select();
  const _simulation = { ...simulation }
  _simulation[verificationId] = {
    log: null,
    startMsg: "==> Start simulating...",
    progress,
    endMsh: null,
    monitor: null
  }
  yield put(updateSimulationReducer(_simulation))

  try {
    const stopWaiting = yield call(getWaitingIndex, { verificationId, id });
    if (stopWaiting) {
      yield call(updateResultExistStatus, { verificationId });
      return;
    }

    if (progress === 0) {
      progress += 2;
    }
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: "==> Simulation running...\n==> Data processing...",
        progress
      }
    }));

    // simulation running
    const stopWorkflowPolling = yield call(getSimulationRunning, { status, verificationId, id, progress, getProfile })

    if (stopWorkflowPolling) {
      yield call(updateResultExistStatus, { verificationId });
      return;
    }

    yield put(updateSimulationInfo({ verificationId, item: "progress", info: status === taskStatus.SUCCEED ? 100 : -1 }));

    yield put(getWorkflowMonitor(id, verificationId));
    yield call(updateResultExistStatus, { verificationId });
    yield put(doeSimulationFinish(verificationId))

    // simulation complate
    yield put(updateMultiSimulationInfo({
      verificationId, info: {
        progress: -1,
        startMsg: null,
        endMsg: null
      }
    }));

    //get monitor
    // yield delay(3000);
    // yield put(getWorkflowMonitor(id, verificationId));
  } catch (error) {

  }
}

function* getSimulationRunning(action) {
  let { status, verificationId, id, progress, getProfile } = action
  let indexNum = 0;
  while (status === taskStatus.RUNNING) {
    const stopWorkflowPolling = yield call(doesStopWorkflowPolling, { verificationId });
    if (stopWorkflowPolling) {
      return true;
    }

    yield put(getWorkflowMonitor(id, verificationId));

    if (getProfile && indexNum % 6 === 0) {
      yield call(getProfileLog, { verificationId });
    }

    yield delay(5000);

    let _progress = progress;

    try {
      const { data: { data } } = yield call(getWorkFlow, id);
      if (!data) {
        status = taskStatus.FAILED;
      } else {
        status = data.status;
        _progress = data.progress;
      }

      if (status !== taskStatus.RUNNING) {
        yield call(getProfileLog, { verificationId });
      }
    } catch (error) {
      console.error(error)
    }

    indexNum += 1;
    progress = fakeProgress(progress, indexNum, _progress);
    yield put(updateSimulationInfo({ verificationId, item: "progress", info: progress }));
  }
}

function* getWaitingIndex(action) {
  const { verificationId, id } = action;
  let waitingIndex = -1, time;
  waitingIndex = yield call(workflowWaitingIndex, id);
  while (waitingIndex >= 0) { // wait
    const stopWorkflowPolling = yield call(doesStopWorkflowPolling, { verificationId })
    if (stopWorkflowPolling) {
      return true;
    }
    time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: { waitingIndex, waitingTime }
    }));

    yield delay(3000);
    waitingIndex = yield call(workflowWaitingIndex, id);
  }

  while (waitingIndex === -2) { //simulation cancel
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        waitingIndex: -1,
        waitingTime: null,
        startMsg: null,
        progress: -1,
        endMsg: null
      }
    }))
    yield call(updateResultExistStatus, { verificationId });
    yield delay(3000);
    yield put(getWorkflowMonitor(id, verificationId));
    return;
  }

  while (waitingIndex === -3) { //simulation complate
    yield call(updateResultExistStatus, { verificationId });
    // const promise = yield call(checkVerificationStatus, verificationId);
    return;
  }

  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      waitingIndex: -1,
      waitingTime: null
    }
  }));
}

function* _getWorkflowMonitor(action) {
  const { workflowId, verificationId } = action;
  try {
    const log = yield call(getMonitor, workflowId)
    if (log.length > 0) {
      yield put(updateSimulationInfo({ verificationId, item: "monitor", info: monitorLog(log) }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* _getVerificationWorkflow(action) {
  const { verificationId } = action;
  try {
    yield call(_initSimulationInfo, verificationId);
    const promise = yield call(checkVerificationStatus, verificationId);
    const verifyStatus = promise ? promise.status : null;
    //if never run simulation, return
    if (verifyStatus && verifyStatus === VERIFY_NEVER) {
      return;
    }
    if (verifyStatus) {
      yield call(updateResultExistStatus, { verificationId, verifyStatus });
    }

    const verificationStatus = yield call(getVerificationWorkFlow, verificationId);
    if (verificationStatus && verificationStatus.length > 0) {
      yield call(startNewWorkflow, {
        verificationId,
        interfaceStatus: verificationStatus
      })
    } else {
      yield put(getVerificationLog(verificationId));
      // init reducer
    }

  } catch (error) {
    console.error(error)
    yield call(_initSimulationInfo, verificationId);
  }
}

function* _getVerificationlog(action) {
  const { verificationId } = action;
  let _log = '', isLog = false;
  try {
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status !== VERIFY_NEVER) {
        isLog = true;
      }
    }
  } catch (error) {
    console.error(error);
    return;
  }
  if (isLog) {
    try {
      let log = yield call(getCascadeSimulationLog, verificationId)
      if (log) {
        _log = log
      }
      yield call(getProfileLog, { verificationId });
    } catch (error) {
      console.error(error)
    }
  }

  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      log: _log,
      monitor: null
    }
  }))
}

function* _initSimulationInfo(verificationId) {
  yield put(updateMultiSimulationInfo({
    verificationId,
    info: {
      startMsg: null,
      endMsg: null,
      progress: -1,
      waitingIndex: -1,
      waitingTime: null
    }
  }));
}

function* _cancelWorkflow(action) {
  const { verificationId } = action;
  try {
    yield call(cancelVerificationWorkflow, verificationId);
    yield put(updateMultiSimulationInfo({
      verificationId,
      info: {
        startMsg: null,
        endMsg: "==> Simulation cancelled!",
        progress: -1
      }
    }));
    yield delay(5000);
    yield put(updateSimulationInfo({ verificationId, item: "endMsg", info: null }));
  } catch (error) {
    console.error(error);
  }
}

export function* updateResultExistStatus(action) {
  const { verificationId, verifyStatus } = action;
  const { CascadeReducer: { project: { viewList } } } = yield select();
  let resultExist = null;
  const view = viewList.find(v => [IR_EXPLORER, IMPEDANCE, DESIGN_TREE, POWER_TREE, SINGLE_TREE].includes(v));
  switch (view) {
    case IR_EXPLORER:
      try {
        resultExist = yield call(isExistResult, verificationId);
      } catch (error) {
        console.error(error);
      }
      yield put(updateIRResultExist({ id: verificationId, exist: resultExist ? resultExist.exist : false }))
      break;
    case IMPEDANCE:
      try {
        resultExist = yield call(isExistResult, verificationId);
        const historys = yield call(getImpedanceHistorys, verificationId);
        if (resultExist && !resultExist.exist && historys.length) {
          resultExist.exist = true;
        }
      } catch (error) {
        console.error(error);
      }
      const { CascadeReducer: { Impedance: { verificationId: ImpVerificationId } } } = yield select();
      if (verificationId === ImpVerificationId) {
        yield put(updateImpedanceResultExist(resultExist ? resultExist.exist : false, verificationId))
      }
      break;
    case DESIGN_TREE:
    case SINGLE_TREE:
      try {
        const results = yield call(getTreeResult, verificationId);
        const { CascadeReducer: { DesignTree: { verificationId: designVerificationId } } } = yield select();
        if (designVerificationId === verificationId) {
          yield put(updateResults(results ? results : undefined));
        } else {
          yield put(updateResults(undefined));
        }
      } catch (err) {
        console.error(err);
      }
      if (verifyStatus === VERIFY_RUNNING || verifyStatus === WAITING) {
        yield put(updateRunning(true))
      } else {
        yield put(updateRunning(false))
      }
      try {
        const spiceSimulation = yield call(getTreeSpiceStatus, verificationId);
        yield put(setPowerTreeData({ spiceSimulation: spiceSimulation || {} }))
      } catch (err) {
        console.error(err)
      }
      try {
        const results = yield call(getPowerTreeSpiceResult, verificationId);
        const { CascadeReducer: { DesignTree: { verificationId: designVerificationId } } } = yield select();
        if (designVerificationId === verificationId) {
          yield put(saveSpiceResults(results ? results : undefined));
        } else {
          yield put(saveSpiceResults(undefined));
        }
      } catch (err) {
        console.error(err);
      }
      break
    case POWER_TREE:
      try {
        const results = yield call(getMultiTreeResult, verificationId);
        const { CascadeReducer: { DesignTree: { verificationId: designVerificationId } } } = yield select();
        if (designVerificationId === verificationId) {
          yield put(saveMultiResults(results && results.length ? results : []));
        } else {
          yield put(saveMultiResults([]));
        }
      } catch (err) {
        console.error(err);
      }
      if (verifyStatus === VERIFY_RUNNING || verifyStatus === WAITING) {
        yield put(updateRunning(true))
      } else {
        yield put(updateRunning(false))
      }
      try {
        const spiceSimulation = yield call(getTreeSpiceStatus, verificationId);
        yield put(setPowerTreeData({ spiceSimulation: spiceSimulation || {} }))
      } catch (err) {
        console.error(err)
      }
      try {
        const spiceResults = yield call(getMultiTreeSpiceResult, verificationId);
        const { CascadeReducer: { DesignTree: { verificationId: designVerificationId } } } = yield select();
        if (designVerificationId === verificationId) {
          yield put(saveMultiSpiceResults(spiceResults && spiceResults.length ? spiceResults : []));
        } else {
          yield put(saveMultiSpiceResults([]));
        }
      } catch (err) {
        console.error(err);
      }
      break
    default:
      break;
  }
}

function* getProfileLog(action) {
  const { verificationId } = action;
  let profileLog = [];
  try {
    profileLog = yield call(getProfileData, verificationId);
  } catch (error) {
    console.error(error)
  }
  yield put(updateSimulationInfo({ verificationId, item: "profileLog", info: parseProfile(profileLog) }));
}

export function* getStackupErrorCheck({ designId, verificationId, extractionSolver, isTemplate }) {
  //stackup error check
  try {
    let stackupError = null;
    const stackup = yield call(getDesignStackupJson, designId);
    if (!stackup || !stackup.layers) {
      stackupError = [{ error: "Stackup file not exist." }]
    } else {
      stackupError = stackupErrorCheck({
        ...stackup,
        layers: stackup.layers,
        materialList: stackup.materials,
        unit: stackup.unit,
        extractionSolver,
        pageType: CASCADE
      });
    }

    if (stackupError && stackupError.length) {
      if (!isTemplate) {
        yield put(updateMultiSimulationInfo({
          verificationId,
          info: {
            startMsg: null,
            log: null,
            monitor: null,
            endMsg: null,
            stackupError
          }
        }))
      }
      return stackupError;
    } else {
      if (!isTemplate) {
        yield put(updateMultiSimulationInfo({
          verificationId,
          info: {
            stackupError: null
          }
        }))
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* simulationSaga() {
  yield takeEvery(GET_WORKFLOW_MONITOR, _getWorkflowMonitor);
  yield takeEvery(GET_VERIFICATION_WORKFLOW, _getVerificationWorkflow);
  yield takeEvery(GET_VERIFICATION_LOG, _getVerificationlog);
  yield takeEvery(INIT_SIMULATION_INFO, _initSimulationInfo);
  yield takeEvery(CANCEL_WORKFLOW, _cancelWorkflow)
}

export default simulationSaga