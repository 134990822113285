import {
  CHANGE_VERIFICATION_LIST,
  START_ROCKY_VERIFICATION,
  UPDATE_SIMULATION_REDUCER,
  UPDATE_CURRENT_SIMULATION_INFO,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_CURRENT_MONITOR,
  GET_INTERFACE_MONITOR,
  GET_VERIFICATION_LOG,
  UPDATE_SIMULATION_MESSAGE,
  RECALCULATE_EYEDIAGRAM,
  GET_CURRENT_PROFILE,
  START_ROCKY_PACKAGE_VERIFICATION,
  UPDATE_NOT_SET_CARD_VERIFICATIONS,
  START_ROCKY_PACKAGE_PDN,
  UPDATE_CURRENT_MULTI_SIMULATION_INFO,
  START_ROCKY_PCB_CHANNEL,
  START_SSN_SEPARATE_EXTRACTION,
  GET_CURRENT_SSN_VERIFICATION_LOG,
  START_PRE_LAYOUT_SIMULATION
} from './actionTypes';

export const startRockyVerification = (verificationIds, currentVerificationId, ignoreCardModel, isSSN, openSSNVerificationIds,options) => ({
  type: START_ROCKY_VERIFICATION,
  verificationIds,
  currentVerificationId,
  ignoreCardModel,
  isSSN,
  openSSNVerificationIds,
  options
});

export const changeVerificationList = (simulateKeys, simulationType = "simulateKeys") => ({
  type: CHANGE_VERIFICATION_LIST,
  simulateKeys,
  simulationType
});

export const updateSimulationReducer = (info) => ({
  type: UPDATE_SIMULATION_REDUCER,
  info
});

export const updateCurrentSimulationInfo = ({ currentVerificationId, item, info }) => ({
  type: UPDATE_CURRENT_SIMULATION_INFO,
  currentVerificationId,
  item,
  info
});

export const cancelVerificationWorkflow = (verificationId) => ({
  type: CANCEL_VERIFICATION_WORKFLOW,
  verificationId
});

export const getCurrentMonitor = (workflowId, verificationId, dataType, isJump, isMergeVerification) => ({
  type: GET_CURRENT_MONITOR,
  workflowId,
  verificationId,
  dataType,
  isJump,
  isMergeVerification
})

export const getInterfaceMonitor = (verificationId, pageType, isUpdateDiagram, dataType, isJumpType) => ({
  type: GET_INTERFACE_MONITOR,
  verificationId,
  pageType,
  isUpdateDiagram,
  dataType,
  isJumpType
})

export const getVerificationLog = (verificationId, info, mergeVerificationId) => ({
  type: GET_VERIFICATION_LOG,
  verificationId,
  info,
  mergeVerificationId
})

export const updateStimulationMessage = (verificationId) => ({
  type: UPDATE_SIMULATION_MESSAGE,
  verificationId
})

export const recalculateEyediagram = (params) => ({
  type: RECALCULATE_EYEDIAGRAM,
  params
})

export const getCurrentProfile = (verificationId) => ({
  type: GET_CURRENT_PROFILE,
  verificationId
})

export const startRockyPackageVerification = (verificationIds, currentVerificationId) => ({
  type: START_ROCKY_PACKAGE_VERIFICATION,
  verificationIds,
  currentVerificationId
});

export const updateNotSetCardVerifications = (verifications) => ({
  type: UPDATE_NOT_SET_CARD_VERIFICATIONS,
  verifications
})

export const startRockyPackagePDN = (id, verificationId) => ({
  type: START_ROCKY_PACKAGE_PDN,
  id,
  verificationId
})

export const updateCurrentMultiSimulationInfo = ({ currentVerificationId, info }) => ({
  type: UPDATE_CURRENT_MULTI_SIMULATION_INFO,
  currentVerificationId,
  info
});

export const startRockyPCBChannel = (id, verificationId) => ({
  type: START_ROCKY_PCB_CHANNEL,
  id,
  verificationId
})

export const startSeparateExtraction = (obj) => ({
  type: START_SSN_SEPARATE_EXTRACTION,
  obj
})

export const getCurrentSSNVerificationLog = (obj) => ({
  type: GET_CURRENT_SSN_VERIFICATION_LOG,
  obj
})

export const startPreLayoutSimulation = (obj) => ({
  type: START_PRE_LAYOUT_SIMULATION,
  obj
})