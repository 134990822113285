
import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import EyeDiagram from './eyeDiagramRender';
import { getVerificationJson, getHistoryVerificationJson } from '@/services/Andes_v2/results'
import {
  eyeDiagramResult, eyeDiagramItem,
  adsEyeParametersArr, getEyeParamSpec,
  getAdsEyeWidth, getSeaSimEyeParametersArr, adsEyeParametersArrCPHY
} from '../../../../services/Andes_v2/results/eyeDiagram';
import { checkResultStatus, GET, CLEAN } from '@/services/helper/resultStatus';
import debounce from '@/services/helper/debounceFn';
import NP from 'number-precision';
import { getBitRate, getProbeType, TMDS_14, TMDS_2 } from '../../../../services/Andes_v2/AMIModelHelper';
import "@/publicCss/eyeDiagram.css";
import "./index.css";
import { CPHY } from '../../../../services/PCBHelper/constants';

const CURRENT = "current";

class EyeDiagrams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: [{ resultType: CURRENT, eye: {} }],
      signals: { [CURRENT]: [] },
      eyeDiagrams: { [CURRENT]: [] },
      configs: { [CURRENT]: {} },
      contentHeight: null,
      settingHeight: 300,
      probeList: []
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    if (this.props.id) {
      this.getResult();
      this.resize();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const ele = document.getElementById("andes-eye-diagram-box");
    const settingEle = document.getElementById("andes-eye-setting-main");
    if (ele) {
      const height = ele.offsetHeight;
      const width = ele.offsetWidth;
      const settingHeight = settingEle ? settingEle.offsetHeight : 300;
      this.setState({ contentHeight: height, contentWidth: width, settingHeight })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { id, type } = this.props;

    if (id !== prevProps.id || type !== prevProps.type) {
      this.cleanEyeDiagram();
      this.getResult();
      this.resize();
    } else {
      // Update eye diagram result by verification status
      this.updateResult(prevProps.status);
    }
  }

  getResult = () => {
    debounce(() => {
      this.getEyeDiagram(CURRENT, 0, true, true);
    }, 300, false, 'andesEye')();
  }

  updateResult = (prevStatus) => {
    const { status } = this.props;
    const updateStatus = checkResultStatus(prevStatus, status);
    switch (updateStatus) {
      case GET:
        // clean result
        this.cleanEyeDiagram();
        // reload result data
        this.getEyeDiagram(CURRENT, 0, true, true);
        break;
      case CLEAN:
        // clean result
        this.cleanEyeDiagram();
        break;
      default: break;
    }
  }

  cleanEyeDiagram = () => {
    const { id, type } = this.props;
    // Clean cache
    eyeDiagramResult.cleanEyeDiagram([{ id, type }]);
    this.setState({
      selectedList: [{ resultType: CURRENT, eye: {} }],
      signals: { [CURRENT]: [] },
      eyeDiagrams: { [CURRENT]: [] },
      configs: { [CURRENT]: {} }
    });
  }

  getEyeDiagram = async (resultType, selectIndex, clear, first) => {
    if (clear) {
      this.setState({
        selectedList: [{ resultType: CURRENT, eye: {} }],
        signals: { [CURRENT]: [] },
        eyeDiagrams: { [CURRENT]: [] },
        configs: { [CURRENT]: {} }
      }, () => {
        this.updateEyeDiagrams(resultType, selectIndex, first)
      });
    } else {
      this.updateEyeDiagrams(resultType, selectIndex, first)
    }
  };

  updateEyeDiagrams = async (resultType, selectIndex, first) => {
    const { id, isEndToEnd, type, historyList, interfaceType } = this.props;
    this.setupInfo = null;
    if (resultType === CURRENT) {
      this.setupInfo = await getVerificationJson(id, isEndToEnd, type);
    } else if (historyList.find(item => item.id === resultType)) {
      this.setupInfo = await getHistoryVerificationJson(resultType, isEndToEnd, type);
    }
    //if current result not exist and history exist, get history
    if (resultType === CURRENT && first && historyList.length > 0) {
      if (type === "ads" && this.setupInfo) {
        this.setupConfig = isEndToEnd ? this.setupInfo.endToEnd.adsConfig : this.setupInfo.adsConfig;
        this.resultExist = this.setupConfig && Object.keys(this.setupConfig).length ? true : false;

      } else if (type === "compliance" && this.setupInfo) {
        this.setupConfig = isEndToEnd ? this.setupInfo.endToEnd.config : this.setupInfo.config;
        this.resultExist = this.setupConfig && this.setupConfig.analysis ? this.setupConfig.analysis.run_statsim : false;
      } if (type === "hspice" && this.setupInfo) {
        this.setupConfig = this.setupInfo.hspiceConfig;
        this.resultExist = this.setupConfig ? true : false;

      } else {
        this.resultExist = false;
      }
      if (!this.resultExist) {
        resultType = (historyList.find(item => !!item[`${type}Result`]) || {}).id;
        if (!resultType) {
          return;
        }
        this.setState({
          selectedList: [{ resultType, eye: {} }],
          signals: { [resultType]: [] },
          eyeDiagrams: { [resultType]: [] },
          configs: { [resultType]: {} }
        })
        this.setupInfo = await getHistoryVerificationJson(resultType, isEndToEnd, type);
      }
    }
    if (!this.setupInfo) return;

    const currentSignals = isEndToEnd ? this.setupInfo.signals : this.setupInfo.content.signals;
    if (type === "ads") {
      this.setupConfig = isEndToEnd ? this.setupInfo.endToEnd.adsConfig : this.setupInfo.adsConfig;
      this.resultExist = this.setupConfig && Object.keys(this.setupConfig).length ? true : false;
      this.clockBitRateExist = false;
      if (this.setupConfig && this.setupConfig.prbs && [TMDS_14, TMDS_2].includes(this.setupConfig.prbs.type)) {
        this.clockBitRateExist = true;
      }
    } else if (type === "compliance") {
      this.setupConfig = isEndToEnd ? this.setupInfo.endToEnd.config : this.setupInfo.config;
      this.resultExist = this.setupConfig.analysis ? this.setupConfig.analysis.run_statsim : false;
    } else if (type === "hspice") {
      this.setupConfig = this.setupInfo.hspiceConfig;
      this.resultExist = this.setupConfig ? true : false;
    }

    if (!this.resultExist) {
      return;
    }

    let firstId, lastPCBId, lastPCBIndex;
    if (isEndToEnd) {
      const pcbConnections = this.setupInfo.endToEnd.content.pcbConnections;
      firstId = pcbConnections[0].designId;
      lastPCBIndex = pcbConnections.length - 1;
      lastPCBId = pcbConnections[lastPCBIndex].designId;
    }

    let probeList = [];
    let _eyeDiagramList = [];
    if (type === "ads") {
      probeList = ['RX-OUT']
      const eyeProbe = this.setupConfig && this.setupConfig.eyeProbe ? this.setupConfig.eyeProbe : {}
      if (eyeProbe.probeRx) {
        probeList.push("RX-IN")
      }
      if (eyeProbe.probeTx) {
        probeList.push("TX-OUT")
      }
      if (this.resultExist) {
        for (let signal of currentSignals) {
          for (let probe of probeList) {
            _eyeDiagramList.push(new eyeDiagramItem({
              id: id + '_' + signal.name,
              signal: signal.name,
              loading: true,
              adsConfig: this.setupConfig || {},
              firstId,
              lastPCBId,
              isEndToEnd,
              lastPCBIndex,
              config: this.setupConfig.channels ? this.setupConfig.channels.find(it => it.signal === signal.name) : {},
              type,
              probeType: probe,
              interfaceType
            }))
          }
        }
      }
    } else {
      _eyeDiagramList = this.resultExist ? currentSignals.map((signal, index) => new eyeDiagramItem({
        id: id + '_' + signal.name,
        signal: signal.name,
        loading: true,
        adsConfig: this.setupConfig || {},
        firstId,
        lastPCBId,
        isEndToEnd,
        lastPCBIndex,
        config: this.setupConfig.channels ? this.setupConfig.channels.find(it => it.signal === signal.name) : {},
        type,
        interfaceType
      })) : [];
    }

    const { eyeDiagrams, signals, selectedList, configs } = this.state;
    let _selectedList = [...selectedList];
    let _eyeDiagrams = { ...eyeDiagrams }, _signals = { ...signals }, _configs = { ...configs };
    _eyeDiagrams[resultType] = _eyeDiagramList;
    _selectedList[selectIndex].eye = _eyeDiagramList.length > 0 ? _eyeDiagramList[0] : {};
    _configs[resultType] = JSON.parse(JSON.stringify(this.setupConfig));
    _signals[resultType] = currentSignals;

    this.setState({
      signals: _signals,
      eyeDiagrams: _eyeDiagrams,
      selectedList: _selectedList,
      configs: _configs,
      probeList
    }, () => {
      this.getData({ eyeDiagrams: _eyeDiagrams, type, signals: _signals, selectIndex, resultType })
    });
  }

  getData = async ({ eyeDiagrams, type, signals, resultType, selectIndex }) => {
    const { id, isEndToEnd } = this.props;
    let _eyeDiagrams = { ...eyeDiagrams };

    if (!_eyeDiagrams[resultType]) {
      return;
    }
    if (type === "ads") {
      const eyeProbe = this.setupConfig && this.setupConfig.eyeProbe ? this.setupConfig.eyeProbe : {}
      let probeList = [];
      if (eyeProbe.probeRx) {
        probeList.push("ProbeRx")
      }
      if (eyeProbe.probeTx) {
        probeList.push("ProbeTx")
      }
      for (let signal of signals[resultType]) {
        let results = await eyeDiagramResult.getAdsEyeDiagram({
          resultId: `${id}_${type}_${resultType}`,
          id,
          isEndToEnd,
          type,
          signal,
          resultType
        }) || [];
        if (this.props.type !== type) {
          return;
        }
        const findResultSignal = results.find(item => item.signal === signal.name);
        const eyeIndex = _eyeDiagrams[resultType].findIndex(item => item.signal === signal.name && item.probeType === 'RX-OUT');
        if (findResultSignal) {
          _eyeDiagrams[resultType][eyeIndex].imgSrc = findResultSignal.imgSrc;
          _eyeDiagrams[resultType][eyeIndex].parameter = this.setEyeParam(findResultSignal.parameter, type, resultType);
          _eyeDiagrams[resultType][eyeIndex].loading = false;
        } else {
          _eyeDiagrams[resultType][eyeIndex].loading = false;
        }

        if (probeList && probeList.length) {
          for (let probe of probeList) {
            const _results = await eyeDiagramResult.getAdsEyeDiagram({
              resultId: `${id}_${type}_${resultType}_${probe}`,
              id,
              isEndToEnd,
              type,
              signal,
              resultType,
              probe
            }) || [];

            const findResultSignal = _results.find(item => item.signal === signal.name);
            const probeType = getProbeType(probe)
            const eyeIndex = _eyeDiagrams[resultType].findIndex(item => item.signal === signal.name && item.probeType === probeType);
            if (findResultSignal) {
              _eyeDiagrams[resultType][eyeIndex].imgSrc = findResultSignal.imgSrc;
              _eyeDiagrams[resultType][eyeIndex].parameter = this.setEyeParam(findResultSignal.parameter, type, resultType);
              _eyeDiagrams[resultType][eyeIndex].loading = false;
            } else {
              _eyeDiagrams[resultType][eyeIndex].loading = false;
            }

          }
        }

        const { eyeDiagrams: stateEyeDiagrams, selectedList } = this.state;
        let _stateEyeDiagrams = { ...stateEyeDiagrams }, stateSelectedList = [...selectedList];

        _stateEyeDiagrams[resultType] = _eyeDiagrams[resultType];
        if (!stateSelectedList[selectIndex].eye || !Object.keys(stateSelectedList[selectIndex].eye).length) {
          stateSelectedList[selectIndex].eye = _eyeDiagrams[resultType].length > 0 ? _eyeDiagrams[resultType][0] : {};
        }
        this.setState({
          eyeDiagrams: { ..._stateEyeDiagrams },
          selectedList: stateSelectedList
        }, () => {
          this.resize();
        })
      }
    } else if (type === 'compliance') {
      const results = await eyeDiagramResult.getEyeDiagram({ resultId: `${id}_${type}_${resultType}`, id, isEndToEnd, type, signals: signals[resultType], resultType }) || [];

      if (this.props.type !== type) {
        return;
      }
      _eyeDiagrams[resultType].forEach((element, index) => {
        const findResultSignal = results.find(item => item.signal === element.signal);
        if (findResultSignal) {
          element.imgSrc = findResultSignal.imgSrc;
          element.parameter = this.setEyeParam(findResultSignal.parameter, type, resultType);
          element.seaSimParameters = findResultSignal.parameter.parameters
          element.loading = false;
        } else {
          element.loading = false;
        }
      });
      const { eyeDiagrams: stateEyeDiagrams, selectedList } = this.state;
      let _stateEyeDiagrams = { ...stateEyeDiagrams }, stateSelectedList = [...selectedList];

      _stateEyeDiagrams[resultType] = _eyeDiagrams[resultType];
      stateSelectedList[selectIndex].eye = _eyeDiagrams[resultType].length > 0 ? _eyeDiagrams[resultType][0] : {};
      this.setState({
        eyeDiagrams: { ..._stateEyeDiagrams },
        selectedList: stateSelectedList
      }, () => {
        this.resize();
      })
    } else if (type === 'hspice') {
      for (let signal of signals[resultType]) {
        let results = await eyeDiagramResult.getHspiceEyeDiagram({ resultId: `${id}_${type}_${resultType}`, id, isEndToEnd, type, signal, resultType }) || [];
        if (this.props.type !== type) {
          return;
        }
        const findResultSignal = results.find(item => item.signal === signal.name);
        const eyeIndex = _eyeDiagrams[resultType].findIndex(item => item.signal === signal.name);
        if (findResultSignal && eyeIndex > -1) {
          _eyeDiagrams[resultType][eyeIndex].imgSrc = findResultSignal.imgSrc;
          _eyeDiagrams[resultType][eyeIndex].parameter = []
          _eyeDiagrams[resultType][eyeIndex].loading = false;
        } else {
          _eyeDiagrams[resultType][eyeIndex].loading = false;
        }

        const { eyeDiagrams: stateEyeDiagrams, selectedList } = this.state;
        let _stateEyeDiagrams = { ...stateEyeDiagrams }, stateSelectedList = [...selectedList];

        _stateEyeDiagrams[resultType] = _eyeDiagrams[resultType];
        if (!stateSelectedList[selectIndex].eye || !Object.keys(stateSelectedList[selectIndex].eye).length) {
          stateSelectedList[selectIndex].eye = _eyeDiagrams[resultType].length > 0 ? _eyeDiagrams[resultType][0] : {};
        }
        this.setState({
          eyeDiagrams: { ..._stateEyeDiagrams },
          selectedList: stateSelectedList
        }, () => {
          this.resize();
        })
      }
    }
  }

  setEyeParam = (param, type, resultType) => {
    const { interfaceType } = this.props;
    let parameter = null;
    let parametersArr = getSeaSimEyeParametersArr(param.version);
    let dataRate = null;
    if (type === "ads") {
      const { configs } = this.state;
      parametersArr = interfaceType === CPHY ? [...adsEyeParametersArrCPHY] : [...adsEyeParametersArr];
      const _dataRate = configs[resultType] && configs[resultType].prbs ? configs[resultType].prbs.bitRate : null;
      dataRate = getBitRate(_dataRate).bitRate;
    }

    const keys = Object.keys(param)
    if (keys && keys.includes("MaskViolated")) {
      parametersArr.push({ key: 'Eye Violated', valueKey: 'MaskViolated', unit: '' })
    }

    if (param) {
      parameter = parametersArr.map(d => ({
        key: d.key,
        value: d.valueKey === "ui" ? `${param[d.valueKey]} ${d.unit}` : this.getParamItem({ param, key: d.valueKey, unit: d.unit, dataRate, type }),
        spec: getEyeParamSpec(d.valueKey, dataRate),
        disable: d.valueKey === "MaskViolated" && param[d.valueKey] === 1 ? true : false
      }));
    } else {
      parameter = parametersArr.map(d => ({ key: d.key, value: null, spec: null }));
    }
    return parameter;
  };

  getParamItem = ({ param, key, unit, dataRate, type }) => {

    switch (key) {
      case "Width":
      case "WidthAtBER":
      case "EyeOpeningWidth":
        if (type === "ads" && key !== "EyeOpeningWidth") {
          return getAdsEyeWidth({ param, key, unit, dataRate });
        }
        const value = NP.times(parseFloat(param[key]), 1e9) || 0;
        return value.toFixed(3) + ' ns';
      case "Height":
      case "HeightAtBER":
        const _value = NP.times(parseFloat(param[key]), 1e3) || 0;
        return _value.toFixed(1) + ' mV';
      case "JitterPP":
      case "JitterRMS":
      case "CrossingLevel":
      case "MaxJitterInUI":
        return param[key] ? param[key].toExponential(3) : 0;
      case "eye_center_offset":
        let _offsetValue = param.eye_height_center_x - param.eye_center_x;
        const offsetValue = _offsetValue.toFixed(3) + ' UI';
        let eyeCenterOffset = NP.times(parseFloat(_offsetValue), parseFloat(param["ui"]), 1e12);
        const eyeCenterOffsetArr = eyeCenterOffset.toString().split(".");
        if (eyeCenterOffsetArr[1] && eyeCenterOffsetArr[1].length > 3) {
          eyeCenterOffset = eyeCenterOffset.toFixed(3);
        }

        return `${offsetValue} (${eyeCenterOffset} ps)`;
      case "eye_width":
      case "eye_height_center_x":
        const uiWidth = (param[key] ? parseFloat(param[key]).toFixed(3) : 0) + ' ' + unit;
        let widthValue = NP.times(parseFloat(param[key]), parseFloat(param["ui"]), 1e12);
        const widthArr = widthValue.toString().split(".");
        if (widthArr[1] && widthArr[1].length > 3) {
          widthValue = widthValue.toFixed(3);
        }
        return `${uiWidth} (${widthValue} ps)`;
      case "MaskViolated":
      case "CphyMaskViolated":
        return param[key] === 0 ? 'Pass' : 'Fail';
      default:
        return (param[key] ? parseFloat(param[key]).toFixed(3) : 0) + ' ' + unit;
    }
  }

  signalChange = (value, resultType, selectedIndex) => {
    const { eyeDiagrams, selectedList } = this.state;
    let _selectedList = [...selectedList];
    const currentEye = eyeDiagrams[resultType].find(item => item.signal === value) || {};
    _selectedList[selectedIndex].eye = currentEye;
    this.setState({
      selectedList: _selectedList
    });
  }

  signalTreeChange = (key, resultType, selectedIndex) => {
    const { value } = key;
    const [signal, probe] = value.split("~");
    const { eyeDiagrams, selectedList } = this.state;
    let _selectedList = [...selectedList];
    let _probe = probe;
    if (!_probe) {
      _probe = "RX-OUT";
    }
    const currentEye = eyeDiagrams[resultType].find(item => item.signal === signal && item.probeType === _probe) || {};
    _selectedList[selectedIndex].eye = currentEye;
    this.setState({
      selectedList: _selectedList
    });
  }


  getName = (id) => {
    if (id === 'current') {
      return 'Current';
    }
    const { historyList = [] } = this.props;
    const history = historyList.find(d => d.id === id) || {};
    return history.name;
  }

  resultsSelect = (key, selectedIndex) => {
    const { selectedList, eyeDiagrams = {} } = this.state;
    let _selectedList = [...selectedList];

    _selectedList[selectedIndex].eye = eyeDiagrams[key] && eyeDiagrams[key].length > 0 ? eyeDiagrams[key][0] : {};
    _selectedList[selectedIndex].resultType = key;

    this.setState({
      selectedList: _selectedList
    }, () => {
      this.getHistoryResult(key, selectedIndex);
    })
  }

  getHistoryResult = async (key, selectedIndex) => {
    await this.getEyeDiagram(key, selectedIndex);
  }

  addNewEye = () => {
    const { selectedList, eyeDiagrams } = this.state;
    let _selectedList = [...selectedList];
    _selectedList.push({
      resultType: CURRENT,
      eye: eyeDiagrams[CURRENT] && eyeDiagrams[CURRENT].length > 0 ? eyeDiagrams[CURRENT][0] : {}
    })
    this.setState({
      selectedList: _selectedList
    }, () => {
      this.resize();
    })
  }

  closeEye = (selectedIndex) => {
    const { selectedList } = this.state;
    let _selectedList = selectedList.filter((item, index) => index !== selectedIndex);
    this.setState({
      selectedList: _selectedList
    })
  }

  render() {
    const { signals, eyeDiagrams, selectedList, configs, contentHeight, settingHeight, contentWidth, probeList } = this.state;
    const { historyList, type, interfaceType } = this.props;
    const historySelectList = historyList.filter(item => !!item[`${type}Result`]);
    const className = selectedList.length > 1 ? "aurora-result-half-eyediagram" : "";
    const width = contentWidth < 900 ? "100%" : null;
    return (
      <div className='aurora-eye-diagram-main' id="andes-eye-diagram-box">
        {selectedList.length < 2 ? <Button
          className="aurora-eye-diagram-add-div"
          onClick={this.addNewEye}
        >
          <PlusOutlined />
          <span>Comparison Eye Diagram</span>
        </Button> : null}
        <div className="andes-v2-eye-list">
          {selectedList.map((item, index) => (
            <Fragment key={index}>
              {this.resultExist ? <div className={`aurora-result-eyediagram ${className}`} style={{ width }}>
                {selectedList.length > 1 ? <div className="andes-v2-eye-close" title="Close the eye diagram" onClick={() => this.closeEye(index)}>
                  <CloseOutlined className="andes-v2-eye-close-icon" />
                </div> : null}
                {item.eye ? <EyeDiagram
                  eye={item.eye}
                  selectedIndex={index}
                  type={this.props.type}
                  signals={signals[item.resultType] || []}
                  resultType={item.resultType}
                  historySelectList={historySelectList}
                  eyeDiagrams={eyeDiagrams[item.resultType] || []}
                  signalChange={this.signalChange}
                  resultsSelect={this.resultsSelect}
                  getName={this.getName}
                  config={configs[item.resultType]}
                  contentHeight={contentHeight}
                  settingHeight={settingHeight}
                  contentWidth={contentWidth}
                  interfaceType={interfaceType}
                  clockBitRateExist={this.clockBitRateExist}
                  probeList={probeList || []}
                  signalTreeChange={this.signalTreeChange}
                /> : null}
              </div> : null}
            </Fragment>))}
        </div>
      </div>
    );
  }
}


export default EyeDiagrams;