import React, { PureComponent } from 'react';
import { Select, Row, Col, Tooltip } from 'antd';
import ScrollableBar from '@/components/ScrollableBar';
import Selection from './Selection';
import '../index.css';
import StackupMultiZoneButton from './stackupMultiZoneButton';

const Option = Select.Option;

class LayerTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      open: false
    }
  }

  getCheckedLayers = () => {
    const { selections, designID } = this.props;
    if (selections[designID]) {
      return selections[designID].layers || [];
    }
    return [];
  }

  getLayerButton = (layer, i) => {
    const { getColor } = this.props;
    const checked = this.getCheckedLayers().includes(layer);
    const backgroundColor = getColor(layer)
    return (
      <label className="ant-checkbox-wrapper" key={i} onClick={this.selectLayer(layer)}>
        <span className={`ant-checkbox ${checked ? 'ant-checkbox-checked' : ''}`}>
          <input type="checkbox" className='ant-checkbox-input' value={layer} onClick={this.onCheckLayer(layer)} />
          <span className="ant-checkbox-inner" style={{ backgroundColor }}></span>
        </span>
        <span>{layer}</span>
      </label>
    )
  }

  selectLayer = (layer) => (e) => {
    e.preventDefault();
    this.props.selectLayer([layer], this.props.designID)
  }

  onCheckLayer = (layer) => (e) => {
    e.stopPropagation();
    const newList = [...this.getCheckedLayers()];
    const index = newList.indexOf(layer);
    if (index < 0) {
      newList.push(layer);
    } else {
      newList.splice(index, 1);
    }
    this.props.selectLayer(newList, this.props.designID);
  }

  SelectVisibleChange = (open) => {
    this.setState({
      open: open
    })
  }

  visibleChange = (visible) => {
    if (this.state.open) {
      visible = false;
    }
    this.setState({
      visible: visible,
    })
  }

  render() {
    const { metalLayers, stackupObj, designID, changeColorBy, width, leftWidth, ...restProps } = this.props;
    return (
      <Row className='layer-tab'>
        <Col span={16} style={{ padding: '0 10px' }}>
          <ScrollableBar>
            {metalLayers.map(this.getLayerButton)}
          </ScrollableBar>
        </Col>
        <Col span={8} className="pcb-explorer-layer-tab-col">
          <ScrollableBar>
            <div className="pcb-explorer-layer-tab-content">
              {stackupObj && stackupObj[designID] && stackupObj[designID].showZones ?
                <label>
                  <StackupMultiZoneButton {...this.props} {...stackupObj[designID]} />
                </label> : null}
              <label style={{ marginRight: '6 px' }}>
                <Tooltip title='Colored by' overlayClassName='coloredby' open={this.state.visible} mouseEnterDelay={0} mouseLeaveDelay={0} onOpenChange={(visible) => { this.visibleChange(visible) }}>
                  <Select defaultValue="net" size='small' style={{ width: '75px', color: '#fff' }} onChange={(value) => changeColorBy(value, this.props.designID)} onDropdownVisibleChange={this.SelectVisibleChange}>
                    <Option value="net">Net</Option>
                    <Option value="layer">Layer</Option>
                  </Select>
                </Tooltip>
              </label>
              <label>
                <Selection width={width} leftWidth={leftWidth} designID={designID} {...restProps} />
              </label>
            </div>
          </ScrollableBar>
        </Col>
      </Row >
    )
  }
}

export default LayerTab;