import { takeEvery, put, call, select, cancel, delay, fork, take, takeLatest } from "@redux-saga/core/effects";
import {
  GET_DESIGN_CHANNEL_LIST,
  CREATE_CHANNEL,
  DELETE_CHANNEL,
  GET_CHANNEL_CONTENT,
  OPEN_PCB_CHANNEL,
  SAVE_PCB_CHANNEL_TO_SERVER,
  EDIT_COMPONENT_TYPE,
  UPDATE_CHANNEL_CONTENT,
  SAVE_CHANNEL_EXTRACTION_CONFIG,
  AUTO_GET_CHANNEL_LIST,
  ADD_SIGNAL,
  SAVE_SELECT_NETS,
  CHANGE_SIGNAL_NAME,
  DELETE_SIGNAL,
  CHANGE_GROUP_NAME,
  SAVE_CHANNEL_CONFIG,
  CHANNEL_REPLACE,
  CHANNEL_RENAME,
  UPDATE_REFERENCE_NETS,
  COPY_PCB_CHANNEL,
  UPDATE_PORT_SETUPS_TO_SERVER,
  POWER_NETS_SELECTION,
  POWER_NET_UPDATE_VOLTAGE,
  SAVE_COMP_PACKAGE_MODEL,
  CHECK_SIGNALS,
  SAVE_DESIGN_CLIP_INFO,
  SAVE_COMP_CONNECTOR_MODEL,
  DELETE_PWR_GND_NET,
  UPDATE_ADS_CONFIG,
  EDIT_CMC_COMP_PINS_CONNECT,
  SAVE_CMC_MODEL,
  SAVE_COMP_DIODE_MODEL,
  UPDATE_CHANNEL_CONNECTION,
  SAVE_CHANNEL_HYBRID_REGIONS,
  UPDATE_HYBRID_BOUNDARIES,
  SAVE_COMP_PCB_MODEL,
  UPDATE_IC_COMPONENT_PIN,
  UPDATE_DIODE_COMPONENT_PINS,
  EXPAND_CHANNEL,
  CHANGE_SIMULATION_SOLVER,
  UPDATE_HSPICE_CONFIG
} from "./actionTypes";
import {
  createChannelPromise,
  deleteChannelByChannelId,
  getChannelListByDesignId,
  getChannelsTree,
  channelListToTree,
  getDefaultPreLayoutChannelInfo,
  getChannelContentPromise,
  updateChannelContentPromise,
  Extraction,
  getComponents,
  deleteCompsConnectWithNets,
  NEW_GROUP,
  ChannelSignals,
  ChannelCPHYSignals,
  modifyCompType,
  channelSetupReplace,
  updateReplaceMonitor,
  updateComponentsPortSetup,
  copyPCBChannel,
  replacePwrNetsMonitor,
  judgeUpdatePortSetup,
  updateComponentsPackage,
  updateExtractionGapPortsSetup,
  judgeUpdateGapPortSetup,
  updatePortGenerateSetupByComps,
  judgePortGenerateSetupUpdate,
  updatePortSetupByUpdateComp,
  CHECK_BOX_ALL,
  updatePortGenerateSetupForReplace,
  preLayoutChannelSetupReplace,
  saveClipDesignPromise,
  setDefaultModelKeyInConnPkgModel,
  updateComponentAndSignalsByCMC,
  judgeSelectCompExist,
  setSelectCompInComponents,
  judgeReIdentify,
  updateADSAndSeasimComponent,
  getExtractionType,
  updateExtractionInfo,
  updateModelInfoInConnPkgModel,
  handleRefNetsToPowerNets
} from '@/services/Andes_v2/channel';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import designConstructor from '@/services/helper/designConstructor';
import { updateTreeList, openPage, changeCopyLoadingId, updateSelectKeys, updateViewList } from "../project/action";
import {
  getDesignChannelList,
  getChannelContent,
  updateChannelInfo,
  updateChannelContent,
  autoGetChannelList,
  updateChannelReplaceMonitor,
  channelReplace,
  updateChannelName,
  updateReferenceNets,
  openChannelLoading,
  updateGeneratePortsErrors,
  changeChannelCreateVisible,
  updateChannelResultExist,
  updateHybridBoundaries,
  updateHybridInfo,
  updateMultiModelStatus,
  updateSimulationSolverStatus,
  updateHspiceConfigStatus
} from './action';
import { PRE_LAYOUT } from "@/constants/designVendor";
import {
  getPreLayoutInfoById
} from '@/services/Andes_v2/preLayout';
import { getPreLayoutInfo } from "@/services/Andes_v2/preLayout/preLayoutInfo";
import DesignInfo from '@/services/Andes_v2/pcbInfo';
import { PCB_CHANNEL, PCB_CHANNEL_RESULT } from "@/constants/treeConstants";
import designChannelsConstructor from '@/services/Andes_v2/helper/designChannelConstructor';
import { getPowerNets } from "@/services/PCBHelper";
import { changeTabMenu, openTabFooter } from "../../../MonitorStore/action";
import { getVerificationWorkflow, updateSimulationInfo } from '../simulation/action';
import { PROJECTS_INDEX, DESIGN_INDEX, isEndToEndChildrenChannel, isPreLayout } from "@/services/Andes_v2";
import { ANDES_V2 } from "@/constants/pageType";
import { VERIFY_RUNNING, WAITING } from "@/constants/verificationStatus";
import { getDefaultName } from "@/services/helper/setDefaultName";
import { message } from "antd";
import { checkNameFormat } from "@/services/helper/nameFormatCheck";
import { channelErrorCheck } from "../../Channel/errorCheck";
import { getPCBInfo, expandPCB, updateMonitorInfo } from '../project/projectSaga';
import { changeDesign, updateDesignLog } from "../../AndesSider/uploadPCB/store/action";
import { findReferenceNetsBySignal, getReferenceNetsBySignal } from "@/services/api/refNets";
import {
  FIND_REF_NET_RUNNING,
  FIND_REF_NET_SUCCESS,
  FIND_REF_NET_FAILED,
} from "@/constants/findRefNetsConstants";
import { ANDES_V2_CHANNEL_VERSION } from '@/version';
import { versionCompareSize } from "@/services/helper/dataProcess";
import {
  deletePortSetups,
  addPortSetups,
  AutoGeneratePorts,
  clearPortSetups,
  updateCompByPortType,
  PortsGenerationSetup,
  getDefaultPortSetupList,
  getDefaultReferenceNets,
  updateSetupComponentsByPortType,
  GAP,
  getPortGenerateSetupList,
  judgeUpdateGapPortGapSizeSetup,
  updateHFSSExtractionCompsGapSize,
  getUpdateCompBallInfoByPinSize,
  sortPortSetups
} from "@/services/ExtractionPortsHelper";
import { SERDES_TYPES } from "@/services/PCBHelper";
import channelSetupInfo from '@/services/Andes_v2/channel/channelInfo';
import { checkEndToEndByPCBChannel, updateEndToEndReplaceMonitor } from "../endToEndChannel/action";
import {
  updateEndToEndByChannelDeletedSignals,
  updateEndToEndByChannelComponent,
  updateEndToEndByComponents,
  updateEndToEndByChannelRenamedSignal,
  getEndToEndChildrenChannelList,
  uncheckEndToEndAdsUsePkg,
  updateEndToEndPinMap
} from '../endToEndChannel/endToEndChannelSaga';
import { CMC, getComponentByName } from "../../../../services/PCBHelper";
import {
  judgeSeaSimConfigUpdate,
  SeaSimConfig,
  getSeaSimChannels,
  SeaSimChannel,
  updateSignalNameInSeaSimConfig,
  delSignalInSeaSimConfig,
  updateSeaSimConfigComp,
  judgeSeasimConfigEQValue,
  updateSeasimConfigEQ,
  updateSeasimEQConfig,
  updateSeasimConfigInfo,
  judgeSeasimConfigGeneralValue,
  updateAdsConfigDFEValue
} from "../../../../services/Andes_v2/seaSim";
import { HDMI, PCIE, GENERIC, CPHY } from "../../../../services/PCBHelper/constants";
import { savePreLayoutContentToServer } from "../preLayout/preLayoutSaga";
import { startChannelExtraction } from "../simulation/extraction/action";
import { isExistResult } from '../../../../services/Andes_v2/extractionCtrl';
import { generateBoundaryList } from "../../../../services/Andes_v2/channel/cutDesignHelper";
import {
  ADSConfig,
  AMISignalConfig,
  delSignalInAdsConfig,
  updateSignalNameInAdsConfig,
  getSignalAmiCompBySignalName,
  judgeJittersUnitUpdate,
  updateAdsJittersUnit,
  judgeAMIFlagExist,
  updateSignalAMIFlags,
  updateAdsConfigRXModelEQ,
  unCheckAmiModelUsePkg,
  updateTerminationModelPinMap
} from '../../../../services/Andes_v2/AMIModelHelper';
import {
  getAMIComponents,
  setDefaultAMIModel,
  getDefaultAdsSignals,
  updateAdsConfigComp
} from '../../../../services/Andes_v2/channel/AMIModelHelper';
import { updateAggressorsBySelectedSignals } from '../../../../services/Andes_v2/aggressors';
import channelConnection from "../../../../services/Andes_v2/channel/channelConnection";
import { eyeDiagramResult } from '@/services/Andes_v2/results/eyeDiagram';
import { getCMCPinConnections } from "../../../../services/PCBHelper/net";
import { getDesignHybridInfoPromise, saveDesignHybridInfoPromise } from "../../../../services/helper/cutDesign/hybrid";
import HybridRegions from "../../../../services/helper/cutDesign/hybrid/hybridExtraction";
import { PACKAGE, PCB } from "../../../../constants/treeConstants";
import { PACKAGE as PCB_PACKAGE } from '../../../../constants/designType';
import { PACKAGE_INDEX } from "../../../../services/Andes_v2";
import { getViewListBySelectedKey } from "../../../../services/helper/filterHelper";
import { BGA, CONNECTOR, DIODE, DIE } from "../../../../constants/componentType";
import { HFSSInfo } from '@/services/Andes_v2/channel/IntegratedChannel';
import _LayoutData from "../../../../services/data/LayoutData";
import { BALL_TYPE_NONE, BALL_TYPE_NONE_LIST, USE_BALL_LIST } from "../../../../services/ExtractionPortsHelper/portTableHelper";
import { getSweepsTree, sweepListToTree, getSweepListByChannelIdPromise } from "../../../../services/Andes_v2/sweep";
import sweepConstructor from "../../../../services/Andes_v2/sweep/sweepConstructor";
import { updatePortAvoidSinglePinGroup } from "../../../../services/Andes_v2/channel";
import { getSignalElementType } from "../../../../services/Andes_v2/channel/signalsIdentification";
import { CPHYIBISSignalConfig } from "../../../../services/Andes_v2/AMIModelHelper/IntegratedConfig";
import { delSignalInHspiceConfig, HSPICE, HspiceSignalConfig, initChannelHspiceConfig, updateHSPICEModel, updateHspiceConfigComp, updateSignalNameInHspiceConfig, updateHSPICEPin, updateHSPICECompType } from "../../../../services/Andes_v2/simulation";

function* _createNewChannel(action) {
  const { data, designId, generationPortErrors = null, generationPortWarnings = null } = action;
  let content = action.content || {}, netInfo = action.netInfo || {};
  const { AndesV2Reducer: { preLayout: { preLayoutId } } } = yield select();
  let seasimConfig = {};

  if (data.designVendor === PRE_LAYOUT) {
    if (preLayoutId === designId) {
      yield call(savePreLayoutContentToServer);
    }
    //set pre layout default channel
    let preLayoutInfo = {};
    try {
      preLayoutInfo = yield call(getPreLayoutInfo, designId);
    } catch (error) {
      console.error(error);
      return;
    }

    content = getDefaultPreLayoutChannelInfo(preLayoutInfo);
    netInfo = { isPreLayoutChannel: true }
  }


  if (data.type === PCIE) {
    const { channels, analysisChannels } = getSeaSimChannels({
      signals: content.signals,
      components: content.components,
      selectedSignals: content.selectedSignals,
      ...netInfo
    });
    seasimConfig = new SeaSimConfig({ channels, analysisChannels })
  }

  let adsConfig = {};
  if ([PCIE, HDMI, GENERIC, CPHY].includes(data.type)) {
    const { controller, device } = getAMIComponents(content.components);
    const adsSignals = getDefaultAdsSignals({
      signals: content.signals,
      ICComps: content.components.filter(item => SERDES_TYPES.includes(item.type)),
      controller,
      device,
      selectedSignals: content.selectedSignals,
      ...netInfo,
      serdesType: data.type
    });
    adsConfig = new ADSConfig({
      serdesType: data.type,
      signals: adsSignals,
      controller,
      device
    });
  }

  let _data = { ...data };
  delete _data.component;
  delete _data.designVendor;

  //content error check
  const error = yield call(channelErrorCheck, { designId, content })

  try {
    const res = yield call(createChannelPromise, {
      designId,
      ..._data,
      config: seasimConfig,
      adsConfig,
      content: { ...content },
      readyForSim: error ? 0 : 1
    });
    if (res) {
      /* if (vendor !== PRE_LAYOUT) {
        yield fork(generateBoundary, {
          verificationId: res.verificationId,
          port_setups: content.port_setups || [],
          ports_generate_setup_list: content.ports_generate_setup_list || [],
          signals: content.signals || [],
          selectedSignals: content.selectedSignals || [],
          designId,
          referenceNets: content.referenceNets
        })
      } */
      yield put(updateGeneratePortsErrors({ id: res.id, errors: generationPortErrors, warnings: generationPortWarnings }))
      channelConstructor.addChannel(res.id, res);
      const design = designConstructor.getDesign(designId) || {};
      yield put(getDesignChannelList({
        designs: [{ id: designId }],
        projectId: design.projectId,
        designType: design.type === PCB_PACKAGE ? PACKAGE : PCB
      }));
      //open channel
      yield put(openPage({ pageType: PCB_CHANNEL, id: res.id }));
    }
    yield put(changeChannelCreateVisible(false));
  } catch (_error) {
    yield put(changeChannelCreateVisible(false));
    console.error(_error);
    message.error("Channel creation failed!");
  }
}

/* function* generateBoundary({ verificationId, port_setups, ports_generate_setup_list, signals, selectedSignals, designId, clipSize = 3, referenceNets }) {
  const clipData = yield call(generateBoundaryList, { clipSize, signals, selectedSignals, designId, port_setups, ports_generate_setup_list, referenceNets });
  if (clipData && clipData.data) {
    try {
      yield call(saveDesignClip, { info: clipData.data.toDesignClipObject(), verificationId });
    } catch (error) {
      console.error(error);
    }
  }
} */

export function* getDesignChannels(action) {
  const { data: { designs, projectId, afterUploadPCBId, _treeItems, addDesignToCache, addChannelToCache, isSaveChannel, designType } } = action;
  const { AndesV2Reducer: { project, channel: { channelId }, channelResultReducer } } = yield select();
  const { selectedKeys, viewList, expandedKeys } = project;
  let treeList = _treeItems ? [..._treeItems] : [...project.treeItems], allSimChannelIds = [];
  if (addDesignToCache) {
    designConstructor.addDesigns(designs);
  }

  if (addDesignToCache) {
    //clear prev project pcb log
    yield put(updateDesignLog({
      log: null,
      designId: null
    }))
  }

  yield* designs.map(function* (pcb, index) {
    if (addDesignToCache) {
      if (index === 0 && !afterUploadPCBId) {
        yield put(changeDesign(pcb.id));
      }
    }
    try {
      const resChannels = yield call(getChannelListByDesignId, pcb.id) || [];
      const channels = getChannelsTree(resChannels);
      //find channel by status is RUNNING or WAITING
      const simChannels = channels.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status));
      allSimChannelIds = [...new Set([...allSimChannelIds, ...simChannels.map(item => item.id)])];
      //add channels to _treeItems
      treeList = channelListToTree({ _treeItems: treeList, projectId, designId: pcb.id, channels, designType });

      // addChannelToCache && resChannels.forEach(item => {
      //   //save to cache
      //   channelConstructor.addChannel(item.id, item);
      // });
      if (addChannelToCache) {
        for (const item of resChannels) {
          //save to cache
          channelConstructor.addChannel(item.id, item);
          const isExpand = expandedKeys.find(key => key === `${PCB_CHANNEL}-${item.id}`)
          if (item.hasSweep && isExpand) {
            const sweepList = sweepConstructor.getSweepValues(item.id);
            if (sweepList && sweepList.length > 0) {
              const sweepInfo = getSweepsTree(sweepList);
              treeList = sweepListToTree({ treeItems: _treeItems, projectId, designId: pcb.id, channelId: item.id, sweepInfo });
            } else {
              const sweepList = yield call(getSweepListByChannelIdPromise, item.id);
              const sweepInfo = getSweepsTree(sweepList);
              sweepConstructor.addSweeps(sweepList);
              treeList = sweepListToTree({ treeItems: _treeItems, projectId, designId: pcb.id, channelId: item.id, sweepInfo });
            }
          }
        }
      }
      //save
      designChannelsConstructor.set(pcb.id, resChannels.map(d => d.id));
    } catch (error) {
      console.error(error);
    }
  });
  yield put(updateTreeList({ treeItems: [...treeList] }));

  if (afterUploadPCBId) {
    yield put(changeDesign(afterUploadPCBId));
    yield call(expandPCB, { designId: afterUploadPCBId });
  }
  if (allSimChannelIds.length) {
    //if channels exist running or waiting, auto get channel list
    yield put(autoGetChannelList({ projectId }));
  }

  //get channel simulation status and workflow
  let _channelId = null;
  if (viewList.includes(PCB_CHANNEL) && selectedKeys.includes(`${PCB_CHANNEL}-${channelId}`)) {
    _channelId = channelId;
  }
  if (viewList.includes(PCB_CHANNEL_RESULT) && selectedKeys.includes(`${PCB_CHANNEL_RESULT}-${channelResultReducer.channelId}`)) {
    _channelId = channelResultReducer.channelId;
  }
  const channel = channelConstructor.getChannel(_channelId) || {};

  if (!isSaveChannel && channel && channel.id) {
    yield put(getVerificationWorkflow({
      workType: PCB_CHANNEL,
      channelId: _channelId,
      verificationId: channel.verificationId
    }))
  }
}

function* _delChannel(action) {
  const { data: { id, designId } } = action;
  const { AndesV2Reducer: { project: { selectedKeys }, channel: { channelId } } } = yield select();
  try {
    yield call(deleteChannelByChannelId, id);
    //clear cache
    const channel = channelConstructor.getChannel(id);
    designChannelsConstructor.delChannel(channel.designId, id);
    channelConstructor.delChannel(id);
    sweepConstructor.delSweepsByChannelId(id)

    if (id === channelId) {
      //clear info
      yield put(updateChannelInfo({}));
      let _selectedKeys = [...selectedKeys];
      _selectedKeys = _selectedKeys.filter(d => d !== `${PCB_CHANNEL}-${id}`)
      yield put(updateSelectKeys(_selectedKeys));
      yield put(updateViewList(getViewListBySelectedKey(_selectedKeys)));
      //update open channel or end to end channel monitor info
      yield call(updateMonitorInfo, { selectedKeys: _selectedKeys });
    }
  } catch (error) {
    console.error(error);
  }
  const design = designConstructor.getDesign(designId) || {};
  yield put(getDesignChannelList({
    designs: [{ id: designId }],
    projectId: designConstructor.getDesign(designId).projectId,
    designType: design.type === PCB_PACKAGE ? PACKAGE : PCB
  }));
}

let autoSaveTask = null;
function* _openChannel(action) {
  const { id, simulating } = action;
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
  }
  yield call(saveChannelContentToServer);
  yield put(getChannelContent(id));
  const channel = channelConstructor.getChannel(id);
  yield put(openTabFooter());
  let verificationName = channel ? channel.name : null;
  //end to end channel children single channel, display name is pcb name
  if (isEndToEndChildrenChannel(channel)) {
    verificationName = channel.pcbName;
  }
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: channel ? channel.verificationId : null,
    verificationName,
    menuType: "simulation"
  }));
  if (!simulating && channel) {
    yield put(getVerificationWorkflow({
      channelId: id,
      workType: PCB_CHANNEL,
      verificationId: channel.verificationId
    }));
  }
}

function* _getContent(action) {
  const { id } = action;
  yield put(openChannelLoading(true));
  //update hybrid regions - clear prev channel hybrid regions
  yield put(updateHybridInfo({ hybridLines: [], hybridRegions: [], id: null }))
  let res = null;
  try {
    res = yield call(getChannelContentPromise, id);
  } catch (error) {
    console.error(error);
  }

  let adsConfig = res.adsConfig;
  // adsConfigs is empty
  if (res && (!res.adsConfig || Object.keys(res.adsConfig).length === 0)) {
    let netInfo = {};
    if (isPreLayout(res.designId)) {
      netInfo = { isPreLayoutChannel: true }
    } else {
      const { netsList } = DesignInfo.getPCBInfo(res.designId) || {};
      netInfo = { netsList };
    }

    const content = res.content;
    if ([PCIE, HDMI, GENERIC, CPHY].includes(res.type)) {
      const { controller, device } = getAMIComponents(content.components);
      const adsSignals = getDefaultAdsSignals({
        signals: content.signals,
        ICComps: content.components.filter(item => SERDES_TYPES.includes(item.type)),
        controller,
        device,
        selectedSignals: content.selectedSignals,
        ...netInfo,
        serdesType: res.type
      });
      adsConfig = new ADSConfig({
        serdesType: res.type,
        signals: adsSignals,
        controller,
        device
      });
    }
  }

  yield put(updateChannelInfo({ ...res, adsConfig }));
  if (!res) {
    return;
  }

  //is exist result
  let resultExist = null;
  try {
    resultExist = yield call(isExistResult, res.verificationId);
    // get hybrid info
    if (res.content && res.content.extraction && res.content.extraction.hybrid && res.content.extraction.type && res.content.extraction.type === "SIwave") {
      const info = yield call(getHybridRegionInfo, { verificationId: res.verificationId });
      if (info) {
        yield put(updateHybridInfo({
          hybridLines: info.hybrid_lines,
          hybridRegions: info.hybrid_regions,
          id: res.id
        }));
      }
    }
  } catch (error) {
    console.error(error);
  }
  yield put(updateChannelResultExist({ id: res.id, exist: resultExist ? resultExist.exist : false }))
  autoSaveTask = yield fork(autoSave);
  yield call(getPCBInfo, { designId: res.designId });
  const pcbInfo = DesignInfo.getPCBInfo(res.designId);

  //channel setup update by version
  const {
    findRefNetsStatus,
    save,
    isReplace,
    channelInfo
  } = yield call(channelSetupUpdate, { channelInfo: res, pcbInfo })

  if (save) {
    yield put(updateChannelInfo(channelInfo || {}));
  }

  //pcb replace
  if (isReplace) {
    yield put(channelReplace({ channelInfo, isUpdate: true }));
  } else if (findRefNetsStatus) {
    //find reference nets
    yield put(updateReferenceNets());
  } else if (save) {
    yield call(saveChannelContentToServer);
  }

  if (!isReplace) {
    yield put(openChannelLoading(false));
    // yield put(updateChannelConnection());
  }

}

function* _getPCBInfo({ designId }) {
  //port setups generate
  yield call(getPCBInfo, { designId });
  return DesignInfo.getPCBInfo(designId) || { netsList: [], layers: [] };
}

export function* channelSetupUpdate({ channelInfo, pcbInfo }) {
  const isPreLayoutChannel = isPreLayout(channelInfo.designId)

  let findRefNetsStatus = false, save = false;
  //find reference nets
  if (versionCompareSize(channelInfo.version, "0.0.2") && !isPreLayoutChannel) {
    findRefNetsStatus = true;
  }

  //Compatible with the old version,( not exist selectedSignals )
  if (channelInfo.content && !channelInfo.content.selectedSignals) {
    channelInfo.content.selectedSignals = channelInfo.content.signals ?
      channelInfo.content.signals.map(item => item.name) : [];
    save = true;
  }

  //component set default package model
  if (versionCompareSize(channelInfo.version, "0.0.4")) {
    channelInfo = updateComponentsPackage(channelInfo);
    save = true;
  }

  const design = designConstructor.getDesign(channelInfo.designId);
  const isReplace = design && design.designVersion !== channelInfo.designVersion;
  if (isReplace) {
    return { channelInfo, isReplace, save, findRefNetsStatus };
  }

  //port setup not exist
  if (!isPreLayoutChannel && (versionCompareSize(channelInfo.version, "0.0.3") || judgeUpdatePortSetup(channelInfo))) {
    const _pcbInfo = pcbInfo ? pcbInfo : yield call(_getPCBInfo, { designId: channelInfo.designId });

    const info = updateComponentsPortSetup(channelInfo, _pcbInfo);
    channelInfo = info.channelInfo;
    yield put(updateGeneratePortsErrors({ id: channelInfo.id, errors: info.errors, warnings: info.warnings }));
    save = true;
  }

  //update port generate setup by components, "ports_generation_setup" to "ports_generate_setup_list"
  if (!isPreLayoutChannel && (versionCompareSize(channelInfo.version, "0.0.6") || judgePortGenerateSetupUpdate(channelInfo))) {
    channelInfo = updatePortGenerateSetupByComps(channelInfo);
    save = true;
  }

  //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  //re generate port setups by port type is GAP, reference type is All pins
  if (!isPreLayoutChannel && judgeUpdateGapPortSetup(channelInfo)) {
    const resInfo = updateExtractionGapPortsSetup(channelInfo);
    channelInfo = resInfo.channelInfo;
    yield put(updateGeneratePortsErrors({ id: channelInfo.id, errors: resInfo.errors, warnings: resInfo.warnings }));
    save = true;
  }

  //init pcie seaSim config
  if (channelInfo.type === PCIE && (versionCompareSize(channelInfo.version, "0.0.7") || judgeSeaSimConfigUpdate(channelInfo))) {
    const _pcbInfo = isPreLayoutChannel ? {} : (pcbInfo ? pcbInfo : yield call(_getPCBInfo, { designId: channelInfo.designId }));

    const { channels, analysisChannels } = getSeaSimChannels({
      signals: channelInfo.content.signals,
      components: channelInfo.content.components,
      netsList: _pcbInfo.netsList,
      layers: _pcbInfo.layers,
      selectedSignals: channelInfo.content.selectedSignals,
      isPreLayoutChannel
    });
    channelInfo.config = new SeaSimConfig({ channels, analysisChannels });
    save = true;
  }

  //update port generate setup for components setup, gap_size,ball_size,ball_height
  if (!isPreLayoutChannel && (versionCompareSize(channelInfo.version, "0.0.9") && channelInfo.content && channelInfo.content.components)) {
    channelInfo.content.components = updateSetupComponentsByPortType({
      components: channelInfo.content.components,
      designId: channelInfo.designId,
      ports_generate_setup_list: channelInfo.content.ports_generate_setup_list,
      extractionType: getExtractionType(channelInfo.content.extraction),
      isUsedBall: true
    });
    save = true;
  }

  //add backdrillVias ,backdrillStubSize to extraction
  if (!isPreLayoutChannel && versionCompareSize(channelInfo.version, "0.0.11") && channelInfo.content && channelInfo.content.extraction) {
    channelInfo.content.extraction = { ...channelInfo.content.extraction, backdrillVias: true, backdrillStubSize: "8mil" }
    save = true;
  }

  //Re-use the same sub-model for other signals (connector and package)
  if (versionCompareSize(channelInfo.version, "0.0.12") && channelInfo.content) {
    channelInfo = setDefaultModelKeyInConnPkgModel(channelInfo);
    save = true;
  }

  //set default ami model
  if ([PCIE, HDMI, GENERIC, CPHY].includes(channelInfo.type) && channelInfo.content && (versionCompareSize(channelInfo.version, "0.0.14") || !channelInfo.adsConfig || !Object.keys(channelInfo.adsConfig).length)) {
    const _pcbInfo = isPreLayoutChannel ? {} : (pcbInfo ? pcbInfo : yield call(_getPCBInfo, { designId: channelInfo.designId }));
    channelInfo = setDefaultAMIModel(channelInfo, { isPreLayoutChannel, netsList: _pcbInfo.netsList, serdesType: channelInfo.type });
    save = true;
  }

  if ([PCIE, HDMI, GENERIC].includes(channelInfo.type) && channelInfo.adsConfig && judgeJittersUnitUpdate(channelInfo.adsConfig)) {
    channelInfo.adsConfig = updateAdsJittersUnit(channelInfo.adsConfig);
    save = true;
  }

  //judge extraction update - enableLogSweep, timeout, errorTolerance, maxSolution
  if (!isPreLayoutChannel && channelInfo.type === PCIE && channelInfo.content && (versionCompareSize(channelInfo.version, "0.0.15") || (channelInfo.content && !Object.keys(channelInfo.content.extraction).includes("enableLogSweep")))) {
    channelInfo.content.extraction = {
      ...channelInfo.content.extraction,
      enableLogSweep: true,
      errorTolerance: "0.005",
      maxSolution: "250"
    }
    save = true;
  }

  if (channelInfo.content && (versionCompareSize(channelInfo.version, "0.0.16") || judgeSelectCompExist(channelInfo.content.components))) {
    channelInfo = setSelectCompInComponents(channelInfo);
    save = true;
  }

  //set hdmi ami model
  if ([HDMI, GENERIC].includes(channelInfo.type) && channelInfo.content && (!channelInfo.adsConfig || !Object.keys(channelInfo.adsConfig).length)) {
    const _pcbInfo = isPreLayoutChannel ? {} : (pcbInfo ? pcbInfo : yield call(_getPCBInfo, { designId: channelInfo.designId }));
    channelInfo = setDefaultAMIModel(channelInfo, { isPreLayoutChannel, netsList: _pcbInfo.netsList, serdesType: channelInfo.type });
    save = true;
  }

  //add default "IbisHasAMI" field
  if ([PCIE, HDMI, GENERIC].includes(channelInfo.type) && (versionCompareSize(channelInfo.version, "0.0.17") && judgeAMIFlagExist(channelInfo.adsConfig))) {
    channelInfo.adsConfig = updateSignalAMIFlags(channelInfo.adsConfig);
    save = true;
  }

  //if "Equalizer Adaptation" is turned off, update [TX_PRE_SHOOT, TX_DEEMPHASIS,LEQ_DC,LEQ_POLE,LEQ_DC2,LEQ_POLE2] value from array to string. 
  if (channelInfo.type === PCIE && judgeSeasimConfigEQValue(channelInfo.config)) {
    channelInfo.config = updateSeasimConfigEQ(channelInfo.config);
    save = true;
  }
  //Compatible with older versions of config, remove unnecessary fields according to "Equalizer Adaptation". Add required fields
  if (channelInfo.type === PCIE && versionCompareSize(channelInfo.version, "0.0.19")) {
    const returnInfo = updateSeasimEQConfig(channelInfo.config);
    channelInfo.config = returnInfo.config;
    save = returnInfo.save || save;
  }

  // Compatible with older versions of config, ....
  if (channelInfo.content && (versionCompareSize(channelInfo.version, "0.0.20") && !isPreLayoutChannel)) {
    channelInfo.content.extraction = updateExtractionInfo(channelInfo.content.extraction)
    save = true
  }

  // Add connectorModel/packageModel/tableModel, etc. from the old version to the modelList
  // Data when the usage in the package is BGA
  if (channelInfo.content && versionCompareSize(channelInfo.version, "0.0.21")) {
    channelInfo.content.components = updateModelInfoInConnPkgModel(channelInfo.content.components)
    save = true
  }

  // When RX is IBIS Model, modify the options for deleting CTLE Setting from ["Default", "Custom"] to ["Preset", "Custom"], as previously set to "Default" to "Custom"
  // When RX is a Termination Model, add jitter and EQ
  if (channelInfo.adsConfig && channelInfo.adsConfig.signals && (versionCompareSize(channelInfo.version, "0.0.22"))) {
    channelInfo.adsConfig.signals = updateAdsConfigRXModelEQ(channelInfo.adsConfig.signals)
    save = true
  }

  if (channelInfo.config && channelInfo.config.analysis && channelInfo.config.analysis.options && (versionCompareSize(channelInfo.version, "0.0.23"))) {
    channelInfo.config.analysis.options = updateAdsConfigDFEValue(channelInfo.config.analysis.options)
    save = true
  }


  // Fix the issue of missing data in hfss
  if (channelInfo.content && channelInfo.content.extraction && channelInfo.content.extraction.hfss && Object.keys(channelInfo.content.extraction.hfss).length && Object.keys(channelInfo.content.extraction.hfss).length < 11) {
    const new_hfss = new HFSSInfo();
    channelInfo.content.extraction.hfss = { ...new_hfss, ...channelInfo.content.extraction.hfss }
    save = true;
  }

  if (channelInfo.type === PCIE && judgeSeasimConfigGeneralValue(channelInfo.config)) {
    const info = updateSeasimConfigInfo(channelInfo.config);
    channelInfo.config = info.config;
    save = info.save || save;
  }

  return { channelInfo: { ...channelInfo, version: ANDES_V2_CHANNEL_VERSION }, save, isReplace: false, findRefNetsStatus };
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_PCB_CHANNEL_TO_SERVER]);
    let delayTime = 3000;
    if (newAction.type === SAVE_PCB_CHANNEL_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, saveChannelContentToServer, newAction, delayTime);
    }
  }
}

function* debounce(callback, action, time = 0) {
  yield delay(time);
  yield call(callback, action);
}

export function* saveChannelContentToServer(action = {}) {
  const { notUpdateList, isUpdateRefNets } = action;
  const { AndesV2Reducer: { channel, project: { openProjectId, treeItems } } } = yield select();
  let { channelInfo } = channel;
  if (!channelInfo || !Object.keys(channelInfo).length || !channelInfo.id) {
    return;
  }
  const isPreLayoutChannel = isPreLayout(channelInfo.designId);
  //re find reference nets
  if (isUpdateRefNets && !isPreLayoutChannel) {
    let signalNets = channelInfo.content.signals.reduce((prev, curr) => {
      return [
        ...prev,
        {
          name: curr.name,
          nets: channelInfo.type === CPHY ? [...curr.nets_A, ...curr.nets_B, ...curr.nets_C] : [...curr.nets_P.map(d => d), ...curr.nets_N.map(d => d)]
        }
      ]
    }, [])
    channelInfo = yield call(findRefNets, { pcbId: channelInfo.designId, signals: [...signalNets], isSave: true });
  }

  const stackups = !isPreLayoutChannel ? yield call(_LayoutData.getStackupJson, { pcbId: channelInfo.designId }) : {};
  const materialList = stackups && stackups.stackup && stackups.stackup.materials ? stackups.stackup.materials : null;
  const error = yield call(channelErrorCheck, { ...channelInfo }, null, null, materialList);
  try {
    channelSetupInfo.set(channelInfo.id, JSON.parse(JSON.stringify(channelInfo)));
    yield call(updateChannelContentPromise, { ...channelInfo, readyForSim: error ? 0 : 1, version: ANDES_V2_CHANNEL_VERSION });
  } catch (_error) {
    console.error(_error);
  }
  if (!error) {
    //update verification (channel) simulation error check
    yield put(updateSimulationInfo({
      verificationId: channelInfo.verificationId,
      item: "errorCheck",
      info: null
    }));
  }

  if (notUpdateList) {
    return;
  }

  if (isEndToEndChildrenChannel(channelInfo)) {
    //get end to end channel children channel list
    yield call(getEndToEndChildrenChannelList, { endToEndIds: [channelInfo.endToEndId] });
    return;
  }

  //get design children channel list
  const design = designConstructor.getDesign(channelInfo.designId);
  if (design) {
    //update tree channel list (update readyForSim )
    yield put(getDesignChannelList({
      designs: [design],
      projectId: openProjectId,
      _treeItems: treeItems,
      addChannelToCache: true,
      isSaveChannel: true,
      designType: design.type === PCB_PACKAGE ? PACKAGE : PCB
    }))
  }
}

function* _editCompType(action) {
  const { record: { part, type, comps } } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _channelInfo = { ...channelInfo };
  const isPreLayoutChannel = isPreLayout(_channelInfo.designId);
  let _components = JSON.parse(JSON.stringify(channelInfo.content.components));
  let _port_setups = channelInfo.content.port_setups ? [...channelInfo.content.port_setups] : [];
  const referenceNets = channelInfo.content.referenceNets ? [...channelInfo.content.referenceNets] : [];
  let ports_generate_setup_list = channelInfo.content.ports_generate_setup_list ? [...channelInfo.content.ports_generate_setup_list] : [];
  let _signals = channelInfo.content.signals;
  let _selectedSignals = [..._channelInfo.content.selectedSignals];
  const prevPortSetup = ports_generate_setup_list.length ? ports_generate_setup_list[0].setup : new PortsGenerationSetup({});

  let includeSignals = [];
  const compNames = comps.map(item => item.name);
  let newComps = [], deletedComps = [], component = "", compType = null, prevType = null;
  const HAS_MODEL_LIST_TYPES = [CONNECTOR, BGA];

  let allPinList = [], CompPinsNets = {}
  if (type === DIODE && prevType !== DIODE) {
    const findComp = _components.find(item => item.name === compNames[0]);

    if (findComp) {
      const prevType = findComp.prevType;
      if (prevType !== DIODE && type === DIODE) {
        CompPinsNets = _LayoutData.getCompPinsNets(_channelInfo.designId);
        const pcbComp = getComponentByName(_channelInfo.designId, compNames[0]) || {};
        const mPinList = pcbComp.mPart && pcbComp.mPart.mPinList ? pcbComp.mPart.mPinList : [];
        allPinList = JSON.parse(JSON.stringify(mPinList)).map(item => item.mNumber);
      }
    }
  }
  _components.forEach(item => {
    if (item.part === part && compNames.includes(item.name)) {
      prevType = item.type;
      item = modifyCompType(item, type);
      component = item.name;
      compType = item.type;
      //ic / connector -> ignore
      if (SERDES_TYPES.includes(prevType) && !SERDES_TYPES.includes(type)) {
        const _deletedComps = item.pins.map(it => { return { name: item.name, ...it } });
        deletedComps = [...deletedComps, ..._deletedComps];
        ports_generate_setup_list = ports_generate_setup_list.filter(it => it.component !== item.name);
        //find component pins connect signals
        includeSignals.push(...item.pins.filter(it => !!it.signal).map(it => it.signal));
        delete item.gap_size;
        delete item.ball_size;
        delete item.ball_height;
        delete item.ball_type;
        delete item.ball_mid_diameter;
        delete item.connectorModel;
        delete item.pkg;
        delete item.modelList;
      }

      if (!HAS_MODEL_LIST_TYPES.includes(item.type) && item.modelList) {
        // BGA->DIE  / Connector -> IC
        // In the case of IC/DIE, there should not be a modelList
        delete item.modelList;
      }

      //ignore -> ic / connector
      if (SERDES_TYPES.includes(type) && !SERDES_TYPES.includes(prevType)) {
        item.model = {};
        //update port setup
        if (!isPreLayoutChannel) {

          ports_generate_setup_list.push({
            component: item.name,
            setup: JSON.parse(JSON.stringify(prevPortSetup))
          });

          if (prevPortSetup.portType === GAP) {
            item.gap_size = "0";
          }
          if (BALL_TYPE_NONE_LIST.includes(prevPortSetup.portType) && ![BGA, DIE].includes(item.type)) {
            item.ball_type = BALL_TYPE_NONE;
          } else if (USE_BALL_LIST.includes(prevPortSetup.portType)) {
            const updateBallInfo = getUpdateCompBallInfoByPinSize(item, channelInfo.designId);
            Object.keys(updateBallInfo).forEach(key => { item[key] = updateBallInfo[key] })
          }
          newComps.push({ ...item });
        }

      }

      if (type === CMC && prevType !== CMC) {
        item.model = {};
        const findExistSamePartCMC = _components.find(it => it.part === part && it.type === CMC);
        item.pinsConnection = findExistSamePartCMC && findExistSamePartCMC.pinsConnection && findExistSamePartCMC.pinsConnection.length === 2
          ? JSON.parse(JSON.stringify(findExistSamePartCMC.pinsConnection))
          : getCMCPinConnections({
            designId: channelInfo.designId,
            compName: item.name
          });
      }

      if (type === DIODE && prevType !== DIODE && allPinList.length) {
        const compPinsInfo = CompPinsNets[item.name] || {};//{[pin]:{mName:net name, ... }}
        let pinList = []
        for (let item of allPinList) {
          const pinNet = (compPinsInfo[item] || {}).mName;
          const signal = _signals.find(item =>
            channelInfo.type === CPHY ?
              item.nets_A.includes(pinNet) || item.nets_B.includes(pinNet) || item.nets_C.includes(pinNet)
              : item.nets_P.includes(pinNet) || item.nets_N.includes(pinNet));
          if (signal) {
            pinList.push({
              pin: item,
              net: pinNet,
              signal: signal.name
            })
          }
        }
        item.pins = pinList.length ? pinList : item.pins;
      }
    }
  })

  if (deletedComps.length && !isPreLayoutChannel) {
    //delete ports by deleted comps
    _port_setups = deletePortSetups({
      deletedComps,
      port_setups: _port_setups
    });
  }

  if (newComps.length && !isPreLayoutChannel) {
    //add extraction ports by new comps
    const resInfo = addPortSetups({
      newComps,
      port_setups: _port_setups,
      ports_generate_setup_list,
      getDefaultPortSetupList,
      signals: _signals,
      referenceNets,
      pcbInfo: DesignInfo.getPCBInfo(channelInfo.designId),
      designId: channelInfo.designId,
      channelType: channelInfo.type,
      extractionType: getExtractionType(channelInfo.content.extraction)
    });
    _port_setups = resInfo.port_setups;
    ports_generate_setup_list = resInfo.ports_generate_setup_list;
    yield put(updateGeneratePortsErrors({ id: channelInfo.id, errors: resInfo.errors, warnings: resInfo.warnings }));
  }

  _channelInfo.content = {
    ...channelInfo.content,
    components: _components,
    selectedSignals: _selectedSignals
  };
  if (!isPreLayoutChannel) {
    //sort port setups by component and pin
    _port_setups = sortPortSetups(_port_setups, _components, _signals);
    _channelInfo.content.port_setups = _port_setups;
    ports_generate_setup_list = ports_generate_setup_list.filter(item => _components.find(it => SERDES_TYPES.includes(it.type) && it.name === item.component))
    _channelInfo.content.ports_generate_setup_list = ports_generate_setup_list;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_channelInfo.type)) {
    _channelInfo = updateADSAndSeasimComponent(_channelInfo);
  }

  // update hspiceConfig
  let isUpdateHspiceConfig = false;
  const hspiceConfig = _channelInfo.hspiceConfig || {};
  if (_channelInfo.type === CPHY && hspiceConfig && hspiceConfig.signals
    && ((SERDES_TYPES.includes(prevType) && !SERDES_TYPES.includes(type)) || (SERDES_TYPES.includes(type) && !SERDES_TYPES.includes(prevType)))) {
    _channelInfo.hspiceConfig = updateHSPICECompType(_channelInfo);
    isUpdateHspiceConfig = true;
  }

  yield put(updateChannelInfo(_channelInfo));
  yield put(updateHspiceConfigStatus(isUpdateHspiceConfig));

  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });

  // update Multi PCB Model Info
  yield put(updateMultiModelStatus(true))

  const modifiedComponents = _components.filter(item => item.part === part);

  if (judgeReIdentify(prevType, type)) {
    yield call(reIdentifyChannel, {
      channelInfo,
      modifiedComponents,
      components: _components,
      signals: _signals
    });
  }

  yield call(updateSelectSignals);

  //if component type from Connector to IC / Ignore / ..., delete end to end channel connections select component
  if (isEndToEndChildrenChannel({ endToEndId: channelInfo.endToEndId })) {
    yield call(updateEndToEndByChannelComponent, {
      endToEndId: channelInfo.endToEndId,
      channelId: channelInfo.id,
      component,
      compType,
      prevType,
      designId: channelInfo.designId
    })
  }
}

function* updateSelectSignals() {
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let content = { ...channelInfo.content };
  let selectedSignals = [...content.selectedSignals];

  let unCheckedSignals = [];
  //if signal component pins only one, un check signal
  const icComps = content.components.filter(item => SERDES_TYPES.includes(item.type));
  for (let signalItem of content.signals) {
    const includesComps = icComps.filter(item => {
      if (item.pins.filter(it => it.signal === signalItem.name).length) {
        return item.name;
      }
      return null;
    });
    if (includesComps.filter(item => !!item).length < 2) {
      unCheckedSignals.push(signalItem.name);
    }
  }

  selectedSignals = selectedSignals.filter(item => !unCheckedSignals.includes(item));
  yield put(updateChannelContent({ selectedSignals }));
}

function* _saveExtraction(action) {
  let { extraction, prevExtraction = {}, channelId } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();

  if (!channelInfo || !Object.keys(channelInfo).length || channelId !== channelInfo.id) return;

  let _channelInfo = { ...channelInfo };

  if (!extraction) {
    const design = designConstructor.getDesign(channelInfo.designId) || {};
    extraction = new Extraction({ designType: design.type });
  }
  //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  //re generate port setups by port type is GAP, reference type is All pins
  if (extraction.type !== prevExtraction.type && judgeUpdateGapPortSetup(_channelInfo, extraction)) {

    const resInfo = updateExtractionGapPortsSetup(_channelInfo);
    _channelInfo = resInfo.channelInfo;
    yield put(updateGeneratePortsErrors({ id: _channelInfo.id, errors: resInfo.errors, warnings: resInfo.warnings }));
  }

  if (judgeUpdateGapPortGapSizeSetup({
    components: _channelInfo.content.components,
    extractionType: getExtractionType(extraction),
    prevExtractionType: prevExtraction.type,
    ports_generate_setup_list: _channelInfo.content.ports_generate_setup_list
  })) {
    _channelInfo.content.components = updateHFSSExtractionCompsGapSize(_channelInfo.content.components, _channelInfo.content.ports_generate_setup_list);
  }
  _channelInfo.content.extraction = extraction;

  yield put(updateChannelInfo(_channelInfo));
  yield call(saveChannelContentToServer);

  /*  yield put(updateChannelContent({
     extraction,
     port_setups: _channelInfo.content.port_setups,
     ports_generate_setup_list: _channelInfo.content.ports_generate_setup_list
   })); */
}

function* _saveContent() {
  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
}

function* _autoGetChannelList(action) {
  const { projectId, currentSimInfo } = action;
  if (!projectId) {
    return;
  }
  // currentSimInfo - change simulation status, from checking -> error / success
  if (!currentSimInfo) {
    yield call(updateChannelVerificationStatus, { projectId });
  } else {
    yield call(updateChannelStatus, { projectId, currentSimInfo });
  }
}

function* updateChannelVerificationStatus(action) {
  const { projectId } = action;

  let first = true,
    delayTime = 60000,
    isWhile = true,
    allSimChannelIds = [];
  while (isWhile) {
    if (!first) {
      yield delay(delayTime);//6000
    }
    const { LoginReducer: { pageType }, AndesV2Reducer: { project: { treeItems, openProjectId } } } = yield select();
    //if project close , return
    if (pageType !== ANDES_V2 || openProjectId !== projectId) {
      return;
    }
    const prevAllSimChannelIds = [...allSimChannelIds];
    const _allSimChannelIds = yield call(getChannelList, { projectId, treeItems, first });

    const filterIds = prevAllSimChannelIds.filter(item => !_allSimChannelIds.includes(item));
    if (filterIds.length) {
      //clear eye diagram cache
      const adsChannelIds = filterIds.map(item => { return { id: item, type: "ads" } });
      const seaSimChannelIds = filterIds.map(item => { return { id: item, type: "compliance" } });
      const hspiceChannelIds = filterIds.map(item => { return { id: item, type: "hspice" } });
      eyeDiagramResult.cleanEyeDiagram([...adsChannelIds, ...seaSimChannelIds, ...hspiceChannelIds]);
    }
    //if running and waiting exist delay time modified 5s
    _allSimChannelIds.length ? delayTime = 6000 : isWhile = false;
    allSimChannelIds = [..._allSimChannelIds];
    first = false;
  }
}

function* getChannelList({ projectId, treeItems, first }) {
  const { AndesV2Reducer: { project: { expandedKeys } } } = yield select();
  let _treeItems = [...treeItems], allSimChannelIds = [], allSimDesignIds = [];
  //allSimDesignIds -> designId list whose channel status is RUNNING or WAITING
  //allSimChannelIds -> channelId list whose channel status is RUNNING or WAITING 
  // treeItems[1] - 'projects'
  const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
  if (projectIndex < 0) {
    return allSimChannelIds;
  }
  // tree current project design list
  const designs = _treeItems[PROJECTS_INDEX].children[projectIndex].children[DESIGN_INDEX].children || [];
  const packages = _treeItems[PROJECTS_INDEX].children[projectIndex].children[PACKAGE_INDEX].children || [];
  //find design list -> channel status is RUNNING or WAITING
  const allDesigns = [...designs, ...packages];
  for (let design of allDesigns) {
    if (!design.children.length) {
      continue;
    }
    let simChannels = [];
    if (first) {
      simChannels = design.children;
    } else {
      simChannels = design.children.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status) || (!!item.simStatus));
    }
    if (simChannels.length) {
      allSimDesignIds = [...new Set([...allSimDesignIds, design.id])];
    }
  }
  //update channel status
  for (let designId of allSimDesignIds) {
    try {
      //get channels by designId
      const resChannels = yield call(getChannelListByDesignId, designId);
      const channels = getChannelsTree(resChannels);
      //add channels to _treeItems
      const _designType = allDesigns.find(it => it.id === designId).type;
      _treeItems = channelListToTree({ _treeItems, projectId, designId, channels, designType: _designType === PCB_PACKAGE ? PACKAGE : PCB });
      // resChannels.forEach(item => {
      //   //update channel info
      //   channelConstructor.addChannel(item.id, item);
      // });
      for (const item of resChannels) {
        //update channel info
        channelConstructor.addChannel(item.id, item);
        const isExpand = expandedKeys.find(key => key === `${PCB_CHANNEL}-${item.id}`)
        if (item.hasSweep && isExpand) {
          const sweepList = sweepConstructor.getSweepValues(item.id);
          if (sweepList && sweepList.length > 0) {
            const sweepInfo = getSweepsTree(sweepList);
            _treeItems = sweepListToTree({ treeItems: _treeItems, projectId, designId, channelId: item.id, sweepInfo });
          } else {
            const sweepList = yield call(getSweepListByChannelIdPromise, item.id);
            const sweepInfo = getSweepsTree(sweepList);
            sweepConstructor.addSweeps(sweepList);
            _treeItems = sweepListToTree({ treeItems: _treeItems, projectId, designId, channelId: item.id, sweepInfo });
          }
        }
      }
      //find channels by status is [RUNNING, WAITING]
      const simChannels = channels.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status));
      allSimChannelIds = [...new Set([...allSimChannelIds, ...simChannels.map(item => item.id)])];
    } catch (error) {
      console.error(error);
    }
  }
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
  return allSimChannelIds;
}

function* updateChannelStatus(action) {
  const { projectId, currentSimInfo } = action;
  // update once
  const { AndesV2Reducer: { project: { treeItems } } } = yield select();

  let _treeItems = [...treeItems];

  for (let it of currentSimInfo) {
    const channel = channelConstructor.getChannel(it.channelId);
    const designId = channel.designId;
    const designType = (designConstructor.getDesign(designId) || {}).type;

    const name = designType === PCB_PACKAGE ? "Package" : "PCB";

    // treeItems[1] - 'projects'
    const findIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
    if (findIndex > -1) {
      // tree design list
      let designs = _treeItems[PROJECTS_INDEX].children[findIndex].children.find(item => item.name === name).children || [];
      // tree designs index
      const Index = _treeItems[PROJECTS_INDEX].children[findIndex].children.findIndex(item => item.name === name);
      // tree current design index
      let designIndex = designs.findIndex(item => item.id === designId);
      if (designIndex < 0 || Index < 0) {
        continue;
      }
      // tree current design channels
      let channels = designs[designIndex].children || [];
      channels.forEach(item => {
        if (it.channelId === item.id && (!item.simStatus || it.status !== item.simStatus)) {
          item.simStatus = it.status;
        }
      });
      _treeItems[PROJECTS_INDEX].children[findIndex].children[Index].children[designIndex].children = channels;
    }
  }
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _addNewSignal(action) {
  const { group, groupList } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _channelInfo = { ...channelInfo };
  const selectedSignals = channelInfo.content.selectedSignals;
  let _signals = [...channelInfo.content.signals];
  let _selectedSignals = selectedSignals ? [...selectedSignals] : []
  let groupName = group, signalName = "";
  if (group === NEW_GROUP || [HDMI, CPHY].includes(channelInfo.type)) {
    // default group name
    groupName = ![HDMI, CPHY].includes(channelInfo.type) ? getDefaultName({
      nameList: groupList.filter(item => item !== NEW_GROUP),
      defaultKey: "Group",
      key: null
    }) : channelInfo.type;
    //signal name
    signalName = getDefaultName({
      nameList: _signals.map(item => item.name),
      defaultKey: `${groupName}_Signal`,
      key: null,
      firstIndex: 1
    });
  } else {
    groupName = group;
    const groupSignals = _signals.filter(item => item.group === group);
    //signal name
    signalName = getDefaultName({
      nameList: groupSignals,
      key: "name",
      firstIndex: groupSignals.length,
      defaultKey: `${group}_Signal`
    });
  }
  let new_signal = null;
  if (channelInfo.type === CPHY) {
    new_signal = new ChannelCPHYSignals({
      name: signalName,
      group: groupName,
      nets_A: [],
      nets_B: [],
      nets_C: []
    });
  } else {
    new_signal = new ChannelSignals({
      name: signalName,
      group: groupName,
      nets_P: [],
      nets_N: []
    });
  }
  _signals.push(new_signal);
  _selectedSignals.push(signalName);

  if (channelInfo.type === PCIE) {
    //add signal to seaSim config channels
    let config = channelInfo.config && channelInfo.config.channels ? { ...channelInfo.config } : new SeaSimConfig();
    config.channels.push(new SeaSimChannel({ signal: signalName }));
    config.analysis.channels.push({ victim: signalName, aggressors: [] });
    _channelInfo.config = config;
  }

  if ([PCIE, HDMI, GENERIC].includes(channelInfo.type)) {
    //add signal to ads config channels
    let adsConfig = channelInfo.adsConfig ? channelInfo.adsConfig : {};
    const { txComp, rxComp } = getSignalAmiCompBySignalName({ signalName, controller: adsConfig.controller, device: adsConfig.device });
    // The model type in the newly added signal group should be consistent with the previous one
    const IbisHasAMI = adsConfig.signals.length ? adsConfig.signals[0].IbisHasAMI : 'yes';
    adsConfig.signals && adsConfig.signals.push(
      new AMISignalConfig({
        signalName,
        controller: txComp,
        device: rxComp,
        aggressors: [],
        IbisHasAMI
      }))
    _channelInfo.adsConfig = adsConfig;
  }

  let isUpdateHspiceConfig = false;
  if (channelInfo.type === CPHY) {
    //add signal to ads config channels
    let adsConfig = channelInfo.adsConfig ? channelInfo.adsConfig : {};
    const { txComp, rxComp } = getSignalAmiCompBySignalName({ signalName, controller: adsConfig.controller, device: adsConfig.device });
    adsConfig.signals && adsConfig.signals.push(
      new CPHYIBISSignalConfig({
        signalName,
        controller: txComp,
        device: rxComp,
      }))
    _channelInfo.adsConfig = adsConfig;
    // If hspiceConfig exists, add new signal to hspiceConfig
    if (channelInfo.hspiceConfig && channelInfo.hspiceConfig.signals) {
      const hspiceConfig = channelInfo.hspiceConfig;
      const { txComp, rxComp } = getSignalAmiCompBySignalName({ signalName, controller: hspiceConfig.controller, device: hspiceConfig.device });
      hspiceConfig.signals.push(
        new HspiceSignalConfig({
          signalName,
          controller: txComp,
          device: rxComp,
        }))
      _channelInfo.hspiceConfig = hspiceConfig;
      isUpdateHspiceConfig = true;
    }
  }

  _channelInfo.content.signals = _signals;
  _channelInfo.content.selectedSignals = _selectedSignals;

  yield put(updateChannelInfo(_channelInfo));
  yield put(updateHspiceConfigStatus(isUpdateHspiceConfig));
  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
}

function* _selectNets(action) {
  const { newNets, record = {}, prevNets, deletedNets: delNets, selectType, nets_P, nets_N, signalName, cmcComponents, nets_A, nets_B, nets_C } = action; //signal -> signal name
  const { AndesV2Reducer: { channel: { channelInfo, hybridStatus } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _channelInfo = { ...channelInfo };
  let _signals = [...channelInfo.content.signals];
  let _components = [...channelInfo.content.components];
  const prevComps = JSON.parse(JSON.stringify(_components));
  let _powerNets = [...channelInfo.content.powerNets];
  let port_setups = [...channelInfo.content.port_setups];
  let ports_generate_setup_list = [...channelInfo.content.ports_generate_setup_list];
  let referenceNets = [...channelInfo.content.referenceNets];
  let signal = selectType === "reIdentify" ? signalName : record.signal;
  //update signal nets
  const index = _signals.findIndex(item => item.name === signal);
  const prevAllNets = channelInfo.type === CPHY ? [..._signals[index].nets_A, ..._signals[index].nets_B, ..._signals[index].nets_C] : [..._signals[index].nets_P, ..._signals[index].nets_N];
  let type = '';
  if (channelInfo.type === CPHY) {
    type = record.netType === "Line A" ? "nets_A" : record.netType === "Line B" ? "nets_B" : "nets_C";
  } else {
    type = record.netType === "positive" ? "nets_P" : "nets_N";
  }  //const otherNetType = netType === "nets_P" ? "nets_N" : "nets_P";
  if (selectType === "reIdentify") {
    if (channelInfo.type === CPHY) {
      _signals[index].nets_A = [...new Set([...nets_A])];
      _signals[index].nets_B = [...new Set([...nets_B])];
      _signals[index].nets_C = [...new Set([...nets_C])];
    } else {
      _signals[index].nets_P = [...new Set([...nets_P])];
      _signals[index].nets_N = [...new Set([...nets_N])];
    }
  } else {
    _signals[index][type] = [...new Set([...newNets])];
  }
  // _signals[index][otherNetType] = _signals[index][otherNetType].filter(item => !newNets.includes(item))
  //update components
  const deletedNets = selectType === "reIdentify" ? delNets : prevNets.filter(item => !newNets.includes(item));
  const addNets = selectType === "reIdentify" ? [...newNets] : newNets.filter(item => !prevNets.includes(item));
  //get pcb
  const pcbInfo = DesignInfo.getPCBInfo(channelInfo.designId);
  const { netsList, layers } = pcbInfo;
  let deletedComps = [], isSortPorts = false;
  // new all nets
  const newAllNets = channelInfo.type === CPHY ? [..._signals[index].nets_A, ..._signals[index].nets_B, ..._signals[index].nets_C] : [..._signals[index].nets_P, ..._signals[index].nets_N];
  //removed deleted nets
  if (deletedNets.length) {
    //delete pin
    const updateComps = deleteCompsConnectWithNets({
      layers,
      netList: deletedNets.filter(item => !newAllNets.includes(item)),
      pcbNetsList: netsList,
      signalName: signal,
      components: _components,
    });
    _components = updateComps.components;
    deletedComps = updateComps.deletedComps;
  }

  if (deletedComps.length) {
    //delete ports by delete comps
    port_setups = deletePortSetups({
      deletedComps,
      port_setups
    });
    isSortPorts = true;
  }

  let newComps = [];
  //add component or comp pin
  if (addNets.length) {
    const designType = designConstructor.getDesign(channelInfo.designId).type;
    const compsInfo = getComponents({
      layers,
      netList: addNets,
      pcbNetsList: netsList,
      signalName: signal,
      components: _components,
      returnNewComps: true,
      cmcComponents,
      serdesType: channelInfo.type,
      designType
    });
    _components = compsInfo.components;
    newComps = compsInfo.newComps;
  }
  //find power net and update component by rlc component
  let signalNets = [];
  // _signals.forEach(item => {
  //   if (item.nets_P.length || item.nets_N.length) {
  //     signalNets = [...signalNets, ...item.nets_P, ...item.nets_N]
  //   }
  // });
  for (const item of _signals) {
    const otherNets = getSignalElementType(channelInfo.type).map(key => item[key] && item[key].length > 0 ? item[key] : []).flat(2);
    signalNets = [...signalNets, ...otherNets];
  }
  const updateInfo = getPowerNets({
    components: _components,
    powerNets: _powerNets,
    netsList,
    signalNets,
    findGND: true,
    designId: channelInfo.designId
  });
  _powerNets = updateInfo.powerNets;

  _components = updateInfo.components;

  //update reference nets by power nets
  referenceNets = getDefaultReferenceNets(_powerNets, referenceNets);

  //add ports by new components
  if (newComps.length) {
    const resInfo = addPortSetups({
      newComps,
      port_setups,
      ports_generate_setup_list,
      getDefaultPortSetupList,
      signals: _signals,
      referenceNets,
      pcbInfo: DesignInfo.getPCBInfo(channelInfo.designId),
      designId: channelInfo.designId,
      channelType: channelInfo.type,
      extractionType: getExtractionType(channelInfo.content.extraction)
    });
    isSortPorts = true;
    port_setups = resInfo.port_setups;
    ports_generate_setup_list = resInfo.ports_generate_setup_list;
    yield put(updateGeneratePortsErrors({ id: channelInfo.id, errors: resInfo.errors, warnings: resInfo.warnings }));
  }

  if (isSortPorts) {
    //sort port setups by component and pin
    port_setups = sortPortSetups(port_setups, _components, _signals);
  }

  //update ports generate setup list
  ports_generate_setup_list = updatePortSetupByUpdateComp(_components, ports_generate_setup_list);

  const addComps = newComps.filter(item => !prevComps.find(it => it.name === item.name));
  for (let comp of addComps) {
    const index = _components.findIndex(item => item.name === comp.name && SERDES_TYPES.includes(item.type));
    if (index < 0) {
      continue;
    }
    _components[index] = updateCompByPortType(_components[index], ports_generate_setup_list, channelInfo.designId);
  }

  if (channelInfo.type === PCIE) {
    let config = channelInfo.config && channelInfo.config.channels ? { ...channelInfo.config } : new SeaSimConfig();
    const serdesComps = _components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name);
    const deletedICConnComps = deletedComps.filter(item => serdesComps.includes(item.name));
    /*  const newICConnComps = newComps.filter(item => serdesComps.includes(item.name)); */
    config = updateSeaSimConfigComp({
      config,
      signalName: _signals[index].name,
      signal: _signals[index],
      deletedICConnComps,
      components: _components
      /* newICConnComps */
    });
    _channelInfo.config = config;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_channelInfo.type)) {
    let adsConfig = _channelInfo.adsConfig ? { ..._channelInfo.adsConfig } : {};
    adsConfig = updateAdsConfigComp({
      adsConfig,
      components: _components,
      serdesType: _channelInfo.type
    });
    _channelInfo.adsConfig = adsConfig;
  }

  // update hspice config
  let isUpdateHspiceConfig = false;
  if (_channelInfo.type === CPHY && _channelInfo.hspiceConfig && _channelInfo.hspiceConfig.signals) {
    _channelInfo.hspiceConfig = updateHspiceConfigComp({
      hspiceConfig: _channelInfo.hspiceConfig,
      components: _components
    });
    isUpdateHspiceConfig = true;
  }
  ports_generate_setup_list = ports_generate_setup_list.filter(item => _components.find(it => SERDES_TYPES.includes(it.type) && it.name === item.component))

  _channelInfo.content = {
    ...channelInfo.content,
    signals: _signals,
    components: _components,
    powerNets: _powerNets,
    port_setups,
    referenceNets,
    ports_generate_setup_list
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_channelInfo.type)) {
    _channelInfo = updateADSAndSeasimComponent(_channelInfo);
  }

  // update hspiceConfig
  if (_channelInfo.type === CPHY && _channelInfo.hspiceConfig && _channelInfo.hspiceConfig.signals) {
    _channelInfo.hspiceConfig = updateHSPICEModel(_channelInfo, signal);

    isUpdateHspiceConfig = true;
  }

  yield put(updateChannelInfo(_channelInfo));
  yield put(updateHspiceConfigStatus(isUpdateHspiceConfig));
  //update hybrid boundaries
  if (!hybridStatus && _channelInfo.content.extraction && _channelInfo.content.extraction.hybrid &&
    _channelInfo.content.selectedSignals.includes(signal)
    && JSON.stringify(newAllNets.sort()) !== JSON.stringify(prevAllNets.sort())) {
    yield put(updateHybridBoundaries(_channelInfo.verificationId, !newAllNets.length))
  }
  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
  // update Multi PCB Model Info
  yield put(updateMultiModelStatus(true))

  if (isEndToEndChildrenChannel({ endToEndId: channelInfo.endToEndId })) {
    yield call(updateEndToEndByComponents, {
      endToEndId: channelInfo.endToEndId,
      channelId: channelInfo.id,
      deletedComps,
      newComps,
      type,
      signal,
      components: _components
    })
  }
}

function* _updateSignalName(action) {
  const { record: { signal }, prevRecord: { signal: prevName } } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _channelInfo = { ...channelInfo };
  const content = channelInfo.content;
  let _signals = [...content.signals];
  let _components = [...content.components];
  let _port_setups = [...content.port_setups];
  const signalIndex = _signals.findIndex(item => item.name === prevName);
  if (signalIndex < 0) {
    return;
  }

  // Check repeat signal name
  const names = _signals.map(item => item.name);

  if (names.includes(signal)) {
    message.error('Signal name cannot be repeated.');
    return;
  }

  //check name characters format
  const error = checkNameFormat(signal);
  if (error) {
    message.error(error);
    return;
  }

  _signals[signalIndex].name = signal;
  //update selected signals
  let selectedSignals = content.selectedSignals ? [...content.selectedSignals] : [];
  const index = selectedSignals.findIndex(item => item === prevName);
  if (index > -1) {
    selectedSignals[index] = signal;
  }

  //update components pins signal name
  for (let comp of _components) {
    for (let pin of comp.pins) {
      if (pin.signal === prevName) {
        pin.signal = signal;
      }
    }
  }

  //update port setups info signal name
  _port_setups.forEach(item => {
    if (item.info && item.info.signal === prevName) {
      item.info.signal = signal;
    }
  })

  if (channelInfo.type === PCIE) {
    //update SeaSim config channels signal name
    let config = channelInfo.config && channelInfo.config.channels ? { ...channelInfo.config } : new SeaSimConfig();
    config = updateSignalNameInSeaSimConfig(config, prevName, signal);
    _channelInfo.config = config;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_channelInfo.type) && _channelInfo.adsConfig && _channelInfo.adsConfig.signals) {
    //update ads config channels signal name
    _channelInfo.adsConfig = updateSignalNameInAdsConfig(_channelInfo.adsConfig, prevName, signal);
  }

  // 更新 hpisceConfigs的signal name
  if (_channelInfo.type === CPHY && _channelInfo.hpisceConfigs && _channelInfo.hpisceConfigs.signals) {
    _channelInfo.hpisceConfigs = updateSignalNameInHspiceConfig(_channelInfo.hpisceConfigs, prevName, signal);
  }

  _channelInfo.content.signals = _signals;
  _channelInfo.content.components = _components;
  _channelInfo.content.selectedSignals = selectedSignals;
  _channelInfo.content.port_setups = _port_setups;

  yield put(updateChannelInfo(_channelInfo));
  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });

  if (isEndToEndChildrenChannel({ endToEndId: channelInfo.endToEndId })) {
    yield call(updateEndToEndByChannelRenamedSignal, {
      endToEndId: channelInfo.endToEndId,
      channelId: channelInfo.id,
      signal,
      prevSignal: prevName
    })
  }
}

function* _deleteSignal(action) {
  const { record: { signal } } = action;
  const { AndesV2Reducer: { channel: { channelInfo, hybridStatus } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _channelInfo = { ...channelInfo };
  const content = channelInfo.content;
  let _signals = [...content.signals];
  let _components = [...content.components];
  let port_setups = [...content.port_setups];
  let ports_generate_setup_list = [...content.ports_generate_setup_list];

  let _powerNets = [...content.powerNets];
  const signalIndex = _signals.findIndex(item => item.name === signal);
  if (signalIndex < 0) {
    return;
  }
  let selectedSignals = content.selectedSignals ? [...content.selectedSignals] : [];
  const prevSelectedSignals = [...selectedSignals];
  selectedSignals = selectedSignals.filter(item => item !== signal);
  const deletedNets = channelInfo.type === CPHY ? [..._signals[signalIndex].nets_A, ..._signals[signalIndex].nets_B, ..._signals[signalIndex].nets_C] : [..._signals[signalIndex].nets_P, ..._signals[signalIndex].nets_N];
  //get pcb
  const pcbInfo = DesignInfo.getPCBInfo(channelInfo.designId);
  const { netsList, layers } = pcbInfo;
  let deletedComps = [];
  //removed deleted nets
  if (deletedNets.length) {
    //delete pin
    const updateComps = deleteCompsConnectWithNets({
      layers,
      netList: deletedNets,
      pcbNetsList: netsList,
      signalName: signal,
      components: _components
    });

    _components = updateComps.components;
    deletedComps = updateComps.deletedComps;


    //find power net and update component by rlc component
    let signalNets = [];
    // _signals.forEach(item => {
    //   if (item.nets_P.length || item.nets_N.length) {
    //     signalNets = [...signalNets, ...item.nets_P, ...item.nets_N]
    //   }
    // });
    for (const item of _signals) {
      const otherNets = getSignalElementType(channelInfo.type).map(key => item[key] && item[key].length > 0 ? item[key] : []).flat(2);
      signalNets = [...signalNets, ...otherNets];
    }
    const updateInfo = getPowerNets({
      components: _components,
      powerNets: _powerNets,
      netsList,
      signalNets,
      findGND: true,
      designId: channelInfo.designId
    });
    _powerNets = updateInfo.powerNets;
    _components = updateInfo.components;
  }
  _signals = _signals.filter(item => item.name !== signal);

  if (_channelInfo.type === PCIE) {
    let config = _channelInfo.config && _channelInfo.config.channels ? { ..._channelInfo.config } : new SeaSimConfig();
    //delete signal in seasim config
    config = delSignalInSeaSimConfig(config, signal);
    _channelInfo.config = config;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_channelInfo.type) && _channelInfo.adsConfig && _channelInfo.adsConfig.signals) {
    //delete signal in ads config
    _channelInfo.adsConfig = delSignalInAdsConfig(_channelInfo.adsConfig, signal);
  }

  if (_channelInfo.type === CPHY && _channelInfo.hpisceConfigs && _channelInfo.hpisceConfigs.signals) {
    //delete signal in hpisce config
    _channelInfo.hpisceConfigs = delSignalInHspiceConfig(_channelInfo.hpisceConfigs, signal);
  }
  if (deletedComps.length) {
    port_setups = deletePortSetups({
      deletedComps,
      port_setups
    });
    //sort port setups by component and pin
    port_setups = sortPortSetups(port_setups, _components, _signals);
  }
  ports_generate_setup_list = ports_generate_setup_list.filter(item => _components.find(it => SERDES_TYPES.includes(it.type) && it.name === item.component))

  _channelInfo.content = {
    ..._channelInfo.content,
    signals: _signals,
    components: _components,
    powerNets: _powerNets,
    selectedSignals,
    port_setups,
    ports_generate_setup_list
  }
  yield put(updateChannelInfo(_channelInfo));
  //update hybrid boundaries
  if (!hybridStatus && _channelInfo.content.extraction && _channelInfo.content.extraction.hybrid &&
    JSON.stringify(selectedSignals.sort()) !== JSON.stringify(prevSelectedSignals.sort())) {
    const findNet = _signals.filter(item => selectedSignals.includes(item.name)).find(item => _channelInfo.type === CPHY ? item.nets_A.length || item.nets_B.length || item.nets_C.length : item.nets_P.length || item.nets_N.length)
    const clearRegions = !findNet;
    yield put(updateHybridBoundaries(_channelInfo.verificationId, clearRegions))
  }

  // update Multi PCB Model Info
  yield put(updateMultiModelStatus(true))

  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
  if (isEndToEndChildrenChannel({ endToEndId: channelInfo.endToEndId })) {
    yield call(updateEndToEndByChannelDeletedSignals, {
      endToEndId: channelInfo.endToEndId,
      channelId: channelInfo.id,
      deletedSignals: [signal],
      deletedComps
    })
  }
}

function* _updateGroupName(action) {
  const { record: { group }, prevRecord: { group: prevName } } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _signals = [...channelInfo.content.signals];

  // Check repeat group name
  const names = [...new Set(_signals.map(item => item.group))];

  if (names.includes(group)) {
    message.error('Group name cannot be repeated.');
    return;
  }

  //check name characters format
  const error = checkNameFormat(group);
  if (error) {
    message.error(error);
    return;
  }

  _signals.forEach(item => {
    if (item.group === prevName) {
      item.group = group;
    }
  })
  yield put(updateChannelContent({ signals: _signals }));
}

function* _saveConfig(action) {
  const { simulateId } = action;
  yield call(saveChannelContentToServer);
  if (simulateId) {
    //run seasim flow
    yield put(startChannelExtraction([simulateId], { runSeasim: true }));
  }
}

export function* _channelReplace(action) {
  const { channelInfo, channelId, isUpdate, endToEndId } = action;
  const { AndesV2Reducer: { channel: { replaceMonitor } } } = yield select();

  let info = null;
  if (channelInfo && channelInfo.id) {
    info = { ...channelInfo };
  } else {
    info = yield call(getChannelContentPromise, channelId);
  }

  if (!info || !info.content) {
    yield put(openChannelLoading(false));
    return;
  }

  let replaceInfo = null;

  if (isPreLayout(channelInfo.designId)) {
    replaceInfo = yield call(preLayoutChannelReplace, {
      info
    });
  } else {
    replaceInfo = yield call(postLayoutChannelReplace, {
      info,
      isUpdate
    });
  }

  replaceInfo.replaceMonitor.monitor.push(`Channel setup updated successfully.`);
  if (isUpdate) {
    //update reducer channel info
    yield put(updateChannelInfo(replaceInfo.channelInfo || {}));
    //update channel loading
    yield put(openChannelLoading(false));
    //get channel
    const channel = channelConstructor.getChannel(replaceInfo.channelInfo.id);
    //update monitor info
    yield put(openTabFooter());
    let verificationName = channel ? channel.name : null;
    //end to end channel children single channel, display name is pcb name
    if (isEndToEndChildrenChannel(channel)) {
      verificationName = channel.pcbName;
    }
    yield put(changeTabMenu({
      tabSelectKeys: ["monitor"],
      currentVerificationId: channel ? channel.verificationId : null,
      verificationName,
      menuType: "simulation"
    }));
    yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
    yield put(updateMultiModelStatus(true))
  }
  //update replace monitor
  const _replaceMonitor = updateReplaceMonitor(replaceMonitor, replaceInfo.replaceMonitor);
  yield put(updateChannelReplaceMonitor(_replaceMonitor));

  if (endToEndId) {
    //if channel is end to end children channel, update replace message to end to end
    const { AndesV2Reducer: { endToEndChannel: { channelReplaceMonitorList } } } = yield select();
    let _channelReplaceMonitorList = channelReplaceMonitorList ? [...channelReplaceMonitorList] : [];
    const index = _channelReplaceMonitorList.findIndex(item => item.id === endToEndId);
    if (index > -1) {
      _channelReplaceMonitorList[index].replaceMonitorList = updateReplaceMonitor(channelReplaceMonitorList[index].replaceMonitorList, replaceInfo.replaceMonitor, "design");
    } else {
      _channelReplaceMonitorList.push({ id: endToEndId, replaceMonitorList: [replaceInfo.replaceMonitor] });
    }

    yield put(updateEndToEndReplaceMonitor(_channelReplaceMonitorList));
  }

  return replaceInfo.channelInfo;
}

function* postLayoutChannelReplace({ info, isUpdate }) {
  //load design info
  yield call(getPCBInfo, { designId: info.designId });
  const design = designConstructor.getDesign(info.designId) || {};
  const prevPowerNets = JSON.parse(JSON.stringify(info.content.powerNets));
  //replaceInfo => { channelInfo, replaceMonitor }
  const replaceInfo = yield call(channelSetupReplace, { channelInfo: info, designVersion: design.designVersion });

  //update reducer channel info
  if (isUpdate) {
    yield put(updateChannelInfo(replaceInfo.channelInfo || {}))
  }
  //re find reference nets
  const pcbId = info.designId;
  let signalNets = replaceInfo.channelInfo.content.signals.reduce((prev, curr) => {
    return [
      ...prev,
      {
        name: curr.name,
        nets: replaceInfo.channelInfo.type === CPHY ? [...curr.nets_A, ...curr.nets_B, ...curr.nets_C] : [...curr.nets_P.map(d => d), ...curr.nets_N.map(d => d)]
      }
    ]
  }, [])
  if (signalNets.length) {
    const _channelInfo = yield call(findRefNets, { pcbId, signals: signalNets, isSave: true, channelInfo: replaceInfo.channelInfo });
    if (_channelInfo) {
      replaceInfo.channelInfo = _channelInfo;
      const currPowerNets = _channelInfo.content.powerNets;
      const addPwrNets = currPowerNets.filter(item => !prevPowerNets.find(it => it.name === item.name)).map(item => item.name);
      const delPwrNets = prevPowerNets.filter(item => !currPowerNets.find(it => it.name === item.name)).map(item => item.name);
      const monitor = replacePwrNetsMonitor(addPwrNets, delPwrNets);
      replaceInfo.replaceMonitor.monitor.push(...monitor);
      //re generation extraction ports
      const ports_generate_setup_list = updatePortGenerateSetupForReplace(replaceInfo.channelInfo);

      const defaultZ0 = _channelInfo.type === PCIE ? 42.5 : 50;
      const prevPortsSetup = _channelInfo.content.port_setups;
      const avoid_single_pin_group = prevPortsSetup && prevPortsSetup.length ? (prevPortsSetup[0].positive || {}).avoid_single_pin_group : null;
      let { port_setups, ports_generate_setup_list: setupList, errors, warnings } = getDefaultPortSetupList({
        components: _channelInfo.content.components,
        signals: _channelInfo.content.signals,
        pcbInfo: DesignInfo.getPCBInfo(pcbId),
        referenceNets: _channelInfo.content.referenceNets,
        designId: pcbId,
        ports_generate_setup_list,
        referenceZ0: defaultZ0,
        extractionType: getExtractionType(_channelInfo.content.extraction),
        avoid_single_pin_group
      });
      //sort port setups by component and pin
      port_setups = sortPortSetups(port_setups, _channelInfo.content.components, _channelInfo.content.signals);
      _channelInfo.content.port_setups = port_setups;
      _channelInfo.content.ports_generate_setup_list = setupList;
      yield put(updateGeneratePortsErrors({ id: replaceInfo.channelInfo.id, errors, warnings }))
    }
  }
  return replaceInfo;
}

function* preLayoutChannelReplace({ info }) {
  //load pre layout info
  const preLayoutInfo = yield call(getPreLayoutInfoById, info.designId);
  //replaceInfo => { channelInfo, replaceMonitor }
  const replaceInfo = preLayoutChannelSetupReplace({
    channelInfo: info,
    designVersion: preLayoutInfo.designVersion,
    preLayoutInfo
  });
  return replaceInfo;
}

function* _renameChannel(action) {
  const { data } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (data && data.id && data.name) {
    const res = yield call(updateChannelContentPromise, { id: data.id, name: data.name });
    if (!res) {
      return;
    }
    channelSetupInfo.set(res.id, JSON.parse(JSON.stringify(res)));
    channelConstructor.addChannel(data.id, res);
    if (channelInfo.id === res.id) {
      // When the opened channel is the same as the channel with the modified name, modify the name in channelInfo
      yield put(updateChannelName({ name: res.name }));
    }
    const design = designConstructor.getDesign(data.designId) || {};
    yield put(getDesignChannelList({
      designs: [{ id: data.designId }],
      projectId: design.projectId,
      designType: design.type === PCB_PACKAGE ? PACKAGE : PCB
    }));
    //check current open end to end channel setup by updated channel name
    yield put(checkEndToEndByPCBChannel({ updateChannel: { id: data.id, name: data.name } }));
  }
}

let findRefNetTask = null;
function* _updateRefNets() {

  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  //Find signals
  //signals :[{ name, nets_P:[], nets_N:[] } ]
  let content = channelInfo.content;
  const { signals } = content;
  if (!signals || !signals.length) {
    return;
  }

  //cancel prev find ref nets task
  if (findRefNetTask && findRefNetTask) {
    yield cancel(findRefNetTask);
  }

  const pcbId = channelInfo.designId;
  let signalNets = signals.reduce((prev, curr) => {
    return [
      ...prev,
      {
        name: curr.name,
        nets: channelInfo.type === CPHY ? [...curr.nets_A, ...curr.nets_B, ...curr.nets_C] : [...curr.nets_P.map(d => d), ...curr.nets_N.map(d => d)]
      }
    ]
  }, [])
  findRefNetTask = yield fork(findRefNets, { pcbId, signals: signalNets })
}

function* findRefNets(action) {
  const { pcbId, signals, isSave, channelInfo } = action;
  //signals :[ { pcbId, signals:[ { name, nets:[] } ] } ]
  const key = yield call(findReferenceNetsBySignal, { signals: [{ pcbId, signals }] });
  if (!key) {
    //failed
    return channelInfo;
  }
  yield delay(1000);
  let res = yield call(getReferenceNetsBySignal, { key });
  let { status = 0, data = null } = res ? res : {};
  while (status === FIND_REF_NET_RUNNING) {
    yield delay(2000);
    res = yield call(getReferenceNetsBySignal, { key });
    if (!res) {
      break;
    }
    status = res.status;
    data = res.data;
  }

  if (status === FIND_REF_NET_FAILED || !data || !Array.isArray(data)) {
    //failed
    return channelInfo;
  }

  if (status === FIND_REF_NET_SUCCESS) {
    const _channelInfo = yield call(updateRefNetsToPowerNets, { pcbId, data, isSave, channelInfo })
    if (isSave) {
      return _channelInfo;
    }
  }
}

function* updateRefNetsToPowerNets(action) {
  const { pcbId, data, isSave, channelInfo: actionChannelInfo } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  //get signals
  const { _channelInfo, powerNets } = handleRefNetsToPowerNets({ actionChannelInfo, channelInfo, data, pcbId }) || {};
  if (!channelInfo && !powerNets) return;
  if (isSave) {
    return _channelInfo;
  } else {
    yield put(updateChannelContent({ powerNets }));
  }
}

function* _channelCopy(action) {
  const { name, channelId } = action;
  yield put(changeCopyLoadingId({ copyChannelLoadingId: channelId }));
  try {
    const res = yield call(copyPCBChannel, name, channelId);
    if (!res || !res.id) {
      message.error("Copy PCB channel failed!");
      yield put(changeCopyLoadingId({ copyChannelLoadingId: null }));
      return;
    }
    channelConstructor.addChannel(res.id, res);
    const design = designConstructor.getDesign(res.designId) || {};
    yield put(getDesignChannelList({
      designs: [{ id: res.designId }],
      projectId: design.projectId,
      designType: design.type === PCB_PACKAGE ? PACKAGE : PCB
    }));
    yield put(openPage({ pageType: PCB_CHANNEL, id: res.id }));
  } catch (error) {
    message.error("Copy PCB channel failed!");
  }

  yield put(changeCopyLoadingId({ copyChannelLoadingId: null }));
}

function* _updatePortsSetup(action) {
  const { data } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  //update port portImpedance in extraction options
  let _extraction = { ...channelInfo.content.extraction };

  const defaultZ0 = channelInfo.type === PCIE ? 42.5 : 50;
  const z0 = data.port_setups.length ? data.port_setups[0].z0 : defaultZ0;
  _extraction.portImpedance = z0;

  //clear avoid_single_pin_group
  data.port_setups = updatePortAvoidSinglePinGroup({
    port_setups: data.port_setups,
    ports_generate_setup_list: data.ports_generate_setup_list,
    clear: true
  })

  const _channelInfo = {
    ...channelInfo,
    content: { ...channelInfo.content, ...data, extraction: _extraction },
  };
  yield put(updateChannelInfo({
    ..._channelInfo
  }));

  yield delay(300);
  yield call(saveChannelContentToServer);
}

function* _updatePowerNet(action) {
  // name -> updated power net
  //prevName -> prev power net
  const { name, prevName } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  //get signals
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _powerNets = [...channelInfo.content.powerNets];
  let _referenceNets = [...channelInfo.content.referenceNets];
  const prevRefNets = [..._referenceNets];
  let _port_setups = [...channelInfo.content.port_setups];
  let ports_generate_setup_list = [...channelInfo.content.ports_generate_setup_list];

  const existIndex = _powerNets.findIndex(item => item.name === name);
  if (existIndex > -1) {
    return;
  }
  let isPortsUpdate = false;
  const index = _powerNets.findIndex(item => item.name === prevName);
  _powerNets[index].name = name;
  _powerNets[index].custom = true;

  if (_referenceNets.includes(prevName)) {
    _referenceNets = _referenceNets.filter(item => item !== prevName);
    if (parseFloat(_powerNets[index].value) === 0) {
      _referenceNets = [...new Set([..._referenceNets, name])];
    }
    isPortsUpdate = !compareRefNets(_referenceNets, prevRefNets);
    if (isPortsUpdate) {
      //Re generation extraction ports
      const data = yield call(getPortSetups, {
        ports_generate_setup_list,
        designId: channelInfo.designId,
        _port_setups,
        _referenceNets,
        channelId: channelInfo.id,
        extractionType: getExtractionType(channelInfo.content.extraction)
      });
      _port_setups = data.port_setups;
      ports_generate_setup_list = data.ports_generate_setup_list;
    }
  }

  yield put(updateChannelContent({
    powerNets: _powerNets,
    referenceNets: _referenceNets,
    port_setups: _port_setups,
    ports_generate_setup_list
  }));
}

function* getPortSetups({ ports_generate_setup_list, _referenceNets, _port_setups, designId, channelId, extractionType }) {
  if (!_referenceNets.length) {
    _port_setups = clearPortSetups(_port_setups);
  } else {
    const prev_port_setups = JSON.parse(JSON.stringify(_port_setups));
    const prev_port_setup_list = JSON.parse(JSON.stringify(ports_generate_setup_list));
    //Re generation extraction ports
    const pcbInfo = DesignInfo.getPCBInfo(designId);
    const autoPorts = new AutoGeneratePorts();
    const data = autoPorts.autoGeneratePorts(_port_setups, pcbInfo, {
      ports_generate_setup_list,
      designId,
      referenceNets: _referenceNets,
      referenceZ0: _port_setups.length ? _port_setups[0].z0 : "50",
      extractionType
    });
    _port_setups = data.port_setups;
    ports_generate_setup_list = data.setupList;
    const warnings = autoPorts.getWarnings();
    const errors = autoPorts.getErrors();
    yield put(updateGeneratePortsErrors({ id: channelId, errors, warnings }));
    if (errors && errors.length) {
      return { port_setups: prev_port_setups, ports_generate_setup_list: prev_port_setup_list };
    }
  }
  return { port_setups: _port_setups, ports_generate_setup_list };
}

function* _updatePowerVoltage(action) {
  // name ->  power net name
  //voltage ->  power net voltage
  const { name, voltage, prevVoltage } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  //get signals
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _powerNets = [...channelInfo.content.powerNets];
  let _referenceNets = [...channelInfo.content.referenceNets];
  const prevRefNets = [..._referenceNets];
  let _port_setups = [...channelInfo.content.port_setups];
  let ports_generate_setup_list = [...channelInfo.content.ports_generate_setup_list];
  const index = _powerNets.findIndex(item => item.name === name);
  _powerNets[index].value = voltage;

  if (parseFloat(prevVoltage) === 0 && parseFloat(voltage) !== 0) {
    _referenceNets = _referenceNets.filter(item => item !== name);
  }

  if (parseFloat(voltage) === 0 && parseFloat(prevVoltage) !== 0) {
    _referenceNets = [...new Set([..._referenceNets, name])];
  }

  const isPortsUpdate = !compareRefNets(_referenceNets, prevRefNets);
  if (isPortsUpdate) {
    //Re generation extraction ports
    const data = yield call(getPortSetups, {
      ports_generate_setup_list,
      designId: channelInfo.designId,
      _port_setups,
      _referenceNets,
      channelId: channelInfo.id,
      extractionType: getExtractionType(channelInfo.content.extraction)
    });
    _port_setups = data.port_setups;
    ports_generate_setup_list = data.ports_generate_setup_list;
  }

  yield put(updateChannelContent({
    powerNets: _powerNets,
    referenceNets: _referenceNets,
    port_setups: _port_setups,
    ports_generate_setup_list
  }));
}

function compareRefNets(refNets, prevRefNets) {
  if (JSON.stringify([...refNets].sort()) === JSON.stringify([...prevRefNets].sort())) {
    return true;
  }
}

function* _savePkgModel(action) {
  const { pkg, component, dataIndex } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _channelInfo = { ...channelInfo };
  let _components = [...channelInfo.content.components];
  let adsConfig = channelInfo.adsConfig ? { ...channelInfo.adsConfig } : {};
  const compIndex = _components.findIndex(item => item.name === component);
  if (compIndex > -1) {
    _components[compIndex][dataIndex] = pkg ? JSON.parse(JSON.stringify(pkg)) : _components[compIndex].pkg;
  }
  //uncheck channel ami/ibis model usePackage 
  if (pkg && pkg.type !== "None" && (component === adsConfig.controller || component === adsConfig.device)) {
    adsConfig = unCheckAmiModelUsePkg({ adsConfig, component });
    _channelInfo.adsConfig = { ...adsConfig };
  }


  _channelInfo.content.components = _components;
  yield put(updateChannelInfo(_channelInfo));
  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
  //uncheck end-to-end channel ami/ibis model usePackage 
  if (isEndToEndChildrenChannel(channelInfo) && pkg && pkg.type !== "None") {
    yield call(uncheckEndToEndAdsUsePkg, { endToEndId: channelInfo.endToEndId, channelId: channelInfo.id, component })
  }
}

function* _checkedSignals(action) {
  const { checked, key, groupType } = action;
  const { AndesV2Reducer: { channel: { channelInfo, hybridStatus } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  let _channelInfo = { ...channelInfo };

  let _selectedSignals = [..._channelInfo.content.selectedSignals];
  let signals = [..._channelInfo.content.signals];
  let analysisChannels = _channelInfo.config && _channelInfo.config.analysis ? _channelInfo.config.analysis.channels : [];
  let adsSignals = _channelInfo.adsConfig && _channelInfo.adsConfig.signals ? _channelInfo.adsConfig.signals : [];

  if (groupType) {
    const groupSignals = signals.filter(item => item.group === key).map(item => item.name);
    if (checked) {
      _selectedSignals = [...new Set([..._selectedSignals, ...groupSignals])];
    } else {
      _selectedSignals = _selectedSignals.filter(item => !groupSignals.includes(item));
    }

  } else if (key === CHECK_BOX_ALL) {
    _selectedSignals = checked ? signals.map(item => item.name) : [];
  } else {
    if (checked) {
      _selectedSignals = [...new Set([..._selectedSignals, key])];
    } else {
      _selectedSignals = _selectedSignals.filter(item => item !== key);
    }
  }
  _channelInfo.content.selectedSignals = [..._selectedSignals];

  if ([PCIE, HDMI, GENERIC].includes(_channelInfo.type)) {
    const _pcbInfo = yield call(_getPCBInfo, { designId: _channelInfo.designId });
    if (_channelInfo.type === PCIE) {
      analysisChannels = updateAggressorsBySelectedSignals({
        type: "victim",
        signals,
        components: _channelInfo.content.components,
        netsList: _pcbInfo.netsList,
        layers: _pcbInfo.layers,
        selectedSignals: [..._selectedSignals],
        analysisChannels,
        isPreLayoutChannel: isPreLayout(_channelInfo.designId)
      });
      _channelInfo.config.analysis.channels = analysisChannels;
    }

    //update ads signals aggressors
    adsSignals = updateAggressorsBySelectedSignals({
      type: "signalName",
      signals,
      components: _channelInfo.content.components,
      netsList: _pcbInfo.netsList,
      layers: _pcbInfo.layers,
      selectedSignals: [..._selectedSignals],
      adsSignals,
      isPreLayoutChannel: isPreLayout(_channelInfo.designId)
    });
    _channelInfo.adsConfig.signals = adsSignals;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_channelInfo.type)) {
    _channelInfo = updateADSAndSeasimComponent(_channelInfo);
  }

  yield put(updateChannelInfo(_channelInfo));
  //update hybrid boundaries
  if (!hybridStatus && _channelInfo.content.extraction && _channelInfo.content.extraction.hybrid) {
    yield put(updateHybridBoundaries(_channelInfo.verificationId, !_selectedSignals.length))
  }

  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER })
  //yield put(updateChannelContent({ selectedSignals: _selectedSignals }));
}

function* saveDesignClip(action) {
  let { info, verificationId, channelInfo: _info } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  let _channelInfo = _info ? _info : channelInfo;
  if (!verificationId) {
    verificationId = _channelInfo.verificationId;
    if (!verificationId) return;
  };
  try {
    yield call(saveClipDesignPromise, { designClipDTO: info, verificationId });
  } catch (error) {
    console.error(error);
  }
}

function* _saveConnectorModel(action) {
  const { model, component, modelList } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _components = [...channelInfo.content.components];
  const compIndex = _components.findIndex(item => item.name === component);
  if (compIndex > -1) {
    _components[compIndex].connectorModel = model ? JSON.parse(JSON.stringify(model)) : _components[compIndex].connectorModel;
    if (modelList && modelList.length) {
      _components[compIndex].modelList = [...modelList]
    } else {
      delete _components[compIndex].modelList;
    }
  }
  yield put(updateChannelContent({
    components: _components
  }));
}

// Update clip boundary after changing signals
export function* updateClipBoundary({ selectedSignals, isSimulate, channelInfo, isGetData = false }) {
  if (!selectedSignals.length) return;
  const { AndesV2Reducer: {
    channel: { channelInfo: reducerChannelInfo } } } = yield select();
  let _channelInfo = {};
  if (isSimulate) {
    _channelInfo = channelInfo || {};
  } else {
    _channelInfo = reducerChannelInfo || {};
  }

  const content = _channelInfo.content || {};
  const designId = _channelInfo.designId;

  let extraction = {};
  if (content && content.extraction) {
    extraction = content.extraction;
  }
  const port_setups = content.port_setups || [];
  const ports_generate_setup_list = content.ports_generate_setup_list || [];
  const referenceNets = content.referenceNets || [];
  const {
    signals = [],
  } = content;
  if (extraction.useDesignClip && extraction.clipping) {
    const clipSize = parseFloat(extraction.clipSize);
    yield delay(500);
    const clipData = generateBoundaryList({ clipSize, signals, selectedSignals, designId, port_setups, ports_generate_setup_list, referenceNets });
    if (isGetData) {
      return clipData;
    }
    if (clipData && clipData.data) {
      const info = clipData.data.toDesignClipObject();
      yield call(saveDesignClip, { info, channelInfo: isSimulate ? _channelInfo : null });
    }
    return clipData;
  }
}

function* _deletePwrGndNet(action) {
  const { netName } = action;
  const { AndesV2Reducer: { channel: {
    channelInfo: { id, designId,
      content: {
        powerNets = [],
        referenceNets = [],
        ports_generate_setup_list = [],
        port_setups = [],
        extraction = {}
      }
    } } } } = yield select();

  let _powerNets = [...powerNets],
    _referenceNets = [...referenceNets],
    _ports_generate_setup_list = [...ports_generate_setup_list],
    _port_setups = [...port_setups];

  _powerNets = powerNets.filter(item => item.name !== netName);

  if (_referenceNets.includes(netName)) {
    _referenceNets = _referenceNets.filter(item => item !== netName);
  }

  const isPortsUpdate = !compareRefNets(_referenceNets, referenceNets);
  if (isPortsUpdate) {
    //Re generation extraction ports
    const data = yield call(getPortSetups, {
      ports_generate_setup_list: _ports_generate_setup_list,
      designId: designId,
      _port_setups,
      _referenceNets,
      channelId: id,
      extractionType: getExtractionType(extraction)
    });
    _port_setups = data.port_setups;
    _ports_generate_setup_list = data.ports_generate_setup_list;
  }

  yield put(updateChannelContent({
    powerNets: _powerNets,
    referenceNets: _referenceNets,
    port_setups: _port_setups,
    ports_generate_setup_list: _ports_generate_setup_list
  }));
}

function* _updateADSConfig(action) {
  const { adsConfig, save, isCloseModal, id } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  // check numberOfBits
  let _adsConfig = { ...adsConfig };
  let numberOfBits = adsConfig.simulation && adsConfig.simulation.numberOfBits ? adsConfig.simulation.numberOfBits : null;
  if (isCloseModal && numberOfBits !== null) {
    _adsConfig.simulation.numberOfBits = Number(numberOfBits);
  }

  yield put(updateChannelInfo({ ...channelInfo, adsConfig: { ..._adsConfig } }));
  if (isCloseModal) {
    yield call(saveChannelContentToServer);
  } else if (save) {
    yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });
  }

  if (id) {
    //run ads flow
    yield put(startChannelExtraction([id], { runSimulation: true }));
  }
}

function* _updateCMCCompPinsConnect(action) {
  const { part, pinMap } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _channelInfo = { ...channelInfo };
  const isPreLayoutChannel = isPreLayout(_channelInfo.designId);
  if (isPreLayoutChannel) {
    return;
  }
  let _components = JSON.parse(JSON.stringify(channelInfo.content.components));

  let _signals = channelInfo.content.signals;
  _components.forEach(item => {
    if (item.part === part) {
      item.pinsConnection = JSON.parse(JSON.stringify(pinMap))
    }
  })
  let modifiedComponents = _components.filter(item => item.part === part);

  yield put(updateChannelContent({ components: _components }));

  if (modifiedComponents.length) {
    yield call(reIdentifyChannel, {
      channelInfo,
      modifiedComponents,
      components: _components,
      signals: _signals
    });
  }
}

function* reIdentifyChannel(action) {
  const { channelInfo, modifiedComponents, components, signals } = action;
  //get pcb
  const pcbInfo = DesignInfo.getPCBInfo(channelInfo.designId);
  const { netsList, layers } = pcbInfo;
  const { newSignals = [] } = updateComponentAndSignalsByCMC({
    modifiedComponents,
    components,
    signals,
    designId: channelInfo.designId,
    netsList,
    serdesType: channelInfo.type
  });
  for (let signal of newSignals) {
    yield call(_selectNets, {
      selectType: "reIdentify",
      signalName: signal.name,
      newNets: signal.newNets ? [...signal.newNets] : [],
      deletedNets: signal.deletedNets ? [...signal.deletedNets] : [],
      nets_P: signal.nets_P ? [...signal.nets_P] : [],
      nets_N: signal.nets_N ? [...signal.nets_N] : [],
      nets_A: signal.nets_A ? [...signal.nets_A] : [],
      nets_B: signal.nets_B ? [...signal.nets_B] : [],
      nets_C: signal.nets_C ? [...signal.nets_C] : [],
      cmcComponents: signal.cmcComponents || []
    })
  }

  //re generate ports setup by net components and reference nets
  const { AndesV2Reducer: { channel: { channelInfo: _channelInfo } } } = yield select();
  let content = _channelInfo.content || {};
  //default generation ports
  const ports_generate_setup_list = getPortGenerateSetupList({
    components: content.components,
    setup_list: content.ports_generate_setup_list,
    pageType: ANDES_V2
  });
  const referenceNets = getDefaultReferenceNets(content.powerNets, content.referenceNets);
  const findPortZ0 = content.port_setups && content.port_setups[0] ? content.port_setups[0].z0 : null;

  const defaultZ0 = _channelInfo.type === PCIE ? 42.5 : 50;
  const avoid_single_pin_group = content.port_setups && content.port_setups.length ? (content.port_setups[0].positive || {}).avoid_single_pin_group : null;
  let { port_setups, ports_generate_setup_list: setupList, errors, warnings } = getDefaultPortSetupList({
    components: content.components,
    signals: content.signals,
    pcbInfo: { netsList, layers },
    referenceNets,
    designId: _channelInfo.designId,
    ports_generate_setup_list,
    referenceZ0: findPortZ0 || defaultZ0,
    extractionType: getExtractionType(content.extraction),
    avoid_single_pin_group
  });
  //sort port setups by component and pin
  port_setups = sortPortSetups(port_setups, content.components, content.signals);
  content.referenceNets = referenceNets;
  content.port_setups = port_setups;
  content.ports_generate_setup_list = setupList;
  const generationPortErrors = errors;
  const generationPortWarnings = warnings;
  yield put(updateChannelContent({
    referenceNets,
    port_setups,
    ports_generate_setup_list
  }));
  yield put(updateGeneratePortsErrors({
    id: _channelInfo.id,
    errors: generationPortErrors,
    warnings: generationPortWarnings
  }))
}

function* _saveCompCMCModel(action) {
  const { part, pinList, files } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  let components = [...channelInfo.content.components];
  components.forEach(comp => {
    if (comp.part === part) {
      comp.model = {};
      comp.model.files = files ? JSON.parse(JSON.stringify(files)) : [];
      comp.model.pairs = pinList.map(item => { return { pin: item.pin, node: item.node || "", libraryId: item.libraryId || "" } })
    }
  });

  yield put(updateChannelContent({ components }));
}

function* saveDiodeModel(action) {
  const { part, model } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  let components = [...channelInfo.content.components];
  components.forEach(comp => {
    if (comp.part === part) {
      comp.model = { ...model };
    }
  });

  yield put(updateChannelContent({ components }));
}

// This method is not in use
function* _updateChannelConnection() {
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  const isPreLayoutChannel = isPreLayout(channelInfo.designId);
  if (isPreLayoutChannel) {
    return;
  }
  const { netsList } = DesignInfo.getPCBInfo(channelInfo.designId);
  channelConnection.initChannel(channelInfo, netsList);
}

export function* _saveHybridRegions(action) {
  let { hybridRegions, hybridLines, verificationId, saveHfssZones = false, clipData = {} } = action;

  if (!hybridLines && !saveHfssZones) {
    return;
  }
  try {

    let hybridClipDTO = {
      hybrid_regions: hybridRegions ? [...hybridRegions] : [],
      hybrid_lines: hybridLines ? [...hybridLines] : [],
      hfss_zones: []
    };

    if (saveHfssZones) {
      const channel = channelConstructor.getChannelByVerificationId(verificationId);
      if (!channel) {
        return;
      }

      if (!hybridRegions || !hybridLines) {
        const info = yield call(getHybridRegionInfo, { verificationId }) || {};
        hybridRegions = info.hybrid_regions || [];
        hybridLines = info.hybrid_lines || [];
      }
      if (!hybridRegions.length && !hybridLines.length) {
        return;
      }
      hybridClipDTO = {
        hybrid_regions: hybridRegions,
        hybrid_lines: hybridLines,
        hfss_zones: []
      };
      const hybridRegionsInfo = new HybridRegions({ channelId: channel.id, designId: channel.designId });
      hybridRegionsInfo.updateBoundariesToHybrid(clipData || {});
      const hfss_zones = yield call(hybridRegionsInfo.generateHybridRegions, {
        channelId: channel.id,
        designId: channel.designId,
        hybrid_regions: hybridRegions,
        hybrid_lines: hybridLines,
        isSave: true
      });
      const regionInfo = hybridRegionsInfo.getSavedRegion();
      if (regionInfo && regionInfo.hybridRegions && regionInfo.hybridLines) {
        hybridClipDTO.hybrid_regions = regionInfo.hybridRegions;
        hybridClipDTO.hybrid_lines = regionInfo.hybridLines;
      }
      hybridClipDTO.hfss_zones = hfss_zones;
    }
    const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
    if (channelInfo && channelInfo.verificationId === verificationId) {
      yield put(updateHybridInfo({
        hybridLines: hybridClipDTO.hybrid_lines,
        hybridRegions: hybridClipDTO.hybrid_regions,
        id: channelInfo.id
      }));
    }

    yield call(saveDesignHybridInfoPromise, {
      hybridClipDTO,
      verificationId
    });
  } catch (error) {
    console.error(error);
  }

}

function* getHybridRegionInfo(action) {
  const { verificationId } = action;
  try {
    const res = yield call(getDesignHybridInfoPromise, verificationId);
    return res && res ? res : null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function* _updateHybridBoundaries(action) {
  const { verificationId, clearRegions } = action;
  try {

    if (clearRegions) {
      yield call(saveDesignHybridInfoPromise, {
        hybridClipDTO: {
          hybrid_regions: [],
          hybrid_lines: [],
          hfss_zones: []
        },
        verificationId
      });
      return;
    }

    const info = yield call(getHybridRegionInfo, { verificationId }) || {};
    let hybridClipDTO = {
      hybrid_regions: info.hybrid_regions || [],
      hybrid_lines: info.hybrid_lines || [],
      hfss_zones: []
    }

    if (!hybridClipDTO.hybrid_lines || hybridClipDTO.hybrid_lines.length === 0) {
      return;
    }

    const channel = channelConstructor.getChannelByVerificationId(verificationId);
    if (!channel) {
      return;
    }
    const { AndesV2Reducer: {
      channel: { channelInfo } } } = yield select();
    //update hybrid lines and hybrid regions
    const hybridRegionsInfo = new HybridRegions({ channelId: channel.id, designId: channel.designId });
    //get clip design boundaries
    const clipData = yield call(updateClipBoundary, { selectedSignals: channelInfo.content.selectedSignals, channelInfo, isGetData: true });

    hybridRegionsInfo.updateBoundariesToHybrid(clipData || {});

    const { hybridRegions, hybridLines } = yield call(hybridRegionsInfo.generateHybridRegions, {
      channelId: channel.id,
      designId: channel.designId,
      hybrid_regions: hybridClipDTO.hybrid_regions,
      hybrid_lines: hybridClipDTO.hybrid_lines,
      isSave: true,
      updateLines: true
    });
    hybridClipDTO.hybrid_regions = hybridRegions;
    hybridClipDTO.hybrid_lines = hybridLines;

    yield call(saveDesignHybridInfoPromise, {
      hybridClipDTO,
      verificationId
    });
  } catch (error) {
    console.error(error);
  }
}

function* _saveCompPCBModel(action) {
  const { model, modelsList, component } = action;
  // modelsList is array

  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }
  // ["packageModel", "connectorModel", "cableModel","PCBModel"];
  let _components = [...channelInfo.content.components];
  const compIndex = _components.findIndex(item => item.name === component);
  if (compIndex > -1) {
    _components[compIndex].pcbModel = model ? JSON.parse(JSON.stringify(model)) : _components[compIndex].pcbModel;
    if (modelsList && modelsList.length) {
      _components[compIndex].modelList = [...modelsList]
    } else {
      delete _components[compIndex].modelList;
    }
  }

  yield put(updateChannelContent({
    components: _components
  }));
}

function* _updateICComponentPin(action) {
  const { record, compName, pinInfo } = action;
  //pinInfo:{pin,net}

  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  const prevPin = record[`component_${compName}`] || {},
    newPin = {
      pin: pinInfo.pin,
      net: pinInfo.net,
      signal: record.signal,
      component: compName
    };
  let deletePortPins = [];
  let _channelInfo = { ...channelInfo };
  let _components = [...channelInfo.content.components];
  const signals = [...channelInfo.content.signals];
  let _port_setups = channelInfo.content.port_setups ? [...channelInfo.content.port_setups] : [];
  const referenceNets = channelInfo.content.referenceNets ? [...channelInfo.content.referenceNets] : [];
  let ports_generate_setup_list = channelInfo.content.ports_generate_setup_list ? [...channelInfo.content.ports_generate_setup_list] : [];
  let adsConfig = channelInfo.adsConfig ? { ...channelInfo.adsConfig } : {};

  const compIndex = _components.findIndex(item => item.name === compName);
  if (compIndex > -1) {
    //delete same signal and comp pins
    const signal = signals.find(item => item.name === prevPin.signal) || {}
    // const netKey = record.netType === "positive"
    //   ? "nets_P"
    //   : (record.netType === "negative" && "nets_N");
    // const filterType = netKey === "nets_P" ? "nets_N" : "nets_P";
    let netKey = '', filterType = '';
    if (channelInfo.type === CPHY) {
      netKey = record.netType === "Line A" ? "nets_A" : record.netType === "Line B" ? "nets_B" : "nets_C";
      filterType = netKey === "nets_A" ? ["nets_B", "nets_C"] : netKey === "nets_B" ? ["nets_A", "nets_C"] : ["nets_A", "nets_B"];
    } else {
      netKey = record.netType === "positive" ? "nets_P" : "nets_N";
      filterType = netKey === "nets_P" ? ["nets_N"] : ["nets_P"];
    }
    const filterNets = filterType.map(item => signal[item] || []).flat(2);
    const nets = (signal[netKey] || []).filter(item => !filterNets.includes(item));
    //Find pins whose ports need to be deleted
    deletePortPins = _components[compIndex].pins.filter(item => nets.includes(item.net) && item.signal === record.signal);
    //delete same signal and comp pins
    _components[compIndex].pins = _components[compIndex].pins.filter(item => record.signal !== item.signal || !nets.includes(item.net));
    //add new signal comp pins
    _components[compIndex].pins.push({
      pin: pinInfo.pin,
      net: pinInfo.net,
      signal: record.signal,
    })

    if ((prevPin && prevPin.pin) || deletePortPins.length) {
      //delete ports by deleted comps
      const deleteComps = [];
      if (prevPin && prevPin.pin) {
        deleteComps.push({ name: compName, pin: prevPin.pin })
      }
      _port_setups = deletePortSetups({
        deletedComps: [
          ...deleteComps,
          ...deletePortPins.map(item => { return { ...item, name: compName } })
        ],
        port_setups: _port_setups
      });
    }

    //add extraction ports by new comps
    const resInfo = addPortSetups({
      newComps: [{
        name: compName,
        pins: [{ ...newPin }],
        type: _components[compIndex].type,
        part: _components[compIndex].part
      }],
      port_setups: _port_setups,
      ports_generate_setup_list,
      getDefaultPortSetupList,
      signals,
      referenceNets,
      pcbInfo: DesignInfo.getPCBInfo(channelInfo.designId),
      designId: channelInfo.designId,
      channelType: channelInfo.type,
      extractionType: getExtractionType(channelInfo.content.extraction)
    });
    _port_setups = resInfo.port_setups;
    ports_generate_setup_list = resInfo.ports_generate_setup_list;
    yield put(updateGeneratePortsErrors({ id: channelInfo.id, errors: resInfo.errors, warnings: resInfo.warnings }));
    //sort port setups by component and pin
    _port_setups = sortPortSetups(_port_setups, _components, signals);
    //if rx model use rc termination model,update pin map
    adsConfig = updateTerminationModelPinMap({
      adsConfig,
      component: compName,
      pins: _components[compIndex].pins,
      prevPin,
      newPin
    });
  }

  let isUpdateHspiceConfig = false;
  if (channelInfo.hspiceConfig && channelInfo.hspiceConfig.signals) {
    _channelInfo.hspiceConfig = updateHSPICEPin(_channelInfo.hspiceConfig, newPin, prevPin)
    isUpdateHspiceConfig = true;
  }

  _channelInfo.adsConfig = adsConfig;
  _channelInfo.content.components = _components;
  _channelInfo.content.port_setups = _port_setups;
  _channelInfo.content.ports_generate_setup_list = ports_generate_setup_list;
  yield put(updateChannelInfo(_channelInfo));
  yield put(updateHspiceConfigStatus(isUpdateHspiceConfig));
  yield put({ type: SAVE_PCB_CHANNEL_TO_SERVER });

  if (isEndToEndChildrenChannel(channelInfo) && compIndex > -1) {

    yield call(updateEndToEndPinMap, {
      endToEndId: channelInfo.endToEndId,
      adsConfig,
      component: compName,
      pins: _components[compIndex].pins,
      prevPin,
      newPin,
      channelId: channelInfo.id
    })
  }
}

function* editDiodeComponentPins(action) {
  const { part, components } = action;

  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  if (!channelInfo || !channelInfo.content) {
    return;
  }

  let _components = [...channelInfo.content.components];
  for (let comp of _components) {
    if (comp.part !== part) {
      continue;
    }
    const findComp = components.find(item => item.component === comp.name);
    if (!findComp) {
      continue;
    }
    comp.pins = findComp.selectedPins.filter(item => !!item.signal);
  }
  yield put(updateChannelContent({
    components: _components
  }));
}

export function* _expandChannel(action) {
  const { channelId, designId, addSweepToCache, treeItemsData } = action;
  const { AndesV2Reducer: { project: { openProjectId, treeItems } } } = yield select();
  let _channelId = channelId, _designId = designId, _treeItems = treeItemsData || treeItems;
  // filter prelayout
  const isPreLayoutChannel = isPreLayout(designId);
  if (!_channelId || !_designId || isPreLayoutChannel) {
    return;
  }
  const sweepList = yield call(getSweepListByChannelIdPromise, _channelId);
  const sweepInfo = getSweepsTree(sweepList);
  if (addSweepToCache) {
    sweepConstructor.addSweeps(sweepList);
  }
  _treeItems = sweepListToTree({ treeItems: _treeItems, projectId: openProjectId, designId: _designId, channelId: _channelId, sweepInfo });
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

export function* _changeSimulationSolver(action) {
  const { simulationSolver } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  const { content, hspiceConfig = {} } = channelInfo;
  const {
    components = [],
    signals = [],
  } = content;

  if (simulationSolver === HSPICE) {
    if (Object.values(hspiceConfig).length === 0) {
      const defaultHspiceConfig = initChannelHspiceConfig({ components, signals, interfaceType: PCB_CHANNEL });
      let _channelInfo = { ...channelInfo };
      _channelInfo.hspiceConfig = { ...defaultHspiceConfig };
      yield put(updateChannelInfo(_channelInfo))
    }
    yield put(updateSimulationSolverStatus(true))
  }

  yield put(updateChannelContent({ simulationSolver }));
  yield call(saveChannelContentToServer);
}

function* _updateHSPICEConfig(action) {
  const { hspiceConfig, id } = action;
  const { AndesV2Reducer: { channel: { channelInfo } } } = yield select();
  let _channelInfo = { ...channelInfo };
  _channelInfo.hspiceConfig = { ...hspiceConfig };
  yield put(updateChannelInfo(_channelInfo));
  yield call(saveChannelContentToServer);
  yield put(updateHspiceConfigStatus(true));
  if (id) {
    //run ads flow
    yield put(startChannelExtraction([id], { runSimulation: true }));
  }
}

function* channelSaga() {
  yield takeEvery(CREATE_CHANNEL, _createNewChannel);
  yield takeEvery(GET_DESIGN_CHANNEL_LIST, getDesignChannels);
  yield takeEvery(DELETE_CHANNEL, _delChannel);
  yield takeEvery(OPEN_PCB_CHANNEL, _openChannel);
  yield takeEvery(GET_CHANNEL_CONTENT, _getContent);
  yield takeEvery(EDIT_COMPONENT_TYPE, _editCompType);
  yield takeEvery(UPDATE_CHANNEL_CONTENT, _saveContent);
  yield takeEvery(SAVE_CHANNEL_EXTRACTION_CONFIG, _saveExtraction);
  yield takeLatest(AUTO_GET_CHANNEL_LIST, _autoGetChannelList);
  yield takeEvery(ADD_SIGNAL, _addNewSignal);
  yield takeEvery(SAVE_SELECT_NETS, _selectNets);
  yield takeEvery(CHANGE_SIGNAL_NAME, _updateSignalName);
  yield takeEvery(DELETE_SIGNAL, _deleteSignal);
  yield takeEvery(CHANGE_GROUP_NAME, _updateGroupName);
  yield takeEvery(SAVE_CHANNEL_CONFIG, _saveConfig);
  yield takeEvery(CHANNEL_REPLACE, _channelReplace);
  yield takeEvery(CHANNEL_RENAME, _renameChannel);
  yield takeEvery(UPDATE_REFERENCE_NETS, _updateRefNets);
  yield takeEvery(COPY_PCB_CHANNEL, _channelCopy);
  yield takeEvery(UPDATE_PORT_SETUPS_TO_SERVER, _updatePortsSetup);
  yield takeEvery(POWER_NETS_SELECTION, _updatePowerNet);
  yield takeEvery(POWER_NET_UPDATE_VOLTAGE, _updatePowerVoltage);
  yield takeEvery(SAVE_COMP_PACKAGE_MODEL, _savePkgModel);
  yield takeEvery(CHECK_SIGNALS, _checkedSignals);
  yield takeEvery(SAVE_DESIGN_CLIP_INFO, saveDesignClip)
  yield takeEvery(SAVE_COMP_CONNECTOR_MODEL, _saveConnectorModel);
  yield takeEvery(DELETE_PWR_GND_NET, _deletePwrGndNet);
  yield takeEvery(UPDATE_ADS_CONFIG, _updateADSConfig);
  yield takeEvery(EDIT_CMC_COMP_PINS_CONNECT, _updateCMCCompPinsConnect);
  yield takeEvery(SAVE_CMC_MODEL, _saveCompCMCModel);
  yield takeEvery(SAVE_COMP_DIODE_MODEL, saveDiodeModel);
  yield takeEvery(UPDATE_CHANNEL_CONNECTION, _updateChannelConnection);
  yield takeEvery(SAVE_CHANNEL_HYBRID_REGIONS, _saveHybridRegions);
  yield takeLatest(UPDATE_HYBRID_BOUNDARIES, _updateHybridBoundaries);
  yield takeEvery(SAVE_COMP_PCB_MODEL, _saveCompPCBModel);
  yield takeEvery(UPDATE_IC_COMPONENT_PIN, _updateICComponentPin);
  yield takeEvery(UPDATE_DIODE_COMPONENT_PINS, editDiodeComponentPins);
  yield takeEvery(EXPAND_CHANNEL, _expandChannel);
  yield takeEvery(CHANGE_SIMULATION_SOLVER, _changeSimulationSolver);
  yield takeEvery(UPDATE_HSPICE_CONFIG, _updateHSPICEConfig);
}

export default channelSaga;