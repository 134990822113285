import {
  PKG_SPICE,
  SPICE,
  VECTOR,
  PKG_TOUCHSTONE,
  IBIS,
  REPEATER,
  PASSIVE_SPICE,
  PASSIVE_TOUCHSTONE,
  CONNECTOR,
  CONNECTOR_TOUCHSTONE,
  CONNECTOR_SPICE_SIERRA,
  CABLE_TOUCHSTONE,
  EBD,
  VRM,
  DECAP_TOUCHSTONE,
  DECAP_SPICE,
  DECAP_RLC,
  IBIS_AMI,
  PCB_TOUCHSTONE,
  DECAP_MODEL_MAP,
  PIN_MAP,
  DRIVER,
  SOCKET_TOUCHSTONE,
  SOCKET_SPICE,
  SPICE_PARAMETERS,
  SOC_SPICE,
  SOC_WRAPPER,
  PCB_SUBCKT,
  PVT,
  SPICE_PARAMETERS_LIB,
  WIRE_BOND_XML,
  CUSTOM_TOUCHSTONE,
  CUSTOM_SPICE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  PCB_SPICE,
  EYE_MASK,
  ON_DIE_TOUCHSTONE,
  PCB_PDN_TOUCHSTONE,
  ON_DIE_SPICE,
  PCB_PDN_SPICE,
  GENERIC_TOUCHSTONE,
  LEQ_RESPONSE,
  USER_DEFINED_NETLIST,
  ADS_OPTIONS,
  CUSTOM_POST_PROCESS,
  CPM_SPICE,
  TRACE,
  POWERSI_OPTIONS,
  POWERDC_OPTIONS,
  BUFFER_SPICE
} from "../../constants/libraryConstants";
import {
  SYS_LIBRARY_FILE, CUSTOM_LIBRARY_FILE, CUSTOM_LIBRARY_DATA
} from '@/constants/treeConstants';
import { ANDES_V2, CASCADE, ROCKY } from "../../constants/pageType";

function getLibraryTitleSuffix(modelType) {
  let suffix = "";
  switch (modelType) {
    case SPICE:
    case REPEATER:
    case CONNECTOR:
    case CONNECTOR_SPICE_SIERRA:
    case SOC_SPICE:
    case CUSTOM_SPICE:
    case PCB_SPICE:
    case BUFFER_SPICE:
      suffix = 'Netlist';
      break;
    case IBIS:
    case VECTOR:
    case PASSIVE_TOUCHSTONE:
    case PASSIVE_SPICE:
    case PKG_TOUCHSTONE:
    case PKG_SPICE:
    case CONNECTOR_TOUCHSTONE:
    case CABLE_TOUCHSTONE:
    case EBD:
    case PCB_TOUCHSTONE:
    case SOCKET_TOUCHSTONE:
    case SOCKET_SPICE:
    case SPICE_PARAMETERS:
    case SOC_WRAPPER:
    case PCB_SUBCKT:
    case PVT:
    case SPICE_PARAMETERS_LIB:
    case CUSTOM_TOUCHSTONE:
    case HFSS_OPTIONS:
    case SIWAVE_OPTIONS:
    case POWERSI_OPTIONS:
    case POWERDC_OPTIONS:
    case EYE_MASK:
    case ON_DIE_TOUCHSTONE:
    case PCB_PDN_TOUCHSTONE:
    case GENERIC_TOUCHSTONE:
    case LEQ_RESPONSE:
    case ADS_OPTIONS:
      suffix = 'File';
      break;
    /*  case WIRE_BOND_XML:
       suffix = "profile";
       break; */
    default: break;
  }
  return suffix;
}

function getLibraryAccept(modelType) {
  switch (modelType) {
    case IBIS:
      return ".ibs";
    case SPICE:
    case PASSIVE_SPICE:
    case REPEATER:
    case CONNECTOR_SPICE_SIERRA:
    case CONNECTOR:
    case PCB_SPICE:
    case ON_DIE_SPICE:
    case PCB_PDN_SPICE:
    case DECAP_SPICE:
    case USER_DEFINED_NETLIST:
      return ".sp,.lib,.txt,.ckt,.cir";
    case CUSTOM_SPICE:
      return ".sp,.ploc,.lib,.txt,.ckt,.cir";
    case PKG_SPICE:
    case DRIVER:
    case SOCKET_SPICE:
    case CPM_SPICE:
      return ".sp,.lib,.txt,.ckt,.cir";
    case VECTOR:
      return ".vec";
    case EBD:
      return ".ebd";
    case DECAP_MODEL_MAP:
      return "xlsx";
    case SPICE_PARAMETERS:
      return ".inc";
    case SOC_WRAPPER:
      return ".wrapper";
    case PCB_SUBCKT:
      return "*";
    case PVT:
      return ".csv";
    case WIRE_BOND_XML:
    case POWERSI_OPTIONS:
    case POWERDC_OPTIONS:
      return ".xml";
    case HFSS_OPTIONS:
    case SIWAVE_OPTIONS:
    case ADS_OPTIONS:
      return ".yml";
    case EYE_MASK:
      return ".csv,.msk"
    default: return '*';
  }
}

function getLibrarySpecial(modelType, product) {
  let special = '';
  switch (modelType) {
    case SPICE:
      special = ['sierra', 'rocky', ANDES_V2].includes(product) ? 'spFolder' : '';
      break;
    case REPEATER:
      special = product === 'sierra' ? 'spFolder' : '';
      break;
    case PASSIVE_TOUCHSTONE:
    case CONNECTOR_TOUCHSTONE:
    case CABLE_TOUCHSTONE:
    case GENERIC_TOUCHSTONE:
      special = '.sNp';
      break;
    case PKG_TOUCHSTONE:
    case SOCKET_TOUCHSTONE:
    case CUSTOM_TOUCHSTONE:
    case ON_DIE_TOUCHSTONE:
    case PCB_PDN_TOUCHSTONE:
    case PCB_TOUCHSTONE:
      special = product === 'rocky' ? 'sNpZip' : '.sNp';
      break;
    case DECAP_TOUCHSTONE:
      special = 'sNpFolder';
      break;
    case IBIS_AMI:
      special = 'folder';
      break;
    case SOC_SPICE:
    case SPICE_PARAMETERS_LIB:
      special = 'file_folder';
      break;
    case PCB_PDN_SPICE:
    case USER_DEFINED_NETLIST:
    case ON_DIE_SPICE:
    case PCB_SPICE:
    case CUSTOM_SPICE:
      special = product === 'cascade' ? 'plocSpFolder' : product === 'rocky' ? 'spFolder' : ''
      break;
    case IBIS:
      special = ['rocky', 'sierra'].includes(product) ? "ibis_zip" : "";
      break;
    case BUFFER_SPICE:
      special = 'spFolder';
      break;
    case CPM_SPICE:
      special = 'cpmFolder';
      break;
    default: break;
  }
  return special;
}

function getLibraryTitle(type, product) {
  switch (type) {
    case IBIS:
      return 'IBIS';
    case SPICE:
      return product === ANDES_V2 ? 'Generic SPICE' : 'SPICE';
    case CONNECTOR:
      return 'Connector';
    case REPEATER:
      return 'Repeater';
    case VECTOR:
      return 'Vector';
    case PASSIVE_TOUCHSTONE:
      return 'Passive Touchstone';
    case PASSIVE_SPICE:
      return 'Passive SPICE';
    case PKG_TOUCHSTONE:
      return 'Package Touchstone';
    case PKG_SPICE:
      return 'Package SPICE';
    case CONNECTOR_TOUCHSTONE:
      return "Connector Touchstone";
    case CABLE_TOUCHSTONE:
      return "Cable Touchstone";
    case EBD:
      return "EBD";
    case CONNECTOR_SPICE_SIERRA:
      return "Connector SPICE";
    case VRM:
      return "VRM";
    case DECAP_TOUCHSTONE:
      return "Decap Touchstone";
    case DECAP_SPICE:
      return "Decap SPICE";
    case DECAP_RLC:
      return "Decap RLC";
    case SYS_LIBRARY_FILE:
    case CUSTOM_LIBRARY_FILE:
    case CUSTOM_LIBRARY_DATA:
      return "System Library";
    case IBIS_AMI:
      return "IBIS AMI Model";
    case PCB_TOUCHSTONE:
      return product === ROCKY ? "Signal Channel Model" : "PCB Touchstone Model";
    case DECAP_MODEL_MAP:
      return "Decap Model Map";
    case PIN_MAP:
      return "Pin Map";
    case DRIVER:
      return "Driver";
    case SOCKET_TOUCHSTONE:
      return "Socket Touchstone";
    case SOCKET_SPICE:
      return "Socket SPICE";
    case SPICE_PARAMETERS:
      return "Spice Parameters";
    case SOC_SPICE:
      return "Spice Netlist";
    case SOC_WRAPPER:
      return "Wrapper";
    case PCB_SUBCKT:
      return "PCB Model";
    case PVT:
      return "PVT";
    case SPICE_PARAMETERS_LIB:
      return "Spice Parameters Lib";
    case WIRE_BOND_XML:
      return "Wire Bond Profile";
    case CUSTOM_TOUCHSTONE:
      return product === CASCADE ? "Custom Touchstone" : "Custom Component Touchstone";
    case CUSTOM_SPICE:
      return product === CASCADE ? "Custom SPICE" : "Custom Component SPICE";
    case HFSS_OPTIONS:
      return "Extraction Options HFSS";
    case SIWAVE_OPTIONS:
      return "Extraction Options SIwave";
    case POWERSI_OPTIONS:
      return "Extraction Options PowerSI";
    case POWERDC_OPTIONS:
      return "Extraction Options PowerDC";
    case PCB_SPICE:
      return product === ROCKY ? "Signal Channel Model" : "PCB SPICE";
    case EYE_MASK:
      return "Eye Mask";
    case ON_DIE_TOUCHSTONE:
    case ON_DIE_SPICE:
      return "On-Die PDN Model";
    case PCB_PDN_TOUCHSTONE:
    case PCB_PDN_SPICE:
      return "PDN Model";
    case GENERIC_TOUCHSTONE:
      return "Generic Touchstone";
    case LEQ_RESPONSE:
      return "LEQ Response";
    case USER_DEFINED_NETLIST:
      return "User Defined Netlist File";
    case ADS_OPTIONS:
      return "ADS Options";
    case CUSTOM_POST_PROCESS:
      return "Custom Post-Processing";
    case TRACE:
      return "Trace Template";
    case BUFFER_SPICE:
      return "SPICE Model";
    case CPM_SPICE:
      return "CPM";
    default: return '';
  }
}

// for PDN and Cascade
function parseSPModelSelector(fileContent) {
  // convert the input into an Array of Strings
  if (!fileContent || typeof fileContent !== "string") {
    return { models: [], type: "" };
  }
  const lines = fileContent.match(/[^\r\n]+/g);
  let models = [];
  let modelNames = [];
  let inBLock = false;
  // normal: with no parameter, parameter:has six parameters (ModelName, Manuf, MfgPartNumber, Size, Cnom, Volt)
  let type = 'normal';
  let name = null, subcktIndex = null;
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.match(/^\.subckt/ig)) {
      let words = line.match(/[^\s\t]+/g);
      name = words[1] ? words[1] : null;
      if (!name) {
        subcktIndex = i + 1;
        continue;
      }
      subcktIndex = null;
      inBLock = true;
      // .subckt subcktName  port1 port2 ...
      const _ports = words.slice(2, words.length);
      if (modelNames.indexOf(name) < 0) {
        const model = { name, ports: _ports };
        models.push(model);
      }
      modelNames.push(name);
    } else if (subcktIndex === i && line.startsWith('+')) {
      // .subckt
      // + subcktName port1 port2 ...
      subcktIndex = null;
      let words = line.match(/[^\s\t]+/g);
      name = words[1] ? words[1] : null;
      if (!name) {
        continue;
      }
      inBLock = true;
      const _ports = words.slice(2, words.length);
      if (modelNames.indexOf(name) < 0) {
        const model = { name, ports: _ports };
        models.push(model);
      }
      modelNames.push(name);
    } else if (line.match(/^\.end/ig)) {
      name = null;
      inBLock = false;
      continue;
    } else if (inBLock && line.startsWith('+')) {
      let words = line.match(/[^\s\t]+/g);
      let _name = name;
      const index = models.findIndex(item => item.name === _name);
      let _words = words;
      if (words[0] === '+') {
        _words = words.slice(1, words.length);
      } else {
        _words[0] = _words[0].substr(1);
      }
      models[index].ports.push(..._words);
    } else if (inBLock && line.startsWith('* ')) {
      type = 'parameter';
      let parameter = line.slice(2).replace(/"/g, "").split("=");
      let model = { [parameter[0]]: parameter[1] };
      models[models.length - 1].parameter = { ...models[models.length - 1].parameter, ...model };
    }
    else {
      name = null;
      inBLock = false;
      continue;
    }
  };
  return { models, type };
}

function getLibrarySelectOptions(modelType) {

  // if ([SOC_WRAPPER, SOC_SPICE, PCB_SUBCKT].includes(modelType)) {
  //   return [{ title: "VDE Number", key: VDE_NUMBER, options: ["1", "2"], default: "1" }];
  // }
  return null;
}

export {
  getLibraryTitleSuffix,
  getLibraryAccept,
  getLibrarySpecial,
  getLibraryTitle,
  parseSPModelSelector,
  getLibrarySelectOptions
}