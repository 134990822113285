import { CLKTYPE, CUSTOM, ESPI, I2C, I2S, I3C, QSPI, SPI, UART, SPMI } from "../../PCBHelper/constants";
import { getDefaultName } from "../../helper/setDefaultName";
import { _maxPrefix } from "../../helper/split";

const COMBINE_SIGNAL_SUCCESS = 1, FAILED = 2;
export default class CombineSignals {
  constructor() {
    this.selectedSignals = {}; //all selected signals
    this.combineSignals = [];
    this.isOpenEdit = false;
    this.errorMsgs = [];
    this.config = {};// search signal nets config
    this.combinePcbIds = []
  }

  combinePCBSignals = ({ pcbInterfaceInfo, pcbConfig }) => {
    this.pcbConfig = pcbConfig;
    this.getPCBSelectedSignals(pcbInterfaceInfo)
    if (!Object.keys(this.selectedSignals).length) {
      return []
    }
    this.setDefaultCombineSignals();
    this.combineSignalsByType();
    return {
      combineInterfaces: [{ index: "1", dataList: this.combineSignals }],
      selectedSignalKeys: ["1"]
    };
  }

  getPCBSelectedSignals = (pcbInterfaceInfo) => {
    this.selectedSignals = {};
    for (let pcbId of Object.keys(pcbInterfaceInfo || {})) {
      if (pcbInterfaceInfo[pcbId].selectKeys.length !== 1) {
        continue;
      }
      const findSelected = pcbInterfaceInfo[pcbId].allInterfaceCompNetData.find(it => pcbInterfaceInfo[pcbId].selectKeys.includes(it.interfaceKey))
      if (!findSelected) {
        continue;
      }
      this.selectedSignals[pcbId] = {
        pcbs: findSelected.pcbs,
        interfaceKey: findSelected.interfaceKey,
        content: findSelected.content,
        type: findSelected.type
      }
      this.interfaceType = findSelected.type;
    }
  }

  setDefaultCombineSignals = () => {
    const pcbIds = Object.keys(this.selectedSignals);
    this.maxSignalsPcbId = pcbIds.sort((a, b) => this.selectedSignals[b].content.length - this.selectedSignals[a].content.length)[0];
    this.combinePcbIds = pcbIds.filter(item => item !== this.maxSignalsPcbId);
    let signalNames = []
    for (let signal of this.selectedSignals[this.maxSignalsPcbId].content || []) {
      let signalName = signal.signalMergeName;
      if (signalNames.includes(signalName)) {
        signalName = getDefaultName({ nameList: signalNames, key: "", defaultKey: signalName, firstIndex: 1, delimiter: "_" });
      }
      let combineSignal = {
        pcbConnectionList: [[...pcbIds]],
        pcbConns: [...pcbIds],
        signal: signal.name,
        type: this.selectedSignals[this.maxSignalsPcbId].type,
        signalList: {
          [this.maxSignalsPcbId]: [{
            name: signal.name,
            signalMergeName: signal.signalMergeName,
            nets: [...signal.nets],
            type: this.selectedSignals[this.maxSignalsPcbId].type,
            interfaceKey: this.selectedSignals[this.maxSignalsPcbId].interfaceKey
          }]
        },
        signalName,
        signalNetName: signal.signalMergeName
      }
      this.combineSignals.push(combineSignal);
    }
  }

  combineSignalsByType = () => {
    switch (this.interfaceType) {
      case CUSTOM:
      case CLKTYPE:
        this.combineCLKAndCustomSignals();
        break;
      case UART:
        this.combineUARTSignals();
        break;
      case I2C:
      case I3C:
      case SPMI:
        this.combineI2CI3CSPMISignals();
        break;
      case I2S:
        this.combineI2SSignals();
        break;
      case SPI:
      case QSPI:
      case ESPI:
        this.combineSpiQspiEspiSignals();
        break;
      default:
        this.combineStatus = FAILED;
        this.errorMsgs.push("The current type of signals does not support the combine.")
        break;
    }
  }

  combineCLKAndCustomSignals = () => {
    for (let signal of this.combineSignals) {

      for (let id of this.combinePcbIds) {
        const _signalItem = this.selectedSignals[id].content[0];
        signal.signalList[id] = [{
          name: _signalItem.name,
          signalMergeName: _signalItem.signalMergeName,
          nets: [..._signalItem.nets],
          type: this.selectedSignals[id].type,
          interfaceKey: this.selectedSignals[id].interfaceKey
        }]
      }
    }
  }

  combineUARTSignals = () => {
    //tx signal / rx signal
    const advancedConfig = this.pcbConfig[this.maxSignalsPcbId].advancedConfig || {};
    const TXList = advancedConfig.TX, RXList = advancedConfig.RX;

    for (let signal of this.combineSignals) {
      const currSignal = signal.signalList[this.maxSignalsPcbId][0].name;
      let regList = [];
      if (TXList.includes(currSignal) || TXList.find(item => currSignal.match(item))) {
        regList = [...TXList]
      } else if (RXList.includes(currSignal) || RXList.find(item => currSignal.match(item))) {
        regList = [...RXList]
      }

      for (let id of this.combinePcbIds) {
        const _signalItem = this.selectedSignals[id].content.find(item => regList.includes(item.name)
          || regList.find(key => item.name.match(key)));
        if (!_signalItem) {
          continue;
        }
        signal.signalList[id] = [{
          name: _signalItem.name,
          signalMergeName: _signalItem.signalMergeName,
          nets: [..._signalItem.nets],
          type: this.selectedSignals[id].type,
          interfaceKey: this.selectedSignals[id].interfaceKey
        }]
      }
    }
    this.combineStatus = COMBINE_SIGNAL_SUCCESS;
  }

  combineI2CI3CSPMISignals = () => {
    //clk signal (only one), data signals (one or more)
    const advancedConfig = this.pcbConfig[this.maxSignalsPcbId].advancedConfig || {};
    const CLKList = advancedConfig.CLK, DataList = advancedConfig.Data;

    for (let signal of this.combineSignals) {
      const currSignal = signal.signalList[this.maxSignalsPcbId][0].name;
      let regList = [];
      if (CLKList.includes(currSignal) || CLKList.find(item => currSignal.match(item))) {
        regList = [...CLKList]
      } else if (DataList.includes(currSignal) || DataList.find(item => currSignal.match(item))) {
        regList = [...DataList]
      }

      for (let id of this.combinePcbIds) {
        const _signalItemList = this.selectedSignals[id].content.filter(item => regList.includes(item.name)
          || regList.find(key => item.name.match(key)));
        if (!_signalItemList.length) {
          continue;
        }
        let _signalItem = _signalItemList[0];
        if (_signalItemList.length > 1 && (DataList.includes(currSignal) || DataList.find(item => currSignal.match(item)))) {
          const dataFilter = DataList.find(item => item === currSignal || currSignal.match(item));
          _signalItem = _signalItemList.find(item => dataFilter && item.name === dataFilter);
          if (!_signalItem) {
            const maxSames = _signalItemList
              .map(item => { return { ...item, sameKey: _maxPrefix(item.name, currSignal) } })
              .sort((a, b) => b.sameKey.length - a.sameKey.length);
            _signalItem = maxSames[0] || _signalItemList[0];
          }
        }
        if (!_signalItem) {
          continue;
        }
        signal.signalList[id] = [{
          name: _signalItem.name,
          signalMergeName: _signalItem.signalMergeName,
          nets: [..._signalItem.nets],
          type: this.selectedSignals[id].type,
          interfaceKey: this.selectedSignals[id].interfaceKey
        }]
      }
    }
  }

  combineI2SSignals = () => {
    //clk signal (only one), data signals (one or more), ws signals (0 or 1)
    const advancedConfig = this.pcbConfig[this.maxSignalsPcbId].advancedConfig || {};
    const CLKList = advancedConfig.CLK,
      WS = advancedConfig.WS,
      SD = advancedConfig.SD,
      MCLK = advancedConfig.MCLK;

    for (let signal of this.combineSignals) {
      const currSignal = signal.signalList[this.maxSignalsPcbId][0].name;
      let regList = [];
      if (CLKList.includes(currSignal) || CLKList.find(item => currSignal.match(item))) {
        regList = [...CLKList]
      } else if (WS.includes(currSignal) || WS.find(item => currSignal.match(item))) {
        regList = [...WS]
      } else if (MCLK.includes(currSignal) || MCLK.find(item => currSignal.match(item))) {
        regList = [...MCLK]
      } else if (SD.includes(currSignal) || SD.find(item => currSignal.match(item))) {
        regList = [...SD]
      }

      for (let id of this.combinePcbIds) {
        const _signalItemList = this.selectedSignals[id].content.filter(item => regList.includes(item.name)
          || regList.find(key => item.name.match(key)));
        if (!_signalItemList.length) {
          continue;
        }

        let _signalItem = _signalItemList[0];
        if (_signalItemList.length > 1) {
          const dataFilter = regList.find(item => item === currSignal);
          _signalItem = _signalItemList.find(item => dataFilter && item.name === dataFilter);
          if (!_signalItem) {
            const maxSames = _signalItemList
              .map(item => { return { ...item, sameKey: _maxPrefix(item.name, currSignal) } })
              .sort((a, b) => b.sameKey.length - a.sameKey.length);
            _signalItem = maxSames[0] || _signalItemList[0];
          }
        }
        if (!_signalItem) {
          continue;
        }
        signal.signalList[id] = [{
          name: _signalItem.name,
          signalMergeName: _signalItem.signalMergeName,
          nets: [..._signalItem.nets],
          type: this.selectedSignals[id].type,
          interfaceKey: this.selectedSignals[id].interfaceKey
        }]
      }
    }
  }

  combineSpiQspiEspiSignals = () => {
    //clk signal (only one), data signals (one or more), ws signals (0 or 1)
    const advancedConfig = this.pcbConfig[this.maxSignalsPcbId].advancedConfig || {};
    const CLKList = advancedConfig.CLK, ChipSelect = advancedConfig.ChipSelect;

    const _getSignalNameType = this.getSignalNameType;
    function findSameTypeSignal(list, regList, currSignal, dataList, nameType) {
      return list.filter(item =>
        (regList.length &&
          (regList.includes(item.name) || regList.find(key => item.name.match(key))))//clk
        || (currSignal === item.name
          || nameType === item.name
          || nameType === _getSignalNameType(dataList, item.name)));//data->miso...;
    }

    for (let signal of this.combineSignals) {
      const currSignal = signal.signalList[this.maxSignalsPcbId][0].name;
      let regList = [], dataList = [], nameType = "";
      if (CLKList.includes(currSignal) || CLKList.find(key => currSignal.match(key))) {
        regList = [...CLKList];
      } else if (ChipSelect.includes(currSignal) || ChipSelect.find(key => currSignal.match(key))) {
        dataList = [...ChipSelect];
        nameType = this.getSignalNameType(dataList, currSignal);
      } else {
        dataList = [...(advancedConfig[signal.type] || [])];
        nameType = this.getSignalNameType(dataList, currSignal);
      }

      for (let id of this.combinePcbIds) {
        let _signalItemList = findSameTypeSignal(this.selectedSignals[id].content, regList, currSignal, dataList, nameType);//data->miso...;
        if (!_signalItemList.length) {
          continue;
        }

        let _signalItem = _signalItemList[0];
        if (_signalItemList.length > 1 && nameType) {
          _signalItem = _signalItemList.find(item => item.name === currSignal || nameType === item.name);
          if (!_signalItem) {
            const maxSames = _signalItemList
              .map(item => { return { ...item, sameKey: _maxPrefix(item.name, currSignal) } })
              .sort((a, b) => b.sameKey.length - a.sameKey.length);
            _signalItem = maxSames[0] || _signalItemList[0];
          }
        }
        if (!_signalItem) {
          continue;
        }
        signal.signalList[id] = [{
          name: _signalItem.name,
          signalMergeName: _signalItem.signalMergeName,
          nets: [..._signalItem.nets],
          type: this.selectedSignals[id].type,
          interfaceKey: this.selectedSignals[id].interfaceKey
        }]
      }
    }
  }

  getSignalNameType = (dataList, signalName) => {
    let nameType = signalName;
    const nameTypes = dataList.filter(item => signalName.match(item));
    if (nameTypes.length > 1) {
      nameType = nameTypes.sort((a, b) => b.length - a.length)[0]
    } else {
      nameType = nameTypes[0] || signalName;
    }
    return nameType;
  }

  findSignalType = (signalName) => {
    switch (signalName) {
      case "MISO":
      case "SO":
      case "DO":
        return "MISO";
      case "MOSI":
      case "SI":
      case "DI":
        return "MOSI";
      default: return signalName;
    }
  }
}

function getAutoCombineInterfaces({ pcbInterfaceInfo, pcbConfig }) {
  const combineSignals = new CombineSignals();
  return combineSignals.combinePCBSignals({ pcbInterfaceInfo, pcbConfig })
}

export {
  getAutoCombineInterfaces
}