import React, { Component, Fragment } from 'react';
import { Switch, Input, Divider } from 'antd';
import { scaleConversion } from '@/services/helper/numberHelper';
import { valueCheck } from '@/services/helper/valueCheck';
import { numberCheck } from '@/services/helper/dataProcess';
import NP from 'number-precision';
import { getUnitOptions } from '@/services/helper/extractionOptionsHelper';
import UnitAddonAfter from '@/components/UnitAddonAfter';
import { ANDES_V2 } from '@/constants/pageType';
import ClipDesign from './ClipDesign';
import HybridExtraction from './hybridExtraction';
import { PACKAGE } from '../../../../constants/designType';
import ExtractionAdvancedPanel from './extractionAdvancedPanel';
import debounce from '@/services/helper/debounceFn';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import { HFSS_OPTIONS, SIWAVE_OPTIONS } from '../../../../constants/libraryConstants';
import ExtractionYaml from '../../../../components/ExtractionYaml';
import MultiZoneOptions from '../../../../components/ExtractionOptions/MultiZoneOptions';
import designConstructor from '../../../../services/helper/designConstructor';
import './index.css';

class ExtractionOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedPanel: false,
      warningMsg: null
    }
  }

  unitSelect = (key, lic_wait_unit) => {
    const { units, allowModify, extraction = {} } = this.props;
    let unitValue = "", unitKey = `${key}Unit`, options = [];

    if (key === "lic_wait_unit") {
      unitValue = lic_wait_unit;
      unitKey = key;
      options = ["min", "hour"];
    } else {
      options = getUnitOptions(key);
      const unitKey = `${key}Unit`;
      unitValue = units[unitKey];
    }

    let disabled = false;
    const { hfss = {}, siwave = {}, enableLogSweep } = extraction
    if (key === "minSolvedFreq") {
      disabled = !hfss.specifyMinSolvedFreq || !hfss.advDCExtrapolation;
    } else if (key === "meshFrequency") {
      disabled = siwave.meshAutomatic
    } else if (key === 'logSweepMax') {
      disabled = !allowModify || !enableLogSweep;
    } else {
      disabled = !allowModify;
    }
    return UnitAddonAfter({
      unit: unitValue,
      changeUnit: (e) => this.changeUnit(e, key, unitKey),
      list: options,
      disabled
    })
  }

  changeUnit = (key, type, typeUnit) => {
    let units = this.props.units;
    const { extraction } = this.props;
    let config = { ...extraction };

    if (type === "lic_wait_unit") {
      config.lic_wait_unit = key;
      this.props.updateChannelExtractionConfig(config);
      return;
    }

    if (units[typeUnit] === key) {
      return;
    }

    const scale = scaleConversion(key, units[typeUnit]);
    config[type] = config[type] && !numberCheck(config[type]) ? NP.times(config[type], scale).toString() : config[type];
    this.props.updateUnit(key, typeUnit);
    this.props.updateChannelExtractionConfig(config);
  }

  changeConfigValue = (e, type, subType) => {
    let value = e.target.value;
    const { extraction, error } = this.props;
    let _type = type;
    let config = { ...extraction };
    if (["hpc_options", "siwave", 'hfss'].includes(type)) {
      config[type][subType] = value;
    } else {
      config[type] = value;
    }
    if (error && error.type === _type) {
      this.props.updateError(null);
    }
    this.props.updateChannelExtractionConfig(config);
  }

  inputBlur = (e, inputType, subType) => {
    const { extraction, totalThickness } = this.props;
    let value = extraction[inputType];
    let _type = inputType;

    if (["hpc_options", "siwave", 'hfss'].includes(inputType)) {
      value = extraction[inputType][subType];
      _type = subType;
    }

    let config = { ...extraction };
    let error = null;
    const { units } = this.props;

    const unit = units[`${_type}Unit`];
    //max freq allow set "";
    if (!["airHorExt", "airPosZExt"].includes(_type) && (_type !== "maxFreq" || value)) {
      error = valueCheck({
        value: String(value),
        inputType: _type,
        unit,
        version: ANDES_V2,
        totalThickness
      })
    }

    if (error) {
      e.target.focus();
      this.props.updateError({ type: _type, error });
    } else {
      if (["hpc_options", "siwave", 'hfss'].includes(inputType)) {
        config[inputType][subType] = value;
      } else {
        config[inputType] = value;
      }

      const prevError = this.props.error;
      if (prevError && (prevError.type === _type || prevError.type === "judge")) {
        this.props.updateError(null);
      }
      this.props.updateChannelExtractionConfig(config);
    }
  }

  switchChange = (checked, type, subType) => {
    let config = { ...this.props.extraction };
    if (['hfss', 'siwave'].includes(type)) {
      config[type][subType] = checked;
      this.props.updateChannelExtractionConfig(config);
    } else if (["hpcOptionSwitch", "licWaitSwitch", "timeoutSwitch"].includes(type)) {
      this.props.changeSwitch(checked, type);
    } else {
      if (type === "saveResultFolder") {
        if (checked === true) {
          this.setState({
            warningMsg: { errorType: "warning", error: "The result folder could be extremely large", advancedKey: "Misc" }
          })
          debounce(() => {
            this.setState({
              warningMsg: null
            })
          }, 5000, false)()
        } else if (this.state.warningMsg) {
          this.setState({
            warningMsg: null
          })
        }
      }
      config[type] = checked;
      this.props.updateChannelExtractionConfig(config);
    }
  }

  cleanWarningMsg = () => {
    if (this.state.warningMsg) {
      this.setState({
        warningMsg: null
      })
    }
  }

  clickAdvanced = (e, key) => {
    e.stopPropagation();
    this.props.changeAdvancesClipDesign(true)
    this.setState({
      showAdvancedPanel: true,
      advancedKey: key
    })
  }

  closeAdvancesPanel = () => {
    this.props.changeAdvancesClipDesign(false)
    this.setState({
      showAdvancedPanel: false,
      warningMsg: null
    })
  }

  switchMultiZone = (checked, type) => {
    const { extraction } = this.props;
    let config = { ...extraction };
    if (type === 'multiZone') {
      config['multiZone'] = checked ? { ...config['multiZone'], stackupMode: 'Multizone' } : { ...config['multiZone'], stackupMode: 'Laminate' };
    } else {
      config['multiZone'] = { ...config['multiZone'], [type]: checked }
    }
    this.props.updateChannelExtractionConfig(config);
  }

  multiZoneRender = (type) => {
    const { simulateHFSS, extraction, designId } = this.props;
    const { multiZone } = extraction || {};
    const isMultiZone = designConstructor.getDesignMultiZone(designId);

    return <Fragment>
      <MultiZoneOptions
        isMultiZone={!simulateHFSS ? false : isMultiZone}
        zoneOptions={{
          multiZone: multiZone && multiZone.stackupMode && multiZone.stackupMode === 'Multizone',
          enableBends: !multiZone || multiZone.enableBends === undefined ? true : multiZone.enableBends
        }}
        isPowerSI={false}
        changeBooleanSwitch={this.switchMultiZone}
        className="extraction-content"
        hideDivider={true}
      />
    </Fragment>
  }

  render() {
    const { allowModify, extraction = {}, hpcOptionSwitch, hybrid, isShowHybridPreview, designId, verificationId,
      hybridRegions, hybridLines, hybridLoading, hybridRegionsInfo, designType, hybridErrorMsg, getHybridRegionsHFSSError, simulateHFSS } = this.props;
    const { backdrillStubSize, backdrillVias, includeDC, exactDC, enableLogSweep, siwave, type
    } = extraction;
    const { showAdvancedPanel, advancedKey, warningMsg } = this.state;
    const displayHfssOptionList = simulateHFSS && extraction.hfss && extraction.hfss.hfssOptionLibraryId ? false : true;

    return (
      <div className={`andes_v2-channel-extraction-main ${allowModify ? "" : "extraction-gray"}`}>
        <Fragment>
          <ExtractionYaml
            extraction={extraction}
            simulateHFSS={simulateHFSS}
            ansysType={type}
            extractionHfssOptionsList={libraryConstructor.getLibraryValues(HFSS_OPTIONS)}
            extractionSiwaveOptionsList={libraryConstructor.getLibraryValues(SIWAVE_OPTIONS)}
            updateChannelExtractionConfig={this.props.updateChannelExtractionConfig}
          />
          <Divider />
        </Fragment>
        {hybrid ? <Fragment><div className='extraction-content'>
          <HybridExtraction
            hybrid={hybrid}
            hybridRegionsInfo={hybridRegionsInfo}
            verificationId={verificationId}
            designId={designId}
            hybridRegions={hybridRegions}
            hybridLines={hybridLines}
            hybridLoading={hybridLoading}
            updateHybridRegions={this.props.updateHybridRegions}
            hybridErrorMsg={hybridErrorMsg}
            getHybridRegionsHFSSError={getHybridRegionsHFSSError}
            suggestHFSSRegions={siwave && siwave.suggestHFSSRegions}
            switchRegionsChange={this.props.switchRegionsChange}
          />
        </div>
          <Divider />
        </Fragment> : null}

        {/* Frequency Sweep */}
        <div className='extraction-content'>
          <span className='extraction-content-title'>Frequency Sweep</span>
          <span className='extraction-content-advanced' onClick={(e) => this.clickAdvanced(e, 'Frequency Sweep')}>Advanced</span>
        </div>
        <div className='extraction-content'>
          <span className='extraction-content-body'>Include DC</span>
          <Switch
            disabled={!allowModify}
            size="small"
            className="aurora-switch-small"
            checked={includeDC}
            onChange={(e) => this.switchChange(e, "includeDC")}
          />
          <div className='extraction-content-sub-body'>
            <span className='extraction-content-body'>Exact DC Solver</span>
            <Switch
              size="small"
              checked={exactDC}
              className="aurora-switch-small"
              onChange={(e) => this.switchChange(e, "exactDC")}
              disabled={!allowModify || !includeDC}
            />
          </div>
        </div>
        <div className='extraction-content'>
          <span className='extraction-content-body extraction-content-log-sweep-body'>Low frequency log sweep</span>
          <Switch
            disabled={!allowModify}
            size="small"
            className="aurora-switch-small"
            checked={enableLogSweep}
            onChange={(e) => this.switchChange(e, "enableLogSweep")}
          />
        </div>
        <Divider />

        {/* {Modeling} */}
        <div className='extraction-content'>
          <span className='extraction-content-title'>Modeling</span>
          <span className='extraction-content-advanced' onClick={(e) => this.clickAdvanced(e, 'Modeling')}>Advanced</span>
        </div>
        <div className='extraction-content'>
          <ClipDesign
            onRef={this.props.onRef}
            saveClipOptions={this.props.saveClipOptions}
            hybrid={hybrid}
            hybridRegionsInfo={hybridRegionsInfo}
            isShowHybridPreview={isShowHybridPreview}
            showHybridRegions={this.props.showHybridRegions}
            updateHybridRegions={this.props.updateHybridRegions}
            _updatePreviewButtonStatus={this.props._updatePreviewButtonStatus}
            suggestHFSSRegions={siwave && siwave.suggestHFSSRegions}
          />
        </div>
        {designType !== PACKAGE ? <Fragment>
          {/* <Divider /> */}
          <div className='extraction-content extraction-content-back-drill'>
            <span className="extraction-content-body">Back Drill Vias</span>
            <Switch
              disabled={!allowModify}
              size="small"
              className="aurora-switch-small"
              checked={backdrillVias}
              onChange={(checked) => this.switchChange(checked, "backdrillVias")}
            />
            {/* backdrillStubSize [1, 50] */}
            <div className='extraction-content-back-drill-input'>
              <span className='extraction-content-body'>Back Drill Stub Size</span>
              <Input
                className='extraction-input extraction-input-width'
                value={backdrillStubSize}
                addonAfter={"mil"}
                onChange={(e) => this.changeConfigValue(e, 'backdrillStubSize')}
                disabled={!allowModify || !backdrillVias}
                onBlur={(e) => this.inputBlur(e, 'backdrillStubSize')}
              />
            </div>
          </div>
          {/* Multi Zone */}
          {this.multiZoneRender()}
        </Fragment> : null}
        <Divider />

        {/* {misc} */}
        <div className='extraction-content'>
          <span className='extraction-content-title'>Misc</span>
          <span className='extraction-content-advanced' onClick={(e) => this.clickAdvanced(e, 'Misc')}>Advanced</span>
        </div>
        <div className='extraction-content extraction-content-hpc'>
          <span className='extraction-content-body'>Custom HPC</span>
          <Switch
            disabled={!allowModify}
            size="small"
            className="aurora-switch-small"
            checked={hpcOptionSwitch}
            onChange={(e) => this.switchChange(e, "hpcOptionSwitch")}
          />
        </div>
        {showAdvancedPanel ? <ExtractionAdvancedPanel
          closeAdvancesPanel={this.closeAdvancesPanel}
          switchChange={this.switchChange}
          inputBlur={this.inputBlur}
          changeConfigValue={this.changeConfigValue}
          changeUnit={this.changeUnit}
          unitSelect={this.unitSelect}
          advancedKey={advancedKey}
          warningMsg={warningMsg}
          cleanWarningMsg={this.cleanWarningMsg}
          displayHfssOptionList={displayHfssOptionList}
          {...this.props}
        /> : null}
      </div>
    )
  }
}

export default ExtractionOptions;