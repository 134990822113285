const EXTRACTION_OPTIONS = 'extraction_options',
  MODELING = "modeling",
  EXTRACTION_PORTS_SETUP = "extraction_ports_setup",
  SIMULATE = "simulate",
  DEBUG = "debug",
  SEASIM = "SEASIM",
  TOP_BAR_RESULT = "top_bar_result",
  AMI_SETUP = "ami_setup",
  REPORT = "report",
  SERVICES_OPTIONS = "services_options",
  DOWNLOAD = 'download',
  RUN = 'run',
  HSPICE_SIMULATE = 'hspice_simulate',
  HSPICE_SIMULATION = 'hspice_simulation',
  LAYOUT = 'layout',
  PADSTACKS = 'via_padstacks',
  STACKUP = 'stackup';


const ANDES_V2_TOP_BAR_OPTIONS = [
  {
    title: 'Modeling',
    key: SIMULATE,
    icon: {
      iconType: "iconfont",
      className: "icon-icons8-circuit andes-v2-top-bar-circuit-icon"
    }
  },
  {
    title: 'Modeling',
    key: MODELING,
    icon: {
      iconType: "iconfont",
      className: "icon-icons8-circuit andes-v2-top-bar-circuit-icon"
    }
  },
  {
    title: 'Compliance',
    key: SEASIM,
    icon: {
      type: "play-circle"
    }
  },
  {
    title: 'Simulation',
    key: AMI_SETUP,
    icon: {
      type: "play-circle"
    }
  },
  {
    title: 'HSPICE Simulation',
    key: HSPICE_SIMULATION,
    icon: {
      type: "setting"
    }
  },
  {
    title: 'Report',
    key: REPORT
  },
  {
    title: 'Layout',
    key: LAYOUT,
    icon: {
      iconType: "iconfont",
      className: "iconfont icon-stack aurora-top-bar-stackup-icon"
    },
    children: [
      {
        title: 'Via Padstacks',
        key: PADSTACKS
      },
      {
        title: 'Stackup',
        key: STACKUP
      }
    ]
  },
  {
    title: 'Service Options',
    key: SERVICES_OPTIONS,
    icon: {
      type: "setting"
    }
  },
  {
    title: 'Modeling',
    key: RUN,
    icon: {
      type: "play-circle"
    }
  },
  {
    title: 'Download',
    key: DOWNLOAD,
    icon: {
      type: "download"
    }
  },
  {
    title: 'Result',
    key: TOP_BAR_RESULT,
    className: "andes-v2-top-bar-result",
    icon: {
      type: "right",
      className: "andes-v2-top-bar-result-icon"
    }
  },
  {
    title: 'Debug',
    key: DEBUG,
    className: "andes_v2_top-bar-debug",
    icon: {
      iconType: "iconfont",
      className: "icon-Debug andes-v2-top-bar-debug-icon"
    }
  }
];

export {
  ANDES_V2_TOP_BAR_OPTIONS,
  EXTRACTION_OPTIONS,
  SIMULATE,
  DEBUG,
  SEASIM,
  MODELING,
  EXTRACTION_PORTS_SETUP,
  TOP_BAR_RESULT,
  AMI_SETUP,
  REPORT,
  SERVICES_OPTIONS,
  DOWNLOAD,
  RUN,
  HSPICE_SIMULATE,
  HSPICE_SIMULATION,
  LAYOUT,
  PADSTACKS,
  STACKUP
}