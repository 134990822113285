import React, { Component } from 'react';
import EditableTable from '@/components/EditableTable';
import { Row, Col } from 'antd';
import './index.css';

const columns = [{
  title: "",
  key: "key0",
  dataIndex: "key0",
  width: 212
},
{
  title: "",
  key: "value0",
  dataIndex: "value0"
},
{
  title: "",
  key: "key1",
  dataIndex: "key1",
  width: 212
},
{
  title: "",
  key: "value1",
  dataIndex: "value1"
}]
class NameTable extends Component {
  constructor(props) {
    super(props);
    for (let item of columns) {
      if (item.key.includes("key")) {
        item.render = (text, record) => {
          const info = record[item.key] || {};
          return <span
            className="spice-card-name-title"
            style={{ backgroundColor: info.color || "" }}
          >{info.name}</span>
        }
      } else {
        item.render = (text, record) => {
          const info = record[item.key] || {};
          return <div className={!info.value ? 'spice-card-table-td' : ""}>
            <span>{info.value}</span>
          </div>
        }
      }
    }
  }


  render() {
    const { nameList } = this.props;
    return <Row className='space-16'>
      <Col span={24} className='aurora-setup-border'>
        <span className="font-bold cascade-setup-title-color">Name</span>
        <EditableTable
          rowKey={record => record.key}
          columns={columns || []}
          size="small"
          showHeader={false}
          dataSource={nameList || []}
          tableLayout="fixed"
          className="himalayas-spice-card-table"
        />
      </Col>
    </Row>
  }
}

export default NameTable;