import React, { PureComponent } from "react";
import { Input } from 'antd';
import { DIELECTRIC, FILL_MATERIAL, getDefaultMaterialName, stackupItemErrorCheck } from '../../services/Stackup';
import { COPPER, SOLDER } from "../../services/Stackup/Material";

class MaterialName extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: null
    }
  }

  getMaterialName = () => {
    return this.state.name;
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.setDefaultName();
  }

  setDefaultName = () => {
    // Get name
    const { materialName, materialList, materialType, dataIndex } = this.props;
    let _name = materialName;
    if (!materialName) {
      _name = getDefaultMaterialName({ materialType: dataIndex === FILL_MATERIAL ? DIELECTRIC : materialType, materialList });
    }
    this.originName = _name;
    this.setState({
      name: _name
    })
  }

  componentDidUpdate = (prevProps) => {
    const { materialType, selectMaterialName, materialList } = this.props;
    if (materialType !== prevProps.materialType || selectMaterialName !== prevProps.selectMaterialName || materialList.length !== prevProps.materialList.length) {
      this.setDefaultName();
    }
  }

  changeName = (e) => {
    const value = e.target.value;
    const { errorMsg } = this.props;
    this.setState({
      name: value
    });
    const _errorMsg = errorMsg && errorMsg.type === "name" ? null : errorMsg;
    this.props.updateErrorMsg(_errorMsg);
  }

  saveName = (e) => {
    const value = e.target.value;
    const { materialList = [], selectMaterialName } = this.props;
    const _materialList = materialList.filter(item => item.name !== selectMaterialName);
    // Error check
    const error = stackupItemErrorCheck("name", value, { nameList: _materialList });
    if (error) {
      e.target.focus && e.target.focus();
      this.props.updateErrorMsg({ type: "name", error: error.error });
      return error;
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render() {
    const { name } = this.state;
    const { className } = this.props;
    return <div className={`material-definition-item ${className}`}>
      {/* name */}
      <label className='material-edit-value-label'>Name</label>
      <Input
        className='aurora-input material-input'
        placeholder='Name'
        value={name}
        onChange={(e) => this.changeName(e)}
        onBlur={(e) => this.saveName(e)}
        onKeyDown={(e) => this.keyDown(e)}
        disabled={[SOLDER, COPPER].includes(this.originName)}
      />
    </div>
  }
}

export default MaterialName;