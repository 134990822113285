import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Button, Select, Spin } from 'antd';

const Option = Select.Option;

class pcbReplacePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectPcb: ''
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleHeight);
    this.handleHeight();
    const { pcbReplaceInfo } = this.props;
    if (pcbReplaceInfo && pcbReplaceInfo.selectPcb) {
      this.setState({
        selectPcb: pcbReplaceInfo.selectPcb
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleHeight);
  }

  componentDidUpdate(prevProps) {
    const { selectPcb } = this.state
    const { pcbReplaceInfo } = this.props;
    if (prevProps.pcbReplaceInfo && prevProps.pcbReplaceInfo.selectPcb !== selectPcb) {
      this.setState({
        selectPcb: pcbReplaceInfo.selectPcb
      })
    }
  }

  handleHeight = () => {
    const { height = 800 } = this.dialogRoot.getBoundingClientRect();
    this.setState({
      panelMaxHeight: height - 200
    });
  }

  closeModal = () => {
    this.props.updatePcbReplaceInfo({ loading: null, msgInfo: { netsComponents: [], msg: null }, panelVisible: false, selectPcb: '' })
    this.props.getPcbReplace(null)
  }

  changeReplacedPcb = (value) => {
    this.setState({
      selectPcb: value
    })
    const { pcbReplaceInfo } = this.props
    this.props.updatePcbReplaceInfo({ selectPcb: value })
    this.props.uploadReplaceInterfacePcb({ newPcbId: value, oldPcbName: pcbReplaceInfo.pcbReplacedName, isReplace: false, selectPcb: value })
  }

  replaceClick = () => {
    const { selectPcb } = this.state;
    const { pcbReplaceInfo } = this.props
    this.props.uploadReplaceInterfacePcb({ newPcbId: selectPcb, oldPcbName: pcbReplaceInfo.pcbReplacedName, isReplace: true })
  }

  msgContent = (msgInfo) => {
    if (!msgInfo || !msgInfo.netsComponents || !msgInfo.netsComponents.length) { return null }
    const { netsComponents } = msgInfo;
    if ((!netsComponents[0].deleteNets || !netsComponents[0].deleteNets.length)
      && (!netsComponents[0].deleteComponents || !netsComponents[0].deleteComponents.length)
      && (!netsComponents[0].deleteParts || !netsComponents[0].deleteParts.length)) { return null }
    return <div className="sierra-pcb-replace-msg-box">
      {netsComponents.length && netsComponents.map((item, index) => {
        return <Fragment key={index}>
          {item.deleteNets && item.deleteNets.length > 0 && <Fragment>
            <div className="pcb-replace-msg">{'[Warning] The replacing PCB does not have the following nets: '}</div>
            <div className="pcb-replace-previewInfo">{item.deleteNets.join(',\n')}</div></Fragment>}
          {item.deleteComponents && item.deleteComponents.length > 0 && <Fragment>
            <div className="pcb-replace-msg">{'[Warning] The replacing PCB does not have the following components: '}</div>
            <div className="pcb-replace-previewInfo">{item.deleteComponents.join(',\n')}</div></Fragment>}
          {item.deleteParts && item.deleteParts.length > 0 && <Fragment>
            <div className="pcb-replace-msg">{'[Warning] The replacing PCB does not have the following parts: '}</div>
            <div className="pcb-replace-previewInfo">{item.deleteParts.join(',\n')}</div></Fragment>}
        </Fragment>
      }
      )}
    </div>
  }

  render() {
    const { panelMaxHeight = 800, selectPcb } = this.state;
    const { pcbReplaceInfo } = this.props;
    const { loading, msgInfo = {}, pcbReplacedName, replacePcbList } = pcbReplaceInfo;
    const content = (
      <Fragment>
        <Panel
          className='sierra-pcb-replace-panel sierra-panel'
          title={`PCB Replace ${pcbReplacedName}`}
          onCancel={this.closeModal}
          zIndex={2000}
          width={450}
          position='panel-center-left'
          draggable
          minHeight={220}
          minWidth={400}
          maxHeight={panelMaxHeight}
        >
          <Spin spinning={loading ? true : false} tip={loading === 'loading' ? 'Loading PCB...' : loading === 'replace' ? 'Replace PCB...' : ''}>
            <div className="sierra-pcb-replace-content">
              <div className="pcb-replace-input-box">
                <label className="pcb-replace-box-title">Replace with</label>
                <Select value={selectPcb} onChange={(value) => this.changeReplacedPcb(value)} popupClassName='aurora-select-dropdown' className='pcb-replace-select'>
                  {replacePcbList.map(item => <Option key={item.id}>{item.name}</Option>)}
                </Select>
              </div>
              {this.msgContent(msgInfo)}
              <div className="pcb-replace-button-box">
                <Button
                  className=''
                  onClick={this.replaceClick}
                  type="primary"
                >Replace</Button>
                <Button
                  className=''
                  onClick={this.closeModal}
                >Cancel</Button>
              </div>
            </div>
          </Spin>
        </Panel>
        <div id="components-setting-dialog"></div>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default pcbReplacePanel;