import { FASTPI, ROCKY, ANDES_V2, CASCADE, SIERRA } from "../../constants/pageType";
import { getTextWidth } from "./getTextWidth";

function getTopMenuWidth(menu) {
  let width = 0;
  for (let item of menu) {
    const name = item.title ? item.title : item.name;
    width += (getTextWidth(name, 14) + 50);
  }

  return width || 73.8;
}

function getTopMenu(menu, cssId, product, { isSweep, measurementWidth, backSetup }) {
  let width = 0;
  const ele = document.getElementById(cssId);
  const reducedWidth = getWidthByProduct(product, { isSweep, measurementWidth, backSetup });
  const menuWidth = ele ? ele.offsetWidth - reducedWidth : 0;
  let menuList = [], subMenuList = [];
  for (let item of menu) {
    const name = item.title ? item.title : item.name;
    width += (getTextWidth(name, 14) + 50);
    //add ...
    if (width > menuWidth) {
      const subTitleWidth = getTextWidth("...", 14) + 50;
      if (menuWidth - width > subTitleWidth) {
        menuList.push({ key: "subMenu", name: "..." });
      } else {
        const index = menuList.length > 0 ? menuList.length - 1 : 0;
        menuList[index] = { key: "subMenu", name: "..." };
      }
      break;
    } else {
      menuList.push({ ...item });
    }
  }
  subMenuList = menu.filter(item => !menuList.find(it => it.key === item.key));
  const subIndex = menuList.findIndex(item => item.key === "subMenu");

  if (subIndex > -1) {
    menuList[subIndex].children = subMenuList;
  }
  return menuList;
}

function getWidthByProduct(product, { isSweep, measurementWidth = 0, backSetup }) {
  switch (product) {
    case FASTPI:
      return 10;
    case ROCKY:
    case CASCADE:
      return 230;
    case ANDES_V2:
    case SIERRA:
      const setupWidth = backSetup ? 140 : 0;
      return isSweep ? 580 + setupWidth : measurementWidth ? measurementWidth + setupWidth : 30 + setupWidth;
    default: return 230;
  }
}

export {
  getTopMenuWidth,
  getTopMenu
}