import Polygon from '../cutDesign/geometry/Polygon';
import CeLine from '../../geometry/CeLine';
import CePolygonWithHole from '../../geometry/CePolygonWithHole';
import CePolygon from '../../geometry/CePolygon';
import { getAngle, getRightAngleSide } from '../cutDesign/math'
const VERTICAL = 'vertical', HORIZONTAL = 'horizontal', SLASH = 'slash';

function calculateIsOverlap(maxXList, minXList, maxYList, minYList) {
  // Determine whether the name of a component overlaps with the names of other components
  // max=[A.start,B.start]; min=[A.end,B.end];
  if (Math.max.apply(null, maxXList) <= Math.min.apply(null, minXList) && Math.max.apply(null, maxYList) <= Math.min.apply(null, minYList)) {
    return true
  }
  return false
}

function calculateMinMaxData(_x, _y, xTrans, yTrans, _locationList, i, direction) {
  let xMin = _x, yMin = _y;
  let xMax = _x + 2 * xTrans;
  let yMax = _y + 2 * yTrans;
  if (direction === VERTICAL) {
    xMax = _x;
    yMin = _y;
    xMin = _x - 2 * yTrans;
    yMax = _y + 2 * xTrans;
  }

  for (let locationData of _locationList) {
    const { xMax: _xMax, xMin: _xMin, yMin: _yMin, yMax: _yMax } = locationData;
    const isOverlap = calculateIsOverlap([xMin, _xMin], [xMax, _xMax], [yMin, _yMin], [yMax, _yMax])
    if (isOverlap && i < 3) {
      let _data = xTrans;
      if ((direction === VERTICAL && _yMin < yMin && yMin < _yMax) || (direction === HORIZONTAL && _xMin < xMin && xMin < _xMax)) {
        _data = -xTrans
      }
      const x = direction === VERTICAL ? _x : _x - _data;
      const y = direction === VERTICAL ? _y - _data : _y;
      let _i = i + 1;
      return calculateMinMaxData(x, y, xTrans, yTrans, _locationList, _i, direction)
    }
  }
  return { _x, _y, xMax, yMin, xMin, yMax }
}

function calculateCompNameTransform(d, locationList) {
  let _locationList = [...locationList]
  const { x, y, direction, xTrans, yTrans, distanceMove } = d;
  let rotate = '-180', _x, _y, locData;
  if (direction === HORIZONTAL) {
    _x = x - xTrans
    _y = y - (-distanceMove)
    locData = calculateMinMaxData(_x, _y, xTrans, yTrans, _locationList, 1, direction)

  } else if (direction === VERTICAL) {
    _x = x - distanceMove;
    _y = y - xTrans;
    locData = calculateMinMaxData(_x, _y, xTrans, yTrans, _locationList, 1, direction)
    rotate = '-90'
  }

  if (locData) {
    _x = locData._x;
    _y = locData._y;
    _locationList.push({ ...locData })
  }
  return { rotate, _locationList, _x, _y }
}

function calculatePowerNetNameTransform(d, xTrans, yTrans, locationList, x, y, i = 0) {
  let { direction, rotate } = d[0];
  let _x = x, _y = y, _locationList = [...locationList];
  let locData = calculateNetMinMaxData(x, y, xTrans, yTrans, locationList, direction, rotate)
  if (!locData && i < 2) {
    _y = y + yTrans / 3
    return calculatePowerNetNameTransform(d, xTrans, yTrans, locationList, _x, _y, i++)
  }

  if (locData) {
    _x = locData._x;
    _y = locData._y;
    _locationList.push({ ...locData })
  }
  return { translateX: _x, translateY: _y, _locationList, rotate, direction, xTrans, yTrans }
}

function calculateNetNameTransform(d, locationList, i = 0, proportion = 1) {
  let _locationList = [...locationList];
  let { x, y, direction, xTrans, yTrans, rotate, width } = d[i];

  if (!width) { width = yTrans / 2 }

  let _x = x, _y = y, locData;

  if (direction === VERTICAL) {
    _x = x - 2.5 * width * proportion;
    _y = y - xTrans;
  } else if (direction === SLASH) {
    const hypotenuse = Math.hypot(xTrans, (2.5 * width))
    const angle = getAngle(xTrans, (2.5 * width))

    if (rotate < -180) {
      let _rotate = -(rotate + 180)
      const { a, b } = getRightAngleSide(hypotenuse, _rotate + angle)
      _x = x - b;
      _y = y + a;
    } else {
      let _rotate = -(rotate + 90);
      const { a, b } = getRightAngleSide(hypotenuse, _rotate + angle)
      _x = x - a;
      _y = y - b;
    }
  } else if (direction === HORIZONTAL) {
    _x = x - xTrans;
    _y = y + 2.5 * width * proportion;
  }
  locData = calculateNetMinMaxData(_x, _y, xTrans, yTrans, _locationList, direction, rotate)
  if (!locData && i < 3 && d.length > i + 1) {
    const _i = i + 1;
    return calculateNetNameTransform(d, locationList, _i)
  }

  if (locData) {
    _x = locData._x;
    _y = locData._y;
    _locationList.push({ ...locData })
  }

  return { translateX: _x, translateY: _y, _locationList, rotate, direction, xTrans, yTrans, x, y, width }
}

function calculateNetMinMaxData(_x, _y, xTrans, yTrans, _locationList, direction, rotate) {
  let xMin = _x, yMin = _y;
  let xMax = _x + 2 * xTrans;
  let yMax = _y + 2 * yTrans;

  if (direction === VERTICAL) {
    xMax = _x;
    yMin = _y;
    xMin = _x - 2 * yTrans;
    yMax = _y + 2 * xTrans;
  } else if (direction === SLASH && rotate === '-135') {
    xMax = _x + 1.4 * xTrans;
    yMin = _y;
    xMin = _x - 1.4 * yTrans;
    yMax = _y + 1.4 * (xTrans + yTrans);
  } else if (direction === SLASH && rotate === '-225') {
    xMin = _x;
    xMax = _x + 1.4 * (xTrans + yTrans);
    yMin = _y - 1.4 * yTrans;
    yMax = _y + 1.4 * xTrans;
  }

  for (let locationData of _locationList) {
    const { xMax: _xMax, xMin: _xMin, yMin: _yMin, yMax: _yMax } = locationData;
    const isOverlap = calculateIsOverlap([xMin, _xMin], [xMax, _xMax], [yMin, _yMin], [yMax, _yMax])
    if (isOverlap) {
      return false
    }
  }
  return { _x, _y, xMax, yMin, xMin, yMax }
}

function getTransformData(x, y, _x, _y) {
  let direction = '', rotate = '-180';
  if (x === _x && y !== _y) {
    direction = VERTICAL
    rotate = '-90';
  } else if (x !== _x && y === _y) {
    direction = HORIZONTAL
  } else if (x !== _x && y !== _y) {
    rotate = '-225'
    if ((x > _x && y > _y) || (x < _x && y < _y)) {
      rotate = '-135'
    }
    direction = SLASH
  }
  return { direction, rotate };
}

function calculateNetNameLocation(data, type) {
  const { points, width, polyData, shape } = data;
  let sumX = 0, sumY = 0, x, y, r, direction, rotate;
  if (type === 'signalNet' && points) {
    const pointList = points.split(' ');
    const pointListLength = pointList.length;
    if (pointListLength > 0) {
      let _x = 0, _y = 0
      for (let data of pointList) {
        const _location = data.split(',');
        if (_location && _location.length > 1) {
          _x = Math.floor(_location[0] * 10) / 10
          _y = Math.floor(_location[1] * 10) / 10
          sumX = sumX + _x;
          sumY = sumY + _y;
        }
      }
      x = Math.floor((sumX / pointListLength) * 10) / 10
      y = Math.floor((sumY / pointListLength) * 10) / 10
      // r = width * 10;
      r = Math.abs((x - _x) * 2) / 3
      if (r > width * 6) {
        r = width * 6;
      } else if (r < width * 3) {
        r = width * 3;
      }
      const data = getTransformData(x, y, _x, _y)
      direction = data.direction;
      rotate = data.rotate;
    }
  } else if (type === 'powerNet' && polyData) {
    const { xLength, yLength } = data;
    r = Math.abs(xLength);
    x = data.x;
    y = data.y;
    rotate = '-180';
    direction = HORIZONTAL;
  }
  return { x, y, r, direction, width, rotate }
}

function getNetsShapeLocation({ netInfo, nets, netName, type, netObjLists }) {
  const SIGNALNET = 'signalNet'
  let geomType = []
  if (type === SIGNALNET) {
    geomType = [CeLine];
  } else {
    geomType = [CePolygonWithHole, CePolygon];
  }
  const allGeomListData = [];

  for (let { mRefGeom: geom } of netInfo.mGeomList) {
    if (geomType.find(t => geom.mGeometry instanceof t)) {
      if (type === SIGNALNET) {
        const { mStart, mEnd } = geom.mGeometry;
        // const mEnd = { mGeomType: "Pnt", mX: 2411.07, mY: 729.57 }
        // const mStart = { mGeomType: "Pnt", mX: 2415, mY: 733.5 }
        const mStartX = mStart.mX, mStartY = mStart.mY, mEndX = mEnd.mX, mEndY = mEnd.mY
        const dx = mEndX - mStartX;
        const dy = mEndY - mStartY;
        const shapeSize = Math.sqrt(dx * dx + dy * dy);//line Length
        //Center point coordinates
        const x = (mEndX + mStartX) / 2;
        const y = (mEndY + mStartY) / 2;

        function calcArc(start, end) {
          return Math.atan(tanA(start, end));
        }

        function tanA(start, end) {
          return (end.mY - start.mY) / (end.mX - start.mX);
        }

        let angle = ((calcArc(mStart, mEnd) * 180 / Math.PI) - 180);
        angle = Math.round((angle * 100) / 100);

        const { direction, rotate } = getTransformData(mStartX, mStartY, mEndX, mEndY);
        const filterNetObject = netObjLists.filter(it => it.canvasLineList.length && it.canvasLineList[0].net === netName)
        let width = 0, widthList = [];
        for (let netInfo of filterNetObject) {
          const { canvasLineList } = netInfo;
          const _widthList = canvasLineList.map(item => { return item.width });
          widthList = [...widthList, ..._widthList]
        }
        widthList = widthList.sort(
          function (a, b) {
            return a - b
          });

        var length = widthList.length;
        if (length % 2 === 1) {
          width = widthList[(length / 2) - 0.5]
        } else {
          width = widthList[length / 2]
        }

        let r = Math.abs(dx * 2) / 3
        if (r > width * 6) {
          r = width * 6;
        } else if (r < width * 3) {
          r = width * 3;
        }
        allGeomListData.push({ shapeSize, x, y, direction, rotate: direction === SLASH ? angle : rotate, width, r })
      } else {
        // Polygon with hole or polygon without hole
        const { mVertices } = geom.mGeometry;
        if (!mVertices.length) { continue }
        const polygon = new Polygon({ mVertices });
        const sizeArray = polygon.getSizeArray(); //sizeArray - [minX, maxX, minY, maxY]
        const dx = sizeArray[1] - sizeArray[0];
        const dy = sizeArray[3] - sizeArray[2];
        const shapeSize = dx * dy;//area
        //Center point coordinates
        const { x, y } = polygon.getCenterGravity();

        const rotate = '-180', direction = HORIZONTAL;
        let r = dx < dy ? Math.abs((dx) * 2) / 3 : Math.abs((dy) * 2) / 3
        allGeomListData.push({ shapeSize, x, y, rotate, direction, r })
      }
    }
  }
  return allGeomListData
}

/**
   * @description Ray method to determine whether a point is inside a polygon
   * @param {Object} p { x, y }
   * @param {Array} poly [{ x, y },{ x, y },{ x, y }]
   * @return {String} is p inside the geometry
   */
function isPointInPolygon(p, poly) {
  let px = p.x,
    py = p.y,
    flag = false,
    passX = [];
  for (let i = 0, l = poly.length, j = l - 1; i < l; j = i, i++) {
    let { x: sx, y: sy } = getPointCoordinate(poly[i]);
    let { x: tx, y: ty } = getPointCoordinate(poly[j]);

    if ((sx === px && sy === py) || (tx === px && ty === py)) {
      return true;
    }
    if ((sy < py && ty >= py) || (sy >= py && ty < py)) {
      let x = sx + (py - sy) * (tx - sx) / (ty - sy)
      if (x === px) {
        return true;
      }
      if (x > px) {
        passX.push(x)
        flag = !flag
      }
    }
  }
  return flag;
}

function getPointCoordinate(point) {
  if (Array.isArray(point)) {
    return { x: point[0], y: point[1] }
  }
  return { x: point.x, y: point.y }
}

export {
  calculateCompNameTransform,
  calculateNetNameLocation,
  calculateNetNameTransform,
  calculateNetMinMaxData,
  getNetsShapeLocation,
  isPointInPolygon,
  calculatePowerNetNameTransform
}