import React from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { getIRValue, updateIRSelectedKey, updateIRResultTableLoading } from '../.././store/result/IR/action.js';
import './index.css';
class IRMenu extends React.Component {
  changeSelectedKey = (e, resultId) => {
    const { key } = e
    this.props.updateIRSelectedKey(key)
  }

  componentDidMount() {
    const { verificationId } = this.props;
    this.props.getIRValue(verificationId);
  }

  render() {
    const { IRMenuList, IRSelectedKey } = this.props;
    return (
      <div className="IR-Result-Menu">
        <Menu mode="horizontal" selectedKeys={[IRSelectedKey]} items={IRMenuList ? IRMenuList.map(item => ({ key: item.id, label: item.key, onClick: this.changeSelectedKey })) : []} />
      </div>
    );
  }
}

const mapState = (state) => {
  const { IR: { verificationId }, IRResult: { IRMenuList, IRSelectedKey } } = state.CascadeReducer
  return {
    verificationId,
    IRMenuList,
    IRSelectedKey
  }
}

const mapDispatch = (dispatch) => ({
  getIRValue(verificationId) {
    dispatch(getIRValue(verificationId))
  },
  updateIRSelectedKey(IRSelectedKey) {
    dispatch(updateIRSelectedKey(IRSelectedKey))
  },
  updateIRResultTableLoading(loading) {
    dispatch(updateIRResultTableLoading(loading))
  }
})

export default connect(mapState, mapDispatch)(IRMenu);