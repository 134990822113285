import http from '../http';

export default function getCustomizedSettings() {
  return http.get(`/settings`);
}

export function getInstallerShow() {
  return http.get(`/settings/installer`);
}

export function getPageType() {
  return http.get(`/settings/home-page`)
}