import NP from 'number-precision';
import { SIERRA, ANDES_V2, ANDES, ROCKY } from '../../constants/pageType';
import { numberCheck } from "./dataProcess";
import { scaleConversion } from './numberHelper';

const ErrorMsg = [
  'Please set the value.',//0
  'Please enter a number.',//1
  'Please set the log sweep per decade from 1 to 100.',//2
  'Please set the min log sweep from 1Hz to 1KHz.',//3
  'Please set the max log sweep(min linear sweep) from 10MHz to 100MHz.',//4
  'Please set the max linear sweep from 100MHz to 10GHz.',//5
  'Please set the linear sweep frequency step from 1MHz to 10MHz.',//6
  'Please set the port impedance from 0.01 to 1000.',//7
  'Please set the maximum frequency from 5GHz to 100GHz.',//8
  'Please set the maximum frequency step greater than 0.',//9
  'Please set the value greater than 0.1.',//10
  'Please set the value greater than 1.',//11
  'Please enter an integer.',//12
  'Please set the value greater than 2.',//13
  'Please set the value from 0.001 to 1.',//14
  'Please set the max linear sweep 2 from 1GHz to 200GHz.',//15
  'Port limit must be an integer.',//16
  'Please set the port limit from 30 to 300.',//17
  'Please set the linear sweep frequency step from 0.001Hz to 1GHz.',//18
  'Please set the max linear sweep(min linear sweep 2) from 0Hz to 200GHz.',//19
  'Please set the log sweep per decade from 1 to 10000.',//20
  'Please set the min log sweep from 0.00001Hz to 10GHz.',//21
  'Please set the max log sweep(min linear sweep) from 0.00001Hz to 200GHz.',//22
  'Please set the port impedance from 0.001 to 1000000.',//23
  'Please set the max linear sweep 2 from 1GHz to 10GHz.',//24
  'Please set the back drill stub size from 1mil to 50mil.',//25
  'License wait time should be a integer.',//26
  'Please set the license wait time greater than 0.',//27
  'Number of CPU should be a integer.',//28
  'Please set the number of CPU from 1 to 256.',//29
  'Parallel Freq. solve should be a integer.',//30
  'Please set the parallel Freq. solve from 1 to 64.',//31
  'Number of GPU should be a integer.',//32
  'Please set the number of GPU from 0 to 64.',//33
  'Please set the max RAM from 1% to 100%.',//34
  'Max RAM should be a integer.',//35
  'Please set the result interpolation error tolerance from 0.1% to 30%.',//36
  'Please set the maximum number of solutions from 50 to 1000.',//37
  'Please set the value from 1 to 100.',//38
  'Please set the Max Delta from 0.001 to 0.2.',//39
  'Please set the value greater than 3.',//40
  'Please set the log sweep per decade from 1 to 200.',//41
  'Please set the result interpolation error tolerance from 0.05% to 5%.',//42
]

function getErrorMsg(index, type, totalThickness) {
  if (index > -1) {
    if (type === "backdrillStubSize") {
      const maxStubSize = totalThickness > 1 ? totalThickness : 50;
      return `Please set the back drill stub size from 1mil to ${maxStubSize}mil.`;
    }
    return ErrorMsg[index];
  } else {
    return null;
  }
}

const units = [
  'logSweepMin',
  'logSweepMax',
  'linearSweepMax',
  'linearSweepFreqStep',
  'maxFreq',
  'maxFreqStep',
  'caLogSweepMin',
  'caLogSweepMax',
  'caLinearSweepMax',
  'caLinearSweepFreqStep',
  'caMaxFreq',
  "log_start",
  "log_end",
  "linear_start",
  "linear_end",
  "linear_start_2",
  "linear_end_2",
  "linear_step"
];

function valueCheckIndex({ value, inputType, unit, version, totalThickness }) {

  if (!value) {
    return 0;
  }

  let notNum = numberCheck(value);
  if (notNum) {
    return 1;
  }

  let errorIndex = -1;
  let _value = value;
  if (units.includes(inputType) && unit) {
    const scale = scaleConversion("Hz", unit);
    _value = NP.times(parseFloat(_value), scale);
  }

  if (version === ANDES || version === ANDES_V2) {
    switch (inputType) {
      case 'logSweepSAPD':
        if (parseInt(_value) > 200 || parseInt(_value) < 1) {
          errorIndex = 41;
        };
        return errorIndex;
      case "log_dec":
        if (parseInt(_value) > 10000 || parseInt(_value) < 1) {
          errorIndex = 20;
        };
        return errorIndex;
      case "logSweepMin":
      case "log_start":
        if (_value > 10e9 || _value < 1e-5) {
          errorIndex = 21;
        }
        return errorIndex;
      case "logSweepMax":
      case "log_end":
      case "linear_start":
        if (_value > 200e9 || _value < 1e-5) {
          errorIndex = 22;
        }
        return errorIndex;
      case 'linearSweepMax':
      case "linear_end":
      case "linear_start_2":
        if (_value > 200e9 || _value < 0) {
          errorIndex = 19;
        }
        return errorIndex;
      case 'linearSweepFreqStep':
      case "linear_step":
        if (_value > 1e9 || _value < 1e-3) {
          errorIndex = 18;
        }
        return errorIndex;
      case 'portImpedance':
        if (parseFloat(value) > 1e6 || parseFloat(value) < 1e-3) {
          errorIndex = 23;
        }
        return errorIndex;
      case 'maxFreq':
      case "linear_end_2":
        if (_value > 200e9 || _value < 1e9) {
          errorIndex = 15;
        }
        return errorIndex;
      case "backdrillStubSize":
        const maxStubSize = totalThickness > 1 ? totalThickness : 50;
        if (_value > maxStubSize || _value < 1) {
          errorIndex = 25;
        }
        return errorIndex;
      case "lic_wait":
        if (!Number.isInteger(Number(_value))) {
          errorIndex = 26;
        }
        if (parseFloat(_value) < 1) {
          errorIndex = 27;
        }
        return errorIndex;

      case "num_cores":
        if (!Number.isInteger(Number(_value))) {
          errorIndex = 28;
        }
        if (parseFloat(_value) < 1 || parseFloat(_value) > 256) {
          errorIndex = 29;
        }
        return errorIndex;
      case "num_tasks":
        if (!Number.isInteger(Number(_value))) {
          errorIndex = 30;
        }
        if (parseFloat(_value) < 1 || parseFloat(_value) > 64) {
          errorIndex = 31;
        }
        return errorIndex;
      case "num_gpus":
        if (!Number.isInteger(Number(_value))) {
          errorIndex = 32;
        }
        if (parseFloat(_value) < 0 || parseFloat(_value) > 64) {
          errorIndex = 33;
        }
        return errorIndex;
      case "ram_percent":
        if (!Number.isInteger(Number(_value))) {
          errorIndex = 35;
        }
        if (parseFloat(_value) < 1 || parseFloat(_value) > 100) {
          errorIndex = 34;
        }
        return errorIndex;
      case "errorTolerance":
        if (parseFloat(_value) < 0.01 || parseFloat(_value) > 30) {
          errorIndex = 36;
        }
        return errorIndex;
      case "maxSolution":
        if (parseFloat(_value) < 50 || parseFloat(_value) > 1000) {
          errorIndex = 37;
        }
        return errorIndex;
      case 'maxPasses':
        if (parseFloat(_value) < 2 || parseFloat(_value) > 100) {
          errorIndex = 38;
        }
        return errorIndex;
      case 'maxDelta':
        if (parseFloat(value) > 0.2 || parseFloat(value) < 0.001) {
          errorIndex = 39;
        }
        return errorIndex;
      case 'viaNumSides':
        if (parseFloat(value) < 3) {
          errorIndex = 40;
        }
        return errorIndex;
      default: break;
    }
  }

  switch (inputType) {
    case 'logSweepSAPD':
    case 'caLogSweepSAPD':
      if (parseInt(_value) > 100 || parseInt(_value) < 1) {
        errorIndex = 2;
      };
      break;
    case 'logSweepMin':
    case 'caLogSweepMin':
      if (_value > 1000 || _value < 1) {
        errorIndex = 3;
      }
      break;
    case 'logSweepMax':
    case 'caLogSweepMax':
      if (_value > 1e8 || _value < 1e7) {
        errorIndex = 4;
      }
      break;
    case 'linearSweepMax':
    case 'caLinearSweepMax':
      if (_value > 1e10 || _value < 1e8) {
        errorIndex = 5;
      }
      break;
    case 'linearSweepFreqStep':
    case 'caLinearSweepFreqStep':
      if (_value > 1e7 || _value < 1e6) {
        errorIndex = 6;
      }
      break;
    case 'portImpedance':
      if (parseFloat(value) > 1000 || parseFloat(value) < 0.01) {
        errorIndex = 7;
      }
      break;
    case 'maxFreq':
    case 'caMaxFreq':
      const min = version === SIERRA ? 1e9 : 5e9;
      if (_value > 1e11 || _value < min) {
        errorIndex = version === SIERRA ? 24 : 8;
      }
      break;
    case 'maxFreqStep':
      if (_value <= 0) {
        errorIndex = 9;
      }
      break;
    case 'clipSize':
      if (parseFloat(value) < 0.1) {
        errorIndex = 10;
      };
      break;
    case 'meshFrequency':
      if (parseFloat(value) <= 1) {
        errorIndex = 11;
      };
      break;
    case 'maxPasses':
      let integerReg = /^[\d]+$/;
      if (!integerReg.test(value)) {
        errorIndex = 12;
        break;
      };
      if (parseInt(value) <= 2) {
        errorIndex = 13;
      };
      break;
    case 'maxDelta':
      if (parseFloat(value) > 1 || parseFloat(value) < 0.001) {
        errorIndex = 14;
      }
      break;
    case 'portLimit':
      const valueStr = value.toString();
      if (!/^\d+$/.test(valueStr)) {
        errorIndex = 16;
      }

      if (parseFloat(value) > 300 || parseFloat(value) < 30) {
        errorIndex = 17;
      }
      break;
    case "num_cores":
      if (!Number.isInteger(Number(_value))) {
        errorIndex = 28;
      }
      if (parseFloat(_value) < 1 || parseFloat(_value) > 256) {
        errorIndex = 29;
      }
      return errorIndex;
    case "num_tasks":
      if (!Number.isInteger(Number(_value))) {
        errorIndex = 30;
      }
      if (parseFloat(_value) < 1 || parseFloat(_value) > 64) {
        errorIndex = 31;
      }
      return errorIndex;
    case "num_gpus":
      if (!Number.isInteger(Number(_value))) {
        errorIndex = 32;
      }
      if (parseFloat(_value) < 0 || parseFloat(_value) > 64) {
        errorIndex = 33;
      }
      return errorIndex;
    case "ram_percent":
      if (!Number.isInteger(Number(_value))) {
        errorIndex = 35;
      }
      if (parseFloat(_value) < 1 || parseFloat(_value) > 100) {
        errorIndex = 34;
      }
      return errorIndex;
    case "errorTolerance":
      if (parseFloat(_value) < 0.05 || parseFloat(_value) > 5) {
        errorIndex = 42;
      }
      return errorIndex;
    default: errorIndex = -1; break;
  };

  return errorIndex;
}

function valueCheck({ value, inputType, unit, version, totalThickness }) {
  const index = valueCheckIndex({ value, inputType, unit, version, totalThickness });
  return getErrorMsg(index, inputType, totalThickness);
}

const rangeMsg = [
  `"Max log sweep(min linear sweep)" should be greater than "min log sweep".`,
  `"Max linear sweep" should be greater than "min linear sweep(max log sweep)".`,
  `"Max linear sweep 2" should be greater than "min linear sweep 2(max linear sweep)".`,
  `"Maximum Frequency" should be greater than "max linear sweep".`
]
function checkSweepValue({ logSweepMin, logSweepMax, linearSweepMax, maxFreq, version }) {
  if (parseFloat(logSweepMin) > parseFloat(logSweepMax)) {
    return rangeMsg[0];
  }
  if (parseFloat(logSweepMax) > parseFloat(linearSweepMax)) {
    return rangeMsg[1];
  }

  if (maxFreq && parseFloat(linearSweepMax) > parseFloat(maxFreq)) {
    if ([ANDES_V2, ROCKY].includes(version)) {
      return rangeMsg[2];
    } else {
      return rangeMsg[3];
    }
  }
  return null;
}

export { valueCheck, valueCheckIndex, checkSweepValue, rangeMsg };
