import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { select, event } from 'd3-selection';

function drawCrosstalk({ svgElement, skewData, report = false, key = "Xtalk", isChannel }) {

  const svgWidth = svgElement.parentElement.clientWidth;
  const svgHeight = svgElement.parentElement.clientHeight;

  let margin = {
    top: 40,
    left: 75,
    right: 20,
    bottom: 65
  };
  if (report) {
    margin = {
      top: 50,
      left: 100,
      right: 30,
      bottom: 80
    };
  }

  const plotWidth = svgWidth - margin.left - margin.right;
  const plotHeight = svgHeight - margin.top - margin.bottom;

  // const XDomain = Signal
  const xDomain = skewData.map(function (d) { return d.Signal; });
  const xScale = scaleBand()
    .domain(xDomain)
    .range([0, plotWidth])
    .paddingInner(0.4)
    .paddingOuter(0.2)

  let _xDomain = [];
  if (isChannel) {
    const domainLength = xDomain.length;
    if (domainLength) {
      const value = Math.ceil(domainLength / 4);
      for (let index = 0; index <= xDomain.length - 1; index++) {
        if (index === 0 || index === xDomain.length - 1) {
          _xDomain.push(xDomain[index])
        } else if ((index % value) === 0 && index < (xDomain.length - Math.ceil(value / 2))) {
          _xDomain.push(xDomain[index])
        }
      }
    }
  } else {
    _xDomain = [...xDomain]
  }

  const yScale = scaleLinear().range([plotHeight, 0]);

  const xAxis = axisBottom(xScale)
    .tickValues([..._xDomain])

  const yAxis = axisLeft(yScale)
    .ticks(isChannel ? 5 : 10);

  const svg = select(svgElement)
    .attr('class', 'plot full-fill')
  svg.selectAll('g').remove();

  // The bar chart
  const gRoot = svg.append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  xScale.domain();

  let maxData = skewData && skewData[0] ? skewData[0][key] : 0;
  let minData = skewData && skewData[0] ? skewData[0][key] : 0;

  for (const data of skewData) {
    if (parseFloat(data[key]) > parseFloat(maxData)) {
      maxData = data[key];
    }
    if (parseFloat(data[key]) < parseFloat(minData)) {
      minData = data[key];
    }
    data.color = key === "VMax" ? "#2aaf94" : '#3489ad';
  }
  let maxY = Math.ceil(maxData * 100) / 100;
  let minY = Math.floor(minData * 100) / 100;
  if (minY < 0) {
    minY = 0
  }

  yScale.domain([0, maxY]);

  // axes
  gRoot.append("g")
    .attr("class", "axis report-x-axis")
    .attr("transform", "translate(0," + plotHeight + ")")
    .call(xAxis)
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "1em")
    .attr('dy', "1.3em")
    .attr('font-size', isChannel ? 10 : 13)
    .attr("transform", "rotate(0)");

  gRoot.append("g")
    .attr("class", "axis report-y-axis")
    .call(yAxis)
    .attr('font-size', isChannel ? 10 : 13);

  if (isChannel) {
    gRoot.selectAll("rect")
      .data(skewData)
      .enter().append("rect")
      .style("fill", function (d) { return d.color; })
      .attr("x", function (d, i) {
        return xScale(d.Signal);
      })
      .attr("width", xScale.bandwidth())
      .attr("y", function (d) { return yScale(d[key]); })
      .attr("height", function (d) {
        return plotHeight - yScale(d[key]);
      })
      .on("mouseover", function (d) {
        select(this)
          .transition()
          .duration(300)
          .style("fill", "#f89247")

        tooltip.html("Signal:" + d.Signal + ", " + "VMax:" + d.VMax)
          .style('visibility', 'visible');
      })
      .on("mousemove", function () {
        tooltip.style("top", (event.pageY - 10) + "px")
          .style("left", (event.pageX + 10) + "px");
      })
      .on("mouseout", function (d) {
        select(this)
          .transition()
          .duration(300)
          .style('fill', "#2aaf94")

        tooltip.style("visibility", "hidden");
      })

    var tooltip = select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("visibility", "hidden");
  } else {
    gRoot.selectAll("rect")
      .data(skewData)
      .enter().append("rect")
      .style("fill", function (d) { return d.color; })
      .attr("x", function (d, i) {
        return xScale(d.Signal);
      })
      .attr("width", xScale.bandwidth())
      .attr("y", function (d) { return yScale(d[key]); })
      .attr("height", function (d) {
        return plotHeight - yScale(d[key]);
      })
  }

  const yLabel = report ? 'translate(' + -75 + ' ' + plotHeight / 2 + ') rotate(-90)' :
    'translate(' + -50 + ' ' + plotHeight / 2 + ') rotate(-90)';

  gRoot.append('text')
    .text(key === "Xtalk" ? 'Crosstalk to signal ratio' : "Vmax (mV)")
    .attr('class', 'report-delay-name')
    .style('text-anchor', 'middle')
    .attr('font-size', 14)
    .attr('fill', '#000000a6')
    .attr('transform', yLabel);

  gRoot.append('text')
    .text('Signal')
    .attr('class', 'report-signal-name')
    .attr('x', plotWidth / 2)
    .attr('y', plotHeight + 20)
    .attr('fill', '#000000a6')
    .attr('font-size', 13)
    .attr('dy', '2.4em')
    .style('text-anchor', 'middle');

}

function drawCrosstalkVMax(svgElement, skewData, report = false, isChannel = false) {
  drawCrosstalk({
    svgElement,
    skewData,
    report,
    key: "VMax",
    isChannel
  })
}

function drawCrosstalkXTalk(svgElement, skewData, report = false) {
  drawCrosstalk({
    svgElement,
    skewData,
    report,
    key: "Xtalk"
  })
}

export { drawCrosstalk, drawCrosstalkVMax, drawCrosstalkXTalk };