import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Table from '@/components/EditableTable';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  getSignalConnsColumns,
  getPCBSignalConnections,
  getSignalListByPCBChannel
} from '@/services/Andes_v2/endToEndChannel';
import {
  updateEndToEndChannelContent,
  saveChannelSelect,
  changeEndToEndSignalName,
  delEndToEndSignal,
  changeSelectedChannelSignal,
  addConnectionSignal
} from '../store/endToEndChannel/action';
import channelSetupInfo from "@/services/Andes_v2/channel/channelInfo";
import "./index.css";

class SignalMappingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signalColumns: getSignalConnsColumns(props.pcbConnections, props.connections)
    }
  }

  componentDidMount = async () => {
    this.updateSignalColumns();
  }

  componentDidUpdate = async (prevProps) => {
    const { pcbConnections, signalMappingUpdate } = this.props;
    if (prevProps.pcbConnections.length !== pcbConnections.length
      || (signalMappingUpdate && signalMappingUpdate !== prevProps.signalMappingUpdate)) {
      this.updateSignalColumns();
      signalMappingUpdate && this.props._setupUpdateStatus(false, "signalMappingUpdate");
    }
  }

  updateSignalColumns = () => {
    const signalColumns = getSignalConnsColumns(this.props.pcbConnections);
    this.setState({ signalColumns }, () => {
      this.columnsCell(signalColumns);
    })
  }

  columnsCell = (signalColumns) => {
    let _signalColumns = [...signalColumns];
    _signalColumns.forEach(column => {
      const dataIndex = column.dataIndex;
      if (dataIndex === "name") {
        column.onCell = (record) => {
          return {
            record,
            edit: true,
            dataIndex,
            handleSave: this.props._changeEndToEndSignalName
          }
        }

        column.render = (name, record) => {
          return (
            <div className="connection-signal-content">
              <span>{name}</span>
              <CloseOutlined className='signal-delete-icon' onClick={(e) => this.delSignal(e, record)} />
            </div>
          );
        }
      } else {
        column.onCell = (record) => {
          return {
            edit: 'select',
            record,
            allowClear: true,
            options: this.getSelectSignalList(record, column, dataIndex),
            dataIndex,
            getPopupContainer: document.getElementById("root"),
            dropdownMenuClassName: 'andes-v2-select-dropdown-menu',
            handleSave: (_record, prevRecord) => this.channelSignalSelect(_record, dataIndex, column.pcbChannel),
            handleClear: (_record, prevRecord) => this.channelSignalSelect(_record, dataIndex, column.pcbChannel, "clear")
          }
        }
      }
    })

    this.setState({ signalColumns: _signalColumns })
  }

  delSignal = (e, record) => {
    e.stopPropagation();
    this.props._delEndToEndSignal(record.name);
  }

  getSelectSignalList = (record, column, dataIndex) => {
    const pcbChannel = column.pcbChannel;
    const { connections } = this.props;
    if (!pcbChannel.channelId) {
      return [];
    }
    const allSignalList = channelSetupInfo.getSignalNames(pcbChannel.channelId);
    return getSignalListByPCBChannel(pcbChannel, allSignalList, connections, record[dataIndex])
  }

  channelSignalSelect = (record, dataIndex, pcbChannel, type) => {
    if (type === "clear") {
      record[dataIndex] = ""
    }
    this.props._changeSelectedChannelSignal(record, dataIndex, pcbChannel);
  }

  addNewSignal = () => {
    this.props._addConnectionSignal();
    //The table has a scroll bar to scroll to the bottom
    setTimeout(() => {
      const table = ReactDOM.findDOMNode(this.table),
        tableBody = table ? table.querySelector('.aurora-table-body') : null;
      if (tableBody && tableBody.scrollHeight) {
        tableBody.scrollTop = tableBody.scrollHeight;
      }
    }, 260);
  }

  render() {
    const { pcbConnections, connections, endToEndChannelId } = this.props;
    const { signalColumns } = this.state;
    const dataList = getPCBSignalConnections(pcbConnections, connections);
    const data = dataList ? dataList.map((record, index) => ({ ...record, index })) : []
    return (
      <div className="aurora-setup-border">
        <span className='out-title-color font-bold'>Signal Mapping</span>
        <PlusCircleOutlined className='signal-add-icon' onClick={this.addNewSignal} />
        <Table
          rowKey={record => `${endToEndChannelId}-${record.name}`}
          ref={(ref) => this.table = ref}
          columns={signalColumns}
          size="small"
          dataSource={data}
          scroll={dataList.length > 5 ? { y: 240 } : {}}
          className='andes-v2-table end-to-end-channel-connection-table space-10'
          rowClassName={(record, index) => index % 2 !== 0 ? 'editable-row aurora-row-background' : 'editable-row'}
        />
      </div>
    );
  }
}

const mapDispatch = (dispatch) => ({
  _updateEndToEndChannelContent(data) {
    dispatch(updateEndToEndChannelContent(data))
  },
  _saveChannelSelect(record, dataIndex) {
    dispatch(saveChannelSelect(record, dataIndex))
  },
  _changeEndToEndSignalName(record, prevRecord) {
    dispatch(changeEndToEndSignalName(record, prevRecord))
  },
  _delEndToEndSignal(signalName) {
    dispatch(delEndToEndSignal(signalName))
  },
  _changeSelectedChannelSignal(record, dataIndex, pcbChannel) {
    dispatch(changeSelectedChannelSignal(record, dataIndex, pcbChannel))
  },
  _addConnectionSignal() {
    dispatch(addConnectionSignal())
  }
})

export default connect(null, mapDispatch)(SignalMappingTable);