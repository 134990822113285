/**
 *
 *
 * @param {*} basicData obj array
 * @param {*} SORT_RULES sort rules array
 * @param {*} attr obj attr
 * @returns
 */
function SortFn(basicData, SORT_RULES, attr) {
  let arr = [], newData = [];
  SORT_RULES.forEach(rule => arr[rule] = []);
  arr['NA'] = []; // Undefined
  basicData.forEach(basic => SORT_RULES.includes(attr ? basic[attr] : basic) ? arr[attr ? basic[attr] : basic].push(basic) : arr['NA'].push(basic));
  newData.push(...arr['NA']);
  SORT_RULES.forEach(item => newData.push(...arr[item]));
  return newData;
}

// function testSortFn() {
//   const basicData = [{ key: 'A', value: 'AA' },
//   { key: 'D', value: 'DD' },
//   { key: 'C', value: 'CC' },
//   { key: 'A', value: 'AAA' },
//   { key: 'A', value: 'AA' }
//   ]
//   const SORT_RULES = ['A', 'B', 'C', 'D'];
//   console.log(SortFn(basicData, SORT_RULES, 'key'));
// }
// testSortFn()

function SortComponent(a, b) {
  if (a.name.length === b.name.length) {
    return a.name.localeCompare(b.name);
  } else {
    return a.name.length - b.name.length
  }
}

function SortComponents(comps, chips = []) {
  const filterChips = [];
  const filterNotChips = [];

  comps.forEach(item => {
    (chips.includes(item.name) ? filterChips : filterNotChips).push(item);
  });

  filterNotChips.sort((a, b) => {
    const aPart = a.part;
    const bPart = b.part;
    if (aPart.length !== bPart.length) {
      return aPart.length - bPart.length;
    }
    const partComparison = aPart.localeCompare(bPart);
    if (partComparison !== 0) {
      return partComparison;
    }
    return SortComponent(a, b);
  });

  return [...filterChips, ...filterNotChips];
}

export { SortFn, SortComponent, SortComponents };