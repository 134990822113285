import {
  CREATE_USER_FAILED,
  CLOSE_CREATE_USER_MODAL,
  OPEN_CREATE_USER_MODAL,
  INIT_USER_TABLE_SUCCESS,
  CHANGE_MENU,
  CHANGE_TABLE_SUCCESS,
  JUMP_TO_PAGE_SUCCESS,
  OPEN_CREATE_ROLE_MODAL,
  CLOSE_CREATE_ROLE_MODAL,
  CREATE_ROLE_FAILED,
  CHANGE_EMAIL_MODAL_VISIBLE,
  SAVE_ROLE_LIST,
  SAVE_PERM_LIST,
  CLEAR_DATA,
  UPDATE_ROLE_MANAGEMENT
} from './actionTypes';
import { USER_LOGOUT } from '../Login/actionTypes';

const defaultState = {
  table: {
    dataSource: [],
    loading: false
  },
  pagination: {
    showQuickJumper: false,
    defaultCurrent: 0,
    defaultPageSize: 10,
    total: 0,
  },
  msg: null,
  visiable: false,
  menu: 'user',
  roleVisiable: false,
  roleMsg: null,
  permissionMsg: null,
  emailVisible: false,
  roles: [],
  permissions: [],
  roleManagement: ""
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_USER_FAILED:
      return {
        ...state,
        msg: action.msg
      }
    case OPEN_CREATE_USER_MODAL:
      return {
        ...state,
        visiable: true,
      }
    case CLOSE_CREATE_USER_MODAL:
      return {
        ...state,
        visiable: false,
        msg: null,
      }
    case CHANGE_TABLE_SUCCESS:
    case INIT_USER_TABLE_SUCCESS:
    case JUMP_TO_PAGE_SUCCESS:
      const { content, totalElements, size } = action.responseData;
      content.forEach((item) => {
        item.key = item.id
      })
      return {
        ...state,
        table: {
          ...state.table,
          dataSource: content,
          loading: false
        },
        pagination: {
          ...state.pagination,
          pageSize: size,
          total: totalElements,
          current: action.pageNum ? action.pageNum : 1
        }
      }
    case CHANGE_MENU:
      return {
        ...state,
        menu: action.menu
      }
    case OPEN_CREATE_ROLE_MODAL:
      return {
        ...state,
        roleVisiable: true,
      }
    case CLOSE_CREATE_ROLE_MODAL:
      return {
        ...state,
        roleVisiable: false,
        roleMsg: null,
      }
    case CREATE_ROLE_FAILED:
      return {
        ...state,
        roleMsg: action.roleMsg,
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CHANGE_EMAIL_MODAL_VISIBLE:
      return {
        ...state,
        emailVisible: action.visible
      }
    case SAVE_ROLE_LIST:
      return {
        ...state,
        roles: action.list
      }
    case SAVE_PERM_LIST:
      return {
        ...state,
        permissions: action.list
      }
    case CLEAR_DATA:
      return {
        ...state,
        table: {
          dataSource: [],
          loading: true
        }
      }
    case UPDATE_ROLE_MANAGEMENT:
      return {
        ...state,
        roleManagement: action.roleManagement
      }
    default:
      return state;
  }
}