import { takeEvery, select, put, cancel, call, delay, fork } from 'redux-saga/effects';
import {
  START_CARD_MODELING,
  AUTO_GET_CARD_VERIFICATIONS
} from './actionTypes';
import { verificationWorkFlow, getWorkflowTask, updateWorkflowTask, updateWaitingStartMsg } from '../saga';
import {
  updateSimulationReducer,
  updateCurrentSimulationInfo,
  changeVerificationList,
  getVerificationLog
} from '../action';
import {
  doRockySimulation,
  getVerificationContentPromise,
  getRockyVerificationsInChannel,
  updateChannelItem,
  PROJECT_INDEX,
  CARD_INDEX,
  getChannelConfig
} from '@/services/Rocky';
import {
  checkVerificationStatus,
  getVerificationWorkFlow
} from '@/services/workflow/workflow';
import { VERIFY_SUCCESS } from '@/constants/verificationStatus';
import { getDesignStackupJson } from "@/services/api/designFile";
import { stackupErrorCheck } from "@/services/Stackup";
import cardChannelsConstructor from '../../../../../services/Rocky/cardHelper';
import { projectMenu } from '../../project/action';
import { isRockyPuppeteer } from '../../../../../services/helper/browser';
import { ROCKY } from '../../../../../constants/pageType';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { CARD } from '../../../../../constants/treeConstants';
import {
  autoGetCardVerifications
} from './action';
import { getCardErrorCheck } from '../../../errorCheck/cardErrorCheck';
import { getProjectType } from '../../rocky/saga';
import RockySetup from '@/services/Rocky/helper/rockyDatabase';
import { updateCardErrorCheckList } from '../../card/action';
import { checkCardContentIsNeedUpdate } from '../../card/saga'
import { API_RES_MESSAGE } from '../../../../../constants/returnCode';
import dayjs from 'dayjs';

let workflowTask = getWorkflowTask();

function* _startModeling(action) {
  let { verificationIds, verificationId } = action;
  //clear selected verification list
  yield put(changeVerificationList([], "cardSimulateKeys"));
  if (verificationIds.length === 0) {
    return;
  }
  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
    updateWorkflowTask(null);
  }

  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  const cardVerification = cardChannelsConstructor.getVerification(verificationId) || {};
  let designId = cardVerification.designId,
    currentSimInfo = [];

  let _verificationIds = [];
  for (let item of verificationIds) {
    //check simulation status
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && [VERIFY_RUNNING, WAITING].includes(promise.status)) {
      continue;
    }

    _verificationIds.push(item);
    currentSimInfo.push({
      verificationId: item,
      status: 'checking'
    });
    //clean prev simulation info by verificationId
    delete newSimulationReducer[item];
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    newSimulationReducer[item] = {
      startMsg: `==> ${waitingTime} Checking setup...`
    }

    if (!designId) {
      const cardVerification = cardChannelsConstructor.getVerification(item) || {};
      designId = cardVerification.designId;
    }
  };

  verificationIds = [..._verificationIds];
  yield put(updateSimulationReducer(newSimulationReducer));
  //auto get verification list
  let { RockyReducer: { project: { currentProjectId }, card: { cardChannelId } } } = yield select();
  yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: cardChannelId }));
  //error check 
  //stackup / verification
  const info = yield call(cardSimulationErrorCheck, {
    verificationIds,
    currentSimInfo,
    designId
  });
  //{ channelId, verificationIds: [id1,id2] }
  let channels = info ? info.channels : [];
  verificationIds = info ? info.verificationIds : [];

  if (!channels || channels.length === 0) {
    return;
  }
  //auto get verification list
  yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: cardChannelId }));
  reducer = yield select();
  simulationReducer = reducer.RockyReducer.simulationReducer;
  channels.forEach(item => delete item.channelId);//[ { verificationIds: [verificationId1, verificationId2, ...]}, { ... } ]
  let waitingTask = null;
  try {
    waitingTask = yield fork(updateWaitingStartMsg, { verificationIds, _simulation: simulationReducer })
    yield call(doRockySimulation, channels);
    yield cancel(waitingTask);
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    yield* verificationIds.map(function* (item) {
      simulationReducer[item] = {};
      let msg = ""
      if (error && error.code === API_RES_MESSAGE) {
        msg = `==> ${error.msg}`;
        // get log
        yield put(getVerificationLog(item));
        simulationReducer[item].simulationMsg = msg;
      } else {
        msg = `==> ${error}`;
        simulationReducer[item].endMsg = msg;
      }
    })
    //update simulation reducer
    yield put(updateSimulationReducer(simulationReducer));
    //update tree verification status (auto get verification list)
    yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: cardChannelId }));
    yield delay(1000);
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
    //clear selected verification list
    yield put(changeVerificationList([], "cardSimulateKeys"));
    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "resultExist", info: resultExist }));
    return;
  }

  // fake progress
  // id: workflowId
  // update tree verification status (auto get verification list)
  yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: cardChannelId }));
  try {
    const verificationStatus = yield call(getVerificationWorkFlow, verificationId);
    if (verificationStatus && verificationStatus.length > 0) {
      if (workflowTask) {
        yield cancel(workflowTask);
      };
      const _workflowTask = yield fork(verificationWorkFlow, { verificationId, verificationWork: verificationStatus, simulationType: CARD });
      updateWorkflowTask(_workflowTask);
      return;
    } else {
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
      yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }))
    };
  } catch (error) {
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "progress", info: -1 }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId: verificationId, item: "startMsg", info: null }))
  }
}

function* cardSimulationErrorCheck({
  verificationIds,
  designId,
  currentSimInfo
}) {
  //check stackup data
  let stackupError = null;
  if (designId) {
    //Design stackup.json error check
    const stackup = yield call(getDesignStackupJson, designId);
    if (!stackup || !stackup.layers) {
      stackupError = [{ error: 'Stackup file does not exist!' }];
    } else {
      stackupError = stackupErrorCheck({
        ...stackup,
        layers: stackup.layers,
        materialList: stackup.materials,
        unit: stackup.unit,
      });
    }
  }
  let errorCheckIds = [];//Exist error check verification id;
  let { RockyReducer } = yield select();
  let simulationReducer = RockyReducer.simulationReducer;
  let { extraction, cardChannelId } = RockyReducer.card;
  let channels = [], channelExtractions = {}; //{ channelId, verificationIds: [id1,id2], pdnId }
  for (let item of verificationIds) {
    //get current verification's channelId
    const cardVerification = cardChannelsConstructor.getVerification(item) || {};
    const channelId = cardVerification.channelId;
    // error check
    //get current verification content
    let responseInfo = null;
    try {
      responseInfo = yield call(getVerificationContentPromise, item);
    } catch (error) {
      console.error(error);
    }
    let { RockyReducer: { card: { cardErrorCheck }, project: { pkgSpiceList, pkgTouchstoneList, ebdList, ibisList } } } = yield select();
    const index = currentSimInfo.findIndex(sim => sim.verificationId === item);
    if (responseInfo && responseInfo.interfaces) {
      //if interfaces not init,return
      if ((!responseInfo.version || responseInfo.version === "0")) {
        simulationReducer[item] = {};
        if (index > -1) {
          currentSimInfo[index].status = 'error';
        }
        errorCheckIds.push(item);
        const errorIndex = cardErrorCheck.findIndex(error => error.verificationId === item);
        if (errorIndex > -1) {
          cardErrorCheck[errorIndex].errorCheck = { error: ["Interface has not been initialized!"] };
        } else {
          cardErrorCheck.push({
            verificationId: item,
            errorCheck: { error: ["Interface has not been initialized!"] }
          })
        }
        continue;
      } else {
        responseInfo = yield call(checkCardContentIsNeedUpdate, { response: responseInfo, isUpdateNow: true })
        cardErrorCheck = cardErrorCheck.filter(it => it.verificationId !== item);
      }

      // error check
      const info = RockySetup.mergeInterfacesInfo(responseInfo.interfaces, responseInfo.name, yield call(getProjectType));
      const cardInfo = { info, Interfaces: responseInfo.interfaces };
      channelExtractions[cardChannelId] = extraction.channelType;
      //if verification channel is not opened
      let channelType = channelExtractions[channelId];
      if (!channelExtractions[channelId]) {
        try {
          const configRes = yield call(getChannelConfig, channelId);

          channelType = configRes && configRes.config && configRes.config.extraction
            ? (configRes.config.extraction.channelType || "SIwave")
            : channelType;

          channelExtractions[channelId] = channelType;
        } catch (error) {
          console.error(error);
        }
      }
      const errorCheck = getCardErrorCheck(cardInfo, channelType, { pkgSpiceList, pkgTouchstoneList, ebdList, ibisList });
      if (errorCheck) {
        simulationReducer[item] = {};
        if (index > -1) {
          currentSimInfo[index].status = 'error';
        }
        errorCheckIds.push(item);
        const errorIndex = cardErrorCheck.findIndex(error => error.verificationId === item);
        if (errorIndex > -1) {
          cardErrorCheck[errorIndex].errorCheck = { ...errorCheck };
        } else {
          cardErrorCheck.push({
            verificationId: item,
            errorCheck: errorCheck
          })
        }
        yield put(updateCardErrorCheckList(cardErrorCheck));
      } else {
        cardErrorCheck = cardErrorCheck.filter(it => it.verificationId !== item);
      }

      yield put(updateCardErrorCheckList(cardErrorCheck));

      if (stackupError && stackupError.length) {
        errorCheckIds.push(item);
        simulationReducer[item] = {};
        simulationReducer[item].stackupError = stackupError;
        if (index > -1) {
          currentSimInfo[index].status = 'error';
        }
      } else if (!errorCheckIds.includes(item)) {
        simulationReducer[item] = {};
        simulationReducer[item].startMsg = '==> Start simulating...';
        if (index > -1) {
          currentSimInfo[index].status = 'success';
        }

        const findCIndex = channels.findIndex(i => i.channelId === channelId);

        if (findCIndex > -1) {
          channels[findCIndex].verificationIds = channels[findCIndex].verificationIds
            ? [...channels[findCIndex].verificationIds, item]
            : [item];
        } else {
          channels.push({
            channelId,
            verificationIds: [item]
          })
        }
      }
    }
  }

  errorCheckIds = [...new Set([...errorCheckIds])];

  //update tree verification status (auto get verification list)
  let { RockyReducer: { project: { currentProjectId }, card: { cardChannelId: _cardChannelId } } } = yield select();
  yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: _cardChannelId }));
  //update simulation reducer
  yield put(updateSimulationReducer(simulationReducer));

  // remove exist error check verification
  verificationIds = verificationIds.filter(item => !errorCheckIds.includes(item));

  if (!verificationIds || verificationIds.length === 0 || !channels || channels.length === 0) {
    //update tree verification status (auto get verification list)
    yield put(autoGetCardVerifications({ projectId: currentProjectId, channelId: cardChannelId }));
    return { verificationIds: [], channels: [] };
  };
  return { verificationIds, channels };
}

// Update card verification status
function* _autoGetCardVerificationList(action) {
  if (isRockyPuppeteer()) return;
  const { projectId, channelId, currentSimInfo } = action;
  let delayTime = 5000;

  // currentSimInfo - change simulation status, from checking -> error / success
  if (!currentSimInfo) {
    let first = true;
    let isWhile = true;
    while (isWhile) {
      const { LoginReducer: { pageType },
        RockyReducer: {
          project: { currentProjectId, treeItems },
          card: { cardChannelId } } } = yield select();

      if (pageType !== ROCKY
        || !cardChannelId
        || !currentProjectId
        || cardChannelId !== channelId
        || currentProjectId !== projectId) {
        return;
      };
      if (!first) {
        yield delay(delayTime);//5000
      }
      first = false;
      let _treeItems = [...treeItems], allSimulationChannelIds = [];

      const projectIndex = _treeItems[PROJECT_INDEX].children.findIndex(item => item.id === projectId)

      if (!projectIndex < 0) {
        isWhile = false;
        return;
      }

      const _project = _treeItems[PROJECT_INDEX].children[projectIndex];

      if (!_project.children || !_project.children[CARD_INDEX]) {
        isWhile = false;
        return;
      }
      const cards = _project.children[CARD_INDEX].children || [];
      const currentChannel = cardChannelsConstructor.getChannel(channelId);
      if (!currentChannel) {
        isWhile = false;
        return;
      }
      const design_index = cards.findIndex(item => item.id === currentChannel.designId);
      if (design_index < 0) {
        isWhile = false;
        return;
      }
      const channels = cards[design_index].children || [];
      const channel_index = channels.findIndex(item => item.id === channelId);

      try {
        // channel status
        const verifications = yield call(getRockyVerificationsInChannel, channelId);
        const _verifications = (verifications && verifications.rockyVerifications) || [];

        const updatedChannelItem = updateChannelItem(_verifications, channels[channel_index], null, false, CARD);
        //update currentChannel to _treeItems
        _treeItems[PROJECT_INDEX].children[projectIndex].children[CARD_INDEX].children[design_index].children[channel_index] = updatedChannelItem;
        // Update project channel list in menu
        yield put(projectMenu({ treeItems: [..._treeItems] }));
        // update current channel verifications
        cardChannelsConstructor.setChannelVerifications(channelId, updatedChannelItem.children);

        const simVerifications = _verifications.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status))
        allSimulationChannelIds = [...allSimulationChannelIds, ...simVerifications];

        if (allSimulationChannelIds && allSimulationChannelIds.length) {
          delayTime = 5000;
        } else {
          isWhile = false;
        }
      } catch (error) {
        console.error(error);
        isWhile = false;
      }
    }
  } else {
    // update once
    const { RockyReducer: { project } } = yield select();
    let { treeItems } = project;

    let _treeItems = [...treeItems];
    // treeItems[PROJECT_INDEX] - 'projects'
    const projectIndex = _treeItems[PROJECT_INDEX].children.findIndex(item => item.id === projectId);
    if (projectIndex < 0) {
      // tree channel list
      return;
    };
    const _project = _treeItems[PROJECT_INDEX].children[projectIndex];

    if (!_project.children || !_project.children[CARD_INDEX]) {
      return;
    }
    const cards = _project.children[CARD_INDEX].children || [];
    const currentChannel = cardChannelsConstructor.getChannel(channelId);
    if (!currentChannel) {
      return;
    }
    const design_index = cards.findIndex(item => item.id === currentChannel.designId);
    if (design_index < 0) {
      return;
    }
    const channels = cards[design_index].children || [];
    const channel_index = channels.findIndex(item => item.id === channelId);

    if (channel_index < 0) {
      return;
    }
    // tree current channel verifications
    let verifications = channels[channel_index].children || [];
    verifications.forEach(item => {
      const index = currentSimInfo.findIndex(i => i.verificationId === item.id);
      if (index > -1 && (!item.simStatus || (item.simStatus && currentSimInfo[index].status !== item.simStatus))) {
        item.simStatus = currentSimInfo[index].status;
      }
    });
    //update verifications to treeItems
    _treeItems[PROJECT_INDEX].children[projectIndex].children[CARD_INDEX].children[design_index].children[channel_index].children = verifications;
    yield put(projectMenu({ treeItems: [..._treeItems] }));
  };
}

let autoUpdateVerificationsTask = null;
function* _autoGetCardVerifications(action) {
  if (autoUpdateVerificationsTask) {
    yield cancel(autoUpdateVerificationsTask);
  }
  autoUpdateVerificationsTask = yield fork(_autoGetCardVerificationList, action);
}

function* cardSaga() {
  yield takeEvery(START_CARD_MODELING, _startModeling);
  yield takeEvery(AUTO_GET_CARD_VERIFICATIONS, _autoGetCardVerifications);
}

export default cardSaga;