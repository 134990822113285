import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var PinSymbolPinMap = function (symbolGrp, symbolId, symPinNum) {
  this.mSymbolGroup = symbolGrp;
  this.mSBIdentifier = symbolId;
  this.mSymPinNumber = symPinNum;
};

PinSymbolPinMap.prototype.Clone = function () {
  return new PinSymbolPinMap(this.mSymbolGroup, this.mSBIdentifier, this.mSymPinNumber);
}

// var PartPin = function () {
//     this.mName = "";
//     this.mType = "";
//     this.mSwapGrp = "";
//     this.mSymbolPinMap = [];
// };

// var PartPin = function (fromPin) {
//     this.CopyFrom(fromPin);
// };

// var PartPin = function (id, name) {
//     this.mNumber = id;
//     this.mName = name;
//     this.mType = "";
//     this.mSwapGrp = "";
//     this.mSymbolPinMap = [];
// };

function PartPin(id, name, type, swapGrp) {
  this.mNumber = id === undefined ? "" : id;
  this.mName = name ? "" : name;
  this.mType = type ? "" : type;
  this.mSwapGrp = swapGrp ? "" : swapGrp;
  // this.mFutprtPadID  = new CeLinkedMap();
  // this.mSymbolPinMap = [];
};

PartPin.prototype.CopyFrom = function (fromPin) {
  this.mNumber = fromPin.this.mNumber;
  this.mName = fromPin.this.mName;
  this.mType = fromPin.this.mType;
  this.mSwapGrp = fromPin.this.mSwapGrp;
  // this.mFutprtPadID  = new CeLinkedMap();
  // this.mFutprtPadID.CopyFrom(fromPin.mFutprtPadID);
  // this.mSymbolPinMap = [];
  // for(var i=0; i<fromPin.mSymbolPinMap.length; i++)
  //     this.mSymbolPinMap.push(fromPin.mSymbolPinMap[i].Clone());
}

PartPin.prototype.SetPinID = function (id) {
  this.mNumber = id;
}

PartPin.prototype.SetName = function (name) {
  this.mName = name;
}

PartPin.prototype.SetType = function (type) {
  this.mType = type;
}

PartPin.prototype.SetSwapGroup = function (grp) {
  this.mSwapGrp = grp;
}

PartPin.prototype.GetPinID = function () {
  return this.mNumber;
}

PartPin.prototype.GetSymPinID = function () {
  if (this.mSymbolPinMap.length > 0) {
    return this.mSymbolPinMap[0].mSymPinNumber;
  }
  return -1;
}

PartPin.prototype.SetFootPrintPadID = function (footprint, padID) {
  this.mFutprtPadID.put(footprint, padID);
}

PartPin.prototype.equals = function (pin2) {
  if (pin2 == null)
    return false;

  if (this.mName.compareToIgnoreCase(pin2.this.mName) != 0 ||
    this.mType.compareToIgnoreCase(pin2.this.mType) != 0 ||
    this.mNumber != pin2.this.mNumber)
    return false;

  return true;
}

PartPin.prototype.WriteIntoIODataNode = function () {
  var myBlock = new CeIODataBlock("Pin");
  var pinData = this.mNumber + ",";
  pinData += this.mName != null ? this.mName : "";
  pinData += ",";
  pinData += this.mType != null ? this.mType : "";
  pinData += ",";
  pinData += this.mSwapGrp.length ? this.mSwapGrp : " ";
  myBlock.AddDataItem("DefData", pinData);

  // var sympinMap = new StringList();
  // for(var i=0; i<this.mSymbolPinMap.length; i++){
  //     sympinMap.add(this.mSymbolPinMap[i].mSymbolGroup);
  //     sympinMap.add(this.mSymbolPinMap[i].mSBIdentifier);
  //     sympinMap.add(this.mSymbolPinMap[i].mSymPinNumber);
  // }
  // myBlock.AddDataItem("SymPinMap", sympinMap);

  // if(this.mFutprtPadID != null){
  //     var futPadMap = new StringList();
  //     var keys = this.mFutprtPadID.keySet();
  //     for(var i=0; i<keys.length; i++){
  //         futPadMap.add(keys[i]);
  //         futPadMap.add(this.mFutprtPadID.get(keys[i]));
  //     }
  //     if(futPadMap.isEmpty()==false)
  //         myBlock.AddDataItem("FutPadMap", futPadMap);
  // }   

  return myBlock;
}

PartPin.prototype.ReadFromIODataNode = function (myBlock) {
  var defData = myBlock.GetItemString("DefData");
  var items = defData.split(",");
  if (items.length >= 3) {
    this.mNumber = items[0];
    this.mName = items[1];
    this.mType = items[2];
    if (items.length >= 4)
      this.mSwapGrp = items[3];
  }
  // var sympinMap = myBlock.GetItemStringList("SymPinMap");
  // if(sympinMap!=null && sympinMap.size()>=3){
  //     for(var i=0; i<sympinMap.size(); i+=3){
  //         var group  = sympinMap.get(i);
  //         var symbolId = parseInt(sympinMap.get(i+1));
  //         var pinNum = parseInt(sympinMap.get(i+2));
  //         this.mSymbolPinMap.push(new PinSymbolPinMap(group, symbolId, pinNum));
  //     }
  // }
  // var futPadMap = myBlock.GetItemStringList("FutPadMap");
  // if(futPadMap!=null && futPadMap.size()>=2){
  //     for(var i=0; i<futPadMap.size(); i+=2){
  //         var futprt  = futPadMap.get(i);
  //         var padId  = futPadMap.get(i+1);
  //         this.mFutprtPadID.put(futprt, parseInt(padId));
  //     }
  // }
  return true;
}

// PartPin.prototype.SetSymbolPinMap = function(symbolGroup, identifer, pinNum) {
//     this.mSymbolPinMap.push(new PinSymbolPinMap(symbolGroup, identifer, pinNum));
// }

// PartPin.prototype.SetSymbolPinMap = function(symbolGroup, spmap) {
//     if(spmap.size()!=1){
//         throw "-spmap option error."
//     }
//     var splitRule = new StrSplitRule(" :,");
//     var strItems = StringHelper.SplitString(spmap.get(0), splitRule);
//     for(var i=0; i<strItems.length; i+=2){
//         var symbolId = parseInt(strItems[i]);
//         var pinNum = parseInt(strItems[i+1]);
//         this.mSymbolPinMap.push(new PinSymbolPinMap(symbolGroup, symbolId, pinNum));
//     }
// }

export default PartPin;