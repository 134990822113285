import { SPICE } from "../../../constants/libraryConstants";
import { I2CI3C, MODEL, SCHEMATIC } from "./prelayoutConstants";

function PreLayoutSetting(params = {}) {
  const {
    prelayout = MODEL,
    GPIO = I2CI3C,
    IC = 2, connector = 0, pullUpRes = 0, repeater = 0, testPoint = 0,
    signalNumber = 2 } = params;

  this.prelayout = prelayout;
  this.GPIO = GPIO;
  this.IC = prelayout === SCHEMATIC && !params.IC ? 0 : IC;
  this.connector = connector;
  this.pullUpRes = pullUpRes;
  this.repeater = repeater;
  this.testPoint = testPoint;
  this.signalNumber = signalNumber;
}

function PreLayoutModel(params = {}) {
  const { modelType = SPICE, libraries = [] } = params;

  this.modelType = modelType;
  this.libraries = libraries.filter(item => !!item);
}

function PreLayoutComponent(params = {}) {
  const { name, layer = 'Top', type, pins, value, voltage, group } = params;
  this.name = name;
  this.layer = layer;
  this.type = type;
  this.pins = pins;
  this.value = value || "";
  this.voltage = voltage || "";
  this.group = group || "";
}

function PreLayoutPin(params) {
  const { pin, net, signal, model } = params;
  this.pin = pin;
  this.net = net;
  this.signal = signal;
  this.model = model || { libraryId: "", port: "", subckt: "" };
  this.pinName = pin;
}

export {
  PreLayoutSetting,
  PreLayoutModel,
  PreLayoutComponent,
  PreLayoutPin
}