import {
  INIT_SIMULATION_INFO,
  UPDATE_MULTI_SIMULATION_INFO,
  UPDATE_SIMULATION_INFO,
  UPDATE_SIMULATION_REDUCER,
  GET_WORKFLOW_MONITOR,
  GET_VERIFICATION_WORKFLOW,
  GET_VERIFICATION_LOG,
  CANCEL_WORKFLOW,
  DOES_SIMULATION_FAILED,
  CLEAR_SIMULATION_INFO
} from './actionType'

export const initSimulationInfo = (verificationId) => ({
  type: INIT_SIMULATION_INFO,
  verificationId
})

export const updateMultiSimulationInfo = ({ verificationId, info }) => ({
  type: UPDATE_MULTI_SIMULATION_INFO,
  verificationId,
  info
});

export const updateSimulationInfo = ({ verificationId, item, info }) => ({
  type: UPDATE_SIMULATION_INFO,
  verificationId,
  item,
  info
});

export const updateSimulationReducer = (simulation) => ({
  type: UPDATE_SIMULATION_REDUCER,
  simulation
});

export const getWorkflowMonitor = (workflowId, verificationId) => ({
  type: GET_WORKFLOW_MONITOR,
  workflowId,
  verificationId,
});

export const getVerificationWorkflow = (verificationId) => ({
  type: GET_VERIFICATION_WORKFLOW,
  verificationId
})

export const getVerificationLog = (verificationId) => ({
  type: GET_VERIFICATION_LOG,
  verificationId
})

export const cancelWorkflow = (verificationId) => ({
  type: CANCEL_WORKFLOW,
  verificationId
})

export const doeSimulationFinish = (id) => ({
  type: DOES_SIMULATION_FAILED,
  id
})

export const clearSimulationInfo = () => ({
  type: CLEAR_SIMULATION_INFO
})