
function Connection({
  CONNECTION_ID,
  channel1,
  channel2,
  connection
}) {
  this.CONNECTION_ID = CONNECTION_ID;
  this.channel1 = channel1 || new ConnectionChannel({});
  this.channel2 = channel2 || new ConnectionChannel({});
  this.connection = connection || new ConnectionItem({});
}

function ConnectionChannel({ designId = "", designName = "", channelId = "", channelName = "" }) {
  this.designId = designId;
  this.designName = designName;
  this.channelId = channelId;
  this.channelName = channelName;
}

function ConnectionItem({
  signal_connections_map = [],
  cableModels = [],
  connector1 = null,
  connector2 = null
}) {
  this.signal_connections_map = signal_connections_map;
  this.cableModels = cableModels;
  this.connector1 = connector1 || new Connector({});
  this.connector2 = connector2 || new Connector({})
}

function Connector({
  component = "",
  models = [],
  pins = []
}) {
  this.component = component;
  this.models = models;
  this.pins = pins;
}


function ConnectorPin({
  pin = "",
  port = "",
  modelId = "",
  pinMap = false,
  pin_map = "",
  externalMap = false,
  external_map = {}
}) {
  this.pin = pin;

  if (port) {
    this.port = port;
    this.modelId = modelId;
  }

  if (pinMap) {
    this.pin_map = pin_map;
  }

  if (externalMap) {
    this.external_map = external_map;
  }
}

function SignalConnectionMap({
  name = "",
  channel1_signal = "",
  channel2_signal = ""
}) {
  this.name = name;
  this.channel1_signal = channel1_signal;
  this.channel2_signal = channel2_signal;
}

function PCBConnection({
  designId = "",
  designName = "",
  channelId = "",
  channelName = "",
  prevConnectionId = "",
  nextConnectionId = ""
}) {
  this.designId = designId;
  this.designName = designName;
  this.channelId = channelId;
  this.channelName = channelName;
  this.prevConnectionId = prevConnectionId;
  this.nextConnectionId = nextConnectionId;
}

function EndToEndChannelContent() {
  this.pcbConnections = [
    { ...new PCBConnection({ nextConnectionId: "1" }) },
    { ...new PCBConnection({ prevConnectionId: "1" }) }
  ];
  this.connections = [{
    ...new Connection({ CONNECTION_ID: "1" })
  }]
}

export {
  Connection,
  ConnectionChannel,
  ConnectionItem,
  ConnectorPin,
  Connector,
  SignalConnectionMap,
  EndToEndChannelContent,
  PCBConnection
}
