import React, { PureComponent, Fragment } from 'react';
import { CloseOutlined, DownloadOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Checkbox, Divider, Tooltip } from 'antd';
import './new.css';

const CheckboxGroup = Checkbox.Group;

class History extends PureComponent {
  inputClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { historyActions, historyData } = this.props;
    const { historyTitleClick, fileNameClick, historyItemTitleClick, downloadCSV, signalNameClick, colorChange, crossTalk, changePort, deleteHistory } = historyActions;
    const { historyShow, historyList, currentResultKey, portSelect, files, showNearEndCrossTalk, showFarEndCrossTalk, showReturnLoss, showInsertionLoss, showDiffToComm, width } = historyData;
    const historyFiles = files.filter(item => item.fileType === 'history');
    return (
      <div className='andes-result-default'>
        <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className='andes-result-driver-title' onClick={historyTitleClick}>
            {historyShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
            <span className='andes-result-menu-title'>History</span>
          </div>
        </Divider>
        {historyShow && historyList && historyList.length > 0 && historyList.map(item =>
          <div className='andes-result-history-list' key={item.time}>
            <div className='andes-result-history-title' onClick={(e) => historyItemTitleClick(e, item.name, item.time)}>
              {item.show ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
              <span title={item.name}>{item.name}</span>
              <CloseOutlined
                className="andes-file-delete-icon"
                title='Delete History'
                onClick={(e) => deleteHistory(e, item.time)} />
            </div>
            <div className='andes-result-history-file-content' style={{ display: item.show ? 'block' : 'none', width: width }}>
              {historyFiles.length > 0 ? historyFiles.map((file, fileIndex) =>
                item.time === file.time &&
                <div key={file.id}>
                  <Row key={file.id} className='andes-result-file-name'>
                    <Col span={24} onClick={() => fileNameClick(file.id)}>
                      {file.show ? <DownOutlined className="andes-file-expand-icon" /> : <RightOutlined className="andes-file-expand-icon" />}
                      <span className='span-file-name'>{file.pcb}</span>
                      {currentResultKey === 'returnLoss' || currentResultKey === 'insertionLoss' || currentResultKey === 'diffToComm' ?
                        <Tooltip title='Export CSV' overlayClassName='aurora-tooltip'>
                          <DownloadOutlined
                            onClick={(e) => downloadCSV(e, file, file.time)}
                            className="andes-file-download-icon" />
                        </Tooltip>
                        : null}
                    </Col>
                  </Row>
                  <Row
                    key={fileIndex}
                    style={{ display: file.show ? 'block' : 'none' }}
                    className='andes-result-ports-list'
                  >
                    {showNearEndCrossTalk || showFarEndCrossTalk ? crossTalk(file)
                      : <CheckboxGroup
                        value={portSelect[file.id]}
                        key={file.id}
                        onChange={(values) => changePort(values, file.id)}
                        style={{ width: "100%" }}
                      >
                        {file.netList && file.netList.map((signalInfo, signalIndex) => <Fragment key={signalInfo.signal}>
                          <div>
                            <div
                              style={{ display: file.netList.length > 1 ? 'block' : 'none' }}
                              onClick={() => signalNameClick(file, signalIndex)}
                              className='andes-channel-port-name andes-channel-port-name-general'
                            >
                              {signalInfo.show ? <DownOutlined className='andes-file-expand-icon' /> : <RightOutlined className='andes-file-expand-icon' />}
                              <span>{signalInfo.signal}</span>
                            </div>
                            <div style={{ display: signalInfo.show ? 'block' : 'none' }}>
                              <Row style={{ display: signalInfo.show ? 'block' : 'none' }}>
                                {signalInfo.ports && signalInfo.ports.length && signalInfo.ports.map(portNumber => {
                                  const portIndex = portNumber - 1;
                                  const port = file.ports[portIndex];
                                  return <Fragment key={port.name}>
                                    {signalInfo.ports.map(matrix => {
                                      const matrixIndex = matrix - 1;
                                      const _matrix = file.matrix[portIndex][matrixIndex];
                                      return _matrix.display && <Checkbox
                                        key={`${file.id}::${portIndex}::${matrixIndex}`}
                                        value={`${file.id}::${portIndex}::${matrixIndex}`}
                                        className='andes-result-checkbox-group'
                                      >
                                        <span className='andes-result-checkbox-group-name'>
                                          <input
                                            type='color'
                                            className="color-select"
                                            value={_matrix.color}
                                            style={{ verticalAlign: 'middle' }}
                                            onChange={(e) => colorChange(e, { id: file.id, portIndex, matrixIndex })}
                                            id={`col::${file.id}::${portIndex}::${matrixIndex}`}
                                          />
                                          <label htmlFor={`${portIndex}::${matrixIndex}`} className='andes-result-checkbox-label'>
                                            {showReturnLoss ? `S(${portNumber}, ${matrix}) ${port.compType}_${port.comp}`
                                              : null
                                            }
                                            {(showInsertionLoss || showDiffToComm) ? `S(${portNumber}, ${matrix}) (${port.compType}_${port.comp}, ${file.ports[matrixIndex].compType}_${file.ports[matrixIndex].comp})`
                                              : null
                                            }
                                          </label>
                                        </span>
                                        <span className='andes-result-curve-current' style={{ color: _matrix.color }}>{_matrix.current}</span>
                                      </Checkbox>
                                    })}
                                  </Fragment>
                                })
                                }
                              </Row>
                            </div>
                          </div>
                        </Fragment>)}
                      </CheckboxGroup>}
                  </Row>
                </div>) : null}
            </div>
          </div> || null)}
      </div>
    );
  }
}

export default History;