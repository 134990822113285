import React, { Component, Fragment } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Select, Collapse, Tooltip } from 'antd';
import {
  AMIModelConfig,
  saveIbisModel,
  adsConfigItemErrorCheck,
  ADS_TX,
  AMISignalPRBSConfig,
  updateParamsValueByCorner,
  SET_ALL_DATA_SELECT_LIST,
} from '@/services/Andes_v2/AMIModelHelper';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import FileContentPanel from '@/components/LibraryUpload/FileContentPanel';
import { getFileContent } from '@/services/Andes_v2/library';
import { IBIS } from '../../../../constants/libraryConstants';
import { getIbisModelParse, getIBISModelList } from '../../../../services/Andes_v2/library';
import JittersTable from '../jittersTable';
import IbisEQ from '../IbisEQ';
import { getValueFormEquation } from '@/services/Andes_v2/AMIModelHelper/equationCount';
import { END_TO_END_CHANNEL } from '../../../../constants/treeConstants';
import PRBSSetup from '../SetupComponents/PRBSSetup';
import { AMI_PRBS_MODE_LIST, updatePRBSOptions } from '../../../../services/Andes_v2/AMIModelHelper';
import "./index.css";

const { Option } = Select;
const jittersColumns = [
  {
    key: "name",
    title: "Name",
    width: 140
  },
  {
    key: "type",
    title: "Type",
    width: 100
  },
  {
    key: "value",
    title: "Value"
  }
];

class IBISModelSetup extends Component {
  constructor(props) {
    super(props);
    const { modelInfo: { model, component, prbs, dirType, interfaceType, channelId }, serdesType } = props;
    this.modelType = "IBIS";
    this.state = {
      applyAll: false,
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new AMIModelConfig({
        type: interfaceType,
        modelType: this.modelType,
        component,
        channelId,
        modelDir: dirType
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      fileVisible: false,
      libraryComponents: [],
      libraryModels: [],
      modelVisible: true
    }
    this.fileInfo = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.defaultModel();
  }

  componentDidUpdate = (prevProps) => {
    const { modelInfo: { signal, dirType } } = this.props;
    const prevSignal = prevProps.modelInfo ? prevProps.modelInfo.signal : null;
    const prevDirType = prevProps.modelInfo ? prevProps.modelInfo.dirType : null;

    if ((prevSignal !== signal) || (dirType !== prevDirType)) {
      this.defaultModel();
    }
  }

  closeModal = (isClose) => {
    const { applyAll, model, prbs } = this.state;
    const { modelInfo: { dirType, signal } } = this.props;

    let _model = { ...model };
    if (model && model.EQ) {
      _model.EQ = getValueFormEquation(model.EQ);
    }
    this.props.closeModal({ model: _model, prbs, signal, dirType, applyAll, isClose });
  }

  defaultModel = () => {
    const { modelInfo: { model, component, prbs, interfaceType, channelId, dirType }, serdesType } = this.props;
    if (!model || !model.libraryId) {
      this.setState({
        model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new AMIModelConfig({
          type: interfaceType,
          modelType: this.modelType,
          component,
          channelId,
          modelDir: dirType
        }),
        prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
        fileVisible: false,
        libraryComponents: [],
        libraryModels: [],
        modelVisible: true
      })
      return;
    }

    const fileList = libraryConstructor.getLibraryValues(IBIS) || [];
    const file = fileList.find(item => item.id === model.libraryId);

    if (file) {
      this.fileInfo = {
        id: file.id,
        name: file.name
      }
    }
    this.setState({
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new AMIModelConfig({
        type: interfaceType,
        modelType: this.modelType,
        component,
        channelId,
        modelDir: dirType
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      fileVisible: false,
      libraryComponents: [],
      libraryModels: [],
      modelVisible: true
    }, () => {
      file && this.getFileParse(file, model);
    })
  }

  selectFileByTree = (id) => {
    const { model } = this.state;
    const { modelInfo } = this.props;
    let _model = { ...model };
    const libraryList = libraryConstructor.getLibraryValues(IBIS) || [];
    const file = libraryList.find(item => item.id === id);

    if (!file) {
      this.components = [];
      this.fileInfo = {
        id: "",
        name: "",
        fileName: "",
        ibisAmi: {}
      }
      _model = new AMIModelConfig({
        type: modelInfo.interfaceType,
        component: modelInfo.component,
        channelId: modelInfo.channelId,
        libraryId: "",
        libraryName: "",
        libraryFile: "",
        ibisComponent: "",
        modelType: this.modelType,
        modelDir: modelInfo.dirType,
        jitters: _model.jitters,
        EQ: _model.EQ
      });
      this.setState({
        model: _model
      }, () => {
        this.closeModal(false);
      })
      return;
    }

    if (file && file.id === _model.libraryId && file.name === _model.libraryFile) {
      return;
    }

    this.fileInfo = {
      id: file.id,
      name: file.name,
    }
    _model = new AMIModelConfig({
      type: modelInfo.interfaceType,
      component: modelInfo.component,
      channelId: modelInfo.channelId,
      libraryId: file.id,
      libraryName: "",
      libraryFile: file.name,
      ibisComponent: "",
      modelType: this.modelType,
      modelDir: modelInfo.dirType,
      jitters: _model.jitters,
      EQ: _model.EQ
    });
    this.setState({
      model: _model,
      libraryModels: [],
      libraryComponents: []
    }, () => {
      this.getFileParse(file);
    })
  }

  getFileParse = async (file, model) => {
    const { modelInfo: { dirType } } = this.props;
    const libraryModels = await getIbisModelParse(file.id) || {};
    let models = getIBISModelList(libraryModels, model);
    const libraryComponents = libraryModels && libraryModels.packages ? libraryModels.packages.map(item => item.component) : []

    let _model = { ...this.state.model };

    if (!model && models && models[0]) {
      _model.ibisComponent = libraryComponents[0];
      const info = saveIbisModel({
        modelInfo: { ...models[0] },
        model: _model,
        dirType,
        libraryModels: models
      });

      _model = info.model;
      models = info.libraryModels;
    }

    this.setState({
      libraryComponents,
      libraryModels: models,
      model: _model
    }, () => {
      this.closeModal(false)
    })
  }

  selectChange = (key, type) => {
    const { model } = this.state;
    let _model = { ...model };
    _model[type] = key;
    if (type === "dataType" && _model.setAllData) {
      _model = updateParamsValueByCorner({ model: _model, corner: key, fileInfo: this.fileInfo });
    }
    this.setState({
      model: _model
    })
  }

  setAllDataChanged = (e) => {
    const { model } = this.state;
    let _model = { ...model };
    _model.setAllData = e.target.checked;
    this.setState({
      model: _model
    })
  }

  selectModel = (e, modelInfo) => {
    e && e.stopPropagation();
    const { model, libraryModels } = this.state;
    const { modelInfo: { dirType } } = this.props;
    let _model = { ...model }, _libraryModels = [...libraryModels];

    const info = saveIbisModel({
      modelInfo,
      model: _model,
      dirType,
      libraryModels: _libraryModels
    });

    _model = info.model;
    _libraryModels = info.libraryModels;
    this.setState({
      model: _model,
      libraryModels: _libraryModels
    })
  }

  saveModelSelect = (key, type, pinName) => {
    const { libraryModels, model } = this.state;
    let _libraryModels = [...libraryModels], _model = { ...model };
    const index = _libraryModels.findIndex(item => item[type].pin === pinName);
    _libraryModels[index][`${type}Model`] = key;

    if (_model[type] === pinName) {

      if (type === "invPin") {
        _model.invModelName = key;
      } else {
        _model.modelName = key;
      }
    }
    this.setState({
      model: _model,
      libraryModels: _libraryModels
    })
  }

  applyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  saveConfigValue = (value, type) => {
    const { prbs } = this.state;
    let _prbs = prbs ? { ...prbs } : {};
    _prbs[type] = value;
    _prbs = updatePRBSOptions(_prbs, value)

    this.setState({
      prbs: _prbs
    })
  }

  inputChange = (e, type) => {
    const value = e.target.value;
    const { error } = this.state;
    this.setState({
      error: error && error.type === type ? null : error
    })
    this.saveConfigValue(value, type);
  }

  inputBlur = (e, type) => {
    const value = e.target.value;
    const { error: _error } = adsConfigItemErrorCheck({ value, type });
    if (_error) {
      e.target.focus();
      this.setState({
        error: { type, error: _error }
      })
    } else {
      const { error } = this.state;
      this.setState({
        error: error && error.type === type ? null : error
      })
      this.saveConfigValue(value, type);
    }
  }

  showFileContent = (fileVisible) => {
    this.setState({
      fileVisible
    })
  }

  usePkgCheckBox = (usePackage) => {
    const { componentPkgInfo, modelInfo: { component, interfaceType, channelId } } = this.props;
    const key = interfaceType === END_TO_END_CHANNEL ? `${component}::${channelId}` : component;
    const disabled = componentPkgInfo && componentPkgInfo[key];
    return <div
      className={`ads-model-select-item`}
      title={disabled ? `Component "${component}" has already set the package model.` : ""}>
      <span>Use Package</span>
      <Checkbox
        className='ami-model-select-checkbox'
        checked={usePackage}
        disabled={disabled}
        onChange={(e) => this.changeUsePkg(e)} />
    </div>
  }

  changeUsePkg = (e) => {
    const { model } = this.state;
    let _model = { ...model };
    _model.usePackage = e.target.checked;
    this.setState({
      model: _model
    })
  }

  render() {
    const { modelInfo: { dirType } } = this.props;
    const { model, applyAll, prbs, error, fileVisible, libraryComponents } = this.state;
    return <Fragment>
      {this.getSelectComponent({
        title: "IBIS File",
        value: model.libraryFile,
        type: "libraryFile",
        libraryId: model.libraryId,
        saveValue: this.selectFileByTree,
        list: libraryConstructor.getLibraryValues(IBIS) || []
      })}
      {this.getSelectComponent({
        title: "Component",
        type: "ibisComponent",
        value: model.ibisComponent,
        list: libraryComponents || []
      })}
      {this.getSelectComponent({
        title: "Corner",
        type: "dataType",
        value: model.dataType,
        list: SET_ALL_DATA_SELECT_LIST,
      })}
      {this.usePkgCheckBox(model.usePackage)}
      {model.libraryFile ? this.modelSelectRender(model) : null}
      {model.jitters && model.jitters.length ? this.modelJittersRender(model) : null}
      {model.EQ ? this.modelEQSRender(model) : null}
      {dirType === ADS_TX ? this.modelPRBSRender(prbs) : null}
      {error ? <span className={error.errorType === "warning" ? "aurora-model-name-warning-msg" : "aurora-error-msg-span"}>{error.error}</span> : null}
      {<div className="ads-model-apply-all-content">
        <span>Apply setting to all {dirType} model</span>
        <Checkbox
          checked={applyAll}
          onChange={(e) => this.applyChange(e)}
        />
      </div>}
      {fileVisible && model.libraryFile.length ? <FileContentPanel
        title={model.libraryFile}
        getLibraryFileContent={getFileContent}
        closeModal={() => this.showFileContent(false)}
        libraryId={model.libraryId}
        fileName={model.libraryFile}
      /> : null}
    </Fragment>
  }

  changeModelVisible = () => {
    this.setState({
      modelVisible: !this.state.modelVisible
    })
  }

  modelEQSRender = (model) => {
    const { modelInfo: { dirType }, bitRate, prbsType, serdesType } = this.props;

    return <IbisEQ
      model={model}
      dirType={dirType}
      error={this.state.error}
      bitRate={bitRate}
      saveModel={this.saveModel}
      updateError={this.updateError}
      prbsType={prbsType}
      serdesType={serdesType}
    />
  }

  modelSelectRender = (_model) => {
    const { libraryModels, modelVisible } = this.state;
    const items = [{
      key: "models",
      label: <div className="ads-model-jitter-title">
        <span>Models</span>
      </div>,
      children: <div className="ads-select-model-content">
        <div className="ads-select-model-item ads-select-model-header">
          {/*<div><Icon type={modelVisible ? "down" : "right"} onClick={(e) => this.changeModelVisible(e)} /></div> */}
          <div className="ibis-select-model-pins-title">
            <div className="ibis-select-model-pin-title">Pin</div>
            <div className="ibis-select-model-signal-title">Signal Name</div>
          </div>
          <div className="ads-select-model-name-title">Model Name</div>
        </div>
        {modelVisible ? libraryModels.map((model, index) => {
          const checked = this.getSelected(_model, model);
          return <div
            key={index}
            className={`ads-select-model-item ${checked ? "ads-select-model-selected" : ""}`}
            onClick={(e) => this.selectModel(e, model)}
          >
            <Checkbox
              checked={checked}
              className='ads-model-checkbox'
            />
            <div className="ibis-select-model-pins-title">
              <div className="ibis-select-model-pin-item-title">
                <div className="ibis-select-model-pin-title">{model.pin.pin}</div>
                <div className="ibis-select-model-signal-title">{model.pin.signal_name}</div>
              </div>
              <div className="ibis-select-model-pin-item-title">
                <div className="ibis-select-model-pin-title">{model.invPin.pin}</div>
                <div className="ibis-select-model-signal-title">{model.invPin.signal_name}</div>
              </div>
            </div>
            {this.getModelNameRender(model)}
          </div>
        }) : null}
      </div>
    }]
    return <Collapse className="ami-model-collapse-content ads-model-collapse-main" defaultActiveKey={["models"]} items={items} />
  }

  getModelNameRender = (model) => {
    if (model.pinModels.length === 1 && model.invPinModels.length === 1) {
      const pinModel = model.pinModels[0].name, invPinModel = model.invPinModels[0].name;
      return pinModel === invPinModel ? <div className="ads-select-model-item-title">{pinModel}</div>
        : <div className="ibis-select-model-name-title">
          <div className="ibis-select-model-signal-title">{pinModel}</div>
          <div className="ibis-select-model-pin-title">{invPinModel}</div>
        </div>
    } else {
      return <div className="ibis-select-model-name-title">
        <div className="ibis-select-model-name-select" onClick={(e) => { e && e.stopPropagation() }}>
          {this.modelNameSelection({
            modelList: model.pinModels || [],
            type: "pin",
            value: model.pinModel,
            pinName: model.pin.pin
          })}
        </div>
        <div className="ibis-select-model-name-select" onClick={(e) => { e && e.stopPropagation() }}>
          {this.modelNameSelection({
            modelList: model.invPinModels || [],
            type: "invPin",
            value: model.invPinModel || model.pinModel,
            pinName: model.invPin.pin
          })}
        </div>
      </div>;
    }
  }

  modelNameSelection = ({
    modelList,
    type,
    value,
    pinName
  }) => {
    return <Select
      value={value}
      onChange={(key) => this.saveModelSelect(key, type, pinName)}
      popupMatchSelectWidth={false}
      className="aurora-select"
      popupClassName="aurora-select-dropdown"
      getPopupContainer={() => document.getElementById('root')}
    >
      {modelList.map((item) => (
        this.getSelectOption(type, item)))}
    </Select>
  }

  getSelected = (selectModel, model) => {
    if (!selectModel || !selectModel.modelName) {
      return false;
    }
    if (selectModel.pin === model.pin.pin && selectModel.invPin === model.invPin.pin) {
      return true;
    }
  }

  saveModel = (model) => {
    this.setState({
      model
    })
  }

  updateError = (error) => {
    this.setState({
      error
    })
  }

  modelJittersRender = (_model) => {
    const { bitRate, modelInfo } = this.props;
    return <JittersTable
      model={_model}
      type={IBIS}
      modelInfo={modelInfo}
      jitters={_model.jitters}
      bitRate={bitRate}
      jittersColumns={jittersColumns}
      saveModel={this.saveModel}
      updateError={this.updateError}
    />
  }

  modelPRBSRender = (prbs) => {
    return <PRBSSetup
      prbs={prbs}
      getSelectComponent={this.getSelectComponent}
      saveConfigValue={this.saveConfigValue}
      inputBlur={this.inputBlur}
      inputChange={this.inputChange}
      modelList={AMI_PRBS_MODE_LIST}
    />
  }

  getSelectComponent = ({
    title,
    value,
    type,
    list = [],
    className,
    saveValue,
    disabled
  }) => {
    return (
      <div className={`ads-model-select-item ${className}`}>
        <span>{title}</span>
        <Fragment>
          <Select
            value={value}
            onChange={(key) => saveValue ? saveValue(key, type) : this.selectChange(key, type)}
            popupMatchSelectWidth={false}
            className={value && type === "libraryFile" ? "aurora-select ads-model-file-select" : "aurora-select"}
            popupClassName="aurora-select-dropdown"
            getPopupContainer={() => document.getElementById('root')}
            disabled={disabled}
          >
            {list.map((item) => (
              this.getSelectOption(type, item)))}
          </Select>
          {value && type === "libraryFile" ?
            <Tooltip overlayClassName="aurora-tooltip" title="Show File Content">
              <InfoCircleOutlined
                className="ads-model-file-content-icon"
                onClick={() => this.showFileContent(true)} />
            </Tooltip>
            : null}
        </Fragment>
      </div>
    );
  }

  getSelectOption = (type, item) => {
    switch (type) {
      case "mode":
        return <Option
          key={item.key}
          value={item.key}
          title={item.title}
        >{item.title}</Option>
      case "libraryFile":
        return <Option
          key={item.id}
          value={item.id}
          title={item.name}
        >{item.name}</Option>
      case "pin":
      case "invPin":
        return <Option
          key={item.name}
          value={item.name}
          title={item.name}
        >{item.name}</Option>
      default:
        return <Option
          key={item}
          value={item}
          title={item}
        >{item}</Option>
    }

  }
}


export default IBISModelSetup;