import React, { Component } from "react";
import ModelIbis from './ModelIbis';
import ModelSpice from './ModelSpice';
import { SIERRA } from "../../constants/pageType";
import { ModelPin, Model } from '@/services/helper/IntegratedInterface';
import '@/publicCss/style.css';
import './index.css';

const IBIS_AMI = 'IBIS_AMI';
const USE = ['IBIS', 'SPICE', IBIS_AMI];
const MODELS = [ModelIbis, ModelSpice, ModelIbis];
const OUTPUT = ['Output', 'Open_drain', 'Open_sink', 'Open_source'];
const IBIS_AMI_FILE_MODEL = 'IBIS_AMI_FILE_MODEL', AMI_OBJ_PARAM = 'AMI_OBJ_PARAM', IBIS_ODT = 'IBIS_ODT';
const ADV_DEFAULT = 'default';

class SingleModel extends Component {
  constructor(props) {
    super(props);
    const { record, clkMemory, libType, dataIndex } = props;
    let applyAll = true;
    let applySignal = false;
    if (record && record.signal && record.signal.match(/^(DM)/ig)) {
      applyAll = false;
    };
    const { amiParams, advanceParams } = record

    const _amiModel = amiParams && amiParams.amiModel ? amiParams.amiModel : dataIndex === 'receiver' ? AMI_OBJ_PARAM : IBIS_AMI_FILE_MODEL;
    this.state = {
      offset: undefined,
      use: libType, //IBIS SPICE IBIS_AMI
      currentView: "model",
      model: record.model || {},
      deviceVcc: record.deviceVcc || "",
      selectModel: 'selector',
      TemplateVisible: false,
      pu: this.getVoltage('nd_pu', record.pinModels) || "",
      pd: this.getVoltage('nd_pd', record.pinModels) || "0",
      pinModels: record.pinModels,
      applyAll: applyAll,
      applySignal: applySignal,
      applyAllMemory: clkMemory,
      powerOff: (props.record.powerOff === "1" && "1") || "0",
      pinsModelList: null,
      amiModel: record && record.model && record.model.libType === IBIS_AMI ? _amiModel : '',
      amiModelUse: amiParams && amiParams.amiModelUse ? amiParams.amiModelUse : _amiModel === AMI_OBJ_PARAM ? IBIS_ODT : '',
      amiODTValue: amiParams && amiParams.amiODTValue ? amiParams.amiODTValue : _amiModel === AMI_OBJ_PARAM ? '50' : '',
      ifIbisAdvancedOpen: advanceParams && advanceParams.ifIbisAdvancedOpen ? advanceParams.ifIbisAdvancedOpen : '0',
      ibisAdvancedOptions: advanceParams && advanceParams.ibisAdvancedOptions ? advanceParams.ibisAdvancedOptions : {
        delayRise: { value: '0', unit: 'ps', isDefault: true },
        delayFall: { value: '0', unit: 'ps', isDefault: true },
        tailsRise: { value: '0', unit: 'ps', isDefault: true },
        tailsFall: { value: '0', unit: 'ps', isDefault: true }
      },
      advancedValidate: true,
      advancedValidateMessage: "",
      amiParameters: amiParams && amiParams.amiParameters ? amiParams.amiParameters : [],
      amiModelInfo: amiParams && amiParams.amiModelInfo ? amiParams.amiModelInfo : {}
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  getIbisModelList = (type, fileName, fileId) => {
    let obj = {
      libraryId: fileId,
      fileName,
      usage: type === 'tx' ? 'Tx' : 'Rx',
    }
    return this.props.getIbisModelList(obj);
  }

  getSpiceModelList = (type, fileName, fileId) => {
    let obj = {
      libraryId: fileId,
      fileName,
      usage: type === 'tx' ? 'Tx' : 'Rx',
    }
    return this.props.getSpiceModelList(obj);
  }

  getVoltage = (pinName, pinModels) => {
    const find = pinModels ? pinModels.find(item => item.pinName === pinName) : null;
    if (find) {
      return find.voltage
    };
    return null;
  }

  onUseChange = (use) => {
    if (use !== this.state.use) {
      const { pinModels, powerOff } = this.state;
      const { product, record } = this.props;
      const modelType = use === "SPICE" && product === SIERRA && record.usage === 'Receiver' ? "Input" : "";
      this.setState({
        use,
        model: new Model({ libType: use, modelType }),
        pinModels: product === SIERRA ? [] : pinModels
      }, () => {
        const { record, dataIndex, assignModel } = this.props;
        const { signal, component, net, pin, ...restProps } = record;
        const { model, deviceVcc, applyAll, applyAllMemory, pinModels, pu, pd, amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters } = this.state;
        this.savePinSpiceModel(null)
        assignModel({
          record: {
            ...restProps, component, pin, signal, edit: dataIndex, net, libType: this.state.use,
            amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters
          },
          model,
          deviceVcc,
          pinModelsInfo: { pu, pd, pinModels },
          applyAll: product === SIERRA ? false : applyAll,
          applyAllMemory,
          powerOff
        });
      })
    }
  }

  powerOffChange = (off, modelList = []) => {
    const powerOff = (off && "1") || "0";
    const { pinModels, pu, model, deviceVcc } = this.state;
    let _pinModels = [...(pinModels || [])];
    if (!off && model.libType === "IBIS") {
      //if powerOff is turn off ,re set pu and pd is pinModels
      const findPU = _pinModels.findIndex(item => item.pinName === "nd_pu");
      const findPD = _pinModels.findIndex(item => item.pinName === "nd_pd");
      const findModel = modelList.find(item => item.name === model.modelName) || {};
      const modelPU = findModel.deviceVcc && findModel.deviceVcc !== 'NA' ? parseFloat(findModel.deviceVcc).toString() : "";
      if (findPU > -1) {
        _pinModels[findPU].voltage = modelPU || pu || deviceVcc;
      }
      if (findPD > -1) {
        _pinModels[findPD].voltage = 0;
      }

      this.setState({
        powerOff: powerOff,
        pu: findPU > -1 && _pinModels[findPU].voltage ? _pinModels[findPU].voltage : pu,
        pd: 0,
        pinModels: _pinModels
      })
    } else {
      this.setState({
        powerOff: powerOff
      })
    }
  }

  advanceOffChange = (off) => {
    const ifIbisAdvancedOpen = (off && "1") || "0";
    this.setState({
      ifIbisAdvancedOpen: ifIbisAdvancedOpen
    })
    if (!off) {
      this.cleanAdvOptions();
      this.props.setValidate(true)
      this.setState({
        advancedValidate: true,
        advancedValidateMessage: ""
      })
    }
  }

  onAdvOptionValueChange = (e, key) => {
    let _ibisAdvancedOptions = { ...this.state.ibisAdvancedOptions };
    _ibisAdvancedOptions[key].value = e.target.value;
    this.setState({
      ibisAdvancedOptions: _ibisAdvancedOptions
    }, () => {
      this.advancedValueCheck();
    })
  }

  editOptionsUnitChange = (e, key) => {
    let _ibisAdvancedOptions = { ...this.state.ibisAdvancedOptions };
    _ibisAdvancedOptions[key].unit = e;
    this.setState({
      ibisAdvancedOptions: _ibisAdvancedOptions
    })
  }

  onAdvOptionDefaultChange = (e, key) => {
    let _ibisAdvancedOptions = { ...this.state.ibisAdvancedOptions };
    _ibisAdvancedOptions[key].isDefault = e.target.value === ADV_DEFAULT ? true : false;
    this.setState({
      ibisAdvancedOptions: _ibisAdvancedOptions
    }, () => {
      this.advancedValueCheck();
    })
  }

  cleanAdvOptions = () => {
    this.setState({
      ifIbisAdvancedOpen: '0',
      ibisAdvancedOptions: {
        delayRise: { value: '0', unit: 'ps', isDefault: true },
        delayFall: { value: '0', unit: 'ps', isDefault: true },
        tailsRise: { value: '0', unit: 'ps', isDefault: true },
        tailsFall: { value: '0', unit: 'ps', isDefault: true }
      }
    })
  }

  advancedValueCheck = () => {
    let _ibisAdvancedOptions = { ...this.state.ibisAdvancedOptions };
    this.props.setValidate(true);
    this.setState({
      advancedValidate: true,
      advancedValidateMessage: ""
    })
    for (let key in _ibisAdvancedOptions) {
      if (!_ibisAdvancedOptions[key].isDefault) {
        const check = Number.isInteger(Number(_ibisAdvancedOptions[key].value)) && _ibisAdvancedOptions[key].value !== '' ? true : false;
        if (!check) {
          this.props.setValidate(false);
          this.setState({
            advancedValidate: false,
            advancedValidateMessage: "The value must be an integer."
          })
          return;
        }
      }
    }
  }

  onAmiModelSelector = (key) => {
    // const { amiModelUse } = this.state
    this.setState({
      amiModel: key
    })
    if (key === AMI_OBJ_PARAM) {
      this.setState({
        amiModelUse: IBIS_ODT,
        amiODTValue: '50'
      })
    }
  }

  onAmiModelUseSelector = (e) => {
    this.setState({
      amiModelUse: e.target.value
    })
  }

  changeODTValue = (e, validateStatus) => {
    this.props.setValidate(validateStatus)
    this.setState({
      amiODTValue: e.target.value
    })
  }

  changeIgnoreBits = (e, validateStatus) => {
    this.props.setODTValidate(validateStatus)
    this.setState({
      amiIgnoreBits: e.target.value
    })
  }

  changeIgnoreBitsStatus = (e) => {
    this.setState({
      amiIgnoreBitsStatus: e
    })
    if (!e) {
      this.setState({
        amiIgnoreBits: '20'
      })
    }
  }

  //save amiParameters
  saveAmiParameters = (arr) => {
    let _amiParameters = JSON.parse(JSON.stringify(arr));
    this.setState({
      amiParameters: _amiParameters
    })
  }

  // model: { fileName, modelName, modelType, enableVoltage }
  saveChange = (model, deviceVcc) => {
    const { record, getPins, product } = this.props;
    const { use } = this.state;
    const VCC = deviceVcc || this.state.deviceVcc;

    this.setState((prevState) => {
      let pinModels = record.pinModels ? JSON.parse(JSON.stringify(record.pinModels)) : [];
      if (pinModels.length < 1) {
        const modelType = product === SIERRA ? model.modelType || "I/O" : model.modelType;
        pinModels = getPins({ usage: record.usage, type: modelType });
      }
      // Set default pin value
      pinModels.forEach(pinItem => {
        switch (pinItem.pinName) {
          case 'nd_pu':
            pinItem.voltage = VCC;
            break;
          case 'nd_pd':
            pinItem.voltage = '0';
            break;
          default: break;
        }
      });

      if (product === SIERRA) {
        let ndInFind = pinModels.find(item => item.pinName === 'nd_in');
        let ndEnIndex = pinModels.findIndex(item => item.pinName === 'nd_en');
        if (ndEnIndex === -1 && ndInFind && ((ndInFind.type === 'GND' || ndInFind.type === 'VCC') && !OUTPUT.includes(model.modelType) && use !== "SPICE")) {
          const modelPins = new ModelPin({ pinName: 'nd_en' });
          pinModels.push(modelPins);
        }
        if (ndEnIndex > -1 && (OUTPUT.includes(model.modelType) || use === "SPICE")) {
          //OUTPUT has no enable pin
          pinModels.splice(ndEnIndex, 1)
        }
      }
      return {
        model: { ...prevState.model, ...model },
        deviceVcc: VCC,
        pinModels,
        pu: this.getVoltage('nd_pu', pinModels),
        pd: this.getVoltage('nd_pd', pinModels)
      }
    }, () => {
      const { record, saveModelPins } = this.props;
      const { signal, component, net, pin, ...restProps } = record;
      let { pinModels, applyAll, amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters } = this.state;
      let index = pinModels.findIndex(item => item.pinName === "nd_in");
      if (index > -1) {
        //pinModels[index]:{pinName,stimulus:{}, type:"",voltage ,seed}
        //PRBS-SDR/PRBS-DDR type: type-seed
        if (pinModels[index].type.includes("PRBS-SDR") || pinModels[index].type.includes("PRBS-DDR")) {
          if (pinModels[index].seed) {
            pinModels[index].type = `${pinModels[index].type}-${pinModels[index].seed}`;
          }
          delete pinModels[index].seed;
        } else {
          delete pinModels[index].tabs;
          delete pinModels[index].delay;
          delete pinModels[index].slew;
        }
      }
      saveModelPins({
        record: {
          ...restProps, component, pin, net, signal,
          amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters
        },
        pinModels, applyAll: product === SIERRA ? false : applyAll, modifyType: 'model'
      });
    })
  }

  closeModal = () => {
    this.props.closeModal()
  }

  // Close button, save model
  saveModel = async () => {
    // dataIndex: 'model'
    const { record, dataIndex, assignModel, multipleModels, isMultiSetup } = this.props;
    const { signal, component, net, pin, ...restProps } = record;
    const { use, model, deviceVcc, pu, pd, pinModels, applyAll, applySignal, applyAllMemory, powerOff, pinsModelList,
      amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters } = this.state;

    let index = pinModels.findIndex(item => item.pinName === "nd_in");
    if (index > -1) {
      //pinModels[index]:{pinName,stimulus:{}, type:"",voltage ,seed}
      //PRBS-SDR/PRBS-DDR type: type-seed
      if (pinModels[index].type.includes("PRBS-SDR") || pinModels[index].type.includes("PRBS-DDR")) {
        if (pinModels[index].seed) {
          pinModels[index].type = `${pinModels[index].type}-${pinModels[index].seed}`;
        }
        delete pinModels[index].seed;
      } else {
        delete pinModels[index].tabs;
        delete pinModels[index].delay;
        delete pinModels[index].slew;
      }
    }
    if (!multipleModels) {
      assignModel({
        record: {
          ...restProps, component, pin, signal, edit: dataIndex, net, libType: use,
          amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters
        },
        model: model,
        deviceVcc,
        pinModelsInfo: { pu, pd, pinModels },
        applyAll,
        applySignal,
        applyAllMemory,
        pinsModelList,
        powerOff
      });
      !isMultiSetup && this.props.savePowerOff && this.props.savePowerOff({ record, powerOff, applyAll: applyAll, applyAllMemory });
    }
  }

  TemplateCircuitFile = () => {
    this.setState({
      TemplateVisible: true
    });
  }

  closeTemplateModal = () => {
    this.setState({
      TemplateVisible: false
    });
  }

  onApplyChange = (e) => {
    const { applyAllMemory } = this.state;
    let _applyAllMemory = applyAllMemory;
    if (applyAllMemory && !e.target.checked) {
      _applyAllMemory = false;
    }
    if (e.target.checked) {
      _applyAllMemory = true;
    }
    this.setState({
      applyAll: e.target.checked,
      applyAllMemory: _applyAllMemory
    })
  }

  onApplySignalChange = (e) => {
    this.setState({
      applySignal: e.target.checked
    })
  }

  onApplyMemoryChange = (e) => {
    this.setState({
      applyAllMemory: e.target.checked
    })
  }

  changeVoltage = (e, pinName) => {
    const voltage = e.target.value;
    this.setState({
      [pinName]: voltage
    })
  }

  savePinSpiceModel = (pinsModelList) => {
    //rocky multi pin spice model
    this.setState({
      pinsModelList
    })
  }

  render = () => {
    const { record, ibisList, spiceList, clkMemory, componentName, compModel, ...restProps } = this.props;
    const { model, applyAll, applySignal, pu, pd, powerOff, use, applyAllMemory, amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, advancedValidate, advancedValidateMessage, amiParameters } = this.state;
    const type = record.usage === 'Driver' ? 'tx' : 'rx';
    const SelectModel = MODELS[USE.indexOf(use)];
    return <SelectModel
      {...restProps}
      record={record}
      type={type}
      compModel={compModel}
      applyAll={applyAll}
      applySignal={applySignal}
      applyAllMemory={applyAllMemory}
      clkMemory={clkMemory}
      editModel={model}
      ibisList={ibisList}
      spiceList={spiceList}
      pu={pu}
      pd={pd}
      closeModal={this.closeModal}
      saveChange={this.saveChange}
      powerOffChange={this.powerOffChange}
      onApplyChange={this.onApplyChange}
      onApplySignalChange={this.onApplySignalChange}
      onApplyMemoryChange={this.onApplyMemoryChange}
      changeVoltage={this.changeVoltage}
      getIbisModelList={this.getIbisModelList}
      getSpiceModelList={this.getSpiceModelList}
      powerOff={powerOff}
      savePinSpiceModel={this.savePinSpiceModel}
      amiModel={amiModel}
      amiModelUse={amiModelUse}
      amiODTValue={amiODTValue}
      onAmiModelSelector={this.onAmiModelSelector}
      onAmiModelUseSelector={this.onAmiModelUseSelector}
      changeODTValue={this.changeODTValue}
      changeIgnoreBits={this.changeIgnoreBits}
      changeIgnoreBitsStatus={this.changeIgnoreBitsStatus}
      advanceOffChange={this.advanceOffChange}
      ifIbisAdvancedOpen={ifIbisAdvancedOpen}
      ibisAdvancedOptions={ibisAdvancedOptions}
      onAdvOptionValueChange={this.onAdvOptionValueChange}
      editOptionsUnitChange={this.editOptionsUnitChange}
      cleanAdvOptions={this.cleanAdvOptions}
      onAdvOptionDefaultChange={this.onAdvOptionDefaultChange}
      setValidate={this.props.setValidate}
      advancedValidate={advancedValidate}
      advancedValidateMessage={advancedValidateMessage}
      advancedValueCheck={this.advancedValueCheck}
      amiParameters={amiParameters}
      saveAmiParameters={this.saveAmiParameters}
    />
  }
}

export default SingleModel;