import { getCascadeIRData, updateCascadeIRData, deleteCascadeIRData, simulationIR, importIRExplorer, getIRResultList, getIRResultFile, getResultPowerDomainList, saveIRSetting } from '../../api/Cascade/IRExplorer'
import apiProcess from '../../api/utility';
import checkError from '../../api/checkError';

function getIRDataPromise(verificationId) {
  return apiProcess(getCascadeIRData, verificationId)
}

function updateIRDataPromise(data) {
  return apiProcess(updateCascadeIRData, data)
}

function deleteCascadeIRDataPromise({ irId, verificationId }) {
  return apiProcess(deleteCascadeIRData, { ids:irId, verificationId })
}

function simulationIRPromise(verificationId) {
  return apiProcess(simulationIR, verificationId, false, true)
}

function importIRExplorerPromise({ verificationId, file, importType }) {
  return apiProcess(importIRExplorer, { verificationId, file, importType })
}

function getIRResultListPromise(verificationId) {
  return apiProcess(getIRResultList, verificationId)
}
export function getIRResultFilePromise(resultId, resultType, verificationId) {
  return new Promise((resolve, reject) => {
    getIRResultFile(resultId, resultType, verificationId)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        } else {
          resolve(null)
        }
      }).catch(error => {
        console.error(error);
        checkError(error);
        resolve(null);
      })
  });
}

function getResultPowerDomainPromise(verificationId) {
  return apiProcess(getResultPowerDomainList, verificationId)
}

function saveIrSetting({ verificationId, setup }) {
  return apiProcess(saveIRSetting, { verificationId, setup })
}

export {
  getIRDataPromise,
  updateIRDataPromise,
  deleteCascadeIRDataPromise,
  simulationIRPromise,
  importIRExplorerPromise,
  getIRResultListPromise,
  getResultPowerDomainPromise,
  saveIrSetting
}