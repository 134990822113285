const ROCKY = 'rocky';
export const CHANGE_RESULT_KEY = `${ROCKY}/change_result_key`;
export const OPEN_RESULT_PAGE_INFO = `${ROCKY}/open_result_page_info`;
export const CLEAN_RESULT_PAGE_INFO = `${ROCKY}/clean_result_page_info`;
export const UPDATE_RESULT_CONFIG = `${ROCKY}/update_result_config`;
export const DEFAULT_RESULT_CONFIG = `${ROCKY}/default_result_config`;
export const SAVE_RESULT_CONFIG = `${ROCKY}/save_result_config`;
export const RELOAD_CURRENT_RESULT_PAGE = `${ROCKY}/reload_current_result_page`;
export const UPDATE_EYE_DIAGRAM_CONFIG = `${ROCKY}/update_eye_diagram_config`;
export const GET_CUSTOMIZED_EYE_DIAGRAM = `${ROCKY}/get_customized_eye_diagram`;
export const UPDATE_RESULT_SIGNALS = `${ROCKY}/update_result_signals`;
export const UPDATE_CUSTOM_EYE_STATUS = `${ROCKY}/update_custom_eye_status`;
export const CHANGE_TRAIN_SWITCH_VALUE = `${ROCKY}/change_train_switch_value`;
export const OPEN_RESULT_PACKAGE_PAGE_INFO = `${ROCKY}/open_result-package_page_info`;
export const OPEN_RESULT_CARD_PAGE_INFO = `${ROCKY}/open_result_card_page_info`;
export const UPDATE_RESULT_TYPE = `${ROCKY}/update_result_type`;
export const UPDATE_SELECT_PATTERNS = `${ROCKY}/update_select_patterns`;
export const UPDATE_RESULT_EXISTS = `${ROCKY}/update_result_exists`;
