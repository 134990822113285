import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Form } from 'antd';
import './index.css';
const FormItem = Form.Item;

const LoginForm = (props) => {
  const handleSubmit = (values) => {
    props.submit(values.email, values.password);
  }

  return <Form onFinish={handleSubmit} className="login-form">
    <FormItem name='email' rules={[{ required: true, message: 'please enter your email' }]}>
      <Input prefix={<UserOutlined />} placeholder="Email" autoComplete='username' />
    </FormItem>

    <FormItem name='password' rules={[{ required: true, message: 'please enter your password' }]}>
      <Input prefix={<LockOutlined />} placeholder="Password" type="password" autoComplete='current-password' />
    </FormItem>

    <Button type="primary" htmlType="submit" className="login-form-button" id="user-login-button"> Log in </Button>
  </Form>
}

export default LoginForm;