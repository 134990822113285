import React, { Component } from 'react';
import {
  getChannelSetupContent,
  getConnFileList,
  getSignalConnectionsMap,
  Connector,
  SignalConnectionMap
} from '@/services/helper/connectorHelper';
import ModelAssign from './ModelAssign';
import { CONNECTOR } from "@/services/PCBHelper";
import { SPICE } from '@/constants/libraryConstants';
import { SIERRA } from '@/constants/pageType';
import './index.css';

class ConnectionModel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      signal_connections_map: [],
      connector1: { pins: [], models: [] },
      connector2: { pins: [], models: [] },
      channel1Comps: [],
      channel2Comps: [],
      channel1Info: {},
      channel2Info: {},
      cableModels: [],
      portsObj: {},
      channel1Signals: [],
      channel2Signals: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  getConnector = (connection_channel) => {
    if (connection_channel && Object.keys(connection_channel).length) {
      return { ...connection_channel };
    } else {
      return new Connector({});
    }
  }

  saveConnection = () => {
    const { signal_connections_map, connector1, connector2, cableModels } = this.state;
    let connection = this.props.connection;
    connection.signal_connections_map = [...signal_connections_map];
    connection.connector1 = connector1;
    connection.connector2 = connector2;
    connection.cableModels = cableModels ? cableModels : [];
    this.props.saveConnection(connection, this.props.CONNECTION_ID);
  }

  componentDidUpdate = async (prevProps) => {
    const { CONNECTION_INDEX, endToEndChannelId } = this.props;
    if (CONNECTION_INDEX !== prevProps.CONNECTION_INDEX
      || endToEndChannelId !== prevProps.endToEndChannelId) {
      await this.getConnection();
    }
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this, this.props.CONNECTION_INDEX);
    }
    await this.getConnection();
  }

  componentWillUnmount = () => {
    this.setState = () => false;
  }

  getConnection = async () => {
    const { channel1, channel2, connection = {}, product, Interfaces, channelSetupInfo } = this.props;
    const { connector1, connector2, cableModels, signal_connections_map } = connection;
    let _signal_connections_map = signal_connections_map;
    let channel1Info = null, channel2Info = null,
      channel1Comps = null, channel2Comps = null,
      channel1Signals = [], channel2Signals = [];

    const _connector1 = this.getConnector(connector1), _connector2 = this.getConnector(connector2);
    if (product === SIERRA) {
      const _channel1 = Interfaces.find(inter => inter.pcbId === channel1.designId);
      const _channel2 = Interfaces.find(inter => inter.pcbId === channel2.designId);
      channel1Info = _channel1 ? _channel1.content : {};
      channel2Info = _channel2 ? _channel2.content : {};
    } else {
      channel1Info = await getChannelSetupContent(channel1.channelId, channelSetupInfo);
      channel2Info = await getChannelSetupContent(channel2.channelId, channelSetupInfo);
    }

    channel1Comps = channel1Info.components ? channel1Info.components.filter(item => item.type === CONNECTOR) : [];
    channel2Comps = channel2Info.components ? channel2Info.components.filter(item => item.type === CONNECTOR) : [];

    channel1Signals = channel1Info.signals ? channel1Info.signals.map(item => item.name) : [];
    channel2Signals = channel2Info.signals ? channel2Info.signals.map(item => item.name) : [];

    if (!signal_connections_map || !signal_connections_map.length) {
      _signal_connections_map = getSignalConnectionsMap({ channel1Signals, channel2Signals, SignalConnectionMap });
    }
    this.setState({
      channel1Info,
      channel2Info,
      connector1: _connector1,
      connector2: _connector2,
      channel1Comps,
      channel2Comps,
      signal_connections_map: _signal_connections_map || [],
      channel1Signals,
      channel2Signals,
      cableModels: cableModels && cableModels.length ? cableModels : [],
      portsObj: {},
    })
    const files = getConnFileList({
      cableModels,
      connector1: _connector1,
      connector2: _connector2,
    });
    this.getFileParse(files);
    // Waiting for data to load connector-model-box- 
    setTimeout(() => { this.modelAssignRef.resize() }, 50)
  }

  getFileParse = (files = []) => {
    //filter libraryId not exist files
    const _files = files.filter(item => item && item.libraryId);
    const { portsObj } = this.state;
    let _portsObj = { ...portsObj };
    this.getFilesParseList(files).then(res => {
      if (res && Array.isArray(res)) {
        _files.forEach(file => {
          const libraryId = file.libraryId;
          let _ports = [];
          const currentFileInfo = res.find(it => it.libraryId === libraryId);
          _ports = currentFileInfo && Array.isArray(currentFileInfo.ports) ? currentFileInfo.ports : [];
          //update file portsObj
          _portsObj[libraryId] = [..._ports];
        });
        this.setState({
          portsObj: _portsObj
        })
      }
    })
  }

  getFilesParseList = (files) => {
    const promiseList = files.map(item => {
      return item.type === SPICE ? this.props.getSpiceParse({ libraryId: item.libraryId, returnType: "Obj" }) : this.props.getTouchstoneParse(item.libraryId, item.file);
    });
    return Promise.all(promiseList);
  }

  updateConnector = (connector) => {
    this.setState({
      ...this.state,
      ...connector
    }, () => {
    
      this.saveConnection()
    })
  }

  onModelAssignRef = (ref) => {
    this.modelAssignRef = ref;
  }

  render = () => {
    const { signal_connections_map, connector1, connector2,
      channel1Comps, channel2Comps, channel1Info, channel2Info, cableModels = [], portsObj
    } = this.state;
    const { connection, channel1, channel2, connectorFileList,
      cableFileList, pcbConnections, connections, CONNECTION_ID, product,
      judgeFileExsit, connectorCompList, CONNECTION_INDEX, endToEndChannelId,
      getAddIcon
    } = this.props;

    return <div
      className='end-to-end-channel-connection'
      id="end-to-end-channel-connection-id"
    >
      <ModelAssign
        onRef={this.onModelAssignRef}
        connection={connection}
        channel1={channel1}
        channel2={channel2}
        connector1={connector1}
        connector2={connector2}
        channel1Comps={channel1Comps}
        channel2Comps={channel2Comps}
        channel1Info={channel1Info}
        channel2Info={channel2Info}
        connectorFileList={connectorFileList}
        cableFileList={cableFileList}
        signal_connections_map={signal_connections_map}
        updateConnector={this.updateConnector}
        cableModels={cableModels}
        portsObj={portsObj}
        pcbConnections={pcbConnections}
        connections={connections}
        CONNECTION_ID={CONNECTION_ID}
        getFileParse={this.getFileParse}
        judgeFileExsit={judgeFileExsit}
        connectorCompList={connectorCompList}
        product={product}
        CONNECTION_INDEX={CONNECTION_INDEX}
        endToEndChannelId={endToEndChannelId}
        getAddIcon={getAddIcon}
      />
    </div>
  }
}


export default ConnectionModel;