import { getProjectsByTypeNameVersion, createProject, deleteProjectsByIDs, getTrashList, getProjectContentTypeName, projectRename } from '../api/v2/projectCtrl';
import checkError from '../api/checkError';
import { SUCCESS } from '../../constants/returnCode';
import { deleteDesignsPromise } from '../api/Design/design';
import LayoutData from '../data/LayoutData';
import { ANDES_SETUP_VERSION } from '../../version';
import { getVerificationStatus } from '../api/v2/verificationCtrl';
import { getAndesSimulationLog, doSimulation, uploadAndesDebugFile, debugAndesVerify, andesCopyProject, getResultsCurveInAndes } from '../api/Andes';
import apiProcess from '../api/utility';
import { message } from 'antd';

const VERSION = '0.0.1';
/**
 * Get project list by type name
 *
 * @export
 * @param VERSION: 0.0.1 (lowest version)
 * @returns Promise
 */
export function getProjectList() {
  return new Promise((resolve, reject) => {
    getProjectsByTypeNameVersion('Andes', VERSION).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve([]);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve([]);
    });
  });
};

/**
 * Create Andes project
 *
 * @export
 * @param {String} name Project name
 * @param version: ANDES_SETUP_VERSION (Latest version)
 * @returns Promise
 */
export function createProjectPromise(name) {
  return new Promise((resolve, reject) => {
    createProject({ name, typeName: 'Andes', version: ANDES_SETUP_VERSION }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
};

/**
 * Delete project promise
 *
 * @export
 * @param {String} ids Project ID list
 * @returns Promise
 */
export function deleteProjectPromise(ids) {
  return new Promise((resolve, reject) => {
    deleteProjectsByIDs(ids).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
}

export function deleteDesignInProject(id) {
  return new Promise((resolve, reject) => {
    deleteDesignsPromise([id], 'true').then(res => {
      LayoutData.cleanLayoutInfo(id);
      resolve(true);
    }, error => {
      checkError(error);
      resolve(true);
    })
  })
}

export function getAndesTrashList(id) {
  return new Promise((resolve, reject) => {
    getTrashList('Andes').then(res => {
      if (res.data.data) {
        const filterTrash = res.data.data.filter(item => item.typeName === 'Andes');
        resolve(filterTrash);
      } else {
        resolve([]);
      }
    }, error => {
      checkError(error);
      resolve([]);
    })
  })
}

export function doAndesSimulation(verificationIds) {
  return new Promise((resolve, reject) => {
    apiProcess(doSimulation, verificationIds).then(res => {
      resolve(res);
    }, error => {
      reject(error)
    })
  })
};

/**
 * debug verify (andes) 
 * 2020/03/23
 * @export
 * @param {string} step /1(setup)/2(extraction)/3(pproc)
 * @param {string} verificationId verificationId 
 * @returns Promise
 */
export function doDebugVerify({ step, verificationId }) {
  return new Promise((resolve, reject) => {
    apiProcess(debugAndesVerify, { step, verificationId }, false, true).then(res => {
      resolve(res);
    }, error => {
      reject(error);
    });
  });
}

/**
 * Get Andes project by project ID
 * 2019.09.09
 *
 * @export
 * @param {String} projectId Project ID
 * @returns Promise
 */
export function getAndesProjectPromise(projectId) {
  return new Promise((resolve, reject) => {
    apiProcess(getProjectContentTypeName, { projectId, typeName: 'andes' }, false, true).then(res => {
      resolve(res)
    }, error => {
      reject(error);
    });
  });
};

/**
 * Get andes simulation log, response: file
 * 2020.03.23
 *
 * @export
 * @param {string} verificationId
 * @param {*} path path
 * @returns Promise
 */
export function getSimulationLogInAndes(verificationId) {
  return new Promise((resolve, reject) => {
    getAndesSimulationLog(verificationId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(false)
      }
    }, error => {
      resolve(false)
    });
  })
}

/**
 * Upload debug file andes
 * 2020/03/23

 * @export
 * @param {String} verificationId  verificationId 
 * @returns Promise
 */
export function uploadDebug(file, verificationId, step, UploadProgress) {
  return new Promise((resolve, reject) => {
    apiProcess(uploadAndesDebugFile, { file, verificationId, step, UploadProgress }, false, true).then(res => {
      resolve(res)
    }, error => {
      message.error(error);
    });
  });
}

/**
 * andes copy project
 * 2020.03.26
 * @param projectId
 * @export
 * @returns XMLHttpRequest

 */
export function andesProjectCopy(projectId) {
  return new Promise((resolve, reject) => {
    apiProcess(andesCopyProject, projectId, false, true).then(res => {
      resolve(true);
    }, error => {
      reject(error)
    });
  });
};

/**
 * andes  project rename
 * 2020.05.21
 * @param projectId
 * @param projectName
 * @export
 * @returns XMLHttpRequest

 */
export function changeProjectName(projectId, projectName) {
  return new Promise((resolve, reject) => {
    apiProcess(projectRename, { projectId, projectName }, false, true).then(res => {
      resolve(res)
    }, error => {
      reject(error);
    });
  });
};


export function getResultsCurveInAndesPromise(params) {
  return apiProcess(getResultsCurveInAndes, params);
}