import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import { Checkbox, ColorPicker, Radio, Spin } from 'antd';
import {
  LineChartOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import Panel from '@/components/Panel';
import { getImpedanceRdieCdie } from '@/services/Cascade/Impedance';
import CPMParameter from '../../../services/Cascade/helper/CPMParameter/Parameter';
import { toNonExponential } from '../../../services/helper/numberHelper';
import { drawPlot, redrawPlot } from '../../../services/Cascade/helper/CPMParameter/PlotCtrl';
import getIndex from '../../../services/helper/insertionSearch';
import { unitChange } from '../../../services/helper/mathHelper';
import { parseCPMCurrent } from '../../../services/LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import './index.css';

const currentSetting = {
  xUnit: 's',
  xLabel: 'Time',
  yUnit: 'A',
  yLabel: 'Current',
  xScale: 'linear',
  yScale: 'linear'
}

const rdieSetting = {
  xUnit: 'Hz',
  xLabel: 'Frequency',
  yUnit: 'Ω',
  yLabel: 'Resistance',
  xScale: 'log',
  yScale: 'linear'
}

const cdieSetting = {
  xUnit: 'Hz',
  xLabel: 'Frequency',
  yUnit: 'F',
  yLabel: 'Capacitance',
  xScale: 'log',
  yScale: 'linear'
}

const CURRENT = 'current', RDIE = 'rdie', CDIE = 'cdie'
class CpmFileContent extends Component {
  constructor(props) {
    super(props);
    this.root = document.getElementById('root');
    this.width = this.getDefaultWidth();
    this.state = {
      width: this.width - 32,
      height: null,
      fileLoading: false,
      showNetList: true,
      showCharacterize: false,
      leftWidth: "76%",
      rightWidth: "34%",
      showType: CURRENT,
      curveInfo: [],
      currentInfo: [],
      rdieInfo: [],
      cdieInfo: []
    }
    this.svgRef = createRef()
    this.contentRef = createRef()
    this.plot2d = null;
  }

  componentDidMount() {
    const { libraryId } = this.props;
    if (libraryId) {
      this.getFileContent(libraryId);
      this.getRdieCdie(libraryId)
    }
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.root.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (!width) {
      _width = 600
    };
    _width = width * 0.6;
    _width = _width < 600 ? 600 : _width;

    if (!height) {
      _height = 400;
    };
    _height = 478;
    this.setState({
      panelWidth: _width,
      panelHeight: _height,
      height: _height,
    }, () => {
      let contentWidth = this.contentRef.current.offsetWidth;
      this.setState({ contentWidth }, () => {
        let leftWidth = parseFloat(this.state.leftWidth) / 100 * contentWidth;
        this.changeWidth(leftWidth);
      });
    });
  }

  getPorts = (fileContent) => {
    const portsInfo = parseCPMCurrent(fileContent)
    let data = []
    let newData = []
    portsInfo.forEach(info => {
      const ports = info.ports || []
      ports.forEach(item => {
        let time = [], current = [];
        item.points.forEach(point => {
          time.push(Number(toNonExponential(point.time)))
          current.push(Number(toNonExponential(point.current)))
        })
        data.push({
          id: `${info.name} - ${item.name}`,
          name: `${info.name} - ${item.name}`,
          x: time,
          y: current,
          visible: true
        })
      })
    })
    for (let item of data) {
      const { x, y } = item;
      let newTime = [...x], newCurrent = [...y];
      if (newTime.length && newTime[0] === 0) {
        newTime[0] = newTime[1] ? newTime[1] / 100 : 1e-6;
      }
      newTime = newTime.map(i => (unitChange({ num: i, oldUnit: 'ns', newUnit: 's' }).number))
      newData.push({ ...item, x: newTime, y: newCurrent })
    }
    if (!newData.length) {
      newData.push({ x: [], y: [] })
    }
    this.setState({
      currentInfo: newData
    })
  }

  componentDidUpdate(prevProps) {
    const { libraryId, fileName } = this.props;
    if (prevProps.libraryId !== libraryId || fileName !== prevProps.fileName) {
      this.getFileContent(libraryId);
      this.getRdieCdie(libraryId)
    }
  }

  getDefaultWidth = () => {
    const offset = this.root.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    const _width = width * 0.8;
    return _width > 1000 ? 1000 : _width;
  }

  changePanelSize = (obj) => {
    let contentWidth = this.state.contentWidth;
    if (this.contentRef.current) {
      contentWidth = this.contentRef.current.offsetWidth;
    }
    this.setState({
      height: obj.height,
      contentWidth,
    }, () => {
      let leftWidth = this.getLeftWidth();
      this.changeWidth(leftWidth);
    })
  }

  getFileContent = (libraryId) => {
    const { getLibraryFileContent, fileName } = this.props;
    this.setState({
      fileLoading: true,
      fileContent: null
    });
    getLibraryFileContent(libraryId, fileName).then(res => {
      if (res) {
        this.setState({
          fileLoading: false,
          fileContent: typeof (res) !== "string" ? (res.toString ? res.toString() : "") : res
        }, () => {
          this.getPorts(res)
        })
      }
    });
  }

  getRdieCdie = async (libraryId) => {
    try {
      const res = await getImpedanceRdieCdie(libraryId) || []
      let rdieInfo = [], cdieInfo = []
      res.forEach(({ frequency, rdie, cdie }) => {
        if (!rdieInfo.length) {
          rdieInfo = [{
            id: 'Rdie',
            name: 'Rdie',
            x: [],
            y: [],
            visible: true
          }]
        }
        rdieInfo[0].x.push(Number(frequency));
        rdieInfo[0].y.push(Number(rdie));

        if (!cdieInfo.length) {
          cdieInfo = [{
            id: 'Cdie',
            name: 'Cdie',
            x: [],
            y: [],
            visible: true
          }]
        }
        cdieInfo[0].x.push(Number(frequency));
        cdieInfo[0].y.push(Number(cdie));
      })
      if (!rdieInfo.length) {
        rdieInfo.push({ x: [], y: [] })
      }

      if (!cdieInfo.length) {
        cdieInfo.push({ x: [], y: [] })
      }
      this.setState({
        rdieInfo,
        cdieInfo
      })
    } catch (error) {
      console.error(error)
    }
  }

  netListShow = () => {
    this.setState((prevState) => ({
      showNetList: !prevState.showNetList
    }), () => {
      if (this.plot2d) {
        this.draw()
      }
    })
  }

  initPlot = () => {
    const { currentInfo, rdieInfo, cdieInfo, showType } = this.state
    this.param.importData(showType === CURRENT ? currentInfo : showType === RDIE ? rdieInfo : cdieInfo);
    this.draw();
    setTimeout(() => {
      const curves = this.param.getCurves() || []
      const curveInfo = curves.filter(curve => curve.x.length && curve.y.length).map((curve, index) => ({ ...curve, curveIndex: index }));
      this.setState({
        curveInfo,
        selectedKeys: curves.filter(curve => curve.x.length && curve.y.length).map(curve => curve.name)
      })
    }, 10);
  }

  draw = () => {
    this.param.cleanPlotViewer();
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      // changeAxis: this.changeAxis,
    };
    this.plot2d = this.param.createPlot(this.svgRef.current, events);
    drawPlot(this.param, this.plot2d, 'current');
  }

  plotShow = () => {
    const { showCharacterize, showType } = this.state
    this.setState({
      showCharacterize: !showCharacterize
    }, () => {
      if (this.state.showCharacterize) {
        this.changeParameter(showType === CURRENT ? currentSetting : showType === RDIE ? rdieSetting : cdieSetting)
      }
    })
  }

  changeParameter = ({ xUnit, yUnit, xLabel, yLabel, xScale, yScale }) => {
    this.param = new CPMParameter({ id: 'cpm-plot', xUnit, yUnit, xLabel, yLabel, xScale, yScale });
    this.initPlot()
  }

  getLeftWidth = () => {
    if (this.contentRef.current) {
      let contentWidth = this.contentRef.current.offsetWidth;
      let leftWidth = parseFloat(this.state.leftWidth) / 100 * contentWidth;
      return leftWidth;
    }
    return this.state.leftWidth;
  }

  changeWidth = (leftWidth) => {
    const { contentWidth } = this.state;
    if (contentWidth) {
      let rightWidth = contentWidth - leftWidth;
      if (rightWidth < 230) {
        leftWidth = (contentWidth - 230) / contentWidth * 100 + "%";
        rightWidth = 230 / contentWidth * 100 + '%';
      } else if (leftWidth < 150) {
        leftWidth = 150 / contentWidth * 100 + "%";
        rightWidth = (contentWidth - 150) / contentWidth * 100 + '%';
      } else {
        leftWidth = leftWidth / contentWidth * 100 + "%";
        rightWidth = rightWidth / contentWidth * 100 + "%";
      }
      this.setState({
        rightWidth,
        leftWidth,
      }, () => {
        if (this.plot2d) {
          this.draw()
        }
      });
    }
  }

  changeSize = (leftWidth) => {
    const contentWidth = this.contentRef.current.offsetWidth;
    this.setState({ contentWidth }, () => {
      this.changeWidth(leftWidth);
    });
  }

  changeType = (e) => {
    this.setState({
      showType: e.target.value
    }, () => {
      const { showType } = this.state
      this.changeParameter(showType === CURRENT ? currentSetting : showType === RDIE ? rdieSetting : cdieSetting)
    })
  }

  changeSelectedKeys = (keys) => {
    let curves = this.param.getCurves()
    curves.forEach((curve, i) => {
      curve.visible = keys.includes(curve.name)
    });
    this.setState({
      selectedKeys: keys
    }, () => {
      redrawPlot(this.plot2d)
    })
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  colorChange = (e, curveIndex) => {
    const value = e.toHexString();
    if (value) {
      const curves = this.param.getCurves()
      const { curveInfo } = this.state;
      const findIndex = curveInfo.findIndex(item => item.curveIndex === curveIndex)
      if (findIndex > -1) {
        curveInfo[findIndex].color = value;
      }
      curves[curveIndex].color = value;
      this.setState({
        curveInfo
      }, () => {
        redrawPlot(this.plot2d)
      });
    }
  }

  _getIndex = (xPoints, time) => {
    return getIndex(xPoints, time, 0, xPoints.length - 1);
  }

  changeMouse = (time) => {
    const curves = this.param.getCurves()
    const { curveInfo } = this.state;
    let index = null;
    for (let k = 0; k < curves.length; k++) {
      const curveInfoIndex = curveInfo.findIndex(item => item.curveIndex === k);
      if (curveInfoIndex < 0) { continue }
      if (curves[k].visible) {
        if (index === null) {
          index = this._getIndex(curves[0].x, time);
        }
        if (curves[k].y.length === 0) {
          curveInfo[curveInfoIndex].current = '';
        } else {
          if (index > -1) {
            let data = curves[k].y[index]
            if (parseInt(data) < 1) {
              data = parseFloat(data.toPrecision(3));
            }
            if (parseInt(data) < 100 && parseInt(data) >= 1) {
              data = parseFloat(data.toFixed(2));
            }
            if (parseInt(data) < 1000 && parseInt(data) >= 100) {
              data = parseFloat(data.toPrecision(4));
            }
            if (parseInt(data) > 1000) {
              data = parseInt(data);
            };
            curveInfo[curveInfoIndex].current = data;
          } else {
            curveInfo[curveInfoIndex].current = '';
          }
        }
      } else {
        curveInfo[curveInfoIndex].current = '';
      }
    };

    this.setState({
      curveInfo
    });
  }

  cancelMove = () => {
    const curves = this.param.getCurves()
    const { curveInfo } = this.state;
    for (let k = 0; k < curves.length; k++) {
      const curveInfoIndex = curveInfo.findIndex(item => item.curveIndex === k);
      if (curveInfoIndex < 0) { continue }
      if (curves[k].visible) {
        curveInfo[curveInfoIndex].current = '';
      }
    };
    this.setState({
      curveInfo
    })
  }

  render() {
    const { title, closeModal } = this.props;
    const { fileContent, fileLoading, height, width, showNetList, showCharacterize, panelHeight, rightWidth, showType, selectedKeys, curveInfo } = this.state;
    const _height = height ? height - 78 : 400;
    const FolderIcon = showNetList ? MenuFoldOutlined : MenuUnfoldOutlined
    const content = (
      <Panel
        position='panel-center-left'
        title={<div>{title}</div>}
        onCancel={closeModal}
        zIndex={2000}
        width={this.width}
        height={panelHeight ? panelHeight : 478}
        draggable
        resize={this.changePanelSize}
        className='file-content-panel'
        minWidth={200}
        minHeight={200}
      >
        <FolderIcon
          title={showNetList ? 'Hide Netlist' : 'Show Netlist'}
          onClick={this.netListShow}
          className='hide-show-netlist'
        />
        <LineChartOutlined
          title='Show/Hide Plot'
          className='hide-show-plot'
          onClick={this.plotShow}
        />
        <div
          className='file-content-display-body'
          style={!showNetList ? { width: '0%' } : (showCharacterize ? { width: '24%' } : { width: '100%' })}
        >
          <Spin spinning={fileLoading} tip="Loading...">
            <pre style={{ margin: 0, overflow: 'auto', height: _height, display: showNetList ? 'block' : 'none' }} className='file-content'>
              {fileContent}
            </pre>
          </Spin>
        </div>
        <div className={showNetList ? 'cpm-graph-right' : 'cpm-graph-right-100'} style={showCharacterize ? {} : { display: 'none' }} ref={this.contentRef}>
          <div className='cpm-file-svg'
            style={{ right: rightWidth }}
          >
            <svg ref={this.svgRef}></svg>
          </div>
          <div className='cpm-graph-list' style={{ height: _height, width: rightWidth }}>
            <div className='cpm-graph-right-content'>
              <div className='cpm-graph-option-list'
              >
                <Radio.Group
                  className='cpm-radio-group'
                  value={showType}
                  onChange={this.changeType}
                >
                  <Radio value={CURRENT} className='cpm-radio-item cpm-radio-item-current'>Current</Radio>
                  <Radio value={RDIE} className='cpm-radio-item'>Rdie</Radio>
                  <Radio value={CDIE} className='cpm-radio-item'>Cdie</Radio>
                </Radio.Group>
                <div className='cpm-gragh-checkbox'>
                  <Checkbox.Group value={selectedKeys} onChange={this.changeSelectedKeys}  >
                    {curveInfo.map((item, index) => (
                      <Checkbox value={item.name} key={item.name} className='cpm-graph-checkbox-group cpm-graph-option-name'>
                        <span className='cpm-graph-checkbox-group-name'>
                          <ColorPicker value={item.color}
                            className='aurora-color-picker-small'
                            onChange={(value) => this.colorChange(value, item.curveIndex)}
                            size='small'
                            onClick={(e) => this.stopPropagation(e)}
                          />
                          <span className='cpm-option-name' title='item.name'>{item.name}</span>
                          <span style={{ color: item.color }} className='cpm-graph-curve-current'>{item.current}</span>
                        </span>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.root)
  }
}

export default CpmFileContent;