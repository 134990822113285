import { connect } from 'react-redux';
import { closePanel } from '../../store/Sierra/actionCreators';
import ComponentsTable from '../../components/DesignInfo/ComponentTable';

const mapState = (state) => {
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return { defaultLeft, defaultTop }
}


const mapDispatch = (dispatch) => ({
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
})

export default connect(mapState, mapDispatch)(ComponentsTable);