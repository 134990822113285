import {
  START_CHANNEL_EXTRACTION
} from '../actionTypes';

export const startChannelExtraction = (channelIds, config = { runSeasim: false, setupOnly: false, runSimulation: false, hybridInfo: null }) => ({
  type: START_CHANNEL_EXTRACTION,
  channelIds,
  runSeasim: config.runSeasim,
  runSimulation: config.runSimulation,
  setupOnly: config.setupOnly,
  hybridInfo: config.hybridInfo
})