import { downloadPDNResultFile, uploadPDNSnpFile, deleteSnpFile, saveCurrentPdnResult, deleteResultHistory, getPDNResultFile, getFastPIDCResultList, getDCRsultFileData } from '../api/pdn/PDNAPICtrl';
import checkError from '../api/checkError';
import apiProcess from '../api/utility';

/**
 * download result snp file
 *s
 * @param verificationId
 * @param fileName
 * @export
 * @returns Promise
 */
export function downloadResultFile(verificationId, fileName) {
  return new Promise((resolve, reject) => {
    downloadPDNResultFile(verificationId, fileName).then(res => {
      if (res.data) {
        resolve(res.data)
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    })
  });
}


/**
 * upload snp file
 *
 * @param verificationId
 * @param file
 * @export
 * @returns Promise
 */
export function UploadSnpFile(verificationId, file) {
  return new Promise((resolve, reject) => {
    uploadPDNSnpFile(verificationId, file).then(res => {
      if (res.data) {
        resolve(res.data)
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    })
  });
}

/**
 * delete snp file
 *
 * @param verificationId
 * @param fileName
 * @export
 * @returns Promise
 */
export function deleteImportFile(verificationId, fileName) {
  return new Promise((resolve, reject) => {
    apiProcess(deleteSnpFile, { verificationId, fileName }).then(res => {
      resolve(res)
    }, error => {
      resolve(null);
    })
  });
}

/**
 * save pdn current result
 *
 * @param verificationId
 * @param resultName
 * @export
 * @returns Promise
 */
export function savePdnResult(verificationId, resultName) {
  return apiProcess(saveCurrentPdnResult, { verificationId, resultName })
}

/**
 * save pdn current result
 *
 * @param verificationId
 * @param resultName
 * @export
 * @returns Promise
 */
export function delResultHistory(verificationId, dataTime) {
  return apiProcess(deleteResultHistory, { verificationId, dataTime })
}

/**
 * get pdn result VRM.txt, response: file
 *
 * @param projectId
 * @param pdnId
 * @export
 * @returns Promise
 */
export function getVRMModelFile(projectId, pdnId) {
  return new Promise((resolve, reject) => {
    const path = 'VRM.txt';
    if (projectId && pdnId) {
      getPDNResultFile(projectId, pdnId, path).then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        resolve(null);
      })
    } else {
      resolve(null);
    }
  })
}

/**
 * get pdn result VRM_opt.txt, response: file
 *
 * @param projectId
 * @param pdnId
 * @export
 * @returns Promise
 */
export function getVRMOptFile(projectId, pdnId) {
  return new Promise((resolve, reject) => {
    const path = 'VRM_opt.txt';
    if (projectId && pdnId) {
      getPDNResultFile(projectId, pdnId, path).then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        resolve(null);
      })
    } else {
      resolve(null);
    }
  })
}

/**
 * get pdn result DecapRL.txt, response: file
 *
 * @param projectId
 * @param pdnId
 * @export
 * @returns Promise
 */
export function getDecapFile(projectId, pdnId) {
  return new Promise((resolve, reject) => {
    const path = 'DecapRL.txt';
    if (projectId && pdnId) {
      getPDNResultFile(projectId, pdnId, path).then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null)
        }
      }, error => {
        console.error(error);
        resolve(null);
      })
    } else {
      resolve(null);
    }
  })
}

export function getFastPIDCResultListPromise(verificationId) {
  return apiProcess(getFastPIDCResultList, verificationId)
}

export function getDCRsultFileDataPromise(resultId, resultType, verificationId) {
  return new Promise((resolve, reject) => {
    getDCRsultFileData(resultId, resultType, verificationId)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        } else {
          resolve(null)
        }
      }).catch(error => {
        console.error(error);
        checkError(error);
        resolve(null);
      })
  });
}
