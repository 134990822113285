import React, { Component } from 'react';
import { connect } from 'react-redux';
import VirtualComponent from '../../../../components/VirtualComponent';
import {
  saveVirtualComponent
} from '../../store/sierra/action';
import {
  PackageModelInfo,
  PackageModelFileInfo
} from '@/services/Sierra';
import {
  getTouchstoneParse,
  getPkgSpiceModelList,
  getTouchstoneParsePorts
} from '@/services/Sierra/library';
import { SIERRA } from '../../../../constants/pageType';
/* import sierraLibrary from '../../../../services/Sierra/library/libraryStorage';
import { CUSTOM_SPICE, CUSTOM_TOUCHSTONE } from '../../../../constants/libraryConstants'; */

class VirtualComponentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  saveVirtualComponent = (virtualComponents) => {
    const { Interfaces, current } = this.props;
    const currInterface = Interfaces.find(item => item.pcb === current) || {};
    if (!currInterface.pcbId) {
      return;
    }
    this.props._saveVirtualComponent(virtualComponents, currInterface.pcbId)
  }

  getSetupInfoByDesignId = () => {
    const { Interfaces, current } = this.props;
    const currInterface = (Interfaces || []).find(item => item.pcb === current) || { content: {} };
    const otherVirtualComps = (Interfaces || []).filter(item => item.pcb !== current)
      .filter(item => item.content && item.content.virtualComps && item.content.virtualComps.length)
      .map(item => item.content.virtualComps)
      .flat(2).map(item => item.name);

    return {
      designId: currInterface.pcbId,
      virtualComponents: currInterface.content.virtualComps || [],
      signals: JSON.parse(JSON.stringify(currInterface.content.signals || [])),
      components: JSON.parse(JSON.stringify(currInterface.content.components || [])),
      otherVirtualComps
    }
  }

  render = () => {
    const { verificationId } = this.props;
    //todo
    /* const touchstoneList = sierraLibrary.getTree(CUSTOM_TOUCHSTONE).map(item => { return { ...item, fileName: item.name } })
    const spiceList = sierraLibrary.getTree(CUSTOM_SPICE).map(item => { return { ...item, fileName: item.name } }) */
    return <VirtualComponent
      product={SIERRA}
      {...this.getSetupInfoByDesignId()}
      verificationId={verificationId}
      touchstoneList={[]/* touchstoneList */}
      spiceList={[]/* spiceList */}
      ModelInfo={PackageModelInfo}
      ModelFileInfo={PackageModelFileInfo}
      getTouchstoneParse={getTouchstoneParse}
      getSPiceParse={getPkgSpiceModelList}
      getTouchstoneParsePorts={getTouchstoneParsePorts}
      saveVirtualComponent={this.saveVirtualComponent}
    />
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra, project: { verificationId } } } = state;
  const sierraInfo = sierra.sierraInfo;
  let Interfaces = [];
  if (sierraInfo) {
    Interfaces = sierraInfo.Interfaces || [];
  }
  return {
    Interfaces,
    verificationId
  }
}

const mapDispatch = (dispatch) => ({
  _saveVirtualComponent(virtualComponents, pcbId) {
    dispatch(saveVirtualComponent(virtualComponents, pcbId))
  }
})

export default connect(mapState, mapDispatch)(VirtualComponentTable);