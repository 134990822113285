import http from '../http';
import JSZip from 'jszip';

/************************************************/
/*                PDN Controller                */
/************************************************/

/**
 * Create PDN
 * 2019.09.09
 *
 * @export
 * @param {String} designId Design ID
 * @param {JSON} pdnContent PDN content
 * @param {String} pdnName PDN name
 * @param {String} projectId Project ID
 * @returns XMLHttpRequest
 */
export function createPDN({ designId, pdnContent, pdnName, projectId, pdnVersion, libraryId }) {
  return http.post(`/project/PDN`, {
    designId,
    pdnContent,
    pdnId: "",
    pdnName,
    projectId,
    verificationId: "",
    verificationName: pdnName,
    pdnVersion,
    libraryId
  })
};

/**
 * Update PDN
 * 2019.09.09
 *
 * @export
 * @param {String} designId Design ID
 * @param {JSON} pdnContent PDN content
 * @param {String} pdnId PDN ID
 * @param {String} pdnName PDN name
 * @param {String} projectId Project ID
 * @returns XMLHttpRequest
 */
export function updatePDN({ designId, pdnContent, pdnId, pdnName, projectId, verificationId, pdnVersion, libraryId }) {
  return http.put(`/project/PDN`, {
    designId,
    pdnContent,
    pdnId,
    pdnName,
    projectId,
    verificationId,
    verificationName: pdnName,
    pdnVersion,
    libraryId
  })
};

/**
 * Update PDN Library Id
 * 2021.03.12
 *
 * @export
 * @param {String} pdnId PDN ID
 * @param {String} libraryId Library ID
 * @returns XMLHttpRequest
 */
export function updatePDNLibraryId({ pdnId, libraryId }) {
  return http.post(`/project/pdn/${pdnId}/update/libraryId/${libraryId}`);
};

/**
 * Do simulation
 * 2019.09.09
 *
 * @export
 * @param {Array} verificationIds:[]
 * @returns XMLHttpRequest
 */
export function PDNDoSimulation(verificationIds) {
  return http.post(`/project/PDN/verification/simulation`, verificationIds);
};

/**
 * Get PDN content
 * 2019.09.09
 *
 * @export
 * @param {String} pdnId PDN ID
 * @returns XMLHttpRequest
 */
export function getPDNContent(pdnId) {
  return http.get(`/project/PDN/${pdnId}`);
};

/**
 * Delete PDNs by PDN IDs
 * 2019.09.09
 *
 * @export
 * @param {Array} pdnIds
 * @returns XMLHttpRequest
 */
export function deletePDNs(pdnIds) {
  return http.delete(`/project/PDNs`, {
    params: { pdnIds: pdnIds.toString() }
  });
};

/**
 * Get PDN project by project ID
 * 2019.09.09
 *
 * @export
 * @param {String} projectId Project ID
 * @returns XMLHttpRequest
 */
export function getPDNProject(projectId) {
  return http.get(`/project/${projectId}/PDN`);
};

/**
 * Re-extraction model
 * 2019.10.14
 * 
 * @param {String} pdnId PDN ID
 * @export 
 * @returns XMLHttpRequest
 * Deprecated API
 */
export function reExtraction(pdnId) {
  return http.post(`/project/PDN/${pdnId}/reExtraction`);
}

/**
 * Parse power domain
 * 2019.10.14
 * 
 * @export
 * @param {*} name
 * @returns XMLHttpRequest
 */
export function parsePowerDomain({ libraryId, powerDomainName }) {
  return http.get(`/library/${libraryId}/SPIM/${powerDomainName}/parse/file`);
};

/************************************************/
/*              Package Controller              */
/************************************************/

/**
 * Create package-chip
 * 2019.09.09
 *
 * @export
 * @param {JSON} packageParam 
 * {
 *  chip: "string",
 *  id: "",
 *  libraryId: "string",
 *  model: "string",
 *  modelType: "string"
 * }
 * @returns XMLHttpRequest
 */
export function createPacakageChip(packageParam) {
  return http.post(`/project/PDN/package`, packageParam);
};

/**
 * Update package-chip
 * 2019.09.09
 *
 * @export
 * @param {JSON} packageParam 
 * {
 *  chip: "string",
 *  id: "string",
 *  libraryId: "string",
 *  model: "string",
 *  modelType: "string"
 * }
 * @returns XMLHttpRequest
 */
export function updatePackageChip(packageParam) {
  return http.put(`/project/PDN/package`, packageParam);
};

/**
 * Delete Package-Chip
 * 2019.09.09
 *
 * @export
 * @param {Array} packageIds package ids
 * @returns XMLHttpRequest
 */
export function deletePackageChip(packageIds) {
  return http.delete(`/project/PDN/packages`, {
    data: packageIds
  })
};

/**
 * get result files list, response: file
 * 2019.09.24
 *
 * @export
 * @param {string} verificationId verificationId
 * @returns XMLHttpRequest
 */
export function getResultFiles(verificationId) {
  return http.get(`/project/verification/${verificationId}/PDN/file`)
};

/**
 * get result port
 * 2019.09.24
 *
 * @export
 * @param {string} verificationId verificationId
 * @returns XMLHttpRequest
 */
export function getResultInfo({ verificationId, fileName, cols, rows, path }) {
  return http.post(`/project/verification/${verificationId}/PDN/${fileName}/data`, {
    "cols": [
      ...cols
    ],
    "path": path,
    "rows": [
      ...rows
    ]
  })
};
/**
 * get result file, response: file
 * 2019.09.25
 *
 * @export
 * @param {string} projectId projectId
 * @param {string} pdnId pdnId
 * @param {string} path path
 * @returns XMLHttpRequest
 */
export function getPDNResultFile(projectId, pdnId, path) {
  return http.get(`/project/${projectId}/PDN/${pdnId}/singleFile?path=${path}`)
};


/**
 * debug pdn simulation 
 * 2019.09.27
 *
 * @export
 * @param {string} resultType resultType /1(setup)/2(extraction)/3(simulation)
 * @param {string} verificationId verificationId 
 * @returns XMLHttpRequest
 */
export function debugVerify({ resultType, verificationId }) {
  return http.post(`/project/verification/${verificationId}/PDN/verify?resultType=${resultType}`);
};

/**
 * download pdn simulation log, response: file
 * 2019.09.27
 *
 * @export
 * @param {string} verificationId verificationId 
 * @returns XMLHttpRequest
 */
export function getSimulationLog(verificationId) {
  return http.get(`${verificationId}/PDN/log`)
}

/**
 * Download pdn result file (snp), response: file
 * 2019.11.25
 *
 * @export
 * @returns XMLHttpRequest
 */
export function downloadPDNResultFile(verificationId, fileName) {
  return http.post(`/${verificationId}/PDN/snp?fileName=${fileName}`)
}

// todo - Backend need to change the response format
/**
 * Upload pdn snp file
 * 2019.11.25
 *
 * @export
 * @returns XMLHttpRequest
 */
export function uploadPDNSnpFile(verificationId, file) {
  let formData = new FormData();
  let zip = new JSZip();
  zip.file(file.name, file);
  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level: 1 }
  }).then(blob => {
    formData.append('file', new File([blob], file.name));
    return http.post(`/project/verification/${verificationId}/PDN/upload/snp`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  });
}

/**
 * delete snp file
 * 2019/12/10
 *
 * @param verificationId
 * @param fileName
 * @export
 * @returns Promise
 */
export function deleteSnpFile({ verificationId, fileName }) {
  return http.delete(`/project/verification/${verificationId}/PDN/upload/snp?snpFileName=${fileName}`)
}

/**
 * save pdn current result
 *
 * @param verificationId
 * @param resultName
 * @export
 * @returns Promise
 */
export function saveCurrentPdnResult({ verificationId, resultName }) {
  return http.post(`/result/history?name=${resultName}&verificationId=${verificationId}`)
}

/**
 * get pdn result history list
 *
 * @param verificationId
 * @export
 * @returns Promise
 */
export function getPDNResultHistoryList(verificationId) {
  return http.get(`/result/history/list?verificationId=${verificationId}`)
}

/**
 * delete result history
 *
 * @param verificationId
 * @param dataTime history time
 * @export
 * @returns Promise
 */
export function deleteResultHistory({ verificationId, dataTime }) {
  return http.delete(`/result/history/folder?verificationId=${verificationId}&dataTime=${dataTime}`)
}

/**
 * get PDN file
 * 2020/01/06
 *
 * @export
 * @param {string} projectId projectId
 * @param {string} type PDN/PinToPin
 * @param {string} path path
 * @returns XMLHttpRequest
 */
export function getPDNFile(projectId, type, path) {
  return http.get(`/project/${projectId}/${type}/file?path=${path}`)
};

export function getPDNSettingInfo(verificationId) {
  return http.get(`/project/verification/PDN/setting?verificationId=${verificationId}`)
}

export function getFastPIDCResultList(verificationId) {
  return http.post(`dc/result/list?verificationId=${verificationId}`)
}

export function getDCRsultFileData(resultId, resultType, verificationId) {
  return http.post(`dc/result/file?resultId=${resultId}&resultType=${resultType}&verificationId=${verificationId}`)
}
export function parseRNetworkName({ libraryId, powerDomainName }) {
  return http.get(`/library/${libraryId}/SPIM/${powerDomainName}/parse/rnetwork`);
};

/**
 * Save cutdesign
 *
 * @export
 * @param {*} param {content:{unit,x:[],y:[]},verificationId}
 * @return {*} 
 */
export function saveCutDesign(param) {
  return http.post(`/project/PDN/powersi/cutdesign`, param)
}