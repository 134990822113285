import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Line from '../Line';
import TabFooter from '../TabSwitchFooter';
import { getCCCMsg } from '../../pages/CCC/store/action';
import { getReSizeTopBottom, updateContentHeight } from '@/services/helper/documentReSize';
import TopBottom from './topBottom';

class LayoutTB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 400,
      width: 500,
      leftLinePosition: {
        position: "absolute",
        left: 500,
      },
      contentResize: true,
      loading: true
    }
  }

  componentDidMount() {
    this.screenChange();
    const { bottomClassName } = this.props;
    this.parentEleBottom = document.getElementsByClassName(bottomClassName)[0];
    if (this.parentEleBottom) {
      const width = this.parentEleBottom.offsetWidth / 2;
      this.setState({
        width: width,
        tabWidth: width,
        leftLinePosition: {
          position: "absolute",
          left: width,
        },
      })
    }
    this.parentEle = document.getElementsByClassName('aurora-content')[0];
    const splitSize = this.props.splitSize ? this.props.splitSize : 2;
    if (this.parentEle) {
      const height = this.parentEle.offsetHeight / splitSize;
      this.setState({
        height: height
      });
    }

    this.setState({
      loading: false
    })
  }

  componentDidUpdate = (prevProps) => {
    const { tabVisible, tabSelectKeys, siderWidth } = this.props;
    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props._getCCCMsg();
    }

    if (siderWidth !== prevProps.siderWidth) {
      this.resize();
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getSize();
  }

  getSize = () => {
    const { bottomClassName } = this.props;
    let { height, width, tabWidth, leftLinePosition } = getReSizeTopBottom('aurora-content', bottomClassName, this.props.splitSize);
    this.setState({
      height,
      width,
      tabWidth,
      leftLinePosition: { ...leftLinePosition }
    });
    this.props.topBarResize && this.props.topBarResize();
  }

  changeHeight = (height) => {
    height = updateContentHeight(height, 100);
    this.setState({
      height
    })
  }

  changeBottomSplitLineWidth = (width) => {
    const { bottomClassName } = this.props;
    this.parentElBottom = document.getElementsByClassName(bottomClassName)[0];
    let leftWidth = width;
    const maxWidth = this.parentElBottom.offsetWidth - 40;
    if (leftWidth < 40) {
      leftWidth = 40;
    } else if (leftWidth > maxWidth) {
      leftWidth = maxWidth;
    }
    this.setState({
      width: leftWidth,
      tabWidth: this.parentElBottom.offsetWidth - leftWidth,
      leftLinePosition: { position: "absolute", left: leftWidth },
      contentResize: !this.state.contentResize
    });

    this.props.topBarResize && this.props.topBarResize();
  }

  getMonitor = () => {
    const { tabVisible, tabSelectKeys, menuType } = this.props;
    const { width, height, tabWidth } = this.state;
    return <div
      className='aurora-footer'
      style={{ width: tabWidth, position: 'absolute', left: width, borderTop: 'none' }}
    >
      <TabFooter
        height={height}
        drageable={false}
        closeTabFooter={this.props._closeTabFooter}
        leftWidth={width}
        changeTabMenu={this.props.changeMonitorTabMenu}
      >
        {this.props.MonitorChild(menuType, tabVisible, tabSelectKeys, width)}
      </TabFooter>
    </div>
  }

  getBottom = () => {
    const { tabVisible } = this.props;
    const { width, leftLinePosition } = this.state;
    const topBarWidth = tabVisible ? width : '100%';
    return <Fragment>
      {this.props.getBottomSetup(topBarWidth)}
      {tabVisible && <Line
        position={leftLinePosition}
        changeWidth={(width) => this.changeBottomSplitLineWidth(width)}
        width={width}
        resize={(width) => this.changeBottomSplitLineWidth(width)}
      />}
      {tabVisible && this.getMonitor()}
      {this.props.getTopMenuVisible() ? this.props.topBar(topBarWidth) : null}
    </Fragment>
  }

  render() {
    const { height } = this.state;
    return (
      <TopBottom
        {...this.props}
        getBottom={this.getBottom}
        height={height}
        changeHeight={this.changeHeight}
      />
    )
  }
};

const mapDispatch = (dispatch) => ({
  _getCCCMsg() {
    dispatch(getCCCMsg())
  }
})

export default connect(null, mapDispatch)(LayoutTB);