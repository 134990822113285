import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { PACKAGE, PCB_PRE_LAYOUT } from "../../../constants/treeConstants";
import { BGA, DIE, IC, PMIC } from "../../../constants/componentType";
import {
  Canvas,
  decapHeight,
  totalHeight,
  marginWidth,
  componentWidth,
  parasiticsWidth,
  parasiticsSpace,
  decapWholeWidth,
  decapHeightSpace,
  decapWidth,
  caclDecapPosition
} from "../../../services/Cascade/prelayout";
import { createDecapGroup, showDecapGroup, updateBall, updateCanvasStatus, updateVoltage } from "../store/prelayout/action";
import { getTextWidth } from "../../../services/helper/getTextWidth";
import { numberCheck } from "../../../services/helper/dataProcess";
import './index.css';

class Setup extends Component {

  constructor() {
    super();
    this.state = {
      height: totalHeight,
      decapsPosition: [],
      inputVoltage: "",
      ballL: false,
      ballR: false,
      ballC: false
    }
  }

  componentDidMount() {
    this.getCanvansWidth();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate = (prevProps) => {
    const { id, loading, viewList, canvasUpdate } = this.props;
    const { width } = this.state;

    if (canvasUpdate) {
      this.getCanvansWidth()
      this.props.updateCanvasStatus(false)
    }

    if (id !== prevProps.id || ((!prevProps.viewList || !prevProps.viewList.includes(PCB_PRE_LAYOUT)) && viewList.includes(PCB_PRE_LAYOUT))) {
      if (id !== prevProps.id) {
        this.brush = null;
      }
      this.getCanvansWidth();
    }

    if (loading !== prevProps.loading) {
      const ele = document.getElementById(`pre-layout-${id}`);
      const offset = ele ? ele.getBoundingClientRect() : null;
      const offsetWidth = offset && offset.width ? offset.width < 1000 ? 1000 : offset.width : 1000;
      if (width !== offsetWidth) {
        this.getCanvansWidth()
      }
    }
  }

  resize = () => {
    this.getCanvansWidth()
  }

  addNewDecapGroup = (e) => {
    e && e.stopPropagation();
    this.props.createDecapGroup()
  }

  getCanvansWidth = () => {
    const { id, decapGroups } = this.props;
    const ele = document.getElementById(`pre-layout-${id}`);
    const offset = ele ? ele.getBoundingClientRect() : null;
    const offsetWidth = offset && offset.width ? offset.width < 1000 ? 1000 : offset.width : 1000;
    const decapNumber = decapGroups.reduce((prev, group) => {
      return prev + group.decaps.length
    }, 0)
    const _width = marginWidth * 2 + componentWidth * 2 + parasiticsWidth + parasiticsSpace * 2 + decapNumber * decapWholeWidth;
    const decapsPosition = caclDecapPosition(decapGroups);
    this.setState({
      width: _width > offsetWidth ? _width : offsetWidth,
      decapsPosition
    }, () => {
      this.draw()
    })
  }

  draw = () => {
    const { width, height, decapsPosition } = this.state;
    if (!this.brush) {
      const { id } = this.props;
      if (!id) {
        return;
      }
      this.brush = new Canvas({ id: `pre-layout-canvas-${id}`, width, height });
    }
    const { powerNets, referenceNets } = this.props;
    this.brush.reset({ width, height })
    this.brush.set('powerNets', powerNets);
    this.brush.set('referenceNets', referenceNets);
    this.brush.set('decaps', decapsPosition);
    this.brush.draw();
    this.setState({
      width
    })
  }

  inputVoltageStatus = (e, input) => {
    e && e.stopPropagation();
    this.setState({
      inputVoltage: input
    }, () => {
      this.inputRef && this.inputRef.focus()
    })
  }

  inputVoltageBlur = (e) => {
    const voltage = e.target.value;
    const { inputVoltage } = this.state;
    const error = numberCheck(voltage);
    if (!error) {
      this.props.updateVoltage(inputVoltage, voltage)
    }
    this.setState({
      inputVoltage: ""
    })
  }

  setBall = (e, key) => {
    e && e.stopPropagation();
    this.setState({
      [key]: true
    }, () => {
      this.inputRef && this.inputRef.focus()
    })
  }

  inputBall = (e, key, ballKey) => {
    const value = e.target.value;
    const error = numberCheck(value);
    if (!error) {
      this.props.updateBall(key, value)
    }
    this.setState({
      [ballKey]: ""
    })
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  canvasRender = () => {
    const { width, height } = this.state;
    const { id } = this.props;
    return <canvas id={`pre-layout-canvas-${id}`} className="pre-layout-canvas" width={width} height={height} />

  }

  componentsRender = () => {
    const { components } = this.props;
    const { width } = this.state;
    const pmic = components.filter(item => item.type === PMIC || item.type === BGA);
    const ic = components.filter(item => item.type === IC || item.type === DIE);
    return <div style={{ width }} className="pre-layout-relative">
      {this.componentRender(pmic, 'left', '#f47188')}
      {this.componentRender(ic, 'right', '#6495ed')}
      {this.decapGroupsRender()}
      {this.ballRender()}
    </div>
  }

  componentRender = (comps, side) => {
    const { height, inputVoltage } = this.state;
    const { isPackage } = this.props;
    let _height = height / comps.length;
    return comps.map((comp, index) => {
      const { name, voltage } = comp
      return <div
        style={{ top: _height * index, height: _height - 40, lineHeight: `${_height - 40}px` }}
        className={`setup-component setup-component-${side}`}
        key={name}
      >
        {name}
        {!isPackage ? side === 'right' ? ' (IC)' : ' (PMIC)' : null}
        {side === 'left' && !isPackage && <span className='setup-voltage' onClick={(e) => this.inputVoltageStatus(e, name)}>{
          inputVoltage === name ?
            <Input className='aurora-input' defaultValue={voltage} ref={(input) => this.inputRef = input} onBlur={(e) => this.inputVoltageBlur(e)} onPressEnter={this.onPressEnter} />
            :
            <span> {voltage ? voltage : '___'}V</span>
        }</span>}
      </div>
    })
  }

  addGroupRender = () => {
    return (
      <div className="add-decap-group" onClick={this.addNewDecapGroup}>
        <PlusCircleOutlined />
        <span>Add Decap Group</span>
      </div>
    );
  }

  decapGroupsRender = () => {
    const { decapGroups, groupShow } = this.props;
    const { decapsPosition } = this.state;
    return decapGroups.map((group, index) => {
      const { name, decaps } = group;
      const _group = decapsPosition.find(item => item.name === name)
      if (!_group) {
        return null;
      }
      const { top, x, decaps: decapsPos } = _group;
      return <Fragment key={name}>
        {this.decapGroupRender(name, group, x, top, groupShow === name ? true : false, true)}
        <div className="group-decaps">
          {
            decaps.map((item, index) => {
              const findDecap = decapsPos.find(pos => pos.name === item.name && index === pos.index);
              return findDecap ? this.decapRender(name, item, findDecap.x, top + decapHeight + decapHeightSpace, false, false, index) : null
            })
          }
        </div>
      </Fragment>
    })
  }

  decapGroupRender = (groupName, data, left, top, select, isGroup) => {
    const { name, l_group, r_group } = data;
    return <div
      style={{ left, top, width: decapWidth, height: decapHeight }}
      className={`setup-decap-group ${select ? 'setup-decap-group-select' : ''} ${isGroup ? 'setup-decap-groups' : 'setup-decap-item'}`}
      onClick={() => this.props.showDecapGroup(groupName)}
      key={name}
      title={name}
    >
      <div className='decap-name' style={{ width: decapHeight, fontSize: 14 }}>{name}</div>
      <div className='decap-r'>R Group: {r_group || r_group === 0 ? r_group : '___'}mΩ</div>
      <div className='decap-l'>L Group: {l_group || l_group === 0 ? l_group : '___'}pH</div>
    </div>
  }

  decapRender = (groupName, data, left, top, select, isGroup, index) => {
    const { name, l_layout, r_layout, number } = data;
    const textWidth = getTextWidth(name, 12, null, 'bold');
    return <div
      style={{ left, top, width: decapWidth, height: decapHeight }}
      className={`setup-decap-group ${select ? 'setup-decap-group-select' : ''} ${isGroup ? 'setup-decap-groups' : 'setup-decap-item'}`}
      onClick={() => this.props.showDecapGroup(groupName)}
      key={name + index}
      title={name}
    >
      <div className='decap-name' style={{ left: `calc(50% - 0.5 * ${textWidth > 120 ? 120 : textWidth}px)` }}>{name}</div>
      <div className='decap-r'>R: {r_layout || r_layout === 0 ? r_layout : '___'}mΩ</div>
      <div className='decap-l'>L: {l_layout || l_layout === 0 ? l_layout : '___'}pH</div>
      <div className='decap-number'>x{number}</div>
    </div>
  }

  ballRender = () => {
    const { ball } = this.props;
    const { l = "", r = "", c = "" } = ball;
    const { ballL, ballR, ballC } = this.state;
    const defaultLeft = componentWidth + parasiticsSpace;
    const ballWidth = parasiticsWidth / 3;
    return <div className={`setup-ball`}>
      <div className={ballR ? 'decap-r select-decap-r' : 'decap-r'} onClick={(e) => this.setBall(e, 'ballR')} style={{ left: defaultLeft + 10 + ballWidth }}>
        {ballR ? <Input className='aurora-input' defaultValue={r} ref={(input) => this.inputRef = input} onPressEnter={this.onPressEnter} onBlur={(e) => this.inputBall(e, 'r', 'ballR')} addonAfter='mΩ' /> : <span>R: {r || r === 0 ? r : '___'}mΩ</span>}
      </div>
      <div className={ballL ? 'decap-l select-decap-l' : 'decap-l'} onClick={(e) => this.setBall(e, 'ballL')} style={{ left: defaultLeft + 10 }}>
        {ballL ? <Input className='aurora-input' defaultValue={l} ref={(input) => this.inputRef = input} onPressEnter={this.onPressEnter} onBlur={(e) => this.inputBall(e, 'l', 'ballL')} addonAfter='nH' /> : <span>L: {l || l === 0 ? l : '___'}nH</span>}
      </div>
      <div className={ballC ? 'decap-c select-decap-c' : 'decap-c'} onClick={(e) => this.setBall(e, 'ballC')} style={{ left: defaultLeft + 10 + 2.5 * ballWidth }}>
        {ballC ? <Input className='aurora-input' defaultValue={c} ref={(input) => this.inputRef = input} onPressEnter={this.onPressEnter} onBlur={(e) => this.inputBall(e, 'c', 'ballC')} addonAfter='pF' /> : <span>C: {c || c === 0 ? c : '___'}pF</span>}
      </div>
    </div>
  }

  render() {
    const { id } = this.props;
    return <Fragment>
      {/* <span className="font-bold cascade-setup-title-color-only">Setup</span> */}
      <div id={`pre-layout-${id}`} className="cascade-prelayout-setup">
        {this.addGroupRender()}
        {this.componentsRender()}
        {this.canvasRender()}
      </div>
    </Fragment>
  }
}

const mapState = (state) => {
  const { CascadeReducer: { prelayout: { content = {}, id, canvasUpdate, groupShow, type }, project: { viewList } } } = state;
  const { components = [], powerNets = [], referenceNets = [], decapGroups = [], ball = {} } = content
  return {
    components,
    id,
    loading: false,
    viewList,
    powerNets,
    referenceNets,
    decapGroups,
    canvasUpdate,
    groupShow,
    ball,
    isPackage: type === PACKAGE ? true : false,
  }
}

const mapDispatch = (dispatch) => ({
  createDecapGroup() {
    dispatch(createDecapGroup())
  },
  updateCanvasStatus(boolean) {
    dispatch(updateCanvasStatus(boolean))
  },
  showDecapGroup(groupName) {
    dispatch(showDecapGroup(groupName))
  },
  updateVoltage(name, voltage) {
    dispatch(updateVoltage(name, voltage))
  },
  updateBall(key, value) {
    dispatch(updateBall(key, value))
  }
})

export default connect(mapState, mapDispatch)(Setup);