import { defaultTreeItems } from '../../../../services/Cascade';
import { SINGLE_PAGE_LAYOUT, PCB_ONLY } from '@/constants/layoutConstants';
import {
  UPDATE_PROJECT_MENU,
  UPDATA_TREE_LIST,
  CLEAR_CURRENT_PROJECT,
  UPDATE_SELECTED_KEYS,
  UPDATE_EXPAND_KEYS,
  SAVE_OPEN_PROJECT_INFO,
  UPDATE_VIEW_LIST,
  UPDATE_PAGE_LAYOUT,
  UPDATE_DESIGN_STATUS,
  UPDATE_CHANGE_PCB_LOG,
  UPDATE_UPLOAD_TEMPLATE_LOG,
  UPDATE_PCB_LAYOUT,
  UPDATA_DEFAULT_DECAP,
  UPDATE_LIBRARY_MODEL_SAVE,
  CHANGE_PCB_UPDATE_STATUS,
  UPDATE_SIMULATION_LIST,
  UPDATE_STACKUP_LIST,
  SAVE_PREVIEW_STATUS,
  FORCE_PREVIEW_SHOW,
  UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO,
  COMPONENT_SETTING_VISIBLE,
  ROOT_PANEL_VISIBLE,
  UPDATE_SIM_LOADING,
  UPDATE_COPY_LOADING_LIST,
  UPDATE_SERVICE_CONFIG
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  treeItems: JSON.parse(JSON.stringify(defaultTreeItems)),
  expandedKeys: ['projects', 'library'],
  selectedKeys: [],
  openProjectId: null,
  viewList: [],
  layout: SINGLE_PAGE_LAYOUT,
  VRMList: [],
  listStatus: false,
  pcbLayout: PCB_ONLY,
  defaultDecap: '',
  // design
  designStatus: false,
  updatePCBLog: [],
  updateTemplateLog: [],
  applyModelList: [],
  pcbUpdate: false,
  runningList: [],
  stackupList: [],
  previewStatus: false,
  forcePreviewShow: false,
  reportInfo: {},
  settingVisible: false,
  rootVisible: false,
  simLoading: false,
  copyLoadingList: [],
  serviceOptions: {}
};

export const CascadeProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_MENU:
      return {
        ...state
      }
    case UPDATA_TREE_LIST:
      return {
        ...state,
        treeItems: action.treeItems
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        viewList: [],
        openProjectId: null,
        updatePCBLog: [],
        designStatus: false
      }
    case UPDATE_SELECTED_KEYS:
      return {
        ...state,
        selectedKeys: action.selectedKeys
      }
    case UPDATE_EXPAND_KEYS:
      return {
        ...state,
        expandedKeys: action.expandedKeys
      }
    case SAVE_OPEN_PROJECT_INFO:
      return {
        ...state,
        openProjectId: action.id ? action.id : state.openProjectId
      }
    case UPDATE_VIEW_LIST:
      return {
        ...state,
        viewList: action.viewList
      }
    case UPDATE_PAGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_DESIGN_STATUS:
      return {
        ...state,
        designStatus: action.status
      }
    case UPDATE_CHANGE_PCB_LOG:
      return {
        ...state,
        updatePCBLog: action.log
      }
    case UPDATE_UPLOAD_TEMPLATE_LOG:
      return {
        ...state,
        updateTemplateLog: action.log
      }
    case UPDATE_PCB_LAYOUT:
      return {
        ...state,
        pcbLayout: action.pcbLayout
      }
    case UPDATA_DEFAULT_DECAP:
      return {
        ...state,
        defaultDecap: { libraryId: action.libraryId, libraryType: action.libraryType, name: action.name }
      }
    case UPDATE_LIBRARY_MODEL_SAVE:
      return {
        ...state,
        applyModelList: action.applyModelList
      }
    case CHANGE_PCB_UPDATE_STATUS:
      return {
        ...state,
        pcbUpdate: !state.pcbUpdate
      }
    case UPDATE_SIMULATION_LIST:
      return {
        ...state,
        runningList: action.runningList
      }
    case UPDATE_STACKUP_LIST:
      return {
        ...state,
        stackupList: action.stackupList
      }
    case SAVE_PREVIEW_STATUS:
      return {
        ...state,
        previewStatus: action.status
      }
    case FORCE_PREVIEW_SHOW:
      return {
        ...state,
        forcePreviewShow: action.show
      }
    case UPDATE_REPORT_INFO:
      if (action.isUpdate) {
        return {
          ...state,
          reportInfo: {
            ...action.reportInfo
          }
        }
      } else {
        return {
          ...state,
          reportInfo: { ...state.reportInfo, ...action.reportInfo }
        }
      }
    case CLEAR_REPORT_INFO:
      return {
        ...state,
        reportInfo: {}
      }
    case COMPONENT_SETTING_VISIBLE:
      return {
        ...state,
        settingVisible: action.boolean
      }
    case ROOT_PANEL_VISIBLE:
      return {
        ...state,
        rootVisible: action.boolean
      }
    case UPDATE_SIM_LOADING:
      return {
        ...state,
        simLoading: action.loading
      }
    case UPDATE_COPY_LOADING_LIST:
      return {
        ...state,
        copyLoadingList: action.copyLoadingList
      }
    case UPDATE_SERVICE_CONFIG:
      return {
        ...state,
        serviceOptions: action.serviceOptions
      }
    default: return state
  }
} 