class GlobalVariable {
  constructor() {
    this.pageType = null;
  };

  updatePageType(type) {
    this.pageType = type;
  };

}

const globalVariable = new GlobalVariable();
export default globalVariable;