import CeArc from './CeArc';
import CeLarc from './CeLarc';
import CeBox2D from './CeBox2D';
import CeCircle from './CeCircle';
import CeDiamond from './CeDiamond';
import CeDonutRound from './CeDonutRound';
import CeDonutSqrRnd from './CeDonutSqrRnd';
import CeDonutSquare from './CeDonutSquare';
import CeLine from './CeLine';
import CeLineSegs from './CeLineSegs';
import CeOctagon from './CeOctagon';
import CeOvalHalf from './CeOvalHalf';
import CeOval from './CeOval';
import CePolygon from './CePolygon';
import CePolygonWithHole from './CePolygonWithHole';
import CeRectangle from './CeRectangle';
import CeRectCutCorner from './CeRectCutCorner';
import CeSquare from './CeSquare';
import CeSurface from './CeSurface';
import CeVertex from './CeVertex';
import CeVertices from './CeVertices';
import CeVertexArc from './CeVertexArc';
import StringHelper from '../utility/StringHelper';
import StrSplitRule from '../utility/StrSplitRule';

var CeGeomFactory = function () {}

CeGeomFactory.CreateGeometryFromType = function (type) {
  var retGeom = null;

  if (type == null || type == undefined)
    return retGeom;

  switch (type) {
    case "Arc":
      retGeom = new CeArc();
      break;
    case "Box2d":
      retGeom = new CeBox2D();
      break;
    case "Circle":
      retGeom = new CeCircle();
      break;
    case "Diamond":
      retGeom = new CeDiamond();
      break;
    case "DonutRound":
      retGeom = new CeDonutRound();
      break;
    case "DonutSqrRnd":
      retGeom = new CeDonutSqrRnd();
      break;
    case "DonutSquare":
      retGeom = new CeDonutSquare();
      break;
    case "Line":
      retGeom = new CeLine();
      break;
    case "Lines":
      retGeom = new CeLineSegs();
      break;
    case "Octagon":
      retGeom = new CeOctagon();
      break;
    case "HalfOval":
      retGeom = new CeOvalHalf();
      break;
    case "Oval":
      retGeom = new CeOval();
      break;
    case "Polygon":
      retGeom = new CePolygon();
      break;
    case "PolygonHole":
      retGeom = new CePolygonWithHole();
      break;
    case "Rectangle":
      retGeom = new CeRectangle();
      break;
    case "RectCutCorner":
    case "RoundedRectangle":
      retGeom = new CeRectCutCorner();
      break;
    case "Square":
      retGeom = new CeSquare();
      break;
    case "Surface":
      retGeom = new CeSurface();
      break;
    case "Pnt":
      retGeom = new CeVertex();
      break;
    case "Pnts":
      retGeom = new CeVertices();
      break;
    case "Parc":
      retGeom = new CeVertexArc();
      break;
    case "Larc":
      retGeom = new CeLarc();
      break;
    default:
      break;
  }

  return retGeom;
}

CeGeomFactory.CreateGeometryFromData = function (geomData) {
  var type = geomData.pop_front();
  var ceGeom = this.CreateGeometryFromType(type);
  if (ceGeom != null) {
    if (geomData.size() == 1) {
      var splitRule = new StrSplitRule(" ,<>");
      splitRule.AddReservePair("(", ")");
      splitRule.AddReservePair("[", "]");
      splitRule.AddReservePair("{", "}");
      geomData = StringHelper.SplitString(geomData.get(0), splitRule);
    }
    ceGeom.UpdateByString(geomData);
  }
  return ceGeom;
}

export default CeGeomFactory;