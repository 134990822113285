import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { CloseOutlined, EyeOutlined, PlusCircleOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Tooltip, message } from "antd";
import Table from "@/components/EditableTable";
import { addSignal, addPCBInSignals, editSignal, editSignalName, deleteSignal, updateSierraInterface, updateReferenceNets, replaceInterfacePCB, updatePcbReplaceInfo, updatePreInfoReplaceInfo, updatePreLayoutReplace } from '../../store/sierra/action';
import { refreshPCB } from '../../store/project/action';
import { autoFilterSignalNets } from '@/services/Sierra';
import DesignInfo from '@/services/Sierra/pcbInfo';
import { selectChange, changeDisplaySelected, selectLayer, changeShowNameStatus } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import { countTime, closeCountTime } from '@/services/helper/hasOperate';
import projectDesigns from '@/services/helper/projectDesigns';
import canvas from '@/services/LayoutCanvas';
import { NetGroupSelection } from '@/services/PCBHelper';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import PcbReplacePanel from './pcbReplacePanel';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import '../index.css';

const columns = [
  {
    title: "Signal",
    dataIndex: "name",
    width: "25%"
  },
  {
    title: "PCB",
    dataIndex: "pcb",
    width: "30%"
  },
  {
    title: "Net(s)",
    dataIndex: "nets",
    width: "45%"
  }
];

class SignalsNetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      PCBNets: {},
      pcbReplacePCBName: null,
      replacePcbList: []
    }
    this.netGroupSelection = new NetGroupSelection();

    columns[0].onCell = (record, index) => {
      let obj = {}
      if (record.signalLength && record.signalLength > 0) {
        obj.rowSpan = record.signalLength;
        obj.tdClassName = 'signalName'
      } else if (!record.pcb) {
        obj.rowSpan = 1;
      } else {
        obj.rowSpan = 0;
      }

      if (!this.props.pcbLoading) {
        return {
          record,
          ...obj,
          edit: true,
          dataIndex: 'name',
          className: 'pintopin-signal-name-cell',
          handleSave: (row) => this.saveSignal(row, record)
        }
      } else {
        return {
          edit: false,
          ...obj
        }
      }
    };
    columns[0].title = () => {
      const iconDisplay = this.getIconDisplay()
      return (
        <div>Signal
          {iconDisplay && <EyeOutlined
            className="aurora-select-nets-icon"
            title={'Display all signal nets to layout.'}
            onClick={() => this.signalsNetSelect(true)} />}
        </div>
      );
    }

    columns[1].onCell = (record) => {
      const _record = { ...record, pcb: record.pcb ? [record.pcb] : [] }
      return {
        record: _record,
        edit: 'select',
        options: this.props.PCBNames,
        dataIndex: "pcb",
        selectMode: 'multiple',
        handleSave: this.addPCBs,
        getPopupContainer: document.getElementById("sierra-content-main"),
        dropdownMenuClassName: 'sierra-select-dropdown-menu',
        getLabel: (option) => this.getLabel(option, record)
      };
    };

    columns[0].render = (value, row, index) => {
      return <span>
        {value}
        {!this.props.pcbLoading && <CloseOutlined
          className='signal-delete-icon'
          onClick={(e) => this.delSignal(e, row, index)} />}
      </span>
    }

    columns[2].onCell = (record, index) => {
      const options = this.getNetsOptions(record);
      return {
        record,
        edit: 'aurora-select',
        editable: true,
        options: options,
        dataIndex: "nets",
        selectMode: 'multiple',
        handleSave: this.netSelection,
        clearSelected: this.clearSelected,
        onChange: this.changeSelected,
        onFocus: () => this.netSelectFocus(record),
        dropdownMenuClassName: 'sierra-select-dropdown-menu',
        popupMatchSelectWidth: true
      };
    };

    columns[2].render = (value) => {
      return <span>{value.join(', ')}</span>
    }
  }

  uploadReplaceInterfacePcb = (data) => {
    const { newPcbId, oldPcbName, isReplace } = data;
    const { currentProjectId } = this.props;
    const currentProjectPcb = projectDesigns.getAvailableDesigns(currentProjectId);
    const pcbInfo = currentProjectPcb.find(item => item.name === oldPcbName);
    if (pcbInfo && pcbInfo.id && newPcbId) {
      this.props._replaceInterfacePCB(pcbInfo.id, newPcbId, isReplace)
    }
  }

  pcbReplaceClick = (e, option, record) => {
    e && e.stopPropagation && e.stopPropagation();

    // get can replaced pcbId
    const { PCBNames, signals, currentProjectId } = this.props;
    const oldPCBName = record.pcb;
    let signalsMap = {}, filterPCBNames = [oldPCBName]
    signals.forEach(item => {
      signalsMap[item.name] = signalsMap[item.name] || [];
      signalsMap[item.name].push(item.pcb);
    });
    let filterSignalNames = signalsMap ? Object.keys(signalsMap) : []
    filterSignalNames.forEach(item => {
      if (signalsMap[item] && signalsMap[item].includes(oldPCBName) && signalsMap[item].length > 1) {
        filterPCBNames.push(...signalsMap[item].filter(info => info !== oldPCBName))
      }
    })
    const _PCBNames = PCBNames.filter(item => !filterPCBNames.includes(item));
    const pcbInfo = projectDesigns.getAvailableDesigns(currentProjectId).filter(item => _PCBNames.includes(item.name)).map(item => { return { id: item.id, name: item.name } });
    this.props._updatePcbReplaceInfo({ panelVisible: true, pcbReplacedName: option, replacePcbList: pcbInfo })
    if (_PCBNames.length) {
      this.setState({
        pcbReplacePCBName: option,
        replacePcbList: pcbInfo
      })
    }
  }

  getPcbReplace = (pcbName) => {
    this.props._updatePcbReplaceInfo()
    this.setState({
      pcbReplacePCBName: pcbName
    })
  }

  getLabel = (option, record) => {
    const isPreLayout = this.props.preLayoutReady.includes(record.pcbId);
    return <Fragment>
      <span>{option}</span>
      {!isPreLayout && <span onClick={(e) => this.pcbReplaceClick(e, option, record)} className={`iconfont icon-replace1 aurora-option-label-icon`}></span>}
    </Fragment>
  }

  delSignal = (e, row, index) => {
    e && e.stopPropagation && e.stopPropagation();
    this.props.deleteSignal({ name: row.name });
    if (row.nets && row.nets.length > 0) {
      countTime(this.gerRefNets, 10000);
    }
  }

  getIconDisplay = () => {
    const { Interfaces, selectedDesignIDs } = this.props;
    const pcbList = Interfaces.map(item => item.pcbId)
    const filterIds = pcbList.filter(item => selectedDesignIDs.includes(item))
    if (filterIds.length) { return true }
    return false
  }

  selectDisplay = (nets, pcbId) => {
    const { selectedDesignIDs } = this.props;
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ nets: [...nets] }, pcbId);
      this.props.changeDisplaySelected(true, pcbId)
    }
  }

  clearSelected = (value, record, setFieldsValue) => {
    const { nets, pcbId, name } = record;
    const newNets = nets.filter(item => item !== value);
    this.props.editSignal({ nets: [...newNets], pcbId, signalName: name });
    if (setFieldsValue) {
      setFieldsValue({ nets: newNets })
    }
    this.selectDisplay([...newNets], pcbId);
  }

  changeSelected = (list, record, setFieldsValue) => {
    const { nets, pcbId, name } = record;
    let newNets = [...nets];
    if (!list) {
      return;
    }
    if (list.length === 1 && nets.includes(list[0])) {
      newNets = newNets.filter(item => item !== list[0]);
    } else {
      newNets = [...new Set([...newNets, ...list])];
    }
    this.props.editSignal({ nets: [...newNets], pcbId, signalName: name });
    if (setFieldsValue) {
      setFieldsValue({ nets: newNets })
    }
    this.selectDisplay([...newNets], pcbId);
  }

  getSelectData = (record) => {
    const { Interfaces } = this.props;
    const { pcbId, nets } = record;
    const currentInterface = Interfaces.find(item => item.pcbId === pcbId);
    let pinsObj = {}, comps = [], resComp = [];
    const { components = [], port_setups = [] } = currentInterface.content;
    for (let port_setup of port_setups) {
      const { info, positive } = port_setup;
      if (nets.includes(info.net)) {
        if (!pinsObj[positive.component]) {
          // add components
          comps.push(positive.component)
          pinsObj[positive.component] = []
        }
        pinsObj[positive.component].push(positive.pin)
      }
    }


    for (let comp of components) {
      const { type, pins, name } = comp
      if (['Res', 'Jumper'].includes(type)) {
        const filterNets = pins.filter(item => nets.includes(item.net))
        if (filterNets.length) {
          // add connect select signal net res comp
          comps.push(name);
          resComp.push(name)
        }

        const filterPin = pins.filter(item => nets.includes(item.net));
        if (filterPin && filterPin.length && pins.length > 2) {
          pinsObj[name] = filterPin.map(item => item.pin)
        }
      } else if (type === 'Repeater') {
        const filterPin = pins.filter(item => nets.includes(item.net));
        if (filterPin) {
          pinsObj[name] = filterPin.map(item => item.pin)
          comps.push(name)
        }
      }
    }
    comps = [...new Set(comps)];
    const layout = canvas.getLayout(pcbId);
    let layers = layout && layout.findCurrentLayer ? layout.findCurrentLayer(comps) : [];
    layers = [...new Set(layers)];
    // get layers
    return { pinsObj, comps, layers, resComp }
  }

  signalsNetSelect = () => {
    const { Interfaces, selectedDesignIDs } = this.props;
    const interfaces = Interfaces.filter(i => selectedDesignIDs.includes(i.pcbId));
    if (!interfaces.length) return;
    interfaces.forEach(d => {
      const pcbId = d.pcbId;
      let _signalNets = [];
      for (let signal of d.content.signals) {
        _signalNets = [..._signalNets, ...signal.nets]
      }
      const { pinsObj, comps, layers, resComp } = this.getSelectData({ pcbId, nets: _signalNets })
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ pinsObj, comps, nets: [..._signalNets] }, pcbId);
      this.props.changeDisplaySelected(true, pcbId)
      this.props._changeShowNameStatus(true, pcbId)
      setTimeout(() => {
        this.netGroupSelection.selectNetGroup({ pinsObj, comps, nets: [..._signalNets], pcbId, resComp })
      }, 800);
    })
  }

  netSelectFocus = (record) => {
    const { pcbId, nets } = record;
    const { selectedDesignIDs } = this.props;
    if (selectedDesignIDs.includes(pcbId)) {
      if (nets.length) {
        const { pinsObj, comps, layers, resComp } = this.getSelectData(record)
        this.props._selectLayer(layers, pcbId);
        this.props._selectChange({ pinsObj, comps, nets: [...nets] }, pcbId);
        setTimeout(() => {
          this.netGroupSelection.selectNetGroup({ pinsObj, comps, nets: [...nets], pcbId, resComp })
        }, 800);
      } else {
        this.props._selectChange({ nets: [...nets] }, pcbId);
      }
      this.props.changeDisplaySelected(true, pcbId)
      this.props._changeShowNameStatus(true, pcbId)
    }
  }

  netSelection = (record) => {
    const { nets, pcbId } = record;
    const options = this.getNetsOptions(record);
    const newNets = nets.filter(item => options.includes(item));
    this.selectDisplay([...newNets], pcbId);
    countTime(this.gerRefNets, 10000);
  }

  getNetsOptions = (record) => {
    const { PCBNets } = this.state;
    return PCBNets[record.pcbId] || [];
  }

  addPCBs = (record) => {
    const { pcb, name, pcbId, nets } = record;
    this.props.addPCBInSignals(pcb, name, pcbId);
    if (nets && nets.length > 0) {
      countTime(this.gerRefNets, 10000);
    }
  }

  addSignal = (e) => {
    const { PCBNames } = this.props;
    let addPcbName = null;
    if (PCBNames && PCBNames.length === 1) {
      //If there is only one pcb, add it automatically
      addPcbName = PCBNames[0];
    }
    this.props.addSignal(addPcbName);
    //The table has a scroll bar to scroll to the bottom
    setTimeout(() => {
      const table = ReactDOM.findDOMNode(this.table),
        tableBody = table ? table.querySelector('.aurora-table-body') : null;
      if (tableBody && tableBody.scrollHeight) {
        tableBody.scrollTop = tableBody.scrollHeight;
      }
    }, 240);
  }

  saveSignal = (row, prev) => {
    const prevName = prev.name;
    const name = row.name;
    const { signals } = this.props;
    let newSignals = [...signals];
    if (!row.pcb) {
      let reName = newSignals.find(item => item.name !== prevName && item.name === name);
      if (reName) {
        message.error('Signal name cannot be repeated.');
        return;
      }
      newSignals.forEach(item => {
        if (item.name === prevName) {
          item.name = name;
        }
      });

      this.props.updateSierraInterface({ signals: JSON.parse(JSON.stringify(newSignals)) });
      return;
    }
    this.props.editSignalName(name, prevName);
  }

  gerRefNets = () => {
    this.props._updateReferenceNets();
  }

  componentDidMount() {
    this.getPcbNets();
  }

  componentDidUpdate(prevProps) {
    this.getPcbNets(prevProps);
    const { verificationId } = this.props;
    if (verificationId !== prevProps.verificationId) {
      //clear count update reference nets time
      closeCountTime();
    }
  }

  getPcbNets = (prevProps) => {
    const { currentPCBs, pcbComponentsNets, refreshStatus, preLayoutReady } = this.props;
    const { PCBNets } = this.state;
    currentPCBs.forEach(pcbId => {
      if ((!PCBNets[pcbId] || (refreshStatus && prevProps && refreshStatus !== prevProps.refreshStatus))) {
        if (preLayoutReady.includes(pcbId)) {
          const nets = preLayoutData.getNets(pcbId);
          this.setState((prevState) => {
            prevState.PCBNets[pcbId] = nets;
            return {
              PCBNets: prevState.PCBNets
            }
          });
        } else if (pcbComponentsNets.includes(pcbId)) {
          const info = DesignInfo.getPCBInfo(pcbId);
          if (info && info.netsList) {
            const netsList = DesignInfo[pcbId].netsList;
            const filter = autoFilterSignalNets(netsList);
            this.setState((prevState) => {
              prevState.PCBNets[pcbId] = filter;
              return {
                PCBNets: prevState.PCBNets
              }
            });
          }
        }
      }
    });

    if (prevProps && refreshStatus && refreshStatus !== prevProps.refreshStatus) {
      this.props.refreshPCB(false);
    }
  }

  replacePreClick = () => {
    const { preLayoutReplaceInfo } = this.props;
    this.props._updatePreLayoutReplace(preLayoutReplaceInfo.map(item => item.pcbId))
  }

  cancelPreReplace = () => {
    this.props._updatePreInfoReplaceInfo(null)
  }

  getPreReplaceLog = (preLayoutReplaceInfo) => {
    return <div className="sierra-pcb-replace-msg-box">
      {preLayoutReplaceInfo.length && preLayoutReplaceInfo.map((item) => {
        if (!item.updateInfo) {
          return null;
        }
        return <Fragment key={item.pcbId}>
          <div className="pcb-replace-msg">[Warning] The pre-layout {item.pcbName} has been updated: </div>
          {item.updateInfo.delNets && item.updateInfo.delNets.length > 0 && <Fragment>
            <div className="pcb-replace-msg">[Warning] The pre-layout does not have the following nets: </div>
            <div className="pcb-replace-previewInfo">{item.updateInfo.delNets.join(', ')}</div></Fragment>}
          {item.updateInfo.delCompPins && item.updateInfo.delCompPins.length > 0 && <Fragment>
            <div className="pcb-replace-msg">[Warning] The pre-layout does not have the following component-pins: </div>
            <div className="pcb-replace-previewInfo">[{item.updateInfo.delCompPins.map(it => `${it.component}-${it.pin}`).join(', ')}]</div></Fragment>}
          {item.updateInfo.addCompPins && item.updateInfo.addCompPins.length > 0 && <Fragment>
            <div className="pcb-replace-msg">[Warning] The pre-layout adds new component-pins connected to signal nets: </div>
            <div className="pcb-replace-previewInfo">[{item.updateInfo.addCompPins.map(it => `${it.component}-${it.pin}`).join(', ')}]</div></Fragment>}
        </Fragment>
      })}
      <div className="pcb-replace-button-box">
        <Button
          onClick={this.replacePreClick}
          type="primary"
          title={"Update interface by pre-layout setup."}
        >Update</Button>
        <Button
          onClick={this.cancelPreReplace}
        >Cancel</Button>
      </div>
    </div>
  }

  render() {
    const { signals, currentProjectId, pcbReplaceInfo, currInterfaceDesignMessage, preLayoutReplaceInfo } = this.props;
    const { loading, pcbReplacePCBName, replacePcbList } = this.state;
    return (
      <Spin spinning={loading}>
        <span className="font-bold sierra-setup-title-color">Signal Nets</span>
        <PlusCircleOutlined className='signal-add-icon' onClick={this.addSignal} />
        {currInterfaceDesignMessage ? <Tooltip
          title={currInterfaceDesignMessage}
        >
          <InfoCircleOutlined className='signal-interface-design-info-icon' />
        </Tooltip> : null}
        {!currInterfaceDesignMessage && preLayoutReplaceInfo && preLayoutReplaceInfo.length ? <Tooltip
          trigger={['click']}
          title={preLayoutReplaceInfo && preLayoutReplaceInfo[0] && preLayoutReplaceInfo[0].loading ? "Updating pre-layout interface..." : this.getPreReplaceLog(preLayoutReplaceInfo)}
          overlayClassName='sierra-replace-tooltip'
        >
          {preLayoutReplaceInfo && preLayoutReplaceInfo[0] && preLayoutReplaceInfo[0].loading ? <LoadingOutlined className='signal-interface-design-loading-info-icon' /> : <InfoCircleOutlined className='signal-interface-design-info-icon' />}
        </Tooltip> : null}
        <Table
          ref={(ref) => this.table = ref}
          columns={columns}
          className="sierra-signal-nets-table"
          dataSource={signals}
          rowKey={record => record.name + '_' + record.pcb}
          expandIconAsCell={false}
          scroll={
            signals && signals.length && signals.length > 8
              ? { y: 320 }
              : {}
          }
        />
        {pcbReplaceInfo && pcbReplaceInfo.panelVisible ?
          <PcbReplacePanel
            pcbName={pcbReplacePCBName}
            getPcbReplace={this.getPcbReplace}
            replacePcbList={replacePcbList}
            currentProjectId={currentProjectId}
            uploadReplaceInterfacePcb={this.uploadReplaceInterfacePcb}
            updatePcbReplaceInfo={this.props._updatePcbReplaceInfo}
            pcbReplaceInfo={pcbReplaceInfo}
          /> : null}
      </Spin>
    );
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra, project: { pcbComponentsNets, currentProjectId, viewList, refreshStatus, verificationId, selectedKeys, preLayoutReady = [] } } } = state;
  const sierraInfo = sierra.sierraInfo;
  let signals = [], currentPCBs = [], Interfaces = [];
  if (sierraInfo) {
    signals = sierraInfo.info ? sierraInfo.info.signals || [] : [];
    currentPCBs = (sierraInfo.Interfaces || []).map(item => item.pcbId);
    Interfaces = (sierraInfo.Interfaces || []);
  }
  const PCBNames = projectDesigns.getAvailableDesigns(currentProjectId).map(item => item.name);
  return {
    signals,
    PCBNames,
    pcbComponentsNets,
    currentPCBs,
    viewList,
    refreshStatus,
    verificationId,
    pcbLoading: sierra.pcbLoading,
    Interfaces,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    currentProjectId,
    pcbReplaceInfo: sierra.pcbReplaceInfo,
    preLayoutReady,
    currInterfaceDesignMessage: sierra.currInterfaceDesignMessage,
    preLayoutReplaceInfo: sierra.preLayoutReplaceInfo
  }
};

const mapDispatch = (dispatch) => ({
  addSignal(addPcbName) {
    dispatch(addSignal(addPcbName));
  },
  addPCBInSignals(pcbs, name, pcbId) {
    dispatch(addPCBInSignals(pcbs, name, pcbId))
  },
  editSignal({ nets, pcbId, signalName }) {
    dispatch(editSignal({ nets, pcbId, signalName }));
  },
  editSignalName(name, prevName) {
    dispatch(editSignalName(name, prevName));
  },
  deleteSignal({ name }) {
    dispatch(deleteSignal({ name }))
  },
  updateSierraInterface(data) {
    dispatch(updateSierraInterface(data))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  refreshPCB(refreshStatus) {
    dispatch(refreshPCB(refreshStatus))
  },
  _updateReferenceNets() {
    dispatch(updateReferenceNets())
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID))
  },
  _changeShowNameStatus(status, designID) {
    dispatch(changeShowNameStatus(status, designID))
  },
  _replaceInterfacePCB(previousPCBId, newPCBId, isReplace) {
    dispatch(replaceInterfacePCB(previousPCBId, newPCBId, isReplace))
  },
  _updatePcbReplaceInfo(info) {
    dispatch(updatePcbReplaceInfo(info))
  },
  _updatePreInfoReplaceInfo(info) {
    dispatch(updatePreInfoReplaceInfo(info))
  },
  _updatePreLayoutReplace(pcbIds) {
    dispatch(updatePreLayoutReplace(pcbIds))
  }
})

export default connect(mapState, mapDispatch)(SignalsNetsTable);