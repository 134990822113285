import { PRE_LAYOUT } from "../../../constants/designVendor";
import { END_TO_END_CHANNEL, PCB, PCB_CHANNEL, PCB_CHANNEL_RESULT, PCB_PRE_LAYOUT, END_TO_END_CHANNEL_RESULT, PACKAGE, EXPERIMENTS, EXPERIMENTS_RESULT } from "../../../constants/treeConstants";
import channelConstructor from "../channel/channelConstructor";
import endToEndChannelConstructor from "../endToEndChannel/endToEndChannelConstructor";
import projectDesigns from '@/services/helper/projectDesigns';
import designConstructor from '@/services/helper/designConstructor';
import { AMI_SETUP, DEBUG, MODELING, REPORT, SEASIM, SERVICES_OPTIONS, SIMULATE, TOP_BAR_RESULT, RUN, DOWNLOAD, HSPICE_SIMULATION, LAYOUT } from "../../TopBarHelper";
import { CPHY, GENERIC, HDMI, PCIE } from "../../PCBHelper/constants";
import { PACKAGE as PCB_PACKAGE } from '../../../constants/designType';
import { getDebugType } from "..";
import sweepConstructor from "../sweep/sweepConstructor";
import { HSPICE } from "../simulation";

function getPCBPageInfo({ channelId, view, projectId, resultChannelId, sweepId }) {
  let pageType = "", id = "", design = null;
  switch (view) {
    case PCB_CHANNEL:
    case PCB_CHANNEL_RESULT:
      const _channelId = view === PCB_CHANNEL ? channelId : resultChannelId;
      const channel = channelConstructor.getChannel(_channelId) || {};
      const _design = designConstructor.getDesign(channel.designId) || {};
      if (_design) {
        design = _design;
        id = channel.designId;
      }
      break;
    case END_TO_END_CHANNEL:
    case END_TO_END_CHANNEL_RESULT:
      const designs = projectDesigns.getAvailableDesigns(projectId);
      if (designs && designs.length) {
        design = designs[0];
        id = designs[0].id;
      }
      break;
    case EXPERIMENTS:
    case EXPERIMENTS_RESULT:
      const sweep = sweepConstructor.getSweep(sweepId) || {};
      const sweepDesign = designConstructor.getDesign(sweep.designId) || {};
      if (sweepDesign) {
        design = sweepDesign;
        id = sweep.designId;
      }
      break;
    default: break;
  }
  if (!design) {
    return { pageType, id };
  }
  if (design.vendor === PRE_LAYOUT) {
    pageType = PCB_PRE_LAYOUT
  } else if (design.type === PCB_PACKAGE) {
    pageType = PACKAGE;
  } else {
    pageType = PCB;
  }
  return { pageType, id };
}

function getTopBarWidth({ channelId, viewList, endToEndChannelId }) {
  if (!channelId && !endToEndChannelId) {
    return 400;
  }

  if (viewList.includes(PCB_CHANNEL) && channelId) {
    return 950;
  }

  if (viewList.includes(END_TO_END_CHANNEL) && endToEndChannelId) {
    return 900;
  }
}

function getCCCVerifications(projectId) {
  let channelList = [];
  const designs = designConstructor.getDesignValues(projectId);
  for (let design of designs) {
    const channels = channelConstructor.getChannelValues(design.id);
    channelList.push({
      ...design,
      verificationList: [...channels]
    })
  }

  const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
  return [{
    type: PCB_CHANNEL,
    name: "PCB Channel",
    subType: "Design",
    verificationType: "PCB Channel",
    subList: [...channelList]
  },
  {
    type: END_TO_END_CHANNEL,
    name: "Multi-PCB Channel",
    verificationType: "Multi-PCB Channel",
    verificationList: [...endToEndList]
  }]
}

function getCCCSpecifyServiceVerification(verificationId, viewList) {
  if (!verificationId) {
    return null;
  }
  const filterViews = viewList.filter(item => [PCB_CHANNEL, END_TO_END_CHANNEL].includes(item));
  const view = filterViews.length ? filterViews[0] : null;

  let verification = null, specifyServiceType = null;
  switch (view) {
    case PCB_CHANNEL:
      verification = channelConstructor.getChannelByVerificationId(verificationId);
      specifyServiceType = PCB_CHANNEL;
      break;
    case END_TO_END_CHANNEL:
      verification = endToEndChannelConstructor.getEndToEndChannelByVerificationId(verificationId);
      specifyServiceType = END_TO_END_CHANNEL;
      break;
    default:
      verification = channelConstructor.getChannelByVerificationId(verificationId);
      specifyServiceType = PCB_CHANNEL;
      if (!verification) {
        verification = endToEndChannelConstructor.getEndToEndChannelByVerificationId(verificationId);
        specifyServiceType = END_TO_END_CHANNEL;
      }
      break;
  }

  return verification ? { ...verification, specifyServiceType } : null;
}

function isEndToEndChildrenChannel(data) {
  if (data && data.endToEndId && data.endToEndId !== "0") {
    return true;
  }
}

function isPreLayout(designId) {
  const design = designConstructor.getDesign(designId);
  if (design && design.vendor === PRE_LAYOUT) {
    return true;
  } else {
    return false;
  }
}

function isPackagePCB(designId) {
  const design = designConstructor.getDesign(designId);
  if (design && design.type === PCB_PACKAGE) {
    return true;
  } else {
    return false;
  }
}

function getTopMenuBackground(key, visible) {
  return visible[key] ? { background: '#c7e0f4' } : {};
}

function getTopMenuDisplay({
  key,
  channelId,
  viewList,
  endToEndChannelId,
  simulationSolver
}) {
  const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(item));

  switch (key) {
    case MODELING:
      return (!channelId || getDesignVendor(channelId) === PRE_LAYOUT) ? false : true;
    case SEASIM:
      if (view === PCB_CHANNEL) {
        const channel = channelConstructor.getChannel(channelId) || {};
        return (channel.type === PCIE && channelId)
          ? true : false;
      } else if (view === END_TO_END_CHANNEL) {
        const endToEnd = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {};

        return endToEndChannelId && endToEnd.type === PCIE ? true : false;
      }
      return false;
    case SIMULATE:
      return ((view === END_TO_END_CHANNEL && endToEndChannelId)
        || (view === PCB_CHANNEL && channelId && getDesignVendor(channelId) === PRE_LAYOUT))
        ? true : false;
    case TOP_BAR_RESULT:
      if ((view === PCB_CHANNEL && channelId) || (view === END_TO_END_CHANNEL && endToEndChannelId) || view === EXPERIMENTS) {
        return true;
      }
      return false;
    case AMI_SETUP:
      if (view === PCB_CHANNEL) {
        const channel = channelConstructor.getChannel(channelId) || {};
        return ([PCIE, HDMI, GENERIC, CPHY].includes(channel.type) && channelId)
          ? true : false;
      } else if (view === END_TO_END_CHANNEL) {
        const endToEnd = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {};
        return endToEndChannelId && [PCIE, HDMI, GENERIC, CPHY].includes(endToEnd.type) ? true : false;
      }
      return false;
    case REPORT:
    case SERVICES_OPTIONS:
      return view === EXPERIMENTS ? false : true;
    case DOWNLOAD:
    case RUN:
      return view === EXPERIMENTS ? true : false;
    case HSPICE_SIMULATION:
      return simulationSolver === HSPICE ? true : false;
    case LAYOUT:
      return (!channelId || getDesignVendor(channelId) === PRE_LAYOUT) ? false : true
    default: break;
  }
  return true;
}

function getDesignVendor(channelId) {
  const channel = channelConstructor.getChannel(channelId);
  if (channel && channel.designId) {
    const design = designConstructor.getDesign(channel.designId);
    return design ? design.vendor : null;
  }
}

function resultDisabled({
  view,
  channelId,
  channelResultExist,
  endToEndChannelId,
  endToEndResultExist,
  sweepId
}) {
  if (view === PCB_CHANNEL && channelId && channelResultExist && channelId === channelResultExist.id && channelResultExist.exist) {
    return { pageType: PCB_CHANNEL_RESULT, id: channelId };
  }

  if (view === END_TO_END_CHANNEL && endToEndChannelId && endToEndResultExist && endToEndResultExist.id === endToEndChannelId && endToEndResultExist.exist) {
    return { pageType: END_TO_END_CHANNEL_RESULT, id: endToEndChannelId };
  }
  if (view === EXPERIMENTS && sweepId) {
    return { pageType: EXPERIMENTS_RESULT, id: sweepId };
  }
  return false;
}

function getTopMenuDisabled({
  key,
  channelId,
  viewList,
  endToEndChannelId,
  projectId,
  channelLoading,
  channelEndToEndId,
  channelResultExist,
  endToEndResultExist,
  experimentIds,
  sweepId
}) {
  const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(item));
  const debugType = getDebugType({ viewList, channelId, endToEndChannelId, channelEndToEndId });
  switch (key) {
    case SIMULATE:
      if (view === PCB_CHANNEL) {
        return (channelId && !channelLoading)
          ? "" : "andes-v2-top-bar-item-disabled";
      } else if (view === END_TO_END_CHANNEL) {
        return endToEndChannelId ? "" : "andes-v2-top-bar-item-disabled";
      }
      return "andes-v2-top-bar-item-disabled";
    case MODELING:
      return channelLoading || !channelId ? "andes-v2-top-bar-item-disabled" : "";
    case SEASIM:
      if (view === PCB_CHANNEL) {
        const channel = channelConstructor.getChannel(channelId) || {};
        return (channelLoading || channel.type !== PCIE || !channelId)
          ? "andes-v2-top-bar-item-disabled" : "";
      } else if (view === END_TO_END_CHANNEL) {
        const endToEnd = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {};

        return !endToEndChannelId || endToEnd.type !== PCIE ? "andes-v2-top-bar-item-disabled" : "";
      }
      return "andes-v2-top-bar-item-disabled";
    case DEBUG:
      return !debugType && !projectId ? "andes-v2-top-bar-item-disabled" : "";
    case TOP_BAR_RESULT:
      if (resultDisabled({
        view,
        channelId,
        channelResultExist,
        endToEndChannelId,
        endToEndResultExist,
        sweepId
      })) {
        return "";
      } else {
        return "andes-v2-top-bar-item-disabled";
      }
    case AMI_SETUP:
      if (view === PCB_CHANNEL) {
        const channel = channelConstructor.getChannel(channelId) || {};
        return (channelLoading || ![PCIE, HDMI, GENERIC, CPHY].includes(channel.type) || !channelId)
          ? "andes-v2-top-bar-item-disabled" : "";
      } else if (view === END_TO_END_CHANNEL) {
        const endToEnd = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {};

        return !endToEndChannelId || ![PCIE, HDMI, GENERIC, CPHY].includes(endToEnd.type) ? "andes-v2-top-bar-item-disabled" : "";
      }
      return "andes-v2-top-bar-item-disabled";
    case REPORT:
      const designs = designConstructor.getDesignValues(projectId).map(item => { return { ...item, children: channelConstructor.getChannelValues(item.id) } });

      if (!designs.length || !designs.find(item => item.children && item.children.length)) {
        return "andes-v2-top-bar-item-disabled";
      }
      return;
    case RUN:
      if (view === EXPERIMENTS) {
        return experimentIds && experimentIds.length > 0 ? "" : "andes-v2-top-bar-item-disabled";
      }
      break;
    default: break;
  }
  return "";
}

function getServiceTitleList({ projectName, pcbName, verificationName }) {
  return [{ type: "Project", name: projectName },
  { type: "Design", name: pcbName },
  { type: "Channel", name: verificationName }
  ]
}

function getExtractionTimeout(timeout) {
  const hours = Math.floor(timeout / 60);
  const minutes = timeout % 60;
  return { hours, minutes }
}

export {
  getPCBPageInfo,
  getTopBarWidth,
  getCCCVerifications,
  getCCCSpecifyServiceVerification,
  isEndToEndChildrenChannel,
  isPreLayout,
  getTopMenuBackground,
  getTopMenuDisplay,
  getTopMenuDisabled,
  resultDisabled,
  getDesignVendor,
  getServiceTitleList,
  getExtractionTimeout,
  isPackagePCB
}