import { getChannelPDNContentByID, updateRockyPDNContent, rockyPDNReExtractionModel } from '../api/Rocky/rockyPDNCtrl';
import apiProcess from '../api/utility';

/**
 * Get rocky channel PDN content by PDNID
 *
 * @export
 * @param {string} PDNID
 * @returns
 */
export function getChannelPDNContentPromise(PDNID) {
  return new Promise((resolve, reject) => {
    if (PDNID) {
      apiProcess(getChannelPDNContentByID, PDNID).then(res => {
        resolve(res);
      }, error => {
        resolve(null);
        console.error(error);
      })
    } else {
      resolve(null);
    }
  })
}

/**
 * Get rocky channel PDN content by PDNID
 *
 * @export
 * @param {string} PDNID
 * @returns
 */
export function updateRockyPDNContentPromise({ PDNID, pdnContent, readyForSim, version }) {
  return new Promise((resolve, reject) => {
    apiProcess(updateRockyPDNContent, { PDNID, pdnContent, readyForSim, version }).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
      console.error(error);
    })
  })
}

/**
 * update rocky pdn ifDoExtraction
 *
 * @export
 * @param {string} rockyPDNId
 * @returns
 */
export function rockyPDNReExtraction(rockyPDNId) {
  return new Promise((resolve, reject) => {
    apiProcess(rockyPDNReExtractionModel, rockyPDNId).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
      console.error(error);
    })
  });
}