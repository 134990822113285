import VerifySetupDatabase from '../Sierra/verifySetupDatabase';
import { AndesInterface, Extraction } from './AndesInterface';
class AndesVerify extends VerifySetupDatabase {
  constructor(props) {
    super(props);
  }

  mergeInterfacesInfo(interfacesInfo, verificationName) {
    let newContent = new AndesInterface(verificationName);
    interfacesInfo.forEach(info => {
      let { content, pcb, pcbId } = info;
      PRAMS.forEach(pram => newContent[pram].push(...addPCB(content[pram], pcb, pcbId)));
      if (content.channel && Object.keys(content.channel).length > 0) {
        newContent.channel[pcb] = content.channel;
      } else {
        newContent.channel[pcb] = 'SIwave';
      }
      newContent.info = content.info || {
        type: 'General',
        datarate: '2.5'
      };;

      newContent.extraction = content.extraction || new Extraction();
      newContent.refNets = content.refNets ? { ...content.refNets, pcb, pcbId } : { power: [], ground: [], pcb, pcbId };
    });
    if (newContent.signals && Array.isArray(newContent.signals)) {
      let _signals = [], _signalNames = [], signals = [];
      newContent.signals.forEach(signal => {
        const index = _signalNames.indexOf(signal.name);
        if (index > -1) {
          _signals[index].push(signal);
        } else {
          _signalNames.push(signal.name);
          _signals.push([signal]);
        }
      });
      _signals.forEach(item => {
        item[0].signalLength = item.length;
        signals.push(...item);
      });
      newContent.signals = signals;
    };
    return newContent;
  }

}

const PRAMS = ['signals', 'components'];
function addPCB(prev, pcb, pcbId) {
  return prev && prev.length > 0 ? prev.map(item => ({ ...item, pcb, pcbId })) : [];
}


const AndesVerifySetup = new AndesVerify();
export default AndesVerifySetup;