import {
  GET_CHANNEL_PDN_CONTENT,
  UPDATE_ROCKY_PDN_CONTENT,
  UPDATE_ROCKY_PDN_INFO,
  SAVE_PDN_VRM,
  UPDATE_COMPONENTS,
  COMP_REMOVED_CHANGE,
  SAVE_SPLIT_COMPS,
  SAVE_MERGE_COMPS,
  SAVE_PDN_CONFIG,
  ROCKY_PDN_RE_EXTRACTION,
  UPDATE_ROCKY_PDN_IFDOEXTRACTION,
  SAVE_ROCKY_PDN_PKG_DIE_MODEL,
  UPDATE_ROCKY_PDN_ERROR_LIST,
  UPDATE_COMPONENT_RLC_PREFIX,
  UPDATE_LOADING_PCB,
  UPDATE_COMP_PREFIX_PROMPT,
  ROCKY_PDN_SELECT_NETS
} from './actionTypes';

/* channel pdn */
export const getChannelPDNContent = (verificationId, PDNID) => ({
  type: GET_CHANNEL_PDN_CONTENT,
  verificationId,
  PDNID
})

export const updateRockyPDNContent = (info) => ({
  type: UPDATE_ROCKY_PDN_CONTENT,
  info
});

export const updateRockyPDNInfo = (data) => ({
  type: UPDATE_ROCKY_PDN_INFO,
  data
})

/* VRM */
export const savePdnVRM = (vrmType, data, index) => ({
  type: SAVE_PDN_VRM,
  vrmType,
  data,
  index
});

export const reLaunchPDNExtraction = () => ({
  type: ROCKY_PDN_RE_EXTRACTION
})

/* components */

export const updateComponents = ({ part, comps, checked, usage }) => ({
  type: UPDATE_COMPONENTS,
  part,
  comps,
  usage,
  checked
});

export const compRemovedChange = ({ part, removes, usage }) => ({
  type: COMP_REMOVED_CHANGE,
  part,
  removes,
  usage,
});

export const saveSplitComps = ({ part, splitPart, comps }) => ({
  type: SAVE_SPLIT_COMPS,
  part,
  splitPart,
  comps
});

export const saveMergeComps = ({ part, partList }) => ({
  type: SAVE_MERGE_COMPS,
  part,
  partList
});

export const savePDNConfig = (config) => ({
  type: SAVE_PDN_CONFIG,
  config
})

export const updateRockyPDNIfDoExtraction = (data) => ({
  type: UPDATE_ROCKY_PDN_IFDOEXTRACTION,
  data
});

export const savePDNPkgDieModel = ({ pkg, die, part }) => ({
  type: SAVE_ROCKY_PDN_PKG_DIE_MODEL,
  pkg,
  die,
  part
});

export const updateComponentRLCPrefix = (COMP_PREFIX_LIB) => ({
  type: UPDATE_COMPONENT_RLC_PREFIX,
  COMP_PREFIX_LIB
})

/*  ERROR CHECK */
export const updateRockyPDNErrorList = (errorCheck) => ({
  type: UPDATE_ROCKY_PDN_ERROR_LIST,
  errorCheck
});

export const updateLoadingPCB = (pcbLoading) => ({
  type: UPDATE_LOADING_PCB,
  pcbLoading
});

/* Update rlc component prefix prompt icon display */
export const updateCompPrefixDisplay = (showPrefix) => ({
  type: UPDATE_COMP_PREFIX_PROMPT,
  showPrefix
});

export const rockyPdnSelectNets = (powerType, nets) => ({
  type: ROCKY_PDN_SELECT_NETS,
  powerType,
  nets
});