import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PCB, PCB_PRE_LAYOUT } from '@/constants/treeConstants';
import LayoutExplorer from '../../LayoutExplorer/Sierra';
import { PCB_ONLY, PCB_TOP_BOTTOM, TOP_BOTTOM_LAYOUT, PCB_LEFT_RIGHT, LEFT_RIGHT_lAYOUT } from '../../../constants/layoutConstants';
import TBLayout from '@/components/ContentLayout/topBottom';
import LRLayout from '@/components/ContentLayout/leftRight';
import projectDesigns from '@/services/helper/projectDesigns';
import PreLayout from './PreLayout';

class PCBLayout extends Component {

  getTop = (pcbInfo) => {
    const { siderWidth } = this.props;
    switch (pcbInfo.key) {
      case PCB:
        return <LayoutExplorer
          designID={pcbInfo.id}
          leftWidth={siderWidth}
        />
      case PCB_PRE_LAYOUT:
        setTimeout(() => {
          this.props.topBarResize && this.props.topBarResize();
        }, 100)
        return <div id={`sierra-pre-layout-main-${pcbInfo.id}`} className='my-aurora-content'>
          <PreLayout designID={pcbInfo.id} leftWidth={0} />
        </div>
      default: return null;
    }
  }

  getDefault = (pcbInfo, topHeight = 0, leftWidth = 0) => {
    const { siderWidth } = this.props;
    switch (pcbInfo.key) {
      case PCB:
        return <LayoutExplorer
          designID={pcbInfo.id}
          leftWidth={siderWidth + leftWidth}
          topHeight={topHeight}
        />
      case PCB_PRE_LAYOUT:
        setTimeout(() => {
          this.props.topBarResize && this.props.topBarResize();
        }, 100)
        return <div id={`sierra-pre-layout-main-${pcbInfo.id}`} className={`my-aurora-content aurora-PCB-layout-content-${pcbInfo.id}`}>
          <PreLayout designID={pcbInfo.id} leftWidth={0} />
        </div>
      default: return null;
    }
  }

  getBottom = (pcbInfo, topHeight) => {
    return this.getDefault(pcbInfo, topHeight);
  }

  getLeft = (pcbInfo) => {
    return this.getDefault(pcbInfo);
  }

  getRight = (pcbInfo, leftWidth) => {
    return this.getDefault(pcbInfo, 0, leftWidth);
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [], includeSetup = false;
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PCB_PRE_LAYOUT].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      } else {
        includeSetup = true;
      }
    });
    let count = 0;
    if (includeSetup) {
      count++;
    }
    if (!pcbs.length) {
      return count;
    } else if (pcbs.length > 1 && pcbLayout === PCB_TOP_BOTTOM && layout === TOP_BOTTOM_LAYOUT) {
      return count + 2;
    } else {
      return 2;
    }
  }

  _getLRSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [], includeSetup = false;
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PCB_PRE_LAYOUT].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      } else {
        includeSetup = true;
      }
    });
    let count = 0;
    if (includeSetup) {
      count++;
    }
    if (!pcbs.length) {
      return count;
    } else if (pcbs.length > 1 && pcbLayout === PCB_LEFT_RIGHT && layout === LEFT_RIGHT_lAYOUT) {
      return count + 2;
    } else {
      return 2;
    }
  }

  getContent = () => {
    const { pcbLayout, selectedKeys, siderWidth, currentProjectId } = this.props;
    const splitSize = this._getSplitSize();
    const lrSplitSize = this._getLRSplitSize();
    const currentProjectDesignIds = projectDesigns.getAvailableDesignIds(currentProjectId) || [];
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB_PRE_LAYOUT, PCB].includes(arr[0]) && currentProjectDesignIds.includes(arr[1])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) return null;
    if (pcbLayout === PCB_ONLY || pcbs.length === 1) {
      return [PCB].includes(pcbs[0].key) ?
        <LayoutExplorer
          designID={pcbs[0].id}
          leftWidth={siderWidth}
        />
        : <div id={`sierra-pre-layout-main-${pcbs[0].id}`} className='my-aurora-content'>
          <PreLayout designID={pcbs[0].id} leftWidth={siderWidth} />
        </div>
    } else if (pcbLayout === PCB_TOP_BOTTOM) {
      return <TBLayout
        {...this.props}
        id={"sierra-content-pcb"}
        bottomClassName={"sierra-content-bottom"}
        getTop={() => this.getTop(pcbs[0])}
        getBottom={(topHeight) => this.getBottom(pcbs[1], topHeight)}
        splitSize={splitSize}
      />
    } else {
      return <LRLayout
        {...this.props}
        leftClassName={"sierra-content-left"}
        getLeft={() => this.getLeft(pcbs[0])}
        getRight={(leftWidth) => this.getRight(pcbs[1], leftWidth)}
        splitSize={lrSplitSize}
        lineClassName='aurora-layout-split-screen'
      />
    }
  }

  render() {
    return (
      this.getContent()
    )
  }
}

const mapState = (state) => {
  const { SierraReducer } = state;
  const { project: { pcbLayout, selectedKeys, layout, currentProjectId } } = SierraReducer;
  const { tabVisible, monitorScreenInfo: { tabSelectKeys } } = state.MonitorInfoReducer;
  return {
    pcbLayout,
    selectedKeys,
    tabVisible,
    tabSelectKeys,
    layout,
    currentProjectId
  };
}

export default connect(mapState, null)(PCBLayout);