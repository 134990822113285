import {
  UPDATE_LIBRARY_MENU,
  SAVE_DECAP_GENERIC,
  UPDATE_LIBRARY_STATUS,
  SAVE_LIBRARY_LIST,
  SET_OPEN_PART_PANEL
} from './actionType';

const defaultState = {
  libraryStatus: false,
  VRMList: [],
  DecapList: [],
  DecapGeneric: [],
  DriverList: [],
  wireBondList: [],
  customSpiceList: [],
  customTouchstoneList: [],
  extractionHfssOptionsList: [],
  extractionSiwaveOptionsList: [],
  partVisible: false,
  cpmList: []
}

export const CascadeLibraryRenducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_LIBRARY_MENU:
    case UPDATE_LIBRARY_STATUS:
      return {
        ...state,
        libraryStatus: !state.libraryStatus
      }
    case SAVE_DECAP_GENERIC:
      return {
        ...state,
        DecapGeneric: action.DecapGeneric
      }
    case SAVE_LIBRARY_LIST:
      return {
        ...state,
        ...action.libraries
      }
    case SET_OPEN_PART_PANEL:
      return {
        ...state,
        partVisible: action.visible
      }
    default: return state;
  }
};