import {
  getBMAChannelList, createBMAFiles, getBMAFilesInfo, getBMADataFromSelectFile,
  deleteBMAFiles, getDataFromSelectFiles, getBMAChannelInfo, updateBMAChannelInfo,
  createBMASpec, getSpecContent, updateSpecContent, renameBMAFile, createBMAFolder,
  getBMALayerColorConfig, updateBMALayerColorConfig
} from '../../api/BMA/channel';
import apiProcess from '../../api/utility';

function getBMAChannelListPromise(projectId) {
  return apiProcess(getBMAChannelList, projectId);
}

function createBMAFilesPromise({ projectId, files, fileInfo, format, metaData, config, parentId }) {
  return apiProcess(createBMAFiles, { projectId, files, fileInfo, format, metaData, config, parentId });
}

function createBMASpecPromise({ channelId, type, files }) {
  return apiProcess(createBMASpec, { channelId, type, files });
}

function getBMAFilesInfoPromise(projectId) {
  return apiProcess(getBMAFilesInfo, projectId);
}

function getBMADataFromSelectFilePromise({ projectId, bmaInputFileIds, type, page, size }) {
  return apiProcess(getBMADataFromSelectFile, { projectId, bmaInputFileIds, type, page, size });
}

function getDataFromSelectFilesPromise({ projectId, bmaInputFileIds, type }) {
  return apiProcess(getDataFromSelectFiles, { projectId, bmaInputFileIds, type, })
}

function deleteBMAFilePromise(list) {
  return apiProcess(deleteBMAFiles, list)
}

function renameBMAFilePromise({ name, bmaInputFileId }) {
  return apiProcess(renameBMAFile, { name, bmaInputFileId })
}

function getBMAChannelInfoPromise(bmaChannelId) {
  return apiProcess(getBMAChannelInfo, bmaChannelId)
}

function updateBMAChannelInfoPromise({ bmaChannelId, content }) {
  return apiProcess(updateBMAChannelInfo, { bmaChannelId, content })
}

function getSpecContentPromise({ bmaChannelId, type }) {
  return apiProcess(getSpecContent, { bmaChannelId, type })
}

function updateSpecContentPromise({ bmaChannelId, type, content }) {
  return apiProcess(updateSpecContent, { bmaChannelId, type, content })
}

function createBMAFolderPromise({ projectId, name, parentId }) {
  return apiProcess(createBMAFolder, { projectId, name, parentId })
}

function getBMALayerColorConfigPromise({ bmaChannelId }) {
  return apiProcess(getBMALayerColorConfig, { bmaChannelId })
}

function updateBMALayerColorConfigPromise({ bmaChannelId, content }) {
  return apiProcess(updateBMALayerColorConfig, { bmaChannelId, content })
}

export {
  getBMAChannelListPromise,
  createBMAFilesPromise,
  getBMAFilesInfoPromise,
  createBMASpecPromise,
  getBMADataFromSelectFilePromise,
  deleteBMAFilePromise,
  renameBMAFilePromise,
  getDataFromSelectFilesPromise,
  getBMAChannelInfoPromise,
  updateBMAChannelInfoPromise,
  getSpecContentPromise,
  updateSpecContentPromise,
  createBMAFolderPromise,
  getBMALayerColorConfigPromise,
  updateBMALayerColorConfigPromise
}