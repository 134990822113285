import React, { Component, Fragment } from "react";
import { createPortal } from 'react-dom';
import PadStackInfo from "../../pages/LayoutExplorer/components/DesignInfo/PadStackInfo";
import { getOffsetLeft, getOffsetTop } from '@/services/helper/htmlElement';
import canvas from '@/services/LayoutCanvas';
import { SINGLE_PAGE_LAYOUT } from "../../constants/layoutConstants";


class PadstackPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxWidth: 800,
      maxHeight: 600,
      leftWidth: 0,
      topWidth: 0,
      designID: null,
      isLayoutPage: false
    }
    this.layoutRef = null
    this.layout = null
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.getScreenSize();
    this.getLayout();
  }

  componentDidUpdate(prevProps) {
    if (this.props.layout !== prevProps.layout) {
      this.getLayout();
    }
  }

  getScreenSize = () => {
    this.layoutRef = document.getElementById('root');

    if (this.layoutRef) {
      const maxWidth = this.layoutRef.offsetWidth;
      const maxHeight = this.layoutRef.offsetHeight;
      const offsetLeft = getOffsetLeft(this.layoutRef);
      const offsetTop = getOffsetTop(this.layoutRef);

      this.setState({
        maxWidth: parseInt(maxWidth * 0.8),
        maxHeight: parseInt(maxHeight * 0.8),
        leftWidth: offsetLeft,
        topWidth: offsetTop
      })
    }
  }

  getLayout = () => {
    const { layout, designId } = this.props;
    if (layout !== SINGLE_PAGE_LAYOUT && designId) {
      this.layout = canvas.getLayout(designId);
    } else {
      this.layout = null;
    }
  }

  onShowVias = (name, lastName) => {
    this.layout && this.layout.selectViaHoles(name, lastName);
  }

  render() {
    const content = <Fragment>
      <PadStackInfo
        {...this.props}
        {...this.state}
        onShowVias={this.onShowVias}
      />
      <div id='pad-stack-dialog'></div>
    </Fragment>
    return createPortal(content, this.dialogRoot)
  }
}


export default PadstackPanel;