var SymbolIdentifier = function (ID, symbolName) {
  // TODO. id or name??????
  this.ID = ID || -1;
  this.symbolName = symbolName || '';
}

SymbolIdentifier.prototype.updateByString = function (dataStr) {
  if (typeof dataStr !== 'string') return;

  var dataItems = dataStr.split(/:/);
  if (dataItems.length == 2) {
    this.ID = dataItems[0];
    this.symbolName = dataItems[1];
  } else if (dataItems.length == 1) {
    this.symbolName = dataItems[0];
  }
}

export default SymbolIdentifier;