import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusCircleOutlined, DownOutlined, RightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Switch, Input, Select, Divider, Radio, Checkbox, message, Tabs, Tooltip } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { scaleConversion, numFormatConversion, numExponentialFormat, getUnitConversionValue, toNonExponential } from '@/services/helper/numberHelper';
import { fastPIValueCheck } from '@/services/helper/fastPIValueCheck';
import NP from 'number-precision';
import { numberCheck } from '@/services/helper/dataProcess';
import { getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { ImpExtraction, SIWAVE, HFSS, PSI, POWERSI, meshMethod_PHIMESH, meshMethod_PHIPLUS, meshMethod_CLASSICMESH, BASIC, LAYOUT, SIMULATION_BASIC, SIMULATION_ADVANCED, NATURAL, MAGNETIC, NOCONSIDERED, BASICMODE, ENHANCEDMODE, LOG, LINEAR, DECADE, STEP } from '@/services/Cascade/Impedance';
import PreviewBtn from '@/components/ClipDesign/previewBtn';
import { AEDB, CADENCE_BRD, ODB_PP, SPD } from '../../../../constants/designVendor';
import HPCOptions from '../../../../components/ExtractionOptions/HPCOptions';
import MultiZoneOptions from '../../../../components/ExtractionOptions/MultiZoneOptions';
import { cleanupDesignPromise } from '../../../../services/api/Design/design';
import { SIGN_OFF_TEMPLATE } from '../../../../constants/treeConstants';
import designConstructor from '../../../../services/helper/designConstructor';
import { SINGLE_PAGE_LAYOUT } from '../../../../constants/layoutConstants';
import TimeoutOptions from '../../../../components/ExtractionOptions/TimeoutOptions';
import './index.css';

const { Option } = Select;
const { TabPane } = Tabs
NP.enableBoundaryChecking(false);// default param is true
const specialVoidParams = [
  { key: 'pwsiDogleg', title: 'Dogleg hole smaller than', unit: 'mm' },
  { key: 'pwsiThermal', title: 'Thermal hole smaller than', unit: 'mm' },
  { key: 'pwsiHole', title: 'Other hole smaller than', unit: 'mm' },
  { key: 'pwsiViaHole', title: 'Via hole smaller than', unit: 'mm' },
  { key: 'pwsiSlenderHoleAreaThreshold', title: 'Slender hole area smaller than', unit: 'mm²' },
  { key: 'pwsiSlenderHoleSizeThreshold', title: 'Slender hole size smaller than', unit: 'mm' },
];

const thermalTracePadParams = [
  { key: 'pwsiThermalTracePadTraceToShapeFactor', title: 'Pad-Trace-Shape factor' },
  { key: 'pwsiThermalTracePadShapeToShapeThreshold', title: 'Shape-Trace-Shape threshold', unit: 'mm' },
];

const viaAntipadParams = [
  { key: 'pwsiViaAntipadSearchFactor', title: 'Via-AntiPad search factor' },
  { key: 'pwsiViaAntipadDistanceRangeFactor', title: 'Via-AntiPad distance range factor' }
];

const pwsiTabs = [
  { key: BASIC, name: 'Basic' },
  { key: LAYOUT, name: 'Layout' },
  { key: SIMULATION_BASIC, name: 'Simulation (Basic) ' },
  { key: SIMULATION_ADVANCED, name: 'Simulation (Advanced) ' },
]

const interPlaneCouplingParams = [
  { key: NOCONSIDERED, title: 'Not considered' },
  { key: BASICMODE, title: 'Basic mode' },
  { key: ENHANCEDMODE, title: 'Enhanced mode' }
]

const samplingTypeOptions = [
  { name: 'Log', value: LOG },
  { name: 'Linear', value: LINEAR }
]

const defaultCustomFrequencySweep = [{
  start: '100',
  end: '1e8',
  type: LOG,
  step: '100',
  units: {
    start: 'Hz',
    end: 'MHz',
    step: 'MHz'
  }
}, {
  start: '1e8',
  end: '2e8',
  type: LINEAR,
  step: '5e6',
  units: {
    start: 'MHz',
    end: 'MHz',
    step: 'MHz'
  }
}]
class ExtractionPanel extends Component {

  constructor(props) {
    super(props);
    const extraction = new ImpExtraction();
    const _FMAX = numFormatConversion(extraction.FMAX, -6);
    const isMultiZone = designConstructor.getDesignMultiZone(props.pcbId);
    const vendor = designConstructor.getDesignVendor(props.pcbId)
    this.state = {
      ...extraction,
      FMAX: _FMAX,
      FMAXUnit: 'MHz',
      maxWidth: 862,
      maxHeight: 810,
      advanced: false,
      cleanupLoading: false,
      pcbId: props.pcbId,
      isMultiZone,
      apply: false,
      customFrequencySweep: defaultCustomFrequencySweep,
      vendor
    }
    this.ymlLibrary = {
      hfssLibraryId: '',
      siwaveLibraryId: '',
      powersiLibraryId: ''
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 862)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { designList } = this.props;
    if (designList.length) {
      const pcbId = this.state.pcbId || designList[0].id;
      const isMultiZone = designConstructor.getDesignMultiZone(pcbId);
      const vendor = designConstructor.getDesignVendor(pcbId)
      this.setState({
        pcbId: pcbId,
        isMultiZone,
        vendor
      }, () => {
        this.setConfig()
      })
    } else {
      this.setConfig();
    }
  }

  setConfig = () => {
    const { Config, designList } = this.props;
    const { pcbId, vendor } = this.state;
    const newExtraction = vendor === SPD ? new ImpExtraction({ type: POWERSI }) : new ImpExtraction()
    let _Config = designList.length ? designList.find(item => item.id === pcbId).extraction || newExtraction : Config;
    if (_Config) {
      if (vendor === SPD && _Config.type !== POWERSI) {
        _Config = new ImpExtraction({ ..._Config, type: POWERSI })
      }
      if ([ODB_PP, AEDB].includes(vendor) && _Config.type === POWERSI) {
        _Config = new ImpExtraction({ ..._Config, type: SIWAVE })
      }
      let { FMAX, errorTolerance, type, ymlLibraryId, customFrequencySweep } = _Config;
      const _FMAX = numFormatConversion(FMAX, -6);
      const _customFrequencySweep = customFrequencySweep || defaultCustomFrequencySweep
      _customFrequencySweep.forEach(item => {
        const formatKeys = item.type === LINEAR ? ['start', 'end', 'step'] : ['start', 'end']
        Object.keys(item).forEach(key => {
          if (formatKeys.includes(key)) {
            item[key] = getUnitConversionValue(item[key], item.units[key], 'Hz',)
          }
        })
      })
      const _errorTolerance = (errorTolerance || errorTolerance === 0) && [HFSS, SIWAVE].includes(type) ? Number(errorTolerance) * 100 : undefined;
      if (type === HFSS) this.ymlLibrary.hfssLibraryId = ymlLibraryId
      else if (type === SIWAVE) this.ymlLibrary.siwaveLibraryId = ymlLibraryId
      else if (type === POWERSI) this.ymlLibrary.powersiLibraryId = ymlLibraryId
      const extraction = new ImpExtraction({
        ..._Config,
        customFrequencySweep: _customFrequencySweep,
        FMAX: (_FMAX === '0' ? '' : _FMAX) || '', // v0.1.15 update default value to 0， display ''
        errorTolerance: _errorTolerance
      })
      this.setState({
        ...extraction
      })
    }
  }

  fMaxSelectAfter = () => {
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      defaultValue={this.state.FMAXUnit}
      onChange={(e) => this.changeMaxUnit(e)}
      style={{ width: 65 }}
    >
      <Option value="MHz">MHz</Option>
      <Option value="GHz">GHz</Option>
    </Select>
  };

  changeMaxUnit = (unit) => {
    const { FMAXUnit, FMAX } = this.state;

    if (FMAXUnit === unit) {
      return;
    }
    const scale = scaleConversion(unit, FMAXUnit);
    let _FMAX = FMAX && !numberCheck(FMAX) ? NP.times(FMAX, scale) : FMAX;
    this.setState({
      FMAX: _FMAX,
      FMAXUnit: unit
    });
  }

  unitSelect = (key, index) => {
    const { customFrequencySweep } = this.state;
    const options = ["Hz", "KHz", "MHz", "GHz"];
    const value = customFrequencySweep[index].units[key]
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      value={value}
      style={{ width: 68 }}
      onChange={(e) => this.changeUnit(e, key, index)}
    >
      {options.map(item => (
        <Option key={item} value={item}>{item}</Option>
      ))}
    </Select>
  }

  changeUnit = (key, type, index) => {
    const { customFrequencySweep } = this.state
    const prevUnit = customFrequencySweep[index].units[type]
    let value = customFrequencySweep[index][type]
    if (prevUnit === key) {
      return;
    }
    const scale = scaleConversion(key, prevUnit);
    value = value && !numberCheck(value) ? NP.times(value, scale).toString() : value;
    if (type === 'start' && index > 0) {
      customFrequencySweep[index - 1].end = value
      customFrequencySweep[index - 1].units.end = key
    } else if (type === 'end' && index < customFrequencySweep.length - 1) {
      customFrequencySweep[index + 1].start = value
      customFrequencySweep[index + 1].units.start = key
    }
    customFrequencySweep[index][type] = value
    customFrequencySweep[index].units[type] = key
    this.setState({
      customFrequencySweep
    })
  }

  sweepInputBlur = (e, inputType, index) => {
    const { error, customFrequencySweep } = this.state
    const value = customFrequencySweep[index][inputType]
    const check = fastPIValueCheck({ value })
    if (check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: check }
      });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
      }
      const newError = error && error.type === inputType ? null : error;
      customFrequencySweep[index][inputType] = _value
      this.setState({
        customFrequencySweep,
        error: newError
      })
    }
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { FMAXUnit, error, type } = this.state;
    const unit = inputType === 'FMAX' ? FMAXUnit : null;

    if (['REFR', 'FMAX'].includes(inputType)) {
      if (value) {
        _check = fastPIValueCheck({ value, inputType, unit, product: 'Cascade' });
      }
    } else {
      _check = fastPIValueCheck({ value, inputType, unit, product: 'Cascade' });
    }

    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
      }
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: _value,
        error: newError
      }, () => {
        if (inputType === 'ratio' && type === POWERSI) {
          this.saveAndShow()
        }
      });
    }
  }

  changeValue = (e, type) => {
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    });
  }

  changeSelect = (key, type) => {
    this.setState({
      [type]: key
    })
  }

  changeSweepValue = (e, type, index) => {
    let value = e.target ? e.target.value : e;
    const { error, customFrequencySweep } = this.state;
    const newError = error && error.type === type ? null : error;
    if (type === 'start' && index > 0) {
      customFrequencySweep[index - 1]['end'] = value
    } else if (type === 'end' && index < customFrequencySweep.length - 1) {
      customFrequencySweep[index + 1]['start'] = value
    }
    customFrequencySweep[index][type] = value
    this.setState({
      customFrequencySweep,
      error: newError
    });
  }

  changeSelectValue = (value, type) => {
    if (type === HFSS) {
      this.ymlLibrary.hfssLibraryId = value
    } else if (type === SIWAVE) {
      this.ymlLibrary.siwaveLibraryId = value
    } else if (type === POWERSI) {
      this.ymlLibrary.powersiLibraryId = value
    }
    this.setState({
      ymlLibraryId: value
    });
  }

  changeSwitch = (checked, type) => {
    let value = checked ? 1 : 0;

    this.setState({
      [type]: value
    });
  }

  changeBooleanSwitch = (checked, type) => {
    let params = {}
    if (type === 'includeDC') {
      params = {
        calculateDCPoint: checked,
        bbsFitted: checked
      }
    }
    if (type === 'accurateMode' && checked) {
      params = { ...params, pdcEqualPotential: false }
    }
    this.setState({
      [type]: checked,
      ...params
    });
  }

  changeEnableLinearSweep = (checked) => {
    const { customFrequencySweep, error } = this.state
    let _customFrequencySweep = customFrequencySweep
    let _error
    if (checked) {
      if (customFrequencySweep && customFrequencySweep.length === 0) {
        _customFrequencySweep = defaultCustomFrequencySweep
        _customFrequencySweep.forEach(item => {
          const formatKeys = item.type === LINEAR ? ['start', 'end', 'step'] : ['start', 'end']
          Object.keys(item).forEach(key => {
            if (formatKeys.includes(key)) {
              item[key] = getUnitConversionValue(item[key], item.units[key], 'Hz',)
            }
          })
        })
      }
    } else {
      _error = error && ['start', 'end', 'step'].includes(error.type) ? null : error;
    }
    this.setState({
      customFrequencySweep: _customFrequencySweep,
      enableLinearSweep: checked,
      error: _error
    });
  }

  coreUseChange = (e) => {
    let value = e.target.value;
    this.setState({
      psiUseAllCores: value === 'all' ? true : false
    })
  }

  showAdvanced = (e) => {
    e && e.stopPropagation();
    this.setState({
      advanced: !this.state.advanced
    })
  }

  meshingChange = (e) => {
    let value = e.target.value;
    this.setState({
      autoMeshFreq: value === 'auto' ? true : false
    })
  }

  radioChange = (e, type) => {
    let value = e.target.value;
    this.setState({
      [type]: value
    })
  }

  psiLossModelChange = (e) => {
    let value = e.target.value;
    this.setState({
      psiLossModel: value
    })
  }

  psiAutoSmallHoleChange = (e) => {
    let value = e.target.value;
    this.setState({
      psiAutoSmallHole: value
    })
  }

  FMaxFormat = (fMax, unit) => {
    //update fMax by unit
    // "MHz" / "GHz" ==> "Hz"
    const scale = scaleConversion("Hz", unit);
    const _fMax = fMax ? NP.times(fMax, scale).toString() : fMax;
    const _format = numExponentialFormat(_fMax)
    return _format ? _format : 0;
  }

  sweepFormat = (value, unit) => {
    const scale = scaleConversion("Hz", unit);
    const _value = value ? NP.times(value, scale).toString() : value;
    const _format = numExponentialFormat(_value)
    return _format ? _format : 0;
  }

  closeModal = () => {
    const { error, cleanupLoading, customFrequencySweep, enableLinearSweep } = this.state;
    if (cleanupLoading) {
      message.error('Please close the page after Cleanup is over.')
      return;
    }

    let newError = error
    if (enableLinearSweep) {
      for (let item of customFrequencySweep) {
        const keys = Object.keys(item)
        for (let key of keys) {
          if (!item[key]) {
            newError = { type: key, error: "Please set the value." }
            this.setState({
              error: newError
            })
            return;
          } else {
            newError = newError && newError.type === key ? null : newError;
            this.setState({
              error: newError
            })
          }
        }
        if (item.end && item.start) {
          const endValue = Number(toNonExponential(this.sweepFormat(item.end, item.units.end)))
          const startValue = Number(toNonExponential(this.sweepFormat(item.start, item.units.start)))
          if (endValue < startValue) {
            newError = { type: 'end', error: "End sweep must be greater than start sweep." }
            this.setState({
              error: newError
            })
            break;
          } else {
            newError = newError && newError.type === 'end' ? null : newError;
            this.setState({
              error: newError
            })
          }
        }
      }
    }

    if (newError) {
      return
    }

    setTimeout(() => {
      this.saveConfig();
      this.props.closeModal(false);
    }, 100);
  }

  saveConfig = (newPCBId, updateConfig) => {
    const { layout, getSelectedDesignIDs } = this.props
    const { error, apply } = this.state;
    if (error) {
      return;
    }
    const { FMAX, FMAXUnit, errorTolerance, type, pcbId, customFrequencySweep } = this.state;
    const { view } = this.props;
    const _customFrequencySweep = [...customFrequencySweep]
    //update FMAX by unit
    let _FMAX = this.FMaxFormat(FMAX, FMAXUnit);
    _customFrequencySweep.forEach(item => {
      const formatKeys = item.type === LINEAR ? ['start', 'end', 'step'] : ['start', 'end']
      Object.keys(item).forEach(key => {
        if (formatKeys.includes(key)) {
          item[key] = this.sweepFormat(item[key], item.units[key])
        }
      })
    })
    let config = {
      ...new ImpExtraction({ ...this.state }),
      FMAX: _FMAX, // v0.1.15 default value change to ''
      customFrequencySweep: _customFrequencySweep
    };

    if ([HFSS, SIWAVE].includes(type)) {
      let _errorTolerance = errorTolerance === '0' ? errorTolerance : errorTolerance / 100;
      config.errorTolerance = _errorTolerance;
    }

    if (view === SIGN_OFF_TEMPLATE) {
      this.props.saveConfig(config);
    } else {
      this.props.saveConfig(pcbId, config, apply);
    }
    if (newPCBId) {
      const isMultiZone = designConstructor.getDesignMultiZone(newPCBId);
      const vendor = designConstructor.getDesignVendor(newPCBId)
      this.setState({
        pcbId: newPCBId,
        isMultiZone,
        vendor
      }, () => {
        this.setConfig()
      })
    }
    if (updateConfig && layout !== SINGLE_PAGE_LAYOUT && getSelectedDesignIDs.includes(pcbId)) {
      setTimeout(() => {
        this.setConfig()
      }, 100);
    }
  }

  onPressEnter = (e) => {
    e.target.blur();
  }

  changeAnsysType = (e, prevType) => {
    const { errorTolerance } = this.state
    const type = e.target.value;
    let ymlLibraryId
    if (type === HFSS) ymlLibraryId = this.ymlLibrary.hfssLibraryId
    else if (type === SIWAVE) ymlLibraryId = this.ymlLibrary.siwaveLibraryId
    else if (type === POWERSI) ymlLibraryId = this.ymlLibrary.powersiLibraryId
    let params = { ymlLibraryId }
    if ([HFSS, SIWAVE].includes(prevType)) {
      let _errorTolerance = errorTolerance === '0' ? errorTolerance : errorTolerance / 100;
      params.errorTolerance = _errorTolerance;
    }
    let Config = new ImpExtraction({ ...this.state, ...params, type });
    if ([HFSS, SIWAVE].includes(type)) {
      const { errorTolerance: newErrorTolerance } = Config
      const _errorTolerance = (newErrorTolerance || newErrorTolerance === 0) ? Number(newErrorTolerance) * 100 : undefined;
      Config.errorTolerance = _errorTolerance
    } else {
      Config.errorTolerance = undefined
    }
    this.setState({
      ...Config,
      FMAXUnit: 'MHz',
    })
  }

  getRenderByType = (type) => {
    switch (type) {
      case HFSS:
        return this.HFSSRender();
      case SIWAVE:
        return this.SIwaveRender();
      case PSI:
        return this.PSIRender();
      default: break;
    }
  }

  saveAndShow = () => {
    const { ratio, pcbId } = this.state;
    this.saveConfig(null, true);
    setTimeout(() => {
      this.props.showPreview(ratio, pcbId);
    }, 100);
  }

  previewMultiZone = (e) => {
    e && e.stopPropagation();
    this.saveConfig(null, true);
    setTimeout(() => {
      this.props.previewMultiZone();
    }, 100);
  }

  cleanup = (e) => {
    e && e.stopPropagation();
    this.setState({
      cleanupLoading: true
    }, async () => {
      const { distance, pcbId } = this.state;
      this.saveConfig();
      try {
        await cleanupDesignPromise({ designId: pcbId, snap: distance });
      } catch (error) {
        console.error(error)
      } finally {
        this.setState({
          cleanupLoading: false
        })
      }
    })
  }

  changePCBSelect = (value) => {
    this.saveConfig(value)
  }

  changeApply = (e) => {
    const apply = e.target.checked;
    this.setState({
      apply
    })
  }

  getOptionFile = () => {
    const { extractionHfssOptionsList, extractionSiwaveOptionsList, extractionPowerSIOptionsList } = this.props;
    const { type } = this.state;
    switch (type) {
      case SIWAVE:
        return {
          selectOptions: extractionSiwaveOptionsList,
          title: 'SIwave Option File'
        }
      case HFSS:
        return {
          selectOptions: extractionHfssOptionsList,
          title: 'HFSS Option File'
        }
      case POWERSI:
        return {
          selectOptions: extractionPowerSIOptionsList,
          title: 'PowerSI Option File'
        }
      default: return null;
    }
  }

  MeshingRender = () => {
    const { meshFrequency, autoMeshFreq } = this.state;
    const value = autoMeshFreq ? 'auto' : 'mesh';
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Meshing Frequency</span>
        <span>
          <Radio.Group value={value} onChange={this.meshingChange}>
            <Radio value={'auto'}>Auto</Radio>
            <Radio value={'mesh'}>Other​</Radio>
          </Radio.Group>
          {!autoMeshFreq && <Input
            value={meshFrequency}
            addonAfter={'GHz'}
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            style={{ width: 'calc(45% - 145px)' }}
            onChange={(e) => this.changeValue(e, 'meshFrequency')}
            onBlur={(e) => this.inputBlur(e, 'meshFrequency')}
            onPressEnter={this.onPressEnter}
          />}
        </span>
      </div>
      <Divider />
    </Fragment>
  }

  MeshingMethodRender = () => {
    const { meshingMethod } = this.state;
    return <div className='cascade-top-option-panel-content' key={'meshingMethod'}>
      <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>HFSS Initial Mesh Method</span>
      <span>
        <Radio.Group value={meshingMethod} onChange={(e) => this.radioChange(e, 'meshingMethod')}>
          <Radio value={meshMethod_PHIMESH}>Phi mesh</Radio>
          <Radio value={meshMethod_PHIPLUS}>Phi plus</Radio>
          <Radio value={meshMethod_CLASSICMESH}>Classic mesh</Radio>
        </Radio.Group>
      </span>
    </div>
  }

  toleranceRender = () => {
    const { errorTolerance } = this.state;
    return <div className='cascade-top-option-panel-content'>
      <span className='cascade-top-option-panel-content-body'>Result Interpolation Error Tolerance</span>
      <span><Input
        className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
        addonAfter={'%'}
        value={errorTolerance}
        onChange={(e) => this.changeValue(e, 'errorTolerance')}
        onBlur={(e) => this.inputBlur(e, 'errorTolerance')}
        onPressEnter={this.onPressEnter}
      />
      </span>
    </div>
  }

  SIwaveRender = () => {
    const { VOID, PADM, PLNM, VRTC, causality, passivity, customPlaneVoidMeshingSetting } = this.state;
    return <Fragment>
      {this.MeshingRender()}
      {this.toleranceRender()}
      <Divider />
      <Fragment>
        <div className='cascade-top-option-panel-content'>
          <span className='cascade-top-option-panel-content-body'>Custom plane void meshing setting</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={customPlaneVoidMeshingSetting}
            onChange={(checked) => this.changeBooleanSwitch(checked, 'customPlaneVoidMeshingSetting')}
          />
        </div>
        {
          customPlaneVoidMeshingSetting ? <Fragment>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Do not mesh the void smaller than</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={'mm²'}
                value={VOID}
                onChange={(e) => this.changeValue(e, 'VOID')}
                onBlur={(e) => this.inputBlur(e, 'VOID')}
                onPressEnter={this.onPressEnter}
              />
              </span>
            </div>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Explicitly mesh pads larger than</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={'mm²'}
                value={PADM}
                onChange={(e) => this.changeValue(e, 'PADM')}
                onBlur={(e) => this.inputBlur(e, 'PADM')}
                onPressEnter={this.onPressEnter}
              />
              </span>
            </div>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Ignore planes smaller than</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={'mm²'}
                value={PLNM}
                onChange={(e) => this.changeValue(e, 'PLNM')}
                onBlur={(e) => this.inputBlur(e, 'PLNM')}
                onPressEnter={this.onPressEnter}
              />
              </span>
            </div>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Snap vertices separated by less</span>
              <span><Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                addonAfter={'um'}
                value={VRTC}
                onChange={(e) => this.changeValue(e, 'VRTC')}
                onBlur={(e) => this.inputBlur(e, 'VRTC')}
                onPressEnter={this.onPressEnter}
              />
              </span>
            </div>
          </Fragment> : null
        }
      </Fragment>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Enforce Causality</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={causality ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'causality')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Enforce Passivity</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={passivity ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'passivity')}
        />
      </div>
    </Fragment>
  }

  HFSSRender = () => {
    const { maxSolution, maxPasses, maxDelta, minConvergedPass, passivity } = this.state;
    return <Fragment>
      {this.MeshingRender()}
      {this.toleranceRender()}
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Maximum Number of Solutions</span>
        <span><Input
          className='cascade-top-option-panel-content-input cascade-option-input-addonAfter' value={maxSolution}
          onChange={(e) => this.changeValue(e, 'maxSolution')}
          onBlur={(e) => this.inputBlur(e, 'maxSolution')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Adaptive Meshing Max Number of Passes</span>
        <span><Input
          className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
          value={maxPasses}
          onChange={(e) => this.changeValue(e, 'maxPasses')}
          onBlur={(e) => this.inputBlur(e, 'maxPasses')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Minimum Converged Passes</span>
        <span><Input
          className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
          value={minConvergedPass}
          onChange={(e) => this.changeValue(e, 'minConvergedPass')}
          onBlur={(e) => this.inputBlur(e, 'minConvergedPass')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Adaptive Meshing Max Delta</span>
        <span><Input
          className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
          value={maxDelta}
          onChange={(e) => this.changeValue(e, 'maxDelta')}
          onBlur={(e) => this.inputBlur(e, 'maxDelta')}
          onPressEnter={this.onPressEnter}
        />
        </span>
      </div>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Enforce Passivity</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={passivity ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'passivity')}
        />
      </div>
    </Fragment>
  }

  PSIRender = () => {
    const { psiLossModel, psiAutoSmallHole, psiSmallHoleSize } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Improved Loss Model</span>
        <span>
          <Radio.Group value={psiLossModel} onChange={this.psiLossModelChange}>
            <Radio value={'Level 1'}>Level 1</Radio>
            <Radio value={'Level 2'}>Level 2</Radio>
            <Radio value={'Level 3'}>Level 3</Radio>
          </Radio.Group>
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Ignore Small Holes</span>
        <span>
          <Radio.Group name="psiAutoSmallHole" value={psiAutoSmallHole} onChange={this.psiAutoSmallHoleChange}>
            <Radio value={true}>Auto Detect</Radio>
          </Radio.Group>
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'></span>
        <span>
          <Radio.Group name="psiAutoSmallHole" value={psiAutoSmallHole} onChange={this.psiAutoSmallHoleChange}>
            <Radio value={false}>Diameter smaller than</Radio>
          </Radio.Group>
          <Input
            value={psiSmallHoleSize}
            disabled={psiAutoSmallHole}
            addonAfter={'um'}
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            style={{ width: 'calc(45% - 177px)' }}
            onChange={(e) => this.changeValue(e, 'psiSmallHoleSize')}
            onBlur={(e) => this.inputBlur(e, 'psiSmallHoleSize')}
            onPressEnter={this.onPressEnter}
          />
        </span>
      </div>
    </Fragment>
  }

  setModelNameRender = () => {
    const { createModelByPartNumber } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Create component model names</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Based upon component part number</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={createModelByPartNumber}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'createModelByPartNumber')}
        />
      </div>
    </Fragment>
  }

  padShapeRender = () => {
    const { pwsiMaxPadSize, pwsiMinShapeSize, pwsiMaxPadSizeRelative, pwsiPadRelative, pwsiApplyToPwrGndNets, pwsiApplyToSignalNets, pwsiArcDiscretizationPrecision } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Pad &lt;===&gt; Shape Conversion</span>
      </div>
      <Radio.Group value={pwsiPadRelative} onChange={(e) => this.radioChange(e, 'pwsiPadRelative')} style={{ display: 'block' }}>
        <div className='cascade-top-option-panel-content'>
          <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span cascade-top-option-panel-content-pad-body'> <Radio value={false}>Max pad size</Radio></span>
          <Input
            className='cascade-top-option-panel-content-input cascade-top-option-panel-content-pad-input cascade-option-input-addonAfter'
            addonAfter={'mm'}
            value={pwsiPadRelative ? '' : pwsiMaxPadSize}
            disabled={pwsiPadRelative}
            onChange={(e) => this.changeValue(e, 'pwsiMaxPadSize')}
            onBlur={(e) => this.inputBlur(e, 'pwsiMaxPadSize')}
            onPressEnter={this.onPressEnter}
          />
          <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span cascade-top-option-panel-content-pad-body'>Min shape size</span>
          <Input
            className='cascade-top-option-panel-content-input cascade-top-option-panel-content-pad-input cascade-option-input-addonAfter'
            addonAfter={'mm'}
            value={pwsiPadRelative ? '' : pwsiMinShapeSize}
            disabled={pwsiPadRelative}
            onChange={(e) => this.changeValue(e, 'pwsiMinShapeSize')}
            onBlur={(e) => this.inputBlur(e, 'pwsiMinShapeSize')}
            onPressEnter={this.onPressEnter}
          />
        </div>
        <div className='cascade-top-option-panel-content'>
          <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span cascade-top-option-panel-content-pad-body'>  <Radio value={true} >Max pad relative</Radio></span>
          <Input
            className='cascade-top-option-panel-content-input cascade-top-option-panel-content-pad-input'
            value={!pwsiPadRelative ? '' : pwsiMaxPadSizeRelative}
            disabled={!pwsiPadRelative}
            onChange={(e) => this.changeValue(e, 'pwsiMaxPadSizeRelative')}
            onBlur={(e) => this.inputBlur(e, 'pwsiMaxPadSizeRelative')}
            onPressEnter={this.onPressEnter}
          />
        </div>
      </Radio.Group>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span cascade-top-option-panel-content-pad-body' >Apply to</span>
        <span>Power/Ground nets</span>
        <span className='cascade-top-option-panel-content-sub-switch' style={{ marginRight: '5%' }}>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={pwsiApplyToPwrGndNets}
            onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiApplyToPwrGndNets')}
          />
        </span>
        <span>Signal nets</span>
        <span className='cascade-top-option-panel-content-sub-switch'>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={pwsiApplyToSignalNets}
            onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiApplyToSignalNets')}
          />
        </span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>Maximum arc length replaced by line segment</span>
        <Input
          className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
          addonAfter={'mm'}
          value={pwsiArcDiscretizationPrecision}
          onChange={(e) => this.changeValue(e, 'pwsiArcDiscretizationPrecision')}
          onBlur={(e) => this.inputBlur(e, 'pwsiArcDiscretizationPrecision')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>During shape processing, pads larger than the Max pad size(when not empty) are converted to shapes.</span>
      </div>
    </Fragment>
  }

  clipRender = (type) => {
    const { CLIP, IPC, ratio } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Clipping</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={CLIP === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'CLIP')}
        />
        <Input
          className={`cascade-top-option-panel-content-input cascade-option-input-addonAfter ${type === POWERSI ? 'cascade-option-clipping-input' : 'cascade-option-clipping-input-siwave'}`}
          addonAfter={'Ratio'}
          disabled={CLIP === 0}
          value={ratio}
          onChange={(e) => this.changeValue(e, 'ratio')}
          onBlur={(e) => this.inputBlur(e, 'ratio')}
          onPressEnter={this.onPressEnter}
        />
        {
          type === POWERSI &&
          <Fragment>
            <PreviewBtn
              disabled={CLIP === 0}
              savePreviewStatus={this.props.savePreviewStatus}
              saveAndShow={this.saveAndShow}
              onRef={this.props.previewBtnOnRef}
            />
          </Fragment>
        }
      </div>
      {type === SIWAVE ? <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Intra-plane Coupling</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={IPC === 1 ? true : false}
          onChange={(checked) => this.changeSwitch(checked, 'IPC')}
        />
      </div>
        : null}
      <Divider /></Fragment>
  }

  ymlOptionRender = () => {
    const { ymlLibraryId, type } = this.state;
    const content = this.getOptionFile()
    if (!content) {
      return null;
    }
    const { title, selectOptions } = content
    return <Fragment>
      {type !== POWERSI && <Divider />}
      <div className='cascade-top-option-panel-content'>
        <span className={'cascade-top-option-panel-content-body'}>{title}</span>
        <Select
          placeholder={title}
          value={ymlLibraryId || ''}
          onChange={(value) => { this.changeSelectValue(value, type) }}
          className={"aurora-select cascade-top-option-panel-content-input"}
          popupClassName='aurora-select-dropdown'
          allowClear
        >
          {selectOptions.map(item => <Option
            key={item.id}
            value={item.id}
            title={item.name}
          >{item.name}</Option>)}
        </Select>
      </div>
    </Fragment>
  }

  getAdvancedOptions = () => {
    const { type } = this.state;
    const { vendor } = this.props;
    switch (type) {
      case HFSS:
        return vendor === CADENCE_BRD ? [
          { rendering: this.MeshingMethodRender, optionType: 'render' },
          { key: 'auroraAedb', title: 'Use Aurora generated AEDB​', optionType: 'switch' }
        ] : [
          { rendering: this.MeshingMethodRender, optionType: 'render' },
        ];
      case SIWAVE:
      case PSI:
        return vendor === CADENCE_BRD ? [{ key: 'auroraAedb', title: 'Use Aurora generated AEDB​', optionType: 'switch' }] : [];
      default:
        return []
    }
  }

  advancedRender = () => {
    const advancedOptions = this.getAdvancedOptions();
    const { advanced } = this.state;
    return advancedOptions.length ? <Fragment>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-advanced-body' onClick={this.showAdvanced}>
          {advanced ? <DownOutlined className='advanced-arrow-icon' /> : <RightOutlined className='advanced-arrow-icon' />}
          Advanced
        </span>
      </div>
      {advanced && advancedOptions.map(option => {
        return this.advancedOptionsRender(option)
      })}
    </Fragment> : null;
  }

  advancedOptionsRender = (option) => {
    const { key, title, optionType, child, mixKey, addonAfter, rendering, style = {} } = option;
    const className = child ? 'cascade-extraction-panel-next-sub-span' : 'cascade-extraction-panel-sub-span'
    switch (optionType) {
      case 'switch':
        return <div className='cascade-top-option-panel-content' key={key}>
          <span className={`cascade-top-option-panel-content-body ${className}`} style={style}>{title}</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={this.state[key] || false}
            onChange={(checked) => this.changeBooleanSwitch(checked, key)}
          />
        </div>
      case 'mix':
        return <div className='cascade-top-option-panel-content' key={key}>
          <span className={`cascade-top-option-panel-content-body ${className}`} style={style}>{title}</span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={this.state[key] || false}
            onChange={(checked) => this.changeBooleanSwitch(checked, key)}
          />
          {
            this.state[key] ?
              <Input
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter cascade-option-advanced-mix-input'
                addonAfter={addonAfter}
                value={this.state[mixKey]}
                onChange={(e) => this.changeValue(e, mixKey)}
                onBlur={(e) => this.inputBlur(e, mixKey)}
                onPressEnter={this.onPressEnter}
              /> : null
          }
        </div>
      case 'render':
        return rendering()
      default:
        return <div className='cascade-top-option-panel-content' key={key}>
          <span className={`cascade-top-option-panel-content-body ${className}`}>{title}</span>
        </div>
    }
  }

  sampRender = () => {
    const { psiAdaptSamp, psiEnforceDC } = this.state;
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className={`cascade-top-option-panel-content-body`}>Adaptive Sampling</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={psiAdaptSamp || false}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'psiAdaptSamp')}
        />
      </div>
      {psiAdaptSamp && <div className='cascade-top-option-panel-content'>
        <span className={`cascade-top-option-panel-content-body cascade-extraction-panel-sub-span`}>Enforce DC point and causality</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={psiEnforceDC || false}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'psiEnforceDC')}
        />
      </div>}
    </Fragment>
  }

  HPCRender = (type) => {
    const { numCores, corePercent, ramPercent } = this.state;
    return <HPCOptions
      numCores={numCores}
      corePercent={corePercent}
      ramPercent={ramPercent}
      type={type}
      ramDisplay={[SIWAVE, HFSS].includes(type) ? true : false}
      changeValue={this.changeValue}
      inputBlur={this.inputBlur}
      onPressEnter={this.onPressEnter}
    />
  }

  timeRender = () => {
    const { licWaitSwitch, licWait, licWaitUnit, timeoutSwitch, timeoutHours, timeoutMinutes } = this.state;
    return <TimeoutOptions
      licWaitSwitch={licWaitSwitch}
      licWait={licWait}
      licWaitUnit={licWaitUnit}
      timeoutSwitch={timeoutSwitch}
      timeoutHours={timeoutHours}
      timeoutMinutes={timeoutMinutes}
      changeValue={this.changeValue}
      changeSwitch={this.changeBooleanSwitch}
      inputBlur={this.inputBlur}
      changeSelect={this.changeSelect}
    />
  }

  multiZoneRender = (type, hideDivider, bottomDivider) => {
    const { multiZonePreview } = this.props;
    const { isMultiZone, multiZone, cleanup, alignZoneShape, distance, fixOverlapping, createZones, cleanupLoading, enableBends } = this.state;
    return <MultiZoneOptions
      hideDivider={hideDivider}
      bottomDivider={bottomDivider}
      isMultiZone={[SIWAVE, PSI].includes(type) ? false : isMultiZone}
      multiZonePreview={multiZonePreview}
      zoneOptions={{
        multiZone,
        cleanup,
        alignZoneShape,
        distance,
        fixOverlapping,
        createZones,
        cleanupLoading,
        enableBends
      }}
      isPowerSI={type === POWERSI ? true : false}
      changeBooleanSwitch={this.changeBooleanSwitch}
      radioChange={this.radioChange}
      changeValue={this.changeValue}
      inputBlur={this.inputBlur}
      onPressEnter={this.onPressEnter}
      previewMultiZone={this.previewMultiZone}
      cleanupFn={this.cleanup}
    />
  }

  sweepConfigRender = () => {
    const { customFrequencySweep } = this.state
    return (
      <Fragment>
        <div className='cascade-top-option-panel-content'>
          <div className='cascade-sweep-content'>
            {customFrequencySweep.length > 0 ? customFrequencySweep.map((item, index) => {
              return (
                <div className='cascade-extraction-content' key={index}>
                  <Input
                    value={item.start}
                    style={{ marginRight: 10 }}
                    className='cascade-top-option-panel-content-input cascade-extraction-input-item'
                    addonAfter={this.unitSelect('start', index)}
                    onChange={(e) => this.changeSweepValue(e, 'start', index)}
                    onBlur={(e) => this.sweepInputBlur(e, 'start', index)}
                  />
                  To
                  <Input
                    value={item.end}
                    style={{ marginLeft: 10 }}
                    className='cascade-top-option-panel-content-input cascade-extraction-input-item'
                    addonAfter={this.unitSelect('end', index)}
                    onChange={(e) => this.changeSweepValue(e, 'end', index)}
                    onBlur={(e) => this.sweepInputBlur(e, 'end', index)}
                  />
                  <Select
                    value={item.type}
                    style={{ marginLeft: 18 }}
                    className='aurora-select cascade-top-option-panel-content-input cascade-extraction-select-item'
                    popupClassName='aurora-select-dropdown'
                    onChange={(e) => { this.changeSweepValue(e, 'type', index) }}
                  >
                    {samplingTypeOptions.map(option => <Option
                      key={option.name}
                      value={option.value}
                      title={option.name}
                    >{option.name}</Option>)}
                  </Select>
                  <span className='cascade-extraction-content-body cascade-extraction-select-item'>{item.type === LINEAR ? STEP : DECADE}</span>
                  {item.type === LINEAR ? <Input
                    className='cascade-top-option-panel-content-input cascade-extraction-input-item'
                    value={item.step}
                    onChange={(e) => { this.changeSweepValue(e, 'step', index) }}
                    onBlur={(e) => this.sweepInputBlur(e, 'step', index)}
                    addonAfter={this.unitSelect('step', index)}
                  /> : <Input
                    className='cascade-top-option-panel-content-input cascade-extraction-input-item'
                    value={item.step}
                    onChange={(e) => { this.changeSweepValue(e, 'step', index) }}
                    onBlur={(e) => this.sweepInputBlur(e, 'step', index)}
                  />}
                  <CloseOutlined
                    className='cascade-extraction-delete-icon'
                    onClick={(e) => this.deleteSweepRow(e, index)} />
                </div>
              );
            }) :
              <div className='cascade-extraction-empty'>No Data</div>}
          </div>
        </div>
      </Fragment>
    );
  }

  deleteSweepRow = (e, index) => {
    e.stopPropagation()
    let { customFrequencySweep } = this.state
    customFrequencySweep = customFrequencySweep.filter((item, _index) => _index !== index)
    customFrequencySweep.forEach((item, index) => {
      if (index > 0) {
        item.start = customFrequencySweep[index - 1].end
        item.units.start = customFrequencySweep[index - 1].units.end
      }
    })
    this.setState({
      customFrequencySweep
    })
  }

  addSweepRow = () => {
    let { customFrequencySweep } = this.state
    const sweepLength = customFrequencySweep.length
    const sweep = {
      start: sweepLength > 0 ? customFrequencySweep[sweepLength - 1].end : '',
      end: '',
      type: sweepLength > 0 ? LINEAR : LOG,
      step: '',
      units: {
        start: sweepLength > 0 ? customFrequencySweep[sweepLength - 1].units.end : 'Hz',
        end: sweepLength > 0 ? customFrequencySweep[sweepLength - 1].units.end : 'MHz',
        step: sweepLength > 0 ? customFrequencySweep[sweepLength - 1].units.end : 'MHz'
      }
    }
    customFrequencySweep.push(sweep)
    this.setState({
      customFrequencySweep
    })
  }

  notCreatePinGroupRender = (type) => {
    const { avoidSinglePinGroup } = this.state
    return [SIWAVE, HFSS].includes(type) ? <Fragment>
      <Divider />
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Do not create a pin group for single pin</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={avoidSinglePinGroup}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'avoidSinglePinGroup')}
        />
      </div>
    </Fragment> : null
  }

  DCRender = () => {
    const { includeDC, exactDC, type } = this.state
    return <div className='cascade-top-option-panel-content'>
      <span className='cascade-top-option-panel-content-body'>Include DC</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={includeDC || false}
        onChange={(checked) => this.changeBooleanSwitch(checked, "includeDC")}
      />
      {[SIWAVE, PSI, HFSS].includes(type) && <div className='extraction-content-sub-body'>
        <span className='cascade-top-option-panel-content-body'>Exact DC</span>
        <Switch
          size="small"
          checked={includeDC ? exactDC : false}
          className="aurora-switch-small"
          onChange={(checked) => this.changeBooleanSwitch(checked, "exactDC")}
          disabled={!includeDC}
        />
      </div>}
    </div>
  }

  portImpedanceRender = () => {
    const { REFR } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Port Impedance</span>
        <Input className='cascade-top-option-panel-content-input'
          value={REFR}
          onChange={(e) => this.changeValue(e, 'REFR')}
          onBlur={(e) => this.inputBlur(e, 'REFR')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <Divider />
    </Fragment>
  }

  sweepRender = () => {
    const { SAPD, FMAX, enableLinearSweep } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>
          Custom Frequency Sweep
          {enableLinearSweep && <PlusCircleOutlined
            className='cascade-imp-icon'
            onClick={this.addSweepRow}
            style={{ marginBottom: 3 }} />}
        </span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={enableLinearSweep || false}
          onChange={(checked) => this.changeEnableLinearSweep(checked)}
        />
      </div>
      {
        enableLinearSweep ? this.sweepConfigRender()
          : <Fragment>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body'>Samples Per Decade</span>
              <Input className='cascade-top-option-panel-content-input'
                value={SAPD}
                onChange={(e) => this.changeValue(e, 'SAPD')}
                onBlur={(e) => this.inputBlur(e, 'SAPD')}
                onPressEnter={this.onPressEnter}
              />
            </div>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body'>Maximum Frequency</span>
              <Input className='cascade-top-option-panel-content-input'
                addonAfter={this.fMaxSelectAfter()}
                value={FMAX}
                onChange={(e) => this.changeValue(e, 'FMAX')}
                onBlur={(e) => this.inputBlur(e, 'FMAX')}
                onPressEnter={this.onPressEnter}
              />
            </div>
          </Fragment>
      }
    </Fragment>
  }

  ansysRender = () => {
    const { type, discreteSweep } = this.state
    return <Fragment>
      {[SIWAVE, HFSS].includes(type) && this.ymlOptionRender()}
      <Divider />
      {this.portImpedanceRender()}
      {this.DCRender()}
      {this.sweepRender()}
      {type === PSI && this.sampRender()}
      {[SIWAVE, HFSS].includes(type) && <div className='cascade-top-option-panel-content'>
        <Divider />
        <span className='cascade-top-option-panel-content-body'>Discrete Sweep</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={discreteSweep || false}
          onChange={(checked) => this.changeBooleanSwitch(checked, "discreteSweep")}
        />
      </div>}
      <Divider />
      {this.clipRender(type)}
      {this.getRenderByType(type)}
      {this.multiZoneRender(type)}
      {this.HPCRender(type)}
      {this.timeRender()}
      {this.notCreatePinGroupRender(type)}
      {this.advancedRender()}
    </Fragment>
  }

  changePwsiTab = (value) => {
    this.setState({ pwsiTab: value })
  }

  polygonThresholdRender = () => {
    const { pwsiPolygonSimplificationThreshold } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Polygon Simplification Threshold</span>
        <Input
          className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
          addonAfter={'mm'}
          value={pwsiPolygonSimplificationThreshold}
          onChange={(e) => this.changeValue(e, 'pwsiPolygonSimplificationThreshold')}
          onBlur={(e) => this.inputBlur(e, 'pwsiPolygonSimplificationThreshold')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <Divider />
    </Fragment>
  }

  viaHandlingRender = () => {
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Via/Trace/Shape Handling</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Convert thermal traces to shapes</span>
      </div>
      {thermalTracePadParams.map(item => {
        return <div className='cascade-top-option-panel-content' key={item.key}>
          <span className='cascade-top-option-panel-content-body  cascade-extraction-panel-next-sub-span'>{item.title}</span>
          <span><Input
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            addonAfter={item.unit}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            onBlur={(e) => this.inputBlur(e, item.key)}
            onPressEnter={this.onPressEnter}
          />
          </span>
        </div>
      })}
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Identify anti-pad near shape edges and update padstack with identified anti-pad</span>
      </div>
      {viaAntipadParams.map(item => {
        return <div className='cascade-top-option-panel-content' key={item.key}>
          <span className='cascade-top-option-panel-content-body  cascade-extraction-panel-next-sub-span'>{item.title}</span>
          <span><Input
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            addonAfter={item.unit}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            onBlur={(e) => this.inputBlur(e, item.key)}
            onPressEnter={this.onPressEnter}
          />
          </span>
        </div>
      })}
      <Divider />
    </Fragment>
  }

  netCouplingRender = () => {
    const { pwsiKeepShapesOfDisabledNets, pwsiDisableCPLSimulation } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Net and Coupling</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Disabled Nets</span>
      </div>
      <div className='cascade-top-option-panel-content' >
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span'>Keep the shapes of disabled nets</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiKeepShapesOfDisabledNets}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiKeepShapesOfDisabledNets')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>Disabling nets that are not of interest will result in faster simulation, but might sometimes lead to less accurate result.</span>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>Keeping the shapes in disabled nets is a balance of the speed(and memory) and result accuracy.</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Coupled traces</span>
      </div>
      <div className='cascade-top-option-panel-content' >
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span'>Disabled coupled lines simulation</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiDisableCPLSimulation}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiDisableCPLSimulation')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>If there are lots of parallel traces very close to each other, the coupling between them might be strong.</span>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>Considering the trace coupling usually improves the result accuracy.</span>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>Note: In addition to the switch here, you also need to adjust the coupling parameters for the nets of interest in the Net Manager.</span>
      </div>
      <Divider />
    </Fragment>
  }

  specialVoidRender = () => {
    const { pwsiAutoSpecialVoid } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Special Void Settings</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Exclude the special voids from the simulation</span>
      </div>
      {specialVoidParams.map(item => {
        return <div className='cascade-top-option-panel-content' key={item.key}>
          <span className='cascade-top-option-panel-content-body  cascade-extraction-panel-next-sub-span'>{item.title}</span>
          <span><Input
            className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
            addonAfter={item.unit}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            onBlur={(e) => this.inputBlur(e, item.key)}
            onPressEnter={this.onPressEnter}
            disabled={false}
          />
          </span>
        </div>
      })}
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span'>Auto Special Void Setting</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAutoSpecialVoid}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiAutoSpecialVoid')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>The special voids are negative metal shapes that are not included in the simulation since they are too small.</span>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Set the special void criterion to reasonable number to balance the result accuracy and resource usage.</span>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Also if you see a few circuit or trace reference errors in the run time error log file, try to increase the special void size to cover more circuit nodes.</span>
      </div>
    </Fragment>
  }

  electricModelsRender = () => {
    const { pwsiTemperature, pwsiCombineOverlapAntiPadHole, pwsiEnforceCausalityInMaterial, pwsiAdvancedDiscontinuityHandling, pwsiEnhancedPadAntipad, pwsiDetectActualViaClearance } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Elecrtic Models</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Pad and AntiPad</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Use enhanced Pad/Antipad model to extract the capacitances</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiEnhancedPadAntipad}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiEnhancedPadAntipad')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-next-sub-span'>Detect actual via clearance</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiDetectActualViaClearance}
          disabled={!pwsiEnhancedPadAntipad}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiDetectActualViaClearance')}
        />
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Discontinuity Handling</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Use advanced discontinuity models for trace and via</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAdvancedDiscontinuityHandling}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiAdvancedDiscontinuityHandling')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-next-sub-span cascade-extraction-panel-note-span'>when using advanced models from above selection:</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-next-sub-span' >Combine overlapping anti-pad and holes at via location</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiCombineOverlapAntiPadHole}
          disabled={!pwsiAdvancedDiscontinuityHandling}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiCombineOverlapAntiPadHole')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-next-sub-span cascade-extraction-panel-note-span'>This option will change layout and once saved can not be reversed</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Dielectric Materials</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body  cascade-extraction-panel-next-sub-span'>Enforce Causality in material parameters</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiEnforceCausalityInMaterial}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiEnforceCausalityInMaterial')}
        />
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Set Temperature</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Temperature</span>
        <Input
          className='cascade-top-option-panel-content-input cascade-top-option-panel-content-advanced-input cascade-option-input-addonAfter'
          addonAfter='℃'
          value={pwsiTemperature}
          onChange={(e) => this.changeValue(e, 'pwsiTemperature')}
          onBlur={(e) => this.inputBlur(e, 'pwsiTemperature')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <Divider />
    </Fragment>
  }

  fieldDomainRender = () => {
    const { pwsiBoundaryCondition, pwsiCompensationWithShapeWidth, pwsiInterPlaneCoupling, pwsiAutoEnableRLGCAdjustment, calculateDCPoint, bbsFitted, pdcEqualPotential, portGenFlow, accurateMode } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Field Domain</span>
      </div>
      {/** boundary condition */}
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Boundary condition</span>
      </div>
      <Radio.Group name='pwsiBoundaryCondition' value={pwsiBoundaryCondition} onChange={(e) => this.radioChange(e, 'pwsiBoundaryCondition')} style={{ display: 'block' }}>
        <div className='cascade-top-option-panel-content'>
          <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span'><Radio value={MAGNETIC} >Magnetic wall</Radio></span>
        </div>
        <div className='cascade-top-option-panel-content'>
          <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span'><Radio value={NATURAL} >Natural boundary condition</Radio></span>
        </div>
      </Radio.Group>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body' style={{ paddingLeft: '64px' }}>Compensate for shape width</span>
        <Switch
          disabled={pwsiBoundaryCondition === MAGNETIC}
          size="small"
          className="aurora-switch-small"
          checked={pwsiCompensationWithShapeWidth}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiCompensationWithShapeWidth')}
        />
      </div>
      {/** IPC */}
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className=' cascade-extraction-panel-sub-span' >Inter-plane coupling (IPC)</span>
      </div>
      <Radio.Group name='pwsiInterPlaneCoupling' value={pwsiInterPlaneCoupling} onChange={(e) => this.radioChange(e, 'pwsiInterPlaneCoupling')} className='cascade-extraction-panel-radio-group'>
        {interPlaneCouplingParams.map(item => {
          return <div className='cascade-top-option-panel-content' key={item.key}>
            <span className='cascade-top-option-panel-content-body cascade-extraction-panel-next-sub-span'> <Radio value={item.key} key={item.key} >{item.title}</Radio></span>
          </div>

        })}
      </Radio.Group>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>When the frequency is low enough so that the skin depth is larger than the metal thickness, the field might couple through the metal shapes. This phenomenon can be captured by the inter-plane coupling formulation.</span>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Note: Including IPC in the simulation may result in heavy memory usage and long simulation time.</span>
      </div>
      {/** PowerDC Option */}
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>PowerDC Option</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Calculate DC point as reference</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={calculateDCPoint}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'calculateDCPoint')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-next-sub-span'>Passivity and causality enforcement by BroadbandSPICE</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={calculateDCPoint ? bbsFitted : false}
          disabled={!calculateDCPoint}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'bbsFitted')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-next-sub-span'>Equal Potential</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={calculateDCPoint ? pdcEqualPotential : false}
          disabled={!calculateDCPoint || accurateMode}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pdcEqualPotential')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-next-sub-span'>Enable Port Generation Analysis Flow</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={calculateDCPoint ? portGenFlow : false}
          disabled={!calculateDCPoint}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'portGenFlow')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-next-sub-span'>Enable DC Accuracy Mode</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={calculateDCPoint ? accurateMode : false}
          disabled={!calculateDCPoint}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'accurateMode')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Note: When DC point is selected as reference, frequency sweep mode will be switched to AFS to ensure better fitting.</span>
      </div>
      {/** automatic RLGC adjustment */}
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className=' cascade-extraction-panel-sub-span'>Automatic RLGC adjustment for antipad array</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Enable automatic RLGC adjustment</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAutoEnableRLGCAdjustment}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiAutoEnableRLGCAdjustment')}
        />
      </div>
      <Divider />
    </Fragment>
  }

  meshRender = () => {
    const { pwsiMaxMeshEdgeLength, pwsiMaxEdgeCheck, pwsiSimplifyGeometry, pwsiCoarseMesh } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Mesh</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-sub-span'>Maximum mesh edge length</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiMaxEdgeCheck}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiMaxEdgeCheck')}
        />
        <Input
          className='cascade-top-option-panel-content-input cascade-top-option-panel-content-mesh-input cascade-option-input-addonAfter'
          addonAfter={'mm'}
          value={!pwsiMaxEdgeCheck ? '' : pwsiMaxMeshEdgeLength}
          onChange={(e) => this.changeValue(e, 'pwsiMaxMeshEdgeLength')}
          onBlur={(e) => this.inputBlur(e, 'pwsiMaxMeshEdgeLength')}
          onPressEnter={this.onPressEnter}
          disabled={!pwsiMaxEdgeCheck}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>This is the constraint on the maximum length of the triangles in the mesh for finite element simulation in the field domain. The default value is obtained from the package size.</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Mesh quality and coarse option</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Simplify the geometry to improve mesh quality</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiSimplifyGeometry}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiSimplifyGeometry')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Coarse mesh</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiCoarseMesh}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiCoarseMesh')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>This option is used when the user would like to run the simulation with a coarser mesh which means a mesh with less nodes. The size of the mesh will automatically adjusted. The option may lead to less accurate result and is not recommended unless a normal simulation runs out of memory.</span>
      </div>
      <Divider />
    </Fragment>
  }

  referenceHandlingRender = () => {
    const { pwsiStopForTraceRefError, pwsiIgnorePwrGndNetTraces } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Reference Handling</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-sub-span'>Stop the simulation when trace reference error is encounterd</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiStopForTraceRefError}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiStopForTraceRefError')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Ignore power and ground net traces</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiIgnorePwrGndNetTraces}
          disabled={!pwsiStopForTraceRefError}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiIgnorePwrGndNetTraces')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Sometimes the simulation runs fine, but the low frequency result does not seem to be correct. Check the run time error log file to see if there are any warnings on trace reference change. If so, set the above option since these traces might be critical to this specific simulation. The option is not checked by default because the trace reference errors do not usually result in wrong answer</span>
      </div>
      <Divider />
    </Fragment>
  }

  netsAndShapesRender = () => {
    const { pwsiFilterSignalNet, pwsiNarrowGapModeling, pwsiCoplanarTracesModeling } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Nets and Shapes</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Net Options</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Filter signal nets</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiFilterSignalNet}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiFilterSignalNet')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>If there are too many signal nets in the design, those far away from the ones of interest can be turned off automatically to accelerate the simulation.</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Shape Options</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Consider coupling between field domains through narrow gaps</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiNarrowGapModeling}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiNarrowGapModeling')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Detect and model the coplanar traces coupling with field domains</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiCoplanarTracesModeling}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiCoplanarTracesModeling')}
        />
      </div>
      <Divider />
    </Fragment>
  }

  specialHandlingRender = () => {
    const { pwsiAllowNegativeElement, pwsiIgnoreAllCurrentSource, topFloating, bottomFloating, topDistance, bottomDistance,
      pwsiAFSConvergenceCriteria, pwsiMinimumLayerThicknessForDcAcUpperFrequency, pwsiReusePreFreqPts } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>Special Handling</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Special Circuit Element Support</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Allow negative capacitance/resistance</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiAllowNegativeElement}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiAllowNegativeElement')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>Using negative capacitor/resistor may lead to non-passive result.</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Ignore all current sources inside circuit models</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiIgnoreAllCurrentSource}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiIgnoreAllCurrentSource')}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-next-sub-span cascade-extraction-panel-note-span'>Add full floating patch to cover traces and shapes without reference.</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Add Top floating patch at a distance of</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={topFloating}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'topFloating')}
        />
        <Input
          className={`cascade-top-option-panel-content-input cascade-top-option-panel-content-mesh-input cascade-option-input-addonAfter`}
          addonAfter={'mm'}
          value={!topFloating ? '' : topDistance}
          onChange={(e) => this.changeValue(e, 'topDistance')}
          onBlur={(e) => this.inputBlur(e, 'topDistance')}
          onPressEnter={this.onPressEnter}
          disabled={!topFloating}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Add Bottom floating patch at a distance of</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={bottomFloating}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'bottomFloating')}
        />
        <Input
          className={`cascade-top-option-panel-content-input cascade-top-option-panel-content-mesh-input cascade-option-input-addonAfter`}
          addonAfter={'mm'}
          value={!bottomFloating ? '' : bottomDistance}
          onChange={(e) => this.changeValue(e, 'bottomDistance')}
          onBlur={(e) => this.inputBlur(e, 'bottomDistance')}
          onPressEnter={this.onPressEnter}
          disabled={!bottomFloating}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Sometimes the result does not look right at very low frequencies, for example, a short circuit becomes open. This is usually caused by the discontinuity in the parallel plate field domains. Floating planes are added automatically if field domain discontinuity is detected. However, in some situations, especially in rigid-flex multi-zone simulation, addition of a full floating patch may be needed to cover coplanar shapes and traces without reference planes.</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-extra-content-body cascade-extraction-panel-sub-span cascade-extraction-panel-note-span'>Warning: Adding a floating plane may change the high frequency results.</span>
      </div>
      <div className='cascade-top-option-panel-content-sub-title'>
        <span className='cascade-extraction-panel-sub-span'>Special Simulation Setting Support</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>AFS convergence criteria</span>
        <Input
          className={'cascade-top-option-panel-content-input cascade-top-option-panel-content-advanced-input'}
          value={pwsiAFSConvergenceCriteria}
          onChange={(e) => this.changeValue(e, 'pwsiAFSConvergenceCriteria')}
          onBlur={(e) => this.inputBlur(e, 'pwsiAFSConvergenceCriteria')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Minimum metal layer thickness required for DC-AC fit upper frequency</span>
        <Input
          className={`cascade-top-option-panel-content-input cascade-top-option-panel-content-advanced-input cascade-option-input-addonAfter`}
          addonAfter={'mm'}
          value={pwsiMinimumLayerThicknessForDcAcUpperFrequency}
          onChange={(e) => this.changeValue(e, 'pwsiMinimumLayerThicknessForDcAcUpperFrequency')}
          onBlur={(e) => this.inputBlur(e, 'pwsiMinimumLayerThicknessForDcAcUpperFrequency')}
          onPressEnter={this.onPressEnter}
        />
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-next-sub-span'>Reuse previously obtained frequency solution points</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={pwsiReusePreFreqPts}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'pwsiReusePreFreqPts')}
        />
      </div>
    </Fragment>
  }

  enfrocePassiveResultsRender = () => {
    const { autoOutputResult } = this.state
    return <Fragment>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body cascade-top-option-panel-content-title'>General</span>
      </div>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-longer-body cascade-extraction-panel-sub-span'>Automatically output enforced passive result</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={autoOutputResult}
          onChange={(checked) => this.changeBooleanSwitch(checked, 'autoOutputResult')}
        />
      </div>
    </Fragment>
  }

  basicRender = () => {
    const { type } = this.state
    return <Fragment>
      {this.ymlOptionRender()}
      <Divider />
      {this.multiZoneRender(type, true, true)}
      {this.clipRender(type)}
      {this.DCRender()}
      <Divider />
      {this.portImpedanceRender()}
      {this.sweepRender()}
      {this.HPCRender(type)}
      {this.timeRender()}
    </Fragment>
  }

  layoutRender = () => {
    return <Fragment>
      {this.polygonThresholdRender()}
      {this.viaHandlingRender()}
      {this.padShapeRender()}
    </Fragment>
  }

  simulationBasicRender = () => {
    return <Fragment>
      {this.enfrocePassiveResultsRender()}
      <Divider />
      {this.netCouplingRender()}
      {this.specialVoidRender()}
    </Fragment>
  }

  simulationAdvancedRender = () => {
    return <Fragment>
      {this.fieldDomainRender()}
      {this.electricModelsRender()}
      {this.meshRender()}
      {this.referenceHandlingRender()}
      {this.netsAndShapesRender()}
      {this.specialHandlingRender()}
    </Fragment>
  }

  pwsiRender = () => {
    const { pwsiTab } = this.state
    return <Fragment>
      <Tabs activeKey={pwsiTab} onChange={this.changePwsiTab}>
        {pwsiTabs.map(item => <TabPane tab={item.name} key={item.key} />)}
      </Tabs>
      {pwsiTab === BASIC && this.basicRender()}
      {pwsiTab === LAYOUT && this.layoutRender()}
      {pwsiTab === SIMULATION_BASIC && this.simulationBasicRender()}
      {pwsiTab === SIMULATION_ADVANCED && this.simulationAdvancedRender()}
    </Fragment>
  }

  postRender = () => {
    const { error, type, vendor } = this.state;
    const isSPD = vendor === SPD, isODBPPOrAEDB = [ODB_PP, AEDB].includes(vendor);
    const { designList = [] } = this.props;
    return <div className={`cascade-top-option-panel-main cascade-top-extraction-panel-main ${designList.length > 1 ? 'margin-top-0' : ''}`}>
      <div className='cascade-top-option-panel-content'>
        <Radio.Group onChange={(e) => this.changeAnsysType(e, type)} value={type} className='extraction-radio-group'>
          <Radio
            value={SIWAVE}
            className='extraction-radio-group-item'
            disabled={isSPD}
          >
            Ansys SIwave</Radio>
          <Radio
            value={HFSS}
            className='extraction-radio-group-item'
            disabled={isSPD}
          >
            Ansys HFSS</Radio>
          <Radio
            value={PSI}
            className='extraction-radio-group-item'
            disabled={isSPD}
          >
            Ansys SIwave PSI
            {
              isSPD && <Tooltip title={'SPD cannot invoke Ansys'} overlayClassName='aurora-tooltip'>
                <QuestionCircleOutlined className='cascade-question-icon' />
              </Tooltip>
            }
          </Radio>
          <Radio
            value={POWERSI}
            className='extraction-radio-group-item'
            disabled={isODBPPOrAEDB}
          >
            Cadence PowerSI
            {
              isODBPPOrAEDB && <Tooltip title={'ODB++,AEDB cannot invoke Cadence PowerSI'} overlayClassName='aurora-tooltip'>
                <QuestionCircleOutlined className='cascade-question-icon' />
              </Tooltip>
            }
          </Radio>
        </Radio.Group>
      </div>
      {[SIWAVE, HFSS, PSI].includes(type) && this.ansysRender()}
      {type === POWERSI && this.pwsiRender()}
      {error && <span className='cascade-top-option-panel-error-msg'>Error: {error.error}</span>}
    </div>
  }

  preRender = () => {
    const { FMAX } = this.state;
    const { designList = [] } = this.props;
    return <div className={`cascade-top-option-panel-main cascade-top-extraction-panel-main ${designList.length > 1 ? 'margin-top-0' : ''}`} style={{ minHeight: 'unset' }}>
      <div className='cascade-top-option-panel-content'>
        <span className='cascade-top-option-panel-content-body'>Maximum Frequency</span>
        <Input className='cascade-top-option-panel-content-input'
          addonAfter={this.fMaxSelectAfter()}
          value={FMAX}
          onChange={(e) => this.changeValue(e, 'FMAX')}
          onBlur={(e) => this.inputBlur(e, 'FMAX')}
          onPressEnter={this.onPressEnter}
        />
      </div>
    </div>
  }

  contentRender = () => {
    const { pcbId } = this.state;
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    return isPreLayout ? this.preRender() : this.postRender();
  }

  applyRender = () => {
    const { apply } = this.state;
    return <div className="cascade-top-option-extraction-apply">
      <Checkbox
        checked={apply}
        onChange={this.changeApply}
      />
      <span>
        Apply extraction options to all PCBs
      </span>
    </div>
  }

  render() {
    const { maxWidth, maxHeight, pcbId } = this.state;
    const { designList, view } = this.props;
    const content = (
      <Panel
        className='cascade-top-option-panel'
        title={<div className='cascade-top-option-panel-name'>Extraction Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth)}
        position='panel-center'
        mask={false}
        draggable
        minWidth={200}
        minHeight={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        footer={view === SIGN_OFF_TEMPLATE || (designList && designList.length === 1) ? null : this.applyRender()}
      >
        <div>
          {view === SIGN_OFF_TEMPLATE || (designList && designList.length === 1) ? this.contentRender() :
            <Tabs className='cascade-extraction-panel-tabs' activeKey={pcbId} onChange={this.changePCBSelect} items={designList.map(item => ({ key: item.id, label: item.name, disabled: item.disable, children: this.contentRender() }))} />
          }
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ExtractionPanel;