import { BGA, CAP, CMC, CONNECTOR, DIE, DIODE, IC, IGNORE, IND, RES } from "../../constants/componentType";
import { SortFn } from "../helper/sort";
import { splitComponentName } from "../helper/splitComponent";
import { SERDES_TYPES } from "../PCBHelper";
import { PCIE } from "../PCBHelper/constants";
import { PortSetupNegative } from "./portsSetup";
import { PortsGenerationSetup } from "./portsSetupHelper";

function deletePortSetups({
  deletedComps,
  port_setups
}) {
  let _port_setups = [...port_setups];
  for (let pin of deletedComps) {
    //find pin
    const pinIndex = _port_setups.findIndex(item => item.positive && item.positive.pin === pin.pin && item.positive.component === pin.name);
    if (pinIndex < 0) {
      continue;
    }
    //delete pin in port setups
    _port_setups.splice(pinIndex, 1);
  }
  return _port_setups;
}

function addPortSetups({
  newComps,
  port_setups,
  signals,
  pcbInfo,
  designId,
  referenceNets,
  ports_generate_setup_list,
  getDefaultPortSetupList,
  channelType,
  extractionType
}) {
  let _port_setups = [...port_setups];

  //find ic / connector components
  let _newComps = newComps.filter(item => [...SERDES_TYPES, DIE].includes(item.type));
  if (!_newComps.length) {
    return {
      port_setups: _port_setups,
      ports_generate_setup_list,
      errors: [],
      warnings: []
    };
  }

  const defaultZ0 = channelType === PCIE ? 42.5 : 50;
  const referenceZ0 = _port_setups.length ? _port_setups[0].z0 : defaultZ0;
  const avoid_single_pin_group = _port_setups.length ? (_port_setups[0].positive || {}).avoid_single_pin_group : null;

  //filter comp pins by port_setups
  let _newComps_ = [];
  for (let comp of _newComps) {
    let pins = [];
    for (let pin of comp.pins) {
      const pinIndex = _port_setups.findIndex(item => item.positive && item.positive.pin === pin.pin && item.positive.component === pin.name);
      if (pinIndex < 0) {
        pins.push({ ...pin });
      }
    }
    if (pins.length) {
      _newComps_.push({ ...comp, pins });
    }

    const compName = splitComponentName(comp.name);
    const find = ports_generate_setup_list.find(it => it.component === compName);
    if (!find) {
      ports_generate_setup_list.push({
        component: compName,
        setup: new PortsGenerationSetup({})
      })
    }
  }

  //generation new component pins ports
  const { port_setups: new_port_setups, ports_generate_setup_list: setupList, errors, warnings } = getDefaultPortSetupList({
    components: _newComps_,
    signals,
    pcbInfo,
    designId,
    referenceNets,
    ports_generate_setup_list,
    referenceZ0,
    extractionType,
    avoid_single_pin_group
  });

  _port_setups = [..._port_setups, ...new_port_setups];
  return {
    port_setups: _port_setups,
    ports_generate_setup_list: setupList,
    errors,
    warnings
  };
}

function clearPortSetups(port_setups) {
  port_setups.forEach(item => {
    item.negative = new PortSetupNegative({});
  });
  return port_setups;
}

function sortPortSetups(ports_setups, components, signals) {

  // let newPortSetups = []
  // const sortKeys = ports_setups
  //   .filter(item => item.positive && item.positive.component && item.positive.pin)
  //   .map(item => `${item.positive.component}::${item.positive.pin}`)
  //   .sort()
  //   .sort((a, b) => {
  //     const aComp = a.split("::")[0];
  //     const bComp = b.split("::")[0];
  //     if (aComp === DIE) {
  //       return -1;
  //     }
  //     if (bComp === DIE) {
  //       return 1;
  //     }
  //     return 0;
  //   })

  // for (let key of sortKeys) {
  //   const [component, pin] = key.split("::");
  //   const findPort = ports_setups.find(item => item.positive
  //     && item.positive.component === component
  //     && item.positive.pin === pin)
  //   if (findPort) {
  //     newPortSetups.push(findPort);
  //   }
  // }
  // return newPortSetups

  const sort = [IC, CONNECTOR, DIE, BGA, CMC, DIODE, CAP, IND, RES, IGNORE];
  const sortComponents = SortFn(components, sort, 'type');
  const componentNames = sortComponents.map(item => item.name);

  let newPortSetups = ports_setups.map(port => {
    return {
      ...port,
      component: port.positive.component,
      signal: port.info.signal,
      net: port.info.net
    }
  })

  const signalsPorts = []
  for (const signal of signals) {
    // const signalPorts = [];
    for (const netName of ['nets_P', 'nets_N', 'nets_A', 'nets_B', 'nets_C']) {
      if (signal[netName]) {
        const ports = newPortSetups.filter(item => item.signal === signal.name && signal[netName].includes(item.net));
        newPortSetups = newPortSetups.filter(item => !(item.signal === signal.name && signal[netName].includes(item.net)));
        if (ports.length > 0) {
          signalsPorts.push(...ports);
        }
      }
    }
    // const sortPorts = SortFn(signalPorts, componentNames, 'component');
    // signalsPorts.push(...sortPorts);
  }

  const resPorts = SortFn(signalsPorts, componentNames, 'component');

  return resPorts;
}

export {
  deletePortSetups,
  addPortSetups,
  clearPortSetups,
  sortPortSetups
}