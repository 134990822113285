import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  uploadLicensePromise, getLicensePromise, getLicenseFeaturesPromise,
  getLicenseUsagePromise, getLicenseUsageHistoryPromise, getLicenseModePromise, licenseSwitchPromise,
  checkInLicenseDirectlyPromise
} from '../../../services/License/licenseCtrl';
import { OPEN_LICENSE_PAGE, UPLOAD_LICENSE, USAGE_HISTORY_PAGE_CHANGE, USAGE_REFRESH, UPLOAD_LICENSE_MODE, CHECK_IN_CLICK } from './actionType';
import {
  updateLicenseTable, updateLicenseFeaturesTable, updateLicenseUsage, updateLicenseUsageHistory,
  updateCurrentUsageTime, updateUsageHistoryTime, updateLicenseModeAction
} from './action';
import { message } from 'antd';
import dayjs from 'dayjs';

function* openPage(action) {
  const { page } = action;
  if (page === 'management') {
    yield call(getLicense);
    yield call(getLicenseFeatures);
    yield call(getMode)
  } else if (page === 'usage') {
    yield call(getUsage);
    yield call(getUsageHistory);
  }
}

function* uploadLicense(action) {
  const { file } = action;
  try {
    yield call(uploadLicensePromise, file);
  } catch (error) {
    message.error(error);
  }
  yield call(getLicense);
  yield call(getLicenseFeatures);
}

function* uploadLicenseMode(action) {
  const { mode, url } = action;
  try {
    let res = yield call(licenseSwitchPromise, mode, url);
    yield put(updateLicenseModeAction(mode, url));
    if (res) {
      message.success("Connection success!");
      yield call(getLicense);
      yield call(getLicenseFeatures);
    } else {
      message.error("Connection fail! Please choose local mode or restart remote license server ...")
    }
  } catch (error) {
    message.error("Connection fail! Please choose local mode or restart remote license server ...")
    message.error(error);
  }
}

function* getLicense() {
  try {
    const res = yield call(getLicensePromise);
    yield put(updateLicenseTable(res));
  } catch (error) {
    console.error(error)
  }
}

function* getLicenseFeatures() {
  try {
    const res = yield call(getLicenseFeaturesPromise);
    yield put(updateLicenseFeaturesTable(res));
  } catch (error) {
    console.error(error);
  }
}


function getTime() {
  const time = new Date();
  time.setUTCSeconds(time.getUTCSeconds());
  return dayjs(time.toUTCString()).format('MM-DD HH:mm:ss');
}

function* getUsage() {
  try {
    let res = yield call(getLicenseUsagePromise);
    res.forEach(item => {
      item.checkOutTime = dayjs(item.checkOutTime).format('YYYY-MM-DD HH:mm:ss Z');
    })
    yield put(updateLicenseUsage(res));
    yield put(updateCurrentUsageTime(getTime()));
  } catch (error) {
    console.error(error);
  }
}

function* getMode() {
  try {
    let res = yield call(getLicenseModePromise);
    yield put(updateLicenseModeAction(res.mode, res.url));
  } catch (error) {
    console.error(error);
  }
}

function* getUsageHistory(action) {
  const page = action ? action.page - 1 : 0,
    size = action ? action.size : 10;
  try {
    let res = yield call(getLicenseUsageHistoryPromise, { page, size });
    res.content.forEach(item => {
      item.checkInTime = dayjs(item.checkInTime).format('YYYY-MM-DD HH:mm:ss Z');
      item.checkOutTime = dayjs(item.checkOutTime).format('YYYY-MM-DD HH:mm:ss Z');
    })
    yield put(updateLicenseUsageHistory(res))
    yield put(updateUsageHistoryTime(getTime()));
  } catch (error) {
    console.error(error)
  }
}

function* usageHistoryChange(action) {
  const { pagination } = action;
  yield call(getUsageHistory, { page: pagination.current, size: pagination.pageSize })
}



function* refreshTable(action) {
  const { table } = action;
  if (table === 'current') {
    yield call(getUsage);
  } else if (table === 'history') {
    yield call(getUsageHistory);
  }
}

function* checkInClick(action) {
  const { id } = action;
  try {
    yield call(checkInLicenseDirectlyPromise, id);
    yield call(getUsage);
  } catch (error) {
    console.error(error)
  }
}

function* licenseSaga() {
  yield takeEvery(OPEN_LICENSE_PAGE, openPage);
  yield takeLatest(UPLOAD_LICENSE, uploadLicense);
  yield takeLatest(USAGE_HISTORY_PAGE_CHANGE, usageHistoryChange);
  yield takeLatest(USAGE_REFRESH, refreshTable)
  yield takeLatest(UPLOAD_LICENSE_MODE, uploadLicenseMode);
  yield takeLatest(CHECK_IN_CLICK, checkInClick)
}

export default licenseSaga;