import React, { Component } from 'react';
import Table from '@/components/EditableTable';

const columns = [{
  title: 'Die',
  dataIndex: 'die',
  width: '33%',
}, {
  title: 'Rdie (Ω)',
  dataIndex: 'rdieValue',
  width: '33%',
}, {
  title: 'Cdie (pF)',
  dataIndex: 'cdieValue',
  width: '33%',
}]

class RdieCdieTable extends Component {

  constructor(props) {
    super(props);
    this.columns = JSON.parse(JSON.stringify(columns));
  }

  render() {
    const { tableData } = this.props;
    return <div className="cascade-result-effect-table">
      <Table
        dataSource={tableData}
        columns={this.columns}
        size="small"
        rowKey={(record, index) => { return index }}
        className="cascade-result-effect-table-main"
      />
    </div>
  }
}

export default RdieCdieTable