export const OPEN_PAGE = 'SIERRA/open_page';
export const SAVE_OPEN_PROJECT_INFO = 'SIERRA/save_open_project_info';
export const UPDATE_PROJECT_MENU = 'SIERRA/update_project_menu';
export const LIBRARY_MENU = 'SIERRA/library_menu';
export const OPEN_PROJECT = 'SIERRA/open_project';
export const PROJECT_MENU = 'SIERRA/project_menu';
export const CHANGE_LAYOUT = 'SIERRA/change_layout';
export const CLEAR_CURRENT_PROJECT = 'SIERRA/clear_current_project';
export const UPDATE_TRASH_MENU = 'SIERRA/update_trash_menu';
export const TRASH_MENU = 'SIERRA/trash_menu';
export const CHANGE_VIEW_LIST = 'SIERRA/change_view_list';
export const SAVE_COMPONENTS_NETS_IN_PROJECT = 'SIERRA/save_components_nets_in_project';
export const UPDATE_PCBS_LAYOUTDB = 'SIERRA/update_pcbs_layout';
export const UPDATE_PRELAYOUT_DATA = 'SIERRA/update_prelayout_data';
export const CHANGE_TREE_SELECTED_KEY = 'SIERRA/change_tree_selected_key';
export const SHOW_SIERRA_RESULT = 'SIERRA/show_sierra_result';
export const AUTO_GET_VERIFICATION_LIST = 'SIERRA/auto_get_verification_list';
export const UPDATE_VIEW_STATUS = 'SIERRA/update_view_status';
export const REFRESH_PCB = 'SIERRA/refresh_pcb';
export const UPDATE_CURRENT_VERIFICATION_STATUS = 'SIERRA/update_current_verification_status';
export const DOWNLOAD_DEBUG_MSG = 'SIERRA/download_debug_msg';
export const DEBUG_DOWNLOAD_LOG_UPDATE = 'SIERRA/debug_download_log_update';
export const DOWNLOAD_DEBUG_MSG_CLOSE = 'SIERRA/download_debug_msg_close';
export const UPDATE_DOWNLOAD_MSG = 'SIERRA/update_download_msg';
export const UPDATE_COPY_LIST = 'SIERRA/update_copy_list';
export const UPDATE_GENERATE_PORTS_ERRORS = `SIERRA/update_generate_ports_errors`;
export const LOAD_SWEEP_LIST = `SIERRA/load_sweep_list`;
export const SAVE_SWEEP_NAME = 'SIERRA/save_sweep_name';
export const UPDATE_PCB_LAYOUT = `SIERRA/update_pcb_layout`;
export const DELETE_PCB = 'SIERRA/delete_pcb';
export const OPEN_NEW_PAGE = "SIERRA/open_new_page";
export const UPDATE_REPORT_INFO = "SIERRA/update_report_info";
export const CLEAR_REPORT_INFO = "SIERRA/clear_report_info";
export const SAVE_REPORT_CONFIG = "SIERRA/save_report_config";
export const GET_REPORT_CONFIG = "SIERRA/get_report_config";
export const GET_REPORT = "SIERRA/get_report";
export const GET_REPORT_LOG = "SIERRA/get_report_log";

