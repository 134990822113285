const CHANNEL_TYPE = "Andes_v2";

export const CREATE_CHANNEL = `${CHANNEL_TYPE}/create_channel`;
export const GET_DESIGN_CHANNEL_LIST = `${CHANNEL_TYPE}/get_design_channel_list`;
export const DELETE_CHANNEL = `${CHANNEL_TYPE}/delete_channel`;
export const OPEN_PCB_CHANNEL = `${CHANNEL_TYPE}/open_pcb_channel`;
export const CLEAR_PCB_CHANNEL_INFO = `${CHANNEL_TYPE}/clear_pcb_channel_info`;
export const CHANNEL_RENAME = `${CHANNEL_TYPE}/channel_rename`;
export const COPY_PCB_CHANNEL = `${CHANNEL_TYPE}/copy_pcb_channel`;
export const CHANGE_CHANNEL_CREATE_VISIBLE = `${CHANNEL_TYPE}/change_channel_create_visible`;

/* content */
export const GET_CHANNEL_CONTENT = `${CHANNEL_TYPE}/get_channel_content`;
export const UPDATE_CHANNEL_INFO = `${CHANNEL_TYPE}/update_channel_info`;
export const SAVE_PCB_CHANNEL_TO_SERVER = `${CHANNEL_TYPE}/save_pcb_channel_to_server`;

/* setup */
export const UPDATE_CHANNEL_CONTENT = `${CHANNEL_TYPE}/update_channel_content`;
export const EDIT_COMPONENT_TYPE = `${CHANNEL_TYPE}/edit_component_type`;
export const ADD_SIGNAL = `${CHANNEL_TYPE}/add_signal`;
export const SAVE_SELECT_NETS = `${CHANNEL_TYPE}/save_select_nets`;
export const CHANGE_SIGNAL_NAME = `${CHANNEL_TYPE}/change_signal_name`;
export const DELETE_SIGNAL = `${CHANNEL_TYPE}/delete_signal`;
export const CHANGE_GROUP_NAME = `${CHANNEL_TYPE}/change_group_name`;
export const SAVE_CHANNEL_CONFIG = `${CHANNEL_TYPE}/save_channel_config`;
export const CHANNEL_REPLACE = `${CHANNEL_TYPE}/chanel_replace`;
export const UPDATE_CHANNEL_REPLACE_MONITOR = `${CHANNEL_TYPE}/update_channel_replace_monitor`;
export const POWER_NETS_SELECTION = `${CHANNEL_TYPE}/power_nets_selection`;
export const POWER_NET_UPDATE_VOLTAGE = `${CHANNEL_TYPE}/power_net_update_voltage`;
export const SAVE_COMP_PACKAGE_MODEL = `${CHANNEL_TYPE}/save_comp_package_model`;
export const SAVE_COMP_PCB_MODEL = `${CHANNEL_TYPE}/save_comp_pcb_model`;
export const UPDATE_MULTI_MODEL_STATUS = `${CHANNEL_TYPE}/update_multi_model_status`;
export const UPDATE_IC_COMPONENT_PIN = `${CHANNEL_TYPE}/update_ic_component_pin`;
export const UPDATE_DIODE_COMPONENT_PINS = `${CHANNEL_TYPE}/update_diode_component_pins`;

export const SAVE_CHANNEL_EXTRACTION_CONFIG = `${CHANNEL_TYPE}/save_channel_extraction_config`;

export const AUTO_GET_CHANNEL_LIST = `${CHANNEL_TYPE}/auto_get_channel_list`;

export const UPDATE_CHANNEL_NAME = `${CHANNEL_TYPE}/update_channel_name`;

export const UPDATE_REFERENCE_NETS = `${CHANNEL_TYPE}/update_reference_nets`;
export const UPDATE_PORT_SETUPS_TO_SERVER = `${CHANNEL_TYPE}/update_port_setups_to_server`;

export const OPEN_CHANNEL_LOADING = `${CHANNEL_TYPE}/open_channel_loading`;
export const UPDATE_GENERATE_PORTS_ERRORS = `${CHANNEL_TYPE}/update_generate_ports_errors`;
export const CHECK_SIGNALS = `${CHANNEL_TYPE}/check_signals`;

export const UPDATE_EXIST_RESULT_STATUS = `${CHANNEL_TYPE}/update_exist_result_status`;
export const SAVE_DESIGN_CLIP_INFO = `${CHANNEL_TYPE}/save_design_clip_info`;
export const FORCE_PREVIEW_PANEL_SHOW = `${CHANNEL_TYPE}/force_preview_panel_show`;
export const SAVE_COMP_CONNECTOR_MODEL = `${CHANNEL_TYPE}/save_comp_connector_model`;

export const SAVE_PREVIEW_STATUS = `${CHANNEL_TYPE}/save_preview_status`;
export const DELETE_PWR_GND_NET = `${CHANNEL_TYPE}/delete_pwr_gnd_net`;

/* ads */
export const UPDATE_ADS_CONFIG = `${CHANNEL_TYPE}/update_ads_config`;

export const EDIT_CMC_COMP_PINS_CONNECT = `${CHANNEL_TYPE}/edit_cmc_comp_pins_connect`;
export const SAVE_CMC_MODEL = `${CHANNEL_TYPE}/save_cmc_model`;
export const SAVE_COMP_DIODE_MODEL = `${CHANNEL_TYPE}/save_comp_diode_model`;
export const UPDATE_CHANNEL_CONNECTION = `${CHANNEL_TYPE}/update_channel_connection`;
export const UPDATE_COMPLIANCE_CONFIG = `${CHANNEL_TYPE}/update_compliance_config`;
export const UPDATE_HYBRID_STATUS = `${CHANNEL_TYPE}/update_hybrid_status`;
export const SAVE_CHANNEL_HYBRID_REGIONS = `${CHANNEL_TYPE}/save_channel_hybrid_regions`;
export const UPDATE_HYBRID_BOUNDARIES = `${CHANNEL_TYPE}/update_hybrid_boundaries`;
export const UPDATE_HYBRID_INFO = `${CHANNEL_TYPE}/update_hybrid_info`;

export const EXPAND_CHANNEL = `${CHANNEL_TYPE}/expand_channel`;
// CPHY hspice Simulation
export const CHANGE_SIMULATION_SOLVER = `${CHANNEL_TYPE}/change_simulation_solver`;
export const UPDATE_HSPICE_CONFIG = `${CHANNEL_TYPE}/update_hspice_config`;
export const UPDATE_SIMULATION_SOLVER_STATUS = `${CHANNEL_TYPE}/update_simulation_solver`;
export const UPDATE_HSPICE_CONFIG_STATUS = `${CHANNEL_TYPE}/update_hspice_config_status`;