import { GENERATE_AUTO } from "./portTableHelper";

export const PORT_SETUP_TYPE = "type",
  KEEP_RLC = "keep_rlc",
  Z0 = "z0",
  PORT_SETUP_INFO = "info",
  PORT_SETUP_POSITIVE = "positive",
  PORT_SETUP_NEGATIVE = "negative";

/* Reference type */
export const PORT_REFERENCE_PIN = "pin",
  PORT_REFERENCE_VIA = 'via',
  PORT_REFERENCE_COMPONENT = "component",
  PORT_REFERENCE_POINT = "point",
  PORT_REFERENCE_PIN_GROUP = "pin_group";

export const PORT_REFERENCE_LIST = [{
  name: "Component",
  key: PORT_REFERENCE_COMPONENT,
  types: ["gap"]
},
{
  name: "Pin",
  key: PORT_REFERENCE_PIN,
  types: ["gap", "circuit"]
}, {
  name: "Via",
  key: PORT_REFERENCE_VIA,
  types: ["gap", "circuit"]
},
{
  name: "Point",
  key: PORT_REFERENCE_POINT,
  types: ["circuit"]
}];

class PortSetup {

  constructor({
    portGenerationUsage = GENERATE_AUTO,
    type = "",
    keep_rlc = false,
    z0 = "50",
    info = {},
    positive = new PortSetupPositive({}),
    negative = new PortSetupNegative({})
  }) {
    this.portGenerationUsage = portGenerationUsage; //[ Auto, Manual, Ignore]
    this.type = type; //[circuit, gap]"
    this.keep_rlc = keep_rlc; //whether the component RLC should be kept, default = false",
    this[Z0] = z0; //float, port reference impedance
    this[PORT_SETUP_INFO] = info; //"json, product-specific port information",​
    this[PORT_SETUP_POSITIVE] = positive;
    this[PORT_SETUP_NEGATIVE] = negative;
  }
}

function PortSetupPositive({ component = "", pin = "", avoid_single_pin_group = "" }) {
  this.component = component; //"required, component name"
  this.pin = pin; //"required for signal port, pin name"
  if (avoid_single_pin_group) {
    this.avoid_single_pin_group = avoid_single_pin_group;
  }
}

class PortSetupNegative {
  // TODO - support pin_group terminal
  constructor(params = {}) {
    this.type = params.type || ""; //enum: [ pin_group, component, pin, via, point]
    this.getPortNegativeByType(params.type, params);
  }

  getPortNegativeByType = (type, params) => {
    switch (type) {
      case PORT_REFERENCE_PIN:
        return this.ReferencePinNegative(params);
      case PORT_REFERENCE_VIA:
        return this.ReferenceVIaNegative(params);
      case PORT_REFERENCE_COMPONENT:
      case PORT_REFERENCE_PIN_GROUP:
        return this.ReferenceComponentNegative(params);
      case PORT_REFERENCE_POINT:
        return this.ReferencePointNegative(params);
      default: return;
    }
  }


  ReferencePinNegative = ({ component = "", pin = "", NET = "" }) => {
    this.component = component; //if provided, reference can be defined on the component pins
    this.pin = pin;  //optinal for signal port, a single pin for reference
    this.NET = NET; //  Display frontend
  }

  ReferenceVIaNegative = ({ point_x = "", point_y = "" }) => {
    this.point_x = point_x; //string, x coordinate of a via or point
    this.point_y = point_y;  //string, y coordinate of a via or point
  }

  ReferenceComponentNegative = ({ component = "", pins = null, nets = null, PINS_INFO = null }) => {
    if (component) {
      this.component = component;
    }
    this.pins = pins;  //list of pins for the reference
    this.nets = nets;  //list of nets for the reference
    /* Display frontend */
    this.PINS_INFO = PINS_INFO; // [{ component, pin , net }]
  }

  ReferencePointNegative = (point_x = "", point_y = "", layer = "") => {
    //TODO
    this.point_x = point_x; //string, x coordinate of a via or point
    this.point_y = point_y;  //string, y coordinate of a via or point
    this.layer = layer; //string, layer name of the reference point
  }
}

export {
  PortSetup,
  PortSetupPositive,
  PortSetupNegative
}
