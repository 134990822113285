const TYPE = "Cascade/SignOffTemplate";

export const PARSE_FILE_AND_CREATE_SIGN_OFF_TASK = `${TYPE}/parse_file_and_create_sign_off_task`;
export const GET_SIGN_OFF_CONTENT = `${TYPE}/get_sign_off_content`;
export const UPDATE_SIGN_OFF_TEMPLATE_INFO = `${TYPE}/update_sign_off_template_info`;
export const CHANGE_SIGN_OFF_PCB = `${TYPE}/change_sign_off_pcb`;
export const SAVE_SIGN_OFF_VERIFICATION_ID = `${TYPE}/save_sign_off_verification_id`;
export const CREATE_AND_RUN_IMPEDANCE_TASK = `${TYPE}/create_and_run_impedance_task`;
export const UPDATE_SIGN_OFF_TEMPLATE_LIST_STATUS = `${TYPE}/update_sign_off_template_list_status`;
export const SELECT_SIGN_OFF_TASK = `${TYPE}/select_sign_off_task`;
export const UPDATE_SIGN_OFF_TASK_ERROR = `${TYPE}/update_sign_off_task_error`;
export const UPDATE_SIGN_OFF_CREATE_TASK_STATUS = `${TYPE}/update_sign_off_create_task_status`;
export const RESET_TEMPLATE_SETUP = `${TYPE}/reset_template_setup`;
export const UPDATE_TEMPLATE_ERRORS = `${TYPE}/update_template_errors`;
export const UPDATE_TEMPLATE_PCB_LOADING = `${TYPE}/update_template_pcb_loading`;
export const UPDATE_TEMPLATE_PORTS = `${TYPE}/update_template_ports`;
export const SAVE_TEMPLATE_CAP_MODEL = `${TYPE}/save_template_cap_model`;
export const UPDATE_TEMPLATE_CONTENT = `${TYPE}/update_template_content`;
export const UPDATE_TEMPLATE_SOC = `${TYPE}/update_template_soc`;
export const UPDATE_TEMPLATE_COMP_SETTING = `${TYPE}/update_template_comp_setting`;
export const UPDATE_TEMPLATE_COMPONENTS_SETTING_STATUS = `${TYPE}/update_template_components_setting_status`;
export const UPDATE_TEMPLATE_CONTENT_LOADING = `${TYPE}/update_template_content_loading`;
export const UPDATE_TEMPLATE_GND = `${TYPE}/update_template_gnd`;
export const SAVE_SIGN_OFF_CONTENT = `${TYPE}/save_sign_off_content`;
export const UPDATE_TEMPLATE = `${TYPE}/update_template`;
export const SAVE_TEMPLATE_CONFIG = `${TYPE}/save_template_config`;
export const RE_ASSIGN_TEMPLATE_DECAP_MODEL = `${TYPE}/re_assign_template_decap_model`;
export const UPDATE_MODEL_ASSIGN_LOADING = `${TYPE}/update_model_assign_loading`;
export const DOWNLOAD_TEMPLATE_S1P_FILE = `${TYPE}/download_template_s1p_file`;
export const UPDATE_CREATE_TASK_MSG = `${TYPE}/update_create_task_msg`;
export const SAVE_IMPEDANCE_VALUE = `${TYPE}/save_impedance_value`;
export const UPDATE_TEMPLATE_SETUP = `${TYPE}/update_template_setup`;
export const SAVE_TEMPLATE_VALUE = `${TYPE}/save_template_value`;
export const CHANGE_TEMPLATE_COMP_NET = `${TYPE}/change_template_comp_net`;
export const CREATE_NEW_POWER = `${TYPE}/create_new_power`;
export const DELETE_TEMPLATE_PWR_DOMAIN = `${TYPE}/delete_template_pwr_domain`;
export const ADD_TEMPLATE_POWER_DOMAIN = `${TYPE}/add_template_power_domain`;
export const UPDATE_SIGN_EXTRACTION = `${TYPE}/update_sign_extraction`;
export const SAVE_IMP_FREQ_LIST = `${TYPE}/save_imp_freq_list`;
export const DELETE_IMPEDANCE_FREQ = `${TYPE}/delete_impedance_freq`;
export const UPDATE_TEMPLATE_SETUP_VALUE = `${TYPE}/update_template_setup_value`;
export const UPDATE_TEMPLATE_STACKUP_ERROR = `${TYPE}/update_template_stackup_error`;
export const UPDATE_DCR_EXTRACTION = `${TYPE}/update_dcr_extraction`;
export const SAVE_ADDITIONAL_NETS = `${TYPE}/save_additional_nets`;
export const UPDATE_DESIGN = `${TYPE}/save_design_id`;
export const UPDATE_SIGN_OFF_TEMPLATE_LOGS = `${TYPE}/update_sign_off_tempalte_logs`;
export const SAVE_TEMPLATE_DISPLAY = `${TYPE}/save_sign_off_tempalte_display`;
export const UPDATE_TEMPLATE_COMP_TABLE_DISPLAY = `${TYPE}/update_sign_off_tempalte_comp_table_display`;
export const UPDATE_TEMPLATE_DECAP_MODEL = `${TYPE}/update_sign_off_tempalte_decap_model`;
export const UPDATE_TEMPLATE_PDF_PANEL = `${TYPE}/update_template_pdf_panel`;
export const CHOOSE_PDF_TABLE = `${TYPE}/choose_pdf_table`;