import React, { PureComponent } from 'react';
import EditableTable from '../../../../../components/EditableTable';
import { numberToScientific } from '../../../../../services/helper/dataProcess';
import { Tooltip } from 'antd';

const columns = [
  {
    title: "Signal",
    key: "signal",
    dataIndex: 'signal',
    width: "50%"
  },
  {
    title: "Capacitance",
    key: "capacitance",
    dataIndex: 'capacitance',
    width: "50%"
  }],
  segmentColumns = [
    {
      title: "Signal",
      key: "signal",
      dataIndex: 'signal',
      width: "30%"
    }, {
      title: "Segments",
      key: "segments",
      dataIndex: 'segments',
      width: "10%"
    }, {
      title: "Capacitance",
      key: "capacitance",
      dataIndex: 'capacitance',
      width: "30%"
    }, {
      title: "Total Capacitance",
      key: "total_capacitance",
      dataIndex: 'total_capacitance',
      width: "30%"
    }];
const multiColumns = [...(JSON.parse(JSON.stringify(columns)))],
  multiSegmentColumns = [...(JSON.parse(JSON.stringify(segmentColumns)))]
multiColumns.splice(1, 0, {
  title: "PCB",
  key: "pcb",
  dataIndex: 'pcb',
});
multiSegmentColumns.splice(2, 0, {
  title: "PCB",
  key: "pcb",
  dataIndex: 'pcb',
})
const unit = {
  1e-12: 'p',
  1e-9: 'n',
  1e-6: 'u',
  1e-3: 'm',
  1: ''
}
class TotalCapacitanceTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    }
  }

  componentDidMount = () => {
    this.getColumnsCell()
  }

  componentDidUpdate = (prevProps) => {
    const { hasSegment, isMulti, isUpdateData } = this.props;
    const _update = isUpdateData && isUpdateData !== prevProps.isUpdateData;

    if (hasSegment !== prevProps.hasSegment || isMulti !== prevProps.isMulti || _update) {
      this.getColumnsCell()
      if (_update) {
        this.props.updateStatus(false);
        this.props.updateCurveStatus(false);
      }
    }
  }

  getColumnsCell = () => {
    let _columns = this.getColumns();

    for (let column of _columns) {

      switch (column.dataIndex) {
        case "signal":
          column.render = (text, record) => {
            return {
              children: <Tooltip
                overlayClassName="aurora-tooltip"
                title={record.signal}>
                <div>{record.signal}</div>
              </Tooltip>,
              props: { rowSpan: record.segmentLength }
            }
          }
          break;
        case "segments":
          column.render = (text, record) => {
            return <Tooltip
              overlayClassName="aurora-tooltip"
              title={(record.comps || []).join(", ")}>
              <div>{record.segment}</div>
            </Tooltip>
          }
          break;
        case "capacitance":
          column.render = (text, record) => {
            const capacitor = numberToScientific(record.capacitance, 4, unit);
            return <span title={record.capacitance}>{capacitor ? `${capacitor}F` : ""}</span>
          }
          break;
        case "total_capacitance":
          column.render = (text, record) => {
            const capacitor = numberToScientific(record.total_capacitance, 4, unit);
            return {
              children: <span title={record.total_capacitance}>{capacitor ? `${capacitor}F` : ""}</span>,
              props: { rowSpan: record.segmentLength }
            }
          }
          break;
        default: break;
      }
    }

    this.setState({
      columns: _columns
    })
  }

  getColumns = () => {
    const { hasSegment, isMulti } = this.props;
    if (isMulti) {
      return hasSegment ? multiSegmentColumns : multiColumns;
    }
    return hasSegment ? segmentColumns : columns
  }

  render() {
    const { itemKey, title, dataList } = this.props;
    const { columns } = this.state;

    return <div className='sierra-result-content-box' key={itemKey}>
      {title && <div className='sierra-result-content-title'>
        <label className='font-bold'>{title}</label>
      </div>}
      <EditableTable
        columns={columns}
        dataSource={dataList || []}
        className='sierra-trace-capacitance-table'
        scroll={(dataList && dataList.length && dataList.length > 10) ? { y: 380 } : {}}
        size="small"
        rowKey={(record) => record.signal}
      />
    </div>
  }
}

export default TotalCapacitanceTable;