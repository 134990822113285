import {
  CONTROLLER,
  MEMORY,
  X16,
  BYTE_NETS,
  BYTE_PINS,
  CLK_NETS,
  CLK_PINS,
  DDR3_ADR,
  DDR3_CMD,
  DDR4_ADR,
  DDR4_CMD_78,
  DDR4_CMD_96,
  LPDDR4_ADR,
  LPDDR4_CMD,
  PreLayoutSignal,
  PreLayoutDiffSignal,
  CLK,
  DQS,
  PTR,
  RLCList,
  DDR_PIN,
  DefaultModelPreLayout,
  ADR,
  CMD,
} from './preLayoutConfig';
import { PreLayoutComponent, PreLayoutSignalGroup, TemplateSections, RLCSections } from '../../PreLayout/IntegratedPreLayout';
import { RESISTOR, PreLayoutPin } from '../../PreLayout';
import { DDR3, DDR3L, DDR4, LPDDR4, PACKAGES, TERMINATION_DDR_TYPES } from '../constants';
import { RES, BGA, DIE } from "@/constants/componentType";
import { unitChange } from '@/services/helper/mathHelper';
import { SortFn } from '../../helper/sort';

const bytePin = 11;
// get default pre layout
function getDefaultPreLayout(type, pcbType) {

  const comp = new PreLayoutComponent(pcbType === PACKAGES ? "BGA" : 'U1');
  comp.type = pcbType === PACKAGES ? BGA : CONTROLLER;
  // if ([LPDDR4, LPDDR5].includes(type)) {
  const component = [comp];
  let preLayoutInfo = new DefaultModelPreLayout({ type, component, pcbType });
  // } else {
  //   comp.layer = 'Top';
  //   comp.locations = { x: "0", y: "0", width: "1000", height: "1000" };
  //   const component = [comp];
  //   preLayoutInfo = new DefaultPreLayout({ type, component });
  // }
  return preLayoutInfo;
}

// add components
function addComponent(start, total) {
  let compList = [];
  for (let i = start; i < total; i++) {
    const comp = new PreLayoutComponent(`UM${i}`);
    comp.type = MEMORY;
    comp.locations = { x: "", y: "" };
    compList.push(comp);
  }
  return compList;
}

// add default signal group
function getSignals({ nets, sections, i }) {
  let signals = [];
  nets.forEach((name, index) => {
    let net = '';
    if (name.match(DQS)) {
      net = name.replace(DQS, `DQS${i}`);
    } else if (name.match('DM')) {
      net = `DM${i}`;
    } else if (name.match('DQ')) {
      net = `DQ${i * 8 + index - 1}`;
    } else {
      net = name;
    }
    const signal = name.match(DQS) || name.match(CLK) ?
      new PreLayoutDiffSignal({ name, index: String(index), sections, nets: net })
      : new PreLayoutSignal({ name, index: String(index), sections, nets: net });
    signals.push(signal);
  })
  return signals;
}

function getDefaultByteGroup(memory, width, unit) {
  let byteGroup = [];
  let _memory = memory;
  if (width === X16) {
    _memory = memory * 2;
  }
  let sections = [];
  const signal_spacing = unit ? unitChange({ num: 15, newUnit: unit, decimals: 4 }).number.toString() : "15";
  for (let i = 1; i <= 5; i++) {
    sections.push(new TemplateSections({ id: String(i), prev: i === 1 ? '' : String(i - 1), next: i === 5 ? [] : [String(i + 1)] }));
  }
  for (let i = 0; i < _memory; i++) {
    const group = `Byte${i}`;
    const signals = getSignals({ nets: BYTE_NETS, sections, i });
    const signalGroup = new PreLayoutSignalGroup({ group, signals, signal_spacing });
    signalGroup.maxLength = '5';
    byteGroup.push(signalGroup);
  }
  return byteGroup;
}

function changeByteGroup(memory, width, byteGroup, unit) {
  let newByteGroup = [...byteGroup];
  let _memory = memory;
  if (width === X16) {
    _memory = memory * 2;
  }
  let sections = byteGroup[0].signals[0].sections.map(section => {
    return new TemplateSections({ id: section.id, prev: section.prev, next: section.next });
  })
  const signal_spacing = unit ? unitChange({ num: 15, newUnit: unit, decimals: 4 }).number.toString() : "15";
  if (_memory > byteGroup.length) {
    for (let i = byteGroup.length; i < _memory; i++) {
      const group = `Byte${i}`;
      const signals = getSignals({ nets: BYTE_NETS, sections, i });
      const signalGroup = new PreLayoutSignalGroup({ group, signals, signal_spacing });
      newByteGroup.push(signalGroup);
    }
  } else {
    newByteGroup = newByteGroup.slice(0, _memory);
  }
  return newByteGroup;
}

function getDefaultCLKGroup(type, memory, unit, width) {
  let CLKGroup = [];
  let groupCLK = `CLK`, groupADR = `ADR`, groupCMD = `CMD`;
  let signalsADR = [], signalCMD = [];
  // get default sections
  const clkSections = getDefaultCLKSections(type, memory, true);
  const newSections = getDefaultCLKSections(type, memory);
  // add CLK
  let signals = getSignals({ nets: CLK_NETS, sections: clkSections });
  const signal_spacing = unit ? unitChange({ num: 15, newUnit: unit, decimals: 4 }).number.toString() : "15";
  let signalGroup = new PreLayoutSignalGroup({ group: groupCLK, signals, signal_spacing });
  signalGroup.maxLength = "0";
  CLKGroup.push(signalGroup);

  // get ADR and CMD signal
  switch (type) {
    case DDR3:
    case DDR3L:
      signalsADR = getSignals({ nets: DDR3_ADR, sections: newSections });
      signalCMD = getSignals({ nets: DDR3_CMD, sections: newSections });
      break;
    case DDR4:
      signalsADR = getSignals({ nets: DDR4_ADR, sections: newSections });
      signalCMD = getSignals({ nets: width === X16 ? DDR4_CMD_96 : DDR4_CMD_78, sections: newSections });
      break;
    case LPDDR4:
      signalsADR = getSignals({ nets: LPDDR4_ADR, sections: newSections });
      signalCMD = getSignals({ nets: LPDDR4_CMD, sections: newSections });
      break;
    default:
      break;
  }

  // add ADR
  signalGroup = new PreLayoutSignalGroup({ group: groupADR, signals: signalsADR, signal_spacing });
  CLKGroup.push(signalGroup);

  // add CMD
  signalGroup = new PreLayoutSignalGroup({ group: groupCMD, signals: signalCMD, signal_spacing });
  CLKGroup.push(signalGroup);

  return CLKGroup;
}

// get default CLK table sections
function getDefaultCLKSections(type, memory, isCLK = false) {
  let sections = [
    new TemplateSections({ id: '1', prev: '', next: ['2'] }),
    new TemplateSections({ id: '2', prev: '1', next: ['3'] }),
    new TemplateSections({ id: '3', prev: '2', next: ['4', '6'] })
  ];
  for (let i = 1; i <= memory; i++) {
    // one loop has 3 section: 
    // via: id: 4, prev: 3, next:[5 (microstrip)]
    // microstrip: id: 5, prev: 4, next:[]
    // stripline: id: 6, prev: 3, next:[7 (next via), 9 (next stripline)]
    // microstrip and via in memory
    let memoryVia = new TemplateSections({ id: String(3 * i + 1), prev: String(3 * i), next: [String(3 * i + 2)] });
    let memoryMicrostrip = new TemplateSections({ id: String(3 * i + 2), prev: String(3 * i + 1), next: [] });
    sections.push(memoryVia);
    sections.push(memoryMicrostrip);
    // stripline section
    let stripline = new TemplateSections({ id: String(3 * i + 3), prev: String(3 * i), next: i === memory ? [String(3 * (i + 1) + 1)] : [String(3 * (i + 1) + 1), String(3 * (i + 1) + 3)] });
    sections.push(stripline)
  }
  let length = sections.length;
  if (!TERMINATION_DDR_TYPES.includes(type)) {
    // last memory to termination
    sections.push(new TemplateSections({ id: String(length + 1), prev: String(length), next: [String(length + 2)] }));
    sections.push(new TemplateSections({ id: String(length + 2), prev: String(length + 1), next: isCLK ? [] : [String(length + 3)] }));
    // termination
    if (!isCLK) {
      sections.push(new TemplateSections({ id: String(length + 3), prev: String(length + 2), next: [] }));
    } else {
      sections[sections.length - 1][PTR] = "100";
    }
  }
  return sections;
}

// add pin
function getControllerPin(pins, CLKPins, memory, width) {
  let _memory = memory, pinsLength = pins.length === 0 ? 0 : pins.length / bytePin, newPinList = [...pins];
  if (width === X16) {
    _memory = memory * 2;
  }
  if (_memory > pinsLength) {
    for (let i = pinsLength; i < _memory; i++) {
      let _pinList = BYTE_PINS.map((signal, index) => {
        let pin = getNetName(signal, index, i);
        return new PreLayoutPin({ pin, net: pin, signal: signal.match(DQS) ? DQS : signal, sectionId: '1', signalGroup: `Byte${i}` })
      })
      newPinList = [...newPinList, ..._pinList];
    }
  } else {
    newPinList = [...pins.slice(0, _memory * 11)];
  }
  return [...newPinList, ...CLKPins];
}

function getMemoryPin({ name, CLKPins, width, memory, type, memoryIndex }) {
  let i = Number(name.replace('UM', ''));
  let _pinList = [];
  const _type = type === DDR3L ? DDR3 : type;
  if (width === X16) {
    _pinList = [...BYTE_PINS.map((signal, index) => {
      let net = getNetName(signal, index, i * 2)
      const pin = getPinName(signal, width, _type, i * 2);
      return new PreLayoutPin({ pin, net, signal: signal.match(DQS) ? DQS : signal, sectionId: '5', signalGroup: `Byte${i * 2}` })
    }), ...BYTE_PINS.map((signal, index) => {
      let net = getNetName(signal, index, i * 2 + 1)
      const pin = getPinName(signal, width, _type, i * 2 + 1);
      return new PreLayoutPin({ pin, net, signal: signal.match(DQS) ? DQS : signal, sectionId: '5', signalGroup: `Byte${i * 2 + 1}` })
    })]
  } else {
    _pinList = BYTE_PINS.map((signal, index) => {
      let net = getNetName(signal, index, i)
      const pin = getPinName(signal, width, _type, i);
      return new PreLayoutPin({ pin, net, signal: signal.match(DQS) ? DQS : signal, sectionId: '5', signalGroup: `Byte${i}` })
    })
  }
  let newCLKPins = [];
  for (let pin of CLKPins) {
    const pinName = getPinName(pin.net, width, _type);
    let newPin = { ...pin, pin: pinName }
    // sectionId = 4 + The number of memory + memory number * 2;
    newPin.sectionId = String(3 + 3 * memoryIndex + 2);
    newCLKPins.push(newPin);
  }
  return [..._pinList, ...newCLKPins];
}

function getCLKPins(type, width) {
  let pinList = [];
  let nets = [];
  switch (type) {
    case DDR3:
    case DDR3L:
      nets = [...CLK_PINS, ...DDR3_ADR, ...DDR3_CMD];
      break;
    case DDR4:
      nets = [...CLK_PINS, ...DDR4_ADR, ...(width === X16 ? DDR4_CMD_96 : DDR4_CMD_78)];
      break;
    case LPDDR4:
      nets = [...CLK_PINS, ...LPDDR4_ADR, ...LPDDR4_CMD];
      break;
    default:
      break;
  }
  pinList = nets.map(name => new PreLayoutPin({ pin: name, net: name, signal: name.match(CLK) ? CLK : name, sectionId: '1', signalGroup: getSignalGroup(name, type) }));
  return pinList;
}

function getSignalGroup(name, type) {
  if (CLK_PINS.includes(name)) {
    return CLK;
  }
  switch (type) {
    case DDR3:
    case DDR3L:
      return DDR3_ADR.includes(name) ? 'ADR' : 'CMD';
    case DDR4:
      return DDR4_ADR.includes(name) ? 'ADR' : 'CMD';
    case LPDDR4:
      return LPDDR4_ADR.includes(name) ? 'ADR' : 'CMD';
    default:
      break;
  }
}

function getNetName(name, index, i) {
  let pin = '';
  if (name.match(DQS)) {
    pin = name.replace(DQS, `DQS${i}`);
  } else if (name.match('DM')) {
    pin = `DM${i}`;
  } else {
    pin = `DQ${i * 8 + index - 2}`;
  }
  return pin;
}

function getPinName(name, width, type, i) {
  const index = width === X16 ? i % 2 : 0;
  let newName = name;
  if (newName.match(DQS) || newName.match('DM')) {
    newName = `${name}${index}`;
  } else if (newName.match('DQ')) {
    newName = `DQ${Number(name.replace('DQ', '')) + 8 * index}`;
  }
  const pinList = DDR_PIN[`${type}_${width}_PIN`];
  return pinList[newName];
}

// add data bus section
function addSection(index, signalGroup, components) {
  let newSignalGroup = [], templateSections = [...signalGroup[0].signals[0].sections];
  let sectionsId = templateSections.map(section => Number(section.id));
  let id = templateSections.length + 1;
  while (true) {
    if (sectionsId.includes(id)) {
      id = id + 1;
    } else {
      break;
    }
  }
  id = String(id);
  let next = templateSections.find(section => section.id === index).next;
  let _index = templateSections.findIndex(section => section.id === index) + 1;
  const newSection = new TemplateSections({ id, prev: index, next });
  for (let group of signalGroup) {
    group.signals.forEach(signal => {
      signal.sections.find(section => section.id === index).next = [id];
      for (let i of newSection.next) {
        signal.sections.find(section => section.id === i).prev = id;
      }
      signal.sections.splice(_index, 0, newSection);
    })
    newSignalGroup.push(group);
  }

  // if add in last section, change pin sectionId
  let newComponents = [...components];
  if (next && next.length === 0) {
    newComponents.forEach(comp => {
      if (comp.type === MEMORY) {
        comp.pins.forEach(pin => {
          if (pin.net.match('DQ') || pin.net.match('DM')) {
            pin.sectionId = id;
          }
        })
      }
    })
  }
  return { newSignalGroup, newComponents };
}

// del section
function delSection(id, signalGroup, components) {
  let templateSections = [...signalGroup[0].signals[0].sections]
  let findSection = templateSections.find(section => section.id === id);
  let prev = findSection.prev, next = findSection.next, newSignalGroup = [];

  // if section is first/last section, change pin sectionId
  let newComponents = [...components];
  if (!prev) {
    newComponents.forEach(comp => {
      if (comp.type === CONTROLLER) {
        comp.pins.forEach(pin => {
          if (pin.net.match('DQ') || pin.net.match('DM')) {
            pin.sectionId = next[0];
          }
        })
      }
    })
  } else if (next && next.length === 0) {
    newComponents.forEach(comp => {
      if (comp.type === MEMORY) {
        comp.pins.forEach(pin => {
          if (pin.net.match('DQ') || pin.net.match('DM')) {
            pin.sectionId = prev;
          }
        })
      }
    })
  }

  for (let group of signalGroup) {
    group.signals.forEach(signal => {
      for (let section of signal.sections) {
        let _id = section.id;
        if (_id === prev) {
          section.next = [...section.next.filter(num => num === _id), ...next];
        } else if (next.includes(_id)) {
          section.prev = prev;
        }
      }
      signal.sections = signal.sections.filter(section => section.id !== id);
    })
    newSignalGroup.push(group);
  }

  return { newSignalGroup, newComponents };
}

// get RLC section
function getRLCSection(section, type) {
  const { id, next, prev, value } = section;
  return new RLCSections({ id, type, next, prev, value });
}

// get section
function getTemplateSection(section) {
  return new TemplateSections(section);
}

// get Termination RLC Section
function getTerminationSection(section, type) {
  const { id, next, prev } = section;
  let RLCSection = new RLCSections({ id, type: "resistor", value: "30", next, prev });
  const voltage = type === DDR4 ? "0.6" : "0.75";
  return { ...RLCSection, voltage };
}

// get CLK table sections
function getDefaultSection(scrollLength, start = 1) {
  let sectionGroup = {};
  for (let i = start; i < start + scrollLength; i++) {
    let prev = String(i - 1), next = [String(i + 1)];
    if (i === 1) {
      prev = "";
    }
    if (i === scrollLength) {
      next = [];
    }
    sectionGroup[`section_template_${i}`] = new TemplateSections({ id: String(i), prev, next });
    sectionGroup[`section_length_${i}`] = '';
  }
  return sectionGroup;
}

// get each part length of CLK table
// default section: Controll - memory length: 3, memory - memory length: 1, memory - termination: 3, termination 1;
function getDefaultSectionLengthGroup(type, memory) {
  let lengthGroup = [{ name: 'U1 --> UM0', numberOfSections: '3', firstSection: '1' }];
  for (let i = 0; i < memory - 1; i++) {
    lengthGroup.push({ name: `UM${i} --> UM${i + 1}`, numberOfSections: '1', firstSection: String((i + 1) * 3 + 3) });
  }
  if (type !== LPDDR4) {
    lengthGroup.push({ name: `UM${memory - 1} --> Termination`, numberOfSections: '1', firstSection: String(3 + memory * 3) });
    lengthGroup.push({ name: `Termination`, numberOfSections: '1', firstSection: String(6 + memory * 3) });
  }
  return lengthGroup;
}

// split clk data to two part
function getCLKData({ CLKData, components, sectionLength }) {
  let CLKTableData = [], branchData = [];
  CLKData.forEach(data => {
    let signals = [...data.signals], newTableSignals = [], newBranchSignals = [];
    for (let signal of signals) {
      let startLength = 0;
      const point = compPoint(components, signal);
      let newTableSection = getCLKSectionIndex({ data, startLength, sectionLength, signal, point });
      let striplineSection = newTableSection.map(section => (section.id));
      let newBranchSection = signal.sections.filter(sec => !striplineSection.includes(sec.id));
      if (signal.name.match(CLK)) {
        const { nets_P, nets_N } = signal;
        newTableSignals.push({ ...signal, name: nets_P[0], nets: nets_P, sections: [...newTableSection], index: '0' });
        newBranchSignals.push({ ...signal, name: nets_P[0], nets: nets_P, sections: [...newBranchSection], index: '0' });
        newTableSignals.push({ ...signal, name: nets_N[0], nets: nets_N, sections: [...newTableSection], index: '1' });
        newBranchSignals.push({ ...signal, name: nets_N[0], nets: nets_N, sections: [...newBranchSection], index: '1' });
      } else {
        newTableSignals.push({ ...signal, sections: [...newTableSection] });
        newBranchSignals.push({ ...signal, sections: [...newBranchSection] });
      }
    }
    CLKTableData.push({ ...data, signals: [...newTableSignals] });
    branchData.push({ ...data, signals: [...newBranchSignals] })
  })
  return { CLKTableData, branchData }
}

function getCLKSectionIndex({ data, startLength, sectionLength, signal, point }) {
  let newTableSection = [];
  const isCLK = data.name === CLK ? true : false;
  const flybyTopology = isCLK ? sectionLength.filter((item, index) => index !== sectionLength.length - 1) : sectionLength;
  const newPoint = isCLK ? point.splice(-1, 1) : point;
  for (let i = 0; i < flybyTopology.length; i++) {
    let first = flybyTopology[i].firstSection;
    startLength = caclPrevLength(flybyTopology, i);
    let _length = i - 1 > -1 ? startLength : 1;
    let _section = signal.sections.find(section => section.id === first);
    if (_section) {
      newTableSection = findSectionIndex({ newTableSection, section: _section, length: _length, sectionLength: Number(flybyTopology[i]['numberOfSections']), signal, point: newPoint })
    }
  }
  if (isCLK) {
    const value = signal.sections.find(item => item.hasOwnProperty(PTR)) ?
      signal.sections.find(item => item.hasOwnProperty(PTR))[PTR] : "";
    const index = newTableSection.length > 0 ? newTableSection[newTableSection.length - 1].index + 1 : 0;
    newTableSection.push({ id: PTR, index, type: RESISTOR, value });
  }
  return newTableSection;
}

function caclPrevLength(flybyTopology, i) {
  return i === 0 ? 1 : Number(flybyTopology[i - 1]['numberOfSections']) + caclPrevLength(flybyTopology, i - 1);
}

function findSectionIndex({ newTableSection, section, length, sectionLength, signal, point }) {
  let tableSection = [...newTableSection];
  let _section = section, _length = length;
  _section.index = _length;
  _length = _length + 1;
  tableSection.push(_section);
  for (let i = 0; i < sectionLength - 1; i++) {
    let _next = _section.next[0];
    if (!_next || _next.length === 0 || point.includes(_section.id)) {
      break;
    }
    _section = signal.sections.find(section => section.id === _next);
    _section.index = _length;
    _length = _length + 1;
    tableSection.push(_section);
  }
  return tableSection;
}

function compPoint(components, signal) {
  return components.filter(comp => comp.type === MEMORY).map(comp => {
    // find section connect pin
    let info = comp.pins.find(pin => signal.name === CLK ? pin.net.match(signal.name) : pin.net === signal.name)
    let sectionId = info && info.sectionId ? info.sectionId : null;
    while (true) {
      const id = sectionId;
      const section = signal.sections.find(section => section.id === id);
      if (!section || section.next.length > 1) {
        break;
      }
      sectionId = section.prev;
    }
    return sectionId
  })
}

function addNewSection(data, { record, dataIndex, model }) {
  const index = Number(dataIndex.replace('section_', '')) - 1;
  const prevSection = record[`section_${index}`];
  if (prevSection) {
    const prev = prevSection.id;
    return RLCList.includes(model.type) ? getRLCSection({ id: "", prev, next: [] }, model.type)
      : new TemplateSections({ id: "", prev, ...model })
  }
  return null;
}

function createNewSection({ CLKTableData, record, dataIndex, model, newData, newCLKTableData }) {
  // add new section
  let newSection = addNewSection(CLKTableData, { record, dataIndex, model });
  if (!newSection) {
    return { newData, newCLKTableData };
  }
  const _dataIndex = Number(dataIndex.replace('section_', ''));
  newSection.id = getNewSectionId(newData, record);
  for (let signalGroup of newData) {
    if (signalGroup.name === record.groupName) {
      for (let signal of signalGroup.signals) {
        let next = signal.sections.find(section => section.id === newSection.prev).next;
        signal.sections.find(section => section.id === newSection.prev).next = [newSection.id];
        newSection.next = next;
        next.forEach(nextId => {
          const nextSection = signal.sections.find(section => section.id === nextId);
          nextSection.prev = newSection.id;
          if (nextSection.index) {
            nextSection.index++;
          }
        })
        signal.sections.push(newSection);
      }
    }
  }
  newSection.index = _dataIndex;
  for (let newCLKData of newCLKTableData) {
    if (newCLKData.name === record.groupName) {
      for (let sig of newCLKData.signals) {
        sig.sections.push(newSection);
      }
    }
  }
  return { newSection, newData, newCLKTableData };
}

function getNewSectionId(data, record) {
  const signals = data.find(d => d.name === record.groupName).signals;
  const sections = record.groupName === CLK ? signals[0].sections.map(section => section.id)
    : signals.find(signal => signal.name === record.name).sections.map(section => section.id);
  let id = sections.length;
  while (id + 1) {
    if (!sections.includes(String(id))) {
      return String(id);
    }
    id = id + 1;
  }
}

function _updateLayer({ content, key, value, isContent }) {
  if (isContent) {
    content.components.forEach(comp => {
      if (comp.type === key) {
        comp.layer = value;
      }
    })
  } else {
    content.forEach(comp => {
      comp.layer = value;
    })
  }
  return content;
}

function calculateDistance(c, w, x, y) {
  const _x = Math.abs(x), _y = Math.abs(y);
  if (_x === 0) {
    return _y - w;
  } else if (_y === 0) {
    return _x - c;
  } else {
    // Slope
    const k = _y / _x;
    let x1, y1;
    if (c * k > w) {
      x1 = w / k;
      y1 = w;
    } else {
      x1 = c;
      y1 = k * c;
    }
    // Find the length between two points
    return Math.sqrt((_x - x1) * (_x - x1) + (_y - y1) * (_y - y1));
  }
}

function calculateMemoryDiscantance(index, memorys) {
  const location_1 = memorys.find(m => m.name === `UM${index - 1}`);
  const location_2 = memorys.find(m => m.name === `UM${index}`);
  const x1 = Number(location_1.locations.x), y1 = Number(location_1.locations.y),
    x2 = Number(location_2.locations.x), y2 = Number(location_2.locations.y);
  // Find the length between two points
  return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
}

function sectionWithInput(section, value) {
  let newSection = { ...section }
  if (RLCList.includes(newSection.type)) {
    newSection.value = value;
  } else {
    newSection.length = value;
  }
  return newSection;
}

// change all length in one section
function changeWholeSectionLength({ data, section, value, type, changeType, name, model, withEmpty = false }) {
  let newData = [...data];
  newData.forEach(group => {
    if (changeType === 'all' || (changeType === 'same' && name === group.name)) {
      group.signals.forEach(signal => {
        let index = signal.sections.findIndex(sec => sec.index === section.index);
        if (!index) {
          index = signal.sections.findIndex(sec => sec.id === section.id);
        }
        const current = signal.sections[index];
        if (current && current.type === type) {
          signal.sections[index] = sectionWithInput(signal.sections[index], value);
        } else if (current && !current.type && withEmpty) {
          const { id, next, prev, index: currentIndex } = current;
          signal.sections[index] = { id, next, prev, ...model };
          if (currentIndex >= 0) {
            signal.sections[index].index = currentIndex;
          }
          signal.sections[index] = sectionWithInput(signal.sections[index], value);
        }
      })
    }
  })
  return newData;
}

// change current length in one section
function changecurrentSectionLength(data, section, value, groupName, name) {
  let newData = data ? [...data] : [];
  let _group = newData.find(group => group.name === groupName);
  if (name.match(CLK)) {
    let signals = _group ? _group.signals : [];
    signals.forEach(signal => {
      if (section && section.id === PTR) {
        if (signal.sections.find(section => section.hasOwnProperty(PTR))) {
          signal.sections.find(section => section.hasOwnProperty(PTR))[PTR] = value;
        } else if (signal.sections.find(section => section.id === PTR)) {
          signal.sections.find(section => section.id === PTR).value = value;
        }
      }
      let index = section ? signal.sections.findIndex(item => item.id === section.id) : -1;
      if (index >= 0) {
        signal.sections[index] = sectionWithInput(signal.sections[index], value);
      }
    })
  } else {
    let signal = _group ? _group.signals.find(signal => signal.name === name) : {};
    let index = signal && signal.sections && section ? signal.sections.findIndex(item => item.id === section.id) : -1;
    if (index >= 0) {
      signal.sections[index] = sectionWithInput(signal.sections[index], value);
    }
  }
  return newData;
}

function setCLKTableTemplate({ newData, record, model, sectionId, voltage = null }) {
  for (let signalGroup of newData) {
    if (signalGroup.name === record.groupName) {
      for (let signal of signalGroup.signals) {
        let index = signal.sections.findIndex(item => item.id === sectionId);
        if (index !== -1) {
          let _index = signal.sections[index].index ? signal.sections[index].index : -1;
          signal.sections[index] = RLCList.includes(model.type) ?
            getRLCSection(signal.sections[index], model.type) : getTemplateSection({ ...signal.sections[index], ...model });
          if (voltage) {
            signal.sections[index] = { ...signal.sections[index], voltage }
          }
          if (_index !== -1) {
            signal.sections[index].index = _index;
          }
        }
      }
    }
  }
  return newData;
}

// delete byte section
function deleteSections(deleteId, sections) {
  let newSections = [];
  const section = sections.find(section => section.id === deleteId);
  if (section) {
    for (let sec of sections) {
      if (section.prev === sec.id) {
        sec.next = section.next;
      }
      if (section.next.includes(sec.id)) {
        sec.prev = section.prev
      }
      if (sec.id !== section.id) {
        newSections.push(sec);
      }
    }
  } else {
    newSections = [...sections];
  }
  return newSections;
}

function prelayoutLibraryCheck(section, traceList, viaList) {
  let list = null, title = null;
  if (section) {
    if (section.type && section.template) {
      if (section.type === 'via') {
        list = viaList;
      } else if (section.type === 'trace') {
        list = traceList;
      }
      title = list && list.findIndex(item => item.id === section.libId) < 0 ? `File ${section.template} not find.` : null;
    } else if ((section.length || section.value) && (!section.type || (!RLCList.includes(section.type) && !section.libId))) {
      title = `Template is not set.`;
    }
  }
  return title;
}

function modelPreLayoutContentJson({ components = [], model = {}, memory = 1, type, prelayout, topology, width, numberOfChannel = 1, packageId, includeRes = false }) {
  this.components = components;
  this.model = model;
  this.memory = memory;
  this.type = type;
  this.prelayout = prelayout;
  this.topology = topology;
  this.width = width;
  this.numberOfChannel = numberOfChannel;
  this.packageId = packageId;
  this.includeRes = includeRes
}

const COMP_HEIGHT = 100,
  RES_WIDTH = 120, REPEATER_WIDTH = 200, TEST_WIDTH = 150,
  GROUP_RES_DISTANCE = 20, CLK_TOP = 14;

const SIGNAL_DISTANCE = 40, CANVAS_PADDING = 20, SIGNAL_TO_COMP = 50,
  COMP_PADDING = 70, SIGNAL_COMP_WIDTH = 50, COMP_NAME_PADDING = 65, RES_HEIGHT = 25, PIN_NAME_TOP = 15,
  PORT_DISTANCE = 8, SIGNAL_WIDTH = 110, COMP_WIDTH = 160, COMP_DISTANCE = 30, COMP_TO_COMP = 40, CAP_WIDTH = 20;

function getCanvasCompHeight(comp, signals) {
  switch (comp.type) {
    case RES:
      return RES_HEIGHT;
    case CONTROLLER:
    case MEMORY:
    case BGA:
    case DIE:
    default:
      return 2 * COMP_PADDING + (signals.length - 1) * SIGNAL_DISTANCE;
  }
}

function calcPortsLocation(components, interfaceType) {
  let filterNets = components.filter(item => [CONTROLLER, MEMORY, DIE, BGA].includes(item.type));
  const sort = [CONTROLLER, MEMORY, DIE, BGA];
  filterNets = SortFn(filterNets, sort, 'type');
  let ports = [];
  let startX = CANVAS_PADDING;
  let memoryCompIndex = 0;
  for (let comp of filterNets) {
    const { type, pins, name, value } = comp;
    if ([MEMORY, BGA].includes(type)) { memoryCompIndex++ }
    let signalY = CANVAS_PADDING + COMP_PADDING - 10 - PIN_NAME_TOP;
    for (let pin of pins) {
      let endY = signalY + CLK_TOP;
      let signalX = [MEMORY, BGA].includes(type) ? startX + 35 : startX + COMP_WIDTH - 35;
      ports.push({ comp: name, value, ...pin, x: signalX, y: endY })
      signalY = signalY + SIGNAL_DISTANCE;
    }
    startX = startX + COMP_WIDTH + (memoryCompIndex > 0 ? COMP_TO_COMP : SIGNAL_WIDTH);
  }

  let resStartY = [CLK, ADR, CMD].includes(interfaceType) ? CANVAS_PADDING + COMP_PADDING + CLK_TOP : CANVAS_PADDING + COMP_PADDING;
  const resComps = components.filter(item => [RES].includes(item.type))
  for (let resComp of resComps) {
    const { pins, name, value } = resComp;
    let resStartX = startX + 30;
    let endY = resStartY - 10;
    for (let pin of pins) {
      if (pin.net === pin.signal) {
        ports.push({ comp: name, value, ...pin, x: resStartX, y: endY })
      }
    }
    resStartY = resStartY + SIGNAL_DISTANCE
  }

  return ports
}

function calcAddIconLocation(components, signals) {
  let addIconLocation = [];
  const memoryComp = components.filter(item => item.type === MEMORY || item.type === BGA);
  const memoryCompLength = memoryComp.length;
  if (memoryCompLength > 1) {
    const resComp = components.filter(item => item.type === RES);
    let signalX = CANVAS_PADDING + COMP_WIDTH + SIGNAL_WIDTH + memoryCompLength * (COMP_WIDTH + COMP_TO_COMP) + 24,
      signalY = CANVAS_PADDING + COMP_PADDING;
    for (let signal of signals) {
      const findConnectRes = resComp.find(item => item.pins.find(it => it.signal === signal));
      if (!findConnectRes) {
        let endY = signalY - 15
        addIconLocation.push({ signal, x: signalX, y: endY })
      }
      signalY = signalY + SIGNAL_DISTANCE
    }
  }
  return addIconLocation
}

const BYTE = 'Byte';
function byteSort(a, b) {
  if (a.startsWith(BYTE) && b.startsWith(BYTE)) {
    const aSplitValue = a.split(BYTE);
    const bSplitValue = b.split(BYTE);
    const aIndex = aSplitValue && aSplitValue.length && aSplitValue[1] ? aSplitValue[1] : 0;
    const bIndex = bSplitValue && bSplitValue.length && bSplitValue[1] ? bSplitValue[1] : 0;
    return aIndex - bIndex;
  } else {
    var order = [BYTE, CLK, ADR, CMD];
    const aIndex = order.findIndex(item => a.includes(item));
    const bIndex = order.findIndex(item => b.includes(item));
    return aIndex - bIndex
  }
}

export {
  getDefaultPreLayout,
  addComponent,
  getDefaultByteGroup,
  getDefaultCLKGroup,
  getControllerPin,
  getMemoryPin,
  getCLKPins,
  addSection,
  delSection,
  changeByteGroup,
  getRLCSection,
  getDefaultSection,
  getDefaultSectionLengthGroup,
  getCLKData,
  createNewSection,
  getNewSectionId,
  compPoint,
  _updateLayer,
  calculateDistance,
  calculateMemoryDiscantance,
  getTerminationSection,
  changeWholeSectionLength,
  sectionWithInput,
  changecurrentSectionLength,
  getTemplateSection,
  setCLKTableTemplate,
  deleteSections,
  prelayoutLibraryCheck,
  modelPreLayoutContentJson,
  SIGNAL_DISTANCE,
  CANVAS_PADDING,
  SIGNAL_TO_COMP,
  COMP_HEIGHT,
  COMP_PADDING,
  RES_WIDTH,
  SIGNAL_COMP_WIDTH,
  REPEATER_WIDTH,
  TEST_WIDTH,
  PORT_DISTANCE,
  GROUP_RES_DISTANCE,
  getCanvasCompHeight,
  SIGNAL_WIDTH,
  COMP_WIDTH,
  COMP_DISTANCE,
  COMP_TO_COMP,
  COMP_NAME_PADDING,
  RES_HEIGHT,
  PIN_NAME_TOP,
  calcPortsLocation,
  calcAddIconLocation,
  CAP_WIDTH,
  byteSort,
  CLK_TOP
}