import { CARD } from '../constants';
import { getChannelJson, getChannelJsonByDesignId } from '../projectCtrl';

class PCBInterfacesInfo {
  constructor() {
    this.getPCBInterfaces.bind(this);
    this.getPowerComponentsInfo.bind(this);
    this.deletePCBInterfaces.bind(this);
  }

  getPCBInterfaces(id, channels, type) {
    //id -> projectId, cardDesignId
    return new Promise((resolve, reject) => {
      if (id) {
        const interfacesInfo = this[id] && Object.keys(this[id]).length > 1 ? this[id] : null;
        if (interfacesInfo) {
          resolve(interfacesInfo);
        } else {
          let Info = { id };
          const getJsonFn = type === CARD ? getChannelJsonByDesignId : getChannelJson;
          getJsonFn(id).then(fileContent => {
            if (fileContent) {
              const InterfaceList = fileContent.InterfaceList ? fileContent.InterfaceList : [];
              const _sortChannels = channels.map(channel => {
                return { ...channel }
              })
              let _sortInterfaceListName = InterfaceList.sort((a, b) => {
                return a.Name.localeCompare(b.Name);
              }).map(item => {
                return { ...item }
              });
              resetInterfaceListName(_sortInterfaceListName);
              _sortInterfaceListName.forEach(item => {
                const name = item.Name;
                const shortName = item.shortName;
                const channel = _sortChannels.find(it => [shortName,name].includes(it.name));
                const channelId = channel ? channel.id : null;
                if (channelId) {
                  const VTTNets = Array.isArray(item.VTTNets) ? item.VTTNets.filter(it => !!it) : [];
                  Info[channelId] = { ...item, VTTNets };
                }
              });
              this[id] = Info ? JSON.parse(JSON.stringify(Info)) : null;
              resolve(Info);
            } else {
              resolve(null);
            }
          }, error => {
            console.error(error);
            resolve(null);
          })
        }
      }
    })
  }

  getPowerComponentsInfo(id, channelId) {
    return new Promise((resolve, reject) => {
      const interfacesInfo = this[id] ? this[id] : null;
      if (interfacesInfo) {
        let currentChannel = interfacesInfo[channelId] ? interfacesInfo[channelId] : {};
        if (!currentChannel) {
          resolve({});
        }
        const { PowerComponents, GroundNets, PowerNets, VTTNets } = currentChannel;
        let powerComponentInfo = {
          PowerComponents,
          GroundNets,
          PowerNets,
          VTTNets
        };
        resolve(powerComponentInfo)
      } else {
        resolve({})
      }
    })
  }

  deletePCBInterfaces(id) {
    if (this[id]) {
      delete this[id]
    }
  }

}

function resetInterfaceListName(InterfaceList) {
  let map = {};
  map['A'] = 1;
  map['B'] = 1;
  InterfaceList.forEach(item => {
    let name = item.Name;
    let nameList = name.split('_');
    let suffix = nameList[0] + '_' + nameList[1];
    if (name.includes('A')) {
      item.shortName = suffix + '_A' + map['A'];
      map['A']++;
    } else if (name.includes('B')) {
      item.shortName = suffix + '_B' + map['B'];
      map['B']++;
    }
  })
}

const Info = new PCBInterfacesInfo();
export default Info;
