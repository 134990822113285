import React, { Component, Fragment } from "react";
import Panel from "@/components/Panel";
import { createPortal } from 'react-dom';
import { CloseCircleFilled, CloseOutlined, CopyOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Select, Input, Popover } from "antd";
import TreeSelect from '@/components/TreeSelect';
import sierraLibrary from "../../../../services/Sierra/library/libraryStorage";
import { PART_REPEATER_LIBRARY, REPEATER } from "../../../../constants/libraryConstants";
import { getFilesParseList, getPartLibraryListBySearch, getRepeaterModelNodes } from "../../../../services/Sierra/library";
import { strDelimited } from "../../../../services/helper/split";
import { getDefaultIndex } from "../../../../services/helper/setDefaultName";
import { getPkgPinListWidth } from "../../../../services/helper/packageHelper";
import NodeSelection from "../../../../components/pinNodeConnect/nodeSelection";
import { strFormatChange } from "../../../../services/helper/stringHelper";
import '../../../../publicCss/netlist.css';
import "./index.css";

const { Option } = Select;
class RepeaterModel extends Component {
  constructor(props) {
    super(props);
    /*    model: {
    files: [{ type: "Repeater", version: "1", folder: "", fileName: "repeater1.sp", subckt: "subckt1", libraryId: "12" },
    { type: "Repeater", version: "1", folder: "", fileName: "repeater1.sp", subckt: "subckt2", libraryId: "12" },
    { type: "Repeater", version: "1", folder: "", fileName: "repeater2.sp", subckt: "subckt1", libraryId: "13" }],
    pairs: [
      { pin: "1", node: "rep1", libraryId: "12", fileName: "repeater1.sp", subckt: "subckt1", modelKey: "1" },
      { pin: "2", node: "rep2", libraryId: "12", fileName: "repeater1.sp", subckt: "subckt1", modelKey: "1" },
      { pin: "3", node: "rep1", libraryId: "12", fileName: "repeater1.sp", subckt: "subckt2", modelKey: "2" },
      { pin: "4", node: "rep2", libraryId: "12", fileName: "repeater1.sp", subckt: "subckt2", modelKey: "2" },
      { pin: "5", node: "rep1", libraryId: "13", fileName: "repeater2.sp", subckt: "subckt1", modelKey: "3" },
      { pin: "6", node: "rep2", libraryId: "13", fileName: "repeater2.sp", subckt: "subckt1", modelKey: "3" }
    ]
  } */
    this.state = {
      files: [],
      pinList: [],
      subcktList: [],
      fileList: [],
      search: "",
      selectFile: "",
      editNode: ""
    }
    this.dialogRoot = document.getElementById('root');
  }

  saveModel = () => {
    const { files, pinList } = this.state;
    const pairs = pinList.map(item => { return [item.input, item.output] }).flat(2).filter(item => !!item.pin);
    const { partNumber, position } = this.props;
    this.props.saveModelInfo({
      partNumber,
      model:
      {
        files,
        pairs,
      },
      position
    })
    this.props.save();
  }

  componentDidMount = () => {
    this.setDefaultData()
    this.getFileList()
  }

  componentDidUpdate = (prevProps) => {
    const { libraryTreeInfo } = this.props;
    if ((prevProps.partNumber !== this.props.partNumber) || prevProps.position !== this.props.position) {
      this.setDefaultData()
    }
    if (libraryTreeInfo
      && libraryTreeInfo.length && (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length)
      && (libraryTreeInfo.includes(REPEATER))) {
      this.getFileList()
    }
  }

  getFileList = () => {
    this.setState({
      fileList: sierraLibrary.getTree(REPEATER)
    })
  }

  setDefaultData = async () => {
    const { record: { model, connections, partNumber, position } } = this.props;
    let _model = model && typeof (model) === "object" ? model : { pairs: [], files: [] }
    if (!connections || !connections.length) {
      this.setState({
        files: _model.files || [],
        pinList: []
      })
      return;
    }

    //init model files libraryId
    let files = [...(_model.files || [])];
    for (let item of files) {

      if (item.libraryId) {
        continue;
      }
      const partList = await getPartLibraryListBySearch({ partNumbers: [partNumber], type: PART_REPEATER_LIBRARY });
      const findPart = partList.length > 1 ? partList.find(it => it.position === position) : partList[0];
      const findModel = findPart && findPart.model ? (findPart.model.files || []).find(it => it.fileName === item.fileName) : null;
      if (!findModel) {
        continue;
      }

      item.libraryId = findModel.libraryId;
    }

    let pinList = [];
    for (let pinItem of connections) {
      if ((!pinItem.input || !pinItem.input.length) && (!pinItem.output || !pinItem.output.length)) {
        continue;
      }
      const findInput = (_model.pairs || []).find(item => item.pin === pinItem.input[0]) || {};
      const findOutput = (_model.pairs || []).find(item => item.pin === pinItem.output[0]) || {};

      pinList.push({
        input: {
          pin: pinItem.input[0] || "",
          node: findInput.node || "",
          fileName: findInput.fileName || "",
          modelKey: findInput.modelKey || "",
          subckt: findInput.subckt || "",
          libraryId: findInput.libraryId || ""
        },
        output: {
          pin: pinItem.output[0] || "",
          node: findOutput.node || "",
          fileName: findOutput.fileName || "",
          modelKey: findOutput.modelKey || "",
          subckt: findOutput.subckt || "",
          libraryId: findOutput.libraryId || ""
        }
      })
    }
    this.setState({
      pinList,
      files: _model.files || []
    }, () => {
      this.getFileParse(this.state.files)
    })
  }

  selectModelFile = (file, fileIndex) => {
    const { files } = this.state;
    let delFile = fileIndex > -1 ? files[fileIndex] : null;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    const isFolder = file.childFile ? true : false;
    const newFile = {
      folder: isFolder ? file.name : "",
      fileName: isFolder ? file.childFile : file.name,
      libraryId: file.id,
      subckt: ""
    }
    if (fileIndex > -1) {
      _files[fileIndex] = newFile;
    } else {
      _files.push(newFile);
    }
    this.setNewFile(newFile, delFile, _files);
  }

  setNewFile = (newFile, delFile, _files) => {
    const { portsObj, subcktList } = this.state;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    //delete prev select file portsObj and subcktList
    if (delFile && delFile.libraryId && _portsObj[delFile.libraryId]) {
      const findList = _files.filter(item => item.libraryId === delFile.libraryId);
      if (!findList.length) {
        //delete subckt
        _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
      }
    }
    this.setState({
      files: _files,
      subcktList: _subcktList,
      errorMsg: null
    }, () => {
      this.clearPinNode({ delFile, newFile });
    });
  }

  clearPinNode = (params) => {
    const { newFile, delFile, newSubckt, file } = params || {}
    const { pinList, subcktList, files } = this.state;
    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : this.getPins();

    for (let i = 0; i < _pinList.length; i++) {
      let item = _pinList[i];
      if (!item.input && !item.output) {
        continue;
      }
      if (!item.input.node && !item.output.node) {
        continue;
      }
      //params is undefined,clear all nodes
      //delFile exist, clear delFile selected nodes
      //input
      if (!params || (delFile
        && delFile.libraryId === item.input.libraryId
        && delFile.fileName === item.input.fileName
        && delFile.subckt === item.input.subckt)) {
        item.input.node = "";
        item.input.libraryId = "";
        item.input.fileName = "";
        item.input.subckt = "";
        item.input.modelKey = "";
      }
      //params is undefined,clear all nodes
      //delFile exist, clear delFile selected nodes
      if (!params || (delFile
        && delFile.libraryId === item.output.libraryId
        && delFile.fileName === item.output.fileName
        && delFile.subckt === item.output.subckt)) {
        item.output.node = "";
        item.output.libraryId = "";
        item.output.fileName = "";
        item.output.subckt = "";
        item.output.modelKey = "";
      }
      _pinList[i] = item;
    }


    if (newSubckt && file) {
      const findFile = subcktList.find(item => item.libraryId === file.libraryId && item.fileName === file.fileName) || {};
      const findModel = (findFile.models || []).find(it => it.name === newSubckt);
      if (files && files.length === 1 && findModel && findModel.ports && findModel.ports.length === 2) {
        _pinList = this.setDefaultNodeToPinList(_pinList, findModel.ports, { ...file, subckt: newSubckt }, true);
      }
    }
    this.setState({
      pinList: _pinList
    }, () => {
      if (newFile && newFile.libraryId) {
        this.getFileParse([newFile], true);
      }

    })
  }


  getFileParse = (files, isNew = false) => {
    if (!files || files.length === 0) {
      return;
    }
    //filter libraryId not exist files
    const _files = files.filter(item => !!item.libraryId);
    const { pinList, subcktList, files: stateFiles } = this.state;
    let _subcktList = [...subcktList],
      _pinList = [...pinList];
    //get files ports
    getFilesParseList(files).then(res => {
      if (res && Array.isArray(res)) {
        _files.forEach(file => {
          const libraryId = file.libraryId,
            folder = file.folder,
            fileName = file.fileName;
          let models = [], subIndex = -1;
          const currentFileInfo = res.find(item => item.libraryId === file.libraryId);
          if (folder) {
            //folder
            const folderFiles = currentFileInfo && Array.isArray(currentFileInfo.files) ? [...currentFileInfo.files] : [];
            const currentFile = Array.isArray(folderFiles) ? folderFiles.find(item => item.fileName === fileName) : {};
            models = currentFile && Array.isArray(currentFile.models) ? [...currentFile.models] : [];
            subIndex = _subcktList.findIndex(it => it.libraryId === libraryId && it.fileName === fileName);
          } else {
            //file
            models = currentFileInfo && Array.isArray(currentFileInfo.models) ? [...currentFileInfo.models] : [];
            subIndex = _subcktList.findIndex(it => it.libraryId === libraryId);
          }

          if (subIndex > -1) {
            _subcktList[subIndex] = {
              libraryId,
              fileName: file.fileName,
              models
            }
          } else {
            _subcktList.push({
              libraryId,
              fileName: file.fileName,
              models
            });
          }

          if (isNew || !file.subckt || !models.find(it => it.name === file.subckt)) {
            file.subckt = models[0] ? models[0].name : "";
          }

          //file has one subckt and only two nodes
          const findModel = models.find(it => it.name === file.subckt);
          if (stateFiles.length === 1 && _files.length && findModel && findModel.ports && findModel.ports.length === 2) {
            _pinList = this.setDefaultNodeToPinList(_pinList, findModel.ports, file, isNew);
          }
        });

        this.setState({
          pinList: _pinList,
          subcktList: _subcktList
        })
      }
    })
  }

  setDefaultNodeToPinList = (_pinList, ports, file, isNew) => {
    let inNode = "", outNode = "";
    const findSelect = _pinList.find(it => it.input && it.output);
    if (findSelect && !isNew) {
      inNode = ports.includes(findSelect.input.node) ? findSelect.input.node : "";
      outNode = ports.includes(findSelect.output.node) ? findSelect.output.node : "";
    }

    if (!inNode && !outNode) {
      const nodeInfo = getRepeaterModelNodes([{ name: file.subckt, ports: ports }]);
      inNode = nodeInfo.inNode;
      outNode = nodeInfo.outNode;
    }

    let modelKeys = [], modelKey = "1";
    for (let item of _pinList) {
      if (!item.input || !item.output) {
        continue;
      }
      modelKey = getDefaultIndex(modelKey, modelKeys);

      item.input.node = inNode;
      item.input.libraryId = file.libraryId;
      item.input.fileName = file.fileName;
      item.input.subckt = file.subckt;
      item.input.modelKey = modelKey;

      item.output.node = outNode;
      item.output.libraryId = file.libraryId;
      item.output.fileName = file.fileName;
      item.output.subckt = file.subckt;
      item.output.modelKey = modelKey;

      modelKeys.push(modelKey)
    }
    return _pinList
  }

  deleteFileSelect = (type, fileIndex, deleteLine) => {
    const { files, subcktList } = this.state;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let _subcktList = [...subcktList];
    if (fileIndex < 0) {
      return;
    }

    //delete file or delete subckt file
    const delFile = _files.find((item, index) => index === fileIndex);
    if (delFile) {
      //delete current file select node
      this.clearPinNode({ delFile: { ...delFile } });
    }
    if (type !== "Subckt") {

      if (delFile && delFile.libraryId) {
        const otherFiles = _files.find((item, index) => index !== fileIndex && item.libraryId === delFile.libraryId);
        if (!otherFiles) {
          _subcktList = _subcktList.filter(item => !(item.libraryId === delFile.libraryId && item.fileName === delFile.fileName));
        }
      }
      if (deleteLine) {
        //delete current file line
        _files = _files.filter((item, index) => index !== fileIndex);
      } else {
        //delete current file
        _files[fileIndex] = {
          type: "",
          folder: "",
          libraryId: "",
          fileName: "",
          subckt: ""
        };
      }
      this.setState({
        files: _files,
        subcktList: _subcktList,
        errorMsg: null
      })
    } else {
      //delete current file subckt
      _files[fileIndex].subckt = "";
      this.setState({
        files: _files,
        errorMsg: null
      })
    }
  }

  addNewFileSelect = (e) => {
    e && e.stopPropagation();
    const { files } = this.state;
    let _files = [...files];
    const file = {
      libraryId: "",
      fileName: "",
      folder: "",
      subckt: ""
    }
    if (!_files.length) {
      _files.push({ ...file });
    }
    _files.push({ ...file });
    this.setState({
      files: _files
    })
  }

  selectSpiceSubckt = (_key, fileIndex) => {
    if (!_key) {
      return;
    }
    const { label: name, key } = _key || {}
    const { files } = this.state;
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];
    let currentFile = fileIndex > -1 && _files[fileIndex] ? { ..._files[fileIndex] } : null;
    if (!currentFile) {
      return;
    }

    const libraryId = currentFile.libraryId,
      fileName = currentFile.fileName,
      prevSubckt = currentFile.subckt;
    const find = _files.find(item => item.libraryId === libraryId && item.fileName === fileName && item.subckt && item.subckt === key);
    if (find) {
      this.setState({
        errorMsg: "The current subckt has been selected."
      })
      return;
    }
    _files[fileIndex].subckt = name;

    this.setState({
      files: _files,
      errorMsg: null
    }, () => {
      prevSubckt && this.clearPinNode({ delFile: { ...currentFile }, newSubckt: name, file: _files[fileIndex] });
    })
  }

  changeApplyAll = (e, pinItem) => {
    e && e.stopPropagation();
    const { pinList } = this.state;
    let _pinList = [...pinList];
    let modelKeys = [pinItem.input.modelKey]
    for (let item of _pinList) {
      if (item.input.pin === pinItem.input.pin) {
        continue;
      }
      const modelKey = getDefaultIndex(modelKeys.length, modelKeys);
      modelKeys.push(modelKey);
      item.input = {
        pin: item.input.pin,
        node: pinItem.input.node,
        libraryId: pinItem.input.libraryId,
        fileName: pinItem.input.fileName,
        subckt: pinItem.input.subckt,
        modelKey
      }
      item.output = {
        pin: item.output.pin,
        node: pinItem.output.node,
        libraryId: pinItem.output.libraryId,
        fileName: pinItem.output.fileName,
        subckt: pinItem.output.subckt,
        modelKey
      }
    }
    this.setState({
      pinList: _pinList
    })
  }

  getFileSelection() {
    const { fileList, files, subcktList } = this.state;
    return (
      <Fragment>
        {files && files.length > 0 ? files.map((file, index) => {
          const modelTitle = index === 0 ? "Model File" : "";
          if (file.fileName) {
            /* find subckt select list */
            const currentSubckt = subcktList ? subcktList.find(item => (item.libraryId === file.libraryId && item.fileName === file.fileName)) : null;
            const subckts = currentSubckt ? currentSubckt.models : [];

            let fileName = file.fileName ? file.fileName : undefined;
            if (file.folder && fileName) {
              fileName = `${file.folder}::${fileName}`;
            }
            return (
              <div className='spice-model-multi-select' key={index}>
                {/* spice file */}
                {this.getSelectComponent({
                  type: modelTitle,
                  width: "calc(50% + 45px)",
                  placeholder: "Model File",
                  value: fileName ? fileName : '',
                  selectHandler: this.selectModelFile,
                  list: fileList,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : fileName,
                  tree: true
                })}
                {/* subckt */}
                {this.getSelectComponent({
                  type: "Subckt",
                  width: "calc(50% - 55px)",
                  placeholder: 'Subckt',
                  value: file.subckt ? file.subckt : '',
                  selectHandler: this.selectSpiceSubckt,
                  list: subckts,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : file.subckt,
                  tree: false
                })}
                <CloseOutlined
                  title={'Delete the file'}
                  className='spice-model-delete-file-icon'
                  onClick={() => { this.deleteFileSelect('file', index, true) }} />
              </div>
            );
          } else {
            return (
              <div className='spice-model-single-select' key={index}>
                {/* file not select */}
                {this.getSelectComponent({
                  type: modelTitle,
                  placeholder: "Model File",
                  value: file.fileName ? file.fileName : '',
                  selectHandler: this.selectModelFile,
                  list: fileList,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : file.fileName,
                  tree: true
                })}
                <CloseOutlined
                  title={'Delete the file'}
                  className='spice-model-delete-file-icon'
                  onClick={() => { this.deleteFileSelect('file', index, true) }} />
              </div>
            );
          }
        }) : <div className='spice-model-single-select'>
          {this.getSelectComponent({
            type: "Model File",
            placeholder: "Model File",
            value: '',
            selectHandler: this.selectModelFile,
            list: fileList,
            fileIndex: -1,
            fileId: '',
            tree: true
          })}</div>}
      </Fragment>
    );
  }

  getSelectComponent({ type, width, placeholder, value, selectHandler, list, fileIndex, fileId, tree }) {
    let _value = { key: fileId, label: value };
    return (
      <div className='spice-netlist-model-select' style={{ width }}>
        {type === "Model File" ?
          <div>
            <span>{type}</span>
            <PlusCircleOutlined
              title={'Add new file'}
              className='spice-model-add-file-icon'
              onClick={(e) => { this.addNewFileSelect(e) }} />
          </div>
          : <span className={type}>{type === 'Subckt' ? "" : type}</span>}
        {tree ? <TreeSelect
          showSearch
          labelInValue
          placeholder={placeholder}
          value={_value}
          fileIndex={fileIndex}
          onSelectItem={selectHandler}
          className={`spice-netlist-model-selection ${type}_select`}
          popupClassName='spice-netlist-model-dropdown'
          popupMatchSelectWidth={false}
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, fileIndex) }} /> }}
          getPopupContainer={() => document.getElementById('root')}
          fileList={list}
          showFileFolder={true}
          selected={_value.key}
        /> : <Select
          labelInValue
          placeholder={placeholder}
          value={_value}
          onChange={(item) => item && selectHandler({ label: item.label, key: item.value }, fileIndex)}
          className={`spice-netlist-model-selection ${type}_select`}
          popupClassName='spice-netlist-model-dropdown'
          popupMatchSelectWidth={false}
          allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, fileIndex) }} /> }}
          getPopupContainer={() => document.getElementById('root')}
        >
          {list.map((item, index) => (
            <Option
              key={item.name}
              value={item.id ? item.id : item.name}
              title={item.name}
            >{item.name}</Option>
          ))}
        </Select>}
      </div >
    );
  }

  renderDialog = () => {
    const { partNumber, position, maxWidth, maxHeight } = this.props;
    const { pinList } = this.state;
    const { leftPinWidth, rightPinWidth } = getPkgPinListWidth(pinList, "pin",
      {
        leftKey: "input",
        rightKey: "output",
        leftTitle: "INPUT",
        rightTitle: "OUTPUT",
        fontSize: 14,
        maxWidth: 100,
        fontWeight: 600
      });

    const { leftPinWidth: pinLValueWidth, rightPinWidth: pinRValueWidth } = getPkgPinListWidth(pinList, "node",
      {
        leftKey: "input",
        rightKey: "output"
      });

    const content = <Panel
      title={`Repeater Model - ${partNumber}${position ? ` - ${position}` : ""}`}
      className='sierra-repeater-connections-panel sierra-panel'
      position='panel-center-left'
      zIndex={2000}
      onCancel={() => { this.saveModel() }}
      width={700}
      draggable
      minHeight={200}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    >
      <div className="sierra-repeater-connections-content">
        {this.getFileSelection()}
        <ul className="repeater-model-pin-content">
          <div className="repeater-model-pin-item repeater-model-pin-item-title">
            <div className="repeater-model-pin-input">
              <div className="repeater-model-pin-title" style={{ width: leftPinWidth }}>INPUT</div>
            </div>
            <div className="repeater-model-pin-output">
              <div className="repeater-model-pin-title" style={{ width: rightPinWidth }}>OUTPUT</div>
            </div>
          </div>
          {pinList.map((pinItem, index) =>
            <div className="repeater-model-pin-item" key={index}>
              {/* input */}
              <div className="repeater-model-pin-input">
                {this.getNodeSelection({
                  pinValueWidth: pinLValueWidth,
                  pinItem: pinItem.input,
                  pinType: "input"
                })}
                <div className="repeater-model-pin-title" style={{ width: leftPinWidth }}>{pinItem.input.pin}</div>
              </div>
              <div className='spice-pin-line'></div>
              {/* output */}
              <div className="repeater-model-pin-output">
                <div className="repeater-model-pin-title" style={{ width: rightPinWidth }}>{pinItem.output.pin}</div>
                {this.getNodeSelection({
                  pinValueWidth: pinRValueWidth,
                  pinItem: pinItem.output,
                  pinType: "output"
                })}
                {this.getCopyAllDisplay(pinItem) ? <div className="repeater-model-pin-copy-content" >
                  <CopyOutlined
                    title="Apply to all input, output pair."
                    className="repeater-model-pin-copy-icon"
                    onClick={(e) => this.changeApplyAll(e, pinItem)} />
                </div> : null}
              </div>
            </div>
          )}
        </ul>
      </div>
    </Panel>
    return createPortal(content, this.dialogRoot)
  }

  getCopyAllDisplay = (pinItem) => {
    if (!(pinItem.input
      && pinItem.input.node
      && pinItem.output
      && pinItem.output.node
      &&
      pinItem.input.libraryId === pinItem.output.libraryId
      && pinItem.input.fileName === pinItem.output.fileName
      && pinItem.input.subckt === pinItem.output.subckt
      && pinItem.input.modelKey === pinItem.output.modelKey)) {
      return false;
    }
    const { subcktList } = this.state;
    const findModel = (subcktList || []).find(item => item.libraryId === pinItem.input.libraryId && item.fileName === pinItem.input.fileName);
    if (!findModel || !findModel.models || !findModel.models.length) {
      return false;
    }
    const findNodes = findModel.models.find(item => item.name === pinItem.input.subckt);
    if (findNodes.ports.length !== 2) {
      return false;
    }
    return true;
  }

  render = () => {
    const { text } = this.props;
    return <div>
      <span>{text || ""}</span>
      {this.renderDialog()}
    </div>
  }

  getNodeSelection = ({
    pinValueWidth,
    pinItem,
    pinType
  }) => {
    return this.state.editNode === pinItem.pin ? <Popover
      overlayClassName='spice-node-select-Popover pin-spice-node-select-Popover'
      content={this.nodeSelectRender({ ...pinItem }, pinType)}
      title=""
      trigger="click"
      open={true}
      placement={"top"}
    ><Input
        className='repeater-spice-pin-input'
        style={{ width: pinValueWidth ? pinValueWidth + 44 : 80 }}
        placeholder={"Node"}
        value={pinItem.node}
        id={pinItem.pin}
      /></Popover>
      : <Input
        className='repeater-spice-pin-input'
        style={{ width: pinValueWidth ? pinValueWidth + 44 : 80 }}
        placeholder={"Node"}
        value={pinItem.node}
        id={pinItem.pin}
        onClick={() => this.openSelectNode(pinItem, pinType)}
        onChange={(e) => this.deleteSelectNode(e, pinItem, pinType)}
        allowClear
      />
  }

  deleteSelectNode = (e, pinItem, pinType) => {
    if (e.target.value) {
      return;
    }
    const { pinList } = this.state;
    if (!pinItem) {
      return;
    }
    let _pinList = [...pinList];
    const index = _pinList.findIndex(item => item[pinType] && item[pinType].pin === pinItem.pin);
    if (index < 0) {
      return;
    }
    _pinList[index][pinType] = {
      ..._pinList[index][pinType],
      libraryId: "",
      fileName: "",
      subckt: "",
      node: "",
      modelKey: ""
    }

    this.setState({
      pinList: _pinList
    })
  }

  openSelectNode = (pinItem, pinType) => {
    const { files, pinList } = this.state;
    if (!pinItem) {
      return;
    }
    let file = null;
    if (!pinItem.node) {
      const findPin = pinList.find(item => item[pinType] && item[pinType].pin === pinItem.pin);
      const anotherType = pinType === "input" ? "output" : "input";
      if (findPin && findPin[anotherType] && findPin[anotherType].node) {
        file = files.find(item => item.libraryId === findPin[anotherType].libraryId && item.fileName === findPin[anotherType].fileName && item.subckt === findPin[anotherType].subckt)
      } else {
        file = files && files[0] && files[0].subckt ? { ...files[0] } : null;
      }
    } else {
      file = files.find(item => item.libraryId === pinItem.libraryId && item.fileName === pinItem.fileName && item.subckt === pinItem.subckt)
    }
    this.setState({
      selectFile: file,
      editNode: pinItem.pin,
      searchValue: null
    })
  }

  getSelectedFileList = () => {
    const { files } = this.state;
    let _files = [...files];
    let list = [];
    for (let item of _files) {
      if (!item.subckt) {
        continue;
      }
      let key = `${item.libraryId}::${item.subckt}::${item.fileName}`;
      let value = `${item.subckt}::${item.fileName}`;

      value = item.folder ? `${value}::${item.folder}` : value;

      list.push({
        key,
        value,
      })
    }

    return list;
  }

  getDisplayPortList = (pinData) => {
    const { pinList, selectFile, subcktList, searchValue } = this.state;
    let modelGroup = new Map();
    const value = strFormatChange(searchValue || "");
    const searchReg = new RegExp(`(${value})`, 'i');

    if (!selectFile || !subcktList) {
      return { modelGroupKeys: [], modelGroup }
    }
    const findModel = (subcktList || []).find(item => item.libraryId === selectFile.libraryId && item.fileName === selectFile.fileName);
    if (!findModel || !findModel.models || !findModel.models.length) {
      return { modelGroupKeys: [], modelGroup }
    }
    const findNodes = findModel.models.find(item => item.name === selectFile.subckt);
    if (!findNodes || !findNodes.ports || !findNodes.ports.length) {
      return { modelGroupKeys: [], modelGroup }
    }

    const searchNodes = searchValue && searchReg ? findNodes.ports.filter(item => item && item.match(searchReg)) : [];
    let currModelKey = null;
    if (pinData.node) {
      modelGroup.set(pinData.modelKey, [{ ...pinData, selectPin: pinData.pin, select: true }])
      currModelKey = pinData.modelKey;
    }

    for (let item of pinList) {
      const input = item.input || {};
      const output = item.output || {};

      if (input.pin !== pinData.pin
        && !!input.node
        && input.libraryId === selectFile.libraryId
        && input.fileName === selectFile.fileName
        && input.subckt === selectFile.subckt) {
        if (modelGroup.get(input.modelKey)) {
          modelGroup.set(input.modelKey, [...modelGroup.get(input.modelKey), { ...input, selectPin: input.pin, select: true }])
        } else {
          modelGroup.set(input.modelKey, [{ ...input, selectPin: input.pin, select: true }])
        }
      }

      if (output.pin !== pinData.pin
        && !!output.node
        && output.libraryId === selectFile.libraryId
        && output.fileName === selectFile.fileName
        && output.subckt === selectFile.subckt) {
        if (modelGroup.get(output.modelKey)) {
          modelGroup.set(output.modelKey, [...modelGroup.get(output.modelKey), { ...output, selectPin: output.pin, select: true }])
        } else {
          modelGroup.set(output.modelKey, [{ ...output, selectPin: output.pin, select: true }])
        }
      }
    }

    for (let key of Array.from(modelGroup.keys())) {
      const selectedNodes = modelGroup.get(key) || [];
      const _selectedNodes = selectedNodes.map(item => item.node);
      const nodes = findNodes.ports.filter(item => !_selectedNodes.includes(item))
        .map(item => {
          return {
            node: item,
            modelKey: key
          }
        })
      if (!nodes.length && currModelKey !== key) {
        modelGroup.delete(key);
        continue;
      }
      const sortNodes = [...nodes.filter(item => searchNodes.includes(item.node)),
      ...selectedNodes.filter(item => searchNodes.includes(item.node)),
      ...nodes.filter(item => !searchNodes.includes(item.node)),
      ...selectedNodes.filter(item => !searchNodes.includes(item.node))]
      modelGroup.set(key, [...sortNodes]);
    }


    const notSearchNodes = findNodes.ports.filter(item => !searchNodes.includes(item))
    const sortNodes = [...new Set([...searchNodes, ...notSearchNodes])];

    modelGroup.set("New", sortNodes.map(item => {
      return {
        node: item,
        modelKey: "New"
      }
    }))

    return { modelGroupKeys: Array.from(modelGroup.keys()), modelGroup };
  }

  selectNodesFile = (key) => {
    if (!key) {
      return;
    }
    const [libraryId, subckt, fileName] = strDelimited(key, "::");
    this.setState({
      selectFile: { libraryId, subckt, fileName }
    })
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  selectPinNode = ({ nodeItem, pinItem, pinType, selectFile }) => {
    const { pinList } = this.state;
    let _pinList = [...pinList];
    const index = _pinList.findIndex(item => item[pinType] && item[pinType].pin === pinItem.pin);
    if (index < 0) {
      return;
    }
    let modelKey = nodeItem.modelKey
    if (nodeItem.modelKey === "New") {
      const filterKeys = _pinList
        .map(item => [(item.input || {}).modelKey, (item.output || {}).modelKey])
        .flat(2)
        .filter(item => !!item);
      if (!filterKeys.length) {
        modelKey = "1"
      } else {
        modelKey = getDefaultIndex(filterKeys.length, filterKeys);
      }
    }
    _pinList[index][pinType] = {
      ..._pinList[index][pinType],
      libraryId: selectFile.libraryId,
      fileName: selectFile.fileName,
      subckt: selectFile.subckt,
      node: nodeItem.node,
      modelKey: modelKey
    }
    this.setState({
      pinList: _pinList,
      editNode: "",
      searchValue: "",
      selectFile: null
    })
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: "",
      searchValue: "",
      selectFile: null
    })
  }

  nodeSelectRender = (pinItem, pinType) => {
    const { searchValue, selectFile } = this.state;
    const { modelGroupKeys, modelGroup } = this.getDisplayPortList(pinItem);
    const selectFileList = this.getSelectedFileList();
    const { maxWidth, maxHeight } = this.props;
    return <NodeSelection
      pinItem={pinItem}
      pinType={pinType}
      searchValue={searchValue}
      selectFile={selectFile}
      modelGroupKeys={modelGroupKeys}
      modelGroup={modelGroup}
      selectFileList={selectFileList}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      closeNodesSelection={this.closeNodesSelection}
      selectNodesFile={this.selectNodesFile}
      searchNode={this.searchNode}
      selectPinNode={this.selectPinNode}
    />
  }
}

export default RepeaterModel;