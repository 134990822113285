import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Select, Tooltip } from 'antd';
import ModelSetup from './modelSetup'
import {
  ADS_TX,
  ADS_RX,
  AMI_CONTROLLER,
  AMI_DEVICE,
  updateModelCompByDir,
  getSelectICComps,
  CircuitModelConfig,
  updateAdsSignalModelType
} from '@/services/Andes_v2/AMIModelHelper';
import { END_TO_END_CHANNEL } from '../../../constants/treeConstants';
import './index.css';
import { PCIE } from '../../../services/PCBHelper/constants';

const { Option } = Select;
const AMI = "AMI", IBIS = "IBIS";
const MODEL_LIST = [{
  key: AMI,
  title: 'AMI Model'
}, {
  key: IBIS,
  title: "IBIS Buffer"
}]

class SignalSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelVisible: false,
      directionToGroup: true,
      directionToAll: false
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  openModelSetup = ({ e, signal, type, component, dirType, model, prbs, interfaceType, channelId, pins, modelType, disabledTermination, pinAndNets }) => {
    this.setState({ modelVisible: true });
    this.modelInfo = {
      signal,
      type,
      model,
      component,
      dirType,
      prbs,
      interfaceType,
      channelId,
      pins,
      modelType,
      disabledTermination,
      pinAndNets
    }
  }

  updateDirection = ({ key, signal, type }) => {
    const { adsConfig, signals, interfaceType, selectedSignals = [], modelKey, adsSignals, serdesType } = this.props;
    const { directionToGroup, directionToAll } = this.state;
    let antherType = AMI_DEVICE;
    if (type === AMI_DEVICE) {
      antherType = AMI_CONTROLLER;
    }
    let _adsConfig = { ...adsConfig };
    const typePkgExist = this.getCompPackageExist(_adsConfig, type);
    const anotherTypePkgExist = this.getCompPackageExist(_adsConfig, antherType);
    if ((directionToGroup && interfaceType !== END_TO_END_CHANNEL && serdesType === PCIE) || directionToAll) {
      let sameGroupSignals = [];
      if (interfaceType === END_TO_END_CHANNEL) {
        sameGroupSignals = [...selectedSignals]
      } else {
        const channelSignal = signals.find(item => item.name === signal) || {};
        sameGroupSignals = directionToAll ? signals.map(item => item.name) : signals.filter(item => item.group === channelSignal.group).map(item => item.name);
      }

      for (let item of _adsConfig.signals) {
        if (!sameGroupSignals.includes(item.signalName)) {
          continue;
        }
        const rxModelKey = !item[`rx${modelKey}Model`] && item.rcModel ? 'rcModel' : `rx${modelKey}Model`;
        const signalInfo = updateModelCompByDir({
          key,
          signalItem: item,
          modelKey,
          _adsConfig,
          type,
          antherType,
          interfaceType,
          adsSignals,
          rxModelKey,
          typePkgExist,
          anotherTypePkgExist
        })
        item[`tx${modelKey}Model`] = signalInfo[`tx${modelKey}Model`];
        item[`${rxModelKey}`] = signalInfo[`${rxModelKey}`];
        if (item.txCircuitModel) {
          item.txCircuitModel = signalInfo.txCircuitModel;
        }
        if (item.rxCircuitModel) {
          item.rxCircuitModel = signalInfo.rxCircuitModel;
        }
      }
    } else {
      const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
      if (index < 0) {
        return;
      }

      let rxModelKey = _adsConfig.signals[index][`rx${modelKey}Model`] && Object.keys(_adsConfig.signals[index][`rx${modelKey}Model`]).length ? `rx${modelKey}Model` : `rcModel`;

      const signalInfo = updateModelCompByDir({
        key,
        signalItem: _adsConfig.signals[index],
        modelKey,
        _adsConfig,
        type,
        antherType,
        interfaceType,
        adsSignals,
        rxModelKey,
        typePkgExist,
        anotherTypePkgExist
      })
      _adsConfig.signals[index][`tx${modelKey}Model`] = signalInfo[`tx${modelKey}Model`];
      _adsConfig.signals[index][`${rxModelKey}`] = signalInfo[`${rxModelKey}`];
      if (_adsConfig.signals[index].txCircuitModel) {
        _adsConfig.signals[index].txCircuitModel = signalInfo.txCircuitModel;
      }
      if (_adsConfig.signals[index].rxCircuitModel) {
        _adsConfig.signals[index].rxCircuitModel = signalInfo.rxCircuitModel;
      }
    }

    this.props._updateADSConfig(_adsConfig);
  }

  getNewPairs = (signalName, model, preSignalName, preModelInfo, adsSignals) => {
    const { component, channelId } = preModelInfo
    const { pairs, component: preComponent, channelId: preChannelId } = model;
    const findSignalInfo = adsSignals.find(it => it.signal === signalName);
    const preSignalInfo = adsSignals.find(it => it.signal === preSignalName)
    let newPairs = [];

    if (findSignalInfo && preSignalInfo) {
      const key = (findSignalInfo.controller.component === component && (!findSignalInfo.controller.channelId || !channelId || (findSignalInfo.controller.channelId === channelId))) ? AMI_CONTROLLER : AMI_DEVICE;
      const preKey = (preSignalInfo.controller.component === preComponent && (!preSignalInfo.controller.channelId || !preChannelId || (preSignalInfo.controller.channelId === preChannelId))) ? AMI_CONTROLLER : AMI_DEVICE;

      for (let pair of pairs) {
        const { pin } = pair;
        if (preSignalInfo[preKey].negative.pin === pin && findSignalInfo[key].negative.pin) {
          newPairs.push({ ...pair, pin: findSignalInfo[key].negative.pin })
        } else if (preSignalInfo[preKey].positive.pin === pin && findSignalInfo[key].positive.pin) {
          newPairs.push({ ...pair, pin: findSignalInfo[key].positive.pin })
        }
      }
    }
    return newPairs;
  }

  saveModel = ({ model, prbs, signal, dirType, applyAll, type, isClose }) => {
    const { adsConfig, modelKey, adsAllSignals } = this.props;
    let _adsConfig = { ...adsConfig };
    if (typeof isClose === 'boolean' && !isClose) {
      this.saveModelFile({ model, signal, dirType, type });
      return;
    }
    if (type === "circuit") {
      this.saveCircuitModel({ model, signal, dirType, applyAll });
      return;
    }
    let key = type === "rcModel" ? type : `${dirType.toLowerCase()}${modelKey}Model`;
    if (applyAll) {
      _adsConfig.signals.forEach(item => {
        let preKey = `${dirType.toLowerCase()}${modelKey}Model`
        if (dirType.toLowerCase() === 'rx' && !item[preKey] && item.rcModel) {
          preKey = 'rcModel'
        }
        const isDisabled = dirType === ADS_RX && type === 'rcModel' && item.rxCircuitModel && item.rxCircuitModel.libraryId;
        const channelId = item[preKey].channelId;

        let _model = JSON.parse(JSON.stringify(model))
        if (type === 'rcModel' && !isDisabled) {
          if (model.pairs.length) {
            // The data in the pair of rcMode corresponds to its pin
            const pairs = this.getNewPairs(item.signalName, model, signal, item[preKey], adsAllSignals, channelId)
            _model.pairs = pairs;
          } else if (preKey === type) {
            // If there are no pins in this set of signals, the other RC settings remain unchanged
            _model = item[preKey]
          }
          item[key] = {
            ...JSON.parse(JSON.stringify(_model)),
            component: item[preKey].component,
          }
        } else if (!isDisabled) {
          item[key] = {
            ...JSON.parse(JSON.stringify(_model)),
            component: item[preKey].component,
            usePackage: this.getUsePackage({ _model, prevModel: item[preKey] })
          }
        }

        if (dirType === ADS_TX) {
          item.prbs = { ...prbs }
        }

        if (!isDisabled) {

          if (channelId) {
            item[key].channelId = channelId;
          }

          if (type === "rcModel") {
            delete item[`${dirType.toLowerCase()}${modelKey}Model`]
          } else if (dirType.toLowerCase() === 'rx' && item.rcModel) {
            delete item.rcModel
          }
        }
      })
    } else {
      const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
      if (index < 0) {
        return;
      }

      _adsConfig.signals[index][key] = JSON.parse(JSON.stringify(model));

      if (type === "rcModel") {
        delete _adsConfig.signals[index][`${dirType.toLowerCase()}${modelKey}Model`]
      } else if (dirType.toLowerCase() === 'rx') {
        delete _adsConfig.signals[index].rcModel
      }

      if (dirType === ADS_TX) {
        _adsConfig.signals[index].prbs = { ...prbs }
      }
    }

    this.props._updateADSConfig(_adsConfig);
    this.setState({
      modelVisible: false
    })
  }

  saveModelFile = ({ model, signal, dirType, type }) => {
    const { adsConfig, modelKey } = this.props;
    let _adsConfig = { ...adsConfig };
    let key = type === "rcModel" ? type : `${dirType.toLowerCase()}${modelKey}Model`;
    const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
    if (index < 0) {
      return;
    }

    _adsConfig.signals[index][key] = JSON.parse(JSON.stringify(model));

    if (type === "rcModel") {
      delete _adsConfig.signals[index][`${dirType.toLowerCase()}${modelKey}Model`]
    } else if (dirType.toLowerCase() === 'rx') {
      delete _adsConfig.signals[index].rcModel
    }

    this.props._updateADSConfig(_adsConfig);
  }

  deleteCircuitModel = (signalInfo, dirType) => {
    const { adsConfig, } = this.props;
    let _adsConfig = { ...adsConfig };
    const index = _adsConfig.signals.findIndex(item => item.signalName === signalInfo.signal);
    if (index < 0) {
      return;
    }
    let key = `${dirType.toLowerCase()}CircuitModel`;
    delete _adsConfig.signals[index][key];

    this.props._updateADSConfig(_adsConfig);
  }

  saveCircuitModel = ({ model, signal, dirType, applyAll }) => {
    const { adsConfig, adsAllSignals, modelKey, interfaceType } = this.props;
    let _adsConfig = { ...adsConfig };
    let key = `${dirType.toLowerCase()}CircuitModel`;
    if (applyAll) {
      _adsConfig.signals.forEach(item => {
        let prevModel = JSON.parse(JSON.stringify(item[key] || {}));
        const isDisabled = dirType === ADS_RX && (item.rcModel && !item[`${dirType.toLowerCase()}${modelKey}Model`]);
        if (!isDisabled && (!prevModel || !prevModel.component)) {
          //default circuit model by ami/ibis model
          if (dirType === ADS_TX) {
            const _model_ = item[`${dirType.toLowerCase()}${modelKey}Model`] || {};
            prevModel = { component: _model_.component, channelId: _model_.channelId }
          } else if (dirType === ADS_RX) {
            const _model_ = (item.rcModel && !item[`${dirType.toLowerCase()}${modelKey}Model`]) ? item.rcModel : item[`${dirType.toLowerCase()}${modelKey}Model`];
            prevModel = _model_ ? { component: _model_.component, channelId: _model_.channelId } : {}
          }
          prevModel = new CircuitModelConfig({ component: prevModel.component, channelId: prevModel.channelId, interfaceType })
        }

        if (!isDisabled && prevModel && prevModel.component) {
          const channelId = prevModel.channelId;
          let _model = JSON.parse(JSON.stringify(model))
          if (model.pairs.length) {
            // The data in the pair of rcMode corresponds to its pin
            const pairs = this.getNewPairs(item.signalName, model, signal, prevModel, adsAllSignals, channelId)
            _model.pairs = pairs;
          } else {
            // If there are no pins in this set of signals, the other RC settings remain unchanged
            _model = prevModel
          }

          item[key] = {
            ...JSON.parse(JSON.stringify(_model)),
            component: prevModel.component
          }
          if (channelId) {
            item[key].channelId = channelId;
          }
        }
      })
    } else {
      const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
      if (index < 0) {
        return;
      }

      _adsConfig.signals[index][key] = JSON.parse(JSON.stringify(model));
    }

    this.props._updateADSConfig(_adsConfig);
    this.setState({
      modelVisible: false
    })
  }

  getUsePackage = ({ _model, prevModel }) => {
    const { componentPkgInfo, interfaceType } = this.props;

    if (interfaceType === END_TO_END_CHANNEL) {
      const componentKey = `${prevModel.component}::${prevModel.channelId}`;
      if ((_model.component === prevModel.component && _model.channelId === prevModel.channelId) || !componentPkgInfo[componentKey]) {
        return _model.usePackage;
      }
    } else {
      if (_model.component === prevModel.component || !componentPkgInfo[prevModel.component]) {
        return _model.usePackage;
      }
    }

    return prevModel.usePackage
  }

  getCompPackageExist = (adsConfig, type) => {
    const { componentPkgInfo, interfaceType } = this.props;

    if (interfaceType === END_TO_END_CHANNEL) {
      const componentKey = adsConfig[type] && adsConfig[`${type}Channel`] ? `${adsConfig[type]}::${adsConfig[`${type}Channel`].channelId}` : null;
      return componentKey ? componentPkgInfo[componentKey] : false;
    } else {
      return componentPkgInfo[adsConfig[type]] || false
    }
  }

  getDirAndModel = (signal, comp) => {
    if (!comp || !comp.component) {
      return { model: {}, dirType: "", pins: [] };
    }
    const { modelKey } = this.props;

    let pins = [];
    if (comp.positive && comp.positive.pin) {
      pins.push(comp.positive.pin)
    }

    if (comp.negative && comp.negative.pin) {
      pins.push(comp.negative.pin)
    }

    let returnInfo = { model: {}, dirType: "", pins };
    if ((signal[`tx${modelKey}Model`] && signal[`tx${modelKey}Model`].component === comp.component) && (!comp.channelId || (comp.channelId === signal[`tx${modelKey}Model`].channelId))) {
      returnInfo = { model: signal[`tx${modelKey}Model`], dirType: ADS_TX, pins, ModelType: `tx${modelKey}Model` }
    } else if (signal[`rx${modelKey}Model`] && signal[`rx${modelKey}Model`].component === comp.component && (!comp.channelId || (comp.channelId === signal[`rx${modelKey}Model`].channelId))) {
      returnInfo = { model: signal[`rx${modelKey}Model`], dirType: ADS_RX, pins, ModelType: `rx${modelKey}Model` };
    } else if (signal[`rcModel`] && signal[`rcModel`].component === comp.component && (!comp.channelId || (comp.channelId === signal[`rcModel`].channelId))) {
      returnInfo = { model: { ...signal[`rcModel`] }, dirType: ADS_RX, pins, ModelType: `rcModel` };
    }
    //get circuit model by dir type
    const circuitDir = returnInfo.dirType.toLowerCase();
    if (signal[`${circuitDir}CircuitModel`]
      && signal[`${circuitDir}CircuitModel`].component === comp.component
      && (!comp.channelId || (comp.channelId === signal[`${circuitDir}CircuitModel`].channelId))) {
      returnInfo.circuitModel = signal[`${circuitDir}CircuitModel`]
    }

    return returnInfo || { model: {}, dirType: "", pins };
  }

  getAggressorsOptions = (signal) => {
    const { signals = [], selectedSignals = [], interfaceType } = this.props;
    return interfaceType === END_TO_END_CHANNEL ? [...selectedSignals].filter(item => item !== signal) : signals.filter(item => selectedSignals.includes(item.name) && item.name !== signal).map(item => item.name);
  }

  saveAggressors = (aggressors, signal) => {
    const { adsConfig } = this.props;
    let _adsConfig = { ...adsConfig };
    const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
    if (index < 0) {
      return;
    }

    _adsConfig.signals[index].aggressors = [...aggressors];
    this.props._updateADSConfig(_adsConfig);
  }

  // components
  getComponents = ({ adsConfig, interfaceType, controllerCircuitModelExist, deviceCircuitModelExist }) => {
    const { components = [], controllerICComps, deviceICComps, controllerInfo = {}, deviceInfo = {}, contrPCBIndex, devicePCBIndex } = this.props;
    const controller = adsConfig.controller, device = adsConfig.device;
    const { contrComps, deviceComps } = getSelectICComps({
      interfaceType,
      components,
      controllerICComps,
      deviceICComps
    });
    return <div className="andes-ads-component-content">
      {this.getModelTypeSelection("left")}
      {this.getCircuitModelTitle({ comp: controller, compInfo: controllerInfo, exist: controllerCircuitModelExist })}
      <div className="ami-model-pins">
        {this.getCompSelection({
          interfaceType,
          pcbIndex: contrPCBIndex,
          component: controller,
          type: "controller",
          anotherType: "device",
          comps: contrComps,
          compInfo: controllerInfo,
          className: "andes-ads-component-content-controller"
        })}
        <div className="ami-model-signal-main">Signal</div>
        {this.getCompSelection({
          interfaceType,
          pcbIndex: devicePCBIndex,
          component: device,
          type: "device",
          anotherType: "controller",
          comps: deviceComps,
          compInfo: deviceInfo,
          className: "andes-ads-component-content-device"
        })}
      </div>
      {this.getCircuitModelTitle({ comp: device, compInfo: deviceInfo, exist: deviceCircuitModelExist })}
      {this.getModelTypeSelection("right")}
      <div className="ami-model-signal-aggressors"><span>Aggressors</span></div>
    </div >
  }

  getModelTypeSelection = (type) => {
    const { modelType } = this.props;
    return (
      <div className={`ami-model-setup-content ami-model-setup-title-${type}-content`}>
        <Select
          value={modelType}
          showSearch
          onChange={(key) => this.changeModelType(key)}
          className={'aurora-select ads-model-type-select'}
          popupClassName='aurora-select-dropdown'
        >
          {MODEL_LIST.map(item =>
            <Option
              key={item.key}
              value={item.key}
              title={item.title}
            >{item.title}</Option>
          )}
        </Select>
      </div>
    );
  }

  getCircuitModelTitle = ({ comp, compInfo, exist }) => {
    return <div className={exist ? "ami-model-setup-content ami-model-setup-circuit-content" : "ami-model-setup-circuit-disabled-content"}>
      {exist ? <Fragment>
        <div>Circuit Model</div>
        <Tooltip
          overlayClassName='aurora-tooltip'
          title={`Delete circuit model`}
        >
          <CloseOutlined
            className='andes-ami-model-circuit-delete-icon'
            onClick={(e) => { e.stopPropagation(); this.deleteAllCircuitModel(comp, compInfo) }} />
        </Tooltip>
      </Fragment>
        : <Tooltip
          overlayClassName='aurora-tooltip'
          title={`Add circuit model`}
        >
          <PlusCircleOutlined
            className='andes-ami-model-circuit-add-icon'
            onClick={(e) => { e.stopPropagation(); this.addCompCircuitModel(comp, compInfo) }} />
        </Tooltip>}
    </div>
  }

  getCompSelection = ({
    interfaceType,
    pcbIndex,
    component,
    type,
    anotherType,
    comps,
    compInfo,
    className
  }) => {
    return (
      <div className={className}>
        <Tooltip
          overlayClassName='aurora-tooltip'
          title={interfaceType === END_TO_END_CHANNEL ?
            <Fragment>
              <div>
                Design: PCB {pcbIndex + 1}
              </div>
              <div>
                Component: {component}
              </div>
            </Fragment>
            : ""
          }
        >
          <Select
            value={component}
            showSearch
            onChange={(key) => this.saveComponent(key, type, anotherType, comps, compInfo)}
            className={interfaceType === END_TO_END_CHANNEL ? 'end-to-end-ads-comp-select aurora-select' : 'aurora-select'}
            popupClassName='aurora-select-dropdown'
          >
            {comps.map(item =>
              <Option
                key={item.name}
                value={item.name}
                title={item.pcb ? `${item.pcb} - ${item.name}` : item.name}
              >{item.pcb ? `${item.pcb} - ${item.name}` : item.name}</Option>
            )}
          </Select>
        </Tooltip>
      </div>
    );
  }

  changeModelType = (key) => {
    this.props.changeModelType(key);

    //If the model setup is open, close the panel
    this.closeSetupPanel()
    const { adsConfig, interfaceType } = this.props;
    let _adsConfig = { ...adsConfig };
    _adsConfig = updateAdsSignalModelType(_adsConfig, key, interfaceType);
    this.props._updateADSConfig(_adsConfig);
  }

  deleteAllCircuitModel = (comp, compInfo) => {
    const { adsConfig, interfaceType } = this.props;
    let _adsConfig = { ...adsConfig };
    const channelId = interfaceType === END_TO_END_CHANNEL && compInfo && compInfo.id ? compInfo.id : null;
    for (let item of _adsConfig.signals || []) {
      if (item.txCircuitModel && item.txCircuitModel.component === comp && (!channelId || channelId === item.txCircuitModel.channelId)) {
        delete item.txCircuitModel;
      }
      if (item.rxCircuitModel && item.rxCircuitModel.component === comp && (!channelId || channelId === item.rxCircuitModel.channelId)) {
        delete item.rxCircuitModel;
      }
    }

    this.props._updateADSConfig(_adsConfig);
  }

  addCompCircuitModel = (comp, compInfo) => {
    const { adsConfig, interfaceType } = this.props;
    let _adsConfig = { ...adsConfig };
    const channelId = interfaceType === END_TO_END_CHANNEL && compInfo && compInfo.id ? compInfo.id : null;

    for (let item of _adsConfig.signals || []) {
      const modelKey = item.IbisHasAMI === "yes" ? "Ami" : "";
      const txModelKey = `tx${modelKey}Model`;
      if (item[txModelKey] && item[txModelKey].component === comp && (!channelId || channelId === item[txModelKey].channelId)) {
        item.txCircuitModel = new CircuitModelConfig({
          interfaceType,
          component: comp,
          channelId,
        });
        continue;
      }

      if (!item[`rx${modelKey}Model`] && item.rcModel) {
        delete item.rxCircuitModel;
        continue;
      }
      const rxModelKey = `rx${modelKey}Model`;
      if (item[rxModelKey] && item[rxModelKey].component === comp && (!channelId || channelId === item[rxModelKey].channelId)) {
        item.rxCircuitModel = new CircuitModelConfig({
          interfaceType,
          component: comp,
          channelId,
        });
      }
    }
    this.props._updateADSConfig(_adsConfig);
  }

  saveComponent = (key, type, anotherType, ICComps, setupInfo) => {
    /* setupInfo: channelSetup { id, name, designId,pcbName, ... } */
    const { adsConfig, interfaceType } = this.props;
    let _adsConfig = { ...adsConfig };
    _adsConfig[type] = key;

    if (interfaceType === END_TO_END_CHANNEL) {
      _adsConfig[`${type}Channel`] = { channelId: setupInfo.id, designId: setupInfo.designId, designName: setupInfo.pcbName }
    }

    if (_adsConfig[anotherType] === key && interfaceType !== END_TO_END_CHANNEL) {
      _adsConfig[anotherType] = "";
      if (ICComps.length === 2) {
        const comp = ICComps.find(item => item.name !== key) || {}
        _adsConfig[anotherType] = comp.name || "";
      }
    }

    this.props._updateADSConfig(_adsConfig);
    this.props.setComponentPkgInfo(_adsConfig);
  }

  render = () => {
    const { adsSignals, interfaceType, modelType, controllerCircuitModelExist, deviceCircuitModelExist, adsConfig } = this.props;
    const { modelVisible } = this.state;

    return (
      <Fragment>
        {this.getComponents({ adsConfig, interfaceType, controllerCircuitModelExist, deviceCircuitModelExist })}
        {adsSignals.map(signal => {
          const {
            model: controllerModel,
            dirType: controllerDir,
            pins: controllerPins,
            ModelType: controllerModelType,
            circuitModel: controllerCircuitModel
          } = this.getDirAndModel(signal, signal.controller, signal.controllerChannelId);
          const {
            model: deviceModel,
            dirType: deviceDir,
            pins: devicePins,
            ModelType: deviceModelType,
            circuitModel: deviceCircuitModel } = this.getDirAndModel(signal, signal.device, signal.deviceChannelId);
          return (
            <div key={signal.signal} className="andes-ami-model-signals-content">
              {this.getAMIModelDisplay({
                type: AMI_CONTROLLER,
                model: controllerModel,
                signalInfo: signal,
                dirType: controllerDir,
                component: signal.controller.component,
                interfaceType,
                channelId: signal.controller.channelId,
                pins: controllerPins,
                modelType: controllerModelType,
                circuitModel: controllerCircuitModel
              })}
              {this.getCircuitModelDisplay({
                type: AMI_CONTROLLER,
                model: controllerCircuitModel,
                signalInfo: signal,
                dirType: controllerDir,
                component: signal.controller.component,
                interfaceType,
                channelId: signal.controller.channelId,
                pins: controllerPins,
                modelExist: controllerCircuitModelExist,
                amiModelType: controllerModelType,
                pinAndNets: { positive: signal.controller.positive, negative: signal.controller.negative }
              })}
              <div className="ami-model-pins">
                {this.getPinsRender({ pinInfo: signal.controller, type: AMI_CONTROLLER })}
                <div className="ami-model-signal-main">
                  <div className="ami-model-signal" title={signal.signal}>{signal.signal}</div>
                  {/* <Select
                  value={signal.aggressors}
                  showSearch
                  mode={'multiple'}
                  onChange={(keys) => this.saveAggressors(keys, signal.signal)}
                  placeholder={"Aggressors"}
                  className='aurora-select'
                  popupClassName='aurora-select-dropdown'
                  getPopupContainer={() => document.getElementById('andes-ads-model-setup-main')}
                >
                  {this.getAggressorsOptions(signal.signal).map(item =>
                    <Option key={item}>{item}</Option>
                  )}
                </Select> */}
                </div>
                {this.getPinsRender({ pinInfo: signal.device, type: AMI_DEVICE })}
              </div>
              {this.getCircuitModelDisplay({
                type: AMI_DEVICE,
                model: deviceCircuitModel,
                signalInfo: signal,
                dirType: deviceDir,
                component: signal.device.component,
                interfaceType,
                channelId: signal.device.channelId,
                pins: devicePins,
                modelExist: deviceCircuitModelExist,
                amiModelType: deviceModelType,
                pinAndNets: { positive: signal.device.positive, negative: signal.device.negative }
              })}
              {this.getAMIModelDisplay({
                type: AMI_DEVICE,
                model: deviceModel,
                signalInfo: signal,
                dirType: deviceDir,
                component: signal.device.component,
                interfaceType,
                channelId: signal.device.channelId,
                pins: devicePins,
                modelType: deviceModelType,
                circuitModel: deviceCircuitModel
              })}
              <div className="ami-model-signal-aggressors">
                <Select
                  value={signal.aggressors}
                  showSearch
                  mode={'multiple'}
                  onChange={(keys) => this.saveAggressors(keys, signal.signal)}
                  placeholder={"Aggressors"}
                  className='aurora-select'
                  popupClassName='aurora-select-dropdown'
                  getPopupContainer={() => document.getElementById('andes-ads-model-setup-main')}
                /*   getPopupContainer={trigger => trigger.parentNode} */
                >
                  {this.getAggressorsOptions(signal.signal).map(item =>
                    <Option key={item} value={item.key}>{item}</Option>
                  )}
                </Select>
              </div>
            </div>
          );
        })}
        {/* Tx / Rx Model Select */}
        {modelVisible ? this.getModelSetup(modelType) : null}
      </Fragment>
    );
  }

  getModelSetup = (modelType) => {
    const { adsConfig: { prbs = {} }, componentPkgInfo, serdesType } = this.props;
    return <ModelSetup
      modelType={modelType}
      modelInfo={this.modelInfo}
      prbs={prbs}
      componentPkgInfo={componentPkgInfo}
      saveModel={this.saveModel}
      closeSetupPanel={this.closeSetupPanel}
      serdesType={serdesType}
    />
  }

  closeSetupPanel = () => {
    this.setState({
      modelVisible: false
    })
  }

  getPinsRender = ({ pinInfo, type }) => {
    return <Fragment>
      <div className="ami-model-pin-list">
        {["positive", "negative"].map((item, index) => (
          <div key={index} className="ami-model-pin-item">
            {type === AMI_DEVICE ? <div className='ami-pin-long-line'></div> : null}
            <div className="ami-model-pin-content">
              {type === AMI_DEVICE ? <div className="ami-model-pin-type-title ami-model-pin-type-device-title">{item === "positive" ? "+" : "-"}</div> : null}
              <div className="ami-model-pin-title" title={pinInfo[item].pin}>{pinInfo[item].pin}</div>
              {type === AMI_CONTROLLER ? <div className="ami-model-pin-type-title">{item === "positive" ? "+" : "-"}</div> : null}
            </div>
            {type === AMI_CONTROLLER ? <div className='ami-pin-long-line'></div> : null}
          </div>
        ))}
      </div>
    </Fragment>
  }

  getAMIModelDisplay = ({
    type,
    model,
    signalInfo,
    dirType,
    component,
    interfaceType,
    channelId,
    pins,
    modelType,
    circuitModel
  }) => {
    const errorClassName = signalInfo[`${dirType}ModelFileError`] ? "ami-model-file-error-title" : "";
    const title = model.modelName ? model.modelName : dirType === ADS_RX && model.fileName ? model.fileName : null;
    return (
      <div className="ami-model-setup-content">
        {type === AMI_DEVICE ? this.directionSelect({
          value: dirType,
          type,
          signal: signalInfo.signal,
          component
        }) : null}
        <div className="ami-model-title-content">
          <div
            className={dirType ? `ami-model-title ${errorClassName}` : "ami-model-title ami-model-title-disabled"}
            title={title}
            onClick={dirType ? (e) => this.openModelSetup({
              e,
              type,
              signal: signalInfo.signal,
              model,
              dirType,
              component,
              prbs: signalInfo.prbs,
              interfaceType,
              channelId,
              pins,
              modelType,
              disabledTermination: dirType === ADS_RX && circuitModel && Object.keys(circuitModel).length
            }) : null}>
            {model.invModelName && model.invModelName !== model.modelName ? `${model.modelName} / ${model.invModelName}` : title}
            {errorClassName && <Tooltip
              title={`Model ${model.libraryName} has been deleted, the model is invalid.`}
              overlayClassName='aurora-tooltip'
            >
              <QuestionCircleOutlined
                className='aurora-file-check-icon'
                onClick={(e) => { e.stopPropagation() }} />
            </Tooltip>}
          </div>
        </div>
        {type === AMI_CONTROLLER ? this.directionSelect({
          value: dirType,
          type,
          signal: signalInfo.signal,
          component
        }) : null}
      </div>
    );
  }

  getCircuitModelDisplay = ({
    type,
    model,
    signalInfo,
    dirType,
    component,
    interfaceType,
    channelId,
    pins,
    modelExist,
    amiModelType,
    pinAndNets
  }) => {
    const errorClassName = signalInfo[`${dirType}CircuitModelFileError`] ? "ami-model-file-error-title" : "";
    const title = model && model.fileName ? model.fileName : null;
    if (!modelExist) {
      return <div className="ami-model-setup-content ami-model-setup-circuit-content ami-model-setup-circuit-disabled-content"></div>
    }
    const disabled = dirType === ADS_RX && amiModelType === "rcModel";
    return (
      <div className="ami-model-setup-content ami-model-setup-circuit-content">
        <div className={"ami-model-title-content"}>
          <div
            className={dirType && !disabled ? `ami-model-title ${errorClassName}` : "ami-model-title ami-model-title-disabled"}
            title={title}
            onClick={dirType && !disabled ? (e) => this.openModelSetup({
              e,
              type,
              signal: signalInfo.signal,
              model,
              dirType,
              component,
              prbs: signalInfo.prbs,
              interfaceType,
              channelId,
              pins,
              modelType: "Circuit",
              pinAndNets
            }) : null}>
            {title}
            {errorClassName && <Tooltip
              title={`Model ${model.fileName} has been deleted, the model is invalid.`}
              overlayClassName='aurora-tooltip'
            >
              <QuestionCircleOutlined
                className='aurora-file-check-icon'
                onClick={(e) => { e.stopPropagation() }} />
            </Tooltip>}
          </div>
          {model && !disabled ? <Tooltip
            overlayClassName='aurora-tooltip'
            title={`Delete circuit model`}
          ><CloseOutlined
              className='andes-ami-model-circuit-delete-icon'
              onClick={(e) => {
                e.stopPropagation();
                this.deleteCircuitModel(signalInfo, dirType)
              }} />
          </Tooltip> : null}
        </div>
      </div>
    );
  }

  directionGroupAllChange = (e, type) => {
    const checked = e.target.checked;
    this.setState({
      [`directionTo${type}`]: checked
    })
  }

  directionSelect = ({
    signal,
    type,
    value,
    component
  }) => {
    const { directionToAll, directionToGroup } = this.state;
    const { interfaceType, serdesType } = this.props;
    return <div className="ami-model-direction-main">
      <Select
        value={value}
        onChange={(key) => this.updateDirection({ key, signal, type })}
        popupMatchSelectWidth={false}
        className={`aurora-select`}
        disabled={!component}
        popupClassName="aurora-select-dropdown ami-model-direction-dropdown"
        getPopupContainer={trigger => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            {interfaceType !== END_TO_END_CHANNEL && serdesType === PCIE ? <div
              style={{ padding: '4px 8px', cursor: 'pointer' }}
              onMouseDown={e => { e.preventDefault() }}
            >
              <Checkbox
                onChange={(e) => this.directionGroupAllChange(e, "Group")}
                checked={directionToGroup}
              >
                Apply direction to all signals of same group.
              </Checkbox>
            </div> : null}
            <div
              style={{ padding: '4px 8px', cursor: 'pointer' }}
              onMouseDown={e => { e.preventDefault() }}
            >
              <Checkbox
                onChange={(e) => this.directionGroupAllChange(e, "All")}
                checked={directionToAll}
              >
                Apply direction to all signals.
              </Checkbox>
            </div>
          </div >
        )
        }
      >
        {
          [ADS_TX, ADS_RX].map((item) => (
            <Option
              key={item}
              value={item}
              title={item}
            >{item}</Option>))
        }
      </Select >
    </div >
  }

}
export default SignalSetup;