import { all, takeEvery, put, call, fork, race, take, delay, select } from 'redux-saga/effects';
import { upload as _upload, } from '@/services/api/Design/design';
import { RUNNING, SUCCEED, FAILED, CANCEL } from '@/constants/workflowStatus';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import { getWorkFlow, cancelWorkflow } from '@/services/api/v2/workflowCtrl';
import getDesignFile from '@/services/api/designFile';
import { TRANSLATION_SUCCESS, TRANSLATION_FAILED, GET_WORK_FLOWS, TRANSLATION_RUNNING, GET_TRANSLATION_MONITOR, CANCEL_UPLOAD_WORKFLOW } from './actionType';
import {
  translationProgress, translationSuccess, translationFailed,
  getWorkFlow as workFlowAction,
  translationMonitor, addMonitorMsg,
  translationDebug,
  changeDisableStatus
} from './action';
import { message } from 'antd';
import { getMonitor } from '@/services/workflow/workflow';
import { expandMenu, updatePDNsAfterReplacePCB } from '../../store/pdn/action';
import { updateProject } from '../../store/project/action';
import LayoutData from '@/services/data/LayoutData';
import { updatePCBRefreshStatus, cleanStatus } from '../../../LayoutExplorer/store/FastPI/actionCreators'

function* pollData(workflowId, projectID, designId) {
  try {
    yield delay(3000);
    yield put(workFlowAction(workflowId, projectID, designId));
  } catch (error) {
    return;
  }
}


const KEYARR = ['Statistics', 'Total counts', 'Layers:', 'Trace length',
  'Net histograms', 'Polygon edges', 'Pins:', 'Vias:'];
function filterContent(log) {
  try {
    if (KEYARR === 'undefined' || !(KEYARR instanceof Array) || KEYARR.length < 1) return false;
    const arrTxt = KEYARR.join('|'),
      regObj = new RegExp(arrTxt, 'ig');
    const _match = log.match(regObj);
    if (_match && _match.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
}

function* dataFetch(action) {
  yield takeEvery(GET_WORK_FLOWS, function* (action) {
    const { workflowId, projectID, designId } = action;
    try {
      const response = (yield call(getWorkFlow, workflowId)).data;
      let monitor = yield call(getMonitor, workflowId);
      let _monitor = [], _debugMonitor = [];
      const progress = /[0-9]+[%]/g;
      for (let item of monitor) {

        // Filter progress bar
        const _match = item.log.match(progress);
        if (_match && _match.length > 0) {
          continue;
        }

        // Filter '' string
        if (!item.log) {
          continue;
        }

        // Filter debug log
        if (filterContent(item.log)) {
          _debugMonitor.push(item);
          continue;
        }

        _monitor.push(item);
      };
      yield put(translationMonitor(_monitor));
      yield put(translationDebug(_debugMonitor));
      if (response.code === SUCCESS) {
        const { data } = response;
        if (data.status === RUNNING) {
          const progress = parseInt(data.progress);
          const running = translationProgress({ workflowId, designId, projectID, progress: progress });
          yield put(running);
        }

        if (data.status === SUCCEED) {
          const msg = `==> Finish compiling PCB.`
          const succeeded = translationSuccess(data.progress, msg);
          yield put(succeeded);

          const { PDNReducer: { pdn: { expandedKeys }, PDNUploadReducer: { currentPCBKey }, project: { currentProjectId } } } = yield select();
          if (currentProjectId === projectID) {
            yield put({ type: 'PDN/open_project', id: projectID });
            message.success('Upload completed!', 3);
            let Keys = [...expandedKeys];
            if (currentPCBKey && !Keys.includes(currentPCBKey)) {
              Keys.push(currentPCBKey);
            }
            yield put(expandMenu([...Keys]));
          }
          // If replace, modify the pdn
          if (currentPCBKey === 'replace') {
            // refresh design
            yield call(LayoutData.LoadLayoutDB, designId)
            yield put(cleanStatus(designId));
            yield put(updatePCBRefreshStatus(true, designId));
            // modify the pdn
            yield put(updatePDNsAfterReplacePCB(designId));
          }
          yield put(changeDisableStatus(false));
        }

        if (data.status === FAILED) {
          const msg = `==> Failed compiling PCB. \n==> ${data.message}`;
          const failed = translationFailed(msg, data.progress);
          const { PDNReducer: { project: { currentProjectId } } } = yield select();
          if (currentProjectId === projectID) {
            yield put({ type: 'PDN/open_project', id: projectID });
          }
          yield put(failed);
          yield put(changeDisableStatus(false));
        }

        if (data.status === CANCEL) {
          const msg = `==> Upload PCB closed.`;
          yield put(translationFailed(msg, 100));
          yield put(changeDisableStatus(false));
        }
      } else if (response.code === CHECK_FAIL) {
        const msg = `==> Failed compiling PCB. \n` + response.msg;
        yield put(translationFailed(msg, 100));
        yield put(changeDisableStatus(false));
      }
    } catch (error) {
      yield put(addMonitorMsg(`==> Failed compiling PCB.`));
      const msg = `==> Failed compiling PCB. \n` + error;
      yield put(translationFailed(msg, 100));
      yield put(changeDisableStatus(false));
      console.error(error);
    }
  })
}

function* getLog(action) {
  yield takeEvery(GET_TRANSLATION_MONITOR, function* (action) {
    const { designId } = action;
    const response = yield call(getDesignFile, designId, 'lay_trans/log/layout_trans.log');
    let monitor = response ? response.data : null;
    yield put(translationMonitor(monitor));
  })
}

function* translationWork(action) {
  yield takeEvery(TRANSLATION_RUNNING, function* (action) {
    const { workflowId, designId, projectID } = action;
    try {
      yield race([
        call(pollData, workflowId, projectID, designId),
        take(TRANSLATION_SUCCESS),
        take(TRANSLATION_FAILED),
      ]);
    } catch (error) {
      console.error(error);
    }
  })
}

function* cancelUpload(action) {
  const { PDNReducer: { project: { currentProjectId } } } = yield select();
  const { workflowId } = action;
  yield call(cancelWorkflow, workflowId);
  const msg = `==> Upload PCB closed.`;
  yield put(translationFailed(msg, 100));
  yield put(updateProject(currentProjectId));
}

export default function* FileUploadSaga() {
  yield all([
    fork(translationWork),
    fork(dataFetch),
    fork(getLog),
    takeEvery(CANCEL_UPLOAD_WORKFLOW, cancelUpload)
  ])
}