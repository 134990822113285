//Get ddr signal type by signal name
export function getDDRSignalType(signal) {
  if (!signal) {
    return;
  }
  let regArr = [];
  //find DQ0-DQN(DQ0,DQ9)  //DQ
  const DQRegObj = new RegExp(`^(DQ[0-9]+)`, 'ig');
  regArr.push({ reg: DQRegObj, value: `(DQ)&(^((?!DQS).)+$)`, type: 'DQ', portTypeList: ["DQ"] });

  //find DM
  const DMRegObj = new RegExp(`^(DM)`, 'ig');
  regArr.push({ reg: DMRegObj, value: `(DM)`, type: 'DM', portTypeList: ["DM", "DMI"] });

  //Find ADR /ADR/ADDR/ADD/ INPUT
  const ADRRegObj = new RegExp(`^(A[0-9]+)|^(CA[0-9]+)`, "ig")
  regArr.push({ reg: ADRRegObj, value: `(ADR)|(ADDR)|(ADD)`, type: 'ADR', portTypeList: ["ADR", "CA", "A"] });

  //find CMD  /BA0-BAN(BA0,BA9) //CMD INPUT
  const BAReg = new RegExp(`^(BA[0-9]+)`, 'ig');
  regArr.push({ reg: BAReg, value: `(CMD)`, type: 'CMD', portTypeList: ["BA"] });


  //find CMD RAS  //CMD INPUT
  const RASRegObj = new RegExp(`^(RAS)`, 'ig');
  regArr.push({ reg: RASRegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["RAS"] });


  //find CMD  CAS  //CMD INPUT
  const CASRegObj = new RegExp(`^(CAS)`, 'ig');
  regArr.push({ reg: CASRegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["CAS"] });


  //find CMD WE  //CMD INPUT
  const WERegObj = new RegExp(`^(WE)`, 'ig');
  regArr.push({ reg: WERegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["WE"] });

  //find CMD  /ODT  //CMD INPUT
  const ODTRegObj = new RegExp(`^(ODT)`, 'ig');
  regArr.push({ reg: ODTRegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["ODT"] });

  //find CMD  /CS  //CMD INPUT
  const CSRegObj = new RegExp(`^(CS)`, 'ig');
  regArr.push({ reg: CSRegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["CS"] });

  //find CMD  /CKE  //CMD INPUT
  const CKERegObj = new RegExp(`^(CKE)`, 'ig');
  regArr.push({ reg: CKERegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["CKE"] });

  //find CMD  /CKE  //CMD INPUT
  const ACTRegObj = new RegExp(`^(ACT)`, 'ig');
  regArr.push({ reg: ACTRegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["ACT"] });

  //find CMD  /BG  //CMD INPUT
  const BGRegObj = new RegExp(`^(BG)`, 'ig');
  regArr.push({ reg: BGRegObj, value: `(CMD)`, type: 'CMD', portTypeList: ["BG"] });

  //find CLKP/CLKN //CLK
  const CLKRegObj = new RegExp(`^(CLKP)|^(CLKN)|(CLKP[0-9]+)|((CLKN[0-9]+))`, 'ig');
  regArr.push({ reg: CLKRegObj, value: `(CLK)|(CLKIN)|(CK)`, type: 'CLK', portTypeList: ["CLK", "CK"] });

  //find DQSP/DQSN //DQS
  const DQSRegObj = new RegExp(`^(DQSN)|^(DQSP)|^(RDQS_T)|^(RDQS[0-9]+(T|C))|^(RDQS_C)|(^DQS[0-9]+P)|(^DQS[0-9]+N)`, 'ig');
  regArr.push({ reg: DQSRegObj, value: `(DQS)`, type: 'DQS', portTypeList: ["DQS"] });

  //find WCK_T/WCK_C //DQS
  const WCKRegObj = new RegExp(`^(WCK_T)|^(WCK_C)|^(WCK[0-9]+(T|C))`, 'ig');
  regArr.push({ reg: WCKRegObj, value: `(WCK)`, type: 'WCK', portTypeList: ["WCK"] });

  for (let item of regArr) {
    if (signal.match(item.reg)) {
      return item;
    }
  }
}

//get first number from string
export function getNum(str) {
  if (!str) {
    return null;
  }
  //Matches the first digit in the string
  let pattern = new RegExp("[0-9]+");
  let num = str.match(pattern);
  return num ? num[0] : null;
}
