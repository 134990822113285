import { COMPONENT_CLASS, ROOT_CLASS, DRIVER_CLASS, CONN_CLASS, IC_CLASS } from "../../../constants/powertreeClassType";

function PowerTree({ id = "", name, tree }) {
  this.id = id;
  this.tree = tree ? tree : {
    root: "",
    groundNet: "",
    groundComps: [],
    branch: [],
    name,
    pins: {},
    deep: 0,
    pcbIndex: [],
    key: name,
    logs: [],
    nextKeys: [],
    whatIf: false,
    whatIfData: []
  };
}

class TreeItem {
  constructor(props) {
    this.type = props.type;
    this.name = props.name;
    this.part = props.partName || props.part;
    this.pins = props.pins;
    this.disabled = false;
    this.isGnd = props.isGnd || false;
    this.pcbKey = props.pcbKey || "";
    this.hidden = props.hidden || false;
    this.gndKey = "";
    this.pathKey = props.pathKey || [];
    this.pcbId = props.pcbId || "";
    this.hiddenLine = props.hiddenLine || false;
    this.hiddenPins = props.hiddenPins || false;
  }
}

class RootItem extends TreeItem {
  constructor(props) {
    super(props);
    this.load = "";
    this.voltage = "";
    this.deepIndex = 1;
    this.broComp = props.broComp;
  }
}

class ComponentItem extends TreeItem {
  constructor(props) {
    super(props);
    this.resistance = props.resistance || "0";
    this.rootNet = props.rootNet;
    this.prevNet = props.prevNet;
    this.prevComp = props.prevComp;
    this.anotherPrev = [];
    this.pinMap = props.pinMap;
    this.nextNet = props.nextNet;
    this.classType = COMPONENT_CLASS;
    this.hiddenComp = props.hiddenComp;
    this.extraGndComp = props.extraGndComp;
    this.rootInd = props.rootInd || false;
    this.loadId = props.loadId || null;
    this.showPrevNet = props.showPrevNet || null;
    this.connComp = props.connComp || null;
  }
}

class ICItem extends TreeItem {
  constructor(props) {
    super(props);
    this.load = "";
    this.vMin = "";
    this.vMax = "";
    this.rootNet = props.rootNet;
    this.prevNet = props.prevNet;
    this.prevComp = props.prevComp;
    this.anotherPrev = [];
    this.middle = false;
    this.nextNet = "";
    this.voltage = "";
    this.gnd = "";
    this.classType = IC_CLASS
  }
}

class DriverItem extends TreeItem {
  constructor(props) {
    super(props);
    this.load = "";
    this.vMin = "";
    this.vMax = "";
    this.rootNet = props.rootNet;
    this.prevNet = props.prevNet;
    this.prevComp = props.prevComp;
    this.anotherPrev = [];
    this.gnd = props.gnd || "";
    this.driverMap = props.driverMap;
    this.middle = true;
    this.model = { id: "", name: "" }
    this.grid = 1;
    this.gndOut = props.gndOut || "";
    this.classType = DRIVER_CLASS
  }
}

class ConnectionItem extends TreeItem {
  constructor(props) {
    super(props);
    this.rootNet = props.rootNet;
    this.prevNet = props.prevNet;
    this.prevComp = props.prevComp;
    this.anotherPrev = [];
    this.gnd = "";
    this.middle = true;
    this.resistance = props.resistance || "0";
    this.connectionIndex = props.connectionIndex;
    this.nextNet = props.nextNet || "";
    this.grid = 1;
    this.connectorPins = {};
    this.connectionType = props.connectionType;
    this.connectionKey = "";
    this.truePrevComp = props.truePrevComp || undefined;
    this.classType = CONN_CLASS;
    this.nextConnNet = props.nextConnNet || "";
  }
}

class TreeOption {
  constructor(props = {}) {
    const { lumped = true, showGnd = true, useInput = true, includeInd = false } = props;
    this.lumped = lumped;
    this.showGnd = showGnd;
    this.useInput = useInput;
    this.includeInd = includeInd;
  }
}

class TreePlotSetting {
  constructor(props = {}) {
    const {
      netColor = '#b0b0e1', gndColor = '#898989', connColor = '#7776ee',
      gridWidth = '260', gridHeight = '160', autoWidth = true, autoHeight = true,
      autoSize = true, nameSize = '16', valueDisplay = true,
      pass = true, passSize = '11', pairRes = true, pairResSize = '12', voltage = true, voltageSize = '10',
      displayRes = true, displayInd = true, displaySwitch = true, displayFerrite = true, displayDiode = true, displayJumper = true,
      displayTransistor = true,
      // constant
      displayroot =  true, displayLoad = true, displayVRM = true, displayDriver = true, displayConnector = true
    } = props;

    // net setting
    this.netColor = netColor;
    this.gndColor = gndColor;
    this.connColor = connColor;

    // grid setting
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
    this.autoWidth = autoWidth;
    this.autoHeight = autoHeight;

    // component setting
    this.autoSize = autoSize
    this.nameSize = nameSize;
    this.valueDisplay = valueDisplay;

    // result setting
    this.pass = pass;
    this.passSize = passSize;
    this.pairRes = pairRes;
    this.pairResSize = pairResSize;
    this.voltage = voltage;
    this.voltageSize = voltageSize;

    // show setting
    this.displayRes = displayRes;
    this.displayInd = displayInd;
    this.displaySwitch = displaySwitch;
    this.displayFerrite = displayFerrite;
    this.displayDiode = displayDiode;
    this.displayJumper = displayJumper;
    this.displayroot = displayroot;
    this.displayLoad = displayLoad;
    this.displayVRM = displayVRM;
    this.displayDriver = displayDriver;
    this.displayConnector = displayConnector;
    this.displayTransistor = displayTransistor;
  }
}

export {
  PowerTree,
  RootItem,
  ComponentItem,
  ICItem,
  DriverItem,
  ConnectionItem,
  TreeOption,
  TreePlotSetting
}