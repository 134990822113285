import React, { Component } from "react";
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { getPanelWidth, getPanelMaxHeight, getPanelMaxWidth } from "@/services/helper/panelSizeHelper";
import DebugDownloadContent from '@/components/DebugDownload_v2';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { IMPEDANCE } from "../../../../constants/treeConstants";
import { IMPEDANCE_TITLE, TEMPLATE_TITLE } from "../../../../services/Cascade/constants";
import '@/publicCss/style.css';
import '@/publicCss/debug.css';
import './index.css';

class SParameterModelDownload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadMenuList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 384),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.setState({
      downloadMenuList: this.getData()
    })
    this.resize();
  }

  getData = () => {
    const { projectId, verificationId, view } = this.props;
    const list = CascadeChannels.getList(view, projectId) || [];
    const findV = list.find(item => item.id === verificationId);

    const selectInfo = { id: verificationId, name: findV ? findV.name : "" }
    const { downloadList, currentCheckValues } = this._getDownloadList(true);
    let title = TEMPLATE_TITLE;
    if (view === IMPEDANCE) {
      title = IMPEDANCE_TITLE;
    }
    return [{
      title,
      key: "sParameterModelDownload",
      menuType: "sParameterModelDownload",
      info: {
        selectList: [...list],
        selectInfo
      },
      downloadList,
      currentCheckValues
    }]
  }


  _getDownloadList = (isGetValues) => {
    const downloadList = [
      { name: 'PDN Touchstone Models with VRM port', default: true, option: 'with_vrm_models' },
      { name: 'PDN Impedance with VRM shorted', default: false, option: 'vrm_shorted_models' },
      { name: "All domain S-parameter model with VRM ports", default: false, option: "with_vrm_models_all" },
      { name: "All domain S-parameter model with VRM shorted", default: false, option: "vrm_shorted_models_all" }];

    if (!isGetValues) {
      return downloadList;
    }
    let currentCheckValues = [];
    for (let item of downloadList) {
      if (item.default) {
        currentCheckValues.push({ name: item.option, children: item.children ? item.children.map(it => it.option) : [] });
      }
    }
    return { downloadList, currentCheckValues }
  }

  getDownloadURL = ({ verificationId, options, token }) => {
    return `/Cascade/download?verificationId=${verificationId}&options=${options}&access_token=${token}`;
  }

  getHeight = () => {
    const content = document.getElementById("s-parameter-download-content-id");

    if (content) {
      const height = content.offsetHeight + 70 || 336;
      return height > 336 ? height : 336;
    }
    return 336;
  }

  render() {
    const { maxWidth, maxHeight, downloadMenuList } = this.state;
    const height = this.getHeight();
    const content = (
      <Panel
        className='aurora-debug-panel cascade-s-parameter-download-panel panel-x-scroll-hidden'
        title={<div className='aurora-debug-title'>Download</div>}
        onCancel={this.props.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 450 })}
        position='panel-center'
        draggable
        minHeight={336}
        minWidth={450}
        maxHeight={maxHeight}
        height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <div id="s-parameter-download-content-id">
          <DebugDownloadContent
            debugMenuList={downloadMenuList}
            getDownloadList={this._getDownloadList}
            getDownloadSimulationURL={this.getDownloadURL}
          />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId } } } = state;
  return {
    projectId: openProjectId
  };
}

export default connect(mapState, null)(SParameterModelDownload);