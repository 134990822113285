import React, { Component, createRef } from 'react'
import { Select, Tooltip } from 'antd'
import { DECAP_TOUCHSTONE, DECAP_SPICE, DECAP_RLC } from '@/constants/libraryConstants';
import { parseSPModelSelector } from '@/services/Library';
import TreeSelect from '../TreeSelect';
import _ from 'lodash';

const { Option } = Select

export default class UserLibrary extends Component {
  constructor(props) {
    super(props)
    let model = props.model;
    let _decapType = "";
    if (model.libraryType === "decap_rlc") {
      _decapType = "RLC"
    } else if (model.libraryType === "decap_touchstone") {
      _decapType = "touchstone"
    } else {
      _decapType = "SPICE"
    }
    let selectCkts = []
    this.state = {
      model,
      decapType: _decapType,
      selectCkts: selectCkts,
      subcktDisable: this.checkSubcktDisable(model),
      subcktOpen: false,
      search: {
        model: "",
        subckt: ""
      },
      loading: props.loading,
      params: {},
      hasParam: -1,
    }

    this.selectDecapRef = createRef();
    this.selectRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { model } = this.props
    if (!_.isEqual(model, prevProps.model)) {
      let _decapType = "";
      if (model.libraryType === "decap_rlc") {
        _decapType = "RLC"
      } else if (model.libraryType === "decap_touchstone") {
        _decapType = "touchstone"
      } else {
        _decapType = "SPICE"
      }
      this.setState({
        model,
        decapType: _decapType,
        subcktDisable: this.checkSubcktDisable(model),
      })
    }
  }

  userLibraryTypeChange = (value) => {
    this.props.userLibraryTypeChange(value)
    this.setState({
      model: {},
      decapType: value,
    })
  }

  checkSubcktDisable = (model) => {
    if (model && model.id) {
      const findType = this.props.decapList.find(item => item.id === model.id);
      if (findType && (findType.type === 'file' || findType.type === DECAP_SPICE) && findType.dataType !== DECAP_TOUCHSTONE) {
        this.getSubckts(model.id, findType);
        return false;
      } else if (findType && findType.type === "folder") {
        return false
      } else {
        return true
      }
    } else {
      return true;
    }
  };

  selectModel = (modelId) => {
    let findItem
    for (let item of this.props.decapList) {
      if (item.id === modelId) {
        findItem = { ...item }
        break;
      } else if (item.children) {
        const _findItem = item.children.find(_item => _item.id === modelId)
        if (_findItem) {
          findItem = { ..._findItem }
          break
        }
      }
    }
    const _model = {
      id: findItem.id,
      name: (findItem.dataType === DECAP_TOUCHSTONE && findItem.type === "folder") ? "" : findItem.name,
      subcktName: '',
      type: findItem.dataType === DECAP_TOUCHSTONE ? "decap_touchstone" : "Decap",
      libraryType: findItem.dataType,
      folderName: (findItem.dataType === DECAP_TOUCHSTONE && findItem.type === "folder") ? findItem.name : ""
    }
    this.setState({
      model: _model,
      search: {
        model: '',
        subckt: ''
      }
    });
    if (findItem.type === "file" || findItem.type === DECAP_SPICE) {
      if (findItem.dataType === DECAP_TOUCHSTONE) {
        this.selectDecapRef.current.blur();
        this.props.modelChange(this.state.model)
      } else {
        this.setState({
          subcktDisable: false,
          loading: true,
        }, () => {
          this.getSubckts(modelId, findItem);
        })
      }
    } else {
      if (findItem.dataType === DECAP_TOUCHSTONE) {
        this.setState({
          loading: true,
          subcktDisable: false,
        }, () => {
          this.selectDecapRef.current.blur();
          this.getTouchstoneFiles(modelId, findItem)
          this.props.modelChange(this.state.model);
        })
      } else {
        this.setState({
          subcktDisable: true
        }, () => {
          this.selectDecapRef.current.blur();
          this.props.modelChange(this.state.model);
        })
      }
    }
    this.selectDecapRef.current.blur();
  };

  getTouchstoneFiles = (findItem) => {
    setTimeout(() => {
      this.setState({
        selectCkts: findItem.children ? findItem.children : [],
        loading: false,
        subcktOpen: true
      })
    }, 100)
  }

  getSubckts = (id) => {
    this.props.getLibraryFile(id).then(res => {
      const { models: subckts, type } = parseSPModelSelector(res);
      let { model } = this.state;
      if (subckts.length === 1) {
        model.subcktName = subckts[0].name;
        this.setState({
          model: { ...model },
          subcktOpen: false,
          subcktType: type
        }, () => {
          this.props.modelChange(this.state.model);
        });
      }
      if (subckts.length > 1 && !model.subcktName) {
        this.setState({
          subcktOpen: true,
          subcktType: type
        });
      }
      this.setState({
        selectCkts: subckts,
        loading: false,
        subcktType: type,
      })
    }, (error) => {
      this.setState({
        selectCkts: [],
        loading: false
      })
    })
  }

  valueSearch = (value, type) => {
    this.setState({
      search: {
        ...this.state.search,
        [type]: value
      }
    })
  }

  modelSelectList = () => {
    const { decapList } = this.props;
    const { decapType } = this.state;
    const _decapList = this.getDecapLists(decapList, decapType);
    return _decapList.map(decap => ({ value: decap.id, label: decap.name }))
  }

  getDecapLists = (decapList, decapType) => {
    if (decapType === 'touchstone') {
      const touchstoneList = decapList.filter(item => item.dataType === DECAP_TOUCHSTONE)
      const _touchstoneList = JSON.parse(JSON.stringify(touchstoneList))
      return _touchstoneList.map(item => {
        if (item.format && item.format === 'Folder') {
          item.children = item.children.map(item => {
            return { ...item, type: 'file' }
          })
          return { ...item, type: 'folder' }
        } else {
          return { ...item, type: 'file' }
        }
      })
    } else if (decapType === 'SPICE') {
      return decapList.filter(item => ['decap_file', DECAP_SPICE].includes(item.dataType))
    } else {
      return decapList.filter(item => ['decap_data', DECAP_RLC].includes(item.dataType))
    }
  }

  subcktFocus = () => {
    this.setState({
      subcktOpen: true,
    })
  }

  subcktSelect = (value) => {
    const { model } = this.state;
    this.setState({
      subcktOpen: false
    });
    this.selectRef.current.blur();
    if (model.subcktName === value) {
      this.props.modelChange(this.state.model)
    }
  }

  selectSubckt = (name) => {
    let model = { ...this.state.model };
    if (model.type === DECAP_TOUCHSTONE && model.libraryType === "folder") {
      model.name = name
    } else {
      model.subcktName = name;
    }
    this.setState({
      model,
      subcktOpen: false,
      search: {
        model: '',
        subckt: ''
      }
    }, () => {
      this.props.modelChange(this.state.model)
    })
  }

  displayTooltip = (params, index) => {
    this.setState({
      hasParam: index,
      params: params
    })
  }

  unDisplayTooltip = () => {
    this.setState({
      hasParam: -1,
      params: {}
    })
  }

  parameterTooltip = (param) => {
    if (!param) {
      return;
    }
    let ModelName = param.ModelName ? param.ModelName : "",
      Manuf = param.Manuf ? param.Manuf : "",
      MfgPartNumber = param.MfgPartNumber ? param.MfgPartNumber : "",
      Size = param.Size ? param.Size : "",
      Cnom = param.Cnom ? param.Cnom : "",
      Volt = param.Volt ? param.Volt : "";
    return <div>
      <span>ModelName = {ModelName}</span><br />
      <span>Manuf = {Manuf}</span><br />
      <span>MfgPartNumber = {MfgPartNumber}</span><br />
      <span>Size = {Size}</span><br />
      <span>Cnom = {Cnom}</span><br />
      <span>Volt = {Volt}</span><br />
    </div>
  }

  subcktSelectList = () => {
    const { subcktType, params, hasParam, selectCkts, model, search } = this.state;
    const { trigger = 'root' } = this.props;
    return selectCkts.filter(item => item.name.toLowerCase().includes(search.subckt.toLowerCase())).map(({ name, parameter }, index) => {
      return {
        value: name,
        label: subcktType === 'normal' ? <span>
          {name}
        </span> : <span
          onMouseEnter={() => { this.displayTooltip(parameter, index) }}
          onMouseLeave={() => { this.unDisplayTooltip() }}
        >
          {hasParam === index && <Tooltip
            title={this.parameterTooltip(params)}
            overlayClassName='aurora-tooltip system-library-tooltip'
            placement='left'
            defaultOpen={true}
            getPopupContainer={() => document.getElementById(trigger)}
          />}
          {name}
        </span>
      }
    })
  }

  render() {
    const { model, decapType, subcktDisable } = this.state
    const { userLibraryType, noSystemLibrary, decapList, typeOptions = ['touchstone', 'SPICE', 'RLC'] } = this.props
    return (
      <div>
        <div className="decap-select-model-content" style={noSystemLibrary ? { paddingTop: 10, paddingBottom: 10 } : {}}>
          <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">Type</span>
              <Select size="small" value={userLibraryType} onChange={this.userLibraryTypeChange} popupClassName="select-model-select-dropdown" dropdownStyle={{ zIndex: 1000000 }}>
                {typeOptions.includes("touchstone") && <Option value="touchstone">Touchstone</Option>}
                {typeOptions.includes("SPICE") && <Option value="SPICE">SPICE</Option>}
                {!noSystemLibrary && typeOptions.includes("RLC") && <Option value="RLC">RLC</Option>}
              </Select>
            </div>
          </div>
          <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">File</span>
              {userLibraryType === 'touchstone' ? <TreeSelect
                placeholder='Decap Model'
                selectRef={this.selectDecapRef}
                showSearch
                value={model.name}
                onSelectItem={(item) => this.selectModel(item.id)}
                className='select-model-selection-component'
                popupClassName="select-model-select-dropdown"
                // fileList={csmCpmSpiceList}
                size='small'
                dropdownRender={() => this.getDecapLists(decapList, decapType)}
              /> : <Select
                size="small"
                value={model.libraryType === DECAP_SPICE || model.libraryType === DECAP_TOUCHSTONE || model.libraryType === DECAP_RLC ? model.name : undefined}
                placeholder='Decap Model'
                onChange={this.selectModel}
                className='select-model-selection-component'
                ref={this.selectDecapRef}
                //could search
                showSearch
                onSearch={(value) => this.valueSearch(value, 'model')}
                popupClassName="select-model-select-dropdown"
                options={this.modelSelectList()}
              />}
            </div>
          </div>
          {userLibraryType === "SPICE" && <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">Model</span>
              <Select
                size="small"
                value={model.libraryType === DECAP_SPICE ? model.subcktName : undefined}
                placeholder={decapType === "Touchstone" ? 'File' : 'Subckt'}
                disabled={subcktDisable}
                onFocus={this.subcktFocus}
                onChange={this.selectSubckt}
                className='select-model-selection-component'
                ref={this.selectRef}
                showSearch
                onSearch={(value) => this.valueSearch(value, 'subckt')}
                options={this.subcktSelectList()}
                popupClassName="select-model-select-dropdown"
                popupMatchSelectWidth={false}
              >
              </Select>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}
