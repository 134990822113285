import React, { Component, Fragment } from 'react';
import Table from '@/components/EditableTable';
import { numberCheck } from '../../../../../services/helper/dataProcess';
import { OPEN_DRAIN, percentIndex, percentOutIndex, PUSH_PULL } from '../../../../../services/Sierra/helper/measurementHelper';
import { SettingOutlined } from '@ant-design/icons';
import { Popover, Radio } from 'antd';
import '../new.css';

const columns = [{
  title: 'Model',
  dataIndex: 'model',
  key: 'model',
  width: '12.5%',
  render: (text) => {
    return <span
      className="measurement-table-component-title"
      title={text}
    >{text}</span>
  }
}, {
  title: 'Pin',
  dataIndex: 'pin',
  key: 'pin',
  width: '12.5%'
}, {
  title: 'VDD',
  dataIndex: 'vdd',
  key: 'vdd',
  width: '7%'
},
{
  title: 'VIH',
  dataIndex: 'vinh',
  key: 'vinh',
  width: '13%'
}, {
  title: 'VIL',
  dataIndex: 'vinl',
  key: 'vinl',
  width: '13%'
},
{
  title: 'VOH',
  dataIndex: 'vouth',
  key: 'vouth',
  width: '13%'
}, {
  title: 'VOL',
  dataIndex: 'voutl',
  key: 'voutl',
  width: '13%'
}, {
  title: 'Vhigh',
  dataIndex: 'v_high',
  key: 'v_high',
  width: '8%'
}, {
  title: 'Vlow',
  dataIndex: 'v_low',
  key: 'v_low',
  width: '8%'
},];

const columnsIndex = ['v_high', 'v_low'];
const RadioGroup = Radio.Group

class MeasurementTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.columns = JSON.parse(JSON.stringify(columns));
  }

  componentDidMount() {
    this.initColumns()
  }

  initColumns = () => {

    this.columns.forEach(column => {

      if (column.dataIndex === "pin") {
        column.render = (text, record) => {
          let _text = "";
          const { type } = this.props;
          if (type === "result") {
            const list = text.split('::');
            if (list.length === 2) {
              const [comp, pin] = list;
              _text = `${comp} - ${pin}`;
            }
            if (list.length === 3) {
              const [pcb, comp, pin] = list;
              _text = `${comp} - ${pin} - ${pcb}`;
            }
          } else {
            const { Interfaces, isMultiPCB, isSweep } = this.props;
            _text = ((isSweep && isMultiPCB) || (Interfaces && Interfaces.length > 1)) && record.pcb ? `${record.comp} - ${record.pinNumber} - ${record.pcb}` : `${record.comp} - ${record.pinNumber}`;
          }

          return <span
            className="measurement-table-component-title"
            title={_text}
          >{_text}</span>
        }
      }

      if (column.dataIndex === "vdd") {
        column.title = () => {
          return (
            <div className="measurement-vdd-content">
              <span>VDD</span>
              <Popover
                content={this.vddDefinition()}
                title=""
                trigger="click"
              >
                <SettingOutlined title="Switch VDD Definition" className="measurement-vdd-setting-icon" />
              </Popover>
            </div>
          );
        }
      }

      if (columnsIndex.includes(column.dataIndex)) {
        const { isBase } = this.props;
        column.onCell = (record) => {
          return {
            record,
            edit: isBase ? false : true,
            dataIndex: column.dataIndex,
            allowEmpty: true,
            handleSave: (record) => this.editVoltage(record, column.dataIndex)
          }
        }
      } else if ([...percentIndex, ...percentOutIndex].includes(column.dataIndex)) {
        column.onCell = (record) => {
          const { isBase } = this.props;
          if (isBase) {
            return {
              edit: false
            }
          }
          let _dataIndex = column.dataIndex;
          if (percentOutIndex.includes(column.dataIndex) && record.usage === "Driver") {
            _dataIndex = column.dataIndex.replace("out", "in")
          } else if (record.usage === "Driver"
            || (percentOutIndex.includes(column.dataIndex) && record.usage !== "Driver")) {
            return { edit: false };
          }
          const unit = record[`${_dataIndex}_per`] ? "%" : "V";
          return {
            record,
            edit: 'input-with-select',
            dataIndex: record[`${_dataIndex}_per`] ? `${_dataIndex}_per` : _dataIndex,
            handleSave: (record) => this.savePercentValue(record, _dataIndex),
            allowEmpty: true,
            unit,
            unitList: [{ key: "V", title: 'V' }, { key: "%", title: '% VDD' }]
          }
        }

        column.render = (text, record) => {
          let _dataIndex = column.dataIndex;
          if (percentOutIndex.includes(column.dataIndex) && record.usage === "Driver") {
            _dataIndex = column.dataIndex.replace("out", "in")
          } else if (record.usage === "Driver" ||
            (percentOutIndex.includes(column.dataIndex) && record.usage !== "Driver")) {
            return null;
          }
          if (record[`${_dataIndex}_per`]) {
            return `${record[`${_dataIndex}_per`]}% (${record[_dataIndex]})`;
          }
          return record[_dataIndex];
        }
      }
    })
  }


  vddDefinition = () => {
    const { modelType, isBase } = this.props;
    return <div className="measure-vdd-radio-content">
      <RadioGroup
        onChange={this.props.updateModelType}
        className="measure-vdd-radio-group"
        value={modelType}
        disabled={isBase || false}
      >
        <Radio value={OPEN_DRAIN}>{OPEN_DRAIN}</Radio>
        <Radio value={PUSH_PULL}>{PUSH_PULL}</Radio>
      </RadioGroup>
    </div>
  }

  editVoltage = (record, dataIndex) => {
    const { tableData } = this.props;
    let _tableData = [...tableData];

    const index = tableData.findIndex(t => t.pin === record.pin);
    if (index > -1) {
      const value = record[dataIndex];
      if (parseFloat(value) === parseFloat(_tableData[index][dataIndex])) {
        return;
      }
      const error = numberCheck(value);
      if (!error) {
        _tableData[index][dataIndex] = Number(value);
        this.props.updateTableData(_tableData, true);
      }
    }
  }

  savePercentValue = (record, dataIndex) => {
    const { value, unit } = record;
    const { tableData } = this.props;
    let _tableData = [...tableData];

    const index = tableData.findIndex(t => t.pin === record.pin);
    if (index > -1) {
      if ((unit === "%" && parseFloat(value) === parseFloat(_tableData[index][`${dataIndex}_per`]))
        || (unit === "V" && parseFloat(value) === parseFloat(_tableData[index][dataIndex]))) {
        return;
      }
      const error = numberCheck(value);
      if (!error) {
        if (unit === '%') {
          const vdd = _tableData[index]['vdd'];
          _tableData[index][dataIndex] = Number((Number(value) / 100 * vdd).toFixed(4));
          _tableData[index][`${dataIndex}_per`] = Number(value)
        } else {
          _tableData[index][dataIndex] = Number(value);
          _tableData[index][`${dataIndex}_per`] = null;
          delete _tableData[index][`${dataIndex}_per`]
        }
        this.props.updateTableData(_tableData, true);
      }
    }
  }

  render() {
    const { tableData, } = this.props;
    return <Fragment>
      <Table
        columns={this.columns}
        rowKey={(record) => record.pin}
        dataSource={JSON.parse(JSON.stringify(tableData || []))}
        bordered
        size="small"
        tableLayout="fixed"
        className="post-process-panel-data-table"
      />
    </Fragment>
  }

}

export default MeasurementTable;