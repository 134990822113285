class ProjectDB {
  constructor() {
    this.projectList = new Map(); //key - project id, value :{ id ,name, version , .... }
  }

  getSize = () => {
    return this.projectList.size;
  };

  getProjectList = () => {
    return this.projectList;
  }

  getProjectValues = () => {
    let list = [];
    for (let val of this.projectList.values()) {
      list.push(val);
    }
    return list;
  }

  getProject = (id) => {
    return this.projectList.get(id);
  }

  addProject = (id, data) => {
    this.projectList.set(id, data);
  }

  delProject = (id) => {
    this.projectList.delete(id);
  }

  updateProject = (newData) => {
    this.projectList = newData;
  }

  clearCache = () => {
    this.projectList = new Map();
  }
};

export default ProjectDB;