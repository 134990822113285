import CeLayerDocument from './CeLayerDocument';
import CeLayerMask from './CeLayerMask';
import CeLayerPaste from './CeLayerPaste';
import CeLayerSilkScreen from './CeLayerSilkScreen';

var CeLayerMaker = function () {};

CeLayerMaker.CreateLogicLayer = function (type) {
  var layer = null;
  if (type == "SILK_SCREEN")
    layer = new CeLayerSilkScreen();
  else if (type == "SOLDER_MASK")
    layer = new CeLayerMask();
  else if (type == "SOLDER_PASTE")
    layer = new CeLayerPaste();
  else if (type == "DOCUMENT")
    layer = new CeLayerDocument();
  else
    console.error("Layer type is not yet supported in AddLogicLayer.");
  return layer;
}

export default CeLayerMaker;