import React, { PureComponent } from 'react';
import Panel from '../Panel';
import { createPortal } from 'react-dom';
import { Spin } from 'antd';
import './style.css';

class FileDisplayPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.root = props.root || document.getElementById('root');
    this.width = props.width || this.getDefaultWidth();
    this.state = {
      width: this.width - 32,
      height: null,
    }
  }

  getDefaultWidth = () => {
    const offset = this.root.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    const _width = width * 0.8;
    return _width > 1000 ? 1000 : _width;
  }

  changePanelSize = (obj) => {
    this.setState({
      height: obj.height,
      width: obj.width - 36
    })
  }

  render() {
    const { closePanel, title, file, footer, id = '', mask = false, fileLoding = false } = this.props;
    const { height, width } = this.state;
    const _height = height ? height - 78 : 400;

    const content = (
      <Panel
        id={id}
        position='panel-center-left'
        title={<div>{title}</div>}
        onCancel={closePanel}
        zIndex={2000}
        width={this.width}
        mask={mask}
        draggable
        resize={this.changePanelSize}
        footer={footer || null}
        className='file-content-panel'
        minWidth={200}
        minHeight={200}
      >
        <div className='file-content-display'>
          <Spin spinning={fileLoding} tip="Loading...">
            <pre style={{ margin: 0, overflow: 'auto', height: _height, width: width }} className='file-content'>
              {file}
            </pre>
          </Spin>
        </div>
      </Panel>
    )
    return createPortal(content, this.root)
  }
}

export default FileDisplayPanel;