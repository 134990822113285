import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import CeGeomHolder from '../geometry/CeGeomHolder';

var FutprtPadTemplate = function (id) {
  if (id == undefined || id == null)
    this.mID = 0;
  else
    this.mID = id;
  this.mGeomHolder = new CeGeomHolder();
};

FutprtPadTemplate.prototype.GetID = function () {
  return this.mID;
}

FutprtPadTemplate.prototype.AddGeometry = function (padGeom) {
  this.mGeomHolder.push(padGeom);
}

FutprtPadTemplate.prototype.GetGeometry = function () {
  return this.mGeomHolder.get(0);
}

FutprtPadTemplate.prototype.GetGeometryList = function () {
  return this.mGeomHolder;
}

FutprtPadTemplate.prototype.WriteIntoIODataNode = function (myBlock) {
  myBlock.AddDataItem("TemplateID", this.mID);
  var geomListBlock = new CeIODataBlock("GeometryList");
  this.mGeomHolder.WriteIntoIODataNode(geomListBlock);
  myBlock.AddBlockNode(geomListBlock);
}

FutprtPadTemplate.prototype.ReadFromIODataNode = function (myBlock) {
  this.mID = myBlock.GetItemLong("TemplateID", this.mID);
  var geomListBlock = myBlock.GetSubBlock("GeometryList");
  this.mGeomHolder.ReadFromIODataNode(geomListBlock);
}

FutprtPadTemplate.prototype.HasSameGeometry = function (padGeom) {
  var myGeom = this.GetGeometry();
  return this.mGeomHolder.size() == 1 && myGeom.equals(padGeom);
}

export default FutprtPadTemplate;