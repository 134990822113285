import { parseSPFolderModelSelector } from './index';

class SpFolderModelHelper {
  constructor(id, fileNames) {
    this[id] = [];
    for (const name of fileNames) {
      this[id].push({
        fileName: name,
        models: null
      })
    }
  }

  parseModelSelector(id, fileContent) {
    this[id] = parseSPFolderModelSelector(this[id], fileContent);
  }

  /*get model list by filename and type(Rx, Tx)*/
  getModelList(id, fileNames) {
    const files = this[id];
    const models = Array.isArray(files) ? files.filter(item => fileNames.includes(item.fileName) && item.models) : null
    return models;
  }

  deleteModel(id) {
    delete this[id]
  }
}

export default SpFolderModelHelper;