import React, { Component, Fragment } from 'react';
import { Button, Collapse } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import {
  adsConfigItemErrorCheck,
  PRBS,
  AMI_SIMULATION,
  updateAmiSimOptions,
  updateBitRateByProtocolType,
  ENCODER,
  updateEncoderByProtocolType,
  EYEMASK,
  NOTPPU,
  ADSOPTION,
  updateAdsConfigSignals
} from '@/services/Andes_v2/AMIModelHelper';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import SignalSetup from './signalSetup';
import AMISimBasicSetup from './amiSimBasicSetup';
import { END_TO_END_CHANNEL } from '../../../constants/treeConstants';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import { EYE_MASK, ADS_OPTIONS } from '@/constants/libraryConstants';
import "./index.css";
import { PROBE_LIST } from '../../../services/Andes_v2/AMIModelHelper';
import { numberCheck } from '../../../services/helper/dataProcess';
import { CPHY } from '../../../services/PCBHelper/constants';
import CPHYSignalSetup from './CPHYSignalSetup';

const AMI_SETUP = "Model Setup", SIMULATION_SETTING = "Simulation Settings";
const AMI = "AMI", IBIS = "IBIS";

class AMIModel extends Component {
  constructor(props) {
    super(props);
    const signal = props.adsConfig && props.adsConfig.signals && props.adsConfig.signals[0] ? props.adsConfig.signals[0] : {};
    let modelType = AMI;
    if (signal.IbisHasAMI === "no") {
      modelType = IBIS;
    }
    this.state = {
      maxWidth: 1260,
      maxHeight: 620,
      pageOption: AMI_SETUP,
      modelType,
      componentPkgInfo: {}
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    this.setState = () => false;
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 620),
      maxWidth: getPanelMaxWidth(offset, 1260)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { adsConfig = {}, signals, signal_map, interfaceType } = this.props;
    const signal = adsConfig.signals && adsConfig.signals[0] ? adsConfig.signals[0] : {};
    let modelType = AMI;
    if (signal.IbisHasAMI === "no") {
      modelType = IBIS;
    }

    this.setState({
      modelType
    })
    this.setComponentPkgInfo(adsConfig);
    const { adsConfig: newConfig, save } = updateAdsConfigSignals({ adsConfig, signals, signal_map, interfaceType });
    if (save) {
      this.props._updateADSConfig(newConfig, { isCloseModal: false, id: null })
    }
  }

  setComponentPkgInfo = (adsConfig) => {
    const { components = [], interfaceType, controllerICComps, deviceICComps } = this.props;
    let controllerPkg, devicePkg;
    let controllerKey = adsConfig.controller,
      deviceKey = adsConfig.device;
    if (interfaceType === END_TO_END_CHANNEL) {
      controllerPkg = ((controllerICComps || []).find(item => item.name === adsConfig.controller) || {}).pkg;
      devicePkg = ((deviceICComps || []).find(item => item.name === adsConfig.device) || {}).pkg;
      controllerKey = `${adsConfig.controller}::${adsConfig.controllerChannel.channelId}`;
      deviceKey = adsConfig.deviceChannel ? `${adsConfig.device}::${adsConfig.deviceChannel.channelId}` : "";
    } else {
      controllerPkg = ((components || []).find(item => item.name === adsConfig.controller) || {}).pkg;
      devicePkg = ((components || []).find(item => item.name === adsConfig.device) || {}).pkg;
    }
    this.setState({
      componentPkgInfo: {
        [controllerKey]: controllerPkg && controllerPkg.type !== "None",
        [deviceKey]: devicePkg && devicePkg.type !== "None"
      }
    })
  }

  componentDidUpdate = (prevProps) => {
    const { interfaceType, adsConfig = {}, controllerICComps } = this.props;
    if (interfaceType === END_TO_END_CHANNEL && !prevProps.controllerICComps && controllerICComps) {
      this.setComponentPkgInfo(adsConfig)
    }
  }

  closeModal = (isSimulate, id) => {
    if (this.state.error) {
      return;
    }
    this.saveConfig(isSimulate ? id : null)
    this.props.closePanel();
  }

  saveConfig = (id) => {
    const { adsConfig } = this.props;
    this.props._updateADSConfig(adsConfig, { isCloseModal: true, id })
  }

  saveConfigValue = (value, type, parentType, unit, save = true) => {
    const { adsConfig, serdesType } = this.props;
    let _adsConfig = { ...adsConfig };
    const _value = unit ? `${value} ${unit}` : value;
    if (parentType) {
      if (type === NOTPPU) {
        _adsConfig[parentType].advancedSetting[type] = _value;
      } else if (type === EYEMASK) {
        if (value) {
          const eyeLibraryList = libraryConstructor.getLibraryValues(EYE_MASK);
          const findInfo = eyeLibraryList.find(item => item.id === value)
          if (findInfo) {
            _adsConfig[parentType].eyeMask = { libraryId: findInfo.id }
          }
        } else {
          // clear eye mask Info
          _adsConfig[parentType].eyeMask = { libraryId: "" }
        }
      } else if (type === ADSOPTION) {
        if (value) {
          const adsOptionLibraryList = libraryConstructor.getLibraryValues(ADS_OPTIONS);
          const findInfo = adsOptionLibraryList.find(item => item.id === value)
          if (findInfo) {
            _adsConfig[parentType] = findInfo.id;
            _adsConfig.ymlOptionLibraryName = findInfo.name;
          }
        } else {
          _adsConfig[parentType] = '';
          _adsConfig.ymlOptionLibraryName = '';
        }
      } else {
        _adsConfig[parentType][type] = _value;
      }
    } else {
      _adsConfig[type] = _value;
    }

    if (type === "type" && parentType === PRBS) {
      _adsConfig[PRBS] = updateBitRateByProtocolType(_adsConfig[PRBS], value, serdesType);
      _adsConfig[ENCODER] = updateEncoderByProtocolType(value, serdesType);
    }

    if (type === "mode" && parentType === AMI_SIMULATION) {
      _adsConfig = updateAmiSimOptions(_adsConfig, _value)
    }

    this.props._updateADSConfig(_adsConfig, { save });
  }

  checkChange = (e, type, parentType) => {
    const value = e.target.checked;
    this.saveConfigValue(value, type, parentType);
  }

  inputChange = (e, type, parentType, unit) => {
    const value = e.target.value;
    const { error } = this.state;
    this.setState({
      error: error && error.type === type ? null : error
    })
    this.saveConfigValue(value, type, parentType, unit, false);
  }

  changeTagList = (e, valueList, type, parentType) => {
    let errorMsg = null;
    for (let value of valueList) {
      errorMsg = numberCheck(value);
      if (errorMsg) {
        errorMsg = `${type} value must be a number!`;
      }
    }

    if (errorMsg) {
      this.setState({
        error: { type, error: errorMsg }
      })
    } else {
      const { error } = this.state;
      this.setState({
        error: error && error.type === type ? null : error
      })
      this.saveConfigValue(valueList, type, parentType);
    }
  }

  saveMultiConfigValue = (key, type, parentType) => {
    const { adsConfig } = this.props;
    let _adsConfig = { ...adsConfig };
    PROBE_LIST.forEach(item => {
      if (key.includes(item)) {
        _adsConfig[parentType][item] = true;
      } else {
        _adsConfig[parentType][item] = false;
      }
    })
    this.props._updateADSConfig(_adsConfig, { save: true });
  }

  inputBlur = (e, type, parentType, unit) => {
    const value = e.target.value;
    const { error: _error } = adsConfigItemErrorCheck({ value, type });
    if (_error) {
      e.target.focus();
      this.setState({
        error: { type, error: _error }
      })
    } else {
      const { error } = this.state;
      this.setState({
        error: error && error.type === type ? null : error
      })
      this.saveConfigValue(value, type, parentType, unit);
    }
  }

  render() {
    const { adsConfig = {}, signals, adsSignals, selectedSignals, interfaceType, serdesType, adsAllSignals } = this.props;
    const { maxHeight, maxWidth, pageOption, error } = this.state;
    const content = (
      <Panel
        className='andes-ads-model-setup-panel panel-x-scroll-hidden'
        title={<div className='andes-ads-model-setup-panel-title'>Simulation Setup{/* {pageOption === AMI_SETUP ? "Signals AMI Setup" : "Simulation Settings"} */}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        position='panel-center'
        panelBodyId={"andes-ads-model-setup-panel-body"}
        draggable
        minHeight={200}
        minWidth={200}
        width={getPanelWidth(maxWidth, { defaultWidth: 1260 })}
        maxHeight={maxHeight}
        overflow={"auto"}
        footer={this.footerOptions(pageOption)}
      >
        <div className='andes-ami-model-main' id="andes-ads-model-setup-main">
          {this.simulationSettingRender({
            adsConfig,
            maxHeight,
            serdesType
          })}
          {this.amiModelAssignRender({ adsConfig, adsSignals, signals, selectedSignals, interfaceType, serdesType, adsAllSignals })}
          {error ? <span className="aurora-error-msg-span">{error.error}</span> : null}
        </div>
      </Panel>)
    return createPortal(content, this.dialogRoot);
  }

  changePage = (key) => {
    this.setState({
      pageOption: key
    })
  }

  simulateClick = () => {
    const { channelId, endToEndId, interfaceType } = this.props;
    if (this.state.error) {
      return;
    }
    const id = interfaceType === END_TO_END_CHANNEL ? endToEndId : channelId;
    this.closeModal(true, id);
  }

  amiModelAssignRender = ({ adsConfig, adsSignals, signals, selectedSignals, interfaceType, serdesType, adsAllSignals }) => {
    const { modelType, componentPkgInfo } = this.state;
    const { components = [], controllerICComps, deviceICComps, controllerInfo = {}, deviceInfo = {}, contrPCBIndex, devicePCBIndex } = this.props
    const { controllerCircuitModelExist, deviceCircuitModelExist } = serdesType !== CPHY ? this.getCircuitModelExist(adsConfig) : {};
    const items = [{
      key: "ami_setup",
      label: <div className="ami-model-collapse-title">
        Signals Buffer
      </div>,
      children: <Fragment>
        {serdesType === CPHY ? <CPHYSignalSetup
          adsSignals={adsSignals}
          adsConfig={adsConfig}
          signals={signals}
          serdesType={serdesType}
          modelType={IBIS}
          selectedSignals={selectedSignals}
          interfaceType={interfaceType}
          _updateADSConfig={this.props._updateADSConfig}
          adsAllSignals={adsAllSignals}
          onRef={this.signalSetupOnRef}
          componentPkgInfo={componentPkgInfo}
          setComponentPkgInfo={this.setComponentPkgInfo}
          components={components}
          controllerICComps={controllerICComps}
          deviceICComps={deviceICComps}
          controllerInfo={controllerInfo}
          deviceInfo={deviceInfo}
          contrPCBIndex={contrPCBIndex}
          devicePCBIndex={devicePCBIndex}
        /> : <SignalSetup
          adsSignals={adsSignals}
          adsConfig={adsConfig}
          signals={signals}
          serdesType={serdesType}
          modelType={modelType}
          modelKey={modelType === AMI ? "Ami" : ""}
          selectedSignals={selectedSignals}
          interfaceType={interfaceType}
          _updateADSConfig={this.props._updateADSConfig}
          adsAllSignals={adsAllSignals}
          onRef={this.signalSetupOnRef}
          controllerCircuitModelExist={controllerCircuitModelExist}
          deviceCircuitModelExist={deviceCircuitModelExist}
          componentPkgInfo={componentPkgInfo}
          changeModelType={this.changeModelType}
          setComponentPkgInfo={this.setComponentPkgInfo}
          components={components}
          controllerICComps={controllerICComps}
          deviceICComps={deviceICComps}
          controllerInfo={controllerInfo}
          deviceInfo={deviceInfo}
          contrPCBIndex={contrPCBIndex}
          devicePCBIndex={devicePCBIndex}
        />}
      </Fragment>
    }]
    return <Collapse className="ami-model-collapse-content" defaultActiveKey={["ami_setup"]} items={items} />
  }

  signalSetupOnRef = (ref) => {
    this.signalSetupChild = ref;
  }

  simulationSettingRender = ({ adsConfig, maxHeight, serdesType }) => {
    return <AMISimBasicSetup
      adsConfig={adsConfig}
      maxHeight={maxHeight}
      serdesType={serdesType}
      saveConfigValue={this.saveConfigValue}
      inputChange={this.inputChange}
      inputBlur={this.inputBlur}
      checkChange={this.checkChange}
      _updateADSConfig={this.props._updateADSConfig}
      changeTagList={this.changeTagList}
      saveMultiConfigValue={this.saveMultiConfigValue}
    />
    /*    </Collapse.Panel> */
    /* </Collapse> */
  }

  footerOptions = (pageOption) => {
    if (this.props.isEndToEndChildren) {
      return null;
    }
    //const { left, right } = this.getFooterOption(pageOption);
    return <div className='ami-setup-footer-options'>
      <div>
        {/* {left && <div
          className="ami-setup-left-options"
          onClick={() => this.changePage(left)}
        >
          <Icon type="left" />
          <span>{left}</span>
        </div>} */}
      </div>
      <div className="ami-setup-middle-options">
        <Button size="small" onClick={() => this.simulateClick()}>
          Simulate
        </Button>
      </div>
      <div>
        {/* {right && <div
          className="ami-setup-right-options"
          onClick={() => this.changePage(right)}
        >
          <span>{right}</span>
          <Icon type="right" />
        </div>} */}
      </div>
    </div>
  }

  getFooterOption = (pageOption) => {
    let left, right;
    switch (pageOption) {
      case AMI_SETUP:
        right = SIMULATION_SETTING;
        break;
      case SIMULATION_SETTING:
        left = AMI_SETUP;
        break;
      default: break;
    }
    return { left, right }
  }

  getCircuitModelExist = (adsConfig = {}) => {
    const { interfaceType, controllerInfo, deviceInfo } = this.props;
    let findControllerModel = false, findDeviceModel = false;
    const signals = adsConfig.signals || [];
    if (interfaceType === END_TO_END_CHANNEL) {
      findControllerModel = signals.find(item =>
        (item.txCircuitModel
          && item.txCircuitModel.component === adsConfig.controller
          && controllerInfo && item.txCircuitModel.channelId === controllerInfo.id
        )
        || (item.rxCircuitModel
          && item.rxCircuitModel.component === adsConfig.controller
          && controllerInfo && item.rxCircuitModel.channelId === controllerInfo.id));

      findDeviceModel = signals.find(item =>
        (item.txCircuitModel
          && item.txCircuitModel.component === adsConfig.device
          && deviceInfo && item.txCircuitModel.channelId === deviceInfo.id)
        || (item.rxCircuitModel
          && item.rxCircuitModel.component === adsConfig.device
          && deviceInfo && item.rxCircuitModel.channelId === deviceInfo.id));
    } else {
      findControllerModel = signals.find(item =>
        (item.txCircuitModel && item.txCircuitModel.component === adsConfig.controller)
        || (item.rxCircuitModel && item.rxCircuitModel.component === adsConfig.controller));

      findDeviceModel = signals.find(item =>
        (item.txCircuitModel && item.txCircuitModel.component === adsConfig.device)
        || (item.rxCircuitModel && item.rxCircuitModel.component === adsConfig.device));

    }
    return { controllerCircuitModelExist: findControllerModel, deviceCircuitModelExist: findDeviceModel }
  }

  changeModelType = (key) => {
    this.setState({ modelType: key })
  }
}


export default AMIModel;