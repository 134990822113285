import {
  UPDATE_ROCKY_CONTENT,
  UPDATE_ROCKY_CONTENT_INFO,
  UPDATE_ROCKY_INTERFACES,
  UPDATE_CURRENT_CONFIG,
  UPDATE_ERROR_CHECK_LIST,
  UPDATE_CURRENT_LIBRARY_FILE_LIST,
  COMPONENT_LOADING,
  CLEAN_SETUP_INFO,
  UPDATE_TOUCHSTONE_STATUS_LIST,
  UPDATE_SERVICE_CONFIG,
  UPDATE_PREV_STIMULUS_MODE,
  SWITCH_RANK_STATUS,
  UPDATE_PACKAGE_LOADING,
  UPDATE_PACKAGE_MSG,
  UPDATE_ROCKY_PACKAGE_LAYOUT_INFO,
  CLEAN_PACKAGE_SETUP_INFO,
  UPDATE_PACKAGE_SETUP_LOADING,
  UPDATE_PACKAGE_CONTENT_INFO,
  UPDATE_APPLY_SETUP_STATUS,
  UPDATE_SAVE_STATUS,
  UPDATE_PACKAGE_PDN_INFO,
  UPDATE_USER_DEFINED_NETLSIT_VALUE,
  UPDATE_PCB_CHANNEL_CONTENT,
  UPDATE_PDN_ERROR_CHECK,
  UPDATE_SSN_PACKAGE_INFO,
  UPDATE_MODEL_ASSIGN_LOADING,
  UPDATE_POWER_COMPONENTS_APPLY_LOADING,
  UPDATE_DECAP_MODEL_SETUP_LOADING,
  IS_UPDATE_PACKAGE_PIN_STATUS,
  UPDATE_CHANNEL_ERROR_CHECK_LIST,
  UPDATE_CHANNEL_REFERENCE_NETS,
  UPDATE_CONTENT_LOADING,
  UPDATE_LOADING
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  rockyInfo: null,
  currentConfig: {},
  copyAllBytes: false,
  infoErrorCheck: [],
  currentLibraryFileList: [],
  componentLoading: false,
  TouchstoneStatusList: [],
  prevStimulusMode: null,
  switchRank: false,
  packageLoading: false,
  packageMsg: [],
  rockyPackageInfo: {},
  packageSetupLoading: false,
  applySetupStatus: false,
  saveStatus: "",
  packagePDNInfo: {},
  userDefinedNetlist: [],
  modelAssignLoading: false,
  powerComponentsApplyLoading: false,
  powerComponentsModelSetLoading: false,
  channelErrorCheck: [],
  contentLoading: false
}

export const RockyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ROCKY_CONTENT:
      return {
        ...state,
        rockyInfo: {
          ...action.rockyInfo
        }
      }
    case UPDATE_ROCKY_CONTENT_INFO:
      return {
        ...state,
        rockyInfo: {
          ...state.rockyInfo,
          info: {
            ...state.rockyInfo.info,
            ...action.data
          }
        }
      }
    case UPDATE_ROCKY_INTERFACES:
      return {
        ...state,
        rockyInfo: {
          ...state.rockyInfo,
          ...action.data
        }
      }
    case UPDATE_CURRENT_CONFIG:
      return {
        ...state,
        currentConfig: action.currentConfig
      }
    case UPDATE_ERROR_CHECK_LIST:
      return {
        ...state,
        infoErrorCheck: action.infoErrorCheck
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_CURRENT_LIBRARY_FILE_LIST:
      return {
        ...state,
        currentLibraryFileList: action.list
      }
    case COMPONENT_LOADING:
      return {
        ...state,
        componentLoading: action.loading
      };
    case CLEAN_SETUP_INFO:
      //clearPrev clear prev open verification info(one verification)
      let clearPrev = action.clearPrev;
      let pageType = action.pageType
      let newState = clearPrev ? {
        ...defaultState,
        currentConfig: state.currentConfig,
        infoErrorCheck: state.infoErrorCheck
      }
        : pageType === 'verification' ? {
          ...defaultState,
          rockyPackageInfo: state.rockyPackageInfo,
        } : { ...defaultState };
      return { ...newState };
    case UPDATE_TOUCHSTONE_STATUS_LIST:
      return {
        ...state,
        TouchstoneStatusList: [...action.list]
      }
    case UPDATE_SERVICE_CONFIG:
      return {
        ...state,
        hspiceOption: action.hspiceOption,
        ansysOption: action.ansysOption,
        options: action.options
      }
    case UPDATE_PREV_STIMULUS_MODE:
      return {
        ...state,
        prevStimulusMode: action.prevStimulusMode
      }
    case SWITCH_RANK_STATUS:
      return {
        ...state,
        switchRank: action.status
      }
    case UPDATE_PACKAGE_LOADING:
      return {
        ...state,
        packageLoading: action.loading
      }
    case UPDATE_PACKAGE_MSG:
      return {
        ...state,
        packageMsg: action.errorMsgInfo
      }
    case UPDATE_ROCKY_PACKAGE_LAYOUT_INFO:
      return {
        ...state,
        rockyPackageInfo: action.rockyPackageInfo
      }
    case CLEAN_PACKAGE_SETUP_INFO:
      return {
        ...state,
        rockyPackageInfo: {},
      }
    case UPDATE_PACKAGE_SETUP_LOADING:
      return {
        ...state,
        packageSetupLoading: action.loading
      }
    case UPDATE_PACKAGE_CONTENT_INFO:
      return {
        ...state,
        rockyPackageInfo: {
          ...state.rockyPackageInfo,
          content: {
            ...state.rockyPackageInfo.content,
            ...action.obj
          }
        }
      }
    case UPDATE_APPLY_SETUP_STATUS:
      return {
        ...state,
        applySetupStatus: action.status
      }
    case UPDATE_SAVE_STATUS:
      return {
        ...state,
        saveStatus: action.status
      }
    case UPDATE_PACKAGE_PDN_INFO:
      return {
        ...state,
        packagePDNInfo: action.info
      }
    case UPDATE_USER_DEFINED_NETLSIT_VALUE:
      return {
        ...state,
        userDefinedNetlist: action.list
      }
    case UPDATE_PCB_CHANNEL_CONTENT:
      return {
        ...state,
        pcbChannelInfo: action.content
      }
    case UPDATE_PDN_ERROR_CHECK:
      return {
        ...state,
        pdnErrorCheckList: action.pdnErrorCheckList
      }
    case UPDATE_SSN_PACKAGE_INFO:
      return {
        ...state,
        isUpdateSsnPackageChannel: action.status
      }
    case UPDATE_MODEL_ASSIGN_LOADING:
      return {
        ...state,
        modelAssignLoading: action.loading
      }
    case UPDATE_POWER_COMPONENTS_APPLY_LOADING:
      return {
        ...state,
        powerComponentsApplyLoading: action.loading
      }
    case UPDATE_DECAP_MODEL_SETUP_LOADING:
      return {
        ...state,
        powerComponentsModelSetLoading: action.loading
      }
    case IS_UPDATE_PACKAGE_PIN_STATUS:
      return {
        ...state,
        updatePackagePinStatus: action.status
      }
    case UPDATE_CHANNEL_ERROR_CHECK_LIST:
      return {
        ...state,
        channelErrorCheck: action.channelErrorCheck
      }
    case UPDATE_CONTENT_LOADING:
      return {
        ...state,
        contentLoading: action.loading
      }
    case UPDATE_LOADING:
      return {
        ...state,
        [action.loadingKey]: action.loading
      }
    default: return state
  }
};