import { connect } from 'react-redux';
import { saveData } from './store/actionCreators';
import { changeStackupTable, settingUnit } from './store/actionCreators';
import Stackup from '../../components/stackup';
import { reCheckSimulationStackup } from '../../../PDN/store/simulation/action';


const mapState = (state) => {
  const { stackup: { stackupObj = {} } } = state.PDNReducer;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return {
    stackupObj, defaultLeft, defaultTop
  }
}

const mapDispatch = (dispatch) => ({
  setStackupData(data, unit, designID) {
    dispatch(saveData(data, unit, designID));
  },
  changeTable(data, unit, designID) {
    dispatch(changeStackupTable(data, unit, designID));
  },
  settingUnit(unit, designID) {
    dispatch(settingUnit(unit, designID));
  },
  _reCheckSimulation(data, designID) {
    dispatch(reCheckSimulationStackup(data, designID));
  }
})

export default connect(mapState, mapDispatch)(Stackup);