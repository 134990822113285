import React, { Component, Fragment } from "react";
import { Input } from 'antd';
import { numberCheck } from "../../../services/helper/dataProcess";
import { valueUnitSplit } from "../../../services/helper/valueUnitSplit";
import UnitAddonAfter from "../../UnitAddonAfter";
import ReferenceComponentPin from "./ReferenceComponentPin";
import { PORT_REFERENCE_COMPONENT } from "../../../services/ExtractionPortsHelper/portsSetup";
import './index.css';

class ReferenceComponent extends Component {

  constructor(props) {
    super(props);
    const findComponent = props.findComponent || {};
    const gapSizeObj = valueUnitSplit(findComponent.gap_size);
    const ballSizeObj = valueUnitSplit(findComponent.ball_size,'donot know');
    const ballHeightObj = valueUnitSplit(findComponent.ball_height);
    this.state = {
      referencePinVisible: false,
      gapSize: gapSizeObj.value || "0",
      gapSizeUnit: gapSizeObj.unit || "mm",
      ballSize: ballSizeObj.value || "",
      ballSizeUnit: ballSizeObj.unit || "mm",
      ballHeight: ballHeightObj.value || "",
      ballHeightUnit: ballHeightObj.unit || "mm",
    }
  }

  addonAfter = (type) => {
    const unit = this.state[`${type}Unit`];
    return UnitAddonAfter({
      unit,
      changeUnit: (key) => this.changeUnit(key, type),
      list: ["mm", "mil"]
    });
  }

  changeUnit = (unit, type) => {
    this.setState({
      [`${type}Unit`]: unit
    })
  }

  openReferencePinSetup = () => {
    this.setState({
      referencePinVisible: true
    })
  }

  closeReferencePins = (pins, nets) => {
    this.setState({
      referencePinVisible: false
    });
    this.props.changeReference(PORT_REFERENCE_COMPONENT, { pins, nets });
  }

  updateValue = (e, type) => {
    this.setState({
      [type]: e.target.value
    });
    const { errorMsg } = this.props;
    if (errorMsg && errorMsg.type === type) {
      this.props.updateError(null);
    }
  }

  valueOnBlur = (e, type) => {
    const value = e.target.value;
    let error = null;
    if (!["ballSize", "ballHeight"].includes(type) || value) {
      error = numberCheck(value);
    }
    if (error) {
      this.props.updateError({ type, error: `${this.getLabel(type)} ${error}` });
    }
  }

  getLabel = (type) => {
    switch (type) {
      case "gapSize":
        return "Reference Plane Distance";
      case "ballSize":
        return "Size";
      case "ballHeight":
        return "Height";
      case "distance":
        return "Search distance";
      case "maxPins":
        return "Max number of pins";
      default: return null;
    }
  }

  render() {
    const { referenceNets, reference } = this.props;
    const { pins = [], nets = [] } = reference;
    const { gapSize, ballSize, ballHeight, referencePinVisible } = this.state;
    return <Fragment>
      <div className='signal-port-edit-item'>
        <label>Reference Pins</label>
        <div
          className='signal-port-edit-reference-pins-content'
          onClick={this.openReferencePinSetup}
        >
          {nets.length ?
            "All pins on reference nets"
            : pins.join(", ")}
        </div>
      </div>
      {this.getInputComponent({
        value: gapSize,
        className: "signal-port-edit-item",
        type: "gapSize",
        addonAfter: this.addonAfter
      })}
      <div className='signal-port-edit-item signal-port-edit-ball-item'>
        <label>Solder Ball​</label>
        <div className='signal-port-edit-ball-sub-item'>
          {this.getInputComponent({
            value: ballSize,
            className: "signal-port-edit-sub-item",
            type: "ballSize",
            addonAfter: this.addonAfter
          })}
          {this.getInputComponent({
            value: ballHeight,
            className: "signal-port-edit-sub-item",
            type: "ballHeight",
            addonAfter: this.addonAfter
          })}
        </div>
      </div>
      {referencePinVisible ? <ReferenceComponentPin
        {...this.props}
        reference={reference}
        pinType={nets.length ? "all" : "specify"}
        referenceNets={referenceNets}
        closePanel={this.closeReferencePins}
        getLabel={this.getLabel}
        getInputComponent={this.getInputComponent}
      /> : null}
    </Fragment>
  }

  getInputComponent = ({
    value,
    type,
    className,
    addonAfter,
    updateValue,
    valueOnBlur
  }) => {
    return <div className={className}>
      <label>{this.getLabel(type)}</label>
      <Input
        className='aurora-input'
        value={value}
        onChange={(e) => updateValue ? updateValue(e, type) : this.updateValue(e, type)}
        onBlur={(e) => valueOnBlur ? valueOnBlur(e, type) : this.valueOnBlur(e, type)}
        addonAfter={addonAfter ? addonAfter(type) : null}
      />
    </div>
  }
}

export default ReferenceComponent;