import UploadMonitor from './UploadMonitor';
import SimulationMonitor from './SimulationMonitor';
import DetailMonitor from './detailMonitor';
import ProfileTable from './profileTable';
import { monitorChild } from '@/components/MonitorFooter';
import { CASCADE } from '../../../../constants/pageType';
import SpecifyService from '../../../CCC/SpecifyService';
import LayoutMonitor from './LayoutMonitor';

function _monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, width = undefined, pageType = CASCADE, titleSelectedKeys, setupType }) {
  return monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, width, pageType, titleSelectedKeys, setupType },
    UploadMonitor,
    SimulationMonitor,
    DetailMonitor,
    ProfileTable,
    SpecifyService,
    LayoutMonitor
  )
}

export default _monitorChild;