import React, { Component } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Divider, Col, Select } from 'antd';
import ResultGenerateBtn from '@/components/ResultGenerateBtn';
import { WAITING, VERIFY_RUNNING } from '@/constants/verificationStatus';
import { numberCheck } from '@/services/helper/dataProcess';
import { getResBlob } from '@/services/helper/downloadHelper';
import { ANDES_V2 } from '@/constants/pageType';
import TagsInput from '@/components/TagsInput';
import FileSaver from 'file-saver';
import { ROCKY } from '../../constants/pageType';
import { scaleConversion } from '../../services/helper/numberHelper';
import { PRELAYOUT } from '../../services/Rocky/preLayout/preLayoutConfig';
import PowerSumTDRSetting from '../../services/Rocky/result/powerTDRSetting';

const { Option } = Select;
const PowersumFrequenciesError = "Please enter at least one PowerSum analysis frequency";
class PowerSumSetting extends Component {
  constructor(config) {
    super()
    this.state = {
      powersum_frequencies: [],
      libraryIds: [],
      setupShow: true,
      targetLineShow: true,
      settingError: null,
      targetLineError: null,
      generateLoading: false,
      downloadLoading: false
    }
    this.setting = null;
  }

  componentDidMount() {
    const { id, pageType, updateSetting, changeSettingStatus } = this.props;
    if (id && pageType !== ROCKY) {
      this.getSettingData();
    }
    if (updateSetting && pageType === ROCKY) {
      this.getRockySettingData();
      updateSetting && changeSettingStatus && changeSettingStatus(false)
    }
  }

  componentDidUpdate(prevProps) {
    const { id, status } = this.props;
    const { updateSetting, pageType, changeSettingStatus } = this.props;
    if (id && id !== prevProps.id && pageType !== ROCKY) {
      this.getSettingData(false);
    }

    if (prevProps.status !== status && status) {
      if (![WAITING, VERIFY_RUNNING].includes(status)) {
        this.setState({
          generateLoading: false
        })
      }
    }

    if (updateSetting && pageType === ROCKY) {
      this.getRockySettingData(false);
      updateSetting && changeSettingStatus && changeSettingStatus(false)
    }
  }

  async getSettingData() {
    const { id, isEndToEnd, sweepId, dataRate = '16GT/s', getTDRSBRSettingData, Setting, channelId, pageType } = this.props;

    this.setting = new Setting(id);
    const data = pageType === ROCKY ? await getTDRSBRSettingData(id, channelId) : await getTDRSBRSettingData(id, isEndToEnd, sweepId);
    this.setting.updateData(data);

    if ((this.setting.powersum_frequencies || []).length === 0) {
      this.setting.powersum_frequencies.push(`${parseFloat((parseFloat(dataRate) / 2).toFixed(3))} GHz`);
    }

    this.setState({
      powersum_frequencies: this.setting.powersum_frequencies
    })

  }

  getRockySettingData() {
    const { reportConfig, channelId } = this.props;
    this.setting = new PowerSumTDRSetting(channelId);
    this.setting.updateData(reportConfig);

    this.setState({
      powersum_frequencies: this.setting.powersum_frequencies,
      libraryIds: this.setting.libraryIds
    })
  }

  titleClick = (key, value) => {
    this.setState({ [key]: !value })
  }

  recalculate = async () => {
    const { isEndToEnd, sweepId, channelId, id, pageType } = this.props;
    this.setState({
      settingError: '',
      generateLoading: true
    })
    if (pageType === ROCKY) {
      const params = this.setting.getSetting(null, true, false)
      this.generateTDRSBRResultFun(params, id, channelId)
    } else {
      const params = this.setting.getSetting({ generate_powersum: true, generate_waveform: false })
      const error = this.getPowersumFrequenciesError(params)
      if (error) { return }
      delete params.datarate;
      this.generateTDRSBRResultFun(params, isEndToEnd, sweepId)
    }
  }

  getPowersumFrequenciesError(params, library) {
    let settingError = ""
    if (params && params.powersum_frequencies && !params.powersum_frequencies.length) {
      settingError = "PowerSum analysis frequency"
      return true
    }
    if (library && params && params.libraryIds && !params.libraryIds.length) {
      settingError = settingError ? `${settingError} And Touchstone Files` : "Touchstone Files";
    }
    if (settingError) {
      this.setState({
        settingError: `Please enter at least one ${settingError}`,
        generateLoading: false
      })
      return true
    }
    return false
  }

  generateTDRSBRResultFun = (params, isEndToEnd, sweepId) => {
    const { generateTDRSBRResult } = this.props;
    generateTDRSBRResult(params, isEndToEnd, sweepId).then(res => {
      try {
        const workflow = res;
        this.props.recalculateTDRSBRWorkflow(workflow, isEndToEnd);
      } catch (error) {
        this.setState({
          settingError: `Generate failed! ${error}`,
          generateLoading: false
        })
      }
    }, error => {
      this.setState({
        error: error,
        generateLoading: false
      })
    })
  }

  getBlobInfo = () => {
    let url = "", setting = null;
    const { pageType, id, channelId } = this.props;
    const { powersum_frequencies, libraryIds } = this.state;
    const token = localStorage.getItem('token');
    if (pageType === ANDES_V2) {
      url = `/api/v3/andes/channel/powersum/table/download?channelId=${id}`;
      setting = { type: "GET", token, pageType: ANDES_V2 };
    } else if (pageType === ROCKY) {
      url = `/api/v3/rocky/ssn/channel/result/power-sum/table/download?verificationId=${id}&channelId=${channelId}`;
      setting = { type: "POST", token, pageType: ROCKY, params: { powersum_frequencies: powersum_frequencies || [], libraryIds: libraryIds } };
    } else {
      setting = { type: "GET", token, pageType: ROCKY };
    }
    return { url, setting }
  }

  downloadTable = () => {
    const { powersum_frequencies } = this.state;
    const { pageType, interfaceType } = this.props;
    if (pageType === ROCKY) {
      const params = this.setting;
      const error = this.getPowersumFrequenciesError(params, interfaceType === PRELAYOUT ? true : false)
      if (error) { return }
    } else if (!powersum_frequencies.length) {
      this.setState({
        settingError: PowersumFrequenciesError,
      })
      return
    }
    this.setState({ downloadLoading: true });
    const { url, setting } = this.getBlobInfo()
    getResBlob(url, setting).then(blob => {
      const name = 'PowerSum_Breakdown_Sheets.zip';
      FileSaver.saveAs(blob, name);
      this.setState({ downloadLoading: false })
    }).catch(error => {
      console.error(error);
      this.setState({ downloadLoading: false })
    })
  }

  changeList = (type, list, focus, _value) => {
    const { maxFreq, pageType, reportConfig } = this.props;
    const value = _value ? _value.replace(/\s(Hz|KHz|MHz|GHz)$/, "") : _value;
    let isChange = false
    if (!numberCheck(value)) {
      const [value, unit] = _value.split(" ");
      const toHzValue = Number(value) * scaleConversion("Hz", unit);
      if (toHzValue <= maxFreq || !maxFreq) {
        isChange = true;
      }
    }

    if (isChange || !_value) {
      this.setting.powersum_frequencies = list;
      this.setState({ powersum_frequencies: list, settingError: "" });
      pageType === ROCKY && this.props.updateReportInfo({ reportConfig: { ...this.setting, powerSumTargetLine: reportConfig.powerSumTargetLine } })
    }
  }

  handleChange = (values, b, c) => {
    this.setting.libraryIds = values;
    this.setState({ libraryIds: values, settingError: "" })
    this.props.pageType === ROCKY && this.props.updateReportInfo({ reportConfig: { ...this.setting, powerSumTargetLine: this.props.reportConfig.powerSumTargetLine } })
  }

  settingRender = () => {
    const { setupShow, settingError, generateLoading, downloadLoading, powersum_frequencies, libraryIds } = this.state;
    const { pageType, folderList = [], interfaceType } = this.props;
    return <>
      <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
        <div className='result-import-title' onClick={() => this.titleClick('setupShow', setupShow)}>
          {setupShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
          <span className='sparameter-result-menu-title'>Setup</span>
        </div>
      </Divider>
      {setupShow && (
        <>
          <Row>
            <span className='powersum-config-title'>PowerSum analysis frequency</span>
            <TagsInput
              type="powersum_frequencies"
              className="powersum-setting-tags-input"
              tagList={powersum_frequencies}
              allowedDuplicates={true}
              displayInput={true}
              unit={"GHz"}
              unitOptions={['Hz', 'KHz', 'MHz', 'GHz']}
              isAddonAfter={true}
              changeTagList={this.changeList}
            />
          </Row>
          {pageType === ROCKY && interfaceType === PRELAYOUT ?
            <Row>
              <span className='powersum-config-title'>Touchstone Files</span>
              <Select
                mode="multiple"
                value={libraryIds}
                onChange={this.handleChange}
                className="powersum-setting-tags-input"
              >
                {folderList.map(item => <Option key={item.libraryId}>{item.fileName}</Option>)}
              </Select>
            </Row> : null}
          {settingError ? <div className='error-msg'>{settingError}</div> : null}
          <Row gutter={8}>
            <Col span={12}>
              {ResultGenerateBtn({
                onClick: this.recalculate,
                loading: 'btn',
                ifLoading: generateLoading,
                disabled: settingError && pageType !== ROCKY ? true : false,
                btnName: 'Generate'
              })}
            </Col>
            <Col span={12}>
              {ResultGenerateBtn({
                onClick: this.downloadTable,
                loading: 'btn',
                ifLoading: downloadLoading,
                loadingInfo: 'Downloading...',
                btnName: 'Download Table'
              })}
            </Col>
          </Row>
        </>
      )}
    </>;
  }

  changeTargetLine = (type, list, _value) => {
    const { xMin, xMax, yMin, yMax } = this.props.getAxis();
    const { verticalLine, horizontalLine } = this.props;
    let targetLineError = null;

    if (_value) {
      const value = Number(_value);
      // check if input is number
      if (numberCheck(_value)) {
        targetLineError = 'Please input number';
      } else {
        // check if input is in range
        if (type === 'verticalLine') {
          if (value > xMax || value < xMin) {
            targetLineError = `Please enter VerticalLine at ${xMin} ~ ${xMax}`;
          } else if (verticalLine.includes(value)) return;
        } else {
          if (value > yMax || value < yMin) {
            targetLineError = `Please enter HorizontalLine at ${yMin} ~ ${yMax}`;
          } else if (horizontalLine.includes(value)) return;
        }
      }
    }

    if (targetLineError) {
      this.setState({ targetLineError });
      setTimeout(() => {
        this.setState({ targetLineError: null })
      }, 3000)
      return;
    }

    this.props.setTargetLinesValue({
      verticalLine,
      horizontalLine,
      drawType: type,
      [type]: list.map(d => Number(d)),
      isDraw: true
    });
  }

  targetLinesRender = () => {
    const { targetLineShow, targetLineError } = this.state;
    const { verticalLine, horizontalLine } = this.props;
    const { xMin, xMax, yMin, yMax } = this.props.getAxis();

    const lineInfo = [
      {
        title: 'Vertical lines',
        type: 'verticalLine',
        value: verticalLine.filter(d => d >= xMin && d <= xMax),
        filterValue: verticalLine.filter(d => d < xMin || d > xMax),
        unit: 'GHz'
      },
      {
        title: 'Horizontal Lines',
        type: 'horizontalLine',
        value: horizontalLine.filter(d => d >= yMin && d <= yMax),
        filterValue: horizontalLine.filter(d => d < yMin || d > yMax),
        unit: 'dB'
      }
    ]
    return <>
      <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
        <div className='result-import-title' onClick={() => this.titleClick('targetLineShow', targetLineShow)}>
          {targetLineShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
          <span className='sparameter-result-menu-title'>Target Line</span>
        </div>
      </Divider>
      {targetLineShow && (
        <>
          {
            lineInfo.map(info => (<Row key={info.type}>
              <span className='config-title'>{info.title}</span>
              <TagsInput
                type={info.type}
                className="powersum-target-lines-tags-input"
                tagList={info.value}
                allowedDuplicates={true}
                displayInput={true}
                changeTagList={(type, list, focus, _value) => this.changeTargetLine(type, [...list, ...info.filterValue], _value)}
                addonAfterUnit={info.unit}
              />
            </Row>))
          }
          {targetLineError ? <div className='error-msg'>{targetLineError}</div> : null}
        </>
      )}
    </>;
  }

  render() {
    return (
      <div className='tdr-sbr-setting'>
        {this.settingRender()}
        {this.targetLinesRender()}
      </div>
    )
  }
}

export default PowerSumSetting;