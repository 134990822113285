import React, { PureComponent } from "react";
import { createPortal } from 'react-dom';
import { Input } from "antd";
import Panel from '@/components/Panel';
import { getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { ANDES_V2, CASCADE, ROCKY, HIMALAYAS } from "../../constants/pageType";
import { PROJECT_V2 } from "../../constants/projectVersion";
import '@/publicCss/style.css';
import './index.css';

const CUSTOM_WV_TITLE = "Custom PP", HSPICE = 'HSPICE', ANSYS = 'Ansys', ADS = 'ADS', PCB_PROCESSING = 'PCB Processing', MACRO_MODELING = 'Macro Modeling';
class ServiceOption extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 660,
      options: []
    }
    this.dialogRoot = document.getElementById('root');
  }



  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 660)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    const _options = this.getOptionsByProduct();
    this.setState({
      options: _options
    }, () => {
      //get project service options config
      this.props.getServiceOptionsConfig(this.props.projectId).then(res => {
        this.getConfigByProduct(res)
      }, error => {
        this.getConfigByProduct({ hspiceOption: "", adsOption: "", ansysOption: "", options: {} })
      })
    })
  }

  getOptionsByProduct = () => {
    const { product, projectVersion } = this.props;
    switch (product) {
      case CASCADE:
        return [ANSYS, PCB_PROCESSING]
      case ANDES_V2:
        return [ADS, ANSYS, PCB_PROCESSING, HSPICE, MACRO_MODELING]
      case ROCKY:
        return projectVersion === PROJECT_V2 ? [HSPICE, ANSYS, CUSTOM_WV_TITLE, PCB_PROCESSING] : [HSPICE, ANSYS, PCB_PROCESSING]
      case HIMALAYAS:
        return [HSPICE]
      default: return [];
    }
  }

  getConfigByProduct = (configs) => {
    const { product } = this.props;
    const { hspiceOption, ansysOption, adsOption, options } = configs;
    switch (product) {
      case CASCADE:
        this.props._updateServiceConfig({ ansysOption, options });
        break;
      case ANDES_V2:
        this.props._updateServiceConfig({ adsOption, ansysOption, hspiceOption, options });
        break;
      case ROCKY:
      case HIMALAYAS:
        this.props._updateServiceConfig(hspiceOption, ansysOption, options);
        break;
      default: break;
    }
  }

  handleChange = (e, type) => {
    const { hspiceOption, ansysOption, adsOption, optionsValue: options } = this.props;
    const value = e.target.value;
    const _value = value.replace(/\u200b/g, '');
    const configs = { hspiceOption, ansysOption, adsOption, options }
    switch (type) {
      // Himalayas/Rocky/Andes
      case HSPICE:
        configs.hspiceOption = _value
        break;
      // Rocky/Andes/Cascade
      case ANSYS:
        configs.ansysOption = _value;
        break;
      // Andes
      case ADS:
        configs.adsOption = _value;
        break;
      // Andes/Rocky/Cascade
      case PCB_PROCESSING:
        configs.options = { ...options, pcbProcessingOption: _value }
        break;
      case CUSTOM_WV_TITLE:
        configs.options = { ...options, customWVOption: _value }
        break;
      // Andes
      case MACRO_MODELING:
        configs.options = { ...options, macroModelingOption: _value }
        break;
      default: break;
    }
    this.getConfigByProduct(configs)
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  getOptionValue = (key) => {
    if ([HSPICE, ANSYS, ADS].includes(key)) {
      return this.props[`${key.toLowerCase()}Option`]
    } else {
      const options = this.props.optionsValue || {};
      switch (key) {
        case PCB_PROCESSING:
          return options.pcbProcessingOption || "";
        case CUSTOM_WV_TITLE:
          return options.customWVOption || "";
        case MACRO_MODELING:
          return options.macroModelingOption || "";
        default:
          return "";
      }
    }
  }

  render() {
    const { maxWidth, options = [] } = this.state;
    const content = (
      <Panel
        className='service-option-panel aurora-x-scroll-hidden'
        title={<div className='service-option-title'>Service Options</div>}
        onCancel={this.props.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 660 })}
        position='panel-center'
        draggable
        minHeight={140}
        minWidth={200}
      >
        <div className='service-option-content'>
          {options.map(item =>
            <div className='service-option-item' key={item}>
              <label className="service-option-name">{item}</label>
              <Input
                key={item}
                className="service-option-input"
                value={this.getOptionValue(item)}
                onChange={e => this.handleChange(e, item)}
                onKeyDown={e => this.handleKeyDown(e, item)}
              />
            </div>)}
        </div>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default ServiceOption