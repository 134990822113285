export const NEW_INTERFACE = 'new_interface';
export const SAVE_CONFIG = 'save_config';
export const CREATE_INTERFACE = 'create_interface';
export const UPDATE_VIEW = 'update_view';
export const UPDATE_CONFIG = 'update_config';
export const ADD_SIGNAL = 'add_signal';
export const ADD_REFERENCE_NETS = 'add_reference_nets';
export const SAVE_CONFIG_SUCCESS = 'save_config_success';
export const GET_INTERFACES_LIST = 'get_interfaces_list';
export const SAVE_INTERFACE_FAILED = 'save_interface_failed';
export const GET_INTERFACES_LIST_SUCCESS = 'get_inetrface_list_success';
export const GET_INTERFACE = 'get_interface';
export const GET_INTERFACE_SUCCESS = 'get_interface_success';
export const CHANGE_LANES = 'change_lanes';
export const UPDATE_LANES = 'update_lanes';
export const DELETE_INTERFACE = 'delete_interface';