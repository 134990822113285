/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { getIRResultListPromise, getIRResultFilePromise, getResultPowerDomainPromise } from '../../../../../services/Cascade/IRExplorer/IRCtrl';
import { GET_IR_LIST_VALUE, UPDATE_IR_SELECTED_KEY, CHANGE_RESULT_TYPE } from './actionType';
import { saveIRValue, updateIRSelectedKey, getIRInfoSuccess, updateIRResultTableLoading, clearIRResultData } from './action';
import { IRVia, IRPin } from '../../../../../services/Cascade/result/IR/IRTable';
import { openPage } from '../../project/action';
import { accSub } from '../../../../../services/Cascade/helper/match';
import { IR_EXPLORER, IMPEDANCE, IMPEDANCE_RESULT, IR_EXPLORER_RESULT } from '../../../../../constants/treeConstants';

function* getIRValue(action) {
  const { verificationId } = action;
  try {
    const readInfo = yield call(getIRResultListPromise, verificationId);
    const { CascadeReducer: { IR: { IRExplorerInfo: { IRTableData } } } } = yield select();
    let list = [], grounds = [];
    IRTableData.forEach(item => { grounds = [...new Set([...grounds, ...item.gndNets])] });
    for (let key in readInfo) {
      if (grounds.includes(key)) {
        list.push({ key, id: readInfo[key] });
      } else {
        list.unshift({ key, id: readInfo[key] });
      }
    }
    const _resultPowerDomainData = yield call(getResultPowerDomain, verificationId)
    yield put(saveIRValue(list, _resultPowerDomainData));
    if (list.length) {
      yield put(updateIRSelectedKey(list[0].id));
    }
  } catch (error) {
    console.error(error)
  }
}

function* getResultPowerDomain(verificationId) {
  try {
    const responseData = yield call(getResultPowerDomainPromise, verificationId);
    if (responseData && responseData.length) {
      const _resultPowerDomainData = responseData.map(item => {
        return { ...item.content, id: item.id, allPowerNet: [...item.content.powerNets, ...item.content.extendNets] }
      })
      return _resultPowerDomainData
    } else {
      return []
    }
  } catch (error) {
    console.error(error)
    return [];
  }
}

function* getIRResultFileData({ IRSelectedKey, type, verificationId, resultKey, IRMenuList, resultPowerDomainData }) {
  let irData = [];
  try {
    const res = yield call(getIRResultFilePromise, IRSelectedKey, type, verificationId);
    if (res) {
      const IRMenuItem = IRMenuList.find(item => item.id === IRSelectedKey)
      const powerKey = resultPowerDomainData.filter(it => it.powerNets.includes(IRMenuItem.key))
      let data = res.match(/[^\r\n]+/g);
      for (let item of data) {
        let itemArr = item.split(',');
        if (resultKey === 'via') {
          irData.push(new IRVia(itemArr, IRMenuItem.key));
        } else {
          let voltageDrop = '';
          if (powerKey.length) {
            voltageDrop = accSub(powerKey[0].nominal, itemArr[6])
          } else {
            // ground Net 
            // or resultPowerDomainData.length === 0, use the voltage data to display
            voltageDrop = (itemArr[6] * 1000).toFixed(2)
          }
          irData.push(new IRPin(itemArr, IRMenuItem.key, voltageDrop));
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
  return irData
}

function* getIRValueFile(action) {
  const { IRSelectedKey } = action;
  const { CascadeReducer: { IRResult: { resultKey, IRMenuList, resultPowerDomainData }, IR: { verificationId } } } = yield select();
  const type = resultKey === 'via' ? 1 : 0;
  let data = yield call(getIRResultFileData, { IRSelectedKey, type, verificationId, resultKey, IRMenuList, resultPowerDomainData });
  if (data.length) {
    yield put(getIRInfoSuccess(data))
  }

  yield put(updateIRResultTableLoading(false))
}

function* _updateChannelResultType(action) {
  const { resultKey } = action;
  const { CascadeReducer: { project: { viewList }, IR: { verificationId: irVerificationId }, Impedance: { verificationId } } } = yield select();
  if (resultKey === "setup") {
    yield put(clearIRResultData())
    if (viewList.includes(IR_EXPLORER_RESULT)) {
      yield put(openPage({ pageType: IR_EXPLORER, id: irVerificationId }));
    } else if (viewList.includes(IMPEDANCE_RESULT)) {
      yield put(openPage({ pageType: IMPEDANCE, id: verificationId }));
    }
  }
}

function* IRResultSaga() {
  yield takeEvery(GET_IR_LIST_VALUE, getIRValue);
  yield takeLatest(UPDATE_IR_SELECTED_KEY, getIRValueFile);
  yield takeEvery(CHANGE_RESULT_TYPE, _updateChannelResultType);
}

export default IRResultSaga