import CeGeomTools from './CeGeomTools';
import CeGeometry from './CeGeometry';
import CePolygon from './CePolygon';
import CePolygonWithHole from './CePolygonWithHole';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

const CeSurface = function () {
  CeGeometry.call(this, "Surface");
  this.mPolygons = [];
}

// subclass extends superclass
CeSurface.prototype = Object.create(CeGeometry.prototype);
CeSurface.prototype.constructor = CeSurface;

CeSurface.prototype.SetData = function (polygons) {
  this.mPolygons = polygons;
}

CeSurface.prototype.AddPolygon = function (polygon) {
  this.mPolygons.push(polygon);
}

CeSurface.prototype.GetPolygonNumber = function () {
  return this.mPolygons.length;
}

CeSurface.prototype.CopyFrom = function (fromObj) {
  this.mPolygons = [];
  for (var i = 0; i < fromObj.mPolygons.length; i++) {
    this.mPolygons.push(fromObj.mPolygons[i].Clone());
  }
}

CeSurface.prototype.Clone = function () {
  var clnObj = new CeSurface();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeSurface.prototype.Scaling = function (scaleFactor) {
  if (CeGeomTools.TwoNumberEqual(scaleFactor, 1.0) == true)
    return;

  var polygon;
  for (var i = 0; i < this.mPolygons.length; i++) {
    this.mPolygons[i].Scaling(scaleFactor);
  }
}

CeSurface.prototype.GetPolygon = function (i) {
  if (i < 0 || i >= this.mPolygons.length)
    return null;
  return this.mPolygons[i];
}

CeSurface.prototype.ConvertToGCPolygon = function () {
  if (this.mPolygons.length == 1)
    return this.mPolygons[0].ConvertToGCPolygon();
  return null;
}

CeSurface.prototype.ConvertToGCPolygons = function (section) {
  var polyList = [];
  for (var i = 0; i < this.mPolygons.length; i++) {
    polyList.push(this.mPolygons[i].ConvertToGCPolygon());
  }
  return polyList;
}

CeSurface.prototype.ContainPolygonWithHole = function () {
  for (var i = 0; i < this.mPolygons.length; i++) {
    if (this.mPolygons[i] instanceof CePolygonWithHole)
      return true;
  }
  return false;
}

CeSurface.prototype.WriteIntoIODataNode = function () {

  var myBlock = new CeIODataBlock(this.GetGeomType());
  for (var i = 0; i < this.mPolygons.length; i++) {
    myBlock.AddBlockNode(this.mPolygons[i].WriteIntoIODataNode());
  }
  return myBlock;
}

CeSurface.prototype.ReadFromIODataNode = function (myBlock) {
  var subBlocks = myBlock.GetAllSubBlocks();
  for (var i = 0; i < subBlocks.length; i++) {
    var geomType = subBlocks[i].GetName();
    var polygon;
    if (geomType == "Polygon")
      polygon = new CePolygon();
    else if (geomType == "PolygonHole")
      polygon = new CePolygonWithHole();
    else
      console.log("Geometry type to construct surface is not yet supporte: " + geomType);

    //    var polygon = CeGeomFactory.CreateGeometryFromType(subBlocks[i].GetName());
    if (polygon.ReadFromIODataNode(subBlocks[i]))
      this.mPolygons.push(polygon);
  }
  return this.mPolygons.length > 0;
}

export default CeSurface;