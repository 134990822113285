import { I2CI3C, SPIQSPIeSPI, UART, I2S, CLKTYPE, CUSTOM } from '../../PCBHelper/constants';
const I2C = 'I2C', I3C = 'I3C',
  CLK = 'CLK', NetNamePrefix = 'netNamePrefix', Data = 'Data',
  SCK = 'SCK', SCL = 'SCL', SDA = 'SDA', SCLK = 'SCLK',
  SPI = 'SPI', QSPI = 'QSPI', ESPI = 'ESPI',
  ChipSelect = 'ChipSelect', TX = 'TX', RX = 'RX',
  isDifferential = 'isDifferential',
  WS = 'WS', SD = 'SD', MCLK = 'MCLK',
  AllowVoltageKeywords = 'allowVoltageKeywords',
  SPMI = "SPMI", SDATA = "SDATA", DAT = "DAT", DATA = 'DATA', EXCLUDE = "EXCLUDE", GROUP_NAME = "groupName";
class defaultAdvancedConfig {
  constructor(type = '', { netNamePrefix = [], CLK = [], Data = [], isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || [];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.CLK = CLK || [];
    this.Data = Data || [];
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class I2CI3CAdvancedConfig {
  constructor(type = '', { netNamePrefix, CLK, Data, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || [I2C, I3C];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.CLK = CLK || [SCK, SCL, "CLK"];
    this.Data = Data || [SDA, DAT];
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class defaultCustomConfig {
  constructor(type = '', { netNamePrefix, isDifferential, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || [];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.isDifferential = isDifferential || false;
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class CLKAdvanceConfig {
  constructor(type, { netNamePrefix, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || ['CLK', 'TCXO', 'XIN', 'XOUT', 'CK'];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.isRegular = isRegular || false;
    // this.CLK = ['CLK'];
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class SPI_qSPI_eSPIAdvancesConfig {
  constructor(type, { netNamePrefix, CLK, SPI, QSPI, ESPI, ChipSelect, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || ['SPI'];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.CLK = CLK || ['SCK', 'SCL', 'SCLK', 'CLK'];
    this.SPI = SPI || ['SI', 'SO', 'MOSI', 'MISO', 'DI', 'DO', 'D0', 'D1', 'D2', 'D3'];
    this.QSPI = QSPI || ['IO', 'SI', 'SO', 'MOSI', 'MISO', 'DAT', 'D0', 'D1', 'D2', 'D3'];
    this.ESPI = ESPI || ['IO', 'DAT'];
    this.ChipSelect = ChipSelect || ['SS', 'CS'];
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class UARTAdvanceConfig {
  constructor(type, { netNamePrefix, TX, RX, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || ['UART'];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.TX = TX || ['TX'];
    this.RX = RX || ['RX'];
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class I2SAdvanceConfig {
  constructor(type, { netNamePrefix, CLK, WS, SD, MCLK, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || ['I2S'];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.CLK = CLK || ['SCK', 'SCL', 'SCLK', 'CLK'];
    this.WS = WS || ['LRCLK', 'LRCK', 'FSYNC', 'SYNC', 'SFRM'];
    this.SD = SD || ['DATA', 'IN', 'OUT', 'DAT', 'TXD', 'RXD'];
    this.MCLK = MCLK || ['MCLK'];
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

class SPMIAdvanceConfig {
  constructor(type = '', { netNamePrefix, CLK, Data, isRegular, allowVoltageKeywords, EXCLUDE, excludeIsRegular }) {
    this.type = type;
    this.netNamePrefix = netNamePrefix || [SPMI];
    this.allowVoltageKeywords = allowVoltageKeywords || false;
    this.CLK = CLK || [SCLK, "CLK"];
    this.Data = Data || [SDATA, DATA, SDA, DAT];
    this.isRegular = isRegular || false;
    this.EXCLUDE = EXCLUDE || [];
    this.excludeIsRegular = excludeIsRegular || true;
  }
}

export const SIERRA_IDENTIFICATION_TYPES = [{
  key: I2CI3C,
  title: 'I2C / I3C',
}, {
  key: SPIQSPIeSPI,
  title: 'SPI / QSPI / eSPI',
}, {
  key: UART,
  title: 'UART',
}, {
  key: I2S,
  title: 'I2S',
}, {
  key: CLKTYPE,
  title: 'CLK',
}, {
  key: SPMI,
  title: 'SPMI',
}, {
  key: CUSTOM,
  title: 'Custom',
}]

const addInterfaceInputList = [/* {
  title: "PCB",
  key: "pcb"
}, */ {
    title: "Type",
    key: "interfaceType",
    placeholder: 'Interface Type',
    list: SIERRA_IDENTIFICATION_TYPES
  },/*  {
    title: "Name",
    key: "name"
  }, */ {
    title: "Component",
    key: "component"
  },
  {
    title: "Group Name",
    key: GROUP_NAME
  }];

function defaultIdentificationAdvanceConfig({
  type,
  setting = {}
}) {
  switch (type) {
    case I2CI3C:
      return new I2CI3CAdvancedConfig(type, setting || {});
    case SPIQSPIeSPI:
      return new SPI_qSPI_eSPIAdvancesConfig(type, setting || {});
    case UART:
      return new UARTAdvanceConfig(type, setting || {});
    case I2S:
      return new I2SAdvanceConfig(type, setting || {})
    case CLKTYPE:
      return new CLKAdvanceConfig(type, setting || {})
    case SPMI:
      return new SPMIAdvanceConfig(type, setting || {})
    case CUSTOM:
      return new defaultCustomConfig(type, setting || {});
    default: return new defaultAdvancedConfig(type, setting || {})
  }
}

function getAdvanceInput(key) {
  switch (key) {
    case CLK:
    case Data:
    case TX:
    case RX:
    case WS:
    case SD:
    case MCLK:
      return {
        key: key,
        title: key,
        multiple: true
      }
    case NetNamePrefix:
      return {
        key: NetNamePrefix,
        title: 'Net name keywords',
        multiple: true
      }
    case SPI:
    case QSPI:
    case ESPI:
      return {
        key: key,
        title: `${key} Data`,
        multiple: true
      }
    case ChipSelect:
      return {
        key: key,
        title: 'Chip Select',
        multiple: true
      }
    case isDifferential:
      return {
        key: key,
        title: 'Differential nets'
      }
    case AllowVoltageKeywords:
      return {
        key: key,
        title: 'Include net name with voltage keywords'
      }
    case EXCLUDE:
      return {
        key: key,
        title: "EXCLUDE"
      }
    case GROUP_NAME:
      return {
        key: GROUP_NAME,
        title: "Group Name"
      }
    default: return;
  }
}

function getIdentificationAdvancesInputList(advancedConfig) {
  let keys = Object.keys(advancedConfig)
  if (!keys && keys.length) { return [] }
  keys = keys.filter(item => !['type', 'isRegular', "excludeIsRegular"].includes(item))
  const identificationAdvancesInputList = keys.map(key => { return getAdvanceInput(key) })
  return identificationAdvancesInputList
}

function typeSort(a, b) {
  var order = ["MOSI", "MISO", "IO0", "IO1", "IO2", "IO3"]
  return order.indexOf(a.name) - order.indexOf(b.name);
}

function getSignalName(findData, compNet) {
  // const findData = 'IO', compNet = 'VAL_THC1_SPI2_IO_0_SV_HDR';
  const _rule = `${findData}[0-9]{1,}|${findData}_[0-9]{1,}`
  const regObj = new RegExp('(' + _rule + ')', 'ig');
  const _match = compNet.match(regObj);
  let name = findData;
  if (_match && _match.length) {
    name = _match[0].replace('_', '');
  }
  return name
}

function getAdvanceConfig(type) {
  let dataName = '', dataNameList = [], config = '', ISConnectTwoComp = false, types = [];
  switch (type) {
    case UART:
      config = TX;
      dataNameList = [RX];
      ISConnectTwoComp = true;
      types = [UART]
      break;
    case I2CI3C:
      config = CLK;
      dataNameList = [Data];
      types = [I2C, I3C]
      break;
    case SPIQSPIeSPI:
      config = CLK;
      dataNameList = [QSPI, ESPI, SPI];
      ISConnectTwoComp = true;
      types = dataNameList
      break;
    case I2S:
      config = CLK;
      ISConnectTwoComp = true;
      dataNameList = [WS, SD, MCLK]
      types = [I2S]
      break;
    case CUSTOM:
      config = 'netNamePrefix';
      dataNameList = ['netNamePrefix'];
      ISConnectTwoComp = false;
      types = [CUSTOM]
      break;
    case CLKTYPE:
      config = 'netNamePrefix';
      ISConnectTwoComp = true;
      types = [CLKTYPE]
      break;
    case SPMI:
      config = CLK;
      dataNameList = [Data];
      types = [SPMI]
      break;
    default: break;
  }
  return { dataName, config, ISConnectTwoComp, dataNameList, types }
}

function DefaultInterfaceCreationConfig({ type = I2CI3C, component, setting = {} }) {
  this.component = component || "";
  this.interfaceType = type || I2CI3C;
  this.advancedConfig = new defaultIdentificationAdvanceConfig({ type, setting: setting || {} })
}

export {
  addInterfaceInputList,
  defaultIdentificationAdvanceConfig,
  getIdentificationAdvancesInputList,
  typeSort,
  getSignalName,
  getAdvanceConfig,
  DefaultInterfaceCreationConfig,
  GROUP_NAME
}