const PROJECT_TYPE = 'ANDES';
export const EXPAND_MENU = `${PROJECT_TYPE}/expand_menu`;
export const ADD_SIGNAL = `${PROJECT_TYPE}/add_signal`;
export const UPDATE_ANDES_INTERFACE = `${PROJECT_TYPE}/update_andes_interface`;
export const GET_VERIFICATION_CONTENT = `${PROJECT_TYPE}/get_verification_content`;
export const UPDATE_ANDES_CONTENT = `${PROJECT_TYPE}/update_andes_content`;
export const EDIT_SIGNAL_NAME = `${PROJECT_TYPE}/edit_signal_name`;
export const AUTO_SAVE_CONFIG_TO_SERVER = `${PROJECT_TYPE}/auto_save_config_to_serevr`;
export const SAVE_CONFIG_TO_SERVER = `${PROJECT_TYPE}/save_config_to_serevr`;
export const UPDATE_ANDES_INFO = `${PROJECT_TYPE}/update_andes_info`;
export const EDIT_SIGNAL = `${PROJECT_TYPE}/edit_signal`;
export const UPDATE_INTERFACES = `${PROJECT_TYPE}/update_interfaces`;
export const EDIT_CHANNEL_TYPE = `${PROJECT_TYPE}/edit_channel_type`;
export const DELETE_SIGNAL = `${PROJECT_TYPE}/delete_signal`;
export const CHANGE_SERDES_TYPE = `${PROJECT_TYPE}/change_serdes_type`;
export const CHANGE_DATA_RATE = `${PROJECT_TYPE}/change_data_rate`;
export const SAVE_EXTRACTION = `${PROJECT_TYPE}/save_extraction`;
export const UPDATE_NETS_COMPS = `${PROJECT_TYPE}/update_nets_comps`;
export const SAVE_CURRENT_VERIFICATION = `${PROJECT_TYPE}/save_current_verification`;
export const CURRENT_VERIFICATION_DEBUG_VERIFY = `${PROJECT_TYPE}/current_verification_debug_verify`;