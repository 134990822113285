export const LOCATION_CHANGE = 'fastpi/location';
export const LAYER_CHANGE = 'fastpi/layer_change';
export const OPEN_PANEL = 'fastpi/open_panel';
export const CLOSE_PANEL = 'fastpi/close_panel';
export const COLORBY_CHANGE = 'fastpi/colorby_change';
export const SELECTION_CLEAR_ONE = 'fastpi/selection_clear_one';
export const SELECTED_ALL = 'fastpi/selected_all';
export const CANCEL_SELECTED_ALL = 'fastpi/cancel_selected_all';
export const SHOW_SELECTED = 'fastpi/show_selected';
export const HIDE_SELECTED = 'fastpi/hide_selected';
export const SELECTION_CHECKBOX_CHANGE = 'fastpi/selection_checkbox_change';
export const CLEAN_STATUS = 'fastpi/clean_status';
export const CHANGE_SELECTED_DISPLAY = 'change_selected_display';
export const SELECT_CHANGE = `fastpi/select_change`;
export const UPDATE_PCB_REFRESH_STATUS = `fastpi/update_pcb_refresh_status`;
export const SELECTION_CLEAR = `fastpi/selection_clear`;