import CeLayerManager from './CeLayerManager';
import CeSymbolManager from './CeSymbolManager';
import CeNetManager from './CeNetManager';
import CeLayoutSettings from './CeLayoutSettings';
import CePolygon from '../geometry/CePolygon';
import CeGeomHolder from '../geometry/CeGeomHolder';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeLayoutDB = function () {
  this.mUnits = "mm";
  this.mLayerMgr = new CeLayerManager();
  this.mSymbolMgr = new CeSymbolManager();
  this.mPartMgr = null;
  this.mNetManager = new CeNetManager();
  this.mLayoutSettings = new CeLayoutSettings();
  this.mGeomHolder = new CeGeomHolder();
};

CeLayoutDB.prototype.SetPartDB = function (partDB) {
  this.mPartMgr = partDB;
};

CeLayoutDB.prototype.ClearAll = function () {
  this.mLayerMgr.ClearAll();
  this.mSymbolMgr.ClearAll();
  this.mNetManager.ClearAll();
  this.mLayoutSettings.ClearAll();
}

CeLayoutDB.prototype.GetBBox = function () {
  return this.mProfile.BoundingBox();
}

CeLayoutDB.prototype.GetSymbolManager = function () {
  return this.mSymbolMgr;
}

CeLayoutDB.prototype.GetGeomHolder = function () {
  return this.mGeomHolder;
}

CeLayoutDB.prototype.CleanupGeomContainer = function () {
  this.mGeomHolder.CleanUp();
}

CeLayoutDB.prototype.GetNextIDInGeomContainer = function () {
  return this.mGeomHolder.GetNextGeomObjID();
}

CeLayoutDB.prototype.GetTemplateManager = function () {
  return this.mSymbolMgr;
}

CeLayoutDB.prototype.GetLayerManager = function () {
  return this.mLayerMgr;
}

CeLayoutDB.prototype.GetPartManager = function () {
  return this.mPartMgr;
}

CeLayoutDB.prototype.SetPartDB = function (partDb) {
  return this.mPartMgr = partDb;
}

CeLayoutDB.prototype.GetNetManager = function () {
  return this.mNetManager;
}

CeLayoutDB.prototype.SetLayoutUnits = function (units) {
  this.mUnits = units;
}

CeLayoutDB.prototype.SetProfile = function (profile) {
  this.mProfile = profile;
}

CeLayoutDB.prototype.SetShapeList = function (shapeList) {
  this.mSymbolMgr.SetShapeList(shapeList);
}

CeLayoutDB.prototype.GetLayoutUnits = function () {
  return this.mUnits;
}

CeLayoutDB.prototype.SetLayerStackUp = function (layerStackup) {
  this.mLayerMgr.SetStackUp(layerStackup);
}

CeLayoutDB.prototype.GetLayerType = function (name) {
  return this.mLayerMgr.GetLayerType(name);
}

CeLayoutDB.prototype.InitPlotSettings = function () {
  this.mLayoutSettings.InitPartSettings(this.GetAllPartNames());
  this.mLayoutSettings.InitLayerSettings(this.GetAllLayerNames());
  this.mLayoutSettings.InitNetSettings(this.GetAllNetNames());
}

CeLayoutDB.prototype.GetProfile = function () {
  return this.mProfile;
}

CeLayoutDB.prototype.GetAllPartNames = function () {
  return this.mPartMgr.GetAllPartNames();
}

CeLayoutDB.prototype.GetAllLayerNames = function () {
  return this.mLayerMgr.GetAllLayerNames();
}

CeLayoutDB.prototype.GetAllNetNames = function () {
  return this.mNetManager.GetAllNets();
}

CeLayoutDB.prototype.GetComponentsOnLayer = function (layer) {
  var compLayer = this.mLayerMgr.GetComponentLayer(layer);
  if (compLayer != null)
    return compLayer.GetComponents();
  return null;
}

CeLayoutDB.prototype.GetLayoutSettings = function () {
  return this.mLayoutSettings;
}

CeLayoutDB.prototype.LoadDB = function (ceBlock, fileID, caseName) {
  this.ClearAll();

  this.mUnits = ceBlock.GetItemString("Units", "Unkown");

  var profileItem = ceBlock.GetBlockNode("Outline");
  this.mProfile = new CePolygon();
  this.mProfile.ReadFromIODataNode(profileItem);

  var symbolBlk = ceBlock.GetSubBlock("GeomSymbols");
  this.mSymbolMgr.ReadFromIODataNode(symbolBlk);

  var netsBlk = ceBlock.GetSubBlock("Nets");
  this.mNetManager.ReadFromIODataNode(netsBlk);

  var plotBlk = ceBlock.GetSubBlock("PlotAttrib");
  if (plotBlk) {
    this.mLayoutSettings.ReadFromIODataNode(plotBlk);
  } else {
    this.mLayoutSettings.InitNetSettings(this.GetAllNetNames());
  }

  var lyrmgrBlk = ceBlock.GetSubBlock("LayerStackup");
  this.mLayerMgr.ReadFromIODataNode(lyrmgrBlk, fileID, caseName, this);

  return true;
}

CeLayoutDB.prototype.InitPlotAtributes = function () {
  if (this.mLayoutSettings.IsEmpty()) {

    var nets = this.mNetManager.GetAllNets();
    this.mLayoutSettings.InitNetSettings(nets);

    var layers = this.GetAllLayerNames();
    this.mLayoutSettings.InitLayerSettings(layers);
  }

}

CeLayoutDB.prototype.SaveDB = function (ceBlock, designPath) {

  ceBlock.AddDataItem("Units", this.mUnits);

  var profileItem = this.mProfile.WriteIntoIODataNode();
  profileItem.SetName("Outline");
  ceBlock.AddBlockNode(profileItem);

  var symbolBlk = new CeIODataBlock("GeomSymbols");
  this.mSymbolMgr.WriteIntoIODataNode(symbolBlk);
  ceBlock.AddBlockNode(symbolBlk);

  var netsBlk = new CeIODataBlock("Nets");
  this.mNetManager.WriteIntoIODataNode(netsBlk);
  ceBlock.AddBlockNode(netsBlk);

  var lyrmgrBlk = new CeIODataBlock("LayerStackup");
  this.mLayerMgr.WriteIntoIODataNode(lyrmgrBlk, designPath);
  ceBlock.AddBlockNode(lyrmgrBlk);

  // var attribBlk = new CeIODataBlock("PlotAttrib");
  // this.mLayoutSettings.WriteIntoIODataNode(attribBlk);
  // ceBlock.AddBlockNode(attribBlk);

  return true;
}

CeLayoutDB.prototype.AddShape = function (name, id, geom) {
  this.mSymbolMgr.AddShape(name, id, geom);
  this.mGeomHolder.CleanUp();
}

CeLayoutDB.prototype.AddComponent = function (comp, partName, loc, layer) {
  var cePart = this.mPartMgr.GetPart(partName);
  var componentLayer = this.mLayerMgr.GetComponentLayer(layer);
  var ceComp = componentLayer.AddComponent(comp, cePart);
  ceComp.SetLayer(layer);
  ceComp.SetLocation(loc);
}

CeLayoutDB.prototype.GetLogicLayer = function (logicLayer, metalLayer) {
  return this.mLayerMgr.GetLogicLayer(logicLayer, metalLayer);
}

CeLayoutDB.prototype.AddLogicLayerGeometry = function (geom, logic, layer) {
  var logicLayer = this.mLayerMgr.GetLogicLayer(logic, layer);
  if (logicLayer != null)
    logicLayer.AddGeometry(-1, geom, null);

  this.mGeomHolder.CleanUp();
}

CeLayoutDB.prototype.AddNet = function (netName, netType) {
  this.mNetManager.AddNet(netName, netType);
}

CeLayoutDB.prototype.getComponent = function (compName) {
  for (const layer of this.mLayerMgr.mMetalLayers) {
    if (!layer.mComponentLayer) {
      continue;
    }
    for (let component of layer.mComponentLayer.mComponents) {
      if (component.mName === compName) {
        return component;
      }
    }
  }
  return null;
}

export default CeLayoutDB;