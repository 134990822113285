import { message } from 'antd';
import { clearToken } from './auth';
import { UNKNOWN_EXCEPTION, } from '../../constants/returnCode';

export const INTERNAL_SERVER_ERROR = 'Internal server error!';
export const IDENTITY_EXPIRED = 'Identity expired, please log in again.';
export const INSUFFICIENT_PERMISSIONS = 'Insufficient permissions!';
export const REQUEST_ERROR = 'Request address error!';
export const UNKNOWN = 'Unknown error.';

// Login
export const LOGIN_ILLEGAL = 'Your email or password is illegal!';
// Error index
export const ERROR1 = 'Error(1): ';
export const ERROR2 = 'Error(2): ';

const checkError = (error) => {
  if (error && error.response) {
    const msg = error.response.data ? error.response.data.msg : null;
    const code = error.response.data ? error.response.data.code : null;
    const data = error.response.data ? error.response.data.data : null;
    switch (error.response.status) {
      case 401:
        message.error(msg || IDENTITY_EXPIRED).then(
          () => {
            window.location.href = '/login';
            clearToken();
          })
        return '';
      case 403:
        message.error(msg || INSUFFICIENT_PERMISSIONS).then(
          () => {
            window.location.href = '/login';
            clearToken();
          });
        return '';
      case 404:
        return msg || REQUEST_ERROR;
      case 500:
        return code === UNKNOWN_EXCEPTION ? data : msg || INTERNAL_SERVER_ERROR;
      default:
        break;
    }
    return msg || UNKNOWN;
  }
  return UNKNOWN;
}

export default checkError;