import {
  EXPAND_MENU,
  UPDATE_ANDES_INTERFACE,
  UPDATE_ANDES_CONTENT,
  UPDATE_ANDES_INFO,
  UPDATE_NETS_COMPS
} from './actionTypes';
import {
  USER_LOGOUT
} from '../../../Login/actionTypes';

const defaultState = {
  expandedKeys: ['library', 'projects'],
  updateNetsComponents: []
}

export const AndesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case EXPAND_MENU:
      return {
        ...state,
        expandedKeys: action.expandKeys
      }
    case UPDATE_ANDES_INTERFACE:
      return {
        ...state,
        andesInfo: {
          ...state.andesInfo,
          info: {
            ...state.andesInfo.info,
            ...action.data
          }
        }
      }
    case UPDATE_ANDES_CONTENT:
      return {
        ...state,
        andesInfo: {
          ...state.andesInfo,
          ...action.info
        }
      }
    case UPDATE_ANDES_INFO:
      return {
        ...state,
        andesInfo: {
          ...state.andesInfo,
          ...action.data
        }
      }
    case UPDATE_NETS_COMPS:
      return {
        ...state,
        updateNetsComponents: action.updateNetsComponents
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default: return state
  }
};