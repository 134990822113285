import CeGeometry from './CeGeometry';
import CeVertex from './CeVertex';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

var CeXMark = function (type) {
  var geomType = "XMark";
  if (type != null && type != undefined)
    geomType = type;
  CeGeometry.call(this, geomType);

  this.mCenter = new CeVertex();
  this.mDiameter = 0.0;
}

// subclass extends superclass
CeXMark.prototype = Object.create(CeGeometry.prototype);
CeXMark.prototype.constructor = CeXMark;

CeXMark.prototype.CopyFrom = function (fromObj) {
  this.mCenter.CopyFrom(fromObj.mCenter);
  this.mDiameter = fromObj.mDiameter;
  CeGeometry.prototype.CopyFrom.call(this, fromObj);
}

CeXMark.prototype.Clone = function () {
  var clnObj = new CeXMark();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeXMark.prototype.SetData = function (cx, cy, diameter) {
  this.mCenter.SetData(cx, cy);
  this.mDiameter = diameter;
}

CeXMark.prototype.SetDiameter = function (diameter) {
  this.mDiameter = diameter;
}

CeXMark.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mCenter.mX);
  defData.push(this.mCenter.mY);
  defData.push(this.mDiameter);
  return defData;
}

CeXMark.prototype.GetCenter = function () {
  return this.mCenter;
}

CeXMark.prototype.GetDiameter = function () {
  return this.mDiameter;
}

CeXMark.prototype.UpdateByString = function (dataVals) {
  // llX, llY, urX, urY
  if (dataVals.size() != 3)
    return false;

  var minPntVals = new StringList();
  minPntVals.push(dataVals.get(0));
  minPntVals.push(dataVals.get(1));
  this.mCenter.UpdateByString(minPntVals);

  this.mDiameter = parseFloat(dataVals.get(2));

  return true;
}

CeXMark.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.GetDataString();
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeXMark.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeXMark.prototype.Transform = function (celocation) {
  this.mCenter.Transform(celocation.mRotAngle, celocation.mCW, celocation.mPosition, celocation.mFlipOnX, celocation.mFlipOnY);
}

export default CeXMark;