const PROJECT_TYPE = 'Cascade';
/* --- Project --- */
export const UPDATE_PROJECT_MENU = `${PROJECT_TYPE}/update_project_menu`;
export const ADD_PROJECT = `${PROJECT_TYPE}/add_project`;
export const UPDATA_TREE_LIST = `${PROJECT_TYPE}/UPDATA_TREE_LIST`;
export const CREATE_PROJECT = `${PROJECT_TYPE}/create_project`;
export const CLEAR_CURRENT_PROJECT = `${PROJECT_TYPE}/clear_current_project`;
export const UPDATE_SELECTED_KEYS = `${PROJECT_TYPE}/update_selected_keys`;
export const UPDATE_EXPAND_KEYS = `${PROJECT_TYPE}/update_expand_keys`;
export const OPEN_PROJECT = `${PROJECT_TYPE}/open_project`;
export const CLOSE_PROJECT = `${PROJECT_TYPE}/close_project`;
export const SAVE_OPEN_PROJECT_INFO = `${PROJECT_TYPE}/save_open_project_info`;
export const OPEN_PAGE = `${PROJECT_TYPE}/open_page`;
export const UPDATE_VIEW_LIST = `${PROJECT_TYPE}/update_view_list`;
export const DELETE_PROJECT = `${PROJECT_TYPE}/delete_project`;
export const DELETE_PCB = `${PROJECT_TYPE}/delete_PCB`;
export const PCB_REPLACE = `${PROJECT_TYPE}/pcb_replace`;
export const UPDATE_PAGE_LAYOUT = `${PROJECT_TYPE}/update_page_layout`;
export const ADD_VERIFICATION = `${PROJECT_TYPE}/add_verification`;
export const CANCEL_CREARTE_VERIFICATION = `${PROJECT_TYPE}/cancel_create_verification`;
export const CREATE_VERIFICATION = `${PROJECT_TYPE}/create_verification`;
export const DELETE_VERIFICATION = `${PROJECT_TYPE}/delete_verification`;
export const CHANGE_RENAME_STATUS = `${PROJECT_TYPE}/change_rename_status`;
export const RENAME_VERIFICATION = `${PROJECT_TYPE}/rename_verification`;
export const COPY_VERIFICATION = `${PROJECT_TYPE}/copy_verification`;
export const UPDATE_LIST_STATUS = `${PROJECT_TYPE}/update_list_status`;
export const UPDATE_PMIC_LIST = `${PROJECT_TYPE}/update_pmic_list`;
export const UPDATE_TRASH = `${PROJECT_TYPE}/update_trash`;
export const RENAME_PROJECT = `${PROJECT_TYPE}/rename_project`;
export const UPDATE_DESIGN_STATUS = `${PROJECT_TYPE}/update_design_status`;
export const UPDATE_CHANGE_PCB_LOG = `${PROJECT_TYPE}/update_change_pcb_log`;
export const UPDATE_UPLOAD_TEMPLATE_LOG = `${PROJECT_TYPE}/update_upload_template_log`;
export const UPDATE_PCB_LAYOUT = `${PROJECT_TYPE}/update_pcb_layout`;
export const LIBRARY_DEFAULT = `${PROJECT_TYPE}/library_default`;
export const UPDATA_DEFAULT_DECAP = `${PROJECT_TYPE}/update_default_decap`;
export const UPDATE_LIBRARY_MODEL_SAVE = `${PROJECT_TYPE}/update_library_model_save`;
export const CHANGE_PCB_UPDATE_STATUS = `${PROJECT_TYPE}/change_pcb_update_status`;
export const UPDATE_SIMULATION_LIST = `${PROJECT_TYPE}/update_simulation_list`;
export const NEW_SIMULATION_CHANNEL = `${PROJECT_TYPE}/new_simulation_channel`;
export const UPDATE_STACKUP_LIST = `${PROJECT_TYPE}/update_stackup_list`;
export const SAVE_PREVIEW_STATUS = `${PROJECT_TYPE}/save_preview_status`;
export const FORCE_PREVIEW_SHOW = `${PROJECT_TYPE}/force_preview_show`;;
export const UPDATE_REPORT_INFO = `${PROJECT_TYPE}/update_report_info`;
export const SAVE_REPORT_CONFIG = `${PROJECT_TYPE}/save_report_config`;
export const GET_REPORT = `${PROJECT_TYPE}/get_report`;
export const CLEAR_REPORT_INFO = `${PROJECT_TYPE}/clear_report_info`;
export const COMPONENT_SETTING_VISIBLE = `${PROJECT_TYPE}/component_setting_visible`;
export const ROOT_PANEL_VISIBLE = `${PROJECT_TYPE}/root_panel_visible`;
export const UPDATE_SIM_LOADING = `${PROJECT_TYPE}/update_simulation_loading`;
export const START_LAYOUT_CHECK = `${PROJECT_TYPE}/layout_checkout`;
export const GET_LAYOUT_CHECK_RESULT = `${PROJECT_TYPE}/layout_checkout_result`;
export const RENAME_PRE_LAYOUT = `${PROJECT_TYPE}/rename_pre_layout`;
export const UPDATE_COPY_LOADING_LIST = `${PROJECT_TYPE}/update_copy_loading_list`;
export const UPDATE_SERVICE_CONFIG = `${PROJECT_TYPE}/update_service_config`;
export const SAVE_SERVICE_OPTION = `${PROJECT_TYPE}/save_service_option`;
export const UPDATE_FULL_POWER_TREE_DISPLAY = `${PROJECT_TYPE}/update_full_power_tree_display`;