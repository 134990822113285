import {
  GET_PRE_LAYOUT_CONTENT,
  UPDATE_PRE_LAYOUT_CONTENT,
  UPDATE_PRE_LAYOUT_INFO,
  CREATE_PRE_LAYOUT,
  SELECT_SECTION_TEMPLATE,
  EDIT_INSERTION_LOSS,
  CLEAR_PRE_LAYOUT_CONTENT,
  CHANGE_PRE_LAYOUT_CONFIG,
  UPDATE_SIGNALS_COLUMNS,
  EDIT_GROUP_NAME,
  EDIT_SIGNAL_NAME,
  EDIT_NET_NAME,
  CHANGE_LENGTH_UNIT,
  EDIT_SIGNAL_SPACING,
  SAVE_PRE_LAYOUT_MODELS,
  FIRST_OPEN_PRE_LAYOUT_SETTING,
  SAVA_PRELAYOUT_PCB_MODEL,
  PRELAYOUT_RENAME,
  EDIT_PIN_OR_NET_NAME
} from './actionTypes';

export const createPreLayout = (pcbType) => ({
  type: CREATE_PRE_LAYOUT,
  pcbType
});

export const getPreLayoutContent = ({ id, name }) => ({
  type: GET_PRE_LAYOUT_CONTENT,
  id,
  name
});

export const updatePreLayoutContent = ({ preLayoutInfo, preLayoutId }) => ({
  type: UPDATE_PRE_LAYOUT_CONTENT,
  preLayoutInfo,
  preLayoutId
});

export const clearPreLayoutContent = () => ({
  type: CLEAR_PRE_LAYOUT_CONTENT
});

export const updatePreLayoutInfo = ({ info, notSave = false, designID }) => ({
  type: UPDATE_PRE_LAYOUT_INFO,
  info,
  notSave,
  preLayoutId: designID
});

export const selectSectionTemplate = (data, designID) => ({
  type: SELECT_SECTION_TEMPLATE,
  data,
  designID
});

export const editInsertionLoss = (record, dataIndex, designID) => ({
  type: EDIT_INSERTION_LOSS,
  record,
  dataIndex,
  designID
});

export const changePreLayoutConfig = (info, designID) => ({
  type: CHANGE_PRE_LAYOUT_CONFIG,
  info,
  designID
});

export const updateSignalsColumnsStatus = (status, designID) => ({
  type: UPDATE_SIGNALS_COLUMNS,
  status,
  preLayoutId: designID
});

export const editGroupName = (record, prevRecord, designID) => ({
  type: EDIT_GROUP_NAME,
  record,
  prevRecord,
  designID
});

export const editSignalName = (record, prevRecord, designID) => ({
  type: EDIT_SIGNAL_NAME,
  record,
  prevRecord,
  designID
});

export const editNetName = (record, prevRecord, dataIndex, designID) => ({
  type: EDIT_NET_NAME,
  record,
  prevRecord,
  dataIndex,
  designID
});

export const changeLengthUnit = (unit, designID) => ({
  type: CHANGE_LENGTH_UNIT,
  unit,
  designID
});

export const editSignalSpacing = (record, designID) => ({
  type: EDIT_SIGNAL_SPACING,
  record,
  designID
});

export const savePreLayoutModel = (models, designID) => ({
  type: SAVE_PRE_LAYOUT_MODELS,
  models,
  designID
});

export const firstOpenPreLayoutSettingUpdate = (status, designID) => ({
  type: FIRST_OPEN_PRE_LAYOUT_SETTING,
  status,
  preLayoutId: designID
})

export const savePreLayoutPCBModel = (model, designID) => ({
  type: SAVA_PRELAYOUT_PCB_MODEL,
  model,
  designID
})

export const preLayoutRename = (itemData) => ({
  type: PRELAYOUT_RENAME,
  itemData
})

export const editPinOrNetName = (preData, newName, nameType, designID) => ({
  type: EDIT_PIN_OR_NET_NAME,
  preData,
  newName,
  nameType,
  designID
})