import { max, min, histogram } from 'd3-array'
import { getFileInRockyVerification } from '../../../api/Rocky/rockyCtrl';

/** ImpedanceResultHelper class constructor * */
function ImpedanceResultHelper(signalInfoList) {
  this.signalInfoList = signalInfoList; // Array of ImpedanceResultHelper.SignalInfo
  this.impedanceData = null;           // ImpedanceResultHelper.ImpedanceData
  this.diffImpedanceData = null;
};

/** Signal definition */
ImpedanceResultHelper.SignalInfo = function (signalName) {
  this.signalName = signalName || null;
  this.netList = null;
  this.groupName = null;
};

/** Get the impedance result
 *  @param  stackup - a CeLayerStack object containing the layout design stackup
 *  @return a promise to the impedance result. The promise resolves to
 *          a object of ImpedanceResultHelper.ImpedanceData, or null if data is not available.
 */
ImpedanceResultHelper.prototype.getImpedanceAsPromise = getImpedanceAsPromise;

// ------ Result data class ------

/** Impedance statistics for each net and each layer, as well as the raw impedance data for all traces */
ImpedanceResultHelper.ImpedanceData = function () {
  this.netStats = []; // Array of ImpedanceResultHelper.NetStatItem, impedance statistics of all nets
  this.layerStats = []; // Array of ImpedanceResultHelper.LayerStatItem, impedance statistics of all layers
  this.netLayerTraces = []; // Array of ImpedanceResultHelper.NetLayerTraceItem, raw impedance data of all traces
};

// ------ Statistical result classes ------

/** The impedance statistics for a net */
ImpedanceResultHelper.NetStatItem = function () {
  this.name = null; // String, name of the signal
  this.group = null; // String, net group in the DDR interface
  this.numTrc = null; // Number, number of traces
  this.numNoRef = null; // Number, number of traces without reference plane
  this.Z0 = null; // Number, typical impedance value
  this.minZ0 = null; // Number, minimum impedance value
  this.maxZ0 = null; // Number, maximum impedance value
  this.w = null; // Number, typical trace width
  this.length = null; // Number, total trace length
  this.layerStats = [];   // Array of ImpedanceResultHelper.LayerStatItem, impedance statistics of this net on each layer
  this.signal = null;
};

/** The impedance statistics on a layer */
ImpedanceResultHelper.LayerStatItem = function () {
  this.layer = null; // String, name of the layer
  this.indMetal = null; // Number, index of the this layer in all the metal layers
  this.Er = null; // Number, dielectric constant of the layer
  this.thickness = null; // Number, thickness of the layer
  this.numTrc = null; // Number, number of traces
  this.numNoRef = null; // Number, number of traces without reference plane
  this.Z0 = null; // Number, typical impedance value
  this.minZ0 = null; // Number, minimum impedance value
  this.maxZ0 = null; // Number, maximum impedance value
  this.w = null; // Number, typical trace width
  this.length = null; // Number, total trace length
};

/** The differential impedance statistics for a pair of differential nets */
ImpedanceResultHelper.DiffNetStatItem = function () {
  this.signal1 = null; // String, name of the first signal
  this.signal2 = null; // String, name of the second signal
  this.layoutNetName1 = null; // String, layout net name of the first net
  this.layoutNetName2 = null; // String, layout net name of the second net
  this.group = null; // String, net group in the DDR interface
  this.numTrcPair = null; // Number, number of differential pairs
  this.numNoRef = null; // Number, number of differential traces without reference plane
  this.Zdiff = null; // Number, typical differential impedance value
  this.minZdiff = null; // Number, minimum differential impedance value
  this.maxZdiff = null; // Number, maximum differential impedance value
  this.w = null; // Number, typical trace width
  this.layerStats = [];   // Array of ImpedanceResultHelper.LayerDiffNetStatItem, impedance statistics of this net on each layer
};

/** The differential impedance statistics on a layer */
ImpedanceResultHelper.LayerDiffNetStatItem = function () {
  this.layer = null; // String, name of the layer
  this.indMetal = null; // Number, index of the this layer in all the metal layers
  this.Er = null; // Number, dielectric constant of the layer
  this.thickness = null; // Number, thickness of the layer
  this.numTrcPair = null; // Number, number of differential trace pairs
  this.numNoRef = null; // Number, number of differential traces without reference plane
  this.Zdiff = null; // Number, typical differential impedance value
  this.minZdiff = null; // Number, minimum differential impedance value
  this.maxZdiff = null; // Number, maximum differential impedance value
  this.w = null; // Number, typical trace width
};

// ------ Raw result classes ------

/** Raw trace impedance result data structure */
ImpedanceResultHelper.NetLayerTraceItem = function () {
  this.netName = null;  // String, net name
  this.layerTraces = [];    // Array of ImpedanceResultHelper.LayerTraceItem, one for each net
};

/** Raw trace impedance result data structure -- trace impedance list on a layer */
ImpedanceResultHelper.LayerTraceItem = function () {
  this.layerName = null;    // String, layer name
  this.traces = [];      // Array of ImpedanceResultHelper.TraceItem, one for each layer
};

/** Raw trace impedance result data structure -- trace impedance */
ImpedanceResultHelper.TraceItem = function () {
  this.ind = null; // Number, net object index in its layer
  this.Z0 = null; // Number, trace characteristics impedance
  this.lowerPlane = null; // String, lower reference plane name
  this.upperPlane = null; // String, upper reference plane name
  this.h1 = null; // Number, dielectric height above the trace
  this.h2 = null; // Number, dielectric height below the trace
  this.w = null; // Number, trace width
  this.x1 = null; // Number, x coordinate, starting point
  this.y1 = null; // Number, y coordinate, starting point
  this.x2 = null; // Number, x coordinate, ending point
  this.y2 = null; // Number, y coordinate, ending point
  this.length = null; // Number, trace length derived from the coordinates
}

/** Raw differential impedance result data structure */
ImpedanceResultHelper.NetLayerTrcPairItem = function () {
  this.netName1 = null; // String, first net name of the differential pair
  this.netName2 = null; // String, second net name of the differential pair
  this.layerTrcPairs = [];   // Array of ImpedanceResultHelper.LayerTrcPairItem, one for each net
};

/** Raw differential impedance result data structure -- differential impedance list on a layer */
ImpedanceResultHelper.LayerTrcPairItem = function () {
  this.layerName = null;    // String, layer name
  this.trcPairs = [];      // Array of ImpedanceResultHelper.TrcPairItem, one for each layer
};

/** Raw differential impedance result data structure -- differential impedance */
ImpedanceResultHelper.TrcPairItem = function () {
  this.ind1 = null; // Number, net object index of the first net in its layer
  this.ind2 = null; // Number, net object index of the second net in its layer
  this.Zdiff = null; // Number, differential impedance
  this.lowerPlane = null; // String, lower reference plane name
  this.upperPlane = null; // String, upper reference plane name
  this.h1 = null; // Number, dielectric height below the trace
  this.h2 = null; // Number, dielectric height above the trace
  this.w1 = null; // Number, trace width of the first net
  this.w2 = null; // Number, trace width of the second net
  this.spacing = null; // Number, spacing between the diff pair
};

// ------------------------------ Implementation Details ------------------------------

/** ------ implementation of ImpedanceResultHelper.prototpye.getImpedanceAsPromise ------
 *  Get the impedance result
 *  @param  stackup - a CeLayerStack object containing the layout design stackup
 *  @return a promise to the DDR impedance result. The promise resolves to
 *          a object of ImpedanceResultHelper.ImpedanceData, or null if data is not available.
 */
function getImpedanceAsPromise({ verificationSubId, designSubId, channelId, interfaceName }) {
  const self = this;
  return new Promise((resolve, reject) => {
    processImpedance(self, {
      verificationSubId,
      designSubId,
      channelId,
      interfaceName
    }).then(res => {
      resolve(res);
    }, error => {
      reject(error)
    })
  });
}

function processImpedance(self, { verificationSubId, designSubId,
  channelId,
  interfaceName
}) {
  return new Promise((resolve, reject) => {
    const path = `${verificationSubId}/result/${interfaceName}/data/PCB${designSubId}_Impedance.dat`;
    getFileInRockyVerification({ channelId, filePath: path }).then(response => {
      var rawImpedanceData = parseImpedanceFile(response.data);
      self.impedanceData = processImpedanceData(rawImpedanceData.netLayerTraces, self.signalInfoList);
      const DQSMatch = interfaceName.includes(`Read`) ? /^DQS|CLK|RDQS/g : /^DQS|CLK|WCK/g
      const DQS = self.signalInfoList.filter(item => item.signalName.match(DQSMatch));
      self.diffImpedanceData = processDiffImpedanceData(rawImpedanceData.diffNetLayerTraces, DQS, self.impedanceData);
      resolve({ single: self.impedanceData, diff: self.diffImpedanceData });
    }, error => {
      reject(error)
    });
  });
}

/** Read the impedance file and convert it into a trace impedance data array
 *  @param  fileContent - content of the impedance file as a String
 *  @return an object containg: an Array of ImpedanceResultHelper.NetLayerTraceItem
 */
function parseImpedanceFile(fileContent) {

  // the file parsing code is temporary since the output format might
  // need to be adjusted to improve the efficiency
  var lineBuffer = fileContent.match(/[^\r\n]+/g);

  var netLayerTraces = [];

  var netName = null;              // String, name of a single net (block)
  var layerName = null;            // String, name of the trace or diff pair layer (block)
  var netLayerTraceItem = null;    // ImpedanceResultHelper.NetLayerTraceItem, all traces of a net in all layers
  var layerTraceItem = null;       // ImpedanceResultHelper.LayerTraceItem, all traces of a net in a single layer

  let diffNetLayerTraces = [], inDiffBlock = false;

  while (lineBuffer.length) {

    // read a line
    var line = lineBuffer.shift().trim();

    if (line.length === 0)
      continue;
    var words = line.match(/[^\s\t]+/g);

    if (words[0] === 'DIFF' || words[0] === 'DIFF_PAIR') {
      inDiffBlock = true;
      netName = 'DIFF_PAIR';
      netLayerTraceItem = new ImpedanceResultHelper.NetLayerTrcPairItem();
      netLayerTraceItem.netName = netName;

    } else if (words[0] === 'NETS') {
      if (words.length >= 3);
      netLayerTraceItem.netName1 = words[1];
      netLayerTraceItem.netName2 = words[2];
    } else if (words.length === 2) {
      // a starting line, either a new net or a new layer
      if (!netName) {

        // the first word is the net name
        netName = words[0];
        netLayerTraceItem = new ImpedanceResultHelper.NetLayerTraceItem();
        netLayerTraceItem.netName = netName;
      } else {

        // the first word is the layer name
        layerName = words[0];
        if (inDiffBlock) {
          layerTraceItem = new ImpedanceResultHelper.LayerTrcPairItem();
        } else {
          layerTraceItem = new ImpedanceResultHelper.LayerTraceItem();
        };
        layerTraceItem.layerName = layerName;
      } // if (!netName) ... else ...

    } // if (words.length == 2)
    else if (words.length === 1) {

      // this can only be '}'
      if (layerName) {

        // end of a layer block, add the traces on the layer to the net
        if (inDiffBlock) {
          netLayerTraceItem.layerTrcPairs.push(layerTraceItem);
        } else {
          netLayerTraceItem.layerTraces.push(layerTraceItem);
        }
        layerName = null;

      } else if (netName) {
        if (inDiffBlock) {
          diffNetLayerTraces.push(netLayerTraceItem);
          inDiffBlock = false;
        } else {
          // end of a net block, add the traces of this net to net trace list
          netLayerTraces.push(netLayerTraceItem);
        };
        netName = null;
      }

    } // if (words.length == 2) ... else if (words.length == 1) ...
    else {

      if (inDiffBlock) {

        // this is a trace impedance line, example:
        // 9	17	138.795096	Lower Plane: GND1	 h1 = 8.000000	Upper Plane: NULL	Wdith = 3.500000 , 3.500000	Spacing = 6.600000
        // h1 and h2 could be not in the string
        let traceItem = new ImpedanceResultHelper.TrcPairItem();
        traceItem.ind1 = Number(words[0]);                        // net object index in its layer
        traceItem.ind2 = Number(words[1]);                        // net object index in its layer
        traceItem.Zdiff = Number(words[2]);                        // trace characteristics impedance
        traceItem.lowerPlane = words[5] == 'NULL' ? null : words[5];    // lower reference plane name
        //Deal with the situation that there is not 'h1'
        const h1Index = words.indexOf('h1');
        if (h1Index > -1) {
          traceItem.h1 = Number(words[h1Index + 2]);                      // dielectric height above the trace
        };

        const UpperIndex = words.indexOf('Upper');
        if (UpperIndex > -1) {
          traceItem.upperPlane = words[UpperIndex + 2] === 'NULL' ? null : words[UpperIndex + 2];    // upper reference plane name
        };
        //Deal with the situation that there is not 'h2'
        const h2Index = words.indexOf('h2');
        if (h2Index > -1) {
          traceItem.h2 = Number(words[h2Index + 2]);                   // dielectric height below the trace
        }

        let wIndex = words.indexOf('Wdith');
        if (wIndex < 0) {
          wIndex = words.indexOf('Width');
        };
        traceItem.w1 = Number(words[wIndex + 2]);                       // trace width
        traceItem.w2 = Number(words[wIndex + 4]);                       // trace width

        const SpacingIndex = words.indexOf('Spacing');
        if (SpacingIndex > -1) {
          traceItem.spacing = Number(words[SpacingIndex + 2]);
        }

        layerTraceItem.trcPairs.push(traceItem);

      } else {
        // this is a trace impedance line, example:
        // 5       39.477886       Lower Plane: NULL    h1 = 4.14   Upper Plane: Layer7     h2 = 5.9        Width = 15.748  ( 1082.677165, 777.559055 )     ( 1082.677165, 812.992126 )
        // h1 and h2 could be not in the string
        var traceItem = new ImpedanceResultHelper.TraceItem();
        traceItem.ind = Number(words[0]);                        // net object index in its layer
        traceItem.Z0 = Number(words[1]);                        // trace characteristics impedance
        traceItem.lowerPlane = words[4] === 'NULL' ? null : words[4];    // lower reference plane name
        //Deal with the situation that there is not 'h1'
        var index = 5;
        if (words[index] === "h1") {
          traceItem.h1 = Number(words[index + 2]);                      // dielectric height above the trace
          index = index + 3;
        }
        traceItem.upperPlane = words[index + 2] === 'NULL' ? null : words[index + 2];    // upper reference plane name
        index = index + 3;
        //Deal with the situation that there is not 'h1'
        if (words[index] === "h2") {
          traceItem.h2 = Number(words[index + 2]);                      // dielectric height below the trace
          index = index + 3;
        }

        traceItem.w = Number(words[index + 2]);                       // trace width
        traceItem.x1 = Number(words[index + 4].split(',')[0]);         // x coordinate, starting point
        traceItem.y1 = Number(words[index + 5]);                       // y coordinate, starting point
        traceItem.x2 = Number(words[index + 8].split(',')[0]);         // x coordinate, ending point
        traceItem.y2 = Number(words[index + 9])                        // y coordinate, ending point

        traceItem.length = Math.sqrt((traceItem.x1 - traceItem.x2) * (traceItem.x1 - traceItem.x2) +
          (traceItem.y1 - traceItem.y2) * (traceItem.y1 - traceItem.y2));

        layerTraceItem.traces.push(traceItem);
      }

    } // if (words.length == 2) ... else if (word.length == 1) ... else ...

  } // while (lineBuffer.length)

  // netLayerTraces: [NetLayerTraceItem]
  // NetLayerTraceItem: { layerTraces: [LayerTraceItem], netName }
  // LayerTraceItem: { layerName, traces: [TraceItem] }
  return {
    netLayerTraces: netLayerTraces,
    diffNetLayerTraces: diffNetLayerTraces
  };
} // parseImpedanceFile

/** Help class for generating trace statistics */
function TraceStats() {
  this.traceList = []; // traces covered by at least one reference plane
  this.badTraceList = []; // traces without reference plane
}
TraceStats.prototype.addTrace = TraceStats_addTrace;
TraceStats.prototype.getStatistics = TraceStats_getStatistics;

/** TraceStats.prototype.addTrace
 *  @param traceItem - an object of ImpedanceResultHelper.TraceItem
 */
function TraceStats_addTrace(traceItem) {
  if (traceItem.lowerPlane || traceItem.upperPlane)
    this.traceList.push(traceItem);
  else
    this.badTraceList.push(traceItem);
}

/** TraceStats.prototype.getStatistics - Generate the statistics of a group of traces */
function TraceStats_getStatistics() {

  let statistics = {
    numTrc: this.traceList.length + this.badTraceList.length,  // total number of traces
    Z0: null,                                              // typical trace characteristics impedance of this layer
    minZ0: null,                                              // minimum trace characteristics impedance
    maxZ0: null,                                              // maximum trace characteristics impedance
    w: null,                                              // typical trace width
    length: null,                                              // total trace length
    numNoRef: this.badTraceList.length
  };

  if (this.traceList.length > 0) {

    statistics.minZ0 = min(this.traceList, function (trace) { return trace.Z0; });
    statistics.maxZ0 = max(this.traceList, function (trace) { return trace.Z0; });

    // find the typical impedance from histogram
    var zHist = histogram().value(function (trace) { return trace.Z0; })(this.traceList);
    var maxZ0Count = max(zHist, function (hist) { return hist.length; });
    for (let iHist of zHist) {
      if (iHist.length == maxZ0Count) {
        statistics.Z0 = iHist[0].Z0;
      }
    }

    // find the typical width from histogram
    var wHist = histogram().value(function (trace) { return trace.w; })(this.traceList);
    var maxWidCount = max(wHist, function (hist) { return hist.length; });
    for (let iHist of wHist) {
      if (iHist.length == maxWidCount) {
        statistics.w = iHist[0].w;
      }
    }
    // find the total trace length
    statistics.length = 0;
    for (let iTrace of this.traceList) {
      statistics.length += iTrace.length;
    }

  } // if (this.traceList.length > 0)

  return statistics;
} //TraceStats_getStatistics


/** Help class for generating differential trace statistics */
function TrcPairStats() {
  this.trcPairList = []; // diff pairs covered by at least one reference plane
  this.badTrcPairList = []; // diff pairs without reference plane
}
TrcPairStats.prototype.addTrcPair = TrcPairStats_addTrcPair;
TrcPairStats.prototype.getStatistics = TrcPairStats_getStatistics;

/** TrcairStats.prototype.addTrcPair
 *  @param trcPairItem - an object of ImpedanceResultHelper.TrcPairItem
 */
function TrcPairStats_addTrcPair(trcPairItem) {
  if (trcPairItem.lowerPlane || trcPairItem.upperPlane)
    this.trcPairList.push(trcPairItem);
  else
    this.badTrcPairList.push(trcPairItem);
}

/** TrcPairStats.prototype.getStatistics - Generate the statistics of a group of differential pairs */
function TrcPairStats_getStatistics() {

  var statistics = {
    numTrcPair: this.trcPairList.length + this.badTrcPairList.length,  // total number of differential pairs
    Zdiff: null,                                              // typical differential impedance of this group
    minZdiff: null,                                              // minimum differential impedance
    maxZdiff: null,                                              // maximum differential impedance
    w: null,                                              // typical trace width
    numNoRef: this.badTrcPairList.length
  };

  if (this.trcPairList.length > 0) {

    statistics.minZdiff = min(this.trcPairList, function (trcPair) { return trcPair.Zdiff; });
    statistics.maxZdiff = max(this.trcPairList, function (trcPair) { return trcPair.Zdiff; });

    // find the typical impedance from histogram
    const zHist = histogram().value(function (trcPair) { return trcPair.Zdiff; })(this.trcPairList);
    const maxZdiffCount = max(zHist, function (hist) { return hist.length; });
    for (let iHist of zHist) {
      if (iHist.length == maxZdiffCount) {
        statistics.Zdiff = iHist[0].Zdiff;
      }
    }

    // find the typical width from histogram
    let allWidths = [];
    for (let iPair of this.trcPairList) {
      allWidths.push(iPair.w1);
      allWidths.push(iPair.w2);
    }
    const wHist = histogram()(allWidths);
    const maxWidCount = max(wHist, function (hist) { return hist.length; });
    for (let iHist of wHist) {
      if (iHist.length == maxWidCount) {
        statistics.w = iHist[0];
      }
    }

  } // if (this.trcPairList.length > 0)

  return statistics;
} // TrcPairStats_getStatistics

/** Calculate the per-net and per-layer statistics from the raw impedance data     
 *  @param  netLayerTraces   - Array of ImpedanceResultHelper.NetLayerTraceItem
 *  @param  stackup          - a CeLayerStack object containing the layout design stackup
 *  @param  signalInfoList   - Array of ImpedanceResultHelper.SignalInfo containing singal definitions
 *  @return a ImpedanceResultHelper.ImpedanceData object with raw and statistical data
 */
function processImpedanceData(netLayerTraces, signalInfoList) {
  // initialize the results
  let netNameList = [];
  let perNetStats = [];
  let layerNameList = [];
  let perLayerStats = [];             // statistics for all nets on each layer

  for (const signal of signalInfoList) {
    signal.netList.forEach(item => {
      const _netIndex = netNameList.map(item => item.net).indexOf(item.net);
      if (_netIndex < 0) {
        netNameList.push({ net: item.net, signal: signal.signalName });
        perNetStats.push({
          netStat: new TraceStats(),  // statistics for this net on all layers
          layersStats: [],             // statistics for this net on each layer
        })
      } else {
        netNameList[_netIndex].signal = netNameList[_netIndex].signal + `, ${signal.signalName}`;
      }

    })
  } // for (var iNet in signalInfoList)

  function getNetIndex(name) {
    const index = netNameList.map(item => item.net).indexOf(name);
    return index;
  }

  for (let net of netLayerTraces) {
    net.layerTraces.forEach(layer => {
      if (layerNameList.indexOf(layer.layerName) < 0) {
        layerNameList.push(layer.layerName);
      }
    })
  }

  for (let i = 0; i < layerNameList.length; i++) {

    perLayerStats.push(new TraceStats());

    for (let iNet of perNetStats) {
      iNet.layersStats.push(new TraceStats());
    }

  }

  // loop through all the nets
  for (let netLayerTraceItem of netLayerTraces) {

    var iNet = getNetIndex(netLayerTraceItem.netName);

    if (iNet < 0) continue;

    // loop through all the layers under this net
    for (let layerTraceItem of netLayerTraceItem.layerTraces) {

      var iLayer = layerNameList.indexOf(layerTraceItem.layerName);

      if (iLayer < 0)
        continue;

      // add this trace to appropriate statistics list
      for (let trace of layerTraceItem.traces) {
        perNetStats[iNet].netStat.addTrace(trace);
        perNetStats[iNet].layersStats[iLayer].addTrace(trace);
        perLayerStats[iLayer].addTrace(trace);
      }
    }
  }

  // generate the statistics table 
  let impedanceData = new ImpedanceResultHelper.ImpedanceData();
  impedanceData.netLayerTraces = netLayerTraces;

  // per net trace statistics
  perNetStats.forEach((iNet, index) => {

    // statistics of this net on all layers
    let stats = iNet.netStat.getStatistics();

    let netStatItem = new ImpedanceResultHelper.NetStatItem();
    netStatItem.name = netNameList[index].net;
    netStatItem.signal = netNameList[index].signal;
    // netStatItem.group = signalInfoList[iNet].group;
    netStatItem.numTrc = stats.numTrc;
    netStatItem.numNoRef = stats.numNoRef;
    netStatItem.Z0 = stats.Z0;
    netStatItem.minZ0 = stats.minZ0;
    netStatItem.maxZ0 = stats.maxZ0;
    netStatItem.w = stats.w;
    netStatItem.length = stats.length;

    // per-layer statistics of the net
    for (let iL = 0; iL < layerNameList.length; iL++) {

      stats = iNet.layersStats[iL].getStatistics();
      if (stats.numTrc > 0) {
        var layerStatItem = new ImpedanceResultHelper.LayerStatItem();
        layerStatItem.layer = layerNameList[iL];
        layerStatItem.numTrc = stats.numTrc;
        layerStatItem.numNoRef = stats.numNoRef;
        layerStatItem.Z0 = stats.Z0;
        layerStatItem.minZ0 = stats.minZ0;
        layerStatItem.maxZ0 = stats.maxZ0;
        layerStatItem.w = stats.w;
        layerStatItem.length = stats.length;

        netStatItem.layerStats.push(layerStatItem);
      }

    }
    impedanceData.netStats.push(netStatItem);
  })

  // per-layer trace 
  for (let iL = 0; iL < layerNameList.length; iL++) {

    var layerStatItem = new ImpedanceResultHelper.LayerStatItem();
    layerStatItem.layer = layerNameList[iL]
    layerStatItem.indMetal = iL > -1 ? iL : null;
    // layerStatItem.Er = iL > -1 ? null : stackupLayers[iStackup].getPermittivity();
    // layerStatItem.thickness = stackupLayers[iStackup].getLayerThickness();

    // for metal layers, check the trace statistics
    if (iL >= 0) {

      var stats = perLayerStats[iL].getStatistics();
      if (stats.numTrc > 0) {
        layerStatItem.numTrc = stats.numTrc;
        layerStatItem.Z0 = stats.Z0;
        layerStatItem.minZ0 = stats.minZ0;
        layerStatItem.maxZ0 = stats.maxZ0;
        layerStatItem.w = stats.w;
        layerStatItem.length = stats.length;
        layerStatItem.numNoRef = stats.numNoRef;
      }

    } // if (iL >= 0)

    impedanceData.layerStats.push(layerStatItem);

  }

  return impedanceData;
} // processImpedanceData


function processDiffImpedanceData(netLayerTraces, signalInfoList, singleImpedanceData) {
  let layerNameList = [], perLayerStats = [], perNetStats = [];
  perNetStats.push({
    diffNetStat: new TrcPairStats(),  // statistics for this net on all layers
    layersStats: [],             // statistics for this net on each layer
  })
  if (netLayerTraces.length > 0 && netLayerTraces[0].layerTrcPairs.length > 0) {

    for (let net of netLayerTraces) {
      net.layerTrcPairs.forEach(layer => {
        if (layerNameList.indexOf(layer.layerName) < 0) {
          layerNameList.push(layer.layerName);
        }
      })
    }
    for (let iLayer of layerNameList) {
      perLayerStats.push(new TrcPairStats());

      perNetStats[0].layersStats.push(new TrcPairStats());
    };

    // loop through all the layers under the differential nets
    for (let layerTrcPairItem of netLayerTraces[0].layerTrcPairs) {
      var iLayer = layerNameList.indexOf(layerTrcPairItem.layerName);

      if (iLayer < 0)
        continue;

      // add this trace to appropriate statistics list
      for (let trace of layerTrcPairItem.trcPairs) {
        perNetStats[0].diffNetStat.addTrcPair(trace);
        perNetStats[0].layersStats[iLayer].addTrcPair(trace);
        perLayerStats[iLayer].addTrcPair(trace);
      };
    };


    // generate the statistics table 
    let diffImpedanceData = new ImpedanceResultHelper.ImpedanceData();

    // statistics of this net on all layers
    let stats = perNetStats[0].diffNetStat.getStatistics();

    let netStatItem = new ImpedanceResultHelper.DiffNetStatItem();
    netStatItem.signal1 = signalInfoList[0].signalName;
    netStatItem.signal2 = signalInfoList[1].signalName;
    netStatItem.layoutNetName1 = signalInfoList[0].netList.map(net => net.net).join(", ");
    netStatItem.layoutNetName2 = signalInfoList[1].netList.map(net => net.net).join(", ");
    netStatItem.numTrcPair = stats.numTrcPair;
    netStatItem.numNoRef = stats.numNoRef;
    netStatItem.Zdiff = stats.Zdiff;
    netStatItem.minZdiff = stats.minZdiff;
    netStatItem.maxZdiff = stats.maxZdiff;
    netStatItem.w = stats.w;

    // per-layer statistics of the net
    layerNameList.forEach((name, index) => {
      stats = perNetStats[0].layersStats[index].getStatistics();
      if (stats.numTrcPair > 0) {
        let layerStatItem = new ImpedanceResultHelper.LayerDiffNetStatItem();
        layerStatItem.layer = name;
        layerStatItem.numTrcPair = stats.numTrcPair;
        layerStatItem.numNoRef = stats.numNoRef;
        layerStatItem.Zdiff = stats.Zdiff;
        layerStatItem.minZdiff = stats.minZdiff;
        layerStatItem.maxZdiff = stats.maxZdiff;
        layerStatItem.w = stats.w;

        netStatItem.layerStats.push(layerStatItem);
      }
    });
    diffImpedanceData.netStats.push(netStatItem);
    // per-layer trace 
    layerNameList.forEach((name, index) => {

      let layerStatItem = new ImpedanceResultHelper.LayerDiffNetStatItem();
      layerStatItem.layer = name;
      // for metal layers, check the trace statistics
      let stats = perLayerStats[index].getStatistics();
      if (stats.numTrcPair > 0) {
        layerStatItem.numTrcPair = stats.numTrcPair;
        layerStatItem.numNoRef = stats.numNoRef;
        layerStatItem.Zdiff = stats.Zdiff;
        layerStatItem.minZdiff = stats.minZdiff;
        layerStatItem.maxZdiff = stats.maxZdiff;
        layerStatItem.w = stats.w;
      };

      diffImpedanceData.layerStats.push(layerStatItem);

    });
    delete diffImpedanceData.netLayerTraces;
    return diffImpedanceData;
  };
};

export default ImpedanceResultHelper;