import {
  GET_VERIFICATION_CONTENT,
  UPDATE_ROCKY_CONTENT,
  EDIT_SIGNAL_NAME,
  UPDATE_ROCKY_INTERFACES,
  UPDATE_ROCKY_CONTENT_INFO,
  EDIT_SIGNAL_NETS,
  OPEN_PAGE,
  DELETE_SIGNAL,
  UPDATE_INTERFACES,
  UPDATE_CURRENT_CONFIG,
  ADD_POWER_GROUNDS,
  POWER_NET_SELECTION,
  SAVE_EXTRACTION,
  SAVE_PIN_MODEL,
  SAVE_POWER_OFF,
  SAVE_MODEL_PINS,
  SAVE_STIMULUS,
  SAVE_CURRENT_VERIFICATION,
  SAVE_SIMULATION_OPTION,
  SAVE_COMP_MODEL,
  APPLY_SETUP_TO_SELECT,
  UPDATE_ERROR_CHECK_LIST,
  UPDATE_CURRENT_LIBRARY_FILE_LIST,
  GET_LIBRARY_FILE_PARSE,
  COMPONENT_LOADING,
  SAVE_COMP_PACKAGE_MODEL,
  GET_CHANNEL_PDN_CONTENT,
  CLEAN_SETUP_INFO,
  UPDATE_TOUCHSTONE_STATUS_LIST,
  UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS,
  UPDATE_SERVICE_CONFIG,
  SAVE_SERVICE_OPTION,
  UPDATE_PREV_STIMULUS_MODE,
  UPDATE_ADR_CMD_STIMULUS,
  UPDATE_CHANNEL_BY_VERSION,
  UPDATE_PORT_SETUPS_TO_SERVER,
  SWITCH_RANK_STATUS,
  CLEAR_STANDBY_MODEL,
  APPLY_ROCKY_PORT_SETUP,
  STIMULUS_RANDOM,
  SELECT_PACKAGE_LAYOUT_MODEL,
  UPDATE_PACKAGE_LOADING,
  UPDATE_PACKAGE_MSG,
  GET_PACKAGE_CONTENT,
  UPDATE_ROCKY_PACKAGE_LAYOUT_INFO,
  CLEAN_PACKAGE_SETUP_INFO,
  SAVE_CURRENT_PACKAGE_VERIFICATION,
  UPDATE_DIMM_CARD_MODEL,
  CHANGE_SOCKET_MODEL,
  UPDATE_MEMORY_SELECTIONS,
  UPDATE_PACKAGE_SIGNAL_INFO,
  UPDATE_PACKAGE_SETUP_LOADING,
  DELETE_PACKAGE_SIGNAL,
  CHANGE_PACKAGE_SIGNAL_NAME,
  ADD_PACKAGE_VERIFICATION,
  ADD_PACKAGE_CHANNEL,
  COMBINE_PACKAGE_INTERFACE,
  UPDATE_PACKAGE_CONTENT_INFO,
  UPDATE_REF_NETS,
  UPDATE_APPLY_SETUP_STATUS,
  UPDATE_SAVE_STATUS,
  SAVE_VIRTUAL_COMPONENT,
  EDIT_PACKAGE_COMPONENT_TYPE,
  GET_CLK_VERIFICATION_CONTENT,
  UPDATE_PACKAGE_PDN_INFO,
  SAVE_BALL_SIZE,
  UPDATE_PACKAGE_NETS,
  UPDATE_PACKAGE_COMPONENTS,
  UPDATE_PORTS,
  SWITCH_PACKAGE_PORTS,
  SPLIT_COMPONENT_PART,
  MERGE_COMPONENTS,
  UPDATE_COMP_USAGE,
  REMOVE_COMPONENTS,
  SAVE_DECAP_MODEL,
  SAVE_PKG_PDN_EXTRACTION,
  SAVE_PKG_PDN_COMPONENT_SETTING,
  ADD_POWER_DOMAIN,
  DELETE_POWER_DOMAIN,
  UPDATE_PACKAGE_PORT_AND_BALL,
  UPDATE_USER_DEFINED_NETLSIT_VALUE,
  UPDATE_PCB_CHANNEL_CONTENT,
  UPDATE_VRM,
  UPDATE_VOLTAGE,
  SAVE_VRMS,
  UPDATE_INCLUDE_EXTEND_NETS,
  SAVE_PACKAGE_CONTENT_AND_CLEAR,
  SAVE_PCB_CHANNEL_CONTENT,
  UPDATE_PDN_ERROR_CHECK,
  UPDATE_TARGET_IC,
  UPDATE_SSN_PACKAGE_INFO,
  UPDATE_PKG_DIE_PORTS_BY_CPM,
  RE_ASSIGN_DECAP_MODEL,
  UPDATE_MODEL_ASSIGN_LOADING,
  START_MODELING_EXTRACTION,
  GET_PCB_CHANNEL_CONTENT,
  GET_PCB_AND_PACKAGE_CHANNEL_INFO,
  UPDATE_PCB_POWER_COMPONENTS,
  SAVE_CHANNEL_DECAP_MODEL,
  UPDATE_PKG_REFERENCE_NETS,
  RE_ASSIGN_CHANNEL_DECAP_MODEL,
  UPDATE_CHANNEL_REFERENCE_NETS,
  APPLY_POWER_COMPONENTS,
  UPDATE_POWER_COMPONENTS_APPLY_LOADING,
  UPDATE_DECAP_MODEL_SETUP_LOADING,
  IS_UPDATE_PACKAGE_PIN_STATUS,
  UPDATE_CHANNEL_ERROR_CHECK_LIST,
  UPDATE_POWER_COMPONENTS_AFTER_REF_NETS,
  UPDATE_CHANNEL_CONTENT,
  UPDATE_CHANNEL_COMP_USAGE,
  REMOVE_CHANNEL_COMPONENTS,
  SPLIT_CHANNEL_COMPONENT_PART,
  MERGE_CHANNEL_COMPONENTS,
  UPDATE_CONTENT_LOADING,
  UPDATE_LOADING
} from './actionTypes';

export const getVerificationContent = (verificationId, status) => ({
  type: GET_VERIFICATION_CONTENT,
  verificationId,
  status
});

export const updateRockyContent = (rockyInfo) => ({
  type: UPDATE_ROCKY_CONTENT,
  rockyInfo
});

export const updateRockyContentInfo = (data) => ({
  type: UPDATE_ROCKY_CONTENT_INFO,
  data
});

export const updateRockyInterfaces = (data) => ({
  type: UPDATE_ROCKY_INTERFACES,
  data
});

export const updateInterfaces = (data) => ({
  type: UPDATE_INTERFACES,
  ...data
});

export const openPage = ({ pageType, id, status, PDNID }) => ({
  type: OPEN_PAGE,
  pageType,
  id,
  status,
  PDNID
});

export const saveCurrentVerification = (verificationIds, verificationId, options) => ({
  type: SAVE_CURRENT_VERIFICATION,
  verificationIds,
  verificationId,
  options
})

/* === Signal ===  */
export const editSignalName = (name, prevName) => ({
  type: EDIT_SIGNAL_NAME,
  name,
  prevName
});

export const editSignalNets = ({ nets, signalName }) => ({
  type: EDIT_SIGNAL_NETS,
  nets,
  signalName
});

export const deleteSignal = ({ name }) => ({
  type: DELETE_SIGNAL,
  name
});

/* === Simulation options(config) === */
export const updateCurrentConfig = (currentConfig) => ({
  type: UPDATE_CURRENT_CONFIG,
  currentConfig
});

export const saveSimulationOption = (apply, _2TModeChange) => ({
  type: SAVE_SIMULATION_OPTION,
  apply,
  _2TModeChange
})

export const updateUserDefinedList = (list) => ({
  type: UPDATE_USER_DEFINED_NETLSIT_VALUE,
  list
})
/* === Service options(config) === */
export const updateServiceConfig = (hspiceOption, ansysOption, options) => ({
  type: UPDATE_SERVICE_CONFIG,
  hspiceOption,
  ansysOption,
  options
});

export const saveServiceOption = () => ({
  type: SAVE_SERVICE_OPTION,
})

/* === Power Ground Nets === */
export const addPowerGrounds = () => ({
  type: ADD_POWER_GROUNDS
});

export const powerNetSelection = (name, prevName) => ({
  type: POWER_NET_SELECTION,
  name,
  prevName
});

/* === extraction === */
export const saveExtraction = (extraction) => ({
  type: SAVE_EXTRACTION,
  extraction
});

/* === Pin table === */
export const saveModel = ({ record, model, deviceVcc, pinModelsInfo, applyAll, applyAllMemory, pinsModelList }) => ({
  type: SAVE_PIN_MODEL,
  record,
  model,
  deviceVcc,
  pinModelsInfo,
  applyAll,
  applyAllMemory,
  pinsModelList
});

export const savePowerOff = ({ record, powerOff, applyAll, applyAllMemory }) => ({
  type: SAVE_POWER_OFF,
  record,
  powerOff,
  applyAll,
  applyAllMemory
});

export const saveModelPins = ({ record, pinModels, applyAll, modifyType }) => ({
  type: SAVE_MODEL_PINS,
  record,
  pinModels,
  applyAll,
  modifyType
});

export const saveStimulus = ({ record, stimulus, pinName, applyAll }) => ({
  type: SAVE_STIMULUS,
  record,
  stimulus,
  pinName,
  applyAll
});

/* === Component table === */
export const saveCompModel = ({ fileInfo, use, name }) => ({
  type: SAVE_COMP_MODEL,
  fileInfo,
  use,
  name
});

export const updateComponentLoading = (loading) => ({
  type: COMPONENT_LOADING,
  loading
})

export const saveCompPackageModel = (pkg, component) => ({
  type: SAVE_COMP_PACKAGE_MODEL,
  pkg,
  component
})

/* === COPY SETUP === */

export const applySetupToSelected = (copyVerifications, interfaceType) => ({
  type: APPLY_SETUP_TO_SELECT,
  copyVerifications,
  interfaceType,
})

export const updateApplySetupStatus = (status) => ({
  type: UPDATE_APPLY_SETUP_STATUS,
  status
})

/*  ERROR CHECK */
export const updateErrorCheckList = (infoErrorCheck) => ({
  type: UPDATE_ERROR_CHECK_LIST,
  infoErrorCheck
})

/* get current verification library file list */
export const updateCurrentLibraryFileList = (list) => ({
  type: UPDATE_CURRENT_LIBRARY_FILE_LIST,
  list
})

export const getLibraryFileParse = () => ({
  type: GET_LIBRARY_FILE_PARSE
})

/* channel pdn */
export const getChannelPDNContent = (verificationId, PDNID) => ({
  type: GET_CHANNEL_PDN_CONTENT,
  verificationId,
  PDNID
});

export const cleanSetupInfo = (clearPrev, pageType) => ({
  type: CLEAN_SETUP_INFO,
  clearPrev,
  pageType
});

export const updateTouchstoneStatusList = (list) => ({
  type: UPDATE_TOUCHSTONE_STATUS_LIST,
  list
});

export const updateTouchstoneMacroModelingStatus = ({ Components, verificationId }) => ({
  type: UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS,
  Components,
  verificationId
});

export const updatePrevStimulusMode = (prevStimulusMode) => ({
  type: UPDATE_PREV_STIMULUS_MODE,
  prevStimulusMode
});

export const updateADRCMDStimulus = (cmd_2t_mode) => ({
  type: UPDATE_ADR_CMD_STIMULUS,
  cmd_2t_mode
});

export const updateChannelByVersion = ({ designVersion, verifications }) => ({
  type: UPDATE_CHANNEL_BY_VERSION,
  designVersion,
  verifications
});

export const updatePortSetupsToServer = (data, ComponentSetups, interfaceType) => ({
  type: UPDATE_PORT_SETUPS_TO_SERVER,
  data,
  ComponentSetups,
  interfaceType
});

export const switchRankStatus = (status) => ({
  type: SWITCH_RANK_STATUS,
  status
});

export const closeRank = (record, component) => ({
  type: CLEAR_STANDBY_MODEL,
  record,
  component
})

export const applyRockyPortSetup = (info, ComponentSetups) => ({
  type: APPLY_ROCKY_PORT_SETUP,
  info,
  ComponentSetups
})

export const stimulusRandom = () => ({
  type: STIMULUS_RANDOM
})

export const selectPackageLayoutModel = (packageInfo, compName) => ({
  type: SELECT_PACKAGE_LAYOUT_MODEL,
  packageInfo,
  compName
})

export const updatePackageLoading = (loading) => ({
  type: UPDATE_PACKAGE_LOADING,
  loading
})

export const updatePackageMsg = (errorMsgInfo) => ({
  type: UPDATE_PACKAGE_MSG,
  errorMsgInfo
})

export const getPackageLayoutContent = (packageVerificationId) => ({
  type: GET_PACKAGE_CONTENT,
  packageVerificationId
})

export const updateSSNPackageInfo = (status) => ({
  type: UPDATE_SSN_PACKAGE_INFO,
  status
})

export const updateRockyPackageLayoutInfo = (rockyPackageInfo) => ({
  type: UPDATE_ROCKY_PACKAGE_LAYOUT_INFO,
  rockyPackageInfo
})

export const cleanPackageSetupInfo = () => ({
  type: CLEAN_PACKAGE_SETUP_INFO,
})

export const saveCurrentPackageVerification = (verificationIds, verificationId) => ({
  type: SAVE_CURRENT_PACKAGE_VERIFICATION,
  verificationIds,
  verificationId
});

export const updateDimmCardModel = (dimmCardModel, record, applyAll) => ({
  type: UPDATE_DIMM_CARD_MODEL,
  dimmCardModel,
  record,
  applyAll
});

export const changeSocketModel = (model, record, applyAll) => ({
  type: CHANGE_SOCKET_MODEL,
  model,
  record,
  applyAll
});

export const updateMemorySelections = (checked, memoryName) => ({
  type: UPDATE_MEMORY_SELECTIONS,
  checked,
  memoryName
})

export const updatePackageSignalInfo = (obj) => ({
  type: UPDATE_PACKAGE_SIGNAL_INFO,
  obj
})

export const updatePackageSetupLoading = (loading) => ({
  type: UPDATE_PACKAGE_SETUP_LOADING,
  loading
})

export const deletePackageSignal = (record) => ({
  type: DELETE_PACKAGE_SIGNAL,
  record
})

export const changePackageSignalName = (record, prevRecord) => ({
  type: CHANGE_PACKAGE_SIGNAL_NAME,
  record,
  prevRecord
})

export const addPackageVerification = (obj) => ({
  type: ADD_PACKAGE_VERIFICATION,
  obj
})

export const addPackageChannel = (obj) => ({
  type: ADD_PACKAGE_CHANNEL,
  obj
})

export const combinePackageInterface = (obj) => ({
  type: COMBINE_PACKAGE_INTERFACE,
  obj
})

export const updatePackageContentInfo = (obj) => ({
  type: UPDATE_PACKAGE_CONTENT_INFO,
  obj
})

export const updateRefNets = () => ({
  type: UPDATE_REF_NETS
})

export const updateSaveStatus = (status) => ({
  type: UPDATE_SAVE_STATUS,
  status
});

export const saveVirtualComponent = (virtualComponents) => ({
  type: SAVE_VIRTUAL_COMPONENT,
  virtualComponents
});

export const _editPkgComponentType = (record) => ({
  type: EDIT_PACKAGE_COMPONENT_TYPE,
  record
})

export const getCLKVerificationContent = (idList) => ({
  type: GET_CLK_VERIFICATION_CONTENT,
  idList
})

export const updatePackagePDNInfo = (info) => ({
  type: UPDATE_PACKAGE_PDN_INFO,
  info
})

export const saveBallSize = (key, shape, size, height, middle, material, componentName) => ({
  type: SAVE_BALL_SIZE,
  key,
  shape,
  size,
  height,
  middle,
  material,
  componentName
})

export const updatePackageNets = (key, id, nets) => ({
  type: UPDATE_PACKAGE_NETS,
  key,
  nets,
  id
})

export const updateDomainAfterUpdateNets = (id) => ({
  type: UPDATE_PACKAGE_COMPONENTS,
  id
})

export const savePackagePorts = (id, ports, portType) => ({
  type: UPDATE_PORTS,
  ports,
  portType,
  id
})

export const switchPackagePorts = (lumped, compType) => ({
  type: SWITCH_PACKAGE_PORTS,
  lumped,
  compType
})

export const splitComponentPart = (id, compList, part, newPart) => ({
  type: SPLIT_COMPONENT_PART,
  id,
  compList,
  part,
  newPart
})

export const mergeComponents = (id, mergeList) => ({
  type: MERGE_COMPONENTS,
  id,
  mergeList
})

export const updateComponentUsage = (id, compsName, part, checked) => ({
  type: UPDATE_COMP_USAGE,
  id,
  compsName,
  part,
  checked
})

export const removeComponents = (id, comps, part) => ({
  type: REMOVE_COMPONENTS,
  id,
  comps,
  part
})

export const saveCapModel = (record, value, model) => ({
  type: SAVE_DECAP_MODEL,
  record,
  value,
  model
})

export const savePkgPDNExtraction = (extraction) => ({
  type: SAVE_PKG_PDN_EXTRACTION,
  extraction
})

export const savePkgComponentSetting = (componentSetting) => ({
  type: SAVE_PKG_PDN_COMPONENT_SETTING,
  componentSetting
})

export const addNewPowerDomain = () => ({
  type: ADD_POWER_DOMAIN
})

export const deletePowerDomain = (id) => ({
  type: DELETE_POWER_DOMAIN,
  id
})

export const updatePackagePortAndBall = (oldLibraryId, libraryId) => ({
  type: UPDATE_PACKAGE_PORT_AND_BALL,
  oldLibraryId,
  libraryId
})

export const updatePCBChannelContent = (content) => ({
  type: UPDATE_PCB_CHANNEL_CONTENT,
  content
})

export const updatePDNVRM = (id, vrm) => ({
  type: UPDATE_VRM,
  id,
  vrm
})

export const updatePDNVoltage = (record) => ({
  type: UPDATE_VOLTAGE,
  record
})

export const saveVRMs = (vrms) => ({
  type: SAVE_VRMS,
  vrms
})

export const updateIncludeExtendNets = (id, includeExtend) => ({
  type: UPDATE_INCLUDE_EXTEND_NETS,
  id,
  includeExtend
})

export const savePackageContentAndClear = () => ({
  type: SAVE_PACKAGE_CONTENT_AND_CLEAR
})

export const savePcbChannelContent = (info) => ({
  type: SAVE_PCB_CHANNEL_CONTENT,
  info
});

export const updatePdnErrorCheck = (pdnErrorCheckList) => ({
  type: UPDATE_PDN_ERROR_CHECK,
  pdnErrorCheckList
})

export const reAssignDecapModel = () => ({
  type: RE_ASSIGN_DECAP_MODEL
})

export const updateModelAssignLoading = (loading) => ({
  type: UPDATE_MODEL_ASSIGN_LOADING,
  loading
})

export const changeTargetIC = (value) => ({
  type: UPDATE_TARGET_IC,
  value
})

export const updatePkgPDNDiePortsByCPM = (cpmPairs, packageId, lumped) => ({
  type: UPDATE_PKG_DIE_PORTS_BY_CPM,
  cpmPairs,
  packageId,
  lumped
})

export const startModelingExtraction = (obj) => ({
  type: START_MODELING_EXTRACTION,
  obj
})

export const getPcbChannelContent = (id) => ({
  type: GET_PCB_CHANNEL_CONTENT,
  id
})

export const getPCBAndPackageChannelInfo = (channelId, packageChannelId, pcbPreLayoutId) => ({
  type: GET_PCB_AND_PACKAGE_CHANNEL_INFO,
  channelId,
  packageChannelId,
  pcbPreLayoutId
})

export const updatePCBPowerComponents = (components) => ({
  type: UPDATE_PCB_POWER_COMPONENTS,
  components
})

export const saveChannelDecapModel = (record, value, model, rockyType) => ({
  type: SAVE_CHANNEL_DECAP_MODEL,
  record,
  value,
  model,
  rockyType
})

export const updatePKGReferenceNets = (nets) => ({
  type: UPDATE_PKG_REFERENCE_NETS,
  nets
})

export const reAssignChannelDecapModel = (rockyType) => ({
  type: RE_ASSIGN_CHANNEL_DECAP_MODEL,
  rockyType
})

export const updateChannelReferenceNets = (nets, rockyType) => ({
  type: UPDATE_CHANNEL_REFERENCE_NETS,
  rockyType,
  nets
})

export const applyPowerComponents = (rockyType, currentType, tabKey, copyPowerComponents, copyPowerDomains) => ({
  type: APPLY_POWER_COMPONENTS,
  rockyType,
  currentType,
  tabKey,
  copyPowerComponents,
  copyPowerDomains
})

export const updatePowerComponentsApplyLoading = (loading) => ({
  type: UPDATE_POWER_COMPONENTS_APPLY_LOADING,
  loading
})

export const updateDecapModelSetupLoading = (loading) => ({
  type: UPDATE_DECAP_MODEL_SETUP_LOADING,
  loading
})

export const isUpdatePackagePinStatus = (status) => ({
  type: IS_UPDATE_PACKAGE_PIN_STATUS,
  status
})

export const updateChannelErrorCheckList = (channelErrorCheck) => ({
  type: UPDATE_CHANNEL_ERROR_CHECK_LIST,
  channelErrorCheck
})

export const updatePowerComponentsAfterRefNets = (nets, rockyType) => ({
  type: UPDATE_POWER_COMPONENTS_AFTER_REF_NETS,
  nets,
  rockyType
})

export const updateChannelContent = ({ contentType, updateContent, updateVerificationId }) => ({
  type: UPDATE_CHANNEL_CONTENT,
  contentType,
  updateContent,
  updateVerificationId
})

export const updateChannelCompUsage = (compsName, part, checked, rockyType) => ({
  type: UPDATE_CHANNEL_COMP_USAGE,
  compsName,
  part,
  checked,
  rockyType
})

export const removeChannelComponents = (comps, part, rockyType) => ({
  type: REMOVE_CHANNEL_COMPONENTS,
  comps,
  part,
  rockyType
})

export const splitChannelComponentPart = (compList, part, newPart, rockyType) => ({
  type: SPLIT_CHANNEL_COMPONENT_PART,
  compList,
  part,
  newPart,
  rockyType
})

export const mergeChannelComponents = (mergeList, rockyType) => ({
  type: MERGE_CHANNEL_COMPONENTS,
  mergeList,
  rockyType
})

export const updateContentLoading = (loading) =>({
  type: UPDATE_CONTENT_LOADING,
  loading
})

export const updateLoading = (loadingKey, loading) => ({
  type: UPDATE_LOADING,
  loadingKey,
  loading
})