import React, { Component, Fragment } from 'react';
import { CloseOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '../Panel';
import './index.css';

function deleteBtn({ deleteConfirm, cancelDel, removeCancel, data, okTitle = "OK", cancelTitle = "Cancel" }) {
  return (
    <Fragment>
      <Button
        className='aurora-confirm-ok-button'
        onClick={(e) => deleteConfirm(e, data)}
        style={{ width: removeCancel ? '100%' : '50%' }}
      >{okTitle}</Button>
      <Button
        className={removeCancel ? 'aurora-confirm-cancel-none' : 'aurora-confirm-cancel-button'}
        onClick={cancelDel}
      >{cancelTitle}</Button>
    </Fragment>
  )
}

function changeBtn({ onChange, onIgnore }) {
  return <Fragment>
    <Button
      className='aurora-confirm-ok-button'
      onClick={onChange}
      style={{ width: '50%' }}
    >Change</Button>
    <Button
      className='aurora-confirm-cancel-button'
      onClick={onIgnore}
    >Ignore</Button>
  </Fragment>
}

function simulateBtn({ onSimulate, cancelSim }) {
  return <Fragment>
    <Button
      className='aurora-confirm-ok-button'
      onClick={onSimulate}
      style={{ width: '50%' }}
    >Simulate</Button>
    <Button
      className='aurora-confirm-cancel-button'
      onClick={cancelSim}
    >Cancel</Button>
  </Fragment>
}

function panelCloseBtn({ onFix, closePanel, confirmButton }) {
  return <Fragment>
    <Button
      className='aurora-confirm-ok-button'
      onClick={onFix}
      style={{ width: '50%' }}
    >{confirmButton ? confirmButton : "Fix"}</Button>
    <Button
      className='aurora-confirm-cancel-button'
      onClick={closePanel}
    >Close</Button>
  </Fragment>
}

function updateBtn({ onUpdate, cancelUpdate, cancelTitle }) {
  return <Fragment>
    <Button
      className='aurora-confirm-ok-button'
      onClick={onUpdate}
      style={{ width: '50%' }}
    >Update</Button>
    <Button
      className='aurora-confirm-cancel-button'
      onClick={cancelUpdate}
    >{cancelTitle || "Ignore"}</Button>
  </Fragment>
}

function updateReplace({ delConfirmClick }) {
  return <Fragment>
    <Button
      className='aurora-confirm-ok-button'
      onClick={() => { delConfirmClick('replace') }}
      style={{ width: '50%' }}
    >Replace</Button>
    <Button
      className='aurora-confirm-cancel-button'
      onClick={() => { delConfirmClick('merge') }}
    >Merge</Button>
  </Fragment>
}

function simCancelBtn({ reSimConfirmClick }) {
  return <Fragment>
    <Button
      className='aurora-confirm-ok-button'
      onClick={() => { reSimConfirmClick('Simulate') }}
      style={{ width: '50%' }}
    >Simulate</Button>
    <Button
      className='aurora-confirm-cancel-button'
      onClick={() => { reSimConfirmClick('Cancel') }}
    >Cancel</Button>
  </Fragment>
}

function refreshBtn({ refreshConfirm, cancelRefresh, cancelTitle = 'Cancel' }) {
  return (
    <Fragment>
      <Button
        className='aurora-confirm-ok-button'
        onClick={(e) => refreshConfirm(true)}
        style={{ width: '33.3%' }}
      >Complete</Button>
      <Button
        className='aurora-confirm-ok-button'
        onClick={(e) => refreshConfirm(false)}
        style={{ width: '33.3%' }}
      >Simple</Button>
      <Button
        className={'aurora-confirm-cancel-button'}
        onClick={cancelRefresh}
        style={{ width: '33.3%' }}
      >{cancelTitle}</Button>
    </Fragment>
  )
}

function determineBtn({ okClick, okTitle = 'OK' }) {
  return (
    <Fragment>
      <Button
        className='aurora-confirm-ok-button'
        onClick={(e) => okClick(true)}
        style={{ width: '90%' }}
      >{okTitle}</Button>
    </Fragment>
  )
}

function createOrEditBtn({ saveFileContent }) {
  return (
    <Fragment>
      <Button
        className='aurora-confirm-ok-button'
        onClick={() => saveFileContent('create')}
        style={{ width: '50%' }}
      >Create</Button>
      <Button
        className='aurora-confirm-ok-button'
        onClick={() => saveFileContent('edit')}
        style={{ width: '50%' }}
      >Edit</Button>
    </Fragment>
  )
}
class DelConfirm extends Component {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  getButton = (type) => {
    switch (type) {
      case 'delete':
        return deleteBtn(this.props);
      case 'change':
        return changeBtn(this.props);
      case 'simulate':
        return simulateBtn(this.props);
      case "close":
        return panelCloseBtn(this.props);
      case 'update':
        return updateBtn(this.props);
      case 'uploadDNS':
      case "uploadConnections":
      case "uploadPartLibrary":
        return updateReplace(this.props);
      case 'refresh':
        return refreshBtn(this.props);
      case "updateVerification":
        return simCancelBtn(this.props);
      case "determine":
        return determineBtn(this.props);
      case "createOrEdit":
        return createOrEditBtn(this.props);
      default: return deleteBtn(this.props);
    }
  }

  render = () => {
    const { message, maskStyle, type = 'delete', width = 360, mask = true, className, showCloseBtn, closeModal } = this.props;
    const content = (
      <Panel
        className={maskStyle ? `aurora-confirm-mask-panel aurora-confirm-panel ${className}` : `aurora-confirm-panel ${className}`}
        zIndex={2000}
        width={width}
        noHeader={true}
        position='panel-center'
        mask={mask}
        maskStyle={maskStyle}
        draggable={false}
        minWidth={width}
        maxWidth={width}
        maxHeight={800}
        createWH
        overflow={'auto'}
      >
        <div className='aurora-confirm-main'>
          {
            showCloseBtn ? <CloseOutlined className='aurora-confirm-close-icon' onClick={closeModal} /> : null
          }
          <div className='aurora-confirm-title'>
            {["uploadConnections", "uploadPartLibrary", "createOrEdit"].includes(type) ? <ExclamationCircleTwoTone className="aurora-confirm-Tips-icon" />
              : <img src="/lib/imgs/warning.png" className='aurora-confirm-warning-png' alt="" />}
          </div>
          <div className='aurora-confirm-content'>
            {message}
          </div>
          <div className='aurora-confirm-button clear'>
            {this.getButton(type)}
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}


export default DelConfirm;