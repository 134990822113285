import {
  INIT_STACKUP_DATA,
  CHANGE_STACKUP_TABLE,
  SETTING_UNIT
} from './actionTypes';
import {
  CLEAN_STATUS
} from '../../../store/Andes/actionTypes';
import { USER_LOGOUT } from '../../../../Login/actionTypes';

const defaultState = {
  data: [],
  unit: null,
  loading: true,
}

const stackupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_STACKUP_DATA:
    case CHANGE_STACKUP_TABLE:
      return { ...state, data: action.data, unit: action.unit ? action.unit : state.unit, loading: false }
    case SETTING_UNIT:
      return { ...state, unit: action.unit }
    case CLEAN_STATUS:
      return {
        ...defaultState
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default:
      return state;
  }
}

export default stackupReducer;