import React, { Component, Fragment } from "react";
import { getPinHeight, getPkgPinListWidth } from "../../services/helper/packageHelper";
import { portSetupSelect } from "../PinSpiceModel/pinRender";
import { getTextWidth } from "../../services/helper/getTextWidth";
import '../../publicCss/netlist.css';
import './index.css';

const PIN_L = "pinL", PIN_R = "pinR";
class SchematicPairs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  changeSelectPort = ({ pin, port, pinData }) => {
    const { pairs, subcktList, subckt, nodes, touchstonePorts, type } = this.props;
    let _pairs = [...pairs], _nodes = [...nodes];
    const index = pairs.findIndex(item => item.pin === pin && item.position === pinData.position);
    _pairs[index].node = port || "";

    if (type === "SPICE") {
      const subcktInfo = subcktList.find(item => item.name === subckt);
      if (port) {
        const findIndex = subcktInfo.ports.findIndex(item => item === port);
        _pairs[index].index = findIndex;
      } else {
        delete _pairs[index].index;
      }
      const pairsPorts = _pairs.map(item => item.node).filter(item => !!item);
      _nodes = subcktInfo.ports.filter(item => !pairsPorts.includes(item));
    } else if (type === "touchstone") {
      if (port) {
        const findIndex = (touchstonePorts || []).findIndex(item => item.port === port);
        _pairs[index].index = findIndex;
      } else {
        delete _pairs[index].index;
      }
      const pairsPorts = _pairs.map(item => item.node).filter(item => !!item);
      _nodes = touchstonePorts.filter(item => !pairsPorts.includes(item.port)).map(item => item.port);
    }
    this.props.savePairsAndNodes(_pairs, _nodes);
  }

  render = () => {
    const { pairs, getPinListByPairs, dirType } = this.props;
    const pinList = getPinListByPairs(pairs);
    /* const { leftPinWidth, rightPinWidth } = getPkgPinListWidth(pinList, "pin"); */
    const { leftPinWidth: leftPinValueWidth, rightPinWidth: rightPinValueWidth } = getPkgPinListWidth(pinList, "pinValue");
    return <div>
      {pinList.map((item, index) => {
        return <div
          className="spice-pin-connect-node-item spice-pin-node-item-center spice-pin-node-pairs-item-center"
          key={index}
          style={{
            height: getPinHeight(item, 40),
            paddingRight: 0
          }}
        >
          {this.getLeftModelTitle(dirType, item.component)}
          <div className="spice-pin-connect-model-content">

            <div className='spice-pin-list-li-left'>
              {this.getLeftPin({
                item,
                /* pinWidth: leftPinWidth, */
                pinValueWidth: leftPinValueWidth
              })}
            </div>
            {/* net box */}
            <div
              className="spice-box-title"
            /* title={item.component} */
            >
              <span className='spice-box-signal-text'>Circuit</span>
            </div>
            {/* - */}
            <div className="spice-pin-list-li-right">
              {this.getRightPin({
                item,
                /*  pinWidth: rightPinWidth, */
                pinValueWidth: rightPinValueWidth
              })}
            </div>
          </div>
          {this.getRightModelTitle(dirType, item.component)}
        </div>
      })}
    </div>
  }

  getLeftModelTitle = (dirType, component) => {
    let title = ""
    if (dirType === "TX") {
      title = "TX"
    }
    if (dirType === "RX") {
      title = "Channel"
    }

    const _title = title !== "Channel" ? `${component}:${title}` : title;
    const width = getTextWidth(_title, 12);
    return <Fragment>
      <div className={`spice-pin-model-${title}-main`}>
        <div className={`spice-pin-model-${title}-content`}></div>
        <div className={`spice-pin-model-${title}-title`}
          style={width > 50 ? { right: 2, left: "unset" } : { left: 2, right: "unset" }}>
          <span>{_title}</span>
        </div>
      </div>
      <div className={title === "Channel" ? "spice-pin-model-right-main" : `spice-pin-model-right-main spice-pin-model-tx-rx-main spice-pin-model-line-${title}-main`}>
        <div className='spice-pin-line'></div>
        <div className='spice-pin-line'></div>
      </div></Fragment>
  }

  getRightModelTitle = (dirType, component) => {
    let title = ""
    if (dirType === "TX") {
      title = "Channel"
    }
    if (dirType === "RX") {
      title = "RX"
    }
    const _title = title !== "Channel" ? `${component}:${title}` : title;
    return <Fragment>
      <div className={`spice-pin-model-left-main spice-pin-model-line-${title}-main`}>
        <div className='spice-pin-line'></div>
        <div className='spice-pin-line'></div>
      </div>
      <div className={`spice-pin-model-${title}-main`}>
        <div className={`spice-pin-model-${title}-content`}></div>
        <div className={`spice-pin-model-${title}-title`}>
          <span>{_title}</span>
        </div>
      </div>
    </Fragment >
  }

  getLeftPin = ({
    item,
    pinWidth,
    pinValueWidth }) => {
    const { nodes } = this.props;
    return item.pinLeft.map((pinData, index) => (
      <div className='spice-pin-die-value-box spice-total-pin-box' key={`${pinData.pin}-${index}`}>
        {/* Net */}
        {pinData.net ? <div className={index > 0 ? 'spice-pin-die-box spice-pin-die-bottom-box' : 'spice-pin-die-box'}
          title={pinData.net}
        >
          {pinData.net}
        </div> : null}
        {/* port */}
        {portSetupSelect({
          placeholder: "Node",
          port: pinData.pinValue,
          pin: pinData.pin,
          pinType: PIN_L,
          pinData,
          changeSelectPort: this.changeSelectPort,
          pinValueWidth,
          ports: nodes
        })}
      </div>)
    )
  }

  getRightPin = ({
    item,
    pinWidth,
    pinValueWidth }) => {
    const { nodes } = this.props;
    return item.pinRight.map((pinData, index) => (
      <div className='spice-pin-die-value-box spice-total-pin-box' key={`${pinData.pin}-${index}`}>
        {/* port */}
        {portSetupSelect({
          placeholder: "Node",
          port: pinData.pinValue,
          pin: pinData.pin,
          pinType: PIN_R,
          pinData,
          changeSelectPort: this.changeSelectPort,
          pinValueWidth,
          ports: nodes
        })}
        {pinData.net ? <div className={index > 0 ? 'spice-pin-die-box spice-pin-die-bottom-box' : 'spice-pin-die-box'}
          title={pinData.net}
        >
          {pinData.net}
        </div> : null}
      </div>)
    )
  }
}

export default SchematicPairs;