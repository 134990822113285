import { numberCheck } from "../helper/dataProcess";
import { checkNameFormat } from "../helper/nameFormatCheck";
import { SIGMA, DELTA, EPS_R, MIU_R, FREQUENCY, FREQ_CORNER, EPS_DC, FREQ_DEP, USE_DC_EPS, materialUnitSplit } from "./Material";
import { THICKNESS, ETCHFACTOR } from "./stackupTableHelper";
import { DIELECTRIC, METAL, MULTI_ZONE } from './stackupConstants';
import { MATERIAL } from "../../constants/libraryConstants";
import {
  CUSTOM,
  HAMMERSTAD_JENSEN,
  HURAY,
  ROUGHNESS_NONE,
  RMS_ROUGHNESS,
  SURFACE_RATIO,
  NODULE_RADIUS,
  rmsRoughnessUnitList,
  TOP_ROUGHNESS,
  BOT_ROUGHNESS,
  SIDE_ROUGHNESS
} from "./Roughness";
import { UNIT } from "../data/newStackupData";
import { newValueUnitSplit, valueUnitSplit } from "../helper/valueUnitSplit";
import { unitChange } from "../helper/mathHelper";
import hash from 'object-hash';
import { ANDES_V2, CASCADE, SIERRA } from "../../constants/pageType";

/**
 * check stackup item data
 * @param dataType type thickness/ ...
 * @param value data value
 * @param nameList layer names list [ { name, ...} , ... ]
 * @param type Metal / Dielectric
 * */
function stackupItemErrorCheck(dataType, value, setting = {}) {
  const { nameList = [], type = "", unit } = setting;
  let error = null;
  const displayType = getStackupDisplayType(dataType, type);

  if ([NODULE_RADIUS, RMS_ROUGHNESS].includes(dataType)) {
    const _valueObj = newValueUnitSplit(value);
    value = _valueObj.value;
    const unit = _valueObj.unit;
    if (!rmsRoughnessUnitList.includes(unit)) {
      return { type: dataType, error: `Unrecognized ${displayType} unit. We only support "mm", "um", "nm", "pm".` }
    }
  }
  //number check
  if (dataType !== 'name' && dataType !== ETCHFACTOR) {
    error = numberCheck(value);
    if (error) {
      error = displayType + ' ' + error;
      return { type: dataType, error }
    }
  }

  switch (dataType) {
    case THICKNESS:
      if (parseFloat(value) <= 0) {
        error = displayType + ' should be greater than 0.';
      }
      break;
    case 'name':
      error = checkNameFormat(value);
      if (!error && nameList) {
        const find = nameList.find(item => item.name === value);
        if (find) {
          error = 'Name already exists.';
        }
      }
      break;
    case SIGMA:
      if (type === METAL && (parseFloat(value) < 1.0e5 || parseFloat(value) > 1.0e10)) {//range 1.0e5 ~ 1.0e10
        error = displayType + ' should be between 1.0e5 ~ 1.0e10.';
      }
      break;
    case DELTA:
      if (parseFloat(value) < 0 || parseFloat(value) > 0.1) {//range 0 ~ 0.1
        error = displayType + ' should be between 0 ~ 0.1.';
      }
      break;
    case EPS_R:
      if (parseFloat(value) < 1 || parseFloat(value) > 10) {//range 1 ~ 10
        error = displayType + ' should be between 1 ~ 10.';
      }
      break;
    case MIU_R:
      if (parseFloat(value) < 0 || parseFloat(value) > 10) {//range 0 ~ 10
        error = displayType + ' should be between 0 ~ 10.';
      }
      break;
    case FREQUENCY:
    case FREQ_CORNER:
      if (unit && !["MHz", "GHz"].includes(unit)) {
        error = `Unrecognized ${displayType} unit. We only support "MHz", "GHz".`;
      }
      break;
    default: break;
  }

  return error ? { type: dataType, error } : null;
}

function stackupErrorCheck({
  layers,
  materialList,
  unit,
  prevLayers,
  isUpload = false,
  stackups,
  zones,
  extractionSolver,
  pageType,
  bends
}) {
  let error = [];
  const isMultiZones = zones;

  if (!layers.length) {
    error.push({ error: 'Stackup is missing layers. The Aurora platform will automatically generate layers. Please modify it in the Stackup panel.' })
  }

  if (isUpload) {
    let metalList = prevLayers.filter(item => item.type === METAL), dielectricList = prevLayers.filter(item => item.type === DIELECTRIC);
    // Check if the metal layers are consistent
    const dataName = layers.filter(item => item.type === METAL).map(item => item.name);
    if (dataName.length !== metalList.length) {
      error.push({ dataType: "Metal", error: 'Metal layers mismatch.' });
      return error;
    }
    for (let item of dataName) {
      if (metalList.findIndex(m => m.name === item) < 0) {
        error.push({ dataType: "Metal", error: 'Metal layers mismatch.' });
        return error;
      }
    }
    // Check if the layer names are duplicated
    let nameCheck = {};
    for (let check of [...metalList, ...dielectricList]) {
      if (nameCheck[check.name]) {
        error.push({ dataType: "Layers", error: 'Upload layers name is duplicated.' });
        return error;
      }
      nameCheck[check.name] = true;
    }

    for (let index in dataName) {
      if (dataName[index] !== metalList[index].name) {
        error.push({ dataType: "Layers", error: 'Metal layers mismatch.' });
        return error;
      }
    }
  }

  if (!UNIT.includes(unit)) {
    error.push({ dataType: "Unit", error: `Unrecognized unit. We only support "mil", "um", "mm".` })
  }

  for (let i = 0; i < layers.length; i++) {
    const item = layers[i];
    //thickness error check
    const thicknessError = stackupItemErrorCheck(THICKNESS, item[THICKNESS]);
    thicknessError && error.push({ layerName: item.name, ...thicknessError });

    //Metal material error check
    const material = materialList.find(it => it.name === item.material);
    if (!item.material) {
      error.push({ layerName: item.name, dataType: MATERIAL, error: "Material is not set." });
    }

    if (!material && item.material) {
      error.push({ layerName: item.name, dataType: MATERIAL, error: `${item.material} is not found in the material list.` });
    } else if (material && item.material) {
      const metalMaterialErrors = getMaterialErrorCheck(material, item.name);
      error.push(...metalMaterialErrors);
    }

    if (item.type === METAL) {
      //roughness error check
      const topRoughnessError = getRoughnessErrorCheck(item.roughness);
      const botRoughnessError = item.bot_roughness ? getRoughnessErrorCheck(item.bot_roughness) : null;
      const sideRoughnessError = item.side_roughness ? getRoughnessErrorCheck(item.side_roughness) : null;
      topRoughnessError && error.push({
        layerName: item.name,
        dataType: TOP_ROUGHNESS,
        error: `Surface top roughness ${topRoughnessError}`
      });
      botRoughnessError && error.push({
        layerName: item.name,
        dataType: BOT_ROUGHNESS,
        error: `Surface bottom roughness ${botRoughnessError}`
      });
      sideRoughnessError && error.push({
        layerName: item.name,
        dataType: SIDE_ROUGHNESS,
        error: `Surface side roughness ${sideRoughnessError}`
      });
      continue;
    }

    if (!isMultiZones || ![CASCADE, ANDES_V2, SIERRA].includes(pageType)) {
      continue;
    }
    //multi zone stackup error check
    // const findLayer = stackups ? stackups.find(_item => _item.layers && !!_item.layers.find(it => it === item.name)) : null;
    // if (findLayer && !item.laminated && !isUpload && stackupMode === MULTI_ZONE) {
    //   error.push({ layerName: item.name, dataType: item.type, error: `${item.name} does not belong to any stackup.` })
    //   error.push({ layerName: item.name, dataType: item.type, error: `Laminated for layer "${item.name}" is not selected.` })
    // }
  }

  // if (isMultiZones && stackupMode === MULTI_ZONE && extractionSolver === "SIwave" && [CASCADE, ANDES_V2, SIERRA].includes(pageType)) {
  //   error.push({ error: `Multi-zone stackup does not support "SIwave" solver.` });
  // }

  return error;
}

function getStackupDisplayType(dataType, layerType) {
  switch (dataType) {
    case THICKNESS:
      return '"Thickness"';
    case SIGMA:
      if (layerType === METAL) {
        return '"Conductivity"';
      } else if (layerType === DIELECTRIC) {
        return '"DC Conductivity"';
      }
      return;
    case DELTA:
      return '"Loss Tangent"';
    case EPS_R:
      return '"Relative Permittivity"';
    case MIU_R:
      return '"Relative Permeability"';
    case FREQUENCY:
      return '"Frequency"';
    case FREQ_CORNER:
      return '"High Frequency Corner"';
    case EPS_DC:
      return '"​DC Permittivity​"';
    case RMS_ROUGHNESS:
      return '"RMS Roughness"';
    case SURFACE_RATIO:
      return '"Hall-Huray Surface Ratio"';
    case NODULE_RADIUS:
      return '"Nodule Radius"';
    default: return dataType;
  }
}

function getRoughnessErrorCheck(roughness) {
  if (!roughness) {
    return "Surface roughness is not set.";
  }
  switch (roughness.type) {
    case ROUGHNESS_NONE:
      return null;
    case HAMMERSTAD_JENSEN:
      const error = stackupItemErrorCheck(RMS_ROUGHNESS, roughness[RMS_ROUGHNESS]);
      if (error) {
        return error.error;
      }
      return null;
    case HURAY:
      if (roughness.setting === CUSTOM) {
        const error = stackupItemErrorCheck(SURFACE_RATIO, roughness[SURFACE_RATIO]);
        if (error) {
          return error.error;
        }

        const _error = stackupItemErrorCheck(NODULE_RADIUS, roughness[NODULE_RADIUS]);
        if (_error) {
          return _error.error;
        }
      }
      return null;
    default: return null;
  }
}

function getMaterialErrorCheck(material, layerName = "") {
  let error = [];
  let _material = materialUnitSplit(material);
  //name check
  /* const materialNameError = stackupItemErrorCheck("name", _material.name);
  materialNameError && error.push({ layerName, ...materialNameError }); */

  if (_material.type === METAL) {
    //sigma check
    const materialError = stackupItemErrorCheck(SIGMA, _material[SIGMA], { type: METAL });
    materialError && error.push({ layerName, ...materialError });

    //miu_r
    if (_material[MIU_R]) {
      const epsRError = stackupItemErrorCheck(MIU_R, _material[MIU_R]);
      epsRError && error.push({ layerName, ...epsRError });
    }
  }
  if (_material.type === DIELECTRIC) {
    //eps_r
    const epsRError = stackupItemErrorCheck(EPS_R, _material[EPS_R]);
    epsRError && error.push({ layerName, ...epsRError });

    //delta
    const deltaError = stackupItemErrorCheck(DELTA, _material[DELTA]);
    deltaError && error.push({ layerName, ...deltaError });

    //freq_dep
    if (_material[FREQ_DEP] !== "no") {
      //frequency
      const frequencyError = stackupItemErrorCheck(FREQUENCY, _material[FREQUENCY], { unit: _material[`${FREQUENCY}Unit`] });
      frequencyError && error.push({ layerName, ...frequencyError });

      //freq_corner
      const freCornerError = stackupItemErrorCheck(FREQ_CORNER, _material[FREQ_CORNER], { unit: _material[`${FREQ_CORNER}Unit`] });
      freCornerError && error.push({ layerName, ...freCornerError });

      //use_dc_eps
      if (_material[USE_DC_EPS]) {
        //eps_dc
        const epsDcError = stackupItemErrorCheck(EPS_DC, _material[EPS_DC]);
        epsDcError && error.push({ layerName, ...epsDcError });
      }

      //eps_r
      const sigmaError = stackupItemErrorCheck(SIGMA, _material[SIGMA], { type: DIELECTRIC });
      sigmaError && error.push({ layerName, ...sigmaError });
    }
  }
  return error;
}

let checkConfig = null;
function djordjevicErrorCheck(config) {
  if (hash(checkConfig) === hash(config) || config[FREQ_DEP] === 'no') {
    checkConfig = JSON.parse(JSON.stringify(config));
    return;
  }
  // get value
  checkConfig = JSON.parse(JSON.stringify(config));
  const freq_1 = unitChange({ num: config[FREQUENCY], oldUnit: config[`${FREQUENCY}Unit`], newUnit: 'Hz', decimals: 2 }).number;
  const freq_corner = unitChange({ num: config[FREQ_CORNER], oldUnit: config[`${FREQ_CORNER}Unit`], newUnit: 'Hz', decimals: 2 }).number
  const eps_1 = Number(config[EPS_R]), delta = Number(config[DELTA]), sigma = Number(config[SIGMA]);
  let eps_dc = Number(config[EPS_DC]);

  // get clac constant
  const eps_0 = 8.854e-12;
  const omega_1 = 2 * Math.PI * freq_1;
  const omega_b = 2 * Math.PI * freq_corner;
  // Formulation
  // K = (eps_1 * delta_1 - sigma / (omega_1 * eps_0)) / atan(omega_b / omega_1)
  const K = (eps_1 * delta - sigma / (omega_1 * eps_0)) / Math.atan(omega_b / omega_1);
  if (K < 0) {
    const sigma_valid = (eps_1 * delta * omega_1 * eps_0).toExponential(2);
    return { error: `Please reduce DC Conductivity to be smaller than ${sigma_valid}` };
  }

  // eps_inf = eps_1 - K * log(sqrt(omega_b * omega_b + omega_1 * omega_1) / omega_1)
  const eps_inf = eps_1 - K * Math.log(Math.sqrt(omega_b * omega_b + omega_1 * omega_1) / omega_1);
  if (eps_inf < 1) {
    let eps_1_valid = 1 + K * Math.log(Math.sqrt(omega_b * omega_b + omega_1 * omega_1) / omega_1);
    eps_1_valid = Number(eps_1_valid.toFixed(2));
    return { error: `Please increase Relative Permittivity to be greater than ${eps_1_valid}` };
  }

  if (!config[USE_DC_EPS]) {
    // eps_dc = eps_inf + 10 * delta_1 * eps_inf
    eps_dc = eps_inf + 10 * delta * eps_inf;
    eps_dc = Number(eps_dc.toFixed(2));
  }

  if (eps_dc < eps_1) {
    return { error: "Please increase DC Permittivity​ to be greater than Relative Permittivity", eps_dc };
  } else {
    return { eps_dc, error: "" };
  }
}

export {
  stackupItemErrorCheck,
  stackupErrorCheck,
  getRoughnessErrorCheck,
  getMaterialErrorCheck,
  djordjevicErrorCheck
}