import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import ResultTopMenu from '@/components/ResultTopMenu';
import { changeResultKey } from '../store/result/action';
import { EXPERIMENTS_RESULT } from '../../../constants/treeConstants';
import { SIERRA } from '../../../constants/pageType';
import './index.css'

const baseMenu = [{
  name: 'Impedance',
  key: 'impedance'
},
{
  name: 'Delay',
  key: 'delay'
},
{
  name: 'Waveform',
  key: 'waveform'
}];
const channelMenu = [{
  name: 'Network Parameter ',
  key: 'channel'
}];
const eyeDiagramMenu = [{
  name: 'Eye Diagram',
  key: 'eyeDiagrams'
}];
const capacitanceMenu = [{
  name: 'Total Trace Capacitance',
  key: 'capacitance'
}];


function getMenu(viewList, hasChannel) {
  let menu = JSON.parse(JSON.stringify(baseMenu));

  //The sweep experiment has no result of eyeDiagram
  if (!viewList.includes(EXPERIMENTS_RESULT)) {
    menu = [...menu, ...eyeDiagramMenu];
  }
  if (hasChannel) {
    menu.splice(2, 0, ...channelMenu);
    !viewList.includes(EXPERIMENTS_RESULT) && menu.splice(3, 0, ...capacitanceMenu);
  }
  return menu;
}

function rightMenu(props) {
  const { rightMenuList = [], changeRightMenuKey, rightVisible, progress, isSweep, recalc, sweepReCalc, sweepMeasureProgress } = props;
  return rightMenuList.map(item => {
    const Icon = item.icon;
    let _progress = progress;
    if (item.key === "measurement" && isSweep && sweepReCalc && sweepReCalc.length) {
      _progress = sweepMeasureProgress;
    }
    return <div key={item.key} className='result-top-menu-item sierra-top-menu-right-item'>
      <span
        className={rightVisible[item.key] ? "menu-item-title menu-item-title-select" : "menu-item-title"}
        onClick={() => changeRightMenuKey(item.key)}
      >
        {item.icon ? <Icon className='sierra-result-top-menu-setting-icon' /> : null}
        {item.name}
        {item.key === "measurement" && (
          (recalc && progress !== false && progress >= 0)
          || (isSweep && sweepReCalc && sweepReCalc.length && _progress && _progress >= 0)) ?
          <Fragment>
            <div className='sierra-result-post-processing-progress' style={{ width: `${_progress}%` }} />
            <span className='sierra-result-post-processing-progress-span'>{_progress}%</span>
          </Fragment>
          : null}
      </span>
    </div>
  });
}

class TopMenu extends PureComponent {

  getMeasurementWidth = () => {
    const { selectedKey, progress, recalc, sweepReCalc, isSweep } = this.props;
    let measurementWidth = 0;
    if (selectedKey === "waveform") {
      measurementWidth = 200;
      if ((recalc && progress !== false) || (isSweep && sweepReCalc && sweepReCalc.length)) {
        measurementWidth = 250
      }
    }

    return measurementWidth;
  }
  render() {
    const { selectedKey, changeResultType, viewList, hasChannel, leftWidth, layoutLeftWidth, isSweep, rightMenuList } = this.props;
    const measurementWidth = this.getMeasurementWidth();

    return (
      <ResultTopMenu
        selectedKey={selectedKey}
        changeResultType={changeResultType}
        menu={getMenu(viewList, hasChannel)}
        backSetup={{
          name: 'Setup',
          key: 'setup'
        }}
        leftWidth={leftWidth}
        layoutLeftWidth={layoutLeftWidth}
        product={SIERRA}
        isSweep={isSweep}
        measurementWidth={measurementWidth}
        selectedChildren={this.props.getExperimentShowList}
      >
        {rightMenuList && rightMenuList.length ? rightMenu(this.props) : null}
      </ResultTopMenu>
    )
  }
}

const mapDispatch = (dispatch) => ({
  changeResultKey(key) {
    dispatch(changeResultKey(key))
  },
})

export default connect(null, mapDispatch)(TopMenu);