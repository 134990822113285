import CeLarc from "../../geometry/CeLarc";
import CeLine from "../../geometry/CeLine";
import Line from "./geometry/Line";
import Larc from "./geometry/Larc";
import Circle from "./geometry/Circle";
import Rectangle from "./geometry/Rectangle";
import Polygon from './geometry/Polygon';
import { unitChange } from '../../helper/mathHelper';
import CeCircle from "../../geometry/CeCircle";
import GCPolygon from "../../geometry/GCPolygon";
import CePolygonWithHole from "../../geometry/CePolygonWithHole";
import CePolygon from "../../geometry/CePolygon";

const getRandomColor = function () {
  return '#' + (Math.random() * 0xffffff << 0).toString(16);
}

class Boundaries {
  // clipSize - unit is mm
  constructor({ layoutDB, distance, clipSize }) {
    this.layoutDB = layoutDB;
    this.distance = distance; // The unit is the unit of design
    this.netsBoundaries = [];
    this.compsBoundaries = [];
    this.tempShape = new Map();  // { key - id, value - shapeSymbol }
    this.unit = layoutDB.mUnits === 'mils' ? 'mil' : layoutDB.mUnits;
    this.clipSize = clipSize;
    this.pointDensity = this.initPointDensity(clipSize);
  }

  initPointDensity = (clipSize) => {
    let num = 10;
    return this.milToUnit(Math.ceil(num));
  }

  milToUnit = (num) => {
    return unitChange({ num: parseFloat(num), newUnit: this.unit, decimals: 12 }).number;
  }

  // arr - [[x1, y1], [x2, y2]]
  getGeneralBoundary(arr) {
    let boundaryArr = [];
    let num = 10;
    if (this.clipSize > 10) {
      num = this.clipSize * 2;
    }
    const pointDensity = this.milToUnit(Math.ceil(num));
    for (let item of arr) {
      const boundary = new Boundary('Circle');
      boundary.type = 'Circle';
      boundary.geom = new Circle({ mDiameter: 0, mX: item[0], mY: item[1] });
      boundaryArr.push(...boundary.toPoints(this.distance, pointDensity))
    };
    return boundaryArr;
  }

  getNetBoundary(netName) {
    const { mNetManager } = this.layoutDB;
    const net = mNetManager.GetNetFromName(netName);
    const netBoundary = new NetBoundary(net.mName);
    let item = null;
    let mGeomList = [].concat(net.mGeomList);
    mGeomList = mGeomList.reduce((prev, curr) => {
      if (curr.mRefGeom.mGeometry &&
        (curr.mRefGeom.mGeometry instanceof CePolygonWithHole ||
          curr.mRefGeom.mGeometry instanceof CePolygon)
      ) {
        prev.unshift(curr)
      } else {
        prev.push(curr)
      }
      return prev;
    }, []);
    let polyArr = [];
    for (let { mRefGeom: geom } of mGeomList) {
      // case1: mGeometry: null, mLocation ("Circle", "Rectangle")
      let notInPolygon = true;
      if (!geom.mGeometry && geom.mLocation) {
        const { mPosition } = geom.mLocation;
        if (mPosition.mGeomType === 'Pnt') {
          const { mX, mY } = mPosition;
          item = this.getShapeBoundary(geom.mSymbolID, { mX, mY });
          if (polyArr.length) {
            if (item.type === 'Circle') {
              for (let poly of polyArr) {
                if (inPolygon(item.geom, poly)) {
                  notInPolygon = false;
                  break;
                }
              }
            } else if (item.type === 'Rectangle') {
              const { mHeight, mWidth, mX, mY } = item.geom;
              const h = mHeight / 2, w = mWidth / 2;
              const p = [{ mX: mX - w, mY: mY - h },
              { mX: mX - w, mY: mY + h },
              { mX: mX + w, mY: mY - h },
              { mX: mX + w, mY: mY + h }];
              for (let poly of polyArr) {
                if (p.every(_p => inPolygon(_p, poly))) {
                  notInPolygon = false;
                  break;
                }
              }
            }
          }
        } else {
          console.error(`[Debug] Unrecognized type (mPosition.mGeomType)`, mPosition.mGeomType)
          continue;
        }
      } else if (!geom.mLocation && geom.mGeometry) {
        if (geom.mGeometry instanceof CeLine) {
          const { mStart, mEnd } = geom.mGeometry;
          item = this.getShapeBoundary(geom.mSymbolID, { mStart, mEnd }, 'Line');
          if (polyArr.length) {
            try {
              for (let poly of polyArr) {
                if (inPolygon(mStart, poly) && inPolygon(mEnd, poly)) {
                  notInPolygon = false;
                  break;
                }
              }
            } catch (error) {
              console.error(error)
            }
          }
        } else if (geom.mGeometry instanceof CeLarc) {
          const { mStart, mEnd, mCenter, mCCW } = geom.mGeometry;
          item = this.getShapeBoundary(geom.mSymbolID, { mStart, mEnd, mCenter, mCCW }, 'Larc');
          if (polyArr.length) {
            try {
              for (let poly of polyArr) {
                if (inPolygon(mStart, poly) && inPolygon(mEnd, poly)) {
                  notInPolygon = false;
                  break;
                }
              }
            } catch (error) {
              console.error(error)
            }
          }
        } else if (geom.mGeometry instanceof CePolygonWithHole || geom.mGeometry instanceof CePolygon) {
          const { mVertices } = geom.mGeometry;
          item = this.getShapeBoundary(geom.mSymbolID, { mVertices }, 'Polygon');
          polyArr.push(item.geom.mVertices);
        } else {
          console.error(`[Debug] Unrecognized type (geom.mGeometry)`, geom.mGeometry)
          continue;
        }
      } else {
        // TODO
        continue;
      }
      if (notInPolygon) {
        netBoundary.add(this.getBoundaryPoints(item));
      }
    }
    let obj = {}, allBoundaries = [];
    netBoundary.boundaries.forEach(d => {
      allBoundaries = allBoundaries.concat(d);
    })

    const arr = allBoundaries.filter(item => {
      if (item && !obj[item.toString()] && !item.includes(NaN)) {
        obj[item.toString()] = true;
        return item;
      }
      return false;
    });
    return arr;
  }

  getPinsBoundaryArr(pins) {
    let arr = [];
    for (let pin of pins) {
      arr.push({ pin: pin.number, boundary: this.getCompPinBoundary(pin) });
    }
    return arr;
  }

  // Polygon - Convert the location of the pad from relative component location to pin location
  _pinLocConvert(pinInfo) {
    function vertice(mX, mY) {
      this.mX = mX;
      this.mY = mY;
      this.mGeomType = 'Pnt';
    }
    let mVertices = [] // { mX, mY }
    // x,y - pin location
    const { x, y, pad: { mNumPnts, mXs, mYs } } = pinInfo;
    for (let i = 0; i < mNumPnts; i++) {
      mVertices.push(new vertice(mXs[i] - x, mYs[i] - y))
    }
    return mVertices;
  }

  getCompPinBoundary(pin) {
    let boundary = new Boundary();
    const pad = pin.pad;
    if (pad instanceof CeCircle) {
      boundary.type = 'Circle';
      boundary.geom = new Circle({ mDiameter: pad.mDiameter, mX: pin.x, mY: pin.y });
    } else if (pad instanceof GCPolygon) {
      boundary.type = 'Polygon';
      boundary.geom = new Polygon({ mVertices: this._pinLocConvert(pin), mX: pin.x, mY: pin.y });
    }
    else {
      return []
    }
    return boundary.toPoints(this.distance, this.pointDensity);
  }

  getBoundaryPoints(boundary) {
    if (boundary) {
      return boundary.toPoints(this.distance, this.pointDensity);
    }
    return null;
  }

  getShapeBoundary(id, data, type) {
    let symbol = null;
    if (id !== -1) {
      symbol = this.getShapeSymbolById(id);
    }
    if (id !== -1 && !symbol) return null;
    const boundary = new Boundary(type);
    if (type === 'Line') {
      const { mStart, mEnd } = data;
      const start = this.getShapeBoundary(id, mStart);
      const end = this.getShapeBoundary(id, mEnd);
      boundary.geom = new Line({ start, end });
    } else if (type === 'Larc') {
      const { mStart, mEnd, mCenter, mCCW } = data;
      const start = this.getShapeBoundary(id, mStart);
      const end = this.getShapeBoundary(id, mEnd);
      boundary.geom = new Larc({ start, end, mCenter, mCCW });
    } else if (type === 'Polygon') {
      boundary.type = 'Polygon';
      boundary.geom = new Polygon({ mVertices: data.mVertices });
    } else if (symbol instanceof Circle) {
      boundary.type = 'Circle';
      boundary.geom = new Circle({ ...symbol, mX: data.mX - symbol.mX, mY: data.mY - symbol.mY });
    } else if (symbol instanceof Rectangle) {
      boundary.type = 'Rectangle';
      boundary.geom = new Rectangle({ ...symbol, mX: data.mX - symbol.mX, mY: data.mY - symbol.mY });
    } else if (symbol instanceof Polygon) {
      boundary.type = 'Polygon';
      boundary.geom = new Polygon({ ...symbol, mX: data.mX, mY: data.mY });
    }
    return boundary;
  }

  getShapeSymbolById(id) {
    if (!this.tempShape.has(id)) {
      /*  const twoDistance = 2 * this.distance; */
      const { mSymbolMgr } = this.layoutDB;
      const symbol = mSymbolMgr.GetShapeGeomObj(id);
      const geomType = symbol.mGeometry.mGeomType;
      if (geomType === 'Circle') {
        const { mCenter, mDiameter } = symbol.mGeometry;
        this.tempShape.set(id, new Circle({
          mDiameter: mDiameter/*  + twoDistance */,
          mX: mCenter.mX,
          mY: mCenter.mY
        }))
      } else if (geomType === 'Rectangle' || geomType === 'RectCutCorner' || geomType === 'Square') {
        const { mCenter, mHeight, mWidth } = symbol.mGeometry;
        this.tempShape.set(id, new Rectangle({
          mHeight,
          mWidth,
          mX: mCenter.mX,
          mY: mCenter.mY
        }))
      } else if (geomType === 'Polygon') {
        const { mCCW, mVertices } = symbol.mGeometry;
        this.tempShape.set(id, new Polygon({
          mCCW,
          mVertices
        }))
      } else {
        console.error(`[Debug] Unrecognized type (geomType)`, geomType)
      }
    }
    return this.tempShape.get(id) || [];
  }

  getViaSymbolById(id) {
    const { mSymbolMgr } = this.layoutDB;
    return mSymbolMgr.GetViaGeomObj(id);
  }

}

class NetBoundary {
  constructor(name) {
    this.name = name;
    this.boundaries = [];
  }

  add(points) {
    this.boundaries.push(points);
  }
}

class Boundary {
  constructor(type = '') {
    this.type = type;
    this.geom = {};
  }

  toPoints = (distance, pointDensity) => {
    return this.geom.toPoints ? this.geom.toPoints(distance, pointDensity) : null;
  }

  toHybridPoints = (distance, pointDensity) => {
    return this.geom.toHybridPoints ? this.geom.toHybridPoints(distance, pointDensity) : null;
  }
}

/**
  * @description Ray method to determine whether a point is inside a polygon
  * @param {Object} p point { mX, mY}
  * @param {Array} poly points array [{ mX, mY }]
  * @return {String} true - inside the polygon, false - outside the polygon
  */
function inPolygon(p, poly) {
  var px = p.mX,
    py = p.mY,
    flag = false

  for (var i = 0, l = poly.length, j = l - 1; i < l; j = i, i++) {
    var sx = poly[i].mX,
      sy = poly[i].mY,
      tx = poly[j].mX,
      ty = poly[j].mY

    // Points coincide with polygon vertices
    if ((sx === px && sy === py) || (tx === px && ty === py)) {
      return 'on'
    }

    // Determine whether the two ends of the line segment are on both sides of the ray
    if ((sy < py && ty >= py) || (sy >= py && ty < py)) {
      // The X coordinate of the point on the line segment that has the same Y coordinate as the ray
      var x = sx + (py - sy) * (tx - sx) / (ty - sy)

      // point on the edge of the polygon
      if (x === px) {
        return 'on'
      }

      // The ray goes through the boundary of the polygon
      if (x > px) {
        flag = !flag
      }
    }
  }

  // The point is inside the polygon when the ray crosses the polygon boundary an odd number of times
  return flag ? true : false
}


export default Boundaries;
export { Boundary }