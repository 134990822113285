import React from "react";
import { getPCBUploadList } from "@/services/PCBHelper/pcbHelper";
import { CASCADE } from '@/constants/pageType';
import { PCBS, CASCADE_PACKAGES } from '@/constants/treeConstants';

export default function higherOrderComponent({ UploadPCBMenu, ...otherProps }) {
  return class extends React.Component {
    constructor(props) {
      super()
    }
    render() {
      const { data } = this.props;
      const pcbType = data.dataType === PCBS ? CASCADE : CASCADE_PACKAGES;
      const pcbList = getPCBUploadList(pcbType);
      return <UploadPCBMenu
        {...otherProps}
        {...this.props}
        pcbList={pcbList}
      />
    }
  }
}