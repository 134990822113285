import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Spin, Tooltip, Popover, Checkbox } from 'antd';
import EditableTable from '@/components/EditableTable';
import PointSelectPanel from './PointSelectPanel';
import { updateDCRData, deletePathRData, resistanceSelectedList } from '../store/DCR/action';
import ResistanceData from '../../../services/Cascade/helper/DCRData';
import { selectChange } from '../../LayoutExplorer/store/Cascade/actionCreators';
import { numberToScientific, getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { numberCheck } from '../../../services/helper/dataProcess';
import { valueUnitSplit } from "../../../services/helper/valueUnitSplit";
import _ from 'lodash';
import { unitChange } from '../../../services/helper/mathHelper';

const ResistanceColumns = [{
  title: 'Index',
  dataIndex: 'index',
  key: 'index',
  width: '6%',
}, {
  title: 'Point 1',
  dataIndex: 'point1',
  key: 'point1',
  width: '20%',
}, {
  title: 'Point 2',
  dataIndex: 'point2',
  key: 'point2',
  width: '20%',
}, {
  title: 'Net',
  dataIndex: 'net',
  width: '20%'
},
{
  title: 'Spec',
  dataIndex: 'spec',
  width: '15%'
},
{
  title: 'Resistance',
  dataIndex: 'resistance',
  width: '20%',
  sorter: (a, b) => {
    var c = isFinite(a.resistance)
    var d = isFinite(b.resistance);
    return (c !== d && d - c) || (c && d ? a.resistance - b.resistance : a.resistance.localeCompare(b.resistance));
  }
}];

class Resistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsUpdate: false,
      selectedPathList: [],
      visible: false
    };

    ResistanceColumns[0].render = (text, record, index) => {
      const { visible, selectedPathList, action } = this.state;
      const merge = action === 'form' ? true : false;
      const existLoop = action === 'exist' ? true : false;
      const title = merge ? "Form Loop" : (existLoop ? "The same loop already exists!" : "");
      return {
        children: selectedPathList &&
          selectedPathList.length > 0 &&
          selectedPathList[0] === record.id ?
          <Popover
            placement="topLeft"
            overlayClassName="merge-path-popover"
            getPopupContainer={() =>
              document.getElementById("cascade-content-main")
            }
            content={
              <Fragment>
                <Tooltip
                  title={title}
                  mouseLeaveDelay={0}
                  mouseEnterDelay={0.3}
                  overlayClassName="icon-tooltip"
                >
                  <span
                    className="merge-path-popover-span"
                    onClick={(e) => merge ? this.clickFormLoop(e) : null}
                  >
                    {title}
                  </span>
                </Tooltip>
              </Fragment>
            }
            open={visible}
          >
            <div className="path-r-index-content cursor-pointer" onClick={(e) => this.clickTableRow(e, record)}>
              <Checkbox
                checked={selectedPathList.includes(record.id)}
                onChange={(e) => this.clickTableRow(e, record, true)}
                className='path-r-index-checkbox'
              />
              <span>{text}</span>
              <CloseOutlined
                onClick={(e) => this.deleteDcrExplorer(e, index)}
                className="resistance-delete-icon" />
            </div>
          </Popover>
          : <div className="path-r-index-content cursor-pointer" onClick={(e) => this.clickTableRow(e, record)}>
            <Checkbox
              checked={selectedPathList.includes(record.id)}
              onChange={(e) => this.clickTableRow(e, record, true)}
              className='path-r-index-checkbox'
            />
            <span>{text}</span>
            <CloseOutlined
              onClick={(e) => this.deleteDcrExplorer(e, index)}
              className="resistance-delete-icon" />
          </div>,
        props: {
          tdClassName: 'path-r-index-td'
        }
      };
    }

    ResistanceColumns[1].render = (text, record) => {
      const { samePoints = [], error, point1s } = record;
      const className = error ? "resistance-table-error-span" : "";
      return <Tooltip title={this.props.loading ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
        {Array.isArray(point1s) ? point1s.map((item, index) => <span key={index} className={`${className}`}>
          {point1s.length > 1 && item.comp ? "(" : null}
          {samePoints.includes(item.comp) ? <span className='dcr-warning-color'>{item.comp}</span> : item.comp}
          {(item.pins && item.pins.length) ?
            <Fragment> - <span className="point-pin-span">{item.pins.map((pin, index) => <span key={pin}><span className={samePoints.includes(`${item.comp}::${pin}`) ? 'dcr-warning-color' : ''}>{pin}</span>{index === item.pins.length - 1 ? '' : ', '}</span>)}</span></Fragment> : ""
          }
          {point1s.length > 1 && item.comp ? ") " : null}
        </span>) : null}
      </Tooltip>
    }

    ResistanceColumns[2].render = (text, record) => {
      const { samePoints = [], error, point2s } = record;
      const className = error ? "resistance-table-error-span" : ""
      return <Tooltip title={this.props.loading ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
        {Array.isArray(point2s) ? point2s.map((item, index) => <span key={index} className={`${className}`}>
          {point2s.length > 1 && item.comp ? "(" : null}
          {samePoints.includes(item.comp) ? <span className='dcr-warning-color'>{item.comp}</span> : item.comp}
          {(item.pins && item.pins.length) ?
            <Fragment> - <span className="point-pin-span">{item.pins.map((pin, index) => <span key={pin}><span className={samePoints.includes(`${item.comp}::${pin}`) ? 'dcr-warning-color' : ''}>{pin}</span>{index === item.pins.length - 1 ? '' : ', '}</span>)}</span></Fragment> : ""
          }
          {point2s.length > 1 && item.comp ? ") " : null}
        </span>) : null}
      </Tooltip>
    }

    ResistanceColumns[3].render = (nets) => {
      let newNets = [...new Set(nets)];
      return <span>{newNets.join(', ')}</span>
    }

    ResistanceColumns[4].render = (data, record) => {
      const { value } = valueUnitSplit(record.spec || "");
      const spec = record.spec && value ? `${record.spec}Ω` : "";
      return <span className="cascade-sign-off-impedance-value-span">{spec}</span>
    }

    ResistanceColumns[4].onCell = (record) => {
      const { value, unit } = valueUnitSplit(record.spec || "");
      return {
        record: { ...record, value, unit },
        edit: 'input-with-select',
        dataIndex: 'value',
        allowEmpty: true,
        defaultUnit: "m",
        handleSave: this.resistanceSpecUpdate,
        unitAddonAfter: 'Ω',
        unitList: [{ key: "m", title: 'mΩ' }, { key: "", title: 'Ω' }, { key: "K", title: 'KΩ' }, { key: "M", title: 'MΩ' }]
      }
    }

    ResistanceColumns[5].render = (data, record) => {
      const { samePoints = [] } = record;
      if (samePoints.length) {
        return <span>0Ω  <Tooltip overlayClassName='aurora-tooltip' title='Point 1 and Point2 have same pins.'><QuestionCircleOutlined className='dcr-warning-color' /></Tooltip></span>;
      }
      let _resistance = record.resistance;
      let className = "";
      if (record.resistance && record.resistance !== "Inf" && record.resistance !== "inf") {
        const { value, unit } = valueUnitSplit(record.spec || "");
        const specValue = value && !isNaN(value) ? unitChange({
          num: value,
          oldUnit: `${unit}ohm`,
          newUnit: "ohm",
          decimals: -1
        }).number : null;
        className = parseFloat(record.resistance) > parseFloat(specValue) ? "cascade-target-actual-error-value" : ""
      }

      if (record.resistance && record.resistance !== "Inf" && record.resistance !== "inf") {
        _resistance = numberToScientific(_resistance) + 'Ω';
      }
      return <div className={`dcr-resistance ${className}`}>
        <span>{_resistance}</span>
      </div>
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    /*     const { target } = e;
        const tagBox = document.getElementsByClassName("cascade-resistance-tables")[0];
        const panelBox = document.getElementsByClassName("dcr-point-select-table-panel")[0];
        if (panelBox && panelBox.contains(e.target)) {
          return;
        }
        if (
          (tagBox &&
            !tagBox.contains(target) &&
            target.classList[0] !== "merge-path-popover-span" &&
            target.classList[0] !== "merge-path-popover") ||
          target.classList[0] === "editable-cell-value-wrap"
        ) {
          this.setState({
            selectedPathList: [],
            action: null
          });
          return;
        } */
  };

  resistanceSpecUpdate = (record) => {
    const { id, value, unit } = record;
    if (value) {
      const error = numberCheck(value);
      if (error) {
        return;
      }
    }

    const { resistanceData } = this.props;
    const _resistanceData = resistanceData.map(item => {
      if (item.id === id) {
        item.spec = value ? `${value}${unit}` : "";
      }
      return item;
    });
    this.props._updateDCRData(_resistanceData)

  }

  componentDidMount = () => {
    this.setOnCell();
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentDidUpdate = (prevProps) => {
    const { noPCB, designStatus, verificationId } = this.props;
    if (prevProps.noPCB !== noPCB || designStatus !== prevProps.designStatus) {
      this.setOnCell();
    }
    if (verificationId !== prevProps.verificationId) {
      this.setState({
        selectedPathList: [],
        visible: false,
        action: null
      })
    }
  }

  setOnCell = () => {
    const { noPCB, designStatus } = this.props;
    ResistanceColumns[1].onCell = (record) => {
      const { loading, Components, designId, selectedDesignIDs, viewList, nets } = this.props;
      const anotherPointNets = (record.point2s || []).map(item => item.net).filter(item => !!item).flat(2);
      return noPCB || designStatus ? {
        edit: false
      } : {
        record,
        data: record.point1s,
        selectNets: record.net,
        anotherPointNets: anotherPointNets || [],
        edit: !loading,
        customInput: PointSelectPanel,
        error: record.error,
        dataIndex: 'point1',
        title: "Point 1",
        Components: Components,
        nets,
        savaPointModel: (data, nets, type) => this.savaPointModel(data, nets, type, record),
        displayType: 'model',
        _select: (obj) => this.props._selectChange(obj, designId),
        viewList: viewList,
        loading: loading,
        show: selectedDesignIDs.includes(designId)
      }
    }

    ResistanceColumns[2].onCell = (record) => {
      const { loading, Components, designId, selectedDesignIDs, viewList, nets } = this.props;
      const anotherPointNets = (record.point1s || []).map(item => item.net).flat(2);
      return noPCB || designStatus ? {
        edit: false
      } : {
        record,
        data: record.point2s,
        selectNets: record.net,
        anotherPointNets: anotherPointNets || [],
        edit: !loading,
        customInput: PointSelectPanel,
        error: record.error,
        dataIndex: 'point2',
        title: "Point 2",
        Components: Components,
        nets,
        savaPointModel: (data, nets, type) => this.savaPointModel(data, nets, type, record),
        _select: (obj) => this.props._selectChange(obj, designId),
        viewList: viewList,
        loading: loading,
        show: selectedDesignIDs.includes(designId)
      }
    }
    this.setState({
      columnsUpdate: noPCB || designStatus
    })
  }

  deleteDcrExplorer = (e, index) => {
    e.stopPropagation();
    const { resistanceData } = this.props;
    let _resistanceData = [...resistanceData];
    const deleteList = _resistanceData.splice(index, 1);
    const deleteIdList = deleteList.map(item => item.id);

    this.props._deletePathRData(deleteIdList);
    // const _data = _resistanceData.map(item => ({ point1: item.point1, point2: item.point2, id: item.id ? item.id : "" }));
    // this.props._updateDCRData(_data)
  }

  savaPointModel = (data, nets, type, record) => {
    //type -> point1 / point2
    const { resistanceData } = this.props;
    const _resistanceData = resistanceData.map(item => {
      if (item.id === record.id) {
        item[`${type}s`] = data;
        item.net = [];
        item.error = false;
        if (Array.isArray(item.point1s)) {
          item.net.push(...item.point1s.map(it => it.net).filter(it => !!it));
          if (item.point1s.length && item.point1s.some(point => point.comp && (!point.pins || !point.pins.length))) {
            item.error = true;
          }
        }
        if (Array.isArray(item.point2s)) {
          item.net.push(...item.point2s.map(it => it.net).filter(it => !!it));
          if (item.point2s.length && item.point2s.some(point => point.comp && (!point.pins || !point.pins.length))) {
            item.error = true;
          }
        }
        item.net = [...new Set([...item.net, ...nets])];
      }
      return item;
    });
    this.props._updateDCRData(_resistanceData)
  }

  addClick = (e) => {
    const { resistanceData } = this.props;
    const _resistanceData = [...resistanceData]
    const data = new ResistanceData()
    _resistanceData.push(data)
    const _data = _resistanceData.map(item => ({
      point1s: item.point1s,
      point2s: item.point2s,
      id: item.id ? item.id : "",
      net: item.net || [],
      spec: item.spec || ""
    }));
    this.props._updateDCRData(_data)
  }

  selectChange = (selectedRowKeys, selectedRows) => {
    this.props._resistanceSelectedList(selectedRows)
  }

  clickTableRow = (e, record) => {
    e && e.stopPropagation();
    const { id } = record;
    const { selectedPathList, visible } = this.state;

    let pathList = [...selectedPathList];
    let _visible = visible;
    if (!selectedPathList.includes(id)) {
      pathList.push(id);
    } else {
      pathList = pathList.filter((item) => item !== id);
    }
    if (pathList.length > 1) {
      _visible = true;
    } else {
      _visible = false;
    }
    this.setState({
      selectedPathList: pathList,
      visible: _visible,
      action: "form"
    });
  }

  clickFormLoop = (e) => {
    e && e.stopPropagation();
    const { selectedPathList } = this.state;
    if (selectedPathList.length === 0) {
      return;
    }
    const { resistanceData, pairs = [] } = this.props;
    let _resistanceData = [...resistanceData];
    let _pairs = [...pairs];
    const findSameLoop = _pairs.find(it => it.ids && _.isEqual(it.ids, selectedPathList));
    if (findSameLoop) {
      this.setState({
        action: "exist"
      });
      return;
    }
    _pairs.push({ ids: [...selectedPathList], spec: "" });

    this.setState({
      selectedPathList: [],
      action: null
    });
    this.props._updateDCRData(_resistanceData, _pairs)
  };

  render() {
    const { resistanceData, resistanceTableLoading, loading, designId } = this.props;
    const { selectedPathList } = this.state;
    return (
      <Fragment>
        <div className="cascade-setup-border space-16">
          <span className="font-bold cascade-setup-title-color">Path Resistance</span>
          {loading || !designId ? <Tooltip title={!designId ? 'Please Select PCB First.' : loading ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
            <PlusCircleOutlined className='resistance-add-icon resistance-add-icon-disable' />
          </Tooltip> :
            <PlusCircleOutlined className='resistance-add-icon' onClick={(e) => this.addClick(e)} />}
          <div className="space-10">
            <Spin spinning={resistanceTableLoading}>
              <EditableTable
                rowKey="index"
                columns={ResistanceColumns}
                size="small"
                dataSource={resistanceData}
                className="cascade-resistance-tables"
                onRow={(record) => {
                  let rowClassName = record.rowClassName ? record.rowClassName : "";
                  if (
                    selectedPathList && selectedPathList.length > 0 &&
                    selectedPathList.includes(record.id)
                  ) {
                    rowClassName = rowClassName + " aurora-column-select-row";
                  }
                  return (
                    {
                      className: rowClassName
                    }
                  );
                }}
              // rowSelection={{
              //   onChange: this.selectChange,
              //   getCheckboxProps: record => ({
              //     name: record.name,
              //   }),
              // }}
              />
            </Spin>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { viewList, openProjectId, selectedKeys }, DCR: { resistanceData, pairs, resistanceTableLoading, designId, verificationId } } } = state
  return {
    viewList,
    projectId: openProjectId,
    resistanceData,
    resistanceTableLoading,
    designId,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    pairs,
    verificationId
  };
}

const mapDispatch = (dispatch) => ({
  _updateDCRData(resistanceData, pairs) {
    dispatch(updateDCRData({ resistanceData, pairs }))
  },
  _deletePathRData(idList) {
    dispatch(deletePathRData(idList))
  },
  _selectChange(canvasObj = {}, designID) {
    dispatch(selectChange(canvasObj, designID))
  },
  _resistanceSelectedList(selectList) {
    dispatch(resistanceSelectedList(selectList))
  }
})

export default connect(mapState, mapDispatch)(Resistance);