const SEV_VERIFICATION = "2"
class PackageVerificationConstructor {
  constructor() {
    this.packageList = new Map();
    this.packagePDNs = new Map();
    this.verifications = new Map();
  }

  addPackageVerifications = (packageDesignId, packageVerificationList, packagePDN) => {
    this.packageList.set(packageDesignId, packageVerificationList)
    for (let item of packageVerificationList) {
      this.verifications.set(item.id, item)
    }
    packagePDN && this.verifications.set(packagePDN.id, packagePDN)
  }

  getPackageVerification = (verificationId) => {
    return this.verifications.get(verificationId)
  }

  getChannelVerifications = (packageDesignId, channelName) => {
    const packages = this.packageList.get(packageDesignId) || [];
    const channelInfo = packages.find(item => item.groupName === channelName);
    return channelInfo && channelInfo.channels ? channelInfo.channels : [];
  }

  getChannelVerification = (packageDesignId) => {
    const packages = this.packageList.get(packageDesignId) || [];
    let _packageList = [];
    packages.forEach(channelInfo => {
      const { channels, groupName, category } = channelInfo;
      if (category && category == SEV_VERIFICATION) {
        _packageList.push({ ...channelInfo })
      } else if (channels && channels.length) {
        channels.forEach(d => {
          _packageList.push({ ...d, channelName: groupName })
        })
      }
    })
    return _packageList;
  }

  getPackageVerifications = (packageDesignId, packageId) => {
    const verificationInfo = this.getChannelVerification(packageDesignId).find(item => item.id === packageId)
    return verificationInfo ? verificationInfo : {}
  }

  getPackageVerificationsByVId = (packageDesignId, packageVerificationId) => {
    const verificationInfo = this.getChannelVerification(packageDesignId).find(item => item.verificationId === packageVerificationId)
    return verificationInfo ? verificationInfo : {}
  }

  getChannelFormVerificationId = (packageDesignId, packageVerificationId) => {
    const packages = this.packageList.get(packageDesignId) || [];
    for (let packageInfo of packages) {
      const { channels } = packageInfo;
      const findVerification = channels.find(item => item.id === packageVerificationId)
      if (findVerification) {
        return channels
      }
    }
    return []
  }

  getPackageInfo = (packageDesignId) => {
    return this.packageList.get(packageDesignId) || [];
  }

  addVerificationsToPackageInfo = (packageDesignId, currentInfo = {}) => {
    let packageVerificationList = this.packageList.get(packageDesignId) || [];
    const findIndex = packageVerificationList.findIndex(item => item.groupName === currentInfo.groupName)
    if (findIndex > -1) {
      packageVerificationList[findIndex].channels.push(currentInfo)
    } else {
      packageVerificationList.push({ channels: [currentInfo], groupName: currentInfo.groupName })
    }
    this.packageList.set(packageDesignId, [...packageVerificationList])
  }

  addSEVVerificationsToPackageInfo = (packageDesignId, currentInfo = {}) => {
    let packageVerificationList = this.packageList.get(packageDesignId) || [];
    packageVerificationList.push({ ...currentInfo })
    this.packageList.set(packageDesignId, [...packageVerificationList])
  }

  clearPackageList = () => {
    this.packageList = new Map();
  }

  addPackagePDN = (packageId, pdn) => {
    this.packagePDNs.set(packageId, pdn);
  }

  getPackagePDN = (packageId) => {
    return this.packagePDNs.get(packageId);
  }
}

let packageVerificationConstructor = new PackageVerificationConstructor();
export default packageVerificationConstructor;