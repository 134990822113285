const LIBRARY_TYPE = 'Andes_v2';

export const UPDATE_LIBRARY_MENU = `${LIBRARY_TYPE}/update_library_menu`;
export const UPDATE_LIBRARY_MENU_LIST = `${LIBRARY_TYPE}/update_library_menu_list`;
export const UPDATE_LIBRARY_DATA = `${LIBRARY_TYPE}/update_library_data`;
export const DELETE_LIBRARY = `${LIBRARY_TYPE}/delete_library`;
export const SAVE_LIBRARY_CONFIG_SERVER = `${LIBRARY_TYPE}/save_library_config_server`;
export const ADD_LIBRARY_TO_CACHE = `${LIBRARY_TYPE}/add_library_to_cache`;
export const UPDATE_LIBRARY_STATUS = `${LIBRARY_TYPE}/update_library_status`;
export const AFTER_IMPORT_LIBRARY = `${LIBRARY_TYPE}/after_import_library`;
export const OPEN_LIBRARY_FOLDER = `${LIBRARY_TYPE}/open_library_folder`;