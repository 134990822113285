import { getDefaultIndex, getDefaultName } from "../../../helper/setDefaultName";
import { RES } from "../../../PCBHelper";
import { GENERIC } from "../../../PCBHelper/constants";
import { SchematicCanvas } from "../../../SchematicCanvas";
import preLayoutLibraryData from "../../library/PreLayoutData";
import { getDefaultSignalNames, getNewComponentName, getPointName, getSignalColor } from "./setupHelper";
import _ from "lodash";

class SchematicPlot {
  constructor(props) {
    const { elementsSvg, schematicSvg, width, height, top, elementsTypes, action = {}, GPIO, signalNumber, isSweep, isPuppeteer = false } = props;
    this.elementsSvg = elementsSvg;
    this.schematicSvg = schematicSvg;
    this.width = width;
    this.height = height;
    this.isPuppeteer = isPuppeteer;
    this.GPIO = GPIO || GENERIC;
    this.signalNumber = signalNumber || 1;
    this.elementsTypes = elementsTypes || [];
    this.updatePreLayoutInfo = action.updatePreLayoutInfo;
    this.compUpdate = action.compUpdate;
    /*   this.signalsUpdate = action.signalsUpdate; */
    this.signalPointsUpdate = action.signalPointsUpdate;
    this.openCompBox = action.openCompBox;
    this.selectSignalSection = action.selectSignalSection;
    this.showTemplateInfo = action.showTemplateInfo;
    this.closeTemplateInfo = action.closeTemplateInfo;
    this.getParam = action.getParam;
    this.action = action || {};
    this.top = top;
    this.isSweep = isSweep;
    this.initData();
  }

  initData = () => {
    this.canvas = new SchematicCanvas({
      elementsSvg: this.elementsSvg,
      schematicSvg: this.schematicSvg,
      width: this.width,
      height: this.height,
      top: this.top,
      rects: this.getRects(),
      lines: this.getLines(),
      elementsTypes: this.elementsTypes,
      container: this.getParam("canvasContainer", "state"),
      isSweep: this.isSweep,
      isPuppeteer: this.isPuppeteer,
      action: {
        rectUpdate: this.rectUpdate,
        rectDelete: this.rectDelete,
        openRectBox: this.openRectBox,
        createElement: this.createNewComponent,
        saveElement: this.saveComponent,
        createLine: this.createSignal,
        deleteLine: this.deleteSignal,
        selectLine: this.selectSignalSection,
        addRectToLine: this.addCompToSignal,
        deleteLineSection: this.deleteSignalSection,
        showTemplateInfo: this.showTemplateInfo,
        closeTemplateInfo: this.closeTemplateInfo,
        templateData: preLayoutLibraryData
      }
    });
    !this.isSweep && this.canvas.drawElements();
    this.canvas.draw();
  }

  createNewComponent = ({ key, x, y }) => {
    const comps = this.getParam("components") || [];
    let comp = {
      name: getNewComponentName(key, comps),
      type: key,
      pins: [],
      location: [x, y],
      width: key === RES ? 2 : 3,
      height: 2,
      layer: "Top"
    }
    if (key === RES) {
      comp.voltage = "1.8";
      comp.value = "10K"
    }
    return this.getRectByComp(comp);
  }

  saveComponent = (rectItem) => {
    const _comp = this.getCompByRect(rectItem);
    this.action.saveNewComponent(_comp);
  }

  getRects = () => {
    const comps = this.getParam("components") || [];
    return comps.map(comp => this.getRectByComp(comp));
  }

  getLines = () => {
    const signals = this.getParam("signals") || [];
    return signals.map(signal => this.getLineBySignal(signal));
  }

  getPoints = () => {
    const points = this.getParam("points") || [];
    return JSON.parse(JSON.stringify(points));
  }

  getLineBySignal = (signal) => {
    return {
      name: signal.name,
      color: signal.color,
      nets: [...(signal.nets || [])],
      sections: [...(signal.sections || []).map(secItem => {
        return {
          id: secItem.id,
          in: {
            ...secItem.in,
          },
          out: {
            ...secItem.out
          },
          model: { ...(secItem.template || {}) },
          length: secItem.traceLength
        }
      })],
      points: JSON.parse(JSON.stringify(signal.points || []))
    }
  }

  clear = () => {
    this.canvas.clear();
  }

  draw = () => {
    this.canvas.draw()
  }

  initContainer = ({ width, height, container }) => {
    this.canvas.initContainer({ width, height, container })
  }

  rectUpdate = (rectItem) => {
    const comp = this.getCompByRect(rectItem);
    this.compUpdate(comp, { isUpdate: true });
  }

  rectDelete = (rectItem) => {

    if (!rectItem.points.length) {
      this.compUpdate(rectItem, { isDelete: true });
      return;
    }
    const signals = this.getParam("signals") || [];
    const findSignal = signals.find(item => item.points && item.points.length);
    if (!findSignal) {
      this.compUpdate(rectItem, { isDelete: true });
      return;
    }
    const findSection = findSignal.sections.find(item => (item.in && item.in.type === "component" && item.in.component === rectItem.name)
      || (item.out && item.out.type === "component" && item.out.component === rectItem.name));
    if (!findSection) {
      this.compUpdate(rectItem, { isDelete: true });
      return;
    }
    return this.deleteSignalSection(findSignal.name, findSection.id, rectItem.name);
  }

  openRectBox = (rectItem, comps) => {
    const comp = this.getCompByRect(rectItem);
    this.openCompBox(comp, comps);
  }

  getCompByRect = (rectItem) => {
    const comp = {
      name: rectItem.name,
      location: [...(rectItem.location || [])],
      type: rectItem.type,
      width: rectItem.width,
      height: rectItem.height,
      pins: [...(rectItem.points || []).map(pin => {
        return {
          pin: pin.point,
          location: pin.location,
          net: pin.line,
          signal: pin.group
        }
      })],
      layer: rectItem.layer
    }
    if (comp && comp.type === RES) {
      comp.voltage = rectItem.voltage;
      comp.value = rectItem.value;
    }

    return comp;
  }

  getRectByComp = (comp) => {
    return {
      name: comp.name,
      location: comp.location,
      type: comp.type,
      width: comp.width,
      height: comp.height,
      points: [...(comp.pins || []).map(pin => {
        return {
          point: pin.pin,
          location: pin.location,
          line: pin.net,
          group: pin.signal
        }
      })],
      layer: comp.layer,
      value: comp.value,
      voltage: comp.voltage
    }
  }

  updateCanvas = ({ comps, update, signals, isUpdateSignals = false }) => {
    let rects = []
    for (let comp of comps || []) {
      rects.push({ prevName: comp.prevName, ...this.getRectByComp(comp) });
    }
    if (rects.length && update.length) {
      this.canvas.updateRects(rects, update);
    }
    if (isUpdateSignals && signals) {
      this.canvas.updateLines(signals.map(item => this.getLineBySignal(item)));
    }
  }

  updateCanvasContainer = (container) => {
    if (!container || !container.width || !container.height) return;
    this.canvas.updateCanvasContainer(container);
  }

  createSignal = ({ currentLine }) => {

    const signalNames = getDefaultSignalNames(this.GPIO, this.signalNumber);
    let components = this.getParam("components") || [];
    let delPinsComps = []
    components = components.map(item => {
      if (item.pins.length) {
        delPinsComps.push(item.name)
        item.pins = [];
      }; return item;
    })

    const startComp = currentLine.start.name,
      endComp = currentLine.end.name,
      resComps = components.filter(item => item.type === RES).map(item => item.name);
    const startCompInfo = components.find(item => item.name === startComp) || {},
      endCompInfo = components.find(item => item.name === startComp) || {};

    const isStartRes = resComps.includes(startComp),
      isEndRes = resComps.includes(endComp);
    const startValue = startCompInfo.value, endValue = endCompInfo.value,
      startVoltage = startCompInfo.voltage, endVoltage = endCompInfo.voltage;


    let first = true, signals = [], compPins = {};
    for (let signalName of signalNames) {
      let signal = {
        name: signalName,
        nets: [signalName],
        sections: [],
        points: [],
        color: getSignalColor(signals.map(signal => signal.color))
      }
      let sectionId = 1,
        currentSection = {},
        sections = [],
        currSignalPoints = [];
      for (let i = 0; i < currentLine.points.length; i++) {
        const point = currentLine.points[i];
        //first component point
        if (i === 0) {
          let comp = startComp;
          if (isStartRes && !first) {
            comp = getDefaultName({ nameList: resComps, defaultKey: 'R', key: "", firstIndex: 1 });
            components.push({
              name: comp,
              location: [],
              width: 2,
              height: 2,
              pins: [],
              type: RES,
              layer: "Top",
              value: startValue,
              voltage: startVoltage
            });
            resComps.push(comp);
          }
          currentSection.in = {
            component: comp,
            pin: signalName,
            type: "component"
          };
          const pin = { pin: signalName, net: signalName, signal: signalName, location: first ? [parseInt(point.x), parseInt(point.y)] : [] };
          if (!compPins[comp]) {
            compPins[comp] = [pin]
          } else {
            compPins[comp].push(pin)
          }
          currSignalPoints.push({
            name: comp,
            pin: signalName,
            type: "component"
          })
          continue;
        }
        const isComp = i === currentLine.points.length - 1;
        //last component point
        if (currentSection.in && !currentSection.out) {
          if (isComp) {
            let _endComp = endComp;
            if (isEndRes && !first) {
              _endComp = getDefaultName({ nameList: resComps, defaultKey: 'R', key: "", firstIndex: 1 });
              components.push({
                name: _endComp,
                location: [],
                width: 3,
                height: 2,
                pins: [],
                type: RES,
                layer: "Top",
                value: endValue,
                voltage: endVoltage
              });
              resComps.push(_endComp);
            }
            currentSection.out = {
              component: _endComp,
              pin: signalName,
              type: "component"
            }
            const pin = { pin: signalName, net: signalName, signal: signalName, location: first ? [parseInt(point.x), parseInt(point.y)] : [] };
            if (!compPins[_endComp]) {
              compPins[_endComp] = [pin]
            } else {
              compPins[_endComp].push(pin)
            }
            currSignalPoints.push({
              name: _endComp,
              pin: signalName,
              type: "component"
            })
            currentSection.id = sectionId.toString();
            currentSection.template = {};
            currentSection.traceLength = "";
            sections.push({
              ...JSON.parse(JSON.stringify(currentSection))
            })
            currentSection = {
              in: {
                ...currentSection.out
              }
            };
            sectionId += 1;
          } else {
            const signalPointName = getDefaultName({ nameList: currSignalPoints, defaultKey: signalName, key: "name", delimiter: "_" });
            currSignalPoints.push({
              name: signalPointName,
              location: [point.x, point.y],
              type: "point"
            })
          }
        }
      }
      signal.sections = sections;
      if (first) {
        const currLinePoints = [{ name: sections[0].id, points: currSignalPoints }];
        signal.points = currLinePoints;
        first = false;
      }
      signals.push(signal);
    }

    let newRects = [];

    for (let comp of components) {

      if (delPinsComps.includes(comp.name)) {
        comp.pins = [];
      }

      if (compPins[comp.name]) {
        comp.pins = [...comp.pins, ...compPins[comp.name]];
        newRects.push(this.getRectByComp(comp))
      } else if (delPinsComps.includes(comp.name)) {
        newRects.push(this.getRectByComp(comp))
      }
    }

    this.updatePreLayoutInfo({ signals, components, keys: ["signals", "components"] });

    return { lines: signals.map(item => this.getLineBySignal(item)), newRects };
  }

  addCompToSignal = ({ currentLine }) => {
    let signals = this.getParam("signals") || [];
    const components = this.getParam("components") || [];
    const index = signals.findIndex(signal => signal.name === currentLine.end.name);
    if (index < 0) {
      return {};
    }

    const secIndex = signals[index].sections.findIndex(item => item.id === currentLine.end.section.id);
    if (secIndex < 0) {
      return {};
    }

    const startComp = currentLine.start.name,
      resComps = components.filter(item => item.type === RES).map(item => item.name);
    const isRes = resComps.includes(startComp);

    const startCompInfo = components.find(item => item.name === startComp) || {};

    const value = startCompInfo.value, voltage = startCompInfo.voltage;

    const prevOut = JSON.parse(JSON.stringify(signals[index].sections[secIndex].out));

    let pointExistNames = [], currSignalResInfo = [];
    //get new points name
    for (let it of signals[index].sections) {
      if (it.in && it.in.type === "point") {
        pointExistNames.push(it.in.component)
      }
      if (it.out && it.out.type === "point") {
        pointExistNames.push(it.out.component)
      }

      if (it.in && it.in.type === "component" && resComps.includes(it.in.component)) {
        currSignalResInfo.push({ sectionId: it.id, name: it.in.component, type: "in" })
      }
      if (it.out && it.out.type === "component" && resComps.includes(it.out.component)) {
        currSignalResInfo.push({ sectionId: it.id, name: it.out.component, type: "out" })
      }
    }

    let pointName = "";

    if (!pointExistNames.length) {
      /*   for (let signal of signals) {
          let name = ""
          for (let it of signal.sections) {
            if (it.in && it.in.type === "point") {
              name = it.in.component;
              signal.name === currentLine.end.name && pointExistNames.push(it.in.component)
              break;
            } else if (it.out && it.out.type === "point") {
              name = it.out.component;
              signal.name === currentLine.end.name && pointExistNames.push(it.out.component)
              break;
            }
          }
          name = name.replace(/[0-9]+/ig, "");
          name && pointExistKeys.push(name)
        } */
      /*  pointName = getPointName(pointExistKeys); */
      pointName = `A1`;
    } else {
      pointName = pointExistNames[0].replace(/[0-9]+/ig, "");
      pointName = getDefaultName({ nameList: pointExistNames, defaultKey: pointName, key: "", firstIndex: 1 })
    }

    const otherSignals = signals.filter(item => item.name !== signals[index].name).map(item => item.name);

    const poIndex = signals[index].points.findIndex(item => item.name === currentLine.end.section.id);
    const secPoints = JSON.parse(JSON.stringify(signals[index].points[poIndex].points));
    const poStartIndex = secPoints.findIndex(item => item.name === currentLine.end.sectionPoints.start.name);
    const poEndIndex = secPoints.findIndex(item => item.name === currentLine.end.sectionPoints.end.name);
    if (poStartIndex < 0 || !poEndIndex < 0) {
      return {};
    }

    let isFilterStartPoint = false, isStartPoint = false, isFilterEndPoint = false, isEndPoint = false;
    if (_.isEqual(secPoints[poStartIndex].location, [currentLine.end.x, currentLine.end.y])
/*       _.isEqual(secPoints[poEndIndex].location, [currentLine.end.x, currentLine.end.y]) */) {
      isFilterStartPoint = true;
      if (pointExistNames.includes(secPoints[poStartIndex].name)) {
        isStartPoint = true;
        pointName = secPoints[poStartIndex].name;
      }
    }

    if (_.isEqual(secPoints[poEndIndex].location, [currentLine.end.x, currentLine.end.y])) {
      isFilterEndPoint = true;
      if (pointExistNames.includes(secPoints[poEndIndex].name)) {
        isEndPoint = true;
        pointName = secPoints[poEndIndex].name;
      }
    }

    const sectionIds = signals[index].sections.map(item => item.id);
    let newSecId1 = null;
    //split prev section to two section (current section and newSec1)
    if (!isStartPoint && !isEndPoint) {
      signals[index].sections[secIndex].out = {
        component: pointName,
        pin: pointName,
        type: "point"
      };

      newSecId1 = getDefaultIndex(signals[index].sections.length, sectionIds);
      signals[index].sections.splice(secIndex + 1, 0, {
        id: newSecId1,
        in: {
          component: pointName,
          pin: pointName,
          type: "point"
        },
        out: prevOut,
        template: {},
        traceLength: ""
      });
      sectionIds.push(newSecId1);
    }

    //add new section 
    const newSecId2 = getDefaultIndex(signals[index].sections.length, sectionIds);
    signals[index].sections.splice(secIndex + 2, 0, {
      id: newSecId2,
      in: {
        component: pointName,
        pin: pointName,
        type: "point"
      },
      out: {
        component: startComp,
        pin: signals[index].name,
        type: "component"
      },
      template: {},
      length: ""
    });

    //update components
    const comp = startComp;
    const pin = { pin: signals[index].name, net: signals[index].name, signal: signals[index].name, location: [currentLine.start.x, currentLine.start.y] };
    const compIndex = components.findIndex(item => item.name === comp);
    components[compIndex].pins.push(pin);

    let signalResComps = {}, newComps = [startComp];
    for (let signalName of otherSignals) {
      let comp = startComp;

      if (isRes) {
        comp = getDefaultName({ nameList: resComps, defaultKey: 'R', key: "", firstIndex: 1 });
        components.push({
          name: comp,
          location: [],
          width: 3,
          height: 2,
          pins: [{ pin: signalName, net: signalName, signal: signalName, location: [] }],
          type: RES,
          layer: "Top",
          value: value,
          voltage: voltage
        });
        resComps.push(comp);
        signalResComps[signalName] = comp;
      } else {
        const pin = { pin: signalName, net: signalName, signal: signalName, location: [] };
        const compIndex = components.findIndex(item => item.name === comp);
        components[compIndex].pins.push(pin)
      }
      newComps.push(comp)
    }
    //split prev section to two section points
    if (!isStartPoint && !isEndPoint) {
      signals[index].points.splice(poIndex, 1);
      signals[index].points.push({
        name: signals[index].sections[secIndex].id,
        points: [...secPoints.filter((item, index) => index < poStartIndex || (index === poStartIndex && !isFilterStartPoint)), {
          name: pointName,
          location: [currentLine.end.x, currentLine.end.y],
          type: "point"
        }]
      }, {
        name: newSecId1,
        points: [{
          name: pointName,
          location: [currentLine.end.x, currentLine.end.y],
          type: "point"
        }, ...secPoints.filter((item, index) => index > poEndIndex || (index === poEndIndex && !isFilterEndPoint)),]
      })
    }


    //add new section points
    let newPoints = [];
    for (let point of currentLine.points) {
      if (point.type) {
        newPoints.push({
          name: comp,
          pin: signals[index].name,
          type: "component"
        })
      } else if (point.endType) {
        newPoints.push({
          name: pointName,
          location: [point.x, point.y],
          type: "point"
        })
      } else {
        const signalPointName = getDefaultName({ nameList: newPoints, defaultKey: signals[index].name, key: "name", delimiter: "_" });
        newPoints.push({
          name: signalPointName,
          location: [point.x, point.y],
          type: "point"
        })
      }
    }
    signals[index].points.push({ name: newSecId2, points: newPoints });

    const { signals: newSignals } = this.updateSignalSectionsByCurrSignal({ updateSignal: signals[index], pointName, signals, isRes, startComp, signalResComps, currSignalResInfo, resComps });
    //update other signals
    this.updatePreLayoutInfo({ signals: newSignals, components, keys: ["signals", "components"] });
    return { lines: newSignals.map(item => this.getLineBySignal(item)), newRects: components.filter(item => newComps.includes(item.name)).map(item => this.getRectByComp(item)) }
  }

  deleteSignalSection = (signalName, sectionId, delComp) => {
    let signals = this.getParam("signals") || [];
    let components = this.getParam("components") || [];
    const index = signals.findIndex(signal => signal.name === signalName);
    if (index < 0) {
      return {};
    }

    const secIndex = signals[index].sections.findIndex(item => item.id === sectionId);
    if (secIndex < 0) {
      return {};
    }
    const resComps = components.filter(item => item.type === RES).map(item => item.name);

    let currSignalResInfo = [];
    //get new points name
    for (let it of signals[index].sections) {
      if (it.in && it.in.type === "component" && resComps.includes(it.in.component)) {
        currSignalResInfo.push({ sectionId: it.id, name: it.in.component, type: "in" })
      }
      if (it.out && it.out.type === "component" && resComps.includes(it.out.component)) {
        currSignalResInfo.push({ sectionId: it.id, name: it.out.component, type: "out" })
      }
    }
    let updateRects = [], isRes = false;
    const currSection = signals[index].sections[secIndex];
    if ((currSection.in && currSection.in.type === "component")) {
      isRes = this.removePinsInComp({ components, component: currSection.in.component, signalName, updateRects });
    }
    if ((currSection.out && currSection.out.type === "component")) {
      isRes = this.removePinsInComp({ components, component: currSection.out.component, signalName, updateRects });
    }

    let removePointName = "";

    if (currSection.in && currSection.in.type === "point") {
      removePointName = currSection.in.component;
    }
    if (currSection.out && currSection.out.type === "point") {
      removePointName = currSection.out.component;
    }

    signals[index].sections = signals[index].sections.filter(item => item.id !== sectionId);

    const findPointsExists = signals[index].sections.filter(item => (item.in && item.in.type === "point" && item.in.component === removePointName) || (item.out && item.out.type === "point" && item.out.component === removePointName));
    if (findPointsExists.length === 2) {

      let firstSection = findPointsExists[0], secondSection = findPointsExists[1];
      const firstPointIndex = signals[index].points.findIndex(item => item.name === firstSection.id);
      const secondPointIndex = signals[index].points.findIndex(item => item.name === secondSection.id);
      const fSectionIndex = signals[index].sections.findIndex(item => item.id === firstSection.id);
      const sSectionIndex = signals[index].sections.findIndex(item => item.id === secondSection.id);
      const fPointIndex = signals[index].points[firstPointIndex].points.findIndex(item => item.name === removePointName);
      const sPointIndex = signals[index].points[secondPointIndex].points.findIndex(item => item.name === removePointName);

      let fLineIndex = fPointIndex === 0 ? 1 : signals[index].points[firstPointIndex].points.length - 2,
        sLineIndex = sPointIndex === 0 ? 1 : signals[index].points[secondPointIndex].points.length - 2;

      let pointDir = "X", inPointDir = "X";
      const outLineLocation = signals[index].points[secondPointIndex].points[sLineIndex].type === "component" ? this.findCompPin(components, signals[index].points[secondPointIndex].points[sLineIndex].name, signals[index].points[secondPointIndex].points[sLineIndex].pin) : signals[index].points[secondPointIndex].points[sLineIndex].location;
      const inLineLocation = signals[index].points[firstPointIndex].points[fLineIndex].type === "component" ? this.findCompPin(components, signals[index].points[firstPointIndex].points[fLineIndex].name, signals[index].points[firstPointIndex].points[fLineIndex].pin) : signals[index].points[firstPointIndex].points[fLineIndex].location;
      if (outLineLocation && signals[index].points[secondPointIndex].points[sPointIndex].location[0] - outLineLocation[0] === 0) {
        pointDir = "Y";
      }
      if (inLineLocation && signals[index].points[firstPointIndex].points[fPointIndex].location[0] - inLineLocation[0] === 0) {
        inPointDir = "Y";
      }
      signals[index].points[secondPointIndex].points = signals[index].points[secondPointIndex].points.filter(item => item.name !== removePointName);

      if (inPointDir === pointDir) {
        signals[index].points[firstPointIndex].points = signals[index].points[firstPointIndex].points.filter(item => item.name !== removePointName);
      }
      if (fPointIndex === 0 && sPointIndex !== 0) {
        signals[index].points[secondPointIndex].points.push(...signals[index].points[firstPointIndex].points);
      } else if (fPointIndex === 0 && sPointIndex === 0) {
        signals[index].points[secondPointIndex].points.unshift(...signals[index].points[firstPointIndex].points.reverse());
      } else if (fPointIndex !== 0 && sPointIndex === 0) {
        signals[index].points[secondPointIndex].points.unshift(...signals[index].points[firstPointIndex].points);
      } else if (fPointIndex !== 0 && sPointIndex !== 0) {
        signals[index].points[secondPointIndex].points = signals[index].points[secondPointIndex].points.reverse();
        signals[index].points[secondPointIndex].points.unshift(...signals[index].points[firstPointIndex].points);
      }
      const copySection = firstSection.in.component === removePointName ? firstSection.out : firstSection.in;
      if (signals[index].sections[sSectionIndex].in.component === removePointName) {
        signals[index].sections[sSectionIndex].in = {
          ...copySection
        }
      } else if (signals[index].sections[sSectionIndex].out.component === removePointName) {
        signals[index].sections[sSectionIndex].out = {
          ...copySection
        }
      }
      signals[index].sections.splice(fSectionIndex, 1);
      signals[index].points.splice(firstPointIndex, 1);
    }
    signals[index].sections = signals[index].sections.filter(item => item.id !== currSection.id);
    signals[index].points = signals[index].points.filter(item => item.name !== currSection.id);
    /*  this.signalsUpdate(signals[index], { isUpdate: true }); */
    let { signals: newSignals, delComps } = this.updateSignalSectionsByCurrSignal({ updateSignal: signals[index], pointName: removePointName, signals, sectionId: currSection.id, isRes, currSignalResInfo, resComps, isDel: true, components, updateRects });
    if ((delComps && delComps.length > 0) || delComp) {
      components = components.filter(item => ![...(delComps || []), delComp].includes(item.name))
    }
    newSignals = newSignals.filter(item => item.sections && item.sections.length);
    this.updatePreLayoutInfo({ signals: newSignals, components, keys: ["signals", "components"] });
    return { lines: newSignals.map(item => this.getLineBySignal(item)), updateRects, deleteRects: delComp ? [...(delComps || []), delComp] : delComps }

  }

  updateSignalSectionsByCurrSignal = ({ updateSignal, pointName, signals, startComp, isRes, components, updateRects, signalResComps, sectionId, currSignalResInfo, resComps, isDel }) => {
    /*    this.compUpdate(components[compIndex], { isUpdate: true });
       this.signalsUpdate(signals[index], { isUpdate: true }); */
    let allPointExistKeys = [pointName.replace(/[0-9]+/ig, "")]
    let delComps = []
    for (let signal of signals) {
      if (signal.name === updateSignal.name) {
        continue;
      }
      const findSection = sectionId && isRes ? signal.sections.find(item => item.id === sectionId) : null;

      if (findSection && findSection.in && findSection.in.type === "component" && resComps.includes(findSection.in.component)) {
        delComps.push(findSection.in.component);
      }
      if (findSection && findSection.out && findSection.out.type === "component" && resComps.includes(findSection.out.component)) {
        delComps.push(findSection.out.component);
      }

      const _findSection = sectionId ? signal.sections.find(item => item.id === sectionId) : null;

      if (isDel && _findSection && _findSection.in && _findSection.in.type === "component" && !(resComps || []).includes(_findSection.in.component)) {
        this.removePinsInComp({ components, component: _findSection.in.component, signalName: signal.name, updateRects });
      }
      if (isDel && _findSection && _findSection.out && _findSection.out.type === "component" && !(resComps || []).includes(_findSection.out.component)) {
        this.removePinsInComp({ components, component: _findSection.out.component, signalName: signal.name, updateRects });
      }

      let itemResMap = {};
      for (let item of signal.sections) {
        if (item.in && item.in.type === "component" && resComps.includes(item.in.component)) {
          const findR = currSignalResInfo.find(it => it.sectionId === item.id && it.type === "in");
          itemResMap[findR.name] = item.in.component;
        }

        if (item.out && item.out.type === "component" && resComps.includes(item.out.component)) {
          const findR = currSignalResInfo.find(it => it.sectionId === item.id && it.type === "out");
          itemResMap[findR.name] = item.out.component;
        }
      }

      signal.sections = JSON.parse(JSON.stringify(updateSignal.sections || []));

      let currPointName = getPointName(allPointExistKeys);
      allPointExistKeys.push(currPointName);
      for (let secItem of signal.sections) {
        if (secItem.in && secItem.in.type === "component") {
          secItem.in.pin = signal.name;
          if (isRes && startComp === secItem.in.component && signalResComps) {
            secItem.in.component = signalResComps[signal.name];
          } else if (!!itemResMap[secItem.in.component]) {
            secItem.in.component = itemResMap[secItem.in.component];
          }
        }
        if (secItem.out && secItem.out.type === "component") {
          secItem.out.pin = signal.name;
          if (isRes && startComp === secItem.out.component && signalResComps) {
            secItem.out.component = signalResComps[signal.name];
          } else if (!!itemResMap[secItem.out.component]) {
            secItem.out.component = itemResMap[secItem.out.component];
          }
        }
        if (secItem.in && secItem.in.type === "point") {
          const prevName = secItem.in.component.match(/[0-9]+/g);
          const num = prevName ? prevName[0] : "1"
          secItem.in.component = `${currPointName}${num}`;
          secItem.in.pin = `${currPointName}${num}`;
        }
        if (secItem.out && secItem.out.type === "point") {
          const prevName = secItem.out.component.match(/[0-9]+/g);
          const num = prevName ? prevName[0] : "1"
          secItem.out.component = `${currPointName}${num}`;
          secItem.out.pin = `${currPointName}${num}`;
        }
      }
    }
    return { signals, delComps };
  }

  findCompPin = (components, component, pin) => {
    let location = [];
    const findComp = components.find(it => it.name === component);
    if (findComp && findComp.pins) {
      const point = findComp.pins.find(item => item.pin === pin);
      location = point ? point.location : [];
    }
    return location;
  }

  deleteSignal = (signalName) => {
    let signals = this.getParam("signals") || [];
    let components = this.getParam("components") || [];
    let updateRects = []

    /*     for (let comp of components) {
          if (comp.pins.find(pin => pin.signal === signalName)) {
            comp.pins = comp.pins.filter(item => item.signal !== signalName);
            updateRects.push(this.getRectByComp(comp));
          }
        }
        signals = signals.filter(item => item.name !== signalName); */
    let delComps = [];

    for (let comp of components) {
      if (comp.type === RES && comp.pins.find(item => item.signal !== signalName)) {
        delComps.push(comp.name)
        continue;
      }
      comp.pins = []
      updateRects.push(this.getRectByComp(comp));
    }
    components = components.filter(item => !delComps.includes(item.name));
    signals = [];
    this.updatePreLayoutInfo({ signals, components, keys: ["signals", "components"] });
    return { updateRects, deleteRects: delComps }
  }

  removePinsInComp = ({ components, component, signalName, updateRects, isRes }) => {
    let compIndex = components.findIndex(comp => comp.name === component);
    if (compIndex > -1) {
      components[compIndex].pins = components[compIndex].pins.filter(pin => signalName !== pin.signal);
      const index = updateRects.findIndex(it => it.name === components[compIndex].name);
      if (index > -1) {
        updateRects[index] = this.getRectByComp(components[compIndex]);
      } else {
        updateRects.push(this.getRectByComp(components[compIndex]));
      }
      if (components[compIndex].type === RES) {
        isRes = true;
      }
    }
    return isRes
  }

  reDrawSignals = (signals) => {
    const lines = signals.map(item => this.getLineBySignal(item));
    this.canvas.lines = lines;
    this.canvas.drawLines(lines);
  }

  removeAllSignals = (signalName) => {
    this.canvas && this.canvas.deleteLine(signalName)
  }

  removeSignalSection = (signalName, sectionId) => {
    this.canvas && this.canvas.deleteLineSection(signalName, sectionId)
  }
}

export {
  SchematicPlot
}