import {
  UPLOAD_PCB_START_UPDATE_STATUS,
  UPDATE_UPLOAD_PROGRESS,
  UPDATE_UPLOAD_PCB_MSG,
  CLEAN_UPLOAD_PCB_STATUS,
  UPDATE_UPLOAD_WORKFLOW,
  UPDATE_TRANSLATION_DEBUG,
  UPDATE_TRANSLATION_MONITOR,
  UPDATE_TRANSLATION_PROGRESS,
  UPDATE_UPLOAD_END_MSG,
  SAVE_CURRENT_PCB_KEY,
  UPDATE_COMPRESS_PROGRESS
} from './actionType';

const defaultState = ({
  visible: false,
  compressProgress: -1,
  uploadProgress: 0,
  transProgress: -1,
  msg: null,
  monitor: null,
  uploadProjectId: null,
  uploadDesignId: null,
  uploadWorkflowId: null,
  currentPCBKey: null,
  disabled: false,
  debugMonitor: null,
  endMsg: null,
  uploadType: null
})

const RockyUploadReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPLOAD_PCB_START_UPDATE_STATUS:
      return {
        ...state,
        visible: true,
        compressProgress: 0,
        monitor: null,
        msg: '',
        disabled: true,
        uploadProjectId: action.projectId,
        endMsg: "",
        uploadType: action.uploadType
      }
    case UPDATE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.uploadProgress
      }
    case UPDATE_UPLOAD_PCB_MSG:
      return {
        ...state,
        msg: state.msg ? (state.msg + '\n' + action.msg) : action.msg
      }
    case CLEAN_UPLOAD_PCB_STATUS:
      return {
        ...state,
        visible: false,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
        disabled: false,
        uploadDesignId: null,
        uploadWorkflowId: null,
        uploadType: null
      }
    case UPDATE_UPLOAD_WORKFLOW:
      return {
        ...state,
        uploadDesignId: action.uploadDesignId,
        uploadWorkflowId: action.uploadWorkflowId
      }
    case UPDATE_TRANSLATION_MONITOR:
      return {
        ...state,
        monitor: action.monitor
      }
    case UPDATE_TRANSLATION_DEBUG:
      return {
        ...state,
        debugMonitor: action.debug
      }
    case UPDATE_TRANSLATION_PROGRESS:
      return {
        ...state,
        transProgress: action.transProgress
      }
    case UPDATE_UPLOAD_END_MSG:
      return {
        ...state,
        endMsg: action.msg
      }
    case SAVE_CURRENT_PCB_KEY:
      return {
        ...state,
        currentPCBKey: action.pcbKey
      }
    case UPDATE_COMPRESS_PROGRESS:
      return {
        ...state,
        compressProgress: action.compressProgress
      }
    default: return state
  }
}

export default RockyUploadReducer;