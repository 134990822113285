import { combineReducers } from 'redux';
import { HimalayasProjectReducer } from './project';
import { HimalayasLibraryReducer } from './library';
import { HimalayasSpiceCardReducer } from './spiceCardTemplate';
import { HimalayasSimulationReducer } from './simulation';
import { HimalayasIoSimReducer } from './ioSim';

const rootReducer = combineReducers({
  project: HimalayasProjectReducer,
  library: HimalayasLibraryReducer,
  spiceCard: HimalayasSpiceCardReducer,
  simulation: HimalayasSimulationReducer,
  ioSim: HimalayasIoSimReducer
});

export default rootReducer;