import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '../Panel';
import Table from '../EditableTable';
import { Select } from 'antd';
import PinConnectionSetting, { CLEAR, HEADTAIL, NEARBY } from './pinConnectionSetting';
import './index.css';

const Columns = [{
  title: "Pin 1",
  dataIndex: 'pinsLeft',
  width: '50%'
}, {
  title: "Pin 2",
  dataIndex: 'pinsRight',
  width: '50%'
}]
const { Option } = Select;
class PinConnectionPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 500,
      columns: Columns
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.getColumns();
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleHeight);
  }

  handleHeight = () => {
    const { height = 800 } = this.dialogRoot.getBoundingClientRect();
    this.setState({
      panelMaxHeight: height - 200
    });
  }

  getColumns = () => {
    Columns[0].render = (text) => {
      return <span>{text || ''}</span>
    }

    Columns[1].render = (text) => {
      return <span>{text || ''}</span>
    }

    const dataIndexs = [{ index: 0, dataIndex: 'pinsLeft' }, { index: 1, dataIndex: 'pinsRight' }];
    dataIndexs.forEach(item => {
      const { index, dataIndex } = item;
      Columns[index].onCell = (record) => {
        const { pins, dataSource } = this.props;
        const usedPins = dataSource.map(i => ([i['pinsLeft'], i['pinsRight']])).flat(2).filter(pin => !record[dataIndex] || !record[dataIndex].includes(pin));
        const options = pins.map(p => ({ value: p.pin, disabled: usedPins.includes(p.pin), title: `${p.pin}::${p.pinName} (${p.net})` })).sort((a, b) => !a.disabled && b.disabled ? -1 : 1)
        return {
          edit: 'select',
          record,
          options: options,
          text: record[dataIndex],
          dataIndex,
          // selectMode: 'multiple',
          allowClear: true,
          handleSave: this.savePins,
          dropdownMenuClassName: 'pin-connection-select-dropdown-menu',
          getPopupContainer: document.getElementById('root'),
          handleClear: this.clearPins
        }
      }
    })
    this.setState({
      columns: Columns
    })
  }

  savePins = (record) => {
    const index = record.index;
    const { dataSource } = this.props;
    const _dataSource = [...dataSource];
    const findIndex = _dataSource.findIndex(i => i.index === index);
    if (findIndex > -1) {
      _dataSource[findIndex] = { ...record };
      this.props.saveDataSource(_dataSource)
    }
  }

  clearPins = (record, selectType) => {
    const index = record.index;
    const { dataSource } = this.props;
    const _dataSource = [...dataSource];
    const findIndex = _dataSource.findIndex(i => i.index === index);
    if (findIndex > -1) {
      _dataSource[findIndex] = { ...record, [selectType]: '' };
      this.props.saveDataSource(_dataSource)
    }
  }

  closeModal = () => {
    this.props.savePinMap()
    this.props.save()
  }

  autoSetConnection = (autoType) => {
    const { dataSource, pins } = this.props;
    const _dataSource = [...dataSource];
    const _pins = pins.sort((a, b) => Number(a.pin) - Number(b.pin));
    let loopNumber = 0;
    switch (autoType) {
      case HEADTAIL:
        const half = Math.floor(_pins.length / 2);
        _dataSource.forEach(item => {
          if (loopNumber < half) {
            item.pinsLeft = _pins[loopNumber] ? _pins[loopNumber].pin : ''
            item.pinsRight = _pins[half * 2 - 1 - loopNumber] ? _pins[half * 2 - 1 - loopNumber].pin : ''
            loopNumber = loopNumber + 1;
          }
        })
        break;
      case NEARBY:
        _dataSource.forEach(item => {
          item.pinsLeft = _pins[loopNumber] ? _pins[loopNumber].pin : ''
          item.pinsRight = _pins[loopNumber + 1] ? _pins[loopNumber + 1].pin : ''
          loopNumber = loopNumber + 2;
        })
        break;
      case CLEAR:
        _dataSource.forEach(item => {
          item.pinsLeft = ''
          item.pinsRight = ''
        })
        break;
      default:
        break;
    }
    this.props.saveDataSource(_dataSource)
  }

  selectDesignRender = () => {
    const { designList, designId } = this.props;
    return (
      <div className='power-select-comp-select'>
        <span>Layout</span>
        <Select
          value={designId}
          onSelect={this.props.selectDesign}
          className={"aurora-select"}
          popupClassName='aurora-select-dropdown'
        >
          {designList.map(item => <Option
            key={item.designId}
            title={item.designName}
          >{item.designName}</Option>)}
        </Select>
      </div>
    );
  }

  selectCompRender = () => {
    const { comps, comp } = this.props;
    return (
      <div className='power-select-comp-select'>
        <span>Component</span>
        <Select
          value={comp}
          onSelect={this.props.selectComp}
          className={"aurora-select"}
          popupClassName='aurora-select-dropdown'
        >
          {comps.map(item => <Option
            key={item.name}
            value={item.name}
            title={item.name}
          >{item.name}</Option>)}
        </Select>
      </div>
    );
  }

  render() {
    const { panelMaxHeight = 800, width, columns } = this.state;
    const { partName, dataSource } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='pmic-part-power-select-panel'
          title={`Pin Map - ${partName}`}
          onCancel={this.closeModal}
          zIndex={2000}
          width={width}
          position='panel-center-left'
          draggable
          minHeight={220}
          minWidth={400}
          maxHeight={panelMaxHeight}
        >
          <PinConnectionSetting autoSetConnection={this.autoSetConnection} />
          <div className='pmic-part-pin-map-table'>
            {this.selectDesignRender()}
            {this.selectCompRender()}
            <Table
              className="pmic-part-table-content"
              columns={columns}
              dataSource={dataSource}
              rowKey={(record) => record.index}
              size="small"
            />
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PinConnectionPanel;