import http from "../http";


/**
 * get cascade report config 
 * 2022/12/05
 * @typeName  Cascade_Sign_Off / Cascade_Impedance
 * @export
 *  */
function getCascadeReportConfig({ projectId, typeName }) {
  return http.get(`/cascade/${projectId}/report/config?typeName=${typeName}`)
}

/**
 * Generate report
 * 2022/12/06
 * @param {string} projectId
 * @param {*} param
 * @returns
 */
function generateCascadeReport({ projectId, param }) {
  return http.post(`/cascade/${projectId}/report`, {
    ...param
  });
}

/**
 * Get report file by format
 * 2022/12/06
 * @param {string} projectId
 * @param {*} param
 * @returns
 */
function getCascadeReportFile({ projectId, format }) {
  return http.get(`/cascade/${projectId}/report/${format}`, {
    responseType: 'arraybuffer'
  });
}

/**
 * Get report status
 * 2022/12/06
 * @param {string} projectId
 * @returns
 */
function getCascadeReportStatus(projectId) {
  return http.get(`/cascade/${projectId}/report/status`);
}

/**
 * save report config
 * 2022/12/09
 * @param {string} projectId
 * @param {Object} config - config
 * @returns
 */
function saveCascadeReportConfig({ projectId, config }) {
  return http.post(`/cascade/${projectId}/report/config`, { ...config });
}

export {
  getCascadeReportConfig,
  generateCascadeReport,
  getCascadeReportFile,
  getCascadeReportStatus,
  saveCascadeReportConfig
}