import React, { Component, Fragment } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Input, Select, Tooltip } from 'antd';
import CreateIdentificationAdvanced from '@/components/createChannelContent/createAdvanced';
import {
  getIdentificationAdvancesInputList,
  addInterfaceInputList,
  GROUP_NAME
} from '@/services/Sierra/helper/InterfaceHelper';

const COMPONENT = "component";
const Option = Select.Option;

class CreateContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  inputContent = (item, config) => {
    const { key, placeholder, list } = item;
    const { component } = config;
    const { componentList, selectChange, selectComponent, pcbId, groupName, inputChange, checkValue } = this.props;
    switch (key) {
      /*  case NAME:
         return <Input
           className='aurora-input create-content-input'
           value={config[key]}
           placeholder={title}
           onChange={(e) => inputChange(e, key)}
           onKeyDown={(e) => this.keyDown(e)}
           onBlur={(e) => checkValue(e.target.value, key)}
         /> */
      case COMPONENT:
        return <Select
          value={component ? component.name : ""}
          showSearch
          allowClear
          onChange={(key) => selectComponent(key)}
          placeholder={"Component"}
          className='aurora-select create-content-select'
          popupClassName='aurora-select-dropdown'
          disabled={!pcbId}
        >
          {componentList.map(item =>
            <Option key={item.name}>{item.name}</Option>
          )}
        </Select>
      case GROUP_NAME:
        return <Input
          className='aurora-input create-content-input'
          value={groupName}
          /* placeholder={"Group Name"} */
          onChange={(e) => inputChange(e, key)}
          onKeyDown={(e) => this.keyDown(e)}
          onBlur={(e) => checkValue(e.target.value, key)}
        />
      default: return <Select
        value={config[key]}
        onChange={(e) => selectChange(e, key)}
        placeholder={placeholder}
        className='aurora-select create-content-select'
        popupClassName='aurora-select-dropdown'
        disabled={!pcbId}
      >
        {list.map(it =>
          <Option key={it.key}>{it.title}</Option>
        )}
      </Select>;
    }
  }

  isSuffixIcon = (advancedConfig, key) => {
    const isRegular = advancedConfig[key];
    const inputKey = key === "excludeIsRegular" ? "EXCLUDE" : "netNamePrefix";
    return (
      <Fragment>
        {advancedConfig[inputKey] && advancedConfig[inputKey].length ? <Tooltip
          overlayClassName="aurora-tooltip"
          title={"Clear all"}
          key='nets-tooltip-clear-icon-input'>
          <CloseCircleFilled
            className="create-nets-keywords-clear-icon"
            onClick={(e) => this.props.clearAllKeywords(e, inputKey)} />
        </Tooltip> : null}
        <Tooltip
          overlayClassName="aurora-tooltip"
          title={isRegular ? "Regular Expression" : "Non Regular Expression"}
          key='icon-input'>
          <div
            onClick={() => this.props.updateRegularValue(!isRegular, key)}
            className={isRegular ? 'regular-icon-selected' : 'regular-icon'}
          >.*</div>
        </Tooltip>
      </Fragment>
    );
  }

  render = () => {
    const { updateAdvancedConfig, config } = this.props;
    const identificationAdvancesInputList = getIdentificationAdvancesInputList({ ...(config.advancedConfig || {}) });
    return <Fragment>
      <div className='create-data-content'>
        {addInterfaceInputList.map(item =>
          <div key={item.key} className="create-content-input-item">
            <label className='create-edit-input-label'>{item.title}</label>
            {this.inputContent(item, config)}
          </div>)}
        <CreateIdentificationAdvanced
          serdesType={config.interfaceType}
          advancedConfig={{ ...config.advancedConfig }}
          updateAdvancedConfig={(_config, type) => updateAdvancedConfig(_config, type)}
          advancedInputList={identificationAdvancesInputList}
          isSuffixIcon={this.isSuffixIcon}
        />
      </div>
    </Fragment>
  }
}

export default CreateContent;