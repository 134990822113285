import React, { Fragment } from 'react';
import { Button, Progress } from 'antd';
import "./index.css";

function reportTopBarTitle({
  reportVisible,
  reportPanelVisible,
  reportProgress,
  updateReportPanel,
  className
}) {
  return reportVisible && !reportPanelVisible ? <Fragment key="report">
    <div className={`top-bar-item top-bar-report-item`}
      onClick={() => updateReportPanel(true)}
      style={reportVisible ? { background: '#c7e0f4' } : {}}
      title="Report"
    >
      <div className='top-bar-report'>
        <span className="iconfont icon-baogao top-bar-report-icon top-bar-icon-diff"></span>
        <span className={`top-bar-item-hide ${className}`}>Report</span>
      </div>
      <div className='top-bar-report-progress' style={{ width: `${reportProgress}%` }} />
    </div>
    <span className='andes-v2-report-progress-span'>{reportProgress}%</span>
  </Fragment>
    :
    <div className={`top-bar-item`}
      key="report"
      onClick={() => updateReportPanel(true)}
      style={reportVisible ? { background: '#c7e0f4' } : {}}
      title="Report"
    >
      <div className='top-bar-report'>
        <span className="iconfont icon-baogao top-bar-report-icon top-bar-icon-diff"></span>
        <span className={`top-bar-item-hide ${className}`}>Report</span>
      </div>
    </div>
}

function reportProgressRender({
  reportVisible,
  reportMessage,
  reportProgress = -1,
  downloadReport,
  disabled = false,
  downloadLoading
}) {
  const messageClassName = reportMessage && reportMessage.indexOf('successfully') > -1 ? 'download-success' : 'download-error';
  return <div className='report-message-main .clear'>
    {!reportVisible ? <Fragment>
      {reportMessage ? <div
        className={`${messageClassName} report-download-message`}
      >{reportMessage}</div> : null}
      <div className='report-download-button clear'>
        <Button
          title={"download"}
          type="primary"
          ghost
          disabled={reportVisible || downloadLoading || disabled}
          onClick={(e) => downloadReport(e)}
          className='report-download-btn'
        >Download</Button>
      </div>
    </Fragment> :
      <Progress
        size={{ height: 10 }}
        className='report-progress'
        percent={reportProgress}
      />}
  </div>
}

export {
  reportTopBarTitle,
  reportProgressRender
}