import Plot2D from '../../Result/Public/waveform/Plot2D';

function Signal() {
  this.name = null; // String, name of the signal
  this.type = null; // String, signal type - 'voltage' or 'current'
};

// ------ Result data class ------
/** Raw curve data of a byte */
class WaveformData {
  constructor(type, historyTime) {
    this.signals = []; // Array of WaveformResultHelper.Signal, list of signal description
    this.time = []; // Array of Number, time instances of the simulation
    this.curveData = []; // Array of Array of Number, values at each time instance for all signals
    this.plot = null;
    this.type = type; // result/history
    this.historyTime = historyTime ? historyTime : 'current';// simulation result time 
  }

  parseRawCurve(fileContent) {
    // break the file into lines
    var lineBuffer = fileContent.match(/[^\r\n]+/g);
    if (!lineBuffer || (Array.isArray(lineBuffer) && !lineBuffer.length)) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
      return;
    }
    // read the header
    var numSignals = 0; // number of signals in the raw data file
    var numPoints = 0; // number of sampling points in the raw data file
    var indDataLine = 0; // starting line of the curve data
    var isReadingSignalDef = false; // indicator of the signal definition section in the header

    for (let i = 0; i < lineBuffer.length; i++) {

      if (isReadingSignalDef) {

        // get the name and type of the signal
        var words = lineBuffer[i].trim().split("\t");
        var signalItem = new Signal();
        // Signal1_Controller_U1, PCIe_TX0_Controller_U1
        //Andes-v2: 1	PCI_LANE0_J1_H5_J5_J2_H5_J5	voltage
        signalItem.name = words[1];
        signalItem.type = words[2];
        this.signals.push(signalItem);

        // add a data line for the signal
        this.curveData.push([]);

        if (this.signals.length === numSignals)
          isReadingSignalDef = false;
      } else {

        words = lineBuffer[i].split(" ");
        if (words[0] === 'No.' && words[1] === 'Variables:') {
          // number of signals
          numSignals = Number(words[2]);
        } else if (words[0] === 'No.' && words[1] === 'Points:') {
          // number of points
          numPoints = Number(words[2]);
        } else if (words[0] === 'Variables:') {
          // starting of variable definitions
          isReadingSignalDef = true;
        } else if (words[0] === 'Values:') {
          // starting of curve values
          indDataLine = Number(i);
          break;
        }
      }
    } // for (var i in lineBuffer)

    // data integrity check
    if (numPoints <= 0 || numSignals <= 0 || this.signals.length !== numSignals)
      return null;
    else if (indDataLine + numSignals * numPoints >= lineBuffer.length)
      return null;

    // read the data points
    try {
      for (var iPoint = 0; iPoint < numPoints; iPoint++) {

        for (var iSignal = 0; iSignal < numSignals; iSignal++) {

          indDataLine++;
          if (iSignal === 0) {
            words = lineBuffer[indDataLine].split('\t');
            /* this.curveData[iSignal].push(Number(words[2])); */
            this.curveData[iSignal].push(Number(words[words.length - 1]));
          } else {
            this.curveData[iSignal].push(Number(lineBuffer[indDataLine]));
          }

        } // for (var iSignal = 0; iSignal < numSignals; iSignal++)
      } // for (var iPoint = 0; iPoint < numPoints; iPoint++)

      // separate the time signal
      this.time = this.curveData.shift();
      this.signals.shift();

    } catch (err) {
      this.signals = [];
      this.time = [];
      this.curveData = [];
    }
  } // parseRawCurveFile

  getCurvesData = () => {
    const { signals, time, curveData } = this;
    const { CurveItem } = Plot2D;
    // prepare the curve data
    var curves = [];
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      if (signal.name) {
        let newCurve = new CurveItem();
        newCurve.x = time;
        newCurve.y = curveData[i];
        newCurve.name = signal.name;
        curves.push(newCurve);
      }
    }

    return curves;
  }
};

export default WaveformData;