import http from "../http";

/**
 * Get library files in project by type
 * 2019.08.09
 * 
 * @export
 * @param {*} projectId
 * @param {*} libraryFileName
 * @returns
 */
export function getLibraryFileInProject_v2(projectId, libraryId) {
  return http.get(`/project/${projectId}/library/${libraryId}/file`);
}

/**
 * Delete library models
 * 2019/08/15
 * @export
 * @param {string} projectId
 * @param {Array} libraryIds 
 * @returns
 */
export function deleteLibModels({ projectId, libraryIds }) {
  return http.delete(`/project/${projectId}/library`,
    {
      params: {
        libraryIds: libraryIds.toString()
      }
    }
  )
}

/**
 * Get library map
 * 2019/08/15
 * @export
 * @param {string} product
 * @returns
 */
export function getLibraryMap(product) {
  return http.get(`/${product}/library/folder/map`);
}

/**
 * Get library folder
 * 2021/07/06
 * @export
 * @param {string} product
 * @returns
 */
export function getLibraryFolder({ product, folderId, type }) {
  return http.get(`/${product}/library/folder/list`, {
    params: {
      type,
      folderId
    }
  });
}

/**
 * Get current library folder info
 * 2021/07/06
 * @export
 * @param {string} folderId
 * @returns
 */
export function getLibraryFolderInfo(folderId) {
  return http.get(`/library/folder`, {
    params: {
      folderId
    }
  });
}

/**
 * Create / rename library folder
 * 2021/07/06
 * @export
 * @param {string} product
 * @param {object} folder - { id, name, parentId, type }
 * @returns
 */
export function editLibraryFolder({ product, folder }) {
  return http.post(`/${product}/library/folder/edit`, folder);
}

/**
 * Delete library folder
 * 2021/07/06
 * @export
 * @param {string} product
 * @param {Array} [ids]
 * @returns
 */
export function deleteLibraryFolder({ product, folderIds }) {
  return http.post(`/${product}/library/folder/delete`, folderIds);
}

export function getLibraryTree({ product, type }) {
  return http.get(`/${product}/library/tree`, {
    params: {
      type
    }
  })
}

export function updateLibraryFile({ product, formData, config }) {
  return http.post(`library/${product}/update`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getSystemLibraryList(folderId) {
  return http.get(`/system/library/list?folderId=${folderId}`)
}

export function getSystemLibraryFile(libraryId) {
  return http.get(`/system/library/resource?libraryId=${libraryId}`)
}


export function getSystemLibraryData({ page, vendor }) {
  return http.get(`/system/library/data?page=${page}&vendor=${vendor}`)
}

export function getSystemLibrarySearch({ page, keyword }) {
  return http.post(`/system/library/search`, { page, keyword });
}

export function getSystemLibraryBulkSearch(searchParams) {
  return http.post(`system/library/bulk/search`, searchParams);
}

export function getSystemGeneric() {
  return http.get(`/system/library/generic`)
}

export function getCustomLibraryList(folderId) {
  return http.get(`/custom/library/list?folderId=${folderId}`);
}

export function getCustomLibraryById(libraryId) {
  return http.get(`/custom/library/resource?libraryId=${libraryId}`);
}

/* export one model */
export function getCustomLibraryMatch(partNumber) {
  return http.get(`/custom/library/match?partNumber=${encodeURIComponent(partNumber)}`);
}

/* export model list */
export function getCustomLibrarySearch(partNumber) {
  return http.get(`/custom/library/search?partNumber=${encodeURIComponent(partNumber)}`);
}

/**
 * get library children list by folder id
 * 2023/10/10
 * @param {string} id folder id
 * @param {productType} productType product
 * @return */
export function getFolderChildren({ productType, id }) {
  return http.get(`/library/${productType}/list?folderId=${id}`)
}

/**
 * Get children of SPICE/Repeater folder in the library
 * 2023/10/24
 * @param {string} libraryId sp folder libraryId
 * @param {string} libraryType spice/repeater
 * @param {string} productType sierra/rocky
 * @export
 * @returns
 */
export function getSPFolderFileList({ libraryId, libraryType, productType }) {
  return http.get(`/user/library/${productType}/list?libraryId=${libraryId}&libraryType=${libraryType}`);
}

/**
 * Get a list of subckt and node of SPICE file in the library
 * 2023/10/30
 * @param {string} libraryId sp folder libraryId
 * @param {array} fileNames []
 * @param {string} productType sierra/rocky
 * @export
 * @returns
 */
export function getSPFolderFileDetail({ fileNames, libraryId, productType }) {
  return http.get(`/user/library/${productType}/detail?fileNames=${fileNames}&libraryId=${libraryId}`);
}

/**
  * Get children of folder in the library
  * 2024/10/29
  * @param {string} libraryId folder libraryId
  * @param {string} productType sierra/rocky
  * @export
  * @returns
  */
export function getIBISFolderFileDetail({ libraryId, productType }) {
  return http.get(`/user/library/${productType}/detail?libraryId=${libraryId}`);
}