import React, { Component } from 'react';
import { Input } from 'antd';
import {
  checkLibraryValue,
  getLibraryConfigVersion,
  ViaConfig,
  ViaKeyArr,
  LIBRARY_UNITS,
  UNIT,
  DRILL_DIAMETER,
  PLATING_THICKNESS,
  PAD_DIAMETER,
  ANTI_PAD_DIAMETER,
  VIA_TO_VIA_PITCH
} from '@/services/PreLayout/PreLayoutLibrary';
import { VIA, VIA_VERSION } from '../../constants/libraryConstants';
import { unitChange } from '@/services/helper/mathHelper';
import UnitAddonAfter from '@/components/UnitAddonAfter';


class ViaSetup extends Component {

  constructor(props) {
    super(props);
    const config = new ViaConfig();
    this.state = {
      config,
      version: VIA_VERSION,
      prevConfig: null,
      defaultConfig: this.getDefaultConfig(config)
    }
  }

  addonAfter = (unit) => {
    const { errorMsg } = this.props;
    return UnitAddonAfter({ unit, changeUnit: this.changeUnit, list: LIBRARY_UNITS, disabled: errorMsg ? true : false })
  }

  changeUnit = (key) => {
    const { config, defaultConfig = {} } = this.state;
    let _config = { ...config };
    const oldUnit = _config[UNIT];
    for (let item of ViaKeyArr) {
      if (item.key && item.unit && _config[item.key]) {
        const defaultValue = defaultConfig[item.key] || _config[item.key],
          defaultUnit = defaultConfig[`${item.key}Unit`] || oldUnit;
        _config[item.key] = unitChange({
          num: defaultValue,
          oldUnit: defaultUnit,
          newUnit: key,
          decimals: 4
        }).number.toString();
      }
    }
    _config[UNIT] = key;
    this.setState({
      config: {
        ..._config
      }
    })
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    // Get data
    this.getData();
  }

  getData = () => {
    const { libraryId, getLibraryDetail } = this.props;
    if (libraryId) {
      getLibraryDetail(libraryId).then(res => {
        if (res && res.config) {
          this.setState({
            config: { ...res.config },
            defaultConfig: this.getDefaultConfig(res.config),
            type: res.type,
            version: res.version,
            prevConfig: { ...res.config }
          })
        }
      })
    }
  }

  getDefaultConfig = (config) => {
    let defaultConfig = {};
    for (let key in config) {
      if ([DRILL_DIAMETER, PLATING_THICKNESS, PAD_DIAMETER, ANTI_PAD_DIAMETER, VIA_TO_VIA_PITCH].includes(key)) {
        defaultConfig[key] = config[key];
        defaultConfig[`${key}Unit`] = config[UNIT];
      }
    }
    return defaultConfig;
  }

  componentDidUpdate = (prevProps) => {
    const { libraryId } = this.props;
    if (libraryId !== prevProps.libraryId) {
      const config = new ViaConfig();
      this.setState({
        config,
        prevConfig: null,
        version: VIA_VERSION,
        defaultConfig: this.getDefaultConfig(config)
      }, () => {
        this.getData();
      })
    }
  }

  getConfig = () => {
    const { config, prevConfig, version } = this.state;
    const { libraryId, product } = this.props;
    for (let item of ViaKeyArr) {
      //check value
      const error = checkLibraryValue({ value: config[item.key], key: item.key, title: item.title, product, config });
      if (error) {
        this.props.updateErrorMsg(error);
        return null;
      }
    }
    //update version by config
    let _version = version;
    if (libraryId) {
      _version = getLibraryConfigVersion({ version, prevConfig, config })
    }
    return { config, type: VIA, version: _version, save: (_version !== version || !libraryId) };
  }

  saveValue = (e, key) => {
    const value = e.target.value;
    const { errorMsg } = this.props;
    const { config } = this.state;
    let _config = config ? { ...config } : {};
    _config[key] = value;
    this.changeConfigKey = key;
    this.setState({
      config: _config
    })
    if (errorMsg && errorMsg.type === key) {
      this.props.updateErrorMsg(null);
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  checkData = (e, key, title) => {
    const value = e.target.value;
    const { config, defaultConfig } = this.state;
    const error = checkLibraryValue({ value, key, title, product: this.props.product, config });
    if (error) {
      e.target.focus();
      this.props.updateErrorMsg(error);
    } else {
      let _config = config ? { ...config } : {};
      let _defaultConfig = defaultConfig ? { ...defaultConfig } : {};
      if (this.changeConfigKey === key) {
        _defaultConfig[key] = value;
        _defaultConfig[`${key}Unit`] = _config[UNIT];
        this.changeConfigKey = null;
      }
      _config[key] = value;
      this.setState({
        config: _config,
        defaultConfig: _defaultConfig
      })
    }
  }

  render = () => {
    const { config } = this.state;
    return <div className='via-content'>
      {/* value list */}
      {ViaKeyArr.map(item => <div key={item.key} className='library-item'>
        <label className='library-edit-value-label-via'>{item.title}</label>
        <Input
          key={item.key}
          className='aurora-input library-input-via'
          value={config && config[item.key] ? config[item.key] : null}
          placeholder={item.title}
          addonAfter={item.unit ? this.addonAfter(config[UNIT]) : null}
          onChange={(e) => this.saveValue(e, item.key)}
          onKeyDown={(e) => this.keyDown(e)}
          onBlur={(e) => this.checkData(e, item.key, item.title)}
        />
      </div>)}
    </div>
  }
}

export default ViaSetup;