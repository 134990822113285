import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { Switch, Button } from 'antd'
import { createPortal } from 'react-dom';
import '../index.css';

class CascadeSchematicAdvanced extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.state = {
      panelWidth: 300,
      panelHeight: 180
    };
  }

  closeModal = () => {
    this.props.settingClick(null, false)
  }

  render() {
    const { panelWidth, panelHeight } = this.state;
    const { grid, font, left, top } = this.props;
    const content = (
      <Fragment>
        <Panel
          position='panel-center-left'
          title="Schematic Advanced"
          zIndex={2000}
          onCancel={this.closeModal}
          width={panelWidth}
          height={panelHeight}
          draggable
          minWidth={300}
          maxHeight={1000}
          defaultLeft={left}
          defaultTop={top}
        >
          <div className="cascade-schematic-advanced">
            <div className="advanced-item">
              <span className="advanced-title">Show Gridlines</span>
              <span className="advanced-content">
                <Switch
                  size="small"
                  checked={grid}
                  onChange={this.props.changeGrid}
                />
              </span>
            </div>
            <div className="advanced-item">
              <span className="advanced-title">Font Size</span>
              <span className="advanced-content">
                <Button size="small" className="font-button" disabled={font >= 20 ? true : false} onClick={(e) => this.props.changeFont(e, font + 1)}>+</Button>
                <Button size="small" className="font-button" disabled={font <= 8 ? true : false} onClick={(e) => this.props.changeFont(e, font - 1)}>-</Button>
              </span>
            </div>
            <div className="advanced-item">
              <span className="advanced-title">Save Schematic</span>
              <span className="advanced-content">
                <Button size="small" className="schematic-download-button" onClick={(e) => this.props.downloadCanvas(e)}>Download</Button>
              </span>
            </div>
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default CascadeSchematicAdvanced;