import {
  AMI_PRBS_MODE_LIST,
  SEED,
  TAPS,
  REGISTER_LENGTH,
  BIT_SEQUENCE,
  AMI_SIMULATION,
  AMI_SIM_MODE_LIST,
  NUMBER_OF_BIT,
  AMI_INTERFACE_TYPE_LIST,
  AMI_HDMI_TYPE_LIST,
  FIR_TAPS,
  DE_EMPHASIS,
  ADS_RX,
  ADS_TX,
  DE_EMPHASIS_OPTIONS,
  FIR_TAPS_OPTIONS,
  RX_NOISE,
  TX_SJ_FREQUENCY,
  CTLE,
  FFE,
  CTLE_OPTIONS,
  CTLE_CUSTOM_OPTIONS,
  FFE_OPTIONS,
  DFE,
  DFE_OPTIONS,
  DFE_ALL_OPTIONS,
  ENABLE_CTLE_OR_FFE,
  getCtlePresetOptions,
  getFFEOptions,
  getDFEOptions,
  NUMBER_OF_TAPS,
  PROTOCOL,
  getHDMIPresetOptions,
  DFE_FILTER_OPTIONS,
  DFE_ADAPTIVE_EQUALIZATION,
  SLICER_OUTPUT,
  MANUAL,
  OPTIMIZED
} from ".";
import { strDelimited } from "../../helper/split";
import { CPHY, HDMI, PCIE } from "../../PCBHelper/constants";
import { CPHY_AMI_PRBS_MODE_LIST, DFE_SETUP_OPTIONS, DFE_TYPE, DFETAPUSED, getPresetOption, getTapNum, PRESET, SERIES } from "./constants";
import { AMIModelConfig, setDefaultNoAmiJitters } from "./IntegratedConfig";
import { getValueFormEquation } from '@/services/Andes_v2/AMIModelHelper/equationCount';

function getIbisAmiModelList(ibisAmi) {
  let models = [];
  if (ibisAmi.models) {
    for (let modelItem of ibisAmi.models) {
      let pins = ibisAmi.pins.filter(item => item.modelName === modelItem.name);
      pins = JSON.parse(JSON.stringify(pins));
      pins.forEach(item => {
        const findPin = ibisAmi.diffPins.find(it => it.pin === item.pin || it.invPin === item.pin);
        if (findPin && findPin.pin === item.pin) {
          item.pinType = "pin";
        }
        if (findPin && findPin.invPin === item.pin) {
          item.pinType = "invPin";
        }
      })
      models.push({
        ...modelItem,
        pins
      })
    }
  }
  return models;
}

function updatePRBSOptions(prbs, type, serdesType) {
  const modelList = serdesType === CPHY ? CPHY_AMI_PRBS_MODE_LIST : AMI_PRBS_MODE_LIST;
  const findItem = modelList.find(item => item.key === type);
  if (findItem) {
    // prbs = clearPRBSOptions(prbs);
    for (let item of findItem.children) {
      if (!prbs[item.key]) {
        prbs[item.key] = item.defaultValue;
      }
    }
  }
  return prbs;
}

// function clearPRBSOptions(prbs) {
//   delete prbs[SEED];
//   delete prbs[TAPS];
//   delete prbs[REGISTER_LENGTH];
//   delete prbs[BIT_SEQUENCE];
//   return prbs;
// }


function updateAmiSimOptions(adsConfig, type) {
  const findItem = AMI_SIM_MODE_LIST.find(item => item.key === type);
  if (findItem) {
    delete adsConfig[AMI_SIMULATION][NUMBER_OF_BIT];
    for (let item of findItem.children) {
      adsConfig[AMI_SIMULATION][item.key] = item.defaultValue;
    }
  }
  return adsConfig;
}

function updateBitRateByProtocolType(prbs, type, serdesType) {
  const list = getProtocolTypeList(serdesType);
  const findItem = list.find(item => item.key === type);
  if (!findItem) {
    return prbs;
  }
  prbs.bitRate = findItem.bitRate;
  if (findItem.clockBitRate) {
    prbs.clockBitRate = findItem.clockBitRate;
  } else {
    delete prbs.clockBitRate;
  }
  return prbs;
}

function updateEncoderByProtocolType(type, serdesType) {
  const list = getProtocolTypeList(serdesType);
  const findItem = list.find(item => item.key === type);
  if (findItem) {
    return findItem.encoder;
  }
}

function getProtocolTypeList(type) {
  switch (type) {
    case PCIE:
      return AMI_INTERFACE_TYPE_LIST;
    case HDMI:
      return AMI_HDMI_TYPE_LIST;
    default: return [];
  }
}

function ibisAMIModelParamsParse(ibisAmi) {
  if (!ibisAmi || !ibisAmi.amis) {
    return;
  }

  for (let amisItem of ibisAmi.amis) {
    if (!amisItem.parameters) {
      continue;
    }
    for (let paramKey in amisItem.parameters) {
      amisItem.parameters[paramKey] = ibisAMIParameterParse(amisItem.parameters[paramKey])
    }
  }
  return ibisAmi
}

const RANGE = "Range", CORNER = "Corner", BOOLEAN = "Boolean", LIST = "List",
  STRING = "String", INTEGER = "Integer", FLOAT = "Float", TAP = "Tap",
  GAUSSIAN = "Gaussian", PARAMETER_DUAL_DIRAC = "Dual-Dirac", PARAMETER_DJRJ = "DjRj";
function ibisAMIParameterParse(param) {
  if (param[RANGE]) {
    //Range : "1.100 0.100 1.100"
    const rangeList = strDelimited(param[RANGE], " ");
    param.valueType = RANGE;
    param.valueList = rangeList.filter((item, index) => index > 0 && !!item);
  }

  if (param[CORNER]) {
    //Corner: "50 55 45"
    const cornerTypes = ["Typ", "Slow", "Fast"];
    param.valueType = CORNER;
    const valueList = strDelimited(param[CORNER], " ").filter(item => !!item);
    param.valueList = valueList.map((item, index) => { return { type: cornerTypes[index], value: item } })
  }

  if (param.Type === BOOLEAN) {
    param.valueType = BOOLEAN;
    param.valueList = ["True", "False"];
  }

  if (param[LIST]) {
    //List : "1 2 3 4 5"
    param.valueType = LIST;
    let valueList = [];
    if (param.Type === STRING) {
      valueList = strDelimited(param[LIST], "\" ").filter(item => !!item);
      valueList = valueList.map((item, index) => {
        if (index < valueList.length - 1) {
          return `${item}"`;
        }
        return item;
      })
    } else {
      valueList = strDelimited(param[LIST], " ").filter(item => !!item);
    }
    param.valueList = valueList;
  }

  if (param.Type === TAP) {
    param.valueType = TAP;
  }

  if (param[GAUSSIAN]) {
    //Gaussian <mean> <sigma>
    const [mean, sigma] = strDelimited(param[GAUSSIAN], " ");
    param.valueType = GAUSSIAN;
    param.mean = mean;
    param.sigma = sigma;
    param.displayValue = param[GAUSSIAN];
  }

  if (param[PARAMETER_DUAL_DIRAC]) {
    //Dual-Dirac <mean> <mean> <sigma>
    param.valueType = PARAMETER_DUAL_DIRAC;
    param.displayValue = param[PARAMETER_DUAL_DIRAC];
  }

  if (param[PARAMETER_DJRJ]) {
    //DjRj <minDj> <maxDj> <sigma>
    param.valueType = PARAMETER_DJRJ;
    param.displayValue = param[PARAMETER_DJRJ];
  }

  return param;
}

function updateParamsValueByCorner({
  model,
  corner,
  fileInfo
}) {

  if (!model || !model.amiParameters || !model.amiParameters.length) {
    return model;
  }
  const amis = fileInfo.ibisAmi ? fileInfo.ibisAmi.amis : [];
  const models = fileInfo.ibisAmi ? fileInfo.ibisAmi.models : [];

  const findModel = models.find(item => item.name === model.modelName);
  const algorithmicModels = findModel ? findModel.algorithmicModels : [];
  const algorithmicModelsItem = algorithmicModels && algorithmicModels.length ? algorithmicModels[0] : {};
  const parameterName = algorithmicModelsItem.parameterFile;
  const findAmis = amis.find(item => item.name === parameterName);
  const ibisParameters = findAmis ? findAmis.parameters : {};

  for (let param of model.amiParameters) {
    if (!ibisParameters[param.name] || ibisParameters[param.name].valueType !== CORNER) {
      continue;
    }
    const valueList = ibisParameters[param.name].valueList || [];
    const findCorner = valueList.find(item => item.type === corner);
    if (findCorner) {
      param.value = findCorner.value;
    }
  }
  return model;
}


function updateSignalNameInAdsConfig(adsConfig, prevName, name) {
  if (!adsConfig || !adsConfig.signals) {
    return adsConfig;
  }

  for (let sigItem of adsConfig.signals) {
    if (sigItem.signalName === prevName) {
      sigItem.signalName = name;
    }
    if (sigItem.aggressors && sigItem.aggressors.includes(prevName)) {
      sigItem.aggressors = sigItem.aggressors.filter(item => item !== prevName);
      sigItem.aggressors.push(name);
    }
  }

  return adsConfig;
}

function delSignalInAdsConfig(adsConfig, signal) {
  if (!adsConfig || !adsConfig.signals) {
    return adsConfig;
  }
  adsConfig.signals = adsConfig.signals.filter(item => item.signalName !== signal);

  for (let sigItem of adsConfig.signals) {
    if (sigItem.aggressors && sigItem.aggressors.includes(signal)) {
      sigItem.aggressors = sigItem.aggressors.filter(item => item !== signal);
    }
  }

  return adsConfig;
}

function getSignalAmiCompBySignalName({ signalName = "", controller, device }) {
  if (signalName.match('TX')) {
    return { txComp: controller, rxComp: device }
  }
  if (signalName.match('RX')) {
    return { txComp: device, rxComp: controller }
  }
  return { txComp: controller, rxComp: device };
}

function updateAdsSignalModelType(adsConfig, key, interfaceType) {
  if (!adsConfig || !adsConfig.signals) {
    return adsConfig;
  }

  for (let sigItem of adsConfig.signals) {
    const { txComp = "", rxComp = "", txChannelId = "", rxChannelId = "" } = getModelComp(sigItem);

    delete sigItem.txAmiModel;
    delete sigItem.rxAmiModel;
    delete sigItem.txModel;
    delete sigItem.rxModel;
    delete sigItem.rcModel;

    if (key === "AMI") {
      sigItem.txAmiModel = new AMIModelConfig({
        type: interfaceType,
        modelType: key,
        component: txComp,
        channelId: txChannelId,
      });
      sigItem.rxAmiModel = new AMIModelConfig({
        type: interfaceType,
        modelType: key,
        component: rxComp,
        channelId: rxChannelId,
      })
      sigItem.IbisHasAMI = "yes";
    }

    if (key === "IBIS") {
      sigItem.txModel = new AMIModelConfig({
        type: interfaceType,
        modelType: key,
        component: txComp,
        channelId: txChannelId,
        jitters: setDefaultNoAmiJitters(ADS_TX) || []
      });
      sigItem.rxModel = new AMIModelConfig({
        type: interfaceType,
        modelType: key,
        component: rxComp,
        channelId: rxChannelId,
        jitters: setDefaultNoAmiJitters(ADS_RX) || [],
        modelDir: ADS_RX
      })
      sigItem.IbisHasAMI = "no";
    }
  }
  return adsConfig;
}

function getModelComp(sigItem) {
  let txComp = "", txChannelId = "", rxComp = "", rxChannelId = "";

  let modelKey = "Ami";

  if (sigItem.IbisHasAMI === "no") {
    modelKey = ""
  }

  if (sigItem[`tx${modelKey}Model`]) {
    txComp = sigItem[`tx${modelKey}Model`].component;
    txChannelId = sigItem[`tx${modelKey}Model`].channelId;
  }

  if (sigItem[`rx${modelKey}Model`]) {
    rxComp = sigItem[`rx${modelKey}Model`].component;
    rxChannelId = sigItem[`rx${modelKey}Model`].channelId;
  } else if (sigItem.rcModel) {
    rxComp = sigItem.rcModel.component;
    rxChannelId = sigItem.rcModel.channelId;
  }

  return { txComp, txChannelId, rxComp, rxChannelId }
}

function saveIbisModel({
  modelInfo,
  model,
  dirType,
  libraryModels
}) {

  if (!model.jitters || !model.jitters.length) {
    model.jitters = setDefaultNoAmiJitters(dirType) || [];
  }

  const index = libraryModels.findIndex(item => item.pin.pin === modelInfo.pin.pin);
  //Pin
  model.pin = modelInfo.pin.pin || "";
  model.invPin = modelInfo.invPin.pin || "";
  //update dataType to default
  model.dataType = "Typ";

  let pinModel = modelInfo.pinModel, invPinModel = modelInfo.invPinModel;

  if (!pinModel) {
    pinModel = modelInfo.pinModels[0].name;
    libraryModels[index].pinModel = pinModel;
  }

  if (!invPinModel) {
    invPinModel = modelInfo.invPinModels[0].name;
    if (pinModel && modelInfo.invPinModels.find(it => it.name === pinModel)) {
      invPinModel = pinModel;
    }
    libraryModels[index].invPinModel = invPinModel;
  }

  model.modelName = pinModel;
  model.invModelName = invPinModel;
  return { model, libraryModels };
}

function judgeJittersUnitUpdate(adsConfig) {
  const signals = adsConfig.signals || [];
  const findSignal = signals[0] || null;

  if (!findSignal || findSignal.IbisHasAMI === "no") {
    return false;
  }

  const findTx = signals.find(item => item.txAmiModel && item.txAmiModel.jitters.find(item => item.unit === "Float"));
  const findRx = signals.find(item => item.rxAmiModel && item.rxAmiModel.jitters.find(item => item.unit === "Float"));

  if (findTx || findRx) {
    return true;
  }
  return false;
}

function updateAdsJittersUnit(adsConfig) {
  for (let signal of adsConfig.signals || []) {

    const findTxJitterIndex = signal.txAmiModel.jitters.findIndex(item => item.name === TX_SJ_FREQUENCY);

    if (signal.txAmiModel && findTxJitterIndex > -1) {
      const txItem = signal.txAmiModel.jitters[findTxJitterIndex];
      signal.txAmiModel.jitters[findTxJitterIndex].unit = setJittersUnit(txItem.name, txItem.value, txItem.unit);
    }

    const findRxJitterIndex = signal.rxAmiModel.jitters.findIndex(item => item.name === RX_NOISE);

    if (signal.rxAmiModel && findRxJitterIndex > -1) {
      const rxItem = signal.rxAmiModel.jitters[findRxJitterIndex];
      signal.rxAmiModel.jitters[findRxJitterIndex].unit = setJittersUnit(rxItem.name, rxItem.value, rxItem.unit);
    }
  }
  return adsConfig;
}

function setJittersUnit(type, value, unit) {
  switch (type) {
    case TX_SJ_FREQUENCY:
      return parseFloat(value) === 0 ? "GHz" : "Hz";
    case RX_NOISE:
      return "V";
    default: return unit;
  }
}

function updateEQOptionsByType(key, EQ, dirType, serdesType) {
  const options = getEQOptionsByType(key, EQ, false, serdesType);

  for (let item of options) {
    if (dirType === ADS_RX && item.disabled) { continue }
    EQ[item.key] = ["list", "tagsInput"].includes(item.type) ? (item.value || []) : (item.value || "");
    if (item.sub) {
      EQ[item.sub.key] = ["list", "tagsInput"].includes(item.sub.type) ? (item.sub.value || []) : (item.sub.value || "");
    }
  }
  return EQ;
}

function getEQOptionsByType(key, EQ = {}, isDisplay = false, serdesType) {
  switch (key) {
    case FIR_TAPS:
      return FIR_TAPS_OPTIONS;
    case DE_EMPHASIS:
      return DE_EMPHASIS_OPTIONS;
    case true:
    case CTLE:
      if (EQ.CTLEType === "Custom") {
        return [...CTLE_OPTIONS, ...CTLE_CUSTOM_OPTIONS];
      }
      const list = getCtlePresetOptions(EQ[PRESET], serdesType)
      return [...CTLE_OPTIONS, ...list];
    case FFE:
      const ffeList = getFFEOptions(EQ.FFEType)
      return [...FFE_OPTIONS, ...ffeList];
    case DFE:
      if (!EQ.DFEType || (EQ.DFEType === "None" && EQ[DFETAPUSED] !== PROTOCOL)) {
        return [...DFE_OPTIONS];
      }
      const dfeList = getDFEOptions(EQ.DFEType, EQ[PRESET], isDisplay);
      return [...DFE_OPTIONS, ...dfeList];
    default: return [];
  }
}

function updateEQOptionsBySubType(key, EQ, type, dirType, serdesType) {
  const typeKey = dirType === ADS_RX ? ENABLE_CTLE_OR_FFE : "type";

  let optionType = EQ[typeKey],
    preset = EQ[PRESET] || "";

  if (type === DFE_TYPE) {
    EQ = updateDFEOptionsByType(key, EQ, false, null, serdesType)
  } else {
    const options = getEQOptionsByType(optionType, { [type]: key, [PRESET]: preset }, false, serdesType);

    for (let item of options) {
      if (item.key === type || (dirType === ADS_RX && item.disabled) || (type === PRESET && item.key === NUMBER_OF_TAPS)) {
        continue;
      }
      EQ[item.key] = ["list", "tagsInput"].includes(item.type) ? (item.value || []) : (item.value || "");
      if (item.sub) {
        EQ[item.sub.key] = ["list", "tagsInput"].includes(item.sub.type) ? (item.sub.value || []) : (item.sub.value || "");
      }
    }
    EQ[type] = key;
  }
  return EQ;
}

function updateDFEOptionsByType(key, EQ, isOpt = false, prevType, serdesType) {
  if (isOpt && EQ[DFETAPUSED] === PROTOCOL) {
    //protocol is check, change optimized
    return updateDFEProtocolOptions(key, EQ, prevType, serdesType);
  }
  if (key === "Protocol") {
    EQ[DFETAPUSED] = PROTOCOL;
    if (!EQ[PRESET]) {
      const preSetOptions = getPresetOption(serdesType);
      EQ[PRESET] = preSetOptions && preSetOptions.length ? preSetOptions[0].option : ""
    }
  } else {
    delete EQ[DFETAPUSED];
  }
  //set default type
  key = [OPTIMIZED, MANUAL, "None"].includes(key) ? key : OPTIMIZED;
  EQ[DFE_TYPE] = key;

  //update data
  for (let item of DFE_FILTER_OPTIONS) {
    EQ[item] = (DFE_SETUP_OPTIONS.find(it => it.key === item) || {}).value || "";
  }

  if (EQ[DFETAPUSED] === PROTOCOL) {
    EQ = updateTapsByPreset(EQ)
  }
  const info = clearDFEOptionByType({ ...EQ }, EQ);
  EQ = info.EQ;
  return EQ;
}

function updateDFEProtocolOptions(key, EQ, prevType, serdesType) {

  if (key === OPTIMIZED) {
    //Update num of taps
    if (!EQ[NUMBER_OF_TAPS] || EQ[NUMBER_OF_TAPS] === "0") {
      const presets = getPresetOption(serdesType);
      const findPreset = presets.find(item => item.option === EQ[PRESET]);
      const tapNum = findPreset && findPreset.option ? getTapNum(findPreset.option) : "";
      EQ[NUMBER_OF_TAPS] = tapNum === "NO" ? "" : tapNum || "";
    }

    /*  delete EQ[TAPS]; */
  } else if (key === MANUAL) {
    /*    EQ[NUMBER_OF_TAPS] = ""; */

    //update tap values
    //get: HDMI 12Gbps  I = 3dB ~ 8dB
    const specifiedPresets = getHDMIPresetOptions(["12"], ["3", "4", "5", "6", "7", "8"]);
    const findSpecPreset = specifiedPresets.find(item => item.option === EQ[PRESET]);
    //HDMI 12Gbps  I = 3dB ~ 8dB, tap values is -0.025
    if (findSpecPreset) {
      EQ[NUMBER_OF_TAPS] = "1";
      EQ[TAPS] = ["-0.025"];
    }
  }

  if (prevType === "None") {
    //set default
    EQ[DFE_ADAPTIVE_EQUALIZATION] = "no";
    EQ[SLICER_OUTPUT] = "yes";
  }
  EQ[DFE_TYPE] = key;
  return EQ;
}

function updateDFEProtocolOptionsBySetup(key, EQ) {
  const prevType = EQ[DFE_TYPE];

  if (key === NUMBER_OF_TAPS) {
    EQ[DFE_TYPE] = OPTIMIZED;
    /*   delete EQ[TAPS]; */
  }
  if (key === TAPS) {
    EQ[DFE_TYPE] = MANUAL;
    /*   EQ[NUMBER_OF_TAPS] = ""; */
  }
  if (prevType === "None") {
    //set default
    EQ[DFE_ADAPTIVE_EQUALIZATION] = "no";
    EQ[SLICER_OUTPUT] = "yes";
  }
  return EQ;
}

function updateTapsByPreset(EQ) {
  //get: HDMI 12Gbps  I = 3dB ~ 8dB
  const specifiedPresets = getHDMIPresetOptions(["12"], ["3", "4", "5", "6", "7", "8"]);
  const findPreset = specifiedPresets.find(item => item.option === EQ[PRESET]);
  //HDMI 12Gbps  I = 3dB ~ 8dB, tap values is -0.025
  if (findPreset) {
    EQ[DFE_TYPE] = MANUAL;
    EQ[NUMBER_OF_TAPS] = "1";
    EQ[TAPS] = ["-0.025"];
    EQ[DFETAPUSED] = PROTOCOL;
  } else {
    const numberOfTaps = getTapNum(EQ[PRESET]);

    if (numberOfTaps === "NO") {
      for (let item of DFE_ALL_OPTIONS) {
        if (item === PRESET && EQ[ENABLE_CTLE_OR_FFE] && EQ[ENABLE_CTLE_OR_FFE] === CTLE) { continue }
        delete EQ[item];
      }
      EQ[DFE_TYPE] = "None";
      EQ[DFETAPUSED] = PROTOCOL;
    } else {
      EQ[DFE_TYPE] = OPTIMIZED;
      EQ[NUMBER_OF_TAPS] = numberOfTaps;
      EQ[DFETAPUSED] = PROTOCOL;
      delete EQ[TAPS]
    }
  }
  return EQ;
}

function updateEQOptionsByPreset(key, EQ, type, dirType, serdesType) {
  // numberOfTaps
  let preset = EQ[PRESET] || "";
  let options = [];
  //reset DFE EQ
  const DFEEQ = { [DFE_TYPE]: "None" };
  for (let key of DFE_ALL_OPTIONS) {
    if (EQ.hasOwnProperty(key)) {
      DFEEQ[key] = EQ[key]
    }
  }

  if ((EQ.enableCTLEOrFFE && EQ.enableCTLEOrFFE === "CTLE" && EQ.CTLEType && EQ.CTLEType === "Preset")) {
    const presetOptions = getCtlePresetOptions(preset, serdesType);
    EQ = { enableCTLEOrFFE: "CTLE", CTLEType: "Preset" };
    options = [...options, ...presetOptions]
  } else if (EQ.enableCTLE && EQ.CTLEType === "Preset") { // CPHY IBIS RX EQ
    const presetOptions = getCtlePresetOptions(preset, serdesType);
    EQ = { enableCTLE: EQ.enableCTLE, CTLEType: "Preset" };
    options = [...options, ...presetOptions]
  }

  for (let item of options) {
    // Add default values to EQ
    if (item.key === type || (dirType === ADS_RX && item.disabled)) {
      continue;
    }
    EQ[item.key] = ["list", "tagsInput"].includes(item.type) ? (item.value || []) : (item.value || "");
    if (item.sub) {
      EQ[item.sub.key] = ["list", "tagsInput"].includes(item.sub.type) ? (item.sub.value || []) : (item.sub.value || "");
    }
  }
  EQ[type] = key;
  if (DFEEQ[DFETAPUSED] === PROTOCOL) {
    EQ = updateEFEOptionsByPreset({ DFEEQ, EQ, preset: EQ[PRESET] });
  } else {
    EQ = { ...EQ, ...DFEEQ }
  }

  return EQ;
}

function updateEFEOptionsByPreset({ DFEEQ, EQ }) {
  let DFEObj = { DFEType: "None" }
  DFEObj[PRESET] = EQ[PRESET] || DFEEQ[PRESET];

  if ([MANUAL, OPTIMIZED].includes(DFEEQ[DFE_TYPE])
    || (DFEEQ[DFE_TYPE] === "None" && DFEEQ[DFETAPUSED] === PROTOCOL)) {
    //update data
    for (let item of DFE_ALL_OPTIONS) {
      DFEObj[item] = DFEEQ[item] || (DFE_SETUP_OPTIONS.find(it => it.key === item) || {}).value || "";
    }

    if (DFEEQ[DFETAPUSED] === PROTOCOL) {
      DFEObj[DFETAPUSED] = PROTOCOL;
      DFEObj = updateTapsByPreset(DFEObj)
    }
    const newInfo = clearDFEOptionByType(DFEObj, EQ);
    DFEObj = newInfo.DFEObj;
    EQ = newInfo.EQ;
  }
  // update data
  EQ = { ...EQ, ...DFEObj }
  return EQ;
}

function clearDFEOptionByType(DFEObj, EQ) {

  if (DFEObj[DFE_TYPE] === "None") {
    for (let item of DFE_FILTER_OPTIONS) {
      delete EQ[item]
      delete DFEObj[item]
    }
  }
  if (DFEObj[DFE_TYPE] === OPTIMIZED) {
    delete EQ[TAPS]
    delete DFEObj[TAPS]
  }

  if (DFEObj[DFE_TYPE] === MANUAL && DFEObj[DFETAPUSED] !== PROTOCOL) {
    EQ[NUMBER_OF_TAPS] = "";
    DFEObj[NUMBER_OF_TAPS] = "";
  }
  return { DFEObj, EQ };
}

function judgeAMIFlagExist(adsConfig = {}) {
  const signals = adsConfig.signals || [];
  if (signals[0] && !signals[0].IbisHasAMI) {
    return true;
  }
  return false;
}

function updateSignalAMIFlags(adsConfig = {}) {
  const signals = adsConfig.signals || [];
  const findSignal = signals[0] || {};

  let IbisHasAMI = "yes";
  if (findSignal.txAmiModel || findSignal.rxAmiModel) {
    IbisHasAMI = "yes"
  }
  if (findSignal.txModel || findSignal.rxModel) {
    IbisHasAMI = "no"
  }

  for (let signal of adsConfig.signals || []) {
    signal.IbisHasAMI = IbisHasAMI;
  }
  return adsConfig;
}

function updateAdsConfigRXModelEQ(signals) {
  let _signals = JSON.parse(JSON.stringify(signals));

  _signals.forEach(signal => {
    // EQ only exists in IBIS/Termination, but AMI does not have EQ
    // Add calculated values after adding poles
    if (signal.rxModel && !signal.rcModel) {
      const EQ = signal.rxModel && signal.rxModel.EQ ? signal.rxModel.EQ : {};
      if (EQ && EQ.CTLEType === "Default") {
        let newEQ = {
          ...EQ,
          CTLEType: "Custom",
          prefactor: EQ.dcGain,
          zeros: [EQ.zero1],
          poles: [EQ.pole1, EQ.pole2],
          numberOfPoles: '2',
          numberOfZeros: '1'
        }
        newEQ = getValueFormEquation(newEQ)

        delete newEQ.pole1;
        delete newEQ.pole2;
        delete newEQ.zero1;
        delete newEQ.dcGain;

        signal.rxModel.EQ = newEQ;
      } else if (EQ && EQ.CTLEType === "Custom" && EQ.dcGain) {
        let newEQ = {
          ...EQ,
          prefactor: EQ.dcGain,
        };
        newEQ = getValueFormEquation(newEQ)
        delete newEQ.dcGain;
        signal.rxModel.EQ = newEQ;
      }
    } else if (signal.rcModel && !signal.rxModel && !signal.rxAmiModel) {
      if (signal.rcModel && !signal.rcModel.jitters) {
        signal.rcModel.jitters = setDefaultNoAmiJitters(ADS_RX)
      }
      if (signal.rcModel && !signal.rcModel.EQ) {
        signal.rcModel.EQ = { [ENABLE_CTLE_OR_FFE]: "None", DFEType: "None" }
      }
    }
  })
  return _signals
}

function getEQDFEValue(EQ, itemData) {
  let disabled, value;
  switch (itemData.key) {
    case DFE_TYPE:
      value = EQ[DFETAPUSED] === PROTOCOL ? "Protocol" : EQ[DFE_TYPE] === "None" ? "None" : "Custom";
      break;
    case PRESET:
      disabled = EQ[DFETAPUSED] !== PROTOCOL;
      value = EQ[DFETAPUSED] !== PROTOCOL ? "" : EQ[PRESET];
      break;
    case NUMBER_OF_TAPS:
      disabled = EQ[DFETAPUSED] !== PROTOCOL && EQ[DFE_TYPE] === MANUAL;
      value = disabled ? "" : EQ[NUMBER_OF_TAPS]
      break;
    case TAPS:
      disabled = (EQ[DFETAPUSED] !== PROTOCOL && EQ[DFE_TYPE] !== MANUAL)
        /* || (EQ[DFETAPUSED] === PROTOCOL && EQ[DFE_TYPE] === OPTIMIZED) */;
      break;
    case DFE_ADAPTIVE_EQUALIZATION:
    case SLICER_OUTPUT:
      disabled = EQ[DFE_TYPE] === "None" /* && EQ[DFETAPUSED] !== PROTOCOL */;
      break;
    default: break;
  }
  return { disabled, value }
}

function unCheckAmiModelUsePkg({ adsConfig, component, channelId }) {

  for (let signal of adsConfig.signals || []) {
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
    if (!signal[`tx${modelKey}Model`] && !signal[`rx${modelKey}Model`]) {
      continue;
    }

    if (signal[`tx${modelKey}Model`]
      && signal[`tx${modelKey}Model`].component === component
      && (!channelId || signal[`tx${modelKey}Model`].channelId === channelId)
      && !!signal[`tx${modelKey}Model`].usePackage) {
      signal[`tx${modelKey}Model`].usePackage = false;
    }

    if (signal[`rx${modelKey}Model`]
      && signal[`rx${modelKey}Model`].component === component
      && (!channelId || signal[`rx${modelKey}Model`].channelId === channelId)
      && !!signal[`rx${modelKey}Model`].usePackage) {
      signal[`rx${modelKey}Model`].usePackage = false;
    }
  }
  return adsConfig
}

function updateTerminationModelPinMap({ adsConfig, component, pins, prevPin, newPin, channelId }) {
  if (!adsConfig || !adsConfig.signals || !adsConfig.signals.length) {
    return adsConfig;
  }
  let modelComp = null, modelChannelId = null;

  if (
    (adsConfig.controller === component
      && (!channelId || (adsConfig.controllerChannel && channelId === adsConfig.controllerChannel.channelId)))
    ||
    (adsConfig.device === component
      && (!channelId || (adsConfig.deviceChannel && channelId === adsConfig.deviceChannel.channelId)))) {
    modelComp = component;
    modelChannelId = channelId;
  }
  if (!modelComp) {
    return adsConfig;
  }
  const pinNames = pins.map(item => item.pin);
  for (let signal of adsConfig.signals) {
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
    const isUsedRC = !signal[`rx${modelKey}Model`] && signal.rcModel ? true : false;

    if (signal.txCircuitModel
      && signal.txCircuitModel.component === modelComp
      && signal.txCircuitModel.channelId === modelChannelId) {
      signal.txCircuitModel.pairs = updateCirAndRcPairs({ pairs: signal.txCircuitModel.pairs, prevPin, newPin, pinNames, type: signal.txCircuitModel.topology });
    }

    if (signal.rxCircuitModel
      && signal.rxCircuitModel.component === modelComp
      && signal.rxCircuitModel.channelId === modelChannelId) {
      signal.rxCircuitModel.pairs = updateCirAndRcPairs({ pairs: signal.rxCircuitModel.pairs, prevPin, newPin, pinNames, type: signal.rxCircuitModel.topology });
    }

    if (!isUsedRC) {
      continue;
    }
    if ((signal.rcModel && signal.rcModel.component !== modelComp)
      ||
      (modelChannelId && signal.rcModel && signal.rcModel.channelId !== modelChannelId)) {
      continue;
    }
    signal.rcModel.pairs = updateCirAndRcPairs({ pairs: signal.rcModel.pairs, prevPin, newPin, pinNames });
  }
  return adsConfig;
}

function updateCirAndRcPairs({ pairs, prevPin, newPin, pinNames, type }) {

  if (type === SERIES) {
    const index = pairs.findIndex(item => item.pin === prevPin.pin);
    if (index < 0) {
      const prevPairs = JSON.parse(JSON.stringify(pairs.filter(item => pinNames.includes(item))));
      pairs = [];
      for (let pin of pinNames) {
        const findInPair = prevPairs.find(it => it.pin === pin && ["P_DEVICE", "N_DEVICE"].includes(it.position)) || {};
        const findOutPair = prevPairs.find(it => it.pin === pin && ["P_CHANNEL", "N_CHANNEL"].includes(it.position)) || {};
        pairs.push({
          pin,
          node: findInPair.node || "",
          index: findInPair.index,
          position: findInPair.position
        },
          {
            pin,
            node: findOutPair.node || "",
            index: findOutPair.index,
            position: findOutPair.position
          })
      }
    } else {
      for (let pin of pairs) {
        if (pin.pin === prevPin.pin) {
          pin.pin = newPin.pin;
        }
      }
    }
  }
  const index = pairs.findIndex(item => item.pin === prevPin.pin);
  if (index < 0) {
    const prevPairs = JSON.parse(JSON.stringify(pairs.filter(item => pinNames.includes(item))));
    pairs = [];
    for (let pin of pinNames) {
      const findPair = prevPairs.find(it => it.pin === pin) || {};
      pairs.push({
        pin,
        node: findPair.node || "",
        index: findPair.index
      })
    }
  } else {
    pairs[index].pin = newPin.pin;
  }
  return pairs;

}

export {
  getIbisAmiModelList,
  updatePRBSOptions,
  updateAmiSimOptions,
  updateBitRateByProtocolType,
  updateEncoderByProtocolType,
  ibisAMIModelParamsParse,
  RANGE,
  CORNER,
  BOOLEAN,
  LIST,
  updateParamsValueByCorner,
  updateSignalNameInAdsConfig,
  delSignalInAdsConfig,
  getSignalAmiCompBySignalName,
  updateAdsSignalModelType,
  saveIbisModel,
  judgeJittersUnitUpdate,
  updateAdsJittersUnit,
  updateEQOptionsByType,
  getEQOptionsByType,
  updateEQOptionsBySubType,
  judgeAMIFlagExist,
  updateSignalAMIFlags,
  updateAdsConfigRXModelEQ,
  updateEQOptionsByPreset,
  getEQDFEValue,
  updateDFEOptionsByType,
  updateDFEProtocolOptionsBySetup,
  STRING,
  INTEGER,
  FLOAT,
  unCheckAmiModelUsePkg,
  TAP,
  updateTerminationModelPinMap,
  GAUSSIAN,
  PARAMETER_DUAL_DIRAC,
  PARAMETER_DJRJ
}