import MarkLine from "./markLine";
class MarkData {
  constructor() {
    this.marks = [];
    this.removeMarkLine = this.removeMarkLine.bind(this);
  }

  createMarkLine({ plotId, prevX, root, x, padding, height, xValue, curves, targetX, getYValue, getXValue }) {
    const markLine = new MarkLine({
      id: `${plotId}-${prevX}`,
      prevX,
      delMarkLine: this.removeMarkLine,
      getYValue,
      getXValue,
      targetX,
      xValue,
      x,
      padding,
      height
    })
    markLine.drawMarkLine(root);
    markLine.drawMarkNode(curves)
    this.marks.push(markLine);
  }

  removeMarkLine(id) {
    this.marks = this.marks.filter(m => m.id !== id);
  }

  removeMarkOnCurve(hashId) {
    this.marks.forEach(mark => {
      mark.removeNode(hashId);
    })
  }

  clearAll() {
    this.marks.forEach(mark => {
      mark.removeMarkLine()
    });
  }

  redraw({ padding, height }) {
    this.marks.forEach(mark => {
      mark.redrawLine({ padding, height });
      mark.redrawNodes({ padding });
    })
  }
}

export default MarkData;