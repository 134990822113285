import { ANDES_V2, CASCADE, SIERRA } from "../../constants/pageType";
import { getSignalElementType } from "../Andes_v2/channel/signalsIdentification";
import { DriverPoints, getDefaultList, ReceiverPoints } from "../Andes_v2/constants";
import { CPHY } from "../PCBHelper/constants";

export const INSERTIONLOSS = 'insertionLoss', RETURNLOSS = 'returnLoss', COMINSERTION = 'commonInsertionLoss', COMRETURN = 'commonReturnLoss', NEXT = 'next',
  FEXT = 'fext', TDR = 'TDR', SBR = 'SBR', DIFFTOCOM = 'diffToComm', IMPEDANCE = "impedance", CROSSCOUPLING = 'crossCoupling';

function getReportGuideKeys(type) {
  switch (type) {
    case "rocky":
    case SIERRA:
      return [INSERTIONLOSS, RETURNLOSS, NEXT, FEXT]
    case "andes_v2_pre_layout":
    case "andes_v2":
      return [INSERTIONLOSS, RETURNLOSS, COMINSERTION, COMRETURN, NEXT, FEXT, DIFFTOCOM, TDR, SBR]
    case "andes_v2_reference_line":
      return [INSERTIONLOSS, RETURNLOSS, COMINSERTION, COMRETURN, NEXT, FEXT, DIFFTOCOM]
    case "andes_v2_CPHY":
    case "andes_v2_CPHY_pre_layout":
      return [INSERTIONLOSS, RETURNLOSS, COMINSERTION, COMRETURN, DIFFTOCOM, CROSSCOUPLING, TDR, SBR]
    case "andes_v2_CPHY_reference_line":
      return [INSERTIONLOSS, RETURNLOSS, COMINSERTION, COMRETURN, DIFFTOCOM, CROSSCOUPLING]
    case CASCADE:
      return [IMPEDANCE];
    default: return []
  }
}

function getReportGuideValue(type, keys, pointsParams) {
  if (type === "andes_v2" || type === 'andes_v2_pre_layout') {
    const { dataRate, interfaceType } = pointsParams || {}
    return keys.map(key => {
      switch (key) {
        case RETURNLOSS:
        case INSERTIONLOSS:
        case COMINSERTION:
        case COMRETURN:
        case NEXT:
        case FEXT:
        case TDR:
        case SBR:
        case DIFFTOCOM:
        case IMPEDANCE:
          return { name: key, x: [], y: [], points: getDefaultList({ PCIeType: interfaceType, type: key, dataRate }), xDynamicRange: [], yDynamicRange: [], dataRate: dataRate || '16', defaultDataRate: dataRate || '16' }
        default: return {}
      }
    })
  } else if (type === "andes_v2_CPHY" || type === 'andes_v2_CPHY_pre_layout') {
    const { interfaceType, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec } = pointsParams || {};
    return keys.map(key => {
      switch (key) {
        case RETURNLOSS:
        case COMRETURN:
        case TDR:
        case SBR:
        case DIFFTOCOM:
        case IMPEDANCE:
        case CROSSCOUPLING:
          return {
            name: key,
            x: [],
            y: [],
            points: getDefaultList({ PCIeType: interfaceType, type: key, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec }),
            driverPoints: getDefaultList({ PCIeType: interfaceType, type: key, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec, pointType: DriverPoints }),
            receiverPoints: getDefaultList({ PCIeType: interfaceType, type: key, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec, pointType: ReceiverPoints }),
            xDynamicRange: [],
            yDynamicRange: []
          }
        case INSERTIONLOSS:
        case COMINSERTION:
          return {
            name: key,
            x: [],
            y: [],
            points: getDefaultList({ PCIeType: interfaceType, type: key, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec }),
            driverPoints: getDefaultList({ PCIeType: interfaceType, type: key, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec, pointType: DriverPoints }),
            receiverPoints: getDefaultList({ PCIeType: interfaceType, type: key, symbolRate, fLPMax, fMax, fh, fINTMin, cphyInsertionLossSpec, pointType: ReceiverPoints }),
            xDynamicRange: [],
            yDynamicRange: [],
            cphyInsertionLossSpec: 'Short'
          }
        default: return {}
      }
    })
  } else {
    return keys.map(key => {
      switch (key) {
        case RETURNLOSS:
        case INSERTIONLOSS:
        case NEXT:
        case FEXT:
        case TDR:
        case SBR:
        case DIFFTOCOM:
        case IMPEDANCE:
        case CROSSCOUPLING:
          return { name: key, x: [], y: [] }
        default: return {}
      }
    })
  }

}

function getReportAdvanceColumns(tableColumns, number, product) {
  let _referenceLinesColumns = []
  const index = tableColumns.findIndex(item => item.title === 'Reference lines');
  switch (number) {
    case 0:
      _referenceLinesColumns = [...tableColumns];
      break;
    case 1:
      let columns1 = [
        { ...tableColumns[index].children[0], title: 'Times (ns)' },
        { ...tableColumns[index].children[1], title: 'Voltage (V)' }
      ], columns2 = [
        { ...tableColumns[tableColumns.length - 1].children[0], title: 'Times (ns)' },
        { ...tableColumns[tableColumns.length - 1].children[1], title: 'Voltage (V)' }
      ]
      if (product === ANDES_V2) {
        columns1 = [
          { ...tableColumns[index].children[1], title: 'Times (ns)' },
          { ...tableColumns[index].children[0], title: 'Voltage (V)' }
        ];
        columns2 = [
          { ...tableColumns[tableColumns.length - 1].children[1], title: 'Times (ns)' },
          { ...tableColumns[tableColumns.length - 1].children[0], title: 'Voltage (V)' }
        ]
      }
      _referenceLinesColumns = [
        tableColumns[0],
        ...columns1,
        {
          ...tableColumns[tableColumns.length - 1],
          children: [...columns2]
        }
      ]
      break;
    case 2:
      let columns3 = [
        { ...tableColumns[index].children[0], title: 'Times (ns)' },
        { ...tableColumns[index].children[1], title: 'Impedance (Ω)' }
      ], columns4 = [
        { ...tableColumns[tableColumns.length - 1].children[0], title: 'Times (ns)' },
        { ...tableColumns[tableColumns.length - 1].children[1], title: 'Impedance (Ω)' }
      ]
      if (product === ANDES_V2) {
        columns3 = [
          { ...tableColumns[index].children[1], title: 'Times (ns)' },
          { ...tableColumns[index].children[0], title: 'Impedance (Ω)' }
        ];
        columns4 = [
          { ...tableColumns[tableColumns.length - 1].children[1], title: 'Times (ns)' },
          { ...tableColumns[tableColumns.length - 1].children[0], title: 'Impedance (Ω)' }
        ]
      }
      _referenceLinesColumns = [
        tableColumns[0],
        ...columns3,
        {
          ...tableColumns[tableColumns.length - 1],
          children: [...columns4]
        }
      ]
      break;
    default:
      break;
  }
  return _referenceLinesColumns
}

function getValue(name) {
  switch (name) {
    case 'insertionLoss':
      return 'Insertion Loss';
    case 'returnLoss':
      return 'Return Loss';
    case 'commonInsertionLoss':
      return 'Common Mode Insertion Loss';
    case 'commonReturnLoss':
      return 'Common Mode Return Loss';
    case 'next':
      return 'NEXT';
    case 'fext':
      return 'FEXT';
    case 'TDR':
      return 'TDR';
    case 'SBR':
      return 'SBR';
    case 'diffToComm':
      return 'DiffToComm';
    case "impedance":
      return "Impedance";
    case "crossCoupling":
      return "Intra-Lane Cross Coupling"
    default: return ''
  }
}

function getCompByNets(components, nets = []) {
  let compNames = [];
  for (const comp of components) {
    const pin = comp.pins.find(item => nets.includes(item.net));
    if (pin) {
      compNames.push(comp.name);
    }
  }
  return compNames;
}

function getCPHYDefaultConfig(components, signals) {
  let driver = new Set(), receiver = new Set();
  for (const signal of signals) {
    const nets = getSignalElementType(CPHY).map(key => signal[key]).flat();
    const compNames = getCompByNets(components, nets);
    if (compNames.length > 1) {
      driver.add(compNames[0]);
      receiver.add(compNames[1]);
    }
  }
  return {
    symbolRate: 2.5,
    fh: 1.25,
    fMax: 1.875,
    fINTMin: 0.45,
    fLPMax: 0.01,
    driver: Array.from(driver),
    receiver: Array.from(receiver)
  };
}

export {
  getReportGuideValue,
  getReportGuideKeys,
  getReportAdvanceColumns,
  getValue,
  getCPHYDefaultConfig
}