import React, { Component, Fragment } from 'react';
import { Input, Select } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { CONNECTOR, IC, RES } from '../../../../../constants/componentType';
import UnitAddonAfter from '../../../../../components/UnitAddonAfter';
import { checkRLCValue, numberCheck } from '../../../../../services/helper/dataProcess';
import { splitValueUnit } from '../../../../../services/helper/RLCValue';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

const Option = Select.Option;
const RES_INPUT = [{ key: "value", title: "Value" }, { key: "voltage", title: "Voltage" }]
const UNITS = [{ title: 'mΩ', key: "m" }, { title: 'Ω', key: "1" }, { title: 'KΩ', key: 'K' }, { title: 'MΩ', key: 'M' }];

class CompInfoPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      type: "",
      value: "",
      unit: "1",
      voltage: "",
      error: [],
      compInfo: null
    };
    this.dialogRoot = document.getElementById(`pre-layout-schematic-${props.id}`);
  }

  componentDidMount = () => {
    this.initComp()
  }

  componentDidUpdate = (prevProps) => {
    const { openedCompUpdate } = this.props;
    if (prevProps.comp && openedCompUpdate && openedCompUpdate !== prevProps.openedCompUpdate) {
      this.saveComp()
      this.initComp()
      this.props.changeOpenCompStatus()
    }
  }

  initComp = () => {
    const { comp = {} } = this.props;
    const valueObj = splitValueUnit(comp.value || "");
    const value = valueObj.value, unit = valueObj.unit;
    this.setState({
      name: comp.name,
      type: comp.type,
      value,
      unit: unit || '1',
      voltage: comp.voltage,
      compInfo: JSON.parse(JSON.stringify(comp))
    })
  }

  saveComp = () => {
    const { name, type, value, unit, voltage, compInfo, error } = this.state;
    if (error && error.length) {
      return;
    }
    const { comp = {} } = this.props;
    let _comp = {
      prevName: compInfo.name,
      name,
      type,
      comps: comp.comps
    }
    if (compInfo.type === RES) {
      const _value = unit !== '1' ? `${value || 0}${unit}` : value || 0;
      _comp.value = checkRLCValue(_value);
      _comp.voltage = voltage;
    }
    this.setState({
      compInfo: _comp
    })
    this.props.saveComp(_comp, comp.comps)
  }

  close = () => {
    const { error } = this.state;
    if (error && error.length) {
      return;
    }
    this.saveComp();
    this.props.closePanel();
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  changeValue = (e, type) => {
    const { error } = this.state;
    this.setState({
      [type]: e.target.value,
      error: error.filter(e => e.type !== type)
    });
  }

  inputCheck = (type) => {
    let check = null;
    if (type === "name") {
      const { components, comp } = this.props;
      const value = this.state[type];
      check = checkNameFormat(value);
      if (!check) {
        const findComp = components.find(item => item.name === value && item.name !== comp.name);
        if (findComp) {
          check = "This name is already exist!";
        }
      }
    } else if (["value", "voltage"].includes(type)) {
      const value = this.state[type];
      check = numberCheck(value);
    }
    if (check) {
      this.setState({
        error: [
          ...this.state.error,
          { type, error: check }
        ]
      })
    } else {
      this.saveComp()
    }
  }

  changeUnit = (key) => {
    this.setState({
      unit: key
    })
  }

  selectChange = (value, type) => {
    this.setState({
      [type]: value
    }, () => {
      this.saveComp()
    })
  }

  nameRender = () => {
    const { name } = this.state;
    const { comp } = this.props;
    return <div className="sierra-pre-layout-comp-info-item">
      <span>Name</span>
      <Input
        className="aurora-input"
        value={comp.comps && comp.comps.length ? comp.comps && comp.comps.join(", ") : name}
        disabled={comp.comps && comp.comps.length ? true : false}
        onChange={(e) => this.changeValue(e, "name")}
        onBlur={() => this.inputCheck("name")}
        onKeyDown={this.keyDown}
      />
    </div>
  }

  typeRender = () => {
    const { type } = this.state;
    const { comp } = this.props;
    if (![IC, CONNECTOR].includes(comp.type)) {
      return null;
    }
    return <Fragment>
      <div className="sierra-pre-layout-comp-info-item">
        <span>Type</span>
        <Select
          value={type}
          disabled={comp.comps && comp.comps.length ? true : false}
          onChange={(key) => this.selectChange(key, "type")}
          className='aurora-select'
          popupClassName='aurora-select-dropdown'
          getPopupContainer={() => document.getElementById('root')}
        >
          {[IC, CONNECTOR].map(item =>
            <Option key={item} >{item}</Option>
          )}
        </Select>
      </div>
    </Fragment>
  }

  valueRender = () => {
    const { unit } = this.state;
    const { comp } = this.props;
    if (comp.type === RES) {
      return <Fragment>
        {RES_INPUT.map(item => (
          <div className="sierra-pre-layout-comp-info-item" key={item.key}>
            <span>{item.title}</span>
            <Input
              className="aurora-input"
              placeholder={item.key}
              value={this.state[item.key]}
              onChange={(e) => this.changeValue(e, item.key)}
              onBlur={() => this.inputCheck(item.key)}
              onKeyDown={this.keyDown}
              addonAfter={item.key === "value" ? UnitAddonAfter({
                unit,
                changeUnit: (unit) => this.changeUnit(unit),
                list: UNITS
              }) : "V"}
            />
          </div>
        ))}
      </Fragment>
    }
  }


  render() {
    const { openElements, id } = this.props;
    const className = openElements ? "sierra-pre-layout-comp-info-ele-panel" : "";
    const { error } = this.state;
    const content = (
      <Panel
        className={`sierra-pre-layout-comp-info-panel sierra-panel ${className}`}
        title="Component Setup"
        zIndex={2000}
        width={250}
        minWidth={200}
        minHeight={200}
        position='panel-top-left'
        redirectDom={`pre-layout-schematic-${id}`}
        isRedirectDomId={true}
        onCancel={this.close}
        overflow='hidden'
        draggable
      >
        <div className='sierra-pre-layout-comp-info-content'>
          {this.nameRender()}
          {this.typeRender()}
          {this.valueRender()}
          {error && error[0] ? <span className='aurora-model-name-error-msg'>{error[0].error}</span> : null}
        </div>
      </Panel >)

    return createPortal(content, this.dialogRoot);
  }
}

export default CompInfoPanel;