// class CsUnits{
//
// private:
//     CsUnitList mUnit;
//     string mType;
//     string mName;
//     double mFactor;
// ...    
// CsUnits::CsUnits()
// CsUnits::CsUnits(string type, string name, double factor)
var CsUnits = function (strType, strName, dblFactor) {
  this.mUnit = "Length_m";
  this.mType = strType == undefined ? null : strType;
  this.mName = strName == undefined ? null : strName;
  this.mFactor = dblFactor == undefined ? 1.0 : dblFactor;
};

CsUnits.CsUnitList = {
  Length_m: "m",
  Length_cm: "cm",
  Length_mm: "mm",
  Length_um: "um",
  Length_inch: "inch",
  Length_mil: "mil",
  Length_mils: "mil",
  Unknown: "Unknown"
};

// static string CsUnits::UnitsToString(CsUnitList &unit);
CsUnits.UnitsToString = function (csUnit) {
  return CsUnits.CsUnitList[csUnit];
}; // CsUnits.UnitFactor

// static CsUnitList CsUnits::tringToUnits(string &unitStr);
CsUnits.StringToUnits = function (strUnit) {

  if (strUnit == "M") {
    return "Length_um";
  }

  switch (strUnit.toLowerCase()) {
    case "i":
    case "mil":
    case "mils":
      return "Length_mils";
    case "inch":
    case "inches":
      return "Length_inch";
    case "u":
    case "um":
      return "Length_um";
    case "mm":
      return "Length_mm";
    case "cm":
      return "Length_cm";
    case "m":
      return "Length_m";
    default:
      return "Unknown";
  };
}; // CsUnits.StringToUnits

// static double CsUnits::UnitFactor(CsUnitList unit);
CsUnits.UnitFactor = function (csUnit) {
  switch (csUnit) {
    case "Length_inch":
      return 25.4;
    case "Length_mils":
      return 0.0254;
    case "Length_cm":
      return 10.0;
    case "Length_mm":
      return 1.0;
    case "Length_um":
      return 1.0e-3;
    case "Length_m":
      return 1000.0;
    default:
      return 0.0;
  }
}; // CsUnits.UnitFactor

// static double CsUnits::Convert(double fromVal, CsUnitList fromUnit, CsUnitList toUnit);
CsUnits.Convert = function (dblFromVal, csUnitFrom, csUnitTo) {
  var fromFactor = CsUnits.UnitFactor(csUnitFrom);
  var toFactor = CsUnits.UnitFactor(csUnitTo);
  return dblFromVal * fromFactor / toFactor;
};

// static double CsUnits::ConvertFactor(CsUnitList fromUnit, CsUnitList toUnit);
CsUnits.ConvertFactor = function (csUnitFrom, csUnitTo) {
  var fromFactor = CsUnits.UnitFactor(csUnitFrom);
  var toFactor = CsUnits.UnitFactor(csUnitTo);
  return fromFactor / toFactor;
};

export default CsUnits;