import React, { Component, Fragment } from 'react';
import { CloseOutlined, DownloadOutlined, EyeOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Checkbox, Tooltip, ColorPicker } from 'antd';
import { downloadResultFile } from '@/services/PDN'
import { downloadFile } from '@/services/helper/downloadHelper'
import { TARGET, RESULTS, IMPORT, HISTORY } from '../../constants';

const CheckboxGroup = Checkbox.Group;

class fileContent extends Component {

  fileDownload = (e, file, type) => {
    e.stopPropagation();
    let filename;
    const { verificationId } = this.props;
    let path = file.name;
    if (type && type === 'import') {
      path = `import/${file.name}`;
    }
    downloadResultFile(verificationId, path).then(res => {
      if (res) {
        let content = res;
        filename = file.name;
        downloadFile(content, filename)
      }
    });
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  getDefault = (file, portSelect) => {
    const { changePort, colorChange, type } = this.props;
    return <CheckboxGroup
      value={portSelect[file.id]}
      key={file.id}
      onChange={(values) => changePort(values, file.id, file.type, type)}
    >
      {
        file.ports ? file.ports.map((port, portIndex) =>
          <Fragment key={port.name}>
            {
              file.matrix[portIndex].map((matrix, matrixIndex) =>
                <Checkbox
                  key={`${file.id}::${portIndex}::${matrixIndex}`}
                  value={`${file.id}::${portIndex}::${matrixIndex}`}
                  className='pdn-result-checkbox-group'
                >
                  <span className='pdn-result-checkbox-group-name'>
                    <ColorPicker
                      value={matrix.color}
                      className='aurora-color-picker-small'
                      style={{ verticalAlign: 'middle' }}
                      onChange={(value) => colorChange(value, { id: file.id, portIndex, matrixIndex })}
                      size='small'
                      onClick={(e) => this.stopPropagation(e)}
                    />
                    <label htmlFor={`${portIndex}::${matrixIndex}`} className='pdn-result-checkbox-label'>
                      {`Z(${portIndex + 1},${matrixIndex + 1})`}
                    </label>
                  </span>
                  <span className='pdn-result-curve-current' style={{ color: matrix.color }}>{matrix.current}</span>
                </Checkbox>
              )
            }
          </Fragment>
        ) : null
      }
    </CheckboxGroup>
  }

  getMultiPort = (file, portSelect) => {
    const { changePort, colorChange, portNameClick, type } = this.props;
    return (
      <CheckboxGroup
        value={portSelect[file.id]}
        key={file.id}
        onChange={(values) => changePort(values, file.id, file.type, type)}
      >
        {file.ports ? file.ports.map((port, portIndex) =>
          <Fragment key={port.name}>
            <div
              onClick={() => portNameClick(file, portIndex)}
              className='pdn-port-name'
            >
              {
                port.display ? <DownOutlined className='pdn-file-expand-icon' /> : <RightOutlined className='pdn-file-expand-icon' />
              }
              <span>Port {portIndex + 1} ({port.name})</span>
            </div>
            <div style={{ display: port.display ? 'block' : 'none' }} className='pdn-multi-port-group'>
              {
                file.matrix[portIndex].map((matrix, matrixIndex) => {
                  return matrix.display && <Checkbox
                    key={`${file.id}::${portIndex}::${matrixIndex}`}
                    value={`${file.id}::${portIndex}::${matrixIndex}`}
                    className='pdn-result-checkbox-group'
                  >
                    <span className='pdn-result-checkbox-group-name'>
                      <ColorPicker
                        value={matrix.color}
                        className='aurora-color-picker-small'
                        style={{ verticalAlign: 'middle' }}
                        onChange={(value) => colorChange(value, { id: file.id, portIndex, matrixIndex })}
                        size='small'
                        onClick={(e) => this.stopPropagation(e)}
                        id={`col::${file.id}::${portIndex}::${matrixIndex}`}
                      />
                      <label htmlFor={`${portIndex}::${matrixIndex}`} className='pdn-result-checkbox-label'>
                        {`Z(${portIndex + 1},${matrixIndex + 1}) ${portIndex + 1 !== matrixIndex + 1 ? `${(file.ports[matrixIndex].name)}` : ''}`}
                      </label>
                    </span>
                    <span className='pdn-result-curve-current' style={{ color: matrix.color }}>{matrix.current}</span>
                  </Checkbox>
                })}
            </div>
          </Fragment>
        ) : null}
      </CheckboxGroup>
    );
  }

  getContent = () => {
    const { type, file, portSelect } = this.props;
    switch (type) {
      case TARGET:
      case IMPORT:
        return this.getDefault(file, portSelect)
      case RESULTS:
      case HISTORY:
        return file.ports && file.ports.length > 1 ? this.getMultiPort(file, portSelect) : file.ports && file.ports.length === 1 ? this.getDefault(file, portSelect) : null
      default: return null;
    }
  }

  render() {
    const { file, fileIndex, fileNameClick, type, deleteFile, showOptimization, time } = this.props;
    let fileClassName = null;
    if (type === RESULTS && file.type === 'opt') {
      fileClassName = 'span-file-name-opt';
    } else if (type === IMPORT) {
      fileClassName = 'span-import-file-name';
    } else if (type === HISTORY) {
      if (file.type === 'opt') {
        fileClassName = 'span-history-file-name-opt';
      } else {
        fileClassName = 'span-history-file-name';
      }
    }
    else {
      fileClassName = 'span-file-name';
    }
    return (
      <div key={file.id} style={type === HISTORY ? { display: 'block' } : {}}>
        <Row key={file.id} className='pdn-result-file-name'>
          <Col span={24} onClick={() => fileNameClick(file.id)}>
            {file.show ? <DownOutlined className="file-expand-icon" /> : <RightOutlined className="file-expand-icon" />}
            <span className={fileClassName} title={file.name}>{file.name}</span>
            {(type === RESULTS || type === HISTORY) && file.type === 'opt' && <Tooltip title='Optimization' overlayClassName='aurora-tooltip' getPopupContainer={() => document.getElementById('pdn-result-opt-icon')}>
              <EyeOutlined
                className='pdn-result-opt-icon'
                onClick={(e) => showOptimization(e, time)}
                id='pdn-result-opt-icon' />
            </Tooltip>}
            {type === IMPORT && <CloseOutlined
              className="file-delete-icon"
              onClick={(e) => deleteFile(e, file.name, file.id)} />}
            {type !== HISTORY && <DownloadOutlined
              className="file-download-icon"
              onClick={(e) => this.fileDownload(e, file, type)} />}
          </Col>
        </Row>
        <Row
          key={`${file.id}_${fileIndex}`}
          style={{ display: file.show ? 'block' : 'none' }}
          className='pdn-result-ports-list'
        >
          {this.getContent()}
        </Row>
      </div>
    );
  }
}

export default fileContent;