import {
  GET_LAYOUT_CHECKOUT,
  SAVE_CURRENT_DESIGN,
  SAVE_CURRENT_PROJECT_PCB,
  START_LAYOUT_CHECK,
  UPDATE_DESIGN_MONITOR_INFO,
  SAVE_INFO,
  CANCEL_LAYOUT_CHECK,
  SAVE_SIMULATION_DESIGNS,
  CHANGE_DESIGN,
  SAVE_RESULT,
  CHANGE_PRODUCT
} from './actionType'

export const getLayoutCheckoutFlow = (designId) => ({
  type: GET_LAYOUT_CHECKOUT,
  designId
})

export const saveCurrentProjectPCB = (designList) => ({
  type: SAVE_CURRENT_PROJECT_PCB,
  designList
})

export const saveCurrentDesign = (designId) => ({
  type: SAVE_CURRENT_DESIGN,
  designId
})

export const startLayoutSimulation = (designId, product) => ({
  type: START_LAYOUT_CHECK,
  designId,
  product
})

export const updateDesignMonitor = ({ designId, info }) => ({
  type: UPDATE_DESIGN_MONITOR_INFO,
  designId,
  info
})

export const saveLayoutInfo = (params) => ({
  type: SAVE_INFO,
  params
})

export const cancelCheck = (designId) => ({
  type: CANCEL_LAYOUT_CHECK,
  designId
})

export const saveSimDesigns = (designIds) => ({
  type: SAVE_SIMULATION_DESIGNS,
  designIds
})

export const changeLayoutDesign = (designId) => ({
  type: CHANGE_DESIGN,
  designId
})

export const updateLayoutResult = (designId, result) => ({
  type: SAVE_RESULT,
  designId,
  result
})

export const changeProduct = (product) => ({
  type: CHANGE_PRODUCT,
  product
})