import React from "react";
import { createPortal } from "react-dom";
import { Tag, Tooltip, List, Input } from 'antd';
import { SearchOutlined, SwapOutlined } from '@ant-design/icons';
import Panel from "../../../../components/Panel";
import { getDisplaySignalPorts } from "../../../../services/Andes_v2/results/sparameter/dataHelper";

class TxAndRxPortSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      searchValue: '',
      signalPorts: props.signalPorts,
      processValue: ''
    }

    this.dialogRoot = document.getElementById('root');
  }

  onInputSearchValue = (e) => {
    this.setState({ processValue: e.target.value })
  }

  onSearch = () => {
    const { processValue } = this.state;
    this.setState({ searchValue: processValue })
  }

  swapPort = (signal, index) => {
    const { signalPorts } = this.state;
    const signalPortIndex = signalPorts.findIndex((item) => item.signal === signal)
    const { txPort, rxPort } = signalPorts[signalPortIndex];
    let _txPort = [...txPort], _rxPort = [...rxPort];
    _txPort[index] = rxPort[index];
    _rxPort[index] = txPort[index];
    const _signalPorts = [...signalPorts];
    _signalPorts[signalPortIndex] = {
      ..._signalPorts[signalPortIndex],
      txPort: _txPort,
      rxPort: _rxPort
    }
    this.setState({ signalPorts: _signalPorts }, () => {
      this.props.changeRxCentricMode("signalPorts", _signalPorts)
    })
  }

  render() {
    const { components } = this.props;
    const { searchValue, signalPorts } = this.state;
    const _signalPorts = signalPorts.filter((item) => {
      return !searchValue ? true : (new RegExp(searchValue, 'i')).test(item.signal)
    })
    const { portList, txWidth, rxWidth } = getDisplaySignalPorts(_signalPorts, components);
    const content = <Panel
      className='result-reference-panel'
      title="Tx And Rx Port Setting"
      width={600}
      draggable
      minHeight={500}
      zIndex={3000}
      position='panel-center'
      onCancel={() => this.props.closePanel(signalPorts)}
    >
      <div className="port-setting-panel-content">
        <Input
          className="port-setting-panel-search"
          placeholder="Search Signal"
          size="small"
          onBlur={this.onSearch}
          onChange={this.onInputSearchValue}
          addonAfter={<SearchOutlined onClick={this.onSearch} />}
        />
        <List
          dataSource={portList}
          className="port-setting-list"
          renderItem={(item, index) => {
            return <List.Item>
              <div key={`${item.signal}-${index}`}>
                <div className="port-setting-signal-name" title={`Signal: ${item.signal}`}>{item.signal}</div>
                {item.ports.map((it, _index) => {
                  return <div className="port-setting-box" key={it.leftPortText + _index}>
                    <span className="port-box">
                      <span className="port-name" title={`Port: ${it.leftPortText}`} style={{ width: txWidth }}>{it.leftPortText}</span>
                      <Tag color={it.leftType === "TX" ? "cyan" : "orange"} className="tag">{it.leftType}</Tag>
                    </span>
                    <Tooltip title="Exchange of the Port type">
                      <SwapOutlined className="swap-icon" onClick={() => this.swapPort(item.signal, _index)} />
                    </Tooltip>
                    <span className="port-box">
                      <span className="port-name" title={`Port: ${it.rightPortText}`} style={{ width: rxWidth }}>{it.rightPortText}</span>
                      <Tag color={it.rightType === "TX" ? "cyan" : "orange"} className="tag">{it.rightType}</Tag>
                    </span>
                  </div>
                })}
              </div>
            </List.Item>
          }}
        />
      </div>
    </Panel>
    return createPortal(content, this.dialogRoot);
  }
}

export default TxAndRxPortSetting;