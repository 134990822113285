import CeShapeObject from './CeShapeObject';
import CeVia from './CeVia';
import CeObjectManager from './CeObjectManager';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeSymbolManager = function () {
  this.mShapeList = new CeObjectManager();
  this.mViaList = new CeObjectManager();
};

CeSymbolManager.prototype.ClearAll = function () {
  this.mShapeList.ClearAll();
  this.mViaList.ClearAll();
}

CeSymbolManager.prototype.SetShapeList = function (shapeList) {
  for (var i = 0; i < shapeList.length; i++) {
    this.mShapeList.Add(shapeList[i]);
  }
}

CeSymbolManager.prototype.GetShapeGeomObj = function (id) {
  var csObj = this.mShapeList.GetCsObject(id);
  return csObj;
}

CeSymbolManager.prototype.GetViaGeomObj = function (id) {
  var csObj = this.mViaList.GetCsObject(id);
  return csObj;
}

CeSymbolManager.prototype.AddVia = function (newVia) {
  var objList = this.mViaList.GetAllObjects();
  for (var i = 0; i < objList.length; i++) {
    if (newVia.equals(objList[i])) {
      return objList[i].GetID();
    }
  }
  var retID = this.mViaList.Add(newVia);
  var name = "Via_" + String.valueOf(retID + 1);
  newVia.SetName(name);

  return retID;
}

CeSymbolManager.prototype.WriteIntoIODataNode = function (symbolBlk) {

  // Shape list block
  var shapesBlk = new CeIODataBlock("ShapeList");
  var shapeList = this.mShapeList.GetAllObjects();
  for (var i = 0; i < shapeList.length; i++) {
    shapeList[i].WriteIntoIODataNode(shapesBlk);
  }
  symbolBlk.AddBlockNode(shapesBlk);

  // Via list block
  var viaListBlk = new CeIODataBlock("ViaList");
  var objList = this.mViaList.GetAllObjects();
  for (var i = 0; i < objList.length; i++) {
    var dataItem = objList[i].WriteIntoIODataNode();
    viaListBlk.AddBlockNode(dataItem);
  }
  symbolBlk.AddBlockNode(viaListBlk);

}

CeSymbolManager.prototype.ReadFromIODataNode = function (symbolBlk) {
  var shapesBlk = symbolBlk.GetSubBlock("ShapeList");
  var shapeBlkNodes = shapesBlk.GetBlockNodes();
  for (var i = 0; i < shapeBlkNodes.length; i += 2) {
    var geomObj = new CeShapeObject();
    if (geomObj.ReadFromIODataNode(shapeBlkNodes[i], shapeBlkNodes[i + 1]))
      this.mShapeList.Add(geomObj);
  }

  var viaListBlk = symbolBlk.GetSubBlock("ViaList");
  var viaBlkList = viaListBlk.GetAllSubBlocks();
  for (var i = 0; i < viaBlkList.length; i++) {
    var viaObj = new CeVia();
    if (viaObj.ReadFromIODataNode(viaBlkList[i])) {
      this.mViaList.Add(viaObj);
    }
  }
}

CeSymbolManager.prototype.AddShape = function (name, id, geom) {
  var geomObj = new CeShapeObject();
  geomObj.SetData(name, id, geom);
  this.mShapeList.Add(geomObj);
}

CeSymbolManager.prototype.GetViaIDByData = function (viaData) {
  var objList = this.mViaList.GetAllObjects();
  for (var i = 0; i < objList.length; i++) {
    if (viaData.equals(objList[i])) {
      return viaData.GetID();
    }
  }
  return -1;
}

CeSymbolManager.prototype.GetNextNewViaID = function () {
  return this.mViaList.TakeNextID();
}

export default CeSymbolManager;