import { getLibraryMap, getLibraryFolder, getLibraryFolderInfo, editLibraryFolder, deleteLibraryFolder, getLibraryTree } from "../../api/v2/libraryCtrl";
import apiProcess from "../../api/utility";

export function getSierraLibraryMap() {
  return apiProcess(getLibraryMap, 'sierra');
}

export function getSierraLibraryFolder(folderId, type) {
  return apiProcess(getLibraryFolder, { product: 'sierra', folderId, type })
}

export function getSierraLibraryFolderInfo(folderId) {
  return apiProcess(getLibraryFolderInfo, folderId)
}

export function editSierraLibraryFolder({ id, name, parentId, type }) {
  return apiProcess(editLibraryFolder, { product: 'sierra', folder: { id, name, parentId, type } })
}

export function deleteSierraLibraryFolder(folderIds) {
  return apiProcess(deleteLibraryFolder, { product: 'sierra', folderIds })
}

export function getSierraLibraryTree(type) {
  return apiProcess(getLibraryTree, { product: 'sierra', type })
}