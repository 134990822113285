import { scaleConversion } from "../../helper/numberHelper";
import NP from 'number-precision';
import { MANUAL, OPTIMIZED, TAPS, getTapNum } from "./constants";

function findValueFromArray(list, number) {
  //Obtain the absolute value of the position data corresponding to the array
  if (list && list.length > number) {
    let value = JSON.parse(JSON.stringify(list[number]))
    return value > 0 ? value : -value;
  }
  return 0;
}

function calculationPrefactorValue(preset, dcGainValue, acGainValue, polesValue, zeroValue) {
  const wp1 = findValueFromArray(polesValue, 0),
    wp2 = findValueFromArray(polesValue, 1),
    wp3 = findValueFromArray(polesValue, 2),
    wp4 = findValueFromArray(polesValue, 3);
  const wz = findValueFromArray(zeroValue, 0);

  const presetType = getPresetType(preset)
  switch (presetType) {
    case 'PCIE Gen3':
    case 'PCIE Gen4':
    case 'USB3 Gen2':
    case 'HDMI 3,6,8,10Gbps':
    case 'HDMI 12Gbps':
      return acGainValue * wp2;
    case 'PCIE Gen5':
      return wp1 * wp3 * wp4 / wz;
    case 'CPHY':
    case 'USB3 Gen1':
      return dcGainValue * wp1 * wp2 / wz;
    default: return 0;
  }
}

function getPresetType(preset) {
  const data = preset.split(' ');
  let presetType = ''
  // judge cphy preset
  if (preset.startsWith('MIPI CPHY')) {
    presetType = 'CPHY';
  } else if (data && data.length > 0) {
    presetType = `${data[0]} ${data[1]}`
  }
  return presetType
}

function calculationZerosInAngularSpeed(zeros, preset = "", dcGainValue, acGainValue, polesValue = []) {
  let newZeroList = [];
  const wp1 = findValueFromArray(polesValue, 0),
    wp2 = findValueFromArray(polesValue, 1);

  const presetType = getPresetType(preset)
  switch (presetType) {
    case 'PCIE Gen3':
    case 'PCIE Gen4':
      let zero = -dcGainValue / acGainValue * wp1;
      newZeroList.push(zero);
      break;
    case 'PCIE Gen5':
      let zeroList = getCalculationFrequencyValue(zeros)
      let zero1 = -dcGainValue * wp2;
      newZeroList = [...zeroList, zero1];
      break;
    case 'USB3 Gen1':
      let zeroListUSB = getCalculationFrequencyValue(zeros);
      newZeroList = [...zeroListUSB];
      break;
    case 'USB3 Gen2':
      let zeroUsb = -dcGainValue / acGainValue * wp1;
      newZeroList.push(zeroUsb);
      break;
    case 'HDMI 3,6,8,10Gbps':
    case 'HDMI 12Gbps':
      const zeroHDMI = -wp1 / acGainValue;
      newZeroList.push(zeroHDMI);
      break;
    case 'CPHY':
      const zeroCPHYList = getCalculationFrequencyValue(zeros, '1');
      newZeroList = [...zeroCPHYList];
      break;
    default:
      let zeroDefaultList = getCalculationFrequencyValue(zeros);
      newZeroList = [...zeroDefaultList]
      break;
  }
  return newZeroList;
}

function getCalculationFrequencyValue(list, symbol = "-1") {
  const pi = Math.PI;
  const newList = list.map(item => {
    const value = unitConversion(item, "Hz", 'num');
    return symbol * 2 * pi * value;
  })
  return newList
}

function unitConversion(value, unit, format) {
  try {
    const [num, numUnit] = value.split(' ')
    const scale = scaleConversion(unit, numUnit)
    const newNum = NP.times(num, scale).toString()
    return format === 'num' ? newNum : newNum + unit;
  } catch (error) { return NaN }
}

function getValueFormEquation(EQ) {
  const { poles = [], zeros = [], acGain, dcGain, preset, CTLEType, enableCTLEOrFFE, DFETapUsed, DFEType } = EQ;
  let polesInAngularSpeed = [], zerosInAngularSpeed = [], prefactorValue = EQ.prefactor || "", numberOfTapsValue = EQ.numberOfTaps || "";
  let newEQ = JSON.parse(JSON.stringify(EQ))
  if (DFEType === OPTIMIZED && DFETapUsed && DFETapUsed === 'protocol') {
    /* const tapValue = getTapNum(preset);
    numberOfTapsValue = tapValue === 'NO' ? "" : tapValue;
    newEQ.numberOfTaps = numberOfTapsValue; */
    delete newEQ[TAPS];//TODO
  } else if (DFEType === MANUAL) {
    newEQ.numberOfTaps = newEQ.taps && newEQ.taps.length ? newEQ.taps.length.toString() : '0';
  } else if (DFEType === OPTIMIZED) {
    delete newEQ[TAPS];//TODO
  }

  if (enableCTLEOrFFE === 'FFE') { return newEQ }
  if (CTLEType === "Preset") {
    const symbol = getPresetType(preset) === 'CPHY' ? '1' : '-1';
    polesInAngularSpeed = getCalculationFrequencyValue(poles, symbol)
    zerosInAngularSpeed = calculationZerosInAngularSpeed(zeros, preset, dcGain, acGain, polesInAngularSpeed)
    prefactorValue = calculationPrefactorValue(preset, dcGain, acGain, polesInAngularSpeed, zerosInAngularSpeed)

    prefactorValue = Math.round(prefactorValue).toString();
  } else if (CTLEType === "Custom") {
    polesInAngularSpeed = getCalculationFrequencyValue(poles)
    zerosInAngularSpeed = calculationZerosInAngularSpeed(zeros)
  }

  // Rounding
  polesInAngularSpeed = polesInAngularSpeed.map(item => Math.round(item))
  zerosInAngularSpeed = zerosInAngularSpeed.map(item => Math.round(item))
  newEQ = {
    ...newEQ,
    polesInAngularSpeed,
    zerosInAngularSpeed,
    prefactor: prefactorValue
  }
  return newEQ;
}

export {
  getValueFormEquation
}