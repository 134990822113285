import React, { Fragment } from 'react';
import { checkRLCValueFormat } from '@/services/helper/dataProcess';
import { splitComponentName } from '../../../services/helper/splitComponent';
import { RDIMM_TYPES, DDR5_SODIMM, DIMM_DDR_TYPES, LPDDR4 } from '../../../services/Rocky/constants';
import { CONTROLLER, MEMORY } from '../../../constants/componentType';
import { isByte } from '../../../services/Rocky/result/helper/eyediagram/displayHelper';
import { getVirtualCompsErrors } from '../../../services/VirtualComponent/errorCheck';
import { WAVE } from '../../../services/ExtractionPortsHelper';
import { BALL_TYPE_NONE, USE_BALL_LIST } from '../../../services/ExtractionPortsHelper/portTableHelper';
import RockySSNChannelInfo from '@/services/Rocky/SSN/channelsInfo';
import { TOUCHSTONE } from '../../../constants/libraryConstants';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import designConstructor from '../../../services/helper/designConstructor';
import { CAP } from '../../../constants/componentType';

const RLCType = ['RLC', 'Ind', 'Res'];
const ICTypes = ["Controller", "Memory"];

function getErrorCheck(rockyInfo, libraryInfo, extractionType, ddrType, includePdn, isSSN, runType = "ALL") {
  if (!rockyInfo) {
    return;
  }
  let { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList,
    socketSpiceList, socketTouchstoneList, customTouchstoneList, customSpiceList, onDieSpiceList, ibisAmiList } = libraryInfo;
  let error = [];

  const info = rockyInfo.info;
  if (!info) return;
  const ssnInfo = RockySSNChannelInfo.getCurrentSSNInfo(rockyInfo.verificationId);
  const openPatternSweep = ssnInfo && ssnInfo.content && ssnInfo.content.openPatternSweep ? ssnInfo.content.openPatternSweep : false;

  const { Components, PowerNets, Ports_generate_setup_list = [], VirtualComps = [] } = info;

  //check VTT nets voltage
  const VTTNets = info.VTTNets ? info.VTTNets : [];
  let Nets = [];
  PowerNets.forEach(item => {
    if (VTTNets && VTTNets.includes(item.name)) {
      Nets.push(item);
    }
  })
  Nets.forEach(item => {
    if (!item.name) {
      error.push(<p style={{ margin: 0 }}>Power net is not set.</p>)
    } else {
      if (!item.value) {
        error.push(<p style={{ margin: 0 }}>Power net "<span className="font-bold">{item.name}</span>" voltage is not set.</p>)
      }

      if (item.name.match(/gnd/ig) && !item.value) {
        error.push(<p style={{ margin: 0 }}>GND net "<span className="font-bold">{item.name}</span>" voltage is not set.</p>)
      }
    }
  })

  // check driver/receiver model
  const ICs = Components.filter(item => ICTypes.includes(item.type));
  //Only one active dimm component can be set at the same time
  const findDIMMMemoryComps = ICs.filter(item => item.type === MEMORY);
  const findDIMMActiveComps = findDIMMMemoryComps.filter(item => !!item.active);
  const name = rockyInfo && rockyInfo.verificationName ? rockyInfo.verificationName : info.Name ? info.Name : "";
  if ((DIMM_DDR_TYPES.includes(ddrType) && findDIMMMemoryComps.length > 1 && (!findDIMMActiveComps.length || findDIMMActiveComps.length > 1))) {
    error.push(<p style={{ margin: 0 }}>Only one DIMM component can be set as active at the same time.</p>)
  }

  if ((ddrType === LPDDR4 && isByte(name) && findDIMMMemoryComps.length > 1 && (!findDIMMActiveComps.length || findDIMMActiveComps.length > 1))) {
    error.push(<p style={{ margin: 0 }}>Only one Memory component can be set as active at the same time.</p>)
  }

  // // check ssn powerNets
  // if (ssnPowerNets && ssnPowerNets.length) {
  //   error = getPDNPowerErrorCheck(ssnPowerNets, name, error)
  // }

  if (ICs.length > 0) {
    ICs.forEach(comp => {
      if (comp.pkg && comp.pkg.type !== "None") {
        error = checkICCompModel({
          model: comp.pkg,
          comp,
          modelType: "Package",
          touchstoneList: pkgTouchstoneList,
          spiceList: pkgSpiceList,
          ebdList,
          error
        })
      }

      if (comp.socketModel && comp.socketModel.type !== "None") {
        error = checkICCompModel({
          model: comp.socketModel,
          comp,
          modelType: "Socket",
          touchstoneList: socketTouchstoneList,
          spiceList: socketSpiceList,
          error
        })
      }

      const findCompSetup = Ports_generate_setup_list.find(it => it.component === splitComponentName(comp.name)) || {};
      const findPortSetup = findCompSetup.setup || {};
      if (findPortSetup && findPortSetup.portType === "gap" && !comp.gap_size) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" extraction port reference plane distance is not set.</p>)
      }
      if (extractionType === "HFSS" && findPortSetup && findPortSetup.portType === "gap" && comp.gap_size && parseFloat(comp.gap_size) <= 0) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" when the extraction type is "HFSS", the extraction port reference plane distance should be greater than 0.</p>)
      }

      if (findPortSetup && [...USE_BALL_LIST].includes(findPortSetup.portType) && (comp.ball_type && comp.ball_type !== BALL_TYPE_NONE && (!comp.ball_size || !comp.ball_height))) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" extraction port (ball size / ball height) is not set.</p>)
      }
      const isActive = DIMM_DDR_TYPES.includes(ddrType) && comp.active;
      const isCheckModel = comp.type === MEMORY
        && (!RDIMM_TYPES.includes(ddrType) || isActive || (!(RDIMM_TYPES.includes(ddrType) && !comp.active && findDIMMMemoryComps.length > 1)))
      comp.pins.forEach(pin => {
        if (pin.driver && (comp.type === CONTROLLER || isCheckModel)) {
          const usage = "Driver";
          const model = pin.driver.model;
          const pinModels = pin.driver.pinModels;
          const powerOff = pin.driver.powerOff;

          if (model && model.libType === 'SPICE' && model.fileName) {
            let { modelExist, libraryList } = getSpiceModelExist([...spiceList, ...onDieSpiceList], model);
            if (!modelExist) {
              const fileExist = libraryList.find(spice => spice.name === model.fileName);
              const text = fileExist ? "updated" : 'deleted';
              error.push(<p style={{ margin: 0 }}>{usage} "<span className="font-bold">{comp.name}/{pin.pin}</span>" spice file {model.folder ? `${model.folder}::` : ""}{model.fileName} has been {text}.</p>)
            }
          } else if (model && model.libType === 'IBIS' && model.fileName) {
            let modelExist = ibisList.find(ibis => ibis.id === model.libraryId && ibis.name === model.fileName)
              || ibisAmiList.find(ibis => ibis.children && ibis.children.find(it => it.id === model.libraryId && it.name === model.fileName));
            if (!modelExist) {
              const fileExist = ibisList.find(ibis => ibis.name === model.fileName);
              const text = fileExist ? "updated" : 'deleted';
              error.push(<p style={{ margin: 0 }}>{usage} "<span className="font-bold">{comp.name}/{pin.pin}</span>" IBIS file {model.fileName} has been {text}.</p>)
            }
          }

          if (pinModels && pinModels.length > 0 && !openPatternSweep) {
            //When using pattern sweep for the SEV project, there is no need to set pinModels
            const ND_PU = pinModels.find(item => item.pinName === "nd_pu");
            const ND_PD = pinModels.find(item => item.pinName === "nd_pd");

            if (model && model.libType === 'SPICE' && !model.folder) {//folder does not require pu/pd voltage
              if (!ND_PU.voltage) {
                error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" driver model V_PU is not set.</p>)
              }

              if (!ND_PD.voltage) {
                error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" driver model V_PD is not set.</p>)
              }
            } else if (model && model.libType === 'IBIS') {
              if (powerOff === '1') {
                if (!ND_PU.voltage) {
                  error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" driver model V_PU is not set.</p>)
                }

                if (!ND_PD.voltage) {
                  error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" driver model V_PD is not set.</p>)
                }
              }
            }
            const ND_IN = pinModels.find(item => item.pinName === "nd_in");
            let inputStimulus = null, vector = null;
            inputStimulus = ND_IN.type;
            vector = ND_IN;

            if (!inputStimulus) {
              error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" stimulus is not set.</p>)
            }
            if (inputStimulus === 'VEC') {
              if (!vector.stimulus || Object.keys(vector.stimulus).length === 0 || !vector.stimulus.fileName) {
                error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" stimulus vector is not set.</p>)
              } else {
                const stimulusModel = vector.stimulus;
                if (stimulusModel) {
                  let modelExist = vectorList.find(vec => vec.id === stimulusModel.libraryId && vec.name === stimulusModel.fileName);
                  if (!modelExist) {
                    error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" vector file {stimulusModel.fileName} not exist.</p>)
                  }
                }
              }
            }
          }

          /* if (!model || (model && !model.modelType)) {
            error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" driver model type is not set.</p>)
          } */
          // runType usage
          if (((['ALL', "Write"].includes(runType)) || !runType)
            && (!model || (model && !model.modelName) || (model && model.libType === "SPICE" && model.folder && getPinNodeExist(model, "driver", includePdn)))) {
            error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" driver model is not set.</p>)
          }
        }

        if (pin.receiver && (comp.type === CONTROLLER || isCheckModel)
          && !(ddrType === DDR5_SODIMM && comp.type === MEMORY && ["CLKP1", "CLKN1"].includes(pin.signal))) {
          const usage = "Receiver";
          const model = pin.receiver.model;

          if (model && model.libType === 'SPICE' && model.fileName) {
            let { modelExist, libraryList } = getSpiceModelExist([...spiceList, ...onDieSpiceList], model);
            if (!modelExist) {
              const fileExist = libraryList.find(spice => spice.name === model.fileName);
              const text = fileExist ? "updated" : 'deleted';
              error.push(<p style={{ margin: 0 }}>{usage} "<span className="font-bold">{comp.name}/{pin.pin}</span>" spice file {model.folder ? `${model.folder}::` : ""}{model.fileName} has been {text}.</p>)
            }
          } else if (model && model.libType === 'IBIS' && model.fileName) {
            let modelExist = ibisList.find(ibis => ibis.id === model.libraryId && ibis.name === model.fileName)
              || ibisAmiList.find(ibis => ibis.children && ibis.children.find(it => it.id === model.libraryId && it.name === model.fileName));
            if (!modelExist) {
              const fileExist = ibisList.find(ibis => ibis.name === model.fileName);
              const text = fileExist ? "updated" : 'deleted';
              error.push(<p style={{ margin: 0 }}>{usage} "<span className="font-bold">{comp.name}/{pin.pin}</span>" IBIS file {model.fileName} has been {text}.</p>)
            }
          }

          /* if (!model || (model && !model.modelType)) {
            error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" receiver model type is not set.</p>)
          } */
          // 
          if ((['ALL', "Read"].includes(runType) || !runType)
            && (!model || (model && !model.modelName) || (model && model.libType === "SPICE" && model.folder && getPinNodeExist(model, "receiver", includePdn)))) {
            error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{comp.name}/{pin.pin}</span>" receiver model is not set.</p>)
          }
        }

        let showError = false;
        const isHaveCardModel = comp.dimmCardModel && comp.dimmCardModel.type !== "None" && comp.dimmCardModel.id;
        if (RDIMM_TYPES.includes(ddrType) && comp.type === MEMORY && isHaveCardModel && pin.dimmStdModels && pin.dimmStdModels.length > 0) {
          showError = false
          let dimmStdModels = pin.dimmStdModels;
          if (!isByte(name)) {//fix clk_adr dimm std models
            dimmStdModels = [pin.dimmStdModels[0]];
          }
          dimmStdModels.forEach((item, index) => {
            if ((comp.active && findDIMMMemoryComps.length > 1 && item.rankId && item.rankId !== 1)
              || (!comp.active && (findDIMMMemoryComps.length > 1 || (findDIMMMemoryComps.length === 1 && item.rankId && item.rankId !== 1)))) {

              if (!item.modelName) {
                showError = true
              }
              else if (item.libType === 'SPICE' && item.fileName) {
                let modelExist = spiceList.find(spice => spice.id === item.libraryId && spice.name === item.fileName)
                if (!modelExist) {
                  const fileExist = spiceList.find(spice => spice.name === item.fileName);
                  const text = fileExist ? "updated" : 'deleted';
                  error.push(<p style={{ margin: 0 }}>Standby "<span className="font-bold">{comp.name}/{pin.pin}{item.rankId ? ` - ${item.rankId}` : ""}</span>" spice file {item.fileName} has been {text}.</p>)
                }
              }
              else if (item.libType === 'IBIS' && item.fileName) {
                let modelExist = ibisList.find(ibis => ibis.id === item.libraryId && ibis.name === item.fileName);
                if (!modelExist) {
                  const fileExist = ibisList.find(ibis => ibis.name === item.fileName);
                  const text = fileExist ? "updated" : 'deleted';
                  error.push(<p style={{ margin: 0 }}>Standby "<span className="font-bold">{comp.name}/{pin.pin}{item.rankId ? ` - ${item.rankId}` : ""}</span>" IBIS file {item.fileName} has been {text}.</p>)
                }
              }
            }
          })
        }
        //multi memory
        if (RDIMM_TYPES.includes(ddrType) && isHaveCardModel && findDIMMMemoryComps.length > 1 && comp.type === MEMORY && !comp.active && (!pin.dimmStdModels || !pin.dimmStdModels.length)) {
          showError = true;
        }
        //single memory
        if (RDIMM_TYPES.includes(ddrType) && isHaveCardModel && findDIMMMemoryComps.length === 1 && comp.type === MEMORY && (!pin.dimmStdModels || !pin.dimmStdModels.length)) {
          showError = true;
        }

        const stdModelCheck = ((comp.pkg && comp.pkg.rank)
          || (ddrType === DDR5_SODIMM && comp.type === MEMORY && !["CLKP", "CLKN"].includes(pin.signal)));

        if (pin.stdModel && stdModelCheck) {
          showError = false
          if (!pin.stdModel.modelName) {
            showError = true
          }
          else if (pin.stdModel && pin.stdModel.libType === 'SPICE' && pin.stdModel.fileName) {
            let modelExist = spiceList.find(spice => spice.id === pin.stdModel.libraryId && spice.name === pin.stdModel.fileName);
            if (!modelExist) {
              const fileExist = spiceList.find(spice => spice.name === pin.stdModel.fileName);
              const text = fileExist ? "updated" : 'deleted';
              error.push(<p style={{ margin: 0 }}>Standby "<span className="font-bold">{comp.name}/{pin.pin}</span>" spice file {pin.stdModel.fileName} has been {text}.</p>)
            }
          }
          else if (pin.stdModel && pin.stdModel.libType === 'IBIS' && pin.stdModel.fileName) {
            let modelExist = ibisList.find(ibis => ibis.id === pin.stdModel.libraryId && ibis.name === pin.stdModel.fileName);
            if (!modelExist) {
              const fileExist = ibisList.find(ibis => ibis.name === pin.stdModel.fileName);
              const text = fileExist ? "updated" : 'deleted';
              error.push(<p style={{ margin: 0 }}>Standby "<span className="font-bold">{comp.name}/{pin.pin}</span>" IBIS file {pin.stdModel.fileName} has been {text}.</p>)
            }
          }
        }

        if (!pin.stdModel && stdModelCheck) {
          showError = true;
        }

        if (showError && ((comp.pkg && comp.pkg.rank) || (comp.type === MEMORY && DIMM_DDR_TYPES.includes(ddrType) && isHaveCardModel))) {
          error.push(<p style={{ margin: 0 }}>Standby "<span className="font-bold">{comp.name}-{pin.pin}</span>" standby model is not set.</p>)
        }
      });
    });
  }

  if (!isSSN) {
    error = getRlcCompsValueCheck({
      Components,
      error
    });
  }

  //virtual components error check
  if (VirtualComps && VirtualComps.length) {
    const errors = getVirtualCompsErrors(VirtualComps, { touchstoneList: customTouchstoneList, spiceList: customSpiceList })
    if (errors.length) {
      error = [...error, ...errors];
    }
  }

  if (error && error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }
}

function getPwrComponentErrorCheck(powerComponents) {
  if (!powerComponents) {
    return;
  }
  let error = [];

  // powerComponents CAP value check
  const powerComps = powerComponents.filter(item => item.usage === 'Cap');
  if (powerComps.length > 0) {
    powerComps.some(rlc => {

      if (!rlc.value) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{rlc.name}</span>" RLC value is not set.</p>)
      } else {
        let rValue = rlc.value.r;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue, 'Res');

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{rlc.name}</span>" Resistance value format error.</p>);
        } else if (rCheck && rCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{rlc.name} </span>- Resistance value cannot be 0.</p>);
          return true;
        }

        //Check the Inductance value format
        let lValue = rlc.value.l;
        let lCheck = checkRLCValueFormat(lValue, 'Ind');

        if (!lCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{rlc.name}</span>" Inductance value format error.</p>);
        } else if (lCheck && lCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{rlc.name} </span>- Inductance value cannot be 0.</p>);
          return true;
        }

        //Check the Capacitance value format
        let cValue = rlc.value.c;
        let cCheck = checkRLCValueFormat(cValue, "Cap");

        if (!cCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{rlc.name}</span>" Capacitance value format error.</p>);
        } else if (cCheck && cCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{rlc.name}</span>" Capacitance value cannot be 0.</p>);
        }
      }
      return false;
    })
  }

  // powerComponents  RES IND value check
  const RComps = powerComponents.filter(item => item.usage === 'Res' || item.usage === 'Ind');
  if (RComps.length > 0) {
    RComps.some(item => {
      if (item.usage === 'Res') {
        let rValue = item.value;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue, 'Res');

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part} </span>- Resistance value format error.</p>);
          return true;
        } else if (rCheck && rCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part} </span>- Resistance value cannot be 0.</p>);
          return true;
        }
      } else if (item.usage === 'Ind') {
        let lValue = item.value;
        //Check the Inductance value format
        let lCheck = checkRLCValueFormat(lValue, 'Ind');

        if (!lCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part} </span>- Inductance value format error.</p>);
          return true;
        } else if (lCheck && lCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{item.part} </span>- Inductance value cannot be 0.</p>);
          return true;
        }
      }
      return false;
    })
  }

  if (error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }
}

function checkICCompModel({ model, comp, modelType, error, touchstoneList, spiceList, ebdList = [] }) {
  if (model.type === 'IBIS') {
    if (!model.component || !model.model) {
      error.push(<p style={{ margin: 0 }}>{modelType} "<span className="font-bold">{comp.name}</span>" model is not set.</p>)
    }
  } else if (model.files && model.files.filter(item => !!item.libraryId).length > 0) {
    const pkgFiles = model.files;
    const Existfiles = pkgFiles.filter(item => item.fileName && item.type);
    if (!Existfiles || Existfiles.length === 0) {
      error.push(<p style={{ margin: 0 }}>{modelType} "<span className="font-bold">{comp.name}</span>" model is not set.</p>)
    } else {
      for (let _file of Existfiles) {
        const { typeText, fileType, fileList } = getPkgLibraryInfo(_file.type, { touchstoneList, spiceList, ebdList });
        const fileExist = fileList.find(file => _file.libraryId && file.id === _file.libraryId && file.name === _file.fileName);
        if (!fileExist) {
          error.push(<p style={{ margin: 0 }}>{modelType} "<span className="font-bold">{comp.name}</span>" {fileType} file {_file.fileName} not exist.</p>)
        }
        if ((_file.type === 'SPICE' && !_file.subckt) || !model.pairs || model.pairs.length === 0) {
          error.push(<p style={{ margin: 0 }}>{modelType} "<span className="font-bold">{comp.name}</span>" model is not set.</p>)
        } else if (fileExist) {
          //Find port not set
          const _notPortPairs = model.pairs.filter(item => !item.node);
          if (_notPortPairs && _notPortPairs.length > 0) {
            const nodes = _notPortPairs.map(item => item.pin);
            error.push(<p style={{ margin: 0 }}>{modelType} "<span className="font-bold">{comp.name}</span>" pins [{nodes.join(", ")}] are not connected to any {typeText}.</p>)
          }
          // let samePortPairs = [], text = "";
          // // Find same port
          // model.pairs.forEach(item => {
          //   const sameFind = model.pairs.find(it => it.pin !== item.pin && item.node && (it.node === item.node && it.libraryId === item.libraryId && (!item.subckt || item.subckt === it.subckt)));
          //   const existIndex = samePortPairs.findIndex(it => (sameFind && ((it.pin === item.pin && it.samePin === sameFind.pin) || (it.samePin === item.pin && it.pin === sameFind.pin))));
          //   if (sameFind && existIndex < 0) {
          //     samePortPairs.push({ pin: item.pin, samePin: sameFind.pin });
          //     text = `${text ? `${text},` : ""} [${item.pin}, ${sameFind.pin}]`;
          //   }
          // })
          // if (samePortPairs && samePortPairs.length > 0) {
          //   error.push(<p style={{ margin: 0 }}>{modelType} "<span className="font-bold">{comp.name}</span>" pins {text} connect the same {typeText}.</p>)
          // }
        }
      }
    }
  }
  return error;
}

const getPkgLibraryInfo = (type, { touchstoneList, spiceList, ebdList }) => {
  switch (type) {
    case 'Touchstone':
      return { typeText: 'port', fileType: 'touchstone', fileList: touchstoneList || [] };
    case 'EBD':
      return { typeText: 'pin', fileType: 'ebd', fileList: ebdList || [] }
    default:
      return { typeText: 'node', fileType: 'spice', fileList: spiceList || [] };
  }
}

function getRlcCompsValueCheck({
  Components,
  error,
  resNotZero,
  PowerComponents
}) {
  // RLC components value
  const RLCs = Components.filter(item => RLCType.includes(item.type));
  if (RLCs.length > 0) {
    RLCs.forEach(rlc => {
      if (!rlc.value) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" value is not set.</p>)
      } else {
        const rlcValue = checkRLCValueFormat(rlc.value);
        if (!rlcValue) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" value format error.</p>)
        }
      }
    })
  }

  //Cap components Value
  const caps = Components.filter(item => item.type === 'Cap');
  if (caps.length > 0) {
    caps.forEach(rlc => {
      if (!rlc.value) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" RLC value is not set.</p>)
      } else {
        let rValue = rlc.value.r;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue)

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Resistance value format error.</p>);
        }

        //Check the Inductance value format
        let lValue = rlc.value.l;
        let lCheck = checkRLCValueFormat(lValue)

        if (!lCheck) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Inductance value format error.</p>);
        }

        //Check the Capacitance value format
        let cValue = rlc.value.c;
        let cCheck = checkRLCValueFormat(cValue, "Cap");

        if (!cCheck) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Capacitance value format error.</p>);
        } else if (cCheck && cCheck === "0") {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Capacitance value cannot be 0.</p>);
        }
      }
    })
  }
  if (resNotZero) {
    const resList = Components.filter(item => item.type === "Res" && item.value == "0");
    if (resList && resList.length) {
      const nameList = resList.map(item => item.name);
      error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{nameList.join(", ")}</span>" Resistance value cannot be 0.</p>);
    }
  }

  if (PowerComponents && PowerComponents.length > 0) {
    let errorList = []
    for (let comp of PowerComponents) {
      const leftNet = comp.pins ? comp.pins[0].net : null;
      const rightNet = comp.pins ? comp.pins[1].net : null;
      const name = '[Components] [' + (leftNet ? leftNet : '') + ' ' + (rightNet ? rightNet : '') + ']';
      if (errorList.includes(comp.part) || comp.usage !== CAP) {
        continue;
      }
      if (!comp.model || !comp.model.id || !comp.model.name) {
        error.push(<p style={{ margin: 0 }}><span className='font-bold'>{name}</span> {comp.part} - Decap model is not set.</p>)
        errorList.push(comp.part)
        continue;
      }

      if (comp.model.libraryType === "decap_spice" && !comp.model.subcktName) {
        error.push(<p style={{ margin: 0 }}><span className='font-bold'>{name}</span> {comp.part} - Decap model subckt is not set.</p>);
        errorList.push(comp.part)
      }
    }
  }

  return error;
}

function getSpiceModelExist(spiceList, model) {
  let libraryList = [];
  if (!!model.folder) {
    const folder = spiceList.find(item => item.id === model.libraryId && item.name === model.folder);
    libraryList = folder && folder.children ? folder.children : [];
  } else {
    libraryList = spiceList;
  }
  const findFile = libraryList.find(item => item.id === model.libraryId && item.name === model.fileName);

  if (!findFile) {
    return { modelExist: false, libraryList };
  }
  return { modelExist: true, libraryList };
}

function getPinNodeExist(model, type, includePdn) {
  if (type === "driver" && (!model.node || !model.node.in || !model.node.out || (includePdn && !model.node.vddq))) {
    return true;
  }

  if (type === "receiver" && (!model.node || !model.node.out || (includePdn && !model.node.vddq))) {
    return true;
  }
}

function getPDNPowerErrorCheck(ssnPowerNets, name, error) {
  // check ssn powerNets
  if (ssnPowerNets && ssnPowerNets.length) {
    let nameList = name.split("_");
    for (let powerInfo of ssnPowerNets) {
      const { ioNames } = powerInfo;
      nameList = nameList.filter(item => !ioNames.includes(item));
    }
    if (nameList && nameList.length) {
      error.unshift(<p key={"pdnError"} style={{ margin: 0 }}>Power net not selected, please set it in pdn canvas.</p>)
    }
  }
  return error
}

function ssnErrorCheck({ ssn, content, libraryInfo, designId }) {
  if (!content) { return }
  const { openPatternSweep, runType, patternSweeps, includePdn, pdn, verificationIds } = content;
  const pcb = designConstructor.getDesign(designId) || {};
  const pcbIsPreLayout = pcb.vendor === PRE_LAYOUT;
  let error = [], runInterfaceName = [];

  const rule = /CLK_(ADR|CMD)/g;
  for (let id of verificationIds) {
    const findInfo = ssn.find(it => it.id === id);
    const name = findInfo && findInfo.name ? findInfo.name : "";
    if (name.match(rule)) {
      let list = name.includes("CLK_ADR") ? ["CLK", "ADR"] : ["CLK", "CMD"];
      const nameList = list.map(item => { return name.replace(rule, item) })
      runInterfaceName = [...runInterfaceName, ...nameList];
    } else {
      runInterfaceName.push(name)
    }
  }

  // If the switch for pattern sweep is turned on, then pattern sweep must be selected
  if (openPatternSweep) {
    const filterPatternSweep = patternSweeps.filter(item => item.ifRun);
    if (!filterPatternSweep.length) {
      error.push(<p style={{ margin: 0 }}>The pattern sweep is not set.</p>)
    }
  }

  const { onDieTouchstoneList, onDieSpiceList, pdnTouchstoneList, pdnSpiceList } = libraryInfo
  if (includePdn) {
    const { onDies, gndNet, pcb, pkg } = pdn;
    // Is the package pdn connected
    if (!onDies || !onDies.length) {
      error.push(<p style={{ margin: 0 }}>Power Nets for package PDN not set.</p>)
    } else {
      // on die
      for (let itemInfo of onDies) {
        const { onDie, powerNets } = itemInfo;
        for (let powerInfo of powerNets) {
          const { ioNames, powerNet } = powerInfo;
          let _ioNames = [...ioNames]
          if (onDie && onDie.type === 'value') {
            let notSet = [];
            if (!onDie.rdieValue) { notSet.push("RDie") }
            if (!onDie.cdieValue) { notSet.push("CDie") }
            if (notSet.length) {
              error.push(<p style={{ margin: 0 }}>[{notSet.join(", ")}] of {powerNet} are not set.</p>)
            }
          } else if (onDie && onDie.type === 'SPICE') {
            const onDieList = onDie && onDie.nodes && onDie.nodes.length ? onDie.nodes.map(it => [...it.ioNames]).flat(2) : []
            _ioNames = [..._ioNames, ...onDieList]
            const onDieError = getModelErrorCheckout(onDie, onDieTouchstoneList, onDieSpiceList, 'On Die Model', powerNet)
            if (onDieError && onDieError.length) {
              error = [...error, ...onDieError]
            }
          } else if (onDie && ["CPM", "CSM"].includes(onDie.type)) {
            const fileErrors = onDieModelCSMCPMCheck(onDie, onDieSpiceList);
            if (fileErrors && fileErrors.length) {
              error = [...error, ...fileErrors]
            }
            runInterfaceName = []
          }

          runInterfaceName = runInterfaceName.filter(item => !_ioNames.includes(item))
        }

      }

      if (runInterfaceName.length) {
        // ioName
        error.push(<p style={{ margin: 0 }}>[{runInterfaceName.join(", ")}] did not select power net,please set it in pdn canvas.</p>)
      }

      //pcb pdbn
      if (pcb && pcbIsPreLayout) {
        const pcbError = getModelErrorCheckout(pcb, pdnTouchstoneList, pdnSpiceList, 'PCB PDN Model')
        if (pcbError && pcbError.length) {
          error = [...error, ...pcbError]
        }
      }
    }
  }

  // At least select read/write/all
  if (!runType) {
    error.push(<p style={{ margin: 0 }}>The simulation type to be run is not selected, and can be selected as read-only, read-only, or read-write.</p>)
  }

  return error;
}

function getModelErrorCheckout(model, touchstoneList, spiceList, modelType, powerNet) {
  const { libraryId, pairs, type, fileName } = model;
  let error = [];
  if (!libraryId) {
    // pcb pdn model not set
    error.push(<p style={{ margin: 0 }}>{powerNet ? <Fragment>"<span className="font-bold">{powerNet}</span>"</Fragment> : ''} {modelType} file not set.</p>)
  } else {
    let modelExist = false;
    if (type === TOUCHSTONE) {
      modelExist = touchstoneList.find(item => item.id === libraryId)
    } else {
      modelExist = spiceList.find(item => item.id === libraryId)
    }
    if (!modelExist) {
      error.push(<p style={{ margin: 0 }}>{powerNet ? <Fragment>"<span className="font-bold">{powerNet}</span>"</Fragment> : ''} {modelType} file {fileName} not exist.</p>)
    } else {
      // The file has been selected, but the node has not been set
      const { typeText } = getPkgLibraryInfo(type, {});
      const filterPairs = pairs.filter(item => !item.node)
      if (filterPairs && filterPairs.length > 0) {
        const nodes = filterPairs.map(item => item.pin);
        error.push(<p style={{ margin: 0 }}>{powerNet ? <Fragment>"<span className="font-bold">{powerNet}</span>"</Fragment> : ''} {modelType} pins [{nodes.join(", ")}] are not connected to any `${typeText}`.</p>)
      }
    }
  }
  return error;
}

function onDieModelCSMCPMCheck(onDie, onDieSpiceList) {
  let error = [];
  if (!onDie.libraryId || !onDie.fileName) {
    error.push(<p style={{ margin: 0 }}>On Die {onDie.type} Model is not set.</p>)
  } else {
    const findModel = onDieSpiceList.find(item => item.id === onDie.libraryId);
    const findFile = findModel && findModel.children ? findModel.children.find(item => item.fileName === onDie.fileName) : null;
    if (!findModel || !findFile) {
      error.push(<p style={{ margin: 0 }}>On Die {onDie.type} Model does not exist.</p>)
    }
  }

  if (error.length) {
    return error
  }

  if (onDie.type === "CSM" && (!onDie.csmPairs || !onDie.csmPairs.length || !!onDie.csmPairs.find(item => !item.csmSignal || !item.pad || !item.in))) {
    const notFindOnDieInfo = onDie.csmPairs.filter(item => !item.csmSignal || !item.pad || !item.in)
    if (notFindOnDieInfo && notFindOnDieInfo.length) {
      const nameList = notFindOnDieInfo.map(item => item.pkgSignal);
      error.push(<p style={{ margin: 0 }}>Signals {nameList.join(", ")} does not match CSM Signal.</p>)
    }
  }

  if (!onDie.cpmPairs || !onDie.cpmPairs.length || !!onDie.cpmPairs.find(item => !item.node || !item.pin)) {
    error.push(<p style={{ margin: 0 }}>On Die CPM connection is not set.</p>)
  }
  return error
}

function ssnPrelayoutJsonErrorCheck(components, nameList, touchstoneList, spiceList, modelType) {
  let error = [];
  for (let comp of components) {
    const { pins, type } = comp;
    const selectPinsList = pins.filter(item => nameList.includes(item.keyword))
    let notSetLibraryList = [];

    for (let pinInfo of selectPinsList) {
      const { model, pin } = pinInfo;

      if (!model || !model.libraryId || pinInfo.port) {
        notSetLibraryList.push(pin)
        continue;
      }
      let modelExist = false;
      if (modelType === TOUCHSTONE) {
        modelExist = touchstoneList.find(item => item.id === model.libraryId)
      } else {
        modelExist = spiceList.find(item => item.id === model.libraryId)
      }
      if (!modelExist) {
        error.push(<p style={{ margin: 0 }}>{type} "<span className="font-bold">{comp.name}/{pin}</span>" {modelType} file {model.fileName} has been deleted.</p>)
      }
    }
    if (notSetLibraryList.length) {
      error.push(<p style={{ margin: 0 }}>{type} "<span className="font-bold">{comp.name}/{notSetLibraryList.join(", ")}</span>" are not set.</p>)
    }
  }
  return error.length === 0 ? null : { error };
}

function getCPMPkgSettingErrorCheck(res) {
  let error = []
  if (res && res.content && res.content.powerDomains && res.content.powerDomains.length) {
    const filterList = res.content.powerDomains.filter(item => !item.diePorts || !item.diePorts.length)
    if (filterList && filterList.length) {
      const powerNetsList = filterList.map(item => item.PowerNets).flat(2)
      error.push(`--------------------- Package PDN error ---------------------`)
      error.push(<p style={{ margin: 0 }}>Please set the Die ports for power domain <span className="font-bold">[ {powerNetsList.join(", ")} ]</span>  to Pin Group.</p>)
    }
  }
  return error.length === 0 ? null : { error };
}

function getChannelPowerComponentsErrorCheck(PowerComponents) {
  let error = [];
  if (PowerComponents.length > 0) {
    let errorList = []
    for (let comp of PowerComponents) {
      const leftNet = comp.pins ? comp.pins[0].net : null;
      const rightNet = comp.pins ? comp.pins[1].net : null;
      const name = '[Components] [' + (leftNet ? leftNet : '') + ' ' + (rightNet ? rightNet : '') + ']';

      if (errorList.includes(comp.part) || comp.usage !== CAP) {
        continue;
      }
      if (!comp.model || !comp.model.id || !comp.model.name) {
        error.push(<p style={{ margin: 0 }}><span className='font-bold'>{name}</span> {comp.part} - Decap model is not set.</p>)
        errorList.push(comp.part)
        continue;
      }

      if (comp.model.libraryType === "decap_spice" && !comp.model.subcktName) {
        error.push(<p style={{ margin: 0 }}><span className='font-bold'>{name}</span> {comp.part} - Decap model subckt is not set.</p>);
        errorList.push(comp.part)
      }
    }
  }
  return error.length === 0 ? null : { error };
}

export { getErrorCheck, getPwrComponentErrorCheck, checkICCompModel, getRlcCompsValueCheck, ssnErrorCheck, ssnPrelayoutJsonErrorCheck, getCPMPkgSettingErrorCheck, getChannelPowerComponentsErrorCheck };