import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';
import { getNewDisplayPortList, updateConnectorPinPort, getPinPortInfo, getPinTypeDisplay } from "@/services/helper/connectorHelper";
import { getPanelMaxHeight, getPanelMaxWidth } from '@/services/helper/panelSizeHelper';
import './index.css';

class SignalPinsPortSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editNode: null,
      maxWidth: 600,
      maxHeight: 600,
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 360),
      maxWidth: getPanelMaxWidth(offset, 830)
    })
  }


  handleClickOutside = (e) => {
    const { target } = e;
    const PopoverRoot = document.getElementsByClassName('connection-model-port-select-Popover')[0];

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || (PopoverRoot && !PopoverRoot.contains(target))) {
      this.closeNodesSelection();
    }
  }

  selectPinPort = ({ port, pin, type, currentPortInfo, modelKey }) => {
    //type -> pin type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
    const { portsObj, connector1, connector2, signalInfo, signalType } = this.props;
    const libraryId = currentPortInfo.libraryId;
    //find current libraryId ports
    const ports = portsObj[libraryId] ? portsObj[libraryId] : [];
    if (ports.length === 0) {
      return;
    }
    const { _connector1, _connector2 } = updateConnectorPinPort({
      port,
      modelId: libraryId,
      modelKey,
      pin,
      type,
      connector1,
      connector2,
    })
    this.props._updateConnector({
      connector1: _connector1,
      connector2: _connector2
    }, true, signalInfo[`${signalType}`]);
    this.setState({
      searchValue: port
    })
    this.closeNodesSelection();
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: "",
      searchValue: ""
    })
  }

  openSelectNode = ({ pin, pinType }) => {
    this.setState({
      editNode: `${pin}::${pinType}`
    })
  }

  deleteSelectedPort = (e, pin, type) => {
    e && e.stopPropagation();
    const { connector1, connector2, signalInfo, signalType } = this.props;
    const { _connector1, _connector2 } = updateConnectorPinPort({
      pin,
      type,
      connector1,
      connector2,
      port: "",
      modelId: "",
      modelKey: ""
    })
    this.props._updateConnector({
      connector1: _connector1,
      connector2: _connector2
    }, true, signalInfo[`${signalType}`]);
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  render() {
    const { signalInfo = {}, portType, signalType } = this.props;
    const signalName = signalInfo[`${signalType}`];
    // const topPin = signalInfo.pins ? signalInfo.pins[0] : {};
    // const bottomPin = signalInfo.pins ? signalInfo.pins[1] : {};

    return <div className="connector-model-select-ports-main" key={signalName}>
      <div className="connector-model-port-signal">{signalName}</div>
      <div className="connector-model-port-pins">
        {/* {this.getSignalPinsRender({ pinInfo: topPin, portType })}
        {this.getSignalPinsRender({ pinInfo: bottomPin, portType })} */}
        {(signalInfo.pins || []).map((pinInfo, index) => {
          return <div key={`connector-model-pinInfo-${index}`}>{this.getSignalPinsRender({ pinInfo, portType })}</div>
        })}
      </div>
    </div>
  }

  portPopover = ({ pinItem, portType, pin, pinPort, pinType, pinModelKey, libraryId, placement }) => {
    const cssId = `${pin}::${pinType}`;
    const { editNode } = this.state;
    if (editNode === cssId) {
      return <Popover
        overlayClassName='connection-model-port-select-Popover'
        content={this.nodeSelectRender({ pin, pinType, pinPort, pinItem, portType })}
        title=""
        trigger="click"
        open={true}
        placement={placement ? placement : "top"}
      >{this.connectorPinPortInput({
        pin,
        pinPort,
        pinType,
        cssId,
        libraryId,
        pinModelKey,
        portType
      })}</Popover>
    }

    if (pinPort) {
      return (
        <div
          className='connection-model-pin-input-div'
          id={cssId}
          title={pinPort}
          onClick={() => this.openSelectNode({ pin, pinPort, pinType, cssId, libraryId, pinModelKey })}
        >
          <div className='connection-pin-input-pin-port'>
            <span className='connection-pin-input-pin-port-text'>
              {pinPort}
            </span>
          </div>
          <CloseCircleFilled
            className='connection-model-pin-node-delete-icon'
            onClick={(e) => { this.deleteSelectedPort(e, pin, pinType) }} />
        </div>
      );
    }

    return this.connectorPinPortInput({
      pin,
      pinPort,
      pinType,
      cssId,
      libraryId,
      pinModelKey,
      portType
    })
  }

  connectorPinPortInput = ({
    pin,
    pinPort,
    pinType,
    cssId,
    libraryId,
    pinModelKey
  }) => {
    return <Input
      className='connection-model-pin-input'
      placeholder="Port"
      value={pinPort}
      id={cssId}
      onClick={() => this.openSelectNode({ pin, pinPort, pinType, cssId, libraryId, pinModelKey })}
    />
  }

  getSignalPinsRender = ({ pinInfo = {}, portType }) => {
    const { pinLWidth, pinRWidth } = this.props;
    const widthObj = { pinLWidth, pinRWidth }
    const { leftPinInfo, rightPinInfo } = getPinPortInfo(portType, pinInfo);

    return <div className="connector-model-port-pin">
      {portType === "cable" ?
        <Fragment>
          <div className="connector-model-port-pin-content">
            <div className="connector-model-pin-type-span">{getPinTypeDisplay(pinInfo.pinLType)}</div>
            <div className="connector-model-port-pin-title"
              title={pinInfo.pinL}
              style={{ width: pinLWidth + 24 }}
            >{pinInfo.pinL}</div>
          </div>
          <div className='connection-pin-long-line'></div>
          {this.portPopover({
            pinItem: pinInfo,
            portType,
            ...leftPinInfo
          })}
          {this.portPopover({
            pinItem: pinInfo,
            portType,
            ...rightPinInfo
          })}
          <div className="connector-model-port-pin-content">
            <div className="connector-model-pin-type-span">{getPinTypeDisplay(pinInfo.pinRType)}</div>
            <div className="connector-model-port-pin-title"
              title={pinInfo.pinR}
              style={{ width: pinRWidth + 24 }}
            >
              {pinInfo.pinR}
            </div>
          </div>
        </Fragment>
        :
        <Fragment>
          <div className="connector-model-port-pin-content">
            <div className="connector-model-pin-type-span">{getPinTypeDisplay(pinInfo[`${portType}Type`])}</div>
            <div
              className="connector-model-port-pin-title"
              title={pinInfo[`${portType}`]}
              style={{ width: widthObj[`${portType}Width`] + 24 }}
            >{pinInfo[`${portType}`]}</div>
          </div>
          <div className='connection-pin-long-line'></div>
          {this.portPopover({
            pinItem: pinInfo,
            portType,
            ...leftPinInfo
          })}
          {this.portPopover({
            pinItem: pinInfo,
            portType,
            ...rightPinInfo
          })}
        </Fragment>}
    </div>
  }

  nodeSelectRender = ({ pin, pinType, pinPort, pinItem, portType }) => {
    //type -> pin type "pinL" / "pinR" / "pinLExternal" /"pinRExternal" /"pinLExternalCable" / "pinRExternalCable"
    const { searchValue, maxWidth, maxHeight } = this.state;
    const { portsObj, pinList, selectFile, selectFile2 } = this.props;
    const netType = portType === "cable" ? "pinLType" : `${portType}Type`;
    const _selectFile = pinItem[netType] === "negative" && selectFile2 ? selectFile2 : selectFile;
    const _portList = getNewDisplayPortList({ currentItem: pinItem, type: pinType, searchValue, portsObj, selectFile: _selectFile, pinList });
    return (
      <div className='connection-model-ports-content' style={{ maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200, maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100 }}>
        <div className='connection-model-ports-content-header'>
          <span>Component Pin {pin}</span>
        </div>
        <div className='connection-model-ports-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='connection-model-ports-content-close-icon' />
        </div>
        <div className="connection-model-port-list-body-with-search">
          <div className="connection-model-port-list-body-search-wrapper">
            <Input
              placeholder={`Search Port`}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='connection-model-port-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {_portList.map(item =>
                <li
                  key={item.port}
                  title={item.info}
                  className={item.port === pinPort && item.select ? 'connector-current-pin-selected-port-li' : (item.select ? 'connector-port-li-selected' : '')}
                  onClick={!item.select ? () => this.selectPinPort({ port: item.port, pin, type: pinType, currentPortInfo: item, modelKey: _selectFile.modelKey }) : null}
                >
                  {item.info !== item.port ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item.port}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SignalPinsPortSetup;