import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import CeLinkedMap from '../utility/CeLinkedMap';
import StringHelper from '../utility/StringHelper';
import StrSplitRule from '../utility/StrSplitRule';
import StringList from '../utility/StringList';

var PartInfo = function () {
  this.mPartName = "Unknown";
  this.mVendor = "";
  this.mDescription = "";
  this.mPartType = "";
  this.mPhyProps = new CeLinkedMap();
};

PartInfo.prototype.GetManufacture = function () {
  return this.mVendor;
}

PartInfo.prototype.SetVendor = function (vendor) {
  this.mVendor = vendor;
}

PartInfo.prototype.SetType = function (type) {
  this.mPartType = type;
}

PartInfo.prototype.SetDescription = function (description) {
  this.mDescription = description;
}

PartInfo.prototype.WriteIntoIODataNode = function () {
  var myBlock = new CeIODataBlock("PartInfo");
  myBlock.AddDataItem("Name", '"' + this.mPartName + '"');
  myBlock.AddDataItem("Vendor", '"' + this.mVendor + '"');
  myBlock.AddDataItem("Type", '"' + this.mPartType + '"');
  myBlock.AddDataItem("Description", '"' + this.mDescription + '"');
  if (this.mPartName === "Cap_1") {
    console.log("Cap")
  }
  var propmap = new StringList();
  var keys = this.mPhyProps.keySet();
  for (var i = 0; i < keys.length; i++) {
    var propName = keys[i];
    if (propName == null)
      continue;

    var val = this.mPhyProps.getValue(propName);
    if (val != null)
      propmap.add("(" + propName + "," + val + ")");
    else
      propmap.add("(" + propName + ",)");
  }
  myBlock.AddDataItem("Attributes", propmap);
  return myBlock;
}

PartInfo.prototype.ReadFromIODataNode = function (myBlock) {
  this.mPartName = myBlock.GetItemString("Name", null);
  this.mVendor = myBlock.GetItemString("Vendor", null);
  this.mPartType = myBlock.GetItemString("Type", null);
  this.mDescription = myBlock.GetItemString("Description", null);
  var attributes = myBlock.GetItemStringList("Attributes");
  this.SetAttributes(attributes);
}

PartInfo.prototype.SetAttributes = function (attributes) {
  if (attributes == null)
    return;

  var splitRule = new StrSplitRule(",");
  splitRule.SetStringNumber(2);
  for (var i = 0; i < attributes.size(); i++) {
    var strItems = StringHelper.SplitString(attributes.get(i), splitRule).mValues;
    if (strItems.length === 1) {
      this.mPhyProps.put(strItems[0], null);
    }
    else if (strItems.length === 2) {
      this.mPhyProps.put(strItems[0], strItems[1]);
    }
  }
}

export default PartInfo;