import {
  getCascadeProjectList,
  createProject,
  getCascadeProjectChildren,
  deleteCascadeProject,
  deleteCascadeDesign,
  createChannel,
  deleteChannel,
  renameChannel,
  copyVerification,
  selectPCB,
  updateProjectServiceOptionsConfig,
  getProjectServiceOptionsConfig
} from '../../api/Cascade/project';
import { getPMICList } from '../../api/Design/design';
import { getTrashList, cleanupTrash, projectRename } from '../../api/v2/projectCtrl';
import apiProcess from "../../api/utility";

/** 
* Get project list
* 2021.11.11
* @return Promise
*/
function getProjectList() {
  return apiProcess(getCascadeProjectList)
}

function CascadeProjectRename({ projectId, name }) {
  return apiProcess(projectRename, { projectId, projectName: name });
}

function createProjectPromise(data) {
  return apiProcess(createProject, data)
}

function getProjectChildren(projectId) {
  return apiProcess(getCascadeProjectChildren, projectId);
}

function deleteProjectByIds(projectIdList) {
  return apiProcess(deleteCascadeProject, projectIdList)
}

function deleteDesignInProject(designIds) {
  return apiProcess(deleteCascadeDesign, designIds)
}

function createCascadeChannel({ projectId, name, channelType, signOffId }) {
  return apiProcess(createChannel, { projectId, name, channelType, signOffId })
}

function deleteCascadeChannel(verificationIds) {
  return apiProcess(deleteChannel, { verificationIds })
}

function renameCascadeChannel({ verificationId, name }) {
  return apiProcess(renameChannel, { verificationId, name })
}

function copyCascadeVerification({ verificationId, name }) {
  return apiProcess(copyVerification, { verificationId, name })
}

function getCascadePMIC(designId) {
  return apiProcess(getPMICList, designId)
}

function getCascadeTrashList() {
  return apiProcess(getTrashList, 'Cascade');
}

function cleanCascadeTrash() {
  return apiProcess(cleanupTrash, 'Cascade');
}

function selectTaskPCB({ verificationId, designId }) {
  return apiProcess(selectPCB, { verificationId, designId })
}

function getServiceConfig(projectId) {
  return apiProcess(getProjectServiceOptionsConfig, projectId)
}

function updateProjectServiceConfig({ projectId, options }) {
  return apiProcess(updateProjectServiceOptionsConfig, { projectId, options })
}

export {
  getProjectList,
  createProjectPromise,
  getProjectChildren,
  deleteProjectByIds,
  deleteDesignInProject,
  createCascadeChannel,
  deleteCascadeChannel,
  renameCascadeChannel,
  copyCascadeVerification,
  getCascadePMIC,
  getCascadeTrashList,
  cleanCascadeTrash,
  CascadeProjectRename,
  selectTaskPCB,
  getServiceConfig,
  updateProjectServiceConfig
}