import React, { Component } from 'react';
import { CheckCircleOutlined, CloseCircleFilled, CloseCircleOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input, Button, Select, Slider, Spin } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import './index.css';
import { getCascadeCSMCPMSpiceModelList } from '../../services/Cascade/library';
import { getWriteAndReadPortList } from '../../services/LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import CPMConnection from '../CPMCSMSetup/CPMConnection';
import auroraDBJson from '../../services/Designs/auroraDbData';

const { Option } = Select
const NONE = 'None', SUCCESS = 'Success', ERROR = 'Error', LOADING = 'Loading'
class PlocSetupPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxWidth: 300,
      maxHeight: 100,
      plocSelectFile: {},
      connectionVisible: false,
      cpmPairs: [],
      autoMatchStatus: NONE,
      autoMatchSpan: '',
      loading: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 300),
      maxHeight: getPanelMaxHeight(offset, 100)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.initData()
  }

  initData = async () => {
    const { plocInfo } = this.props;
    const _plocInfo = JSON.parse(JSON.stringify(plocInfo || {}));
    this.setState({
      plocInfo: _plocInfo,
      cpmPairs: _plocInfo.cpmPairs || [],
      triPairs: _plocInfo.triPairs || [],
      transforms: _plocInfo.transforms || null,
      plocSelectFile: _plocInfo.plocSelectFile
    }, () => {
      if (_plocInfo.plocSelectFile && _plocInfo.plocSelectFile.libraryId)
        this.changeSelectPLOCModel(_plocInfo.plocSelectFile.libraryId, false)
    })
  }

  closeModal = () => {
    this.setState({
      loading: true,
      autoMatchStatus: NONE,
      autoMatchSpan: ''
    }, () => {
      setTimeout(() => {
        const { plocSelectFile, cpmPairs, triPairs, transforms } = this.state
        this.props.savePlocInfo({ plocSelectFile, cpmPairs, triPairs, transforms })
        this.props.savePlocPorts(cpmPairs)
        this.props.closeModal(false);
      }, 100)
    })
  }

  changeSelectPLOCModel = (value, updatePairs = true) => {
    const { plocModelList } = this.props
    const currentFile = plocModelList.find(item => item.id === value)
    if (currentFile) {
      const { id, name } = currentFile
      let updateFileInfo = { libraryId: id, fileName: name };
      if (updatePairs) {
        this.setState({
          cpmPairs: [],
          triPairs: [],
          transforms: null,
        })
      }
      this.setState({
        plocSelectFile: updateFileInfo,
        autoMatchStatus: LOADING,
        autoMatchSpan: 'Automatically matching PLOC file content by Pin Number.'
      }, async () => {
        const file = await getCascadeCSMCPMSpiceModelList({ ...updateFileInfo, fileType: 'ploc' });
        this.autoSetPlocByPinNumber(file)
      });
    }
  }

  deletePlocFileSelect = () => {
    this.setState({
      plocSelectFile: {}
    })
  }

  openCPMConnection = (e, visible) => {
    e && e.stopPropagation();
    this.setState({
      connectionVisible: visible,
    }, () => {
      if (!visible) {
        this.setState({
          autoMatchStatus: NONE,
          autoMatchSpan: ''
        })
      }
    })
  }

  saveCPMPinMapping = ({ cpmPairs, triPairs, transforms, canvasFile, csmPairs }) => {
    this.setState({
      cpmPairs,
      triPairs,
      transforms,
      canvasFile,
      csmPairs
    })
  }

  autoSetPlocByPinNumber = (file) => {
    if (!file || !file.model || !file.model.CPM || !file.model.CPM.models) {
      return;
    }
    const nodes = JSON.parse(JSON.stringify(file.model.CPM.models));
    const { designId, chip } = this.props;
    const chipInfo = auroraDBJson.getComponent(designId, chip);
    if (!chipInfo || !chipInfo.pins) {
      return;
    }
    let cpmPairs = [];
    for (let nodeInfo of nodes) {
      const { net, node, portName } = nodeInfo;
      const pinInfo = chipInfo.pins.get(node);
      if (!pinInfo) {
        continue;
      }
      cpmPairs.push({
        csmNet: net,
        node,
        portName,
        pin: pinInfo.pin,
        net: pinInfo.net
      })
    }

    if (cpmPairs.length / nodes.length > 0.1) {
      this.setState({
        cpmPairs: cpmPairs,
        autoMatchStatus: SUCCESS,
        autoMatchSpan: `${SUCCESS}: Automatic matching is success (By Pin Number), there are ${nodes.length} Nodes in the PLOC file, and ${cpmPairs.length} Nodes are successfully matched. Close the PLOC Setup Options Panel to view the Port grouping.`
      })
    } else {
      this.setState({
        autoMatchStatus: ERROR,
        autoMatchSpan: `${ERROR}: Automatic matching failed (By Pin Number). Please edit PLOC Connection to match manually.`
      })
    }
  }

  getPlocMsgClass = () => {
    const { autoMatchStatus } = this.state;
    switch (autoMatchStatus) {
      case SUCCESS:
        return 'port-setup-ploc-success-msg';
      case ERROR:
        return 'port-setup-ploc-error-msg';
      case LOADING:
        return 'port-setup-ploc-loading-msg';
      case NONE:
      default:
        break;
    }
  }

  getPlocMsgIcon = () => {
    const { autoMatchStatus } = this.state;
    switch (autoMatchStatus) {
      case SUCCESS:
        return <CheckCircleOutlined />;
      case ERROR:
        return <CloseCircleOutlined />;
      case LOADING:
        return <LoadingOutlined />;
      case NONE:
      default:
        break;
    }
  }

  render() {
    const { maxWidth, maxHeight, plocSelectFile, connectionVisible, triPairs, transforms, cpmPairs, autoMatchSpan, autoMatchStatus, loading } = this.state;
    const { plocModelList, designId, PowerNets, ReferenceNets, chip, product, autoMatch } = this.props
    const setting = { defaultWidth: 500 }
    const content = (
      <Panel
        className='ploc-setup-panel'
        title={'PLOC Setup Options'}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center-left'
        draggable
        minWidth={200}
        minHeight={100}
        maxHeight={maxHeight}
      >
        <Spin spinning={loading} tip='Generating Ports...'>
          <div className='ploc-content-body'>
            <div className='ploc-content-item'>
              <span>PLOC Model</span>
              <Select
                className='aurora-select'
                popupClassName="aurora-select-dropdown"
                onSelect={(value) => this.changeSelectPLOCModel(value)}
                value={plocSelectFile && plocSelectFile.libraryId ? plocSelectFile.libraryId : null}
                size='small'
                showSearch={true}
                popupClassNameMatchSelectWidth={false}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deletePlocFileSelect() }} /> }}
              >
                {(plocModelList || []).map(item =>
                  <Option
                    key={item.id}
                    value={item.id}
                  >{item.name}</Option>
                )}
              </Select>
            </div>
            <div className='ploc-content-item'>
              <span>PLOC Connection</span>
              <EditOutlined className='ploc-content-icon' onClick={(e) => this.openCPMConnection(e, true)} />
            </div>
            {autoMatchStatus !== NONE ? <div className='ploc-content-item'>
              <div className={`port-setup-ploc-msg ${this.getPlocMsgClass()}`}>
                {this.getPlocMsgIcon()}
                {autoMatchSpan}
              </div>
            </div> : null}
          </div>
        </Spin>
        {connectionVisible ? <CPMConnection
          designId={designId}
          component={chip}
          PowerNets={PowerNets}
          ReferenceNets={ReferenceNets}
          selectFile={plocSelectFile}
          triPairs={triPairs}
          transforms={transforms}
          cpmPairs={cpmPairs}
          canvasFile={plocSelectFile}
          closePanel={() => this.openCPMConnection(null, false)}
          saveCPMPinMapping={this.saveCPMPinMapping}
          writeSelectFile={plocSelectFile}
          getPortList={getWriteAndReadPortList}
          isUpdateFile={true}
          modelType={'PLOC'}
          product={product}
          getCSMCPMSpiceModelList={getCascadeCSMCPMSpiceModelList}
          autoMatch={autoMatch}
        /> : null}
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PlocSetupPanel;