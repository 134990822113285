import { Divider, Input, Switch } from "antd"
import React, { Fragment } from "react"
import UnitAddonAfter from "../UnitAddonAfter";
import './index.css'

const TimeoutOptions = (props) => {
  const {
    disabled, licWaitSwitch, licWait, licWaitUnit, timeoutSwitch, timeoutHours, timeoutMinutes,
    changeValue, changeSwitch, inputBlur, className, changeSelect
  } = props;
  return <Fragment>
    <Divider />
    <div className={`comp-extraction-option-content ${className}`}>
      <span className='comp-extraction-option-content-body'>License Wait Time</span>
      <Switch
        disabled={disabled}
        size="small"
        className="aurora-switch-small"
        checked={licWaitSwitch}
        onChange={(e) => changeSwitch(e, "licWaitSwitch")}
      />
      {licWaitSwitch ? <span>
        <Input
          className='comp-extraction-option-content-input comp-extraction-option-content-input-with-switch'
          value={licWait}
          onChange={(e) => changeValue(e, 'licWait')}
          addonAfter={UnitAddonAfter({ unit: licWaitUnit, list: ['min', 'hour'], changeUnit: (key) => changeSelect(key, 'licWaitUnit') })}
          disabled={disabled}
          onBlur={(e) => inputBlur(e, 'licWait')}
        />
      </span> : null}
    </div>
    <div className={`comp-extraction-option-content ${className}`}>
      <span className='comp-extraction-option-content-body'>Custom Timeout</span>
      <Switch
        disabled={disabled}
        size="small"
        className="aurora-switch-small"
        checked={timeoutSwitch ? true : false}
        onChange={(e) => changeSwitch(e, "timeoutSwitch")}
      />
    </div>
    {timeoutSwitch ? <div className={`comp-extraction-option-content ${className}`}>
      <span className='comp-extraction-option-content-body comp-extraction-option-sub-span'>Abort the extraction after</span>
      <Input
        className='comp-extraction-option-content-input comp-extraction-option-content-input-both'
        value={timeoutHours}
        onChange={(e) => changeValue(e, 'timeoutHours')}
        disabled={disabled}
        onBlur={(e) => inputBlur(e, 'timeoutHours')}
        addonAfter={"hour"}
      />
      <Input
        className='comp-extraction-option-content-input comp-extraction-option-content-input-both'
        value={timeoutMinutes}
        onChange={(e) => changeValue(e, 'timeoutMinutes')}
        disabled={disabled}
        onBlur={(e) => inputBlur(e, 'timeoutMinutes')}
        addonAfter={"minute"}
      />
    </div> : null}
  </Fragment>
}

export default TimeoutOptions;