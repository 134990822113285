import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EditableTable from '../../EditableTable';
import './index.css';

const columns = [{
  title: 'Pair',
  dataIndex: 'pairs',
  width: '10%',
},
{
  title: 'CSM Bump Node',
  dataIndex: 'node',
  width: '45%',
},
{
  title: 'Package Die Bump',
  dataIndex: 'pin',
  width: '45%',
},];

class TriMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pairs: props.pairs || this.props.getDefaultPairs()
    };

    columns[0].render = (text, record) => {
      return <span>{record.index}</span>
    }

    columns[0].title = () => {
      return <div>
        <span>Pair</span>
      </div>
    }

    columns[1].title = () => {
      const { modelType } = this.props
      return <div>
        <span>{`${modelType} Bump Node`}</span>
      </div>
    }

    columns[1].onCell = (record) => {
      const { nodeList } = this.props;
      return {
        edit: 'select',
        options: (nodeList || []).map(item => { return { key: item.node, value: item.node, title: `${item.net}::${item.node}` } }),
        record: { ...record, nodeNumber: record.node && record.node.node ? record.node.node : "" },
        text: '',
        dataIndex: 'nodeNumber',
        handleSave: (value) => this.savePin(value, "node", record),
        getPopupContainer: document.getElementById("cpm-setup-canvas-main"),
        dropdownMenuClassName: 'aurora-select-dropdown'
      }
    }

    columns[1].render = (text, record) => {
      const _text = record.node && record.node.node ? `${record.node.net}::${record.node.node}` : "";
      return <span title={_text}>{_text}</span>
    }

    columns[2].render = (text, record) => {
      const _text = record.pin && record.pin.pin ? `${record.pin.net}::${record.pin.pin}` : "";
      return <span title={_text}>{_text}</span>
    }

    columns[2].onCell = (record) => {
      const { powerPinList } = this.props;
      return {
        edit: 'select',
        options: (powerPinList || []).map(item => { return { key: item.pinNumber, value: item.pinNumber, title: `${item.net}::${item.pinNumber}` } }),
        record: { ...record, pinNumber: record.pin && record.pin.pin ? record.pin.pin : "" },
        text: '',
        dataIndex: 'pinNumber',
        handleSave: (value) => this.savePin(value, "pin", record),
        getPopupContainer: document.getElementById("cpm-setup-canvas-main"),
        dropdownMenuClassName: 'aurora-select-dropdown'
      }
    }
  }

  componentDidMount = () => {
    const { pairs } = this.props;
    this.setState({
      pairs
    })
    this.props.updateTriPairsStatus(false)
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateTriPairs, pairs } = this.props;
    if (prevProps.isUpdateTriPairs !== isUpdateTriPairs && isUpdateTriPairs) {
      this.setState({
        pairs
      })
      this.props.updateTriPairsStatus(false)
    }
  }

  savePin = (record, type, prevRecord) => {
    let pairs = this.state.pairs;
    const index = pairs.findIndex(item => item.index === record.index);
    if (index < 0) {
      return;
    }
    if (type === "pin") {
      const pin = record.pinNumber;
      const { powerPinList } = this.props;
      const findPin = powerPinList.find(item => item.pinNumber === pin);
      pairs[index].pin = {
        ...(findPin || {})
      }
    } else if (type === "node") {
      const node = record.nodeNumber;
      const { nodeList } = this.props;
      const findNode = nodeList.find(item => item.node === node);
      pairs[index].node = {
        ...(findNode || {})
      }
    }
    this.setState({
      pairs
    }, () => {
      this.props.updateTriPairs(this.state.pairs, false)
    })
  }

  matchPairs = () => {
    this.props.matchTriMapping(this.state.pairs)
  }

  autoMatchPairs = () => {
    this.props.autoMatchPairs()
  }

  removeAllPairs = () => {
    this.setState({
      pairs: this.props.getDefaultPairs()
    }, () => {
      this.props.updateTriPairs(this.state.pairs, true)
    })
  }

  showTriPairs = () => {
    this.props.updateTriPairs(this.state.pairs)
  }

  render() {
    const { modelType, autoMatch } = this.props
    const { pairs } = this.state;
    return (
      <Panel
        title={<div>
          <span>{`${modelType} - Package Mapping`}</span>
          {/*  <Icon
            type="eye"
            className='cpm-edit-tri-mapping-panel-eye-icon'
            title="Show Mapping"
            onClick={this.showTriPairs} /> */}
          <DeleteOutlined
            className='cpm-edit-tri-mapping-panel-clean-icon'
            title="Remove Mapping"
            onClick={this.removeAllPairs} />
        </div>}
        className='cpm-edit-tri-mapping-panel'
        visible
        onCancel={() => this.props.closePanel()}
        width={600}
        position='panel-top-left'
        draggable
        left={0}
        top={0}
        defaultLeft={50}
        minHeight={200}
        minWidth={100}
        zIndex={10}
        overflow='hidden'
      >
        {autoMatch && <span className='cpm-connection-auto-match-pair-button' onClick={this.autoMatchPairs}>+ Automatically set Pair by Pin Number</span>}
        <EditableTable
          className="cpm-edit-tri-mapping-table"
          rowKey={(record) => record.index}
          columns={columns}
          dataSource={pairs}
        />
        <div className='cpm-connection-match-button-content'>
          <Button className='cpm-connection-match-button' onClick={this.matchPairs}>Match</Button>
        </div>
      </Panel>
    );
  }
}

export default TriMapping;