import { takeEvery, put, call, select } from 'redux-saga/effects';
import { createFolderPromise, createProjectPromise } from '@/services/api/Design/design';
import { CREATE_FOLDER } from './actionType';
import { openFolder } from '../../action';
import { cancelAction } from './action';
import { message } from 'antd';

function* create(action) {
  const { current } = (yield select()).designReducer;
  const { id } = current;
  const { name, createType } = action;
  try {
    if (createType === 'folder') {
      yield call(createFolderPromise, id, name)
    } else if (createType === 'project') {
      yield call(createProjectPromise, id, name)
    }
    yield put(cancelAction());
    yield put(openFolder(current));
    message.success(`Create ${createType} successfully!`, 2)
  } catch (error) {
    message.error(`Create ${createType} failed!`, 2)
  }
}

export default function* FolderCreateSaga() {
  yield takeEvery(CREATE_FOLDER, create)
}