import { connect } from 'react-redux';
import { saveData } from './store/actionCreators';
import { changeStackupTable, settingUnit } from './store/actionCreators';
import Stackup from '../../components/stackup';

const mapState = (state) => {
  const { stackup: { data, unit }, project: { designID } } = state.AndesReducer;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return { data, unit, defaultLeft, defaultTop, designID }
}

const mapDispatch = (dispatch) => ({
  setStackupData(data, unit) {
    dispatch(saveData(data, unit));
  },
  changeTable(data, unit) {
    dispatch(changeStackupTable(data, unit));
  },
  settingUnit(unit) {
    dispatch(settingUnit(unit));
  }
})

export default connect(mapState, mapDispatch)(Stackup);