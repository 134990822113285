const CanvasObject = function () {
  this.layer = null; // layer name
  this.type = null; // object type
  this.net = null; // net name
  this.comp = null; // component name
  this.pin = null; // component pin name
  this.toolTip = null; // pop-up tool tip
  this.element = null; // SVG element - for quick access
  this.pinNumber = null; // component pin number
};

CanvasObject.prototype.copyFrom = function (canvasObj) {

  this.layer = canvasObj.layer;
  this.type = canvasObj.type;
  this.net = canvasObj.net;
  this.comp = canvasObj.comp;
  this.pin = canvasObj.pin;
  this.toolTip = canvasObj.toolTip;
  this.pinNumber = canvasObj.pinNumber;
};

export default CanvasObject;