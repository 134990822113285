import http from "../http";

/**
 * Create verification config for Sierra
 * 2019.08.05
 * 
 * @export
 * @param {string} type interface type
 * @param {string} projectId project id
 * @param {string} verificationId verification id
 * @param {object} config { xTalk, clock, cycles, slewRate, timeStep }
 * @returns XMLHttpRequest
 */
export function createVerificationConfig({ type, projectId, verificationId, config }) {
  return http.post(`/project/verification/config`, {
    typeName: type,
    id: '',
    projectId,
    verificationId,
    config
  })
}

/**
 * Update verification config for Sierra
 * 2019.08.05
 * 
 * @export
 * @param {string} type interface type
 * @param {string} projectId project id
 * @param {string} verificationId verification id
 * @param {object} config { xTalk, clock, cycles, slewRate, timeStep }
 * @returns XMLHttpRequest
 */
export function updateVerificationConfig({ type, projectId, verificationId, config, id }) {
  return http.put(`/project/verification/config`, {
    typeName: type,
    id,
    projectId,
    verificationId,
    config
  });
}