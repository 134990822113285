import { getHistoryJson } from './result';

class HistoryData {

  constructor(verificationId) {
    this.verificationId = verificationId;
    this.historyInfo = {};
  }

  getContent = ({ projectId, verificationId, time }) => {
    return new Promise((resolve, reject) => {
      const content = this.existInfo(verificationId, time);
      if (content) {
        resolve(content);
      } else {
        getHistoryJson(verificationId, projectId, time).then(res => {
          if (res && res.Interfaces) {
            if (this.historyInfo[verificationId]) {
              const index = this.historyInfo[verificationId].findIndex(item => item.time === time);
              if (index > -1) {
                this.historyInfo[verificationId].map(item => {
                  if (item.time === time) {
                    item = { time, Interfaces: res.Interfaces };
                  }
                })
              } else {
                this.historyInfo[verificationId].push({ time, Interfaces: res.Interfaces });
              }
            } else {
              this.historyInfo[verificationId] = [{ time, Interfaces: res.Interfaces }];
            }
            resolve(res.Interfaces);
          } else {
            resolve([]);
          }
        }, error => {
          resolve([]);
        })
      }
    })
  }

  existInfo = (verificationId, time) => {
    if (this.historyInfo[verificationId]) {
      let info = this.historyInfo[verificationId].find(item => item.time === time);
      if (info && info.Interfaces) {
        return info.Interfaces
      }
    } else {
      return null
    }
  }

  removeContent = (verificationId) => {
    delete this.historyInfo[verificationId];
  }
}

export default HistoryData;