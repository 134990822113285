import React, { Fragment } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Divider, Tooltip } from 'antd';
import './index.css';

const HPCTooltip = (type, numCores = 0) => {
  if (type === 'PowerSI') {
    return <div>
      <span>* 8 core or less is recommended to maximize efficiency.</span>
    </div>
  } else {
    const pool = numCores - 4 < 0 ? 0 : numCores - 4;
    let pack = Math.round(Math.log((numCores - 4) / 8) / Math.log(4) + 1);
    pack = isNaN(pack) || pack < 0 ? 0 : pack;
    return <div>
      <div>HPC licenses needed:​</div>
      <div>Pool: {pool}    or    Pack: {pack}</div>
      <br></br>
      <div>* Aurora does not change the HPC license type setting on your service worker machine.</div>
    </div>
  }
}

const HPCOptions = (props) => {
  const { type, numCores, corePercent, ramPercent, ramDisplay, className = '' } = props;
  const { changeValue, inputBlur, onPressEnter } = props;
  return (
    <Fragment>
      <Divider />
      <div className={`comp-extraction-option-content ${className}`}>
        <span className='comp-extraction-option-content-body'>Multi-processing</span>
      </div>
      <div className={`comp-extraction-option-content ${className}`}>
        <span className={`comp-extraction-option-content-body comp-extraction-option-sub-span`}>
          Max Number of CPU
          <Tooltip overlayClassName='aurora-tooltip' title={() => HPCTooltip(type, numCores)}>
            <InfoCircleOutlined className="span-msg-icon" />
          </Tooltip>
        </span>
        <span>
          <Input
            className='comp-extraction-option-content-input comp-extraction-option-content-core-input'
            value={numCores}
            onChange={(e) => changeValue(e, 'numCores')}
            onBlur={(e) => inputBlur(e, 'numCores')}
            onPressEnter={onPressEnter}
          />
          <span className='comp-extraction-option-input-or-span'>or</span>
          <Input
            className='comp-extraction-option-content-input comp-extraction-option-content-core-input comp-extraction-option-input-addonAfter'
            addonAfter={'%'}
            value={corePercent}
            onChange={(e) => changeValue(e, 'corePercent')}
            onBlur={(e) => inputBlur(e, 'corePercent')}
            onPressEnter={onPressEnter}
          />
        </span>
      </div>
      {ramDisplay && <div className={`comp-extraction-option-content ${className}`}>
        <span className={`comp-extraction-option-content-body comp-extraction-option-sub-span`}>Max Memory</span>
        <Input
          className='comp-extraction-option-content-input comp-extraction-option-input-addonAfter'
          value={ramPercent}
          addonAfter={'%'}
          onChange={(e) => changeValue(e, 'ramPercent')}
          onBlur={(e) => inputBlur(e, 'ramPercent')}
          onPressEnter={onPressEnter}
        />
      </div>}
    </Fragment>
  );
}

export default HPCOptions;