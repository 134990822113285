import React, { Component } from 'react';
import './index.css';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';

export const HEADTAIL = 'headTail', NEARBY = 'nearBy', CLEAR = 'clear'
class PinConnectionSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      radioValue: HEADTAIL
    }
    this.dialogRoot = document.getElementById('root');
  }

  switchExpand = () => {
    this.setState({
      expand: !this.state.expand
    })
  }

  changeConnectionRadio = (e) => {
    this.setState({
      radioValue: e.target.value
    })
  }

  update = () => {
    const { radioValue } = this.state;
    this.props.autoSetConnection(radioValue)
  }

  clear = () => {
    this.props.autoSetConnection(CLEAR)
  }

  displayPinRender = (type) => {
    let list = [['1', '2'], ['3', '4']];
    switch (type) {
      case HEADTAIL:
        list = [['1', '4'], ['2', '3']];
        break;
      case NEARBY:
      default:
        break;
    }
    return <div>
      {
        list.map(values => {
          const [left, right] = values;
          return <div key={left} className='pmic-part-quick-setup-radio-content'>
            <span>{left}</span>
            <span>{right}</span>
          </div>
        })
      }
    </div>
  }

  render() {
    const { expand, radioValue } = this.state;
    return (
      <div className='pmic-part-pin-map-table'>
        <div onClick={this.switchExpand}>
          {expand ? <DownOutlined className='pmic-part-quick-setup-title-icon' /> : <RightOutlined className='pmic-part-quick-setup-title-icon' />}
          <span className='pmic-part-quick-setup-title'>Quick Setup</span>
        </div>
        {
          expand ? <div>
            <Radio.Group name='connectionAuto' value={radioValue} onChange={this.changeConnectionRadio} className='pmic-part-quick-setup-radio-group'>
              <Radio value={HEADTAIL} className='pmic-part-quick-setup-radio'>{this.displayPinRender(HEADTAIL)}</Radio>
              <Radio value={NEARBY} className='pmic-part-quick-setup-radio'>{this.displayPinRender(NEARBY)}</Radio>
            </Radio.Group>
          </div> : null
        }
        {
          expand ? <div className='pmic-part-quick-setup-radio-group-button'>
            <Button size='small' onClick={this.clear}>clear</Button>
            <Button size='small' onClick={this.update}>Update</Button>
          </div> : null
        }
      </div>
    );
  }
}

export default PinConnectionSetting;