import { GET_BMA_CHANNEL_RESULT, UPDATE_RESULT_KEY } from './actionType';

export const getBMAChannelResult = (channelId) => ({
  type: GET_BMA_CHANNEL_RESULT,
  channelId
})

export const updateResultKey = (resultKey) => ({
  type: UPDATE_RESULT_KEY,
  resultKey
})