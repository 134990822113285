export const PROJECTS = 'projects',
  PROJECT = 'project',
  PCBs = 'PCBs',
  PCB = 'PCB',
  PROJECT_CREATE = 'project_create',
  TRASH = 'trash',
  RESULT = 'result',
  VERIFICATIONS = 'Verifications',
  VERIFICATION = 'Verification',
  VERIFICATION_CREATE = 'Verification_create',
  My_TRASH = 'my_trash',
  BUFFER = 'buffer';
