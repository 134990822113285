import {
  OPEN_UPLOAD_PROGRESS_MODAL,
  UPLOAD_PROGRESS_CHANGE,
  SHOW_ERROR_MSG,
  CLOSE_UPLOAD_PROGRESS_MODAL,
  TRANSLATION_RUNNING,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
  GET_WORK_FLOWS,
  TRANSLATION_MONITOR,
  GET_TRANSLATION_MONITOR,
  UPLOAD_PROJECT_ID,
  ADD_MSG,
  CURRENT_UPLOAD_DESIGN_ID
} from './actionType';

export const openUploadProgressModal = () => ({
  type: OPEN_UPLOAD_PROGRESS_MODAL,
})

export const changePercentAction = (uploadProgress) => ({
  type: UPLOAD_PROGRESS_CHANGE,
  uploadProgress
})

export const showErrorMsgAction = (msg) => ({
  type: SHOW_ERROR_MSG,
  msg,
})

export const closeUploadProgressModal = () => ({
  type: CLOSE_UPLOAD_PROGRESS_MODAL,
})

export const translationProgress = ({ workflowId, designId, parentID, progress }) => ({
  type: TRANSLATION_RUNNING,
  workflowId,
  designId,
  parentID,
  transProgress: progress
})

export const translationSuccess = (progress, msg) => ({
  type: TRANSLATION_SUCCESS,
  transProgress: progress,
  msg
})

export const translationFailed = (msg, progress) => ({
  type: TRANSLATION_FAILED,
  msg,
  transProgress: progress,
})

export const getWorkFlow = (workflowId, parentID, designId) => ({
  type: GET_WORK_FLOWS,
  workflowId,
  parentID,
  designId
});

export const translationMonitor = (monitor) => ({
  type: TRANSLATION_MONITOR,
  monitor
});

export const getTranslateLog = (designId) => ({
  type: GET_TRANSLATION_MONITOR,
  designId
});

export const uploadProjectID = (projectId) => ({
  type: UPLOAD_PROJECT_ID,
  projectId
});

export const addMonitorMsg = (msg) => ({
  type: ADD_MSG,
  msg
});

export const currentUploadDesignId = (designId) => ({
  type: CURRENT_UPLOAD_DESIGN_ID,
  designId
});