const ANDES_V2 = 'andes_v2';
export const LOCATION_CHANGE = `${ANDES_V2}/location`;
export const LAYER_CHANGE = `${ANDES_V2}/layer_change`;
export const OPEN_PANEL = `${ANDES_V2}/open_panel`;
export const CLOSE_PANEL = `${ANDES_V2}/close_panel`;
export const COLORBY_CHANGE = `${ANDES_V2}/colorby_change`;
export const SELECTION_CLEAR_ONE = `${ANDES_V2}/selection_clear_one`;
export const SELECTION_CLEAR = `${ANDES_V2}/selection_clear`;
export const SELECTED_ALL = `${ANDES_V2}/selected_all`;
export const CANCEL_SELECTED_ALL = `${ANDES_V2}/cancel_selected_all`;
export const SHOW_SELECTED = `${ANDES_V2}/show_selected`;
export const HIDE_SELECTED = `${ANDES_V2}/hide_selected`;
export const SELECTION_CHECKBOX_CHANGE = `${ANDES_V2}/selection_checkbox_change`;
export const CLEAN_STATUS = `${ANDES_V2}/clean_status`;
export const CHANGE_SELECTED_DISPLAY = `${ANDES_V2}/change_selected_display`;
export const SELECT_CHANGE = `${ANDES_V2}/select_change`;
export const UPDATE_PCB_REFRESH_STATUS = `${ANDES_V2}/update_pcb_refresh_status`;