/** split str to value and unit
 * @param {string} str eg: 1mm, 10GHz
 */

function valueUnitSplit(str) {
  let value = "", unit = "";

  if (typeof str !== 'string') {
    return { value, unit }
  }

  const findNum = /^[0-9]\d*(\.\d+)?e(\-|\+)?\d+|^[0-9]\d*(\.\d+)?/gi;

  const numMatch = str.match(findNum);

  if (numMatch) {
    value = numMatch[0];
    unit = str.slice(value.length, str.length);
  }
  return {
    value,
    unit
  }
}

function newValueUnitSplit(str) {
  let value = "", unit = "";

  if (typeof str !== 'string') {
    return { value, unit }
  }

  const regex = /^(\d+(\.\d*)?|\.\d+)([a-zA-Z]+)$/;
  const match = str.match(regex);

  if (match) {
    value = match[1];
    unit = match[3];
  } else {
    value = "";
    unit = str;
  }

  return { value, unit };
}

export {
  valueUnitSplit,
  newValueUnitSplit
}