import React, { Fragment } from "react";
import libraryConstructor from "../library/libraryConstructor";
import { CloseOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from "antd";
import LibrarySelectPanel from '@/components/PreLayout/librarySelectPanel';
import { getGroupLibraryList } from '../../PreLayout/PreLayoutLibrary';
import { strDelimited } from "../../helper/split";
import { CAPACITOR, INDUCTOR, RESISTOR } from "../../PreLayout";
import { calculateImpedance } from '../library';
import { preLayoutModelFreq } from '.';
import { valueUnitSplit } from "../../helper/valueUnitSplit";
import { ANDES_V2 } from "../../../constants/pageType";

const RLC_LIST = [CAPACITOR, RESISTOR, INDUCTOR];

function preLayoutSection({
  item,
  selectTemplate,
  addSection,
  delSection,
  saveLibraryConfigToServer
}) {

  if (!item.dataIndex.match("section")) {
    return;
  }

  item.render = (value, record) => {
    if (getSectionDisabled(item.dataIndex, record)) {
      return <span className="row-disabled"></span>;
    }
    const section = record[item.dataIndex];
    const text = section ? RLC_LIST.includes(section.type) ? section.value : section.length : "";
    return (
      <Fragment>
        {section && section.libType && <img src={`/lib/imgs/svgImgs/${section.libType}.svg`} alt="" className='pre-layout-section-img' />}
        {section && section.error ?
          <div className='aurora-file-check-div'>
            {text}
            <Tooltip
              title={section.error}
              overlayClassName='aurora-tooltip'
            ><QuestionCircleOutlined
              className='aurora-file-check-icon'
              onClick={(e) => { e.stopPropagation() }} />
            </Tooltip>
          </div>
          : <div className='pre-layout-relative pre-layout-center'>{text}</div>}
      </Fragment>
    );
  }

  item.onCell = (record) => {
    if (getSectionDisabled(item.dataIndex, record)) {
      return;
    }
    const section = record[item.dataIndex];
    const text = section ? RLC_LIST.includes(section.type) ? section.value : section.length : "";

    return {
      record,
      edit: true,
      dataIndex: item.dataIndex,
      customInput: LibrarySelectPanel,
      sectionName: item.displayTitle,
      text,
      libraryConstructor,
      libraryList: getGroupLibraryList(libraryConstructor),
      selectTemplate,
      inputValue: true,
      copy: true,
      calculateImpedance,
      saveLibraryConfigToServer,
      product: ANDES_V2
    }
  }

  item.title = () => {
    return (
      <div className="pre-layout-section-title">
        <span>{item.displayTitle}</span>
        {<PlusCircleOutlined
          title="Add a section"
          className="pre-layout-add-section"
          onClick={(e) => addSection(e, item)} />}
        {item.allowDel && <CloseOutlined
          className="pre-layout-delete-section"
          title="Delete the section"
          onClick={(e) => delSection(e, item)} />}
      </div>
    );
  }
}

function getSectionDisabled(dataIndex, record) {
  const sectionIndex = parseInt(strDelimited(dataIndex, "_", { returnIndex: 1 }));
  if ((!record || sectionIndex < 1) || (record[dataIndex] && record[dataIndex].type)) {
    return false;
  }
  const prevDataIndex = `section_${sectionIndex - 1}`;
  const nextDataIndex = `section_${sectionIndex + 1}`;

  if (sectionIndex === 1) {
    if (!record[prevDataIndex] || !record[prevDataIndex].type) {
      return true;
    }
  } else {
    if ((!record[nextDataIndex] || !record[nextDataIndex].id || !record[prevDataIndex].type)
      && (!record[prevDataIndex] || !record[prevDataIndex].type)) {
      return true;
    }
  }
}

function getModelFrequency(model) {
  let frequency = model.frequency || preLayoutModelFreq();
  let _frequency = [];
  for (let item of frequency) {
    let stateItem = { type: item.type };
    const { value: start, unit: startUnit } = valueUnitSplit(item.start);
    const { value: end, unit: endUnit } = valueUnitSplit(item.end);
    stateItem = {
      ...stateItem,
      start,
      startUnit,
      end,
      endUnit
    }
    if (item.type === "log") {
      const { value: dec, unit: decUnit } = valueUnitSplit(item.dec);
      stateItem.dec = dec;
      stateItem.decUnit = decUnit;
    } else if (item.type === "linear") {
      const { value: step, unit: stepUnit } = valueUnitSplit(item.step);
      stateItem.step = step;
      stateItem.stepUnit = stepUnit;
    }
    _frequency.push(stateItem)
  }
  return _frequency;
}

function getPreLayoutModelFreq(frequency) {
  let _frequency = [];
  for (let item of frequency) {
    let data = { type: item.type };
    data.start = item.start + item.startUnit;
    data.end = item.end + item.endUnit;

    if (item.type === "log") {
      data.dec = item.dec + item.decUnit;
    } else if (item.type === "linear") {
      data.step = item.step + item.stepUnit;
    }
    _frequency.push(data);
  }
  return _frequency;
}

export {
  preLayoutSection,
  getModelFrequency,
  getPreLayoutModelFreq
}