import { getResultFileInAndes } from '../../api/Andes';
import { getResultsCurveInAndesPromise } from '../projectCtrl';
import ParameterData from '../../Result/Public/sparameter/parameterData';
import { npiFileParse } from '../../Result/Andes/dataHelper';
class AndesParameterData extends ParameterData {
  constructor(props) {
    super(props);
    const { fileType, time, id, projectId, pcb } = props;
    this.id = `${fileType}-${time}-${id}`;
    this.projectId = projectId || null;
    this.pcbId = id || null;
    this.pcb = pcb || null;
    this.fileType = fileType || null;
    this.time = time || null;
    this.initParseNpiFile(npiFileParse);
  }

  getNpiFile = (index, path) => {
    return new Promise((resolve, reject) => {
      let _path = path ? path : `result/${this.pcbId}_mm.npi`;
      getResultFileInAndes({ projectId: this.projectId, verificationId: this.verificationId, path: _path }).then(res => {
        const parseData = this.parseNpiFile(res.data);
        this.ports = parseData.ports;
        this.freq = parseData.freq;
        this.initMatrix(index);
        resolve(this);
      }, error => {
        console.error(error);
        resolve(this);
      })
    })
  }

  getHistoryNpiFile = (index) => {
    return new Promise((resolve, reject) => {
      let path = `history/${this.time}/result/${this.pcbId}_mm.npi`;
      this.getNpiFile(index, path).then(res => {
        resolve(res);
      });
    })
  }

  /**
   * Get curve data
   *
   * @param param - s,y,z
   * @param value - am for amplitude, ​ph for phase​, re for real, im for imaginary, db for dB
   * type - s/y/z + am/ph/re/im
   */
  getCurveData = ({ row, col, param, value, fileIndex }) => {
    return new Promise((resolve, reject) => {
      // TODO - multi file
      if (!this.matrix || !this.matrix[row] || this.matrix[row][col]) {
        this.initMatrix(fileIndex);
      }
      if (!this.matrix) {
        resolve(null);
        return;
      }
      let item = this.matrix[row][col],
        type = param + value;

      if (item[type]) {
        resolve({
          type,
          color: item.color,
          curve: item[type]
        })
      } else {
        if (value === 'am' || value === 'ph' || value === 'db') {
          let pathType = 'sam';
          if (value === 'ph') {
            pathType = type;
          }
          let path = `result/${this.pcbId}_mm.${pathType}`;
          if (this.fileType === 'history') {
            path = `history/${this.time}/result/${this.pcbId}_mm.${pathType}`;
          }

          getResultsCurveInAndesPromise({ verificationId: this.verificationId, cols: [col], rows: [row], path }).then((response) => {
            item[type] = response[0].curve;
            resolve({
              type,
              color: item.color,
              curve: item[type]
            })
          })
        } else {
          this.getCurveData({ row, col, param, value: 'am' }).then(amData => {
            this.getCurveData({ row, col, param, value: 'ph' }).then((phData) => {
              const cos = Math.cos;
              const sin = Math.sin;
              item[param + 're'] = amData.curve.map(function (am, i) {
                return am * cos(phData.curve[i]);
              });
              item[param + 'im'] = amData.curve.map(function (am, i) {
                return am * sin(phData.curve[i])
              });
              resolve({
                type,
                color: item.color,
                curve: item[type]
              })
            })
          })
        }
      }
    })
  }
}


export default AndesParameterData;