import CeGeometry from './CeGeometry';
import CeVertex from './CeVertex';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeVertices = function () {
  CeGeometry.call(this, "Pnts");
  this.mPntList = [];
}

// subclass extends superclass
CeVertices.prototype = Object.create(CeGeometry.prototype);
CeVertices.prototype.constructor = CeVertices;

CeVertices.prototype.add = function (x, y) {
  var pnt = new CeVertex();
  pnt.SetData(x, y);
  this.mPntList.push(pnt);
}

CeVertices.prototype.addPnt = function (pnt) {
  this.mPntList.push(pnt);
}

CeVertices.prototype.remove = function (pos) {
  if (pos >= 0 && pos < this.mPntList.length)
    this.mPntList.splice(pos, 1);
}

CeVertices.prototype.size = function () {
  return this.mPntList.length;
}

CeVertices.prototype.get = function (i) {
  return this.mPntList[i];
}

CeVertices.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.GetDataString();
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeVertices.prototype.ReadFromIODataNode = function (dataItem) {
  var dataVals = dataItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeVertices.prototype.equals = function (obj) {
  if (obj instanceof CeVertices == false)
    return false;

  if (this.mPoints.length != obj.mPoints.length)
    return false;

  for (var i = 0; i < this.mPoints.length; i++) {
    if (this.mPoints[i].equals(obj.mPoints[i]) == false)
      return false;
  }

  return true;
}

CeVertices.prototype.GetDataString = function () {
  var dataVals = new StringList();
  var numPnts = this.mPntList.length;
  dataVals.push(numPnts);
  for (var i = 0; i < numPnts; i++) {
    dataVals.push(this.mPntList[i].mX);
    dataVals.push(this.mPntList[i].mY);
  }
  return dataVals;
}

CeVertices.prototype.UpdateByString = function (dataVals) {
  // n x0 y0, x1 y1 ... xn yn
  if (dataVals.size() < 3)
    return false;

  var numPnts = parseInt(dataVals.shift());
  if (dataVals.size() != (2 * this.mNumPnt))
    return false;

  this.mPntList = [];
  var x, y;
  for (var i = 0; i < numPnts; i++) {
    x = parseFloat(dataVals.get(i));
    i++;
    y = parseFloat(dataVals.get(i));
    this.add(x, y);
  }

  return true;
}

export default CeVertices;