import React, { Component, Fragment } from 'react';
import { CloseCircleFilled, FileTextOutlined, LoadingOutlined, SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { Input, Tooltip, Transfer, Divider } from 'antd';
import { numberCheck } from "@/services/helper/dataProcess";
import TagsInput from '../TagsInput';
import { getPowerNetsByKewWords } from '../../services/PCBHelper';
import _ from 'lodash';
import { getPowerNetsSearchStatus } from '../../services/Sierra/library';
import { updateTables } from '../../services/Sierra/helper/powerNetsTableHelper';
import sleep from '../../services/helper/sleep';
import MsgDialog from '../MsgDialog';
import VirtualTable from '../VirtualTable';
import './index.css';
import auroraDBJson from '../../services/Designs/auroraDbData';

const leftTableColumns = [
  {
    dataIndex: 'net',
    title: 'Nets',
    width: '100%'
  }
];
const rightTableColumns = [
  {
    dataIndex: 'net',
    title: 'Power Nets',
    width: '70%',
  }, {
    dataIndex: 'voltage',
    title: 'Voltage (V)',
    width: '30%',
  }
];
class PowerNetsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      designId: props.designId,
      powerNets: [],
      searchVoltage: "",
      inputVoltage: "",
      pcbNets: [],
      searchLoading: false,
      filterLoading: false,
      netKeywordsInput: "",
      //
      targetKeys: [],
      dataSource: [],
      updateLeftDataStatus: false,
      updateRightDataStatus: false,
      reSearchVisible: false,
      isRegular: false,
      searchHeight: 72,
      filterList: [],// {filter,voltage}
      logs: [],
      logVisible: false,
      showSearch: false,
      inputSearchNet: "",
      inputSearchPowerNet: "",
      searchNets: [],
      netKeywords: []
    }
  }

  componentDidMount = () => {
    this.props.onRef(this);
    this.initColumns();
    this.getTableData();
  }

  initColumns = () => {

    rightTableColumns[0].render = (text, record) => {
      return <div className='pcb-power-table-item' title={text}>
        <span>{text}</span>
      </div>;
    }

    rightTableColumns[1].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'voltage',
        handleSave: this.voltageUpdate,
      }
    }

    rightTableColumns[1].render = (text) => {
      return <div><span>{text}</span></div>
    }
  }

  voltageUpdate = (record) => {
    const { net, voltage } = record;
    const { powerNets, updateRightDataStatus, dataSource } = this.state;
    let _powerNets = [...powerNets], _dataSource = [...dataSource];
    const index = _powerNets.findIndex(item => item.net === net);
    const _index = _dataSource.findIndex(item => item.net === net);
    if (index < 0) {
      return;
    }
    if (numberCheck(voltage)) {
      return;
    }
    _powerNets[index].voltage = voltage;
    _dataSource[_index].voltage = voltage;
    this.setState({
      powerNets: _powerNets,
      dataSource: _dataSource,
      updateRightDataStatus: !updateRightDataStatus
    })
  }

  deleteAllNets = (e) => {
    e && e.stopPropagation();
    this.setState({
      powerNets: [],
      targetKeys: [],
      dataSource: this.state.pcbNets.map(item => { return { net: item, key: item } }),
      updateRightDataStatus: !this.state.updateRightDataStatus
    })
  }

  componentDidUpdate(prevProps) {
    const { designId, update } = this.props;
    const switchPcb = designId !== prevProps.designId;
    if (switchPcb || (update && update !== prevProps.update)) {
      this.setState({
        designId: designId,
        pcbNets: switchPcb ? [] : this.state.pcbNets
      }, () => {
        this.getTableData()
      })
    }
  }

  getTableData = async (reLoad) => {
    const { designId, updateLeftDataStatus, updateRightDataStatus } = this.state;
    try {
      if (designId) {
        !reLoad && this.props.changeLoading(true)
        const nets = await this.props.powerStore.getTable(designId);
        const table = nets && nets.length ? nets : [];
        let _pcbNets = []
        if (!reLoad) {
          await this.props.setDefaultData();
          _pcbNets = auroraDBJson.getNetNames(designId) || [];
        } else {
          _pcbNets = this.state.pcbNets;
        }

        this.prevPowerNets = JSON.parse(JSON.stringify(table));
        const dataSource = _pcbNets.map(item => { return { net: item, key: item, voltage: (table.find(it => it.net === item) || {}).voltage } })
        this.setState({
          powerNets: table,
          pcbNets: _pcbNets,
          dataSource,
          targetKeys: table.map(item => item.net),
          updateLeftDataStatus: !updateLeftDataStatus,
          updateRightDataStatus: !updateRightDataStatus,
          filterLoading: false,
          logVisible: false
        }, () => {
          !reLoad && this.props.changeLoading(false)
        })
      }
    } catch (error) {
      console.error(error)
      this.setState({
        filterLoading: false
      })
      !reLoad && this.props.changeLoading(false)
    }
  }

  saveTable = async (notSave = false) => {
    const { designId, powerNets } = this.state;
    let _save = false;
    if (!_.isEqual(this.prevPowerNets, powerNets)) {
      !notSave && await this.props.powerStore.updateTable([{ designId, nets: powerNets }]);
      _save = true;
    }
    return _save
  }

  changeNetKeywordsList = (type, list) => {
    this.setState({
      netKeywords: list
    }, () => {
      this.getSearchHeight()
    })
  }

  getSearchHeight = () => {
    const transferEle = document.getElementById("pcb-power-nets-transfer-search-main");
    const searchHeight = transferEle ? transferEle.offsetHeight : 72;
    this.setState({
      searchHeight
    })
  }

  clearAllKeywords = (e) => {
    e && e.stopPropagation()
    this.setState({
      netKeywords: [],
      searched: false,
      updateLeftDataStatus: !this.state.updateLeftDataStatus
    }, () => {
      this.getSearchHeight()
    })
  }

  isSuffixIcon = (isRegular) => {
    const { netKeywords, searchLoading } = this.state;
    const Icon = searchLoading ? LoadingOutlined : SearchOutlined
    return (
      <Fragment>
        <Tooltip
          overlayClassName="aurora-tooltip"
          title={isRegular ? "Regular Expression" : "Non Regular Expression"}
          key='tooltip-icon-input'>
          <div
            onClick={() => this.updateRegularValue(!isRegular)}
            className={isRegular ? 'regular-icon-selected' : 'regular-icon'}
          >.*</div>
        </Tooltip>
        {isRegular ?
          <Fragment>
            {netKeywords && netKeywords.length ? <Tooltip
              overlayClassName="aurora-tooltip"
              title={"Clear all"}
              key='tooltip-clear-icon-input'>
              <CloseCircleFilled
                className="pcb-power-nets-clear-icon"
                onClick={(e) => this.clearAllKeywords(e)} />
            </Tooltip> : null}
            <Icon
              className="pcb-power-nets-search-icon"
              onClick={!searchLoading ? (e) => this.getSearchNets(e) : null} />
          </Fragment>
          : null}
      </Fragment>
    );
  }

  updateRegularValue = (isRegular) => {
    this.setState({
      isRegular,
      inputSearchNet: "",
      searched: false
    })
  }

  changeSearchVoltage = (e) => {
    this.setState({
      inputVoltage: e.target.value
    })
  }

  saveSearchVoltage = (e) => {
    const value = e.target.value;
    if (value && numberCheck(value)) {
      this.setState({
        inputVoltage: this.state.searchVoltage
      })
      return;
    }
    this.setState({
      searchVoltage: value
    })
  }

  getSearchNetsByFilter = async (applyAll) => {
    const { designId, filterList, powerNets } = this.state;
    if (!designId) {
      return;
    }
    this.setState({
      filterLoading: true,
      logs: [],
      logVisible: true
    }, async () => {
      const _filterList = filterList.map(item => {
        return {
          filter: item.filter,
          voltage: item.voltage || ""
        }
      })
      try {
        this.getSearchLog();
        const save = this.saveTable(true);
        save && await updateTables([{ designId, nets: powerNets }]);
        this.props.powerStore.saveTable(designId, powerNets);
        await this.props.searchPowerNetsByKeywords({ designId, netLibraryList: _filterList, applyAll });
      } catch (error) {
        this.setState({
          filterLoading: false,
          logs: [...(this.state.logs || []), "Search power nets failed!"]
        })
      }
    })
  }

  getSearchLog = async () => {
    let logs = this.state.logs || [];
    let UPDATING = 1, UPDATE_COMPLETED = 2, UPDATE_FAILED = 3;
    let status = UPDATING;
    while (status === UPDATING) {
      try {
        const res = await getPowerNetsSearchStatus();
        if (!res) {
          status = UPDATE_COMPLETED;
        } else {
          status = res.status;
          logs = res.logs || logs;
        }

        this.setState({
          logs
        })
        //success
        if (status === UPDATE_COMPLETED) {
          this.props.powerStore.clearAll()
          this.getTableData(true);
        }
        //delay 2s
        await sleep(1000)
      } catch (error) {
        status = UPDATE_FAILED;
        this.props.powerStore.clearAll()
        this.getTableData(true);
        await sleep(1000)
      }
    }
  }

  netKeywordsInputChange = (value) => {
    this.setState({
      netKeywordsInput: value
    })
  }

  onChange = (nextTargetKeys) => {
    const { powerNets, targetKeys, searchVoltage, dataSource, updateRightDataStatus, updateLeftDataStatus } = this.state;
    let _powerNets = [...powerNets], _dataSource = [...dataSource];
    _powerNets = _powerNets.filter(item => nextTargetKeys.includes(item.net));
    const newNets = nextTargetKeys.filter(item => !targetKeys.includes(item));
    if (newNets.length) {
      _powerNets.push(...newNets.map(item => {
        const index = _dataSource.findIndex(it => it.net === item);
        if (index > -1) {
          _dataSource[index].voltage = searchVoltage || "";
        }
        return {
          net: item,
          voltage: searchVoltage || ""
        }
      }))

    }
    this.setState({
      targetKeys: nextTargetKeys,
      powerNets: _powerNets,
      dataSource: _dataSource,
      updateRightDataStatus: !updateRightDataStatus,
      updateLeftDataStatus: !updateLeftDataStatus
    });
  }

  applyLibrary = (visible, e) => {
    e && e.stopPropagation();
    this.setState({
      reSearchVisible: visible
    })
  }

  saveNewFilters = (filters, applyAll) => {
    if (!filters.length) {
      return;
    }
    this.setState({
      filterList: filters
    }, () => {
      this.getSearchNetsByFilter(applyAll)
    })
  }

  openReSearchNets = () => {
    const { designId } = this.state;
    const { ReSearchNets } = this.props;
    const { filterList } = this.state;
    return <ReSearchNets
      designId={designId}
      isReSearch={true}
      filterList={filterList.map(item => item.filter)}
      saveFilters={this.saveNewFilters}
      closeLibraryPanel={() => this.applyLibrary(false)}
    />
  }

  openLog = (e) => {
    e && e.stopPropagation()
    this.setState({
      logVisible: true
    })
  }

  changeSearchNet = (e) => {
    this.setState({
      inputSearchNet: e.target.value
    })
  }

  getSearchNets = async (e) => {
    e && e.stopPropagation()
    const { designId, pcbNets, netKeywords, isRegular, updateLeftDataStatus } = this.state;
    if (!designId || !netKeywords.length) {
      return;
    }
    this.setState({
      searchLoading: true
    }, async () => {
      const searchNets = getPowerNetsByKewWords({ netKeywords, isRegular, pcbNets });
      this.setState({
        searchNets,
        searchLoading: false,
        searched: true,
        updateLeftDataStatus: !updateLeftDataStatus
      })
    })
  }

  searchRender = () => {
    const { netKeywords, isRegular, inputSearchNet } = this.state;
    return <div className="component-pcb-transfer-search-net-content">
      {!isRegular ? <Input
        className='aurora-input'
        placeholder='Search Nets'
        value={inputSearchNet}
        onChange={(e) => this.changeSearchNet(e)}
        suffix={this.isSuffixIcon(isRegular)}
      />
        : <TagsInput
          type="netKeywords"
          tagList={netKeywords}
          placeholder='Search Nets'
          changeTagList={this.changeNetKeywordsList}
          inputChange={this.netKeywordsInputChange}
          className={isRegular ? 'power-pcb-select-regular-input' : 'power-pcb-select-no-regular-input'}
          isSuffix={this.isSuffixIcon(isRegular)}
        />}
    </div>
  }

  voltageRender = () => {
    const { inputVoltage } = this.state;
    return <div className='component-pcb-transfer-search-net-content component-pcb-transfer-voltage-content'>
      <Input
        className='aurora-input'
        placeholder='Voltage'
        value={inputVoltage}
        onChange={(e) => this.changeSearchVoltage(e)}
        onBlur={((e) => this.saveSearchVoltage(e))}
        addonAfter={"V"}
      />
    </div>
  }

  clickDialogOkButton = () => {
    this.setState({
      logVisible: false
    })
  }

  getNets = () => {
    const { dataSource, targetKeys, inputSearchNet, searchNets, searched, isRegular, netKeywords } = this.state;
    if (isRegular) {
      return searched && netKeywords && netKeywords.length ?
        dataSource.filter(item => targetKeys.includes(item.net) || searchNets.includes(item.net))
        : dataSource
    }

    return inputSearchNet ?
      dataSource.filter(item => targetKeys.includes(item.net) || (item.net || "").toLowerCase().indexOf((inputSearchNet || "").toLowerCase()) !== -1)
      : dataSource
  }

  filterRender = () => {
    const { reSearchVisible, logs, logVisible, filterLoading } = this.state;
    const Icon = filterLoading ? LoadingOutlined : FilterOutlined;
    return (
      <Fragment>{logs && logs.length ?
        <Fragment>
          <Tooltip
            overlayClassName="aurora-tooltip"
            title={"Search Log"}
            key='tooltip-log-icon-input'>
            <FileTextOutlined
              className={logVisible ? "pcb-power-nets-log-icon pcb-power-nets-log-icon-select" : "pcb-power-nets-log-icon"}
              onClick={(e) => this.openLog(e)} />
          </Tooltip>
          <Divider type="vertical" />
        </Fragment> : null}
        <Tooltip
          overlayClassName="aurora-tooltip"
          title={"Power Nets Filter"}
          key='tooltip-filter-icon-input' >
          <Icon
            className={`pcb-power-nets-filter-icon ${reSearchVisible ? "pcb-power-nets-filter-icon-select" : ""}`}
            onClick={filterLoading ? null : (e) => this.applyLibrary(true, e)} />
        </Tooltip>
      </Fragment>
    );
  }

  render() {
    const { updateLeftDataStatus, updateRightDataStatus, targetKeys, reSearchVisible, searchHeight, logs, logVisible } = this.state;
    const data = this.getNets();
    const { panelMaxHeight, maxWidth } = this.props;

    return <div className='component-pcb-power-nets-table' style={{ height: panelMaxHeight - 208 }}>
      <TableTransfer
        dataSource={data}
        targetKeys={targetKeys}
        panelMaxHeight={panelMaxHeight}
        maxWidth={maxWidth}
        showSearch={true}
        onChange={this.onChange}
        updateLeftDataStatus={updateLeftDataStatus}
        updateRightDataStatus={updateRightDataStatus}
        titles={[
          <div>
            {this.filterRender()}
            <strong>All Nets</strong>
          </div>,
          <strong>Power Nets</strong>]}
        locale={{
          itemUnit: 'Nets',
          itemsUnit: 'Nets',
          searchPlaceholder: 'Search Nets'
        }}
        filterOption={(inputValue, item) => {
          return (item.net || "").toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1
        }}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
        searchHeight={searchHeight}
        voltageRender={this.voltageRender}
        searchRender={this.searchRender}
      />
      {reSearchVisible ? this.openReSearchNets() : null}
      {logVisible ? <MsgDialog
        mask={false}
        type="log"
        allowButton={true}
        allowDownload={true}
        messageList={logs}
        fileName="search.log"
        clickOkButton={this.clickDialogOkButton}
      /> : null}
    </div>
  }
}

const TableTransfer = ({
  leftColumns,
  rightColumns,
  dataSource,
  targetKeys,
  panelMaxHeight,
  updateLeftDataStatus,
  updateRightDataStatus,
  powerNetSelectedKeys,
  leftSearchRender,
  rightSearchRender,
  searchHeight,
  voltageRender,
  searchRender,
  inputSearchNet,
  maxWidth,
  ...restProps }) => (
  <Transfer
    dataSource={dataSource}
    targetKeys={targetKeys}
    {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      let columns = [], updateDataStatus = updateLeftDataStatus, height = panelMaxHeight - 300


      if (direction === "left") {
        columns = leftColumns;
        updateDataStatus = updateLeftDataStatus;
        height = panelMaxHeight - 268 - searchHeight;
      } else {
        columns = rightColumns;
        updateDataStatus = updateRightDataStatus;
        height = panelMaxHeight - 304;
      }

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
        columnTitle: <span></span>,
        columnWidth: 36
      };

      const SETTINGS = {
        minIndex: 0,
        maxIndex: filteredItems.length,
        startIndex: 0,
        itemHeight: 39,
        amount: 50,
        tolerance: 10
      }

      return <div>
        {direction === "left" ? <div id="pcb-power-nets-transfer-search-main">
          {searchRender()}
          {voltageRender()}
        </div> : null}
        <VirtualTable
          rowSelection={rowSelection}
          className={`pcb-power-nets-table pcb-power-nets-${direction}-table`}
          rowKey='net'
          loading={false}
          columns={columns}
          pagination={false}
          size="small"
          dataSource={filteredItems}
          bordered
          setting={SETTINGS}
          disableSort={true}
          height={height}
          scrollId={`virtual-table-scroll-${direction}`}
          updateDataStatus={updateDataStatus}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
        {/* <EditableTable
          rowSelection={rowSelection}
          className={`pcb-power-nets-table pcb-power-nets-${direction}-table`}
          rowKey='net'
          loading={false}
          columns={columns}
          pagination={false}
          size="small"
          dataSource={filteredItems}
          bordered
          scroll={{ y: height, x: maxWidth * 0.5 }}
          virtual={true}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        /> */}
      </div>
    }}
  </Transfer>
);

export default PowerNetsTable;