import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { Select, Button, Progress, TreeSelect, message, Tooltip, Input, Collapse, Divider } from "antd";
import Panel from '@/components/Panel';
import {
  updateReportInfo,
  clearReportInfo,
  saveReportConfig,
  getReport
} from '../../store/project/action';
import DelConfirm from '@/components/DelConfirm';
import ReportTemplate from "@/components/ReportTemplate/reportTemplate";
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { timeFormat } from '@/services/helper/timeFormat';
import designConstructor from "@/services/helper/designConstructor";
import { generateChannelReport } from '../../../../services/Andes_v2/channel';
import channelConstructor from "@/services/Andes_v2/channel/channelConstructor";
import projectConstructor from "@/services/Andes_v2/project/projectConstructor";
import ReportAdvance from '@/components/reportAdvance';
import './index.css';
import endToEndChannelConstructor from "../../../../services/Andes_v2/endToEndChannel/endToEndChannelConstructor";
import { END_TO_END_CHANNEL, END_TO_END_CHANNELS } from "../../../../constants/treeConstants";
import { ANDES_V2 } from "../../../../constants/pageType";
import { CPHY } from "../../../../services/PCBHelper/constants";
import { numberCheck } from "../../../../services/helper/dataProcess";
import { DriverOrReceiver, DriverPoints, getDefaultList, ReceiverPoints } from "../../../../services/Andes_v2/constants";
import { RETURNLOSS, COMRETURN, DIFFTOCOM, CROSSCOUPLING } from '../../../../services/helper/reportHelper';

const Option = Select.Option;
const Driver = "Driver", Receiver = "Receiver";

const referenceLinesColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 100,
    ellipsis: true
  },
  {
    title: 'Channel Spec (dB@GHz)',
    dataIndex: 'points',
    key: 'points',
    width: 180,
    ellipsis: true
  },
  {
    title: 'Driver/Receiver',
    key: DriverOrReceiver,
    children: [
      {
        title: 'Driver Spec (dB@GHz)',
        dataIndex: DriverPoints,
        key: DriverPoints,
        width: 180,
        ellipsis: true
      },
      {
        title: 'Receiver Spec (dB@GHz)',
        dataIndex: ReceiverPoints,
        key: ReceiverPoints,
        width: 180,
        ellipsis: true,
      }
    ]
  },
  {
    title: 'Reference lines',
    key: 'referenceLines',
    children: [
      {
        title: 'Magnitude (dB)',
        dataIndex: 'y',
        key: 'y',
        width: 100,
        ellipsis: true
      },
      {
        title: 'Frequency (GHz)',
        dataIndex: 'x',
        key: 'x',
        width: 100,
        ellipsis: true
      }
    ]
  },
  {
    title: 'Dynamic Range',
    key: 'dynamicRange',
    children: [
      {
        title: 'Magnitude (dB)',
        dataIndex: 'yDynamicRange',
        key: 'yDynamicRange',
        width: 100,
        ellipsis: true
      },
      {
        title: 'Frequency (GHz)',
        dataIndex: 'xDynamicRange',
        key: 'xDynamicRange',
        width: 100,
        ellipsis: true
      }
    ]
  }
]

function getTreeData(list) {
  return list.map(item => {
    return {
      title: item.name,
      value: item.id,
      key: item.id,
      children: item.children ? item.children.map(it => ({ title: it.name, value: it.id, key: it.id })) : []
    }
  })
}

class ReportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: 'PPTX',
      closeVisible: false,
      currentChannel: null,
      templateList: [],
      maxLength: 0,
      maxWidth: 700,
      maxHeight: 400,
      downloadLoading: true,
      errorMsg: {},
      symbolRate: '',
      frequency: {},
      updateMaskTypes: [],
      driver: [],
      receiver: []
    }
    this.dialogRoot = document.getElementById('root');
    this.firstUpdateConfigs = true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 400),
      maxWidth: getPanelMaxWidth(offset, 700)
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.selectChannel();
    //await this.getReportConfig();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.channelId !== this.props.channelId) {
      this.selectChannel();
      // await this.getReportConfig();
    }
    if (prevProps.projectId !== this.props.projectId) {
      //clear prev project report info
      this.props._clearReportInfo();
      this.props.closePanel();
    }

    // Initialize config data
    const { reportConfig, serdesType } = this.props;
    if (this.firstUpdateConfigs && serdesType === CPHY && reportConfig && reportConfig.custom) {
      const { symbolRate = 2.5, fh, fMax, fINTMin = 0.45, fLPMax = 0.01, driver = [], receiver = [] } = reportConfig && reportConfig.custom ? reportConfig.custom : {};
      this.setState({
        symbolRate,
        frequency: {
          fh: fh || symbolRate * 0.5,
          fMax: fMax || symbolRate * 0.75,
          fINTMin,
          fLPMax
        },
        driver,
        receiver
      })
      delete this.firstUpdateConfigs;
    }
  }

  selectChannel = () => {
    const { channelId, projectId, endToEndChannelId, viewList, reportConfig } = this.props;
    let findSelect = null, isMultiPCB = false;
    if (viewList.includes(END_TO_END_CHANNEL)) {
      findSelect = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId);
      isMultiPCB = true;
    } else {
      findSelect = channelConstructor.getChannel(channelId);
    }

    const { currentChannel } = this.state;
    let _currentChannel = { ...currentChannel };

    if (findSelect) {
      _currentChannel = { id: findSelect.id, name: findSelect.name, design: findSelect.pcbName, type: findSelect.type };
    } else {
      const designs = designConstructor.getDesignValues(projectId).map(item => { return { ...item, children: channelConstructor.getChannelValues(item.id) } });
      const design = designs.find(item => item.children && item.children.length);
      if (design && design.children && design.children[0]) {
        const findC = design.children[0];
        _currentChannel = { id: findC.id, name: findC.name, design: findC.pcbName }
      }
      isMultiPCB = false;
    }
    this.setState({
      currentChannel: _currentChannel
    }, () => {
      if (reportConfig) {
        let config = { ...reportConfig };
        config.isMultiPCB = isMultiPCB;
        this.props._updateReportInfo({ reportConfig: config });
      }
    })
  }

  DownloadReport = (e) => {
    const { reportType, currentChannel } = this.state;
    const { reportConfig } = this.props;
    if (!currentChannel || !currentChannel.id) { return }
    this.props._updateReportInfo({ reportVisible: true, reportProgress: 0 });
    const { projectId } = this.props;
    const find = projectConstructor.getProject(projectId);
    let projectName = find ? find.name : "";

    let mime, fileName;
    const _fileName = `${projectName}_${timeFormat('YYYYMMDD_HHmmss')}`;
    let format = 'pptx';

    if (reportType === 'PDF') {
      format = 'pdf';
      mime = 'application/pdf';
      fileName = `${_fileName}.pdf`;
    } else if (reportType === 'PPTX') {
      format = 'pptx';
      mime = 'application/vnd.ms-powerpoint';
      fileName = `${_fileName}.pptx`;
    }

    const _config = reportConfig ? JSON.parse(JSON.stringify(reportConfig)) : {
      channelIds: [],
      language: 'english',
      templateId: "",
      format: "pptx",
      fileName: ""
    };
    /*    const channels = channelConstructor.getChannelValues(projectId) || []; */
    const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
    let isMultiPCB = false;
    if (endToEndList.find(item => item.id === currentChannel.id)) {
      isMultiPCB = true;
    }

    const config = {
      ..._config,
      language: _config.language ? _config.language : "english",
      channelIds: currentChannel ? [currentChannel.id] : [],
      format,
      fileName
    };
    config.isMultiPCB = isMultiPCB;
    this.props._updateReportInfo({ reportConfig: config });
    generateChannelReport(projectId, { ...config, fileName: _fileName }).then(res => {
      this.props._getReport({ projectId, channelId: currentChannel.id, format, mime, fileName });
    }, error => {
      message.error(`Download report in ${reportType} format failed! ` + error)
      this.props._updateReportInfo({ reportVisible: false });
    })
    this.props._saveReportConfig(currentChannel.id, isMultiPCB);
  }

  changeReportType = (value) => {
    this.setState({
      reportType: value
    })
    this.props._updateReportInfo({ reportMessage: "" });
  }

  changeReportLanguage = (value) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.language = value;
    this.props._updateReportInfo({ reportConfig: config, reportMessage: "" });
  }

  closeModal = () => {
    const { reportVisible, projectId } = this.props;
    const { currentChannel } = this.state;
    if (reportVisible) {
      this.setState({
        closeVisible: true
      })
    } else {
      const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
      let isMultiPCB = false;
      if (endToEndList.find(item => item.id === currentChannel.id)) {
        isMultiPCB = true;
      }
      if (!currentChannel || !currentChannel.id) { return }
      this.props._saveReportConfig(currentChannel.id, isMultiPCB);
      //clear prev project report info
      this.props._clearReportInfo();
      this.props.closePanel();
    }
  }

  deleteConfirm = () => {
    // save report config
    const { projectId } = this.props;
    const { currentChannel } = this.state;
    const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
    let isMultiPCB = false;
    if (endToEndList.find(item => item.id === currentChannel.id)) {
      isMultiPCB = true;
    }
    if (!currentChannel || !currentChannel.id) { return }
    this.props._saveReportConfig(currentChannel.id, isMultiPCB);
    //clear prev project report info
    this.props._clearReportInfo();
    this.props.closePanel();
  }

  cancelDel = () => {
    this.setState({
      closeVisible: false
    })
  }

  onMinimize = () => {
    this.props.closePanel();
    // save report config
    const { projectId } = this.props;
    const { currentChannel } = this.state;
    const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
    let isMultiPCB = false;
    if (endToEndList.find(item => item.id === currentChannel.id)) {
      isMultiPCB = true;
    }
    if (!currentChannel || !currentChannel.id) { return }
    this.props._saveReportConfig(currentChannel.id, isMultiPCB);
  }

  onTreeChange = (key) => {
    const { value } = key;
    const { currentChannel } = this.state;
    const { reportConfig } = this.props;
    const findChannel = channelConstructor.getChannel(value);
    const findEndToEndChannel = endToEndChannelConstructor.getEndToEndChannel(value);
    if ((!findChannel || !Object.keys(findChannel).length)
      && (!findEndToEndChannel || !Object.keys(findEndToEndChannel).length)) {
      return;
    }
    let isMultiPCB = false, selected = null;
    if (findChannel) {
      selected = findChannel;
    } else if (findEndToEndChannel) {
      selected = findEndToEndChannel
      isMultiPCB = true;
    }
    if (!selected) {
      return;
    }
    let config = { ...reportConfig };
    config.channelIds = [value];
    config.isMultiPCB = isMultiPCB;
    // Save the old config and update the data in redux
    this.props._updateReportInfo({ reportConfig: config, reportConfigLoading: true });
    this.props._saveReportConfig(currentChannel.id, isMultiPCB);
    // Get the new config and keep the old config if obj is empty but need to modify the channelIds
    this.setState({
      currentChannel: { id: selected.id, name: selected.name, design: selected.pcbName || null, type: selected.type }
    }, () => {
      this.props.getReportConfig(value, isMultiPCB);
    })
  }

  getChannelSelectList = (projectId) => {
    const designs = designConstructor.getDesignValues(projectId).map(item => {
      return {
        ...item,
        children: channelConstructor.getChannelValues(item.id)
      }
    });
    const endToEndList = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || []
    const endToEndChannels = endToEndList.length ? [{
      id: END_TO_END_CHANNELS,
      name: "Multi-PCB Channels",
      children: endToEndList
    }] : [];
    return getTreeData([...designs.filter(item => !!item.children.length), ...endToEndChannels]);
  }

  updateReportConfig = ({ templateId }) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.templateId = templateId;
    this.props._updateReportInfo({ reportConfig: config });

    if (this.state.downloadLoading) {
      this.setState({
        downloadLoading: false
      })
    }
  }

  updateMessage = (message) => {
    this.props._updateReportInfo({ reportMessage: message });
  }

  changeReferenceLineData = (data, isXTable) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.custom = config.custom ? config.custom : {};
    config.custom.guideValue = data;
    config.custom.isXTable = isXTable;
    this.props._updateReportInfo({ reportConfig: config });
  }

  changeIsXTable = (data) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.custom.isXTable = data
    this.props._updateReportInfo({ reportConfig: config });
  }

  changeConfigs = (changeType) => {
    const { symbolRate, frequency, driver, receiver } = this.state;
    const { reportConfig } = this.props;

    let guideValue = [...reportConfig.custom.guideValue];
    guideValue.forEach(item => {
      if ((['fh', 'symbolRate'].includes(changeType) && item.name === COMRETURN) || (['fMax', 'symbolRate'].includes(changeType) && [CROSSCOUPLING, DIFFTOCOM].includes(item.name)) || (changeType === 'fLPMax' && item.name === CROSSCOUPLING)) {
        const defaultList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, symbolRate, cphyInsertionLossSpec: item.cphyInsertionLossSpec, ...frequency });
        item.points = [...defaultList];
      }
      if (['fh', 'fMax', 'fLPMax', 'symbolRate'].includes(changeType) && item.name === RETURNLOSS) {
        const defaultDriverList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, symbolRate, pointType: 'driverPoints', ...frequency });
        item.driverPoints = [...defaultDriverList];
      }
      if (
        (['fh', 'symbolRate'].includes(changeType) && item.name === RETURNLOSS)
        || (['fMax', 'symbolRate'].includes(changeType) && [RETURNLOSS, COMRETURN, DIFFTOCOM].includes(item.name))
        || (changeType === 'fINTMin' && item.name === COMRETURN)
        || (changeType === 'fLPMax' && item.name === RETURNLOSS)) {
        const defaultReceiverList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, symbolRate, pointType: 'receiverPoints', ...frequency });
        item.receiverPoints = [...defaultReceiverList];
      }
    })
    this.setState({ updateMaskTypes: [RETURNLOSS, COMRETURN, DIFFTOCOM, CROSSCOUPLING] });

    let config = { ...reportConfig };
    if (config && config.custom) {
      config.custom.symbolRate = Number(symbolRate);
      config.custom.driver = driver;
      config.custom.receiver = receiver;
      for (const key of Object.keys(frequency)) {
        config.custom[key] = Number(frequency[key]);
      }
      config.custom.guideValue = guideValue;
    }
    this.props._updateReportInfo({ reportConfig: config });
  }

  changeSymbolRate = (value) => {
    const { frequency, errorMsg } = this.state;
    // 0.08~6Gpsp
    if (!!numberCheck(value) || value < 0.08 || value > 6) {
      this.setState({ errorMsg: { ...errorMsg, symbolRate: 'Please enter 0.08 ~ 6.' }, symbolRate: value })
      return;
    } else {
      // Reset fMax，fh
      this.setState({
        errorMsg: { ...errorMsg, fh: null, fMax: null, symbolRate: null },
        symbolRate: value,
        frequency: {
          ...frequency,
          fh: parseFloat((value * 0.5).toFixed(3)),
          fMax: parseFloat((value * 0.75).toFixed(3))
        }
      }, () => {
        this.changeConfigs('symbolRate');
      })
    }
  }

  changeFrequency = (value, type) => {
    const { frequency, errorMsg } = this.state;
    const error = numberCheck(value, true);
    if (error) {
      this.setState({ errorMsg: { ...errorMsg, [type]: error }, frequency: { ...frequency, [type]: value } })
      return;
    } else {
      this.setState({ errorMsg: { ...errorMsg, [type]: null }, frequency: { ...frequency, [type]: value } }, () => {
        this.changeConfigs(type);
      })
    }
  }

  getFrequencyLabel = (name) => {
    let prefix = "", subscript = "";
    switch (name) {
      case 'fh':
        prefix = "fh";
        break;
      case 'fMax':
        prefix = "f";
        subscript = "Max"
        break;
      case 'fINTMin':
        prefix = "f";
        subscript = "INT,MIN"
        break;
      case 'fLPMax':
        prefix = "f";
        subscript = "LP,MAX"
        break;
      default:
        break;
    }
    return <Fragment>
      <span className='andes-v2-report-frequency-title-prefix'>{prefix}</span>
      <span className='andes-v2-report-frequency-title-subscript'>{subscript}</span>
    </Fragment>
  }

  changeDriverOrReceiver = (value, type) => {
    const key = type === Driver ? 'driver' : 'receiver';
    this.setState({ [key]: value }, () => {
      this.changeConfigs(type);
    })
  }

  CPHYConfigRender = () => {
    const { symbolRate, frequency, errorMsg, driver, receiver } = this.state;
    const { components } = this.props;
    return <Fragment>
      <div className="andes-v2-report-input-box">
        <label className="andes-v2-report-label">Symbol Rate</label>
        <Tooltip
          className='aurora-error-tooltip'
          title={errorMsg.symbolRate ? errorMsg.symbolRate : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={errorMsg.symbolRate ? true : false}>
          <Input size='small' value={symbolRate} addonAfter="Gsps" className="andes-v2-report-input" onChange={(e) => this.changeSymbolRate(e.target.value)}
          />
        </Tooltip>
      </div>
      <div className='andes-v2-report-select-box'>
        {
          [Driver, Receiver].map((item, index) => {
            return <div className='andes-v2-report-flex-item' style={index % 2 === 0 ? { marginRight: '20px' } : { marginLeft: '20px' }} key={item}>
              <label className='andes-v2-report-label'>{item}</label>
              <Select
                size='small'
                mode="multiple"
                className='andes-v2-report-flex-item-select'
                dropdownStyle={{ zIndex: 100000 }}
                value={item === Driver ? driver : receiver}
                onChange={(value) => this.changeDriverOrReceiver(value, item)}
              >
                {(components || []).map(comp => (
                  <Option
                    key={comp.name}
                    value={comp.name}
                    disabled={item === Driver ? receiver.includes(comp.name) : driver.includes(comp.name)}
                  >
                    {comp.name}
                  </Option>
                ))}
              </Select>
            </div>
          })
        }
      </div>
      <Collapse
        className='andes-v2-report-collapse'
        items={[{
          key: "Frequency",
          label: "Frequency",
          children: [['fh', 'fMax'], ['fINTMin', 'fLPMax']].map((list, i) => {
            return <div key={i} className="andes-v2-report-frequency">
              {
                list.map((item, index) => <div className='andes-v2-report-flex-item' style={index % 2 === 0 ? { marginRight: '20px' } : { marginLeft: '20px' }} key={item}>
                  <label className="andes-v2-report-frequency-title">{this.getFrequencyLabel(item)}</label>
                  <Tooltip
                    className='aurora-error-tooltip'
                    title={errorMsg[item] ? errorMsg[item] : null}
                    overlayClassName='aurora-error-msg-tooltip'
                    open={errorMsg[item] ? true : false}>
                    <Input size='small' className='andes-v2-report-frequency-flex-item-input' value={frequency[item]} addonAfter="GHz" onChange={(e) => this.changeFrequency(e.target.value, item)} />
                  </Tooltip>
                </div>)
              }
            </div>
          })
        }]}
      />
      <Divider className='andes-v2-report-divider' />
    </Fragment>
  }

  ReportAdvance = (custom, reportDesignId, reportConfigLoading, type) => {
    const { updateMaskTypes, frequency, symbolRate } = this.state;
    const guideValue = custom && custom.guideValue ? custom.guideValue : [];
    const isXTable = custom ? custom.isXTable : false;
    const _vendor = reportDesignId ? designConstructor.getDesign(reportDesignId).vendor : null;
    return (<ReportAdvance
      referenceLineData={guideValue}
      changeReferenceLineData={this.changeReferenceLineData}
      isXTable={isXTable}
      changeIsXTable={this.changeIsXTable}
      referenceLinesColumns={type === CPHY ? referenceLinesColumns : referenceLinesColumns.filter(item => item.key !== DriverOrReceiver)}
      type={type === CPHY ? 'andes_v2_CPHY' : 'andes_v2'}
      reportDesignId={reportDesignId}
      vendor={_vendor}
      reportConfigLoading={reportConfigLoading}
      product={ANDES_V2}
      symbolRate={symbolRate}
      frequency={frequency}
      updateMaskTypes={updateMaskTypes}
      changeUpdateMaskTypes={this.changeUpdateMaskTypes}
    >
      {type === CPHY && this.CPHYConfigRender()}
    </ReportAdvance>)
  }

  render() {
    const { reportVisible, reportProgress, reportConfig = {}, reportMessage, reportConfigLoading, projectId, channelDesignId, serdesType } = this.props;
    const { language = 'english', custom, channelIds } = reportConfig;
    const { reportType, closeVisible, currentChannel = {}, maxWidth, maxHeight, downloadLoading } = this.state;
    const messageClassName = reportMessage && reportMessage.indexOf('successfully') > -1 ? 'download-success' : 'download-error';
    let reportDesignId = channelDesignId
    if (channelIds && channelIds.length) {
      const findChannel = channelConstructor.getChannel(channelIds[0]);
      if (findChannel && findChannel.designId) {
        reportDesignId = findChannel.designId
      }
    }

    const content = (
      <Fragment>
        <Panel
          className='andes-v2-report-panel aurora-x-scroll-hidden'
          title={<div className='report-panel-title'>Report Generation Options</div>}
          onCancel={this.closeModal}
          onMinimize={this.onMinimize}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { minWidth: 270, defaultWidth: serdesType === CPHY ? 1300 : 900 })}
          minWidth={270}
          position='panel-center'
          draggable
          minHeight={200}
          maxHeight={maxHeight}
          minimizeVisible={reportVisible ? true : false}
          overflow={"auto"}
        >
          <div className='report-content clear'>
            <div>
              <label className="report-item-title">Channel</label>
              <TreeSelect
                treeData={this.getChannelSelectList(projectId)}
                value={currentChannel ?
                  { value: currentChannel.id, label: currentChannel.design ? `${currentChannel.design} - ${currentChannel.name}` : currentChannel.name }
                  : { value: "", label: "" }
                }
                onChange={this.onTreeChange}
                placeholder='Please select'
                labelInValue
                className='report-select aurora-select'
                dropdownStyle={{ maxHeight: 500 }}
                getPopupContainer={() => document.getElementById('root')}
                popupClassName='andes-v2-report-select-dropdown'
                virtual={false}
              />
            </div>
            <div>
              <label className="report-item-title">File Type</label>
              <Select
                value={reportType}
                onChange={(value) => this.changeReportType(value)}
                className='aurora-select report-select'
                popupClassName='report-select-dropdown'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option key="PPTX">PPTX</Option>
                <Option key="PDF">PDF</Option>
              </Select>
            </div>
            <div>
              <label className="report-item-title">Language</label>
              <Select
                value={language}
                onChange={(value) => this.changeReportLanguage(value)}
                className='aurora-select report-select'
                popupClassName='report-select-dropdown'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option key="english">English</Option>
                <Option key="japanese">Japanese</Option>
                {/* <Option key="chinese">Chinese</Option> */}
              </Select>
            </div>
            {this.ReportAdvance(custom, reportDesignId, reportConfigLoading, (currentChannel || {}).type)}
            <ReportTemplate
              reportConfig={reportConfig}
              updateReportConfig={this.updateReportConfig}
              updateReoprtMessage={this.updateMessage}
              type="ANDES"
              serdesType={serdesType}
            />
            <div className='report-message-main .clear'>
              {!reportVisible ? <Fragment>
                {reportMessage ? <div className={`${messageClassName} report-download-message`}>{reportMessage}</div> : null}
                <div className='report-download-button clear'>
                  <Button
                    title={"download"}
                    type="primary"
                    ghost
                    disabled={reportVisible || (!currentChannel || !currentChannel.id) || downloadLoading}
                    onClick={(e) => this.DownloadReport(e)}
                    className='report-download-btn'
                  >Download</Button>
                </div> </Fragment> :
                <Progress
                  size={{ height: 10 }}
                  className='report-progress'
                  percent={reportProgress}
                />}
            </div>
            <div className="report-bottom-severed"></div>
          </div>
        </Panel>
        {
          closeVisible ? <DelConfirm
            data={null}
            maskStyle={true}
            deleteConfirm={this.deleteConfirm}
            cancelDel={this.cancelDel}
            message={'After closing the panel, the download report will end. Are you sure you want to close it?'}
          /> : null
        }
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const {
    AndesV2Reducer: {
      channel: { channelId, channelInfo: { designId } },
      project: { openProjectId, reportInfo, viewList },
      endToEndChannel: { endToEndChannelId }
    } } = state;
  const { reportProgress, reportVisible, reportConfig = {}, reportMessage, reportConfigLoading } = reportInfo || {};
  let vendor = null;
  if (designId) {
    vendor = designConstructor.getDesign(designId).vendor;
  }
  return {
    channelId,
    projectId: openProjectId,
    reportProgress,
    reportVisible,
    reportConfig,
    reportMessage,
    reportConfigLoading,
    channelDesignId: designId,
    vendor: vendor,
    endToEndChannelId,
    viewList
  };
}

const mapDispatch = (dispatch) => ({
  _updateReportInfo(info) {
    dispatch(updateReportInfo(info))
  },
  _clearReportInfo() {
    dispatch(clearReportInfo())
  },
  _saveReportConfig(channelId, isMultiPCB) {
    dispatch(saveReportConfig(channelId, isMultiPCB))
  },
  _getReport({ projectId, channelId, format, mime, fileName }) {
    dispatch(getReport({ projectId, channelId, format, mime, fileName }));
  }
})

export default connect(mapState, mapDispatch)(ReportPanel);