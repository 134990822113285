import { runHimalayasIoSim } from "../../api/Himalayas/ioSim";
import apiProcess from "../../api/utility";

/**
 * run io sim by verificationId
 * 2023/05/30
 * @param {string} id  ->io sim verificationId
 * */
function runIoSim({ verificationId, workflowTypes }) {
  return apiProcess(runHimalayasIoSim, { verificationId, workflowTypes });
}

export {
  runIoSim
}
