export const UPDATE_LIBRARY_MENU = 'SIERRA/update_library_menu';
export const TOUCHSTONE_RUNNING_LIST = 'SIERRA/touchstone_running_list';
export const LIBRARY_TREE_NEED_UPDATE = 'SIERRA/library_tree_need_update';
export const LIBRARY_FOLDER_CREATE = 'SIERRA/library_folder_create';
export const OPEN_LIBRARY_FOLDER = 'SIERRA/open_library_folder';
export const GET_LIBRARY_TREE = 'SIERRA/get_library_tree';
export const AFTER_IMPORT_LIBRARY = 'SIERRA/after_import_library';
export const GET_PART_LIBRARY = 'SIERRA/get_part_library';
export const SAVE_LIBRARY_FILE_CONTENT = 'SIERRA/save_library_file_content';
export const UPDATE_FILE_CONTENT = 'SIERRA/update_file_content';
export const GET_DEFAULT_LIBRARY = 'SIERRA/get_default_library';
export const SET_DEFAULT_LIBRARY = 'SIERRA/set_default_library';
export const UPDATE_DEFAULT_LIBRARY = 'SIERRA/update_default_library';
export const UPDATE_REPEATER_FILE_STATUS = 'SIERRA/update_repeater_file_status';
export const SAVE_PRE_LAYOUT_TEMPLATE = `SIERRA/save_pre_layout_template`;