import CeGeomFactory from './CeGeomFactory';
import CeGeometry from './CeGeometry';

const CeGeomHolder = function () {
  this.mNewGeomID = 0;
  this.mGeomList = [];
}

CeGeomHolder.prototype.AddGeometry = function (geom) {
  this.mGeomList.push(geom);
}

CeGeomHolder.prototype.add = function (geom) {
  this.mGeomList.push(geom);
}

CeGeomHolder.prototype.push = function (geom) {
  this.mGeomList.push(geom);
}

CeGeomHolder.prototype.GetGeometry = function (gid) {
  for (var i = 0; i < this.mGeomList.length; i++) {
    if (gid == this.mGeomList[i].GetGeomID())
      return this.mGeomList[i];
  }
  return null;
}

CeGeomHolder.prototype.DelGeometry = function (geomIDs) {
  var i, j;
  for (i = 0; i < geomIDs.length; i++) {
    for (j = this.mGeomList.length - 1; j >= 0; j--) {
      var geom = this.mGeomList[j];
      if (geomIDs[i] == geom.GetGeomID()) {
        this.mGeomList.splice(j, 1);
        break;
      }
    }
  }
}

CeGeomHolder.prototype.GetNextGeomObjID = function () {
  return this.mNewGeomID++;
}

CeGeomHolder.prototype.Clear = function () {
  this.mGeomList = [];
}

CeGeomHolder.prototype.CleanUp = function () {
  this.mNewGeomID = 0;
  this.mGeomList = [];
}

CeGeomHolder.prototype.WriteIntoIODataNode = function (myBlock) {
  for (var i = 0; i < this.mGeomList.length; i++) {
    var blockNode = this.mGeomList[i].WriteIntoIODataNode();
    myBlock.AddBlockNode(blockNode);
  }
}

CeGeomHolder.prototype.ReadFromIODataNode = function (myBlock) {
  var blockNodes = myBlock.GetBlockNodes();
  for (var i = 0; i < blockNodes.length; i++) {
    var geomType = blockNodes[i].GetName();
    var geometry = CeGeomFactory.CreateGeometryFromType(geomType);
    if (geometry != null && geometry instanceof CeGeometry) {
      geometry.ReadFromIODataNode(blockNodes[i]);
      this.add(geometry);
    } else
      console.log("CeGeomHolder.ReadFromIODataNode error.");
  }
}

CeGeomHolder.prototype.Clone = function () {
  var copyOfGeomList = new CeGeomHolder();
  for (var i = 0; i < this.mGeomList.length; i++) {
    var geom = this.mGeomList.Clone();
    copyOfGeomList.add(geom);
  }
  return copyOfGeomList;
}

export default CeGeomHolder;