import React, { Component, Fragment } from "react";
import { CloseOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Select } from "antd";
import { getConnectionIdsByPCBList, getMaxWidthByPCBName } from '@/services/helper/connectorHelper';
import ConnectionModel from './ConnectionModel';
import { SIERRA } from "../../constants/pageType";
import "./index.css";

const { Option } = Select;
class ConnectorSetup extends Component {

  componentDidMount = () => {
    this.updateConnection();
  }

  componentDidUpdate = (prevProps) => {
    const { connections, pcbConnections, endToEndChannelId, connectorSetupUpdate, CONNECTION_ID, product } = this.props;
    if ((product !== SIERRA && !CONNECTION_ID && connections.length)
      || connections.length !== prevProps.connections.length
      || endToEndChannelId !== prevProps.endToEndChannelId
      || (connectorSetupUpdate && connectorSetupUpdate !== prevProps.connectorSetupUpdate)
      || (pcbConnections.length !== prevProps.pcbConnections.length)
    ) {
      product !== SIERRA ? this.updateConnection() : this.updateTileConnection();
      connectorSetupUpdate && this.props._setupUpdateStatus(false, "connectorSetupUpdate");
    }
  }

  updateConnection = async () => {
    const { connections, CONNECTION_ID, pcbConnections } = this.props;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);
    if (!connection) {
      const connectionIdList = getConnectionIdsByPCBList(pcbConnections);
      this.props.updateConnectionId(connectionIdList.length ? connectionIdList[0] : "")
    }
    this.connectionRef && await this.connectionRef.getConnection();
  }

  updateTileConnection = async () => {
    const { connections } = this.props;
    if (connections.length) {
      for (let connectionInfo of connections) {
        const { connectionList } = connectionInfo;
        for (let connection of connectionList) {
          const { CONNECTION_ID } = connection;
          const key = `connection${CONNECTION_ID}Ref`;
          this[key] && this[key].getConnection();
        }
      }
    }
  }

  saveConnection = (connection, CONNECTION_ID) => {
    this.props._saveConnection(connection, { CONNECTION_ID });
  }

  openLeft = (connectionIdList) => {
    const { CONNECTION_ID } = this.props;
    const index = connectionIdList.findIndex(item => item === CONNECTION_ID);
    this.props.updateConnectionId(connectionIdList[index - 1]);
  }

  openRight = (connectionIdList) => {
    const { CONNECTION_ID } = this.props;
    const index = connectionIdList.findIndex(item => item === CONNECTION_ID);
    this.props.updateConnectionId(connectionIdList[index + 1]);
  }

  deletePCBInConnector = (value, channelIndex, CONNECTION_ID) => {
    //Switching between PCBs involves modifying multiple instances
    const { connections } = this.props;
    this.props._changePcbInConnector(value, channelIndex, CONNECTION_ID, connections)
  }

  switchContent = () => {
    const { pcbConnections = [], CONNECTION_ID } = this.props;
    let firstID = "", lastID = "";
    const connectionIdList = getConnectionIdsByPCBList(pcbConnections);
    const maxLength = connectionIdList.length;
    if (connectionIdList.length) {
      firstID = connectionIdList[0];
      lastID = connectionIdList[maxLength - 1];
    }

    return (
      <Fragment>
        <div className="connection-model-content">
          {maxLength > 1 && <div
            className='change-connection-icon'
            style={firstID !== CONNECTION_ID ? { cursor: 'pointer' } : {}}
            onClick={() => { firstID !== CONNECTION_ID && this.openLeft(connectionIdList) }}
          >
            <LeftOutlined style={firstID !== CONNECTION_ID ? { color: '#1890ff' } : {}} />
          </div>}
          <div className="connection-model-list">
            {this.connectionList(connectionIdList, CONNECTION_ID, this.connectionTitleList)}
          </div>
          {maxLength > 1 && <div
            className='change-connection-icon'
            style={lastID !== CONNECTION_ID ? { cursor: 'pointer' } : {}}
            onClick={() => { lastID !== CONNECTION_ID && this.openRight(connectionIdList) }}
          >
            <RightOutlined style={lastID !== CONNECTION_ID ? { color: '#1890ff' } : {}} />
          </div>}
        </div>
        <div>{this.connectionList(connectionIdList, CONNECTION_ID, this.connectionModel)}</div>
      </Fragment>
    );
  }

  tileContent = () => {
    const { connections } = this.props;
    return <Fragment>
      {connections.map(item => <div className='connection-model-content-wrapper'
        key={item.CONNECTION_ID}
        id={`connection-model-box-${item.CONNECTION_ID}`}
      >
        <div className="connection-model-content">
          <div className="connection-model-tile-list">
            {this.connectionList(item, item.CONNECTION_ID, this.connectionTitleList)}
          </div>
        </div>
        <div>{this.connectionList(item, item.CONNECTION_ID, this.connectionModel)}</div>
      </div>)}
    </Fragment>
  }

  render() {
    const { product, addConnector, addStatus } = this.props;
    const disableClassName = !addStatus ? "icon-disabled" : ""
    return (
      <div className="aurora-setup-border">
        <div className="aurora-connector-option">
          <span className='out-title-color font-bold'>Connection Setup</span>
          {product === SIERRA &&
            <PlusCircleOutlined
              className={`signal-add-icon ${disableClassName}`}
              onClick={() => { addStatus && addConnector() }}
              title={addStatus ? "Add New PCB" : "No more available connector for connection"} />
          }
        </div>
        {product !== SIERRA ?
          this.switchContent() :
          this.tileContent()
        }
      </div>
    );
  }

  connectionTitleList = (CONNECTION_ID) => {
    const { connections, pcbConnections, product, /* connectorList */ } = this.props;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);
    const prevPCBIndex = pcbConnections.findIndex(item => item.nextConnectionId === CONNECTION_ID);
    const nextPCBIndex = pcbConnections.findIndex(item => item.prevConnectionId === CONNECTION_ID);
    const prevPCBTitle = prevPCBIndex > -1 ? `PCB ${parseInt(prevPCBIndex) + 1}` : 'PCB',
      nextPCBTitle = nextPCBIndex > -1 ? `PCB ${parseInt(nextPCBIndex) + 1}` : 'PCB';
    if (!connection) {
      return;
    }
    const width = product !== SIERRA ? getMaxWidthByPCBName(connection) : getMaxWidthByPCBName(connection) + 50;
    /*  const leftDisableClassName = product === SIERRA && !connectorList.find(item => (!item.connect || !item.connect.pcbId) && item.pcbId === connection.channel1.designId) ? "icon-disabled" : "";
     const rightDisableClassName = product === SIERRA && !connectorList.find(item => (!item.connect || !item.connect.pcbId) && item.pcbId === connection.channel2.designId) ? "icon-disabled" : ""; */

    return <div className="connection-channel-tile-title">
      <div>
        <div className="connection-title-tile-pcb">{prevPCBTitle}</div>
        {this.pcbTitleList(connection.channel1, 1, connection.channel2, width, CONNECTION_ID)}
        {/* <Icon type="plus-circle" className={`signal-add-icon ${leftDisableClassName}`} onClick={() => !leftDisableClassName && addPcbConnector(CONNECTION_ID, 1)} title={!leftDisableClassName ? "Add New Connector" : "No more connector to add"} /> */}
      </div>
      <div>
        <div className="connection-title-tile-pcb">{nextPCBTitle}</div>
        {this.pcbTitleList(connection.channel2, 2, connection.channel1, width, CONNECTION_ID)}
        {/* <Icon type="plus-circle" className={`signal-add-icon ${rightDisableClassName}`} onClick={() => !rightDisableClassName && addPcbConnector(CONNECTION_ID, 2)} title={!rightDisableClassName ? "Add New Connector" : "No more connector to add"} /> */}
      </div>
    </div>
  }

  getAddIcon = (index, CONNECTION_ID) => {
    const leftIndex = 1;
    const { connections, product, addPcbConnector, connectorList } = this.props;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);
    let _className = '';
    if (index === leftIndex) {
      _className = product === SIERRA && !connectorList.find(item => (!item.connect || !item.connect.pcbId) && item.pcbId === connection.channel1.designId) ? "icon-disabled" : "";
    } else {
      _className = product === SIERRA && !connectorList.find(item => (!item.connect || !item.connect.pcbId) && item.pcbId === connection.channel2.designId) ? "icon-disabled" : "";
    }

    return (
      <PlusCircleOutlined
        className={`signal-add--connection-icon ${_className}`}
        onClick={() => !_className && addPcbConnector(CONNECTION_ID, index)}
        title={!_className ? "Add New Connector" : "No more connector to add"} />
    );

  }

  pcbTitleList = (channel, channelIndex, anotherChannel, width, CONNECTION_ID) => {
    const { product, Interfaces, connections } = this.props;
    if (product === SIERRA) {
      const list = this.props.getPcbList(Interfaces, anotherChannel, connections, CONNECTION_ID, channel)
      return <Select
        value={channel.designId && channel.designName ? { key: channel.designId, label: channel.designName } : undefined}
        style={{ width: width - 10 }}
        className={`aurora-select connection-single-select`}
        placeholder={'PCB'}
        showSearch
        labelInValue={true}
        popupMatchSelectWidth={false}
        popupClassName="connector-select-dropdown-menu"
        getPopupContainer={() => document.getElementById('root')}
        onChange={option => { option && this.deletePCBInConnector({ label: option.label, key: option.value }, channelIndex, CONNECTION_ID) }}
      >
        {list.map(item => (
          <Option key={item.key} title={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
    } else {
      return <Fragment>
        <span className='connection-pcb-name'>{channel.designName}</span>
        <span className='connection-channel-name'>{channel.channelName}</span>
      </Fragment>
    }
  }

  connectionList = (connectionIdList, CONNECTION_ID, children) => {
    const { product } = this.props;
    if (product === SIERRA) {
      return <div
        key={CONNECTION_ID}
        className="connection-model-main"
      >
        {children(CONNECTION_ID)}
      </div>
    } else {
      const currentIndex = connectionIdList.findIndex(item => item === CONNECTION_ID);
      return connectionIdList.map((item, index) => {
        const style = index < currentIndex ? { marginLeft: "-100%" } : { marginLeft: "100%" }
        return <div
          key={index}
          className="connection-model-main"
          style={(CONNECTION_ID === item) ? {} : style}
        >
          {CONNECTION_ID === item ? children(CONNECTION_ID) : null}
        </div>
      })
    }
  }

  onRef = (ref, CONNECTION_ID) => {
    if (this.props.product !== SIERRA) {
      this.connectionRef = ref;
    } else {
      const key = `connection${CONNECTION_ID}Ref`
      this[key] = ref;
    }
  }

  connectionModel = (CONNECTION_ID) => {
    const { connections, _updateConnectionModelSetup, endToEndChannelId, pcbConnections,
      connectorFileList, cableFileList, channelConstructor, getTouchstoneParse, getSpiceParse,
      channelSetupInfo, product, Interfaces, judgeFileExsit, deleteConnector
    } = this.props;
    const connectorCompList = product === SIERRA ? this.props.getConnCompList({ CONNECTION_ID, connections, Interfaces }) : this.props.connectorCompList;
    const connection = connections.find(item => item.CONNECTION_ID === CONNECTION_ID);
    if (!connection) {
      return;
    }
    if (product === SIERRA) {
      const { connectionList = [] } = connection;
      return connectionList.map((item, index) => {
        return (
          <div className="connection-model-box" key={index}>
            <CloseOutlined
              className="connector-delete-button"
              onClick={() => deleteConnector(item, CONNECTION_ID)} />
            <ConnectionModel
              onRef={this.onRef}
              connection={item}
              saveConnection={this.saveConnection}
              channel1={connection.channel1}
              channel2={connection.channel2}
              CONNECTION_ID={CONNECTION_ID}
              CONNECTION_INDEX={item.CONNECTION_ID}
              connectorFileList={connectorFileList}
              cableFileList={cableFileList}
              pcbConnections={pcbConnections}
              connections={connections}
              _updateConnectionModelSetup={_updateConnectionModelSetup}
              channelConstructor={channelConstructor}
              getTouchstoneParse={getTouchstoneParse}
              channelSetupInfo={channelSetupInfo}
              Interfaces={Interfaces}
              judgeFileExsit={judgeFileExsit}
              connectorCompList={connectorCompList}
              getSpiceParse={getSpiceParse}
              product={product}
              getAddIcon={index === 0 && this.getAddIcon}
              deleteConnector={deleteConnector}
            />
          </div>
        );
      });
    } else {
      return <ConnectionModel
        onRef={this.onRef}
        endToEndChannelId={endToEndChannelId}
        connection={connection.connection}
        saveConnection={this.saveConnection}
        channel1={connection.channel1}
        channel2={connection.channel2}
        CONNECTION_ID={CONNECTION_ID}
        connectorFileList={connectorFileList}
        cableFileList={cableFileList}
        pcbConnections={pcbConnections}
        connections={connections}
        _updateConnectionModelSetup={_updateConnectionModelSetup}
        channelConstructor={channelConstructor}
        getTouchstoneParse={getTouchstoneParse}
        channelSetupInfo={channelSetupInfo}
        Interfaces={Interfaces}
        judgeFileExsit={judgeFileExsit}
        connectorCompList={connectorCompList}
        getSpiceParse={getSpiceParse}
        product={product}
      />
    }
  }
}

export default ConnectorSetup;
