import RockySSNChannelInfo from "../SSN/channelsInfo";

class ChannelVerifications {
  constructor() {
    this.channels = new Map(); // key - channelId, value - verifications

    this.setChannel = this.setChannel.bind(this);
    this.getVerificationsByChannelId = this.getVerificationsByChannelId.bind(this);
    this.cleanChannels = this.cleanChannels.bind(this);
  };

  setChannel(channelId, verifications) {
    this.channels.set(channelId, verifications);
  };

  getChannelIds() {
    return this.channels.keys();
  };

  getVerificationsByChannelId(channelId) {
    return this.channels.get(channelId); // undefined | []
  };

  getVerificationListByChannelId(channelId, isGetSSN) {
    const list = this.channels.get(channelId); // undefined | [];
    if (isGetSSN) {
      const _verifications = RockySSNChannelInfo.getSSNInterfaceList(channelId)
      return _verifications;
    }
    return list
  };

  cleanChannels(channelIds) {

  }

};

const RockyChannelVerifications = new ChannelVerifications();
export default RockyChannelVerifications; 