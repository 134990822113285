import {
  PKG_SPICE,
  PKG_TOUCHSTONE,
  PASSIVE_TOUCHSTONE,
  PASSIVE_SPICE,
  TRACE,
  VIA,
  CONNECTOR_TOUCHSTONE,
  CABLE_TOUCHSTONE,
  IBIS_AMI,
  IBIS,
  PCB_TOUCHSTONE,
  SPICE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  EYE_MASK,
  GENERIC_TOUCHSTONE,
  LEQ_RESPONSE,
  ADS_OPTIONS,
  BUFFER_SPICE,
  CPHY_EYE_MASK,
  USER_DEFINED_NETLIST
} from "../../../constants/libraryConstants";
import { getLibraryDetail } from "./libraryCtrl";

export const libraryTypeList = [
  TRACE,
  VIA,
  PKG_TOUCHSTONE,
  PKG_SPICE,
  PASSIVE_TOUCHSTONE,
  PASSIVE_SPICE,
  CONNECTOR_TOUCHSTONE,
  CABLE_TOUCHSTONE,
  IBIS_AMI,
  IBIS,
  BUFFER_SPICE,
  PCB_TOUCHSTONE,
  SPICE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  ADS_OPTIONS,
  EYE_MASK,
  GENERIC_TOUCHSTONE,
  LEQ_RESPONSE,
  CPHY_EYE_MASK,
  USER_DEFINED_NETLIST
];

class LibraryConstructor {
  constructor() {
    for (let item of libraryTypeList) {
      this[item] = new Map(); //key - library id, value :{ id ,name, type, version ,config };
    }
  }

  getSize = (type) => {
    return this[type].size;
  };

  getLibraryList = (type) => {
    return this[type];
  }

  getLibraryValues = (type) => {
    let list = [];
    if (this[type]) {
      for (let val of this[type].values()) {
        list.push(val);
      }
    }
    return list;
    //return this[type].values().reduce((prev, curr) => { return [...prev, ...curr] });
  }

  getLibraryValueList = (types) => {
    let list = [];
    for (let type of types) {
      for (let val of this[type].values()) {
        list.push(val);
      }
    }
    return list;
  }

  getLibrary = (type, id) => {
    return this[type].get(id);
  }

  addLibrary = (type, id, data) => {
    this[type].set(id, data);
  }

  delLibrary = (type, id) => {
    this[type].delete(id);
  }

  checkFile = (type, libraryId) => {
    if (this[type].get(libraryId)) {
      return true;
    } else {
      return false;
    }
  }

  getLibraryConfig(id, type) {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(null);
      }
      const library = this.getLibrary(type, id);
      if (!library || !library.config) {

        getLibraryDetail(id).then(res => {
          this.addLibrary(type, id, res);
          resolve(res);
        })
      } else {
        resolve(library);
      }
    })
  }

  clearCache = (type) => {
    this[type] = new Map();
  }

  clearAllCache = () => {
    for (let item of libraryTypeList) {
      this[item] = new Map(); //key - library id, value :{ id ,name, type, version ,config };
    }
  }
};

let libraryConstructor = new LibraryConstructor();
export default libraryConstructor;