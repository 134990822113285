import { INIT_STACKUP_DATA, CHANGE_STACKUP_TABLE, SETTING_UNIT } from './actionTypes';

export const saveData = (data, unit, designID) => ({
  type: INIT_STACKUP_DATA,
  data,
  unit,
  designID
})

export const changeStackupTable = (data, unit, designID) => ({
  type: CHANGE_STACKUP_TABLE,
  data,
  unit,
  designID
})

export const settingUnit = (unit, designID) => ({
  type: SETTING_UNIT,
  unit,
  designID
})