import React, { Component, Fragment } from 'react';
import { Input } from 'antd';
import { TARGET_RL_VALUE } from '@/services/Cascade/constants';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { numberCheck } from "@/services/helper/dataProcess";
import UnitAddonAfter from '@/components/UnitAddonAfter';
import { TargetNameEdit } from './TargetNameEdit';
import { unitChange } from '@/services/helper/mathHelper';
import { calcFrequencyPoints, getImpTargetName } from '@/services/Cascade/Impedance';
import '../index.css';

const ResUnit = ['Ω', 'KΩ', 'mΩ'];
const IndUnit = ['H', 'uH', 'nH', 'pH'];
const KeyArr = [
  { title: 'Resistance', key: 'resistance', unit: 'resUnit', unitList: ResUnit },
  { title: 'Inductance', key: 'inductance', unit: 'indUnit', unitList: IndUnit }
];

class RLValue extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initState();
  }

  componentDidUpdate = (prevProps) => {
    const { targetIndex } = this.props;
    if (targetIndex !== prevProps.targetIndex) {
      this.initState()
    }
  }

  inputRef = (ref) => {
    this.targetNameRef = ref;
  }

  initState = () => {
    const { record, targetIndex, target, targetType, PowerNets } = this.props;
    const currentTarget = target[targetIndex];
    const name = getImpTargetName({
      targetName: currentTarget ? currentTarget.targetName : "",
      record,
      targetType,
      PowerNets,
    });
    this.setState({
      nameStatus: false,
      targetName: name,
      tempName: name,
      resistance: currentTarget && currentTarget.resistance ? currentTarget.resistance.value : '100',
      resUnit: currentTarget && currentTarget.resistance ? currentTarget.resistance.unit : 'mΩ',
      inductance: currentTarget && currentTarget.inductance ? currentTarget.inductance.value : '100',
      indUnit: currentTarget && currentTarget.inductance ? currentTarget.inductance.unit : 'pH',
      error: null,
      save: false
    }, () => {
      this.props.savePoints(this.getFrequencyPoints());
    })
  }

  changeName = (e, status) => {
    e.stopPropagation();
    this.setState({
      nameStatus: status,
      error: null
    }, () => {
      if (status) {
        this.targetNameRef.focus();
      }
    })
  }

  saveTempName = (e) => {
    this.setState({
      tempName: e.target.value
    })
  }

  saveNewTargetName = (e) => {
    e.stopPropagation();
    const { tempName } = this.state;
    const error = checkNameFormat(tempName);
    if (error) {
      this.setState({
        error
      })
    } else {
      this.setState({
        targetName: tempName,
        nameStatus: false,
        error: null,
        save: true
      })
      const { target, targetIndex } = this.props;
      if (target[targetIndex]) {
        const targetNames = target.filter(item => item.targetName);
        let _tempName = tempName
        while (targetNames.includes(_tempName)) {
          _tempName = `${_tempName}_1`
        }
        const _target = { ...target[targetIndex], targetName: _tempName };
        this.props.updateTarget(_target, targetIndex);
      }
    }
  }

  changeValue = (e, type) => {
    this.setState({
      [type]: e.target.value,
      save: true
    }, () => { this.props.refreshPlot() })
  }

  changeUnit = (unit, unitKey, key) => {
    const prevUnit = this.state[unitKey];
    const value = this.state[key];
    const newValue = unitChange({ num: value, oldUnit: prevUnit, newUnit: unit }).number;
    this.setState({
      [unitKey]: unit,
      [key]: newValue,
      save: true
    }, () => { this.props.refreshPlot() })
  }

  saveTarget = (prevType, closePanel) => {
    const { powerDomainId, targetIndex } = this.props;
    const { resistance, resUnit, inductance, indUnit, targetName, save } = this.state;

    const frequencyPoints = this.getFrequencyPoints('Error');

    if (frequencyPoints === 'Error') {
      return false;
    }

    const target = {
      frequencyPoints,
      powerDomainId,
      targetName,
      resistance: { value: resistance, unit: resUnit },
      inductance: { value: inductance, unit: indUnit },
      targetType: TARGET_RL_VALUE
    }

    if (prevType !== TARGET_RL_VALUE || save) {
      this.props.updateTarget(target, targetIndex, closePanel)
    } else if (closePanel) {
      this.props.filterEmptyTarget()
    };

    return true;
  }

  getFrequencyPoints = (errorReturn = []) => {
    const { resistance = '100', resUnit = 'mΩ', inductance = '100', indUnit = 'pH' } = this.state;
    const resError = numberCheck(resistance);
    if (resError) {
      if (errorReturn === 'Error') {
        this.setState({
          error: 'Resistance must be a number.'
        })
      }
      return errorReturn;
    }

    const indError = numberCheck(inductance);
    if (indError) {
      if (errorReturn === 'Error') {
        this.setState({
          error: 'Resistance must be a number.'
        })
      }
      return errorReturn;
    }

    const res = unitChange({ num: resistance, oldUnit: resUnit, newUnit: 'Ω' }).number;
    const ind = unitChange({ num: inductance, oldUnit: indUnit, newUnit: 'H' }).number;
    const frequencyPoints = calcFrequencyPoints(res, ind);
    return frequencyPoints
  }

  render() {
    const { targetName, nameStatus, error } = this.state;

    return <Fragment>
      <div className='imp-target-RL-value cascade-setup-border'>
        {TargetNameEdit({
          targetName,
          nameStatus,
          changeName: this.changeName,
          saveTempName: this.saveTempName,
          saveNewTargetName: this.saveNewTargetName,
          onRef: this.inputRef
        })}
        {KeyArr.map(item => <div key={item.key} className='imp-target-RL-content'>
          <span className="imp-target-RL-content-span">{item.title}</span>
          <Input
            key={item.key}
            value={this.state[item.key]}
            onChange={(e) => this.changeValue(e, item.key)}
            addonAfter={UnitAddonAfter({
              unit: this.state[item.unit],
              changeUnit: (key) => this.changeUnit(key, item.unit, item.key),
              list: item.unitList
            })}
          />
        </div>)}
      </div>
      {error && <span className='imp-target-name-error-msg'>{error}</span>}
    </Fragment>
  }
}

export default RLValue;