

import { PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS } from "../../../constants/treeConstants";
import channelConstructor from "../channel/channelConstructor";
import designConstructor from '@/services/helper/designConstructor';
import endToEndChannelConstructor from "../endToEndChannel/endToEndChannelConstructor";
import { getAndesV2PCBChannelDebugList, PCB_PARSING, REPORT } from "../../helper/debugHelper";
import { getVerificationJson } from "../results";
import endToEndSetup from "../endToEndChannel/endToEndSetup";
import { isPreLayout } from "..";
import sweepConstructor from "../sweep/sweepConstructor";
import { PACKAGE } from "../../../constants/designType";
import { PRE_LAYOUT } from "../../../constants/designVendor";

const DEBUG_SIMULATION_INFO = {
  selectInfo: {},
  selectList: [],
  selectChildren: [],
  selectChild: {}
}

function getDebugMenuList() {
  return [
    {
      title: "Layout Parsing",
      key: PCB_PARSING,
      info: {}
    },
    {
      title: "Channel",
      key: PCB_CHANNEL,
      info: {
        ...DEBUG_SIMULATION_INFO
      }
    },
    {
      title: "Channel Sweep",
      key: EXPERIMENTS,
      info: {
        // selected pcb
        selectInfo: {},
        // optional pcb
        selectList: [],
        // selected channel
        selectChild: {},
        // optional channel
        selectChildren: [],
        // selected sweep
        selectSweep: {},
        // optional sweeps
        selectSweeps: []
      }
    },
    {
      title: "Multi-PCB Channel",
      key: END_TO_END_CHANNEL,
      info: {
        selectInfo: {},
        selectList: []
      }
    },
    {
      title: "Report",
      key: REPORT,
      info: {
        selectInfo: {},
        selectList: []
      }
    }
  ]
}

/**
 * update debug menu list (update select list and selected info)
 * 
 *  */
function updateDebugMenuList({ debugMenuList, channelId, endToEndChannelId, projectId, debugType, sweepId, sweepList }) {
  //get pcb parsing info (selected pcb and all pcb list in open project)
  const designInfo = getPCBParsingDesign({ debugType, channelId, projectId });
  //get end to end info (selected end to end  and all end to end channel list in open project)
  const endToEndInfo = getEndToEndDebugList({ endToEndChannelId, projectId });
  //get channel info (selected pcb and channel and all pcb and pcb channel list in open project)
  const channelInfo = getChannelDebugList({ channelId, projectId });
  const sweepInfo = getSweepDebugListBySweepId({ sweepId, projectId, channelId, sweepList });
  //get report info (selected all channel list in open project)
  const reportInfo = getPCBParsingDesign({ debugType, channelId, projectId });

  for (let item of debugMenuList) {
    switch (item.key) {
      case PCB_PARSING:
        item.info = { ...designInfo };
        break;
      case PCB_CHANNEL:
        item.info = { ...channelInfo };
        break;
      case END_TO_END_CHANNEL:
        item.info = { ...endToEndInfo };
        break;
      case REPORT:
        item.info = { ...reportInfo };
        break;
      case EXPERIMENTS:
        item.info = { ...sweepInfo };
        break;
      default: break;
    }
  }
  return debugMenuList;
}

function getDebugType({ viewList, channelId, endToEndChannelId, channelEndToEndId, sweepId }) {
  const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(item));
  switch (view) {
    case PCB_CHANNEL:
      if (channelId && channelEndToEndId && channelEndToEndId !== "0") {
        return END_TO_END_CHANNEL;
      }
      return channelId ? PCB_CHANNEL : null;
    case END_TO_END_CHANNEL:
      return endToEndChannelId ? END_TO_END_CHANNEL : null;
    case EXPERIMENTS:
      return sweepId ? EXPERIMENTS : null;
    default: return;
  }
}

function getChannelDebugList({ channelId, projectId }) {
  let info = {};

  if (channelId) {
    info = getChannelDebugListByChannelId(channelId);
  }

  if ((!channelId || !info) && projectId) {
    info = getChannelDebugListByProjectId(projectId);
  }

  return info ? { ...info } : {};
}

function getChannelDebugListByChannelId(channelId) {
  let selectInfo = {},
    selectList = [],
    selectChildren = [],
    selectChild = {};
  //get channel by channelId
  const channel = channelConstructor.getChannel(channelId);
  if (!channel) {
    return;
  }
  selectChild = { id: channel.id, name: channel.name };
  //get design by designId
  const design = designConstructor.getDesign(channel.designId);
  if (!design) {
    return;
  }
  selectInfo = { id: design.id, name: design.name };
  //get current design channels by designId
  const channels = channelConstructor.getChannelValues(design.id);
  selectChildren = channels ? [...channels] : [];
  //get current project designs by projectId
  const designs = designConstructor.getDesignValues(design.projectId);
  selectList = designs ? [...designs] : [];
  return {
    selectInfo,
    selectList,
    selectChildren,
    selectChild
  }
}

function getChannelDebugListByProjectId(projectId) {
  let selectInfo = {},
    selectList = [],
    selectChild = {},
    selectChildren = [];

  //get designs by projectId
  const designs = designConstructor.getDesignValues(projectId);
  if (!designs || !designs.length) {
    return;
  }
  const design = designs[0];
  selectList = designs ? [...designs] : [];

  selectInfo = { id: design.id, name: design.name };
  //get current design channels by designId
  const channels = channelConstructor.getChannelValues(design.id);
  selectChildren = channels ? [...channels] : [];
  if (!channels || !channels.length) {
    return;
  }
  const channel = channels[0];
  selectChild = { id: channel.id, name: channel.name };
  return {
    selectInfo,
    selectList,
    selectChildren,
    selectChild
  }
}

function getPCBParsingDesign({ debugType, channelId, projectId }) {
  let designId = null;
  switch (debugType) {
    case PCB_CHANNEL:
      //get channel by channelId
      const channel = channelConstructor.getChannel(channelId);
      if (!channel) {
        break;
      }
      //get design by designId
      const _cDesign = designConstructor.getDesign(channel.designId);
      if (!_cDesign/*  || _cDesign.vendor === PRE_LAYOUT */) {
        break;
      }
      designId = _cDesign.id;
      break;
    default:
      break;
  }

  if (!designId) {
    const designs = designConstructor.getDesignValues(projectId) || [];
    /*  const _design = designs.find(item => item.vendor !== PRE_LAYOUT); */
    designId = designs[0] ? designs[0].id : null;
  }

  //get design by designId
  const design = designConstructor.getDesign(designId);
  if (!design) {
    return { selectInfo: {} };
  }
  return {
    selectInfo: { id: design.id, name: design.name },
    selectList: getProjectDesigns(projectId) || []
  };
}

function getProjectDesigns(projectId) {
  return designConstructor.getDesignValues(projectId) || [];
}

function getSweepDebugListBySweepId({ sweepId, channelId, projectId, sweepList }) {
  let selectInfo = {}, selectList = [], selectChild = {}, selectChildren = [], selectSweep = {}, selectSweeps = [],
    _designId = "", _channelId = channelId;
  // on sweep page
  if (sweepId) {
    const sweep = sweepConstructor.getSweep(sweepId);
    if (sweep) {
      selectSweep = { id: sweep.id, name: sweep.name };
      _channelId = sweep.channelId;
      _designId = sweep.designId;
      const channel = channelConstructor.getChannel(_channelId);
      const design = designConstructor.getDesign(_designId);
      selectInfo = { id: design.id, name: design.name };
      selectChild = { id: channel.id, name: channel.name };
      selectSweep = { id: sweep.id, name: sweep.name };
    }
  } else if (channelId) { // on channel page
    const channel = channelConstructor.getChannel(channelId);
    if (channel) {
      _designId = channel.designId;
    }
    const design = designConstructor.getDesign(_designId);
    if (!design || design.vendor === PRE_LAYOUT || design.type === PACKAGE) {
      return {
        selectInfo,
        selectList,
        selectChildren,
        selectChild,
        selectSweep,
        selectSweeps
      }
    }
    selectInfo = { id: design.id, name: design.name };
    selectChild = { id: channel.id, name: channel.name };
  }

  // get selected info
  const designs = designConstructor.getDesignValues(projectId).filter(item => item.vendor !== PRE_LAYOUT && item.type !== PACKAGE);
  selectList = designs ? [...designs] : [];
  if (selectList.length > 0 && !selectInfo.id) {
    selectInfo = selectList[0];
    _designId = selectInfo.id;
  }

  const channels = channelConstructor.getChannelValues(_designId);
  selectChildren = channels ? [...channels] : [];
  if (selectChildren.length > 0 && !selectChild.id) {
    selectChild = selectChildren[0];
    _channelId = selectChild.id;
  }
  const sweeps = sweepList.get(_channelId);
  selectSweeps = sweeps ? [...sweeps] : [];
  if (selectSweeps.length > 0 && !selectSweep.id) {
    selectSweep = selectSweeps[0];
  }

  return {
    selectInfo,
    selectList,
    selectChildren,
    selectChild,
    selectSweep,
    selectSweeps
  };
}

/** 
 * @param {Object} info prev info { selectList,selectChild,selectChildren,selectInfo }
 * @param {String} value current selected pcb name
 */
function updatePCBChannelSelectList(info, value) {
  let _simulationInfo = { ...info };
  let selectList = info.selectList || [];
  const selected = selectList.find(item => item.id === value);
  if (!selected) {
    return;
  }

  //get channels by design id
  const channels = channelConstructor.getChannelValues(selected.id);
  const channel = channels && channels[0] ? { id: channels[0].id, name: channels[0].name } : { id: "", name: "" };
  _simulationInfo = {
    ..._simulationInfo,
    selectInfo: { id: selected.id, name: selected.name },
    selectChildren: channels ? [...channels] : [],
    selectChild: channel
  }
  return _simulationInfo;
}

/** 
 * @param {Object} info prev channel select info { ...DEBUG_SIMULATION_INFO }
 * @param {String} value current selected pcb channel
 */
function updatePCBChannelSelectChildList(info, value) {
  let _info = { ...info };
  let selectChildren = info.selectChildren || [];
  const selected = selectChildren.find(item => item.id === value);
  if (!selected) {
    return;
  }
  _info = {
    ..._info,
    selectChild: { id: selected.id, name: selected.name }
  }
  return _info;
}

function getEndToEndDebugList({ endToEndChannelId, projectId }) {
  let selectInfo = {},
    selectList = [];
  //get end to end channels by projectId
  const endToEndChannels = endToEndChannelConstructor.getEndToEndChannelValues(projectId);
  if (!endToEndChannels || !endToEndChannels.length) {
    return;
  }
  selectList = [...endToEndChannels];
  let endToEnd = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId);
  endToEnd = endToEnd ? endToEnd : endToEndChannels[0];
  selectInfo = { id: endToEnd.id, name: endToEnd.name };
  return {
    selectInfo,
    selectList
  }
}

function updateEndToEndSelectList(projectId, value, info) {
  const endToEndChannels = endToEndChannelConstructor.getEndToEndChannelValues(projectId) || [];
  const endToEnd = endToEndChannels.find(item => item.id === value);
  if (endToEnd) {
    info.selectInfo = { id: endToEnd.id, name: endToEnd.name };
  }
  return info;
}

function getReportDownloadList({ debugMenuList }) {
  let list = [];
  const reportInfo = debugMenuList.find(item => item.key === REPORT).info;
  if (!reportInfo.selectInfo || !reportInfo.selectInfo.id) {
    return [];
  }

  const info = channelConstructor.getChannelValues(reportInfo.selectInfo.id);

  list = info.map((item) => {
    return { name: item.name, option: item.id }
  })
  return list
}

async function getEndToEndDebugDownloadList({ debugMenuList }) {
  let list = [], isSimulation = true;
  const endToEndDebugInfo = debugMenuList.find(item => item.key === END_TO_END_CHANNEL).info;
  if (!endToEndDebugInfo.selectInfo || !endToEndDebugInfo.selectInfo.id) {
    return [];
  }
  //get simulation setup
  const info = await getVerificationJson(endToEndDebugInfo.selectInfo.id, true);
  let pcbConnections = info && info.endToEnd && info.endToEnd.content ? info.endToEnd.content.pcbConnections : [];

  if (!info) {
    //never simulation get current setup
    await endToEndSetup.get(endToEndDebugInfo.selectInfo.id);
    pcbConnections = endToEndSetup.getPCBConnections(endToEndDebugInfo.selectInfo.id);
    isSimulation = false;
  }

  if (!pcbConnections || !pcbConnections.length) {
    return [];
  }

  for (let i = 0; i < pcbConnections.length; i++) {
    const item = pcbConnections[i];
    if (!item.channelId) {
      continue;
    }
    const design = designConstructor.getDesign(item.designId) || {};
    const channelItem = getAndesV2PCBChannelDebugList(design.vendor);

    list.push({
      name: `PCB ${i + 1} (${item.designName} - ${item.channelName})`,
      default: true,
      option: item.channelId,
      children: [...channelItem]
    })
  }
  // end to end channel log ands result folder
  const endToEnd = [
    { name: 'Log file', default: true, option: 'log' },
    { name: 'Result folder', default: true, option: 'result' },]
  isSimulation && list.push(...endToEnd);

  return list;
}


function getDebugCheckValues(values, prevValues, downloadAllList) {
  let currentCheckValues = [...prevValues];
  let newValues = [];
  for (let item of values) {
    let value = { name: item, children: [] }
    const find = currentCheckValues.find(it => it.name === item);
    if (find && find.children) {
      value.children = find.children;
    } else {
      const findAll = downloadAllList.find(it => it.option === item);
      value.children = findAll && findAll.children ? findAll.children.map(it => it.option) : [];
    }
    newValues.push(value);
  }
  return newValues;
}

function getEndToEndDebugDownloadOptions(values, endToEndChannelId) {
  let channels = []; // [{ channelId, options: []}]
  let options = [];
  for (let item of values) {
    if (["log", "result"].includes(item.name)) {
      options.push(item.name);
      continue;
    }

    const channel = channelConstructor.getChannel(item.name) || {};
    const isPre = isPreLayout(channel.designId);
    let channelOptions = item.children || [];
    if (isPre) {
      channelOptions = channelOptions.filter(item => item !== "pcb");
    }
    channels.push({
      channelId: item.name,
      options: channelOptions
    })
  }

  return {
    channels,
    options,
    endToEndChannelId
  }
}

// change pcb
function updateSweepSelectInfo(info, value, sweepList) {
  let selectInfo = {}, selectList = info.selectList, selectChild = {}, selectChildren = [], selectSweep = {}, selectSweeps = [];
  selectInfo = selectList.find(item => item.id === value);
  const channels = channelConstructor.getChannelValues(selectInfo.id);
  selectChildren = channels ? [...channels] : [];
  if (selectChildren.length > 0) {
    selectChild = selectChildren[0];
    const sweeps = sweepList.get(selectChild.id);
    selectSweeps = sweeps ? [...sweeps] : [];
    selectSweep = selectSweeps.length > 0 ? selectSweeps[0] : {}
  }

  return {
    selectInfo,
    selectList,
    selectChildren,
    selectChild,
    selectSweep,
    selectSweeps
  };
}

// change channel 
function updateSweepSelectChild(info, value, sweepList) {
  let selectChild = {}, selectChildren = info.selectChildren, selectSweep = {}, selectSweeps = [];

  selectChild = selectChildren.find(item => item.id === value);
  if (selectChild.id) {
    const sweeps = sweepList.get(selectChild.id);
    selectSweeps = sweeps ? [...sweeps] : [];
    selectSweep = selectSweeps.length > 0 ? selectSweeps[0] : {}
  }
  return {
    ...info,
    selectChild,
    selectSweep,
    selectSweeps
  }
}

// change sweep
function updateSweepSelectSweep(info, value) {
  const selectSweeps = info.selectSweeps;
  const selectSweep = selectSweeps.find(item => item.id === value);

  return {
    ...info,
    selectSweep
  }
}
export {
  getDebugType,
  getChannelDebugList,
  updatePCBChannelSelectList,
  updatePCBChannelSelectChildList,
  getPCBParsingDesign,
  getProjectDesigns,
  getEndToEndDebugList,
  getDebugMenuList,
  updateDebugMenuList,
  updateEndToEndSelectList,
  getEndToEndDebugDownloadList,
  getDebugCheckValues,
  getEndToEndDebugDownloadOptions,
  getReportDownloadList,
  updateSweepSelectInfo,
  updateSweepSelectChild,
  updateSweepSelectSweep
}