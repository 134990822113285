import {
  CHANGE_LEFT,
  CHANGE_TOP
} from './actionTypes';

export const changeLeft = (left) => ({
  type: CHANGE_LEFT,
  left
});

export const changeTop = (top) => ({
  type: CHANGE_TOP,
  top
});