import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Parameters from '@/services/Sierra/Sparameter/parameter';
import { SweepResultData } from '@/services/Sierra';
import { getResultList } from '@/services/helper/sparameter/dataProcess';
import { strDelimited } from '@/services/helper/split';
import Sparameter from '../components/Sparameter';
import { sparameterShowCurves } from '@/services/Sierra/helper/sparameterDataHelper.js'
import makeCancelable from '@/services/api/makeCancelable';
import { getSweepMouseMoveYAxisValue, cancelSweepMove } from '../../../../services/Sierra/helper/sparameterDataHelper';

const defaultState = {
  files: [],
  displayFiles: [],
  portSelect: {},
  Interfaces: {},
  resultList: {},
  keepSelect: false
}
class SweepChannel extends Component {

  constructor(props) {
    super(props);
    this.uploadSP = createRef();
    this.uploadSnpRef = createRef();
    this.state = {
      ...defaultState
    }
    this.plot2d = null;
  }

  changeMouse = (frequency) => {
    try {

      let { files, resultList } = this.state;
      const displayMode = this.childComp.getDisplayMode();
      const axis = this.childComp.getAxis();
      const yScale = axis.yScale;
      const selectArr = this.childComp.getCurrentSelects();
      let _resultList = { ...resultList };
      for (let pcb in _resultList) {
        _resultList[pcb] = getSweepMouseMoveYAxisValue({
          resultsObj: _resultList[pcb],
          displayMode,
          selectArr,
          files,
          x: frequency,
          setting: this.childComp.getSetting(),
          yScale
        })
      }
      this.setState({
        resultList: _resultList
      });
    } catch (error) {
      console.error(error)
    }
  }

  cancelMove = () => {
    try {
      let { resultList } = this.state;
      const displayMode = this.childComp.getDisplayMode();
      let _resultList = { ...resultList };
      for (let pcb in _resultList) {
        _resultList[pcb] = cancelSweepMove({
          resultsObj: _resultList[pcb],
          displayMode
        })
      }
      this.setState({
        resultList: _resultList
      });
    } catch (error) {
      console.error(error)
    }
  }

  componentWillUnmount = () => {
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
  }

  componentDidUpdate(prevProps) {
    const { sweepId, experimentList } = this.props;
    if (sweepId && sweepId !== prevProps.sweepId) {
      this.setState({
        ...defaultState,
        portSelect: {},
        resultList: {},
      });
      Parameters.cleanParameters();
      this.getInterface();
      if (this.selectAllCancelAble) {
        this.selectAllCancelAble.cancel();
      }
    }

    if (experimentList && experimentList.length !== prevProps.experimentList.length) {
      this.setState({
        resultList: {},
        files: [],
        displayFiles: [],
        Interfaces: {},
        keepSelect: true
      });
      Parameters.cleanParameters();
      this.getInterface();
      if (this.selectAllCancelAble) {
        this.selectAllCancelAble.cancel();
      }
    }
  }

  componentDidMount = async () => {
    const { currentProjectId, sweepId } = this.props;
    this.setState({
      ...defaultState,
      portSelect: {},
      resultList: {},
    });
    if (currentProjectId && sweepId) {
      this.getInterface();
    }
  }

  getInterface = async () => {
    const { experimentList, currentProjectId } = this.props;
    let Interfaces = {}
    for (let exp of experimentList) {
      const res = await SweepResultData.getVerificationJsonPromise(exp.id, currentProjectId);
      if (res) {
        let currentInterfaces = SweepResultData.getInterfaces(exp.id);
        Interfaces[exp.id] = currentInterfaces;
      }
    }
    this.setState({
      Interfaces
    }, () => {
      this.changeResult();
    })
  }

  changeResult = async () => {
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.childComp.changeAxis,
    }
    Parameters.cleanParameters();
    this.childComp.setLoading(true)
    this.setState({
      resultList: {}
    }, async () => {
      this.mounted = true;
      const { currentProjectId: projectId, experimentList } = this.props;
      const { parameter, value } = this.childComp.getSetting();
      this.plot2d = Parameters.createPlot(this.childComp.svgRef.current, { param: parameter, value }, events);
      let files = []
      for (let item of experimentList) {
        const params = await Parameters.getParametersInfo({ verificationId: item.id, verificationSubId: item.id, projectId, channelList: item.channelList, groupName: item.name })
        const parametersArr = Array.from(params.values());
        if (parametersArr.length > 0) {
          let _files = Array.from(params.values()).map(item => ({ ...item, show: true }));
          let fileId = files.map(f => f.id);
          if (_files && _files.length) {
            files.push(..._files.filter(f => !fileId.includes(f.id)));
          }
        }
      }
      const displayMode = this.childComp.getDisplayMode();
      this.getAllNpi(files).then(res => {
        this.childComp.setLoading(false);
        const __files = [...res.map(item => ({ ...item, show: true, rowName: item.pcb, rowId: item.pcb }))];
        this.setState({
          files: __files,
          displayFiles: this.mergeFiles(__files)
        }, () => {
          displayMode === 'Default' ? this.defaultResultList() : this.findResultList();
        })
      }, error => {
        this.childComp.setLoading(false)
        this.setState({
          files: files,
        }, () => {
          displayMode === 'Default' ? this.defaultResultList() : this.findResultList();
        })
      })
      this.childComp.screenChange();
    })
  }

  getAllNpi = (files) => {
    const { Interfaces } = this.state;
    const promiseList = files.map((item, index) => {
      const content = Interfaces[item.verificationId].find(d => item.id.includes(d.pcbId));
      return item.getExperimentNpiFile(index, item.verificationId, content);
    })
    return Promise.all(promiseList);
  }

  getSingleNpi = (file) => {
    const { files } = this.state;
    const { verificationSubId } = this.props;
    let length = files.length >= 0 ? files.length : 0;
    const promiseList = file.map((item, index) => {
      return item.getExperimentNpiFile(length, verificationSubId);
    })
    return Promise.all(promiseList);
  }

  getColorByIndex = (fileId, row, col) => {
    const { files } = this.state;
    const _file = files.find(file => file.id === fileId);
    return _file && _file.matrix && _file.matrix[row] && _file.matrix[row][col] ? _file.matrix[row][col].color : "";
  }

  defaultResultList = (key) => {
    const { resultList, files, Interfaces, keepSelect, allPortSelect } = this.state;
    const { experimentList } = this.props;
    let _resultList = resultList ? resultList : {};
    let _allPortSelect = allPortSelect ? allPortSelect : {};
    let _Interfaces = [];
    let _portSelect = { ...this.state.portSelect }
    for (let exp of experimentList) {
      const currentInterface = Interfaces[exp.id] ? Interfaces[exp.id] : []
      _Interfaces.push(...currentInterface.map(item => ({ ...item, groupName: exp.name })));
    }
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    let pcbs = _Interfaces.map(item => ({ pcb: item.pcb, groupName: item.groupName }));
    for (let _interface of _Interfaces) {
      const pcb = _interface.pcb;
      const groupName = _interface.groupName;
      const fileIndex = files.findIndex(file => file.pcb === pcb && file.groupName === groupName);
      if (fileIndex < 0) {
        continue;
      }
      const findFile = files[fileIndex];
      if (!findFile.ports || !findFile.ports.length) {
        continue;
      }

      const portList = findFile.ports;
      const fileId = files[fileIndex].id;
      if (!_resultList[pcb]) {
        _resultList[pcb] = {};
      }
      if (!_allPortSelect[pcb]) {
        _allPortSelect[pcb] = {
          Default: {}
        };
      }

      if (!_resultList[pcb].Default) {
        let _default = [];
        for (let i = 0; i < portList.length; i++) {
          const port = portList[i];
          const name = `Port ${port.index} (${port.name})`
          if (!_allPortSelect[pcb].Default[name]) {
            _allPortSelect[pcb].Default[name] = []
          }
          _default[i] = { rowName: name, children: [], groupName: [groupName] };
          _default[i].children = portList.map(_port => {
            _allPortSelect[pcb].Default[name].push(`${fileId}::${port.index - 1}::${_port.index - 1}`)
            return {
              id: `${fileId}::${port.index - 1}::${_port.index - 1}`,
              name: `S(${port.index}, ${_port.index}) ${port.index !== _port.index ? _port.name : ''}`,
              color: { [groupName]: this.getColorByIndex(fileId, port.index - 1, _port.index - 1) }
            }
          })
        }
        let show = _default[0] ? [_default[0].rowName] : [];
        if (keepSelect) {
          const { portSelect } = this.state;
          const keys = Object.keys(portSelect);
          show = _default.map(item => item.rowName).filter(item => keys.includes(item))
        }
        _resultList[pcb].Default = { list: _default, show };
      } else if (_resultList[pcb].Default) {
        let list = _resultList[pcb].Default.list;
        for (let i = 0; i < portList.length; i++) {
          const port = portList[i];
          let _rowName = `Port ${port.index} (${port.name})`;
          let findIndex = list.findIndex(l => l.rowName === _rowName);
          if (!_allPortSelect[pcb].Default[_rowName]) {
            _allPortSelect[pcb].Default[_rowName] = []
          }
          if (findIndex > -1) {
            _resultList[pcb].Default.list[findIndex].groupName.push(groupName);
            _resultList[pcb].Default.list[findIndex].children.forEach((child, index) => {
              _allPortSelect[pcb].Default[_rowName] = [...new Set([..._allPortSelect[pcb].Default[_rowName], `${fileId}::${port.index - 1}::${index}`])]
              child.color[groupName] = this.getColorByIndex(fileId, port.index - 1, index);
            })
          } else {
            let _default = { rowName: _rowName, children: [], groupName: [groupName] };
            _default.children = portList.map(_port => {
              _allPortSelect[pcb].Default[_rowName] = [...new Set([..._allPortSelect[pcb].Default[_rowName], `${fileId}::${port.index - 1}::${_port.index - 1}`])]
              return {
                id: `${fileId}::${port.index - 1}::${_port.index - 1}`,
                name: `S(${port.index}, ${_port.index}) ${port.index !== _port.index ? _port.name : ''}`,
                color: { [groupName]: this.getColorByIndex(fileId, port.index - 1, _port.index - 1) }
              }
            })
            _resultList[pcb].Default.list.push(_default)
          }
        }
      }
      if (key) {
        _portSelect = this._changePortSelect(_allPortSelect[pcb], key)
      }
    }
    this.setState({
      resultList: _resultList,
      allPortSelect: _allPortSelect,
      portSelect: _portSelect
    }, () => {
      if (!this.state.keepSelect) {
        pcbs.forEach(p => {
          const _findFile = files.find(item => item.pcb === p.pcb && item.groupName === p.groupName);
          const currentList = _resultList[p.pcb];
          if (_findFile && currentList && currentList.Default && currentList.Default.list && currentList.Default.list.length > 0 && currentList.Default.list[0].children[0]) {
            const defaultList = currentList.Default.list[0];
            const defaultValues = defaultList.children[0].id;
            const defaultKey = defaultList.rowName;
            const [name, id] = strDelimited(defaultValues, "@");
            this.changePort([`${p.groupName}@${id}`], defaultKey, _findFile.id, p.groupName);
          }
        })
      } else {
        this.removeUnselectPorts();
        this.setState({
          keepSelect: false
        })
      }
    })
  }

  findResultList = (key) => {
    const { files, Interfaces, resultList, allPortSelect } = this.state;
    const { experimentList } = this.props;
    let _Interfaces = [];
    let _resultList = resultList ? resultList : {};
    let _allPortSelect = allPortSelect ? allPortSelect : {};
    let _portSelect = { ...this.state.portSelect }
    for (let exp of experimentList) {
      const currentInterface = Interfaces[exp.id] ? Interfaces[exp.id] : []
      _Interfaces.push(...currentInterface.map(item => ({ ...item, groupName: exp.name })));
    }
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    let edit = false;
    for (let _interface of _Interfaces) {
      const pcb = _interface.pcb;
      const groupName = _interface.groupName;
      const fileIndex = files.findIndex(file => file.pcb === pcb && file.groupName === groupName);
      if (fileIndex < 0) {
        continue;
      }
      const findFile = files[fileIndex];
      if (!findFile.ports || !findFile.ports.length) {
        continue;
      }
      const content = _interface.content ? _interface.content : {};
      const { signals, components, virtualComps } = content;
      if (!_resultList[pcb]) {
        _resultList[pcb] = {};
      }

      if (!_resultList[pcb].IL || !_resultList[pcb].RL) {
        edit = true;
        const portList = findFile.ports;
        const { IL, RL, portSelect, FEXT, NEXT } = getResultList({
          Signals: signals,
          Components: components,
          VirtualComps: virtualComps,
          portList,
          getColorByIndex: this.getColorByIndex,
          infoList: ["IL", "RL", "FEXT", "NEXT"],
          fileId: findFile.id,
          portSelects: _allPortSelect[pcb]
        });
        _allPortSelect[pcb] = portSelect
        _resultList[pcb].IL = { list: this.saveColorAsArray(IL, groupName), show: IL.map(item => item.rowName), groupName: [groupName] };
        _resultList[pcb].RL = { list: this.saveColorAsArray(RL, groupName), show: RL.map(item => item.rowName), groupName: [groupName] };
        _resultList[pcb].FEXT = { list: this.saveColorAsArray(FEXT, groupName), show: FEXT.map(item => item.rowName), groupName: [groupName] };
        _resultList[pcb].NEXT = { list: this.saveColorAsArray(NEXT, groupName), show: NEXT.map(item => item.rowName), groupName: [groupName] };
      } else if (_resultList[pcb].IL && _resultList[pcb].RL &&
        (!_resultList[pcb].IL.groupName.includes(groupName) || !_resultList[pcb].RL.groupName.includes(groupName))) {
        edit = true;
        const portList = findFile.ports;
        const { IL, RL, portSelect, FEXT, NEXT } = getResultList({
          Signals: signals,
          Components: components,
          portList,
          getColorByIndex: this.getColorByIndex,
          infoList: ["IL", "RL", "FEXT", "NEXT"],
          fileId: findFile.id,
          portSelects: _allPortSelect[pcb]
        });
        _allPortSelect[pcb] = portSelect
        _resultList[pcb].IL.list = this.mergeResultList(_resultList[pcb].IL.list, IL, groupName);
        _resultList[pcb].IL.groupName.push(groupName);
        _resultList[pcb].RL.list = this.mergeResultList(_resultList[pcb].RL.list, RL, groupName);
        _resultList[pcb].RL.groupName.push(groupName);
        _resultList[pcb].FEXT.list = this.mergeResultList(_resultList[pcb].FEXT.list, FEXT, groupName);
        _resultList[pcb].FEXT.groupName.push(groupName);
        _resultList[pcb].NEXT.list = this.mergeResultList(_resultList[pcb].NEXT.list, NEXT, groupName);
        _resultList[pcb].NEXT.groupName.push(groupName);
      }
      if (key) {
        _portSelect = this._changePortSelect(_allPortSelect[pcb], key)
      }
    }
    this.setState({
      portSelect: _portSelect
    })

    if (edit) {
      this.setState({
        resultList: _resultList,
        allPortSelect: _allPortSelect,
      });
    }
  }

  _changePortSelect = (pcbPortSelectData, key) => {
    let _portSelect = { ...this.state.portSelect }
    if (key === 'Default') {
      let new_portSelect = {}
      const keys = Object.keys(pcbPortSelectData[key])
      for (const k of keys) {
        if (_portSelect && _portSelect[k] && _portSelect[k].length) {
          new_portSelect[k] = _portSelect[k]
        }
      }
      _portSelect = new_portSelect
    } else {
      const keys = pcbPortSelectData && pcbPortSelectData[key] ? Object.keys(pcbPortSelectData[key]) : []
      for (const k of keys) {
        if (_portSelect && _portSelect[k] && _portSelect[k].length) {
          const filterData = _portSelect[k].filter(item => pcbPortSelectData[key][k].includes(item))
          _portSelect[k] = [...new Set(filterData)]
        }
      }

      const default_keys = Object.keys(pcbPortSelectData.Default)
      for (const k of default_keys) {
        if (_portSelect && _portSelect[k] && _portSelect[k].length) {
          _portSelect[k] = []
        }
      }
    }

    return _portSelect
  }

  _rowNameClick = (displayMode, value, pcb) => {
    let _resultList = { ...this.state.resultList };
    if (_resultList[pcb] && _resultList[pcb][displayMode]) {
      let _show = [..._resultList[pcb][displayMode].show];
      if (_show.includes(value)) {
        _show = _show.filter(item => item !== value);
      } else {
        _show.push(value);
      }
      _resultList[pcb][displayMode].show = _show;
      this.setState({
        resultList: _resultList
      });
    }
  };

  getPortSelect = (data, signalPortSelect = [], allChecked) => {
    let _signalPortSelect = [...signalPortSelect]
    const { selectPortData, allPortData } = data;
    let idList = []

    if (allChecked) {
      if (!signalPortSelect) {
        return;
      }
      _signalPortSelect = _signalPortSelect.filter(item => !selectPortData.includes(item));

      selectPortData.forEach(uncheck => {
        const [fileId, row, col] = strDelimited(uncheck, "::");
        Parameters.removeCurve({ row, col, id: `${fileId}` });
      })
    } else {
      idList = allPortData.filter(item => !selectPortData.includes(item))
      if (_signalPortSelect) {
        _signalPortSelect.push(...idList)
      } else {
        _signalPortSelect = [...idList]
      }

    }
    return { _signalPortSelect, idList }
  }

  changeSelectSignalAll = (columnData) => {
    const { portSelect } = this.state;
    const { signal, allChecked } = columnData

    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }

    const { _signalPortSelect, idList } = this.getPortSelect(columnData, portSelect[signal], allChecked)

    portSelect[signal] = _signalPortSelect
    this.showDataCurves(idList)
    this.setState({
      portSelect: portSelect
    })
  }

  changeSelectRowAll = (columnData) => {
    const { dataList, allChecked } = columnData;
    let { portSelect } = this.state;
    let allIdList = []
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }

    for (let data of dataList) {
      const { signal } = data;
      const { _signalPortSelect, idList } = this.getPortSelect(data, portSelect[signal], allChecked)
      allIdList = [...allIdList, ...idList]
      portSelect[signal] = _signalPortSelect
    }

    if (!allChecked && allIdList && allIdList.length) {
      this.showDataCurves(allIdList)
    }
    this.setState({
      portSelect: portSelect
    })
  }

  _changeSelectAll = (e, group, fileId, verticalListData) => {
    // Select multiple rows and columns of data
    const check = e.target.checked;
    let { portSelect } = this.state;
    const prevSelect = portSelect[group.rowName] || [];
    let prevOtherPcbSelect = [];
    const { rowName, groupName } = group;
    if (check) {
      if (verticalListData && verticalListData.length) {
        for (let verticalInfo of verticalListData) {
          const { name, dataList } = verticalInfo;
          if (!groupName.includes(name)) { continue }
          const currentDataInfo = dataList.find(item => item.signal === rowName);
          if (currentDataInfo) {
            prevOtherPcbSelect = [...prevOtherPcbSelect, ...currentDataInfo.allPortData]
          }
        }
      } else {
        prevOtherPcbSelect = group.children.map(it => it.id)
      }
    };
    portSelect[group.rowName] = [...prevOtherPcbSelect];

    this.setState({
      portSelect: portSelect
    });

    if (check) {
      const newChecks = prevOtherPcbSelect.filter(a => !prevSelect.includes(a));
      const { files } = this.state;
      const setting = this.childComp.getSetting();
      const curves = newChecks.map(d => {
        const [fileId, row, col] = strDelimited(d, "::");
        let parameter = Parameters.getParameter(fileId);
        if (!parameter) {
          return null;
        }
        const curveType = parameter.type; // simulation || target
        let fileIndex = files.findIndex(item => item.id === fileId);
        if (!files[fileIndex].ports || !parameter.ports) {
          return null;
        };
        return { parameter, fileIndex, row, col, curveType }
      });
      Parameters.showCurves({ curves, param: setting.parameter, value: setting.value, sweep: true }).then(resCurves => {
        if (resCurves) {
          resCurves.forEach(cur => {
            const _fileIndex = files.findIndex(item => item.id === cur.id);
            files[_fileIndex].matrix[cur.row][cur.col][cur.type] = cur.y;
            cur.visible = true;
          })
          this.plot2d.updateCurves(resCurves, true);
          resCurves.forEach(cur => {
            const _fileIndex = files.findIndex(item => item.id === cur.id);
            const color = files[_fileIndex].matrix[cur.row][cur.col].color;
            this.plot2d.resetColor({ id: cur.id, row: cur.row, col: cur.col, color: color });
          })
          this.plot2d.resetPlot();
          const axis = this.childComp.getAxis();
          const setting = this.childComp.getSetting();
          if (Parameters.getSize() > 0) {
            Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
            Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
          }
        };
      });
    } else {
      const removedSelect = prevSelect.filter(item => !prevOtherPcbSelect.includes(item));
      const removeCurves = removedSelect.map(uncheck => {
        const [id, row, col] = strDelimited(uncheck, "::");
        return { id, row, col };
      })
      Parameters.removeCurves(removeCurves);
    };
  }

  setChannelState = (value, key) => {
    this.setState({
      key: value
    })
  }

  render() {
    const { portSelect, resultList, displayFiles, allPortSelect } = this.state;
    const { experimentList } = this.props;
    return <Sparameter
      onRef={(ref) => { this.childComp = ref }}
      files={displayFiles}
      portSelect={portSelect}
      resultList={resultList}
      plot2d={this.plot2d}
      setChannelState={this.setChannelState}
      defaultResultList={this.defaultResultList}
      findResultList={this.findResultList}
      experimentList={experimentList}
      settingChangeCB={this.settingChangeCB}
      allPortSelect={allPortSelect}
      className="sierra-sweep-sparameter-result"
      {...this.resultListAction}
    />
  }

  fileNameClick = (id) => {
    const { files } = this.state;
    let index = files.findIndex(item => item.id === id);
    const parameter = files[index];
    if (parameter.ports && parameter.matrix) {
      this.setState((prevState) => {
        prevState.files[index].show = !prevState.files[index].show;
        return {
          files: prevState.files
        }
      });
      return;
    }
  }

  changePort = (values, key, fileId) => {
    let { portSelect } = this.state;
    //current pcb select keys
    let currentKeys = [];
    if (!portSelect[key]) {
      portSelect[key] = [];
    }

    let prev = [];
    if (fileId) {
      portSelect[key].forEach(item => {
        const [id] = strDelimited(item, "::");
        if (id === fileId) {
          prev.push(item);
          currentKeys.push(item);
        }
      })
    } else {
      prev = portSelect[key] || [];
    }
    if (!Array.isArray(values)) {
      return;
    }
    // find uncheck, pre ['0::0','0::1'] -> values ['0::0']
    let uncheck = null, newCheck = null;
    const _values = values.filter(item => item.includes(fileId))
    if (prev.length - _values.length === 1) {
      const _uncheck = prev.filter(p => !_values.includes(p));
      if (_uncheck.length === 1) {
        uncheck = _uncheck[0];
      };
    };
    if (uncheck) {
      const selectIndex = portSelect[key].findIndex(port => port === uncheck);
      portSelect[key].splice(selectIndex, 1);
    } else {
      portSelect[key] = [...new Set([...portSelect[key], ...values])];
      currentKeys = [...new Set([...currentKeys, ..._values])];
      const newCheckList = currentKeys.filter(p => !prev.includes(p));
      if (newCheckList.length === 1) {
        newCheck = newCheckList[0];
      } else {
        return;
      }
    };
    this.setState({
      portSelect: portSelect
    });

    if (uncheck) {
      const [fileId, row, col] = strDelimited(uncheck, "::");
      Parameters.removeCurve({ row, col, id: `${fileId}` });
      return;
    };

    if (newCheck) {
      this.showCurve(newCheck);
    }
  }

  showDataCurves = (idList) => {
    const { files } = this.state;
    const setting = this.childComp.getSetting();
    const curves = idList.map(d => {
      const [fileId, row, col] = strDelimited(d, "::");
      let parameter = Parameters.getParameter(fileId);
      if (!parameter) {
        return null;
      }
      const curveType = parameter.type; // simulation || target
      let fileIndex = files.findIndex(item => item.id === fileId);
      if (!files[fileIndex].ports || !parameter.ports) {
        return null;
      };
      return { parameter, fileIndex, row, col, curveType }
    });
    const _showCurves = sparameterShowCurves({
      parameters: Parameters,
      curves,
      param: setting.parameter,
      value: setting.value,
      sweep: true,
      files
    });
    this.selectAllCancelAble = makeCancelable(_showCurves)
    this.selectAllCancelAble.promise.then(resCurves => {
      if (resCurves) {
        this.plot2d.updateCurves(resCurves, true);
        resCurves.forEach(cur => {
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          const color = files[_fileIndex].matrix[cur.row][cur.col].color;
          this.plot2d.resetColor({ id: cur.id, row: cur.row, col: cur.col, color: color });
        })
        this.plot2d.resetPlot();
        const axis = this.childComp.getAxis();
        const setting = this.childComp.getSetting();
        if (Parameters.getSize() > 0) {
          Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      };
    })
  }

  changeAllPorts = (checked, list, key) => {
    let { portSelect } = this.state;

    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }

    if (checked) {
      if (!portSelect[key]) {
        return;
      }
      const idList = list.map(item => item._id);
      portSelect[key] = portSelect[key].filter(item => !idList.includes(item));
      idList.forEach(uncheck => {
        const [fileId, row, col] = strDelimited(uncheck, "::");
        Parameters.removeCurve({ row, col, id: `${fileId}` });
      })
    } else {
      const idList = list.filter(item => !item.checked).map(item => item._id);
      if (!portSelect[key]) {
        portSelect[key] = [...idList]
      } else {
        portSelect[key].push(...idList);
      }
      this.showDataCurves(idList)

    }
    this.setState({
      portSelect: portSelect
    })
  }

  showCurve = (newCheck) => {
    const { files } = this.state;
    const setting = this.childComp.getSetting();
    const displayMode = this.childComp.getDisplayMode()
    let [id, row, col] = strDelimited(newCheck, "::");
    let parameter = Parameters.getParameter(id);
    if (!parameter) {
      return;
    }
    const curveType = parameter.type; // simulation || target
    let fileIndex = files.findIndex(item => item.id === id);
    if (!files[fileIndex].ports || !files[fileIndex].ports.length || !parameter.ports || !parameter.ports.length) {
      return;
    }
    Parameters.showCurve({ parameter, fileIndex, row, col, param: setting.parameter, value: setting.value, curveType, sweep: true }).then(curves => {
      if (curves) {
        if (displayMode !== this.childComp.getDisplayMode()) { return }
        files[fileIndex].matrix[row][col][curves.type] = curves.y;
        curves.visible = true;
        this.plot2d.updateCurves(curves, true);
        this.plot2d.resetPlot();
        if (!files[fileIndex].matrix || !files[fileIndex].matrix.length) {
          return;
        }
        const color = files[fileIndex].matrix[row][col].color;
        this.plot2d.resetColor({ id, row, col, color: color });
        const axis = this.childComp.getAxis();
        const setting = this.childComp.getSetting();
        Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    });
  }

  colorChange = (e, key) => {
    e.stopPropagation();
    const [id, row, col] = strDelimited(key, "::");
    const [groupName, name] = strDelimited(key, "@")
    const { files, resultList } = this.state;
    const displayMode = this.childComp.getDisplayMode();
    let fileIndex = files.findIndex(item => item.id === id);
    const pcb = files[fileIndex].pcb;
    let _resultList = resultList[pcb];
    const value = e.toHexString()
    if (value) {
      files[fileIndex].matrix[row][col].color = value;
      for (let group of _resultList[displayMode].list) {
        let _find = false;
        for (let child of group.children) {
          if (child.id.includes(name)) {
            child.color[groupName] = value;
            _find = true;
            break;
          }
        }
        if (_find) {
          break;
        }
      }
      this.setState({
        files,
        resultList: { ...resultList, pcb: _resultList }
      }, () => {
        this.plot2d.resetColor({ id, row: row, col: col, color: value })
        this.plot2d.redrawCurves();
      })
    }
  }

  mergeFiles = (files) => {
    let _files = [];
    for (let file of files) {
      let current = _files.find(f => f.pcb === file.pcb);
      if (!current) {
        _files.push(file);
      }
    }
    return _files;
  }

  saveColorAsArray = (group, groupName) => {
    let newGroup = [...group];
    newGroup = group.map(item => {
      const _children = item.children.map(child => {
        const color = child.color;
        return { ...child, color: { [groupName]: color } }
      })
      return { ...item, children: _children, groupName: [groupName] }
    })
    return newGroup
  }

  mergeResultList = (group, result, _groupName) => {
    let newList = [];
    for (let item of group) {
      const { rowName, groupName, children } = item;
      const findItem = result.find(r => r.rowName === rowName);
      if (findItem) {
        let newGroupName = [...groupName, _groupName];
        let newChildren = children.map(child => {
          let color = child.color;
          const findChild = findItem.children.find(c => c.name === child.name);
          if (findChild) {
            color[_groupName] = findChild.color;
          }
          return { ...child, color }
        })
        newList.push({ rowName, groupName: newGroupName, children: newChildren })
      }
    }
    let listName = newList.map(item => item.rowName);
    let moreResults = result.filter(r => !listName.includes(r.rowName));
    newList.push(...this.saveColorAsArray(moreResults, _groupName))
    return newList
  }

  settingChangeCB = () => {
    const { files } = this.state;
    const axis = this.childComp.getAxis();
    const setting = this.childComp.getSetting();
    Parameters.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale, sweep: true });
  }

  removeUnselectPorts = () => {
    const { experimentList } = this.props;
    let { portSelect } = this.state;
    const nameGroup = experimentList.map(item => item.name);
    let newPortSelect = { ...portSelect };
    const keys = Object.keys(newPortSelect);
    for (let key of keys) {
      if (newPortSelect[key] && newPortSelect[key].length) {
        let newList = [];
        newPortSelect[key].forEach(item => {
          const [groupName] = strDelimited(item, "@");
          if (nameGroup.includes(groupName)) {
            newList.push(item)
            this.showCurve(item)
          } else {
            const [fileId, row, col] = strDelimited(item, "::");
            Parameters.removeCurve({ row, col, id: `${fileId}` });
          }
        })
        newPortSelect[key] = newList;
      }
    }
    this.setState({ portSelect: newPortSelect })
  }

  resultListAction = {
    changePort: this.changePort,
    colorChange: this.colorChange,
    _rowNameClick: this._rowNameClick,
    _changeSelectAll: this._changeSelectAll,
    fileDownload: this.fileDownload,
    fileNameClick: this.fileNameClick,
    changeAllPorts: this.changeAllPorts,
    changeSelectRowAll: this.changeSelectRowAll,
    changeSelectSignalAll: this.changeSelectSignalAll
  }
}

const mapState = (state) => {
  const { project, sweep: { experimentInfo } } = state.SierraReducer;
  const { currentProjectId } = project;
  const { id = "" } = experimentInfo;
  return {
    currentProjectId,
    sweepId: id
  }
}

export default connect(mapState, null)(SweepChannel);