import CeGeometry from './CeGeometry';
import CeVertex from './CeVertex';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeDonutRound = function (type) {
  var geomType = "DonutRound";
  if (type != null && type != undefined)
    geomType = type;
  CeGeometry.call(this, geomType);

  this.mCenter = new CeVertex();
  this.mOuterD = 1.0;
  this.mInnerD = 0.5;
}

// subclass extends superclass
CeDonutRound.prototype = Object.create(CeGeometry.prototype);
CeDonutRound.prototype.constructor = CeDonutRound;

CeDonutRound.prototype.SetData = function (cx, cy, outD, innD) {
  this.mCenter.SetData(cx, cy);
  this.mOuterD = outD;
  this.mInnerD = innD;
}

CeDonutRound.prototype.CopyFrom = function (fromObj) {
  this.mCenter = fromObj.mCenter.Clone();
  this.mOuterD = fromObj.mOuterD;
  this.mInnerD = fromObj.mInnerD;
}

CeDonutRound.prototype.GetCenter = function () {
  return this.mCenter;
}

CeDonutRound.prototype.SetCenter = function (center) {
  this.mCenter = center;
}

CeDonutRound.prototype.Clone = function () {
  var clnObj = new CeDonutRound();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeDonutRound.prototype.MoveToCenterLocalCS = function () {
  var center = this.mCenter.Clone();
  this.mCenter.mX = 0.0;
  this.mCenter.mY = 0.0;
  return center;
}

CeDonutRound.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.mCenter.GetDataString();
  dataVals.add(this.mOuterD);
  dataVals.add(this.mInnerD);
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeDonutRound.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeDonutRound.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mCenter.GetDataString());
  defData.push(this.mOuterD.toString());
  defData.push(this.mInnerD.toString());
  return defData;
}

CeDonutRound.prototype.UpdateByString = function (dataVals) {
  // inner-diameter, outer-diameter
  if (dataVals.size() != 4)
    return false;

  var centerVals = new StringList();
  centerVals.push(dataVals.get(0));
  centerVals.push(dataVals.get(1));
  this.mCenter = new CeVertex();
  this.mCenter.UpdateByString(centerVals);

  this.mOuterD = parseFloat(dataVals.get(2));
  this.mInnerD = parseFloat(dataVals.get(3));

  return true;
}

export default CeDonutRound;