import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { Spin, Tabs } from 'antd'
import { createPortal } from 'react-dom';
import PortSetup from '@/components/PortSetup';
import { CASCADE } from '../../../constants/pageType';

class CascadeMultiLoadPortSetup extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.state = {
      panelWidth: 600,
      panelHeight: 600,
      layoutData: {},
      loading: true,
      statePorts: [],
      error: false,
      tabs: [],
      activeKey: '',
      loadPorts: []
    }
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width = 1000, height = 1000 } = offset;
    this.setState({
      panelWidth: width * 0.8,
      panelHeight: height * 0.8,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.props.onPortsRef(this);
    const { ports, targetIC, loadComps } = this.props
    this.setState({
      statePorts: ports || [],
      activeKey: targetIC,
      tabs: loadComps.map(comp => comp.name)
    })
    setTimeout(() => {
      this.setLayoutData();
    }, 100)
  }

  setLayoutData = () => {
    const { targetIC, PowerNets, ReferenceNets, pcbId } = this.props;
    this.setState({
      layoutData: {
        targetIC,
        pcbId,
        PowerNets,
        ReferenceNets
      },
      loading: false
    })
  }

  saveStatePorts = (id, ports) => {
    const { multiPCB, targetIC } = this.props
    const { activeKey: load } = this.state
    this.setState({
      statePorts: ports
    })
    if (multiPCB) {
      if (!ports.some(port => port.referencePins.length === 0)) {
        this.setState({ error: false })
      }
      if (load === targetIC) {
        this.props.savePorts(id, ports)
      } else {
        this.props.saveLoadPorts(load, id, ports)
      }
    }
  }

  savePorts = (id, ports) => {
    const { activeKey: load } = this.state
    const { targetIC } = this.props
    if (!ports.some(port => port.referencePins.length === 0)) {
      this.setState({ error: false })
    }
    this.setState({
      statePorts: ports
    })
    if (load === targetIC) {
      this.props.savePorts(id, ports)
    } else {
      this.props.saveLoadPorts(load, id, ports)
    }
  }

  closeModal = () => {
    const { statePorts } = this.state;
    const { notIncludeTarget, onPortsRef, save, savePortsAndClose, multiPCB } = this.props;
    if (multiPCB) {
      if (statePorts.some(port => port.referencePins.length === 0)) {
        this.setState({ error: 'Port require at least one power pin and one ground pin' })
      } else {
        this.setState({ error: false })
        onPortsRef(null);
        save();
        savePortsAndClose && savePortsAndClose(statePorts)
      }
    } else if (notIncludeTarget) {
      onPortsRef(null);
      save();
      savePortsAndClose && savePortsAndClose(statePorts)
    } else if (statePorts.some(port => port.referencePins.length === 0)) {
      this.setState({ error: 'Port require at least one power pin and one ground pin' })
    } else {
      this.setState({ error: false })
      onPortsRef(null);
      save();
      savePortsAndClose && savePortsAndClose(statePorts)
    }
  }

  changeTab = (tab) => {
    const { targetIC, ReferenceNets, pcbId, loadComps, ports } = this.props;
    const { statePorts: prevStatePorts } = this.state
    if (prevStatePorts.length && prevStatePorts.some(port => port.referencePins.length === 0)) {
      this.setState({ error: 'Port require at least one power pin and one ground pin' })
      return
    }
    let { PowerNets } = this.props
    let loadPorts = []
    if (tab !== targetIC) {
      const loadComp = loadComps.find(comp => comp.name === tab)
      if (loadComp.loadMonitor && loadComp.loadMonitor.length) {
        PowerNets = [loadComp.loadMonitor[0].find(item => item.type === 'net').name]
        loadPorts = loadComp.loadPorts || []
      } else {
        PowerNets = []
      }
    }
    const statePorts = tab === targetIC ? ports : loadPorts
    this.setState({
      layoutData: {
        targetIC: tab,
        pcbId,
        PowerNets,
        ReferenceNets
      },
      statePorts,
      loadPorts,
      loading: false,
      activeKey: tab,
      error: false
    })
  }

  renderDialog() {
    const { panelWidth, panelHeight, layoutData, loading, error, tabs, activeKey, loadPorts } = this.state;
    const { targetIC = "", pcbId = "", PowerNets = [], ReferenceNets = [] } = layoutData;
    const title = this.props.title || `Port Generation${PowerNets.length ? ` - ${PowerNets[0]}` : ''}`
    const content = (
      <Fragment>
        <Panel
          id='imp-port-panel'
          className='imp-port-panel'
          position='panel-center-left'
          title={title}
          zIndex={1000}
          onCancel={this.closeModal}
          width={panelWidth > 600 ? panelWidth : 600}
          height={panelHeight}
          draggable
          minWidth={600}
          minHeight={600}
        >
          {loading && <Spin spinning={loading} size="large" tip="Loading...">
            <div style={{ width: "100%", height: 600 }}></div>
          </Spin>}
          {pcbId && <Fragment>
            {tabs && tabs.length ? <div className='port-setup-tabs'><Tabs activeKey={activeKey} onChange={this.changeTab} className='port-setup-tabs-content' type='card' items={tabs.map(item => ({ key: item, label: item }))} /></div> : null}
            <PortSetup
              {...this.props}
              product={CASCADE}
              designId={pcbId}
              PowerNets={PowerNets}
              ReferenceNets={ReferenceNets}
              chip={targetIC}
              savePorts={this.props.notIncludeTarget ? this.saveStatePorts : this.savePorts}
              error={error}
              ports={targetIC === this.props.targetIC ? this.props.ports : loadPorts}
              portSetupTagClass={true}
            />
          </Fragment>
          }
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { text } = this.props;
    return <Fragment>
      <div className="editable-cell-value-wrap" ref={this.inputRef}>
        {text}
      </div>
      {this.renderDialog()}
    </Fragment>
  }
}

export default CascadeMultiLoadPortSetup;