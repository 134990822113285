import ParameterData from "./parameterData";
import { select } from 'd3-selection';
import Viewer, { CurveItem } from '../../Result/Public/sparameter/PlotView';
import { deleteParameter } from '../../api/sparameterNew';
import { getYLabel } from '../../helper/sparameter/utility';

class Parameters {
  constructor() {
    this.parameters = new Map();
    this.plotViewer = null;
  }

  getSize() {
    return this.parameters.size;
  }

  getParameter(id) {
    return this.parameters.get(id);
  }

  // initialization paramter list
  getParametersInfo({ verificationId, verificationSubId, projectId, channelList, groupName }) {
    return new Promise((resolve, reject) => {
      // data
      channelList.forEach(ele => {
        const id = groupName ? `${groupName}@${ele.fileName}` : ele.fileName;
        this.parameters.set(id, new ParameterData({ name: id, verificationId, verificationSubId, projectId, type: ele.type, pcb: ele.pcb, groupName, pcbId: ele.pcbId }));
      });
      resolve(this.parameters);
    })
  }

  // initialization parameter
  setParametersInfo({ param = {}, groupName }) {
    const id = param.id || (groupName ? `${groupName}@${param.fileName}` : param.fileName);
    this.parameters.set(id, new ParameterData({ ...param }));
  }

  createPlot(element, setting, events) {
    this.plotViewer = new Viewer({ element, events, product: 'Sierra' });
    this.plotViewer.createAxis();
    let xAxis = this.plotViewer.getAxis('x');
    xAxis.setUnit('Hz');
    xAxis.setLabel('Frequency');
    xAxis.setScale('linear');
    let yAxis = this.plotViewer.getAxis('y');
    // TODO: Y axis unit:  zam - ohm
    yAxis.setUnit('');
    yAxis.setLabel(getYLabel(setting));
    // Default y scale
    yAxis.setting.scale = "linear";
    return this.plotViewer;
  }

  showCurve({ parameter, row, col, param, value, fileIndex, curveType, sweep }) {
    if (!parameter || !parameter.getCurveData || !parameter.ports) return new Promise((resolve) => { resolve(null) });
    return new Promise((resolve) => {
      parameter.getCurveData({ row, col, param, value, fileIndex, sweep }).then((response) => {
        var curve = new CurveItem();
        curve.x = parameter.freq;
        curve.y = response.curve;
        curve.row = row;
        curve.col = col;
        curve.type = response.type;
        curve.name = parameter.name;
        curve.id = parameter.id;
        curve.color = response.color;
        curve.curveType = curveType || null;
        resolve(curve)
      });
    })
  }

  // curves -[{ parameter, fileIndex, row, col, curveType }]
  showCurves({ curves, param, value, sweep }) {
    const curvesFilter = curves.filter(d => d && d.parameter && d.parameter.getCurveData && d.parameter.ports);
    return Promise.all(curvesFilter.map(cur => cur.parameter.getCurveData({ row: cur.row, col: cur.col, param, value, fileIndex: cur.fileIndex, sweep, curveType: cur.curveType, folderName: cur.folderName }).then(response => {
      let curve = new CurveItem();
      curve.x = cur.parameter.freq;
      curve.y = response.curve;
      curve.row = cur.row;
      curve.col = cur.col;
      curve.type = response.type;
      curve.name = cur.parameter.name;
      curve.id = cur.parameter.id;
      curve.color = response.color;
      curve.curveType = cur.curveType || null;
      return curve;
    })))
  }

  removeCurve({ row, col, id }) {
    this.plotViewer.removeCurve({ row, col, id });
  }

  // [{ row, col, id }]
  removeCurves(curves) {
    this.plotViewer.removeCurves(curves);
  }

  changeAxis(axis, { param, value, scale }) {
    if (this.plotViewer) {
      const _axis = axis.toUpperCase();
      if (_axis === 'Y') {
        let yAxis = this.plotViewer.getAxis('y');
        yAxis.setLabel(getYLabel({ value, yScale: scale }));
      }
      this.plotViewer['update' + _axis + 'Axis']({ param, value, scale });
    }
  }

  updateRange(type, axis) {
    if (axis) {
      this.plotViewer.updateAxisRange(type, axis)
    }
  }

  cleanPlotViewer() {
    if (this.plotViewer !== null) {
      select(this.plotViewer.svgElement).selectAll('*').remove();
      this.plotViewer = null;
    }
  }

  changeParameter({ parameters, setting, yScale, xScale, sweep }) {
    if (this.plotViewer === null) return;
    const xAxis = this.plotViewer.getAxis('x'),
      yAxis = this.plotViewer.getAxis('y');
    xAxis.setScale(xScale);
    yAxis.setScale(yScale);

    const curves = this.plotViewer.curves;
    const promises = curves.map(item => {
      const parameter = parameters.find((param) => param.id === item.id);
      return parameter.getCurveData({ row: item.row, col: item.col, param: setting.parameter, value: setting.value, sweep }).then(
        (response) => {
          item.y = response.curve;
          item.type = response.type;
        }
      )
    });
    return Promise.all(promises).then(() => {
      yAxis.setLabel(getYLabel({ value: setting.value, yScale }));
      let unit = '';
      xAxis.setUnit("Hz");

      if (setting.value === 'am') {
        if (setting.parameter === 's') {
          unit = '';
        } else {
          unit = setting.parameter === 'y' ? 'Ω⁻¹' : 'Ω';
        }
      } else {
        unit = '';
      }
      yAxis.setUnit(unit);
      this.plotViewer.updateYAxis({ param: setting.parameter, value: setting.value, scale: yScale });
    })
  }

  deleteParam(id) {
    return new Promise((resolve, reject) => {
      deleteParameter({ ids: [id] }).then(res => {
        this.parameters.delete(id);
        resolve(this.parameters);
      })
    })
  }

  cleanParameters = () => {
    this.parameters = new Map();
    this.plotViewer = null;
  }

  zoomIn = () => {
    this.plotViewer.zoomIn()
  }

  zoomOut = () => {
    this.plotViewer.zoomOut()
  }

  fitView = () => {
    this.plotViewer.fitView()
  }
}

const parameters = new Parameters();
export default parameters;