import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Table from '@/components/EditableTable';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Divider } from 'antd';
import { uploadSierraConnections, getSierraConnections, updateSierraConnections } from '../../../../services/Sierra/projectCtrl';
import DelConfirm from '../../../../components/DelConfirm';
import { getPanelMaxHeight, getPanelMaxWidth } from '../../../../services/helper/panelSizeHelper';
import designConstructor from '../../../../services/helper/designConstructor';
import { getDefaultIndex } from '../../../../services/helper/setDefaultName';
import "./index.css";

const columns = [
  {
    title: "Board A",
    key: "Aboard",
    dataIndex: "Aboard",
    width: "34%"
  },
  {
    title: "Conn A",
    key: "Aconn",
    dataIndex: "Aconn",
    width: "16%"
  },
  {
    title: "Board B",
    key: "Bboard",
    dataIndex: "Bboard",
    width: "34%"
  },
  {
    title: "Conn B",
    key: "Bconn",
    dataIndex: "Bconn",
    width: "16%"
  }
];

class Connections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connections: [],
      isSaveToProject: true,
      append: true,
      maxWidth: 800,
      maxHeight: 900,
      designNames: []
    }
    this.dialogRoot = document.getElementById('root');
    this.uploadRef = createRef();

    columns.forEach(column => {
      if (column.dataIndex === "Aboard" || column.dataIndex === "Bboard") {
        column.render = (text, record) => {
          const pcbExist = !text ? true : this.state.designNames.find(item => item === text);
          return pcbExist ?
            <div className='connections-board-title'>{text}</div>
            : <div className='aurora-file-check-div connections-board-title'>
              <span>{text}</span>
              <Tooltip
                title={`The PCB does not exist in the project.`}
                overlayClassName='aurora-tooltip'
              >
                <QuestionCircleOutlined className='aurora-file-check-icon' onClick={(e) => this.spanClick(e)} /></Tooltip>
            </div>;
        }

        column.onCell = (record) => {
          return {
            edit: "auto-complete",
            record,
            dataIndex: column.dataIndex,
            dataSource: this.state.designNames.map(name => ({ value: name, label: name })),
            onChange: (value, _dataIndex, _record) => this.editConnPCB(value, record, column.dataIndex),
            onSelect: (value, _dataIndex, _record) => this.editConnPCB(value, record, column.dataIndex),
            // onBlur: (value, _dataIndex, _record) => this.editConnPCB(value, record, column.dataIndex),
            getPopupContainer: document.getElementById('root')
          }
        }
      }

      if (column.dataIndex === "Aconn" || column.dataIndex === "Bconn") {

        column.render = (text, record, index) => {
          const conn = record[column.dataIndex] || {};
          return (
            <span>{conn.component}
              {conn.pin ? <span>.{conn.pin}</span> : null}
              {column.dataIndex === "Bconn" ? <CloseOutlined
                className='connection-delete-icon'
                title="Remove the connection"
                onClick={(e) => this.delConn(e, record, index)} /> : null}
            </span>
          );
        }

        column.onCell = (record) => {
          const conn = record[column.dataIndex] || {};
          const value = conn.pin && conn.component ? `${conn.component}.${conn.pin}` : conn.pin ? `.${conn.pin}` : `${conn.component || ""}`

          return {
            edit: true,
            record: { ...record, [column.dataIndex]: value },
            dataIndex: column.dataIndex,
            handleSave: (_record) => this.editComponentPin(_record, record, column.dataIndex)
          }
        }
      }
    })
  }

  spanClick = (e) => {
    e && e.stopPropagation();
  }

  delConn = (e, record, index) => {
    e && e.stopPropagation();
    const { connections } = this.state;
    const new_connections = [...connections];
    new_connections.splice(index, 1);
    this.setState({
      connections: new_connections
    })
  }

  editConnPCB = (value, prevRecord, dataIndex) => {
    const { connections } = this.state;
    const new_connections = [...connections];
    if (!dataIndex) return;
    const index = new_connections.findIndex(item => item.index === prevRecord.index);
    if (index <= -1) {
      return;
    }
    new_connections[index][dataIndex] = value;
    this.setState({
      connections: new_connections
    })
  }

  editComponentPin = (record, prevRecord, dataIndex) => {
    const { connections } = this.state;
    const new_connections = [...connections];
    if (!dataIndex) return;
    const [component, pin] = record[dataIndex].split(".");
    const index = new_connections.findIndex(item => item.index === prevRecord.index);
    if (index <= -1) {
      return;
    }
    new_connections[index][dataIndex] = {
      component: component || "",
      pin: pin || ""
    };
    this.setState({
      connections: new_connections
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    // get current project connections
    const { projectId } = this.props;
    const connections = await getSierraConnections(projectId);
    const designs = designConstructor.getAvailableDesigns(projectId);
    (connections || []).forEach((item, index) => { item.index = index.toString() });
    this.setState({
      connections: connections || [],
      designNames: designs.map(item => item.name)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 800),
      maxHeight: getPanelMaxHeight(offset, 300) - 80
    })
  }

  closePanel = () => {
    this.saveConnections();
    this.props.closePanel(false)
  }

  saveConnections = async () => {
    const { connections } = this.state;
    const { projectId } = this.props;
    if (connections) {
      connections.forEach(item => { delete item.index });
      await updateSierraConnections({ projectId, connections, append: false });
    }
  }

  okConfirm = async () => {
    const { connections } = this.state;
    const { isTopMenu } = this.props;
    this.saveConnections();
    if (!isTopMenu) {
      this.props.connSignals(connections);
    } else {
      this.props.closePanel(false)
    }
  }

  cancelConfirm = () => {
    this.saveConnections();
    this.props.closePanel(false)
  }

  uploadConn = () => {
    const ele = this.uploadRef.current;
    if (!ele) return;
    ele.click();
  }

  uploadConnFile = async (e) => {
    const { projectId } = this.props;
    const { connections } = this.state;
    const file = e.target.files[0];

    try {
      //get api to parse file and get connections
      const new_connections = await uploadSierraConnections({ projectId, file });
      //  await updateSierraConnections({ projectId, connections: new_connections, append })
      // const connections = await getSierraConnections(projectId);
      if (new_connections && new_connections.length) {
        const saveToProject = !connections || !connections.length ? true : false;
        this.setState({
          new_connections,
          openPanel: connections && connections.length ? true : false,
          msg: "Upload connections file successfully!"
        }, () => {
          if (saveToProject) {
            this.saveConnectionsToProject("replace")
          }
        })
      } else {
        this.setState({
          msg: "Upload connections file successfully!",
          openPanel: false
        })
      }
    } catch (error) {
      console.error(error);
      this.setState({
        msg: "Upload connections file failed!",
        openPanel: false
      })
    }
    setTimeout(() => {
      this.setState({
        msg: ""
      })
    }, 3000)
    this.uploadRef.current.value = '';
  }

  saveConnectionsToProject = async (type) => {
    const { projectId } = this.props;
    const { new_connections } = this.state;
    const append = type === "merge" ? true : false;
    await updateSierraConnections({ projectId, connections: new_connections, append });
    const connections = await getSierraConnections(projectId);
    (connections || []).forEach((item, index) => { item.index = index.toString() });
    this.setState({
      openPanel: false,
      connections: connections || []
    })
  }

  getContentFooter = () => {
    const { isTopMenu } = this.props;
    return <div className="sierra-connections-footer-content">
      <Button key="ok" type="primary" onClick={this.okConfirm}>OK</Button>
      {!isTopMenu ? <Button key="cancel" style={{ marginLeft: 30 }} onClick={this.cancelConfirm} >Cancel</Button> : null}
    </div>
  }

  addConn = () => {
    let connections = [...this.state.connections];
    connections.push({
      index: getDefaultIndex(connections.length, connections.map(item => item.index)),
      Aboard: "",
      Aconn: { component: "", pin: "" },
      Bboard: "",
      Bconn: { component: "", pin: "" }
    })
    this.setState({
      connections
    })
  }

  downloadConn = () => {

  }

  onCheckAllChange = (e) => {
    this.setState({
      isSaveToProject: e.target.checked
    })
  }

  switchAppend = (key) => {
    this.setState({
      append: key === "Add"
    })
  }

  render = () => {
    const { connections = [], msg, openPanel, maxHeight, maxWidth } = this.state;
    const content = (
      <Panel
        className='sierra-connections-panel sierra-panel'
        position='panel-center-left'
        title="PCB Connections"
        zIndex={2000}
        onCancel={this.closePanel}
        width={maxWidth < 800 ? maxWidth : 800}
        maxWidth={maxWidth}
        draggable
        minHeight={300}
        maxHeight={maxHeight}
        footer={this.getContentFooter()}
        footerHeight={52}
      >
        <div className='sierra-connections-main'>
          <div className='sierra-connections-buttons'>
            {/*  {new_connections && new_connections.length ? <div className='sierra-connections-options-content'>
              <Checkbox
                checked={isSaveToProject}
                onChange={(e) => this.onCheckAllChange(e)}
                className='sierra-connections-checkbox'
              />
              <span>Save to project</span>
              <Select
                value={append ? "Add" : "Replace"}
                onSelect={this.switchAppend}
                className="aurora-select"
                popupClassName='aurora-select-dropdown'
                popupMatchSelectWidth={false}
                getPopupContainer={() => document.getElementById('root')}
              >
                <Option key="Add" value="Add">Add</Option>
                <Option key="Replace" value="Replace">Replace</Option>
              </Select>
            </div> : null} */}
            {/* <Button
              key="download"
              type="primary"
              className='sierra-connection-download-button'
              onClick={this.downloadConn}
            >Download</Button> */}
            <Button
              key="upload"
              type="link"
              className='sierra-connection-upload-button'
              onClick={this.uploadConn}
            >Upload Excel</Button>
            <Divider type="vertical" />
            <Button
              key="add"
              type="link"
              className='sierra-connection-add-button'
              onClick={this.addConn}
            >Add New Connection</Button>
          </div>
          {msg ? <span
            className={msg.match("successfully") ? "aurora-success-msg" : 'aurora-model-name-error-msg'}
          >{msg}</span> : null}
          <Table
            columns={columns}
            dataSource={connections || []}
            rowKey={(record) => record.index}
            size="small"
            pagination={false}
            tableLayout="fixed"
            className="sierra-connections-table"
            tablePadding={true}
          />
        </div>
      </Panel>);
    return <Fragment>
      {createPortal(content, this.dialogRoot)}
      <input
        type='file'
        ref={this.uploadRef}
        style={{ display: 'none' }}
        accept={".xlsx"}
        onChange={(e) => this.uploadConnFile(e)}
      />
      {openPanel ? <DelConfirm
        type="uploadConnections"
        maskStyle={true}
        delConfirmClick={this.saveConnectionsToProject}
        confirmButton="uploadConnections"
        message={"Keep the existing setup?"}
      /> : null}
    </Fragment>
  }
}

export default Connections;