import React, { PureComponent, Fragment, createRef } from "react";
import { CloseOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Menu, Input } from "antd";
import ModelDialog from "@/components/SelectModel/ModelDialog";
import DecapModelSelection from "./decapModelSelection";
import { getDefaultIndex, getDefaultName } from "../../services/helper/setDefaultName";
import { checkNameFormat } from "../../services/helper/nameFormatCheck";
import "./index.css";

const ADD_MODEL = "Add Model";

class ModelSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    const models = props.record.models;
    let _models = models ? JSON.parse(JSON.stringify(models)) : [];
    if (!_models.length || !_models.find((item) => !!item.id)) {
      let decapList = props.decapList;
      let model = {};
      // Previous selected model
      let selectedModel = props.getSelectedModel();
      if (selectedModel) {
        model = {
          id: selectedModel.id,
          name: selectedModel.name,
          subcktName: "",
          type: selectedModel.type,
          libraryType: selectedModel.libraryType,
          folderName: selectedModel.folderName,
          editName: "",
        };
      } else if (
        decapList.length === 1 &&
        (!model.type || model.type === "spice")
      ) {
        model = {
          id: decapList[0].id,
          name: decapList[0].name,
          subcktName: "",
          type: "spice",
          libraryType: decapList[0].type,
          folderName: "",
          editName: "",
        };
      }
      _models[0] = model;
    }
    _models = _models.map((item, index) => {
      return { ...item, key: index.toString(), editName: item.editName || `${index + 1}` };
    });
    let modelNameList = _models.map((item, index) => {
      return { title: item.editName, key: index.toString() };
    });

    this.state = {
      modelNameList: [...modelNameList],
      editName: '',
      selectedKeys: modelNameList.length ? [modelNameList[0].key] : [],
      models: _models,
      isSelected: -1,
      errorMsg: null,
    };
    this.inputRef = createRef();
    this.nameInputRef = createRef();
    this.dialogRoot = document.getElementById(
      props.trigger ? props.trigger : "root"
    );
  }

  componentDidMount() {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect(),
    });
    document.addEventListener("mousedown", this.handleClickOutside, true);
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, true);
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.setState({
      offset: this.inputRef.current.getBoundingClientRect(),
    });
  };

  handleClickOutside = (e) => {
    const { target } = e;
    if (!this.dialogRoot.contains(target) &&
      target.classList[0] !== "ant-select-dropdown-menu-item" &&
      target.classList[0] !== "ant-select-dropdown-menu"
    ) {
      this.saveModel();
    }
  };

  saveModel = () => {
    const { models, apply } = this.state;
    let _models = models;
    const { record } = this.props;
    this.props.saveDecapModel(record, _models, apply);
    this.props.save();
  };

  changeMenu = (e, item) => {
    const { selectedKeys, modelNameList, models, isSelected, errorMsg } = this.state;
    if (errorMsg) {
      this.setState({
        errorMsg: "Please change name!"
      })
      return;
    }
    const { decapList } = this.props;
    let _selectedKeys = [...selectedKeys],
      _modelNameList = [...modelNameList],
      _models = [...models];
    let modelKey = item.key;
    if (item.key === ADD_MODEL) {
      const length = _modelNameList.length;
      // auto select library exist type
      let defaultType = "spice";
      if (!decapList.find((item) => item.dataType === "decap_file")) {
        defaultType = "touchstone";
        if (!decapList.find((item) => item.dataType === "decap_touchstone")) {
          defaultType = "rlc";
        }
      }
      // let key value is not duplicate
      const key = getDefaultIndex(length, _modelNameList.map((item) => item.key));
      const title = getDefaultName({ nameList: _modelNameList, defaultKey: "", key: "title" })
      _modelNameList.push({
        title: title,
        key: key,
      });
      _models.push({ key: key, type: defaultType, editName: title });
      modelKey = key;
      _selectedKeys.splice(0, 1, key);
      this.selectionRef.setState({
        decapModelOpen: true,
      })
    } else if (item.key === isSelected) {
      return;
    } else {
      _selectedKeys.splice(0, 1, item.key);
      // this.setState({
      //   errorMsg: '',
      // })
    }
    const index = _models.findIndex(it => it.key === modelKey);
    const _model = index > -1 ? _models[index] : null;
    this.setState({
      modelNameList: _modelNameList,
      selectedKeys: _selectedKeys,
      models: _models,
    }, () => {
      this.selectionRef.changeMenu({
        index,
        model: _model
      })
    });
  };

  deleteModel = (e, key) => {
    e.stopPropagation();
    const { models, modelNameList } = this.state;
    let _models = [...models],
      _modelNameList = [...modelNameList];

    const index = _models.findIndex((item) => item.key === key);
    if (index < 0) {
      return;
    }
    _models.splice(index, 1);
    _modelNameList.splice(index, 1);

    this.setState({
      models: _models,
      modelNameList: _modelNameList,
    });
  };

  changeName = (e, item) => {
    e.stopPropagation();
    const { models, modelNameList } = this.state;
    let _models = [...models],
      _modelNameList = [...modelNameList];

    const key = item.key;
    let _isDoubleClicked = key;

    const index = _modelNameList.findIndex((item) => item.key === key);
    if (index < 0) {
      return;
    }
    this.setState({
      models: _models,
      isSelected: _isDoubleClicked,
      editName: "",
    });
  };

  // close model to save
  closeModelSelect = (e) => {
    e.stopPropagation();
    this.saveModel();
    this.props.save();
  };

  updateModels = (models) => {
    this.setState({
      models,
    });
  };

  changeInputValue = (e) => {
    let _editName = e.target.value;
    this.setState({
      editName: _editName,
    })
  }

  inputBlur = (e, item) => {
    this.onPressEnter(e, item);
  }

  onKeyDown = (e, item) => {
    if (e.keyCode === 188) {
      this.onPressEnter(e, item);
    }
  }

  onPressEnter = (e, item) => {
    e.stopPropagation();
    const { models, modelNameList, editName } = this.state;
    let _models = [...models],
      _modelNameList = [...modelNameList];
    const key = item.key;
    const index = _modelNameList.findIndex((item) => item.key === key);
    if (index < 0) {
      return;
    }
    // if not set editName return editName before
    if (editName === "") {
      this.setState({
        models: _models,
        modelNameList: _modelNameList,
        isSelected: -1,
        errorMsg: '',
      })
      return;
    }
    let errorMsg = checkNameFormat(editName);
    if (errorMsg) {
      this.setState({
        errorMsg: errorMsg
      });
      return;
    }
    const _title = item.title;
    let _filter = modelNameList.filter(item => item.title !== _title);
    let names = _filter.map(item => item.title);
    if (names.includes(editName)) {
      this.setState({
        errorMsg: `${editName} already exists!`,
      });
      this.nameInputRef.current.focus();
      return;
    }
    _models[index].editName = editName;
    _modelNameList[index].title = _models[index].editName;
    this.setState({
      models: _models,
      modelNameList: _modelNameList,
      isSelected: -1,
      errorMsg: '',
    })
  }

  onRef = (ref) => {
    this.selectionRef = ref;
  }

  renderDialog() {
    const { offset, isSelected, errorMsg } = this.state;
    const { trigger, decapList, record: { part } } = this.props;
    if (offset === undefined) return null;
    const { models, modelNameList, selectedKeys } = this.state;
    const MenuItems = [
      {
        key: 'Models',
        label: <div className="decap-models-content">
          {`Models`}
          <div
            className="decap-model-add"
            onClick={(e) => {
              this.changeMenu(e, { key: ADD_MODEL });
            }}
            key={ADD_MODEL}
          >
            <PlusCircleOutlined className="decap-model-add-icon"></PlusCircleOutlined>
          </div>
        </div>
      },
      ...modelNameList.map(item => {
        return {
          key: item.key,
          className: selectedKeys.includes(item.key)
            ? "model-select-menu-item-selectd model-select-menu-item"
            : "model-select-menu-item",
          onClick: (e) => this.changeMenu(e, item),
          label: <div className="decap-model-name-list">
            <div className="decap-model-name-title">
              {isSelected === item.key ? (
                <Input
                  autoFocus
                  size="small"
                  ref={this.nameInputRef}
                  defaultValue={item.title}
                  onChange={(e) => this.changeInputValue(e, item)}
                  onBlur={(e) => this.inputBlur(e, item)}
                  onPressEnter={(e) => this.onPressEnter(e, item)}
                  onKeyDown={(e) => this.onKeyDown(e, item)}
                />
              ) : (
                item.title ? item.title : `${item.key * 1 + 1}`
              )}
            </div>
            <div
              className="decap-model-edit"
              onClick={(e) => {
                this.changeName(e, item);
              }}
            >
              <EditOutlined />
            </div>
            <div
              className="decap-model-delete"
              onClick={(e) => { this.deleteModel(e, item.key); }}
            >
              <CloseOutlined />
            </div>
          </div>
        }
      })
    ]
    return (
      <ModelDialog
        offset={this.inputRef}
        root={this.dialogRoot}
        type="pdnModel"
        isSelected={isSelected}
        className="pdn-model-select-dialog"
      >
        <div className="decap-dialog-title">
          <h4
            className="decap-dialog-title-content"
            title={part}
          >{part}</h4>
        </div>
        <div id="model-select-content-root" className="model-select-content">
          <div
            className="decap-dialog-close"
            onClick={(e) => this.closeModelSelect(e)}
          >
            <CloseOutlined className="decap-close-icon"></CloseOutlined>
          </div>
          <div className="model-select-menu-box">
            <Menu className="model-select-menu" selectedKeys={selectedKeys} items={MenuItems} />
            {/* {errorMsg ? <span className="aurora-error-msg-span">{errorMsg}</span> : null} */}
          </div>
          <div className="model-select-selection-box">
            <DecapModelSelection
              onRef={this.onRef}
              models={models}
              nameList={modelNameList}
              trigger={trigger}
              decapList={decapList}
              getLibraryFile={this.props.getLibraryFile}
              updateModels={this.updateModels}
              selectedKeys={selectedKeys}
            />
            {errorMsg ? <span className="aurora-error-msg-span">{errorMsg}</span> : null}
          </div>
        </div>
      </ModelDialog>
    );
  }

  render() {
    const { inputRef } = this;
    const { value } = this.state;
    return (
      <Fragment>
        <div className="editable-cell-value-wrap" ref={inputRef}>
          {value}
        </div>
        {this.renderDialog()}
      </Fragment>
    );
  }
}

export default ModelSearch;
