import React from 'react';
import { checkRLCValueFormat } from '@/services/helper/dataProcess';
import { MultiPinSPICE, ICTypes } from '../constants';
import {
  IBIS, SPICE, CONNECTOR_SPICE_SIERRA, CONNECTOR_TOUCHSTONE, VECTOR, PASSIVE_TOUCHSTONE, PASSIVE_SPICE, REPEATER, PKG_TOUCHSTONE, PKG_SPICE,
  TRACE
} from '../../../constants/libraryConstants';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { getVirtualCompsErrors } from '../../../services/VirtualComponent/errorCheck';
import { CAP, COMP_REPEATER, CONNECTOR, IND, JUMPER, RES, RLC } from '../../../constants/componentType';
import designConstructor from '../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import preLayoutData from '../../../services/Sierra/prelayout/preLayoutData';
import { MODEL, SCHEMATIC } from '../../../services/Sierra/prelayout/prelayoutConstants';
import { checkTraceLength, getPreLayoutSignalsSections, TRACE_LENGTH_UNITS } from '../../../services/Sierra/prelayout/Schematic';
import { splitValueUnitByUnits } from '../../../services/helper/numberHelper';

const RLCType = [RLC, RES, IND, CAP, JUMPER];

export function getSierraErrorCheck(sierraInfo, defaultBufferSpice) {
  if (!sierraInfo) {
    return;
  }
  const { PCBsInInterface } = sierraInfo;
  let warning = [];
  let error = [];

  if (PCBsInInterface && PCBsInInterface.length === 0) {
    error.push(<p style={{ margin: 0 }}>No signals are set.</p>)
  }
  const info = sierraInfo.info;
  if (!info) return;

  if (!sierraInfo.Interfaces || sierraInfo.Interfaces.length === 0) {
    error.push(<p style={{ margin: 0 }}>No signal nets are set.</p>)
  }

  let errorPowerGndNets = [], driverBufferModelList = [], receiverBufferModelList = [], signalNames = [];

  // Power/Ground not found in PCB
  sierraInfo.Interfaces.forEach(content => {
    const vendor = designConstructor.getDesignVendor(content.pcbId);
    const components = content.content && content.content.components ? content.content.components : [];
    const channel = content.content && content.content.channel ? content.content.channel : {};
    const powerNets = content.content && content.content.powerNets ? content.content.powerNets : [];
    const powerComponents = content.content && content.content.powerComponents ? content.content.powerComponents : [];
    const signals = content.content && content.content.signals ? content.content.signals : [];

    signalNames.push(...signals.map(item => item.name));
    // Prelayout node
    if (vendor === PRE_LAYOUT) {
      const preLayoutError = preLayoutInterfaceErrorCheck({ content, signals, components });
      error.push(...preLayoutError);
    }

    if (vendor !== PRE_LAYOUT) {
      if (powerNets.length === 0) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{content.pcb}</span>" - No power and ground nets are set.</p>)
      } else {
        const GND = powerNets.find(net => parseFloat(net.value) === 0 || net.name.match(/gnd/i));
        if (!GND) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{content.pcb}</span>" - No ground net is set.</p>)
        }
      }
    }

    // model
    const ICs = components.filter(item => ICTypes.includes(item.type));
    if (ICs.length > 0) {
      ICs.forEach(comp => {
        if (comp.pkg) {
          let _error = checkPkgFile(comp.pkg, `${content.pcb}::${comp.name}`);
          error.push(..._error)
        }

        //ports error check
        if (vendor !== PRE_LAYOUT) {
          const ports_generate_setup_list = content.content ? content.content.ports_generate_setup_list || [] : [];
          const portsError = getExtractionPortErrorCheck(ports_generate_setup_list, comp, channel, content.pcb);
          error.push(...portsError);
        }

        const compModel = comp.model;
        const _error = checkPins({ pins: comp.pins, compModel, name: `${content.pcb}::${comp.name}`, sweep: false, driverBufferModelList, receiverBufferModelList })
        error.push(..._error)
      });
    }

    const repeaterError = getRepeaterErrorCheck({ components, pcb: content.pcb });
    error.push(...repeaterError);

    // RES/IND/CAP/JUMPER components value
    const rlcError = getRLCValueCheck({ components, powerNets, errorPowerGndNets, pcb: content.pcb })
    error.push(...rlcError);
    // R L C components model
    const passiveModels = components.filter(item => RLCType.includes(item.type));
    if (passiveModels.length > 0) {
      const _error = checkPassive(passiveModels, content.pcb);
      error.push(..._error)
    }

    // powerComponents value check
    const pwrCompError = getPowerCompsCheck({ powerComponents, channel, pcb: content.pcb })
    error.push(...pwrCompError);

    const connsError = getConnCheck(components, content.pcb);
    error.push(...connsError);

    //virtual components error check
    if (content.content && content.content.virtualComps && content.content.virtualComps.length) {
      const errors = getVirtualCompsErrors(content.content.virtualComps, { getCheckFile: sierraLibrary.checkFile, pcbName: content.pcb })
      if (errors && errors.length) {
        error.push(...errors);
      }
    }
  });

  signalNames = [...new Set(signalNames)];
  let driverNotExist = [], receiverNotExist = [];
  if (!driverBufferModelList.length && !receiverBufferModelList.length) {
    driverNotExist.push(...signalNames);
    receiverNotExist.push(...signalNames);
  } else {
    let receiverError = false, receiverPins = [];

    for (let signal of signalNames) {
      const driverModels = driverBufferModelList.filter(it => it.signal === signal);
      const receiverModels = receiverBufferModelList.filter(it => it.signal === signal);

      if (!driverModels.length) {
        driverNotExist.push(signal);
      }

      if (!receiverModels.length) {
        receiverNotExist.push(signal);
        continue;
      }

      for (let reItem of receiverModels) {
        if (reItem.model && reItem.model.modelName && !reItem.model.modelType) {
          error.push(<p style={{ margin: 0 }}><span className="font-bold">{reItem.comp}/{reItem.pin}</span> does not set receiver model type.</p>)
        }
        if (reItem.model && reItem.model.modelName && (!reItem.model.libraryId || !reItem.model.fileName || !reItem.model.libType)) {
          error.push(<p style={{ margin: 0 }}><span className="font-bold">{reItem.comp}/{reItem.pin}</span> does not set receiver model file.</p>)
        }
      }

      const findReceiverExistModel = receiverModels.find(it => it.model && it.model.fileName && it.model.modelType && it.model.modelName);
      const receiverModelNotExist = (!findReceiverExistModel && (!defaultBufferSpice || !defaultBufferSpice.libraryId || !defaultBufferSpice.name));

      if (receiverModelNotExist) {
        receiverError = true;
        receiverPins.push(...receiverModels.map(item => `${item.comp}/${item.pin}`));
      }
    }

    if (receiverError) {
      error.push(<p style={{ margin: 0 }}><span className="font-bold">[{receiverPins.join(", ")}]</span> {receiverPins.length > 1 ? "do" : "does"} not set receiver model and default receiver buffer model is not set, You can set the receiver model of the interface or set a model in the SPICE library as the default receiver buffer model.</p>)
    }
  }

  //driver
  driverNotExist.length && error.push(<p style={{ margin: 0 }}>{driverNotExist.length > 1 ? "Signals " : "Signal "}
    <span className="font-bold">[{driverNotExist.join(", ")}]</span> {driverNotExist.length > 1 ? "do" : "does"} not set any driver.</p>);
  //receiver
  receiverNotExist.length && error.push(<p style={{ margin: 0 }}>{receiverNotExist.length > 1 ? "Signals " : "Signal "}
    <span className="font-bold">[{receiverNotExist.join(", ")}]</span> {receiverNotExist.length > 1 ? "do" : "does"} not set any receiver.</p>)

  if (warning.length === 0 && error.length === 0) {
    return null;
  } else {
    return {
      //warning, 
      error
    }
  }
}

export function getSweepErrorCheck(experiment, base) {
  const error = [];
  const Interfaces = experiment.Interfaces;
  for (let item of Interfaces) {
    if (item.pkg) {
      item.pkg.forEach(comp => {
        const { component, pkgInfo } = comp;
        const _error = checkPkgFile(pkgInfo, component);
        error.push(..._error);
      })
    }
    if (item.pins) {
      const baseInterface = base.Interfaces.find(i => i.interfaceId === item.interfaceId) || {};
      const pins = baseInterface.pins || [];
      item.pins.forEach(comp => {
        const { component, pinInfo } = comp;
        let basePinInfo = pins.find(p => p.component === component);
        basePinInfo = basePinInfo ? basePinInfo.pinInfo : [];
        let _pinInfo = pinInfo ? pinInfo.map(p => {
          const basePin = basePinInfo.find(pin => pin.pin === p.pin);
          if (basePin) {
            return { usage: basePin.usage, ...p }
          }
          return p
        }) : [];
        let compModel = {}
        if (item.model) {
          let _compModel = item.model.find(m => m.component === component);
          if (_compModel) {
            compModel = _compModel.modelInfo;
          }
        }
        const _error = checkPins({ pins: _pinInfo, compModel, name: component, sweep: true });
        error.push(..._error);
      })
    }
    if (item.passive && item.passive.length) {
      const _error = checkPassive(item.passive);
      error.push(..._error);
    }
  }
  return error.length ? error : null;
}

export function checkPkgFile(pkg = {}, name) {
  let error = [], notUseSameFile = [];
  if (pkg.type === 'IBIS') {
    if (!pkg.component || !pkg.model) {
      error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" package model is not set.</p>)
    }
  } else if (pkg.type === 'Touchstone / SPICE') {
    const pkgFiles = pkg.files ? pkg.files : [];
    const Existfiles = pkgFiles.filter(item => item.fileName && item.type);
    if (!Existfiles || Existfiles.length === 0) {
      error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" package model is not set.</p>)
    } else {
      for (let _file of Existfiles) {
        const typeText = _file.type === 'Touchstone' ? 'port' : 'node';
        const fileType = _file.type === 'Touchstone' ? 'touchstone' : 'spice';
        const fileExist = _file.type === 'Touchstone' ? sierraLibrary.checkFile(PKG_TOUCHSTONE, _file.libraryId) : sierraLibrary.checkFile(PKG_SPICE, _file.libraryId);
        if (!fileExist) {
          error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" {fileType} file {_file.fileName} not exist.</p>)
        }
        if ((_file.type === 'SPICE' && !_file.subckt) || !pkg.pairs || pkg.pairs.length === 0) {
          error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" package model is not set.</p>)
        } else if (fileExist) {
          //Find port not set
          const _notPortPairs = pkg.pairs && pkg.pairs.length ? pkg.pairs.filter(item => !item.node) : [];
          if (_notPortPairs && _notPortPairs.length > 0) {
            const nodes = _notPortPairs.map(item => item.pin);
            error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" pins [{nodes.join(", ")}] are not connected to any {typeText}.</p>)
          }
          let samePortPairs = [], text = "";
          if (pkg.pairs) {
            //Find same port
            pkg.pairs.forEach(item => {
              const sameFind = pkg.pairs.find(it => it.pin !== item.pin && item.node && (it.node === item.node && it.libraryId === item.libraryId && (!item.subckt || item.subckt === it.subckt)));
              const existIndex = samePortPairs.findIndex(it => (sameFind && ((it.pin === item.pin && it.samePin === sameFind.pin) || (it.samePin === item.pin && it.pin === sameFind.pin))));
              if (sameFind && existIndex < 0) {
                samePortPairs.push({ pin: item.pin, samePin: sameFind.pin });
                text = `${text ? `${text},` : ""} [${item.pin}, ${sameFind.pin}]`;
              }
              const diePort = pkg.pairs.find(it => it.pin === `${item.pin}_u`);
              if (diePort && (diePort.libraryId !== item.libraryId || diePort.subckt !== item.subckt)) {
                if (!notUseSameFile.includes(`${item.pin} and ${diePort.pin}`)) {
                  notUseSameFile.push(`${item.pin} and ${diePort.pin}`)
                  error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" {item.pin} and {diePort.pin} should be the same model file.</p>)
                }
              }
            })
          }
          if (samePortPairs && samePortPairs.length > 0) {
            error.push(<p style={{ margin: 0 }}>PKG "<span className="font-bold">{name}</span>" pins {text} connect the same {typeText}.</p>)
          }
        }
      }
    }
  }
  return error
}

export function checkPins({ pins, compModel = {}, name, sweep = false, driverBufferModelList = [], receiverBufferModelList = [] }) {

  let error = [];
  pins.forEach(pin => {
    let pinIn = null, pinDie = null;
    if (compModel.pairs && compModel.pairs.length > 0) {
      pinIn = compModel.pairs.find(item => item.pin === `${pin.pin}_in`);
      pinDie = compModel.pairs.find(item => item.pin === `${pin.pin}_u`);
    }

    if (pin.usage) {
      const model = pin.model;
      if (model && model.libType === MultiPinSPICE) {
        if (!pinDie || !pinDie.node) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set{!pin.usage && ' type and'} simulation model.</p>)
        } else {
          const findFile = compModel.files ? compModel.files.find(item => item.libraryId === pinDie.libraryId && item.fileName === pinDie.fileName && item.subckt === pinDie.subckt) : null;
          const text = findFile && findFile.folder ? `${findFile.folder}::${pinDie.fileName}` : pinDie.fileName;
          const childName = findFile && findFile.folder ? pinDie.fileName : null;
          let modelExist = sierraLibrary.checkFile(SPICE, pinDie.libraryId, childName);
          if (!modelExist) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" file {text} not exist.</p>)
          }
        }
        if (pin.usage === 'Driver' && pinIn && pinIn.node) {
          const ND_IN = pin.pinModels ? pin.pinModels.find(item => item.pinName === "nd_in") : null;
          if (!ND_IN) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set stimulus model.</p>)
          } else if (ND_IN.type !== MultiPinSPICE) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" driver Model has set MultiPin SPICE and set in node, stimulus must be set to MultiPin SPICE.</p>)
          }
        }
      } else {
        if (pin.usage === "Driver" && !sweep && (!model || (model && !model.modelType && !model.modelName))) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set{!pin.usage && ' type and'} driver model.</p>)
        } else if (pin.usage === "Driver" && model && !model.modelName) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set{!pin.usage && ' type and'} model name in driver model.</p>)
        } else if (pin.usage === "Driver" && model && !model.modelType) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set{!pin.usage && ' type and'} model type in driver model.</p>)
        }

        if (model && model.libType === 'SPICE' && model.fileName && model.modelName) {
          let modelExist = sierraLibrary.checkFile(SPICE, model.libraryId);
          if (!modelExist) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" file {model.fileName} not exist.</p>)
          }
        } else if (model && model.libType === 'IBIS' && model.fileName && model.modelName) {
          let modelExist = sierraLibrary.checkFile(IBIS, model.libraryId);
          if (!modelExist) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" file {model.fileName} not exist.</p>)
          }
        }

        if (pin.usage === 'Driver' && pin.pinModels && pin.pinModels.length > 0) {
          const ND_PU = pin.pinModels.find(item => item.pinName === "nd_pu");
          const ND_PD = pin.pinModels.find(item => item.pinName === "nd_pd");

          if (model && model.libType === 'SPICE') {
            if (!ND_PU.voltage) {
              error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set V_PU.</p>)
            }

            if (!ND_PD.voltage) {
              error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set V_PD.</p>)
            }
          } else if (model && model.libType === 'IBIS') {
            if (pin.powerOff === '1') {
              if (!ND_PU.voltage) {
                error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set V_PU.</p>)
              }

              if (!ND_PD.voltage) {
                error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set V_PD.</p>)
              }
            }
          }
        } else if (pin.usage === 'Driver' && !sweep) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set stimulus model.</p>)
        }
      }
      if (pin.usage === 'Driver') {
        driverBufferModelList.push({ usage: pin.usage, pin: pin.pin, comp: name, model: pin.model, signal: pin.signal });
      }

      if (pin.usage === 'Receiver') {
        receiverBufferModelList.push({ usage: pin.usage, pin: pin.pin, comp: name, model: pin.model, signal: pin.signal });
      }

      if (pin.usage === 'Driver' && pin.pinModels && pin.pinModels.length > 0) {
        const ND_IN = pin.pinModels.find(item => item.pinName === "nd_in");
        const ND_EN = pin.pinModels.find(item => item.pinName === "nd_en");
        let inputStimulus = null, vector = null;
        if (ND_EN) {
          if (ND_EN.type === 'GND' || ND_EN.type === 'VCC') {
            inputStimulus = ND_IN.type;
            vector = ND_IN;
          } else {
            inputStimulus = ND_EN.type;
            vector = ND_EN;
          }
        } else {
          inputStimulus = ND_IN.type;
          vector = ND_IN;
        }
        if ((!inputStimulus && !sweep && model && model.modelType && model.libType !== MultiPinSPICE)) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set stimulus model.</p>)
        } else if (!inputStimulus && sweep && (!model || (model && model.modelType && model.libType !== MultiPinSPICE))) {
          error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set stimulus model.</p>)
        }
        if (inputStimulus === 'VEC') {
          if (!vector.stimulus || !vector.stimulus.libraryId) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set the simulation stimulus vector.</p>)
          } else {
            const stimulusModel = vector.stimulus;
            if (stimulusModel) {
              let modelExist = sierraLibrary.checkFile(VECTOR, stimulusModel.libraryId);
              if (!modelExist) {
                error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" file {stimulusModel.fileName} not exist.</p>)
              }
            }
          }
        }

        if (inputStimulus === MultiPinSPICE) {
          if (!pinIn || !pinIn.node) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" stimulus model has set MultiPin SPICE, driver model must be set to MultiPin SPICE and set in node.</p>)
          }
          if (!vector.stimulus || !vector.stimulus.node) {
            error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" does not set the simulation stimulus model.</p>)
          } else {
            const stimulusModel = vector.stimulus;
            if (stimulusModel) {
              let modelExist = sierraLibrary.checkFile(SPICE, stimulusModel.libraryId);
              if (!modelExist) {
                const text = stimulusModel.folder ? `${stimulusModel.folder}::${stimulusModel.fileName}` : stimulusModel.fileName;
                error.push(<p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{name}/{pin.pin}</span>" file {text} not exist.</p>)
              }
            }
          }
        }
      }
    }
  });
  return error
}

function checkPassive(passive = [], pcb) {
  let error = []
  passive.forEach(rlc => {
    const name = pcb ? `${pcb}::${rlc.name}` : rlc.name;
    if (rlc.model && (rlc.model.type === 'SPICE' || rlc.model.type === 'Touchstone')) {
      if (!rlc.model.libraryId || !rlc.model.fileName) {
        error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold"></span>" dose not set {rlc.model.type} file.</p>)
      } else {
        let file = rlc.model.type === 'SPICE' ? sierraLibrary.checkFile(PASSIVE_SPICE, rlc.model.libraryId) : sierraLibrary.checkFile(PASSIVE_TOUCHSTONE, rlc.model.libraryId);
        if (!file) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{name}</span>" {rlc.model.type} file is not exist.</p>)
        }
      }

      if (rlc.model && rlc.model.type === 'SPICE' && !rlc.model.subckt) {
        error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{name}</span>" dose not set SPICE subckt.</p>)
      }

      let errorMsg;
      let errorTypeNP = rlc.model.type === 'SPICE' ? 'node' : 'port';
      rlc.model.pairs.forEach(pair => {
        if (pair.pin && !pair.node) {
          errorMsg = <p style={{ margin: 0 }}>RLC "<span className="font-bold">{name}</span>" passive model pin({pair.pin}) is not connected to {errorTypeNP}.</p>
        } else if (!pair.pin && pair.node) {
          errorMsg = <p style={{ margin: 0 }}>RLC "<span className="font-bold">{name}</span>" passive model {errorTypeNP}({pair.node}) is not connected to pin.</p>
        }
      })
      if (errorMsg) {
        error.push(errorMsg);
      }
    }
  })
  return error
}

function connComponentsErrorCheck(interfaces) {
  let error = null;
  let connComps = {};
  for (let info of interfaces) {
    if (!info.content || !info.content.components) {
      continue
    }
    const conns = info.content.components.filter(item => item.type === CONNECTOR);
    for (let conn of conns) {
      if ((!conn.connect || !conn.connect.componentName || !conn.connect.pcbId)) {
        if (!connComps[info.pcb]) {
          connComps[info.pcb] = [conn.name];
        } else {
          connComps[info.pcb].push(conn.name)
        }
      } else if (conn.connect.pcbId) {
        const findConn = interfaces.find(item => item.pcbId === conn.connect.pcbId && item.pcbId !== info.pcbId)
        const connComponent = findConn && findConn.content && findConn.content.components ? findConn.content.components.find(item => item.name === conn.connect.componentName && item.type === CONNECTOR) : null
        if (!findConn || !connComponent) {
          if (!connComps[info.pcb]) {
            connComps[info.pcb] = [conn.name];
          } else {
            connComps[info.pcb].push(conn.name)
          }
          continue;
        }
      }
    }
  }
  if (Object.keys(connComps).length) {
    error = <div>
      The following Connector components are not connected to any PCB connector(s). Do you want to change their usage to "Unused"?
      <div style={{ fontWeight: 600, marginLeft: 6 }}>
        {Object.keys(connComps).map((item, index) => <div key={index}>
          <div>{item}:</div>
          <div style={{ marginLeft: 8 }}>{connComps[item].join(", ")}</div>
        </div>
        )}</div>
    </div>;
  }

  if (error) {
    return error
  }
  return null;

}

function getExtractionPortErrorCheck(ports_generate_setup_list, comp, channel, pcb) {
  let error = [];
  const findCompSetup = ports_generate_setup_list.find(it => it.component === comp.name) || {};
  const findPortSetup = findCompSetup.setup || {};
  if (findPortSetup && findPortSetup.portType === "gap" && !comp.gap_size) {
    error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{pcb}::{comp.name}</span>" extraction port reference plane distance is not set.</p>)
  }

  if (channel && channel.type === "HFSS" && findPortSetup && findPortSetup.portType === "gap" && comp.gap_size && parseFloat(comp.gap_size) <= 0) {
    error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{pcb}::{comp.name}</span>" when the extraction type is "HFSS", the extraction port reference plane distance should be greater than 0.</p>)
  }

  if (findPortSetup && findPortSetup.portType === "wave" && (!comp.ball_size || !comp.ball_height)) {
    error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{pcb}::{comp.name}</span>" extraction port (ball size / ball height) is not set.</p>)
  }
  return error;
}

function getRepeaterErrorCheck({ components, pcb }) {
  const repeaters = components.filter(item => item.type === COMP_REPEATER);
  let error = [];
  if (repeaters.length > 0) {
    repeaters.forEach(item => {
      if (!item.model) {
        error.push(<p style={{ margin: 0 }}>{item.type} "<span className="font-bold">{pcb}::{item.name}</span>" does not set repeater model.</p>)
      } else {
        const model = item.model;
        if (model.name && model.id) {
          let modelExist = sierraLibrary.checkFile(REPEATER, model.id);
          if (!modelExist) {
            error.push(<p style={{ margin: 0 }}>{item.type} "<span className="font-bold">{pcb}::{item.name}</span>" file {model.name} not exist.</p>)
          } else {
            if (!model.subcktName) {
              error.push(<p style={{ margin: 0 }}>{item.type} "<span className="font-bold">{pcb}::{item.name}</span>" does not set repeater model.</p>)
            }
            item.pins.forEach(pin => {
              if (!pin.node) {
                error.push(<p style={{ margin: 0 }}>{item.type} "<span className="font-bold">{pcb}::{item.name}/{pin.pin}</span>" does not set repeater model.</p>)
              }
            })
          }
        } else if (model.files && model.files.length > 0 && model.pairs && model.pairs.length > 0) {
          model.pairs.forEach(p => {
            if (!p.node) {
              error.push(<p style={{ margin: 0 }}>{item.type} "<span className="font-bold">{pcb}::{item.name}/{p.pin}</span>" does not set repeater model.</p>)
            }
          })
        } else {
          error.push(<p style={{ margin: 0 }}>{item.type} "<span className="font-bold">{pcb}::{item.name}</span>" does not set repeater model.</p>)
        }
      }
    })
  }
  return error
}

function getRLCValueCheck({ components, powerNets, errorPowerGndNets, pcb }) {
  // RES/IND/CAP/JUMPER components value
  let error = [];
  const RLCs = components.filter(item => RLCType.includes(item.type));
  if (RLCs.length > 0) {
    RLCs.forEach(rlc => {
      if (rlc.pins && rlc.pins.length > 0) {
        //power nets by connect signal voltage error check
        const signalPins = rlc.pins.filter(item => !item.signal);
        const signalPowerNets = signalPins.map(item => item.net);
        signalPowerNets.forEach(net => {
          const findPowerNet = powerNets.find(item => item.name === net);
          if (findPowerNet && !findPowerNet.value && !errorPowerGndNets.includes(net)) {
            errorPowerGndNets.push(net);
            error.push(<p style={{ margin: 0 }}>Power / Ground Nets "<span className="font-bold">{pcb}</span>" does not set {net} voltage.</p>)
          }
        })
      }
      if ((rlc.model && rlc.model.type === 'value') || !rlc.model) {
        if (!rlc.value) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" missing value.</p>)
        } else if (rlc.type === CAP) {
          let rValue = rlc.value.r;
          //Check the Resistance value format
          let rCheck = checkRLCValueFormat(rValue)

          if (!rCheck) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Resistance value format error.</p>);
          }

          //Check the Inductance value format
          let lValue = rlc.value.l;
          let lCheck = checkRLCValueFormat(lValue)

          if (!lCheck) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Inductance value format error.</p>);
          }

          //Check the Capacitance value format
          let cValue = rlc.value.c;
          let cCheck = checkRLCValueFormat(cValue, CAP);

          if (!cCheck) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Capacitance value format error.</p>);
          } else if (cCheck && cCheck === "0") {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Capacitance value cannot be 0.</p>);
          }
        } else {
          const rlcValue = checkRLCValueFormat(rlc.value);
          if (!rlcValue) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" value format error.</p>)
          }
        }
      }
    })
  }
  return error;
}

function getPowerCompsCheck({ powerComponents, channel, pcb }) {
  let error = []
  const powerComps = powerComponents.filter(item => item.type === CAP);
  if (powerComps.length > 0 && channel && channel.type !== 'Default') {
    powerComps.forEach(rlc => {
      if (!rlc.value) {
        error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" missing value.</p>)
      } else {
        let rValue = rlc.value.r;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue);

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Resistance value format error.</p>);
        }

        //Check the Inductance value format
        let lValue = rlc.value.l;
        let lCheck = checkRLCValueFormat(lValue);

        if (!lCheck) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Inductance value format error.</p>);
        }

        //Check the Capacitance value format
        let cValue = rlc.value.c;
        let cCheck = checkRLCValueFormat(cValue, CAP);

        if (!cCheck) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Capacitance value format error.</p>);
        } else if (cCheck && cCheck === "0") {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{pcb}::{rlc.name}</span>" Capacitance value cannot be 0.</p>);
        }
      }

    })
  }
  return error;
}

function getConnCheck(components, pcb) {
  let error = [];
  const conns = components.filter(item => item.type === CONNECTOR);
  conns.forEach(conn => {
    if ((!conn.connect || !conn.connect.componentName)) {
      error.push(<p style={{ margin: 0 }}>Connector "<span className="font-bold">{pcb}::{conn.name}</span>" is not connected to other PCB.</p>)
    }

    const hasModel = conn.models && conn.models.filter(model => !!model.libraryId).length ? true : false;
    const hasCable = conn.cableModels && conn.cableModels.filter(model => !!model.libraryId).length ? true : false;

    if (hasModel) {
      const fileType = conn.models[0].type === SPICE ? CONNECTOR_SPICE_SIERRA : CONNECTOR_TOUCHSTONE;
      let modelExist = sierraLibrary.checkFile(fileType, conn.models[0].libraryId);
      if (!modelExist) {
        error.push(<p style={{ margin: 0 }}>Connector "<span className="font-bold">{pcb}::{conn.name}</span>" file {conn.models[0].file} not exist.</p>);
      }
    }

    if (hasCable) {
      const fileType = conn.cableModels[0].type === SPICE ? CONNECTOR_SPICE_SIERRA : CONNECTOR_TOUCHSTONE;
      let modelExist = sierraLibrary.checkFile(fileType, conn.cableModels[0].libraryId);
      if (!modelExist) {
        error.push(<p style={{ margin: 0 }}>Connector "<span className="font-bold">{pcb}::{conn.name}</span>" cable file {conn.cableModels[0].file} not exist.</p>);
      }
    }

    conn.pins.forEach(pin => {
      if ((hasModel && (!pin.port || !pin.external_map || !pin.external_map.port)) || (hasCable && (!pin.external_map || !pin.external_map.cablePort))) {
        error.push(<p style={{ margin: 0 }}>Connector "<span className="font-bold">{pcb}::{conn.name}::{pin.pin}</span>" has port not set.</p>);
      }
    })
  })
  return error;
}

function preLayoutInterfaceErrorCheck({ content, signals, components }) {
  let prelayoutSignals = [], prelayoutComps = [], error = [];
  const prelayoutInfo = preLayoutData.getLocalPrelayout(content.pcbId);
  const signalNets = (signals || []).map(item => item.nets || []).flat(2);
  if (prelayoutInfo && prelayoutInfo.content && prelayoutInfo.content.prelayout === MODEL) {
    prelayoutSignals = [...(prelayoutInfo.content.signals || [])];
    prelayoutInfo.content.components.forEach(component => {
      prelayoutComps.push(component);
      component.pins.forEach(pin => {
        if (!pin.model || !pin.model.port) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{content.pcb}</span>" - <span className="font-bold">{component.name}::{pin.pin}</span> is not assigned the model node.</p>)
        }
      })
    })
  }

  if (prelayoutInfo && prelayoutInfo.content && prelayoutInfo.content.prelayout === SCHEMATIC) {

    if (!prelayoutInfo.content.signals || !prelayoutInfo.content.signals.length) {
      error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{content.pcb}</span>" - No signals are set.</p>)
    }

    if (!prelayoutInfo.content.components || !prelayoutInfo.content.components.length) {
      error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{content.pcb}</span>" - No components are set.</p>)
    }

    const compsNames = (prelayoutInfo.content.components || []).map(item => item.name);
    let comps = new Map();
    for (let comp of compsNames) {
      if (!comps.get(comp)) {
        comps.set(comp, [comp])
      } else {
        comps.set(comp, [...comps.get(comp), comp])
      }
    }
    const repeatedComps = [...new Set(compsNames.filter(item => (comps.get(item) || []).length > 1))];
    if (repeatedComps.length) {
      error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{content.pcb}</span>" - Component name [{repeatedComps.join(", ")}] {repeatedComps.length > 1 ? "are" : "is"} repeated.</p>)
    }

    prelayoutSignals.push(...(prelayoutInfo.content.signals || []).map(item => item.nets || []).flat(2))
    prelayoutComps = prelayoutInfo.content.components || [];

    const sections = getPreLayoutSignalsSections(prelayoutInfo.content.signals || [], true);
    const signalNames = (prelayoutInfo.content.signals || []).map(item => item.name);
    const signalNameText = signalNames.join(", ");
    for (let secItem of sections) {
      if (!secItem.template || !secItem.template.id) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}</span>" - <span className="font-bold">{signalNameText}::{secItem.title}</span> is not assigned the trace template.</p>)
      } else {
        let templateExist = sierraLibrary.checkFile(TRACE, secItem.template.id);
        if (!templateExist) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}</span>" - <span className="font-bold">{signalNameText}::{secItem.title}</span> trace template {secItem.template && secItem.template.name ? `[${secItem.template.name}]` : ""} does not exist in the library.</p>);
        }
      }

      if (!secItem.traceLength) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}</span>" - <span className="font-bold">{signalNameText}::{secItem.title}</span> is not assigned the trace length.</p>)
      } else {
        const { value, unit } = splitValueUnitByUnits(secItem.traceLength || "", TRACE_LENGTH_UNITS);
        const lengthError = checkTraceLength(value, unit);
        lengthError && error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}</span>" - <span className="font-bold">{signalNameText}::{secItem.title}</span> trace length {lengthError}.</p>)
      }
    }
  }

  const findNotExistNets = [...new Set(signalNets.filter(item => !prelayoutSignals.includes(item)))];
  if (findNotExistNets.length) {
    error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}</span>" - <span className="font-bold">Signal Nets: [{findNotExistNets.join(", ")}]</span> {findNotExistNets.length > 1 ? "are" : "is"} not defined in the pre-layout.</p>)
  }
  const findNotExistComps = [];
  for (let comp of components) {
    const findComp = prelayoutComps.find(item => item.name === comp.name);
    if (!findComp) {
      findNotExistComps.push(comp.name);
      continue;
    }
    const pins = (findComp.pins || []).map(item => item.pin);
    const notExistPins = comp.pins.filter(item => !pins.includes(item.pin));
    if (notExistPins.length) {
      error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}::{comp.name}</span>" - <span className="font-bold">Pins: [{notExistPins.join(", ")}]</span> {notExistPins.length > 1 ? "are" : "is"} not defined in the pre-layout.</p>)
    }
  }
  if (findNotExistComps.length) {
    error.push(<p style={{ margin: 0 }}>"<span className="font-bold">PreLayout:{content.pcb}</span>" - <span className="font-bold">Components: [{findNotExistComps.join(", ")}]</span> {findNotExistComps.length > 1 ? "are" : "is"} not defined in the pre-layout.</p>)
  }

  return error;
}

export {
  getRepeaterErrorCheck,
  getExtractionPortErrorCheck,
  connComponentsErrorCheck,
  getRLCValueCheck,
  checkPassive,
  getPowerCompsCheck,
  getConnCheck,
  preLayoutInterfaceErrorCheck
}