import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import DelayResult from './verification/DelayResult';
import ImpedanceResult from './verification/ImpedanceResult';
import ImpedanceSweep from './sweep/ImpedanceSweep';
import DelaySweep from './sweep/DelaySweep';
import Waveform from './verification/WaveForm';
import WaveformSweep from './sweep/WaveForm';
import { SettingOutlined } from '@ant-design/icons';
import { Select, message } from 'antd';
import { changeResultKey, saveCurrentSimulationConfig } from '../store/result/action';
import { updateExperimentInfo } from '../store/sweep/action';
import { EXPERIMENTS_RESULT } from '@/constants/treeConstants';
import Channel from './verification/Sparameter';
import SweepChannel from './sweep/Sparameter';
import EyeDiagrams from './eyeDiagrams';
import { ResultData, SweepResultData } from '@/services/Sierra';
import { EXPERIMENTS, RESULT, VERIFICATION } from '../../../constants/treeConstants';
import { updateMultiInterfaceSetupStatus } from '../store/multiInterface/action';
import TopMenu from './topMenu';
import { openNewPage } from '../store/project/action';
import TotalTraceCapacitance from './verification/totalTraceCapacitance';
import '@/publicCss/waveform.css';
import './index.css';

const Option = Select.Option;
class Result extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      hasResult: true,
      experimentResult: [],
      hasChannel: false,
      hasMeasurement: false,
      rightVisible: {}
    }

    this.resultRef = createRef();
  }

  componentDidMount() {
    const { viewList, verificationSubId, sweepId } = this.props;

    if (viewList.includes(EXPERIMENTS_RESULT)) {
      if (sweepId) {
        this.getSweepInfo();
      }
    } else {
      if (verificationSubId) {
        this.getInfo();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { viewList, verificationSubId, sweepId, verificationId } = this.props;

    const view = viewList.find(item => [EXPERIMENTS_RESULT, RESULT].includes(item));
    const prevView = prevProps.viewList ? prevProps.viewList.find(item => [EXPERIMENTS_RESULT, RESULT].includes(item)) : null;
    let updateInfo = false;
    if (view !== prevView) {
      updateInfo = true;
    }
    switch (view) {
      case EXPERIMENTS_RESULT:
        if (sweepId !== prevProps.sweepId || updateInfo) {
          this.getSweepInfo();
        }
        break;
      case RESULT:
        if (prevProps.verificationSubId !== verificationSubId
          || prevProps.verificationId !== verificationId
          || updateInfo) {
          this.getInfo();
        }
        break;
      default: break;
    }
  }

  getInfo = () => {
    const { verificationSubId, currentProjectId, verificationId, currentResultKey } = this.props;
    // get current result verification.json
    ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId).then(res => {
      if (res && res.Config) {
        this.props._saveCurrentSimulationConfig(res.Config);
      }
      let hasChannel = false;
      ////add network s-parameter (channel) menu
      if (res && res.Interfaces
        && res.Interfaces.find(item => item.content && item.content.channel && ["SIwave", "HFSS", "PowerSI"].includes(item.content.channel.type))) {
        hasChannel = true;
      }

      if (!hasChannel && ["capacitance", "channel"].includes(currentResultKey)) {
        this.changeResultType({ key: 'impedance' });
      }

      this.setState({
        hasResult: res ? true : false,
        hasChannel,
        hasMeasurement: true
      });
    }, error => {
      this.setState({
        hasResult: false,
        hasChannel: false
      });
      if (["capacitance", "channel"].includes(currentResultKey)) {
        this.changeResultType({ key: 'impedance' });
      }
    })
  }

  getSweepInfo = async () => {
    const { currentProjectId, experimentsList, currentResultKey } = this.props;
    let experimentResult = [];
    if (["eyeDiagrams", "capacitance"].includes(currentResultKey)) {
      this.changeResultType({ key: 'impedance' });
    }
    let hasChannel = false, isUpdateMenu = false, designs = [];
    for (let item of experimentsList) {
      if (item.show) {
        const res = await SweepResultData.getVerificationJsonPromise(item.id, currentProjectId)
        if (res && res.length) {
          experimentResult.push({ id: item.id, name: item.name, channelList: item.channelList, show: item.show, verificationId: item.verificationId });
          //if support eye diagram, need this config
          /*  if (res.Config && !saveConfig.length) {
            this.props._saveCurrentSimulationConfig(res.Config);
            saveConfig.push(res.Config);
          } */
          //add network s-parameter (channel) menu
          if (!isUpdateMenu
            && res.find(it => it.content && it.content.channel && ["SIwave", "HFSS", "PowerSI"].includes(it.content.channel.type))) {
            hasChannel = true;
            isUpdateMenu = true;
          }

          const _designs = res ? res.map(item => item.PCBID) : [];
          designs.push(..._designs);
        } else {
          message.error(`The result of ${item.name} does not exist`);
        }
      }
    }
    this.setState({
      hasChannel,
      experimentResult,
      hasMeasurement: true
    })
  }

  getResultContent = () => {
    const { currentResultKey, siderLeftWidth, layoutLeftWidth } = this.props;
    const { hasResult, rightVisible } = this.state;
    if (!hasResult) {
      return null;
    }
    switch (currentResultKey) {
      case 'impedance':
        return <ImpedanceResult />
      case 'delay':
        return <DelayResult />
      case 'waveform':
        return <Waveform measurePanelVisible={rightVisible["measurement"]} changeMeasureVisible={this.changeMeasureVisible} />
      case 'channel':
        return <Channel />
      case 'eyeDiagrams':
        return <EyeDiagrams siderLeftWidth={siderLeftWidth} layoutLeftWidth={layoutLeftWidth} />;
      case 'capacitance':
        return <TotalTraceCapacitance />
      default:
        return null;
    }
  }

  changeMeasureVisible = (_bool) => {
    this.setState({
      rightVisible: { ...this.state.rightVisible, measurement: _bool }
    })
  }

  getSweepResultContent = () => {
    const { currentResultKey, siderLeftWidth, layoutLeftWidth } = this.props;
    const { experimentResult, rightVisible } = this.state;
    if (!experimentResult.length) {
      return null;
    }
    switch (currentResultKey) {
      case 'impedance':
        return <ImpedanceSweep resultList={experimentResult} />
      case 'delay':
        return <DelaySweep resultList={experimentResult} />
      case 'waveform':
        return <WaveformSweep resultList={experimentResult} measurePanelVisible={rightVisible["measurement"]} changeMeasureVisible={this.changeMeasureVisible} />
      case 'channel':
        return <SweepChannel experimentList={experimentResult} />
      case 'eyeDiagrams':
        return <EyeDiagrams siderLeftWidth={siderLeftWidth} layoutLeftWidth={layoutLeftWidth} />
      case 'capacitance':
      default:
        return null;
    }
  }

  changeResultType = ({ key }) => {
    const { viewList } = this.props;
    if (key === "setup") {
      const { multiSetupResult, verificationId, sweepId } = this.props;
      if (multiSetupResult) {
        this.props._updateMultiInterfaceSetupStatus(multiSetupResult)
      } else {
        let pageType = VERIFICATION, id = verificationId;
        if (viewList.includes(EXPERIMENTS_RESULT)) {
          pageType = EXPERIMENTS;
          id = sweepId;
        }
        this.props._openNewPage({ pageType, id })
      }
      return;
    }
    this.props.changeResultKey(key);

    if (key === 'channel' && viewList.includes(EXPERIMENTS_RESULT)) {
      const { experimentsList } = this.props;
      const { experimentResult } = this.state;
      let newExperimentsList = experimentsList.map(item => {
        let show = item.show
        if (show && (!item.channelList || !item.channelList.length)) {
          show = false;
        }
        return { ...item, show }
      })
      const _experimentResult = experimentResult.filter(item => item.channelList && item.channelList.length);
      this.setState({
        experimentResult: _experimentResult
      })
      this.props.updateExperimentInfo(newExperimentsList, 'resultList');
    }
  }

  changeExperimentKey = (value) => {
    const { experimentsList } = this.props;
    const newExperimentsList = experimentsList.map(item => (
      { ...item, show: value.includes(item.id) ? true : false }
    ))
    this.props.updateExperimentInfo(newExperimentsList, 'resultList');
    setTimeout(() => {
      this.getSweepInfo()
    }, 100)
  }

  caclMaxTag = (value) => {
    let maxWidth = 240, count = 0; // Select box length - Tag Placeholder length
    const tagLength = 36; // Length of tag except characters
    for (let key of value) {
      let length = key.length < 12 ? key.length * 8 : 12 * 8; // 7 = one character length
      maxWidth = maxWidth - length - tagLength;
      if (maxWidth < 0) {
        break;
      } else {
        count = count + 1;
      }
    }
    return count
  }

  getExperimentShowList = () => {
    const { experimentsList, currentResultKey } = this.props;
    const show = experimentsList.filter(item => item.show);
    const value = show.map(item => item.id);
    const maxTagCount = this.caclMaxTag(show.map(item => item.name));
    return <div className="sierra-result-experiment-display">
      <Select
        className="aurora-select sierra-result-experiment-select"
        size='small'
        mode='multiple'
        value={value}
        maxTagCount={maxTagCount}
        maxTagTextLength={12}

        maxTagPlaceholder={`+${value.length - maxTagCount}`}
        onChange={this.changeExperimentKey}
      >
        {experimentsList.map(item => (
          <Option
            key={item.id}
            disabled={!item.exist || (currentResultKey === 'channel' && (!item.channelList || !item.channelList.length))}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  }

  getRightMenu = () => {
    const { currentResultKey } = this.props;
    const { hasMeasurement } = this.state;

    let menu = [];
    if (currentResultKey === "waveform" && hasMeasurement) {
      menu.push({
        key: "measurement",
        name: "Measurement",
        icon: SettingOutlined
      })
    }
    return menu;
  }

  changeRightMenuKey = (key) => {
    this.setState({
      rightVisible: { ...this.state.rightVisible, [key]: true }
    })
  }

  render() {
    const { currentResultKey, viewList, siderLeftWidth, layoutLeftWidth, currentProgress, recalc, sweepReCalc, sweepMeasureProgress } = this.props;
    const { hasChannel, rightVisible } = this.state;
    const isSweep = viewList.includes(EXPERIMENTS_RESULT);
    const rightMenuList = this.getRightMenu()
    return (
      <div className='sierra-result-main' ref={this.resultRef}>
        <TopMenu
          selectedKey={currentResultKey}
          leftWidth={siderLeftWidth}
          layoutLeftWidth={layoutLeftWidth}
          hasChannel={hasChannel}
          viewList={viewList}
          isSweep={isSweep}
          changeResultType={(key) => this.changeResultType({ key })}
          getExperimentShowList={isSweep ? this.getExperimentShowList : null}
          rightMenuList={rightMenuList}
          changeRightMenuKey={this.changeRightMenuKey}
          rightVisible={rightVisible}
          recalc={recalc}
          sweepReCalc={sweepReCalc}
          progress={currentProgress}
          sweepMeasureProgress={sweepMeasureProgress}
        />
        <div className='sierra-result-content'>
          {isSweep ? this.getSweepResultContent() : this.getResultContent()}
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  const { resultReducer,
    project: { currentProjectId },
    sweep: { experimentInfo },
    multiInterfaceSetup: { multiSetupResult },
    simulationReducer: { singleProgress },
    resultReducer: { recalc, sweepReCalc, sweepMeasureProgress }
  } = state.SierraReducer;
  const { currentResultKey, channelList, resultInfo } = resultReducer;
  const { verificationSubId, verificationId } = resultInfo;
  const { id = "", resultList = [] } = experimentInfo;
  const currentProgress = singleProgress.find(item => item.verificationId === verificationId)
  return {
    currentResultKey,
    channelList,
    verificationId,
    currentProjectId,
    verificationSubId,
    sweepId: id,
    experimentsList: resultList,
    multiSetupResult,
    recalc,
    currentProgress: currentProgress ? currentProgress.progress : false,
    sweepReCalc,
    sweepMeasureProgress
  }
}

const mapDispatch = (dispatch) => ({
  changeResultKey(key) {
    dispatch(changeResultKey(key))
  },
  _saveCurrentSimulationConfig(config) {
    dispatch(saveCurrentSimulationConfig(config))
  },
  updateExperimentInfo(info, currentType) {
    dispatch(updateExperimentInfo(info, currentType))
  },
  _updateMultiInterfaceSetupStatus(group) {
    dispatch(updateMultiInterfaceSetupStatus(group))
  },
  _openNewPage({ pageType, id }) {
    dispatch(openNewPage({ pageType, id }))
  }
})

export default connect(mapState, mapDispatch)(Result);