import React, { PureComponent } from 'react';
import { getSignalDelay } from '@/services/Sierra/results';
import { connect } from 'react-redux';
import makeCancelable from '@/services/api/makeCancelable';
import DelayComp, { sortDelayData, delayColumn } from '../components/Delay';
import { SweepResultData } from '@/services/Sierra';

class DelayResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      resultList: [],
    }
  }

  componentDidMount = () => {
    const { sweepId } = this.props;
    if (sweepId) {
      this.getData()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { sweepId, resultList } = this.props;
    if (prevProps.sweepId !== sweepId) {
      this.getData()
    }

    if (resultList.length !== prevProps.resultList.length) {
      this.getData(true)
    }
  }

  getData = (keep = false) => {
    const newResultList = keep ? this.keepResult() : this.props.resultList;
    this.setState({
      resultList: JSON.parse(JSON.stringify(newResultList))
    }, () => {
      this.getDelayData();
    })
  }

  keepResult = () => {
    let newResultList = JSON.parse(JSON.stringify(this.props.resultList));
    const { resultList } = this.state;
    return newResultList.map(item => {
      const current = resultList.find(r => r.id === item.id);
      return current ? current : item
    })
  }

  getDelayData = async () => {
    const { currentProjectId } = this.props;
    const { resultList } = this.state;
    const newResultList = [];
    for (let exp of resultList) {
      if (exp.delayData && exp.delayData.length) {
        newResultList.push({ ...exp });
        continue;
      }
      try {
        const res = await SweepResultData.getVerificationJsonPromise(exp.id, currentProjectId);
        let designs = [];
        if (res) {
          let currentInterfaces = SweepResultData.getInterfaces(exp.id);
          currentInterfaces.forEach(item => {
            designs.push({
              id: item.pcbId,
              name: item.pcb
            });
          })
          const promise = getSignalDelay({ experimentId: exp.id, designs, projectId: currentProjectId })
          this.cancelable = makeCancelable(promise);
          const response = await this.cancelable.promise;
          const delay = response;
          newResultList.push({ ...exp, delayData: sortDelayData(delay, currentInterfaces) });
        }
      } catch (error) {
        newResultList.push({ ...exp, delayData: [] });
        console.error(error);
      }
    }
    this.setState({
      resultList: newResultList
    }, () => {
      this.DelayDataHandle(newResultList)
    })
  }

  DelayDataHandle = (resultList) => {
    let _delayData = [], childrenColumns = [], column = [];
    if (resultList.length < 2) {
      _delayData = resultList[0] ? resultList[0].delayData : [];
    } else {
      for (let i = 0; i < resultList.length; i++) {
        const delayData = resultList[i].delayData;
        if (!_delayData.length && delayData) {
          delayData.forEach(item => {
            const { netName, driver, receiver, delay } = item;
            _delayData.push({ netName, driver, receiver, [`delay_${i}`]: delay })
          })
        } else if (delayData) {
          delayData.forEach((item, index) => {
            const { delay } = item;
            _delayData[index][`delay_${i}`] = delay;
          })
        }
        childrenColumns.push({
          title: resultList[i].name,
          dataIndex: `delay_${i}`,
          key: `delay_${i}`,
          render: (text) => {
            return <span>{text}</span>
          },
          onCell: (record) => {
            return {
              className: 'sweep-delay-table-td'
            }
          }
        })
      }
      column = JSON.parse(JSON.stringify(delayColumn));
      column[0].children = childrenColumns;
    }
    this.setState({
      delayData: _delayData,
      column
    })
  }

  render() {
    const { delayData, column } = this.state;
    return DelayComp({ delayData, key: 'Delay', column });
  }
}

const mapState = (state) => {
  const { project, sweep: { experimentInfo } } = state.SierraReducer;
  const { currentProjectId } = project;
  const { id = "" } = experimentInfo;
  return {
    currentProjectId,
    sweepId: id
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(DelayResult);