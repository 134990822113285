import { combineReducers } from 'redux';
import reducer from '../../Admin/reducer';
import designReducer from '../../Design/components/reducer';
import folderCreateFormReducer from '../../Design/components/FolderCreate/FolderForm/reducer';
import UploadReducer from '../../Design/components/Upload/reducer';
import LoginReducer from '../../Login/reducer';
import DownloadReducer from './../../DownloadAurora/reducer';
import PDNReducer from '../../PDN/store/rootReducer';
import { PanelStatus } from '../../panelStore/reducer';
import SierraReducer from '../../Sierra/store/rootReducer';
import { CCCReducer } from '../../CCC/store';
import AndesReducer from '../../Andes/store/rootReducer';
import { TabMonitorReducer } from '../../tabMonitor';
import RockyReducer from '../../Rocky/store/rootReducer';
import { MonitorInfoReducer } from '../../MonitorStore';
import LicenseReducer from '../../License/store/reducer';
import AndesV2Reducer from '../../Andes_v2/store/rootReducer';
import CascadeReducer from '../../Cascade/store/rootReducer';
import HimalayasReducer from '../../Himalayas/store/rootReducer'
import { default as StackupReducer } from '../../LayoutExplorer/components/Stackup_v1/store/reducer';
import { LayoutReducer } from '../../LayoutExplorer/LayoutStore';
import BMAReducer from '../../BMA/store/rootReducer'

const rootReducer = combineReducers({
  reducer,
  LoginReducer,
  DownloadReducer,
  /* ----- DESIGN REDUCER BEGIN ----- */
  designReducer,
  folderCreateFormReducer,
  UploadReducer,
  StackupReducer,
  LayoutReducer,
  /* ----- PDN REDUCER ----- */
  PDNReducer,
  /* ----- Panel REDUCER ----- */
  PanelStatus,
  /* ----- Sierra REDUCER ----- */
  SierraReducer,
  /* ----- CCC REDUCER ----- */
  CCCReducer,
  /* ----- Andes REDUCER ----- */
  AndesReducer,
  /* ----- Tab Monitor REDUCER ----- */
  TabMonitorReducer,
  /* ----- Rocky REDUCER ----- */
  RockyReducer,
  /* ----- Rocky BMA REDUCER ----- */
  BMAReducer,
  /* Monitor Info REDUCER */
  MonitorInfoReducer,
  LicenseReducer,
  /* Andes V2 REDUCER */
  AndesV2Reducer,
  /* Cascade REDUCER */
  CascadeReducer,
  /* Himalayas REDUCER */
  HimalayasReducer
})

export default rootReducer;
