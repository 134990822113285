import { strDelimited } from "./split";

function strFormatChange(str) {
  let netArr = [];
  strDelimited(str).forEach(item => {
    if (/[^a-zA-Z0-9]/ig.test(item)) {
      netArr.push(`\\${item}`)
    } else {
      netArr.push(item);
    }
  });

  return netArr.join("");
}

/**
 *  string replace all
 * @param {string} str Raw string
 * @param {string} replacedChar Characters to be replaced or replaced reg
 * @param {string} replaceChar INew character to replace
 * @param {string} type replacedChar type "char" / "reg",default is "reg"
 */

function strReplaceAll(str, replacedChar, replaceChar, type = "reg") {

  const replacedReg = type === "reg" ? replacedChar : new RegExp(replacedChar, "gm");
  const targetStr = str.replace(replacedReg, replaceChar);

  if (typeof (targetStr) === "string") {
    return targetStr;
  } else {
    return str;
  }
  //str.split(replacedChar).join(replaceChar)
}

export {
  strFormatChange,
  strReplaceAll
}