import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TopMenu from '../TopMenu';
import { changeResultType } from '../../store/result/action';
import ResultContent from '../resultContent';
import { getAndesChannelFinishedTime, getVerificationJson } from '../../../../services/Andes_v2/results';
import { recalculateTDRSBRWorkflow } from '../../store/simulation/action';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import { PCB_CHANNEL } from '../../../../constants/treeConstants';
import './result.css';

class ChannelResult extends PureComponent {

  constructor() {
    super()
    this.state = {
      currentResultTime: null,
      seaSimHistory: false,
      adsHistory: false
    }
  }

  componentDidMount() {
    if (this.props.channelId) {
      this.getChannelResultInfo()
    }
  }

  componentDidUpdate(prevProps) {
    const { channelId, historyList } = this.props;

    if (prevProps.channelId && channelId !== prevProps.channelId) {
      this.getChannelResultInfo()
    }

    if (prevProps.historyList && historyList.length !== prevProps.historyList.length) {
      const seaSimHistory = historyList.find(item => !!item.complianceResult);
      const adsHistory = historyList.find(item => !!item.adsResult);
      this.setState({
        seaSimHistory: seaSimHistory ? true : false,
        adsHistory: adsHistory ? true : false
      })
    }
  }

  async getChannelResultInfo() {
    const { channelId, resultKey } = this.props;

    this.setupInfo = await getVerificationJson(channelId);
    const adsSetupInfo = await getVerificationJson(channelId, false, "ads");
    const complianceInfo = await getVerificationJson(channelId, false, "compliance");
    let dataRate;

    if (this.setupInfo) {
      this.interfaceType = this.setupInfo.type;
      if (complianceInfo && complianceInfo.config && complianceInfo.config.analysis) {
        this.runSeasim = complianceInfo.config.analysis.run_statsim;
      };
      if (adsSetupInfo && adsSetupInfo.adsConfig && Object.keys(adsSetupInfo.adsConfig).length) {
        this.runAds = true;
      };
      if (adsSetupInfo && adsSetupInfo.hspiceConfig && Object.keys(adsSetupInfo.hspiceConfig).length) {
        this.runHspice = true;
      };
      if (this.setupInfo.config && this.setupInfo.config.analysis && this.setupInfo.config.analysis.options) {
        dataRate = this.setupInfo.config.analysis.options.datarate;
      };
    } else {
      this.setupInfo = null;
      this.runSeasim = false;
      this.runAds = false;
      this.runHspice = false;
    }

    if ((!this.runSeasim && resultKey === "compliance")
      || (!this.runAds && resultKey === "ads")) {
      this.props.changeResultType("channel");
    }

    const time = await getAndesChannelFinishedTime(channelId);
    this.setState({
      currentResultTime: time,
      dataRate
    })

  }

  render() {
    const { resultKey, changeResultType, channelId, historyList, recalculateTDRSBRWorkflow, status, interfaceType, leftWidth, layoutLeftWidth } = this.props;
    const { currentResultTime, seaSimHistory, adsHistory, dataRate } = this.state;
    return (<div className='andes-v2-result-main'>
      <TopMenu
        selectedKey={resultKey}
        leftWidth={leftWidth}
        layoutLeftWidth={layoutLeftWidth}
        changeResultType={(key) => changeResultType(key, channelId, PCB_CHANNEL)}
        currentResultTime={currentResultTime}
        interfaceType={this.interfaceType || interfaceType}
        runSeasim={this.runSeasim || seaSimHistory}
        runAds={this.runAds || adsHistory}
        runHspice={this.runHspice}
      />
      <ResultContent
        resultKey={resultKey}
        id={channelId}
        historyList={historyList}
        interfaceType={interfaceType}
        isEndToEnd={false}
        recalculateTDRSBRWorkflow={recalculateTDRSBRWorkflow}
        status={status}
        dataRate={dataRate}
      />
    </div>)
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { channelResultReducer, project: { treeItems, openProjectId } } } = state;
  const { resultKey, channelId, historyList } = channelResultReducer;
  const status = getStatus(treeItems, openProjectId, channelId);
  const interfaceType = getSerdesType(channelId);

  return {
    resultKey,
    channelId,
    historyList,
    status,
    interfaceType
  }
}

const mapDispatch = (dispatch) => ({
  changeResultType(key, id, setupType) {
    dispatch(changeResultType(key, id, setupType))
  },
  recalculateTDRSBRWorkflow(workflow, isEndToEnd) {
    dispatch(recalculateTDRSBRWorkflow(workflow, isEndToEnd))
  }
})

export default connect(mapState, mapDispatch)(ChannelResult);

function getStatus(treeItems, projectId, channelId) {
  try {
    const item = channelConstructor.getChannel(channelId);
    return item.status;
    /*     const projectItem = treeItems[1].children.find(d => d.id === projectId);
        const designItem = projectItem.children[0].children.find(d => d.id === item.designId);
        return designItem.children.find(d => d.id === channelId).status; */
  } catch (error) {
    return null;
  }
}

function getSerdesType(channelId) {
  try {
    const item = channelConstructor.getChannel(channelId);
    return item.type;
  } catch (error) {
    return null;
  }
}