import {
  getChannelExtractionResult, getTDRSBRSetting, generateTDRSBR, getHistoryResults,
  getEndToEndChannelResult, getEndToEndTDRSBRSetting, generateEndToEndTDRSBR,
  getSweepExtractionResult, getSweepTDRSBRSetting,
  generateSweepTDRSBR, getChannelWaveformResultList, getHistoryChannelWaveformResultList
} from '../../../api/Andes_v2/result';
import WaveformResultHelper from '../../../Andes/results/WaveformResultHelper';
import apiProcess from '../../../api/utility';
import { strDelimited } from '@/services/helper/split';
import { getSignalGroup, getComponents, getPCBIndexMap } from '../sparameter/endToEnd';
import { getHistoryVerificationJson } from '../../results';
import { getMultiPCBHistoryResults } from '../../../api/Andes_v2/multiPCBHistory';
import { valueUnitSplit } from '../../../helper/valueUnitSplit';
import { scaleConversion } from '../../../helper/numberHelper';
import NP from 'number-precision';
import { SERDES_TYPES } from '../../../PCBHelper';
import { SortFn } from '../../../helper/sort';

export async function getWaveformResult({ id, type, isEndToEnd, pair, signal }) {
  let wavefromData = null, name = 'result';
  if (isEndToEnd) {
    wavefromData = (await getEndToEndChannelResult({ endToEndChannelId: id, type, pair, signal })).data;
    name = 'endToEnd';
  } else {
    wavefromData = (await getChannelExtractionResult({ channelId: id, type, pair, signal })).data;
  }
  let waveform = new WaveformResultHelper(name);
  waveform.parseRawCurve(wavefromData);
  return waveform;
}

export async function getExperimentWaveformResult({ experimentId, type, name }) {
  const wavefromData = (await getSweepExtractionResult({ experimentId, type })).data;
  const waveform = new WaveformResultHelper(name);
  waveform.parseRawCurve(wavefromData);
  return waveform;
}

// Waveform
export async function getWaveformResultData({ id, displayMode }) {
  const wavefromData = (await getChannelWaveformResultList({ channelId: id, displayMode })).data;
  const waveform = new WaveformResultHelper();
  waveform.parseRawCurve(wavefromData);
  return waveform;
}

export async function getHistoryWaveformResultData({ historyId, displayMode }) {
  const wavefromData = (await getHistoryChannelWaveformResultList({ historyId, displayMode })).data;
  const waveform = new WaveformResultHelper();
  waveform.parseRawCurve(wavefromData);
  return waveform;
}

export async function getTDRSBRSettingData(id, isEndToEnd, sweepId) {

  if (sweepId) {
    return await apiProcess(getSweepTDRSBRSetting, sweepId);
  }

  if (isEndToEnd) {
    return await apiProcess(getEndToEndTDRSBRSetting, id)
  } else {
    return await apiProcess(getTDRSBRSetting, id);
  }
}

export async function generateTDRSBRResult(params, isEndToEnd, sweepId) {

  if (sweepId) {
    return await apiProcess(generateSweepTDRSBR, { params, sweepId });
  }

  if (isEndToEnd) {
    return await apiProcess(generateEndToEndTDRSBR, params, false, true);
  } else {
    return await apiProcess(generateTDRSBR, params, false, true);
  }
}

export async function getHistoryWaveformResult({ historyId, type, name, isEndToEnd = false, pair, signal }) {
  let waveformData = null;
  if (isEndToEnd) {
    waveformData = (await getMultiPCBHistoryResults({ historyId, type, pair, signal })).data;
  } else {
    waveformData = (await getHistoryResults({ historyId, type, pair, signal })).data;
  }
  let waveform = new WaveformResultHelper('history', name);
  waveform.parseRawCurve(waveformData);
  return waveform;
}

function matchTDRName(curve, content, components, displayMode) {
  const pcbIndexMap = getPCBIndexMap(content.endToEnd.content.pcbConnections);
  // CPHY：id::signalName_PCB_components_pin1_pin2::pair
  // other: id::signalName_PCB_components_pin1_pin2
  const nameSplit = curve.name.split('::');
  const name = nameSplit[1];

  const find = components.find(comp => {
    const reg = displayMode === "TDR"
      ? new RegExp(`${comp.name}_${comp.designName}_${comp.component}_(${comp.pins.join('|')})_(${comp.pins.join('|')})`, "i")
      : new RegExp(`${comp.name}_${comp.designName}_${comp.component}_(${comp.pins.join('|')})`, "i")
    return reg.test(name)
  })

  if (find) {
    // pinName may contain '_'
    // const match = name.match(new RegExp(`(\\S*)::${find.name}_${find.designName}_${find.component}_(\\S*)`, 'i'));
    // replace the part of the name that does not belong to the pin
    const pinsStr = name.replace(new RegExp(`(\\S*)::${find.name}_${find.designName}_${find.component}_(\\S*)`, 'i'), '');
    const pins = find.pins
      .filter(sub => pinsStr.includes(sub))
      .sort((a, b) => pinsStr.indexOf(a) - pinsStr.indexOf(b));
    if (pcbIndexMap.get(find.designName) === undefined) {
      return null;
    }
    return {
      id: curve.name,
      displayDesign: `PCB${pcbIndexMap.get(find.designName) + 1}`,
      displayCompPins: `${find.component}<+${pins[0]}, -${pins[1]}>`,
      color: curve.color,
      signal: find.name,
      component: find.component,
      design: find.designName,
      pins: `${pins[0]}_${pins[1]}`,
      dispalyCompPin: `${find.component}<${pins[0]}>`
    }
  }
  return null;
}

function matchPowerSumName(curve, content, components, displayMode) {
  const pcbIndexMap = getPCBIndexMap(content.endToEnd.content.pcbConnections);
  const name = curve.name,
    nameSplit = strDelimited(name, '::')[1],
    type = displayMode === 'MM_POWERSUM' ? strDelimited(nameSplit, '_')[0] : '',
    prefix = displayMode === 'MM_POWERSUM' ? strDelimited(nameSplit, '_')[0] + '_' : '';
  let compPin;
  const matchName = function (comp) {
    if (displayMode === "MM_POWERSUM") {
      return [`${comp.name}_${comp.designName}_${comp.component}_${comp.pins[0]}_${comp.pins[1]}`,
      `${comp.name}_${comp.designName}_${comp.component}_${comp.pins[1]}_${comp.pins[0]}`
      ]
    } else {
      compPin = comp.pins.find((pin) => name.match(`${pin}$`))
      return [`${comp.name}_${comp.designName}_${comp.component}_${comp.pins[0]}`,
      `${comp.name}_${comp.designName}_${comp.component}_${comp.pins[1]}`
      ]
    }
  }
  const find = components.find(comp => name.match(new RegExp(`::${prefix}(${matchName(comp).join('|')})`, 'i')))
  if (find) {
    const match = name.match(new RegExp(`(\\S*)::${prefix}${find.name}_${find.designName}_${find.component}_(\\S*)`, 'i'));
    if (pcbIndexMap.get(find.designName) === undefined) {
      return null;
    }
    return {
      id: name,
      displayDesign: `PCB${pcbIndexMap.get(find.designName) + 1}`,
      displayCompPins: displayMode === 'MM_POWERSUM' ? `${find.component}<+${find.pins[0]}, -${find.pins[1]}>` : `${find.component}_${compPin}`,
      color: curve.color,
      signal: find.name,
      component: find.component,
      design: find.designName,
      pins: match[2],
      type
    }
  }
  return null;
}

function getSBREndToEndResultList(curves, interfaceContent, pair, signalName, displayMode) {
  const pcbIndexMap = getPCBIndexMap(interfaceContent.endToEnd.content.pcbConnections);
  let obj = { list: [], show: [] };
  let _curves = [];
  const signalGroups = getSignalGroup(interfaceContent);
  for (let signalGroup of signalGroups) {
    const beginDesign = signalGroup.beginChannel.designName;
    const endDesign = signalGroup.endChannel.designName;
    if (pcbIndexMap.get(beginDesign) === undefined || pcbIndexMap.get(endDesign) === undefined) {
      continue;
    }
    // {name, pins}
    const beginComps = signalGroup.beginChannel.components;
    const endComps = signalGroup.endChannel.components;
    const reg = new RegExp(`(\\S*)::${signalGroup.name}_${beginDesign}_(${beginComps.map(d => d.name).join('|')})_(\\S*)_${endDesign}_(${endComps.map(d => d.name).join('|')})_(\\S*)`, 'i')
    const findCurves = curves.filter(d => d.name.match(reg));
    if (!findCurves || !findCurves.length) {
      continue;
    }
    const rowName = pair ? `${signalGroup.name} (${pair})` : signalGroup.name;
    let children = [];
    for (const find of findCurves) {
      _curves.push(find.name);
      // pinName may contains '_'
      // "Signal1_mlb_U600_B16_A15_PreLayout1_U1_CPHY0_A_CPHY0_B"
      const matchName = find.name.replace(new RegExp(`(\\S*)::${signalGroup.name}_${beginDesign}_`, 'i'), '');
      // beginName/endName : components_pin1_pin2
      const [beginName, endName] = matchName.split(`_${endDesign}_`);
      let beginMatchObj = [], endMatchObj = [];
      for (const comp of beginComps) {
        const _beginMatchObj = displayMode === "SBR"
          ? beginName.match(new RegExp(`(${comp.name})_(${comp.pins.join('|')})_(${comp.pins.join('|')})`, 'i'))
          : beginName.match(new RegExp(`(${comp.name})_(${comp.pins.join('|')})`, 'i'))
        if (_beginMatchObj) {
          beginMatchObj = _beginMatchObj;
          break;
        }
      }

      for (const comp of endComps) {
        const _endMatchObj = displayMode === "SBR"
          ? endName.match(new RegExp(`(${comp.name})_(${comp.pins.join('|')})_(${comp.pins.join('|')})`, 'i'))
          : endName.match(new RegExp(`(${comp.name})_(${comp.pins.join('|')})`, 'i'));
        if (endMatchObj) {
          endMatchObj = _endMatchObj;
          break;
        }
      }
      let child = {
        id: find.name,
        color: find.color,
        singal: signalGroup.name,
        design1: `PCB${pcbIndexMap.get(beginDesign) + 1}`,
        design1CompPins: `${beginMatchObj[1]}<+${beginMatchObj[2]}, -${beginMatchObj[3]}>`,
        design2: `PCB${pcbIndexMap.get(endDesign) + 1}`,
        design2CompPins: `${endMatchObj[1]}<+${endMatchObj[2]}, -${endMatchObj[3]}>`,
        design1CompPin: `${endMatchObj[1]}<${beginMatchObj[2]}>`,
        design2CompPin: `${endMatchObj[1]}<${endMatchObj[2]}>`,
      }
      children.push(child)
    }

    obj.show.push(rowName);
    obj.list.push({
      rowName,
      children
    })
  }
  // pulse
  const restCurves = curves.filter(d => !_curves.includes(d.name));
  restCurves.forEach(c => {
    const nameSplit = strDelimited(c.name, '::');
    // const signal = pair ? signalGroups.find(item => nameSplit[2].search(`${item.name}_`) > -1) : null;
    // const rowName = pair ? `${(signal || {}).name} - ${nameSplit[1]} (${pair})` : nameSplit[1];
    const rowName = pair ? `${signalName} - ${nameSplit[1]} (${pair})` : nameSplit[1];
    const item = { rowName, children: [{ id: c.name, name: nameSplit[1], color: c.color }] }
    obj.show.push(rowName)
    obj.list.push(item)
  })
  return obj;
}

function getSBRChannelResultList(curves, interfaceContent, pair, signalName, displayMode) {
  let obj = { list: [], show: [] };
  const signals = interfaceContent && interfaceContent.content && interfaceContent.content.signals ? interfaceContent.content.signals : [];
  const components = interfaceContent && interfaceContent.content && interfaceContent.content.components ? interfaceContent.content.components : [];
  const rowNameMap = new Map();
  for (let c of [...curves]) {
    const nameSplit = strDelimited(c.name, '::')[1];

    //find signal name
    const signal = signals.find(item => nameSplit.search(`${item.name}_`) > -1);
    if (nameSplit === "pulse" || !signal) {
      let rowName = nameSplit;
      if (pair) {
        rowName = `${signalName} - ${nameSplit} (${pair})`;
      }
      const item = {
        id: c.name,
        name: nameSplit,
        color: c.color
      }
      if (rowNameMap.has(rowName)) {
        const oldChildren = rowNameMap.get(rowName);
        oldChildren.push(item);
        rowNameMap.set(rowName, oldChildren);
      } else {
        rowNameMap.set(rowName, [item])
      }
      continue;
    }
    //split signal name and component , filter signal for name
    const compPinSplit = nameSplit.replace(`${signal.name}_`, "");

    //find component 1
    const {
      compName: comp1Name,
      compPositivePin: comp1PositivePin,
      compNegativePin: comp1NegativePin,
      compPin: comp1Pin
    } = getCompAndPins(compPinSplit, components, signal, pair, displayMode);

    //filter comp1 name and pins for name
    const _compPinSplit = displayMode === "SBR"
      ? compPinSplit.replace(`${comp1Name}_${comp1PositivePin}_${comp1NegativePin}_`, "")
      : compPinSplit.replace(`${comp1Name}_${comp1Pin}_`, "")

    //find component 2
    const {
      compName: comp2Name,
      compPositivePin: comp2PositivePin,
      compNegativePin: comp2NegativePin,
      compPin: comp2Pin
    } = getCompAndPins(_compPinSplit, components, signal, pair, displayMode);

    const rowName = pair ? `${signal.name || nameSplit} (${pair})` : signal.name || nameSplit;
    const item = {
      id: c.name,
      name: nameSplit,
      signal: signal.name,
      comp1Pins: `${comp1Name}<+${comp1PositivePin}, -${comp1NegativePin}>`,
      comp2Pins: `${comp2Name}<+${comp2PositivePin}, -${comp2NegativePin}>`,
      comp1Pin: `${comp1Name}<${comp1Pin}>`,
      comp2Pin: `${comp2Name}<${comp2Pin}>`,
      color: c.color
    }
    if (rowNameMap.has(rowName)) {
      const oldChildren = rowNameMap.get(rowName);
      oldChildren.push(item);
      rowNameMap.set(rowName, oldChildren);
    } else {
      rowNameMap.set(rowName, [item])
    }
  }
  rowNameMap.forEach((value, key) => {
    obj.list.push({
      rowName: key,
      children: value
    })
  })
  obj.show = obj.list.map(d => d.rowName)
  return obj;
}

export function getCompAndPins(compPinStr, components, signal, pair, displayMode) {
  //comp split
  //find comp name
  // const compName = strDelimited(compPinStr, '_')[0];
  let compName;
  for (const comp of components) {
    const reg = new RegExp(`^(${comp.name})`)
    if (reg.test(compPinStr)) {
      compName = comp.name;
      break;
    }
  }
  //find component by components
  const comp = components.find(item => item.name === compName) || { pins: [] };
  const types = pair ? pair.split('').map(p => `nets_${p}`) : ['nets_P', 'nets_N'];
  //filter pins by signal and find positive or negative pin
  const compPins = comp.pins.filter(item => item.signal === signal.name).map(item => {
    let typeText = "";
    if (signal[types[0]] && signal[types[0]].includes(item.net)) {
      typeText = "+";
    } else if (signal[types[1]] && signal[types[1]].includes(item.net)) {
      typeText = "-";
    }
    return { ...item, typeText }
  });
  const compPositivePin = (compPins.find(item => item.typeText === "+") || { pin: "" }).pin,
    compNegativePin = (compPins.find(item => item.typeText === "-") || { pin: "" }).pin;

  let compPin = "";
  if (displayMode === "SE_SBR") {
    for (const pinInfo of comp.pins) {
      const reg = new RegExp(`^(${comp.name}_${pinInfo.pin})`)
      if (reg.test(compPinStr)) {
        compPin = pinInfo.pin;
        break;
      }
    }
  }

  return { compName, compPositivePin, compNegativePin, compPin };
}

export function getSBRResultList({ curves, isEndToEnd, interfaceContent, pair, signalName, displayMode }) {
  if (isEndToEnd) {
    return getSBREndToEndResultList(curves, interfaceContent, pair, signalName, displayMode);
  }
  return getSBRChannelResultList(curves, interfaceContent, pair, signalName, displayMode);
}


function getTDREndToEndResultList({ interfaceContent, curves, pair, displayMode }) {
  let obj = { list: [], show: [] };
  const signalGroups = getSignalGroup(interfaceContent);
  let _curves = [...curves];
  for (let signalGroup of signalGroups) {
    const rowName = pair ? `${signalGroup.name} (${pair})` : signalGroup.name;
    obj.show.push(rowName);
    const components = getComponents([signalGroup]);
    const children = _curves.map(c => matchTDRName(c, interfaceContent, components, displayMode)).filter(d => !!d);
    // Sort according to pcb index
    const compDesigns = components.map(comp => comp.designName);
    const _children = SortFn(children, compDesigns, 'design');
    const item = { rowName, children: _children };
    obj.list.push(item)
  }
  return obj;
}

async function getTDRChannelResultList({ id, propsId, interfaceContent, curves, pair, displayMode }) {
  function child(curve, comp, net) {
    this.id = curve.name;
    this.name = IC.find(d => d.name === comp).type + '::' + comp;
    this.color = curve.color;
    this.net = net;
    this.comp = comp;
  }
  let obj = { list: [], show: [] }, signals, components;
  if (id === propsId) {
    signals = interfaceContent.content.signals;
    components = interfaceContent.content.components;
  } else {
    const historyJson = await getHistoryVerificationJson(id);
    if (historyJson) {
      //TODO
      signals = historyJson.content.signals;
      components = historyJson.content.components;
    }
  }
  if (!signals || !components) return obj;
  const IC = components.filter(c => SERDES_TYPES.includes(c.type));
  const ICNames = IC.map(d => d.name)
  const reg = new RegExp(`^(${signals.map(s => s.name).join('|')})_(${ICNames.join('|')})`, 'i');
  const rowNameMap = new Map();
  for (const c of curves) {
    const nameSplit = strDelimited(c.name, '::')[1];
    const match = nameSplit.match(reg);
    if (match && match.length > 2) {
      const signal = match[1];
      const comp = match[2];
      const rowName = pair ? `${signal} (${pair})` : signal;
      const net = nameSplit.replace(`${signal}_${comp}_`, "");
      const item = new child(c, comp, net)
      if (rowNameMap.has(rowName)) {
        const oldChildren = rowNameMap.get(rowName);
        oldChildren.push(item);
        rowNameMap.set(rowName, oldChildren);
      } else {
        rowNameMap.set(rowName, [item])
      }
    }
  }
  const compNames = components.map(item => item.name);
  rowNameMap.forEach((value, key) => {
    let _value = value
    if (["SE_TDR", "SE_SBR"].includes(displayMode)) {
      _value = SortFn(value, compNames, 'comp');
    }
    obj.list.push({
      rowName: key,
      children: _value
    })
  })
  obj.show = obj.list.map(d => d.rowName)
  return obj;
}

export async function getTDRResultList({ isEndToEnd, ...restProps }) {
  // TDR list: [{ rowName: signal, children: [] }]
  if (isEndToEnd) {
    return getTDREndToEndResultList({ ...restProps })
  }
  return await getTDRChannelResultList({ ...restProps })
}

function getPowerSumEndToEndResultList(curves, interfaceContent, displayMode) {
  let obj = { list: [], show: [] };
  const signalGroups = getSignalGroup(interfaceContent);
  let _curves = [...curves];
  for (let signalGroup of signalGroups) {
    obj.show.push(signalGroup.name);
    const children = _curves.map(c => matchPowerSumName(c, interfaceContent, getComponents([signalGroup]), displayMode)).filter(d => !!d);
    const item = { rowName: signalGroup.name, children };
    obj.list.push(item)
  }
  return obj;
}

function getPowerSumChannelResultList(curves, interfaceContent, displayMode, pair) {
  let obj = { list: [], show: [] };
  let signals, components;
  if (interfaceContent && interfaceContent.content) {
    signals = interfaceContent.content.signals;
    components = interfaceContent.content.components;
  }
  if (!signals || !components) return obj;
  let childrenObj = {}
  for (let c of [...curves]) {
    const nameSplit = strDelimited(c.name, '::')[1];
    const type = displayMode === 'MM_POWERSUM' ? strDelimited(nameSplit, '_')[0] : '';

    //find signal name
    const signal = signals.find(item => nameSplit.search(`${item.name}_`) > -1);
    //split signal name and component , filter signal for name
    const compPinSplit = displayMode === 'MM_POWERSUM' ? nameSplit.replace(`${type}_${signal.name}_`, "") : nameSplit.replace(`${signal.name}_`, "");

    //find component
    const {
      compName,
      compPositivePin,
      compNegativePin
    } = getCompAndPins(compPinSplit, components, signal, pair);

    const rowName = pair ? `${signal.name || nameSplit} (${pair})` : signal.name || nameSplit;
    const compPin = displayMode === 'MM_POWERSUM' ? '' : [compPositivePin, compNegativePin].find((pin) => nameSplit.match(`${pin}$`));
    const item = {
      id: c.name,
      name: nameSplit,
      signal: signal.name,
      compPins: displayMode === 'MM_POWERSUM' ? `${compName}<+${compPositivePin}, -${compNegativePin}>` : `${compName}_${compPin}`,
      color: c.color,
      type
    }
    if (childrenObj[rowName]) {
      childrenObj[rowName].push(item)
    } else {
      childrenObj[rowName] = [item]
    }
  }
  for (const key of Object.keys(childrenObj)) {
    obj.list.push({
      rowName: key,
      children: childrenObj[key]
    })
    obj.show.push(key)
  }
  return obj;
}

export function getPowerSumResultList({ curves, isEndToEnd, interfaceContent, displayMode, pair }) {
  if (isEndToEnd) {
    return getPowerSumEndToEndResultList(curves, interfaceContent, displayMode)
  }
  return getPowerSumChannelResultList(curves, interfaceContent, displayMode, pair)
}

export function getFreqByPreLayoutSetup(preLayoutContent) {
  if (!preLayoutContent || !preLayoutContent.content || !preLayoutContent.content.models) {
    return 5e9;
  }
  const frequency = preLayoutContent.content.models[0].frequency;
  let _valueObj = { value: 5e9, unit: "Hz" }, _value = "5e9";

  if (frequency && frequency[2] && frequency[2].end) {
    _value = frequency[2].end;
  } else if (frequency && frequency[2] && frequency[2].start) {
    _value = frequency[2].start;
  }
  _valueObj = valueUnitSplit(_value);
  const scale = scaleConversion("Hz", _valueObj.unit);
  return NP.times(parseFloat(_valueObj.value), scale) || 5e9;
}

function getNameElement({ nameSplit, components, reg }) {
  const match = nameSplit.match(reg);
  const comp = components.find(d => d.name.toLowerCase() === match[2]) || {};
  const compName = comp.name;
  const pinLower = nameSplit.replace(match[0], '');
  const pins = comp.pins || []
  const { pin, signal } = pins.find(d => pinLower.startsWith((d || { pin: "" }).pin.toLowerCase())) || {};
  const type = match[1].toLowerCase() === 'tx' ? 'Driver' : 'Receiver'
  return {
    compName,
    pin,
    signal,
    type
  }
}

function getWaveformChannelResultList({ curves, interfaceContent }) {
  let obj = { list: [], show: [] };
  let signals, components;
  if (interfaceContent && interfaceContent.content) {
    signals = interfaceContent.content.signals;
    components = interfaceContent.content.components;
  }
  if (!signals || !components) return obj;
  const compNameReg = components.map(d => d.name.toLowerCase()).join('|');
  // signal -> child
  const signalMap = new Map();

  const reg = new RegExp(`^(tx|rx)_(${compNameReg})_`, 'i');

  curves.forEach(c => {
    let child, signal;
    // ex1: tx_u1_cphy0_a [tx_comp_signal]
    // ex2: tx_u1_cphy0_a_ctle [tx_comp_signal_ctle]
    // ex2: tx_u1_cphy0_a,tx_u1_cphy1_b [tx_comp_signal,tx_comp_signal] 
    const nameSplit = c.name.split("::")[1];
    if (nameSplit.includes(',')) {
      const [name1, name2] = nameSplit.split(',');
      const nameElement1 = getNameElement({ nameSplit: name1, components, reg });
      const nameElement2 = getNameElement({ nameSplit: name2, components, reg });
      signal = nameElement1.signal;
      child = {
        id: c.name,
        name: nameSplit,
        color: c.color,
        comp: nameElement1.compName,
        pin1: nameElement1.pin,
        pin2: nameElement2.pin,
        signal,
        type: nameElement1.type
      }
    } else {
      const nameElement = getNameElement({ nameSplit, components, reg })
      signal = nameElement.signal;
      const pin = nameElement.pin;
      child = {
        id: c.name,
        name: nameSplit,
        color: c.color,
        comp: nameElement.compName,
        pin,
        signal,
        type: nameElement.type,
        ctle: nameSplit.endsWith('ctle_in')
      }
    }

    if (signal) {
      if (!signalMap.has(signal)) {
        signalMap.set(signal, [child])
      } else {
        const list = signalMap.get(signal);
        list.push(child);
        signalMap.set(signal, list);
      }
    }
  })

  for (const [key, value] of signalMap) {
    obj.list.push({
      children: value,
      rowName: key
    })

    obj.show.push(key)
  }

  return obj;
}

export function getWaveformResultList({ curves, interfaceContent }) {
  return getWaveformChannelResultList({ curves, interfaceContent })
}