class IoSimsConstructor {
  constructor() {
    this.ioSimList = new Map(); //key - ioSim verificationId (verificationId), value :{ id ,name, verificationId .... }
  }

  getSize = () => {
    return this.ioSimList.size;
  };

  getList = () => {
    return this.ioSimList;
  }

  getValues = () => {
    let list = [];
    for (let val of this.ioSimList.values()) {
      list.push(val);
    }
    return list;
  }

  get = (id) => {
    return this.ioSimList.get(id);
  }

  add = (id, data) => {
    this.ioSimList.set(id, data);
  }

  addList = (list) => {
    for (let item of list || []) {
      this.ioSimList.set(item.id, item);
    }
  }

  del = (id) => {
    this.ioSimList.delete(id);
  }

  clearCache = () => {
    this.ioSimList = new Map();
  }
};

let ioSimsConstructor = new IoSimsConstructor();
export default ioSimsConstructor;