import { LIBRARY_FILE, LIBRARY_FOLDER } from '@/constants/treeConstants';
import { IBIS_AMI } from '../../../constants/libraryConstants';

/**
 *
 *
 * @param {*} key
 * @param {*} { name, type, id } type - file|data
 */
function libraryItem(key, { name, type, id, libraryType, nodeClass, dataType, deletable = true, format, fileType, children, path }) {
  this.name = name;
  this.type = type;
  this.id = id;
  this.dataType = dataType || LIBRARY_FILE;
  this.key = key + '-' + this.id + "-" + name;
  this.nodeClass = nodeClass;
  this.deletable = deletable;
  this.format = format;
  this.fileType = getFileType(fileType); //file / folder
  if (children) {
    this.children = children;
  }
  this.icon = getIcon(dataType);
  this.path = path || null
}

function getFileType(fileType) {
  if (fileType && typeof (fileType) === "string") {
    return fileType.toLowerCase()
  }
  return "file"
}

function getIcon(dataType) {
  if (dataType === LIBRARY_FOLDER) {
    return "folder"
  } else if (dataType === LIBRARY_FILE) {
    return "file"
  }
}

/**
 * Get library array
 *
 * @param {String} key trace|via
 * @param {Array} dataArr [{ id: "",  name: "", type: "pkg_touchstone", userId: "" }]
 * @returns [libraryItem]
 */
function getLibraryArray(key, dataArr) {
  if (!Array.isArray(dataArr) || !dataArr.length) {
    return [];
  }
  const _arr = dataArr.map(item => new libraryItem(key, {
    name: item.name,
    type: key, //pkg_touchstone / pkg_spice / passive_touchstone / passive_spice / connector
    id: item.id,
    // dataType: key === IBIS_AMI ? LIBRARY_FOLDER : key === EYE_MASK && item.format === 'Data' ? LIBRARY_DATA : LIBRARY_FILE,
    dataType: key === IBIS_AMI || item.fileType === "Folder" ? LIBRARY_FOLDER : LIBRARY_FILE,
    nodeClass: 'tree-library-file',
    deletable: item.deletable,
    format: item.format,
    fileType: item.fileType,
    children: item.fileType === "Folder" ? item.children ? getLibraryChildren(key, item) : [] : null,
    path: item.path
  }));
  return _arr;
}

function getLibraryChildren(key, data) {
  if (!data || !data.children || !data.children.length) {
    return [];
  }

  const list = data.children.map(item => new libraryItem(key, {
    name: item.name,
    type: key, //pkg_touchstone / pkg_spice / passive_touchstone / passive_spice / connector
    id: item.id,
    dataType: key === IBIS_AMI || item.fileType === "Folder" ? LIBRARY_FOLDER : LIBRARY_FILE,
    nodeClass: 'tree-library-file',
    deletable: item.deletable,
    format: item.format,
    fileType: item.fileType,
    children: item.fileType === "Folder" && item.children ? getLibraryChildren(key, item) : null,
    path: item.path
  }));
  return list;
}

export {
  getLibraryArray,
  libraryItem
}