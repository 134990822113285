import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input, Button } from 'antd';
import { createSignal, cancelSignal } from './store/actionCreators';
import { getRelatedNets } from '@/services/LayoutCanvas/oldCanvas';
import './searchBox.css';

const NO_RELATED_TYPE = ['source_net', 'pintopin_net']

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      result: undefined,
      checkedSignalNets: [],
      signalType: 'single',
      relatedNets: [],
      relateNet: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    const { search } = nextProps;
    if (this.props !== nextProps) {
      const newState = {
        value: '',
        result: undefined,
        relatedNets: [],
        relateNet: ''
      }
      if (search === null) {
        newState.checkedSignalNets = []
      } else {
        newState.checkedSignalNets = search.selected;
      }
      this.setState(newState)
    }
  }

  changeValue = (e) => {
    const value = e.target.value;
    if (value || this.isSelectSignal()) {
      this.setState({
        value: value,
        result: this.props.onSearch(value)
      });
    } else {
      this.setState({
        value: value,
      });
    }
  }

  isSelectSignal() {
    const { search } = this.props;
    // if (search && search.selected && search.selected.length > 0) {
    //   this.props.onSelect({
    //     nets: search.selected
    //   });
    // }
    return search && search.type === 'signal';
  }

  search = () => {
    const value = this.state.value;
    if (value || this.isSelectSignal()) {
      this.setState({
        result: this.props.onSearch(value)
      });
    }
  }

  onSelect = (e) => {
    const { nets } = this.state.result;
    const name = e.target.innerText;
    this.setState({
      value: name,
      result: undefined
    })
    if (nets.includes(name)) {
      this.props.onSelect({ nets: [name] });
    } else {
      this.props.onSelect({ comps: [name] });
    }
  }

  onSignalChange = (options) => (checkedList) => {
    const preCheckedNets = this.state.checkedSignalNets;
    const filterDelete = preCheckedNets.filter(item => !checkedList.includes(item));
    const _delete = filterDelete.filter(item => options.includes(item));
    const _selected = [...new Set([...preCheckedNets, ...checkedList])].filter(item => !_delete.includes(item));
    this.props.onSelect({
      nets: [..._selected],
    });
    this.setState({
      checkedSignalNets: _selected
    }, () => {
      if (_selected.length > preCheckedNets.length) {
        const selectedNet = _selected[_selected.length - 1];
        const { from } = this.props.search;
        if (!NO_RELATED_TYPE.includes(from)) {
          const nets = getRelatedNets(selectedNet);
          this.setState({
            relateNet: selectedNet,
            relatedNets: nets.filter(net => !_selected.includes(net))
          })
        }
      } else {
        this.setState({
          relateNet: '',
          relatedNets: []
        })
      }
    })
  }

  onRelatedNetsChange = (checkedList) => {
    const { checkedSignalNets } = this.state;
    const _selected = [...new Set([...checkedSignalNets, ...checkedList])];
    this.setState(state => ({
      checkedSignalNets: _selected,
      relatedNets: state.relatedNets.filter(net => !checkedList.includes(net))
    }), () => {
      this.props.onSelect({
        nets: _selected,
      });
    })
  }

  onSignalTypeChange = (e) => {
    this.setState({
      signalType: e.target.value
    })
  }

  onCreateSignal = () => {
    const { checkedSignalNets: nets, signalType: type } = this.state;
    this.props.createSignal(nets, type);
    this.setState({
      value: '',
      result: undefined,
      checkedSignalNets: [],
    })
  }

  onSignalCancel = () => {
    this.props.cancelSelectSignal();
    this.setState({
      value: '',
      result: undefined,
      checkedSignalNets: [],
    })
  }

  renderResult = () => {
    const { value, result, checkedSignalNets, relateNet, relatedNets } = this.state;
    let resultList;
    if (this.isSelectSignal()) {
      const { name } = this.props.search;
      const options = Array.isArray(result) ? result : this.props.onSearch(value);
      resultList = (
        <div className="signal-select">
          <Checkbox.Group
            options={options}
            value={checkedSignalNets}
            onChange={this.onSignalChange(options)}
          />

          {/* <RadioGroup onChange={this.onSignalTypeChange} value={signalType}>
            <Radio value={'auto'}>Auto</Radio>
            <Radio value={'signal'}>Single</Radio>
            <Radio value={'multi'}>Multi</Radio>
          </RadioGroup> */}
          {
            (relateNet && relatedNets.length > 0) ? (
              <div className="selected">
                <div className="result-title">Nets related to {relateNet}</div>
                <Checkbox.Group
                  options={relatedNets}
                  value={checkedSignalNets}
                  onChange={this.onRelatedNetsChange}
                />
              </div>
            ) : null
          }
          {checkedSignalNets.length > 0 ? (
            <div className='selected'>
              <div className="result-title">{name || 'Selected'} Nets: </div>
              <Checkbox.Group
                options={checkedSignalNets}
                value={checkedSignalNets}
                onChange={this.onSignalChange(checkedSignalNets)}
              />
            </div>) : null}
          <div className="signal-confirm">
            <Button type='primary' size="small" onClick={this.onSignalCancel}> Cancel </Button>
            <Button style={{ width: '70px' }} type='primary' size="small" onClick={this.onCreateSignal} disabled={!checkedSignalNets.length}> OK </Button>
          </div>
        </div>
      )
    } else {
      if (!value || !result) {
        return;
      }
      const { nets, components } = result;
      const { onSelect } = this;
      resultList = (nets.length === 0 && components.length === 0) ? (
        <div className="result-title">No Result</div>
      ) : (
          <div style={{ maxHeight: '315px' }}>
            {nets.length > 0 && <div className="result-title" onClick={this.toogle}>Nets</div>}
            {nets.length > 0 && (<ul className="result-list">
              {nets.map((net, i) => <li key={i} onClick={onSelect}>{net}</li>)}
            </ul>)}
            {components.length > 0 && <div className="result-title">Components</div>}
            {components.length > 0 && (<ul className="result-list">
              {components.map((comp, i) => <li key={i} onClick={onSelect}>{comp}</li>)}
            </ul>)}
          </div>
        )
    }

    return (
      <div className="search-result">
        {resultList}
      </div>
    )
  }

  componentDidUpdate = (prevProps) => {
    const { input } = this.inputRef;
    input.focus();
  }

  render() {
    const { className } = this.props;
    const { value } = this.state;
    const { search, renderResult } = this;
    return (
      <div className={`search-box ${className}`}>
        <Input
          placeholder="Net, Component"
          addonAfter={<SearchOutlined className="input-search-button" onClick={search} />}
          allowClear
          value={value}
          onChange={this.changeValue}
          onPressEnter={search}
          ref={(input) => { this.inputRef = input; }}
          autoComplete='off'
        />
        {renderResult()}
      </div>
    );
  }
}

const mapState = (state) => {
  const { search } = state.default;
  return { search };
}


const mapDispatch = (dispatch) => ({
  createSignal: (nets, type) => {
    dispatch(createSignal(nets, type))
  },
  cancelSelectSignal() {
    dispatch(cancelSignal())
  }
})

export default connect(mapState, mapDispatch)(SearchBox);