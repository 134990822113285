import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ViaResult from './viaResult';
import BgaResult from './bgaResult';
import DCTopMenu from './DCTopMenu'
import { changeDCResultType } from '../../store/result/action';
import FileSaver from 'file-saver';
import { getResBlob } from '@/services/helper/downloadHelper';
import dayjs from 'dayjs';

class bumpResultContent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  getDCResultContent = () => {
    const { DCResultKey, verificationId, currentProjectId, PDNID, existResult, layoutStatus } = this.props
    switch (DCResultKey) {
      case 'via':
        return <ViaResult
          projectId={currentProjectId}
          pdnId={PDNID}
          verificationId={verificationId}
        />
      case 'pin':
        return <BgaResult
          projectId={currentProjectId}
          pdnId={PDNID}
          verificationId={verificationId}
        />
      default:
        return null
    }
  }

  changeResultType = (e, resultId) => {
    const { key } = e
    this.props.changeDCResultType(key)
  }

  downloadDCResult = () => {
    const { verificationId, currentProjectPDNs } = this.props;
    const token = localStorage.getItem('token')
    const url = `/api/v3/project/PDN/dc/result?verificationId=${verificationId}&access_token=${token}`

    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    const targetTime = dayjs(time.toUTCString()).format('YYYYMMDD');

    const currentPDN = currentProjectPDNs.find(item => item.verificationId === verificationId)
    const name = currentPDN.name || "design";

    let fileName = `${name}_${targetTime}_dc_result.zip`
    getResBlob(url).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, fileName);
      }
    })
  }

  render() {
    const { DCResultKey } = this.props;
    return (
      <div className='pdn-result-dc-content'>
        {DCTopMenu(DCResultKey, this.changeResultType, this.downloadDCResult)}
        {this.getDCResultContent()}
      </div>
    )
  }
}

const mapState = (state) => {
  const { resultReducer: { DCResultKey }, simulationReducer: { existResult }, project: { verificationId, currentProjectId, PDNID, currentProjectPDNs } } = state.PDNReducer
  return {
    DCResultKey,
    existResult,
    verificationId,
    currentProjectId,
    PDNID,
    currentProjectPDNs
  }
}

const mapDispatch = (dispatch) => ({
  changeDCResultType(key) {
    dispatch(changeDCResultType(key))
  }
})

export default connect(mapState, mapDispatch)(bumpResultContent)