import Line from './Line';
import Circle from './Circle';
import { getBbyArcAndR, getAbyArcAndR, calcArc, tanA, keepTwoDecimal } from '../math';
import { isPointInPolygon } from '../../canvasHelper/canvasMath';
import { calcDistanceOfTwoPoints } from '../../calcPointsDistance';

function Polygon({ mCCW = true, mVertices = [], mX = null, mY = null }) {
  this.mCCW = mCCW;
  this.mVertices = mVertices;
  this.mX = mX === null ? 0 : mX;
  this.mY = mY === null ? 0 : mY;
}

//   -------- line1
//   ———————— polygon line
//   -------- line2
Polygon.prototype.toPoints = function (distance, pointDensity) {
  let arr = [];
  for (let i = 0; i < this.mVertices.length; i++) {
    const v = this.mVertices[i];
    if (v.mGeomType !== 'Pnt') {
      // Parc
      continue;
    }

    const mX = this.mX + v.mX, mY = this.mY + v.mY;


    let v2 = this.mVertices[i + 1];
    if (this.mVertices[i + 1] === undefined) {
      v2 = this.mVertices[0];
    }
    const start = { mX, mY },
      end = { mX: this.mX + v2.mX, mY: this.mY + v2.mY };

    let l1_S, l1_E, l2_S, l2_E;
    if (start.mX === end.mX) {
      l1_S = [start.mX - distance, start.mY];
      l1_E = [end.mX - distance, end.mY];
      l2_S = [start.mX + distance, start.mY];
      l2_E = [end.mX + distance, end.mY];
    } else if (start.mY === end.mY) {
      l1_S = [start.mX, start.mY + distance];
      l1_E = [end.mX, end.mY + distance];
      l2_S = [start.mX, start.mY - distance];
      l2_E = [end.mX, end.mY - distance];
    } else {
      const arc = Math.abs(calcArc(start, end))
      const b = getBbyArcAndR(arc, distance),
        a = getAbyArcAndR(arc, distance);
      const tan = tanA(start, end);
      if (tan > 0) {
        l1_S = [start.mX - b, start.mY + a];
        l1_E = [end.mX - b, end.mY + a];
        l2_S = [start.mX + b, start.mY - a];
        l2_E = [end.mX + b, end.mY - a];
      } else {
        l1_S = [start.mX + b, start.mY + a];
        l1_E = [end.mX + b, end.mY + a];
        l2_S = [start.mX - b, start.mY - a];
        l2_E = [end.mX - b, end.mY - a];
      }
    }

    const l1Start = {
      mX: l1_S[0],
      mY: l1_S[1]
    }, l1End = {
      mX: l1_E[0],
      mY: l1_E[1]
    },
      l2Start = {
        mX: l2_S[0],
        mY: l2_S[1]
      }, l2End = {
        mX: l2_E[0],
        mY: l2_E[1]
      };

    // TODO - filter circle points
    const line1 = new Line({ start: l1Start, end: l1End });
    const line2 = new Line({ start: l2Start, end: l2End });
    const distance1 = line1.distance(l1Start, l1End);
    const distance2 = line2.distance(l2Start, l2End);
    const points1 = line1.linearInterpolation(l1Start, l1End, 0, Math.ceil(distance1 / pointDensity));
    const points2 = line2.linearInterpolation(l2Start, l2End, 0, Math.ceil(distance2 / pointDensity));

    // TODO - filter points
    let circlePoints = [];
    // Draw circles on each point
    const circle = new Circle({ mDiameter: 0, mX, mY });
    circlePoints = circle.toPoints(distance * 2, pointDensity)
    arr.push(l1_S, l1_E, l2_S, l2_E, ...points1, ...points2, ...circlePoints);
  }
  return arr.filter(d => !isNaN(d[0]) && !isNaN(d[1])).map(d => [keepTwoDecimal(d[0]), keepTwoDecimal(d[1])]);
}

// [minX, maxX, minY, maxY]
Polygon.prototype.getSizeArray = function () {
  if (!this.mVertices.length) return [];
  let minX = this.mX + this.mVertices[0].mX,
    maxX = this.mX + this.mVertices[0].mX,
    minY = this.mY + this.mVertices[0].mY,
    maxY = this.mY + this.mVertices[0].mY;
  for (let i = 1; i < this.mVertices.length; i++) {
    const v = this.mVertices[i];
    if (v.mGeomType !== 'Pnt') {
      // Parc
      continue;
    }

    const mX = this.mX + v.mX, mY = this.mY + v.mY;
    if (mX < minX) {
      minX = mX;
    }

    if (mX > maxX) {
      maxX = mX;
    }

    if (mY < minY) {
      minY = mY;
    }

    if (mY > maxY) {
      maxY = mY;
    }
  }
  return [minX, maxX, minY, maxY];
}

Polygon.prototype.getCenterGravity = function () {
  if (!this.mVertices.length) return {};
  const new_mVertices = this.mVertices.filter(it => it.mGeomType === 'Pnt').map(it => { return { x: it.mX, y: it.mY } });
  const _mVertices = this.mVertices.filter(it => it.mGeomType === 'Pnt');
  let first = new_mVertices[0], last = new_mVertices[new_mVertices.length - 1];
  if (first.x !== last.x || first.y !== last.y) new_mVertices.push(first);

  let area = 0,
    x = 0, y = 0,
    nPts = new_mVertices.length,
    p1, p2, f;

  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = new_mVertices[i]; p2 = new_mVertices[j];
    f = p1.x * p2.y - p2.x * p1.y;
    area += f;
    x += (p1.x + p2.x) * f;
    y += (p1.y + p2.y) * f;
  }
  f = area * 3;
  let location = { x: x / f, y: y / f };
  const _p = { mX: x / f, mY: y / f }

  const isPoint = isPointInPolygon(location, new_mVertices);

  if (!isPoint) {
    let minDistance = Infinity, minVertices = [];
    for (let vertices of _mVertices) {
      const distance = calcDistanceOfTwoPoints(_p, vertices)
      if (distance < minDistance) {
        minDistance = distance;
        minVertices = { ...vertices }
      }
    }
    location = { x: minVertices.mX, y: minVertices.mY }
  }

  return location;
}

export default Polygon;