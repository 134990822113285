import CeLayerMetal from './CeLayerMetal';
import CeLayerDocument from '../partlib/CeLayerDocument';
import CeLinkedMap from '../utility/CeLinkedMap';
import StringList from '../utility/StringList';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeLayerManager = function () {
  this.mNextLayerID = 0;
  this.mMetalLayers = [];
  this.mDocumentLayers = [];
  this.mLayerIDNameMap = new CeLinkedMap();
};

CeLayerManager.prototype.ClearAll = function () {
  this.mMetalLayers = [];
  this.mDocumentLayers = [];
  this.mLayerIDNameMap.clear();
}

CeLayerManager.prototype.GetMetalLayer = function (name, createIt) {
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    if (name == this.mMetalLayers[i].GetName())
      return this.mMetalLayers[i];
  }

  if (createIt != null && createIt != undefined && createIt == true) {
    var metalLyr = new CeLayerMetal();
    metalLyr.SetName(name);
    metalLyr.SetID(this.mNextLayerID);
    metalLyr.SetThickness(0.0);
    // this.mLayerIDNameMap.put(this.mNextLayerID, name);
    this.mNextLayerID++;
    this.mMetalLayers.push(metalLyr);
    return metalLyr;
  }
  return null;
}

CeLayerManager.prototype.AddMetalLayer = function (name, type, thickness) {
  var metalLyr = this.GetMetalLayer(name, true);
  metalLyr.SetType(type);
  metalLyr.SetThickness(thickness);
  return metalLyr;
}

CeLayerManager.prototype.AddDocumentLayer = function (name) {
  var docLayer = new CeLayerDocument();
  docLayer.SetName(name);
  docLayer.SetID(this.mNextLayerID);
  this.mLayerIDNameMap.put(this.mNextLayerID, name);
  this.mDocumentLayers.push(docLayer);
  this.mNextLayerID++;
  return docLayer;
}

CeLayerManager.prototype.AddLogicLayer = function (name, type, onMetal) {
  var metalLayer = this.GetMetalLayer(onMetal, true);
  var newLayer = metalLayer.AddLogicLayer(name, this.mNextLayerID, type);
  this.mLayerIDNameMap.put(this.mNextLayerID, name);
  this.mNextLayerID++;
  return newLayer;
}

CeLayerManager.prototype.AddComponentLayer = function (name, onMetal) {
  var metalLayer = this.GetMetalLayer(onMetal, true);
  var existCompLayer = metalLayer.GetComponentLayer(name);
  if (existCompLayer != null)
    return existCompLayer;

  var newLayer = metalLayer.AddComponentLayer(name, this.mNextLayerID);
  this.mLayerIDNameMap.put(this.mNextLayerID, name);
  this.mNextLayerID++;
  return newLayer;
}

CeLayerManager.prototype.GetComponentLayer = function (name) {
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    var compLayer = this.mMetalLayers[i].GetComponentLayer(name);
    if (compLayer != null)
      return compLayer;
  }
  return null;
}

CeLayerManager.prototype.GetAllLayerComponents = function () {
  let compList = [];
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    var compLayer = this.mMetalLayers[i].GetComponentLayer();
    if (compLayer != null) {
      const components = compLayer.GetComponents() || [];
      compList.push(...components)
    }
  }
  return compList;
}

CeLayerManager.prototype.GetMetalCompLayer = function (onMetal, creatIt) {
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    if (onMetal == this.mMetalLayers[i].GetName())
      return this.mMetalLayers[i].GetComponentLayer();
  }
  if (creatIt == true) {
    return this.AddComponentLayer("Component_layer", onMetal);
  }
  return null;
}

// TODO - this function is not working correctly due to the construction of metal layers at
//        loading stage...
CeLayerManager.prototype.LayerNameToID = function (name) {
  return this.mLayerIDNameMap.getKey(name);
}

CeLayerManager.prototype.LayerIDToName = function (layerID, isRealKey = false) {
  return this.mLayerIDNameMap.getValue(layerID, isRealKey);
}

CeLayerManager.prototype.GetLayerType = function (lyrName) {
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    if (lyrName == this.mMetalLayers[i].GetName())
      return this.mMetalLayers[i].GetType();
    var lyrType = this.mMetalLayers[i].GetSubLayerType(lyrName);
    if (lyrType != null)
      return lyrType;
  }
  return null;
}

CeLayerManager.prototype.GetLayerObject = function (lyrName) {
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    if (lyrName == this.mMetalLayers[i].GetName())
      return this.mMetalLayers[i];
    var lyrObj = this.mMetalLayers[i].GetSubLayerObject(lyrName);
    if (lyrObj != null)
      return lyrObj;
  }
  return null;
}

CeLayerManager.prototype.GetLogicLayer = function (lyrName, metalLayer) {
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    if (metalLayer != null && metalLayer != undefined &&
      metalLayer == this.mMetalLayers[i].GetName())
      return this.mMetalLayers[i].GetLogicLayer(lyrName);

    var logicLyr = this.mMetalLayers[i].GetLogicLayer(lyrName);
    if (logicLyr != null)
      return logicLyr;
  }
  return null;
}

CeLayerManager.prototype.GetDocumentLayer = function (lyrName) {
  for (var i = 0; i < this.mDocumentLayers.length; i++) {
    if ((lyrName == this.mDocumentLayers[i].GetName()) == false)
      return this.mDocumentLayers[i];
  }
  return null;
}

CeLayerManager.prototype.GetAllMetalLayers = function () {
  var names = new StringList();
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    names.push(this.mMetalLayers[i].GetName());
  }
  return names;
}

CeLayerManager.prototype.GetAllLayerNames = function () {
  var names = new StringList();
  for (var i = 0; i < this.mMetalLayers.length; i++) {
    var metalLayer = this.mMetalLayers[i];
    if (i == 0) {
      var subLyrs = metalLayer.GetSubLayers(false);
      if (subLyrs.isEmpty() == false) {
        names.push(subLyrs);
      }
    }
    names.push(metalLayer.GetName());
    if (i == this.mMetalLayers.length - 1) {
      var subLyrs = metalLayer.GetSubLayers(true);
      if (subLyrs.isEmpty() == false) {
        names.push(subLyrs);
      }
    }
  }
  return names;
}

CeLayerManager.prototype.SetStackUp = function (layerStackup) {
  for (var i = 0; i < layerStackup.length; i++) {
    this.GetMetalLayer(layerStackup[i], true);
  }
}

CeLayerManager.prototype.ReadFromIODataNode = function (myBlock, layoutDB) {

  var metalLayers = myBlock.GetItemStringList("MetalLayers");
  // create the metal layer array first because the loading is asynchronous
  // TODO - this causes the name/ID map to be wrong!!!
  var layerArray = metalLayers.GetJSArrary();
  this.SetStackUp(layerArray);

  this.mNextLayerID = myBlock.GetItemLong("NextLayerID", this.mNextLayerID);
  var lyrNameIDMap = myBlock.GetItemStringList("LayerNameIDs");
  var lyrNameList = new StringList();
  for (var i = 0; i < lyrNameIDMap.size(); i += 2) {
    var name = lyrNameIDMap.get(i);
    var id = parseInt(lyrNameIDMap.get(i + 1));
    this.mLayerIDNameMap.put(id, name);
    lyrNameList.add(name);
  }

  // In case the plot setting is not available in the design file, initialized it here.
  // The settings won't be over-written because there is a protection in the initialization
  // code
  layoutDB.GetLayoutSettings().InitLayerSettings(lyrNameList);

  // At the beginning, we don't read the layer data until instructed by the Explorer.
  // This allows the Explorer to have more flexibility and can display the first layer
  // faster.
};

CeLayerManager.prototype.ReadMetalLayerData = function (layerName, layerFileContent, layoutDB) {

  var metalLayer = this.GetMetalLayer(layerName);

  if (!metalLayer)
    return false;

  var layerBlock = new CeIODataBlock("MetalLayer");
  if (!layerBlock.ReadBlockFromFile(layerFileContent))
    return false;

  return metalLayer.ReadFromIODataNode(layerBlock, layoutDB);

}; // CeLayerManager.prototype.ReadMetalLayerData

CeLayerManager.prototype.WriteIntoIODataNode = function (myBlock) {

  var metalLayers = this.GetAllMetalLayers();
  myBlock.AddDataItem("MetalLayers", metalLayers);
  myBlock.AddDataItem("NextLayerID", this.mNextLayerID);
  var lyrNameIDMap = new StringList();
  for (var i = 0; i < this.mLayerIDNameMap.size(); i++) {
    lyrNameIDMap.push(this.mLayerIDNameMap.getValue(i));
    lyrNameIDMap.push(this.mLayerIDNameMap.getKey(i));
  }
  myBlock.AddDataItem("LayerNameIDs", lyrNameIDMap);

  // var layerFolder = designPath + File.separator + "layers";
  // var dir = new File(layerFolder);
  // if (!dir.exists()) {
  //     dir.mkdir();
  // }

  // for(var i=0; i<this.mMetalLayers.size(); i++)
  //     var lyrFileName = layerFolder + File.separator + this.mMetalLayers[i].GetName() + ".lyr";
  //     var layerBlock = new CeIODataBlock("MetalLayer");
  //     this.mMetalLayers[i].WriteIntoIODataNode(layerBlock);
  //     layerBlock.WriteBlockToFile(lyrFileName);
  // }
};


export default CeLayerManager;