import React, { PureComponent, Fragment } from "react";
import { DownOutlined, QuestionCircleOutlined, RightOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { Input, Select, Tooltip, InputNumber } from "antd";
import { MDFLM_List } from "@/services/helper/constants";
import './index.css';

const Option = Select.Option;
class SimulationOption extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      advanced: false
    };
  }

  unitChange = (value, type) => {
    this.props.changeOptionsUnit(value, type);
  }

  selectClock = (unit) => {
    return (
      <Select value={unit} onChange={(value) => this.unitChange(value, 'clock')} className='pull-down-select' getPopupContainer={trigger => trigger.parentNode} popupMatchSelectWidth={false}>
        <Option key="K">KHz</Option>
        <Option key="M">MHz</Option>
        <Option key="G">GHz</Option>
      </Select>
    )
  }

  selectTimeStep = (unit) => {
    return (
      <Select
        value={unit}
        onChange={(value) => this.unitChange(value, 'timeStep')}
        popupMatchSelectWidth={false}
        className='pull-down-select'
        popupClassName="pull-down-select-dropdown"
        getPopupContainer={() => document.getElementById("root")}>
        <Option key="m">ms</Option>
        <Option key="u">us</Option>
        <Option key="n">ns</Option>
        <Option key="p">ps</Option>
      </Select>
    )
  }

  /*   selectSlewRate = (unit) => {
      return (
        <Select value={unit} onChange={(value) => this.unitChange(value, 'slewRate')} className='pull-down-select' getPopupContainer={trigger => trigger.parentNode}>
          <Option key="u">us</Option>
          <Option key="n">ns</Option>
          <Option key="p">ps</Option>
        </Select>
      )
    } */

  openSelect = (e) => {
    e.stopPropagation();
    this.setState({
      open: true
    })
  }

  closeSelect = (e) => {
    e.stopPropagation();
    this.setState({
      open: false
    })
    this.props.saveOption(e, 'ngspiceMDFLM');
  }

  selectChange = (value) => {
    this.setState({
      open: false
    })
    this.props.saveOption(value.replace('ps', ''), 'ngspiceMDFLMSelect');
  }

  changeAdvanced = (e) => {
    e.stopPropagation();
    const newAdvanced = !this.state.advanced;
    this.setState({
      advanced: newAdvanced
    })
  }

  selectSimulate = (value) => {
    this.setState({
      open: false
    })
    this.props.handleChange(value, 'simulate');
  }

  getAdvancedSetting = () => {
    const { config: { fixTimeStep = false } } = this.props;
    const Icon = fixTimeStep ? PushpinFilled : PushpinOutlined
    return (
      <Icon
        title="Fix the Time step"
        onClick={(e) => this.props.changeSwitch(e, !fixTimeStep)}
        className={"pintopin-simulation-config-setting"}
      />
    );
  }

  render() {
    const { clock = "", cycles = "", timeStep = "", /* slewRate = "", */ simulate = 'Default', ngspiceMDFLM = '30ps', hspiceCores = 2 } = this.props.config;
    const { handleChange, handleKeyDown, saveOption, error } = this.props;
    const { open, advanced } = this.state;
    return (
      <Fragment>
        <div className='pintopin-simulation-config-block'>
          <div className='pintopin-simulation-config-item'>
            <label className="pintopin-simulation-config-name">Clock</label>
            <Input
              key="Clock"
              className='pintopin-simulation-config-value'
              value={clock && clock.value ? clock.value : ""}
              onChange={e => handleChange(e, "clock")}
              onKeyDown={e => handleKeyDown(e, "clock")}
              onBlur={(e) => saveOption(e, "clock")}
              addonAfter={this.selectClock(clock && clock.unit ? clock.unit : "M")}
            />
          </div>
          <div className='pintopin-simulation-config-item'>
            <label className="pintopin-simulation-config-name">Time Step</label>
            <Input
              key="Time Step"
              className='pintopin-simulation-config-value pintopin-simulation-config-time-step-value'
              value={timeStep && timeStep.value ? timeStep.value : ""}
              onChange={e => handleChange(e, "timeStep")}
              onKeyDown={e => handleKeyDown(e, "timeStep")}
              onBlur={(e) => saveOption(e, "timeStep")}
              addonAfter={this.selectTimeStep(timeStep && timeStep.unit ? timeStep.unit : "n")}
            />
            {this.getAdvancedSetting()}
          </div>
          {  /*  <div className='simulation-config-item width-25'>
            <label className="simulation-config-name">Slew Rate</label>
            <Input
              key="Slew Rate"
              className="verify-setup-input-select"
              value={slewRate && slewRate.value ? slewRate.value : slewRate}
              onChange={e => handleChange(e, "slewRate")}
              onKeyDown={e => handleKeyDown(e, "slewRate")}
              onBlur={() => saveOption("slewRate")}
              addonAfter={this.selectSlewRate(slewRate && slewRate.unit ? slewRate.unit : "n")}
            />
          </div> */}
          <div className='pintopin-simulation-config-item'>
            <label className="pintopin-simulation-config-name">Cycles</label>
            <Input
              key="Cycles"
              className='pintopin-simulation-config-value'
              value={cycles}
              onChange={e => handleChange(e, "cycles")}
              onKeyDown={e => handleKeyDown(e, "cycles")}
              onBlur={(e) => saveOption(e, "cycles")}
            />
          </div>
          <div className='pintopin-simulation-config-item'>
            <label className="pintopin-simulation-config-name">Simulator</label>
            <Select value={simulate} onChange={(value) => this.selectSimulate(value)} getPopupContainer={trigger => trigger.parentNode} className='pintopin-simulation-config-value'>
              <Option key="Default">Aurora NGSPICE</Option>
              <Option key="HSPICE">HSPICE</Option>
            </Select>
          </div>
          {simulate === "Default" && <div className="pintopin-simulation-config-advanced">
            <div className="pintopin-simulation-config-advanced-title" onClick={(e) => this.changeAdvanced(e)}>
              {advanced ? <DownOutlined /> : <RightOutlined />}
              <span>Advanced</span>
            </div>
            {advanced && <div className='pintopin-simulation-config-item'>
              <label className="pintopin-simulation-config-advanced-name">Maximum Delay For Lumped Model</label>
              <div className='pintopin-simulation-option-mdlfm-content'>
                <Input
                  key="ngspiceMDFLM"
                  value={ngspiceMDFLM.replace('ps', '')}
                  addonAfter='ps'
                  className='pintopin-simulation-mdflm-input'
                  onFocus={(e) => this.openSelect(e)}
                  onChange={(e) => handleChange(e, "ngspiceMDFLM")}
                  onKeyDown={(e) => handleKeyDown(e, "ngspiceMDFLM")}
                  onBlur={(e) => this.closeSelect(e)}
                />
                <Select
                  onChange={(value) => this.selectChange(value)}
                  className='pintopin-simulation-options-input-select pintopin-simulation-config-value'
                  open={open}
                  value={clock && clock.value ? clock.value : ""}
                  popupClassName='pintopin-simulation-options-select'
                  suffixIcon={null}>
                  {MDFLM_List.map(item => (
                    <Option key={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </div>}
          </div>}
          {(simulate === "HSPICE") &&
            <div className="pintopin-simulation-config-advanced pintopin-simulation-config-hspice-advanced">
              <div className='pintopin-simulation-config-item'>
                <label className="pintopin-simulation-config-advanced-name" >
                  Number of Cores
                </label>
                <Tooltip title="Every 2 cores require one HSPICE license" overlayClassName='icon-tooltip'>
                  <QuestionCircleOutlined className='cores-help-icon' />
                </Tooltip>
                <div className='pintopin-simulation-config-hpice-content'>
                  <InputNumber
                    key="hspiceCores"
                    value={hspiceCores}
                    precision={0}
                    className='pintopin-simulation-config-value'
                    onChange={(e) => handleChange(e, "hspiceCores")}
                    onKeyDown={(e) => handleKeyDown(e, "hspiceCores")}
                    onBlur={(e) => saveOption(e, "hspiceCores")}
                  />
                </div>
              </div>
            </div>}
          {error ? <span className='pintopin-simulation-config-error-msg'>{error}</span> : null}
        </div>
      </Fragment>
    );
  }
}

export default SimulationOption;
