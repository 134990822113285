import React, { Fragment } from 'react';
import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  Loading3QuartersOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { Tooltip, Checkbox, Popover, Tag, Dropdown } from 'antd';
import UploadPCB from '../../../pages/Andes_v2/AndesSider/uploadPCB';
import {
  CPHY_EYE_MASK,
  EYE_MASK,
  MICROSTRIP,
  STRIPLINE,
} from '../../../constants/libraryConstants';
import {
  LIBRARY,
  PROJECTS,
  TRASH,
  ADD,
  DELETE,
  CLEAN,
  LIBRARY_DATA,
  PCBS,
  UPLOAD_PCB,
  PCB,
  MY_LIBRARY,
  END_TO_END_CHANNELS,
  PROJECT_CREATE,
  PROJECT,
  PCB_CHANNEL,
  VERIFY,
  PCB_PRE_LAYOUT,
  SIMULATE,
  END_TO_END_CHANNEL,
  STATUS,
  REPLACE_PCB,
  RENAME,
  COPY,
  LIBRARY_OPEN,
  LIBRARY_FILE,
  DOWNLOAD,
  COPYING_PROJECT,
  ADD_PCB_CHANNEL,
  LIBRARY_FOLDER,
  LIBRARY_FOLDER_FILE,
  PACKAGE,
  PACKAGES,
  IMPORTEXPORT,
  MORE,
  EXPERIMENTS,
  EXPERIMENTS_CREATE
} from '@/constants/treeConstants';
import {
  PRE_LAYOUT
} from '@/constants/designVendor'
import { VERIFY_RUNNING, WAITING } from '../../../constants/verificationStatus';
import { DESIGN_FAILED } from '../../../constants/designCategory'
import { getSimulationIconInfo, getVerifyCheckAllChecked } from './simulationHelper';
import { getVerificationTreeStatus } from '../../workflow/workflowHelper';
import CreateItem from '../../../components/CreateItem';
import projectConstructor from '../project/projectConstructor';
import channelConstructor from '../channel/channelConstructor';
import { COPYING, COPY_FAILED } from '../../../constants/copyStatus';
import { isEndToEndChildrenChannel, isPreLayout, isPackagePCB } from './publicHelper';
import { CPHY, DP, ETHERNET, GENERIC, HDMI, MIPI, PCIE, USB } from '../../PCBHelper/constants';
import endToEndChannelConstructor from '../endToEndChannel/endToEndChannelConstructor';
import projectDesigns from '@/services/helper/projectDesigns';
import { strDelimited } from '@/services/helper/split';
import designConstructor from '../../helper/designConstructor';
import IconCollection from '@/components/TreeIconCollection';
import sweepConstructor from '../sweep/sweepConstructor';

const IconKey = {
  1: [ADD],
  2: [CLEAN],
  3: [DELETE],
  4: [UPLOAD_PCB, SIMULATE],
  5: [/* ADD, */ DELETE, /* VERIFY, */ REPLACE_PCB],
  6: [DELETE, VERIFY, STATUS, RENAME, COPY],
  7: [RENAME, DELETE, COPY],
  8: [DELETE, DOWNLOAD],
  9: [DELETE, VERIFY, STATUS, COPY, RENAME],
  10: [ADD, /* VERIFY, */ SIMULATE],
  11: [UPLOAD_PCB],
  12: [DELETE, RENAME, STATUS],
  13: [IMPORTEXPORT],
  14: [ADD, IMPORTEXPORT],
  15: [VERIFY, STATUS, MORE],
  0: []
};

function getIconKey(dataType, vendor, designId, type) {
  // because only in the PCB type for the prelayout time rendering
  if (vendor === PRE_LAYOUT) {
    return 12
  }
  switch (dataType) {
    case LIBRARY:
      return 1;
    case PROJECTS:
      return 14;
    case TRASH:
      return 2;
    case LIBRARY_DATA:
    case PACKAGE:
      return 3;
    case PCBS:
      return 4;
    case PCB:
      return 5;
    case PCB_CHANNEL:
      return isPreLayout(designId) || isPackagePCB(designId) || type === CPHY ? 6 : 15;
    case PROJECT:
      return 7;
    case LIBRARY_FILE:
    case LIBRARY_FOLDER:
      return 8;
    case END_TO_END_CHANNEL:
      return 9;
    case END_TO_END_CHANNELS:
      return 10;
    case PACKAGES:
      return 11;
    case MY_LIBRARY:
      return 13
    case EXPERIMENTS:
      return 12;
    default: return 0;
  }
}

function getIconCollectionKey({ item, type }) {
  switch (type) {
    case PCB_CHANNEL:
      if (isEndToEndChildrenChannel(item)) {
        return;
      }
      return [EXPERIMENTS, RENAME, COPY, DELETE];
    default:
      break;
  }
}

function getIconCollection({ item, keys, actions }) {
  const { renameClick, delItem, copyClick, _deleteClick, _addItem } = actions;
  return keys && keys.map(key => {
    switch (key) {
      case RENAME:
        let renameType = null;
        if (item.dataType === PCB_CHANNEL) {
          renameType = "Channel"
        }
        return { title: "Rename", icon: 'edit', func: (e) => renameClick(e, item, renameType) };
      case DELETE:
        const delType = getType(item.dataType);
        return { title: "Delete", icon: 'close', func: (e) => delType ? _deleteClick(e, item) : delItem(e, item) };
      case COPY:
        const copyType = getCopyType(item.dataType);
        return { title: "Copy", icon: 'copy', func: (e) => copyClick(e, item, copyType) };
      case EXPERIMENTS:
        return { title: "Sweep", icon: 'experiment', func: (e) => _addItem(e, item, 'create'), tooltip: "Create sweep based on the current Channel" };
      default: return {};
    }
  })
}

function getType(dataType) {
  switch (dataType) {
    case LIBRARY_DATA:
      return LIBRARY_DATA;
    case LIBRARY_FILE:
      return LIBRARY_FILE;
    case LIBRARY_FOLDER:
      return LIBRARY_FOLDER;
    case PCB:
      return PCB;
    case PROJECT:
      return PROJECT;
    case PCB_CHANNEL:
      return PCB_CHANNEL;
    case PCB_PRE_LAYOUT:
      return PCB_PRE_LAYOUT;
    case END_TO_END_CHANNEL:
      return END_TO_END_CHANNEL;
    case PACKAGE:
      return PACKAGE;
    case EXPERIMENTS:
      return EXPERIMENTS;
    default: return false;
  }
}

function outTitle({ item, actions, props }) {
  const Keys = IconKey[getIconKey(item.dataType)];

  const className = Keys.includes(VERIFY) ? "aurora-tree-checked-box-span" : "";
  return <span>
    <span className={`out-title-color ${item.key} ${className}`}>{item.name}</span>
    <Fragment>{Keys.map((key, index) => { return getIcon({ key, item, actions, props }) })}</Fragment>
  </span>
}

function defaultTitle({ item, actions, props }) {
  const Keys = IconKey[getIconKey(item.dataType, item.vendor)];
  const className = Keys.includes(VERIFY) ? "aurora-tree-checked-box-span" : "";
  const { copyProjectLoadingId, copyChannelLoadingId, copyEndToEndChannelLoadingId } = actions;
  const copyClassName = [copyProjectLoadingId, copyChannelLoadingId, copyEndToEndChannelLoadingId].includes(item.id) ? "aurora-copy-loading-tree" : "";
  const failedClassName = item.category === DESIGN_FAILED ? "andes-v2-tootip-failed" : null;
  return <span>
    <Tooltip
      key={item.key}
      placement="topLeft"
      mouseEnterDelay={0.8}
      mouseLeaveDelay={0}
      title={item.name}
      className={`andes-v2-tooltip-span ${item.key} ${className} ${item.nodeClass} ${copyClassName} ${failedClassName}`}
      overlayClassName='aurora-tooltip'
    >{item.name}
    </Tooltip>
    <Fragment>{Keys.map((key, index) => { return getIcon({ key, item, actions, props }) })}</Fragment>
  </span>
}

function libraryTitle({ item, actions, props }) {
  let Keys = IconKey[getIconKey(item.dataType)];
  if (!item.deletable) {
    Keys = Keys.filter(item => item !== DELETE)
  }
  if (item.type === CPHY_EYE_MASK) {
    Keys = Keys.filter(item => item !== DOWNLOAD)
  }
  const displayType = getDisplayLibType(item.libraryType);
  const { downloadLibraryInfo, downloadProgress } = actions;
  const { tagName, color, typeWidth } = getLibraryTypeTagInfo(item.type);

  return <span
    className={downloadLibraryInfo && downloadLibraryInfo.id === item.id && item.dataType !== LIBRARY_FOLDER_FILE ?
      (downloadProgress === 100 ? 'download-progress-bar download-progress-bar-success' : 'download-progress-bar')
      : ""}
    style={{ "--percent": downloadProgress }}>
    <div>
      <Tooltip
        key={item.key}
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={item.name}
        className={'aurora-icon-color aurora-tooltip-span'}
        overlayClassName='aurora-tooltip'
        onClick={(e) => actions.editItem(e, item)}
      >
        {displayType ? `${item.name} - ${displayType}` : item.name}
        {tagName ? <Tag
          color={color}
          className={`aurora-library-type-tag aurora-tag-${color}`}
          style={{ width: typeWidth }}
        >{tagName}</Tag> : ""}
      </Tooltip>
      <Fragment>{Keys.map((key) => { return getIcon({ key, item, actions, props }) })}</Fragment>
    </div>
  </span>
}

function verificationTitle({ item, actions, props }) {
  const { renameEndToEndChannelInfo, saveRename, cancelRename } = actions;
  if (renameEndToEndChannelInfo && renameEndToEndChannelInfo.id === item.id && item.dataType === END_TO_END_CHANNEL) {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => saveRename(data, "EndToEndChannel")}
      projectList={endToEndChannelConstructor.getEndToEndChannelValues().filter(it => it.id !== item.id)}
      cancelCreate={() => cancelRename("EndToEndChannel")}
    />
  }
  const Keys = IconKey[getIconKey(item.dataType, '', item.designId, item.type)];
  const className = Keys.includes(VERIFY) ? "aurora-tree-checked-box-span" : "";
  let displayName = item.name, displayType = item.type;
  if (isEndToEndChildrenChannel(item)) {
    displayName = item.designName;
    displayType = "";
  }
  const color = getSerdesTypeTagColor(displayType);

  return <span>
    <Tooltip
      key={item.key}
      placement="topLeft"
      mouseEnterDelay={0.8}
      mouseLeaveDelay={0}
      title={<Fragment><span>{displayName}</span></Fragment>}
      className={`andes-v2-tooltip-span ${item.key} ${className} ${item.nodeClass}`}
      overlayClassName='aurora-tooltip'
    >
      <span
        className="aurora-tree-verification-span"
        style={{ width: `calc(100% - ${item.typeWidth}px )` }}
      >{displayName}</span>
      {displayType ? <Tag
        color={color}
        className={`aurora-interface-type-tag aurora-tag-${color}`}
        style={{ width: item.typeWidth }}
      >{displayType}</Tag> : ""}
    </Tooltip>
    <Fragment>
      {/*  <Dropdown
        trigger={['click']}
        onClick={e => { e.stopPropagation(); }}
        overlayClassName="andes-tree-menu"
        menu={verificationIconMenu({ Keys, item, actions, props })}>
        <Icon type="bars" className='aurora-tree-menu-icon' />
      </Dropdown> */}
      {/* {getIcon({ key: VERIFY, item, actions, props })}
      {getIcon({ key: STATUS, item, actions, props })} */}
      {Keys.map((key, index) => { return getIcon({ key, item, actions, props }) })} </Fragment>
  </span >
}

function getSerdesTypeTagColor(type) {
  switch (type) {
    case PCIE:
      return "blue";
    case HDMI:
      return "magenta";
    case DP:
      return "volcano";
    case ETHERNET:
      return "brown";
    case MIPI:
      return "green";
    case USB:
      return "cyan";
    case GENERIC:
      return "geekblue";
    case CPHY:
      return "purple"
    default: return "blue";
  }
}

function PCBChannelTitle({ item, actions, props }) {
  const { renameChannelInfo, saveRename, cancelRename } = actions;
  if (renameChannelInfo && renameChannelInfo.id === item.id) {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => saveRename(data, "Channel")}
      projectList={channelConstructor.getChannelValues(item.designId).filter(it => it.id !== item.id)}
      cancelCreate={() => cancelRename("Channel")}
    />
  } else {
    return verificationTitle({ item, actions, props });
  }
}

function getDisplayLibType(libType) {
  switch (libType) {
    case MICROSTRIP:
      return "Microstrip";
    case STRIPLINE:
      return "Stripline";
    default: return null;
  }
}

function getLibraryTypeTagInfo(libraryType) {
  switch (libraryType) {
    case EYE_MASK:
      return { tagName: "Generic", color: "blue", typeWidth: 50 };
    case CPHY_EYE_MASK:
      return { tagName: "CPHY", color: "purple", typeWidth: 50 };
    default: return {};
  }
}

function libraryMenu(item, _addItem) {
  return [
    { key: 'create', onClick: (e) => _addItem(e, item, 'create'), label: 'Create Eye Mask' },
    { key: 'upload', onClick: (e) => _addItem(e, item, 'upload'), label: 'Upload file' },
    { key: 'create_cphy', onClick: (e) => _addItem(e, item, 'create_cphy'), label: 'Create CPHY Eye Mask' }
  ]
}

function getIcon({ key, item, actions, props, className }) {
  const { delItem, _addItem, _deleteClick, spanClick, changeSimulateAll, changeSimulateCheck, startSimulation, copyItem } = actions;
  //if project is copying or copy failed, pcb and end to end channels return no icon
  if ([PCBS, END_TO_END_CHANNELS].includes(item.dataType) && [COPYING, COPY_FAILED].includes(item.category)) {
    return;
  }

  switch (key) {
    case ADD:
      let disabled = false;
      if (item.dataType === END_TO_END_CHANNELS) {
        const projectId = strDelimited(item.key, "-", { returnIndex: 1 });
        const designs = projectDesigns.getAvailableDesigns(projectId);
        disabled = designs.length < 2;
      }
      if (item.dataType === LIBRARY && item.key === EYE_MASK) {
        return (
          <Dropdown menu={{ items: libraryMenu(item, _addItem), className: "upload-dropdown-button" }} trigger={['click']} key={key}>
            <PlusSquareOutlined
              className='aurora-tree-add-icon'
              id={`sierra-add-library-${item.key}-icon`}
              onClick={(e) => spanClick(e)} />
          </Dropdown>
        );
      }
      if (item.category !== DESIGN_FAILED) {
        return (
          <Tooltip
            key={key}
            title={disabled ? "There are at least two designs to create a multi-pcb channel." : null}
            overlayClassName='aurora-tooltip'
          >
            <PlusSquareOutlined
              className={`aurora-tree-add-icon ${item.addIconClass} ${className} ${disabled ? "aurora-icon-disabled" : ""}`}
              id={`andes-add-${item.key}-icon`}
              key={`${item.key}-add`}
              onClick={(e) => !disabled ? _addItem(e, item) : null} />
          </Tooltip>
        );
      } break;
    case DELETE:
      const type = getType(item.dataType);
      if (isEndToEndChildrenChannel(item)) {
        return;
      }
      return (
        <CloseOutlined
          key={`${item.key}-delete`}
          className={`aurora-tree-del-icon ${className}`}
          onClick={(e) => type ? _deleteClick(e, item) : delItem(e, item)} />
      );
    case CLEAN:
      return (
        <DeleteOutlined
          key={item.key}
          className='aurora-tree-del-icon'
          onClick={(e) => _deleteClick(e, item)} />
      );
    case UPLOAD_PCB:
    case REPLACE_PCB:
      return <UploadPCB key={item.key} data={item} type={key} />;
    case VERIFY:
      return verifyCheckBox({ itemData: item, key, changeSimulateAll, changeSimulateCheck, spanClick, props });
    case SIMULATE:
      return simulationIcon({ itemData: item, key, startSimulation, props, spanClick });
    case STATUS:
      let _className = className;
      if (!isPreLayout(item.designId) && !isPackagePCB(item.designId) && item.type !== CPHY) {
        _className = 'aurora-not-prelayout-status-icon';
      }
      if (item.dataType === EXPERIMENTS) {
        _className = 'aurora-experiment-status-icon'
      }
      return verificationStatusIcon({ itemData: item, key, actions, spanClick, className: _className });
    case RENAME:
      return renameRender({ key, item, props, actions, className });
    case COPY:
    case COPYING_PROJECT:
      return copyRender({ key, item, props, actions, className });
    case DOWNLOAD:
      return downloadRender({ key, item, props, actions });
    case COPY_FAILED:
      return copyFailedIcon({ key, item, props, actions });
    case IMPORTEXPORT:
      return importOrExportRender({ key, item, props, actions });
    case MORE:
      const collectionKeys = getIconCollectionKey({ item, type: item.dataType });
      if (!collectionKeys) return;
      const collectionMenu = getIconCollection({ item, keys: collectionKeys, actions });
      const moreType = getCopyType(item.dataType);
      const copyInfo = actions[`copy${moreType}Info`];
      return <Popover
        key={key}
        content={copyInfo && copyInfo.id === item.id ? copyItem(item, moreType) : null}
        trigger="click"
        onClick={(e) => spanClick(e)}
        open={copyInfo && copyInfo.id === item.id ? true : false}
        overlayClassName='sierra-copy-popover'
      >
        <IconCollection
          key={key}
          menu={collectionMenu}
          data={item}
        />
      </Popover>
    default: return;
  }
}

function copyFailedIcon({ key }) {
  return (
    <Tooltip
      key={key}
      title={"Current project copy failed!"}
      overlayClassName='aurora-tooltip  aurora-design-tooltip'>
      <ExclamationCircleOutlined className="design-failed-icon" />
    </Tooltip>
  );
}

function importOrExportRender({ key, item, props, actions }) {
  const { importExportFolder, showImportExportModel } = actions
  // uploading: {key: string,status: boolean}
  const { uploading } = props
  return uploading && uploading.key === item.key && uploading.status
    ?
    <Tooltip key={key} title='Files are being imported or exported' overlayClassName='aurora-tooltip'>
      <Loading3QuartersOutlined
        spin
        onClick={(e) => { e && e.stopPropagation(); showImportExportModel(true) }}
        key={key}
        className={item.dataType === MY_LIBRARY ? `aurora-tree-import-export-loading-icon aurora-tree-import-export-library-loading` : `aurora-tree-import-export-loading-icon aurora-tree-import-export-project-loading`} />
    </Tooltip>
    :
    <span
      key={key}
      title='Import Or Export'
      onClick={(e) => importExportFolder(e, item)}
      className={item.dataType === MY_LIBRARY ? `iconfont icon-import-export aurora-tree-import-export-library-icon` : `iconfont icon-import-export aurora-tree-import-export-project-icon`}
      style={{ color: '#1890ff' }}
    ></span>;
}

function downloadRender({ key, item, actions }) {
  const style = !item.deletable ? { marginRight: 0 } : { marginRight: 22 };
  const { downloadLibraryInfo, downloadFile } = actions;
  return (!downloadLibraryInfo || downloadLibraryInfo.id !== item.id) ?
    <Tooltip key={key} title='Download' overlayClassName='aurora-tooltip'>
      <DownloadOutlined
        style={style}
        className="aurora-tree-download-icon"
        onClick={(e) => downloadFile(e, item)} />
    </Tooltip> : null;
}

function getCopyType(dataType) {
  switch (dataType) {
    case PROJECT:
      return 'Project';
    case PCB_CHANNEL:
      return "Channel";
    case END_TO_END_CHANNEL:
      return "EndToEndChannel";
    default: return;
  }
}

function copyRender({
  key,
  item,
  actions,
  className
}) {
  if (isEndToEndChildrenChannel(item)) {
    return;
  }
  const dataType = item.dataType;
  const type = getCopyType(dataType);
  const { copyItem, spanClick, copyClick } = actions;
  const copyInfo = actions[`copy${type}Info`];
  const copyLoadingId = actions[`copy${type}LoadingId`];
  const typeText = type === "EndToEndChannel" ? "Multi-PCB Channel" : type;
  const copying = key === COPYING_PROJECT;

  return (copyLoadingId && copyLoadingId === item.id) || copying ?
    <Tooltip key={key} title={copying ? "Current project is copying..." : `Copying current ${typeText}`} overlayClassName='aurora-tooltip'>
      <span
        key={key}
        onClick={(e) => spanClick(e)}
        className={`iconfont icon-loading3 aurora-tree-loading-icon ${copying ? "aurora-tree-copy-loading-icon" : ""}`}
        style={{ color: '#1890ff' }}
      ></span>
    </Tooltip>
    :
    <Popover
      key={key}
      content={copyInfo && copyInfo.id === item.id ? copyItem(item, type) : null}
      trigger="click"
      onClick={(e) => spanClick(e)}
      open={copyInfo && copyInfo.id === item.id ? true : false}
      overlayClassName='aurora-copy-popover'
    >
      <Tooltip key={key} title={`Copy ${typeText}`} overlayClassName='aurora-tooltip'>
        <CopyOutlined
          className={`aurora-tree-copy-icon ${className}`}
          key={key}
          onClick={(e) => copyClick(e, item, type)} />
      </Tooltip>
    </Popover>;
}

function renameRender({ key, item, actions, className }) {
  if (isEndToEndChildrenChannel(item)) {
    return;
  }
  const { renameClick } = actions;
  let type = null;
  let _className = null;
  if (item.dataType === PROJECT) {
    type = "Project"
  } else if (item.dataType === PCB_CHANNEL) {
    type = "Channel"
  } else if (item.vendor === PRE_LAYOUT) {
    _className = "aurora-tree-prelayout-rename-icon"
    type = "PreLayout"
  } else if (item.dataType === END_TO_END_CHANNEL) {
    type = 'EndToEndChannel'
  } else if (item.dataType === EXPERIMENTS) {
    type = "Sweep"
    className = "aurora-tree-sweep-rename-icon"
  }
  return (
    <Tooltip key={key} title='Rename' overlayClassName='aurora-tooltip'>
      <EditOutlined
        className={`${_className} aurora-tree-project-rename-icon ${className}`}
        key={key}
        onClick={(e) => renameClick(e, item, type)} />
    </Tooltip>
  );
}

function verificationStatusIcon({ itemData, key, actions, spanClick, className }) {
  const type = getCopyType(itemData.dataType);
  const copyLoadingId = actions[`copy${type}LoadingId`];
  if (copyLoadingId && copyLoadingId === itemData.id) {
    return;
  }
  let { verificationStatus, color, msg } = getVerificationTreeStatus(itemData.status, itemData.readyForSim, itemData);
  return (
    <Tooltip
      key={key}
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0}
      title={msg}
      overlayClassName='aurora-tooltip'
    >
      {verificationStatus === 'check-circle' ?
        <CheckCircleOutlined
          key={key}
          onClick={(e) => spanClick(e)}
          className={`aurora-status-icon andes-v2-status-check-icon ${className}`}
          style={{ color: color }}
        />
        :
        <span
          key={key}
          onClick={(e) => spanClick(e)}
          className={`iconfont icon-${verificationStatus} aurora-status-icon ${className}`}
          style={{ color: color }}
        ></span>}
    </Tooltip>
  );
}

function channelSimulationMenu(startSimulation, itemData, simulateKeys) {
  const simulateList = itemData.dataType === PCBS ? channelConstructor.getList(simulateKeys) :
    endToEndChannelConstructor.getList(simulateKeys);
  return [
    { key: 'Modeling', label: 'Modeling', onClick: (e) => startSimulation(e, itemData, 'modeling') },
    { key: 'Compliance', label: 'Compliance Simulate', onClick: (e) => startSimulation(e, itemData, 'compliance'), disabled: simulateList.find(item => item && item.type !== PCIE) ? true : false },
    { key: 'Simulate', label: 'Simulate', onClick: (e) => startSimulation(e, itemData, 'simulate'), disabled: simulateList.find(item => item && ![PCIE, HDMI, GENERIC].includes(item.type)) ? true : false }
  ]
}

function simulationIcon({ itemData, key, props, startSimulation, spanClick }) {
  const { simulateKeys = [], tooltipMsg = "" } = getSimulationIconInfo(itemData, props);
  return (
    <Tooltip
      key={key}
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0}
      title={tooltipMsg}
      overlayClassName='aurora-tooltip'
    >
      <Dropdown
        menu={{ items: channelSimulationMenu(startSimulation, itemData, simulateKeys) }}
        disabled={simulateKeys.length === 0}
      >
        <PlayCircleOutlined
          className={simulateKeys.length > 0 ? 'aurora-tree-simulate-icon' : 'aurora-tree-simulate-icon simulate-icon-disabled'}
          onClick={(e) => spanClick(e)} />
      </Dropdown>
      {/*   <Icon
        type="play-circle"
        className={simulateKeys.length > 0 ? 'aurora-tree-simulate-icon' : 'aurora-tree-simulate-icon simulate-icon-disabled'}
        onClick={(e) => simulateKeys.length > 0 ? startSimulation(e, itemData) : null}
      />  */}
    </Tooltip>
  );
}

function getVerifyDisabled(itemData) {
  return (itemData.status === VERIFY_RUNNING || itemData.status === WAITING || itemData.simStatus)
    ? true : false
}

function verifyCheckBox({ key, itemData, spanClick, changeSimulateAll, changeSimulateCheck, props }) {
  if (isEndToEndChildrenChannel(itemData)) {
    return;
  }

  const { pcbChannelSimSelectKeys, endToEndSimSelectKeys } = props;
  const keys = [PCB_CHANNEL, PCB, PCB_PRE_LAYOUT].includes(itemData.dataType) ? pcbChannelSimSelectKeys : endToEndSimSelectKeys;

  if ([PCB_CHANNEL, END_TO_END_CHANNEL].includes(itemData.dataType)) {
    const disabled = getVerifyDisabled(itemData)
    return <span
      key={key}
      onClick={(e) => spanClick(e)}
      className='ant-checkbox-wrapper-box'
    >
      <Checkbox
        key={key}
        disabled={disabled}
        checked={disabled ? false : keys.includes(itemData.id)}
        onChange={(e) => !disabled ? changeSimulateCheck(e, itemData) : null}
      />
    </span>
  }

  if ([PCB, PCB_PRE_LAYOUT, END_TO_END_CHANNELS].includes(itemData.dataType)) {
    const { checkedAll, indeterminate } = getVerifyCheckAllChecked(itemData, keys);
    return <span
      key={key}
      onClick={(e) => spanClick(e)}
    >
      <Checkbox
        key={key}
        checked={checkedAll}
        indeterminate={indeterminate}
        disabled={(itemData.dataType === PCB_PRE_LAYOUT)}
        onChange={(e) => changeSimulateAll(e, itemData)}
      />
    </span>
  }
}

function expreimentTitle({ item, actions, props }) {
  const { renameSweepInfo, saveRename, cancelRename } = actions;
  if (renameSweepInfo && renameSweepInfo.id === item.id) {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => saveRename(data, "Sweep")}
      projectList={sweepConstructor.getSweepValues(item.channelId).filter(it => it.id !== item.id)}
      cancelCreate={() => cancelRename("Sweep")}
    />
  }
  return defaultTitle({ item, actions, props });
}

function renderTitle(item, actions, props) {
  const { projectCreate, experimentCreate } = actions;
  // because only in the PCB type for the prelayout time rendering
  if (item.vendor === PRE_LAYOUT) {
    return preLayoutTitle({ item, actions, props })
  }
  switch (item.dataType) {
    case LIBRARY:
    case MY_LIBRARY:
    case PROJECTS:
    case TRASH:
    case PCBS:
    case END_TO_END_CHANNELS:
    case LIBRARY_OPEN:
    case PACKAGES:
      return outTitle({ item, actions, props });
    case LIBRARY_DATA:
    case LIBRARY_FILE:
    case LIBRARY_FOLDER:
    case LIBRARY_FOLDER_FILE:
      return libraryTitle({ item, actions, props });
    case PROJECT_CREATE:
      return projectCreate(item);
    case PCB_CHANNEL:
      return PCBChannelTitle({ item, actions, props });
    case END_TO_END_CHANNEL:
      return verificationTitle({ item, actions, props });
    case PROJECT:
      return projectTitle({ item, actions, props });
    case ADD_PCB_CHANNEL:
      return addInterfaceTitle({ item, actions, props });
    case EXPERIMENTS:
      return expreimentTitle({ item, actions, props })
    case EXPERIMENTS_CREATE:
      return experimentCreate(item);
    default:
      return defaultTitle({ item, actions, props });
  }
}

function addInterfaceTitle({ item, actions }) {
  return (
    <span className="aurora-add-interface-action" onClick={(e) => actions._addItem(e, item)}>
      <PlusOutlined className="aurora-add-interface-icon" />
      <span
        className="aurora-tree-verification-span"
      >{item.name}</span>
    </span>
  );
}

function projectTitle({ item, actions, props }) {
  const { renameProjectInfo, saveRename, cancelRename } = actions;
  const copyStatus = [COPYING, COPY_FAILED].includes(item.category);
  if (renameProjectInfo && renameProjectInfo.id === item.id) {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => saveRename(data, "Project")}
      projectList={projectConstructor.getProjectValues().filter(it => it.id !== item.id)}
      cancelCreate={() => cancelRename("Project")}
    />
  } else if (copyStatus) {
    return copyingProjectTitle({ item, actions, props });
  }
  return defaultTitle({ item, actions, props });
}

function preLayoutTitle({ item, actions, props }) {
  const { renamePreLayoutInfo, saveRename, cancelRename } = actions;
  if (renamePreLayoutInfo && renamePreLayoutInfo.id === item.id) {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => saveRename(data, "PreLayout")}
      projectList={designConstructor.getDesignValues().filter(it => it.id !== item.id && it.projectId === item.projectId)}
      cancelCreate={() => cancelRename("PreLayout")}
    />
  }
  return defaultTitle({ item, actions, props });
}

function copyingProjectTitle({ item, actions, props }) {
  let Keys = [], copyClassName = "", tooltipText = item.name, tooltipClassName = "";
  if (item.category === COPYING) {
    copyClassName = "aurora-copy-loading-tree";
    tooltipText = tooltipText + " - Current project is copying...";
    Keys = [COPYING_PROJECT, DELETE];
  } else {
    copyClassName = "andes-v2-tootip-failed";
    tooltipText = tooltipText + " - Current project copy failed.";
    Keys = [DELETE, COPY_FAILED];
    tooltipClassName = "aurora-design-tooltip";
  }

  return <span>
    <Tooltip
      key={item.key}
      placement="topLeft"
      mouseEnterDelay={0.8}
      mouseLeaveDelay={0}
      title={tooltipText}
      className={`andes-v2-tooltip-span ${item.key} ${item.nodeClass} ${copyClassName}`}
      overlayClassName={`aurora-tooltip ${tooltipClassName}`}
    >{item.name}
    </Tooltip>
    <Fragment>{Keys.map((key, index) => { return getIcon({ key, item, actions, props }) })}</Fragment>
  </span>
}

export {
  outTitle,
  defaultTitle,
  IconKey,
  getIconKey,
  libraryTitle,
  getType,
  getIcon,
  renderTitle
};