import CanvasObject from './CanvasObject';

var CanvasCircle = function () {
  CanvasObject.call(this);
  this.xc = 0;
  this.yc = 0;
  this.r = 0;
};

CanvasCircle.prototype = Object.create(CanvasObject.prototype);
CanvasCircle.prototype.constructor = CanvasCircle;

export default CanvasCircle;