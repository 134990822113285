import React, { Component, Fragment } from 'react';
import Table from '../EditableTable';
import Icon, {
  CloseOutlined,
  EyeOutlined,
  MonitorOutlined,
  PlusCircleOutlined,
  RedoOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import DelConfirm from '@/components/DelConfirm';
import DescDom from './portDesc';
import PortSetupPanel from './portSetupPanel';
import _ from 'lodash';
import '@/publicCss/style.css';
import './index.css';
import PlocSetupPanel from './plocSetupPanel';

const PortColumn = [
  {
    title: 'Port',
    dataIndex: 'port',
    width: '20%'
  },
  {
    title: 'Power Pins',
    dataIndex: 'powerPins',
    width: '30%'
  },
  {
    title: 'Reference Pins',
    dataIndex: 'referencePins',
    width: '30%'
  },
  {
    title: 'Target',
    dataIndex: 'target',
    width: '20%'
  }
]

const DCPortColumn = [{
  title: 'Group',
  dataIndex: 'port',
  width: '20%'
},
{
  title: 'Power Pins',
  dataIndex: 'powerPins',
  width: '30%'
},
{
  title: 'Reference Pins',
  dataIndex: 'referencePins',
  width: '30%'
},
{
  title: 'Current (A)',
  dataIndex: 'current',
  width: '20%'
}],
  NoTargetPortColumn = [
    {
      title: 'Port',
      dataIndex: 'port',
      width: '20%'
    },
    {
      title: 'Power Pins',
      dataIndex: 'powerPins',
      width: '40%'
    },
    {
      title: 'Reference Pins',
      dataIndex: 'referencePins',
      width: '40%'
    }
  ]

class PortSetup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mergeGroup: false,
      columnsUpdate: false,
      portSetupVisible: false,
      plocVisible: false,
      plocInfo: {}
    }
    this.columns = PortColumn;
  }

  componentDidMount() {
    this.initColumns();
    this.props.onRef && this.props.onRef(this);
  }

  initColumns = () => {
    const { DCLoad, notIncludeTarget } = this.props;

    this.columns = DCLoad ? DCPortColumn : (notIncludeTarget ? NoTargetPortColumn : PortColumn);

    this.columns[0].title = () => {
      const { tableData } = this.props;
      return (
        <div>{DCLoad ? "Group" : "Port"}
          {tableData && tableData.length ? <EyeOutlined
            className="port-setup-table-eye-icon"
            title='Display all pin groups'
            onClick={(e) => this.showAllGroups(e)} /> : null}
        </div>
      );
    }

    this.columns[0].render = (text, record) => {
      return <div className='port-setup-table-index' onClick={(e) => this.showGroup(e, record)}>
        {record.portName ? record.portName : text}
        <CloseOutlined title="Delete port" className="ports-table-delete-icon" onClick={(e) => this.deleteRow(e, record)} />
      </div>
    }

    this.columns[1].render = (text, record) => {
      return <span>{text.join(', ')}</span>
    }

    this.columns[1].onCell = (record) => {
      const { descData } = this.props;
      const pins = [...record.powerPins, ...descData[0].unused].sort();
      return {
        record,
        edit: 'aurora-select',
        options: pins,
        dataIndex: 'powerPins',
        selectMode: 'multiple',
        selectButtonTitle: "Select pins",
        allowStick: true,
        onChange: (list, record, setFieldsValue) => this.addPins(list, record, setFieldsValue, 'powerPins'),
        clearSelected: (value, record, setFieldsValue) => this.removePins(value, record, setFieldsValue, 'powerPins'),
        auroraSelectBlur: (record) => this.saveGroupPins(record, 'powerPins', 'PowerNet'),
        onFocus: () => this.props.selectTags([...record.powerPins], 'PowerNet', true),
        getPopupContainer: document.getElementById('pin-select-dialog')
      }
    }

    this.columns[2].render = (text, record) => {
      return <span>{text.join(', ')}</span>
    }

    this.columns[2].onCell = (record) => {
      const { descData } = this.props;
      const selectedPins = descData[1].used.sort().map((item) => { return { type: 'Selected', value: item } });
      const availablePins = descData[1].unused.sort().map((item) => { return { type: 'Available', value: item } });
      const pins = [...availablePins, ...selectedPins];
      return {
        record,
        edit: 'aurora-select',
        options: pins,
        optGroup: ['Available', 'Selected'],
        dataIndex: 'referencePins',
        selectMode: 'multiple',
        selectButtonTitle: "Select pins",
        allowStick: true,
        onChange: (list, record, setFieldsValue) => this.addPins(list, record, setFieldsValue, 'referencePins'),
        clearSelected: (value, record, setFieldsValue) => this.removePins(value, record, setFieldsValue, 'referencePins'),
        auroraSelectBlur: (record) => this.saveGroupPins(record, 'referencePins', 'ReferenceNet'),
        onFocus: () => this.props.selectTags([...record.referencePins], 'ReferenceNet', true),
        getPopupContainer: document.getElementById('pin-select-dialog')
      }
    }

    if (DCLoad) {
      this.columns[3].onCell = (record) => {
        return {
          record,
          edit: true,
          dataIndex: 'current',
          handleSave: (record) => this.editCurrent(record)
        }
      }
    } else if (this.columns[3]) {
      this.columns[3].render = (text, record) => {
        const targetName = record.target ? record.target.filter(item => !!item).map(t => t.targetName).join(', ') : "";
        return <span>{targetName}</span>
      }
      this.columns[3].onCell = (record) => {
        const { targetSetup, powerDomainId, PowerNets = [], maxFreq } = this.props;
        if (!targetSetup) {
          return;
        }
        const targetName = record.target ? record.target.filter(item => !!item).map(t => t.targetName).join(', ') : "";
        return {
          record,
          edit: true,
          customInput: targetSetup,
          powerDomainId,
          dataIndex: 'target',
          text: targetName,
          title: 'Target',
          target: record.target ? record.target.filter(item => !!item) : [],
          targetType: "port",
          portId: record.port,
          maxFreq,
          PowerNets,
          updateTarget: (_target) => this.updateTarget(_target, record)
        }
      }
    }

    this.setState({
      columnsUpdate: true
    })
  }

  addTableRow = (e) => {
    e && e.stopPropagation();
    const { tableData } = this.props;
    const groups = tableData.map(item => Number(item.port));
    let port = tableData.length + 1;
    while (groups.includes(port)) {
      port = port + 1;
    }
    let table = [...tableData, { port, powerPins: [], referencePins: [] }];
    this.props.updateCurrentState('tableData', table)
  }

  deleteRow = (e, record) => {
    e && e.stopPropagation();
    const { port } = record;
    this.props.deleteTableRow(port);
  }

  updateTarget = (target, record) => {
    const { tableData } = this.props;
    const index = tableData.findIndex(item => item.port === record.port);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = { ...record, target: target || [] }
      this.props.updateCurrentState('tableData', newData, this.props.saveTable)
    }
  }

  showAllGroups = (e) => {
    e && e.stopPropagation();
    const { tableData, chip } = this.props;
    const portShowList = tableData.map(item => {
      const { powerPins, referencePins } = item;
      return {
        positivePorts: { [chip]: powerPins },
        negativePorts: { [chip]: referencePins },
        showLine: true
      }
    })
    if (portShowList.length > 0) {
      this.props.showGroupPins(portShowList)
    }
  }

  showGroup = (e, record) => {
    e && e.stopPropagation();
    const { chip } = this.props;
    const { powerPins, referencePins } = record;
    const portShowList = [{
      positivePorts: { [chip]: powerPins },
      negativePorts: { [chip]: referencePins },
      showLine: true,
      showText: false
    }]
    this.props.showGroupPins(portShowList)
  }

  addPins = (list, record, setFieldsValue, type) => {
    const { tableData } = this.props;
    let newTableData = [...tableData];
    const currentRow = newTableData.find(item => item.port === record.port);
    let pinsList = record[type], _type = 'add';
    if (list.length === 1 && pinsList.find(pin => pin === list[0])) {
      pinsList = pinsList.filter(pin => pin === list[0]);
      _type = 'remove'
    } else {
      pinsList = [...new Set([...pinsList, ...list])];
    }
    if (type === 'referencePins' && pinsList.length) {
      pinsList = this.getPinListByRefe(_type === 'remove' ? list : pinsList, _type);
      if (pinsList === false) {
        return;
      }
      newTableData = this.refeGroupHandle(pinsList, newTableData)
    }
    currentRow[type] = pinsList;
    if (setFieldsValue) {
      setFieldsValue({ [type]: pinsList })
    }
    this.props.updatePinUse({ table: newTableData, type, DCLoad: this.props.DCLoad });
    this.props.selectTags([...pinsList], type === 'powerPins' ? 'PowerNet' : 'ReferenceNet', true)
  }

  removePins = (value, record, setFieldsValue, type) => {
    const { tableData, DCLoad } = this.props;
    let newTableData = [...tableData];
    const currentRow = newTableData.find(item => item.port === record.port);
    let ports = record[type];
    ports = ports.filter(pin => pin !== value);
    if (setFieldsValue) {
      setFieldsValue({ [type]: ports })
    }
    currentRow[type] = ports;
    if (type === 'referencePins' && ports.length) {
      ports = this.getPinListByRefe([value], 'remove')
      newTableData = this.refeGroupHandle(ports, newTableData)
    }
    this.props.updatePinUse({ table: newTableData, type, DCLoad });
  }

  getPinListByRefe = (pinList, type) => {
    if (!pinList || !pinList.length) return [];
    let newPins = [];
    const { refeGroup } = this.props;
    const _refeGroup = [...refeGroup], spliceIndex = [];
    if (type === 'add') {
      pinList.forEach(pin => {
        const findIndex = _refeGroup.findIndex(item => item.includes(pin));
        if (findIndex > -1) {
          newPins.push(..._refeGroup[findIndex]);
          _refeGroup.splice(findIndex, 1);
          spliceIndex.push(findIndex)
        } else {
          newPins.push(pin)
        }
      })
      if (spliceIndex.length > 1) {
        this.setState({
          mergeGroup: true,
          splice: _refeGroup,
          newPins
        })
        return false;
      }
      _refeGroup.push(newPins)
    } else {
      const findIndex = _refeGroup.findIndex(item => item.includes(pinList[0]));
      if (findIndex > -1) {
        if (_refeGroup[findIndex].length < 2) {
          _refeGroup.splice(findIndex, 1);
          newPins = [];
        } else {
          _refeGroup[findIndex] = _refeGroup[findIndex].filter(item => !pinList.includes(item))
          newPins = _refeGroup[findIndex];
        }
      }
    }
    this.props.updateCurrentState('refeGroup', _refeGroup);
    return [...new Set(newPins)];
  }

  refeGroupHandle = (pinsList, table) => {
    const newTableData = [...table];
    newTableData.forEach(row => {
      if (row.referencePins.some(item => pinsList.includes(item))) {
        row.referencePins = pinsList;
      }
    })
    return newTableData;
  }

  saveGroupPins = (record, pinType, type) => {
    this.props.selectTags([...record[pinType]], type, false);
    this.props.saveTable();
  }

  cleanGroups = () => {
    this.props.updateCurrentState('refeGroup', []);
    const ports = this.props.pushAllPinsToOneGroup();
    this.props.updatePinUse({ table: ports, type: 'allPins', save: true, DCLoad: true });
  }

  autoCreateGroup = (e) => {
    e.stopPropagation();
    let newTable = []
    this.props.updateCurrentState('refeGroup', []);
    this.props.updatePinUse({ table: [], type: 'allPins', save: false, DCLoad: false });
    const { descData, DCLoad } = this.props;
    for (let pin of descData[0].unused) {
      const groups = newTable.map(item => Number(item.port));
      let port = newTable.length + 1;
      while (groups.includes(port)) {
        port = port + 1;
      }
      newTable.push({ port, powerPins: [pin], referencePins: [] })
    }
    this.props.updatePinUse({ table: newTable, type: 'powerPins', save: false, afterSaveFn: this.props.autoFindReference, DCLoad: DCLoad })
  }

  createGroupInCell = () => {
    this.props.createGridGroup()
  }

  cancelMerge = () => {
    this.setState({
      mergeGroup: false,
      spliceIndex: [],
      newPins: []
    })
  }

  mergeGroup = () => {
    const { splice = [], newPins = [] } = this.state;
    let pins = [...new Set(newPins)], _splice = [...splice];
    _splice.push([...new Set(newPins)])
    this.props.updateCurrentState('refeGroup', _splice);
    const { tableData } = this.props;
    let newTableData = [...tableData];
    newTableData = this.refeGroupHandle(pins, newTableData);
    this.props.updatePinUse({ table: newTableData, type: 'referencePins', DCLoad: this.props.DCLoad });
    this.cancelMerge()
  }

  editCurrent = (record) => {
    const { tableData } = this.props;
    const index = tableData.findIndex(item => item.port === record.port);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = { ...record }
      this.props.updateCurrentState('tableData', newData, this.props.saveTable)
    }
  }

  updatePortSetupPanel = (boolean) => {
    this.setState({
      portSetupVisible: boolean
    })
  }

  updatePlocPanel = (boolean) => {
    this.setState({
      plocVisible: boolean
    })
  }

  savePlocInfo = ({ plocSelectFile, cpmPairs, triPairs, transforms }) => {
    this.setState({
      plocInfo: {
        plocSelectFile,
        cpmPairs,
        triPairs,
        transforms
      }
    })
  }

  plocIcon = () => (
    <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
      <text textAnchor="start" fontFamily="Noto Sans JP" fontSize="5" id="svg_3" y="6" x="0" strokeWidth="0" fill="currentColor" transform="scale(1, 2)" letterSpacing='0.8'>PLOC</text>
    </svg>
  )

  render() {
    const { tableData, DCLoad, gridColumn, gridRow, widthSize, heightSize, range, border, size, error, plocModelList, designId, product, PowerNets, ReferenceNets, chip, plocSupport, autoMatch } = this.props;
    const { mergeGroup, splice = [], newPins = [], portSetupVisible, plocVisible, plocInfo } = this.state;
    const errorMsg = `The selected pin already exists in other reference pin groups, whether to merge the reference pin group.`;
    return <Fragment>
      <div className='port-setup-border margin-top-20'>
        <span className="font-bold port-setup-title-color">{DCLoad ? "Groups" : "Ports"}</span>
        <PlusCircleOutlined className='port-setup-add-icon' onClick={(e) => this.addTableRow(e)} />
        <div className='ports-setup-table-icon-group'>
          {plocSupport && <Tooltip title='Set port with PLOC' overlayClassName='aurora-tooltip' placement="bottomLeft">
            <Icon component={this.plocIcon} className='ploc-icon' onClick={() => this.updatePlocPanel(true)} />
          </Tooltip>}
          <Tooltip title='Grid based pin group port setup' overlayClassName='aurora-tooltip' placement="bottomLeft">
            <TableOutlined onClick={() => this.updatePortSetupPanel(true)} />
          </Tooltip>
          <Tooltip title='One port per power pin' overlayClassName='aurora-tooltip' placement="bottomLeft">
            <MonitorOutlined onClick={(e) => this.autoCreateGroup(e)} />
          </Tooltip>
          <Tooltip title={DCLoad ? 'Reset groups' : 'Lumped port'} overlayClassName='aurora-tooltip' placement="bottomLeft">
            <RedoOutlined onClick={() => this.cleanGroups()} />
          </Tooltip>
        </div>
        <Table
          columns={this.columns}
          size="small"
          dataSource={tableData}
          className='space-10 ports-group-setup-table'
          rowKey={(record) => record.port}
          scroll={{ y: tableData.length > 8 ? 320 : false, x: false }}
          rowClassName={(record) => {
            const { powerPins, referencePins } = record;
            const { show } = this.props;
            return [...powerPins, ...referencePins].find(item => show.includes(item)) ? 'editable-row aurora-row-background' : ''
          }}
        />
        <div id='pin-select-dialog'></div>
        {error && <span className='port-setup-panel-error-msg'>Error: {error}</span>}
      </div>
      <DescDom {...this.props} />
      {mergeGroup ? <DelConfirm
        data={{ splice, newPins }}
        deleteConfirm={this.mergeGroup}
        cancelDel={this.cancelMerge}
        message={errorMsg}
        mask={true}
      /> : null}
      {portSetupVisible && <PortSetupPanel
        closeModal={this.updatePortSetupPanel}
        createGroupInCell={this.createGroupInCell}
        updateGridData={this.props.updateGridData}
        removeGridLines={this.props.removeGridLines}
        removePinPath={this.props.removePinPath}
        updatePinPath={this.props.updatePinPath}
        gridColumn={gridColumn}
        gridRow={gridRow}
        widthSize={widthSize}
        heightSize={heightSize}
        range={range}
        border={border}
        size={size}
      />}
      {plocVisible && <PlocSetupPanel
        plocModelList={plocModelList}
        closeModal={this.updatePlocPanel}
        designId={designId}
        product={product}
        PowerNets={PowerNets}
        ReferenceNets={ReferenceNets}
        chip={chip}
        savePlocPorts={this.props.savePlocPorts}
        savePlocInfo={this.savePlocInfo}
        plocInfo={plocInfo}
        autoMatch={autoMatch}
      />}
    </Fragment>
  }
}

export default PortSetup;