/* eslint-disable no-undef */
import { call, fork, put, takeEvery, takeLatest, select, cancel, delay } from 'redux-saga/effects';
import {
  BACK_TO_SETUP,
  GET_HISTORYS,
  SAVE_HISTORY,
  DELETE_HISTORY,
  GET_IMPORTS,
  DELETE_IMPORT,
  GET_COMPARES,
  SAVE_COMPARES
} from './actionType';
import { updateHistoryList, updateHistoryStatus, updateImportList, updateImportStatus, updateRunningImport, updateCompareList, updateCompareStatus } from './action';
import { openPage } from '../../project/action';
import { IR_EXPLORER, IMPEDANCE, IMPEDANCE_RESULT, IR_EXPLORER_RESULT } from '../../../../../constants/treeConstants';
import {
  saveImpedanceHistory,
  deleteImpedanceHistory,
  getImpedanceHistorys,
  getImpResultImportList,
  deleteImpResultImports,
  getImpedanceComparison,
  saveImpedanceComparison,
  getImpedanceHasResult
} from '@/services/Cascade/Impedance';
import { VERIFY_RUNNING, WAITING } from '../../../../../constants/verificationStatus';
import { RUNNING, WAITING as WORKFLOWWAITING } from '../../../../../constants/workflowStatus';
import { transformWorkflowStatusToVerification } from '@/services/helper/resultStatus'
import { getWorkFlow } from '@/services/api/v2/workflowCtrl';
import _ from 'lodash';

function* backToSetup(action) {
  const { CascadeReducer: { project: { viewList }, IR: { verificationId: irVerificationId }, Impedance: { verificationId } } } = yield select();
  if (viewList.includes(IR_EXPLORER_RESULT)) {
    yield put(openPage({ pageType: IR_EXPLORER, id: irVerificationId }));
  } else if (viewList.includes(IMPEDANCE_RESULT)) {
    yield put(openPage({ pageType: IMPEDANCE, id: verificationId }));
  }
}

function* getHistoryList() {
  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  try {
    const res = yield call(getImpedanceHistorys, verificationId);
    yield put(updateHistoryList(res))
  } catch (error) {
    console.error(error);
  }
}

function* saveHistory(action) {
  const { name } = action;
  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  try {
    const res = yield call(saveImpedanceHistory, { name, verificationId });
    yield put(updateHistoryList(res))
    yield put(updateHistoryStatus())
  }
  catch (error) {
    console.error(error);
  }
}

function* deleteHistory(action) {
  const { id } = action;
  try {
    const res = yield call(deleteImpedanceHistory, [id]);
    yield put(updateHistoryList(res ? res : []))
    yield put(updateHistoryStatus())
  }
  catch (error) {
    console.error(error);
  }
}

let importTask = undefined;
function* getImportList() {
  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  try {
    let res = yield call(getImpResultImportList, verificationId);
    const runningList = res && res.length ? res.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status)).map(item => item.workflowId) : [];
    yield put(updateImportList(res || []));
    yield put(updateRunningImport(runningList))
    yield put(updateImportStatus());

    if (runningList.length) {
      if (importTask) {
        yield cancel(importTask)
      }
      importTask = yield fork(getImportsTaskStatus)
    } else {
      importTask = undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteImport(action) {
  const { id } = action;
  try {
    yield call(deleteImpResultImports, [id]);
    yield call(getImportList)
  }
  catch (error) {
    console.error(error);
  }
}

function* getImportsTaskStatus() {
  const { CascadeReducer: { ImpedanceResult: { runningImports: oldRunning } } } = yield select();

  while (oldRunning.length) {
    yield delay(3000)
    const { CascadeReducer: { ImpedanceResult: { runningImports } } } = yield select();
    let newRunning = [];
    const { CascadeReducer: { ImpedanceResult: { imports } } } = yield select();
    let _imports = [...imports];
    for (let id of runningImports) {
      try {
        const { data: { data } } = yield call(getWorkFlow, id);
        if (!data || !data.status || [RUNNING, WORKFLOWWAITING].includes(data.status)) {
          newRunning.push(id);
        } else {
          const index = _imports.findIndex(item => item.workflowId === id);
          if (index > -1) {
            _imports[index].status = transformWorkflowStatusToVerification(data.status);
          }
        }
      } catch (error) {
        console.error(error)
        newRunning.push(id);
      }
    }

    if (runningImports.length !== newRunning.length) {
      yield put(updateImportList(_imports));
      yield put(updateRunningImport(newRunning))
      yield put(updateImportStatus());
    }
  }
}

function* getCompares() {
  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  yield put(updateCompareList([], false));
  try {
    const res = yield call(getImpedanceComparison, verificationId) || [];
    const successList = yield call(getImpedanceHasResult) || [];
    const _successList = successList.map(s => s.children).flat(2)
    const compares = [];
    for (let compare of res) {
      const find = _successList.find(success => success.id === compare.verificationId);
      if (find) {
        compares.push({ ...compare, historys: find.histories || [] })
      }
    }
    yield put(updateCompareList(compares, true));
  } catch (error) {
    yield put(updateCompareList([], false));
    console.error(error)
  }
}

function* saveCompares(action) {
  const { compares } = action
  const { CascadeReducer: { Impedance: { verificationId } } } = yield select();
  try {
    yield call(saveImpedanceComparison, { verificationId, comparison: compares });
    yield put(updateCompareList(compares));
    yield put(updateCompareStatus())
  } catch (error) {
    yield put(updateCompareList([], false));
    console.error(error)
  }
}

function* ImpedanceResultSaga() {
  yield takeEvery(BACK_TO_SETUP, backToSetup)
  yield takeEvery(GET_HISTORYS, getHistoryList)
  yield takeEvery(SAVE_HISTORY, saveHistory)
  yield takeEvery(DELETE_HISTORY, deleteHistory)
  yield takeEvery(GET_IMPORTS, getImportList)
  yield takeEvery(DELETE_IMPORT, deleteImport);
  yield takeEvery(GET_COMPARES, getCompares);
  yield takeEvery(SAVE_COMPARES, saveCompares);
}

export default ImpedanceResultSaga