import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVerificationJson } from '@/services/Andes_v2/results'
import { getWaveformResult, getPowerSumResultList } from '@/services/Andes_v2/results/waveform';
import PowerSum from '@/components/powerSum';
import { getReportConfig, saveReportConfig, updateReportInfo } from '../../store/project/action';
import { generateTDRSBRResult, getTDRSBRSettingData } from '../../../../services/Andes_v2/results/waveform';
import { ANDES_V2 } from '../../../../constants/pageType';
import Setting from '../../../../services/Andes_v2/results/waveform/TDRSBRSetting';

class PowerSumResult extends Component {
  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    const { reportConfig, id, channelId, resultPageType, isEndToEnd } = this.props;
    return <PowerSum
      {...this.props}
      id={id}
      channelId={channelId}
      isEndToEnd={isEndToEnd}
      resultPageType={resultPageType}
      getVerificationJson={getVerificationJson}
      getWaveformResult={getWaveformResult}
      reportConfig={reportConfig || {}}
      generateTDRSBRResult={generateTDRSBRResult}
      getTDRSBRSettingData={getTDRSBRSettingData}
      getPowerSumResultList={getPowerSumResultList}
      pageType={ANDES_V2}
      Setting={Setting}
    />
  }

}


const mapState = (state) => {
  const { AndesV2Reducer: { project: { openProjectId, reportInfo } } } = state;
  const { reportConfig = {}, reportConfigLoading } = reportInfo || {};
  return {
    projectId: openProjectId,
    reportConfig,
    reportConfigLoading
  };
}

const mapDispatch = (dispatch) => ({
  _updateReportInfo(info) {
    dispatch(updateReportInfo(info))
  },
  _saveReportConfig(channelId, isMultiPCB) {
    dispatch(saveReportConfig(channelId, isMultiPCB))
  },
  _getReportConfig({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }) {
    dispatch(getReportConfig({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }))
  }
})

export default connect(mapState, mapDispatch)(PowerSumResult);