import {
  GET_SSN_RESULT_CONTENT,
  UPDATE_SELECT_VERIFICATION
} from './actionType';

export const getSSNResultContent = (id) => ({
  type: GET_SSN_RESULT_CONTENT,
  id
})

export const updateSelectVerification = (id) => ({
  type: UPDATE_SELECT_VERIFICATION,
  id
})