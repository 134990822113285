import { USER_LOGIN, LOGIN_SUCCESS, USER_LOGOUT, PAGE_TYPE, SAVE_SYSTEM_VERSION, LOGIN_INFO } from './actionTypes';

export const loginAction = (email, password) => ({
  type: USER_LOGIN,
  email,
  password
})

export const loginSuccessAction = (email, userName) => ({
  type: LOGIN_SUCCESS,
  email,
  userName
})

export const logoutAction = () => ({
  type: USER_LOGOUT
});


export const setPageType = (type) => ({
  type: PAGE_TYPE,
  pageType: type
});

export const saveSystemVersion = (version) => ({
  type: SAVE_SYSTEM_VERSION,
  version
});

export const userLoginInfo = (email, userName) => ({
  type: LOGIN_INFO,
  email,
  userName
});