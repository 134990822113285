import { strDelimited } from '../../helper/split';

function spiceModel(name, ports = []) {
  this.name = name; // String, model name
  this.ports = ports;
}

function parseSpiceModelSelector(fileContent) {
  if (!fileContent) {
    return [];
  }
  // convert the input into an Array of Strings
  const lines = fileContent.match ? fileContent.match(/[^\r\n]+/g) || [] : [];
  const models = [];
  let modelNames = [];
  let inBLock = false;
  let name = null, subcktIndex = null;
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.match(/^\.subckt/ig)) {

      let words = line.match(/[^\s\t]+/g);
      name = words[1] ? words[1] : null;
      if (!name) {
        subcktIndex = i + 1;
        continue;
      }
      subcktIndex = null;
      inBLock = true;
      // .subckt subcktName  port1 port2 ...
      let _ports = words.slice(2, words.length);
      _ports = filterPorts(_ports);
      if (modelNames.indexOf(name) < 0) {
        const model = new spiceModel(name, _ports);
        models.push(model);
      }
      modelNames.push(name);
    } else if (subcktIndex === i && line.startsWith('+')) {
      // .subckt
      // + subcktName port1 port2 ...
      subcktIndex = null;
      let words = line.match(/[^\s\t]+/g);
      name = words[1] ? words[1] : null;
      if (!name) {
        continue;
      }
      inBLock = true;
      let _ports = words.slice(2, words.length);
      _ports = filterPorts(_ports);
      if (modelNames.indexOf(name) < 0) {
        const model = new spiceModel(name, _ports);
        models.push(model);
      }
      modelNames.push(name);
    } else if (line.match(/^\.end/ig)) {
      name = null;
      inBLock = false;
      continue;
    } else if (inBLock && line.startsWith('+')) {
      let words = line.match(/[^\s\t]+/g);
      const index = models.findIndex(item => item.name === name);
      let _words = words;
      if (words[0] === '+') {
        _words = words.slice(1, words.length);
      } else {
        _words[0] = _words[0].substr(1);
      }
      _words = filterPorts(_words);
      models[index].ports.push(..._words);
    } else {
      name = null;
      inBLock = false;
      continue;
    }
  };
  return models;
}

function filterPorts(ports) {
  let _ports = [];
  for (let i = 0; i < ports.length; i++) {
    const item = ports[i];
    //filter "=" and includes "=" (eg: "R_PKG=0.0")
    if (item.match("=")) {
      continue;
    }

    // filter eg: "L_PKG = 0.1"
    // _ports: [ ..., "L_PKG", "=", "0.1", ... ]
    const prevPort = findPortNameEqualSign(ports[i - 1]), nextPort = findPortNameEqualSign(ports[i + 1], 0);

    if ((prevPort && prevPort === "=") || (nextPort && nextPort === '=')) {
      continue;
    }
    _ports.push(item);
  }
  return _ports;
}

/* find equal by port name */
function findPortNameEqualSign(port, index) {
  if (!port) {
    return port;
  }

  //"=12" -> ["=", "2"]
  //"w=" -> ["w", "="]
  const portArr = strDelimited(port);

  //if port is prev port, return portArr[portArr.length-1], if port is next port, return portArr[0]
  if (index !== undefined) {
    return portArr[index];
  }
  return portArr[portArr.length - 1];
}

function parseSPFolderModelSelector(fileModels, fileContent) {
  if (Array.isArray(fileContent)) {
    for (let file of fileContent) {
      //file { fileName, subcktList:[], ports:[], ... }
      let name = null, models = [], modelNames = [];
      if (Array.isArray(file.subcktList) && file.subcktList.length > 0) {
        let subcktList = file.subcktList;
        //[".subckt repeater1 in out", ... ]
        subcktList = subcktList.filter(item => item.match(/^\.subckt/i));
        if (subcktList.length === 0) {
          continue;
        }
        for (let subckt of subcktList) {
          //"[".subckt", "repeater1", "in", "out" ]
          let words = subckt.match(/[^\s\t]+/g);
          name = words[1];
          let _ports = words.slice(2, words.length);
          _ports = filterPorts(_ports);
          if (modelNames.indexOf(name) < 0) {
            const model = new spiceModel(name, _ports);
            models.push(model);
          }
          modelNames.push(name);
        }
      } else {
        continue;
      }
      if (fileModels) {
        const index = fileModels.findIndex(item => item.fileName === file.fileName);
        if (index > -1) {
          fileModels[index].models = models;
        } else {
          fileModels.push({
            fileName: file.fileName,
            models
          })
        }
      } else {
        fileModels = [];
        fileModels.push({
          fileName: file.fileName,
          models
        })
      }
    }
  }
  return fileModels;
}

export {
  parseSpiceModelSelector,
  parseSPFolderModelSelector,
  filterPorts
};