import React, { PureComponent } from 'react';
import { CloseOutlined, DownloadOutlined, EyeOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Divider, Tooltip } from 'antd';
import ResultFile from './fileContent';
import { HISTORY } from '../../constants';

class History extends PureComponent {

  inputClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { historyActions, historyData, portNameClick } = this.props;
    const { historyTitleClick, fileNameClick, historyItemTitleClick, showHistoryJson, deleteHistory, downloadHistory, showOptimization, changePort, colorChange } = historyActions;
    const { historyShow, historyNameList, portSelect, files, existResult, verificationId } = historyData;
    const historyFiles = files.filter(item => item.fileType === HISTORY);
    const Icon = historyShow ? DownOutlined : RightOutlined
    return (
      <div className='pdn-result-upload' id='pdn-sparameter-history'>
        <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className='result-import-title'>
            <Icon className="title-expand-icon" onClick={historyTitleClick} />
            <span className='pdn-result-menu-title' onClick={historyTitleClick}>History</span>
            <Tooltip title='Compare Setup' overlayClassName='aurora-tooltip' onClick={this.inputClick} getPopupContainer={() => document.getElementById('pdn-sparameter-history')}>
              <EyeOutlined className="pdn-result-show-icon" onClick={showHistoryJson} />
            </Tooltip>
          </div>
        </Divider>
        {historyShow && historyNameList && historyNameList.length > 0 && historyNameList.map(item =>
          <div className='pdn-result-history-list' key={item.time}>
            <div className='pdn-result-history-title' onClick={(e) => historyItemTitleClick(e, item.name, item.time)}>
              {item.show ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
              <span title={item.name}>{item.name}</span>
              <CloseOutlined className="file-delete-icon" onClick={(e) => deleteHistory(e, item)} />
              <DownloadOutlined
                className="import-file-download-icon"
                onClick={(e) => downloadHistory(e, item)} />
            </div>
            <Row className='pdn-result-history-files' style={{ display: item.show ? 'block' : 'none' }}>
              {historyFiles.length > 0 ? historyFiles.map((file, fileIndex) =>
                file.time === item.time && (!existResult || (existResult && file.type !== 'target')) &&
                <ResultFile
                  file={file}
                  fileNameClick={fileNameClick}
                  fileIndex={fileIndex}
                  portSelect={portSelect}
                  changePort={changePort}
                  verificationId={verificationId}
                  colorChange={colorChange}
                  portNameClick={portNameClick}
                  showOptimization={showOptimization}
                  time={item.time}
                  key={file.id}
                  type={HISTORY}
                />) : null}
            </Row>
          </div>)
        }
      </div>
    );
  }
}

export default History;