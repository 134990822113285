import { END_TO_END_CHANNEL, END_TO_END_CHANNELS, END_TO_END_CHANNEL_RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, PCB, PCBS, PCB_CHANNEL, PCB_CHANNEL_RESULT, RESULT } from "../../../constants/treeConstants";
import designChannelsConstructor from "./designChannelConstructor";
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import channelConstructor from "../channel/channelConstructor";
import designConstructor from '@/services/helper/designConstructor';
import { getPageKeyList } from "../../helper/filterHelper";
import endToEndChannelConstructor from "../endToEndChannel/endToEndChannelConstructor";
import { channelErrorCheck } from "../../../pages/Andes_v2/Channel/errorCheck";
import projectEndToEndConstructor from "../project/projectEndToEndConstructor";
import { strDelimited } from "../../helper/split";
import { endToEndErrorCheck } from "../../../pages/Andes_v2/EndToEndChannel/endToEndErrorCheck";
import { isEndToEndChildrenChannel } from "..";
import endToEndChildrenChannelsConstructor from "../endToEndChannel/endToEndChildrenChannelsConstructor";
import { workflowJudge } from "../../workflow/workflowHelper";
import LayoutData from '@/services/data/LayoutData';
import sweepConstructor from "../sweep/sweepConstructor";

/** 
 * update channel extraction / end_to_end channel selected keys
 */
function getSimSelectKeys(itemData, prevKeys) {
  let keys = [...prevKeys];
  if (prevKeys.includes(itemData.id)) {
    keys = prevKeys.filter(item => item !== itemData.id);
  } else {
    keys = [...new Set([...prevKeys, itemData.id])];
  }
  return keys;
}


/** 
 * update channel extraction selected keys
 */
function getAllSimSelectKeys(prevKeys, itemData, checked) {
  const _filter = itemData.children && itemData.children.length > 0 ? itemData.children.filter(c => c.status !== VERIFY_RUNNING && c.status !== WAITING && !c.simStatus) : [];
  const newKeys = _filter && _filter.length > 0 ? _filter.map(v => v.id) : [];
  let keys = [...prevKeys];
  if (!checked) {
    keys = prevKeys.filter(item => !newKeys.includes(item));
  } else {
    keys = [...new Set([...prevKeys, ...newKeys])];
  }
  return keys;
}

function getVerifyCheckAllChecked(itemData, keys) {
  let checkedAll = false, indeterminate = false;
  switch (itemData.dataType) {
    case PCB:
      const channels = designChannelsConstructor.getChannelsByDesignId(itemData.id) || [];
      const selectedList = channels.filter(item => keys.includes(item));

      if (!selectedList.length) {
        checkedAll = false; indeterminate = false;
      } else if (selectedList.length === channels.length) {
        checkedAll = true; indeterminate = false;
      } else {
        checkedAll = false; indeterminate = true;
      }
      break;
    case END_TO_END_CHANNELS:
      const projectId = strDelimited(itemData.key, "-", { returnIndex: 1 });
      const endToEndChannels = projectEndToEndConstructor.getEndToEndChannelsByProjectId(projectId) || [];
      const endToEndSelectedList = endToEndChannels.filter(item => keys.includes(item));
      if (!endToEndSelectedList.length) {
        checkedAll = false; indeterminate = false;
      } else if (endToEndSelectedList.length === endToEndChannels.length) {
        checkedAll = true; indeterminate = false;
      } else {
        checkedAll = false; indeterminate = true;
      }
      break;
    default: break;
  }
  return { checkedAll, indeterminate };
}

function getSimulationIconInfo(itemData, props) {
  let simulateKeys = [], tooltipMsg = "";

  if (itemData.dataType === PCBS) {
    simulateKeys = props.pcbChannelSimSelectKeys || [];
    tooltipMsg = simulateKeys.length > 0 ? 'Channel modeling and simulation' : 'Please select at least one.'
  }

  if (itemData.dataType === END_TO_END_CHANNELS) {
    simulateKeys = props.endToEndSimSelectKeys || [];
    tooltipMsg = simulateKeys.length > 0 ? 'Multi-PCB channel simulation' : 'Please select at least one.'
  }
  return { simulateKeys, tooltipMsg }
}

function getMonitorInfo({
  viewList,
  currentVerificationId,
  currentProjectId,
  errorCheck,
  channelInfo,
  endToEndChannelInfo = {},
  replaceMonitor,
  designStackupErrors = [],
  endToEndSetupUpdateInfo = {},
  channelReplaceMonitorList = [],
  hybridRegions,
  notExistNets
}) {
  let subName = "", subType = "", idEndToEndChildren = false,
    verifications = [], _errorCheck = null, selectedKey = "",
    verificationType = null, _replaceMonitor = [], designStackupErrorList = null, setupUpdateMsgList = [], endToEndReplaceMonitorList = [],
    channelName = '';

  const keys = getPageKeyList(PCB_CHANNEL);
  //END_TO_END_CHANNEL, / PCB_CHANNEL
  const view = viewList.find(item => keys.includes(item));
  switch (view) {
    case PCB_CHANNEL:
    case PCB_CHANNEL_RESULT:
      verificationType = "Channel";
      const channel = channelConstructor.getChannelByVerificationId(currentVerificationId);
      if (channel) {
        const design = designConstructor.getDesign(channel.designId);
        subName = design ? design.name : null;
        subType = "Design";
        verifications = channelConstructor.getChannelValues(channel.designId).map(item => { return { ...item, pageType: PCB_CHANNEL } })
        if (errorCheck && channelInfo.id === channel.id) {
          const materialList = LayoutData.getStackupMaterials(channel.designId);
          _errorCheck = channelErrorCheck(channelInfo, null, hybridRegions, materialList);
        }
        const monitor = replaceMonitor.find(item => item.id === channel.id);
        _replaceMonitor = monitor ? monitor.monitor : [];
        const _designStackupError = designStackupErrors.find(item => item.id === channel.designId);
        if (_designStackupError && _designStackupError.stackupError && _designStackupError.stackupError.length) {
          designStackupErrorList = _designStackupError.stackupError;
        }
      }
      if (isEndToEndChildrenChannel(channel)) {
        const endToEnd = endToEndChannelConstructor.getEndToEndChannel(channel.endToEndId);
        subName = endToEnd ? endToEnd.name : null;
        subType = "Multi-PCB Channel";
        verifications = endToEndChildrenChannelsConstructor.getChannelsByEndToEndId(channel.endToEndId) || [];
        selectedKey = "end_to_end_channel_children";
        verificationType = "PCB";
        idEndToEndChildren = true;
      }

      break;
    case END_TO_END_CHANNEL:
    case END_TO_END_CHANNEL_RESULT:
      verifications = endToEndChannelConstructor.getEndToEndChannelValues(currentProjectId).map(item => { return { ...item, pageType: END_TO_END_CHANNEL } })
      const endToEnd = endToEndChannelConstructor.getEndToEndChannelByVerificationId(currentVerificationId) || {};

      if (errorCheck && endToEndChannelInfo.id === endToEnd.id) {
        _errorCheck = endToEndErrorCheck(endToEndChannelInfo.content);
      }
      if (endToEndSetupUpdateInfo && endToEndSetupUpdateInfo.id === endToEnd.id) {
        setupUpdateMsgList = endToEndSetupUpdateInfo.updateMsgList || [];
      }

      if (channelReplaceMonitorList && channelReplaceMonitorList.length) {
        const find = channelReplaceMonitorList.find(item => item.id === endToEnd.id) || {};
        endToEndReplaceMonitorList = find.replaceMonitorList;
      }
    case EXPERIMENTS:
    case EXPERIMENTS_RESULT:
      const sweep = sweepConstructor.getSweepByVerificationId(currentVerificationId);
      if (sweep) {
        const design = designConstructor.getDesign(sweep.designId);
        const channel = channelConstructor.getChannel(sweep.channelId);
        subName = design ? design.name : null;
        subType = "Design";
        channelName = channel.name;
        selectedKey = 'sweep';
        verifications = sweepConstructor.getSweepValues(sweep.channelId).map(item => { return { ...item, pageType: EXPERIMENTS } })
        if (errorCheck && channelInfo.id === sweep.channelId) {
          const materialList = LayoutData.getStackupMaterials(sweep.designId);
          _errorCheck = channelErrorCheck(channelInfo, null, hybridRegions, materialList);

        }
        if (notExistNets && notExistNets.length > 0) {
          if (!_errorCheck) _errorCheck = [];
          _errorCheck.push({
            title: `[${sweep.name}]`,
            // boldKey: `${comp.name}`,
            errorMsg: `The selected nets [${notExistNets.join(', ')}] does not exist in channel.`
          })
        }
        const monitor = replaceMonitor.find(item => item.id === sweep.channelId);
        _replaceMonitor = monitor ? monitor.monitor : [];
        const _designStackupError = designStackupErrors.find(item => item.id === sweep.designId);
        if (_designStackupError && _designStackupError.stackupError && _designStackupError.stackupError.length) {
          designStackupErrorList = _designStackupError.stackupError;
        }
      }
      break;
    default: break;
  }
  return {
    subName,
    subType,
    verificationType,
    verifications,
    _errorCheck,
    _replaceMonitor,
    designStackupErrorList,
    setupUpdateMsgList,
    selectedKey,
    endToEndReplaceMonitorList,
    idEndToEndChildren,
    channelName
  }
}

function cancelCallWorkflow(selectedKeys, verificationId, workType, monitorVerificationId) {
  let verification = {};
  if (workType === PCB_CHANNEL) {
    verification = channelConstructor.getChannelByVerificationId(verificationId) || {};
  } else if (workType === END_TO_END_CHANNEL) {
    verification = endToEndChannelConstructor.getEndToEndChannelByVerificationId(verificationId) || {};
  } else if (workType === EXPERIMENTS) {
    verification = sweepConstructor.getSweepByVerificationId(verificationId) || {};
  }

  return workflowJudge({
    selectedKeys,
    verificationId,
    verificationKey: `${workType}-${verification.id}`,
    resultKey: `${workType}_${RESULT}-${verification.id}`,
    monitorVerificationId
  });
}

export {
  getSimSelectKeys,
  getVerifyCheckAllChecked,
  getSimulationIconInfo,
  getAllSimSelectKeys,
  getMonitorInfo,
  cancelCallWorkflow
}