let interTime = null;

function countTime(callback, second) {//second is the time to detect non-operation, in seconds,
  // callback is the function that needs to be executed without clicking in the time period

  let timer, status = true;
  if (interTime) {
    //clear prev
    clearInterval(interTime);
    interTime = null;
  }

  interTime = setInterval(function () {
    if (!status) {
      callback();
      status = true;
      clearInterval(interTime);
    }
  }, 1000);

  if (timer) {
    clearTimeout(timer);
    timer = null;
  }

  timer = setTimeout(function () {
    status = false;
  }, second);
}

function closeCountTime() {
  if (interTime) {
    clearInterval(interTime);
    interTime = null;
  }
}

function getCountTime() {
  return interTime;
}

export { countTime, closeCountTime, getCountTime };