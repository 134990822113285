import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Table from '@/components/EditableTable';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import "./index.css";

const columns = [
  {
    title: "check",
    key: "check",
    dataIndex: "check"
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "type"
  },
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    width: "10%"
  },
  {
    title: "Signal",
    key: "signalMergeName",
    dataIndex: "signalMergeName",
    /* width: "26%" */
  },
  {
    title: "Nets",
    key: "nets",
    dataIndex: "nets",
    width: "26%"
  }
];
const mergeColumns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    width: "10%"
  },
  {
    title: "Signal",
    key: "signalMergeName",
    dataIndex: "signalMergeName",
    width: "36%"
  },
  {
    title: "Nets",
    key: "nets",
    dataIndex: "nets",
    width: "64%"
  }
]
class MergeSignalsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pcbInterfaceInfo: {},
      mergeSignals: [],
      selectedData: [],
      selectedKeys: []
    }
    this.dialogRoot = document.getElementById('root');

    columns[0].render = (check, record) => {
      const { selectedKeys } = this.state;
      return <div>
        <Checkbox
          checked={selectedKeys.includes(`${record.interfaceKey}::${record.name}`)}
          onChange={(e) => this.checkSignals(e, record.interfaceKey, record.name)}
        />
      </div>
    }

    columns[1].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.rowSpan
      }
    }

    columns[4].render = (text, record) => {
      return <div className="sierra-merge-signals-nets-td">{(record.nets || []).join(", ")}</div>
    }

    mergeColumns[2].render = (text, record) => {
      return <span>{(record.nets || []).join(", ")}</span>
    }

  }

  checkSignals = (e, interfaceKey, name) => {
    e.stopPropagation();
    let _selectedKeys = [...this.state.selectedKeys];
    const key = `${interfaceKey}::${name}`;
    if (_selectedKeys.includes(key)) {
      _selectedKeys = _selectedKeys.filter(item => item === key)
    } else {
      _selectedKeys.push(key)
    }
    this.setState({
      selectedKeys: _selectedKeys
    })
  }

  componentDidMount = () => {
    const { pcbInterfaceInfo, designId, mergeSignals } = this.props;
    const _pcbInterfaceInfo = JSON.parse(JSON.stringify(pcbInterfaceInfo || {})),
      _mergeSignals = JSON.parse(JSON.stringify(mergeSignals || []));
    let data = _pcbInterfaceInfo[designId].allInterfaceCompNetData || [];

    //get selected signals
    const allMergeData = data.filter(item => _pcbInterfaceInfo[designId].selectKeys.includes(item.interfaceKey));
    const mergedSignalKeys = _mergeSignals.map(item => item.name);
    let selectedData = [];
    for (let item of allMergeData) {
      let obj = {
        interfaceKey: item.interfaceKey,
        type: item.type
      }
      const _signals = item.content.filter((it) => !mergedSignalKeys.includes(it.name));
      if (_signals.length === 0) {
        continue;
      }
      const signals = item.content.filter((it, index) => index > 0);

      selectedData.push({
        ..._signals[0],
        ...obj,
        rowSpan: _signals.length
      }, ...signals.map(it => { return { ...it, ...obj, rowSpan: 0 } }))
    }
    this.setState({
      pcbInterfaceInfo: _pcbInterfaceInfo,
      mergeSignals: _mergeSignals,
      selectedData
    })
  }

  closePanel = () => {
    this.props.closePanel(false)
  }

  okConfirm = () => {
    const { pcbInterfaceInfo } = this.state;
    this.props.closePanel({ save: true, pcbInterfaceInfo });
  }

  cancelConfirm = () => {
    this.props.closePanel({ save: false })
  }


  getContentFooter = () => {
    return <div className="sierra-connections-footer-content">
      <Button key="ok" type="primary" onClick={this.okConfirm}>OK</Button>
      <Button key="cancel" style={{ marginLeft: 30 }} onClick={this.cancelConfirm} >Cancel</Button>
    </div>
  }

  mergeSelectedSignals = () => {

  }

  render = () => {
    const { mergeSignals, selectedData, selectedKeys } = this.state;
    const content = (
      <Panel
        className='sierra-connections-panel sierra-panel'
        position='panel-center-left'
        title="Merge Signals"
        zIndex={2000}
        onCancel={this.closePanel}
        width={1000}
        draggable
        minHeight={300}
        maxHeight={800}
        footer={this.getContentFooter()}
        footerHeight={52}
      >
        <div className='sierra-merge-signals-main'>
          <div className='sierra-merge-signals-left'>
            <div className='merge-selected-signal-button-div'>
              <Button
                key="merge"
                type="primary"
                ghost
                size="small"
                disabled={selectedKeys.length <= 2}
                onClick={() => this.mergeSelectedSignals()}
                className='merge-selected-signal-button'
                title={"Merge selected signals into one signal"}
              >Merge Signals</Button>
            </div>
            <Table
              columns={columns}
              dataSource={selectedData || []}
              rowKey={(record) => `${record.name}-${record.type}`}
              size="small"
              pagination={false}
              tableLayout="fixed"
              className="sierra-connections-table"
            />
          </div>
          <div className='sierra-merge-signals-arrow-right'><DoubleRightOutlined /></div>
          <div className='sierra-merge-signals-right'>
            <Table
              columns={mergeColumns}
              dataSource={mergeSignals || []}
              rowKey={(record) => `${record.signalMergeName}`}
              size="small"
              pagination={false}
              tableLayout="fixed"
              className="sierra-connections-table"
            />
          </div>
        </div>
      </Panel>);
    return <Fragment>
      {createPortal(content, this.dialogRoot)}
    </Fragment>
  }
}

export default MergeSignalsPanel;