import {
  UPDATE_PROJECT_MENU,
  UPDATE_PAGE_LAYOUT,
  UPDATE_PCB_LAYOUT,
  UPDATE_EXPAND,
  UPDATE_SELECTED_KEYS,
  UPDATE_TREE_LIST,
  ADD_PROJECT,
  CREATE_PROJECT,
  CANCEL_CREATE_PROJECT,
  OPEN_PROJECT,
  CLOSE_PROJECT,
  UPDATE_OPEN_PROJECT_INFO,
  CLEAR_PROJECT_INFO,
  UPDATE_VIEW_LIST,
  DELETE_PROJECT,
  OPEN_PAGE,
  RENAME_CHANNEL,
  RENAME_PROJECT,
  CLEAN_TRASH,
  UPDATE_TRASH,
  UPDATE_SERVICE_CONFIG,
  SAVE_SERVICE_OPTION,
  UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO,
  SAVE_REPORT_CONFIG,
  GET_PROJECT_REPORT
} from "./actionTypes";

export const updateProjectMenu = () => ({
  type: UPDATE_PROJECT_MENU
});

export const updatePageLayout = (layout, singleType) => ({
  type: UPDATE_PAGE_LAYOUT,
  layout,
  singleType
});

export const updatePCBLayout = (pcbLayout, prePcbLayout) => ({
  type: UPDATE_PCB_LAYOUT,
  pcbLayout,
  prePcbLayout
});

export const updateExpand = (expandedKeys) => ({
  type: UPDATE_EXPAND,
  expandedKeys
});

export const updateSelectedKeys = (selectedKeys) => ({
  type: UPDATE_SELECTED_KEYS,
  selectedKeys
});

export const updateTreeList = ({ treeItems }) => ({
  type: UPDATE_TREE_LIST,
  treeItems
});

export const updateViewList = (viewList) => ({
  type: UPDATE_VIEW_LIST,
  viewList
})

export const addProject = () => ({
  type: ADD_PROJECT
});

export const createProject = (data) => ({
  type: CREATE_PROJECT,
  data
});

export const cancelCreateProject = () => ({
  type: CANCEL_CREATE_PROJECT
});

export const openProject = ({ id }) => ({
  type: OPEN_PROJECT,
  id
});

export const closeProject = () => ({
  type: CLOSE_PROJECT
});

export const updateOpenedProjectInfo = ({ id }) => ({
  type: UPDATE_OPEN_PROJECT_INFO,
  id
});

export const clearProjectInfo = () => ({
  type: CLEAR_PROJECT_INFO
});

export const deleteProject = (id) => ({
  type: DELETE_PROJECT,
  id
});

export const openPage = ({ pageType, id }) => ({
  type: OPEN_PAGE,
  pageType,
  id
});

export const renameChannel = (itemData) => ({
  type: RENAME_CHANNEL,
  itemData
});

export const renameProject = (itemData) => ({
  type: RENAME_PROJECT,
  itemData
});

export const cleanTrash = () => ({
  type: CLEAN_TRASH
});

export const updateTrash = () => ({
  type: UPDATE_TRASH
});

/* === Service options(config) === */
export const updateServiceConfig = (hspiceOption) => ({
  type: UPDATE_SERVICE_CONFIG,
  hspiceOption
});

export const saveServiceOption = () => ({
  type: SAVE_SERVICE_OPTION,
})

/* == report ==  */
export const updateReportInfo = (info) => ({
  type: UPDATE_REPORT_INFO,
  info
});

export const clearReportInfo = () => ({
  type: CLEAR_REPORT_INFO
});

export const saveReportConfig = (projectId) => ({
  type: SAVE_REPORT_CONFIG,
  projectId
});

export const getReport = ({ projectId, format, mime, fileName }) => ({
  type: GET_PROJECT_REPORT,
  projectId,
  format,
  mime,
  fileName
})