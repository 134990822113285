import React, { PureComponent, Fragment, } from 'react';
import './index.css';

class ErrorCheck extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { errorShow, errorCheck } = this.props;
    return (
      <Fragment>
        {errorCheck && /* errorShow && */
          <Fragment>
           { /* <span className="pdn-warning-title">Error / Warning:</span> */}
            <pre className="pdn-warning-dialog">
              {errorCheck && errorCheck.error && errorCheck.error.length > 0 && errorCheck.error.map((item, index) => {
                return <Fragment key={index}>{item}</Fragment>
              })}
            </pre>
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default ErrorCheck;