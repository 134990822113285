import React, { Component } from 'react';
import { Input } from 'antd';
import '../index.css';

class TimeSetting extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { stepTime, stopTime, startTime, changeSettingValue } = this.props
    return <div className='imp-transient-setting-content'>
      <div className='imp-transient-setting-body'>
        <span className='imp-transient-setting-title'>Transient start time</span>
        <Input
          value={startTime}
          onChange={(e) => changeSettingValue(e, 'startTime')}
          addonAfter={<div className='imp-current-after'>ns</div>}
        />
      </div>
      <div className='imp-transient-setting-body'>
        <span className='imp-transient-setting-title'>Transient stop time</span>
        <Input
          value={stopTime}
          onChange={(e) => changeSettingValue(e, 'stopTime')}
          addonAfter={<div className='imp-current-after'>ns</div>}
        />
      </div>
      <div className='imp-transient-setting-body'>
        <span className='imp-transient-setting-title'>Transient step time</span>
        <Input
          value={stepTime}
          onChange={(e) => changeSettingValue(e, 'stepTime')}
          addonAfter={<div className='imp-current-after'>ps</div>}
        />
      </div>
    </div>
  }
}

export default TimeSetting