import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TopMenu from './IRTopMenu';
import PinResult from './PinResult';
import ViaResult from './ViaResult';
import { changeResultType } from '../.././store/result/IR/action.js';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { getResBlob } from '@/services/helper/downloadHelper';
import cascadeProjects from '@/services/Cascade/DB/cascadeProject';

class IRResultContent extends PureComponent {

  getResultContent = () => {
    const { resultKey } = this.props;
    switch (resultKey) {
      case 'via':
        return <ViaResult />;
      case 'pin':
        return <PinResult />;
      default:
        return null;
    }
  }

  downloadIRResult = () => {
    const { verificationId, openProjectId } = this.props;
    const token = localStorage.getItem('token')
    const url = `/api/v3/Cascade/ir/result?verificationId=${verificationId}&access_token=${token}`

    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    const targetTime = dayjs(time.toUTCString()).format('YYYYMMDD');

    const projectData = cascadeProjects.getProject(openProjectId)
    const name = projectData.name ? projectData.name : "design";

    let fileName = `${name}_ir_result_${targetTime}.zip`
    getResBlob(url).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, fileName);
      }
    })
  }

  render() {
    const { changeResultType, resultKey, layoutLeftWidth, leftWidth } = this.props;
    return (
      <div className="cascade-result-IR" >
        {TopMenu({
          selectedKey: resultKey,
          changeResultType,
          downloadIRResult: this.downloadIRResult,
          layoutLeftWidth,
          leftWidth
        })}
        {this.getResultContent()}
      </div>
    )
  }
}

const mapState = (state) => {
  const { IRResult: { resultKey, verificationId }, project: { openProjectId } } = state.CascadeReducer
  return {
    resultKey,
    verificationId,
    openProjectId
  }
}

const mapDispatch = (dispatch) => ({
  changeResultType(key) {
    dispatch(changeResultType(key))
  }
})

export default connect(mapState, mapDispatch)(IRResultContent);