import { message } from "antd";
import { checkNameFormat } from "../../helper/nameFormatCheck";
import channelSetupInfo from "../channel/channelInfo";
import { updateConnectorPins } from "../../helper/connectorHelper/connectionModelHelper";
import { ConnectorPin } from "./IntegratedEndToEndChannel";
import { deleteUnUsedCableModels, deleteUnUsedConnectorModels } from "../../helper/connectorHelper";

function getSignalConnsColumns(pcbConnections) {

  const width = `${100 / (pcbConnections.length + 1)}%`;
  let columns = [{
    title: `Signal`,
    dataIndex: "name",
    key: "name",
    width
  }];

  for (let i = 0; i < pcbConnections.length; i++) {
    const key = `PCB_${i}`;
    const item = pcbConnections[i];
    columns.push({
      title: `PCB ${i + 1} Signal`,
      dataIndex: key,
      key: key,
      index: i,
      width,
      pcbChannel: item
    })
  }
  return columns;
}

function getSignalListByPCBChannel(pcbChannel, allSignalList, connections, selectedSignalName) {
  let signalList = [...allSignalList];

  let selectedSignals = [];

  if (pcbChannel.prevConnectionId) {
    const connection = connections.find(item => item.CONNECTION_ID === pcbChannel.prevConnectionId);
    if (!connection) {
      return signalList;
    }
    const signal_connections_map = connection.connection.signal_connections_map || [];
    selectedSignals = signal_connections_map.map(item => item.channel2_signal)
  } else if (pcbChannel.nextConnectionId) {
    const connection = connections.find(item => item.CONNECTION_ID === pcbChannel.nextConnectionId);
    if (!connection) {
      return signalList;
    }
    const signal_connections_map = connection.connection.signal_connections_map || [];
    selectedSignals = signal_connections_map.map(item => item.channel1_signal)
  }

  selectedSignals = selectedSignals.filter(item => item !== selectedSignalName && !!item);
  let options = [];
  for (let item of allSignalList) {
    if (!selectedSignals.includes(item)) {
      options.push({ value: item, disabled: false })
    }
  }
  options = [...options, ...selectedSignals.map(item => { return { value: item, disabled: true } })]
  return options;
}

function getPCBSignalConnections(pcbConnections, connections) {
  let list = [];
  if (!pcbConnections || !pcbConnections.length) {
    return [];
  }
  const fistConnectionId = pcbConnections[0].nextConnectionId;
  const fistConnection = connections.find(item => item.CONNECTION_ID === fistConnectionId);
  const first_signal_connections_map = fistConnection.connection.signal_connections_map || [];
  const signalNameList = first_signal_connections_map.map(item => item.name);

  for (let signal of signalNameList) {
    let signalObj = {
      name: signal
    }

    for (let i = 0; i < pcbConnections.length; i++) {
      const pcbItem = pcbConnections[i];
      const dataIndex = `PCB_${i}`;
      if (signalObj[dataIndex]) {
        continue;
      }
      if (pcbItem.prevConnectionId) {
        const connection = connections.find(item => item.CONNECTION_ID === pcbItem.prevConnectionId);
        const signal_connections_map = connection.connection.signal_connections_map || [];
        const channelSignal = signal_connections_map.find(item => item.name === signal);
        signalObj[dataIndex] = channelSignal ? channelSignal.channel2_signal : "";
      } else if (pcbItem.nextConnectionId) {
        const connection = connections.find(item => item.CONNECTION_ID === pcbItem.nextConnectionId);
        const signal_connections_map = connection.connection.signal_connections_map || [];
        const channelSignal = signal_connections_map.find(item => item.name === signal);
        signalObj[dataIndex] = channelSignal ? channelSignal.channel1_signal : "";
      }
    }
    list.push({
      ...signalObj
    })
  }
  return list;
}

function updateEndToEndSignalName(connections, name, prevName) {
  let isChangeName = true;
  for (let conn of connections) {
    if (!conn.connection || !conn.connection.signal_connections_map) {
      continue;
    }
    const sameName = conn.connection.signal_connections_map.find(item => item.name === name);
    if (sameName) {
      isChangeName = false;
      message.error("Signal name cannot be repeated.")
      break;
    }
    //check name characters format
    const error = checkNameFormat(name);
    if (error) {
      isChangeName = false;
      message.error(error);
      break;
    }
    const index = conn.connection.signal_connections_map.findIndex(item => item.name === prevName);
    conn.connection.signal_connections_map[index].name = name;
  }
  return { isChangeName, connections };
}

function deleteEndToEndSignal(connections, signalName) {
  for (let conn of connections) {
    if (!conn.connection || !conn.connection.signal_connections_map) {
      continue;
    }
    const channel1Comps = channelSetupInfo.getComponents(conn.channel1.channelId);
    const channel2Comps = channelSetupInfo.getComponents(conn.channel2.channelId);

    const delSignalMap = conn.connection.signal_connections_map.find(item => item.name === signalName);
    conn.connection.signal_connections_map = conn.connection.signal_connections_map.filter(item => item.name !== signalName);

    const connectorObj = updateConnectorPins({
      type: "delete",
      connector1: conn.connection.connector1,
      connector2: conn.connection.connector2,
      deleteSignalConnMap: [delSignalMap],
      channel1Comps,
      channel2Comps,
      ConnectorPin
    })

    conn.connection.connector1 = connectorObj.connector1;
    conn.connection.connector2 = connectorObj.connector2;
    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1: conn.connection.connector1,
      connector2: conn.connection.connector2,
      cableModels: conn.connection.cableModels
    });
    conn.connection.connector1 = updateConn.connector1;
    conn.connection.connector2 = updateConn.connector2;
    conn.connection.cableModels = updateConn.cableModels;
  }
  return connections;
}

function changeEndToEndChannelSignal({ connections, record, dataIndex, pcbChannel }) {
  const channelSignalName = record[dataIndex];
  const prevIndex = connections.findIndex(item => item.CONNECTION_ID === pcbChannel.prevConnectionId);
  const nextIndex = connections.findIndex(item => item.CONNECTION_ID === pcbChannel.nextConnectionId);

  const channelInfo = channelSetupInfo.getContent(pcbChannel.channelId),
    channelComps = channelSetupInfo.getComponents(pcbChannel.channelId);

  if (prevIndex > -1) {
    const channel1Info = channelSetupInfo.getContent(connections[prevIndex].channel1.channelId),
      channel1Comps = channelSetupInfo.getComponents(connections[prevIndex].channel1.channelId);

    const prevConn = connections[prevIndex].connection;
    let signal_connections_map = [...prevConn.signal_connections_map];
    const index = signal_connections_map.findIndex(item => item.name === record.name);
    const prevSignal = signal_connections_map[index].channel2_signal;
    signal_connections_map[index].channel2_signal = channelSignalName;
    prevConn.signal_connections_map = signal_connections_map;

    //update connector component pins
    const connectorObj = updateConnectorPins({
      type: "add",
      connector1: prevConn.connector1,
      connector2: prevConn.connector2,
      prevSignal,
      signalType: "channel2",
      newSignalConnMap: [signal_connections_map[index]],
      channel1Comps,
      channel2Comps: channelComps,
      channel1Info,
      channel2Info: channelInfo,
      ConnectorPin
    })

    prevConn.connector1 = connectorObj.connector1;
    prevConn.connector2 = connectorObj.connector2;
    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1: prevConn.connector1,
      connector2: prevConn.connector2,
      cableModels: prevConn.cableModels
    });
    prevConn.connector1 = updateConn.connector1;
    prevConn.connector2 = updateConn.connector2;
    prevConn.cableModels = updateConn.cableModels;

    connections[prevIndex].connection = prevConn;
  }

  if (nextIndex > -1) {
    const channel2Info = channelSetupInfo.getContent(connections[nextIndex].channel2.channelId),
      channel2Comps = channelSetupInfo.getComponents(connections[nextIndex].channel2.channelId);

    const prevConn = connections[nextIndex].connection;
    let signal_connections_map = [...prevConn.signal_connections_map];
    const index = signal_connections_map.findIndex(item => item.name === record.name);
    const prevSignal = signal_connections_map[index].channel1_signal;
    signal_connections_map[index].channel1_signal = channelSignalName;
    prevConn.signal_connections_map = signal_connections_map;

    //update connector component pins
    const connectorObj = updateConnectorPins({
      type: "add",
      connector1: prevConn.connector1,
      connector2: prevConn.connector2,
      prevSignal,
      signalType: "channel1",
      newSignalConnMap: [signal_connections_map[index]],
      channel1Comps: channelComps,
      channel2Comps,
      channel1Info: channelInfo,
      channel2Info,
      ConnectorPin
    })

    prevConn.connector1 = connectorObj.connector1;
    prevConn.connector2 = connectorObj.connector2;
    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1: prevConn.connector1,
      connector2: prevConn.connector2,
      cableModels: prevConn.cableModels
    });
    prevConn.connector1 = updateConn.connector1;
    prevConn.connector2 = updateConn.connector2;
    prevConn.cableModels = updateConn.cableModels;

    connections[nextIndex].connection = prevConn;
  }

  return connections;
}

function updateModelsAndCableModels({ connector1, connector2, cableModels }) {
  //filter unUsed model file by new pins
  connector1.models = deleteUnUsedConnectorModels({
    models: connector1.models,
    pins: connector1.pins
  });
  connector2.models = deleteUnUsedConnectorModels({
    models: connector2.models,
    pins: connector2.pins
  });
  //filter unUsed cable model file by new pins
  cableModels = deleteUnUsedCableModels(connector1, connector2, cableModels);
  return { connector1, connector2, cableModels }
}

export {
  getSignalConnsColumns,
  getSignalListByPCBChannel,
  getPCBSignalConnections,
  updateEndToEndSignalName,
  deleteEndToEndSignal,
  changeEndToEndChannelSignal,
  updateModelsAndCableModels
}