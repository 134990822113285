import {
  CREATE_END_TO_END_CHANNEL,
  DELETE_END_TO_END_CHANNEL,
  GET_END_TO_END_CHANNEL_CONTENT,
  OPEN_END_TO_END_CHANNEL,
  UPDATE_END_TO_END_CHANNEL_INFO,
  GET_END_TO_END_CHANNEL_LIST,
  UPDATE_END_TO_END_CHANNEL_CONTENT,
  SAVE_CHANNEL_SELECT,
  SAVE_CONNECTION,
  CLEAR_END_TO_END_CHANNEL_INFO,
  AUTO_GET_END_TO_END_CHANNEL_LIST,
  UPDATE_CONNECTION_MODEL_SETUP,
  CHANGE_END_TO_END_SIGNAL_NAME,
  DELETE_END_TO_END_SIGNAL,
  CHANGE_SELECTED_CHANNEL_SIGNAL,
  ADD_END_TO_END_SIGNAL,
  DELETE_PCB_CONNECTION,
  ADD_PCB_IN_CONNECTION,
  COPY_END_TO_END_CHANNEL,
  UPDATE_END_TO_END_SETUP_MSG_LIST,
  CHECK_END_TO_END_SETUP,
  AUTO_GET_END_TO_END_CHILDREN_CHANNEL_LIST,
  UPDATE_END_TO_END_REPLACE_MONITOR,
  SAVE_SEASIM_CONFIG,
  UPDATE_EXIST_RESULT_STATUS,
  UPDATE_CHANNEL_TOPOLOGY_LOADING,
  UPDATE_ADS_CONFIG,
  UPDATE_MULTI_COMPLIANCE_CONFIG,
  END_TO_END_CHANNEL_RENAME
} from './actionTypes';

export const createEndToEndChannel = ({ data, projectId }) => ({
  type: CREATE_END_TO_END_CHANNEL,
  data,
  projectId
});

export const deleteEndToEndChannel = (data) => ({
  type: DELETE_END_TO_END_CHANNEL,
  data
});

export const clearEndToEndChannelInfo = () => ({
  type: CLEAR_END_TO_END_CHANNEL_INFO
});

/* content */
export const getEndToEndContent = (id) => ({
  type: GET_END_TO_END_CHANNEL_CONTENT,
  id
});

export const updateEndToEndChannelInfo = (info) => ({
  type: UPDATE_END_TO_END_CHANNEL_INFO,
  info
});

export const updateEndToEndChannelContent = (data) => ({
  type: UPDATE_END_TO_END_CHANNEL_CONTENT,
  data
});

export const openEndToEndChannel = (id) => ({
  type: OPEN_END_TO_END_CHANNEL,
  id
});

export const getEndToEndChannelList = (projectId) => ({
  type: GET_END_TO_END_CHANNEL_LIST,
  projectId
});

export const saveChannelSelect = (record, dataIndex) => ({
  type: SAVE_CHANNEL_SELECT,
  record,
  dataIndex
});

export const saveConnection = (connection, CONNECTION_ID) => ({
  type: SAVE_CONNECTION,
  connection,
  CONNECTION_ID
});

export const autoGetEndToEndList = ({ projectId, currentSimInfo, openProject, runSimulation }) => ({
  type: AUTO_GET_END_TO_END_CHANNEL_LIST,
  projectId,
  currentSimInfo,
  openProject,
  runSimulation
});

export const updateConnectionModelSetup = (status) => ({
  type: UPDATE_CONNECTION_MODEL_SETUP,
  status
});

export const changeEndToEndSignalName = (record, prevRecord) => ({
  type: CHANGE_END_TO_END_SIGNAL_NAME,
  record,
  prevRecord
});

export const delEndToEndSignal = (signalName) => ({
  type: DELETE_END_TO_END_SIGNAL,
  signalName
});

export const changeSelectedChannelSignal = (record, dataIndex, pcbChannel) => ({
  type: CHANGE_SELECTED_CHANNEL_SIGNAL,
  record,
  dataIndex,
  pcbChannel
});

export const addConnectionSignal = () => ({
  type: ADD_END_TO_END_SIGNAL
});

export const addPCBInConnection = (key) => ({
  type: ADD_PCB_IN_CONNECTION,
  key
});

export const delPCBConnection = (key) => ({
  type: DELETE_PCB_CONNECTION,
  key
});

export const copyEndToEndChannel = (name, endToEndChannelId) => ({
  type: COPY_END_TO_END_CHANNEL,
  name,
  endToEndChannelId
});

export const updateEndToEndSetupMsgList = (endToEndSetupUpdateInfo) => ({
  type: UPDATE_END_TO_END_SETUP_MSG_LIST,
  endToEndSetupUpdateInfo
});

export const checkEndToEndByPCBChannel = ({ deletedDesignId, updateChannel }) => ({
  type: CHECK_END_TO_END_SETUP,
  deletedDesignId,
  updateChannel
});

export const autoGetEndToEndChildrenChannelList = (endToEndIds, projectId) => ({
  type: AUTO_GET_END_TO_END_CHILDREN_CHANNEL_LIST,
  endToEndIds,
  projectId
});

export const updateEndToEndReplaceMonitor = (replaceMonitorList) => ({
  type: UPDATE_END_TO_END_REPLACE_MONITOR,
  replaceMonitorList
});

export const saveSeaSimConfig = (simulateId) => ({
  type: SAVE_SEASIM_CONFIG,
  simulateId
});

export const updateEndToEndResultExist = (resultExist) => ({
  type: UPDATE_EXIST_RESULT_STATUS,
  resultExist
});

export const updateChannelTopologyLoading = (loading) => ({
  type: UPDATE_CHANNEL_TOPOLOGY_LOADING,
  loading
});

export const updateADSConfig = (adsConfig, options = { save: true, isCloseModal: false, id: null }) => ({
  type: UPDATE_ADS_CONFIG,
  adsConfig,
  save: options.save,
  id: options.id,
  isCloseModal: options.isCloseModal
});

export const updateMultiComplianceConfig = (configObj) => ({
  type: UPDATE_MULTI_COMPLIANCE_CONFIG,
  ...configObj
})

export const endToEndChannelRename = (itemData) => ({
  type: END_TO_END_CHANNEL_RENAME,
  itemData
})