import { getCompBallSizeByPinSize } from "../../ExtractionPortsHelper";
import { SOLDER } from "../../Stackup/Material";
const CYLINDER = 'Cylinder', SPHEROID = 'Spheroid';

class BallSize {
  constructor(props) {
    const {
      bgaSize, bgaShape, bgaMiddle, bgaHeight, bgaMaterial,
      dieSize, dieShape, dieMiddle, dieHeight, dieMaterial,
      DIE, BGA, designId
    } = props;

    this.bgaShape = bgaShape || SPHEROID;

    let pinSize = null;
    if (!bgaSize || !bgaMiddle || !bgaHeight) {
      pinSize = getCompBallSizeByPinSize(BGA, designId, true);
    }
    this.bgaSize = bgaSize || `${Number((pinSize * 0.9).toFixed(2))}um`;
    this.bgaHeight = bgaHeight || `${Number((pinSize * 0.8).toFixed(2))}um`;
    this.bgaMiddle = bgaMiddle || `${Number((pinSize * 1.1).toFixed(2))}um`;
    this.bgaMaterial = bgaMaterial || SOLDER;
    this.dieShape = dieShape || CYLINDER;

    if (!dieSize || !dieMiddle || !dieHeight) {
      pinSize = getCompBallSizeByPinSize(DIE, designId, true);
    }
    this.dieSize = dieSize || `${Number((pinSize * 0.9).toFixed(2))}um`;
    this.dieHeight = dieHeight || `${Number((pinSize * 0.8).toFixed(2))}um`;
    this.dieMiddle = dieMiddle || `${Number((pinSize * 1.1).toFixed(2))}um`;
    this.dieMaterial = dieMaterial || SOLDER;

  }
}

export {
  CYLINDER,
  SPHEROID,
  BallSize
}