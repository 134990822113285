import React, { Component } from "react";
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import Table from '@/components/EditableTable';
import {
  getChannelComponents,
  PackageConnModelInfo,
  PackageConnModelFileInfo,
  getPkgCompPinList,
  getCompUsageList,
  PackageDieModelInfo
} from '@/services/Andes_v2/channel';
import RLCCompModel from '@/components/RLCCompModel';
import { getRLCModelText } from '@/services/RLCModel';
import {
  IC,
  DIODE,
  CMC,
  CAP,
  IND,
  RES,
  CONNECTOR
} from "@/services/PCBHelper";
import {
  editComponentType,
  updateChannelContent,
  saveCompPackageModel,
  saveCompConnectorModel,
  editCMCCompPinsConnect,
  saveCMCModel,
  saveCompDiodeModel,
  saveCompPCBModel,
  updateDiodeCompPins
} from '../store/channel/action';
import libraryConstructor from "../../../services/Andes_v2/library/libraryConstructor";
import {
  GENERIC_TOUCHSTONE,
  PASSIVE_SPICE,
  PASSIVE_TOUCHSTONE,
  PKG_TOUCHSTONE,
  SPICE,
  TOUCHSTONE,
} from "../../../constants/libraryConstants";
import { parseSpiceModelSelector } from "../../../services/LibraryHelper/SpiceModelHelper";
import {
  getFileContent,
  getTouchstoneParse,
  getPkgSpiceModelList
} from "../../../services/Andes_v2/library";
import PackageModel from '@/components/PackageModel_v1';
import { ANDES_V2 } from "../../../constants/pageType";
import { getCompModelText, _getCompModelText } from "../../../services/helper/fileExistCheck";
import ConnectCMCPinsPanel from './ConnectCMCPinsPanel';
import CMCModel from "../../../components/CMCModel";
import { getPortNumberFromFileSuffix } from "../../../services/helper/touchstoneHelper";
import designConstructor from "../../../services/helper/designConstructor";
import { PACKAGE as PCB_PACKAGE } from "../../../constants/designType";
import { BGA, DIE } from "../../../constants/componentType";
import EditDiodePinsPanel from "./EditDiodePinsPanel";
import { CPHY } from "../../../services/PCBHelper/constants";

const compColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '20%',
  sorter: (a, b) => a.part.localeCompare(b.part)
}, {
  title: 'Components',
  dataIndex: 'comps',
  width: '24%',
}, {
  title: 'Usage',
  dataIndex: 'type',
  width: '16%',
  sorter: (a, b) => a.type.localeCompare(b.type)
}
  , {
  title: 'Model',
  dataIndex: 'model',
  width: '22%'
},
{
  title: 'Package',
  dataIndex: 'pkg',
  width: '22%'
}];
class ComponentTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      connectCMCPinsVisible: false,
      cmcComp: {}
    }
    compColumns[1].render = (comps, record) => {
      const names = comps.map(item => item.name);

      return {
        children: <div
          className='aurora-table-comp'>
          <span>{names.join(', ')}</span>
          {[CMC, DIODE].includes(record.type) ?
            <EditOutlined
              className="cmc-connect-pins-icon"
              onClick={(e) => this.editCompPin(e, record)} />
            : null}
        </div>,
        props: {
          tdClassName: this.getPcbOpen() ? 'aurora-cursor' : "",
          tdClick: () => this.componentSelect(record)
        },
      };
    }

    compColumns[2].onCell = (record) => {
      const { serdesType, channelDesignId } = this.props;
      const options = getCompUsageList({ type: record.type, record, designId: channelDesignId, serdesType });
      return {
        record,
        edit: "select",
        options: options,
        dataIndex: "type",
        handleSave: (_record) => { this.props._editComponentType(_record) },
      }
    }

    compColumns[2].render = (type, record) => {
      const { serdesType, channelDesignId } = this.props;
      const options = getCompUsageList({ type, record, designId: channelDesignId, serdesType });
      const usage = options.find(item => item.key === type);
      return usage ? usage.value : type;
    }

    compColumns[3].onCell = (record) => {
      const { powerNets, channelDesignId } = this.props;
      switch (record.type) {
        case RES:
        case IND:
        case CAP:
          const text = getRLCModelText(record.model, record.type, record.passiveFileNotExist);
          return {
            edit: true,
            text,
            record,
            customInput: RLCCompModel,
            dataIndex: 'model',
            powerNets: powerNets.map(item => item.name),
            saveRLCModel: this.saveRLCModel,
            spiceList: libraryConstructor.getLibraryValues(PASSIVE_SPICE),
            touchstoneList: libraryConstructor.getLibraryValues(PASSIVE_TOUCHSTONE),
            getFileContent,
            parseModelSelector: parseSpiceModelSelector
          }
        case CMC:
          return {
            edit: true,
            record,
            channelDesignId,
            text: getCompModelText({ ...record.cmcModel, type: TOUCHSTONE }, record, { fileNotExist: record.cmcFileNotExist }),
            customInput: CMCModel,
            dataIndex: 'model',
            saveCMCModel: this._saveCMCModel,
            touchstoneList: libraryConstructor.getLibraryValues(PASSIVE_TOUCHSTONE),
            getFileContent,
            getTouchstoneParse
          }
        case DIODE:
          let options = libraryConstructor.getLibraryValues(PASSIVE_TOUCHSTONE).map(item => { return { key: item.id, value: item.name } });
          options = options.filter(item => getPortNumberFromFileSuffix(item.value) === "1")
          return {
            edit: "select",
            labelInValue: true,
            record: { ...record, diodeModelId: record.diodeModel && record.diodeModel.id ? record.diodeModel.id : "None" },
            options: [{ key: "None", value: "None" }, ...options],
            dataIndex: "diodeModelId",
            handleSave: (_record) => { this._saveDiodeModel(_record) },
            getLabel: (option) => { return <span>{option.value}</span> }
          }
        default: return;
      }
    }

    compColumns[3].title = () => {
      const { channelDesignId } = this.props;
      const design = designConstructor.getDesign(channelDesignId) || {};
      if (design.type === PCB_PACKAGE) {
        return "PCB";
      } else {
        return "Model";
      }
    }

    compColumns[3].render = (model, record) => {
      const _componentName = record.comps && record.comps[0] ? record.comps[0].name : null;
      switch (record.type) {
        case RES:
        case IND:
        case CAP:
          const text = getRLCModelText(record.model, record.type, record.passiveFileNotExist);
          return <span>{text}</span>;
        case CONNECTOR:
          //const connectorModel = getCompModelText(record.connectorModel, record, { fileNotExist: record.connectorFileNotExist });
          return <div className="package-model-columns-content" onClick={() => this.props.scrollToAnchor(_componentName)}>
            <span className="package-model-columns-title">
              Channel Builder
            </span>
          </div>
        case CMC:
          if (!record.cmcModel || !record.cmcModel.files || !record.cmcModel.files.length) {
            return <span>None</span>
          }
          return getCompModelText({ ...record.cmcModel, type: TOUCHSTONE }, record, { fileNotExist: record.cmcFileNotExist });
        case DIODE:
          return record.diodeFileNotExist ?
            _getCompModelText({
              deleteText: record.diodeFileNotExist.deleteText,
              updateText: record.diodeFileNotExist.updateText
            }) :
            <span>{record.diodeModel && record.diodeModel.name ? record.diodeModel.name : "None"}</span>;
        case BGA:
          //const pcbModel = getCompModelText(record.pcbModel, record, { fileNotExist: record.pcbModelFileNotExist });
          return <div className="package-model-columns-content" onClick={() => this.props.scrollToAnchor(_componentName)}>
            <span className="package-model-columns-title">
              Channel Builder
            </span>
          </div>

        default: return <div className="aurora-table-row-disabled"></div>;
      }
    }


    if (compColumns[4]) {
      compColumns[4].onCell = (record) => {
        const { serdesType } = this.props;
        if (record.type === IC) {
          const componentName = record.comps && record.comps[0] ? record.comps[0].name : null;
          const pinList = record.comps && record.comps[0] ? getPkgCompPinList(componentName, this.props.components, this.props.signals) : [];
          return {
            edit: true,
            record,
            componentName,
            pinList,
            text: getCompModelText(record.pkg, record, { fileNotExist: record.packageFileNotExist }),
            modelType: "Package",
            displayType: "model",
            customInput: PackageModel,
            dataIndex: 'package',
            product: ANDES_V2,
            signals: this.props.signals,
            savePackageModel: (pkg, record) => this._savePackageModel(pkg, record, 'pkg'),
            spiceList: [], //libraryConstructor.getLibraryValues(PKG_SPICE),
            touchstoneList: libraryConstructor.getLibraryValues(PKG_TOUCHSTONE),
            PackageModelInfo: PackageConnModelInfo,
            PackageModelFileInfo: PackageConnModelFileInfo,
            getTouchstoneParse,
            getSPiceParse: getPkgSpiceModelList,
            pinListClassName: "spice-pin-connect-node-item-center-model",
            type: serdesType
          }
        } else if (record.type === DIE) {
          if (serdesType !== CPHY) return;
          const componentName = record.comps && record.comps[0] ? record.comps[0].name : null;
          const pinList = record.comps && record.comps[0] ? getPkgCompPinList(componentName, this.props.components, this.props.signals) : [];

          return {
            edit: true,
            record: { ...record, pkg: record.dieModel },
            componentName,
            pinList,
            text: getCompModelText(record.dieModel, record, { fileNotExist: record.genericFileNotExist }),
            modelType: 'DIE',
            displayType: "model",
            customInput: PackageModel,
            dataIndex: 'dieModel',
            product: ANDES_V2,
            signals: this.props.signals,
            savePackageModel: (pkg, record) => this._savePackageModel(pkg, record, 'dieModel'),
            spiceList: libraryConstructor.getLibraryValues(SPICE),
            touchstoneList: libraryConstructor.getLibraryValues(GENERIC_TOUCHSTONE),
            PackageModelInfo: PackageDieModelInfo,
            getTouchstoneParse,
            getSPiceParse: getPkgSpiceModelList,
            notFoundContent: "Please upload file to Generic Model",
            pinListClassName: "spice-pin-connect-node-item-center-model",
            type: serdesType
          }
        }
      }
    }

    compColumns[4].title = () => {
      const { channelDesignId } = this.props;
      const design = designConstructor.getDesign(channelDesignId) || {};
      if (design.type === PCB_PACKAGE) {
        return "Model";
      } else {
        return "Package";
      }
    }

    compColumns[4].render = (text, record) => {
      const { serdesType } = this.props;
      switch (record.type) {
        case IC:
          return getCompModelText(record.pkg, record, { fileNotExist: record.packageFileNotExist });
        case DIE:
          return serdesType === CPHY ? getCompModelText(record.dieModel, record, { fileNotExist: record.genericFileNotExist })
            : <div className="aurora-table-row-disabled"></div>
        default:
          return <div className="aurora-table-row-disabled"></div>
      }
    }
  }

  _saveCMCModel = (part, files, pinList) => {
    this.props._saveCMCModel(part, files, pinList);
  }

  _savePCBModel = (model, record, modelList) => {
    const { comps } = record;
    if (comps && comps[0]) {
      this.props._saveCompPCBModel(model, modelList, comps[0].name);
    }
  }

  _saveDiodeModel = (record) => {
    const touchstoneList = libraryConstructor.getLibraryValues(PASSIVE_TOUCHSTONE) || [];
    const library = touchstoneList.find(item => item.id === record.diodeModelId) || {};
    const model = record.diodeModelId === "None" ? {} : { id: library.id, name: library.name };
    this.props._saveCompDiodeModel(record.part, model);
  }

  editCompPin = (e, record) => {
    if (record.type === CMC) {
      this.openCMCPinConnect(e, record)
    }
    if (record.type === DIODE) {
      this.openDiodePinSelect(e, record)
    }
  }

  openCMCPinConnect = (e, record) => {
    this.spanClick(e);
    this.setState({
      connectCMCPinsVisible: true,
      cmcComp: { ...record }
    })
  }

  openDiodePinSelect = (e, record) => {
    this.spanClick(e);
    this.setState({
      selectDiodePinsVisible: true,
      diodeComp: { ...record }
    })
  }

  spanClick = (e) => {
    e && e.stopPropagation();
  }

  _savePackageModel = (pkg, record, dataIndex) => {
    const { comps } = record;
    if (comps && comps[0]) {
      this.props._saveCompPackageModel(pkg, comps[0].name, dataIndex);
    }
  }

  _saveConnectorModel = (model, record, modelList) => {
    const { comps } = record;
    if (comps && comps[0]) {
      this.props._saveCompConnectorModel(model, comps[0].name, modelList);
    }
  }

  saveRLCModel = (model, record) => {
    const { components } = this.props;
    let _components = [...components];
    _components.forEach(comp => {
      if (comp.part === record.part) {
        comp.model = { ...model }
      }
    });
    this.props._updateChannelContent({ components: _components });
  }

  componentSelect = (record) => {
    const names = record.comps.map(item => item.name);
    this.props.displayToLayout(names);
  }

  getPcbOpen = () => {
    const { selectedDesignIDs, channelDesignId, viewList } = this.props;
    return selectedDesignIDs.includes(channelDesignId) && viewList && viewList.length > 1;
  }

  closeModal = (type) => {
    if (type === CMC) {
      this.setState({
        cmcComp: {},
        connectCMCPinsVisible: false
      })
    } else if (type === DIODE) {
      this.setState({
        diodeComp: {},
        selectDiodePinsVisible: false
      })
    }
  }

  render() {
    const { components, channelDesignId, signals, pcbNetsList, serdesType } = this.props;
    const { connectCMCPinsVisible, cmcComp, selectDiodePinsVisible, diodeComp } = this.state;
    const design = designConstructor.getDesign(channelDesignId) || {};
    const designType = design.type; /* === PCB_PACKAGE */
    const dataList = getChannelComponents(components);
    return (<div className="aurora-setup-border">
      <span className='out-title-color font-bold'>Components</span>
      <Table
        rowKey={record => `${record.part}-${(record.comps || []).map(comp => comp.name).join(",")}-${record.type}`}
        columns={designType === PCB_PACKAGE && serdesType !== CPHY ? compColumns.filter((i, index) => index < compColumns.length - 1) : compColumns}
        size="small"
        dataSource={dataList}
        className='andes-v2-table channel-component-table space-10'
      />
      {connectCMCPinsVisible ?
        <ConnectCMCPinsPanel
          cmcComp={cmcComp}
          designId={channelDesignId}
          signals={signals}
          pcbNetsList={pcbNetsList}
          closeModal={() => this.closeModal(CMC)}
          _editCMCCompPinsConnect={this.props._editCMCCompPinsConnect}
        />
        : null}
      {selectDiodePinsVisible ?
        <EditDiodePinsPanel
          diodeComp={diodeComp}
          designId={channelDesignId}
          signals={signals}
          pcbNetsList={pcbNetsList}
          closeModal={() => this.closeModal(DIODE)}
          serdesType={serdesType}
          editDiodeCompPins={this.props._editDiodeCompPins}
        />
        : null}
    </div>
    )
  }
}

const mapDispatch = (dispatch) => ({
  _editComponentType(record) {
    dispatch(editComponentType(record))
  },
  _updateChannelContent(data) {
    dispatch(updateChannelContent(data))
  },
  _saveCompPackageModel(pkg, component, dataIndex) {
    dispatch(saveCompPackageModel(pkg, component, null, dataIndex))
  },
  _saveCompConnectorModel(model, component, modelList) {
    dispatch(saveCompConnectorModel(model, component, modelList))
  },
  _editCMCCompPinsConnect(part, pinMap) {
    dispatch(editCMCCompPinsConnect(part, pinMap))
  },
  _saveCMCModel(part, files, pinList) {
    dispatch(saveCMCModel(part, files, pinList))
  },
  _saveCompDiodeModel(part, model) {
    dispatch(saveCompDiodeModel(part, model))
  },
  _saveCompPCBModel(model, modelsObj, component) {
    dispatch(saveCompPCBModel(model, modelsObj, component))
  },
  _editDiodeCompPins(part, components) {
    dispatch(updateDiodeCompPins(part, components))
  }
})

export default connect(null, mapDispatch)(ComponentTable);