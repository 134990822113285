import CeGeometry from './CeGeometry';
import CeVertices from './CeVertices';
import CeIODataItem from '../CeFileIO/CeIODataItem';


const CeLineSegs = function () {
  CeGeometry.call(this, "Lines");
  this.mPoints = new CeVertices();
}

// subclass extends superclass
CeLineSegs.prototype = Object.create(CeGeometry.prototype);
CeLineSegs.prototype.constructor = CeLineSegs;

CeLineSegs.prototype.SetData = function (numPnts, xArray, yArray) {
  for (var i = 0; i < numPnts; i++) {
    this.mPoints.add(xArray[i], yArray[i]);
  }
}

CeLineSegs.prototype.Clone = function () {
  var clnObj = new CeLineSegs();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeLineSegs.prototype.CopyFrom = function (fromObj) {
  this.mPoints = fromObj.mPoints.Clone();
}

CeLineSegs.prototype.equals = function (obj) {
  return this.mPoints.equals(obj.mPoints);
}

CeLineSegs.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.GetDataString();
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeLineSegs.prototype.ReadFromIODataNode = function (dataItem) {
  var dataVals = dataItem.GetItemValue();
  return this.mPoints.UpdateByString(dataVals);
}

CeLineSegs.prototype.GetDataString = function () {
  return this.mPoints.GetDataString();
}

CeLineSegs.prototype.UpdateByString = function (dataVals) {
  // n x0 y0, x1 y1 ... xn yn
  if (dataVals.size() < 5)
    return false;

  return this.mPoints.UpdateByString(dataVals);
}

export default CeLineSegs;