import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import UploadFile from '@/components/UploadFile';
import JSZip from 'jszip';
import NP from 'number-precision';
import { repeatedNames, checkFileName } from '@/services/helper/nameHelper';
import {
  createLibraryList,
  updateLibraryFiles
} from '@/services/Sierra/library';
import { expandMenu, updateInterfaceAfterLibraryUpdate } from '../store/sierra/action';
import { openLibraryFolder, getLibraryTreeByType } from '../store/library/action';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { updateLibraryHelper } from '@/services/Sierra';
import {
  PASSIVE_TOUCHSTONE,
  PKG_TOUCHSTONE,
  CONNECTOR_TOUCHSTONE
} from '@/constants/libraryConstants';
import './sierraPanel.css';
import { IBIS } from '../../../constants/libraryConstants';

class LibraryUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      name: null,
      errorFileList: [],
      errorMsg: '',
      repeatedName: [],
      doubleName: '',
      checking: false,
      uploading: false,
      compressProgress: -1,
      uploadProgress: -1,
      compressMsg: '',
      fileType: 'file'
    }
    this.decapRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onUploadFiles = (fileList, errorFileList, fileType) => {
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
    });
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList],
      fileType
    });
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    const { modelType } = this.props;
    let _fileList = [...fileList];
    const findIndex = _fileList.findIndex(item => item.name === prevName);
    const fileSuffix = _fileList[findIndex].fileSuffix;
    _fileList[findIndex].name = name;
    _fileList[findIndex].fileName = `${name}${fileSuffix}`;
    _fileList[findIndex].file.fileName = `${name}${fileSuffix}`;
    this.setState({
      fileList: [..._fileList]
    });
    const names = _fileList.map(item => item.fileName);
    return this.checkFileNames(modelType, names);
  }

  checkFileNames = async (type, names) => {
    const { parentId, updateLibrary } = this.props;
    if (updateLibrary && (names.includes(updateLibrary.name) || (names.includes(`${updateLibrary.name}${updateLibrary.accept}`)))) {
      return true;
    }
    let reName = [], nameSet = true;
    const existList = await sierraLibrary.get({ id: parentId, type: type });
    names.forEach(item => {

      if (!item) {
        this.setState({
          checking: false,
          errorMsg: 'Model name is not set.'
        });
        nameSet = false;
        return;
      }

      //remove .zip in fileName
      let index = -1;
      const zipSuffix = /.zip$/i, snpSuffix = /.s[0-9]{1,}p/ig;
      let name = typeof (item) === 'string' ? item.replace(zipSuffix, "") : item;

      if (type === PASSIVE_TOUCHSTONE || type === PKG_TOUCHSTONE || type === CONNECTOR_TOUCHSTONE) {
        name = typeof (item) === 'string' ? item.replace(snpSuffix, "") : item;
        index = existList.findIndex(i => i.name.replace(snpSuffix, "") === name);
      } else {
        index = existList.findIndex(i => i.name === name);
      }

      //Whether the file has been uploaded
      if (index > -1) {
        reName.push(item);
        this.setState({
          checking: false,
        });
      }
    });

    if (!nameSet) {
      return;
    }
    if (reName.length > 0) {
      this.setState({
        errorMsg: 'Model name already exists!',
        repeatedName: [...reName]
      });
      return;
    }
    // Whether the file name is duplicated.
    const rName = repeatedNames(names);

    if (rName) {
      this.setState({
        doubleName: rName,
        checking: false,
        errorMsg: 'Model name cannot be repeated.'
      });
      return;
    }
    //Whether the file name is legal.
    const errorName = checkFileName(names);

    if (errorName && errorName.length > 0) {
      this.setState({
        checking: false,
        errorName: [...errorName],
        errorMsg: 'File names may only contain the following characters: numbers, letters, underscores, minus.'
      });
      return;
    }
    this.setState({
      errorMsg: '',
      repeatedName: [],
      errorName: [],
      doubleName: ''
    });
    return true;
  }

  changeName = (e) => {
    if (e.target.value) {
      this.setState({
        name: e.target.value
      })
    } else {
      this.setState({
        name: null
      })
    }
  }

  delFile = (fileList, errorFileList) => {
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    })
    const { modelType } = this.props;
    const names = fileList.map(item => { return item.fileName });
    this.checkFileNames(modelType, names);
  }

  saveLibraryFile = () => {
    const { fileList, compressProgress, uploadProgress, errorFileList } = this.state;
    const { modelType, updateLibrary } = this.props;
    if (compressProgress === -1 && uploadProgress === -1) {
      if (errorFileList.length !== 0) {
        this.createLibrary(modelType, { fileList: [] });
      } else if (updateLibrary && updateLibrary.id) {
        this.updateLibraryFile(modelType, updateLibrary, { fileList: [...fileList] });
      } else {
        this.createLibrary(modelType, { fileList: [...fileList] });
      }
    }
  }

  createLibrary = async (type, info) => {
    this.setState({
      checking: true,
      errorMsg: '',
    });
    const { parentId } = this.props;
    let fileList = [...info.fileList];
    if (!fileList.length) {
      this.props.closeLibraryPanel();
      return;
    }
    const { fileType } = this.state;
    const names = fileList.map(item => item.fileName);
    const status = type === IBIS && fileType === "zip" ? true : await this.checkFileNames(type, names);
    if (status) {
      this.setState({
        checking: false,
        uploading: true,
        compressProgress: 0,
      })
      let libraries = [];
      let files = [];
      fileList.forEach(file => {
        const fileName = fileType === 'folder' ? file.name : file.fileName;
        libraries.push({
          id: '',
          name: fileName,
          fileName: fileName,
          type: fileType && fileType !== 'zip' ? fileType : "file"
        });
        files.push(file.file);
      });
      let formData = new FormData();
      //todo app zip dot not zip again
      if (type === IBIS && fileType === "zip") {
        this.setState({
          compressProgress: -1,
          uploadProgress: 0,
          compressMsg: ''
        });
        files.forEach(file => {
          formData.append('file', file.file);
        })
        formData.append('libraries', JSON.stringify(libraries));
        formData.append('typeName', type);
        formData.append('fileType', fileType);// 'file' / 'folder' / 'zip
        formData.append('folderId', parentId);
        createLibraryList(formData, this.config).then(res => {
          this.afterUpload({ parentId, type })
        });
      } else {
        let zip = new JSZip();
        for (let file of files) {
          zip.file(file.fileName, file.file);
        }
        return zip.generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: { level: 1 }
        }, this.metadata).then(blob => {
          this.setState({
            compressProgress: -1,
            uploadProgress: 0,
            compressMsg: ''
          });
          formData.append('file', new File([blob], 'file.zip'));
          formData.append('libraries', JSON.stringify(libraries));
          formData.append('typeName', type);
          formData.append('fileType', fileType);// 'file' / 'folder' / 'zip
          formData.append('folderId', parentId);
          createLibraryList(formData, this.config).then(res => {
            this.afterUpload({ parentId, type })
          });
        });
      }
    };
    return;
  }

  updateLibraryFile = async (type, info, filesInfo) => {
    this.setState({
      checking: true,
      errorMsg: '',
    });
    const { parentId, accept } = this.props;
    let fileList = [...filesInfo.fileList];
    if (!fileList.length) {
      this.props.closeLibraryPanel();
      return;
    }
    const { fileType } = this.state;
    const names = fileList.map(item => item.fileName);
    const status = await this.checkFileNames(type, names);
    if (status) {
      this.setState({
        checking: false,
        uploading: true,
        compressProgress: 0,
      })
      let libraries = [], files = [], newName = info.name;
      fileList.forEach(file => {
        const fileName = fileType === 'folder' ? file.name : file.fileName;
        libraries.push({
          id: '',
          name: fileName,
          fileName: fileName,
          type: fileType && fileType !== 'zip' ? fileType : "file"
        });
        files.push(file.file);
        newName = fileName;
      });
      let formData = new FormData();
      let zip = new JSZip();
      for (let file of files) {
        zip.file(file.fileName, file.file);
      }
      return zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, this.metadata).then(blob => {
        this.setState({
          compressProgress: -1,
          uploadProgress: 0,
          compressMsg: ''
        });
        formData.append('file', new File([blob], 'file.zip'));
        formData.append('type', type);
        formData.append('fileType', fileType);// 'file' / 'folder' / 'zip
        formData.append('libraryId', info.id);
        formData.append('name', newName);
        updateLibraryFiles(formData, this.config).then(res => {
          const removedModels = res && res.removedModels ? res.removedModels : [];
          this.afterUpload({ parentId, type, removedModels, newName, oldName: info.name });
          updateLibraryHelper({ id: info.id, accept, type, fileName: newName });
          setTimeout(() => {
            this.props.updateInterfaceAfterLibraryUpdate()
          }, 200);
        });
      });
    }
    return
  }

  afterUpload = ({ parentId, type, removedModels, newName, oldName }) => {
    const { expandedKeys } = this.props;
    let keys = [...expandedKeys];
    this.props.openLibraryFolder(parentId, type, true);
    if (!keys.includes(type) || !keys.includes(`${type}-${parentId}`)) {
      keys.push(parentId === -1 ? type : `${type}-${parentId}`);
    }
    this.props._expandMenu([...keys]);
    this.props.getLibraryTreeByType([type]);
    this.setState({
      uploading: false,
      modelType: null,
      modelName: '',
      repeatedName: [],
      VectorData: {},
      compressProgress: -1,
      uploadProgress: 100,
      compressMsg: ''
    });
    const removedList = removedModels && removedModels.length > 0 ? { removedModels, newName, oldName, type } : null;
    const { modelType } = this.props;
    this.props.closeLibraryPanel(modelType);
    setTimeout(() => {
      if (removedList) {
        this.props.openRemoveModelsPanel(removedList);
      }
    }, 200);
  }

  metadata = (metadata) => {
    let msg = "Progress " + metadata.percent.toFixed(2) + " %";
    if (metadata.currentFile) {
      msg += ", current file " + metadata.currentFile;
    };
    const progress = NP.strip(parseFloat(metadata.percent.toFixed(2)));
    setTimeout(() => {
      this.setState({
        compressProgress: progress,
        compressMsg: msg
      });
    }, 50);
  }

  getUploadingStatus = () => { return this.state.uploading };

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          compressProgress: 100,
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  render() {
    const { uploadTitle, accept, importText, special, updateLibrary } = this.props;
    const { uploading, errorMsg, repeatedName, doubleName, checking,
      compressProgress, uploadProgress, compressMsg, errorName } = this.state;
    return (
      <UploadFile
        text={uploadTitle}
        importText={importText}
        onUploadFile={this.onUploadFiles}
        delFile={this.delFile}
        saveFile={this.saveLibraryFile}
        changeFileName={this.changeFileName}
        repeatedName={repeatedName}
        doubleName={doubleName}
        errorName={errorName}
        errorMsg={errorMsg}
        accept={accept}
        special={special}
        nameStatus={true}
        allowMulti={updateLibrary && updateLibrary.id ? false : true}
        compressProgress={compressProgress}
        uploadProgress={uploadProgress}
        compressMsg={compressMsg}
        uploading={uploading}
        checking={checking}
      />)
  }
}
const mapState = (state) => {
  const { SierraReducer: { sierra } } = state;
  return {
    expandedKeys: sierra.expandedKeys
  }
}

const mapDispatch = (dispatch) => ({
  openLibraryFolder(libraryId, key, update) {
    dispatch(openLibraryFolder(libraryId, key, update));
  },
  _expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  getLibraryTreeByType(type) {
    dispatch(getLibraryTreeByType(type));
  },
  updateInterfaceAfterLibraryUpdate() {
    dispatch(updateInterfaceAfterLibraryUpdate());
  }
});

export default connect(mapState, mapDispatch)(LibraryUpload);