import {
  CHANGE_RESULT_KEY,
  OPEN_RESULT_PAGE_INFO,
  CLEAN_RESULT_PAGE_INFO,
  UPDATE_RESULT_CONFIG,
  RELOAD_CURRENT_RESULT_PAGE,
  UPDATE_EYE_DIAGRAM_CONFIG,
  UPDATE_RESULT_SIGNALS,
  UPDATE_CUSTOM_EYE_STATUS,
  CHANGE_TRAIN_SWITCH_VALUE,
  OPEN_RESULT_PACKAGE_PAGE_INFO,
  OPEN_RESULT_CARD_PAGE_INFO,
  UPDATE_RESULT_TYPE,
  UPDATE_SELECT_PATTERNS,
  UPDATE_COMPINFO_LIST,
  UPDATE_WAVEFORM_LIST,
  UPDATE_RESULT_EXISTS,
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { GET_SSN_RESULT_CONTENT } from './ssn/actionType'

const defaultState = {
  resultKey: "impedance",
  resultInfo: {},
  resultConfig: null,
  reloadStatus: false,
  eyeDiagramConfig: {
    plot: { signals: [], location: "" },
    plot_config: {}
  },
  resultSignals: [],
  customEyeHashId: false,
  resultType: "Signal Pattern",
  resultExistList: []
};

const RockyResultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_RESULT_KEY:
      return {
        ...state,
        resultKey: action.key
      }
    case OPEN_RESULT_PAGE_INFO:
      return {
        ...state,
        resultInfo: {
          verificationId: action.verificationId ? action.verificationId : state.resultInfo.verificationId,
          verificationSubId: action.verificationSubId ? action.verificationSubId : state.resultInfo.verificationSubId,
          designSubId: action.designSubId ? action.designSubId : state.resultInfo.designSubId,
          designID: action.designID ? action.designID : state.resultInfo.designID,
          interfaces: action.interfaces ? [...action.interfaces] : [],
          channelId: action.channelId ? action.channelId : state.resultInfo.channelId,
          channelName: action.channelName ? action.channelName : state.resultInfo.channelName,
          ddrType: action.ddrType ? action.ddrType : state.resultInfo.ddrType,
          verificationName: action.verificationName || ""
        },
      }
    case CLEAN_RESULT_PAGE_INFO:
      return {
        ...state,
        resultInfo: {}
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_RESULT_CONFIG:
      return {
        ...state,
        resultConfig: action.resultConfig
      }
    case RELOAD_CURRENT_RESULT_PAGE:
      return {
        ...state,
        reloadStatus: action.reloadStatus
      }
    case UPDATE_EYE_DIAGRAM_CONFIG:
      return {
        ...state,
        eyeDiagramConfig: action.eyeDiagramConfig ? action.eyeDiagramConfig : { plot: {}, plot_config: {} }
      }
    case UPDATE_RESULT_SIGNALS:
      return {
        ...state,
        resultSignals: action.resultSignals
      }
    case UPDATE_CUSTOM_EYE_STATUS:
      return {
        ...state,
        customEyeHashId: action.customEyeHashId
      }
    case CHANGE_TRAIN_SWITCH_VALUE:
      return {
        ...state,
        eyeDiagramConfig: { ...state.eyeDiagramConfig, trainSwitchValue: action.trainSwitchValue }
      }
    case OPEN_RESULT_PACKAGE_PAGE_INFO:
      return {
        ...state,
        resultInfo: {
          verificationId: action.verificationId,
          verificationSubId: action.verificationSubId,
          designID: action.designID,
          channelName: action.channelName,
          packageVerificationId: action.packageVerificationId,
          ddrType: action.ddrType,
          packageVerificationSubId: action.packageVerificationSubId
        },
      }
    case OPEN_RESULT_CARD_PAGE_INFO:
      return {
        ...state,
        resultInfo: {
          verificationId: action.verificationId,
          verificationSubId: action.verificationSubId,
          designSubId: action.designSubId,
          designID: action.designID,
          interfaces: action.interfaces ? [...action.interfaces] : [],
          channelId: action.channelId,
          channelName: action.channelName
        },
        resultKey: 'channel'
      }
    case GET_SSN_RESULT_CONTENT:
      return {
        ...state,
        resultKey: 'waveform',
        resultType: "Single Pattern",
        resultExistList: []
      }
    case UPDATE_RESULT_TYPE:
      return {
        ...state,
        resultType: action.resultType
      }
    case UPDATE_SELECT_PATTERNS:
      return {
        ...state,
        selectPatterns: action.selectPatterns ? [...action.selectPatterns] : []
      }
    case UPDATE_RESULT_EXISTS:
      return {
        ...state,
        resultExistList: action.list
      }
    default: return state
  }
};

export default RockyResultReducer;