import {
  RESET_SETUP,
  SET_POWER_TREE_DATA,
  SAVE_MULTI_RESULT,
  SAVE_MULTI_SPICE_RESULT
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  verificationId: null,
  connectors: [],
  root: {},
  initConn: false,
  results: [],
  spiceResults: []
};

export const PowerTreeReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case SET_POWER_TREE_DATA:
      return {
        ...state,
        ...action.params
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case RESET_SETUP:
      return {
        ...state,
        ...defaultState
      }
    case SAVE_MULTI_RESULT:
      return {
        ...state,
        results: action.results
      }
    case SAVE_MULTI_SPICE_RESULT:
      return {
        ...state,
        spiceResults: action.spiceResults
      }
    default: return state
  }
} 