const CeNetGeom = function () {
  this.mLayerID = -1;
  this.mRefGeom = null;
};

CeNetGeom.prototype.GetLayerID = function () {
  return this.mLayerID;
}

CeNetGeom.prototype.SetLayerID = function (id) {
  this.mLayerID = id;
}

CeNetGeom.prototype.GetNetRefGeom = function () {
  return this.mRefGeom;
}

CeNetGeom.prototype.SetNetGeom = function (geom) {
  this.mRefGeom = geom;
}

CeNetGeom.prototype.WriteIntoIODataNode = function (geomBlk) {
  this.mRefGeom.WriteIntoIODataNode(geomBlk);
}

CeNetGeom.prototype.ReadFromIODataNode = function (geomBlk) {
  return this.mRefGeom.ReadFromIODataNode(geomBlk);
}

export default CeNetGeom;