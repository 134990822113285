import { getComponentTable, updateComponentTable } from '../../api/compSetting';
import componentSetting from '../../helper/componentsHelper/compSettingHelper';
import componentDoNotStuff from '../../helper/componentsHelper/compDoNotStuff';
import apiProcess from '../../api/utility';
import { IGNORE, IND, RES, COMP_REPEATER, CAP, JUMPER, CONNECTOR, IC, RLC_TYPES } from '../../PCBHelper';
import { splitValueUnit } from '../../helper/RLCValue';
import { SortFn } from '../../helper/sort';
import debounce from '../../helper/debounceFn';
import { SIERRA } from '../../../constants/pageType';
import auroraDBJson from '../../Designs/auroraDbData';
import { getCompTypeByPrefixLib } from '../../Designs/helper';
import { getCompType, getTypeFromPrefixPart } from '../../Designs/compTypeHelper';
import { getRLCCompValue } from '../setupHelper';
import { versionCompareSize } from '../../helper/dataProcess';

class CompTableHelper {
  constructor() {
    this.compVersion = "1.0.0";
    this.componentTable = new Map();  // key - designId, value - { passive, version }
  }

  saveTable(designId, table) {
    this.componentTable.set(designId, table);
  }

  get = (designId) => {
    const obj = this.componentTable.get(designId);
    return obj && obj.table ? obj.table : [];
  }

  getTable = async (designId) => {
    if (!designId) {
      return {};
    }

    let obj = this.componentTable.get(designId);
    const version = await componentSetting.getVersion(designId);
    // || versionCompareSize(obj.version, version)
    if (!obj || !obj.table || !obj.table.length) {
      try {
        obj = await getCompTable(designId);
        if (!obj || !obj.table || !obj.table.length || (versionCompareSize(obj.version, version) && obj.version !== this.compVersion)) {
          const table = await getDesignComponentTable(designId, obj && obj.table ? obj.table : []);
          obj = {
            version: this.compVersion,
            table
          }
          this.updateTable([{ designId, componentTable: obj }]);
        } else {
          if (obj.version !== this.compVersion) {
            obj.version = this.compVersion;
            this.updateTable([{ designId, componentTable: obj }]);
          }
          this.saveTable(designId, obj);
        }
      } catch (err) {
        console.error(err);
      }
    }
    return obj
  }

  getTableData = async (designId) => {
    const obj = await this.getTable(designId);
    return obj && obj.table ? obj.table : [];
  }

  getVersion = async (designId) => {
    const obj = await this.getTable(designId);
    return obj && obj.version ? obj.version : this.compVersion;
  }

  async updateTable(componentTables) {
    try {
      debounce(async () => {
        await this.updatePassiveTable(componentTables)
      }, 500, true, 'sierraUpdateTable')()
    } catch (error) {
      console.error(error)
    }
  }

  async updatePassiveTable(componentTables) {
    componentTables.forEach(item => { item.componentTable.version = this.compVersion });
    await updateTables(componentTables);
    componentTables.forEach(obj => {
      const { designId, componentTable } = obj;
      this.saveTable(designId, componentTable);
    })
  }

  clearTable(designId) {
    this.saveTable(designId, null);
  }

  updateTableByPrefix = async ({ pcbId, newKeys, deleteKeys, COMP_PREFIX_LIB }) => {

    const doNotStuff = await componentDoNotStuff.getDoNotStuff(pcbId);
    let compMap = auroraDBJson.getComponents(pcbId);
    let table = await this.getTableData(pcbId);
    let partTypeInfo = {}
    //delete passive type by delete prefix
    if (deleteKeys && Object.keys(deleteKeys).length) {
      let deleteTableParts = []
      for (let tabItem of table) {
        let needUpdateComps = [];
        const prevType = tabItem.usage;
        let delType = getTypeFromPrefixPart(deleteKeys, tabItem.part);
        if (delType && delType !== IGNORE) {
          needUpdateComps = [...(tabItem.name || [])];
        } else {
          //comp name perfix
          for (let name of (tabItem.name || [])) {
            delType = getCompTypeByPrefixLib(name, deleteKeys);
            if (delType && delType !== IGNORE) {
              needUpdateComps.push(name);
            }
          }
        }
        let newType = IGNORE;
        if (needUpdateComps.length) {
          newType = getTypeFromPrefixPart(COMP_PREFIX_LIB, tabItem.part);
          if (!newType || newType === IGNORE) {
            let types = [], deleteComps = [];
            for (let name of (tabItem.name || [])) {
              const compInfo = compMap.get(name) || {};
              const pins = compInfo.pins ? [...compInfo.pins.values()] : [];
              const compType = getCompType({
                compName: name,
                COMP_PREFIX_LIB,
                pinLength: pins.length,
                partName: tabItem.part,
                product: SIERRA,
              });
              if (compType && compType !== IGNORE) {
                types.push(compType);
              } else {
                deleteComps.push(name);
              }
            }

            if (deleteComps.length) {
              tabItem.name = tabItem.name.filter(name => !deleteComps.includes(name));
            }
            if (!types.length) {
              newType = IGNORE;
            } else {
              const _types = [...new Set(types)];
              newType = _types.length === 1 ? types[0] : sortTypes(types)[0];
            }
          }

          if (!newType || newType === IGNORE) {
            deleteTableParts.push(tabItem.part);
          } else if (newType !== prevType) {
            tabItem.usage = newType;
            const compInfo = compMap.get(tabItem.name[0]) || {};
            const { value: _value, unit, model } = getPassiveModelAndValue({
              partName: tabItem.part,
              value: compInfo.value,
              type: newType,
              prevModel: tabItem.model
            });
            tabItem.model = model;
            tabItem.value = _value;
            tabItem.unit = unit;
          }
        }
      }

      if (deleteTableParts.length) {
        table = table.filter(item => !deleteTableParts.includes(item.part));
      }
    }
    //update passive type and add new passive by new added prefix
    if (newKeys && Object.keys(newKeys).length) {

      const components = [...compMap.values()];
      for (let comp of components) {
        const { name: compName, partName, value, pins } = comp;
        if (doNotStuff.includes(compName)) {
          continue;
        }
        const pinList = pins ? [...pins.values()].map(it => it.pin) : [];
        let newType = IGNORE;
        if (partTypeInfo[partName]) {
          newType = partTypeInfo[partName];
        } else {
          //part
          newType = getTypeFromPrefixPart(newKeys, partName);

          if (newType && newType !== IGNORE) {
            //custom prefix includes part
            partTypeInfo[partName] = newType;
          } else {
            //custom part
            const partType = getTypeFromPrefixPart(COMP_PREFIX_LIB, partName);
            //CUSTOM prefix 
            newType = partType && partType !== IGNORE ? partType : getCompTypeByPrefixLib(compName, newKeys);
          }
        }

        if (!newType || newType === IGNORE) {
          continue;
        }

        const index = table.findIndex(item => item.part === partName);
        const prevType = index > -1 ? table[index].usage : IGNORE;

        if ([COMP_REPEATER, IC, CONNECTOR].includes(newType)) {
          if (index > -1) {
            table[index].name = (table[index].name || []).filter(it => it !== compName);
          }
          continue;
        }

        if (index > -1) {

          if (!(table[index].name || []).includes(compName)) {
            table[index].name ? table[index].name.push(compName) : table[index].name = [compName];
          }

          if (prevType !== newType) {
            table[index].usage = newType;
            const { value: _value, unit, model } = getPassiveModelAndValue({ partName, value, type: newType, prevModel: table[index].model });
            table[index].model = model;
            table[index].value = _value;
            table[index].unit = unit;
          }
        } else {
          const { value: _value, unit, model } = getPassiveModelAndValue({ partName, value, type: newType });
          table.push({
            part: partName,
            name: [compName],
            usage: newType,
            value: _value,
            unit: unit,
            pins: [...pinList],
            model
          })
        }
      }
      table = table.filter(it => it.name && it.name.length);
    }


    await this.updatePassiveTable([{ designId: pcbId, componentTable: { table, version: this.compVersion } }]);
  }

  updateTableByDoNotStuff = async ({ pcbId, newUnStuff, newStuff }) => {
    let compMap = auroraDBJson.getComponents(pcbId);
    let table = await this.getTableData(pcbId);
    const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(pcbId);
    let update = false;
    table = table.filter(item => {
      const filterName = item.name.filter(name => newUnStuff.includes(name))
      if (filterName.length) {
        update = true;
        item.name = item.name.filter(name => !filterName.includes(name));
        if (item.name.length) {
          return true;
        }
        return false
      }
      return true;
    })

    if (newStuff && newStuff.length) {
      const rlcTypes = [...RLC_TYPES, JUMPER];
      for (let compName of newStuff) {
        const comp = compMap.get(compName) || {};

        const index = table.findIndex(item => item.part === comp.partName);
        update = true;
        if (index > -1) {
          table[index].name = [...new Set([...(table[index].name || []), compName])];
          update = true;
          continue;
        }
        const pins = comp.pins ? [...comp.pins.values()] : [];
        const type = getCompType({
          compName,
          COMP_PREFIX_LIB,
          pinLength: pins.length,
          partName: comp.partName,
          product: SIERRA,
        })

        if (!rlcTypes.includes(type)) {
          continue;
        }
        update = true;
        const { value: _value, unit, model } = getPassiveModelAndValue({
          partName: comp.partName,
          value: comp.value,
          type
        });
        table.push({
          part: comp.partName,
          name: [compName],
          pins: pins.map(it => it.pin),
          usage: type,
          value: _value,
          unit,
          model
        })
      }
    }
    if (update) {
      await this.updatePassiveTable([{ designId: pcbId, componentTable: { table: table, version: this.compVersion } }]);
    }
  }
}

function getCompTable(designId) {
  return apiProcess(getComponentTable, { designId });
}

function updateTables(componentTables) {
  return apiProcess(updateComponentTable, componentTables);
}

async function getDesignComponentTable(designId, oldTable = []) {
  let table = [];
  const COMP_PREFIX_LIB = await componentSetting.getPrefixLib(designId);
  const doNotStuff = await componentDoNotStuff.getDoNotStuff(designId);
  const compMap = auroraDBJson.getComponents(designId);
  const rlcTypes = [...RLC_TYPES, JUMPER], partInfo = {};

  const components = [...compMap.values()];
  for (let compItem of components) {
    const { name: compName, partName, value: compValue, pins } = compItem;
    const _pins = [...pins.values()];
    const pinNumbers = [..._pins.map(item => item.pin)];

    let type = IGNORE;
    if (doNotStuff.includes(compName)) {
      continue;
    }
    type = getCompType({
      compName,
      COMP_PREFIX_LIB,
      pinLength: _pins.length,
      partName,
      product: SIERRA,
    });
    if (!rlcTypes.includes(type)) {
      continue;
    }

    partInfo[partName] = [...partInfo[partName] || [], type];

    const findIndex = table.findIndex(item => item.part === partName);
    if (findIndex > -1) {
      table[findIndex].name = [...table[findIndex].name, compName];
    } else {
      const { value: newValue, unit: newUnit, model: newModel } = getPassiveModelAndValue({ partName, value: compValue, type });

      const old = oldTable.find(item => item.part === partName && item.usage === type);

      let value = "", unit = "";
      if (type === CAP) {
        value = old && old.value ? old.value : newValue;
      } else {
        value = old && !isNaN(old.value) ? old.value : newValue;
        unit = old ? old.unit : newUnit;
        value = value && !isNaN(value) ? Number(value) : value;
      }

      let model = { ...newModel };
      if (old && old.model && old.model.type !== "value") {
        model = old.model;
        const newPairs = pinNumbers.map(pin => {
          const findPair = model.pairs.find(it => it.pin === pin);
          return {
            pin,
            node: findPair ? findPair.node : ""
          }
        })
        model.pairs = newPairs;
      }

      table.push({
        part: partName,
        name: [compName],
        usage: type,
        pins: pinNumbers,
        value,
        unit,
        model
      })
    }
  }
  //re assign type by partInfo component type list, select max count type
  for (let part of Object.keys(partInfo)) {
    const types = partInfo[part];
    const _types = [...new Set(types)];
    if (_types.length <= 1) {
      continue;
    }
    const newType = sortTypes(types)[0];
    const index = table.findIndex(item => item.part === part);
    if (index < 0 || table[index].usage === newType) {
      continue;
    }
    table[index].usage = newType;
    const { value: _value, unit, model } = getPassiveModelAndValue({
      partName: table[index].part,
      value: table[index].value,
      type: newType,
      prevModel: table[index].model
    });
    table[index].model = model;
    table[index].value = _value;
    table[index].unit = unit;
  }
  table = SortFn(table, [RES, JUMPER, IND, CAP], 'usage');

  return table;
}

function getPassiveModelAndValue({ partName, value, type, prevModel }) {
  const { value: _value, model } = getRLCCompValue({
    part: partName,
    value,
    type,
    prevModel
  });
  if (type === CAP) {
    return { value: _value, model }
  }
  let { value: valNum, unit } = splitValueUnit(_value);
  valNum = valNum && !isNaN(valNum) ? Number(valNum) : valNum;
  return { value: valNum, unit, model }
}

function sortTypes(types) {
  const typeMap = new Map();
  types.forEach(item => typeMap.set(item, (typeMap.get(item) || 0) + 1));
  return types.sort((a, b) => typeMap.get(b) - typeMap.get(a));
}

const compTableHelper = new CompTableHelper();

export default compTableHelper;