/**
 * class StringList
 */
var CeLinkedMap = function () {
  this.mKeys = [];
  this.mVals = [];
};

CeLinkedMap.prototype.clear = function () {
  this.mKeys = [];
  this.mVals = [];
}

CeLinkedMap.prototype.add = function (key, val) {
  this.mKeys.push(key);
  this.mVals.push(val);
}

CeLinkedMap.prototype.put = function (key, val) {
  this.mKeys.push(key);
  this.mVals.push(val);
}

CeLinkedMap.prototype.isEmpty = function () {
  return this.mKeys.length == 0;
}

CeLinkedMap.prototype.containsKey = function (key) {
  var index = this.mKeys.indexOf(key);
  return index >= 0;
}

CeLinkedMap.prototype.size = function () {
  return this.mKeys.length;
}

CeLinkedMap.prototype.getKey = function (val) {
  // If input is index
  if (typeof val == "number" && val >= 0 && val < this.mVals.length)
    return this.mKeys[val];

  // If input is a real key
  var index = this.mVals.indexOf(val);
  if (index >= 0)
    return this.mKeys[index];
  return null;
}

CeLinkedMap.prototype.getValue = function (key, isRealKey = false) {
  // If input is index
  if (!isRealKey && typeof key == "number" && key >= 0 && key < this.mVals.length)
    return this.mVals[key];

  if (!isRealKey && typeof key == "string") {
    for (var i = 0; i < this.mKeys.length; i++) {
      if (key.toUpperCase() === this.mKeys[i].toUpperCase())
        return this.mVals[i];
    }
    return null;
  }

  // If input is a real key
  var index = this.mKeys.indexOf(key);
  if (index >= 0)
    return this.mVals[index];
  return null;
}

CeLinkedMap.prototype.setValue = function (key, val) {
  var index = this.mKeys.indexOf(key);
  if (index > -1)
    this.mVals[index] = val;
  else
    this.put(key, val);
}

CeLinkedMap.prototype.keySet = function () {
  return this.mKeys;
}

export default CeLinkedMap;