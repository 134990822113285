import { MICROSTRIP, STRIPLINE, DIFF } from "../../../constants/libraryConstants";

export const WIDTH = 'Width',
  THICKNESS = 'Thickness',
  HEIGHT = 'Height',
  HEIGHT1 = "Height1",
  HEIGHT2 = "Height2",
  SPACING = 'Spacing',
  ER = 'Er',
  LOSS_TANGENT = 'LossTangent',
  CONDUCTIVITY = 'Conductivity',
  Z0 = "Z0",
  Z_DIFF = "Zdiff",
  UNIT = "Unit",
  MIL = "mil",
  MM = 'mm',
  UM = "um";

const LIBRARY_UNITS = [MIL, MM, UM];

/* 1mil = 1/1000inch = 0.00254cm = 0.0254mm 

1mil = 25.4um

1um = 0.001mm

1mm = 39.37mil

1um = 0.03937mil

1mil = 0.0254mm
*/

const TraceKeyArr = [
  { title: 'Width', key: WIDTH, unit: "mil", width: 56, modify: true },
  { title: 'Thickness', key: THICKNESS, unit: "mil", width: 76, modify: true },
  { title: 'Height', key: HEIGHT, unit: "mil", width: 56, type: MICROSTRIP, modify: true },
  { title: 'Height1', key: HEIGHT1, unit: "mil", width: 56, type: STRIPLINE, modify: true },
  { title: 'Spacing', key: SPACING, unit: "mil", width: 76, signalType: DIFF, modify: true },
  { title: 'Height2', key: HEIGHT2, unit: "mil", width: 56, type: STRIPLINE, modify: true },
  /*   { title: 'Dielectric Constant', key: ER, unit: "mil" },
    { title: 'Loss Tangent', key: LOSS_TANGENT, unit: "mil" },
    { title: 'Conductivity', key: CONDUCTIVITY, unit: "(S/m)" },
    { title: 'Z', key: Z0 },
    { title: 'Zd', key: Z_DIFF } */
];

const TraceKeyZDArr = [
  { title: 'Z0', key: Z0, width: 54, modify: false },
  { title: 'Zdiff', key: Z_DIFF, width: 76, signalType: DIFF, modify: false }
]

const TraceColumns = [
  {
    title: 'Dielectric Constant',
    dataIndex: ER,
    key: ER,
    width: '33.33%',
    modify: true
  },
  {
    title: 'Loss Tangent',
    dataIndex: LOSS_TANGENT,
    key: LOSS_TANGENT,
    width: '33.33%',
    modify: true
  },
  {
    title: 'Conductivity (S/m)',
    dataIndex: CONDUCTIVITY,
    key: CONDUCTIVITY,
    width: '33.33%',
    modify: true
  }/* ,
  {
    title: 'Z',
    dataIndex: Z,
    key: Z,
    width: '11%',
  },
  {
    title: 'Zd',
    dataIndex: ZD,
    key: ZD,
    width: '11%'
  } */
]

export const DRILL_DIAMETER = 'DrillDiameter',
  PAD_DIAMETER = 'PadDiameter',
  PLATING_THICKNESS = "PlatingThickness",
  ANTI_PAD_DIAMETER = "AntiPadDiameter",
  VIA_TO_VIA_PITCH = "ViaToViaPitch";

const ViaKeyArr = [
  { title: 'Drill Diameter', key: DRILL_DIAMETER, unit: "mil", modify: true },
  { title: 'Plating Thickness', key: PLATING_THICKNESS, unit: "mil", modify: true },
  { title: 'Pad Diameter', key: PAD_DIAMETER, unit: "mil", modify: true },
  { title: 'Via Anti-Pad Diameter', key: ANTI_PAD_DIAMETER, unit: "mil", modify: true },
  { title: 'Via-To-Via Pitch', key: VIA_TO_VIA_PITCH, unit: "mil", modify: true },
  { title: 'Dielectric Constant', key: ER, modify: true }
];

function TraceConfig(type) {
  this[WIDTH] = "8"; //width (mil)
  this[THICKNESS] = "1.4"; // (mil)
  if (type === MICROSTRIP) {
    this[HEIGHT] = "8"; // (mil)
  } else if (type === STRIPLINE) {
    this[HEIGHT1] = "8"; // (mil)
    this[HEIGHT2] = "12"; // (mil)
  }
  this[SPACING] = "5"; //spacing (mil)
  this[ER] = "4.2"; //Dielectric Constant (mil)
  this[LOSS_TANGENT] = "0.02"; //Loss Tangent (mil)
  this[CONDUCTIVITY] = "5.8e7"; //Conductivity (S/m)
  this[Z0] = "68.8973";
  this[Z_DIFF] = "99.337";
  this[UNIT] = MIL;
}

function ViaConfig() {
  this[DRILL_DIAMETER] = "12"; //Drill Diameter (mil)
  this[PLATING_THICKNESS] = "1"; //Pad Diameter (mil)
  this[PAD_DIAMETER] = "24"; //Plating Thickness (mil) default  2*Drill Diameter 
  this[ER] = "4.2"; //Dielectric Constant (mil)
  this[ANTI_PAD_DIAMETER] = "48" //Anti-Pad Diameter (mil) default 2*PadDiameter
  this[VIA_TO_VIA_PITCH] = "72"// Via-to-via pitch, (mil) default 1.5*Anti-Pad Diameter  
  this[UNIT] = MIL;
}

const configUnitKeys = [
  THICKNESS,
  WIDTH,
  HEIGHT,
  HEIGHT1,
  HEIGHT2,
  SPACING,
  DRILL_DIAMETER,
  PAD_DIAMETER,
  PLATING_THICKNESS,
  ANTI_PAD_DIAMETER,
  VIA_TO_VIA_PITCH];

export {
  TraceConfig,
  ViaConfig,
  TraceKeyArr,
  ViaKeyArr,
  TraceKeyZDArr,
  TraceColumns,
  LIBRARY_UNITS,
  configUnitKeys
}