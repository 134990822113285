import { select, event, selectAll } from 'd3-selection';
import { min, max } from 'd3-array';
import { scaleLinear, scaleLog } from 'd3-scale';
import { line, curveMonotoneX } from 'd3-shape';
import { axisBottom, axisLeft } from 'd3-axis';
import { format, formatPrefix } from 'd3-format';
import { drag } from 'd3-drag';
import {/*  zoom, */ zoomTransform, zoomIdentity } from 'd3-zoom';
import NP from 'number-precision';
import { displayUnit, db, radiansToDegrees } from '../../../helper/sparameter/utility';
import defaultColor from '@/constants/defaultColors';
import MarkData from './Mark/markData';
import ReferenceLine from './Reference/referenceLine';
import { ANDES_V2, CASCADE } from '../../../../constants/pageType';
import { PACKAGE_PDN_RESULT } from '../../../../constants/treeConstants';
import { formatExponential } from '../../../helper/numberHelper';

const d3 = { min, max };
let maxWidth = 0;
let location = {};
NP.enableBoundaryChecking(false);
const ANDES_EYE_MASK = 'AndeseyeMask'

export class PlotOptions {
  constructor() {
    this.background = '#fff'; // String, background color
    this.title = null; // String, plot title
    this.ui = null; // unit interval - approximate data cycle length
    this.zoom = null; // String, allowed zoom behavior, 'x', 'y', or 'xy', null or '' for no zoom
    this.xMin = null; // Number, minimum x coordinate of the plot
    this.xMax = null; // Number, maximum x coordinate of the plot
    this.yMin = null; // Number, minimum y coordinate of the plot
    this.yMax = null; // Number, maximum y coordinate of the plot
    this.parameter = null // String, s,y,z
    this.format = null // String, am / ph / re / im / db
  }
};

class PlotViewer {
  constructor({ element, options, events, product, id = null }) {
    this.svgElement = element;
    this.options = options || new PlotOptions();
    this.curves = []; // Array of CurveItem
    this.events = events || null;
    this.axis = [];
    this.id = id || product;
    this.mark = new MarkData();
    this.reference = new ReferenceLine();
    this.padding = {
      top: 24,
      bottom: 40,
      left: 40,
      right: 50
    };
    this.gRoot = null;
    this.cRoot = null;
    this.rRoot = null;
  }

  zoom = () => {
    return;
    /*  if (["FastPI", CASCADE].includes(this.id)) {
       return;
     }
     const plot = this;
     const svgEle = plot.root.select(`g.${plot.id}-result-svg-viewer-g`)
     plot.zoomCtrl = zoom()
       .scaleExtent([0.05, 200])
       .on("zoom", () => {
         const { k, x, y } = event.transform;
 
         svgEle.attr('transform', `translate(${x}, ${y}) scale(${k},${k})`);
         let t = event.transform.rescaleX(plot.xScale)  //获得缩放后的scale
         plot._xAxis.call(plot.xAxis.scale(t))           //重新设置x坐标轴的scale
         let yt = event.transform.rescaleY(plot.yScale)  //获得缩放后的scale
         plot._yAxis.call(plot.yAxis.scale(yt))          //重新设置y坐标轴的scale
         plot.root.selectAll("path.curve")
           .attr("d", function (c) {
             return c.visible ? plot.getCurveData(c) : null
           })
         // plot._line
         //.x(function (d) { //获取曲线，用新的x尺度来计算line
         //  return t(d.x)
         //})
         // .y(function (d) { //获取曲线，用新的y尺度来计算line
         //  return yt(d.y)
         //}) );
       });
     select(this.svgElement).call(this.zoomCtrl); */
  }

  zoomIn = () => {
    this._zoom(1.272);
  }

  zoomOut = () => {
    this._zoom(1 / 1.272);
  }

  _zoom = (ratio, x, y) => {

    let xc, yc, scale;
    if (x === undefined || y === undefined) {

      // relative zoom, responding to the zoom button, zoom about the screen center
      // The assumption is that the center location is not changed after the zoom.
      // before zooming, the center coordinate is
      //     (1)   Xc = x * s1 + dx1
      // where Xc is the center coordinate of the SVG view box, x is actual coordinate
      // in the g element of zoom control, s1 is the previous scaling, and dx1 is the
      // previous shift.
      // From (1) we can calculate the actual coordinate as
      //     (2)   x = (Xc - dx1) / s1
      // Now after the zoom, the center coordinate remain the same, so we have
      //     (3)   Xc = x * s2 + dx2
      // where s2 and dx2 is the new scaling and shifting, respectively. The scaling
      // factor is known, so we only need to determine the shifting.
      // Substitute (2) into (3) leads to
      //     (4)   dx2 = Xc * (1 - s2 / s1) + dx1 * s2 / s1
      //

      const t = zoomTransform(this.svgElement);
      xc = this.viewBoxXc * (1 - ratio) + t.x * ratio;
      yc = this.viewBoxYc * (1 - ratio) + t.y * ratio;

      // remember this setting in the zoom control object
      // this.zoomCtrl.translate([xc, yc]);
      scale = t.k * ratio;
      // get the scale from the zoom object so that it won't be out of bound
      if (scale < 0.05) scale = 0.05;
      else if (scale > 300) scale = 200;
    } else {
      // Responding to the mouse event, the shifting and scaling are calculated by
      // d3. In this mode, the parameter 'ratio' is the actual scaling factor.
      xc = x;
      yc = y;
      scale = ratio;
    }
    const svgEle = this.root.select(`g.${this.id}-result-svg-viewer-g`)
    this.svgElement.__zoom = new zoomIdentity.constructor(scale, xc, yc);
    svgEle.attr('transform', 'translate(' + xc + ' ' + yc + ') scale(' + scale + ',' + scale + ')');
    const plot = this;

    let t = this.svgElement.__zoom.rescaleX(plot.xScale)  //获得缩放后的scale
    plot._xAxis.call(plot.xAxis.scale(t))                 //重新设置x坐标轴的scale
    let yt = this.svgElement.__zoom.rescaleY(plot.yScale)  //获得缩放后的scale
    plot._yAxis.call(plot.yAxis.scale(yt))       //重新设置y坐标轴的scale
    plot.root.selectAll("path.curve")
      .attr("d", function (c) {
        return c.visible ? plot.getCurveData(c) : null
      })
    /* plot._line
      .x(function (d) { //获取曲线，用新的x尺度来计算line
        return t(d.x)
      })
      .y(function (d) { //获取曲线，用新的y尺度来计算line
        return yt(d.y)
      })) */
  }

  fitView = () => {
    // re-calculate the bounding box
    this.resetPlot();
    this.redrawCurves()

    this.svgElement.__zoom = new zoomIdentity.constructor(1, 0, 0);
  }

  zoom = () => {
    return;
    /*  if (["FastPI", CASCADE].includes(this.id)) {
       return;
     }
     const plot = this;
     const svgEle = plot.root.select(`g.${plot.id}-result-svg-viewer-g`)
     plot.zoomCtrl = zoom()
       .scaleExtent([0.05, 200])
       .on("zoom", () => {
         const { k, x, y } = event.transform;
 
         svgEle.attr('transform', `translate(${x}, ${y}) scale(${k},${k})`);
         let t = event.transform.rescaleX(plot.xScale)  //获得缩放后的scale
         plot._xAxis.call(plot.xAxis.scale(t))           //重新设置x坐标轴的scale
         let yt = event.transform.rescaleY(plot.yScale)  //获得缩放后的scale
         plot._yAxis.call(plot.yAxis.scale(yt))          //重新设置y坐标轴的scale
         plot.root.selectAll("path.curve")
           .attr("d", function (c) {
             return c.visible ? plot.getCurveData(c) : null
           })
         // plot._line
         //.x(function (d) { //获取曲线，用新的x尺度来计算line
         //  return t(d.x)
         //})
         // .y(function (d) { //获取曲线，用新的y尺度来计算line
         //  return yt(d.y)
         //}) );
       });
     select(this.svgElement).call(this.zoomCtrl); */
  }

  zoomIn = () => {
    this._zoom(1.272);
  }

  zoomOut = () => {
    this._zoom(1 / 1.272);
  }

  _zoom = (ratio, x, y) => {

    let xc, yc, scale;
    if (x === undefined || y === undefined) {

      // relative zoom, responding to the zoom button, zoom about the screen center
      // The assumption is that the center location is not changed after the zoom.
      // before zooming, the center coordinate is
      //     (1)   Xc = x * s1 + dx1
      // where Xc is the center coordinate of the SVG view box, x is actual coordinate
      // in the g element of zoom control, s1 is the previous scaling, and dx1 is the
      // previous shift.
      // From (1) we can calculate the actual coordinate as
      //     (2)   x = (Xc - dx1) / s1
      // Now after the zoom, the center coordinate remain the same, so we have
      //     (3)   Xc = x * s2 + dx2
      // where s2 and dx2 is the new scaling and shifting, respectively. The scaling
      // factor is known, so we only need to determine the shifting.
      // Substitute (2) into (3) leads to
      //     (4)   dx2 = Xc * (1 - s2 / s1) + dx1 * s2 / s1
      //

      const t = zoomTransform(this.svgElement);
      xc = this.viewBoxXc * (1 - ratio) + t.x * ratio;
      yc = this.viewBoxYc * (1 - ratio) + t.y * ratio;

      // remember this setting in the zoom control object
      // this.zoomCtrl.translate([xc, yc]);
      scale = t.k * ratio;
      // get the scale from the zoom object so that it won't be out of bound
      if (scale < 0.05) scale = 0.05;
      else if (scale > 300) scale = 200;
    } else {
      // Responding to the mouse event, the shifting and scaling are calculated by
      // d3. In this mode, the parameter 'ratio' is the actual scaling factor.
      xc = x;
      yc = y;
      scale = ratio;
    }
    const svgEle = this.root.select(`g.${this.id}-result-svg-viewer-g`)
    this.svgElement.__zoom = new zoomIdentity.constructor(scale, xc, yc);
    svgEle.attr('transform', 'translate(' + xc + ' ' + yc + ') scale(' + scale + ',' + scale + ')');
    const plot = this;

    let t = this.svgElement.__zoom.rescaleX(plot.xScale)  //获得缩放后的scale
    plot._xAxis.call(plot.xAxis.scale(t))                 //重新设置x坐标轴的scale
    let yt = this.svgElement.__zoom.rescaleY(plot.yScale)  //获得缩放后的scale
    plot._yAxis.call(plot.yAxis.scale(yt))       //重新设置y坐标轴的scale
    plot.root.selectAll("path.curve")
      .attr("d", function (c) {
        return c.visible ? plot.getCurveData(c) : null
      })
    /* plot._line
      .x(function (d) { //获取曲线，用新的x尺度来计算line
        return t(d.x)
      })
      .y(function (d) { //获取曲线，用新的y尺度来计算line
        return yt(d.y)
      })) */
  }

  fitView = () => {
    // re-calculate the bounding box
    this.resetPlot();
    this.redrawCurves()

    this.svgElement.__zoom = new zoomIdentity.constructor(1, 0, 0);
  }

  createAxis(names) {
    names = (names && names.length > 1) ? names : ['x', 'y'];
    names.forEach((name) => {
      this.axis.push(new Axis(name));
    });
  }

  // Update X/Y axis range
  updateAxisRange = (type, axis, redraw = true) => {
    if (isNaN(axis.start) || isNaN(axis.end)) return;
    const plot = this;
    // Get mix,max
    let min = Math.min(axis.start, axis.end);
    let max = Math.max(axis.start, axis.end);
    const _axis = plot.getAxis(type);
    if (_axis.getSetting().scale === 'log' && min <= 0) {
      if (type === 'x') {
        min = plot.options[`${type}Min`] || plot.getLogMinValue() || 1e-9
      } else {
        min = plot.options[`${type}Min`] || 1e-9;
      }
    }
    _axis.setRange(min, max);
    if (type === 'x') {
      // Update xScale
      plot.xScale
        .domain([min, max])
        .range([0, plot.size.width])

      // Update result-selector-slide range
      const xMax = plot.options.xMax,
        xMin = plot.options.xMin;
      let width = plot.size.width,
        startX = (min - xMin) / (xMax - xMin) * width,
        endX = (max - xMin) / (xMax - xMin) * width;

      plot.updateRange(startX, endX);
    } else if (type === 'y') {
      let setting = _axis.getSetting();

      if (setting.scale === 'degrees' || setting.scale === 'radians') {
        plot.yScale.domain(_axis.getRange(plot.options.format));
      } else if (plot.options.format === 'db') {
        plot.yScale.domain(_axis.getRange()).nice();
      } else {
        plot.yScale.domain(_axis.getRange(plot.options.format));
        if (this.id !== CASCADE) {
          plot.yScale = plot.yScale.nice();
        }
      }
      var svg = select(plot.svgElement);
      var yrange = plot.yScale.domain();
      if (plot.options.format === 'db') {
        setting.max = yrange[1];
        setting.min = yrange[0];
      }
      _axis.setRange(yrange[0], yrange[1]);
      var yPrefix = formatPrefix(Math.ceil(yrange[1] - yrange[0]));

      svg.select('.axis--y')
        .call(plot.yAxis.tickFormat(function (d) {
          return parseFloat(d.toPrecision(12));
        }));

      svg.select('.yAxisText')
        .text(displayUnit(yPrefix, _axis.unit));

      if (_axis.unit) {
        svg.select('.ylabel')
          .text(_axis.label + ' (' + _axis.unit + ')');
      } else {
        svg.select('.ylabel')
          .text(_axis.label);
      }
    }
    redraw && this.redrawCurves();
  }

  getAxis(name) {
    const axis = this.axis;
    return axis.find(item => item.name === name);
  }

  /** get the max and min data
   *  @param axisName  - name of the axis
   *  @param type  - min or max
   */
  getValue(axisName, type, scale) {
    const curves = this.curves;
    const plot = this
    const fn = d3[type];
    const _filter = curves.filter(item => item.curveType && (item.curveType === "target" || item.curveType === 'optTarget'));
    if (axisName === 'y' && type === 'max') {
      let maxY = max(curves, function (c) {
        return max(c[axisName]);
      });

      if (scale === 'log') {
        maxY = (maxY * 1.1) > 1 ? maxY : (maxY * 1.1);
      } else {
        maxY = maxY * 1.1;
      }
      return maxY;
    } else if (axisName === 'y' && type === 'min') {
      let ymin = min(curves, function (c) {
        return min(c[axisName]);
      });

      if (this.options.format !== 'db' && scale !== 'log') {
        ymin = ymin < 0 ? ymin : 0;
      }
      return ymin;
    } else if (_filter.length > 0 && axisName === 'x') {
      return fn(_filter, function (c) {
        return fn(c[axisName]);
      });
    }
  }

  getType() {
    if (this.curves.length > 0) {
      return this.curves[0].type;
    } else {
      return '';
    }
  }

  line(curve, indexArray) {
    const xData = curve.getData('x'),
      yData = curve.getData('y'),
      // TODO
      xScale = getScaleFn(this, 'x'),
      yScale = getScaleFn(this, 'y');
    let fn = null;
    if (this.id.includes('FastPI') && curve.curveType !== 'target' && curve.curveType !== 'optTarget') {
      fn = line()
        .x(function (i) {
          return xScale(xData[i]);
        })
        .y(function (i) {
          return yScale(yData[i]);
        })
        .curve(curveMonotoneX);
    } else {
      fn = line()
        .x(function (i) {
          return xScale(xData[i]);
        })
        .y(function (i) {
          return yScale(yData[i]);
        })
    }
    this._line = fn;
    return fn(indexArray);
  }

  // isDraw：When modifying display, the control does not render the line segment
  crossReferenceLine({ lineDatas, displayMode, isShowReferenceLine, redrawType, isDraw = true, showPointType = [] }) {
    const plot = this;
    const xAxis = plot.getAxis('x'), yAxis = plot.getAxis('y');
    const xSetting = xAxis.getSetting(), ySetting = yAxis.getSetting();
    // If you choose not to show or the displayMode does not Default
    if (!isShowReferenceLine || displayMode === "Default" || (displayMode && displayMode !== this.reference.displayMode)) {
      this.reference.removeLine(`${this.reference.plotId}-reference-line`);
    }
    this.reference.setValue({
      plotId: plot.id,
      lineDatas,
      displayMode,
      isShow: isShowReferenceLine,
      height: plot.size && plot.size.height,
      width: plot.size && plot.size.width,
      padding: plot.padding,
      getYValue: plot.getYValue,
      getXValue: plot.getXValue,
      xMin: xSetting.min,
      xMax: xSetting.max,
      yMin: ySetting.min,
      yMax: ySetting.max,
      showPointType
    })
    isDraw && this.reference.drawLine(plot.rRoot, redrawType);
  }

  updateCurves(curveData, concat) {
    if (concat) {
      this.curves = this.curves.concat(curveData);
    } else {
      this.curves = curveData;
    }
  }

  setCurveColors() {
    const plot = this;
    for (let i = 0; i < plot.curves.length; i++) {
      if (!plot.curves[i].color) {
        plot.curves[i].color = defaultColor[i % defaultColor.length];
      }
    }
  }

  resetPlot() {
    const plot = this;
    if (!plot.curves.length) {
      return;
    }

    let xAxis = plot.getAxis('x'),
      yAxis = plot.getAxis('y');
    // margins
    this.padding = {
      top: plot.options.title ? 60 : 24,
      bottom: xAxis.label ? 60 : 40,
      left: yAxis.label ? 80 : 40,
      right: 50
    };

    plot.initRange();
    const value = this.id === ANDES_EYE_MASK ? Math.ceil(plot.options.xMax - plot.options.xMin) : plot.options.xMax - plot.options.xMin;
    var prefix = formatPrefix(".3", value);

    // // scaling factors
    const totalWidth = plot.svgElement.parentElement.offsetWidth - 8;
    const totalHeight = plot.id === ANDES_EYE_MASK ? plot.svgElement.parentElement.offsetHeight - 9 : plot.svgElement.parentElement.offsetHeight;
    plot.size = {
      width: totalWidth - plot.padding.left - plot.padding.right,
      height: totalHeight - plot.padding.top - plot.padding.bottom - 20
    };
    maxWidth = plot.size.width;

    let x = plot.padding.left - 0.05 * plot.size.width;
    let y = plot.padding.top + 0.05 * plot.size.height
    this.viewBoxXc = x + 0.5 * plot.size.width;
    this.viewBoxYc = y + 0.5 * plot.size.height;

    // Set x scale
    const xscale = xAxis.getSetting().scale;
    if (xscale === 'log') {
      plot.xScale = scaleLog()
        .domain(xAxis.getRange())
        .range([0, plot.size.width])
    } else {
      plot.xScale = scaleLinear()
        .domain(xAxis.getRange())
        .range([0, plot.size.width])
    };

    //  Set y scale
    const yscale = yAxis.getSetting().scale;

    if (yscale === 'log') {
      plot.yScale = scaleLog()
        .domain(yAxis.getRange(plot.options.format))
        .range([plot.size.height, 0])
      if (this.id !== CASCADE) {
        plot.yScale = plot.yScale.nice();
      }
    } else {
      if (yscale === 'degrees' || yscale === 'radians') {
        plot.yScale = scaleLinear()
          .domain(yAxis.getRange(plot.options.format))
          .range([plot.size.height, 0])
      } else {
        plot.yScale = scaleLinear()
          .domain(yAxis.getRange(plot.options.format))
          .range([plot.size.height, 0])
          .nice();
      }
    };

    if (plot.events && plot.events.changeAxis) {
      let yRange = yAxis.getRange(plot.options.format);
      let ymin = yRange[0], ymax = yRange[1];
      if (yscale !== 'degrees' || yscale !== 'radians') {
        if (yRange[0] ? (yRange[0] > 0.0001 && yRange[0] < 0.01) : (yRange[0] < -0.0001 && yRange[0] > -0.01)) {
          ymin = yRange[0].toFixed(4);
        } else if (yRange[0] >= 0.01 || yRange[0] <= -0.01) {
          ymin = yRange[0].toFixed(2);
        }

        if (yRange[1] ? (yRange[1] > 0.0001 && yRange[1] < 0.01) : (yRange[1] < -0.0001 && yRange[1] > -0.01)) {
          ymax = yRange[0].toFixed(4);
        } else if (yRange[1] >= 0.01 || yRange[1] <= -0.01) {
          ymax = yRange[1].toFixed(2);
        }
      }

      if (!yRange[0]) {
        ymin = 0;
      }

      if (!yRange[1]) {
        ymax = 0;
      }
      plot.events.changeAxis({
        yMin: ymin,
        yMax: ymax,
        yUnit: `(${yAxis.unit})`,
      })
    }


    // axis generators
    plot.xAxis = axisBottom(plot.xScale)
      .tickSizeInner(-plot.size.height)
      .tickSizeOuter(0)
      .ticks(10);

    plot.yAxis = axisLeft(plot.yScale)
      .tickSizeInner(-plot.size.width)
      .tickSizeOuter(0)
      .ticks(10);

    // ------ Start plotting ------
    // remove any existing graphics
    select(plot.svgElement).selectAll('g.curve-g').remove();

    plot.svgRoot = select(plot.svgElement)
      .attr('class', 'plot full-fill')
      .attr('width', totalWidth)
      .attr('height', totalHeight)

    if (!plot.gRoot) {
      plot.gRoot = select(plot.svgElement)
        .append('g')
        .attr('class', `${plot.id}-curve-mark-g-box`)
    }

    if (!plot.cRoot) {
      plot.cRoot = select(plot.svgElement)
        .append('g')
        .attr('class', `${plot.id}-curve-svg-box`)
    }

    if (!plot.rRoot) {
      let plotId = plot.id;
      if (plot.id === ANDES_V2) {
        plotId = 'andes-v2'
      }
      plot.rRoot = select(plot.svgElement)
        .append('g')
        .attr('class', `${plotId}-reference-line-box`)
    }

    // root element is a simple shift
    plot.root = plot.gRoot
      .append('g')
      .attr('class', 'curve-g')
      .attr('transform', 'translate(' + (['imp-pwl-current', 'cpm-plot', CASCADE].includes(this.id) ? plot.padding.left - 6 : plot.padding.left) + ',' + plot.padding.top + ')')
      .on('mouseenter', function () {
        plot.root.select('line.red-line').attr('hidden', null);
        plot.root.select(`#${plot.id}-result-X-axis`).attr('hidden', null);
      })
      .on('mousemove', mousemoveEvent)
      .on('mouseleave', function () {
        plot.root.select('line.red-line').attr('hidden', true);
        plot.root.select(`#${plot.id}-result-X-axis`).attr('hidden', true);
        if (plot.events && plot.events.cancelMove) {
          setTimeout(() => {
            plot.events.cancelMove();
          }, 80)
        }
      })
      .on('click', mouseClickEvent);

    plot.root.append('text')
      .attr('id', `${plot.id}-result-X-axis`)

    if (plot.id === 'FastPI' || plot.id === 'CASCADE') {
      //Change the position of existing Mark
      plot.mark.redraw({ xRange: plot.xScale.domain(), padding: plot.padding, height: plot.size.height })
    }

    function mousemoveEvent() {
      var x = event.offsetX - plot.padding.left,
        y = event.offsetY - plot.padding.top;

      if (x > 0 && x < plot.size.width && y > 0 && y < plot.size.height) {

        const xrange = plot.xScale.domain();
        const xm = plot.xScale.invert(x);

        // TODO:  drag dialog change the line
        plot.root
          .select('line')
          .attr('x1', x)
          .attr('x2', x);

        // Displa X-axis
        const xRangeValueInt = parseInt(xrange[1] - xrange[0]);
        const rangeLength = xRangeValueInt.toString().length;
        const _value = xrange[1] - xrange[0]
        const xPrefix = formatPrefix(`.${rangeLength}`, _value);
        let value = parseFloat(xPrefix(xm));
        if (plot.id === ANDES_EYE_MASK) {
          value = parseFloat(xm.toFixed(2));
        }
        let text = value;
        if (value < 0.01 || value > 999) {
          text = formatExponential(text);
        } else {
          text = text.toFixed(2);
        }
        plot.root
          .select(`#${plot.id}-result-X-axis`)
          .attr('x', x - 20)
          .attr('y', -5)
          .text(text)

        if (plot.events && plot.events.changeMouse) {
          setTimeout(() => {
            plot.events.changeMouse(xm);
          }, 50)
        }
      }
    }


    function mouseClickEvent() {
      if (plot.id !== 'FastPI' && plot.id !== 'CASCADE') {
        return;
      }
      var x = event.offsetX - plot.padding.left,
        y = event.offsetY - plot.padding.top;

      if (x >= 0 && x <= plot.size.width && y >= 0 && y <= plot.size.height) {
        const xrange = plot.xScale.domain();
        //xm= plot.xScale.invert(x)   //curveX is calculated by the interval scale and xm
        const targetX = plot.xScale.invert(x);
        // Displa X-axis
        const xRangeValueInt = parseInt(xrange[1] - xrange[0]);
        const rangeLength = xRangeValueInt.toString().length;
        const value = this.id === ANDES_EYE_MASK ? Math.ceil(xrange[1] - xrange[0]) : xrange[1] - xrange[0];
        let xPrefix = formatPrefix(`.${rangeLength}`, value);
        //unit
        let xAxis = plot.getAxis('x'),
          xUnit = displayUnit(xPrefix, xAxis.unit); // MHz
        //Remove the brackets around the unit
        let prevX = x;
        let xValue = valueFormat(xUnit.slice(1, xUnit.length - 1), parseFloat(xPrefix(targetX)))

        x = x + plot.padding.left;

        // draw mark line
        plot.mark.createMarkLine({
          plotId: plot.id,
          prevX,
          root: plot.cRoot,
          x,
          padding: plot.padding,
          height: plot.size.height,
          xValue,
          curves: plot.curves,
          targetX,
          getYValue: plot.getYValue,
          getXValue: plot.getXValue
        });
      }
    }
    // plot area
    plot.root.append('rect')
      .attr('width', plot.size.width)
      .attr('height', plot.size.height)
      .attr('fill', plot.options.background)
      .attr('stroke', '#ccc');

    //add Chart Title
    if (plot.options.title) {
      plot.root.append('text')
        .text(plot.options.title)
        .attr('x', plot.size.width / 2)
        .attr('dy', '-0.8em')
        .style('text-anchor', 'middle');
    }

    // Add the x-axis label
    let unit = displayUnit(prefix, xAxis.unit);
    if (!unit && plot.id === ANDES_EYE_MASK) {
      unit = xAxis.unit
    }
    if (xAxis.label) {
      plot.root.append('text')
        .attr('class', 'xlabel')
        .text(xAxis.label + ' ' + unit)
        .attr('x', plot.size.width / 2)
        .attr('y', plot.size.height)
        // .attr('y', plot.size.height + padding.top)
        .attr('dy', '2.4em')
        .style('text-anchor', 'middle')
        .style("font-size", "15px");

    }

    // // add y-axis label
    if (yAxis.label && yAxis.unit) {
      plot.root.append('text')
        .attr('class', 'ylabel')
        .text(yAxis.label + ' (' + yAxis.unit + ')')
        .style('text-anchor', 'middle')
        .attr('transform', 'translate(' + -56 + ' ' + plot.size.height / 2 + ') rotate(-90)')
        .style('font-size', '15px');
    }

    if (yAxis.label && !yAxis.unit) {
      plot.root.append('text')
        .attr('class', 'ylabel')
        .text(yAxis.label)
        .style('text-anchor', 'middle')
        .attr('transform', 'translate(' + -56 + ' ' + plot.size.height / 2 + ') rotate(-90)')
        .style('font-size', '15px');
    }

    plot.root.append('line')
      .attr("class", "red-line")
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', plot.size.height)
      .attr('stroke', '#ff0000')
      .attr('hidden', true)
      .attr('stroke-width', 2)
      .attr('id', `${plot.id}-result-X-line`);


    // initialize the range selector
    var selectorEle = plot.root.append('g').attr('id', `${plot.id}-result-selector`);
    location.startX = 0;
    location.endX = plot.size.width;
    if (plot.events && plot.events.changeAxis) {
      changeAxis();
    }
    // add background
    selectorEle
      .attr('transform', 'translate(0,' + (plot.size.height + 46) + ')')
      .append('rect')
      .attr('class', 'selector-backgroud')
      .attr('width', plot.size.width + 5)
      .attr('height', 15);

    let xmin = plot.options.xMin ? plot.options.xMin : xAxis.getRange()[0];
    let xmax = plot.options.xMax ? plot.options.xMax : xAxis.getRange()[1];
    let portExist = plot.curves && plot.curves.length > 0 ? true : false;
    const _value = this.id === ANDES_EYE_MASK ? Math.ceil(xmax - xmin) : xmax - xmin;
    const selectXPrefix = formatPrefix(".1", _value);

    function getData(value) {
      let _value = value
      if (value) {
        if (_value < 0.01 || _value > 999) {
          _value = formatExponential(_value);
        } else {
          _value = _value.toFixed(2);
        }
      }
      return _value
    }
    /*  const selectXPrefix = formatPrefix(".1", plot.options.xMax - plot.options.xMin); */
    // add xmin and xMax
    let xMinText = selectXPrefix(xmin), xMaxText = selectXPrefix(xmax);

    xMinText = getData(xmin);
    xMaxText = getData(xmax)

    const axisFont = plot.id === "Sierra" && plot.size.width >= 500 ? "15px" : '14px';
    selectorEle
      .append('text')
      .attr('x', -5)
      .attr('y', 12)
      .text(xMinText)
      .style('text-anchor', 'end')
      .style('font-size', axisFont);
    selectorEle
      .append('text')
      .attr('x', plot.size.width + 10)
      .attr('y', 12)
      .text(xMaxText)
      .style('text-anchor', 'start')
      .style('font-size', axisFont);

    plot.displaySlideRangeText(selectorEle, selectXPrefix);
    // add slide
    var slideDrag = drag().on('drag', function () {
      selectAll(`.${plot.id}-result-slide-range-text`).remove();
      var element = select(this),
        x = Math.min(location.startX, location.endX) + event.dx,
        width = Math.abs(location.endX - location.startX),
        max = plot.size.width - width;

      if (x < 0) {
        location.endX = max > 0 ? max : width;
        location.startX = 0;
      } else if (x > max) {
        location.startX = max;
        location.endX = plot.size.width;
      } else {
        location.startX += event.dx;
        location.endX += event.dx;
      }

      element.attr('x', Math.min(location.startX, location.endX));
      select(`#${plot.id}-result-startX`).attr('x', location.startX);
      select(`#${plot.id}-result-endX`).attr('x', location.endX);
      displayRangeText();
      if (plot.events && plot.events.changeAxis) {
        changeAxis();
      }
    }).on('end', function () {
      var max = plot.options.xMax,
        min = plot.options.xMin,
        dx = max - min,
        start = location.startX / plot.size.width * dx + min,
        end = location.endX / plot.size.width * dx + min;
      xAxis.setRange(Math.min(start, end), Math.max(start, end));
      plot.xScale.domain(xAxis.getRange());
      plot.redrawCurves();
      rangeTextShow = false;
      selectAll(`.${plot.id}-result-range-text`).remove();
      plot.displaySlideRangeText(selectorEle, selectXPrefix);
    });

    function changeAxis() {
      let max = plot.options.xMax,
        min = plot.options.xMin,
        width = plot.size.width;
      const xMin = format(".4g")((Math.min(location.startX, location.endX) / width * (max - min) + min) / (plot.options.ui ? plot.options.ui : 1)),
        xMax = format(".4g")((Math.max(location.startX, location.endX) / width * (max - min) + min) / (plot.options.ui ? plot.options.ui : 1));
      plot.events.changeAxis({
        xMin,
        xMax,
      })
    }
    if (portExist) {
      selectorEle
        .append('rect')
        .attr('class', `${plot.id}-result-selector-slide`)
        .attr('width', plot.size.width)
        .style('fill', '#1790ff')
        .attr('x', 0)
        .attr('height', 15)
        .call(slideDrag);
    } else {
      selectorEle
        .append('rect')
        .attr('class', `${plot.id}-result-selector-slide`)
        .attr('width', plot.size.width)
        .style('fill', '#1790ff')
        .attr('x', 0)
        .attr('height', 15)
    }
    plot.displaySlideRangeText(selectorEle, selectXPrefix);

    // set traveller drag event
    var rangeTextShow = false;
    var travellerDrag = drag().on('drag', function () {
      selectAll(`.${plot.id}-result-slide-range-text`).remove();
      var element = select(this),
        id = element.attr('id'),
        x = event.x;
      let locationId = '';
      if (id === `${plot.id}-result-endX`) {
        locationId = 'endX';
      } else {
        locationId = 'startX';
      }

      if (x < 0) location[locationId] = 0;
      else if (x > plot.size.width) location[locationId] = plot.size.width;
      else location[locationId] = x;

      element.attr('x', location[locationId]);
      select(`.${plot.id}-result-selector-slide`)
        .attr('x', Math.min(location.startX, location.endX))
        .attr('width', Math.abs(location.endX - location.startX));

      displayRangeText();
      if (plot.events && plot.events.changeAxis) {
        changeAxis();
      }
    }).on('end', function () {
      var max = plot.options.xMax,
        min = plot.options.xMin,
        dx = max - min,
        start = location.startX / plot.size.width * dx + min,
        end = location.endX / plot.size.width * dx + min;
      xAxis.setRange(Math.min(start, end), Math.max(start, end));
      plot.xScale.domain(xAxis.getRange());
      plot.redrawCurves();

      rangeTextShow = false;
      selectAll(`.${plot.id}-result-range-text`).remove();
      plot.displaySlideRangeText(selectorEle, selectXPrefix)
    });

    if (portExist) {
      // add start traveller
      selectorEle
        .append('rect')
        .attr('id', `${plot.id}-result-startX`)
        .attr('class', 'selector-traveller')
        .attr('x', location.startX)
        .attr('width', 5)
        .attr('height', 15)
        .call(travellerDrag);
      // add end traveller
      selectorEle
        .append('rect')
        .attr('id', `${plot.id}-result-endX`)
        .attr('class', 'selector-traveller')
        .attr('x', location.endX)
        .attr('width', 5)
        .attr('height', 15)
        .call(travellerDrag);
    } else {
      // add start traveller
      selectorEle
        .append('rect')
        .attr('id', `${plot.id}-result-startX`)
        .attr('class', 'selector-traveller')
        .attr('x', location.startX)
        .attr('width', 5)
        .attr('height', 15)
      // add end traveller
      selectorEle
        .append('rect')
        .attr('id', `${plot.id}-result-endX`)
        .attr('class', 'selector-traveller')
        .attr('x', location.endX)
        .attr('width', 5)
        .attr('height', 15)
    }


    function displayRangeText() {
      if (!rangeTextShow) {
        rangeTextShow = true;
        selectorEle
          .append('text')
          .attr('class', `${plot.id}-result-range-text range-text-style`)
          .attr('y', 26)
          .style('text-anchor', 'end');

        selectorEle
          .append('text')
          .attr('class', `${plot.id}-result-range-text range-text-style`)
          .attr('y', 26)
          .style('text-anchor', 'start');
      }
      var textElements = selectAll(`.${plot.id}-result-range-text`)._groups[0];
      var x = Math.min(location.startX, location.endX),
        max = plot.options.xMax,
        min = plot.options.xMin,
        width = plot.size.width;
      select(textElements[0])
        .attr('x', x - 5)
        .text(selectXPrefix((x / width * (max - min) + min)));
      x = Math.max(location.startX, location.endX)
      select(textElements[1])
        .attr('x', x + 10)
        .text(selectXPrefix((x / width * (max - min) + min)));
    }

    // if (this.reference && this.reference.isShow) {
    //   const xAxis = plot.getAxis('x'), yAxis = plot.getAxis('y');
    //   const xSetting = xAxis.getSetting(), ySetting = yAxis.getSetting();
    //   this.reference.setValue({
    //     height: plot.size.height,
    //     width: plot.size.width,
    //     padding: plot.padding,
    //     getYValue: plot.getYValue,
    //     getXValue: plot.getXValue,
    //     xMin: xSetting.min,
    //     xMax: xSetting.max,
    //     yMin: ySetting.min,
    //     yMax: ySetting.max,
    //   });
    //   this.reference.drawLine(plot.rRoot);
    // }

    this.zoom()
    this.resetFlag = true;
  } // resetPlot

  displaySlideRangeText(selectorEle, selectXPrefix) {
    if (!selectorEle) {
      return;
    }
    selectAll(`.${this.id}-result-slide-range-text`).remove();
    const xAxis = this.getAxis('x');
    let xmin = this.options.xMin ? this.options.xMin : xAxis.getRange()[0];
    let xmax = this.options.xMax ? this.options.xMax : xAxis.getRange()[1];
    const value = this.id === ANDES_EYE_MASK ? Math.ceil(xmax - xmin) : xmax - xmin;
    let _selectXPrefix = selectXPrefix ? selectXPrefix : formatPrefix(".1", value);

    let x = Math.min(location.startX, location.endX),
      max = this.options.xMax,
      min = this.options.xMin,
      width = this.size.width,
      maxX = Math.max(location.startX, location.endX);

    if (x > 0) {
      selectorEle
        .append('text')
        .attr('class', `${this.id}-result-slide-range-text range-text-style`)
        .attr('y', 26)
        .style('text-anchor', 'start')
        .attr('x', x - 20)
        .text(_selectXPrefix((x / width * (max - min) + min)));
    }
    if (maxX < width) {
      selectorEle
        .append('text')
        .attr('class', `${this.id}-result-slide-range-text range-text-style`)
        .attr('y', 26)
        .style('text-anchor', 'end')
        .attr('x', maxX + 20)
        .text(_selectXPrefix((maxX / width * (max - min) + min)));
    }
  }

  initRange() {
    const plot = this;
    const _filter = plot.curves.filter(item => item.curveType && (item.curveType === "target" || item.curveType === 'optTarget'));

    let xAxis = plot.getAxis('x'),
      yAxis = plot.getAxis('y');

    // data range
    if (_filter.length > 0) {
      plot.options.xMax = max(_filter, function (c) {
        return max(c.x);
      });
      plot.options.xMin = min(_filter, function (c) {
        return min(c.x);
      });
    } else {
      plot.options.xMax = max(plot.curves, function (c) {
        return max(c.x);
      });
      plot.options.xMin = min(plot.curves, function (c) {
        return min(c.x);
      });
    }

    plot.options.yMin = min(plot.curves, function (c) {
      return min(c.y);
    });

    if (plot.options.format && plot.options.format !== 'db' && yAxis.setting.scale !== 'log') {
      plot.options.yMin = plot.options.yMin < 0 ? plot.options.yMin : 0;
    }
    plot.options.yMax = max(plot.curves, function (c) {
      return max(c.y);
    });
    if (yAxis.setting.scale === 'degrees') {
      plot.options.yMin = radiansToDegrees(plot.options.yMin);
      plot.options.yMax = radiansToDegrees(plot.options.yMax);
    }
    if (plot.options.yMax && yAxis.setting.scale === 'log') {
      plot.options.yMax = NP.times(plot.options.yMax, 1.1) > 1 ? plot.options.yMax : NP.times(plot.options.yMax, 1.1);
    } else if (plot.options.yMax) {
      plot.options.yMax = NP.times(plot.options.yMax, 1.1)
    }

    if (plot.id === ANDES_EYE_MASK && plot.options.yMax) {
      plot.options.yMin = plot.options.yMin - NP.times(plot.options.yMax, 0.1);
      plot.options.xMin = plot.options.xMin - NP.times(plot.options.xMax, 0.1)
      plot.options.xMax = NP.times(plot.options.xMax, 1.1)
    }
    xAxis.setRange(plot.options.xMin, plot.options.xMax);
    yAxis.setRange(plot.options.yMin, plot.options.yMax);
  }

  redrawCurves() {

    let plot = this;
    if (!plot.xScale) return;

    const xAxisObj = plot.getAxis('x');
    const yAxisObj = plot.getAxis('y');

    if (xAxisObj.getSetting().scale === 'log') {
      let xMax = plot.options.xMax;
      let xMin = plot.options.xMin;
      if (Number(xMin) <= 0) {
        plot.updateAxisRange('x', { start: plot.getLogMinValue() || 1e-9, end: xMax }, false);
      }
      plot.curves.forEach(curve => {
        if (Number(curve.x[0]) <= 0) {
          curve.x[0] = plot.getLogMinValue() || 1e-9;
        }
      })
    }

    if (yAxisObj.getSetting().scale === 'log') {
      let yMax = plot.options.yMax;
      let yMin = plot.options.yMin;
      if (Number(yMin) <= 0) {
        plot.updateAxisRange('y', { start: 1e-9, end: yMax }, false);
      }
      plot.curves.forEach(curve => {
        if (Number(curve.y[0]) <= 0) {
          curve.y[0] = 1e-9;
        }
      })
    }

    if (plot.id === 'FastPI' || plot.id === 'CASCADE') {
      //Change the position of existing Mark
      const xRange = plot.xScale.domain();
      plot.mark.redraw({ xRange, padding: plot.padding, height: plot.size.height });
    }

    // Replot the axis
    plot.root.selectAll('g.axis').remove();
    var xrange = plot.xScale.domain();
    var yrange = plot.yScale.domain();
    const xRangeValueInt = parseInt(xrange[1] - xrange[0]);
    const xRangeLength = xRangeValueInt.toString().length;
    const value = this.id === ANDES_EYE_MASK ? Math.ceil(xrange[1] - xrange[0]) : xrange[1] - xrange[0];
    let xPrefix = formatPrefix(`.${xRangeLength}`, value);
    // axis generators
    let xRatio = 0;

    if (xrange[0] > 0) {
      xRatio = parseInt(xrange[1] / xrange[0]).toString().length;
    } else {
      xRatio = parseInt(xrange[1]).toString().length;
    }
    let xAxisNum = 4, maxNumX = 9;
    if (plot.size.width < 900) {
      maxNumX = 7;
    }

    if (plot.size.width < 500) {
      maxNumX = 5;
    }

    if (xRatio >= maxNumX) {
      xAxisNum = 1;
    } else {
      xAxisNum = maxNumX - xRatio;
    }

    let yRatio = 0;

    if (yrange[0] > 0) {
      yRatio = parseInt(yrange[1] / yrange[0]).toString().length;
    } else {
      yRatio = parseInt(yrange[1]).toString().length;
    }
    let yAxisNum = 4, maxNumY = 9;

    if (plot.size.height < 500) {
      maxNumY = 7;
    }

    if (plot.size.height < 180) {
      maxNumY = 5;
    }
    if (plot.size.height < 90) {
      maxNumY = 3;
    }

    if (yRatio >= maxNumY) {
      yAxisNum = 1;
    } else {
      yAxisNum = maxNumY - yRatio;
    }

    let multipleY = 1, multipleX = 1;

    if (plot.size.height < 480) {
      multipleY = 2;
    }
    if (plot.size.height <= 220) {
      multipleY = 3;
    }
    if (plot.size.height <= 120) {
      multipleY = 5;
    }

    if (plot.size.width < 600) {
      multipleX = 2;
    }
    if (plot.size.width <= 300) {
      multipleX = 3;
    }
    if (plot.size.width <= 100) {
      multipleX = 5;
    }

    plot.xAxis = axisBottom(plot.xScale)
      .tickSizeInner(-plot.size.height)
      .tickSizeOuter(0)
      .ticks(10)
      .tickFormat(function (d) {
        const num = parseFloat(xPrefix(d));
        if (_xScale === 'log') {
          if (countxAxis % 9 < xAxisNum) {
            if (num > 999 || (countxAxis > 50 && num < 0.1) || (countxAxis < 50 && num < 0.01)) {
              countxAxis++;
              return formatExponential(num);
            } else {
              countxAxis++;
              return num;
            }
          } else {
            countxAxis++;
            return '';
          }
        } else {
          if (countxAxis % multipleX === 0) {
            countxAxis++;
            return num;
          } else {
            countxAxis++;
          }

          if (num === xrange[1] && multipleX !== 5) {
            return num;
          }
        }
      })
    plot.yAxis = axisLeft(plot.yScale)
      .tickSizeInner(-plot.size.width)
      .tickSizeOuter(0)
      .ticks(10)
      .tickFormat(function (d) {
        const num = parseFloat(d.toPrecision(12));
        if (_yScale === 'log') {
          if (countyAxis % 9 < yAxisNum) {
            if (num > 999 || (countyAxis > 50 && num < 0.01) || (countyAxis < 50 && num < 0.001)) {
              countyAxis++;
              return formatExponential(num);
            } else {
              countyAxis++;
              return num;
            }
          } else {
            countyAxis++;
            return '';
          }
        } else {
          if (countyAxis % multipleY === 0) {
            countyAxis++;
            return num;
          } else {
            countyAxis++;
          }

          if (num === yrange[1] && multipleY !== 5) {
            return num;
          }
        }
      });
    let countxAxis = 0, countyAxis = 0;
    const _xAxis = plot.getAxis('x');
    var _xScale = _xAxis.getSetting().scale;
    const _yAxis = plot.getAxis('y');
    var _yScale = _yAxis.getSetting().scale;
    plot._xAxis = plot.root.append('g')
      .attr('class', 'axis axis--x')
      .attr("transform", 'translate(0, ' + plot.size.height + ')')
      .call(plot.xAxis);

    const axisFont = plot.id === "Sierra" && plot.size.width >= 500 ? "15px" : '14px';
    plot._xAxis.selectAll("text")
      .attr("y", 8)
      .style('font-size', axisFont)
      .style('color', '#000');

    plot._yAxis = plot.root.append('g')
      .attr('class', 'axis axis--y')
      .call(plot.yAxis/* .tickFormat(function (d) {
        return parseFloat(d.toPrecision(12));
      }) */);

    plot.root.selectAll('.tick')
      .attr("stroke-dasharray", "2,2");

    let unit = displayUnit(xPrefix, xAxisObj.unit);
    if (!unit && plot.id === ANDES_EYE_MASK) {
      unit = xAxisObj.unit
    }
    plot.root.select('.xlabel')
      .text(xAxisObj.label + ' ' + unit)

    plot._yAxis.selectAll("text")
      .attr("x", -8)
      .style('font-size', axisFont)
      .style('color', '#000');

    plot.root.select(`svg.${plot.id}-result-svg-viewer`).remove();
    plot.root.append('svg')
      .attr('class', `${plot.id}-result-svg-viewer`)
      .attr('top', 0)
      .attr('left', 0)
      .attr('width', plot.size.width)
      .attr('height', plot.size.height)
      .attr('viewBox', '0 0 ' + plot.size.width + ' ' + plot.size.height)
      .append("g")
      .attr('class', `${plot.id}-result-svg-viewer-g`)
      .selectAll('path')
      .data(plot.curves)
      .enter()
      .append('path')
      .attr('class', 'curve')
      .attr('stroke-width', 2);

    plot.root.selectAll('path')
      .attr('stroke', '#ccc');
    // redraw the curves
    var maxPoint = null,
      minPoint = null;
    plot.root.selectAll('path.curve')
      .data(plot.curves)
      .attr('stroke', function (c) {
        return c.color;
      })
      .attr('d', function (c) {
        return c.visible ? plot.getCurveData(c) : null;
      })
      .attr('stroke-dasharray', function (c) {
        if (c.curveType === 'optTarget') {
          return "5,5"
        }
      })

    /*  function getCurveData(curve) {
       var xrange = plot.getAxis('x').getRange();
       const [min, max] = xrange;
       let points = [];
       const xData = curve.x;
       for (let i = 0, length = xData.length; i < length; i++) {
         if (curve.curveType === 'target' || curve.curveType === 'optTarget' || curve.y.length <= 20) {
           points.push(i);
         } else {
           const x = xData[i];
           if (x > max) {
             break;
           } else if (x >= min) {
             points.push(i);
           }
         }
       }
       if (curve.y.length === 2) {
         points = [0, 1]
       }
       return plot.line(curve, points);
     } */

    var curveSvg = plot.root.select('svg');
    curveSvg.selectAll('circle').remove();
    curveSvg.selectAll('text').remove();

    // add max and min point
    var cx, cy;
    if (maxPoint !== null) {
      cx = plot.xScale(maxPoint.x);
      cy = plot.yScale(maxPoint.y);
      curveSvg.append('circle')
        .attr('class', `${plot.id}-result-curve-mark`)
        .attr('cx', cx)
        .attr('cy', cy)
        .attr('r', 2);
      curveSvg.append('text')
        .attr('x', cx)
        .attr('y', cy - 4)
        .text(maxPoint.y.toFixed(2))
        .attr('class', `${plot.id}-result-mark-text`);
    }

    if (minPoint !== null) {
      cx = plot.xScale(minPoint.x);
      cy = plot.yScale(minPoint.y);
      curveSvg.append('circle')
        .attr('class', `${plot.id}-result-curve-mark`)
        .attr('cx', cx)
        .attr('cy', cy)
        .attr('r', 2);
      curveSvg.append('text')
        .attr('x', cx)
        .attr('y', cy + 12)
        .text(minPoint.y.toFixed(2))
        .attr('class', `${plot.id}-result-mark-text`);
    }


    // terminate the events
    if (event && event.keyCode) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.reference && this.reference.isShow) {
      const drawTypes = this.options.format === 'db' ? ['x', 'y', 'points'] : ['x'];
      this.reference.setValue({
        height: plot.size.height,
        width: plot.size.width,
        padding: plot.padding,
        getYValue: plot.getYValue,
        getXValue: plot.getXValue,
        xMin: xrange[0],
        xMax: xrange[1],
        yMin: yrange[0],
        yMax: yrange[1],
        drawTypes
      });
      this.reference.drawLine(plot.rRoot);
    }
    this.zoom()
  }

  getCurveData(curve) {
    const plot = this;
    var xrange = plot.getAxis('x').getRange();
    const [min, max] = xrange;
    let points = [];
    const xData = curve.x;
    for (let i = 0, length = xData.length; i < length; i++) {
      if (curve.curveType === 'target' || curve.curveType === 'optTarget' || curve.y.length <= 20) {
        points.push(i);
      } else {
        const x = xData[i];
        if (x > max) {
          break;
        } else if (x >= min) {
          points.push(i);
        }
      }
    }
    if (curve.y.length === 2) {
      points = [0, 1]
    }
    return plot.line(curve, points);
  }

  removeCurve({ row, col, id, hashId }) {
    const index = this.curves.findIndex(curve => curve.id === id && curve.row === row && curve.col === col);
    if (index > -1) {
      this.curves.splice(index, 1);
      if (hashId) {
        this.mark.removeMarkOnCurve(hashId)
      }
      this.resetPlot();
      this.redrawCurves();
    } else {
      // remove fail
      return true;
    }
  }

  removeCurves(curves) {
    if (!curves.length) return;
    curves.forEach(({ row, col, id, hashId }) => {
      const index = this.curves.findIndex(curve => curve.id === id && curve.row === row && curve.col === col);
      this.curves.splice(index, 1);
      if (hashId) {
        this.mark.removeMarkOnCurve(hashId)
      }
    })
    this.resetPlot();
    this.redrawCurves();
  }

  clearCurveMark() {
    this.mark.clearAll()
  }

  updateRange(startX, endX) {
    let width = endX - startX;
    if (width > maxWidth) {
      width = maxWidth;
    }
    if (endX > maxWidth) {
      endX = maxWidth
    }
    if (startX < 0) {
      startX = 0;
    }
    location.startX = startX;
    location.endX = endX;
    this.root
      .select(`#${this.id}-result-startX`)
      .attr('x', startX);

    this.root
      .select(`#${this.id}-result-endX`)
      .attr('x', endX);

    this.root
      .select(`.${this.id}-result-selector-slide`)
      .attr('width', width)
      .attr('x', startX);
    this.displaySlideRangeText(this.root.select(`#${this.id}-result-selector`))
  }

  updateYAxis({ param, value, scale }) {
    const plot = this;
    if (!plot.size) return;

    plot.options.parameter = param;
    plot.options.format = value;
    // yAxis - { label, name, range: [start, end], setting: { max, min, scale }, unit }
    var yAxis = plot.getAxis('y');

    // Set y aixs scale - log|linear
    yAxis.setScale(scale);

    // Get mix,max rang 
    var setting = yAxis.getSetting();
    var min, max;
    // todo scale
    switch (value) {
      case 'db':
        if (param === 's') {
          min = plot.getValue('y', 'min', scale);
          max = plot.getValue('y', 'max', scale);
          /* min = Math.min(plot.getValue('y', 'min', scale), 10 ** -2.5);
          max = Math.max(plot.getValue('y', 'max', scale), 0.1); */
          break;
        } else {
          switch (scale) {
            case 'linear':
              min = plot.getValue('y', 'min', scale);
              max = plot.getValue('y', 'max', scale);
              break;
            case 'log':
              min = plot.getValue('y', 'min', scale);
              max = plot.getValue('y', 'max', scale);
              break;
            default: break;
          }
        }
        break;
      case 'am':
        if (param === 's') {
          switch (scale) {
            case 'linear':
              min = plot.getValue('y', 'min', scale);
              max = plot.getValue('y', 'max', scale);
              break;
            case 'log':
              min = plot.getValue('y', 'min', scale);
              max = 1;
              break;
            default: break;
          }
        } else {
          switch (scale) {
            case 'linear':
              min = 0;
              max = plot.getValue('y', 'max', scale);
              break;
            case 'log':
              min = plot.getValue('y', 'min', scale);
              max = plot.getValue('y', 'max', scale);
              break;
            default: break;
          }
        }
        break;
      case 'ph':
        if (scale === 'radians') {
          min = -Math.PI;
          max = Math.PI;
        } else if (scale === 'degrees') {
          min = -180;
          max = 180;
        }
        break;
      default:
        min = plot.getValue('y', 'min', scale);
        max = plot.getValue('y', 'max', scale);
        break;
    }
    // Update y axis range
    yAxis.setRange(min, max);
    plot.options.yMin = min
    plot.options.yMax = max
    // Set y scale
    if (scale === 'log') {
      plot.yScale = scaleLog()
        .domain(yAxis.getRange(plot.options.format))
        .range([plot.size.height, 0]);
      if (this.id !== CASCADE) {
        plot.yScale = plot.yScale.nice();
      }
    } else {
      if (scale === 'degrees' || scale === 'radians') {
        plot.yScale = scaleLinear()
          .domain(yAxis.getRange(plot.options.format))
          .range([plot.size.height, 0])
      } else {
        plot.yScale = scaleLinear()
          .domain(yAxis.getRange(plot.options.format))
          .range([plot.size.height, 0])
          .nice();
      }
    };

    // Update the range value in the input box
    let yRange = yAxis.getRange(plot.options.format);
    let ymin = yRange[0], ymax = yRange[1];
    if (scale !== 'degrees' || scale !== 'radians') {
      if (yRange[0] ? (yRange[0] > 0.0001 && yRange[0] < 0.01) : (yRange[0] < -0.0001 && yRange[0] > -0.01)) {
        ymin = yRange[0].toFixed(4);
      } else if (yRange[0] >= 0.01 || yRange[0] <= -0.01) {
        ymin = yRange[0].toFixed(2);
      }

      if (yRange[1] ? (yRange[1] > 0.0001 && yRange[1] < 0.01) : (yRange[1] < -0.0001 && yRange[1] > -0.01)) {
        ymax = yRange[0].toFixed(4);
      } else if (yRange[1] >= 0.01 || yRange[1] <= -0.01) {
        ymax = yRange[1].toFixed(2);
      }
    }

    if (!yRange[0]) {
      ymin = 0;
    }

    if (!yRange[1]) {
      ymax = 0;
    }
    plot.events.changeAxis({
      yMin: ymin,
      yMax: ymax,
    });

    var svg = select(plot.svgElement);
    var yrange = plot.yScale.domain();

    if (plot.options.format === 'db') {
      setting.max = yrange[1];
      setting.min = yrange[0];
    }

    let yPrefix = null;
    if (yrange[1] - yrange[0]) {
      yPrefix = formatPrefix(Math.ceil(yrange[1] - yrange[0]));
    }
    svg.select('.axis--y')
      .call(plot.yAxis.tickFormat(function (d) {
        return parseFloat(d.toPrecision(12));
      }));

    if (yPrefix) {
      svg.select('.yAxisText')
        .text(displayUnit(yPrefix, yAxis.unit));
    } else {
      svg.select('.yAxisText')
        .text('');
    }

    if (yAxis.unit) {
      svg.select('.ylabel')
        .text(yAxis.label + ' (' + yAxis.unit + ')');
    } else {
      svg.select('.ylabel')
        .text(yAxis.label);
    }

    this.redrawCurves();
  }

  getLogMinValue = () => {
    const plot = this;
    if (plot.id === CASCADE || plot.id === PACKAGE_PDN_RESULT) {
      const minxCurves = (plot.curves || []).filter(curve => curve.x[0]).filter(item => !!item);
      const findMin = minxCurves.find(item => item === 1);
      if (findMin) {
        return 0.1;
      }
      return 1;
    }
    return 1e-9;
  }

  // Change x scale
  updateXAxis({ param, value, scale }) {
    const plot = this;
    if (!plot.size) return;

    plot.options.parameter = param;
    plot.options.format = value;
    // xAxis - { label, name, range: [start, end], setting: { max, min, scale }, unit }
    const xAxis = plot.getAxis('x');

    // Set x aixs scale - log|linear6

    xAxis.setScale(scale);

    // Get mix,max rang 
    var min, max;
    if (scale === "log" && plot.options.xMin <= 0) {
      plot.options.xMin = plot.getLogMinValue() || 1e-9;
    }
    max = plot.options.xMax;
    min = plot.options.xMin;
    // Update x axis range
    xAxis.setRange(min, max);

    // Set x scale
    if (scale === 'log') {
      plot.xScale = scaleLog()
        .domain(xAxis.getRange())
        .range([0, plot.size.width])
    } else {
      plot.xScale = scaleLinear()
        .domain(xAxis.getRange())
        .range([0, plot.size.width])
    };

    // Update result-selector-slide range
    const xMax = plot.options.xMax,
      xMin = plot.options.xMin;
    let width = plot.size.width,
      startX = (min - xMin) / (xMax - xMin) * width,
      endX = (max - xMin) / (xMax - xMin) * width;
    plot.updateRange(startX, endX);
    // Update the range value in the input box
    let xRange = xAxis.getRange();
    plot.events.changeAxis({
      xMin: xRange[0],
      xMax: xRange[1],
    })
    this.redrawCurves();
  }

  resetColor({ id, row, col, color }) {
    let curve = this.curves.find(item => item.id === id && item.row === row.toString() && item.col === col.toString());
    if (curve) {
      curve.color = color;
    }
  }

  getYValue = (y) => {
    return this.yScale(y);
  }

  getXValue = (x) => {
    return this.xScale(x);
  }
}

class Axis {
  constructor(name) {
    this.name = name;
    this.range = [null, null];
    this.unit = null;
    this.label = null;
    this.setting = {
      scale: 'linear',
      min: null,
      max: null
    }
  }

  setRange(min, max) {
    if (min || min === 0) {
      this.range[0] = min;
      this.setting.min = min;
    }
    if (max || max === 0) {
      this.range[1] = max;
      this.setting.max = max;
    }
  }

  getRange(format) {
    let range = this.range;
    if (format === 'db') {
      const _range = range.map(db);
      range = [_range[0], _range[1] < 0 ? _range[1] : 0];
    } else if (this.setting.scale === 'degrees') {
      let min = Math.min(range[0], range[1])
      let max = Math.max(range[0], range[1])
      if (min < -180) {
        min = -180;
      } else if (min > 180) {
        min = 180;
      }

      if (max > 180) {
        max = 180;
      } else if (max < -180) {
        max = -180;
      }
      range = [min, max];
    } else if (this.setting.scale === 'radians') {
      let min = Math.min(range[0], range[1])
      let max = Math.max(range[0], range[1])
      if (min < -3.14) {
        min = -3.14;
      } else if (min > 3.14) {
        min = 3.14;
      }

      if (max > 3.14) {
        max = 3.14;
      } else if (max < -3.14) {
        max = -3.14;
      }
      range = [min, max];
    }
    return range;
  }

  setUnit(unit) {
    this.unit = unit;
  }

  setLabel(label) {
    this.label = label;
  }

  getSetting() {
    return this.setting;
  }

  setScale(scale) {
    this.setting.scale = scale
  }
}

export class CurveItem {
  constructor(xData, yData) {
    this.x = xData || []; // Array of Number, the x coordinates of the curve
    this.y = yData || []; // Array of Number, the y coordinates of the curve
    this.name = null; // String, curve name
    this.color = null; // String, curve color
  }

  getData = (axisName) => {
    return this[axisName];
  }

}

function getScaleFn(plot, axis) {
  const scale = plot.getAxis(axis).getSetting().scale,
    name = axis + 'Scale';
  let scaleFn = plot[name];
  if (plot.options.format === 'db' && axis === 'y') {
    scaleFn = (value) => {
      return plot[name](db(value));
    }
  } else if (scale === 'degrees') {
    scaleFn = (value) => {
      return plot[name](radiansToDegrees(value))
    }
  };
  return scaleFn;
}

function valueFormat(unit, value) {
  let _value = value;
  if (_value > 999) {
    if (unit === 'MHz') {
      _value = _value.toExponential(2) + unit.slice(0, 1);
    } else if (unit === 'KHz') {
      _value = NP.strip(NP.times(_value, 1e-3));
      if (_value > 999) {
        _value = _value.toExponential(2) + 'M';
      } else {
        _value = _value.toPrecision(2) + 'M';
      }
    }
  } else if (_value < 0.1) {
    if (unit === 'MHz') {
      _value = NP.strip(NP.times(_value, 1000));
      if (_value <= 0.011) {
        _value = NP.strip(NP.times(_value, 1000));
        _value = _value.toPrecision(2);
      } else {
        _value = _value.toPrecision(2) + 'K'
      }
    } else if (unit === 'KHz') {
      if (_value < 0.01) {
        _value = _value.toExponential(2) + 'K';
      } else {
        _value = _value.toPrecision(2) + 'K'
      }
    }
  } else {
    _value = _value.toPrecision(2) + unit.slice(0, 1);
  }

  return _value;
}

export default PlotViewer;