import React, { Component, Fragment } from 'react';
import { ApartmentOutlined, CloseOutlined, PlusSquareOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Spin, Input, message, Divider, Checkbox, Cascader, ColorPicker } from 'antd';
import ResultLayout from '@/services/Result/Public/resultLayout';
import EffectTable from './effectTable';
import EffectCanvas from './effectCanvas';
import { getImpedanceEffect, getEffectiveFreq, getImpedanceHistoryEffect, getImpedanceHasResult, initCompareMap } from '@/services/Cascade/Impedance';
import { changeEffectToCanvasData } from '@/services/Cascade/result';
import { unitChange } from '@/services/helper/mathHelper';
import { numberCheck } from '@/services/helper/dataProcess';
import defaultColor from '@/constants/defaultColors';
import MapPanel from './mapPanel'
import _ from 'lodash';
import { getAdaptWidthName, getTextWidth } from '../../../../../services/helper/getTextWidth';
import html2canvas from 'html2canvas';
import { downloadFile } from '../../../../../services/helper/downloadHelper';

const currentId = 'current', historyKey = 'history', compareKey = 'comparison';
class Effective extends Component {

  constructor(props) {
    super(props)
    this.state = {
      frequency: 10,
      calcValue: 10,
      newEffectValue: false,
      effects: [],
      historyEffects: [],
      min: 0.0001,
      max: 500,
      loading: true,
      hidden: {},
      barList: [],
      barData: [],
      rawBarData: [],
      colors: [],
      colorStatus: false,
      redraw: false,
      compareAdd: false,
      compareIds: [],
      successList: [],
      compareEffects: [],
      mapStatus: false,
      tipStatus: [],
      shot: false
    }
    this.mapInfo = {}
  }

  componentDidMount() {
    const { id, compares } = this.props;
    if (id) {
      this.getEffectFreq();
    }

    if (compares.length) {
      this.checkCompares();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, historys, compares, updateHistory, updateCompare } = this.props;
    if ((id && id !== prevProps.id) || historys.length !== prevProps.historys.length || compares.length !== prevProps.compares.length) {
      this.getEffectFreq();
      this.checkCompares();
    } else if (updateHistory !== prevProps.updateHistory) {
      this.getHistoryEffect()
    } else if (updateCompare !== prevProps.updateCompare) {
      this.getCompareEffect();
      this.checkCompares();
    }
    if (!_.isEqual(prevState.effects, this.state.effects)) {
      this.showBar(null, [currentId])
    }
  }

  getEffectFreq = async () => {
    const { id } = this.props;
    const data = await getEffectiveFreq(id);
    const frequency = unitChange({ num: data, oldUnit: 'Hz', newUnit: 'MHz' }).number;
    this.setState({
      frequency,
      calcValue: frequency,
      loading: true
    }, () => {
      this.getEffectTable()
    })
  }

  getEffectTable = async () => {
    const { id } = this.props;
    const { frequency } = this.state;
    const newFreq = unitChange({ num: frequency, oldUnit: 'MHz', newUnit: 'Hz' }).number;
    try {
      const data = await getImpedanceEffect({ verificationId: id, frequency: newFreq });
      const min = unitChange({ num: data.min, oldUnit: 'Hz', newUnit: 'MHz' }).number;
      const max = unitChange({ num: data.max, oldUnit: 'Hz', newUnit: 'MHz' }).number;
      this.setState({
        effects: data.effectiveRLs,
        min,
        max
      }, () => {
        this.getHistoryEffect()
      })
    } catch (err) {
      this.getHistoryEffect()
    }
  }

  getHistoryEffect = async () => {
    const { historys } = this.props;
    const { frequency, hidden } = this.state;
    const newFreq = unitChange({ num: frequency, oldUnit: 'MHz', newUnit: 'Hz' }).number;
    let historyEffects = [], _hidden = {};
    for (let history of historys) {
      try {
        const { id, name } = history;
        if (id) {
          const data = await getImpedanceHistoryEffect({ historyId: id, frequency: newFreq });
          historyEffects.push({ id, name, effects: data.effectiveRLs })
          _hidden[id] = true;
        }
      } catch (err) {
        console.error(err);
      }
    }
    this.setState({
      historyEffects,
      hidden: { ..._hidden, ...hidden }
    }, () => {
      this.getCompareEffect()
    })
  }

  getCompareEffect = async () => {
    const { compares } = this.props;
    const { frequency, hidden } = this.state;
    const newFreq = unitChange({ num: frequency, oldUnit: 'MHz', newUnit: 'Hz' }).number;
    let compareEffects = [], _hidden = {};
    for (let compare of compares) {
      try {
        const { verificationId, name, historys } = compare;
        if (verificationId) {
          const data = await getImpedanceEffect({ verificationId, frequency: newFreq });
          compareEffects.push({ id: verificationId, name: `${name}-Current`, effects: data.effectiveRLs })
          _hidden[verificationId] = true;
        }
        if (historys.length) {
          for (let history of historys) {
            const { historyId, historyName } = history
            const data = await getImpedanceHistoryEffect({ historyId, frequency: newFreq });
            compareEffects.push({ id: historyId, name: `${name}-${historyName}`, effects: data.effectiveRLs })
            _hidden[historyId] = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    const compareIds = compares.map(c => c.verificationId)
    this.setState({
      compareEffects,
      loading: false,
      hidden: { ..._hidden, ...hidden },
      compareIds
    }, () => {
      const { barList, newEffectValue } = this.state;
      if (!barList.length) {
        this.showBar(null, [currentId])
      } else if (newEffectValue) {
        this.getbarDatabase(barList)
      }
    })
  }

  changeCalcValue = (e) => {
    this.setState({
      calcValue: e.target.value
    })
  }

  onPressEnter = (e) => {
    e && e.target.blur();
  }

  getNewFrequency = (e) => {
    const { calcValue, frequency, min, max } = this.state;
    let error = numberCheck(calcValue, true);

    if (!error && (calcValue < min || calcValue > max)) {
      error = `Value must be between ${min}MHz and ${max}MHz!`;
    }

    if (error) {
      message.error(error, 6);
      this.setState({
        calcValue: frequency
      });
      return;
    }

    this.setState({
      frequency: calcValue,
      newEffectValue: true,
      loading: true
    }, () => {
      this.getEffectTable()
    })
  }

  hiddenTable = (e, id) => {
    e && e.stopPropagation();
    const { hidden } = this.state;
    const display = hidden[id] ? false : true;
    this.setState({
      hidden: { ...hidden, [id]: display }
    })
  }

  showBar = (e, ids) => {
    e && e.stopPropagation();
    const { barList } = this.state;
    let _barList = barList.some(d => ids.includes(d)) ? barList.filter(item => !ids.includes(item)) : [...barList, ...ids];
    this.getbarDatabase(_barList);
  }

  showTip = (boolean, powerNet) => {
    const { tipStatus } = this.state;
    this.setState({
      tipStatus: boolean ? [...tipStatus, powerNet] : tipStatus.filter(item => item !== powerNet)
    })
  }

  getbarDatabase = (barList) => {
    let barDatabase = [];
    const { effects, historyEffects, compareEffects } = this.state;
    const { compares } = this.props;
    if (barList.includes(currentId)) {
      barDatabase.push({ id: currentId, name: 'Current', effects, type: currentId });
    }
    const showHistory = historyEffects.filter(item => barList.includes(item.id)).map(item => ({ ...item, type: historyKey }));
    const showCompare = compareEffects.filter(item => barList.includes(item.id)).map(item => ({ ...item, type: compareKey }));;
    const barData = changeEffectToCanvasData([...barDatabase, ...showHistory, ...showCompare], compares);
    this.setState({
      barData,
      barList,
      rawBarData: [...barDatabase, ...showHistory, ...showCompare],
    }, () => {
      this.getColor()
    })
  }

  getColor = () => {
    const { colors: oldColor, barList, rawBarData } = this.state;
    const usedColor = oldColor.map(item => item.color);
    let colorIndex = 0;
    const colors = barList.map(id => {
      const find = oldColor.find(item => item.id === id);
      const findName = rawBarData.find(raw => id === raw.id);
      const name = findName ? findName.name : '';
      if (find) {
        return { id, color: find.color, name }
      } else {
        let _color = defaultColor[colorIndex];
        while (usedColor.includes(_color)) {
          colorIndex = colorIndex + 1;
          _color = defaultColor[colorIndex]
        }
        return { id, color: _color, name }
      }
    })
    this.setState({
      colors,
      newEffectValue: false
    })
  }

  colorThrottle = (e, key) => {
    _.throttle(this.colorChange(e, key), 200);
  }

  colorChange = (e, key) => {
    const { colors, colorStatus } = this.state;
    const color = e.toHexString();
    let _colors = [...colors];
    const findIndex = _colors.findIndex(item => item.id === key);
    if (findIndex > -1) {
      _colors[findIndex].color = color
      this.setState({
        colors: _colors,
        colorStatus: !colorStatus
      })
    }
  }

  changeWidthCB = () => {
    this.setState({
      redraw: !this.state.redraw
    })
  }

  historyDelete = (e, key) => {
    e && e.stopPropagation();
    this.props.deleteHistory(key)
    if (this.state.barList.includes(key)) {
      this.showBar(null, [key])
    }
  }

  selectNewTask = (value, selectOptions) => {
    this.addNewCompare(selectOptions);
    this.setState({
      compareAdd: false
    })
  }

  showCompareAdd = (e, compareAdd) => {
    e && e.stopPropagation();
    compareAdd && this.getSuccessImpedance()
    this.setState({
      compareAdd
    })
  }

  addNewCompare = ([project, verfication]) => {
    const { compares } = this.props;
    const projectName = project.name || "";
    const { id, histories, name } = verfication;
    this.props.updateCompareList([
      ...compares,
      {
        name: `${projectName} - ${name}`,
        verificationId: id,
        historys: histories,
        map: []
      }
    ])
    this.setState({
      compareIds: [...this.state.compareIds, id]
    })
  }

  delCompare = (e, key) => {
    e && e.stopPropagation();
    const { compares } = this.props;
    const { barList, compareIds } = this.state;
    const id = key.replace('-title', '');
    const compare = compares.find(item => item.verificationId === id);
    if (compare) {
      const ids = [compare.verificationId, ...compare.historys.map(i => i.historyId)];
      if (barList.some(d => ids.includes(d))) {
        this.showBar(null, ids)
      }
      this.props.updateCompareList([
        ...compares.filter(i => i.verificationId !== id)
      ], true)
      this.setState({
        compareIds: [...compareIds.filter(i => i !== id)]
      })
    }
  }

  getSuccessImpedance = async () => {
    const { id } = this.props;
    const { compareIds } = this.state;
    const res = await getImpedanceHasResult() || [];
    let list = [...res];
    res.forEach(project => {
      project.children = project.children.map(child => ({ ...child, disabled: id === child.id || compareIds.includes(child.id) ? true : false }))
    })
    this.setState({
      successList: list
    })
  }

  openMapPanel = (e, key) => {
    e && e.stopPropagation()
    const id = key.replace('-title', '');
    const { compares } = this.props;
    const compare = compares.find(item => item.verificationId === id);
    if (compare) {
      const { compareEffects, historyEffects, effects } = this.state;
      this.mapInfo = {
        title: compare.name,
        verificationId: id,
        info: compare,
        compareEffects,
        currentInfo: [{ effects, name: 'Current', id: currentId }, ...historyEffects]
      }
      this.setState({
        mapStatus: true
      })
    }
  }

  closeMapPanel = () => {
    this.mapInfo = {}
    this.setState({
      mapStatus: false
    })
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  screenShot = (powerNet) => {
    this.setState({
      shot: true
    }, () => {
      setTimeout(() => {
        const ele = document.getElementById(`cascade-effect-box-${powerNet}`);
        if (ele) {
          html2canvas(ele).then(canvas => {
            downloadFile(canvas, powerNet, 'canvas');
          })
        }
        this.setState({
          shot: false
        })
      }, 500)
    })
  }

  saveCompareMap = (verificationId, map) => {
    const { compares } = this.props;
    let _compares = [...compares];
    const findIndex = _compares.findIndex(compare => compare.verificationId === verificationId);
    if (findIndex > -1) {
      _compares[findIndex].map = map;
      this.props.updateCompareList([...compares])
      this.setState({
        newEffectValue: true
      })
    }
  }

  checkCompares = () => {
    const { compares } = this.props;
    const { compareEffects, historyEffects, effects } = this.state;
    let _compares = [...compares], save = false;
    _compares.forEach(compare => {
      if (!compare.map || !compare.map.length) {
        compare.map = initCompareMap({
          info: compare,
          compareEffects,
          currentInfo: [{ effects, name: 'Current', id: currentId }, ...historyEffects]
        })
        save = true
      }
    })
    save && this.props.updateCompareList([...compares])
  }

  nameWidthCheck = (name) => {
    const rightItem = document.getElementById('cascade-result-effect-right-key') || {};
    const rightWidth = rightItem.clientWidth || 400;
    return getAdaptWidthName(name, rightWidth - 140, 14, 'bold')
  }

  settingRender = () => {
    const { calcValue } = this.state;
    return <div className="cascade-result-effect-setting">
      <span className="cascade-result-effect-title">Calculate inductance @</span>
      <Input
        addonAfter="MHz"
        className="cascade-result-effect-input"
        onChange={this.changeCalcValue}
        onPressEnter={this.onPressEnter}
        onBlur={this.getNewFrequency}
        value={calcValue}
      />
    </div>
  }

  resultNameRender = ({ key, name, check, nextLevel = false, show = false, del = false, isTitle = false, map = false, level = 0 }) => {
    const { hidden, barList, colors } = this.state;
    const resultShow = hidden[key] ? false : true;
    const _class = nextLevel ? `cascade-effective-result-next-divider` : !isTitle ? `cascade-effective-result-not-title-divider` : '';
    const nameStyle = key === currentId ? { paddingRight: 0 } : {}
    const _color = colors.find(item => item.id === key);
    const _name = this.nameWidthCheck(name)
    const Icon = resultShow ? DownOutlined : RightOutlined;
    return (
      <Divider orientation="left" className={`cascade-effective-result-vertically-divider ${_class}`}>
        <div className='cascade-effective-result-title' style={{ marginLeft: level * 20 }}>
          {show && <Icon
            className="title-expand-icon"
            onClick={(e) => this.hiddenTable(e, key)}
          />}
          {check && <Checkbox
            className='cascade-effective-result-checkout-box'
            checked={barList.includes(key) ? true : false}
            onClick={(e) => this.showBar(e, [key])}
          />}
          {_color &&
            <ColorPicker
              value={_color.color}
              className='aurora-color-picker-small'
              onChange={(e) => this.colorChange(e, key)}
              size='small'
              onClick={(e) => this.stopPropagation(e)}
            />}
          <span className='cascade-effective-result-menu-title' style={nameStyle}>
            <span title={name}>{_name}</span>
            {isTitle && this.iconRender(key)}
          </span>
          {map && <ApartmentOutlined
            className='cascade-effective-icon cascade-effective-map-icon'
            onClick={(e) => map(e, key)} />
          }
          {del && <CloseOutlined
            className='cascade-effective-icon cascade-effective-history-delete-icon'
            onClick={(e) => del(e, key)} />}
        </div>
      </Divider>
    );
  }

  historyItems = () => {
    const { historyEffects } = this.state;
    return historyEffects.map(history => {
      const { id, name } = history;
      return <Fragment key={id}>
        {this.resultNameRender({ key: id, name, check: true, nextLevel: true, del: this.historyDelete, level: 1 })}
      </Fragment>
    })
  }

  canvasRender = (item) => {
    const { barList, rawBarData, colorStatus, colors, newEffectValue, redraw, shot } = this.state;
    return <EffectCanvas
      powerNet={item.powerNet}
      ports={item.ports}
      barList={barList}
      rawBarData={rawBarData}
      colors={colors}
      colorStatus={colorStatus}
      loading={newEffectValue}
      redraw={redraw}
      showTip={this.showTip}
      screenShot={this.screenShot}
      shot={shot}
    />
  }

  tableRender = (item) => {
    const { barList, rawBarData, newEffectValue, shot } = this.state;
    return <EffectTable
      powerNet={item.powerNet}
      ports={item.ports}
      barList={barList}
      rawBarData={rawBarData}
      loading={newEffectValue}
      shot={shot}
    />
  }

  resultLeft = () => {
    const { barData, loading } = this.state;
    return <div className="cascade-result-effect-left">
      <Spin spinning={loading}>
        {barData.map(item => (
          <div key={item.powerNet} className="cascade-result-effect-left-box">
            <div className='cascade-setup-border cascade-position-relative' id={`cascade-effect-box-${item.powerNet}`}>
              {this.canvasRender(item)}
              <Divider />
              {this.tableRender(item)}
              {this.tipRender(item)}
            </div>
          </div>
        ))}
      </Spin>
    </div>
  }

  resultRight = () => {
    const { hidden, compareAdd, mapStatus } = this.state;
    const { historys, compares } = this.props;
    return <div className="cascade-result-effect-right" id="cascade-result-effect-right-key">
      {this.settingRender()}
      {this.resultNameRender({ key: currentId, name: 'Current', check: true, isTitle: true })}
      {historys && historys.length ? <Fragment>
        {this.resultNameRender({ key: historyKey, name: 'History', check: false, show: true, isTitle: true })}
        {!hidden[historyKey] && this.historyItems()}
      </Fragment> : null}
      {this.resultNameRender({ key: compareKey, name: 'Comparison', check: false, show: true, isTitle: true })}
      {
        !hidden[compareKey] && <Fragment>
          {compares && compares.length ? this.compareItems() : null}
          {compareAdd && this.selectNewTaskRender()}
        </Fragment>
      }
      {
        mapStatus && <MapPanel
          closeModal={this.closeMapPanel}
          saveCompareMap={this.saveCompareMap}
          {...this.mapInfo}
        />
      }
    </div>
  }

  iconRender = (type) => {
    switch (type) {
      case compareKey:
        return (
          <Fragment>
            <PlusSquareOutlined
              className={`cascade-result-effective-compare-add-icon`}
              title='Add tasks to compare'
              onClick={(e) => this.showCompareAdd(e, true)} />
          </Fragment>
        );
      case currentId:
      case historyKey:
      default:
        return;
    }
  }

  selectNewTaskRender = () => {
    const { successList } = this.state;
    return (
      <Fragment>
        <Cascader
          size="small"
          className="cascade-result-effective-compare-cascader"
          options={successList}
          fieldNames={{ label: 'name', value: 'id' }}
          onChange={this.selectNewTask}
          showSearch={true}
        />
        <CloseOutlined
          className="cascade-result-effective-compare-cascader-close-icon"
          onClick={(e) => this.showCompareAdd(e, false)} />
      </Fragment>
    );
  }

  compareItems = () => {
    const { compares } = this.props;
    const { hidden } = this.state;
    return compares.map(compare => {
      const { verificationId, name, historys } = compare;
      return <Fragment key={verificationId}>
        {this.resultNameRender({ key: `${verificationId}-title`, name, check: false, show: true, del: this.delCompare, map: this.openMapPanel, level: 1 })}
        {!hidden[`${verificationId}-title`] && this.resultNameRender({ key: verificationId, name: 'Current', check: true, nextLevel: true, level: 2 })}
        {!hidden[`${verificationId}-title`] && historys.map(history => {
          const { historyId, historyName } = history;
          return <Fragment key={historyId}>
            {this.resultNameRender({ key: historyId, name: historyName, check: true, nextLevel: true, level: 2 })}
          </Fragment>
        })}
      </Fragment>
    })
  }

  tipRender = (item) => {
    const { colors, tipStatus } = this.state;
    const { ports = [], powerNet } = item;
    let ids = ports.map(port => port.value ? port.value.map(v => v.id) : []).flat(3);
    ids = [...new Set(ids)];
    const width = getTextWidth(powerNet, 14, undefined, '700')
    const _colors = colors.filter(item => ids.includes(item.id));
    return !tipStatus.includes(powerNet) ? <div className="cascade-result-effective-color-tips" style={{ width: `calc(100% - ${width + 50}px)` }}>
      {_colors.map(c => {
        const { id, color, name } = c;
        return <span key={id} className="tip-content">
          <span className="tip-color" style={{ backgroundColor: color }}></span>
          <span>{name}</span>
        </span>
      })}
    </div> : null
  }

  render() {
    return <ResultLayout
      defaultRightWidth={330}
      className='impedace-result-effective-layout'
      resultLeft={this.resultLeft}
      resultRight={this.resultRight}
      changeWidthCB={this.changeWidthCB}
    />
  }
}

export default Effective;