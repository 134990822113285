import { select, event, selectAll } from 'd3-selection';
import hash from 'object-hash';
import { min, max } from 'd3-array';
import { scaleLinear, scaleLog } from 'd3-scale';
import { line } from 'd3-shape';
import { axisBottom, axisLeft } from 'd3-axis';
import { format, formatPrefix } from 'd3-format';
import { drag } from 'd3-drag';
import { displayUnit } from '../../../helper/sparameter/utility';
import defaultColor from '@/constants/defaultColors';
import MarkData from './Mark/markData';
import { unitToNumber } from '../../../helper/dataProcess'
import { /* zoom, */ zoomTransform, zoomIdentity } from 'd3-zoom';
import { formatExponential, scaleConversion } from '../../../helper/numberHelper';
import NP from 'number-precision';
import TargetLine from './TargetLine/targetLine';
/**
 * Curve plotting utility
 * 
 * TODO 
 *     - move to a dedicated Utility module
 *     - improve drawing efficiency when zooming
 *     - pan limit
 *     - reset the y domain to show values after zoom
 *     - more curve controls
 *     - subplot
 *     - view finder
 */

/** Plot2D class constructor
 *  @param svgElement  - the SVG element in which the curves are to be plotted
 *  @param plotOptions - object of Plot2D.PlotOptions, settings of the plot
 *  @param events      - Optional, events: changeMouse,cancelMove, 
 */
function Plot2D(svgElement, options, events, product) {
  this.svgElement = svgElement;
  this.options = options || new Plot2D.PlotOptions();
  this.curves = []; // Array of Plot2D.CurveItem
  this.type = '';
  this.location = location;
  this.events = events || null;
  this.product = product || null;
  this.mark = new MarkData();
  if (product === 'andes') {
    this.targetLine = new TargetLine();
  }
  this.scale = {
    x: "linear",
    y: "linear"
  }
}

/** Draw multiple 2D curves in a given SVG
 *  @param curveData  - Array of Plot2D.CurveItem, the x and y data values of the curves
 */
Plot2D.prototype.plotCurves = plotCurves;

/** Plot a curve with default settings
 *  @param xData - Array of Number, x coordinates of the curve
 *  @param yData - Array of Number, y coordinates of the curve
 */
Plot2D.prototype.plot = plot;

/** Update the plot with new curve settings */
Plot2D.prototype.updatePlot = updatePlot;

Plot2D.prototype.removeCurves = removeCurves;
Plot2D.prototype.crossProbingPlot = crossProbingPlot;
Plot2D.prototype.removeCrossProbing = removeCrossProbing;
Plot2D.prototype.getCurveData = getCurveData;
Plot2D.prototype.changeScale = changeScale;

Plot2D.prototype.redrawPlot = function (svgElement, padding, xRange, yRange) {
  this.svgElement = svgElement;
  resetPlot(this, padding, xRange, yRange);
  redrawCurves(this)();
};

Plot2D.prototype.reSetEvents = function (events) {
  this.events = events;
}

Plot2D.prototype.updateRange = updateRange;

/** Curve data specification */
Plot2D.CurveItem = function () {
  this.x = []; // Array of Number, the x coordinates of the curve
  this.y = []; // Array of Number, the y coordinates of the curve
  this.name = null; // String, curve name
  this.color = null; // String, curve color
  this.visible = false; // Boolean, whether the curve is shown
  // TODO - more curve controls
  //this.lineWidth  = 1;        // Number, line width
  //this.lineType   = 'solid';  // String, line type
  //this.marker     = null;     // String, marker shape on each point,
  //this.markerSize = 3;        // Number, makrer size
  //this.markerFill = null;     // String, marker fill color
};

/** Plot configurations */
Plot2D.PlotOptions = function () {
  this.xMin = null; // Number, minimum x coordinate of the plot
  this.xMax = null; // Number, maximum x coordinate of the plot
  this.yMin = null; // Number, minimum y coordinate of the plot
  this.yMax = null; // Number, maximum y coordinate of the plot
  this.xUnit = null; // String, unit of the xAxis
  this.yUnit = null; // String, unit of the yAxis
  this.background = '#fff'; // String, background color
  this.xLabel = null; // String, label for the x axis
  this.yLabel = null; // String, label for the y axis
  this.title = null; // String, plot title
  this.ui = null; // unit interval - approximate data cycle length
  this.grid = null; // String, 'x', 'y' or 'xy', null or '' for no grid
  this.zoom = null; // String, allowed zoom behavior, 'x', 'y', or 'xy', null or '' for no zoom
};

let location = {};
let maxWidth = 0;
// ------------------------------ Implementation Details ------------------------------

// Reference code: http://bl.ocks.org/stepheneb/1182434
/** ------ implementation of Plot2D.prototype.plotCurves ------
 *  Draw multiple 2D curves in a given SVG
 *  @param curveData  - Array of Plot2D.CurveItem, the x and y data values of the curves
 */
function plotCurves(curveData, concat, padding, xRange, yRange, colorIndex) {
  if (concat) {
    this.curves = this.curves.concat(curveData);
  } else {
    this.curves = curveData;
  }
  setCurveColors(this, colorIndex);
  if (!colorIndex && colorIndex !== 0) {
    resetPlot(this, padding, xRange, yRange);
    redrawCurves(this)();
  }
}

function removeCurves(index, length) {
  this.curves.splice(index, length);
  setCurveColors(this);
  resetPlot(this);
  redrawCurves(this)();
}

Plot2D.prototype.zoom = function () {
  return;
  /*  const svgEle = this.root.select(`g.waveform-curve-g`);
   const plot = this;
   this.zoomCtrl = zoom()
     .scaleExtent([0.05, 200])
     .on("zoom", () => {
       const { k, x, y } = event.transform;
       svgEle.attr('transform', `translate(${x}, ${y}) scale(${k},${k})`)
       plot.root.selectAll('text.mark-text').remove();
 
       const svgMarkEle = select(this.svgElement).select(`g.sierra-curve-svg-box`);
       svgMarkEle && svgMarkEle.attr('transform', `translate(${x + 70 * k}, ${y + 24 * k}) scale(${k},${k})`)
       let t = event.transform.rescaleX(plot.xScale)  //获得缩放后的scale
       plot._xAxis.call(plot.xAxis.scale(t))          //重新设置x坐标轴的scale
       let yt = event.transform.rescaleY(plot.yScale)  //获得缩放后的scale
       plot._yAxis.call(plot.yAxis.scale(yt))          //重新设置y坐标轴的scale
       plot.root.selectAll("path.curve")
         .attr("d", function (c) {
           return c.visible ? getCurveData(c, plot) : null;
         })
       //  plot.line
       // .x(function (d) { //获取曲线，用新的x尺度来计算line
       //   return t(d.x)
       // })
       //.y(function (d) { //获取曲线，用新的y尺度来计算line
       //  return yt(d.y)
       //})) 
 
       if (plot.product === 'sierra') {
         const new_padding = { top: 24 * k, bottom: 40 * k, left: 70 * k, right: 50 * k };
         const yScale = plot.yScale.domain() || [];
         let max = Math.max(...yScale),
           min = Math.min(...yScale);
         plot.mark.redraw({ padding: new_padding, height: plot.size.height, yMin: min, yMax: max })
       }
 
     });
   select(this.svgElement).call(this.zoomCtrl); */
}

Plot2D.prototype.zoomIn = function (e, plot) {
  _zoom(1.272, undefined, undefined, plot);
}

Plot2D.prototype.zoomOut = function (e, plot) {
  _zoom(1 / 1.272, undefined, undefined, plot);
}

function _zoom(ratio, x, y, _this) {

  let xc, yc, scale;
  if (x === undefined || y === undefined) {

    // relative zoom, responding to the zoom button, zoom about the screen center
    // The assumption is that the center location is not changed after the zoom.
    // before zooming, the center coordinate is
    //     (1)   Xc = x * s1 + dx1
    // where Xc is the center coordinate of the SVG view box, x is actual coordinate
    // in the g element of zoom control, s1 is the previous scaling, and dx1 is the
    // previous shift.
    // From (1) we can calculate the actual coordinate as
    //     (2)   x = (Xc - dx1) / s1
    // Now after the zoom, the center coordinate remain the same, so we have
    //     (3)   Xc = x * s2 + dx2
    // where s2 and dx2 is the new scaling and shifting, respectively. The scaling
    // factor is known, so we only need to determine the shifting.
    // Substitute (2) into (3) leads to
    //     (4)   dx2 = Xc * (1 - s2 / s1) + dx1 * s2 / s1
    //
    const t = zoomTransform(_this.svgElement);
    xc = _this.viewBoxXc * (1 - ratio) + t.x * ratio;
    yc = _this.viewBoxYc * (1 - ratio) + t.y * ratio;

    // remember this setting in the zoom control object
    // this.zoomCtrl.translate([xc, yc]);
    scale = t.k * ratio;
    // get the scale from the zoom object so that it won't be out of bound
    if (scale < 0.05) scale = 0.05;
    else if (scale > 300) scale = 200;
  } else {
    // Responding to the mouse event, the shifting and scaling are calculated by
    // d3. In this mode, the parameter 'ratio' is the actual scaling factor.
    xc = x;
    yc = y;
    scale = ratio;
  }
  const svgEle = _this.root.select(`g.waveform-curve-g`);
  const svgMarkEle = select(_this.svgElement).select(`g.sierra-curve-svg-box`);
  _this.svgElement.__zoom = new zoomIdentity.constructor(scale, xc, yc);
  svgEle.attr('transform', 'translate(' + xc + ' ' + yc + ') scale(' + scale + ',' + scale + ')');
  svgMarkEle && svgMarkEle.attr('transform', 'translate(' + (xc + 70 * scale) + ' ' + (yc + 24 * scale) + ') scale(' + scale + ',' + scale + ')');
  _this.root.selectAll('text.mark-text').remove();
  let t = _this.svgElement.__zoom.rescaleX(_this.xScale)  //获得缩放后的scale
  _this._xAxis.call(_this.xAxis.scale(t))                 //重新设置x坐标轴的scale
  let yt = _this.svgElement.__zoom.rescaleY(_this.yScale)  //获得缩放后的scale
  _this._yAxis.call(_this.yAxis.scale(yt))                 //重新设置y坐标轴的scale
  _this.root.selectAll("path.curve")
    .attr("d", function (c) {
      return c.visible ? getCurveData(c, _this) : null;
    }
      /*  _this.line
         .x(function (d) { //获取曲线，用新的x尺度来计算line
           return t(d.x)
         })
         .y(function (d) { //获取曲线，用新的y尺度来计算line
           return yt(d.y)
         }) */
    )
  if (_this.product === 'sierra') {
    const new_padding = { top: 24 * scale, bottom: 40 * scale, left: 70 * scale, right: 50 * scale };
    const yScale = _this.yScale.domain() || [];
    let max = Math.max(...yScale),
      min = Math.min(...yScale);
    _this.mark.redraw({ padding: new_padding, height: _this.size.height, yMin: min, yMax: max })
  }
}

Plot2D.prototype.fitView = function (e, plot) {
  // re-calculate the bounding box
  resetPlot(plot, plot.defaultPadding, plot.defaultXRange, plot.defaultYRange);
  // padding, xRange, yRange
  redrawCurves(plot)();
  plot.svgElement.__zoom = new zoomIdentity.constructor(1, 0, 0);
}


Plot2D.prototype.getAxis = (type, min, max, defaultUnit = "Hz") => {
  const prefix = formatPrefix(".1", max - min);
  let unit = prefix(1).match(/\D$/gi);
  let _min = min, _max = max;
  let _unit = defaultUnit;
  if (type === "x") {
    if (unit) {
      _unit = unit + defaultUnit;
      const scale = scaleConversion(_unit, defaultUnit);
      _min = parseFloat((min * scale).toPrecision(12));
      _max = parseFloat((max * scale).toPrecision(12));
    }
  } else if (type === "y") {
    const scale = unit ? scaleConversion(unit[0], defaultUnit) : 1;
    _min = NP.times(Math.min(min, max), scale);
    _max = NP.times(Math.max(min, max), scale);
    _unit = unit ? unit[0] + defaultUnit : defaultUnit;
  }
  return {
    min: _min,
    max: _max,
    unit: _unit
  }
}


/** ------ implementation of Plot2D.prototype.plot ------
 *  Plot a curve with default settings
 *  @param xData - Array of Number, x coordinates of the curve
 *  @param yData - Array of Number, y coordinates of the curve
 */
function plot(xData, yData) {

  const newCurve = new Plot2D.CurveItem();
  newCurve.x = xData;
  newCurve.y = yData;
  this.curves.push(newCurve);
  setCurveColors(this);

  // re-calculate the sizing and plot initialize the plot
  resetPlot(this);

  // redraw the curves
  redrawCurves(this)();
} // plot

/** ------ implementation of Plot2D.prototype.updatePlot ------
 *  Update the plot with new curve settings 
 */
function updatePlot() {
  redrawCurves(this)();
}

const defaultColors = defaultColor;

export function getColor(index) {
  return defaultColors[index % defaultColors.length];
}

/** Set a default color for curves without a color
 *  @param plot - a Plot2D object 
 *  
 */
function setCurveColors(plot, colorIndex = 0) {
  for (const i in plot.curves) {
    if (!plot.curves[i].color) {
      plot.curves[i].color = getColor(i + colorIndex);
    }
  }
} // setCurveColors

/** re-calculate the sizing and plot initialize the plot
 *  @param plot - a Plot2D object 
 */
function resetPlot(plot, _padding, xRange, yRange) {
  // margins

  const padding = {
    top: _padding ? _padding.top : (plot.options.title ? 60 : 24),
    bottom: _padding ? _padding.bottom : (plot.options.xLabel ? 60 : 40),
    left: _padding ? _padding.left : (plot.options.yLabel ? 70 : 40),
    right: _padding ? _padding.right : 50
  };

  plot.defaultPadding = _padding;
  plot.defaultXRange = xRange;
  plot.defaultYRange = yRange;

  if (plot.product === "sierra_total_capacitance") {
    padding.left = 90;
    // data range
    const curves = plot.curves.filter(item => !!item.visible);
    plot.options.xMin = min(curves, function (c) {
      return min(c.x);
    });
    plot.options.xMax = max(curves, function (c) {
      return max(c.x);
    });
    plot.options.yMin = min(curves, function (c) {
      return min(c.y);
    });
    plot.options.yMax = max(curves, function (c) {
      return max(c.y);
    });
    if (!curves.length) {
      plot.options.xMin = 0;
      plot.options.xMax = 0;
      plot.options.yMin = 0;
      plot.options.yMax = 0;
    }
  } else {
    // data range
    plot.options.xMin = min(plot.curves, function (c) {
      return min(c.x);
    });
    plot.options.xMax = max(plot.curves, function (c) {
      return max(c.x);
    });
    plot.options.yMin = min(plot.curves, function (c) {
      return min(c.y);
    });
    plot.options.yMax = max(plot.curves, function (c) {
      return max(c.y);
    });
  }

  if (yRange && yRange.length === 2) {
    plot.options.yMin = yRange[0];
    plot.options.yMax = yRange[1];
  }

  //TODO
  // scaling factors
  if (!plot.svgElement || !plot.svgElement.parentElement || !plot.svgElement.parentElement.clientWidth) {
    return;
  }
  const totalWidth = plot.svgElement.parentElement.clientWidth;
  const totalHeight = plot.svgElement.parentElement.clientHeight;
  plot.size = {
    width: totalWidth - padding.left - padding.right,
    height: totalHeight - padding.top - padding.bottom - 20
  };
  maxWidth = plot.size.width;
  let x = padding.left - 0.05 * plot.size.width;
  let y = padding.top + 0.05 * plot.size.height
  plot.viewBoxXc = x + 0.5 * plot.size.width;
  plot.viewBoxYc = y + 0.5 * plot.size.height;


  // Set x scale
  const xScale = (plot.scale || {}).x;
  plot.options.xMin = xScale === 'log' && plot.options.xMin <= 0 ? 1e-12 : plot.options.xMin;
  //xRange ->  // 10 cycle ~ 15 cycle
  const x_range = xRange ? [xRange[0], xRange[1]] : [plot.options.xMin, plot.options.xMax];
  if (xScale === 'log') {
    plot.xScale = scaleLog()
      .domain(x_range)
      .range([0, plot.size.width])
  } else {
    plot.xScale = scaleLinear()
      .domain(x_range)
      .range([0, plot.size.width])
  };

  let _yMin = plot.product === "sierra_total_capacitance" || plot.product === 'cascade_transient_result' ? plot.options.yMin : plot.options.yMin > 0 ? 0 : plot.options.yMin

  const yScale = (plot.scale || {}).y;
  if (yScale === 'log') {
    _yMin = _yMin <= 0 ? 1e-12 : _yMin;
    plot.options.yMax = plot.options.yMax <= 0 ? 1e-12 : plot.options.yMax;
    plot.yScale = scaleLog()
      .domain([plot.options.yMax, _yMin])
      .range([0, plot.size.height])
  } else {
    plot.yScale = scaleLinear()
      .domain([plot.options.yMax, _yMin])
      .nice()
      .range([0, plot.size.height])
      .nice();
  };

  if (plot.events && plot.events.changeAxis) {
    const yRange = plot.yScale.domain();
    if (plot.product === 'cascade_transient_result') {
      plot.events.changeAxis({
        yMin: yRange[1],
        yMax: yRange[0],
        yUnit: `${plot.options.yUnit}`,
      })
    } else {
      const fixedRange = isNaN(yRange[1]) ? 0 : yRange[1].toFixed(1);
      const yMin = plot.product === "sierra_total_capacitance" ? fixedRange : (yRange[1] > 0 ? 0 : fixedRange)
      plot.events.changeAxis({
        yMin: yMin,
        yMax: isNaN(yRange[0]) ? 0 : yRange[0].toFixed(1),
        yUnit: `${plot.options.yUnit}`,
      })
    }
  }

  // line generator
  plot.line = line()
    .x(function (d) {
      return plot.xScale(d[0]);
    })
    .y(function (d) {
      return plot.yScale(d[1]);
    });

  // axis generators
  const xGrid = plot.options.grid && plot.options.grid.toLowerCase().indexOf('x') > -1;
  // var xRange = plot.xScale.domain();
  // var yRange = plot.yScale.domain();
  // let countxAxis = 0, countyAxis = 0;
  // let multipleY = 1, multipleX = 1;

  // if (plot.size.height < 480) {
  //   multipleY = 2;
  // }
  // if (plot.size.height <= 220) {
  //   multipleY = 3;
  // }
  // if (plot.size.height <= 120) {
  //   multipleY = 5;
  // }

  // if (plot.size.width < 600) {
  //   multipleX = 2;
  // }
  // if (plot.size.width <= 300) {
  //   multipleX = 3;
  // }
  // if (plot.size.width <= 100) {
  //   multipleX = 5;
  // }

  plot.xAxis = axisBottom(plot.xScale)
    .tickSizeInner(xGrid ? -plot.size.height : 0)
    .tickSizeOuter(xGrid ? 0 : 6)
    .ticks(10);

  const yGrid = plot.options.grid && plot.options.grid.toLowerCase().indexOf('y') > -1;
  plot.yAxis = axisLeft(plot.yScale)
    .tickSizeInner(yGrid ? -plot.size.width : 0)
    .tickSizeOuter(yGrid ? 0 : 6)
    .ticks(10);

  // ------ Start plotting ------
  // remove any existing graphics
  select(plot.svgElement).selectAll('g').remove();

  // root element is a simple shift
  // padding.left = padding.left - 14;
  // padding.top = padding.top + 5;
  plot.root = select(plot.svgElement)
    .attr('class', 'plot full-fill')
    .attr('width', totalWidth)
    .attr('height', totalHeight)
    .append('g')
    .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
    .on('mouseenter', function () {
      plot.root.select('line').attr('hidden', null);
      plot.root.select(`#${plot.product}-X-axis`).attr('hidden', null);
    })
    .on('mousemove', mousemoveEvent)
    .on('mouseover', mousemoveOver)
    .on('mouseleave', function () {
      plot.root.select('line').attr('hidden', true);
      plot.root.select(`#${plot.product}-X-axis`).attr('hidden', true);
      if (plot.events && plot.events.cancelMove) {
        setTimeout(() => {
          plot.events.cancelMove();
        }, 80)
      }
    })

  plot.root.append('text')
    .attr('id', `${plot.product}-X-axis`)

  if (plot.product === 'sierra') {
    const yScale = plot.yScale.domain() || [];
    let max = Math.max(...yScale),
      min = Math.min(...yScale);
    plot.mark.redraw({ xRange: plot.xScale.domain(), padding: padding, height: plot.size.height, yMin: min, yMax: max })
  }

  function mousemoveEvent() {
    const x = event.offsetX - padding.left,
      y = event.offsetY - padding.top;

    if (x > 0 && x < plot.size.width && y > 0 && y < plot.size.height) {
      const xrange = plot.xScale.domain(),
        /*   yrange = plot.yScale.domain(), */
        time = x / plot.size.width * (xrange[1] - xrange[0]) + xrange[0];

      plot.root
        .select('line')
        .attr('x1', x)
        .attr('x2', x);

      // Display X-axis value
      const xPrefix = formatPrefix(".2", xrange[1] - xrange[0]);
      /*  yPrefix = formatPrefix(".3", yrange[1] - yrange[0]); */
      if (!!time) {
        let strTime = xPrefix(time);
        for (const prefix of ['n', 'µ', 'm', 'p', 'G', 'M', 'K']) {
          if (strTime.indexOf(prefix) > -1) {
            strTime = strTime.replace(prefix, '');
          }
        }

        plot.root
          .select(`#${plot.product}-X-axis`)
          .attr('x', x - 20)
          .attr('y', -5)
          .text(strTime)
      }
    }
  }

  function mousemoveOver() {
    const x = event.offsetX - padding.left,
      y = event.offsetY - padding.top;

    if (x > 0 && x < plot.size.width && y > 0 && y < plot.size.height) {
      const xrange = plot.xScale.domain(),
        yrange = plot.yScale.domain();
      let time = x / plot.size.width * (xrange[1] - xrange[0]) + xrange[0];

      plot.root
        .select('line')
        .attr('x1', x)
        .attr('x2', x);

      // Display X-axis value
      const yPrefix = formatPrefix(".3", yrange[1] - yrange[0]);
      const xPrefix = formatPrefix(".2", xrange[1] - xrange[0]);
      if (!!time && plot.events && plot.events.changeMouse) {
        setTimeout(() => {
          if (plot.options.xUnit === 'Hz') {
            let strTime = xPrefix(time);
            let unit = strTime.match(/\D$/gi)[0];
            const scale = scaleConversion('Hz', unit + 'Hz');
            time = parseFloat(strTime.replace(unit, "") * scale);
          }
          plot.events.changeMouse(time, yPrefix);
        }, 50)
      }
    }
  }
  // plot area
  plot.root.append('rect')
    .attr('class', 'waveform-zoom')
    .attr('width', plot.size.width)
    .attr('height', plot.size.height)
    .attr('fill', plot.options.background)
    .attr('stroke', '#ccc');

  //add Chart Title
  if (plot.options.title) {
    plot.root.append('text')
      .text(plot.options.title)
      .attr('x', plot.size.width / 2)
      .attr('dy', '-0.8em')
      .style('text-anchor', 'middle');
  }

  const xrange = plot.xScale.domain();
  const xPrefix = formatPrefix(".1", xrange[1] - xrange[0]);
  // Add the x-axis label
  if (plot.options.xLabel) {
    plot.root.append('text')
      .attr('class', 'xLabel')
      .text(plot.options.xLabel + ' ' + displayUnit(xPrefix, plot.options.xUnit))
      .attr('x', plot.size.width / 2)
      .attr('y', _padding ? plot.size.height + 10 : plot.size.height)
      .attr('dy', '2.4em')
      .style('text-anchor', 'middle')
      .style("font-size", "15px");
  }

  const yTransform = _padding ? 'translate(' + -((_padding.left - 20) || 56) + ' ' + plot.size.height / 2 + ') rotate(-90)' :
    'translate(' + (plot.product === "sierra_total_capacitance" ? -70 : -50) + ' ' + plot.size.height / 2 + ') rotate(-90)';

  const yrange = plot.yScale.domain();
  const yPrefix = formatPrefix(".1", yrange[1] - yrange[0]);
  let yText = plot.options.yLabel + ' (' + plot.options.yUnit + ')'
  let unitTest = displayUnit(yPrefix, plot.options.yUnit)
  if (unitTest) {
    yText = plot.options.yLabel + ' ' + displayUnit(yPrefix, plot.options.yUnit)
  }
  // add y-axis label
  if (plot.options.yLabel) {
    plot.root.append('text')
      .attr('class', "yLabel")
      .text(yText)
      .style('text-anchor', 'middle')
      .attr('transform', yTransform)
      .style("font-size", "15px");
  }

  plot.root.append('line')
    .attr('x1', 0)
    .attr('y1', 0)
    .attr('x2', 0)
    .attr('y2', plot.size.height)
    .attr('class', `${plot.product}-waveform-line`)
    .attr('stroke', '#ff0000')
    .attr('hidden', true)
    .attr('stroke-width', 2)


  // initialize the range selector
  const selectorEle = plot.root.append('g')
    .attr('class', `${plot.product}-waveform-select-element`)
  location[`${plot.product}-StartX`] = 0;
  // location[`${plot.product}-EndX`] = plot.options.ui * 10 / (plot.options.xMax - plot.options.xMin) * plot.size.width;
  location[`${plot.product}-EndX`] = plot.size.width;
  if (plot.events && plot.events.changeAxis) {
    updateAxis(plot);
  }
  // add background
  selectorEle
    .attr('transform', 'translate(0,' + (plot.size.height + 46) + ')')
    .append('rect')
    .attr('class', 'selector-backgroud')
    .attr('width', plot.size.width)
    .attr('height', 15);

  // add xmin and xMax
  const prefix = formatPrefix(".1", plot.options.xMax - plot.options.xMin);
  selectorEle
    .append('text')
    .attr('x', -5)
    .attr('y', 12)
    .text(prefix(plot.options.xMin))
    .style('text-anchor', 'end')
    .style('font-size', plot.product === "sierra_total_capacitance" ? "11px" : '12px');
  selectorEle
    .append('text')
    .attr('x', plot.size.width + 8)
    .attr('y', 12)
    .text(prefix(plot.options.xMax))
    .style('text-anchor', 'start')
    .style('font-size', plot.product === "sierra_total_capacitance" ? "11px" : '12px');
  // add slide
  const slideDrag = drag().on('drag', function () {
    const element = select(this),
      x = Math.min(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]) + event.dx,
      width = Math.abs(location[`${plot.product}-EndX`] - location[`${plot.product}-StartX`]),
      max = plot.size.width - width;

    if (x < 0) {
      location[`${plot.product}-EndX`] = width;
      location[`${plot.product}-StartX`] = 0;
    } else if (x > max) {
      location[`${plot.product}-StartX`] = max;
      location[`${plot.product}-EndX`] = plot.size.width;
    } else {
      location[`${plot.product}-StartX`] += event.dx;
      location[`${plot.product}-EndX`] += event.dx;
    }

    element.attr('x', Math.min(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]));
    select(`#${plot.product}-StartX`).attr('x', location[`${plot.product}-StartX`]);
    select(`#${plot.product}-EndX`).attr('x', location[`${plot.product}-EndX`]);
    displayRangeText();
    if (plot.events && plot.events.changeAxis) {
      updateAxis(plot);
    }
  }).on('end', function () {
    const max = plot.options.xMax,
      min = plot.options.xMin,
      dx = max - min,
      start = location[`${plot.product}-StartX`] / plot.size.width * dx + min,
      end = location[`${plot.product}-EndX`] / plot.size.width * dx + min;
    plot.xScale
      .domain([Math.min(start, end), Math.max(start, end)])
      .range([0, plot.size.width]);
    plot.updatePlot();
    rangeTextShow = false;
    selectAll('.range-text').remove();
  });

  selectorEle
    .append('rect')
    .attr('id', `${plot.product}-selector-slide`)
    .attr('class', 'selector-slide')
    .attr('width', plot.size.width)
    .style('fill', '#1790ff')
    .attr('x', 0)
    .attr('height', 15)
    .call(slideDrag);

  // set traveller drag event
  let rangeTextShow = false;
  const travellerDrag = drag().on('drag', function () {
    const element = select(this),
      id = element.attr('id'),
      x = event.x;
    if (x < 0) location[id] = 0;
    else if (x > plot.size.width) location[id] = plot.size.width;
    else location[id] = x;

    element.attr('x', location[id]);
    select(`#${plot.product}-selector-slide`)
      .attr('x', Math.min(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]))
      .attr('width', Math.abs(location[`${plot.product}-EndX`] - location[`${plot.product}-StartX`]));

    displayRangeText();
    if (plot.events && plot.events.changeAxis) {
      updateAxis(plot);
    }
  }).on('end', function () {
    const max = plot.options.xMax,
      min = plot.options.xMin,
      dx = max - min,
      start = location[`${plot.product}-StartX`] / plot.size.width * dx + min,
      end = location[`${plot.product}-EndX`] / plot.size.width * dx + min;
    plot.xScale
      .domain([Math.min(start, end), Math.max(start, end)])
      .range([0, plot.size.width]);
    plot.updatePlot();

    rangeTextShow = false;
    selectAll('.range-text').remove();
  });

  // add start traveller
  selectorEle
    .append('rect')
    .attr('id', `${plot.product}-StartX`)
    .attr('class', 'selector-traveller')
    .attr('x', location[`${plot.product}-StartX`])
    .attr('width', 5)
    .attr('height', 15)
    .call(travellerDrag);
  // add end traveller
  selectorEle
    .append('rect')
    .attr('id', `${plot.product}-EndX`)
    .attr('class', 'selector-traveller')
    .attr('x', location[`${plot.product}-EndX`])
    .attr('width', 5)
    .attr('height', 15)
    .call(travellerDrag);

  function displayRangeText() {
    if (!rangeTextShow) {
      rangeTextShow = true;
      selectorEle
        .append('text')
        .attr('id', `${plot.product}-range-text-end`)
        .attr('class', 'range-text range-text-style')
        .attr('y', 11)
        .style('text-anchor', 'end');

      selectorEle
        .append('text')
        .attr('id', `${plot.product}-range-text-start`)
        .attr('class', 'range-text range-text-style')
        .attr('y', 11)
        .style('text-anchor', 'start');
    }
    let x = Math.min(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]),
      max = plot.options.xMax,
      min = plot.options.xMin,
      width = plot.size.width;
    select(`#${plot.product}-range-text-start`)
      .attr('x', x - 34)
      .text(prefix((x / width * (max - min) + min)));
    x = Math.max(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`])
    select(`#${plot.product}-range-text-end`)
      .attr('x', x + 40)
      .text(prefix((x / width * (max - min) + min)));
  }
  plot.zoom()
} // resetPlot

function updateRange(startX, endX) {
  location[`${this.product}-StartX`] = startX;
  location[`${this.product}-EndX`] = endX;
  let width = endX - startX;
  if (width > maxWidth) {
    width = maxWidth;
  }
  if (endX > maxWidth) {
    endX = maxWidth
  }
  this.root
    .select(`#${this.product}-StartX`)
    .attr('x', startX);

  this.root
    .select(`#${this.product}-EndX`)
    .attr('x', endX);

  this.root
    .select(`#${this.product}-selector-slide`)
    .attr('x', startX)
    .attr('width', width);
}

/** redraw the curve and axis upon zoom event
 *  @param plot - a Plot2D object 
 */
function redrawCurves(plot) {

  return function () {
    // Replot the axis
    if (!plot.root) {
      return;
    }
    plot.root.selectAll('g.axis').remove();
    plot.root.selectAll('text.mark-text').remove();

    const xrange = plot.xScale.domain();
    if (plot.product === 'sierra') {
      const new_padding = { top: 24, bottom: 40, left: 70, right: 50 };
      const yScale = plot.yScale.domain() || [];
      let max = Math.max(...yScale),
        min = Math.min(...yScale);
      plot.mark.redraw({ padding: new_padding, height: plot.size.height, yMin: min, yMax: max });
    }


    let countxAxis = 0, countyAxis = 0;
    let xRatio = 0;
    const xRangeValueInt = parseInt(xrange[1] - xrange[0]);
    const xRangeLength = xRangeValueInt.toString().length;
    const value = xrange[1] - xrange[0];
    const xPrefix = formatPrefix(`.${xRangeLength}`, value);

    if (xrange[0] > 0) {
      xRatio = parseInt(xrange[1] / xrange[0]).toString().length;
    } else {
      xRatio = parseInt(xrange[1]).toString().length;
    }
    let xAxisNum = 4, maxNumX = 9;
    if (plot.size.width < 900) {
      maxNumX = 7;
    }

    if (plot.size.width < 500) {
      maxNumX = 5;
    }

    if (xRatio >= maxNumX) {
      xAxisNum = 1;
    } else {
      xAxisNum = maxNumX - xRatio;
    }

    plot._xAxis = plot.root.append('g')
      .attr('class', 'axis axis--x')
      .style('font-size', '14px')
      .attr("transform", 'translate(0, ' + plot.size.height + ')')
      .call(plot.xAxis.tickFormat(function (d) {
        let str = xPrefix(d);
        for (const prefix of ['n', 'µ', 'm', 'p', 'G', 'M', 'K']) {
          if (xPrefix(d).indexOf(prefix) > -1) {
            str = xPrefix(d).replace(prefix, '');
          }
        }
        let num = parseFloat(str);
        if (plot.scale.x === 'log') {
          if (countxAxis % 9 < xAxisNum) {
            if (num > 999 || (countxAxis > 50 && num < 0.1) || (countxAxis < 50 && num < 0.01)) {
              countxAxis++;
              return formatExponential(num);
            } else {
              countxAxis++;
              return num;
            }
          } else {
            countxAxis++;
            return '';
          }
        }
        return num;
      }));

    const axisFont = plot.product && plot.product.match("sierra") && plot.size.width >= 500 ? "15px" : '14px';

    plot._xAxis.selectAll("text")
      .attr("y", 6)
      .style('font-size', axisFont)
      .style('color', '#000');

    // Update the x label
    plot.root.select('.xLabel')
      .text(plot.options.xLabel + ' ' + displayUnit(xPrefix, plot.options.xUnit))

    const yrange = plot.yScale.domain();
    const yPrefix = formatPrefix(".3", yrange[1] - yrange[0]);
    let yRatio = 0;

    if (yrange[0] > 0) {
      yRatio = parseInt(yrange[1] / yrange[0]).toString().length;
    } else {
      yRatio = parseInt(yrange[1]).toString().length;
    }
    let yAxisNum = 4, maxNumY = 9;

    if (plot.size.height < 500) {
      maxNumY = 7;
    }

    if (plot.size.height < 180) {
      maxNumY = 5;
    }
    if (plot.size.height < 90) {
      maxNumY = 3;
    }

    if (yRatio >= maxNumY) {
      yAxisNum = 1;
    } else {
      yAxisNum = maxNumY - yRatio;
    }
    plot._yAxis = plot.root.append('g')
      .attr('class', 'axis axis--y')
      .style('font-size', '14px')
      .call(plot.yAxis.tickFormat(function (d) {
        let str = yPrefix(d);
        for (const prefix of ["f", 'p', 'n', 'µ', 'm']) {
          if (yPrefix(d).indexOf(prefix) > -1) {
            str = yPrefix(d).replace(prefix, '');
          }
        }
        let num = parseFloat(str);

        if (plot.scale.y === 'log') {
          num = parseFloat(parseFloat(str).toPrecision(12));
          if (countyAxis % 9 < yAxisNum) {
            if (num > 999 || (countyAxis > 50 && num < 0.01) || (countyAxis < 50 && num < 0.001)) {
              countyAxis++;
              return formatExponential(num);
            } else {
              countyAxis++;
              return num;
            }
          } else {
            countyAxis++;
            return '';
          }
        }
        return num
      }));

    let yText = plot.options.yLabel + ' (' + plot.options.yUnit + ')'
    let unitTest = displayUnit(yPrefix, plot.options.yUnit)
    if (unitTest) {
      yText = plot.options.yLabel + ' ' + displayUnit(yPrefix, plot.options.yUnit)
    }
    if (!plot.options.yUnit) {
      yText = plot.options.yLabel;
    }
    // Update the y label
    plot.root.select('.yLabel')
      .text(yText)

    plot.root.selectAll('.tick')
      .attr("stroke-dasharray", "2,2");

    plot._yAxis.selectAll("text")
      .attr("x", -8)
      .style('font-size', axisFont)
      .style('color', '#000');

    // initialize the curves
    plot.root.select(`svg.result-svg-viewer`).remove();
    plot.root.append('svg')
      .attr('class', `result-svg-viewer`)
      .attr('stroke-width', 2)
      .attr('top', 0)
      .attr('left', 0)
      .attr('width', plot.size.width)
      .attr('height', plot.size.height)
      .attr('viewBox', '0 0 ' + plot.size.width + ' ' + plot.size.height)
      .append('g')
      .attr('class', "waveform-curve-g")
      .selectAll('path')
      .data(plot.curves)
      .enter()
      .append('path')
      .attr('class', 'curve');

    // redraw the curves
    plot.maxPoint = null;
    plot.minPoint = null;
    plot.root.selectAll('path.curve')
      .data(plot.curves)
      .attr('stroke', function (c) {
        return c.color;
      })
      .attr('d', function (c) {
        return c.visible ? getCurveData(c, plot) : null;
      });

    /*    function getCurveData(curve) {
         var min = xrange[0],
           max = xrange[1],
           points = [];
         for (var i = 0, length = curve.x.length; i < length; i++) {
           var x = curve.x[i],
             y = curve.y[i];
           if (x > max) {
             break;
           } else if (x >= min) {
             points.push([x, y]);
             if (!maxPoint) {
               maxPoint = {
                 x: x,
                 y: y
               };
               minPoint = {
                 x: x,
                 y: y
               }
             } else {
               if (y > maxPoint.y) {
                 maxPoint.x = x;
                 maxPoint.y = y;
               } else if (y < minPoint.y) {
                 minPoint.x = x;
                 minPoint.y = y;
               }
             }
           }
         }
         return plot.line(points);
       } */

    const curveSvg = plot.root.select('svg');
    curveSvg.selectAll('circle').remove();
    curveSvg.selectAll('text').remove();

    // terminate the events
    if (event && event.keyCode) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (plot.product === 'andes') {
      const yRange = plot.yScale.domain();
      const xRange = plot.xScale.domain();
      plot.root.select(`g#andes-target-line`).remove();

      plot.targetLine.setValue({
        plotId: 'andes',
        height: plot.size.height,
        width: plot.size.width,
        getXValue: (x) => plot.xScale(x),
        getYValue: (y) => plot.yScale(y),
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[1],
        yMax: yRange[0]
      })

      plot.targetLine.drawLines(plot.root);
    }
  } // return function()
} // redrawCurves

function getCurveData(curve, plot) {
  const xrange = plot.xScale.domain();
  const min = xrange[0],
    max = xrange[1],
    points = [];
  for (let i = 0, length = curve.x.length; i < length; i++) {
    const x = curve.x[i],
      y = curve.y[i];
    if (x > max) {
      break;
    } else if (x >= min) {
      points.push([x, y]);
      if (!plot.maxPoint) {
        plot.maxPoint = {
          x: x,
          y: y
        };
        plot.minPoint = {
          x: x,
          y: y
        }
      } else {
        if (y > plot.maxPoint.y) {
          plot.maxPoint.x = x;
          plot.maxPoint.y = y;
        } else if (y < plot.minPoint.y) {
          plot.minPoint.x = x;
          plot.minPoint.y = y;
        }
      }
    }
  }
  return plot.line(points);
}

function changeRange(plot, StartX, EndX) {
  let max = plot.options.xMax,
    min = plot.options.xMin;

  // Reduce the display range of the waveform
  location[`${plot.product}-EndX`] = EndX > plot.size.width ? plot.size.width : EndX;
  location[`${plot.product}-StartX`] = StartX < 0 ? 0 : StartX;

  let dx = max - min,
    start = location[`${plot.product}-StartX`] / plot.size.width * dx + min,
    end = location[`${plot.product}-EndX`] / plot.size.width * dx + min;

  select(`#${plot.product}-StartX`).attr('x', location[`${plot.product}-StartX`]);
  select(`#${plot.product}-EndX`).attr('x', location[`${plot.product}-EndX`]);
  select(`#${plot.product}-selector-slide`)
    .attr('x', Math.min(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]))
    .attr('width', Math.abs(location[`${plot.product}-EndX`] - location[`${plot.product}-StartX`]));

  plot.xScale
    .domain([Math.min(start, end), Math.max(start, end)])
    .range([0, plot.size.width]);

  plot.updatePlot();
}

function crossProbingPlot(data) {
  const { timeList, timeUnit, curveName, selectedKeys, rangeList, measureList, shootList, usage } = data
  if (!curveName) {
    return;
  }
  const plot = this
  //  Clear data in mark
  selectAll(`.${plot.product}-curve-svg-box`).remove();
  plot.cRoot = select(plot.svgElement)
    .append('g')
    .attr('class', `${plot.product}-curve-svg-box`)

  this.mark.clearAll()
  // Restore the extent of the graph to the maximum
  changeRange(this, 0, plot.size.width)
  if (!timeList || !timeList.length) { return }

  function getX(value, plot) {
    let max = plot.options.xMax,
      min = plot.options.xMin,
      dx = max - min;
    const x = (value - min) / dx * plot.size.width
    return x
  }
  const number = unitToNumber(timeUnit)

  const xrange = plot.xScale.domain();
  let xPrefix = formatPrefix('.2', xrange[1] - xrange[0]);
  let xUnit = displayUnit(xPrefix, plot.options.xUnit); // MHz

  const new_padding = { top: 24, bottom: 40, left: 70, right: 50 };
  let _curves = [];
  plot.curves.forEach((curve, i) => {
    if (selectedKeys.includes(i)) {
      const hashId = hash(curveName).substring(0, 10);
      _curves.push({ ...curve, hashId })
    }
  })

  timeList.forEach((item, index) => {
    // plot selected data
    const x = getX(item.value * number, plot);
    const targetX = plot.xScale.invert(x);
    let prevX = Number(Number(x).toFixed(0));
    let xValue = parseFloat(xPrefix(targetX)) + xUnit.slice(1, xUnit.length - 1)

    let anotherX = {};
    if (item.another) {
      const _x = getX(item.another * number, plot);
      const _targetX = plot.xScale.invert(_x);
      let _prevX = Number(Number(_x).toFixed(0));
      let _xValue = parseFloat(xPrefix(_targetX)) + xUnit.slice(1, xUnit.length - 1)
      anotherX = { x: _x, targetX: _targetX, prevX: _prevX, xValue: _xValue }
    }

    let type = item.type;

    plot.mark.createMarkCross({
      plotId: plot.product,
      prevX,
      root: plot.cRoot,
      x,
      padding: new_padding,
      height: plot.size.height,
      xValue,
      curves: _curves,
      targetX,
      getYValue: getYValue,
      getXValue: getXValue,
      curveName,
      type,
      anotherX,
      extra: item.extra,
      usage
    });
  });

  function getY(value, plot) {
    const yScale = plot.yScale.domain() || [];
    let max = Math.max(...yScale),
      min = Math.min(...yScale);
    let dy = max - min;
    const y = (value - min) / dy * plot.size.height
    return plot.size.height - y
  }

  shootList.forEach((item, index) => {
    // plot selected data
    const x = getX(item.shootTime[0] * number, plot);
    const targetX = plot.xScale.invert(x);
    let prevX = Number(Number(x).toFixed(0));
    let xValue = parseFloat(xPrefix(targetX)) + xUnit.slice(1, xUnit.length - 1);

    const y = getY(Number(item.value), plot);
    const targetY = plot.yScale.invert(y);
    let yValue = Number(item.value);

    let _y = getY(Number(item.another), plot);
    const _targetY = plot.yScale.invert(y);
    let _yValue = Number(item.another);
    const anotherY = { y: _y, targetY: _targetY, yValue: _yValue }

    let type = item.type;

    plot.mark.createMarkCross({
      plotId: plot.product,
      prevX,
      root: plot.cRoot,
      x,
      padding: new_padding,
      height: plot.size.height,
      xValue,
      curves: _curves,
      targetX,
      getYValue: getYValue,
      getXValue: getXValue,
      curveName,
      type,
      targetY,
      yValue,
      anotherY,
      extra: item.extra,
      usage
    });
  })

  measureList.forEach(item => {
    // plot selected data
    const y = getY(item.value, plot);
    const targetY = plot.yScale.invert(y);
    let prevY = Number(Number(y).toFixed(0));
    let yValue = item.value;

    let type = item.type;
    plot.mark.createMarkCross({
      plotId: plot.product,
      prevX: `measure-${prevY}`,
      root: plot.cRoot,
      y,
      padding: new_padding,
      height: plot.size.height,
      width: plot.size.width,
      yValue,
      curves: _curves,
      targetY,
      getYValue: getYValue,
      getXValue: getXValue,
      curveName,
      type,
      x: 0,
      extra: item.extra,
      usage
    });
  })

  const rangValueList = rangeList ? rangeList.map(item => {
    const x = getX(item.value * number, plot)
    return x
  }) : timeList.map(item => getX(item.value * number, plot));
  const min = Math.min(...rangValueList), max = Math.max(...rangValueList), dx = max - min;
  let start = min - dx / 2, end = max + dx / 2;
  // Reduce the display range of the waveform
  changeRange(this, start, end)

  function getYValue(y) {
    return plot.yScale(y);
  }

  function getXValue(x) {
    return plot.xScale(x);
  }
}

function removeCrossProbing(values) {
  // Hide cross probing data
  const plot = this;
  if (!values.includes(plot.mark.curveName)) {
    selectAll(`.${plot.product}-curve-svg-box`).remove();
  }
}

function updateAxis(plot) {
  const max = plot.options.xMax,
    min = plot.options.xMin,
    width = plot.size.width;
  const _displayUnit = plot.options.xUnit === 'Hz' ? 1 : 1e-9;
  let xMin = format(".4g")((Math.min(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]) / width * (max - min) + min) / _displayUnit),
    xMax = format(".4g")((Math.max(location[`${plot.product}-StartX`], location[`${plot.product}-EndX`]) / width * (max - min) + min) / _displayUnit);
  if (plot.options.xUnit === 'Hz') {
    const { min: _xMin, max: _xMax, unit: xUnit } = plot.getAxis("x", xMin, xMax, "Hz")

    if (plot.product === "sierra_total_capacitance") {
      const yRange = plot.yScale.domain()
      const { min: yMin, max: yMax, unit: yUnit } = plot.getAxis("y", yRange[0], yRange[1], "F")
      plot.events.changeAxis({
        xMin: _xMin,
        xMax: _xMax,
        xUnit,
        yMin: isNaN(yMin) ? 0 : yMin.toFixed(1),
        yMax: isNaN(yMax) ? 0 : yMax.toFixed(1),
        yUnit: yUnit || "F",
      })
    } else {
      plot.events.changeAxis({
        xMin: isNaN(_xMin) ? '' : _xMin,
        xMax: isNaN(_xMax) ? '' : _xMax,
        xUnit
      })
    }
  } else {
    plot.events.changeAxis({
      xMin: isNaN(xMin) ? '' : xMin,
      xMax: isNaN(xMax) ? '' : xMax
    })
  }
}

function changeScale(type, scale) {
  this.scale[type] = scale;
  const plot = this;

  const xScale = (plot.scale || {}).x;
  plot.options.xMin = xScale === 'log' && plot.options.xMin <= 0 ? 1e-12 : plot.options.xMin;
  const x_range = [plot.options.xMin, plot.options.xMax];
  if (xScale === 'log') {
    plot.xScale = scaleLog()
      .domain(x_range)
      .range([0, plot.size.width])
  } else {
    plot.xScale = scaleLinear()
      .domain(x_range)
      .range([0, plot.size.width])
  };

  plot.xAxis = axisBottom(plot.xScale)
    .tickSizeInner(-plot.size.height)
    .tickSizeOuter(0)
    .ticks(10)

  let _yMin = plot.product === "sierra_total_capacitance" || plot.product === 'cascade_transient_result' ? plot.options.yMin : plot.options.yMin > 0 ? 0 : plot.options.yMin

  const yScale = (plot.scale || {}).y;
  if (yScale === 'log') {
    _yMin = _yMin <= 0 ? 1e-12 : _yMin;
    plot.options.yMax = plot.options.yMax <= 0 ? 1e-12 : plot.options.yMax;
    plot.yScale = scaleLog()
      .domain([plot.options.yMax, _yMin])
      .range([0, plot.size.height])
  } else {
    plot.yScale = scaleLinear()
      .domain([plot.options.yMax, _yMin])
      .nice()
      .range([0, plot.size.height])
      .nice();
  };
  const yGrid = plot.options.grid && plot.options.grid.toLowerCase().indexOf('y') > -1;
  plot.yAxis = axisLeft(plot.yScale)
    .tickSizeInner(yGrid ? -plot.size.width : 0)
    .tickSizeOuter(yGrid ? 0 : 6)
    .ticks(10);

  if (plot.events && plot.events.changeAxis) {
    updateAxis(plot);
  }
  //resetPlot(this, this.defaultPadding, this.defaultXRange, this.defaultYRange);
  redrawCurves(this)();
}

export default Plot2D;

export function getLocation() {
  return location;
}