import React from 'react';
import { Radio } from 'antd';
import "./style.css";

export default function DisplayMode({ displayModeChange, displayMode, Modes }) {
  return (
    <Radio.Group onChange={displayModeChange} value={displayMode} className='sparameter-display-mode'>
      <div className='sparameter-display-mode-group'>{Modes.map(mode => <Radio value={mode.value} key={mode.value}>{mode.name}</Radio>)}</div>
    </Radio.Group>
  )
}
