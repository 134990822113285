import apiProcess from '../api/utility';
import workflowFliter from '../helper/workflowFliter';
import {
  getInterfacesList, getProjectInfoByProjectId,
  getFilesInProject,
} from '../api/project';
import { SUCCESS } from '../../constants/returnCode';
import { getDesignsInProjectPromise } from '../api/Design/design';
import { uploadLibrary } from '../api/library';
import { getWorkFlowStatus } from '../api/v2/workflowCtrl';
import { getLibraryFileInProject_v2 } from '../api/v2/libraryCtrl';
import { uploadConfigFile } from '../api/v2/verificationCtrl';

const projectId = window.location.search.split('=')[1];
export const PROJECT_ID = projectId;


let interfacesList = null; // menu
export function getInterfacesListByID() {
  return new Promise((resolve, reject) => {
    getInterfacesList(projectId).then(res => {
      if (res.data.code === SUCCESS) {
        const list = res.data.data;
        interfacesList = list ? changeInterfacesFormat(list) : null
      }
      resolve(interfacesList);
    }, error => {
      console.error(error);
      resolve(interfacesList);
    })
  })
}

let info = null;
export function getDesignsInfoInProject() {
  return new Promise((resolve, reject) => {
    getDesignsInProjectPromise(projectId).then(res => {
      const resData = res.designs;
      if (resData.length === 0) {
        info = { designs: [] }
        resolve(info);
      } else {
        const designs = [].concat(resData).map(info => { return { name: info.name, id: info.id } })
        info = { designs };
        resolve(info);
      }
    }, error => {
      console.error(error);
      resolve(info);
    })
  })
}

export function getProjectName() {
  return new Promise((resolve, reject) => {
    getProjectInfoByProjectId(projectId).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        const { name, id } = res.data.data;
        resolve({ id, name });
      }
    }, error => {
      console.error(error);
    })
  })
}

/**
 * Change format
 * interfaces obj -> interfaces list
 * interfaces obj: {  PinToPin: { name, pcb } }, Serdes: { PCIe: { name, pcb } } }
 * interfaces list: PinToPin - [{ type: "PinToPin", list: [{ name, pcb }] }]
 *                  
 * @param {*} interfaces 
 */
function changeInterfacesFormat(interfaces) {
  let arr = [], newArr = [];
  // ["Serdes"]
  Object.keys(interfaces).forEach(type => {
    let list = [];
    const typeValue = interfaces[type];
    // ["PCIe"] 
    Object.keys(typeValue).forEach(subType => {
      if (subType === 'PCIe') {
        if (typeValue[subType].length > 0) {
          list.push({ subType, interfacesList: typeValue[subType] })
          typeValue[subType].map((item, index) => {
            newArr.push({ type: "Serdes", subType: 'PCIe', name: item.name, pcb: item.pcb, lane_numbers: item.lane_numbers })
          })
        }
      }
    });
    if (list.length > 0) {
      const obj = { type, list }
      arr.push(obj);
    }
  });
  return [arr, newArr];
}

let managerList = null;
export function getManagerList() {
  return new Promise((resolve, reject) => {
    getInterfacesList(projectId).then(res => {
      managerList = changeManagerFormat(res.data.data);
      resolve(managerList);
    })
  })
}

function changeManagerFormat(interfaces) {
  let arr = [];
  if (!interfaces) return arr;
  Object.keys(interfaces).forEach(type => {
    const typeValue = interfaces[type];
    Object.keys(typeValue).forEach(subType => {
      // TODO: support PCIE multiple interface simulation
      // if (subType === 'PCIe') {
      //   arr.push(...typeValue[subType].map(item => ({ ...item, pcb: [item.pcb], interfaceType: subType, mainType: type })))
      // } else {
      //   const names = Array.from(new Set(typeValue[subType].map(item => item.name)));
      //   names.forEach(name => {
      //     const pcb = typeValue[subType].filter(item => item.name === name).map(item => item.pcb);
      //     arr.push({ name, pcb, interfaceType: subType, mainType: type });
      //   })
      // }
      // if (subType !== 'PCIe') {
      const names = Array.from(new Set(typeValue[subType].map(item => item.name)));
      names.forEach(name => {
        const pcb = typeValue[subType].filter(item => item.name === name).map(item => item.pcb);
        arr.push({ name, pcb, interfaceType: subType, mainType: type });
      })
      // }
    })
  });
  return arr;
}

export function uploadLibraryFiles(files, fileType) {
  return new Promise((resolve, reject) => {
    uploadLibrary({ projectId, files, fileType }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(true);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    })
  })
}

export function getVerificationLog({ mainType, type, path }) {
  return new Promise((resolve, reject) => {
    getFilesInProject({ projectId, mainType, type, path }).then(res => {
      resolve(res.data);
    }, error => {
      resolve('');
      /*  resolve('Log not found!'); */
    })
  })
}

/**
 * get connector and channel model list.
 * 2019.08.13
 * 
 * @export
 * @param {string} fileType connector/channel
 * @param {string} fileName file name
 * @param {string} libraryId fild id
 * @returns Promise
 */

export function getLibraryfile({ fileType, fileName, libraryId }) {
  return new Promise((resolve, reject) => {
    const fileContent = publicModelHelper.getModelList(fileType, fileName, null);
    if (fileContent) {
      resolve(fileContent);
    } else {
      getLibraryFileInProject_v2(projectId, libraryId).then(res => {
        if (res.data) {
          resolve(publicModelHelper.getModelList(fileType, fileName, res.data));
        } else {
          resolve([]);
        }
      }, error => {
        resolve([]);
        console.log(error);
      })
    }
  })
}

export function getInterfaceWorkStatus(verificationId) {
  return workflowFliter(apiProcess(getWorkFlowStatus, verificationId));
};

let publicModelHelper = null;
/**
 * upload config file(json) 
 *2019.08.15
 * @export
 * @param {string} file 
 * @returns XMLHttpRequest
 */

export function uploadConfigsFile(file) {
  return new Promise((resolve, reject) => {
    uploadConfigFile(projectId, file).then(res => {
      resolve(res.data.data);
    }, error => {
      reject(error);
    })
  })
}

export function getLibraryFileInProject(libraryId) {
  return new Promise((resolve, reject) => {
    getLibraryFileInProject_v2(PROJECT_ID, libraryId).then(res => {
      if (res) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      resolve(null);
      console.error(error);
    });
  });
};