import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { TRANSLATION_FLOW, CANCEL_UPLOAD_WORKFLOW, GET_TRANSLATION_MONITOR, CHANGE_DESIGN } from './actionType';
import {
  updateUploadWorkflow,
  updateMsg,
  cleanUploadPCBStatus,
  translationMonitor,
  translationDebug,
  updateTranslationProgress,
  updateEndMsg,
  getTranslationMonitor,
  updateDesignLog
} from './action';
import { getWorkFlow, cancelWorkflow } from '@/services/api/v2/workflowCtrl';
import { RUNNING, SUCCEED, FAILED, CANCEL } from '@/constants/workflowStatus';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import { getMonitor, getDesignLog } from '@/services/workflow/workflow';
import { openProject, updateExpand } from '../../../store/project/action';
import { cleanTabMonitorStatus } from '../../../../MonitorStore/action';
import { REPLACE_PCB } from '@/constants/treeConstants';
import { replacePCB } from '../../../store/project/action';

function* pcbTranslationFlow(action) {
  const { response, designId: _designId, pcbType } = action;
  const responseData = response.data;
  const { AndesV2Reducer: { AndesV2UploadReducer: { uploadProjectId } } } = yield select();
  if (responseData.code === SUCCESS) {
    //Directory (Aurora DB,AAF)
    if (responseData.data && !responseData.data.workflowId) {
      // clean upload status
      yield put(cleanUploadPCBStatus());
      const { AndesV2Reducer: { project: { openProjectId } } } = yield select();
      if (openProjectId === uploadProjectId) {
        yield call(reOpenProject, uploadProjectId, responseData.data.id);
        yield put(cleanTabMonitorStatus());
      }
      yield put(updateMsg(`==> Upload completed.`));
      yield call(_pcbReplaceFn, _designId);
    }

    // Translation
    if (responseData.data && responseData.data.workflowId) {
      const { workflowId, designId } = responseData.data;

      yield put(updateUploadWorkflow(designId, workflowId));
      // begin translate
      yield put(updateMsg(`==> Finish uploading ${pcbType}.\n==> Start compiling ${pcbType}.`));
      yield call(beginTranslation, { workflowId, designId, pcbType });
    }
  } else {
    let msg = responseData.msg ? responseData.msg : 'Unknown reason.'
    yield put(updateMsg('==> Upload failed: ' + msg));
    // clean upload status
    yield put(cleanUploadPCBStatus());
    yield call(reOpenProject, uploadProjectId);
    yield call(_pcbReplaceFn, _designId);
  }
}

function* beginTranslation(action) {
  const { workflowId, designId, pcbType } = action;

  const { AndesV2Reducer: { AndesV2UploadReducer: { uploadProjectId } } } = yield select();
  try {
    let res = yield call(getWorkFlow, workflowId);
    if (!res || !res.data || res.data.code === CHECK_FAIL) {
      // yield put(updateMsg(`==> Failed compiling ${pcbType}. \n ${res.data.msg} \n ==> Upload failed!`));
      yield put(updateMsg(`==> ${res.data.msg}`));
      // clean upload status
      yield put(cleanUploadPCBStatus());
      yield call(reOpenProject, uploadProjectId, designId);
      yield delay(3000);
      yield put(getTranslationMonitor(workflowId));
      return;
    }
    let { code, data } = res.data;
    let { status } = data;
    while (status === RUNNING) {

      yield delay(3000);
      res = yield call(getWorkFlow, workflowId);
      if (!res || !res.data || res.data.code === CHECK_FAIL) {
        //yield put(updateEndMsg(`==> Failed compiling ${pcbType}. \n ${res.data.msg} \n ==> Upload failed!`));
        yield put(updateMsg(`==> ${res.data.msg}`));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        yield call(reOpenProject, uploadProjectId);
        break;
      }
      code = res.data.code;
      data = res.data.data;
      status = data.status;
      //get monitor
      yield put(getTranslationMonitor(workflowId));
      if (code === SUCCESS) {
        const progress = parseInt(data.progress);
        const running = updateTranslationProgress({ progress });
        yield put(running);
      } else if (code === CHECK_FAIL) {
        yield put(updateMsg(`==> Failed compiling ${pcbType}. \n ${res.data.msg} \n ==> Upload failed!`));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        break;
      }
    }
    if (code === SUCCESS) {
      if (status === SUCCEED) {
        const succeeded = updateTranslationProgress({ progress: 100 });
        yield put(succeeded);
        // clean upload status
        yield put(cleanUploadPCBStatus(true));
        const { AndesV2Reducer: { project: { expandedKeys, openProjectId }, AndesV2UploadReducer: { currentPCBKey } } } = yield select();
        if (openProjectId === uploadProjectId) {
          yield call(reOpenProject, uploadProjectId, designId);
          //expand pcb tree
          let Keys = [...expandedKeys];
          if (currentPCBKey && currentPCBKey !== REPLACE_PCB && !Keys.includes(currentPCBKey)) {
            Keys.push(currentPCBKey);
          }
          yield put(updateExpand([...Keys]));
        }
      }

      if (status === FAILED) {
        const msg = `==> ${data.message}`;
        yield put(updateEndMsg(msg));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        yield call(reOpenProject, uploadProjectId);
      }

      if (status === CANCEL) {
        const msg = `==> Upload ${pcbType} cancelled.`;
        yield put(updateEndMsg(msg));
        // clean upload status
        yield put(cleanUploadPCBStatus());
        yield call(reOpenProject, uploadProjectId);
      }
      yield call(_pcbReplaceFn, designId);
      yield delay(3000);
      yield put(getTranslationMonitor(workflowId));
    } else if (code === CHECK_FAIL) {
      yield put(updateEndMsg(`==> Failed compiling ${pcbType}.\n ${res.data.msg}\n ==> Upload failed!`));
      // clean upload status
      yield put(cleanUploadPCBStatus());
      yield call(reOpenProject, uploadProjectId);
      yield call(_pcbReplaceFn, designId);
      yield delay(3000);
      yield put(getTranslationMonitor(workflowId));
      return;
    }

  } catch (error) {
    const msg = `==> Failed compiling ${pcbType}.`;
    yield put(updateEndMsg(msg));
    // clean upload status
    yield put(cleanUploadPCBStatus());
    yield call(reOpenProject, uploadProjectId);
    yield call(_pcbReplaceFn, designId);
    console.error(error);
  }
}

const KEYARR = ['Statistics', 'Total counts', 'Layers:', 'Trace length',
  'Net histograms', 'Polygon edges', 'Pins:', 'Vias:'];
function filterContent(log) {
  try {
    if (KEYARR === 'undefined' || !(KEYARR instanceof Array) || KEYARR.length < 1) return false;
    const arrTxt = KEYARR.join('|'),
      regObj = new RegExp(arrTxt, 'ig');
    const _match = log.match(regObj);
    if (_match && _match.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
}

function* cancelUploadPCBWorkflow(action) {
  const { workflowId } = action;
  yield call(cancelWorkflow, workflowId);
  yield put(cleanUploadPCBStatus());
}

function* _getTranslationMonitor(action) {
  const { workflowId } = action;
  let monitor = yield call(getMonitor, workflowId);
  let _monitor = [], _debugMonitor = [];

  const progress = /[0-9]+[%]/g;
  for (let item of monitor) {

    // Filter progress bar
    const _match = item.log.match(progress);
    if (_match && _match.length > 0) {
      continue;
    }

    // Filter '' string
    if (!item.log) {
      continue;
    }

    // Filter debug log
    if (filterContent(item.log)) {
      _debugMonitor.push(item);
      continue;
    }

    _monitor.push(item);
  };
  yield put(translationMonitor(_monitor));
  yield put(translationDebug(_debugMonitor));
}

function* reOpenProject(uploadProjectId, designId) {
  const { AndesV2Reducer: { project: { openProjectId } } } = yield select();
  if (openProjectId === uploadProjectId) {
    const _designId = designId ? designId : "upload";
    yield put(openProject(uploadProjectId, _designId));
  }
}

function* _pcbReplaceFn(designId) {
  const { AndesV2Reducer: { AndesV2UploadReducer: { currentPCBKey } } } = yield select();
  if (currentPCBKey === REPLACE_PCB) {
    //pcb replace
    yield put(replacePCB(designId));
  }
}

function* _changeDesign(action) {
  const { designId } = action;
  if (designId !== "upload") {
    yield call(getPCBLog, { designId });
  }
}

function* getPCBLog(action) {
  const { designId } = action;
  const log = yield call(getDesignLog, designId);
  yield put(updateDesignLog({
    log,
    designId
  }))
}

function* uploadSaga() {
  yield takeEvery(TRANSLATION_FLOW, pcbTranslationFlow);
  yield takeEvery(CANCEL_UPLOAD_WORKFLOW, cancelUploadPCBWorkflow);
  yield takeEvery(GET_TRANSLATION_MONITOR, _getTranslationMonitor);
  yield takeEvery(CHANGE_DESIGN, _changeDesign);
}

export default uploadSaga;