import React, { Component, Fragment } from "react";
import { Select, Input } from 'antd';
import { numberCheck } from "../../../services/helper/dataProcess";
import UnitAddonAfter from "../../UnitAddonAfter";
import { getViaList, PORT_REFERENCE_VIA, CIRCUIT, getViaListByDistance } from "../../../services/ExtractionPortsHelper";
import { strDelimited } from "../../../services/helper/split";
import './index.css';

const Option = Select.Option;
class ReferenceVia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      distance: 10,
      distanceUnit: "mm",
      viaList: [],
      allViaList: []
    }
  }

  componentDidMount = () => {
    const { referNetsInfo, componentPinInfo } = this.props;
    const { distance, distanceUnit } = this.state;
    const allViaList = getViaList(referNetsInfo, componentPinInfo);
    const viaList = getViaListByDistance({ allViaList, distance, distanceUnit })
    this.setState({
      allViaList,
      viaList
    })
  }

  addonAfter = () => {
    const unit = this.state.distanceUnit;
    return UnitAddonAfter({
      unit,
      changeUnit: (key) => this.changeUnit(key),
      list: ["mm", "mil"]
    });
  }

  changeUnit = (unit) => {
    const { allViaList, distance } = this.state;
    const viaList = getViaListByDistance({
      allViaList,
      distance,
      distanceUnit: unit
    })
    this.setState({
      distanceUnit: unit,
      viaList
    });

  }

  updateValue = (e) => {
    this.setState({
      distance: e.target.value
    });
    const { errorMsg } = this.props;
    if (errorMsg && errorMsg.type === "distance") {
      this.props.updateError(null);
    }
  }

  valueOnBlur = (e, type) => {
    const value = e.target.value;
    const error = numberCheck(value);
    if (error) {
      this.props.updateError({ type, error: `Search Distance ${error}` });
    } else {
      const { allViaList, distanceUnit } = this.state;
      const viaList = getViaListByDistance({
        allViaList,
        distance: value,
        distanceUnit
      });
      this.setState({
        viaList
      })
    }
  }

  changeVia = (key) => {
    const keys = strDelimited(key, "::");
    this.props.changeReference(PORT_REFERENCE_VIA, { point_x: keys[0], point_y: keys[1] });
  }

  changeLayer = () => {

  }

  render() {
    const { distance, viaList } = this.state;
    const { reference, layerList = [], portType } = this.props;
    const { point_x, point_y } = reference;
    const value = viaList.find(item => item.mX === Number(point_x) && item.mY === Number(point_y));
    let viaValue = "";
    if (value) {
      viaValue = `( ${value.mX} , ${value.mY} ) ( ${value.net} , ${value.distance}mil )`
    }
    return <Fragment>
      {this.getSelectComponent({
        label: "Reference Via​",
        value: viaValue,
        changeFn: this.changeVia,
        options: viaList || [],
        type: "via"
      })}
      {portType === CIRCUIT ? this.getSelectComponent({
        label: "Layer​",
        value: "",
        changeFn: this.changeLayer,
        options: layerList,
        type: "layer"
      }) : null}
      <div className="signal-port-edit-item">
        <label>Search distance​</label>
        <Input
          className='aurora-input'
          value={distance}
          onChange={(e) => this.updateValue(e)}
          onBlur={(e) => this.valueOnBlur(e)}
          addonAfter={this.addonAfter()}
        />
      </div>
    </Fragment>
  }


  getSelectComponent = ({
    label,
    value,
    changeFn,
    options
  }) => {
    return <div className='signal-port-edit-item'>
      <label>{label}</label>
      <Select
        className='aurora-select'
        value={value}
        showSearch
        onChange={(key) => changeFn(key)}
        popupMatchSelectWidth={false}
        popupClassName='aurora-select-dropdown'
      >
        {options.map((item, index) => {
          const itemValue = `( ${item.mX} , ${item.mY} ) ( ${item.net} , ${item.distance}mil )`;
          return <Option
            key={index}
            title={itemValue}
            value={`${item.mX}::${item.mY}::${item.net}`}
            className={itemValue === value ? "ant-select-dropdown-menu-item-selected" : ""}
          >{itemValue}</Option>
        })}
      </Select>
    </div >
  }
}

export default ReferenceVia;