class Channels {
  constructor() {
    this.channels = new Map(); // key - channelId, value - Channel

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
  };

  set(id, channel) {
    this.channels.set(id, channel)
  };

  get(id) {
    return this.channels.get(id) || null;
  };

  delete(id) {
    this.channels.delete(id);
  };

  getInfoFromVerificationId(verificationId) {
    const keys = Array.from(this.channels.keys())
    for (let key of keys) {
      const info = this.channels.get(key);
      if (info && info.verificationId === verificationId) {
        return info
      }
    }
    return null
  }

  getName(id) {
    const got = this.get(id);
    return got ? got.name : null;
  }

};
const RockyChannels = new Channels();
export default RockyChannels;