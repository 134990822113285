import { connect } from 'react-redux';
import Profile from '@/components/Profile';

const mapState = (state) => {
  const { simulationReducer } = state.SierraReducer;
  const { profile } = simulationReducer;

  return {
    profileLog: profile || []
  }
}

export default connect(mapState, null)(Profile);
