// display net connection
export const NETS = 'Nets',
  NETS_PINS = 'Nets + Pin Numbers',
  NETS_PINNAMES = 'Nets + Pin Names',
  PINS = 'Pins',
  PINNAMES = 'Pin Names';

// auto connection
export const BY_NETS = 'By Nets',
  BY_PINS = 'By Pins',
  NONE = 'None';