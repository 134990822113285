import http from '../http';
import JSZip from 'jszip';

/**
 * run BMA channel info
 * 2024/09/13
 * 
 * @export
 * @param run BMA channel info
 * @param bmaChannelId
 * @param content
 * @returns
 */
export function runBMAChannelInfo({ verificationId, selectInputFileIds, simulationTypes }) {
  return http.post(`bma/channel/simulation`, { selectInputFileIds, verificationId, simulationTypes })
}

/**
 * Get BMA Verification Log
 * @param {string} verificationId
 * @export
 * @returns XMLHttpRequest
 */
export function getVerificationLog(verificationId) {
  return http.get(`bma/channel/verification/${verificationId}/log`)

}