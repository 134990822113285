import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createVectorData, updateVectorData } from '@/services/Sierra/library';
import { expandMenu } from '../store/sierra/action';
import { openLibraryFolder, getLibraryTreeByType } from '../store/library/action';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Spin, Progress } from 'antd';
import { getStimulusData } from '../../../services/Sierra/library';
import VectorEditForm from '@/components/VectorEdit';
import './sierraPanel.css';

class VectorEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      uploadProgress: -1
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onRef = (ref) => {
    this.childEdit = ref;
  }

  saveVectorData = () => {
    const { id, parentId } = this.props;
    const { uploadProgress } = this.state;
    const { action, formatData, name } = this.childEdit.saveData();
    if (action === 'stop') {
      return;
    } else if (action === 'close') {
      this.props.closeLibraryPanel();
      return;
    } else if (action === 'save') {
      const info = { ...formatData, fileName: name, typeName: 'vector', folderId: parentId };
      if (uploadProgress > -1) {
        return;
      }
      if (id) {
        //update
        this.setState({
          uploading: true,
          uploadProgress: 0
        })
        updateVectorData(id, info, this.config).then(res => {
          const { expandedKeys } = this.props;
          let keys = [...expandedKeys];
          this.props.openLibraryFolder(parentId, 'vector', false);
          if (!keys.includes('vector') || !keys.includes(`'vector'-${parentId}`)) {
            keys.push(parentId === -1 ? 'vector' : `'vector'-${parentId}`);
          }
          this.props._expandMenu([...keys]);
          this.setState({
            uploading: false
          });
          setTimeout(() => {
            this.props.closeLibraryPanel();
          }, 500);
        });
      } else {
        createVectorData(info, this.config).then(res => {
          const { expandedKeys } = this.props;
          let keys = [...expandedKeys];
          this.props.openLibraryFolder(parentId, 'vector', true);
          if (!keys.includes('vector') || !keys.includes(`'vector'-${parentId}`)) {
            keys.push(parentId === -1 ? 'vector' : `'vector'-${parentId}`);
          }
          this.props.getLibraryTreeByType(['vector']);
          this.props._expandMenu([...keys]);
          this.setState({
            uploading: false
          })
          setTimeout(() => {
            this.props.closeLibraryPanel();
          }, 500);
        });
      };
    }
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  getProgressFormat = (percent) => {
    if (percent === 100) {
      return <CheckCircleOutlined className='upload-success-icon' />;
    } else {
      return `${percent}%`;
    }
  };

  render() {
    const { uploadProgress, uploading } = this.state;
    const { name, defaultVectorName, vectorList, id } = this.props;

    return (<div className='vector-value-box'>
      {uploadProgress > -1 && <Progress
        size={{ height: 12 }}
        strokeColor={'#1890ff'}
        percent={uploadProgress}
        format={(percent) => this.getProgressFormat(percent)}
        className="library-edit-upload-progress-bar"
      />}
      <Spin spinning={uploading} tip='Uploading...'>
        <VectorEditForm
          id={id}
          name={name}
          defaultVectorName={defaultVectorName}
          vectorList={vectorList}
          getEditData={getStimulusData}
          onRef={this.onRef}
          unit='ns' // us, ns, ps
        />
      </Spin>
    </div>
    )
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra } } = state;
  return {
    expandedKeys: sierra.expandedKeys,
  }
}

const mapDispatch = (dispatch) => ({
  openLibraryFolder(libraryId, key, update) {
    dispatch(openLibraryFolder(libraryId, key, update));
  },
  _expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  getLibraryTreeByType(type) {
    dispatch(getLibraryTreeByType(type))
  }
});

export default connect(mapState, mapDispatch)(VectorEdit);