import {
  getChannelSetting, getHistoryList, getChannelFinishedTime, saveToHistory, getHistorySetting, getHistoryCurveData,
  getEndToEndChannelSetting, getEndToEndCurveData, getAndesPreLayoutJson,
  getExperimentSetting, getExperimentCurveData
} from '../../api/Andes_v2/result';
import {
  getMultiPCBChannelFinishedTime, saveMultiPCBResultToHistory, getMultiPCBHistoryList, getMultiPCBHistoryCurveData,
  getMultiPCBHistorySetting
} from '../../api/Andes_v2/multiPCBHistory';
import apiProcess from '../../api/utility';

/**
 * If isEndToEnd == true, the response data is a channel json { config, content,... }
 * If isEndToEnd == false, the response data includes channels json and endToEnd json
 *  { channels: [channel{ config, content }], endToEnd: { content } }
 *
 * @export
 * @param {*} id - If isEndToEnd == ture, the id means end to end channel id, else the id means channel id
 * @param {*} isEndToEnd
 * @return {*} 
 */
export async function getVerificationJson(id, isEndToEnd, type = null) {
  let response = null;
  if (isEndToEnd) {
    response = await getEndToEndChannelSetting(id, type);
  } else {
    response = await getChannelSetting(id, type);
  }
  if (response &&
    response.data &&
    (response.data.content || (response.data.endToEnd && response.data.channels))
  ) {
    return response.data;
  }
  return null;
}

export async function getExperimentVerificationJson(experimentId, type = null) {
  let response = await getExperimentSetting(experimentId, type);
  if (response && response.data &&
    (response.data.content || (response.data.endToEnd && response.data.channels))
  ) {
    return response.data;
  }
  return null;
}

export async function getAndesHistoryList(channelId) {
  try {
    return await apiProcess(getHistoryList, channelId);
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAndesChannelFinishedTime(channelId) {
  try {
    return await apiProcess(getChannelFinishedTime, channelId);
  } catch (error) {
    console.error(error);
    return '';
  }
}

export async function saveAndesResultsToHistory({ channelId, name }) {
  try {
    return await apiProcess(saveToHistory, { channelId, name })
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getHistoryVerificationJson(historyId, isEndToEnd, type = null) {
  try {
    let response = null;
    if (isEndToEnd) {
      response = await getMultiPCBHistorySetting(historyId);
    } else {
      response = await getHistorySetting(historyId, type);
    }
    if (response &&
      response.data &&
      (response.data.content || (response.data.endToEnd && response.data.channels))
    ) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
}

export function getAndesHistoryCurveData({ historyId, signal, pair, couplingType, resultDataParam }) {
  return apiProcess(getHistoryCurveData, { historyId, signal, pair, couplingType, resultDataParam })
}

export function getEndToEndChannelResultCurve({ endToEndChannelId, resultDataParam, signal, pair, couplingType }) {
  return apiProcess(getEndToEndCurveData, { endToEndChannelId, resultDataParam, signal, pair, couplingType })
}

export function getExperimentResultCurveData({ experimentId, resultDataParam }) {
  return apiProcess(getExperimentCurveData, { experimentId, resultDataParam })
}

export async function getAndesMultiPCBChannelFinishedTime(endToEndId) {
  try {
    return await apiProcess(getMultiPCBChannelFinishedTime, endToEndId);
  } catch (error) {
    console.error(error);
    return '';
  }
}

export async function getAndesMultiPCBHistoryList(endToEndId) {
  try {
    return await apiProcess(getMultiPCBHistoryList, endToEndId);
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function saveAndesMultiPCBResultsToHistory({ endToEndId, name }) {
  try {
    return await apiProcess(saveMultiPCBResultToHistory, { endToEndId, name })
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAndesMultiPCBHistoryCurveData({ historyId, resultDataParam, signal, pair, couplingType }) {
  return apiProcess(getMultiPCBHistoryCurveData, { historyId, resultDataParam, signal, pair, couplingType })
}

export async function getPreLayoutJson(channelId) {
  const response = await getAndesPreLayoutJson(channelId);
  if (response &&
    response.data &&
    (response.data.content)
  ) {
    return response.data;
  }
  return null;
}