import { call, select, takeEvery, put, delay, cancel, fork } from "redux-saga/effects";
import { getChannelContentPromise } from "../../../../../services/Andes_v2/channel";
import sweepConstructor from "../../../../../services/Andes_v2/sweep/sweepConstructor";
import { updateMultiSimulationInfo, updateSimulationReducer } from "../action";
import { channelErrorCheck } from "../../../Channel/errorCheck";
import { getDesignStackupJson } from "../../../../../services/api/designFile";
import { ANDES_V2 } from "../../../../../constants/pageType";
import { stackupErrorCheck } from "../../../../../services/Stackup";
import { START_EXPERIMENTS_SIMULATION } from "../actionTypes";
import { getDesignHybridInfoPromise } from "../../../../../services/helper/cutDesign/hybrid";
import { changeTabMenu, openTabFooter } from "../../../../MonitorStore/action";
import { getExperimentsBySweepIdPromise, startSweepExtractionPromise } from "../../../../../services/Andes_v2/sweep";
import { getVerificationWorkFlow } from "../../../../../services/workflow/workflow";
import { checkChannelSimStatus, startNewWorkflow } from "../saga";
import { EXPERIMENTS } from "../../../../../constants/treeConstants";
import dayjs from 'dayjs';
import { updateExperimentData } from "../../sweep/action";
import { expandChannel } from "../../channel/action";

let deleteTask = null;
function* _startExtraction(action) {
  const { experimentIds } = action;
  const { AndesV2Reducer: { sweep: { sweepId } } } = yield select();
  const { designId, channelId, verificationId, name } = sweepConstructor.getSweep(sweepId) || {};
  //check prev simulation status,if running or waiting, return
  if (yield call(checkChannelSimStatus, verificationId)) {
    return;
  }
  // channelInfo -> check error
  let channelInfo = {};
  try {
    channelInfo = yield call(getChannelContentPromise, channelId);
    if (!channelInfo) {
      return;
    }
  } catch (error) {
    console.error(error);
  }
  // check error
  const isError = yield call(checkChannelInfo, { channelInfo, designId, channelId, verificationId, name });
  if (isError) {
    return;
  }

  // start extraction
  try {
    if (deleteTask) {
      yield cancel(deleteTask)
    }
    const { AndesV2Reducer: { simulation } } = yield select();
    let _simulation = { ...simulation };

    // Circular call changes wait for [time] information and can be canceled with cancel
    deleteTask = yield fork(updateWaitStartMsg, { verificationId, _simulation })
    // Start the simulation, block the process, and cancel the above cycle after the execution completes
    const response = yield call(startSweepExtractionPromise, [experimentIds]);
    yield cancel(deleteTask)

    if (!response || !Array.isArray(response) || response.length === 0) {
      _simulation[verificationId] = {
        endMsg: response && response.msg ? response.msg : '==> Simulation failed!'
      };
      //update simulation reducer
      yield put(updateSimulationReducer(_simulation));
      yield call(autoGetExperimentData, { verificationId });
      return;
    }

    yield call(autoGetExperimentData, { verificationId });
    const verificationStatus = yield call(getVerificationWorkFlow, verificationId);
    if (verificationStatus && verificationStatus.length > 0) {
      yield call(startNewWorkflow, {
        workType: EXPERIMENTS,
        // channelId,
        sweepId,
        verificationId: verificationId,
        verificationWork: verificationStatus
      })
      // update variablesTables of status
      yield call(autoGetExperimentData, { verificationId });
      return;
    }
  } catch (error) {
    console.error(error);
    if (deleteTask) { yield cancel(deleteTask) }
    yield put(updateMultiSimulationInfo({
      verificationId: verificationId,
      info: {
        startMsg: null,
        progress: -1,
        endMsg: error ? `==> ${error}` : '==> Simulation failed!'
      }
    }));
    yield call(autoGetExperimentData, { verificationId });
  }
}

function* checkChannelInfo(action) {
  const { channelInfo, designId, channelId, verificationId, name } = action;
  const { AndesV2Reducer: { simulation, sweep: { notExistNets } } } = yield select();

  let _simulation = { ...simulation }, designStackupErrors = [], stackupError = null;
  _simulation[verificationId] = {
    startMsg: '==> Checking setup...'
  }
  yield put(updateSimulationReducer(_simulation));
  yield put(openTabFooter());
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    menuType: "simulation",
    currentVerificationId: verificationId,
    verificationName: name,
  }));
  // check channel
  let setupInfo = { content: {} }, hybrid_regions = [];
  const setupInfoRes = yield call(getChannelContentPromise, channelId);
  if (setupInfoRes && setupInfoRes.content) {
    setupInfo = setupInfoRes;
  }
  if (channelInfo.content && channelInfo.content.extraction && channelInfo.content.extraction.hybrid && channelInfo.content.extraction.type && channelInfo.content.extraction.type === "SIwave") {
    const info = yield call(getDesignHybridInfoPromise, { verificationId: channelInfo.verificationId });
    hybrid_regions = info.hybrid_regions;
  }
  const stackup = yield call(getDesignStackupJson, designId) || {};
  let errorCheck = yield call(channelErrorCheck, setupInfo, null, hybrid_regions, stackup.materials);

  // To determine whether a net that does not present in channel is selected
  if (notExistNets && notExistNets.length > 0) {
    if (!errorCheck) errorCheck = [];
    errorCheck.push({
      title: `[${name}]`,
      // boldKey: `${comp.name}`,
      errorMsg: `The selected nets [${notExistNets.join(', ')}] does not exist in channel.`
    })
  }

  const design = designStackupErrors.find(item => item.id === designId);
  if (!design) {
    try {
      if (!stackup || !stackup.layers) {
        stackupError = [{ error: "Stackup file not exist." }]
      } else {
        const extractionSolver = setupInfo && setupInfo.content && setupInfo.content.extraction ? setupInfo.content.extraction.type : null;
        stackupError = stackupErrorCheck({
          ...stackup,
          layers: stackup.layers,
          materialList: stackup.materials,
          unit: stackup.unit,
          extractionSolver,
          pageType: ANDES_V2
        });
      }
      designStackupErrors.push({ id: designId, stackupError });
    } catch (error) {
      console.error(error)
    }
  } else {
    stackupError = design.stackupError;
  }

  let isError = false;
  if ((errorCheck && errorCheck.length)
    || (stackupError && stackupError.length)
  ) {
    _simulation[verificationId] = {
      errorCheck,
      stackupError
    }
    isError = true;
  } else {
    _simulation[verificationId] = {
      startMsg: '==> Start simulating...',
    }
  }
  //update stackup error 
  _simulation.designStackupErrors = designStackupErrors;
  //update stimulation reducer info
  yield put(updateSimulationReducer(_simulation));
  return isError;
}

function* updateWaitStartMsg(action) {
  const { verificationId, _simulation } = action;
  let time, isFirst = true;
  while (true) {
    if (isFirst) {
      yield delay(3000)
    } else {
      yield delay(30000); // wait 30s
    }
    time = new Date();
    isFirst = false
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    if (_simulation[verificationId].startMsg) {
      const addMsg = `\n==> ${waitingTime}  Data pre-processing...`;
      _simulation[verificationId] = {
        startMsg: _simulation[verificationId].startMsg + addMsg,
      };
    }
    yield put(updateSimulationReducer(_simulation));
  }
}

export function* autoGetExperimentData(action) {
  const { AndesV2Reducer: { sweep: { sweepId } } } = yield select();
  const { verificationId } = action;
  const { channelId, designId, id } = sweepConstructor.getSweepByVerificationId(verificationId) || {};
  if (channelId && designId && id) {
    yield put(expandChannel(channelId, designId));
  }
  if (id === sweepId) {
    const variablesTables = yield call(getExperimentsBySweepIdPromise, id);
    yield put(updateExperimentData({ variablesTables }));
  }
}

function* sweepExtractionSaga() {
  yield takeEvery(START_EXPERIMENTS_SIMULATION, _startExtraction);
}

export default sweepExtractionSaga;