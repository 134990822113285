export const
  LEFT_RIGHT_lAYOUT = 1,
  TOP_BOTTOM_LAYOUT = 2,
  SINGLE_PAGE_LAYOUT = 3,
  PCB_ONLY = 'a',
  PCB_TOP_BOTTOM = 'b',
  PCB_LEFT_RIGHT = 'c';

// Setup, PCB Mixed layout (Left, Right)
// setup left, pcb[only] right 1a
// setup left, pcb[top|bottom] right 1b
// setup left, pcb[left|right] right 1c

// Setup, PCB Mixed layout (Top, bottom)
// PCB[only] top, setup bottom 2a
// PCB[top|bottom] top, setup bottom 2b
// PCB[left|right] top, setup bottom 2c

// Single layout
// Setup/PCB[only] 3, 3a
// PCB[top|bottom] layout 3b
// PCB[left|right] layout 3c