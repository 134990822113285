import {
  UPLOAD_PROGRESS_CHANGE,
  CLOSE_UPLOAD_PROGRESS_MODAL,
  TRANSLATION_RUNNING,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
  GET_WORK_FLOWS,
  TRANSLATION_MONITOR,
  GET_TRANSLATION_MONITOR,
  UPLOAD_PROJECT_ID,
  ADD_MSG,
  UPDATE_UPLOAD_WORKFLOW,
  SAVE_CURRENT_PCB_KEY,
  TRANSLATION_DEBUG,
  CANCEL_UPLOAD_WORKFLOW,
  UPDATE_COMPRESS_PROGRESS,
  UPLOAD_PCB_START_UPDATE_STATUS,
  TRANSLATION_FLOW,
  CLEAR_REPLACE_PCB_INFO,
  CHANGE_UPLOAD_DISABLE_STATUS
} from './actionType';

export const uploadPCBStart = (projectId, uploadType) => ({
  type: UPLOAD_PCB_START_UPDATE_STATUS,
  projectId,
  uploadType
})

export const changeUploadDisableStatus = (disabled) => ({
  type: CHANGE_UPLOAD_DISABLE_STATUS,
  disabled
})

export const changePercentAction = (uploadProgress) => ({
  type: UPLOAD_PROGRESS_CHANGE,
  uploadProgress
})

export const closeUploadProgressModal = (endMsg) => ({
  type: CLOSE_UPLOAD_PROGRESS_MODAL,
  endMsg
})

export const translationProgress = ({ workflowId, designId, projectID, progress }) => ({
  type: TRANSLATION_RUNNING,
  workflowId,
  designId,
  projectID,
  transProgress: progress
})

export const translationSuccess = (progress, msg) => ({
  type: TRANSLATION_SUCCESS,
  transProgress: progress,
  msg
})

export const translationFailed = (msg, progress) => ({
  type: TRANSLATION_FAILED,
  msg,
  transProgress: progress,
})

export const getWorkFlow = (workflowId, projectID, designId) => ({
  type: GET_WORK_FLOWS,
  workflowId,
  projectID,
  designId
});

export const translationMonitor = (monitor) => ({
  type: TRANSLATION_MONITOR,
  monitor
});

export const getTranslateLog = (designId) => ({
  type: GET_TRANSLATION_MONITOR,
  designId
});

export const uploadProjectID = (projectId) => ({
  type: UPLOAD_PROJECT_ID,
  projectId
});

export const addMonitorMsg = (msg) => ({
  type: ADD_MSG,
  msg
});

export const updateUploadWorkflow = (designId, workflowId) => ({
  type: UPDATE_UPLOAD_WORKFLOW,
  designId,
  workflowId
});

export const saveCurrentPCBKey = (key) => ({
  type: SAVE_CURRENT_PCB_KEY,
  key
});

export const translationDebug = (debug) => ({
  type: TRANSLATION_DEBUG,
  debug
});

export const cancelUploadWorkflow = (workflowId) => ({
  type: CANCEL_UPLOAD_WORKFLOW,
  workflowId
});

export const updateCompressProgress = (compressProgress) => ({
  type: UPDATE_COMPRESS_PROGRESS,
  compressProgress
})

export const translationFlow = (response, designId) => ({
  type: TRANSLATION_FLOW,
  response,
  designId
});

export const clearReplacePCBInfo = (designId) => ({
  type: CLEAR_REPLACE_PCB_INFO,
  designId
})
