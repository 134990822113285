import { connect } from 'react-redux';
import layoutMonitor from '../../../LayoutExplorer/components/layoutMonitor';

const mapState = (state) => {
  const { project: { openProjectId } } = state.CascadeReducer;
  const { monitorScreenInfo: { projectName } } = state.MonitorInfoReducer;
  return {
    projectId: openProjectId,
    projectName,
    subOptionType: 'group'
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(layoutMonitor);