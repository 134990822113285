import { CABLE_TOUCHSTONE, CONNECTOR_TOUCHSTONE, PKG_TOUCHSTONE, PCB_TOUCHSTONE, PASSIVE_TOUCHSTONE, GENERIC_TOUCHSTONE } from "../../../constants/libraryConstants";
import {
  getAndesLibraryList,
  saveAndesLibraryData,
  delAndesLibrary,
  getAndesLibraryDetail,
  calculateAndesImpedance,
  uploadAndesLibrary,
  getLibraryFileContent,
  getFolderFileDetail,
  getAndesIBISFileParse
} from "../../api/Andes_v2/library";
import apiProcess from "../../api/utility";
import TouchstoneHelper from "../../helper/touchstoneHelper";
import SpiceModelHelper from "./SpiceModelHelper";
import IBISModelHelper from './IBISModelHelper';
import { getFolderChildren } from "../../api/v2/libraryCtrl";
import { ANDES_V2 } from "../../../constants/pageType";


let touchstoneHelper = null;

/**
 * get library list
 * 2021.03.10
 * @return Promise
 *  */
function getLibraryList() {
  return apiProcess(getAndesLibraryList);
}

/**
 * save library data
 * 2021/03/10
 * @param {object} data { id, name, type, version, config }, id,type not allowed modified
 * @return Promise
 *  */
function saveLibraryData(data) {
  return apiProcess(saveAndesLibraryData, data);
}

/**
 * Delete library by id
 * 2021/03/10
 * @param {string} libraryId
 * @return Promise
 *  */
function deleteLibraryById(libraryId, type) {
  return new Promise(function (resolve, reject) {
    apiProcess(delAndesLibrary, libraryId).then(res => {
      if ([PKG_TOUCHSTONE, PCB_TOUCHSTONE, CABLE_TOUCHSTONE, CONNECTOR_TOUCHSTONE, PASSIVE_TOUCHSTONE, GENERIC_TOUCHSTONE].includes(type) && touchstoneHelper) {
        touchstoneHelper.deleteModel(libraryId);
      }
      resolve(res);
    }, error => {
      resolve(null);
    })
  })

}

/**
 * Get library detail data by id
 * 2021/03/10
 * @param {string} libraryId
 * @return Promise
 *  */
function getLibraryDetail(libraryId) {
  return apiProcess(getAndesLibraryDetail, libraryId);
}

function calculateImpedance(config) {
  return apiProcess(calculateAndesImpedance, config);
}

/**
 * create library
 * 2021/07/21
 * @return Promise
 *  */
function createLibrary({ files, fileType, typeName, metadata, config }) {
  return apiProcess(uploadAndesLibrary, { files, fileType, typeName, metadata, config });
}

/**
 * Get library file content by id
 * 2021/07/22
 * @param {string} libraryId
 * @return Promise
 *  */
function getFileContent(libraryId, fileName) {
  return new Promise(function (resolve, reject) {
    getLibraryFileContent(libraryId, fileName).then((res) => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.log(error);
      resolve(null);
    })
  })
}

/**
 * get touchstone file parse.
 * 2021/07/22
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */
function getTouchstoneParse(libraryId, fileName) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, ports: [] });
      return;
    }
    if (!touchstoneHelper) {
      touchstoneHelper = new TouchstoneHelper([libraryId]);
    }
    const ports = touchstoneHelper.getTouchstonePorts(libraryId);
    if (!ports) {
      getLibraryFileContent(libraryId).then((response) => {
        touchstoneHelper.parseTouchstonePorts(libraryId, response.data, fileName);
        const _ports = touchstoneHelper.getTouchstonePorts(libraryId);
        resolve({ libraryId, ports: _ports });
      },
        (error) => {
          console.error(error)
          resolve({ libraryId, ports: [] });
        })
    } else {
      resolve({ libraryId, ports });
    }
  });
}

/**
 * get pkg spice file parse.
 * 2020/11/13
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */
let spiceModelHelper = null;
function getPkgSpiceModelList(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, models: [] });
      return;
    }
    if (!spiceModelHelper) {
      spiceModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = spiceModelHelper.getSpiceModelList(libraryId);
    if (!models) {
      getLibraryFileContent(libraryId).then((response) => {
        spiceModelHelper.parseModelSelector(libraryId, response.data);
        const _models = spiceModelHelper.getSpiceModelList(libraryId);
        resolve({ libraryId, models: _models });
      }, error => {
        console.error(error);
        resolve({ libraryId, models: [] });
      })
    } else {
      resolve({ libraryId, models });
    }
  });
}

export function getFolderDetail(libraryId, productType = "andes") {
  return apiProcess(getFolderFileDetail, { libraryId, productType });
  /*   return new Promise(function (resolve, reject) {
      getFolderFileDetail({ libraryId }).then((res) => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          resolve(null);
        }
      }, error => {
        console.log(error);
        resolve(null);
      })
    }) */
}

/**
 * get IBIS file parse.
 * 2022/05/06
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */
let ibisModelHelper = null;
function getIbisModelParse(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve(null);
      return;
    }
    if (!ibisModelHelper) {
      ibisModelHelper = new IBISModelHelper([libraryId]);
    }
    const models = ibisModelHelper.getIbisModelList(libraryId);
    if (!models) {
      getAndesIBISFileParse(libraryId).then((response) => {
        ibisModelHelper.parseModelSelector(libraryId, response.data);
        const models = ibisModelHelper.getIbisModelList(libraryId);
        resolve(models);
      },
        (error) => {
          console.error(error)
          resolve(null);
        })
    } else {
      resolve(models);
    }
  });
}

export function getTouchstoneParsePorts(libraryId) {
  if (touchstoneHelper) {
    const ports = touchstoneHelper.getTouchstonePorts(libraryId);
    return ports;
  } else {
    return null;
  }
}

/**
 * get library children list by folder id
 * 2024/12/02
 * @param {string} id folder id
 * @return Promise */
function getLibraryFolderChildren(id) {
  return apiProcess(getFolderChildren, { productType: ANDES_V2, id })
}

export {
  getLibraryList,
  saveLibraryData,
  deleteLibraryById,
  getLibraryDetail,
  calculateImpedance,
  createLibrary,
  getFileContent,
  getTouchstoneParse,
  getPkgSpiceModelList,
  getIbisModelParse,
  getLibraryFolderChildren
}