import {
  CLOSE_MONITOR_TAB_FOOTER,
  OPEN_MONITOR_TAB_FOOTER,
  CHANGE_MONITOR_SCREEN_MENU,
  CLEAN_MONITOR_SCREEN_STATUS
} from './actionTypes';

export const closeTabFooter = () => ({
  type: CLOSE_MONITOR_TAB_FOOTER,
});

export const openTabFooter = () => ({
  type: OPEN_MONITOR_TAB_FOOTER,
});

export const changeTabMenu = (info) => ({
  type: CHANGE_MONITOR_SCREEN_MENU,
  info
});

export const cleanTabMonitorStatus = () => ({
  type: CLEAN_MONITOR_SCREEN_STATUS,
});