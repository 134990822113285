import http from './http'
import JSZipUtils from 'jszip-utils';

/**
 * Get report template list
 * 2021/03/15
 * @export
 * @returns
 */
export function getTemplateList(type) {
  return http.get(`/user/report_template?type=${type}`);
}

/**
 * Upload report template file
 * 2021/03/15
 * @export
 * @returns
 */
export function uploadTemplate({ files, name, config }) {
  let formData = new FormData();
  let file = files[0].file
  const blob = new Blob([file], {
    type: 'plain/text;charset=utf-8;'
  });
  formData.append('file', blob, `${name}.pptx`);
  return http.post(`/user/report_template?name=${name}`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
* Delete report template file
* 2021/03/15
* @export
* @returns
*/
export function deleteTemplateList(templateIds) {
  return http.delete(`/user/report_template`, {
    data: templateIds
  });
}

/**
 * Get thumbnail
 * 2021/04/02
 * @export
 */
export function getThumbnail() {
  const token = localStorage.getItem('token');
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(`/api/v3/user/report_template/thumbnail?&access_token=${token}`, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function getAuroraThumbnail(type) {
  return http.get(`/user/report_template/thumbnail/aurora?type=${type}`, {
    responseType: 'arraybuffer'
  })
}