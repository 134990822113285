import React, { PureComponent, Fragment } from 'react';
import { Collapse, Switch, Spin } from 'antd';
import EditableTable from '@/components/EditableTable';
import TagsInput from '@/components/TagsInput';
import RangeInput from '@/components/RangeInput';
import MaskInput from '@/components/MaskInput';
import { numberCheck } from '@/services/helper/dataProcess';
import { getReportAdvanceColumns, getReportGuideKeys, getReportGuideValue, getValue, INSERTIONLOSS, RETURNLOSS, COMRETURN } from '@/services/helper/reportHelper';
import { PRE_LAYOUT } from '@/constants/designVendor';
import './index.css';
import { ANDES_V2, CASCADE, SIERRA } from '../../constants/pageType';
import { SortFn } from '../../services/helper/sort';
import { PCIE } from '../../services/PCBHelper/constants';
import { DriverOrReceiver, DriverPoints, getDefaultList, ReceiverPoints } from '../../services/Andes_v2/constants';

class ReportAdvance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: this.props.referenceLinesColumns,
      tableData: [],
      error: ''
    }
  }

  getDefaultGuideValue = (vendor, type, referenceLineData = []) => {
    const key = (vendor === PRE_LAYOUT) ? `${type}_pre_layout` : type;
    const defaultGuideKey = getReportGuideKeys(key);
    const defaultGuideValue = getReportGuideValue(key, defaultGuideKey);
    const nameList = []
    // Filter the qualified data

    let _referenceLineData = referenceLineData.filter((item) => {
      if (defaultGuideKey.includes(item.name)) {
        nameList.push(item.name)
        return true;
      }
      return false
    })
    // Add the data that are not available
    defaultGuideValue.forEach((item) => {
      if (!nameList.includes(item.name)) {
        _referenceLineData.push(item)
      }
    })
    return _referenceLineData;
  }

  changeTableData = () => {
    const { referenceLineData, type, vendor } = this.props;
    const _referenceLineData = this.getDefaultGuideValue(vendor, type, referenceLineData);
    this.setState({
      tableData: _referenceLineData
    })
  }

  componentDidMount = () => {
    this.changeTableData();
    this.getColumns();
  }

  componentDidUpdate(prevProps) {
    const { referenceLineData, reportDesignId, type } = this.props;
    if (referenceLineData.length !== prevProps.referenceLineData.length
      || JSON.stringify(referenceLineData) !== JSON.stringify(prevProps.referenceLineData)
      || reportDesignId !== prevProps.reportDesignId) {
      this.changeTableData();
    }

    if (type !== prevProps.type) {
      this.changeTableData();
      this.getColumns();
    }
  }

  getColumns = () => {
    const { product, updateMaskTypes, symbolRate, frequency, referenceLinesColumns } = this.props;
    let _tableColumns = [...referenceLinesColumns];
    if (product === ANDES_V2) {
      _tableColumns.forEach(column => {
        if (column.key === 'name') {
          column.render = (value, record) => {
            const name = getValue(value)
            return <span>{name}</span>;
          }
        } else if (column.key === 'referenceLines') {
          column.children.forEach((referenceLine, index) => {
            if (referenceLine.key === 'x') {
              referenceLine.render = (value, record) => {
                return this.InputValueRender(record, value ? value : [], 'x');
              }
            } else if (referenceLine.key === 'y') {
              referenceLine.render = (value, record) => {
                return this.InputValueRender(record, value ? value : [], 'y');
              }
            }
          })
        } else if (column.key === 'dynamicRange') {
          column.children.forEach((dynamicRange, index) => {
            if (dynamicRange.key === 'xDynamicRange') {
              dynamicRange.render = (value, record) => {
                return this.InputValueRender(record, value ? value : [record.xMin, record.xMax], 'xDynamicRange');
              }
            } else if (dynamicRange.key === 'yDynamicRange') {
              dynamicRange.render = (value, record) => {
                return this.InputValueRender(record, value ? value : [record.yMin, record.yMax], 'yDynamicRange');
              }
            }
          })
        } else if (column.key === 'points') {
          column.render = (value, record) => {
            return <MaskInput
              maskList={value}
              typeName={getValue(record.name)}
              defaultDataRate={record.defaultDataRate && record.defaultDataRate.match(/[-+]?\d+(?:\.\d+)?/g)[0]}
              dataRate={record.dataRate}
              width='100%'
              type={record.name}
              changeMaskList={(params) => this.changeList({ inputType: 'points', ...params })}
              PCIeType={record.PCIeType}
              cphyInsertionLossSpec={record.cphyInsertionLossSpec || 'Short'}
              symbolRate={symbolRate}
              frequency={frequency}
              updateMaskTypes={updateMaskTypes}
              changeUpdateMaskTypes={this.props.changeUpdateMaskTypes}
            />
          }
        } else if (column.key === DriverOrReceiver) {
          column.children.forEach((point, index) => {
            point.render = (value, record) => {
              return <MaskInput
                maskList={value}
                typeName={getValue(record.name)}
                width='100%'
                type={record.name}
                PCIeType={record.PCIeType}
                changeMaskList={(params) => this.changeList({ inputType: point.key, ...params })}
                symbolRate={symbolRate}
                frequency={frequency}
                pointType={point.key}
                updateMaskTypes={updateMaskTypes}
                changeUpdateMaskTypes={this.changeUpdateMaskTypes}
              />
            }
          })
        }
      })
    } else {
      _tableColumns[0].render = (value, record) => {
        const name = getValue(value)
        return <span>{name}</span>;
      }
      _tableColumns[1].render = (value, record) => {
        return this.InputValueRender(record, value ? value : [], 'x');
      }
      _tableColumns[2].render = (value, record) => {
        return this.InputValueRender(record, value ? value : [], 'y');
      }
    }
    this.setState({ tableColumns: _tableColumns });
  }

  InputValueRender = (record, value, inputType) => {
    return <div>
      {inputType && inputType.match(/Range/g) ? <RangeInput
        rangeList={value}
        width='100%'
        type={`${record.name}_${inputType}`}
        changeRangeList={(type, list, errorMsg) => this.changeList({ list, inputType, type: record.name, errorMsg })}
      /> : <TagsInput
        tagList={value}
        className="report-reference-line-tags"
        type={`${record.name}_${inputType}`}
        allowedDuplicates={true}
        width='100%'
        changeTagList={(type, list, focus) => this.changeList({ list, inputType, type: record.name })}
        displayInput={true}
        tagInputBlur={(type) => this.tagInputBlur(inputType, record.name)}
      />}
    </div>
  }

  changeList = ({ list = [], inputType, type, dataRate, errorMsg, isChangeDataRate = false, cphyInsertionLossSpec, isChangeCPHYInsertionLossSpec = false }) => {
    if (isChangeDataRate) {
      this.changeDataRate(dataRate);
      return;
    }

    if (isChangeCPHYInsertionLossSpec) {
      this.changeCPHYInsertionLossSpec(type, list, cphyInsertionLossSpec);
      return;
    }

    let error = '', _list = [];
    const numberList = ['SBR', 'TDR', 'impedance']
    if (['points', DriverPoints, ReceiverPoints].includes(inputType)) _list.push(dataRate);
    list.forEach(item => {
      if (['points', DriverPoints, ReceiverPoints].includes(inputType)) {
        const data = item
        if (data) {
          _list.push(data)
        }
        _list = [...new Set(_list)];
      } else if (inputType === 'xDynamicRange' || inputType === 'yDynamicRange') {
        if (!errorMsg) {
          const data = Number(item);
          if (data || data === 0) {
            _list.push(data)
          }
        } else {
          error = errorMsg || 'The value entered must be a number.'
        }
      } else if (!numberCheck(item)) {
        const numberData = Number(item);
        // Frequency or Times
        if (inputType === 'x' && numberData < 0) {
          if (!numberList.includes(type) || type === 'impedance') {
            error = 'Frequency data needs to be positive.';
          } else {
            error = 'Times data needs to be positive.';
          }
        } else if (inputType === 'y' && (type === 'TDR' || type === 'impedance') && numberData < 0) { //Impedance
          error = 'Impedance data needs to be positive.';
        } else if (inputType === 'y' && type === 'SBR' && numberData < 0) { // Voltage
          error = 'Voltage data needs to be positive.';
        } else if (inputType === 'y' && numberData > 0 && !numberList.includes(type)) { // Magnitude
          error = 'Magnitude data needs to be negative.';
        } else {
          // const data = (parseFloat(numberData.toFixed(2))).toString()
          const data = numberData;
          if (!numberCheck(data)) {
            _list.push(data)
          }
        }
        _list = [...new Set(_list)];
      } else {
        error = 'The value entered must be a number.'
      }
    })

    if (!error) {
      if ((inputType !== 'points' && _list.length < 6) || ['points', DriverPoints, ReceiverPoints].includes(inputType)) {
        this.changeReportData(inputType, type, _list)
      } else {
        error = 'Input data cannot exceed five.'
      }
    }

    if (error) {
      this.setState({
        error
      })
      setTimeout(() => {
        this.setState({
          error: null
        })
      }, 3000)
    }
  }

  changeDataRate = (dataRate) => {
    let _tableData = [...this.state.tableData];
    _tableData.forEach(item => {
      if (item.PCIeType === PCIE && (item.name === INSERTIONLOSS || item.name === RETURNLOSS || item.name === COMRETURN)) {
        const defaultList = getDefaultList({ PCIeType: item.PCIeType, type: item.name, dataRate });
        item.points = [...defaultList];
      }
      item.dataRate = dataRate;
    })
    this.setState({
      tableData: _tableData
    })
  }

  changeCPHYInsertionLossSpec = (type, list, cphyInsertionLossSpec) => {
    let _tableData = [...this.state.tableData];
    const index = _tableData.findIndex(item => item.name === type);
    if (index > -1) {
      _tableData[index].cphyInsertionLossSpec = cphyInsertionLossSpec;
      _tableData[index].points = list;
      this.setState({
        tableData: _tableData
      })
    }
  }

  changeReportData = (inputType, type, list) => {
    let _isXTable = this.props.isXTable;
    let _tableData = [...this.state.tableData], disabled = true;
    _tableData.forEach(item => {
      if (item.name === type) {
        if (['points', DriverPoints, ReceiverPoints].includes(inputType)) {
          item.dataRate = list[0];
          list.shift();
        }
        item[inputType] = list
      }
      if (item.x.length) {
        disabled = false
      }
    })

    if (disabled && _isXTable) {
      _isXTable = false
    }

    this.setState({
      tableData: _tableData,
    })
    this.props.changeReferenceLineData(_tableData, _isXTable)
  }

  tagInputBlur = (inputType, type) => {
    const _referenceLineData = [...this.props.referenceLineData]
    const list = (_referenceLineData.find(item => item.name === type) || {})[inputType]
    if (list && list.length) {
      const errors = list.filter(item => !!numberCheck(item))
      if (errors && errors.length) {
        const _list = list.filter(item => !numberCheck(item))
        this.changeReportData(inputType, type, _list)
      }
    }
  }

  getDisabled = (referenceLineData) => {
    let disabled = true;
    for (let data of referenceLineData) {
      const { x } = data;
      if (x && x.length) {
        disabled = false
      }
    }
    return disabled
  }

  getTableData = (dataList) => {
    const { vendor, type } = this.props;
    const tableData = [];
    for (let data of dataList) {
      if (data.name === 'SBR') {
        tableData[1] = [data]
      } else if (data.name === 'TDR') {
        tableData[2] = [data]
      } else {
        if (!tableData[0]) {
          tableData[0] = [data]
        } else {
          tableData[0].push(data)
        }
      }
    }
    if (tableData[0] && tableData[0].length > 0) {
      const key = (vendor === PRE_LAYOUT) ? `${type}_pre_layout` : type;
      tableData[0] = SortFn(tableData[0], getReportGuideKeys(key), 'name');
    }
    return tableData;
  }

  tableList = (data) => {
    const { product } = this.props;
    const tableDataList = this.getTableData(data)
    return (tableDataList.length && tableDataList.map((item, index) => {
      const _tableColumns = getReportAdvanceColumns(this.state.tableColumns, index, product)
      const data = item.map((record, index) => ({ ...record, index }))
      return <EditableTable
        key={index}
        rowKey={(record) => record.index}
        columns={_tableColumns}
        dataSource={data}
        size="small"
        className={`report-advances-table`}
      />
    }))
  }

  render() {
    const { isXTable, type, reportConfigLoading = false, children } = this.props;
    const { tableData, error } = this.state;
    const switchDisabled = this.getDisabled(tableData);
    const items = [
      {
        key: "advanced",
        label: <div className="report-advances-header">
          <span>Advanced</span>
        </div>,
        children: <Fragment>
          {children}
          <div className="report-advances-title">Reference Lines</div>
          {
            !reportConfigLoading ? <Fragment>
              {this.tableList(tableData)}
              {![CASCADE, SIERRA].includes(type) ? <div className="report-advances-table-title">
                Generate data table
                <span className='report-advances-switch'>
                  <Switch
                    size="small"
                    className="aurora-switch-small"
                    checked={isXTable}
                    onChange={this.props.changeIsXTable}
                    disabled={switchDisabled}
                  />
                </span>
              </div> : null}
              {error ? <span className="aurora-error-msg-span">{error}</span> : null}
            </Fragment> : <div className='report-advances-spin'>
              <Spin spinning={reportConfigLoading} tip={reportConfigLoading ? 'Loading Report Config...' : ''} />
            </div>
          }
        </Fragment>
      }
    ]
    return <Collapse className="report-advances-collapse" items={items} />
  }
}

export default ReportAdvance;