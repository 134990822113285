import { VERIFICATION } from "@/constants/treeConstants";

function getCCCVerifications(verificationList, type) {
  return [{
    type: VERIFICATION,
    name: type,
    verificationType: type,
    verificationList: [...verificationList]
  }]
}

function getCCCSpecifyServiceVerification(verificationId, verificationList, type) {
  if (!verificationId) {
    return null;
  }

  let verification = null, specifyServiceType = VERIFICATION;
  verification = verificationList.find(item => item.id === verificationId);

  return verification ? { ...verification, specifyServiceType } : null;
}

export {
  getCCCVerifications,
  getCCCSpecifyServiceVerification
}