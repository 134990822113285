import React, { Component, createRef } from 'react'
import { Select, Input, Row, Col, Spin } from 'antd'
import {
  vendorList,
  tempList,
  formList,
  voltageList,
  capList,
  dcBiasVoltageList
} from '@/constants/systemLibrary';
import SystemLibraryStore from '@/services/helper/systemLibraryHelper';
import { setInitialState } from '@/services/helper/virtualListHelper';
import _ from 'lodash';

const Option = Select.Option;
const DefaultSettings = {
  itemHeight: 22,  //The length of a single row in the table
  amount: 9,      //The number of data displayed in the table
  tolerance: 44,   //The number of data on both sides of the data displayed
  minIndex: 0,
  maxIndex: 0,
  startIndex: 0   //The starting position of the data displayed
}
export default class SystemLibrary extends Component {
  constructor(props) {
    super(props)
    const setting = { ...DefaultSettings, maxIndex: 0 };
    let model = props.model;
    this.state = {
      initialState: setInitialState(setting),
      searchList: [],
      searchCount: 0,
      searchValue: {},
      maxSearchCount: 0,
      maxCount: 0,
      setting,
      isHidden: true,
      sysLoading: false,
      pageList: [],
      dataList: [],
      pageCount: 0,
      loading: props.loading,
      model,
      isSearch: false,
      selectedPartNumber: props.selectedPartNumber,
    }
    this.pageRef = createRef()
    this.inputCapacitanceRef = createRef()
  }

  componentDidMount() {
    this.defaultPageList()
  }

  componentDidUpdate(prevProps) {
    const { model } = this.state
    if (Object.keys(model).length === 0 && Object.keys(this.props.model).length !== 0) {
      this.setState({
        model: this.props.model
      })
    }

    if (!_.isEqual(this.props.model, prevProps.model)) {
      this.setState({
        model: this.props.model
      })
    }
  }

  resetSearchValue = () => {
    this.setState({
      searchValue: {}
    })
  }
  getSearchList = async (page) => {
    const { vendor } = this.props
    const { searchList, setting, searchValue = {} } = this.state;
    const res = await SystemLibraryStore.getSearchList(page, { ...searchValue, vendor });
    const { pageData = [], pageCount } = res;
    const newPageList = page === 0 ? [...pageData] : [...searchList, ...pageData];
    const newSetting = { ...setting, maxIndex: pageCount === page + 1 ? newPageList.length : newPageList.length + 1 }
    let isHidden = newPageList.length < 9
    this.setState({
      isHidden,
      searchList: newPageList,
      searchCount: page,
      setting: newSetting,
      maxSearchCount: pageCount,
      initialState: setInitialState(newSetting),
      isSearch: false,
      sysLoading: false
    }, () => this.getNewScrollData())
  }

  scrollPage = (target, e) => {
    const { scrollHeight, scrollTop } = target;
    const { pageCount, maxCount, searchValue, searchCount, maxSearchCount, loading } = this.state;
    const isLastPage = searchValue ? maxSearchCount - 1 === searchCount : pageCount === maxCount - 1
    e.stopPropagation();
    if (scrollHeight - scrollTop <= 201 && !isLastPage && !loading) {
      this.setState({
        loading: true
      }, () => {
        searchValue ? this.getSearchList(searchCount + 1) : this.getNewPage(pageCount + 1)
      })
    } else {
      if (this.scrollTimer) {
        clearTimeout(this.scrollTimer);
      }
      this.scrollTimer = setTimeout(() => this.getNewScrollData(), 200);
    }
  }

  getNewPage = async (page) => {
    const { model } = this.props;
    const res = await SystemLibraryStore.getPageList(page, model.folder ? model.folder : 'Murata');
    const { maxCount, pageList, setting } = this.state;
    const { pageData } = res;
    const newPageList = [...pageList, ...pageData];
    const newSetting = { ...setting, maxIndex: maxCount === page + 1 ? newPageList.length : newPageList.length + 1 }
    this.setState({
      pageList: newPageList,
      pageCount: page,
      loading: false,
      setting: newSetting,
      initialState: setInitialState(newSetting)
    }, () => this.getNewScrollData());
  }

  getNewScrollData = () => {
    const { initialState, setting } = this.state;
    const scrollTop = this.pageRef && this.pageRef.scrollTop ? this.pageRef.scrollTop : 0;
    const index = setting.minIndex + Math.floor((scrollTop - initialState.toleranceHeight) / setting.itemHeight);
    const data = this.getData(index, initialState.bufferedItems);
    const topPaddingHeight = Math.max((index - setting.minIndex) * setting.itemHeight, 0);
    const bottomPaddingHeight = Math.max(
      initialState.totalHeight - topPaddingHeight - data.length * setting.itemHeight,
      0
    ) - 50;
    const PaddingHeight = Object.assign({}, initialState, {
      topPaddingHeight,
      bottomPaddingHeight
    })
    this.setState({
      dataList: data,
      initialState: PaddingHeight,
    });
  }

  getData(offset, limit) {
    const data = [];
    const { setting, pageList, searchList, searchValue } = this.state;
    const start = Math.max(setting.minIndex, offset);
    const dataList = searchValue ? searchList : pageList;
    if (!dataList || !dataList.length) {
      return [];
    }
    const end = Math.min(offset + limit - 1, setting.maxIndex);
    if (start <= end) {
      for (let i = start; i < end; i++) {
        data.push(dataList[i] ? dataList[i] : { name: 'loading' })
      }
    }
    return data;
  }

  selectSearchValue = (value, key) => {
    if (key === 'vendor') {
      this.changeSystemFolder(value);
      this.setState({
        searchValue: value,
        pageCount: 0,
        pageList: [{ name: 'loading' }],
      }, () => {
        this.defaultPageList()
      })
    } else {
      this.changeSearchValue({ target: { value } }, key);
    }
  }

  changeSearchValue = (e, key) => {
    const { searchValue = {} } = this.state;
    const value = e.target.value;
    let _values = { ...searchValue };
    if (value) {
      _values[key] = value;
    } else {
      delete _values[key];
      if (!Object.keys(_values).length) {
        _values = null;
      }
    }
    this.sysLibraryValueSearch(_values);
  }

  changeSystemFolder = (value) => {
    this.setState({
      model: {
        ...this.state.model,
        folder: value
      }
    })
  }

  defaultPageList = async () => {
    const { model } = this.state;
    const folder = model.folder ? model.folder : 'Murata';
    const res = await SystemLibraryStore.getPageList(0, folder);
    const { pageData, pageCount } = res;
    const setting = { ...DefaultSettings, maxIndex: pageData.length + 1 }
    this.setState({
      isHidden: false,
      pageList: [...pageData],
      maxCount: pageCount,
      setting,
      vendor: folder,
      initialState: setInitialState(setting),
      isSearch: false,
    }, () => {
      const { searchValue } = this.state;
      if (searchValue) {
        this.sysLibraryValueSearch(searchValue)
      } else {
        this.getNewScrollData()
      }
    })

  }

  searchPartNumber = async (e) => {
    let _value = e.target.value
    this.setState({
      searchValue: {},
      sysLoading: true
    }, async () => {
      const { searchValue } = this.state
      const res = await SystemLibraryStore.getSearchList(0, { ...searchValue, name: _value })
      const { pageData, pageCount } = res;
      const setting = { ...DefaultSettings, maxIndex: pageData.length + 1 }
      this.setState({
        searchList: pageData,
        sysLoading: false,
        isSearch: false,
        maxCount: pageCount,
        setting,
        initialState: setInitialState(setting),
      }, () => { this.getNewScrollData() });
    })
  }

  sysLibraryValueSearch = (value) => {
    let setting = { ...DefaultSettings }, searchList = [];
    if (!value) {
      const { maxCount, pageList, pageCount } = this.state;
      setting = { ...DefaultSettings, maxIndex: maxCount === pageCount + 1 ? pageList.length : pageList.length + 1 };
    } else {

      setting = { ...DefaultSettings, maxIndex: 1 };
      searchList = [{ name: 'loading' }]
    }
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({
      searchValue: value,
      searchList,
      searchCount: 0,
      maxSearchCount: 0,
      setting,
      initialState: setInitialState(setting),
      isSearch: true,
      sysLoading: true,
    }, () => {
      this.getNewScrollData()
      if (value) {
        this.searchTimer = setTimeout(() => {
          this.getSearchList(0)
        }, 1000);
      } else {
        this.setState({
          isSearch: false,
          sysLoading: false,
          isHidden: false,
        })
      }
    })
  }

  getInitPartNumber = (name) => {
    this.setState({
      sysLoading: true
    }, async () => {
      const { searchValue } = this.state
      if (name && name !== undefined) {
        const item = await SystemLibraryStore.getSearchList(0, { ...searchValue, name });
        this.setState({
          selectedPartNumber: item.pageData[0],
          sysLoading: false
        })
      } else {
        this.setState({
          selectedPartNumber: {},
          sysLoading: false
        })
      }
    })

  }

  systemLibraryTypeChange = (key) => {
    this.props.systemLibraryTypeChange(key)
    this.setState({
      selectedPartNumber: {},
      model: {}
    })
    if (key === 'vendor') {
      this.defaultPageList()
      this.props.fileTypeChange('sparameter')
    }
  }

  fileTypeChange = (type) => {
    const model = this.state.model;
    this.props.fileTypeChange(type)
    this.setState({
      model: {
        ...model,
        libraryType: type
      },
    }, () => {
      if (model.id && model.name) {
        this.props.modelChange(this.state.model)
      }
    })
  }

  selectGeneric = (name) => {
    const { DecapGeneric } = this.props;
    const { model } = this.state
    this.setState({
      model: {
        ...model,
        name: name,
        libraryType: 'generic',
        subcktName: ''
      }
    }, () => {
      const current = DecapGeneric.find(item => item.name === name);
      this.selectSystemModel(name, current.id);
      this.props.getSubcktList(current.id)
    })
  }

  selectSystemModel = (name, id, folder) => {
    const model = { ...this.state.model, name, id, folder }
    this.setState({
      model: { ...model }
    }, () => {
      this.props.modelChange(this.state.model)
    })
  }

  selectCapacitor = (value) => {
    let model = this.state.model;
    this.setState({
      model: {
        ...model,
        subcktName: value,
        libraryType: "generic",
        type: 'System'
      }
    }, () => {
      this.props.modelChange(this.state.model)
    })
  }

  sysLibrarySelectSysModel = (name, id, item) => {
    const { model } = this.state
    this.props.changePartNumber(item)
    this.setState({
      selectedPartNumber: item,
      model: {
        ...model,
        type: 'System',
        subcktName: '',
        libraryType: this.props.fileType,
      }
    }, () => {
      const { vendor } = this.state;
      this.selectSystemModel(name, id, vendor);
    })
  }

  sysLibrarySelectSysModelSweep = (name, id, item) => {
    const { model, clickTimeout } = this.state
    if (clickTimeout) return;
    const timeout = setTimeout(() => {
      this.props.changePartNumber(item)
      this.setState({
        selectedPartNumber: item,
        model: {
          ...model,
          type: 'System',
          subcktName: '',
          libraryType: this.props.fileType,
        }
      }, () => {
        const { vendor } = this.state;
        this.selectSystemModel(name, id, vendor);
      })
      this.setState({
        clickTimeout: null
      })
    }, 250);
    this.setState({
      clickTimeout: timeout
    })
  }

  sysLibrarySelectSysModelDouble = (name, id, item) => {
    const { clickTimeout, model } = this.state
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      this.setState({
        clickTimeout: null
      })
    }
    this.props.changePartNumber(item)
    this.setState({
      selectedPartNumber: item,
      model: {
        ...model,
        type: 'System',
        subcktName: '',
        libraryType: this.props.fileType,
      }
    }, () => {
      const { vendor } = this.state;
      const model = { ...this.state.model, name, id, vendor }
      this.setState({
        model: { ...model }
      }, () => {
        this.props.modelChange(this.state.model)
        this.props.sweepNewModel(model)
      })
    })
  }

  searchRender = () => {
    const { maxHeight } = this.props
    let { systeamLibraryType, libraryType } = this.props
    const { searchValue = {} } = this.state
    return (
      <div className='decap-select-model-content' style={{ maxHeight: maxHeight }}>
        {libraryType === "system" && systeamLibraryType === 'vendor' && <div>
          <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">Form Factor</span>
              <Select
                size="small"
                popupClassName="select-model-select-dropdown"
                onChange={(value) => this.selectSearchValue(value, 'formFactor')}
                showSearch={true}
                allowClear={true}
                value={searchValue && searchValue.formFactor}
              >
                {formList.map(item => <Option key={item}>{item}</Option>)}
              </Select>
            </div>
            <div className="select-model-content-cal">
              <span className="type-name">Capacitance</span>
              <Select
                size="small"
                popupClassName="select-model-select-dropdown"
                onChange={(value) => this.selectSearchValue(value, 'capacitor')}
                showSearch={true}
                allowClear={true}
                value={searchValue && searchValue.capacitor}
              >
                {capList.map(item => <Option key={item}>{item}</Option>)}
              </Select>
            </div>
          </div>
          <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">Voltage Rating</span>
              <Select
                size="small"
                popupClassName="select-model-select-dropdown"
                onChange={(value) => this.selectSearchValue(value, 'voltageRating')}
                showSearch={true}
                allowClear={true}
                value={searchValue && searchValue.voltageRating}
              >
                {voltageList.map(item => <Option key={item}>{item}</Option>)}
              </Select>
            </div>
            <div className="select-model-content-cal">
              <span className="type-name">Temperature</span>
              <Select
                size="small"
                popupClassName="select-model-select-dropdown"
                onChange={(value) => this.selectSearchValue(value, 'temperature')}
                showSearch={true}
                allowClear={true}
                value={searchValue && searchValue.temperature}
              >
                {tempList.map(item => <Option key={item}>{item}</Option>)}
              </Select>
            </div>
          </div>
          <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">DC Bias Voltage</span>
              <Select
                size="small"
                popupClassName="select-model-select-dropdown"
                onChange={(value) => this.selectSearchValue(value, 'dcBiasVoltage')}
                showSearch={true}
                allowClear={true}
                value={searchValue && searchValue.dcBiasVoltage}
              >
                {dcBiasVoltageList.map(item => <Option key={item}>{item}</Option>)}
              </Select>
            </div>
            <div className="select-model-content-cal">
            </div>
          </div>
        </div>}
      </div>
    )
  }

  render() {
    const { systeamLibraryType, libraryType, maxHeight, vendor, DecapGeneric, genericSubckt, fileType, notIncludesCustom, sweep } = this.props
    const { isHidden, selectedPartNumber = {}, isSearch, sysLoading, dataList, model } = this.state
    return (
      <div>
        <div className='decap-select-model-content' style={{ maxHeight: maxHeight }}>
          <div className="select-model-content-row">
            <div className="select-model-content-cal">
              <span className="type-name">Type</span>
              <Select size="small" value={systeamLibraryType} onChange={this.systemLibraryTypeChange} popupClassName="select-model-select-dropdown">
                <Option value="vendor">Vendor</Option>
                <Option value="generic">Generic</Option>
                {notIncludesCustom ? null : <Option value="Custom">Custom</Option>}
              </Select>
            </div>
          </div>
          <div className="select-model-content-row">
            {
              systeamLibraryType === 'vendor' && <div className="select-model-content-cal">
                <span className="type-name">Vendor</span>
                <Select size="small" value={vendor} popupClassName="select-model-select-dropdown">
                  {vendorList.map(item => <Option key={item}>{item}</Option>)}
                </Select>
              </div>
            }
          </div>
          <div className="select-model-content-row">
            {systeamLibraryType === 'vendor' ? <div className="select-model-content-cal">
              <span className="type-name">Model Type</span>
              <Select size="small" value={fileType} onChange={this.fileTypeChange} popupClassName="select-model-select-dropdown">
                <Option value="netlist">Spice Netlist</Option>
                <Option value="sparameter">S-Parameter File</Option>
              </Select>
            </div> :
              <div className="select-model-content-cal">
                <span className="type-name">File</span>
                <Select size="small" value={model.libraryType === "generic" ? model.name : ''} onChange={this.selectGeneric} popupClassName="select-model-select-dropdown">
                  {DecapGeneric.map(item => <Option value={item.name} key={item.key}>{item.name}</Option>)}
                </Select>
              </div>}
          </div>
          <div className="select-model-content-row">
            {systeamLibraryType === "vendor" ? <div className="select-model-content-cal">
              <span className="type-name">Part Number</span>
              <Input size="small" placeholder="" defaultValue={model.name} key={model.name} onChange={value => { this.changeSearchValue(value, 'name') }} />
            </div> :
              <div className="select-model-content-cal">
                <span className="type-name">Capacitance</span>
                <Select size="small" value={model.libraryType === "generic" ? model.subcktName : ''} ref={this.inputCapacitanceRef} onChange={(value) => this.selectCapacitor(value)} popupClassName="select-model-select-dropdown">
                  {genericSubckt.map((item) => { return <Option value={item.name} key={item.name}>{item.name}</Option> })}
                </Select>
              </div>}
          </div>
        </div>
        {libraryType === "system" && systeamLibraryType === 'vendor' && <div>
          <div className="decap-select-model-content" style={{ maxHeight: maxHeight, cursor: 'pointer' }}>
            <strong>Search</strong>
          </div>
          {this.searchRender()}
        </div>}
        {libraryType === "system" && systeamLibraryType === 'vendor' && <div className="decap-select-model-content">
          <div className="select-model-content-row">
            {!sysLoading ?
              <div className="select-model-name-box" >
                <div className={isHidden ? "select-model-li-title hidden" : "select-model-li-title"}>
                  <Row style={{ width: "100%" }}>
                    <Col span={6}>
                      <span className="select-model-li-strong">Part Number</span>
                    </Col>
                    <Col span={3}><span>Form Factor</span></Col>
                    <Col span={3}><span>C</span></Col>
                    <Col span={4}><span>Voltage Rating</span></Col>
                    <Col span={3}><span>Bias Voltage</span></Col>
                    <Col span={5}><span>Temperature</span></Col>
                  </Row>
                </div>
                {
                  !isSearch && selectedPartNumber.name ?
                    <div key={selectedPartNumber.name} className={isHidden ? 'select-model-li select-model-li-selected hidden' : 'select-model-li select-model-li-selected'} >
                      <Row style={{ width: "100%" }}>
                        <Col span={6}>
                          <span className="select-model-li-strong">{selectedPartNumber.name}</span>
                        </Col>
                        <Col span={3}><span>{selectedPartNumber.formFactor}</span></Col>
                        <Col span={3}><span>{selectedPartNumber.capacitorValue}</span></Col>
                        <Col span={4}><span>{selectedPartNumber.voltageRating}</span></Col>
                        <Col span={3}><span>{selectedPartNumber.dcBiasVoltage}</span></Col>
                        <Col span={5}><span>{selectedPartNumber.temperature}</span></Col>
                      </Row>
                    </div> : null
                }
                <div className="select-model-ul" onScrollCapture={(e) => this.scrollPage(this.pageRef, e)} ref={c => { this.pageRef = c }}>
                  <div style={{ height: this.state.initialState.topPaddingHeight }} />
                  {(dataList).filter(item => { return item.name !== model.name }).map(((item) => {
                    const { id, name, formFactor, capacitorValue, dcBiasVoltage, voltageRating, temperature } = item;
                    if (name !== "loading") {
                      return <div key={name} className="select-model-li"
                        onDoubleClick={() => sweep ? this.sysLibrarySelectSysModelDouble(name, id, item) : null}
                        onClick={() => sweep ? this.sysLibrarySelectSysModelSweep(name, id, item) : this.sysLibrarySelectSysModel(name, id, item)}>
                        <Row style={{ width: "100%" }}>
                          <Col span={6}>
                            <span className="select-model-li-strong">{name}</span>
                          </Col>
                          <Col span={3}><span>{formFactor}</span></Col>
                          <Col span={3}><span>{capacitorValue}</span></Col>
                          <Col span={4}><span>{voltageRating}</span></Col>
                          <Col span={3}><span>{dcBiasVoltage}</span></Col>
                          <Col span={5}><span>{temperature}</span></Col>
                        </Row>
                      </div>
                    } else return null
                  }))}
                  <div style={{ height: this.state.initialState.bottomPaddingHeight < 0 ? 0 : this.state.initialState.bottomPaddingHeight }} />
                </div>
              </div> :
              <div className='select-model-name-box'>
                <div className="select-model-li-loading">
                  <Spin></Spin>
                </div>
              </div>
            }
          </div>
        </div >
        }
      </div >
    )
  }
}
