import { BasicComponent, BasicCompModel } from '../helper/IntegratedInterface';

export class IntegratedInterface {
  constructor(name = '') {
    this.name = name; // interface name
    this.signals = []; // [Signal]
    this.components = []; // [RLCCONComponent,ICComponent]
    this.powerNets = []; // [SourceNets]
    this.explorable = []; // [BasicExplorable]
    this.channel = {};//{channel}
    this.powerComponents = []; // [{ id, name, pins, type, value }]
  }
}

export function Extraction() {
  //common
  this.clipping = true;
  this.logSweepMin = "1000";
  this.logSweepMax = "1e7";
  this.logSweepSAPD = "10";
  this.linearSweepMax = "5e9";
  this.linearSweepFreqStep = "5e6";
  this.maxFreq = "5e9";
  // 3.0.2 update
  this.clipSize = "0.5"; //float >= 0.1, unit: ratio
  this.includeDC = false; //true/false
  this.exactDC = false; //true/false
  this.portImpedance = "50";

  //SIwave/HFSS
  this.discreteSweep = false; //true/false, not display
  this.meshFrequency = "5"; //float >1
  this.maxPasses = "10"; // only show if modeling with HFSS
  this.maxDelta = "0.02"; //float [0.001,1] only show if modeling with HFSS
  //new
  this.enablePassivePort = false;
  this.multiZone = { stackupMode: 'Laminate', enableBends: true }; //only use if modeling with HFSS
  //HPC opened (SIwave/HFSS Custom HPC)
  /* hpc_options: {
    "num_tasks": "1",
    "num_cores": "4",
    "num_gpus": "0",
    "ram_percent": "90"
  } */
  //power si
  this.corePercent = "50";//PowerSI HPC
  this.numCores = "4";//PowerSI HPC
  this.pwsiMaxPadSize = "";
  this.pwsiMinShapeSize = "";
  this.pwsiDogleg = "1.5";
  this.pwsiThermal = "1.5";
  this.pwsiHole = "1.5";
  this.pwsiViaHole = "1.5";
  this.pwsiSlenderHoleSize = "1";
  this.pwsiAutoSpecialVoid = false;
  this.bottomFloating = false;
  this.bottomDistance = "0.01";
  this.topFloating = false;
  this.topDistance = "0.01";
  this.pwsiSlenderHoleAreaThreshold = "0";
  this.pwsiSlenderHoleSizeThreshold = "0";
};

export function HPCOptions() {
  this.num_tasks = "1";
  this.num_cores = "4";
  this.num_gpus = "0";
  this.ram_percent = "90";
}

/*  ==== EyeDiagramConfig ==== */
export class EyeDiagramConfig {
  constructor({ clocks = [], signals = [], ui = "0", t_shift = "0", v_ref = "0", trigger = "", time_scale = "", x_label = "", clockFrequency = "" }) {
    this.eye_config = new eyeConfig({
      clocks,
      signals,
      ui,
      t_shift,
      v_ref,
      trigger,
      clockFrequency
    });
    this.plot_config = new plotConfig({ time_scale, x_label });
  }
}

export class eyeConfig {
  constructor({ clocks = [], signals = [], ui = "0", t_shift = "0", v_ref = "0", trigger = "", clockFrequency = "", clockPins = [], signalPins = [] }) {
    this.clocks = clocks; //{list{str}}   a single or differential clock signal, default = None
    this.signals = signals; //{list{str}}   names of the signals to be included in the eye diagram, default = None, all signals included
    this.components = []; //list{str}
    this.signalPins = signalPins || []; //list{object:{ component, pin, signal, pcbId }}
    this.clockPins = clockPins || []; //list{object:{ component, pin, signal, pcbId }}
    this.ui = ui; // {float}     (required) unit interval of the signal, in second  double == (1/2 * (1/ clock)), single == 1/ clock
    this.t_shift = t_shift; // {float}   shift between the signals to the triggering signal in terms of ui, default = 0, the script internally adds 0.5 UI
    this.v_ref = v_ref; //  {float}   (required) reference voltage for eye width and jitter calculation
    this.trigger = trigger; // {string}    If 'No Clock' is selected in Clock Type, change the Trigger Type options to 'Single Data Rate' and 'Double Data Rate'. The default value is determined by the stimulus. If there is a DDR in the stimulus, then set the default to 'Double Data Rate'. Otherwise use 'Single Data Rate',For other Clock Type settings, provide the Trigger Type options 'Rising Edge', 'Falling Edge', and 'Double Edge'
    this.delay = "0"; // {float}       time period to skip from the beginning, default = 0
    this.frame_size = "1.75"; //{float}     size of the frame in terms of UI, default = 1.75 unit ui, range [1,2]
    this.clockFrequency = clockFrequency; //{float} clock frequency (frontend) Use the interface frequency as default, allow the user to modify. Change the datarate and ui calculation based on this input​
  }
}

export class plotConfig {
  constructor({ time_scale = "", x_label = "" }) {
    this.type = "colormap"; //colormap / line
    this.plot_clk = false; // {boolean}   whether clock signals should be overlayed, default = False
    this.diff_clk = true; //{boolean}   if differential clock, whether the differential signal should be plotted, default = True  (DQSP,DQSPN  DQSP-DQSN)
    this.time_scale = time_scale; // {float}   time scaling factor (ps ns,us) ps ==> 1e-12, ns ==> 1e-9, us ==> 1e-6
    this.v_min = ""; //{float}     minimum voltage in volt, no default
    this.v_max = ""; //{float}     maximum voltage in volt, no default
    this.x_label = x_label; //{string} label string for the x axis (ps ns,us)
    this.paper_x = 6.4; //{ float } figure x size on paper in inches, default = 6.4      ​
    this.paper_y = 4.8; //{ float } figure y size on paper in inches, default = 4.8
    this.dpi = 200; //{ int } dots per inch(dpi) on paper, default = 100​, The default(100) label is very fuzzy


  }
}

// ================= Component structure =================
// Driver,receiver(IC) component
export class ICComponent extends BasicComponent {
  constructor(props) {
    super(props);
    this.deviceVcc = "";
    this.model = {
      files: []
    }
    this.pkg = {
      type: 'None'
    }
  }
}

// =================== Model structure ===================
export class Model {
  constructor({ libType = '', enableVoltage = '', modelName = '', fileName = '', libraryId = '', modelType = '', version = null }) {
    this.libType = libType; // IBIS / SPICE
    this.libraryId = libraryId;
    this.fileName = fileName;
    this.modelType = modelType; //IO / ...
    this.modelName = modelName;
    this.enableVoltage = enableVoltage;
    this.version = version;
  }
}

// ============== Component Model structure ==============
// Receiver model
export class ICCompPinModel extends BasicCompModel {
  constructor(props) {
    super(props);
    this.corner = "typ"; // typ|fast|slow
    this.usage = ""; // Receiver|Driver|Ignore
    this.model = {}; // model {libType, ...}
    this.powerOff = ""; // POWER ON/POWER OFF - false|true
    this.pinModels = [];
  }
}

export class RLCModelInfo {
  constructor(modelType = '') {
    this.type = modelType; // value/Touchstone/SPICE
    this.fileName = '';
    this.libraryId = '';
    this.subckt = '';
    this.pairs = []; //[{ pin:"", node:"" }, ...]
    if (modelType === 'Touchstone') {
      this.referenceNet = "";
    }
  }
}
export class RepeaterComponent {
  constructor({ name = '', type = '', pins = [] }) {
    this.name = name;
    this.type = type;
    this.model = {
      files: [],
      pairs: []
    }
    // this.pinModels = pinModels;
    this.pins = pins;
  }
}

export class RepeaterPin {
  constructor({ signal = '', pin = '', net = '', corner = '' }) {
    this.signal = signal || '';
    this.pin = pin || '';
    this.net = net || '';
    this.corner = corner || 'typ';
    this.usage = 'Repeater';
  }
}

export class SierraInterface extends IntegratedInterface {
  constructor(props) {
    super(props);
    this.extraction = new Extraction();
    this.channel = {
      type: 'SIWave',
      fileName: '',
      modelName: '',
      libraryId: '',
    }
  }
};

export class PackageModelInfo {
  constructor(type = '') {
    this.type = type;
    this.files = []; //{ type, fileName, libraryId, subckt }
    this.pairs = []; // [ { pin, node, subckt, libraryId } ,{ pin, node, subckt, libraryId }, ... ]
  }
}

export class PackageModelFileInfo {
  constructor() {
    this.type = "";
    this.fileName = "";
    this.libraryId = "";
    this.subckt = "";
  }
}

// ============== Connector structure ==============

export function Connector({ models = [], cableModels = [], pins = [], name, part = "", connect = {} }) {
  this.models = models;
  this.cableModels = cableModels;
  this.pins = pins;
  this.name = name;
  this.part = part;
  this.connect = connect; //{ pcbId, componentName }
  this.type = 'Connector'
}

export function ConnectorPin({ pin, net, signal, port = "", modelId = "", external_map = new ExternalPort({}) }) {
  this.pin = pin;
  this.net = net;
  this.signal = signal;
  this.port = port;
  this.modelId = modelId;
  this.external_map = external_map;
}

export function ExternalPort({ modelId = "", port = "", cableModelId = "", cablePort = "" }) {
  this.modelId = modelId;
  this.port = port;
  this.cableModelId = cableModelId;
  this.cablePort = cablePort;
}