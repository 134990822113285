import CeGeometry from './CeGeometry';
import CeCircle from './CeCircle';
import CeVertex from './CeVertex';
import CeVertices from './CeVertices';
import CeArc from './CeArc';
import CeBox2D from './CeBox2D';
import CeVertexArc from './CeVertexArc';
import GCPolygon from './GCPolygon';
import StringList from '../utility/StringList';
import StringHelper from '../utility/StringHelper';
import StrSplitRule from '../utility/StrSplitRule';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

const CePolygon = function (type) {
  var geomType = "Polygon";
  if (type != null && type != undefined)
    geomType = type;
  CeGeometry.call(this, geomType);

  this.mCCW = true;
  this.mIsSolid = true;
  this.mVertices = [];
}

// subclass extends superclass
CePolygon.prototype = Object.create(CeGeometry.prototype);
CePolygon.prototype.constructor = CePolygon;

CePolygon.prototype.CopyFrom = function (fromObj) {
  CeGeometry.prototype.CopyFrom.call(this, fromObj);
  this.mCCW = fromObj.mCCW;
  this.mIsSolid = fromObj.mIsSolid;
  this.mVertices = [];
  for (var i = 0; i < fromObj.mVertices.length; i++) {
    this.mVertices.push(fromObj.mVertices[i].Clone());
  }
}

CePolygon.prototype.Clone = function () {
  var clnObj = new CePolygon();
  clnObj.CopyFrom(this);
  return clnObj;
}

CePolygon.prototype.MoveToCenterLocalCS = function () {
  var center = this.GetCenter();
  for (var i = 0; i < this.mVertices.length; i++) {
    this.mVertices[i].MoveToLocalCS(center);
  }
  return center;
}

CePolygon.prototype.Scaling = function (scaleFactor) {
  for (var i = 0; i < this.mVertices.length; i++) {
    this.mVertices[i].Scaling(scaleFactor);
  }
}

CePolygon.prototype.isIsland = function () {
  return this.mIsSolid;
}

CePolygon.prototype.GetNumberVertex = function () {
  return this.mVertices.length;
}

CePolygon.prototype.GetVertices = function () {
  return this.mVertices;
}

CePolygon.prototype.GetDiscretePoints = function (ccw) {
  var discretePnts = new CeVertices();
  let mX = 0, mY = 0;
  if (this.mVertices.length > 0) {
    mX = this.mVertices[0].mX;
    mY = this.mVertices[0].mY;
  }
  discretePnts.add(mX, mY);
  for (var i = 1; i < this.mVertices.length; i++) {
    if (this.mVertices[i].IsArcPoint()) {
      var arcPnt = this.mVertices[i];
      if (arcPnt.OnSameLocation(this.mVertices[i - 1])) {
        continue;
      }

      var lineSegs = CeArc.ArcToLineSegments(this.mVertices[i - 1], arcPnt);
      var j;
      for (j = 1; j < lineSegs.mNumPnts; j++) {
        discretePnts.add(lineSegs.mXs[j], lineSegs.mYs[j]);
      }
    } else {
      discretePnts.add(this.mVertices[i].mX, this.mVertices[i].mY);
    }
  }
  if (discretePnts.size() > 2) {
    if (discretePnts.get(0).OnSameLocation(discretePnts.get(discretePnts.size() - 1))) {
      discretePnts.remove(discretePnts.size() - 1);
    }
  }
  if (ccw != this.mCCW) {
    var discretePnts2 = new CeVertices();
    var i;
    for (i = discretePnts.size() - 1; i >= 0; i--) {
      discretePnts2.addPnt(discretePnts.get(i));
    }
    return discretePnts2;
  }
  return discretePnts;
}

CePolygon.prototype.GetVertexNumber = function () {
  return this.mVertices.length;
}

/** Add a vertex to the polygon
 *  @param x - x coordinate of the point, if the second parameter is not provided,
 *             then x is assumed to be a CeVertex object containing the point coordinates
 *  @param y - y coordinate of the point
 */
CePolygon.prototype.AddVertex = function (x, y) {
  if (y == undefined) {
    this.mVertices.push(x);
  } else {
    var pnt = new CeVertex();
    pnt.SetData(x, y);
    this.mVertices.push(pnt);
  }
}

CePolygon.prototype.AddArc = function (x, y, cx, cy, ccw) {
  var arcV = new CeVertexArc();
  arcV.SetData(x, y, cx, cy, ccw);
  this.mVertices.push(arcV);
}

CePolygon.prototype.SetBeSolid = function (solid) {
  this.mIsSolid = solid;
}

CePolygon.prototype.SetCCWDirection = function (ccw) {
  this.mCCW = ccw;
}

CePolygon.prototype.BoundingBox = function () {
  let bbox = new CeBox2D();
  let mX = 0, mY = 0;
  if (this.mVertices.length > 0) {
    mX = this.mVertices[0].mX;
    mY = this.mVertices[0].mY;
  }
  bbox.mMinPnt.mX = mX;
  bbox.mMinPnt.mY = mY;
  bbox.mMaxPnt.mX = mX;
  bbox.mMaxPnt.mY = mY;

  for (var i = 0; i < this.mVertices.length; i++) {
    if (this.mVertices[i].mGeomType === "Parc" && this.mVertices[i].mCenter) {
      const obj = this.getParcMData(this.mVertices[i])
      bbox.ExpandParc(obj);
    } else {
      bbox.Expand(this.mVertices[i]);
    }
  }
  return bbox;
}

CePolygon.prototype.getParcMData = function (data) {
  const centerPoint = data.mCenter;
  let radius = Math.abs(centerPoint.mX - data.mX);
  if (!radius) {
    radius = centerPoint.mY - data.mY;
  }
  let minX = centerPoint.mX - radius;
  let minY = centerPoint.mY - radius;
  let maxX = centerPoint.mX + radius;
  let maxY = centerPoint.mY + radius;
  return { minX, minY, maxX, maxY }
}

CePolygon.prototype.ConvertToGCPolygon = function () {
  if (this.mVertices.length == 2 && this.mVertices[1].IsArcPoint()) {
    var endPnt = this.mVertices[1];
    if (this.mVertices[0].OnSameLocation(endPnt) == true) {
      var radius = this.mVertices[0].ToPointDistance(endPnt.mCenter);
      var circle = new CeCircle();
      circle.SetData(endPnt.mCenter.mX, endPnt.mCenter.mY, 2 * radius);
      return circle.ConvertToGCPolygon();
    }
  }

  var pntList = this.GetDiscretePoints(true);
  var numPnt = pntList.size();
  var xArray = [];
  var yArray = [];

  for (var i = 0; i < numPnt; i++) {
    var pnt = pntList.get(i);
    if (pnt != null && pnt instanceof CeVertex) {
      xArray.push(pnt.getX());
      yArray.push(pnt.getY());
    }
  }

  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(numPnt, xArray, yArray);
  return gcPolygon;
}

CePolygon.prototype.WriteIntoIODataNode = function () {
  var myBlock = new CeIODataBlock(this.GetGeomType());
  myBlock.AddDataItem("Solid", StringHelper.BoolToString(this.mIsSolid));
  myBlock.AddDataItem("CCW", StringHelper.BoolToString(this.mCCW));

  for (var i = 0; i < this.mVertices.length; i++) {
    myBlock.AddBlockNode(this.mVertices[i].WriteIntoIODataNode());
  }
  return myBlock;
}

CePolygon.prototype.ReadFromIODataNode = function (myBlock) {
  if (!(myBlock instanceof CeIODataBlock))
    return false;

  this.mIsSolid = myBlock.GetItemBool("Solid", this.mIsSolid);
  this.mCCW = myBlock.GetItemBool("CCW", this.mCCW);
  var allItems = myBlock.GetAllBlockItems();
  var i;
  for (i = 2; i < allItems.length; i++) {
    var pntType = allItems[i].GetName();
    if (pntType == "Pnt") {
      var pnt = new CeVertex();
      pnt.ReadFromIODataNode(allItems[i]);
      this.mVertices.push(pnt);
    } else if (pntType == "Parc") {
      var pnt = new CeVertexArc();
      pnt.ReadFromIODataNode(allItems[i]);
      this.mVertices.push(pnt);
    }
  }
  return true;
}

CePolygon.prototype.GetCenter = function () {
  var cx = 0.0;
  var cy = 0.0;
  var numP = this.mVertices.length;
  for (var i = 0; i < this.mVertices.length; i++) {
    cx += this.mVertices[i].mX;
    cy += this.mVertices[i].mY;
  }
  cx = cx / numP;
  cy = cy / numP;
  var center = new CeVertex();
  center.SetData(cx, cy);
  return center;
}

CePolygon.prototype.GetDataString = function () {
  var dataStrs = new StringList();
  dataStrs.addInt(this.mVertices.length);
  var pnt;
  for (var i = 0; i < this.mVertices.length; i++) {
    var pntData = this.mVertices[i].GetDataString();
    dataStrs.push("(" + pntData.Join(",") + ")");
  }
  dataStrs.push(StringHelper.BoolToString(this.mCCW));
  dataStrs.push(StringHelper.BoolToString(this.mIsSolid));
  return dataStrs;
}

CePolygon.prototype.UpdateByString = function (dataVals) {
  // n, (x0,y0), (x1, y1, xc, yc, CCW), ... (xn-1,yn-1)
  if (!(dataVals instanceof StringList))
    return false;

  var npnts = parseInt(dataVals.get(0), 10);

  var splitRule = new StrSplitRule(" ,");
  var i;
  for (i = 1; i < npnts + 1; i++) {
    var strItems = StringHelper.SplitString(dataVals.get(i), splitRule);
    if (strItems.size() == 2) {
      this.AddVertex(parseFloat(strItems.get(0)),
        parseFloat(strItems.get(1)));
    } else if (strItems.size() == 5) {
      var ccw = StringHelper.StringToBool(strItems.get(4));
      this.AddArc(parseFloat(strItems.get(0)),
        parseFloat(strItems.get(1)),
        parseFloat(strItems.get(2)),
        parseFloat(strItems.get(3)),
        ccw);
    }
  }
  if (dataVals.size() > npnts + 1)
    this.mCCW = StringHelper.StringToBool(dataVals.get(npnts + 1));
  if (dataVals.size() > npnts + 2)
    this.mIsSolid = StringHelper.StringToBool(dataVals.get(npnts + 2));

  return true;
}

CePolygon.prototype.GetSvgPath = function (csConvertor) {
  var gcpoly = this.ConvertToGCPolygon();
  return gcpoly.GetSvgPath(csConvertor);
}

CePolygon.prototype.Transform = function (celocation) {
  for (var i = 0; i < this.mVertices.length; i++) {
    this.mVertices[i].Transform(celocation.mRotAngle, celocation.mCW, celocation.mPosition);
  }
}

CePolygon.prototype.IsCircle = function () {
  if (this.mVertices.length == 2 && this.mVertices[1].IsArcPoint()) {
    var endPnt = this.mVertices[1];
    if (this.mVertices[0].OnSameLocation(endPnt) == true) {
      return true;
    }
  }
  return false;
}

CePolygon.prototype.ConvertToCircle = function () {
  if (this.IsCircle() == false) {
    console.error("Cannot convert polygon to circle.");
    return null;
  };
  let endPnt = null, radius = 0;
  if (this.mVertices.length > 1) {
    endPnt = this.mVertices[1];
    radius = this.mVertices[0].ToPointDistance(endPnt.mCenter);
  }
  let circle = new CeCircle();
  if (endPnt) {
    circle.SetData(endPnt.mCenter.mX, endPnt.mCenter.mY, 2 * radius);
  } else {
    circle.SetData(0, 0, 2 * radius);
  }
  return circle;
}

export default CePolygon;