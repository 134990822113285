import { call, put, fork, take, cancel, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import React from 'react';
import { message } from 'antd';
import {
  GET_VERIFICATION_CONTENT,
  AUTO_SAVE_CONFIG_TO_SERVER,
  SAVE_CONFIG_TO_SERVER,
  ADD_SIGNAL,
  ADD_PCBS_IN_SIGNAL,
  EDIT_SIGNAL,
  UPDATE_INTERFACES,
  ASSIGN_MODEL,
  SAVE_MODEL_PINS,
  ADD_POWER_GROUND_NETS,
  ADD_PCBS_IN_POWER_NETS,
  PG_NETS_SELECTION,
  NET_VOLTAGE_UPDATE,
  CREATE_CONFIG,
  EDIT_CHANNEL_TYPE,
  UPDATE_CHANNEL_MODEL,
  UPDATE_OPTIONS,
  SAVE_CONNECTOR,
  EDIT_COMPONENT_TYPE,
  EDIT_SIGNAL_NAME,
  DELETE_SIGNAL,
  DELETE_PG_NETS,
  SAVE_REPEATER_MODEL,
  SAVE_POWER_OFF,
  SAVE_CHIP_MODEL,
  SAVE_EXTRACTION,
  SAVE_RE_EXTRACTION,
  SAVE_CURRENT_VERIFICATION,
  CURRENT_VERIFICATION_DEBUG_VERIFY,
  UPDATE_INTERFACE_LIBRARY,
  UPDATE_OPTIONS_BY_CLOSE,
  SAVE_RLC_MODEL,
  SAVE_COMP_PACKAGE_MODEL,
  UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS,
  SAVE_PIN_STIMULUS_MODEL,
  UPDATE_COMPONENTS,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  UPDATE_PROBE_PINS,
  UPDATE_REFERENCE_NETS,
  UPDATE_INTERFACE_REFERENCE_NETS,
  UPDATE_INTERFACE_AFTER_LIBRARY_UPDATE,
  UPDATE_PORT_SETUPS_TO_SERVER,
  SAVE_CONNECTOR_GROUP,
  CREATE_INTERFACE_IDENTIFICATION,
  UPDATE_VERIFICATION_SETTING,
  INTERFACE_PCB_REPLACE,
  UPDATE_COMP_SETTING_SETUP,
  SAVE_VIRTUAL_COMPONENT,
  UPDATE_SETUP_AFTER_CLOSE_SETTINGS,
  UPDATE_SETUP_AFTER_CLOSE_PART_LIBRARY,
  UPDATE_PART_REPEATER,
  UPDATE_COMPONENT_PIN_USAGE,
  SAVE_VERIFICATION_CONTENT_TO_SERVER,
  RECREATE_DUPLICATION_VERIFICATIONS,
  START_IDENTIFICATION_CREATING,
  UPDATE_PRE_LAYOUT_REPLACE
} from './actionTypes';
import {
  OPEN_PAGE
} from '../project/actionTypes';
import {
  updateSierraContent,
  updateSierraInterface,
  getVerificationContent,
  updateSierraInfo,
  updateInterfaces,
  updateNetsComps,
  updateCurrentConfig,
  updateInterfaceLibraryMsg,
  updateTouchstoneStatusList,
  updateTouchstoneMacroModelingStatus,
  updateReferenceNets,
  updateGetRefNetsLoading,
  updateInterfaceRefNets,
  findInterfaceReferenceNets,
  addPCBInSignals,
  updateApplyUsage,
  updatePCBLoading,
  changeConnectorStatus,
  changeIdentificationVisible,
  updateIdentificationMassage,
  updatePcbReplaceInfo,
  updateGetCompPrefixStatus,
  needUpdateCompSetting,
  updateCreatePanelLoading,
  updateSetupAfterCloseSettings,
  updateCompSettingsStatus,
  closePartRepeaterPanel,
  updatePartRepeaterLog,
  updateBufferModelStatus,
  updateMeasureConfigStatus,
  updateDuplicationVerifications,
  updateInterfaceDesignMessage,
  startIdentificationCreating,
  updateCreateInterfacesStatus,
  updatePreInfoReplaceInfo
} from './action';
import { VERIFY_SUCCESS, VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import {
  saveComponentsNetsInProject,
  updateViewStatus,
  autoGetVerificationList,
  updatePCBComponentsNets,
  updateCurrentVerificationStatus,
  updateGeneratePortsErrors,
  updateProjectMenu,
  changeTreeSelected,
  changeViewList
} from '../project/action';
import {
  saveComponentsNets
} from '../project/saga'
import SierraVerify from '@/services/Sierra/PinToPinVerifyDatabase';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import {
  RLCCONComponent,
  BasicCompModel,
  BasicComponent,
  Signal,
  SourceNets
} from '@/services/helper/IntegratedInterface';
import { numConversion, unitConversion, numberCheck, versionCompareSize } from '@/services/helper/dataProcess';
import { scaleKeys } from '@/services/helper/constants';
import {
  getVerificationContentPromise,
  createInterfacePromise,
  updateInterfacePromise,
  getComponentsWithNetList,
  deleteInterfacePromise,
  getPowerComponents,
  createPinToPinConfig,
  changeConfigFormat,
  updatePinToPinConfig,
  RepeaterComponent,
  RepeaterPin,
  deleteCompsConnectWithNets,
  SierraInterface,
  reExtraction,
  Extraction,
  updateLibraryInInterface,
  getPairs,
  ICCompPinModel,
  ICComponent,
  Model,
  updateInterfaceContent,
  updateComponentsByPowerNets,
  updateComponentPinSignal,
  signalNetsFormat,
  updateConnector,
  Connector,
  ConnectorPin,
  connectorHandle,
  updateReplaceConnections,
  saveConnectorComp,
  componentTypeChange,
  updateMultipleInterfacePromise,
  getCompTypeByConnections,
  connectorInterfacePCB,
  getCompTypeChange,
  getCompUpdateInfo,
  applySignalModels,
  getCompTypeByRepeaterPinMap,
  setDefaultRepeaterModel,
  updateAllComponentPinsUsage,
  multiPinSpiceComponent,
  setDefaultSpiceBufferModel,
  setDefaultUsageByDriver,
  getConnectorList,
  judgeModelUpdate,
  updateICPinModelsByBuffer,
  updateICPinModels,
  getSierraLayoutComponents,
  getCreateInterfaceStatusInfo
} from '@/services/Sierra';
import {
  findReferenceNetsBySignal,
  getReferenceNetsBySignal,
} from '@/services/api/refNets';
import DesignInfo from '@/services/Sierra/pcbInfo';
import LayoutData from '@/services/data/LayoutData';
import {
  existResult,
  getInterfaceMonitor,
  startSierraVerification,
  debugVerify,
  updateStimulationMessage,
} from '../simulation/action';
import { getSierraInterfaceErrorCheck } from '../../errorCheck/interfaceCheck';
import { SIERRA_SETUP_VERSION } from '@/version';
import { openTabFooter, changeTabMenu } from '../../../tabMonitor/action';
import { getResultPage, updateRecalc } from '../result/action';
import { toNonExponential } from '@/services/helper/numberHelper';
import { simulationConfigList } from '@/services/helper/simulationValueCheck';
import { getLibraryMacroModelingStatus, getIbisModelList } from '@/services/Sierra/library'
import * as taskStatus from '@/constants/workflowStatus';
import NP from 'number-precision';
import {
  MultiPinSPICE,
  ICTypes,
  RLCTypes,
  CREATE_RUNNING,
  CREATE_SUCCESS,
  CREATE_FAILED
} from '../../constants';
import { getPins } from '@/services/Sierra/library/IbisModelHelper';
import { closeCountTime, getCountTime } from '@/services/helper/hasOperate';
import { ResultData } from '@/services/Sierra';
import projectDesigns from '@/services/helper/projectDesigns';
import { getDefaultName } from '@/services/helper/setDefaultName';
import {
  FIND_REF_NET_RUNNING,
  FIND_REF_NET_SUCCESS,
  FIND_REF_NET_FAILED,
} from '../../../../constants/findRefNetsConstants';
import {
  updateExtractionPortsSetup,
  judgeUpdateGapPortSetup,
  updateExtractionGapPortsSetup,
  judgeUpdatePowerSISetup,
  getDefaultPortsGenerateSetupList,
  getExtractionPortsSetup
} from '@/services/Sierra/helper/extractionPortsHelper';
import { RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, VERIFICATION, MULTI_INTERFACE_SETUP, PCB_PRE_LAYOUT, VERIFICATION_GROUP } from '@/constants/treeConstants';
import { strDelimited } from '@/services/helper/split';
import { updateSetupComponentsByPortType, judgeUpdateGapPortGapSizeSetup, updateHFSSExtractionCompsGapSize } from '@/services/ExtractionPortsHelper';
import { getPowerNets, getConnectedPinNumber, isPowerGND } from '@/services/PCBHelper/net';
import FindInterface from '../../../../services/Sierra/helper/findInterface';
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import compTableHelper from '@/services/Sierra/helper/compTableHelper';
import {
  assignIBISModels,
  autoAssignIBISPkg,
  clearModels,
  APPLY_MODEL_TO_ALL_INTERFACES,
  APPLY_MODEL_TO_SETUP,
  APPLY_PKG,
  ASSIGN_MODEL_TO_ALL_INTERFACES,
  AUTO_ASSIGN_MODEL,
  applyModelToAllComponents
} from '@/services/Sierra/helper/assignIBISModelsHelper.js'
import {
  JUMPER,
  UNUSED,
  IC,
  COMP_REPEATER,
  CHIP,
  CONNECTOR,
  TEST_POINT,
  TEST_POINT_DISPLAY
} from '../../../../constants/componentType';
import { applySierraUserDefaultSetting, userDefaultSettings } from '@/services/userDefaultSetting/userDefaultSettingCtrl';
import designConstructor from '../../../../services/helper/designConstructor';
import { getRLCCompValue, getRLCValueByType } from '../../../../services/Sierra/setupHelper';
import { getConnectedPin } from '../../../../services/PCBHelper/compPinMapHelper';
import { getPartNumber, RLC_TYPES } from '../../../../services/PCBHelper';
import { openPreLayoutPage } from '../prelayout/action';
import { PRE_LAYOUT } from '../../../../constants/designVendor';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import { editPreLayoutComponents, getPreLayoutComponentsByPinNet, getUpdatePreLayoutInfo, updateInterfaceByPreLayout } from '../../../../services/Sierra/prelayout';
import { autoUpdateRepeaterSetting, getPartLibraryListBySearch, getRepeaterUpdateStatus } from '../../../../services/Sierra/library';
import { PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY, REPEATER } from '../../../../constants/libraryConstants';
import powerStore, { removeNetsVoltageUnit } from '../../../../services/Sierra/helper/powerNetsTableHelper';
import { updateMultiSetupBySettings } from '../multiInterface/saga';
import { changeMultiSetupResult, updateMultiInterfaceSetupStatus } from '../multiInterface/action';
import { ONLY_DRIVER_MODEL_TYPES, INPUT } from '@/services/LibraryHelper';
import sierraLibrary from '../../../../services/Sierra/library/libraryStorage';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { getExperimentPage, getExperimentsResult } from '../sweep/saga';
import { updateExperimentInfo } from '../sweep/action';
import { SIERRA } from '../../../../constants/pageType';
import { getSchematicPreLayoutPowerNets } from '../../../../services/Sierra/prelayout/Schematic';

const SIERRA_SETUP_UPDATE_VERSION = "3.1.0";
const SIERRA_PROBE_POINT_VERSION = '3.1.4';
let autoUpdateRefTask = null, updateRefTask = false;

function* getContent(action) {
  const { verificationID, loadPCB } = action;
  yield put(needUpdateCompSetting(null))
  yield put(findInterfaceReferenceNets(null));
  yield put(updateGetRefNetsLoading({ loading: false }));
  yield put(updateRecalc(false))
  yield put(updateInterfaceDesignMessage(""))
  yield put(updatePreInfoReplaceInfo(null))
  let { sierraInfo = {}, PCBsInInterface = [],
    newUpdatedInterface = {}, Interfaces = [],
    updateInterface = false, interfaceUpdateDesigns = [],
    isUpdateRef = false, updateCompPrefix = null } = yield call(getInterfaceData, verificationID);
  yield put(updateSierraContent(sierraInfo));
  yield put(changeConnectorStatus(true))
  // Load PCB
  const { SierraReducer: { project } } = yield select();
  for (let pcbId of PCBsInInterface) {
    const currProjectDesigns = projectDesigns.getDesigns(project.currentProjectId) || [];
    let findD = currProjectDesigns.find(it => it.id === pcbId);
    if (!findD) {
      //interface designId is subId , interface is invalid
      findD = currProjectDesigns.find(it => it.subId === pcbId);
      if (findD) {
        yield put(updateInterfaceDesignMessage(`The current design ${findD.name} is invalid due to copying. Please recreate the interface of this design.`))
      }
    }
    if (!designConstructor.isPreLayout(pcbId)) {
      yield fork(auroraDBJson.getAuroraJson, pcbId, {}, SIERRA)
    }
  }
  if (loadPCB) {
    yield put(updatePCBLoading(true));
    let pcbComponentsNets = project.pcbComponentsNets || [];
    const addPCB = PCBsInInterface.filter(pcbId => !pcbComponentsNets.includes(pcbId));
    yield call(saveComponentsNets, { pcbIds: addPCB });
    yield put(updatePCBLoading(false))
  }

  newUpdatedInterface = yield call(_updateInterfaceDataByVersion, { Interfaces });
  Interfaces = newUpdatedInterface.Interfaces;
  let updatePort = newUpdatedInterface.updatePort;
  const updatePreInfo = newUpdatedInterface.updatePreInfo;

  if (updatePort) {
    yield put(updateSierraInfo({ Interfaces }));
  }

  if (Interfaces.length > 0) {
    const { currentProjectId } = project;
    yield put(autoGetVerificationList(currentProjectId));
  }
  if (interfaceUpdateDesigns.length > 0 || isUpdateRef) {
    //when pcb replace ,update reference nets(auto find reference nets by signal nets.)
    yield put(updateReferenceNets());
  }

  //if component setting version update, open update panel
  if (updateCompPrefix && updateCompPrefix.length) {
    yield put(needUpdateCompSetting(updateCompPrefix))
  }

  if (updatePreInfo && updatePreInfo.length) {
    yield put(updatePreInfoReplaceInfo(updatePreInfo))
  }

  if (updateInterface || updatePort) {
    // update interface tp server
    yield delay(3000);
    yield put({ type: SAVE_CONFIG_TO_SERVER });
  }
};

export function* _updateInterface(action) {
  let newInterfaces = [...action.Interfaces];
  let updateInterface = action.updateInterface;
  let updateCompPrefix = [];
  const isSinglePcb = newInterfaces.length === 1;
  yield* newInterfaces.map(function* (item) {
    // Update extraction options 3.0.2, Compatible with verison < 3.0.2
    //versionCompareSize(currentVersion,basicVersion)  if currentVersion < basicVersion return true
    if (versionCompareSize(item.version, "3.0.2")) {
      // Not exist extraction section
      if (!item.content.extraction) {
        item.content.extraction = new Extraction();
        updateInterface = true;
      } else if (!item.content.extraction.clipSize) {
        item.content.extraction = { ...new Extraction(), ...item.content.extraction };
        updateInterface = true;
      }
    }

    //update components
    if (item.content && item.content.components) {
      const components = [];
      for (let comp of item.content.components) {
        //update components rlc model
        if (['Res', 'Ind', 'Cap', 'Jumper'].includes(comp.type)) {
          if (!comp.model) {
            updateInterface = true;
            comp.model = {
              type: 'value',
              fileName: '',
              libraryId: '',
              subckt: '',
              pairs: []
            }
          }
        }

        //update components repeater model
        if (comp.type === COMP_REPEATER) {
          const pins = Array.isArray(comp.pins) ? comp.pins : [];
          let model = comp.model;
          if (comp.model && comp.model.id) {
            model = { files: [], pairs: [] };
            model.files[0] = {
              folder: "",
              fileName: comp.model.name,
              libraryId: comp.model.id,
              subckt: comp.model.subcktName,
              type: COMP_REPEATER
            }
            comp.pins.forEach(item => {
              model.pairs.push({
                pin: item.pin,
                node: item.node ? item.node : "",
                fileName: item.node ? comp.model.name : "",
                libraryId: item.node ? comp.model.id : "",
                subckt: item.node ? comp.model.subcktName : "",
                modelKey: "1"
              });
              delete item.node;
            });
            updateInterface = true;
          } else if (!comp.model || !comp.model.files) {
            model = { files: [], pairs: [] };
            let pairs = [];
            pins.forEach(item => {
              let pin = {
                pin: item.pin,
                node: "",
                fileName: "",
                libraryId: "",
                subckt: "",
                modelKey: ""
              };
              pairs = [...pairs, pin];
            });
            model.pairs = pairs;
            updateInterface = true;
          }
          comp.model = model;
        }

        //libType to type

        //update components package model
        if (ICTypes.includes(comp.type)) {
          //Chip to IC
          if (comp.type === CHIP) {
            comp.type = IC;
            updateInterface = true;
          }
          if (comp.model && comp.model.files && comp.model.files.length) {
            let files = [];
            for (let item of comp.model.files) {
              if (item.libType) {
                item.type = item.libType;
                delete item.libType;
                updateInterface = true;
              }
              files.push(item)
            }
            comp.model.files = files;
          }
          if (!comp.pkg) {
            updateInterface = true;
            comp.pkg = {
              type: 'None'
            };
          }

          let _pins = [];
          for (let pin of comp.pins) {
            let _models = []
            for (let model of pin.pinModels) {
              if (model.pinName === 'nd_in' && ['PRBS-DDR', 'PRBS-SDR'].find(type => model.type.match(type))) {
                if (!model.tabs) {
                  model.tabs = ['7', '6'];
                  updateInterface = true;
                }
              }
              _models.push(model);
            }
            _pins.push({ ...pin, pinModels: _models });
          }
          comp.pins = [..._pins];
        };

        //version 3.1.4, add probe pins
        if (versionCompareSize(item.version, SIERRA_PROBE_POINT_VERSION) && !comp.probePins) {
          comp.probePins = [];
          updateInterface = true;
        }

        if (comp.type === CONNECTOR && versionCompareSize(item.version, '3.2.2')) {
          comp = updateConnector(comp, newInterfaces);
          updateInterface = true;
        } else if (comp.type === CONNECTOR) {
          const another = newInterfaces.find(inter => inter.pcbId === comp.connect.pcbId);
          if (!another) {
            comp.connect = {};
            comp.models = [];
            comp.cableModels = [];
            comp.pins = comp.pins.map(pin => ({ pin: pin.pin, signal: pin.signal, net: pin.net }));
          }
        }
        components.push(comp);
      }
      item.content.components = [...components];
    }

    // update powerNets
    const vendor = designConstructor.getDesignVendor(item.pcbId);
    if (vendor !== PRE_LAYOUT && item.content && item.content.powerNets && item.content.powerNets.length) {
      if (!item.content.powerNets[0].type) {
        const powerNets = yield call(getPowerNetsData, item.content)
        item.content.powerNets = [...powerNets]
        updateInterface = true
      }
      const { nets, save } = removeNetsVoltageUnit(item.content.powerNets, false);
      item.content.powerNets = nets;
      updateInterface = save ? save : updateInterface;
    }
    if (vendor !== PRE_LAYOUT) {
      const settingVersion = yield call(componentSetting.getVersion, item.pcbId);
      if (!item.settingVersion) {
        item.settingVersion = settingVersion;
        updateInterface = true
      } else if (versionCompareSize(item.settingVersion, settingVersion) && item.content.components && item.content.components.length) {
        /*  updateCompPrefix.push(item.pcbId); */
        //item.content.components,
        const compPrefixLib = yield call(componentSetting.getPrefixLib, item.pcbId);
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, item.pcbId);
        const compPinMap = yield call(componentSetting.getCompPinMap, item.pcbId);
        const passiveTable = yield call(compTableHelper.getTableData, item.pcbId);
        const updateInfo = getCompUpdateInfo({
          components: item.content.components,
          compPrefixLib,
          doNotStuff,
          pcbId: item.pcbId,
          compPinMap,
          passiveTable,
          isSinglePcb
        })
        updateCompPrefix.push({ pcbId: item.pcbId, pcbName: item.pcb, updateInfo })
      }
    }

    if (versionCompareSize(item.version, "3.2.5") && item.content.extraction && item.content.extraction.enablePassivePort === undefined) {
      //add passive port option
      item.content.extraction.enablePassivePort = false;
      updateInterface = true;
    }
  });
  return { Interfaces: newInterfaces, updateInterface, updateCompPrefix };
}

function* _updateInterfaceDataByVersion(action) {
  let newInterfaces = [...action.Interfaces];
  let updatePort = false, updatePreInfo = [];
  const { SierraReducer: { project: { verificationId } } } = yield select();

  let hasVCMultiInterfaces = false, allVirtualComps = [];
  if (newInterfaces && newInterfaces.length > 1) {
    hasVCMultiInterfaces = newInterfaces.filter(item => item.content && item.content.virtualComps && item.content.virtualComps.length).length > 1;
  }
  yield* newInterfaces.map(function* (item) {
    //update extraction port （3.2.1）
    const vendor = designConstructor.getDesignVendor(item.pcbId);
    if (vendor !== PRE_LAYOUT) {
      const pcbInfo = DesignInfo.getPCBInfo(item.pcbId);
      if (versionCompareSize(item.version, '3.2.1') && pcbInfo) {
        const { ports_generate_setup_list, referenceNets, port_setups, components, errors, warnings } = yield call(updateExtractionPortsSetup, item, pcbInfo);
        yield put(updateGeneratePortsErrors({ id: verificationId, errors, warnings }));
        item.content.ports_generate_setup_list = ports_generate_setup_list;
        item.content.referenceNets = referenceNets;
        item.content.port_setups = port_setups;
        item.content.components = components;
        updatePort = true;
      }

      if (versionCompareSize(item.version, '3.2.3')) {
        item.content.components = updateSetupComponentsByPortType({
          components: item.content.components,
          designId: item.pcbId,
          ports_generate_setup_list: item.content.ports_generate_setup_list,
          extractionType: item.content.channel ? item.content.channel.type : ""
        });
        updatePort = true;
      }

      if (judgeUpdateGapPortSetup(item.content)) {
        const data = updateExtractionGapPortsSetup(item.content, item.pcbId);
        item.content = data.newData;
        updatePort = true;
      }

      if (hasVCMultiInterfaces && item.content && item.content.virtualComps && item.content.virtualComps.length) {
        for (let comp of item.content.virtualComps) {
          if (allVirtualComps.includes(comp.name)) {
            comp.name = getDefaultName({ nameList: allVirtualComps, defaultKey: "UVC", key: "", firstIndex: 1 });
          }
          allVirtualComps.push(comp.name)
        }
      }
    } else {
      //update pre layout info
      yield call(preLayoutData.getPreLayout, item.pcbId);
      const { isUpdate, updateInfo } = yield call(getUpdatePreLayoutInfo, { content: item.content, pcbId: item.pcbId });
      if (isUpdate) {
        updatePreInfo.push({
          pcbId: item.pcbId,
          pcbName: item.pcb,
          updateInfo
        })
      }
    }
  });

  return { Interfaces: newInterfaces, updatePort, updatePreInfo };
}

function* _updateCurrentConfig(action) {
  const { config, verificationID } = action;
  //simulation config
  let newConfig = changeConfigFormat({ ...config });
  let ngspice = {}, update = false;

  if ((!newConfig.simulate) && config) {
    newConfig.simulate = 'Default';
    ngspice.simulate = 'Default';
  }

  if ((!newConfig.ngspiceMDFLM) && config) {
    newConfig.ngspiceMDFLM = '30ps';
    ngspice.ngspiceMDFLM = '30ps';
  }

  if ((!newConfig.hspiceCores) && config) {
    config.hspiceCores = 2;
    newConfig.hspiceCores = 2;
    update = true;
  }

  if ((ngspice && Object.keys(ngspice).length) || update) {
    yield call(updatePinToPinConfig, { verificationId: verificationID, id: config.id, config: { ...config, ...ngspice } });
  }

  newConfig.clock = {
    value: newConfig.clock && newConfig.clock.value ? newConfig.clock.value : '',
    unit: newConfig.clock && newConfig.clock.unit ? newConfig.clock.unit : 'M',
  }

  newConfig.timeStep = {
    value: newConfig.timeStep && newConfig.timeStep.value ? newConfig.timeStep.value : '',
    unit: newConfig.timeStep && newConfig.timeStep.unit ? newConfig.timeStep.unit : 'n',
  }

  yield put(updateCurrentConfig(newConfig));
}

function* addSignal(action) {
  const { addPcbName } = action;
  const { SierraReducer: { sierra } } = yield select();
  let signals = sierra.sierraInfo && sierra.sierraInfo.info ? sierra.sierraInfo.info.signals : null;

  if (signals) {
    let _signal = [...new Set(signals.map(item => item.name))];
    const signalName = getDefaultName({ nameList: _signal.map(item => { return { name: item } }), defaultKey: 'Signal' });
    const newSignal = new Signal(signalName);
    signals.push(newSignal);
    yield put(updateSierraInterface({ signals: [...signals] }));
    //If there is only one pcb, add it automatically
    if (addPcbName) {
      yield put(addPCBInSignals([addPcbName], signalName))
      return
    }
    // If there is a PCB in the previous signal, add the same PCB for the added signal
    if (signals.length > 1) {
      let addSignalName = signals[0].name;
      let pcbNameList = signals.filter(item => item.name === addSignalName && item.pcb).map(item => item.pcb)
      yield put(addPCBInSignals(pcbNameList, signalName, '', true))
    }
  }
};

function* addPCBSINSignal(action) {
  // TODO: delete interface or delete PCB
  const { pcbs, name, pcbId, addSignal } = action;
  const { SierraReducer: { sierra, project: { currentProjectId, verificationId } } } = yield select();
  let prevInterfaces = [];
  if (!sierra.sierraInfo || !sierra.sierraInfo.info) {
    return;
  }
  if (sierra.sierraInfo) {
    prevInterfaces = sierra.sierraInfo.PCBsInInterface;
  }

  let defaultChannelType = 'Default';
  const userDefaultSetting = yield call(userDefaultSettings.getSettings)
  defaultChannelType = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings.extractionOption : defaultChannelType;
  if (sierra.sierraInfo.Interfaces.length) {
    defaultChannelType = sierra.sierraInfo.Interfaces[0] && sierra.sierraInfo.Interfaces[0].content && sierra.sierraInfo.Interfaces[0].content.channel && designConstructor.getDesignVendor(sierra.sierraInfo.Interfaces[0].pcbId) !== PRE_LAYOUT ? sierra.sierraInfo.Interfaces[0].content.channel.type : defaultChannelType;
  }

  //Find signals that have no pcb and are not currently signal
  let _info = sierra.sierraInfo.info;
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => name !== item.name && !item.pcb) : [];
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const _name = sierra.sierraInfo.info.name;
  const exsitInterface = _Interfaces.map(item => item.pcb);
  // Delete pcb in signal
  let deleteConnectorValue = [], deleteConnectorPcbIds = [];

  if (pcbs.length === 0) {
    let interIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
    if (!pcbId) {
      return;
    }
    let info = { ..._Interfaces[interIndex] };
    let signals = [...info.content.signals];
    //delete current signal pcb
    _prevSignal = _info && _info.signals ? _info.signals.filter(item => (name === item.name && item.pcbId === pcbId) || !item.pcb) : [];
    const signalIndex = _prevSignal.findIndex(item => item.name === name);
    _prevSignal[signalIndex] = { name, nets: [] };
    const components = [...info.content.components];
    const _prevConnector = components.filter(item => item.type === CONNECTOR);
    const vendor = designConstructor.getDesignVendor(info.pcbId);

    if (vendor === PRE_LAYOUT) {
      const signal = signals.find(item => item.name === name);

      let sameSignalNets = [];

      if (signal && signal.nets && signal.nets.length > 0) {
        const { otherSignalNets, otherSignalAndNetList } = signalNetsFormat(signals, name);
        const nets = signal.nets.filter(net => !otherSignalNets.includes(net));
        sameSignalNets = signal.nets.filter(net => otherSignalNets.includes(net));
        const prelayout = yield call([preLayoutData, preLayoutData.getPreLayout], info.pcbId);
        const _components = prelayout.content.components;
        const filterComps = _components.filter(item => item.pins.some(pin => nets.includes(pin.net)) && !item.pins.some(pin => otherSignalNets.includes(pin.net))).map(item => item.name);
        info.content.signals = signals.filter(item => item.name !== name);
        info.content.components = components.filter(item => !filterComps.includes(item.name));
        info.content.components.forEach(comp => {
          comp.pins = comp.pins.filter(pin => !nets.includes(pin.net));
          //update pin signal (The net deleted by the current signal and the net that other signals exist)
          if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
            comp.pins = updateComponentPinSignal({
              comp,
              sameSignalNets,
              otherSignalNets,
              otherSignalAndNetList,
              signalName: name
            });
          }
        });
        _Interfaces[interIndex].content.powerNets = [];
        // [{pcbId::name}]
        const updateComponents = info.content.components.map(item => item.name);
        deleteConnectorValue = _prevConnector.filter(item => !updateComponents.includes(item.name));
        deleteConnectorValue = deleteConnectorValue.map(item => _Interfaces[interIndex].pcbId + "::" + item.name);

        _Interfaces[interIndex].content.signals = signals.filter(item => item.name !== name);
        if (_Interfaces[interIndex].content.signals.length < 1) {
          deleteConnectorPcbIds.push(_Interfaces[interIndex].pcbId)
          yield call(deleteInterfacePromise, [_Interfaces[interIndex].interfaceId]);
          _Interfaces.splice(interIndex, 1);
        }
      } else {
        _Interfaces[interIndex].content.signals = signals.filter(item => item.name !== name);
        if (_Interfaces[interIndex].content.signals.length < 1) {
          deleteConnectorPcbIds.push(_Interfaces[interIndex].pcbId)
          yield call(deleteInterfacePromise, [_Interfaces[interIndex].interfaceId]);
          _Interfaces.splice(interIndex, 1);
        }
      }
    } else {
      let powerNets = [...info.content.powerNets];
      const pcbInfo = DesignInfo.getPCBInfo(info.pcbId);
      const { netsList = [], layers = [] } = pcbInfo || {};
      const signal = signals.find(item => item.name === name);

      //get other signals nets
      const { otherSignalNets, otherSignalAndNetList } = signalNetsFormat(signals, name);
      //sameSignalNets:[net1,net2,...] the deleted nets exist in other signals
      let sameSignalNets = [];

      const compPrefixLib = yield call(componentSetting.getPrefixLib, pcbId);
      const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
      const compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
      const passiveTable = yield call(compTableHelper.getTableData, pcbId);

      if (signal && signal.nets && signal.nets.length > 0) {
        const nets = signal.nets.filter(net => !otherSignalNets.includes(net));
        sameSignalNets = signal.nets.filter(net => otherSignalNets.includes(net));
        const deletedComps = getComponentsWithNetList({ netList: nets, pcbNetsList: netsList, layers, pcbId: info.pcbId, COMP_PREFIX_LIB: compPrefixLib, doNotStuff, passiveTable, compPinMap });
        let update = deleteCompsConnectWithNets({ signalName: name, deletedComps, components });
        //find power net and update component by rlc component
        const otherSignals = signals.filter(item => item.name !== name);
        const updateComp = yield call(getUpdatePowerNets, { components: update.components, powerNets, signals: otherSignals, netsList, findGND: false, update: true, designId: pcbId });
        let updatePowerNets = updateComp.powerNets;
        update.components = updateComp.components;

        update.components.forEach(comp => {
          //update pin signal (The net deleted by the current signal and the net that other signals exist)
          if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
            comp.pins = updateComponentPinSignal({
              comp,
              sameSignalNets,
              otherSignalNets,
              otherSignalAndNetList,
              signalName: name
            });
          }
        })

        _Interfaces[interIndex].content.powerNets = [...updatePowerNets];

        const updateComponents = update.components.map(item => item.name);
        deleteConnectorValue = _prevConnector.filter(item => !updateComponents.includes(item.name));
        // [{pcbId::name}]
        deleteConnectorValue = deleteConnectorValue.map(item => _Interfaces[interIndex].pcbId + "::" + item.name);
        _Interfaces[interIndex].content.components = update.components;
        _Interfaces[interIndex].content.signals = signals.filter(item => item.name !== name);
        if (_Interfaces[interIndex].content.signals.length < 1) {
          deleteConnectorPcbIds.push(_Interfaces[interIndex].pcbId)
          yield call(deleteInterfacePromise, [_Interfaces[interIndex].interfaceId]);
          _Interfaces.splice(interIndex, 1);
        }
      } else {
        _Interfaces[interIndex].content.signals = signals.filter(item => item.name !== name);
        if (_Interfaces[interIndex].content.signals.length < 1) {
          deleteConnectorPcbIds.push(_Interfaces[interIndex].pcbId)
          yield call(deleteInterfacePromise, [_Interfaces[interIndex].interfaceId]);
          _Interfaces.splice(interIndex, 1);
        }
      }
    }
  }

  if (deleteConnectorValue.length > 0 || deleteConnectorPcbIds.length > 0) {
    // Update exist interface components
    _Interfaces.forEach(item => {
      item.content.components.forEach(comp => {
        if (comp.type === CONNECTOR && comp.connect
          && (
            deleteConnectorValue.includes(comp.connect.pcbId + "::" + comp.connect.componentName)
            || (comp.connect.pcbId && deleteConnectorPcbIds.includes(comp.connect.pcbId))
          )) {
          comp.connect = {};
          if (comp.cableModels && comp.cableModels.length) {
            comp.cableModels = [];
            comp.pins.forEach(pin => {
              if (pin.external_map) {
                pin.external_map.cableModelId = "";
                pin.external_map.cablePort = "";
              }
            })
          }
        }
      })
    })
  }

  const _createInterfacePCB = pcbs.filter(pcb => !exsitInterface.includes(pcb));
  if (_createInterfacePCB.length > 0) {
    // Create interface
    const createInterface = projectDesigns.getAvailableDesigns(currentProjectId).filter(item => _createInterfacePCB.includes(item.name));
    let response = null;
    yield* createInterface.map(function* (info) {
      let newInterface = new SierraInterface(_name);
      let signalNames = [...new Set(_info.signals.map(item => item.name))]
      const addSignalList = signalNames.map(item => { return new Signal(item) })
      // newInterface.signals.push(new Signal(name));
      // When adding pcb, add the selected pcb for each set of signals
      const vendor = designConstructor.getDesignVendor(info.id);
      newInterface.signals = [...newInterface.signals, ...addSignalList];
      newInterface.channel.type = vendor === PRE_LAYOUT ? 'Default' : defaultChannelType;
      const settingVersion = yield call(componentSetting.getVersion, info.id);
      newInterface.extraction = yield call(reSetClipDesignByCompsNum, { pcbId: info.id, extraction: newInterface.extraction })

      response = yield call(createInterfacePromise, {
        projectId: currentProjectId,
        designId: info.id,
        verificationId: verificationId,
        verificationName: _name,
        content: newInterface,
        interfaceName: _name,
        readyForSim: 0,
        version: SIERRA_SETUP_VERSION,
        settingVersion
      });

      // Update interface
      _Interfaces.push({
        content: response.interfaceContent,
        interfaceId: response.id,
        name: _name,
        pcb: info.name,
        pcbId: info.id,
        designVersion: response.designVersion,
        settingVersion: response.settingVersion,
        ifDoExtraction: response.ifDoExtraction,
        readyForSim: response.readyForSim,
      })
    });
  };

  // Update interface
  let addPcbList = [];
  for (let pcbName of pcbs) {
    // Filter out added pcbs
    const InterfaceInfo = _Interfaces.find(item => item.pcb === pcbName)
    if (InterfaceInfo && !InterfaceInfo.content.signals.find(item => item.name === name)) {
      addPcbList.push(pcbName)
    }
  }
  const filterInterface = _Interfaces.filter(existPCB => addPcbList.includes(existPCB.pcb));
  if (filterInterface.length > 0) {
    // Update interfaces
    filterInterface.forEach(_interface => {
      const index = _Interfaces.findIndex(item => item.interfaceId === _interface.interfaceId);
      let signals = _interface.content.signals;
      const _signalNames = signals.map(it => it.name);
      let addSignalList = [];
      let signalNames = addSignal ? [name] : [...new Set(_info.signals.map(item => item.name))];
      // Filter the signal of the unselected pcb
      for (let _signalName of signalNames) {
        if (!_signalNames.includes(_signalName)) {
          addSignalList.push(new Signal(_signalName))
        }
      }
      if (addSignalList.length) {
        signals = [...signals, ...addSignalList]
        _interface.content.signals = [...signals];
        _Interfaces[index] = _interface;
      }
    });

  };
  const PCBsInInterface = _Interfaces.map(item => item.pcbId);
  // Update interfaces
  yield put(updateSierraInfo({ Interfaces: _Interfaces, PCBsInInterface }));
  // Update info
  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, _name);
  const signalNames = info.signals.map(item => item.name);
  _prevSignal = _prevSignal.filter(item => !signalNames.includes(item.name));
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  const connectorList = getConnectorList(_Interfaces);
  yield put(updateSierraInfo({ Interfaces: _Interfaces, connectorList }));
  yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: null });
  // Load PCB
  const addPCB = PCBsInInterface.filter(pcbId => !prevInterfaces.includes(pcbId));
  yield put(saveComponentsNetsInProject(addPCB));
};

function* reSetClipDesignByCompsNum({ pcbId, extraction }) {
  //When the number of components is less than or equal to 2, do not clip design
  if (!auroraDBJson.checkAuroraJson(pcbId)) {
    yield call(auroraDBJson.getAuroraJson, pcbId, {}, SIERRA);
  }

  if (auroraDBJson.isFlexBoard(pcbId) && extraction) {
    extraction.clipping = false
  }
  return extraction;
}

let autoSaveTask = null;
function* openVerificationPage(action) {
  const { view, verificationId, getStatus, rename, id, isMultiSetup } = action;
  const { SierraReducer: { project: { currentProjectId, currentProjectVerifications } } } = yield select();
  if (!view) {
    return;
  }

  if (!isMultiSetup) {
    yield put(changeMultiSetupResult(null))
  }

  if (view === PCB_PRE_LAYOUT) {
    yield put(openPreLayoutPage(id))
    return;
  }

  if (view === EXPERIMENTS) {
    yield call(getExperimentPage, { id })
    yield put(updateViewStatus(action));
    return;
  }

  if (view === EXPERIMENTS_RESULT) {
    yield put(updateExperimentInfo({ id: '', resultList: [] }, 'all'))
    yield call(getExperimentsResult, { id })
    yield put(updateViewStatus(action));
    return;
  }

  if (view === RESULT && !getStatus) {
    yield put(updateViewStatus(action));
    const findV = currentProjectVerifications.find(item => item.id === verificationId);
    if (!findV) { return; }
    yield put(getResultPage({
      verificationId: verificationId,
      verificationSubId: findV.subId,
      name: findV.name,
      status: findV.status
    }))
    return;
  }

  let loadPCB = true;
  if (view === RESULT) {
    loadPCB = false;
    const findV = currentProjectVerifications.find(item => item.id === verificationId);
    if (!findV) { return; }
    yield put(getResultPage({
      verificationId: verificationId,
      verificationSubId: findV.subId,
      name: findV.name,
      status: findV.status
    }))
  }

  if (!verificationId) {
    yield put(updateViewStatus(action));
    return;
  }
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
    if (!rename) {
      //Get current reference nets that need to be updated
      let timeInterVal = getCountTime();
      let isUpdateRefNets = timeInterVal ? true : false;
      //clear count update reference nets time
      closeCountTime();
      if (updateRefTask) {
        isUpdateRefNets = true;
      }
      timeInterVal = null;
      yield call(saveVerificationContentToServer, { pcbs: null, isUpdateRefNets });
    }
  }
  yield put(updateViewStatus(action));
  //update apply to all pins of same component status
  yield put(updateApplyUsage(false));
  yield put(getVerificationContent(verificationId, loadPCB));
  const current = currentProjectVerifications.find(item => item.id === verificationId);
  if (current) {
    yield put(changeTabMenu({
      selectKeys: ['monitor'],
      menuType: 'simulation',
      verificationId,
      projectId: currentProjectId,
      verificationName: current.name
    }));
    if (view !== RESULT) {
      yield put(openTabFooter());
    }
  }
  // Check interface status - running, success, failed, never, cancel
  const promise = yield call(checkVerificationStatus, verificationId);
  let resultExist = false;
  if (promise && promise.status) {
    yield put(updateCurrentVerificationStatus(promise.status));
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    }
  };
  yield put(existResult(resultExist));
  // Get interface monitor, log, progress
  yield put(getInterfaceMonitor(verificationId));
  autoSaveTask = yield fork(autoSave);
}

let pcbList = [], saveAction = {};
function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([AUTO_SAVE_CONFIG_TO_SERVER, SAVE_CONFIG_TO_SERVER]);
    let delayTime = 3000;
    if (newAction.type === AUTO_SAVE_CONFIG_TO_SERVER) {
      if (lastTask) {
        pcbList = newAction.pcbs ? [...new Set([...newAction.pcbs, ...(pcbList || [])])] : [];
        saveAction = { ...saveAction, ...newAction };
        yield cancel(lastTask);
      } else {
        pcbList = newAction.pcbs;
        saveAction = { ...newAction };
      }
      lastTask = yield fork(debounce, delayTime, saveVerificationContentToServer, { ...saveAction, pcbs: pcbList });
    } else if (newAction.type === SAVE_CONFIG_TO_SERVER) {
      delayTime = 3000;
      if (lastTask) {
        pcbList = newAction.pcbs ? [...new Set([...newAction.pcbs, ...(pcbList || [])])] : [];
        saveAction = { ...saveAction, ...newAction };
        yield cancel(lastTask);
      } else {
        pcbList = newAction.pcbs;
        saveAction = { ...newAction };
      }
      lastTask = yield fork(debounce, delayTime, saveVerificationContentToServer, { ...saveAction, pcbs: pcbList });
    }
  }
};

function* debounce(time = 0, callback, info) {
  yield delay(time);
  yield call(callback, info);
  updateRefTask = false
}

function* saveVerificationContentToServer(action) {
  const { pcbs, isUpdateRefNets, isMeasureConfig, key, updateInitialized } = action;
  if (isMeasureConfig) {
    yield put(updateMeasureConfigStatus(key, false));
  }

  const { SierraReducer } = yield select();
  const sierra = SierraReducer && SierraReducer.sierra;
  if (!sierra) {
    if (isMeasureConfig) {
      yield put(updateMeasureConfigStatus(key, true))
    }
    return;
  };
  const sierraInfo = sierra.sierraInfo;
  if (!sierraInfo) {
    if (isMeasureConfig) {
      yield put(updateMeasureConfigStatus(key, true))
    } return
  };
  const { currentProjectId } = SierraReducer.project;
  let Interfaces = sierraInfo.Interfaces;
  const verificationId = sierraInfo.verificationId;
  if (!verificationId) {
    if (isMeasureConfig) {
      yield put(updateMeasureConfigStatus(key, true))
    }
    return;
  }
  let response = null;
  if (!Interfaces || !Interfaces.length) {
    if (isMeasureConfig) {
      yield put(updateMeasureConfigStatus(key, true))
    }
    return
  };
  if (pcbs && pcbs.length) {
    yield* pcbs.map(function* (pcbId) {
      try {
        let info = Interfaces.find(item => item.pcbId === pcbId);
        if (!info || !info.content) {
          return;
        }
        //1:ready, 0: not ready
        let readyForSim = 1;
        const errorCheck = getSierraInterfaceErrorCheck(info);
        if (errorCheck) {
          readyForSim = 0;
        }

        if (!info.content.extraction) {
          info.content.extraction = new Extraction();
        }
        let settingVersion = info.settingVersion;
        if (!settingVersion) {
          try {
            settingVersion = yield call(componentSetting.getVersion, pcbId);
          } catch (error) {
            console.error(error)
          }
        }
        ResultData.cleanAll();
        const interfaceInfo = {
          designId: pcbId,
          interfaceId: info.interfaceId,
          interfaceName: info.name,
          projectId: currentProjectId,
          verificationId,
          verificationName: info.name,
          content: info.content,
          readyForSim: readyForSim,
          version: SIERRA_SETUP_VERSION,
          designVersion: info.designVersion,
          settingVersion
        }
        if (isUpdateRefNets || updateInitialized) {
          interfaceInfo.initialized = 1;
        }
        response = yield call(updateInterfacePromise, interfaceInfo);
      } catch (error) {
        console.error(error)
      }
    })
  } else {
    //update reference nets
    if (isUpdateRefNets) {
      try {
        const { pcbIds, signals } = yield call(getSignals);
        if (pcbIds && pcbIds.length && signals && signals.length) {
          const _Interfaces = yield call(findRefNets, { pcbIds, signals, isSave: true });
          if (_Interfaces) {
            Interfaces = _Interfaces;
          }
        }
      } catch (error) {
        console.error(error)
      };
    }
    yield* Interfaces.map(function* (_interface) {
      try {
        const errorCheck = getSierraInterfaceErrorCheck(_interface);
        // //1:ready, 0: not ready
        let readyForSim = 1;
        if (errorCheck) {
          readyForSim = 0;
        }

        if (!_interface.content.extraction) {
          _interface.content.extraction = new Extraction();
        }
        ResultData.cleanAll();
        let settingVersion = _interface.settingVersion;
        if (!settingVersion) {
          settingVersion = yield call(componentSetting.getVersion, _interface.pcbId);
        }
        const interfaceInfo = {
          designId: _interface.pcbId,
          interfaceId: _interface.interfaceId,
          interfaceName: _interface.name,
          projectId: currentProjectId,
          verificationId,
          verificationName: _interface.name,
          content: _interface.content,
          readyForSim: readyForSim,
          version: _interface.version,
          designVersion: _interface.designVersion,
          settingVersion
        }
        if (isUpdateRefNets || updateInitialized) {
          interfaceInfo.initialized = 1;
        }
        response = yield call(updateInterfacePromise, interfaceInfo);
      } catch (error) {
        console.error(error)
      };
    })
  }

  if (response) {
    yield put(autoGetVerificationList(currentProjectId));
  }

  if (isMeasureConfig) {
    yield put(updateMeasureConfigStatus(key, true))
  }

  pcbList = [];
  return response;
};

function* editSignal(action) {
  const { SierraReducer: { sierra } } = yield select();
  const { errors, warnings, info, _Interfaces, connectorList } = yield call(editSignalPromise, { signalData: action, sierraInfo: sierra.sierraInfo })
  yield put(updateGeneratePortsErrors({ id: sierra.sierraInfo.verificationId, errors, warnings }));
  yield put(updateSierraInterface({ ...info }));
  yield put(updateSierraInfo({ Interfaces: _Interfaces, connectorList }));
  yield put(changeConnectorStatus(true))
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* _updateMultiInterfaces(action) {
  const { Interfaces, pcbs } = action;
  //Find signals that have no pcb
  const { SierraReducer: { sierra } } = yield select();
  let _info = sierra.sierraInfo.info;
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
  yield put(updateSierraInfo({ Interfaces: Interfaces }));
  // Update info
  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: [...pcbs] });
}

function* _updateInterfaces(action) {
  const { Interfaces, pcbId, needMerge = false } = action;
  //Find signals that have no pcb
  const { SierraReducer: { sierra } } = yield select();

  yield put(updateSierraInfo({ Interfaces: Interfaces }));
  if (needMerge) {
    let _info = sierra.sierraInfo.info;
    let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
    // Update info
    const SETUP = SierraVerify;
    let info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
    //add signals that have no pcb
    info.signals = [...info.signals, ..._prevSignal];
    yield put(updateSierraInterface({ ...info }));
  }

  yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: [pcbId] });
}

export function* findComponentByPin(libraryId, pin) {
  const model = yield call(getIbisModelList, { libraryId, usage: '' })
  const { packages } = model || {};
  let IBISComponent = ''
  if (packages) {
    let pkg = packages.find(pkg => {
      let tmp = pkg.pinSection.find(item => item.pin === pin)
      if (tmp) return pkg.component
      else return false
    })
    if (pkg) IBISComponent = pkg.component
  }

  return IBISComponent
}

function* assignModel(action) {
  const { record: { component, signal, pin, net, libType, pcb, pcbId, usage }, model, deviceVcc = "", pinModelsInfo, applyAll, applySignal, powerOff } = action;
  const _libType = libType;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
  let editingInterface = _Interfaces[_InterfaceIndex];
  let components = [...editingInterface.content.components];
  const { enableVoltage, fileName, modelName, modelType, libraryId, version } = model;

  const compIndex = components.findIndex(item => item.name === component);
  const comp = components[compIndex];
  const pinModelIndex = (comp.pins || []).findIndex(item =>
    item.net === net && item.pin === pin && item.signal === signal
  );
  let pinModelObj = comp.pins[pinModelIndex];
  let prevModel = JSON.parse(JSON.stringify(pinModelObj.model || {}));

  const isModelUpdate = judgeModelUpdate(prevModel, model);
  let isPowerOffUpdate = false, isPUUpdate = false;

  const { pu, pd, pinModels: newPinModels } = pinModelsInfo;

  if (usage === "Driver") {
    isPowerOffUpdate = powerOff !== pinModelObj.powerOff;

    const findPU = (pinModelObj.pinModels || []).find(item => item.pinName === "nd_pu") || {};
    const findPD = (pinModelObj.pinModels || []).find(item => item.pinName === "nd_pd") || {};
    isPUUpdate = (powerOff === "1" || libType === "SPICE") && (pu !== findPU.voltage || pd !== findPD.voltage);
  }

  if (!isModelUpdate && !isPowerOffUpdate && !isPUUpdate) {
    return;
  }

  // update IBIS file and component
  if (libType && libraryId && fileName) {
    // find component
    const IBISComponent = yield call(findComponentByPin, libraryId, pin)
    const prevFile = comp.model && comp.model.files && comp.model.files[0]
    if (!prevFile || prevFile.libraryId !== libraryId || prevFile.fileName !== fileName) {
      components[compIndex].pkg = { type: 'None' }
    }
    components[compIndex].model = {
      files: [{
        type: libType,
        libraryId,
        component: IBISComponent || (prevFile && prevFile.libraryId === libraryId ? prevFile.component : ''),
        fileName,
        version
      }]
    }
  }

  const findPUIndex = (newPinModels || []).findIndex(item => item.pinName === "nd_pu");
  const findPDIndex = (newPinModels || []).findIndex(item => item.pinName === "nd_pd");
  let newPU;
  if (findPUIndex > -1) {
    newPU = newPinModels[findPUIndex].voltage;
    newPinModels[findPUIndex].voltage = (powerOff === "1" || libType === "SPICE") && pu ? pu : newPinModels[findPUIndex].voltage;
  }
  if (findPDIndex > -1) {
    newPinModels[findPDIndex].voltage = (powerOff === "1" || libType === "SPICE") && pd ? pd : newPinModels[findPDIndex].voltage;
  }

  if (applyAll) {//Apply to all pins of same component and same usage
    components[compIndex].pins.forEach(pinItem => {
      if (usage === pinItem.usage) {
        //update model
        const { enableVoltage, fileName, modelName, modelType, libraryId, version } = model;
        let newModel = new Model({
          libType: _libType,
          libraryId: libraryId,
          fileName,
          modelType,
          modelName,
          enableVoltage,
          version
        });
        pinItem.model = newModel;
        //update pin Models nd_pu,nd_pd voltage
        if (pinModelsInfo && usage === 'Driver') {
          pinItem.pinModels = updateICPinModelsByBuffer({
            pinModels: pinItem.pinModels,
            usage: pinItem.usage,
            libType: _libType,
            modelType,
            newPinModels
          });
        }
        if (usage === "Driver") {
          pinItem.powerOff = powerOff;
        }
      }
    });
    let prevModel = components[compIndex].model;
    //if component model is MultiPinSPICE, update to IBIS/SPICE
    if (prevModel && prevModel.files && prevModel.files[0] && prevModel.files[0].type === MultiPinSPICE) {
      components[compIndex].model = {
        files: [{
          type: libType,
          libraryId,
          fileName,
          version
        }]
      }
    }
  } else {
    //update model
    let newModel = new Model({
      libType: _libType,
      libraryId: libraryId,
      fileName,
      modelType,
      modelName,
      enableVoltage,
      version
    });

    //update current pin model
    components[compIndex].pins[pinModelIndex].model = newModel;
    //update pin Models nd_pu,nd_pd voltage
    if (pinModelsInfo && usage === 'Driver') {
      let _pinModels = components[compIndex].pins[pinModelIndex].pinModels;
      _pinModels = updateICPinModelsByBuffer({
        pinModels: _pinModels,
        usage,
        libType: _libType,
        modelType,
        newPinModels
      });
      if (usage === "Driver") {
        components[compIndex].pins[pinModelIndex].powerOff = powerOff;
      }
      //TODO
      /* const findIN = _pinModels.findIndex(item => item.pinName === 'nd_in');
      //delete nd_in type when libType modified
      if (findIN > -1 && libType !== MultiPinSPICE && prevModel.libType === MultiPinSPICE && _pinModels[findIN].type === MultiPinSPICE) {
        _pinModels[findIN].type = "";
        _pinModels[findIN].stimulus = "";
      } */
      components[compIndex].pins[pinModelIndex].pinModels = _pinModels;
    }
  }

  if (applySignal) {
    const pinModelIndex = comp.pins.findIndex(item =>
      item.net === net && item.pin === pin && item.signal === signal
    );
    const pinInfo = components[compIndex].pins[pinModelIndex]
    const IBISComponent = components[compIndex].model.files[0].component
    components = applySignalModels(components, pinInfo, IBISComponent, deviceVcc, pinModelsInfo, isModelUpdate)
  }

  components[compIndex].deviceVcc = deviceVcc || (newPU ? newPU : pu);
  editingInterface.content.components = [...components];
  _Interfaces[_InterfaceIndex] = { ...editingInterface };
  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId }));

  //update library file error message in current interfaces
  let verificationId = sierra.sierraInfo.verificationId;
  let interfaceLibraryError = sierra.interfaceLibraryError ? sierra.interfaceLibraryError : [];
  interfaceLibraryError = updateLibraryMsg(interfaceLibraryError, verificationId, "model", { component, pcb, pin });
  interfaceLibraryError = updateLibraryMsg(interfaceLibraryError, verificationId, "stimuli", { component, pcb, pin });
  yield put(updateInterfaceLibraryMsg(interfaceLibraryError));
};

function* saveModelPins(action) {
  const { record: { component, pin, net, signal, pcb, pcbId }, pinModels, applyAll, applyAllDriver } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
  if (_InterfaceIndex < 0) {
    return;
  }
  let editingInterface = _Interfaces[_InterfaceIndex];
  let components = [...editingInterface.content.components];
  const compIndex = components.findIndex(comp => comp.name === component);
  const pinModelIndex = components[compIndex].pins.findIndex(item =>
    item.pin === pin && item.net === net && item.signal === signal
  );
  components[compIndex].pins[pinModelIndex].pinModels = pinModels;

  const nd_pu = pinModels ? pinModels.find(item => item.pinName === "nd_pu") : null;
  if (applyAll || applyAllDriver) {
    //apply nd_in stimulus and type to all pins of same component and usage
    //apply nd_in stimulus and type to all pins of all driver pins

    if (applyAll) {
      components[compIndex].pins.forEach(pinItem => {
        if (pinItem.pin !== pin && pinItem.usage === "Driver") {
          pinItem.pinModels = updateICPinModels({
            pinModels: pinItem.pinModels,
            usage: pinItem.usage,
            libType: pinItem.model && pinItem.model.libType ? pinItem.model.libType : "IBIS",
            modelType: pinItem.model && pinItem.model.modelType ? pinItem.model.modelType : "I/O",
            newPinModels: pinModels
          });
        }
      })
    } else if (applyAllDriver) {
      //current interface
      components.forEach(item => {
        if (item.type === IC && item.pins) {
          item.pins.forEach(pinItem => {
            if (pinItem.usage === "Driver") {
              const libType = pinItem.model && pinItem.model.libType ? pinItem.model.libType : "IBIS";
              const modelType = pinItem.model && pinItem.model.libType ? pinItem.model.libType : "I/O";

              pinItem.pinModels = updateICPinModels({
                pinModels: pinItem.pinModels,
                usage: pinItem.usage,
                libType,
                modelType,
                newPinModels: pinModels
              });
            }
          })
          item.deviceVcc = nd_pu && nd_pu.value ? nd_pu.value : item.deviceVcc
        }
      })
      //other interfaces
      if (_Interfaces.length > 1) {
        for (let info of _Interfaces) {
          if (info.pcbId === pcbId || !info.content || !info.content.components) {
            continue;
          }

          info.content.components.forEach(item => {
            if (item.type === IC && item.pins) {
              item.pins.forEach(pinItem => {
                if (pinItem.usage === "Driver") {
                  const libType = pinItem.model && pinItem.model.libType ? pinItem.model.libType : "IBIS";
                  const modelType = pinItem.model && pinItem.model.libType ? pinItem.model.libType : "I/O";

                  pinItem.pinModels = updateICPinModels({
                    pinModels: pinItem.pinModels,
                    usage: pinItem.usage,
                    libType,
                    modelType,
                    newPinModels: pinModels
                  });
                }
              })
              item.deviceVcc = nd_pu && nd_pu.value ? nd_pu.value : item.deviceVcc
            }
          })
        }
      }
    }
  }
  components[compIndex].deviceVcc = nd_pu && nd_pu.value ? nd_pu.value : components[compIndex].deviceVcc
  editingInterface.content.components = [...components];
  _Interfaces[_InterfaceIndex] = { ...editingInterface };
  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId }));

  //update library file error message in current interfaces
  let verificationId = sierra.sierraInfo.verificationId;
  let interfaceLibraryError = sierra.interfaceLibraryError ? sierra.interfaceLibraryError : [];
  interfaceLibraryError = updateLibraryMsg(interfaceLibraryError, verificationId, "stimuli", { component, pcb, pin });
  yield put(updateInterfaceLibraryMsg(interfaceLibraryError));
}

function* addPowerGNDNets() {
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  if (_Interfaces.length === 1) {
    const vendor = designConstructor.getDesignVendor(_Interfaces[0].pcbId);
    if (vendor === PRE_LAYOUT) {
      return;
    }
    _Interfaces[0].content.powerNets.push(new SourceNets());
    yield put(updateInterfaces({
      Interfaces: _Interfaces,
      pcb: _Interfaces[0].pcb,
      pcbId: _Interfaces[0].pcbId,
      needMerge: true
    }));
  } else {
    let powerNets = sierra.sierraInfo.info.powerNets;
    powerNets.push(new SourceNets());
    yield put(updateSierraInterface({ powerNets: [...powerNets] }));
  }
};

function* addPCBsInPGNets(action) {
  const { pcb, net } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  if (!net) {
    _Interfaces[index].content.powerNets.push(new SourceNets());
  } else {
    // TODO
  }
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: _Interfaces[index].pcbId,
    needMerge: true
  }));
};

function* pgNetsSeletion(action) {
  const { pcb, pcbId, netSelect, name } = action;
  const { SierraReducer: { sierra, project: { verificationId } } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  let PowerNets = [..._Interfaces[index].content.powerNets];
  let components = [..._Interfaces[index].content.components];
  const vendor = designConstructor.getDesignVendor(pcbId);
  if (vendor === PRE_LAYOUT) {
    return
  }
  if (netSelect) {
    const netIndex = PowerNets.findIndex(item => item.name === netSelect);
    if (netIndex > -1) {
      message.error(`Net ${netSelect} has been selected!`);
      return;
    }
  }
  const powerTable = yield call(powerStore.getTable, pcbId);
  const findPwr = (powerTable || []).find(item => item.net === netSelect) || {};
  if (name) {
    const netIndex = PowerNets.findIndex(item => item.name === name);
    PowerNets[netIndex].name = netSelect;
    //Is the current net selected by the user
    PowerNets[netIndex].custom = true;
    PowerNets[netIndex].value = findPwr.voltage || PowerNets[netIndex].value;
    //delete reference
    delete PowerNets[netIndex].reference;
    //update components RLC S-parameter model reference
    components.forEach(comp => {
      //removed referenceNet if reference net === removed power/gnd net
      if (name && RLCTypes.includes(comp.type) && comp.model && comp.model.type === "Touchstone" && comp.model.referenceNet === name) {
        comp.model.referenceNet = "";
      }
      //delete power connect pin
      const index = comp.pins.findIndex(pin => pin.net === name && !pin.signal);
      if (index > -1) {
        comp.pins = comp.pins.filter(pin => pin.net !== name);
      }
    });
  } else {
    const _netIndex = PowerNets.findIndex(item => item.name === '');
    PowerNets[_netIndex].name = netSelect;
    //Is the current net selected by the user
    PowerNets[_netIndex].custom = true;
    PowerNets[_netIndex].value = findPwr.voltage || PowerNets[_netIndex].value;
    //delete reference
    delete PowerNets[_netIndex].reference;
  };
  const nets = PowerNets.map(item => item.name);
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  const { netsList, layers } = pcbInfo;
  const prevPowerComponents = _Interfaces[index].content.powerComponents;
  const COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, pcbId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
  const _compTableHelper = yield call(compTableHelper.getTable, pcbId);
  const compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
  const passiveTable = (_compTableHelper || []).table || [];
  const { newPowerComps, updatedComponents } = getPowerComponents({ nets, pcbNetsList: netsList, layers, pcbId, prevPowerComponents, COMP_PREFIX_LIB, doNotStuff, passiveTable, compPinMap, updateSignalComps: true, components });
  components = updatedComponents || components;
  //JUDGE Pull Up/Pull Down
  const findItem = components.find(comp => RLC_TYPES.includes(comp.type) && comp.pins && comp.pins.find(it => it.net === netSelect))
  const _netIndex = PowerNets.findIndex(item => item.name === netSelect);
  if (findItem) {
    PowerNets[_netIndex].type = "Pull Up/Down";
  } else {
    PowerNets[_netIndex].type = "Reference";
  }

  _Interfaces[index].content.powerComponents = [...newPowerComps];
  _Interfaces[index].content.powerNets = [...PowerNets];
  _Interfaces[index].content.components = [...components];

  const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, _Interfaces[index], pcbInfo, { changeSetup: false });
  yield put(updateGeneratePortsErrors({ id: verificationId, errors, warnings }));
  _Interfaces[index].content.ports_generate_setup_list = ports_generate_setup_list;
  _Interfaces[index].content.referenceNets = referenceNets;
  _Interfaces[index].content.port_setups = port_setups;
  _Interfaces[index].content.components = portComponents;
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: pcbId,
    needMerge: true
  }));
};

function* netVoltageUpdate(action) {
  const { pcb, pcbId, name, value } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  let PowerNets = [..._Interfaces[index].content.powerNets];
  const netIndex = PowerNets.findIndex(item => item.name === name);
  PowerNets[netIndex].value = value;
  _Interfaces[index].content.powerNets = [...PowerNets];

  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: pcbId,
    needMerge: true
  }));
}

function* createConfig(action) {
  const { verificationId } = action;
  const { SierraReducer: { project: { currentProjectId } } } = yield select();
  const config = {
    clock: '1M',
    cycles: '10',
    /*  slewRate: '50n', */    // setup version 3.0.5   remove slewRate
    timeStep: '10n',
    xTalk: 1,
    simulate: 'Default'
  }
  yield call(createPinToPinConfig, { verificationId, config, projectId: currentProjectId });
}

function* editChannelType(action) {
  const { info } = action;
  const { pcb, channel } = info;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  const prevChannelType = _Interfaces[index].content.channel.type;
  _Interfaces[index].content.channel.type = channel;
  let _channel = {
    type: channel,
    fileName: '',
    modelName: '',
    libraryId: '',
  };
  _Interfaces[index].content.channel = _channel;

  if (judgeUpdateGapPortSetup(_Interfaces[index].content, channel)) {
    const data = updateExtractionGapPortsSetup(_Interfaces[index].content, _Interfaces[index].pcbId);
    _Interfaces[index].content = data.newData;
  }

  if (judgeUpdateGapPortGapSizeSetup({
    components: _Interfaces[index].content.components,
    extractionType: _Interfaces[index].content.channel.type,
    prevExtractionType: prevChannelType,
    ports_generate_setup_list: _Interfaces[index].content.ports_generate_setup_list
  })) {
    _Interfaces[index].content.components = updateHFSSExtractionCompsGapSize(_Interfaces[index].content.components, _Interfaces[index].content.ports_generate_setup_list)
  }

  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId: _Interfaces[index].pcbId }));
};

function* updateChannel(action) {
  const { pcb, libraryId, model } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  let channel = {
    type: 'Upload',
    fileName: model.fileName,
    modelName: model.modelName,
    libraryId: libraryId,
  };
  _Interfaces[index].content.channel = channel;
  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId: _Interfaces[index].pcbId }));
};

function* _updateOptions(action) {
  // TODO - refactor
  const { optType, update, fixTimeStep } = action;
  let value = action.value;
  const { SierraReducer: { sierra } } = yield select();
  let Config = { ...sierra.currentConfig };
  if (update === 'value') {
    //clock timeStep cycles
    if (simulationConfigList.includes(optType)) {
      value = toNonExponential(value);
    }
    if (optType === 'cycles' || optType === 'simulate' || optType === 'ngspiceMDFLM') {
      Config[optType] = value;
    } else if (optType === 'clock') {
      Config[optType].value = value;
      if (!fixTimeStep) {
        //Time Step = 1/(100F) where F is frequency in Hz.
        //unit conversion scale
        const scale = unitConversion(0, scaleKeys[Config.clock.unit]);
        //Calculate the time step according to the formula
        let newTimeStep = NP.divide(1, NP.times(value, scale, 100));
        //unit conversion
        //Convert to ps first
        const sScale = unitConversion(-4, 0);
        newTimeStep = NP.strip(NP.times(newTimeStep, sScale));

        //Keep two decimal places
        newTimeStep = NP.round(newTimeStep, 2);

        //Convert ps to current unit
        const pScale = unitConversion(scaleKeys[Config.timeStep.unit], -4);
        newTimeStep = NP.times(newTimeStep, pScale);

        Config.timeStep.value = newTimeStep;
      }

    } else if (optType === 'timeStep') {
      Config[optType].value = value;
    } else if (optType === 'hspiceCores') {
      Config[optType] = Number(value);
    }
  } else if (update === 'unit') {
    let _value = Config[optType].value;
    let prevUnit = Config[optType].unit;
    //value error check
    let error = numberCheck(_value);
    if (error) {
      _value = numConversion(parseFloat(_value));
      if (isNaN(_value)) {
        if (optType === 'clock') {
          _value = 1;//unit is MHz;
          prevUnit = 'M';
        } else if (optType === 'timeStep') {
          _value = 10; //unit is ns
          prevUnit = 'n';
        }
      }
    }
    //Change value according to unit
    const scale = unitConversion(scaleKeys[value], scaleKeys[prevUnit]);
    _value = NP.times(_value, scale);
    _value = toNonExponential(_value);
    Config[optType].value = _value;
    Config[optType].unit = value;
  } else if (update === 'coupling') {
    if (optType === 'coupling') {
      Config.xTalk = value;
    }
  }

  Config.clock = {
    value: Config.clock && Config.clock.value ? Config.clock.value : '',
    unit: Config.clock && Config.clock.unit ? Config.clock.unit : 'M',
  }

  Config.timeStep = {
    value: Config.timeStep && Config.timeStep.value ? Config.timeStep.value : '',
    unit: Config.timeStep && Config.timeStep.unit ? Config.timeStep.unit : 'n',
  }

  yield put(updateCurrentConfig(Config));
  const _config = {
    timeStep: Config.timeStep.value + Config.timeStep.unit,
    xTalk: Config.xTalk !== null ? Config.xTalk : 1,
    cycles: Config.cycles,
    clock: Config.clock.value + Config.clock.unit,
    /*  slewRate: slewRate + 'n', */ //setup version 3.0.5  Remove Slew Rate in json (The simulation engine will figure out the slew rate from the clock speed).
    simulate: Config.simulate,
    ngspiceMDFLM: Config.ngspiceMDFLM,
    hspiceCores: Config.hspiceCores ? Config.hspiceCores : 2,
    fixTimeStep: fixTimeStep || false
  };
  yield call(updatePinToPinConfig, { verificationId: sierra.sierraInfo.verificationId, id: Config.id, config: _config });
}

function* _updateOptionsByClose(action) {
  const { SierraReducer: { sierra } } = yield select();
  let Config = { ...sierra.currentConfig };
  if (!Config) {
    return;
  }
  const timeStep = (Config.timeStep && Config.timeStep.value ? Config.timeStep.value : '') + (Config.timeStep && Config.timeStep.unit ? Config.timeStep.unit : 'n');
  const clock = (Config.clock && Config.clock.value ? Config.clock.value : '') + (Config.clock && Config.clock.unit ? Config.clock.unit : 'M');
  const cycles = Config.cycles ? Config.cycles : '';
  const simulate = Config.simulate ? Config.simulate : {};
  const ngspiceMDFLM = Config.ngspiceMDFLM ? Config.ngspiceMDFLM : '30ps';
  const xTalk = Config.xTalk !== null ? Config.xTalk : 1;
  const hspiceCores = Config.hspiceCores ? Config.hspiceCores : 2
  const _config = {
    timeStep,
    xTalk,
    cycles,
    clock,
    simulate,
    ngspiceMDFLM,
    hspiceCores,
    fixTimeStep: Config.fixTimeStep || false
  };
  yield call(updatePinToPinConfig, { verificationId: sierra.sierraInfo.verificationId, id: Config.id, config: _config });
}

function* saveConnectionGroup(action) {
  const { connections, havePrev, updatePCBIds } = action;
  for (let connection of connections) {
    const { channel1, channel2 } = connection;
    if (updatePCBIds && !updatePCBIds.includes(channel1.designId) && !updatePCBIds.includes(channel2.designId)) {
      continue;
    }
    if (havePrev) {
      yield call(saveConnector, { connection: connection.connection, prevConnection: connection.prevConnection, noNeedUpdate: true })
    } else {
      yield call(saveConnector, { connection, prevConnection: connection, noNeedUpdate: true })
    }
  }
  if (!updatePCBIds) {
    yield put(changeConnectorStatus(true));
  }
}

function* saveConnector(action) {
  const { connection, prevConnection, noNeedUpdate } = action;
  if (!connection) {
    return;
  }
  const { SierraReducer: { sierra } } = yield select();
  //Find signals that have no pcb
  /*   let _info = sierra.sierraInfo.info;
    let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : []; */

  let Interfaces = [...sierra.sierraInfo.Interfaces];

  for (let i = 1; i < 3; i++) {
    const channel = connection[`channel${i}`];
    const anotherChannel = i === 1 ? connection[`channel2`] : connection[`channel1`];
    let interfaceIndex = Interfaces.findIndex(_interface => _interface.pcbId === channel.designId);
    if (interfaceIndex < 0) {
      interfaceIndex = Interfaces.findIndex(_interface => _interface.pcbId === prevConnection[`channel${i}`].designId);
    }
    if (interfaceIndex > -1) {
      const info = connection.connection;
      const connector = info[`connector${i}`] || {};
      const prevConnector = prevConnection.connection[`connector${i}`] || {};
      const another = i === 1 ? info[`connector2`] : info[`connector1`];
      const compIndex = Interfaces[interfaceIndex].content.components.findIndex(comp => comp.name === connector.component);

      if (compIndex < 0) {
        if (i === 1 && !connector.component && !another.component) {
          // If the modified pcb, and comp is not set, add the default option
          let connectorComponentsList = Interfaces[interfaceIndex].content.components.filter(item => item.type === CONNECTOR && (!item.connect || !item.connect.pcbId));
          if (connectorComponentsList.length) {
            const findConnectorIndex = Interfaces[interfaceIndex].content.components.findIndex(comp => comp.name === connectorComponentsList[0].name);
            let connectorComp = Interfaces[interfaceIndex].content.components[findConnectorIndex];
            const _connector1 = { component: connectorComp.name, pins: connectorComp.pins, models: [] };
            Interfaces[interfaceIndex].content.components[findConnectorIndex] = saveConnectorComp({ connectorComp, anotherChannel, another, cableModels: info.cableModels, connector: { ...connector, connector1: _connector1 } });
          }
          //If the corresponding connector comp is not found
        }
      }

      if (compIndex >= 0) {
        let connectorComp = Interfaces[interfaceIndex].content.components[compIndex];
        Interfaces[interfaceIndex].content.components[compIndex] = saveConnectorComp({ connectorComp, anotherChannel, another, cableModels: info.cableModels, connector });
      }

      if (prevConnector.component && connector.component !== prevConnector.component) {
        // If the Comp setting changes, delete the setting in the previous components
        let prevInterfaceIndex = Interfaces.findIndex(_interface => _interface.pcbId === prevConnection[`channel${i}`].designId);
        const prevCompIndex = Interfaces[prevInterfaceIndex].content.components.findIndex(comp => comp.name === prevConnector.component);
        if (prevCompIndex > -1) {
          let connectorComp = Interfaces[prevInterfaceIndex].content.components[prevCompIndex];
          Interfaces[prevInterfaceIndex].content.components[prevCompIndex] = saveConnectorComp({ connectorComp, connector: prevConnector, clear: true })
        }
      }
    }
  }
  const _connectorList = getConnectorList(Interfaces);
  yield put(updateSierraInfo({ Interfaces: Interfaces, connectorList: _connectorList }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
  if (!noNeedUpdate) {
    yield put(changeConnectorStatus(true));
  }

}

function getConnectSignalNet({ resData, compPinsNets, _LayoutData, findConnectData, pcbId, compPinMap, type, compPrefixLib, doNotStuff, powerTableNets = [] }) {
  const { name, pins, part } = resData;
  const data = compPinsNets[name];
  const pinLength = Object.keys(data).length;
  let connectNets = {}
  pins.forEach(item => {
    const { pin, signal } = item;
    if (!connectNets[signal]) {
      connectNets[signal] = []
    }
    let connectPins = "";
    if (type === COMP_REPEATER) {
      const findPart = compPinMap && compPinMap.Repeater ? compPinMap.Repeater.find(it => it.part === part) : null
      connectPins = getConnectedPin({ pinNum: pin, pinLength, pinMap: findPart && findPart.pinMap ? findPart.pinMap : [] })
    } else {
      connectPins = getConnectedPinNumber({ pinNum: pin, pinLength })
    }

    if (connectPins && compPinsNets[name][connectPins]) {
      const connectNet = compPinsNets[name][connectPins].mName;
      if (!isPowerGND(compPinsNets[name][connectPins], null, pcbId) && !powerTableNets.includes(connectNet)) {

        const clkSignalNets = findConnectData.getRelateNetsByName(connectNet, _LayoutData, { compPrefixLib, doNotStuff, compPinMap, powerTableNets })
        connectNets[signal].push(...clkSignalNets)
      }
    }
  })
  return connectNets

}

function* editComponentType(action) {
  const { compType, comps, pcbId } = action;
  const { SierraReducer: { sierra } } = yield select();
  let Interfaces = [...sierra.sierraInfo.Interfaces];
  const _index = Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = Interfaces[_index].content;
  let components = [...editInterface.components],
    powerNets = [...editInterface.powerNets],
    signals = [...editInterface.signals];
  let _type = compType === TEST_POINT_DISPLAY ? TEST_POINT : compType;
  const vendor = designConstructor.getDesignVendor(pcbId);
  let compPinsNets, _LayoutData, findConnectData;
  if (vendor !== PRE_LAYOUT) {
    compPinsNets = LayoutData.getCompPinsNets(pcbId);
    _LayoutData = LayoutData.getLayout(pcbId);
    findConnectData = new FindInterface(pcbId)
  }
  let connectSignalNets = {}, compPinMap = {};
  if (_type === COMP_REPEATER) {
    compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
  }
  const compPrefixLib = yield call(componentSetting.getPrefixLib, pcbId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
  const powerTableNets = vendor !== PRE_LAYOUT ? yield call(powerStore.getTable, pcbId, "netName") : [];
  let isUpdatePwr = false;
  comps.forEach(item => {
    const name = item.name;
    const prevType = item.type;
    const compIndex = components.findIndex(comp => comp.name === name);
    const component = JSON.parse(JSON.stringify(components[compIndex]));
    const { newComp } = componentTypeChange({ type: _type, component });
    components[compIndex] = newComp;
    // update value
    if (vendor !== PRE_LAYOUT) {
      if (!RLCTypes.includes(prevType) && RLCTypes.includes(_type)) {
        isUpdatePwr = true;

        if (compPinsNets && compPinsNets[name]) {
          // get comp connect signal net
          connectSignalNets = getConnectSignalNet({
            resData: item,
            compPinsNets: compPinsNets,
            _LayoutData: _LayoutData,
            findConnectData: findConnectData,
            pcbId,
            compPrefixLib,
            doNotStuff,
            compPinMap,
            powerTableNets
          })
        }

        let compFind = components.find(item => item.name !== name && item.part === component.part && item.type === _type);
        if (compFind) {
          if (Object.prototype.toString.call(compFind.value) === "[object Object]") {
            components[compIndex].value = { ...compFind.value }
          } else {
            components[compIndex].value = compFind.value
          }
        } else {
          components[compIndex].value = getRLCValueByType({ newType: _type, prevValue: component.value, part: components[compIndex].part, addDefault: false });
        }
      } else if (prevType !== COMP_REPEATER && _type === COMP_REPEATER) {
        if (compPinsNets && compPinsNets[name]) {
          // get comp connect signal net
          connectSignalNets = getConnectSignalNet({
            resData: item,
            compPinsNets,
            _LayoutData,
            findConnectData,
            pcbId,
            compPinMap,
            type: COMP_REPEATER,
            compPrefixLib,
            doNotStuff,
            powerTableNets
          })
        }
      } else if (RLCTypes.includes(prevType) && !RLCTypes.includes(_type)) {
        components[compIndex].pins = components[compIndex].pins.filter(it => !!it.signal);
        isUpdatePwr = true;
      }
    }
  });
  Interfaces[_index].content.components = [...components];
  let updateData = {}, _sierraInfo = { ...sierra.sierraInfo }, _errors = [], _warnings = [];
  if (connectSignalNets && Object.keys(connectSignalNets).length) {
    for (let signalInfo of Interfaces[_index].content.signals) {
      const { name } = signalInfo
      if (connectSignalNets[name] && connectSignalNets[name].length) {
        let allNets = [...new Set([...signalInfo.nets, ...connectSignalNets[name]])];
        updateData = yield call(editSignalPromise, { signalData: { nets: allNets, signalName: signalInfo.name, pcbId }, sierraInfo: _sierraInfo, isCompType: true })

        _errors = [..._errors, ...updateData.errors]
        _warnings = [..._warnings, ...updateData.warnings]
      }
    }
  } else if (isUpdatePwr && vendor !== PRE_LAYOUT) {
    //find power net and update component by rlc component
    const pcbInfo = DesignInfo.getPCBInfo(pcbId);
    const { netsList } = pcbInfo;
    const updateComp = yield call(getUpdatePowerNets, {
      components,
      powerNets,
      netsList,
      signals,
      findGND: true,
      update: true,
      designId: pcbId
    });
    Interfaces[_index].content.powerNets = updateComp.powerNets;
    Interfaces[_index].content.components = updateComp.components;
  }
  let connectorList = [], _Interfaces = [], info = {};
  if (updateData && Object.keys(updateData).length) {
    // update Signal net
    info = updateData.info
    connectorList = updateData.connectorList;
    _Interfaces = updateData._Interfaces;
  } else {
    // signal net not change
    //Find signals that have no pcb
    let _info = _sierraInfo.info;
    let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
    const index = Interfaces.findIndex(inter => inter.pcbId === pcbId);
    if (vendor !== PRE_LAYOUT) {
      const pcbInfo = DesignInfo.getPCBInfo(pcbId);
      const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, Interfaces[index], pcbInfo);
      _errors = [...errors];
      _warnings = [...warnings]
      Interfaces[index].content.ports_generate_setup_list = ports_generate_setup_list;
      Interfaces[index].content.referenceNets = referenceNets;
      Interfaces[index].content.port_setups = port_setups;
      Interfaces[index].content.components = portComponents;
    }

    // Update info
    //update connections
    _Interfaces = connectorHandle(Interfaces);
    const SETUP = SierraVerify;
    info = SETUP.mergeInterfacesInfo(_Interfaces, _Interfaces[0].name);
    connectorList = getConnectorList(_Interfaces);
    //add signals that have no pcb
    info.signals = [...info.signals, ..._prevSignal];
  }
  yield put(updateGeneratePortsErrors({ id: sierra.sierraInfo.verificationId, errors: _errors, warnings: _warnings }));

  yield put(updateSierraInfo({ Interfaces: _Interfaces, connectorList }));
  yield put(updateSierraInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
  yield put(changeConnectorStatus(true))
  if (updateData && Object.keys(updateData).length) {
    const delayTime = 5000
    if (autoUpdateRefTask) {
      yield cancel(autoUpdateRefTask);
    }
    updateRefTask = true;
    autoUpdateRefTask = yield fork(debounce, delayTime, updateRefNets);
  }
};

function* editSignalName(action) {
  const { name, prevName } = action;
  const { SierraReducer: { sierra } } = yield select();
  let Interfaces = [...sierra.sierraInfo.Interfaces];
  const _info = sierra.sierraInfo.info;
  //Find signals that have no pcb
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
  let names = [];
  // Check repeat signal name
  Interfaces.forEach(item =>
    names.push(...item.content.signals.map(item => item.name))
  );
  names = [...names, ..._prevSignal.map(item => item.name)];
  if (names.includes(name)) {
    message.error('Signal name cannot be repeated.');
    return;
  };
  yield* Interfaces.map(function* (info) {
    let signal = info.content.signals.find(item => item.name === prevName);
    if (signal) {
      signal.name = name;
      for (let comp of info.content.components) {
        for (let pin of comp.pins) {
          if (pin.signal === prevName) {
            pin.signal = name;
          }
        }
      }

      for (let comp of (info.content.virtualComps || [])) {
        for (let pin of (comp.pins || [])) {
          if (pin.signal === prevName) {
            pin.signal = name;
          }
        }
      }
      // update extraction port
      const vendor = designConstructor.getDesignVendor(info.pcbId);
      if (vendor !== PRE_LAYOUT) {
        const pcbInfo = DesignInfo.getPCBInfo(info.pcbId);
        const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, info, pcbInfo, { changeRef: false, changeSetup: false });
        yield put(updateGeneratePortsErrors({ id: sierra.sierraInfo.verificationId, errors, warnings }));
        info.content.ports_generate_setup_list = ports_generate_setup_list;
        info.content.referenceNets = referenceNets;
        info.content.port_setups = port_setups;
        info.content.components = portComponents;
      }
    }
  });
  Interfaces = connectorHandle(Interfaces);
  // Update info
  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  const connectorList = getConnectorList(Interfaces);
  yield put(updateSierraInfo({ Interfaces: Interfaces, connectorList }))
  yield put(changeConnectorStatus(true));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* deleteSignal(action) {
  const { name } = action;
  const { SierraReducer: { sierra } } = yield select();
  let Interfaces = [...sierra.sierraInfo.Interfaces];
  let sierraInfo = { ...sierra.sierraInfo };
  const infoSignals = sierraInfo.info && sierraInfo.info.signals ? sierraInfo.info.signals : [];
  const findCurrentSignal = infoSignals.find(item => item.name === name);
  //Find signals that have no pcb and are not currently deleted signal
  let _prevSignal = infoSignals.filter(item => item.name !== name && !item.pcb);
  if (findCurrentSignal && findCurrentSignal.pcb) {
    let delPcbIds = [];
    yield* Interfaces.map(function* (info) {
      const vendor = designConstructor.getDesignVendor(info.pcbId);
      let signals = [...info.content.signals];
      let components = [...info.content.components];
      const signal = signals.find(item => item.name === name);
      if (vendor === PRE_LAYOUT) {
        let sameSignalNets = [];
        if (signal && signal.nets && signal.nets.length > 0) {
          const { otherSignalNets, otherSignalAndNetList } = signalNetsFormat(signals, name);
          const nets = signal.nets.filter(net => !otherSignalNets.includes(net));
          sameSignalNets = signal.nets.filter(net => otherSignalNets.includes(net));
          const prelayout = yield call([preLayoutData, preLayoutData.getPreLayout], info.pcbId);
          const _components = prelayout.content.components;
          const filterComps = _components.filter(item => item.pins.some(pin => nets.includes(pin.net)) && !item.pins.some(pin => otherSignalNets.includes(pin.net))).map(item => item.name);
          info.content.signals = signals.filter(item => item.name !== name);
          info.content.components = components.filter(item => !filterComps.includes(item.name));
          info.content.components.forEach(comp => {
            comp.pins = comp.pins.filter(pin => !nets.includes(pin.net));
            //update pin signal (The net deleted by the current signal and the net that other signals exist)
            if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
              comp.pins = updateComponentPinSignal({
                comp,
                sameSignalNets,
                otherSignalNets,
                otherSignalAndNetList,
                signalName: name
              });
            }

            //update component s-parameter model reference
            //removed referenceNet if signal nets includes reference net  
            if (RLCTypes.includes(comp.type) && comp.model && comp.model.type === "Touchstone" && nets.includes(comp.model.referenceNet)) {
              comp.model.referenceNet = "";
            }
            //update repeater model pairs
            if (comp.type === COMP_REPEATER) {
              let pairs = [];
              if (!comp.model || !comp.model.pairs || comp.model.pairs.length === 0) {
                pairs = getPairs({ modelType: comp.type, pins: comp.pins });
              } else {
                pairs = comp.model.pairs;
                const pinNames = comp.pins.map(item => item.pin);
                pairs = pairs.filter(item => pinNames.includes(item.pin));
              }
              comp.model.pairs = pairs;
            }
          });
          if (info.content.signals.length < 1) {
            delPcbIds.push(info.pcbId);
          }
        } else {
          info.content.signals = signals.filter(item => item.name !== name);
          if (info.content.signals.length < 1) {
            delPcbIds.push(info.pcbId);
          }
        }
      } else {
        const pcbInfo = DesignInfo.getPCBInfo(info.pcbId);

        const COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, info.pcbId);
        const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, info.pcbId);
        const _compTableHelper = yield call(compTableHelper.getTable, info.pcbId);
        const compPinMap = yield call(componentSetting.getCompPinMap, info.pcbId);
        const passiveTable = (_compTableHelper || []).table || [];
        const { netsList, layers } = pcbInfo;
        //get other signals nets
        const { otherSignalNets, otherSignalAndNetList } = signalNetsFormat(signals, name);
        //sameSignalNets:[net1,net2,...] the deleted nets exist in other signals
        let sameSignalNets = [];
        if (signal && signal.nets && signal.nets.length > 0) {
          const nets = signal.nets.filter(net => !otherSignalNets.includes(net));
          sameSignalNets = signal.nets.filter(net => otherSignalNets.includes(net));
          const deletedComps = getComponentsWithNetList({ netList: nets, pcbNetsList: netsList, layers, pcbId: info.pcbId, doNotStuff, COMP_PREFIX_LIB, passiveTable, compPinMap });
          const update = deleteCompsConnectWithNets({ signalName: name, deletedComps, components });
          info.content.components = update.components;
          info.content.signals = signals.filter(item => item.name !== name);
          if (info.content.signals.length < 1) {
            delPcbIds.push(info.pcbId);
          }

          info.content.components.forEach(comp => {
            //update pin signal (The net deleted by the current signal and the net that other signals exist)
            if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
              comp.pins = updateComponentPinSignal({
                comp,
                sameSignalNets,
                otherSignalNets,
                otherSignalAndNetList,
                signalName: name
              });
            }

            //update component s-parameter model reference
            //removed referenceNet if signal nets includes reference net  
            if (RLCTypes.includes(comp.type) && comp.model && comp.model.type === "Touchstone" && nets.includes(comp.model.referenceNet)) {
              comp.model.referenceNet = "";
            }
            //update repeater model pairs
            if (comp.type === COMP_REPEATER) {
              let pairs = [];
              if (!comp.model || !comp.model.pairs || comp.model.pairs.length === 0) {
                pairs = getPairs({ modelType: comp.type, pins: comp.pins });
              } else {
                pairs = comp.model.pairs;
                const pinNames = comp.pins.map(item => item.pin);
                pairs = pairs.filter(item => pinNames.includes(item.pin));
              }
              comp.model.pairs = pairs;
            }
          });

          //find power net and update component by rlc component
          const updateComp = yield call(getUpdatePowerNets, {
            components: info.content.components,
            powerNets: info.content.powerNets,
            netsList,
            signals,
            findGND: true,
            update: true,
            designId: info.pcbId
          });
          let updatePowerNets = updateComp.powerNets;
          info.content.components = updateComp.components;

          const _powerNets = updatePowerNets.map(item => item.name);
          //update power components
          const prevPowerComponents = info.content.powerComponents;
          const newPowerComps = getPowerComponents({ nets: _powerNets, pcbNetsList: netsList, layers, pcbId: info.pcbId, prevPowerComponents, doNotStuff, COMP_PREFIX_LIB, passiveTable, compPinMap });
          info.content.powerNets = [...updatePowerNets];
          info.content.powerComponents = [...newPowerComps];

          for (let comp of (info.content.virtualComps || [])) {
            comp.pins = (comp.pins || []).filter(pin => !signal.nets.includes(pin.net));
          }
          info.content.virtualComps = (info.content.virtualComps || []).filter(item => !!item.pins.length);
        } else {
          info.content.signals = signals.filter(item => item.name !== name);
          if (info.content.signals.length < 1) {
            delPcbIds.push(info.pcbId);
          }
        }

        // update extraction port
        const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, info, pcbInfo);
        yield put(updateGeneratePortsErrors({ id: sierraInfo.verificationId, errors, warnings }));
        info.content.ports_generate_setup_list = ports_generate_setup_list;
        info.content.referenceNets = referenceNets;
        info.content.port_setups = port_setups;
        info.content.components = portComponents;
      }
    });
    if (delPcbIds.length > 0) {
      yield* delPcbIds.map(function* (item) {
        const index = Interfaces.findIndex(info => info.pcbId === item);
        if (index > -1) {
          yield call(deleteInterfacePromise, [Interfaces[index].interfaceId]);
          Interfaces.splice(index, 1);
        }
      });
    }

    Interfaces = connectorHandle(Interfaces);
  }


  const SETUP = SierraVerify;
  let interfaceName = null;
  if (Interfaces.length === 0) {
    interfaceName = sierraInfo.info.name;
  } else {
    interfaceName = Interfaces[0].name;
  }

  let info = SETUP.mergeInterfacesInfo(Interfaces, interfaceName);
  //connector list
  const connectorList = getConnectorList(Interfaces);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  yield put(updateSierraInfo({ Interfaces: Interfaces, connectorList }));
  yield put(changeConnectorStatus(true))
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* deletePGNets(action) {
  const { pcb, pcbId, name } = action;
  const { SierraReducer: { sierra, project: { verificationId } } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);

  let PowerNets = [..._Interfaces[index].content.powerNets];
  let components = [..._Interfaces[index].content.components];

  if (!name) {
    //net not exist
    const pwr_index = PowerNets.findIndex(item => !item.name);
    PowerNets.splice(pwr_index, 1);
    _Interfaces[index].content.powerNets = [...PowerNets];
    yield put(updateInterfaces({
      Interfaces: _Interfaces,
      pcb,
      pcbId: pcbId,
      needMerge: true
    }));
    return;
  }

  const vendor = designConstructor.getDesignVendor(pcbId);
  if (vendor === PRE_LAYOUT) {
    return;
  }

  PowerNets = PowerNets.filter(item => item.name !== name);
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  const { netsList, layers } = pcbInfo;
  const nets = PowerNets.map(item => item.name);
  const prevPowerComponents = _Interfaces[index].content.powerComponents;
  const COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, pcbId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
  const compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
  const _compTableHelper = yield call(compTableHelper.getTable, pcbId);
  const passiveTable = (_compTableHelper || []).table || [];
  const newPowerComps = getPowerComponents({ nets, pcbNetsList: netsList, layers, pcbId, prevPowerComponents, COMP_PREFIX_LIB, doNotStuff, passiveTable, compPinMap });
  components = updateComponentsByPowerNets({ PowerNets, components });
  _Interfaces[index].content.powerNets = [...PowerNets];
  _Interfaces[index].content.powerComponents = [...newPowerComps];

  //update components RLC S-parameter model reference
  components.forEach(comp => {
    //removed referenceNet if reference net === removed power/gnd net
    if (name && RLCTypes.includes(comp.type) && comp.model && comp.model.type === "Touchstone" && comp.model.referenceNet === name) {
      comp.model.referenceNet = "";
    }
  });

  _Interfaces[index].content.components = [...components];
  const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, _Interfaces[index], pcbInfo, { changeSetup: false });
  yield put(updateGeneratePortsErrors({ id: verificationId, errors, warnings }));
  _Interfaces[index].content.ports_generate_setup_list = ports_generate_setup_list;
  _Interfaces[index].content.referenceNets = referenceNets;
  _Interfaces[index].content.port_setups = port_setups;
  _Interfaces[index].content.components = portComponents;

  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: pcbId,
    needMerge: true
  }));
};

function* saveRepeaterModel(action) {
  const { model, name, pcb, pcbId } = action;
  //model:{files,pairs}
  //name component name
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = [...editInterface.components];
  const compIndex = components.findIndex(comp => comp.name === name);
  //save model
  if (compIndex > -1) {
    components[compIndex].model = model ? JSON.parse(JSON.stringify(model)) : components[compIndex].model;
  }
  _Interfaces[index].content.components = [...components];
  //update library file error message in current interfaces
  let verificationId = sierra.sierraInfo.verificationId;
  let interfaceLibraryError = sierra.interfaceLibraryError ? sierra.interfaceLibraryError : [];
  interfaceLibraryError = updateLibraryMsg(interfaceLibraryError, verificationId, "repeater", { component: name, pcb });
  yield put(updateInterfaceLibraryMsg(interfaceLibraryError));

  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: pcbId
  }));
}

function* savePowerOff(action) {
  const { record: { component, signal, pin, net, pcb, pcbId }, powerOff, applyAll } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
  let editingInterface = _Interfaces[_InterfaceIndex];
  // // All exist signal net name
  let components = [...editingInterface.content.components];

  const compIndex = components.findIndex(item => item.name === component);
  const comp = components[compIndex];
  if (applyAll) {//apply powerOff to all pins of same component
    components[compIndex].pins.forEach(pinItem => {
      pinItem.powerOff = powerOff;
    })
  } else {
    const pinModelIndex = comp.pins.findIndex(item =>
      item.net === net && item.pin === pin && item.signal === signal
    );
    components[compIndex].pins[pinModelIndex].powerOff = powerOff;
  }

  editingInterface.content.components = [...components];
  _Interfaces[_InterfaceIndex] = { ...editingInterface };
  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId }));
}

function* saveChipModel(action) {
  const { libType, files, pairs, pcb, pcbId, name, applyModelObj } = action;
  //name component name
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = [...editInterface.components];
  const compIndex = components.findIndex(comp => comp.name === name);
  const prevModel = components[compIndex].model;
  const prevModelFile = prevModel && prevModel.files && prevModel.files[0] ? prevModel.files[0] : {};
  //update component model
  const prevLibType = prevModelFile ? prevModelFile.type : "";
  const currentModelFile = files && files[0] ? files[0] : {};
  const id = currentModelFile ? currentModelFile.libraryId : "";
  const model = libType === MultiPinSPICE ? { files, pairs } : { files }
  components[compIndex].model = {
    ...model
  }
  components[compIndex].applyModelObj = applyModelObj || {};

  //clear pins model
  if (
    prevLibType !== libType
    ||
    (libType !== MultiPinSPICE &&
      ((!id && prevModelFile.libraryId) || (id && prevModelFile.libraryId && id !== prevModelFile.libraryId)))
  ) {
    components[compIndex].pins.forEach(item => {
      if (libType === MultiPinSPICE) {
        item.model = { libType };
      } else {
        item.model = {};
        item.pinModels = [];
      }
    });
  }

  // update pkg by chosen component
  const FILE = files && files[0];

  if (components[compIndex].pkg.type === 'IBIS' && (!id || components[compIndex].pkg.libraryId !== id)) {
    //if ibis file changed, remove ibis pkg model
    components[compIndex].pkg = { type: 'None' }
  }
  // auto assign pkg
  if (libType === 'IBIS' && applyModelObj && applyModelObj[APPLY_PKG] && id) {
    components[compIndex] = autoAssignIBISPkg(prevModelFile, components[compIndex])
  }

  const userDefaultSetting = yield call(userDefaultSettings.getSettings);
  const sierraSettings = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings : null;
  const ioBufferUsage = sierraSettings && sierraSettings.ioBufferUsage ? sierraSettings.ioBufferUsage : null;
  // assign IBIS models
  const currentComp = components[compIndex];
  if (libType === 'IBIS' && FILE && FILE.libraryId && FILE.component && applyModelObj && applyModelObj[AUTO_ASSIGN_MODEL]) {
    components[compIndex] = yield call(assignIBISModels, { libraryId: FILE.libraryId, version: FILE.version, currentComp, ioBufferUsage })
  } else if (!FILE || !FILE.libraryId || !FILE.component || libType !== 'IBIS') {
    // CLEAR: Clear corresponding pinInfos when IBIS file is not exist.
    const CLEAR = true
    if (CLEAR) components[compIndex] = yield call(clearModels, currentComp)
  }



  _Interfaces[index].content.components = [...components];
  _Interfaces[index].content.applyModelObj = applyModelObj || {};

  if (
    libType === "IBIS"
    && FILE && FILE.libraryId
    && applyModelObj
    && (applyModelObj[APPLY_MODEL_TO_SETUP]
      || applyModelObj[APPLY_MODEL_TO_ALL_INTERFACES]
      || applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES])) {
    //apply ibis model to all ic components
    //apply and auto assign ibis model to all same parts components
    _Interfaces = yield call(applyModelToAllComponents, { file: FILE, Interfaces: _Interfaces, applyModelObj, part: components[compIndex].part, pcbId, ioBufferUsage })
  }


  //update library file error message in current interfaces
  let verificationId = sierra.sierraInfo.verificationId;
  let interfaceLibraryError = sierra.interfaceLibraryError ? sierra.interfaceLibraryError : [];
  const updateIndex = interfaceLibraryError.findIndex(item => item.verificationId === verificationId);
  if (updateIndex > -1) {
    let currentLibraryError = interfaceLibraryError[updateIndex];
    if (currentLibraryError && currentLibraryError.error) {
      let error = currentLibraryError.error;
      if (error && error.model && error.model.length > 0) {
        let updatePin = []
        error.model.forEach(item => {
          if (item.pcb === pcb && item.compName === name) {
            updatePin.push(item.pin)
          }
        })

        updatePin.forEach(pin => {
          let findIndex = error.model.findIndex(item => item.pin === pin);
          if (findIndex > -1) {
            interfaceLibraryError[updateIndex].error.model.splice(findIndex, 1);
            if (interfaceLibraryError[updateIndex].error.model.length === 0) {
              delete interfaceLibraryError[updateIndex].error.model;
            }
            if (Object.keys(interfaceLibraryError[updateIndex].error).length === 0) {
              interfaceLibraryError = interfaceLibraryError.filter(item => item.verificationId !== verificationId);
            }
          }
        })

        if (updatePin.length > 0) {
          yield put(updateInterfaceLibraryMsg(interfaceLibraryError));
        }
      }

      if (error && error.stimuli && error.stimuli.length > 0) {
        let updatePin = []
        error.stimuli.forEach(item => {
          if (item.pcb === pcb && item.compName === name) {
            updatePin.push(item.pin);
          }
        })

        updatePin.forEach(pin => {
          let findIndex = error.stimuli.findIndex(item => item.pin === pin);
          if (findIndex > -1) {
            interfaceLibraryError[updateIndex].error.stimuli.splice(findIndex, 1);
            if (interfaceLibraryError[updateIndex].error.stimuli.length === 0) {
              delete interfaceLibraryError[updateIndex].error.stimuli;
            }
            if (Object.keys(interfaceLibraryError[updateIndex].error).length === 0) {
              interfaceLibraryError = interfaceLibraryError.filter(item => item.verificationId !== verificationId);
            }
          }
        })

        if (updatePin.length > 0) {
          yield put(updateInterfaceLibraryMsg(interfaceLibraryError));
        }
      }
    }
  }

  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: pcbId
  }));
}

function* saveRLCModel(action) {
  const { model, pcb, pcbId, value, part, comps } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = [...editInterface.components];
  const names = comps.map(item => item.name);//type is not same
  if (Array.isArray(components)) {
    components.forEach(item => {
      if (item.part === part && names.includes(item.name)) {
        if (model.type === 'value') {
          item.value = value;
        }
        item.model = { ...model };
      }
    })
  }

  _Interfaces[index].content.components = [...components];
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId
  }));
}

function* saveComponentPkgModel(action) {
  const { pkg, component, pcb, pcbId } = action;
  const { SierraReducer: { sierra: { sierraInfo, TouchstoneStatusList }, project: { verificationId } } } = yield select();
  if (!sierraInfo || !sierraInfo.Interfaces) {
    return;
  }
  let _Interfaces = [...sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = [...editInterface.components];
  if (Array.isArray(components)) {
    components.forEach((item, index) => {
      if (item.name === component) {
        item.pkg = { ...pkg };
      }
    })
  }

  const statusIndex = TouchstoneStatusList.findIndex(it => it.verificationId === verificationId);
  if (statusIndex > -1) {
    yield put(updateTouchstoneMacroModelingStatus({ Components: components, verificationId: verificationId }));
  }

  _Interfaces[index].content.components = [...components];
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId
  }));
}

function* updateCompComponent(action) {
  const { chips, checked, pcb, pcbId, _type, isPower } = action;
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();

  if (!sierraInfo || !sierraInfo.Interfaces) return;

  let _Interfaces = [...sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = isPower ? [...editInterface.powerComponents] : [...editInterface.components];
  let powerNets = [...editInterface.powerNets], signals = [...editInterface.signals];
  const powerNetNames = powerNets.map(item => item.name);
  let needMerge = false;
  if (Array.isArray(components)) {
    components.forEach(item => {
      if (chips.includes(item.name)) {
        item.type = checked ? _type : UNUSED;
        //remove power pin in unStuffed component 
        if (item.type === UNUSED) {
          item.pins = item.pins.filter(pin => {
            if (pin.net && powerNetNames.includes(pin.net) && !item.signal) {
              return false
            }
            return true;
          })
        }
      }
    })

    if (!isPower && !designConstructor.isPreLayout(pcbId)) {
      //find power net and update component by rlc component
      const pcbInfo = DesignInfo.getPCBInfo(pcbId);
      const { netsList } = pcbInfo;
      const updateComp = yield call(getUpdatePowerNets, {
        components,
        powerNets,
        netsList,
        signals,
        findGND: true,
        update: true,
        designId: pcbId
      });
      _Interfaces[index].content.powerNets = updateComp.powerNets;
      _Interfaces[index].content.components = updateComp.components;
      needMerge = true;
    }
  }
  if (isPower) {
    _Interfaces[index].content.powerComponents = [...components];
  } else {
    _Interfaces[index].content.components = [...components];
  }

  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId,
    needMerge
  }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveSplitComponents(action) {
  const { part, splitPart, comps, pcb, pcbId, isPower } = action;
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();

  if (!sierraInfo || !sierraInfo.Interfaces) return;

  let _Interfaces = [...sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = isPower ? [...editInterface.powerComponents] : [...editInterface.components];
  if (Array.isArray(components)) {
    const partList = components.map(item => item.part);
    let compNames = comps.map(item => item.name)
    if (splitPart && !partList.includes(splitPart)) {
      components.forEach(comp => {
        if (comp.part === part && compNames.includes(comp.name)) {
          comp.part = splitPart;
        }
      })
    } else if (splitPart && partList.includes(splitPart)) {
      let currentSplit = components.filter(item => item.part === splitPart);
      components.forEach(comp => {
        if (comp.part === splitPart && compNames.includes(comp.name)) {
          comps.part = splitPart;
          comps.model = currentSplit[0].model;
          comps.value = currentSplit[0].value;
          comps.modelName = currentSplit[0].modelName;
        }
      })
    }
  }
  if (isPower) {
    _Interfaces[index].content.powerComponents = [...components];
  } else {
    _Interfaces[index].content.components = [...components];
  }
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId
  }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveMergeComponents(action) {
  const { part, partList, pcb, pcbId, isPower } = action;
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();

  if (!sierraInfo || !sierraInfo.Interfaces) return;

  let _Interfaces = [...sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = isPower ? [...editInterface.powerComponents] : [...editInterface.components];
  let current = components.filter(item => item.part === part);
  if (Array.isArray(components)) {
    components.forEach(comp => {
      if (partList.includes(comp.part)) {
        comp.part = part;
        comp.value = current[0].value;
        comp.model = current[0].model;
      }
    });
  }
  if (isPower) {
    _Interfaces[index].content.powerComponents = [...components];
  } else {
    _Interfaces[index].content.components = [...components];
  }
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId
  }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* _savePinsModel(action) {
  const { model: { files, pairs }, record: { pin, usage, component, pcb } } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  const editInterface = _Interfaces[index].content;
  let components = [...editInterface.components];
  const compIndex = components.findIndex(item => item.name === component);
  const pins = components[compIndex].pins;
  pins.forEach(pin => {
    const currentPin_in = pairs.find(item => item.pin === `${pin.pin}_in`);
    if (pin.usage === usage && currentPin_in) {
      let pinModels = getPins({ type: MultiPinSPICE, usage });
      const pinIndex = pinModels.findIndex(item => item.pinName === 'nd_in');
      pinModels[pinIndex].type = MultiPinSPICE;
      const file = files.find(item => item.libraryId === currentPin_in.libraryId);
      pinModels[pinIndex].stimulus = {
        ...currentPin_in,// libraryId, fileName, subckt, pin, node
        folder: file && file.folder ? file.folder : ""
      }
      pin.pinModels = pinModels;
    }
  });
  components[compIndex].pins = [...pins];
  _Interfaces[index].components = [...components];

  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb,
    pcbId: _Interfaces[index].pcbId
  }));

  //update library file error message in current interfaces
  let verificationId = sierra.sierraInfo.verificationId;
  let interfaceLibraryError = sierra.interfaceLibraryError ? sierra.interfaceLibraryError : [];
  interfaceLibraryError = updateLibraryMsg(interfaceLibraryError, verificationId, "stimuli", { component, pcb, pin });
  yield put(updateInterfaceLibraryMsg(interfaceLibraryError));
}

function* _updateCompProbePins(action) {
  const { probePinsList, pcbId, part } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[index].content;
  let components = [...editInterface.components];
  components.forEach(comp => {
    if (comp.part === part) {
      const compIndex = probePinsList.findIndex(item => comp.name === item.component);
      if (compIndex > -1) {
        const probePins = probePinsList[compIndex].pins.filter(item => !!item.selected);
        const probePinNames = probePins.map(item => item.pin);
        comp.probePins = [...probePinNames];
      } else {
        comp.probePins = [];
      }
    }
  })

  _Interfaces[index].components = [...components];
  yield put(updateInterfaces({
    Interfaces: _Interfaces,
    pcb: _Interfaces[index].pcb,
    pcbId
  }));
}

function* getSignals() {
  const { SierraReducer: { sierra } } = yield select();
  //Find signals that have no pcb
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  if (!_Interfaces || !_Interfaces.length) {
    return { pcbIds: [], signals: [] };
  }

  const pcbIds = _Interfaces.map(item => item.pcbId);
  let signals = [];
  _Interfaces.forEach(info => {
    const isPreLayout = designConstructor.isPreLayout(info.pcbId);
    if (!isPreLayout && info && info.content && info.content.signals) {
      const _signals = info.content.signals.filter(item => item.nets && item.nets.length);
      if (_signals.length) {
        signals.push({
          pcbId: info.pcbId,
          signals: JSON.parse(JSON.stringify(_signals))
        })
      }
    }
  })
  if (!signals || !signals.length) {
    return { pcbIds: [], signals: [] };
  }
  return { pcbIds, signals }
}

let findRefNetTaskList = [];
function* updateRefNets() {
  const { SierraReducer: { sierra } } = yield select();
  if (!sierra.sierraInfo || !sierra.sierraInfo.Interfaces || !sierra.sierraInfo.Interfaces.length) {
    return;
  }
  //Find signals that have no pcb
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  if (!_Interfaces || !_Interfaces.length) {
    return;
  }

  const { pcbIds, signals } = yield call(getSignals);

  if (!pcbIds || !pcbIds.length || !signals || !signals.length) {
    return;
  }

  yield put(updateGetRefNetsLoading({ loading: true }));

  //signals :[ { pcbId, signals:[ { name, nets:[] } ] } ]
  yield* pcbIds.map(function* (item) {
    //cancel prev current pcb task
    const index = findRefNetTaskList.findIndex(it => it.pcbId === item);
    if (index > -1 && findRefNetTaskList[index] && findRefNetTaskList[index].findRefNetTask) {
      yield cancel(findRefNetTaskList[index].findRefNetTask);
    }

    //new task
    const currentSignals = signals.find(it => it.pcbId === item);
    findRefNetTaskList.push({
      pcbId: item,
      findRefNetTask: yield fork(findRefNets, { pcbIds: [item], signals: [currentSignals] })
    });
  })
}

function* findRefNets(action) {
  const { pcbIds, signals, isSave } = action;
  let key = null;
  try {
    key = yield call(findReferenceNetsBySignal, { signals });
  } catch (error) {
    console.error(error);
    yield put(updateGetRefNetsLoading({ loading: false }))
    return
  }
  if (!key) {
    yield put(updateGetRefNetsLoading({ loading: false }))
    return;
  }
  yield delay(1000);
  let res = null
  try {
    res = yield call(getReferenceNetsBySignal, { key });
  } catch (error) {
    console.error(error);
    yield put(updateGetRefNetsLoading({ loading: false }))
    return
  }

  let { status = 0, data = null } = res ? res : {};
  while (status === FIND_REF_NET_RUNNING) {
    yield delay(2000);
    try {
      res = yield call(getReferenceNetsBySignal, { key });
    } catch (error) {
      console.error(error);
      yield put(updateGetRefNetsLoading({ loading: false }))
      break;
    }
    if (!res) {
      // failed
      yield put(updateGetRefNetsLoading({ loading: false }))
      break;
    }
    status = res.status;
    data = res.data;
  }

  if (status === FIND_REF_NET_FAILED) {
    //failed
    yield put(updateGetRefNetsLoading({ loading: false }));
    return;
  }

  if (!data || !Array.isArray(data)) {
    //failed
    yield put(updateGetRefNetsLoading({ loading: false }));
    return;
  }

  if (status === FIND_REF_NET_SUCCESS) {
    if (isSave) {
      const _Interfaces = yield call(updateRefNetsToPowerNets, { pcbIds, data, isSave });
      return _Interfaces;
    } else {
      yield put(updateInterfaceRefNets({ pcbIds, data }));
    }
  }
}

function* updateRefNetsToPowerNets(action) {
  const { pcbIds, data, isSave } = action;
  const { SierraReducer: { sierra, project: { pcbComponentsNets } } } = yield select();
  //Find signals that have no pcb

  if (!sierra.sierraInfo || !sierra.sierraInfo.info) {
    return
  }
  let info = sierra.sierraInfo.info;
  let _prevSignal = info && info.signals ? info.signals.filter(item => !item.pcb) : [];
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  const { pcbIds: _pcbIds, signals: _signals } = yield call(getSignals);
  if (!_pcbIds || !_pcbIds.length || !_signals || !_signals.length) {
    return;
  }
  for (let pcbId of pcbIds) {
    const vendor = designConstructor.getDesignVendor(info.pcbId);
    if (vendor === PRE_LAYOUT) {
      continue;
    }

    const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
    if (_InterfaceIndex < 0) {
      continue;
    }
    let editingInterface = _Interfaces[_InterfaceIndex];
    if (!editingInterface || !editingInterface.content || !editingInterface.content.signals.length) {
      continue;
    }
    let powerNets = editingInterface.content.powerNets ? [...editingInterface.content.powerNets] : [];
    const components = editingInterface.content.components ? [...editingInterface.content.components] : [];
    let compPowerNets = [];
    for (let comp of components) {
      if (!RLCTypes.includes(comp.type)) {
        continue;
      };
      for (let pin of comp.pins) {
        // power,ground nets
        if (!pin.signal) {
          compPowerNets.push(pin.net);
        }
      }
    };

    // find current pcb signal nets
    let allSignalNets = []
    if (editingInterface.content.signals && editingInterface.content.signals.length) {
      for (let signal of editingInterface.content.signals) {
        const { nets } = signal;
        if (nets.length) {
          allSignalNets = [...allSignalNets, ...nets]
        }
      }
    }

    let currentPcbRefNets = data.find(item => item.pcbId === pcbId);
    if (!currentPcbRefNets || !Array.isArray(currentPcbRefNets.nets)) {
      continue;
    }
    currentPcbRefNets.nets = currentPcbRefNets ? currentPcbRefNets.nets.filter(item => !allSignalNets.includes(item)) : [];
    //reference and not includes rlc components power nets
    const refNets = powerNets.filter(item => !!item.reference && !compPowerNets.includes(item.name));
    //find deleted reference nets name
    let deleteRefNets = [];
    refNets.forEach(item => {
      const find = currentPcbRefNets.nets.find(it => it === item.name);
      if (!find) {
        deleteRefNets.push(item.name);
      }
    })
    //filter deleted ref nets
    powerNets = powerNets.filter(item => !deleteRefNets.includes(item.name));
    //update power nets(add new reference nets) 
    currentPcbRefNets.nets.forEach(item => {
      const index = powerNets.findIndex(net => net.name === item);
      if (index > -1) {
        powerNets[index].reference = true;
        if (powerNets[index].custom) {
          delete powerNets[index].custom;
        }
      } else {
        powerNets.push({
          name: item,
          value: "",
          reference: true,
          type: 'Reference'
        })
      }
    });
    _Interfaces[_InterfaceIndex].content.powerNets = [...powerNets];
    //update power components
    const prevPowerComponents = _Interfaces[_InterfaceIndex].content.powerComponents;
    let pcbsLayoutDB = [...pcbComponentsNets];
    let pcbInfo = null;
    if (!pcbComponentsNets.includes(pcbId)) {
      yield call(getLayoutDB, pcbId, true);
      const _DesginData = LayoutData.getLayout(pcbId);
      pcbInfo = {
        netsList: [..._DesginData.mNetManager.mNetList.mVals],
        layers: [..._DesginData.mLayerMgr.mMetalLayers]
      };
      pcbsLayoutDB.push(pcbId);
      DesignInfo.savePCBInfo(pcbId, pcbInfo);
      yield put(updatePCBComponentsNets(pcbsLayoutDB));
    } else {
      pcbInfo = DesignInfo.getPCBInfo(pcbId);
    }
    const { netsList, layers } = pcbInfo;
    let _powerNets = powerNets.map(item => item.name);
    const COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, pcbId);
    const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
    const compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
    const _compTableHelper = yield call(compTableHelper.getTable, pcbId);
    const passiveTable = (_compTableHelper || []).table || [];
    const newPowerComps = getPowerComponents({ nets: _powerNets, pcbNetsList: netsList, layers, pcbId, prevPowerComponents, COMP_PREFIX_LIB, doNotStuff, passiveTable, compPinMap });
    _Interfaces[_InterfaceIndex].content.powerComponents = [...newPowerComps];
  }
  const _name = sierra.sierraInfo.info.name;
  const SETUP = SierraVerify;
  info = SETUP.mergeInterfacesInfo(_Interfaces, _name);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  yield put(updateSierraInfo({ Interfaces: _Interfaces }));
  yield put(updateGetRefNetsLoading({ loading: false }));
  if (isSave) {
    return _Interfaces;
  } else {
    yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: pcbIds, updateInitialized: true });
  }
}

function* saveExtraction(action) {
  let { extraction, pcb, channel, apply } = action;
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];
  //Find signals that have no pcb
  if (!_Interfaces.length) return;
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  if (index < 0) return;
  let pcbs = [];

  if (apply) {
    yield* _Interfaces.map((item) => {

      //update extraction
      if (!extraction) {
        item.content.extraction = new Extraction();
      } else {
        item.content.extraction = { ...extraction };
      }

      if (item.pcbId !== pcb) {
        // If these two are different, update the channel type
        const prevChannelType = JSON.parse(JSON.stringify(item.content.channel.type));
        const vendor = designConstructor.getDesignVendor(item.pcbId);
        if ((channel === 'Default' && vendor !== PRE_LAYOUT) || vendor !== PRE_LAYOUT) {
          if (channel !== prevChannelType) {
            // extraction type different
            let newChannel = {
              type: channel,
              fileName: '',
              modelName: '',
              libraryId: '',
            };

            item.content.channel = newChannel;
            if (vendor !== PRE_LAYOUT) {
              if (judgeUpdateGapPortSetup(item.content, channel)) {
                const data = updateExtractionGapPortsSetup(item.content, item.pcbId);
                item.content = data.newData;
              }

              let data = {
                referenceNets: item.content.referenceNets,
                port_setups: item.content.port_setups,
                ports_generate_setup_list: item.content.ports_generate_setup_list
              }
              if (channel === 'PowerSI' && judgeUpdatePowerSISetup(item.content.ports_generate_setup_list)) {
                item.content.ports_generate_setup_list = getDefaultPortsGenerateSetupList({ components: item.content.components });
                data = getExtractionPortsSetup({ ...data, ports_generate_setup_list: item.content.ports_generate_setup_list }, item.pcbId);
                item.content = { ...item.content, ...data.newData };
              }

              if (judgeUpdateGapPortGapSizeSetup({
                components: item.content.components,
                extractionType: item.content.channel.type,
                prevExtractionType: prevChannelType,
                ports_generate_setup_list: item.content.ports_generate_setup_list
              })) {
                item.content.components = updateHFSSExtractionCompsGapSize(item.content.components, item.content.ports_generate_setup_list)
              }
            }
          }
        }
      }
      return item
    })
    pcbs = _Interfaces.map(item => item.pcbId)
  } else {
    //update extraction
    if (!extraction) {
      _Interfaces[index].content.extraction = new Extraction();
    } else {
      _Interfaces[index].content.extraction = { ...extraction };
    }
    pcbs = [_Interfaces[index].pcbId];
  }

  let data = {
    referenceNets: _Interfaces[index].content.referenceNets,
    port_setups: _Interfaces[index].content.port_setups,
    ports_generate_setup_list: _Interfaces[index].content.ports_generate_setup_list
  }
  if (channel === 'PowerSI' && judgeUpdatePowerSISetup(_Interfaces[index].content.ports_generate_setup_list)) {
    _Interfaces[index].content.ports_generate_setup_list = getDefaultPortsGenerateSetupList({ components: _Interfaces[index].content.components });
    data = getExtractionPortsSetup({ ...data, ports_generate_setup_list: _Interfaces[index].content.ports_generate_setup_list }, _Interfaces[index].pcbId);
    _Interfaces[index].content = { ..._Interfaces[index].content, ...data.newData };
  }

  yield call(_updateMultiInterfaces, { Interfaces: _Interfaces, pcbs: [...pcbs] })
}

function* doReExtraction(action) {
  const { interfaceId, ifDoExtraction, isMultiSetup = false } = action;
  try {
    yield call(reExtraction, interfaceId, ifDoExtraction);
    if (!isMultiSetup) {
      const { SierraReducer: { sierra } } = yield select();
      let Interfaces = sierra && sierra.sierraInfo && sierra.sierraInfo.Interfaces && sierra.sierraInfo.Interfaces.length ? [...sierra.sierraInfo.Interfaces] : [];
      let index = Interfaces.findIndex(item => item.interfaceId === interfaceId);
      if (index > -1) {
        Interfaces[index].ifDoExtraction = ifDoExtraction;
      }
      yield put(updateSierraInfo({ Interfaces: Interfaces }));
    }
  } catch (error) {
    console.error(error)
  }
}

export function getLayoutDB(id, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    LayoutData.LoadLayoutDB(id, onlyCompLayer).then(res => {
      resolve(true);
    }, error => {
      resolve(true);
    })
  })
}

function* saveCurrentVerificationToServer(action) {
  const { verificationIds, currentVerificationId } = action;
  //save current verification json
  try {
    let _verificationIds = [...verificationIds];
    //Get current reference nets that need to be updated                                        
    let timeInterVal = getCountTime();
    let isUpdateRefNets = timeInterVal ? true : false;
    //clear count update reference nets time
    closeCountTime();
    timeInterVal = null;
    if (updateRefTask) {
      isUpdateRefNets = true;
    }
    //null:pcbs, isUpdateRefNets
    const response = yield call(saveVerificationContentToServer, { pcbs: null, isUpdateRefNets });
    if (response) {
      //check verification status
      const promise = yield call(checkVerificationStatus, currentVerificationId);
      if (promise && promise.status) {
        if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
          //running or waiting return
          _verificationIds = _verificationIds.filter(item => item !== currentVerificationId);
        }
      };
      if (!_verificationIds.length) {
        return;
      }
      yield put(updateStimulationMessage(currentVerificationId));
      yield put(startSierraVerification(verificationIds, currentVerificationId));
    }
  } catch (error) {
    console.error(error)
  }
}

function* verificationDebugVerify(action) {
  const { step, verificationId } = action;
  //save current verification json
  const response = yield call(saveVerificationContentToServer, {});
  if (response) {
    yield put(debugVerify(step, verificationId));
  }
}

function* updateInterfaceLibrary(action) {
  const { libraryType, libraryFile } = action;
  //library file exist check
  const { SierraReducer: { project: { verificationId }, sierra: { sierraInfo, interfaceLibraryError } } } = yield select();
  if (!sierraInfo || !sierraInfo.Interfaces || sierraInfo.Interfaces.length === 0) {
    return;
  }
  //Find signals that have no pcb
  let _info = sierraInfo.info;
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
  let _Interfaces = sierraInfo.Interfaces;
  let _interfaceLibraryError = interfaceLibraryError ? [...interfaceLibraryError] : [];
  let libraryError = _interfaceLibraryError.find(item => item.verificationId === verificationId);
  let currentLibraryError = libraryError ? libraryError.error : {};
  _Interfaces.forEach(info => {
    if (info.content) {
      let components = info.content.components ? [...info.content.components] : [];
      components.forEach(comp => {
        //check ibis/spice model
        if ((libraryType === 'ibis' || libraryType === 'spice') && ICTypes.includes(comp.type)) {
          if (comp.model && comp.model.files && comp.model.files.length) {
            comp.model.files.forEach(item => {
              if (item.libraryId === libraryFile.id) {
                const text = item.folder ? `${item.folder}::${item.fileName}` : item.fileName;
                let currentError = {
                  pcb: info.pcb,
                  compName: comp.name,
                  pin: "",
                  error: <p style={{ margin: 0 }}>Model "<span className="font-bold">{info.pcb}::{comp.name}</span>" {libraryType} file <span className="font-bold">{text}</span> has been deleted.</p>
                }

                currentLibraryError.model = currentLibraryError.model ? [...currentLibraryError.model, currentError] : [currentError]
                item.libraryId = "";
                item.fileName = "";
                if (item.type === MultiPinSPICE) {
                  item.folder = "";
                  item.subckt = "";
                }
              }
            });
            comp.model && comp.model.pairs && comp.model.pairs.forEach(item => {
              if (item.node && item.libraryId === libraryFile.id) {
                item.libraryId = "";
                item.fileName = "";
                item.node = "";
                item.subckt = "";
              }
            })
          }
          if (comp.pkg && comp.pkg.libraryId === libraryFile.id) {
            comp.pkg = { type: 'None' }
            let currentError = {
              pcb: info.pcb,
              compName: comp.name,
              error: <p style={{ margin: 0 }}>Package "<span className="font-bold">{comp.name}</span>" {libraryType} file <span className="font-bold">{libraryFile.fileName}</span> has been deleted.</p>
            };
            currentLibraryError.model = currentLibraryError.model ? [...currentLibraryError.model, currentError] : [currentError];
          }

          comp.pins.forEach(pin => {

            //check ibis/spice model
            let currentModel = pin.model ? pin.model : {};
            if (currentModel.libraryId === libraryFile.id || currentModel.fileName === libraryFile.fileName) {
              pin.model = new Model({});
              pin.pinModels = [];
              pin.powerOff = "0";
              //add error message
              let currentError = {
                pcb: info.pcb,
                compName: comp.name,
                pin: pin.pin,
                error: <p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{info.pcb}::{comp.name}/{pin.pin}</span>" {libraryType} file <span className="font-bold">{libraryFile.fileName}</span> has been deleted.</p>
              }
              currentLibraryError.model = currentLibraryError.model ? [...currentLibraryError.model, currentError] : [currentError]
            }
          })
        }

        //check ibis/spice model
        if (libraryType === 'vector' && ICTypes.includes(comp.type)) {
          comp.pins.forEach(pin => {

            //check stimulus
            pin.pinModels && pin.pinModels.forEach(item => {
              if (item.stimulus && typeof (item.stimulus) === 'object') {
                let currentStimuli = { ...item.stimulus };
                if (currentStimuli.libraryId === libraryFile.id) {
                  //add error message
                  const text = currentStimuli.folder ? `${currentStimuli.folder}::${currentStimuli.fileName}` : currentStimuli.fileName;
                  let currentError = {
                    pcb: info.pcb,
                    compName: comp.name,
                    pin: pin.pin,
                    error: <p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{info.pcb}::{comp.name}/{pin.pin}</span>" vector file <span className="font-bold">{text}</span> has been deleted.</p>
                  }
                  item.stimulus = "";
                  currentLibraryError.stimuli = currentLibraryError.stimuli ? [...currentLibraryError.stimuli, currentError] : [currentError]
                }
              }
            })
          })
        }

        //repeater file check
        if (libraryType === 'repeater' && comp.type === COMP_REPEATER && comp.model) {
          let files = comp.model.files ? comp.model.files : [];
          let pairs = comp.model.pairs ? comp.model.pairs : [];
          if (comp.model.id || comp.model.name) {
            files.push({
              folder: "",
              libraryId: comp.model.id,
              fileName: comp.model.name,
              subckt: comp.model.subcktName,
              type: COMP_REPEATER
            });
            pairs = [];
            comp.pins.forEach(item => {
              pairs.push({
                pin: item.pin,
                node: item.node,
                libraryId: comp.model.id,
                fileName: comp.model.name,
                subckt: comp.model.subcktName,
                modelKey: "1"
              })
            });
          }

          files.forEach(item => {
            if (item.libraryId === libraryFile.id) {
              item.libraryId = "";
              item.fileName = "";
              item.subckt = "";
              pairs.forEach(item => {
                if (item.libraryId === libraryFile.id) {
                  item.node = "";
                  item.libraryId = "";
                  item.fileName = "";
                  item.subckt = "";
                  item.modelKey = "";
                }
              });
              let currentError = {
                pcb: info.pcb,
                compName: comp.name,
                error: <p style={{ margin: 0 }}><span className="font-bold">{info.pcb}::{comp.name}</span>" repeater file <span className="font-bold">{libraryFile.fileName}</span> has been deleted.</p>
              }
              if (currentLibraryError.repeater) {
                currentLibraryError.repeater.push(currentError);
              } else {
                currentLibraryError.repeater = [];
                currentLibraryError.repeater.push(currentError);
              }
            }
          })

          comp.model = { files, pairs }
        }

        //connector file check
        if (libraryType === 'connector' && comp.type === CONNECTOR) {
          if (comp.libraryId === libraryFile.id || comp.pkg === libraryFile.fileName) {
            comp.pkg = "";
            comp.libraryId = "";
            comp.modelName = "";
            let currentError = {
              pcb: info.pcb,
              compName: comp.name,
              error: <p style={{ margin: 0 }}>"<span className="font-bold">{info.pcb}::{comp.name}</span>" connector file <span className="font-bold">{libraryFile.fileName}</span> has been deleted.</p>
            }
            if (currentLibraryError.connector) {
              currentLibraryError.connector.push(currentError);
            } else {
              currentLibraryError.connector = [];
              currentLibraryError.connector.push(currentError);
            }
          }
        }

        if (ICTypes.includes(comp.type) && (libraryType === 'pkg_touchstone' || libraryType === 'pkg_spice')) {
          if (comp.pkg && comp.pkg.files) {
            comp.pkg.files.forEach(file => {
              if (file && file.libraryId === libraryFile.id && file.fileName === libraryFile.fileName) {
                comp.pkg.files.splice(comp.pkg.files.indexOf(file), 1)
                if (comp.pkg.files.length === 0) {
                  comp.pkg = { type: 'None' };
                  let currentError = {
                    pcb: info.pcb,
                    compName: comp.name,
                    error: <p style={{ margin: 0 }}>Package "<span className="font-bold">{comp.name}</span>" {libraryType} file <span className="font-bold">{libraryFile.fileName}</span> has been deleted.</p>
                  };
                  currentLibraryError.model = currentLibraryError.model ? [...currentLibraryError.model, currentError] : [currentError];
                }
              }
            })
          }
        }
      })

      //update
      info.content.components = [...components]
    }
  })

  let errorIndex = _interfaceLibraryError.findIndex(item => item.verificationId === verificationId);
  if (errorIndex > -1) {
    if (!currentLibraryError || (currentLibraryError &&
      (!currentLibraryError.model || !currentLibraryError.model.length) &&
      (!currentLibraryError.stimuli || !currentLibraryError.stimuli.length) &&
      (!currentLibraryError.repeater || !currentLibraryError.repeater.length) &&
      (!currentLibraryError.connector || !currentLibraryError.connector.length))) {
      _interfaceLibraryError.splice(errorIndex, 1)
    } else {
      _interfaceLibraryError[errorIndex].error = { ...currentLibraryError };
    }
  } else {
    if (currentLibraryError && (currentLibraryError.model || currentLibraryError.stimuli || currentLibraryError.repeater || currentLibraryError.connector)) {
      _interfaceLibraryError.push({
        verificationId: verificationId,
        error: { ...currentLibraryError }
      })
    }
  }
  yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));

  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, _Interfaces[0].Name);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  const connectorList = getConnectorList(_Interfaces);
  yield put(updateSierraInfo({ Interfaces: _Interfaces, connectorList }));
  yield put(changeConnectorStatus(true))
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function updateLibraryMsg(libraryError, verificationId, type, info) {
  /* let interfaceLibraryError = JSON.parse(JSON.stringify(libraryError)); */
  let interfaceLibraryError = libraryError;
  const updateIndex = interfaceLibraryError.findIndex(item => item.verificationId === verificationId);
  const { component, pcb, pin } = info;
  if (updateIndex > -1) {
    let currentLibraryError = interfaceLibraryError[updateIndex];
    if (currentLibraryError && currentLibraryError.error) {
      let error = currentLibraryError.error;
      if (error && error[type] && error[type].length > 0) {
        if (type === 'model' || type === 'stimuli') {
          let findIndex = error[type].findIndex(item => item.pcb === pcb && item.compName === component && item.pin === pin);
          if (findIndex > -1) {
            // delete current pin error msg
            interfaceLibraryError[updateIndex].error[type].splice(findIndex, 1);
            if (interfaceLibraryError[updateIndex].error[type].length === 0) {
              delete interfaceLibraryError[updateIndex].error[type];
            }
            if (Object.keys(interfaceLibraryError[updateIndex].error).length === 0) {
              //clean current interface library check error message
              interfaceLibraryError = interfaceLibraryError.filter(item => item.verificationId !== verificationId);
            }
          }
        } else {
          let findIndex = error[type].findIndex(item => item.pcb === pcb && item.compName === component);
          if (findIndex > -1) {
            // delete current pin error msg
            interfaceLibraryError[updateIndex].error[type].splice(findIndex, 1);
            if (interfaceLibraryError[updateIndex].error[type].length === 0) {
              delete interfaceLibraryError[updateIndex].error[type];
            }
            if (Object.keys(interfaceLibraryError[updateIndex].error).length === 0) {
              //clean current interface library check error message
              interfaceLibraryError = interfaceLibraryError.filter(item => item.verificationId !== verificationId);
            }
          }
        }
      }
    }
  }
  return interfaceLibraryError;
}

function* interfacePCBReplace(action) {
  const { SierraReducer: { project: { currentProjectVerifications, verificationId }, sierra: { sierraInfo: { Interfaces } } } } = yield select();
  const { isReplace } = action;
  yield put(updatePcbReplaceInfo({ loading: isReplace ? "replace" : 'loading' }))
  const { _Interfaces, netsComponents } = yield call(designReplace, { ...action, verificationID: verificationId, Interfaces });
  if (isReplace) {
    const verification = currentProjectVerifications.find(item => item.id === verificationId) || {};
    const SETUP = SierraVerify;
    const PCBsInInterface = _Interfaces.map(item => item.pcbId);
    const name = verification.name || (_Interfaces[0] && _Interfaces[0].name ? _Interfaces[0].name : null);

    let info = SETUP.mergeInterfacesInfo(_Interfaces, name);
    const connectorList = getConnectorList(_Interfaces);
    const sierraInfo = {
      Interfaces: _Interfaces,
      PCBsInInterface,
      info,
      connectorList,
      verificationId: verificationId
    };
    yield put(updateSierraContent(sierraInfo));
    yield put(changeConnectorStatus(true))
    yield put(updatePcbReplaceInfo({ loading: null, msgInfo: { netsComponents: [], msg: '' }, panelVisible: false, selectPcb: '' }))
  } else {
    let msg = netsComponents && netsComponents.length > 0
      && (
        (netsComponents[0].deleteComponents && netsComponents[0].deleteComponents.length > 0)
        || (netsComponents[0].deleteNets && netsComponents[0].deleteNets.length > 0)
        || (netsComponents[0].deleteParts && netsComponents[0].deleteParts.length > 0)
      ) ? 'Warning: The replacing PCB does not have the following.' : ''
    yield put(updatePcbReplaceInfo({ loading: null, msgInfo: { netsComponents, msg } }))
  }
}

export function* designReplace(action) {
  const { Interfaces, verificationID, previousPCBId, newPCBId, isReplace = true } = action;
  const { SierraReducer: { project: { pcbComponentsNets, currentProjectId } } } = yield select();
  let _Interfaces = Array.isArray(Interfaces) ? JSON.parse(JSON.stringify(Interfaces)) : [];
  const vendor = designConstructor.getDesignVendor(previousPCBId);
  if (!_Interfaces || _Interfaces.length < 1 || vendor === PRE_LAYOUT) {
    return Interfaces;
  }
  let ifReplaceType = 'All';
  let deleteNets = [], deleteComponents = [], deleteParts = [], connectionComps = [];
  let prevComponentNames = [];
  try {
    _Interfaces.forEach(item => {
      prevComponentNames.push({
        pcbId: item.pcbId,
        compNames: item.content.components.map(comp => comp.name),
        components: item.content.components
      })
    });

    yield* _Interfaces.map(function* (info) {
      let currentPcb = null;
      let ifReplace = false;
      if (previousPCBId && newPCBId && previousPCBId === info.pcbId) {
        currentPcb = projectDesigns.getAvailableDesigns(currentProjectId).find(item => item.id === newPCBId);
        if (currentPcb) {
          ifReplace = true;
          ifReplaceType = 'Interfaces';
        }
      } else {
        currentPcb = projectDesigns.getAvailableDesigns(currentProjectId).find(item => item.id === info.pcbId);
        if (currentPcb && currentPcb.designVersion !== info.designVersion) {
          ifReplace = true;
        }
      }
      if (ifReplace && ifReplaceType) {
        if (ifReplaceType === 'Interfaces') {
          info.pcbId = currentPcb.id;
          info.pcb = currentPcb.name;
          info.pcbSubId = currentPcb.subId;
        }
        info.designVersion = currentPcb.designVersion;
        let { signals, components, powerNets, powerComponents } = info.content;
        let newComponents = [];
        let pcbsLayoutDB = [...pcbComponentsNets]
        let pcbInfo = null;
        let COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, info.pcbId);
        let doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, info.pcbId);
        let compPinMap = yield call(componentSetting.getCompPinMap, info.pcbId);
        let _compTableHelper = yield call(compTableHelper.getTable, info.pcbId);
        const passiveTable = (_compTableHelper || []).table || [];

        yield call(getLayoutDB, info.pcbId, true);
        const _DesginData = LayoutData.getLayout(info.pcbId);
        pcbInfo = {
          netsList: [..._DesginData.mNetManager.mNetList.mVals],
          layers: [..._DesginData.mLayerMgr.mMetalLayers]
        };
        pcbsLayoutDB.push(info.pcbId);
        DesignInfo.savePCBInfo(info.pcbId, pcbInfo);
        pcbsLayoutDB = [...new Set(pcbsLayoutDB)];
        yield put(updatePCBComponentsNets(pcbsLayoutDB));
        const { netsList, layers } = pcbInfo;
        //update component settings
        const pcbComps = getSierraLayoutComponents(layers);//object
        const pcbComponents = Object.values(pcbComps);
        const pcbCompNames = Object.keys(pcbComps);
        //update do not stuff
        let saveDoNotStuff = false;
        doNotStuff = doNotStuff.filter(comp => {
          if (pcbCompNames.includes(comp)) {
            return comp;
          }
          deleteComponents.push(comp)
          saveDoNotStuff = true;
          return false;
        })
        if (saveDoNotStuff) {
          yield call(componentDoNotStuff.updateDoNotStuffSetting, [{ designId: info.pcbId, doNotStuff }]);
        }

        //update repeater pin map
        if (compPinMap.repeater && compPinMap.repeater.length) {
          compPinMap.repeater = compPinMap.repeater.filter(item => {
            const findComp = pcbComponents.find(it => it.part === item.part);
            if (findComp) {
              const pins = (findComp.pinList || []).map(item => item.mNumber);
              item.pinMap.forEach(pinMap => {
                pinMap.input = pinMap.input.filter(it => pins.includes(it));
                pinMap.output = pinMap.output.filter(it => pins.includes(it));
              })
              return item;
            }
            COMP_PREFIX_LIB.Repeater = (COMP_PREFIX_LIB.Repeater || []).filter(it => it !== item.part);
            deleteParts.push(item.part)
            return false;
          })
          yield call(componentSetting.updatePrefixLib, info.pcbId, COMP_PREFIX_LIB);
          yield call(componentSetting.updateCompPinMap, info.pcbId, "repeater", compPinMap.repeater);
        }

        let netList = pcbInfo ? netsList.map(item => item.mName) : [];
        //find deleted signal nets and exist nets
        signals.forEach(signal => {
          const { nets } = signal;
          const signalName = signal.name;
          let currentSignal = { ...signal };
          let allSignalNetNameList = [], newNets = [], delNets = [];
          allSignalNetNameList = [...nets];
          allSignalNetNameList.forEach(item => {
            if (netList.includes(item)) {
              newNets.push(item)
            } else {
              delNets.push(item);
              deleteNets.push(item);
            }
          });
          // get new component by net
          let exsitcomponentsName = newComponents.map(it => it.name);
          const componentsList = getComponentsWithNetList({ netList: newNets, pcbNetsList: netsList, layers, pcbId: info.pcbId, pcbReplace: true, COMP_PREFIX_LIB, passiveTable, doNotStuff, compPinMap });
          for (const comp of componentsList) {
            let { pin, name, net, value, type, part } = comp;
            const _index = exsitcomponentsName.indexOf(name);
            const findPassive = (passiveTable || []).find(item => item.part === part);
            if (findPassive && findPassive.usage) {
              type = findPassive.name && findPassive.name.includes(name) ? findPassive.usage : UNUSED;
            }
            if (_index < 0) {
              // Not exist component
              let newComp;
              if (RLCTypes.includes(type)) {
                const { value: _value, model } = getRLCCompValue({
                  passiveTable,
                  findPassive,
                  part,
                  value,
                  type
                })
                newComp = new RLCCONComponent({ name, type, value: _value, probePins: [] });
                newComp.model = model;
              } else if (ICTypes.includes(type)) {
                newComp = new ICComponent({ name, type: IC });
              } else if (type === COMP_REPEATER) {
                newComp = new RepeaterComponent({ name, type })
              } else if (type === CONNECTOR) {
                newComp = new Connector({ name, type })
              } else {
                newComp = new BasicComponent({ name, type, probePins: [] });
              }
              if (ICTypes.includes(type)) {
                newComp.pins = [new ICCompPinModel({ pin, net, signal: signalName })];
              } else if (type === COMP_REPEATER) {
                newComp.pins = [new RepeaterPin({ pin, net, signal: signalName })];
              } else if (type === CONNECTOR) {
                newComp.pins = [new ConnectorPin({ pin, net, signal: signalName })];
              } else {
                newComp.pins = [new BasicCompModel({ pin, net, signal: signalName })];
              }
              newComp.part = part;
              newComponents.push(newComp);
              // Update components name list
              exsitcomponentsName.push(name);
            } else {
              // Exsit component
              const pinFindIndex = newComponents[_index].pins.findIndex(it => it.pin === pin);
              if (pinFindIndex > -1) {
                continue;
              }
              if (ICTypes.includes(newComponents[_index].type)) {
                newComponents[_index].pins.push(new ICCompPinModel({ pin, net, signal: signalName }));
              } else if (newComponents[_index].type === COMP_REPEATER) {
                newComponents[_index].pins.push(new RepeaterPin({ pin, net, signal: signalName }));
              } else if (newComponents[_index].type === CONNECTOR) {
                newComponents[_index].pins.push(new ConnectorPin({ pin, net, signal: signalName }));
              } else {
                newComponents[_index].pins.push(new BasicCompModel({ pin, net, signal: signalName }));
              }
            }
          };
          currentSignal.nets = nets.filter(net => newNets.includes(net));
          let editedSignalIndex = info.content.signals.findIndex(item => item.name === signalName);
          if (editedSignalIndex > -1) {
            info.content.signals[editedSignalIndex] = currentSignal;
          } else {
            info.content.signals.push(currentSignal);
          }
        })

        //find power net and update component by rlc component
        const updateComp = yield call(getUpdatePowerNets, { components: newComponents, powerNets, signals: info.content.signals, netsList, findGND: true, update: true, designId: info.pcbId });
        let updatePowerNets = updateComp.powerNets;
        newComponents = updateComp.components;
        //filter power nets by current pcb nets
        const pcbNets = netsList.map(it => it.mName);
        updatePowerNets = updatePowerNets.filter(it => pcbNets.includes(it.name));

        let currentComponents = [];
        //update components
        newComponents.forEach(comp => {
          //Find whether the current comp exists in the previous components
          let compIndex = components.findIndex(item => item.name === comp.name);
          if (compIndex > -1) {
            let prevComp = components[compIndex];
            prevComp.part = comp.part;
            //Find whether the current pin exists in the previous pins
            comp.pins.forEach(pin => {
              let pinIndex = prevComp.pins.findIndex(item => item.pin === pin.pin);
              if (pinIndex === -1) {
                if (comp.type !== prevComp.type) {
                  //If the types are not the same, redefine pin
                  let prevType = prevComp.type;
                  if (ICTypes.includes(prevType)) {
                    prevComp.pins.push(new ICCompPinModel({ pin: pin.pin, net: pin.net, signal: pin.signal }));
                  } else if (prevType === COMP_REPEATER) {
                    prevComp.pins.push(new RepeaterPin({ pin: pin.pin, net: pin.net, signal: pin.signal }));
                  } else {
                    prevComp.pins.push(new BasicCompModel({ pin: pin.pin, net: pin.net, signal: pin.signal }));
                  }
                } else {
                  prevComp.pins.push(pin);
                }
              } else {
                prevComp.pins[pinIndex].net = pin.net;
                prevComp.pins[pinIndex].signal = pin.signal;
              }
            });
            const pinNames = prevComp.pins.map(it => it.pin);
            //update probePins
            if (prevComp.probePins) {
              prevComp.probePins = prevComp.probePins.filter(it => pinNames.includes(it));
            }

            const pinIns = pinNames.map(it => { return `${it}_in` });
            const pinDies = pinNames.map(it => { return `${it}_u` });
            // MultiPinSPICE pairs update
            if (prevComp.type === IC && prevComp.model && prevComp.model.pairs && prevComp.model.pairs.length) {
              const prevPairs = (prevComp.model.pairs || []).filter(it => pinIns.includes(it.pin) || pinDies.includes(it.pin));
              prevComp.model.pairs = prevComp.pins.map(item => {
                const findPinDie = prevPairs.find(it => it.pin === `${item.pin}_u`);
                const findPinIn = prevPairs.find(it => it.pin === `${item.pin}_in`);
                let newPairs = [];
                if (findPinIn) {
                  newPairs.push(findPinIn)
                } else {
                  newPairs.push({
                    libraryId: "",
                    fileName: "",
                    node: "",
                    pin: `${item.pin}_in`,
                    subckt: ""
                  })
                }
                if (findPinDie) {
                  newPairs.push(findPinDie)
                } else {
                  newPairs.push({
                    libraryId: "",
                    fileName: "",
                    node: "",
                    pin: `${item.pin}_u`,
                    subckt: ""
                  })
                }

                return newPairs
              }).flat(2)
            }

            // package pairs update
            if (prevComp.pkg && prevComp.pkg.pairs && prevComp.pkg.pairs.length) {
              const prevPairs = (prevComp.pkg.pairs || []).filter(it => pinNames.includes(it.pin) || pinDies.includes(it.pin));
              prevComp.pkg.pairs = prevComp.pins.map(item => {
                const findPinDie = prevPairs.find(it => it.pin === `${item.pin}_u`);
                const findPin = prevPairs.find(it => it.pin === item.pin);
                let newPairs = [];
                if (findPin) {
                  newPairs.push(findPin)
                } else {
                  newPairs.push({
                    libraryId: "",
                    node: "",
                    pin: item.pin,
                    subckt: ""
                  })
                }
                if (findPinDie) {
                  newPairs.push(findPinDie)
                } else {
                  newPairs.push({
                    libraryId: "",
                    node: "",
                    pin: `${item.pin}_u`,
                    subckt: ""
                  })
                }

                return newPairs
              }).flat(2)
            }

            // repeater pairs update
            if (prevComp.type === COMP_REPEATER && prevComp.model && prevComp.model.pairs && prevComp.model.pairs.length) {
              const prevPairs = (prevComp.model.pairs || []).filter(it => pinNames.includes(it.pin));
              prevComp.model.pairs = prevComp.pins.map(item => {
                const findPin = prevPairs.find(it => it.pin === item.pin)
                return findPin ? { ...findPin } : {
                  fileName: "",
                  libraryId: "",
                  node: "",
                  pin: item.pin,
                  subckt: "",
                  modelKey: ""
                }
              })
            }
            if (prevComp.type === CONNECTOR) {
              prevComp.pcbId = info.pcbId;
              prevComp.pcb = info.pcb;
            }
            currentComponents.push(prevComp);
            prevComp.type === CONNECTOR && connectionComps.push(JSON.parse(JSON.stringify({ ...prevComp })))
          } else {
            currentComponents.push(comp);
            if (comp.type === CONNECTOR) {
              comp.pcbId = info.pcbId;
              comp.pcb = info.pcb;
            }
            comp.type === CONNECTOR && connectionComps.push(JSON.parse(JSON.stringify({ ...comp })))
          }
        })

        //if pins length === 0, delete current component.
        currentComponents = currentComponents.filter(item => item.pins.length > 0);

        //update powerComponents 
        let _powerNets = updatePowerNets.map(item => item.name);
        const newPowerComps = getPowerComponents({ nets: _powerNets, pcbNetsList: netsList, layers, pcbId: info.pcbId, prevPowerComponents: powerComponents, COMP_PREFIX_LIB, doNotStuff, passiveTable, compPinMap });
        info.content.components = [...currentComponents];
        info.content.powerNets = [...updatePowerNets];
        info.content.powerComponents = [...newPowerComps];

        //save interface to server.
        const { SierraReducer } = yield select();
        const { currentProjectId } = SierraReducer.project;
        const errorCheck = getSierraInterfaceErrorCheck(info);
        // //1:ready, 0: not ready
        let readyForSim = 1;
        if (errorCheck) {
          readyForSim = 0;
        }
        if (!info.content.extraction) {
          info.content.extraction = new Extraction();
        }
        if (isReplace) {
          try {
            ResultData.cleanAll();
            let settingVersion = info.settingVersion;
            if (!settingVersion) {
              settingVersion = yield call(componentSetting.getVersion, info.pcbId);
            }
            yield call(updateInterfacePromise, {
              designId: info.pcbId,
              interfaceId: info.interfaceId,
              interfaceName: info.name,
              projectId: currentProjectId,
              verificationId: verificationID,
              verificationName: info.name,
              content: info.content,
              readyForSim: readyForSim,
              version: info.version,
              designVersion: info.designVersion,
              settingVersion
            });
          } catch (error) {
            console.error(error)
          }
        }
      } else if (info.content && info.content.components) {
        connectionComps.push(...JSON.parse(JSON.stringify(info.content.components.filter(it => {
          if (it.type === CONNECTOR) {
            return { ...it, pcbId: info.pcbId === previousPCBId ? newPCBId : info.pcbId }
          }
          return false;
        }))))
      }

    })
    _Interfaces = updateReplaceConnections({ Interfaces: _Interfaces, previousPCBId, newPCBId, connectionComps });
    _Interfaces = connectorHandle(_Interfaces);

    //update design replace message
    let prevComp = [], newComp = [];
    prevComponentNames.forEach(item => {
      prevComp = [...prevComp, ...item.compNames]
    });
    Interfaces.forEach(item => {
      item.content.components.forEach(com => {
        newComp.push(com.name);
      })
    })
    prevComp.forEach(item => {
      if (!newComp.includes(item)) {
        deleteComponents.push(item);
      }
    })
  } catch (error) {
    console.error(error)
  }
  const { SierraReducer: { sierra: { updateNetsComponents } } } = yield select();
  let netsComponents = [...updateNetsComponents];
  let updateIndex = netsComponents.findIndex(item => item.verificationId === verificationID);
  if (updateIndex > -1) {
    if (deleteNets.length === 0 && deleteComponents.length === 0 && deleteParts.length === 0) {
      netsComponents.splice(updateIndex, 1)
    } else {
      netsComponents[updateIndex].deleteNets = deleteNets;
      netsComponents[updateIndex].deleteComponents = deleteComponents;
      netsComponents[updateIndex].deleteParts = deleteParts;
    }
  } else {
    netsComponents.push({
      deleteNets,
      deleteComponents,
      deleteParts,
      verificationId: verificationID
    })
  }
  if (isReplace && ifReplaceType === 'All') {
    yield put(updateNetsComps(netsComponents));
    yield put(changeConnectorStatus(true))
  }
  return { _Interfaces, netsComponents };

}

function* getUpdatePowerNets({ components, powerNets, netsList, signals, findGND, update, designId }) {
  let signalNets = [];
  signals.forEach(item => {
    if (item.nets && item.nets.length > 0) {
      signalNets = [...signalNets, ...item.nets]
    }
  });
  const powerNetsTable = yield call(powerStore.getTable, designId);
  let doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
  const { components: _components, powerNets: updatePowerNets } = getPowerNets({ components, powerNets, netsList, signalNets, findGND, designId, haveTypeValue: true, doNotStuff, powerNetsTable })
  return { components: _components, powerNets: updatePowerNets };
}

export function getPowerNetsData(action) {
  const { components, powerNets } = action;
  let newPowerNets = [], _PowerNets = []
  for (let comp of components) {
    if (!RLCTypes.includes(comp.type)) {
      continue;
    }

    for (let pin of comp.pins) {
      if (!pin.signal) {
        newPowerNets.push(pin.net)
      }
    }
  }
  for (let netData of powerNets) {
    let type = 'Reference';
    if (newPowerNets.includes(netData.name) /* || netData.name.match(/gnd/ig) */) {
      // powerNet and gnd net
      type = 'Pull Up/Down';
    }
    _PowerNets.push({ ...netData, type })
  }
  return _PowerNets;
}

export function* _UpdateTouchstoneStatus(action) {
  //update touchstone file macro modeling status list
  const { Components, verificationId } = action;
  let { SierraReducer: { sierra: { TouchstoneStatusList } } } = yield select();

  if (!Components || Components.length === 0) {
    return { msgList: [], TouchstoneStatusList };
  }

  let touchstoneFileStatusMsgList = [];
  const compPkgList = Array.isArray(Components) ? Components.filter(comp => ICTypes.includes(comp.type)).map(it => it.pkg) : [];
  let pkgFileList = compPkgList.filter(it => it.files);
  let touchstonePkgList = [];
  for (let pkg of pkgFileList) {
    if (!pkg.files || pkg.files.length === 0) {
      continue;
    }

    const touchstones = Array.isArray(pkg.files) ? pkg.files.filter(it => it.type === 'Touchstone') : [];
    touchstones.forEach(it => {
      const find = touchstonePkgList.find(file => file.libraryId === it.libraryId);
      if (!find) {
        touchstonePkgList.push(it);
      }
    })
  }

  if (!touchstonePkgList || touchstonePkgList.length === 0) {
    return { msgList: [], TouchstoneStatusList };
  }

  yield* touchstonePkgList.map(function* (file) {
    const libraryId = file.libraryId;
    const fileName = file.fileName;
    let fileStatusObj;
    if (libraryId) {
      fileStatusObj = yield call(getLibraryMacroModelingStatus, libraryId);
    }
    let status = taskStatus.SUCCEED, msg = '';

    if (fileStatusObj) {
      status = fileStatusObj.status;
      switch (status) {
        case taskStatus.RUNNING:
        case taskStatus.WAITING:
          msg = `Touchstone file ${fileName} is not ready for simulation, please waiting...`;
          break;
        case taskStatus.FAILED:
        case taskStatus.CANCEL:
          msg = `Touchstone file ${fileName} is broken, please upload a new touchstone file for package model.`;
          break;
        default: break;
      }
    }
    msg && touchstoneFileStatusMsgList.push(msg);
  });

  if (touchstoneFileStatusMsgList && touchstoneFileStatusMsgList.length > 0) {
    const statusIndex = TouchstoneStatusList.findIndex(it => it.verificationId === verificationId);
    if (statusIndex > -1) {
      TouchstoneStatusList[statusIndex].msgList = [...touchstoneFileStatusMsgList];
    } else {
      TouchstoneStatusList.push({
        verificationId,
        msgList: [...touchstoneFileStatusMsgList]
      })
    }
  } else {
    TouchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== verificationId);
  }
  yield put(updateTouchstoneStatusList(TouchstoneStatusList));
  return { msgList: touchstoneFileStatusMsgList, TouchstoneStatusList };
}

function* toUploadInterfaces() {
  const { SierraReducer: { sierra, project } } = yield select();
  if (sierra.sierraInfo && sierra.sierraInfo.Interfaces && sierra.sierraInfo.Interfaces.length && project.verificationId && (project.viewList || []).includes(VERIFICATION)) {
    let Interfaces = [...sierra.sierraInfo.Interfaces], verificationID = project.verificationId;
    let newInterfaces = yield call(updateLibraryInInterface, Interfaces);
    let error = {}, errorExist = null;
    if (newInterfaces && newInterfaces.Interfaces) {
      error = newInterfaces.error;
      errorExist = newInterfaces.errorExist;
      Interfaces = newInterfaces.Interfaces;
    }
    const { SierraReducer: { sierra: { interfaceLibraryError } } } = yield select();
    let _interfaceLibraryError = interfaceLibraryError ? [...interfaceLibraryError] : [];
    let errorIndex = _interfaceLibraryError.findIndex(item => item.verificationId === verificationID);
    if (errorIndex > -1) {
      if (!errorExist) {
        _interfaceLibraryError.splice(errorIndex, 1)
      } else {
        _interfaceLibraryError[errorIndex].error = { ...error };
      }
    } else {
      if (errorExist) {
        _interfaceLibraryError.push({
          verificationId: verificationID,
          error: { ...error }
        })
      }
    }
    yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));
    const SETUP = SierraVerify;
    let info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
    const connectorList = getConnectorList(Interfaces);
    yield put(updateSierraInfo({ Interfaces, connectorList, info }));
    yield put({ type: SAVE_CONFIG_TO_SERVER });
    yield put(changeConnectorStatus(true))
  }
}

function* updatePortSetup(action) {
  const { data, current } = action;
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();
  if (!sierraInfo || !sierraInfo.Interfaces || !sierraInfo.Interfaces.length) {
    return;
  }
  const newInterfaces = [...sierraInfo.Interfaces];
  const index = newInterfaces.findIndex(item => item.pcb === current);
  if (index < 0) {
    return;
  }

  //update port portImpedance in extraction options
  let _extraction = { ...newInterfaces[index].content.extraction };
  const z0 = data.port_setups.length ? data.port_setups[0].z0 : "50";
  _extraction.portImpedance = z0;

  newInterfaces[index].content = { ...newInterfaces[index].content, ...data, extraction: _extraction };
  yield put(updateSierraInfo({ Interfaces: newInterfaces }));

  yield delay(300);
  yield call(saveVerificationContentToServer, { pcbs: [newInterfaces[index].pcbId] });
}

function* getInterfaceData(verificationID) {
  try {
    const response = yield call(getVerificationContentPromise, verificationID);

    const SETUP = SierraVerify;
    // { name, signals, powerNets, explorable }
    let Interfaces = response.Interfaces || [];
    let isUpdateRef = response.initialized === 0 ? true : false;
    let updateInterface = false, updateCompPrefix = null;
    //update content
    let version = Interfaces && Interfaces[0] ? Interfaces[0].version : SIERRA_SETUP_VERSION;
    //versionCompareSize(currentVersion,basicVersion)  if currentVersion < basicVersion return true
    if (versionCompareSize(version, SIERRA_SETUP_UPDATE_VERSION)) {
      //remove models and stimuli
      Interfaces = updateInterfaceContent({ Interfaces });
      updateInterface = true;
    }
    const { SierraReducer } = yield select();
    const { project: { currentProjectId } } = SierraReducer;
    let interfaceUpdateDesigns = [];
    Interfaces.forEach(info => {
      let currentPcb = projectDesigns.getAvailableDesigns(currentProjectId).find(item => item.id === info.pcbId);
      if (currentPcb && currentPcb.designVersion !== info.designVersion) {
        interfaceUpdateDesigns.push(info.pcbId);
      }
    });
    if (interfaceUpdateDesigns.length > 0) {
      const { _Interfaces } = yield call(designReplace, { Interfaces, verificationID });
      Interfaces = _Interfaces
    }

    const PCBsInInterface = Interfaces.map(item => item.pcbId);

    //update interfaces
    let newUpdatedInterface = yield call(_updateInterface, { Interfaces, updateInterface });
    Interfaces = newUpdatedInterface.Interfaces;
    updateInterface = newUpdatedInterface.updateInterface;
    updateCompPrefix = newUpdatedInterface.updateCompPrefix;

    //library file exist check

    let newInterfaces = yield call(updateLibraryInInterface, Interfaces);
    let error = {}, errorExist = null;
    if (newInterfaces && newInterfaces.Interfaces) {
      error = newInterfaces.error;
      errorExist = newInterfaces.errorExist;
      Interfaces = newInterfaces.Interfaces;
      updateInterface = true;
    }

    const { SierraReducer: { sierra: { interfaceLibraryError } } } = yield select();
    let _interfaceLibraryError = interfaceLibraryError ? [...interfaceLibraryError] : [];
    let errorIndex = _interfaceLibraryError.findIndex(item => item.verificationId === verificationID);
    if (errorIndex > -1) {
      if (!errorExist) {
        _interfaceLibraryError.splice(errorIndex, 1)
      } else {
        _interfaceLibraryError[errorIndex].error = { ...error };
      }
    } else {
      if (errorExist) {
        _interfaceLibraryError.push({
          verificationId: verificationID,
          error: { ...error }
        })
      }
    }
    yield put(updateInterfaceLibraryMsg(_interfaceLibraryError));
    //save interfaces to server
    if (Object.keys(error).length > 0) {
      updateInterface = true;
    }

    let info = SETUP.mergeInterfacesInfo(Interfaces, response.Name);
    const connectorList = getConnectorList(Interfaces);
    yield call(_updateCurrentConfig, { config: response.Config, verificationID });

    const sierraInfo = {
      Interfaces,
      PCBsInInterface,
      info,
      connectorList,
      verificationId: verificationID
    };
    return { sierraInfo, PCBsInInterface, newUpdatedInterface, Interfaces, updateInterface, interfaceUpdateDesigns, isUpdateRef, updateCompPrefix }
  } catch (error) {
    console.error(error)
    return {}
  }
}

function* editSignalPromise({ signalData, sierraInfo, changeTypeFromSetting, userDefaultSetting, connections, updateInfo, isSearchCreate = false, isCompType = false }) {
  if (!userDefaultSetting) {
    userDefaultSetting = yield call(userDefaultSettings.getSettings)
  }
  const { nets, pcbId, signalName } = signalData;

  const vendor = designConstructor.getDesignVendor(pcbId);
  if (vendor === PRE_LAYOUT) {
    const editObj = yield call(editPreLayoutSignal, { signalData, sierraInfo, changeTypeFromSetting, updateInfo })
    return editObj
  }

  let pcbReplace = false;
  let COMP_PREFIX_LIB = yield call(componentSetting.getPrefixLib, pcbId);
  let doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId) || [];
  let _compTableHelper = yield call(compTableHelper.getTable, pcbId);
  const passiveTable = (_compTableHelper || []).table || [];

  let _info = sierraInfo.info;
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
  let _Interfaces = [...sierraInfo.Interfaces];
  const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
  let editingInterface = _Interfaces[_InterfaceIndex];
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  if (!pcbInfo) {
    // Update info
    const _name = sierraInfo.info.name;
    const SETUP = SierraVerify;
    let info = SETUP.mergeInterfacesInfo(_Interfaces, _name);
    const connectorList = getConnectorList(_Interfaces);
    //add signals that have no pcb
    info.signals = [...info.signals, ..._prevSignal];
    return { editingInterface, errors: [], warnings: [], info, _Interfaces, connectorList }
  }
  const { netsList, layers } = pcbInfo || {};

  // // All exist signal net name
  let signals = [...editingInterface.content.signals],
    editedSignalIndex = signals.findIndex(item => item.name === signalName);
  const currentSignal = signals[editedSignalIndex];
  //sameSignalNets:[net1,net2,...] the deleted nets exist in other signals
  let allSignalNetNamelist = [], sameSignalNets = [];
  signals.forEach(item => allSignalNetNamelist.push(...item.nets));
  const compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
  //get other signals nets
  const { otherSignalNets, otherSignalAndNetList } = signalNetsFormat(signals, signalName);

  let components = [...editingInterface.content.components];
  let powerNets = [...editingInterface.content.powerNets];
  let virtualComps = [...(editingInterface.content.virtualComps || [])];
  const prevNets = currentSignal.nets;
  // 1. Delete the components connected with deletedNets
  if (prevNets.length > 0) {
    // 1.Delete component
    // Delete components which are only connected with the deleted nets or delete pinModel
    const deletedNets = prevNets.filter(net => !nets.includes(net) && !otherSignalNets.includes(net));
    //Exist in the nets that the current signal wants to delete and exist in the nets of other signals
    sameSignalNets = prevNets.filter(net => !nets.includes(net) && otherSignalNets.includes(net));
    if (deletedNets.length > 0) {
      // { netList, pcbNetsList, layers, pcbId }
      const deletedComps = getComponentsWithNetList({ netList: deletedNets, pcbNetsList: netsList, layers, pcbId, COMP_PREFIX_LIB, pcbReplace, doNotStuff, passiveTable, compPinMap });
      const update = deleteCompsConnectWithNets({ signalName, deletedComps, components })
      components = update.components;
      for (let comp of virtualComps) {
        comp.pins = (comp.pins || []).filter(pin => !deletedNets.includes(pin.net));
      }
      virtualComps = virtualComps.filter(item => !!item.pins.length);
    }
  }

  // 2. Add nets, filter exist nets
  currentSignal.nets = nets ? [...nets] : [];
  if (editedSignalIndex > -1) {
    signals[editedSignalIndex] = currentSignal;
  } else {
    signals.push(currentSignal);
  }

  // 3. Add components - RLCCONComponent, BasicComponent(Connector.IC)
  let exsitcomponentsName = components.map(component => component.name);
  // Get components with add nets
  let addNets = nets.filter(net => !prevNets.includes(net) && !otherSignalNets.includes(net));
  if (changeTypeFromSetting) {
    addNets = [...nets];
    pcbReplace = true
  }
  let repeaterComps = {};
  // { netList, pcbNetsList, layers, pcbId }
  const componentsList = getComponentsWithNetList({ netList: addNets, pcbNetsList: netsList, layers, pcbId, COMP_PREFIX_LIB, pcbReplace, doNotStuff, compPinMap, passiveTable });
  for (const comp of componentsList) {
    let { pin, name, net, value, type, part } = comp;
    type = connections ? getCompTypeByConnections({ connections, type, name, pcbId }) : type;
    const _index = exsitcomponentsName.indexOf(name);
    if (isSearchCreate && type === COMP_REPEATER) {
      if (_index < 0) {
        const pins = componentsList.filter(it => it.name === name);
        type = getCompTypeByRepeaterPinMap({ type, part, pins, compPinMap, compName: name });
        repeaterComps[name] = type;
      } else {
        type = repeaterComps[name] ? repeaterComps[name] : COMP_REPEATER;
      }
    }
    const findPassive = (passiveTable || []).find(item => item.part === part);

    if (!isCompType && findPassive && findPassive.usage && !doNotStuff.includes(name)) {
      type = findPassive.name && findPassive.name.includes(name) ? findPassive.usage : UNUSED;
    }
    if (_index < 0) {
      // Not exist component
      let newComp;
      if (RLCTypes.includes(type)) {
        const { value: _value, model } = getRLCCompValue({
          passiveTable,
          findPassive,
          part,
          value,
          type
        })

        if (doNotStuff.includes(name)) {
          type = UNUSED;
        }
        newComp = new RLCCONComponent({ name, type, value: _value, probePins: [] });
        newComp.model = model;
      } else if (ICTypes.includes(type)) {
        newComp = new ICComponent({ name, type: IC });
      } else if (type === COMP_REPEATER) {
        newComp = new RepeaterComponent({ name, type })
      } else if (type === CONNECTOR) {
        newComp = new Connector({ name, type });
      } else {
        newComp = new BasicComponent({ name, type, probePins: [] });
      }
      if (ICTypes.includes(type)) {
        newComp.pins = [new ICCompPinModel({ pin, net, signal: signalName })];
      } else if (type === COMP_REPEATER) {
        newComp.pins = [new RepeaterPin({ pin, net, signal: signalName })];
      } else if (type === CONNECTOR) {
        newComp.pins = [new ConnectorPin({ pin, net, signal: signalName })];
      } else {
        newComp.pins = [new BasicCompModel({ pin, net, signal: signalName })];
      }
      newComp.part = part;
      components.push(newComp);
      // Update components name list
      exsitcomponentsName.push(name);
    } else {
      if (changeTypeFromSetting && components[_index].type !== type) {
        let update = false;
        // If the components setting is turned off, the usage of comp will change according to the changed type
        //comp change
        if (type === CONNECTOR && components[_index].type !== CONNECTOR && [IC, UNUSED].includes(components[_index].type) && _Interfaces.length === 1) {
          //one interface cannot change connector to ic
          type = components[_index].type;
        } else {
          update = getCompTypeChange({
            updateInfo,
            pcbId,
            type,
            component: components[_index]
          })
          if (update) {
            const { newComp } = componentTypeChange({ type: type, component: components[_index] });
            if (RLCTypes.includes(newComp.type)) {
              const { value: _value_, model: _model } = getRLCCompValue({
                passiveTable,
                findPassive,
                part,
                value,
                type
              })
              newComp.value = _value_;
              newComp.model = _model;
            }
            components[_index] = newComp;
          }
        }
      } else if (RLCTypes.includes(type) && type !== components[_index].type && !(type === JUMPER && !changeTypeFromSetting && components[_index].type !== CONNECTOR)) {
        const { value: _value_, model: _model } = getRLCCompValue({
          passiveTable,
          findPassive,
          part,
          value,
          type
        })
        let newComp = new RLCCONComponent({ name, type, value: _value_, probePins: [] })
        newComp.part = part;
        newComp.model = _model;
        components[_index] = newComp;
      }

      //find current pin exist and net ,signal same
      const pinIndex = components[_index].pins.findIndex(item => item.pin === pin & item.net === net && item.signal === signalName);
      if (pinIndex > -1) {
        continue;
      }

      //find current pin
      const _pinIndex = components[_index].pins.findIndex(item => item.pin === pin);
      if (_pinIndex > -1) {
        components[_index].pins[_pinIndex].net = net;
        components[_index].pins[_pinIndex].signal = signalName;
        continue;
      }

      // Exsit component
      if (ICTypes.includes(components[_index].type)) {
        components[_index].pins.push(new ICCompPinModel({ pin, net, signal: signalName }));
      } else if (components[_index].type === COMP_REPEATER) {
        components[_index].pins.push(new RepeaterPin({ pin, net, signal: signalName }));
      } else if (components[_index].type === CONNECTOR) {
        components[_index].pins.push(new ConnectorPin({ pin, net, signal: signalName }));
      } else {
        components[_index].pins.push(new BasicCompModel({ pin, net, signal: signalName }));
      }
    }
  };

  components.forEach(comp => {
    if (comp.type === UNUSED) {
      const findRLC = components.find(it => RLCTypes.includes(it.type) && comp.part === it.part);
      if (findRLC) {
        comp.probePins = findRLC.probePins;
        comp.value = findRLC.value;
        comp.model = findRLC.model ? JSON.parse(JSON.stringify(findRLC.model)) : {};
      }
    }
  })

  //find power net and update component by rlc component
  const updateComp = yield call(getUpdatePowerNets, { components, powerNets, netsList, signals, findGND: true, update: true, designId: pcbId, changeTypeFromSetting });
  const updatePowerNets = updateComp.powerNets;
  components = updateComp.components;

  let _powerNets = updatePowerNets.map(item => item.name);

  let signalNets = [], compPowerNets = [];
  signals.forEach(item => {
    if (item.nets && item.nets.length > 0) {
      signalNets = [...signalNets, ...item.nets]
    }
  })
  components.forEach(comp => {
    //update pin signal,(The net deleted by the current signal and the net that other signals exist)
    if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
      comp.pins = updateComponentPinSignal({
        comp,
        sameSignalNets,
        otherSignalNets,
        otherSignalAndNetList,
        signalName
      });
    }

    //update components RLC S-parameter model reference
    //removed referenceNet if new signal nets not includes reference net  
    if (RLCTypes.includes(comp.type) && comp.model && comp.model.type === "Touchstone" && !signalNets.includes(comp.model.referenceNet)) {
      comp.model.referenceNet = "";
    }

    //update repeater model pairs
    if (comp.type === COMP_REPEATER) {
      let pairs = [];
      if (!comp.model || !comp.model.pairs || comp.model.pairs.length === 0) {
        pairs = getPairs({ modelType: comp.type, pins: comp.pins });
      } else {
        pairs = comp.model.pairs;
        comp.pins.forEach(pin => {
          const findPin = pairs.find(item => item.pin === pin.pin);
          if (!findPin) {
            pairs.push({
              pin: pin.pin,
              node: "",
              libraryId: "",
              fileName: "",
              subckt: ""
            })
          }
        });
      }
      comp.model.pairs = pairs;
      comp.model = setDefaultRepeaterModel({
        part: comp.part,
        pairs: comp.model.pairs,
        files: comp.model.files,
        compName: comp.name,
        compPinMap,
        pins: comp.pins.map(it => it.pin)
      })

      comp.model.files.forEach(item => {
        if (item.libraryId || (!item.libraryId && item.fileName)) {
          const fileExist = item.libraryId ? sierraLibrary.checkFile(REPEATER, item.libraryId, item.folder ? item.fileName : null) : false;
          if (!fileExist) {
            const findFile = sierraLibrary.findFileByName(REPEATER, item.fileName, item.folder);

            if (findFile && findFile.id) {

              (comp.model.pairs || []).forEach(pin => {
                if (pin.libraryId === item.libraryId) {
                  pin.libraryId = findFile.id;
                }
              })
              item.libraryId = findFile.id;
            }
          }
        }
      })
    }

    if (ICTypes.includes(comp.type)) {
      let pairs = [];
      if (!comp.pkg || !comp.pkg.pairs || comp.pkg.pairs.length === 0) {
        pairs = getPairs({ modelType: 'Package', pins: comp.pins });
      } else {
        pairs = comp.pkg.pairs;
        comp.pins.forEach(pin => {
          const findPin = pairs.find(item => item.pin === pin.pin);
          if (!findPin) {
            let pinR = { pin: pin.pin, node: "", subckt: "", libraryId: "" };
            let pinL = { pin: `${pin.pin}_u`, node: "", subckt: "", libraryId: "" };
            pairs = [...pairs, pinL, pinR]
          }
        });
      }
      comp.pkg.pairs = pairs;
    }

    if (RLCTypes.includes(comp.type)) {
      for (let pin of comp.pins) {
        if (!pin.signal) {
          compPowerNets.push(pin.net);
        }
      }
    }
  });


  //get power components
  const prevPowerComponents = editingInterface.content.powerComponents;
  const newPowerComps = getPowerComponents({ nets: _powerNets, pcbNetsList: netsList, layers, pcbId, prevPowerComponents, COMP_PREFIX_LIB, doNotStuff, changeTypeFromSetting, updateInfo, passiveTable, compPinMap });

  editingInterface.content.powerComponents = [...newPowerComps];
  editingInterface.content.powerNets = [...updatePowerNets];
  editingInterface.content.signals = [...signals];
  editingInterface.content.components = [...components];
  editingInterface.content.virtualComps = [...(virtualComps || [])];

  const { ports_generate_setup_list, referenceNets, port_setups, components: portComponents, errors, warnings } = yield call(updateExtractionPortsSetup, editingInterface, pcbInfo, {}, userDefaultSetting);

  editingInterface.content.ports_generate_setup_list = ports_generate_setup_list;
  editingInterface.content.referenceNets = referenceNets;
  editingInterface.content.port_setups = port_setups;
  editingInterface.content.components = portComponents;

  _Interfaces[_InterfaceIndex] = { ...editingInterface };
  if (!isSearchCreate || !connections || !connections.length) {
    _Interfaces = connectorHandle(_Interfaces);
  }

  // Update info
  const _name = sierraInfo.info.name;
  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, _name);
  const connectorList = getConnectorList(_Interfaces);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];

  return { editingInterface, errors, warnings, info, _Interfaces, connectorList }
}

function* editPreLayoutSignal({ signalData, sierraInfo, changeTypeFromSetting, updateInfo }) {
  const { nets, pcbId, signalName } = signalData;

  let _info = sierraInfo.info;
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
  let _Interfaces = [...sierraInfo.Interfaces];
  const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
  let editingInterface = _Interfaces[_InterfaceIndex];
  // prelayout content
  const prelayout = yield call([preLayoutData, preLayoutData.getPreLayout], pcbId);
  const _components = prelayout.content.components;
  // // All exist signal net name
  let signals = [...editingInterface.content.signals],
    editedSignalIndex = signals.findIndex(item => item.name === signalName);
  const currentSignal = signals[editedSignalIndex];
  //sameSignalNets:[net1,net2,...] the deleted nets exist in other signals
  let allSignalNetNamelist = [], sameSignalNets = [];
  signals.forEach(item => allSignalNetNamelist.push(...item.nets));
  //get other signals nets
  const { otherSignalNets, otherSignalAndNetList } = signalNetsFormat(signals, signalName);

  let components = [...editingInterface.content.components];
  const prevNets = currentSignal.nets;
  // 1. Delete the components connected with deletedNets
  if (prevNets.length > 0) {
    // 1.Delete component
    // Delete components which are only connected with the deleted nets or delete pinModel
    const deletedNets = prevNets.filter(net => !nets.includes(net) && !otherSignalNets.includes(net));
    //Exist in the nets that the current signal wants to delete and exist in the nets of other signals
    sameSignalNets = prevNets.filter(net => !nets.includes(net) && otherSignalNets.includes(net));
    if (deletedNets.length > 0) {
      for (let item of components) {
        item.pins = item.pins.filter(pin => nets.includes(pin.net) || otherSignalNets.includes(pin.net))
      }
      components = components.filter(item => item.pins.length);
    }
  }

  // 2. Add nets, filter exist nets
  currentSignal.nets = nets ? [...nets] : [];
  if (editedSignalIndex > -1) {
    signals[editedSignalIndex] = currentSignal;
  } else {
    signals.push(currentSignal);
  }

  // 3. Add components - RLCCONComponent, BasicComponent(Connector.IC)
  let exsitcomponentsName = components.map(component => component.name);
  // Get components with add nets
  let addNets = nets.filter(net => !prevNets.includes(net) && !otherSignalNets.includes(net));
  if (changeTypeFromSetting) {
    addNets = [...nets];
  }
  // { netList, pcbNetsList, layers, pcbId }
  const componentsList = getPreLayoutComponentsByPinNet(_components, addNets);
  components = editPreLayoutComponents({ componentsList, exsitcomponentsName, components, changeTypeFromSetting, signalName, pcbId, updateInfo })

  //find power net and update component by rlc component

  let signalNets = [], compPowerNets = [];
  signals.forEach(item => {
    if (item.nets && item.nets.length > 0) {
      signalNets = [...signalNets, ...item.nets]
    }
  })
  components.forEach(comp => {
    //update pin signal,(The net deleted by the current signal and the net that other signals exist)
    if (sameSignalNets.length > 0 && comp.pins && comp.pins.length) {
      comp.pins = updateComponentPinSignal({
        comp,
        sameSignalNets,
        otherSignalNets,
        otherSignalAndNetList,
        signalName
      });
    }

    //update components RLC S-parameter model reference
    //removed referenceNet if new signal nets not includes reference net  
    if (RLCTypes.includes(comp.type) && comp.model && comp.model.type === "Touchstone" && !signalNets.includes(comp.model.referenceNet)) {
      comp.model.referenceNet = "";
    }

    //update repeater model pairs
    if (comp.type === COMP_REPEATER) {
      let pairs = [];
      if (!comp.model || !comp.model.pairs || comp.model.pairs.length === 0) {
        pairs = getPairs({ modelType: comp.type, pins: comp.pins });
      } else {
        pairs = comp.model.pairs;
        comp.pins.forEach(pin => {
          const findPin = pairs.find(item => item.pin === pin.pin);
          if (!findPin) {
            pairs.push({
              pin: pin.pin,
              node: "",
              libraryId: "",
              fileName: "",
              subckt: ""
            })
          }
        });
      }
      comp.model.pairs = pairs;
    }

    if (ICTypes.includes(comp.type)) {
      let pairs = [];
      if (!comp.pkg || !comp.pkg.pairs || comp.pkg.pairs.length === 0) {
        pairs = getPairs({ modelType: 'Package', pins: comp.pins });
      } else {
        pairs = comp.pkg.pairs;
        comp.pins.forEach(pin => {
          const findPin = pairs.find(item => item.pin === pin.pin);
          if (!findPin) {
            let pinR = { pin: pin.pin, node: "", subckt: "", libraryId: "" };
            let pinL = { pin: `${pin.pin}_u`, node: "", subckt: "", libraryId: "" };
            pairs = [...pairs, pinL, pinR]
          }
        });
      }
      comp.pkg.pairs = pairs;
    }

    if (RLCTypes.includes(comp.type)) {
      for (let pin of comp.pins) {
        if (!pin.signal) {
          compPowerNets.push(pin.net);
        }
      }
    }
  });

  let prevPowerNets = [...(editingInterface.content.powerNets || [])]
  const powerNets = prelayout.content && prelayout.content.signals ? getSchematicPreLayoutPowerNets(prelayout.content.signals, prelayout.content.components, components, prevPowerNets) : prevPowerNets;

  editingInterface.content.powerComponents = [];
  editingInterface.content.powerNets = powerNets || [];
  editingInterface.content.signals = [...signals];
  editingInterface.content.components = [...components];

  _Interfaces[_InterfaceIndex] = { ...editingInterface };
  _Interfaces = connectorHandle(_Interfaces);

  // Update info
  const _name = sierraInfo.info.name;
  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, _name);
  const connectorList = getConnectorList(_Interfaces);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];

  return { editingInterface, errors: [], warnings: [], info, _Interfaces, connectorList }
}

function* createInterfaceIdentification(action) {
  const { info } = action;
  yield put(updateCreatePanelLoading(false));
  const { interfaceIdentificationInfo, connections, groupName } = info
  //interfaceIdentificationInfo -> [ {interfaceName, type, interfaces }, ... ]
  const { SierraReducer: { project: { currentProjectId, currentProjectVerifications } } } = yield select();
  let projectNameList = currentProjectVerifications.map(item => { return { name: item.name } });

  let interfacesInfo = [], verificationId = '';

  if (!interfaceIdentificationInfo.length) {
    yield put(updateCreatePanelLoading(true))
    return;
  }

  const userDefaultSetting = yield call(userDefaultSettings.getSettings)

  let partList = [];
  for (let identificationInfo of interfaceIdentificationInfo) {
    let verificationName = identificationInfo.interfaceName, verType = identificationInfo.type;
    if (projectNameList.map(it => it.name).includes(verificationName)) {
      verificationName = getDefaultName({ nameList: projectNameList, defaultKey: verificationName, firstIndex: 1, delimiter: "_" });
    }
    projectNameList.push({ name: verificationName });
    let interfaceParams = [];

    const SETUP = SierraVerify;
    let _info = SETUP.mergeInterfacesInfo([], verificationName);
    const _sierraInfo = {
      verificationId: '',
      Interfaces: [],
      PCBsInInterface: [],
      connectorList: [],
      info: {
        ..._info
      }
    }
    for (let interfaceInfo of identificationInfo.interfaces || []) {

      const { content, designId, type } = interfaceInfo || {}
      if (!content || !content.length) { continue }
      verType = !verType ? type : verType;

      let newInterfaceContent = new SierraInterface(verificationName);
      const new_signals = content.map(item => { return new Signal(item.name) })
      newInterfaceContent.signals.push(...new_signals);
      newInterfaceContent.extraction = yield call(reSetClipDesignByCompsNum, { pcbId: designId, extraction: newInterfaceContent.extraction })

      const signals = content.map(item => {
        return {
          name: item.name,
          pcb: designConstructor.getDesignName(designId),
          pcbId: designId,
          signalLength: 1,
          nets: []
        }
      })

      _sierraInfo.info = {
        ..._sierraInfo.info,
        signals
      }
      _sierraInfo.Interfaces.push({
        content: newInterfaceContent,
        pcbId: designId,
      })
      let updateData = {}
      for (let signalData of content) {
        // add net ,update interface content data
        updateData = yield call(editSignalPromise, {
          signalData: {
            ...signalData,
            signalName: signalData.name,
            pcbId: designId,
            pcbs: [designConstructor.getDesignName(designId)]
          },
          sierraInfo: _sierraInfo,
          userDefaultSetting,
          connections,
          isSearchCreate: true
        })
      }
      let interfaceContent = { ...updateData.editingInterface.content }

      // apply user default settings
      if (userDefaultSetting && userDefaultSetting.sierraSettings) {
        interfaceContent = yield call(applySierraUserDefaultSetting, interfaceContent, userDefaultSetting.sierraSettings, designId)
      }

      const settingVersion = yield call(componentSetting.getVersion, designId);
      //  interfaceContent = repeaterCompToIC({ interfaceContent });
      const interfaceParam = {
        content: interfaceContent,
        designId: designId,
        interfaceId: '',
        interfaceName: verificationName,
        projectId: currentProjectId,
        readyForSim: 0,
        verificationId: '',
        verificationName: verificationName,
        version: SIERRA_SETUP_VERSION,
        type: "PinToPin",
        settingVersion
      }
      interfaceParams.push(interfaceParam);
    }
    const verificationParam = {
      id: '',
      name: verificationName,
      projectId: currentProjectId,
      tag: verType,
      typeName: "PinToPin",
      group: groupName
    }
    //connector pcbs
    interfaceParams = connectorInterfacePCB({ interfaceParams, connections });
    //auto match buffer model by library and component part
    for (let item of interfaceParams) {
      partList = yield call(getAllComponentParts, { components: item.content.components, partList, designId: item.designId });
    }
    interfacesInfo.push({ interfaceParams, verificationParam });
  }
  //auto match buffer model by library and component part
  const modelMap = yield call(getBufferModelByPart, { partList });
  interfacesInfo = yield call(autoMatchInterfacesBufferModelByPart, { interfacesInfo, modelMap, userDefaultSetting });

  try {
    const response = yield call(updateMultipleInterfacePromise, { verificationParams: interfacesInfo, enableFilter: true });
    const repeatVerifications = response && response.repeatVerifications.length ? response.repeatVerifications : null;
    verificationId = response ? response.verificationId : null;
    if (repeatVerifications) {
      let repeaterVerNames = []
      const verifications = repeatVerifications.map(item => {
        const signals = item.interfaceParams.map(it => {
          if (it.content && it.content.signals && it.content.signals.length) {
            return JSON.parse(JSON.stringify(it.content.signals));
          }
          return []
        }).flat(2);
        item.verificationParam && repeaterVerNames.push(item.verificationParam.name);
        return {
          name: item.verificationParam ? item.verificationParam.name : "",
          signals
        }
      })
      const newInterfacesInfo = interfacesInfo.filter(item => item.verificationParam && !repeaterVerNames.includes(item.verificationParam.name));
      yield put(updateDuplicationVerifications({ newInterfacesInfo, repeatVerifications, verifications, show: true, groupName }));
      yield put(updateProjectMenu({ openProjectId: currentProjectId }))
    } else if (response.key) {
      yield put(startIdentificationCreating({ createKey: response.key, verificationId, groupName }));
    } else {
      yield put(updateIdentificationMassage("Create interface failed! Response is null!"))
    }
  } catch (error) {
    yield put(updateIdentificationMassage("Create interface failed!"))
    console.error(error)
  }
  yield put(updateCreatePanelLoading(true));
}



function* updateVerificationSetting(action) {
  const { designId, prevInfo, noNeedUpdate = false } = action;
  try {
    if (!noNeedUpdate) {
      yield call(updateInterfaceSetupBySettings);
    }

    const { SierraReducer: { sierra: { sierraInfo } } } = yield select();
    const _Interfaces = sierraInfo.Interfaces;
    let signalDataList = [], updateIds = [], updateInfo = {};
    for (let Interface of _Interfaces || []) {
      const { content: { signals }, pcbId, settingVersion: prevSettingVersion } = Interface;
      const settingVersion = yield call(componentSetting.getVersion, pcbId);
      if (!((designId && designId === pcbId) || versionCompareSize(prevSettingVersion, settingVersion))) {
        yield call(updateInterfaceSetupBySettings);
        // Modifying the component setting through sierraTree only modifies one of the nets
        continue
      }
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      updateIds.push(pcbId);
      const findConnectData = new FindInterface(pcbId)
      const _LayoutData = LayoutData.getLayout(pcbId);
      const compPrefixLib = yield call(componentSetting.getPrefixLib, pcbId);
      const compPinMap = yield call(componentSetting.getCompPinMap, pcbId);
      const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
      const powerTableNets = !isPreLayout ? yield call(powerStore.getTable, pcbId, "netName") : [];

      const currPrevInfo = prevInfo ? prevInfo[pcbId] : null;
      // updateInfo[pcbId] = getCompareSettingInfo(currPrevInfo, { compPrefixLib, compPinMap, doNotStuff })
      updateInfo[pcbId] = {
        prevInfo: currPrevInfo,
        newInfo: { compPrefixLib, compPinMap, doNotStuff }
      }
      for (let signal of signals) {
        const { nets, name } = signal;
        if (!nets || !nets.length) { continue }
        let clkSignalNets = findConnectData.getRelateNetsByName(nets[0], _LayoutData, { compPrefixLib, doNotStuff, compPinMap, powerTableNets }, 'compSetting')
        //filter power nets
        clkSignalNets = [...new Set([...clkSignalNets, ...nets])].filter(item => !(powerTableNets || []).includes(item));
        signalDataList.push({ nets: clkSignalNets, pcbId, signalName: name, compPrefixLib, doNotStuff })
      }
    }

    let updateData = {};
    for (let signalData of signalDataList) {
      // add net ,update interface content data
      updateData = yield call(editSignalPromise, { signalData, sierraInfo: sierraInfo, changeTypeFromSetting: true, updateInfo })
    }

    //update setting version
    for (let pcbId of updateIds) {
      try {
        const version = yield call(componentSetting.getVersion, pcbId);
        const index = (updateData._Interfaces || []).findIndex(item => item.pcbId === pcbId);
        if (index > -1) {
          updateData._Interfaces[index].settingVersion = version;
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (updateData && Object.keys(updateData).length) {
      // update Signal net
      const info = updateData.info
      const connectorList = updateData.connectorList;
      const _Interfaces = updateData._Interfaces;
      const errors = updateData.errors

      yield put(updateGeneratePortsErrors({ id: sierraInfo.verificationId, errors, warnings: updateData.warnings }));
      yield put(updateSierraInterface({ ...info }));
      yield put(updateSierraInfo({ Interfaces: _Interfaces, connectorList }));
      yield put(changeConnectorStatus(true))
      yield put(updateGetCompPrefixStatus(true))

      /*   for (let interfaceInfo of _Interfaces) { */
      yield put({ type: SAVE_CONFIG_TO_SERVER/* , pcbs: [interfaceInfo.pcbId] */ });
      /*  } */
      if (_Interfaces.length) {
        // update reference nets
        const delayTime = 5000
        if (autoUpdateRefTask) {
          yield cancel(autoUpdateRefTask);
        }
        updateRefTask = true;
        autoUpdateRefTask = yield fork(debounce, delayTime, updateRefNets);
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* _updateCompSettingSetup(action) {
  const { update, updateCompPrefix } = action;
  yield put(needUpdateCompSetting(false));
  const { SierraReducer: { sierra } } = yield select();
  let _Interfaces = [...sierra.sierraInfo.Interfaces];

  for (let designId of updateCompPrefix || []) {
    const version = yield call(componentSetting.getVersion, designId);
    const index = _Interfaces.findIndex(item => item.pcbId === designId);
    if (index > -1) {
      _Interfaces[index].settingVersion = version;
      yield put(updateSierraInfo({ Interfaces: _Interfaces }));
    }
    if (update) {
      yield call(updateVerificationSetting, { designId, noNeedUpdate: true });
    }
  }

  if (!update) {
    yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: [...updateCompPrefix] });
  }
}

function* _saveVirtualComps(action) {
  const { virtualComponents, pcbId } = action;
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();
  let _Interfaces = [...sierraInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  if (index < 0) {
    return;
  }
  _Interfaces[index].content.virtualComps = JSON.parse(JSON.stringify(virtualComponents));
  yield put(updateSierraInfo({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: [pcbId] });
}

export function getAllComponentParts({ components, partList, designId }) {
  const _partList = components.filter(item => ICTypes.includes(item.type)).map(item => {
    const partNumber = designId ? getPartNumber(item.part, designId) : "";
    return { partNumber, part: item.part };
  });

  return [...partList, ..._partList];
}

export function* getBufferModelByPart({ partList }) {
  const modelMap = new Map();
  try {
    const partNumbers = [...new Set(partList.map(item => [item.partNumber, item.part]).flat(2))].filter(item => !!item)
    const modelList = yield call(getPartLibraryListBySearch, { partNumbers, type: PART_IBIS_LIBRARY });
    if (!modelList || !modelList.length) {
      return modelMap
    }
    for (let part of partList) {
      //first find model by partNumber, then find model by part name
      const findPartNumberModel = modelList.find(item => item.partNumber === part.partNumber);
      const findModel = findPartNumberModel && findPartNumberModel.libraryId ? findPartNumberModel : modelList.find(item => item.partNumber === part.part);
      if (!findModel || !findModel.libraryId) {
        continue;
      }
      modelMap.set(part.part, findModel)
    }
  } catch (error) {
    console.error(error)
  }
  return modelMap;
}

function* autoMatchInterfacesBufferModelByPart(action) {
  const { interfacesInfo, modelMap, userDefaultSetting } = action;
  const sierraSettings = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings : null;
  const ioBufferUsage = sierraSettings && sierraSettings.ioBufferUsage ? sierraSettings.ioBufferUsage : null;
  for (let info of interfacesInfo || []) {
    for (let _interface of info.interfaceParams || []) {
      _interface.content.components = yield call(autoMatchBufferModelByPart, {
        components: _interface.content.components,
        modelMap,
        ioBufferUsage
      });
    }
  }
  return interfacesInfo;
}

export function* autoMatchBufferModelByPart(action) {
  const { components, modelMap, isReplace = false, ioBufferUsage } = action;
  try {
    for (let i = 0; i < components.length; i++) {
      let comp = components[i];
      if (!ICTypes.includes(comp.type)) {
        continue;
      }
      if (!isReplace && comp.model && comp.model.files &&
        comp.model.files.length && comp.model.files[0] && comp.model.files[0].libraryId) {
        continue;
      }

      const _model = modelMap.get(comp.part);
      if (!_model || !_model.libraryId) {
        continue;
      }
      components[i].model = {
        files: [
          {
            fileName: _model.file,
            component: _model.component,
            libraryId: _model.libraryId,
            type: "IBIS",
            version: _model.version
          }
        ],
        pairs: []
      };
      components[i] = yield call(assignIBISModels, { libraryId: _model.libraryId, currentComp: components[i], allowClear: false, version: _model.version, ioBufferUsage });
    }
  } catch (error) {
    console.error(error)
  }
  return components;
}

function* _updateSetupBySettings(action) {
  const { view } = action;
  if (view === VERIFICATION) {
    yield call(updateInterfaceSetupBySettings);
  } else if (view === MULTI_INTERFACE_SETUP) {
    yield call(updateMultiSetupBySettings);
  }
}

function* updateInterfaceSetupBySettings() {
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();
  if (!sierraInfo || !sierraInfo.verificationId || !sierraInfo.Interfaces || !sierraInfo.Interfaces.length) {
    return;
  }
  try {
    const response = yield call(getVerificationContentPromise, sierraInfo.verificationId);

    const SETUP = SierraVerify;
    let Interfaces = response.Interfaces;
    const PCBsInInterface = Interfaces.map(item => item.pcbId);
    let info = SETUP.mergeInterfacesInfo(Interfaces, response.Name);
    const connectorList = getConnectorList(Interfaces);

    const _sierraInfo = {
      Interfaces,
      PCBsInInterface,
      info,
      connectorList,
      verificationId: response.VerificationID || sierraInfo.verificationId
    };
    yield put(updateSierraContent(_sierraInfo));
  } catch (error) {
    console.error(error)
  }
}

function* _updateSetupByPartLibrary(action) {
  const { libraryType } = action;
  yield put(updateCompSettingsStatus(null))
  const { SierraReducer: { project: { viewList }, sierra: { sierraInfo } } } = yield select();
  const viewV = viewList.find(v => [VERIFICATION].includes(v));
  const _view = viewList.find(v => [VERIFICATION, MULTI_INTERFACE_SETUP].includes(v));
  if (libraryType === PART_IBIS_LIBRARY && _view) {
    console.log("Part library update ibis")
    yield put(updateSetupAfterCloseSettings(_view));
  }
  if (libraryType === PART_REPEATER_LIBRARY) {
    let PCBsInInterface = []
    if (sierraInfo && _view) {
      PCBsInInterface = sierraInfo.PCBsInInterface || [];
    }
    console.log("Part library update interface designId:", PCBsInInterface)
    let prevInfo = {}
    yield* PCBsInInterface.map(function* (designId) {
      //get prev setting
      const compPrefixLib = yield call(componentSetting.getPrefixLib, designId);
      const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
      const compPinMap = yield call(componentSetting.getCompPinMap, designId);
      prevInfo[designId] = {
        compPrefixLib: JSON.parse(JSON.stringify(compPrefixLib || {})),
        compPinMap: JSON.parse(JSON.stringify(compPinMap || {})),
        doNotStuff: JSON.parse(JSON.stringify(doNotStuff || []))
      }
    })
    console.log("Part library clear all component setting cache")
    componentSetting.clearAllSetting();
    yield put(updateCompSettingsStatus(true))

    yield* PCBsInInterface.map(function* (designId) {
      //get new setting
      console.log("Part library update component setting cache")
      yield call(componentSetting.getSetting, { designId });
      if (viewV) {
        console.log("Part library update interface setup")
        yield call(updateVerificationSetting, { designId });
      }
    })

    if (_view && !viewV) {
      console.log("Part library update multi setup2")
      yield put(updateSetupAfterCloseSettings(_view));
    }
  }
}

let repeaterUpdateTask = null;
function* _updatePartRepeaterInfo(action) {
  const { addRepeaterParts } = action;
  let log = "Checking for updates..."
  yield put(updatePartRepeaterLog(log));
  if (repeaterUpdateTask) {
    yield cancel(repeaterUpdateTask)
  }
  try {
    yield call(autoUpdateRepeaterSetting, addRepeaterParts)
  } catch (error) {
    console.error(error);
    yield put(closePartRepeaterPanel(true))
    return;
  }
  repeaterUpdateTask = yield fork(_getRepeaterUpdateFlow);
}


function* _getRepeaterUpdateFlow() {
  let REPEATER_UPDATING = 1, REPEATER_UPDATE_COMPLETED = 2;
  let status = REPEATER_UPDATING;
  let log = "";
  while (status === REPEATER_UPDATING) {
    try {
      const res = yield call(getRepeaterUpdateStatus);
      if (!res) {
        status = REPEATER_UPDATE_COMPLETED;
        break;
      }
      status = res.status;
      log = res.log || log || "Checking for updates...";
      if (log) {
        yield put(updatePartRepeaterLog(log));
      }
    } catch (error) {
      status = REPEATER_UPDATE_COMPLETED;
    }
    yield delay(1000);
  }

  yield put(updatePartRepeaterLog(log));
  yield delay(800);
  yield put(closePartRepeaterPanel(true))
  yield put(updatePartRepeaterLog(null));
}

function* editCompUsage(action) {
  const { record, usageApplyAll } = action;
  const { component, pin, net, signal, pcbId, pcb } = record;
  const { SierraReducer: { sierra, library: { defaultBufferSpice } } } = yield select();
  if (!sierra || !sierra.sierraInfo || !sierra.sierraInfo.Interfaces) {
    return;
  }
  let _Interfaces = [...sierra.sierraInfo.Interfaces];

  const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
  const editInterface = _Interfaces[_index].content;
  let components = [...editInterface.components];
  yield put(updateBufferModelStatus(true))
  let signals = [], compList = [];
  signals.push(record.signal);
  compList.push(`${pcbId}::${component}`);

  if (usageApplyAll) {
    //apply usage to all pins of current component
    components = yield call(updateAllComponentPinsUsage, { pcbId, components, component, usage: record.usage, defaultBufferSpice, signals, compList })
  } else {
    //update current component pin usage and model
    const compIndex = components.findIndex(comp => comp.name === component);
    const pinModelIndex = components[compIndex].pins.findIndex(item =>
      item.pin === pin && item.net === net && item.signal === signal
    );
    const prevUsage = components[compIndex].pins[pinModelIndex].usage;
    components[compIndex].pins[pinModelIndex].usage = record.usage;
    // Delete model or not, stimulus
    const editItem = components[compIndex].pins[pinModelIndex];
    if (!editItem.model) {
      editItem.model = {};
    }
    const prevModelType = editItem.model.modelType
    const newUsage = editItem.usage;

    let model = components[compIndex].model, libType = "", isSetDefault = true;
    if (model && model.files && model.files[0]) {
      libType = model.files[0].type;
      isSetDefault = model.files[0].libraryId && model.files[0].fileName && defaultBufferSpice && model.files[0].libraryId !== defaultBufferSpice.libraryId ? false : isSetDefault;
    }
    if (libType === MultiPinSPICE) {
      //if lib type is MultiPinSPICE,update model pairs
      components = multiPinSpiceComponent({ components, component, pin, prevUsage, usage: record.usage });
      components[compIndex].pins[pinModelIndex].powerOff = "";
    } else {
      const shouldClear = (prevModelType && ONLY_DRIVER_MODEL_TYPES.includes(prevModelType) && newUsage === 'Receiver')
        || (prevModelType && INPUT.includes(prevModelType) && newUsage === 'Driver')

      //delete model by usage and model type
      if (editItem && editItem.model && editItem.model.libraryId && shouldClear) {
        components[compIndex].pins[pinModelIndex].model = {
          ...editItem.model,
          modelName: "",
          modelType: "",
          enableVoltage: ""
        }
      }

      //if default spice file exist, auto assign spice receiver model
      if (record.usage === "Receiver"
        && isSetDefault
        && defaultBufferSpice
        && defaultBufferSpice.libraryId
        && (!editItem.model || !editItem.model.libraryId || !editItem.model.fileName || !editItem.model.modelName)) {
        const model = yield call(setDefaultSpiceBufferModel, { defaultBufferSpice, usage: record.usage })
        editItem.model = model ? model : editItem.model
        components[compIndex].pins[pinModelIndex].model = editItem.model;
        if (model && model.libraryId && (!components[compIndex].model || !components[compIndex].model.files || !components[compIndex].model.files.length || !components[compIndex].model.files.find(it => !!it.libraryId))) {
          components[compIndex].model = {
            files: [{
              type: "SPICE",
              libraryId: editItem.model.libraryId,
              component: "",
              fileName: editItem.model.fileName,
              version: editItem.model.version
            }]
          }
        }
      }

      if (editItem.usage === "Receiver") {
        editItem.pinModels = []
      }

      let deviceVcc = ""
      //update device vcc and pinModels (nd_pu,nd_pd,nd_in)
      if (editItem.usage === "Driver" && (!editItem.pinModels || !editItem.pinModels.length)) {

        if (editItem.model.libType === "IBIS" && editItem.model.libraryId) {
          const models = yield call(getIbisModelList, { libraryId: editItem.model.libraryId, usage: '' }) || {}
          const modelInfo = models ? (models.models || []).find(item => item.name === editItem.model.modelName) : null;
          deviceVcc = modelInfo && modelInfo.deviceVcc && modelInfo.deviceVcc !== "NA" ? parseFloat(modelInfo.deviceVcc).toString() : "";
          components[compIndex].deviceVcc = deviceVcc || "";
        }

        editItem.pinModels = updateICPinModelsByBuffer({
          pinModels: editItem.pinModels,
          usage: editItem.usage,
          libType: editItem.model.libType,
          modelType: editItem.model.modelType || "I/O",
          pu: deviceVcc,
          pd: "0",
          isUsageUpdate: true
        })
      }

      components[compIndex].pins[pinModelIndex].pinModels = editItem.pinModels;
    }
  }
  _Interfaces[_index].content.components = [...components];
  if (record.usage === 'Driver') {
    const model = yield call(setDefaultSpiceBufferModel, { defaultBufferSpice, usage: "Receiver" })
    yield call(setDefaultUsageByDriver, { _Interfaces, signals, compList, model })
  }
  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId }));
  yield put(updateBufferModelStatus(false))
}

function* _reCreateVerification(action) {
  const { selectKeys = [], cancel = false } = action;
  const { SierraReducer: { sierra: { duplicationVerificationInfo } } } = yield select();
  try {
    if (!duplicationVerificationInfo || cancel) {
      yield put(updateDuplicationVerifications(null))
      /*  yield put(changeIdentificationVisible(false)) */
      return;
    }
    const newInterfacesInfo = duplicationVerificationInfo.newInterfacesInfo || [];
    let verificationParams = (duplicationVerificationInfo.repeatVerifications || []).filter(item => item.verificationParam && selectKeys.includes(item.verificationParam.name));
    verificationParams = [...newInterfacesInfo, ...verificationParams];
    if (!verificationParams.length) {
      yield put(updateDuplicationVerifications(null))
      yield put(updateCreatePanelLoading(true));
      return;
    }
    const groupName = duplicationVerificationInfo.groupName;
    yield put(updateDuplicationVerifications({ ...duplicationVerificationInfo, loading: true }))
    const response = yield call(updateMultipleInterfacePromise, { verificationParams: verificationParams, enableFilter: false });
    yield put(updateCreatePanelLoading(true));
    yield put(updateDuplicationVerifications(null));

    const verificationId = response ? response.verificationId : "";

    if (response.key) {
      yield put(startIdentificationCreating({ createKey: response.key, verificationId, groupName }));
      return;
    }
  } catch (error) {
    yield put(updateDuplicationVerifications(null))
  }
}

function* _startCreateInterfaces(action) {
  const { createKey, verificationId, groupName } = action;
  yield put(updateCreateInterfacesStatus({ status: CREATE_RUNNING, logs: ["Start creating interfaces..."] }));

  let status = CREATE_RUNNING, logs = [];
  while (status !== CREATE_SUCCESS) {
    try {
      const response = yield call(getCreateInterfaceStatusInfo, createKey);
      if (!response) {
        status = CREATE_FAILED;
        break;
      }
      status = response.status;
      logs = response.logs;
      yield put(updateCreateInterfacesStatus({ status, logs }));
      yield delay(1500)
    } catch (error) {
      status = CREATE_FAILED;
      yield put(updateCreateInterfacesStatus({ status, logs: [...logs, "Failed to get the status of creating interfaces!"] }));
      break;
    }
  }

  yield put(updateCreateInterfacesStatus(null));

  if (verificationId) {
    yield put(changeIdentificationVisible(false))
    // update selectKeys
    const { SierraReducer: { project: { selectedKeys, viewList, layout, currentProjectId } } } = yield select();

    if (groupName) {
      yield put(updateProjectMenu({ openProjectId: currentProjectId }))
      yield put(updateMultiInterfaceSetupStatus(groupName))
    } else {
      let list = [...viewList, VERIFICATION];
      list = [...new Set(list)];
      list = list.filter(item => ![RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
      if (layout === 3) {
        list = [VERIFICATION]
      }
      yield put(changeViewList(list))
      // selectedKeys  viewList
      if (list.length > 1 && selectedKeys.length > 0) {
        let newKeys = [];
        selectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (![RESULT, VERIFICATION, EXPERIMENTS_RESULT, RESULT, VERIFICATION_GROUP].includes(key)) {
            newKeys.push(item);
          }
        })
        newKeys.push(`${VERIFICATION}-${verificationId}`);
        yield put(changeTreeSelected([...newKeys]));
      } else {
        yield put(changeTreeSelected([`${VERIFICATION}-${verificationId}`]));
      }

      // show the last one interface
      yield put(updateProjectMenu({ openProjectId: currentProjectId, verificationId }))
      yield call(openVerificationPage, { view: VERIFICATION, verificationId })
    }
  }
}

function* _preLayoutReplace(action) {
  const { pcbIds } = action;
  yield put(updatePreInfoReplaceInfo([{ loading: true }]));
  const { SierraReducer: { sierra: { sierraInfo } } } = yield select();
  let Interfaces = [...sierraInfo.Interfaces || []];
  let _info = sierraInfo.info;
  let _prevSignal = _info && _info.signals ? _info.signals.filter(item => !item.pcb) : [];
  yield* Interfaces.map(function* (info) {
    if (!pcbIds.includes(info.pcbId)) {
      return;
    }

    if (!info.content || !info.content.components.length || !info.content.signals || !info.content.signals.length) {
      return;
    }
    const preLayoutInfo = yield call(preLayoutData.getPreLayout, info.pcbId);
    const { newSignals, newComps, newPowerNets } = updateInterfaceByPreLayout({
      preLayoutInfo,
      components: info.content.components,
      signals: info.content.signals,
      powerNets: info.content.powerNets,
      pcbId: info.pcbId
    });
    info.content.components = newComps || [];
    info.content.signals = newSignals || [];
    info.content.powerNets = newPowerNets || [];
  })

  Interfaces = connectorHandle(Interfaces);

  // Update info
  const _name = sierraInfo.info.name;
  const SETUP = SierraVerify;
  let info = SETUP.mergeInterfacesInfo(Interfaces, _name);
  const connectorList = getConnectorList(Interfaces);
  //add signals that have no pcb
  info.signals = [...info.signals, ..._prevSignal];
  yield put(updateSierraInterface({ ...info }));
  yield put(updateSierraInfo({ Interfaces, connectorList }));
  yield put(changeConnectorStatus(true))
  yield put(updatePreInfoReplaceInfo(null));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* sierraSaga() {
  yield takeEvery(GET_VERIFICATION_CONTENT, getContent);
  yield takeLatest(ADD_SIGNAL, addSignal);
  yield takeLatest(ADD_PCBS_IN_SIGNAL, addPCBSINSignal);
  yield takeEvery(OPEN_PAGE, openVerificationPage);
  yield takeEvery(EDIT_SIGNAL, editSignal);
  yield takeEvery(UPDATE_INTERFACES, _updateInterfaces);
  yield takeEvery(ASSIGN_MODEL, assignModel);
  yield takeEvery(SAVE_MODEL_PINS, saveModelPins);
  yield takeEvery(ADD_POWER_GROUND_NETS, addPowerGNDNets);
  yield takeEvery(ADD_PCBS_IN_POWER_NETS, addPCBsInPGNets);
  yield takeEvery(PG_NETS_SELECTION, pgNetsSeletion);
  yield takeEvery(NET_VOLTAGE_UPDATE, netVoltageUpdate);
  yield takeEvery(CREATE_CONFIG, createConfig);
  yield takeEvery(EDIT_CHANNEL_TYPE, editChannelType);
  yield takeEvery(UPDATE_CHANNEL_MODEL, updateChannel);
  yield takeEvery(UPDATE_OPTIONS, _updateOptions);
  yield takeEvery(UPDATE_OPTIONS_BY_CLOSE, _updateOptionsByClose);
  yield takeEvery(SAVE_CONNECTOR, saveConnector);
  yield takeEvery(EDIT_COMPONENT_TYPE, editComponentType);
  yield takeEvery(EDIT_SIGNAL_NAME, editSignalName);
  yield takeEvery(DELETE_SIGNAL, deleteSignal);
  yield takeEvery(DELETE_PG_NETS, deletePGNets);
  yield takeEvery(SAVE_REPEATER_MODEL, saveRepeaterModel);
  yield takeEvery(SAVE_POWER_OFF, savePowerOff);
  yield takeEvery(SAVE_CHIP_MODEL, saveChipModel);
  yield takeEvery(SAVE_RLC_MODEL, saveRLCModel);
  yield takeEvery(SAVE_EXTRACTION, saveExtraction);
  yield takeEvery(SAVE_RE_EXTRACTION, doReExtraction);
  yield takeEvery(SAVE_CURRENT_VERIFICATION, saveCurrentVerificationToServer);
  yield takeEvery(CURRENT_VERIFICATION_DEBUG_VERIFY, verificationDebugVerify);
  yield takeEvery(UPDATE_INTERFACE_LIBRARY, updateInterfaceLibrary);
  yield takeEvery(SAVE_COMP_PACKAGE_MODEL, saveComponentPkgModel);
  yield takeEvery(UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS, _UpdateTouchstoneStatus)
  yield takeEvery(SAVE_PIN_STIMULUS_MODEL, _savePinsModel);
  yield takeEvery(UPDATE_COMPONENTS, updateCompComponent);
  yield takeEvery(SAVE_SPLIT_COMPONENTS, saveSplitComponents);
  yield takeEvery(SAVE_MERGE_COMPONENTS, saveMergeComponents);
  yield takeEvery(UPDATE_PROBE_PINS, _updateCompProbePins);
  yield takeEvery(UPDATE_REFERENCE_NETS, updateRefNets);
  yield takeEvery(UPDATE_INTERFACE_REFERENCE_NETS, updateRefNetsToPowerNets);
  yield takeEvery(UPDATE_INTERFACE_AFTER_LIBRARY_UPDATE, toUploadInterfaces);
  yield takeEvery(UPDATE_PORT_SETUPS_TO_SERVER, updatePortSetup);
  yield takeEvery(SAVE_CONNECTOR_GROUP, saveConnectionGroup);
  yield takeEvery(CREATE_INTERFACE_IDENTIFICATION, createInterfaceIdentification)
  yield takeEvery(UPDATE_VERIFICATION_SETTING, updateVerificationSetting)
  yield takeEvery(INTERFACE_PCB_REPLACE, interfacePCBReplace);
  yield takeEvery(UPDATE_COMP_SETTING_SETUP, _updateCompSettingSetup);
  yield takeEvery(SAVE_VIRTUAL_COMPONENT, _saveVirtualComps);
  yield takeEvery(UPDATE_SETUP_AFTER_CLOSE_SETTINGS, _updateSetupBySettings);
  yield takeEvery(UPDATE_SETUP_AFTER_CLOSE_PART_LIBRARY, _updateSetupByPartLibrary);
  yield takeEvery(UPDATE_PART_REPEATER, _updatePartRepeaterInfo);
  yield takeEvery(UPDATE_COMPONENT_PIN_USAGE, editCompUsage);
  yield takeEvery(SAVE_VERIFICATION_CONTENT_TO_SERVER, saveVerificationContentToServer)
  yield takeEvery(RECREATE_DUPLICATION_VERIFICATIONS, _reCreateVerification);
  yield takeEvery(START_IDENTIFICATION_CREATING, _startCreateInterfaces);
  yield takeEvery(UPDATE_PRE_LAYOUT_REPLACE, _preLayoutReplace);
}

export default sierraSaga;