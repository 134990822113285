import React, { Component } from 'react';
import { connect } from 'react-redux';
import IRTable from './IRTable';
import ComponentsTable from './componentsTable'
import { Row, Col, Spin } from 'antd';
import projectDesigns from '@/services/helper/projectDesigns';
import TargetIC from '../components/targetIC';
import PCBSelect from '../components/PCBSelect';
import { changeTargetIC, refreshIR, updateSelectPowerNets, changePCB, updateCompRLCPrefix } from '../store/IRExplorer/action'
import { getNets } from '@/services/Cascade/helper/setupData';
import componentSetting from '../../../services/Cascade/helper/compSettingHelper';
import DelConfirm from '@/components/DelConfirm';
import './index.css';

class IRContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nets: [],
      loadComponents: [],
      width: null
    }
  }

  componentDidMount() {
    const { nets } = this.state;
    const { designId } = this.props;
    if ((!nets || nets.length === 0) && designId) {
      this.getNetsOptions(designId);
    }
    window.addEventListener('resize', this.resize.bind(this));
    this.resize({ target: { innerWidth: window.innerWidth } });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize = (e) => {
    this.setState({
      width: e.target.innerWidth ? e.target.innerWidth - 300 : null
    })
  }

  componentDidUpdate(prevProps) {
    const { verificationId, designId, IRloading, irLoadSelect } = this.props;
    const { nets } = this.state;
    if (((!nets || nets.length === 0) && designId && (!IRloading && IRloading !== prevProps.IRloading))
      || verificationId !== prevProps.verificationId
      || (irLoadSelect.length && irLoadSelect.length !== prevProps.irLoadSelect.length)
      || designId !== prevProps.designId) {
      this.getNetsOptions(designId);
    }
  }

  getNetsOptions = async (designId) => {
    const netsInfo = getNets(designId);
    if (netsInfo) {
      const { nets, loadComponents, vrm } = netsInfo;
      this.setState({
        nets,
        loadComponents,
        vrm
      })
    }
  }

  updateCompPrefixVersion = async (bool) => {
    const { designId } = this.props;
    const version = await componentSetting.getVersion(designId);
    this.props.updateCompRLCPrefix({ version }, bool)
  }

  render() {
    const { nets, loadComponents, vrm, width } = this.state;
    const { IRTableLoading, irLoadSelect, powerNetSelect, IRloading, contentWidth,
      designId, projectId, designStatus, updateCompPrefix, pcbUpdate, designName } = this.props;
    const noPCB = designId && !projectDesigns.getDesignExist(projectId, designId)
    return (
      <div className='cascade-ir-content'>
        <Spin spinning={IRloading} >
          <Row>
            <Col span={24} className='cascade-setup-border'>
              <Spin spinning={IRTableLoading}>
                <div className='cascade-pcb-select-box'>
                  <PCBSelect
                    pcbId={designId}
                    projectId={projectId}
                    selectPCB={this.props.changePCB}
                    noPCB={noPCB}
                    designStatus={designStatus}
                    pcbUpdate={pcbUpdate}
                    designName={designName} />
                </div>
                <div className='cascade-pcb-target-box'>
                  <TargetIC
                    loadComponents={loadComponents}
                    _changeTargetIC={this.props._changeTargetIC}
                    loadSelect={irLoadSelect}
                    refresh={this.props.refreshIR}
                    powerNetSelect={powerNetSelect}
                    loading={IRloading}
                    updateSelectPowerNets={this.props.updateSelectPowerNets}
                    noPCB={noPCB}
                  />
                </div>
              </Spin>
            </Col>
          </Row>
          <Row className='space-16'>
            <Col span={24} className='cascade-setup-border'>
              <IRTable
                nets={nets}
                loadComponents={loadComponents}
                vrm={vrm || []}
                contentWidth={contentWidth ? contentWidth : width}
                noPCB={noPCB}
              />
            </Col>
          </Row>
          <Row className='space-16'>
            <Col span={24} className='cascade-setup-border'>
              <ComponentsTable noPCB={noPCB} />
            </Col>
          </Row>
          <div id="IR-content-dialog"></div>
          {updateCompPrefix ? <DelConfirm
            type='update'
            onUpdate={() => this.updateCompPrefixVersion(true)}
            cancelUpdate={() => this.updateCompPrefixVersion(false)}
            message={"The Component Setting of the current PCB has been updated. Do you want to update the Power Domains?"}
          /> : null}
        </Spin>
      </div>
    )
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { viewList, openProjectId, designStatus, treeItems, pcbUpdate },
    IR: { verificationId, designId, designName, updateCompPrefix, IRloading, powerNetSelect, IRExplorerInfo: { irLoadSelect = [] }, IRTableLoading } } } = state

  return {
    viewList,
    projectId: openProjectId,
    verificationId,
    maxIRDataLength: irLoadSelect.length,
    designId,
    IRloading,
    irLoadSelect,
    IRTableLoading,
    powerNetSelect,
    designStatus,
    treeItems,
    updateCompPrefix,
    pcbUpdate,
    designName
  };
}

const mapDispatch = (dispatch) => ({
  _changeTargetIC(value) {
    dispatch(changeTargetIC(value))
  },
  refreshIR(selectNets) {
    dispatch(refreshIR(selectNets))
  },
  updateSelectPowerNets(obj) {
    dispatch(updateSelectPowerNets(obj))
  },
  changePCB(designId) {
    dispatch(changePCB(designId))
  },
  updateCompRLCPrefix(COMP_PREFIX_LIB, update) {
    dispatch(updateCompRLCPrefix(COMP_PREFIX_LIB, update))
  }
})

export default connect(mapState, mapDispatch)(IRContent);