import { takeEvery, select, put, call, delay } from "@redux-saga/core/effects";
import { START_END_TO_END_SIMULATION } from "../actionTypes";
import {
  updateSimSelectKeys,
  updateSimulationReducer,
  updateMultiSimulationInfo
} from '../action';
import { checkChannelSimStatus, startNewWorkflow, updateResultExistStatus } from '../saga';
import endToEndChannelConstructor from "@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor";
import { autoGetEndToEndList } from '../../endToEndChannel/action';
import { endToEndStartSimulation, getEndToEndChannelContent } from '@/services/Andes_v2/endToEndChannel';
import { END_TO_END_CHANNEL } from "@/constants/treeConstants";
import { openPage } from "../../project/action";
import { getVerificationWorkFlow } from "@/services/workflow/workflow";
import { openTabFooter, changeTabMenu } from "../../../../MonitorStore/action";
import { saveEndToEndChannelContentToServer, _checkEndToEndSetup } from '../../endToEndChannel/endToEndChannelSaga';
import { endToEndErrorCheck } from '../../../EndToEndChannel/endToEndErrorCheck';
import { channelErrorCheck } from "../../../Channel/errorCheck";
import { getChannelContentPromise } from "@/services/Andes_v2/channel";
/* import { channelSeaSimConfigCheck } from '@/services/Andes_v2/seaSim'; */
import { isPreLayout } from "@/services/Andes_v2";
import { getPreLayoutInfoById, getPreLayoutErrorCheck } from "@/services/Andes_v2/preLayout";
import { getDesignStackupJson } from "@/services/api/designFile";
import { stackupErrorCheck } from "@/services/Stackup";
import { eyeDiagramResult } from '@/services/Andes_v2/results/eyeDiagram';
import { adsConfigErrorCheck } from "../../../../../services/Andes_v2/AMIModelHelper";
import { ANDES_V2 } from "../../../../../constants/pageType";
import LayoutData from '@/services/data/LayoutData';

function* _startSimulation(action) {
  const { endToEndChannelIds, runSeasim, runAds } = action;
  //clear checkbox end to end channel
  yield put(updateSimSelectKeys([], "endToEnd"));
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo }, project: { viewList, openProjectId }, simulation } } = yield select();
  let _simulation = { ...simulation }, currentSimInfo = [], simulationEndToEndIds = [], verificationIds = [], openEndToEnd = null;

  //clear eye diagram cache
  eyeDiagramResult.cleanEyeDiagram(endToEndChannelIds.map(item => { return { id: item, type: "compliance" } }));
  eyeDiagramResult.cleanEyeDiagram(endToEndChannelIds.map(item => { return { id: item, type: "ads" } }));

  let _endToEndChannelIds = [];

  for (let id of endToEndChannelIds) {
    const endToEndChannel = endToEndChannelConstructor.getEndToEndChannel(id);
    if (!endToEndChannel) {
      continue;
    }

    //check prev simulation status,if running or waiting, return
    if (yield call(checkChannelSimStatus, endToEndChannel.verificationId)) {
      continue;
    }

    _endToEndChannelIds.push(id);
    //clear prev simulation info
    _simulation[endToEndChannel.verificationId] && delete _simulation[endToEndChannel.verificationId];
    currentSimInfo.push({
      endToEndChannelId: id,
      verificationId: endToEndChannel.verificationId,
      status: 'checking'
    });
    _simulation[endToEndChannel.verificationId] = {
      startMsg: '==> Checking setup...',
    }
    yield put(autoGetEndToEndList({ projectId: openProjectId, currentSimInfo }));
  }

  yield put(updateSimulationReducer(_simulation));

  if (_endToEndChannelIds.length === 0) {
    return;
  }

  for (let id of _endToEndChannelIds) {
    const endToEndChannel = endToEndChannelConstructor.getEndToEndChannel(id);
    if (!endToEndChannel) {
      continue;
    }

    if (endToEndChannelInfo && viewList.includes(END_TO_END_CHANNEL) && id === endToEndChannelInfo.id) {
      //save setup json to server
      yield call(saveEndToEndChannelContentToServer);
      yield put(openTabFooter());
      yield put(changeTabMenu({
        tabSelectKeys: ["monitor"],
        currentVerificationId: endToEndChannel.verificationId,
        verificationName: endToEndChannel.name,
        menuType: "simulation"
      }));
      openEndToEnd = endToEndChannel;
    }
    //check end to end channel and children channel setup 
    const { errorCheck, seasimConfigErrors, adsConfigErrors, channelsErrorCheckList } = yield call(checkSetup, { id, runSeasim, runAds });
    let simulationStatus = "success";
    if ((errorCheck && errorCheck.length)
      || (seasimConfigErrors && seasimConfigErrors.length)
      || (adsConfigErrors && adsConfigErrors.length)
      || channelsErrorCheckList.length) {
      _simulation[endToEndChannel.verificationId] = {
        errorCheck,
        channelsErrorCheckList,
        seasimConfigErrors,
        adsConfigErrors
      }
      simulationStatus = "error";

      //update channel setup error check
      for (let item of channelsErrorCheckList) {
        _simulation[item.verificationId] = {
          errorCheck: item.errorCheck,
          preLayoutErrors: item.preLayoutErrors,
          stackupError: item.stackupError
        };
      }
    } else {
      _simulation[endToEndChannel.verificationId] = {
        startMsg: '==> Start simulating...',
      }
      simulationEndToEndIds.push(id);
      verificationIds.push(endToEndChannel.verificationId);
    }
    const index = currentSimInfo.findIndex(it => it.endToEndChannelId === id);
    if (index > -1) {
      currentSimInfo[index].status = simulationStatus;
    } else {
      currentSimInfo.push({
        endToEndChannelId: id,
        verificationId: endToEndChannel.verificationId,
        status: simulationStatus
      });
    }
  }
  yield put(updateSimulationReducer(_simulation));
  yield put(autoGetEndToEndList({ projectId: openProjectId, currentSimInfo }));

  if (simulationEndToEndIds.length === 0) {
    return;
  }

  if (!openEndToEnd) {
    //open channel
    yield put(openPage({ pageType: END_TO_END_CHANNEL, id: simulationEndToEndIds[0], simulating: true }));
    openEndToEnd = endToEndChannelConstructor.getEndToEndChannel(simulationEndToEndIds[0]) || {};
  }

  try {
    const response = yield call(endToEndStartSimulation, { endToEndChannelIds: simulationEndToEndIds, runSeasim, runAds });

    if (!response || !Array.isArray(response) || response.length === 0) {
      verificationIds.forEach(item => {
        _simulation[item] = {
          endMsg: response && response.msg ? response.msg : '==> Simulation failed!'
        };
      })
      //update simulation reducer
      yield put(updateSimulationReducer(_simulation));
      yield put(autoGetEndToEndList({ projectId: openProjectId }));
      return;
    }

    yield put(autoGetEndToEndList({ projectId: openProjectId }));
    if (openEndToEnd && openEndToEnd.verificationId) {
      //is exist result
      yield call(updateResultExistStatus, {
        workType: END_TO_END_CHANNEL,
        verificationId: openEndToEnd.verificationId,
        endToEndChannelId: openEndToEnd.id
      });
      const verificationStatus = yield call(getVerificationWorkFlow, openEndToEnd.verificationId);
      if (verificationStatus && verificationStatus.length > 0) {
        yield call(startNewWorkflow, {
          workType: END_TO_END_CHANNEL,
          endToEndChannelId: openEndToEnd.id,
          verificationId: openEndToEnd.verificationId,
          verificationWork: verificationStatus
        })
        return;
      }
    }
  } catch (error) {
    console.error(error);
    yield delay(1000);
    if (openEndToEnd.verificationId) {
      //clear current open channel stimulation info
      yield put(updateMultiSimulationInfo({
        verificationId: openEndToEnd.verificationId,
        info: {
          startMsg: null,
          progress: -1,
          endMsg: error ? `${error}` : '==> Simulation failed!'
        }
      }));
      //is exist result
      yield call(updateResultExistStatus, {
        workType: END_TO_END_CHANNEL,
        verificationId: openEndToEnd.verificationId,
        endToEndChannelId: openEndToEnd.id
      });
    }
    yield put(autoGetEndToEndList({ projectId: openProjectId }));
  }
}

function* checkSetup(action) {
  const { id, /* runSeasim, */ runAds } = action;
  //get setup json
  let res = yield call(getEndToEndChannelContent, id) || {};

  //check end to end channel setup by channel setup json
  res = yield call(_checkEndToEndSetup, { setupInfo: res, updateClip: true });

  //error check
  const errorCheck = endToEndErrorCheck(res.content);
  if (errorCheck) {
    return { errorCheck, channelsErrorCheckList: [] }
  }
  let seasimConfigErrors = null;
  /* if (runSeasim) {
    seasimConfigErrors = channelSeaSimConfigCheck(res, true);
  } */

  if (seasimConfigErrors) {
    return { errorCheck, seasimConfigErrors, channelsErrorCheckList: [] }
  }

  let adsConfigErrors = null;
  if (runAds) {
    const selectedSignals = res.adsConfig && res.adsConfig.signals ? res.adsConfig.signals.map(item => item.signalName) : [];
    adsConfigErrors = adsConfigErrorCheck(res.adsConfig, selectedSignals, true, res.type);
  }

  if (adsConfigErrors) {
    return { errorCheck, adsConfigErrors, channelsErrorCheckList: [] }
  }
  //end to end channel children channels error check
  const channelsErrorCheckList = yield call(getChannelErrorCheck, res);
  //re check end to end channel setup by channel setup json
  res = yield call(_checkEndToEndSetup, { setupInfo: res, updateClip: true });
  return { errorCheck, seasimConfigErrors, channelsErrorCheckList }
}

function* getChannelErrorCheck(endToEndInfo) {
  if (!endToEndInfo || !endToEndInfo.content) {
    return [];
  }
  let channelsErrorCheckList = [];
  const pcbConnections = endToEndInfo.content.pcbConnections || [],
    connections = endToEndInfo.content.connections || [];

  for (let i = 0; i < pcbConnections.length; i++) {
    const conn = pcbConnections[i];
    if (!conn.channelId) {
      continue;
    }
    //clear eye diagram cache
    eyeDiagramResult.cleanEyeDiagram([{ id: conn.channelId, type: "compliance" }, { id: conn.channelId, type: "ads" }]);
    //get channel setup json
    const channelSetup = yield call(getChannelContentPromise, conn.channelId);
    if (!channelSetup) {
      continue;
    }
    //find selected signals
    let type = "", connectionId = null;
    if (conn.nextConnectionId) {
      type = "1";
      connectionId = conn.nextConnectionId;
    } else if (conn.prevConnectionId) {
      type = "2";
      connectionId = conn.prevConnectionId;
    }

    const connection = connections.find(item => item.CONNECTION_ID === connectionId);
    if (!connection) {
      continue;
    }
    //find selected signals
    const signalMap = connection.connection.signal_connections_map || [];
    const checkedSignals = signalMap.map(item => item[`channel${type}_signal`]);
    let stackup = {};
    const isPreLayoutChannel = isPreLayout(conn.designId);
    if (!isPreLayoutChannel) {
      stackup = yield call(getDesignStackupJson, conn.designId) || {};
    }
    const errorCheck = yield call(channelErrorCheck, channelSetup, checkedSignals, null, stackup.materials);
    if (errorCheck && !isPreLayoutChannel) {
      yield call(LayoutData.getStackupJson, { pcbId: conn.designId });
    }

    let preLayoutErrors = [], stackupError = [];
    //pre layout setup check
    if (isPreLayoutChannel) {
      const preLayoutInfo = yield call(getPreLayoutInfoById, conn.designId);
      preLayoutErrors = getPreLayoutErrorCheck(preLayoutInfo);
    } else {
      //check stackup
      if (!stackup || !stackup.layers) {
        stackupError = [{ error: "Stackup file not exist." }]
      } else {
        const extractionSolver = channelSetup && channelSetup.content && channelSetup.content.extraction ? channelSetup.content.extraction.type : null;
        stackupError = stackupErrorCheck({
          ...stackup,
          layers: stackup.layers,
          materialList: stackup.materials,
          unit: stackup.unit,
          extractionSolver,
          pageType: ANDES_V2
        });
      }
    }
    if (errorCheck || (preLayoutErrors && preLayoutErrors.length) || (stackupError && stackupError.length)) {
      channelsErrorCheckList.push({
        channelId: conn.channelId,
        verificationId: channelSetup.verificationId,
        displayName: `PCB${i + 1} (${conn.designName} - ${conn.channelName})`,
        errorCheck,
        preLayoutErrors,
        stackupError
      })
    }
  }
  return channelsErrorCheckList;
}

function* endToEndSimulationSaga() {
  yield takeEvery(START_END_TO_END_SIMULATION, _startSimulation);
}

export default endToEndSimulationSaga;