import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TopMenu from './TopMenu';
import Sparameter from './sparameter/index';
import Effective from './effective';
import Decap from './decap/index';
import DecapGroup from './decapGroup/index';
import {
  changeResultKey,
  backToImpedance,
  getHistorys,
  saveHistory,
  deleteHistory,
  saveCompare,
  getImports,
  deleteImport,
  getCompares
} from '../../store/result/Impedance/action';
import Transient from './transient';
import RdieCdie from './rdieCdie';

class ImpResultContent extends PureComponent {

  componentDidMount() {
    this.props.getHistorys();
    this.props.getImports();
    this.props.getCompares();
  }

  componentUpdate = (prevProps) => {
    if (this.props.verificationId && prevProps.verificationId !== this.props.verificationId) {
      this.props.getHistorys()
      this.props.getImports()
      this.props.getCompares()
    }
  }

  changeSelectKey = (key) => {
    if (key === 'setup') {
      this.props.backToImpedance()
    } else {
      this.props.changeResultKey(key)
    }
  }

  getResultRender = (key) => {
    switch (key) {
      case 'effective':
        return this.effectiveRender();
      case 'impedance':
      default:
        return this.sparameterRender();
      case "decap":
        return this.decapRender()
      case "decapGroup":
        return this.decapGroupRender()
      case "transient":
        return this.transientRender()
      case "rdieCdie":
        return this.rdieCdieRender()
    }
  }

  sparameterRender = () => {
    const { data, verificationId, historys, updateHistory, imports, updateImports, resultExist, compares, updateCompare, target } = this.props;
    const exist = resultExist ? resultExist.exist : false;
    return <Sparameter
      ImpedanceContent={data}
      target={target}
      id={verificationId}
      historys={historys}
      imports={imports}
      updateHistory={updateHistory}
      updateImports={updateImports}
      deleteHistory={this.props.deleteHistory}
      getImports={this.props.getImports}
      deleteImport={this.props.deleteImport}
      compares={compares}
      updateCompare={updateCompare}
      updateCompareList={this.props.saveCompare}
      exist={exist}
    />
  }

  effectiveRender = () => {
    const { verificationId, historys, updateHistory, compares, updateCompare } = this.props;
    return <Effective
      id={verificationId}
      historys={historys}
      updateHistory={updateHistory}
      deleteHistory={this.props.deleteHistory}
      compares={compares}
      updateCompare={updateCompare}
      updateCompareList={this.props.saveCompare}
    />
  }

  decapRender = () => {
    const { verificationId, historys, updateHistory } = this.props
    return <Decap
      id={verificationId}
      historys={historys}
      updateHistory={updateHistory}
      deleteHistory={this.props.deleteHistory}
    />
  }

  decapGroupRender = () => {
    const { verificationId, historys, updateHistory } = this.props
    return <DecapGroup
      id={verificationId}
      historys={historys}
      updateHistory={updateHistory}
      deleteHistory={this.props.deleteHistory}
    />
  }

  transientRender = () => {
    const { verificationId, data } = this.props
    return <Transient
      id={verificationId}
      ImpedanceContent={data}
    />
  }

  rdieCdieRender = () => {
    const { verificationId } = this.props
    return <RdieCdie
      id={verificationId}
    />
  }

  render() {
    const { resultKey, layoutLeftWidth, leftWidth } = this.props;
    return <div className="cascade-result-impedance">
      {TopMenu({
        selectedKey: resultKey,
        changeResultType: this.changeSelectKey,
        layoutLeftWidth,
        leftWidth,
        saveHistory: this.props.saveHistory
      })}
      <div className="cascade-result-impedance-result-content">
        {this.getResultRender(resultKey)}
      </div>
    </div>
  }
}

const mapState = (state) => {
  const { ImpedanceResult: { resultKey, historys, imports, updateHistory, updateImports, compares, updateCompare }, Impedance: { data, verificationId, resultExist, target }, project: { openProjectId } } = state.CascadeReducer
  return {
    resultKey,
    data,
    verificationId,
    openProjectId,
    historys,
    imports,
    updateHistory,
    updateImports,
    resultExist,
    compares,
    updateCompare,
    target
  }
}

const mapDispatch = (dispatch) => ({
  changeResultKey(key) {
    dispatch(changeResultKey(key))
  },
  backToImpedance() {
    dispatch(backToImpedance())
  },
  getHistorys() {
    dispatch(getHistorys())
  },
  saveHistory(name) {
    dispatch(saveHistory(name))
  },
  deleteHistory(id) {
    dispatch(deleteHistory(id))
  },
  saveCompare(compares) {
    dispatch(saveCompare(compares))
  },
  getImports() {
    dispatch(getImports())
  },
  deleteImport(id) {
    dispatch(deleteImport(id))
  },
  getCompares() {
    dispatch(getCompares())
  }
})

export default connect(mapState, mapDispatch)(ImpResultContent);