import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  USER_LOGOUT,
  USER_LOGIN,
  PAGE_TYPE,
  SAVE_SYSTEM_VERSION,
  LOGIN_INFO,
} from './actionTypes';
import { isAuthed } from '@/services/api/auth';

const defaultState = {
  login: isAuthed(),
  userName: null,
  pageType: null,
  email: null,
  version: null,
  userPermissions: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        userName: action.userName,
        email: action.email,
      }
    case LOGIN_FAILED:
      return state;
    case USER_LOGOUT:
      return {
        ...state,
        login: false,
        email: null
      }
    case PAGE_TYPE:
      return {
        ...state,
        pageType: action.pageType
      }
    case SAVE_SYSTEM_VERSION:
      return {
        ...state,
        systemVersion: action.version
      }
    case LOGIN_INFO:
      return {
        ...state,
        email: action.email,
        userName: action.userName
      }
    default:
      return state;
  }
}