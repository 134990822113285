import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import { InfoCircleOutlined, RedoOutlined, RetweetOutlined, ExperimentOutlined } from '@ant-design/icons';
import { Tooltip, Spin, Descriptions } from 'antd';
import ModelSelect from '@/components/ModelSelect/ModelSelect';
import {
  updateImpedanceStatus,
  saveCapModel,
  reAssignDecapModel,
} from '../store/Impedance/action';
import { getLibraryFileInfo, getSysLibraryFile, getCustomLibBySearch } from '@/services/Cascade/library';
import SystemLibDetail from '@/services/Cascade/DB/systemLibDetail';
import { CUSTOM_LIBRARY } from '../../../constants/treeConstants';
import { getCustomLibraryPath } from '../../../services/Cascade/helper/match';
import { MODEL_MATCH_WEIGHTS } from '../../../services/Cascade/constants';
import designConstructor from '../../../services/helper/designConstructor';
import { ImpComponent } from '../../../services/Cascade/Impedance/impedanceClass';
import auroraDBJson from '../../../services/Designs/auroraDbData';
import { COMPONENTBASED, PARTBASED } from '../../../constants/resolution';
import { CASCADE } from '../../../constants/pageType';

const DescItem = Descriptions.Item;

const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '20%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'PCB',
  dataIndex: 'pcbName',
  width: '25%',
},
{
  title: 'Components',
  dataIndex: 'components',
  width: '25%',
}, {
  title: 'Usage',
  dataIndex: 'usage',
  width: '10%',
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '20%',
}];

class CapComponentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPartNumber: {},
      modelUpdate: false,
      lastTypeInfo: {
        vendor: "",
        libraryType: 'system',
        systeamLibraryType: 'vendor',
        userLibraryType: 'SPICE',
        fileType: 'sparameter',
        name: '',
        modelLibraryType: "",
        modelId: 0,
      },
      displayPartNumber: false,
      capCount: 0,
    }
    this.tableDataList = []
  }

  componentDidMount = () => {
    this.setDefaultColumns();
    this.getComponentsData();
  }

  componentDidUpdate = (prevProps) => {
    const { openProjectId, verificationId, status, noPCB, designStatus, page } = this.props;
    if ((openProjectId && openProjectId !== prevProps.openProjectId)
      || (verificationId && verificationId !== prevProps.verificationId)
      || (status && status !== prevProps.status)
      || (designStatus !== prevProps.designStatus && !designStatus)
      || page !== prevProps.page) {
      this.getComponentsData();
    }

    if (prevProps.noPCB !== noPCB) {
      this.setDefaultColumns();
    }

    if (verificationId !== prevProps.verificationId) {
      this.setState({
        displayPartNumber: false
      })
    }
  }

  componentWillUnmount = () => {
    this.setState = () => false;
  }

  setDefaultColumns = () => {

    ComponentsColumns[0].title = () => {
      const { displayPartNumber } = this.state;
      const filterPartNumber = this.tableDataList.filter(item => !!item.partNumber);
      return (
        <div>
          <span>Part {displayPartNumber && filterPartNumber.length ? "Number" : "Name"}</span>
          {filterPartNumber.length ? <Tooltip
            title='Toggle to display part name or part number.'
            mouseLeaveDelay={0} mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >
            <RetweetOutlined
              className="impedance-part-switch-icon"
              onClick={(e) => this.changePartDisplay(e)} />
          </Tooltip> : null}
        </div>
      );
    }

    ComponentsColumns[0].render = (text, record) => {
      const { displayPartNumber } = this.state;
      const partText = displayPartNumber && record.partNumber ? record.partNumber : record.part;
      return <Tooltip title={record.partNumber ? this.displayPartRender(record) : null} mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
        <span className='cursor-pointer cascade-part-name'>{partText}</span>
      </Tooltip>
    }

    ComponentsColumns[0].onCell = (record, index) => {
      const { displayPartNumber } = this.state;
      const dataIndex = displayPartNumber && record.partNumber ? 'partNumber' : 'part';
      const rowSpan = this.calculateRowSpan(index, dataIndex);
      return {
        edit: false,
        rowSpan: rowSpan,
        tdClassName: rowSpan > 0 ? 'cascade-cap-comp-group-border-td' : ''
      }
    }

    ComponentsColumns[1].render = (text, record) => {
      return <div className='impedance-margin-left-3 cascade-cap-comp-pcb-column'>
        <Tooltip title={text} overlayClassName='aurora-tooltip'>
          {text}
        </Tooltip>
      </div>
    }

    ComponentsColumns[2].render = (text, record) => {
      return <div className='impedance-margin-left-3'>
        {text.map(item => item.name).join(', ')}
      </div>;
    }

    ComponentsColumns[3].render = (text, record) => {
      return <div className='impedance-margin-left-3'>{text}</div>
    }

    ComponentsColumns[4].title = () => {
      const { modelAssignLoading } = this.props;
      return (
        <Fragment>
          <span className="cascade-decap-model-title">Model</span>
          <Tooltip
            title='Re-assign decap model.'
            mouseLeaveDelay={0}
            mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >

            {modelAssignLoading ?
              <span className="cascade-decap-model-assigning">Assigning...</span>
              : <RedoOutlined
                className="cascade-decap-re-match-icon"
                onClick={this.props._reAssignDecapModel} />}
          </Tooltip>
        </Fragment>
      );
    }

    ComponentsColumns[4].render = (text, record) => {
      const { value, model, models, applySweep } = record;
      if (value && (value.r || value.l || value.c)) {
        return <span>
          R = {(value.r && (value.r + 'Ω')) || '0'}, L = {(value.l && (value.l + 'H')) || '0H'}, C = {(value.c && (value.c + 'F')) || '0F'}
        </span>
      } else {
        if (models && models.length) {
          if (models.length === 1) {
            const model = models[0]
            let matchWeights = model.matchWeights || null;
            let name = ""
            if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
              name = model.subcktName ? model.subcktName : ''
            } else {
              name = model.name ? model.name : ''
            }
            const modelPath = model.type === 'Custom' ? getCustomLibraryPath(model.path) : null;
            return (
              <span>
                <Tooltip placement="left"
                  overlayClassName='aurora-tooltip system-library-tooltip'
                  title={model.type === CUSTOM_LIBRARY ? (modelPath || name) : this.modelContent(name)}
                  style={{ zIndex: 100000000 }}>
                  <span className="cascade-component-model-span"
                  >{name}</span>
                </Tooltip>
                {matchWeights && matchWeights <= MODEL_MATCH_WEIGHTS ? <Tooltip
                  placement="topRight"
                  overlayClassName='aurora-tooltip'
                  title="The matching similarity between the currently automatically selected Model and Part Number is low. It is recommended to reselect manually."
                >
                  <InfoCircleOutlined className="cascade-component-model-info-icon" />
                </Tooltip> : null}
              </span>
            );
          } else {
            const title = models.map(model => {
              let name = model.editName
              if (!name) {
                if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
                  name = model.subcktName ? model.subcktName : ''
                } else {
                  name = model.name ? model.name : ''
                }
              }
              return name
            }).join(', ')
            return <Fragment>
              <Tooltip
                title={title}
                overlayClassName='aurora-tootip'
              >
                <div className='cascade-decap-sweep-model-column'>{title} </div>
              </Tooltip>
              {applySweep &&
                <Tooltip
                  title='Sweeping'
                  overlayClassName='aurora-tooltip'
                  placement='topRight'
                >
                  <ExperimentOutlined className='cascade-component-model-info-icon' />
                </Tooltip>}
            </Fragment>
          }
        }
        return ''
      }
    }

    ComponentsColumns[4].onCell = (record) => {
      const { DecapList, DecapGeneric, noPCB, singleLayout } = this.props;
      const { selectedPartNumber } = this.state
      if (noPCB || record.isPreLayout) {
        return {
          edit: false
        }
      } else {
        return {
          record,
          edit: true,
          customInput: ModelSelect,
          decapList: DecapList,
          dataIndex: 'modelName',
          getLibraryFile: getLibraryFileInfo,
          getSystemLibraryFile: getSysLibraryFile,
          getSelectedModel: this.getSelectedModel,
          saveDecapModel: this.saveDecapModel,
          onClick: (e) => this.stopProps(e),
          product: CASCADE,
          DecapGeneric: DecapGeneric,
          selectedPartNumber: selectedPartNumber,
          changePartNumber: this.changePartNumber,
          lastTypeInfo: this.state.lastTypeInfo,
          getCustomLibraryPath: getCustomLibraryPath,
          getCustomLibBySearch: getCustomLibBySearch,
          lastTypeInfoChange: this.lastTypeInfoChange,
          supportSweep: singleLayout
        }
      }
    }
  }

  displayPartRender = (record) => {
    const { displayPartNumber } = this.state;
    let partText = record.partNumber, partType = "Number";
    if (displayPartNumber) {
      partText = record.part;
      partType = "Name"
    }

    return <div>
      <span className="font-bold">Part {partType}: </span>
      <span>{partText}</span>
    </div>
  }

  calculateRowSpan = (index, dataIndex) => {
    const data = [...this.tableDataList]
    const currentValue = data[index][dataIndex];
    let rowSpan = 1;
    for (let i = index + 1; i < data.length; i++) {
      if (data[i][dataIndex] === currentValue) {
        rowSpan++;
      } else {
        break;
      }
    }
    if (index > 0 && data[index - 1][dataIndex] === currentValue) {
      return 0;
    }
    return rowSpan;
  };

  changePartDisplay = (e) => {
    e && e.stopPropagation();
    this.setState({
      displayPartNumber: !this.state.displayPartNumber
    })
  }

  stopProps = (e) => {
    e.stopPropagation();
  }

  lastTypeInfoChange = (vendor, libraryType, systeamLibraryType, userLibraryType, fileType, model) => {
    this.setState({
      lastTypeInfo: {
        vendor,
        libraryType,
        systeamLibraryType,
        userLibraryType,
        fileType,
        name: model.name,
        modelLibraryType: model.libraryType,
        modelId: model.id
      }
    })
  }

  modelContent = (name) => {
    let model = SystemLibDetail.getSystemModel(name);
    if (model) {
      return (
        <Descriptions size="small" column={2} bordered={true}>
          <DescItem label="Form Factor" span={2}>{model.formFactor}</DescItem>
          <DescItem label="Capacitance" span={2}>{model.capacitorValue}</DescItem>
          <DescItem label="Voltage Ratinge" span={2}>{model.voltageRating}</DescItem>
          <DescItem label="DC Bias Voltage" span={2}>{model.dcBiasVoltage}</DescItem>
          <DescItem label="Temperature" span={2}>{model.temperature}</DescItem>
        </Descriptions>
      )
    }
  }

  getInitModelList = async () => {
    let { modelUpdate } = this.state;
    let names = [];
    for (let item of this.tableDataList) {
      if (item.model && item.model.type === 'System' && item.model.name) {
        names.push(item.model.name);
      }
    }
    await SystemLibDetail.saveSystemModel([...new Set(names)]);
    this.setState({
      modelUpdate: !modelUpdate
    })
  }

  getComponentsData = () => {
    const { data, componentsTableDisplay } = this.props;
    this.tableDataList = []
    data.forEach(item => {
      let comps = []
      item.powerDomains.forEach(domain => {
        const { id, content } = domain;
        const { MAIN_POWER_NETS } = content;
        const Components = content.Components.filter(i => i.usage === 'Cap')
        for (let comp of Components) {
          if (!comp) {
            continue;
          }
          const { name, part, pins, usage } = comp;
          const isPreLayout = designConstructor.isPreLayout(item.designId);
          const partNumber = !isPreLayout ? auroraDBJson.getPartNumberByPartName(item.designId, part) : null;
          const findIndex = comps.findIndex(item => item.part === part);
          if (componentsTableDisplay === PARTBASED && findIndex > -1) {
            comps[findIndex].components.push({ name, pins, usage })
          } else {
            const pcbInfo = { pcbId: item.designId, pcbName: item.designName }
            comps.push(new ImpComponent({ comp: { ...comp, partNumber }, PowerNets: MAIN_POWER_NETS, id, pcbInfo, isPreLayout }))
          }
        }
      })
      this.tableDataList.push(...comps)
    })
    this.sortTableList()
    const capCount = this.tableDataList.reduce((sum, item) => {
      return sum + (item.components ? item.components.length : 0);
    }, 0);
    this.setState({ capCount })
    this.props.updateImpedanceStatus(false);
    this.getInitModelList()
  }

  sortTableList = () => {
    const { displayPartNumber } = this.state
    const groupedData = this.tableDataList.reduce((acc, current) => {
      const dataIndex = displayPartNumber ? 'partNumber' : 'part';
      const key = current[dataIndex];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(current);
      return acc;
    }, {});
    this.tableDataList = Object.keys(groupedData).reduce((acc, key) => {
      groupedData[key].forEach(item => {
        item.groupKey = key;
        acc.push(item);
      });
      return acc;
    }, []);
  }

  getSelectedModel = () => {
    return null;
  }

  saveDecapModel = (record, value, models, apply, applySweep) => {
    const { applyModelList } = this.props;
    let _applyModelList = [...applyModelList]
    if (models && models.length === 1) {
      const findIndex = applyModelList.findIndex(m => m.partName === record.part);
      const applyModel = { model: models[0], partName: record.part };
      if (findIndex > -1) {
        _applyModelList[findIndex] = { ...applyModel }
      } else {
        _applyModelList = [...applyModelList, { ...applyModel }]
      }
    }
    this.props.saveCapModel(record, value, models, apply, _applyModelList, applySweep);
  }

  changePartNumber = (item) => {
    this.setState({
      selectedPartNumber: item
    })
  }

  render() {
    const { loading } = this.props;
    const { capCount } = this.state;
    const dataSource = this.tableDataList.map((item, rowIndex) => ({ ...item, rowIndex }))
    return <div className='cascade-cap-components-table'>
      <span className="font-bold cascade-setup-title-color">Cap Components</span>
      <span className="font-bold cascade-setup-title-color cascade-setup-title-right">{`Total Cap Count: ${capCount}`}</span>
      <div className="space-10">
        <Spin spinning={loading ? true : false}>
          <EditableTable
            rowKey={(record) => record.rowIndex}
            onRow={(record, index) => {
              if (this.tableDataList[index + 1] && record.groupKey !== this.tableDataList[index + 1].groupKey) {
                return {
                  className: 'cascade-cap-comp-group-border'
                }
              }
            }}
            onChange={(pagination, filters, sorter) => {
              if (sorter.order) {
                const sortedData = [...this.tableDataList].sort((a, b) => {
                  if (sorter.field === 'part') {
                    return sorter.order === 'ascend'
                      ? a.part.localeCompare(b.part)
                      : b.part.localeCompare(a.part);
                  }
                  return 0;
                });
                this.tableDataList = sortedData;
                this.forceUpdate();
              }
            }}
            columns={ComponentsColumns}
            size="small"
            dataSource={dataSource}
            tableLayout="fixed"
            className="cascade-components-table"
          />
        </Spin>
      </div>
    </div>
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, applyModelList, designStatus },
    Impedance: { verificationId, loading, data, status, designId, modelAssignLoading, page, impedanceComponentsTableDisplay, componentsTableDisplay },
    library: { DecapList, DecapGeneric }
  } } = state
  const componentsDisplay = componentsTableDisplay || impedanceComponentsTableDisplay
  const singleLayout = data.length > 1 ? false : true;
  return {
    loading,
    openProjectId,
    verificationId,
    page,
    data,
    status,
    DecapList,
    DecapGeneric,
    pcbId: designId,
    applyModelList,
    designStatus,
    modelAssignLoading,
    componentsTableDisplay: [COMPONENTBASED, PARTBASED].includes(componentsDisplay) ? componentsDisplay : PARTBASED,
    singleLayout
  }
}

const mapDispatch = (dispatch) => ({
  updateImpedanceStatus(status) {
    dispatch(updateImpedanceStatus(status))
  },
  saveCapModel(record, value, models, apply, applyModelList, applySweep) {
    dispatch(saveCapModel(record, value, models, apply, applyModelList, applySweep))
  },
  _reAssignDecapModel() {
    dispatch(reAssignDecapModel())
  }
})

export default connect(mapState, mapDispatch)(CapComponentsTable);