import React, { Component, Fragment } from 'react'
import { EyeOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { getImpedanceJson, getHistoryDecapGroup, getDecapGroup } from '@/services/Cascade/Impedance';
import { PCB, PACKAGE } from '@/constants/treeConstants';
import { mergeDecapGroupData } from "@/services/Cascade/result/Impedance/decapGroup"
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { selectChange, selectLayer } from '@/pages/LayoutExplorer/store/Cascade/actionCreators';
import canvas from '@/services/LayoutCanvas';
import EditableTable from '@/components/EditableTable';
import './index.css'

const decapGroupColumns = [
  {
    title: "Group Name",
    dataIndex: "groupName",
    width: "10%",
    sorter: (a, b) => a.groupName.localeCompare(b.groupName)
  },
  {
    title: "Components",
    dataIndex: "components",
    width: "18%",
  },
  {
    title: "Resistance DC(mOhm)",
    dataIndex: "resistance",
    width: "18%",
    sorter: (a, b) => a.resistance - b.resistance
  },
  {
    title: "Inductance @1MHz(pH)",
    dataIndex: "inductance",
    width: '18%',
    sorter: (a, b) => a.inductance - b.inductance
  },
  {
    title: "Inductance @10MHz(pH)",
    dataIndex: "inductance_10",
    width: '18%',
    sorter: (a, b) => a.inductance_10 - b.inductance_10
  },
  {
    title: "Inductance @100MHz(pH)",
    dataIndex: "inductance_100",
    width: '18%',
    sorter: (a, b) => a.inductance_100 - b.inductance_100
  }
]

class DecapGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableDataList: [],
      historyList: [],
      isMounted: false,
      designID: '',
      maxHeight: 1000,
      show: true
    }
  }

  componentDidMount = () => {
    this.setState({
      isMounted: true
    })
    this.resize()
    if (this.props.id) {
      this.getData()
    }

    if (this.props.historys) {
      this.getHistoryData()
    }

    decapGroupColumns[0].render = (text, record) => {
      return <span onClick={() => this.viewComponents(record.components)} className="aurora-cursor">{text}</span>
    }

    decapGroupColumns[1].render = (text, record) => {
      return <span>{text.join(', ')}</span>
    }

    document.addEventListener('resize', this.resize)
  }

  componentDidUpdate = (prevProps) => {
    const { updateHistory } = this.props;
    if (updateHistory !== prevProps.updateHistory) {
      this.getHistoryData()
    }
  }

  componentWillUnmount = () => {
    this.setState({
      isMounted: false
    })
    document.removeEventListener('resize', this.resize)
  }

  resize = () => {
    const ele = document.getElementById('cascade-content-main');
    const height = (ele.clientHeight || 1145) - 145;
    this.setState({
      maxHeight: height
    })
  }

  getData = async () => {
    const { id } = this.props
    try {
      const impedanceSetup = await getImpedanceJson(id)
      const decapGroup = await getDecapGroup(id)
      const result = mergeDecapGroupData(decapGroup.data)
      if (this.state.isMounted) {
        this.setState({
          tableDataList: result,
          designId: impedanceSetup.designId
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  getHistoryData = async () => {
    const { historys } = this.props;
    if (!historys.length) {
      this.setState({
        historyList: []
      })
      return;
    }
    try {
      let historyList = [];
      for (let history of historys) {
        const decapGroup = await getHistoryDecapGroup(history.id)
        const result = mergeDecapGroupData(decapGroup.data);
        historyList.push({ name: history.name, tableList: result, show: false })
      }

      this.setState({
        historyList,
      })
    } catch (error) {
      console.error(error)
    }
  }

  toggleTable = (key) => {
    this.setState(prevState => {
      const updateItems = prevState.tableDataList.map(item => {
        if (item.powerNet === key) {
          return {
            ...item,
            showTable: !item.showTable
          }
        }
        return item
      })
      return {
        tableDataList: updateItems
      }
    })
  }

  viewStuffedDecap = (e, capGroups) => {
    let stuffedComps = [];
    capGroups.forEach(item => {
      stuffedComps.push(...item.components)
    });
    this.viewComponents(stuffedComps)
  }

  viewComponents = (stuffedComps) => {
    const { viewList, selectedIDs } = this.props;
    if (!viewList.includes(PCB) && !viewList.includes(PACKAGE)) {
      return;
    }
    const { designId } = this.state
    if (selectedIDs.includes(designId)) {
      const layout = canvas.getLayout(designId);
      if (!layout) {
        return;
      }
      let layers = layout.findCurrentLayer(stuffedComps);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, designId);
      this.props._selectChange({ comps: [...stuffedComps] }, designId);
    }
  }

  showCurrent = () => {
    this.setState({
      show: !this.state.show
    })
  }

  showHistory = (name) => {
    const { historyList } = this.state;
    let _historyList = [...historyList];
    const findIndex = _historyList.findIndex(item => item.name === name);
    if (findIndex > -1) {
      _historyList[findIndex].show = !_historyList[findIndex].show
      this.setState({
        historyList: _historyList
      })
    }
  }

  showHistoryTable = (name, key) => {
    const { historyList } = this.state;
    let _historyList = [...historyList];
    const findIndex = _historyList.findIndex(item => item.name === name);
    if (findIndex > -1) {
      const _findIndex = _historyList[findIndex].tableList.findIndex(item => item.powerNet === key);
      _historyList[findIndex].tableList[_findIndex].showTable = !_historyList[findIndex].tableList[_findIndex].showTable;
      if (_findIndex > -1) {
        this.setState({
          historyList: _historyList
        })
      }
    }
  }

  currentRender = () => {
    const { viewList } = this.props
    const { tableDataList, maxHeight, show } = this.state
    let view = viewList.includes(PCB) || viewList.includes(PACKAGE) ? true : false
    const ShowIcon = show ? DownOutlined : RightOutlined;
    return (
      <Fragment>
        <div className='cascade-decap-group-content'>
          <Row className='cascade-decap-group-row'>
            <ShowIcon className='cascade-decap-group-icon' onClick={() => this.showCurrent()} />
            <span className='font-bold cascade-setup-title-color'>Current</span>
          </Row>
        </div>
        {
          show && tableDataList.map((item, key) => {
            const ShowTableIcon = item.showTable ? DownOutlined : RightOutlined;
            return (
              <div className='cascade-decap-group-content' key={key}>
                <Row className='cascade-decap-group-row'>
                  <Col className='cascade-decap-group-setup-border'>
                    <ShowTableIcon className='cascade-decap-group-icon' onClick={() => this.toggleTable(item.powerNet)} />
                    <span className='font-bold cascade-setup-title-color'>{item.powerNet}</span>
                    <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                      <EyeOutlined
                        className={view ? "cascade-decap-group-eye-icon" : "cascade-decap-group-eye-icon cascade-decap-group-eye-icon-disable"}
                        onClick={(e) => this.viewStuffedDecap(e, item.capGroups)} />
                    </Tooltip>
                    {
                      item.showTable ? <div className='space-10'>
                        <EditableTable
                          rowKey={(record) => { return record.groupName }}
                          columns={decapGroupColumns}
                          dataSource={item.capGroups}
                          size="small"
                          className="cascade-imp-table"
                          scroll={item.capGroups.length > (maxHeight / 38) ? { y: maxHeight } : {}}
                        />
                      </div> : null}
                  </Col>
                </Row>
              </div>
            );
          })
        }
      </Fragment>
    );
  }

  historyRender = () => {
    const { viewList } = this.props
    const { historyList, maxHeight } = this.state
    let view = viewList.includes(PCB) ? true : false
    return historyList.map(item => {
      const { name, show, tableList } = item;
      const ShowIcon = show ? DownOutlined : RightOutlined;
      return (
        <Fragment key={name}>
          <div className='cascade-decap-group-content'>
            <Row className='cascade-decap-group-row'>
              <ShowIcon className='cascade-decap-group-icon' onClick={() => this.showHistory(name)} />
              <span className='font-bold cascade-setup-title-color'>{name}</span>
            </Row>
          </div>
          {
            show && tableList.map((item, key) => {
              const ShowTableIcon = item.showTable ? DownOutlined : RightOutlined;
              return (
                <div className='cascade-decap-group-content' key={key}>
                  <Row className='cascade-decap-group-row'>
                    <Col className='cascade-decap-group-setup-border'>
                      <ShowTableIcon className='cascade-decap-group-icon' onClick={() => this.showHistoryTable(name, item.powerNet)} />
                      <span className='font-bold cascade-setup-title-color'>{item.powerNet}</span>
                      <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                        <EyeOutlined
                          className={view ? "cascade-decap-group-eye-icon" : "cascade-decap-group-eye-icon cascade-decap-group-eye-icon-disable"}
                          onClick={(e) => this.viewStuffedDecap(e, item.capGroups)} />
                      </Tooltip>
                      {
                        item.showTable ? <div className='space-10'>
                          <EditableTable
                            rowKey={(record) => { return record.groupName }}
                            columns={decapGroupColumns}
                            dataSource={item.capGroups}
                            size="small"
                            className="cascade-imp-table"
                            scroll={item.capGroups.length > (maxHeight / 38) ? { y: maxHeight } : {}}
                          />
                        </div> : null}
                    </Col>
                  </Row>
                </div>
              );
            })
          }
        </Fragment>
      );
    });
  }

  render() {
    return (
      <div className='cascade-decap-group-main'>
        {this.currentRender()}
        {this.historyRender()}
      </div>
    )
  }
}

const mapState = (state) => {
  const { project: { viewList, openProjectId, selectedKeys } } = state.CascadeReducer
  return {
    viewList,
    openProjectId,
    selectedIDs: getSelectedDesignIDs(selectedKeys),
  }
}

const mapDispatch = (dispatch) => ({
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  }
})
export default connect(mapState, mapDispatch)(DecapGroup)
