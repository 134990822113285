import { checkRLCValue } from "../../helper/dataProcess";

const RL_UNITS = {
  RES: [{ key: "m", title: "mΩ" }, { key: "Ω", title: "Ω" }, { key: "K", title: "KΩ" }, { key: "M", title: "MΩ" }],
  IND: [{ key: "H", title: "H" }, { key: "u", title: "uH" }, { key: "n", title: "nH" }, { key: "p", title: "pH" }],
}

const RL_UNITS_SUFFIX = {
  RES: "Ω",
  IND: "H",
}

const RL_PLACEHOLDER = {
  RES: "Resistance",
  IND: "Inductance",
}

function getRLInputKey(type) {
  const units = RL_UNITS;
  const RES = { key: 'r', placeholder: 'Resistance', units: units["RES"] };
  const IND = { key: 'l', placeholder: 'Inductance', units: units["IND"] };

  switch (type) {
    case 'Res':
    case 'Ferrite':
    case 'Switch':
    case 'Jumper':
      return [RES];
    case 'Ind':
      return [IND];
    default: return [];
  }
}

function splitValue(rl, defV, defU, _unit) {
  let value, unit;
  rl = checkRLCValue(rl).toString();
  if (!isNaN(Number(rl))) {
    return { value: rl, unit: parseFloat(rl) !== 0 ? _unit : defU }
  } else {
    unit = rl.match(/\D$/);
    if (unit) {
      unit = unit[0];
    }
    value = rl.slice(0, -(unit.length));
    if ((unit && !isNaN(Number(unit))) || !unit) {
      unit = defU;
    }
    if (isNaN(value)) {
      value = defV;
    }
    return { value: value.toString(), unit }
  }
}

function checkTypeof(type) {
  return (typeof type) === 'string' || (typeof type) === 'number';
}

function splitRLValue(rl) {
  let res = { R: {}, L: {} };
  if (rl && checkTypeof(rl.R)) {
    // 0 default value, 'Ω' default unit
    const resR = splitValue(rl.R, 0, 'Ω', 'Ω');
    res.R = { ...resR };
  }
  if (rl && checkTypeof(rl.L)) {
    const resL = splitValue(rl.L, 0, 'n', 'H');
    res.L = { ...resL };
  }
  return res;
}

export { getRLInputKey, RL_UNITS, RL_UNITS_SUFFIX, RL_PLACEHOLDER, splitRLValue };