import { strDelimited } from "../../helper/split";
import { IC, SERDES_TYPES } from "../../PCBHelper";
import channelSetupInfo from "../channel/channelInfo";
import { getChannelInfo } from "../endToEndChannel";
import { findPinsBySignal } from "./seaSimSetupHelper";

async function getEndToEndSeaSimPCB(pcbConnections, connections, EndToEndSignals) {
  if (!pcbConnections || !connections) {
    return {};
  }
  const lastIndex = pcbConnections.length - 1;
  const pcb1 = pcbConnections[0];
  const pcb2 = pcbConnections[lastIndex];

  const pcb1ConnectionId = pcb1.nextConnectionId, pcb2ConnectionId = pcb2.prevConnectionId;
  const pcb1Connection = connections.find(item => item.CONNECTION_ID === pcb1ConnectionId),
    pcb2Connection = connections.find(item => item.CONNECTION_ID === pcb2ConnectionId);
  const channel1Info = await getChannelInfo(pcb1.channelId) || {};
  const channel2Info = await getChannelInfo(pcb2.channelId) || {};

  let pcb1Components = [], pcb2Components = [];
  if (channel1Info && channel1Info.id) {
    const content = channel1Info.content || { components: [], signals: [] };
    const component = pcb1Connection.connection.connector1.component;
    pcb1Components = content.components.filter(item => item.name !== component && SERDES_TYPES.includes(item.type));
    pcb1Components = JSON.parse(JSON.stringify(pcb1Components)).map(item => {
      return { ...item, pcbIndex: 1, channelId: pcb1.channelId, designName: channel1Info.pcbName, designId: pcb1.designId }
    });
    //signal_connections_map
  }

  if (channel2Info && channel2Info.id) {
    const content = channel2Info.content || { components: [], signals: [] };
    const component = pcb2Connection.connection.connector2.component;
    pcb2Components = content.components.filter(item => item.name !== component && SERDES_TYPES.includes(item.type));
    pcb2Components = JSON.parse(JSON.stringify(pcb2Components)).map(item => {
      return { ...item, pcbIndex: lastIndex + 1, channelId: pcb2.channelId, designName: channel2Info.pcbName, designId: pcb2.designId }
    })
  }

  let signals = [];
  for (let signal of EndToEndSignals) {
    const pcb1Signals = channel1Info.content && channel1Info.content.signals ? channel1Info.content.signals : [];
    const pcb2Signals = channel2Info.content && channel2Info.content.signals ? channel2Info.content.signals : [];

    const pcb1Signal = signal.PCB_0, pcb2Signal = signal[`PCB_${lastIndex}`];
    const findSignal1 = pcb1Signals.find(item => item.name === pcb1Signal),
      findSignal2 = pcb2Signals.find(item => item.name === pcb2Signal);
    signals.push({
      name: signal.name,
      firstPCB: findSignal1 ? JSON.parse(JSON.stringify(findSignal1)) : {},
      lastPCB: findSignal2 ? JSON.parse(JSON.stringify(findSignal2)) : {}
    })
  }

  return {
    components: [...pcb1Components, ...pcb2Components],
    signals,
    selectedSignals: signals.map(item => item.name)
  }
}

function getEndToEndSeaSimChannels(pcbConnections, connections) {
  let channels = [], analysisChannels = [];
  const connection = connections.find(item => item.CONNECTION_ID === pcbConnections[0].nextConnectionId);
  const signal_connections_map = connection.connection.signal_connections_map;
  for (let signal of signal_connections_map) {
    channels.push({
      signal: signal.name,
      controller: {
        component: "",
        pins: [],
        design: {
          designId: "",
          channelId: ""
        }
      },
      device: {
        component: "",
        pins: [],
        design: {
          designId: "",
          channelId: ""
        }
      },
      direction: "TX"
    });
    analysisChannels.push({
      victim: signal.name,
      aggressors: []
    })
  }
  return { channels, analysisChannels };
}

function updateEndToEndSeaSimCompByConnection(config, pcbConnections, connection, CONNECTION_ID) {
  let _config = { ...config };
  const lastIndex = pcbConnections.length - 1;
  if (pcbConnections[0].nextConnectionId === CONNECTION_ID && connection.connector1.component) {
    const connComp = connection.connector1.component;
    _config.channels.forEach((item) => {
      if (item.controller.design && item.controller.design.designId === pcbConnections[0].designId && connComp === item.controller.component) {
        item.controller.component = "";
        item.controller.pins = [];
        item.controller.design = {};
      }

      if (item.device.design && item.device.design.designId === pcbConnections[0].designId && connComp === item.device.component) {
        item.device.component = "";
        item.device.pins = [];
        item.device.design = {};
      }
    })
  }

  if (pcbConnections[lastIndex].prevConnectionId === CONNECTION_ID && connection.connector2.component) {
    const connComp = connection.connector2.component;
    _config.channels.forEach((item) => {
      if (item.controller.design && item.controller.design.designId === pcbConnections[lastIndex].designId && connComp === item.controller.component) {
        item.controller.component = "";
        item.controller.pins = [];
        item.controller.design = {};
      }

      if (item.device.design && item.device.design.designId === pcbConnections[lastIndex].designId && connComp === item.device.component) {
        item.device.component = "";
        item.device.pins = [];
        item.device.design = {};
      }
    })
  }

  return _config;
}

function updateEndToEndSeaSimCompBySignal({ config, record, dataIndex, pcbChannel, lastIndex }) {
  const connIndex = strDelimited(dataIndex, "_", { returnIndex: 1 });

  if (connIndex !== "0" && connIndex !== String(lastIndex)) {
    return config;
  }
  const signalName = record[dataIndex];
  let _config = { ...config };
  //get channel info
  const content = channelSetupInfo.getContent(pcbChannel.channelId);
  const components = content.components, signals = content.signals;
  //first pcb or last pcb
  if (connIndex !== "0" && connIndex !== String(lastIndex)) {
    return _config;
  }

  const index = _config.channels.findIndex(item => item.signal === record.name);
  if (index < 0) {
    return _config;
  }

  //update controller and device component
  const controllerComp = _config.channels.find(item => item.controller && item.controller.component && item.controller.design.designId === pcbChannel.designId);
  const deviceComp = _config.channels.find(item => item.device && item.device.component && item.device.design.designId === pcbChannel.designId);

  if (controllerComp) {
    const comp = components.find(it => it.name === controllerComp.controller.component) || { pins: [] }
    const pins = findPinsBySignal({
      pins: comp.pins,
      signals,
      signal: signalName
    });

    _config.channels[index].controller.pins = [...pins];
    _config.channels[index].controller.component = controllerComp.controller.component;
    _config.channels[index].controller.design = {
      designId: pcbChannel.designId,
      channelId: pcbChannel.channelId
    };
    if (!pins.length) {
      _config.channels[index].controller.component = "";
      _config.channels[index].controller.design = {};
    }
  }

  if (deviceComp) {
    const comp = components.find(it => it.name === deviceComp.device.component) || { pins: [] }
    const pins = findPinsBySignal({
      pins: comp.pins,
      signals,
      signal: signalName
    });

    _config.channels[index].device.pins = [...pins];
    _config.channels[index].device.component = deviceComp.device.component;
    _config.channels[index].device.design = {
      designId: pcbChannel.designId,
      channelId: pcbChannel.channelId
    };
    if (!pins.length) {
      _config.channels[index].device.component = "";
      _config.channels[index].device.design = {};
    }
  }

  return _config;
}

function delEndToEndSeaSimCompByDelPCB({
  config,
  newIndex,
  newChannel,
  channelInfo,
  connections,
  delPCB }) {
  if (!config) {
    return config;
  }

  if (newChannel) {
    return updateEndToEndSeaSimChannels({
      newChannel,
      config,
      connections,
      prevChannelId: delPCB.channelId,
      channelInfo,
      index: newIndex
    })
  }

  let _config = { ...config };
  _config.channels.forEach(item => {

    if (item.controller.design && item.controller.design.designId === delPCB.designId) {
      item.controller = {
        component: "",
        pins: [],
        design: {}
      }
    }

    if (item.device.design && item.device.design.designId === delPCB.designId) {
      item.device = {
        component: "",
        pins: [],
        design: {}
      }
    }
  })

  return _config;
}

function delEndToEndSeaSimCompByIgnoreComp({ config, component, designId }) {


  let _config = { ...config };
  _config.channels.forEach(item => {

    if (item.controller.design.designId === designId && item.controller.component === component) {
      item.controller = {
        component: "",
        pins: [],
        design: {}
      }
    }

    if (item.device.design.designId === designId && item.device.component === component) {
      item.device = {
        component: "",
        pins: [],
        design: {}
      }
    }
  })

  return _config;
}

function updateEndToEndSeaSimChannels({
  newChannel = {},
  config,
  connections,
  prevChannelId,
  channelInfo = {},
  index }) {

  const components = channelInfo.content && channelInfo.content.components ? channelInfo.content.components : [];
  const signals = channelInfo.content && channelInfo.content.signals ? channelInfo.content.signals : [];
  const connectionKey = parseInt(index) === 0 ? "nextConnectionId" : "prevConnectionId";
  const signalKey = parseInt(index) === 0 ? "channel1_signal" : "channel2_signal";
  const connection = connections.find(item => item.CONNECTION_ID === newChannel[connectionKey]) || {};
  const signal_connections_map = connection.connection.signal_connections_map || [];

  let icComp = components.find(item => item.type === IC);
  if (!icComp) {
    icComp = components.find(item => SERDES_TYPES.includes(item.type)) || {};
  }

  for (let signal of config.channels) {
    const findSignal = signal_connections_map.find(item => item.name === signal.signal) || {};
    if (signal.controller.design.channelId === prevChannelId) {
      const pins = icComp && icComp.pins ? findPinsBySignal({
        pins: icComp.pins,
        signals,
        signal: findSignal[signalKey]
      }) : [];

      signal.controller.pins = [...pins];
      signal.controller.component = icComp.name;
      signal.controller.design = {
        designId: newChannel.designId,
        channelId: newChannel.channelId
      };
      if (!pins.length) {
        signal.controller.component = "";
        signal.controller.design = {};
      }
    }

    if (signal.device.design.channelId === prevChannelId) {
      const pins = icComp && icComp.pins ? findPinsBySignal({
        pins: icComp.pins,
        signals,
        signal: findSignal[signalKey]
      }) : [];

      signal.device.pins = [...pins];
      signal.device.component = icComp.name;
      signal.device.design = {
        designId: newChannel.designId,
        channelId: newChannel.channelId
      };
      if (!pins.length) {
        signal.device.component = "";
        signal.device.design = {};
      }
    }
  }
  return config;
}

export {
  getEndToEndSeaSimPCB,
  getEndToEndSeaSimChannels,
  updateEndToEndSeaSimCompByConnection,
  updateEndToEndSeaSimCompBySignal,
  delEndToEndSeaSimCompByDelPCB,
  delEndToEndSeaSimCompByIgnoreComp,
  updateEndToEndSeaSimChannels
}