import { updateUser, deleteUser, getUserEmailList, getUserRoleList } from './api/users';
import apiProcess from './api/utility';

export function updateUserPromise(id, data) {
  return apiProcess(updateUser, { id, data })
}

export function deleteUserPromise(id) {
  return apiProcess(deleteUser, id)
}

export function getUserEmailListPromise() {
  return apiProcess(getUserEmailList)
}

export function getUserRoleListPromise() {
  return apiProcess(getUserRoleList)
}