import { connect } from 'react-redux';
import {
  changeUploadDisableStatus,
  saveCurrentPCBKey
} from './store/action';
import higherOrderComponent from './explorerHOC';
import UploadPCBMenu from '@/components/UploadPCB';
import DesignUpload from './designUpload';
import { createNewPreLayout } from '../../store/prelayout/action';
import projects from '@/services/Sierra/data/projects';
import { SIERRA } from '../../../../constants/pageType';
import { uploadPromise, replaceDesignPromise } from '@/services/api/Design/design';
import '@/publicCss/uploadPCB.css';


const mapState = (state) => {
  const SierraUploadReducer = state.SierraReducer.SierraUploadReducer;
  const {
    disabled,
    uploadProgress,
    compressProgress
  } = SierraUploadReducer;
  return {
    disabled,
    uploadProgress,
    compressProgress
  }
}

const mapDispatch = (dispatch) => ({
  _saveCurrentPCBKey(key) {
    dispatch(saveCurrentPCBKey(key));
  },
  _createPreLayout() {
    dispatch(createNewPreLayout())
  },
  _changeUploadDisableStatus(disabled) {
    dispatch(changeUploadDisableStatus(disabled))
  }
})
const UploadPCB = higherOrderComponent({
  UploadPCBMenu,
  DesignUpload,
  projectsDB: projects,
  product: SIERRA,
  uploadPromise,
  replaceDesignPromise
})

export default connect(mapState, mapDispatch)(UploadPCB);