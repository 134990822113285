import CeRectangle from './CeRectangle';
import StringHelper from '../utility/StringHelper';
import CeIODataItem from '../CeFileIO/CeIODataItem';


const CeOvalHalf = function (rightArc = true, cx = 0, cy = 0, w = 0, h = 0) {
  CeRectangle.call(this, "HalfOval");
  this.mRightArc = rightArc;
  CeRectangle.prototype.SetData(cx, cy, w, h);
}

// subclass extends superclass
CeOvalHalf.prototype = Object.create(CeRectangle.prototype);
CeOvalHalf.prototype.constructor = CeOvalHalf;

CeOvalHalf.prototype.Clone = function () {
  var clnObj = new CeOvalHalf();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeOvalHalf.prototype.CopyFrom = function (fromObj) {
  CeRectangle.prototype.CopyFrom.call(this, fromObj);
  this.mRightArc = fromObj.mRightArc;
}

CeOvalHalf.prototype.WriteIntoIODataNode = function () {
  var dataVals = CeRectangle.prototype.GetDataString.call(this);
  dataVals.add(StringHelper.BoolToString(this.mRightArc));
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeOvalHalf.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeOvalHalf.prototype.GetDataString = function () {
  var defData = CeRectangle.prototype.GetDataString.call(this);
  defData.push(StringHelper.BoolToString(this.mRightArc));
  return defData;
}

CeOvalHalf.prototype.UpdateByString = function (dataVals) {
  // center(x,y), width, height, cornerSize
  if (CeRectangle.prototype.UpdateByString.call(this, dataVals) == false)
    return false;

  if (dataVals.size() < 5)
    return false;

  this.mRightArc = StringHelper.StringToBool(dataVals.get(4));

  return true;
}

export default CeOvalHalf;