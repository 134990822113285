import {
  PCB_CHANNEL,
  /*   PCB_CHANNEL_RESULT */
} from '@/constants/treeConstants';
/* import { treeDataType } from '../../tree/treeItem'; */
import { isEndToEndChildrenChannel } from '../helper/publicHelper';
import { getChannelTypeWidth } from '../project/projectHelper';
import designConstructor from '../../helper/designConstructor';

/* function channelResultItem({ id, name, dataType }) {
  this.name = name;
  this.key = dataType + '-' + id;
  this.dataType = dataType;
  this.nodeClass = 'andes_v2_result';
} */
/**
 * channel Item
 *
 * @param {Object} { id, name, designId, designVersion ,version } id - channelId
 *                                                           name -channelName,
 *                                                           version - version, 
 *                                                           designVersion - designVersion
 */
function channelItem({ id, name, status, type, readyForSim, designId, version, endToEndId, nodeClass, pcbName, typeWidth, isLeaf }) {
  this.id = id;
  this.name = name;
  this.key = PCB_CHANNEL + '-' + id;
  this.dataType = PCB_CHANNEL;
  this.designId = designId;
  this.status = status;
  this.readyForSim = readyForSim;
  this.version = version;
  this.type = type;
  this.simStatus = null;
  this.children = [/* new channelResultItem({ id, name: 'Result', dataType: treeDataType(PCB_CHANNEL_RESULT) }) */];
  this.endToEndId = endToEndId || null;
  this.nodeClass = nodeClass;
  this.designName = pcbName;
  this.typeWidth = typeWidth;
  this.isLeaf = isLeaf;
}

function getChannelsTree(arr) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const typeList = arr.map(item => item.type);
  const typeWidth = getChannelTypeWidth(typeList);
  return arr.map(item => new channelItem({
    id: item.id,
    name: item.name,
    designId: item.designId,
    status: item.status,
    readyForSim: item.readyForSim,
    version: item.version,
    type: item.type,
    endToEndId: item.endToEndId || null,
    pcbName: /*item.pcbName*/ designConstructor.getDesignName(item.designId) || item.pcbName,
    typeWidth: isEndToEndChildrenChannel(item) ? 0 : typeWidth,
    nodeClass: isEndToEndChildrenChannel(item) ? "andes-end-to-end-channel-children" : "andes-pcb-channel-node-name",
    isLeaf: !item.hasSweep
  }));
}

export {
  getChannelsTree
}