import React from 'react';
import SparameterResult from './Sparameter';
import Waveform from './Waveform';
import PowerSum from './Waveform/powerSum';
import Delay from './Delay';
import EyeDiagram from './EyeDiagram';
import WaveformResult from './WaveformResult';

export default function resultContent(props) {
  const { resultKey, ...restProps } = props;
  let result = null, className = '';
  switch (resultKey) {
    case 'channel':
      result = <SparameterResult {...restProps} />
      className = 'andes-v2-result-content-channel';
      break;
    case 'TDR':
    case 'SBR':
      result = <Waveform resultKey={resultKey} {...restProps} />
      className = 'andes-v2-result-content-waveform';
      break;
    case 'MM_POWERSUM':
      result = <PowerSum resultKey={resultKey} {...restProps} />
      className = 'andes-v2-result-content-waveform';
      break;
    case 'delay':
      result = <Delay {...restProps} />
      break;
    case 'compliance':
    case 'ads':
    case 'hspice':
      return <EyeDiagram type={resultKey} {...restProps} />;
    case 'waveform':
      result = <WaveformResult type="waveform" {...restProps} />;
      className = 'andes-v2-result-content-waveform';
      break;
    default: break;
  }
  return <div className={`andes-v2-result-content ${className}`}>{result}</div>
}