import React, { Component, Fragment } from 'react';
import Panel from '../../../components/Panel';
import { createPortal } from 'react-dom';
import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { Select, Spin, Tooltip, Popover, Input } from 'antd';
import LayoutData from '@/services/data/LayoutData';
import { componentList } from '@/services/helper/setup/setupData'
import makeCancelable from '@/services/api/makeCancelable';
import CreatePanel from './create';
import { CREATE_PDN, UPDATE_PDN, CREATE_UPDATE } from '../constants';
import './index.css';

class Package extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      components: [],
      chip: props.packageData ? props.packageData.chip : undefined,
      intelSPIM: props.packageData ? props.packageData.model : undefined,
      intelSPIMList: props.intelSPIMList &&
        props.intelSPIMList.children &&
        props.intelSPIMList.children.length > 0 ?
        props.intelSPIMList.children.map(item => ({ name: item.name, id: item.id })) : [],
      intelSPIMID: props.packageData ? props.packageData.libraryId : '',
      id: props.packageData ? props.packageData.id : '',
      pinLength: 500,
      pinInputVisible: false,
      pinLengthValue: 500,
      error: '',
      componentList: [],
      createVisible: false,
      message: ''
    }
    this.dialogRoot = document.getElementById('root');
    this.settingRoot = document.getElementsByClassName('pdn-package-popover')[0];
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    const { currentProjectDesigns } = this.props;
    this.loadPCB(currentProjectDesigns[0].id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentProjectDesigns, intelSPIMList } = this.props;
    if (currentProjectDesigns.length > 0
      && prevProps.currentProjectDesigns.length > 0
      && prevProps.currentProjectDesigns[0].id !== currentProjectDesigns[0].id) {
      this.loadPCB(currentProjectDesigns[0].id);
    };
    if (intelSPIMList &&
      intelSPIMList.children &&
      intelSPIMList.children.length > 0) {
      const list = intelSPIMList.children.map(item => ({ name: item.name, id: item.id }));
      if (!this.equal(list, prevState.intelSPIMList)) {
        this.setState({
          intelSPIMList: list
        })
      }
    }
  }

  componentWillUnmount() {
    if (this.cancelable) {
      this.cancelable.cancel();
    };
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    this.settingRoot = document.getElementsByClassName('pdn-package-popover')[0];
    if (this.inputRef !== null
      && this.inputRef !== target
      && this.settingRoot && !this.settingRoot.contains(target)
    ) {
      this.savePinLength(e);
    }
  }

  equal(a, b) {
    const _bList = b.map(item => item.name);
    if (a.length !== b.length) {
      return false
    } else {
      return (a.every(item => _bList.includes(item.name)))
    }
  }

  loadPCB(designId) {
    this.setState({
      loading: true,
    });
    const { pinLength } = this.state;
    const getLayoutDBPromise = LayoutData.LoadLayoutDB(designId);
    this.cancelable = makeCancelable(getLayoutDBPromise);
    this.cancelable.promise.then(res => {
      const DesginData = LayoutData.getLayout(designId);
      const compList = componentList(DesginData);
      let list = Object.values(compList);
      list = list.filter(item => item.part)
      // !name match(/^(iso|tp|sw)/) 
      const filterList = list.filter(item =>
        item.pinList.length > pinLength &&
        (item.type === 'Ignore' || (item.type === 'Cap' && !item.part.match(/CAP/ig))) &&
        !item.name.match(/^(iso|tp|sw)/i));
      const _filterList = filterList.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.setState({
        loading: false,
        components: _filterList,
        componentList: list
      });
      if (_filterList.length === 1 && !this.state.chip) {
        this.setState({
          chip: _filterList[0].name
        })
      }
    }, error => {
      console.log(error);
    });
  };

  selectChip = (chip) => {
    this.setState({
      chip
    })
  };

  selectIntelSPIM = (model) => {
    const { intelSPIMList } = this.state;
    this.setState({
      intelSPIM: model,
      intelSPIMID: intelSPIMList.find(item => item.name === model).id
    })
  }

  handleVisibleChange = (visible) => {
    this.setState({ pinInputVisible: visible });
  };

  content = () => {
    const { pinLengthValue, error } = this.state;
    return (
      <Fragment>
        <div className='pdn-package-setting-header'>
          Advanced
          <CloseOutlined
            className='pdn-package-setting-close'
            onClick={(e) => this.savePinLength(e)} />
        </div>
        <div className='pdn-package-setting-content'>
          <span className='pdn-package-setting-span'>Filter components by the number of pins</span>
          <Input
            value={pinLengthValue}
            className='pdn-package-setting-input'
            onChange={(e) => this.pinInputChange(e)}
            ref={(input) => { this.inputRef = input; }}
            onPressEnter={(e) => this.savePinLength(e)}
            onBlur={(e) => this.savePinLength(e)}
          />
          {error && <span className='pdn-package-setting-error'>{error}</span>}
        </div>
      </Fragment>
    );
  }

  pinInputChange = (e) => {
    this.setState({
      pinLengthValue: e.target.value,
      error: ''
    });
  }

  cancelSavePinLength = (e) => {
    this.setState({
      pinInputVisible: false,
      error: ''
    })
  }

  savePinLength = (e) => {
    const { pinLengthValue, componentList } = this.state;
    let reg = /^[\d]+$/;
    if (!reg.test(pinLengthValue) && pinLengthValue) {
      const error = 'Please enter an integer.';
      this.setState({
        error: error,
        pinInputVisible: true,
      });
      return;
    };
    this.setState({
      pinInputVisible: false,
      pinLength: pinLengthValue
    });
    const filterList = componentList.filter(item =>
      item.pinList.length > pinLengthValue &&
      (item.type === 'Ignore' || item.type === 'Cap' && !item.part.match(/CAP/ig)) &&
      !item.name.match(/^(j|y|e|iso|tp|sw)/i));
    const _filterList = filterList.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    this.setState({
      components: _filterList
    });
    if (_filterList.length === 1) {
      this.setState({
        chip: _filterList[0].name
      })
    }
  }

  settingClick = (e) => {
    this.setState({
      pinInputVisible: true
    }, () => {
      if (this.inputRef) {
        this.inputRef.focus()
      }
    })
  }

  closePanel = (data) => {
    const { packageData, closeModal } = this.props;
    if (!packageData || !packageData.model || !packageData.libraryId) {
      data.createType = CREATE_PDN;
      closeModal('Package', data);
    } else {
      if (packageData.chip !== data.chip && packageData.libraryId !== data.libraryId) {
        this.setState({
          createVisible: true,
          message: 'allChange'
        })
      } else if (packageData.chip !== data.chip) {
        this.setState({
          createVisible: true,
          message: 'chipChange'
        })
      } else if (packageData.libraryId && packageData.libraryId !== data.libraryId) {
        this.setState({
          createVisible: true,
          message: 'modelChange'
        })
      } else {
        closeModal('Package', data);
      }
    }
  }

  closeWithCreate = (data, create) => {
    const { closeModal } = this.props;
    const { message } = this.state;
    const msg = message;
    if (create) {
      switch (msg) {
        case 'allChange':
          data.createType = CREATE_UPDATE;
          break;
        case 'chipChange':
          data.createType = UPDATE_PDN;
          break;
        case 'modelChange':
          data.createType = CREATE_UPDATE;
          break;
        default:
          data.createType = UPDATE_PDN;
          break;
      }
    } else {
      data.createType = false;
    }
    closeModal('Package', data)
    this.setState({
      createVisible: false,
      message: ''
    })
  }


  closeCreate = () => {
    this.setState({
      createVisible: false,
      message: ''
    })
  }

  render() {
    const { title, closeModal } = this.props;
    const { components, chip, intelSPIM, intelSPIMList, intelSPIMID, id, pinInputVisible, createVisible, message, openSelect } = this.state;
    let data = { chip, model: intelSPIM, libraryId: intelSPIMID, id, createType: false };
    const content = (
      <Fragment>
        <Popover
          content={this.content()}
          trigger="click"
          placement="rightTop"
          open={pinInputVisible}
          onOpenChange={this.handleVisibleChange}
          classNames={{ root: 'pdn-package-popover' }}
        >
          <Panel
            className='package-panel'
            position='panel-center-left'
            title={<Fragment>
              <span>{title}</span>
              <Tooltip
                classNames={{ root: 'pdn-sider-tooltip' }}
                title='Advanced'
                zIndex={3000}
              >
                <SettingOutlined
                  className='pdn-package-setting-icon'
                  onClick={(e) => this.settingClick(e)} />
              </Tooltip>
            </Fragment>}
            zIndex={2000}
            onCancel={() => this.closePanel(data)}
            width={400}
            draggable
            minHeight={160}
            createWH
          >
            <Spin tip="Loading..." spinning={this.state.loading}>
              <div className='package-mode-box'>
                <div className='package-mode-box-setup'>
                  <Tooltip
                    title={'Chip'}
                    placement="left"
                    classNames={{ root: 'package-selection-tooltip' }}
                  >
                    <Select
                      className='chip-selection'
                      popupClassName='chip-dropdown-selection'
                      onChange={this.selectChip}
                      value={chip}
                      placeholder={'Chip'}
                      options={
                        components.map(comp => ({ value: comp.name, label: comp.name }))
                      }
                    />
                  </Tooltip>
                </div>
                <div className='package-mode-box-setup'>
                  <Tooltip
                    title={'Intel SPIM'}
                    placement="left"
                    classNames={{ root: 'package-selection-tooltip' }}
                  >
                    <Select
                      showSearch
                      className='spim-selection'
                      popupClassName='chip-dropdown-selection'
                      onChange={this.selectIntelSPIM}
                      value={intelSPIM}
                      placeholder={'Intel SPIM'}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      options={
                        intelSPIMList.map(model => ({ value: model.name, label: model.name }))
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            </Spin>
          </Panel>
        </Popover>
        {createVisible &&
          <CreatePanel
            message={message}
            closeCreate={this.closeCreate}
            data={data}
            closeWithCreate={this.closeWithCreate}
          />}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default Package;