import http from "../http";

export function getCascadeProjectList(typeName = "Cascade") {
  return http.get(`my-project/type/${typeName}`);
}

export function createProject(data) {
  return http.post(`/my-project/`, {
    ...data,
    typeName: "Cascade"
  })
}

export function deleteCascadeProject(projectIdList) {
  return http.delete(`/my-project/`, { data: projectIdList })
}

export function getCascadeProjectChildren(projectId, typeName = "Cascade") {
  return http.get(`/Cascade/project/${projectId}`)
}

export function deleteCascadeDesign(designIds, isNew = "true") {
  return http.delete(`/my-project/designs`, { data: { ids: designIds, isNew } });
}

export function createChannel({ projectId, name, channelType, signOffId }) {
  return http.post(`/Cascade/channel/create`, {
    projectId,
    name,
    channelType,
    signOffId
  })
}

export function deleteChannel({ verificationIds }) {
  return http.post(`/Cascade/channel/delete`, {
    verificationIds
  })
}

export function renameChannel({ verificationId, name }) {
  return http.post(`/Cascade/channel/rename`, {
    verificationId,
    name
  })
}

export function copyVerification({ name, verificationId }) {
  return http.post(`/Cascade/verification/copy`, {
    name,
    verificationId
  })
}

export function selectPCB({ verificationId, designId }) {
  return http.post(`/Cascade/pcb/select`, {
    verificationId,
    designId
  })
}

export function getPartByProject(projectId) {
  return http.get(`/Cascade/all/parts?projectId=${projectId}`)
}

export function getProjectServiceOptionsConfig(projectId) {
  return http.get(`/my-project/project/${projectId}/option`);
}

export function updateProjectServiceOptionsConfig({ projectId, options }) {
  return http.put(`/my-project/project/${projectId}/option`, options);
}