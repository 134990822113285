import CeLayerLogic from './CeLayerLogic';

var CeLayerPaste = function () {
  CeLayerLogic.call(this, "SOLDER_PASTE");
};

// inherits from CeLayerBase
CeLayerPaste.prototype = Object.create(CeLayerLogic.prototype);
CeLayerPaste.prototype.constructor = CeLayerPaste;

export default CeLayerPaste;