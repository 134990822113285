import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import EditableTable from "@/components/EditableTable";
import { QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Row, Col, Popover, Tooltip, Spin, message } from "antd";
import {
  saveComponents,
  saveSplitComponents,
  saveMergeComponents,
  updateCompRLCPrefix,
} from "../../store/pdn/action";
import {
  ignoreComponents,
  changeUsage,
  decapRemovedChange,
  saveSelectedModel,
} from "../../store/pdn/action";
import ModelSelect from "@/components/PDNModelSelect/ModelSelect";
import TableTag from "@/components/TableTag";
import {
  selectChange,
  selectLayer,
} from "../../../LayoutExplorer/store/FastPI/actionCreators";
import { isMac } from "@/services/api/userAgent";
import { checkRLCValue } from "@/services/helper/dataProcess";
import CustomRLC from "../../../../components/CustomRLC";
import { SortFn } from "@/services/helper/sort";
import { getSplitPartName } from "@/services/helper/setDefaultName";
import { getLibraryFile } from "@/services/PDN/library";
import { getSelectedDesignIDs } from "@/services/helper/dataProcess";
import canvas from "@/services/LayoutCanvas";
import "./index.css";

const ComponentsColumns = [
  {
    title: "Part Number",
    dataIndex: "part",
    width: "20%",
    sorter: (a, b) => a.part.localeCompare(b.part),
  },
  {
    title: "Components",
    dataIndex: "selectComps",
    width: "40%",
  },
  {
    title: "Usage",
    dataIndex: "usage",
    width: "10%",
    sorter: (a, b) => a.usage.localeCompare(b.usage),
  },
  {
    title: "Model",
    dataIndex: "modelName",
    width: "30%",
  },
];

const RLCUsages = ["Res", "Ind", "Cap"];

class Components extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      mergePartList: [],
      mergeVisible: false,
    };

    ComponentsColumns[0].render = (text, record) => {
      const { mergeVisible, mergePartList } = this.state;
      if (
        RLCUsages.includes(record.usage) &&
        mergePartList &&
        mergePartList.length > 0 &&
        mergePartList[0] === record.part
      ) {
        return (
          <Popover
            placement="topLeft"
            overlayClassName="merge-part-popover"
            getPopupContainer={() =>
              document.getElementById("pdn-content-main")
            }
            content={
              <Fragment>
                <Tooltip
                  title="Merge Part"
                  mouseLeaveDelay={0}
                  mouseEnterDelay={0.3}
                  overlayClassName="icon-tooltip"
                >
                  <span
                    className="merge-part-popover-span"
                    onClick={(e) => this.MergePart(e)}
                  >
                    Merge
                  </span>
                </Tooltip>
              </Fragment>
            }
            open={mergeVisible}
          >
            <span
              onClick={(e) => this.tableOnRow(e, record)}
              className="pdn-part-name"
            >
              {record.part}
            </span>
          </Popover>
        );
      } else if (RLCUsages.includes(record.usage)) {
        return (
          <span
            className="cursor-pointer pdn-part-name"
            onClick={(e) => this.tableOnRow(e, record)}
          >
            {record.part}
          </span>
        );
      } else {
        return <span className="pdn-part-name">{record.part}</span>;
      }
    };

    ComponentsColumns[1].onCell = (record) => {
      const { comps } = record;

      let compsName = [];
      comps.forEach((item) => {
        if (item.usage !== "Unused") {
          compsName.push({
            name: item.name,
            checked: true,
          });
        } else {
          compsName.push({
            name: item.name,
            checked: false,
          });
        }
      });

      return record.usage === "Cap" ||
        record.usage === "Removed" ||
        record.usage === "Ind" ||
        record.usage === "Res"
        ? {
          record,
          compsName,
          edit: true,
          customInput: TableTag,
          dataIndex: "selectComps",
          popupContainer: "pdn-content-main",
          changeCompsChecked: this.changeCompsChecked,
          changeDecapRemove: this.changeDecapRemove,
          select: this.select,
          splitComps: this.splitComps,
          onClick: (e) => this.stopProps(e),
          isPCBOpened: this.isPCBOpened,
        }
        : null;
    };

    ComponentsColumns[1].render = (selectComps, record) => {
      const mac = isMac();
      let tdRef = document.getElementById(`td-${record.index}`);
      const height = tdRef && tdRef.offsetHeight;
      const usageTypejudge =
        record.usage !== "Cap" &&
          record.usage !== "Ind" &&
          record.usage !== "Res" &&
          record.usage !== "Removed"
          ? true
          : false;
      return (
        <Fragment>
          <div className="pdn-component-td" id={`td-${record.index}`}>
            <span
              className={
                usageTypejudge ? "pdn-components-name" : "pdn-component-td-2"
              }
              onClick={
                usageTypejudge ? this._selectClick.bind(this, record) : null
              }
            >
              {selectComps.length > 0 ? (
                selectComps.join(", ")
              ) : (
                <span className="pdn-component-td-2">Unstuffed</span>
              )}
            </span>
            {mac && height && height > 88 ? (
              <div className="pdn-component-arrow"></div>
            ) : null}
          </div>
        </Fragment>
      );
    };

    ComponentsColumns[2].render = (usage, record) => {
      if (usage === "Ignore") {
        return <span className="pdn-components-ignore-name">{usage}</span>;
      } else if (usage === "Unused") {
        return <span className="pdn-components-unused-name">{usage}</span>;
      } else {
        return <span className="pdn-components-name">{usage}</span>;
      }
    };

    ComponentsColumns[3].onCell = (record) => {
      return record.usage === "Cap"
        ? {
          record,
          edit: true,
          customInput: ModelSelect,
          decapList: this.props.DecapNames,
          trigger: "pdn-model-dialog",
          getLibraryFile: getLibraryFile,
          dataIndex: "modelName",
          getSelectedModel: this.getSelectedModel,
          saveDecapModel: this.saveDecapModel,
          saveSelectedModel: this.saveSelectedModel,
          onClick: (e) => this.stopProps(e),
        }
        : (record.usage === "Ind" || record.usage === "Res") && {
          record,
          edit: true,
          dataIndex: "value",
          handleSave: this.saveValue,
        };
    };

    ComponentsColumns[3].render = (data, record) => {
      const { value, models } = record;
      const { SPIMNames } = this.props;
      if (record.usage === "Chip") {
        if (record.pkg) {
          //spim model exist check
          const findSPIM = record.pkg.id ? SPIMNames.find(item => item.id === record.pkg.id) : true;
          if (!findSPIM) {
            return (
              <div className='aurora-file-check-div'>
                {record.pkg.name}
                <Tooltip
                  title={`Library SPIM ${record.pkg.name} has been deleted, the model is invalid.`}
                  overlayClassName='aurora-tooltip'
                >
                  <QuestionCircleOutlined
                    className='aurora-file-check-icon'
                    onClick={(e) => { e && e.stopPropagation(); }} /></Tooltip>
              </div>
            );
          }
          return <span className="components-pkg-name">{record.pkg.name}</span>;
        }
      } else if (record.usage === "Cap") {
        if (models && models.length) {
          let nameList = [];
          let deletedNames = [], updatedNames = [];
          this.checkModelTips(record, { nameList, deletedNames, updatedNames });
          let newNameList = nameList.filter(item => item && item.trim());
          let _deletedNames = deletedNames.join(", ");
          let _updatedNames = updatedNames.join(", ");
          return (
            <Fragment>
              <span
                className="pdn-component-td-2"
              >
                {newNameList.map((item, index) =>
                  <div
                    key={index}
                    className={
                      (_updatedNames.includes(item) || _deletedNames.includes(item))
                        ? "pdn-file-check-span"
                        : ""
                    }
                  >{item}{(index === (newNameList.length - 1)) ? " " : ", "}</div>
                )}
              </span>
              {(_updatedNames || _deletedNames) ? (
                (_updatedNames ? (<Tooltip title={`${_updatedNames} has been updated`} overlayClassName="aurora-tooltip">
                  <QuestionCircleOutlined className="pdn-file-check-icon" onClick={(e) => this.spanClick(e)} />
                </Tooltip>) : (<Tooltip title={`${_deletedNames} has been deleted`} overlayClassName="aurora-tooltip">
                  <QuestionCircleOutlined className="pdn-file-check-icon" onClick={(e) => this.spanClick(e)} />
                </Tooltip>))
              ) : null}
            </Fragment>
          );
        }
      } else if (
        record.usage !== "Ignore" &&
        record.usage !== "Unused" &&
        record.usage !== "Removed"
      ) {
        return <span className="pdn-component-td-2">{value}</span>;
      } else {
        return null;
      }
    };
  }

  checkModelTips = (record, { nameList, deletedNames, updatedNames }) => {
    const { models } = record;
    const { DecapNames } = this.props;
    for (let _model of models) {
      let _name = _model.name;
      if (_model.type === "spice") {
        _name = _model.subcktName;
      } else {
        _name = _model.name;
      }
      nameList.push(_name);
      const findItem = DecapNames.find((item) => item.id === _model.id);
      const findName = DecapNames.find((item) => item.name === _model.name);
      if (_model && _model.id) {
        if (!findItem && !findName) {
          deletedNames.push(_name);
        } else if (!findItem && findName) {
          updatedNames.push(_name);
        }
      }
    }
  }

  isPCBOpened = () => {
    const { pcbId, selectedDesignIDs } = this.props;
    return selectedDesignIDs.includes(pcbId);
  };

  stopProps = (e) => {
    e.stopPropagation();
  };

  _selectClick = (record) => {
    const { pcbId } = this.props;
    if (record.comps && this.isPCBOpened()) {
      const names = record.comps.map((item) => item.name);
      this.props._selectChange({ comps: [...names] }, pcbId);
    }
  };

  select = (record, names) => {
    const { pcbId } = this.props;
    if (this.isPCBOpened()) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer(names);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...names] }, pcbId);
    }
  };

  changeCompsChecked = (compsName, checked, record) => {
    let components = [...compsName];
    this.props.ignoreComponents({
      part: record.part,
      components,
      checked,
      usage: record.usage,
    });
  };

  changeDecapRemove = (compsNames, record) => {
    let removes = [];
    compsNames.forEach((item) => {
      if (item.removed) {
        removes.push(item.name);
      }
    });
    this.props.decapRemovedChange({
      part: record.part,
      removes,
      usage: record.usage,
    });
  };

  saveDecapModel = (record, models, apply) => {
    this.props.saveComponents({ part: record.part, models, apply });
  };

  saveSelectedModel = (model) => {
    this.props._saveSelectedModel({ first: true, model });
  };

  saveValue = (record) => {
    const value = checkRLCValue(record.value);
    this.props.saveComponents({
      part: record.part,
      model: record.model,
      value: value,
    });
  };

  getComponents = (Components) => {
    function mergeParts(comps) {
      let parts = [],
        existList = [];
      comps.forEach((comp) => {
        const { part, usage, name, pins, COMP_TYPE } = comp;
        if (existList.includes(part)) {
          const _findIndex = parts.findIndex((item) => item.part === part);
          parts[_findIndex].comps.push({ name, pins, usage, COMP_TYPE });
          parts[_findIndex].selectComps.push(name);
        } else {
          existList.push(part);
          parts.push({
            part,
            comps: [{ name, pins, usage, COMP_TYPE }],
            usage,
            value: null,
            model: null,
            selectComps: [name],
          });
        }
      });
      return parts;
    }
    let _partComps = [], // [{part, comps, value, usage}]
      partList = [];
    //Take out the component whose usage is "Removed" separately.//setup version 0.1.9
    let newRemoveComps = mergeParts(
      Components.filter((item) => item.usage === "Removed")
    );
    let newIgnoreComps = mergeParts(
      Components.filter((d) => d.usage === "Ignore")
    );
    let Comps = Components.filter(
      (item) => item.usage !== "Removed" && item.usage !== "Ignore"
    );

    //Distinguish comp according to part and COMP_TYPE(usage is not "Removed" or 'Ignore')
    Comps.forEach((comp) => {
      const { part, usage, name, pins, COMP_TYPE } = comp;
      //Distinguish comp according to part and COMP_TYPE, Ignore components can not merge with unstuff/stuff/removed
      let existIndex = partList.findIndex(
        (item) => item.part === part && item.COMP_TYPE === COMP_TYPE
      );
      if (existIndex > -1) {
        const _findIndex = _partComps.findIndex((item) => item.part === part);
        _partComps[_findIndex].comps.push({ name, pins, usage, COMP_TYPE });

        if (usage !== "Unused") {
          _partComps[_findIndex].selectComps.push(name);
        }
      } else {
        partList.push({ part, COMP_TYPE });
        let _comp = { part, comps: [{ name, pins, usage, COMP_TYPE }], usage };
        if (usage === "Cap" || (usage === "Unused" && COMP_TYPE === "Cap")) {
          _partComps.push({
            ..._comp,
            models: comp.models ? [...comp.models] : [],
            selectComps: [name]
          });
        } else if (usage === "Ind" || usage === "Res") {
          _partComps.push({
            ..._comp,
            value: comp.value,
            prevValue: comp.value,
            selectComps: [name],
          });
        } else if (usage === "Chip") {
          _partComps.push({
            ..._comp,
            pkg: comp.pkg,
            selectComps: [name],
          });
        } else {
          //usage is (Unused)
          _partComps.push({
            ..._comp,
            value: comp.value,
            model: comp.model,
            selectComps: [name],
          });
        }
      }
    });

    _partComps.forEach((item, index) => {
      let usages = item.comps.map((comp) => comp.usage);
      //If the usage of all components of an item is "Unused", set the usage of the item to COPM_TYPE and selectComps to empty.(Unstuffed)
      //Unstuffed
      if (usages.every((usage) => usage === "Unused")) {
        item.usage = item.comps[0].COMP_TYPE;
        item.selectComps = [];
      } else if (usages.includes("Unused")) {
        //includes Unused
        item.usage = item.comps[0].COMP_TYPE;
      }
    });

    // update selectComps(Cap/Res/Ind)
    _partComps.forEach((item, index) => {
      if (
        item.usage !== "Unused" &&
        item.usage !== "Chip" &&
        item.usage !== "Ignore"
      ) {
        let selects = [];
        selects = item.comps.filter((item) => item.usage !== "Unused");
        item.selectComps = [...selects.map((item) => item.name)];
      }
    });

    //Merge removed and other
    _partComps = [..._partComps, ...newRemoveComps, ...newIgnoreComps];
    const sort = ["Chip", "Cap", "Res", "Ind", "Removed", "Unused", "Ignore"];
    _partComps = SortFn(_partComps, sort, "usage");
    return _partComps;
  };

  openPanel = (e) => {
    e.stopPropagation();
    this.setState({
      visible: true,
    });
  };

  closeModal = (COMP_PREFIX_LIB) => {
    const prev = this.props.COMP_PREFIX_LIB;
    this.setState(
      {
        visible: false,
      },
      () => {
        let changed = false;
        const arr = ["Res", "Ind", "Cap"];
        for (const item of arr) {
          if (prev[item].length !== COMP_PREFIX_LIB[item].length) {
            changed = true;
            break;
          } else {
            // prev[item].length === COMP_PREFIX_LIB[item].length
            if (
              prev[item].some((value) => !COMP_PREFIX_LIB[item].includes(value))
            ) {
              changed = true;
              break;
            }
          }
        }
        if (changed) {
          this.props.updateCompRLCPrefix(COMP_PREFIX_LIB);
        }
      }
    );
  };

  splitComps = (compList, record) => {
    const { part } = record;
    let partName = part;
    const { pdnContent } = this.props;
    const comps = (pdnContent && pdnContent.Components) || [];
    const components = this.getComponents(comps);
    const newPartName = getSplitPartName({ components, partName });
    this.setState({
      splitPartName: newPartName,
      mergePartList: [],
    });
    this.props.saveSplitComponents({
      part: record.part,
      splitPart: newPartName,
      comps: compList,
    });
    setTimeout(() => {
      this.setState({
        splitPartName: null,
      });
    }, 3000);
  };

  tableOnRow = (e, record) => {
    e.stopPropagation();
    const { part } = record;
    const { mergePartList, mergeVisible, mergeType } = this.state;
    if (mergePartList && mergePartList.length > 0) {
      if (record.usage !== mergeType) {
        message.info("Please select components of the same type to merge");
        return;
      }
    }
    let partList = [...mergePartList],
      visible = mergeVisible;
    if (!mergePartList.includes(part)) {
      partList.push(part);
    } else {
      partList = partList.filter((item) => item !== part);
    }
    if (partList.length > 1) {
      visible = true;
    } else {
      visible = false;
    }
    this.setState({
      mergePartList: partList,
      splitPartName: null,
      mergeVisible: visible,
      mergeType: record.usage,
    });
  };

  MergePart = (e) => {
    e.stopPropagation();
    const { mergePartList } = this.state;
    if (mergePartList.length === 0) {
      return;
    }
    let partName = mergePartList[0];
    this.props.saveMergeComponents({ part: partName, partList: mergePartList });
    this.setState({
      mergePartName: partName,
      mergePartList: [],
    });
    setTimeout(() => {
      this.setState({
        mergePartName: null,
      });
    }, 3000);
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  getSelectedModel = () => {
    const { first, model } = this.props.selectedModel;
    const { defaultDecapModel } = this.props;
    const decapModel = {
      id: defaultDecapModel ? defaultDecapModel.id : "",
      name: defaultDecapModel ? defaultDecapModel.name : "",
      type: "spice",
      libraryType: defaultDecapModel ? defaultDecapModel.type : "",
    };
    // check first open pdn
    if (!first) {
      const { pdnContent } = this.props;
      const comps = pdnContent ? pdnContent.Components : [];
      const comp = comps.find(
        (item) =>
          item.usage === "Cap" &&
          item.model &&
          (item.model.subcktName || item.model.libraryType === "data")
      );
      const _model = comp ? comp.model : decapModel;
      // save a exist model to reducer and use this model
      this.props._saveSelectedModel({ first: true, model: _model });
      return _model;
    } else {
      // use a preivous model
      return model || decapModel;
    }
  };

  handleClickOutside = (e) => {
    const { target } = e;
    const dialogBox = document.getElementById("pdn-model-dialog");
    if (
      dialogBox &&
      dialogBox &&
      dialogBox.children.length > 0 &&
      !dialogBox.contains(target)
    ) {
      return;
    }
    const tagBox = document.getElementsByClassName("pdn-components-table")[0];
    if (
      (tagBox &&
        !tagBox.contains(target) &&
        target.classList[0] !== "merge-part-popover-span" &&
        target.classList[0] !== "merge-part-popover") ||
      target.classList[0] === "pdn-component-td" ||
      target.classList[0] === "editable-cell-value-wrap" ||
      target.classList[0] === "pdn-component-td-2"
    ) {
      this.setState({
        mergePartList: [],
      });
      return;
    }
  };

  render() {
    const {
      pdnContent,
      COMP_PREFIX_LIB,
      loading,
      copyComponents,
    } = this.props;
    const comps = (pdnContent && pdnContent.Components) || [];
    const dataSource = this.getComponents(comps);
    const { visible, mergePartList, splitPartName, mergePartName } = this.state;
    return (
      <Fragment>
        <Row>
          <Col span={24}>
            <span className="font-bold pdn-setup-title-color">Components</span>
            <Tooltip
              title={loading ? "Loading PCB..." : "Custom Reference Designator"}
              overlayClassName="aurora-tooltip"
            >
              {loading ? (
                <SettingOutlined className="pdn-component-advance pdn-component-advance-disable" />
              ) : (
                <SettingOutlined
                  className="pdn-component-advance"
                  onClick={(e) => {
                    this.openPanel(e);
                  }} />
              )}
            </Tooltip>
            <div className="space-10">
              <Spin tip="Decap model copying..." spinning={copyComponents}>
                <EditableTable
                  onRow={(record) => {
                    let rowClassName = "";
                    if (
                      record.part === splitPartName &&
                      mergePartList.length === 0 &&
                      !mergePartName
                    ) {
                      rowClassName = "pdn-component-split-row";
                    } else if (
                      mergePartList.length > 0 &&
                      mergePartList.includes(record.part) &&
                      !mergePartName
                    ) {
                      rowClassName = "pdn-component-select-row";
                    } else if (mergePartName && record.part === mergePartName) {
                      rowClassName = "pdn-component-merge-row";
                    }
                    return (
                      RLCUsages.includes(record.usage) && {
                        className: rowClassName,
                      }
                    );
                  }}
                  rowKey={record => `${record.part}-${record.usage}`}
                  columns={ComponentsColumns}
                  size="small"
                  dataSource={dataSource}
                  className="pdn-components-table"
                />
              </Spin>
            </div>
          </Col>
        </Row>
        {visible && (
          <CustomRLC
            closeModal={this.closeModal}
            COMP_PREFIX_LIB={COMP_PREFIX_LIB}
          />
        )}
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const {
    PDNReducer: { pdn, project },
  } = state;
  const { DecapNames = [], defaultDecap, treeSelectedKeys, SPIMNames = [] } = project;
  const pdnContent = pdn.pdnInfo ? pdn.pdnInfo.pdnContent : null;
  const pcbId = pdn.pdnInfo && pdn.pdnInfo.designId;
  let COMP_PREFIX_LIB = { Res: [], Ind: [], Cap: [] };
  if (pdnContent && pdnContent.COMP_PREFIX_LIB) {
    COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB;
  }
  let defaultDecapModel = null;
  if (defaultDecap && defaultDecap.id) {
    defaultDecapModel = DecapNames.find((d) => d.id === defaultDecap.id);
  }

  return {
    pdnContent,
    DecapNames,
    pcbId,
    COMP_PREFIX_LIB,
    selectedModel: pdn.selectedModel,
    defaultDecapModel,
    selectedDesignIDs: getSelectedDesignIDs(treeSelectedKeys),
    SPIMNames
  };
};

const mapDispatch = (dispatch) => ({
  saveComponents({ part, model, value, models }) {
    dispatch(saveComponents({ part, model, value, models }));
  },
  ignoreComponents({ part, components, checked, usage }) {
    dispatch(ignoreComponents({ part, components, checked, usage }));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID));
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  saveSplitComponents({ part, splitPart, comps }) {
    dispatch(saveSplitComponents({ part, splitPart, comps }));
  },
  saveMergeComponents({ part, partList }) {
    dispatch(saveMergeComponents({ part, partList }));
  },
  changeUsage({ part, chips, usage }) {
    dispatch(changeUsage({ part, chips, usage }));
  },
  updateCompRLCPrefix(prefixList) {
    dispatch(updateCompRLCPrefix(prefixList));
  },
  decapRemovedChange({ part, removes, usage }) {
    dispatch(decapRemovedChange({ part, removes, usage }));
  },
  _saveSelectedModel(model) {
    dispatch(saveSelectedModel(model));
  },
});

export default connect(
  mapState,
  mapDispatch
)(Components);
