import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import TabFooter from '@/components/TabSwitchFooter';
import { getBodySize, getReSizeLeftRight, updateContentHeight } from '@/services/helper/documentReSize';
import { getCCCMsg } from '../../pages/CCC/store/action';
import LeftRight from './leftRight';
class LayoutLR extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: 500,
      height: 250,
      bottomLinePosition: {
        position: "absolute",
        bottom: 250
      },
      contentResize: true,
      loading: true
    }
  }

  componentDidUpdate(prevProps) {
    const { siderWidth } = this.props;
    const bodyWidth = document.body.clientWidth;

    if (prevProps.siderWidth !== siderWidth) {
      let { width } = this.state;
      const maxWidth = bodyWidth - 20 - siderWidth;
      if (width > maxWidth) {
        width = maxWidth;
      }
      this.setState({ width })
      const { contentResize } = this.state;
      this.setState({
        contentResize: !contentResize
      });
      this.props.contentSetupResize && this.props.contentSetupResize(!contentResize);
    }
    const { tabVisible, tabSelectKeys } = this.props;

    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props._getCCCMsg();
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this.screenChange();
    this.parentEle = document.getElementsByClassName('aurora-content')[0];

    if (this.parentEle) {
      const splitSize = this.props.splitSize ? this.props.splitSize : 2;
      const width = this.parentEle.offsetWidth / splitSize;
      this.setState({
        width: width,
      });
    }
    this.setState({
      loading: false
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getReSize();
  }

  getReSize = () => {
    const { height, linePosition } = getBodySize();
    const setting = getReSizeLeftRight("aurora-content", this.props.splitSize);
    let width = setting.width;
    this.setState({
      height: height,
      bottomLinePosition: { ...linePosition },
      width,
    });
    this.props.topBarResize && this.props.topBarResize();
  }

  changeTabFooterHeight = (height) => {
    let { bodyClientHeight } = getBodySize();
    height = updateContentHeight(height, 140);
    this.setState({
      bottomLinePosition: {
        position: "absolute",
        bottom: height + 1,
        minHeight: 20,
        maxHeight: bodyClientHeight - 140
      },
      height
    });
  }

  changeSize = (height) => {
    this.changeTabFooterHeight(height);
  }

  getMonitor = () => {
    const { tabVisible, menuType, tabSelectKeys } = this.props;
    const { height, bottomLinePosition, width } = this.state;
    return <div className='aurora-footer'>
      <TabFooter
        linePosition={bottomLinePosition}
        height={height}
        changeHeight={this.changeTabFooterHeight}
        closeTabFooter={this.props._closeTabFooter}
        changeSize={this.changeSize}
        leftWidth={width}
        changeTabMenu={this.props.changeMonitorTabMenu}
      >
        {this.props.MonitorChild(menuType, tabVisible, tabSelectKeys, width)}
      </TabFooter>
    </div>
  }

  getLeft = (_width) => {
    const { bottomLinePosition } = this.state;
    const { tabVisible } = this.props;

    return <Fragment>
      {this.props.getLeft(_width, bottomLinePosition)}
      {tabVisible && this.getMonitor()}
      {this.props.getTopMenuVisible() ? this.props.topBar(_width) : null}
    </Fragment>
  }

  render() {
    return (
      <LeftRight {...this.props} getLeft={(_width) => this.getLeft(_width)} getRight={(_width) => this.props.getRight(_width)} />
    )
  }
};

const mapDispatch = (dispatch) => ({
  _getCCCMsg() {
    dispatch(getCCCMsg())
  }
})

export default connect(null, mapDispatch)(LayoutLR);