import {
  START_SIERRA_VERIFICATION,
  CHANGE_VERIFICATION_LIST,
  UPDATE_SIERRA_PROGRESS,
  UPDATE_SINGLE_MONITOR,
  CLEAN_SINGLE_PROGRESS,
  CLEAN_MONITOR,
  CHANGE_UPLOAD_MES,
  RESULT_EXIST,
  SAVE_CURRENT_LOG,
  WAITING_INDEX,
  UPDATE_ERROR_CHECK_LIST,
  UPDATE_PRE_PROCESS_LOG,
  UPDATE_START_MSG,
  UPDATE_END_MSG,
  SAVE_CURRENT_PROFILE,
  SAVE_EXPERIMENT_QUEUE,
  UPDATE_DESIGN_STACKUP_ERROR_MESSAGE,
  UPDATE_CONNECTOR_ERROR
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  verificationId: null,
  simulateKeys: [],
  singleProgress: [],
  singleMonitor: {},
  uploadDebugMes: null,
  existResult: false,
  waitingQueue: [],
  sierraInfoErrorCheck: [],
  preProcessLog: [],
  endMsg: [],
  startMsg: [],
  experimentQueue: [],
  stackupErrorList: [],
  connError: []
}

export const simulationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case START_SIERRA_VERIFICATION:
      return {
        ...state,
        verificationId: action.verificationId
      }
    case CHANGE_VERIFICATION_LIST:
      return {
        ...state,
        simulateKeys: action.simulateKeys
      }
    case UPDATE_SIERRA_PROGRESS:
      const { singleProgress } = state;
      let progress = [...singleProgress];
      const index = singleProgress.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (index) !== 'boolean' && index > -1) {
        progress[index].progress = action.progress;
      } else {
        progress.push({
          progress: action.progress,
          verificationId: action.verificationId
        })
      }
      return {
        ...state,
        singleProgress: [...progress]
      };
    case CLEAN_SINGLE_PROGRESS:
      let progressInfo = [...state.singleProgress];
      const i = progressInfo.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (i) !== 'boolean' && i > -1) {
        progressInfo.splice(i, 1);
      }
      return {
        ...state,
        singleProgress: [...progressInfo]
      }
    case UPDATE_SINGLE_MONITOR:
      return {
        ...state,
        singleMonitor: {
          ...(state.singleMonitor || {}),
          [action.verificationId]: {
            monitor: action.monitor,
            verificationId: action.verificationId,
            workflowId: action.workflowId
          }
        }
      }
    case CLEAN_MONITOR:
      return {
        ...state,
        singleMonitor: {
          ...state.singleMonitor,
          [action.verificationId]: null
        }
      }
    case CHANGE_UPLOAD_MES:
      return {
        ...state,
        uploadDebugMes: action.mes
      }
    case RESULT_EXIST:
      return {
        ...state,
        existResult: action.existResult
      }
    case SAVE_CURRENT_LOG:
      return {
        ...state,
        log: { ...state.log, [action.verificationId]: action.log || null }
      }
    case WAITING_INDEX:
      return {
        ...state,
        waitingQueue: action.waitingQueue
      }
    case UPDATE_ERROR_CHECK_LIST:
      return {
        ...state,
        sierraInfoErrorCheck: action.sierraInfoErrorCheck
      }
    case UPDATE_PRE_PROCESS_LOG:
      return {
        ...state,
        preProcessLog: [...action.preProcessLog]
      }
    case USER_LOGOUT:
      return {
        ...defaultState,
        sierraInfoErrorCheck: []
      }
    case UPDATE_START_MSG:
      return {
        ...state,
        startMsg: action.startMsg
      }
    case UPDATE_END_MSG:
      return {
        ...state,
        endMsg: action.endMsg
      }
    case SAVE_CURRENT_PROFILE:
      return {
        ...state,
        profile: action.profile
      }
    case SAVE_EXPERIMENT_QUEUE:
      return {
        ...state,
        experimentQueue: action.queue
      }
    case UPDATE_DESIGN_STACKUP_ERROR_MESSAGE:
      return {
        ...state,
        stackupErrorList: action.stackupErrorList
      }
    case UPDATE_CONNECTOR_ERROR:
      return {
        ...state,
        connError: action.connError
      }
    default: return state
  }
};