import { ALL_PINS, BALL_TYPE_NONE, BALL_TYPE_NONE_LIST, CYLINDER, NEARBY_PINS, PIN, PIN_GROUP, PIN_PER_REF_NET, SINGLE_PIN, SPHEROID } from "./portTableHelper";
import { PORT_REFERENCE_COMPONENT, PORT_REFERENCE_PIN, PORT_REFERENCE_PIN_GROUP, PortSetup, PortSetupNegative, PortSetupPositive } from "./portsSetup";
import { AutoGeneratePorts } from './portGenerationHelper';
import { GENERATE_AUTO } from "./portTableHelper";
import { SERDES_TYPES, DDR_COMP_TYPES, CHIP } from "../PCBHelper";
import { splitComponentName } from '../helper/splitComponent';
import LayoutData from "../data/LayoutData";
import { calcDistanceOfTwoPoints } from "../helper/calcPointsDistance";
import { evaluate } from 'mathjs';
import { CIRCUIT, GAP, WAVE } from ".";
import { valueUnitSplit } from "../helper/valueUnitSplit";
import { setDefaultPortData } from '../userDefaultSetting/userDefaultSettingCtrl.js'
import { strDelimited } from "../helper/split";
import { BGA, DIE } from "../../constants/componentType";
import { ANDES_V2 } from "../../constants/pageType";
import { PACKAGE } from "../../constants/treeConstants";

function PortSetupPinInfo({
  signal = "",
  net = "",
  polarity = ""
}) {
  this.signal = signal; // string, signal name of the positive pin
  this.net = net; //string, net name of the positive pin
  this.polarity = polarity; //enum: [positive, negative], signal type in a diff pair
}

function PortsGenerationSetup({
  portType = PIN_GROUP,
  referenceType = SINGLE_PIN,
  filterPinOptions = ["number"],
  pinNumber = "5",
  pinDistance = "10mm"
}) {
  this.portType = portType || PIN_GROUP;
  this.referenceType = referenceType || SINGLE_PIN;
  if (referenceType === NEARBY_PINS) {
    this.filterPinOptions = [...(filterPinOptions || [])];
    this.pinNumber = pinNumber;
    this.pinDistance = pinDistance;
  }
}

function getSignalNetInfo(pinObj, signals) {
  if (!pinObj) {
    return {};
  }
  const info = getNetInfo({
    signals,
    signalName: pinObj.signal,
    net: pinObj.net
  });

  return info;
}

function getNetInfo({ signals, signalName, net }) {
  const signal = signals.find(it => it.name === signalName);
  let polarity = "";
  if (signal && signal.nets_N && signal.nets_N.includes(net)) {
    polarity = "negative";
  } else if (signal && signal.nets_P && signal.nets_P.includes(net)) {
    polarity = "positive";
  }
  return new PortSetupPinInfo({
    signal: signalName,
    net,
    polarity
  })
}

function getDefaultReferenceNets(powerNets = [], referenceNets) {

  if (referenceNets && referenceNets.length) {
    //add new refer nets by voltage is 0
    const newNets = powerNets.filter(item => !referenceNets.includes(item.name));
    const refNets = newNets.filter(item => parseFloat(item.value) === 0).map(item => item.name);
    referenceNets = [...referenceNets, ...refNets];
    //delete ref nets
    const deleteNets = referenceNets.filter(item => !powerNets.find(it => it.name === item));
    referenceNets = referenceNets.filter(item => !deleteNets.includes(item));
    return referenceNets;
  }
  let _powerNets = JSON.parse(JSON.stringify(powerNets));
  const refNets = _powerNets.filter(item => parseFloat(item.value) === 0);
  if (refNets.length) {
    return refNets.map(item => item.name);
  }
  return _powerNets.map(item => item.name);
}

function getDefaultPortSetupList({
  components,
  signals,
  pcbInfo,
  designId,
  referenceNets,
  ports_generation_setup,
  ports_generate_setup_list,
  types = [...SERDES_TYPES, DIE],
  referenceZ0,
  extractionType,
  avoid_single_pin_group
}) {
  let data = [];
  const ICComponents = components.filter(item => types.includes(item.type));
  for (let comp of ICComponents) {
    const currPortSetup = ports_generate_setup_list.find(item => item.component === splitComponentName(comp.name));
    if (!currPortSetup || !currPortSetup.setup || !currPortSetup.setup.portType !== PIN_GROUP) {
      avoid_single_pin_group = null
    }
    for (let item of comp.pins) {
      const index = data.findIndex(p => p.positive && p.positive.pin === item.pin)
      if (!item.signal || (index >= 0 && data[index].positive.component === comp.name)) {
        continue;
      }
      const info = getNetInfo({
        signals,
        signalName: item.signal,
        net: item.net
      });
      const itemPin = new PortSetup({
        portGenerationUsage: GENERATE_AUTO,
        info,
        z0: referenceZ0 ? referenceZ0 : "50",
        positive: new PortSetupPositive({
          component: splitComponentName(comp.name),
          pin: item.pin,
          avoid_single_pin_group
        }),
        negative: new PortSetupNegative({})
      })
      data.push(itemPin);
    }
  }
  let port_setups = JSON.parse(JSON.stringify(data));
  const autoPorts = new AutoGeneratePorts();
  data = autoPorts.autoGeneratePorts(data, pcbInfo, {
    ports_generation_setup,
    ports_generate_setup_list,
    designId,
    referenceNets,
    extractionType
  });
  let warnings = autoPorts.getWarnings();

  let change = false, _ports_generate_setup_list = [...ports_generate_setup_list];
  // Verify if wave can be used as the port type
  if (warnings && warnings.length) {
    warnings.forEach(item => {
      if (item.includes('wave port is not supported')) {
        const splitInfo = item.split(" ");
        const index = _ports_generate_setup_list.findIndex(item => item.component === splitInfo[1]);
        if (index > -1) {
          change = true;
          _ports_generate_setup_list[index].setup = {
            portType: CIRCUIT,
            referenceType: SINGLE_PIN
          }
        }
      }
    })
  }

  if (change) {
    autoPorts.cleanWarnings()
    // If wave cannot be used, change the default value to circuit
    data = autoPorts.autoGeneratePorts(data.port_setups, pcbInfo, {
      ports_generation_setup,
      ports_generate_setup_list: _ports_generate_setup_list,
      designId,
      referenceNets,
      extractionType
    });
    warnings = autoPorts.getWarnings();
  }
  const errors = autoPorts.getErrors();

  if (!errors || !errors.length) {
    port_setups = data.port_setups;
    ports_generate_setup_list = data.setupList;
  }

  return {
    port_setups,
    ports_generate_setup_list,
    errors,
    warnings,
    isExistPorts: autoPorts.isExistPorts
  }
}

function getCompBallSizeByPinSize(compObj, designId, returnNum, originalValue) {
  if (!compObj || !compObj.name) {
    return "";
  }
  const layoutDb = LayoutData.getLayout(designId);
  const compName = splitComponentName(compObj.name);
  const layoutComponent = LayoutData.getComponent(designId, compName);
  let unit = layoutDb.GetLayoutUnits();

  if (unit === "mils") {
    unit = "mil";
  }
  const _scale = evaluate(`1 ${unit} to um`).toString();
  const scale = _scale.split(' ')[0];

  let findPin = compObj.pins.find(item => !!item.signal) || compObj.pins.find(item => !!item.net);
  let findPinLocation = null
  if (layoutComponent) {
    findPinLocation = layoutComponent.mPinsLocationList.find(item => findPin && item.pinNumber === findPin.pin);
  }
  if (!findPinLocation || !findPinLocation.mLocation) {
    return "";
  }
  let pinSize = 0;
  if (findPinLocation.mLocation.shapeType === "polygon") {
    //mLocation : {Xs:[], Ys:[]}
    pinSize = getPolygonPinSize(findPinLocation.mLocation);
  } else if (findPinLocation.mLocation.shapeType === "circle" && findPinLocation.mLocation.r) {
    //mLocation : {xc:"", xc:"", r }
    pinSize = 2 * findPinLocation.mLocation.r;
  }

  if (pinSize && pinSize > 0) {
    pinSize = originalValue ? pinSize * scale : pinSize * scale * (3 / 4);
    if (pinSize < 10) {
      pinSize = pinSize > 0.001 ? pinSize.toPrecision(2) : pinSize.toExponential(2);
    } else {
      pinSize = pinSize.toFixed(0);
    }
    return returnNum ? pinSize : `${pinSize}um`;
  }
  return "";
}

/**
 * get polygon pin size 
 * @param {Object} location {Xs:[], Ys:[] }
*/
function getPolygonPinSize(location) {
  const Xs = location.Xs,
    Ys = location.Ys;

  /* if (Xs.length !== 4) {
    return null;
  } */

  let minDistance = +Infinity;
  for (let i = 0; i < Xs.length; i++) {
    for (let j = 1; j < Xs.length; j++) {
      if (i === j || i > j) {
        continue;
      }
      //calculate pin size
      const distance = calcDistanceOfTwoPoints({ mX: Xs[i], mY: Ys[i] }, { mX: Xs[j], mY: Ys[j] });
      if (distance < minDistance) {
        minDistance = distance;
      }
    }
  }



  if (minDistance > 0) {
    return Math.sqrt(minDistance);
  }
  return null;
}

function updateSetupComponentsByPortType({ components, designId, ports_generate_setup_list, extractionType, userDefaultSetting, isUsedBall }) {
  if (!components) {
    return components;
  }

  let portList = ports_generate_setup_list.map(item => item.component)
  let _components = [...components];
  for (let i = 0; i < _components.length; i++) {
    if (![CHIP, ...SERDES_TYPES, ...DDR_COMP_TYPES].includes(_components[i].type)) {
      delete _components[i].gap_size;
      delete _components[i].ball_height;
      delete _components[i].ball_size;
      delete _components[i].ball_mid_diameter;
      delete _components[i].ball_type;
      delete _components[i].ball_material;
      continue;
    }
    if (userDefaultSetting && userDefaultSetting.sierraSettings) {
      const { portType, extractionOption } = userDefaultSetting.sierraSettings;
      if (portList.includes(_components[i].name)) {
        _components[i] = setDefaultPortData(_components[i], portType, extractionOption, designId)
      }
    }
    _components[i] = updateCompByPortType(_components[i], ports_generate_setup_list, designId, extractionType, isUsedBall);
  }

  return _components;
}

function updateCompByPortType(itemComp, ports_generate_setup_list, designId, extractionType, isUsedBall) {
  const findComp = ports_generate_setup_list.find(item => item.component === itemComp.name);
  if (findComp) {
    switch (findComp.setup.portType) {
      case GAP:
        if (!itemComp.gap_size) {
          itemComp.gap_size = extractionType === "HFSS" ? "10um" : "0";
        }
        if (isUsedBall) {
          itemComp = getNewBallInfo(itemComp, designId, findComp.setup.portType)
        } else {
          delete itemComp.ball_height;
          delete itemComp.ball_size;
          delete itemComp.ball_mid_diameter;
          delete itemComp.ball_type;
          delete itemComp.ball_material;
        }
        break;
      case WAVE:
        itemComp = getNewBallInfo(itemComp, designId, findComp.setup.portType)
        delete itemComp.gap_size;
        break;
      case PIN_GROUP:
      case CIRCUIT:
        if (isUsedBall) {
          itemComp = getNewBallInfo(itemComp, designId, findComp.setup.portType)
        } else {
          delete itemComp.ball_height;
          delete itemComp.ball_size;
          delete itemComp.ball_mid_diameter;
          delete itemComp.ball_type;
          delete itemComp.ball_material;
        }
        delete itemComp.gap_size;
        break;
      default:
        delete itemComp.gap_size;
        delete itemComp.ball_height;
        delete itemComp.ball_size;
        delete itemComp.ball_mid_diameter;
        delete itemComp.ball_type;
        delete itemComp.ball_material;
        break;
    }
  }
  return itemComp;
}

function getNewBallInfo(_itemComp, designId, portType) {
  let ballInfo = {};
  let itemComp = JSON.parse(JSON.stringify(_itemComp))
  if (!itemComp.ball_size || !itemComp.ball_height) {
    ballInfo = getUpdateCompBallInfoByPinSize(itemComp, designId, null, itemComp.ball_type, portType)
  }
  if (!itemComp.ball_size) {
    itemComp.ball_size = ballInfo.ball_size
  }
  if (!itemComp.ball_height) {
    itemComp.ball_height = ballInfo.ball_height
  }
  if (itemComp.ball_type === SPHEROID && !itemComp.ball_mid_diameter) {
    itemComp.ball_mid_diameter = ballInfo.ball_mid_diameter
  }

  if (itemComp.ball_type === BALL_TYPE_NONE) {
    delete itemComp.ball_height;
    delete itemComp.ball_size;
    delete itemComp.ball_mid_diameter;
    delete itemComp.ball_material
  }
  return itemComp
}

function getBallInfo(key, pinSize) {

  function getValue(value, proportion) {
    if (!value && value !== 0) {
      return value
    }
    let _value = value * proportion;
    if (_value < 10) {
      _value = _value > 0.001 ? _value.toPrecision(2) : _value.toExponential(2);
    } else {
      _value = _value.toFixed(0);
    }
    return _value;
  }

  switch (key) {
    case SPHEROID:
      return {
        ball_size: getValue(pinSize, 0.9),
        ball_sizeUnit: "um",
        ball_height: getValue(pinSize, 0.8),
        ball_heightUnit: "um",
        ball_mid_diameter: getValue(pinSize, 1.1),
        ball_mid_diameterUnit: "um"
      };
    case BALL_TYPE_NONE:
      return {}
    default: return {
      ball_size: getValue(pinSize, 0.9),
      ball_sizeUnit: "um",
      ball_height: getValue(pinSize, 0.9),
      ball_heightUnit: "um"
    };
  }
}

function getUpdateCompBallInfoByPinSize(compObj, designId, returnNum, ballType, portType) {
  let info = {};
  let defaultBallType = "";
  if (ballType) {
    defaultBallType = ballType;
  } else if (portType && ![BGA, DIE].includes(compObj.type) && BALL_TYPE_NONE_LIST.includes(portType)) {
    defaultBallType = BALL_TYPE_NONE;
  } else {
    defaultBallType = compObj.type === BGA ? SPHEROID : CYLINDER;
  }
  const pinSize = getCompBallSizeByPinSize(compObj, designId, true, true);
  if (!pinSize && pinSize !== 0) {
    // When there is no value
    info = {
      ball_type: defaultBallType,
      ball_size: '',
      ball_height: ''
    }
  }
  const ballInfo = getBallInfo(defaultBallType, pinSize);
  info = {
    ball_type: defaultBallType,
    ball_size: returnNum ? ballInfo.ball_size : `${ballInfo.ball_size}um`,
    ball_height: returnNum ? ballInfo.ball_height : `${ballInfo.ball_height}um`
  }
  if (defaultBallType === SPHEROID) {
    info.ball_mid_diameter = returnNum ? ballInfo.ball_mid_diameter : `${ballInfo.ball_mid_diameter}um`
  }
  return info
}

function getPortGenerateSetupList({ components, setup = null, setup_list = null, userDefaultSetting, pageType, designType }) {
  const ICComps = components.filter(comp => [CHIP, ...SERDES_TYPES, ...DDR_COMP_TYPES].includes(comp.type));
  //default generation ports
  let ports_generate_setup_list = [];
  let portType = undefined, referenceType = undefined, filterPinOptions = ["number"], pinNumber = "5", pinDistance = "10mm";
  if (userDefaultSetting && userDefaultSetting.sierraSettings) {
    portType = userDefaultSetting.sierraSettings.portType;
    const portSetupInfo = userDefaultSetting.sierraSettings.portSetupInfo;
    referenceType = portType ? (portType === WAVE || portType === GAP) ? ALL_PINS : PIN : undefined;
    //GET reference type by user setting
    if (portSetupInfo && portSetupInfo.referenceType) {
      referenceType = portSetupInfo.referenceType;
      if (referenceType === NEARBY_PINS) {
        filterPinOptions = portSetupInfo.filterPinOptions || filterPinOptions;
        pinNumber = portSetupInfo.pinNumber || pinNumber;
        pinDistance = portSetupInfo.pinDistance || pinDistance;
      }
    }
  }
  if (pageType === ANDES_V2 || designType === PACKAGE) {
    portType = WAVE;
    referenceType = ALL_PINS;
  }

  ICComps.forEach(item => {
    const compName = splitComponentName(item.name);
    const oldSetup = setup_list ? setup_list.find(comp => comp.component === item.name || item.component === compName) : null;
    const find = ports_generate_setup_list.find(it => it.component === compName);

    if (!find) {
      ports_generate_setup_list.push({
        component: compName,
        setup: setup ? JSON.parse(JSON.stringify(setup)) :
          oldSetup && oldSetup.setup ? JSON.parse(JSON.stringify(oldSetup.setup)) : new PortsGenerationSetup({
            portType,
            referenceType,
            filterPinOptions,
            pinNumber,
            pinDistance
          })
      })
    }
  })
  return ports_generate_setup_list;
}

/**
 * Determine whether the gap_size needs to be updated
 * When the extraction type is HFSS, gap _size must be greater than 0. 
 * When the gap size is 0, the reference plane touches the metal layer. 
 * In HFSS, a physical plane is added as reference, so it causes short circuit.
 *  */
function judgeUpdateGapPortGapSizeSetup({
  components,
  extractionType,
  prevExtractionType,
  ports_generate_setup_list
}) {

  if (extractionType !== "HFSS" || prevExtractionType === extractionType) {
    return;
  }

  for (let comp of components) {
    if (![CHIP, ...SERDES_TYPES, ...DDR_COMP_TYPES].includes(comp.type)) {
      continue;
    }

    const findComp = ports_generate_setup_list.find(item => item.component === splitComponentName(comp.name));
    const findPortSetup = findComp && findComp.setup ? findComp.setup : {};
    if (findPortSetup.portType === GAP && (!comp.gap_size || parseFloat(comp.gap_size) <= 0)) {
      return true;
    }
  }
  return false;
}

/** 
 * When the extraction type is HFSS, gap _size must be greater than 0. 
 * */
function updateHFSSExtractionCompsGapSize(components, ports_generate_setup_list) {
  components.forEach(item => {
    const findComp = ports_generate_setup_list.find(comp => comp.component === splitComponentName(item.name));
    const findPortSetup = findComp && findComp.setup ? findComp.setup : {};
    if (findPortSetup.portType === GAP
      && [CHIP, ...SERDES_TYPES, ...DDR_COMP_TYPES].includes(item.type)
      && (!item.gap_size || parseFloat(item.gap_size) <= 0)) {
      item.gap_size = "10um";
    }
  });
  return components;
}

function portRenderSetupList(setupList) {
  let _setupList = JSON.parse(JSON.stringify(setupList));
  for (let item of _setupList) {
    if ([CIRCUIT, WAVE].includes(item.setup.portType)) {
      continue;
    }

    if (item.setup.referenceType === NEARBY_PINS) {
      item.setup = nearbyPinsSetup(item.setup);
    }
  }
  return _setupList;
}

function nearbyPinsSetup(setup) {
  let _setup = JSON.parse(JSON.stringify(setup));
  const pinDistanceObj = valueUnitSplit(_setup.pinDistance);
  _setup.pinDistance = pinDistanceObj.value || "10";
  _setup.pinDistanceUnit = pinDistanceObj.unit || "mm";
  _setup.pinNumber = _setup.pinNumber || "5";
  _setup.filterPinOptions = _setup.filterPinOptions || ["number"];
  return _setup;
}

function getReferencePins({ compInfo, referenceNets, editComponent }) {
  if (!compInfo[editComponent] || !compInfo[editComponent].referencePins) {
    return []
  }

  let pins = [];
  for (let net of referenceNets) {
    const netPins = [...compInfo[editComponent].referencePins[net]];
    pins.push(...netPins.map(item => `${item}::${net}`));
  }
  return pins;
}

function updatePortSetupsByType({ record, index, _port_setups, pinType, save }) {
  switch (pinType) {
    case SINGLE_PIN:
      if (!record.referencePin && !record.deletedReferencePin) {
        break;
      }

      if (record.deletedReferencePin) {
        _port_setups[index].negative = _port_setups[index].negative.type === PORT_REFERENCE_PIN ? {
          ..._port_setups[index].negative,
          component: record.component,
          NET: null,
          pin: null
        } : {
          ..._port_setups[index].negative,
          component: record.component,
          PINS_INFO: [],
          pins: []
        }
        break;
      }
      const [_pin, _net] = strDelimited(record.referencePin, "::");
      _port_setups[index].negative = _port_setups[index].negative.type === PORT_REFERENCE_PIN ? {
        ..._port_setups[index].negative,
        component: record.component,
        NET: _net,
        pin: _pin
      } : {
        ..._port_setups[index].negative,
        component: record.component,
        PINS_INFO: [{ component: record.component, pin: _pin, net: _net }],
        pins: [_pin]
      }
      break;
    case NEARBY_PINS:
    case PIN_PER_REF_NET:

      if (record.deletedReferencePin) {
        const [delPin, delNet] = strDelimited(record.deletedReferencePin, "::");
        const delIndex = _port_setups[index].negative.PINS_INFO.findIndex(item => item.component === record.component && item.pin === delPin && item.net === delNet);
        _port_setups[index].negative.PINS_INFO.splice(delIndex, 1);
        _port_setups[index].negative = {
          ..._port_setups[index].negative,
          component: record.component,
          pins: _port_setups[index].negative.pins.filter(item => item !== delPin)
        }
        break;
      }

      if (save && record.referencePin) {
        const [pin, net] = strDelimited(record.referencePin, "::");
        let prevPorts = pinType === PIN_PER_REF_NET ? _port_setups[index].negative.PINS_INFO.filter(item => item.net !== net) : _port_setups[index].negative.PINS_INFO;
        //remove other component negative pins
        prevPorts = prevPorts.filter(item => item.component === record.component);
        const prevPins = prevPorts.map(item => item.pin);
        _port_setups[index].negative = {
          ..._port_setups[index].negative,
          component: record.component,
          PINS_INFO: [...prevPorts, { component: record.component, pin, net }],
          pins: [...prevPins, pin]
        }
        break;
      }
      if (!record.referencePins) {
        break;
      }
      _port_setups[index].negative = {
        ..._port_setups[index].negative,
        component: record.component,
        pins: [],
        PINS_INFO: []
      }
      //remove other component negative pins
      _port_setups[index].negative.PINS_INFO = _port_setups[index].negative.PINS_INFO.filter(item => item.component === record.component);
      for (let item of record.referencePins) {
        const [pin, net] = strDelimited(item, "::");
        _port_setups[index].negative.PINS_INFO.push({ component: record.component, pin, net });
        _port_setups[index].negative.pins = _port_setups[index].negative.PINS_INFO.map(item => item.pin);
      }
      break;
    default: break;
  }
  return _port_setups;
}

function searchNetAndPins(searchValue, referenceNets, referencePinsObj) {
  let nets = [], pinsObj = {};
  if (!searchValue) {
    return { nets: referenceNets, pinsObj: referencePinsObj }
  }

  const reg = new RegExp(searchValue, "ig");

  nets = referenceNets.filter(net => !!net.match(reg));
  nets.forEach(item => { pinsObj[item] = [] });
  for (let net in referencePinsObj) {
    const pins = referencePinsObj[net].filter(item => item.match(reg));
    if (pins.length) {
      nets.unshift(net);
      pinsObj[net] = [...pins];
    }
  }
  nets = [...new Set(nets)];
  return { nets, pinsObj }
}

function checkPortSetups(port_setups) {
  let error = [];
  for (let port of port_setups || []) {
    if (!port.positive) {
      continue;
    }
    const negative = port.negative || {};
    if (
      ((negative.type === PORT_REFERENCE_PIN_GROUP
        || negative.type === PORT_REFERENCE_COMPONENT)
        && (!negative.pins || !negative.pins.length)
        && (!negative.nets || !negative.nets.length))
      ||
      (negative.type === PORT_REFERENCE_PIN && !negative.pin)) {
      error.push(`${port.positive.component} - ${port.positive.pin} reference port does not set.`);
    }
  }
  return error.length ? error : null;
}

function getPortReferencePin(record, portType, referenceNets) {

  if (portType === NEARBY_PINS) {
    return record.reference && record.reference.PINS_INFO ? record.reference.PINS_INFO.map(item => `${item.pin}::${item.net}`) : [];
  }

  if ((portType === SINGLE_PIN || (portType === PIN_PER_REF_NET && referenceNets.length === 1)) && record.reference.type === PORT_REFERENCE_PIN_GROUP) {
    return record.reference && record.reference.pins && record.reference.pins[0] && record.reference.PINS_INFO ? `${record.reference.pins[0]}::${record.reference.PINS_INFO[0].net}` : "";
  }

  if (portType === PIN_PER_REF_NET) {
    return record.reference && record.reference.PINS_INFO ? record.reference.PINS_INFO.map(item => `${item.pin}::${item.net}`) : [];
  }

  if (portType === SINGLE_PIN && record.reference.type === PORT_REFERENCE_PIN) {
    return record.reference && record.reference.pin ? `${record.reference.pin}::${record.reference.NET}` : [];
  }
}

export {
  PortSetupPinInfo,
  PortsGenerationSetup,
  getSignalNetInfo,
  getDefaultReferenceNets,
  getDefaultPortSetupList,
  getNetInfo,
  getCompBallSizeByPinSize,
  updateSetupComponentsByPortType,
  updateCompByPortType,
  getPortGenerateSetupList,
  judgeUpdateGapPortGapSizeSetup,
  updateHFSSExtractionCompsGapSize,
  portRenderSetupList,
  nearbyPinsSetup,
  getReferencePins,
  searchNetAndPins,
  updatePortSetupsByType,
  checkPortSetups,
  getPortReferencePin,
  getBallInfo,
  getUpdateCompBallInfoByPinSize,
  getPolygonPinSize
}