import { getSierraTouchStoneCurve, getFileInSierraPinToPinProject, getSierraEndToEndSparameterCurve } from '../../api/sierra';
import { getExperimentResultPromise, getExperimentCurve } from '../projectCtrl';
import { PDN_NPI } from '../../../pages/Sierra/constants';
import defaultColor from '@/constants/defaultColors';

function getInfo(name, components, extractType, virtualComps) {
  let comp = '', pin = '', signal = '', net = '';

  const vcComp = virtualComps.find(item => name.match(new RegExp(`^${item.name}_`, 'ig')));
  const compInfo = vcComp ? vcComp : components.find(c => name.match(new RegExp(`^${c.name}_`, 'ig')))
  if (compInfo) {
    comp = compInfo.name;
    const pins = compInfo.pins;
    let pinInfo = null;
    if (extractType === "PowerSI") {
      pinInfo = pins.find(p => name.match(new RegExp(`${p.pin}_${p.net}$`, 'ig')))
    } else {
      pinInfo = pins.find(p => name.match(new RegExp(`${p.pin}_${p.signal}$`, 'ig')))
    }
    if (pinInfo) {
      pin = pinInfo.pin;
      signal = pinInfo.signal;
      net = pinInfo.net;
    }
  }
  return { comp, pin, signal, net }
}
class ParameterData {

  constructor({ name, verificationId, verificationSubId, projectId, type, pcb, groupName, pcbId, ports }) {
    this.id = name || null;
    this.name = name || null;
    this.ports = ports || null;
    this.freq = null;
    this.matrix = null;
    this.verificationId = verificationId;
    this.verificationSubId = verificationSubId;
    this.projectId = projectId;
    this.type = type;
    this.pcb = pcb;
    this.names = [];
    this.groupName = groupName || null;
    this.pcbId = pcbId;
    this.colorCount = 0;
    this.colorArr = [];
  }

  /**
   * Get curve data
   *
   * @param param - s,y,z
   * @param value - am for amplitude, ​ph for phase​, re for real, im for imaginary, db for dB
   * type - s/y/z + am/ph/re/im
   */
  getCurveData = ({ row, col, param, value, fileIndex, sweep = false, curveType, folderName }) => {
    // TODO - multi file
    if (!this.matrix || !this.matrix[row] || !this.matrix[row][col]) {
      this.initMatrix(fileIndex);
    }

    let item = this.matrix[row][col],
      type = param + value;

    return new Promise((resolve, reject) => {
      if (item[type]) {
        resolve({
          type,
          color: item.color,
          curve: item[type]
        })
      } else {
        if (value === 'am' || value === 'ph' || value === 'db') {
          //Network Parameter - curve - /project/verification/{verification_id}/PinToPin/pcb_{pcb_subid}_{verification_subid}.sNp/data
          const _value = value === 'db' ? 'am' : value;
          if (sweep) {
            getExperimentCurve({ experimentId: this.verificationId, designId: this.pcbId, cols: [col], rows: [row], path: `pdn.s${_value}` }).then(response => {
              item[type] = response.data[0].curve;
              resolve({
                type,
                color: item.color,
                curve: item[type]
              })
            })
          } else if (curveType === "end-to-end") {
            getSierraEndToEndSparameterCurve({
              verificationId: this.verificationId,
              folderName: folderName,
              cols: [col],
              rows: [row],
              path: `end-to-end.s${_value}`
            }).then((response) => {
              item[type] = response.data.data[0].curve;
              resolve({
                type,
                color: item.color,
                curve: item[type]
              })
            })
          } else {
            getSierraTouchStoneCurve({ verificationId: this.verificationId, fileName: this.name, cols: [col], rows: [row], path: `pdn.s${_value}` }).then((response) => {
              item[type] = response.data.data[0].curve;
              resolve({
                type,
                color: item.color,
                curve: item[type]
              })
            })
          }
        } else {
          this.getCurveData({ row, col, param, value: 'am', sweep }).then(amData => {
            this.getCurveData({ row, col, param, value: 'ph', sweep }).then((phData) => {
              const cos = Math.cos;
              const sin = Math.sin;
              item[param + 're'] = amData.curve.map(function (am, i) {
                return am * cos(phData.curve[i]);
              });
              item[param + 'im'] = amData.curve.map(function (am, i) {
                return am * sin(phData.curve[i])
              });
              resolve({
                type,
                color: item.color,
                curve: item[type]
              })
            })
          })
        }
      }
    })
  }

  initMatrix = (index) => {
    let n = index ? index : 0
    this.matrix = [];
    for (let i = 0, num = this.ports.length; i < num; i++) {
      this.matrix[i] = [];
      for (let j = 0; j < num; j++) {
        this.matrix[i][j] = {
          display: true,
          color: this.getColor(j * num + i + n),
        }
      }
    }
  }

  getColor(i) {
    if (this.colorCount === defaultColor.length) {
      this.colorCount = 0;
      this.colorArr = [];
    }
    let colorIndex = i % defaultColor.length;
    while (this.colorArr[colorIndex]) {
      colorIndex = (colorIndex + 1) % defaultColor.length;
    }
    this.colorCount++;
    this.colorArr[colorIndex] = true;
    return defaultColor[colorIndex];
  }

  getNpiFile = (index, verificationId, content) => {
    return new Promise((resolve, reject) => {
      if (!content || !content.content) {
        resolve(this);
        return;
      }
      const setupContent = content.content;
      // snp match: Reg /\.s[0-9]{1,}p/i
      //Network Parameter - npi - path={verification_subid}/result/pcb_{pcb_subid}_{verification_subid}/pdn.npi
      const name = this.name.split(/\.s[0-9]{1,}p/i)[0];
      let path = `${verificationId}/result/${name}/pdn.npi`;
      getFileInSierraPinToPinProject(this.projectId, path).then(res => {
        if (res) {
          this.parseNpiFile(res.data, setupContent);
          this.initMatrix(index);
          resolve(this);
        } else {
          this.ports = [];
          this.freq = [];
          this.matrix = [];
          resolve(this);
        }
      }, error => {
        this.ports = [];
        this.freq = [];
        this.matrix = [];
        resolve(this);
      })
    })
  }

  getExperimentNpiFile = (index, experimentId, content) => {
    return new Promise((resolve, reject) => {
      if (!content || !content.content) {
        resolve(this);
        return;
      }
      const setupContent = content.content;
      const designId = content.pcbId;
      getExperimentResultPromise({ experimentId, option: PDN_NPI, designId }).then(res => {
        if (res) {
          this.parseNpiFile(res, setupContent);
          this.initMatrix(index);
          resolve(this);
        } else {
          this.ports = [];
          this.freq = [];
          this.matrix = [];
          resolve(this);
        }
      }, error => {
        this.ports = [];
        this.freq = [];
        this.matrix = [];
        resolve(this);
      })
    })
  }

  parseNpiFile = (data, setupContent) => {
    const extractType = setupContent.extraction ? setupContent.extraction.type : null;
    let components = setupContent.components || [];
    const virtualComps = setupContent.virtualComps || [];

    if (!data) {
      this.ports = [];
      this.freq = [];
      return;
    }
    let lineBuffer = data.match(/[^\r\n]+/g);
    if (lineBuffer.length < 2) {
      this.ports = [];
      this.freq = [];
      return;
    }
    let portNum = Number(lineBuffer[0].slice(5));
    let ports = [],
      frequency = [],
      endLine = portNum + 1,
      samePort = 0;
    for (let i = 1; i < endLine; i++) {
      if (!lineBuffer[i]) continue;
      let words = lineBuffer[i].split(' ');
      let name = words[2];
      if (name.startsWith('Chip') || name.startsWith('IC')) {
        name = name.slice(5, name.lastIndexOf('_'));
      } else if (name.startsWith('Vrm')) {
        name = name.slice(4, name.lastIndexOf('_')) + '(VRM)';
      } else {
        // port_001_R7_1_Signal1,  port_001_R7_1_Signal_1
        const splitName = name.split('_');
        if (splitName.length > 4 && extractType !== "PowerSI") {
          const portNum = splitName[1]
          const portlength = 6 + portNum.length;
          name = name.slice(portlength, name.length);
        } else if (words[3] && extractType === "PowerSI") {
          // 1 50 U1-D10 I2C2_SCL -> comp_pin_net
          const [comp, pin] = words[2].split("-") || [];
          name = `${comp}_${pin}_${words[3]}`;
        }
      }

      const { comp, pin, signal, net } = getInfo(name, components, extractType, virtualComps);
      name = extractType === "PowerSI" ? `${comp}_${pin}_${signal}` : name;

      if (!this.names.includes(name)) {
        ports.push({
          index: samePort > 0 ? Number(words[0]) - samePort : Number(words[0]),
          impedance: Number(words[1]),
          name: name,
          comp,
          pin,
          display: true,
          signal: signal,
          net
        })
        this.names.push(name)
      } else {
        samePort = samePort + 1;
      }
    }

    for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
      frequency.push(Number(lineBuffer[i]))
    }

    this.ports = ports;
    this.freq = frequency;

  }

  setFreq = (freq) => {
    this.freq = freq || [];
  }
}

export default ParameterData;