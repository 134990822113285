import { connect } from 'react-redux';
import { selectLayer, changeColorBy, clearSelection } from '../store/FastPI/actionCreators';
import { LayerTab } from '../components';
import {
  checkboxChange, selectedAll, clearOneSelection,
  cancelSelectedAll, showSelected, hideSelected, changeDisplaySelected
} from '../store/FastPI/actionCreators';
import '../index.css';
import { FASTPI } from '../../../constants/pageType';

const mapState = (state) => {
  const { PDNReducer: { explorer: { selections } } } = state;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return {
    selections,
    defaultLeft,
    defaultTop,
    pageType: FASTPI
  }
};

const mapDispatch = (dispatch) => ({
  selectLayer(checkedList, designID) {
    dispatch(selectLayer(checkedList, designID));
  },
  changeColorBy(value, designID) {
    dispatch(changeColorBy(value, designID))
  },
  checkboxChanged: (canvasObj) => {
    dispatch(checkboxChange(canvasObj))
  },
  clearOneSelection: (arr, designID) => {
    dispatch(clearOneSelection(arr, designID))
  },
  selectedAll: (designID) => {
    dispatch(selectedAll(designID))
  },
  cancelSelectedAll: (designID) => {
    dispatch(cancelSelectedAll(designID))
  },
  showSelected: (designID) => {
    dispatch(showSelected(designID))
  },
  hideSelected: (designID) => {
    dispatch(hideSelected(designID))
  },
  changeDisplaySelected: (show, designID) => {
    dispatch(changeDisplaySelected(show, designID))
  },
  clearSelection(designID) {
    dispatch(clearSelection(designID))
  }
})

export default connect(mapState, mapDispatch)(LayerTab);