import React, { Component, Fragment, createRef } from 'react';
import UploadFile from '@/components/UploadFile';
import NP from 'number-precision';
import { repeatedNames, checkFileName } from '@/services/helper/nameHelper';
import { PKG_TOUCHSTONE, PCB_TOUCHSTONE, PASSIVE_TOUCHSTONE, CABLE_TOUCHSTONE, CONNECTOR_TOUCHSTONE, GENERIC_TOUCHSTONE, BUFFER_SPICE, SPICE } from '../../constants/libraryConstants';
import { message, Select } from 'antd';
import { ANDES_V2, HIMALAYAS } from '../../constants/pageType';
import './index.css';

const { Option } = Select;
class LibraryUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      name: null,
      errorFileList: [],
      errorMsg: '',
      repeatedName: [],
      doubleName: '',
      checking: false,
      uploading: false,
      compressProgress: -1,
      uploadProgress: -1,
      compressMsg: '',
      fileType: "file",
      uploadCheckMessage: '',
      tags: []
    }
    this.decapRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onUploadFiles = (fileList, errorFileList, fileType) => {
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
    });
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList],
      fileType
    });
  }

  updateFileList = (fileList) => {
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
    });
    this.setState({
      fileList: [...fileList]
    });
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    const { modelType } = this.props;
    let _fileList = [...fileList];
    const findIndex = _fileList.findIndex(item => item.name === prevName);
    const fileSuffix = _fileList[findIndex].fileSuffix;
    _fileList[findIndex].name = name;
    _fileList[findIndex].fileName = `${name}${fileSuffix}`;
    _fileList[findIndex].file.fileName = `${name}${fileSuffix}`;
    this.setState({
      fileList: [..._fileList]
    });
    const names = _fileList.map(item => item.fileName);
    return this.checkFileNames(modelType, names);
  }

  checkFileNames = (type, names) => {
    const { libraryList } = this.props;
    let reName = [];
    for (let item of names) {
      if (!item) {
        this.setState({
          checking: false,
          errorMsg: 'Model name is not set.'
        });
        return;
      } else {
        let index = -1;
        if ([PKG_TOUCHSTONE, PCB_TOUCHSTONE, PASSIVE_TOUCHSTONE, CABLE_TOUCHSTONE, CONNECTOR_TOUCHSTONE, GENERIC_TOUCHSTONE].includes(type)) {
          const snpSuffix = /.s[0-9]{1,}p/ig;
          const name = typeof (item) === 'string' ? item.replace(snpSuffix, "") : item;
          index = libraryList.findIndex(i => i.name.replace(snpSuffix, "") === name);
        } else {
          index = libraryList.findIndex(i => i.name === item);
        }

        //Whether the file has been uploaded
        if (index > -1) {
          reName.push(item);
          this.setState({
            checking: false
          });
        }
      }
    }

    if (reName.length > 0) {
      this.setState({
        errorMsg: 'Model name already exists!',
        repeatedName: [...reName]
      });
      return;
    }
    // Whether the file name is duplicated.
    const rName = repeatedNames(names);

    if (rName) {
      this.setState({
        doubleName: rName,
        checking: false,
        errorMsg: 'Model name cannot be repeated.'
      });
      return;
    }

    //Whether the file name is legal.
    const errorName = checkFileName(names);

    if (errorName && errorName.length > 0) {
      this.setState({
        checking: false,
        errorName: [...errorName],
        errorMsg: 'File names may only contain the following characters: numbers, letters, underscores, minus.'
      });
      return;
    }
    this.setState({
      errorMsg: '',
      repeatedName: [],
      errorName: [],
      doubleName: ''
    });
    return true;
  }

  changeName = (e) => {
    this.setState({
      name: e.target.value || null
    })
  }

  delFile = (fileList, errorFileList) => {
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList],
      uploadCheckMessage: ''
    })
    const { modelType } = this.props;
    const names = fileList.map(item => { return item.fileName });
    this.checkFileNames(modelType, names);
  }

  saveLibraryFile = () => {
    const { fileList, compressProgress, uploadProgress } = this.state;
    const { modelType } = this.props;
    if (compressProgress === -1 && uploadProgress === -1) {
      this._createLibrary(modelType, { fileList: [...fileList] });
    }
  }

  _createLibrary = (type, info) => {
    this.setState({
      checking: true,
      errorMsg: '',
    });
    let fileList = [...info.fileList];
    if (!fileList.length) {
      this.props.closeLibraryPanel();
      return;
    }
    const { fileType, tags } = this.state;
    const names = fileList.map(item => item.fileName);
    const status = this.checkFileNames(type, names);
    if (status) {
      this.setState({
        checking: false,
        uploading: true,
        compressProgress: 0,
      })
      let libraries = [];
      let files = [];
      const { vde } = this.props;
      fileList.forEach(file => {
        const fileName = fileType === 'folder' ? file.name : file.fileName;
        libraries.push({
          name: fileName,
          fileName: fileName,
          type: fileType && fileType !== 'zip' ? fileType : "file",
          vdeNum: vde ? vde : file.vdeNum
        });
        files.push(file.file);
      });
      this.props.createLibrary({ files, libraries, fileType, typeName: type, libraryFormat: 'file', metadata: this.metadata, config: this.config, tags }).then(res => {
        const { expandedKeys } = this.props;
        let keys = [...expandedKeys];
        if (!keys.includes(type)) {
          keys.push(type);
        }
        this.props.expandMenu([...keys]);
        this.setState({
          uploading: false,
          repeatedName: [],
          compressProgress: -1,
          uploadProgress: 100,
          compressMsg: ''
        });

        let filesInfo = [];
        const { product, modelType } = this.props;
        if ([ANDES_V2, HIMALAYAS].includes(product)) {
          filesInfo = res || []
        } else {
          files.forEach(item => {
            let find = res && Array.isArray(res[type]) ? res[type].find(i => i.libraryStructure && item.fileName === i.libraryStructure.name) : null;
            if (find) {
              filesInfo.push({
                id: find.id,
                name: find.libraryStructure.name,
                type
              })
            }
          })
        }
        setTimeout(() => {
          this.props.closeLibraryPanel(filesInfo, modelType);
          if (product !== ANDES_V2 || ![BUFFER_SPICE, SPICE].includes(modelType)) {
            this.props.updateLibraryMenu();
          }
        }, 500);
      }, error => {
        if (this.props.checkDisplay) {
          // Some files need to be uploaded to check if the data is correct
          setTimeout(() => {
            this.setState({
              uploading: false,
              repeatedName: [],
              compressProgress: -1,
              uploadProgress: -1,
              compressMsg: '',
              errorMsg: '',
              uploadCheckMessage: error
            })
          }, 50)
        } else {
          message.error('Create library failed! ' + error)
          setTimeout(() => {
            this.props.closeLibraryPanel();
          }, 500);
        }
      });
    }
  }

  metadata = (metadata) => {
    let msg = "Progress " + metadata.percent.toFixed(2) + " %";
    if (metadata.currentFile) {
      msg += ", current file " + metadata.currentFile;
    }
    const progress = NP.strip(parseFloat(metadata.percent.toFixed(2)));
    setTimeout(() => {
      if (progress >= 100) {
        this.setState({
          compressProgress: -1,
          uploadProgress: 0,
          compressMsg: ''
        });
      } else {
        this.setState({
          compressProgress: progress,
          compressMsg: msg
        });
      }
    }, 50);
  }

  getUploadingStatus = () => { return this.state.uploading };

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          compressProgress: 100,
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  changeTags = (value) => {
    this.setState({
      tags: value
    })
  }

  tagRender = () => {
    const { tagList } = this.props;
    const { tags } = this.state;
    return <div className="library-upload-tag-content">
      <span className="library-upload-tag-title">Tag</span>
      <Select
        onChange={(value) => this.changeTags(value)}
        value={tags}
        dropdownStyle={{ zIndex: 100000 }}
        showSearch={true}
        className='aurora-select library-upload-tag-select'
      >
        {tagList.map(tag => <Option key={tag}>{tag}</Option>)}
      </Select>
    </div>
  }

  render() {
    const { uploadTitle, accept, importText, special, libraryOptions, allowMulti = true, tagList } = this.props;
    const { uploading, errorMsg, repeatedName, doubleName, checking,
      compressProgress, uploadProgress, compressMsg, errorName, uploadCheckMessage } = this.state;
    return (
      <Fragment>
        {tagList && tagList.length ? this.tagRender() : null}
        <UploadFile
          text={uploadTitle}
          importText={importText}
          onUploadFile={this.onUploadFiles}
          delFile={this.delFile}
          saveFile={this.saveLibraryFile}
          changeFileName={this.changeFileName}
          updateFileList={this.updateFileList}
          repeatedName={repeatedName}
          doubleName={doubleName}
          errorName={errorName}
          errorMsg={errorMsg}
          accept={accept}
          special={special}
          nameStatus={true}
          allowMulti={allowMulti}
          compressProgress={compressProgress}
          uploadProgress={uploadProgress}
          compressMsg={compressMsg}
          uploading={uploading}
          checking={checking}
          libraryOptions={libraryOptions}
          uploadCheckMessage={uploadCheckMessage}
        />
      </Fragment>
    )
  }
}

export default LibraryUpload;