import { RLC_UNITS_SUFFIX } from "../helper/getRLCInputKey";

function numSplit(value) {
  let num = "", unit = "";

  if (!value || typeof (value) !== "string") {
    return { value, unit };
  }
  const numReg = /^[0-9]\d*(\.\d+)?e(-|\+)?\d+|^[0-9]\d*(\.\d+)?/gi;
  const number = value.match(numReg);
  if (number && number.length) {
    num = number[0];
    unit = value.slice(num.length);
  }

  return { value: num, unit };
}

function getRLCText(value, type) {
  if (!value) {
    return "";
  }
  return `${value}${RLC_UNITS_SUFFIX[type.slice(0, 3).toUpperCase()]}`;
}

function SectionColumn({ sectionIndex, index, allowDel = false }) {
  let _index = index;
  if (!index) {
    _index = sectionIndex;
  }
  return {
    title: `Section ${sectionIndex}`,
    dataIndex: `section_${sectionIndex}`,
    displayTitle: `Section ${_index}`,
    key: `${sectionIndex}`,
    allowDel: allowDel,
    width: 120
  }
}

export {
  numSplit,
  getRLCText,
  SectionColumn
}