import { call, put, takeEvery, fork, all, select } from 'redux-saga/effects';
import { DELETE_USER, EDIT_USER, RESET_PASSWORD } from './actionType';
import { updateUserPromise, deleteUserPromise } from '@/services/userCtrl';
import { jumpToPageAction } from '../../actionCreators';
import { message } from 'antd';
import { resetPasswordPromise } from '../../../../services/authCtrl';

function* deleteAccount() {
  yield takeEvery(DELETE_USER, function* (action) {
    try {
      const { id } = action;
      yield call(deleteUserPromise, id);
      const { reducer: { pagination: { total } } } = yield select();
      const page = parseInt((total - 2) / 10);
      yield put(jumpToPageAction(page + 1, 'user'));
      message.success('Delete user successfully!', 2);
    } catch (error) {
      message.error(`Delete user failed: ${error}!`, 2);
    }
  })
}

const getStatus = (status) => {
  switch (status) {
    case 'Inactivated':
      return 0;
    case 'Activated':
      return 1;
    case 'Expired':
      return 2;
    default: return 1
  }
}

function* editUser() {
  yield takeEvery(EDIT_USER, function* (action) {
    try {
      const { reducer: { pagination: { current } } } = yield select();
      const { name, firstName, lastName, region, roleIds } = action.information;
      const data = {
        name,
        firstName,
        lastName,
        region,
        status: getStatus(action.information.status),
        roleIds
      }
      try {
        const res = yield call(updateUserPromise, action.id, data);
        if (res) {
          yield put(jumpToPageAction(current, 'user'));
        }
      } catch (error) {
        message.error(error, 2);
      }
    } catch (error) {
      message.error('Update user failed!', 2);
    }
  })
}

function* resetUserPassword() {
  yield takeEvery(RESET_PASSWORD, function* (action) {
    const { param } = action;
    try {
      yield call(resetPasswordPromise, param);
      message.success('Reset user password successfully!', 2);
    } catch (error) {
      message.success('Reset user password failed! ' + error, 2);
    }
  })
}

function* AdminUserSagas() {
  yield all([
    fork(deleteAccount),
    fork(editUser),
    fork(resetUserPassword)
  ])
}

export default AdminUserSagas;
