import {
  EXPAND_MENU,
  ADD_SIGNAL,
  UPDATE_ANDES_INTERFACE,
  GET_VERIFICATION_CONTENT,
  UPDATE_ANDES_CONTENT,
  EDIT_SIGNAL_NAME,
  UPDATE_ANDES_INFO,
  EDIT_SIGNAL,
  UPDATE_INTERFACES,
  EDIT_CHANNEL_TYPE,
  DELETE_SIGNAL,
  CHANGE_SERDES_TYPE,
  CHANGE_DATA_RATE,
  SAVE_EXTRACTION,
  UPDATE_NETS_COMPS,
  SAVE_CURRENT_VERIFICATION,
  CURRENT_VERIFICATION_DEBUG_VERIFY
} from './actionTypes';

export const expandMenu = (expandKeys) => ({
  type: EXPAND_MENU,
  expandKeys
});

export const addSignal = () => ({
  type: ADD_SIGNAL
});

export const updateAndesInterface = (data) => ({
  type: UPDATE_ANDES_INTERFACE,
  data
});

export const getVerificationContent = (verificationID, loadPCB) => ({
  type: GET_VERIFICATION_CONTENT,
  verificationID,
  loadPCB
});

export const updateAndesContent = (info) => ({
  type: UPDATE_ANDES_CONTENT,
  info
});

export const editSignalName = (name, prevName) => ({
  type: EDIT_SIGNAL_NAME,
  name,
  prevName
});

export const updateAndesInfo = (data) => ({
  type: UPDATE_ANDES_INFO,
  data
});

export const editSignal = ({ nets, pcbId, signalName, netType }) => ({
  type: EDIT_SIGNAL,
  nets,
  pcbId,
  signalName,
  netType
});

export const updateInterfaces = ({ Interfaces, pcb, pcbId }) => ({
  type: UPDATE_INTERFACES,
  Interfaces,
  pcb,
  pcbId
});

export const editChannelType = (info) => ({
  type: EDIT_CHANNEL_TYPE,
  info
});

export const deleteSignal = ({ name }) => ({
  type: DELETE_SIGNAL,
  name
});

export const changeSerdesType = ({ serdesType }) => ({
  type: CHANGE_SERDES_TYPE,
  serdesType
});

export const changeDataRate = ({ dataRate }) => ({
  type: CHANGE_DATA_RATE,
  dataRate
});

export const saveExtraction = (extraction, pcb) => ({
  type: SAVE_EXTRACTION,
  extraction,
  pcb
});

export const updateNetsComps = (updateNetsComponents) => ({
  type: UPDATE_NETS_COMPS,
  updateNetsComponents
});

export const saveCurrentVerification = (verificationIds, currentVerificationId) => ({
  type: SAVE_CURRENT_VERIFICATION,
  verificationIds,
  currentVerificationId
});

export const currentVerificationDebugVerify = (step, verificationId) => ({
  type: CURRENT_VERIFICATION_DEBUG_VERIFY,
  step,
  verificationId
})