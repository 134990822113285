import {
  getRockyLibraryList, createRockyLibrary, updateRockyLibraryData, delRockyLibraryByIDs,
  getRockyLibraryData, getLibraryParseData, getRockyLibraryFile,
  getRockyLibraryGeneralData, getMacroModelingStatusByLibraryId,
  calculateRockyImpedance, getTemplateDetail, getEBDJson, rockyViaTraceCopy,
  rockyLibraryRename, getDefaultLibrary, updateDefaultLibrary, getRockyLibraryById
} from '../../api/Rocky/library';
import IbisModelHelper from '../helper/IbisModelHelper';
import SpiceModelHelper from '../helper/SpiceModelHelper';
import TouchstoneHelper from '../../helper/touchstoneHelper';
import EBDHelper from '../../helper/ebdHelper';
import apiProcess from '../../api/utility';
import { getResBlob } from '../../helper/downloadHelper';
import { getSPFolderFileDetail, getSPFolderFileList, getIBISFolderFileDetail } from '../../api/v2/libraryCtrl';
import SpFolderModelHelper from '../../LibraryHelper/SpiceModelHelper/SpFolderModelHelper';
import { SUCCESS } from '@/constants/returnCode';
import CPMCSMSpice from './csmCpmParseHelper';
export { getLibraryArray, libraryItem } from './libraryData';

/**
 * Get rocky user library list
 * 2020/06/01
 * @export
 * @returns Promise
 */
export function getLibraryList() {
  return apiProcess(getRockyLibraryList);
}

/**
 * Upload rocky user library
 * 2020/05/30
 * @export
 * @returns XMLHttpRequest
 */
export function createLibrary({ files, libraries, typeName, libraryFormat, metaData, config }) {
  return apiProcess(createRockyLibrary, { files, libraries, typeName, libraryFormat, metaData, config })
}

/**
 * create rocky  vector user library
 * 2020/05/30
 * @export
 * @returns XMLHttpRequest
 */
export function updateLibraryData({ libraryId, libraries, typeName, libraryFormat, config }) {
  return apiProcess(updateRockyLibraryData, { libraryId, libraries, typeName, libraryFormat, config })
}

/**
 * delete rocky user library by ids
 * 2020/06/01
 * @export
 *  @param libraryIds:array [] String  delete library ids
 * @returns Promise
 */
export function delLibraryByIds(libraryIds, fileType) {
  return new Promise((resolve, reject) => {
    apiProcess(delRockyLibraryByIDs, libraryIds).then(res => {
      resolve(res);
      let id = libraryIds[0];
      if (fileType === 'IBIS' && ibisModelHelper) {
        ibisModelHelper.deleteModel(id);
      } else if (fileType === 'SPICE' && spiceModelHelper) {
        spiceModelHelper.deleteModel(id);
      }
      if (fileType === 'pkg_spice' && spiceModelHelper) {
        spiceModelHelper.deleteModel(id);
      } else if (fileType === 'pkg_touchstone' && touchstoneHelper) {
        touchstoneHelper.deleteModel(id);
      }
    }, error => {
      reject(error)
    })
  })
}

/**
 * get library data  by library id
 * 2020/06/01
 * @export
 *  @param libraryId
 * @returns Promise
 */
export function getLibraryData(libraryId) {
  return new Promise((resolve, reject) => {
    apiProcess(getRockyLibraryData, libraryId).then(res => {
      resolve(res)
    }, error => {
      console.error(error);
      resolve(null);
    })
  })
}

export function getLibraryFileContent(libraryId, fileName) {
  return new Promise((resolve, reject) => {
    getRockyLibraryFile(libraryId, fileName).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      resolve(null)
    })
  })
}

let ibisModelHelper = null;
/**
 * get ibis file content list.
 * 2019.11.15
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage Tx/Rx
 * @returns Promise
 */
export function getIbisModelList({ libraryId, usage, fileName, libType }) {
  return new Promise((resolve, reject) => {
    let _fileName = null, id = libraryId;
    if (fileName && libType === "IBIS_AMI") {
      _fileName = fileName;
      id = `${libraryId}-${_fileName}`;
    }

    if (!ibisModelHelper) {
      ibisModelHelper = new IbisModelHelper([id]);
    }

    const models = ibisModelHelper.getIbisModelList(id, usage);
    if (!models) {
      getLibraryParseData(libraryId, _fileName).then((response) => {
        ibisModelHelper.parseModelSelector(id, response.data);
        const model = ibisModelHelper.getIbisModelList(id, usage);
        resolve(model);
      },
        (error) => {
          console.error(error)
          resolve([]);
        }
      )
    } else {
      resolve(models);
    }
  });
}

/**
 * get spice file content list.
 * 2019.11.19
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage TX/RX
 * @returns Promise
 */
let spiceModelHelper = null;

export function getSpiceModelList({ libraryId, fileName, usage }) {
  const key = fileName ? `${libraryId}::${fileName}` : libraryId;
  return new Promise((resolve, reject) => {
    if (!spiceModelHelper) {
      spiceModelHelper = new SpiceModelHelper([key]);
    }
    const models = spiceModelHelper.getSpiceModelList(key, usage);
    if (!models) {
      getRockyLibraryFile(libraryId, fileName).then((response) => {
        spiceModelHelper.parseModelSelector(key, response.data);
        const model = spiceModelHelper.getSpiceModelList(key, usage);
        resolve({ models: model, libraryId, fileName });
      }, error => {
        console.error(error)
        resolve({ models, libraryId, fileName });
      })
    } else {
      resolve({ models, libraryId, fileName });
    }
  });
}

/**
 * get pkg spice file parse.
 * 2020/11/13
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */

export function getPkgSpiceModelList(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, models: [] });
      return;
    }
    if (!spiceModelHelper) {
      spiceModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = spiceModelHelper.getSpiceModelList(libraryId);
    if (!models) {
      getLibraryParseData(libraryId).then((response) => {
        spiceModelHelper.parseModelSelector(libraryId, response.data);
        const _models = spiceModelHelper.getSpiceModelList(libraryId);
        resolve({ libraryId, models: _models });
      }, error => {
        console.error(error);
        resolve({ libraryId, models: [] });
      })
    } else {
      resolve({ libraryId, models });
    }
  });
}

/**
 * Download library file, response: file
 * 
 * @param {string} libraryId
 * @param {string} libraryName
 * @export
 * @returns Promise
 */
export function downloadLibraryFile(libraryId, libraryName) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    getResBlob(`api/v3/library/${libraryId}/rocky/download?libraryName=${libraryName}&access_token=${token}`).then(blob => {
      resolve(blob);
    }, error => {
      resolve(null);
    });
  })
}

export function getIBISParseModels({ libraryId, usage }) {
  if (ibisModelHelper) {
    const models = ibisModelHelper.getIbisModelList(libraryId, usage);
    return models;
  } else {
    return null
  }
}

export function getSpiceParseModels({ libraryId, usage }) {
  if (spiceModelHelper) {
    const models = spiceModelHelper.getSpiceModelList(libraryId, usage);
    return models;
  } else {
    return null
  }
}

export function getIBISPackageModels(libraryId) {
  if (ibisModelHelper) {
    const models = ibisModelHelper.getIbisPackageList(libraryId);
    return models;
  } else {
    return null
  }
}

export function getFolderSpiceParseModels({ libraryId, fileName }) {
  if (spFolderModelHelper) {
    const fileList = spFolderModelHelper.getModelList(libraryId, [fileName]);
    const models = fileList && fileList.length ? fileList[0].models || [] : []
    return models;
  } else {
    return null
  }
}

/**
 * get ibis file content list.
 * 2019.11.15
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage TX/RX
 * @returns Promise
 */
export function getIbisPackageList(libraryId) {
  return new Promise((resolve, reject) => {
    if (!ibisModelHelper) {
      ibisModelHelper = new IbisModelHelper([libraryId]);
    }
    const packages = ibisModelHelper.getIbisPackageList(libraryId);
    if (!packages) {
      getLibraryParseData(libraryId).then((response) => {
        ibisModelHelper.parseModelSelector(libraryId, response.data);
        const _packages = ibisModelHelper.getIbisPackageList(libraryId);
        resolve(_packages);
      },
        (error) => {
          console.error(error)
          resolve([]);
        }
      )
    } else {
      resolve(packages);
    }
  });
}

/**
 * get rocky library data (VRM)
 * 
 * @param {string} libraryId
 * @export
 * @returns Promise
 */
export function getLibraryGeneralData(libraryId) {
  return new Promise((resolve, reject) => {
    apiProcess(getRockyLibraryGeneralData, libraryId).then(res => {
      resolve(res);
    }, error => {
      console.error(error);
      resolve(null);
    })
  })
}

let touchstoneHelper = null;
/**
 * get touchstone file parse.
 * 2020.11.05
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */
export function getTouchstoneParse(libraryId, fileName) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, ports: [] });
      return;
    }
    if (!touchstoneHelper) {
      touchstoneHelper = new TouchstoneHelper([libraryId]);
    }
    const ports = touchstoneHelper.getTouchstonePorts(libraryId);
    if (!ports) {
      getLibraryParseData(libraryId).then((response) => {
        touchstoneHelper.parseTouchstonePorts(libraryId, response.data, fileName);
        const _ports = touchstoneHelper.getTouchstonePorts(libraryId);
        resolve({ libraryId, ports: _ports, fileName });
      },
        (error) => {
          console.error(error)
          resolve({ libraryId, ports: [], fileName });
        })
    } else {
      resolve({ libraryId, ports, fileName });
    }
  });
}

export function getTouchstoneFile(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve(null);
      return;
    }
    getLibraryParseData(libraryId).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      }
    }, (error) => {
      console.error(error)
      resolve(null);
    })
  });
}

export function getTouchstoneParsePorts(libraryId) {
  if (touchstoneHelper) {
    const ports = touchstoneHelper.getTouchstonePorts(libraryId);
    return ports;
  } else {
    return null;
  }
}

/**
 * get rocky library Macro Modeling status
 * 2020/11/10
 * @export
 *  @param libraryId
 * @returns Promise
 */
export function getLibraryMacroModelingStatus(libraryId) {
  if (!libraryId) {
    return new Promise((resolve, reject) => {
      resolve(null);
      return;
    })
  } else {
    return apiProcess(getMacroModelingStatusByLibraryId, libraryId);
  }
}

export function calculateImpedance(config) {
  return apiProcess(calculateRockyImpedance, config);
}

/**
 * Get via/trace detail data by id
 * 2021/04/13
 * @param {string} libraryId
 * @return Promise
 *  */
export function getDetailData(libraryId) {
  return apiProcess(getTemplateDetail, libraryId);
}


let ebdHelper = null;
export function getEBDParse(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, ports: [] });
      return;
    }

    if (!ebdHelper) {
      ebdHelper = new EBDHelper([libraryId]);
    }

    const ports = ebdHelper.getEBDJson(libraryId);
    if (!ports || !ports.length) {
      apiProcess(getEBDJson, libraryId).then(res => {
        if (res) {
          const _ports = ebdHelper.setEBDJson(libraryId, res.ebdParse)
          resolve({ libraryId, ports: _ports })
        }
      }, error => {
        console.error(error)
        resolve({ libraryId, ports: [] });
      })
    } else {
      resolve({ libraryId, ports });
    }
  })
}

export function getEBDRankList(libraryId) {
  if (libraryId) {
    return ebdHelper.getEBDRankList(libraryId);
  } else {
    return [];
  }
}

export function rockyViaTraceCopyPromise(libraryId, name) {
  return apiProcess(rockyViaTraceCopy, { libraryId, name });
}

/**
 * library rename
 * @param {string} id libraryId
 * @param {string} name new library name
 *  */
export function renameLibrary({ id, name }) {
  return apiProcess(rockyLibraryRename, { id, name });
}

/**
 * Get rocky user library folder children
 * 2023/10/24
 * @export
 * @returns Promise
 */
export function getLibraryFolderChildren({ libraryId, libraryType }) {
  return apiProcess(getSPFolderFileList, { libraryId, libraryType, productType: "rocky" });
}

let spFolderModelHelper = null;
export function getFolderFileDetail({ fileName, libraryId }) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve(null);
      return;
    }
    if (!spFolderModelHelper) {
      spFolderModelHelper = new SpFolderModelHelper(libraryId, [fileName]);
    }
    const files = spFolderModelHelper.getModelList(libraryId, [fileName]);
    if (!files || files.length === 0) {
      getSPFolderFileDetail({ fileNames: [fileName], libraryId, productType: "rocky" }).then(res => {
        if (res && res.data && res.data.code === SUCCESS) {
          spFolderModelHelper.parseModelSelector(libraryId, res.data.data);
          const fileList = spFolderModelHelper.getModelList(libraryId, [fileName]);
          const models = fileList && fileList.length ? fileList[0].models || [] : []
          resolve(models);
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        resolve(null);
      })
    } else {
      const models = files.length ? files[0].models || [] : []
      resolve(models);
    }
  })
}

/**
 * get spice file content list.
 * 2019.11.19
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage TX/RX
 * @returns Promise
 */
let spiceCSMCPMModelHelper = null;

export function getCSMCPMSpiceModelList({ libraryId, fileName }) {
  const key = fileName ? `${libraryId}::${fileName}` : libraryId;
  return new Promise((resolve, reject) => {
    if (!spiceCSMCPMModelHelper) {
      spiceCSMCPMModelHelper = new CPMCSMSpice([key]);
    }
    const model = spiceCSMCPMModelHelper.getSpiceModelList(key);
    if (!model || !Object.keys(model).length) {
      getRockyLibraryFile(libraryId, fileName).then((response) => {
        spiceCSMCPMModelHelper.parseSpice(key, response.data);
        const model = spiceCSMCPMModelHelper.getSpiceModelList(key);
        resolve({ model, libraryId, fileName });
      }, error => {
        console.error(error)
        resolve({ model, libraryId, fileName });
      })
    } else {
      resolve({ model, libraryId, fileName });
    }
  });
}

export function getDefaultDecap() {
  return apiProcess(getDefaultLibrary);
}

export function setDefaultDecap({ libraryId, libraryType, name, product }) {
  return apiProcess(updateDefaultLibrary, { libraryId, libraryType, name, product });
}

export function getRockyLibraryByIdPromise({ libraryId }) {
  return apiProcess(getRockyLibraryById, { libraryId }, false, false, true);
}

export function getRockyIBISFolderFileDetail({ libraryId, productType = "ROCKY" }) {
  return apiProcess(getIBISFolderFileDetail, { libraryId, productType });
}

export function getLibraryByIdPromise({ libraryId }) {
  return apiProcess(getRockyLibraryById, { libraryId }, false, true, true);
}