import React, { Component } from 'react';
import { Progress, Row, Col, Tooltip } from 'antd';
import {
  getSimulationStatus,
  NO_SIMULATION,
  SIMULATION_RUNNING,
  SIMULATION_WAITING,
} from '@/services/workflow/workflowHelper';
import { TitleRender } from '../TitleRender';
class SimulationHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hoverOption: null
    };
  }

  getWaitSpan = () => {
    const { topWidth } = this.props;
    if (topWidth <= 528 && topWidth >= 438) {
      return 14;
    } else if (topWidth < 438) {
      return 16;
    }
    return 10;
  }

  displayTooltip = (hoverOption) => {
    this.setState({ hoverOption });
  }

  render() {
    const {
      topWidth,
      projectName,
      subName,
      subType,
      verificationName,
      waitingIndex,
      waitingTime,
      verifications = [],
      progress,
      cancelProgress,
      verificationType,
      selectedKey,
      monitorMenu,
      displayMonitorList,
      changeMonitorMenu,
      changeVerification,
      idEndToEndChildren,
      channelName
    } = this.props;
    const status = getSimulationStatus({ waitingIndex, progress });
    const waitSpan = this.getWaitSpan();
    return <div className='aurora-simulation-title' id="aurora-simulation-title-id">
      <Row className='aurora-simulation-holygrail' span={24} style={{ width: topWidth - 36 }}>
        {TitleRender({
          topWidth,
          projectName,
          subName,
          subType,
          verificationName,
          waitingIndex,
          verifications,
          verificationType,
          progress,
          changeVerification,
          selectedKey,
          monitorMenu,
          displayMonitorList,
          changeMonitorMenu,
          hoverOption: this.state.hoverOption,
          displayTooltip: this.displayTooltip,
          channelName
        })}
        {status === SIMULATION_RUNNING ? ProgressRender({ topWidth, progress }) : null}
        {status === SIMULATION_WAITING ? WaitingRender({ topWidth, waitSpan, waitingIndex, waitingTime }) : null}
      </Row>
      {(status !== NO_SIMULATION && !idEndToEndChildren) ? CancelButton({ status, cancelProgress }) : null}
    </div>
  }
}

export default SimulationHeader;

function ProgressRender({ topWidth, progress }) {
  return <div className='aurora-simulation-middle'>
    <Col className='aurora-simulation-progress' span={22}>
      <Progress
        size={{ height: 14 }}
        strokeColor={'#1890ff'}
        percent={progress}
        className="simulation-progress-bar"
      />
    </Col>
  </div>
}

function WaitingRender({ topWidth, waitSpan, waitingIndex, waitingTime }) {
  return <Col className='aurora-simulation-middle' span={waitSpan}>
    {topWidth > 717 ? <span className='simulation-waiting-title'> Simulation waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>
      : <span className='simulation-waiting-title'>Waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
  </Col>
}

function CancelButton({ status, cancelProgress }) {
  return <div className='aurora-simulation-btn'>
    <div className='aurora-cancel-button'>
      <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
        <span className='iconfont icon-cancel9' onClick={(e) => cancelProgress(e)}></span>
      </Tooltip>
    </div>
  </div>
}