function getConnectionIdsByPCBList(
  pcbConnections
) {
  let list = [];
  for (let item of pcbConnections) {
    if (!list.includes(item.prevConnectionId) && item.prevConnectionId) {
      list.push(item.prevConnectionId)

    }
    if (!list.includes(item.nextConnectionId) && item.nextConnectionId) {
      list.push(item.nextConnectionId)
    }
  }

  return list;
}


function getMaxWidthByPCBName(connection) {
  let width = 300;
  const pcb1Name = connection.channel1.designName, channel1Name = connection.channel1.channelName,
    pcb2Name = connection.channel2.designName, channel2Name = connection.channel2.channelName;

  const getPCBNameLength = (pcbName, channelName) => {
    let length = 0;

    if (pcbName) {
      length = length + pcbName.length;
    }

    if (channelName) {
      length = length + channelName.length;
    }

    return length;
  }

  const pcb1Length = getPCBNameLength(pcb1Name, channel1Name), pcb2Length = getPCBNameLength(pcb2Name, channel2Name);

  const length = pcb1Length > pcb2Length ? pcb1Length : pcb2Length;
  width = length * 9;
  return width;
}


/**
 * clear connection ports by port type (connector,cable)
 * @param {Array} connectorPins connector component pins 
 * [{ 
 * pin,
 * port, modelId, 
 * external_map:{
 *  port, modelId,
 *  cablePort, cableModelId
 *   }
 * }]
 * @param {String} type port type "connector" / "cable"
 * */
function clearPortsInConnectorPins(connectorPins, type, modelId) {
  connectorPins.forEach(item => {
    if (type === "connector") {
      if (item.modelId === modelId) {
        item.port = "";
        item.modelId = "";
        item.modelKey = "";
      }

      if (item.external_map && item.external_map.modelId === modelId) {
        item.external_map = {
          ...item.external_map,
          port: "",
          modelId: "",
          modelKey: "",
        };
      }
    }

    if (type === "cable") {
      if (item.external_map && modelId === item.external_map.cableModelId) {
        item.external_map = {
          ...item.external_map,
          cablePort: "",
          cableModelId: "",
          cableModelKey: ""
        };
      }
    }
  })
  return connectorPins;
}

/**
 * get connector setup select components list
 * filter already selected components
 *  */
function getConnSelectComps({ channel1Comps, channel2Comps, pcbConnections, connections, CONNECTION_ID }) {
  const nextPCB = pcbConnections.find(item => item.nextConnectionId === CONNECTION_ID) || {};
  const prevPCB = pcbConnections.find(item => item.prevConnectionId === CONNECTION_ID) || {};

  if (channel1Comps) {
    const prevConn = connections.find(item => item.CONNECTION_ID === nextPCB.prevConnectionId);
    if (!prevConn || !prevConn.connection || !prevConn.connection.connector2) {
      return channel1Comps;
    }
    return channel1Comps.filter(item => item.name !== prevConn.connection.connector2.component);
  }

  if (channel2Comps) {
    const nextConn = connections.find(item => item.CONNECTION_ID === prevPCB.nextConnectionId);
    if (!nextConn || !nextConn.connection || !nextConn.connection.connector1) {
      return channel2Comps;
    }
    return channel2Comps.filter(item => item.name !== nextConn.connection.connector1.component);
  }

  return channel1Comps ? channel1Comps : channel2Comps;
}

function Connector({
  component = "",
  models = [],
  pins = []
}) {
  this.component = component;
  this.models = models;
  this.pins = pins;
}


function ConnectorPin({
  pin = "",
  port = "",
  modelId = "",
  modelKey = "",
  pinMap = false,
  pin_map = "",
  externalMap = false,
  external_map = {}
}) {
  this.pin = pin;

  if (port) {
    this.port = port;
    this.modelId = modelId;
    this.modelKey = modelKey;
  }

  if (pinMap) {
    this.pin_map = pin_map;
  }

  if (externalMap) {
    this.external_map = external_map;
  }
}

function SignalConnectionMap({
  name = "",
  channel1_signal = "",
  channel2_signal = ""
}) {
  this.name = name;
  this.channel1_signal = channel1_signal;
  this.channel2_signal = channel2_signal;
}

export {
  getConnectionIdsByPCBList,
  getMaxWidthByPCBName,
  clearPortsInConnectorPins,
  getConnSelectComps,
  Connector,
  ConnectorPin,
  SignalConnectionMap
}

export {
  getSignalConnectionsMap,
  getDisplayPinList,
  addConnectorPinsByComp,
  deleteConnectorComp,
  getConnectorChannelIndex,
  updateConnectorPins,
  getConnFileList,
  getChannelSetupContent,
  getConnectorModels,
  getPortsSelectFiles,
  getModelListLength,
  getSignalPinTypeByNet,
  getNetType,
  netToPinType
} from './connectionModelHelper';

export {
  getDisplayPortList,
  updateConnectorPinPort,
  getPortSelectFile,
  getPortByType,
  getSelectedPorts,
  updateConnectionPinMap
} from './connectorPortHelper';

export {
  getSignalSelectedFile,
  getConnectorModelsByType,
  getNewDisplayPortList,
  updateConnectorModel,
  updateCableModel,
  getSelectedSignalsBySelectFile,
  getPinPortInfo,
  clearPortsInConnectorPinsByModel,
  clearPinsPortByFile,
  clearPinsPortBySignal,
  copyPinsPort,
  copyPinPortsBySignal,
  updateCopyGroupsBySelectFile,
  getModifiedPinsBySignals,
  updateCopyGroupsBySignal,
  getPinTypeDisplay,
  getCopyGroups,
  getApplySignals,
  getPinNetWidth,
  deleteUnUsedCableModels,
  updateConnectorAllPinsPort,
  getConnSignalType,
  deleteUnUsedConnectorModels,
  getChannelSelectedSignalsBySelectFile,
  autoMatchPort,
  getAutoMatchFile
} from './connectorPortSelectHelper';