import { PRE_LAYOUT } from "../../../constants/designVendor";
import designConstructor from "../../helper/designConstructor";
import { getCentricContentPromise } from "../rockySSNCtrl";

function SSNValue({ id, channelId, designId, userId, verificationId }) {
  this.id = verificationId;
  this.currentId = id;
  this.subId = id;
  this.channelId = channelId;
  this.designId = designId;
  this.name = "SSN";
  this.userId = userId;
  this.verificationId = verificationId;
}
class SSNVerifications {
  constructor() {
    this.verifications = new Map(); // key - channelId, value - verifications
  };

  setInfo = (id, verificationData) => {
    this.verifications.set(id, verificationData)
  }

  getCurrentInterfaceInfo = (interfaceId) => {
    let keys = this.verifications.keys();
    for (let key of keys) {
      const info = this.verifications.get(key);
      const ssn = info && info.ssn && info.ssn.length ? info.ssn : [];
      const findInfo = ssn.find(item => item.id === interfaceId)
      if (findInfo) {
        return findInfo
      }
    }
    return null;
  }

  getCurrentSSNInfo = (interfaceId) => {
    let keys = this.verifications.keys();
    for (let key of keys) {
      const info = this.verifications.get(key);
      const ssn = info && info.ssn && info.ssn.length ? info.ssn : [];
      const findInfo = ssn.find(item => item.id === interfaceId)
      if (findInfo) {
        return info
      }
    }
    return null;
  }

  getInfo = (channelId) => {
    return this.verifications.get(channelId) || {};
  }

  getInterfaceList = (channelId) => {
    const value = this.verifications.get(channelId);
    const ssn = value && value.ssn && value.ssn.length ? value.ssn : [];
    return ssn;
  }

  getSSNInterface = (channelId) => {
    const value = this.verifications.get(channelId);
    const SSNInfo = new SSNValue({ ...value });
    return SSNInfo
  }

  getSSNInterfaceList = (channelId) => {
    const value = this.verifications.get(channelId);
    const SSNInfo = new SSNValue({ ...value });
    const ssn = value && value.ssn && value.ssn.length ? value.ssn : [];
    const newSSNList = [...ssn, SSNInfo];
    return newSSNList
  }

  getSSNVerificationId = (channelId) => {
    const value = this.verifications.get(channelId);
    return value && value.verificationId ? value.verificationId : "";
  }

  getVerifications = (channelId, isNew) => {
    return new Promise((resolve, reject) => {
      const info = this.verifications.get(channelId);
      if (info && !isNew) {
        resolve(JSON.parse(JSON.stringify(info.ssn)));
      } else {
        getCentricContentPromise(channelId).then(res => {
          if (res) {
            this.setInfo(channelId, { ...res, channelId })
            resolve(res.ssn);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  getPromiseInfo = (channelId, isNew) => {
    return new Promise((resolve, reject) => {
      const info = this.verifications.get(channelId);
      if (info && !isNew) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getCentricContentPromise(channelId).then(res => {
          if (res) {
            this.setInfo(channelId, { ...res, channelId })
            resolve(res)
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  getMergeInterfaceInfo = (channelId) => {
    const info = this.verifications.get(channelId);
    const interfaceList = info && info.ssn ? info.ssn : [];
    const packageLayout = interfaceList && interfaceList.length ? interfaceList[0].soc : null;
    const packageId = packageLayout && packageLayout.pkg ? packageLayout.pkg.packageId : null;
    const pkg = designConstructor.getDesign(packageId) || {};
    const pcb = designConstructor.getDesign(info.designId) || {};
    if (pkg.vendor === PRE_LAYOUT || pcb.vendor === PRE_LAYOUT) {
      return false;
    }
    return info;
  }

  getIsMergeInterface = (channelId) => {
    const info = this.verifications.get(channelId);
    const interfaceList = info && info.ssn ? info.ssn : [];
  }

  getMergeVerificationId = async (channelId) => {
    const info = await this.getPromiseInfo(channelId);
    return info && info.mergeVerificationId ? info.mergeVerificationId : null;
  }

  getChannelVerifications = async (verifications) => {
    let verificationList = [];
    for (let verification of verifications) {
      const list = await this.getVerifications(verification.id)
      verificationList = [...verificationList, ...list];
    }
    return verificationList;
  }

  clean() {
    this.verifications = new Map();
  }
};

const RockySSNChannelInfo = new SSNVerifications();
export default RockySSNChannelInfo; 