import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import DesignTree from './tree';
import './index.css';

class DesignTreeContent extends Component {

  render() {
    const { loading, powerTrees, treeTip, treeLoading } = this.props;
    return <div className='cascade-design-tree-content'>
      {
        powerTrees.length ? powerTrees.map((item, index) => {
          return item && treeLoading[item.id] !== 'hidden' ? <Row
            className='space-16'
            key={item.id}
            style={index === 0 ? { marginTop: 0 }
              : index === (powerTrees.length - 1) ? { paddingBottom: 16 }
                : {}}
          >
            <Col span={24} className='cascade-setup-border'>
              <DesignTree data={item} key={item.id} loading={loading} number={powerTrees.length} />
            </Col>
          </Row> : null
        }) :
          <div className='design-tree-spin'>
            <Spin spinning={loading} tip={treeTip || 'Loading PCB...'}>
              <div></div>
            </Spin>
          </div>
      }
      <div id="design-tree-content-dialog"></div>
    </div>
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { designStatus, treeItems },
    DesignTree: { verificationId, loading, updateCompPrefix, powerTrees, treeTip, treeLoading }
  } } = state

  return {
    designStatus,
    treeItems,
    verificationId,
    loading,
    updateCompPrefix,
    powerTrees,
    treeTip,
    treeLoading
  };
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(DesignTreeContent);