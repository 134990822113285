import { call, put, select, takeEvery } from 'redux-saga/effects';
import { GET_SSN_RESULT_CONTENT, UPDATE_SELECT_VERIFICATION } from './actionType';
import rockyChannels from '@/services/Rocky/helper/channels';
import SSNResultVerificationInfo from '@/services/Rocky/result/ssnVerificationInfo';
import { openResultPageInfo, updateResultExists } from '../action';
import RockySSNChannelInfo from '../../../../../services/Rocky/SSN/channelsInfo';
import { changeTabMenu, openTabFooter } from '../../../../MonitorStore/action';
import { getInterfaceMonitor } from '../../simulation/action';
import { VERIFY_RUNNING } from '../../../../../constants/verificationStatus';
import { SSN_RESULT } from '../../../../../constants/treeConstants';


function* getSSNResultContent(action) {
  const { id: channelId } = action;
  try {
    SSNResultVerificationInfo.cleanVerificationInfo();
    // At this point, it must be a single pattern
    // Obtain which cases have been run before
    let info = yield call(RockySSNChannelInfo.getInfo, channelId);
    if (info && info.id) {
      const runList = yield call(SSNResultVerificationInfo.getResultExists, info.id, true)
      if (runList && runList.length) {
        const selectVerificationId = runList[0].id;
        const _runList = runList.map(item => {
          const type = item.write ? item.write : item.read ? item.read : "";
          const name = type && type === "Merge" ? "Merged" : type;
          return { ...item, displayName: name ? `${item.name} (${name})` : item.name }
        })
        yield call(findVerificationInfo, { channelId, selectVerificationId })
        yield put(updateResultExists(_runList))
        return
      }
    }
    const channelInfo = rockyChannels.get(channelId);
    // Do not display anything when there is none
    yield put(openResultPageInfo({
      verificationId: "",
      verificationSubId: "",
      designSubId: "",
      designID: channelInfo.designId || "",
      interfaces: [],
      channelId,
    }));
  } catch (error) {
    console.error(error)
  }
}

function* findVerificationInfo({ channelId, selectVerificationId }) {
  try {
    const { RockyReducer: { project: { currentProjectId, projectList } } } = yield select();
    const find = projectList.find(item => item.id === currentProjectId);
    let verificationSubId = null, verificationId = null, interfaces = [], status = -1, designID = '', designSubId = "";
    const channelInfo = rockyChannels.get(channelId);
    let dataList = yield call(RockySSNChannelInfo.getVerifications, channelId);
    if (dataList && dataList.length) {
      let index = 0;
      if (selectVerificationId) {
        index = dataList.findIndex(item => item.id === selectVerificationId)
      }
      if (index < 0) { index = 0 }
      const findInfo = dataList[index];
      status = findInfo ? findInfo.status : -1;
      verificationSubId = findInfo.subId;
      verificationId = findInfo.id;
      designID = findInfo.designId;
      designSubId = findInfo.designSubId;
      if (findInfo.name !== "SSN") {
        const content = yield call(SSNResultVerificationInfo.getCurrentVerificationInfo, { verificationId, channelId, verificationSubId });
        if (content && content.Interfaces) {
          interfaces = content.Interfaces.map(item => { return { ...item, name: item.Name, subId: verificationSubId, id: item.interfaceId } })
          designSubId = interfaces[0].pcbSubId;
        }
      }

      if (status === VERIFY_RUNNING) {
        yield put(openTabFooter())
      }
      yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], projectId: currentProjectId, verificationName: findInfo ? findInfo.name : null, currentVerificationId: findInfo.id, dataType: SSN_RESULT }))
      yield put(getInterfaceMonitor(findInfo.id, null, true));

      yield put(openResultPageInfo({
        verificationId,
        verificationSubId,
        designSubId: designSubId,
        designID,
        interfaces,
        channelId,
        channelName: channelInfo && channelInfo.name ? channelInfo.name : null,
        ddrType: find.type,
        verificationName: /* findInfo.name === "SSN" ? "PDN" :  */findInfo.name
      }));
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateSelectVerification(action) {
  const { id } = action;
  const { RockyReducer: { result: { resultInfo: { channelId } } } } = yield select();
  yield call(findVerificationInfo, { channelId, selectVerificationId: id })
}

function* rockySSNResultSaga() {
  yield takeEvery(GET_SSN_RESULT_CONTENT, getSSNResultContent);
  yield takeEvery(UPDATE_SELECT_VERIFICATION, updateSelectVerification);
}
export default rockySSNResultSaga;