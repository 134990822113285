import CeGeomTools from './CeGeomTools';
import CeGeometry from './CeGeometry';
import CeCircle from './CeCircle';
import CeVertex from './CeVertex';
import CeVertexArc from './CeVertexArc';
import GCPolygon from './GCPolygon';
import StringList from '../utility/StringList';
import StringHelper from '../utility/StringHelper';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeLarc = function () {
  CeGeometry.call(this, "Larc");
}

// subclass extends superclass
CeLarc.prototype = Object.create(CeGeometry.prototype);
CeLarc.prototype.constructor = CeLarc;

CeLarc.prototype.SetData = function (xs, ys, xe, ye, xc, yc, ccw) {
  this.mCCW = ccw;
  this.mStart = new CeVertex();
  this.mStart.SetData(xs, ys);
  this.mEnd = new CeVertex();
  this.mEnd.SetData(xe, ye);
  this.mCenter = new CeVertex();
  this.mCenter.SetData(xc, yc);
}

CeLarc.prototype.CopyFrom = function (fromObj) {
  this.mCCW = fromObj.mCCW;
  this.mStart = fromObj.mStart.Clone();
  this.mEnd = fromObj.mEnd.Clone();
  this.mCenter = fromObj.mCenter.Clone();
  CeGeometry.prototype.CopyFrom.call(this, fromObj);
}

CeLarc.prototype.Clone = function () {
  var clnObj = new CeLarc();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeLarc.prototype.Scaling = function (scaleFactor) {
  this.mStart.Multiply(scaleFactor);
  this.mEnd.Multiply(scaleFactor);
  this.mCenter.Multiply(scaleFactor);
}

CeLarc.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mStart.GetDataString());
  defData.push(this.mEnd.GetDataString());
  defData.push(this.mCenter.GetDataString());
  defData.push(StringHelper.BoolToString(this.mCCW));
  return defData;
}

CeLarc.prototype.UpdateByString = function (dataVals) {
  // startX, startY, endX, endY, centerX, centerY, true(ccw)
  if (!(dataVals instanceof StringList) || dataVals.size() != 7)
    return false;

  var startVals = new StringList();
  startVals.push(dataVals.get(0));
  startVals.push(dataVals.get(1));
  this.mStart = new CeVertex();
  this.mStart.UpdateByString(startVals);

  var endVals = new StringList();
  endVals.push(dataVals.get(2));
  endVals.push(dataVals.get(3));
  this.mEnd = new CeVertex();
  this.mEnd.UpdateByString(endVals);

  var centerVals = new StringList();
  centerVals.push(dataVals.get(4));
  centerVals.push(dataVals.get(5));
  this.mCenter = new CeVertex();
  this.mCenter.UpdateByString(centerVals);

  this.mCCW = StringHelper.StringToBool(dataVals.get(6));

  return true;
}

CeLarc.prototype.WriteIntoIODataNode = function () {
  return new CeIODataItem(this.GetGeomType(), this.GetDataString());
}

CeLarc.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeLarc.prototype.equals = function (obj) {
  if (!(obj instanceof CeLarc))
    return false;

  if (this.mCCW != obj.mCCW || this.mStart.equals(obj.mStart) == false ||
    this.mEnd.equals(obj.mEnd) == false ||
    this.mCenter.equals(obj.mCenter) == false)
    return false;

  return true;
}

CeLarc.prototype.GetRadius = function () {
  return this.mStart.ToPointDistance(this.mCenter);
}

CeLarc.prototype.GetStartAngle = function () {
  return CeGeomTools.PointToXaxisAngle(this.mStart.mX - this.mCenter.mX,
    this.mStart.mY - this.mCenter.mY);
}

CeLarc.prototype.GetEndAngle = function () {

  return CeGeomTools.PointToXaxisAngle(this.mEnd.mX - this.mCenter.mX,
    this.mEnd.mY - this.mCenter.mY);
}

CeLarc.prototype.GetExtent = function () {
  if (this.mCCW) {
    return this.GetEndAngle() - this.GetStartAngle();
  } else {
    return Math.PI * 2 - Math.abs((this.GetEndAngle() - this.GetStartAngle()));
  }
}

CeLarc.prototype.GetLargeArcFlag = function () {
  var angle = this.GetStartAngle() - this.GetEndAngle();
  var arcAngle;
  if (angle === 0) {
    return 1;
  } else if (angle > 0) {
    arcAngle = this.mCCW ? (2 * Math.PI - angle) : angle;
  } else {
    arcAngle = this.mCCW ? (-angle) : (2 * Math.PI + angle);
  }

  return arcAngle > Math.PI ? 1 : 0;
}

CeLarc.prototype.GetConvetArcs = function (radius) {
  var ratio = radius / this.GetRadius();
  var vertex = new CeVertex();
  vertex.SetData((this.mStart.mX - this.mCenter.mX) * ratio, (this.mStart.mY - this.mCenter.mY) * ratio);
  var inner = {},
    outer = {};
  inner.r = this.GetRadius() - radius;
  outer.r = this.GetRadius() + radius;

  inner.start = new CeVertex();
  inner.start.SetData(this.mStart.mX - vertex.mX, this.mStart.mY - vertex.mY);
  outer.start = new CeVertex();
  outer.start.SetData(this.mStart.mX + vertex.mX, this.mStart.mY + vertex.mY);

  vertex.SetData((this.mEnd.mX - this.mCenter.mX) * ratio, (this.mEnd.mY - this.mCenter.mY) * ratio);
  inner.end = new CeVertex();
  inner.end.SetData(this.mEnd.mX - vertex.mX, this.mEnd.mY - vertex.mY);
  outer.end = new CeVertex();
  outer.end.SetData(this.mEnd.mX + vertex.mX, this.mEnd.mY + vertex.mY);

  return [inner, outer];
}

CeLarc.prototype.GetCenter = function () {
  return this.mCenter;
}

export default CeLarc;