import {
  UPDATE_EXPERIMENT_INFO,
  UPDATE_SWEEP_CREATE_STATUS,
  UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO,
  UPDATE_SWEEP_MEASUREMENT_DISPLAY_INFO
} from './actionTypes';

const defaultState = {
  experimentInfo: {
    experiments: [],
    id: "",
    sweepName: "",
    verificationName: "",
    forSim: [],
    tableColumns: [],
    hasSchematic: false
  },
  schematicPreLayoutInfo: null,
  sweepMeasurementInfo: null
}

export const SweepReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_EXPERIMENT_INFO:
      return {
        ...state,
        experimentInfo: action.currentType === 'all' ? {
          ...state.experimentInfo,
          ...action.info
        } : {
          ...state.experimentInfo,
          [action.currentType]: action.info
        }
      }
    case UPDATE_SWEEP_CREATE_STATUS:
      return {
        ...state,
        sweepCreateStatus: action.status
      }
    case UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO:
      return {
        ...state,
        schematicPreLayoutInfo: action.schematicPreLayoutInfo
      }
    case UPDATE_SWEEP_MEASUREMENT_DISPLAY_INFO:
      return {
        ...state,
        sweepMeasurementInfo: action.sweepMeasurementInfo
      }
    default: return state
  }
}