import { selectAll } from 'd3-selection';

function drawPlot(param, plot2d) {

  param.showCurves().then((curves) => {
    param.data = curves
    plot2d.updateCurves(curves, true);
    plot2d.setCurveColors();
    plot2d.resetPlot();
    plot2d.updateAxisRange('y', { start: plot2d.options.yMin < 0 ? plot2d.options.yMin : 0, end: plot2d.options.yMax });
    plot2d.redrawCurves();
    selectAll('g.axis--x .tick text').attr("y", '10');
    selectAll('g.axis--x .tick text').attr("transform", 'rotate(-45)');
    selectAll('g.axis--x .tick text').attr("dx", '-1em');
    selectAll('g.tick text').style('font-size', '12');
    selectAll('text.xlabel').style('font-size', '12');
    selectAll('text.xlabel').attr('dy', '3.2em');
    selectAll('text.ylabel').style('font-size', '12');
  })
}

function redrawPlot(plot2d) {
  plot2d.redrawCurves()
  selectAll('g.axis--x .tick text').attr("y", '10');
  selectAll('g.axis--x .tick text').attr("transform", 'rotate(-45)');
  selectAll('g.axis--x .tick text').attr("dx", '-1em');
  selectAll('g.tick text').style('font-size', '12');
  selectAll('text.xlabel').style('font-size', '12');
  selectAll('text.xlabel').attr('dy', '3.2em');
  selectAll('text.ylabel').style('font-size', '12');
}

export {
  drawPlot,
  redrawPlot
}