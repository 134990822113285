import { BGA, DIE } from '../../constants/componentType';
import { PACKAGE, PCB } from '../../constants/treeConstants';
import { MEMORY } from '../PCBHelper';
import { CONTROLLER } from '../Rocky/preLayout/preLayoutConfig';
import { arraySort } from './arrayHelper';
import { getDDRSignalType, getNum } from './getSignalType';

class TouchstoneHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getTouchstoneFiles() {
    return Object.keys(this);
  }

  deleteModel(id) {
    delete this[id];
  }

  getTouchstonePorts(id) {
    const ports = this[id] ? this[id] : null;
    return ports;
  }

  parseTouchstonePorts(id, fileContent, fileName) {
    let ports = [], portNumber = getPortNumberFromFileSuffix(fileName);
    const lines = fileContent ? fileContent.match(/[^\r\n]+/g) : [];
    let inBLock = false, portLength = 0, portObj = {};

    if (lines && lines.length > 0) {
      for (const line of lines) {
        const words = line.match(/[^\s\t]+/g);

        if (!words[0] || words[0].indexOf("!") === -1) {
          inBLock = false;
          continue;
        }
        //filter line
        //find port list
        //line: !Port 2 BG1_AL5::DDR_A13_0[0]
        //line: !P_20_BG1_AL5::DDR_A13_0[0]
        //line:  ! Port20_BG1_AL5::DDR_A13_0[0]
        //line:  ! Port1_BEV_CHIP_L1_BUMPS_11132019FO1_TOP__DD_DDR_A13_0_0__0::DDR_A13_0[0]
        //line:  ! Port[34] = Bump_EMI1_DQS1_T_524_T1
        //line:  ! Port[35] = BGA_EMI1_CA0_E15_T1
        /*  const portReg = /(Port)|(P)/ig;
        if (!words[0].match(portReg) && (!words[1] || !words[1].match(portReg))) {
          continue;
        } */
        const portReg = /(^!Port)|(^!P(?<![a-zA-Z]))/ig;
        const _portReg = /(^Port)|(^P(?<![a-zA-Z]))/ig;

        if (!words[0].match(portReg) && (!words[1] || !words[1].match(_portReg))) {
          inBLock = false;
          continue;
        }

        //words[0]: ! / !P_1 / !Port[2]
        //words[1]:  Port[34] / 2
        //words[1]:  Port20_BG1_AL5::DDR_A13_0[0]
        //words[1]:  Port1_BEV_CHIP_L1_BUMPS_11132019FO1_TOP__DD_DDR_A13_0_0__0::DDR_A13_0[0]
        //get port number
        const port = getPortNumber(words);

        if (!port) {
          inBLock = false;
          continue;
        }

        if (!inBLock) {
          inBLock = true;
          portLength += 1;
          portObj[`port${portLength}`] = [];
        }

        const _newWords = words[1] ? words[1].split("_") : [];
        //words[1]: [ "Port[34]" ]
        //_newWords: ["Port20", "BG1", "AL5::DDR", "A13", "0[0]"]
        //_newWords: ["Port1", "BEV", "CHIP", "L1", "BUMPS", "11132019FO1", "TOP", "", "DD", "DDR", "A13", "0", "0", "", "0::DDR", "A13", "0[0]"]

        //portInfoList:  Port20_BG1_AL5::DDR_A13_0[0]
        //portInfoList:  [ "Port1_BEV_CHIP_L1_BUMPS_11132019FO1_TOP__DD_DDR_A13_0_0__0::DDR_A13_0[0]" ]
        //portInfoList:  [ "Port[34]", "=", "Bump_EMI1_DQS1_T_524_T1" ]
        //portInfoList:  [ "Port[35]", "=", "BGA_EMI1_CA0_E15_T1" ]
        let portInfoList = words.filter((it, index) => index !== 0);
        const portInfo = portInfoList.join(" ");

        //get net and pin info
        //infoList: ["BG1", "AL5::DDR", "A13", "0[0]"]
        const infoList = _newWords.filter((it, index) => index !== 0);
        //pinNetInfo: "BG1_AL5::DDR_A13_0[0]"
        const pinNetInfo = infoList.join("_");
        //_info: [ "BG1_AL5" , "DDR_A13_0[0" ]
        const _info = pinNetInfo.split("::");

        //TODO signal name list temporarily not used
        /*   //pinInfo: "BG1_AL5"
          const pinInfo = _info[0] ? _info[0] : "";
          //pinNameList: ["BGA", "AL5"]
          const pinNameList = pinInfo.split(/[^a-zA-Z0-9]/);//[^a-zA-Z0-9] */

        //netName: "DDR_A13_0[0]"
        const netName = _info[1] ? _info[1] : "";

        //TODO signal name list temporarily not used
        /*   const findNetReg = /[a-zA-Z]+\d+/gi;
          //netNameArr: [ "A13" ]
          //netName === "DDR3_A13_0[0]" ==> signalNameArr= [ "DDR3", "A13" ]
          //netName === "DDR3_A13_BG1" ==> signalNameArr= [ "DDR3", "A13", "BG1" ]
          const signalNameArr = netName.match(findNetReg) ? netName.match(findNetReg) : null;
          //signalName: "A13"
          const signalNameList = signalNameArr ? signalNameArr : [netName]; */
        //judge port exist
        //filter port ,if current port number exist,cover;
        const findPortIndex = portObj[`port${portLength}`].findIndex(it => it.port === port);
        if (findPortIndex > -1) {
          portObj[`port${portLength}`][findPortIndex] = {
            port,
            netName,
            info: portInfo ? portInfo : (words[1] ? words[1] : "")
          }
        } else {
          portObj[`port${portLength}`].push({
            port,
            /*  pinInfo, */
            netName,
            /*  signalNameList, */
            /* pinName: pinNameList ? pinNameList[pinNameList.length - 1] : pinInfo, */
            info: portInfo ? portInfo : (words[1] ? words[1] : "")
          })
        }
      }
    }

    //find ports length equal to port number 
    for (let key of Object.keys(portObj)) {
      if (portObj[key].length !== parseInt(portNumber)) {
        continue;
      }
      ports = portObj[key];
    }

    ports = reSetPorts(ports);

    if (ports.length !== parseInt(portNumber)) {
      ports = updatePortsByPortNumber(portObj, portNumber);
    }

    this[id] = ports;
    return ports;
  }
}

function reSetPorts(ports) {
  //sort ports by port
  ports = ports.sort((a, b) => {
    return parseInt(a.port) - parseInt(b.port);
  });

  //re set port number
  ports.forEach((item, index) => { item.port = (index + 1).toString(); });
  return ports;
}

function updatePortsByPortNumber(portObj, portNumber) {
  //Find the Ports whose Ports length is closest to the port number
  const sortPorts = Object.keys(portObj).map(key => {
    return {
      num: Math.abs(parseInt(portNumber) - portObj[key].length),
      ports: portObj[key]
    }
  }).sort((a, b) => a.num - b.num);

  let ports = sortPorts.length ? sortPorts[0].ports : [];
  //if ports or ports length less than port number, update ports
  if (!ports.length || ports.length < parseInt(portNumber)) {
    ports = reSetPorts(ports);
    for (let i = 1; i <= parseInt(portNumber); i++) {
      const findPort = ports.find(item => parseInt(item.port) === i);
      if (findPort) {
        continue;
      }
      ports.push({
        port: i.toString(),
        info: "",
        netName: ""
      })
    }
    return ports;
  }

  //if ports length more than port number,removed ports
  if (ports.length > parseInt(portNumber)) {
    ports = ports.filter(item => parseInt(item.port) <= parseInt(portNumber));
  }

  return ports;
}

//get port number from string
//words ["!", "Port", "1", ... ] / ["!", "Port[2]", ... ] / ["!Port_3", ... ]
//portNumber -> max port number, s68p => 68
function getPortNumber(words, portNumber) {
  let portNum = null;
  for (let i = 0; i < words.length; i++) {
    if (i > 2) {
      break;
    }
    const num = getNum(words[i]);
    if (num !== null && (!portNumber || (0 < Number(num) && Number(num) <= Number(portNumber)))) {
      portNum = num;
      break;
    }
  }
  return portNum;
}

//get max port number from file suffix
function getPortNumberFromFileSuffix(fileName) {
  if (!fileName) {
    return null;
  }
  const pattern = new RegExp("s([0-9]+)p", "ig");
  const fileSuffix = fileName.match(pattern);
  let num = fileSuffix ? getNum(fileSuffix[0]) : null;
  return num;
}

function autoSelectTouchstonePorts({ pairs, libraryId, pinList, ports, rank, modelKey }) {
  let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];
  let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];
  let _ports = ports ? JSON.parse(JSON.stringify(ports)) : [];

  let newPorts = [];
  for (let port of _ports) {
    if (!_pairs.find(pair => pair.libraryId === libraryId && port.port === pair.node)) {
      newPorts.push(port)
    }
  }
  _ports = newPorts;

  let selectedPorts = [];

  const nets = _pinList.map(item => item.net);
  //netList sort by net length
  const netList = arraySort(nets);

  for (let i = 0; i < netList.length; i++) {
    const net = netList[i];
    const index = _pinList.findIndex(it => it.net === net);
    if (index < 0) {
      continue;
    }
    let item = _pinList[index]; //{ pinRight, pinLeft, net, signal }

    const pins = item.pinRight;
    const pinDies = item.pinLeft;
    const signal = item.signal;

    //When adding a modelKey, it is necessary to check whether other ports have been selected (modelKey)
    //Need to use the same modelKey as his peers' ports
    const findModelKey = [...pins, ...pinDies].find(item => item.pinModelKey && item.pinLibraryId === libraryId)
    let _modelKey = findModelKey && findModelKey.pinModelKey ? findModelKey.pinModelKey : modelKey;
    let info = findPortsByGroup({ pins, net, ports: _ports, signal, selectedPorts, pinType: 'R', pairs: _pairs, pinList: _pinList, libraryId, index, modelKey: _modelKey });
    _pairs = info._pairs ? info._pairs : _pairs;
    _pinList = info._pinList ? info._pinList : _pinList;
    _ports = info._ports ? info._ports : _ports;
    selectedPorts = info._selectedPorts ? info._selectedPorts : selectedPorts;
    info = findPortsByGroup({ pins: pinDies, net, ports: _ports, signal, selectedPorts, pinType: 'L', pairs: _pairs, pinList: _pinList, libraryId, index, rank, netNames: info.netNames, modelKey: _modelKey });
    _pairs = info._pairs ? info._pairs : _pairs;
    _pinList = info._pinList ? info._pinList : _pinList;
    _ports = info._ports ? info._ports : _ports;
    selectedPorts = info._selectedPorts ? info._selectedPorts : selectedPorts;
  }

  return { pairs: _pairs, pinList: _pinList, selectedPorts }
}

function findPortsByGroup({ pins, net, ports, signal, selectedPorts, pinType, pairs, pinList, libraryId, index, rank, netNames = [], modelKey }) {
  let _pairs = [...pairs], _pinList = [...pinList], _selectedPorts = [...selectedPorts], _ports = [...ports], _netNames = [];
  const isDie = pinType === 'L' ? 'pinLeft' : 'pinRight';
  for (let pin of pins) {
    let info = findPortNode({ pin, net, _ports, signal, selectedPorts: _selectedPorts, pinType, rank, netNames, libraryId });
    if (info && info.pinNode) {
      _selectedPorts = info.selectedPorts;
      _ports = info._ports;
      const pairIndex = _pairs.findIndex(pair => pair.pin === pin.pin);
      _pairs[pairIndex].node = info.pinNode;
      _pairs[pairIndex].libraryId = libraryId;
      if (modelKey) {
        _pairs[pairIndex].modelKey = modelKey
      }
      const pinIndex = _pinList[index][isDie].findIndex(item => item.pin === pin.pin);
      _pinList[index][isDie][pinIndex].pinValue = info.pinNode;
      _pinList[index][isDie][pinIndex].pinLibraryId = libraryId;

      if (modelKey) {
        _pairs[pairIndex].modelKey = modelKey;
        _pinList[index][isDie][pinIndex].pinModelKey = modelKey;
      }

      if (pinType === 'R' && info.netName) {
        _netNames.push(info.netName)
      }
    }
  }
  return { _pairs, _pinList, _selectedPorts, _ports, netNames: _netNames }
}

function findPortNode({ pin, net, _ports, signal, selectedPorts, pinType, rank, netNames, libraryId, isSSN, signalGroupName }) {
  let pinNode = pin.pinValue;
  if (pinNode) {
    libraryId === pin.pinLibraryId && selectedPorts.push(pinNode);
    return;
  }

  // bga and bump reg
  const BGAReg = new RegExp(`(BGA)|(BG1)|(_A_)`, 'ig');
  const BUMPReg = new RegExp(`BUMPS|BUMP|DIE|_D_`, "ig");

  const controllerReg = new RegExp(`_A_`, 'ig');
  const memoryReg = new RegExp(`_D_`, 'ig');

  const bgaReg = new RegExp(`(BGA)|(BG1)`, 'ig');
  const bumpReg = new RegExp(`BUMPS|BUMP|DIE`, "ig");

  //net reg
  let netArr = [];
  net.split("").forEach(item => {
    if (/[^a-zA-Z0-9]/ig.test(item)) {
      netArr.push(`\\${item}`)
    } else {
      netArr.push(item);
    }
  });

  const netStr = netArr.join("");
  //Matching pin(net/signal) is at the beginning or end, or does not contain numbers or letters before and after.
  const _pin = pin.pin.replace('_u', '');
  const pinReg = new RegExp(`(^|[^a-zA-Z0-9])${_pin}([^a-zA-Z0-9]|$)`, 'ig');
  const netReg = new RegExp(`(^|[^a-zA-Z0-9])${netStr}([^a-zA-Z0-9]|$)`, 'ig');
  //DQSPA -> DQSP, DQS1PB->DQS1P, DQ0A->DQ0
  const _signal = signal.match(/(DQS[0-9]+(P|N)(A|B))|(DQ[0-9]+(A|B))|(DQS(P|N)(A|B))|(DQ(A|B))|(DM(A|B))/ig) ? signal.replace(/A|B/ig, "") : signal;
  const signalReg = new RegExp(`(^|[^a-zA-Z0-9])${_signal}([^a-zA-Z0-9]|$)`, 'ig');

  const signalGroupReg = new RegExp(`(^|[^a-zA-Z0-9])${signalGroupName}([^a-zA-Z0-9]|$)`, 'ig');

  let pinPort = null; // { port, info, ... }

  //find ports by net name and signal 
  let findPorts = _ports.filter(p => p.info && p.info.match(netReg));
  if (findPorts.length) {
    //filter findPorts by BGA and BUMP
    let findNetPorts = findPorts.length ? pinType === 'R' ? findPorts.filter(p => p.info.match(bgaReg) || !p.info.match(bumpReg))
      : findPorts.filter(p => p.info.match(bumpReg) || !p.info.match(bgaReg)) : [];

    pinPort = isSSN ? getPortNotSelect(findNetPorts, selectedPorts) : findNetPorts.length ? findNetPorts[0] : null
  }

  if (pinPort) {
    pinNode = pinPort.port ? pinPort.port : "";
    selectedPorts.push(pinNode);
  }

  if (pinNode) {
    _ports = _ports.filter(port => port.port !== pinNode);
    const _netName = pinPort ? pinPort.netName : '';
    return { _ports, pinNode, selectedPorts, netName: _netName }
  }

  if (!pinNode && pinType === 'R' && !pinPort) {
    //find pin node by signal name and is BGA
    const _filterPorts = _ports.filter(p => p.info.match(BUMPReg) || !p.info.match(bumpReg));
    let pinPorts = findPortBySignalAndNet({ filterPorts: _filterPorts, signalReg, signal, net, pinReg, selectedPorts });

    if (!pinPorts.length) {
      //find pin node by pin Name and is BGA
      pinPorts = _ports.filter(p => p.info.match(pinReg) && (p.info.match(BGAReg) || !p.info.match(BUMPReg)));
    }

    if (signalGroupReg && pinPorts.length > 1) {
      const _pinPorts = pinPorts.filter(p => p.info.match(signalGroupReg));
      if (_pinPorts && _pinPorts.length > 0) {
        pinPorts = _pinPorts;
      }
    }

    if (pinPorts.length > 1) {
      pinPorts = pinPorts.filter(p => !p.info.match(bumpReg))
    }

    let BGAPort = pinPorts ? pinPorts.find(p => p.info.match(bgaReg)) : null;
    if (!BGAPort) {
      BGAPort = pinPorts ? pinPorts.find(p => p.info.match(controllerReg)) : null;
    }

    pinPort = BGAPort ? BGAPort : pinPorts && pinPorts.length ? pinPorts[0] : null;
  } else if (!pinNode && pinType === 'L' && !pinPort) {
    // find pinDie node
    //find pin die node by signal Name and is BGA
    const _filterPorts = _ports.filter(p => p.info.match(BUMPReg) || !p.info.match(bgaReg));
    let pinPorts = findPortBySignalAndNet({ filterPorts: _filterPorts, signalReg, signal, net, pinReg, selectedPorts })

    if (signalGroupReg && pinPorts.length > 1) {
      const _pinPorts = pinPorts.filter(p => p.info.match(signalGroupReg));
      if (_pinPorts && _pinPorts.length > 0) {
        pinPorts = _pinPorts;
      }
    }
    if (!pinPorts.length || pinPorts.length > 2) {
      //find pin die node by pin name and is BUMP
      const filterPorts = _ports.filter(p => p.info.match(pinReg) && (p.info.match(BUMPReg) || !p.info.match(BGAReg)));
      if (filterPorts && filterPorts.length) {
        pinPorts = filterPorts
      }
    }

    if (pinPorts.length) {
      pinPort = getPortNotSelect(pinPorts, selectedPorts)
    }

    if (rank) {
      const pinNameList = pin.pin.split('_');
      const number = Number(pinNameList[pinNameList.length - 1]) ? pinNameList[pinNameList.length - 1] : "1";
      let matchRank = false;
      for (let _p of pinPorts) {
        const _pinPortSplit = _p.info.split(/[_\-=]+/g);
        for (let sp of _pinPortSplit) {
          if (sp === number || sp === `0${number}`) {
            matchRank = true;
            pinPort = _p;
            break;
          }
          if (matchRank) break;
        }
      }
    }

    // find pin die port bt pin port
    if ((!pinPorts.length || !pinPort) && netNames.length) {
      let _pinPorts = _ports.filter(p => netNames.includes(p.netName));
      if (_pinPorts.length > 1) {
        let filterPorts = pinPorts.filter(p => !p.info.match(BGAReg));
        if (!filterPorts) {
          filterPorts = pinPorts.filter(p => !p.info.match(bgaReg))
        }
      }
      pinPort = _pinPorts.length ? _pinPorts[0] : null;
    }
  }

  if (!pinPort) {
    //Find pin port by findPinPorts
    let findPinPorts = _ports.length ? pinType === 'R' ? _ports.filter(p => p.info.match(BGAReg) || !p.info.match(BUMPReg))
      : _ports.filter(p => p.info.match(BUMPReg) || !p.info.match(BGAReg)) : [];

    if (findPinPorts.length > 1 && pinType === 'R') {
      let filterPorts = findPinPorts.filter(item => !item.info.match(bumpReg))
      if (!filterPorts || !filterPorts.length) {
        filterPorts = findPinPorts.filter(item => !item.info.match(memoryReg))
      }
      findPinPorts = filterPorts;
    } else if (findPinPorts.length > 1 && pinType === 'L') {
      let filterPorts = findPinPorts.filter(item => !item.info.match(BGAReg))
      if (!filterPorts || !filterPorts.length) {
        filterPorts = findPinPorts.filter(item => !item.info.match(controllerReg))
      }
      findPinPorts = filterPorts;
    }

    if (findPinPorts.length && findPinPorts.length === 1) {
      pinPort = findPinPorts[0];
    } else if (findPinPorts.length && findPinPorts.length > 1) {
      //filter findPinPorts by pin name
      const _findPinPorts = findPinPorts.filter(p => p.info.match(pinReg));
      // pinPort = _findPinPorts.length === 1 ? _findPinPorts[0] : "";
      if (!pinPort && _findPinPorts.length) {
        pinPort = getPortNotSelect(_findPinPorts, selectedPorts)
      }
    }
  }

  if (pinPort && pinPort.port) {
    pinNode = pinPort.port;
    selectedPorts.push(pinNode);
  }

  const _netName = pinPort ? pinPort.netName : '';

  //filter selected port
  _ports = _ports.filter(port => port.port !== pinNode);
  return { _ports, selectedPorts, pinNode, netName: _netName };
}

function findPortBySignalAndNet({ filterPorts, signalReg, signal, net, pinReg, selectedPorts = [] }) {
  let pinPorts = [];
  pinPorts = filterPorts.filter(p => p.info.match(signalReg) && !selectedPorts.includes(p.port));
  if (pinPorts.length) {
    return pinPorts;
  }

  //DQ1 -> DQ, DQSP -> DQS -> positive: "T", negative: "C"
  const signalType = getDDRSignalType(signal);
  let signalNumber = getNum(signal);
  const netName = net ? net.match(/DQ[0-9]+/ig) : net;
  let netNumber = netName ? netName[0].match(/[0-9]+/ig)[0] : null;
  //([^a-zA-Z]|$)
  const typeRegList = signalType ? signalType.portTypeList.map(item => {
    if (signal.match(/(DQSP)|(CLKP)|(WCK_T)|(RDQS_T)|(DQS[0-9]+P)|(DQSN)|(CLKN)|(WCK_C)|(RDQS_C)|(DQS[0-9]+N)|([RDQS|WCK]+[0-9]+(T|C))/ig)) {
      return new RegExp(`${item}`, "ig");
    } else {
      return new RegExp(`${item}([^a-zA-Z]|$)`, "ig");
    }
  }) : null;

  const findPorts = filterPorts.filter(p => typeRegList && typeRegList.filter(it => p.info.match(it)).length);
  for (let i = 0; i < findPorts.length; i++) {
    const item = findPorts[i];
    if (selectedPorts.includes(item.port)) {
      continue;
    }
    const portInfoArr = getPortInfoSplitList(signalType.portTypeList, item.info);
    //info -> port1_DQS_T0_DDR
    //arr -> [ "port1_DQS",  "_T0_DDR" ]
    const str = portInfoArr.length ? portInfoArr[portInfoArr.length - 1] : null; // "_T0_DDR" 

    if ((signal.match(/DQS[0-9]+P|([RDQS|WCK]+[0-9]+T)/ig) && item.info.match(/DQS[0-9]+P|([RDQS|WCK]+[0-9]+T)/ig))
      || (signal.match(/DQS[0-9]+N|([RDQS|WCK]+[0-9]+C)/ig) && item.info.match(/DQS[0-9]+N|([RDQS|WCK]+[0-9]+C)/ig))) {
      const number = str ? getNum(str) : null;
      if (number && (number === signalNumber || number === netNumber)) {
        pinPorts.push(item);
        continue;
      }
    }

    if ((signal.match(/(DQSP)|(DQS[0-9]+P)|(RDQS_T)|([RDQS|WCK]+[0-9]+T)/ig) && item.info.match(/(DQSP)|(RDQS_T)|(WCK_T)/ig))
      || (signal.match(/(DQSN)|(DQS[0-9]+N)|(RDQS_C)|([RDQS|WCK]+[0-9]+C)/ig) && item.info.match(/(DQSN)|(RDQS_C)|(WCK_C)/ig))
      || (signal.match(/CLKP/ig) && item.info.match(/CLKP/ig))
      || (signal.match(/CLKN/ig) && item.info.match(/CLKN/ig))) {
      pinPorts.push(item);
      continue;
    }

    // item.info -> SOC_BGA_CHA_DQS_T[0] 0 PORT= 66 Z0=50
    // str -> _T[0] 0 PORT= 66 Z0=50
    // str.replace(/[^a-z]+/ig, "") -> TPORTZ
    // const signal = 'WCKA7TA';
    if (signal.match(/(DQSP)|(CLKP)|(WCK_T)|(RDQS_T)|(DQS[0-9]+P)|([RDQS|WCK]+[0-9]+T)/ig) && str && str.replace(/[^a-z]+/ig, "").match(/^T/ig)) {
      pinPorts.push(item);
      continue;
    }

    //same as DQSN/CLKN
    if (signal.match(/(DQSN)|(CLKN)|(WCK_C)|(RDQS_C)|(DQS[0-9]+N)|([RDQS|WCK]+[0-9]+C)/ig) && str && str.replace(/[^a-z]+/ig, "").match(/^C/ig)) {
      pinPorts.push(item);
      continue;
    }

    if ((signal.match(/DQS[0-9]+P|([RDQS|WCK]+[0-9]+T)/ig) && item.info.match(/DQS[0-9].*P$|([RDQS|WCK]+[0-9].*T$)/ig))
      || (signal.match(/DQS[0-9]+N|([RDQS|WCK]+[0-9]+C)/ig) && item.info.match(/DQS[0-9].*N$|([RDQS|WCK]+[0-9].*C$)/ig))) {
      const number = str ? getNum(str) : null;
      if (number && (number === signalNumber || number === netNumber)) {
        pinPorts.push(item);
        continue;
      }
    }

    if ((signal.match(/CLKP/ig) && item.info.match(/^[CLK|CK].*P$/ig))
      || (signal.match(/CLKN/ig) && item.info.match(/^[CLK|CK].*N$/ig))) {
      pinPorts.push(item);
      continue;
    }

    //DQ [0-9], CA[0-9], DM[0-9]...
    //DQ0 - DQ7 / DQ8 - DQ15 / DQ16 - DQ24
    const number = str ? getNum(str) : null;
    const DQSReg = /(DQS[0-9]+[P|N])|([RDQS|WCK]+[0-9]+[T|C])/ig;
    if (number && ((number === signalNumber && !signal.match(DQSReg)) || number === netNumber) && !selectedPorts.includes(item.port)) {
      pinPorts.push(item);
      continue;
    }
    if (signalNumber > 7) {
      signalNumber = signalNumber % 8;
      signalNumber = signalNumber.toString()
    }
    if (netNumber > 7) {
      netNumber = netNumber % 8;
      netNumber = netNumber.toString()
    }
    if (number && ((number === signalNumber && !signal.match(DQSReg)) || number === netNumber) && !selectedPorts.includes(item.port)) {
      pinPorts.push(item);
      continue;
    }

    //DM , RAS, WE, ...
    if (!signalNumber && !signal.match(/(DQSP)|(CLKP)|(WCK_T)|(RDQS_T)|(DQS[0-9]+P)|(DQSN)|(CLKN)|(WCK_C)|(RDQS_C)|(DQS[0-9]+N)|([RDQS|WCK]+[0-9]+[T|C])/ig)) {
      pinPorts.push(item);
      continue;
    }

    if (signal.match(/DM[0-9]+/ig) && item.info.match(/DM/ig) && (!number || (number && (number === signalNumber || number === netNumber)))) {
      pinPorts.push(item);
      continue;
    }
  }

  if (pinPorts.length > 1) {
    let filterPorts = pinPorts.filter(p => p.info.match(pinReg))
    if (filterPorts && filterPorts.length) {
      pinPorts = [...filterPorts]
    }
  }
  return pinPorts;
}

function getPortNotSelect(findPinPorts, selectedPorts) {
  for (let find of findPinPorts) {
    if (!selectedPorts.includes(find.port)) {
      return find;
    }
  }
  return "";
}

function getPortInfoSplitList(portTypeList, portInfo) {
  let portInfoArr = [];
  for (let item of portTypeList) {
    const reg = new RegExp(`${item}`, "ig");
    if (portInfo.match(reg)) {
      return portInfo.split(reg);
    }
  }
  return portInfoArr;
}

function selectTouchstonePorts({ dataList, libraryId, ports, key, components, subckt, signalGroupName, fileName, channelName, allSelectedPorts, pcbType }) {
  if (key) { } else {
    let _dataList = [], newComponents = [...components];
    for (let channelInfo of dataList) {
      let _channelList = []
      for (let interfaceInfo of channelInfo.channelList) {
        let _components = []
        let selectedPorts = [];
        for (let compInfo of interfaceInfo.components) {
          const { pins, name, type } = compInfo;
          if (![CONTROLLER, MEMORY, DIE, BGA].includes(type)) {
            _components.push({ ...compInfo })
            continue
          }

          const findCompIndex = newComponents.findIndex(item => item.name === name);
          let _pins = [];
          for (let pin of pins) {
            const { signal, net, model, signal_group, channel } = pin;

            const findPinIndex = newComponents[findCompIndex].pins.findIndex(item => item.pin === pin.pin);

            let _model = model;
            if ((!signalGroupName || (signalGroupName && signal_group === signalGroupName)) && (!channelName || !channel || (channelName && channel === channelName)) && (!model || !model.libraryId)) {
              let info = findPortNode({
                pin,
                net,
                _ports: ports,
                signal,
                selectedPorts: [PACKAGE, PCB].includes(pcbType) ? allSelectedPorts : selectedPorts,
                libraryId,
                pinType: [CONTROLLER, BGA].includes(compInfo.type) ? 'R' : 'L',
                netNames: [],
                isSSN: true,
                signalGroupName
              });
              if (info && info.pinNode) {
                if ([PACKAGE, PCB].includes(pcbType)) {
                  allSelectedPorts = info.selectedPorts;
                } else {
                  selectedPorts = info.selectedPorts;
                }
                _model = {
                  libraryId,
                  port: info.pinNode,
                  subckt: subckt ? subckt : '',
                  fileName
                }
              }
            }
            newComponents[findCompIndex].pins[findPinIndex].model = _model
            _pins.push({ ...pin, model: _model })
          }
          _components.push({ ...compInfo, pins: _pins })
        }
        _channelList.push({ ...interfaceInfo, components: _components })
      }
      _dataList.push({ ...channelInfo, channelList: _channelList })
    }
    return { dataList: _dataList, components: newComponents }
  }
}

export default TouchstoneHelper;
export { autoSelectTouchstonePorts, getPortNumber, getPortNumberFromFileSuffix, getNum, selectTouchstonePorts, getPortInfoSplitList };