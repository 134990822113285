import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import SierraSider from './SierraSider';
import SierraContent from './Content';
import Line from '@/components/Line';
import { changeLeft, changeTop } from "../panelStore/action";
import { closeTabFooter, openTabFooter, cleanTabMonitorStatus } from '../tabMonitor/action';
import { changeLayout, changeViewList, updatePCBLayout, changeTreeSelected } from './store/project/action';
import { VERIFICATION, PCB, RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, PCB_PRE_LAYOUT, MULTI_INTERFACE_SETUP } from '@/constants/treeConstants';
import { clearCCCStatus } from '../CCC/store/action';
import DownloadLog from './downloadLog';
import { isChrome } from '../../services/helper/browser';
import projectDesigns from '@/services/helper/projectDesigns';
import DelConfirm from '@/components/DelConfirm';
import { SIERRA } from '@/constants/pageType';
import { setPageType } from '../Login/action';
import SideFooter from '../../components/SideFooter';
import { PRE_LAYOUT } from '../../constants/designVendor';
import { changeProduct } from '../LayoutExplorer/LayoutStore/action';
import '@/publicCss/style.css';
import '@/publicCss/tree.css';
import '@/publicCss/libraryStyle.css';
import './sierra.css';

const { Content } = Layout;

class SierraPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 280,
      linePosition: {
        position: "absolute",
        left: 280
      },
      openMenu: true,
      layoutVisible: false,
      tooltipVisible: false,
      viewVisible: false,
      viewToolTip: false,
      chrome: true,
      browserVisible: false
    }
  }
  componentWillMount() {
    this.props.setPageType(SIERRA);
    this.props.clearCCCStatus();
  }

  changeWidth(width) {
    this.setState({ width })
    let position = {
      position: "absolute",
      left: width,
      minWidth: 56,
      maxWidth: 1000
    }
    this.setState({
      linePosition: position
    })
    this.props.changeLeft(width);
  }

  componentDidMount = () => {
    this.props.changeLeft(280);
    this.props.changeTop(54);
    this.props.cleanTabMonitorStatus();
    this.props.changeProduct(SIERRA);
    const chrome = isChrome();
    this.setState({
      chrome,
      browserVisible: chrome ? false : true
    })
  }

  componentDidUpdate = (prevProps) => {
    const { pageType } = this.props;
    if (pageType !== prevProps.pageType) {
      this.props.cleanTabMonitorStatus();
    }
  }

  changeSize = (width) => {
    let newWidth = width;
    const prevWidth = this.state.width;
    if (prevWidth === 56 && width > 56) {
      this.setState({
        openMenu: true
      });
      newWidth = 255;
      this.changeWidth(newWidth);
    } else {
      width = width < 255 ? 56 : width;
      width = width > 1000 ? 1000 : width;
      if (width === 56) {
        this.setState({
          openMenu: false
        });
      }
      this.changeWidth(width);
    }

  }

  openMenu = (e) => {
    e.stopPropagation();
    let position = {
      position: "absolute",
      left: 255,
      minWidth: 56,
      maxWidth: 1000,
    }
    this.setState({
      openMenu: true,
      width: 255,
      linePosition: position
    });
  }


  changeTabFooter = () => {
    const { openTabFooter, closeTabFooter, tabVisible } = this.props;
    tabVisible ? closeTabFooter() : openTabFooter();
  }

  layoutClick = (layoutType, singleType) => {
    this.props.changeLayout(layoutType);
    if (singleType) {
      this.singleLayoutClick(singleType);
      return;
    }
    const { viewList, currentProjectId, currentProjectVerifications } = this.props;

    if (!currentProjectId) {
      return;
    }

    if (viewList.length === 1) {
      if ([VERIFICATION, RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(viewList[0])) {
        let newList = [...viewList];
        if (currentProjectVerifications.length === 0) {
          newList = [];
        }
        if (projectDesigns.getAvailableDesignsLength(currentProjectId) > 0) {
          // Open PCB
          const currentProjectDesigns = projectDesigns.getAvailableDesigns(currentProjectId);
          if (currentProjectDesigns[0].vendor === PRE_LAYOUT) {
            newList = [...newList, PCB_PRE_LAYOUT];
            this.props.changeViewList([...newList], PCB_PRE_LAYOUT);
            return;
          }
          newList = [...newList, PCB];
          this.props.changeViewList([...newList], PCB);
        } else {
          this.props.changeViewList([...newList]);
        }
      } else {
        let newList = [...viewList]
        if (projectDesigns.getAvailableDesignsLength(currentProjectId) === 0) {
          newList = [];
        }
        if (currentProjectVerifications.length > 0) {
          // Open verification
          newList = [...newList, VERIFICATION];
          this.props.changeViewList([...newList], VERIFICATION);
        } else {
          this.props.changeViewList([...newList]);
        }
      }
    }
  }

  viewMouseOver = (e) => {
    this.setState({
      viewToolTip: true
    })
  }

  viewMouseOut = (e) => {
    this.setState({
      viewToolTip: false
    })
  }

  ViewVisibleChange = (visible) => {
    this.setState({
      viewVisible: visible
    });
  }

  singleLayoutClick = (type) => {
    const { viewList, currentProjectId, selectedKeys } = this.props;

    if (!currentProjectId) {
      return;
    }

    if (type === PCB || type === PCB_PRE_LAYOUT) {
      const newKeys = selectedKeys.filter(d => d.split('-')[0] === type);
      this.props._changeTreeSelected(newKeys);
      if (viewList.includes(type)) {
        this.props.changeViewList([type]);
      } else {
        this.props.changeViewList([type], type);
      }
    } else {
      const newKeys = selectedKeys.filter(d => ![PCB_PRE_LAYOUT, PCB].includes(d.split('-')[0]));
      this.props._changeTreeSelected(newKeys);
      const v = viewList.filter(d => ![PCB_PRE_LAYOUT, PCB].includes(d));
      if (v.length) {
        const view = v[0];
        this.props.changeViewList([view]);
      } else {
        this.props.changeViewList([VERIFICATION], VERIFICATION);
      }
    }
  }

  okConfirm = () => {
    this.setState({
      browserVisible: false
    });
    window.location.href = "./";
  }

  pcbLayoutChange = (type) => {
    const { pcbLayout } = this.props;
    this.props._updatePCBLayout(type, pcbLayout);
  }

  sideFooterActions = () => {
    const { openMenu } = this.state;
    const { viewList, tabVisible, layout, currentProjectId, pcbLayout } = this.props;
    return {
      openMenu,
      viewList,
      tabVisible,
      layout,
      currentProjectId,
      pcbLayout,
      layoutClick: this.layoutClick,
      changeTabFooter: this.changeTabFooter,
      pcbLayoutChange: this.pcbLayoutChange,
    }
  }

  render = () => {
    const { openMenu, chrome, browserVisible } = this.state;
    const errorMsg = 'Non Chrome browser is not supported in the simulation platform.';
    return (
      <Fragment>
        {chrome ? <Layout className="my-sierra-layout">
          <div className='sierra-sider'
            style={{ width: this.state.width }}
          >
            <div className='sierra-sider-menu' style={{ display: !openMenu ? 'block' : 'none' }}>
              <MenuOutlined className='sierra-sider-button' onClick={(e) => this.openMenu(e)} />
            </div>
            <div style={{ display: openMenu ? 'block' : 'none' }} className='sierra-sider-menu-tree'>
              <SierraSider width={this.state.width} />
            </div>
            <SideFooter actions={this.sideFooterActions()} />
          </div>
          <Line
            //Position is used to locate the Line
            position={this.state.linePosition}
            changeWidth={(width) => this.changeWidth(width)}
            width={this.state.width}
            resize={this.changeSize}
          />
          <Content className='aurora-content' style={{ left: this.state.width, overflow: '' }}>
            <SierraContent leftWidth={this.state.width} />
          </Content>
          <DownloadLog />
        </Layout> : null}
        {browserVisible ? <DelConfirm
          data={null}
          removeCancel={true}
          deleteConfirm={this.okConfirm}
          message={errorMsg}
        /> : null}
      </Fragment>
    );
  }
}


const mapState = (state) => {
  const { PanelStatus, LoginReducer: { pageType } } = state;
  const { project: { verificationId, layout, pcbLayout, viewList, currentProjectId, currentProjectVerifications, selectedKeys } } = state.SierraReducer;
  const { defaultTop, defaultLeft } = PanelStatus;
  const { tabVisible } = state.TabMonitorReducer;
  return {
    defaultTop,
    defaultLeft,
    tabVisible,
    verificationId,
    layout,
    viewList,
    currentProjectId,
    pageType,
    currentProjectVerifications,
    selectedKeys,
    pcbLayout
  }
}

const mapDispatch = (dispatch) => ({
  openTabFooter() {
    dispatch(openTabFooter())
  },
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  changeLeft(width) {
    dispatch(changeLeft(width));
  },
  changeTop(top) {
    dispatch(changeTop(top));
  },
  changeLayout(layout) {
    dispatch(changeLayout(layout))
  },
  changeViewList(viewList, viewType) {
    dispatch(changeViewList(viewList, viewType))
  },
  cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus());
  },
  clearCCCStatus() {
    dispatch(clearCCCStatus());
  },
  setPageType(type) {
    dispatch(setPageType(type));
  },
  _updatePCBLayout(pcbLayout, prePcbLayout) {
    dispatch(updatePCBLayout(pcbLayout, prePcbLayout))
  },
  _changeTreeSelected(selectedKeys) {
    dispatch(changeTreeSelected(selectedKeys));
  },
  changeProduct(product) {
    dispatch(changeProduct(product))
  }
})

export default connect(mapState, mapDispatch)(SierraPage);
