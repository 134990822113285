import React, { Component, Fragment } from 'react';
import { Switch, Input, Radio, Select } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { ImpOptions, KEEPDECAPWITHVALUENOLESS, KEEPDECAPLIST } from '../../../../services/Cascade/Impedance';
import './index.css';
import { fastPIValueCheck } from '../../../../services/helper/fastPIValueCheck';
import { numExponentialFormat } from '../../../../services/helper/numberHelper';

const { Option } = Select
class OptionPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      maxWidth: 350,
      maxHeight: 100,
      includeSense: false,
      vrmOpen: false,
      includeDecapModel: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 550),
      maxHeight: getPanelMaxHeight(offset, 100)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { Options } = this.props;
    if (Options) {
      const _options = new ImpOptions(Options)
      this.setState({
        ..._options
      })
    }
  }

  closeModal = () => {
    const { error } = this.state;

    if (error) {
      return;
    }

    const Options = new ImpOptions({ ...this.state });
    this.props.saveOptions(Options)
    this.props.closeModal(false);
  }

  changeSwitch = (checked, type) => {
    const { error, minimizeLargeDecaps, includeDecapModel } = this.state
    let params = {}
    if (type === 'minimizeLargeDecaps' && !checked) {
      const newError = error && ['targetMargin', 'keepDecapWithValueNoLess'].includes(error.type) ? null : error
      params.error = newError
      this.setState({ error: newError })
    }
    if (type === 'minimizeLargeDecaps' && checked && includeDecapModel) {
      params.includeDecapModel = false
    }
    if (type === 'includeDecapModel' && checked && minimizeLargeDecaps) {
      params.minimizeLargeDecaps = false
    }
    this.setState({
      ...params,
      [type]: checked
    })
  }

  changeSelectValue = (value, type) => {
    this.setState({
      [type]: value
    })
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { error } = this.state;
    _check = fastPIValueCheck({ value, inputType, product: 'Cascade' });
    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
      }
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: _value,
        error: newError
      });
    }
  }

  changeValue = (e, type) => {
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    });
  }

  radioChange = (e, type) => {
    let value = e.target.value;
    const state = { [type]: value }
    const { error } = this.state;
    if (type === 'decapOptimizationOption' && value === KEEPDECAPWITHVALUENOLESS) {
      state['keepDecapList'] = []
      state['keepDecapWithValueNoLess'] = '100'
    } else if (type === 'decapOptimizationOption' && value === KEEPDECAPLIST) {
      state['keepDecapWithValueNoLess'] = ''
      const newError = error && error.type === 'keepDecapWithValueNoLess' ? null : error;
      this.setState({ error: newError })
    }
    this.setState({
      ...state
    });
  }

  decapOptimization = () => {
    const { impComponents } = this.props
    const { minimizeLargeDecaps, targetMargin, keepDecapWithValueNoLess, keepDecapList, decapOptimizationOption } = this.state
    return (
      <Fragment>
        <div className='cascade-top-option-panel-content'>
          <span className='cascade-top-option-panel-content-body'>
            Minimize the large decaps
          </span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={minimizeLargeDecaps}
            onChange={(checked) => this.changeSwitch(checked, 'minimizeLargeDecaps')}
          />
        </div>
        {minimizeLargeDecaps && <Fragment>
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>
              Target margin
            </span>
            <Input
              value={targetMargin}
              addonAfter={'%'}
              className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
              style={{ width: 'calc(55% - 100px)' }}
              onChange={(e) => this.changeValue(e, 'targetMargin')}
              onBlur={(e) => this.inputBlur(e, 'targetMargin')}
            // onPressEnter={this.onPressEnter}
            />
          </div>
          <Radio.Group value={decapOptimizationOption} onChange={(e) => this.radioChange(e, 'decapOptimizationOption')}>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>
                <Radio value={KEEPDECAPWITHVALUENOLESS} >Keep the decap with value no less than</Radio>
              </span>
              <Input
                value={keepDecapWithValueNoLess}
                addonAfter={'uF'}
                disabled={decapOptimizationOption !== KEEPDECAPWITHVALUENOLESS}
                className='cascade-top-option-panel-content-input cascade-option-input-addonAfter'
                style={{ width: 'calc(55% - 100px)' }}
                onChange={(e) => this.changeValue(e, 'keepDecapWithValueNoLess')}
                onBlur={(e) => this.inputBlur(e, 'keepDecapWithValueNoLess')}
              // onPressEnter={this.onPressEnter}
              />
            </div>
            <div className='cascade-top-option-panel-content'>
              <span className='cascade-top-option-panel-content-body cascade-extraction-panel-sub-span'>
                <Radio value={KEEPDECAPLIST}>Keep the following decap components</Radio>
              </span>
              <Select
                value={keepDecapList}
                disabled={decapOptimizationOption !== KEEPDECAPLIST}
                onChange={(value) => { this.changeSelectValue(value, 'keepDecapList') }}
                mode='multiple'
                style={{ width: 'calc(55% - 100px)' }}
                className='aurora-select cascade-top-option-panel-content-input'
                popupClassName='aurora-select-dropdown'
              >
                {impComponents.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </div>
          </Radio.Group>
        </Fragment>
        }
      </Fragment>
    );
  }

  render() {
    const { error, maxWidth, maxHeight, includeSense, vrmOpen, includeDecapModel } = this.state;
    const { isImpSinglePCB } = this.props
    const setting = { defaultWidth: 550 }
    const content = (
      <Panel
        className='cascade-top-option-panel cascade-top-config-panel'
        title={<div className='cascade-top-option-panel-name'>Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="100"
      >
        <div className='cascade-top-option-panel-main cascade-top-opt-panel-main' id="simulate-content-id">
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body'>
              Include Sense Ports
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={includeSense}
              onChange={(checked) => this.changeSwitch(checked, 'includeSense')}
            />
          </div>
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body'>
              VRM Open
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={vrmOpen}
              onChange={(checked) => this.changeSwitch(checked, 'vrmOpen')}
            />
          </div>
          <div className='cascade-top-option-panel-content'>
            <span className='cascade-top-option-panel-content-body'>
              Include Decap Models in Extraction
            </span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={includeDecapModel}
              onChange={(checked) => this.changeSwitch(checked, 'includeDecapModel')}
            />
          </div>
          {isImpSinglePCB && this.decapOptimization()}
          {error && <span className='cascade-top-option-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default OptionPanel;