import MarkLine from "./markLine.js";
class MarkData {
  constructor() {
    this.marks = [];
    this.removeMarkLine = this.removeMarkLine.bind(this);
    this.curveName = ''
  }

  createMarkCross({ plotId, prevX, root, x, y, yValue, targetY, padding, height, xValue, curves, targetX, getYValue, getXValue, curveName, type, anotherX = {}, width, anotherY = {}, extra = {}, usage }) {
    const markLine = new MarkLine({
      id: `${plotId}-${prevX}${anotherX.prevX ? `-${anotherX.prevX}` : ''}`,
      prevX,
      delMarkLine: this.removeMarkLine,
      getYValue,
      getXValue,
      targetX,
      xValue,
      x,
      padding,
      height,
      type,
      anotherX,
      y,
      yValue,
      targetY,
      width,
      anotherY,
      extra,
      usage
    })
    markLine.drawCrossNode(root, curves)
    this.curveName = curveName
    this.marks.push(markLine);
  }

  removeMarkLine(id) {
    this.marks = this.marks.filter(m => m.id !== id);
  }

  removeMarkOnCurve(hashId) {
    this.marks.forEach(mark => {
      mark.removeNode(hashId);
    })
  }

  clearAll() {
    this.marks.forEach(mark => {
      mark.removeMarkLine()
    });
  }

  redraw({ padding, height, yMin, yMax }) {
    this.marks.forEach(mark => {
      mark.redrawLine({ padding, height });
      mark.redrawNodes({ padding, height, yMin, yMax });
    })
  }
}

export default MarkData;