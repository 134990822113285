import {
  SAVE_CURRENT_DESIGN,
  SAVE_CURRENT_PROJECT_PCB,
  UPDATE_DESIGN_MONITOR_INFO,
  SAVE_INFO,
  SAVE_SIMULATION_DESIGNS,
  SAVE_RESULT,
  CHANGE_PRODUCT
} from './actionType';
import { USER_LOGOUT } from '../../Login/actionTypes';

const defaultState = {
  checkout: {},
  projectLayout: [],
  designId: "",
  product: "",
  simulationDesigns: [],
  results: {},
  runningDesigns: ""
};

export const LayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case SAVE_CURRENT_PROJECT_PCB:
      return {
        ...state,
        projectLayout: action.designList
      }
    case SAVE_CURRENT_DESIGN:
      return {
        ...state,
        designId: action.designId
      }
    case UPDATE_DESIGN_MONITOR_INFO:
      const info = state.checkout[action.designId] || {}
      return {
        ...state,
        checkout: {
          ...state.checkout,
          [action.designId]: {
            ...info,
            ...action.info
          }
        }
      }
    case SAVE_INFO:
      return {
        ...state,
        ...action.params
      }
    case SAVE_SIMULATION_DESIGNS:
      return {
        ...state,
        simulationDesigns: [...action.designIds]
      }
    case SAVE_RESULT:
      return {
        ...state,
        results: {
          ...state.results,
          [action.designId]: action.result
        }
      }
    case CHANGE_PRODUCT:
      return {
        ...state,
        product: action.product,
        designId: "",
        simulationDesigns: [],
        runningDesigns: ""
      }
    default: return state
  }
} 