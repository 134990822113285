import {
  calcTwoPointsDistance, getAbyArcAndR, getBbyArcAndR, calcArc, calcCirclePoints, calcIncludedAngle,
  keepTwoDecimal
} from '../math';
function Larc({ start, end, mCenter, mCCW }) {
  this.mStart = start; // Boundary
  this.mEnd = end; // Boundary
  this.mCenter = mCenter;
  this.mCCW = mCCW; // Counter Clock Wise flag = Y | N
}

Larc.prototype.toPoints = function (_r, pointDensity) {
  const lineW = (this.mStart.geom.mDiameter - 2 * _r) / 2;
  const r = calcTwoPointsDistance(this.mCenter, this.mStart.geom);
  const l = _r + r + lineW;
  let arr = [];
  let start = this.getStartEndPoint(_r, l, this.mStart.geom),
    end = this.getStartEndPoint(_r, l, this.mEnd.geom);

  let startArc = calcIncludedAngle({ mX: this.mCenter.mX + l, mY: this.mCenter.mY }, start, this.mCenter);
  const arc = calcIncludedAngle(start, end, this.mCenter);
  if (start.mY < this.mCenter.mY) {
    startArc = 2 * Math.PI - startArc;
  }

  const dl = parseInt(arc * l / pointDensity);
  const _arc = arc / dl;
  for (let a = 1; a < dl; a++) {
    let _arc_;
    if (this.mCCW) {
      _arc_ = startArc + _arc * a;
    } else {
      _arc_ = startArc - _arc * a;
    }
    const point = calcCirclePoints({ x0: this.mCenter.mX, y0: this.mCenter.mY, r: l, arc: _arc_ })
    if (isNaN(point.x) || isNaN(point.y)) {
      continue;
    }
    arr.push([point.x, point.y])
  }

  arr.push([start.mX, start.mY], [end.mX, end.mY]);
  return arr.map(d => [keepTwoDecimal(d[0]), keepTwoDecimal(d[1])]);
};

Larc.prototype.getStartEndPoint = function (_r, l, _p) {
  let p = { mX: null, mY: null }
  let dX = 0, dY = 0;
  if (this.mCenter.mX === _p.mX) {
    dY = this.mCenter.mY > _p.mY ? - l : l;
  } else if (this.mCenter.mY === _p.mY) {
    dX = this.mCenter.mX > _p.mX ? -l : l;
  } else {
    const arc = calcArc(this.mCenter, _p);
    let startdX = Math.abs(getAbyArcAndR(arc, l)),
      startdY = Math.abs(getBbyArcAndR(arc, l));
    dX = this.mCenter.mX > _p.mX ? - startdX : startdX;
    dY = this.mCenter.mY > _p.mY ? - startdY : startdY;
  }
  p.mX = this.mCenter.mX + dX;
  p.mY = this.mCenter.mY + dY;
  return p;
}

export default Larc;