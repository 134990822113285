import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Select } from 'antd';
import { PORT_TYPE_LIST, PORT_REFERENCE_LIST, PortSetupNegative } from '../../../services/ExtractionPortsHelper';
import { PORT_REFERENCE_COMPONENT, PORT_REFERENCE_PIN, PORT_REFERENCE_POINT, PORT_REFERENCE_VIA } from '../../../services/ExtractionPortsHelper/portsSetup';
import ReferenceComponent from './ReferenceComponent';
import ReferencePin from './ReferencePin';
import ReferenceVia from "./ReferenceVia";
import ReferencePoint from './ReferencePoint';
import { CIRCUIT, GAP } from '../../../services/ExtractionPortsHelper/portTableHelper';
import '../../../publicCss/aurora.css';
import './index.css';

const Option = Select.Option;

class EditPortPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      pin: props.pin,
      info: props.info,
      portType: props.portType,
      reference: props.reference || {},
      errorMsg: null
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillMount = () => {
    const { pcbComponentList, component, pin, pcbInfo, referenceNets } = this.props;
    this.componentPinInfo = {};
    this.referNetsInfo = pcbInfo.netsList.filter(item => referenceNets.includes(item.mName));
    const comp = pcbComponentList.find(item => item.name === component);
    if (comp) {
      const pinObj = comp.pinList.find(item => item.mNumber === pin);
      this.componentPinInfo = { ...pinObj };
    }
  }

  closeModal = () => {
    const { component, pin, info, portType, reference } = this.state;
    this.props.closeEditPort({
      component,
      pin,
      info,
      portType,
      reference
    });
  }

  changeComponent = (key) => {
    this.setState({
      component: key,
      pin: "",
      info: {}
    })
  }

  changePin = (key) => {
    const { component } = this.state;
    const { components, signals, getSignalNetInfo } = this.props;
    const findComponent = components.find(item => item.name === component);
    const pin = findComponent ? findComponent.pins.find(item => item.pin === key) : null;
    this.setState({
      pin: key,
      info: getSignalNetInfo(pin, signals)
    })
  }

  changePorType = (key) => {
    const { reference: { type } } = this.state;

    if ((key === GAP && type === PORT_REFERENCE_POINT) || (key === CIRCUIT && type === PORT_REFERENCE_COMPONENT)) {
      this.changeReference(null);
    }
    this.setState({
      portType: key,
      errorMsg: null
    })
  }

  selectReference = (key) => {
    const { component } = this.state;
    let params = null;
    if (key === PORT_REFERENCE_PIN) {
      params = { component }
    }
    this.changeReference(key, params);
  }

  changeReference = (key, params) => {
    const negative = params ? new PortSetupNegative({ type: key, ...params }) : new PortSetupNegative({ type: key });
    this.setState({
      reference: negative,
      errorMsg: null
    })
  }

  updateError = (errorMsg) => {
    this.setState({
      errorMsg
    })
  }

  render() {
    const { component, pin, info, portType, reference, errorMsg } = this.state;
    const { components } = this.props;
    const findComponent = components.find(item => item.name === component);
    const content = (
      <Panel
        className='extraction-ports-panel panel-x-scroll-hidden'
        title={<div className='extraction-ports-title'>Signal Port</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={600}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={600}
      >
        <div className='extraction-ports-content'>
          {/* component pin */}
          <div className='signal-port-edit-item'>
            <label>Component - Pin</label>
            <div className='signal-port-comp-pin-content'>{component} - {pin}</div>
          </div>
          {/* Net */}
          <div className='signal-port-edit-item'>
            <label>Net</label>
            <div className='signal-port-comp-pin-content'>{info.net}</div>
          </div>
          {/* {this.getSelectComponent({
            label: "Component",
            value: `${component} - ${pin}`,
            changeFn: this.changeComponent,
            disabled: portTable,
            options: components,
            type: "component"
          })}
          {this.getSelectComponent({
            label: "Pin",
            value: `${pin} ( ${info.net} )`,
            changeFn: this.changePin,
            disabled: portTable,
            options: findComponent && findComponent.pins ? findComponent.pins : [],
            type: "pin"
          })} */}
          {/* port type */}
          {this.getSelectComponent({
            label: "Type",
            value: portType,
            changeFn: this.changePorType,
            options: PORT_TYPE_LIST,
            type: "portType"
          })}
          {/* Reference */}
          {this.getSelectComponent({
            label: "Reference",
            value: reference.type,
            changeFn: this.selectReference,
            options: PORT_REFERENCE_LIST,
            portType,
            type: "reference"
          })}
          {this.getReferenceRender(findComponent)}
          {errorMsg ? <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  getSelectComponent = ({
    label,
    value,
    changeFn,
    disabled,
    options,
    type,
    portType
  }) => {
    return <div className='signal-port-edit-item'>
      <label>{label}</label>
      <Select
        className='aurora-select'
        value={value}
        onChange={(key) => changeFn(key)}
        popupMatchSelectWidth={false}
        popupClassName='aurora-select-dropdown'
        disabled={disabled}
      >
        {this.getSelectOptions({
          options,
          value,
          type,
          portType
        })}
      </Select>
    </div>
  }

  getSelectOptions = ({ options, value, type, portType }) => {
    switch (type) {
      case "component":
        return options.map(item =>
          <Option key={item.name} title={item.name}>{item.name}</Option>
        );
      case "portType":
        return options.map(item =>
          <Option key={item.key} title={item.key}>{item.name}</Option>
        );
      case "reference":
        return options.map(item =>
          (item.types.includes(portType) && <Option key={item.key} title={item.key}>{item.name}</Option>)
        );
      case "pin":
        return options.map(item => (
          <Option
            key={item.pin}
            title={`${item.pin} ( ${item.net} )`}
            className={`${item.pin} ( ${item.net} )` === value ? "ant-select-dropdown-menu-item-selected" : ""}
          >{item.pin} ( {item.net} )</Option>))
      default: return null;
    }

  }

  getReferenceRender = (findComponent) => {
    const { reference, errorMsg, portType } = this.state;
    const { referenceNets } = this.props;
    switch (reference.type) {
      case PORT_REFERENCE_COMPONENT:
        return <ReferenceComponent
          {...this.props}
          reference={reference}
          errorMsg={errorMsg}
          findComponent={findComponent}
          referenceNets={referenceNets}
          referNetsInfo={this.referNetsInfo}
          componentPinInfo={this.componentPinInfo}
          updateError={this.updateError}
          changeReference={this.changeReference}
        />;
      case PORT_REFERENCE_PIN:
        return <ReferencePin
          {...this.props}
          reference={reference}
          errorMsg={errorMsg}
          referNetsInfo={this.referNetsInfo}
          componentPinInfo={this.componentPinInfo}
          findComponent={findComponent}
          updateError={this.updateError}
          changeReference={this.changeReference}
        />;
      case PORT_REFERENCE_VIA:
        return <ReferenceVia
          {...this.props}
          reference={reference}
          referNetsInfo={this.referNetsInfo}
          componentPinInfo={this.componentPinInfo}
          portType={portType}
          errorMsg={errorMsg}
          findComponent={findComponent}
          updateError={this.updateError}
          changeReference={this.changeReference}
        />;
      case PORT_REFERENCE_POINT:
        return <ReferencePoint
          {...this.props}
          reference={reference}
          referNetsInfo={this.referNetsInfo}
          componentPinInfo={this.componentPinInfo}
          errorMsg={errorMsg}
          findComponent={findComponent}
          updateError={this.updateError}
          changeReference={this.changeReference}
        />
      default: return null;
    }
  }
}

export default EditPortPanel;