import CeGeomTools from './CeGeomTools';
import CeVertex from './CeVertex';
import StringList from '../utility/StringList';
import StringHelper from '../utility/StringHelper';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeVertexArc = function () {
  CeVertex.call(this, "Parc");
  this.mCenter = new CeVertex();
  this.mCCW = true;
}

// subclass extends superclass
CeVertexArc.prototype = Object.create(CeVertex.prototype);
CeVertexArc.prototype.constructor = CeVertexArc;

CeVertexArc.prototype.SetData = function (x, y, cx, cy, ccw) {
  CeVertex.prototype.SetData.call(this, x, y);
  this.mCenter = new CeVertex();
  this.mCenter.SetData(cx, cy);
  this.mCCW = ccw;
}

CeVertexArc.prototype.CopyFrom = function (fromObj) {
  CeVertex.prototype.CopyFrom.call(this, fromObj);
  this.mCenter = fromObj.mCenter.Clone();
  this.mCCW = fromObj.mCCW;
}

CeVertexArc.prototype.GetRadius = function () {
  var dis = (this.mX - this.mCenter.mX) * (this.mX - this.mCenter.mX) +
    (this.mY - this.mCenter.mY) * (this.mY - this.mCenter.mY);
  if (CeGeomTools.TwoNumberEqual(dis, 0.0))
    return 0.0;
  return Math.sqrt(dis);
}

CeVertexArc.prototype.Clone = function () {
  var clnObj = new CeVertexArc();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeVertexArc.prototype.Scaling = function (scaleFactor) {
  CeVertex.prototype.Scaling.call(this, scaleFactor);
  this.mCenter.Scaling(scaleFactor);
}

CeVertexArc.prototype.IsArcPoint = function () {
  return true;
}

CeVertexArc.prototype.GetDataString = function () {
  var dataList = CeVertex.prototype.GetDataString.call(this);
  dataList.push(this.mCenter.mX);
  dataList.push(this.mCenter.mY);
  dataList.push(StringHelper.BoolToString(this.mCCW));
  return dataList;
}

CeVertexArc.prototype.UpdateByString = function (dataVals) {
  if (dataVals.size() != 5)
    return false;

  CeVertex.prototype.UpdateByString.call(this, dataVals);

  var center = new StringList();
  center.push(dataVals.get(2));
  center.push(dataVals.get(3));
  this.mCenter.UpdateByString(center);

  this.mCCW = StringHelper.StringToBool(dataVals.get(4));
  return true;
}

CeVertexArc.prototype.MoveToLocalCS = function (origin) {
  CeVertex.prototype.MoveToLocalCS.call(this, origin);
  this.mCenter.MoveToLocalCS(origin);
}

CeVertexArc.prototype.Move = function (dx, dy) {
  CeVertex.prototype.Move.call(this, dx, dy);
  this.mCenter.Move(dx, dy);
}

CeVertexArc.prototype.WriteIntoIODataNode = function () {
  return new CeIODataItem(this.GetGeomType(), this.GetDataString());
}

CeVertexArc.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeVertexArc.prototype.Transform = function (rotAngle, cw, loc) {
  CeVertex.prototype.Transform.call(this, rotAngle, cw, loc);
  this.mCenter.Transform(rotAngle, cw, loc);
}

export default CeVertexArc;