import { PCB_ONLY, SINGLE_PAGE_LAYOUT } from "../../../../constants/layoutConstants";
import { defaultTreeItems } from "../../../../services/Himalayas";
import {
  UPDATE_EXPAND,
  UPDATE_SELECTED_KEYS,
  UPDATE_TREE_LIST,
  UPDATE_OPEN_PROJECT_INFO,
  CLEAR_PROJECT_INFO,
  UPDATE_VIEW_LIST,
  UPDATE_SERVICE_CONFIG,
  UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO
} from './actionTypes';


const defaultState = {
  treeItems: JSON.parse(JSON.stringify(defaultTreeItems)),
  expandedKeys: ['projects', 'library'],
  selectedKeys: [],
  viewList: [],
  layout: SINGLE_PAGE_LAYOUT,
  pcbLayout: PCB_ONLY,
  openProjectId: null,
  reportInfo: {}
};
export const HimalayasProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_EXPAND:
      return {
        ...state,
        expandedKeys: action.expandedKeys
      }
    case UPDATE_SELECTED_KEYS:
      return {
        ...state,
        selectedKeys: action.selectedKeys
      }
    case UPDATE_TREE_LIST:
      return {
        ...state,
        treeItems: action.treeItems
      }
    case UPDATE_VIEW_LIST:
      return {
        ...state,
        viewList: action.viewList
      }
    case UPDATE_OPEN_PROJECT_INFO:
      return {
        ...state,
        openProjectId: action.id
      }
    case CLEAR_PROJECT_INFO:
      return {
        ...state,
        openProjectId: null,
        viewList: [],
        selectedKeys: [],
        reportInfo: {}
      }
    case UPDATE_SERVICE_CONFIG:
      return {
        ...state,
        hspiceOption: action.hspiceOption
      }
    case UPDATE_REPORT_INFO:
      return {
        ...state,
        reportInfo: { ...state.reportInfo, ...action.info }
      }
    case CLEAR_REPORT_INFO:
      return {
        ...state,
        reportInfo: {}
      }
    default: return state;
  }
}