class ElementListener {
  constructor(props) {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    //config
    this.config = {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    };

    this.observer = new MutationObserver(this.callback);

    this.targetNode = props.targetNode;

    //handle function
    this.childListHandle = props.childListHandle || null;
    this.attributesHandle = props.attributesHandle || null;
    this.characterDataHandle = props.characterDataHandle || null;
    this.subtreeHandle = props.subtreeHandle || null;
  }

  //callback function
  callback = (mutationsList, observer) => {
    // Use traditional 'for loops' for IE 11
    for (let mutation of mutationsList) {
      const type = mutation.type;
      switch (type) {
        case 'childList':
          this.childListHandle && this.childListHandle(mutation)
          break;
        case 'attributes':
          this.attributesHandle && this.attributesHandle(mutation)
          break;
        case 'characterData':
          this.characterDataHandle && this.characterDataHandle(mutation)
          break;
        case 'subtree':
          this.subtreeHandle && this.subtreeHandle(mutation)
          break;
        default: break;
      }
    }
  };

  observe = () => {
    this.observer.observe(this.targetNode, this.config)
  }

  disconnect = () => {
    this.observer.disconnect()
  }

}

export default ElementListener;