import React, { Component } from 'react';
import CPMConnection from './CPMConnection';
import { DIE } from '../../constants/componentType';
import CSMSignalsConnection from './CSMSignalsConnection';
import { PRE_LAYOUT } from '../../constants/designVendor';
import { FileInfo } from './cpmCsmContent';
import { getWriteAndReadPortList } from '../../services/LibraryHelper/SpiceModelHelper/csmCpmParseHelper';
import { CASCADE, ROCKY } from '../../constants/pageType';
import './index.css';
import { updateSSNPackageInfo } from '../../pages/Rocky/store/rocky/action';

class CpmCsmSelectPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFile: {},
      PowerNets: [],
      ReferenceNets: [],
      cpmVisible: false,
      csmVisible: false,
      canvasFile: {},
      displayPanel: false
    }
  }
  componentDidMount() {
    this.getPackageChannelInfo()
    this.initData()
  }

  initData = async () => {
    const { packageId, pdnInfo, cpmCsmVisible, targetPkg, product } = this.props;
    const { powerNet } = cpmCsmVisible;
    const findCurrentInfo = product === CASCADE ? pdnInfo.powerNets.find(item => powerNet.includes(item.powerNet)) : pdnInfo.onDies.find(item => item.powerNets.find(it => it.powerNet === powerNet));
    if (!findCurrentInfo) { return }

    let PowerNets = [], ReferenceNets = [], dieComponent = null;
    if (product === CASCADE) {
      const powerDomain = targetPkg.content
      PowerNets.push(...powerDomain.PowerNets);
      ReferenceNets.push(...powerDomain.ReferenceNets)
      if (!dieComponent) {
        dieComponent = powerDomain.Components.find(item => item.usage === DIE);
      }
    } else if (product === ROCKY) {
      const packagePDN = this.props.packageVerificationConstructor.getPackagePDN(packageId) || {}
      const pkgInfo = await this.props.PackagePCBPdnInfo.getPCBPackagePdnInfo(packagePDN.id) || {};//package pdn id
      const powerDomains = pkgInfo.content && pkgInfo.content.powerDomains ? pkgInfo.content.powerDomains : []
      for (let powerDomain of powerDomains) {
        PowerNets.push(...powerDomain.PowerNets);
        ReferenceNets.push(...powerDomain.ReferenceNets)
        if (!dieComponent) {
          dieComponent = powerDomain.Components.find(item => item.usage === DIE);
        }
      }
    }
    PowerNets = [...new Set(PowerNets)];
    ReferenceNets = [...new Set(ReferenceNets)];
    const _info = JSON.parse(JSON.stringify(findCurrentInfo.onDie || {}));
    const selectFile = { libraryId: _info.libraryId, fileName: _info.fileName, subckt: _info.subckt };
    const writeSelectFile = _info.writeSelectFile ? _info.writeSelectFile : new FileInfo({ ..._info });
    const readSelectFile = _info.readSelectFile ? _info.readSelectFile : new FileInfo({});
    this.setState({
      PowerNets,
      ReferenceNets,
      component: dieComponent ? dieComponent.name : null,
      info: _info,
      selectFile: { libraryId: _info.libraryId, fileName: _info.fileName, subckt: _info.subckt },
      csmPairs: _info.csmPairs || [],
      cpmPairs: _info.cpmPairs || [],
      triPairs: _info.cpmConfig ? _info.cpmConfig.triPairs : [],
      transforms: _info.cpmConfig ? _info.cpmConfig.transforms : null,
      canvasFile: { ...selectFile },
      displayPanel: true,
      writeSelectFile: { ...writeSelectFile },
      readSelectFile: { ...readSelectFile },
    })
  }

  getPackageChannelInfo = () => {
    const { rockyPackageInfo, packageChannelId, pcbChannelInfo, pcb, channelId, preLayoutId } = this.props;
    let getChannelId = null, getPackageChannelId = null, getPCBPreLayoutId = null;
    if (pcb && pcb.vendor !== PRE_LAYOUT && channelId && (
      !pcbChannelInfo
      || !Object.keys(pcbChannelInfo).length
    )) {
      getChannelId = channelId;
    } else if (pcb && pcb.vendor === PRE_LAYOUT && channelId && (!preLayoutId || preLayoutId !== pcb.id)) {
      getPCBPreLayoutId = pcb.id
    }

    if (packageChannelId && (
      !rockyPackageInfo
      || !Object.keys(rockyPackageInfo).length
      || rockyPackageInfo.id !== packageChannelId)) {
      //get package channel info
      getPackageChannelId = packageChannelId;
    }
    if (getPackageChannelId || getChannelId) {
      this.props._getPCBAndPackageChannelInfo(getChannelId, getPackageChannelId, getPCBPreLayoutId)
    }
  }

  saveCPMPinMapping = ({ cpmPairs, triPairs, transforms, canvasFile, csmPairs }) => {
    this.setState({
      cpmPairs,
      triPairs,
      transforms,
      canvasFile,
      csmPairs
    }, () => {
      this.closePanel()
    })
  }
  saveCSMPairs = (csmPairs) => {
    this.setState({
      csmPairs
    }, () => {
      this.closePanel()
    })
  }
  closePanel = () => {
    const { selectFile, csmPairs, cpmPairs, canvasFile, triPairs, transforms, info, writeSelectFile, readSelectFile } = this.state;
    const { cpmCsmVisible } = this.props;
    const { powerNet } = cpmCsmVisible;
    let cpmExist = false;
    if (canvasFile && selectFile && canvasFile.libraryId === selectFile.libraryId && canvasFile.fileName === selectFile.fileName) {
      cpmExist = true;
    }
    let _cpmPairs = cpmPairs, _triPairs = triPairs, _transforms = transforms;
    if (!cpmExist) {
      _cpmPairs = [];
      _triPairs = [];
      _transforms = null
    }

    const _info = {
      ...selectFile,
      csmPairs,
      cpmPairs: _cpmPairs,
      cpmConfig: {
        triPairs: _triPairs,
        transforms: _transforms
      },
      type: info.type,
      writeSelectFile,
      readSelectFile
    }
    if (info.type === "CPM") {
      delete info.csmPairs;
    }
    this.props.closeFilePanel("onDie", _info, powerNet)
    this.props.changeCpmCsmVisible({})
  }

  closePanels = () => { }

  render = () => {
    const { cpmCsmVisible, packageId, packageChannelId, pcb, SignalNets, product, modelType, rockyPackageInfo, isUpdateSsnPackageChannel, pcbChannelInfo, content, pinsInfo, DesignInfo } = this.props;
    const { selectFile, component, PowerNets, ReferenceNets,
      csmPairs, triPairs, transforms, cpmPairs, canvasFile, displayPanel, readSelectFile, writeSelectFile } = this.state;
    if (!displayPanel) { return null }
    if (cpmCsmVisible.key === "CPM") {
      return <CPMConnection
        designId={packageId}
        component={component}
        PowerNets={PowerNets}
        ReferenceNets={ReferenceNets}
        selectFile={selectFile}
        triPairs={triPairs}
        transforms={transforms}
        cpmPairs={cpmPairs}
        canvasFile={canvasFile}
        closePanel={this.closePanels}
        saveCPMPinMapping={this.saveCPMPinMapping}
        SignalNets={SignalNets}
        readSelectFile={readSelectFile}
        writeSelectFile={writeSelectFile}
        getPortList={getWriteAndReadPortList}
        csmPairs={csmPairs}
        pcb={pcb}
        product={product}
        modelType={modelType}
        pcbChannelInfo={pcbChannelInfo}
        isUpdateSsnPackageChannel={isUpdateSsnPackageChannel}
        content={content}
        pinsInfo={pinsInfo}
        getCSMCPMSpiceModelList={this.props.getCSMCPMSpiceModelList}
        DesignInfo={DesignInfo}
      />
    }
    return <CSMSignalsConnection
      designId={packageId}
      component={component}
      selectFile={selectFile}
      csmPairs={csmPairs}
      packageChannelId={packageChannelId}
      saveCSMPairs={this.saveCSMPairs}
      closePanel={this.closePanels}
      readSelectFile={readSelectFile}
      writeSelectFile={writeSelectFile}
      getPortList={getWriteAndReadPortList}
      pcb={pcb}
      product={product}
      rockyPackageInfo={rockyPackageInfo}
      isUpdateSsnPackageChannel={isUpdateSsnPackageChannel}
      pcbChannelInfo={pcbChannelInfo}
      content={content}
      updateSSNPackageInfo={updateSSNPackageInfo}
    />
  }
}

export default CpmCsmSelectPanel;