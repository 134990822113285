import { SeaSimAnalysisConfig } from ".";
import {
  PCIE_4
} from "./constants";

class SeaSimConfig {
  constructor(params = {}) {
    this.channels = params.channels || [];
    this.analysis = params.analysis || this.gerDefaultAnalysis(params);
  }

  gerDefaultAnalysis = (params) => {
    return {
      channels: params.analysisChannels || [],
      options: params.options || new SeaSimAnalysisConfig(PCIE_4)
    }
  }
}

function SeaSimChannel({ signal, controller, device, direction }) {
  this.signal = signal || "";
  this.controller = controller || {
    component: "",
    pins: []
  };
  this.device = device || {
    component: "",
    pins: []
  };
  this.direction = direction || "";
}

export {
  SeaSimConfig,
  SeaSimChannel
}