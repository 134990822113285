import { partCapValueMatch } from '../helper/RLCValue';
import { unitChange } from '../helper/mathHelper';

function matchSubckt(comp, subckts, Vdc = '1') {
  // name: subckt.name, oldV: the voltage of the currently selected subckt
  let name = '', oldV = '', oldVdc = '';
  //partV: voltage in part
  let partV = getPartVol(comp.part);
  let _Vdc = String(Vdc);
  const VdcReg = new RegExp(`(_${Vdc}Vdc)|(^${Vdc}Vdc)`);
  const vdcReg = new RegExp(`(_${String(Vdc).replace('.', 'p')}vdc)|(^${String(Vdc).replace('.', 'p')}vdc)`)
  if (!subckts.find(item => item.name.match(VdcReg) || item.name.toLowerCase().match(vdcReg))) {
    _Vdc = '1';
  }
  if (subckts && subckts.length > 0) {
    subckts.forEach(subckt => {
      //num: four-digit code, like 0201, f: Capacitance, v: Voltage, vdc: Nominal Voltage
      let num = '', f = '', v = '', vdc = '';
      let subcktList = subckt.name.split('_');

      subcktList.forEach(sub => {
        if (sub.match(/^\d{4}$/g)) {
          num = sub;
        } else if (sub.match(/Vdc/ig)) {
          vdc = sub.toUpperCase().replace(/(P)/ig, '.').replace(/Vdc/ig, '');
        } else if (sub.match(/V(?!dc)/ig)) {
          v = sub.toUpperCase().replace(/(P)/g, '.');
        } else if (sub.match(/(\d+\.)*\d+[UPN]F*(?!\d)/ig)) {
          f = partCapValueMatch({ param: sub, f })
        }
      })
      if (comp.part.indexOf(num) !== -1 && f !== '') {
        if (matchCap(comp.part, f)) {
          [name, oldV, oldVdc] = compareVol(partV, oldV, v, name, subckt.name, oldVdc, vdc, _Vdc);
        }
      } else if (f !== '' && name === '') {
        if (matchCap(comp.part, f)) {
          [name, oldV, oldVdc] = compareVol(partV, oldV, v, name, subckt.name, oldVdc, vdc, _Vdc);
        }

      }
    })
    if (!name) {
      let oldF = '';
      let partF = partCapValueMatch({ param: comp.part, f: "" });
      subckts.forEach(subckt => {
        //num: four-digit code, like 0201, f: Capacitance, v: Voltage
        let f = '', num = '';
        let subcktList = subckt.name.split('_');
        subcktList.forEach(sub => {
          if (sub.match(/^\d{4}$/g)) {
            num = sub;
          } else if (sub.match(/(\d+\.)*\d+[UPN]F*(?!\d)/ig)) {
            f = partCapValueMatch({ param: sub, f })
          }
        })
        if (comp.part.indexOf(num) !== -1 && f) {
          [name, oldF] = compareCnom(partF, oldF, f, name, subckt.name)
        } else if (f && !name) {
          [name, oldF] = compareCnom(partF, oldF, f, name, subckt.name)
        }
      })
    }
  }
  return name;
};

function matchSpSubckt(comp, subckts) {
  let name = '', oldV = '';
  let partName = comp.part;
  let partV = getPartVol(partName);
  let matchComp = false;
  subckts.forEach(subckt => {
    if (subckt && subckt.parameter) {
      let { ModelName, Size, Cnom, Volt } = subckt.parameter;
      if (ModelName === partName) {
        name = subckt.name;
        matchComp = true;
      } else if (!matchComp && partName.indexOf(Size) !== -1) {
        if (matchCap(comp.part, Cnom.toUpperCase())) {
          [name, oldV] = compareVol(partV, oldV, Volt, name, subckt.name);
        } else if (name === '') {
          if (matchCap(comp.part, Cnom.toUpperCase())) {
            [name, oldV] = compareVol(partV, oldV, Volt, name, subckt.name);
          }
        }
      }
    }
  })
  if (name === '') {
    let oldF = '';
    let partF = partCapValueMatch({ param: comp.part, f: "" });;
    subckts.forEach(subckt => {
      if (subckt && subckt.parameter) {
        let { Size, Cnom } = subckt.parameter;
        if (partName.indexOf(Size) !== -1) {
          [name, oldF] = compareCnom(partF, oldF, Cnom, name, subckt.name)
        } else if (name === '') {
          [name, oldF] = compareCnom(partF, oldF, Cnom, name, subckt.name)
        }
      }
    })
  }
  return name;
};

function matchCap(part, f) {
  // if f = 2UF, num = 2, unit = UF
  let num = Number(f.replace(/[a-z|A-Z]/g, ''));
  let unit = f.replace(/[\d.]/g, '').toLowerCase().replace('f', 'F');
  let list = [], _part = part.replace(' ', '');

  //1UF = 1000NF, 1NF = 1000PF
  const farad = ['nF', 'uF', 'pF'];
  farad.forEach(_unit => {
    const change = unitChange({ num, oldUnit: unit, newUnit: _unit, decimals: 1 });
    list.push(`${change.number}${_unit.toUpperCase()}`);
    list.push(`${change.number}.0${_unit.toUpperCase()}`);
  })
  const partFarad = partCapValueMatch({ param: _part, f: "" }).toUpperCase().replace(/((?!PF)P)/g, '.');
  return list.includes(partFarad) ? true : false;
}

function getPartVol(part) {
  let list = [];
  let pos = part.indexOf('V');
  let volStart = -1, volEnd = -1;
  while (pos > -1) {
    list.push(pos);
    pos = part.indexOf('V', pos + 1);
  }
  list.forEach(v => {
    let i = v, point = true;
    while (i > 0) {
      if (part[i - 1].match(/[\d]/g)) {
        volStart = i;
        volEnd = v;
        i = i - 1;
      } else if (point && part[i - 1].match(/[.p]/g)) {
        volStart = i;
        volEnd = v;
        i = i - 1;
        point = false;
      } else {
        break;
      }
    }
  })
  if (volStart !== -1) {
    return part.substring(volStart - 1, volEnd + 1).toUpperCase().replace('P', '.');
  } else {
    return '';
  }
}

const faradUnit = ['NF', 'UF', 'PF'];
function getPartCnom(part, keepUnit = false) {
  let list = [];
  let _part = part;
  let pos = _part.indexOf('F');
  let FStart = -1, FEnd = -1;
  while (pos > -1) {
    list.push(pos);
    pos = _part.indexOf('F', pos + 1);
  }

  list.forEach(f => {
    let i = f - 1, point = true;
    if (_part[i].match(/[\d]/g) || faradUnit.includes(`${_part[i]}${_part[f]}`)) {
      while (i > 0) {
        if (_part[i - 1].match(/[\d]/g)) {
          FStart = i;
          FEnd = f;
          i = i - 1;
        } else if (point && _part[i - 1].match(/[.p]/g)) {
          FStart = i;
          FEnd = f;
          i = i - 1;
          point = false;
        } else {
          break;
        }
      }
    }
  })

  if (FStart !== -1) {
    let Cnom = _part.substring(FStart - 1, FEnd + 1);
    if (keepUnit) return Cnom;
    let num = Number(Cnom.replace(/[a-z|A-Z]/g, ''));
    let unit = Cnom.replace(/[\d.]/g, '').toLowerCase().replace('f', 'F');
    num = unitChange({ num, oldUnit: unit, newUnit: 'nF', decimals: 3 }).number;
    return num ? `${num}NF` : '';
  } else {
    return '';
  }
}

function compareVol(partV, oldV, newV, oldName, newName, oldVdc, partVdc, vdc) {
  if (oldVdc !== vdc && vdc === partVdc) {
    return [newName, newV, vdc];
  }
  if (oldVdc === vdc && vdc !== partVdc) {
    return [oldName, oldV, oldVdc];
  }
  if (!oldV) {
    return [newName, newV, partVdc];
  }
  if (!partV) {
    return [oldName, oldV, oldVdc];
  }
  let _part = Number(partV.replace('V', ''));
  let _old = Number(oldV.replace('V', ''));
  let _new = Number(newV.replace('V', ''));
  if (_part < _old) {
    return [newName, newV, partVdc];
  } else if (_part < _new) {
    return [oldName, oldV, oldVdc];
  }
  if (_part - _old <= _part - _new) {
    return [oldName, oldV, oldVdc];
  } else {
    return [newName, newV, partVdc];
  }

}

function compareCnom(partF, oldF, newF, oldName, newName) {
  let name = '', f = '';
  if (!partF) {
    return [name, f];
  }
  let new_num = Number(newF.replace(/[a-z|A-Z]/g, ''));
  let new_unit = newF.replace(/[\d.]/g, '').toLowerCase().replace('f', 'F');
  new_num = unitChange({ num: new_num, oldUnit: new_unit, newUnit: 'nF', decimals: 1 }).number;
  let part_num = Number(partF.replace(/[a-z|A-Z]/g, ''));
  let part_unit = partF.replace(/[\d.]/g, '').toLowerCase().replace('f', 'F');
  part_num = unitChange({ num: part_num, oldUnit: part_unit, newUnit: 'nF', decimals: 1 }).number;
  if ((part_num && new_num && ((part_num / new_num) > 10 || (new_num / part_num) > 10))) {
    return oldName ? [oldName, oldF] : [name, f];
  }

  if (!oldF) {
    return [newName, newF];
  }

  let old_num = Number(oldF.replace(/[a-z|A-Z]/g, ''));
  let old_unit = partF.replace(/[\d.]/g, '').toLowerCase().replace('f', 'F')
  old_num = unitChange({ num: old_num, oldUnit: old_unit, newUnit: 'nF', decimals: 1 }).number;
  if (old_num && part_num && new_num && (old_num - part_num < new_num - part_num)) {
    return [oldName, oldF]
  } else {
    return [newName, newF]
  }

}

function autoFindDecap(component, decap, decapNames, Vdc) {
  //auto find model
  const { id, subckts, type } = decap;
  let matchComponents = [...component];
  if (id && matchComponents && matchComponents.length > 0) {
    let model = { name: "", subcktName: "", id: "", type: "", libraryType: "" };
    let autoModel = {};
    let matchDecap = '';
    let defaultName = {};
    decapNames.forEach(lib => {
      if (lib.id === id) {
        defaultName = { ...lib };
      }
    })

    matchComponents = component.map((item) => {
      if (item.usage === "Cap") {
        if (item && item.models && item.models.length && item.models.find(it => !!it.id)) {
          autoModel[item.part] = item.models;
        }
        if (!autoModel[item.part]) {
          if (type !== "parameter") {
            matchDecap = matchSubckt(item, subckts, Vdc);
          } else {
            matchDecap = matchSpSubckt(item, subckts);
          }
          if (matchDecap) {
            const model = {
              name: defaultName.name,
              subcktName: matchDecap,
              id: id,
              type: "spice",
              libraryType: defaultName.type,
            };
            item.models = [model];
            autoModel[item.part] = [model];
          } else {
            item.models = [model];
            autoModel[item.part] = [model];
          }
        } else {
          item.models = autoModel[item.part];
        }
      }
      return item;
    });
  }
  return matchComponents;
}

export { matchSubckt, matchSpSubckt, autoFindDecap };