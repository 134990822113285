import { select, event } from 'd3-selection';
import CanvasObject from './CanvasObject';
import CanvasPath from './CanvasPath';
import CanvasObjectList from './CanvasObjectList';
import LayerRenderer from './LayerRenderer';
import CePolygon from '../geometry/CePolygon';
import CeCircle from '../geometry/CeCircle';
import GCPolygon from '../geometry/GCPolygon';
import CeRectangle from '../geometry/CeRectangle';
import CeLine from '../geometry/CeLine';

var COMPONENT_COLOR = 'yellow';
var SELECTION_COLOR = 'magenta';
const PINPATH_COLOR = 'orange'

var CompLayerRenderer = function (metalLayer, layoutRenderer, layerName, hidden, clickCallback, milToUnit) {
  LayerRenderer.call(this, layoutRenderer, layerName, hidden);
  this.metalLayer = metalLayer;
  this.compObjList = [];
  this.d3CompGroup = [];
  this.compPinsData = [];
  this.clickCallback = clickCallback;
  this.milToUnit = milToUnit;
  this.point = new Map();
  this.pinOutline = ''
};

CompLayerRenderer.prototype = Object.create(LayerRenderer.prototype);
CompLayerRenderer.prototype.constructor = CompLayerRenderer;

CompLayerRenderer.prototype.setShowPinList = function (showPinList, onlyHiddenPins = false) {
  this.showPinList = showPinList;
  this.onlyHiddenPins = onlyHiddenPins;
}

/**
 * generate component object list form drawing svg from component layer
 */
CompLayerRenderer.prototype.prepareData = function () {

  var compList = this.metalLayer.GetComponentLayer().GetComponents();
  // get the unit, it will influence the calculation of outline
  var unit = this.layoutDB.mUnits;
  if (compList == null) {
    console.log("Unable to get the component layer from metal layer.");
    return;
  }
  this.compObjList = [];
  for (var i = 0; i < compList.length; i++) {

    var compName = compList[i].GetName();
    var cePart = compList[i].mPart;
    var footPrintSymbol = cePart.GetFootPrintSymbol(this.layoutDB.mPartMgr);
    const netList = this.layoutDB.mNetManager.GetNetsByComp(compName);

    var compObj = {
      name: compName,
      svgGrp: null,
      outlineObj: null,
      objList: new CanvasObjectList(this.clickCallback),
      selected: false
    };
    this.compObjList.push(compObj);

    // create a template object that contains detailed object information
    var templateObj = new CanvasObject();
    templateObj.layer = this.layerName;
    templateObj.type = 'pin';
    templateObj.comp = compName;

    // draw pads
    var pads = footPrintSymbol ? footPrintSymbol.GetPadGeometries() : [];
    var minY, minX = minY = Number.MAX_VALUE;
    var maxY, maxX = maxY = Number.MIN_VALUE;
    if (pads)
      for (var j = 0; j < pads.length; j++) {
        const num = footPrintSymbol.mMetalLayerList[0].mPadList[j].mPadID;
        const pinInfo = cePart.GetPinByPinNumber(num);
        const pinName = pinInfo.mName;
        const pinNumber = pinInfo.mNumber;
        const netInfo = netList.find(item => item.mPinNum === pinNumber);
        const toolTip = `${compName}: ${pinNumber}:: ${pinName}${netInfo ? ` -> ${netInfo.net}` : ''}`;

        templateObj.pin = pinName;
        templateObj.pinNumber = pinNumber;
        templateObj.toolTip = toolTip;
        if (this.showPinList && this.showPinList.length && !this.onlyHiddenPins) {
          const _compName = compName;
          const findPin = this.showPinList.find(item => item.name === _compName && item.pin === pinNumber);
          findPin && compObj.objList.addGeometry(pads[j], compList[i].mLocation, templateObj);
        } else {
          compObj.objList.addGeometry(pads[j], compList[i].mLocation, templateObj);
        }
        if (pads[j] instanceof GCPolygon) {
          minX = Math.min(minX, Math.min.apply(null, pads[j].mXs));
          minY = Math.min(minY, Math.min.apply(null, pads[j].mYs));
          maxX = Math.max(maxX, Math.max.apply(null, pads[j].mXs));
          maxY = Math.max(maxY, Math.max.apply(null, pads[j].mYs));
        } else if (pads[j] instanceof CeCircle) {
          var centerX = pads[j].mCenter.getX();
          var centerY = pads[j].mCenter.getY();
          var r = pads[j].mDiameter / 2;
          minX = Math.min(minX, centerX - r);
          minY = Math.min(minY, centerY - r);
          maxX = Math.max(maxX, centerX + r);
          maxY = Math.max(maxY, centerY + r);
        } else console.log(pads[j])
      }

    // draw the outline
    // if(unit === 'mm'){
    // 	minX -= 0.2; minY-= 0.2; maxX += 0.2; maxY += 0.2;
    // }else{
    // 	minX -= 15; minY-= 15; maxX += 15; maxY += 15;
    // }

    var outline;
    if (footPrintSymbol && footPrintSymbol.mOutline) {
      outline = footPrintSymbol.mOutline.ConvertToGCPolygon();
    } else {

      outline = new CePolygon();
      if (!footPrintSymbol || !footPrintSymbol.mMetalLayerList || !footPrintSymbol.mMetalLayerList.length) {
        console.error('foot print symbol don\'t have metal layer: ' + (footPrintSymbol ? footPrintSymbol.mID : ""));

      } else {
        var logicLayers = footPrintSymbol.mMetalLayerList[0].mLogicLayers;
        if (footPrintSymbol.mMetalLayerList.length > 1) console.log(footPrintSymbol.mMetalLayerList.length)
        for (var iLayer = 0; iLayer < logicLayers.length; iLayer++) {
          //
          if (logicLayers[iLayer].GetType() != "DOCUMENT") continue;
          var geomList = logicLayers[iLayer].mGeomList.slice(0);
          //if (geomList.length < 3) console.log('geomList: '+geomList.length);
          var start, end;
          for (var iGeom = 0; iGeom < geomList.length; iGeom++) {
            var geometry = geomList[iGeom].mGeometry;
            if (geometry instanceof CeRectangle) {
              var center = geometry.mCenter;
              start = {
                mX: geometry.mCenter.mX - geometry.mWidth / 2,
                mY: geometry.mCenter.mY - geometry.mHeight / 2
              };

              end = {
                mX: geometry.mCenter.mX + geometry.mWidth / 2,
                mY: geometry.mCenter.mY + geometry.mHeight / 2
              };
            } else if (geometry instanceof CeLine) {
              start = geometry.GetStart();
              end = geometry.GetEnd();
            } else {
              console.log('error: don\'t support this geometry')
            }

            minX = Math.min(minX, start.mX, end.mX);
            minY = Math.min(minY, start.mY, end.mY);
            maxX = Math.max(maxX, start.mX, end.mX);
            maxY = Math.max(maxY, start.mY, end.mY);
          };
        }
      }
      outline.AddVertex(minX, minY);
      outline.AddVertex(minX, maxY);
      outline.AddVertex(maxX, maxY);
      outline.AddVertex(maxX, minY);
      outline = outline.ConvertToGCPolygon();
    }

    if (compList[i].mLocation)
      compList[i].mLocation.TransformGCPolygon(outline);
    compObj.outlineObj = new CanvasPath();
    compObj.outlineObj.pathData = outline.GetSvgPath();
    compObj.outlineObj.layer = this.layerName;
    compObj.outlineObj.type = 'component';
    compObj.outlineObj.comp = compName;

  } // for (var i = 0; i < compList.length; i++)

  // call the virtual function of the parent
  LayerRenderer.prototype.prepareData.call(this);

}; // CompLayerRenderer.prototype.prepareData

var clickEventHandling = function (d, layoutRenderer, callback) {
  if (layoutRenderer.canvasType !== "NET") {
    //nets canvas
    event.stopPropagation();
  }

  if (event.defaultPrevented || layoutRenderer.coupleMode) {
    return;
  }

  select(d.svgGrp).attr('display', 'none');
  var nets = getElementsByPosition(event.clientX, event.clientY);
  const _comps = getCompsByPosition(event.clientX, event.clientY);
  select(d.svgGrp).attr('display', null);
  const canvasObj = { comps: [d.name] };
  canvasObj.multi = event.ctrlKey === true;
  if (nets.length > 0) {
    canvasObj.nets = nets;
  }
  if (_comps.length > 0) {
    canvasObj.comps = [...canvasObj.comps, ..._comps];
  }
  canvasObj.x = event.clientX;
  canvasObj.y = event.clientY;
  callback(canvasObj);
};

function getElementsByPosition(x, y) {
  var element = document.elementFromPoint(x, y);
  if (element.tagName !== 'svg') {
    select(element).attr('display', 'none');
    var nets = getElementsByPosition(x, y);
    select(element).attr('display', null);
    var net = element.__data__.net;
    if (net && nets.indexOf(net) < 0)
      nets.push(net)
    return nets;
  } else {
    return [];
  }
}

function getCompsByPosition(x, y) {
  var element = document.elementFromPoint(x, y);
  if (element.tagName !== 'svg') {
    select(element).attr('display', 'none');
    var comps = getCompsByPosition(x, y);
    select(element).attr('display', null);
    var comp = element.__data__.comp;
    if (comp && comps.indexOf(comp) < 0)
      comps.push(comp)
    return comps;
  } else {
    return [];
  }
}

CompLayerRenderer.prototype.redraw = function () {
  var self = this;

  this.d3CompGroup = this.layerElement
    .selectAll('g')
    .data(this.compObjList)
    .enter()
    .append('g')
    .attr('comp', function (compObj) {
      return compObj.name;
    });
  //add rect (rocky cpm)
  this.d3CompGroup
    .append("rect")
    .attr("id", function (compObj) {
      return `${compObj.name}-rect`;
    });

  this.updateColoring();

  const mComponentsList = this.metalLayer.mComponentLayer.mComponents;
  this.d3CompGroup
    .data(this.compObjList)
    .each(function (compObj) {
      if (compObj.outlineObj)
        compObj.outlineObj.drawSVG(select(this), {
          'fill-opacity': 0
        });
      compObj.objList.drawSVG(select(this));
      compObj.svgGrp = this;
      select(this).on('click', function (d) {
        clickEventHandling(d, self.layoutRenderer, self.clickCallback);
      })
    })
    .append('title')
    .text(function (compObj, index) {
      let x = null, y = null, compInfo = null;
      if (mComponentsList[index].mName === compObj.name) {
        compInfo = mComponentsList[index];
      } else {
        const findComponentIndex = mComponentsList.findIndex(item => item.mName === compObj.name);
        if (findComponentIndex > -1) {
          compInfo = mComponentsList[findComponentIndex];
        }
      };
      if (compInfo) {
        x = compInfo.mLocation.mPosition.mX;
        y = compInfo.mLocation.mPosition.mY;
      }

      return `${compObj.name}\nLayer: ${compObj.outlineObj.layer}\n(${x}, ${y})`
    });

  this.updateStrokeWidth();

  // call the virtual function of the parent
  LayerRenderer.prototype.redraw.call(this);

}; // CompLayerRenderer.prototype.redraw

CompLayerRenderer.prototype.updateColoring = function () {

  // use the default component color
  // var color = this.layoutDB.GetLayoutSettings().GetLayerColor(this.layerName);
  var color = COMPONENT_COLOR;

  if (this.layoutRenderer.fillMode)
    this.layerElement
      .attr('fill', color)
      .attr('stroke', null);
  else
    this.layerElement
      .attr('fill', 'black')
      .attr('fill-opacity', 0)
      .attr('stroke', color);
};

/** virtual function - set the object visibility of this layer */
CompLayerRenderer.prototype.updateVisibility = function () {

  var hidden = true;
  if (this.layoutDB.GetLayoutSettings().IsLayerVisible(this.layerName)) {
    hidden = null;
    // this layer is now visible, render if it has not been done before
    if (!this.dataLoaded) {
      this.prepareData();
    }
    if (!this.rendered) {
      // $rootScope.$broadcast('layerRendering', this.layerName);
      this.redraw();
      // $rootScope.$broadcast('layerRendered', this.layerName);
    }
  }
  this.layerElement.attr('hidden', hidden);
};

/** virtual function - for line shapes, update the line width of the layer */
CompLayerRenderer.prototype.updateStrokeWidth = function () {

  // use the default component color
  // var color = this.layoutDB.GetLayoutSettings().GetLayerColor(this.layerName);
  var color = COMPONENT_COLOR;

  for (var i = 0, len = this.compObjList.length; i < len; i++) {
    if (this.compObjList[i].outlineObj) {
      let _color = this.compObjList[i].selected ? SELECTION_COLOR : color
      if (this.compObjList[i].selected) {
        const name = this.compObjList[i].name;
        const data = this.compPinsData.find(item => item.comp === name)
        if (data) {
          _color = getCompColor(data.type)
        } else {
          _color = SELECTION_COLOR
        }
      }
      this.compObjList[i].outlineObj.setStroke(this.layoutRenderer.outlineStrokeWidth,
        _color);
    }
  }

  if (this.pinOutline)
    this.pinOutline.setStroke(this.layoutRenderer.outlineStrokeWidth, PINPATH_COLOR)

  const gridLines = select(this.layerElement.node().parentNode)
    .select('g.gridLines')
    .selectAll('line')
  if (gridLines.size() > 0) {
    gridLines.attr('stroke-width', this.layoutRenderer.outlineStrokeWidth)
  }
};

/** set or unset the component selection coloring
 *  @param compName name of the component to be changed, note that the component maynot belong
 *                  to this layer
 *  @param select whether to set or unset the selection coloring
 */
CompLayerRenderer.prototype.setCompSelection = function (compNames, isSelected, compPinsData = []) {
  this.compPinsData = compPinsData;
  for (var i = 0, len = this.compObjList.length; i < len; i++) {

    const compObj = this.compObjList[i];
    if (compNames.indexOf(compObj.name) > -1) {
      compObj.selected = isSelected;

      var color = isSelected ? SELECTION_COLOR : COMPONENT_COLOR;
      var fill = null;
      var stroke = null;
      if (isSelected) {
        // when not selected, the color and filling are controlled outside
        fill = this.layoutRenderer.fillMode ? color : 'black';
        stroke = this.layoutRenderer.fillMode ? null : color;
      }

      if (compPinsData.length) {
        const findInfo = compPinsData.find(item => item.comp === compObj.name);
        if (findInfo) {
          const _color = getCompColor(findInfo.type)
          color = _color;
          fill = _color;
        }
      }

      // set the group coloring (for the pins)
      select(compObj.svgGrp)
        .attr('fill', fill)
        .attr('stroke', stroke)
        .attr('opacity', null)
        .attr('fill-opacity', 1)

      // set the outline coloring
      if (compObj.outlineObj && compObj.outlineObj.element) {
        compObj.outlineObj.element.attr('stroke', color);
      }
    } else {
      if (isSelected)
        select(compObj.svgGrp)
          .attr('opacity', 0.2);
      else
        select(compObj.svgGrp)
          .attr('opacity', null);
    }
  }
};

CompLayerRenderer.prototype.setPinSelection = function (pins = {}, isSelected, color = 'yellow') {
  this.setPinAttr(pins, isSelected, 'fill', color);
}

CompLayerRenderer.prototype.highlightPinSelection = function (pins = {}, svgNode) {
  const comps = Object.keys(pins);

  for (let comp of comps) {
    const find = this.compObjList.find(d => d.name === comp)

    if (!find) continue;
    const pinsNode = svgNode.append("g").classed(`comp-pins-selection-g-${comp}`, true);
    find.objList.drawSVG(pinsNode, "magenta", true);
  }
}

CompLayerRenderer.prototype.setPinOpacity = function (pins = {}, opacity = '') {
  //this.setPinAttr(pins, true, 'opacity', opacity);
  const _opacity = opacity ? opacity.replace(".", "-") : "1";
  const className = `pin-opacity-port-${_opacity}`;
  this.setPinAttr(pins, true, 'class', className);
}

CompLayerRenderer.prototype.setPinAttr = function (pins = {}, isSelected, arrtName, value) {
  const comps = Object.keys(pins);
  for (let comp of comps) {
    const find = this.compObjList.find(d => d.name === comp)
    if (!find) continue;
    const canvasArr = Object.keys(find.objList).filter(d => Array.isArray(find.objList[d]) && find.objList[d].length);
    const compPins = pins[comp];
    if (compPins && compPins.length) {
      for (let pin of compPins) {
        for (let canvasItem of canvasArr) {
          const findPin = find.objList[canvasItem].find(d => d.pinNumber === pin);
          if (findPin) {
            if (arrtName === 'class') {
              select(findPin.element)
                .classed(value, isSelected)
                .on('mousedown', ((canvas) => {
                  //right mouse click
                  if (event && event.button === 2) {
                    this.layoutRenderer.rightClickPin({ canvas, findPin })
                  }
                }))
            } else {
              select(findPin.element)
                .attr(arrtName, isSelected ? value : '')
                .on('mousedown', ((canvas) => {
                  //right mouse click
                  if (event && event.button === 2) {
                    this.layoutRenderer.rightClickPin({ canvas, findPin })
                  }
                }))
            }
            continue;
          }
        }
      }
    }
  }
}

CompLayerRenderer.prototype.findPins = function (pins = {}) {
  const comps = Object.keys(pins);
  let arr = [];
  for (let comp of comps) {
    const find = this.compObjList.find(d => d.name === comp)
    if (!find) continue;
    const canvasArr = Object.keys(find.objList).filter(d => Array.isArray(find.objList[d]) && find.objList[d].length);
    const compPins = pins[comp];
    if (compPins && compPins.length) {
      for (let pin of compPins) {
        for (let canvasItem of canvasArr) {
          const findPin = find.objList[canvasItem].find(d => d.pinNumber === pin);
          if (findPin) {
            arr.push(findPin);
          }
          continue;
        }
      }
    }
  }
  return arr;
}

CompLayerRenderer.prototype.hightlightCompOutline = function (showOutLineList, isSelected) {
  const filterCompObjData = this.compObjList.filter(compObj => showOutLineList.includes(compObj.name))

  for (let compObj of filterCompObjData) {
    compObj.selected = isSelected;
    let color = isSelected ? SELECTION_COLOR : COMPONENT_COLOR;
    let stroke = null;
    if (isSelected) {
      // when not selected, the color and filling are controlled outside
      stroke = this.layoutRenderer.fillMode ? null : color;
    }

    select(compObj.svgGrp)
      .attr('fill', 'yellow')
      .attr('stroke', stroke)
      .attr('opacity', null)
      .attr('fill-opacity', 0.3)
  }
}

CompLayerRenderer.prototype.addColorToPortPins = function ({ ports, direction }) {

  const comps = Object.keys(ports);
  for (let comp of comps) {
    const find = this.compObjList.find(d => d.name === comp)
    if (!find) continue;
    const canvasArr = Object.keys(find.objList).filter(d => Array.isArray(find.objList[d]) && find.objList[d].length);
    const compPins = ports[comp];
    if (compPins && compPins.length) {
      for (let pin of compPins) {
        for (let canvasItem of canvasArr) {
          const findPin = find.objList[canvasItem].find(d => d.pinNumber === pin);
          if (findPin) {
            select(findPin.element)
              .classed(`pin-${direction}-port-box`, true)
            continue;
          }
        }
      }
    }
  }
}

CompLayerRenderer.prototype.getFourPoint = function (compName) {
  return this.point.get(compName)
}

CompLayerRenderer.prototype.getPinPath = function (pins, size, widthSize, heightSize) {
  const compList = this.metalLayer.GetComponentLayer().GetComponents();
  if (!compList) {
    console.log("Unable to get the component layer from metal layer.");
    return;
  }
  for (let i = 0; i < compList.length; i++) {
    const compName = compList[i].GetName();
    const cePart = compList[i].mPart;
    const footPrintSymbol = cePart.GetFootPrintSymbol(this.layoutDB.mPartMgr);
    const pads = footPrintSymbol ? footPrintSymbol.GetPadGeometries() : [];
    let minY, minX = minY = Infinity;
    let maxY, maxX = maxY = -Infinity;
    if (pads)
      for (let j = 0; j < pads.length; j++) {
        const num = footPrintSymbol.mMetalLayerList[0].mPadList[j].mPadID;
        const pinInfo = cePart.GetPinByPinNumber(num);
        const pinNumber = pinInfo.mNumber;
        if (pins.includes(pinNumber)) {
          if (pads[j] instanceof GCPolygon) {
            minX = Math.min(minX, Math.min.apply(null, pads[j].mXs));
            minY = Math.min(minY, Math.min.apply(null, pads[j].mYs));
            maxX = Math.max(maxX, Math.max.apply(null, pads[j].mXs));
            maxY = Math.max(maxY, Math.max.apply(null, pads[j].mYs));
          } else if (pads[j] instanceof CeCircle) {
            const centerX = pads[j].mCenter.getX();
            const centerY = pads[j].mCenter.getY();
            const r = pads[j].mDiameter / 2;
            minX = Math.min(minX, centerX - r);
            minY = Math.min(minY, centerY - r);
            maxX = Math.max(maxX, centerX + r);
            maxY = Math.max(maxY, centerY + r);
          } else console.log(pads[j])
        }
      }

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    const width = maxX - minX;
    const height = maxY - minY;
    const scaledWidth = width * size * widthSize;
    const scaledHeight = height * size * heightSize;
    minX = centerX - scaledWidth / 2;
    minY = centerY - scaledHeight / 2;
    maxX = centerX + scaledWidth / 2;
    maxY = centerY + scaledHeight / 2;

    let outline = new CePolygon();
    outline.AddVertex(minX, minY);
    outline.AddVertex(minX, maxY);
    outline.AddVertex(maxX, maxY);
    outline.AddVertex(maxX, minY);
    outline = outline.ConvertToGCPolygon();
    if (compList[i].mLocation)
      compList[i].mLocation.TransformGCPolygon(outline);
    this.point.set(compName, outline)
    this.pinOutline = new CanvasPath();
    this.pinOutline.pathData = outline.GetSvgPath();
  }
}

CompLayerRenderer.prototype.redrawPinPath = function (svgNode) {
  this.pinOutline.drawSVG(svgNode, {
    'class': 'layer_pin',
    'fill-opacity': 0
  });
  this.updateStrokeWidth()
}

CompLayerRenderer.prototype.getHiddenComps = function (hiddenComps) {
  hiddenComps.forEach((compName) => {
    this.ele = this.layerElement
      .selectAll(`g[comp="${compName}"]`)
      .attr('display', 'none')
  })
}

CompLayerRenderer.prototype.deleteHiddenComp = function (hiddenComp) {
  this.ele = this.layerElement
    .selectAll(`g[comp="${hiddenComp}"]`)
    .attr('display', 'unset')
}

CompLayerRenderer.prototype.setNotDisplayPin = function (displayPinList) {
  const comps = Object.keys(displayPinList);
  for (let comp of comps) {
    const find = this.compObjList.find(d => d.name === comp)
    if (!find) continue;
    const canvasArr = Object.keys(find.objList).filter(d => Array.isArray(find.objList[d]) && find.objList[d].length);
    const compPins = displayPinList[comp];
    if (compPins && compPins.length) {
      for (let canvasItem of canvasArr) {
        find.objList[canvasItem].forEach(findPin => {
          if (findPin && !compPins.includes(findPin.pinNumber)) {
            select(findPin.element)
              .classed('canvas-not-display-pin', true)
          }
        })
      }
    }
  }
}

function getCompColor(type) {
  switch (type) {
    case 'pullRes':
      return '#00ff82';
    case 'dampRes':
      return '#1ba0e0';
    default:
      return SELECTION_COLOR;
  }
}

export default CompLayerRenderer;