import {
  UPDATE_LIBRARY_MENU,
  UPDATE_PROJECT_MENU,
  EXPAND_MENU,
  PROJECT_MENU,
  LIBRARY_MENU,
  OPEN_PROJECT,
  SAVE_OPEN_PROJECT_INFO,
  UPDATE_TREE_SELECTED_KEYS,
  UPDATE_VIEW_LIST,
  OPEN_CHANNEL,
  CLEAR_CURRENT_PROJECT,
  SAVE_CURRENT_CHANNEL_INFO,
  OPEN_PAGE_INFO,
  UPDATE_PCBS_LAYOUT_DB,
  SAVE_COMPONENTS_NETS_IN_PROJECT,
  AUTO_GET_VERIFICATION_LIST,
  CHANGE_LAYOUT,
  UPDATE_TRASH_MENU,
  TRASH_MENU,
  CLEAN_CURRENT_CHANNEL,
  GET_REPORT,
  UPDATE_REPORT_PROGRESS,
  UPDATE_REPORT_VISIBLE,
  GET_REPORT_FILE,
  UPDATE_REPORT_CONFIG,
  SAVE_REPORT_CONFIG,
  CLEAR_REPORT_INFO,
  UPDATE_REPORT_MESSAGE,
  UPDATE_EXTRACTION_CONFIG,
  DEFAULT_CHANNEL_CONFIG,
  DEFAULT_EXTRACTION_CONFIG,
  SAVE_CHANNEL_EXTRACTION_CONFIG,
  UPDATE_CHANNEL_COMPONENT_LIST,
  UPDATE_SSN_CHECK,
  DEBUG_MONITOR,
  GET_TOUCHSTONE_FILE_STATUS,
  UPDATE_PROJECT_INTERFACES_INFO,
  UPDATE_MASK_LIBRARY_LIST,
  UPDATE_SHARE_MENU,
  SHARE_MENU,
  SAVE_PRE_LAYOUT_TEMPLATE,
  CLEAN_CHANNEL_BY_PROJECTID,
  REPLACE_PCB,
  UPDATE_EXPORT_PROGRESS,
  UPDATE_PCB_LAYOUT,
  OPEN_PACKAGE_TREE,
  UPDATE_PACKAGE_EXTRACTION_CONFIG,
  SAVE_PACKAGE_VERIFICATION_EXTRACTION_CONFIG,
  CLEAN_PACKAGE_CURRENT_CHANNEL,
  DELETE_CARD,
  UPDATE_CURRENT_PACKAGE_ID,
  UPDATE_CARD_LIBRARY_LIST,
  CARD_LIBRARY_RENAME,
  UPDATE_STACKUP_STATUS_LIST,
  UPDATE_CURRENT_LOADING_PCB,
  UPDATE_WAVEFORM_CONFIG,
  SAVE_WAVEFORM_CONFIG,
  SET_DEFAULT_WAVEFORM_CONFIG,
  SAVE_WIRE_BOND_PROFILE,
  OPEN_SSN_CHANNEL,
  GET_LIBRARY_FOLDER_CHILDREN,
  CLEAR_PCB_RELATED_DATA,
  EDIT_PROJECT_NAME,
  LIBRARY_DEFAULT,
  UPDATA_DEFAULT_DECAP,
  INIT_SYS_LIBRARY,
  UPDATE_SYS_LIBRARY,
} from './actionTypes';

export const updateLibraryMenu = (isRetrieveFileInFolder) => ({
  type: UPDATE_LIBRARY_MENU,
  isRetrieveFileInFolder
});

export const updateProjectMenu = (obj) => ({
  type: UPDATE_PROJECT_MENU,
  obj
});

export const updateTrashMenu = () => ({
  type: UPDATE_TRASH_MENU
});

export const trashMenu = ({ treeItems }) => ({
  type: TRASH_MENU,
  treeItems
});

export const expandMenu = (expandedKeys) => ({
  type: EXPAND_MENU,
  expandedKeys
});

export const projectMenu = ({ treeItems, projectList, packageLayoutList }) => ({
  type: PROJECT_MENU,
  treeItems,
  projectList,
  packageLayoutList
});

export const libraryMenu = ({ treeItems, ibisList, spiceList, vectorList, VRMList, pkgTouchstoneList, pkgSpiceList, eyeMaskList, traceList, viaList, ebdList, socketTouchstoneList, socketSpiceList, cardLibraryList, customTouchstoneList, customSpiceList, wireBondProfileList, decapTouchstoneList, decapSpiceList, extractionHfssOptionsList,
  extractionSiwaveOptionsList, pcbTouchstoneList, pcbSpiceList, onDieTouchstoneList, pdnTouchstoneList, onDieSpiceList, pdnSpiceList, patternLibraryList, specificSpiceLibraryList, userDefinedNetListList, DecapGeneric, customPostProcessList, ibisAmiList }) => ({
    type: LIBRARY_MENU,
    treeItems,
    ibisList,
    spiceList,
    vectorList,
    VRMList,
    pkgTouchstoneList,
    pkgSpiceList,
    eyeMaskList,
    traceList,
    viaList,
    ebdList,
    socketTouchstoneList,
    socketSpiceList,
    cardLibraryList,
    customTouchstoneList,
    customSpiceList,
    wireBondProfileList,
    decapTouchstoneList,
    decapSpiceList,
    extractionHfssOptionsList,
    extractionSiwaveOptionsList,
    pcbTouchstoneList,
    pcbSpiceList,
    onDieTouchstoneList,
    pdnTouchstoneList,
    onDieSpiceList,
    pdnSpiceList,
    patternLibraryList,
    specificSpiceLibraryList,
    userDefinedNetListList,
    DecapGeneric,
    customPostProcessList,
    ibisAmiList
  });

export const openProject = ({ id, afterTranslation, key, isUploadPackage, load }) => ({
  type: OPEN_PROJECT,
  id,
  key, // PROJECT
  afterTranslation,
  isUploadPackage,
  load
});

export const saveOpenProjectInfo = ({ id, name, version }) => ({
  type: SAVE_OPEN_PROJECT_INFO,
  id,
  name,
  version,
});

export const updateTreeSelectedKeys = (selectedKeys) => ({
  type: UPDATE_TREE_SELECTED_KEYS,
  selectedKeys
});

export const updateViewList = (viewList, viewType) => ({
  type: UPDATE_VIEW_LIST,
  viewList,
  viewType
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  layout
});

export const openChannel = (channelId, key, isResultPage) => ({
  type: OPEN_CHANNEL,
  channelId,
  key,
  isResultPage
});

export const clearCurrentProject = (isBMA) => ({
  type: CLEAR_CURRENT_PROJECT,
  isBMA
});

export const clearCurrentChannel = () => ({
  type: CLEAN_CURRENT_CHANNEL
})

export const saveCurrentChannelInfo = ({ currentChannelId }) => ({
  type: SAVE_CURRENT_CHANNEL_INFO,
  currentChannelId
});

export const openPageInfo = (info) => ({
  type: OPEN_PAGE_INFO,
  info
});

export const updatePCBComponentsNets = (pcbsLayoutDB) => ({
  type: UPDATE_PCBS_LAYOUT_DB,
  pcbsLayoutDB
});

export const saveComponentsNetsInProject = (pcbIds) => ({
  type: SAVE_COMPONENTS_NETS_IN_PROJECT,
  pcbIds
});

export const autoGetVerificationList = ({ projectId, channelId, currentSimInfo, currentPkgDesignId, verificationType, isUpdateDiagram }) => ({
  type: AUTO_GET_VERIFICATION_LIST,
  projectId,
  channelId,
  currentSimInfo,
  currentPkgDesignId,
  verificationType,
  isUpdateDiagram
})

export const getReport = ({ projectId, format, mime, fileName }) => ({
  type: GET_REPORT,
  projectId,
  format,
  mime,
  fileName
});

export const updateReportProgress = (progress) => ({
  type: UPDATE_REPORT_PROGRESS,
  progress
});

export const updateReportVisible = (visible) => ({
  type: UPDATE_REPORT_VISIBLE,
  visible
});

export const getReportFile = ({ projectId, format, mime, fileName }) => ({
  type: GET_REPORT_FILE,
  projectId,
  format,
  mime,
  fileName
});

export const updateReportConfig = (reportConfig) => ({
  type: UPDATE_REPORT_CONFIG,
  reportConfig
});

export const saveReportConfig = () => ({
  type: SAVE_REPORT_CONFIG
});

export const clearReportInfo = () => ({
  type: CLEAR_REPORT_INFO
});

export const updateReportMessage = (message) => ({
  type: UPDATE_REPORT_MESSAGE,
  message
});

export const defaultChannelConfig = (channelId, componentList) => ({
  type: DEFAULT_CHANNEL_CONFIG,
  channelId,
  componentList
});

/* === EXTRACTION Config */
export const updateExtractionConfig = (extraction, isOpenExtractionPanel) => ({
  type: UPDATE_EXTRACTION_CONFIG,
  extraction,
  isOpenExtractionPanel
});

export const defaultExtractionConfig = (channelId, xTalk, isCard) => ({
  type: DEFAULT_EXTRACTION_CONFIG,
  channelId,
  xTalk,
  isCard
});

export const saveChannelExtractionConfig = (extraction, prevExtraction, apply) => ({
  type: SAVE_CHANNEL_EXTRACTION_CONFIG,
  extraction,
  prevExtraction,
  apply
});

export const updateChannelComponentList = (componentList) => ({
  type: UPDATE_CHANNEL_COMPONENT_LIST,
  componentList
});

export const updateSSNCheck = (ssnSelectPDNIds) => ({
  type: UPDATE_SSN_CHECK,
  ssnSelectPDNIds
});

export const debugMonitorAction = (msg) => ({
  type: DEBUG_MONITOR,
  msg
});

export const getTouchstoneFileStatus = (runningFileList) => ({
  type: GET_TOUCHSTONE_FILE_STATUS,
  runningFileList
});

export const updateProjectInterfacesInfo = (currentProjectInterfaceInfo) => ({
  type: UPDATE_PROJECT_INTERFACES_INFO,
  currentProjectInterfaceInfo
});

export const updateMaskLibraryList = () => ({
  type: UPDATE_MASK_LIBRARY_LIST,
});

export const updateShareMenu = () => ({
  type: UPDATE_SHARE_MENU,
});

export const shareMenu = ({ treeItems }) => ({
  type: SHARE_MENU,
  treeItems
});

export const savePreLayoutTemplate = ({ dataType, data }) => ({
  type: SAVE_PRE_LAYOUT_TEMPLATE,
  dataType,
  data
});

export const clearChannelByProjectId = (projectId) => ({
  type: CLEAN_CHANNEL_BY_PROJECTID,
  projectId
});

export const replacePCB = (designId) => ({
  type: REPLACE_PCB,
  designId
});

export const updateExportProgress = (progress) => ({
  type: UPDATE_EXPORT_PROGRESS,
  progress
})

export const updatePCBLayout = (pcbLayout, prePcbLayout) => ({
  type: UPDATE_PCB_LAYOUT,
  pcbLayout,
  prePcbLayout
});

export const openPackageById = (packageDesignId, key) => ({
  type: OPEN_PACKAGE_TREE,
  packageDesignId,
  key
});

export const updatePackageExtractionConfig = (packageExtraction) => ({
  type: UPDATE_PACKAGE_EXTRACTION_CONFIG,
  packageExtraction
})

export const savePackageVerificationExtractionConfig = ({ extractionConfig, prevExtraction }) => ({
  type: SAVE_PACKAGE_VERIFICATION_EXTRACTION_CONFIG,
  extractionConfig,
  prevExtraction
})

export const cleanPackageCurrentChannel = () => ({
  type: CLEAN_PACKAGE_CURRENT_CHANNEL
});

export const deleteCard = (itemData) => ({
  type: DELETE_CARD,
  itemData
})

export const updateCurrentPackageId = (id) => ({
  type: UPDATE_CURRENT_PACKAGE_ID,
  id
});

export const updateCardLibraryList = (libraryObj) => ({
  type: UPDATE_CARD_LIBRARY_LIST,
  libraryObj
});

export const cardLibraryRename = (data) => ({
  type: CARD_LIBRARY_RENAME,
  data
});

export const updateStackupStatusList = (stackupStatusList) => ({
  type: UPDATE_STACKUP_STATUS_LIST,
  stackupStatusList
});

export const updateCurrentLoadingPCBs = (currentLoadingPCBs) => ({
  type: UPDATE_CURRENT_LOADING_PCB,
  currentLoadingPCBs
});

export const updateWaveformConfig = (waveformConfig) => ({
  type: UPDATE_WAVEFORM_CONFIG,
  waveformConfig
});

export const saveWaveformConfig = (channelId, waveformConfig) => ({
  type: SAVE_WAVEFORM_CONFIG,
  channelId,
  waveformConfig
});

export const defaultWaveformConfig = (channelId, ddrType, isPrevCase) => ({
  type: SET_DEFAULT_WAVEFORM_CONFIG,
  channelId,
  ddrType,
  isPrevCase
});

export const saveWireBondProfile = (libraryId, designId, oldLibraryId) => ({
  type: SAVE_WIRE_BOND_PROFILE,
  libraryId,
  designId,
  oldLibraryId
})

export const openSSNChannel = (id, key) => ({
  type: OPEN_SSN_CHANNEL,
  id,
  key
});

export const getSpiceFolderChildren = (folderInfo) => ({
  type: GET_LIBRARY_FOLDER_CHILDREN,
  folderInfo
})

export const clearPCBRelatedData = () => ({
  type: CLEAR_PCB_RELATED_DATA
});

export const editProjectName = (name, itemData) => ({
  type: EDIT_PROJECT_NAME,
  name,
  itemData
})

export const libraryDefault = (info) => ({
  type: LIBRARY_DEFAULT,
  info
})

export const updateDefaultDecap = (defaultDecap) => ({
  type: UPDATA_DEFAULT_DECAP,
  defaultDecap
})

export const initSystemLibrary = () => ({
  type: INIT_SYS_LIBRARY
})

export const updateSystemLibrary = (folderId, path) => ({
  type: UPDATE_SYS_LIBRARY,
  folderId,
  path
})