var CeGeomType = {
  geom_Arc: "Arc",
  geom_Box2d: "Box2d",
  geom_Circle: "Circle",
  geom_Diamond: "Diamond",
  geom_DonutRound: "DonutRound",
  geom_DonutSqrRnd: "DonutSqrRnd",
  geom_DonutSquare: "DonutSquare",
  geom_Larc: "Larc",
  geom_HalfOval: "HalfOval",
  geom_Line: "Line",
  geom_Lines: "Lines",
  geom_Octagon: "Octagon",
  geom_Oval: "Oval",
  geom_Polygon: "Polygon",
  geom_PolygonWithArc: "PolygonWithArc",
  geom_PolygonHole: "PolygonHole",
  geom_RectCutCorner: "RectCutCorner",
  geom_Rectangle: "Rectangle",
  geom_RoundedRectangle: "Rounderrectangle",
  geom_Square: "Square",
  geom_Surface: "Surface",
  geom_Pnt: "Pnt",
  geom_Pnts: "Pnts",
  geom_Parc: "Parc",
  geom_Text: "Text",
  geom_Triangle: "Triangle",
  geom_Unknown: "Unknown"
};

const CeGeometry = function (type) {
  this.mGeomType = type;
}

CeGeometry.prototype.SetGeomID = function (id) {
  this.mGeomID = id;
}

CeGeometry.prototype.GetGeomID = function () {
  return this.mGeomID;
}

CeGeometry.prototype.SetGeomType = function (type) {
  this.mGeomType = type;
}

CeGeometry.prototype.GetGeomType = function () {
  return this.mGeomType;
}

CeGeometry.prototype.CopyFrom = function (fromObj) {
  this.mGeomType = fromObj.mGeomType;
  this.mGeomID = fromObj.mGeomID;
}

CeGeometry.prototype.ReadFromIODataNode = function (fileItem) {
  console.log("Need implementing ReadFromIODataNode in " + this.mGeomType);
  return false;
}

export default CeGeometry;