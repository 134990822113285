import {
  UPDATE_PRE_LAYOUT_CONTENT,
  UPDATE_PRE_LAYOUT_INFO,
  CLEAR_PRE_LAYOUT_CONTENT,
  UPDATE_SIGNALS_COLUMNS,
  FIRST_OPEN_PRE_LAYOUT_SETTING
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

// preLayoutInfo: {
//   components: [],
//   signal_groups: [],
//   prelayout: "", // Schematic / Modle
//   type: "",// type -> PCIe / ...
//   width: "", // type PCIe => width -> x1, x2, x4, ...
//   unit: "",
//   selectedGroups: [],
//   maxSectionLength: 1,
//   diffPairsNumber: ""
//   lanesNumber: 1/2/3...
// }
const defaultState = {
  info: {} // { id: { preLayoutInfo } }
}

function updateInfo(prevInfo, preLayoutId, value) {
  let deepCopy = JSON.parse(JSON.stringify(prevInfo));
  const prev = deepCopy[preLayoutId];
  if (prev) {
    deepCopy[preLayoutId].preLayoutInfo = { ...deepCopy[preLayoutId].preLayoutInfo, ...value };
  } else {
    deepCopy[preLayoutId] = {
      preLayoutInfo: value
    }
  }
  return deepCopy;
}

function update(prevInfo, preLayoutId, key, value) {
  let deepCopy = JSON.parse(JSON.stringify(prevInfo));
  const prev = deepCopy[preLayoutId];
  if (prev) {
    deepCopy[preLayoutId][key] = value
  } else {
    deepCopy[preLayoutId] = {
      [key]: value
    }
  }
  return deepCopy;
}

export const AndesPreLayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PRE_LAYOUT_CONTENT:
      return {
        ...state,
        info: updateInfo(state.info, action.preLayoutId, action.preLayoutInfo)
      }
    case UPDATE_PRE_LAYOUT_INFO:
      return {
        ...state,
        info: updateInfo(state.info, action.preLayoutId, action.info)
      }
    case CLEAR_PRE_LAYOUT_CONTENT:
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_SIGNALS_COLUMNS:
      return {
        ...state,
        info: update(state.info, action.preLayoutId, 'signalColumnsUpdateStatus', action.status)
      }
    case FIRST_OPEN_PRE_LAYOUT_SETTING:
      return {
        ...state,
        info: update(state.info, action.preLayoutId, 'firstOpenSettingStatus', action.status)
      }
    default: return state;
  }
};