
import http from '../http';
/**
 * 
 *
 * @export
 * @param {string} ssnChannelId
 * @returns
 */
export function getSSNCentricContentByID(ssnChannelId) {
  return http.get(`/rocky/ssn/channel/diagram?channelId=${ssnChannelId}`);
};

/**
 * 
 *
 * @export
 * @param {string} ssnChannelId
 * @returns
 */
export function updateSSNCentricContentByID(content) {
  return http.post(`/rocky/ssn/channel/update`, content);
};

export function getSSNWaveform({ displayMode, type, verificationId }) {
  return http.get(`/verification/pdn/result/waveform/file?displayMode=${displayMode}&type=${type}&verificationId=${verificationId}`)
}

/**
 * 
 * update interface buffer model by csm pairs
 * @export
 * @param {string} ssnChannelId
 * @returns
 */
export function updateSSNBufferModelByCSMModel(ssnChannelId) {
  return http.get(`rocky/ssn/channel/csm/update/interface/buffer-model?channelId=${ssnChannelId}`)
}

export function getPTSWSignalsStatus(channelId) {
  return http.get(`/rocky/ssn/channel/result/VictimNetStatus?channelId=${channelId}`)
}

export function getPTSWMask({ channelId, victimNet, fileName }) {
  return http.get(`/rocky/ssn/channel/result/eye/models?channelId=${channelId}&victimNet=${victimNet}&fileName=${fileName}`)
}