import { PCB } from '../../../../constants/treeConstants';
import {
  UPDATE_PRE_LAYOUT,
  OPEN_PRE_LAYOUT,
  LOADING_TYPE,
  UPDATE_LOACTIONS,
  FINISH_DRAW,
  CLK_SECTION_LENGTH,
  UPDATE_LAYER,
  CHANGE_AUTO_FILL,
  CLEAR_PRE_LAYOUT,
  PRE_LAYOUT_INIT_STATUS,
  CHANGE_LIBRARY_FILE,
  PRELAYOUT_LOG,
  PRELAYOUT_ERROR,
  UPDATE_MODEL_PRELAYOUT_CONTENT,
  SCHEMATIC_LOADING,
  SAVE_MODEL_PRELAYOUT_INFO
} from './actionTypes';

const defaultState = {
  id: '',
  projectId: '',
  content: null,
  pcbType: PCB,
  designVersion: '',
  version: '',
  preLayoutLoading: false,
  placement: {
    width: "750",
    height: "540",
    memorys: [],
    controller: {},
    draw: false
  },
  CLKTableSection: [],
  componentLayer: {
    Controller: 'Top',
    Memory: 'Top'
  },
  autoFillStatus: false,
  first: false,
  libraryStatus: false,
  prelayoutLog: [],
  prelayoutError: '',
  schematicLoading: false,
  verificationId: "",
  channelId: "",
  name:""
}

export const RockyPreLayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_PRE_LAYOUT:
      return {
        ...state,
        projectId: action.projectId,
        id: action.id,
        content: action.preLayout,
        pcbName: action.pcbName,
        designVersion: action.designVersion,
        version: action.version,
        preLayoutLoading: false,
        first: action.first,
        pcbType: action.pcbType,
        verificationId: action.verificationId,
        channelId: action.channelId,
        name: action.name
      }
    case UPDATE_PRE_LAYOUT:
    case UPDATE_MODEL_PRELAYOUT_CONTENT:
      return {
        ...state,
        content: action.content
      }
    case LOADING_TYPE:
      return {
        ...state,
        preLayoutLoading: action.loading
      }
    case UPDATE_LOACTIONS:
      return {
        ...state,
        placement: action.locations
      }
    case FINISH_DRAW:
      return {
        ...state,
        placement: {
          ...state.placement,
          draw: false
        }
      }
    case CLK_SECTION_LENGTH:
      return {
        ...state,
        CLKTableSection: [...action.partLength]
      }
    case UPDATE_LAYER:
      return {
        ...state,
        componentLayer: action.layer
      }
    case CHANGE_AUTO_FILL:
      return {
        ...state,
        autoFillStatus: action.status
      }
    case CLEAR_PRE_LAYOUT:
      return {
        ...defaultState
      }
    case PRE_LAYOUT_INIT_STATUS:
      return {
        ...state,
        first: false
      }
    case CHANGE_LIBRARY_FILE:
      return {
        ...state,
        libraryStatus: action.status
      }
    case PRELAYOUT_LOG:
      return {
        ...state,
        prelayoutLog: action.log
      }
    case PRELAYOUT_ERROR:
      return {
        ...state,
        prelayoutError: action.errorMsg
      }
    case SCHEMATIC_LOADING:
      return {
        ...state,
        schematicLoading: action.boolean
      }
    case SAVE_MODEL_PRELAYOUT_INFO:
      return {
        ...state,
        content: {
          ...state.content,
          ...action.info
        }
      }
    default: return state
  }
};