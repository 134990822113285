import { Select, Checkbox, Input } from "antd";
import React, { Component, Fragment } from "react";
import { CIRCUIT, NEARBY_PINS, nearbyPinsSetup, REFERENCE_TYPE_LIST, WAVE } from "../../services/ExtractionPortsHelper";
import UnitAddonAfter from "../UnitAddonAfter";
import { unitChange } from "../../services/helper/mathHelper";
import { numberCheck } from "../../services/helper/dataProcess";
import '@/publicCss/aurora.css';

const Option = Select.Option;
const units = ["mm", "um", "mil"];
export default function referencePinGroupRender({
  compSetup = {},
  compInfo = {},
  extractionType,
  refTypeChange,
  checkChange,
  addonAfter,
  inputOnBlur,
  changeInputValue
}) {
  return <Fragment>
    {![CIRCUIT, WAVE].includes(compSetup.portType) ? getSelectComponent({
      label: "Reference Pin Grouping",
      value: compSetup.referenceType,
      changeFn: (key) => refTypeChange(key, compInfo.component),
      options: REFERENCE_TYPE_LIST,
      type: "referenceType",
      portType: compSetup.portType,
      extractionType
    }) : null}
    {compSetup.referenceType === NEARBY_PINS && <Checkbox.Group
      className="signal-port-edit-item-checkbox"
      onChange={(keys) => checkChange(keys, compInfo.component)}
      value={compSetup.filterPinOptions}
    >
      {getCheckBox({ compSetup, compName: compInfo.component, addonAfter, inputOnBlur, changeInputValue })}

    </Checkbox.Group>}
  </Fragment>

}
function getSelectComponent({
  label,
  value,
  changeFn,
  options,
  portType,
  multiple
}) {
  return (
    <div className='signal-port-edit-item'>
      <label>{label}</label>
      <Select
        className='aurora-select'
        value={value}
        showSearch
        mode={multiple}
        onChange={(key) => changeFn(key)}
        popupMatchSelectWidth={false}
        popupClassName='aurora-select-dropdown'
      >
        {options.map(item => {
          if (item.disabled && item.disabled === portType) {
            return null;
          } else if (typeof (item) === "string") {
            return <Option
              key={item}
              value={item}
              title={item}
            >{item}</Option>
          } else {
            return <Option
              key={item.key}
              value={item.key}
              title={item.name}
            >{item.name}</Option>
          }
        })}
      </Select>
    </div>
  );
}
function getCheckBox({
  compSetup,
  compName,
  addonAfter,
  inputOnBlur,
  changeInputValue,
}) {
  return <Fragment><div className='signal-port-edit-item extraction-ports-auto-checkbox-main extraction-ports-auto-checkbox-1'>
    <Checkbox
      value="number"
      key="number"
      className='extraction-ports-sub-checkbox'
    >Max number of pins</Checkbox>
    {getInputComponent({
      value: compSetup.pinNumber,
      disabled: !compSetup.filterPinOptions.includes("number"),
      type: "pinNumber",
      typeText: "Max number of pins",
      component: compName,
      addonAfter,
      inputOnBlur,
      changeInputValue,
    })}
  </div>
    <div className='signal-port-edit-item extraction-ports-auto-checkbox-main'>
      <Checkbox
        value="distance"
        key="distance"
        className='extraction-ports-sub-checkbox'
      >Reference pins within</Checkbox>
      {getInputComponent({
        value: compSetup.pinDistance,
        disabled: !compSetup.filterPinOptions.includes("distance"),
        type: "pinDistance",
        typeText: "Reference pins distance",
        className: "extraction-ports-sub-right-input",
        unit: compSetup.pinDistanceUnit,
        unitOptions: units,
        component: compName,
        addonAfter,
        inputOnBlur,
        changeInputValue,
      })}
    </div>
  </Fragment>
}

export function getInputComponent({
  value,
  disabled,
  type,
  typeText,
  unit,
  unitOptions,
  className,
  component,
  inputOnBlur,
  changeInputValue,
  addonAfter
}) {
  return <Input
    className={`aurora-input ${className}`}
    disabled={disabled || false}
    value={value}
    onBlur={(e) => inputOnBlur(e, type, typeText, component)}
    onChange={(e) => changeInputValue(e, type, component)}
    addonAfter={unitOptions ? addonAfter({ unit, key: type, disabled, unitOptions, component }) : (unit || null)}
  />
}

export class UserSettingPortSetupRender extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setupStateInfo: {}
    }
  }

  refTypeChange = (key) => {
    const { portSetupInfo } = this.props;
    let setupInfo = { ...(portSetupInfo || {}) };
    setupInfo.referenceType = key;
    if (key === NEARBY_PINS) {
      setupInfo = { ...setupInfo, ...nearbyPinsSetup(setupInfo) };
    } else {
      delete setupInfo.pinDistance;
      delete setupInfo.pinDistanceUnit;
      delete setupInfo.filterPinOptions;
      delete setupInfo.pinNumber;
    }
    this.props.updatePortSetupInfo(setupInfo);
  }

  checkChange = (checkedKeys) => {
    const { portSetupInfo } = this.props;
    const { errorMsg } = this.state;
    let setupInfo = { ...(portSetupInfo || {}) };
    setupInfo.filterPinOptions = checkedKeys;
    this.setState({
      errorMsg: errorMsg && errorMsg.type === "filterPinOptions" ? null : errorMsg
    });

    this.props.updatePortSetupInfo(setupInfo);
  }

  addonAfter = ({ unit, key, disabled, unitOptions, component }) => {
    return UnitAddonAfter({
      unit,
      disabled,
      changeUnit: (e) => this.changeUnit(e, key, component),
      list: unitOptions,
      className: "extraction-ports-unit-select"
    })
  }

  changeUnit = (unit, key) => {
    const { portSetupInfo } = this.props;
    let setupInfo = { ...(portSetupInfo || {}) };

    if (key === "pinDistance") {
      setupInfo[key] = unitChange({
        num: setupInfo[key],
        oldUnit: setupInfo[`${key}Unit`],
        newUnit: unit,
        decimals: 3
      }).number;
    }

    setupInfo[`${key}Unit`] = unit;
    this.props.updatePortSetupInfo(setupInfo);
  }

  inputOnBlur = (e, type, typeText) => {
    const value = e.target.value;
    const error = numberCheck(value);
    const { portSetupInfo } = this.props;
    let setupInfo = { ...(portSetupInfo || {}) };
    if (error) {
      this.setState({
        errorMsg: {
          type,
          error: `${typeText} ${error}`
        }
      })
    } else {
      setupInfo[type] = value;
      this.props.updatePortSetupInfo(setupInfo);
    }
  }


  changeInputValue = (e, type) => {
    const { setupStateInfo } = this.props;
    const { errorMsg } = this.state;
    let setupInfo = { ...(setupStateInfo || {}) };

    setupInfo[type] = e.target.value;
    this.setState({
      setupStateInfo: setupInfo,
      errorMsg: errorMsg && errorMsg.type === type ? null : errorMsg
    });
  }

  render = () => {
    const { setupStateInfo, errorMsg } = this.state;
    const { portSetupInfo, portType } = this.props;
    return <div className='user-default-setting-ports-content'>
      {referencePinGroupRender({
        compSetup: { portType, ...(portSetupInfo || {}), ...setupStateInfo },
        refTypeChange: this.refTypeChange,
        checkChange: this.checkChange,
        addonAfter: this.addonAfter,
        inputOnBlur: this.inputOnBlur,
        changeInputValue: this.changeInputValue
      })}
      {errorMsg && errorMsg.error ? <div className="aurora-model-name-error-msg">{errorMsg.error}</div> : null}
    </div>
  }
}