import {
  MY_LIBRARY,
  LIBRARY,
  PROJECTS,
  TRASH,
  LIBRARY_OPEN
} from '../../../constants/treeConstants';
import {
  SPICE_PARAMETERS,
  SOC_BUFFER,
  SOC_SPICE,
  SOC_WRAPPER,
  PCB_SUBCKT,
  PVT,
  SPICE_PARAMETERS_LIB
} from '../../../constants/libraryConstants';

function treeItem({ name, key, dataType, ...restProps }) {
  this.name = name;
  this.key = key;
  this.dataType = dataType;
  this.children = [];
  Object.keys(restProps).map(key => this[key] = restProps[key])
};

function getDefaultTreeItems() {
  return [
    {
      name: 'Library',
      key: LIBRARY,
      dataType: MY_LIBRARY,
      nodeClass: 'expand-icon-large',
      children: [
        {
          name: "SoC Buffer",
          key: SOC_BUFFER,
          dataType: LIBRARY_OPEN,
          nodeClass: 'expand-icon-middle',
          children: [
            { name: 'Spice Netlist', key: SOC_SPICE },
            { name: 'Wrapper', key: SOC_WRAPPER },
            {
              name: 'Spice Parameters',
              key: SPICE_PARAMETERS,
              children: [
                { ...new treeItem({ name: 'Lib', key: SPICE_PARAMETERS_LIB, dataType: LIBRARY }) }
              ]
            },
            { name: 'PVT', key: PVT, }
          ].map(d => new treeItem({ name: d.name, key: d.key, dataType: LIBRARY, children: d.children || undefined }))
        },
        {
          name: 'PCB Model',
          key: PCB_SUBCKT,
          dataType: LIBRARY,
          nodeClass: 'expand-icon-middle',
          children: []
        }
      ]
    },
    {
      name: 'Project',
      key: PROJECTS,
      dataType: PROJECTS,
      nodeClass: 'expand-icon-large',
      children: []
    },
    {
      name: 'Trash',
      key: TRASH,
      nodeClass: 'expand-icon-large',
      dataType: TRASH,
      children: []
    }]
}

const defaultTreeItems = getDefaultTreeItems();

export {
  treeItem,
  defaultTreeItems
}
