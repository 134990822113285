import { COMP_REPEATER, IGNORE } from "../../constants/componentType";
import LayoutData from "../data/LayoutData";
import { getDefaultIndex } from "../helper/setDefaultName";
import { getConnectedPin, getDefaultRepeaterPinMap } from "./compPinMapHelper";
import { checkCompsType, getComponentByLayer, RLC_TYPES, CMC, getComponentNeedInfoByName, JUMPER } from "./components";
import { PCIE, INTERFACE_TYPES } from "./constants";
import { getCMCPinConnections, getConnectedPinNumber, isPowerGND } from "./net";
import _ from 'lodash';

const all_CONNECTOR_USAGE = [...RLC_TYPES, JUMPER]

class RelateNetsGroup {
  constructor() {
    this.groups = new Map();
    this.compPinsNets = {};
  }

  setGroups = ({ netList, designId, setupComps, type, updatedCMCComponents }) => {
    this._LayoutData = LayoutData.getLayout(designId);
    this.setCompPinsNets(designId);

    for (let net of netList) {
      this.groups.set(net.mName, this.getRelatedNetsByNet({
        net,
        designId,
        setupComps,
        type,
        updatedCMCComponents
      }));
    }
  }

  getGroups = (nets) => {
    if (nets) {
      return Array.from(this.groups.values()).filter(item => nets.includes(item.net));
    }
    return Array.from(this.groups.values());
  }

  getRelatedNetsByNet = ({
    net,
    designId,
    setupComps,
    pinMap,
    type,
    filterInterfaceNetRule,
    currentPcbSetting = {},
    updatedCMCComponents,
    checkCompsTypeFunction,
    isPowerGNDFn,
    isMulti = false
  }) => {
    const _isPowerGND = isPowerGNDFn || isPowerGND;
    this.isMulti = isMulti;
    const relateNets = new RelateNets({ isPowerGNDFn: _isPowerGND, isMulti });

    if (!this._LayoutData) {
      this._LayoutData = LayoutData.getLayout(designId);
    }
    if (!Object.keys(this.compPinsNets).length) {
      this.setCompPinsNets(designId);
    }

    const netInfo = this._LayoutData.mNetManager.GetNetFromName(net.mName);
    if (_isPowerGND(netInfo, this._LayoutData.mSymbolMgr, designId) && (!filterInterfaceNetRule || (filterInterfaceNetRule && filterInterfaceNetRule(netInfo, this._LayoutData.mSymbolMgr)))) {
      return this.isMulti ? [] : {};
    }
    //get related nets
    const netsInfoMap = relateNets.getNets({
      netInfo,
      compPinsNets: this.compPinsNets,
      setupComps,
      designId,
      pinMap,
      type,
      symbol: this._LayoutData.mSymbolMgr,
      filterInterfaceNetRule,
      currentPcbSetting,
      updatedCMCComponents,
      checkCompsTypeFunction
    });
    let relatedList = [];
    if (!netsInfoMap.relateMap) {
      return this.isMulti ? [] : {}
    }

    for (let netsInfo of Array.from(netsInfoMap.relateMap.values())) {
      const _relatedNets = [...Array.from(netsInfo.netsList.keys())];

      const targetComps = netsInfoMap.getCompListByKey("targetComponents", netsInfo);
      relatedList.push({
        net: net.mName,
        allNets: [...new Set([net.mName, ..._relatedNets])],
        relatedNets: _relatedNets,
        targetComponents: [...targetComps],
        rlcComponents: netsInfoMap.getCompListByKey("rlcComponents", netsInfo),
        rlcCompNets: netsInfoMap.getCompListByKey("rlcCompNets", netsInfo),
        cmcComponents: netsInfoMap.getCompListByKey("cmcComponents", netsInfo),
        repeaterComponents: netsInfoMap.getCompListByKey("repeaterComponents", netsInfo),
        netTargetComponents: [...netsInfo.netTargetComponents || [], ...targetComps]
      });
    }
    if (isMulti) {
      return relatedList;
    }
    return relatedList[0] || {};
  }

  setCompPinsNets = (designId) => {
    this.compPinsNets = LayoutData.getCompPinsNets(designId);
  }
}

export default RelateNetsGroup;

class RelateNets {
  constructor(props = {}) {
    this.isPowerGND = props.isPowerGNDFn || isPowerGND;
    this.isMulti = props.isMulti || false
  }

  getNets = ({ netInfo, compPinsNets, setupComps, designId, pinMap, type, symbol, filterInterfaceNetRule, currentPcbSetting, updatedCMCComponents, checkCompsTypeFunction }) => {
    this.compPinsNets = compPinsNets;
    this.pinMap = pinMap;
    this.type = type;
    this.designId = designId;
    this.setupComps = setupComps || [];
    this.symbol = symbol;
    this.filterInterfaceNetRule = filterInterfaceNetRule
    this.currentPcbSetting = currentPcbSetting;
    this.updatedCMCComponents = updatedCMCComponents;
    this.checkCompsTypeFunction = checkCompsTypeFunction;
    this.relateMap = new Map();
    this.relateMap.set("group_1", { ...new RelatedItem({}) });
    this.setRelatedNets(netInfo, "group_1");
    return this;
  }

  getCompListByKey = (key, _this) => {
    let list = [];
    for (let net of Array.from(_this.netsList.values())) {
      net[key] && list.push(...net[key]);
    }
    return [...new Set(list)];
  }

  setRelatedNets = (netInfo, groupKey) => {
    if (this.isPowerGND(netInfo, this.symbol, this.designId) && (!this.filterInterfaceNetRule || (this.filterInterfaceNetRule && this.filterInterfaceNetRule(netInfo, this.symbol)))) {
      return;
    }
    const _this = this.relateMap.get(groupKey) || {};
    const pinList = netInfo ? netInfo.mPinList : [];
    const { compPrefixLib, doNotStuff = [], compPinMap = {}, passiveTable = [] } = this.currentPcbSetting;
    let repeaterPinList = []
    for (const { mCompName, mPinNum, mLayerName } of pinList) {
      const findComp = this.setupComps.find(it => it.name === mCompName);
      const { pinLength, partNumber, pinList: compPinList } = getComponentNeedInfoByName({ designID: this.designId, compName: mCompName })
      const rlcTypes = this.checkCompsTypeFunction ? all_CONNECTOR_USAGE : RLC_TYPES;
      let compType = IGNORE;

      const findPassive = (passiveTable || []).find(item => item.part === partNumber);
      if (findPassive && findPassive.type) {
        compType = (findPassive.name && findPassive.name.includes(findPassive.mCompName)) ? findPassive.type : IGNORE;
      } else {
        compType = this.getRelatedCompType({ mCompName, pinLength, partNumber, compPrefixLib, compPinMap });
      }
      //rlc find
      if ((!findComp || rlcTypes.includes(findComp.type))
        && rlcTypes.includes(compType)) {
        if (doNotStuff.includes(mCompName)) {
          _this.netTargetComponents.push(mCompName);
        } else {
          this.setRLCRelateNets({
            mCompName,
            mLayerName,
            mPinNum,
            pinLength,
            _this
          });
        }
      } else if (compType === COMP_REPEATER) {
        repeaterPinList.push({
          mCompName,
          findComp,
          mLayerName,
          mPinNum,
          compPinMap,
          partNumber,
          compPinList,
          pinLength,
        })
        /*    this.setRepeaterRelateNets({
             mCompName,
             findComp,
             mLayerName,
             mPinNum,
             compPinMap,
             partNumber,
             compPinList,
             pinLength,
             _this
           }) */

      } else if (![...INTERFACE_TYPES, PCIE].includes(this.type)) {
        this.setCMCRelateNets({
          mCompName,
          findComp,
          mLayerName,
          mPinNum,
          _this
        })
      } else {
        _this.targetComponents.push(mCompName);
        _this.netTargetComponents.push(mCompName);
      }
    }

    if (repeaterPinList.length) {
      for (let repeaterItem of repeaterPinList) {
        this.setRepeaterRelateNets({
          ...repeaterItem,
          _this
        })
      }
    }

    for (let key of Array.from(this.relateMap.keys())) {
      const netInfo = this.relateMap.get(key) || {};
      const newRelatedNets = [...new Set(netInfo.relatedNets.filter(item => !netInfo.netsList.has(item.mName) && item.mName !== netInfo.mName && (!this.filterInterfaceNetRule || (this.filterInterfaceNetRule && !item.mPinList.find(it => netInfo.filterSameCompList.includes(it.mCompName))))))];
      for (let net of newRelatedNets) {
        netInfo.netsList.set(net.mName, {
          netInfo: net,
          targetComponents: [...netInfo.targetComponents],
          rlcComponents: [...netInfo.rlcComponents],
          rlcCompNets: [...netInfo.rlcCompNets],
          cmcComponents: [...netInfo.cmcComponents],
          repeaterComponents: [...netInfo.repeaterComponents]
        });
      }

      if (newRelatedNets.length) {
        if (this.filterInterfaceNetRule) {
          netInfo.filterSameCompList = [...netInfo.filterSameCompList, ...netInfo.targetComponents]
        }
        for (let _netInfo of newRelatedNets) {
          this.setRelatedNets(_netInfo, key)
        }
      }
    }
  }

  getRelatedCompType = ({ mCompName, pinLength, partNumber, compPrefixLib, compPinMap }) => {
    if (this.checkCompsTypeFunction) {
      return this.checkCompsTypeFunction(mCompName, pinLength, partNumber, compPrefixLib, compPinMap)
    } else {
      return checkCompsType(mCompName, compPrefixLib, pinLength, partNumber);
    }
  }

  setRLCRelateNets = ({
    mCompName,
    mLayerName,
    mPinNum,
    pinLength,
    _this
  }) => {
    _this.rlcComponents.push(mCompName);
    const component = getComponentByLayer(this.designId, mLayerName, mCompName) || {};
    const pins = component.mPart ? (component.mPart.mPinList || []) : [];
    const filterPins = pins.filter(item => item.mNumber !== mPinNum);
    const findPin = filterPins.length ? filterPins[0] : {};
    let pinNum = findPin && findPin.mNumber ? findPin.mNumber : null;
    if (this.filterInterfaceNetRule && pinLength >= 4) {
      // array resistor (find interface)
      pinNum = getConnectedPinNumber({ pinNum: mPinNum, pinLength }) || "";
    }
    const netInfo = pinNum ? this.compPinsNets[mCompName][pinNum] : null;
    if (pinNum
      && netInfo
      && ((this.filterInterfaceNetRule && !this.filterInterfaceNetRule(netInfo, this.symbol)) || (!this.filterInterfaceNetRule && !this.isPowerGND(netInfo, this.symbol, this.designId)))) {
      _this.relatedNets.push(netInfo);
      _this.rlcCompNets.push({ component: mCompName, net: netInfo.mName });
    }
  }

  setCMCRelateNets = ({
    mCompName,
    findComp,
    mLayerName,
    mPinNum,
    _this
  }) => {
    _this.targetComponents.push(mCompName);
    const component = getComponentByLayer(this.designId, mLayerName, mCompName);
    //CMC find
    if ((!findComp || findComp.type === CMC) && component.mPart && component.mPart.mPinList && component.mPart.mPinList.length === 4) {
      const _pinMap = this.pinMap ? this.pinMap : (findComp && findComp.pinMap);
      const findCMC = this.updatedCMCComponents ? this.updatedCMCComponents.find(it => it.mCompName === mCompName) : null;
      const updatedPinsConnection = findCMC && findCMC.pinsConnection && findCMC.pinsConnection.length === 2 ? findCMC.pinsConnection : null;
      const pinsConnection = updatedPinsConnection ? updatedPinsConnection : (!_pinMap ? getCMCPinConnections({ pinList: component.mPart.mPinList }) : _pinMap);

      //if user ignore cmc comp
      if (findCMC && findCMC.type !== CMC) {
        _this.cmcComponents.push({
          mCompName,
          pinsConnection,
          type: findCMC.type,
          part: component.mPart.mInfo ? component.mPart.mInfo.mPartName : "",
          pins: [...component.mPart.mPinList]
        });
        return;
      }

      const pinNum = getConnectedPinNumber({
        pinNum: mPinNum,
        pinMap: pinsConnection,
        pinLength: 4
      }) || "";
      const netInfo = this.compPinsNets[mCompName] ? this.compPinsNets[mCompName][pinNum.toString()] : null;
      if (netInfo
        && ((this.filterInterfaceNetRule && !this.filterInterfaceNetRule(netInfo, this.symbol)) || (!this.filterInterfaceNetRule && !this.isPowerGND(netInfo, this.symbol, this.designId)))) {
        _this.rlcCompNets.push({ component: mCompName, net: netInfo.mName, type: CMC });
        _this.cmcComponents.push({
          mCompName,
          pinsConnection,
          type: CMC,
          part: component.mPart.mInfo ? component.mPart.mInfo.mPartName : "",
          pins: [...component.mPart.mPinList]
        });
        _this.relatedNets.push(netInfo);
      }
    }
  }

  setRepeaterRelateNets = ({
    mCompName,
    mPinNum,
    compPinMap,
    partNumber,
    compPinList,
    pinLength,
    _this
  }) => {
    let repeaterPinMap = compPinMap.repeater ? compPinMap.repeater : [];
    //let findPinMap = repeaterPinMap.find(item => item.part === partNumber && (!item.components || item.components.includes(mCompName)));
    let findPinMapList = repeaterPinMap.filter(item => item.part === partNumber && (!item.components || item.components.includes(mCompName)));
    if (!findPinMapList.length) {
      let findPinMap = null;
      findPinMap = { part: partNumber, pinMap: [] }
      findPinMap.pinMap = getDefaultRepeaterPinMap(compPinList);
      findPinMapList.push(findPinMap);
    }

    let isFirst = true;
    const prevPath = new RelatedItem(_this);
    for (let pinMapItem of findPinMapList) {

      if (!pinMapItem.pinMap.length) {
        continue;
      }

      if (prevPath.repeaterPinMap.find(item => item.comp === mCompName && (item.pin === mPinNum || item.pinMap === pinNum || item.pin === pinNum || item.pinMap === mPinNum))) {
        continue;
      }
      let _this_ = null, newKey = null;
      if (isFirst) {
        isFirst = false;
        _this_ = _this;
      } else {
        const keys = Array.from(this.relateMap.keys()).map(item => item.split("_")[1]);
        newKey = getDefaultIndex(keys.length, keys);
        _this_ = new RelatedItem(prevPath)
      }

      if (!_this_) {
        continue;
      }

      const pinNum = getConnectedPin({
        pinNum: mPinNum,
        pinMap: pinMapItem.pinMap,
        pinLength,
        notDefaultPinMap: true
      }) || "";

      if (!pinNum) {
        _this_.targetComponents.push(mCompName);
        _this_.netTargetComponents.push(mCompName);
        continue;
      }
      if (newKey) {
        this.relateMap.set(`group_${newKey}`, _this_);
      }


      _this_.repeaterPinMap.push({ comp: mCompName, pin: mPinNum, pinMap: pinNum })

      const netInfo = this.compPinsNets[mCompName] ? this.compPinsNets[mCompName][pinNum.toString()] : null;

      if (netInfo && ((this.filterInterfaceNetRule && !this.filterInterfaceNetRule(netInfo, this.symbol)) || (!this.filterInterfaceNetRule && !this.isPowerGND(netInfo, this.symbol, this.designId)))) {
        _this_.rlcCompNets.push({ component: mCompName, net: netInfo.mName, type: COMP_REPEATER });
        _this_.repeaterComponents.push({
          mCompName,
          pinMap: pinMapItem.pinMap,
          type: COMP_REPEATER,
          part: partNumber,
          pins: [...compPinList]
        });
        _this_.targetComponents = _this_.targetComponents.filter(item => item !== mCompName);
        _this_.netTargetComponents = _this_.netTargetComponents.filter(item => item !== mCompName)
        _this_.relatedNets.push(netInfo);
      }
    }
  }
}

function RelatedItem(params = {}) {
  this.relatedNets = JSON.parse(JSON.stringify(params.relatedNets || []));
  this.rlcComponents = JSON.parse(JSON.stringify(params.rlcComponents || []));
  this.targetComponents = JSON.parse(JSON.stringify(params.targetComponents || []));
  this.rlcCompNets = JSON.parse(JSON.stringify(params.rlcCompNets || []));
  this.cmcComponents = JSON.parse(JSON.stringify(params.cmcComponents || []));
  this.repeaterComponents = JSON.parse(JSON.stringify(params.repeaterComponents || []));
  this.netTargetComponents = JSON.parse(JSON.stringify(params.netTargetComponents || []));
  this.filterSameCompList = JSON.parse(JSON.stringify(params.filterSameCompList || []));
  this.netsList = params.netsList ? _.cloneDeep(params.netsList) : new Map();
  this.repeaterPinMap = JSON.parse(JSON.stringify(params.repeaterPinMap || []));
}

