import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Button, Transfer, Select } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';

const Option = Select.Option;
class PinMapSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 500,
      maxHeight: 500,
      pinStore: [],
      target: [],
      sourceSelect: [],
      targetSelect: [],
      applys: []
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.setPinStore();
  }

  componentDidUpdate = (prevProps) => {
    const { comp } = this.props;
    if (comp && comp !== prevProps.comp) {
      this.setPinStore();
    }
  }

  setPinStore = () => {
    const { pins, targetPins, type, outputs } = this.props;
    const target = pins.filter(pin => targetPins.includes(`${pin.comp ? `${pin.comp}_` : ""}${pin.pinName}`)).map(pin => `${pin.comp ? `${pin.comp}_` : ""}${pin.pin}`);
    const pinStore = pins.map(pin => ({
      key: `${pin.comp ? `${pin.comp}_` : ''}${pin.pin}`,
      title: `${pin.pinName}::${pin.pin}(${pin.comp ? `${pin.comp} - ` : ''}${pin.net}${pin.nextComp ? ` -> ${pin.nextComp} -> ${pin.nextNet}` : ''})`,
      sortNet: pin.nextNet ? pin.nextNet : pin.net,
      ...pin
    })).sort((a, b) => a.pinName > b.pinName ? 1 : -1)
    const applys = type === 'ground' ? outputs.map(o => o.join(', ')) : [];
    this.setState({
      target,
      pinStore,
      applys
    })
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 500),
      maxHeight: getPanelMaxHeight(offset, 500),
    })
  }

  closeModal = () => {
    this.props.closePanel(null);
  }

  selectPins = (source, targetSelect) => {
    this.setState({
      sourceSelect: [...source],
      targetSelect: [...targetSelect],
    })
  }

  movePins = (targetKeys, direction, moveKeys) => {
    let target = [];
    if (direction === 'right') {
      target = [...new Set([...targetKeys, ...moveKeys])]
    } else if (direction === 'left') {
      target = targetKeys.filter(item => !moveKeys.includes(item));
    }
    this.setState({
      target,
    })
  }

  update = (target, type, record) => {
    const { applys } = this.state;
    this.props.selectPins(target, type, record, applys);
    this.props.save()
  }

  selectApplys = (values) => {
    this.setState({
      applys: values
    })
  }

  selectCompRender = () => {
    const { comps, comp } = this.props;
    return <div className='power-select-comp-select'>
      <span>Current Component</span>
      <Select
        value={comp}
        onSelect={this.props.selectComp}
        className={"aurora-select"}
        popupClassName='aurora-select-dropdown'
      >
        {comps.map(item => <Option
          key={item.name}
          value={item.name}
          title={item.name}
        >{item.name}</Option>)}
      </Select>
    </div>
  }

  render() {
    const { target, maxWidth, maxHeight, pinStore, sourceSelect, targetSelect, applys } = this.state;
    const { title, type, record, apply, outputs, pmicType } = this.props;
    const content = (
      <Panel
        className='cascade-power-select-panel'
        title={<div className='cascade-power-select-title'>{`Select ${type.replace(/^\S/, s => s.toUpperCase())} Pins ${title ? `- ${title}` : ''}`}</div>}
        onCancel={this.props.save}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 900 })}
        height={550}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
        minHeight={500}
        minWidth={900}
        defaultTop={200}
      >
        <Fragment>
          {
            pmicType !== 'buckConverter' && this.selectCompRender()
          }
          <div className={`power-select-content cascade-power-transfer-outside ${pmicType !== 'buckConverter' ? 'cascade-power-transfer-outside-has-select' : ''}`}>
            <Transfer
              className='cascade-power-transfer'
              showSearch
              dataSource={pinStore}
              titles={[<strong>All Pins</strong>, <strong>Selected</strong>]}
              targetKeys={target}
              selectedKeys={[...sourceSelect, ...targetSelect]}
              onChange={this.movePins}
              onSelectChange={this.selectPins}
              render={item => item.title}
              filterOption={(inputValue, item) =>
                (item.title || "").toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1
              }
              listStyle={{
                width: getPanelWidth('48%', { defaultWidth: 240 }),
                height: '100%',
              }}
              locale={{
                itemUnit: 'Pin',
                itemsUnit: 'Pins',
                searchPlaceholder: 'Search Pins'
              }}
            />
          </div>
          <div className='power-select-content power-select-content-button'>
            {
              apply && outputs.length ? <div className='power-select-apply'>
                <span className='power-select-apply-tip'>Apply to</span>
                <Select
                  size="small"
                  className='power-select-apply-select'
                  mode='multiple'
                  popupClassName='cascade-power-select-apply-select-dropdown'
                  value={applys}
                  onChange={this.selectApplys}
                  optionLabelProp="label"
                >
                  {outputs.map(output => {
                    return output.length ? <Option key={output.join(', ')} label={output[0]}>{output.join(', ')}</Option> : null
                  })}
                </Select>
              </div> : null
            }
            <Button
              onClick={() => this.update(target, type, record)}
              size="small"
              className='cascade-power-select-btn'
            >Update</Button>
          </div>
        </Fragment>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }
};


export default PinMapSelect;
