import { numberCheck } from "../../../helper/dataProcess";
import NP from 'number-precision';

class TDRSBRSetting {
  constructor(channelId) {
    this.channelId = channelId;
    this.datarate = "";
    this.delay_risetime = "25ps";
    this.tdr_duration_ratio = "20";
    this.sbr_duration_ratio = "20";
    this.sbr_delay = "0";
    this.sbr_risetime = "25ps";
    this.tdr_delay = "0";
    this.tdr_risetime = "25ps";
    this.powersum_frequencies = [];
    this.getRange = this.getRange.bind(this);
  }

  getRange(type) {
    switch (type) {
      case 'datarate':
        return [0.001, Infinity]; // > 1Mbps, but must be smaller than the maximum extraction frequency, unit GHz
      case 'duration_ratio':
        return [0, Infinity]; // value range: >0, default = 20
      case 'delay_risetime':
        return [1, 100]; // value range: 1~100 ps, default = 25ps
      case 'risetime':  // tdr_risetime | sbr_risetime
        return [0, Infinity]; // value range: > 0 ps, default = 25ps
      case 'tdr_delay':
        return [0, 1000]; // value range: 0~1000 ps, default = 0
      case 'sbr_delay':
        return [0, 1000]; // value range: 0~1000 ps, default = 0
      default: return;
    }
  }

  getDisplayNameByType(type) {
    switch (type) {
      case 'datarate':
        return 'data rate';
      case 'duration_ratio':
        return 'duration';
      case 'risetime':
        return 'rise time';
      default: return;
    }
  }

  // datarate < fmax/2
  checkRange(type, value, maxFreq, isPreLayout) {
    const name = this.getDisplayNameByType(type);
    //number check
    const numError = numberCheck(value);
    if (numError) {
      return `The ${name} ${numError}`;
    }
    const range = this.getRange(type);
    let from = range[0], to = range[1], _maxFreq = maxFreq;

    if (type === 'datarate' && maxFreq !== -1 && !isPreLayout) {
      _maxFreq = maxFreqHzToGHz(maxFreq);
      to = _maxFreq / 2;
    }

    if (type === "duration_ratio" && parseFloat(value) === from) {
      return `Please set the ${name} value greater than 0.`
    }

    if (value < from || value > to) {

      if (type === 'datarate') {
        // to = to ? to + "Gbps" : '1TBps';

        return value < from
          ? `The data rate value must be greater than 1Mbps.`
          : `The maximum extraction frequency is ${_maxFreq}GHz. Please set the data rate to be smaller or equal to ${to} (fmax/2).`
      }

      if (["duration_ratio", 'risetime'].includes(type)) {
        return `Please set the ${name} value greater than ${from}.`
      }
      return `Please set the ${name} value from ${from} to ${to}.`
    } else {
      return false;
    }
  }

  updateData(data) {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    })
  }

  getSetting({ dataRateUnit, generate_powersum, generate_waveform }) {
    return {
      channelId: this.channelId,
      datarate: this.datarate.includes('bps') ? this.datarate : `${this.datarate || ""}${dataRateUnit || ""}`,
      delay_risetime: this.delay_risetime.includes('ps') ? this.delay_risetime : this.delay_risetime + 'ps',
      tdr_duration_ratio: Number(this.tdr_duration_ratio),
      sbr_duration_ratio: Number(this.sbr_duration_ratio),
      sbr_delay: this.sbr_delay,
      sbr_risetime: this.sbr_risetime.includes('ps') ? this.sbr_risetime : this.sbr_risetime + 'ps',
      tdr_delay: this.tdr_delay,
      tdr_risetime: this.tdr_risetime.includes('ps') ? this.tdr_risetime : this.tdr_risetime + 'ps',
      powersum_frequencies: this.powersum_frequencies,
      generate_powersum,
      generate_waveform
    }
  }

  updateSettingData(delayData, dataRate) {
    const _dateRate = getDataRate(dataRate);

    const { tdr_risetime, sbr_risetime, delay_risetime, riseTime } = getRisetimeByDataRate(_dateRate);
    //update rise time
    this.tdr_risetime = tdr_risetime;
    this.sbr_risetime = sbr_risetime;
    this.delay_risetime = delay_risetime;

    //update duration ratio
    const { tdr_duration_ratio, sbr_duration_ratio } = getMaxDelayUI(delayData, _dateRate, riseTime);
    this.tdr_duration_ratio = tdr_duration_ratio;
    this.sbr_duration_ratio = sbr_duration_ratio;
  }
}

function maxFreqHzToGHz(value) {
  return Number(value) / 1e9;
}

const keys = [{
  key: 'datarate',
  name: 'Data Rate'
}, {
  key: 'risetime',
  name: 'Rise Time'
}, {
  key: `duration_ratio`,
  name: 'Duration'
}];

function getMaxDiffDelay(delayData) {
  if (delayData && delayData.dataList && delayData.dataList.length) {
    const diffDelayList = delayData.dataList.map(item => Number(item.diff_delay));
    //unit ps to s
    return Math.max(...diffDelayList) * 1e-12;
  }
  return 0;
}

function getMaxDelayUI(delayData, dataRate, risetime) {
  const channelMaxDiffDelay = getMaxDiffDelay(delayData);
  const _risetime = risetime ? risetime : 25;
  //tdr_max_delay_ui = int(np.round((2 * np.max(self.delay_mm) + delay + risetime) * self._datarate + 3))
  const tdr_duration_ratio = Math.round((2 * channelMaxDiffDelay + 0 + _risetime * 1e-12) * dataRate + 3);
  //sbr_max_delay_ui = int(np.round((np.max(self.delay_mm) + delay + 2 * risetime) * self._datarate) + 5)
  const sbr_duration_ratio = Math.round((channelMaxDiffDelay + 0 + 2 * _risetime * 1e-12) * dataRate + 5);
  return {
    tdr_duration_ratio: tdr_duration_ratio < 20 ? 20 : tdr_duration_ratio,
    sbr_duration_ratio: sbr_duration_ratio < 20 ? 20 : sbr_duration_ratio,
  };
}

/* Round to precision decimal places */
function round(number, precision) {
  return Math.round(+number + 'e' + precision) / Math.pow(10, precision);
}

function getRisetimeByDataRate(dataRate) {
  // ui = 1 / dataRate
  const ui = NP.divide(1, dataRate);
  //risetime = 1/3 * ui ,  unit is s
  let riseTime = NP.divide(ui, 3);

  //s -> ps
  riseTime = NP.times(riseTime, 1e12);

  // if rise time less than 5ps,Round to 1 decimal places.
  if (riseTime < 5) {
    riseTime = round(riseTime, 1);
  } else {
    riseTime = Math.round(riseTime);
  }

  if (riseTime <= 0) {
    riseTime = 0.1;
  }

  const _riseTime = `${riseTime}ps`;
  return {
    tdr_risetime: _riseTime,
    sbr_risetime: _riseTime,
    delay_risetime: _riseTime,
    riseTime: riseTime
  };
}

function getDataRate(dataRate) {
  const match = dataRate.match(/(M|G|T)bps/g);
  const dataRateUnit = match ? match[0] : 'Gbps';
  const _dateRate = valueMatch(dataRate);
  let scale = 1e9;
  switch (dataRateUnit) {
    case "Gbps":
      scale = 1e9; break;
    case "Mbps":
      scale = 1e6; break;
    case "Tbps":
      scale = 1e12; break;
    default: scale = 1e9; break;
  }
  return NP.times(scale, parseFloat(_dateRate));
}

function valueMatch(value) {
  const m = value.toString().match(/^[0-9]\d*(\.\d+)?e(-|\+)?\d+|^[0-9]\d*(\.\d+)?/);
  return m ? m[0] : ''
}

export default TDRSBRSetting;
export {
  valueMatch,
  keys,
  maxFreqHzToGHz,
}