import React from 'react';
import { Select, } from 'antd';
import { LIBRARY_UNITS } from "@/services/PreLayout/PreLayoutLibrary";
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

const Option = Select.Option;

export default function unitSelect({ unit, changeUnit }) {
  return <div className='pre-layout-unit-select'>
    <span className='out-title-color pre-layout-span'>Unit</span>
    <Select
      value={unit}
      onChange={(key) => changeUnit(key)}
      placeholder={"Unit"}
      className='aurora-select pre-layout-select'
      popupClassName='aurora-select-dropdown'
      getPopupContainer={trigger => trigger.parentNode}
    >
      {LIBRARY_UNITS.map(item =>
        <Option key={item}>{item}</Option>
      )}
    </Select>
  </div>
}