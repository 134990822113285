import {
  UPDATE_SIMULATION_REDUCER,
  UPDATE_SIMULATION_INFO,
  GET_WORKFLOW_MONITOR,
  UPDATE_MULTI_SIMULATION_INFO,
  GET_VERIFICATION_WORKFLOW,
  GET_VERIFICATION_LOG,
  GET_CURRENT_PROFILE,
  CANCEL_VERIFICATION_WORKFLOW,
  UPDATE_SIM_SELECT_KEYS,
  RECALCULATE_TDR_SBR_WORKFLOW
} from './actionTypes';

export const updateSimulationReducer = (simulation) => ({
  type: UPDATE_SIMULATION_REDUCER,
  simulation
});

export const updateSimulationInfo = ({ verificationId, item, info }) => ({
  type: UPDATE_SIMULATION_INFO,
  verificationId,
  item,
  info
});

export const updateMultiSimulationInfo = ({ verificationId, info }) => ({
  type: UPDATE_MULTI_SIMULATION_INFO,
  verificationId,
  info
});

export const getWorkflowMonitor = (workflowId, verificationId, workType) => ({
  type: GET_WORKFLOW_MONITOR,
  workflowId,
  verificationId,
  workType
});

export const getVerificationWorkflow = ({ verificationId, channelId, workType, endToEndChannelId }) => ({
  type: GET_VERIFICATION_WORKFLOW,
  verificationId,
  channelId,
  workType,
  endToEndChannelId
});

export const getVerificationLog = (verificationId, workType) => ({
  type: GET_VERIFICATION_LOG,
  verificationId,
  workType
});

export const cancelVerificationWorkflow = (verificationId) => ({
  type: CANCEL_VERIFICATION_WORKFLOW,
  verificationId
});

export const getCurrentProfile = (verificationId) => ({
  type: GET_CURRENT_PROFILE,
  verificationId
});

export const updateSimSelectKeys = (keys, simType) => ({
  type: UPDATE_SIM_SELECT_KEYS,
  keys,
  simType
})

export const recalculateTDRSBRWorkflow = (workflow, isEndToEnd, workType) => ({
  type: RECALCULATE_TDR_SBR_WORKFLOW,
  workflow,
  isEndToEnd,
  workType
})