// ================= Component structure =================
function basicCompModel({ signal, pin, net }) {
    this.signal = signal || '';
    this.pin = pin || '';
    this.net = net || '';
}
export class BasicComponent {
  constructor({ name = '', type = '', pins = [], value = '' }) {
    this.name = name;
    this.type = type;
    this.value = value;
    this.pins = pins;
    this.addModel.bind(this);
  }

  addModel({ signal, pin, net }) {
    this.pins.push(new basicCompModel({ signal, pin, net }));
  }
}