import React, { Component, Fragment, createRef } from 'react';
import { Spin, Checkbox } from 'antd';
import SparameterSetting from '../../../../services/Result/Public/sparameter/SparameterSetting';
import Parameters from '@/services/Sierra/Sparameter/parameter';
import DisplayMode from '@/components/Sparameter/displayMode';
import ResultList, { RESULT } from '@/components/Sparameter/resultList';
import ResultRightMenu from '@/services/Result/Public/resultRightMenu';
import { Modes } from '../../constants';
import { strDelimited } from '@/services/helper/split';
import ResultLayout from '@/services/Result/Public/resultLayout';
import { axisFormat } from '@/services/Result/Public/sparameter/dataHelper';
import { getSweepList } from '@/services/Sierra';
import EndToEndResult from '../../../../components/Sparameter/endToEnd';
import photo from '@/components/PublicSvg/photo.svg';
import saveSvg from 'save-svg-as-png';
/* import ResultZoom from '../../../../components/ResultTopMenu/resultZoom'; */
import './new.css';

class Sparameter extends Component {

  constructor(props) {
    super(props);
    this.uploadSP = createRef();
    this.svgRef = createRef();
    this.uploadSnpRef = createRef();
    this.state = {
      loading: true,
      displayMode: 'Default',
    }
  }

  onRef = (ref) => {
    this.sparameterSetting = ref;
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  updateDisplayModeModel = () => {
    this.setState({
      displayMode: 'Default'
    })
  }

  changeAxis = (axis) => {
    const xUnit = this.getXUnit();
    this.sparameterSetting.setAxis(axisFormat(xUnit, axis));
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    this.resultRightMenu.updateFileListState();
  }

  changeFileListBottom = () => {
    this.resultRightMenu.updateFileListState();
  }

  removeAllCurves = () => {
    const { portSelect } = this.props;
    const selects = Object.values(portSelect).reduce((a, b) => {
      return a.concat(b);
    }, []);
    let ports = [...selects];
    const removeCurves = ports.map(curve => {
      const [fileId, row, col] = strDelimited(curve, "::");
      return { row, col, id: fileId };
    });
    if (removeCurves.length) {
      Parameters.removeCurves(removeCurves);
    };
  }

  getSetting = () => {
    return this.sparameterSetting.getSetting();
  }

  getAxis = () => {
    return this.sparameterSetting.getAxis();
  }

  getXUnit = () => {
    return this.sparameterSetting.getXUnit()
  }

  axisChangeCB = (axis, value) => {
    const setting = this.getSetting();
    Parameters.changeAxis(axis, { param: setting.parameter, value: setting.value, scale: value })
  }

  updateRangeCB = (update, min, max) => {
    if (update === 'x') {
      Parameters.updateRange('x', { start: min, end: max })
    } else {
      const axis = this.getAxis();
      Parameters.updateRange('y', { start: axis['yMin'], end: axis['yMax'], yScale: axis['yScale'] })
    }
  }

  printResult = (e) => {
    e.preventDefault();
    const options = {
      backgroundColor: '#ffffff',
      encoderOptions: 1
    }
    document.querySelector(".plot") && saveSvg.saveSvgAsPng(document.querySelector(".plot"), "Network_parameter_result.png", options);
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div className='sierra-result-parameter-right'>
          <svg ref={this.svgRef}></svg>
          {/* {ResultZoom({
            zoomIn: Parameters.zoomIn,
            zoomOut: Parameters.zoomOut,
            fitView: Parameters.fitView
          })} */}
          <div className='sierra-result-curve-right'>
            <div className='photo-box' onClick={this.printResult}>
              <img src={photo} alt="" className='sierra-photo' />
            </div>
          </div>
        </div>
      </Spin>
    )
  }

  getCurrentSelects = () => {
    let { portSelect = {} } = this.props;
    return Object.values(portSelect).reduce((a, b) => {
      return a.concat(b);
    }, []);
  }

  resultRightMenuOnRef = (ref) => {
    this.resultRightMenu = ref;
  }

  displayModeChange = (e) => {
    const value = e && e.target ? e.target.value : this.state.displayMode;
    this.removeAllCurves();
    this.props.setChannelState({}, 'portSelect')
    this.setState({
      displayMode: value,
    }, () => {
      if (value === 'Default') {
        this.props.defaultResultList(value);
      } else {
        this.props.findResultList(value);
      };
    })
  }

  getDisplayMode = () => {
    return this.state.displayMode;
  }

  setLoading = (bool) => {
    this.setState({ loading: bool });
  }

  rightTopRender = () => {
    const { displayMode } = this.state;
    //Virtual component hide insertion loss
    const { hideIL } = this.props;
    const _Modes = hideIL ? Modes.filter(item => item.value !== "IL") : Modes;
    return (
      <DisplayMode
        displayModeChange={this.displayModeChange}
        displayMode={displayMode}
        Modes={_Modes}
      />
    )
  }

  ResultRowSelectCheckbox = (data = []) => {
    return (<div className="sparameter-channel-display-inline-flex">
      {data.map((item, index) =>
        <span
          className='sparameter-result-menu-title sparameter-sweep-checkbox sparameter-all-sweep-checkbox'
          key={item.name}
          style={index === 0 ? { marginLeft: 240 } : {}}>
          <Checkbox
            indeterminate={item.indeterminate}
            onChange={(e) => this.props.changeSelectRowAll(item)}
            checked={item.allChecked}
          ></Checkbox>
        </span>
      )}
    </div>)
  }

  getMultiSweepList = (allPortSelect, experimentList, portSelect, displayMode) => {
    const allKeys = Object.keys(allPortSelect);
    const data = allKeys.map(key => {
      const sweepData = getSweepList(allPortSelect[key][displayMode], experimentList, portSelect);
      return { ...sweepData, pcb: key }
    })
    return data
  }

  getResultListByVCPcb = (resultList, hasVcPCBs) => {
    if (!hasVcPCBs || !hasVcPCBs.length) {
      return resultList;
    }
    for (let pcb of hasVcPCBs) {
      if (resultList[pcb] && resultList[pcb].IL) {
        delete resultList[pcb].IL
      }

    }
    return resultList;
  }

  resultListRender = () => {
    const { files, portSelect, resultList, hasVcPCBs, experimentList = null, allPortSelect = {}, changeSelectRowAll, changeSelectSignalAll, endToEndList } = this.props;
    const { displayMode } = this.state;
    const multiRowSelectData = this.getMultiSweepList(allPortSelect, experimentList, portSelect, displayMode);
    const _resultList = this.getResultListByVCPcb(resultList, hasVcPCBs);
    const showEndToEnd = ["RL", "IL"].includes(displayMode) && endToEndList && endToEndList.length;
    return (
      <Fragment>
        <ResultList
          type={RESULT}
          files={files.filter(item => item.type !== "end-to-end")}
          displayMode={displayMode}
          portSelect={portSelect}
          changeAllPorts={this.props.changeAllPorts}
          changePort={this.props.changePort}
          colorChange={this.props.colorChange}
          resultList={_resultList}
          rowNameClick={this.props._rowNameClick}
          changeSelectAll={this.props._changeSelectAll}
          multiFiles={true}
          experimentList={experimentList}
          fileDownload={this.props.fileDownload}
          fileNameClick={this.props.fileNameClick}
          allPortSelect={allPortSelect}
          changeSelectRowAll={changeSelectRowAll}
          changeSelectSignalAll={changeSelectSignalAll}
          multiRowSelectData={multiRowSelectData}
          ResultRowSelectCheckbox={this.ResultRowSelectCheckbox}
          className={showEndToEnd ? "sierra-sparameter-result-content" : ""}
        />
        {showEndToEnd ?
          <EndToEndResult
            endToEndList={endToEndList}
            files={files.filter(item => item.type === "end-to-end")}
            displayMode={displayMode}
            portSelect={portSelect}
            colorChange={this.props.colorChange}
            changeEndToEndPorts={this.props.changeEndToEndPorts}
          /> : null}
      </Fragment>
    )
  }

  settingRender = () => {
    return (
      <SparameterSetting
        onRef={this.onRef}
        portSelectLen={this.getCurrentSelects().length}
        settingChangeCB={this.props.settingChangeCB}
        changeFileListBottom={this.changeFileListBottom}
        axisChangeCB={this.axisChangeCB}
        updateRangeCB={this.updateRangeCB}
      />
    )
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        onRef={this.resultRightMenuOnRef}
        top={this.rightTopRender}
        resultList={this.resultListRender}
        setting={this.settingRender}
      />
    )
  }

  changeWidthCB = () => {
    const { plot2d } = this.props;
    if (plot2d && plot2d.curves && plot2d.curves.length) {
      plot2d.resetPlot();
      const axis = this.getAxis();
      const setting = this.getSetting();
      Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
      Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
    }
  }

  render() {
    const { className } = this.props;
    return (
      <div className={`sierra-touchstone-result-main ${className || ""}`}>
        <ResultLayout
          defaultRightWidth={350}
          resultLeft={this.resultLeft}
          resultRight={this.resultRight}
          changeWidthCB={this.changeWidthCB}
        />
      </div >
    )
  }
}

export default Sparameter;