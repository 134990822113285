import React, { Fragment, PureComponent } from "react";
import { CloseOutlined, PlusSquareOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import Panel from '@/components/Panel';
import MaterialPanel from '@/components/Material';
import { DIELECTRIC, METAL } from "../../../../../../services/Stackup";
import DelConfirm from "../../../../../../components/DelConfirm";
import { MATERIAL } from "../../../../../../constants/libraryConstants";
import { ANDES_V2 } from "../../../../../../constants/pageType";
import '@/publicCss/aurora.css';
import './index.css';

class MaterialMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('stackup-panel-main');
    this.width = -1;
    this.state = {
      selectMaterialName: null,
      metalOpen: true,
      dielectricOpen: true,
      deleteConfirmName: null
    }
  }

  componentWillMount() {
    const domRect = this.dialogRoot.getBoundingClientRect();
    const width = domRect.width || -1;
    this.width = width;
  }

  selectedMaterial = (e, name) => {
    e && e.stopPropagation();
    this.setState({
      selectMaterialName: name
    })
  }

  _saveMaterial = ({ newMaterial, record }) => {
    const { designID } = this.props;
    this.props._saveMenuMaterial({ newMaterial, prevMaterialInfo: record, designID });
    this.setState({
      selectMaterialName: null
    })
  }

  metalExpand = (e) => {
    e && e.stopPropagation();
    this.setState({
      metalOpen: !this.state.metalOpen
    })
  }

  dielectricExpand = (e) => {
    e && e.stopPropagation();
    this.setState({
      dielectricOpen: !this.state.dielectricOpen
    })
  }

  deleteMaterial = (e, name) => {
    e && e.stopPropagation();
    this.setState({
      deleteConfirmName: name
    })
  }

  deleteConfirm = (e) => {
    e && e.stopPropagation();
    this.props._delMaterialList(this.state.deleteConfirmName, this.props.designID);
    this.setState({
      deleteConfirmName: null
    })
  }

  cancelDel = (e) => {
    e && e.stopPropagation();
    this.setState({
      deleteConfirmName: null
    })
  }

  addMaterial = (e) => {
    e && e.stopPropagation();
    this.setState({
      selectMaterialName: "New Material"
    })
  }

  render() {
    const { closeModal, materialList = [], left, top } = this.props;
    const { selectMaterialName, metalOpen, dielectricOpen, deleteConfirmName } = this.state;
    const defaultLeft = this.width > 250 ? this.width - 125 : '50%';
    const metalMaterials = materialList.filter(item => item.type === METAL);
    const dielectricMaterials = materialList.filter(item => item.type === DIELECTRIC);
    return (
      <Panel
        className='material-menu-panel panel-x-scroll-hidden'
        position='panel-center-left'
        title={<Fragment>
          <span>Material List</span>
          <PlusSquareOutlined className="material-add" onClick={(e) => this.addMaterial(e)} />
        </Fragment>}
        zIndex={2000}
        onCancel={closeModal}
        width={250}
        left={left}
        top={top}
        draggable
        defaultLeft={defaultLeft}
        defaultTop={top}
        minWidth={250}
        redirectDom='stackup-panel'
        minHeight={100}
      >
        <div className='material-menu-content'>
          <div className="material-menu-type-title" onClick={(e) => this.metalExpand(e)}>
            {metalOpen ? <DownOutlined /> : <RightOutlined />}
            <span>Metal</span>
          </div>
          {metalOpen && <div className='material-menu-type-content'>
            {metalMaterials.map(item => (
              <li
                onClick={(e) => this.selectedMaterial(e, item.name)}
                className={selectMaterialName === item.name ? "material-menu-item material-menu-item-selected" : "material-menu-item"}
                key={item.name}
                title={item.name}
              >
                <span>{item.name}</span>
                <CloseOutlined
                  title="Delete Material"
                  className="material-menu-item-delete-icon"
                  onClick={(e) => this.deleteMaterial(e, item.name)} />
              </li>
            ))}
          </div>}
          <div className="material-menu-type-title" onClick={(e) => this.dielectricExpand(e)}>
            {dielectricOpen ? <DownOutlined /> : <RightOutlined />}
            <span>Dielectric</span>
          </div>
          {dielectricOpen && <div className='material-menu-type-content'>
            {dielectricMaterials.map(item => (
              <li
                onClick={(e) => this.selectedMaterial(e, item.name)}
                className={selectMaterialName === item.name ? "material-menu-item material-menu-item-selected" : "material-menu-item"}
                key={item.name}
                title={item.name}
              >
                <span>{item.name}</span>
                <CloseOutlined
                  title="Delete Material"
                  className="material-menu-item-delete-icon"
                  onClick={(e) => this.deleteMaterial(e, item.name)} />
              </li>
            ))}
          </div>}
          {selectMaterialName ? this.getMaterialSetup(selectMaterialName, materialList) : null}
          {deleteConfirmName ? <DelConfirm
            message={"Can not be restored after deleting the material. Are you sure you want to delete it?"}
            deleteConfirm={(e) => this.deleteConfirm(e, deleteConfirmName)}
            cancelDel={(e) => this.cancelDel(e)}
          /> : null}
        </div>
      </Panel >
    );

  }

  getMaterialSetup = (selectMaterialName, materialList) => {
    const material = materialList.find(item => item.name === selectMaterialName);
    if (!material && selectMaterialName !== "New Material") {
      return null;
    }

    const record = {
      type: material ? material.type : null,
      selectMaterialName,
      material: selectMaterialName,
      name: selectMaterialName
    }
    return <MaterialPanel
      editType="materialMenu"
      record={record}
      closePanel={this._saveMaterial}
      materialList={materialList}
      dataIndex={MATERIAL}
      pageType={ANDES_V2}
    />
  }
}

export default MaterialMenu;