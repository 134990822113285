import React from 'react';
import { checkRLCValueFormat } from '@/services/helper/dataProcess';

const RLCType = ['Ind', 'Res', 'Cap'];

export function getAndesErrorCheck(andesInfo) {
  if (!andesInfo) {
    return;
  }
  const { PCBsInInterface } = andesInfo;
  let warning = [];
  let error = [];

  if (PCBsInInterface && PCBsInInterface.length === 0) {
    error.push(<p style={{ margin: 0 }}>Does not set the signal.</p>)
    return { error };
  }

  if (andesInfo.Interfaces.length === 0) {
    error.push(<p style={{ margin: 0 }}>Does not set the signal.</p>)
    return { error };
  }
  const info = andesInfo.info;
  if (!info) return;
  const { components, signals } = info;

  if (signals.length === 0) {
    error.push(<p style={{ margin: 0 }}>Signal is not set.</p>)
    return { error };
  }

  if (!components || (components && !components.length)) {
    error.push(<p style={{ margin: 0 }}>Signal positive/negative net is not set.</p>);
    return { error };
  }

  signals.forEach(item => {
    if (item && !item.name) {
      error.push(<p style={{ margin: 0 }}>Signal name is not set.</p>);
    }

    if (item && item.positive.length === 0) {
      error.push(<p style={{ margin: 0 }}>{item.name} positive net is not set.</p>);
    }

    if (item && item.negative.length === 0) {
      error.push(<p style={{ margin: 0 }}>{item.name} negative net is not set.</p>);
    }
  });

  // Components usage
  const controller = components.filter(d => d.type === 'Controller');
  const device = components.filter(d => d.type === 'Device');
  if (!controller.length) {
    error.push(<p style={{ margin: 0 }}>No "Controller" in Component Usage.</p>)
  }

  if (!device.length) {
    error.push(<p style={{ margin: 0 }}>No "Device" in Component Usage.</p>)
  }

  // Power/Ground not found in PCB
  andesInfo.Interfaces.forEach(item => {
    let content = item.content;
    if (!content.refNets.ground || content.refNets.ground.length === 0) {
      error.push(<p style={{ margin: 0 }}> "<span className="font-bold">{item.pcb}</span>" Ground net is not set.</p>)
    }
  });

  // components value
  const RLCs = components.filter(item => RLCType.includes(item.type));
  if (RLCs.length > 0) {
    RLCs.forEach(rlc => {
      if (rlc.type === 'Cap') {
        if (!rlc.value) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" RLC value is not set.</p>);
        } else {
          let rValue = rlc.value.r;
          //Check the Resistance value format
          let rCheck = checkRLCValueFormat(rValue)

          if (!rCheck) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" Resistance value format error.</p>);
          }

          //Check the Inductance value format
          let lValue = rlc.value.l;
          let lCheck = checkRLCValueFormat(lValue)

          if (!lCheck) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" Inductance value format error.</p>);
          }

          //Check the Capacitance value format
          let cValue = rlc.value.c;
          let cCheck = checkRLCValueFormat(cValue, "Cap");

          if (!cCheck) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" Capacitance value format error.</p>);
          } else if (cCheck && cCheck === "0") {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" Capacitance value cannot be 0.</p>);
          }
        }
      } else {

        if (!rlc.value) {
          error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" missing value.</p>)
        } else {
          const rlcValue = checkRLCValueFormat(rlc.value);
          if (!rlcValue) {
            error.push(<p style={{ margin: 0 }}>RLC "<span className="font-bold">{rlc.part}::{rlc.name}</span>" value format error.</p>)
          }
        }
      }
    })
  }

  if (warning.length === 0 && error.length === 0) {
    return null;
  } else {
    return {
      //warning, 
      error
    }
  }
}