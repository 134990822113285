import React, { Component } from 'react';
import { connect } from 'react-redux';
import AcPreLayout from './acPreLayout';
import DcPreLayout from './dcPreLayout';
import { AC } from '../../../constants/treeConstants';
import './index.css';

class PreLayout extends Component {

  render() { 
    const { electric } = this.props;
    return electric === AC ? <AcPreLayout /> : <DcPreLayout />
  }

}

const mapState = (state) => {
  const { CascadeReducer: { prelayout: { electric } } } = state;
  return {
    electric
  }
}

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(PreLayout);
