import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginForm from '@/components/LoginForm';
import { loginAction, setPageType } from './action';
import permissionData from '@/services/helper/data/permissionData';
import './Login.css';

class LoginPage extends Component {

  componentWillMount() {
    this.props.setPageType(null);
  }

  render() {
    const { loginStatus, downloadStatus } = this.props;
    if (loginStatus && downloadStatus === false) {
      if (permissionData.isAdmin()) {
        return <Redirect to='/admin' />
      } else {
        return <Redirect to='/' />
      }
    }
    else if (loginStatus && downloadStatus === true) {
      return <Redirect to='/download' />
    }
    else {
      return (
        <div className="login">
          <LoginForm submit={this.props.login} />
        </div>
      )
    }

  }
}

const mapState = (state) => {
  const { login, email } = state.LoginReducer;
  const { download } = state.DownloadReducer;
  return {
    loginStatus: login,
    email,
    downloadStatus: download
  }
}

const mapDispatch = (dispatch) => ({
  login(email, password) {
    dispatch(loginAction(email, password))
  },
  setPageType(type) {
    dispatch(setPageType(type))
  },
})

export default connect(mapState, mapDispatch)(LoginPage);;