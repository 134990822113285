import { getBMAResultJson, getBMAResultJsonFile, downloadBMAResult, deleteBMAResult } from '../../api/BMA/result';
import apiProcess from '../../api/utility';
import { RANK_MARGIN } from '../helper/result';

function getBMAResultJsonPromise({ bmaChannelId, simulationType }) {
  return apiProcess(getBMAResultJson, { bmaChannelId, simulationType })
}

function getBMAResultJsonFilePromise({ bmaChannelId, simulationType, file, urlType }) {
  return new Promise((resolve, reject) => {
    getBMAResultJsonFile({ bmaChannelId, simulationType, file, urlType }).then(async response => {
      if (response.data) {
        if (simulationType === RANK_MARGIN || urlType === "json") {
          resolve(response.data);
        } else {
          const url = await ('data:image/png;base64,' + btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          ));
          resolve(url);
        }
      } else {
        resolve(null);
      }
    }, error => {
      resolve(null);
    });
  });
}

function downloadBMAResultPromise({ bmaChannelId, simulationTypes }) {
  return apiProcess(downloadBMAResult, { bmaChannelId, simulationTypes })
}

function deleteBMAResultPromise({ bmaChannelId, simulationType, folders }) {
  return apiProcess(deleteBMAResult, { bmaChannelId, simulationType, folders })
}

export {
  getBMAResultJsonPromise,
  getBMAResultJsonFilePromise,
  downloadBMAResultPromise,
  deleteBMAResultPromise
}