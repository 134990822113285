import React, { PureComponent } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import ResultFile from './fileContent';
import { TARGET } from '../../constants';

class Target extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      targetShow: true
    }
  }

  targetTitleClick = () => {
    this.setState((prevState) => ({
      targetShow: !prevState.targetShow
    }))
  }

  render() {
    const { targetActions, targetData } = this.props;
    const { fileNameClick, changePort, colorChange, deleteFile } = targetActions;
    const { files, portSelect, verificationId } = targetData;
    const { targetShow } = this.state;
    const targetFiles = files.filter(file => file.type === TARGET && !file.fileType);
    const Icon = targetShow ? DownOutlined : RightOutlined;
    return (
      <div className='pdn-result-default'>
        <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className='result-import-title'>
            <Icon className="title-expand-icon" onClick={this.targetTitleClick} />
            <span className='pdn-result-menu-title' onClick={this.targetTitleClick}>Target</span>
          </div>
        </Divider>
        <div className='pdn-result-file-content' style={{ display: targetShow ? 'block' : 'none' }}>
          {targetFiles.length > 0 ? targetFiles.map((file, fileIndex) =>
            <ResultFile
              file={file}
              fileNameClick={fileNameClick}
              fileIndex={fileIndex}
              portSelect={portSelect}
              changePort={changePort}
              verificationId={verificationId}
              colorChange={colorChange}
              deleteFile={deleteFile}
              key={'target' + file.id}
              type={TARGET}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
export default Target;