import React, { Component, Fragment } from 'react'
import { createPortal } from 'react-dom';
import { Spin, Select, TreeSelect, Tabs } from 'antd';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../services/helper/panelSizeHelper';
import ExtractionOptions from './extractionOptions';
import { ALL_INTERFACES } from '../../../services/Sierra/multiInterfaceSetup';
import MultiSetupExtractionPorts from './multiSetupExtractionPorts';
import { getExtractionData, updateMultiSetupExtractionApply, updateMultiExtrPCBSelectStatus } from '../store/multiInterface/action';
import './index.css';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;
const EXTRACTION_OPTIONS = 'Extraction Options',
  EXTRACTION_PORTS_SETUP = 'Extraction Ports',
  SAME = "same", ALL = "all";
const applyPCBs = [{
  key: SAME,
  title: "Same PCB",
},
{
  key: ALL,
  title: "All PCBs",
}]
class MultiSetupExtractionOption extends Component {

  constructor(props) {
    super(props);
    const { pcbId } = this.props;
    this.state = {
      verificationList: [],
      applyIds: [],
      maxHeight: 600,
      maxWidth: 830,
      loading: true,
      apply: false,
      current: pcbId,
      applyType: ALL
    }
    this.selectPCB = pcbId;
    this.dialogRoot = document.getElementById('root');
    this.extractionPortRef = {};
    this.extractionContentRef = {};
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 600),
      maxWidth: getPanelMaxWidth(offset, 830),
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getVerificationList()
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId, pcbSelectStatus } = this.props;
    if (verificationId && verificationId !== prevProps.verificationId) {
      this.getVerificationList();
    }

    if (pcbSelectStatus && prevProps.pcbSelectStatus !== pcbSelectStatus) {
      this.updateCurrent();
      this.props._updateMultiExtrPCBSelectStatus(false);
    }
  }

  getVerificationList = () => {
    const { currentProjectVerifications, verificationIds = [], /* verificationId, */ multiSetupGroup } = this.props;
    const verifications = (currentProjectVerifications || []).filter(item => verificationIds.includes(item.id) /* && item.id !== verificationId */);
    let verificationList = [], groupKeys = [];
    if (multiSetupGroup !== ALL_INTERFACES) {
      verificationList = verifications.map(item => {
        return {
          key: item.id,
          title: item.name,
          value: item.id
        }
      })
    } else {
      groupKeys = [...new Set(verifications.map(item => item.group).filter(item => !!item))];

      const groupVerificationList = groupKeys.map(group => {
        const findV = verifications.filter(it => it.group === group);
        return {
          key: group,
          title: group,
          value: group,
          children: findV.map(item => {
            return {
              key: item.id,
              title: item.name,
              value: item.id
            }
          })
        }
      })
      const noGroupVerifications = verifications.filter(item => !item.group).map(item => {
        return {
          key: item.id,
          title: item.name,
          value: item.id
        }
      });
      verificationList = [...groupVerificationList, ...noGroupVerifications];
    }

    this.setState({
      verificationList: [{
        key: "All",
        title: "All",
        value: "All",
        children: [...verificationList]
      }],
      groupKeys
    })
  }

  onRef = (ref, pcb) => {
    if (pcb) {
      this.extractionContentRef[pcb] = ref
    }
  }

  onPortRef = (ref, pcb) => {
    if (pcb) {
      this.extractionPortRef[pcb] = ref;
    }
  }

  getContent = ({ pcbId, interfaceId }) => {
    const { apply, maxHeight } = this.state;
    const { extractionType, verificationInfo, verificationId } = this.props;
    if (extractionType === EXTRACTION_OPTIONS) {
      return <ExtractionOptions
        {...this.props}
        onRef={this.onRef}
        current={pcbId}
        verificationId={verificationId}
        interfaceId={interfaceId}
        verificationInfo={verificationInfo}
        closeModal={this.props.closePanel}
        getApplyVIds={this.getApplyVIds}
        closeLoading={this.closeLoading}
        updateCurrent={this.updateCurrent}
        apply={apply} />
    } else {
      return <MultiSetupExtractionPorts
        {...this.props}
        onRef={this.onPortRef}
        current={pcbId}
        maxHeight={maxHeight}
        interfaceId={interfaceId}
        getApplyVIds={this.getApplyVIds}
        closeLoading={this.closeLoading}
        updateCurrent={this.updateCurrent}
        closeModal={this.props.closePanel}
      />
    }
  }

  closePanel = () => {
    const { extractionType } = this.props;
    const { current } = this.state;

    if (extractionType === EXTRACTION_OPTIONS && this.extractionContentRef && this.extractionContentRef[current]) {
      this.extractionContentRef[current].closeModal(true)
    } else if (extractionType === EXTRACTION_PORTS_SETUP && this.extractionPortRef && this.extractionPortRef[current]) {
      this.extractionPortRef[current].savePortsSetup("close");
    }
  }

  getApplyVIds = () => {
    const { verificationIds, currentProjectVerifications, multiSetupGroup, extractionType, extrOptionsApply, extrPortsApply } = this.props;
    const { groupKeys } = this.state;
    const applyConfig = extractionType === EXTRACTION_OPTIONS ? extrOptionsApply : extrPortsApply;
    const applyIds = applyConfig && applyConfig.applyIds ? applyConfig.applyIds : [];
    let _applyIds = [];
    if (applyIds.length && applyIds[0] === "All") {
      _applyIds = [...verificationIds];
    } else {

      if (multiSetupGroup === ALL_INTERFACES) {
        const applyGroups = applyIds.filter(item => groupKeys.includes(item));
        const verifications = (currentProjectVerifications || []).filter(item => verificationIds.includes(item.id));

        const applyGroupIds = applyGroups.map(item => { return [...verifications.filter(it => it.group === item).map(it => it.id)] }).flat(2);
        const noGroupIds = applyIds.filter(item => !groupKeys.includes(item) && verificationIds.includes(item));

        _applyIds = [...applyGroupIds, ...noGroupIds];
      } else {
        _applyIds = applyIds.filter(id => verificationIds.includes(id));
      }
    }
    /*   _applyIds = _applyIds.filter(item => item !== verificationId); */
    return { applyIds: _applyIds, applyType: applyConfig && applyConfig.applyType ? applyConfig.applyType : "" };
  }

  changeApplyPCBType = (applyType) => {
    const { extractionType } = this.props;
    const type = extractionType === EXTRACTION_OPTIONS ? "extrOptionsApply" : "extrPortsApply";
    this.props._updateMultiSetupExtractionApply({ applyType: type, config: { ...this.props[type], applyType } });
  }

  applyRender = () => {
    const { verificationList } = this.state;
    const { extractionType, extrOptionsApply, extrPortsApply } = this.props;
    const applyConfig = extractionType === EXTRACTION_OPTIONS ? extrOptionsApply || {} : extrPortsApply || {};
    return (
      <div className='multi-setup-simulation-apply-content'>
        <span>Apply {extractionType} to</span>
        <TreeSelect
          treeData={verificationList || []}
          value={applyConfig.applyIds || []}
          onChange={this.applyChange}
          placeholder='Please select'
          treeCheckable
          showCheckedStrategy={SHOW_PARENT}
          className='aurora-select'
          dropdownStyle={{ maxHeight: 500 }}
          getPopupContainer={() => document.getElementById('root')}
          popupClassName='multi-setup-option-select-dropdown' />
        <div className="sierra-multi-extraction-content-apply-item">
          <span>and</span>
          <Select
            className='aurora-select'
            value={applyConfig.applyType}
            popupClassName='multi-setup-option-select-dropdown'
            onChange={(key) => this.changeApplyPCBType(key)}
          >
            {applyPCBs.map(item => {
              return <Option key={item.key} value={item.key}>{item.title}</Option>
            })}
          </Select>
        </div>
      </div>
    );
  }

  applyChange = (keys) => {
    const { extractionType } = this.props;
    const type = extractionType === EXTRACTION_OPTIONS ? "extrOptionsApply" : "extrPortsApply";
    this.props._updateMultiSetupExtractionApply({ applyType: type, config: { ...this.props[type], applyIds: keys } });
  }

  changePCBSelect = (value) => {
    //Switch PCB board
    //Data needs to be saved during switching
    const { extractionType } = this.props;
    this.setState({
      loading: true
    })
    const { current } = this.state;
    this.selectPCB = value;
    this.props._updateMultiExtrPCBSelectStatus(false);
    if (extractionType === EXTRACTION_OPTIONS && this.extractionContentRef && this.extractionContentRef[current]) {
      this.extractionContentRef[current].closeModal(false)
    } else if (extractionType === EXTRACTION_PORTS_SETUP && this.extractionPortRef && this.extractionPortRef[current]) {
      const confirm = this.extractionPortRef[current].savePortsSetup("changePCB")
      if (confirm) {
        return
      }
    }
    /*     this.updateCurrent() */
  }

  updateCurrent = () => {
    const { verificationInfo, extrType } = this.props;
    /*  setTimeout(() => { */
    this.setState({
      current: this.selectPCB,
      loading: false
    })
    const interfaceId = (verificationInfo.list.find(item => item.pcbId === this.selectPCB) || {}).interfaceId;

    if (!interfaceId) {
      return;
    }
    this.props._getExtractionData({ interfaceId, extrType, pcbId: this.selectPCB })
    /*     }, 300) */
  }

  getApplyLog = () => {
    const { extractionType, applyPortsLog, applyOptionsLog } = this.props;
    if (extractionType === EXTRACTION_OPTIONS) {
      return applyOptionsLog || null;
    } else if (extractionType === EXTRACTION_PORTS_SETUP) {
      return applyPortsLog || null;
    }
    return null;
  }

  closeLoading = () => {
    this.setState({
      loading: false
    })
  }


  render() {
    const { maxWidth, maxHeight, loading,/*  apply, */ current } = this.state;
    const { extractionType, verificationInfo, interfaceId, } = this.props;
    /*   const showApply = extractionType === EXTRACTION_OPTIONS ?
        verificationInfo && verificationInfo.list && verificationInfo.list.length > 1
        : verificationInfo && verificationInfo.list && verificationInfo.list.filter(item => !item.disable).length > 1 */
    const log = this.getApplyLog();
    const content = (
      <Panel
        className='sierra-advance-panel sierra-panel'
        title={<div className='sierra-extraction-name'>{extractionType} - {verificationInfo.interfaceName || ""}</div>}
        onCancel={this.closePanel}
        zIndex={2000}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        width={getPanelWidth(maxWidth, { defaultWidth: 830 })}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <Spin tip={log || "Loading..."} spinning={loading || !!log}>
          <div className='sierra-advance-content'>
            {verificationInfo.list && verificationInfo.list === 1 ? this.getContent({ pcbId: current, interfaceId }) :
              <Fragment>
                <Tabs
                  activeKey={current}
                  onChange={this.changePCBSelect}
                  items={verificationInfo.list.map(tab => ({ key: tab.pcbId, label: tab.pcb, disabled: tab.disable, children: this.getContent({ ...tab }) }))}
                />
                {this.applyRender()}
              </Fragment>}
          </div>
        </Spin>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const { SierraReducer: {
    multiInterfaceSetup: {
      setupData: { verificationIds },
      multiSetupGroup,
      applyPortsLog,
      portsStatus,
      applyOptionsLog,
      optionsStatus, extrOptionsApply, extrPortsApply, pcbSelectStatus },
    project: { currentProjectVerifications } } } = state;
  return {
    verificationIds,
    currentProjectVerifications,
    multiSetupGroup,
    applyPortsLog,
    portsStatus,
    optionsStatus,
    applyOptionsLog,
    extrPortsApply,
    extrOptionsApply,
    pcbSelectStatus
  };
}

const mapDispatch = (dispatch) => ({

  _getExtractionData(info) {
    dispatch(getExtractionData(info))
  },
  _updateMultiSetupExtractionApply(info) {
    dispatch(updateMultiSetupExtractionApply(info))
  },
  _updateMultiExtrPCBSelectStatus(status) {
    dispatch(updateMultiExtrPCBSelectStatus(status))
  }
})

export default connect(mapState, mapDispatch)(MultiSetupExtractionOption)