import { changeLibraryFormat } from '../../helper/rlcValueFormat';
import { getLibraryData } from './index';

class DecapData {

  constructor() {
    this.Info = {};
  }

  getContent = (decapIds) => {
    let data = {};
    const promises = decapIds.map(item => {
      const content = this.existInfo(item);
      if (content) {
        return new Promise((resolve, reject) => {
          resolve({ [item]: { ...content } });
        })
      } else {
        return new Promise((resolve, reject) => {
          getLibraryData(item).then(res => {
            if (res && res.libraryData) {
              const libraryUnit = changeLibraryFormat(res.libraryData);
              let decapData = { ...libraryUnit.libraryData };
              this.Info[item] = decapData;
              resolve({ [item]: { ...decapData } })
            } else {
              resolve({ [item]: null })
            }
          }, error => {
            resolve({ [item]: null })
          })
        })
      }
    });
    return Promise.all(promises);
  }

  existInfo = (decapId) => {
    if (this.Info[decapId]) {
      return this.Info[decapId]
    } else {
      return null
    }
  }

  removeContent = (decapId) => {
    if (this.Info[decapId]) {
      delete this.Info[decapId];
    }
  }

  cleanContent = () => {
    this.Info = {}
  }
}

const decapData = new DecapData();
export default decapData;