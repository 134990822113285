import { CLKTYPE, CUSTOM, ESPI, I2C, I2S, I3C, QSPI, SPI, UART, SPMI } from "../../PCBHelper/constants";

const MERGE_SIGNAL_SUCCESS = 1, FAILED = 2, NEED_MANUAL = 3;
export default class MergeSignals {
  constructor() {
    this.allMergeData = []; //all selected signals
    this.mergeSignals = [];
    this.isOpenEdit = false;
    this.errorMsgs = null;
    this.config = {};// search signal nets config
  }

  mergeSignalsToOneSignals = (_pcbInterfaceInfo, pcbConfig, pcbId) => {
    this.errorMsgs = [];
    this.pcbInterfaceInfo = _pcbInterfaceInfo;
    this.designId = pcbId;
    /* for (let pcbId of Object.keys(_pcbInterfaceInfo)) { */
    if (!_pcbInterfaceInfo[pcbId].selectKeys.length || _pcbInterfaceInfo[pcbId].selectKeys.length <= 1) {
      return { _pcbInterfaceInfo };
    }
    let data = _pcbInterfaceInfo[pcbId].allInterfaceCompNetData;

    //get selected signals
    this.allMergeData = data.filter(item => _pcbInterfaceInfo[pcbId].selectKeys.includes(item.interfaceKey));
    if (!this.allMergeData.length) {
      return { _pcbInterfaceInfo }
    }
    //find signal type
    const filterSignalTypes = [...new Set(this.allMergeData.map(item => item.type))];

    if (filterSignalTypes && filterSignalTypes.length > 1) {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("Different types of signals do not support automatic merge.")
      return { _pcbInterfaceInfo, errorMsgs: this.errorMsgs, mergeStatus: this.mergeStatus }
    }
    this.config = pcbConfig[pcbId] || { advancedConfig: {} };
    this.signalType = filterSignalTypes[0];

    //merge signals
    this.mergeSignalsByType();
    //add merged signals to pcbInterfaceInfo
    this.getNewSignals();

    this.errorMsgs = [...new Set(this.errorMsgs)];

    return {
      _pcbInterfaceInfo: this.pcbInterfaceInfo,
      errorMsgs: this.errorMsgs,
      mergeStatus: this.mergeStatus,
      mergeSignals: this.mergeSignals || []
    };
  }

  getNewSignals = () => {
    switch (this.mergeStatus) {
      case MERGE_SIGNAL_SUCCESS:
        let data = this.pcbInterfaceInfo[this.designId].allInterfaceCompNetData || [];
        //find signals info -> interfaceKey, type,....
        const dataIndex = data.findIndex(item => this.pcbInterfaceInfo[this.designId].selectKeys.includes(item.interfaceKey))
        const findData = data[dataIndex] || {};
        //add merged new signals
        data[dataIndex] = {
          ...findData,
          content: this.mergeSignals || []
        }
        //filter prev selected signals
        data = data.filter(item => item.interfaceKey === findData.interfaceKey || !this.pcbInterfaceInfo[this.designId].selectKeys.includes(item.interfaceKey));
        //clear selected keys
        this.pcbInterfaceInfo[this.designId].selectKeys = [];
        this.pcbInterfaceInfo[this.designId].allInterfaceCompNetData = data;
        break;
      case FAILED:
        //return error message
        break;
      case NEED_MANUAL:
        //Not available
        break;
      default: break;
    }
  }

  mergeSignalsByType = () => {
    switch (this.signalType) {
      case CUSTOM:
      case CLKTYPE:
        this.mergeCLKAndCustomSignals();
        break;
      case UART:
        this.mergeUARTSignals();
        break;
      case I2C:
      case I3C:
      case SPMI:
        this.mergeI2CI3CSPMISignals();
        break;
      case I2S:
        this.mergeI2SSignals();
        break;
      case SPI:
      case QSPI:
      case ESPI:
        this.mergeSpiQspiEspiSignals();
        break;
      default:
        this.mergeStatus = FAILED;
        this.errorMsgs.push("The current type of signals does not support the merge.")
        break;
    }
  }

  mergeCLKAndCustomSignals = () => {
    const allSignals = this.allMergeData.map(item => item.content).flat(2);
    const nets = allSignals.map(item => item.nets).flat(2);
    const findSignal = allSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
    if (findSignal && nets.length) {
      this.mergeSignals.push({
        ...findSignal,
        nets
      })
      this.mergeStatus = MERGE_SIGNAL_SUCCESS;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("Automatic merge signals failed!");
    }
  }

  mergeUARTSignals = () => {
    //tx signal / rx signal
    const TXList = this.config.advancedConfig.TX, RXList = this.config.advancedConfig.RX;
    const allSignals = this.allMergeData.map(item => item.content).flat(2);

    const txSignals = allSignals.filter(item => TXList.includes(item.name)),
      rxSignals = allSignals.filter(item => RXList.includes(item.name));

    if (txSignals.length && rxSignals.length) {
      const txNets = txSignals.map(item => item.nets).flat(2),
        rxNets = rxSignals.map(item => item.nets).flat(2);

      const findTxSignal = txSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0],
        findRxSignal = rxSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];

      this.mergeSignals.push(
        {
          ...findTxSignal,
          nets: txNets
        },
        {
          ...findRxSignal,
          nets: rxNets
        });
      this.mergeStatus = MERGE_SIGNAL_SUCCESS;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("Automatic merge signals failed!");
    }
  }

  mergeI2CI3CSPMISignals = () => {
    //clk signal (only one), data signals (one or more)
    let clkStatus = false, dataStatus = false;
    const CLKList = this.config.advancedConfig.CLK, DataList = this.config.advancedConfig.Data;
    const allSignals = this.allMergeData.map(item => item.content).flat(2);

    //merge clk signals 
    const clkSignals = allSignals.filter(item => CLKList.includes(item.name));

    if (clkSignals.length) {
      const findClk = clkSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
      this.mergeSignals.push({
        ...findClk,
        nets: [...clkSignals.map(item => item.nets).flat(2)]
      })
      clkStatus = true;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("CLK signals merge failed!");
    }

    //merge data signals
    const filterDataLength = this.allMergeData.filter(item => item.content.filter(it => DataList.includes(it.name)).length === 1).length
    const filterDataSignals = allSignals.filter(item => DataList.includes(item.name));
    //All selected signals have only one data signal
    if (filterDataLength === this.allMergeData.length) {
      if (filterDataSignals.length) {
        const findData = filterDataSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
        this.mergeSignals.push({
          ...findData,
          nets: [...filterDataSignals.map(item => item.nets).flat(2)]
        });
        dataStatus = true;
      } else {
        this.errorMsgs.push("Data signals merge failed!");
        this.mergeStatus = FAILED;
      }
    } else {
      this.mergeSignals.push(...JSON.parse(JSON.stringify(filterDataSignals)));
      this.mergeStatus = MERGE_SIGNAL_SUCCESS;
      dataStatus = true;
    }

    if (clkStatus && dataStatus) {
      this.mergeStatus = MERGE_SIGNAL_SUCCESS;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("Automatic merge signals failed!");
    }
  }

  mergeI2SSignals = () => {
    //clk signal (only one), data signals (one or more), ws signals (0 or 1)
    let clkStatus = false, dataStatus = false;
    const CLKList = this.config.advancedConfig.CLK;
    const allSignals = this.allMergeData.map(item => item.content).flat(2);

    //merge clk signals 
    const clkSignals = allSignals.filter(item => CLKList.includes(item.name));

    if (clkSignals.length) {
      const findClk = clkSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
      this.mergeSignals.push({
        ...findClk,
        nets: [...clkSignals.map(item => item.nets).flat(2)]
      })
      clkStatus = true;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("CLK signals merge failed!");
    }
    //merge WS signals 
    const wsSignals = allSignals.filter(item => item.name === "WS");

    if (wsSignals.length) {
      const findWs = wsSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
      this.mergeSignals.push({
        ...findWs,
        nets: [...wsSignals.map(item => item.nets).flat(2)]
      })
    }

    //merge data signals
    const filterDataLength = this.allMergeData.filter(item => item.content.filter(it => !CLKList.includes(it.name) && it.name !== "WS").length === 1).length
    const filterDataSignals = allSignals.filter(item => !CLKList.includes(item.name) && item.name !== "WS");
    //All selected signals have only one data signal
    if (filterDataLength === this.allMergeData.length) {

      if (filterDataSignals.length) {
        const findData = filterDataSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
        this.mergeSignals.push({
          ...findData,
          nets: [...filterDataSignals.map(item => item.nets).flat(2)]
        });
        dataStatus = true;
      } else {
        this.errorMsgs.push("Data signals merge failed!");
        this.mergeStatus = FAILED;
      }
    } else {
      this.mergeSignals.push(...JSON.parse(JSON.stringify(filterDataSignals || [])));
      dataStatus = true;
    }

    if (clkStatus && dataStatus) {
      this.mergeStatus = MERGE_SIGNAL_SUCCESS;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("Automatic merge signals failed!");
    }
  }

  mergeSpiQspiEspiSignals = () => {
    //clk signal (only one), data signals (one or more), ws signals (0 or 1)
    let clkStatus = false;
    const CLKList = this.config.advancedConfig.CLK;
    const allSignals = this.allMergeData.map(item => item.content).flat(2);

    //merge clk signals 
    const clkSignals = allSignals.filter(item => CLKList.includes(item.name));

    if (clkSignals.length) {
      const findClk = clkSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
      this.mergeSignals.push({
        ...findClk,
        nets: [...clkSignals.map(item => item.nets).flat(2)]
      })
      clkStatus = true;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("CLK signals merge failed!");
    }
    //merge data(MISO/MOSI/CS/IO/SI/SO...) signals 
    //Filter clk signals
    const allDataSignals = allSignals.filter(item => !CLKList.includes(item.name));
    const allSignalNames = allDataSignals.map(item => item.name)

    let notMergeSignals = [];
    for (let name of [...new Set(allSignalNames)]) {
      const filterNames = allSignalNames.filter(item => item === name);
      if (filterNames.length === 1) {
        notMergeSignals.push(name);
        continue;
      }
      const filterDataSignals = allDataSignals.filter(item => item.name === name);

      const findSignal = filterDataSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
      this.mergeSignals.push({
        ...findSignal,
        nets: [...filterDataSignals.map(item => item.nets).flat(2)]
      });
    }

    if (notMergeSignals.length) {
      for (let name of notMergeSignals) {
        const nameType = this.findSignalType(name);
        const filterSignals = allDataSignals.filter(item => nameType === this.findSignalType(item.name) && name === item.name);

        if (!filterSignals.length) {
          continue;
        }
        const findSignal = filterSignals.sort((a, b) => a.signalMergeName.length - b.signalMergeName.length)[0];
        this.mergeSignals.push({
          ...findSignal,
          nets: [...filterSignals.map(item => item.nets).flat(2)]
        });
      }
    }

    if (clkStatus) {
      this.mergeStatus = MERGE_SIGNAL_SUCCESS;
    } else {
      this.mergeStatus = FAILED;
      this.errorMsgs.push("Automatic merge signals failed!");
    }
  }

  findSignalType = (signalName) => {
    switch (signalName) {
      case "MISO":
      case "SO":
      case "DO":
        return "MISO";
      case "MOSI":
      case "SI":
      case "DI":
        return "MOSI";
      default: return signalName;
    }
  }
}

export {
  MERGE_SIGNAL_SUCCESS,
  NEED_MANUAL
}