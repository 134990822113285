import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { select } from 'd3-selection';
import { range } from 'd3-array';
import { unitChange } from '../../../helper/mathHelper';

class EffectBarCanvas {
  constructor(props) {
    this.svgEle = props.svgEle;
    this.title = props.title;
    this.powerNet = props.powerNet;
    this.yTitle = props.yTitle;
    this.key = props.key;
    this.mouseMove = props.mouseMove;
    this.data = [];
    this.color = [];
  }

  setColor = (color) => {
    this.color = color;
  }

  getBarColor = (color) => {
    return this.color || [];
  }

  setData = (data) => {
    this.data = data;
  }

  initSvg = () => {
    const svg = select(this.svgEle)
      .attr('class', 'plot full-fill')
    svg.selectAll('g').remove();
  }

  draw = () => {
    if (!this.svgEle) {
      return;
    }

    this.initSvg();

    const svg = select(this.svgEle)
      .attr('class', 'plot full-fill')

    const svgWidth = this.svgEle.clientWidth;
    const svgHeight = this.svgEle.clientHeight;
    const plotWidth = svgWidth - 100;
    const plotHeight = svgHeight - 50;

    const data = this.data;
    const xDomain = data.map(item => (`${item.port} - ${item.powerPins[0]}`));
    const xScale = scaleBand()
      .domain(xDomain)
      .range([0, plotWidth])
      .paddingInner(0.4)
      .paddingOuter(0.2)

    const key = this.key
    const array = parseDataToArray(data, this.color, key);
    const maxGroupLength = array.length;
    const firstDomain = data && data[0] ? `${data[0].port} - ${data[0].powerPins[0]}` : '';
    const xGroupScale = scaleBand()
      .domain(range(maxGroupLength))
      .range([0, xScale(firstDomain)])

    const yScale = scaleLinear().range([plotHeight, 0]);

    const xAxis = axisBottom(xScale)
      .ticks(10)
      .tickValues(xDomain)

    const yAxis = axisLeft(yScale)
      .ticks(10)

    // The bar chart
    const gRoot = svg.append("g")
      .attr("transform", "translate(80, 10)");

    xScale.domain();

    const values = data.map(d => d.value).flat(2);
    let maxData = values && values[0] ? values[0][key] : 0;
    let minData = values && values[0] ? values[0][key] : 0;
    for (let value of values) {
      if (Math.abs(value[key]) > Math.abs(maxData)) {
        maxData = value[key];
      }
      if (Math.abs(value[key]) < Math.abs(minData)) {
        minData = value[key];
      }
    }

    let multiple = 10
    if (maxData && maxData > 1) {
      // Get the multiple of the maximum value of y
      let _maxData = JSON.parse(JSON.stringify(maxData))
      let number = 0
      while (_maxData > 1) {
        _maxData = _maxData / 10;
        number++;
      }
      multiple = Math.pow(10, number - 1)
    }

    let minY = Math.floor(minData * multiple) / multiple;
    if (minY < 0) {
      minY = 0
    }

    let _maxY = Math.ceil(maxData)
    let absY = Math.abs(_maxY)
    let negative = _maxY < 0 ? true : false;

    if (absY < 15) {
      _maxY = negative ? absY + 1 : _maxY;
    } else if (absY < 32) {
      _maxY = Math.ceil(Math.abs(maxData) / 2) * 2
    } else if (absY < 50) {
      _maxY = Math.ceil(Math.abs(maxData) / 5) * 5
    } else if (absY < 100) {
      _maxY = Math.ceil(Math.abs(maxData) / 10) * 10
    } else {
      _maxY = Math.ceil(Math.abs(maxData) / multiple) * multiple;
    }

    if (negative) {
      _maxY = - (_maxY)
    }

    yScale.domain([0, _maxY]);

    // axes
    let xTransform = 'translate(-0)'
    if (xScale.domain().length > 10) {
      xTransform = 'translate(-20 20) rotate(-45)'
    }

    let xFontSize = xScale.domain().length > 10 ? 9 : 12;

    let dx = '10px';
    if (xDomain[0]) {
      dx = xDomain[0].length * 4 + 'px'
    }

    gRoot.append("g")
      .attr("class", "axis effect-bar-chart-xAxis")
      .attr("transform", "translate(0," + plotHeight + ")")
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", dx)
      .attr('dy', "1.3em")
      .attr('font-size', xFontSize)
      .attr('transform', xTransform);

    gRoot.append("g")
      .attr("class", "axis effect-bar-chart-yAxis")
      .call(yAxis)
      .attr('font-size', 13);

    const powerNet = this.powerNet.replace(/[^\d\w]/g, '');


    gRoot.append("g")
      .selectAll("g")
      .data(array)
      .enter()
      .append("g")
      .attr("transform", function (d, i) { return `translate(${xGroupScale(i) ? xGroupScale(i) : 0}, 0)` })
      .selectAll("rect")
      .data(function (d) { return d })
      .enter()
      .append("rect")
      .style("fill", function (d) { return d.color })
      .attr("class", function (d, i) { return `${key}-${d.id}-${powerNet} ${key}-${d.id}-${powerNet}-${i}` })
      .attr("x", function (d, i) {
        if (xDomain.length < 9) {
          return ((plotWidth / (xDomain.length * 2)) * (2 * i + 1)) - 25 + d.index * 7
        } else {
          return xScale(d.x) + xGroupScale(d.index) + 2
        }
      })
      .attr("width", xGroupScale.bandwidth())
      .attr("y", function (d) {
        return yScale(d.value);
      })
      .attr("height", function (d) {
        return plotHeight - yScale(d.value);
      })
      .on('mouseover', (d, i) => {
        this.mouseMove && this.mouseMove(true, powerNet)
        svg.selectAll(`.${key}-${d.id}-${powerNet}-${i}`)
          .style('stroke-width', '4')
          .style('stroke', 'red')
          .style('stroke-linejoin', 'round');
        gRoot.append('text')
          .attr('font-family', 'sans-serif')
          .attr('font-size', 13)
          .attr('text-anchor', 'middle')
          .attr('class', 'divergence')
          .attr('x', function (x, y) {
            if (xDomain.length < 9) {
              return ((plotWidth / (xDomain.length * 2)) * (2 * i + 1)) + d.index * 7
            }
            return xScale(d.x);
          })
          .attr('y', yScale(d.value) - 20)
          .attr('dx', '8px')
          .text(`${d.value.toFixed(2)} (${d.name})`);
      })
      .on('mouseout', (d, i) => {
        this.mouseMove && this.mouseMove(false, powerNet)
        svg.selectAll('.divergence').remove();
        svg.selectAll(`.${key}-${d.id}-${powerNet}-${i}`)
          .style('stroke-width', '0')
          .style('stroke', 'white');
      });

    const yLabel = 'translate(' + -55 + ' ' + plotHeight / 2 + ') rotate(-90)';

    gRoot.append('text')
      .text(this.yTitle)
      .attr('class', 'effect-bar-chart-yAxis-name')
      .style('text-anchor', 'middle')
      .attr('font-size', 14)
      .attr('fill', '#0000a6')
      .attr('transform', yLabel);

    // let xTitleY = xScale.domain().length > 10 ? svgHeight : svgHeight - 5;
    // gRoot.append('text')
    //   .text(this.title)
    //   .attr('class', 'effect-bar-chart-xAxis-name')
    //   .attr('x', plotWidth / 2)
    //   .attr('y', xTitleY)
    //   .attr('fill', '#000000')
    //   .attr('font-size', xFontSize)
    //   .style('text-anchor', 'middle');
  }

  changeBarColor = () => {
    const { svgEle, color, key, powerNet } = this;
    const svg = select(svgEle);
    for (let _color of color) {
      svg.selectAll(`.${key}-${_color.id}-${powerNet.replace(/[^\d\w]/g, '')}`)
        .style('fill', _color.color)
    }
  }
}

function changeEffectToCanvasData(data, compares = []) {
  let barData = [];
  for (let item of data) {
    const { id, name, effects, type } = item;
    let map = {};
    if (type === 'comparison') {
      const compare = compares.find(item => item.verificationId === id || item.historys.map(h => h.historyId).includes(id)) || {};
      const maps = compare.map || [];
      map = maps.find(i => i.id === id) || {}
    }
    for (let effect of effects) {
      let _effect = { ...effect };
      if (type === 'comparison') {
        _effect = getLinkedEffect(_effect, map);
      }
      const { powerNet, portInformation } = _effect;
      const findIndex = barData.findIndex(bd => bd.powerNet === powerNet);
      if (findIndex < 0) {
        const ports = portInformation.map(port => {
          const res = unitChange({ num: port.res, oldUnit: 'Ω', newUnit: 'mΩ' }).number
          const ind = unitChange({ num: port.ind, oldUnit: 'H', newUnit: 'pH' }).number
          return { ...port, value: [{ res, ind, id, name }] }
        })
        barData.push({ powerNet, ports })
      } else {
        portInformation.forEach(port => {
          const powerPins = port.powerPins;
          const _findIndex = barData[findIndex].ports.findIndex(ps =>
            ps.powerPins.every(p => powerPins.includes(p) || powerPins.every(p => ps.powerPins.includes(p))));
          const res = unitChange({ num: port.res, oldUnit: 'Ω', newUnit: 'mΩ' }).number
          const ind = unitChange({ num: port.ind, oldUnit: 'H', newUnit: 'pH' }).number
          if (_findIndex > -1) {
            barData[findIndex].ports[_findIndex].value.push({ res, ind, id, name });
          } else {
            barData[findIndex].ports.push({ ...port, value: [{ res, ind, id, name }] })
          }
        })
      }
    }
  }
  return barData.map((table, index) => {
    const ports = table.ports.map((port, index) => ({ ...port, port: index + 1 }));
    return { ...table, ports }
  });
}

function parseDataToArray(data, color, key) {
  let array = [];
  for (let _color of color) {
    let _data = [...data];
    _data.forEach(d => {
      const x = `${d.port} - ${d.powerPins[0]}`;
      d.value = d.value.map(v => ({ ...v, x }))
    })
    const values = _data.map(d => d.value.find(v => v.id === _color.id));
    if (values.every(v => v === undefined)) {
      continue;
    }

    const index = array.length;
    array.push(values.map(v => v ? { value: v[key], ..._color, x: v.x, index } : { value: 0, ..._color, index }))
  }
  return array
}

function getLinkedEffect(effect, map) {
  const _effect = { ...effect };
  const linkedEffect = map.children || [];
  const findLinked = linkedEffect.find(link => link.id === _effect.powerNet);
  if (findLinked && findLinked.bind) {
    _effect.powerNet = findLinked.bind;
    const linkedPorts = findLinked.children || [];
    _effect.portInformation.forEach(portInfo => {
      const findPort = linkedPorts.find(link => link.id === portInfo.port);
      if (findPort && findPort.bind && findPort.currentPins && findPort.currentPins.length) {
        portInfo.powerPins = [...findPort.currentPins]
      }
    })
  }
  return _effect
}

export {
  changeEffectToCanvasData,
  EffectBarCanvas
};