import React, { PureComponent, Fragment } from "react";
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown } from "antd";
import DelConfirm from '@/components/DelConfirm';
import { isChrome } from '../../services/helper/browser';
import permissionData, { getLinkByPageType } from '@/services/helper/data/permissionData';
import { checkoutLicensePromise } from '../../services/License/licenseCtrl';
import history from '../../services/history';
import { showLicenseErrorMsg } from '../../services/api/utility';
import { ANDES, ANDES_V2, FASTPI, ROCKY, SIERRA, CASCADE, HIMALAYAS } from "../../constants/pageType";
import { XANDES } from "../../constants/permissionType";
import { getVersionPromise } from '../../services/authCtrl';
import './index.css';

class ProjectMenu extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      browserVisible: false
    }
  }

  okConfirm = () => {
    this.setState({
      browserVisible: false
    })
  }

  linkClick = async (e) => {
    const chrome = isChrome();
    this.setState({
      browserVisible: chrome ? false : true
    })
    if (!chrome) return;
    const page = e.key;
    const license = await checkoutLicensePromise(page);
    if (!chrome) return;
    if (license) {
      history.push(getLinkByPageType(page));
    } else {
      history.push('/');
      showLicenseErrorMsg();
    }
  }

  reloadPage = async (product) => {
    const chrome = isChrome();
    this.setState({
      browserVisible: chrome ? false : true
    });
    if (!chrome) {
      return;
    }
    const { email } = this.props;
    const token = localStorage.getItem('token');
    if (!email || !token) {
      return;
    }
    const res = await getVersionPromise();
    const { systemVersion } = this.props;
    //version is updated, reloading page
    if (res && res.version && (!systemVersion || res.version !== systemVersion)) {
      window.location.reload();
      window.location.href = `/${product.toLowerCase()}`;
    }
  }

  clickMenu = ({ key }) => {
    this.linkClick({ key })
    this.reloadPage(key)
  }

  menu = () => {
    const items = [];
    permissionData.hasAndesV2() && items.push({ key: ANDES_V2, label: 'Andes' })
    permissionData.hasAndes() && items.push({ key: ANDES, label: 'Andes V1' })
    permissionData.hasCascade() && items.push({ key: CASCADE, label: 'Cascade' })
    permissionData.hasPDN() && items.push({ key: FASTPI, label: 'FastPI' })
    permissionData.hasRocky() && items.push({ key: ROCKY, label: 'Rocky' })
    permissionData.hasSierra() && items.push({ key: SIERRA, label: 'Sierra' })
    permissionData.hasHimalayas() && items.push({ key: HIMALAYAS, label: 'Himalayas' })
    permissionData.hasXAndes() && items.push({ key: XANDES, label: 'XAndes' })
    return items
  }

  render() {
    const { browserVisible } = this.state;
    const errorMsg = 'Non Chrome browser is not supported in the simulation platform.';
    const items = this.menu();
    return (
      <Fragment>
        <div>
          <Dropdown menu={{ items, onClick: this.clickMenu }} overlayClassName='project-menu' placement="bottom">
            <div className='aurora-menu'>
              <span className='aurora-menu-a'>Solutions</span>
              <MenuOutlined className='aurora-menu-icon' />
            </div>
          </Dropdown>
        </div>
        {browserVisible ? <DelConfirm
          data={null}
          removeCancel={true}
          deleteConfirm={this.okConfirm}
          message={errorMsg}
        /> : null}
      </Fragment>
    );
  }
}

export default ProjectMenu;