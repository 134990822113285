import http from '../http';

/**
 * Create project
 * 2019.09.09
 * 
 * @export
 * @param {String} name Name
 * @param {String} typeName Type name: PinToPin | Andes
 * @returns XMLHttpRequest
 */
export function createProject({ name, typeName, version = '' }) {
  return http.post(`/my-project/`, {
    id: "",
    name,
    typeName,
    version: version
  });
};

/**
 * Get projects by type name
 * 2019.09.09
 *
 * @export
 * @param {String} typeName PinToPin | DDR | PDN | SerDes
 * @returns XMLHttpRequest
 */
export function getProjectsByTypeName(typeName) {
  return http.get(`/my-project/type/${typeName}`);
};

/**
 * Delete projects by projects id
 * 2019.09.09
 * 
 * @export
 * @param {Array} ids ids
 * @returns XMLHttpRequest
 */
export function deleteProjectsByIDs(ids) {
  return http.delete(`/my-project/`, {
    data: ids
  });
};

/**
 * upload debug file
 * 2019.09.19
 *
 * @export
 * @param {String} projectId Project id
 * @param {file} file debug file
 * @returns XMLHttpRequest
 */
export function UploadDebugFile(file, verificationId, status, UploadProgress) {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('resultType', status);
  return http.post(`/project/verification/${verificationId}/PDN/upload`, formData, UploadProgress, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * Clean up transh (for FastPI/sierra nevada)
 * 2019.09.25
 *@param {string} typeName ('PinToPin'/'PDN')
 * @returns XMLHttpRequest
 */
export function cleanupTrash(typeName) {
  return http.delete(`/my-project/cleanup/${typeName}`);
}

/**
 * Get trash list (for FastPI or Sierra)
 * 2019.11.15
 * @param {String} typeName - PinToPin|PDN
 * @returns XMLHttpRequest
 */
export function getTrashList(typeName) {
  return http.get(`/my-project/trash/${typeName}`);
}

/**
 * Get projects by type name and verision
 * 2019.11.11
 * 2020.01.17 + add version
 *
 * @export
 * @param {String} typeName PinToPin | Andes
 * @param {String} version version
 * @returns XMLHttpRequest
 */
export function getProjectsByTypeNameVersion(typeName, version) {
  return http.get(`/my-project/type/${typeName}/version/${version}`);
};

/**
 * Get project content by project id and type name
 * 2020.01.17
 *
 * @export
 * @param {String} projectId Project id
 * @param {String} typeName Type name: sierra | Andes
 * @returns XMLHttpRequest
 */
export function getProjectContentTypeName({ projectId, typeName }) {
  return http.get(`/my-project/project/${projectId}/type/${typeName}`);
}

/**
 * sierra project copy
 * 2020/03/04
 *
 * @export
 * @param {String} projectId Project id
 * @param {String} projectName new name
 * @returns XMLHttpRequest
 */
export function sierraCopyProject({ projectId, projectName }) {
  return http.post(`/sierra/project/${projectId}/copy?projectName=${projectName}`);
}

/**
 * sierra interface copy
 * 2021/10/27
 *
 * @export
 * @param {String} verificationId 
 * @param {String} name new name
 * @returns XMLHttpRequest
 */
export function sierraCopyInterface({ verificationId, interfaceName }) {
  return http.post(`/sierra/project/interface/copy?interfaceName=${interfaceName}&verificationId=${verificationId}`);
}

/**
 * project rename
 * 2020/05/14
 *
 * @export
 * @param {String} projectId Project id
 * @param {String} projectName new name
 * @returns XMLHttpRequest
 */
export function projectRename({ projectId, projectName }) {
  return http.put(`/my-project/${projectId}/rename?projectName=${projectName}`);
}



export function getPDNReportConfig(projectId) {
  return http.get(`/fastpi/${projectId}/report/config`);
}

export function getPDNReportStatus(projectId) {
  return http.get(`/fastpi/${projectId}/report/status`);
}

export function getPDNReport(projectId, format) {
  return http.get(`/fastpi/${projectId}/report/${format}`, {
    responseType: 'arraybuffer'
  });
}

export function generatePDNReport({ projectId, param }) {
  return http.post(`/fastpi/${projectId}/report`, param)
}

export function postPDNReportConfig(param) {
  return http.post('/fastpi/report/config', param)
}

// note api

export function getNoteDetail({ product, id, type = "verification" }) {
  return http.get(`/${product}/notes?id=${id}&type=${type}`);
}

export function saveNote({ product, type = "verification", id, note }) {
  return http.post(`/${product}/edit/notes`, {
    id,
    type,
    notes: note
  })
}

/**
 * update project config
 * @export
 * @param  {string} projectId
 * @param  {string} hspiceOption
 * @param  {string} ansysOption
 * @returns 
 */
export function updateProjectServiceOptionsConfig({ projectId, options }) {
  return http.put(`/my-project/project/${projectId}/option`, options);
}

/**
 * get project config
 * @export
 * @param  {string} projectId
 * @returns 
 */
export function getProjectServiceOptionsConfig(projectId) {
  return http.get(`/my-project/project/${projectId}/option`);
}

export function uploadProjectConnections({ file, projectId }) {
  const formData = new FormData();
  formData.append('file', file);
  /*  formData.append('projectId', projectId) */
  return http.post(`/my-project/connection/excel/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function getProjectConnections(projectId) {
  return http.get(`/my-project/board-connections?projectId=${projectId}`);
}

export function saveProjectConnections({ projectId, connections, append = false }) {
  return http.post(`/my-project/board-connections`, { projectId, connections, append });
}
/**
 * 
 * @param {string[]} projectIds  
 * @returns 
 */
export function sortProject(projectIds) {
  return http.post(`/my-project/sort`, { projectIds })
}