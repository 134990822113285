import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select, Divider } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import projectDesigns from '@/services/helper/projectDesigns';
import { getAllCascadeComponents } from '../../../../services/Cascade/helper/setupData';
import { IGNORE } from '../../../../constants/componentType';
import AddConnection from './addConnection';
import componentSetting from '../../../../services/Cascade/helper/compSettingHelper';
import { IMPEDANCE_DIE, IMPEDANCE_INTERPOSER } from '../../../../services/Cascade/constants';
import designConstructor from '../../../../services/helper/designConstructor';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import { LAYOUT } from '../../../../constants/designType';
import { splitArrayToArrays } from '../../../../services/helper/arrayHelper';
import { AC } from '../../../../constants/treeConstants';
import auroraDBJson from '../../../../services/Designs/auroraDbData';

const { Option, OptGroup } = Select;
class PCBPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 800,
      maxHeight: 1000,
      pcbId: "",
      title: 'PCB/Package/Die/Interposer',
      pcbList: [],
      packageList: [],
      connectors: [],
      connComps: [],
      contentWidth: 600,
      contentHeight: 500,
      drawStatus: false
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const contentRoot = document.getElementById('cascade-net-connection-content') || {}
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 800),
      maxHeight: getPanelMaxHeight(offset, 1000),
      contentWidth: contentRoot.offsetWidth || 600,
      contentHeight: contentRoot.offsetHeight || 500,
      drawStatus: !this.state.drawStatus
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getLayoutList();
  }

  closeModal = () => {
    const { connectors, pcbId } = this.state;
    if (pcbId === IMPEDANCE_DIE) {
      this.props.createDie()
    } else if (pcbId === IMPEDANCE_INTERPOSER) {
      this.props.saveHasInterposer(true)
    } else if (pcbId) {
      this.props.createNewLayout(pcbId, connectors)
    }
    this.props.closeModal(false)
  }

  PCBSelect = (pcbId) => {
    const title = 'PCB/Package/Die/Interposer';
    this.setState({
      pcbId,
      title,
      connectors: []
    }, async () => {
      if (pcbId !== IMPEDANCE_DIE && pcbId !== IMPEDANCE_INTERPOSER) {
        const connComps = await this.getConnectionComps(pcbId);
        this.setState({ connComps })
      }
    })
  }

  getConnectionComps = async (pcbId) => {
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      const preData = await preLayoutData.getPreLayout(pcbId);
      if (preData) {
        const { content } = preData;
        const { components } = content;
        return components
      }
      return []
    }
    const setting = componentSetting.getSetting({ designId: pcbId });
    await auroraDBJson.getAuroraJson(pcbId, setting);
    const { pcbList } = this.state;
    const isPCB = pcbList.find(item => item.id === pcbId) ? true : false;
    if (isPCB) {
      const components = getAllCascadeComponents({ pcbId });
      const connComps = [...components.values()].filter(comp => comp.type === IGNORE).sort((a, b) => a.name > b.name ? 1 : -1) || []
      return connComps
    }
    return []
  }

  getLayoutList = () => {
    const { projectId } = this.props;
    const pcbList = projectDesigns.getAvailablePostLayouts(projectId);
    const packageList = projectDesigns.getAvailablePackages(projectId);
    const preLayoutList = projectDesigns.getAvailablePreLayouts(projectId).filter(design => design && design.content && (!design.content.electric || design.content.electric === AC));
    const preLayoutRule = (item) => {
      return item.type === LAYOUT ? true : false;
    }
    const { trueArray: preLayoutPCBs, falseArray: preLayoutPackages } = splitArrayToArrays({ array: preLayoutList, rule: preLayoutRule })
    this.setState({
      pcbList: [...pcbList, ...preLayoutPCBs],
      packageList: [...packageList, ...preLayoutPackages]
    })
  }

  addConnector = () => {
    const { connectors, pcbId } = this.state;
    this.setState({
      connectors: [
        ...connectors,
        { name: "", designId: pcbId, netShip: [{ connector: "", designId: "", nets: [] }] }
      ]
    })
  }

  addConnectorShip = (index) => {
    const { connectors } = this.state;
    let _connectors = [...connectors];
    _connectors[index].netShip.push({ connector: "", designId: "", nets: [] });
    this.setState({
      connectors: _connectors
    })
  }

  saveConnectorInfo = (index, key, value, resize) => {
    const { connectors } = this.state;
    let _connectors = [...connectors];
    _connectors[index][key] = value;
    this.setState({
      connectors: _connectors
    }, () => {
      if (resize) {
        this.resize()
      }
    })
  }

  timeResize = () => {
    setTimeout(() => {
      this.resize()
    }, 1000)
  }

  pcbSelectRender = () => {
    const { pcbId, title, pcbList, packageList } = this.state;
    const { designIds, hasDie, hasInterposer } = this.props;
    const hasPackage = packageList.find(item => designIds.includes(item.id));
    return <div className="cascade-add-pcb-interposer-content">
      <span className="font-bold cascade-setup-title-color-only">{title}</span>
      <Select
        value={pcbId}
        onChange={this.PCBSelect}
        getPopupContainer={(() => document.getElementById('root'))}
        showSearch={true}
        className="pcb-select"
        popupClassName="aurora-select-dropdown"
      >
        <OptGroup label='PCB'>
          {pcbList.map(option => {
            return <Option key={option.name} title={option.name} value={option.id} disabled={designIds.includes(option.id)}>{option.name}</Option>
          })}
        </OptGroup>
        <OptGroup label='Package'>
          {packageList.map(option => {
            return <Option key={option.name} title={option.name} value={option.id} disabled={hasPackage}>{option.name}</Option>
          })}
        </OptGroup>
        <OptGroup label={IMPEDANCE_DIE}>
          <Option key={IMPEDANCE_DIE} title={hasPackage ? IMPEDANCE_DIE : 'Please add package first.'} value={IMPEDANCE_DIE} disabled={!hasPackage || hasDie}>Die</Option>
        </OptGroup>
        <OptGroup label='Interposer'>
          <Option key={IMPEDANCE_INTERPOSER} title={hasDie ? IMPEDANCE_INTERPOSER : 'Please add die first.'} value={IMPEDANCE_INTERPOSER} disabled={!hasPackage || !hasDie || hasInterposer}>Interposer</Option>
        </OptGroup>
      </Select>
    </div>
  }

  connectorRender = () => {
    const { connectors, connComps, pcbList, pcbId, packageList, contentWidth, contentHeight, drawStatus } = this.state;
    const isPackage = packageList.find(item => pcbId === item.id);
    if (isPackage || pcbId === IMPEDANCE_DIE || pcbId === IMPEDANCE_INTERPOSER || !pcbId) {
      return null
    }
    return (
      <Fragment>
        <Divider />
        <div className="cascade-add-pcb-content">
          <span className="font-bold cascade-setup-title-color">Connectors <PlusCircleOutlined className='cascade-imp-icon' onClick={() => this.addConnector()} /></span>
        </div>
        {
          connectors.map((conn, index) => {
            return <div key={index} className="cascade-setup-border cascade-add-pcb-connector-content">
              <AddConnection
                connector={conn}
                index={index}
                saveConnectorInfo={this.saveConnectorInfo}
                addConnectorShip={this.addConnectorShip}
                deleteShip={this.props.deleteShip}
                connComps={connComps}
                designId={pcbId}
                pcbList={pcbList.filter(item => item.id !== pcbId)}
                getConnectionComps={this.getConnectionComps}
                contentWidth={contentWidth}
                contentHeight={contentHeight}
                drawStatus={drawStatus}
                resize={this.resize}
                getAutoConnectionNet={this.props.getAutoConnectionNet}
              />
            </div>
          })
        }
      </Fragment>
    );
  }

  render() {
    const { maxWidth, maxHeight } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='cascade-add-pcb-panel'
          title="Add PCB/Package/Die/Interposer"
          onCancel={this.closeModal}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { defaultWidth: 800 })}
          maxHeight={maxHeight}
          position='panel-center'
          draggable
          minHeight={150}
          minWidth={200}
          defaultTop={100}
          resizeEnd={this.timeResize}
        >
          {this.pcbSelectRender()}
          {this.connectorRender()}
        </Panel>
        <div id="cascade-add-connection-dialog"></div>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}


export default PCBPanel;
