import {
  CHANGE_VIEW, SAVE_OPEN_PROJECT_INFO, UPDATE_PROJECT_MENU,
  OPEN_PROJECT, PROJECT_MENU, CHANGE_LAYOUT, SHOW_RESULT,
  CLEAR_CURRENT_PROJECT, UPDATE_TRASH_MENU, TRASH_MENU, CHANGE_VIEW_LIST,
  SAVE_COMPONENTS_NETS_IN_PROJECT, UPDATE_PCBS_LAYOUTDB, CHANGE_TREE_SELECTED_KEY,
  UPDATE_VIEW_STATUS, AUTO_GET_VERIFICATION_LIST, REFRESH_PCB, UPDATE_CURRENT_VERIFICATION_STATUS
} from './actionTypes';

export const changeView = (view, info) => ({
  type: CHANGE_VIEW,
  view,
  ...info
});

export const saveOpenProjectInfo = ({ id, name, designs, verifications }) => ({
  type: SAVE_OPEN_PROJECT_INFO,
  id,
  designs,
  name,
  verifications
});

export const updateProjectMenu = (obj) => ({
  type: UPDATE_PROJECT_MENU,
  obj
});

export const openProject = (id) => ({
  type: OPEN_PROJECT,
  id
});

export const projectMenu = ({ treeItems, projectList }) => ({
  type: PROJECT_MENU,
  treeItems,
  projectList
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  layout
});

export const clearCurrentProject = (status) => ({
  type: CLEAR_CURRENT_PROJECT,
  status
});

export const updateTrashMenu = () => ({
  type: UPDATE_TRASH_MENU
});

export const trashMenu = ({ treeItems }) => ({
  type: TRASH_MENU,
  treeItems
});

export const changeViewList = (viewList, viewType) => ({
  type: CHANGE_VIEW_LIST,
  viewList,
  viewType
});

export const saveComponentsNetsInProject = (pcbIds, status) => ({
  type: SAVE_COMPONENTS_NETS_IN_PROJECT,
  pcbIds,
  status
});

export const updatePCBComponentsNets = (pcbsLayoutDB) => ({
  type: UPDATE_PCBS_LAYOUTDB,
  pcbsLayoutDB
});

export const changeTreeSelected = (selectedKeys) => ({
  type: CHANGE_TREE_SELECTED_KEY,
  selectedKeys
});

export const showResult = (view, info) => ({
  type: SHOW_RESULT,
  view,
  ...info
});

export const updateViewStatus = ({ view, designId, verificationId, getStatus, verificationName }) => ({
  type: UPDATE_VIEW_STATUS,
  view,
  designId,
  verificationId,
  getStatus,
  verificationName
});

export const autoGetVerificationList = (projectId, currentSimInfo) => ({
  type: AUTO_GET_VERIFICATION_LIST,
  projectId,
  currentSimInfo
});

export const refreshPCB = (refreshStatus) => ({
  type: REFRESH_PCB,
  refreshStatus
});

export const updateCurrentVerificationStatus = (verificationStatus) => ({
  type: UPDATE_CURRENT_VERIFICATION_STATUS,
  verificationStatus
})
