import React from 'react';
import { checkRLCValueFormat } from '@/services/helper/dataProcess';
import { splitComponentName } from '../../../services/helper/splitComponent';
import { RLC_TYPES, DDR_COMP_TYPES } from '../../../services/PCBHelper';
import { checkICCompModel } from './ErrorCheck';
import { WAVE } from '../../../services/ExtractionPortsHelper';
import { BALL_TYPE_NONE, USE_BALL_LIST } from '../../../services/ExtractionPortsHelper/portTableHelper';

function getCardErrorCheck(cardInfo, extractionType, libraryInfo = {}) {
  if (!cardInfo) {
    return;
  }
  let error = [];

  const info = cardInfo.info;
  if (!info) return;

  const { Components, /* PowerNets, */ Ports_generate_setup_list = [] } = info;
  const { pkgTouchstoneList, pkgSpiceList, ebdList, ibisList } = libraryInfo;

  //check VTT nets voltage
  /* const VTTNets = info.VTTNets ? info.VTTNets : [];
  let Nets = [];
  PowerNets.forEach(item => {
    if (VTTNets && VTTNets.includes(item.name)) {
      Nets.push(item);
    }
  })
  Nets.forEach(item => {
    if (!item.name) {
      error.push(<p style={{ margin: 0 }}>Power net is not set.</p>)
    } else {
      if (!item.value) {
        error.push(<p style={{ margin: 0 }}>Power net "<span className="font-bold">{item.name}</span>" voltage is not set.</p>)
      }

      if (item.name.match(/gnd/ig) && !item.value) {
        error.push(<p style={{ margin: 0 }}>GND net "<span className="font-bold">{item.name}</span>" voltage is not set.</p>)
      }
    }
  }) */

  // check driver/receiver model
  const ICs = Components.filter(item => DDR_COMP_TYPES.includes(item.type));
  if (ICs.length > 0) {
    ICs.forEach(comp => {
      if (comp.pkg && comp.pkg.type !== "None") {
        if (comp.pkg.type === 'IBIS') {
          const fileExist = ibisList.find(file => comp.pkg.libraryId && file.id === comp.pkg.libraryId && file.name === comp.pkg.name);
          if (!fileExist) {
            error.push(<p style={{ margin: 0 }}>Package "<span className="font-bold">{comp.name}</span>" IBIS file {comp.pkg.name} not exist.</p>)
          }
        }
        error = checkICCompModel({
          model: comp.pkg,
          comp,
          modelType: "Package",
          touchstoneList: pkgTouchstoneList,
          spiceList: pkgSpiceList,
          ebdList,
          error
        })
      }

      const findCompSetup = Ports_generate_setup_list.find(it => it.component === splitComponentName(comp.name)) || {};
      const findPortSetup = findCompSetup.setup || {};
      if (findPortSetup && findPortSetup.portType === "gap" && !comp.gap_size) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" extraction port reference plane distance is not set.</p>)
      }
      if (extractionType === "HFSS" && findPortSetup && findPortSetup.portType === "gap" && comp.gap_size && parseFloat(comp.gap_size) <= 0) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" when the extraction type is "HFSS", the extraction port reference plane distance should be greater than 0.</p>)
      }

      if (findPortSetup && [...USE_BALL_LIST].includes(findPortSetup.portType) && (comp.ball_type && comp.ball_type !== BALL_TYPE_NONE && (!comp.ball_size || !comp.ball_height))) {
        error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" extraction port (ball size / ball height) is not set.</p>)
      }

    });
  }

  // RLC components value
  const RLCs = Components.filter(item => RLC_TYPES.includes(item.type));
  if (RLCs.length > 0) {
    RLCs.forEach(rlc => {
      if (!rlc.value) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" value is not set.</p>)
      } else {
        const rlcValue = checkRLCValueFormat(rlc.value);
        if (!rlcValue) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" value format error.</p>)
        }
      }
    })
  }

  //Cap components Value
  const caps = Components.filter(item => item.type === 'Cap');
  if (caps.length > 0) {
    caps.forEach(rlc => {
      if (!rlc.value) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" RLC value is not set.</p>)
      } else {
        let rValue = rlc.value.r;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue)

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Resistance value format error.</p>);
        }

        //Check the Inductance value format
        let lValue = rlc.value.l;
        let lCheck = checkRLCValueFormat(lValue)

        if (!lCheck) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Inductance value format error.</p>);
        }

        //Check the Capacitance value format
        let cValue = rlc.value.c;
        let cCheck = checkRLCValueFormat(cValue, "Cap");

        if (!cCheck) {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Capacitance value format error.</p>);
        } else if (cCheck && cCheck === "0") {
          error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{rlc.name}</span>" Capacitance value cannot be 0.</p>);
        }
      }
    })
  }

  if (error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }
}

export {
  getCardErrorCheck
}