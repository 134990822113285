import { changeLibraryFormat } from '../../helper/rlcValueFormat';
import { getLibraryData } from './index';

class VRMData {

  constructor() {
    this.Info = {};
  }

  getContent = (vrmIds) => {
    const promises = vrmIds.map(item => {
      const content = this.existInfo(item);
      if (content) {
        return new Promise((resolve, reject) => {
          resolve({ [item]: { ...content } })
        })
      } else {
        return new Promise((resolve, reject) => {
          getLibraryData(item).then(res => {
            if (res && res.libraryData) {

              const libraryUnit = changeLibraryFormat(res.libraryData);
              let vrmData = { ...libraryUnit.libraryData };
              this.Info[item] = vrmData;
              resolve({ [item]: { ...vrmData } })
            } else {
              resolve({ [item]: null })
            }
          }, error => {
            resolve({ [item]: null })
          })
        })
      }
    })
    return Promise.all(promises);
  }

  existInfo = (vrmId) => {
    if (this.Info[vrmId]) {
      return this.Info[vrmId]
    } else {
      return null
    }
  }

  removeContent = (vrmId) => {
    if (this.Info[vrmId]) {
      delete this.Info[vrmId];
    }
  }

  cleanContent = () => {
    this.Info = {}
  }
}

const vrmData = new VRMData();
export default vrmData;