import { parseIBISModelSelector } from "../../LibraryHelper/IBISModelHelper";

class IBISModelHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getIbisFiles() {
    return Object.keys(this);
  }

  deleteModel(id) {
    delete this[id];
  }

  parseModelSelector(id, fileContent) {
    this[id] = parseIBISModelSelector(fileContent);
  }

  /*get model list by filename*/
  getIbisModelList(id, type) {
    const models = this[id];
    return models
  }
}

export default IBISModelHelper;