import {
  RUN_BMA_CHANNEL, GET_CHANNEL_WORKFLOW,
  UPDATE_SIMULATION_REDUCER, CLEAR_SIMULATION_INFO,
  UPDATE_CURRENT_SIMULATION_INFO, UPDATE_CURRENT_SIMULATION_PART_INFO,
  GET_WORKFLOW_MONITOR, CANCEL_WORKFLOW, GET_VERIFICATION_WORKFLOW,
  GET_VERIFICATION_LOG
} from './actionType';

export const runBMAChannel = (runSelectList) => ({
  type: RUN_BMA_CHANNEL,
  runSelectList
})

export const getChannelWorkFlow = (verificationId) => ({
  type: GET_CHANNEL_WORKFLOW,
  verificationId
})

export const updateSimulationReducer = (info) => ({
  type: UPDATE_SIMULATION_REDUCER,
  info
});

export const clearSimulationInfo = () => ({
  type: CLEAR_SIMULATION_INFO
})

export const updateCurrentSimulationInfo = ({ verificationId, info }) => ({
  type: UPDATE_CURRENT_SIMULATION_INFO,
  verificationId,
  info
});

export const updateCurrentSimulationPartInfo = ({ verificationId, info }) => ({
  type: UPDATE_CURRENT_SIMULATION_PART_INFO,
  verificationId,
  info
});

export const getWorkflowMonitor = (workflowId, verificationId) => ({
  type: GET_WORKFLOW_MONITOR,
  workflowId,
  verificationId,
});

export const getVerificationLog = (verificationId) => ({
  type: GET_VERIFICATION_LOG,
  verificationId
})

export const cancelWorkflow = (verificationId) => ({
  type: CANCEL_WORKFLOW,
  verificationId
})

export const getVerificationWorkflow = (verificationId) => ({
  type: GET_VERIFICATION_WORKFLOW,
  verificationId
})