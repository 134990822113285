import { BUFFER_SPICE, IBIS, SPICE } from "../../../constants/libraryConstants";

const ADS = 'ADS', HSPICE = 'HSPICE';
const onlyModelTitle = ['Model File (A)', 'Model File (B)', 'Model File (C)'];

const ctleKeyPrefixs = ['ctleA-', 'ctleB-', 'ctleC-'];
const rxCpadKeyPrefixs = ['rxCpadA-', 'rxCpadB-', 'rxCpadC-'];

const IBISPinAndModelColumns = [
  { title: 'Pin', dataIndex: 'pin', key: 'pin', width: 100 },
  { title: 'Model', dataIndex: 'modelName', key: 'modelName', width: 150 },
  { title: "Corner", dataIndex: 'corner', key: 'corner', width: 80 },
  { title: "V_PU", dataIndex: 'pullUpVoltage', key: 'pullUpVoltage' },
  { title: "V_PD", dataIndex: 'pullDownVoltage', key: 'pullDownVoltage' }
];

const cornerOptions = ['typ', 'fast', 'slow'];

const modelFileType = {
  txModel: [SPICE, BUFFER_SPICE],
  rxModel: [IBIS, SPICE, BUFFER_SPICE],
  RxCpadModel: [SPICE],
  ctleModel: [SPICE],
}

const modelTypes = ['txModel', 'rxModel', 'RxCpadModel', 'ctleModel'];

const clickBoxPosition = [
  {
    key: 'tx-click-box',
    left: 40,
    top: 32,
    className: 'andes-v2-hspice-simulation-canvas-tx-box',
    modalType: 'txModel'
  },
  {
    key: 'rx-click-box',
    left: 525,
    top: 50,
    className: 'andes-v2-hspice-simulation-canvas-rx-box',
    modalType: 'rxModel'
  },
  {
    key: 'rxCpad-click-box',
    left: 700,
    top: 50,
    className: 'andes-v2-hspice-simulation-canvas-rxCpad-box',
    modalType: 'rxCpadModel'
  },
  {
    key: 'ctle-click-box',
    left: 950,
    top: 10,
    className: 'andes-v2-hspice-simulation-canvas-ctle-box',
    modalType: 'ctleModel'
  },
]

const autoMatchText = [
  {
    portLText: "1357",
    portRText: "2468",
    isSwap: true
  },
  {
    portLText: "1234",
    portRText: "5678",
    isSwap: true
  },
  {
    portLText: "1234",
    portRText: "8765",
    isSwap: true
  }
]

export {
  ADS,
  HSPICE,
  onlyModelTitle,
  ctleKeyPrefixs,
  rxCpadKeyPrefixs,
  IBISPinAndModelColumns,
  cornerOptions,
  modelFileType,
  modelTypes,
  clickBoxPosition,
  autoMatchText
}