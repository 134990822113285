import { FASTPI } from "../../constants/pageType";
import { DIELECTRIC, DielectricMaterial, METAL, MetalMaterial, ROUGHNESS_NONE, getDefaultMaterialName } from "../Stackup";
import { StackupJson, StackupLayer } from "../Stackup";
import { editDesignStackup } from "../api/designFile";
import { getDefaultName } from "../helper/setDefaultName";
import CsUnits from "../utility/CsUnits";

class StackupJsonData {
  constructor() {
    this.stackup = null;
    this.initStackup = this.initStackup.bind(this);
    this.getStackup = this.getStackup.bind(this);
    this.getUnitString = this.getUnitString.bind(this);
    this.getStackupTableData = this.getStackupTableData.bind(this);
    this.changeStackupData = this.changeStackupData.bind(this);
    this.createStackup = this.createStackup.bind(this);
    this.getStackupTotalThickness = this.getStackupTotalThickness.bind(this);
  }

  initStackup(stackupJson, designId) {
    let save = false;
    stackupJson.unit = this.changeUnitFormat(stackupJson.unit);
    const { materials, emptyNameMaterial } = this.initMaterialConductivity(stackupJson.materials);
    stackupJson.materials = materials;
    if (emptyNameMaterial) {
      stackupJson.layers.forEach(item => {
        if (!item.material) {
          item.material = emptyNameMaterial;
          save = true;
        }
      })
    }
    if (stackupJson.zones) {
      //init multi zones
      stackupJson.layers.forEach(item => {
        if (item.laminated === undefined) {
          item.laminated = true;
          save = true;
        }
      })
    }
    if (stackupJson.stackups && stackupJson.stackups.length) {
      stackupJson.stackups = stackupJson.stackups.filter(item => !!Object.keys(item).length);
    }
    //add fill_material
    const { stackupJson: newStackup, save: _save } = this.addDefaultFillMaterial(stackupJson);
    stackupJson = newStackup;
    save = save ? save : _save;
    this.stackup = stackupJson;
    if (save && designId) {
      editDesignStackup({ designId, stackup: { ...this.stackup } })
    }
  }

  initMaterialConductivity = (materials) => {
    let emptyNameMaterial = null;
    for (let item of materials) {
      if (!item.name) {
        item.name = getDefaultMaterialName({
          materialType: item.type,
          materialList: materials
        });
        emptyNameMaterial = item.name;
      }
      if (item.type !== "Metal") {
        continue;
      }
      item.sigma = Number(item.sigma).toExponential().replace('+', '');
    }
    return { materials, emptyNameMaterial };
  }

  addDefaultFillMaterial = (stackupJson) => {
    if (!!stackupJson.layers.find(item => /* item.hasOwnProperty("fill_material") && */ !!item.fill_material)) {
      return { stackupJson, save: false };
    }
    //init default generic air dielectric
    const materialNames = stackupJson.materials.map(item => item.name);
    const genericAirName = materialNames.includes("Generic_Air") ? getDefaultName({
      nameList: materialNames,
      defaultKey: "Generic_Air",
      firstIndex: 1,
      key: ""
    }) : "Generic_Air";
    let genericAir = new DielectricMaterial({ name: genericAirName });

    let isAdd = true, indexMetal = 0;
    for (let i = 0; i < stackupJson.layers.length; i++) {
      const layer = stackupJson.layers[i];
      if (layer.type !== METAL) {
        continue;
      }

      indexMetal += 1;

      if (!!layer.fill_material) {
        continue;
      }

      if (i === 0 || i === stackupJson.layers.length - 1) {
        //top/bottom metal layer
        isAdd && stackupJson.materials.push(genericAir);
        isAdd = false;
        stackupJson.layers[i].fill_material = genericAir.name;
      } else if (indexMetal % 2 === 1) {
        //odd
        const prevDielectricName = stackupJson.layers[i - 1] ? stackupJson.layers[i - 1].material : "";
        stackupJson.layers[i].fill_material = prevDielectricName || "";
      } else {
        //even
        const nextDielectricName = stackupJson.layers[i + 1] ? stackupJson.layers[i + 1].material : "";
        stackupJson.layers[i].fill_material = nextDielectricName || "";
      }
    }
    return { stackupJson, save: true };
  }

  changeUnitFormat = (unit) => {
    if (unit === "mils") {
      return "mil";
    }
    return unit;
  }

  getStackup() {
    return this.stackup;
  }

  getUnitString(isUpload) {
    if (this.stackup) {
      return this.stackup.unit || (isUpload ? "" : "mil");
    }
    return isUpload ? "" : "mil";
  }

  getStackupTableData() {
    if (!this.stackup) {
      return [];
    }
    let tableData = [];
    const layers = this.stackup.layers || [];
    const materials = this.stackup.materials || [];
    const stackups = this.stackup.stackups || [];//[{name:"", layers:[]}]
    let indexMetal = 0, indexTable = 1;
    for (let i = 0; i < layers.length; i++) {
      const item = layers[i];
      const layoutLayer = item.type === METAL;
      if (layoutLayer) {
        indexMetal++;
      }
      const roughness = item.roughness || { type: ROUGHNESS_NONE };
      let roughnessObj = {
        roughness
      }
      if (item.bot_roughness && item.side_roughness) {
        roughnessObj = {
          roughness,
          bot_roughness: item.bot_roughness,
          side_roughness: item.side_roughness,
        }
      }
      const findStackupTypes = stackups.filter(it => it.layers && it.layers.find(i => i === item.name));
      const value = {
        indexStackup: i,     // the index will be used for data write back
        indexMetal: layoutLayer ? indexMetal : null,
        indexTable: indexTable,
        name: item.name,
        type: item.type,
        thickness: item.thickness,
        material: item.material,
        stackupLayerTypes: findStackupTypes ? findStackupTypes.map(it => it.name) : [], //"FLEX" / "RIGID" / "PRIMARY"
        ...roughnessObj,
        laminated: item.laminated,
        fill_material: item.fill_material
      };

      if (item.etch_factor) {
        value.etch_factor = item.etch_factor
      }

      tableData.push(value)
      indexTable++;
    }
    return {
      tableData,
      materialList: JSON.parse(JSON.stringify(materials)),
      stackups: stackups.length ? JSON.parse(JSON.stringify(stackups)) : null,
      zones: this.stackup.zones ? JSON.parse(JSON.stringify(this.stackup.zones)) : null,
      bends: this.stackup.bends ? JSON.parse(JSON.stringify(this.stackup.bends)) : null
    }
  }

  changeStackupData({ data, materialList, stackups, zones, unit, version, bends }) {
    let layers = [];
    for (let item of data) {
      layers.push(new StackupLayer({ ...item }, true));
    }
    this.stackup = new StackupJson({
      layers,
      materials: materialList,
      unit,
      version,
      stackups,
      zones,
      bends
    });
    return this.stackup;
  }

  createStackup({ _LayerManager, boardThickness, strUnitName, pageType, version }) {

    this.mBoardThickness = boardThickness;
    this.mLayers = []; this.materialList = [];

    //add material
    //metal material
    const metalMaterialName = "Metal1";
    //pageType === FASTPI => 4.6e7
    const sigma = pageType === FASTPI ? 4.6e7 : 5.8e7;
    const metalMaterial = new MetalMaterial({ name: metalMaterialName, sigma });

    const dielectricMaterialName = "Dielectric1";
    const dielectricMaterial = new DielectricMaterial({ name: dielectricMaterialName });

    this.materialList.push(metalMaterial);
    this.materialList.push(dielectricMaterial);

    // create the layers
    let metalLayerNames = _LayerManager.GetAllMetalLayers();
    for (let iLayer = 0, numLayer = metalLayerNames.size(); iLayer < numLayer; iLayer++) {
      // add the metal layer
      let layerName = metalLayerNames.get(iLayer);
      let layer = new StackupLayer({ name: layerName, type: METAL, thickness: 1.4, material: metalMaterialName });
      this.mLayers.push(layer);

      // create a dielectric layer except for the last metal layer
      if (iLayer < numLayer - 1) {
        let dieLayer = new StackupLayer({ name: "LD_" + layerName, type: DIELECTRIC, thickness: 6.0, material: dielectricMaterialName });
        this.mLayers.push(dieLayer);
      }
    }

    //Now we need to set the thickness of the inserted dielectric layers
    //4-layer: 0.020", 0.031", 0.040", 0.047", 0.062", 0.093" and 0.125"
    //6-layer: 0.031", 0.040", 0.047", 0.062", 0.093" and 0.125"
    //8-layer: 0.062", 0.093" and 0.125"
    //10-layer: 0.062", 0.093" and 0.125"

    switch (this.mBoardThickness) {
      // 62 mil board
      case 62:
        switch (metalLayerNames.size()) {
          case 2:
            this.mLayers[1].setThickness(57);
            this.mLayers[1].setMaterial(dielectricMaterialName);
            break;
          case 4:
            this.mLayers[1].setThickness(9.1);
            this.mLayers[3].setThickness(37);
            this.mLayers[5].setThickness(9.1);
            break;
          case 6:
            this.mLayers[1].setThickness(8.1);
            this.mLayers[3].setThickness(13);
            this.mLayers[5].setThickness(9.1);
            this.mLayers[7].setThickness(13);
            this.mLayers[9].setThickness(8.1);
            break;
          case 8:
            this.mLayers[1].setThickness(5.9);
            this.mLayers[3].setThickness(9);
            this.mLayers[5].setThickness(7.3);
            this.mLayers[7].setThickness(5.1);
            this.mLayers[9].setThickness(7.3);
            this.mLayers[11].setThickness(9);
            this.mLayers[13].setThickness(5.9);
            break;
          case 10:
            this.mLayers[1].setThickness(5.9);
            this.mLayers[3].setThickness(5.1);
            this.mLayers[5].setThickness(4.5);
            this.mLayers[7].setThickness(5.1);
            this.mLayers[9].setThickness(5.9);
            this.mLayers[11].setThickness(5.1);
            this.mLayers[13].setThickness(4.5);
            this.mLayers[15].setThickness(5.1);
            this.mLayers[17].setThickness(5.9);
            break;

          default:
            console.log("Error: " + metalLayerNames.size() + " layer board is not supported!");
            return false;

        } // switch (metalLayers.size())

        break;

      // 93 mil board
      case 93:
        switch (metalLayerNames.size()) {
          case 4:
            this.mLayers[1].setThickness(13);
            this.mLayers[3].setThickness(59);
            this.mLayers[5].setThickness(13);
            break;
          case 6:
            this.mLayers[1].setThickness(9.1);
            this.mLayers[3].setThickness(28);
            this.mLayers[5].setThickness(9.1);
            this.mLayers[7].setThickness(28);
            this.mLayers[9].setThickness(9.1);
            break;
          case 8:
            this.mLayers[1].setThickness(9.1);
            this.mLayers[3].setThickness(14);
            this.mLayers[5].setThickness(9.1);
            this.mLayers[7].setThickness(14);
            this.mLayers[9].setThickness(9.1);
            this.mLayers[11].setThickness(14);
            this.mLayers[13].setThickness(9.1);
            break;
          case 10:
            this.mLayers[1].setThickness(9.1);
            this.mLayers[3].setThickness(8);
            this.mLayers[5].setThickness(9.1);
            this.mLayers[7].setThickness(8);
            this.mLayers[9].setThickness(9.1);
            this.mLayers[11].setThickness(8);
            this.mLayers[13].setThickness(9.1);
            this.mLayers[15].setThickness(8);
            this.mLayers[17].setThickness(9.1);
            break;

          default:
            console.log("Error: " + metalLayerNames.size() + " layer board is not supported!");
          // even though we report an error, the default stackup is returned as a last resort

        } // switch (metalLayers.size())

        break;

      // unrecognized board thickness
      default:
        console.log("Error: Unsupported board thickness " + this.mBoardThickness);
        return false;

    } // switch(this.mBoardThickness)

    //get unit
    this.mUnit = this.getUnit(strUnitName);

    this.stackup = new StackupJson({
      layers: this.mLayers,
      materials: this.materialList,
      unit: this.mUnit,
      version
    });

    this.stackup = this.addDefaultFillMaterial(this.stackup);

    return this.stackup;
  }

  getUnit(strUnitName) {
    this.mUnit = "Length_mils";
    // convert the thickness into the a given unit
    if (strUnitName) {
      let csUnit = CsUnits.StringToUnits(strUnitName);

      if (csUnit !== this.mUnit && csUnit !== "Unknown") {
        for (let iLayer in this.mLayers) {
          let t = this.mLayers[iLayer].getLayerThickness();
          t = Math.round(CsUnits.Convert(t, this.mUnit, csUnit) * 100) / 100;
          this.mLayers[iLayer].setThickness(t);
        }
        this.mUnit = CsUnits.UnitsToString(csUnit);
      } else {
        this.mUnit = CsUnits.UnitsToString(this.mUnit);
      }
    } else {
      this.mUnit = CsUnits.UnitsToString(this.mUnit);
    }
    return this.mUnit;
  }

  getStackupTotalThickness() {
    let thickness = 0;
    //Metal
    const metalLayerNames = this.stackup.layers.filter(item => item.type === "Metal").map(item => item.name);
    if (metalLayerNames.length < 2) {
      return 50;
    }
    const startIndex = this.stackup.layers.findIndex(item => item.name === metalLayerNames[0]);
    const endIndex = this.stackup.layers.findIndex(item => item.name === metalLayerNames[metalLayerNames.length - 1]);

    for (let i = 0; i < this.stackup.layers.length; i++) {
      if (i < startIndex || i > endIndex) {
        continue;
      }
      const item = this.stackup.layers[i];
      thickness += Number(item.thickness);
    }
    return Number(thickness.toFixed(3));
  }

  getZone = (zoneName) => {
    return this.stackup.zones ? this.stackup.zones.find(item => item.name === zoneName) : null;
  }
}

export default StackupJsonData;

