class VerificationInfo {
  constructor() {
    this.verifications = new Map(); // key - channelId, value - verifications
  }

  setInfo = (id, verificationInfo) => {
    this.verifications.set(id, verificationInfo)
  }

  getVerificationInfo = (id) => {
    return this.verifications.get(id)
  }
}

const Info = new VerificationInfo();
export default Info;