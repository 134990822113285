import { getFileInRockyVerification } from '../../../api/Rocky/rockyCtrl';
import { getPCBCompAndPin } from '../../../helper/resultPublic';
import { ResultData } from '../index';
import { splitComponentName } from '../../../helper/splitComponent';
import NP from 'number-precision';
import { MEMORY } from '../../../PCBHelper';

function SignalDelayItem() {
  this.signalName = null;
  this.driver = null;
  this.receiver = null;
  this.delay = null;
  this.skew = null;
}

SignalDelayItem.prototype.setDriver = function (name, pin) {
  this.driver = {
    name: name,
    pin: pin
  }
};

SignalDelayItem.prototype.setReceiver = function (name, pin, compName) {
  this.receiver = {
    name: name,
    pin: pin,
    compName: compName
  }
}

SignalDelayItem.prototype.setDelayFromStr = function (str) {
  this.delay = NP.strip(parseFloat((Number(str) * 1e12)));
}

SignalDelayItem.prototype.setSkew = function (skew) {
  this.skew = skew;
};

function getSignalDelayData({ skewSpec,
  verificationSubId,
  channelId,
  interfaceName,
  verificationId
}) {
  return new Promise((resolve, reject) => {
    const path = `${verificationSubId}/result/${interfaceName}/models/CHANNELSkew.dat`;
    getFileInRockyVerification({ channelId, filePath: path }).then(response => {
      ResultData.getVerificationJsonPromise({ verificationId, channelId, verificationSubId }).then(content => {

        const data = parseModelSelectorFile(response.data, skewSpec, content);
        resolve(data);
      })
    }, error => {
      reject(error)
    });
  })
}

export function parseModelSelectorFile(fileContent, skewSpec, content) {
  if (!content) {
    return [];
  }
  let nameInfo = []
  let compInfo = content && content.Interfaces && content.Interfaces[0].Content ? content.Interfaces[0].Content.Components : [];
  const dimmSignalTopology = content && content.Interfaces && content.Interfaces[0].Content ? content.Interfaces[0].Content.dimmSignalTopology : [];
  let cardMemoryComp = []
  if (compInfo.length > 0) {
    for (let comp of compInfo) {
      comp.pins.forEach(pin => {
        const { signal } = pin;
        let usedCardValue = false;

        if (comp.dimmCardModel) {
          if (dimmSignalTopology && dimmSignalTopology.length) {
            const findInfo = dimmSignalTopology.find(item => item.signalName === signal);
            if (findInfo && findInfo.dimmMemoryPins && findInfo.pcbMemoryPins && findInfo.pcbMemoryPins.find(item => item.componentName === comp.name)) {
              for (let dimmMemoryPin of findInfo.dimmMemoryPins) {
                const { componentName, pinName } = dimmMemoryPin;
                // if (active === false) { continue }
                usedCardValue = true;
                nameInfo.push({
                  comp: splitComponentName(componentName),
                  compName: componentName,
                  name: `${splitComponentName(componentName)}_${pinName}`,
                  pin: pinName,
                  cardDriver: { component: comp.name, pin: pin.pin },
                  dimmComp: comp.name
                })
                if (!cardMemoryComp.includes(componentName)) { cardMemoryComp.push(componentName) }
              }
            }
          }
        }
        if (!usedCardValue) {
          nameInfo.push({ comp: splitComponentName(comp.name), pin: pin.pin, name: `${splitComponentName(comp.name)}_${pin.pin}`, compName: comp.name })
        }
      })
    }
  }
  //get memory comp names
  const memoryCompArr = cardMemoryComp && cardMemoryComp.length ? cardMemoryComp : [...new Set([...compInfo.filter(item => item.type === MEMORY).map(item => item.name)])];
  let signalDelayList = [];
  let fileArray = fileContent.match(/[^\r\n]+/g);
  let signalName;
  for (let i = 0, len = fileArray.length; i < len; i++) {
    let words = fileArray[i].trim().split(" ");
    if (words.length === 1) {
      signalName = words[0];
    } else if (words.length >= 3) {
      let item = getSignalDelayItem(words, signalName, nameInfo);
      signalDelayList.push(item);
    } else {
      console.error('can\'t parse string: ' + fileArray[i]);
    }
  }

  let memoryArr = [], memoryGroupList = []; // [signalDelayList(same reveiver component)]
  // Group by receiver (memory device)
  for (let item of signalDelayList) {
    const existIndex = memoryArr.indexOf(item.receiver.name);
    if (existIndex > -1) {
      memoryGroupList[existIndex].push(item);
    } else {
      memoryArr.push(item.receiver.name);
      memoryGroupList.push([item]);
    }
  };

  let delayArr = [];
  for (let receiverGroup of memoryGroupList) {
    // check for the clock net
    let clkSignal = null;
    for (let item of receiverGroup) {
      if (item.signalName && item.signalName.includes('CLK')) {
        clkSignal = 'CLK';
        break;
      } else if (item.signalName && item.signalName.includes('RDQS')) {
        clkSignal = 'RDQS';
        break;
      } else if (item.signalName && item.signalName.includes('DQS')) {
        clkSignal = 'DQS';
        break;
      } else if (item.signalName && item.signalName.includes('WCK')) {
        clkSignal = 'WCK';
        break;
      }
    }

    // calculate the average delay of the clock nets and comp name
    const receiverCompGroup = memoryCompArr.map(compName => {
      const clkSignalsDelay = receiverGroup.filter(item => item.receiver.compName === compName && item.signalName && item.signalName.includes(clkSignal)).map(item => item.delay);
      if (!clkSignalsDelay || !clkSignalsDelay.length) {
        return {};
      }
      const clkDelays = clkSignalsDelay.reduce((prev, current) => prev + current);
      const refDelay = clkDelays / clkSignalsDelay.length;
      return {
        compName,
        refDelay,
        clkSignalsDelay
      }
    })
    if (!receiverCompGroup.length) continue;
    // let skewSpec = refDelay;
    // const ui = 1.250000e-09;
    // skewSpec = (ui * 0.02 * 1e12 + 10000).toPrecision(5) - 10000;  // skew specification in ps, use 2% of the unit interval
    //  DQS, CLK Groups
    for (let item of receiverGroup) {
      const findComp = receiverCompGroup.find(it => it.compName === item.receiver.compName);
      const refDelay = findComp ? findComp.refDelay : "";

      if (!refDelay) {
        continue;
      }
      item.skew = NP.strip(parseFloat((Math.abs(item.delay - refDelay)).toFixed(1))).toFixed(1);
      item.spec = skewSpec;
      item.refDelay = refDelay;
    };
    delayArr.push(...receiverGroup);
  }
  return delayArr;
}


function getSignalDelayItem(list, signalName, nameInfo) {
  let item = new SignalDelayItem();
  item.signalName = signalName;
  let DriverInfo = getPCBCompAndPin(list[0]);
  let driver = nameInfo.find(item => item.name === DriverInfo.compPinName);
  if (driver) {
    item.setDriver(driver.comp, driver.pin);
  } else {
    item.setDriver('', '');
  }
  let ReceiverInfo = getPCBCompAndPin(list[1]);
  let receiver = nameInfo.find(item => item.name === ReceiverInfo.compPinName);
  if (receiver) {
    item.setReceiver(receiver.comp, receiver.pin, receiver.compName);
  } else {
    item.setReceiver('', '', '');
  }
  item.setDelayFromStr(list[2]);
  return item;
}


export default getSignalDelayData;