import NP from 'number-precision';
import { numberCheck } from "./dataProcess";
import { scaleConversion } from './numberHelper';

const ErrorMsg = [
  "Please set the value.",//0
  "Please enter a number.",//1
  "Please set the value from 2 to 200.",//2
  "Please set the maximum frequency from 20MHz to 200GHz.",//3
  "Please set the port impedance from 0.001 to 1000.",//4
  "Please set the value from 0 to 10.",//5
  "Please set PADM value from 0 to 5.",//6
  "Please set PLNM value from 0 to 1e-4.",//7
  "Please set VRTC value from 0 to 10.",//8
  'please set Fsw value from 0 to 10000KHz.',//9
  'The value of keep the decap must greater than 0.',//10
  "Please set the port impedance from 0.01 to 100.",//11
  'Dogleg hole must be less than the value greater than 0.',//12
  'Thermal hole must be less than the value greater than 0.',//13
  'Other hole must be less than the value greater than 0.',//14
  'Via hole must be less than the value greater than 0.',//15
  'The value of Min shape size must greater than 0.',//16
  'The value of Max pad size must greater than 0.',//17
  'The value must be an integer between 1 and 5', //18
  'The value of PSI small hole size must greater than 0.', //19
  'The value of Top floating patch at a distance must between 0.0001 and 10.', //20
  'The value of Bottom floating patch at a distance must between 0.0001 and 10.', //21
  'Max Memory must between 0% and 100%.', //22
  'Percent of Max Number of CPU must between 0% and 100%.', //23
  'Core number of Number of CPU must be an integer.', //24
  'Multi-Zone distance must be bigger than 1e-8.', //25
  'The value must be an integer or \'all\'.', //26
  "Please set the maximum frequency from 20MHz to 100GHz.",//27
  'Please set the min log sweep from 0.00001Hz to 10GHz.',//28
  'Please set the max log sweep(min linear sweep) from 0.00001Hz to 200GHz.',//29
  'Please set the max linear sweep(min linear sweep 2) from 0Hz to 200GHz.',//30
  'Please set the linear sweep frequency step from 0.001Hz to 1GHz.',//31
  'The value must be an integer and greater than 0',//32
  'The value must be an integer and greater than 25',//33
]

const units = [
  'FMAX',
  'logSweepMin',
  'logSweepMax',
  'linearSweepMax',
  'linearSweepFreqStep'];

function fastPIValueCheckIndex({ value, inputType, unit, product, engine }) {

  if (!value) {
    if (['pwsiMaxPadSize', 'pwsiMinShapeSize', 'pwsiMaxPadSizeRelative', 'pwsiMaxMeshEdgeLength'].includes(inputType)) {
      return -1;
    }
    return 0;
  }

  let notNum = numberCheck(value);
  if (notNum) {
    return 1;
  }

  let errorIndex = -1;
  let _value = value;
  if (units.includes(inputType) && unit) {
    const scale = scaleConversion("Hz", unit);
    _value = NP.times(parseFloat(_value), scale);
  }

  switch (inputType) {
    case 'SAPD':
      if (parseFloat(_value) > 200 || parseFloat(_value) < 2) {
        errorIndex = 2;
      };
      break;
    case 'FMAX':
      if (product === "Rocky") {
        if (parseFloat(_value) > 1e11 || parseFloat(_value) < 2e7) {
          errorIndex = 27;
        };
      } else {
        if (parseFloat(_value) > 2e11 || parseFloat(_value) < 2e7) {
          errorIndex = 3;
        };
      }
      break;
    case 'REFR':
      if (['Cascade', 'Rocky'].includes(product)) {
        if (parseFloat(value) > 100 || parseFloat(value) < 0.01) {
          errorIndex = 11;
        }
      } else {
        if (parseFloat(value) > 1000 || parseFloat(value) < 0.001) {
          errorIndex = 4;
        }
      }
      break;
    case 'VOID':
      if (parseFloat(value) < 0 || parseFloat(value) > 10) {
        errorIndex = 5;
      };
      break;
    case 'PADM':
      if (engine === 'SIwave' && (parseFloat(value) < 0 || parseFloat(value) > 5)) {
        errorIndex = 6;
      };
      if (engine === 'PowerSI' && parseFloat(value) < 0) {
        errorIndex = 16;
      }
      break;
    case 'PLNM':
      if (parseFloat(value) < 0 || parseFloat(value) > 1e-4) {
        errorIndex = 7;
      };
      break;
    case 'VRTC':
      if (parseFloat(value) < 0 || parseFloat(value) > 10) {
        errorIndex = 8;
      };
      break;
    case 'VR_FSW':
      if (parseFloat(value) < 0 || parseFloat(value) > 10000) {
        errorIndex = 9;
      };
      break;
    case 'capValue':
      if (parseFloat(value) <= 0) {
        errorIndex = 10;
      };
      break;
    case 'Dogleg':
      if (parseFloat(value) <= 0) {
        errorIndex = 12;
      }
      break;
    case 'Thermal':
      if (parseFloat(value) <= 0) {
        errorIndex = 13;
      }
      break;
    case 'Hole':
      if (parseFloat(value) <= 0) {
        errorIndex = 14;
      }
      break;
    case 'ViaHole':
      if (parseFloat(value) <= 0) {
        errorIndex = 15;
      }
      break;
    case 'SHPM':
      if (parseFloat(value) <= 0) {
        errorIndex = 17;
      }
      break;
    case 'minConvergedPass':
      const number = Number(value);
      if (number < 1 || number > 5 || !Number.isInteger(number)) {
        errorIndex = 18;
      }
      break;
    case 'psiSmallHoleSize':
      if (parseFloat(value) <= 0) {
        errorIndex = 19;
      }
      break;
    case 'topDistance':
      if (parseFloat(value) < 0.0001 || parseFloat(value) > 10) {
        errorIndex = 20;
      }
      break;
    case 'bottomDistance':
      if (parseFloat(value) < 0.0001 || parseFloat(value) > 10) {
        errorIndex = 21;
      }
      break;
    case 'ramPercent':
      if (parseFloat(value) < 0 || parseFloat(value) > 100) {
        errorIndex = 22;
      }
      break;
    case 'corePercent':
      if (parseFloat(value) < 0 || parseFloat(value) > 100) {
        errorIndex = 23;
      }
      break;
    case 'numCores':
      const cores = Number(value);
      if (!Number.isInteger(cores)) {
        errorIndex = 24;
      }
      break;
    case 'distance':
      if (parseFloat(value) < 1e-8) {
        errorIndex = 25;
      }
      break;
    case 'keptMinimumNumber':
      const keptMinimumNumber = Number(value);
      if (!Number.isInteger(keptMinimumNumber)) {
        errorIndex = 26;
      }
      break;
    case "logSweepMin":
      if (_value > 10e9 || _value < 1e-5) {
        errorIndex = 28;
      }
      break
    case "logSweepMax":
      if (_value > 200e9 || _value < 1e-5) {
        errorIndex = 29;
      }
      break
    case 'linearSweepMax':
      if (_value > 200e9 || _value < 0) {
        errorIndex = 30;
      }
      break
    case 'linearSweepFreqStep':
      if (_value > 1e9 || _value < 1e-3) {
        errorIndex = 31;
      }
      break
    case 'gridColumn':
    case 'gridRow':
      const _value_ = Number(value);
      if (parseFloat(value) <= 0 || !Number.isInteger(_value_)) {
        errorIndex = 32;
      }
      break
    case 'range':
      const numValue = Number(value);
      if (parseFloat(value) < 25 || !Number.isInteger(numValue)) {
        errorIndex = 33;
      }
      break
    default: errorIndex = -1; break;
  };

  return errorIndex;
}

function fastPIValueCheck({ value, inputType, unit, product = 'FastPI', engine = 'SIwave' }) {
  const index = fastPIValueCheckIndex({ value, inputType, unit, product, engine });
  if (index > -1) {
    return ErrorMsg[index];
  } else {
    return null;
  }
}

export { fastPIValueCheck, fastPIValueCheckIndex }; 