import defaultColor from '@/constants/defaultColors';
import { CPHY } from '../../../PCBHelper/constants';
import { OPTIMIZATION, SWEEPMODEL } from '../../../../components/Sparameter/resultList';
class ParameterData {
  constructor({ verificationId }) {
    this.id = null;
    this.ports = null;
    this.freq = null;
    this.matrix = null;
    this.verificationId = verificationId || null;
    this.colorArr = [];
    this.colorCount = 0;
  }

  initGetNpiFileFn = (getNpiFileFn) => {
    this.getNpiFileFn = getNpiFileFn;
  }

  initParseNpiFile = (parseNpiFileFn) => {
    this.parseNpiFile = parseNpiFileFn;
  }

  initGetCurve = (getCurveFn) => {
    this.getChannelResultCurve = getCurveFn;
  }

  initGetOptCurve = (getCurveFn) => {
    this.getOptChannelResultCurve = getCurveFn;
  }

  initGetSweepCurve = (getCurveFn) => {
    this.getSweepChannelResultCurve = getCurveFn
  }

  initPackageGetCurve = (getCurveFn) => {
    this.getPackageChannelResultCurve = getCurveFn;
  }

  initMergeGetCurve = (getCurveFn) => {
    this.getMergeChannelResultCurve = getCurveFn;
  }

  getNpiFile = (index, params, components, type) => {
    return new Promise((resolve, reject) => {
      this.getNpiFileFn(params).then(res => {
        const data = res.data;
        const parseData = this.parseNpiFile(data, components, type);
        this.ports = parseData.ports;
        this.freq = parseData.freq;
        this.initMatrix(index, type);
        resolve(this);
      }, error => {
        console.error(error);
        resolve(this);
      })
    })
  }

  async getCurve({ col, row, value, curveType }) {
    if (['package_channel'].includes(curveType)) {
      return await this.getPackageChannelResultCurve({
        verificationId: this.verificationId,
        curveRequestDTO: { cols: [col], rows: [row], type: `s${value}` }
      });
    } else if (curveType === "package_preLayout") {
      const path = this.name.replace(/\.s\d+p$/, '/pdn')
      return await this.getPackageChannelResultCurve({
        verificationId: this.channelId,
        curveRequestDTO: { cols: [col], rows: [row], path: `${path}.s${value}` }
      })
    } else if (curveType === "merge_signal") {
      return await this.getMergeChannelResultCurve({
        verificationId: this.verificationId,
        curveRequestDTO: { cols: [col], rows: [row], type: `s${value}` }
      })
    } else if (curveType === OPTIMIZATION) {
      return await this.getOptChannelResultCurve({
        verificationId: this.verificationId,
        fileName: this.name,
        params: { cols: [col], rows: [row], path: `pdn.s${value}` }
      });
    } else if (curveType === SWEEPMODEL) {
      return await this.getSweepChannelResultCurve({
        verificationId: this.verificationId,
        fileName: this.name,
        params: { cols: [col], rows: [row], path: `pdn.s${value}` },
      })
    } else {
      return await this.getChannelResultCurve({
        verificationId: this.verificationId,
        fileName: this.name,
        params: { cols: [col], rows: [row], path: `pdn.s${value}` }
      });
    }
  }
  /**
   * Get curve data
   *
   * @param param - s,y,z
   * @param value - am for amplitude, ​ph for phase​, re for real, im for imaginary, db for dB
   * type - s/y/z + am/ph/re/im
   */
  getCurveData = async ({ row, col, param, value, fileIndex, curveType, fileName = '', pair, signal, couplingType }) => {
    if (!this.matrix || !this.matrix[row] || !this.matrix[row][col]) {
      this.initMatrix(fileIndex);
    }

    let item = this.matrix[row][col], type = param + value;
    if (item[type]) {
      return {
        type,
        color: item.color,
        curve: item[type]
      }
    } else {
      if (value === 'am' || value === 'ph' || value === 'db') {
        const _value = value === 'db' ? 'am' : value;
        const response = await this.getCurve({ col, row, value: _value, curveType, signal, pair, couplingType });
        item[type] = response && response[0] && response[0].curve;
        return {
          type,
          color: item.color,
          curve: item[type]
        }
      } else {
        // re, im
        const amData = await this.getCurveData({ row, col, param, value: 'am', curveType, fileName, signal, pair, couplingType });
        const phData = await this.getCurveData({ row, col, param, value: 'ph', curveType, fileName, signal, pair, couplingType });
        const cos = Math.cos;
        const sin = Math.sin;
        item[param + 're'] = amData.curve.map((am, i) => am * cos(phData.curve[i]));
        item[param + 'im'] = amData.curve.map((am, i) => am * sin(phData.curve[i]));
        return {
          type,
          color: item.color,
          curve: item[type]
        }
      }
    }
  }

  initMatrix = (index, type) => {
    let n = index ? index : 0
    this.matrix = [];
    for (let i = 0, num = this.ports.length; i < num; i++) {
      this.matrix[i] = [];
      for (let j = 0; j < num; j++) {
        this.matrix[i][j] = {
          display: true,
          color: this.getColor(j * num + i + n),
        }
      }
    }

    // Store coupling data
    if (type === CPHY) {
      const i = this.ports.length;
      this.matrix[i] = [];
      for (let j = 0; j < 3; j++) {
        this.matrix[i][j] = {
          display: true,
          color: this.getColor(j * i + i + n),
        }
      }
    }
  }

  getColor(i) {
    if (this.colorCount === defaultColor.length) {
      this.colorCount = 0;
      this.colorArr = [];
    }
    let colorIndex = i % defaultColor.length;
    while (this.colorArr[colorIndex]) {
      colorIndex = (colorIndex + 1) % defaultColor.length;
    }
    this.colorCount++;
    this.colorArr[colorIndex] = true;
    return defaultColor[colorIndex];
  }
}


export default ParameterData;