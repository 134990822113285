import { parseSpiceModelSelector } from "../../LibraryHelper/SpiceModelHelper";

class SpiceModelHelper {
  constructor(ids = []) {
    for (const id of ids) {
      this[id] = null;
    }
  }

  getSpiceFiles() {
    return Object.keys(this);
  }

  parseModelSelector(id, fileContent) {
    this[id] = parseSpiceModelSelector(fileContent);
  }

  /*get model list by filename and type(Rx, Tx)*/
  getModelList(id, type) {
    return this[id];
  }

  deleteModel(id) {
    delete this[id]
  }
}

export default SpiceModelHelper;