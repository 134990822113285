import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { select } from 'd3-selection';
import { min, max } from 'd3-array';

function drawSkew(svgElement, skewData, report = false) {

  const svgWidth = svgElement.parentElement.clientWidth;
  const svgHeight = svgElement.parentElement.clientHeight;

  let margin = {
    top: 40,
    left: 75,
    right: 20,
    bottom: 65
  };
  if (report) {
    margin = {
      top: 50,
      left: 80,
      right: 30,
      bottom: 80
    };
  }
  const plotWidth = svgWidth - margin.left - margin.right;
  const plotHeight = svgHeight - margin.top - margin.bottom;

  const xDomain = skewData.map(function (d) { return d.signalName; });
  // scaling
  const xScale = scaleBand()
    .domain(xDomain)
    .range([0, plotWidth])
    .paddingInner(0.4)
    .paddingOuter(0.2)

  const yScale = scaleLinear().range([plotHeight, 0]);

  const xAxis = axisBottom(xScale)

  const yAxis = axisLeft(yScale)
    .ticks(10);

  const svg = select(svgElement)
    .attr('class', 'plot full-fill')
  svg.selectAll('g').remove();

  // svg.append("g").append("text")
  //   .style("text-anchor", "middle")
  //   .attr("dx", 0.5 * svgWidth)
  //   .attr("dy", 20)
  //   .text("Delay (ps)");

  // The bar chart
  const gRoot = svg.append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  xScale.domain();

  // find the vertical ranges
  let maxDelay = skewData && skewData[0] ? skewData[0].delay : 0;
  let minDelay = skewData && skewData[0] ? skewData[0].delay : 0;
  let specSkew = 0;
  let delayDqs = 0;

  for (const data of skewData) {
    delayDqs = data.refDelay;

    if (data.delay > maxDelay) {
      maxDelay = data.delay;
    }
    if (data.delay < minDelay) {
      minDelay = data.delay;
    }

    if (data.skew != null) {
      specSkew = data.spec;
      data.color = data.skew > data.spec ? '#f34141' : '#3489ad';
    }
  }
  let maxY = Math.ceil((maxDelay + specSkew * 0.5) / 10) * 10;
  let minY = Math.floor((minDelay - specSkew) / 10) * 10;
  if (maxY < 2 * specSkew) {
    maxY = specSkew;
  }
  if (minY < 0) {
    minY = 0;
  }
  yScale.domain([minY, maxY]);
  // axes
  gRoot.append("g")
    .attr("class", "axis report-x-axis")
    .attr("transform", "translate(0," + plotHeight + ")")
    .call(xAxis)
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "1em")
    .attr('dy', "1.3em")
    .attr("transform", "rotate(0)");

  gRoot.append("g")
    .attr("class", "axis report-y-axis")
    .call(yAxis);

  // reference lines
  let refLines = [
    {
      level: delayDqs - specSkew,
      color: '#f12727'
    }, {
      level: delayDqs,
      color: '#008400'
    }, {
      level: delayDqs + specSkew,
      color: '#f12727'
    }
  ];

  refLines = refLines.filter(d => yScale(d.level) > 0);

  // bar chart
  gRoot.selectAll("none")
    .data(refLines)
    .enter().append("line")
    .attr("stroke", function (d) { return d.color; })
    .attr("x1", 0)
    .attr("y1", function (d) { return yScale(d.level); })
    .attr("x2", plotWidth)
    .attr("y2", function (d) { return yScale(d.level); });

  gRoot.selectAll("rect")
    .data(skewData)
    .enter().append("rect")
    .style("fill", function (d) { return d.color; })
    .attr("x", function (d, i) {
      return xScale(d.signalName);
    })
    .attr("width", xScale.bandwidth())
    .attr("y", function (d) { return yScale(d.delay); })
    .attr("height", function (d) {
      return plotHeight - yScale(d.delay);
    });

  const yLabel = report ? 'translate(' + -55 + ' ' + plotHeight / 2 + ') rotate(-90)' :
    'translate(' + -40 + ' ' + plotHeight / 2 + ') rotate(-90)';

  gRoot.append('text')
    .text('Delay (ps)')
    .attr('class', 'report-delay-name')
    .style('text-anchor', 'middle')
    .attr('font-size', 12)
    .attr('fill', '#000000a6')
    .attr('transform', yLabel);

  gRoot.append('text')
    .text('Signal')
    .attr('class', 'report-signal-name')
    .attr('x', plotWidth / 2)
    .attr('y', plotHeight + 20)
    .attr('fill', '#000000a6')
    .attr('font-size', 12)
    .attr('dy', '2.4em')
    .style('text-anchor', 'middle');
}

export default drawSkew;