import CeNetGeom from './CeNetGeom';
import CeLayerComponent from './CeLayerComponent';
import CeLayerBase from '../partlib/CeLayerBase';
import CeLayerMaker from '../partlib/CeLayerMaker';
import CeRefGeometry from '../geometry/CeRefGeometry';
import CeLinkedMap from '../utility/CeLinkedMap';
import StringList from '../utility/StringList';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeLayerMetal = function () {
  CeLayerBase.call(this);
  this.mThickness = 0.0;
  this.mLogicLayers = [];
  this.mNetGeomList = new CeLinkedMap();
  this.mComponentLayer = null;
};

// inherits from CeLayerBase
CeLayerMetal.prototype = Object.create(CeLayerBase.prototype);
CeLayerMetal.prototype.constructor = CeLayerMetal;

CeLayerMetal.prototype.SetThickness = function (h) {
  this.mThickness = h;
}

CeLayerMetal.prototype.AddNetGeometry = function (net, neyGeom) {
  if (this.mNetGeomList.containsKey(net)) {
    this.mNetGeomList.getValue(net).push(neyGeom);
  } else {
    var geomList = [];
    geomList.push(neyGeom);
    this.mNetGeomList.put(net, geomList);
  }
}

CeLayerMetal.prototype.AddLogicLayer = function (name, id, type) {
  var layer = CeLayerMaker.CreateLogicLayer(type);
  layer.SetName(name);
  layer.SetID(id);
  this.mLogicLayers.push(layer);
  return layer;
}

CeLayerMetal.prototype.AddComponentLayer = function (name, id) {
  this.mComponentLayer = new CeLayerComponent();
  this.mComponentLayer.SetName(name);
  this.mComponentLayer.SetID(id);
  return this.mComponentLayer;
}

CeLayerMetal.prototype.GetComponentLayer = function (name) {
  if (name == undefined)
    return this.mComponentLayer;
  else if (this.mComponentLayer != null && this.mComponentLayer.GetName() == name)
    return this.mComponentLayer;
  return null;
}

CeLayerMetal.prototype.GetSubLayers = function (reverseOrder) {
  var layerList = new StringList();
  if (reverseOrder) {
    for (var i = 0; i < this.mLogicLayers.length; i++) {
      layerList.push(this.mLogicLayers[i].GetName());
    }
    if (this.mComponentLayer != null)
      layerList.push(this.mComponentLayer.GetName());
  } else {
    if (this.mComponentLayer != null)
      layerList.push(this.mComponentLayer.GetName());

    for (var i = 0; i < this.mLogicLayers.length; i++) {
      layerList.push(this.mLogicLayers[i].GetName());
    }
  }

  return layerList;
}

CeLayerMetal.prototype.GetSubLayerType = function (lyrName) {
  if (this.mComponentLayer != null && lyrName == this.mComponentLayer.GetName())
    return this.mComponentLayer.GetType();

  for (var i = 0; i < this.mLogicLayers.length; i++) {
    if (lyrName == this.mLogicLayers[i].GetName())
      return this.mLogicLayers[i].GetType();
  }

  return null;
}

CeLayerMetal.prototype.GetSubLayerObject = function (lyrName) {
  if (this.mComponentLayer != null && lyrName == this.mComponentLayer.GetName())
    return this.mComponentLayer;

  for (var i = 0; i < this.mLogicLayers.length; i++) {
    if (lyrName == this.mLogicLayers[i].GetName())
      return this.mLogicLayers[i];
  }

  return null;
}

CeLayerMetal.prototype.GetLogicLayer = function (lyrName) {
  for (var i = 0; i < this.mLogicLayers.length; i++) {
    if (lyrName == this.mLogicLayers[i].GetName())
      return this.mLogicLayers[i];
  }
  return null;
}

CeLayerMetal.prototype.WriteIntoIODataNode = function (layerBlock) {
  CeLayerBase.prototype.WriteIntoIODataNode.call(this, layerBlock);

  if (this.mComponentLayer != null) {
    var compBlock = new CeIODataBlock("Components");
    this.mComponentLayer.WriteIntoIODataNode(compBlock);
    layerBlock.AddBlockNode(compBlock);
  }

  if (this.mLogicLayers != null) {
    for (var i = 0; i < this.mLogicLayers.length; i++) {
      var lgcLyrBlock = new CeIODataBlock("LogicLayer");
      this.mLogicLayers[i].WriteIntoIODataNode(lgcLyrBlock);
      layerBlock.AddBlockNode(lgcLyrBlock);
    }
  }

  var netGeomBlock = new CeIODataBlock("NetGeometry");
  for (var i = 0; i < this.mNetGeomList.size(); i++) {
    var netName = this.mNetGeomList.getKey(i);
    var netBlock = new CeIODataBlock(netName);
    var geomList = this.mNetGeomList.getValue(i);
    for (var j = 0; j < geomList.length; j++) {
      var geomBlk = new CeIODataBlock("NetGeom");
      geomList[j].WriteIntoIODataNode(geomBlk);
      netBlock.AddBlockNode(geomBlk);
    }
    netGeomBlock.AddBlockNode(netBlock);
  }
  layerBlock.AddBlockNode(netGeomBlock);
}

CeLayerMetal.prototype.ReadFromIODataNode = function (layerBlock, layoutDB) {
  CeLayerBase.prototype.ReadFromIODataNode.call(this, layerBlock);

  var compBlock = layerBlock.GetSubBlock("Components");
  if (compBlock != null) {
    this.mComponentLayer = new CeLayerComponent();
    this.mComponentLayer.ReadFromIODataNode(compBlock, layoutDB);
  }

  var logicLyrBlks = layerBlock.GetSubBlocksOfSameName("LogicLayer");
  if (logicLyrBlks != null) {
    this.mLogicLayers = [];
    for (var i = 0; i < logicLyrBlks.length; i++) {
      var type = logicLyrBlks[i].GetItemString("Type", "Unknown");
      var logicLayer = CeLayerMaker.CreateLogicLayer(type);
      logicLayer.ReadFromIODataNode(logicLyrBlks[i]);
      this.mLogicLayers.push(logicLayer);
    }
  }

  var netManager = layoutDB.mNetManager;
  var netsBlock = layerBlock.GetSubBlock("NetGeometry");
  if (netsBlock != null) {
    var netBlkList = netsBlock.GetAllSubBlocks();
    for (var i = 0; i < netBlkList.length; i++) {
      var netName = netBlkList[i].GetName();
      var ceNet = netManager.GetNetFromName(netName);
      if (ceNet == null)
        console.error("Net is not yet created brefor using it: " + netName);
      var geomBlkList = netBlkList[i].GetAllSubBlocks();
      for (var j = 0; j < geomBlkList.length; j++) {
        var refGeom = new CeRefGeometry();
        refGeom.ReadFromIODataNode(geomBlkList[j]);
        var netGeom = new CeNetGeom();
        netGeom.SetLayerID(this.mID);
        netGeom.SetNetGeom(refGeom);
        ceNet.AddNetGeom(netGeom);
        this.AddNetGeometry(netName, netGeom);
      }
    }
  }
  return true;
}

CeLayerMetal.prototype.SetThickness = function (w) {
  this.mThickness = w;
}

export default CeLayerMetal;