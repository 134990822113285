import { REPEATER } from '../../../constants/libraryConstants';
import { getComponentSetting, getPMICtype, updateComponentSetting } from '../../api/compSetting'
import apiProcess from '../../api/utility';
import sierraLibrary from '../../Sierra/library/libraryStorage';
import _ from 'lodash';

class CompSettingHelper {
  constructor() {
    this.componentSetting = new Map();  // key - designId, value - { compPrefixLib, version }
  }

  saveSetting(designId, setting) {
    this.componentSetting.set(designId, setting);
  }

  getSetting = async ({ designId }) => {
    if (!designId) {
      return {};
    }
    let setting = this.componentSetting.get(designId);
    if (!setting) {
      try {
        setting = await getCompPrefixLib(designId);
        /* if (setting && setting.pinMap && setting.pinMap.repeater) {
          setting.pinMap.repeater = this.updateRepeaterModelFile(setting.pinMap.repeater);
        } */
        this.saveSetting(designId, setting);
      } catch (err) {
        console.error(err);
      }
    }
    return setting
  }

  updateRepeaterModelFile = (repeater) => {
    for (let item of repeater) {
      if (!item.model || !item.model.files || !item.model.files.length) {
        continue;
      }
      for (let file of item.model.files) {
        if (!file.libraryId && !file.fileName) {
          continue;
        }
        const fileExist = file.libraryId ? sierraLibrary.checkFile(REPEATER, file.libraryId, file.folder ? file.fileName : null) : false;
        if (!fileExist) {
          const findFile = sierraLibrary.findFileByName(REPEATER, file.fileName, file.folder);
          item.libraryId = findFile && findFile.id ? findFile.id : item.libraryId;
        }
      }
    }
    return repeater;
  }

  getPrefixLib = async (designId) => {
    const setting = await this.getSetting({ designId });
    return setting && setting.compPrefixLib ? setting.compPrefixLib : {};
  }

  updatePrefixLib = async (designId, newCompPrefixLib) => {
    const setting = await this.getSetting({ designId });
    setting.compPrefixLib = newCompPrefixLib;
    this.saveSetting(designId, setting)
  }

  getCompPinMap = async (designId, tree) => {
    const setting = await this.getSetting({ designId, tree });
    let pinMap = setting && setting.pinMap ? setting.pinMap : {};
    if (pinMap.repeater && pinMap.repeater.length) {
      pinMap.repeater = this.updateRepeaterModelFile(pinMap.repeater, designId);
    }
    return pinMap;
  }

  updateRepeaterModelFile = (repeater, designId) => {
    let update = false;
    for (let item of repeater) {
      if (!item.model || !item.model.files || !item.model.files.length) {
        continue;
      }
      for (let file of item.model.files) {
        if (!file.libraryId && !file.fileName) {
          continue;
        }
        if (file.libraryId || (!file.libraryId && file.fileName)) {
          const fileExist = file.libraryId ? sierraLibrary.checkFile(REPEATER, file.libraryId, file.folder ? file.fileName : null) : false;
          if (!fileExist) {
            update = true;
            const findFile = sierraLibrary.findFileByName(REPEATER, file.fileName, file.folder);

            if (findFile && findFile.id) {

              (item.model.pairs || []).forEach(pin => {
                if (pin.libraryId === file.libraryId) {
                  pin.libraryId = findFile.id;
                }
              })
              file.libraryId = findFile.id;
            }
          }
        }
      }
    }
    if (update) {
      this.updateCompPinMap(designId, 'repeater', repeater);
    }
    return repeater;
  }

  updateCompPinMap = async (designId, type, data, save = true) => {
    const setting = await this.getSetting({ designId });
    const pinMap = setting && setting.pinMap ? setting.pinMap : {};
    pinMap[type] = data;
    setting.pinMap = pinMap;
    if (save) {
      this.updateSetting([{ designId, componentSetting: setting }])
    } else {
      this.saveSetting(designId, setting)
    }
  }

  getVersion = async (designId) => {
    const setting = await this.getSetting({ designId });
    return setting && setting.version ? setting.version : "0.0.1";
  }

  updateVersion = async (designId, version, compSetting, save = false) => {
    const setting = await this.getSetting({ designId });
    setting.version = version;
    if (save) {
      this.updateSetting([{ designId, componentSetting: { ...setting, version } }])
    } else {
      this.saveSetting(designId, setting)
    }
  }

  updateSetting(componentSettings) {
    try {
      updateCompPrefixLib(componentSettings);
      componentSettings.forEach(setting => {
        const { designId, componentSetting } = setting;
        this.saveSetting(designId, componentSetting);
      })
    } catch (error) {
      console.error(error)
    }
  }

  clearSetting(designId) {
    this.saveSetting(designId, null);
  }

  clearAllSetting() {
    this.componentSetting = new Map();
  }

  compareSetting(designId, setting, returnDetail) {
    let prevSetting = this.componentSetting.get(designId);
    let _prevSetting = prevSetting ? { ...prevSetting.compPrefixLib } : {};
    return compareCompPrefixLib(_prevSetting, setting, returnDetail)
  }
}

function getCompPrefixLib(designId) {
  return apiProcess(getComponentSetting, { designId });
}

function updateCompPrefixLib(componentSettings) {
  return apiProcess(updateComponentSetting, componentSettings);
}

function getPMICPartType(partNumber) {
  return apiProcess(getPMICtype, partNumber)
}

function compareCompPrefixLib(prevSetting = {}, setting, returnDetail = false) {
  const keys = Object.keys(setting);
  let newKeys = {}, deleteKeys = {}, save = false;

  for (let key of keys) {
    if (returnDetail) {

      const filterKeys = setting[key].filter(item => !(prevSetting[key] || []).includes(item));
      if (filterKeys.length) {
        newKeys[key] = filterKeys;
      }
      const filterDelKeys = (prevSetting[key] || []).filter(item => !setting[key].includes(item));
      if (filterDelKeys.length) {
        deleteKeys[key] = filterDelKeys;
      }
    }
    if (save) {
      continue;
    }
    if (!prevSetting[key] || prevSetting[key].length !== setting[key].length) {
      save = true;
    }
    if (!_.isEqual(prevSetting[key] || [], setting[key] || [])) {
      save = true;
    }
  }
  return returnDetail ? { newKeys, deleteKeys, save } : save;
}

const componentSetting = new CompSettingHelper();

export default componentSetting;
export {
  getPMICPartType
}