function getUnitOptions(key) {
  let options = [];
  switch (key) {
    case 'logSweepMin':
    case 'logstart':
      options = ["Hz", "KHz"];
      break;
    case 'logSweepMax':
    case 'linearSweepMax':
    case 'logend':
    case 'linearstart':
    case 'linearend':
      options = ["Hz", "KHz", "MHz", "GHz"];
      break;
    case 'linearSweepFreqStep':
    case 'logdec':
    case 'linearstep':
      options = ["Hz", "KHz", "MHz"];
      break;
    case 'maxFreq':
      options = ["GHz"];
      break;
    default:
      options = ["Hz", "KHz", "MHz", "GHz"];
      break;
  }
  return options;
}

export {
  getUnitOptions
}