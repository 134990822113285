import { TreeSelect, Radio, Button, Progress, Tree, Input } from 'antd';
import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import UploadFile from '@/components/UploadFile';
import { getLibraryFileIdList, getProjectFileIdList, getExportParamsInCascade } from '../../services/helper/importExportHelper';
import { importProjectDataPromise, exportProjectDataPromise, getAllLibraryDataPromise } from '@/services/project';
import { getDebugDownloadProgress } from '@/services/helper/downloadHelper';
import { CASCADE, ANDES_V2 } from '../../constants/pageType';
import { PDN } from '../../constants/treeConstants';
import './index.css';
import fileChunk from '../../services/helper/chunksHelper/fileChunk'

const { SHOW_PARENT } = TreeSelect;
const LIBRARY = 'Library', PROJECT = 'Project'

class ImportExportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: 'export',
      // The value of the multiple box in Export
      value: [],
      treeData: [],
      // search result in Export and product is CASCADE
      showTreeData: [],
      fileList: [],
      compressProgress: -1,
      uploadProgress: -1,
      compressMsg: '',
      importError: '',
      exportProgress: 0,
      exportVisible: true,
      exportMessage: null,
      uploading: false,
      uploadTip: ""
    };
    this.dialogRoot = document.getElementById('root');
  }

  onChange = (value) => {
    this.setState({ value });
  };

  componentDidMount = () => {
    const { title } = this.props;
    if (title === LIBRARY) {
      this.getAllLibraryData()
    } else {
      this.getAllProjectData()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { title } = this.props;
    if (prevProps.title !== title) {
      this.setState({
        value: []
      })
      if (title === LIBRARY) {
        this.getAllLibraryData()
      } else {
        this.getAllProjectData()
      }
    }
  }

  setKey = (libraryList) => {
    return libraryList.map(item => {
      return {
        ...item,
        key: item.value || item.key,
        children: item.children && item.children.length ? this.setKey(item.children) : []
      }
    })
  }

  getAllLibraryData = () => {
    const { product } = this.props;
    if (product === PDN || product === CASCADE || product === ANDES_V2) {
      const { libraryList } = this.props;
      if (libraryList) {
        const _libraryList = this.setKey(libraryList)
        this.setState({
          treeData: _libraryList
        })
      }
    } else {
      getAllLibraryDataPromise(product).then(res => {
        if (res && Object.keys(res).length) {
          this.setState({
            treeData: [res]
          })
        }
      })
    }
  }

  getAllProjectData = () => {
    const { projectList } = this.props;
    let res = {
      title: 'All',
      value: 'All',
      key: 'All',
      children: [],
    };
    projectList.map(item =>
      res.children.push({
        title: item.name,
        showTitle: item.name,
        // project - projectId - verificationId
        value: 'project-' + item.id + '-0',
        // project - projectId - verificationId
        key: 'project-' + item.id + '-0',
        isDownload: true
      })
    )
    this.setState({
      treeData: [res]
    });
  }
  onChangeType = (e) => {
    this.setState({
      radioValue: e.target.value,
    });
  }

  onUploadFiles = (fileList, errorFileList, fileType) => {
    this.setState({
      fileList,
      errorFileList: [...errorFileList]
    })
  }

  delFile = (fileList, errorFileList) => {
    this.setState({
      fileList,
      errorFileList: [...errorFileList]
    })
  }

  saveFile = async () => {
    const { fileList } = this.state;
    const { product, changeUploading, title } = this.props

    this.setState({
      checking: false,
      uploading: true,
      compressProgress: 100,
      uploadTip: "Uploading..."
    })

    this.Time = setInterval(() => {
      let progress = fileChunk.progress;
      if (progress === 'success') {
        clearInterval(this.Time);
      } else if (progress === 'fail') {
        this.setState({
          importError: 'Upload failed!',
          uploading: false,
          uploadProgress: -1,
          compressProgress: -1,
          uploadTip: ""
        })
        changeUploading && changeUploading(false)
        clearInterval(this.Time);
      } else {
        this.setState({
          uploadProgress: progress,
          compressProgress: 100
        })
      }
    }, 100);
    const file = fileList[0].file
    const res = await fileChunk.doUpload(file.file, 1024)
    if (res === 'success') {
      this.setState({
        uploadTip: `Parse the imported data and create the ${title.toLowerCase()}...`
      })
      const formData = new FormData()
      formData.append('fileId', fileChunk.id)
      const res = await importProjectDataPromise(formData, product)
      if (res && res.status === "fail") {
        this.setState({
          importError: res.msg,
          uploading: false,
          uploadProgress: -1,
          compressProgress: -1,
          uploadTip: ""
        })
        changeUploading && changeUploading(false)
      } else {
        this.setState({
          uploadProgress: 100,
          compressProgress: 100,
          uploadTip: ""
        })
        setTimeout(() => {
          this.afterUpload()
        }, 100)
      }
    }
  }

  afterUpload = () => {
    this.setState({
      uploading: false,
      compressProgress: -1,
      uploadProgress: 100,
      compressMsg: '',
      importError: '',
      uploadTip: ""
    });
    const { title, changeUploading } = this.props;
    // update library menu
    this.props.afterImportLibrary()
    changeUploading && changeUploading(false)
    if (title === PROJECT) {
      this.props.clearCurrentProject(true);
      this.props.changeViewList([]);
      this.props.closeTabFooter();
      this.props.updateProjectMenu({ isImport: true })
    }
    this.props.closeModal();
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    let _fileList = [...fileList];
    const findIndex = _fileList.findIndex(item => item.name === prevName);
    const fileSuffix = _fileList[findIndex].fileSuffix;
    _fileList[findIndex].name = name;
    _fileList[findIndex].fileName = `${name}${fileSuffix}`;
    _fileList[findIndex].file.fileName = `${name}${fileSuffix}`;
    this.setState({
      fileList: [..._fileList]
    });
  }

  exportClick = () => {
    const { treeData, value, showTreeData } = this.state;
    const { product, title } = this.props
    let exportProjectParams = []
    let libraryIds = []
    let projectIds = []
    if (title === PROJECT) {
      product === CASCADE ?
        exportProjectParams = getExportParamsInCascade(showTreeData.length ? showTreeData : treeData, value)
        :
        projectIds = getProjectFileIdList(treeData, value)
    } else {
      libraryIds = getLibraryFileIdList(treeData, value)
    }
    let exportData = { libraryIds: [], projectIds: [], exportProjectParams }
    let progress = 0, indexNum = 0;
    this.setState({
      exportVisible: false
    })
    const intervalTime = title === LIBRARY ? 100 : 500;
    this.exportTime = setInterval(() => {
      indexNum += 1;
      progress = getDebugDownloadProgress(progress);
      if (indexNum % 3 === 0) {
        this.setState({
          exportProgress: progress
        })
      }
    }, intervalTime);
    title === LIBRARY ? exportData.libraryIds = libraryIds : exportData.projectIds = product === CASCADE ? [] : projectIds;
    exportProjectDataPromise(exportData, product).then(res => {
      if (res && res.data) {
        const token = localStorage.getItem('token');
        let url = `/api/v3/project/download/${product}?access_token=${token}`
        // let fileName = 'Aurora.zip';
        try {
          const a = document.createElement('a');
          // a.download = fileName;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          clearInterval(this.exportTime);
          this.setState({
            exportProgress: -1,
            exportVisible: true,
            exportMessage: `Export success and waiting for download!`
          }, () => {
            setTimeout(() => {
              this.setState({
                exportMessage: ``
              })
            }, 8000)
          })
        } catch (error) {
          this.setState({
            exportProgress: -1,
            exportVisible: true,
            exportMessage: `Export ${title} zip format failed!`
          })
        }
      } else {
        clearInterval(this.exportTime);
        this.setState({
          exportProgress: -1,
          exportVisible: true,
          exportMessage: res && res.msg ? `Export failed! - ${res.msg}` : "Export failed! - Unknown error."
        })
      }
    }, error => {
      clearInterval(this.exportTime);
      this.setState({
        exportProgress: -1,
        exportVisible: true,
        exportMessage: 'Export Failed'
      })
      console.error(error)
    })
  }

  onMinimize = () => {
    const { showImportExportModel, changeUploading } = this.props
    if (showImportExportModel) {
      showImportExportModel(false)
    }
    if (changeUploading) {
      changeUploading(true)
    }
  }

  onLoadData = async (treeNode) => {
    try {
      await new Promise(resolve => {
        const { key } = treeNode;
        const { createChildren, getProjectChildren, product } = this.props
        if (key && key !== 'All') {
          let data = []
          const [, projectId,] = key.split('-')
          if (product === CASCADE) {
            getProjectChildren(projectId).then((res) => {
              data = res
              const { treeData, showTreeData } = this.state
              const newTreeData = treeData[0].children.map((item) => {
                if (item.key === key) {
                  return { ...item, children: createChildren(data) }
                }
                return item
              })
              this.setState({
                treeData: [{ ...treeData[0], children: newTreeData }],
              })
              if (showTreeData && showTreeData.length) {
                const newShowTreeData = showTreeData[0].children.map((item) => {
                  if (item.key === key) {
                    return { ...item, children: createChildren(data) }
                  }
                  return item
                })
                this.setState({
                  showTreeData: [{ ...showTreeData[0], children: newShowTreeData }]
                })
              }
              resolve();
            })
          }
        } else {
          resolve()
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  onCheck = (checkedKeys) => {
    this.setState({ value: checkedKeys });
  };

  searchProject = (e) => {
    const searchValue = e.target.value
    const { treeData, value } = this.state
    if (searchValue) {
      const uselessValue = []
      const newProjects = treeData[0].children.forEach((item) => {
        if (item.title.match(searchValue)) {
          return true
        } else {
          uselessValue.push(item.key)
        }
      })
      const newValue = value.filter((item) => !uselessValue.includes(item))
      this.setState({
        showTreeData: [{ ...treeData[0], children: newProjects }],
        value: newValue
      })
    } else {
      // showTreeData is empty if value of input 
      this.setState({ showTreeData: [] })
    }
  }

  closeModal = () => {
    this.props.closeModal();
    const { changeUploading } = this.props;
    changeUploading && changeUploading(false);
  }

  render() {
    const { radioValue, treeData, value, uploadProgress, compressMsg, importError, compressProgress,
      exportProgress, exportVisible, exportMessage, uploading, showTreeData, fileList, errorFileList, uploadTip } = this.state;
    const messageClassName = exportMessage && exportMessage.indexOf('success') > -1 ? 'download-success' : 'download-error';
    const { title, importExportModelVisible, product } = this.props;
    const tProps = {
      treeData,
      value: value,
      onChange: this.onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Please select',
      popupClassName: 'export-content-drop-down-tree',
      treeNodeLabelProp: 'showTitle',
      treeNodeFilterProp: 'title',
      style: {
        width: '100%'
      },
      popupMatchSelectWidth: false
    };
    const content = (
      <div style={{ visibility: importExportModelVisible ? 'visible' : 'hidden' }}>
        <Panel
          className={`import-export-Panel ${product}-panel`}
          position='panel-center-left'
          title={`Import or Export ${title}`}
          zIndex={2000}
          onCancel={() => this.closeModal()}
          width={500}
          draggable
          minHeight={200}
          minimizeVisible={uploading ? true : false}
          onMinimize={this.onMinimize}
        >
          <Radio.Group onChange={(e) => this.onChangeType(e)} value={radioValue} className='import-export-radio-group'>
            <Radio value='export' className='import-export-radio-edit' >Export Zip</Radio>
            <Radio value='import' className='import-export-radio-upload'>Import Zip</Radio>
          </Radio.Group>
          {radioValue === 'import' && <UploadFile
            text='Import Zip'
            allowMulti={false}
            accept={'.zip'}
            onUploadFile={this.onUploadFiles}
            delFile={this.delFile}
            saveFile={this.saveFile}
            changeFileName={this.changeFileName}
            compressProgress={compressProgress}
            uploadProgress={uploadProgress}
            compressMsg={compressMsg}
            uploading={uploading}
            uploadTip={uploadTip}
            checking={false}
            notSelect={true}
            importError={importError}
            fileList={fileList}
            errorFileList={errorFileList}
          />}
          {radioValue === 'export' && <div className="export-content-tip">
            {
              product === CASCADE && title === PROJECT ?
                <Fragment>
                  <Input
                    placeholder="Please input project name"
                    onChange={this.searchProject}
                  />
                  <Tree
                    checkable
                    loadData={this.onLoadData}
                    onCheck={this.onCheck}
                    checkedKeys={value}
                    className='export-data-tree'
                    treeData={showTreeData.length ? showTreeData : treeData}
                  />
                </Fragment>
                :
                <TreeSelect {...tProps} />
            }
            <div className="export-content-footer">
              {exportVisible ?
                <Fragment>
                  {exportMessage ? <div className={`${messageClassName} export-folder-message`}>{exportMessage}</div> : null}
                  <div className="export-data-button">
                    <Button
                      title={value.length ? "export" : "Choose at least one option to export."}
                      type="primary"
                      ghost
                      disabled={!value.length}
                      onClick={(e) => this.exportClick(e)}
                      size='small'
                    >Export</Button>
                  </div>
                </Fragment>
                : <Progress
                  size={{ height: 10 }}
                  className='export-content-progress'
                  percent={exportProgress}
                />
              }
            </div>
          </div>}
        </Panel>
      </div>
    )
    return createPortal(content, this.dialogRoot)

  }
}

export default ImportExportPanel;