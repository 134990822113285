import CeIODataBlock from '../CeFileIO/CeIODataBlock';

export default function parseDecapConfig(fileContent) {
  let DecapBlock = new CeIODataBlock('DECAPS');
  DecapBlock.ReadBlockFromFile(fileContent);

  var config = new DecapConfig();
  config.ReadFromIODataNode(DecapBlock);
  return config;
};

class DecapConfig {
  constructor() {
    this.decaps = [];
  };

  ReadFromIODataNode(block) {
    const decaps = block.GetAllBlockItemsOfSameName('DECAP')
    decaps.forEach(item => {
      let values = item.GetItemValue();
      const part = values.get(0);
      const comps = values.get(2) ? values.get(2).split(',') : [];
      comps.forEach(comp => {
        if (comp) {
          this.decaps.push(new Decap(comp, part));
        }
      })
    });
  }
}

class Decap {
  constructor(name = null, part = null, model = null) {
    this.name = name;
    this.part = part;
    this.model = model;
  }
}