import { select, event } from 'd3-selection';
import LayerRenderer from './LayerRenderer';
import CanvasObjectList from './CanvasObjectList';
import CanvasObject from './CanvasObject';
import CeXMark from '@/services/geometry/CeXMark';
import { FAST, MEDIUM } from '@/constants/resolution';
import { userDefaultSettings } from '@/services/userDefaultSetting/userDefaultSettingCtrl';

var MetalLayerRenderer = function (layoutRenderer, layerName, hidden, clickCallback) {
  LayerRenderer.call(this, layoutRenderer, layerName, hidden);
  this.netObjLists = [];
  this.d3NetGroups = null;
  this.clickCallback = clickCallback;
};

MetalLayerRenderer.prototype = Object.create(LayerRenderer.prototype);
MetalLayerRenderer.prototype.constructor = MetalLayerRenderer;

/**
 * generate the nets' shape list from layout data
 */
MetalLayerRenderer.prototype.prepareData = function () {
  let viaLayerMap = new Map(); // <viaId, value>, value - The value is boolean, indicating whether to draw the barrel in the current layer
  // quick link to the layout db data members
  var layerManager = this.layoutDB.GetLayerManager();
  var layerObj = layerManager.GetMetalLayer(this.layerName);
  var layerID = layerObj.GetID();

  var symbolMgr = this.layoutDB.GetSymbolManager();

  var netManager = this.layoutDB.GetNetManager();
  var netList = netManager.GetNetList();
  const resolution = userDefaultSettings.getResolution();
  this.netObjLists = [];
  //The vias with antipad need to be drawn at the end
  this.viaLists = [];

  setViaLayerMap(); // Update via layer mapping

  // loop through all the nets
  for (var iNet = 0; iNet < netList.size(); iNet++) {

    var objList = new CanvasObjectList(this.clickCallback),
      netVias = [];
    this.netObjLists.push(objList);
    this.viaLists.push(netVias);

    var netName = netList.getKey(iNet);
    var netObj = netList.getValue(iNet);

    // create a template object containing the net information
    var templateObj = new CanvasObject();
    templateObj.layer = this.layerName;
    templateObj.net = netName;
    templateObj.type = 'shape';

    var viaList = netObj.GetNetViaList();
    let ADD_GROM = null;
    if (resolution === FAST && viaList.length > 100) {
      ADD_GROM = 1;
    } else if (resolution === MEDIUM && viaList.length > 500) {
      ADD_GROM = 2
    }
    // fill the net shapes
    var geomList = netObj.GetNetGeomList();
    for (var iGeom = 0; iGeom < geomList.length; iGeom++) {

      var refGeom = geomList[iGeom].GetNetRefGeom();
      if (layerID !== geomList[iGeom].GetLayerID())
        continue;

      objList.addRefGeometry(refGeom, symbolMgr, templateObj, ADD_GROM);
    }

    // fill the vias

    // If the number of vias in net is greater than 500, do not add vias
    if (resolution === FAST) {
      if (viaList.length > 100) continue;
    } else if (resolution === MEDIUM) {
      if (viaList.length > 500) {
        continue;
      }
    }
    for (var iVia = 0; iVia < viaList.length; iVia++) {
      var viaObjList = new CanvasObjectList(this.clickCallback);
      viaObjList.net = netName;
      var csVia = symbolMgr.GetViaGeomObj(viaList[iVia].mViaID);

      if (csVia == null) {
        console.error("Did not find via from shape manager: " + viaList[iVia].mViaID);
        netVias.push(viaObjList);
        continue;
      }

      viaObjList.viaName = csVia.mName;
      netVias.push(viaObjList);

      // via pad

      for (var iPad = 0; iPad < csVia.mLayerPads.size(); iPad++) {
        if (this.layerName.toUpperCase() !== csVia.mLayerPads.getKey(iPad).toUpperCase())
          continue;
        var pad = csVia.mLayerPads.getValue(iPad);
        var antipad = pad.antipad;
        if (antipad) {
          templateObj.type = 'antipad';
          var shapeObj = symbolMgr.GetShapeGeomObj(antipad.mShapeId);
          var geomObj = shapeObj.GetGeometry();
          viaObjList.antipad = new CanvasObjectList(this.clickCallback);
          viaObjList.antipad = geomObj.mDiameter;
          viaObjList.addGeometry(geomObj, viaList[iVia].mLocation, templateObj);
        }
        if (pad.mShapeId !== -1) {
          templateObj.type = 'pad';
          shapeObj = symbolMgr.GetShapeGeomObj(pad.mShapeId);
          geomObj = shapeObj.GetGeometry();
          viaObjList.pad = geomObj.mDiameter;
          viaObjList.addGeometry(geomObj, viaList[iVia].mLocation, templateObj);
        }

      }

      // via barrel
      if (drawBarrel(csVia)) {
        templateObj.type = 'barrel';
        var barrel = csVia.mBarrel;
        var barrelObj = symbolMgr.GetShapeGeomObj(barrel.mShapeId);
        if (!barrelObj) {
          //console.log(barrel,'There is no shape in shepeObj');
        } else {
          var barrelGeom = barrelObj.GetGeometry();
          viaObjList.barrel = barrelGeom.mDiameter;
          if (barrelGeom.mDiameter !== undefined && barrelGeom.mDiameter === 0) {
            if (viaObjList.antipad) {
              barrelGeom = new CeXMark();
              barrelGeom.SetDiameter(viaObjList.antipad * 0.5);
              viaObjList.addGeometry(barrelGeom, viaList[iVia].mLocation, templateObj);
            }
          } else {
            viaObjList.addGeometry(barrelGeom, viaList[iVia].mLocation, templateObj);
          }
        }
      }

    } // for(var iVia = 0; iVia < viaList.length; iVia++)

  }; // for (var iNet = 0; iNet < netList.size(); iNet++)
  // call the virtual function of the parent
  LayerRenderer.prototype.prepareData.call(this);

  function drawBarrel(csVia) {
    return viaLayerMap.get(csVia.mID) || false;
  }

  function setViaLayerMap() {
    for (let viaItem of symbolMgr.mViaList.mObjects) {
      const layerIDs = viaItem.mLayerPads.mKeys.map(layerName => layerManager.LayerNameToID(layerName));
      const maxID = Math.max(...layerIDs);
      const minID = Math.min(...layerIDs);
      if (layerID > maxID || layerID < minID) {
        viaLayerMap.set(viaItem.mID, false);
      } else {
        viaLayerMap.set(viaItem.mID, true);
      }
    }
  }
}; // MetalLayerRenderer.prototype.prepareData

var clickEventHandling = function (d, layoutRenderer, callback) {
  if (layoutRenderer.canvasType !== "NET") {
    //nets canvas
    event.stopPropagation();
  }
  if (event.defaultPrevented || layoutRenderer.coupleMode) {
    return;
  }

  var nets = getElementsByPosition(event.clientX, event.clientY);
  // multi nets select
  const object = { nets };
  object.multi = event.ctrlKey === true;
  callback(object)
};

/**
 * get the net names by location(x, y)
 * @param {number} x 
 * @param {number} y 
 */
function getElementsByPosition(x, y) {
  var element = document.elementFromPoint(x, y);
  if (element.tagName !== 'svg') {
    select(element).attr('display', 'none');
    var nets = getElementsByPosition(x, y);
    select(element).attr('display', null);
    var net = element.__data__.net;
    if (net && nets.indexOf(net) < 0)
      nets.push(net)
    return nets;
  } else {
    return [];
  }
}

/**
 * draw metal layer
 */
MetalLayerRenderer.prototype.redraw = function () {

  // draw the objects
  var self = this;
  const isCPM = this.layoutRenderer && this.layoutRenderer.isCPMCanvas ? this.layoutRenderer.isCPMCanvas : false;
  if (isCPM) { return }
  this.d3NetGroups = this.layerElement
    .selectAll('g')
    .data(self.layoutRenderer.netInfo)
    .enter()
    .append('g')
    .attr('net', function (net) {
      return net.name;
    })
    .on('click', function (d) {
      clickEventHandling(d, self.layoutRenderer, self.clickCallback);
    })


  this.updateColoring();

  this.d3NetGroups
    .data(self.layoutRenderer.netInfo)
    .append('title')
    .text(function (net) {
      return net.name;
    });

  this.d3NetGroups
    .each(function (d, i) {
      self.netObjLists[i].drawSVG(select(this), d.canvas.fill);
      //var viagroup = select(this).append('g');
      //self.netObjLists[i].via.drawSVG(viagroup);
    });

  //draw vias
  if (this.layoutRenderer.hasVia === undefined || this.layoutRenderer.hasVia) {
    this.d3ViaGroup = this.layerElement.append('g');
    this.d3ViaGroup.selectAll('g')
      .data(self.layoutRenderer.netInfo)
      .enter()
      .append('g')
      .attr('net', function (d) {
        return d.name
      })
      .each(function (d, i) {

        select(this)
          .selectAll('g')
          .data(self.viaLists[i])
          .enter()
          .append('g')
          .each(function (via) {
            var viaElement = select(this),
              text = 'Net: ' + via.net +
                '\nLayer: ' + self.layerName;
            if (via.canvasCircleList[0]) {
              text += '\nCoordinate: ' + via.canvasCircleList[0].xc + ', ' + via.canvasCircleList[0].yc +
                '\nBarrel Diameter: ' + via.barrel +
                '\nPad Diameter: ' + via.pad +
                (via.antipad ? ('\nAntipad Diameter: ' + via.antipad) : '');
            }

            viaElement.append('title').text(text);
            viaElement.attr('name', via.viaName)
            via.drawVia(viaElement);
          });

      });
  }

  // call the virtual function of the parent
  LayerRenderer.prototype.redraw.call(this);

}; // MetalLayerRenderer.prototype.redraw

/** virtual function - set the object visibility of this layer */
MetalLayerRenderer.prototype.updateVisibility = function () {
  var hidden = true;
  if (this.layoutDB.GetLayoutSettings().IsLayerVisible(this.layerName)) {
    hidden = null;
    // this layer is now visible, render if it has not been done before
    if (!this.dataLoaded) {
      this.prepareData();
    }
    if (!this.rendered) {
      //todo
      this.redraw();
    }
  }
  this.layerElement.attr('hidden', hidden);
};

/** set the object color of this layer */
MetalLayerRenderer.prototype.updateColoring = function () {
  var color = this.layoutDB.GetLayoutSettings().GetLayerColor(this.layerName);
  var colorByLayer = this.layoutRenderer.colorByLayer;
  if (colorByLayer) {
    if (this.layoutRenderer.fillMode)
      this.layerElement
        .attr('fill', color)
        .attr('stroke', null);
    // .selectAll('polyline')
    // .attr('stroke', color);
    else
      this.layerElement
        .attr('fill', 'none')
        .attr('stroke', color);
  }
  if (this.d3NetGroups) {
    this.d3NetGroups
      .data(this.layoutRenderer.netInfo)
      .attr('hidden', function (net) {
        return net.canvas.hidden;
      })
      .attr('stroke', function (net) {
        return net.canvas.stroke;
      })
      .attr('fill', function (net) {
        return net.canvas.fill;
      })
      .each(function (net) {
        var netcolor = colorByLayer === true ? color : net.canvas.fill;
        if (net.darkened) netcolor = '#333333';
        select(this).select('g')
          .attr('stroke', netcolor);
      });
  }
  if (this.d3ViaGroup) {
    var nets = this.layoutRenderer.netInfo;

    for (var i = 0; i < nets.length; i++) {
      if (!nets[i].darkened) break;
    }
    if (i === nets.length)
      this.d3ViaGroup.attr('fill', '#333333')
    else
      this.d3ViaGroup.attr('fill', null)
  }
};

MetalLayerRenderer.prototype.updateStrokeWidth = function () {
  if (this.layoutRenderer.hasWidth === false) {
    var width = this.layoutRenderer.outlineStrokeWidth / 2;
    this.layerElement
      .selectAll('polyline')
      .attr('stroke-width', function (net) {
        return Math.min(net.width, width)
      });
  }
}

export default MetalLayerRenderer;