import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TreeForm from '@/components/TreeNode';
import {
  ApartmentOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  PlusSquareOutlined,
  PushpinFilled,
  PushpinOutlined,
} from '@ant-design/icons';
import { Input, Tooltip, Checkbox, message, Popover, Dropdown, Tag, Select, Space } from 'antd';
import UploadPCB from './Upload';
import { expandMenu } from '../store/sierra/action';
import {
  updateProjectMenu,
  openProject,
  clearCurrentProject,
  updateTrashMenu,
  changeTreeSelected,
  changeViewList, projectMenu,
  loadSweepList,
  saveSweepName
} from '../store/project/action';
import {
  updateLibraryMenu,
  openLibraryFolder,
  setFolderCreate,
  changeDefaultLibrary
} from '../store/library/action';
import { closeTabFooter } from '../../tabMonitor/action';
import { sierraProjectCopy, sierraInterfaceCopy, SweepData } from '../../../services/Sierra';
import {
  MY_LIBRARY, LIBRARY, PROJECTS,
  PROJECT, PCBS, PCB,
  PROJECT_CREATE, TRASH, VERIFICATIONS,
  VERIFICATION, VERIFICATION_CREATE, RESULT,
  LIBRARY_FILE, LIBRARY_OPEN,
  LIBRARY_DATA, LIBRARY_FOLDER_FILE,
  LIBRARY_FOLDER, LIBRARY_FOLDER_CREATE,
  EXPERIMENTS, EXPERIMENTS_CREATE, EXPERIMENTS_RESULT,
  ADD_INTERFACE, PCB_SETTING, PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import {
  PKG_TOUCHSTONE,
  CONNECTOR_TOUCHSTONE,
  LIBRARY_SIERRA
} from '@/constants/libraryConstants';
import {
  VERIFY_RUNNING,
  VERIFY_SUCCESS,
  WAITING
} from '@/constants/verificationStatus';
import { DESIGN_FAILED, DESIGN_SUCCESS } from '@/constants/designCategory';
import { SIERRA } from '@/constants/pageType';
import * as taskStatus from '@/constants/workflowStatus';
import DelConfirm from '@/components/DelConfirm';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { strDelimited } from '@/services/helper/split';
import { getLibraryIndex } from '@/services/Sierra/library/libraryData';
import { getLibraryTitle } from '@/services/Library';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import {
  getPopMessage, getType, getIconKey, getIconCollectionKey, getVerificationStatus,
  IconKey, ADD, DELETE, EDIT, VERIFY, SIMULATION, CLEAN, STATUS, RENAME,
  MACRO_MODELING, COPY, LOADING, COPY_PROJECT, ADD_LIBRARY, FILE, FOLDER,
  UPDATE, MORE, INFO, EXPERIMENT, IMPORTEXPORT, BATCH_DELETE, COMBINE_INTERFACE, MULTI_SETUP, BLANK_FILE,
  DEFAULT
} from '@/services/Sierra/helper/treeHelper';
import IconCollection from '@/components/TreeIconCollection';
import RichTextEditor from '@/components/RichTextEditor';
import debounce from '@/services/helper/debounceFn';
import { updateKeys, updateTreeSelectKeys } from '@/services/helper/filterHelper';
import { updateMultiInterfaceSetupStatus } from '../store/multiInterface/action';
import { MULTI_INTERFACE_SETUP, UPLOAD_PCB, REPLACE_PCB, VERIFICATION_GROUP } from '../../../constants/treeConstants';
import { ALL_INTERFACES } from '../../../services/Sierra/multiInterfaceSetup';
import { PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY, NETS_FILTER_LIBRARY, VECTOR, TRACE } from '../../../constants/libraryConstants';
import { CUSTOM, INTERFACE_TYPE_LIST } from '../../../services/PCBHelper/constants';

const SpaceCompact = Space.Compact;
const { Option } = Select;

/* let count = 0; */
class sierraTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      checkedKeys: [],
      inputVerificationName: null,
      inputExpName: null,
      expErrorMsg: null,
      inputName: null,
      copyLoading: null,
      autoFocus: false,
      inputFolder: '',
      textEditorStatus: false,
      copyInfo: {},
      interfaceType: CUSTOM,
      renameType: ""
    }
  }

  componentDidMount() {
    this.props.UpdateLibraryMenu();
    this.props.updateTrashMenu();
    const { expandedKeys, verificationName, verificationId } = this.props;
    if (expandedKeys.length > 2) {
      expandedKeys.forEach((item, index) => {
        const [key, id] = strDelimited(item, "-");
        if (key === 'project') {
          this.props._UpdateProjectMenu({ openProjectId: id, verificationName, verificationId });
        }
      });
    } else {
      this.props._UpdateProjectMenu();
    }
  }

  componentDidUpdate = () => {
    const { autoFocus } = this.state;
    if (autoFocus && this.inputRef) {
      const { input } = this.inputRef;
      input.focus();
    }
    if (autoFocus && this.selectRef) {
      if (this.selectRef) {
        this.selectRef.focus();
      }
    }

    if (autoFocus && this.folderInputRef) {
      const { input } = this.folderInputRef;
      input.focus();
    }
  }

  getIcon = (key, item) => {
    const { copyLoading, copyInfo } = this.state;
    const { delItem, editItem, startVerification, changeSimulateCheck, simulateKeys, /* refNetsLoading, verificationId, */
      updateSettingPCB, viewList, uploading, showImportExportModel, defaultBufferSpice } = this.props;
    const select = simulateKeys ? simulateKeys.findIndex(selected => (selected.verificationId === item.id)) : -1;
    let checked = false;
    if (select > -1) {
      checked = true;
    }

    let disabled = (item.status === VERIFY_RUNNING || item.status === WAITING || item.simStatus /* || (verificationId === item.id && refNetsLoading && refNetsLoading.loading) */) ? true : false;
    switch (key) {
      case ADD:
        return (
          <PlusSquareOutlined
            className='aurora-tree-add-icon'
            id={`sierra-add-${item.key}-icon`}
            key={key}
            onClick={(e) => this._addItem(e, item)} />
        );
      case ADD_LIBRARY:
        return (
          <Dropdown menu={{ items: this.libraryMenu(item), label: "upload-dropdown-button" }} trigger={['click']} key={key}>
            <PlusSquareOutlined
              className='aurora-tree-add-icon'
              id={`sierra-add-library-${item.key}-icon`}
              onClick={(e) => this.spanClick(e)} />
          </Dropdown>
        );
      case DELETE:
        const type = getType(item.dataType);
        return type ? <CloseOutlined
          key={item.key}
          className='aurora-tree-del-icon'
          onClick={(e) => this._deleteClick(e, item)} /> :
          <CloseOutlined
            key={key}
            className='aurora-tree-del-icon'
            onClick={(e) => delItem(e, item)} />;
      case EDIT:
        return (
          <EditOutlined
            className='aurora-tree-edit-icon'
            key={key}
            onClick={(e) => editItem(e, { key: item.key, dataType: item.dataType, item })} />
        );
      case UPLOAD_PCB:
        return <UploadPCB key={key} data={item} type={UPLOAD_PCB} />;
      case VERIFY:
        if (item.dataType === VERIFICATION_GROUP) {
          const { indeterminate, checked: _checked } = this.getVerificationGroupChecked(item);
          return <span
            key={key}
            onClick={(e) => this.spanClick(e)}
          >
            <Checkbox
              key={key}
              indeterminate={indeterminate}
              checked={_checked}
              onChange={(e) => changeSimulateCheck(e, key, item)}
            />
          </span>
        }
        return <span
          key={key}
          onClick={(e) => this.spanClick(e)}
        >
          <Checkbox
            key={key}
            disabled={disabled}
            checked={disabled ? false : checked}
            onChange={(e) => changeSimulateCheck(e, key, item)}
          />
        </span>
      case SIMULATION:
        return (
          <span key={key} onClick={(e) => { this.inputClick(e) }}>
            <Tooltip
              placement="top"
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              title={simulateKeys && simulateKeys.length > 0 ? 'Interfaces Simulation' : 'Please Check Interface!'}
              overlayClassName='aurora-tooltip'
            >
              <PlayCircleOutlined
                id={`simulate-button-${item.key}`}
                key={key}
                className={simulateKeys && simulateKeys.length > 0 ? 'aurora-tree-simulate-icon' : 'aurora-tree-simulate-icon simulate-icon-disabled'}
                onClick={(e) => startVerification(e, item.key, item)} />
            </Tooltip>
          </span>
        );
      case BATCH_DELETE:
        return (
          <span key={key} onClick={(e) => { this.inputClick(e) }} >
            <Tooltip
              placement="top"
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              title={simulateKeys && simulateKeys.length > 0 ? 'Delete Interface' : 'Please Check Interface!'}
              overlayClassName='aurora-tooltip'
            >
              <DeleteOutlined
                id={`simulate-button-${item.key}`}
                key={key}
                className={simulateKeys && simulateKeys.length > 0 ? 'aurora-tree-batch-delete-icon' : 'aurora-tree-batch-delete-icon simulate-icon-disabled'}
                onClick={(e) => this.batchDeleteClick(e, item.key, item)} />
            </Tooltip>
          </span>
        );
      case CLEAN:
        return (
          <DeleteOutlined
            key={item.key}
            className='aurora-tree-del-icon'
            onClick={(e) => this._deleteClick(e, item)} />
        );
      case STATUS:
        let { verificationStatus, color } = getVerificationStatus(item.status, item.readyForSim, item);
        if (!verificationStatus) {
          return null;
        }
        return verificationStatus === 'loading3' || verificationStatus === 'tick' ?
          <span key={key} className={`iconfont icon-${verificationStatus} aurora-status-icon`} style={{ color: color }} ></span>
          : <span
            key={key}
            className={verificationStatus === 'W' ? 'aurora-status-span aurora-status-waiting' : 'aurora-status-span'}
            style={{ color: color }}
          >
            {verificationStatus}</span>
      case MACRO_MODELING:
        const { title, iconType } = item.fileType && [CONNECTOR_TOUCHSTONE, PKG_TOUCHSTONE].includes(item.fileType) ? this.getMacroModelingStatus(item) : { title: "", iconType: "" };
        return title && iconType ? <Tooltip key={key} title={title} overlayClassName='aurora-tooltip'>
          {iconType === "exclamation-circle" ?
            <ExclamationCircleOutlined
              className="sierra-touchstone-status-icon" />
            : <span key={key}
              onClick={(e) => this.spanClick(e)}
              className={`iconfont ${iconType} sierra-pdn-tree-loading-icon aurora-macro-icon`}
            ></span>}
        </Tooltip> : null;
      case RENAME:
        switch (item.dataType) {
          case PROJECT:
            return (
              <EditOutlined
                title='Rename'
                className='aurora-tree-project-rename-icon'
                key={key}
                onClick={(e) => this.renameProject(e, item)} />
            );
          case VERIFICATION:
            return (
              <EditOutlined
                title='Rename'
                className='aurora-tree-rename-icon'
                key={key}
                onClick={(e) => this.renameInterface(e, item)} />
            );
          case LIBRARY_FOLDER:
            return (
              <EditOutlined
                title='Rename'
                className='aurora-tree-project-rename-icon'
                key={key}
                onClick={(e) => this.renameFolder(e, item)} />
            );
          case EXPERIMENTS:
            return (
              <EditOutlined
                title='Rename'
                className='aurora-tree-sweep-rename-icon'
                key={key}
                onClick={(e) => this.renameSweep(e, item)} />
            );
          default: break;
        }
        break;
      case COPY:
        return copyLoading && copyLoading === item.id ?
          <span
            key={key}
            onClick={(e) => this.spanClick(e)}
            title='Copying Current Project'
            className={`iconfont icon-loading3 aurora-tree-loading-icon`}
            style={{ color: '#1890ff' }}
          ></span>
          :
          <Popover
            key={key}
            content={this.copyContent(item)}
            trigger="click"
            onClick={this.inputClick}
            open={copyInfo.type === PROJECT && copyInfo.id === item.id ? true : false}
            overlayClassName='sierra-copy-popover'>
            <CopyOutlined
              title='Copy Current Project'
              className='aurora-tree-copy-icon'
              key={key}
              onClick={(e) => this.copyVisibleChange(e, item, { type: PROJECT })} />
          </Popover>
      case REPLACE_PCB:
        if (item.category === DESIGN_SUCCESS) {
          return (<span key={key} className='tree-node-pcb-replace-icon'><UploadPCB key={key} data={item} type={REPLACE_PCB} /></span>);
        } break
      case LOADING:
        return <Tooltip key={key} title='This project is copying...' overlayClassName='aurora-tooltip'>
          <span
            key={key}
            onClick={(e) => this.spanClick(e)}
            title='Copying Current Project'
            className={`iconfont icon-loading3 aurora-tree-loading-icon copy-loading-icon`}
            style={{ color: '#1890ff' }}
          ></span>
        </Tooltip>;
      case UPDATE:
        return item.dataType !== LIBRARY_DATA ? <span key={key}
          onClick={(e) => this.props.updateLibraryItem(e, item)}
          className="iconfont icon-replace1 aurora-replace-icon"></span> : null;
      case MORE:
        const collectionKeys = getIconCollectionKey(item.dataType);
        const collectionMenu = this.getIconCollection(collectionKeys)
        return <Popover
          key={key}
          content={this.copyContent(item)}
          trigger="click"
          onClick={this.inputClick}
          open={copyInfo.type === VERIFICATION && copyInfo.id === item.id ? true : false}
          overlayClassName='sierra-copy-popover'
        >
          <IconCollection
            key={key}
            menu={collectionMenu}
            data={item}
          />
        </Popover>
      case INFO:
        const _dataType = item.dataType === PCB_PRE_LAYOUT ? PCB : item.dataType;
        if (_dataType === PCB && item.category !== DESIGN_SUCCESS) return null;
        return <RichTextEditor
          key={key}
          product={SIERRA}
          type={_dataType}
          item={item} updateTextEditorStatus={this.updateTextEditorStatus} />
      case IMPORTEXPORT:
        return uploading && uploading.key === item.key && uploading.status
          ?
          <Tooltip key={key} title='Files are being imported or exported' overlayClassName='aurora-tooltip'>
            <Loading3QuartersOutlined
              spin
              onClick={(e) => { e && e.stopPropagation(); showImportExportModel(true) }}
              key={key}
              className={item.dataType === MY_LIBRARY ? `aurora-tree-import-export-loading-icon aurora-tree-import-export-library-loading` : `aurora-tree-import-export-loading-icon aurora-tree-import-export-project-loading`} />
          </Tooltip>
          :
          <span
            key={key}
            title='Import Or Export'
            onClick={(e) => this.importExportFolder(e, item)}
            className={item.dataType === MY_LIBRARY ? `iconfont icon-import-export aurora-tree-import-export-library-icon` : `iconfont icon-import-export aurora-tree-import-export-project-icon`}
            style={{ color: '#1890ff' }}
          ></span>;
      case PCB_SETTING:
        return item.category === DESIGN_SUCCESS ? <span
          className="iconfont icon-icons8-circuit aurora-tree-pcb-setting-icon sierra-pcb-setting-icon"
          key={key}
          title="Components & Nets"
          onClick={(e) => updateSettingPCB(item, e)}
        /> : null;
      case COMBINE_INTERFACE:
        return (
          <span key={key} onClick={(e) => { this.inputClick(e) }} >
            <Tooltip
              placement="top"
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              title={simulateKeys && simulateKeys.length > 0 ? 'Combine Interfaces' : 'Please Check Interface!'}
              overlayClassName='aurora-tooltip'
            >
              <ApartmentOutlined
                id={`simulate-button-${item.key}`}
                key={key}
                className={simulateKeys && simulateKeys.length > 1 ? 'aurora-tree-combine-interface-icon' : 'aurora-tree-combine-interface-icon simulate-icon-disabled'}
                onClick={(e) => this.combineInterface(e, item.key, item)} />
            </Tooltip>
          </span>
        );
      case MULTI_SETUP:
        const { currentProjectVerifications } = this.props;
        const groups = [...new Set(currentProjectVerifications.map(item => item.group).filter(item => !!item))];
        return (
          <span key={key} onClick={(e) => { this.inputClick(e) }} >
            <Tooltip
              placement="top"
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              title='Multi-Interfaces Setup'
              overlayClassName='aurora-tooltip'
            >
              {viewList.includes(MULTI_INTERFACE_SETUP) || !groups.length ?
                <FormOutlined
                  key={key}
                  className={viewList.includes(MULTI_INTERFACE_SETUP) ? "aurora-tree-multi-interface-setup-open-icon aurora-tree-multi-interface-setup-icon" : "aurora-tree-multi-interface-setup-icon"}
                  onClick={() => this.updateMultiInterfaceSetup(null, viewList.includes(MULTI_INTERFACE_SETUP))} /> :

                <Dropdown menu={{ items: this.getMultiSetupMenu(groups) }} trigger={['click']}>
                  <FormOutlined key={key} className="aurora-tree-multi-interface-setup-icon" />
                </Dropdown>
              }
            </Tooltip>
          </span>
        );
      case DEFAULT:
        return defaultBufferSpice && defaultBufferSpice.libraryId === item.id ?
          <Tooltip key={key} title='Remove default receiver buffer model' overlayClassName='aurora-tooltip'>
            <PushpinFilled
              className="aurora-tree-set-icon"
              onClick={() => this.changeDefaultSpice(false)} />
          </Tooltip>
          :
          <Tooltip key={key} title='Set as default receiver buffer model' overlayClassName='aurora-tooltip'>
            <PushpinOutlined
              className="aurora-tree-set-icon"
              onClick={() => this.changeDefaultSpice(item)} />
          </Tooltip>;
      default: break;
    }
  }

  getMultiSetupMenu = (groups) => {
    return [...groups.map(item => ({ key: item, label: item, onClick: () => this.updateMultiInterfaceSetup(item) })), { key: ALL_INTERFACES, label: 'All Interfaces', onClick: () => this.updateMultiInterfaceSetup(ALL_INTERFACES) }]
  }

  getVerificationGroupChecked = (itemData) => {
    const { simulateKeys, verificationId, refNetsLoading } = this.props;
    const ids = itemData.children.filter(item =>
      !(item.status === VERIFY_RUNNING
        || item.status === WAITING
        || item.simStatus
        || (verificationId === item.id && refNetsLoading && refNetsLoading.loading))
    ).map(item => item.id);
    const selectedList = ids.filter(item => !!simulateKeys.find(it => it.verificationId === item));

    if (selectedList.length && selectedList.length < ids.length) {
      return { indeterminate: true, checked: false }
    }

    if (selectedList.length && selectedList.length === ids.length) {
      return { indeterminate: false, checked: true }
    }

    return {
      indeterminate: false,
      checked: false
    }
  }

  getIconCollection = (keys) => {
    return keys.map(key => {
      switch (key) {
        case RENAME:
          return { title: "Rename", icon: 'edit', func: this.renameInterface };
        case DELETE:
          return { title: "Delete", icon: 'close', func: this._deleteClick };
        case COPY:
          return { title: "Copy", icon: 'copy', func: this.copyVisibleChange, params: { type: VERIFICATION } };
        case EXPERIMENT:
          return { title: "Sweep", icon: 'experiment', func: this.addExperimentItem, tooltip: "Create sweep based on the current Interface" };
        default: return {};
      }
    })
  }

  updateTextEditorStatus = () => {
    this.setState({ textEditorStatus: !this.state.textEditorStatus })
  }

  libraryMenu = (item) => {
    return [
      { key: FOLDER, label: 'Create folder', onClick: (e) => this.addLibraryItem(e, item, FOLDER) },
      { key: FILE, label: item.key === VECTOR ? "Upload / Input" : "Upload file", onClick: (e) => this.addLibraryItem(e, item, FILE) },
      { key: BLANK_FILE, label: 'Create file', onClick: (e) => this.addLibraryItem(e, item, BLANK_FILE) }
    ]
  }

  _addItem = (e, item) => {
    e.stopPropagation();
    switch (item.dataType) {
      case PROJECTS:
        this.addProjects();
        break;
      case VERIFICATIONS:
        this.addVerification(item.key);
        break;
      case ADD_INTERFACE:
        this.props.addIdentification(item);
        break;
      case LIBRARY:
        item.key === TRACE && this.props.addItem({
          key: item.key,
          dataType: item.dataType,
          item
        })
        break;
      default: break;
    }
  }

  addLibraryItem = (e, item, key) => {
    e.domEvent.stopPropagation();
    switch (key) {
      case FILE:
        this.props.addItem({ key: item.dataType === LIBRARY ? item.key : item.fileType, dataType: item.dataType, item })
        break;
      case FOLDER:
        this.addLibraryFolder(item);
        break;
      case BLANK_FILE:
        this.props.showLibraryFile(item);
        break;
      default: break;
    }
  }

  importExportFolder = (e, item) => {
    e.stopPropagation();
    this.props.importExportData(item)
  }

  addLibraryFolder = (item) => {
    let { treeItems, expandedKeys, folderCreateInfo } = this.props;

    if (folderCreateInfo) {
      const { type, path } = folderCreateInfo;
      const msg = path ? `There is already folder being created in '${type}/${path}'.` : `There is already folder being created in '${type}'.`;
      message.error(msg, 10);
      return;
    }

    if (!expandedKeys.includes(item.key)) {
      this.props.expandMenu([...expandedKeys, item.key]);
    }
    const type = item.type ? item.type : item.key;
    const { index } = getLibraryIndex(type);
    const _treeItems = [...treeItems];
    const typeList = index.length > 1 ? _treeItems[0].children[index[0]].children[index[1]].children : _treeItems[0].children[index[0]].children;

    let newList = typeList;
    if (item.path) {
      const path = item.path.split('/');

      for (let i = 0; i < path.length - 1; i++) {
        const folderIndex = newList.findIndex(it => it.name === path[i]);
        newList = newList[folderIndex].children;
      }
    }
    const parentId = item.id ? item.id : '-1';
    newList.unshift({
      id: '',
      name: '',
      key: `${item.key}-create`,
      path: item.path,
      type,
      parentId,
      dataType: LIBRARY_FOLDER_CREATE,
      nodeClass: 'folder-create-node'
    });

    this.props.setFolderCreate({ type: getLibraryTitle(type), path: item.path, parentId });
    this.setState({
      autoFocus: true,
      inputFolder: ''
    })
    this.props.projectMenu({ treeItems: [..._treeItems] });
  }

  changeDefaultSpice = (itemData) => {
    const data = itemData ? { libraryId: itemData.id, name: itemData.fileName, libraryType: itemData.fileType } : { libraryId: "", name: "", libraryType: "" }
    this.props._changeDefaultLibrary(data);
  }

  addProjects = () => {
    let { treeItems, expandedKeys } = this.props;
    if (!expandedKeys.includes(PROJECTS)) {
      this.props.expandMenu([...expandedKeys, PROJECTS]);
    }

    if (treeItems[1].children) {
      let dataTypes = treeItems[1].children.map(item => item.dataType);

      if (dataTypes.includes(PROJECT_CREATE)) {
        message.error('There are already projects being created.');
        return;
      }
    }

    const name = getDefaultName({ nameList: treeItems[1].children, defaultKey: "Project" });

    treeItems[1].children.unshift(
      {
        id: name,
        name: name,
        key: name,
        dataType: PROJECT_CREATE,
        nodeClass: 'project-create-node'
      }
    );
    this.props.projectMenu({ treeItems: [...treeItems] });
    this.setState({
      autoFocus: true,
      inputValue: name
    })
  }

  addVerification = (key, e) => {
    e && e.stopPropagation && e.stopPropagation()
    let { treeItems, currentProjectVerifications } = this.props;
    const projectId = strDelimited(key, "-", { returnIndex: 1 });
    const ProjectList = treeItems[1].children;
    const projectIndex = ProjectList.findIndex(item => item.id === projectId);
    if (treeItems[1].children[projectIndex] && treeItems[1].children[projectIndex].children[1] && treeItems[1].children[projectIndex].children[1].children) {
      const dataTypes = treeItems[1].children[projectIndex].children[1].children.map(item => item.dataType);
      if (dataTypes.includes(VERIFICATION_CREATE)) {
        message.error('There are already interface being created.');
        return;
      }
    }

    // children[1].children: verification list
    const name = getDefaultName({ nameList: currentProjectVerifications, defaultKey: `Interface` });
    treeItems[1].children[projectIndex].children[1].children.push(
      {
        id: name,
        name: name,
        key: `${VERIFICATION_CREATE}-${projectId}`,
        dataType: VERIFICATION_CREATE,
        nodeClass: 'verification-create-node'
      }
    );
    this.props.projectMenu({ treeItems: [...treeItems] });
    let { expandedKeys } = this.props;
    const openVerifications = expandedKeys.find(item => item === `${VERIFICATIONS}-${projectId}`);
    if (!openVerifications) {
      expandedKeys.push(`${VERIFICATIONS}-${projectId}`);
    }
    this.props.expandMenu(expandedKeys);
    this.setState({
      autoFocus: true,
      inputVerificationName: name
    });
  }

  addExperimentItem = (e, itemData, del = false) => {
    e.stopPropagation();
    let { treeItems, currentProjectId } = this.props;
    const verificationId = del ? itemData.verificationId : itemData.id;
    const group = itemData.group;
    const newTree = [...treeItems]
    const ProjectList = newTree[1].children;
    const currentProject = ProjectList.find(item => item.id === currentProjectId);
    let interfaceList = currentProject.children[1].children, groupIndex = -1, currentVerification = null;
    if (group) {
      groupIndex = interfaceList.findIndex(it => it.id === group);
      currentVerification = currentProject.children[1].children[groupIndex].children.find(item => item.id === verificationId);
    } else {
      currentVerification = currentProject.children[1].children.find(item => item.id === verificationId);
    }

    if (!del) {
      if (currentVerification && currentVerification.children) {
        const dataTypes = currentVerification.children.map(item => item.dataType);
        if (dataTypes.includes(EXPERIMENTS_CREATE)) {
          message.error('There are already experiment being created.');
          return;
        }
      }

      const name = getDefaultName({ nameList: currentVerification.children.filter(item => item.name !== 'Result'), defaultKey: `Sweep` });
      currentVerification.children.push(
        {
          id: name,
          name: name,
          key: `${EXPERIMENTS_CREATE}-${verificationId}`,
          dataType: EXPERIMENTS_CREATE,
          verificationId,
          nodeClass: 'experiments-create-node',
          group
        }
      );
      this.props.projectMenu({ treeItems: [...treeItems] });
      let { expandedKeys } = this.props;
      const openVerifications = expandedKeys.find(item => item === `${VERIFICATION}-${verificationId}`);
      if (!openVerifications) {
        expandedKeys.push(`${VERIFICATION}-${verificationId}`);
      }
      this.props.expandMenu(expandedKeys);
      this.setState({
        autoFocus: true,
        inputExpName: name
      });
    } else {
      const createIndex = currentVerification.children.findIndex(item => item.dataType === EXPERIMENTS_CREATE);
      if (createIndex > -1) {
        currentVerification.children.splice(createIndex, 1);
      }
      this.props.projectMenu({ treeItems: [...treeItems] });
      this.setState({
        inputExpName: null,
        expErrorMsg: null,
        autoFocus: false
      });
    }
  }

  copyContent = (item) => {
    const { copyInfo } = this.state;
    return (
      <Fragment> <Input
        defaultValue={copyInfo.name}
        className='sierra-copy-name'
        onFocus={(e) => this.copyNameChange(e, item)}
        onBlur={(e) => this.copyNameChange(e, item)}
        ref={(input) => { this.projectCopyInputRef = input; }}
        onPressEnter={(e) => this.copyProject(e, item)}
        onClick={(e) => this.inputClick(e)}
        addonAfter={<Fragment>
          <CloseOutlined
            className='aurora-tree-create-icon'
            onClick={(e) => this.cancelCopyProject(e)} />
          <CheckOutlined
            className='aurora-tree-create-icon mg-r-8'
            onClick={(e) => this.copyProject(e, item)} />
        </Fragment>}
      />
        {copyInfo.error && <span className='sierra-copy-error' onClick={(e) => this.inputClick(e)}>{copyInfo.error}</span>}
      </Fragment>
    );
  }

  copyVisibleChange = (e, item, { type }) => {
    e.stopPropagation();
    const { projectList, currentProjectVerifications } = this.props;
    const nameList = type === PROJECT ? projectList : currentProjectVerifications;
    const defaultName = getDefaultName({ nameList, defaultKey: `${item.name}_Copy`, firstIndex: 1 });
    this.setState({
      copyInfo: {
        id: item.id,
        name: defaultName,
        type
      }
    }, () => {
      if (this.projectCopyInputRef) {
        const { input } = this.projectCopyInputRef;
        input.focus();
      }
    })
  }

  copyNameChange = (e, item) => {
    e.stopPropagation();
    this.setState({
      copyInfo: {
        ...this.state.copyInfo,
        name: e.target.value,
        error: null
      }
    })
  }

  cancelCopyProject = (e) => {
    e.stopPropagation();
    this.setState({
      copyInfo: {}
    })
  }

  copyProject = (e, item) => {
    e.stopPropagation();
    const { copyInfo } = this.state;
    const name = this.projectCopyInputRef && this.projectCopyInputRef.input ? this.projectCopyInputRef.input.value : copyInfo.name
    debounce(() => {
      const error = checkNameFormat(name);
      if (error) {
        this.setState({
          copyInfo: {
            ...this.state.copyInfo,
            error
          }
        });
        return;
      }

      const { projectList, currentProjectVerifications } = this.props;
      const nameList = copyInfo.type === PROJECT ? projectList : currentProjectVerifications;
      const exsitV = nameList.map(item => item.name);
      if (exsitV.includes(name)) {
        this.setState({
          copyInfo: {
            ...this.state.copyInfo,
            error: 'Project:' + name + ' already exists!'
          }
        });
        return;
      }
      const type = copyInfo.type;
      this.setState({
        copyLoading: item.id,
        copyInfo: {}
      });
      if (type === PROJECT) {
        sierraProjectCopy(item.id, name).then(res => {
          if (res) {
            this.props.clearCurrentProject();
            this.props.changeViewList([]);
            this.props.closeTabFooter();
            this.props._UpdateProjectMenu({ openProjectId: res.id });
          } else {
            message.error('Copying project failed!');
          }
          this.setState({
            copyLoading: null
          })
        })
      } else if (type === VERIFICATION) {
        const { currentProjectId } = this.props;
        sierraInterfaceCopy(item.id, name).then(res => {
          if (res) {
            this.props.openProject(currentProjectId)
          } else {
            message.error('Copying interface failed!');
          }
          this.setState({
            copyLoading: null
          })
        })
      }
    }, 1000, true, 'sierraCopy')();
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  getMacroModelingStatus = (item) => {
    const { touchstoneRunningList } = this.props;
    const _item = touchstoneRunningList.find(it => it.id === item.id);
    let title = null, iconType = null,
      status = _item ? _item.status : [taskStatus.RUNNING, taskStatus.WAITING].includes(item.status) ? taskStatus.SUCCEED : item.status;
    switch (status) {
      case taskStatus.WAITING:
        title = "This file is not ready for simulation.";
        iconType = "icon-Waiting";
        break;
      case taskStatus.RUNNING:
        title = "This file is not ready for simulation.";
        iconType = "icon-loading3";
        break;
      case taskStatus.FAILED:
      case taskStatus.CANCEL:
        title = "This file is broken, please upload again.";
        iconType = "exclamation-circle";
        break;
      default:
        break;
    }
    return { title, iconType };
  }

  renderTitle = (item) => {
    const { copyProjectList } = this.props;
    let dataType = item.dataType;
    if (copyProjectList && copyProjectList.length > 0) {
      copyProjectList.forEach(it => {
        if (it.id === item.id) {
          item.children = [];
          item.dataType = COPY_PROJECT;
        }
      })
    }
    switch (dataType) {
      case LIBRARY_FOLDER_CREATE:
        return this.folderCreate(item);
      case PROJECT_CREATE:
      case VERIFICATION_CREATE:
      case EXPERIMENTS_CREATE:
        return this.projectItemCreate(item, dataType);
      case VERIFICATION:
        return this.VerificationTitle(item);
      case LIBRARY:
      case PROJECTS:
      case MY_LIBRARY:
      case PCBS:
      case VERIFICATIONS:
      case TRASH:
      case LIBRARY_OPEN:
        return this.outTitle(item);
      case PROJECT:
        return this.ProjectTitle(item);
      case EXPERIMENTS:
        return this.SweepTitle(item);
      case LIBRARY_DATA:
      case LIBRARY_FILE:
      case LIBRARY_FOLDER_FILE:
        return this.libraryTitle(item);
      case LIBRARY_FOLDER:
        return this.folderTitle(item);
      case COPY_PROJECT:
        return this.CopyingProjectTitle(item);
      case ADD_INTERFACE:
        return this.addInterfaceTitle(item);
      default:
        return this.defaultTitle(item);
    }
  }

  ProjectTitle = (item) => {
    const { projectRenameId, projectInputName, projectRenameError } = this.state;
    const Keys = IconKey[getIconKey(item.dataType)];
    return projectRenameId && projectRenameId === item.id ?
      <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={projectRenameError ? projectRenameError : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={projectRenameError ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            defaultValue={projectInputName}
            className='project-name'
            onFocus={(e) => this.ProjectNameChange(e)}
            onBlur={(e) => this.ProjectNameChange(e)}
            ref={(input) => { this.inputProjectReNameRef = input; }}
            onPressEnter={(e) => this.changeProjectName(e, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => this.cancelProjectName(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={(e) => this.changeProjectName(e, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
      :
      <span>
        <Tooltip
          key={item.key}
          placement="topLeft"
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          className='sierra-tooltip-span'
          overlayClassName='aurora-tooltip'
        >
          <span className='sierra-project-title' onDoubleClick={(e) => this.renameProject(e, item)}>{item.name}</span>
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </span>;
  }

  CopyingProjectTitle = (item) => {
    const Keys = IconKey[getIconKey(COPY_PROJECT)];
    return <Fragment>
      <Tooltip
        key={item.key}
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={item.name}
        className='sierra-tooltip-span'
        overlayClassName='aurora-tooltip'
      >
        <span className='sierra-project-title'>{item.name}</span>
      </Tooltip>
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </Fragment>
  }

  addInterfaceTitle = (item) => {
    return <Fragment>
      <span className="aurora-add-interface-action sierra-add-interface-content" onClick={(e) => this.addVerification(item.key, e)}>
        Add
      </span>
      <span className="aurora-add-interface-action sierra-search-interface-content" onClick={(e) => this._addItem(e, item)}>
        Search
      </span>
    </Fragment>
  }

  changeInterfaceRenameType = (type) => {
    this.setState({
      renameType: type
    })
  }

  VerificationTitle = (item) => {
    const { renameId, inputName, renameErrorMsg, renameType } = this.state;
    const Keys = IconKey[getIconKey(item.dataType)];
    let { msg } = getVerificationStatus(item.status, item.readyForSim, item);
    const color = this.getInterfaceTypeColor(item.type)
    return renameId && renameId === item.id ?
      <span className='verification-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={renameErrorMsg ? renameErrorMsg : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={renameErrorMsg ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <SpaceCompact>
            <Select
              className="sierra-interface-type-input"
              value={renameType}
              onChange={this.changeInterfaceRenameType}
              popupMatchSelectWidth={false}
            >
              {INTERFACE_TYPE_LIST.map(type =>
                <Option key={type} value={type}>{type}</Option>
              )}
            </Select>
            <Input
              defaultValue={inputName}
              className='project-name sierra-interface-create-input'
              onFocus={(e) => this.interfaceNameChange(e)}
              onBlur={(e) => this.interfaceNameChange(e)}
              ref={(input) => { this.inputReNameRef = input; }}
              onPressEnter={(e) => this.changeInterfaceName(e, item)}
              onClick={(e) => this.inputClick(e)}
              addonAfter={<Fragment>
                <CloseOutlined
                  className='aurora-tree-create-icon'
                  onClick={(e) => this.cancelInterfaceName(e, item)} />
                <CheckOutlined
                  className='aurora-tree-create-icon mg-r-8'
                  onClick={(e) => this.changeInterfaceName(e, item)} />
              </Fragment>}
            />
          </SpaceCompact>
        </Tooltip>
      </span>
      :
      <div>
        <Tooltip
          placement="topLeft"
          title={<Fragment><span>{item.name}</span>{msg ? <span> · {msg}</span> : null}</Fragment>}
          className='sierra-verification-tooltip'
          overlayClassName='aurora-tooltip'
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
        >
          <span
            className='sierra-verification-name-span'
            onDoubleClick={(e) => this.renameInterface(e, item)}>{item.name}</span>
          {item.type ? <Tag
            color={color}
            className={`aurora-interface-type-tag aurora-tag-${color}`}
            style={{ width: 50 }}
          >{item.type}</Tag> : ""}
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </div>;
  }

  getInterfaceTypeColor = (type) => {
    switch (type) {
      case 'I2C':
        return "blue";
      case 'I3C':
        return "green"
      case 'SPI':
        return "magenta"
      case 'ESPI':
        return "cyan";
      case 'QSPI':
        return "volcano";
      case 'UART':
        return "brown";
      case 'I2S':
        return "geekblue"
      case 'CLK':
        return "orange"
      case 'Custom':
        return "purple"
      case "SPMI":
        return "gold"
      default: return
    }
  }

  SweepTitle = (item) => {
    const { sweepRenameId, sweepInputName, sweepRenameError } = this.state;
    const Keys = IconKey[getIconKey(item.dataType)];
    return sweepRenameId && sweepRenameId === item.id ?
      <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={sweepRenameError ? sweepRenameError : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={sweepRenameError ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            defaultValue={sweepInputName}
            className='project-name'
            onFocus={(e) => this.sweepNameChange(e)}
            onBlur={(e) => this.sweepNameChange(e)}
            ref={(input) => { this.inputSweepRef = input; }}
            onPressEnter={(e) => this.changeSweepName(e, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => this.cancelSweep(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={(e) => this.changeSweepName(e, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
      :
      <Fragment>
        <Tooltip
          key={item.key}
          placement="topLeft"
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          className='sierra-tooltip-span'
          overlayClassName='aurora-tooltip'
        >
          <span className='sierra-sweep-title' onDoubleClick={(e) => this.renameSweep(e, item)}>{item.name}</span>
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </Fragment>;
  }

  renameProject = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectRenameId: item.id,
      projectInputName: item.name
    }, () => {
      if (this.inputProjectReNameRef) {
        const { input } = this.inputProjectReNameRef;
        input.focus();
      }
    });
  }

  cancelProjectName = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectRenameId: null,
      projectInputName: null,
      projectRenameError: null
    })
  }

  inputFocus = (e, params) => {
    this.setState({
      [params.error]: null,
      [params.name]: e.target.value
    })
  }

  ProjectNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      projectInputName: e.target.value,
      projectRenameError: null
    })
  }

  changeProjectName = (e, item) => {
    const { projectInputName } = this.state;
    const name = this.inputProjectReNameRef && this.inputProjectReNameRef.input ? this.inputProjectReNameRef.input.value : projectInputName;
    debounce(() => {
      if (name === item.name) {
        this.setState({
          projectRenameId: null,
          projectInputName: null,
          projectRenameError: null
        });
        return;
      }
      const error = checkNameFormat(name);
      if (error) {
        this.setState({
          projectRenameError: error
        });
        return;
      }
      const { projectList } = this.props;
      const _filter = projectList.filter(project => project.id !== item.id);
      const exsitV = _filter.map(item => item.name);
      if (exsitV.includes(name)) {
        this.setState({
          projectRenameError: 'Project:' + name + ' already exists!'
        });
        return;
      }
      this.setState({
        projectRenameId: null,
        projectInputName: null,
        projectRenameError: null
      });
      this.props.SaveProjectName(name, item);
    }, 1000, true, 'sierraProjectName')()
  }

  cancelInterfaceName = (e, item) => {
    e.stopPropagation();
    this.setState({
      renameId: null,
      inputName: null,
      renameErrorMsg: null,
      renameType: ""
    })
  }

  renameInterface = (e, item) => {
    e.stopPropagation();
    this.setState({
      renameId: item.id,
      inputName: item.name,
      renameType: item.type
    }, () => {
      if (this.inputReNameRef) {
        const { input } = this.inputReNameRef;
        input.focus();
      }
    });
  }

  interfaceNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      inputName: e.target.value,
      renameErrorMsg: null
    })
  }

  changeInterfaceName = (e, item) => {
    e.stopPropagation();
    const { inputName } = this.state;
    const name = this.inputReNameRef && this.inputReNameRef.input ? this.inputReNameRef.input.value : inputName;
    debounce(() => {
      const { renameType } = this.state;
      if (name === item.name && renameType === item.type) {
        this.setState({
          renameId: null,
          inputName: null,
          renameErrorMsg: null,
          renameType: ""
        });
        return;
      }
      const error = checkNameFormat(name);
      if (error) {
        this.setState({
          renameErrorMsg: error
        });
        return;
      }
      const { currentProjectVerifications } = this.props;
      const _filter = currentProjectVerifications.filter(verification => verification.id !== item.id);
      const exsitV = _filter.map(item => item.name);
      if (exsitV.includes(name)) {
        this.setState({
          renameErrorMsg: 'Verification:' + name + ' already exists!'
        });
        return;
      }
      this.props.SaveInterfaceName(name, renameType, item);
      this.setState({
        renameId: null,
        inputName: null,
        renameErrorMsg: null,
        renameType: ""
      });
    }, 1000, true, 'sierraInterfaceName')();
  }

  libraryFileOperation = (e, item) => {
    e.stopPropagation();

    if (item.type === 'file' && (item.dataType === LIBRARY_FILE || item.dataType === LIBRARY_FOLDER_FILE)) {
      this.props.showLibraryFile(item);
    }
    if (item.dataType === LIBRARY_DATA
      || (item.dataType === LIBRARY && [PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY, NETS_FILTER_LIBRARY].includes(item.key))
    ) {
      this.props.editItem(e, { key: item.key, dataType: item.dataType, item });
    }
  }

  inputNameChange = (e, params) => {
    this.setState({
      [params.error]: null,
      [params.name]: e.target.value,
      autoFocus: false
    })
  }

  inputFolderNameChange = (e) => {
    this.setState({
      folderErrorMsg: null,
      inputFolder: e.target.value
    })
  }

  inputVerificationNameChange = (e) => {
    this.setState({
      interfaceErrorMsg: null,
      inputVerificationName: e.target.value
    });
  }

  folderCreate = (item) => {
    const { folderErrorMsg, inputFolder } = this.state;
    item.name = inputFolder;
    return (
      <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={folderErrorMsg ? folderErrorMsg : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={folderErrorMsg ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            defaultValue={item.name}
            className='project-name'
            onChange={(e) => this.inputFolderNameChange(e)}
            ref={(input) => { this.folderInputRef = input; }}
            onPressEnter={(e) => this.sierraLibraryFolderCreate(e, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                id="sierra-close-project-icon"
                onClick={(e) => this.cancelLibraryFolder(e, item)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                id="sierra-check-project-icon"
                onClick={(e) => this.sierraLibraryFolderCreate(e, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
    );
  }

  sierraLibraryFolderCreate = (e, item) => {
    e.stopPropagation();
    debounce(() => {
      let { name } = item;
      const error = checkNameFormat(name, { maxLength: 20 });
      if (error) {
        this.setState({
          folderErrorMsg: error
        });
        return;
      }
      this.setState({
        folderErrorMsg: null,
        autoFocus: false
      });
      this.props.createFolder(item);
    }, 1000, true, 'sierraLibraryFolder')();
  }

  sierraProjectCreate = (e, item) => {
    e.stopPropagation();
    const { inputValue } = this.state;
    const value = this.inputRef && this.inputRef.input && this.inputRef.input.value ? this.inputRef.input.value : inputValue;
    debounce(() => {
      const error = checkNameFormat(value);
      if (error) {
        this.setState({
          projectErrorMsg: error
        });
        return;
      }
      this.setState({
        projectErrorMsg: null
      });
      this.setState({
        autoFocus: false
      })
      this.props.createProject(value, item);
    }, 1000, true, 'sierraProjectCreate')();
  }

  cancelCreateProject = (e, item) => {
    e.stopPropagation();
    this.setState({
      projectErrorMsg: null,
      autoFocus: false
    });
    this.props.deleteCreateProject(e, item);
  }

  cancelLibraryFolder = (e, item) => {
    e.stopPropagation();
    let { treeItems } = this.props;
    const { index } = getLibraryIndex(item.type);
    const _treeItems = [...treeItems];
    const typeList = index.length > 1 ? _treeItems[0].children[index[0]].children[index[1]].children : _treeItems[0].children[index[0]].children;

    let newList = typeList;
    if (item.path) {
      const path = item.path.split('/');

      for (let i = 0; i < path.length - 1; i++) {
        const folderIndex = newList.findIndex(it => it.name === path[i]);
        newList = newList[folderIndex].children;
      }
    }
    newList.shift();

    this.props.setFolderCreate(null);
    this.setState({
      autoFocus: false,
      inputFolder: '',
      folderErrorMsg: null
    })
    this.props.projectMenu({ treeItems: [..._treeItems] });
  }

  verificationCreate = (e, item) => {
    e && e.stopPropagation && e.stopPropagation();
    const { inputVerificationName, interfaceType } = this.state;
    const value = this.inputRef && this.inputRef.input && this.inputRef.input.value ? this.inputRef.input.value : inputVerificationName;
    debounce(() => {
      const error = checkNameFormat(value);
      if (error) {
        this.setState({
          interfaceErrorMsg: error
        });
        return;
      }
      this.setState({
        autoFocus: false,
        interfaceErrorMsg: null
      }, () => {
        this.props.createVerification(value, interfaceType, item)
      })
    }, 1000, true, 'sierraVerificationCreate')()
  }

  cancelVerificationCreate = (e, item) => {
    e.stopPropagation();
    this.setState({
      interfaceErrorMsg: null
    });
    this.props.deleteVerificationCreate(e, item);

  }

  renameSweep = (e, item) => {
    e.stopPropagation();
    this.setState({
      sweepRenameId: item.id,
      sweepInputName: item.name
    }, () => {
      if (this.inputSweepRef) {
        const { input } = this.inputSweepRef;
        input.focus();
      }
    });
  }

  cancelSweep = (e, item) => {
    e.stopPropagation();
    this.setState({
      sweepRenameId: null,
      sweepInputName: null,
      sweepRenameError: null
    })
  }

  sweepNameChange = (e) => {
    e.stopPropagation();
    this.setState({
      sweepInputName: e.target.value,
      sweepRenameError: null
    })
  }

  changeSweepName = (e, item) => {
    const { sweepInputName } = this.state;
    const name = this.inputSweepRef && this.inputSweepRef.input ? this.inputSweepRef.input.value : sweepInputName;
    debounce(() => {
      if (name === item.name) {
        this.setState({
          sweepRenameId: null,
          sweepInputName: null,
          sweepRenameError: null
        });
        return;
      }
      const error = checkNameFormat(name, { maxLength: 20 });
      if (error) {
        this.setState({
          sweepRenameError: error
        });
        return;
      }
      const _filter = SweepData.getLocalData(item.verificationId) || [];
      const exsitV = _filter.map(item => item.name);
      if (exsitV.includes(name)) {
        this.setState({
          sweepRenameError: 'Sweep:' + name + ' already exists!'
        });
        return;
      }
      this.setState({
        sweepRenameId: null,
        sweepInputName: null,
        sweepRenameError: null
      });
      this.props.saveSweepName(name, item);
    }, 1000, true, 'sierraChangeSweepName')()
  }

  experimentsCreate = (e, item) => {
    e && e.stopPropagation && e.stopPropagation();
    const { inputExpName } = this.state;
    const value = this.inputRef && this.inputRef.input && this.inputRef.input.value ? this.inputRef.input.value : inputExpName;
    debounce(() => {
      let error = checkNameFormat(value, { maxLength: 20 });
      const { experimentInfo = {}, currentProjectVerifications } = this.props;
      const { base = {}, experiments = [] } = experimentInfo;
      const _filter = [base, ...experiments].filter(project => project.id !== item.id);
      const exsitV = _filter.map(item => item.name);
      if (exsitV.includes(value)) {
        error = `Sweep: ${value} already exists!`
      }
      if (error) {
        this.setState({
          interfaceErrorMsg: error
        });
        return;
      }

      // Add loading status
      let { treeItems, currentProjectId } = this.props;
      const newTree = [...treeItems]
      const ProjectList = newTree[1].children;
      const currentProject = ProjectList.find(it => it.id === currentProjectId);
      let interfaceList = currentProject.children[1].children, groupIndex = -1, currentVerification = null;
      const group = (currentProjectVerifications.find(it => it.id === item.verificationId) || {}).group;
      if (group) {
        groupIndex = interfaceList.findIndex(it => it.id === group);
        currentVerification = currentProject.children[1].children[groupIndex].children.find(it => it.id === item.verificationId);
      } else {
        currentVerification = currentProject.children[1].children.find(it => it.id === item.verificationId);
      }
      if (!currentVerification || !currentVerification.children) {
        return
      }
      currentVerification.children.forEach(it => { if (it.dataType === EXPERIMENTS_CREATE) { it.loading = true } })
      this.props.projectMenu({ treeItems: [...treeItems] });

      this.setState({
        autoFocus: false,
        expErrorMsg: null,
        // inputExpName: null
      }, () => {
        this.props.createExperiments(value, item)
      })
    }, 1000, true, 'sierraExperimentsCreate')()
  }

  changeProjectType = (key) => {
    this.setState({
      interfaceType: key
    })
  }

  projectItemCreate = (item, dataType) => {
    const { inputValue, projectErrorMsg, inputVerificationName, interfaceErrorMsg, inputExpName, expErrorMsg, interfaceType } = this.state;
    let name = "", error = "", params = {}, createFunction = null, cancelFunction = {}, cancel = false, loading = false, selectType = false;
    switch (dataType) {
      case VERIFICATION_CREATE:
        name = inputVerificationName;
        error = interfaceErrorMsg;
        params = { name: 'inputVerificationName', error: 'interfaceErrorMsg' };
        createFunction = this.verificationCreate;
        cancelFunction = this.cancelVerificationCreate;
        selectType = true;
        break;
      case EXPERIMENTS_CREATE:
        name = inputExpName;
        error = expErrorMsg;
        params = { name: 'inputExpName', error: 'expErrorMsg' };
        cancelFunction = this.addExperimentItem;
        createFunction = this.experimentsCreate;
        cancel = true;
        loading = item.loading ? true : false;
        break;
      case PROJECT_CREATE:
      default:
        name = inputValue;
        error = projectErrorMsg;
        params = { name: 'inputValue', error: 'projectErrorMsg' };
        createFunction = this.sierraProjectCreate;
        cancelFunction = this.cancelCreateProject;
        break;
    }
    return (
      <span className='project-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={error ? error : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={error ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <SpaceCompact>
            {selectType ?
              <Select
                className="sierra-interface-type-input"
                value={interfaceType}
                onChange={this.changeProjectType}
                popupMatchSelectWidth={false}
              >
                {INTERFACE_TYPE_LIST.map(type =>
                  <Option key={type} value={type}>{type}</Option>
                )}
              </Select> : null}
            <Input
              defaultValue={name}
              className={selectType ? " project-name sierra-interface-create-input" : 'project-name'}
              onFocus={(e) => this.inputFocus(e, params)}
              ref={(input) => { this.inputRef = input; }}
              onPressEnter={(e) => createFunction(e, item)}
              onClick={(e) => this.inputClick(e)}
              onBlur={(e) => this.inputNameChange(e, params)}
              addonAfter={loading ? <LoadingOutlined className='aurora-tree-create-icon aurora-tree-create-loading-icon' /> : <Fragment>
                <CloseOutlined
                  className='aurora-tree-create-icon'
                  id="sierra-close-project-icon"
                  onClick={(e) => cancelFunction(e, item, cancel)} />
                <CheckOutlined
                  className='aurora-tree-create-icon mg-r-8'
                  id="sierra-check-project-icon"
                  onClick={(e) => createFunction(e, item)} />
              </Fragment>}
            />
          </SpaceCompact>
        </Tooltip>
      </span>
    );
  }

  defaultTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    if (item.dataType === RESULT) {
      return <span className='result-style'>{item.name}
        {item.status === VERIFY_SUCCESS && <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>}
      </span>
    }
    if (item.dataType === PCB) {
      return (
        <span>
          <Tooltip
            placement="topLeft"
            mouseEnterDelay={0.8}
            mouseLeaveDelay={0}
            title={item.name}
            overlayClassName='aurora-tooltip'
            className={item.category === DESIGN_FAILED ? 'sierra-debug-tooltip-span' : 'sierra-tooltip-span'}
          >
            {item.name}
          </Tooltip>
          <Fragment>
            {item.category === DESIGN_FAILED &&
              <Tooltip title="PCB upload failed" overlayClassName='aurora-tooltip aurora-design-tooltip'>
                <ExclamationCircleOutlined className="debug-design-icon" />
              </Tooltip>
            }
          </Fragment>
          <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
        </span>
      );
    }

    if (item.dataType === PCB_PRE_LAYOUT) {
      return <span>
        <Tooltip
          placement="topLeft"
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          className='sierra-tooltip-span'
          overlayClassName='aurora-tooltip'
        >{item.name}
          <Tag
            className={`aurora-interface-type-tag`}
            style={{ width: 60, right: 10 }}
          >PreLayout</Tag>
        </Tooltip>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </span>

    }

    return <span>
      <Tooltip
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={item.name}
        className='sierra-tooltip-span'
        overlayClassName='aurora-tooltip'
      >{item.name}
      </Tooltip>
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  libraryTitle = (item) => {
    const { defaultBufferSpice } = this.props;
    const Keys = IconKey[getIconKey(item.dataType, item.fileType, item.type)];
    const isFile = this.isLibraryFile(item);
    const touchstoneClass = [PKG_TOUCHSTONE, CONNECTOR_TOUCHSTONE].includes(item.fileType) && item.status !== taskStatus.SUCCEED ? "sierra-library-touchstone-file-span" : "";
    const className = Keys.includes(DEFAULT) ? "sierra-default-library-file-span" : "";
    const name = item.fileType === TRACE && item.type ? `${item.name} - ${item.type.replace(item.type[0], item.type[0].toUpperCase())}` : item.name;
    return <span>
      <div className='tree-library-content'>
        <Tooltip
          key={item.key}
          placement="topLeft"
          mouseEnterDelay={0.8}
          mouseLeaveDelay={0}
          title={item.name}
          className={isFile ? `aurora-icon-color sierra-tooltip-span sierra-library-file-span ${touchstoneClass} ${className}` : `sierra-tooltip-span ${className} aurora-icon-color`}
          /*  className={'aurora-icon-color sierra-tooltip-span sierra-library-file-span ' + touchstoneClass} */
          overlayClassName='aurora-tooltip'
          onClick={(e) => this.libraryFileOperation(e, item)}
        >{name}
        </Tooltip>
        {item.dataType === LIBRARY_FILE && defaultBufferSpice && item.id === defaultBufferSpice.libraryId ?
          <Tag color="#fff" className="sierra-library-default-tag">Default</Tag> : null}
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </div>
    </span>
  }

  outTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType, item.key)];
    const isPartLibrary = item.dataType === LIBRARY && [PART_IBIS_LIBRARY, PART_REPEATER_LIBRARY, NETS_FILTER_LIBRARY].includes(item.key);
    return <span>
      <span
        className={isPartLibrary ? 'out-title-color aurora-icon-color' : 'out-title-color'}
        onClick={isPartLibrary ? (e) => this.libraryFileOperation(e, item) : null}
      >{item.name}</span>
      <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
    </span>
  }

  folderTitle = (item) => {
    const Keys = IconKey[getIconKey(item.dataType)];
    const { folderRenameId, folderRenameError } = this.state;
    return folderRenameId && folderRenameId === item.id ?
      <span className='verification-name-input' onClick={(e) => this.inputClick(e)}>
        <Tooltip
          className='aurora-error-tooltip'
          title={folderRenameError ? folderRenameError : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={folderRenameError ? true : false}
          getPopupContainer={() => document.getElementsByClassName('my-tree')[0]}
        >
          <Input
            defaultValue={item.name}
            className='project-name'
            ref={(input) => { this.inputReNameFolderRef = input; }}
            onPressEnter={(e) => this.changeFolderName(e, item)}
            onClick={(e) => this.inputClick(e)}
            addonAfter={<Fragment>
              <CloseOutlined
                className='aurora-tree-create-icon'
                onClick={(e) => this.cancelRenameFolder(e)} />
              <CheckOutlined
                className='aurora-tree-create-icon mg-r-8'
                onClick={(e) => this.changeFolderName(e, item)} />
            </Fragment>}
          />
        </Tooltip>
      </span>
      : <span className='sierra-library-folder'>
        <span className='sierra-folder-title' onDoubleClick={(e) => this.renameFolder(e, item)}>{item.name}</span>
        <Fragment>{Keys.map((key, index) => { return this.getIcon(key, item) })}</Fragment>
      </span>;
  }

  renameFolder = (e, item) => {
    e.stopPropagation();
    this.setState({
      folderRenameId: item.id,
      folderRenameError: null
    }, () => {
      if (this.inputReNameFolderRef) {
        const { input } = this.inputReNameFolderRef;
        input.focus();
      }
    })
  }

  cancelRenameFolder = (e) => {
    e.stopPropagation();
    this.setState({
      folderRenameId: null,
      folderRenameError: null
    })
  }

  changeFolderName = (e, item) => {
    e.stopPropagation();
    debounce(() => {
      if (this.inputReNameFolderRef) {
        const { value } = this.inputReNameFolderRef.input;
        const error = checkNameFormat(value, { maxLength: 20 });
        if (error) {
          this.setState({
            folderRenameError: error
          });
          return;
        }
        this.props.renameFolder(value, item);
        setTimeout(() => {
          this.setState({
            folderRenameId: null,
            folderRenameError: null,
            autoFocus: false
          });
        }, 500)
      } else {
        this.setState({
          folderRenameId: null,
          folderRenameError: null
        })
      }
    }, 1000, true, 'sierraChangeFolderName')();
  }

  isLibraryFile = (item) => {
    const dataType = item.dataType, type = item.type;
    let isFile = false;
    switch (dataType) {
      case LIBRARY_FILE:
      case LIBRARY_DATA:
      case LIBRARY_FOLDER_FILE:
        if (type === 'file') {
          isFile = true;
        }
        break;
      default: break
    }
    return isFile;
  }

  openProject = (id) => {
    this.props.openProject(id);
  }

  _deleteClick = (e, data) => {
    e.stopPropagation();
    this.setState({
      delConfirmInfo: data,
      delConfirmVisible: true
    })
  }

  batchDeleteClick = (e, key, item) => {
    e.stopPropagation();
    this.setState({
      delConfirmInfo: item,
      delConfirmVisible: true
    })
  }

  combineInterface = (e, key, item) => {
    this.props.combineInterface()
  }

  updateMultiInterfaceSetup = (group, close) => {
    if (group === null && !close) {
      group = ALL_INTERFACES;
    }
    this.props._updateMultiInterfaceSetupStatus(group, close)
  }

  deleteConfirm = (e, data) => {
    e.stopPropagation();
    this.props.delItem(e, data);
    this.setState({
      delConfirmInfo: null,
      delConfirmVisible: false
    })
  }

  cancelDel = (e) => {
    e.stopPropagation();
    this.setState({
      delConfirmInfo: null,
      delConfirmVisible: false
    })
  }

  onExpand = (keys) => {
    const { expandedKeys } = this.props;
    const { selectedKeys } = this.props;
    const openNew = keys.filter(key => !expandedKeys.includes(key));
    if (openNew.length) {
      const [key, id] = strDelimited(openNew[0], "-");
      if (key === PROJECT) {
        if (keys && keys.length > 0) {
          keys = keys.filter(item => {
            if (item) {
              const [_key, _id] = strDelimited(item, "-");
              if (!(_key === PROJECT && _id !== id)) {
                return item;
              }
            }
            return false;
          });
        }

        if (selectedKeys.length > 0) {
          this.props.changeTreeSelected([]);
        };

        this.props.clearCurrentProject();
        this.props.changeViewList([]);
        this.props.closeTabFooter();
        this.openProject(id);
      } else if (LIBRARY_SIERRA.includes(key)) {
        const libraryId = id ? id : '-1';
        this.props.openLibraryFolder(libraryId, key)
      } else if (key === VERIFICATION) {
        this.props.loadSweepList(id)
      }
    } else {
      const closeNode = expandedKeys.filter(key => !keys.includes(key));

      if (closeNode.length) {
        const [key] = strDelimited(closeNode[0], "-");
        if (key === PROJECT) {
          this.props.changeTreeSelected([]);
          this.props.changeViewList([]);
          this.props.clearCurrentProject(true);
          this.props.closeTabFooter();
        }
      }
    }

    this.props.expandMenu(keys);
  };

  onSelect = (selectedKeys, { selected, node }) => {
    /*  count += 1; */
    setTimeout(() => {
      if (!selected) return;
      const { expandedKeys, layout, pcbLayout } = this.props;
      const keys = [...expandedKeys]
      const eventKey = node.key;
      const [key, id] = strDelimited(eventKey, "-");
      if (!key || [ADD_INTERFACE, VERIFICATION_CREATE].includes(key)) return;
      const { _keys } = updateKeys(keys, eventKey, expandedKeys);
      this.onExpand(_keys);
      const prevSelectedKeys = this.props.selectedKeys;
      const selecteds = updateTreeSelectKeys({
        prevSelectedKeys: [...prevSelectedKeys],
        key,
        eventKey,
        selected,
        layout,
        pcbLayout,
        productType: SIERRA
      });
      selecteds.length && this.props.changeTreeSelected(selecteds);
      const preSelected = prevSelectedKeys.includes(eventKey);
      if (!preSelected) {
        switch (key) {
          case PCB:
            this.props.openPCB();
            break;
          case VERIFICATION:
            this.props.openVerification(id);
            break;
          case RESULT:
            this.props.showSierraResult(id);
            break;
          case EXPERIMENTS:
            this.props.openExperiment(id);
            break;
          case EXPERIMENTS_RESULT:
            this.props.showExperimentsResult(id);
            break;
          case PCB_PRE_LAYOUT:
            this.props.openPreLayout(id);
            break;
          case VERIFICATION_GROUP:
            this.props._updateMultiInterfaceSetupStatus(id);
            break;
          default: break;
        }
      }
      /*  count = 0; */
    }, 500);
  };

  updateProjectMenu = () => {
    const { currentProjectId } = this.props
    this.props._UpdateProjectMenu({ openProjectId: currentProjectId })
  }

  render() {
    const { treeItems, expandedKeys, selectedKeys } = this.props;
    const { delConfirmVisible, delConfirmInfo, projectRenameId } = this.state;
    const type = delConfirmInfo ? getType(delConfirmInfo.dataType, delConfirmInfo.fileType) : "";
    let errorMsg = delConfirmInfo ? getPopMessage(type, delConfirmInfo.dataType) : "";
    return (
      <Fragment>
        <TreeForm
          treeData={treeItems}
          renderTitle={this.renderTitle}
          onExpand={this.onExpand}
          defaultExpandedKeys={['library', 'projects']}
          expandedKeys={expandedKeys}
          onSelect={this.onSelect}
          selectedKeys={selectedKeys}
          autoExpandParent={false}
          defaultExpandParent={false}
          updateProjectMenu={this.updateProjectMenu}
          disableDragProjectId={projectRenameId}
        // Expand parent: autoExpandParent || !prevProps && defaultExpandParent (Auto find the parent node)
        />
        {
          delConfirmVisible ? <DelConfirm
            data={delConfirmInfo}
            deleteConfirm={this.deleteConfirm}
            cancelDel={this.cancelDel}
            message={errorMsg}
          /> : null}
      </Fragment>
    )
  }
}


const mapState = (state) => {
  const { SierraReducer: { sierra: { expandedKeys, refNetsLoading },
    project,
    simulationReducer,
    library: { touchstoneRunningList, folderCreateInfo, defaultBufferSpice },
    sweep: { experimentInfo }
  } } = state;
  const {
    treeItems, verificationId, viewList, layout,
    selectedKeys, currentProjectVerifications, currentProjectId,
    projectList = [], copyProjectList,
    pcbLayout
  } = project;
  const { simulateKeys } = simulationReducer;
  const { verificationName } = state.TabMonitorReducer;
  return {
    expandedKeys,
    treeItems,
    simulateKeys,
    verificationId,
    viewList,
    layout,
    selectedKeys,
    verificationName,
    currentProjectVerifications,
    projectList,
    copyProjectList,
    refNetsLoading,
    currentProjectId,
    folderCreateInfo,
    touchstoneRunningList,
    experimentInfo,
    pcbLayout,
    defaultBufferSpice
  }
}

const mapDispatch = (dispatch) => ({
  expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  UpdateLibraryMenu() {
    dispatch(updateLibraryMenu())
  },
  _UpdateProjectMenu(obj) {
    dispatch(updateProjectMenu(obj))
  },
  openProject(id) {
    dispatch(openProject(id));
  },
  clearCurrentProject(status) {
    dispatch(clearCurrentProject(status));
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  updateTrashMenu() {
    dispatch(updateTrashMenu());
  },
  changeTreeSelected(selectedKeys) {
    dispatch(changeTreeSelected(selectedKeys))
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList))
  },
  projectMenu({ treeItems }) {
    dispatch(projectMenu({ treeItems }));
  },
  openLibraryFolder(libraryId, key) {
    dispatch(openLibraryFolder(libraryId, key));
  },
  setFolderCreate(info) {
    dispatch(setFolderCreate(info)); // info - { type, path, parentId }
  },
  loadSweepList(id) {
    dispatch(loadSweepList(id));
  },
  saveSweepName(name, info) {
    dispatch(saveSweepName(name, info));
  },
  _updateMultiInterfaceSetupStatus(group, close) {
    dispatch(updateMultiInterfaceSetupStatus(group, close))
  },
  _changeDefaultLibrary(info) {
    dispatch(changeDefaultLibrary(info))
  }
});

export default connect(mapState, mapDispatch)(sierraTree);