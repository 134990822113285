import {
  GET_IR_EXPLORER_INFO,
  SAVE_IR_EXPLORER_INFO,
  UPDATE_IR_EXPLORER_INFO,
  UPDATE_LOADING_PCB,
  IR_EXPLORER_SELECT_NETS,
  SAVE_IR_VERIFICATIONID,
  DELETE_IR_DATA,
  UPDATE_COMP_RLC_PREFIX,
  UPDATE_IR_LOAD,
  CLEAR_CURRENT_IR_DATA,
  IGNORE_COMPONENTS,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  SAVE_COMPONENTS,
  UPLOAD_IR,
  CHANGE_TARGET_IC,
  UPLOAD_IR_TABLE_LOADING,
  DEBUG_MONITOR_ACTION,
  UPDATE_IR_RESULT_EXIST,
  SAVE_VRM_COMP,
  REFRESH_IR,
  POWER_NET_SELECT_PANEL,
  SELECT_PCB,
  CHANGE_PCB,
  COMPONENT_SETTING_UPDATE,
  UPDATE_EXTRACTION,
  SAVE_EXTRACTION,
  SEARCH_VRM
} from './actionType';

export const getIRExplorerInfo = (designId) => ({
  type: GET_IR_EXPLORER_INFO,
  designId
})

export const saveIRExplorerInfo = (info) => ({
  type: SAVE_IR_EXPLORER_INFO,
  info
})

export const updateIRData = (data) => ({
  type: UPDATE_IR_EXPLORER_INFO,
  data,
})

export const updateLoadingPCB = (IRloading) => ({
  type: UPDATE_LOADING_PCB,
  IRloading
})

export const cascadeIRSelectNets = (netType, nets, id, deleteNet) => ({
  type: IR_EXPLORER_SELECT_NETS,
  netType,
  nets,
  id,
  deleteNet
})

export const saveIRVerificationId = (id) => ({
  type: SAVE_IR_VERIFICATIONID,
  id
})

export const deleteIRData = (irId) => ({
  type: DELETE_IR_DATA,
  irId
})

export const updateCompRLCPrefix = (COMP_PREFIX_LIB, update) => ({
  type: UPDATE_COMP_RLC_PREFIX,
  COMP_PREFIX_LIB,
  update
})

export const updateIrLoad = (irLoadSelect) => ({
  type: UPDATE_IR_LOAD,
  irLoadSelect
})

export const clearCurrentIRData = () => ({
  type: CLEAR_CURRENT_IR_DATA
})

export const ignoreComponents = ({ part, components, checked, usage }) => ({
  type: IGNORE_COMPONENTS,
  part,
  components,
  usage,
  checked
});

export const saveSplitComponents = ({ part, splitPart, comps }) => ({
  type: SAVE_SPLIT_COMPONENTS,
  part,
  splitPart,
  comps
});

export const saveMergeComponents = ({ part, partList }) => ({
  type: SAVE_MERGE_COMPONENTS,
  part,
  partList
});

export const saveComponents = ({ part, model, value, splitPart, comps }) => ({
  type: SAVE_COMPONENTS,
  part,
  model,
  value,
  splitPart,
  comps
});

export const uploadIR = (verificationId, file, importType) => ({
  type: UPLOAD_IR,
  verificationId,
  file,
  importType
})

export const changeTargetIC = (value) => ({
  type: CHANGE_TARGET_IC,
  value
})

export const debugMonitorAction = (msg) => ({
  type: DEBUG_MONITOR_ACTION,
  msg
})

export const updateIRResultExist = (resultExist) => ({
  type: UPDATE_IR_RESULT_EXIST,
  resultExist
})

export const uploadIRTableloading = (loading) => ({
  type: UPLOAD_IR_TABLE_LOADING,
  loading
})

export const saveIRVRMComponent = (row, vrm) => ({
  type: SAVE_VRM_COMP,
  vrm,
  row
})

export const refreshIR = (selectNets) => ({
  type: REFRESH_IR,
  selectNets
})

export const updateSelectPowerNets = (obj) => ({
  type: POWER_NET_SELECT_PANEL,
  obj
})

export const updatePCB = (pcbId, designName) => ({
  type: SELECT_PCB,
  pcbId,
  designName
})

export const changePCB = (pcbId) => ({
  type: CHANGE_PCB,
  pcbId
})

export const updateIRComponentSetting = (bool) => ({
  type: COMPONENT_SETTING_UPDATE,
  bool
})

export const saveExtraction = (extraction) => ({
  type: SAVE_EXTRACTION,
  extraction
})

export const updateExtraction = (extraction) => ({
  type: UPDATE_EXTRACTION,
  extraction
})

export const searchVRMLoading = (boolean) => ({
  type: SEARCH_VRM,
  boolean
})