import {
  UPDATE_PRE_LAYOUT_CONTENT,
  FIRST_OPEN_PRE_LAYOUT,
  COMP_TABLE_LOADING,
  SCHEMATIC_LOADING,
  UPDATE_CANVAS,
  CREATE_LOADING
} from './actionTypes';
import { PreLayoutSetting, PreLayoutModel, PRE_LAYOUT_DESIGN_VERSION } from '../../../../services/Sierra/prelayout';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const setting = new PreLayoutSetting();

const defaultState = {
  id: "",
  name: "",
  ...setting,
  model: new PreLayoutModel(),
  components: [],
  signals: [],
  designVersion: PRE_LAYOUT_DESIGN_VERSION,
  firstOpen: false,
  compTableLoading: false,
  schematicLoading: false,
  canvasUpdate: false,
  create: false
}

export const SierraPreLayoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PRE_LAYOUT_CONTENT:
      return {
        ...state,
        ...action.params
      }
    case FIRST_OPEN_PRE_LAYOUT:
      return {
        ...state,
        firstOpen: action.boolean
      }
    case COMP_TABLE_LOADING:
      return {
        ...state,
        compTableLoading: action.boolean
      }
    case SCHEMATIC_LOADING:
      return {
        ...state,
        schematicLoading: action.boolean
      }
    case UPDATE_CANVAS:
      return {
        ...state,
        canvasUpdate: action.boolean
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CREATE_LOADING:
      return {
        ...state,
        create: action.boolean
      }
    default: return state
  }
};