import {
  CHANGE_RESULT_TYPE,
  GET_IR_LIST_VALUE,
  SAVE_IR_VALUE,
  GET_IR_VALUE_FILE,
  GET_IR_INFO_SUCCESS,
  UPDATE_IR_SELECTED_KEY,
  UPDATE_IR_RESULT_TABLE_LOADING,
  CLEAR_IR_RESULT_DATA
} from './actionType';
const defaultState = {
  resultKey: 'pin',
  IRMenuList: [],
  IRSelectedKey: '',
  irData: [],
  IRResultTableLoading: false,
  resultPowerDomainData: []
}
export const resultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_RESULT_TYPE:
      return {
        ...state,
        resultKey: action.resultKey !== "setup" ? action.resultKey : state.resultKey,
        IRResultTableLoading: true
      }
    case GET_IR_LIST_VALUE:
      return {
        ...state,
        verificationId: action.verificationId
      }
    case SAVE_IR_VALUE:
      return {
        ...state,
        IRMenuList: action.list,
        resultPowerDomainData: action.resultPowerDomainData
      }
    case GET_IR_VALUE_FILE:
      return {
        ...state,
        resultId: action.resultId,
        resultType: action.resultType,
        verificationId: action.verificationId
      }
    case GET_IR_INFO_SUCCESS:
      return {
        ...state,
        irData: action.irData
      }

    case UPDATE_IR_SELECTED_KEY:
      return {
        ...state,
        IRSelectedKey: action.IRSelectedKey
      }
    case UPDATE_IR_RESULT_TABLE_LOADING:
      return {
        ...state,
        IRResultTableLoading: action.loading
      }
    case CLEAR_IR_RESULT_DATA:
      return {
        ...defaultState
      }
    default: return state;
  }

};

