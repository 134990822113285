import React from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { CAP, IND, JUMPER, RES } from "../PCBHelper";
import { SHUNT_C, SHUNT_L, SHUNT_R } from "../VirtualComponent";

class RLCModelInfo {
  constructor({ modelType = 'value', value = { r: "0", l: "0", c: "0" }, passiveModel = null }) {
    this.type = modelType; // value/touchstone/spice
    this.value = { ...value };
    this.passiveModel = passiveModel || new PassiveModel({ type: modelType });
  }
}

function PassiveModel({ type = "", fileName = "", libraryId = "", subckt = "", pairs = [], referenceNet = "" }) {
  this.fileName = fileName;
  this.libraryId = libraryId;
  this.subckt = subckt;
  this.pairs = pairs; //[{ pin:"", node:"" }, ...]
  if (type.toUpperCase() === 'TOUCHSTONE') {
    this.referenceNet = referenceNet;
  }
}

function getRLCValue(type, value, isString = false) {
  switch (type) {
    case RES:
    case JUMPER:
    case SHUNT_R:
      return { r: value || "" };
    case IND:
    case SHUNT_L:
      return { l: value || "" };
    case CAP:
    case SHUNT_C:
      return isString ? { c: value || "" } : { ...value };
    default:
      return null;
  }
}

function getModelValue(type, value, subType) {
  switch (type) {
    case RES:
    case JUMPER:
    case SHUNT_R:
      return value.r;
    case IND:
    case SHUNT_L:
      return value.l;
    case CAP:
      if (subType === "virtualPassive") {
        return value.c;
      }
      return value;
    case SHUNT_C:
      return value.c;
    default:
      return value;
  }
}

function getModelTypeName(type = "") {
  switch (type.toUpperCase()) {
    case "VALUE":
      return "value";
    case "TOUCHSTONE":
      return "Touchstone";
    case "SPICE":
      return "SPICE";
    default:
      return type;
  }
}

const RLCTypeList = [
  { title: 'Value', key: "value" },
  { title: 'SPICE', key: 'spice' },
  { title: 'Touchstone', key: 'touchstone' }
];
const defaultRLCValue = {
  R: { value: '0', unit: 'Ω' },
  L: { value: '0', unit: 'n' },
  C: { value: '0.1', unit: 'n' }
};

function getRLCModelText(model, compType, passiveFileNotExist) {
  if (!model) {
    return "";
  }
  switch (model.type) {
    case "value":
      return getValue(compType, model.value);
    case "touchstone":
    case "spice":
      return getModel(model.passiveModel, passiveFileNotExist);
    default: return "";
  }
}

function getModel(passiveModel, passiveFileNotExist) {
  if (!passiveModel) {
    return "";
  }
  let text = "";
  if (passiveModel.folder) {
    text = `${passiveModel.folder}::`
  }

  if (passiveModel.fileName) {
    text += passiveModel.fileName;
  }

  if (passiveModel.subckt) {
    text += `::${passiveModel.subckt}`;
  }

  if (passiveFileNotExist) {
    return (
      <div className='aurora-file-check-div'>
        <span>{text}</span>
        <Tooltip
          title={`Files ${text} has been ${passiveFileNotExist}, the model is invalid.`}
          overlayClassName='aurora-tooltip'
        ><QuestionCircleOutlined className='aurora-file-check-icon' onClick={(e) => spanClick(e)} />
        </Tooltip>
      </div>
    );
  }

  return text;
}

function spanClick(e) {
  e && e.stopPropagation();
}

function getValue(compType, value) {
  switch (compType) {
    case RES:
      return value.r + "Ω";
    case IND:
      return value.l + "H";
    case CAP:
      return `R = ${value.r}Ω, L = ${value.l}H, C = ${value.c}F`;
    default: return "";
  }
}

function getRLCModel(record) {
  const { model = {}, value, type } = record;
  let _value = getRLCValue(type, value);
  const passiveModel = new PassiveModel({ ...model });
  const _model = new RLCModelInfo({
    modelType: model.type ? model.type.toLowerCase() : model.type,
    value: _value,
    passiveModel
  });
  return { ..._model };
}

function formatRLCValue(type, value, model, displayUnit = "") {
  if (!model || (model && model.type === 'value')) {
    if (type === CAP) {
      if (toString.call(value) === '[object Object]') {
        return `R = ${(value.r && (value.r + 'Ω')) || '0Ω'}, L = ${(value.l && (value.l + 'H')) || '0H'}, C = ${(value.c && (value.c + 'F')) || '0F'}`
      } else {
        return value ? `${value}${displayUnit}` : ""
      }
    } else {
      return toString.call(value) === '[object Object]' ? `${_getRLCValue(type, value)}${displayUnit}` : value ? `${value}${displayUnit}` : ""
    }
  } else {
    if (model && model.fileName) {
      return model.fileName || ""
    } else {
      return ''
    }
  }
}

function _getRLCValue(type, value) {
  if (type === RES) {
    return value.r || ""
  } else if (type === IND) {
    return value.l || ""
  }
  return ""
}

export {
  RLCModelInfo,
  RLCTypeList,
  defaultRLCValue,
  PassiveModel,
  getRLCModelText,
  getRLCValue,
  getModelValue,
  getModelTypeName,
  getRLCModel,
  formatRLCValue
}