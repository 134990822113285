import React, { Component, Fragment } from 'react';
import Table from '@/components/EditableTable';
import { changeEffectToTableData } from '@/services/Cascade/result';

const columns = [
  {
    title: 'Port',
    dataIndex: 'port',
    width: '10%',
  },
  {
    title: 'Power Pins',
    dataIndex: 'powerPins',
    width: '20%',
  },
  {
    title: 'Reference Pins',
    dataIndex: 'referencePins',
    width: '20%',
  },
];

const valueColumns = [{
  title: 'Resistance DC (mΩ)',
  dataIndex: 'res',
  width: '25%',
}, {
  title: 'Inductances (pH)',
  dataIndex: 'ind',
  width: '25%',
}]

class EffectTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
    this.columns = JSON.parse(JSON.stringify(columns));
  }

  componentDidMount() {
    this.setTableData()
  }

  componentDidUpdate(prevProps) {
    const { barList, ports, loading } = this.props;
    if (barList.length !== prevProps.barList.length || ports.length !== prevProps.ports.length
      || (!loading && loading !== prevProps.loading)) {
      this.setTableData()
    }
  }

  setTableData = () => {
    const { ports, barList } = this.props;
    const { childColumns, dataSource } = changeEffectToTableData(ports, barList);
    const _columns = JSON.parse(JSON.stringify(columns));

    _columns[0].sorter = (a, b) => { return Number(a.port) - Number(b.port) }

    _columns[1].render = (text, record) => {
      return <div className='cascade-result-effect-table-main-pins'>{text.join(', ')}</div>;
    }

    _columns[2].render = (text, record) => {
      return <div className='cascade-result-effect-table-main-pins'>{text.join(', ')}</div>;
    }

    const _valueColumns = JSON.parse(JSON.stringify(valueColumns));
    if (childColumns.length === 1) {
      const id = childColumns[0].id;
      _valueColumns.forEach(item => item.dataIndex = `${item.dataIndex}_${id}`);
    } else if (childColumns.length > 1) {
      const id = childColumns[0].id;
      _valueColumns.forEach(item => {
        item.children = childColumns.map(child => {
          const render = (text, record) => {
            const base = record[`${item.dataIndex}_${id}`];
            const perc = this.calcPerc(Number(text), Number(base));
            const percStyle = perc === 0 ? {} : perc < 0 ? { color: 'green' } : { color: 'red' }
            return child.id === id ? text : <div>{text} {!isNaN(perc) ? <span style={percStyle}>({perc}%)</span> : null}</div>
          }
          return {
            title: child.name,
            dataIndex: `${item.dataIndex}_${child.id}`,
            render: render
          }
        })
      })
    }
    _columns.push(..._valueColumns)
    this.columns = _columns;
    this.setState({
      dataSource
    })
  }

  calcPerc = (result, reference) => {
    if (isNaN(result) || isNaN(reference)) {
      return NaN
    }

    if (result < 0 && reference < 0) {
      return Number((-(Math.abs(result) - Math.abs(reference)) / Math.abs(reference) * 100).toFixed(2))
    } else {
      return Number(((result - reference) / reference * 100).toFixed(2))
    }
  }

  render() {
    const { dataSource } = this.state;
    const { shot } = this.props;
    return <div className="cascade-result-effect-table">
      <Table
        dataSource={dataSource}
        columns={this.columns}
        size="small"
        rowKey={(record) => record.port}
        scroll={!shot && dataSource.length > 13 ? { y: 483 } : {}}
        className="cascade-result-effect-table-main"
      />
    </div>
  }
}

export default EffectTable