import {
  getPdnLibraryList, createPdnLibraryList, delPdnLibraryList, updatePdnLibraryList,
  getPdnLibraryData, getVRMModelParsedBySPIM, getPDNVRMModelParsedBySPIM,
  decapParse, decapReCharacterization, _createPdnLibraryList, getDefault, setDefault,
  getDecapTouchstoneCurve, getPdnLibraryFile,
  getLibraryTouchstoneFile,
  PdnLibraryDownload, getTouchstoneFileNames, getPdnLibraryDecapFile, getSPIMLog
} from '../../api/pdn/library';
import { DECAP_PARSE } from '../../../version';
import apiProcess from '../../api/utility';

/**
 * Get pdn user library list
 * 2019/09/09
 * @export
 * @returns Promise
 */
export function getLibraryList() {
  return new Promise((resolve, reject) => {
    apiProcess(getPdnLibraryList).then(res => {
      resolve(res)
    }, error => {
      console.error(error)
      resolve({})
    })
  })
}

/**
 * Create pdn user library list
 * 2019/09/09
 * @export
 * @param {String} data RLC data
 * @param {file} file  upload file
 * @param {String} id id 
 * @param {String} typeName /Inter_SPIM/VRM/Decap
 * @param {String} name  name
 * @returns Promise
 */
export function _createLibraryList({ typeName, files, libraries, libraryFormat }) {
  return new Promise((resolve, reject) => {
    apiProcess(_createPdnLibraryList, { typeName, files, libraries, libraryFormat }).then(res => {
      resolve(res)
    }, error => {
      console.error(error)
      resolve(null)
    })
  })
}

export function createLibraryList(formData, config) {
  return apiProcess(createPdnLibraryList, { formData, config })
}

/**
 * delete pdn user library list
 * 2019/09/09
 * @export
 *  @param userLibraryIds:array [] String   delete library id list
 * @returns Promise
 */
export function delLibraryList(userLibraryIds) {
  return apiProcess(delPdnLibraryList, userLibraryIds)
}

/**
 * Update pdn user library list
 * 2019/09/09
 * @export
 * @param {String} data RLC data
 * @param {file} file  upload file
 * @param {String} id id 
 * @param {String} typeName /Inter_SPIM/VRM/Decap
 * @param {String} name  name
 * @returns Promise
 */
export function updateLibraryList({ typeName, file, libraries, libraryFormat }) {
  return apiProcess(updatePdnLibraryList, { typeName, file, libraries, libraryFormat })
}

/**
 * get pdn user library data
 * 2019/09/10
 * @export
 * @param libraryId:string  user library id 
 * @returns Promise
 */
export function getLibraryData(libraryId) {
  return apiProcess(getPdnLibraryData, libraryId)
}

/**
 * get pdn user library flie, response: file
 * 2019/09/10
 * @export
 * @param libraryId: user library id
 * @returns Promise
 */
export function getLibraryFile(libraryId) {
  return apiProcess(getPdnLibraryFile, libraryId, false, false, true)
}

/**
 * Get VRM model parsed by SPMI power domain
 * {
 *    id - SPIM,
 *    powerDomainName,
 *    spimName,
 *    userLibraryId - VRM Id
 * }
 * @export
 * @param {String} powerdomain
 * @param {String} SPIM
 * @returns
 */
export function getVRMModel(powerdomain, SPIM) {
  return apiProcess(getVRMModelParsedBySPIM, { powerDomainName: powerdomain, spimName: SPIM })
};

/**
 * Get decap response workflow
 *
 * @export
 * @param {String} libraryId PDN lirary Id
 * @returns Promise
 */
export function getDecapResponse(libraryId) {
  return apiProcess(decapParse, { libraryId, parserVersion: DECAP_PARSE })
};

/**
 * Get decap response workflow
 *
 * @export
 * @param {String} libraryId PDN lirary Id
 * @returns Promise
 */
export function reDecapResponse(libraryId) {
  return apiProcess(decapReCharacterization, libraryId)
};

/**
 * Get default decap
 * @export
 * @returns Promis
 */
export function getDefaultDecap() {
  return apiProcess(getDefault)
};

/**
 * Get default decap
 * @export
 * @returns Promis
 */
export function setDefaultDecap(libraryId) {
  return apiProcess(setDefault, { libraryId, typeName: 'decap' })
};

export function getDecapTouchstoneCurvePromise(params) {
  return apiProcess(getDecapTouchstoneCurve, params)
}

export function getLibraryTouchstoneFilePromise(libraryId, path) {
  return apiProcess(getLibraryTouchstoneFile, { libraryId, path })
}

export function getListVRMModel(powerDomainName, libraryId) {
  return apiProcess(getPDNVRMModelParsedBySPIM, { powerDomainName, libraryId })
};

/**
 * get pdn user library flie, response: file
 * 2021/10/28
 * @export
 * @param libraryId: user library id
 * @returns Promise
 */
export function getLibraryDownload(libraryId) {
  return new Promise((resolve, reject) => {
    PdnLibraryDownload(libraryId).then(res => {
      if (res.data) {
        resolve(res.data)
      } else {
        resolve(null)
      }
    }, error => {
      console.log(error)
      resolve(null)
    })
  })
}

export function getTouchstoneFileNameList() {
  return apiProcess(getTouchstoneFileNames)
}

export function getLibraryDecapFile(libraryId, fileName) {
  return new Promise((resolve, reject) => {
    getPdnLibraryDecapFile({ libraryId, fileName }).then(res => {
      if (res.data) {
        resolve(res.data)
      } else {
        resolve(null)
      }
    }, error => {
      console.log(error)
      resolve(null)
    })
  })
}

export function getNewSPIMLog(libraryId) {
  return apiProcess(getSPIMLog, libraryId)
}