import React from 'react';
import {
  CloseOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import { Tooltip, Select, Checkbox } from 'antd';
import { UNIT } from '../data/newStackupData';
import { METAL } from './stackupConstants';
import { roughnessTypeList, ROUGHNESS_NONE } from './Roughness';
import { ANDES_V2, CASCADE, FASTPI, ROCKY, SIERRA } from '../../constants/pageType';
import { getTextWidth } from '../helper/getTextWidth';
import NP from 'number-precision';
import { MIU_R } from './Material';

const Option = Select.Option;
const THICKNESS = "thickness";

const INDEXSTACKUP = 'indexStackup', NAME = 'name', TYPE = 'type',
  MATERIAL = 'material', ER = 'Er', LOSSTANGENT = 'LossTangent',
  CONDUCTIVITY = 'Conductivity', ROUGHNESS = 'roughness',
  ETCHFACTOR = 'etch_factor', LAMINATED = "Laminated",
  FILL_MATERIAL = "fill_material";
function getColumnsList(type, isMultiZones, isLaminated, stackupTypes) {
  switch (type) {
    case ROCKY:
      return [INDEXSTACKUP, NAME, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL,/* ER, LOSSTANGENT, CONDUCTIVITY, */ ETCHFACTOR, ROUGHNESS];
    case FASTPI:
      return [INDEXSTACKUP, NAME, TYPE, THICKNESS, MATERIAL, ER, LOSSTANGENT, CONDUCTIVITY, ROUGHNESS];
    case CASCADE:
      if (isMultiZones) {
        return isLaminated
          ? [INDEXSTACKUP, NAME, LAMINATED, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL, ETCHFACTOR, ROUGHNESS]
          : [INDEXSTACKUP, NAME, ...stackupTypes, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL, ETCHFACTOR, ROUGHNESS];
      }
      return [INDEXSTACKUP, NAME, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL, ETCHFACTOR, ROUGHNESS];
    case ANDES_V2:
    case SIERRA:
      if (isMultiZones) {
        return isLaminated
          ? [INDEXSTACKUP, NAME, LAMINATED, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL, ROUGHNESS]
          : [INDEXSTACKUP, NAME, ...stackupTypes, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL, ROUGHNESS];
      }
      return [INDEXSTACKUP, NAME, TYPE, THICKNESS, MATERIAL, FILL_MATERIAL, ROUGHNESS];
    default:
      return [INDEXSTACKUP, NAME, TYPE, THICKNESS, MATERIAL,/*  ER, LOSSTANGENT, CONDUCTIVITY, */ ROUGHNESS];
  }
}

function getStackupColumnsItem(vendor, stackupTypes) {
  switch (vendor) {
    case INDEXSTACKUP:
      return {
        title: 'Index',
        dataIndex: 'indexStackup',
        width: getTextWidth("Index", 14, 150) + 20,
      }
    case NAME:
      return {
        title: 'Name',
        dataIndex: 'name',
        width: getTextWidth("NameNameName", 14, 150) + 20
      }
    case TYPE:
      return {
        title: 'Type',
        dataIndex: 'type',
        width: getTextWidth("Dielectric", 14, 150) + 20,
      }
    case THICKNESS:
      return {
        title: 'Thickness',
        dataIndex: 'thickness',
        width: getTextWidth("ThicknessThickness", 14, 150) + 20
      }
    case MATERIAL:
      return {
        title: 'Material',
        dataIndex: 'material',
        width: getTextWidth("MaterialMaterialMaterialMaterialMaterial", 14, 150) + 20
      }
    case ER:
      return {
        title: 'εᵣ',
        width: getTextWidth("Er   ", 14, 150) + 20,
        dataIndex: 'Er'
      }
    case LOSSTANGENT:
      return {
        title: 'Loss Tangent',
        width: getTextWidth("Loss Tangent", 14, 150) + 20,
        dataIndex: 'LossTangent'
      }
    case CONDUCTIVITY:
      return {
        title: 'Conductivity',
        width: getTextWidth("Conductivity", 14, 150) + 20,
        dataIndex: 'Conductivity'
      }
    case ROUGHNESS:
      return {
        title: 'Surface Roughness',
        dataIndex: 'roughness',
        width: getTextWidth("Surface Roughness", 14, 150) + 20
      }
    case ETCHFACTOR:
      return {
        title: 'Etch Factor',
        dataIndex: 'etch_factor',
        width: getTextWidth("Etch FactorFactor", 14, 150) + 20
      }
    case LAMINATED:
      return {
        title: 'Laminated',
        titleText: "Laminated",
        dataIndex: LAMINATED,
        width: getTextWidth("Laminated", 14, 150) + 20
      }
    case FILL_MATERIAL:
      return {
        title: 'Fill Material',
        titleText: "fill_material",
        dataIndex: FILL_MATERIAL,
        width: getTextWidth("Fill MaterialFill MaterialFill Material", 14, 150) + 20
      }
    default:
      break;
  }

  if (stackupTypes && stackupTypes.length) {
    const findItem = stackupTypes.find(item => item === vendor);
    if (findItem) {
      return {
        title: vendor,
        titleText: vendor,
        key: vendor,
        dataIndex: `${vendor}::STACKUP_NAME`,
        width: getTextWidth(vendor, 14, 150) + 20
      }
    }
  }
  return;
}

function getStackupColumnsList(type, isMultiZones, isLaminated, stackupTypes) {
  let stackupColumnsList = getColumnsList(type, isMultiZones, isLaminated, stackupTypes).map(vendor => {
    return getStackupColumnsItem(vendor, stackupTypes)
  })
  return stackupColumnsList;
}

function getStackupColumns({
  StackupColumns,
  unit,
  materialList,
  settingUnitChange,
  updateDielectric,
  saveData,
  _saveMaterial,
  MaterialPanel,
  SurfaceRoughnessPanel,
  _saveRoughness,
  openMaterialMenu,
  pageType,
  stackupEtchFactor,
  changeZoneType,
  checkLaminated,
  /*   multiZoneCheckObj,
    checkAllZoneType */
}) {

  StackupColumns[0].render = (data, record) => {
    return indexStackup({
      data,
      record,
      updateDielectric
    });
  }

  StackupColumns[1].onCell = (record) => {
    return {
      record,
      edit: (record.type === 'Dielectric') ? true : false,
      title: 'Name',
      dataIndex: 'name',
      handleSave: (row) => saveData(row, 'name')
    }
  }

  StackupColumns[1].render = (text, record) => {
    return <div
      title={text}
      className={record.type === 'Metal' ? "stackup-name-td" : ''}
    >{text}</div>
  }

  const materialFn = { MaterialPanel, materialList, _saveMaterial };

  for (let column of StackupColumns) {

    if (column.dataIndex === LAMINATED) {
      column.render = (text, record) => {
        const obj = {
          children: <div className='multi-zone-stackup-checkbox-content'>
            <Checkbox
              onChange={e => checkLaminated(e.target.checked, record)}
              checked={record.laminated || false}
              disabled={record.type === METAL}
            />
          </div>,
          props: {
            tdClassName: `multi-zone-stackup-checkbox-content-stackup-name-td`
          }
        }
        return obj;
      }
    }

    if (column.dataIndex.match("::STACKUP_NAME")) {
      column.render = (text, record) => {
        const obj = {
          children: <div className='multi-zone-stackup-checkbox-content'>
            <Checkbox
              onChange={e => changeZoneType(e, column.key, record)}
              checked={(record.stackupLayerTypes || []).includes(column.key)}
            />
          </div>,
          props: {
            tdClassName: `multi-zone-stackup-checkbox-content-stackup-name-td`
          }
        }
        return obj;
      }

      column.title = () => {
        return <div
        >
          {/* <Checkbox
            onChange={e => checkAllZoneType(column.dataIndex)}
            checked={multiZoneCheckObj[column.dataIndex]}
          /> */}
          {column.titleText}
        </div>;
      }
    }

    if (column.dataIndex === "thickness") {
      column.title = () => {
        return <span>
          Thickness <Select
            value={unit}
            onChange={settingUnitChange}
            className='stackup-unit-selection'
            popupClassName="stackup-unit-option"
          >
            {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
          </Select>
        </span>
      }

      column.onCell = (record) => {
        return {
          record,
          edit: true,
          title: 'Thickness',
          dataIndex: 'thickness',
          handleSave: (row) => saveData(row, 'thickness')
        }
      }
    }

    if (column.dataIndex === "material") {
      column.onCell = (record) => {
        return getMaterialTable(record, record.material, materialFn, column.dataIndex, pageType)
      }

      column.title = () => {
        return (
          <span className="stackup-material-title">
            Material <EyeOutlined
              title="Open material menu"
              className='open-material-icon'
              onClick={(e) => openMaterialMenu(e)} />
          </span>
        );
      }
      column.render = (text, record) => {
        let _text = record.material;
        if (record.type === "Metal" && record.material) {
          _text = `${record.material} (σ=${record.Conductivity})`;
        }
        if (record.type === 'Dielectric' && record.material) {
          _text = `${record.material} (εᵣ=${record.Er}, δ=${record.LossTangent})`;
        }
        return <span title={_text}>{_text}</span>
      }
    }

    if (column.dataIndex === FILL_MATERIAL) {
      column.onCell = (record) => {
        if (record.type === METAL) {
          return getMaterialTable(record, record.fill_material, materialFn, column.dataIndex)
        } else {
          return { edit: false }
        }
      }

      column.render = (text, record) => {
        if (record.type === 'Dielectric') {
          return null;
        }
        let _text = record.fill_material;
        if (record.type === "Metal" && record.fill_material && record.fillMaterialInfo && Object.keys(record.fillMaterialInfo).length) {
          _text = `${record.fill_material} (εᵣ=${record.fillMaterialInfo.Er}, δ=${record.fillMaterialInfo.LossTangent})`;
        }

        return <span title={_text}>{_text}</span>
      }
    }

    if (column.dataIndex === "Er") {
      column.title = () => {
        return <span style={{ fontSize: 16 }}>εᵣ</span>
      }

      column.onCell = (record) => {
        return record.Er ? getMaterialTable(record, record.Er, materialFn) : {
          edit: false
        }
      }
    }

    if (column.dataIndex === "LossTangent") {
      column.onCell = (record) => {
        return record.LossTangent ? getMaterialTable(record, record.LossTangent, materialFn) : {
          edit: false
        }
      }
    }

    if (column.dataIndex === "Conductivity") {
      column.onCell = (record) => {
        return record.Conductivity ? getMaterialTable(record, record.Conductivity, materialFn) : {
          edit: false
        }
      }
    }

    if ((pageType === ROCKY || pageType === CASCADE) && column.dataIndex === "etch_factor") {
      column.title = () => {
        return (
          <span className="stackup-material-title">
            Etch Factor <QuestionCircleOutlined className='etch-help-icon' onClick={(e) => { stackupEtchFactor(e) }} />
          </span>
        );
      }

      column.onCell = (record) => {
        return record.type === 'Metal' ? {
          record,
          edit: true,
          // title: 'Etch Factor',
          dataIndex: 'etch_factor',
          handleSave: (row) => saveData(row, 'etch_factor')
        } : { edit: false }
      }
    }

    if (column.dataIndex === "roughness") {
      column.onCell = (record) => {
        return {
          record,
          edit: record.type === METAL ? true : false,
          customInput: SurfaceRoughnessPanel,
          title: 'Surface Roughness',
          dataIndex: 'roughness',
          closePanel: _saveRoughness,
          text: roughnessDisplay(record)
        }
      }

      column.render = (data, record) => {
        if (record.type === METAL) {
          return roughnessDisplay(record);
        }
      }
    }
  }
  return StackupColumns;
}

function getMaterialTable(record, text, { MaterialPanel, materialList, _saveMaterial }, dataIndex, pageType) {
  return {
    record,
    edit: true,
    customInput: MaterialPanel,
    materialList,
    dataIndex: dataIndex || MATERIAL,
    editType: "table",
    text: text,
    closePanel: _saveMaterial,
    pageType
  }
}

function roughnessDisplay(record) {
  const { roughness, bot_roughness, side_roughness } = record;
  let roughnessType = roughness ? roughness.type : ROUGHNESS_NONE;
  let botType = bot_roughness ? bot_roughness.type : roughnessType,
    sideType = side_roughness ? side_roughness.type : roughnessType;

  const top = roughnessTypeList.find(item => item.key === roughnessType);
  const bot = roughnessTypeList.find(item => item.key === botType);
  const side = roughnessTypeList.find(item => item.key === sideType);
  const text = ` Top: ${top ? top.name : "None"}, Bottom: ${bot ? bot.name : "None"}, Side: ${side ? side.name : "None"}`;
  return <span title={text}>{text}</span>
}

function indexStackup({ data, record, updateDielectric }) {
  const edit = <div className="stackup-table-edit">
    <div onClick={() => updateDielectric(record, 'add', 0)}>
      <Tooltip title="Add a dielectric layer above" placement="top" overlayClassName="aurora-tooltip">
        <VerticalAlignTopOutlined />
      </Tooltip>
    </div>
    {record.type === 'Dielectric' && <div onClick={() => updateDielectric(record, 'delete', 0)}>
      <Tooltip title={`Delete the dielectric layer ${record.name}`} placement="top" overlayClassName="aurora-tooltip">
        <CloseOutlined />
      </Tooltip>
    </div>}
    <div onClick={() => updateDielectric(record, 'add', 1)}>
      <Tooltip title="Add a dielectric layer blow" placement="top" overlayClassName="aurora-tooltip">
        <VerticalAlignBottomOutlined />
      </Tooltip>
    </div>
  </div>
  return <Tooltip
    title={edit}
    trigger='hover'
    placement="left"
    overlayClassName="stackup-table-edit-tooltip aurora-tooltip">
    <div>{data + 1}</div>
  </Tooltip>
}

function getStackupData(data, materialList) {
  return data.map(item => {
    const current = materialList.find(m => m.name === item.material);
    if (current) {
      if (current.type === METAL) {
        const currentFill = materialList.find(m => m.name === item.fill_material) || {};
        return {
          ...item,
          Mr: current[MIU_R] || '1',
          Conductivity: current.sigma,
          fillMaterialInfo: {
            Er: currentFill['eps_r'],
            LossTangent: currentFill.delta
          }
        }
      } else {
        return { ...item, Er: current['eps_r'], LossTangent: current.delta }
      }
    }
    return item
  })
}

function getThicknessScale(changedUnit, prevUnit) {
  const scaleObj = {
    mil: {
      mm: 0.0254,
      um: 25.4
    },
    mm: {
      mil: 1 / 0.0254,
      um: 1e3
    },
    um: {
      mil: 1 / 25.4,
      mm: 1 / 1e3
    }
  };
  if (scaleObj[prevUnit] && scaleObj[prevUnit][changedUnit]) {
    return scaleObj[prevUnit][changedUnit];
  }
  return 1;
}

function updateThicknessByUnit(scale, thickness) {
  const newThickness = NP.times(scale, thickness);
  let _thickness = newThickness;
  const strThickness = _thickness.toString();
  let number = _thickness;
  if (strThickness.indexOf('e') > -1) {
    let [num, digit] = strThickness.split('e');
    number = NP.strip(NP.times(Number(num), Math.pow(10, (parseInt(digit)))));
  } else {
    number = NP.strip(NP.times(Number(number), Math.pow(10, 0)));
  }
  let index = 0;
  if (number.toString().indexOf('.') > -1) {
    index = number.toString().substring(number.toString().indexOf('.') + 1).length;
  }
  if (index > 9 && _thickness >= 1e-9) {
    _thickness = NP.strip(Number(newThickness.toFixed(10)))
    const _index = _thickness.toString().substring(_thickness.toString().indexOf('.') + 1).length;
    if (_index > 9) {
      _thickness = Number(_thickness.toString().substring(0, _thickness.toString().length - 1))
    }
  }
  if (_thickness < 1e-9) {
    _thickness = _thickness.toPrecision(9)
  }
  return _thickness
}

export {
  indexStackup,
  getStackupColumns,
  THICKNESS,
  getStackupData,
  getStackupColumnsList,
  ETCHFACTOR,
  FILL_MATERIAL,
  updateThicknessByUnit,
  getThicknessScale
}