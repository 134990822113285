import apiProcess from "../../api/utility";
import { uploadChunk, getUploadStatus } from '../../api/chunksFile'

function uploadChunkPromise(formData) {
  return apiProcess(uploadChunk, formData)
}

function getUploadStatusPromise(id) {
  return apiProcess(getUploadStatus, id)
}

// function deleteBeforeUploadPromise() {
  
// }

export {
  uploadChunkPromise,
  getUploadStatusPromise,
  // deleteBeforeUploadPromise
}