import NetsCanvas from '../LayoutCanvas/NetsCanvas';
import { LayoutLine } from '../helper/cutDesign/hybrid/hybridLines';
import { calcParallelLine, getDisjoint, getLinesIntersectPoint, calcLinePointByDistance, calcIntersectByArcAndLine, calculateArcEndpoint } from './mathHelper';
import { getDefaultIndex, getDefaultName } from '../helper/setDefaultName';
import { N_PORT, VcCompPin, VirtualComponentItem, VC_SHUNT_TYPES, VC_RLC_TYPES, getShuntRLCType } from './virtualCompHelper';
import { RES, RLC_TYPES } from '../PCBHelper';
import _ from 'lodash';
import { unitChange } from '../helper/mathHelper';
import { _maxPrefix } from '../helper/split';
import { SIERRA } from '../../constants/pageType';

class VCCanvas {
  constructor() {
    this.editInfo = {};
    this.netsCanvas = null;
    this.vcComponents = [];//canvas
    this.errors = [];
    this.virtualComps = []; //json (target)
    this.layoutCompNames = [];
    this.viewVirtualComps = [];
    this.openedVirtualComps = [];
    this.VC_COMP_TYPES = [N_PORT, ...RLC_TYPES];
  }

  initLayout = ({ svg, layoutDB, locationSvg, showPinList, events, product, getOtherVirtualComps }) => {
    this.editInfo = {}
    this.product = product;
    this.layoutDB = layoutDB;
    this.VC_COMP_TYPES = this.product === SIERRA ? [...VC_RLC_TYPES] : [N_PORT, ...RLC_TYPES]
    this.netsCanvas = new NetsCanvas(this.VC_COMP_TYPES);
    this.netsCanvas.initLayout(svg, layoutDB, locationSvg, showPinList, events);
    this.updateVcComponents = events.updateVcComponents;
    this.updateCanvasViewVCompsToTable = events.updateCanvasViewVCompsToTable;
    this.showVcErrors = events.showVcErrors;
    this.vcComponents = [];
    this.errors = [];
    this.virtualComps = [];
    this.layoutCompNames = [];
    this.viewVirtualComps = [];
    this.openedVirtualComps = [];
    this.getOtherVirtualComps = getOtherVirtualComps;
  }

  updateLayers = (shownLayers, hiddenLayers) => {
    if (this.netsCanvas) {
      this.netsCanvas.updateLayers(shownLayers, hiddenLayers)
    }
  }

  selectNetCompPin = ({ pinList = [] }) => {
    if (this.netsCanvas) {
      let pins = {};
      for (let pin of pinList) {
        if (pins[pin.name]) {
          pins[pin.name].push(pin.pin)
        } else {
          pins[pin.name] = [pin.pin]
        }
      }
      this.netsCanvas.selectNetCompPin({ pins })
    }
  }

  showNetsAndCompsName = (nets, comps, designId) => {
    if (this.netsCanvas) {
      this.netsCanvas.showNetsAndCompsName(nets, comps, designId)
    }
  }

  removeNetsAndCompsName = () => {
    if (this.netsCanvas) {
      this.netsCanvas.removeNetsAndCompsName()
    }
  }

  initSignalsInfo = ({ DesignData, signals = [], layoutCompNames = [] }) => {
    this.signals = signals;
    this.signalNets = {};
    this.DesignData = DesignData;
    this.layoutCompNames = layoutCompNames;
    this.layoutUnit = this.DesignData.GetLayoutUnits();

    if (this.layoutUnit === "mils") {
      this.layoutUnit = "mil";
    }
    const netManager = DesignData.mNetManager;
    for (let signal of signals) {
      for (let net of signal.nets || []) {
        //GetNetFromName
        const netInfo = netManager.GetNetFromName(net);
        //GetNetRefGeom
        const geomList = netInfo.GetNetGeomList();
        let newGeomList = [];
        for (let geom of geomList) {
          const refGeom = geom.GetNetRefGeom()
          if (refGeom && refGeom.mGeometry && ["Line", "Larc"].includes(refGeom.mGeometry.mGeomType)) {
            newGeomList.push({ geometry: refGeom.mGeometry, layerID: geom.mLayerID })
          }
        }
        this.signalNets[net] = {
          signal: signal.name,
          geomList: newGeomList
        }
      }
    }
  }

  createDividingLine = () => {
    const clickMousePosition = this.netsCanvas.getClickMousePosition();

    if (!clickMousePosition || !Object.keys(clickMousePosition).length) {
      return;
    }

    if (this.editInfo.startLine && this.editInfo.startLine.getPoints().length >= 2) {
      return;
    }

    if (!this.editInfo.startLine) {
      this.initEditInfo()
    }

    this.editInfo.startLine.addPoint(clickMousePosition);
    this.updateLineCanvas(true)
    this.netsCanvas.clearClickMousePosition();
  }

  initEditInfo = () => {
    const names = this.virtualComps.map(item => item.name);
    this.editInfo.startLine = new LayoutLine("startLine");
    this.editInfo.name = this.product === SIERRA ? "UVC" : getDefaultName({ nameList: [...names, ...this.layoutCompNames], defaultKey: "UVC", key: "", firstIndex: 1 });
    this.editInfo.type = this.product === SIERRA ? RES : "N-Port";
    this.editInfo.cutLength = this.getCutLength(0.2, "mil");
    this.editInfo.unit = this.layoutUnit;
  }

  initVcComps = ({ vcComps }) => {
    let index = 0, existResCompLocations = [];
    const _vcComps = JSON.parse(JSON.stringify(vcComps));
    for (let comp of _vcComps) {
      if (comp.type === N_PORT) {
        const pins = this.getPinIntersectNet(comp.pins, comp.location)
        const initVCComponent = new InitVCComponent({
          ...comp,
          pins,
          index: index.toString()
        })
        this.vcComponents.push(initVCComponent);
        index += 1;
      } else if (VC_RLC_TYPES.includes(comp.type) && !existResCompLocations.find(item => _.isEqual(item, comp.location))) {
        const findComps = _vcComps.filter(item => _.isEqual(item.location, comp.location));
        const pins = findComps.map(item => item.pins).flat(2);
        existResCompLocations.push(comp.location);
        const _pins = this.getPinIntersectNet(pins, comp.location)
        const compName = _maxPrefix(findComps.map(item => item.name));
        const initVCComponent = new InitVCComponent({
          ...comp,
          name: compName || comp.name,
          pins: _pins,
          index: index.toString()
        });
        this.vcComponents.push(initVCComponent);
        index += 1;
      }
    }
    this.virtualComps = JSON.parse(JSON.stringify(vcComps));
    this.openedVirtualComps = [];
    this.viewVirtualComps.push(...vcComps.map(item => item.name));
    this.updateCanvasViewVCompsToTable({
      openedVirtualComps: this.openedVirtualComps,
      viewVirtualComps: [...this.viewVirtualComps]
    });
    this.updateVCComponentCanvas(this.vcComponents, false);
  }

  getPinIntersectNet = (pins, locations) => {
    for (let pin of pins) {
      if (pin.pin.match(/_2$/)) {
        continue;
      }
      const { intersectionNet } = this.getIntersectNet([pin.net], JSON.parse(JSON.stringify(locations)))
      pin.intersectionNet = intersectionNet
    }
    return pins;
  }

  updateLineCanvas = (isShowEditPanel) => {
    if (this.netsCanvas) {
      this.netsCanvas.updateLineCanvas({
        points: this.editInfo.startLine.getPoints(),
        lineName: this.editInfo.startLine.name,
        isVirtualComp: true,
        editingInfo: {
          name: this.editInfo.name,
          type: this.editInfo.type,
          cutLength: this.editInfo.cutLength,
          unit: this.editInfo.unit
        }
      }, {
        isShowEditPanel,
        saveLine: this.saveLine,
        deleteLine: this.deleteLine,
        updateCompType: this.updateCompType,
        updateCompName: this.updateCompName,
        updateCutLength: this.updateCutLength,
        removePoint: this.removePoint,
        getCompNames: this.getCompNames,
        showEditPanel: this.showEditPanel
      });

      if (isShowEditPanel && this.openedVirtualComps && this.openedVirtualComps.length) {
        //remove other opened editing panel
        const locations = this.virtualComps.filter(item => this.openedVirtualComps.includes(item.name));
        this.closeVCCompsPanel([...this.vcComponents.filter(item => !!locations.find(it => _.isEqual(it.location, item.location)))]);
      }
    }
  }

  removePoint = (point, lineName) => {
    //remove only one point line
    this.editInfo = {}
    this.netsCanvas && this.netsCanvas.removeAllPointsByName(lineName);
  }

  getCutLength = (cutLength, unit) => {
    if (unit === this.layoutUnit) {
      return cutLength;
    }
    const { number } = unitChange({ num: parseFloat(cutLength), oldUnit: unit, newUnit: this.layoutUnit, decimals: 12 });
    return parseFloat(number);
  }

  saveLine = (lineName) => {
    const location = this.editInfo.startLine.getPoints().map(item => [item.x, item.y]);/* this.calcLocation(this.editInfo.startLine.getPoints(), this.editInfo.cutLength) */;

    const index = getDefaultIndex(this.vcComponents.length, this.vcComponents.map(item => item.index));
    const cutLength = this.getCutLength(this.editInfo.cutLength, this.editInfo.unit);
    const pins = this.createPins(location, cutLength, this.editInfo.type);
    if (!pins.length) {
      if (this.netsCanvas) {
        this.netsCanvas.removeAllPointsByName(lineName)
      }
      this.showVcErrors(`Failed to create component "${this.editInfo.name}" due to miscalculation of location of pins.`)
      this.editInfo = {};
      return;
    }
    const layers = [...new Set(pins.map(item => item.layer))];
    if (layers.length > 1) {
      this.showVcErrors(`Failed to create component "${this.editInfo.name}" because the layers of pins are not in the same layer.`)
      if (this.netsCanvas) {
        this.netsCanvas.removeAllPointsByName(lineName)
      }
      this.editInfo = {};
      return;
    }
    const initVCComponent = new InitVCComponent({
      index,
      name: this.editInfo.name,
      type: this.editInfo.type,
      cutLength: this.editInfo.cutLength,
      unit: this.editInfo.unit,
      location,
      pins,
      layer: layers[0],
      errors: [...this.errors],
      layoutUnit: this.layoutUnit
    })

    this.errors = [];
    //remove other opened editing panel
    const locations = this.virtualComps.filter(item => this.openedVirtualComps.includes(item.name));
    this.closeVCCompsPanel([...this.vcComponents.filter(item => !!locations.find(it => _.isEqual(it.location, item.location)))]);

    this.vcComponents.push(initVCComponent);
    this.editInfo = {};
    this.updateVCComponentCanvas([initVCComponent], true)
    this.addNewVvComp(initVCComponent)
    if (this.netsCanvas) {
      this.netsCanvas.removeAllPointsByName(lineName)
    }
  }

  calcLocation = (points, cutLength) => {
    const point1 = points[0], point2 = points[1];
    const { _point1, _point2 } = calcParallelLine([point1.x, point1.y], [point2.x, point2.y], cutLength)
    const locations = [point1, point2, { x: _point2[0], y: _point2[1] }, { x: _point1[0], y: _point1[1] }];
    return locations
  }

  updateVCComponentCanvas = (vcComponents, isShowEditPanel) => {
    if (this.netsCanvas) {
      for (let vcComponent of vcComponents) {
        this.netsCanvas.showRectangleByLocation({
          locations: vcComponent.location,
          editingInfo: { ...vcComponent },
          events: {
            isShowEditPanel,
            deletePolygon: this.deleteVCComp,
            closePolygon: (vcIndex) => this.closeVCCompPanel(vcIndex),
            updateCompName: (value) => this.updateVCCompInfo(value, "name", vcComponent),
            updateCompType: (value) => this.updateVCCompInfo(value, "type", vcComponent),
            updateCutLength: (value, type) => this.updateVCCompInfo(value, "cutLength", vcComponent, type),
            getCompNames: this.getCompNames,
            showEditPanel: this.showEditPanel
          }
        });
      }
      if (isShowEditPanel && this.editInfo.startLine && this.editInfo.startLine.name) {
        this.deleteLine(this.editInfo.startLine.name)
      }
    }
  }

  closeVCCompsPanel = (vcComponents) => {
    this.updateVCComponentCanvas(vcComponents);
    for (let comp of vcComponents) {
      this.removeShowCompsPanel(comp.index)
    }
  }

  closeVCCompPanel = (vcIndex) => {
    const vcComponent = this.vcComponents.find(item => item.index === vcIndex);
    this.updateVCComponentCanvas([vcComponent]);
    this.removeShowCompsPanel(vcIndex)
  }

  showEditPanel = (vcIndex, type) => {
    const prevShowList = this.virtualComps.filter(item => this.openedVirtualComps.includes(item.name));
    const prevShowVcComponents = this.vcComponents.filter(item => !!prevShowList.find(it => _.isEqual(it.location, item.location)));
    if (type === "line") {
      this.updateLineCanvas(true)
      this.closeVCCompsPanel(prevShowVcComponents)
    } else {
      const vcComponent = this.vcComponents.find(item => item.index === vcIndex);
      if (!vcComponent) {
        return;
      }
      this.closeVCCompsPanel(prevShowVcComponents);
      this.updateVCComponentCanvas([vcComponent], true)
      const showVCs = this.virtualComps.filter(item => _.isEqual(item.location, vcComponent.location)).map(item => item.name);
      this.openedVirtualComps = [...showVCs];
      this.viewVirtualComps.push(...showVCs);
      this.updateCanvasViewVCompsToTable({
        openedVirtualComps: showVCs,
        viewVirtualComps: [...this.viewVirtualComps]
      });
    }
  }

  deleteVCComp = (vcIndex) => {
    this.removeVCComponentCanvas(vcIndex);
    this.delVcComp(vcIndex)
  }

  delVcComp = (vcIndex) => {
    let hideVCs = [];
    const index = this.vcComponents.findIndex(item => item.index === vcIndex);
    if (index < 0) {
      return;
    }
    if (this.vcComponents[index].type === N_PORT) {
      const name = this.vcComponents[index].name;
      this.virtualComps = this.virtualComps.filter(item => item.name !== name);
      hideVCs.push(name);
    }

    if (VC_RLC_TYPES.includes(this.vcComponents[index].type)) {
      hideVCs = this.virtualComps.filter(item => _.isEqual(item.location, this.vcComponents[index].location)).map(item => item.name);
      this.virtualComps = this.virtualComps.filter(item => !_.isEqual(item.location, this.vcComponents[index].location));
    }

    this.vcComponents.splice(index, 1);
    this.updateVcComponents(this.virtualComps);
    //remove line and panel state
    this.viewVirtualComps = this.viewVirtualComps.filter(item => !hideVCs.includes(item));
    this.openedVirtualComps = this.openedVirtualComps.filter(item => !hideVCs.includes(item));

    this.updateCanvasViewVCompsToTable({
      viewVirtualComps: [...this.viewVirtualComps],
      openedVirtualComps: [...this.openedVirtualComps]
    });
  }

  addNewVvComp = (comp) => {
    let showVCs = [];
    let names = this.getCompNames();
    if (comp.type === N_PORT) {
      let name = comp.name;
      if (names.includes(name)) {
        name = getDefaultName({ nameList: names, defaultKey: comp.name, key: "", firstIndex: 1 });
      }
      this.virtualComps.push({ ...new VirtualComponentItem({ ...comp, name, pins: comp.pins.map(item => new VcCompPin({ ...item })) }), errors: [...comp.errors || []] })
      showVCs.push(comp.name)
    }
    if (VC_RLC_TYPES.includes(comp.type)) {
      for (let signal of this.signals) {
        const filterPins = comp.pins.filter(item => this.signalNets[item.net] && this.signalNets[item.net].signal === signal.name);
        if (!filterPins.length) {
          continue;
        }
        const name = getDefaultName({ nameList: names, defaultKey: comp.name, key: "", firstIndex: 1 });
        names.push(name);
        this.virtualComps.push({ ...new VirtualComponentItem({ ...comp, name, pins: filterPins.map(item => new VcCompPin({ ...item })) }), errors: [...comp.errors || []] })
        showVCs.push(name);
      }
    }

    this.viewVirtualComps = [...this.viewVirtualComps, ...showVCs];
    this.openedVirtualComps = [...showVCs];

    this.updateCanvasViewVCompsToTable({
      viewVirtualComps: [...this.viewVirtualComps],
      openedVirtualComps: [...this.openedVirtualComps]
    });

    this.updateVcComponents(this.virtualComps);
  }

  updateVCCompInfo = (value, key, vcComp, type) => {
    const index = this.vcComponents.findIndex(item => item.index === vcComp.index);
    if (index < 0) {
      return;
    }
    const prevType = this.vcComponents[index].type;
    if (type === "unit") {
      this.vcComponents[index].unit = value;
    } else {
      this.vcComponents[index][key] = value;
    }

    if (key === "type") {
      //shunt -> series, add pin 2
      if (VC_SHUNT_TYPES.includes(prevType) && !VC_SHUNT_TYPES.includes(value)) {
        this.updatePinsByType(index);
      }
      //series -> shunt, remove pin 2
      if (!VC_SHUNT_TYPES.includes(prevType) && VC_SHUNT_TYPES.includes(value)) {
        this.vcComponents[index].pins = (this.vcComponents[index].pins || []).filter(item => !!item.pin.match(/_1$/));
        for (let comp of this.virtualComps) {
          if (!_.isEqual(comp.location, this.vcComponents[index].location)) {
            continue;
          }
          comp.pins = (comp.pins || []).filter(item => !!item.pin.match(/_1$/));
        }
      }
    }

    if (key === "cutLength") {
      this.reCalcPinLocationAndUpdateComps(key, value, type, index);
    } else {
      //update virtual comps to table
      this.updateVirtualComps(key, value, type, index);
    }
  }

  updatePinsByType = (index) => {
    let newPins = []
    for (let findPin of this.vcComponents[index].pins) {
      if (findPin.pin.match(/_2$/) || !findPin.intersectionNet) {
        continue;
      }

      const netName = findPin.pin.replace(/_1$/, "");
      //intersectionNet
      //pin_right
      const endPoint = [findPin.intersectionNet.geometry.mEnd.mX, findPin.intersectionNet.geometry.mEnd.mY]
      const pinLocation2 = this.calcPinLocationByDistance({
        pinLocation1: findPin.location,
        endPoint,
        length: this.vcComponents[index].cutLength,
        geomType: findPin.intersectionNet.geometry.mGeomType,
        arc: findPin.intersectionNet.geometry
      });
      newPins.push(new VcPin({
        pin: `${netName}_2`,
        location: pinLocation2,
        net: findPin.net,
        layer: findPin.intersectionNet.layer,
        signal: findPin.signal,
        intersectionNet: findPin.intersectionNet
      }));
      const vIndex = this.virtualComps.findIndex(comp => _.isEqual(comp.location, this.vcComponents[index].location) && !!comp.pins.find(it => it.net === findPin.net));
      if (vIndex > -1) {
        this.virtualComps[vIndex].pins.push(new VcCompPin({
          pin: `${netName}_2`,
          location: pinLocation2,
          net: findPin.net,
          signal: findPin.signal,
        }))
      }
    }
    this.vcComponents[index].pins.push(...newPins);
  }

  updateVirtualComps = (key, value, type, index) => {
    let showVCs = [], hideVCs = [];
    if (key === "type") {
      if (value === N_PORT) {
        const filterComps = this.virtualComps.filter(item => _.isEqual(item.location, this.vcComponents[index].location));
        const newComp = new VirtualComponentItem({
          ...filterComps[0],
          type: value,
          pins: filterComps.map(item => item.pins).flat(2)
        })

        this.virtualComps = this.virtualComps.filter(item => !_.isEqual(item.location, this.vcComponents[index].location));
        this.virtualComps.push({ ...newComp, errors: filterComps[0].errors });
        hideVCs.push(...filterComps.filter((it, index) => index > 0).map(item => item.name))
        showVCs.push(newComp.name)
        this.vcComponents[index].name = newComp.name;
      }
      if (VC_RLC_TYPES.includes(value)) {
        const filterComps = this.virtualComps.filter(item => _.isEqual(item.location, this.vcComponents[index].location));
        hideVCs.push(...this.virtualComps.filter(item => !_.isEqual(item.location, this.vcComponents[index].location)).map(item => item.name))
        this.virtualComps = this.virtualComps.filter(item => !_.isEqual(item.location, this.vcComponents[index].location));
        this.vcComponents[index].name = "UVC";
        for (let signal of this.signals) {
          const findComp = filterComps.find(comp => comp.pins.filter(item => this.signalNets[item.net] && this.signalNets[item.net].signal === signal.name).length) || {};
          const filterPins = (findComp.pins || []).filter(item => this.signalNets[item.net] && this.signalNets[item.net].signal === signal.name);
          if (!filterPins.length) {
            continue;
          }

          const names = this.getCompNames();
          const name = getDefaultName({ nameList: names, defaultKey: "UVC", key: "", firstIndex: 1 });
          const _value = findComp.type !== value && getShuntRLCType(findComp.type) === getShuntRLCType(value) ? findComp.value : "";
          this.virtualComps.push({
            ...new VirtualComponentItem({
              ...findComp,
              name,
              type: value,
              pins: filterPins.map(item => new VcCompPin({ ...item })),
              value: _value
            }),
            errors: findComp.errors
          })
        }
      }
    } else {
      //update virtual comps to table
      for (let comp of this.virtualComps) {
        if (!_.isEqual(comp.location, this.vcComponents[index].location)) {
          continue;
        }

        if (type === "unit") {
          comp.unit = value;
        } else if (key === "name") {
          if (VC_RLC_TYPES.includes(comp.type)) {
            const otherVirtualComps = this.getOtherVirtualComps ? this.getOtherVirtualComps() || [] : [];
            const names = [...this.virtualComps.filter(item => item.name !== comp.name).map(item => item.name), ...this.layoutCompNames, ...otherVirtualComps];
            hideVCs.push(comp.name)
            const name = getDefaultName({ nameList: names, defaultKey: value, key: "", firstIndex: 1 });
            comp.name = name;
            showVCs.push(name)
          } else {
            hideVCs.push(comp.name)
            comp[key] = value;
            showVCs.push(value)
          }
        } else if (key !== "type") {
          comp[key] = value;
        }
      }
    }

    this.updateVcComponents(this.virtualComps);
    hideVCs = [...new Set(hideVCs)];
    showVCs = [...new Set(showVCs)];
    this.viewVirtualComps = this.viewVirtualComps.filter(item => !hideVCs.includes(item));
    this.viewVirtualComps.push(...showVCs);
    this.openedVirtualComps = [...showVCs];

    this.updateCanvasViewVCompsToTable({
      viewVirtualComps: [...this.viewVirtualComps],
      openedVirtualComps: [...this.openedVirtualComps]
    });

    this.updateVCComponentCanvas([this.vcComponents[index]], true)
  }

  reCalcPinLocationAndUpdateComps = (key, value, type, vcIndex) => {
    const vcComponent = this.vcComponents[vcIndex];
    const { newPins, removePins } = this.reCalcPinsLocation(vcComponent);
    this.vcComponents[vcIndex].pins = newPins;
    //update virtual comps to table
    for (let comp of this.virtualComps) {
      if (!_.isEqual(comp.location, vcComponent.location)) {
        continue;
      }

      if (type === "unit") {
        comp.unit = value;
      } else {
        comp[key] = value;
      }
      comp.pins.forEach(pin => {
        const findPin = newPins.find(item => item.pin === pin.pin);
        if (findPin) {
          pin.location = [...findPin.location || []]
        }
      })

      comp.pins = comp.pins.filter(item => !removePins.includes(item.pin));

    }
    this.updateVcComponents(this.virtualComps);
  }

  reCalcPinsLocation = (vcComponent) => {
    const cutLength = this.getCutLength(vcComponent.cutLength, vcComponent.unit);

    let removePins = [];
    for (let pin of vcComponent.pins || []) {
      if (!pin.pin.match(/_2$/)) {
        continue;
      }
      const signalName = pin.pin.replace(/_2$/, "");
      const findPin = vcComponent.pins.find(item => item.pin === `${signalName}_1`);
      if (!findPin.intersectionNet) {
        continue;
      }
      //intersectionNet
      //pin_right
      const endPoint = [findPin.intersectionNet.geometry.mEnd.mX, findPin.intersectionNet.geometry.mEnd.mY]
      const pinLocation2 = this.calcPinLocationByDistance({
        pinLocation1: findPin.location,
        endPoint,
        length: cutLength,
        geomType: findPin.intersectionNet.geometry.mGeomType,
        arc: findPin.intersectionNet.geometry
      });
      if (!pinLocation2) {
        removePins.push(`${signalName}_1`, pin.pin)
      } else {
        pin.location = pinLocation2;
      }
    }
    if (removePins.length) {
      vcComponent.pins = vcComponent.pins.filter(item => !removePins.includes(item.pin));
    }
    return { newPins: vcComponent.pins, removePins };
  }

  removeVCComponentCanvas = (vcIndex) => {
    if (this.netsCanvas) {
      this.netsCanvas.removeRectangle(vcIndex);
    }
  }

  removeShowCompsPanel = (vcIndex) => {
    let hideVCs = [];
    const index = this.vcComponents.findIndex(item => item.index === vcIndex);
    if (index < 0) {
      return;
    }
    if (this.vcComponents[index].type === N_PORT) {
      hideVCs.push(this.vcComponents[index].name);
    }

    if (VC_RLC_TYPES.includes(this.vcComponents[index].type)) {
      hideVCs = this.virtualComps.filter(item => _.isEqual(item.location, this.vcComponents[index].location)).map(item => item.name);
    }
    hideVCs = [...new Set(hideVCs)];
    this.viewVirtualComps = this.viewVirtualComps.filter(item => !hideVCs.includes(item));
    this.openedVirtualComps = [];

    this.updateCanvasViewVCompsToTable({
      viewVirtualComps: [...this.viewVirtualComps],
      openedVirtualComps: [...this.openedVirtualComps]
    });
  }

  switchVcCompsStatus = ({
    viewVirtualComps,
    openedVirtualComps,
    addedComps,
    removedComps,
    deletedComps,
    isEditPanel,
    isUpdateLine
  }) => {
    this.viewVirtualComps = viewVirtualComps ? [...viewVirtualComps] : this.viewVirtualComps;
    this.openedVirtualComps = openedVirtualComps ? [...openedVirtualComps] : this.openedVirtualComps;

    //delete virtual components
    if (deletedComps && deletedComps.length) {
      const findLocations = this.virtualComps.filter(item => deletedComps.includes(item.name));
      this.virtualComps = this.virtualComps.filter(item => !findLocations.find(it => _.isEqual(it.location, item.location)));
      const delComps = this.vcComponents.filter(item => !!findLocations.find(it => _.isEqual(it.location, item.location)));
      this.vcComponents = this.vcComponents.filter(item => !findLocations.find(it => _.isEqual(it.location, item.location)));
      for (let item of delComps) {
        this.removeVCComponentCanvas(item.index)
      }
      return;
    }

    if (removedComps && removedComps.length) {
      const findRemoveLocations = this.virtualComps.filter(item => removedComps.includes(item.name)).map(item => item.location);
      const removeShowComps = this.vcComponents.filter(item => findRemoveLocations.find(it => _.isEqual(it, item.location)))
      if (isEditPanel) {
        //remove edit panel
        this.updateVCComponentCanvas(removeShowComps, false)
      }

      if (isUpdateLine) {
        for (let item of removeShowComps) {
          this.removeVCComponentCanvas(item.index)
        }
      }
    }

    if (addedComps && addedComps.length) {
      const findLocations = this.virtualComps.filter(item => addedComps.includes(item.name)).map(item => item.location);
      const vcComponents = this.vcComponents.filter(item => findLocations.find(it => _.isEqual(it, item.location)));
      if (isEditPanel) {
        this.updateVCComponentCanvas(vcComponents, true)
      }

      if (isUpdateLine) {
        this.updateVCComponentCanvas(vcComponents, false)
      }
    }
  }

  deleteLine = (lineName) => {
    this.netsCanvas && this.netsCanvas.removeAllPointsByName(lineName);
    this.editInfo = {}
  }

  updateCompType = (value) => {
    this.editInfo.type = value;
    if (VC_RLC_TYPES.includes(value)) {
      this.editInfo.name = "UVC"
    } else {
      this.editInfo.name = getDefaultName({ nameList: this.getCompNames(), defaultKey: "UVC", key: "", firstIndex: 1 });
    }
    this.updateLineCanvas(true);
  }

  updateCompName = (value) => {
    const names = this.getCompNames();
    if (names.includes(value)) {
      this.showVcErrors(`Component name "${value}" already exists.`);
      return;
    }
    this.editInfo.name = value;
  }

  getCompNames = () => {
    const names = this.virtualComps.map(item => item.name);
    const otherVirtualComps = this.getOtherVirtualComps ? this.getOtherVirtualComps() || [] : [];
    return [...names, ...this.layoutCompNames, ...otherVirtualComps];
  }

  updateCutLength = (value, type) => {
    if (type === "unit") {
      this.editInfo.unit = value;
    } else {
      this.editInfo.cutLength = value;
    }
  }

  createPins = (locations, length, compType) => {
    let pins = [];
    for (let signal of this.signals) {
      const nets = signal.nets || [];

      const { intersectionNet, error } = this.getIntersectNet(nets, JSON.parse(JSON.stringify(locations)))
      if (error && (!intersectionNet || !intersectionNet.net)) {
        this.errors.push(`${signal.name}: ${error}`);
        continue;
      }
      //pin_left
      const pinLocation1 = this.getIntersectPoint(intersectionNet.geometry, JSON.parse(JSON.stringify(locations)));
      if (pinLocation1) {
        if (VC_SHUNT_TYPES.includes(compType)) {
          pins.push(new VcPin({
            pin: `${signal.name}_1`,
            location: pinLocation1,
            net: intersectionNet.net,
            layer: intersectionNet.layer,
            intersectionNet,
            signal: signal.name
          }));
        } else {
          //pin_right
          /* const endPoint = [intersectionNet.geometry.mEnd.mX, intersectionNet.geometry.mEnd.mY] */
          const endPoint = this.getEndPoint(intersectionNet.geometry);
          const pinLocation2 = this.calcPinLocationByDistance({ pinLocation1, endPoint, length, geomType: intersectionNet.geometry.mGeomType, arc: intersectionNet.geometry });
          if (pinLocation1 && pinLocation2) {
            pins.push(new VcPin({
              pin: `${signal.name}_1`,
              location: pinLocation1,
              net: intersectionNet.net,
              layer: intersectionNet.layer,
              intersectionNet,
              signal: signal.name
            }), new VcPin({
              pin: `${signal.name}_2`,
              location: pinLocation2,
              net: intersectionNet.net,
              layer: intersectionNet.layer,
              signal: signal.name
            }));
          } else {
            this.errors.push(`${signal.name}: pins location calculation error.`);
          }
        }
      } else {
        this.errors.push(`${signal.name}: pins location calculation error.`);
      }
    }
    return pins;
  }

  getEndPoint = (geometry) => {
    const start = [geometry.mStart.mX, geometry.mStart.mY],
      end = [geometry.mEnd.mX, geometry.mEnd.mY];
    const distanceX = Math.abs(start[0] - end[0]),
      distanceY = Math.abs(start[1] - end[1]);

    if (distanceX > distanceY) {
      if (start[0] > end[0]) {
        return start;
      } else {
        return end;
      }
    } else {
      if (start[1] > end[1]) {
        return start;
      } else {
        return end;
      }
    }
  }

  getIntersectNet = (nets, locations) => {
    let intersectionNet = {}, isError = false;
    for (let net of nets) {
      const intersectionLines = this.getIntersectGemo((this.signalNets[net] || {}).geomList, locations);
      if (intersectionLines.length === 1) {
        intersectionNet = {
          net,
          geometry: intersectionLines[0].geometry,
          layer: this.DesignData.GetLayerManager().LayerIDToName(intersectionLines[0].layerID, true)
        }
        break;
      } else if (intersectionLines.length > 1) {
        isError = true;
        break;
      }
    }

    if (!intersectionNet.net) {
      isError = true;
    }
    return {
      intersectionNet,
      error: isError ? "The pin position is calculated incorrectly (the line segment must intersect with the net and there is only one intersection point)." : null
    }
  }

  getIntersectGemo = (geomList, locations) => {

    let intersectionLines = []
    for (let geom of (geomList || [])) {
      /*  if (geom.mGeomType === "Line") { */
      //{mStart:{mX,mY}, mEnd:{mX,mY}}
      const points = [[geom.geometry.mStart.mX, geom.geometry.mStart.mY], [geom.geometry.mEnd.mX, geom.geometry.mEnd.mY]];
      if (!getDisjoint(locations, points)) {
        intersectionLines.push({ geometry: geom.geometry, layerID: geom.layerID })
      }
      /* } */

    }
    return intersectionLines;
  }

  getIntersectPoint = (geometry, locations) => {
    if (geometry.mGeomType === "Line") {
      const points = [[geometry.mStart.mX, geometry.mStart.mY], [geometry.mEnd.mX, geometry.mEnd.mY]];
      const intersectPoint = getLinesIntersectPoint(points, locations)
      if (intersectPoint) {
        return intersectPoint.features[0].geometry.coordinates
      }
      return null;
    } else {
      const arc = {
        start: [geometry.mStart.mX, geometry.mStart.mY],
        end: [geometry.mEnd.mX, geometry.mEnd.mY],
        center: [geometry.mCenter.mX, geometry.mCenter.mY]
      };
      const point = calcIntersectByArcAndLine(arc, locations);
      return point;
    }
  }

  calcPinLocationByDistance = ({ pinLocation1, endPoint, length, geomType, arc }) => {
    if (geomType === "Line") {
      return calcLinePointByDistance(pinLocation1, endPoint, length);
    } else {
      const _arc = {
        start: [arc.mStart.mX, arc.mStart.mY],
        end: [arc.mEnd.mX, arc.mEnd.mY],
        center: [arc.mCenter.mX, arc.mCenter.mY],
      }
      return calculateArcEndpoint(_arc, pinLocation1[0], pinLocation1[1], length);
    }
  }
}

function InitVCComponent({
  name,
  type,
  layer = "",
  location,
  cutLength,
  unit,
  index,
  pins,
  errors,
  layoutUnit
}) {
  this.index = index;
  this.name = name;
  this.type = type;
  this.layer = layer;
  this.location = location || [];
  this.cutLength = cutLength;
  this.unit = unit;
  this.pins = pins || [];
  this.errors = errors;
  this.layoutUnit = layoutUnit || "mil"
}

function VcPin({ pin, location, net, layer, intersectionNet, signal }) {
  this.pin = pin;
  this.location = location;
  this.net = net;
  this.layer = layer;
  this.signal = signal;
  this.intersectionNet = intersectionNet;
}

const vcCanvas = new VCCanvas();
export default vcCanvas;