import {
  UPLOAD_PROGRESS_CHANGE,
  CLOSE_UPLOAD_PROGRESS_MODAL,
  TRANSLATION_RUNNING,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
  TRANSLATION_MONITOR,
  UPLOAD_PROJECT_ID,
  ADD_MSG,
  UPDATE_UPLOAD_WORKFLOW,
  SAVE_CURRENT_PCB_KEY,
  TRANSLATION_DEBUG,
  UPDATE_COMPRESS_PROGRESS,
  UPLOAD_PCB_START_UPDATE_STATUS,
  CHANGE_UPLOAD_DISABLE_STATUS
} from './actionType';

const defalState = ({
  visible: false,
  uploadProgress: 0,
  transProgress: -1,
  msg: null,
  endMsg: null,
  // 0 - upload,1 - translate
  monitor: null,
  uploadProjectId: null,
  uploadDesignId: null,
  currentPCBKey: null,
  debug: null,
  disabled: false,
  compressProgress: -1,
  uploadDesignType: "PCB"
})

const SierraUploadReducer = (state = defalState, action) => {
  switch (action.type) {
    case UPLOAD_PCB_START_UPDATE_STATUS:
      return {
        ...state,
        visible: true,
        compressProgress: 0,
        uploadProgress: 0,
        monitor: null,
        msg: '',
        disabled: true,
        uploadProjectId: action.projectId,
        uploadDesignType: action.uploadType,
        endMsg: ""
      }
    case UPLOAD_PROGRESS_CHANGE:
      const { uploadProgress } = action;
      return {
        ...state,
        uploadProgress,
      }
    case CLOSE_UPLOAD_PROGRESS_MODAL:
      return {
        ...state,
        visible: false,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
        disabled: false,
        monitor: null,
        uploadDesignId: null,
        endMsg: action.msg || ""
      }
    case TRANSLATION_RUNNING:
      const { transProgress } = action;
      return {
        ...state,
        transProgress,
      }
    case TRANSLATION_SUCCESS:
      return {
        ...state,
        visible: false,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
        disabled: false,
        uploadDesignId: null,
        endMsg: action.msg
      }
    case TRANSLATION_FAILED:
      return {
        ...state,
        endMsg: action.msg,
        visible: false,
        uploadProgress: 0,
        transProgress: -1,
        compressProgress: -1,
        uploadDesignId: null,
        disabled: false,
      }
    case TRANSLATION_MONITOR:
      return {
        ...state,
        monitor: action.monitor
      }
    case UPLOAD_PROJECT_ID:
      return {
        ...state,
        uploadProjectId: action.projectId,
      }
    case ADD_MSG:
      return {
        ...state,
        msg: state.msg ? (state.msg + '\n' + action.msg) : action.msg
      }
    case UPDATE_UPLOAD_WORKFLOW:
      return {
        ...state,
        uploadDesignId: action.designId,
        uploadWorkflowId: action.workflowId
      }
    case SAVE_CURRENT_PCB_KEY:
      return {
        ...state,
        currentPCBKey: action.key
      }
    case TRANSLATION_DEBUG:
      return {
        ...state,
        debug: action.debug
      }
    case UPDATE_COMPRESS_PROGRESS:
      return {
        ...state,
        compressProgress: action.compressProgress
      }
    case CHANGE_UPLOAD_DISABLE_STATUS:
      return {
        ...state,
        disabled: action.disabled
      }
    default: return state
  }
}

export default SierraUploadReducer;