import { call, takeEvery, select, put, cancel, fork, delay, take } from "@redux-saga/core/effects";
import {
  CREATE_END_TO_END_CHANNEL,
  GET_END_TO_END_CHANNEL_LIST,
  OPEN_END_TO_END_CHANNEL,
  GET_END_TO_END_CHANNEL_CONTENT,
  SAVE_END_TO_END_CHANNEL_TO_SERVER,
  UPDATE_END_TO_END_CHANNEL_CONTENT,
  DELETE_END_TO_END_CHANNEL,
  SAVE_CHANNEL_SELECT,
  SAVE_CONNECTION,
  AUTO_GET_END_TO_END_CHANNEL_LIST,
  CHANGE_END_TO_END_SIGNAL_NAME,
  DELETE_END_TO_END_SIGNAL,
  CHANGE_SELECTED_CHANNEL_SIGNAL,
  ADD_END_TO_END_SIGNAL,
  DELETE_PCB_CONNECTION,
  ADD_PCB_IN_CONNECTION,
  COPY_END_TO_END_CHANNEL,
  CHECK_END_TO_END_SETUP,
  AUTO_GET_END_TO_END_CHILDREN_CHANNEL_LIST,
  SAVE_SEASIM_CONFIG,
  UPDATE_ADS_CONFIG,
  END_TO_END_CHANNEL_RENAME
} from "./actionTypes";
import {
  createEndToEndChannelPromise,
  getEndToEndChannelList,
  getEndToEndChannelContent,
  saveEndToEndChannelContent,
  delEndToEndChannel,
  EndToEndChannelContent,
  updateConnectionByPCB,
  getChannelInfo,
  SignalConnectionMap,
  updateEndToEndSignalName,
  deleteEndToEndSignal,
  changeEndToEndChannelSignal,
  updateConnectionsByPCB,
  copyEndToEndChannel,
  endToEndSetupUpdate,
  copyChannelIntoEndToEnd,
  getChannelsByEndToEnd,
  updateEndToEndChildrenChannels,
  _updateEndToEndByChannelDeletedSignals,
  _updateEndToEndByChannelComponent,
  _updateEndToEndByAddedComponents,
  _updateEndToEndByDeletedComponents,
  _updateEndToEndByRenamedSignal,
  getEndToEndSignalNameByChannelSignal,
  setMultiPCBDefaultAMIModel,
  clearEndToEndAdsComponent,
  updateEndToEndAdsCompByConnection,
  judgeUpdateConnComp,
  delEndToEndAdsCompByDelPCB,
  delEndToEndAdsCompByIgnoreComp,
  updateEndToEndConnectionsPinMap,
  updateEndToEndTerminationModelPinMap
} from '../../../../services/Andes_v2/endToEndChannel';
import endToEndChildrenChannelsConstructor from '../../../../services/Andes_v2/endToEndChannel/endToEndChildrenChannelsConstructor';
import { getChannelsTree, deleteChannelByChannelId } from '@/services/Andes_v2/channel';
import {
  getEndToEndChannelTree,
  projectChild
} from '@/services/Andes_v2/project';
import { VERIFY_RUNNING, WAITING } from "@/constants/verificationStatus";
import { PROJECTS_INDEX, END_TO_END_INDEX, DESIGN_INDEX } from '@/services/Andes_v2';
import { openPage, updateTreeList, changeCopyLoadingId, updateSelectKeys, updateViewList, openProject } from '../project/action';
import projectEndToEndConstructor from "@/services/Andes_v2/project/projectEndToEndConstructor";
import endToEndChannelConstructor from "@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor";
import {
  getEndToEndContent,
  updateEndToEndChannelInfo,
  updateEndToEndChannelContent,
  clearEndToEndChannelInfo,
  updateConnectionModelSetup,
  updateEndToEndSetupMsgList,
  autoGetEndToEndChildrenChannelList,
  updateEndToEndResultExist,
  updateChannelTopologyLoading
} from "./action";
import { changeTabMenu, openTabFooter } from "../../../MonitorStore/action";
import { END_TO_END_CHANNEL, END_TO_END_CHANNEL_RESULT } from "@/constants/treeConstants";
import { getVerificationWorkflow, updateSimulationInfo } from '../simulation/action';
import { message } from "antd";
import { ANDES_V2 } from "@/constants/pageType";
import { strDelimited } from "@/services/helper/split";
import { getDefaultName } from "../../../../services/helper/setDefaultName";
import { endToEndErrorCheck } from "../../EndToEndChannel/endToEndErrorCheck";
import endToEndSetup from "@/services/Andes_v2/endToEndChannel/endToEndSetup";
import channelConstructor from "../../../../services/Andes_v2/channel/channelConstructor";
import { updateChannelInfo } from "../channel/action";
import { updateChannelSetupByVersion } from "../simulation/extraction/saga";
import { PCB_CHANNEL, PCB_CHANNEL_RESULT, END_TO_END_CHANNELS } from "../../../../constants/treeConstants";
import { isEndToEndChildrenChannel } from "../../../../services/Andes_v2";
import {
  getEndToEndSeaSimChannels,
  SeaSimConfig,
  delSignalInSeaSimConfig,
  updateSignalNameInSeaSimConfig,
  SeaSimChannel,
  updateEndToEndSeaSimCompByConnection,
  updateEndToEndSeaSimCompBySignal,
  delEndToEndSeaSimCompByDelPCB,
  delEndToEndSeaSimCompByIgnoreComp,
  updateSeaSimConfigComp,
  updateEndToEndSeaSimChannels,
  judgeSeasimConfigEQValue,
  updateSeasimConfigEQ,
  updateSeasimEQConfig,
  updateSeasimConfigInfo,
  judgeSeasimConfigGeneralValue,
  updateAdsConfigDFEValue
} from '../../../../services/Andes_v2/seaSim';
import { HDMI, PCIE, GENERIC, CPHY } from "../../../../services/PCBHelper/constants";
import { ANDES_V2__MULTI_PCB_CHANNEL_VERSION } from '../../../../version';
import { CONNECTOR, SERDES_TYPES } from "../../../../services/PCBHelper";
import { startEndToEndSimulation } from "../simulation/endToEndChannel/action";
import { isExistResult } from '../../../../services/Andes_v2/extractionCtrl';
import { versionCompareSize } from "../../../../services/helper/dataProcess";
import { updateMonitorInfo } from "../project/projectSaga";
import {
  delSignalInAdsConfig,
  updateSignalNameInAdsConfig,
  getSignalAmiCompBySignalName,
  AMISignalConfig,
  judgeAMIFlagExist,
  updateSignalAMIFlags,
  updateAdsConfigRXModelEQ,
  unCheckAmiModelUsePkg
} from '../../../../services/Andes_v2/AMIModelHelper';
import { getViewListBySelectedKey } from "../../../../services/helper/filterHelper";
import { CPHYIBISSignalConfig } from "../../../../services/Andes_v2/AMIModelHelper/IntegratedConfig";

function* _createEndToEnd(action) {
  const { data, projectId } = action;
  //data -> {id, name, type }
  try {
    const res = yield call(createEndToEndChannelPromise, {
      ...data,
      content: new EndToEndChannelContent(),
      projectId,
      version: ANDES_V2__MULTI_PCB_CHANNEL_VERSION
    });
    yield call(getEndToEndList, { projectId });
    if (res) {
      //open end to end channel
      yield put(openPage({ pageType: END_TO_END_CHANNEL, id: res.id }));
    }
  } catch (error) {
    console.error(error);
    message.error(`${error} Create multi-PCB channel failed!`);
  }
}

function* getEndToEndList(action) {
  const { projectId, openProject, isWhile } = action;
  const { AndesV2Reducer: { project: { treeItems, viewList, selectedKeys }, endToEndChannel: { endToEndChannelId }, channelResultReducer } } = yield select();
  let _treeItems = [...treeItems];
  // treeItems[PROJECTS_INDEX] - 'projects' ,PROJECTS_INDEX = 1;
  const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
  if (projectIndex < 0) {
    return [];
  }
  let endToEndChannels = [];
  try {
    //get project children end to end channels
    const resEndToEndChannels = yield call(getEndToEndChannelList, projectId);
    if (resEndToEndChannels && Array.isArray(resEndToEndChannels)) {
      const prevEndToEndTrees = _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX] ? _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children : [];
      //update tree
      endToEndChannels = getEndToEndChannelTree(resEndToEndChannels, prevEndToEndTrees);
      //save to cache
      projectEndToEndConstructor.set(projectId, resEndToEndChannels.map(d => d.id));
      resEndToEndChannels.forEach(item => { endToEndChannelConstructor.addEndToEndChannel(item.id, item) });
    }
    const projectItem = _treeItems[PROJECTS_INDEX].children[projectIndex]

    if (endToEndChannels.length) {
      const prevEndToEndItem = _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX];
      if (!prevEndToEndItem || !prevEndToEndItem.name) {
        _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX] = new projectChild({
          name: "Multi-PCB Channel",
          id: projectItem.id, // projectId
          category: projectItem.category,
          dataType: END_TO_END_CHANNELS,
          children: endToEndChannels
        })
      } else {
        _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children = endToEndChannels;
      }
    } else {
      const design = _treeItems[PROJECTS_INDEX].children[projectIndex].children[DESIGN_INDEX];
      if (design.children.length >= 2) {
        _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX] = new projectChild({
          name: "Multi-PCB Channel",
          id: projectItem.id, // projectId
          category: projectItem.category,
          dataType: END_TO_END_CHANNELS,
          children: []
        })
      } else {
        delete _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX];
      }
    }

    yield put(updateTreeList({ treeItems: [..._treeItems] }));
    //get end to end channel simulation status and workflow
    let _endToEndChannelId = null;
    if (viewList.includes(END_TO_END_CHANNEL) && selectedKeys.includes(`${END_TO_END_CHANNEL}-${endToEndChannelId}`)) {
      _endToEndChannelId = endToEndChannelId;
    }
    if (viewList.includes(END_TO_END_CHANNEL_RESULT) && selectedKeys.includes(`${END_TO_END_CHANNEL_RESULT}-${channelResultReducer.endToEndChannelId}`)) {
      _endToEndChannelId = channelResultReducer.endToEndChannelId;
    }
    if (openProject && _endToEndChannelId) {
      const endToEnd = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId) || {};
      yield put(getVerificationWorkflow({
        workType: END_TO_END_CHANNEL,
        endToEndChannelId,
        verificationId: endToEnd.verificationId
      }))
    }
    if (!isWhile) {
      //auto get multi-pcb channel children single channel list
      yield put(autoGetEndToEndChildrenChannelList(endToEndChannels.map(item => item.id), projectId));
    }
  } catch (error) {
    console.error(error);
  }
  return endToEndChannels;
}

let autoSaveTask = null;
function* _openEndToEndChannel(action) {
  const { id } = action;
  if (autoSaveTask) {
    yield cancel(autoSaveTask);
  }
  yield call(saveEndToEndChannelContentToServer);
  yield put(getEndToEndContent(id));
  const endToEndChannel = endToEndChannelConstructor.getEndToEndChannel(id) || {};
  yield put(openTabFooter());
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: endToEndChannel.verificationId,
    verificationName: endToEndChannel.name,
    menuType: "simulation"
  }));
  yield put(getVerificationWorkflow({
    endToEndChannelId: id,
    workType: END_TO_END_CHANNEL,
    verificationId: endToEndChannel.verificationId
  }));
  //clear prev end to end children channels error check
  yield put(updateSimulationInfo({
    verificationId: endToEndChannel.verificationId,
    item: "channelsErrorCheckList",
    info: []
  }))
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([SAVE_END_TO_END_CHANNEL_TO_SERVER]);
    let delayTime = 3000;
    if (newAction.type === SAVE_END_TO_END_CHANNEL_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, saveEndToEndChannelContentToServer, newAction, delayTime);
    }
  }
}

function* debounce(callback, action, time = 0) {
  yield delay(time);
  yield call(callback, action);
}

function* saveEndToEndChannelContentToServer(info) {
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo, channelTopologyLoading } } } = yield select();
  if (channelTopologyLoading && !info) {
    return;
  }
  const saveInfo = info && info.id ? info : endToEndChannelInfo;
  if (!saveInfo || !saveInfo.id) {
    return;
  }
  //error check
  const errors = endToEndErrorCheck(saveInfo.content);
  //save cache
  endToEndSetup.set(saveInfo.id, saveInfo);
  try {
    yield call(saveEndToEndChannelContent, {
      ...saveInfo,
      readyForSim: errors && errors.length ? 0 : 1,
      version: ANDES_V2__MULTI_PCB_CHANNEL_VERSION
    });
  } catch (error) {
    console.error(error);
  }
}

function* _getContent(action) {
  const { id } = action;
  yield put(updateChannelTopologyLoading(false));
  const res = yield call(getEndToEndChannelContent, id);
  if (!res) {
    return;
  }
  //is exist result
  const resultExist = yield call(isExistResult, res.verificationId);
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelId } } } = yield select();
  if (endToEndChannelId === res.id) {
    yield put(updateEndToEndResultExist({ id: res.id, exist: resultExist ? resultExist.exist : false }))
    yield put(updateEndToEndChannelInfo(res || {}));
    autoSaveTask = yield fork(autoSave);
    //check setup by channel json
    yield call(_checkEndToEndSetup, {});
  }
}

function* _updateContent() {
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
}

function* _delEndToEndChannel(action) {
  const { data: { id } } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelId }, project: { selectedKeys } } } = yield select();

  const endToEndChannel = endToEndChannelConstructor.getEndToEndChannel(id);
  //delete end to end channel
  try {
    yield call(delEndToEndChannel, id);
    //update end to end channel list
    yield call(getEndToEndList, { projectId: endToEndChannel.projectId });
    projectEndToEndConstructor.delEndToEndChannel(endToEndChannel.projectId, id);
    endToEndChannelConstructor.delEndToEndChannel(id);

    if (id === endToEndChannelId) {
      //clear info
      yield put(clearEndToEndChannelInfo());
      let _selectedKeys = [...selectedKeys];
      _selectedKeys = _selectedKeys.filter(d => d !== `${END_TO_END_CHANNEL}-${id}`)
      yield put(updateSelectKeys(_selectedKeys));
      yield put(updateViewList(getViewListBySelectedKey(_selectedKeys)));
      //update open channel or end to end channel monitor info
      yield call(updateMonitorInfo, { selectedKeys: _selectedKeys });
    }
  } catch (error) {
    console.error(error);
  }
}

function* _updateChannelSelect(action) {
  const { record, dataIndex } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();

  let _endToEndChannelInfo = JSON.parse(JSON.stringify(endToEndChannelInfo));
  let pcbConnections = endToEndChannelInfo.content.pcbConnections ? [...endToEndChannelInfo.content.pcbConnections] : [];
  let connections = endToEndChannelInfo.content.connections ? [...endToEndChannelInfo.content.connections] : [];
  const index = parseInt(strDelimited(dataIndex, "_", { returnIndex: 1 }));
  //if pcb and channel is already selected
  const channelIds = pcbConnections.map(item => item.channelId).filter(item => !!item);
  if (channelIds.includes(record[dataIndex].channelId)) {
    return;
  }
  if (!pcbConnections[index]) {
    return;
  }
  yield put(updateChannelTopologyLoading(true));

  let copyChannel = { id: "", name: "" }, prevChannelId = null;
  //get prev channel id
  if (pcbConnections[index].channelId && pcbConnections[index].channelId !== record[dataIndex].channelId) {
    prevChannelId = pcbConnections[index].channelId;
  }

  pcbConnections[index].designId = record[dataIndex].designId;
  pcbConnections[index].designName = record[dataIndex].designName;
  pcbConnections[index].channelId = "";
  pcbConnections[index].channelName = "";

  yield put(updateEndToEndChannelContent({ pcbConnections }));

  if (record[dataIndex].channelId) {
    //copy new channel and deleted prev channel
    try {
      copyChannel = yield call(copyChannelIntoEndToEnd, {
        channelId: record[dataIndex].channelId,
        endToEndChannelId: endToEndChannelInfo.id,
        originalChannelId: prevChannelId
      });
    } catch (error) {
      console.error(error);
    }

    if (!copyChannel) {
      yield put(updateChannelTopologyLoading(false));
      return;
    }
  }

  const currentPCB = {
    ...record[dataIndex],
    channelId: copyChannel.id,
    channelName: copyChannel.name
  }

  pcbConnections[index] = {
    ...currentPCB
  }

  let channelInfo = yield call(getChannelInfo, currentPCB.channelId);
  channelInfo = yield call(updateChannelSetupByVersion, { channelInfo, endToEndId: _endToEndChannelInfo.id });

  //Update connections by pcb
  connections = updateConnectionByPCB({
    connections,
    currentPCB,
    pcbConnections
  })

  _endToEndChannelInfo.content.pcbConnections = pcbConnections;
  _endToEndChannelInfo.content.connections = connections;
  if (_endToEndChannelInfo.type === PCIE && (!_endToEndChannelInfo.config || !_endToEndChannelInfo.config.channels || !_endToEndChannelInfo.config.channels.length)) {
    const { channels, analysisChannels } = getEndToEndSeaSimChannels(_endToEndChannelInfo.content.pcbConnections, _endToEndChannelInfo.content.connections);
    _endToEndChannelInfo.config = new SeaSimConfig({ channels, analysisChannels });
  } else if (_endToEndChannelInfo.type === PCIE && _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels && (index === "0" || parseInt(index) === pcbConnections.length - 1)) {
    _endToEndChannelInfo.config = updateEndToEndSeaSimChannels({
      newChannel: pcbConnections[index],
      config: _endToEndChannelInfo.config,
      connections,
      prevChannelId,
      channelInfo,
      index
    })
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_endToEndChannelInfo.type) && (index === 0 || index === pcbConnections.length - 1)) {
    //update ads config signals
    if (!_endToEndChannelInfo.adsConfig || !_endToEndChannelInfo.adsConfig.signals || !_endToEndChannelInfo.adsConfig.signals.length) {
      _endToEndChannelInfo = setMultiPCBDefaultAMIModel(_endToEndChannelInfo, { channelSetupList: [channelInfo] });
    } else {
      _endToEndChannelInfo.adsConfig = clearEndToEndAdsComponent({
        pcbConn: pcbConnections[index],
        adsConfig: _endToEndChannelInfo.adsConfig,
        prevChannelId,
        connections,
        connIndex: index,
        channelInfo,
        serdesType: _endToEndChannelInfo.type
      });
    }
  }

  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelId } } } = yield select();

  if (_endToEndChannelInfo.id !== endToEndChannelId) {
    yield put(updateChannelTopologyLoading(false));
    //error check
    const errors = endToEndErrorCheck(_endToEndChannelInfo.content);
    //save cache
    endToEndSetup.set(_endToEndChannelInfo.id, _endToEndChannelInfo);
    try {
      yield call(saveEndToEndChannelContent, {
        ..._endToEndChannelInfo,
        readyForSim: errors && errors.length ? 0 : 1,
        version: ANDES_V2__MULTI_PCB_CHANNEL_VERSION
      });
    } catch (error) {
      console.error(error);
    }
  } else {
    yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
    yield put(updateConnectionModelSetup(true));
    yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
  }

  if (prevChannelId) {
    yield call(deleteEndToEndChildrenChannel, { id: prevChannelId, endToEndId: endToEndChannelInfo.id, copyChannel })
  }
  yield put(updateChannelTopologyLoading(false));
  yield call(getEndToEndChildrenChannelList, { endToEndIds: [endToEndChannelInfo.id] });
}

function* deleteEndToEndChildrenChannel(action) {
  const { id, endToEndId, copyChannel } = action;
  const { AndesV2Reducer: { channel: { channelId } } } = yield select();
  //DELETE pre channel
  if (!copyChannel || !copyChannel.id) {
    try {
      yield call(deleteChannelByChannelId, id);
    } catch (error) {
      console.error(error);
    }
  }
  //clear cache
  endToEndChildrenChannelsConstructor.delChannel(endToEndId, id);
  channelConstructor.delChannel(id);

  if (id === channelId) {
    //clear info
    yield put(updateChannelInfo({}));
  }
}

function* getEndToEndChildrenChannelList(action) {
  const { endToEndIds, openProject } = action;

  yield* endToEndIds.map(function* (id) {
    yield call(getSingleEndToEndChildren, { id, openProject });
  })
}

function* getSingleEndToEndChildren(action) {
  const { id, openProject } = action;
  const { AndesV2Reducer: { project: { treeItems, selectedKeys, viewList }, channel: { channelId }, channelResultReducer } } = yield select();
  let _treeItems = [...treeItems];

  const endToEnd = endToEndChannelConstructor.getEndToEndChannel(id) || {};
  try {
    const list = yield call(getChannelsByEndToEnd, id);
    const channels = getChannelsTree(list);
    _treeItems = updateEndToEndChildrenChannels({
      channels,
      treeItems: _treeItems,
      endToEndId: id,
      projectId: endToEnd.projectId
    });
    yield put(updateTreeList({ treeItems: [..._treeItems] }));
    list.forEach(item => { channelConstructor.addChannel(item.id, item) });
    endToEndChildrenChannelsConstructor.set(id, list);

    if (!openProject) {
      //running waiting end to end
      return list;
    }
    //get channel simulation status and workflow
    let _channelId = null;
    if (viewList.includes(PCB_CHANNEL) && selectedKeys.includes(`${PCB_CHANNEL}-${channelId}`)) {
      _channelId = channelId;
      yield put(openTabFooter());
    }
    if (viewList.includes(PCB_CHANNEL_RESULT) && selectedKeys.includes(`${PCB_CHANNEL_RESULT}-${channelResultReducer.channelId}`)) {
      _channelId = channelResultReducer.channelId;
    }

    const channel = channelConstructor.getChannel(_channelId) || {};
    if (isEndToEndChildrenChannel(channel)) {
      yield put(changeTabMenu({
        tabSelectKeys: ["monitor"],
        currentVerificationId: channel.verificationId,
        verificationName: channel.pcbName,
        menuType: "simulation"
      }))
      yield put(getVerificationWorkflow({
        workType: PCB_CHANNEL,
        channelId: _channelId,
        verificationId: channel.verificationId
      }))
    }
    //running waiting end to end
    return list;
  } catch (error) {
    console.error(error);
    return [];
  }
}

function* _updateConnection(action) {
  const { connection, CONNECTION_ID } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let connections = endToEndChannelInfo.content.connections ? [...endToEndChannelInfo.content.connections] : [];
  const pcbConnections = endToEndChannelInfo.content.pcbConnections ? [...endToEndChannelInfo.content.pcbConnections] : [];

  const index = connections.findIndex(item => item.CONNECTION_ID === CONNECTION_ID);
  if (index < 0) {
    return;
  }

  connections[index] = {
    ...connections[index],
    connection: {
      signal_connections_map: connection.signal_connections_map,
      cableModels: connection.cableModels,
      connector1: connection.connector1,
      connector2: connection.connector2
    }
  }

  if (endToEndChannelInfo.type === PCIE) {
    let config = _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels ? _endToEndChannelInfo.config : new SeaSimConfig();
    config = updateEndToEndSeaSimCompByConnection(config, pcbConnections, connection, CONNECTION_ID);
    _endToEndChannelInfo.config = config;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(endToEndChannelInfo.type)) {
    //update ads config component
    let adsConfig = _endToEndChannelInfo.adsConfig;
    const updateInfo = judgeUpdateConnComp({
      adsConfig,
      pcbConnections,
      connection,
      connections,
      connectionIndex: index
    })
    if (updateInfo) {
      const channelInfo = yield call(getChannelInfo, pcbConnections[updateInfo.pcbIndex].channelId);
      adsConfig = updateEndToEndAdsCompByConnection({
        adsConfig,
        pcbConnections,
        connection,
        pcbIndex: updateInfo.pcbIndex,
        compName: updateInfo.compName,
        channelInfo,
        serdesType: _endToEndChannelInfo.type
      });
      _endToEndChannelInfo.adsConfig = adsConfig;
    }
  }

  _endToEndChannelInfo.content.connections = connections;
  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
  yield put(updateConnectionModelSetup(true));
}

let autoGetListTask = null;
function* _autoGetEndToEndList(action) {
  const { projectId, currentSimInfo, openProject, runSimulation } = action;
  if (!projectId) {
    return;
  }
  // currentSimInfo - change simulation status, from checking -> error / success
  if (!currentSimInfo) {
    if (autoGetListTask) {
      yield cancel(autoGetListTask);
    }
    autoGetListTask = yield fork(updateEndToEndVerificationStatus, { projectId, openProject, runSimulation });
  } else {
    yield call(updateEndToEndStatus, { projectId, currentSimInfo });
  }
}

function* updateEndToEndVerificationStatus(action) {
  const { projectId, runSimulation } = action;
  let openProject = action.openProject;

  let first = true,
    delayTime = 6000,
    isWhile = true;
  while (isWhile) {
    if (!first) {
      yield delay(delayTime);//6000
      openProject = false;
    }
    const { LoginReducer: { pageType }, AndesV2Reducer: { project: { openProjectId } } } = yield select();
    //if project close , return
    if (pageType !== ANDES_V2 || openProjectId !== projectId) {
      return;
    }
    const endToEndChannels = yield call(getEndToEndList, { projectId, openProject, isWhile: openProject ? false : true })
    const allSimIds = endToEndChannels.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status));
    //if running and waiting exist delay time modified 5s
    allSimIds.length ? delayTime = 6000 : isWhile = false;
    if (first && !openProject && !runSimulation) {
      //auto get multi-pcb channel children single channel list
      yield put(autoGetEndToEndChildrenChannelList(allSimIds.map(item => item.id), projectId));
    }
    if (runSimulation) {
      yield call(getEndToEndChildrenChannelList, { endToEndIds: allSimIds.map(item => item.id), openProject });
    }
    first = false;
  }
}

function* updateEndToEndStatus(action) {
  const { projectId, currentSimInfo } = action;
  // update once
  const { AndesV2Reducer: { project: { treeItems } } } = yield select();

  let _treeItems = [...treeItems];

  for (let it of currentSimInfo) {
    // treeItems[1] - 'projects'
    const projectIndex = _treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
    if (projectIndex > -1) {
      // tree design list
      let endToEndChannelTrees = _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children || [];
      endToEndChannelTrees.forEach(item => {
        if (it.endToEndChannelId === item.id && (!item.simStatus || it.status !== item.simStatus)) {
          item.simStatus = it.status;
        }
      });
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children = endToEndChannelTrees;
    }
  }
  yield put(updateTreeList({ treeItems: [..._treeItems] }));
}

function* _updateSignalName(action) {
  const { record: { name }, prevRecord: { name: prevName } } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let config = _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels ? { ..._endToEndChannelInfo.config } : new SeaSimConfig();

  let connections = endToEndChannelInfo.content.connections ? [...endToEndChannelInfo.content.connections] : [];
  const updateInfo = updateEndToEndSignalName(connections, name, prevName);
  if (!updateInfo.isChangeName) {
    return;
  }
  connections = updateInfo.connections

  if (_endToEndChannelInfo.type === PCIE) {
    //update seasim config signal
    _endToEndChannelInfo.config = updateSignalNameInSeaSimConfig(config, prevName, name);
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_endToEndChannelInfo.type)) {
    //update ads config signal
    _endToEndChannelInfo.adsConfig = updateSignalNameInAdsConfig(_endToEndChannelInfo.adsConfig, prevName, name);
  }
  _endToEndChannelInfo.content.connections = connections;

  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put(updateConnectionModelSetup(true));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
}

function* _delSignal(action) {
  const { signalName } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let config = endToEndChannelInfo.config && endToEndChannelInfo.config.channels ? { ...endToEndChannelInfo.config } : new SeaSimConfig();
  let connections = endToEndChannelInfo.content.connections ? [...endToEndChannelInfo.content.connections] : [];
  connections = deleteEndToEndSignal(connections, signalName);

  if ([PCIE, HDMI, GENERIC, CPHY].includes(endToEndChannelInfo.type)) {
    //delete ads config signal
    _endToEndChannelInfo.adsConfig = delSignalInAdsConfig(_endToEndChannelInfo.adsConfig, signalName);
  }

  if (_endToEndChannelInfo.type === PCIE) {
    //delete seasim config signal
    _endToEndChannelInfo.config = delSignalInSeaSimConfig(config, signalName);

  }

  _endToEndChannelInfo.content.connections = connections;
  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put(updateConnectionModelSetup(true));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER })
}

function* _modifySelectedChannelSignal(action) {
  const { record, dataIndex, pcbChannel } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let connections = _endToEndChannelInfo.content.connections ? [..._endToEndChannelInfo.content.connections] : [];
  connections = changeEndToEndChannelSignal({ connections, record, dataIndex, pcbChannel });
  _endToEndChannelInfo.content.connections = connections;
  if (_endToEndChannelInfo.type === PCIE) {
    //update seaSim config channels component pins by new signal
    let config = _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels ? { ..._endToEndChannelInfo.config } : new SeaSimConfig();
    config = updateEndToEndSeaSimCompBySignal({
      config,
      record,
      dataIndex,
      pcbChannel,
      lastIndex: _endToEndChannelInfo.content.pcbConnections.length - 1
    });

    _endToEndChannelInfo.config = config;
  }

  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put(updateConnectionModelSetup(true));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
}

function* addEndToEndConnectionSignal() {
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let connections = _endToEndChannelInfo.content.connections ? [..._endToEndChannelInfo.content.connections] : [];
  const name = getDefaultName({
    nameList: connections[0].connection.signal_connections_map,
    defaultKey: "Signal",
    firstIndex: connections[0].connection.signal_connections_map.length
  })
  for (let conn of connections) {
    conn.connection.signal_connections_map.push({
      ...new SignalConnectionMap({ name })
    })
  }
  _endToEndChannelInfo.content.connections = connections;
  if (_endToEndChannelInfo.type === PCIE) {
    //add signal to seaSim config channels
    let config = _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels ? { ..._endToEndChannelInfo.config } : new SeaSimConfig();
    config.channels.push(new SeaSimChannel({
      signal: name,
      controller: {
        component: "",
        pins: [],
        design: {
          designId: "",
          channelId: ""
        }
      },
      device: {
        component: "",
        pins: [],
        design: {
          designId: "",
          channelId: ""
        }
      }
    }));
    config.analysis.channels.push({ victim: name, aggressors: [] });
    _endToEndChannelInfo.config = config;
  }

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_endToEndChannelInfo.type)) {
    //add signal to ads config channels
    let adsConfig = _endToEndChannelInfo.adsConfig ? _endToEndChannelInfo.adsConfig : {};
    const { txComp, rxComp } = getSignalAmiCompBySignalName({
      signalName: name,
      controller: { comp: adsConfig.controller, channel: { ...adsConfig.controllerChannel } },
      device: { comp: adsConfig.device, channel: { ...adsConfig.deviceChannel } }
    });
    const IbisHasAMI = adsConfig.signals.length ? adsConfig.signals[0].IbisHasAMI : 'yes';
    let adsSignal = null;
    if (_endToEndChannelInfo.type === CPHY) {
      adsSignal = new CPHYIBISSignalConfig({
        type: END_TO_END_CHANNEL,
        signalName: name,
        controller: txComp.comp || "",
        controllerChannelId: txComp.channel ? txComp.channel.channelId : null,
        device: rxComp.comp || "",
        deviceChannelId: rxComp.channel ? rxComp.channel.channelId : null
      })
    } else {
      adsSignal = new AMISignalConfig({
        type: END_TO_END_CHANNEL,
        signalName: name,
        controller: txComp.comp || "",
        controllerChannelId: txComp.channel ? txComp.channel.channelId : null,
        device: rxComp.comp || "",
        deviceChannelId: rxComp.channel ? rxComp.channel.channelId : null,
        IbisHasAMI
      })
    }
    adsConfig.signals && adsConfig.signals.push(adsSignal)
    _endToEndChannelInfo.adsConfig = adsConfig;
  }

  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put(updateConnectionModelSetup(true));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER })

}

function* _delPCB(action) {
  const { key } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let connections = endToEndChannelInfo.content.connections ? [...endToEndChannelInfo.content.connections] : [];
  let pcbConnections = endToEndChannelInfo.content.pcbConnections ? [...endToEndChannelInfo.content.pcbConnections] : [];
  const index = strDelimited(key, "_", { returnIndex: 1 });
  const delPCB = pcbConnections[parseInt(index)];
  const lastIndex = pcbConnections.length - 1;
  const updateInfo = updateConnectionsByPCB({ type: "delete", delIndex: index, connections, pcbConnections });
  _endToEndChannelInfo.content.connections = updateInfo.connections;
  _endToEndChannelInfo.content.pcbConnections = updateInfo.pcbConnections;

  const endIndex = _endToEndChannelInfo.content.pcbConnections.length - 1;
  const newIndex = parseInt(index) === 0 ? 0 : endIndex;
  if ([PCIE, HDMI, GENERIC, CPHY].includes(_endToEndChannelInfo.type) && (parseInt(index) === 0 || parseInt(index) === lastIndex)) {
    //add signal to seaSim config channels
    const info = yield call(getChannelInfo, _endToEndChannelInfo.content.pcbConnections[newIndex].channelId);
    if (_endToEndChannelInfo.type === PCIE) {
      let config = _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels ? { ..._endToEndChannelInfo.config } : new SeaSimConfig();
      config = delEndToEndSeaSimCompByDelPCB({
        config,
        newIndex,
        newChannel: _endToEndChannelInfo.content.pcbConnections[newIndex],
        channelInfo: info,
        delPCB,
        connections: _endToEndChannelInfo.content.connections
      });
      _endToEndChannelInfo.config = config;
    }

    //add signal to ads config
    let adsConfig = _endToEndChannelInfo.adsConfig && _endToEndChannelInfo.adsConfig ? { ..._endToEndChannelInfo.adsConfig } : null;
    adsConfig = delEndToEndAdsCompByDelPCB({
      adsConfig,
      delPCB,
      newIndex,
      newPCB: _endToEndChannelInfo.content.pcbConnections[newIndex],
      channelInfo: info,
      connections: _endToEndChannelInfo.content.connections,
      serdesType: _endToEndChannelInfo.type
    });
    _endToEndChannelInfo.adsConfig = adsConfig;
  }
  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put(updateConnectionModelSetup(true));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER })
  if (updateInfo.deletedChannelId) {
    yield call(deleteEndToEndChildrenChannel, {
      id: updateInfo.deletedChannelId,
      endToEndId: _endToEndChannelInfo.id
    })
    yield call(getEndToEndChildrenChannelList, { endToEndIds: [_endToEndChannelInfo.id] });
  }
}

function* _addPCB(action) {
  const { key } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  let connections = endToEndChannelInfo.content.connections ? [...endToEndChannelInfo.content.connections] : [];
  let pcbConnections = endToEndChannelInfo.content.pcbConnections ? [...endToEndChannelInfo.content.pcbConnections] : [];

  const index = strDelimited(key, "_", { returnIndex: 1 });
  const lastIndex = pcbConnections.length - 1;
  const delPCB = pcbConnections[parseInt(index)];
  const updateInfo = updateConnectionsByPCB({ type: "add", addIndex: index, connections, pcbConnections });

  _endToEndChannelInfo.content.connections = updateInfo.connections;
  _endToEndChannelInfo.content.pcbConnections = updateInfo.pcbConnections;

  if ([PCIE, HDMI, GENERIC, CPHY].includes(_endToEndChannelInfo.type) && parseInt(index) === lastIndex) {
    //del comp to seaSim config channels
    if (_endToEndChannelInfo.type === PCIE) {
      let config = _endToEndChannelInfo.config && _endToEndChannelInfo.config.channels ? { ..._endToEndChannelInfo.config } : new SeaSimConfig();
      config = delEndToEndSeaSimCompByDelPCB({
        config,
        delPCB
      });
      _endToEndChannelInfo.config = config;
    }

    //del comp to ads config
    let adsConfig = _endToEndChannelInfo.adsConfig && _endToEndChannelInfo.adsConfig ? { ..._endToEndChannelInfo.adsConfig } : null;
    adsConfig = delEndToEndAdsCompByDelPCB({
      adsConfig,
      delPCB,
      newIndex: _endToEndChannelInfo.content.pcbConnections.length - 1,
      serdesType: _endToEndChannelInfo.type
    });
    _endToEndChannelInfo.adsConfig = adsConfig;

  }

  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));
  yield put(updateConnectionModelSetup(true));
  yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
}

function* _copyEndToEnd(action) {
  const { name, endToEndChannelId } = action;
  yield put(changeCopyLoadingId({ copyEndToEndChannelLoadingId: endToEndChannelId }));
  try {
    const res = yield call(copyEndToEndChannel, name, endToEndChannelId);
    if (!res || !res.id) {
      message.error("Copy multi-PCB channel failed!");
      yield put(changeCopyLoadingId({ copyEndToEndChannelLoadingId: null }));
      return;
    }
    endToEndChannelConstructor.addEndToEndChannel(res.id, res);
    yield call(getEndToEndList, { projectId: res.projectId, isWhile: true });
    yield call(getSingleEndToEndChildren, { id: res.id });
    yield put(openPage({ pageType: END_TO_END_CHANNEL, id: res.id }));
  } catch (error) {
    message.error("Copy multi-PCB channel failed!");
  }

  yield put(changeCopyLoadingId({ copyEndToEndChannelLoadingId: null }));
}

function* _checkEndToEndSetup(action) {
  const { deletedDesignId, setupInfo, updateChannel, updateClip } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = setupInfo ? setupInfo : endToEndChannelInfo;
  if (!_endToEndChannelInfo || !_endToEndChannelInfo.id || !Object.keys(_endToEndChannelInfo.content).length) {
    return _endToEndChannelInfo;
  }

  let addModelKey = false;
  if (versionCompareSize(_endToEndChannelInfo.version, "0.0.3")) {
    addModelKey = true;
  }

  let pcbConnections = _endToEndChannelInfo.content.pcbConnections ? [..._endToEndChannelInfo.content.pcbConnections] : [];
  //get all channel setup json
  let channelSetupList = [];
  for (let i = 0; i < pcbConnections.length; i++) {
    const pcb = pcbConnections[i];
    if (!pcb.channelId) {
      continue;
    }
    try {
      let setup = yield call(getChannelInfo, pcb.channelId);
      //check and update channel by channel version
      setup = yield call(updateChannelSetupByVersion, { channelInfo: setup, endToEndId: _endToEndChannelInfo.id, updateClip });
      channelSetupList.push(setup);
    } catch (error) {
      console.error(error);
    }
  }

  //end to end setup update by channel setup
  let {
    save,
    endToEndChannelInfo: _updateInfo,
    updateMsgList
  } = endToEndSetupUpdate({
    endToEndChannelInfo: _endToEndChannelInfo,
    deletedDesignId,
    updateChannel,
    channelSetupList,
    addModelKey,
    isUpdatePCBName: true
  });

  if (_updateInfo.type === PCIE && (!_updateInfo.config || !Object.keys(_updateInfo.config).length)) {
    const { channels, analysisChannels } = getEndToEndSeaSimChannels(_updateInfo.content.pcbConnections, _updateInfo.content.connections);
    _updateInfo.config = new SeaSimConfig({ channels, analysisChannels });
    save = true;
  }
  //set ads config
  if ([PCIE, HDMI, GENERIC, CPHY].includes(_updateInfo.type) && (!_updateInfo.adsConfig || !Object.keys(_updateInfo.adsConfig).length)) {
    _updateInfo = setMultiPCBDefaultAMIModel(_updateInfo, { channelSetupList });
    save = true;
  }

  //add default "IbisHasAMI" field
  if ([PCIE, HDMI, GENERIC].includes(_updateInfo.type) && judgeAMIFlagExist(_updateInfo.adsConfig)) {
    _updateInfo.adsConfig = updateSignalAMIFlags(_updateInfo.adsConfig);
    save = true;
  }

  //if "Equalizer Adaptation" is turned off, update [TX_PRE_SHOOT, TX_DEEMPHASIS,LEQ_DC,LEQ_POLE,LEQ_DC2,LEQ_POLE2] value from array to string. 
  if (_updateInfo.type === PCIE && judgeSeasimConfigEQValue(_updateInfo.config)) {
    _updateInfo.config = updateSeasimConfigEQ(_updateInfo.config);
    save = true;
  }

  //Compatible with older versions of config, remove unnecessary fields according to "Equalizer Adaptation". Add required fields
  if (_updateInfo.type === PCIE && versionCompareSize(_updateInfo.version, "0.0.5")) {
    const returnInfo = updateSeasimEQConfig(_updateInfo.config);
    _updateInfo.config = returnInfo.config;
    save = returnInfo.save || save;
  }

  // When RX is IBIS Model, modify the options for deleting CTLE Setting from ["Default", "Custom"] to ["Preset", "Custom"], as previously set to "Default" to "Custom"
  // When RX is a Termination Model, add jitter and EQ
  if (_updateInfo.adsConfig && _updateInfo.adsConfig.signals && (versionCompareSize(_updateInfo.version, "0.0.6"))) {
    _updateInfo.adsConfig.signals = updateAdsConfigRXModelEQ(_updateInfo.adsConfig.signals)
    save = true
  }

  if (_updateInfo.config && _updateInfo.config.analysis && _updateInfo.config.analysis.options && (versionCompareSize(_updateInfo.version, "0.0.7"))) {
    _updateInfo.config.analysis.options = updateAdsConfigDFEValue(_updateInfo.config.analysis.options)
    save = true
  }

  if (_updateInfo.type === PCIE && judgeSeasimConfigGeneralValue(_updateInfo.config)) {
    const info = updateSeasimConfigInfo(_updateInfo.config);
    _updateInfo.config = info.config;
    save = info.save || save;
  }

  yield put(updateEndToEndChannelInfo(_updateInfo));
  yield put(updateConnectionModelSetup(true));
  yield put(updateEndToEndSetupMsgList({ id: _updateInfo.id, updateMsgList }));
  if (save && !setupInfo) {
    yield call(saveEndToEndChannelContentToServer);
  }
  if (save && setupInfo) {
    //error check
    const errors = endToEndErrorCheck(_updateInfo.content);
    _updateInfo.readyForSim = errors && errors.length ? 0 : 1;
    yield call(saveEndToEndChannelContent, { ..._updateInfo });
  }
  return _updateInfo;
}

function* updateEndToEndByChannelDeletedSignals(action) {
  const { endToEndId, deletedSignals, channelId, deletedComps } = action;
  const res = yield call(getEndToEndChannelContent, endToEndId);
  if (!res || !res.content) {
    return;
  }
  let { content, endToEndSignal } = _updateEndToEndByChannelDeletedSignals({ content: res.content, channelId, deletedSignals, deletedComps });
  const pcbIndex = content.pcbConnections.findIndex(item => item.channelId === channelId);
  //Update seasim config channels signal component
  if (res.type === PCIE && (pcbIndex === 0 || pcbIndex === content.pcbConnections.length - 1)) {
    res.config = updateEndToEndSeaSimCompBySignal({
      config: res.config,
      record: { name: endToEndSignal, [`PCB_${pcbIndex}`]: null },
      dataIndex: `PCB_${pcbIndex}`,
      pcbChannel: content.pcbConnections[pcbIndex],
      lastIndex: content.pcbConnections.length - 1
    });
  }

  yield call(saveEndToEndChannelContentToServer, { ...res, content });
}

function* updateEndToEndByChannelComponent(action) {
  const { endToEndId, channelId, component, compType, prevType, designId } = action;
  const res = yield call(getEndToEndChannelContent, endToEndId);
  if (!res || !res.content) {
    return;
  }

  let content = res.content;

  //update connection component
  if (prevType === CONNECTOR && compType !== CONNECTOR) {
    content = _updateEndToEndByChannelComponent({ content: res.content, channelId, component });
  }

  const pcbIndex = content.pcbConnections.findIndex(item => item.channelId === channelId);
  //Update seasim config channels component by ignore component
  if ([PCIE, HDMI, GENERIC, CPHY].includes(res.type)
    && !SERDES_TYPES.includes(compType)
    && (pcbIndex === 0 || pcbIndex === content.pcbConnections.length - 1)) {
    if (PCIE === res.type) {
      res.config = delEndToEndSeaSimCompByIgnoreComp({
        config: res.config,
        component,
        designId
      });
    }

    res.adsConfig = delEndToEndAdsCompByIgnoreComp({
      adsConfig: res.adsConfig,
      component,
      channelId,
      serdesType: res.type
    });
  }

  yield call(saveEndToEndChannelContentToServer, { ...res, content });
}

function* updateEndToEndByChannelRenamedSignal(action) {
  const { endToEndId, channelId, signal, prevSignal } = action;
  const res = yield call(getEndToEndChannelContent, endToEndId);
  if (!res || !res.content) {
    return;
  }
  let content = _updateEndToEndByRenamedSignal({ content: res.content, channelId, signal, prevSignal });
  yield call(saveEndToEndChannelContentToServer, { ...res, content });
}

function* updateEndToEndByComponents(action) {
  const { endToEndId,
    channelId,
    deletedComps,
    newComps,
    type,
    signal,
    components } = action;
  const res = yield call(getEndToEndChannelContent, endToEndId);
  if (!res || !res.content) {
    return;
  }
  let content = res.content;
  if (deletedComps.length) {
    content = _updateEndToEndByDeletedComponents({
      content,
      channelId,
      deletedComps
    });

  }
  if (newComps.length) {
    content = yield call(_updateEndToEndByAddedComponents, {
      content,
      channelId,
      newComps,
      type,
      signal
    })
  }
  const pcbIndex = content.pcbConnections.findIndex(item => item.channelId === channelId);
  //Update seasim config channels component by ignore component
  if (res.type === PCIE && (pcbIndex === 0 || pcbIndex === content.pcbConnections.length - 1)) {
    const serdesComps = components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name);
    const deletedICConnComps = deletedComps.filter(item => serdesComps.includes(item.name));
    const endToEndSignal = getEndToEndSignalNameByChannelSignal(content, pcbIndex, signal);
    if (endToEndSignal) {
      res.config = updateSeaSimConfigComp({
        config: res.config,
        signalName: endToEndSignal,
        deletedICConnComps,
        designId: content.pcbConnections[pcbIndex].designId
      });
    }
  }

  yield call(saveEndToEndChannelContentToServer, { ...res, content });
}

let autoGetChildrenListTasks = [];
function* _autoGetChildrenChannels(action) {
  const { endToEndIds, projectId } = action;
  for (let id of endToEndIds) {
    const task = yield fork(_autoGetChildrenChannelList, { endToEndId: id, projectId });
    const prevTaskIndex = autoGetChildrenListTasks.findIndex(item => item.id === id);
    if (prevTaskIndex > -1) {
      yield cancel(autoGetChildrenListTasks[prevTaskIndex].task);
      autoGetChildrenListTasks.splice(prevTaskIndex, 1);
    }
    autoGetChildrenListTasks.push({ id, task });
  }
}

function* _autoGetChildrenChannelList(action) {
  const { endToEndId, projectId } = action;

  let first = true,
    delayTime = 60000,
    isWhile = true;
  while (isWhile) {
    if (!first) {
      yield delay(delayTime);//6000
    }
    first = false;
    const { LoginReducer: { pageType }, AndesV2Reducer: { project: { openProjectId } } } = yield select();
    //if project close , return
    if (pageType !== ANDES_V2 || openProjectId !== projectId) {
      return;
    }

    const list = yield call(getSingleEndToEndChildren, { id: endToEndId });

    if (list.find(item => [VERIFY_RUNNING, WAITING].includes(item.status))) {
      delayTime = 6000
    } else {
      isWhile = false;
    }
  }
}

function* _saveConfig(action) {
  const { simulateId } = action;
  yield call(saveEndToEndChannelContentToServer);
  if (simulateId) {
    //run seasim flow
    yield put(startEndToEndSimulation([simulateId], { runSeasim: true }));
  }
}

function* _saveAdsConfig(action) {
  const { adsConfig, save, isCloseModal, id } = action;
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = yield select();
  let _endToEndChannelInfo = { ...endToEndChannelInfo };
  _endToEndChannelInfo.adsConfig = { ...adsConfig };
  yield put(updateEndToEndChannelInfo(_endToEndChannelInfo));

  if (isCloseModal) {
    yield call(saveEndToEndChannelContentToServer);
  } else if (save) {
    yield put({ type: SAVE_END_TO_END_CHANNEL_TO_SERVER });
  }

  if (id) {
    //run ads flow
    yield put(startEndToEndSimulation([id], { runAds: true }));
  }
}

function* uncheckEndToEndAdsUsePkg(action) {
  const { endToEndId, channelId, component } = action;
  const res = yield call(getEndToEndChannelContent, endToEndId);
  if (!res || !res.content) {
    return;
  }

  let adsConfig = res.adsConfig;
  if (!adsConfig || !Object.keys(adsConfig).length || !adsConfig.signals || !adsConfig.signals.length) {
    return;
  }
  let modelComp = null, modelChannelId = null;
  if ((adsConfig.controller === component
    && adsConfig.controllerChannel
    && adsConfig.controllerChannel.channelId === channelId)
    || (adsConfig.device === component
      && adsConfig.deviceChannel
      && adsConfig.deviceChannel.channelId === channelId)) {
    modelComp = component;
    modelChannelId = channelId;
  }
  if (!modelComp || !modelChannelId) {
    return
  }

  adsConfig = unCheckAmiModelUsePkg({ adsConfig, component: modelComp, channelId: modelChannelId });
  res.adsConfig = adsConfig;
  yield call(saveEndToEndChannelContentToServer, { ...res });
}

function* updateEndToEndPinMap(action) {
  const {
    endToEndId,
    component,
    pins,
    prevPin,
    newPin,
    channelId } = action;
  const res = yield call(getEndToEndChannelContent, endToEndId);
  if (!res || !res.content) {
    return;
  }

  let content = res.content;
  let connections = content.connections || [];
  //update connection component pin
  connections = updateEndToEndConnectionsPinMap({
    component,
    prevPin,
    newPin,
    channelId,
    connections
  })

  let adsConfig = res.adsConfig || {};
  //if rx model use rc termination model,update pin map
  adsConfig = updateEndToEndTerminationModelPinMap({
    endToEndId,
    component,
    pins,
    prevPin,
    newPin,
    channelId,
    adsConfig
  })

  res.adsConfig = adsConfig;
  res.content.connections = connections;
  yield call(saveEndToEndChannelContentToServer, { ...res });
}

function* _renameEndToEndChannel(action) {
  const { itemData } = action;
  const { AndesV2Reducer: { project: { openProjectId } } } = yield select();
  if (itemData && itemData.id && itemData.name) {
    try {
      yield call(saveEndToEndChannelContent, {
        id: itemData.id,
        name: itemData.name
      });
      yield put(openProject(openProjectId));
    } catch (error) {
      console.error(error);
    }
  }
}


export {
  updateEndToEndByChannelComponent,
  updateEndToEndByChannelDeletedSignals,
  saveEndToEndChannelContentToServer,
  _checkEndToEndSetup,
  updateEndToEndByComponents,
  updateEndToEndByChannelRenamedSignal,
  getEndToEndChildrenChannelList,
  uncheckEndToEndAdsUsePkg,
  updateEndToEndPinMap
}

function* endToEndChannelSaga() {
  yield takeEvery(CREATE_END_TO_END_CHANNEL, _createEndToEnd);
  yield takeEvery(GET_END_TO_END_CHANNEL_LIST, getEndToEndList);
  yield takeEvery(OPEN_END_TO_END_CHANNEL, _openEndToEndChannel);
  yield takeEvery(GET_END_TO_END_CHANNEL_CONTENT, _getContent);
  yield takeEvery(UPDATE_END_TO_END_CHANNEL_CONTENT, _updateContent);
  yield takeEvery(DELETE_END_TO_END_CHANNEL, _delEndToEndChannel);
  yield takeEvery(SAVE_CHANNEL_SELECT, _updateChannelSelect);
  yield takeEvery(SAVE_CONNECTION, _updateConnection);
  yield takeEvery(AUTO_GET_END_TO_END_CHANNEL_LIST, _autoGetEndToEndList);
  yield takeEvery(CHANGE_END_TO_END_SIGNAL_NAME, _updateSignalName);
  yield takeEvery(DELETE_END_TO_END_SIGNAL, _delSignal);
  yield takeEvery(CHANGE_SELECTED_CHANNEL_SIGNAL, _modifySelectedChannelSignal);
  yield takeEvery(ADD_END_TO_END_SIGNAL, addEndToEndConnectionSignal);
  yield takeEvery(DELETE_PCB_CONNECTION, _delPCB);
  yield takeEvery(ADD_PCB_IN_CONNECTION, _addPCB);
  yield takeEvery(COPY_END_TO_END_CHANNEL, _copyEndToEnd);
  yield takeEvery(CHECK_END_TO_END_SETUP, _checkEndToEndSetup);
  yield takeEvery(AUTO_GET_END_TO_END_CHILDREN_CHANNEL_LIST, _autoGetChildrenChannels);
  yield takeEvery(SAVE_SEASIM_CONFIG, _saveConfig);
  yield takeEvery(UPDATE_ADS_CONFIG, _saveAdsConfig);
  yield takeEvery(END_TO_END_CHANNEL_RENAME, _renameEndToEndChannel);
}

export default endToEndChannelSaga;