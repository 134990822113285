import { ANDES, ANDES_V2, FASTPI, ROCKY, SIERRA, CASCADE } from "../../constants/pageType";
import { PROJECT_V2 } from "../../constants/projectVersion";
import { IMPEDANCE, PCB_CHANNEL, ROCKY_CHANNEL, VERIFICATION } from "../../constants/treeConstants";

/**
 * get ccc specify service default select verification
 * @param {Array} list verification list [{ }]
 *  [{
 *   type: PCB_CHANNEL, //ROCKY_CHANNEL
    name: "PCB Channel",
    subType: "Design", //CHANNEL
    subList: [{
      id,
      name,//design , // rocky channel
      verificationList:[],//channels or verifications
    }]
 * },
  {
 *   type: END_TO_END_CHANNEL, // VERIFICATION
    name: "Multi-PCB Channel",
    verificationList:[],//verifications
 * }]
 * @param {Object} currentVerification { id ,name, verificationId,specifyServiceType:'PCB_channel',designId,... }
*  */
function getDefaultSpecifyService({ list, currentVerification, projectVersion }) {
  let selectType = {
    type: "",
    name: ""
  }, subSelected = {
    type: "",
    id: "",
    name: ""
  }, verification = {
    id: "",
    name: "",
    verificationId: ""
  }, verificationType = "Interface", verificationList = [], selectTypeList = [], subSelectList = [];
  if (list.length > 0) {
    selectTypeList = [...list];
    const defaultV = list.find(item => currentVerification && item.type === currentVerification.specifyServiceType) || list[0];
    selectType.type = defaultV.type;
    selectType.name = defaultV.name;
    verificationType = defaultV.verificationType;

    if ([PCB_CHANNEL, ROCKY_CHANNEL].includes(defaultV.type)) {
      subSelected.type = defaultV.subType;
      subSelectList = [...defaultV.subList];
      let subObj = {};
      switch (defaultV.type) {
        case PCB_CHANNEL:
          subObj = defaultV.subList.find(item => currentVerification && item.id === currentVerification.designId) || defaultV.subList[0];
          break;
        case ROCKY_CHANNEL:
          subObj = projectVersion === PROJECT_V2 ? currentVerification : defaultV.subList.find(item => currentVerification && item.id === currentVerification.channelId) || defaultV.subList[0];
          break;
        default: break;
      }
      if (subObj) {
        subSelected.name = subObj.name;
        subSelected.id = subObj.id;
        subSelected.dataType = subObj.dataType || "";
        subSelected.designName = subObj.designName || "";
        subSelected.verificationId = projectVersion === PROJECT_V2 ? subObj.verificationId : "";
        verificationList = subObj.verificationList || [];
      }
    } else if (defaultV && defaultV.verificationList && defaultV.verificationList.length) {
      verificationList = [...defaultV.verificationList]
    }
  }

  if (currentVerification && projectVersion !== PROJECT_V2) {
    verification = {
      id: currentVerification.id,
      name: currentVerification.name,
      verificationId: currentVerification.verificationId
    }
  }
  return {
    selectType,
    selectTypeList,
    subSelected,
    subSelectList,
    verificationList,
    verification,
    verificationType
  }
}

function getCCCVerifications(verificationList, type) {
  const _type = type === FASTPI ? "PDN" : "Interface";
  return [{
    type: VERIFICATION,
    name: _type,
    verificationType: _type,
    verificationList: [...verificationList]
  }]
}

function getCCCSpecifyServiceVerification(verificationId, verificationList, type) {
  if (!verificationId) {
    return null;
  }

  let verification = null, specifyServiceType = VERIFICATION;
  if (type === FASTPI) {
    verification = verificationList.find(item => item.verificationId === verificationId);
  } else {
    verification = verificationList.find(item => item.id === verificationId);
  }

  return verification ? { ...verification, specifyServiceType } : null;
}

const ANSYS = "Ansys", TRANSLATION = "Translation",
  PCB_PROCESSING = "Pcb_Processing", SIMULATION = "Simulation",
  SYNOPSYS = "Synopsys",//SYNOPSYS===HSPICE
  ADS = "ADS", CADENCE = "Cadence";
function getTaskServerList(serverListSimple, serviceSelectList, pageType, verificationType, setupType, projectVersion, subSelected) {
  //Filter translation and pcb processing
  const _serverListSimple = serverListSimple.filter(item => ![TRANSLATION, PCB_PROCESSING].includes(item.taskType));
  //get selected task list
  const selectedTasks = serviceSelectList.map(item => item.task);
  let includesTasks = [];
  switch (pageType) {
    case ANDES:
      includesTasks = [ANSYS];
      break;
    case ANDES_V2:
      includesTasks = [ANSYS, SIMULATION, ADS];//multi-pcb channel
      if (verificationType === "PCB Channel") {
        //pcb channel only need ansys
        includesTasks = [ANSYS, ADS]
      }
      break;
    case CASCADE:
      includesTasks = [ANSYS];
      if ([IMPEDANCE, "impedance"].includes(setupType)) {
        includesTasks = [ANSYS, CADENCE, SIMULATION];
      }
      break;
    case FASTPI:
      includesTasks = [ANSYS, CADENCE, SIMULATION];
      break;
    case SIERRA:
      includesTasks = [ANSYS, SIMULATION, SYNOPSYS];
      break;
    case ROCKY:
      includesTasks = projectVersion === PROJECT_V2 ? [ANSYS, SYNOPSYS] : [ANSYS, SIMULATION, SYNOPSYS];
      if (projectVersion === PROJECT_V2 && ["PCB", "Package", "Card"].includes(verificationType)) {
        includesTasks = [ANSYS];
      }
      if (projectVersion === PROJECT_V2 && verificationType === "Interface") {
        if (subSelected && subSelected.name && subSelected.name.includes("(Merge)")) {
          includesTasks = [ANSYS];
        } else {
          includesTasks = [SYNOPSYS];
        }

      }
      break;
    default: break;
  }
  //filter tasks by selectedTasks and page type includes tasks
  return _serverListSimple.filter(item => includesTasks.includes(item.taskType) && !selectedTasks.includes(item.taskType));
}

export {
  getDefaultSpecifyService,
  getCCCVerifications,
  getCCCSpecifyServiceVerification,
  getTaskServerList,
  SYNOPSYS
}