import http from './http';

export function getInterfacesList({ projectId, type = 'PCIe', designName }) {
  return http.get(`/${projectId}/services/serdes/interfaces`, {
    params: { type, designName }
  })
}

export function saveConfig({ projectId, config, name, type, designName }) {
  return http.post(`/${projectId}/services/serdes/upload/config`, {
    content: JSON.stringify(config),
    designName,
    name,
    type,
  })
}

export function getInterfaceContent({ projectId, interfaceName, designName, type }) {
  return http.get(`/${projectId}/services/serdes/interfaces/content`, {
    params: {
      interfaceName,
      designName,
      type
    }
  })
}

export function deleteInterface({ projectId, designName, name, type }) {
  return http.delete(`/${projectId}/services/serdes/interface`, {
    data: {
      designName,
      name,
      type
    }
  })
}

export function renameInterface({ projectId, type, designName, originName, updateName }) {
  return http.put(`/service/serdes/${type}/rename/${projectId}`, {
    designName,
    originName,
    updateName
  })
}

export function serdesSimulation({ projectId, designName, name, type }) {
  return http.post(`/${projectId}/services/serdes/simlation`, {
    designName,
    name,
    type
  })
}

export function generateReport({ projectId, interfaceName, type }) {
  return http.post(`/services/serdes/${type}/report/${projectId}?interfaceName=${interfaceName}`);
}