import React, { Component, Fragment } from 'react';
import './index.css';

class BottomLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newY: 0,
      preY: 0,
      height: props.height,
      shapeBottom: props.height,
      delta: 0,
      display: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.height !== this.props.height) {
      this.setState({
        shapeBottom: this.props.height,
      })
    }
  }

  changeShapeStart(e) {
    e.stopPropagation();
    const { clientY } = e;
    const { resize } = this.props;
    const display = resize ? true : false;
    this.setState({
      preY: clientY,
      height: this.props.height,
      display: display
    })
  }

  changeShape(e) {
    e.stopPropagation();
    const { clientY } = e;
    this.setState((preState) => {
      if (clientY === 0) {
        return {
          newY: preState.newY
        }
      } else {
        return {
          newY: clientY
        }
      }
    }, () => {
      const { newY, preY, height } = this.state;
      let delta = preY - newY;
      if (this.props.negative) {
        delta = -delta;
      }
      let newHeight = height + delta;
      if (this.props.changeHeight) {
        this.props.changeHeight(newHeight);
      }
      this.setState({ delta, shapeBottom: newHeight });
    })
  }

  changeShapeEnd(e) {
    e.stopPropagation();
    e.preventDefault();
    const height = this.state.height + this.state.delta;
    this.setState({ height, display: false });
    if (this.props.resize) {
      this.props.resize(height);
    }
  }

  setStyle() {
    const { floatSide, position, resize } = this.props;
    let { shapeBottom } = this.state;
    if (resize) {
      if (position.minHeight && shapeBottom < position.minHeight) {
        shapeBottom = position.minHeight;
      }
      if (position.maxHeight && shapeBottom > position.maxHeight) {
        shapeBottom = position.maxHeight;
      }

      if (floatSide) {
        return {
          float: floatSide,
        }
      } else if (position) {
        return {
          position: position.position,
          bottom: shapeBottom
        }
      }
    } else {
      if (floatSide) {
        return {
          float: floatSide,
        }
      } else if (position) {
        return {
          position: position.position,
          bottom: position.bottom
        }
      }
    }

  }

  render() {
    let style = this.setStyle();
    const { display } = this.state;
    const { resize } = this.props;
    const draggingStyle = display && resize ? {
      background: '#e2dfdf',
      height: 4,
      zIndex: 9999,
    } : {
      zIndex: 9999,
      height: 4,
    };
    const defaultClass = !display && !resize ? 'defaultClass' : 'bottomLineStyle';
    const defaultStyle = !display && !resize ? {
      height: 40,
      zIndex: 1
    } : {}
    return (
      <Fragment>
        <div
          className={defaultClass}
          draggable="true"
          onDragStart={(e) => this.changeShapeStart(e)}
          onDrag={(e) => this.changeShape(e)}
          onDragEnd={(e) => this.changeShapeEnd(e)}
          style={{ ...style, ...draggingStyle, ...defaultStyle }}
        >
        </div>
        <div
          className='bottom-line-hover'
          style={{ ...style, bottom: style.bottom - 7 }}
        />
        <div
          className={`bottom-line-border`}
          style={{ ...style }}
        />
      </Fragment>

    )
  }
}

export default BottomLine;
