import {
  VERIFY_NEVER,
  VERIFY_RUNNING,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  VERIFY_CANCEL,
  WAITING
} from '@/constants/verificationStatus';

function getVerificationStatus(status, readyForSim, verification) {
  let verificationStatus = '', msg = '', color = '';
  if (verification.simStatus) {
    switch (verification.simStatus) {
      case 'checking':
        verificationStatus = 'loading3'; msg = 'Checking for simulation'; color = '#ff5500'; break;
      case 'error':
        verificationStatus = 'N'; msg = 'Not Prepared'; color = '#7b7e8c'; break;
      case 'success':
        verificationStatus = 'tick'; msg = 'Checked Successful'; color = '#4dbd16'; break;
      case VERIFY_FAILED:
      default: verificationStatus = 'tick'; msg = 'Preparing for simulation'; color = '#4dbd16'; break;
    }
  } else {
    switch (status) {
      case VERIFY_NEVER:
        if (readyForSim === 0) {
          verificationStatus = 'N';
          msg = 'Not Prepared';
          color = '#7b7e8c';
        } else if (readyForSim === 1) {
          verificationStatus = 'P';
          msg = 'Prepared for simulation';
          color = '#e850dc';
        }
        break;
      case VERIFY_RUNNING:
        verificationStatus = 'R'; msg = 'Running'; color = '#31aaf3'; break;
      case VERIFY_SUCCESS:
        verificationStatus = 'S'; msg = 'Success'; color = '#4dbd16'; break;
      case VERIFY_FAILED:
        verificationStatus = 'F'; msg = 'Failed'; color = '#b32222e8'; break;
      case VERIFY_CANCEL:
        verificationStatus = 'C'; msg = 'Cancel'; color = '#9d7ace'; break;
      case WAITING:
        verificationStatus = 'W'; msg = 'Waiting'; color = '#ff5500'; break;
      default: break;
    }

    if (readyForSim === 0) {
      verificationStatus = 'N';
      msg = 'Not Prepared';
      color = '#7b7e8c';
    }
  }
  return { verificationStatus, msg, color }
}

export { getVerificationStatus };