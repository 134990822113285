import React, { Component, createRef, Fragment } from 'react';
import ResultLayout from '@/services/Result/Public/resultLayout';
import SparameterSetting from '@/services/Result/Public/sparameter/SparameterSetting';
import parameters from '@/services/Result/Public/sparameter/parameters';
import {
  axisFormat, portChange, displayCurve, getColor, ImpedanceResultListDefault, removeCurves,
  changeWidthCallBack, updateRangeCallBack, axisChangeCallBack,
  changeCurveColor, updatePlotColor, getMouseMoveYAxisValue, cancelMove,
  getSelectAllinSignalCurves, showCurves
} from '@/services/Result/Public/sparameter/dataHelper';
import { DeleteFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import { CASCADE } from '@/constants/pageType';
import { strDelimited } from '@/services/helper/split';
import ResultList, { RESULT, HISTORY, IMPORT, COMPARE } from '@/components/Sparameter/resultList';
import { rowDisplayChange } from '@/services/Result/Public/sparameter/resultsMenuHelper';
import {
  getTargetFile,
  getImpedanceJson,
  getImpedanceResultMaxFreq,
  getImpedanceRlMinAndRlMax,
  updateTargetFrequency,
  importImpedanceSnp,
  getImpedanceHasResult
} from '@/services/Cascade/Impedance';
import { parseTargetFile } from '@/services/helper/parseFile';
import ResultRightMenu from '@/services/Result/Public/resultRightMenu';
import ImpedanceParameterData from '@/services/Cascade/Impedance/parameterData';
import makeCancelable from '@/services/api/makeCancelable';
import { IMPEDANCE_DIE, IMPEDANCE_PACKAGE, TARGET_RL_VALUE, TARGET_TOUCHSTONE } from '../../../../../services/Cascade/constants';
import { calcFrequencyPoints, getImpedanceOptDecap, getImpedanceOptList } from '../../../../../services/Cascade/Impedance';
import { unitChange } from '../../../../../services/helper/mathHelper';
import photo from '@/components/PublicSvg/photo.svg';
import saveSvg from 'save-svg-as-png';
/* import ResultZoom from '../../../../../components/ResultTopMenu/resultZoom'; */
import { MULTI_PCB_TARGET } from '../../../../../constants/uncategorized';
import OptimizationPanel from '../optimizationPanel';
import '../index.css';

let sparameter = new parameters();

const defaultState = {
  portSelect: {}, // { id: { port: ["id:0:0"] } }
  loading: false,
  files: [],
  resultsObj: {}, // { Default: [] },
  historyObj: {}, // { Default: [] },
  importObj: {}, // { Default: [] },
  compareObj: {},
  portObject: {}, // { id: [{ comp, compType, display, fileId, impedance, index, name, portType, signal }] }
  displayMode: 'Default',
  optResult: [],
  optResultVisibvle: false
}

class SparameterResult extends Component {
  constructor() {
    super()
    this.svgRef = createRef();
    this.state = {
      ...defaultState,
      successList: [],
      compareIds: []
    }
    this.plot2d = null;
    this.resize = this.resize.bind(this);
    this.historyContent = new Map();
    this.importContent = new Map();
  }

  onRef = (ref) => {
    this.sparameterSetting = ref;
  }

  resultRightMenuOnRef = (ref) => {
    this.resultRightMenu = ref;
  }

  getXUnit = () => {
    return this.sparameterSetting.getXUnit();
  }

  getCurrentSelects = () => {
    let { portSelect } = this.state;
    return Object.values(portSelect).reduce((a, b) => {
      return Object.values(a).concat(...Object.values(b));
    }, []);
  }

  changeMouse = (x) => {
    try {
      let { files, resultsObj, importObj, displayMode, historyObj, compareObj } = this.state;
      const axis = this.getAxis();
      const yScale = axis.yScale;
      const selectArr = this.getCurrentSelects();
      let _historyObj = { ...historyObj };
      let _importObj = { ...importObj };
      let _compareObj = { ...compareObj };
      Object.keys(historyObj).forEach(id => {
        _historyObj[id] = getMouseMoveYAxisValue({ resultsObj: _historyObj[id], displayMode, selectArr, files, x, setting: this.getSetting(), yScale })
      })
      Object.keys(importObj).forEach(id => {
        _importObj[id] = getMouseMoveYAxisValue({ resultsObj: _importObj[id], displayMode, selectArr, files, x, setting: this.getSetting(), yScale })
      })
      Object.keys(compareObj).forEach(id => {
        _compareObj[id] = getMouseMoveYAxisValue({ resultsObj: _compareObj[id], displayMode, selectArr, files, x, setting: this.getSetting(), yScale })
      })
      const _resultsObj = resultsObj && Object.keys(resultsObj).length ? getMouseMoveYAxisValue({ resultsObj, displayMode, selectArr, files, x, setting: this.getSetting(), yScale }) : resultsObj;
      this.setState({
        resultsObj: _resultsObj,
        historyObj: _historyObj,
        importObj: _importObj,
        compareObj: _compareObj
      });
    } catch (error) {
      console.error(error)
    }
  }

  cancelMove = () => {
    let { resultsObj, displayMode, historyObj, importObj, compareObj } = this.state;
    const selectArr = this.getCurrentSelects();
    let _historyObj = { ...historyObj };
    let _importObj = { ...importObj };
    let _compareObj = { ...compareObj }
    Object.keys(historyObj).forEach(id => {
      _historyObj[id] = cancelMove({ resultsObj: _historyObj[id], displayMode, selectArr })
    })
    Object.keys(importObj).forEach(id => {
      _importObj[id] = cancelMove({ resultsObj: _importObj[id], displayMode, selectArr })
    })
    Object.keys(compareObj).forEach(id => {
      _compareObj[id] = cancelMove({ resultsObj: _compareObj[id], displayMode, selectArr })
    })
    this.setState({
      resultsObj: cancelMove({ resultsObj, displayMode, selectArr }),
      historyObj: _historyObj,
      importObj: _importObj,
      compareObj: _compareObj
    });
  }

  changeAxis = (axis) => {
    const xUnit = this.getXUnit();
    this.sparameterSetting.setAxis(axisFormat(xUnit, axis));
  }

  componentDidMount() {
    this.screenChange();
    if (this.props.id) {
      this.getResult();
    }
  }

  componentDidUpdate(prevProps) {
    const { id, historys, updateHistory, updateImports, updateCompare } = this.props;
    if (id !== prevProps.id) {
      this.getResult();
      if (this.selectAllCancelAble) {
        this.selectAllCancelAble.cancel();
      }
    }

    if (historys.length !== prevProps.historys.length || updateHistory !== prevProps.updateHistory) {
      this.getHistoryResult();
    }

    if (updateImports !== prevProps.updateImports) {
      this.getImportResult();
    }

    if (updateCompare !== prevProps.updateCompare) {
      this.getCompareResult()
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize() {
    this.resultRightMenu.updateFileListState();
  }

  initResult = () => {
    this.interfaceContent = null;
    sparameter = new parameters();
    if (this.plot2d) {
      if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
        this.svgRef.current.removeChild(this.svgRef.current.children[0]);
      }
    }
    this.plot2d = null;
    this.setState({
      ...defaultState,
      resultsObj: {}
    })
  }

  getResult = () => {
    this.initResult();
    const { id } = this.props;
    if (!id) return;
    this.setState({
      loading: true,
      portSelect: {},
    }, async () => {
      try {
        const { ImpedanceContent = [], id } = this.props;
        if (!ImpedanceContent.length) {
          this.setState({
            loading: false
          })
          const events = {
            changeMouse: this.changeMouse,
            cancelMove: this.cancelMove,
            changeAxis: this.changeAxis,
          }
          const { parameter, value } = this.getSetting();
          this.plot2d = sparameter.createPlot(this.svgRef.current, { param: parameter, value }, events, CASCADE);
          return;
        }
        try {
          this.sparameterSetting.setSetting({ parameter: 'Z', value: 'am' });
          this.sparameterSetting.axisChange('x', 'log')
          this.impedanceResultContent = await getImpedanceJson(id);
          const { rlMin, rlMax } = getImpedanceRlMinAndRlMax(this.impedanceResultContent)
          const maxFreq = getImpedanceResultMaxFreq(this.impedanceResultContent);
          this.setState({
            maxFreq,
            rlMin,
            rlMax
          })
          await this.renderSimulationResult();
          this.getHistoryResult();
          this.getImportResult();
          this.getCompareResult();
        } catch (error) {
          console.error(error)
        }
        this.setState({
          loading: false
        })
      } catch (error) {
        this.setState({
          loading: false
        })
      }
    })
  }

  getSetting = () => {
    return this.sparameterSetting.getSetting();
  }

  getAxis = () => {
    return this.sparameterSetting.getAxis();
  }

  initPorts = () => {
    const { ImpedanceContent } = this.props;
    let portList = [];
    const content = this.impedanceResultContent ? this.impedanceResultContent.layouts : null;
    for (let tar of (content || ImpedanceContent || [])) {
      for (let domain of (tar.powerDomains || [])) {
        const { id, content } = domain;
        const { PowerNets = [], ports = [], diePorts = [], bgaPorts = [] } = content || {};
        if (ports && ports.length) {
          portList = [...portList, ...ports.map(item => {
            return {
              powerDomainId: id,
              port: item.port,
              portName: item.portName,
              powerPins: [...item.powerPins],
              PowerNets: [...PowerNets]
            }
          })]
        }
        if (diePorts && diePorts.length) {
          portList = [...portList, ...diePorts.map(item => {
            return {
              powerDomainId: id,
              port: item.port,
              portName: item.portName,
              powerPins: [...item.powerPins],
              PowerNets: [...PowerNets]
            }
          })]
        }
        if (bgaPorts && bgaPorts.length) {
          portList = [...portList, ...bgaPorts.map(item => {
            return {
              powerDomainId: id,
              port: item.port,
              portName: item.portName,
              powerPins: [...item.powerPins],
              PowerNets: [...PowerNets]
            }
          })]
        }
      }
    }
    return portList;
  }

  initTargets = async () => {
    const { ImpedanceContent = [], target: multiTarget = [] } = this.props;
    const { maxFreq, rlMin, rlMax } = this.state;
    const targets = [];

    const targetHandle = async (t, id) => {
      const { targetType, frequencyPoints, fileName, powerPins = [], portName, inductance = {}, resistance = {}, libraryId, targetName } = t;
      let points = null;
      if (targetType === TARGET_TOUCHSTONE) {
        const file = await getTargetFile(libraryId ? { libraryId } : { powerDomainId: id, targetFileName: fileName });
        points = parseTargetFile(file);
      } else if (targetType === TARGET_RL_VALUE) {
        points = JSON.parse(JSON.stringify(frequencyPoints));
        const pointsMax = points[points.length - 1].frequency;
        if (rlMin || rlMax) {
          const res = unitChange({ num: resistance.value, oldUnit: resistance.unit, newUnit: 'Ω' }).number;
          const ind = unitChange({ num: inductance.value, oldUnit: inductance.unit, newUnit: 'H' }).number;
          points = calcFrequencyPoints(res, ind, rlMax, rlMin);
        }
        if (pointsMax < maxFreq) {
          const res = unitChange({ num: resistance.value, oldUnit: resistance.unit, newUnit: 'Ω' }).number;
          const ind = unitChange({ num: inductance.value, oldUnit: inductance.unit, newUnit: 'H' }).number;
          points = calcFrequencyPoints(res, ind, maxFreq);
        }
      } else {
        points = JSON.parse(JSON.stringify(frequencyPoints));
        points = updateTargetFrequency(points, maxFreq);
      }
      const frequency = points.map(item => Number(item.frequency));
      const impedance = points.map(item => Number(item.impedance));
      return { frequency, impedance, powerPins, portName: portName, targetName }
    }

    if (ImpedanceContent.length > 1) {
      let axis = [];
      for (let t of [...multiTarget]) {
        const _t = await targetHandle(t, 'overview')
        axis.push(_t);
      }
      targets.push({ power: MULTI_PCB_TARGET, axis });
      return targets
    }

    for (let layout of ImpedanceContent) {
      if ([IMPEDANCE_DIE, IMPEDANCE_PACKAGE].includes(layout.type)) {
        continue;
      }
      for (let tar of layout.powerDomains) {
        const { id, content } = tar;
        const { PowerNets = [], target = [], ports = [], Components = [] } = content || {};
        const loadPorts = Components.filter(comp => comp.loadPorts).map(item => item.loadPorts).flat(2)
        const loadPortsHasTarget = loadPorts.filter(item => item.target && item.target.length)
        const portsHasTarget = ports.filter(item => item.target && item.target.length);
        if (!target || !PowerNets.length || (!target.length && !portsHasTarget.length && !loadPortsHasTarget.length)) {
          continue;
        }
        let axis = [];
        let portTargets = [];
        for (let item of [...portsHasTarget, ...loadPortsHasTarget]) {
          //remove target [null]
          if (!item || !item.target.filter(it => !!it).length) {
            continue;
          }
          const target = JSON.parse(JSON.stringify(item.target));
          portTargets.push(...target.map(it => {
            it.port = item.port;
            it.powerPins = [...item.powerPins];
            it.portName = item.portName;
            return it;
          }));
        }

        for (let t of [...portTargets, ...target]) {
          const _t = await targetHandle(t, id)
          axis.push(_t);
        }
        const index = targets.findIndex(item => item.power === PowerNets[0]);
        if (index > -1) {
          targets[index].axis = [...targets[index].axis, ...axis];
        } else {
          targets.push({ power: PowerNets[0], axis });
        }
      }
    }
    return targets
  }

  renderSimulationResult = async () => {
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis,
    }
    const { id } = this.props;
    const { parameter, value } = this.getSetting();
    this.plot2d = sparameter.createPlot(this.svgRef.current, { param: parameter, value }, events, CASCADE);
    const targets = await this.initTargets();
    const portList = this.initPorts();
    sparameter.initParameter(id, new ImpedanceParameterData({ verificationId: id, type: 'simulation', portList, targets }));
    const file = sparameter.getParameter(id);
    const res = await this.getAllNpi([file], id, this.impedanceResultContent ? this.impedanceResultContent.layouts : null);
    const optList = await getImpedanceOptList(id)
    if (!res || !res[0].ports) { return; }
    let { portObject, files } = this.state;
    portObject[res[0].id] = res[0].ports.map(d => ({ ...d, fileId: res[0].id }));
    this.setState({
      files: [...files, ...res],
      portObject: portObject,
      optList
    }, () => {
      this.getResultsByType(res[0].id)
    })
  }

  getHistoryResult = async () => {
    const { historys } = this.props;
    if (!historys || !historys.length) { return; }
    const params = historys.map(d => {
      sparameter.initParameter(d.id, new ImpedanceParameterData({ verificationId: d.id, type: HISTORY, targets: [], colorType: 'light' }))
      return sparameter.getParameter(d.id);
    });
    const res = (await this.getAllHistoryNpi(params)).filter(d => !!d);
    const files = this.state.files;
    res.forEach(
      file => {
        file.rowName = historys.find(item => item.id === file.id).name;
        file.rowId = file.id;
        const find = files.find(item => item.id === file.id);
        file.show = find && find.show ? true : false;;
      })
    let _portObject = {};
    res.forEach(d => d.ports ? _portObject[d.id] = d.ports.map(_d => ({ ..._d, fileId: d.id })) : [])

    this.setState((prevState) => {
      const filterSimulation = prevState.files.filter(d => d.type !== HISTORY);
      return {
        files: [...filterSimulation, ...res],
        portObject: { ...prevState.portObject, ..._portObject }
      }
    }, () => {
      let _historyObj = this.state.historyObj;
      let prevHistoryObj = JSON.parse(JSON.stringify(_historyObj));
      res.forEach(d => {
        let general = ImpedanceResultListDefault({}, this.state.portObject[d.id], this.getColorByIndex, HISTORY);
        let prevShow = prevHistoryObj[d.id] && prevHistoryObj[d.id]['Default'] ? prevHistoryObj[d.id]['Default']['show'] : []
        if (prevShow && prevShow.length) {
          let newList = general.Default.list;
          newList = newList.map(item => item.rowName);
          general.Default.show = prevShow.filter(row => newList.includes(row))
        } else {
          general.Default.show = [];
        }
        if (!_historyObj[d.id]) {
          _historyObj[d.id] = {}
        }
        _historyObj[d.id]['Default'] = general.Default;
        this.setState({
          historyObj: _historyObj
        })
      })
    })
  }

  getImportResult = async () => {
    const { imports } = this.props;
    if (!imports || !imports.length) { return; }
    const params = imports.map(d => {
      sparameter.initParameter(d.id, new ImpedanceParameterData({ verificationId: d.id, type: IMPORT, targets: [], status: d.status, colorType: 'light' }))
      return sparameter.getParameter(d.id);
    });

    const res = (await this.getAllImportNpi(params)).filter(d => !!d);
    const files = this.state.files;
    res.forEach(
      file => {
        file.rowName = imports.find(item => item.id === file.id).name;
        file.rowId = file.id;
        const find = files.find(item => item.id === file.id);
        file.show = find && find.show ? true : false;
      })
    let _portObject = {};
    res.forEach(d => d.ports ? _portObject[d.id] = d.ports.map(_d => ({ ..._d, fileId: d.id })) : [])
    this.setState((prevState) => {
      const filterSimulation = prevState.files.filter(d => d.type !== IMPORT);
      return {
        files: [...filterSimulation, ...res],
        portObject: { ...prevState.portObject, ..._portObject }
      }
    }, () => {
      let _importObj = this.state.importObj;
      let prevImportObj = JSON.parse(JSON.stringify(_importObj));
      res.forEach(d => {
        let general = ImpedanceResultListDefault({}, this.state.portObject[d.id], this.getColorByIndex, IMPORT);
        let prevShow = prevImportObj[d.id] && prevImportObj[d.id]['Default'] ? prevImportObj[d.id]['Default']['show'] : []
        if (prevShow && prevShow.length) {
          let newList = general.Default.list;
          newList = newList.map(item => item.rowName);
          general.Default.show = prevShow.filter(row => newList.includes(row))
        } else {
          general.Default.show = [];
        }
        if (!_importObj[d.id]) {
          _importObj[d.id] = {}
        }
        _importObj[d.id]['Default'] = general.Default;
      })
      this.setState({
        importObj: _importObj
      })
    })
  }

  getCompareResult = async () => {
    const { compares = [] } = this.props;
    this.setState({
      compareIds: [...compares.map(i => i.verificationId)]
    }, async () => {
      let _res = [], _historys = []
      let _portObject = {};
      for (let compare of compares) {
        const { historys, verificationId } = compare
        sparameter.initParameter(verificationId, new ImpedanceParameterData({ verificationId, type: 'simulation', targets: [], colorType: 'dark' }));
        const file = sparameter.getParameter(verificationId);
        const compareContent = await getImpedanceJson(verificationId);
        const res = await this.getAllNpi([file], verificationId, compareContent ? compareContent.layouts : null);
        if (!res || !res[0].ports) { return; }
        let { files } = this.state;
        _portObject[res[0].id] = res[0].ports.map(d => ({ ...d, fileId: res[0].id }));
        res.forEach(item => {
          item.type = `${verificationId}-${COMPARE}`;
          item.basicType = 'simulation'
          item.rowName = 'Current';
          item.rowId = verificationId;
          const find = files.find(item => item.id === file.id);
          file.show = find && find.show ? true : false;
        })

        let historyRes = []
        if (historys && historys.length) {
          let params = []
          for (let d of historys) {
            sparameter.initParameter(d.historyId, new ImpedanceParameterData({ verificationId: d.historyId, type: HISTORY, targets: [], colorType: 'dark' }))
            params.push(sparameter.getParameter(d.historyId))
          }

          historyRes = (await this.getAllHistoryNpi(params)).filter(d => !!d);
          historyRes.forEach(
            file => {
              file.rowName = historys.find(item => item.historyId === file.id).historyName;
              file.rowId = file.id;
              file.type = `${verificationId}-${COMPARE}`
              file.basicType = HISTORY
              const find = files.find(item => item.id === file.id);
              file.show = find && find.show ? true : false;;
            })
          historyRes.forEach(d => d.ports ? _portObject[d.id] = d.ports.map(_d => ({ ..._d, fileId: d.id })) : []);
        }
        _res.push(...res)
        _historys.push(...historyRes)
      }
      this.setState(() => {
        let { portObject, files } = this.state;
        const filterSimulation = files.filter(d => !d.type.match(COMPARE));
        return {
          files: [...filterSimulation, ..._res, ..._historys],
          portObject: { ...portObject, ..._portObject }
        }
      }, () => {
        let _compareObj = this.state.compareObj;
        let prevCompareObj = JSON.parse(JSON.stringify(_compareObj));
        [..._res, ..._historys].forEach(d => {
          let general = ImpedanceResultListDefault({}, this.state.portObject[d.id], this.getColorByIndex, COMPARE);
          let prevShow = prevCompareObj[d.id] && prevCompareObj[d.id]['Default'] ? prevCompareObj[d.id]['Default']['show'] : []
          if (prevShow && prevShow.length) {
            let newList = general.Default.list;
            newList = newList.map(item => item.rowName);
            general.Default.show = prevShow.filter(row => newList.includes(row))
          } else {
            general.Default.show = [];
          }
          if (!_compareObj[d.id]) {
            _compareObj[d.id] = {}
          }
          _compareObj[d.id]['Default'] = general.Default;
        })
        this.setState({
          compareObj: { ...prevCompareObj, ..._compareObj }
        })
      })
    })
  }

  getResultsByType = (id) => {
    this.defaultResultList(id);
  }

  defaultResultList = (id) => {
    let { resultsObj, portObject, optList } = this.state;
    if (!portObject[id]) return;
    const _resultList = ImpedanceResultListDefault(resultsObj, portObject[id], this.getColorByIndex, RESULT, optList);
    this.setState({
      resultsObj: _resultList
    }, () => {
      this.defaultDisplay(_resultList);
    })
  }

  defaultDisplay = (resultList) => {
    try {
      const defaultList = resultList['Default'].list.length ? resultList['Default'].list[0] : null;
      if (!defaultList) return;
      const defaultKey = defaultList.rowName;
      const values = defaultList.children[0].id;
      this.changePort([values], defaultKey, defaultList.children[0].fileId);
    } catch (error) {
      console.error(error)
    }
  }

  _historyRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.historyObj[rowId] = rowDisplayChange(prevState.historyObj[rowId], displayMode, value)
      return {
        historyObj: prevState.historyObj
      }
    })
  }

  importRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.importObj[rowId] = rowDisplayChange(prevState.importObj[rowId], displayMode, value)
      return {
        importObj: prevState.importObj
      }
    })
  }

  compareRowNameClick = (displayMode, value, rowId) => {
    this.setState((prevState) => {
      prevState.compareObj[rowId] = rowDisplayChange(prevState.compareObj[rowId], displayMode, value)
      return {
        compareObj: prevState.compareObj
      }
    })
  }

  changePort = (values, key, id) => {
    let { portSelect } = this.state;
    const res = portChange({ portSelect: portSelect[id], values, key })
    if (!res) return;
    const { _portSelect, uncheck, newCheck } = res;
    const _fileId = strDelimited(id, "::")[0];
    this.updatePortSelect(_portSelect, _fileId);
    if (uncheck) {
      const [fileId, row, col, hashId, curveType, wordIndex] = strDelimited(uncheck, "::");
      if (curveType && wordIndex) {
        sparameter.removeCurve({ row, col, hashId, id: fileId, curveType, wordIndex });
      } else {
        sparameter.removeCurve({ row, col, hashId, id: fileId });
      }
      return;
    };

    if (newCheck) {
      this.showCurve(newCheck);
    }
  }

  showCurve = async (newCheck) => {
    const { files, displayMode } = this.state;
    const [fileId, row, col, hashId, curveType, wordIndex] = strDelimited(newCheck, "::");
    const setting = this.getSetting();
    const axis = this.getAxis()
    const { updateFiles: newFiles, curve, color } = await displayCurve({ files, fileId, row, col, hashId, Parameters: sparameter, setting, curveType, wordIndex })
    if (!newFiles) return;
    if (displayMode === this.state.displayMode) {
      this.plot2d.updateCurves(curve, true);
      this.plot2d.resetPlot();
      this.plot2d.resetColor({ id: fileId, row, col, color: color });
      if (sparameter.getSize() > 0) {
        sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    }

    this.setState((prevState) => {
      let files = [...prevState.files];
      newFiles.forEach(fileItem => {
        const findIndex = files.findIndex(d => d.id === fileItem.id);
        if (findIndex > -1) {
          files[findIndex] = fileItem;
        }
      })
      return {
        files: [...files]
      }
    })
  }

  getAllNpi = (files, id, content) => {
    const { ImpedanceContent } = this.props;
    let data = [...(content || ImpedanceContent)];
    let params = id
    const promiseList = files.map((item, index) => {
      return item.getNpiFile(index, params, data);
    })
    return Promise.all(promiseList);
  }

  getAllHistoryNpi = async (historyParams) => {
    const { files } = this.state;
    const promiseList = await historyParams.map(async (item, index) => {
      const { ImpedanceContent } = this.props;
      let data = [...ImpedanceContent];
      if (ImpedanceContent) {
        this.historyContent.set(item.id, data);
        return item.getNpiFile(
          files.length ? files.length + index : index + 1,
          item.id,
          data
        )
      } else {
        return null;
      }
    })
    return Promise.all(promiseList)
  }

  getAllImportNpi = async (importParams) => {
    const { files } = this.state;
    const promiseList = await importParams.map(async (item, index) => {
      const { ImpedanceContent } = this.props;
      let data = [...ImpedanceContent];
      if (ImpedanceContent) {
        this.importContent.set(item.id, data);
        return item.getNpiFile(
          files.length ? files.length + index : index + 1,
          item.id,
          data
        )
      } else {
        return null;
      }
    })
    return Promise.all(promiseList)
  }


  getColorByIndex = (fileId, row, col) => {
    const { files } = this.state;
    return getColor({ files, fileId, row, col })
  }

  settingChangeCB = () => {
    const { files } = this.state;
    const axis = this.getAxis();
    const setting = this.getSetting();
    sparameter.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale });
  }

  changeFileListBottom = () => {
    this.resultRightMenu.updateFileListState();
  }

  axisChangeCB = (axis, value) => {
    axisChangeCallBack({ axis, value, setting: this.getSetting(), sparameter })
  }

  updateRangeCB = (update, min, max) => {
    updateRangeCallBack({ update, min, max, sparameter, axis: this.getAxis() })
  }

  changeWidthCB = () => {
    changeWidthCallBack({ plot2d: this.plot2d, sparameter, axis: this.getAxis(), setting: this.getSetting() })
  }

  printResult = (e) => {
    e.preventDefault();
    const options = {
      backgroundColor: '#ffffff',
    }
    document.querySelector(".plot") && saveSvg.saveSvgAsPng(document.querySelector(".plot"), "result.png", options);
  }

  clearCurveMark = () => {
    sparameter.clearCurveMark();
  }

  getSuccessImpedance = async () => {
    const { id } = this.props;
    const { compareIds } = this.state;
    const res = await getImpedanceHasResult() || [];
    let list = [...res];
    res.forEach(project => {
      project.children = project.children.map(child => ({ ...child, disabled: id === child.id || compareIds.includes(child.id) ? true : false }))
    })
    this.setState({
      successList: list
    })
  }

  addNewCompare = ([project, verfication]) => {
    const { compares } = this.props;
    const projectName = project.name || "";
    const { id, histories, name } = verfication;
    this.props.updateCompareList([
      ...compares,
      {
        name: `${projectName} - ${name}`,
        verificationId: id,
        historys: histories,
        map: []
      }
    ])
    this.setState({
      compareIds: [...this.state.compareIds, id]
    })
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div className='cascade-result-parameter-left'>
          <svg ref={this.svgRef}></svg>
        </div>
        <div className='cascade-result-parameter-right'>
          <div className='photo-box' onClick={this.printResult}>
            <img src={photo} alt="" className='cascade-photo' />
          </div>
          <div className='curve-table-box' onClick={this.clearCurveMark}>
            <DeleteFilled title='Clear Marks' className='cascade-curve-table-icon' />
          </div>
        </div>
        {/* not support mask zoom */}
        {/* {ResultZoom({
          zoomIn: sparameter.zoomIn,
          zoomOut: sparameter.zoomOut,
          fitView: sparameter.fitView
        })} */}
      </Spin>
    );
  }

  colorChange = (e, key) => {
    const { files, resultsObj, displayMode, historyObj, importObj, compareObj } = this.state;
    const fileId = strDelimited(key, "::")[0];
    const list = this.getImportIds().includes(fileId) ? importObj[fileId] :
      this.getHistoryIds().includes(fileId) ? historyObj[fileId] :
        this.getCompareIds().includes(fileId) ? compareObj[fileId] : resultsObj;
    const { updateFiles } = changeCurveColor({ e, key, files, resultList: list, displayMode });
    if (!updateFiles) return;
    this.setState({
      files: updateFiles
    }, () => {
      const value = e.toHexString()
      updatePlotColor({ plot2d: this.plot2d, key, color: value });
    })
  }

  getHistoryIds = () => {
    const { historys } = this.props;
    return historys.map(d => d.id);
  }

  getImportIds = () => {
    const { imports } = this.props;
    return imports.map(d => d.id);
  }

  getCompareIds = () => {
    const { compares } = this.props;
    return compares.map(d => [d.verificationId, ...d.historys.map(h => h.historyId)]).flat(2);
  }

  updatePortSelect = (_portSelect, id) => {
    this.setState((prevState) => {
      prevState.portSelect[id] = _portSelect;
      return {
        portSelect: prevState.portSelect
      }
    });
  }

  _changeSelectAll = (e, group, fileId) => {
    let { portSelect, files } = this.state;
    const axis = this.getAxis();
    const setting = this.getSetting();
    const check = e.target.checked;
    if (this.selectAllCancelAble) {
      this.selectAllCancelAble.cancel();
    }
    if (check) {
      const curves = getSelectAllinSignalCurves({
        e,
        group,
        portSelect: portSelect[fileId] || {},
        files,
        parameters: sparameter,
        setState: (d) => this.updatePortSelect(d, fileId),
      })
      const _showCurves = showCurves({
        parameters: sparameter,
        curves,
        setting,
        // curveType: this.getHistoryIds().includes(fileId) ? (isEndToEnd ? 'endToEnd_history' : 'channel_history') : (isEndToEnd ? 'endToEnd' : 'channel'),
        files
      });
      this.selectAllCancelAble = makeCancelable(_showCurves)
      this.selectAllCancelAble.promise.then(_curves => {
        this.plot2d.updateCurves(_curves, true);
        _curves.forEach(cur => {
          const col = String(cur.col).includes('target') ? 1 : cur.col
          const _fileIndex = files.findIndex(item => item.id === cur.id);
          const color = files[_fileIndex].matrix[cur.row][col].color;
          this.plot2d.resetColor({ id: cur.id, row: cur.row, col: col, color: color });
        })
        this.plot2d.resetPlot();
        if (sparameter.getSize() > 0) {
          sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
          sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
        }
      })
    } else {
      if (!portSelect[fileId]) {
        portSelect[fileId] = {};
      }
      if (portSelect[fileId][group.rowName]) {
        const prevSelect = [...portSelect[fileId][group.rowName]];
        removeCurves(prevSelect, sparameter);
      }
      portSelect[fileId][group.rowName] = [];
      this.updatePortSelect(portSelect[fileId], fileId);
    }
  }

  _rowNameClick = (displayMode, value) => {
    this.setState({
      resultsObj: rowDisplayChange(this.state.resultsObj, displayMode, value)
    })
  }

  removeAllCurves = () => {
    let ports = [...this.getCurrentSelects()];
    removeCurves(ports, sparameter)
  }

  _fileNameClick = (id) => {
    const { files } = this.state;
    const index = files.findIndex(f => f.id === id);
    this.setState((prevState) => {
      prevState.files[index].show = !prevState.files[index].show;
      return {
        files: [...prevState.files]
      }
    })
  }

  changeSettings = () => {
    this.sparameterSetting.setSetting({ parameter: 'Z', value: 'am' });
    this.sparameterSetting.axisChange('x', 'log');
    setTimeout(() => {
      const setting = this.getSetting();
      const axis = this.getAxis();
      if (sparameter.getSize() > 0) {
        sparameter.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
        sparameter.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
      }
    }, 10)
  }

  deleteHistory = (e, file) => {
    e && e.stopPropagation()
    const { id } = file;
    this.removeCurvesById(id)
    this.props.deleteHistory(id)
  }

  deleteImport = (e, file) => {
    e && e.stopPropagation()
    const { id } = file;
    this.removeCurvesById(id)
    this.props.deleteImport(id)
  }

  removeCurvesById = (id) => {
    let ports = [...this.getCurrentSelects()];
    const idReg = new RegExp(`^(${id})`, 'ig');
    ports = ports.filter(item => item.match(idReg));
    if (ports.length) {
      removeCurves(ports, sparameter)
    }
  }

  uploadImports = async (zip, isZip) => {
    const { id } = this.props;
    try {
      const res = await importImpedanceSnp({ zip, verificationId: id, isZip })
      this.props.getImports();
      return res ? true : false;
    } catch (error) {
      console.error(error)
      return false
    }
  }

  deleteCompare = (e, id) => {
    e && e.stopPropagation();
    const { compares } = this.props;
    const compare = compares.find(item => item.verificationId === id);
    if (compare) {
      const ids = [compare.verificationId, ...compare.historys.map(i => i.historyId)];
      ids.forEach(d => {
        this.removeCurvesById(d)
      })
      this.props.updateCompareList([
        ...compares.filter(i => i.verificationId !== id)
      ], true)
      this.setState({
        compareIds: [...this.state.compareIds.filter(i => i !== id)]
      })
    }
  }

  customizeTitle = (item, rowName) => {
    const { displayMode } = this.state;

    if (displayMode === 'Default') {
      return <span className='cascade-customized-title'>
        <span className='design-name'>{item.namePrev}</span>
      </span>
    }
  }

  showOptResult = async (e, power) => {
    e && e.stopPropagation()
    const { id } = this.props
    const { optResult } = this.state
    try {
      if (!optResult.length) {
        const res = await getImpedanceOptDecap(id)
        this.setState({
          optResult: res || []
        })
      }
      this.setState({
        optPower: power,
        optResultVisibvle: true
      })
    } catch (error) {
      console.error(error)
    }
  }

  closeOptResult = () => {
    this.setState({
      optResultVisibvle: false
    })
  }

  resultListRender = () => {
    const { portSelect, displayMode, resultsObj, historyObj, files, importObj, successList, compareObj, optResultVisibvle, optResult, optPower, optList } = this.state;
    const { id, exist, compares } = this.props;
    const historyIds = this.getHistoryIds();
    const historyFiles = files.filter(d => historyIds.includes(d.id));
    const importIds = this.getImportIds();
    const importFiles = files.filter(d => importIds.includes(d.id));
    const compareIds = this.getCompareIds();
    const compareFiles = files.filter(d => compareIds.includes(d.id));
    return (
      <Fragment>
        {/* Result list */}
        <ResultList
          title='Current Result'
          className={exist ? '' : 'cascade-impedance-sparameter-current-result'}
          type={RESULT}
          fileId={id}
          rowId={id}
          displayMode={displayMode}
          portSelect={portSelect}
          portSelectMultiFile={true}
          changePort={this.changePort}
          colorChange={this.colorChange}
          resultList={resultsObj}
          rowNameClick={this._rowNameClick}
          changeSelectAll={this._changeSelectAll}
          showOptResult={this.showOptResult}
          optList={optList}
        />
        {/* History */}
        <ResultList
          type={HISTORY}
          multiFiles={true}
          displayMode={displayMode}
          portSelect={portSelect}
          portSelectMultiFile={true}
          changePort={this.changePort}
          colorChange={this.colorChange}
          resultList={historyObj}
          rowNameClick={this._historyRowNameClick}
          changeSelectAll={this._changeSelectAll}
          files={historyFiles}
          fileNameClick={this._fileNameClick}
          customizeTitle={this.customizeTitle}
          fileDelete={this.deleteHistory}
        />
        {/* Import */}
        <ResultList
          type={IMPORT}
          multiFiles={true}
          displayMode={displayMode}
          portSelect={portSelect}
          portSelectMultiFile={true}
          changePort={this.changePort}
          colorChange={this.colorChange}
          resultList={importObj}
          rowNameClick={this.importRowNameClick}
          changeSelectAll={this._changeSelectAll}
          files={importFiles}
          fileNameClick={this._fileNameClick}
          customizeTitle={this.customizeTitle}
          fileDelete={this.deleteImport}
          uploadImports={this.uploadImports}
        />
        {/* Comparison */}
        <ResultList
          type={COMPARE}
          multiFiles={true}
          displayMode={displayMode}
          compartList={successList}
          getCompareList={this.getSuccessImpedance}
          addNewCompare={this.addNewCompare}
          portSelect={portSelect}
          portSelectMultiFile={true}
          changePort={this.changePort}
          colorChange={this.colorChange}
          resultList={compareObj}
          compares={compares}
          rowNameClick={this.compareRowNameClick}
          changeSelectAll={this._changeSelectAll}
          files={compareFiles}
          fileNameClick={this._fileNameClick}
          customizeTitle={this.customizeTitle}
          compareDelete={this.deleteCompare}
        />
        {optResultVisibvle && <OptimizationPanel
          closeModal={this.closeOptResult}
          optResult={optResult}
          optPower={optPower}
          id={id}
          impedanceSetup={this.impedanceResultContent}
        />}
      </Fragment>
    )
  }

  settingRender = () => {
    const selectArr = this.getCurrentSelects();
    return (
      <SparameterSetting
        onRef={this.onRef}
        portSelectLen={selectArr.length}
        settingChangeCB={this.settingChangeCB}
        changeFileListBottom={this.changeFileListBottom}
        axisChangeCB={this.axisChangeCB}
        updateRangeCB={this.updateRangeCB}
        showFormat={false}
      />
    )
  }

  resultRight = () => {
    return (
      <ResultRightMenu
        onRef={this.resultRightMenuOnRef}
        resultList={this.resultListRender}
        setting={this.settingRender}
      />
    )
  }

  render() {
    return (
      <ResultLayout
        defaultRightWidth={330}
        resultLeft={this.resultLeft}
        resultRight={this.resultRight}
        changeWidthCB={this.changeWidthCB}
        changeSettings={this.changeSettings}
      />
    )
  }
}

export default SparameterResult;


