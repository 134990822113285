import { DCR_TITLE, FULL_TITLE, IMPEDANCE_TITLE, IR_TITLE, SINGLE_TITLE, TEMPLATE_TITLE, TREE_TITLE } from "../constants";

const getVerificationName = (name) => {
  switch (name) {
    case 'Impedance_Explorer':
      return IMPEDANCE_TITLE;
    case 'IR_Explorer':
      return IR_TITLE;
    case 'PathR_Explorer':
      return DCR_TITLE;
    case 'Design_Tree':
      return FULL_TITLE;
    case 'Power_Tree':
      return TREE_TITLE;
    case 'Single_Power_Tree':
      return SINGLE_TITLE;
    case 'Sign_Off_Template':
      return TEMPLATE_TITLE
    default: return null;
  }
}

const sortTitle = ['Design_Tree', 'Power_Tree', 'Sign_Off_Template', 'PathR_Explorer', 'IR_Explorer', 'Impedance_Explorer']

export function createChildren(data) {
  const children = []
  if (data.cascadeChannel) {
    sortTitle.forEach((title) => {
      const list = title === 'Power_Tree' ? [...(data.cascadeChannel[title] || []), ...(data.cascadeChannel['Single_Power_Tree'] || [])] : (data['cascadeChannel'][title] || [])
      if (list.length) {
        let explorer = {
          title: getVerificationName(title),
          // value: title,
          key: title,
          children: []
        }
        let projectId = ''
        list.forEach((task) => {
          explorer.children.push({
            title: task.name,
            // value: 'task-' + task.projectId + '-' + task.verificationId,
            key: 'task-' + task.projectId + '-' + task.verificationId,
            isDownload: true,
            isLeaf: true
          })
          projectId = task.projectId
        })
        // explorer.value += '-' + projectId + '-0'
        explorer.key += '-' + projectId + '-0'
        children.push(explorer)
      }
    })
  }
  return children
}