import CeNetPin from './CeNetPin';
import CeNetVia from './CeNetVia';
import CeNetGeom from './CeNetGeom';
// import CeRefGeometry from '../geometry/CeRefGeometry';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeNet = function (name) {
  this.mName = name;
  this.mGeomList = [];
  this.mViaList = [];
  this.mPinList = [];
};

CeNet.prototype.GetName = function () {
  return this.mName;
}

CeNet.prototype.SetType = function (type) {
  this.mType = type;
}

CeNet.prototype.GetNetGeomList = function () {
  return this.mGeomList;
}

CeNet.prototype.GetNetViaList = function () {
  return this.mViaList;
}

CeNet.prototype.AddCompPin = function (lyrName, compName, mPinNum) {
  this.mPinList.push(new CeNetPin(lyrName, compName, mPinNum));
}

CeNet.prototype.AddNetGeom = function (netGeom) {
  this.mGeomList.push(netGeom);
}

CeNet.prototype.GetNetTypeString = function () {
  return this.mType;
}

CeNet.prototype.SetNetType = function (tpstr) {
  this.mType = tpstr;
}

CeNet.prototype.WriteIntoIODataNode = function (netBlock) {
  netBlock.AddDataItem("Type", this.GetNetTypeString());

  if (this.mViaList.length > 0) {
    var viasBlk = new CeIODataBlock("NetVias");
    for (var i = 0; i < this.mViaList.length; i++) {
      viasBlk.AddDataItem("Via", this.mViaList[i].GetDataStrings());
    }
    netBlock.AddBlockNode(viasBlk);
  }

  if (this.mPinList.length > 0) {
    var pinsBlk = new CeIODataBlock("NetPins");
    for (let i = 0; i < this.mPinList.length; i++) {
      pinsBlk.AddDataItem("Pin", this.mPinList[i].GetDataStrings());
    }
    netBlock.AddBlockNode(pinsBlk);
  }
}

CeNet.prototype.ReadFromIODataNode = function (myBlock) {
  var type = myBlock.GetItemString("Type", "Unknown");
  this.SetNetType(type);

  var netGeomBlks = myBlock.GetSubBlocksOfSameName("NetGeom");
  for (var i = 0; i < netGeomBlks.length; i++) {
    var netGeom = new CeNetGeom();
    if (netGeom.ReadFromIODataNode(netGeomBlks[i])) {
      this.mGeomList.push(netGeom);
    }
  }

  var netViaBlk = myBlock.GetSubBlock("NetVias");
  if (netViaBlk != null) {
    var viaItems = netViaBlk.GetAllBlockItems();
    for (let i = 0; i < viaItems.length; i++) {
      var netVia = new CeNetVia();
      if (netVia.UpdateByString(viaItems[i].GetItemValue()))
        this.mViaList.push(netVia);
    }
  }

  var netPinBlk = myBlock.GetSubBlock("NetPins");
  if (netPinBlk != null) {
    var pinItems = netPinBlk.GetAllBlockItems();
    for (let i = 0; i < pinItems.length; i++) {
      var netPin = new CeNetPin();
      if (netPin.UpdateByString(pinItems[i].GetItemValue()))
        this.mPinList.push(netPin);
    }
  }
  return true;
}

export default CeNet;