import React, { Component } from 'react'
import { connect } from 'react-redux';
import ContentLayout from '@/components/ContentLayout';
import { closeTabFooter, changeTabMenu } from '../../MonitorStore/action';
import { HIMALAYAS } from '@/constants/pageType';
import LayoutContent from './layoutContent';
/* import LayoutLR from './LayoutLR';
import LayoutTB from './LayoutTB'; */
import { getCCCVerifications, getCCCSpecifyServiceVerification } from '@/services/Cascade/helper/specifyHelper';
import { IO_SIM, SPICE_CARD_TEMPLATE } from '../../../constants/treeConstants';
import himalayasChannels from '../../../services/Himalayas/project/channelsConstructor';
import { getChannelList, getProjectChildTitle, getTopBarWidth } from '../../../services/Himalayas';
import HimalayasMonitorChild from './monitor';
import './index.css';

class HimalayasContent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render = () => {
    const { layout, viewList } = this.props
    return <ContentLayout
      {...this.props}
      pageType={HIMALAYAS}
      layout={layout}
      viewList={viewList}
      layoutSingle={this.layoutSingle}
      layoutLR={this.layoutLR}
      layoutTB={this.layoutTB}
    />
  }

  getTopMenuVisible = () => {
    const { projectId } = this.props;
    if (!projectId) {
      return false;
    }
    return true
  }

  _changeMonitorTabMenu = ({ selectKeys }) => {
    const { menuType, viewList, spiceCardVerificationId, projectId, ioSimVerificationId } = this.props;
    let currentVerificationId = undefined, verificationName = null;
    if (selectKeys.some(key => ['monitor', 'profile', "detail"].includes(key))) {
      const view = viewList.find(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));
      let currentItem = null;
      switch (view) {
        case IO_SIM:
          currentVerificationId = ioSimVerificationId;
          currentItem = himalayasChannels.getInfoByVerificationId(IO_SIM, projectId, ioSimVerificationId);
          verificationName = currentItem ? currentItem.name : 'Interface';
          break;
        case SPICE_CARD_TEMPLATE:
          currentVerificationId = spiceCardVerificationId;
          currentItem = himalayasChannels.getInfoByVerificationId(SPICE_CARD_TEMPLATE, projectId, spiceCardVerificationId);
          verificationName = currentItem ? currentItem.name : 'Input';
          break;
        default: break;
      }
    }
    let info = {
      tabSelectKeys: selectKeys,
      currentVerificationId,
      verificationName,
      menuType,
    }
    this.props._changeTabMenu(info);
  }

  _monitorChild = (width) => {
    const { menuType, tabVisible, tabSelectKeys, projectId, viewList, projectName, ioSimVerificationId, spiceCardVerificationId } = this.props;
    const verification = viewList.map(item => getProjectChildTitle(item)).filter(item => !!item);
    const _verification = verification.length ? verification[0] : null;
    const titleSelectedKeys = [
      { type: 'Project', name: projectName },
      { type: 'Verification', name: _verification }];
    let taskList = getChannelList({ name: _verification, projectId, ioSimVerificationId, spiceCardVerificationId });
    const view = viewList.find(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));
    const title = view ? getProjectChildTitle(view) : null;
    return HimalayasMonitorChild({
      menuType,
      tabVisible,
      tabSelectKeys,
      projectId,
      getCCCVerifications: () => getCCCVerifications(taskList.list, title),
      getCurrentVerification: () => getCCCSpecifyServiceVerification(taskList.id, taskList.list),
      width,
      pageType: HIMALAYAS,
      titleSelectedKeys,
    })
  }

  layoutSingle = () => {
    return <LayoutContent
      {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={getTopBarWidth}
    />
  }

  /* layoutLR = () => {
    return <LayoutLR  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={getTopBarWidth}
    />
  }

  layoutTB = () => {
    return <LayoutTB  {...this.props}
      MonitorChild={this._monitorChild}
      getTopMenuVisible={this.getTopMenuVisible}
      changeMonitorTabMenu={this._changeMonitorTabMenu}
      getTopBarWidth={getTopBarWidth}
    />
  } */
}

const mapState = (state) => {
  const {
    LoginReducer: { pageType },
    HimalayasReducer: {
      project: { viewList, layout, openProjectId },
      ioSim: { verificationId: ioSimVerificationId },
      spiceCard: { verificationId: spiceCardVerificationId }
    } } = state;
  const {
    monitorScreenInfo: { tabSelectKeys, menuType, currentVerificationId, projectName },
    tabVisible } = state.MonitorInfoReducer;
  return {
    viewList,
    layout,
    projectId: openProjectId,
    tabSelectKeys,
    menuType,
    currentVerificationId,
    tabVisible,
    pageType,
    projectName,
    ioSimVerificationId,
    spiceCardVerificationId
  }
}
const mapDispatch = (dispatch) => ({
  _changeTabMenu(info) {
    dispatch(changeTabMenu(info));
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  }
})

export default connect(mapState, mapDispatch)(HimalayasContent)