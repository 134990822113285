import { DDR3, DDR3L, NVDDR3, DDR4, LPDDR4, DDR5, LPDDR5, DDR5_RDIMM, DDR5_SODIMM, DDR5_UDIMM, DDR5_RDIMM_CARD, DDR5_SODIMM_CARD, DDR5_UDIMM_CARD, DDR5_RDIMM_X4 } from '../constants';
import permissionData from '@/services/helper/data/permissionData';
import { PACKAGE } from '../../../constants/treeConstants'
import { getPowerVoltage } from './setupData';
import { PROJECT_V2 } from '../../../constants/projectVersion';
export function Extraction(ddrType, designType, projectVersion) {
  //version 0.0.3
  this.clipping = true;
  this.clipSize = "0.3"; //float >= 0.1, unit: ratio
  this.includeDC = false; //true/false
  this.exactDC = false; //true/false
  this.discreteSweep = false; //true/false
  this.meshFrequency = "5"; //float >1
  this.maxPasses = "10"; // only show if modeling with HFSS
  this.maxDelta = "0.02"; //float [0.001,1] only show if modeling with HFSS
  this.portLimit = "150"; // V0.0.3 new field [30,300] must be integer
  this.portImpedance = "50";
  this.siwave = {} //yml file -> siwaveOptionLibraryId
  this.hfss = {} //yml file -> hfssOptionLibraryId
  this.errorTolerance = '0.005'; //float [0.0005,0.05] SIwave/HFSS

  // Byte sweep config
  this.logSweepMin = "1000";
  this.logSweepMax = "1e7";
  this.logSweepSAPD = "10";
  this.linearSweepMax = "5e9";
  this.linearSweepFreqStep = "5e6";
  this.maxFreq = ddrType ? getMaxFreqByDDRType(ddrType, false) : "";

  if (designType === PACKAGE) {
    // package
    const type = permissionData.getRockyExtraction();
    this.type = type === "Default" ? "SIwave" : type;
    this.backdrillVias = false;
  } else {
    // CA sweep config
    if (projectVersion !== PROJECT_V2) {
      this.caLogSweepMin = "1000";
      this.caLogSweepMax = "1e7";
      this.caLogSweepSAPD = "10";
      this.caLinearSweepMax = "5e9";
      this.caLinearSweepFreqStep = "5e6";
      this.caMaxFreq = ddrType ? getMaxFreqByDDRType(ddrType, true) : "";
    }
    this.backdrillVias = false;
    this.backdrillStubSize = "8mil";// [1, 50],unit mil
  }
};

export function HPCOptions() {
  this.num_tasks = "1";
  this.num_cores = "4";
  this.num_gpus = "0";
  this.ram_percent = "90";
}

// For DDR3 – those two tables should be exactly the same default, Fmax = 5GHz.
// For DDR4 and LPDDR4 – the Fmax for CA is 5GHz while the Fmax for Byte is 10GHz.
// For DDR5 – the Fmax for CA is 10GHz while the Fmax for Byte is 20GHz.
// For LPDDR5 – the Fmax for CA is 5GHz while the Fmax for Byte is 20GHz.
function getMaxFreqByDDRType(type, isCA) {
  switch (type) {
    case DDR4:
    case LPDDR4:
      return isCA ? "" : "1e10";
    case DDR5:
    case DDR5_RDIMM:
    case DDR5_SODIMM:
    case DDR5_UDIMM:
    case DDR5_RDIMM_CARD:
    case DDR5_SODIMM_CARD:
    case DDR5_UDIMM_CARD:
    case DDR5_RDIMM_X4:
      return isCA ? "1e10" : "2e10";
    case LPDDR5:
      return isCA ? "" : "2e10";
    case DDR3:
    case DDR3L:
    case NVDDR3:
    default: return ""; // 5GHz
  }
}

const byteSweep = ['logSweepMin', 'logSweepMax', 'logSweepSAPD', 'linearSweepMax', 'linearSweepFreqStep', 'maxFreq'];
const caSweep = ['caLogSweepMin', 'caLogSweepMax', 'caLogSweepSAPD', 'caLinearSweepMax', 'caLinearSweepFreqStep', 'caMaxFreq'];
export function judgeExtraction(extraction, ddrType = "") {
  if (Object.keys(extraction).length >= 22) {
    return extraction;
  }

  let newExtraction = { ...extraction };
  for (let i = 0; i < caSweep.length; i++) {
    if (newExtraction[caSweep[i]] === undefined) {
      newExtraction[caSweep[i]] = newExtraction[byteSweep[i]];
    }
  }
  return newExtraction;
}

export function PDNExtraction() {
  this.CLIP = 1;//default 1
  this.IPC = 1; //default 1
  this.VRTC = "1"; //default 1 float range=[0, 10]
  this.VOID = "1e-6";//range=[0, 10], default=2.0
  this.PLNM = "1e-6";//range=[0, 1e-4], default=1e-6
  this.PADM = "1e-6";//range=[0, 5], default=1e-6
  this.DISC = 0;//default=0
  this.DC = 1;//default=1
  this.FMAX = '200e6';//Range=[0, 2e9], default=200e6
  this.SAPD = "100";//range=[1, 200], default=100
  this.SIWE = 0;//default=0
  this.REFR = '0.1';//range=[0, 1000], default=0.1
  this.UPDC = 0; //default false
}

export function pdnPKG() {
  this.type = "value"; //value / Touchstone / SPICE
  this.r = "0.01";
  this.l = "50p";
  this.c = "500p";
  this.model = {
    fileName: "",
    libraryId: "",
    subckt: "",
    pairs: [] // [ { pin:"", node:"" } ]
  };
}

export function pdnDie() {
  this.type = "value";//value / Touchstone / SPICE
  this.r = "0.1";
  this.c = "10n";
  this.model = {};
}

export class RockyInterface {
  constructor(name = '') {
    this.Name = name; // interface name
    this.Signals = []; // [Signal]
    this.Components = []; // [RLCCONComponent,ICComponent]
    this.PowerNets = []; // [SourceNets]
    this.Channel = {};//{channel}
    this.PowerComponents = []; // [{ id, name, pins, type, value }]
    this.Ports_generate_setup_list = [];
    this.ReferenceNets = [];
    this.Port_setups = [];
  }
}

export class RockyPackageInterface {
  constructor() {
    this.Signals = []; // [Signal]
    this.Components = [];
    this.PowerNets = [];
    this.Ports_generate_setup_list = [];
    this.ReferenceNets = [];
    this.Port_setups = [];
  }
}

export class BasicComponent {
  constructor({ name = '', type = '', pins = [], usage = '', part = "" }) {
    this.name = name;
    this.pins = pins;
    this.part = part;
    if (!usage) {
      this.type = type;
    }
    this.addModel.bind(this);
  }

  addModel({ signal, pin, net }) {
    this.pins.push(new BasicCompModel({ signal, pin, net }));
  }
}

export class BasicCompModel {
  constructor({ signal, pin, net }) {
    this.signal = signal || '';
    this.pin = pin || '';
    this.net = net || '';
  }
}

// ================= Component structure =================
// RLC,connector component
export class RLCCONComponent extends BasicComponent {
  constructor(props) {
    super(props);
    this.value = props.value || '';
    if (props.usage) {
      this.usage = props.usage || '';
      this.COMP_TYPE = props.usage;
    }
  }
}

// Driver,receiver(IC) component
export class ICComponent extends BasicComponent {
  constructor(props) {
    super(props);
    this.deviceVcc = "";
    this.corner = 'typ';
  }
}

// ================== Signal structure ===================
export class Signal {
  constructor(name = '') {
    this.name = name;
    this.nets = [];
  }
}

// ================= SourceNets structure ================
export class SourceNets {
  constructor(name = '', value = '') {
    this.name = name;
    this.value = value;
  }
}

// ============== Component Model structure ==============
// Receiver model
export class ICCompModel extends BasicCompModel {
  constructor(props) {
    super(props);
    this.usage = ""; // Receiver|Driver|Ignore
    this.model = {}; // model object
    this.powerOff = ""; // POWER ON/POWER OFF - false|true
    this.pinModels = [];
  }
}

// ================= PinModel structure ==================
export class PinModel {
  constructor({ pinName }) {
    this.pinName = pinName;
    this.stimulus = {}; // stimulus / object {new Stimulus()}
    this.voltage = "";
    this.type = ""; // CLK|SDR|DDR|GND|VCC|VEC(stimulus) - Driver nd_in/nd_en pin
  }
}

// =================== Model structure ===================
export class Model {
  constructor({ modelType = '', enableVoltage = '', modelName = '', fileName = '', libType = '', libraryId = "", folder = "" }) {
    this.modelType = modelType;
    this.enableVoltage = enableVoltage;
    this.modelName = modelName;
    this.fileName = fileName;
    this.libType = libType; // IBIS SPICE IBIS_AMI
    this.libraryId = libraryId;
    this.folder = folder;
  }
}

export class Stimulus {
  constructor() {
    this.name = "";
    this.delay = "";
    this.riseTime = "";
    this.fallTime = "";
    this.period = "1000"
    this.pattern = "";
    this.voltage = "";
    this.timeUnit = "ns";
  }
}

export class Channel {
  constructor(type = 'Default') {
    this.type = type; // SIwave|HFSS|Default
  }
}

// ==== Driver pin ===
export class Driver {
  constructor({ powerOff = "", pinModels = [], model = {} }) {
    this.model = model || {} // model object
    this.powerOff = powerOff || ""; // POWER ON/POWER OFF - 0|1
    this.pinModels = pinModels || [];
  }
}

// ==== Receiver pin ===
export class Receiver {
  constructor({ model = {} }) {
    this.model = model; // model object
  }
}

// ==== Default Config (simulation options) ====
export function ConfigInfo({
  clock = '800M',
  cycles = '200',
  cmd_2t_mode = false,
  timeStep = '15p',
  corners = [],
  ngspiceMDFLM = '30ps',
  simulate = permissionData.getRockySimulator(),
  caClock,
  caTimeStep,
  hspiceCores = 2,
  isSev = false,
  hspiceLicenses = 2,
  simulateMode,
  statEyeConfig
}) {
  this.clock = clock;
  this.cycles = cycles;
  this.timeStep = timeStep;
  /* this.xTalk = "1"; */
  this.simulate = simulate;
  if (corners && corners.length) {
    this.corners = corners;
  }
  this.cmd_2t_mode = cmd_2t_mode;
  this.ngspiceMDFLM = ngspiceMDFLM;
  if (caClock) {
    this.caClock = caClock;
  }
  if (caTimeStep) {
    this.caTimeStep = caTimeStep;
  }
  if (isSev) {
    this.hspiceLicenses = hspiceLicenses
  } else {
    this.hspiceCores = hspiceCores;
  }
  if (simulateMode) {
    this.simulateMode = simulateMode;
  }
  if (simulateMode === 'StatEye') {
    this.statEyeConfig = statEyeConfig;
  } else {
    this.statEyeConfig = {
      transientEngine: '',
      simulationMode: '',
      edgesNum: '',
      crosstalkType: '',
      eyeDiagramTriggering: '',
      eyeDiagramTriggeringClockMode: '',
      numberBits: ''
    };
  }
}
/* 
export class PackageModelInfo {
  constructor(type = '') {
    this.type = type;
    this.files = [{
      type: type,
      fileName: "",
      libraryId: "",
      subckt: ""
    }]
    this.subckt = '';
    this.pairs = [];
  }
} */
export class PackageModelInfo {
  constructor(type = '') {
    this.type = type;
    this.files = []; //{ type, fileName, libraryId, subckt }
    this.pairs = []; // [ { pin, node, subckt, libraryId } ,{ pin, node, subckt, libraryId }, ... ]
    this.rank = null;
  }
}

export class PackageModelFileInfo {
  constructor() {
    this.type = "";
    this.fileName = "";
    this.libraryId = "";
    this.subckt = "";
    this.modelKey = "";
  }
}

export class WaveformConfig {
  constructor({ ddrType, isPrevCase }) {
    this.crosstalkRiseTime = isPrevCase ? "25ps" : "0.1";//unit ratio
    this.crosstalkFallTime = isPrevCase ? "25ps" : "0.1";//unit ratio
    this.voltagePulse = isPrevCase ? "1" : getPowerVoltage(ddrType);//unit V
  }
}

export class PinSpiceModel {
  constructor({ fileName = '', libType = '', libraryId = "", folder = "", modelName = "", node = null }) {
    this.fileName = fileName || "";
    this.libType = libType; // SPICE
    this.libraryId = libraryId;
    this.folder = folder || "";
    this.modelName = modelName || "";
    this.node = node || { in: "", out: "" }
  }
}

export function getExtractionTimeout(timeout) {
  const hours = Math.floor(timeout / 60);
  const minutes = timeout % 60;
  return { hours, minutes }
}