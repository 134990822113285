import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Progress, Row, Col, Tooltip, Select } from 'antd';
import {
  cancelWorkflow, cancelShow, getCurrentLog, getInterfaceMonitor, cancelVerificationWorkflow
} from '../../store/simulation/action';
import { changeTabMenu, changeVerification } from '../../../tabMonitor/action';
import ErrorCheck from '../../errorCheck';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import './style.css';

const { Option } = Select;
class SimulationMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: null,
      topWidth: 666,
    }
  }

  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible } = this.props;
    if (tabVisible && this.scrollRef) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { tabVisible, currentVerificationId } = this.props;
    const { topWidth } = this.state;
    if (tabVisible && this.scrollRef) {
      this.scrollToBottom();
    }
    if (currentVerificationId && currentVerificationId !== prevProps.currentVerificationId) {
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
  };

  cancelProgress = (e) => {
    e.stopPropagation();
    //cancel simulation
    /* const { singleVerifyInfo } = this.props;
    if (singleVerifyInfo && singleVerifyInfo.workflowId) {
      this.props.cancelWorkflow(singleVerifyInfo.workflowId);
      this.props.cancelShow(true);
    } */
    const { currentVerificationId } = this.props;
    this.props.cancelVerificationWorkflow(currentVerificationId);
    this.props.cancelShow(true);
  }

  changeInterface = (key) => {
    const { currentProjectId, interfaceList } = this.props;
    const _interface = interfaceList.find(item => item.name === key);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId: _interface.id, projectId: currentProjectId });
    this.props.changeVerification(key);
    // TODO
    const status = _interface.status;
    if (status === VERIFY_RUNNING || status === WAITING) {
      this.props.getInterfaceMonitor(_interface.id);
    } else {
      this.props.getCurrentLog(_interface.id);
    }
  }

  render() {
    const {
      runningStatus, currentVerificationId, singleVerifyInfo, monitor, progress, projectName, verificationName, cancelStatus,
      simulationMsg, log, uploadDebugMes, queueIndex, waitingTime, preLog, updateNetsComponents,
      interfaceList, verificationStatus, errorCheck
    } = this.props;
    const { topWidth } = this.state;
    let error = errorCheck;

    let currentUpdateNetsComps = updateNetsComponents.find(item => item.verificationId === currentVerificationId);
    let update = false;
    if (verificationName && currentUpdateNetsComps && (currentUpdateNetsComps.deleteNets.length > 0 || currentUpdateNetsComps.deleteComponents.length > 0)) {
      update = true;
    }

    if (verificationName && !error && ((singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId) || monitor.length > 0 || preLog)) {
      update = false;
    }

    let startDisplay = false, runDisplay = false;
    if (verificationName && queueIndex < 0 && runningStatus && singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId && !singleVerifyInfo.workflowId) {
      startDisplay = true;
    }
    if (verificationName && queueIndex < 0 && runningStatus && singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId && singleVerifyInfo.workflowId) {
      runDisplay = true;
    }
    let logView = false;
    if (!error && !uploadDebugMes && !cancelStatus && !simulationMsg && !update && verificationName && queueIndex < 0 && (!singleVerifyInfo || (singleVerifyInfo && currentVerificationId !== singleVerifyInfo.verificationId))) {
      logView = true;
    }
    return (
      <Fragment>
        <div className='andes-simulation-title'>
          <Row className='andes-simulation-holygrail' span={24}>
            <Col span={topWidth <= 528 ? 8 : 12}>
              <Tooltip
                title={topWidth <= 680 ? <Fragment>{projectName &&
                  <span className='andes-span-msg-tooltip'><span className='font-bold'>Project</span> {projectName}</span>}
                  {verificationName &&
                    <span className='andes-span-msg-tooltip'><span className='font-bold'>Interface</span> {verificationName} </span>}
                </Fragment> : null}
                overlayClassName='icon-tooltip'
                placement="topLeft"
              >
                {(topWidth > 502 || (queueIndex < 0 && progress < 0 && topWidth > 410)) ? <div className='andes-simulation-msg' style={{ width: '100%' }}>
                  {projectName && <div className='andes-simulation-span andes-simulation-project'>
                    <span className='span-msg-project font-bold'>Project </span>
                    <span className='span-msg'>{projectName}</span>
                  </div>}
                  {verificationName && <div className='andes-simulation-span'>
                    <span className='span-msg-project font-bold'>Interface </span>
                    {/* <span className='span-msg'>{verificationName} </span> */}
                    <Select value={verificationName} onChange={this.changeInterface} style={{ marginLeft: 10 }}>
                      {interfaceList.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
                    </Select>
                  </div>}
                </div> : <div className='span-msg-icon-div'><InfoCircleOutlined className='span-msg-icon' /></div>}
              </Tooltip>
            </Col>
            {progress > -1 ?
              (<Col className='andes-simulation-middle' span={topWidth <= 502 ? 12 : 10}>
                <Col className='andes-simulation-progress' span={22}>
                  <Progress
                    size={{ height: 14 }}
                    strokeColor={'#1890ff'}
                    percent={progress}
                    className="simulation-progress-bar"
                  />
                </Col>
              </Col>) :
              ((!cancelStatus && queueIndex >= 0) &&
                <Col className='andes-simulation-middle' span={topWidth <= 528 && topWidth >= 438 ? 14 : topWidth < 438 ? 16 : 10}>
                  {topWidth > 717 && <span className='simulation-waiting-title'> Simulation waiting: {queueIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
                  {topWidth <= 717 && <span className='simulation-waiting-title'>Waiting: {queueIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
                </Col>)
            }
          </Row>
          <div className='andes-simulation-btn'>
            {(!cancelStatus && ((progress > -1 || queueIndex >= 0) || verificationStatus === VERIFY_RUNNING || verificationStatus === WAITING)) && <Fragment>
              <div className='andes-cancel-button'>
                <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
                  <span className='iconfont icon-cancel9' onClick={(e) => this.cancelProgress(e)}></span>
                </Tooltip>
              </div>
            </Fragment>}
          </div>
        </div>
        <div className='my-andes-monitor'>
          {update ? <div className='andes-update-pcb-box'>
            Design has been updated.
            {(currentUpdateNetsComps.deleteNets && currentUpdateNetsComps.deleteNets.length > 0) ? <span className='andes-update-pcb-span'>None of the nets [ {currentUpdateNetsComps.deleteNets.join(', ')} ].</span> : null}
            {(currentUpdateNetsComps.deleteComponents && currentUpdateNetsComps.deleteComponents.length > 0) ? <span className='andes-update-pcb-span'>None of the components [ {currentUpdateNetsComps.deleteComponents.join(', ')} ].</span> : null}
          </div> : null}
          <ErrorCheck
            errorCheck={error}
          />
          <pre style={{ margin: 0, overflow: 'unset', padding: '0 20px', paddingTop: '14px' }}>
            {verificationName && uploadDebugMes ? <span className='debug-mes-span'>{uploadDebugMes} </span> : null}
            {startDisplay ?
              <span className='debug-mes-span'>==&gt;  Start simulating... </span> : null}
            {runDisplay ?
              <span className='debug-mes-span'>==&gt;  Simulation running... </span>
              : null}
            {runDisplay && !preLog && monitor.length === 0 ?
              <span className='debug-mes-span'>==&gt;  Data processing... </span> : null}
            {queueIndex === 0 ? <span className='debug-mes-span'>==&gt; The current task is on the top of the waiting queue and ready to run.</span> : null}
            {queueIndex === 1 ? <span className='debug-mes-span'>==&gt; The current task is in the waiting queue and there is one task ahead.</span> : null}
            {queueIndex > 1 ? <span className='debug-mes-span'>==&gt; The current task is in the waiting queue and there are {queueIndex} tasks ahead.</span> : null}
            {!error && queueIndex < 0 && ((singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId) || monitor.length > 0 || preLog) ?
              (<ul className='andes-monitor-ul'>
                {preLog && <p style={{ margin: 0 }}>{preLog}</p>}
                {monitor.length > 0 ? monitor.map(item =>
                  <li key={item.id} style={{ listStyle: 'none', width: '100%' }}>
                    <p style={{ margin: 0 }}>{item.log}</p>
                  </li>) : null}
              </ul>) : (logView ? log : null)}
            {verificationName && simulationMsg ? <span className='debug-mes-span'>{simulationMsg} </span> : null}
            {verificationName && cancelStatus ? <span className='debug-mes-span'>==&gt;  Simulation cancelled! </span> : null}
          </pre>
          {!error && ((singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId) || monitor.length > 0 || preLog) ? <div ref={(el) => { this.scrollRef = el; }}></div> : null}
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const {
    project: { currentProjectId, treeItems, verificationStatus },
    simulationReducer, andes: { andesInfo = null, updateNetsComponents } } = state.AndesReducer;
  const { singleProgress, singleMonitor, runningStatus, singleVerifyInfo, cancelStatus, simulationMsg, log, uploadDebugMes, waitingQueue, andesInfoErrorCheck, preProcessLog } = simulationReducer;
  const { projectName, verificationName, currentVerificationId, tabVisible } = state.TabMonitorReducer;
  let monitor = [], progress = -1, verifyMsg = null, queueIndex = -1, waitingTime = "", preLog = '';
  const i = singleMonitor && singleMonitor.length > 0 && singleMonitor.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (i) !== 'boolean' && i > -1) {
    monitor = singleMonitor[i].monitor;
  }
  const n = singleProgress && singleProgress.length > 0 && singleProgress.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (n) !== 'boolean' && n > -1) {
    progress = singleProgress[n].progress;
  }
  let m = simulationMsg && simulationMsg.length > 0 && simulationMsg.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (m) !== 'boolean' && m > -1) {
    verifyMsg = simulationMsg[m].msg;
  }

  let q = waitingQueue && waitingQueue.length > 0 && waitingQueue.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (q) !== 'boolean' && q > -1) {
    queueIndex = waitingQueue[q].queueIndex;
    waitingTime = waitingQueue[q].waitingTime;
  }

  const p = preProcessLog && preProcessLog.length > 0 && preProcessLog.findIndex(item => verificationName && item.verificationId === currentVerificationId);

  if (typeof (p) !== 'boolean' && p > -1) {
    preLog = preProcessLog[p].log;
  }
  let interfaceList = [], project = null;
  if (currentProjectId) {
    project = treeItems[0].children.find(item => item.id === currentProjectId);
  }
  if (project && project.children && project.children[1].children) {
    interfaceList = project.children[1].children;
  }

  let errorCheck = null;
  if (andesInfoErrorCheck.length > 0) {
    const currentErrorCheck = andesInfoErrorCheck.find(item => item.verificationId === currentVerificationId);
    if (currentErrorCheck) {
      errorCheck = currentErrorCheck.errorCheck;
    }
  }

  return {
    projectName,
    verificationName,
    currentVerificationId,
    currentProjectId,
    monitor,
    progress,
    runningStatus,
    singleVerifyInfo,
    cancelStatus,
    simulationMsg: verifyMsg,
    tabVisible,
    log,
    andesInfo,
    uploadDebugMes,
    queueIndex,
    waitingTime,
    preLog,
    interfaceList,
    updateNetsComponents,
    verificationStatus,
    errorCheck
  }
}

const mapDispatch = (dispatch) => ({
  cancelWorkflow(workflowId) {
    dispatch(cancelWorkflow(workflowId));
  },
  cancelShow(status) {
    dispatch(cancelShow(status));
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }))
  },
  changeVerification(name) {
    dispatch(changeVerification(name));
  },
  getCurrentLog(verificationId) {
    dispatch(getCurrentLog(verificationId))
  },
  getInterfaceMonitor(verificationId) {
    dispatch(getInterfaceMonitor(verificationId));
  },
  cancelVerificationWorkflow(verificationId) {
    dispatch(cancelVerificationWorkflow(verificationId))
  }
})

export default connect(mapState, mapDispatch)(SimulationMonitor);