import LayoutData from '@/services/data/LayoutData';
import Boundaries from '@/services/helper/cutDesign/Boundaries';
import calcBoundary from '@/services/helper/cutDesign/boundary';
import { unitChange } from '@/services/helper/mathHelper';
import { ALL_PINS, getComponentPinLocation, PIN_GROUP, SINGLE_PIN, WAVE, AutoGeneratePorts } from '../../../services/ExtractionPortsHelper';
import DesignInfo from '../pcbInfo';

export function getNetsBoundaryArr({ clipSize, signals, selectedSignals, designId }) {
  const db = LayoutData.getLayout(designId);
  const boundaries = new Boundaries({ layoutDB: db, distance: updateDistance(clipSize, db.mUnits), clipSize });
  let arr = [];
  getSelectNets(signals, selectedSignals).forEach(net => {
    arr.push({ net, boundary: boundaries.getNetBoundary(net) });
  });
  return arr;
}

export function generateBoundaryList({ clipSize, signals, selectedSignals, designId, port_setups = [], ports_generate_setup_list = [], referenceNets = [] }) {
  if (!selectedSignals.length) return null;
  const _port_setups = JSON.parse(JSON.stringify(port_setups));
  const _ports_generate_setup_list = JSON.parse(JSON.stringify(ports_generate_setup_list));
  let _clipSize = clipSize;
  if (clipSize > 3) {
    _clipSize = 3;
  }
  const refPortBoundaryArr = getReferencePortBoundaryArr({
    clipSize: _clipSize,
    selectedSignals,
    port_setups: _port_setups,
    ports_generate_setup_list: _ports_generate_setup_list,
    designId,
    referenceNets
  });
  const db = LayoutData.getLayout(designId);
  let _unit = db.mUnits;
  if (_unit === "mils") {
    _unit = "mil";
  }
  let maxX = Infinity, maxY = Infinity, minX = -Infinity, minY = -Infinity;
  if (db.mProfile.mVertices.length) {
    maxX = Math.max(...db.mProfile.mVertices.map(d => d.mX));
    maxY = Math.max(...db.mProfile.mVertices.map(d => d.mY));
    minX = Math.min(...db.mProfile.mVertices.map(d => d.mX));
    minY = Math.min(...db.mProfile.mVertices.map(d => d.mY));
  }

  // BoundaryArr[[x1, y1], [x2, y2]]
  // When clipSize is greater than 3, will get a lot of points, and will encounter out of memory.
  // So first generate a boundary with a clip size of 3, and then expand the distance from this boundary to a distance of (clip size - 3)
  const vertices = getNetsBoundaryArr({ clipSize: _clipSize, signals, selectedSignals, designId }).concat(refPortBoundaryArr)
  const verticesArr = getVerticesArr(vertices);
  const data = calcBoundary(verticesArr, _clipSize, _unit, { maxX, maxY, minX, minY }, vertices);
  if (clipSize > 3) {
    const boundaries = new Boundaries({ layoutDB: db, distance: updateDistance(clipSize - 3, db.mUnits), clipSize: clipSize - 3 });
    const _verticesArr = boundaries.getGeneralBoundary(data.data);
    const _data = calcBoundary(_verticesArr, clipSize, _unit, { maxX, maxY, minX, minY });
    return { data: _data, boundaryPoints: { maxX, maxY, minX, minY } };
  } else {
    return { data, boundaryPoints: { maxX, maxY, minX, minY } };
  }
}

function getVerticesArr(vertices) {
  const _netsVertices = [...vertices];

  let _vertices = [];
  for (let item of _netsVertices) {
    const { boundary } = item;
    _vertices = _vertices.concat(boundary);
  }

  let obj = {};
  _vertices = _vertices.filter(item => {
    if (!obj[item.toString()]) {
      obj[item.toString()] = true;
      return item;
    }
    return false;
  });

  return _vertices;
}

export function getReferencePortBoundaryArr({ clipSize, selectedSignals, port_setups, ports_generate_setup_list, designId, referenceNets }) {
  const db = LayoutData.getLayout(designId);
  const boundaries = new Boundaries({ layoutDB: db, distance: updateDistance(clipSize, db.mUnits), clipSize });

  const refPins = getReferencePins({ selectedSignals, port_setups, ports_generate_setup_list, designId, referenceNets });
  let arr = [];
  for (const compInfo of refPins) {
    const compPins = getComponentPinLocation({
      scaling: 1,
      component: db.getComponent(compInfo.comp),
      compName: compInfo.comp,
      mPartMgr: db.mPartMgr,
      savePad: true
    }).filter(d => compInfo.pins.includes(d.number))
    arr.push(...boundaries.getPinsBoundaryArr(compPins));
  }
  return arr;
}

export function getSelectNets(signals, selectedSignals) {
  let names = [];
  signals.forEach(s => {
    if (selectedSignals.includes(s.name)) {
      s.nets_N && names.push(...s.nets_N)
      s.nets_P && names.push(...s.nets_P)
      s.nets_A && names.push(...s.nets_A)
      s.nets_B && names.push(...s.nets_B)
      s.nets_C && names.push(...s.nets_C)
    }
  });
  return names;
}

export function updateDistance(clipSize, unit) {
  let _unit = unit;
  if (_unit === "mils") {
    _unit = "mil";
  }
  const { number } = unitChange({ num: parseFloat(clipSize), oldUnit: 'mm', newUnit: _unit, decimals: 12 });
  return parseFloat(number);
}

function getReferencePins({ selectedSignals, port_setups, ports_generate_setup_list, designId, referenceNets }) {
  let arr = [];  // { refCompPin }
  let reGenerate = false;
  let _port_setups = [...port_setups];
  let _ports_generate_setup_list = [...ports_generate_setup_list];
  _ports_generate_setup_list.forEach(d => {
    const portType = d.setup.portType;
    const referenceType = d.setup.referenceType;
    if (portType === WAVE || referenceType === ALL_PINS) {
      d.setup.portType = PIN_GROUP;
      d.setup.referenceType = SINGLE_PIN;
      reGenerate = true;
    }
  });
  if (reGenerate) {
    const data = (new AutoGeneratePorts()).autoGeneratePorts(
      port_setups,
      DesignInfo.getPCBInfo(designId),
      {
        ports_generate_setup_list: _ports_generate_setup_list,
        designId,
        referenceNets,
        referenceZ0: "50"
      });

    _port_setups = JSON.parse(JSON.stringify(data.port_setups));
  }

  let compObj = {};
  function addPin(comp, pins) {
    if (compObj[comp] === undefined) {
      compObj[comp] = arr.length;
      arr[compObj[comp]] = new refCompPin(comp, pins);
    } else {
      arr[compObj[comp]].addPins(pins);
    }
  }

  for (const port of _port_setups) {
    if (selectedSignals.includes(port.info.signal) && port.negative) {
      if (port.negative.PINS_INFO) {
        for (const info of port.negative.PINS_INFO) {
          addPin(info.component, [info.pin])
        }
      } else if (port.negative.component && port.negative.pin) {
        addPin(port.negative.component, [port.negative.pin])
      }
    }
  }
  return arr;
}

function refCompPin(comp, pins) {
  this.comp = comp; // String
  this.pins = pins; // Array[String]
}

// pins/pin Array/String
refCompPin.prototype.addPins = function (pins) {
  this.pins = [...new Set(this.pins.concat(pins))]; // remove duplicates pins
}