import React, { Component } from 'react';
import PDN from './PDNSetting';
import TopBar from './PDNSetting/TopBar';
import Result from '../result'
import projectDesigns from '@/services/helper/projectDesigns';
import { PDN as PDNConst, RESULT, PCB } from '../constants';
import LayoutSingle from '../../../components/ContentLayout/layoutSingle';
import PCBLayout from './pcbLayout';
import { topBarResize } from '@/services/helper/topBarResize';
import './content.css';

class LayoutContent extends Component {
  topBarResize = () => {
    topBarResize('pdn-top-bar-block', 650);
  }

  getContent = (height) => {
    const { viewList, currentProjectId, currentProjectPDNs, leftWidth } = this.props;
    if (viewList.length > 0) {
      let view = viewList[0];
      switch (view) {
        case PCB:
          return projectDesigns.getAvailableDesignsLength(currentProjectId) > 0 ? <PCBLayout siderWidth={leftWidth} bottomHeight={height} /> : null;
        case PDNConst:
          setTimeout(() => {
            this.topBarResize();
          }, 100)
          return currentProjectPDNs.length > 0 ? <PDN /> : null
        case RESULT:
          return <Result leftWidth={leftWidth} />
        default: return null;
      }
    }
    return null;
  }

  topBar = () => {
    return <TopBar />;
  }

  getClassName = () => {
    const { viewList } = this.props;
    if (viewList.length > 0) {
      let view = viewList[0];
      switch (view) {
        case PDNConst:
          return 'fastpi-normal-setup-main';
        default:
          return '';
      }
    }
  }

  render() {
    return (
      <LayoutSingle
        {...this.props}
        id='pdn-content-main'
        className={this.getClassName()}
        getContent={this.getContent}
        topBar={this.topBar}
        topBarResize={this.topBarResize}
      />
    )
  }
}

export default LayoutContent;