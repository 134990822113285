import http from "../http";

/**
 * Upload spice card template ".xlsm" file
 * 2023/05/29
 * @param formData -> {projectId, name, file } */
export function uploadSpiceCard({ formData }) {
  return http.post(`/Himalayas/template/upload`, formData);
}

/**
 * delete spice card template by ids
 * 2023/05/29
 * @param {Array} channelIds  ->spice card template id list 
 * */
export function delSpiceCardTemplate(channelIds) {
  return http.post(`/Himalayas/channel/delete`, { channelIds });
}

/**
 * get spice card template content by id (not verificationId)
 * 2023/05/29
 * @param {string} id  ->spice card template id (channelId)
 * */
export function getSpiceCardTemplateContent(id) {
  return http.get(`/Himalayas/template/${id}`);
}

/**
 * run spice card template by id (not verificationId)
 * 2023/05/30
 * @param {string} id  ->spice card template id (channelId)
 * */
export function runSpiceCardTemplate(channelId) {
  //todo
  return http.post(`/Himalayas/template/run`, channelId)
}