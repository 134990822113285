import {
  OPEN_LICENSE_PAGE, UPDATE_LICENSE_TABLE, UPDATE_LICENSE_FEATURES_TABLE, UPDATE_LICENSE_USAGE_TABLE,
  UPDATE_LICENSE_USAGE_HISTORY, UPDATE_CURRENT_USAGE_TIME, UPDATE_USAGE_HISTORY_TIME, UPDATE_LICENSE_MODE, UPLOAD_LICENSE_MODE
} from "./actionType"

const defaultState = {
  page: '',
  licenseTable: [],
  licenseFeaturesTable: [],
  licenseUsageTable: [],
  licenseUsageHistory: [],
  usageHistorypagination: {},
  currentUsageTime: '',
  usageHistoryTime: '',
  licenseMode: '',
  licenseModeUrl: ''
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_LICENSE_PAGE:
      return {
        ...state,
        page: action.page
      }
    case UPDATE_LICENSE_TABLE:
      return {
        ...state,
        licenseTable: action.data
      }
    case UPDATE_LICENSE_FEATURES_TABLE:
      return {
        ...state,
        licenseFeaturesTable: action.data
      }
    case UPDATE_LICENSE_USAGE_TABLE:
      return {
        ...state,
        licenseUsageTable: action.data
      }
    case UPDATE_LICENSE_USAGE_HISTORY:
      const { content, totalElements, size, number } = action.data;
      return {
        ...state,
        licenseUsageHistory: content,
        usageHistorypagination: {
          ...state.usageHistorypagination,
          current: number + 1,
          pageSize: size,
          total: totalElements,
        }
      }
    case UPDATE_CURRENT_USAGE_TIME:
      return {
        ...state,
        currentUsageTime: action.time
      }
    case UPDATE_USAGE_HISTORY_TIME:
      return {
        ...state,
        usageHistoryTime: action.time
      }
    case UPDATE_LICENSE_MODE:
      return {
        ...state,
        licenseMode: action.mode,
        licenseModeUrl: action.url
      }
    default: return state
  }
}