import * as turf from "@turf/turf";

function calcMidPoint(point1, point2) {
  let _point1 = turf.point(point1);
  let _point2 = turf.point(point2);

  return turf.midpoint(_point1, _point2);
}

function calcParallelLine(point1, point2, distance) {
  const x1 = point1[0];
  const y1 = point1[1];
  const x2 = point2[0];
  const y2 = point2[1];

  const _distance = parseFloat(distance);
  //x1===x2,k not exist
  if (x1 === x2) {
    return {
      _point1: [x1 + _distance, y1],
      _point2: [x2 + _distance, y2]
    }
  }
  //y1===y2
  if (y1 === y2) {
    return {
      _point1: [x1, y1 + _distance],
      _point2: [x2, y2 + _distance]
    }
  }

  //calc slope k = (point2.y - point1.y) / (point2.x - point1.x)
  const k = (point2[1] - point1[1]) / (point2[0] - point1[0]);


  // Calculate the length of a line segment
  const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

  // Calculate unit vector
  const unitVectorX = (x2 - x1) / length;
  const unitVectorY = (y2 - y1) / length;

  // Determine the translation direction (left, right) based on the slope(k)
  const direction = (k >= 0) ? 1 : -1;

  // Calculate the coordinates of the translated points a and b
  const translatedAx = x1 + direction * _distance * unitVectorY;
  const translatedAy = y1 - direction * _distance * unitVectorX;
  const translatedBx = x2 + direction * _distance * unitVectorY;
  const translatedBy = y2 - direction * _distance * unitVectorX;

  const _point1 = [translatedAx, translatedAy],
    _point2 = [translatedBx, translatedBy];

  return { _point1, _point2 };
}


function getDisjoint(line1, line2) {
  let _line1 = turf.lineString(line1);
  let _line2 = turf.lineString(line2);

  return turf.booleanDisjoint(_line1, _line2);
}

function getLinesIntersectPoint(line1, line2) {
  let _line1 = turf.lineString(line1);
  let _line2 = turf.lineString(line2);
  return turf.lineIntersect(_line1, _line2);
}

function calcLinePointByDistance(start, end, distance) {

  const lineLength = Math.sqrt((end[0] - start[0]) * (end[0] - start[0]) + (end[1] - start[1]) * (end[1] - start[1]))
  if (distance > lineLength) {
    return null;
  }

  if (distance === lineLength) {
    return end;
  }

  const t = distance / lineLength;
  const x = start[0] + t * (end[0] - start[0]),
    y = start[1] + t * (end[1] - start[1]);
  return [x, y];
}

/* arc:{start:[x,y],end:[x,y],center:[x,y]}
line: [[x,y],[x,y]]
 */
function _calcIntersectByArcAndLine(arc, line) {
  // 计算线段的向量：AB = (x2 - x1, y2 - y1)
  const AB = [];
  AB[0] = line[1][0] - line[0][0];
  AB[1] = line[1][1] - line[0][1];
  //计算起点到圆心的向量：AC = (cx - x1, cy - y1)
  const AC = [];
  AC[0] = arc.center[0] - line[0][0];
  AC[1] = arc.center[1] - line[0][1];

  //计算向量AB和向量AC的点积：dot_product = AB · AC = AB.x * AC.x + AB.y * AC.y
  const dot_product = AB[0] * AC[0] + AB[1] * AC[1];
  //计算线段的长度的平方：segment_length_squared = AB.x^2 + AB.y^2
  const segment_length_squared = AB[0] * AB[0] + AB[1] * AB[1];
  //计算参数t：t = dot_product / segment_length_squared.如果t的值小于0，则交点在线段的起点之前；如果t的值大于1，则交点在线段的终点之后；如果t的值在0和1之间，则交点在线段上。
  const t = dot_product / segment_length_squared;
  if (t < 0 || t > 1) {
    return null
  }
  //计算交点的坐标：intersection_x = x1 + t * (x2 - x1), intersection_y = y1 + t * (y2 - y1)
  const intersection1 = [], intersection2 = [];
  intersection1[0] = line[0][0] + t * (line[1][0] - line[0][0]);
  intersection1[1] = line[0][1] + t * (line[1][1] - line[0][1]);
  // 计算弧线的半径
  const r = Math.sqrt((arc.start[0] - arc.center[0]) ** 2 + (arc.start[1] - arc.center[1]) ** 2);
  const squaredDistance = (arc.center[0] - intersection1[0]) * (arc.center[0] - intersection1[0]) + (arc.center[1] - intersection1[1]) * (arc.center[1] - intersection1[1]);

  if (t < 0 || t > 1 || squaredDistance > r * r) {
    console.error("No intersection");
    return null;
  }

  const _t = 1 - t;
  if (t === 0.5) {
    return intersection1
  }

  intersection2[0] = line[0][0] + _t * (line[1][0] - line[0][0]);
  intersection2[1] = line[0][1] + _t * (line[1][1] - line[0][1]);

  if (intersection1.length && !intersection2.length && !isNaN(intersection1[0]) && !isNaN(intersection1[1])) {
    return intersection1;
  }

  if (!intersection1.length && intersection2.length && !isNaN(intersection2[0]) && !isNaN(intersection2[1])) {
    return intersection2;
  }

  if (intersection1.length && !isNaN(intersection1[0]) && !isNaN(intersection1[1]) && isPointOnArc(arc, intersection1, r)) {
    return intersection1
  }

  if (intersection2.length && !isNaN(intersection2[0]) && !isNaN(intersection2[1]) && isPointOnArc(arc, intersection2, r)) {
    return intersection2
  }
  /*  const a_radian = isPointOnArc(arc, [intersection_x, intersection_y], r, true),
     b_radian = isPointOnArc(arc, [_intersection_x, _intersection_y], r, true);
 
   if (a_radian <= b_radian) {
     return [intersection_x, intersection_y]
   } else {
     return [_intersection_x, _intersection_y]
   } */
}

function calcIntersectByArcAndLine(arc, line) {
  //将线段表示为参数方程形式x2 - x1,y2 - y1
  const dx = line[1][0] - line[0][0],
    dy = line[1][1] = line[0][1];

  // 计算弧线的半径
  const r = Math.sqrt((arc.start[0] - arc.center[0]) ** 2 + (arc.start[1] - arc.center[1]) ** 2)

  //计算二次方程的系数
  const a = dx * dx + dy * dy,
    b = 2 * (dx * (line[0][0] - arc.center[0]) + dy * (line[0][1] - arc.center[1])),
    c = (line[0][0] - arc.center[0]) * (line[0][0] - arc.center[0]) + (line[0][1] - arc.center[1]) * (line[0][1] - arc.center[1]) - r * r;

  //计算判别式
  const discriminant = b * b - 4 * a * c;
  //如果判别式小于0，表示没有交点
  if (discriminant < 0) {
    return null;
  }

  if (discriminant === 0) {
    // One intersection between the circle and the line segment
    const t = -b / (2 * a);
    if (t >= 0 && t <= 1) {
      const intersectionX = line[0][0] + t * dx;
      const intersectionY = line[0][1] + t * dy;
      return [intersectionX, intersectionY];
    }
  }

  //计算交点参数 t
  const t1 = (-b + Math.sqrt(discriminant)) / (2 * a),
    t2 = (-b - Math.sqrt(discriminant)) / (2 * a);

  let intersection1 = null, intersection2 = null;
  if (t1 >= 0 && t1 <= 1) {
    intersection1 = [line[0][0] + t1 * dx, line[0][1] + t1 * dy]
  }

  if (t2 >= 0 && t2 <= 1) {
    intersection2 = [line[0][0] + t2 * dx, line[0][1] + t2 * dy]
  }

  if (intersection1 && !intersection2 && !isNaN(intersection1[0]) && !isNaN(intersection1[1])) {
    return intersection1;
  }

  if (!intersection1 && intersection2 && !isNaN(intersection2[0]) && !isNaN(intersection2[1])) {
    return intersection2;
  }

  if (intersection1 && !isNaN(intersection1[0]) && !isNaN(intersection1[1]) && isPointOnArc(arc, intersection1, r)) {
    return intersection1
  }

  if (intersection2 && !isNaN(intersection2[0]) && !isNaN(intersection2[1]) && isPointOnArc(arc, intersection2, r)) {
    return intersection2
  }

  return null;
}

function ___calcIntersectByArcAndLine(arc, line) {
  const r = Math.sqrt((arc.start[0] - arc.center[0]) * (arc.start[0] - arc.center[0]) + (arc.start[1] - arc.center[1]) * (arc.start[1] - arc.center[1]));

  const cx = arc.center[0], cy = arc.center[1];
  const x1 = line[0][0],
    y1 = line[0][1],
    x2 = line[1][0],
    y2 = line[1][1];

  const dx = x2 - x1;
  const dy = y2 - y1;
  const hRatio = ((cx - x1) * dx + (cy - y1) * dy) / (dx * dx + dy * dy);
  const hX = x1 + hRatio * dx;
  const hY = y1 + hRatio * dy;

  const distanceHC = Math.sqrt((hX - cx) * (hX - cx) + (hY - cy) * (hY - cy));

  if (distanceHC > r) {
    // No intersection between the circle and the line segment
    return [];
  } else if (distanceHC === r) {
    // One intersection between the circle and the line segment
    return [hX, hY];
  } else {
    // Two intersections between the circle and the line segment
    const p1X = cx + r * (hX - cx) / distanceHC;
    const p1Y = cy + r * (hY - cy) / distanceHC;
    const p2X = cx - r * (hX - cx) / distanceHC;
    const p2Y = cy - r * (hY - cy) / distanceHC;
    const intersection1 = !isNaN(p1X) && !isNaN(p1Y) ? [p1X, p1Y] : null;
    const intersection2 = !isNaN(p2X) && !isNaN(p2Y) ? [p2X, p2Y] : null;

    if (intersection1 && !intersection2 && !isNaN(intersection1[0]) && !isNaN(intersection1[1])) {
      return intersection1;
    }

    if (!intersection1 && intersection2 && !isNaN(intersection2[0]) && !isNaN(intersection2[1])) {
      return intersection2;
    }

    if (intersection1 && !isNaN(intersection1[0]) && !isNaN(intersection1[1]) && isPointOnArc(arc, intersection1, r)) {
      return intersection1
    }

    if (intersection2 && !isNaN(intersection2[0]) && !isNaN(intersection2[1]) && isPointOnArc(arc, intersection2, r)) {
      return intersection2
    }
  }
}

//Determine whether the point is on the arc
function _isPointOnArc(arc, point, returnRadian = false) {
  //计算向量 AC 和向量 BC：
  // AC = (D.x - C.x, D.y - C.y) = (4 - 5, 3 - 5) = (-1, -2)
  // BC = (B.x - C.x, B.y - C.y) = (8 - 5, 2 - 5) = (3, -3)

  //计算点到圆心的向量：AC = (cx - x1, cy - y1)
  const AC = [];
  AC[0] = arc.center[0] - point[0];
  AC[1] = arc.center[1] - point[1];
  //计算终点到圆心的向量：BC = (cx - x1, cy - y1)
  const BC = [];
  BC[0] = arc.center[0] - arc.end[0];
  BC[1] = arc.center[1] - arc.end[1];
  //计算向量 AC 和向量 BC 的单位向量： AC/||AC||, BC/||BC||
  const AC_unit = [], BC_unit = [];
  const unit = Math.sqrt(AC[0] * AC[0] + AC[1] * AC[1]);
  AC_unit[0] = AC[0] / unit;
  AC_unit[1] = AC[1] / unit;

  const _unit = Math.sqrt(BC[0] * BC[0] + BC[1] * BC[1]);
  BC_unit[0] = BC[0] / _unit;
  BC_unit[1] = BC[1] / _unit;
  //计算向量 AC_unit 和向量 BC_unit 的夹角 θ： arccos(AC_unit · BC_unit), AC_unit.x * BC_unit.x + AC_unit.y * BC_unit.y
  const dot_product = AC_unit[0] * BC_unit[0] + AC_unit[1] * BC_unit[1];
  const radianAngle = Math.acos(dot_product);

  //计算弧 AB 的夹角 α：AB_angle = arccos((B.x - A.x) / ||AB||) 
  const AB_radianAngle = Math.acos((arc.end[0] - arc.start[0]) / Math.sqrt((arc.end[0] - arc.start[0]) * (arc.end[0] - arc.start[0]) + (arc.end[1] - arc.start[1]) * (arc.end[1] - arc.start[1])))
  const _radian = Math.abs(radianAngle - AB_radianAngle);
  //if radianAngle > AB_radianAngle || adianAngle < AB_radianAngle , return false
  if (!returnRadian) {
    return _radian < 0.01 ? true : false;
  }
  return _radian;
}

function isPointOnArc(arc, point, r) {
  // 计算点到圆心的距离

  var d = Math.sqrt((point[0] - arc.center[0]) ** 2 + (point[1] - arc.center[1]) ** 2);

  // 判断点是否在圆上
  if (Math.abs(d - r) >= 0.01) {
    return false;
  }

  // 计算点的角度
  var angle = Math.atan2(point[1] - arc.center[1], point[0] - arc.center[0]);

  // 调整角度范围，使终点角度大于起点角度
  if (Math.atan2(arc.end[1] - arc.center[1], arc.end[0] - arc.center[0]) < Math.atan2(arc.start[1] - arc.center[1], arc.start[0] - arc.center[0])) {
    angle += 2 * Math.PI;
  }

  // 判断角度是否在起点角度和终点角度之间
  if (angle >= Math.atan2(arc.start[1] - arc.center[1], arc.start[0] - arc.center[0]) && angle <= Math.atan2(arc.end[1] - arc.center[1], arc.end[0] - arc.center[0])) {
    return true;
  }

  return false;
}

function calculateArcLength(arc) {

  // 计算弧线的半径
  const r = Math.sqrt((arc.start[0] - arc.center[0]) ** 2 + (arc.start[1] - arc.center[1]) ** 2)

  // 计算起点 A 和圆心 C 的向量 AC
  const AC = (arc.start[0] - arc.center[0], arc.start[1] - arc.center[1])

  //计算终点 B 和圆心 C 的向量 BC
  const BC = (arc.end[0] - arc.center[0], arc.end[1] - arc.center[1])

  //计算向量 AC 和向量 BC 的夹角 θ
  const dot_product = AC[0] * BC[0] + AC[1] * BC[1]
  const AC_length = Math.sqrt(AC[0] ** 2 + AC[1] ** 2)
  const BC_length = Math.sqrt(BC[0] ** 2 + BC[1] ** 2)
  const theta = Math.acos(dot_product / (AC_length * BC_length))

  // 计算弧线的长度
  const arc_length = theta * r

  return arc_length
}

function calculateArcEndpoint(arc, start_x, start_y, distance) {
  // 计算起点 A 和圆心 C 的向量 AC
  const startAngle = Math.atan2(start_y - arc.center[1], start_x - arc.center[0]);

  //计算向量 AC 的长度（弧线半径） r
  const r = Math.sqrt((start_x - arc.center[0]) ** 2 + (start_y - arc.center[1]) ** 2)

  //计算截取弧线的终点角度 endAngle
  let endAngle = startAngle + (parseFloat(distance) / r)

  //将终点角度限制在 0 到 2π 之间
  endAngle = endAngle % (2 * Math.PI);

  // 计算截取终点的坐标
  const _end_x = arc.center[0] + r * Math.cos(endAngle)
  const _end_y = arc.center[1] + r * Math.sin(endAngle)

  if (isNaN(_end_x) || isNaN(_end_y)) {
    return null;
  }

  const arcLength = calculateArcLength({ ...arc, start: [start_x, start_y] });
  const newArcLength = calculateArcLength({ ...arc, start: [start_x, start_y], end: [_end_x, _end_y] });
  if (newArcLength > arcLength) {
    return null;
  }
  return [_end_x, _end_y]
}


export {
  calcMidPoint,
  calcParallelLine,
  getDisjoint,
  getLinesIntersectPoint,
  calcLinePointByDistance,
  calcIntersectByArcAndLine,
  calculateArcEndpoint
}