import CeGeomTools from './CeGeomTools';
import CeGeometry from './CeGeometry';
import CeVertex from './CeVertex';
import GCPolygon from './GCPolygon';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeRectangle = function (type) {
  var geomType = type;
  if (type == null || type === undefined)
    geomType = "Rectangle";
  CeGeometry.call(this, geomType);
  this.mWidth = 0;
  this.mHeight = 0;
  this.mCenter = new CeVertex();
}

// subclass extends superclass
CeRectangle.prototype = Object.create(CeGeometry.prototype);
CeRectangle.prototype.constructor = CeRectangle;

CeRectangle.prototype.SetData = function (cx, cy, w, h) {
  this.mWidth = w;
  this.mHeight = h;
  this.mCenter.SetData(cx, cy);
}

CeRectangle.prototype.CopyFrom = function (fromObj) {
  CeGeometry.prototype.CopyFrom.call(this, fromObj);
  this.mCenter = fromObj.mCenter.Clone();
  this.mWidth = fromObj.mWidth;
  this.mHeight = fromObj.mHeight;
}

CeRectangle.prototype.Clone = function () {
  var clnObj = new CeRectangle();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeRectangle.prototype.MoveToCenterLocalCS = function () {
  var center = this.mCenter.Clone();
  this.mCenter.mX = 0.0;
  this.mCenter.mY = 0.0;
  return center;
}

CeRectangle.prototype.equals = function (obj) {
  if (!(obj instanceof CeRectangle))
    return false;

  if (this.mCenter.equals(obj.mCenter) == false ||
    CeGeomTools.TwoNumberEqual(this.mWidth, obj.mWidth) == false ||
    CeGeomTools.TwoNumberEqual(this.mHeight, obj.mHeight) == false)
    return false;

  return true;
}

CeRectangle.prototype.ConvertToGCPolygon = function () {
  var xArray = [];
  var yArray = [];
  var halfW = 0.5 * this.mWidth;
  var halfH = 0.5 * this.mHeight;
  xArray.push(this.mCenter.mX - halfW);
  yArray.push(this.mCenter.mY - halfH);
  xArray.push(this.mCenter.mX + halfW);
  yArray.push(this.mCenter.mY - halfH);
  xArray.push(this.mCenter.mX + halfW);
  yArray.push(this.mCenter.mY + halfH);
  xArray.push(this.mCenter.mX - halfW);
  yArray.push(this.mCenter.mY + halfH);
  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(4, xArray, yArray);
  return gcPolygon;
}

CeRectangle.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.GetDataString();
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeRectangle.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeRectangle.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mCenter.GetDataString());
  defData.push(this.mWidth);
  defData.push(this.mHeight);
  return defData;
}

CeRectangle.prototype.UpdateByString = function (dataVals) {

  // centerX, centerY, diameter
  if (dataVals.size() < 4)
    return false;

  var centerVals = new StringList();
  centerVals.push(dataVals.get(0));
  centerVals.push(dataVals.get(1));
  this.mCenter = new CeVertex();
  this.mCenter.UpdateByString(centerVals);

  this.mWidth = parseFloat(dataVals.get(2));
  this.mHeight = parseFloat(dataVals.get(3));

  return true;
}

CeRectangle.prototype.Transform = function (celocation) {
  this.mCenter.Transform(celocation.mRotAngle, celocation.mCW, celocation.mPosition);
}

export default CeRectangle;