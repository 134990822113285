import { PRE_LAYOUT } from "../../../constants/designVendor";
import { PROJECT_V2, PROJECT_BMA } from "../../../constants/projectVersion";
import { CARD_VERIFICATION, PACKAGE_PDN, PACKAGE_VERIFICATION, VERIFICATION, MULTIPLE_CHANNEL, PCB_CHANNEL, PCB_PDN } from "../../../constants/treeConstants";
import designConstructor from "../../helper/designConstructor";
import { ROCKY_PDN, SSN_VERIFICATION } from "../constants";

export const REPORT = 'report',
  DEBUG = 'debug',
  SIMULATION_OPTIONS = 'simulation_options',
  PDN = 'pdn',
  EYEMASK = 'eyeMask',
  SERVICES_OPTIONS = 'services_options',
  EXTRACTION_OPTIONS = 'extraction_options',
  EXTRACTION_PORTS = 'extraction_ports',
  RE_CALCULATE_EYE = 're_calculate_eyediagram',
  DOWNLOAD = 'download',
  PADSTACKS = 'via_padstacks',
  SIMULATION = 'simulation',
  PDN_EXTRACTION_OPTIONS = 'pdn_extraction_options',
  SIGNAL_EXTRACTION_OPTIONS = 'signal_extraction_options',
  PKG_PDN_EXTRACTION_OPTIONS = 'pkg_pdn_extraction_options',
  PACKAGE_SETTING = 'pkg_setting',
  MODELING = 'modeling',
  DDR = 'DDR',
  ROCKY_PACKAGE = 'Rocky_Package',
  SAVE = "save",
  STACKUP = "stackup",
  RUN = "run",
  LAYOUT = "layout",
  DISPLAY = 'display',
  ADVANCED = "advanced",
  SPLIT_LINE = "split_line",
  SIMULATION_MENU = "simulation_menu",
  MODELING_MENU = "modeling_menu",
  PCB_CROSSTALK = "pcb_crosstalk",
  WIRE_BOND_PROFILE = "wire_bond_profile",
  MODELING_RUN = 'modeling_run',
  RESULT = "result",
  MULTIPLE_ADVANCED = "multiple_advanced",
  CUSTOM_POST_PROCESS = "custom_post_process",
  DATA_MANAGEMENT = "Data Management",
  DATA_ANALYSIS = "Data Analysis",
  BMA_FILES = "File",
  VIXMASK = "vixmask";

const TOP_MENU_TITLE = {
  [STACKUP]: "Stackup",
  [PCB_CROSSTALK]: "PCB Crosstalk",
  [EYEMASK]: "Eye Mask",
  [PADSTACKS]: "Via Padstacks",
  [SERVICES_OPTIONS]: "Services Options",
  [EXTRACTION_OPTIONS]: "Solver Options",
  [EXTRACTION_PORTS]: "Port Configuration",
  [WIRE_BOND_PROFILE]: "Wire Bond Profile",
  [PACKAGE_SETTING]: "Components",
  [MODELING_RUN]: "Run",
  [CUSTOM_POST_PROCESS]: "Custom Post-Processing",
  [VIXMASK]: "Spec"
}

function getTopBarItem({ contentType, viewList, findDesignId, currentChannelId, projectVersion }) {
  let list = [];
  switch (contentType) {
    case ROCKY_PDN:
      list = viewList.includes(VERIFICATION) ?
        [RUN, REPORT, DEBUG, PDN_EXTRACTION_OPTIONS, STACKUP, PADSTACKS, EYEMASK, SERVICES_OPTIONS]
        : [REPORT, STACKUP, PADSTACKS, DEBUG];
      break;
    case VERIFICATION:
    case SSN_VERIFICATION:
      list = viewList.includes(VERIFICATION) ? [RUN, REPORT, DEBUG, SIMULATION_OPTIONS, EXTRACTION_OPTIONS, EXTRACTION_PORTS, PCB_CROSSTALK, STACKUP, PADSTACKS, EYEMASK, SERVICES_OPTIONS, VIXMASK] :
        viewList.includes(SSN_VERIFICATION) ? [MODELING_RUN, RUN, REPORT, DEBUG, SIMULATION_OPTIONS, EYEMASK, SERVICES_OPTIONS, CUSTOM_POST_PROCESS] :
          currentChannelId ? [REPORT, STACKUP, PADSTACKS, DEBUG, SERVICES_OPTIONS] : [REPORT, STACKUP, PADSTACKS, DEBUG, SERVICES_OPTIONS];
      break;
    case PACKAGE_VERIFICATION:
      list = viewList.includes(PACKAGE_VERIFICATION)
        ? projectVersion === PROJECT_V2 ? [RUN, EXTRACTION_OPTIONS, EXTRACTION_PORTS, STACKUP, PADSTACKS, WIRE_BOND_PROFILE, DOWNLOAD, SERVICES_OPTIONS]
          : [RUN, EXTRACTION_OPTIONS, EXTRACTION_PORTS, REPORT, STACKUP, PADSTACKS, WIRE_BOND_PROFILE, DOWNLOAD, SERVICES_OPTIONS]
        : [REPORT, STACKUP, PADSTACKS, WIRE_BOND_PROFILE, DEBUG, SERVICES_OPTIONS];
      break;
    case CARD_VERIFICATION:
      list = viewList.includes(CARD_VERIFICATION) ?
        [RUN, EXTRACTION_OPTIONS, EXTRACTION_PORTS, STACKUP, PADSTACKS, DEBUG, SAVE]
        : [REPORT, STACKUP, PADSTACKS, DEBUG];
      break;
    case PACKAGE_PDN:
      list = [RUN, PKG_PDN_EXTRACTION_OPTIONS, STACKUP, PADSTACKS, DOWNLOAD, PACKAGE_SETTING, WIRE_BOND_PROFILE, SERVICES_OPTIONS];
      break;
    case PCB_PDN:
      list = [RUN, PKG_PDN_EXTRACTION_OPTIONS, STACKUP, PADSTACKS, DOWNLOAD, SERVICES_OPTIONS];
      break;
    case MULTIPLE_CHANNEL:
      list = viewList.includes(MULTIPLE_CHANNEL) ? [MODELING_RUN, EXTRACTION_OPTIONS, MULTIPLE_ADVANCED, DOWNLOAD] : [REPORT, STACKUP, PADSTACKS, DEBUG]
      break;
    case PCB_CHANNEL:
      list = [RUN, STACKUP, PADSTACKS, EXTRACTION_OPTIONS, EXTRACTION_PORTS, DOWNLOAD, SERVICES_OPTIONS]
      break;
    default:
      list = projectVersion === PROJECT_BMA ? [] : [REPORT, STACKUP, PADSTACKS, DEBUG];
      break;
  }
  //pre layout / no pcb
  if (!findDesignId) {
    list = list.filter(item => ![PADSTACKS, STACKUP].includes(item))
  }
  let leftMenu = contentType === MULTIPLE_CHANNEL && viewList.includes(MULTIPLE_CHANNEL) ? MULTIPLE_TOP_MENU_LIST : TOP_MENU_LIST;
  let rightMenu = [...RIGHT_MENU_LIST];
  if ([PCB_CHANNEL, PCB_PDN, PACKAGE_PDN, PACKAGE_VERIFICATION].includes(contentType)) {
    leftMenu = leftMenu.filter(item => item !== DOWNLOAD)
    rightMenu = [...rightMenu, DOWNLOAD]
  }
  const left = mergeTopBarMenu(list, leftMenu);
  const right = mergeTopBarMenu(list, rightMenu);
  return { left, right };
}


const TOP_MENU_LIST = [RUN, REPORT, SPLIT_LINE, PACKAGE_SETTING, SPLIT_LINE, MODELING_RUN, LAYOUT, MODELING_MENU, PDN_EXTRACTION_OPTIONS, PKG_PDN_EXTRACTION_OPTIONS, SIMULATION_OPTIONS, SPLIT_LINE, DISPLAY, ADVANCED, DOWNLOAD];
const MULTIPLE_TOP_MENU_LIST = [MODELING_RUN, SPLIT_LINE, EXTRACTION_OPTIONS, MULTIPLE_ADVANCED, SPLIT_LINE, DOWNLOAD];
const SUB_MENU_LIST = [LAYOUT, MODELING_MENU, DISPLAY, ADVANCED];
const RIGHT_MENU_LIST = [SAVE, DEBUG];

function mergeTopBarMenu(list, menu) {
  let menuList = [];
  for (let key of menu) {
    if (SUB_MENU_LIST.includes(key)) {
      const children = getChildrenByMenuKey(key).filter(item => list.includes(item));
      if (children.length) {
        menuList.push({
          key,
          children
        })
      }
    } else if (list.includes(key) || (key === SPLIT_LINE)) {
      // The current one is split_ When on line, the next cannot be a split_ Line
      if (key === SPLIT_LINE && (!menuList.length || (menuList[menuList.length - 1] && menuList[menuList.length - 1].key === SPLIT_LINE))) { continue }
      menuList.push({
        key
      })
    }
  }

  if (menuList[menuList.length - 1] && menuList[menuList.length - 1].key === SPLIT_LINE) {
    if (menuList[menuList.length - 2] && menuList[menuList.length - 2].key === SPLIT_LINE) {
      menuList.splice(menuList.length - 2, 1)
    }
    menuList.splice(menuList.length - 1, 1)
  }

  return menuList;
}

function getChildrenByMenuKey(key) {
  switch (key) {
    case LAYOUT:
      return [STACKUP, PADSTACKS, WIRE_BOND_PROFILE];
    case DISPLAY:
      return [EYEMASK, VIXMASK];
    case ADVANCED:
      return [SERVICES_OPTIONS, PCB_CROSSTALK, CUSTOM_POST_PROCESS];
    case MODELING_MENU:
      return [EXTRACTION_OPTIONS, EXTRACTION_PORTS];
    default: return [];
  }
}

function getMenuTitle(key) {
  switch (key) {
    case EYEMASK:
      return "Eye Mask";
    case STACKUP:
      return "Stackup";
    case PADSTACKS:
      return "Via Padstacks";
    case SERVICES_OPTIONS:
      return "Services Options";
    case PCB_CROSSTALK:
      return "PCB Crosstalk";
    case EXTRACTION_OPTIONS:
      return "Solver Options";
    case EXTRACTION_PORTS:
      return "Port Configuration";
    case PACKAGE_SETTING:
      return "Components";
    case CUSTOM_POST_PROCESS:
      return "Custom Post-Processing"
    case VIXMASK:
      return "Spec";
    default: return key;
  }
}

function getCurrentDesign({
  contentType,
  verDesignId,
  cardDesignId,
  pkgDesignId,
  pdnDesignId,
  currentProjectId,
  pkgPDNDesignId,
  viewList
}) {
  let designId = null;
  switch (contentType) {
    case VERIFICATION:
      if (viewList.includes(VERIFICATION)) {
        designId = verDesignId;
      }
      break;
    case CARD_VERIFICATION:
      designId = cardDesignId;
      break;
    case PACKAGE_VERIFICATION:
      designId = pkgDesignId;
      break;
    case ROCKY_PDN:
      designId = pdnDesignId;
      break;
    case PACKAGE_PDN:
    case PCB_PDN:
      designId = pkgPDNDesignId;
      break;
    default: break;
  }

  if (designId && !isPreLayout(designId)) {
    return designId
  }

  if (!designId) {
    const findDesignId = designConstructor.getAvailableFirstPCBDesignsId(currentProjectId);
    return findDesignId ? findDesignId : null;//preLayout / no pcb
  }
}

function isPreLayout(designId) {
  const design = designConstructor.getDesign(designId);

  if (design && design.vendor === PRE_LAYOUT) {
    return true;
  }
  return false;
}

export {
  getTopBarItem,
  getMenuTitle,
  TOP_MENU_TITLE,
  isPreLayout,
  getCurrentDesign
}