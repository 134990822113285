import React, { Component, Fragment } from 'react';
import { Select, Table, Tooltip } from 'antd';
import { getDelayData, getHistoryDelayData, sortDelaySignals, getExperimentDelayData } from '../../../services/Andes_v2/results/delay';
import { getVerificationJson, getHistoryVerificationJson, getExperimentVerificationJson } from '../../../services/Andes_v2/results';
import { VERIFY_SUCCESS } from '../../../constants/verificationStatus';
import { EXPERIMENTS } from '../../../constants/treeConstants';
import { CPHY } from '../../../services/PCBHelper/constants';
import { getSignalGroup } from '../../../services/Andes_v2/results/sparameter/endToEnd';

const Columns = [{
  title: 'Signal',
  dataIndex: 'SignalName',
  width: '33%'
}, {
  title: 'Delay (ps)',
  dataIndex: 'Delay',
  width: '33%'
}, {
  title: 'Skew (ps)',
  dataIndex: 'PNSkew',
  width: '33%'
}];

const ColumnsV2 = [
  {
    title: 'Signal',
    dataIndex: 'signal',
    width: "12%",
    render: (name, record) => {
      return {
        children: <span>{name}</span>,
        props: {
          rowSpan: record.index % 2 === 0 ? 2 : 0,
          className: `andes-v2-delay-result-column-signal ${record.className}`
        }
      }
    }
  },
  {
    title: 'Net',
    dataIndex: 'net',
    render: (name, record) => {
      return {
        children: <span>{name}</span>
      }
    }
  },
  {
    title: 'Component1',
    dataIndex: 'comp1',
    className: 'andes-v2-delay-result-column-comp',
    width: "8%",
    colSpan: 2,
    render: (comp1, record) => {
      return {
        children: comp1._signal ? <Tooltip overlayClassName='aurora-tooltip' title={comp1.signal}><span>{comp1._signal}</span></Tooltip> : <span>{comp1.signal}</span>,
        props: {
          rowSpan: record.index % 2 === 0 ? 2 : 0,
          className: `andes-v2-delay-result-column-comp ${record.className}`
        }
      }
    }
  },
  {
    title: '',
    dataIndex: 'pin1',
    width: "14%",
    colSpan: 0,
    render: (data, record) => {
      return {
        children: <span>{record.comp1.pin}</span>,
      }
    }
  },
  {
    title: 'Component2',
    dataIndex: 'comp2',
    className: 'andes-v2-delay-result-column-comp',
    width: "8%",
    colSpan: 2,
    render: (comp2, record) => {
      return {
        children: comp2._signal ? <Tooltip overlayClassName='aurora-tooltip' title={comp2.signal}><span>{comp2._signal}</span></Tooltip> : <span>{comp2.signal}</span>,
        props: {
          rowSpan: record.index % 2 === 0 ? 2 : 0,
          className: `andes-v2-delay-result-column-comp ${record.className}`
        }
      }
    }
  },
  {
    title: '',
    dataIndex: 'pin2',
    width: "14%",
    colSpan: 0,
    render: (comp2, record) => {
      return {
        children: <span>{record.comp2.pin}</span>,
      }
    }
  },
  {
    title: 'Differential Delay (ps)',
    dataIndex: 'diff_delay',
    render: (diff_delay, record) => {
      return {
        children: <span>{diff_delay}</span>,
        props: {
          rowSpan: record.index % 2 === 0 ? 2 : 0,
          className: record.className || ""
        }
      }
    }
  },
  {
    title: 'Single-ended Delay (ps)',
    dataIndex: 'delay',
  },
  {
    title: 'Skew (ps)',
    dataIndex: 'skew',
    className: 'andes-v2-delay-result-column-skew',
    render: (skew, record) => {
      return {
        children: <span style={{ color: skew < 160 ? '' : 'red' }}>{skew}</span>,
        props: {
          rowSpan: record.index % 2 === 0 ? 2 : 0,
          className: `andes-v2-delay-result-column-skew ${record.className}`
        }
      }
    }
  }]

const Option = Select.Option;
class Delay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      selected: this.getDefaultSelected()
    }
  }

  componentDidMount() {
    const { selected } = this.state;
    const { id, resultPageType, status } = this.props;
    if (resultPageType === EXPERIMENTS) {
      this.getExperimentResult(selected);
    } else if (id && status === VERIFY_SUCCESS) {
      this.getResult();
    }
  }

  componentDidUpdate(prevProps) {
    const { id, resultPageType, updateExperimentListStatus } = this.props;
    if (id !== prevProps.id) {
      if (resultPageType === EXPERIMENTS) {
        const keys = this.getDefaultSelected();
        this.getExperimentResult(keys);
      } else if (id) {
        this.getResult();
      }
    }

    if (prevProps.updateExperimentListStatus !== updateExperimentListStatus && updateExperimentListStatus) {
      const defaultKeys = this.getDefaultSelected();
      this.resultsSelect(defaultKeys)
      this.props.changeUpdateExperimentListStatus(false);
    }
  }

  getDefaultSelected() {
    const { experimentList, status, resultPageType } = this.props;
    if (resultPageType === EXPERIMENTS) {
      const selectedList = experimentList.map(item => item.id);
      if (status === VERIFY_SUCCESS) {
        selectedList.unshift("default");
      }
      return selectedList;
    } else {
      return ['current']
    }
  }

  getResult = async () => {
    const { id, isEndToEnd, interfaceType } = this.props;
    this.setState({
      data: {},
      selected: ['current']
    });
    this.interfaceContent = await getVerificationJson(id, isEndToEnd) || {};
    let dataList = [], v2 = '';
    if (interfaceType === CPHY) {
      const signalsData = isEndToEnd ? getSignalGroup(this.interfaceContent) : this.interfaceContent.content.signals
      for (const signal of (signalsData || [])) {
        for (const pair of ['AB', 'BC', 'CA']) {
          const _components = isEndToEnd ? [...signal.beginChannel.components, ...signal.endChannel.components] : this.interfaceContent.content.components
          const delayData = await getDelayData(id, isEndToEnd, pair, signal.name, _components);
          if (!delayData) continue;
          dataList.push(...delayData.dataList);
          v2 = delayData.v2;
        }
      }
      if (!dataList || dataList.length < 0) return;
      dataList[dataList.length - 2].className = "border-bottom-0";
    } else {
      const delayData = await getDelayData(id, isEndToEnd) || {};
      dataList = delayData.dataList;
      v2 = delayData.v2;
    }
    let { data } = this.state;
    data.current = {
      result: sortDelaySignals(this.interfaceContent, dataList, isEndToEnd, v2),
      v2
    };
    this.setState({
      data
    })
  }

  getCPHYHistoryResult = async (historyKeys) => {
    let { data } = this.state;
    const { isEndToEnd } = this.props;
    for (const key of historyKeys) {
      let dataList = [], v2 = '';
      const setupInfo = await getHistoryVerificationJson(key, isEndToEnd)
      const signalsData = isEndToEnd ? getSignalGroup(setupInfo) : setupInfo.content.signals
      for (const signal of (signalsData || [])) {
        for (const pair of ['AB', 'BC', 'CA']) {
          const _components = isEndToEnd ? [...signal.beginChannel.components, ...signal.endChannel.components] : setupInfo.content.components
          const delayData = await getHistoryDelayData(key, isEndToEnd, pair, signal.name, _components);
          if (!delayData) continue;
          dataList.push(...delayData.dataList);
          v2 = delayData.v2;
        }
      }
      if (!dataList || dataList.length < 0) continue;
      dataList[dataList.length - 2].className = "border-bottom-0";
      data[key] = {
        result: sortDelaySignals(setupInfo, dataList, isEndToEnd, v2),
        v2
      };
    }
    this.setState({ data })
  }

  getHistoryResult = async (historyKeys) => {
    const { isEndToEnd, interfaceType } = this.props;
    if (interfaceType === CPHY) {
      await this.getCPHYHistoryResult(historyKeys);
    } else {
      const dataPromise = historyKeys.map(async d => ({
        id: d,
        data: (await getHistoryDelayData(d, isEndToEnd)),
        setupInfo: await getHistoryVerificationJson(d, isEndToEnd)
      }));
      const datas = await Promise.all(dataPromise);
      let { data } = this.state;
      datas.forEach(d => {
        if (d && d.data) {
          data[d.id] = {
            result: sortDelaySignals(d.setupInfo, d.data.dataList, isEndToEnd, d.data.v2),
            v2: d.data.v2
          };
        }
      })
      this.setState({ data })
    }
  }

  getExperimentResult = async (experimentKeys) => {
    const { isEndToEnd, id } = this.props;
    const dataPromise = experimentKeys.map(async d => ({
      id: d,
      data: d === 'default' ? await getDelayData(id) : await getExperimentDelayData(d),
      setupInfo: d === 'default' ? await getVerificationJson(id, isEndToEnd) : await getExperimentVerificationJson(d)
    }));

    const datas = await Promise.all(dataPromise);
    let { data } = this.state;
    datas.forEach(d => {
      if (d && d.data) {
        data[d.id] = {
          result: sortDelaySignals(d.setupInfo, d.data.dataList, isEndToEnd, d.data.v2),
          v2: d.data.v2
        };
      }
    })
    this.setState({ data })
  }

  resultsSelect = (keys) => {
    const { data } = this.state;
    const { resultPageType } = this.props;
    this.setState({
      selected: keys
    })
    const dataKeys = keys.filter(k => !Object.keys(data).includes(k));
    if (resultPageType === EXPERIMENTS) {
      this.getExperimentResult(dataKeys);
      return;
    }
    this.getHistoryResult(dataKeys);
  }

  getName = (id) => {
    const { experimentList, resultPageType } = this.props;
    if (id === 'current') {
      return 'Current';
    } else if (id === 'default') {
      return 'Default';
    }
    if (resultPageType === EXPERIMENTS) {
      const experiment = experimentList.find(d => d.id === id);
      return experiment ? experiment.name : undefined;
    }
    const { historyList } = this.props;
    return historyList.find(d => d.id === id).name;
  }

  getOptionData = () => {
    const { experimentList, status, historyList, resultPageType } = this.props;
    let optionData = [];
    if (resultPageType === EXPERIMENTS) {
      optionData = [...experimentList];
      if (status === VERIFY_SUCCESS) {
        optionData.unshift({ id: 'default', name: 'Default' });
      }
    } else {
      optionData = [{ id: 'current', name: 'Current' }, ...historyList];
    }
    return optionData;
  }

  render() {
    const { resultPageType, interfaceType, isEndToEnd } = this.props;
    const { data, selected } = this.state;
    return (
      <Fragment>
        <div className='andes-v2-delay-table'>
          {
            resultPageType !== EXPERIMENTS ? <div className="andes-v2-delay-select-div clear">
              <label htmlFor='andes-v2-delay-select' className='andes-v2-delay-select-title'>Result</label>
              <Select
                value={selected}
                mode="multiple"
                onChange={this.resultsSelect}
                className='aurora-select andes-v2-delay-select'
                popupClassName='aurora-select-dropdown'
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {
                  this.getOptionData().map(d => <Option key={d.id}>{d.name}</Option>)
                }
              </Select>
            </div> : null
          }
          {selected.map(item => {
            const _data = (data[item] ? data[item].result : []).map((record, index) => ({ ...record, index }));
            return <Fragment key={item}>
              <span className='andes-v2-delay-name-item'>{this.getName(item)}</span>
              <Table
                columns={data[item] && data[item].v2 ? interfaceType !== CPHY || isEndToEnd ? ColumnsV2.filter(it => it.dataIndex !== 'net') : ColumnsV2 : Columns}
                dataSource={_data}
                pagination={false}
                size="small"
                rowKey={record => record.index}
                className='andes-v2-delay-result-table'
              />
            </Fragment>
          })}
        </div>
      </Fragment>
    )
  }
}

export default Delay;
