const TYPE = "Andes_v2";
/* channel extraction */
export const START_CHANNEL_EXTRACTION = `${TYPE}/start_channel_extraction`;

/* simulation */
export const UPDATE_SIMULATION_REDUCER = `${TYPE}/update_simulation_reducer`;
export const UPDATE_SIMULATION_INFO = `${TYPE}/update_stimulation_info`;
export const GET_WORKFLOW_MONITOR = `${TYPE}/get_workflow_monitor`;
export const UPDATE_MULTI_SIMULATION_INFO = `${TYPE}/update_multi_simulation_info`;
export const GET_VERIFICATION_WORKFLOW = `${TYPE}/get_verification_workflow`;
export const GET_VERIFICATION_LOG = `${TYPE}/get_verification_log`;
export const CANCEL_VERIFICATION_WORKFLOW = `${TYPE}/cancel_verification_workflow`;
export const GET_CURRENT_PROFILE = `${TYPE}/get_current_profile`;

export const UPDATE_SIM_SELECT_KEYS = `${TYPE}/update_sim_select_keys`;

/* end to end channel simulation */
export const START_END_TO_END_SIMULATION = `${TYPE}/start_end_to_end_simulation`;

export const RECALCULATE_TDR_SBR_WORKFLOW = `${TYPE}/_recalculate_tdrsbr_workflow`;

export const START_EXPERIMENTS_SIMULATION = `${TYPE}/start_experiments_simulation`;
