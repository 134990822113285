import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import {
  CloseOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Tooltip, Spin, Switch } from 'antd';
import {
  updateImpedanceStatus,
  reFindDomain,
  deletePowerDomain,
  saveVRM,
  updateIncludeExtended,
  addRow,
  changeNets,
  changeExtraNets,
  saveTargetImpedance,
  savePorts,
  saveLoadPorts,
  deleteSensePort,
  saveBallSize
} from '../store/Impedance/action';
import { PowerDomainRow, getPowerPinList } from '@/services/Cascade/Impedance';
import { getReferenceComps } from '@/services/Cascade/helper/setupData';
import { selectChange, changeShowNameStatus, selectLayer } from '../../LayoutExplorer/store/Cascade/actionCreators';
import { getDisplayDetail, getDisplaySensePorts } from '@/services/Cascade/project';
import VRMCompPanel from './vrmCompPanel';
import targetUploadPanel from './targetPanel';
import multiLoadPortSetup from '../components/multiLoadPortSetup';
import { NetGroupSelection } from '@/services/PCBHelper';
import canvas from '@/services/LayoutCanvas';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import TargetPanel from './targetPanel';
import { CAP, LOAD } from '../../../constants/componentType';
import designConstructor from '../../../services/helper/designConstructor';
import { saveStackupToServer } from '../../LayoutExplorer/components/Stackup_v1/store/actionCreators';
import { CASCADE } from '../../../constants/pageType';
import BallSizePanel from '../components/ballSizePanel';

const domainColumns = [
  {
    title: "Power Nets",
    dataIndex: "PowerNets",
    width: "20%"
  },
  {
    title: "Reference Nets",
    dataIndex: "ReferenceNets",
    width: "20%"
  },
  {
    title: "Target Impedance Spec",
    dataIndex: "targetName",
    width: "20%"
  },
  {
    title: "Port",
    dataIndex: "ports",
    width: '20%'
  },
  {
    title: "PMIC",
    dataIndex: "VRM",
    width: "20%"
  }
]

class PowerDomainTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      closeVRM: false,
      loadingIncludeExtended: [],
      advanced: false,
      showPCBDetail: {},
      icBallSize: false
    }
    this.columns = JSON.parse(JSON.stringify(domainColumns))
    this.netGroupSelection = new NetGroupSelection();
    this.tableDataList = [];
  }

  componentDidMount = () => {
    this.setDefaultColumns();
    this.getPowerDomainData(true)
  }

  componentDidUpdate = (prevProps) => {
    const { openProjectId, verificationId, status, noPCB, page } = this.props;
    const updateStatus = status && status !== prevProps.status;
    if ((openProjectId && openProjectId !== prevProps.openProjectId)
      || (verificationId && verificationId !== prevProps.verificationId)
      || updateStatus || page !== prevProps.page) {
      updateStatus && this.props.updateImpedanceStatus(false);
      this.getPowerDomainData(updateStatus ? false : true)
    }

    if (prevProps.noPCB !== noPCB) {
      this.setDefaultColumns();
      this.setAdvancedColumns();
    }
  }

  updateAdvanced = (e, open) => {
    e.stopPropagation();
    this.setState({
      advanced: open
    }, () => {
      this.setAdvancedColumns(open);
    })
    if (!open) {
      for (let row of this.tableDataList) {
        this.clearVRMModel(row)
      }
    }
  }

  setDefaultColumns = () => {
    const { advanced } = this.state;

    this.columns[3].title = () => {
      const { pcbId } = this.props;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      return <div>
        <span>Port</span>
        {
          !isPreLayout && <Tooltip
            title={`Ball size setting.`}
            mouseLeaveDelay={0} mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >
            <SettingOutlined
              className="impedance-part-switch-icon impedance-package-ports-switch-icon"
              onClick={(e) => this.openIcBallSize(e)}
            />
          </Tooltip>
        }
      </div>
    }

    this.columns[0].render = (text, record) => {
      const { MAIN_POWER_NETS, extendNets, detail, id, PowerNets, includeExtended, VRM, sensePorts } = record;
      return (
        <div className="cascade-impedance-relative">
          <span>{MAIN_POWER_NETS.join(', ')}</span>
          {extendNets && extendNets.length ?
            <Fragment>, <span className="extend-net-select-color">{extendNets.join(', ')}</span></Fragment>
            : null}
          {detail && detail.length && PowerNets.length ? <Tooltip
            overlayClassName="aurora-tooltip cascade-detail-tooltip"
            title={this.getDetail(detail, id, includeExtended, VRM, sensePorts)}
            key={id}
            trigger="click"
            placement="topRight"
            getPopupContainer={() => document.getElementById(`detail_${id}`)}
          >
            <FileTextOutlined
              className={`aurora-tree-info-icon impedance-detail-icon`}
              title={"Topology"}
              onClick={(e) => this.clickDetail(e, detail, id)}
              id={`detail_${id}`} />
          </Tooltip> : null}
        </div>
      );
    }

    this.columns[1].render = (text, record) => {
      return text ? text.join(', ') : '';
    }

    this.columns[2].render = (text, record) => {
      const { singleLayout } = this.props;
      return singleLayout ? <div className="imp-target-text">
        <span>{text}</span>
      </div> : <Tooltip overlayClassName='aurora-tooltip' title="Please set Target in Overview page.">
        <span className="cascade-table-row-disabled"></span>
      </Tooltip>
    }

    this.columns[3].render = (text, record) => {
      return <div onClick={() => this.closePortsPanel()}>{text.length ? 'Pin Group' : 'Lumped'}</div>
    }

    this.columns[4].render = (text, record) => {
      const display = this.getVRMCompName(text);
      return <div>
        <span>{[...new Set(display)].join(', ')}</span>
        {!advanced && <CloseOutlined
          className="impedance-delete-icon"
          onClick={(e) => this.deleteRow(e, record)} />}
      </div>
    }

    this.columns[0].onCell = (record) => {
      const { nets, noPCB } = this.props;
      const filterNets = [];
      this.tableDataList.forEach(item => { filterNets.push(...item.PowerNets, ...item.ReferenceNets, ...item.extraNets) });
      return !noPCB ? {
        record,
        edit: 'aurora-select',
        options: nets.filter(item => !filterNets.includes(item)),
        dataIndex: 'PowerNets',
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.selectNets(list, record, setFieldsValue, 'PowerNets', ['PowerNets', 'MAIN_POWER_NETS']),
        clearSelected: (value, record, setFieldsValue) => this.clearNets(value, record, setFieldsValue, 'PowerNets', ['PowerNets', 'MAIN_POWER_NETS']),
        auroraSelectBlur: (record) => this.saveNets(record),
        onFocus: () => this.select(record.PowerNets),
        loadingMsg: !nets || !nets.length ? 'Loading Nets...' : ''
      } : {
        edit: false
      }
    }

    this.columns[1].onCell = (record) => {
      const { nets, noPCB } = this.props;
      return !noPCB ? {
        record,
        edit: 'aurora-select',
        options: nets,
        dataIndex: 'ReferenceNets',
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.selectNets(list, record, setFieldsValue, 'ReferenceNets', ['ReferenceNets', 'MAIN_REF_NETS']),
        clearSelected: (value, record, setFieldsValue) => this.clearNets(value, record, setFieldsValue, 'ReferenceNets', ['ReferenceNets', 'MAIN_REF_NETS']),
        auroraSelectBlur: (record) => this.saveNets(record),
        onFocus: () => this.select(record.ReferenceNets),
        loadingMsg: !nets || !nets.length ? 'Loading Nets...' : ''
      } : {
        edit: false
      }
    }

    this.columns[2].onCell = (record) => {
      const { singleLayout } = this.props;
      if (!singleLayout) {
        return {
          edit: false
        }
      }
      const { targetVisible } = this.state;
      const { target, targetName, id } = record;
      if (targetVisible !== record.id) {
        return {
          edit: true,
          record: { ...record, targetName },
          dataIndex: 'targetName',
          tdClick: (e) => this.openTableCell(e, record, "target")
        }
      }

      const { noPCB, maxFreq } = this.props;
      return !noPCB ? {
        record,
        edit: true,
        customInput: targetUploadPanel,
        powerDomainId: id,
        dataIndex: 'targetName',
        onTargetRef: this.onTargetRef,
        text: targetName,
        title: 'Target Impedance Spec',
        target: target,
        maxFreq,
        updateTarget: (_target) => this.updateTarget(id, _target),
        closeColumnVisible: () => this.closeColumnVisible(record, "target")
      } : {
        edit: false
      }
    }

    this.columns[3].onCell = (record) => {
      const { portVisible } = this.state;
      const { currentDesignId, singleLayout, customSpiceList } = this.props;
      const isPreLayout = designConstructor.isPreLayout(currentDesignId);
      if (isPreLayout) {
        return {
          edit: false
        }
      }
      if (portVisible !== record.id) {
        return {
          edit: true,
          record: { ...record, ports: record.ports.length ? 'Pin Group' : 'Lumped' },
          dataIndex: 'ports',
          tdClick: (e) => this.openTableCell(e, record, "port")
        }
      }
      const monitorIC = this.getMonitorIC()
      const { noPCB, maxFreq } = this.props;
      const { id, ports = [], MAIN_POWER_NETS, ReferenceNets, Components } = record;
      const loadComps = Components.filter(comp => comp.usage === LOAD)
      const plocModelList = customSpiceList.filter(item => item.name.match(/.ploc$/i))
      return !noPCB ? {
        record,
        edit: true,
        customInput: multiLoadPortSetup,
        dataIndex: 'ports',
        text: ports.length ? 'Pin Group' : 'Lumped',
        targetIC: monitorIC,
        pcbId: currentDesignId,
        powerDomainId: id,
        ports,
        maxFreq,
        savePorts: this.updatePorts,
        saveLoadPorts: this.updateLoadPorts,
        onPortsRef: this.onPortsRef,
        PowerNets: MAIN_POWER_NETS,
        ReferenceNets,
        targetSetup: TargetPanel,
        notIncludeTarget: !singleLayout,
        multiPCB: !singleLayout,
        loadComps,
        closeColumnVisible: () => this.closeColumnVisible(record, "port"),
        plocSupport: true,
        plocModelList,
        autoMatch: true
      } : {
        edit: false
      }
    }

    this.columns[4].onCell = (record) => {
      const { vrmVisible } = this.state;
      if (vrmVisible !== record.id) {
        return {
          edit: true,
          record: { ...record, VRM: record && record.VRM ? [...new Set(this.getVRMCompName(record.VRM))].join(', ') : '' },
          dataIndex: 'VRM',
          tdClick: (e) => this.openTableCell(e, record, "vrm")
        }
      }
      const { COMP_PREFIX_LIB, pcbId: currentDesignId, noPCB, data } = this.props;
      const { closeVRM } = this.state;
      const row = data.find(item => item.id === record.id);
      const components = row && row.content ? row.content.Components : [];
      const powerPinList = getPowerPinList('powerPin', COMP_PREFIX_LIB, record, components);
      const { ReferenceNets, PowerNets, detail } = record;
      const { referenceComps, extraReferenceComps } = getReferenceComps({ ReferenceNets, pcbId: currentDesignId, powerPinList });
      return {
        record,
        edit: true,
        customInput: VRMCompPanel,
        onVRMRef: this.onVRMRef,
        dataIndex: 'VRM',
        powerPinList,
        referencePinList: referenceComps,
        extraReferenceComps,
        text: record && record.VRM ? [...new Set(this.getVRMCompName(record.VRM))].join(', ') : '',
        saveVRMComp: this.saveVRMComp,
        close: closeVRM,
        closeVRMPanel: this.closeVRMPanel,
        noPCB,
        designId: currentDesignId,
        ReferenceNets,
        PowerNets,
        detail,
        closeColumnVisible: () => this.closeColumnVisible(record, "vrm")
      }
    }
  }

  openIcBallSize = (e) => {
    this.stopClick(e);
    this.setState({
      icBallSize: true
    })
  }

  openTableCell = (e, record, type) => {
    let visible = this.state[`${type}Visible`];

    if (visible === record.id) {
      return;
    }

    if (visible) {
      switch (type) {
        case "vrm":
          this.VRMRef && this.VRMRef.closeModal();
          break;
        case "port":
          this.closePortsPanel();
          break;
        case "target":
          this.targetRef && this.targetRef.closeModal();
          break;
        default: break;
      }
    }
    visible = record.id;

    this.setState({
      [`${type}Visible`]: visible
    })
  }

  onVRMRef = (ref) => {
    this.VRMRef = ref;
  }

  onTargetRef = (ref) => {
    this.targetRef = ref;
  }

  setAdvancedColumns = () => {
    const { advanced } = this.state;
    if (advanced) {
      this.columns = this.columns.map(item => ({ ...item, width: `${100 / 7}%` }));
      this.columns.push(...[
        //   {
        //   title: "VR Model",
        //   dataIndex: "modelName",
        //   width: `${100 / 8}%`
        // },
        {
          title: "Nominal Voltage",
          dataIndex: "voltage",
          width: `${100 / 7}%`
        }, {
          title: "Net Extension",
          dataIndex: "extraNets",
          width: `${100 / 7}%`
        }])
    } else {
      this.columns = this.columns.map(item => ({ ...item, width: '20%' }));
      this.columns = this.columns.filter(item => !['modelName', 'voltage', 'extraNets'].includes(item.dataIndex))
    }

    this.columns[4].render = (text, record) => {
      const display = this.getVRMCompName(text);
      return <div>
        <span>{[...new Set(display)].join(', ')}</span>
        {!advanced && <CloseOutlined
          className="impedance-delete-icon"
          onClick={(e) => this.deleteRow(e, record)} />}
      </div>
    }

    if (advanced) {
      // this.columns[5].render = (text, record) => {
      //   return <div>
      //     <span>{text}</span>
      //   </div>
      // }

      // this.columns[5].onCell = (record) => {
      //   const { VRMList = [], noPCB } = this.props;
      //   return !noPCB ? {
      //     record,
      //     allowClear: true,
      //     edit: 'select',
      //     options: VRMList.map(item => item.name),
      //     dataIndex: 'modelName',
      //     handleSave: this.editVRMModel,
      //     handleClear: this.clearVRMModel
      //   } : {
      //     edit: false
      //   }
      // }

      this.columns[5].render = (text, record) => {
        return <div>
          <span>{text}V</span>
        </div>
      }

      this.columns[5].onCell = (record) => {
        return {
          record,
          edit: true,
          dataIndex: 'voltage',
          handleSave: this.saveVoltage
        }
      }

      this.columns[6].render = (text, record) => {
        return (
          <div>
            <span>{text ? text.join(', ') : ''}</span>
            <CloseOutlined
              className="impedance-delete-icon"
              onClick={(e) => this.deleteRow(e, record)} />
          </div>
        );
      }

      this.columns[6].onCell = (record) => {
        const { nets, noPCB } = this.props;
        const filterNets = [];
        this.tableDataList.forEach(item => { filterNets.push(...item.PowerNets, ...item.ReferenceNets, ...item.extraNets) });
        return !noPCB ? {
          record,
          edit: 'aurora-select',
          options: nets.filter(item => !filterNets.includes(item)),
          dataIndex: 'extraNets',
          selectMode: 'multiple',
          onChange: (list, record, setFieldsValue) => this.selectExtraNets(list, record, setFieldsValue, 'extraNets'),
          clearSelected: (value, record, setFieldsValue) => this.clearExtraNets(value, record, setFieldsValue, 'extraNets'),
          auroraSelectBlur: (record) => this.saveNets(record),
          onFocus: () => this.select(record.extraNets),
          loadingMsg: !nets || !nets.length ? 'Loading Nets...' : ''
        } : {
          edit: false
        }
      }

      this.columns[6].title = () => {
        return (
          <span>Net Extension
            <MenuFoldOutlined
              title="Close advanced"
              className="imp-domain-advanced-icon"
              onClick={(e) => this.updateAdvanced(e, false)} />
          </span>
        );
      }

      this.columns[4].title = () => {
        return <span>PMIC</span>
      }
    } else {
      this.columns[4].title = () => {
        return (
          <span>PMIC
            <MenuUnfoldOutlined
              title="Advanced"
              className="imp-domain-advanced-icon"
              onClick={(e) => this.updateAdvanced(e, true)} />
          </span>
        );
      }
    }
    this.setState({
      advanced: advanced
    })
  }

  getVRMCompName = (text = []) => {
    const display = [];
    if (!Array.isArray(text)) {
      return [];
    }
    text.forEach(item => {
      if (item.VRM_COMP && item.VRM_COMP.length) {
        display.push(...item.VRM_COMP);
      } else {
        const gnd = item.groundPin && item.groundPin.length ? item.groundPin[0].comp : '';
        const pwr = item.powerPin && item.powerPin.length ? item.powerPin[0].comp : '';
        if (gnd || pwr) {
          display.push(`[${pwr}, ${gnd}]`)
        }
      }
    })
    return display;
  }

  getDetail = (detail, id, includeExtended, VRM = [], sensePorts = []) => {
    if (!detail || !detail.length) return "";

    const { showPCBDetail } = this.state;
    const { layouts, currentDesignId, singleLayout } = this.props;

    let newDetailList = getDisplayDetail(detail, { showFull: showPCBDetail[id], layouts, designId: currentDesignId });
    const sensePortList = getDisplaySensePorts(sensePorts);
    const { loading, contentWidth } = this.props;
    const { loadingIncludeExtended } = this.state;
    let powerPins = [];
    VRM.forEach(vrm => {
      const pins = vrm.powerPin.length ? vrm.powerPin.map(item => item.comp) : []
      powerPins.push(...pins.map(pin => (`[${pin}]`)))
      powerPins.push(...pins.map(pin => (`{${pin}}`)))
      powerPins.push(...pins.map(pin => (`[${pin}`)))
      powerPins.push(...pins.map(pin => (`{${pin}`)))
    })
    const detailList = (item, key, sense = false, sensePins = []) => {
      let list = [], bold = false, hasPowerPins = false;
      item.forEach((text, index) => {
        if (index !== 0 && !hasPowerPins && ((powerPins.includes(text.split('::')[0]) && !sense) || (sense && sensePins[key] === text))) {
          bold = true;
        }
        list.push(
          <span
            key={`text-${index}`}
            style={bold ? { fontWeight: 'bold' } : {}}
            title={bold ? 'Power Pin' : ''}
          >
            {text}
          </span>)
        if (bold) {
          hasPowerPins = true;
          bold = false;
        }
        if (index < item.length - 1) {
          list.push(<span key={`connect-${index}`}>&nbsp;--&gt;&nbsp;</span>)
        }
      })
      return list
    }

    return (
      <div className="cascade-text-editor-tooltip" key={`${id}_detail`} onClick={(e) => this.stopClick(e)}>
        <div className='cascade-display-detail-box' style={contentWidth ? { maxWidth: contentWidth - 100 } : {}}>
          <Spin spinning={loadingIncludeExtended.map(item => item.id).includes(id)} >
            <div className='impedance-include-extended-content'>
              {
                singleLayout && <Fragment>
                  <span className="impedance-include-extended-title">Include Extended Nets</span>
                  <Tooltip title={loading ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
                    <Switch
                      size="small"
                      className="aurora-switch-small"
                      checked={includeExtended ? true : false}
                      onChange={(checked, e) => this.changeIncludeExtended(e, checked, id)}
                      disabled={loading ? true : false}
                    />
                  </Tooltip>
                </Fragment>
              }
              {
                detail && detail.some(d => d.find(item => item.type === 'connect')) && <Fragment>
                  <span className="impedance-include-extended-title impedance-show-full-title">Show Full Path</span>
                  <Switch
                    size="small"
                    className="aurora-switch-small"
                    checked={showPCBDetail[id]}
                    onChange={(checked, e) => this.showPCBDetail(e, checked, id)}
                    disabled={loading ? true : false}
                  />
                </Fragment>
              }
            </div>
            {newDetailList.map((item, key) => {
              const list = detailList(item, key)
              return <div key={key} className='cascade-detail-line' onClick={(e) => this.clickDetail(e, [detail[key]], id)}>
                {list.map(text => text)}
              </div>
            })}
            {sensePortList.length ? <Fragment>
              <div className='cascade-detail-sense-port-title' onClick={(e) => this.stopClick(e)}>Sense</div>
              {sensePortList.map((item, key) => {
                const pwrList = item.pwrSensePorts ? detailList(item.pwrSensePorts, key, true, item.pwrSensePins) : [];
                const gndList = item.gndSensePorts ? detailList(item.gndSensePorts, key, true, item.gndSensePins) : [];
                return (
                  <div className="cascade-detail-sense-port-item" key={key}>
                    {pwrList.length ? <Fragment><div className='cascade-detail-sense-port-title' onClick={(e) => this.stopClick(e)}>Power</div>
                      <div className='cascade-detail-line cascade-sense-detail-line' onClick={(e) => this.clickDetail(e, [item.senseInfo.powerSensePort])}>
                        <div className='cascade-sense-detail-line-text'>{pwrList.map(text => text)}</div>
                        <CloseOutlined
                          className='sense-delete-icon'
                          title={`Delete Current Sense Port`}
                          onClick={(e) => this.deleteSensePath(e, item.key, id, "power")} />
                      </div></Fragment> : null}
                    {gndList.length ? <Fragment><div className='cascade-detail-sense-port-title' onClick={(e) => this.stopClick(e)}>Gnd</div>
                      <div className='cascade-detail-line cascade-sense-detail-line' onClick={(e) => this.clickDetail(e, [item.senseInfo.groundSensePort])}>
                        <div className='cascade-sense-detail-line-text'>{gndList.map(text => text)}</div>
                        <CloseOutlined
                          className='sense-delete-icon'
                          title={`Delete Current Sense Port`}
                          onClick={(e) => this.deleteSensePath(e, item.key, id, "gnd")} />
                      </div></Fragment> : null}
                  </div>
                );
              })}
            </Fragment> : null}
          </Spin>
        </div>
      </div>
    );
  }

  clickDetail = (e, detail, id) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    const { selectedDesignIDs, targetIC, currentDesignId } = this.props;
    if (selectedDesignIDs.includes(currentDesignId)) {
      const findRowComps = (this.tableDataList.find(it => it.id === id) || {}).Components || [];
      const capComps = findRowComps.filter(it => it.COMP_TYPE === CAP).map(it => it.name);

      let nets = [], comps = [];
      detail.forEach(d => {
        if (d.length) {
          d.forEach(item => {
            if (item.type === 'net') {
              nets.push(item.name)
            } else if (item.type === 'comp') {
              comps.push(item.name)
            }
          })
        }
      });
      comps.push(...capComps);
      const layout = canvas.getLayout(currentDesignId);
      let layers = layout.findCurrentLayer([...comps, targetIC]);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, currentDesignId);
      this.props._selectChange({ nets: [...new Set(nets)], comps: [...new Set([...comps, targetIC])] }, currentDesignId)
      this.props._changeShowNameStatus(true, currentDesignId)
      setTimeout(() => {
        this.netGroupSelection.selectNetGroup({ nets: [...new Set(nets)], isPower: true })
      }, 100);
    }
  }

  showPCBDetail = (e, show, id) => {
    e && e.stopPropagation()
    this.setState({
      showPCBDetail: {
        ...this.state.showPCBDetail,
        [id]: show
      }
    })
  }

  getPowerDomainData = (mount = false) => {
    const { data } = this.props;
    let tableData = [];
    let newLoading = [...this.state.loadingIncludeExtended];
    let haveModel = false;
    for (let row of data) {
      const { id, content } = row;
      const domainRow = new PowerDomainRow({ id, ...content, /* Components: []  */ });
      if (domainRow.modelName) {
        haveModel = true;
      }
      tableData.push(domainRow);
      const findIndex = newLoading.findIndex(item => item.id === id && item.checked === content.includeExtended);
      if (findIndex > -1) {
        newLoading.splice(findIndex, 1)
      }
    }
    if (mount) {
      this.tableDataList = tableData;
      this.setState({
        advanced: haveModel,
        loadingIncludeExtended: newLoading
      }, () => {
        this.setAdvancedColumns();
        this.props.updateImpedanceStatus(false);
      })
    } else {
      this.tableDataList = tableData;
      this.setState({
        loadingIncludeExtended: newLoading
      })
    }
  }

  getMonitorIC = () => {
    const { data, targetIC, page, pcbId } = this.props
    if (page === pcbId) {
      return targetIC
    }
    const debugMonitor = data[0].content.DEBUG_MONITOR
    if (debugMonitor && debugMonitor.length) {
      return debugMonitor[0].find(item => item.type === 'comp').name
    }
    return ""
  }

  stopClick = (e) => {
    e && e.preventDefault() && e.stopPropagation();
  }

  addRow = (e) => {
    e.stopPropagation();
    const newRow = new PowerDomainRow({ id: '' });
    this.tableDataList = [...this.tableDataList, newRow];
    this.props.addRow()
  }

  selectNets = (list, record, setFieldsValue, mainKey, keys) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    const nets = {}
    for (let key of keys) {
      let netsList = record[key];
      if (list.length === 1 && netsList.find(net => net === list[0])) {
        netsList = netsList.filter(net => net !== list[0]);
      } else {
        netsList = [...new Set([...netsList, ...list])];
      }
      if (setFieldsValue && mainKey === key) {
        setFieldsValue({ [key]: netsList })
      }
      currentRow[key] = netsList;
      nets[key] = netsList;
    }
    this.tableDataList = newTableData;
    this.props.changeNets(record.id, nets)
  }

  selectExtraNets = (list, record, setFieldsValue, key) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    const nets = {}
    let netsList = record[key];
    if (list.length === 1 && netsList.find(net => net === list[0])) {
      netsList = netsList.filter(net => net !== list[0]);
    } else {
      netsList = [...new Set([...netsList, ...list])];
    }
    if (setFieldsValue) {
      setFieldsValue({ [key]: netsList })
    }
    currentRow[key] = netsList;
    nets[key] = netsList;
    this.tableDataList = newTableData;
    this.props._changeExtraNets(record.id, nets)
  }

  clearNets = (value, record, setFieldsValue, mainKey, keys) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    const nets = {}
    for (let key of keys) {
      let netsList = record[key];
      netsList = netsList.filter(net => net !== value);
      if (setFieldsValue && mainKey === key) {
        setFieldsValue({ [key]: netsList })
      }
      currentRow[key] = netsList;
      nets[key] = netsList;
    }
    this.tableDataList = newTableData;
    this.props.changeNets(record.id, nets)
  }

  clearExtraNets = (value, record, setFieldsValue, key) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    const nets = {}
    let netsList = record[key];
    netsList = netsList.filter(net => net !== value);
    if (setFieldsValue) {
      setFieldsValue({ [key]: netsList })
    }
    currentRow[key] = netsList;
    nets[key] = netsList;
    this.tableDataList = newTableData;
    this.props._changeExtraNets(record.id, nets)
  }

  saveNets = (record) => {
    this.props.reFindDomain(record)
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const { id } = record;
    this.props.deletePowerDomain(id);
  }

  editVRMModel = (record) => {
    const { VRMList } = this.props;
    let vrmName = record.modelName;
    let currentVRM = VRMList.find(vrm => vrm.name === vrmName);
    if (!currentVRM) {
      currentVRM = { name: '', id: '' };
      vrmName = '';
    }
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    currentRow.VRM = currentRow.VRM.map(vrm => ({ ...vrm, model: currentVRM }));
    currentRow.modelName = vrmName;
    this.tableDataList = newTableData;
    this.props.saveVRM({ id: record.id, vrm: currentVRM }, 'model');
  }

  clearVRMModel = (record) => {
    const newTableData = [...this.tableDataList];
    const currentRow = newTableData.find(item => item.id === record.id);
    if (currentRow.modelName) {
      currentRow.VRM = currentRow.VRM.map(vrm => ({ ...vrm, model: { name: '', id: '' } }));
      currentRow.modelName = '';
      this.props.saveVRM({ id: record.id, vrm: { name: '', id: '' } }, 'model');
    }
  }

  saveVRMComp = (record, VRM) => {
    this.props.saveVRM({ id: record.id, VRM }, 'comp');
  }

  changeIncludeExtended = (e, checked, id) => {
    e && e.stopPropagation() && e.preventDefault();
    let newLoading = [...this.state.loadingIncludeExtended]
    newLoading.push({ id, checked })
    this.setState({
      closeVRM: true,
      loadingIncludeExtended: newLoading
    }, () => {
      this.props.updateIncludeExtended(checked, id)
    })
  }

  closeVRMPanel = () => {
    this.setState({
      closeVRM: false
    })
  }

  closeColumnVisible = (record, type) => {
    const visible = this.state[`${type}Visible`];
    this.setState({
      [`${type}Visible`]: visible === record.id ? null : visible
    })
  }

  updateTarget = (id, target) => {
    this.props.saveTargetImpedance(id, target);
  }

  updatePorts = (id, ports) => {
    this.props.savePorts(id, ports);
  }

  updateLoadPorts = (load, id, ports) => {
    this.props._saveLoadPorts(load, id, ports)
  }

  select = (nets) => {
    const { currentDesignId, selectedDesignIDs } = this.props;
    if (selectedDesignIDs.includes(currentDesignId)) {
      this.props._selectChange({ nets }, currentDesignId)
      this.props._changeShowNameStatus(true, currentDesignId)
      setTimeout(() => {
        this.netGroupSelection.selectNetGroup({ nets: nets, isPower: true })
      }, 100);
    }
  }

  onPortsRef = (ref) => {
    this.portsRef = ref;
  }

  closePortsPanel = () => {
    this.portsRef && this.portsRef.closeModal();
  }

  deleteSensePath = (e, key, id, senseType) => {
    e && e.stopPropagation();
    this.props.deleteSensePort(key, id, senseType)
  }

  saveVoltage = (record) => {
    this.props.saveVRM(record, 'voltage')
  }

  closeBallSize = (props, isClose) => {
    const { currentDesignId } = this.props
    const { ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab } = props;
    if (isClose) {
      this.setState({
        icBallSize: false
      })
    }
    this.props.saveBallSize(currentDesignId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab)
  }

  render() {
    const { loading, currentDesignId, pcbId, ballSizeMap, ICs } = this.props;
    const { icBallSize } = this.state
    return (
      <Fragment>
        <span className="font-bold cascade-setup-title-color">Power Domains</span>
        {currentDesignId === pcbId ? loading ? <Tooltip title={loading && 'Loading PCB...'} overlayClassName='aurora-tooltip'>
          <PlusCircleOutlined className='cascade-imp-icon cascade-imp-icon-disable' />
        </Tooltip> :
          <PlusCircleOutlined className='cascade-imp-icon' onClick={(e) => this.addRow(e)} /> : null}
        <div className="space-10">
          <Spin spinning={loading ? true : false}>
            <EditableTable
              rowKey={(record) => record.id}
              columns={this.columns}
              size="small"
              dataSource={this.tableDataList}
              className="cascade-imp-table cascade-imp-pcb-table"
              tablePadding={true}
            />
          </Spin>
        </div>
        {
          icBallSize &&
          <BallSizePanel
            data={ballSizeMap}
            title='IC Ball Size'
            closePanel={(props, isClose) => this.closeBallSize(props, isClose)}
            comps={ICs}
            pcbId={pcbId}
            saveStackupToServer={this.props.saveStackupToServer}
            product={CASCADE}
          />
        }
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, selectedKeys },
    library: { VRMList, customSpiceList },
    Impedance: { designId, verificationId, loading, targetIC, data = [], status, page } } } = state
  const layout = data.find(item => item.designId === page);
  const singleLayout = data.length > 1 ? false : true;
  return {
    loading,
    openProjectId,
    verificationId,
    targetIC,
    layouts: data,
    page,
    currentDesignId: page,
    data: layout ? layout.powerDomains || [] : [],
    singleLayout,
    status,
    VRMList,
    pcbId: designId,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    maxFreq: layout && layout.extraction ? (layout.extraction.FMAX || null) : null,
    ballSizeMap: layout ? layout.ballSizeMap : {},
    ICs: layout ? layout.ICs.map(item => item.name) : [],
    customSpiceList
  };
}

const mapDispatch = (dispatch) => ({
  updateImpedanceStatus(status) {
    dispatch(updateImpedanceStatus(status))
  },
  reFindDomain(record) {
    dispatch(reFindDomain(record))
  },
  deletePowerDomain(id) {
    dispatch(deletePowerDomain(id))
  },
  saveVRM(params, key) {
    dispatch(saveVRM(params, key))
  },
  updateIncludeExtended(checked, id) {
    dispatch(updateIncludeExtended(checked, id))
  },
  addRow() {
    dispatch(addRow())
  },
  changeNets(id, nets) {
    dispatch(changeNets(id, nets))
  },
  _changeExtraNets(id, nets) {
    dispatch(changeExtraNets(id, nets))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  saveTargetImpedance(id, target) {
    dispatch(saveTargetImpedance(id, target))
  },
  savePorts(id, ports) {
    dispatch(savePorts(id, ports))
  },
  _saveLoadPorts(load, id, ports) {
    dispatch(saveLoadPorts(load, id, ports))
  },
  _changeShowNameStatus(status, designID) {
    dispatch(changeShowNameStatus(status, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID))
  },
  deleteSensePort(key, id, senseType) {
    dispatch(deleteSensePort(key, id, senseType))
  },
  saveBallSize(pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab) {
    dispatch(saveBallSize(pcbId, ballShape, ballSize, ballHeight, ballMiddle, ballMaterial, notGroupPositivePin, compTab))
  },
  saveStackupToServer(stackupData, designID) {
    dispatch(saveStackupToServer(stackupData, designID))
  },
})

export default connect(mapState, mapDispatch)(PowerDomainTable);
