import React, { Fragment } from 'react';

export function CustomLibraryListRender({
  customLibraryList,
  nameWidth,
  manufacturerWidth,
  selectLibrary = null,
  selectedModel = {}
}) {

  let _customModelList = JSON.parse(JSON.stringify(customLibraryList || []));
  const findModelIndex = selectedModel.id ? customLibraryList.findIndex(item => item.id === selectedModel.id) : -1;
  if (findModelIndex > -1) {
    const findModel = JSON.parse(JSON.stringify(_customModelList[findModelIndex]));
    _customModelList = _customModelList.filter(item => item.id !== selectedModel.id);
    _customModelList.unshift({ ...findModel });
  }

  const width = (manufacturerWidth || 0) + (nameWidth || 0);

  return <div className='search-library-content-list'>
    {_customModelList.length ?
      <Fragment>
        <div className='search-library-content-list-main'>
          <div
            className="search-library-list-title"
          >
            <span
              className="search-library-item-name"
              style={{ width: nameWidth || "33.33%" }}
            >ManufacturerPartNumber</span>
            <span
              className="search-library-item-name"
              style={{ width: manufacturerWidth || "33.33%" }}
            >Manufacturer</span>
            <span
              className="search-library-item-path"
              style={{ width: width ? `calc(100% - ${width}px)` : "33.33%" }}
            >Model Path</span>
          </div>
          {_customModelList.map(((item) => {
            const { name, id, displayPath, manufacturer } = item;
            return <div
              key={id}
              className={selectedModel.id === item.id ? "search-library-list-item search-library-list-item-selected" : "search-library-list-item"}
              style={{ cursor: selectLibrary ? "pointer" : "" }}
              onClick={selectLibrary ? () => selectLibrary(item) : null}
            >
              <span
                className="search-library-item-name"
                style={{ width: nameWidth || "33.33%" }}
                title={name}
              >{name}</span>
              <span
                className="search-library-item-name"
                style={{ width: manufacturerWidth || "33.33%" }}
                title={manufacturer}
              >{manufacturer}</span>
              <span
                className="search-library-item-path"
                style={{ width: width ? `calc(100% - ${width}px)` : "33.33%" }}
                title={displayPath}
              >{displayPath}</span>
            </div>
          }))}
        </div>
      </Fragment>
      : <div className='search-library-content-no-data'>No Data</div>}
  </div>
}