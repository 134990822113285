import { newNanoId } from "../../helper/idHelper";
import { SIWAVE, HFSS, POWERSI } from '@/constants/extractionType';

function RockyPackageCompRLCPrefixLib(obj = {}) {
  const {
    Cap = ["C"],
    Bga = [],
    Die = [],
    Ignore = [],
    Ipd = []
  } = obj;

  this.Cap = Cap;
  this.Bga = Bga;
  this.Die = Die;
  this.Ignore = Ignore;
  this.Ipd = Ipd;
};

function RockyPCBCompRLCPrefixLib(obj = {}) {
  const {
    Res = ['R'],
    Ind = ["L", "PL"],
    Cap = ["C", "PC"],
    Jumper = ['PJ', 'J'],
    Ferrite = ['FB', 'FL'],
    Diode = ['D'],
    Transistor = ['Q', 'PQ'],
    Load = ['U'],
    linearSwitch = [],
    specialized = [],
    linearRegulator = [],
    switchingRegulator = [],
    discreteBuckConverter = [],
    powerSwitch = [],
    driver = [],
    multiPortRes = [],
  } = obj;

  this.Res = Res;
  this.Ind = Ind;
  this.Cap = Cap;
  this.Jumper = Jumper;
  this.Ferrite = Ferrite;
  this.Diode = Diode;
  this.Transistor = Transistor;
  this.linearSwitch = linearSwitch;
  this.specialized = specialized;
  this.linearRegulator = linearRegulator;
  this.switchingRegulator = switchingRegulator;
  this.powerSwitch = powerSwitch;
  this.discreteBuckConverter = discreteBuckConverter;
  this.driver = driver;
  this.multiPortRes = multiPortRes;
  this.Load = Load;
};

const LINEAR = 'linear', LOG = 'log', DECADE = 'Decade', STEP = 'Step'
const meshMethod_PHIMESH = 'Phi';
function PackageExtraction(config = {}) {
  const { SIWE = 0, PKGR = 1, VRTC = "2.5", SAPD = 100, DCF = 1, RESA = 1, CLIP = 1,
    DISC = 0, REFR = "0.1", couplingMinFreq = "3.5MHz", VOID = '2', IPC = 1, FMAX = '2e8', PLNM = '1e-6',
    DC = 1, PADM = '1e-6', vrFswSwitch = false, VR_FSW, type = SIWAVE,
    errorTolerance = '0.005', maxSolution = '250', maxPasses = '20', maxDelta = '0.02',
    meshFrequency = '1', autoMeshFreq = true, minConvergedPass = '1',
    includeDC = true, exactDC = true, auroraAedb = false,
    numCores = '4', corePercent = '50', ramPercent = '90',
    meshingMethod = meshMethod_PHIMESH, ymlLibraryId,
    customPlaneVoidMeshingSetting = false, discreteSweep = false,
    causality = false, passivity = true, enableLinearSweep = false,
    customFrequencySweep = [{
      start: '100',
      end: '1e8',
      type: LOG,
      step: '100',
      units: {
        start: 'Hz',
        end: 'MHz',
        step: 'MHz'
      }
    }, {
      start: '1e8',
      end: '2e8',
      type: LINEAR,
      step: '5e6',
      units: {
        start: 'MHz',
        end: 'MHz',
        step: 'MHz'
      }
    }]
  } = config;

  this.type = type;
  this.SAPD = SAPD;
  this.FMAX = FMAX || "2e8";
  this.REFR = REFR;
  this.vrFswSwitch = vrFswSwitch;
  this.VR_FSW = VR_FSW ? VR_FSW : vrFswSwitch ? '600KHz' : '';
  this.includeDC = includeDC || false;
  this.exactDC = exactDC || false;
  this.numCores = numCores;
  this.corePercent = corePercent;
  this.enableLinearSweep = enableLinearSweep || false
  this.customFrequencySweep = customFrequencySweep

  if (type !== POWERSI) {
    this.SIWE = SIWE;
    this.PKGR = PKGR;
    this.DCF = DCF;
    this.RESA = RESA;
    this.DISC = DISC;
    this.couplingMinFreq = couplingMinFreq;
    this.DC = DC;
  }

  if (type === SIWAVE) {
    this.IPC = IPC;
    this.VOID = VOID;
    this.PLNM = PLNM;
    this.PADM = PADM;
    this.VRTC = VRTC;
    this.customPlaneVoidMeshingSetting = customPlaneVoidMeshingSetting;
    this.causality = causality;
    this.passivity = passivity;
  }

  if (type === HFSS) {
    this.maxSolution = maxSolution;
    this.maxPasses = maxPasses;
    this.maxDelta = maxDelta;
    this.minConvergedPass = minConvergedPass;
    this.meshingMethod = meshingMethod;
  }

  if ([SIWAVE, HFSS].includes(type)) {
    this.CLIP = CLIP;
    this.meshFrequency = meshFrequency;
    this.autoMeshFreq = autoMeshFreq;
    this.ramPercent = ramPercent;
    this.ymlLibraryId = ymlLibraryId;
    this.auroraAedb = auroraAedb;
    this.discreteSweep = discreteSweep
    this.errorTolerance = errorTolerance;

    if (config.hasOwnProperty("timeout")) {
      this.timeout = config.timeout
    }
  }
}

function PackagePDNRow() {
  this.id = newNanoId();
  this.PowerNets = [];
  this.ReferenceNets = [];
  this.Components = [];
  this.diePorts = [];
  this.bgaPorts = [];
}

function PCBPDNRow() {
  this.id = newNanoId();
  this.PowerNets = [];
  this.ReferenceNets = [];
  this.Components = [];
  this.ExtendPowerNets = [];
  this.ExtendReferenceNets = [];
  this.VRM = [];
  this.DEBUG_MONITOR = [];
  this.includeExtended = true;
  this.ports = [];
  this.target = [];
  this.voltage = "1"
}

function ImpVRM(index, model) {
  this.VRM_COMP = [];
  this.groundPin = [{ comp: '', pins: [] }];
  this.powerPin = [{ comp: '', pins: [] }];
  this.voltage = '';
  this.model = model;
  this.index = index;
}

function ImpVrmDisplay(index) {
  this.pwr = '';
  this.gnd = '';
  this.vrmComp = '';
  this.inductance = '';
  this.index = index;
  this.gndPins = [];
  this.pwrPins = [];
}

export {
  RockyPackageCompRLCPrefixLib,
  PackageExtraction,
  PackagePDNRow,
  HFSS,
  SIWAVE,
  RockyPCBCompRLCPrefixLib,
  PCBPDNRow,
  ImpVRM,
  ImpVrmDisplay,
  LINEAR,
  LOG,
  DECADE,
  STEP
}