import LayoutData from '../CeLayoutDB/newCeLayoutData';

export function searchNet(value, nets, PCIeName) {
  /*   if (value === '') {
      if (PCIeName) {
        let key = 'TXP';
        switch (PCIeName) {
          case 'Tx_P': key = 'TXP'; break;
          case 'Tx_N': key = 'TXN'; break;
          case 'Rx_P': key = 'RXP'; break;
          case 'Rx_N': key = 'RXN'; break;
        }
        let cacheNets = cache.nets;
        let selectNet = nets.filter(function (net, i) {
          return !(cacheNets[i].indexOf(`PCIE_${key}`.toLowerCase()) < 0);
        })
        let otherNets = nets.filter(function (net, i) {
          return (cacheNets[i].indexOf(`PCIE_${key}`.toLowerCase()) < 0);
        })
        return [...selectNet, ...otherNets];
      } else {
        return nets;
      }
    } else { */
  return nets.filter(function (net, i) {
    return !(nets[i].indexOf(value) < 0);
  })
  /* } */
}

/**
 * get the net names connected with the specified net by RLC
 * @param {string} netName 
 */
export function getRelatedNets(netName, _LayoutData) {
  const pinList = _LayoutData.mNetManager.GetNetFromName(netName).mPinList;
  const relatedNets = [];
  const RLC = 'RLCrlc';
  const rlcComponents = []
  for (const { mLayerName, mCompName, mPinNum } of pinList) {
    if (RLC.includes(mCompName[0])) {
      rlcComponents.push(mCompName);
    }
  }
  const nets = _LayoutData.mNetManager.mNetList.mVals;
  for (const { mName, mPinList } of nets) {
    if (mName !== netName) {
      for (const { mCompName } of mPinList) {
        if (rlcComponents.includes(mCompName)) {
          relatedNets.push(mName);
          break;
        }
      }
    }
  }
  return relatedNets;
}
