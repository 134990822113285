import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { EyeOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Tooltip, Dropdown } from 'antd';
import EditableTable from '@/components/EditableTable';
import CalculateTable from './calculateTable';
import { getLibraryData } from '@/services/PDN/library';
import { changeLibraryFormat } from '@/services/helper/rlcValueFormat';
import { compareAndUpdateHeight } from '@/services/helper/htmlElement';
import { resProcessing, getVRMData } from '@/services/helper/sparameter/resProcess';
import { DECAP_TOUCHSTONE } from '@/constants/libraryConstants';
import './new.css';

const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '18%'
}, {
  title: 'Components',
  dataIndex: 'selectComps',
  width: '40%',
}, {
  title: 'Quantity',
  dataIndex: 'quantity',
  width: '14%'
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '28%',
}];

const defaultColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '25%',
}, {
  title: 'Components',
  dataIndex: 'selectComps',
  width: '25%',
}, {
  title: 'Quantity',
  dataIndex: 'quantity',
  width: '25%',
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '25%',
}];
class OptimizationPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.state = {
      refresh: false,
      maximize: false,
      panelWidth: null,
      panelHeight: null,
      optCurrentResult: null,
      optCompareResult: null,
      optVRMModel: null,
      optVRMOpt: null
    }
    ComponentsColumns[0].render = (text, record) => {
      return <Tooltip title={text} overlayClassName='aurora-tooltip'>
        <div className='pdn-result-component-part'>{text}</div>
      </Tooltip>
    };

    ComponentsColumns[1].render = (selectComps, record, index) => {
      let simHeight = null;
      if (record.type === 'opt') {
        let simRef = document.getElementById(`td-simulation-${record.part}`);
        simHeight = simRef && simRef.offsetHeight;
      }
      return <Fragment>
        <div className='pdn-component-td' id={`td-${record.type}-${record.part}`} style={{ height: simHeight }}>
          <span className='pdn-result-verticalAlign-middle-span' style={{ height: simHeight }}></span>
          <span className='pdn-result-verticalAlign-middle-components-span'>{selectComps.join(', ')}</span>
        </div>
      </Fragment>
    };

    ComponentsColumns[2].render = (value, record) => {
      return <span>{record.selectComps.length}</span>
    }

    ComponentsColumns[3].render = (data, record) => {
      const { value, model, models } = record;
      if (record.usage === 'Cap') {
        if (models && models.length) {
          const modelTextList = models.map((item) => this.getModelName(item));
          return <Tooltip
            title={modelTextList.join(", ")}
            overlayClassName='aurora-tooltip'
            placement='topLeft'
          >
            <div>{modelTextList.join(", ")}</div>
          </Tooltip>
        } else if (value && (value.r || value.l || value.c)) {
          return <span>
            R = {(value.r && (value.r + 'Ω')) || '0'}, L = {(value.l && (value.l + 'H')) || '0H'}, C = {(value.c && (value.c + 'F')) || '0F'}
          </span>
        } else {
          const { libraryType, subcktName, name, libraryData, type } = model || {}
          let showName = type === DECAP_TOUCHSTONE ? name : subcktName;
          let showTip = type === DECAP_TOUCHSTONE ? <div>Model: {name}</div> : <Fragment><div>Model: {name}</div><div>Subckt: {subcktName}</div></Fragment>
          if (libraryType && libraryType === 'file') {
            return <Tooltip
              title={showTip}
              overlayClassName='aurora-tooltip'
              placement='topLeft'
            >
              <span className='pdn-result-history-model'>{showName}</span>
            </Tooltip>
          } else {
            let showTip = type === DECAP_TOUCHSTONE ? <div>Model: {name}</div>
              : libraryData ? <Fragment><div>R = {libraryData.R}, L = {libraryData.L}, C = {libraryData.C}</div></Fragment> : null
            return <Tooltip
              title={showTip}
              overlayClassName='aurora-tooltip'
              placement='topLeft'
            >
              <span>{name}</span>
            </Tooltip>
          }
        }
      } else if (record.usage !== 'Ignore' && record.usage !== 'Unused') {
        return <span>{value}</span>
      } else {
        return null;
      }
    }
  }

  getModelName = (model = {}) => {
    switch (model.type) {
      case "touchstone":
        return model.folderName ? `${model.folderName}::${model.name}` : model.name;
      case "spice":
        return `${model.name}::${model.subcktName}`;
      case "rlc":
        return model.libraryData ? `${model.name}: R = ${model.libraryData.R},L = ${model.libraryData.L},C = ${model.libraryData.C}` : `${model.name}`
      default: return null;
    }
  }

  componentDidUpdate(prevProps) {
    const { optTime, optCompareResult } = this.props;
    const { optTime: prevTime, optCompareResult: prevResult } = prevProps;
    if (!prevResult && optCompareResult) {
      // Refresh the component table to make the original setup highly consistent with Decap optimization
      this.setState({
        refresh: true
      })
    }
    if (prevTime !== optTime) {
      this.getInfo(optTime);
    }
  }

  getDefaultWidth = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 800;
    let _width = width * 0.7;
    _width = _width < 400 ? 400 : _width;
    return _width;
  }

  getDefaultHeight = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { height } = offset;
    if (!height) return 500;
    let _height = height * 0.7;
    _height = _height < 200 ? 200 : _height;
    _height = _height > 800 ? 800 : _height;
    return _height;
  }

  getComponent = (Components) => {

    Components.forEach(comp => {
      if (comp.usage === "Cap" && comp.models && comp.models.length) {
        comp.models.forEach(item => {
          if (item.type === "rlc") {
            let id = item.id;
            getLibraryData(id).then(res => {
              if (res.libraryData) {
                const data = res.libraryData;
                const library = changeLibraryFormat(data);
                item.libraryData = {
                  R: `${library.libraryData.R}${library.RUnit}`,
                  L: `${library.libraryData.L}${library.LUnit}`,
                  C: `${library.libraryData.C}${library.CUnit}`,
                }
              }
            })
          }
        })
      }
      else if (comp.usage === 'Cap' && comp.model && comp.model.libraryType === 'data' && !comp.model.libraryData) {
        let id = comp.model.id;
        getLibraryData(id).then(res => {
          if (res.libraryData) {
            const data = res.libraryData;
            const library = changeLibraryFormat(data);
            comp.model.libraryData = {
              R: `${library.libraryData.R}${library.RUnit}`,
              L: `${library.libraryData.L}${library.LUnit}`,
              C: `${library.libraryData.C}${library.CUnit}`,
            }
          }
        })
      }
      comp.type = 'simulation';
    })
    return Components;
  }

  getOptComponent = (optCurrentResult, optCompareResult) => {
    if (!optCurrentResult || !optCompareResult) {
      return [];
    };
    let newDecaps = JSON.parse(JSON.stringify(optCurrentResult));
    const optNames = optCompareResult.decaps.map(item => item.name);
    // TODO: decap split
    newDecaps.forEach(item => {
      for (let comp of item.comps) {
        if (comp.usage === 'Unused') {
          continue;
        } else if (comp.usage === 'Cap') {
          if (optNames.includes(comp.name)) {
            continue;
          } else {
            comp.usage = 'Unused';
          }
        }
      };
      item.selectComps = item.comps.filter(comp => comp.usage === 'Cap').map(comp => comp.name);
      item.unusedComps = item.comps.filter(comp => comp.usage === 'Unused').map(comp => comp.name);
      item.type = 'opt';
    });
    return newDecaps;
  }

  getInfo = (Time) => {
    const { projectId, pdnId, currentProjectPDNs, historyData } = this.props;
    const time = Time ? Time : 'current';
    let currentPdn = currentProjectPDNs.find(item => item.id === pdnId);
    let pdnName = currentPdn && currentPdn.name;
    historyData.getContent({ projectId, pdnId, time, pdnName }).then(res => {
      if (res) {
        this.setState({
          optCurrentResult: res.Components
        });
      };
    });
    historyData.getOptCfg({ projectId, pdnId, time }).then(res => {
      if (res) {
        this.setState({
          optCompareResult: res
        });
      }
    })
    historyData.getVRMOpt({ projectId, pdnId, time }).then(res => {
      if (res) {
        const str = res ? resProcessing(res) : '';
        const data = getVRMData(str);
        this.setState({ optVRMOpt: data });
      } else {
        this.setState({ optVRMOpt: '' });
      }
    })
    historyData.getVRMModel({ projectId, pdnId, time }).then(res => {
      if (res) {
        const str = res ? resProcessing(res) : '';
        const data = getVRMData(str);
        this.setState({ optVRMModel: data });
      } else {
        this.setState({ optVRMModel: '' });
      }
    })
  }

  Menu = (components) => {
    return [
      { key: 'view_stuffed', label: 'Stuffed', onClick: (e) => this.props.viewStuffedDecap(e, components) },
      { key: 'view_unStuffed', label: 'UnStuffed', onClick: (e) => this.props.viewStuffedDecap(e, components) },
    ]
  }

  changeSizeMax = (e) => {
    e.stopPropagation();
    const { maximize } = this.state;
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (!maximize) {

      if (!width) {
        _width = 1000
      };
      _width = width - 40;

      if (!height) {
        _height = 1000
      };
      _height = height - 40;
    } else {
      _width = this.getDefaultWidth();
      _height = this.getDefaultHeight();
    }

    this.setState({
      maximize: !maximize,
      panelWidth: _width,
      panelHeight: _height
    }, () => {
      //render() again to ensure that the height of the compare component td is consistent with the basic
      setTimeout(() => {
        this.forceUpdateHandler();
      }, 200);
    });
  }

  componentDidMount = () => {
    const { optTime } = this.props;
    this.getInfo(optTime);
    //render() again to ensure that the height of the compare component td is consistent with the basic
    setTimeout(() => {
      this.forceUpdateHandler();
    }, 500);
  }

  forceUpdateHandler() {
    //force update render()
    //render() again to ensure that the height of the compare component is consistent with the basic
    this.forceUpdate();

    //update basic div and compare div height 
    //ensure that the height of the compare div is consistent with the basic div
    const basicContentCSSID = "pdn-result-opt-content-basic";
    const compareContentCSSID = "pdn-result-opt-content-compare";
    const fatherCSSID = "pdn-result-opt-main";
    compareAndUpdateHeight(basicContentCSSID, compareContentCSSID, fatherCSSID);
  };

  PanelResizeEnd = () => {
    //After the panel changes its size, render() again to ensure that the height of the compare component is consistent with the basic
    setTimeout(() => {
      this.forceUpdateHandler();
    }, 200);
  }

  render() {
    const { closeModal, viewList } = this.props;
    const { optCurrentResult, optCompareResult, optVRMModel, optVRMOpt } = this.state;
    const basicComponents = optCurrentResult ? this.getComponent(optCurrentResult) : [];
    const compareComponents = optCompareResult ? this.getOptComponent(optCurrentResult, optCompareResult) : [];
    let view = false;
    if (viewList && viewList.includes('PCB')) {
      view = true;
    }
    const width = this.getDefaultWidth();
    const { maximize, panelWidth, panelHeight } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='pdn-result-history-panel'
          zIndex={2000}
          onCancel={() => closeModal()}
          draggable
          minWidth={388}
          minHeight={200}
          width={maximize ? panelWidth : width}
          height={maximize ? panelHeight : this.getDefaultHeight()}
          position={maximize ? 'panel-center-max' : 'panel-center-left'}
          title={
            <Fragment>
              <span>Optimization</span>
              {!maximize ?
                <FullscreenOutlined
                  title='maximize'
                  className='pdn-result-panel-maximize'
                  onClick={(e) => { this.changeSizeMax(e) }} />
                : <FullscreenExitOutlined
                  title='minimize'
                  className='pdn-result-panel-maximize'
                  onClick={(e) => { this.changeSizeMax(e) }} />
              }
            </Fragment>}
          resizeEnd={this.PanelResizeEnd}
        >
          <div className='pdn-result-history-panel-main clear' id='pdn-result-opt-main'>
            <div style={{ background: '#f2f7fa' }} className='pdn-history-panel-content-basic' id='pdn-result-opt-content-basic'>
              {optVRMModel ? <div className='pdn-result-history-vrm-table pdn-result-history-select-box' id="pdn-result-opt-vrm-basic">
                <CalculateTable
                  title='Calculated VRM'
                  dataSource={optVRMModel}
                />
              </div> : null}
              <div className='pdn-result-history-select-box'>
                <span className="font-bold">Original setup</span>
                <TotalDecap comps={basicComponents} />
                {basicComponents.length > 0 ? <Dropdown menu={{ items: this.Menu(basicComponents), className: "view-dropdown-button" }} trigger={['click']} overlayClassName='pdn-result-history-dropdown' disabled={!view}>
                  <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                    <EyeOutlined className="pdn-result-view-icon" />
                  </Tooltip>
                </Dropdown> : null}
              </div>
              <div className='pdn-result-history-table'>
                <EditableTable
                  rowKey="part"
                  columns={basicComponents.length > 0 ? ComponentsColumns : defaultColumns}
                  size="small"
                  dataSource={basicComponents}
                  scroll={basicComponents.length ? { x: 710 } : {}}
                  className='pdn-result-components-table'
                />
              </div>
            </div>
            <div style={{ background: 'rgb(220, 241, 253)' }} className='pdn-history-panel-content-compare' id='pdn-result-opt-content-compare'>
              {optVRMOpt ? <div className='pdn-result-history-vrm-table pdn-result-history-select-box' id="pdn-result-opt-vrm-compare">
                <CalculateTable
                  title='Calculated VRM After Optimization'
                  dataSource={optVRMOpt}
                />
              </div> : null}
              <div className='pdn-result-history-select-box'>
                <span className="font-bold">Decap optimization</span>
                <TotalDecap comps={compareComponents} />
                {compareComponents.length > 0 ? <Dropdown menu={{ items: this.Menu(compareComponents), className: "view-dropdown-button" }} trigger={['click']} overlayClassName='pdn-result-history-dropdown' disabled={!view}>
                  <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                    <EyeOutlined className="pdn-result-view-icon" />
                  </Tooltip>
                </Dropdown> : null}
              </div>
              <div className='pdn-result-history-table'>
                <EditableTable
                  rowKey="part"
                  columns={compareComponents.length > 0 ? ComponentsColumns : defaultColumns}
                  size="small"
                  dataSource={compareComponents}
                  scroll={compareComponents.length ? { x: 710 } : {}}
                  className='pdn-result-components-table'
                />
              </div>
            </div>
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const TotalDecap = (props) => {
  let count = 0;
  if (props.comps && props.comps.length && props.comps.length !== 0) {
    for (let { selectComps } of props.comps) {
      count += selectComps.length;
    }
  }
  return count > 0 ? (
    <span className='pdn-opt-sum-title'>(<span style={{ fontWeight: "bold" }}>{count}</span> stuffed decaps)</span>
  ) : null
}

const mapState = (state) => {
  const {
    project: { currentProjectPDNs, currentProjectId, PDNID, viewList },
    simulationReducer: { existResult }
  } = state.PDNReducer;
  return {
    currentProjectPDNs,
    projectId: currentProjectId,
    pdnId: PDNID,
    viewList,
    existResult
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(OptimizationPanel);