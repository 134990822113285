import React, { PureComponent } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelWidth } from '../../../services/helper/panelSizeHelper';
import './index.css';
import { PCB_SUBCKT, SOC_SPICE, SOC_WRAPPER, VDE_NUMBER } from '../../../constants/libraryConstants';
import { Select } from 'antd';

const { Option } = Select;
class LibraryEditPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.root = props.root || document.getElementById('root');
    this.state = {
      library: props.library || {}
    }
  }

  closePanel = () => {
    this.props.saveLibrary(this.state.library);
    this.props.closeModal();
  }

  changeSelect = (value, type) => {
    this.setState({
      library: { ...this.state.library, [type]: value }
    })
  }

  getVdeRender = () => {
    const { library } = this.state;
    return <div className='himalayas-edit-library-item'>
      <label>Vde Number</label>
      <Select
        onChange={(value) => this.changeSelect(value, VDE_NUMBER)}
        className="aurora-select"
        value={library[VDE_NUMBER] || ""}
        popupClassName="himalayas-edit-library-option-dropdown">
        {["1", "2"].map(item => <Option key={item} value={item}>{item}</Option>)}
      </Select>
    </div>
  }

  render() {
    const { title, modelType } = this.props;

    const content = (
      <Panel
        position='panel-center-left'
        title={<div className='himalayas-library-edit-panel-title' title={title}>{title}</div>}
        onCancel={this.closePanel}
        zIndex={2000}
        width={getPanelWidth(800, { defaultWidth: 300 })}
        draggable
        className='himalayas-library-edit-panel'
        minWidth={100}
        minHeight={100}
      >
        <div className='himalayas-edit-library-content'>
          {[PCB_SUBCKT, SOC_SPICE, SOC_WRAPPER].includes(modelType)
            ? this.getVdeRender() : null}
        </div>
      </Panel>
    )
    return createPortal(content, this.root)
  }
}

export default LibraryEditPanel;