import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Radio, Select, Switch, Collapse } from 'antd';
import ExtractionOptions from './extractionOptionsRender';
import NP from 'number-precision';
import { numExponentialFormat, numFormatConversion, scaleConversion } from '@/services/helper/numberHelper';
import { saveChannelExtractionConfig, savePreviewStatus, updateHybridStatus, saveChannelHybridRegion, updateHybridInfo, updatePortSetupsToServer } from '../../store/channel/action';
import { checkSweepValue } from '@/services/helper/valueCheck';
import { ANDES_V2 } from '@/constants/pageType';
import { valueUnitSplit } from '../../../../services/helper/valueUnitSplit';
import LayoutData from '@/services/data/LayoutData';
import { HPCOptions, LicenseWait, SIwaveInfo, HFSSInfo, Extraction } from '@/services/Andes_v2/channel/IntegratedChannel';
import { getExtractionTimeout } from '../../../../services/Andes_v2';
import HybridRegions from '../../../../services/helper/cutDesign/hybrid/hybridExtraction';
import { getDesignHybridInfoPromise } from '../../../../services/helper/cutDesign/hybrid';
import preview from '@/services/helper/cutDesign/preview';
import designConstructor from '../../../../services/helper/designConstructor';
import { PACKAGE } from '../../../../constants/designType';
import { generateBoundaryList } from '@/services/Andes_v2/channel/cutDesignHelper';
import { addHybridRegionsBox } from '@/services/helper/cutDesign/hybrid/previewRegions';
import DelConfirm from '@/components/DelConfirm';
import {
  AutoGeneratePorts,
  ALL_PINS,
  WAVE,
  SINGLE_PIN,
  CIRCUIT,
  getUpdateCompBallInfoByPinSize
} from '../../../../services/ExtractionPortsHelper';
import DesignInfo from '@/services/Andes_v2/pcbInfo';
import { updateSetupComponentsByPortType } from '@/services/ExtractionPortsHelper';
import { getExtractionType } from '../../../../services/Andes_v2/channel';
import './index.css';
import { numberCheck } from '../../../../services/helper/dataProcess';
import './index.css';
import { BALL_TYPE_NONE, BALL_TYPE_NONE_LIST, USE_BALL_LIST } from '../../../../services/ExtractionPortsHelper/portTableHelper';
import { BGA, DIE } from '../../../../constants/componentType';

const SIWAVE_HFSS = "SIwave+HFSS", SIWAVE = 'SIwave', HFSS = 'HFSS';
const { Option } = Select;
const defaultUnit = {
  logSweepMinUnit: 'Hz',
  logSweepMaxUnit: "MHz",
  linearSweepMaxUnit: "GHz",
  linearSweepFreqStepUnit: "MHz",
  maxFreqUnit: "GHz",
  minSolvedFreqUnit: "MHz",
  adaptiveFrequencyUnit: "GHz",
  meshFrequencyUnit: "GHz"
}
class ExtractionOptionsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      units: {
        logSweepMinUnit: 'Hz',
        logSweepMaxUnit: "MHz",
        linearSweepMaxUnit: "GHz",
        linearSweepFreqStepUnit: "MHz",
        maxFreqUnit: "GHz",
        minSolvedFreqUnit: "MHz",
        adaptiveFrequencyUnit: "GHz",
        meshFrequencyUnit: "GHz"
      },
      prevExtraction: {
        type: "SIwave"
      },
      loading: true,
      extractionConfig: {
        type: "SIwave"
      },
      maxHeight: 450,
      maxWidth: 830,
      licWaitSwitch: false,
      hpcOptionSwitch: false,
      hybridRegions: [],
      hybridLines: [],
      isShowHybridPreview: false,
      hybridLoading: false,
      frequencyPointsInfo: {},
      confirmVisible: false,
      isFirst: true
    }
    this.dialogRoot = document.getElementById('root');
    this.hybridRegionsInfo = new HybridRegions({ channelId: props.channelId, designId: props.designId });
    this.isSave = true;
  }

  componentWillUnmount = () => {
    if (this.isSave) {
      this.saveOptions("close");
    }
    this.setState = () => false;
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { extraction, designId, hybridStatus } = this.props;
    await this.updateExtractionInfo(extraction, hybridStatus)

    this.stackup = await LayoutData.getStackupJson({ pcbId: designId, reload: true });
    this.totalThickness = this.stackup ? this.stackup.getStackupTotalThickness() : 50;
    this.props.setStackupData(this.stackup.stackup)
  }

  updateExtractionInfo = async (extraction, hybridStatus) => {
    const { verificationId, selectedSignals, designId } = this.props;
    let { type, logSweepMin, logSweepMax,
      logSweepSAPD, linearSweepMax,
      linearSweepFreqStep, maxFreq, includeDC, exactDC, portImpedance,
      discreteSweep, backdrillVias,
      backdrillStubSize, timeout, errorTolerance, maxSolution, enableLogSweep, clipSize,
      siwave, hfss, saveResultFolder, multiZone
    } = extraction;

    let _backdrillStubSize = "8";
    if (backdrillStubSize) {
      _backdrillStubSize = valueUnitSplit(backdrillStubSize).value || "8";
    }

    const { hours, minutes } = timeout ? getExtractionTimeout(timeout) : {};

    let _extractionConfig = {
      type,
      includeDC,
      exactDC,
      logSweepMin: numFormatConversion(logSweepMin) || "1000",
      logSweepMax: numFormatConversion(logSweepMax, -6) || "10",
      logSweepSAPD: logSweepSAPD || "10",
      linearSweepMax: numFormatConversion(linearSweepMax, -9) || "10",
      linearSweepFreqStep: numFormatConversion(linearSweepFreqStep, -6) || "5",
      maxFreq: maxFreq ? numFormatConversion(maxFreq, -9) : "",
      discreteSweep: discreteSweep || false,
      // meshFrequency: meshFrequency || "10",
      portImpedance: portImpedance || "42.5",
      backdrillVias: typeof (backdrillVias) !== "boolean" ? true : backdrillVias,
      backdrillStubSize: _backdrillStubSize,
      timeoutHours: hours,
      timeoutMinutes: minutes,
      errorTolerance: errorTolerance ? errorTolerance * 100 : 0.5,
      maxSolution: maxSolution ? maxSolution : 250,
      enableLogSweep,
      hybrid: hybridStatus ? true : (extraction.hybrid || false),
      saveResultFolder: saveResultFolder || false,
      siwave: siwave ? {
        ...siwave,
        meshFrequency: numFormatConversion(siwave.meshFrequency, -9) || "50",
      } : {},
      hfss: hfss && Object.keys(hfss).length ? {
        ...hfss,
        adaptiveFrequency: numFormatConversion(hfss.adaptiveFrequency, -9) || "50",
        minSolvedFreq: numFormatConversion(hfss.minSolvedFreq, -6) || "10"
      } : {}
    }
    const isMultiZone = designConstructor.getDesignMultiZone(designId);
    if (isMultiZone) {
      _extractionConfig.multiZone = multiZone || { stackupMode: 'Laminate', enableBends: true }
    }

    let hpcOptionSwitch = false, licWaitSwitch = false;
    //hpc options
    if (extraction.hasOwnProperty("hpc_options")) {
      hpcOptionSwitch = true;
      _extractionConfig.hpc_options = { ...extraction.hpc_options };
    }
    //license wait
    if (extraction.hasOwnProperty("lic_wait")) {
      licWaitSwitch = true;
      _extractionConfig.lic_wait = extraction.lic_wait;
      _extractionConfig.lic_wait_unit = extraction.lic_wait_unit;
    }

    this.obtainNumberOfFrequencyPoints(_extractionConfig)

    this.allowSaveHybrid = false;
    this.setState({
      units: JSON.parse(JSON.stringify(defaultUnit)),
      extractionConfig: { ..._extractionConfig },
      prevExtraction: { ...extraction },
      loading: false,
      hpcOptionSwitch,
      licWaitSwitch,
      timeoutSwitch: timeout ? true : false,
      hybridLoading: _extractionConfig.hybrid && !hybridStatus ? true : false
    }, () => {
      _extractionConfig.hybrid && this.getHybridInfo({ verificationId, clipSize, hybridStatus, selectedSignals })
      this.props._updateHybridStatus(_extractionConfig.hybrid);
    });
  }

  obtainNumberOfFrequencyPoints = (extraction) => {
    let config = JSON.parse(JSON.stringify(extraction))
    const { enableLogSweep } = config;

    // enableLogSweep
    let units = ["logSweepMin", "logSweepMax", "maxFreq", "linearSweepMax", "linearSweepFreqStep"]
    units.forEach(item => {
      const unit = `${item}Unit`;
      const scale = scaleConversion("Hz", this.state.units[unit]);
      config[item] = config[item] ? NP.times(config[item], scale).toString() : config[item];
      config[item] = numExponentialFormat(config[item]);
    })
    const { logSweepMin, logSweepMax, logSweepSAPD, linearSweepFreqStep, linearSweepMax, maxFreq } = config;

    let logSweepFrePoints = 1, maxSweepFrePoints = 1, linearSweepFrePoints = 1;

    // log Sweep Frequency points;
    if (!enableLogSweep) {
      logSweepFrePoints = 0
    } else if (logSweepMax && logSweepMin && !numberCheck(logSweepMax) && !numberCheck(logSweepMin)) {
      let logSweepMaxDecimalLog = logSweepMax * 1 === 0 ? 0 : Math.log10(logSweepMax);
      let logSweepMinDecimalLog = logSweepMin * 1 === 0 ? 0 : Math.log10(logSweepMin);
      logSweepFrePoints = Math.floor((logSweepMaxDecimalLog - logSweepMinDecimalLog) * logSweepSAPD + 1);
    }

    // linear Sweep Frequency points;
    if (!enableLogSweep) {
      if (linearSweepMax && linearSweepFreqStep && !numberCheck(linearSweepMax) && !numberCheck(linearSweepFreqStep)) {
        linearSweepFrePoints = Math.floor((linearSweepMax - linearSweepFreqStep) / linearSweepFreqStep + 1);
      }
    } else if (linearSweepMax && logSweepMax && !numberCheck(linearSweepMax) && !numberCheck(logSweepMax)) {
      linearSweepFrePoints = Math.floor((linearSweepMax - logSweepMax) / linearSweepFreqStep + 1);
    }

    // max Sweep Frequency points;
    if (maxFreq && linearSweepMax && !numberCheck(maxFreq) && !numberCheck(linearSweepMax)) {
      maxSweepFrePoints = Math.floor((maxFreq - linearSweepMax) / 20e6 + 1);
    }

    let frequencyPointsTotal = logSweepFrePoints + linearSweepFrePoints + maxSweepFrePoints;
    this.setState({
      frequencyPointsInfo: { frequencyPointsTotal, logSweepFrePoints, linearSweepFrePoints, maxSweepFrePoints }
    })
  }

  getHybridInfo = async ({ verificationId, hybridStatus, clipSize, selectedSignals }) => {
    //Timers prevent state blocking
    const { hybridId, channelId, signals, designId, port_setups, ports_generate_setup_list, referenceNets } = this.props;

    setTimeout(async () => {
      if (!hybridId || hybridId !== channelId) {
        const info = await getDesignHybridInfoPromise(verificationId);
        this.props._updateHybridInfo({
          hybridRegions: info.hybrid_regions || [],
          hybridLines: info.hybrid_lines || [],
          id: this.props.channelId
        })
      }
      if (hybridStatus) {
        let _clipSize = "3";
        if (clipSize && clipSize.toString().includes('mm')) {
          _clipSize = parseFloat(clipSize).toString();
        }

        if (!clipSize) {
          _clipSize = "3";
        }
        const info = generateBoundaryList({
          clipSize: _clipSize,
          signals,
          selectedSignals,
          designId,
          port_setups,
          ports_generate_setup_list,
          referenceNets
        });
        addHybridRegionsBox(designId);
        this.hybridRegionsInfo.updateBoundariesToHybrid(info);
      }

      this.setState({
        isShowHybridPreview: hybridStatus || !selectedSignals.length ? false : true,
        hybridLoading: false
      }, () => {
        this.allowSaveHybrid = true;
      })
    }, 200)
  }

  _updatePreviewButtonStatus = (load = false, show = false) => {
    if (this.clipDesign) {
      this.clipDesign.updatePreviewButtonStatus(load, show);
    }
    if (this.advancedClipDesign) {
      this.advancedClipDesign.updatePreviewButtonStatus(load, show);
    }
  }

  changeAdvancesClipDesign = (advanced) => {
    if (advanced) {
      this.saveOptions();
    } else if (this.advancedClipDesign) {
      this.advancedClipDesign.closeModal()
      this.clipDesign.changeClipValue(this.advancedClipDesign.state)
    }
  }

  changeAnsysType = (e) => {
    let value = e.target.value;

    let hybrid = false;
    const { extractionConfig } = this.state;
    const { hybridRegions, hybridLines, selectedSignals, channelId, verificationId } = this.props;
    let _extractionConfig = { ...extractionConfig },
      _hybridRegions = [...hybridRegions],
      _hybridLines = [...hybridLines];

    if (value === SIWAVE_HFSS) {
      value = "SIwave";
      hybrid = true;

      _extractionConfig.siwave = _extractionConfig.siwave && Object.keys(_extractionConfig.siwave).length ? _extractionConfig.siwave : new SIwaveInfo();
      if (!_extractionConfig.hfss || !Object.keys(_extractionConfig.hfss).length || Object.keys(_extractionConfig.hfss).length < 11) {
        const new_hfss = new HFSSInfo();
        _extractionConfig.hfss = {
          ...new_hfss,
          adaptiveFrequency: numFormatConversion(new_hfss.adaptiveFrequency, -9) || "50",
          minSolvedFreq: numFormatConversion(new_hfss.minSolvedFreq, -6) || "10"
        }
      }
      this.allowSaveHybrid = true;
    } else {
      hybrid = false;
      _hybridRegions = [];
      _hybridLines = [];
      this.hybridRegionsInfo.clearHybridLineCache(true);
      this.props._saveChannelHybridRegion({ hybridRegions: [], hybridLines: [], verificationId })
      if (_extractionConfig.hybrid) {
        this._updatePreviewButtonStatus();
      }

      if (_extractionConfig.siwave && _extractionConfig.siwave.suggestHFSSRegions) {
        _extractionConfig.siwave.suggestHFSSRegions = false;
      }

      if (value === SIWAVE) {
        _extractionConfig.siwave = _extractionConfig.siwave && Object.keys(_extractionConfig.siwave).length ? _extractionConfig.siwave : new SIwaveInfo();
      } else if (value === HFSS && (!_extractionConfig.hfss || !Object.keys(_extractionConfig.hfss).length || Object.keys(_extractionConfig.hfss).length < 11)) {
        const new_hfss = new HFSSInfo();
        _extractionConfig.hfss = {
          ...new_hfss,
          adaptiveFrequency: numFormatConversion(new_hfss.adaptiveFrequency, -9) || "50",
          minSolvedFreq: numFormatConversion(new_hfss.minSolvedFreq, -6) || "10"
        }
      }
    }

    this.props._updateHybridInfo({
      hybridRegions: _hybridRegions,
      hybridLines: _hybridLines,
      id: channelId
    })
    this.props._updateHybridStatus(hybrid);
    this.setState({
      extractionConfig: {
        ..._extractionConfig,
        type: value,
        hybrid
      },
      isShowHybridPreview: hybrid && selectedSignals.length
    }, () => {
      this.props.clearHybridErrorMsg()
      this.hybridRegionsInfo.updateHybridCanvasRegion(true);
    })
  }

  _updateError = (error) => {
    this.setState({
      error
    })
  }

  _updateUnit = (unit, type) => {
    let _units = { ...this.state.units };
    _units[type] = unit;
    this.setState({
      units: _units
    })
  }

  closeModal = (saveType) => {
    this.saveOptions(saveType);
    this.isSave = false;
    this.hybridRegionsInfo.clearHybridLineCache();
    this.props._savePreviewStatus(false);
    this.props._updateHybridStatus(false);


    const { extractionConfig } = this.state;
    const { hybridRegions, hybridLines } = this.props;

    if (!this.allowSaveHybrid) {
      return { hybrid: extractionConfig.hybrid }
    }
    return { hybrid: extractionConfig.hybrid, hybridRegions, hybridLines }
  }

  restoreExtractionInfo = async () => {
    // restore extraction data
    const { prevExtraction, extractionConfig } = this.state;
    const { channelId, designType } = this.props;
    let _extractionConfig = { ...extractionConfig };
    this.clipDesign.closeModal();

    const defaultExtractionValue = new Extraction({ designType });
    this.hybridRegionsInfo.clearHybridLineCache(true);
    if (_extractionConfig.hybrid) {
      this._updatePreviewButtonStatus();
    }

    this.props._updateHybridInfo({ hybridRegions: [], hybridLines: [], id: channelId })
    await this.props._saveChannelExtractionConfig({ ...defaultExtractionValue, hybrid: false }, prevExtraction, channelId);
    await this.updateExtractionInfo({ ...defaultExtractionValue, hybrid: false }, false)

    this.clipDesign.changeClipValue({
      clipping: defaultExtractionValue.clipping,
      clipSizeUnit: 'mm',
      clipSize: "3",
      useDesignClip: defaultExtractionValue.useDesignClip,
      clipSizeInput: "3"
    })
  }

  saveOptions = (saveType) => {
    this.clipDesign.closeModal();
    const { error, loading } = this.state;
    if (error && error.type !== "judge") {
      return;
    }

    if (loading) {
      this.props.closePanel();
      return;
    }

    const { prevExtraction, extractionConfig, licWaitSwitch, hpcOptionSwitch, timeoutSwitch } = this.state;
    const { hybridRegions, hybridLines } = this.props;
    let config = JSON.parse(JSON.stringify(extractionConfig));

    let units = ["logSweepMin", "logSweepMax", "maxFreq", "linearSweepMax", "linearSweepFreqStep", "minSolvedFreq", "adaptiveFrequency", "meshFrequency"]

    //update extraction config by unit
    units.forEach(item => {
      if (['minSolvedFreq', 'adaptiveFrequency'].includes(item) && config.hfss && Object.keys(config.hfss).length) {
        const unit = `${item}Unit`;
        const scale = scaleConversion("Hz", this.state.units[unit]);
        config.hfss[item] = config.hfss[item] ? NP.times(config.hfss[item], scale).toString() : config.hfss[item];
        config.hfss[item] = numExponentialFormat(config.hfss[item]);
      } else if (['meshFrequency'].includes(item) && config.siwave && Object.keys(config.siwave).length) {
        const unit = `${item}Unit`;
        const scale = scaleConversion("Hz", this.state.units[unit]);
        config.siwave[item] = config.siwave[item] ? NP.times(config.siwave[item], scale).toString() : config.siwave[item];
        config.siwave[item] = numExponentialFormat(config.siwave[item]);
      } else if ((item !== "maxFreq" || config["maxFreq"])) {
        const unit = `${item}Unit`;
        const scale = scaleConversion("Hz", this.state.units[unit]);
        config[item] = config[item] ? NP.times(config[item], scale).toString() : config[item];
        config[item] = numExponentialFormat(config[item]);
      }
    })

    config.backdrillStubSize = config.backdrillStubSize ? `${config.backdrillStubSize}mil` : "8mil";

    const errorMsg = checkSweepValue({
      logSweepMin: config["logSweepMin"],
      logSweepMax: config["logSweepMax"],
      linearSweepMax: config["linearSweepMax"],
      maxFreq: config["maxFreq"],
      version: ANDES_V2
    });
    if (errorMsg) {
      this.setState({
        error: { error: errorMsg, type: "judge" }
      });
      return;
    }

    const clipOpts = this.clipOptions || {}
    const { extraction, verificationId, channelId } = this.props;
    let _extraction = { ...extraction };
    //delete prev extraction lic_wait and hpc_options 
    if (!licWaitSwitch) {
      delete _extraction.lic_wait_unit;
      delete _extraction.lic_wait;
    }
    if (!hpcOptionSwitch) {
      delete _extraction.hpc_options;
    }

    //update timeout
    if (timeoutSwitch) {
      const timeoutHours = Number(config.timeoutHours) || 0,
        timeoutMinutes = Number(config.timeoutMinutes) || 0;
      config.timeout = timeoutHours * 60 + timeoutMinutes;
    } else {
      delete config.timeout;
      delete _extraction.timeout;
    }
    delete config.timeoutMinutes;
    delete config.timeoutHours;

    //update errorTolerance
    config.errorTolerance = (Number(config.errorTolerance) || 0) / 100;

    const newExtraction = { ..._extraction, ...config, ...clipOpts };
    this.props._saveChannelExtractionConfig({ ..._extraction, ...config, ...clipOpts }, prevExtraction, channelId);
    this.allowSaveHybrid = newExtraction.hybrid && (!newExtraction.siwave || !newExtraction.siwave.suggestHFSSRegions)
    this.allowSaveHybrid && saveType !== "modeling" && this.props._saveChannelHybridRegion({ hybridRegions, hybridLines, verificationId });
  }

  updateCurrentExtractionConfig = (extractionConfig) => {
    this.obtainNumberOfFrequencyPoints(extractionConfig)
    this.setState({
      extractionConfig: { ...extractionConfig }
    })
  }

  onRef = (ref) => {
    this.clipDesign = ref;
  }

  onAdvancedRef = (ref) => {
    this.advancedClipDesign = ref;
  }

  saveClipOptions = (clipOptions, saveToServer) => {
    this.clipOptions = clipOptions;
    if (saveToServer) {
      this.saveOptions();
    }
  }

  changeSwitch = (checked, type) => {
    const { extractionConfig } = this.state;
    let _extractionConfig = { ...extractionConfig };
    switch (type) {
      case "licWaitSwitch":
        if (checked) {
          _extractionConfig = { ..._extractionConfig, ...new LicenseWait() }
        } else {
          delete _extractionConfig.lic_wait;
          delete _extractionConfig.lic_wait_unit;
        }
        break;
      case "hpcOptionSwitch":
        if (checked) {
          _extractionConfig = {
            ..._extractionConfig,
            hpc_options: {
              ...new HPCOptions()
            }
          }
        } else {
          delete _extractionConfig.hpc_options;
        }
        break;
      case "timeoutSwitch":
        if (checked) {
          _extractionConfig = { ..._extractionConfig, timeoutHours: 72, timeoutMinutes: 0 }
        } else {
          delete _extractionConfig.timeoutHours;
          delete _extractionConfig.timeoutMinutes;
        }
        break;
      default: break;
    }
    this.setState({
      extractionConfig: { ..._extractionConfig },
      [type]: checked
    })
  }

  showHybridRegions = ({ clipSizeUpdate, clipSizeUpdateStatus, selectedSignalUpdate, clipData }) => {
    const { channelId, designId, hybridRegions, hybridLines } = this.props;

    if (!hybridLines || !hybridLines.length) {
      preview(clipData, designId);
      return;
    }
    this.hybridRegionsInfo.generateHybridRegions({
      channelId,
      designId,
      hybrid_regions: hybridRegions,
      hybrid_lines: hybridLines,
      updateHybridRegions: (params = {}) => this.props._updateHybridInfo({ ...params, id: channelId }),
      clipSizeUpdate,
      clipSizeUpdateStatus,
      updateLines: selectedSignalUpdate,
      selectedSignalUpdate,
      isAddHybridRegionsBox: true
    });
  }

  selectExtractionSetting = () => { }

  extractionSetting = () => {
    return <div className='select-extraction-setting-content'>
      <span className='extraction-radio-group-span'>Setting</span>
      <Select
        placeholder={"Model File"}
        value={'Default'}
        onSelect={this.selectExtractionSetting}
        className={"aurora-select"}
        popupClassName='aurora-select-dropdown'
      >
        {['Default', 'Default2'].map(item => <Option
          key={item}
          value={item}
          title={item}
        >{item}</Option>)}
      </Select>
    </div>
  }

  updateHybridInfo = (info) => {
    const { hybridErrorMsg } = this.props;
    if (hybridErrorMsg.includes('cannot be empty')) {
      this.props.clearHybridErrorMsg()
    }
    this.props._updateHybridInfo({ ...info })
  }

  suggestedAdvancedHFSSContent = (extraction, hybrid, siwave, type) => {
    if (extraction.type === "SIwave" && hybrid) {
      return <Fragment>
        <div className='extraction-content SIwave-extraction-setting-content'>
          {type === 'advanced' ?
            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              items={[
                {
                  key: "advanced",
                  label: "Advanced",
                  children: this.suggestedHFSSContent(siwave)
                }
              ]}
            /> :
            this.suggestedHFSSContent(siwave)}
        </div>
      </Fragment>
    } return null
  }


  suggestedHFSSContent = (siwave) => {
    return <Fragment>
      <span className="extraction-content-body">Ansys Suggested HFSS Regions</span>
      <Switch
        size="small"
        className="aurora-switch-small"
        checked={siwave && siwave.suggestHFSSRegions ? siwave.suggestHFSSRegions : false}
        onChange={(e) => this.switchRegionsChange(e, "siwave", "suggestHFSSRegions")}
      />
    </Fragment>
  }

  switchRegionsChange = (checked, type, subType) => {
    let config = { ...this.state.extractionConfig };
    if (checked) {
      if (this.state.isFirst) {
        this.setState({
          confirmVisible: true
        })
      } else {
        this.hybridRegionsInfo.clearHybridLineCache();
        this.updateHybridInfo({ hybridRegions: [], hybridLines: [] })
        this.props._saveChannelHybridRegion({ hybridRegions: [], hybridLines: [], verificationId: this.props.verificationId })
        config[type][subType] = checked;
        this.updateCurrentExtractionConfig(config);
        this.changePortType()
      }
    } else {
      config[type][subType] = checked;
      this.setState({
        isFirst: false
      })
      this.updateCurrentExtractionConfig(config);
    }
  }

  changeConfirmVisible = () => {
    let config = { ...this.state.extractionConfig };
    this.setState({
      confirmVisible: false,
      isFirst: false
    })
    this.hybridRegionsInfo.clearHybridLineCache();
    this.updateHybridInfo({ hybridRegions: [], hybridLines: [] })
    this.props._saveChannelHybridRegion({ hybridRegions: [], hybridLines: [], verificationId: this.props.verificationId })
    config.siwave.suggestHFSSRegions = true;
    this.updateCurrentExtractionConfig(config);
    this.changePortType()
  }

  changePortType = () => {
    // default type wave
    const { ports_generate_setup_list, port_setups, components, referenceNets, designId, extraction } = this.props;

    const pcbInfo = DesignInfo.getPCBInfo(designId);

    // change ports_generate_setup_list
    let _ports_generate_setup_list = ports_generate_setup_list.map(item => {
      let setup = {
        portType: WAVE,
        referenceType: ALL_PINS
      }
      return { ...item, setup }
    })

    // change port_setups
    let info = {
      referenceNets,
      referenceZ0: port_setups[0].z0,
      designId,
      extractionType: extraction.type
    }

    info.ports_generate_setup_list = _ports_generate_setup_list;
    info.applyComponents = _ports_generate_setup_list.map(item => item.component);

    const autoGenerationPort = new AutoGeneratePorts();
    let { port_setups: _port_setups, setupList: portsSetupList } = autoGenerationPort.autoGeneratePorts(
      port_setups,
      pcbInfo,
      {
        ...info
      }
    )
    let warnings = autoGenerationPort.getWarnings();
    let change = false;
    // Verify if wave can be used as the port type
    if (warnings && warnings.length) {
      warnings.forEach(item => {
        if (item.includes('wave port is not supported')) {
          const splitInfo = item.split(" ");
          const index = _ports_generate_setup_list.findIndex(item => item.component === splitInfo[1]);
          if (index > -1) {
            change = true;
            _ports_generate_setup_list[index].setup = {
              portType: CIRCUIT,
              referenceType: SINGLE_PIN
            }
          }
        }
      })
    }

    if (change) {
      // If wave cannot be used, change the default value to circuit
      info.ports_generate_setup_list = _ports_generate_setup_list;
      let obj = autoGenerationPort.autoGeneratePorts(
        port_setups,
        pcbInfo,
        {
          ...info
        }
      )
      _port_setups = obj.port_setups;
      portsSetupList = obj.setupList;
    }

    const errors = autoGenerationPort.getErrors();
    // If there are errors, it will directly fail
    if (errors && errors.length) { return }

    // Value in change components
    let _components = [];
    for (let compInfo of components) {
      const findSetupInfo = portsSetupList.find(item => compInfo.name === item.component);

      if (findSetupInfo && findSetupInfo.setup && findSetupInfo.setup && findSetupInfo.setup.portType) {
        let updateBallInfo = {};
        if (BALL_TYPE_NONE_LIST.includes(findSetupInfo.setup.portType) && ![BGA, DIE].includes(compInfo.type)) {
          updateBallInfo.ball_type = BALL_TYPE_NONE;
        } else if ((!compInfo.ball_type || (compInfo.ball_type !== BALL_TYPE_NONE && (!compInfo.ball_size || !compInfo.ball_height)))) {
          updateBallInfo = getUpdateCompBallInfoByPinSize(compInfo, designId);
        }
        compInfo = { ...compInfo, ...updateBallInfo }
      }

      _components.push(compInfo)
    }

    const _setupComponents = updateSetupComponentsByPortType({
      components: _components,
      ports_generate_setup_list: portsSetupList,
      designId,
      extractionType: getExtractionType(extraction),
      isUsedBall: true
    });
    this.props._updatePortSetupsToServer({ port_setups: _port_setups, referenceNets, ports_generate_setup_list: portsSetupList, components: _setupComponents });
  }

  packageSIwaveHFssContent = (allowModify, extraction, hybrid) => {
    const { siwave } = extraction;
    return <div className={`andes_v2-channel-extraction-main ${allowModify ? "" : "extraction-gray"}`}>
      {this.suggestedAdvancedHFSSContent(extraction, hybrid, siwave)}
    </div>
  }

  render() {
    const {
      extractionConfig,
      loading, error, units,
      hpcOptionSwitch, licWaitSwitch, timeoutSwitch, isShowHybridPreview, hybridLoading, frequencyPointsInfo, confirmVisible } = this.state;
    const { designId, verificationId, hybridRegions, hybridLines, channelId, designType, hybridErrorMsg, getHybridRegionsHFSSError } = this.props;
    const simulateHFSS = extractionConfig.type === 'HFSS' || extractionConfig.hybrid ? true : false;
    return (
      <div className={`andes_v2-channel-extraction-panel-content`}>
        <span className='extraction-radio-group-span'> Ansys</span>
        <Radio.Group onChange={this.changeAnsysType} value={extractionConfig.hybrid ? SIWAVE_HFSS : extractionConfig.type} className='extraction-radio-group'>
          <Radio
            value={'SIwave'}
            className='extraction-radio-group-item'
            disabled={loading}>
            SIwave</Radio>
          <Radio
            value={'HFSS'}
            className='extraction-radio-group-item'
            disabled={loading}>
            HFSS</Radio>
          {designType !== PACKAGE ? <Radio
            value={'SIwave+HFSS'}
            className='extraction-radio-group-item'
            disabled={loading}>
            SIwave+HFSS</Radio> : null}
        </Radio.Group>
        <ExtractionOptions
          updateChannelExtractionConfig={this.updateCurrentExtractionConfig}
          extraction={extractionConfig}
          units={units}
          simulateHFSS={simulateHFSS}
          allowModify={!loading}
          error={error}
          hpcOptionSwitch={hpcOptionSwitch}
          licWaitSwitch={licWaitSwitch}
          updateError={this._updateError}
          updateUnit={this._updateUnit}
          totalThickness={this.totalThickness}
          onRef={this.onRef}
          saveClipOptions={this.saveClipOptions}
          changeSwitch={this.changeSwitch}
          timeoutSwitch={timeoutSwitch}
          isShowHybridPreview={isShowHybridPreview}
          hybrid={extractionConfig.hybrid}
          designId={designId}
          verificationId={verificationId}
          hybridRegions={hybridRegions}
          hybridLines={hybridLines}
          hybridLoading={hybridLoading}
          hybridRegionsInfo={this.hybridRegionsInfo}
          designType={designType}
          updateHybridRegions={(params = {}) => this.updateHybridInfo({ ...params, id: channelId })}
          showHybridRegions={this.showHybridRegions}
          _updatePreviewButtonStatus={this._updatePreviewButtonStatus}
          hybridErrorMsg={hybridErrorMsg}
          getHybridRegionsHFSSError={getHybridRegionsHFSSError}
          onAdvancedRef={this.onAdvancedRef}
          changeAdvancesClipDesign={this.changeAdvancesClipDesign}
          frequencyPointsInfo={frequencyPointsInfo}
          suggestedHFSSContent={this.suggestedAdvancedHFSSContent}
          switchRegionsChange={this.switchRegionsChange}
        />
        {error && <span className='extraction-option-error-msg'>Error: {error.error}</span>}
        {confirmVisible ? <DelConfirm
          type="determine"
          okClick={() => this.changeConfirmVisible()}
          maskStyle={true}
          message={"This is a beta function. Are you sure to use it?"}
        /> : null}
      </div>
    )
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { channel: { channelId, channelInfo = {}, hybridStatus, hybridInfo = {} } } } = state;

  let extraction = { type: "SIwave" }, hybridRegions = [], hybridLines = [], hybridId = null, designType = null;
  if (channelInfo && channelInfo.content && channelInfo.content.extraction) {
    extraction = channelInfo.content.extraction;
    hybridRegions = hybridInfo.hybridRegions || [];
    hybridLines = hybridInfo.hybridLines || [];
    hybridId = hybridInfo.id;
    const design = designConstructor.getDesign(channelInfo.designId) || {};
    designType = design.type;
  }

  const {
    signals = [],
    selectedSignals = [],
    port_setups = [],
    ports_generate_setup_list = [],
    referenceNets = [],
    components
  } = channelInfo.content || {};
  return {
    channelId,
    extraction,
    designId: channelInfo.designId,
    verificationId: channelInfo.verificationId,
    hybridStatus,
    selectedSignals,
    hybridRegions,
    hybridLines,
    hybridId,
    designType,
    signals,
    port_setups,
    ports_generate_setup_list,
    referenceNets,
    components
  }
};


const mapDispatch = (dispatch) => ({
  _saveChannelExtractionConfig(extraction, prevExtraction, channelId) {
    dispatch(saveChannelExtractionConfig(extraction, prevExtraction, channelId))
  },
  _savePreviewStatus(status) {
    dispatch(savePreviewStatus(status))
  },
  _updateHybridStatus(status) {
    dispatch(updateHybridStatus(status))
  },
  _saveChannelHybridRegion({ hybridRegions, hybridLines, verificationId, saveHfssZones }) {
    dispatch(saveChannelHybridRegion({ hybridRegions, hybridLines, verificationId, saveHfssZones }))
  },
  _updateHybridInfo({ hybridLines, hybridRegions, id }) {
    dispatch(updateHybridInfo({ hybridLines, hybridRegions, id }))
  },
  _updatePortSetupsToServer(data) {
    dispatch(updatePortSetupsToServer(data))
  }
})

export default connect(mapState, mapDispatch)(ExtractionOptionsPanel);