import React, { Component, Fragment } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip, Select } from 'antd';
import Table from '@/components/EditableTable';
import { numberCheck } from '../../../../services/helper/dataProcess';
import { getValueAndUnit } from '../../../../services/Andes_v2/simulation';
import { scaleConversion } from '../../../../services/helper/numberHelper';

const { Option } = Select;
const cphyColumns = [
  {
    title: 'Name',
    dataIndex: 'key',
    width: 130
  },
  {
    title: 'Value',
    dataIndex: 'value'
  }
]
function getText(text) {
  switch (text) {
    case "v_idth":
      return <span>V<sub>IDTH</sub></span>
    case "v_idtl":
      return <span>V<sub>IDTL</sub></span>
    case "t_eye_ramp":
      return <span>t<sub>EYE_RAMP</sub></span>
    case "t_mid":
      return <span>t<sub>MID</sub></span>
    case "symbol_rate":
      return <span>Symbol Rate</span>
    default:
      return ""
  }
}

function getDescription(key) {
  switch (key) {
    case "v_idth":
      return "Differential input high threshold"
    case "v_idtl":
      return "Differential input low threshold"
    case "t_eye_ramp":
      return "Eye ramp time at the reference channel output"
    case "t_mid":
      return "Width of the horizontal mid-section of the hexagonal Eye Interior"
    default:
      return ""
  }
}
function getTableData(configData) {
  const tableData = [];
  for (const key of ["symbol_rate", "v_idth", "v_idtl", "t_eye_ramp", "t_mid"]) {
    const [value, unit] = getValueAndUnit(configData[key]);
    tableData.push({
      key: key,
      value,
      unit
    })
  }
  return tableData;
}

function getStandardSymbolRateValue(symbolRate) {
  if (!symbolRate) return {};
  const [value, unit] = symbolRate.split(' ');
  let standardValue = 1;
  if (value && unit) {
    standardValue = Number(value) * scaleConversion("G", unit.replace("sps", ""))
  }
  return { symbolRateValue: value, standardValue, symbolRateUnit: unit };
}

class CPHYEyeMaskTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cphyColumns,
      configData: {},
      editInfo: {} // {key, value}
    };
  }

  componentDidMount() {
    const _cphyColumns = [...cphyColumns];
    _cphyColumns[0].render = (text) => {
      const tooltip = getDescription(text);
      return (
        <span className='eye-mask-table-name-box'>
          {getText(text)}
          {tooltip ? <Tooltip title={tooltip} overlayClassName='icon-tooltip'>
            <InfoCircleOutlined className='eye-mask-table-info-circle-icon' />
          </Tooltip> : null}
        </span>
      );
    }

    _cphyColumns[1].render = (_, record) => {
      const { editInfo, configData } = this.state;
      const { standardValue } = getStandardSymbolRateValue(configData.symbol_rate);
      return <div className="eye-mask-table-input-box">
        <Input
          value={editInfo.key === record.key ? editInfo.value : record.value}
          addonAfter={this.addonAfter(record.key, record.unit)}
          onFocus={() => this.changeValue(record.value, record.key)}
          onChange={(e) => this.changeValue(e.target.value, record.key)}
          onPressEnter={(e) => { e.target.blur(); this.saveValue(record.key, record.unit) }}
          onBlur={() => this.saveValue(record.key, record.unit)}
          disabled={standardValue >= 1 && record.key === 't_mid'}
        />
      </div>
    }
    this.setState({
      cphyColumns: [..._cphyColumns]
    })
  }

  changeValue = (value, key) => {
    this.setState({
      editInfo: {
        key,
        value: value
      }
    })
  }

  saveValue = (key, unit) => {
    const { configData, editInfo } = this.state;
    if (!numberCheck(editInfo.value)) {
      this.setState({ editInfo: {} })
    }
    let t_mid = configData.t_mid;
    if (key === "symbol_rate") {
      const symbolRate = Number(editInfo.value) * scaleConversion("G", unit.replace("sps", ""));
      if (symbolRate >= 1) {
        t_mid = 0;
      }
    }

    this.setState({
      configData: {
        ...configData,
        t_mid,
        [key]: ["symbol_rate", "v_idth", "v_idtl"].includes(key) ? `${editInfo.value} ${unit}` : Number(editInfo.value)
      },
      editInfo: {}
    }, () => {
      this.props.refreshPlot();
    })
  }

  initState = () => {
    const { configData } = this.props;
    this.setState({
      configData: { ...configData }
    })
  }

  getTimePoints = () => {
    const { configData } = this.state;
    return configData;
  }

  changeSymbolRateUnit = (unit) => {
    const { configData } = this.state;
    const [value] = getValueAndUnit(configData.symbol_rate);
    const { standardValue } = getStandardSymbolRateValue(`${value} ${unit}`);
    let t_mid = configData.t_mid;
    if (standardValue >= 1) {
      t_mid = 0;
    }
    this.setState({
      configData: {
        ...this.state.configData,
        t_mid,
        symbol_rate: `${value} ${unit}`
      }
    }, () => {
      this.props.refreshPlot();
    })
  }

  addonAfter = (text = "", unit) => {
    if (text === "symbol_rate") {
      return (
        <Select
          value={unit}
          className="aurora-select"
          popupClassName="aurora-select-dropdown"
          onChange={this.changeSymbolRateUnit}
        >
          <Option value="sps">sps</Option>
          <Option value="Ksps">Ksps</Option>
          <Option value="Msps">Msps</Option>
          <Option value="Gsps">Gsps</Option>
        </Select>
      );
    }
    return text.startsWith("v") ? "mV" : "UI";
  }

  render() {
    const { cphyColumns, configData } = this.state;
    const tableData = getTableData(configData);
    return <Fragment>
      <div className='eye-mask-table-box aurora-setup-border'>
        <div className="eye-mask-data-table">
          <Table
            rowKey={record => record.key}
            columns={cphyColumns}
            size="small"
            dataSource={tableData}
          />
        </div>
      </div>
    </Fragment>
  }
}

export default CPHYEyeMaskTable;