import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Button, Input, Select, Spin, Divider } from 'antd';
import Canvas from '../PreLayout/SchematicSetup/schematicCanvas';
import preLayoutData from '../../../../services/Sierra/prelayout/preLayoutData';
import { I2CI3C } from '../../../../services/PCBHelper/constants';
import {
  PlusSquareOutlined
} from '@ant-design/icons';
import { getPreLayoutSignalsSections, TRACE_LENGTH_UNITS } from '../../../../services/Sierra/prelayout/Schematic';
import UnitAddonAfter from '../../../../components/UnitAddonAfter';
import { numberCheck } from '../../../../services/helper/dataProcess';
import { unitChange } from '../../../../services/helper/mathHelper';
import { splitValueUnitByUnits } from '../../../../services/helper/numberHelper';
import { getDefaultIndex } from '../../../../services/helper/setDefaultName';
import { getPanelMaxHeight, getPanelMaxWidth } from '../../../../services/helper/panelSizeHelper';
import designConstructor from '../../../../services/helper/designConstructor';
import projectDesigns from '../../../../services/helper/projectDesigns';
import { DESIGN_SUCCESS } from '../../../../constants/designCategory';
import './index.css';
import '../index.css';

class PreLayoutSchematicSetup extends Component {
  constructor(props) {
    super(props);

    this.dialogRoot = document.getElementById('root');
    this.state = {
      loading: false,
      width: 800,
      height: 800,
      maxWidth: 1000,
      maxHeight: 800,
      signals: [],
      components: [],
      signalNumber: 2,
      GPIO: "",
      canvasConfig: {},
      canvasUpdate: false,
      selectSections: [],
      sections: [],
      optionList: []
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 800) - 60,
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  selectAfter = (traceLengthUnit, secId, expId) => {
    return UnitAddonAfter({ unit: traceLengthUnit, defaultUnit: TRACE_LENGTH_UNITS[0], changeUnit: (unit) => this.changeUnit(unit, secId, expId), list: TRACE_LENGTH_UNITS })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.setState({
      loading: true,
      canvasUpdate: false
    }, () => {
      this.initPreLayoutData()
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.sweepCreateStatus === true && this.props.sweepCreateStatus === false) {
      this.props.closePanel()
    }
  }

  initPreLayoutData = () => {
    const { pcb } = this.props;
    const preLayout = preLayoutData.getLocalPrelayout(pcb.id);
    if (!preLayout || !preLayout.content) {
      this.setState({
        loading: false,
        signals: [],
        components: [],
        signalNumber: 2,
        GPIO: I2CI3C,
        canvasConfig: { width: 22, height: 16 },
        canvasUpdate: true,
        optionList: []
      })
    }
    const _config = preLayout.content.canvasConfig;
    const actualHeightNum = _config && _config.height ? Number(_config.height) : 16;
    const actualHeight = (actualHeightNum + 3) * 36;
    const actualWidthNum = _config && _config.width ? Number(_config.width) : 22;
    const actualWidth = (actualWidthNum + 6) * 36;

    const sections = getPreLayoutSignalsSections(preLayout.content.signals || [], true);
    let selectSections = [];
    const _sections = JSON.parse(JSON.stringify(sections || []));
    for (let item of _sections) {
      const findSec = (pcb.trace || []).find(it => it.id === item.id);
      if (!findSec) {
        continue;
      }
      const { value, unit } = splitValueUnitByUnits(findSec.length || "", TRACE_LENGTH_UNITS);

      item.traceLengthInput = value;
      item.traceLengthValue = value;
      item.traceLengthUnit = unit;
    }
    selectSections = [{ id: "1", sections: _sections }];

    this.setState({
      loading: false,
      width: actualWidth,
      height: actualHeight,
      signals: preLayout.content.signals || [],
      components: preLayout.content.components || [],
      signalNumber: preLayout.content.signalNumber,
      GPIO: preLayout.content.GPIO,
      canvasConfig: preLayout.content.canvasConfig,
      canvasUpdate: true,
      sections,
      selectSections,
      optionList: this.getPcbOptionsList()
    })
  }

  getPcbOptionsList = () => {
    const { currentProjectId, experiments, expId, base } = this.props;

    let pcbsList = [];
    const currentExp = experiments.find(exp => exp.id === expId);
    const basePCBs = base && base.Interfaces ? base.Interfaces.map(i => i.pcb) : [];
    if (currentExp) {
      currentExp.Interfaces.forEach((item, index) => {
        if (item.pcb) {
          pcbsList.push(item.pcb.name);
        } else {
          pcbsList.push(basePCBs[index].name);
        }
      })
    }
    pcbsList = [...new Set(pcbsList)]
    const _optionList = projectDesigns.getAvailableDesigns(currentProjectId).filter(item => item.category === DESIGN_SUCCESS)
      .filter(item => !pcbsList.includes(item.name)).map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      });
    return _optionList;
  }

  closeModal = () => {
    const { selectSections } = this.state;
    const { pcb, expId, interfaceIndex } = this.props;
    if (!selectSections.length) {
      this.props.closePanel()
      return;
    }
    const filterSections = selectSections[0].sections.filter(sec => sec.traceLengthValue && `${sec.traceLengthValue}${sec.traceLengthUnit || TRACE_LENGTH_UNITS[0]}` !== sec.traceLength);
    const trace = filterSections.map(it => {
      return {
        id: it.id,
        length: `${it.traceLengthValue}${it.traceLengthUnit || TRACE_LENGTH_UNITS[0]}`,
      }
    })
    this.props.updateSingleExperiment({ pcb, trace, expId, interfaceIndex }, "trace");
    this.props.closePanel()
  }

  updateLoading = (loading) => {
    this.setState({
      loading
    })
  }

  updateCanvasStatus = (canvasUpdate) => {
    this.setState({
      canvasUpdate
    })
  }

  selectSectionsChange = (values, id) => {
    const { selectSections, sections } = this.state;
    let _selectSections = [...selectSections];
    const index = _selectSections.findIndex(item => item.id === id);
    if (index < 0) {
      return
    }
    const selected = sections.filter(item => values.includes(item.id));
    _selectSections[index].sections = selected;
    this.setState({
      selectSections: _selectSections
    })
  }

  sectionLengthChange = (e, secId, expId) => {
    const { selectSections } = this.state;
    let _selectSections = [...selectSections];
    const index = _selectSections.findIndex(item => item.id === expId);
    if (index < 0) {
      return;
    }

    const secIndex = _selectSections[index].sections.findIndex(item => item.id === secId);
    if (secIndex < 0) {
      return;
    }

    _selectSections[index].sections[secIndex].traceLengthInput = e.target.value;
    this.setState({
      selectSections: _selectSections
    })
  }

  sectionLengthBlur = (e, secId, expId) => {
    const { selectSections } = this.state;
    let _selectSections = [...selectSections];
    const index = _selectSections.findIndex(item => item.id === expId);
    if (index < 0) {
      return;
    }

    const secIndex = _selectSections[index].sections.findIndex(item => item.id === secId);
    if (secIndex < 0) {
      return;
    }
    const value = e.target.value;
    if (numberCheck(value)) {
      _selectSections[index].sections[secIndex].traceLengthInput = _selectSections[index].sections[secIndex].traceLengthValue;
    } else {
      _selectSections[index].sections[secIndex].traceLengthValue = value;
    }

    this.setState({
      selectSections: _selectSections
    })
  }



  changeUnit = (unit, secId, expId) => {
    const { selectSections } = this.state;
    let _selectSections = [...selectSections];
    const index = _selectSections.findIndex(item => item.id === expId);
    if (index < 0) {
      return;
    }

    const secIndex = _selectSections[index].sections.findIndex(item => item.id === secId);
    if (secIndex < 0) {
      return;
    }

    _selectSections[index].sections[secIndex].traceLengthValue = _selectSections[index].sections[secIndex].traceLengthValue ? unitChange({
      num: _selectSections[index].sections[secIndex].traceLengthValue,
      oldUnit: _selectSections[index].sections[secIndex].traceLengthUnit,
      newUnit: unit,
      decimals: 2
    }).number : "";
    _selectSections[index].sections[secIndex].traceLengthUnit = unit;
    _selectSections[index].sections[secIndex].traceLengthInput = _selectSections[index].sections[secIndex].traceLengthValue;
    this.setState({
      selectSections: _selectSections
    })
  }

  createExp = () => {
    const { selectSections } = this.state;
    const { pcb } = this.props;
    this.props.updateSweepCreateStatus(true);
    let newExps = [];
    for (let item of selectSections) {
      const filterSections = item.sections.filter(sec => sec.traceLengthValue && `${sec.traceLengthValue}${sec.traceLengthUnit || TRACE_LENGTH_UNITS[0]}` !== sec.traceLength);
      newExps.push({
        pcb: pcb,
        trace: filterSections.map(it => {
          return {
            id: it.id,
            length: `${it.traceLengthValue}${it.traceLengthUnit || TRACE_LENGTH_UNITS[0]}`,
          }
        })
      })
    }
    this.props._createExpByTrace(newExps);
  }

  addNewExp = (e) => {
    e && e.stopPropagation();
    const { selectSections, sections } = this.state;
    let _selectSections = [...selectSections];
    _selectSections.push({ id: getDefaultIndex(_selectSections.length, _selectSections.map(it => it.id)), sections: JSON.parse(JSON.stringify(sections || [])) });
    this.setState({
      selectSections: _selectSections
    })
  }

  changePCB = async (id) => {
    const { currentProjectId, interfaceIndex, basePCB, expId, expName } = this.props;
    const selectPCB = projectDesigns.getAvailableDesigns(currentProjectId).find(item => item.id === id);
    if (!id || !selectPCB) {
      return;
    }
    const name = selectPCB.name;
    const subId = selectPCB.subId;
    const isPreLayout = designConstructor.isPreLayout(id);
    let isSchematic = false;
    if (isPreLayout) {
      await preLayoutData.getPreLayout(id);
      isSchematic = preLayoutData.isSchematic(id)
    }

    this.props.changeExperimentPCB({ name, interfaceIndex, projectId: currentProjectId, experimentId: expId, basePCB })
    if (isSchematic) {
      this.props.updateSchematicPreLayoutInfo({
        id: expId,
        name: expName,
        interfaceIndex,
        isSweepBase: expName === "Base" ? true : false,
        basePCB,
        currentPCB: { id, name, subId }
      })
      this.setState({
        loading: true,
        canvasUpdate: false
      }, () => {
        this.initPreLayoutData()
      });
    } else {
      this.props.closePanel();
    }
  }
  renderDialog() {
    const { pcb, isSweepBase, sweepCreateStatus, expName } = this.props;
    const { loading, width, height, signals, signalNumber, components, GPIO, canvasConfig, canvasUpdate, /* sections, */ selectSections, maxWidth, maxHeight, optionList } = this.state;
    /*  const options = sections.map(item => {
       return {
         label: item.title,
         value: item.id,
       }
     }); */
    const content = (
      <Panel
        className='sierra-sweeping-pre-layout-schematic-panel sierra-panel'
        title={<span><label style={{ color: '#2d5eb2' }}>{expName} - {pcb.name}</label> - Schematic Setup</span>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={width + 348}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
      >
        <div className='sweep-pre-layout-schematic-setup-main' style={{ height: height + 20 }}>
          <div className='sweep-pre-layout-schematic-setup-left'>
            {!isSweepBase ? <div className='sweep-pre-layout-schematic-select-section'>
              <span>PCB</span>
              <Select
                className='aurora-select'
                placeholder="Please select PCB"
                defaultValue={pcb.id}
                onChange={(value) => this.changePCB(value)}
                options={[{ label: pcb.name, value: pcb.id }, ...optionList]}>
              </Select>
            </div> : null}
            <div className='sweep-pre-layout-length-left-title'>
              <span>{isSweepBase ? "Length Experiments" : "Length"}</span>
              {isSweepBase ? <PlusSquareOutlined className='sweep-pre-layout-length-left-add-icon' onClick={(e) => this.addNewExp(e)} /> : null}
            </div>
            <div className='sweep-pre-layout-schematic-length-list'>
              {selectSections.map((expItem, index) =>
                <Fragment key={expItem.id}>
                  {/* <div className='sweep-pre-layout-schematic-select-section'>
                    <span>Sections</span>
                    <Select
                      mode="multiple"
                      allowClear
                      className='aurora-select'
                      placeholder="Please select sections"
                      defaultValue={[]}
                      onChange={(values) => this.selectSectionsChange(values, expItem.id)}
                      options={options}>
                    </Select>
                  </div> */}
                  {(expItem.sections || []).map(secItem =>
                    <div className='sweep-pre-layout-schematic-select-section' key={secItem.id}>
                      <span className='sweep-pre-layout-schematic-select-section-name'>{secItem.title}</span>
                      <Input
                        value={secItem.traceLengthInput}
                        className='aurora-input'
                        onChange={(e) => this.sectionLengthChange(e, secItem.id, expItem.id)}
                        onBlur={(e) => this.sectionLengthBlur(e, secItem.id, expItem.id)}
                        addonAfter={this.selectAfter(secItem.traceLengthUnit, secItem.id, expItem.id)}
                      />
                    </div>)}
                  {index !== selectSections.length - 1 ? <Divider /> : null}
                </Fragment>
              )}
            </div>
            {isSweepBase ? <div className='sweep-pre-layout-length-left-title sweep-pre-layout-length-left-button'>
              <Button
                title={"Create New Experiments"}
                type="primary"
                size="small"
                ghost
                disabled={!selectSections.filter(item => item.sections && item.sections.length).length}
                onClick={() => this.createExp()}
                className='sweep-create-btn' loading={sweepCreateStatus || false}>Create</Button>
            </div> : null}
          </div>
          <div className='sweep-pre-layout-schematic-setup-canvas' style={{ height: height + 20, width: width + 14 }}>
            <Spin spinning={loading}>
              <Canvas
                isSweep={true}
                id={pcb.id}
                canvasUpdate={canvasUpdate}
                loading={loading}
                width={width}
                height={height}
                signals={signals}
                components={components}
                signalNumber={signalNumber}
                GPIO={GPIO}
                canvasConfig={canvasConfig}
                updateSchematicLoading={this.updateLoading}
                fixed={false}
                updateCanvasStatus={this.updateCanvasStatus}
              />
            </Spin>
          </div>
        </div>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return (
      <Fragment>
        <div className='editable-cell-value-wrap'>
          {this.props.text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PreLayoutSchematicSetup;