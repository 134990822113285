import LayoutData from '../data/LayoutData';
import { strDelimited } from "../helper/split";
import {
  IGNORE, RES, IND, CAP, IC, CONNECTOR, CONTROLLER, MEMORY, CHIP, JUMPER, FERRITE, SWITCH,
  CMC, DIODE, CMC_COMMENT, BGA, DIE, TRANSISTOR, REMOVED, COMP_REPEATER, TEST_POINT, UNUSED
} from '../../constants/componentType';
import { ANDES_V2 } from "../../constants/pageType";
import { getCompTypeByPrefixLib } from '../Designs/helper';

const SERDES_TYPES = [IC, CONNECTOR, BGA, DIE];
const RLC_TYPES = [RES, IND, CAP];
const RLC_TYPES_LIST = [{
  key: RES,
  value: "Resistor"
},
{
  key: IND,
  value: "Inductor"
},
{
  key: CAP,
  value: "Capacitor"
}];
const DDR_COMP_TYPES = [CONTROLLER, MEMORY, BGA, DIE];
function getLayoutComponents({ layers, COMP_PREFIX_LIB }) {
  let compList = [];
  let layoutCompList = {};
  layers.forEach(item => {
    if (item.mComponentLayer !== null) {
      compList.push(item.mComponentLayer);
    }
  });
  compList.forEach(layer => {
    let comps = layer.mComponents;
    if (comps) {
      for (let component of comps) {
        layoutCompList[component.mName] = { comp: component, layer: layer.mName };
      }
    }
  });

  //update the component information
  for (var compName in layoutCompList) {
    var compInfo = layoutCompList[compName];
    compInfo.name = compName;
    compInfo.part = compInfo.comp && compInfo.comp.mPart && compInfo.comp.mPart.mInfo ? compInfo.comp.mPart.mInfo.mPartName : "";
    compInfo.value = compInfo.comp && compInfo.comp.mPart && compInfo.comp.mPart.mInfo && compInfo.comp.mPart.mInfo.mPhyProps ? compInfo.comp.mPart.mInfo.mPhyProps.getValue('value') : "";
    compInfo.location = compInfo.comp && compInfo.comp.mLocation ? compInfo.comp.mLocation.mPosition : null;
    compInfo.pinList = compInfo.comp && compInfo.comp.mPart ? compInfo.comp.mPart.mPinList : [];

    if (compInfo.comp && compInfo.comp.mPart && compInfo.comp.mPart.mInfo && compInfo.comp.mPart.mInfo.mPartType &&
      compInfo.comp.mPart.mInfo.mPartType.length > 0) {
      compInfo.type = compInfo.comp.mPart.mInfo.mPartType;
      var type = compInfo.type.toUpperCase();
      if (type === 'R' || type === 'RES' || type === 'RESISTOR') {
        compInfo.type = RES;
      }
      else if (type === 'C' || type === 'CAP' || type === 'CAPACITOR') {
        compInfo.type = CAP;
      }
      else if (type === 'L' || type === 'IND' || type === 'INDUCTOR') {
        compInfo.type = IND;
      } else {
        compInfo.type = checkCompsType(compName, COMP_PREFIX_LIB, compInfo.pinList.length);
      }
    } else {
      if (!compName.match(/^([A-Za-z]+)(([0-9]+)|-|_)/g)) {
        compInfo.type = IGNORE;
      } else {
        compInfo.type = checkCompsType(compName, '', compInfo.pinList.length, compInfo.part);
      }
    }
    delete compInfo.comp;
  }
  return layoutCompList;
};

function getTypeFromPartName(part) {
  //part name prefix include type (CAP NP_C0603_39P370_DC_22UC_6.3)
  var partName = part.toUpperCase();
  let _type = null;
  if (partName.match(/^RES/ig)) {
    _type = 'Res';
  } else if (partName.match(/^CAP/ig)) {
    _type = 'Cap';
  } else if (partName.match(/^IND/ig)) {
    _type = 'Ind';
  }
  return _type
}

// const test = ['C1', 'c_1', 'c_f', 'C4D9', 'R5F54', 'L576',
//   'C123P_A_1', 'RA06_20', 'RL_20', 'VR789IA_16', 'R06_02', 'SML123_01'];
// console.log(test.map(item => checkCompsType(item)))
// TODO, check components type: component name, pin number, part name
function checkCompsType(compName, COMP_PREFIX_LIB, pinLength, partName, product) {
  let type = 'Unused';
  if (toString.call(compName) !== '[object String]') {
    return type;
  }
  const name = compName.toString();
  const words = name.split(/^([A-Za-z]+)(([0-9]+)|-|_)/g);

  if (words.length < 2) {
    return type;
  }
  if (!words[1]) {
    return type;
  }

  switch (words[1].toLowerCase()) {
    case "c":
    case "ct":
      type = CAP;
      break;
    case "l":
      type = IND;
      break;
    /*   case "d":
        type = product === ANDES_V2 && pinLength === 2 ? DIODE : IGNORE;
        break; */
    default:
      type = IGNORE;
      break;
  };

  //find Res
  const reg = /^(r)/ig;
  if (words[1].match(reg)) {
    type = RES;
  }

  //find Cap
  const capReg = /^(c)/ig;
  if (words[1].match(capReg)) {
    type = CAP;
  }

  //find Diode
  const diodeReg = /^(d)/ig;
  if (product === ANDES_V2 && words[1].match(diodeReg)) {
    type = DIODE;
  }

  if (partName && ((COMP_PREFIX_LIB && type === 'Ignore') || !COMP_PREFIX_LIB)) {
    const _type = getTypeFromPartName(partName)
    if (_type) {
      type = _type;
    }
  }

  // Judging type by custom component reference designator.
  if (COMP_PREFIX_LIB) {
    type = getCompTypeByPrefixLib(name, COMP_PREFIX_LIB);
  }

  if (type === CAP && (pinLength > 10 || pinLength < 2) && pinLength !== 0) {
    type = IGNORE
  }

  return type;
}

function getComponentsWithNetList({ netList, pcbNetsList, layers, CompsInfo, findPinName = false }) {
  const _netList = [...new Set([...netList])];
  if (!CompsInfo) {
    CompsInfo = getLayoutComponents({ layers });
  }
  let components = []; // [{pin,name,net,value,type}]
  _netList.forEach(netName => {
    const pinList = getPinList(netName, pcbNetsList);
    const list = pinList.map(pin => {
      const compInfo = CompsInfo[pin.mCompName];
      let _compInfo = compInfo;
      if (!compInfo) {
        _compInfo = {
          type: "Unused",
          value: "",
          part: "UNKNOW"
        }
      }
      const { type, value, part } = _compInfo;
      let obj = {
        pin: pin.mPinNum,
        name: pin.mCompName,
        net: netName,
        value: RLC_TYPES.includes(type) ? value : "",
        type: type,
        part
      };
      if (findPinName) {
        obj.pinName = compInfo ? ((compInfo.pinList || []).find(item => item.mNumber === pin.mPinNum) || {}).mName || "" : "";
      }
      return obj
    });
    components.push(...list);
  });
  return components;
};

function getPinList(netName, netList) {
  if (!netName || !netList) return [];
  const netObj = netList.find(item => item.mName === netName);
  // netObj.mPinList - mLayerName, mCompName, mPinNum, mMetalLayerName
  return netObj.mPinList || [];
}

function checkCompNamePrefix(compName) {
  if (typeof (compName) !== "string") {
    return;
  }
  const name = compName.toUpperCase();
  const firstWord = strDelimited(name, "", { returnIndex: 0 });
  switch (firstWord) {
    case "U":
      return IC;
    case "J":
    case "K":
      return CONNECTOR;
    case "C":
      return CAP;
    case "L":
      return IND;
    case "R":
      return RES;
    default: return;
  }
}

function getPinsWithNetListAndComp({ netList, pcbNetsList, layers, compName }) {
  const _netList = [...new Set([...netList])];
  const CompsInfo = getLayoutComponents({ layers });
  const compInfo = CompsInfo[compName];

  let componentPins = []; // [{pin,name,net,value,type}]
  _netList.forEach(netName => {
    const pinList = getPinList(netName, pcbNetsList).filter(item => item.mCompName === compName);
    const list = pinList.map(pin => {
      let _compInfo = compInfo;
      if (!compInfo) {
        _compInfo = {
          type: "Unused",
          value: "",
          part: "UNKNOW"
        }
      }
      const { type, value, part, location, } = _compInfo;
      const pinItem = _compInfo.pinList.find(item => item.mNumber === pin.mPinNum);
      return {
        pin: pin.mPinNum,
        name: pin.mCompName,
        net: netName,
        value: RLC_TYPES.includes(type) ? value : "",
        type: type,
        part,
        compLocation: location,
        pinLocation: pinItem.mLocation
      }
    });
    componentPins.push(...list);
  });
  return componentPins;
};

function getLayerByComponent(designID, chip) {
  const _DesignData = LayoutData.getLayout(designID);
  const _LayerManager = _DesignData.GetLayerManager();
  const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;

  const bottomComp = _LayerManager.GetMetalCompLayer(metalLayerNames[metalLayerNames.length - 1]);
  let components = bottomComp.mComponents;

  if (components.find(item => item.mName === chip)) {
    return 0;
  } else {
    return 1;
  }
}

function getComponentByLayer(designID, layer, compName) {
  const _DesignData = LayoutData.getLayout(designID);
  const _LayerManager = _DesignData.GetLayerManager();

  const compLayer = _LayerManager.GetComponentLayer(layer);
  const components = compLayer ? compLayer.GetComponents() : [];

  if (!compName) {
    return components;
  }
  return components.find(item => item.mName === compName);
}

function getComponentByNameDesignData(_DesignData, compName) {
  if (!_DesignData || !_DesignData.GetLayerManager) {
    return null;
  }
  const _LayerManager = _DesignData.GetLayerManager();
  if (!_LayerManager || !_LayerManager.GetAllMetalLayers) {
    return null;
  }
  const metalLayerNames = _LayerManager.GetAllMetalLayers().mValues;

  if (!metalLayerNames.length) {
    return null;
  }
  //get top layer components
  const topComp = _LayerManager.GetMetalCompLayer(metalLayerNames[0]);
  let comp = topComp ? topComp.mComponents.find(item => item.mName === compName) : null;
  if (comp) {
    return comp;
  }

  //get bottom layer components
  const bottomComp = _LayerManager.GetMetalCompLayer(metalLayerNames[metalLayerNames.length - 1]);
  comp = bottomComp ? bottomComp.mComponents.find(item => item.mName === compName) : null;
  if (comp) {
    return comp;
  }

  //get all layer components
  const allComponents = _LayerManager.GetAllLayerComponents();
  comp = allComponents.find(item => item.mName === compName);
  return comp;
}

function getComponentByName(designID, compName) {
  const _DesignData = LayoutData.getLayout(designID);
  const _data = getComponentByNameDesignData(_DesignData, compName)
  return _data;
}

function getComponentNeedInfoByName({ designID, compName, designData }) {
  let componentInfo = {};
  if (designID) {
    const _designData = LayoutData.getLayout(designID);
    componentInfo = getComponentByNameDesignData(_designData, compName)
  } else if (designData) {
    componentInfo = getComponentByNameDesignData(designData, compName)
  }

  let partNumber = '';
  if (componentInfo && componentInfo.mPart && componentInfo.mPart.mInfo && (componentInfo.mPart.mInfo.mPartType || componentInfo.mPart.mInfo.mPartName)) {
    partNumber = componentInfo.mPart.mInfo.mPartType ? componentInfo.mPart.mInfo.mPartType : componentInfo.mPart.mInfo.mPartName
  }

  const pinLength = componentInfo && componentInfo.mPinsLocationList && componentInfo.mPinsLocationList.length ? componentInfo.mPinsLocationList.length : 0;
  const pinList = componentInfo && componentInfo.mPart && componentInfo.mPart.mPinList ? componentInfo.mPart.mPinList : [];
  return { pinLength, partNumber, pinList }
}

function getCompsByNets({ nets, netsList,/*  returnName = true */ }) {
  let comps = [], pinsObj = {};
  for (let net of nets) {
    const compPinList = getPinList(net, netsList);
    comps.push(...compPinList.map(item => item.mCompName));
    for (let pinItem of compPinList) {
      comps.push(pinItem.mCompName);

      if (pinsObj[pinItem.mCompName]) {
        pinsObj[pinItem.mCompName].push(pinItem.mPinNum)
      } else {
        pinsObj[pinItem.mCompName] = [pinItem.mPinNum]
      }
    }
  }
  comps = [...new Set(comps)]
  return { comps, pinsObj };
}

export {
  IGNORE,
  RES,
  IND,
  CAP,
  TRANSISTOR,
  JUMPER,
  FERRITE,
  SWITCH,
  RLC_TYPES,
  IC,
  CONNECTOR,
  CONTROLLER,
  MEMORY,
  SERDES_TYPES,
  DDR_COMP_TYPES,
  CHIP,
  DIODE,
  CMC,
  REMOVED,
  CMC_COMMENT,
  RLC_TYPES_LIST,
  getLayoutComponents,
  checkCompsType,
  getComponentsWithNetList,
  checkCompNamePrefix,
  getPinsWithNetListAndComp,
  getLayerByComponent,
  getComponentByLayer,
  getComponentByName,
  getComponentByNameDesignData,
  getTypeFromPartName,
  getComponentNeedInfoByName,
  getCompsByNets,
  COMP_REPEATER,
  TEST_POINT,
  UNUSED
}