import {
  createSierraLibraryList, getSierraLibraryList, createSierraStimulus, getLibraryParseData,
  delSierraLibraryList, getSierraStimulusData, updateSierraStimulus, getSierraLibraryFile,
  getMacroModelingStatusByLibraryId, getSierraPartLibraryList, uploadSierraPartLibrary,
  getSierraPartLibraryListBySearch, getSierraPartLibraryCheckFiles, editSierraPartLibrary,
  editSierraLibraryFile, getPartRepeaterUpdateStatus, autoUpdatePartRepeaterSetting,
  setSierraDefaultLibrary, getSierraDefaultLibrary, getSierraNetsFilterLibraryList, uploadSierraNetsFilterLibrary,
  editSierraNetsFilterLibrary, searchSierraPowerNetsByKeywords, geSierraPowerNetsSearchStatus, getSierraTraceTemplateLibrary,
  updateSierraTraceTemplateLibrary, calculateSierraTraceImpedance, getSierraTraceImpedanceData
} from '../../api/sierra/userLib';
import apiProcess from '../../api/utility';
import { getSPFolderFileDetail, getSPFolderFileList, updateLibraryFile } from '../../api/v2/libraryCtrl';
import checkError from '../../api/checkError';
import { SUCCESS } from '@/constants/returnCode';
import IbisModelHelper from './IbisModelHelper';
import SpiceModelHelper from './SpiceModelHelper';
import TouchstoneHelper from '../../helper/touchstoneHelper';
import SpFolderModelHelper from '../../LibraryHelper/SpiceModelHelper/SpFolderModelHelper';

/**
 * Upload sierra user library
 * 2019/09/09
 * @export
 * @param {String} data RLC data
 * @param {file} file  upload file
 * @param {String} id id 
 * @param {String} typeName /Inter_SPIM/VRM/Decap
 * @param {String} name  name
 * @returns XMLHttpRequest
 */
export function createLibraryList(formData, config) {
  return new Promise((resolve, reject) => {
    createSierraLibraryList(formData, config).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  })
}

/*
 * Get sierra user library list
 * 2019/11/12
 * @export
 * @returns XMLHttpRequest
 */
export function getLibraryList() {
  return new Promise((resolve, reject) => {
    getSierraLibraryList().then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve({});
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve({});
    });
  })
}

/*
 * Create stimulus from sierraUserLibrary
 * 2019/11/15
 * @export
 * @returns XMLHttpRequest
 */
export function createVectorData(data, config) {
  return new Promise((resolve, reject) => {
    createSierraStimulus(data, config).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  })
};

let ibisModelHelper = null;
/**
 * get ibis file content list.
 * 2019.11.15
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage TX/RX
 * @returns Promise
 */
export function getIbisModelList({ libraryId, usage }) {
  return new Promise((resolve, reject) => {
    if (!ibisModelHelper) {
      ibisModelHelper = new IbisModelHelper([libraryId]);
    }
    const models = ibisModelHelper.getIbisModelList(libraryId, usage);
    if (!models) {
      getLibraryParseData(libraryId).then((response) => {
        ibisModelHelper.parseModelSelector(libraryId, response.data);
        const model = ibisModelHelper.getIbisModelList(libraryId, usage);
        resolve(model);
      },
        (error) => {
          console.error(error)
          resolve({});
        }
      )
    } else {
      resolve(models || {});
    }
  });
}

export function updateIbisModelList(libraryId) {
  if (!ibisModelHelper) {
    ibisModelHelper = new IbisModelHelper([libraryId]);
  }
  getLibraryParseData(libraryId).then((response) => {
    ibisModelHelper.parseModelSelector(libraryId, response.data);
  }, (error) => {
    console.error(error)
  })
}

export function getIBISPackageModels(libraryId) {
  if (ibisModelHelper) {
    const models = ibisModelHelper.getIbisPackageList(libraryId);
    return models;
  } else {
    return null
  }
}

/**
 * get ibis file content list.
 * 2020.12.15
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage TX/RX
 * @returns Promise
 */
export function getIbisPackageList(libraryId) {
  return new Promise((resolve, reject) => {
    if (!ibisModelHelper) {
      ibisModelHelper = new IbisModelHelper([libraryId]);
    }
    const packages = ibisModelHelper.getIbisPackageList(libraryId);
    if (!packages) {
      getLibraryParseData(libraryId).then((response) => {
        ibisModelHelper.parseModelSelector(libraryId, response.data);
        const _packages = ibisModelHelper.getIbisPackageList(libraryId);
        resolve(_packages);
      },
        (error) => {
          console.error(error)
          resolve([]);
        }
      )
    } else {
      resolve(packages);
    }
  });
}

/**
 * get spice file content list.
 * 2019.11.19
 * 
 * @export
 * @param {*string} libraryId fileId
 * @param {*string}  usage TX/RX
 * @returns Promise
 */
let spiceModelHelper = null;

export function getSpiceModelList({ libraryId, usage }) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ models: [], libraryId });
      return;
    }
    if (!spiceModelHelper) {
      spiceModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = spiceModelHelper.getModelList(libraryId, usage);
    if (!models) {
      getSierraLibraryFile(libraryId).then((response) => {
        spiceModelHelper.parseModelSelector(libraryId, response.data);
        const model = spiceModelHelper.getModelList(libraryId, usage);
        resolve({ models: model, libraryId });
      }, error => {
        console.error(error)
        resolve({ models: [], libraryId });
      })
    } else {
      resolve({ models, libraryId });
    }
  });
}

export function updateSpiceModelList(libraryId) {
  if (!spiceModelHelper) {
    spiceModelHelper = new SpiceModelHelper([libraryId]);
  }
  getSierraLibraryFile(libraryId).then((response) => {
    spiceModelHelper.parseModelSelector(libraryId, response.data);
  }, error => {
    console.error(error)
  })
}

export function getSpiceParseModels({ libraryId, usage }) {
  if (spiceModelHelper) {
    const models = spiceModelHelper.getModelList(libraryId, usage);
    return models;
  } else {
    return null
  }
}

/**
 * get pkg spice file parse.
 * 2020/11/13
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */

export function getPkgSpiceModelList(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, models: [] });
      return;
    }
    if (!spiceModelHelper) {
      spiceModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = spiceModelHelper.getModelList(libraryId);
    if (!models) {
      getLibraryParseData(libraryId).then((response) => {
        spiceModelHelper.parseModelSelector(libraryId, response.data);
        const _models = spiceModelHelper.getModelList(libraryId);
        resolve({ libraryId, models: _models });
      }, error => {
        console.error(error);
        resolve({ libraryId, models: [] });
      })
    } else {
      resolve({ libraryId, models });
    }
  });
}

export function updatePkgSpiceModelList(libraryId) {
  if (!spiceModelHelper) {
    spiceModelHelper = new SpiceModelHelper([libraryId]);
  }
  getLibraryParseData(libraryId).then((response) => {
    spiceModelHelper.parseModelSelector(libraryId, response.data);
  }, error => {
    console.error(error);
  })
}

let touchstoneHelper = null;
/**
 * get touchstone file parse.
 * 2020.11.05
 * 
 * @export
 * @param {*string} libraryId fileId
 * @returns Promise
 */
export function getTouchstoneParse(libraryId, fileName) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ libraryId, ports: [] });
      return;
    }
    if (!touchstoneHelper) {
      touchstoneHelper = new TouchstoneHelper([libraryId]);
    }
    const ports = touchstoneHelper.getTouchstonePorts(libraryId);
    if (!ports) {
      getLibraryParseData(libraryId).then((response) => {
        touchstoneHelper.parseTouchstonePorts(libraryId, response.data, fileName);
        const _ports = touchstoneHelper.getTouchstonePorts(libraryId);
        resolve({ libraryId, ports: _ports });
      },
        (error) => {
          console.error(error)
          resolve({ libraryId, ports: [] });
        })
    } else {
      resolve({ libraryId, ports });
    }
  });
}

export function updateTouchstoneParse(libraryId, fileName) {
  if (!touchstoneHelper) {
    touchstoneHelper = new TouchstoneHelper([libraryId]);
  }
  getLibraryParseData(libraryId).then((response) => {
    touchstoneHelper.parseTouchstonePorts(libraryId, response.data, fileName);
  }, (error) => {
    console.error(error)
  })
}

export function getTouchstoneFile(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve(null);
      return;
    }
    getLibraryParseData(libraryId).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      }
    }, (error) => {
      console.error(error)
      resolve(null);
    })
  });
}

export function getTouchstoneParsePorts(libraryId) {
  if (touchstoneHelper) {
    const ports = touchstoneHelper.getTouchstonePorts(libraryId);
    return ports;
  } else {
    return null;
  }
}

/**
 * delete sierra user library list
 * 2019/11/16
 * @export
 *  @param sierraUserLibraryIds:array [] String   delete library id list
 * @returns XMLHttpRequest
 */
export function delLibraryList(sierraUserLibraryIds, fileType) {
  return new Promise((resolve, reject) => {
    delSierraLibraryList(sierraUserLibraryIds).then(res => {
      if (res.data.msg === 'success') {
        resolve(res.data.msg);
        const id = sierraUserLibraryIds[0];
        clearLibraryCache(id, fileType)
      } else {
        resolve('');
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve('');
    })
  })
}

function clearLibraryCache(id, fileType = "") {
  if (fileType === 'IBIS' && ibisModelHelper) {
    ibisModelHelper.deleteModel(id)
  } else if (fileType && fileType.match(/SPICE/i) && (spiceModelHelper || spFolderModelHelper)) {
    spiceModelHelper && spiceModelHelper.deleteModel(id);
    spFolderModelHelper && spFolderModelHelper.deleteModel(id);
  } else if (fileType === 'repeater' && (repeaterModelHelper || spFolderModelHelper)) {
    repeaterModelHelper && repeaterModelHelper.deleteModel(id);
    spFolderModelHelper && spFolderModelHelper.deleteModel(id);
  } else if (fileType === 'connector' && connectorModelHelper) {
    connectorModelHelper.deleteModel(id)
  } else if (fileType && fileType.match(/touchstone/i) && touchstoneHelper) {
    touchstoneHelper.deleteModel(id)
  }
}

/**
 * Get sierra stimulus data by ID
 * 2019/11/16
 * @export
 *  @param libraryId   id
 * @returns XMLHttpRequest
 */
export function getStimulusData(libraryId) {
  return new Promise((resolve, reject) => {
    getSierraStimulusData(libraryId).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve({});
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve({});
    })
  })
}

/*
 * Create stimulus from sierraUserLibrary
 * 2019/11/15
 * @export
 * @returns XMLHttpRequest
 */
export function updateVectorData(libraryId, data, config) {
  return new Promise((resolve, reject) => {
    updateSierraStimulus(libraryId, data, config).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve({});
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve({});
    });
  })
};

/**
 * get connector and channel model list.
 * 2019.08.13
 * 
 * @export
 * @param {string} fileType connector/channel
 * @param {string} fileName file name
 * @param {string} libraryId fild id
 * @returns Promise
 */

let channelModelHelper = null;

export function getLibraryChannelFile({ libraryId }) {
  return new Promise((resolve, reject) => {
    if (!channelModelHelper) {
      channelModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = channelModelHelper.getModelList(libraryId);
    if (!models) {
      getSierraLibraryFile(libraryId).then((response) => {
        channelModelHelper.parseModelSelector(libraryId, response.data);
        const model = channelModelHelper.getModelList(libraryId);
        resolve(model);
      }, error => {
        console.error(error)
        resolve([]);
      })
    } else {
      resolve(models);
    }
  })
}

export function getLibraryFileContent(libraryId, fileName) {
  return new Promise((resolve, reject) => {
    getSierraLibraryFile(libraryId, fileName).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      resolve(null)
    })
  })
}

export function editLibraryFileContent({ fileId, folderId, name, content, libraryType }) {
  return new Promise((resolve, reject) => {
    editSierraLibraryFile({ fileId, folderId, name, content, libraryType }).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      resolve(null)
    })
  })
}

let connectorModelHelper = null;

export function getLibraryConnectorFile({ libraryId, returnType = 'Array' }) {
  return new Promise((resolve, reject) => {
    if (!connectorModelHelper) {
      connectorModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = connectorModelHelper.getModelList(libraryId);
    if (!models) {
      getSierraLibraryFile(libraryId).then((response) => {
        connectorModelHelper.parseModelSelector(libraryId, response.data);
        const model = connectorModelHelper.getModelList(libraryId);
        resolve(returnType === 'Array' ? model : { libraryId, ports: model, type: 'spice' });
      }, error => {
        console.error(error)
        resolve([]);
      })
    } else {
      resolve(returnType === 'Array' ? models : { libraryId, ports: models, type: 'spice' });
    }
  })
}

export function updateLibraryConnectorFile(libraryId) {
  if (!connectorModelHelper) {
    connectorModelHelper = new SpiceModelHelper([libraryId]);
  }
  getSierraLibraryFile(libraryId).then((response) => {
    connectorModelHelper.parseModelSelector(libraryId, response.data);
  }, error => {
    console.error(error)
  })
}

let repeaterModelHelper = null;

export function getLibraryRepeaterFile({ libraryId }) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve({ models: null, libraryId });
      return;
    }
    if (!repeaterModelHelper) {
      repeaterModelHelper = new SpiceModelHelper([libraryId]);
    }
    const models = repeaterModelHelper.getModelList(libraryId);
    if (!models) {
      getSierraLibraryFile(libraryId).then((response) => {
        repeaterModelHelper.parseModelSelector(libraryId, response.data);
        const model = repeaterModelHelper.getModelList(libraryId);
        resolve({ models: model, libraryId });
      }, error => {
        console.error(error)
        resolve({ models: null, libraryId });
      })
    } else {
      resolve({ models, libraryId });
    }
  })
}

export function updateLibraryRepeaterFile(libraryId) {
  if (!repeaterModelHelper) {
    repeaterModelHelper = new SpiceModelHelper([libraryId]);
  }
  getSierraLibraryFile(libraryId).then((response) => {
    repeaterModelHelper.parseModelSelector(libraryId, response.data);
  }, error => {
    console.error(error)
  })
}

/** 
 * Get a list of SPICE/Repeater in the library
 * @param {string} libraryId sp folder libraryId
 * @param {string} libraryType spice/repeater
 * @param {string} productType sierra
*/

export function getSPFileList({ libraryId, libraryType, productType }) {
  return new Promise((resolve, reject) => {
    if (!productType || !libraryId || !libraryType) {
      resolve([]);
      return;
    }
    getSPFolderFileList({ libraryId, libraryType, productType }).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve([]);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve([]);
    })
  })
}

let spFolderModelHelper = null;
export function getFolderFileDetail({ fileNames, libraryId, productType }) {
  productType = "sierra";
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve(null);
      return;
    }
    if (!spFolderModelHelper) {
      spFolderModelHelper = new SpFolderModelHelper(libraryId, fileNames);
    }
    const files = spFolderModelHelper.getModelList(libraryId, fileNames);
    if (!files || files.length === 0) {
      getSPFolderFileDetail({ fileNames, libraryId, productType }).then(res => {
        if (res && res.data && res.data.code === SUCCESS) {
          spFolderModelHelper.parseModelSelector(libraryId, res.data.data);
          const fileList = spFolderModelHelper.getModelList(libraryId, fileNames);
          resolve({ files: fileList, libraryId });
        } else {
          resolve({ files: null, libraryId });
        }
      }, error => {
        console.error(error);
        resolve({ files: null, libraryId });
      })
    } else {
      resolve({ files, libraryId });
    }
  })
}

export function updateFolderFileDetail(libraryId) {
  if (spFolderModelHelper) {
    spFolderModelHelper.deleteModel(libraryId);
  }
}

/**
 * get rocky library Macro Modeling status
 * 2020/12/22
 * @export
 *  @param libraryId
 * @returns Promise
 */
export function getLibraryMacroModelingStatus(libraryId) {
  return new Promise((resolve, reject) => {
    if (!libraryId) {
      resolve(null);
      return;
    }
    getMacroModelingStatusByLibraryId(libraryId).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    })
  })
}

export function updateLibraryFiles(formData, config) {
  return apiProcess(updateLibraryFile, { product: 'sierra', formData, config })
}

/**
 * get sierra library part IBIS/Repeater
 * 
 * @param {string} page
 * @param {string} pageSize
 * @param {string} type IBIS/REPEATER
 * @export
 * @returns Promise
 */
function getPartLibraryList({ page, pageSize, type }) {
  return apiProcess(getSierraPartLibraryList, { page, pageSize, type });
}

/**
 * upload sierra library part IBIS/Repeater excel
 * 
 * @param {file} file
 * @param {string} type IBIS/REPEATER
 * @param {string} uploadType Add/Replace
 * @export
 * @returns Promise
 */
function uploadPartLibraryFile({ type, file, uploadType = "Add" }) {
  return apiProcess(uploadSierraPartLibrary, { file, uploadType, type });
}

/**
 * search sierra library part IBIS/Repeater list
 * 
 * @param {string} type IBIS/REPEATER
 * @param {string} searchKey search str
 * @export
 * @returns Promise
 */
function getPartLibraryListBySearch({ partNumbers, type }) {
  return apiProcess(getSierraPartLibraryListBySearch, { partNumbers, type });
}

/**
 * get sierra library part IBIS/Repeater check files
 * 
 * @param {string} type part_IBIS/part_repeater
 * @export
 * @returns Promise
 */
function getPartLibraryCheckFiles(type) {
  return apiProcess(getSierraPartLibraryCheckFiles, type);
}

/**
 * edit part library
 * @param {string} type part_IBIS/part_repeater
 * @param {object} editedPart {id, file, component, ....}
 * @export
 * @returns Promise
 *  */
function editPartLibrary({ type, editedParts }) {
  return apiProcess(editSierraPartLibrary, { type, editedParts });
}

/**
 * auto update repeater component setting and interface setup by part  repeater library
 * @param {array} addRepeaterParts new added repeater library
 * @export
 * @returns Promise
 *  */
function autoUpdateRepeaterSetting(addRepeaterParts) {
  return apiProcess(autoUpdatePartRepeaterSetting, addRepeaterParts);
}

/**
 * get  status (auto update repeater component setting and interface setup by part  repeater library)
 * @export
 * @returns Promise
 *  */
function getRepeaterUpdateStatus() {
  return apiProcess(getPartRepeaterUpdateStatus);
}

/**
 * set default library
 * @param {object} param {libraryId, libraryType, name }
 * @export
 * @returns Promise
 *  */
function setDefaultLibrary(param) {
  return apiProcess(setSierraDefaultLibrary, param);
}

/**
 * get default library
 * @export
 * @returns Promise
 *  */
function getDefaultLibrary() {
  return apiProcess(getSierraDefaultLibrary);
}

/**
 * get sierra library nets filter 
 * 
 * @param {string} page
 * @param {string} pageSize
 * @export
 * @returns Promise
 */
function getNetsFilterLibraryList({ page, pageSize }) {
  return apiProcess(getSierraNetsFilterLibraryList, { page, pageSize });
}

/**
 * upload sierra library nets filter 
 * 
 * @param {file} file excel
 * @param {string} uploadType Add/Replace
 * @export
 * @returns Promise
 */
function uploadNetsFilterLibraryFile({ type, file, uploadType = "Add" }) {
  return apiProcess(uploadSierraNetsFilterLibrary, { file, uploadType, type });
}

/**
 * edit nets filter library
 * @param {object} editedFilter {id, filter, ....}
 * @export
 * @returns Promise
 *  */
function editNetsFilterLibrary({ editedFilter }) {
  return apiProcess(editSierraNetsFilterLibrary, { editedFilter });
}

/**
 * search and apply powers nets by keywords
 * @param {array} netLibraryList  [{id, filter, ....}]
 * @param {designId} designId
 * @param {bool} applyAll
 * @export
 * @returns Promise
 *  */
function searchPowerNetsByKeywords({ designId, netLibraryList, applyAll }) {
  return apiProcess(searchSierraPowerNetsByKeywords, { designId, netLibraryList, applyAll });
}

/**
 * get status (auto search power nets by net keywords)
 * @export
 * @returns Promise
 *  */
function getPowerNetsSearchStatus() {
  return apiProcess(geSierraPowerNetsSearchStatus);
}

/**
 * get trace template info
 * @param {string} libraryId
 * @export
 * @returns Promise
 *  */
function getTraceTemplateLibrary(libraryId) {
  return apiProcess(getSierraTraceTemplateLibrary, libraryId);
}

/**
 * update/create trace template info
 * @param {obj} trace
 * @export
 * @returns Promise
 *  */
function updateTraceTemplateLibrary(traceData) {
  return apiProcess(updateSierraTraceTemplateLibrary, traceData);
}

/**
 * calculate trace template Z0 ZDIFF
 * @param {obj} config
 * @export
 * @returns Promise
 *  */
function calculateTraceImpedance(config) {
  return apiProcess(calculateSierraTraceImpedance, config, false, true, false, true);
}

/**
 * get trace template Z0 ZDIFF
 * @param {string} workflowId
 * @export
 * @returns Promise
 *  */
function getTraceImpedanceData(workflowId) {
  return apiProcess(getSierraTraceImpedanceData, workflowId, false, true);
}

export {
  getSierraLibraryFolderInfo,
  editSierraLibraryFolder,
  deleteSierraLibraryFolder,
} from './folderCtrl';

export {
  getPartLibraryList,
  uploadPartLibraryFile,
  getPartLibraryListBySearch,
  getPartLibraryCheckFiles,
  editPartLibrary,
  getRepeaterUpdateStatus,
  autoUpdateRepeaterSetting,
  setDefaultLibrary,
  getDefaultLibrary,
  getNetsFilterLibraryList,
  uploadNetsFilterLibraryFile,
  editNetsFilterLibrary,
  searchPowerNetsByKeywords,
  getPowerNetsSearchStatus,
  clearLibraryCache,
  getTraceTemplateLibrary,
  updateTraceTemplateLibrary,
  calculateTraceImpedance,
  getTraceImpedanceData
}

export {
  getRepeaterModelNodes,
  setDefaultRepeaterModelNodes,
  getFilesParseList,
  autoAssignRepeaterPorts
} from './libraryHelper';