// _signalsList - 
// [{
//   name: "DQ0",
//   netList: [{
//     displayName: "DQ0",
//     fileId: "pcb_566602315499442176_566602387440144384.s22p",
//     net: "DDR3_D0",
//     notPorts: [],
//     ports: [1, 12],
//     portsInfo: [{ comp: "U8", pin: "C3", port: 1 }, { comp: "U10", pin: "E3", port: 12 }],
//     show: true,
//     signal: "DQ0",
//   }],
//   nets: ["DDR3_D0"],
//   show: true,
//   signal: "DQ0"

import _ from "lodash";

// }]
function getGroupSignals(Components, portList, signals, splitComponentName, isMultiList) {
  let netList = [], netNameList = [];

  function getNetConnectorNetList(netList, connectorList, netNameList, Components, _netList) {
    let connectorNets = []
    for (let pinInfo of connectorList) {
      if (['Res', 'Ind', 'Cap'].includes(pinInfo.type)) {
        const findInfo = Components.find(item => item.name === pinInfo.comp)
        const filterNetNameList = findInfo.pins.filter(item => !netList.includes(item.net)).map(item => item.net)
        for (let netName of filterNetNameList) {
          const findNetInfo = _netList.find(item => item.net === netName);
          if (findNetInfo) {
            connectorNets.push(netName);
            const connectorList = [...findNetInfo.portsInfo, ...findNetInfo.notPorts];
            const newNetList = [...netList, ...filterNetNameList]
            const findNetList = getNetConnectorNetList(newNetList, connectorList, netNameList, Components, _netList);
            connectorNets = [...connectorNets, ...findNetList]
          }
        }
      }
    }
    return connectorNets
  }

  for (let compItem of Components) {
    for (let pinItem of compItem.pins) {
      if (!pinItem.signal) {
        continue;
      };
      // U2 A, U2 B -> U2
      const compName = splitComponentName ? splitComponentName(compItem.name) : compItem.name, pinNum = pinItem.pin;
      const isPort = portList.find(find => find.comp === compName && find.pin === pinNum);
      const netIndex = isMultiList ? netList.findIndex(find => find.net === pinItem.net && (!isPort || (isPort && find.fileId === isPort.fileId))) : netNameList.indexOf(pinItem.net);
      if (!isPort) {
        if (compItem.type === 'Res' || compItem.type === 'Ind' || compItem.type === 'Cap') {
          if (netIndex > -1) {
            netList[netIndex].notPorts.push({ comp: compName, pin: pinNum, type: compItem.type });
          } else {
            netList.push({
              net: pinItem.net,
              ports: [],
              portsInfo: [],
              signal: pinItem.signal,
              show: true,
              notPorts: [{ comp: compName, pin: pinNum, type: compItem.type }]
            })
            netNameList.push(pinItem.net)
          }
        }
      } else {
        if (netIndex > -1) {
          netList[netIndex].ports.push(isPort.index);
          netList[netIndex].portsInfo.push({ comp: compName, pin: pinNum, port: isPort.index, type: compItem.type });
        } else {
          netList.push({
            net: pinItem.net,
            ports: [isPort.index],
            portsInfo: [{ comp: compName, pin: pinNum, port: isPort.index, type: compItem.type }],
            signal: pinItem.signal,
            show: true,
            notPorts: [],
            fileId: isPort.fileId
          })
          netNameList.push(pinItem.net)
        }
      }
    }
  };

  // Merge nets, connector by RLC
  let _signalsList = JSON.parse(JSON.stringify(signals));
  _signalsList.forEach(sig => {
    sig.netList = [];
    sig.show = true;
    sig.signal = sig.name;
    const _netList = netList.filter(item => item.signal === sig.name).map(it => ({ ...it, displayName: it.signal }));
    if (_netList.length > 1) {
      let newList = [{ displayName: '', ports: [], portsInfo: [], notPorts: [], signal: sig.name }];
      for (let net of _netList) {
        newList[0].displayName = newList[0].displayName ? newList[0].displayName + '/' + net.net : net.net;
        newList[0].ports = [...newList[0].ports, ...net.ports];
        const connectorList = [...net.portsInfo, ...net.notPorts];
        let nets = getNetConnectorNetList([net.net], connectorList, netNameList, Components, _netList);
        nets = [...new Set([...nets, net.net])]
        const _portsInfo = net.portsInfo.map(item => { return { ...item, nets: nets } })
        newList[0].portsInfo = [...newList[0].portsInfo, ..._portsInfo];
        netList[0].notPorts = [...newList[0].notPorts, ...net.notPorts];
        netList[0].signal = sig.name;
        netList[0].show = true;
      };
      sig.netList = newList;
    } else {
      sig.netList = _netList;
    };
  });
  _signalsList = _signalsList.filter(item => item.netList.length > 0 && item.netList[0].ports.length > 0);
  return _signalsList;
};

// 0 - Not Equal, 1 - not Equal
// FEXT - Far end crosstalk
// NEXT - Near end crosstalk
// IL - Insertion loss
// RL - Return loss
// portComp portSignal portPin
//     0        0        0    FEXT
//     0        0        1    FEXT
//     0        1        0    IL
//     0        1        1    IL
//     1        0        0    NEXT
//     1        0        1    RL
//     1        1        0    NEXT
//     1        1        1    RL
function getValue(key) {
  const FEXT = 'FEXT', NEXT = 'NEXT', IL = 'IL', RL = 'RL';
  const obj = {
    '000': FEXT,
    '001': FEXT,
    '010': IL,
    '011': IL,
    '100': NEXT,
    '101': RL,
    '110': NEXT,
    '111': RL
  };
  return obj[key];
};

function getResultList({ Components, VirtualComps, Signals, portList, getColorByIndex, infoList, fileId, portSelects }) {
  let IL = [], RL = [], FEXT = [], NEXT = [];  // [{ rowName, children: [{ id, name, color }] }]
  if (!portList.length) {
    return { IL, RL };
  }
  // Filter RLC and other comps
  const allPortComps = [...new Set(portList.map(item => item.comp))];
  /* const chipsComps = Components.filter(comp => ['IC', 'Repeater', 'Connector', 'Test Point'].includes(comp.type));
  // U2 A, U2 B -> U2
  const chips = chipsComps.map(it => it.name); */


  let _portSelect = { ...portSelects };
  const _groupSignals = getGroupSignals([...Components, ...(VirtualComps || [])], [...portList], Signals);
  const netConnectInfo = findNetConnectComp([...Components, ...(VirtualComps || [])], Signals, allPortComps);

  for (let sigInfo of _groupSignals) {
    const portSignal = sigInfo.signal;

    let info = {};
    infoList.forEach(item => {
      info[item] = { rowName: portSignal, children: [] }
    })

    for (let port of sigInfo.netList[0].portsInfo) {
      const portComp = port.comp,
        portIndexInfile = port.port,
        portPin = port.pin,
        portNet = sigInfo.netList[0].net ? [sigInfo.netList[0].net] : port.nets;
      const findPort = portList.find(p => p.name === `${portComp}_${portPin}_${portSignal}`);
      const portFileID = fileId;
      const portIndex = findPort ? findPort.index : -1;
      if (portIndex < 0) {
        continue;
      }
      for (let portItem of portList) {
        const _portComp = portItem.comp,
          _portIndex = portItem.index,
          _portPin = portItem.pin,
          _portSignal = portItem.signal,
          _portNet = portItem.net,
          _portIndexInfile = portItem.index;
        const item = {
          id: `${portFileID}::${portIndexInfile - 1}::${_portIndexInfile - 1}`,
          name: `S(${portIndex}, ${_portIndex}) (${portComp}_${portPin}, ${_portComp}_${_portPin})`,
          color: getColorByIndex(portFileID, portIndexInfile - 1, _portIndexInfile - 1)
        };

        const portCompEq = portComp === _portComp ? '1' : '0',
          portSignalEq = portSignal === _portSignal ? '1' : '0',
          portNetEq = portNet.includes(_portNet) ? '1' : '0',//Find IL based on net
          portPinEq = portPin === _portPin ? '1' : '0';

        const key = portCompEq + portNetEq + portPinEq;
        let infoKey = getValue(key);
        if (infoKey === "FEXT" && portSignal === _portSignal) {
          //different signals have same net
          infoKey = getValue(portCompEq + portSignalEq + portPinEq);
        }

        if (!_portSelect[infoKey]) {
          _portSelect[infoKey] = {}
        }
        if (!_portSelect[infoKey][portSignal]) {
          _portSelect[infoKey][portSignal] = []
        }
        if (['IL', 'FEXT', 'NEXT'].includes(infoKey)) {
          // Delete duplicate
          const _currentNetConnectInfo = netConnectInfo.find(item => item.name === _portSignal);
          const currentNetConnectInfo = netConnectInfo.find(item => item.name === portSignal);
          let sameSignalSegment = false;
          //Confirm that two identical comps are connected
          //The comp corresponding to the near-end crosstalk pin is the same, and at least one comp connected through net is the same
          //The comp corresponding to the remote crosstalk pin is different, but the comp connected to the net must include the comp of another pin;
          const _pinCompList = _currentNetConnectInfo && _currentNetConnectInfo.netInfo ? _currentNetConnectInfo.netInfo[_portNet] || [] : [];
          const keys = Object.keys(currentNetConnectInfo.netInfo)
          let pinCompList = [];
          portNet.forEach(item => {
            if (keys.includes(item) && currentNetConnectInfo && currentNetConnectInfo.netInfo) {
              pinCompList = [...new Set([...pinCompList, ...(currentNetConnectInfo.netInfo[item] || [])])]
            }
          })
          sameSignalSegment = getSameSignalSegment({
            infoKey, pinCompList, _pinCompList, portComp, _portComp,
            currentNetConnectInfo, _currentNetConnectInfo, portSignal, _portSignal
          })

          const findExist = info[infoKey].children.find(d => d.id === `${portFileID}::${_portIndexInfile - 1}::${portIndexInfile - 1}`);

          if (allPortComps.includes(portComp) && allPortComps.includes(_portComp) && !findExist && sameSignalSegment) {
            // For the same signal segment
            item.name = `S(${portIndex}, ${_portIndex}) (${portComp}_${portPin}, ${_portComp}_${_portPin}_${_portSignal})`
            _portSelect[infoKey][portSignal].push(item.id)
            info[infoKey] && info[infoKey].children.push(item);
          }
        } else {
          //TODO
          _portSelect[infoKey][portSignal].push(item.id)
          info[infoKey] && info[infoKey].children.push(item);
        }

        _portSelect[infoKey][portSignal] = [...new Set(_portSelect[infoKey][portSignal])]
      }
    };

    IL.push(info.IL);
    RL.push(info.RL);
    NEXT.push(info.NEXT);
    FEXT.push(info.FEXT)
  };
  return { IL, RL, portSelect: _portSelect, NEXT, FEXT }
}

function getSameSignalSegment({ infoKey, pinCompList, _pinCompList, portComp, _portComp,
  currentNetConnectInfo, _currentNetConnectInfo, portSignal, _portSignal }) {
  let sameSignalSegment = false;
  switch (infoKey) {
    case "IL":
      sameSignalSegment = portComp !== _portComp && pinCompList.includes(_portComp) && _pinCompList.includes(portComp);
      break;
    /* case "NEXT":
      sameSignalSegment = portComp === _portComp;
      break; */
    case "NEXT":
    case "FEXT":
      if ((portComp === _portComp && infoKey === "FEXT")
        || (portComp !== _portComp && infoKey === "NEXT")
        || portSignal === _portSignal
      ) {
        break;
      }

      //same components [U111,U108] - [U111,U108] || [U111,U108,R757] - [U111,U108,R756]
      if ((pinCompList.includes(_portComp) && _pinCompList.includes(portComp) && infoKey === "FEXT")  //[U111,U108] - [U111,U108]
        || pinCompList.filter(item => _pinCompList.includes(item)).length > 1 //[U111,U108,R757] - [U111,U108,R756]
      ) {
        sameSignalSegment = true;
        break;
      }

      //[ [U111,U108,R757], [U108,U77,U80,R686] ], [ [U68,U2], [U2,U3], [U5,U6] ] remove rlc
      const groups = currentNetConnectInfo && currentNetConnectInfo.netInfo ? Object.keys(currentNetConnectInfo.netInfo).map(key => currentNetConnectInfo.netInfo[key]) : [];
      //[ [U111,U108,R756], [U108,U77,U80,R685] ], [ [U68,U2], [U2,U4],  [U7,U8] ]
      const _groups = _currentNetConnectInfo && _currentNetConnectInfo.netInfo ? Object.keys(_currentNetConnectInfo.netInfo).map(key => _currentNetConnectInfo.netInfo[key]) : [];

      const currFilterSegmentList = [], otherFilterSegmentList = [];

      for (let list of groups) {
        const sameSegmentList = _groups.filter(_listItem => _listItem.filter(item => list.includes(item)).length > 1);
        if (sameSegmentList.length) {
          currFilterSegmentList.push(list);
          otherFilterSegmentList.push(...sameSegmentList);
        }
      }

      if (!currFilterSegmentList.find(item => _.isEqual(item, pinCompList))
        && !otherFilterSegmentList.find(item => _.isEqual(item, _pinCompList))) {
        sameSignalSegment = true;
      }
      break;
    default:
      break;
  }

  /*   if (portComp === _portComp) {
      sameSignalSegment = pinCompList.find(item => _pinCompList.includes(item) && item !== _portComp) ? true : false;
    } else {
      sameSignalSegment = pinCompList.includes(_portComp) && _pinCompList.includes(portComp) ? true : false;
    } */

  return sameSignalSegment
}

function getConnectInfo(comps, net, name, selectNets, portComps) {
  let _netsList = [net], _compList = []
  for (let comp of comps) {
    const findPin = comp.pins.find(item => item.net === net && item.signal === name);
    if (findPin) {
      if (['Res', 'Ind', 'Cap', 'Jumper'].includes(comp.type) && !portComps.includes(comp.name)) {
        const findPins = comp.pins.filter(item => item.signal === name && item.net && item.net !== net && !selectNets.includes(item.net));
        const netList = findPins ? [...new Set(findPins.map(item => item.net))] : []
        // Find all connected nets;
        for (let newNet of netList) {
          const { netsList, compList } = getConnectInfo(comps, newNet, name, [...selectNets, ...netList], portComps)
          netsList && _netsList.push(...netsList)
          compList && _compList.push(...compList)
        }
      } else if (['IC', 'Repeater', 'Connector', 'Test Point'].includes(comp.type) || portComps.includes(comp.name)) {
        _compList.push(comp.name)
      }
    }
  }
  return { netList: [...new Set(_netsList)], compList: [...new Set(_compList)] }
}

function findNetConnectComp(comps, signals, portComps) {
  let netConnectInfo = []
  for (let info of signals) {
    let netInfo = {};
    const { name, nets } = info;
    for (let net of nets) {
      if (!netInfo[net]) {
        netInfo[net] = []
      }
      const info = getConnectInfo(comps, net, name, [net], portComps)
      if (info && info.compList && info.compList.length) {
        netInfo[net].push(...info.compList)
      }
    }
    netConnectInfo.push({ name, netInfo })
  }
  return netConnectInfo
}

export {
  getGroupSignals,
  getResultList,
  getValue
}