import { runBMAChannelInfo, getVerificationLog } from '../../api/BMA/simulation';
import apiProcess from '../../api/utility';
import { SUCCESS, API_RES_MESSAGE, CHECK_FAIL } from '@/constants/returnCode';

function runBMAChannelInfoPromise({ verificationId, selectInputFileIds, simulationTypes }) {
  return new Promise((resolve, reject) => {
    return runBMAChannelInfo({ verificationId, selectInputFileIds, simulationTypes }).then(res => {
      if (!res || !res.data) {
        reject("[ERROR] Simulation failed!");
        return;
      }
      if (res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else if (res.data.code === API_RES_MESSAGE) {
        reject({ msg: res.data.msg || "", code: API_RES_MESSAGE })
      } else {
        reject(res.data.msg || "[ERROR] Simulation failed!")
      }
    }, error => {
      if (error && error.response && error.response.data && error.response.data.code === CHECK_FAIL && error.response.data.msg) {
        reject(`[ERROR] ${error.response.data.msg}` || "[ERROR] Simulation failed!")
      } else {
        reject(`[ERROR] ${error}` || "[ERROR] Simulation failed!")
      }
    })
  })
}

function geSimulationLogPromise(verificationId) {
  return new Promise((resolve, reject) => {
    getVerificationLog(verificationId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
}

export {
  runBMAChannelInfoPromise,
  geSimulationLogPromise
}