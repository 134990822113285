import { connect } from 'react-redux';
import { selectLayer, changeColorBy } from '../store/Andes/actionCreators';
import { LayerTab } from '../components';
import {
  checkboxChange, selectedAll, clearOneSelection,
  cancelSelectedAll, showSelected, hideSelected, changeDisplaySelected
} from '../store/Andes/actionCreators';
import '../index.css';

const mapState = (state) => {
  const { AndesReducer: { explorer: { selected, layers, displayBoxShow } } } = state;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  return {
    checkedlayers: layers,
    selected,
    defaultLeft,
    defaultTop,
    displayBoxShow
  }
};

const mapDispatch = (dispatch) => ({
  selectLayer(checkedList) {
    dispatch(selectLayer(checkedList));
  },
  changeColorBy(value, designID) {
    dispatch(changeColorBy(value, designID))
  },
  checkboxChanged: (canvasObj) => {
    dispatch(checkboxChange(canvasObj))
  },
  clearOneSelection: (arr) => {
    dispatch(clearOneSelection(arr))
  },
  selectedAll: () => {
    dispatch(selectedAll())
  },
  cancelSelectedAll: () => {
    dispatch(cancelSelectedAll())
  },
  showSelected: () => {
    dispatch(showSelected())
  },
  hideSelected: () => {
    dispatch(hideSelected())
  },
  changeDisplaySelected(show) {
    dispatch(changeDisplaySelected(show))
  }
})

export default connect(mapState, mapDispatch)(LayerTab);