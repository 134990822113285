import { getComponentsByNets, _getNetsListByComp } from '../../helper/componentsHelper';
import { getLayoutComponents } from "@/services/PCBHelper";

const RLTypes = ["Res", "Ind"];

//find extended net by power net
//power net --> components --> res components --> nets --> components --> extended net (connect controller)
function getExtendedNet({ PowerNets, pcbId, getLayoutComponents, layers, controllerComponent }) {
  if (!controllerComponent) {
    return [];
  }
  //find connected components by powerNets
  const components = getComponentsByNets({ netList: PowerNets, pcbId, getLayoutComponents, layers });
  //Does power nets cover the controller component
  //Yes ==> return;
  //No ===> find cover controller component powerNets
  const compNames = components.map(item => item.name);
  if (compNames.includes(controllerComponent)) {
    return [];
  }

  //filter comps by type === "Res" || type === "Ind"
  const rlComponents = components.filter(item => RLTypes.includes(item.type));
  let targetNets = [];
  for (let comp of rlComponents) {
    const nets = _getNetsListByComp(comp.name, [...PowerNets], pcbId);
    const new_components = getComponentsByNets({ netList: nets, pcbId, getLayoutComponents, layers });
    const new_compNames = new_components.map(item => item.name);
    if (new_compNames.includes(controllerComponent)) {
      targetNets = [...targetNets, ...nets];
    }
  }
  return targetNets;
}

function findVRMComponents({ PowerComponents, layers }) {
  let findVRMComps = [], findCaps = [];
  PowerComponents.forEach(comp => {
    if (comp.COMP_TYPE === 'Res' || comp.COMP_TYPE === 'Ind') {
      let CompsInfo = getLayoutComponents({ layers });
      const compInfo = CompsInfo[comp.name];
      let { location } = compInfo;
      findVRMComps.push({ ...comp, usage: comp.COMP_TYPE, location });
    }

    if (comp.COMP_TYPE === 'Cap' && comp.usage !== 'Removed') {
      let CompsInfo = getLayoutComponents({ layers });
      const compInfo = CompsInfo[comp.name];
      let { location } = compInfo;
      findCaps.push({ ...comp, usage: comp.COMP_TYPE, location });
    }
  });
  return { findVRMComps, findCaps };
}

export { getExtendedNet, findVRMComponents };