import UploadMonitor from './UploadMonitor';
import SimulationMonitor from './SimulationMonitor';
import ProfileTable from './profileTable';
import { monitorChild } from '@/components/MonitorFooter';
import SpecifyService from '../../../CCC/SpecifyService';

function _monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, titleSelectedKeys, width = undefined, pageType }) {
  return monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, titleSelectedKeys, width, pageType },
    UploadMonitor,
    SimulationMonitor,
    null,//DetailMonitor
    ProfileTable,
    SpecifyService
  )
}

export default _monitorChild;