import CeLayerLogic from './CeLayerLogic';

var CeLayerSilkScreen = function () {
  CeLayerLogic.call(this, "SILK_SCREEN");
};

// inherits from CeLayerBase
CeLayerSilkScreen.prototype = Object.create(CeLayerLogic.prototype);
CeLayerSilkScreen.prototype.constructor = CeLayerSilkScreen;

export default CeLayerSilkScreen;