import {
  ADD_EXPERIMENT,
  ADD_EXPERIMENT_DATE,
  CREATE_EXPERIMENT,
  OPEN_EXPERIMENT,
  UPDATE_EXPERIMENT_DATA,
  UPDATE_SWEEP_LOADING,
  UPDATE_TRACE_CONFIGS,
  UPDATE_IS_UPDATE_EXPERIMENT_DATA,
  HANDLE_SIGNAL_EXPERIMENT_DATA,
  RENAME_EXPERIMENT,
  DELETE_EXPERIMENT,
  CHANGE_VARIABLES,
  CLEAR_SWEEPINFO,
  UPDATE_EXPERIMENT_IDS,
  UPDATE_TRACE_SETTING,
  HANDLE_TRACE_SETTING,
  UPDATE_NOT_EXIST_NETS,
  UPDATE_SWEEP_INFO
} from './actionTypes';


export const addExperiment = (itemData) => ({
  type: ADD_EXPERIMENT,
  itemData
});

export const createExperiment = (data, channelInfo) => ({
  type: CREATE_EXPERIMENT,
  data,
  channelInfo
});

export const openExperiment = (id, firstCreate) => ({
  type: OPEN_EXPERIMENT,
  id,
  firstCreate
})

export const updateTraceConfigs = (traceConfigs) => ({
  type: UPDATE_TRACE_CONFIGS,
  traceConfigs
})

export const updateTraceSetting = (traceSetting) => ({
  type: UPDATE_TRACE_SETTING,
  traceSetting
})

export const handleTraceSetting = (traceSetting) => ({
  type: HANDLE_TRACE_SETTING,
  traceSetting
})

export const updateSweepLoading = (sweepLoading) => ({
  type: UPDATE_SWEEP_LOADING,
  sweepLoading
})

export const updateExperimentData = (experimentData) => ({
  type: UPDATE_EXPERIMENT_DATA,
  experimentData
})

export const addExperimentData = () => ({
  type: ADD_EXPERIMENT_DATE
})

export const updateIsUpdateExperimentData = (isUpdateExperimentData) => ({
  type: UPDATE_IS_UPDATE_EXPERIMENT_DATA,
  isUpdateExperimentData
})

export const handleSignalExperimentData = (params, handleType) => ({
  type: HANDLE_SIGNAL_EXPERIMENT_DATA,
  params,
  handleType
})

export const renameExperiment = (data) => ({
  type: RENAME_EXPERIMENT,
  data
})

export const deleteExperiment = (data) => ({
  type: DELETE_EXPERIMENT,
  data
});

export const changeVariables = (config, checked) => ({
  type: CHANGE_VARIABLES,
  config,
  checked
});

export const clearSweepInfo = () => ({
  type: CLEAR_SWEEPINFO
});

export const updateExperimentIds = (experimentIds) => ({
  type: UPDATE_EXPERIMENT_IDS,
  experimentIds
});

export const updateNotExistNets = (notExistNets) => ({
  type: UPDATE_NOT_EXIST_NETS,
  notExistNets
});

export const updateSweepInfo = (sweepInfo) => ({
  type: UPDATE_SWEEP_INFO,
  sweepInfo
});