import CeLayerBase from './CeLayerBase';
import FutprtPartPad from './FutprtPartPad';
import CeRefGeometry from '../geometry/CeRefGeometry';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeLayerLogic = function (type) {
  CeLayerBase.call(this, type);

  this.mGeomList = [];
  this.mPadList = [];
};

// inherits from CeLayerBase
CeLayerLogic.prototype = Object.create(CeLayerBase.prototype);
CeLayerLogic.prototype.constructor = CeLayerLogic;

CeLayerLogic.prototype.IsPadExistied = function (padId) {
  for (var i = 0; i < this.mPadList.length; i++) {
    if (padId == this.mPadList[i].GetID())
      return true;
  }
  return false;
}

CeLayerLogic.prototype.AddPad = function (padId, templatePad) {
  var pad = new FutprtPartPad();
  pad.SetPadTemplateIDs(padId, templatePad);
  this.mPadList.push(pad);
  return pad;
}

CeLayerLogic.prototype.AddGeometry = function (shapeID, ceGeom, sntLoc) {
  var refGeom = new CeRefGeometry();
  refGeom.SetData(shapeID, ceGeom, sntLoc);
  this.mGeomList.push(refGeom);
}

CeLayerLogic.prototype.GetGeometries = function () {
  return this.mGeomList;
}

CeLayerLogic.prototype.GetPadList = function () {
  return this.mPadList;
}

CeLayerLogic.prototype.WriteIntoIODataNode = function (layerBlock) {
  CeLayerBase.prototype.WriteIntoIODataNode.call(this, layerBlock);

  if (this.mGeomList != null) {
    for (var i = 0; i < this.mGeomList.length; i++) {
      var geomBlk = new CeIODataBlock("Geometry");
      this.mGeomList[i].WriteIntoIODataNode(geomBlk);
      layerBlock.AddBlockNode(geomBlk);
    }
  }

  if (this.mPadList != null) {
    for (var i = 0; i < this.mPadList.length; i++) {
      var padBlock = new CeIODataBlock("PartPad");
      this.mPadList[i].WriteIntoIODataNode(padBlock);
      layerBlock.AddBlockNode(padBlock);
    }
  }
}

CeLayerLogic.prototype.ReadFromIODataNode = function (layerBlock) {
  CeLayerBase.prototype.ReadFromIODataNode.call(this, layerBlock);

  var geomBlks = layerBlock.GetSubBlocksOfSameName("Geometry");
  for (var i = 0; i < geomBlks.length; i++) {
    var refGeom = new CeRefGeometry();
    if (refGeom.ReadFromIODataNode(geomBlks[i]) == true)
      this.mGeomList.push(refGeom);
  }

  var padBlocks = layerBlock.GetSubBlocksOfSameName("PartPad");
  for (var i = 0; i < padBlocks.length; i++) {
    var partPad = new FutprtPartPad();
    partPad.ReadFromIODataNode(padBlocks[i]);
    this.mPadList.push(partPad);
  }
}

export default CeLayerLogic;