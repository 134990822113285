import { all, fork, call, put, takeEvery, delay, select, takeLatest } from 'redux-saga/effects'
import { USER_LOGIN, USER_LOGOUT, LOGIN_FAILED, PAGE_TYPE } from './actionTypes';
import { setToken, setRefreshToken, clearToken, getUser } from '@/services/api/auth';
import { userloginPromise } from '../../services/authCtrl';
import { loginSuccessAction } from './action';
import globalVariable from '@/services/globalVariable';
import permissionData, { getPageTypeByUrl } from '@/services/helper/data/permissionData';
import { checkoutLicensePromise, licenseHeartbeatPromise, checkinLicensePromise } from '../../services/License/licenseCtrl';
import history from '../../services/history';
import { showTransparentBox } from '../../services/api/utility';
import http from '../../services/api/http';
import userData from '@/services/helper/data/userData';
import { userDefaultSettings } from '../../services/userDefaultSetting/userDefaultSettingCtrl';

function* userLogin() {
  yield takeEvery(USER_LOGIN, function* (action) {
    const response = yield call(userloginPromise, action.email, action.password);
    if (response) {
      const { token, refreshToken } = response;
      setToken(token);
      setRefreshToken(refreshToken);
      const res = yield call(getUser);
      userData.setInfo(res);
      yield put(loginSuccessAction(res.email, res.username));
    } else {
      yield put({ type: LOGIN_FAILED });
    }
  })
}

function* userLogout() {
  yield takeEvery(USER_LOGOUT, function* (action) {
    try {
      permissionData.cleanData();
      userData.clearInfo();
      userDefaultSettings.clearSettings()
      yield call(clearToken);
    } catch (error) {
      console.error(error)
    }
  })

}

function getPageType(pageType) {
  return pageType ? pageType : getPageTypeByUrl(history.location.pathname)
}

function* updatePageType() {
  yield takeEvery(PAGE_TYPE, function (action) {
    http.updatePageType(getPageType(action.pageType))
    globalVariable.updatePageType(getPageType(action.pageType));
  })
}

let prePageType = null;
function* licenseHeartBeat() {
  try {
    yield takeLatest(PAGE_TYPE, function* (action) {
      yield fork(heartBeat);
    })
  } catch (error) {
    console.error('Can\'t fork heatbeat', error)
  }
}

function* heartBeat() {
  // null - home page
  let pageType = (yield select()).LoginReducer.pageType;
  if (prePageType && prePageType !== pageType) {
    try {
      yield call(checkinLicensePromise, prePageType)
    } catch (error) {
      console.error(`Can't checkin ${prePageType} license`, error)
    }
  }
  // double checkout license: Enter product directly in the url: /rocky|/sierra|/fastpi/...
  if (pageType) {
    try {
      yield call(checkoutLicensePromise, pageType);
    } catch (error) {
      console.error(`Can't checkout ${pageType} license`, error);
      return;
    }
  }
  prePageType = pageType;
  while (pageType) {
    pageType = (yield select()).LoginReducer.pageType;
    if (pageType) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          yield call(checkinLicensePromise, pageType)
          yield call(clearToken);
        }
        const res = yield call(licenseHeartbeatPromise, pageType);
        if (!res) {
          showTransparentBox()
        }
      } catch (error) {
        console.error('Can\'t get heartbeat response', error)
      }
      yield delay(60000)
    }
  }
}
function* LoginSagas() {
  yield all([
    fork(userLogin),
    fork(userLogout),
    fork(updatePageType),
    fork(licenseHeartBeat)
  ])
}

export default LoginSagas;