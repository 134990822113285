import React, { Component, Fragment } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import Table from '@/components/EditableTable';

class SelectContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
    this.selectInterfaceColumns = [
      {
        dataIndex: "check",
        key: "check",
        width: 80
      },
      {
        title: 'Type',
        dataIndex: 'type',
        width: 90
      }, {
        title: 'Signal Name',
        dataIndex: 'name',
        width: '28%'
      }, {
        title: 'Nets',
        dataIndex: 'nets',
        /* width: '54%' */
      }
    ]

    this.selectInterfaceColumns[0].title = () => {
      const { checked, indeterminate, selectedProportion } = this.getCheckBoxStatus()
      return <Fragment>
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onChange={(e) => this.props.checkSignals(e, 'all')}
        />
        <span className="select-checkbox-title-proportion">{selectedProportion}</span>
      </Fragment>
    }

    this.selectInterfaceColumns[0].render = (check, record) => {
      const { checked } = this.getCheckBoxStatus(record.interfaceKey)
      return <div className='pcb-channel-table-checkBox'>
        <Checkbox
          checked={checked}
          onChange={(e) => this.props.checkSignals(e, record.interfaceKey)}
        />
      </div>
    }

    this.selectInterfaceColumns[0].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.contentLength
      }
    }

    this.selectInterfaceColumns[1].render = (type, record) => {
      const { selectedDesignIDs, pcbId } = this.props;
      const pcbOpened = selectedDesignIDs.includes(pcbId);
      return <span
        className={pcbOpened ? 'cursor-pointer' : ""}
        onClick={pcbOpened ? (e) => this.props.showNetsToLayout({ e, pcbId: record.pcbId, interfaceKey: record.interfaceKey }) : null}
      >{type}</span>;
    }

    this.selectInterfaceColumns[1].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.contentLength
      }
    }

    this.selectInterfaceColumns[2].render = (text, record) => {
      return <span
        title={record.signalMergeName || record.name}
      >{record.signalMergeName || record.name}</span>
    }

    this.selectInterfaceColumns[2].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'signalMergeName',
        handleSave: (row) => this.props.updateSignalName(row)
      }
    }

    this.selectInterfaceColumns[3].render = (nets, record) => {
      if (nets.length) {
        const { selectedDesignIDs, pcbId } = this.props;
        const pcbOpened = selectedDesignIDs.includes(pcbId);
        return <div
          className={pcbOpened ? 'cursor-pointer' : ""}
          onClick={pcbOpened ? (e) => this.props.showNetsToLayout({ e, pcbId: record.pcbId, signalNets: record.nets }) : null}
        >{nets.join(', ')}</div>
      }
      else { return null }
    }

    this.selectInterfaceColumns[3].title = () => {
      const { selectedDesignIDs, pcbId } = this.props;
      const pcbOpened = selectedDesignIDs.includes(pcbId);
      return (
        <div>
          <span>Nets</span>
          <EyeOutlined
            title={pcbOpened ? "View Nets To Layout" : "Please Open PCB Layout"}
            className={pcbOpened ? 'creation-interface-nets-eye-icon' : "creation-interface-nets-eye-disabled-icon"}
            onClick={pcbOpened ? (e) => this.props.showNetsToLayout({ e, pcbId }) : null} />
        </div>
      );
    }
  }

  componentDidMount = () => {
    const { allInterfaceCompNetData } = this.props;
    if (allInterfaceCompNetData && allInterfaceCompNetData.length) {
      this.getDataSource(allInterfaceCompNetData)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { allInterfaceCompNetData, signalStatus } = this.props;
    if ((prevProps.allInterfaceCompNetData && allInterfaceCompNetData && allInterfaceCompNetData.length !== prevProps.allInterfaceCompNetData.length)
      || (signalStatus && signalStatus !== prevProps.signalStatus)) {
      this.getDataSource(allInterfaceCompNetData)
      this.props.updateSignalStatus(false)
    }
  }

  getCheckBoxStatus = (key) => {
    const { allInterfaceCompNetData, selectKeys } = this.props;

    let checked = false, indeterminate = false;
    if (key) {
      if (selectKeys.includes(key)) { checked = true }
      else { checked = false }
      return { checked }
    }
    const selectedProportion = `${selectKeys.length}/${allInterfaceCompNetData.length}`;
    if (selectKeys.length === 0) { return { checked, indeterminate, selectedProportion }; }
    if (allInterfaceCompNetData.length === selectKeys.length) {
      checked = true;
      indeterminate = false;
    } else {
      checked = false;
      indeterminate = true;
    }
    return { checked, indeterminate, selectedProportion };
  }

  getDataSource = (allInterfaceCompNetData) => {
    let dataSource = [];
    let key = 0;
    for (let info of allInterfaceCompNetData) {
      key = key + 1;
      const { content, type, pcbId, pcbs, interfaceKey } = info;

      content.forEach((item, index) => {
        const { name, nets, signalMergeName, signalId } = item;
        if (index < 1) {
          dataSource.push({ interfaceKey, name, signalMergeName, nets, type, pcbId, pcbs, contentLength: content.length, check: false, signalDisplay: true, signalId })
        } else {
          dataSource.push({ interfaceKey, name, nets, pcbId, signalMergeName, pcbs, contentLength: 0, check: false, signalDisplay: false, signalId })
        }
      })
    }

    this.setState({
      dataSource
    })
  }

  render = () => {
    const { dataSource } = this.state
    const { pcbId } = this.props;
    const _dataSource = dataSource.map((item, index) => { return { ...item, index } })
    return <Fragment>
      <div className="interface-identification-select-content">
        <Table
          columns={this.selectInterfaceColumns}
          dataSource={_dataSource}
          rowKey={(record) => `${pcbId}-${record.name}-${record.index}`}
          size="small"
          pagination={false}
          tableLayout="fixed"
          className="identification-select-table"
          scroll={
            dataSource && dataSource.length && dataSource.length > 8
              ? { y: 400 }
              : {}
          }
        />
      </div>
    </Fragment>
  }
}

export default SelectContent;