import HybridLines, { LayoutLine } from './hybridLines';
import { getDefaultName } from '../../setDefaultName';
import canvas from '@/services/LayoutCanvas';
import LayoutData from '@/services/data/LayoutData';
import { HFSSZoneItem, HybridRegionItem } from '.';
import hull from 'hull.js';
import Circle from '../geometry/Circle';
import { Boundary } from '../Boundaries';
import { unitChange } from '../../mathHelper';
import { getPointByToBoundariesMinDistance, getSortPoints } from './hybridHelper';
import { isPointInPolygon } from '../../canvasHelper/canvasMath';
import Line from '../geometry/Line';
import preview, { cleanPreview } from '../preview';
import { filterBoundaries } from '../boundary';

class HybridRegions {
  constructor({ channelId, designId }) {
    this.hybridLines = null;
    this.hybridLine = null;
    this.lineNameList = [];
    this.updateHybridRegionsFn = null;
    this.regions = new Map();
    this.unit = "mil";
    this.clipDesignBoundaries = {};
    this.canvasRegion = true;
    this.designId = designId;
    this.channelId = channelId;
  }

  addHybridLine = ({ clickMousePosition, updateHybridRegions }) => {
    this.updateHybridRegionsFn = updateHybridRegions;
    if (!this.hybridLines) {
      this.hybridLines = new HybridLines(this.channelId);
    }

    const db = LayoutData.getLayout(this.designId);
    this.unit = db.mUnits;
    if (this.unit === "mils") {
      this.unit = "mil";
    }
    const name = getDefaultName({ nameList: this.lineNameList, defaultKey: "Line", key: "" })

    if (!this.hybridLine) {
      this.hybridLine = new LayoutLine(name);
      this.lineNameList.push(name)
    }

    this.hybridLine.addPoint(clickMousePosition);
    this.updateLineCanvas(this.hybridLine);
  }

  updateLineCanvas = (line, notShowSave) => {
    const layout = canvas.getLayout(this.designId);
    layout.showHybridPoints({
      points: line.points,
      lineName: line.name,
      notShowSave
    }, {
      saveHybridLine: this.saveHybridLine,
      removeHybridLine: this.removeHybridLine,
      removeHybridPoint: this.removeHybridPoint
    })
  }

  clearHybridLineCache = (clearData = true) => {
    const layout = canvas.getLayout(this.designId);
    if (layout) {
      layout.cleanHybridBoundaries();
      layout.removeHybridAllPointsByName();
      cleanPreview(this.designId);
    }
    this.hybridLines = null;
    this.hybridLine = null;
    this.lineNameList = [];
    this.updateHybridRegionsFn = null;
    this.regions = new Map();
    this.prev_hybrid_regions = null;
    this.unit = "mil";
    if (clearData) {
      this.clipDesignBoundaries = {};
      this.canvasRegion = false;
    }
  }

  updateHybridCanvasRegion = (canvasRegion) => {
    this.canvasRegion = canvasRegion;
  }

  saveHybridLine = (name) => {
    if (!this.hybridLine || this.hybridLines.getLine(name)) {
      return;
    }
    //save regions to cache
    const newHybridLine = new LayoutLine(name);
    this.hybridLine.getPoints().forEach(item => { newHybridLine.addPoint({ ...item }) });
    /*   newHybridLine.pointsSort(); */
    let points = newHybridLine.getPoints();
    //calculate points by clip design boundaries
    points = calcPointsByBoundaries(points, this.clipDesignBoundaries, this.unit);
    newHybridLine.updatePoints(points);
    this.updateLineCanvas(newHybridLine, true);

    this.hybridLines.addLine(name, newHybridLine);
    this.calcRegionsByLines(true);
    this.hybridLine = null;
  }

  updateHybridRegionsCache = (hybridRegions = []) => {
    this.prev_hybrid_regions = JSON.parse(JSON.stringify(hybridRegions));
  }

  //update regions to extraction panel
  updateHybridRegionToSetup = () => {
    if (!this.updateHybridRegionsFn) {
      return;
    }
    const { hybridRegions, hybridLines } = this.regionsAndLinesFormat();
    this.updateHybridRegionsCache(hybridRegions);
    this.updateHybridRegionsFn({ hybridRegions, hybridLines })
  }

  removeHybridLine = (name) => {
    //remove hybrid lines points by name
    this.hybridLines.removeLine(name);
    //if line not be saved, remove hybrid line points
    if (this.hybridLine && this.hybridLine.getName(name)) {
      this.hybridLine.removePoints(this.designId);
      this.hybridLine = null;
    }
    this.removeCanvasLineByName(name);
    this.calcRegionsByLines(true)
  }

  removeCanvasLineByName = (name) => {
    const layout = canvas.getLayout(this.designId);
    layout.removeHybridAllPointsByName(name);

  }

  removeHybridPoint = (point, lineName) => {
    if (this.hybridLine && this.hybridLine.getName() === lineName) {
      this.hybridLine.removePoint(point);
      this.updateLineCanvas(this.hybridLine);
    } else if (this.hybridLines.getLine(lineName)) {
      this.hybridLines.getLine(lineName).removePoint(point);
      this.updateLineCanvas(this.hybridLines.getLine(lineName), true);
      this.calcRegionsByLines(true)
    }
  }

  generateHybridRegions = ({
    hybrid_regions,
    hybrid_lines,
    updateHybridRegions,
    isSave = false,
    clipSizeUpdate = false,
    clipSizeUpdateStatus,
    updateLines = false,
    selectedSignalUpdate,
    isAddHybridRegionsBox
  }) => {
    if (!hybrid_lines || !hybrid_lines.length) {
      return;
    }

    if (isSave) {
      return this._generateHybridRegions({ hybrid_regions, hybrid_lines, updateHybridRegions, isSave, clipSizeUpdate, clipSizeUpdateStatus, updateLines, selectedSignalUpdate })
    } else {
      let times = 0;
      const timeout = setInterval(() => {
        times++;
        const layout = canvas.getLayout(this.designId);
        if (layout) {
          isAddHybridRegionsBox && layout.addHybridRegionBox();
          this._generateHybridRegions({ hybrid_regions, hybrid_lines, updateHybridRegions, isSave, clipSizeUpdate, clipSizeUpdateStatus, updateLines, selectedSignalUpdate });
          clearInterval(timeout)
        }
        if (times === 120) {
          clearInterval(timeout)
        }
      }, 800)
    }
  }

  _generateHybridRegions = ({
    hybrid_regions,
    hybrid_lines,
    updateHybridRegions,
    isSave = false,
    clipSizeUpdate,
    clipSizeUpdateStatus,
    updateLines,
    selectedSignalUpdate
  }) => {
    this.clearHybridLineCache(false);
    let updateToSetup = clipSizeUpdate || selectedSignalUpdate ? true : false;
    const db = LayoutData.getLayout(this.designId);
    this.unit = db.mUnits;
    if (this.unit === "mils") {
      this.unit = "mil";
    }
    this.updateHybridRegionsFn = updateHybridRegions;
    this.lineNameList = [];
    this.hybridLine = null;
    this.prev_hybrid_regions = JSON.parse(JSON.stringify(hybrid_regions));
    this.hybridLines = new HybridLines(this.channelId);

    for (let _linePoints of hybrid_lines) {

      let linePoints = JSON.parse(JSON.stringify(_linePoints));

      const name = getDefaultName({ nameList: this.lineNameList, defaultKey: "Line", key: "" })

      const hybridLine = new LayoutLine(name);
      this.lineNameList.push(name)

      if (clipSizeUpdate || updateLines) {
        linePoints = this.updateLinePointsByClipSize(linePoints, clipSizeUpdateStatus, updateLines);
      }

      if (!linePoints || !linePoints.length) {
        continue;
      }

      for (let point of linePoints) {
        hybridLine.addPoint(point);
      }

      if (this.canvasRegion && !isSave) {
        this.updateLineCanvas(hybridLine, true);
      }

      this.hybridLines.addLine(name, hybridLine);
    }

    if (!this.canvasRegion && !isSave) {
      return;
    }

    this.calcRegionsByLines(updateToSetup, isSave);
    if (updateLines) {
      return this.regionsAndLinesFormat();
    }
    if (isSave) {
      return this.generateHfssZones(hybrid_regions);
    }
  }

  regionsAndLinesFormat = () => {
    if (!this.regions || !this.hybridLines) {
      return {};
    }
    let _regions = [], _lines = [];

    const regionsKeys = [...this.regions.keys()];

    const regionsValues = [...this.regions.values()];
    let _List = regionsValues.map((item, index) => { return { x: item.centerPoint[0], y: item.centerPoint[1], key: regionsKeys[index] } })
    _List = getSortPoints(_List).reverse()

    for (let info of _List) {
      const { key } = info;
      _regions.push(new HybridRegionItem({
        name: key,
        extraction: this.regions.get(key).extraction || "SIwave",
        centerPoint: [...this.regions.get(key).centerPoint],
        unit: this.unit
      }))
    }
    const linesMap = this.hybridLines.getLines();
    for (let key of [...linesMap.keys()]) {
      const points = linesMap.get(key).getPoints();
      _lines.push(JSON.parse(JSON.stringify(points)));
    }
    return { hybridRegions: _regions, hybridLines: _lines }
  }

  updateLinePointsByClipSize = (linePoints, clipSizeUpdateStatus, updateLines) => {
    if (updateLines) {
      return calcLinePointsByClipSize(linePoints, this.clipDesignBoundaries, this.unit)
    }
    if (clipSizeUpdateStatus === "increase") {
      //calculate points by clip design boundaries
      return calcPointsByBoundaries(linePoints, this.clipDesignBoundaries, this.unit);
    }
    if (clipSizeUpdateStatus === "reduce") {
      return calcLinePointsByClipSize(linePoints, this.clipDesignBoundaries, this.unit)
    }
  }

  generateHfssZones = (hybrid_regions) => {
    let hfss_zones = [];
    for (let regionItem of hybrid_regions) {
      if (regionItem.extraction !== 'HFSS') {
        continue;
      }
      const region = this.regions.get(regionItem.name);
      if (!region) {
        continue;
      }
      let hfss_zone = new HFSSZoneItem({});
      hfss_zone.unit = region.unit;
      for (const item of region.data) {
        hfss_zone.x.push(item[0]);
        hfss_zone.y.push(item[1]);
      };
      hfss_zones.push(hfss_zone);
    }
    return hfss_zones;
  }

  updateBoundariesToHybrid = (params = {}) => {
    const { data: _clipDesignBoundaries, boundaryPoints = {} } = params || {};
    this.clipDesignBoundaries = JSON.parse(JSON.stringify(_clipDesignBoundaries || {}));
    this.clipDataLimit = boundaryPoints || { maxX: Infinity, maxY: Infinity, minX: -Infinity, minY: -Infinity };
  }

  calcRegionsByLines = (updateToSetup, isSave) => {
    const linesMap = this.hybridLines.getLines();
    const layout = canvas.getLayout(this.designId);
    if (layout) {
      layout.cleanHybridBoundaries();
    }
    this.regions = new Map();
    const clipData = this.clipDesignBoundaries.data || [];
    let nameList = [], regionsNum = 0, existRegionCenterPoints = [];

    let allLines = getAllLines(linesMap, clipData);
    const lineKeys = [...linesMap.keys()];

    for (let key of lineKeys) {
      if (!this.canvasRegion) {
        return;
      }
      let points = linesMap.get(key).getPoints();
      let addPoints = points.filter((item, index) => index > 0 && index < points.length - 1);
      let start = points[0], end = points[points.length - 1];

      //find index by line start and end point
      let startPointIndex = clipData.findIndex(item => item[0] === start.x && item[1] === start.y),
        endPointIndex = clipData.findIndex(item => item[0] === end.x && item[1] === end.y);

      if (startPointIndex < 0 || endPointIndex < 0) {
        points = calcPointsByBoundaries(points, this.clipDesignBoundaries, this.unit);
        addPoints = points.filter((item, index) => index > 0 && index < points.length - 1);
        start = points[0];
        end = points[points.length - 1];
        startPointIndex = clipData.findIndex(item => item[0] === start.x && item[1] === start.y);
        endPointIndex = clipData.findIndex(item => item[0] === end.x && item[1] === end.y);
        const hybridLine = this.hybridLines.getLine(key);
        hybridLine.updatePoints(points);
        this.hybridLines.addLine(key, hybridLine);
        !isSave && this.updateLineCanvas(hybridLine, true);
        this.saveRegion = true;
        allLines = getAllLines(this.hybridLines.getLines(), clipData);
      }
      //find index
      const { startIndex, endIndex, start: _start, end: _end } = findStartEndIndex(startPointIndex, endPointIndex, { start: JSON.parse(JSON.stringify(start)), end: JSON.parse(JSON.stringify(end)) });

      const _allLines = allLines.filter(item => item.name !== key);
      // points >= start index && <= end index
      const minInfo = this.findFirstRegion({
        startIndex,
        endIndex,
        points,
        existRegionCenterPoints,
        start: _start,
        end: _end,
        allLines: _allLines,
        regionsNum,
        nameList,
        addPoints,
        isSave
      });
      nameList = minInfo.nameList;
      regionsNum = minInfo.regionsNum;
      existRegionCenterPoints = minInfo.existRegionCenterPoints;

      // points <= start index || >= end index
      const maxInfo = this.findEndRegion({
        startIndex,
        endIndex,
        points,
        existRegionCenterPoints,
        start: _start,
        end: _end,
        allLines: _allLines,
        regionsNum,
        nameList,
        addPoints,
        isSave
      })
      nameList = maxInfo.nameList;
      regionsNum = maxInfo.regionsNum;
      existRegionCenterPoints = maxInfo.existRegionCenterPoints;
    }
    !isSave && this.rePreviewClipBoundaries();
    (updateToSetup || this.saveRegion) && this.updateHybridRegionToSetup();

  }

  findFirstRegion = ({
    startIndex,
    endIndex,
    points,
    existRegionCenterPoints,
    start,
    end,
    allLines,
    regionsNum,
    nameList,
    addPoints,
    isSave
  }) => {
    // >=startIndex && <=endIndex
    const clipData = this.clipDesignBoundaries.data || [];
    let data = JSON.parse(JSON.stringify(clipData));
    const { findLine, type } = findIntersectingLine({
      startIndex,
      endIndex,
      include: true,
      allLines
    });

    let lines = [];
    if (findLine) {
      const returnData = updatePolyByTypeAndLine({
        data,
        findLine,
        type,
        startIndex,
        endIndex,
        start,
        end,
        addPoints,
        points
      });
      data = returnData.data;
      lines.push(...JSON.parse(JSON.stringify(returnData.lines)));
    } else {
      data = data.filter((item, index) => index >= startIndex && index <= endIndex);
      const info = addLinePointsToBoundaries({
        points,
        addPoints,
        data,
        lines,
        point: end
      });
      data = info.data;
      lines = info.lines;
    }
    const centerPoint = getCenterPoint(data);
    if (isSave) {
      data = this.getHybridBoundaries({ data, lines, start, end, anotherLine: findLine });
    }

    if (!judgeRegionExist(existRegionCenterPoints, centerPoint)) {
      let region = this.prev_hybrid_regions ? this.prev_hybrid_regions.find(item => item.centerPoint[0] === centerPoint[0] && item.centerPoint[1] === centerPoint[1]) : null;
      let name = getDefaultName({ nameList, defaultKey: "Region", key: "" });
      if (!region && this.prev_hybrid_regions) {
        region = this.prev_hybrid_regions.find(item => item.name === name)
      }
      name = region && !nameList.includes(region.name) ? region.name : getDefaultName({ nameList, defaultKey: "Region", key: "" });
      if (this.canvasRegion) {
        this.regions.set(name, { data, centerPoint, unit: this.unit, extraction: region ? region.extraction : "SIwave" });
        const layout = canvas.getLayout(this.designId);
        !isSave && layout.previewHybridBoundaries(data, centerPoint, name);
        regionsNum += 1;
        nameList.push(name);
        existRegionCenterPoints.push([...centerPoint]);
      }
    }
    return {
      nameList,
      regionsNum,
      existRegionCenterPoints
    }
  }

  findEndRegion = ({
    startIndex,
    endIndex,
    points,
    existRegionCenterPoints,
    start,
    end,
    allLines,
    regionsNum,
    nameList,
    addPoints,
    isSave
  }) => {
    // >=startIndex && <=endIndex
    const clipData = this.clipDesignBoundaries.data || [];
    let data = JSON.parse(JSON.stringify(clipData));
    const { findLine, type } = findIntersectingLine({
      startIndex,
      endIndex,
      include: false,
      allLines
    });

    let lines = [];

    if (findLine) {
      const returnData = updatePolyByTypeAndLine({
        data,
        findLine,
        type,
        startIndex,
        endIndex,
        start,
        end,
        addPoints,
        points
      })
      data = returnData.data;
      lines.push(...JSON.parse(JSON.stringify(returnData.lines)));
    } else {
      data = data.filter((item, index) => index <= startIndex || index >= endIndex);
      const info = addLinePointsToBoundaries({
        points,
        addPoints,
        data,
        lines,
        point: start
      });
      data = info.data;
      lines = info.lines;
    }

    const centerPoint = getCenterPoint(data);
    if (isSave) {
      data = this.getHybridBoundaries({ data, lines, start, end, anotherLine: findLine });
    }

    if (!judgeRegionExist(existRegionCenterPoints, centerPoint)) {
      let region = this.prev_hybrid_regions ? this.prev_hybrid_regions.find(item => item.centerPoint[0] === centerPoint[0] && item.centerPoint[1] === centerPoint[1]) : null;
      let name = getDefaultName({ nameList, defaultKey: "Region", key: "" });
      if (!region && this.prev_hybrid_regions) {
        region = this.prev_hybrid_regions.find(item => item.name === name)
      }
      name = region && !nameList.includes(region.name) ? region.name : getDefaultName({ nameList, defaultKey: "Region", key: "" });
      if (this.canvasRegion) {
        this.regions.set(name, { data, centerPoint, unit: this.unit, extraction: region ? region.extraction : "SIwave" });
        const layout = canvas.getLayout(this.designId);
        !isSave && layout.previewHybridBoundaries(data, centerPoint, name);
        regionsNum += 1;
        nameList.push(name);
        existRegionCenterPoints.push([...centerPoint]);
      }
    }
    return {
      nameList,
      regionsNum,
      existRegionCenterPoints
    }
  }

  getHybridBoundaries = ({ data, lines, start, end, anotherLine }) => {
    let boundaryArr = [];
    let distance = unitChange({ num: 1, oldUnit: "mm", newUnit: this.unit, decimals: 12 }).number;

    const _anotherLine = anotherLine ? JSON.parse(JSON.stringify(anotherLine)) : null;
    const circles = getCirclesByDividingLine([start, end], _anotherLine, distance, this.unit);

    // um to mil
    if (this.unit === "um") {
      distance = unitChange({ num: Number(distance), oldUnit: "um", newUnit: "mil", decimals: 12 }).number;
    }

    for (let item of data) {

      const findLine = lines.find(it => (it.start.x === item[0] && it.start.y === item[1]))


      //determines if the point resides inside the polygon
      const isInCircle = !findLine ? pointInCircleOfDividingLine(item, circles) : false;
      if (isInCircle) {
        continue;
      }

      if (this.unit === "um") {
        item[0] = (item[0] / 25.4)
        item[1] = (item[1] / 25.4)
      }
      //find line
      if (findLine) {

        const _findLine = JSON.parse(JSON.stringify(findLine));
        if (this.unit === "um") {
          _findLine.start.x = _findLine.start.x / 25.4;
          _findLine.start.y = _findLine.start.y / 25.4;
          _findLine.end.x = _findLine.end.x / 25.4;
          _findLine.end.y = _findLine.end.y / 25.4;
        }
        const boundary = new Boundary('Line');
        boundary.type = 'Line';

        boundary.geom = new Line({
          start: { mX: _findLine.start.x, mY: _findLine.start.y },
          end: { mX: _findLine.end.x, mY: _findLine.end.y }
        });

        const linePoints = boundary.toHybridPoints(0, 10);
        boundaryArr.push(...linePoints)

      } else {
        const boundary = new Boundary('Circle');
        boundary.type = 'Circle';
        boundary.geom = new Circle({ mDiameter: 2 * distance, mX: item[0], mY: item[1] });
        boundaryArr.push(...boundary.toPoints(0, 10))
      }
    };

    let boundaryData = hull(boundaryArr, 50);

    if (this.unit === 'um') {
      boundaryData.forEach(d => {
        d[0] = (d[0] * 25.4)
        d[1] = (d[1] * 25.4)
      })
    }
    //filter points far from the border of the pcb
    boundaryData = filterBoundaries(boundaryData, this.unit, this.clipDataLimit);

    return boundaryData;
  }

  rePreviewClipBoundaries = () => {
    if (!this.regions || this.regions.size === 0) {
      preview(this.clipDesignBoundaries || {}, this.designId)
    } else {
      cleanPreview(this.designId);
    }
  }

  getSavedRegion = () => {
    if (!this.saveRegion) {
      return null
    }
    return this.regionsAndLinesFormat()
  }
}


function getCirclesByDividingLine(linePoints, antherLine, distance, unit) {
  let circles = [];
  let points = antherLine && antherLine.points ? [...linePoints, ...antherLine.points.filter((it, index) => index === 0 || index === antherLine.points.length - 1)] : [...linePoints];
  points = JSON.parse(JSON.stringify(points));
  distance = Number(distance)
  for (let item of points) {

    circles.push({
      x1: item.x - distance,
      x2: item.x + distance,
      y1: item.y - distance,
      y2: item.y + distance,
    });
  }
  return circles;
}

//whether the point is inside the circle of the dividing line
function pointInCircleOfDividingLine(point, circles) {
  for (let item of circles) {
    if ((point[0] > item.x1 && point[0] < item.x2) && (point[1] > item.y1 && point[1] < item.y2)) {
      return true;
    }
  }
  return false;
}

function calcPointsByBoundaries(points, clipDesignBoundaries, unit) {
  //find boundaries
  if (!points || !points.length) {
    return [];
  }
  const startPoint = points[0]; const endPoint = points[points.length - 1];
  const boundariesData = clipDesignBoundaries.data || [];
  const { startBoundaryPoint, endBoundaryPoint, startDistance, endDistance } = getPointByToBoundariesMinDistance({ start: startPoint, end: endPoint, boundariesData })

  //todo
  //get distance from point to boundary
  const minDistance = getPointToBoundaryMinDistance(unit);

  //If the distance from the point to the boundary is less than the given minimum distance, replace the point with the boundary point
  if (startDistance < minDistance && startBoundaryPoint) {
    points[0] = startBoundaryPoint;
  } else {
    startBoundaryPoint && points.unshift(startBoundaryPoint);
  }

  /*  if (startBoundaryPoint.x === endBoundaryPoint.x && startBoundaryPoint.y === endBoundaryPoint.y) {
     endBoundaryPoint = getPoint(startBoundaryPoint, startPoint, boundariesData.filter(item => !(item[0] === startBoundaryPoint.x && item[1] === startBoundaryPoint.y)));
     endBoundaryPoint && points.push(endBoundaryPoint);
   } else { */
  if (endDistance < minDistance && endBoundaryPoint) {
    points[points.length - 1] = endBoundaryPoint;
  } else {
    endBoundaryPoint && points.push(endBoundaryPoint);
  }
  /* } */
  return points;
}

//get minimum distance from point to boundary
function getPointToBoundaryMinDistance(unit) {
  //TODO - get distance by signal nets trace
  switch (unit) {
    case "mil":
      return 2000;
    case "um":
      return 50800;
    case "mm":
      return 5;
    default: return 50;
  }
  // return unitChange({ num: 2000, newUnit: unit, decimals: 12 }).number;
}


function judgeRegionExist(existRegionCenterPoints, centerPoint) {
  if (!!existRegionCenterPoints.find(item => item[0] === centerPoint[0] && item[1] === centerPoint[1])) {
    return true;
  }
  return false;
}

function findStartEndIndex(startPointIndex, endPointIndex, { start, end }) {
  if (startPointIndex > endPointIndex) {
    return {
      startIndex: endPointIndex,
      endIndex: startPointIndex,
      dir: "-",
      start: end,
      end: start
    }
  }
  return {
    startIndex: startPointIndex,
    endIndex: endPointIndex,
    dir: "+",
    start,
    end
  }
}

function getAllLines(linesMap, data) {
  let allLinePoints = [...linesMap.keys()].map(lineName => {
    const points = JSON.parse(JSON.stringify(linesMap.get(lineName).getPoints()));
    if (!points.length || !data) {
      return false;
    }
    const length = points.length - 1;
    if (length <= 0) {
      return false;
    }

    let s_index = data.findIndex(item => points[0].x === item[0] && points[0].y === item[1]),
      e_index = data.findIndex(item => points[length].x === item[0] && points[length].y === item[1]);
    if (s_index < 0 || e_index < 0) {
      return false;
    }
    const { startIndex, endIndex, dir, start, end } = findStartEndIndex(s_index, e_index, { start: data[s_index], end: data[e_index] });
    return {
      name: lineName,
      points,
      startIndex,
      endIndex,
      dir,
      start: { x: start[0], y: start[1] },
      end: { x: end[0], y: end[1] }
    }
  });
  allLinePoints = allLinePoints.filter(line => !!line);
  return allLinePoints;
}

//calculate center point by region path data
function getCenterPoint(path) {
  let x = 0.0;
  let y = 0.0;
  for (let i = 0; i < path.length; i++) {
    x = x + parseFloat(path[i][0]);
    y = y + parseFloat(path[i][1]);
  }
  x = x / path.length;
  y = y / path.length;

  const center = { x, y };
  //determines if the point resides inside the polygon
  const isPoint = isPointInPolygon(center, path);

  if (!isPoint) {
    const { startBoundaryPoint } = getPointByToBoundariesMinDistance({
      start: { ...center },
      boundariesData: path
    })
    return startBoundaryPoint ? [startBoundaryPoint.x, startBoundaryPoint.y] : [center.x, center.y];
  }
  return [center.x, center.y];

}

function addReversePointsToData(data, point, addPoints) {
  const index = data.findIndex(item => item[0] === point.x && item[1] === point.y);
  const _addPoints = JSON.parse(JSON.stringify(addPoints)).reverse().map(item => { return [item.x, item.y] })
  if (!_addPoints.length) {
    return data;
  }
  data.splice(index + 1, 0, ..._addPoints);
  return data;
}

function addPointsToData(data, point, addPoints) {
  const index = data.findIndex(item => item[0] === point.x && item[1] === point.y);
  const _addPoints = addPoints.map(item => { return [item.x, item.y] });
  if (!_addPoints.length) {
    return data;
  }
  data.splice(index + 1, 0, ..._addPoints);
  return data;
}

function findIntersectingLine({
  startIndex,
  endIndex,
  include,
  allLines }) {
  let findLine = null, distance = Infinity, type = null;
  //data = data.filter((item, index) => index >= startIndex && index <= endIndex&&index<=findLine.startIndex)
  for (let line of allLines) {
    let s_index = -1, e_index = -1, _type = null;
    if (include) {
      if (line.startIndex > startIndex && line.endIndex < endIndex) {
        // <=find line start, >=find line end
        //startIndex -> find line start index -> find line points -> find line end index -> endIndex -> reverse curr line points
        s_index = line.startIndex;
        e_index = line.endIndex;
        _type = "include";
      }/*  else if (line.startIndex < startIndex && line.endIndex > startIndex && line.endIndex < endIndex) {
        //no 
      } */
    } else {
      if (line.startIndex < startIndex && line.endIndex < startIndex) {
        // <= find line start, >= find line end
        //0 -> find line start index -> find line points -> find line end index -> start index -> curr points -> end index -> data length - 1
        s_index = line.startIndex;
        e_index = line.endIndex;
        _type = "notIncludeLessStart";
      } else if (line.startIndex > endIndex && line.endIndex > endIndex) {
        // <= find line start, >= find line end
        // 0 -> start index -> curr points -> end index -> find line start index -> find line points -> find line end index -> data length - 1
        s_index = line.startIndex;
        e_index = line.endIndex;
        _type = "notIncludeMoreEnd";
      } else if (line.startIndex < startIndex && line.endIndex > endIndex) {
        // >= find line start, <= find line end
        // find line start -> start index -> curr points -> end index -> find line end index -> find line points
        s_index = line.startIndex;
        e_index = line.endIndex;
        _type = "notInclude";
      }

    }

    if (s_index < 0 || e_index < 0) {
      continue;
    }
    const s_dis = Math.abs(s_index - startIndex),
      e_dis = Math.abs(e_index - endIndex);
    if (s_dis < distance || e_dis < distance) {
      findLine = line;
      distance = s_dis < e_dis ? s_dis : e_dis;
      type = _type;
    }
  }

  return { findLine, type };
}

function updatePolyByTypeAndLine({
  data,
  findLine,
  type,
  startIndex,
  endIndex,
  start,
  end,
  points,
  addPoints
}) {
  const findLinePoints = findLine.points.filter((item, index) => index > 0 && index < findLine.points.length - 1)

  let lines = [];

  switch (type) {
    case "include":
      data = data.filter((item, index) => index >= startIndex && index <= endIndex && (index <= findLine.startIndex || index >= findLine.endIndex))
      //add find line points
      const includeInfo = addLinePointsToBoundaries({
        points: findLine.points,
        addPoints: findLinePoints,
        data,
        lines,
        point: findLine.start
      });
      data = includeInfo.data;
      lines = includeInfo.lines;
      break;
    case "notInclude":
      data = data.filter((item, index) => (index <= startIndex || index >= endIndex) && index >= findLine.startIndex && index <= findLine.endIndex)
      //add find line points
      const notIncludeInfo = addLinePointsToBoundaries({
        points: findLine.points,
        addPoints: findLinePoints,
        data,
        lines,
        point: findLine.end
      });
      data = notIncludeInfo.data;
      lines = notIncludeInfo.lines;
      break;
    case "notIncludeLessStart":
    case "notIncludeMoreEnd":
      data = data.filter((item, index) => (index <= startIndex || index >= endIndex) && (index <= findLine.startIndex || index >= findLine.endIndex))
      //add find line points
      const _notIncludeInfo = addLinePointsToBoundaries({
        points: findLine.points,
        addPoints: findLinePoints,
        data,
        lines,
        point: findLine.start
      });
      data = _notIncludeInfo.data;
      lines = _notIncludeInfo.lines;
      break;
    default: break;
  }
  //add curr line points
  let point = null;
  if (type === "include") {
    point = end;
  } else {
    point = start;
  }
  const info = addLinePointsToBoundaries({
    points,
    addPoints,
    data,
    lines,
    point
  });
  data = info.data;
  lines = info.lines;

  return { data, lines };
}

function getLinesByPoints(points) {
  let lines = [];
  for (let i = 0; i < points.length; i++) {
    if (!points[i + 1]) {
      continue;
    }
    lines.push({
      start: { x: points[i].x, y: points[i].y },
      end: { x: points[i + 1].x, y: points[i + 1].y }
    })
  }
  return lines;
}

function calcLinePointsByClipSize(points, clipDesignBoundaries, unit) {
  const boundaries = clipDesignBoundaries.data || [];
  if (!points) {
    return [];
  }
  let newPoints = points.filter(item => isPointInPolygon(item, boundaries));

  let leftPoint = null, rightPoint = null;
  if (newPoints.length === 1) {
    const index = points.findIndex(item => item.x === newPoints[0].x && item.y === newPoints[0].y);
    leftPoint = points.find((item, i) => i === index - 1) || points[0];
    rightPoint = points.find((item, i) => i === index + 1) || points[points.length - 1];
  } else {
    leftPoint = points[0];
    rightPoint = points[points.length - 1];
  }

  //find boundaries
  const { startBoundaryPoint, endBoundaryPoint, startDistance, endDistance } = getPointByToBoundariesMinDistance({ start: leftPoint, end: rightPoint, boundariesData: boundaries })

  //get distance from point to boundary
  const minDistance = getPointToBoundaryMinDistance(unit);

  if (startDistance < minDistance && startBoundaryPoint) {
    newPoints[0] = startBoundaryPoint;
  } else {
    startBoundaryPoint && newPoints.unshift(startBoundaryPoint);
  }

  if (endDistance < minDistance && newPoints.length > 1 && endBoundaryPoint) {
    newPoints[newPoints.length - 1] = endBoundaryPoint;
  } else {
    endBoundaryPoint && newPoints.push(endBoundaryPoint);
  }
  return newPoints;
}

function addLinePointsToBoundaries({ points, addPoints, data, point, lines }) {

  if (point.x === points[0].x && point.y === points[0].y) {
    data = addPointsToData(data, point, addPoints);
    lines.push(...getLinesByPoints(points));
  }
  if (point.x === points[points.length - 1].x && point.y === points[points.length - 1].y) {
    data = addReversePointsToData(data, point, addPoints);
    lines.push(...getLinesByPoints(JSON.parse(JSON.stringify(points)).reverse()));
  }

  return { lines, data }
}

export default HybridRegions;
export {
  getCenterPoint
}
