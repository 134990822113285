import { DIODE, LINEAR, LINEAR_SWITCH, SPECIALIZED, SWITCHING } from "../../../constants/componentType";
import { ROCKY } from "../../../constants/pageType";
import { getComponentPinLocation } from "../../ExtractionPortsHelper";
import { FERRITE, IGNORE, IND, JUMPER, RES, SWITCH } from "../../PCBHelper";
import _LayoutData from "../../data/LayoutData";
import { getComponentsByNets } from "../../helper/componentsHelper";
import GetVRMs from "../../helper/componentsHelper/GetPath/getCascaedVRM";
import { getComponents, getComponentsWithNetList, getLayoutComponents } from "../../helper/setup/setupData";
import { defaultGroundNets } from '../../../constants/defaultGroundNets';

function getPowerPinList(type, COMP_PREFIX_LIB = {}, record, Components = []) {
  if (!record || !COMP_PREFIX_LIB) {
    return [];
  }
  const { PowerNets = [], ReferenceNets = [], VRM } = record;
  let nets = [], rules = null;
  const { Res = [], Ind = [], Cap = [], Jumper = [], Ferrite = [], powerSwitch = [] } = COMP_PREFIX_LIB;
  //When COMP_PREFIX_LIB is modified, re-acquire the names
  let res = [...Res], ind = [...Ind], cap = [...Cap],
    jumper = [...Jumper], ferrite = [...Ferrite];
  let resRules = "", indRules = "", capRules = "", jumperRules = "", ferriteRules = "";
  let partName = [...powerSwitch];

  let getRule = (arr) => {
    let rules = ""
    arr.forEach((item, index) => {
      if (index === 0) {
        rules = "^" + item;
      } else {
        rules = resRules + "|^" + item;
      }
    })
    return rules
  }

  resRules = getRule(res);
  indRules = getRule(ind);
  capRules = getRule(cap);
  jumperRules = getRule(jumper);
  ferriteRules = getRule(ferrite);
  const vrmComp = VRM.map(item => item.VRM_COMP).flat(2);

  //resRules eg: ^R|RT
  //indRules eg: ^L
  //indRules eg: ^C|CT
  if (type === 'powerPin') {
    nets = PowerNets || [];
    //rules = /^R|^L/i;
    let powerRules = ""
    if (!res.length && !ind.length && !jumper.length && !ferrite.length) {
      return [];
    }
    if (resRules) {
      powerRules = powerRules ? `${powerRules}|${resRules}` : resRules;
    }
    if (indRules) {
      powerRules = powerRules ? `${powerRules}|${indRules}` : indRules;
    }
    if (jumperRules) {
      powerRules = powerRules ? `${powerRules}|${jumperRules}` : jumperRules;
    }
    if (ferriteRules) {
      powerRules = powerRules ? `${powerRules}|${ferriteRules}` : ferriteRules;
    }
    rules = new RegExp(powerRules, 'i');
  } else {
    nets = ReferenceNets || [];
    //rules = /^C/i;
    if (capRules) {
      rules = new RegExp(`${capRules}`, 'i');
    } else {
      return [];
    }

  };
  // Filter components connect to power or ground
  return Components.filter(comp => {
    if (!comp) return false;
    if (partName.includes(comp.part)) {
      return comp;
    }
    const _nets = comp.pins.map(item => item.net);
    for (const net of _nets) {
      // Only Cap will have Removed usage
      if (comp.usage !== 'Removed' && nets.includes(net) && rules.test(comp.name)) {
        return comp;
      } else if (type === 'powerPin' && nets.includes(net) && (comp.usage === 'Switch' || vrmComp.includes(comp.name))) {
        return comp;
      }
    };
    return false;
  });
}

function getAllRockyComponents({ pcbId, COMP_PREFIX_LIB, isAC = false }) {
  // PathR components
  const layout = _LayoutData.getLayout(pcbId);
  const _netList = layout && layout.mNetManager ? layout.mNetManager.GetAllNets().mValues : [];
  const { Components } = getComponents(_netList, pcbId, COMP_PREFIX_LIB, isAC);
  return Components;
}

function getirPDNComponentsByNets({ ReferenceNets, PowerNets, pcbId, COMP_PREFIX_LIB, isAC, specify = [] }) {
  const { Components, findVRMComps, findCaps } = getComponents([...ReferenceNets, ...PowerNets], pcbId, COMP_PREFIX_LIB, ROCKY, [], specify);
  let new_Components = ComponentFilter({ Components, ReferenceNets, PowerNets });
  new_Components = addMultiNetsFieldToCapComps({ Components: new_Components, pcbId, COMP_PREFIX_LIB, isAC });
  return { Components: new_Components, findVRMComps, findCaps }
}

function ComponentFilter({ Components, PowerNets, ReferenceNets }) {
  const _Components = JSON.parse(JSON.stringify(Components));
  const _filter = _Components.filter(comp => {
    let nets = [...new Set(comp.pins.map(item => item.net))];
    if (nets.every(net => PowerNets.includes(net))) {
      // Component only connect to PowerNets
      if (nets.length > 1) {
        return comp;
      } else {
        if (comp.usage !== 'Cap') {
          // If the component is only connected to single power net and the usage is not 'Cap', the usage is set to Ignore.
          comp.usage = 'Ignore';
          return comp;
        } else {
          return false;
          // Remove the 'Cap' components;
        }
      }
    } else if (nets.every(net => [...PowerNets, ...ReferenceNets].includes(net))) {
      if (nets.length > 1) {
        if (comp.usage === 'Switch') {
          nets = nets.filter(net => !ReferenceNets.includes(net))
          if (nets.length === 1) {
            comp.usage = 'Ignore';
          }
        }
        return comp;
      } else {
        return false;
      }
    } else {
      // The component connect to power and ground;
      // Connect at least two powernets
      const new_net = nets.filter(item => PowerNets.includes(item))
      if ([...new Set(new_net)].length > 1) {
        return comp;
      }
      return false
    }
  });
  return _filter;
};


//Add isMultiNets to cap components to supports caps that connect to more than 2 nets
function addMultiNetsFieldToCapComps({ Components, pcbId, COMP_PREFIX_LIB, isAC, isVersionUpdate = false }) {
  const allComponents = getAllRockyComponents({ pcbId, COMP_PREFIX_LIB, isAC })
  //If the nets to which the cap component is connected is greater than 2, true
  for (let comp of Components) {
    if (comp.usage !== "Cap" || (!isVersionUpdate && comp.isMultiNets !== undefined)) {
      continue;
    }
    const findComp = allComponents.find(it => it.name === comp.name);
    if (!findComp) {
      continue;
    }
    const pinNets = [...new Set(findComp.pins.map(item => item.net))];
    comp.isMultiNets = pinNets.length > 2 ? true : false;
  }
  return Components;
}

function getReferenceComps({ VRM_COMPS = [], findCaps, ReferenceNets, pcbId, COMP_PREFIX_LIB, powerPinList = [] }) {
  let VRMComponents = [];
  let referenceComps = [...findCaps];
  if (VRM_COMPS.length) {
    const _Components = getComponentsWithNetList([...ReferenceNets], pcbId, COMP_PREFIX_LIB, ROCKY);
    for (const compName of VRM_COMPS) {
      const VRM_Components = _Components.filter(item => item.name === compName)
      const pins = VRM_Components.map(it => ({ pin: it.pin, net: it.net }))
      VRMComponents.push({ pins: pins, name: compName })
    }
    referenceComps.push(...VRMComponents)
  }
  if (powerPinList.length) {
    const _powers = powerPinList
      .filter(item => item.COMP_TYPE !== 'Cap')
      .filter(item => item.usage !== 'Load')
      .filter(item => item.pins.some(pin => ReferenceNets.includes(pin.net)));
    referenceComps.push(..._powers)
  }
  referenceComps = referenceComps.filter(item => item.name);
  return { referenceComps, VRMComponents }
}

function _getVRM({
  designId,
  GroundNets,
  PowerNets,
  ExtendNets,
  ExistNets,
  COMP_PREFIX_LIB,
  loadSelect,
  connectInductance,
  findExtend = true,
  isAC = false,
  powerSwitch = [],
  doNotStuff = [],
  buckConverter,
  table = [],
  pinMapSense,
  isTemplate,
  pinConnection = [],
  pmicAndNets = [],
  pinMap = [],
  PMIC = []
}) {
  let comps = loadSelect.map(select => select.comp);
  let _powerNets = PowerNets.filter(item => {
    const _comps = getComponentsByNets({ netList: [item], pcbId: designId, getLayoutComponents, layers: {} });
    return _comps.find(c => comps.includes(c.name)) ? true : false;
  })
  let { Components, findVRMComps, findCaps } = getirPDNComponentsByNets({ ReferenceNets: GroundNets, PowerNets: [..._powerNets, ...ExtendNets], pcbId: designId, COMP_PREFIX_LIB, isAC, powerSwitch, specify: [] })
  const connectNets = getConnectNets({ PowerNets: [..._powerNets, ...ExtendNets], Components: [...Components], isAC });
  const getVRMInfo = new GetVRMs({
    VRM: [],
    ReferenceNets: [...GroundNets],
    PowerNets: [..._powerNets, ...ExtendNets],
    pcbId: designId,
    Components: Components,
    findVRMComps: findVRMComps.filter(item => !comps.includes(item.name)),
    findCaps: findCaps,
    COMP_PREFIX_LIB,
    findExtend: findExtend,
    ExistNets: ExistNets,
    connectInductance: connectInductance,
    connectNets,
    chips: comps,
    isAC,
    PMIC: PMIC,
    powerSwitch,
    doNotStuff,
    buckConverter,
    pinMapSense,
    isTemplate,
    pinConnection,
    pmicAndNets,
    pinMap,
    specify: []
  }, {
    getLayoutComponents,
    getPowerComponentsByNets: getirPDNComponentsByNets,
    getReferenceComps,
    getAllComponents: getAllRockyComponents
  })
  const getVRM = getVRMInfo.getVRM();
  let powerPinComps = [];
  for (let item of getVRM.VRM || []) {
    item.powerPin && powerPinComps.push(...item.powerPin.map(it => it.comp))
  }
  getVRM.Components = getVRM.Components.map(item => {
    if (doNotStuff.includes(item.name) || [DIODE].includes(item.COMP_TYPE)) {
      return { ...item, usage: IGNORE };
    }
    if ([RES, FERRITE, JUMPER, SWITCH].includes(item.COMP_TYPE)) {
      let itemData = table.find(data => data.name && data.name.includes(item.name))
      let value = itemData ? (parseFloat(itemData.value) === 0 ? '0' : itemData.value + itemData.unit) : '0'
      return { ...item, value }
    } else if (comps.includes(item.name)) {
      return { ...item, usage: 'Load' }
    } else if ((item.COMP_TYPE === IND || item.usage === IND) && powerPinComps.includes(item.name)) {
      //vrm power pin ignore
      return { ...item, COMP_TYPE: IGNORE, usage: IGNORE }
    }
    else {
      return item
    }
  })
  return getVRM;
}


function getPMIC(COMP_PREFIX_LIB = {}) {
  const PMIC = [];
  const { linearSwitch = [], linearRegulator = [], switchingRegulator = [], specialized = [] } = COMP_PREFIX_LIB;
  if (COMP_PREFIX_LIB) {
    //LINEAR, SWITCHING, VOLTAGE
    PMIC.push(...linearSwitch.map(item => ({ type: LINEAR_SWITCH, partName: item })))
    PMIC.push(...linearRegulator.map(item => ({ type: LINEAR, partName: item })))
    PMIC.push(...switchingRegulator.map(item => ({ type: SWITCHING, partName: item })))
    PMIC.push(...specialized.map(item => ({ type: SPECIALIZED, partName: item })))
  }
  return PMIC
}

function getConnectNets({ PowerNets, Components, isAC }) {
  let _Components = componentsFilter({ Components, PowerNets: PowerNets, ReferenceNets: [] })
  let connectNets = {}, netsInfo = {};
  const typeList = isAC ? ['Res', 'Ind', 'Jumper', 'Switch', 'Ferrite'] : ['Res', 'Ind', 'Jumper', 'Switch', 'Ferrite', 'Diode'];
  _Components = _Components.filter(item => typeList.includes(item.usage));
  _Components.forEach(comp => {
    const nets = comp.pins.map(item => item.net);
    PowerNets.forEach(net => {
      if (nets.includes(net)) {
        if (netsInfo[net]) {
          netsInfo[net].push({ name: comp.name, type: comp.COMP_TYPE })
        } else {
          netsInfo[net] = [{ name: comp.name, type: comp.COMP_TYPE }]
        }
      }
    })
  })
  const filterNets = Object.keys(netsInfo);
  filterNets.forEach(net => {
    if (netsInfo[net].length) {
      const types = netsInfo[net].map(item => item.type)
      if (types.every(net => ['Ind'].indexOf(net) > -1)) {
        netsInfo[net].forEach(it => {
          if (connectNets[it.name]) {
            connectNets[it.name].push(net)
          } else {
            connectNets[it.name] = [net]
          }
        })
      }
    }
  })

  return connectNets
}

function componentsFilter({ Components, PowerNets, ReferenceNets }) {
  const _Components = JSON.parse(JSON.stringify(Components));
  const _filter = _Components.filter(comp => {
    if (comp.usage === 'Cap') { return false }
    const nets = [...new Set(comp.pins.map(item => item.net))];
    if (nets.every(net => PowerNets.includes(net))) {
      // Component only connect to referenceNets
      if (nets.length > 1) {
        return comp;
      } else {
        return false;
        // The component only connect to single ground net.
      }
    } else if (nets.every(net => [...PowerNets, ...ReferenceNets].includes(net))) {
      // Component only connect to PowerNets
      if (nets.length > 1) {
        return comp;
      } else {
        return false;
      }
    } else {
      const new_net = nets.filter(item => PowerNets.includes(item))
      if (new_net.length > 1) {
        return comp;
      }
      return false
    }
  });
  return _filter;
};

function sortReference(refeList, designId, pwr, pwrPins = []) {
  if (!designId || !pwr || !pwrPins.length) {
    return refeList.map(item => item.name);
  }
  const db = _LayoutData.getLayout(designId);
  const compPins = getComponentPinLocation({
    scaling: 1,
    component: db.getComponent(pwr),
    compName: pwr,
    mPartMgr: db.mPartMgr,
    savePad: true
  }).filter(pin => pwrPins.includes(pin.number))
  let pwrLocation = {}
  if (compPins.length) {
    const { x, y } = compPins[0]
    if (x && y) {
      pwrLocation = { mX: x, mY: y }
    }
  }
  return refeList.sort((a, b) => {
    if (!a.location) {
      return 1
    } else if (!b.location) {
      return -1
    }
    const { mX: aX, mY: aY } = a.location;
    const aXLength = Math.abs(pwrLocation.mX - aX), aYLength = Math.abs(pwrLocation.mY - aY);

    const { mX: bX, mY: bY } = b.location;
    const bXLength = Math.abs(pwrLocation.mX - bX), bYLength = Math.abs(pwrLocation.mY - bY);

    return (aXLength * aXLength + aYLength * aYLength) - (bXLength * bXLength + bYLength * bYLength)
  }).map(item => item.name)
}

function getDisplayDetail(details, sense = false) {
  let newDetailList = [];
  let maxLength = 0;
  for (let detail of details) {
    if (detail.length > maxLength) {
      maxLength = detail.length;
    }
    let _detail = Array.isArray(detail) ? detail.map((item, index) => {
      if (item.type === 'net') {
        return item.name
      } else {
        return index === detail.length - 1 ?
          item.pins && item.pins.length ? `{${item.name}::${item.pins.map(p => p.pinName).join('::')}}` : `{${item.title ? item.title : item.name}}`
          : `[${item.name}]`;
      }
    }) : [];
    newDetailList.push(_detail);
  }
  return newDetailList
}

let truePowerNets = [], filterPowerNets = JSON.parse(JSON.stringify(defaultGroundNets)), maxLevel = 4;
function getRockyMainPowerNet(powerNets, targetIC, designId, COMP_PREFIX_LIB) {
  const components = getAllRockyComponents({ pcbId: designId, COMP_PREFIX_LIB });
  const ic = components.find(item => item.name === targetIC);
  if (!ic) {
    return powerNets;
  }
  const icPowerNets = ic.pins.map(item => item.net);
  for (let powerNet of powerNets) {
    const _powerNets = icPowerNets.filter(net => net === powerNet);
    if (_powerNets.length < 3) {
      filterPowerNets.push(powerNet);
      traceTruePowerNet(powerNet, icPowerNets, designId, COMP_PREFIX_LIB, 1);
    } else {
      truePowerNets.push(powerNet);
    }
  }

  if (!truePowerNets.length) {
    truePowerNets = [];
    filterPowerNets = JSON.parse(JSON.stringify(defaultGroundNets));
    return powerNets;
  }

  let truePowerNet = truePowerNets[0], max = 0;
  for (let trueNet of truePowerNets) {
    const _powerNets = icPowerNets.filter(net => net === trueNet);
    if (_powerNets.length > max) {
      max = _powerNets.length;
      truePowerNet = trueNet;
    }
  }

  truePowerNets = [];
  filterPowerNets = JSON.parse(JSON.stringify(defaultGroundNets));
  return [truePowerNet]
}

function traceTruePowerNet(powerNet, icPowerNets, designId, COMP_PREFIX_LIB, level) {
  if (level >= maxLevel) {
    return;
  }
  const _components = getAllRockyComponents({ pcbId: designId, COMP_PREFIX_LIB });
  const components = getComponentsByNets({
    netList: [powerNet],
    pcbId: designId,
    COMP_PREFIX_LIB: COMP_PREFIX_LIB,
    layers: {},
    getLayoutComponents,
    powerSwitch: []
  }).filter(item => [RES, JUMPER, FERRITE, SWITCH, IND].includes(item.type))
    .map(comp => _components.find(c => c.name === comp.name)).filter(item => !!item);
  let newNets = [], hasNet = false;
  for (let comp of components) {
    const _filterPowerNets = filterPowerNets;
    const nets = comp.pins.map(pin => pin.net).filter(n => !_filterPowerNets.includes(n));
    for (let net of nets) {
      const _powerNets = icPowerNets.filter(n => n === net);
      if (_powerNets.length < 3) {
        filterPowerNets.push(net);
        newNets.push(net)
      } else {
        truePowerNets.push(net);
        hasNet = true
      }
    }
  }

  if (hasNet) {
    return;
  }

  newNets.forEach(net => traceTruePowerNet(net, icPowerNets, designId, COMP_PREFIX_LIB, level + 1))
}

export {
  getPowerPinList,
  getirPDNComponentsByNets,
  getReferenceComps,
  _getVRM,
  sortReference,
  getAllRockyComponents,
  getDisplayDetail,
  getRockyMainPowerNet
}