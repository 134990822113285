import CeGeomTools from './CeGeomTools';
import CeRectangle from './CeRectangle';

const CeOctagon = function () {
  CeRectangle.call(this, "Octagon");
  this.mCornerSize = 0.0;
}

// subclass extends superclass
CeOctagon.prototype = Object.create(CeRectangle.prototype);
CeOctagon.prototype.constructor = CeOctagon;

CeOctagon.prototype.SetData = function (cornerSize, cx, cy, w, h) {
  this.mCornerSize = cornerSize;
  CeRectangle.prototype.SetData.call(this, cx, cy, w, h);
}

CeOctagon.prototype.equals = function (obj) {
  if (!(obj instanceof CeOctagon))
    return false;

  if (CeGeomTools.TwoNumberEqual(this.mCornerSize, obj.mCornerSize) == false)
    return false;

  return CeRectangle.prototype.equals.call(this, obj);
}

CeOctagon.prototype.CopyFrom = function (fromObj) {
  CeRectangle.prototype.CopyFrom.call(this, fromObj);
  this.mCornerSize = fromObj.mCornerSize;
}

CeOctagon.prototype.Clone = function () {
  var clnObj = new CeOctagon();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeOctagon.prototype.WriteIntoIODataNode = function () {
  var retItem = CeRectangle.prototype.WriteIntoIODataNode.call(this);
  retItem.AppendData(this.mCornerSize.toString());
  return retItem;
}

CeOctagon.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeOctagon.prototype.GetDataString = function () {
  var defData = CeRectangle.prototype.GetDataString.call(this);
  defData.push(this.mCornerSize);
  return defData;
}

CeOctagon.prototype.UpdateByString = function (dataVals) {
  if (CeRectangle.prototype.UpdateByString.call(this, dataVals) == false)
    return false;

  if (dataVals.size() < 5)
    return false;

  this.mCornerSize = parseFloat(dataVals.get(4));

  return true;
}

export default CeOctagon;