import {
  SAVE_IR_EXPLORER_INFO,
  UPDATE_LOADING_PCB,
  SAVE_IR_VERIFICATIONID,
  UPDATE_IR_LOAD,
  CLEAR_CURRENT_IR_DATA,
  UPDATE_IR_RESULT_EXIST,
  UPLOAD_IR_TABLE_LOADING,
  POWER_NET_SELECT_PANEL,
  SELECT_PCB,
  COMPONENT_SETTING_UPDATE,
  UPDATE_EXTRACTION,
  SEARCH_VRM
} from './actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { IR_VERSION } from '@/version';

const defaultState = {
  IRExplorerInfo: {},
  IRExplorerId: null,
  IRloading: false,
  verificationId: null,
  debugVRMMonitor: [],
  resultExist: {
    id: '',
    exit: false
  },
  IRTableLoading: false,
  powerNetSelect: {
    open: false,
    select: [],
    allPowerNets: [],
    allNets: []
  },
  version: IR_VERSION,
  designId: null,
  updateCompPrefix: false,
  designName: null,
  extraction: {},
  searchVRM: false
}

export const CascadeIRReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_IR_EXPLORER_INFO:
      return {
        ...state,
        IRExplorerInfo: {
          ...state.IRExplorerInfo,
          ...action.info
        }
      }
    case SAVE_IR_VERIFICATIONID:
      return {
        ...state,
        verificationId: action.id
      }
    case UPDATE_LOADING_PCB:
      return {
        ...state,
        IRloading: action.IRloading
      }
    case UPDATE_IR_LOAD:
      return {
        ...state,
        IRExplorerInfo: {
          ...state.IRExplorerInfo,
          irLoadSelect: action.irLoadSelect
        }
      }
    case CLEAR_CURRENT_IR_DATA:
      return {
        ...state,
        verificationId: null,
        IRExplorerInfo: {},
      }
    case UPLOAD_IR_TABLE_LOADING:
      return {
        ...state,
        IRTableLoading: action.loading
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_IR_RESULT_EXIST:
      return {
        ...state,
        resultExist: action.resultExist
      }
    case POWER_NET_SELECT_PANEL:
      return {
        ...state,
        powerNetSelect: {
          ...state.powerNetSelect,
          ...action.obj
        }
      }
    case SELECT_PCB:
      return {
        ...state,
        designId: action.pcbId,
        designName: action.designName
      }
    case COMPONENT_SETTING_UPDATE:
      return {
        ...state,
        updateCompPrefix: action.bool
      }
    case UPDATE_EXTRACTION:
      return {
        ...state,
        extraction: action.extraction
      }
    case SEARCH_VRM:
      return {
        ...state,
        searchVRM: action.boolean
      }
    default: return state
  }
}