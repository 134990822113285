import { call, put, fork, cancel, takeEvery, select, delay } from 'redux-saga/effects';
import { RUN_MULTIPLE_BYTE, GET_MULTIPLE_CHANNEL_CONTENT, UPDATE_MULTIPLE_SELECT_VERIFICATION, OPEN_MULTIPLE_RESULT_CONTENT, DELETE_INTERFACES, AUTO_MULTIPLE_GET_VERIFICATIONS } from './actionType';
import { updateMultiList, openMultipleResult, updateMultipleStatus, updateResultMenuStatus } from './action';
import { MULTIPLE_CHANNEL } from '@/constants/treeConstants'
import { openTabFooter, changeTabMenu, closeTabFooter } from '../../../MonitorStore/action';
import { cleanResultPageInfo, openResultPageInfo } from '../result/action';
import { getInterfaceMonitor } from '../simulation/action';
import multipleResultConstructor from '@/services/Rocky/multipleResultHelper';
import { createModelingInfoPromise, getModelingListPromise, deleteModelingInfoPromise, getChannelConfig, updateChannelExtractionConfig } from '@/services/Rocky';
import { startMultipleModeling } from '../simulation/multiple/action';
import { isRockyPuppeteer } from '../../../../services/helper/browser';
import { ROCKY } from '../../../../constants/pageType';
import { VERIFY_RUNNING, VERIFY_SUCCESS, WAITING } from '../../../../constants/verificationStatus';
import { getVerificationContentPromise, updateVerificationContent } from '../../../../services/Rocky';
import { initDefaultInterfaces } from '../rocky/saga';
import { ROCKY_SETUP_VERSION } from '../../../../version';
import { setSelectDefaultChannelConfig } from '../project/saga';

function* getMultipleChannelContent(action) {
  const { id } = action;
  try {
    const response = yield call(getModelingListPromise, id);

    yield put(updateMultiList(response))
    multipleResultConstructor.setInfo(id, response)
    if (response && response.length) {
      yield put(openTabFooter())
      const current = response[0];
      yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], verificationName: current ? current.name : null, currentVerificationId: current.id }))
      yield put(getInterfaceMonitor(current.id, MULTIPLE_CHANNEL))
      yield put(openMultipleResult())
    } else {
      yield put(closeTabFooter())
      yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], verificationName: null, currentVerificationId: null }))
      yield put(cleanResultPageInfo())
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteInterfaces(action) {
  const { ids } = action;
  try {
    const { RockyReducer: { rockyMultiple: { channelId, list } } } = yield select();
    // delete
    yield call(deleteModelingInfoPromise, { channelId, verificationIds: ids })
    // update list
    const newList = list.filter(item => !ids.includes(item.id))
    // If a deleted monitor is displayed, change the result displayed on the edge
    // to do
    yield put(updateMultiList(newList))
    multipleResultConstructor.setInfo(channelId, newList)
  } catch (error) {
    console.error(error)
  }
}

function* findVerificationInfo({ channelId, selectVerificationId, list }) {
  try {
    const { RockyReducer: { project: { currentProjectId, projectList } } } = yield select();
    const find = projectList.find(item => item.id === currentProjectId);
    let verificationSubId = null, verificationId = null, interfaces = [], status = -1, dataList = [], designID = '', designSubId = "";
    dataList = [...list]
    if (dataList && dataList.length) {
      let index = 0;
      if (selectVerificationId) {
        index = dataList.findIndex(item => item.id === selectVerificationId)
      }
      if (index < 0) { index = 0 }
      const findInfo = dataList[index];
      status = findInfo ? findInfo.status : -1;
      verificationSubId = findInfo.subId;
      verificationId = findInfo.id;
      designID = findInfo.designId;
      designSubId = findInfo.designSubId;
      yield put(openTabFooter())
      yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], verificationName: findInfo ? findInfo.name : null, currentVerificationId: findInfo.id }))
      // update log
      yield put(getInterfaceMonitor(findInfo.id, MULTIPLE_CHANNEL))

      yield put(openResultPageInfo({
        verificationId,
        verificationSubId,
        designSubId: designSubId,
        designID,
        interfaces,
        channelId,
        channelName: findInfo.channelName,
        ddrType: find.type
      }));
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateSelectVerification(action) {
  try {
    const { id } = action;
    const { RockyReducer: { result: { resultInfo: { channelId } }, rockyMultiple: { list } } } = yield select();
    yield call(findVerificationInfo, { channelId, selectVerificationId: id, list })
  } catch (error) {
    console.error(error)
  }
}

function* runMultipleChannel(action) {
  const { runInfo } = action;
  const { RockyReducer: { rockyMultiple: { channelId: currentChannelId } } } = yield select();
  try {
    let runChannelIds = [], currentInfo = null;
    yield* runInfo.map(function* (channelInfo) {
      const { channelId, verificationList } = channelInfo;
      const isCurrent = currentChannelId === channelId ? true : false;
      const id = yield call(runMultipleByte, { isCurrent, verificationIds: verificationList, channelId });
      if (id) {
        runChannelIds.push(id)
        if (isCurrent) {
          currentInfo = id;
        }
      }
    })
    yield put(startMultipleModeling([...runChannelIds], currentInfo))
  } catch (error) {
    console.error(error)
  }
}

function* runMultipleByte(action) {
  const { verificationIds, isCurrent, channelId } = action
  try {
    const Config = yield call(getChannelConfig, channelId);
    if (Config.config.config && Object.keys(Config.config.config).length) {
      // Verify the data of config
      let _extraction = Config.config.extraction;
      let saveConfig = false;
      if (_extraction && _extraction.channelType === "Default") {
        saveConfig = true;
        _extraction.channelType = "SIwave";
      }
      if (saveConfig) {
        yield call(updateChannelExtractionConfig, channelId, _extraction);
      }
    } else if (!Config.config.timeStep) {
      yield call(setSelectDefaultChannelConfig, { channelId });
    }
    const currentConfig = Config.config.config;
    yield* verificationIds.map(function* (id, veIndex) {
      const response = yield call(getVerificationContentPromise, id);
      if (response) {
        const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
        let Interfaces = JSON.parse(JSON.stringify(response.interfaces));
        if ((!response.version || response.version === "0") && Interfaces && Interfaces.length > 0) {
          Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
          //save setup to server
          yield call(updateVerificationContent, {
            id: id,
            name: response.name,
            interfaces: Interfaces,
            config: response.config,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
            readyForSim: response.readyForSim,
            version: ROCKY_SETUP_VERSION,
            designVersion: response.designVersion ? response.designVersion : "0"
          });
        }
      }
    })


    const createVerificationInfo = yield call(createModelingInfoPromise, verificationIds);

    const { id } = createVerificationInfo;
    const list = yield call(getModelingListPromise, channelId);
    multipleResultConstructor.setInfo(channelId, list);
    if (isCurrent) {
      yield put(updateMultiList(list))
      yield put(updateResultMenuStatus(true))
      yield call(findVerificationInfo, { channelId, selectVerificationId: id, list })
    }
    return id;
  } catch (error) {
    console.error(error)
  }
}

function* _runMultipleByte(action) {
  const { verificationIds } = action;
  try {
    const { RockyReducer: { rocky: { currentConfig }, rockyMultiple: { channelId } } } = yield select();

    // init value
    yield* verificationIds.map(function* (id, veIndex) {
      const response = yield call(getVerificationContentPromise, id);
      if (response) {
        const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
        let Interfaces = JSON.parse(JSON.stringify(response.interfaces));
        if ((!response.version || response.version === "0") && Interfaces && Interfaces.length > 0) {
          Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
          //save setup to server
          yield call(updateVerificationContent, {
            id: id,
            name: response.name,
            interfaces: Interfaces,
            config: response.config,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
            readyForSim: response.readyForSim,
            version: ROCKY_SETUP_VERSION,
            designVersion: response.designVersion ? response.designVersion : "0"
          });
        }
      }
    })

    // Verify the data of config
    const Config = yield call(getChannelConfig, channelId);
    let _extraction = Config.config.extraction;
    let saveConfig = false;
    if (_extraction && _extraction.channelType === "Default") {
      saveConfig = true;
      _extraction.channelType = "SIwave";
    }
    if (saveConfig) {
      yield call(updateChannelExtractionConfig, channelId, _extraction);
    }
    // create multiple info
    const createVerificationInfo = yield call(createModelingInfoPromise, verificationIds);

    const list = yield call(getModelingListPromise, channelId);
    yield put(updateMultiList(list))
    yield put(updateResultMenuStatus(true))
    multipleResultConstructor.setInfo(channelId, list);
    const { id } = createVerificationInfo;
    yield call(findVerificationInfo, { channelId, selectVerificationId: id, list })
    yield put(startMultipleModeling([id], id))
  } catch (error) {
    console.error(error)
  }
}

function* openMultipleResultContent(action) {
  const { selectVerificationId } = action;
  try {
    const { RockyReducer: { project: { currentProjectId, projectList }, rockyMultiple: { channelId, list } } } = yield select();
    const find = projectList.find(item => item.id === currentProjectId);

    let verificationSubId = null, verificationId = null, interfaces = [], designID = '', designSubId = "";
    let index = 0;
    if (selectVerificationId) {
      index = list.findIndex(item => item.id === selectVerificationId)
    }
    const findInfo = list[index];
    verificationSubId = findInfo.subId;
    verificationId = findInfo.id;
    designID = findInfo.designId;
    designSubId = findInfo.designSubId;

    yield put(openResultPageInfo({
      verificationId,
      verificationSubId,
      designSubId: designSubId,
      designID,
      interfaces,
      channelId,
      channelName: findInfo.channelName,
      ddrType: find.type
    }));
  } catch (error) {
    console.error(error)
  }

}

function* _autoGetVerifications(action) {
  if (isRockyPuppeteer()) return;
  if (!action || !action.channelId) { return }
  const { channelId: currentChannelId } = action;
  let delayTime = 60000;
  let first = true;
  let isWhile = true;
  let simList = [];
  while (isWhile) {
    const { LoginReducer: { pageType }, RockyReducer: { rockyMultiple: { channelId, multiByteStatus }, result: { resultInfo } } } = yield select();
    if (pageType !== ROCKY) {
      return;
    };

    if (!first) {
      yield delay(delayTime);//5000
    }

    if (currentChannelId !== channelId || !channelId) {
      isWhile = false;
    }

    const verification = yield call(getModelingListPromise, currentChannelId);
    yield put(updateMultiList(verification))
    const simVerifications = verification.filter(item => [VERIFY_RUNNING, WAITING].includes(item.status));
    if (first && simVerifications.length) {
      simList = [...simVerifications.map(item => item.id)];
    } else if (simVerifications.length !== simList.length) {
      const filterList = simList.filter(item => !simVerifications.find(it => it.id === item))
      if (filterList && filterList.length && resultInfo && Object.keys(resultInfo).length > 0) {
        const verificationId = resultInfo.verificationId;
        const showInfo = verification.find(item => item.status === VERIFY_SUCCESS && filterList.includes(item.id) && verificationId === item.id);
        if (showInfo) {
          yield put(updateMultipleStatus(!multiByteStatus))
        }
        simList = [...simVerifications.map(item => item.id)];
      }
    }
    if (simVerifications && simVerifications.length) {
      delayTime = 5000;
    } else {
      isWhile = false
    }
    first = false;
  }
}

// autoVerifiaction
let autoUpdateVerificationsTask = null;
function* autoGetVerifications(action) {
  if (autoUpdateVerificationsTask) {
    yield cancel(autoUpdateVerificationsTask);
  }
  autoUpdateVerificationsTask = yield fork(_autoGetVerifications, action)

}

function* rockyMultipleSaga() {
  yield takeEvery(GET_MULTIPLE_CHANNEL_CONTENT, getMultipleChannelContent);
  yield takeEvery(RUN_MULTIPLE_BYTE, runMultipleChannel);
  yield takeEvery(UPDATE_MULTIPLE_SELECT_VERIFICATION, updateSelectVerification);
  yield takeEvery(OPEN_MULTIPLE_RESULT_CONTENT, openMultipleResultContent);
  yield takeEvery(DELETE_INTERFACES, deleteInterfaces)
  yield takeEvery(AUTO_MULTIPLE_GET_VERIFICATIONS, autoGetVerifications)
}

export default rockyMultipleSaga;