import React from 'react';
import './index.css';


function ContextMenu(props) {
  const {style, items = [] } = props;
  return (
    <div className="aurora-popover aurora-popover-placement-bottom  aurora-popover-hidden context-menu" style={style}>
      <div className="aurora-popover-content">
        <div className="aurora-popover-arrow"></div>
        <div className="aurora-popover-inner" role="tooltip" onClick={props.onClick}>
          {items.map((item, i) => (<div key={i} className="aurora-popover-inner-content">{item}</div>))}
        </div>
      </div>
    </div>
  );
}

export default ContextMenu;