const SIERRA = `SIERRA`;
export const GET_VERIFICATION_CONTENT = `${SIERRA}/get_content`;
export const UPDATE_SIERRA_CONTENT = `${SIERRA}/update_content`;
export const SELECT_NETS = `${SIERRA}/select_nets`;
export const UPDATE_SIERRA_INTERFACE = `${SIERRA}/update_interface`;
export const AUTO_SAVE_CONFIG_TO_SERVER = `${SIERRA}/auto_save_config_to_serevr`;
export const SAVE_CONFIG_TO_SERVER = `${SIERRA}/save_config_to_serevr`;
export const VOLTAGE_CHANGE = `${SIERRA}/voltage_change`;
export const EXPAND_MENU = `${SIERRA}/expand_menu`;
export const SAVE_COMPONENTS = `${SIERRA}/save_components`;
export const ADD_SIGNAL = `${SIERRA}/add_signal`;
export const ADD_PCBS_IN_SIGNAL = `${SIERRA}/add_pcbs_in_signal`;
export const UPDATE_SIERRAINFO = `${SIERRA}/update_sierrainfo`;
export const EDIT_SIGNAL = `${SIERRA}/editSignal`;
export const UPDATE_INTERFACES = `${SIERRA}/update_interfaces`;
export const ASSIGN_MODEL = `${SIERRA}/assgin_model`;
export const SAVE_MODEL_PINS = `${SIERRA}/save_model_pins`;
export const ADD_POWER_GROUND_NETS = `${SIERRA}/add_power_ground_nets`;
export const ADD_PCBS_IN_POWER_NETS = `${SIERRA}/add_pcbs_in_power_gnd_nets`;
export const PG_NETS_SELECTION = `${SIERRA}/pg_net_selection`;
export const NET_VOLTAGE_UPDATE = `${SIERRA}/net_voltage_update`;
export const CREATE_CONFIG = `${SIERRA}/creat_simulation_config`;
export const EDIT_CHANNEL_TYPE = `${SIERRA}/edit_channel_type`;
export const UPDATE_CHANNEL_MODEL = `${SIERRA}/update_channel_moedl`;
export const UPDATE_OPTIONS = `${SIERRA}/update_options`;
export const SAVE_CONNECTOR = `${SIERRA}/save_connector`;
export const EDIT_COMPONENT_TYPE = `${SIERRA}/edit_component_type`;
export const EDIT_SIGNAL_NAME = `${SIERRA}/edit_signal_name`;
export const DELETE_SIGNAL = `${SIERRA}/delete_signal`;
export const DELETE_PG_NETS = `${SIERRA}/delete_pg_nets`;
export const SAVE_REPEATER_MODEL = `${SIERRA}/save_repeater_model`;
export const SAVE_POWER_OFF = `${SIERRA}/save_power_off`;
export const SAVE_CHIP_MODEL = `${SIERRA}/save_chip_model`;
export const SAVE_EXTRACTION = `${SIERRA}/save_extraction`;
export const SAVE_RE_EXTRACTION = `${SIERRA}/save_re_extraction`;
export const UPDATE_NETS_COMPS = `${SIERRA}/update_nets_comps`;
export const SAVE_CURRENT_VERIFICATION = `${SIERRA}/save_current_verification`;
export const CURRENT_VERIFICATION_DEBUG_VERIFY = `${SIERRA}/current_verification_debug_verify`;
export const UPDATE_CURRENT_CONFIG = `${SIERRA}/update_current_config`;
export const UPDATE_OPTIONS_BY_CLOSE = `${SIERRA}/update_options_by_close`;
export const UPDATE_INTERFACE_LIBRARY = `${SIERRA}/update_interface_library`;
export const UPDATE_INTERFACE_LIBRARY_MSG = `${SIERRA}/update_interface_library_msg`;
export const SAVE_RLC_MODEL = `${SIERRA}/save_RLC_model`;
export const SAVE_COMP_PACKAGE_MODEL = `${SIERRA}/save_comp_package_model`;
export const UPDATE_TOUCHSTONE_STATUS_LIST = `${SIERRA}/update_touchstone_status_list`;
export const UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS = `${SIERRA}/update_touchstone_file_macro_modeling_status`;
export const SAVE_PIN_STIMULUS_MODEL = `${SIERRA}/save_pin_stimulus_model`;
export const UPDATE_COMPONENTS = `${SIERRA}/update_components`;
export const SAVE_SPLIT_COMPONENTS = `${SIERRA}/save_split_components`;
export const SAVE_MERGE_COMPONENTS = `${SIERRA}/save_merge_components`;
export const UPDATE_PROBE_PINS = `${SIERRA}/update_probe_pins`;
export const UPDATE_REFERENCE_NETS = `${SIERRA}/update_reference_nets`;
export const UPDATE_GET_REFERENCE_NETS_LOADING = `${SIERRA}/update_get_reference_nets_loading`;
export const UPDATE_INTERFACE_REFERENCE_NETS = `${SIERRA}/update_interface_reference_nets`;
export const FIND_INTERFACE_REFERENCE_NETS = `${SIERRA}/find_interface_reference_nets`;
export const UPDATE_USAGE_APPLY_ALL = `${SIERRA}/update_usage_apply_all`;
export const UPDATE_INTERFACE_AFTER_LIBRARY_UPDATE = `${SIERRA}/update_interface_after_library_update`;
export const CHANGE_PCB_LOADING = `${SIERRA}/change_pcb_loading`;
export const UPDATE_PORT_SETUPS_TO_SERVER = `${SIERRA}/update_port_setups_to_server`;
export const CONNECTOR_STATUS = `${SIERRA}/connector_status`;
export const SAVE_CONNECTOR_GROUP = `${SIERRA}/save_connector_group`;
export const INTERFACE_PCB_REPLACE = `${SIERRA}/interface_pcb_replace`;
export const UPDATE_PCB_REPLACE_INFO = `${SIERRA}/update_pcb_replace_loading`;
export const UPDATE_COMP_PREFIX_STATUS = `${SIERRA}/update_comp_prefix_status`;
export const SAVE_VIRTUAL_COMPONENT = `${SIERRA}/save_virtual_component`;
/** sweeping */
export const UPDATE_SINGLE_EXPERIMENT = `${SIERRA}/update_single_experiment`;
/** interface identification */
export const CHANGE_IDENTIFICATION_CREATE_VISIBLE = `${SIERRA}/change_identification_create_visible`;
export const CREATE_INTERFACE_IDENTIFICATION = `${SIERRA}/creat_interface_identification`;
export const UPDATE_INTERFACE_IDENTIFICATION_MASSAGE = `${SIERRA}/update_interface_identification_massage`;
export const UPDATE_VERIFICATION_SETTING = `${SIERRA}/update_verification_setting`;
/** auto assign IBIS models **/
export const UPDATE_ASSIGN_STATUS = `${SIERRA}/update_assign_status`

export const UPDATE_COMP_SETTING_SETUP = `${SIERRA}/update_comp_setting_setup`;
export const COMPONENT_SETTING_UPDATE = `${SIERRA}/component_setting_update`;
export const UPDATE_CREATE_PANEL_LOADING = `${SIERRA}/update_create_panel_loading`;
export const UPDATE_SETUP_AFTER_CLOSE_SETTINGS = `${SIERRA}/update_setup_after_close_settings`;
export const UPDATE_SETUP_AFTER_CLOSE_PART_LIBRARY = `${SIERRA}/update_setup_after_close_part_library`;
export const UPDATE_COMP_SETTINGS_STATUS = `${SIERRA}/update_comp_settings_status`;
export const UPDATE_PART_REPEATER = `${SIERRA}/update_part_repeater`;
export const CLOSE_PART_REPEATER_PANEL = `${SIERRA}/close_part_repeater_panel`;
export const UPDATE_PART_REPEATER_LOG = `${SIERRA}/update_part_repeater_log`;
export const UPDATE_COMPONENT_PIN_USAGE = `${SIERRA}/update_component_pin_usage`;
export const UPDATE_BUFFER_MODEL_STATUS = `${SIERRA}/update_buffer_model_status`;
export const SAVE_VERIFICATION_CONTENT_TO_SERVER = `${SIERRA}/save_verification_content_to_server`;
export const UPDATE_MEASUREMENT_CONDITIONS = `${SIERRA}/update_measurement_conditions`;
export const UPDATE_MEASURE_CONFIG_STATUS = `${SIERRA}/update_measure_config_status`;
export const UPDATE_DUPLICATION_VERIFICATIONS = `${SIERRA}/update_duplication_verifications`;
export const RECREATE_DUPLICATION_VERIFICATIONS = `${SIERRA}/recreate_duplication_verifications`;
export const START_IDENTIFICATION_CREATING = `${SIERRA}/start_identification_creating`;
export const UPDATE_CREATE_INTERFACE_STATUS = `${SIERRA}/update_create_interface_status`;
export const CREATE_EXPS_BY_TRACE = `${SIERRA}/create_exps_by_trace`;
export const UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO = `${SIERRA}/update_sweep_schematic_pre_layout_info`;
export const UPDATE_INTERFACE_DESIGN_MESSAGE = `${SIERRA}/update_interface_design_message`;
export const UPDATE_PRE_INFO_REPLACE_INFO = `${SIERRA}/update_pre_info_replace_info`;
export const UPDATE_PRE_LAYOUT_REPLACE = `${SIERRA}/update_pre_layout_replace`;