import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import AndesSider from './AndesSider';
import AndesContent from './Content';
import Line from '@/components/Line';
import { changeLeft, changeTop } from "../panelStore/action";
import { closeTabFooter, openTabFooter, cleanTabMonitorStatus } from '../tabMonitor/action';
import { changeLayout, changeViewList } from './store/project/action';
import { VERIFICATION, PCB, RESULT } from './constants';
import { clearCCCStatus } from '../CCC/store/action';
import { isChrome } from '../../services/helper/browser';
import DelConfirm from '@/components/DelConfirm';
import { ANDES } from '@/constants/pageType';
import { setPageType } from '../Login/action';
import SideFooter from '@/components/SideFooter';
import '@/publicCss/style.css';
import '@/publicCss/tree.css';
import '@/publicCss/libraryStyle.css';
import './andes.css';

const { Content } = Layout;

class AndesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 280,
      linePosition: {
        position: "absolute",
        left: 280
      },
      openMenu: true,
      layoutVisible: false,
      tooltipVisible: false,
      viewVisible: false,
      viewToolTip: false,
      chrome: true,
      browserVisible: false
    }
  }

  componentWillMount() {
    this.props.setPageType(ANDES);
    this.props.clearCCCStatus();
  }

  changeWidth(width) {
    this.setState({ width })
    let position = {
      position: "absolute",
      left: width,
      minWidth: 56,
      maxWidth: 1000
    }
    this.setState({
      linePosition: position
    })
    this.props.changeLeft(width);
  }

  componentDidMount = () => {
    this.props.changeLeft(280);
    this.props.changeTop(54);
    this.props.cleanTabMonitorStatus();
    const chrome = isChrome();
    this.setState({
      chrome,
      browserVisible: chrome ? false : true
    })
  }

  componentDidUpdate = (prevProps) => {
    const { pageType } = this.props;
    if (pageType !== prevProps.pageType) {
      this.props.cleanTabMonitorStatus();
    }
  }

  changeSize = (width) => {
    let newWidth = width;
    const prevWidth = this.state.width;
    if (prevWidth === 56 && width > 56) {
      this.setState({
        openMenu: true
      });
      newWidth = 215;
      this.changeWidth(newWidth);
    } else {
      width = width < 215 ? 56 : width;
      width = width > 1000 ? 1000 : width;
      if (width === 56) {
        this.setState({
          openMenu: false
        });
      }
      this.changeWidth(width);
    }

  }

  openMenu = (e) => {
    e.stopPropagation();
    let position = {
      position: "absolute",
      left: 215,
      minWidth: 56,
      maxWidth: 1000,
    }
    this.setState({
      openMenu: true,
      width: 215,
      linePosition: position
    });
  }


  changeTabFooter = () => {
    const { openTabFooter, closeTabFooter, tabVisible } = this.props;
    tabVisible ? closeTabFooter() : openTabFooter();
  }

  layoutClick = (layoutType, singleType) => {
    this.props.changeLayout(layoutType);
    if (singleType) {
      this.viewClick(singleType);
      return;
    }

    const { viewList, currentProjectId, currentProjectVerifications, currentProjectDesigns } = this.props;

    if (!currentProjectId) {
      return;
    }

    if (layoutType !== 3 && viewList.length === 0) {

    } else if (viewList.length === 1) {
      if (viewList[0] === VERIFICATION || viewList[0] === RESULT) {
        let newList = [...viewList];
        if (currentProjectVerifications.length === 0) {
          newList = [];
        }
        if (currentProjectDesigns.length > 0) {
          newList = [...newList, PCB];
          this.props.changeViewList([...newList], PCB);
        } else {
          this.props.changeViewList([...newList]);
        }
      } else {
        let newList = [...viewList]
        if (currentProjectDesigns.length === 0) {
          newList = [];
        }
        if (currentProjectVerifications.length > 0) {
          newList = [...newList, VERIFICATION];
          this.props.changeViewList([...newList], VERIFICATION);
        } else {
          this.props.changeViewList([...newList]);
        }

      }
    }
  }

  viewMouseOver = (e) => {
    this.setState({
      viewToolTip: true
    })
  }

  viewMouseOut = (e) => {
    this.setState({
      viewToolTip: false
    })
  }

  ViewVisibleChange = (visible) => {
    this.setState({
      viewVisible: visible
    });
  }

  viewClick = (type) => {
    const { viewList, layout, currentProjectId } = this.props;

    if (!currentProjectId) {
      return;
    }

    if (type === PCB && (!viewList.includes(PCB) || layout !== 3)) {
      let newView = [PCB];
      this.props.changeViewList([...newView], PCB);
    }

    if (type === 'PCB' && viewList.includes(PCB) && layout === 3) {
      this.props.changeViewList([], PCB);
    }

    if (type === 'setup') {
      if (viewList.includes(VERIFICATION) && layout !== 3) {
        this.props.changeViewList([VERIFICATION], VERIFICATION);
      } else if (viewList.includes(RESULT) && layout !== 3) {
        this.props.changeViewList([RESULT], RESULT);
      } else if (!viewList.includes(VERIFICATION) && !viewList.includes(RESULT)) {
        let newView = [VERIFICATION];
        this.props.changeViewList(newView, VERIFICATION);
      } else {
        if (viewList.includes(VERIFICATION)) {
          let newView = viewList.filter(item => item !== VERIFICATION);
          this.props.changeViewList(newView, VERIFICATION);
        } else {
          let newView = viewList.filter(item => item !== RESULT);
          this.props.changeViewList([...newView], RESULT);
        }
      }
    }
  }

  okConfirm = () => {
    this.setState({
      browserVisible: false
    });
    window.location.href = "./";
  }

  sideFooterActions = () => {
    const { openMenu } = this.state;
    const { viewList, tabVisible, layout, currentProjectId } = this.props;
    return {
      openMenu,
      viewList,
      tabVisible,
      layout,
      currentProjectId,
      layoutClick: this.layoutClick,
      changeTabFooter: this.changeTabFooter
    }
  }

  render = () => {
    const { openMenu, chrome, browserVisible } = this.state;
    const errorMsg = 'Non Chrome browser is not supported in the simulation platform.';

    return (
      <Fragment>
        {chrome ? <Layout className="my-andes-layout">
          <div className='andes-sider'
            style={{ width: this.state.width }}
          >
            <div className='andes-sider-menu' style={{ display: !openMenu ? 'block' : 'none' }}>
              <MenuOutlined className='andes-sider-button' onClick={(e) => this.openMenu(e)} />
            </div>
            <div style={{ display: openMenu ? 'block' : 'none' }} className='andes-sider-menu-tree'>
              <AndesSider width={this.state.width} />
            </div>
            <SideFooter
              actions={this.sideFooterActions()}
            />
          </div>
          <Line
            //Position is used to locate the Line
            position={this.state.linePosition}
            changeWidth={(width) => this.changeWidth(width)}
            width={this.state.width}
            resize={this.changeSize}
          />
          <Content className='aurora-content' style={{ left: this.state.width, overflow: '' }}>
            <AndesContent leftWidth={this.state.width} />
          </Content>
        </Layout> : null}
        {browserVisible ? <DelConfirm
          data={null}
          removeCancel={true}
          deleteConfirm={this.okConfirm}
          message={errorMsg}
        /> : null}
      </Fragment>
    );
  }
}


const mapState = (state) => {
  const { PanelStatus, LoginReducer: { pageType } } = state;
  const { project: { verificationId, layout, viewList, designID, currentProjectId, currentProjectVerifications,
    currentProjectDesigns } } = state.AndesReducer;
  const { defaultTop, defaultLeft } = PanelStatus;
  const { tabVisible } = state.TabMonitorReducer;
  return {
    defaultTop,
    defaultLeft,
    tabVisible,
    verificationId,
    layout,
    viewList,
    designID,
    currentProjectId,
    pageType,
    currentProjectVerifications,
    currentProjectDesigns
  }
}

const mapDispatch = (dispatch) => ({
  openTabFooter() {
    dispatch(openTabFooter())
  },
  closeTabFooter() {
    dispatch(closeTabFooter())
  },
  changeLeft(width) {
    dispatch(changeLeft(width));
  },
  changeTop(top) {
    dispatch(changeTop(top));
  },
  changeLayout(layout) {
    dispatch(changeLayout(layout))
  },
  changeViewList(viewList, viewType) {
    dispatch(changeViewList(viewList, viewType))
  },
  cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus())
  },
  clearCCCStatus() {
    dispatch(clearCCCStatus());
  },
  setPageType(type) {
    dispatch(setPageType(type));
  },
})

export default connect(mapState, mapDispatch)(AndesPage);
