import CeLinkedMap from './CeLinkedMap';

const StrSplitRule = function (delimiter) {
  this.mDelimiter = delimiter || " ";
  this.mReserveCh = null;
  this.mStringNumber = -1;
};


StrSplitRule.prototype.SetDelimiter = function (delimiter) {
  this.mDelimiter = delimiter;
}

StrSplitRule.prototype.AddReservePair = function (left, right) {
  if (this.mReserveCh == null) {
    this.mReserveCh = new CeLinkedMap();
  }
  this.mReserveCh.put(left, right);
}

StrSplitRule.prototype.SetStringNumber = function (strNum) {
  this.mStringNumber = strNum;
}

StrSplitRule.prototype.IsCharReserved = function (ch) {
  if (this.mReserveCh == null) {
    return false;
  }
  return this.mReserveCh.containsKey(ch);
}

StrSplitRule.prototype.GetReserveEndChar = function (ch) {
  if (this.mReserveCh == null) {
    console.log("StrSplitRule.prototype.GetReserveEndChar fails: " + ch);
    return null;
  }

  return this.mReserveCh.getValue(ch);
}

StrSplitRule.prototype.IsDelimitChar = function (ch) {
  return this.mDelimiter.indexOf(ch) >= 0;
}

export default StrSplitRule;