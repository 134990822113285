import React, { Component, createRef } from 'react';
import Panel from '@/components/Panel';
import LayoutData from '@/services/data/LayoutData';
import { connect } from 'react-redux'
import { Checkbox, Table } from 'antd';
import { updateHiddenCompsAction } from '../Stackup_v1/store/actionCreators'
import canvas from '@/services/LayoutCanvas'
import './index.css';
// import  from '../../../../components/EditableTable';

const compColumns = [{
  title: 'Name',
  dataIndex: 'name',
  width: '20%',
  ellipsis: true,
  sorter: (a, b) => a.name.localeCompare(b.name),
}, {
  title: 'Part',
  dataIndex: 'part',
  ellipsis: true,
  render: (text) => (
    <span title={text}>{text}</span>
  ),
  width: '45%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Pins',
  dataIndex: 'pin',
  ellipsis: true,
  sorter: (a, b) => a.pin - b.pin,
  width: '15%',
}, {
  title: 'Visible',
  dataIndex: 'visible',
  ellipsis: true,
  width: '20%',
}];

class ComponentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compNum: '',
      loading: true,
      COMPONENTS: [],
      height: 493
    };
    compColumns[3].render = (text, record) => {
      const { hiddenComps } = this.props;
      return <Checkbox defaultChecked={!hiddenComps.includes(record.name)} onChange={() => this.getHiddenComps(record)} className='layout-display-item-check-box' />
    }

    compColumns[0].render = (text) => {
      const { select } = this.props;
      const comps = [text];
      const canvasObj = { comps };
      return (
        <span className="components-name" onClick={() => select(canvasObj)} title={text}>{text}</span>
      )
    }
  }

  componentDidMount() {
    const { designID, maxHeight } = this.props;
    const _comps = LayoutData.getComponentsList(designID);
    this.setState({
      loading: false,
      compNum: _comps.length,
      COMPONENTS: _comps
    });

    const _height = _comps.length * 38 + 110;
    this.setState({
      height: _height > maxHeight ? maxHeight : _height
    });
  }


  componentDidUpdate(prevProps) {
    const { maxHeight } = this.props;
    if (maxHeight && maxHeight !== prevProps.maxHeight) {
      const { compNum } = this.state;
      const _height = compNum * 38 + 110;
      this.setState({
        height: _height > maxHeight ? maxHeight : _height
      });
    }
  }

  getHiddenComps = (record) => {
    const { hiddenComps, designID } = this.props;
    const layout = canvas.getLayout(designID);
    if (hiddenComps.includes(record.name)) {
      hiddenComps.splice(hiddenComps.indexOf(record.name), 1)
      this.props.updateHiddenComps(hiddenComps);
      layout.deleteHiddenComp(record.name);
    } else {
      hiddenComps.push(record.name);
      this.props.updateHiddenComps(hiddenComps);
      layout.getHiddenComps(hiddenComps);
    }
  }

  getPanelHeight = (height) => {
    this.setState({ height })
  }

  render() {
    const { leftWidth, topWidth, defaultLeft, defaultTop, maxWidth, designID } = this.props;
    const { compNum, COMPONENTS, height } = this.state;
    const scroll = { y: height - 60, x: maxWidth < 500 ? maxWidth - 5 : 495 };
    return (
      <Panel
        title={'Components ' + (compNum && `(${compNum})`)}
        visible
        onCancel={() => this.props.closePanel(designID)}
        width={maxWidth < 500 ? maxWidth : 500}
        position='panel-top-left'
        className='menu-panel aurora-menu-panel-component'
        draggable
        left={leftWidth ? leftWidth : defaultLeft}
        top={topWidth ? topWidth : defaultTop}
        // height={height < 200 ? 200 : height}
        defaultLeft={50}
        minHeight={200}
        minWidth={100}
        maxWidth={maxWidth}
        getPanelHeight={this.getPanelHeight.bind(this)}
        zIndex={10}
        overflow='hidden'
        redirectDom={`aurora-PCB-layout-content-${designID}`}
      >
        <Table
          rowKey='name'
          columns={compColumns}
          scroll={scroll}
          size="small"
          dataSource={COMPONENTS}
          bordered
          virtual
          pagination={false}
          ref={this.tableRef}
        />
      </Panel>
    )
  }
}

const mapState = (state) => {
  const { hiddenComps } = state.StackupReducer
  return {
    hiddenComps
  }
}

const mapDispatch = (dispatch) => ({
  updateHiddenComps(hiddenComps) {
    dispatch(updateHiddenCompsAction(hiddenComps))
  }
})

export default connect(mapState, mapDispatch)(ComponentsTable);