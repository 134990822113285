import { DESIGN_SUCCESS } from '@/constants/designCategory';
import { PCB, PACKAGE, PCB_PRE_LAYOUT } from '@/constants/treeConstants';
class ProjectDesigns {
  constructor() {
    this.project = new Map(); // key - projectId, value - designs

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.getDesignsLength = this.getDesignsLength.bind(this);
    this.delete = this.delete.bind(this);
  };

  set(id, designs) {
    this.project.set(id, designs);
  };

  get(id) {
    return this.project.get(id).map(item => item.id) || [];
  };

  getDesigns(id) {
    return this.project.get(id) || [];
  };

  getPCBs(id) {
    return this.project.get(id).filter(item => item.dataType === PCB) || [];
  }

  getPackages(id) {
    return this.project.get(id).filter(item => item.dataType === PACKAGE) || [];
  }

  getFirstId(id) {
    return this.getDesignsLength(id) ? this.get(id)[0] : null;
  };

  getDesignsLength(id) {
    return this.get(id).length;
  };

  getAvailableDesigns(id) {
    return this.getDesigns(id).filter(item => item.category === DESIGN_SUCCESS) || []
  };

  getAvailablePCBs(id) {
    return this.getDesigns(id).filter(item => item.category === DESIGN_SUCCESS).filter(item => item.dataType === PCB || item.dataType === PCB_PRE_LAYOUT) || []
  };

  getAvailablePostLayouts(id) {
    return this.getDesigns(id).filter(item => item.category === DESIGN_SUCCESS).filter(item => item.dataType === PCB) || []
  };

  getAvailablePackages(id) {
    return this.getDesigns(id).filter(item => item.category === DESIGN_SUCCESS).filter(item => item.dataType === PACKAGE) || []
  };

  getAvailablePreLayouts(id) {
    return this.getDesigns(id).filter(item => item.category === DESIGN_SUCCESS).filter(item => item.dataType === PCB_PRE_LAYOUT) || []
  }

  getAvailableDesignIds(id) {
    return this.getAvailableDesigns(id).map(item => item.id) || []
  };

  getAvailablePCBIds(id) {
    return this.getAvailablePCBs(id).map(item => item.id) || []
  };

  getAvailableDesignsLength(id) {
    return this.getAvailableDesignIds(id).length
  };

  getAvailableDesignsFirstId(id) {
    return this.getAvailableDesignsLength(id) ? this.getAvailableDesignIds(id)[0] : null;
  };

  getAvailablePCBsFirstId(id) {
    return this.getAvailablePCBs(id).length ? this.getAvailablePCBIds(id)[0] : null;
  };

  getAvailableDesignsVendor(id) {
    let designs = this.getAvailableDesigns(id) || [];
    designs = designs.filter(item => item.dataType !== PACKAGE);
    return designs.length ? designs[0].vendor : null
  };

  getDesignExist(id, designId) {
    return this.getAvailableDesignIds(id).includes(designId) ? true : false
  }

  delete(id) {
    this.project.delete(id);
  };

  addDesign(id, design) {
    let designs = this.getDesigns(id)
    if (!designs.find(item => item.id === design.id)) {
      designs.push(design)
    }
    this.set(id, designs)
  }
};

const projectDesigns = new ProjectDesigns();
export default projectDesigns;
