import { IO_SIM, SPICE_CARD_TEMPLATE } from "../../../constants/treeConstants";

export function getTopBarWidth(viewList) {
  const view = viewList.filter(item => [SPICE_CARD_TEMPLATE, IO_SIM].includes(item));
  if (view.length) {
    const topBar = document.getElementById('himalayas-top-bar');
    const topBarLeft = document.getElementById('himalayas-left-top-bar');

    const topBarLeftLength = topBarLeft && topBarLeft.children && topBarLeft.children.length ? topBarLeft.children.length : 0
    let leftWidth = 0;
    if (topBarLeft && topBarLeft.children && topBarLeft.children.length) {
      for (let item of topBarLeft.children) {
        const itemWidth = item.offsetWidth + 6;
        leftWidth += itemWidth;
      }
    }

    const topBarRight = document.getElementById('himalayas-right-top-bar')
    const topBarRightLength = topBarRight && topBarRight.children && topBarRight.children.length ? topBarRight.children.length : 0

    let rightWidth = 0;
    if (topBarRight && topBarRight.children && topBarRight.children.length) {
      for (let item of topBarRight.children) {
        const itemWidth = item.offsetWidth + 6;
        rightWidth += itemWidth;
      }
    }
    const width = leftWidth + rightWidth;

    let maxWidth = 0;
    const children = topBarLeftLength + topBarRightLength;
    if (children > 0) {
      maxWidth = children * 114
    }
    //hide title only display icon
    if (topBar.className.match("hide")) {
      return maxWidth || 480
    }

    if (!isNaN(width)) {
      return width;
    }

    switch (view[0]) {
      case SPICE_CARD_TEMPLATE:
        return 480;
      case IO_SIM:
        return 490;
      default: return 480;
    }
  }
  return 200;
}
