class IRVia {
  constructor(arr, domain) {
    this.net = arr[0].replace(/\"/g, "");
    this.X = arr[1];
    this.Y = arr[2];
    this.fromLayer = arr[3];
    this.toLayer = arr[4];
    this.I = arr[5];
    this.R = arr[6];
    this.components = `(${arr[1]}, ${arr[2]} ${arr[3]} -> ${arr[4]})`;
    this.domain = domain;
  }
}

class IRPin {
  constructor(arr, domain, voltageDrop) {
    this.components = `${arr[0]} - ${arr[1]}`;
    // this.pin = arr[1];
    this.net = arr[2].replace(/\"/g, "");
    this.X = arr[3];
    this.Y = arr[4];
    this.I = arr[5];
    this.V = (arr[6] * 1000).toFixed(2);
    this.domain = domain;
    this.voltageDrop = voltageDrop;
    this.pin = arr[1];
  }
}

class IRBump {
  constructor(arr, domain, index, voltageDrop) {
    this.components = `${arr[0]}-${arr[1]}-${index}`;
    this.bump = `${arr[0]} - ${arr[1]}`;
    this.net = arr[2].replace(/\"/g, "");
    this.X = arr[3];
    this.Y = arr[4];
    this.I = arr[5];
    this.V = (arr[6] * 1000).toFixed(2);
    this.domain = domain;
    this.voltageDrop = voltageDrop;
    this.pin = arr[1];
  }
}


export { IRVia, IRPin, IRBump }
