
import { newNanoId } from '../../helper/idHelper';

class ConnectorPCB {
  constructor(params = {}) {
    const { pcb, components, init } = params;
    this.pcb = pcb || "";
    this.key = newNanoId();
    if (init) {
      this.components = [new ConnectorComp()];
    } else {
      this.components = components || [];
    }
  }
}

class ConnectorComp {
  constructor(params = {}) {
    const { name } = params;
    this.name = name || "";
    this.prev = [];
    this.next = [];
  }
}

class PinMapRow {
  constructor(params = {}) {
    const { partNumber, libraryId, libraryName, components, nets, pinTable, customPinMap } = params;
    this.id = newNanoId();
    this.partNumber = partNumber || "";
    this.libraryId = libraryId || "";
    this.libraryName = libraryName || "";
    this.components = components || [];
    this.nets = nets || [];
    this.pinTable = pinTable || [];
    this.customPinMap = customPinMap || false;
  }
}

const SIWAVE = 'SIWave', POWERDC = 'PowerDC';
class DCExtraction {
  constructor(params = {}) {
    const {
      // siwave
      customPlaneVoidMeshingDCSetting = false, siwMinVoidArea, siwMinPlaneSize, siwSnapVertice, siwInitMeshSize, siwContactRadius, siwMeshVias = true, siwNumViaSides, clipping = true, auroraAedb = false, simulator, ymlLibraryId,
      // power dc
      pwsiTemperature = '25', pwsiAccuracyMode = false, treatPadAsShape = true, connectObject = true, checkArea = false, area = '0',
      checkAutoAddPinSize = false, checkAutoAddNodesOnPads = false, nodeGapForPad = '0.0508', minNodeNumForPad = '6',
      enable3DVia = false, mesh3DViaSize = '0.32', enableSolid = false,
      maxEdgeLength = "", maxEdgeCheck = false,
      meshDisplay = false,
      simplifyGeometry = true, coarseMesh = false,
      enableAdaptiveMesh = false, meshIterationNumber = '5', topLargestPowerLossElements = '1',
      siwAdaptiveRefinement = true, siwMinPasses = '1', siwMaxPasses = '20', siwPercentRefinement = '20', siwEnergeErr = '0.2', siwRefineVias = false,
      // timeout option
      licWaitSwitch = false, licWait = '1', licWaitUnit = 'min', timeoutSwitch = false, timeoutHours = '72', timeoutMinutes = '0'
    } = params;

    this.simulator = simulator || SIWAVE;

    if (!simulator || simulator === SIWAVE) {
      this.customPlaneVoidMeshingDCSetting = customPlaneVoidMeshingDCSetting;
      this.siwMinVoidArea = siwMinVoidArea || '0.001';
      this.siwMinPlaneSize = siwMinPlaneSize || '6.25e-6';
      this.siwSnapVertice = siwSnapVertice || '0.1';
      this.siwInitMeshSize = siwInitMeshSize || '2.5';
      this.siwContactRadius = siwContactRadius || '0.1';
      this.siwMeshVias = siwMeshVias;
      this.siwNumViaSides = siwNumViaSides || 8;
      this.clipping = clipping;
      this.auroraAedb = auroraAedb;
      this.ymlLibraryId = ymlLibraryId || '';

      // siwave adaptive mesh
      this.siwAdaptiveRefinement = siwAdaptiveRefinement;
      this.siwMinPasses = siwMinPasses;
      this.siwMaxPasses = siwMaxPasses;
      this.siwPercentRefinement = siwPercentRefinement;
      this.siwEnergeErr = siwEnergeErr;
      this.siwRefineVias = siwRefineVias;
    }

    if (simulator === POWERDC) {
      this.pwsiTemperature = pwsiTemperature;
      this.pwsiAccuracyMode = pwsiAccuracyMode;

      //treat pad
      this.treatPadAsShape = treatPadAsShape;
      this.connectObject = connectObject;
      this.checkArea = checkArea;
      this.area = area;

      // pin size
      this.checkAutoAddNodesOnPads = checkAutoAddNodesOnPads;
      this.checkAutoAddPinSize = checkAutoAddPinSize;
      this.nodeGapForPad = nodeGapForPad;
      this.minNodeNumForPad = minNodeNumForPad;

      // 3d via model
      this.enable3DVia = enable3DVia;
      this.mesh3DViaSize = mesh3DViaSize;
      this.enableSolid = enableSolid;

      // max edge length
      this.maxEdgeLength = maxEdgeLength;
      this.maxEdgeCheck = maxEdgeCheck;

      // mesh display
      this.meshDisplay = meshDisplay;

      // mesh quality and coarse option
      this.simplifyGeometry = simplifyGeometry;
      this.coarseMesh = coarseMesh;

      // adaptive mesh
      this.enableAdaptiveMesh = enableAdaptiveMesh;
      this.meshIterationNumber = meshIterationNumber;
      this.topLargestPowerLossElements = topLargestPowerLossElements;

      // xml option file
      this.ymlLibraryId = ymlLibraryId || '';
    }

    this.licWaitSwitch = licWaitSwitch
    this.licWait = licWait
    this.licWaitUnit = licWaitUnit
    this.timeoutSwitch = timeoutSwitch
    this.timeoutHours = timeoutHours
    this.timeoutMinutes = timeoutMinutes
  }
}


export {
  ConnectorPCB,
  ConnectorComp,
  PinMapRow,
  DCExtraction,
  SIWAVE,
  POWERDC
}