import {
  notificationUserEdit,
  getUserNotificationDetail,
  getSenderEmail,
  editSenderEmail,
  getNotificationProducts
} from '../api/emailNotification/userNotificationSettingCtrl';
import apiProcess from '../api/utility';

export function getUserNotificationDetailPromise() {
  return apiProcess(getUserNotificationDetail);
}

export function notificationUserEditPromise(param) {
  return apiProcess(notificationUserEdit, param);
}

export function getSenderEmailPromise() {
  return apiProcess(getSenderEmail);
}

export function editSenderEmailPromise(param) {
  return apiProcess(editSenderEmail, param);
}

export function getAllowNotificationProducts() {
  return apiProcess(getNotificationProducts);
}