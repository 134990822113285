import axios from 'axios';

const http = axios.create({
  baseURL: '/api/v3'
});

http.updateAuth = (token) => {
  http.defaults.headers.common['Authorization'] = token ? ('Bearer ' + token) : '';
}

http.updatePageType = (type) => {
  http.defaults.headers.common['Page-Type'] = type ? type : '';
}

let requestInterceptor = null;
http.setRequestInterceptor = (request, error) => {
  http.removeRequestInterceptor();
  requestInterceptor = http.interceptors.request.use(request, error);
}

http.removeRequestInterceptor = () => {
  if (requestInterceptor) {
    axios.interceptors.request.eject(requestInterceptor);
    requestInterceptor = null;
  }
}

http.interceptors.response.use(response => {
  if (response.status === 200 || response.status === 201 || response.status === 206) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}, error => {
  return Promise.reject(error);
})

export default http;