import React, { Component, Suspense } from 'react';
import { Spin } from 'antd';
import Line from '@/components/Line';
import { getReSizeLeftRight } from '@/services/helper/documentReSize';

class LR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 500,
      linePosition: {
        position: "absolute",
        left: 500,
      },
      contentResize: true,
      loading: true
    }
  }

  componentDidUpdate(prevProps) {
    const { siderWidth } = this.props;
    const bodyWidth = document.body.clientWidth;

    if (siderWidth && prevProps.siderWidth !== siderWidth) {
      let { width } = this.state;
      if (width < 20) {
        width = 20;
      }
      const maxWidth = bodyWidth - 20 - siderWidth;
      if (width > maxWidth) {
        width = maxWidth;
      }
      let position = {
        position: "absolute",
        left: width / 2,
        minWidth: 20,
        maxWidth: maxWidth
      }
      const { contentResize } = this.state;
      this.setState({
        width: width / 2,
        linePosition: position,
        contentResize: !contentResize
      });
      this.props.contentSetupResize && this.props.contentSetupResize(!contentResize);
    }
    const { tabVisible, tabSelectKeys } = this.props;

    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props._getCCCMsg();
    }
  }

  changeWidth = (width) => {
    const bodyWidth = document.body.clientWidth;
    const { siderWidth } = this.props;
    const maxWidth = bodyWidth - 20 - siderWidth;
    if (width < 20) {
      width = 20
    } else if (width > maxWidth) {
      width = maxWidth;
    }
    if (this._isMounted) {
      const { contentResize } = this.state;
      this.setState({
        width,
        linePosition: {
          position: "absolute",
          left: width,
          minWidth: 20,
          maxWidth: maxWidth
        },
        contentResize: !contentResize
      });
      this.props.contentSetupResize && this.props.contentSetupResize();
      this.props.topBarResize && this.props.topBarResize();
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this._isMounted = true;
    this.screenChange();
    this.parentEle = document.getElementsByClassName('aurora-content')[0];

    if (this.parentEle) {
      const splitSize = this.props.splitSize ? this.props.splitSize : 2;
      const width = this.parentEle.offsetWidth / splitSize;
      this.setState({
        width: width,
        linePosition: {
          position: "absolute",
          left: width,
        },
      });
    }
    this.setState({
      loading: false
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getReSize();
  }

  getReSize = () => {
    const setting = getReSizeLeftRight("aurora-content", this.props.splitSize);
    if (this._isMounted) {
      this.setState({
        width: setting.width,
        linePosition: { ...setting.linePosition }
      });
    }
  }

  render() {
    const { width, linePosition, loading } = this.state;
    const { leftClassName, lineClassName } = this.props;
    return (
      <Spin size='large' spinning={loading}>
        <div className={leftClassName}
          style={{ width: width }}
        >
          <Suspense fallback={null}>
            {this.props.getLeft(width)}
          </Suspense>
        </div>
        <Line
          position={linePosition}
          changeWidth={(width) => this.changeWidth(width)}
          width={width}
          resize={(width) => this.changeWidth(width)}
          className={lineClassName || ""}
          showLine={true}
        />
        <div className='aurora-content-right'
          style={{ left: width }}
        >
          <Suspense fallback={null}>
            {this.props.getRight(width)}
          </Suspense>
        </div>
      </Spin>
    )
  }
};

export default LR;