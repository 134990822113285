import React, { PureComponent } from 'react'
import profileTable from './profileContent';
import './style.css'

class Profile extends PureComponent {
  render() {
    const { profileLog } = this.props;
    return (
      <div className='aurora-profile-monitor'>
        {profileTable(profileLog)}
      </div>
    )
  }
}

export default Profile;