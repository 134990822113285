import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Checkbox, Input, Radio } from 'antd';
import Panel from '../../../components/Panel';
import SPICEFileSelect from './components/SPICEFileSelect';
import SubcktNodeSelect from './components/SubcktNodeSelect';
import { SPICE } from '../../../constants/libraryConstants';
import {
  getNodeSelectOption,
  onlyModelTitle,
  drawRxCpadModelHelper,
  getDrawer,
  getValueAndUnit,
  getSubcktByPairInfo,
  handleSelectSubckt,
  handleChangeSpiceType,
  handleSelectFile,
  handleSelectNode,
  getModelKey,
  getFileTreeData
} from '../../../services/Andes_v2/simulation';
import { numberCheck } from '../../../services/helper/dataProcess';

/**
 * RxCpadModel: Spice / value
 */
class RxCpadModel extends Component {
  constructor() {
    super();
    this.state = {
      applyAll: false,
      fileTreeData: [],
      subcktList: [],
      subckt: {},
      spiceType: 'share',
      nodeVisible: '',
      model: {
        type: 'value',
        files: [],
        pairs: [],
        pinModels: []
      },
      editInputInfo: {}
    };
    this.dialogRoot = document.getElementById('root');
  }
  async componentDidMount() {
    const { model } = this.props;
    const { drawer, canvas } = getDrawer('andes-v2-hspice-simulation-rxCapd-model-canvas', null, { left: 1.5, top: 1.5 });

    this.drawer = drawer;
    this.canvas = canvas;
    drawRxCpadModelHelper({ drawer, canvas });
    const spiceType = model.files.length > 1 ? 'only' : 'share';
    this.setState({
      model: JSON.parse(JSON.stringify(model)), spiceType,
      fileTreeData: await getFileTreeData([SPICE], model.files)
    }, () => {
      this.isMountedFlag = true;
    })
  }

  async componentDidUpdate(prevProps) {
    const { libraryStatus, model } = this.props;
    if (prevProps.libraryStatus !== libraryStatus) {
      this.setState({
        fileTreeData: await getFileTreeData([SPICE], model.files)
      })
    }
  }

  componentWillUnmount() {
    this.isMountedFlag = false;
  }

  closeModal = () => {
    const { model, applyAll } = this.state;
    if (this.isMountedFlag) {
      this.props.closeModal(model, applyAll);
    }
  }

  changeModelType = (value) => {
    const { model } = this.state;
    this.setState({ model: { ...model, type: value } })
  }

  changeSpiceType = (type) => {
    const { model, subcktList: prevSubcktList } = this.state;
    const { files, pairs, subcktList } = handleChangeSpiceType(model, type, prevSubcktList);
    this.setState({ spiceType: type, model: { ...model, files, pairs }, subcktList })
  }

  selectModelFile = (file, fileIndex) => {
    const { model, subcktList } = this.state;
    const { files, pairs } = handleSelectFile('rxCpadModel', model, file, fileIndex);
    this.setState({ model: { ...model, files, pairs }, subcktList: { ...subcktList, [fileIndex]: {} } });
  }

  selectSubckt = (subckt, modelKey, file) => {
    const { model, subcktList } = this.state;
    const { files, pairs } = handleSelectSubckt('rxCpadModel', model, subckt, modelKey, file);
    const _file = files.find(item => item.modelKey === modelKey) || {};
    this.setState({ model: { ...model, files, pairs }, subcktList: { ...subcktList, [modelKey]: { ...subckt, ..._file } } })
  }

  selectNode = (node, pairIndex, modelKey) => {
    const { model, spiceType } = this.state;
    const pairs = handleSelectNode(model, spiceType, node, pairIndex, modelKey);
    this.setState({ model: { ...model, pairs }, nodeVisible: '' })
  }

  changeEditInputInfo = (value) => {
    const { editInputInfo } = this.state;
    this.setState({ editInputInfo: { ...editInputInfo, value } })
  }

  editInputFocus = (item) => {
    const [value, unit] = getValueAndUnit(item.cpadValue);
    this.setState({ editInputInfo: { ...item, value, unit } })
  }

  editInputBlur = () => {
    const { editInputInfo: { value, pin, unit }, model } = this.state;
    let _model = null;
    if (!numberCheck(value)) {
      const _pinModels = [...model.pinModels].map(item => {
        if (item.pin === pin) {
          return { ...item, cpadValue: `${value} ${unit}` }
        }
        return item;
      })
      _model = { ...model, pinModels: _pinModels }
    }

    this.setState({ model: _model || model, editInputInfo: {} })
  }

  applyChange = (e) => {
    this.setState({ applyAll: e.target.checked })
  }

  clearNodeVisible = () => {
    this.setState({ nodeVisible: "" })
  }

  setNodeVisible = async (visible, pair, pairIndex) => {
    const { subcktList, model: { files }, spiceType } = this.state;
    const _modelKey = getModelKey({ spiceType, files, modelKey: pair.modelKey, pairIndex });
    const fileInfo = pair.node ? pair : files.length > 1 ? files.find(file => file.modelKey === _modelKey) : files[0];
    if (visible && !Object.keys(subcktList[_modelKey] || {}).length && fileInfo) {
      const subcktInfo = await getSubcktByPairInfo(fileInfo);
      this.setState({ subcktList: { ...subcktList, [_modelKey]: subcktInfo } });
    }
    this.setState({ nodeVisible: visible })
  }

  getNodeOption = (spiceType, sharePairs, onlyPairs, modelKey, pairIndex) => {
    const { subcktList, model: { files } } = this.state;
    const _modelKey = getModelKey({ spiceType, files, modelKey, pairIndex });
    const pairs = spiceType === 'only' ? onlyPairs : sharePairs;
    const subckt = subcktList[_modelKey];
    return getNodeSelectOption('rxCpadModel', pairs, subckt)
  }

  updateFileTreeData = (children) => {
    const { fileTreeData } = this.state;
    this.setState({ fileTreeData: fileTreeData.concat(children) })
  }

  render() {
    const { applyAll, fileTreeData, spiceType, nodeVisible, model: { files = [], pairs = [], pinModels, type }, editInputInfo } = this.state;
    const { signalName } = this.props;
    const content = (
      <Panel
        className='component-CMC-model-panel'
        position='panel-center-left'
        title='Rx C_Pad Model'
        zIndex={2000}
        onCancel={() => this.closeModal()}
        width={600}
        draggable
        minHeight={200}
      >
        <div className='andes-v2-hspice-simulation-model-content'>
          {/* Model Type */}
          <div className='andes-v2-hspice-simulation-flex'>
            <span className='andes-v2-hspice-simulation-title'>Model Type</span>
            <Radio.Group onChange={(e) => this.changeModelType(e.target.value)} value={type}>
              <Radio value="value">Value</Radio>
              <Radio value={SPICE}>SPICE</Radio>
            </Radio.Group>
          </div>
          {type !== SPICE ? null
            : files.length !== 0 ? files.map((file, index) => <div key={`${file.libraryId}-${index}` || `rx-model-${index}`}>
              <SPICEFileSelect
                fileTreeData={fileTreeData}
                selectModelFile={(file) => this.selectModelFile(file, file.modelKey || index)}
                selectSubckt={(subckt) => this.selectSubckt(subckt, file.modelKey, file)}
                file={file}
                spiceType={spiceType}
                changeSpiceType={this.changeSpiceType}
                modelFileTitle={spiceType === 'only' ? onlyModelTitle[index] : "Model File"}
                updateFileTreeData={this.updateFileTreeData}
              />
            </div>) : <SPICEFileSelect
              fileTreeData={fileTreeData}
              selectModelFile={(file) => this.selectModelFile(file, -1)}
              selectSubckt={(subckt) => this.selectSubckt(subckt, -1)}
              file={{}}
              spiceType={spiceType}
              changeSpiceType={this.changeSpiceType}
              updateFileTreeData={this.updateFileTreeData}
            />}
          <div className="andes-v2-hspice-simulation-rxCapd-model-canvas-box">
            {type === SPICE ? <>
              {pairs.map((item, index) => (<div key={`rxCpad-model-${item.pin}`} className='andes-v2-hspice-simulation-rxCapd-model-node'>
                <SubcktNodeSelect
                  id={`rxCpad-model-subckt-node-popover-${item.pin}`}
                  visible={nodeVisible === `rxCpad-model-subckt-node-popover-${item.pin}`}
                  nodeClassName="andes-v2-hspice-simulation-rxCpad-node-box"
                  displayPin={item.pin}
                  nodeValue={item.node}
                  nodeList={this.getNodeOption(spiceType, pairs, [item], item.modelKey, index)}
                  selectNode={(node) => this.selectNode(node, index, item.modelKey)}
                  clickNode={() => this.setNodeVisible(`rxCpad-model-subckt-node-popover-${item.pin}`, item, index)}
                  closeNodelSelect={() => this.clearNodeVisible()}
                />
              </div>))}
            </> : <>{pinModels.map((item, index) => (<div key={`rxCpad-model-${item.pin}`} className='andes-v2-hspice-simulation-rxCapd-model-node'>
              <Input
                value={editInputInfo.pin === item.pin ? editInputInfo.value : getValueAndUnit(item.cpadValue)[0]}
                className='andes-v2-hspice-simulation-z0-input'
                suffix={getValueAndUnit(item.cpadValue)[1]}
                onFocus={() => this.editInputFocus(item)}
                onChange={(e) => this.changeEditInputInfo(e.target.value)}
                onBlur={() => this.editInputBlur()}
                onPressEnter={(e) => e.target.blur()}
              />
            </div>))}</>}
            <span className="andes-v2-hspice-simulation-rxCapd-model-signal-name andes-v2-hspice-no-wrap">{signalName}</span>
            <canvas id="andes-v2-hspice-simulation-rxCapd-model-canvas" width="600" height="260"></canvas>
          </div>
          {/* modal - apply all */}
          {<div className="ads-model-apply-all-content">
            <span>Apply setting to all Signal</span>
            <Checkbox
              checked={applyAll}
              onChange={(e) => this.applyChange(e)}
            />
          </div>}
        </div >
      </Panel >
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default RxCpadModel;