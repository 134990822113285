export default function monitorLog(log, logType, addColor = false) {
  let _log = [...log];

  if (logType === "string") {
    _log.forEach(item => {
      item = filterLogItem(item);
    });
    return _log.filter(item => item !== '');

  } else {
    _log.forEach(item => {
      item.log = filterLogItem(item.log);
      //add color className
      if (addColor && item.log) {
        item.colorClassName = addColorClassName(item.log);
      }
    });
    return _log.filter(item => item.log !== '');
  }
}

function filterLogItem(log) {
  // Remove blank log
  log = log.replace(/(^\s*)|(\s*$)/g, "");
  let regC = /[\u4e00-\u9fa5]+/g;
  if (regC.test(log)) {
    log = '';
  }
  if (log.search(/\[Progress\]/g) > -1) {
    log = '';
  }
  return log;
}

const FILTER_ERROR_REG = /(check errors)|(file failed)/i;
const FILTER_WARNING_REG = /(not found connectable PCB)|(Not found extraction result)|(Model dost not exist,)/i;

function addColorClassName(log) {
  const filter = log.match(FILTER_ERROR_REG);

  if (!filter && log.match(/(error)|(failed)|(failure)|(fail)/i)) {
    return "error";
  }
  const filterWarn = log.match(FILTER_WARNING_REG);
  if (!filterWarn && log.match(/(warning)|(warn)|(not exist)|(not found)|(not find)/i)) {
    return "warning";
  }

  if (log.match("Extract the models of the PCBs")) {
    return "bold";
  }
  return null;
}

function updateLogFormat(log) {
  let logs = [];
  if (typeof log !== "string") {
    return [log]
  }
  const lines = log.match(/[^\r\n]+/g);
  if (!lines || !lines.length) {
    return [log]
  }
  for (let mesItem of lines) {
    logs.push({
      log: mesItem,
      colorClassName: mesItem ? addColorClassName(mesItem) : null
    })
  }
  return logs;
}

export {
  updateLogFormat
}