import http from "../http";

export function getTree(verificationId) {
  return http.get(`/Cascade/verification/${verificationId}/powertree`);
}

export function updateTree({ verificationId, params }) {
  return http.post(`/Cascade/update/powertree`, {
    verificationId,
    ...params
  })
}

export function deleteTree({ verificationId, ids }) {
  return http.post(`/Cascade/delete/powertree`, {
    verificationId,
    ids
  })
}

export function runTree(verificationId) {
  return http.post(`/Cascade/powertree/simulation?verificationId=${verificationId}`)
}

export function uploadTreeFile({ verificationId, file }) {
  const formData = new FormData();
  formData.append('file', file);
  return http.post(`/Cascade/powertree/spec/import?verificationId=${verificationId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export function treeResult(verificationId) {
  return http.get(`/Cascade/powertree/result?verificationId=${verificationId}`);
}

export function getPowerTreeSetting(verificationId) {
  return http.get(`/Cascade/powertree/setup?verificationId=${verificationId}`);
}

export function savePowerTreeSetting({ verificationId, setup }) {
  return http.post(`/Cascade/update/powertree/setup`, {
    verificationId,
    setup
  })
}

export function multiTreeResult(verificationId) {
  return http.get(`/Cascade/multipcb/powertree/result?verificationId=${verificationId}`);
}

export function updateTreeSingle({ verificationId, params }) {
  return http.post(`/Cascade/update/powertree/single`, {
    verificationId,
    ...params
  })
}

export function getPowerTreeSpiceStatus(verificationId) {
  return http.get(`/Cascade/spice/simulation/status?verificationId=${verificationId}`)
}

export function multiTreeSpiceResult(verificationId) {
  return http.get(`/Cascade/multi-pcb/spice/result?verificationId=${verificationId}`);
}

export function powerTreeSpiceResult(verificationId) {
  return http.get(`/Cascade/power-tree/spice/result?verificationId=${verificationId}`);
}