import { END_TO_END_INDEX, PROJECTS_INDEX } from "..";

function updateEndToEndChildrenChannels({
  channels,
  endToEndId,
  treeItems,
  projectId
}) {
  const projectIndex = treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
  if (projectIndex < 0) {
    return treeItems;
  }
  const endToEndIndex = treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children.findIndex(item => item.id === endToEndId);
  if (endToEndIndex < 0) {
    return treeItems;
  }
  //const result = treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children[endToEndIndex].children[0];

  treeItems[PROJECTS_INDEX].children[projectIndex].children[END_TO_END_INDEX].children[endToEndIndex].children = [
   /*  { ...result }, */
    ...channels
  ];
  return treeItems;
}

export {
  updateEndToEndChildrenChannels
}