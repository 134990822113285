import { getSharedByMeListPromise, getSharedToMeListPromise } from './shareCtrl';
import { SHARED_WITH_ME, SHARED_BY_ME } from './constants';
import treeItem, { treeDataType } from '../tree/treeItem';
import { aProjectChild as rockyProjectChild } from '../Rocky/helper/projectTree';
import { ROCKY } from '../../constants/pageType';
import { PROJECT } from '../../pages/PDN/constants';

function getShareTree() {
  const tree = [{
    name: 'Shared with me',
    key: SHARED_WITH_ME,
    nodeClass: 'expand-icon-large',
    dataType: SHARED_WITH_ME
  }, {
    name: 'Shared by me',
    key: SHARED_BY_ME,
    nodeClass: 'expand-icon-large',
    dataType: SHARED_BY_ME
  }];

  return tree;
};

async function getShareTreeList(product) {
  function projectChildTree({ id, dataTypePrefix }) {
    // id - projectId
    switch (product) {
      case ROCKY:
        return rockyProjectChild(id, dataTypePrefix);
      default: return;
    }
  };

  function sharedProjectItem(d, dataTypePrefix) {
    if (!d.projectId) {
      throw new Error('Illegal project!');
    }

    let item = new treeItem({
      name: d.projectName || "Project",
      key: treeDataType(PROJECT, dataTypePrefix) + '-' + d.projectId,
      dataType: treeDataType(PROJECT, dataTypePrefix),
      id: d.projectId,
      libraryIds: d.libraryIds || [],
      owner: {
        email: d.owner ? d.owner.email : "",
        id: d.owner ? d.owner.id : "",
        name: d.owner ? d.owner.name : ""
      },
      users: d.users || []
    });
    item.children = projectChildTree({ id: d.projectId, dataTypePrefix });
    return item;
  };

  let treeData = [...getShareTree()];
  try {
    const sharedByMeChildren = (await getSharedByMeListPromise(product)).map(d => sharedProjectItem(d, SHARED_BY_ME));
    const sharedToMeChildren = (await getSharedToMeListPromise(product)).map(d => sharedProjectItem(d, SHARED_WITH_ME));
    treeData.map((shareData, shareIndex) => {
      if (shareIndex === 0) {
        // shared to me
        shareData.children = sharedToMeChildren;
      } else if (shareIndex === 1) {
        // shared by me
        shareData.children = sharedByMeChildren;
      };
      return shareData;
    });
    return treeData;
  } catch (error) {
    console.error(error);
    return treeData;
  }
};

export default getShareTree;
export { getShareTreeList };