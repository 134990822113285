export const UPLOAD_PROGRESS_CHANGE = 'SIERRA/upload_progress_changed';
export const CLOSE_UPLOAD_PROGRESS_MODAL = 'SIERRA/close_upload_progress_modal';

export const TRANSLATION_SUCCESS = 'SIERRA/translation_success';
export const TRANSLATION_RUNNING = 'SIERRA/translation_running';
export const TRANSLATION_FAILED = 'SIERRA/translation_failed';

export const GET_WORK_FLOWS = 'SIERRA/get_work_flows';
export const TRANSLATION_MONITOR = 'SIERRA/translation_monitor';
export const GET_TRANSLATION_MONITOR = 'SIERRA/get_translation_monitor';
export const UPLOAD_PROJECT_ID = 'SIERRA/upload_project_id';
export const ADD_MSG = 'SIERRA/add_msg';
export const UPDATE_UPLOAD_WORKFLOW = 'SIERRA/update_upload_workflow';

export const SAVE_CURRENT_PCB_KEY = 'SIERRA/save_current_pcb_key';

export const TRANSLATION_DEBUG = 'SIERRA/translation_debug';
export const CANCEL_UPLOAD_WORKFLOW = 'SIERRA/cancel_upload_workflow';

export const UPDATE_COMPRESS_PROGRESS = 'SIERRA/update_compress_progress';

export const UPLOAD_PCB_START_UPDATE_STATUS = `SIERRA/upload_pcb_start_update_status`;
export const TRANSLATION_FLOW = `SIERRA/translation_flow`;
export const CLEAR_REPLACE_PCB_INFO = `SIERRA/clear_replace_pcb_info`;
export const CHANGE_UPLOAD_DISABLE_STATUS = `SIERRA/change_upload_disable_status`;
