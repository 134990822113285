import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import Table from '@/components/EditableTable';
import { numberCheck } from "@/services/helper/dataProcess";
import { CURRENT_PWL_TABLE } from '../../../../services/Cascade/constants';
import { uploadPWLTableFile } from '../../../../services/Cascade/Impedance';
import TimeSetting from './TimeSetting';
import '../index.css';

const CurrentColumns = [
  {
    title: 'Time (ns)',
    dataIndex: 'time',
    width: '50%'
  }, {
    title: 'Current (A)',
    dataIndex: 'current',
    width: '50%'
  }
]

class PWLTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPoints: []
    };
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initColumns();
    this.initState();
  }

  initState = () => {
    const { dieCurrent, maxStopTime } = this.props;
    const currentPoints = dieCurrent && dieCurrent.currentPoints ? dieCurrent.currentPoints : []
    const calcStopTime = currentPoints.length > 0 ? currentPoints[currentPoints.length - 1].time : '0';
    const defaultStopTime = Number(calcStopTime) > maxStopTime ? calcStopTime : maxStopTime
    const isCurrentType = dieCurrent && dieCurrent.currentType && dieCurrent.currentType === CURRENT_PWL_TABLE
    this.setState({
      error: null,
      currentPoints: currentPoints.map((item, index) => ({
        time: item.time,
        current: item.current,
        index
      })),
      stepTime: (dieCurrent && dieCurrent.stepTime && isCurrentType) ? dieCurrent.stepTime : '10',
      stopTime: (dieCurrent && dieCurrent.stopTime && isCurrentType) ? dieCurrent.stopTime : defaultStopTime,
      startTime: (dieCurrent && dieCurrent.startTime && isCurrentType) ? dieCurrent.startTime : '0',
    }, () => {
      this.props.savePoints(this.getCurrentPoints());
    })
  }

  initColumns = () => {
    CurrentColumns[0].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'time',
        handleSave: (row) => this.saveValue(row, 'time')
      }
    }

    CurrentColumns[1].render = (text, record) => {
      return (
        <div>
          <span>{text}</span>
          <CloseOutlined
            className="imp-current-delete-icon"
            onClick={(e) => this.deleteRow(e, record)} />
        </div>
      );
    }

    CurrentColumns[1].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'current',
        handleSave: (row) => this.saveValue(row, 'current')
      }
    }
  }

  updateStopTime = (newPoints) => {
    const { maxStopTime } = this.props
    const defaultStopTime = newPoints.length > 0 ? newPoints[newPoints.length - 1].time : null
    if (Number(defaultStopTime) > maxStopTime) {
      this.setState({
        stopTime: defaultStopTime
      })
    }
  }

  saveValue = (row, key) => {
    const currentIndex = row.index;
    const value = row[key];
    const { currentPoints } = this.state;
    let _points = JSON.parse(JSON.stringify(currentPoints))
    _points.find(point => point.index === currentIndex)[key] = value;
    this.updateStopTime(_points)
    this.setState({
      currentPoints: _points,
    }, () => { this.props.refreshPlot() })
  }

  addTableRow = (e) => {
    const { currentPoints } = this.state;
    let newIndex = currentPoints.length ? currentPoints[currentPoints.length - 1].index + 1 : 0;
    const newPoints = [...currentPoints, { time: '', current: '', index: newIndex }]
    this.updateStopTime(newPoints)
    this.setState({
      currentPoints: newPoints,
    }, () => { this.props.refreshPlot() })
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const { index } = record;
    const { currentPoints } = this.state;
    let newPoints = currentPoints.filter(point => point.index !== index);
    this.updateStopTime(newPoints)
    this.setState({
      currentPoints: newPoints,
    }, () => { this.props.refreshPlot() })
  }

  saveCurrent = (closePanel) => {
    const { stepTime, stopTime, startTime } = this.state;
    let newPoints = this.getCurrentPoints('Error');
    if (newPoints === 'Error') {
      return false;
    }
    const _dieCurrent = {
      currentPoints: [...newPoints],
      currentType: CURRENT_PWL_TABLE,
      stepTime,
      stopTime,
      startTime
    }
    this.props.updateCurrent(_dieCurrent, closePanel)
    return true;
  }

  getCurrentPoints = (errorReturn = []) => {
    const { currentPoints = [] } = this.state;
    let newPoints = [];
    for (let point of currentPoints) {
      const { time, current } = point;
      const checkArray = [{ msg: 'Time must be a number.', value: time }, { msg: 'Current must be a number.', value: current }]
      for (let check of checkArray) {
        let error = numberCheck(check.value);
        if (error) {
          errorReturn === 'Error' && this.setState({ error: check.msg });
          return errorReturn;
        }
      }
      newPoints.push({
        time,
        current
      })
    }
    return newPoints;
  }

  uploadFile = (options) => {
    const { file } = options;
    uploadPWLTableFile({
      file
    }).then(res => {
      if (res) {
        const newPoints = res.map((item, index) => {
          return { ...item, index }
        })
        this.updateStopTime(newPoints)
        this.setState({
          currentPoints: newPoints,
        }, () => {
          this.props.refreshPlot()
        })
      } else {
        this.setState({ error: 'Upload file failed.' })
      }
    }, error => {
      console.error(error);
      this.setState({ error: `${error} Upload file failed.` })
    });
  }

  changeSettingValue = (e, type) => {
    this.setState({
      [type]: e.target.value,
    })
  }

  render() {
    const { error, currentPoints, stepTime, stopTime, startTime } = this.state;

    return (
      <Fragment>
        <div className='imp-current-pwl cascade-setup-border'>
          <Fragment>
            <span className='font-bold imp-current-value-span' >
              <span>PWL Current Table</span>
            </span>
            <PlusCircleOutlined
              title="Add table row"
              className='cascade-imp-icon'
              onClick={(e) => this.addTableRow(e)} />
            <Upload
              name='file'
              showUploadList={false}
              customRequest={this.uploadFile}
            >
              <UploadOutlined className='cascade-imp-icon' title="Upload a two column text file" />
            </Upload>
          </Fragment>
          <div className="imp-current-pwl-table">
            <Table
              rowKey={(record) => record.index}
              columns={CurrentColumns}
              size="small"
              dataSource={currentPoints}
              tablePadding={true}
            />
          </div>
        </div>
        {/* <TimeSetting
          stepTime={stepTime}
          stopTime={stopTime}
          startTime={startTime}
          changeSettingValue={this.changeSettingValue}
        /> */}
        {error && <span className='imp-current-name-error-msg'>{error}</span>}
      </Fragment>
    );
  }
}

export default PWLTable;