const PROJECT_TYPE = 'ROCKY';
export const GET_VERIFICATION_CONTENT = `${PROJECT_TYPE}/get_verification_content`;
export const UPDATE_ROCKY_CONTENT = `${PROJECT_TYPE}/update_rocky_content`;
export const UPDATE_ROCKY_INTERFACES = `${PROJECT_TYPE}/update_rocky_interfaces`;
export const UPDATE_ROCKY_CONTENT_INFO = `${PROJECT_TYPE}/update_rocky_content_info`;
export const SAVE_CONTENT_TO_SERVER = `${PROJECT_TYPE}/save_content_to_server`;
export const AUTO_SAVE_CONTENT_TO_SERVER = `${PROJECT_TYPE}/auto_save_content_to_server`;
export const OPEN_PAGE = `${PROJECT_TYPE}/open_page`;
export const UPDATE_INTERFACES = `${PROJECT_TYPE}/update_interfaces`;
export const SAVE_CURRENT_VERIFICATION = `${PROJECT_TYPE}/save_current_verification`;
export const UPDATE_SAVE_STATUS = `${PROJECT_TYPE}/update_save_status`;
export const UPDATE_LOADING = `${PROJECT_TYPE}/update_loading`;
/* --- Signal ---  */
export const EDIT_SIGNAL_NAME = `${PROJECT_TYPE}/edit_signal_name`;
export const EDIT_SIGNAL_NETS = `${PROJECT_TYPE}/edit_signal_nets`;
export const DELETE_SIGNAL = `${PROJECT_TYPE}/delete_signal`;

/* simulation options */
export const UPDATE_CURRENT_CONFIG = `${PROJECT_TYPE}/update_current_config`;
export const SAVE_SIMULATION_OPTION = `${PROJECT_TYPE}/save_simulation_option`;
export const UPDATE_PREV_STIMULUS_MODE = `${PROJECT_TYPE}/update_prev_stimulus_mode`;
export const UPDATE_USER_DEFINED_NETLSIT_VALUE = `${PROJECT_TYPE}/update_user_defined_netlist_value`

/* service options */
export const UPDATE_SERVICE_CONFIG = `${PROJECT_TYPE}/update_service_config`;
export const SAVE_SERVICE_OPTION = `${PROJECT_TYPE}/save_service_option`;

/* Power Ground Nets */
export const ADD_POWER_GROUNDS = `${PROJECT_TYPE}/add_power_grounds`;
export const POWER_NET_SELECTION = `${PROJECT_TYPE}/power_net_selection`;

/* Extraction */
export const SAVE_EXTRACTION = `${PROJECT_TYPE}/save_extraction`;
export const APPLY_ROCKY_PORT_SETUP = `${PROJECT_TYPE}/apply_rocky_port_setup`;

/* Pin table */
export const SAVE_PIN_MODEL = `${PROJECT_TYPE}/save_pin_model`;
export const SAVE_POWER_OFF = `${PROJECT_TYPE}/save_power_off`;
export const SAVE_MODEL_PINS = `${PROJECT_TYPE}/save_model_pins`;
export const SAVE_STIMULUS = `${PROJECT_TYPE}/save_stimulus`;
export const STIMULUS_RANDOM = `${PROJECT_TYPE}/stimulus_random`;

/* Component table */
export const SAVE_COMP_MODEL = `${PROJECT_TYPE}/save_comp_model`;
export const COMPONENT_LOADING = `${PROJECT_TYPE}/component_loading`;
export const SAVE_COMP_PACKAGE_MODEL = `${PROJECT_TYPE}/save_comp_package_model`;

/* export const COPY_SETUP_ALL = `${PROJECT_TYPE}/copy_setup_all`; */
export const SAVE_COPY_VERIFICATION = `${PROJECT_TYPE}/save_copy_verification`;
export const APPLY_SETUP_TO_SELECT = `${PROJECT_TYPE}/apply_setup_to_select`;
export const UPDATE_APPLY_SETUP_STATUS = `${PROJECT_TYPE}/update_apply_setup_status`;

/* error check */
export const UPDATE_ERROR_CHECK_LIST = `${PROJECT_TYPE}/update_error_check_list`;

/* get current verification library file list */
export const UPDATE_CURRENT_LIBRARY_FILE_LIST = `${PROJECT_TYPE}/update_current_library_file_list`;
export const GET_LIBRARY_FILE_PARSE = `${PROJECT_TYPE}/get_library_file_parse`;

/* channel PDN */
export const GET_CHANNEL_PDN_CONTENT = `${PROJECT_TYPE}/get_channel_pdn_content`;

export const CLEAN_SETUP_INFO = `${PROJECT_TYPE}/clean_setup_info`;
export const CLEAN_PACKAGE_SETUP_INFO = `${PROJECT_TYPE}/clean_package_setup_info`

/* update touchstone file macro modeling status list */
export const UPDATE_TOUCHSTONE_STATUS_LIST = `${PROJECT_TYPE}/update_touchstone_status_list`;
export const UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS = `${PROJECT_TYPE}/update_touchstone_file_macro_modeling_status`;


/* adr cmd stimulus */
export const UPDATE_ADR_CMD_STIMULUS = `${PROJECT_TYPE}/update_adr_cmd_stimulus`;

/* update channel after replace */
export const UPDATE_CHANNEL_BY_VERSION = `${PROJECT_TYPE}/update_channel_after_replace`;

/* extraction ports */
export const UPDATE_PORT_SETUPS_TO_SERVER = `${PROJECT_TYPE}/update_port_setups_to_server`;

/* switch rank status */
export const SWITCH_RANK_STATUS = `${PROJECT_TYPE}/switch_rank_status`;
export const CLEAR_STANDBY_MODEL = `${PROJECT_TYPE}/clear_standby_model`;

/*package layout */
export const SELECT_PACKAGE_LAYOUT_MODEL = `${PROJECT_TYPE}/select_package_layout_model`;
export const UPDATE_PACKAGE_LOADING = `${PROJECT_TYPE}/update_package_layout_loading`;
export const UPDATE_PACKAGE_MSG = `${PROJECT_TYPE}/update_package_msg`;
export const GET_PACKAGE_CONTENT = `${PROJECT_TYPE}/get_package_content`;
export const UPDATE_ROCKY_PACKAGE_LAYOUT_INFO = `${PROJECT_TYPE}/update_rocky_package_layout_info`;
export const SAVE_CURRENT_PACKAGE_VERIFICATION = `${PROJECT_TYPE}/save_current_package_verification`;
export const UPDATE_PACKAGE_SIGNAL_INFO = `${PROJECT_TYPE}/update_package_signal_info`;
export const UPDATE_PACKAGE_SETUP_LOADING = `${PROJECT_TYPE}/update_package_setup_loading`;
export const DELETE_PACKAGE_SIGNAL = `${PROJECT_TYPE}/delete_package_signal`;
export const CHANGE_PACKAGE_SIGNAL_NAME = `${PROJECT_TYPE}/change_package_signal_name`;
export const ADD_PACKAGE_VERIFICATION = `${PROJECT_TYPE}/add_package_verification`;
export const ADD_PACKAGE_CHANNEL = `${PROJECT_TYPE}/add_package_channel`;
export const COMBINE_PACKAGE_INTERFACE = `${PROJECT_TYPE}/combine_package_interface`;
export const UPDATE_PACKAGE_CONTENT_INFO = `${PROJECT_TYPE}/update_package_content_info`;
export const SAVE_ROCKY_PACKAGE_CONTENT_SERVER = `${PROJECT_TYPE}/save_rocky_package_content_server`;
export const UPDATE_REF_NETS = `${PROJECT_TYPE}/update_ref_nets`;
export const EDIT_PACKAGE_COMPONENT_TYPE = `${PROJECT_TYPE}/edit_package_component_type`;
export const UPDATE_PACKAGE_PORT_AND_BALL = `${PROJECT_TYPE}/update_package_port_and_ball`;
export const SAVE_PACKAGE_CONTENT_AND_CLEAR = `${PROJECT_TYPE}/save_package_content_and_clear`;
export const IS_UPDATE_PACKAGE_PIN_STATUS = `${PROJECT_TYPE}/is_update_package_pin_status`;
export const UPDATE_CONTENT_LOADING = `${PROJECT_TYPE}/update_content_loading`;

/* package PDN */
export const UPDATE_PACKAGE_PDN_INFO = `${PROJECT_TYPE}/update_package_info`;
export const SAVE_ROCKY_PACKAGE_PDN_SERVER = `${PROJECT_TYPE}/save_rocky_package_pdn_server`;
export const SAVE_BALL_SIZE = `${PROJECT_TYPE}/save_ball_size`;
export const UPDATE_PACKAGE_NETS = `${PROJECT_TYPE}/update_package_nets`;
export const UPDATE_PACKAGE_COMPONENTS = `${PROJECT_TYPE}/update_package_components`;
export const UPDATE_PORTS = `${PROJECT_TYPE}/update_package_ports`;
export const SWITCH_PACKAGE_PORTS = `${PROJECT_TYPE}/switch_package_ports`;
export const SPLIT_COMPONENT_PART = `${PROJECT_TYPE}/split_component_part`;
export const MERGE_COMPONENTS = `${PROJECT_TYPE}/merge_components`;
export const UPDATE_COMP_USAGE = `${PROJECT_TYPE}/update_comp_usage`;
export const REMOVE_COMPONENTS = `${PROJECT_TYPE}/remove_components`;
export const SAVE_DECAP_MODEL = `${PROJECT_TYPE}/save_decap_model`;
export const SAVE_PKG_PDN_EXTRACTION = `${PROJECT_TYPE}/save_package_pdn_extraction`;
export const SAVE_PKG_PDN_COMPONENT_SETTING = `${PROJECT_TYPE}/save_package_pdn_component_setting`;
export const ADD_POWER_DOMAIN = `${PROJECT_TYPE}/add_power_domain`;
export const DELETE_POWER_DOMAIN = `${PROJECT_TYPE}/delete_power_domain`;
export const UPDATE_VRM = `${PROJECT_TYPE}/update_vrm`;
export const UPDATE_VOLTAGE = `${PROJECT_TYPE}/update_voltage`;
export const SAVE_VRMS = `${PROJECT_TYPE}/save_vrms`;
export const UPDATE_INCLUDE_EXTEND_NETS = `${PROJECT_TYPE}/update_include_extend_nets`;
export const RE_ASSIGN_DECAP_MODEL = `${PROJECT_TYPE}/re_assign_decap_model`;
export const UPDATE_MODEL_ASSIGN_LOADING = `${PROJECT_TYPE}/update_model_assign_loading`;
export const UPDATE_TARGET_IC = `${PROJECT_TYPE}/update_target_ic`;

/* DIMM */
export const UPDATE_DIMM_CARD_MODEL = `${PROJECT_TYPE}/update_dimm_card_model`;
export const CHANGE_SOCKET_MODEL = `${PROJECT_TYPE}/change_socket_model`;
export const UPDATE_MEMORY_SELECTIONS = `${PROJECT_TYPE}/update_memory_selections`;

/* Virtual components */
export const SAVE_VIRTUAL_COMPONENT = `${PROJECT_TYPE}/save_virtual_component`;

/* SSN interface */
export const GET_CLK_VERIFICATION_CONTENT = `${PROJECT_TYPE}/get_clk_verification_content`

/* pcb channel */
export const UPDATE_PCB_CHANNEL_CONTENT = `${PROJECT_TYPE}/update_pcb_channel_content`;
export const SAVE_PCB_CHANNEL_CONTENT = `${PROJECT_TYPE}/save_pcb_channel_content`;
export const UPDATE_PDN_ERROR_CHECK = `${PROJECT_TYPE}/update_PDN_error_check`;
export const GET_PCB_CHANNEL_CONTENT = `${PROJECT_TYPE}/get_pcb_channel_content`;
export const GET_PCB_AND_PACKAGE_CHANNEL_INFO = `${PROJECT_TYPE}/get_pcb_and_package_channel_info`;

export const UPDATE_SSN_PACKAGE_INFO = `${PROJECT_TYPE}/update_ssn_package_info`;
export const UPDATE_PKG_DIE_PORTS_BY_CPM = `${PROJECT_TYPE}/update_pkg_die_ports_by_cpm`;
export const UPDATE_PCB_POWER_COMPONENTS = `${PROJECT_TYPE}/update_pcb_power_components`;

// package channel
export const UPDATE_PKG_REFERENCE_NETS = `${PROJECT_TYPE}/update_pkg_reference_nets`;

// extraction
export const START_MODELING_EXTRACTION = `${PROJECT_TYPE}/start_modeling_extraction`;

// channel
export const RE_ASSIGN_CHANNEL_DECAP_MODEL = `${PROJECT_TYPE}/re_assign_channel_decap_model`;
export const SAVE_CHANNEL_DECAP_MODEL = `${PROJECT_TYPE}/save_channel_decap_model`;
export const UPDATE_CHANNEL_REFERENCE_NETS = `${PROJECT_TYPE}/update_channel_reference_nets`;
export const UPDATE_DECAP_MODEL_SETUP_LOADING = `${PROJECT_TYPE}/update_decap_model_setup_loading`;
export const UPDATE_CHANNEL_ERROR_CHECK_LIST = `${PROJECT_TYPE}/update_channel_error_check_list`;
export const UPDATE_POWER_COMPONENTS_AFTER_REF_NETS = `${PROJECT_TYPE}/update_power_components_after_ref_nets`;
export const UPDATE_CHANNEL_CONTENT = `${PROJECT_TYPE}/update_channel_content`;
export const UPDATE_CHANNEL_COMP_USAGE = `${PROJECT_TYPE}/update_channel_comp_usage`;
export const REMOVE_CHANNEL_COMPONENTS = `${PROJECT_TYPE}/remove_channel_components`;
export const SPLIT_CHANNEL_COMPONENT_PART = `${PROJECT_TYPE}/split_channel_component_part`;
export const MERGE_CHANNEL_COMPONENTS = `${PROJECT_TYPE}/merge_channel_components`;

//Power Components Apply
export const APPLY_POWER_COMPONENTS = `${PROJECT_TYPE}/apply_power_components`;
export const UPDATE_POWER_COMPONENTS_APPLY_LOADING = `${PROJECT_TYPE}/update_power_components_apply_loading`;