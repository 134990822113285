export const OPEN_LICENSE_PAGE = 'open_license_page';
export const UPLOAD_LICENSE = 'upload_license';
export const UPDATE_LICENSE_TABLE = 'update_license_table';
export const UPDATE_LICENSE_FEATURES_TABLE = 'update_license_featrues_table';
export const UPDATE_LICENSE_USAGE_TABLE = 'update_license_usage_table';
export const UPDATE_LICENSE_USAGE_HISTORY = 'update_license_usage_history';
export const USAGE_HISTORY_PAGE_CHANGE = 'usage_history_page_change';
export const USAGE_REFRESH = 'usage_table_refresh';
export const UPDATE_CURRENT_USAGE_TIME = 'update_current_usage_time';
export const UPDATE_USAGE_HISTORY_TIME = 'update_usage_history_time';
export const UPDATE_LICENSE_MODE = 'update_license_mode';
export const UPLOAD_LICENSE_MODE = 'upload_license_mode';
export const CHECK_IN_CLICK = 'check_in_click';