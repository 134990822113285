import React, { Component } from 'react';
import { Tag } from 'antd';
import './index.css';

class CircleTag extends Component {

  render() {
    const { text, color } = this.props;
    let _color = color ? color : "blue";
    return (
      <Tag
        className="aurora-circle-tag"
        color={_color}
      >{text ? text : ""}</Tag>
    );
  }
}
export default CircleTag;