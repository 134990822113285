import {
  CHANGE_SELECT_KEY,
  BACK_TO_SETUP,
  GET_HISTORYS,
  SAVE_HISTORY,
  DELETE_HISTORY,
  UPDATE_HISTORT_LIST,
  UPDATE_HISTORY_STATUS,
  UPDATE_IMPORT_LIST,
  UPDATE_IMPORT_STATUS,
  GET_IMPORTS,
  DELETE_IMPORT,
  RUNNING_IMPORT_LIST,
  UPDATE_COMPARE_LIST,
  UPDATE_COMPARE_STATUS,
  GET_COMPARES,
  SAVE_COMPARES
} from './actionType';

export const changeResultKey = (key) => ({
  type: CHANGE_SELECT_KEY,
  key
})

export const backToImpedance = () => ({
  type: BACK_TO_SETUP
})

export const getHistorys = () => ({
  type: GET_HISTORYS
})

export const updateHistoryList = (historys) => ({
  type: UPDATE_HISTORT_LIST,
  historys
})

export const saveHistory = (name) => ({
  type: SAVE_HISTORY,
  name
})

export const updateHistoryStatus = () => ({
  type: UPDATE_HISTORY_STATUS
})

export const deleteHistory = (id) => ({
  type: DELETE_HISTORY,
  id
})

export const updateImportList = (imports) => ({
  type: UPDATE_IMPORT_LIST,
  imports
})

export const updateImportStatus = () => ({
  type: UPDATE_IMPORT_STATUS
})

export const getImports = () => ({
  type: GET_IMPORTS
})

export const deleteImport = (id) => ({
  type: DELETE_IMPORT,
  id
})

export const updateRunningImport = (list) => ({
  type: RUNNING_IMPORT_LIST,
  list
})

export const updateCompareList = (compares, status) => ({
  type: UPDATE_COMPARE_LIST,
  compares,
  status
})

export const updateCompareStatus = () => ({
  type: UPDATE_COMPARE_STATUS
})

export const getCompares = () => ({
  type: GET_COMPARES
})

export const saveCompare = (compares) => ({
  type: SAVE_COMPARES,
  compares
})