import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Select, Button, Divider } from "antd";
import { PCB_CHANNEL, ROCKY_CHANNEL, SIGN_OFF_TEMPLATE } from "../../../constants/treeConstants";
import { selectTask, updateSelectTaskMessage, getServerList } from '../store/action';
import { getDefaultSpecifyService, getTaskServerList, SYNOPSYS } from '../../../services/CCC/cccHelper';
import { TitleRender } from '@/components/Monitor/TitleRender_v2';
import { FASTPI, SIERRA } from "../../../constants/pageType";
import { PROJECT_V2 } from "../../../constants/projectVersion";
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';

const Option = Select.Option;
const OptGroup = Select.OptGroup;

class SpecifyServicePanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      verificationList: [],
      verificationType: "Interface",
      selectedVerification: {
        type: "Interface",
        id: "",
        name: "",
        verificationId: ""
      },//{id,name,verificationId}
      selectType: {
        type: "",//PCB_CHANNEL / END_TO_END_CHANNEL / VERIFICATION / ROCKY_CHANNEL
        name: ""
      },
      subSelected: {
        type: "", //DESIGN(andes) / CHANNEL (rocky)
        id: "",
        name: ""
      },
      selectTypeList: [],
      subSelectList: [],
      serviceSelectListMap: {
        verification: [{
          task: "",
          selectedServers: [],
          serverList: []
        }]
      },
      topWidth: 800
    }
  }

  componentDidMount = () => {
    this.props._updateSelectTaskMessage(null);
    this.props._getServerList();
    this.screenChange();
    this.resize();
    const { getCCCVerifications, getCurrentVerification, projectId, setupType, projectVersion } = this.props;
    const { serviceSelectListMap } = this.state;
    let _serviceSelectListMap = { ...serviceSelectListMap };

    const list = getCCCVerifications(projectId);
    const currentVerification = getCurrentVerification();
    const defaultSetup = getDefaultSpecifyService({ list, currentVerification, projectVersion });
    if (setupType === SIGN_OFF_TEMPLATE) {
      _serviceSelectListMap = {
        pathR: [{
          task: "",
          selectedServers: [],
          serverList: []
        }],
        impedance: [{
          task: "",
          selectedServers: [],
          serverList: []
        }]
      }
    }
    this.setState({
      verificationList: defaultSetup.verificationList,
      selectType: defaultSetup.selectType,
      subSelected: defaultSetup.subSelected,
      selectTypeList: defaultSetup.selectTypeList,
      subSelectList: defaultSetup.subSelectList,
      selectedVerification: defaultSetup.verification,
      verificationType: defaultSetup.verificationType,
      list,
      serviceSelectListMap: _serviceSelectListMap

    })
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate = (prevProps) => {
    const { topWidth } = this.state;
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  selectTypeChange = (key) => {
    const { selectTypeList } = this.state;
    const selectType = selectTypeList.find(item => item.name === key);
    if ([PCB_CHANNEL, ROCKY_CHANNEL].includes(selectType.type)) {
      this.setState({
        selectType: {
          name: selectType.name,
          type: selectType.type
        },
        subSelected: {
          type: selectType.subType,
          id: "",
          name: ""
        },
        subSelectList: selectType.subList || [],
        verificationType: selectType.verificationType,
        verificationList: []
      });
    } else {
      this.setState({
        selectType: {
          name: selectType.name,
          type: selectType.type
        },
        verificationType: selectType.verificationType,
        subSelected: {
          type: "",
          id: "",
          name: ""
        },
        verificationList: selectType.verificationList || []
      })
    }
    this.clearVerification();
  }

  subSelectChange = (key) => {
    const { subSelectList, subSelected, verificationType } = this.state;
    const { isGroup, projectVersion } = this.props;
    let subSelect = null, _group = null, _verificationType = verificationType;
    if (isGroup) {
      const [group, value] = key.split("::");
      const findGroup = subSelectList.find(item => item.name === group);
      subSelect = findGroup.children.find(item => item.id === value)
      _group = group;
      _verificationType = group;
    } else {
      subSelect = subSelectList.find(item => item.name === key);
    }

    if (!subSelect) {
      return;
    }

    this.setState({
      subSelected: {
        ...subSelected,
        id: subSelect.id,
        name: subSelect.name,
        dataType: isGroup ? _group : "",
        design: subSelect.designName,
        verificationId: projectVersion === PROJECT_V2 ? subSelect.verificationId : null
      },
      verificationList: subSelect.verificationList || [],
      verificationType: _verificationType
    })
    this.clearVerification();
    this.props._updateSelectTaskMessage(null);
  }

  verificationChange = (key, label = "name") => {
    const { verificationList } = this.state;
    const VSelect = verificationList.find(item => item[label] === key);
    if (!VSelect) {
      return;
    }
    this.setState({
      selectedVerification: {
        verificationId: VSelect.verificationId,
        id: VSelect.id,
        name: VSelect.name
      }
    })
    this.props._updateSelectTaskMessage(null);
  }

  clearVerification = () => {
    this.setState({
      selectedVerification: {
        id: "",
        name: "",
        verificationId: ""
      }
    })
  }

  serverChange = (keys, index, key) => {
    const { serviceSelectListMap } = this.state;
    let _serviceSelectListMap = { ...serviceSelectListMap };
    let _serviceSelectList = _serviceSelectListMap[key] || [];
    _serviceSelectList[index].selectedServers = [...keys];
    _serviceSelectListMap[key] = _serviceSelectList;
    this.setState({
      serviceSelectListMap: _serviceSelectListMap
    });
    this.props._updateSelectTaskMessage(null);
  }

  taskChange = (value, key, index) => {
    const { serverListSimple } = this.props;
    const { serviceSelectListMap } = this.state;
    let _serviceSelectListMap = { ...serviceSelectListMap };
    let _serviceSelectList = _serviceSelectListMap[key] || [];
    const find = serverListSimple.find(it => it.taskType === value);

    _serviceSelectList[index] = {
      task: value || "",
      selectedServers: [],
      serverList: find ? find.servers : []
    }
    _serviceSelectListMap[key] = _serviceSelectList;
    this.setState({
      serviceSelectListMap: _serviceSelectListMap
    });
    this.props._updateSelectTaskMessage(null);
  }

  addNewTaskSelect = (e, key) => {
    e.stopPropagation();
    const { serviceSelectListMap } = this.state;
    let _serviceSelectListMap = { ...serviceSelectListMap };
    let _serviceSelectList = _serviceSelectListMap[key] || []
    _serviceSelectList.push({
      task: "",
      selectedServers: [],
      serverList: []
    });
    _serviceSelectListMap[key] = _serviceSelectList;
    this.setState({
      serviceSelectListMap: _serviceSelectListMap
    });
    this.props._updateSelectTaskMessage(null);
  }

  deleteSelectedTask = (taskIndex, key) => {
    const { serviceSelectListMap } = this.state;
    let _serviceSelectListMap = { ...serviceSelectListMap };
    let _serviceSelectList = _serviceSelectListMap[key] || [];
    _serviceSelectList = _serviceSelectList.filter((item, index) => index !== taskIndex);
    _serviceSelectListMap[key] = _serviceSelectList;
    this.setState({
      serviceSelectListMap: _serviceSelectListMap
    });
    this.props._updateSelectTaskMessage(null);
  }

  selectTask = () => {
    const { selectedVerification, serviceSelectListMap, subSelected } = this.state;
    const { projectVersion } = this.props;
    let _selectedKey = { ...selectedVerification }
    if (projectVersion === PROJECT_V2) {
      _selectedKey = { ...subSelected }
    }
    this.props._selectTask(_selectedKey, serviceSelectListMap, this.props.setupType);
  }

  getTitleTree = (titleSelectedKeys) => {
    const { pageType } = this.props;
    return titleSelectedKeys.map(item => {
      if (!item.name) {
        return null;
      }
      return {
        key: item.name,
        name: item.name,
        type: [SIERRA, FASTPI].includes(pageType) ? item.type : null
      }
    }).filter(item => !!item)
  }

  render() {
    const { selectTaskMessage, titleSelectedKeys, isGroup, projectVersion } = this.props;
    const { verificationType, topWidth, subSelected, subSelectList,
      selectType, selectTypeList, selectedVerification,
      verificationList, serviceSelectListMap } = this.state;
    const width = verificationType === "Multi-PCB Channel" ? 130 : 92;

    return <Fragment>
      <div className="specify-service-project-title">
        {TitleRender({
          topWidth,
          progress: -1,
          monitorTree: this.getTitleTree(titleSelectedKeys),
          selectedKey: titleSelectedKeys.map(item => item.name).filter(item => !!item),
          displayList: titleSelectedKeys.filter(item => !!item.name)
        })}
      </div>
      <div className='specify-service-content'>
        <div className='specify-service-main'>
          <div className='specify-service-title'>Specify Service Selector</div>
          {selectTypeList && selectTypeList.length > 1 ? this.selectComponent({
            title: "Type",
            value: selectType.name,
            options: selectTypeList,
            width,
            selectChange: this.selectTypeChange
          }) : null}
          {subSelected.type ? this.selectComponent({
            title: subSelected.type,
            dataType: subSelected.dataType,
            value: isGroup ? subSelected.id : subSelected.name,
            name: subSelected.name,
            options: subSelectList,
            width,
            label: subSelected.design,
            selectChange: this.subSelectChange,
            allowGroup: true
          }) : null}
          {projectVersion !== PROJECT_V2 ? this.selectComponent({
            title: verificationType,
            value: isGroup ? selectedVerification.id : selectedVerification.name,
            name: selectedVerification.name,
            options: verificationList,
            width,
            selectChange: this.verificationChange,
            isId: isGroup
          }) : null}
          {Object.keys(serviceSelectListMap || {}).map(item => this.taskServerRender(item, width))}
          <div className='specify-service-item specify-service-button-content'>
            <Button
              className='specify-service-button'
              type='primary'
              onClick={this.selectTask}
              disabled={this.getDisabled()}
            >Select</Button>
          </div>
          {selectTaskMessage && <div className={`specify-service-item specify-service-message-content ${this.getMsgClassName(selectTaskMessage)}`}>
            {selectTaskMessage}
          </div>}
        </div>
      </div>
    </Fragment>
  }

  getMsgClassName = (selectTaskMessage) => {
    return selectTaskMessage.indexOf('successfully') > -1 ? 'specify-service-message-success-content' : 'specify-service-message-failed-content';
  }

  getDisabled = () => {
    const { selectedVerification, serviceSelectListMap, subSelected } = this.state;
    const { projectVersion } = this.props;
    const ServerList = Object.keys(serviceSelectListMap).filter(key => !!serviceSelectListMap[key].filter(item => !!item.selectedServers.length).length)
    if (projectVersion === PROJECT_V2 && ServerList.length && subSelected && subSelected.id && subSelected.dataType) {
      return false;
    }
    if (selectedVerification && selectedVerification.id && ServerList.length) {
      return false;
    } else {
      return true;
    }
  }

  getStatus = (value) => {
    const str = value ? value.replace(/^\S/, s => s.toUpperCase()) : '';
    return <span style={{ color: this.getColor(value), fontWeight: 'bold' }}>{str}</span>
  }

  getColor = (value) => {
    switch (value) {
      case 'busy': return '#f12727';
      case 'free': return '#00b907';
      case 'offline': return '#8c8c8c';
      default: return '#000000a6';
    }
  };

  selectComponent = ({ title, value, options, selectChange, width, dataType, label, allowGroup, isId, name }) => {
    return <div className='specify-service-item'>
      <span style={{ width }}>{title}</span>
      <Select
        value={{
          key: dataType ? `${dataType}::${value}` : value || "", label: label ? name && value ? `${label} - ${name}` : `${label} - ${value}` || value : name && value ? name : value || ""
        }}
        showSearch
        labelInValue={true}
        onChange={option => option && selectChange(option.value, isId ? "id" : "name")}
        placeholder={title}
        className='aurora-select'
        popupClassName='aurora-select-dropdown'
        style={{ width: `calc(100% - ${width}px` }}
      >
        {options.map(item =>
          item.children && allowGroup ? <OptGroup key={item.name} label={item.name}>
            {item.children.map(it => <Option
              key={`${item.name}::${it.id}`}
              title={it.name}
            >{item.name === "Interface" ? it.name : `${it.designName} - ${it.name}`}</Option>)}
          </OptGroup>
            : <Option key={isId ? item.id : item.name} title={item.name}>{item.name}</Option>

        )}
      </Select>
    </ div>
  }

  getVerType = (key) => {
    switch (key) {
      case "pathR":
        return "R Explorer";
      case "impedance":
        return "Z Explorer";
      default: return key;
    }
  }

  taskServerRender = (key, width) => {
    const { serverListSimple, pageType, setupType, projectVersion } = this.props;
    const { serviceSelectListMap, verificationType, subSelected } = this.state;
    const itemData = serviceSelectListMap[key] || [];
    const _setupType = key === "verification" ? setupType : key;
    let taskServerList = getTaskServerList(serverListSimple, itemData, pageType, verificationType, _setupType, projectVersion, subSelected);
    const isMulti = Object.keys(serviceSelectListMap).length > 1;
    const className = isMulti ? "specify-service-sub-item" : "";
    return itemData.map((service, index) => {
      return (
        <Fragment key={index}>
          <div className='specify-service-task-content' key={index}>
            {isMulti && index === 0 ? <div className='specify-service-item'>
              <span className="server-title">{this.getVerType(key)}</span>
            </div> : null}
            <div className={`specify-service-item ${className}`}>
              <span style={{ width }}>
                <span>Task</span>
                {index === 0 ? <PlusCircleOutlined
                  title={'Add new Task'}
                  className='specify-service-add-task-icon'
                  onClick={(e) => { this.addNewTaskSelect(e, key) }} /> : null}
              </span>
              <Select
                value={this.getTaskDisplay(service.task)}
                className='aurora-select'
                onChange={(_key) => this.taskChange(_key, key, index)}
                popupClassName='aurora-select-dropdown'
                allowClear={true}
                style={{ width: `calc(100% - ${width}px` }}
              >
                {taskServerList.map(item =>
                  <Option key={item.taskType} title={item.taskType}>{this.getTaskDisplay(item.taskType)}</Option>
                )}
              </Select>
            </div>
            <div className={`specify-service-item ${className}`}>
              <span style={{ width }}>Services</span>
              <Select
                mode="multiple"
                value={service.selectedServers}
                onChange={(keys) => this.serverChange(keys, index, key)}
                className='aurora-select'
                popupClassName='aurora-select-dropdown'
                popupMatchSelectWidth={false}
                style={{ width: `calc(100% - ${width}px` }}
              >
                {service.serverList.map(item =>
                  <Option key={item.name} title={item.name}>{item.name} - {this.getStatus(item.status)}</Option>
                )}
              </Select>
            </div>
            {itemData.length === 1 ? null :
              <CloseOutlined
                title={'Delete the task'}
                className='specify-service-delete-task-icon'
                onClick={() => { this.deleteSelectedTask(index, key) }} />}
          </div>
          {(itemData.length - 1) !== index ?
            <Divider dashed /> : null}
        </Fragment>
      );
    });
  }

  getTaskDisplay = (taskType) => {
    if (taskType === SYNOPSYS) {
      return "HSpice";
    }
    return taskType;
  }
}

const mapState = (state) => {
  const { serverListSimple = [], selectTaskMessage } = state.CCCReducer;
  const { pageType } = state.LoginReducer;

  return {
    serverListSimple,
    selectTaskMessage,
    pageType
  }
}

const mapDispatch = (dispatch) => ({
  _selectTask(verification, serviceSelectListMap, setupType) {
    dispatch(selectTask(verification, serviceSelectListMap, setupType))
  },
  _updateSelectTaskMessage(msg) {
    dispatch(updateSelectTaskMessage(msg))
  },
  _getServerList() {
    dispatch(getServerList());
  },
})

export default connect(mapState, mapDispatch)(SpecifyServicePanel)