import {
  INIT_STACKUP_DATA,
  CHANGE_STACKUP_TABLE,
  SETTING_UNIT,
  UPDATE_STACKUP_COLUMNS_STATUS,
  UPDATE_STACKUP_ERROR_MSG,
  UPDATE_SHOW_ZONES_STATUS,
  CHANGE_STACKUP_ZONE_PANEL_SHOW,
  UPDATE_SELECTED_ZONES,
  UPDATE_HIDDEN_COMPS,
  REMOVE_STACKUP_DATA
} from './actionTypes';
import {
  CLEAN_STATUS as ANDES_CLEAN_STATUS
} from '../../../store/Andes_v2/actionTypes';
import {
  CLEAN_STATUS as ROCKY_CLEAN_STATUS
} from '../../../store/Rocky/actionTypes';
import { USER_LOGOUT } from '../../../../Login/actionTypes';
import { updateStackupData, updateStackupDataByType, removeStackupData } from '../../../../../services/Stackup';


const defaultState = {
  stackupObj: {},
  hiddenComps: []
  /*   data: [],
    materialList: [],
    unit: null,
    loading: true,
    stackupColumnsStatus: false,
    stackupError: null */
}

const stackupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_STACKUP_DATA:
    case CHANGE_STACKUP_TABLE:
      return {
        ...state,
        stackupObj: updateStackupData(action.designID, state.stackupObj, action)
      }
    case SETTING_UNIT:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "unit", action.unit)
      }
    case ANDES_CLEAN_STATUS:
    case ROCKY_CLEAN_STATUS:
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_STACKUP_COLUMNS_STATUS:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "stackupColumnsStatus", action.status)
      }
    case UPDATE_STACKUP_ERROR_MSG:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "stackupError", action.errorObj)
      }
    case UPDATE_SHOW_ZONES_STATUS:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "showZones", action.show)
      }
    case CHANGE_STACKUP_ZONE_PANEL_SHOW:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "stackupZonePanelShow", action.show)
      }
    case UPDATE_SELECTED_ZONES:
      return {
        ...state,
        stackupObj: updateStackupDataByType(action.designID, state.stackupObj, "selectedZones", action.selectedZones)
      }
    case UPDATE_HIDDEN_COMPS:
      return {
        ...state,
        hiddenComps: action.hiddenComps
      }
    case REMOVE_STACKUP_DATA:
      return {
        ...state,
        stackupObj: removeStackupData(action.designID,  state.stackupObj)
      }
    default:
      return state;
  }
}

export default stackupReducer;