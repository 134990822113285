import React, { Component } from 'react';
import FilePanel from '@/components/FileDisplayPanel';

class FileContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileLoading: false
    }
  }

  componentDidMount() {
    const { libraryId } = this.props;
    if (libraryId) {
      this.getFileContent(libraryId);
    }
  }

  componentDidUpdate(prevProps) {
    const { libraryId, fileName } = this.props;
    if (prevProps.libraryId !== libraryId || fileName !== prevProps.fileName) {
      this.getFileContent(libraryId);
    }
  }

  getFileContent = (libraryId) => {
    const { getLibraryFileContent, fileName } = this.props;
    this.setState({
      fileLoading: true,
      fileContent: null
    });
    getLibraryFileContent(libraryId, fileName).then(res => {
      if (res) {
        this.setState({
          fileLoading: false,
          fileContent: typeof (res) !== "string" ? (res.toString ? res.toString() : "") : res
        })
      }
    });
  }

  render() {
    const { title, closeModal } = this.props;
    const { fileContent, fileLoading } = this.state;
    return (
      <FilePanel
        closePanel={closeModal}
        title={title}
        file={fileContent}
        fileLoading={fileLoading}
      />
    )
  }
}

export default FileContent;