import { evaluate } from 'mathjs';
import { WAVE } from '.';
import LayoutData from '../data/LayoutData';
import { PortSetupNegative } from './portsSetup';
import {
  GAP,
  CIRCUIT,
  PIN_GROUP,
  SINGLE_PIN,
  PIN_PER_REF_NET,
  NEARBY_PINS,
  ALL_PINS
} from './portTableHelper';
import permissionData from '@/services/helper/data/permissionData.js'

let cachedPcb = {
  pcbInfo: null,
  compPins: null
};

class AutoGeneratePorts {
  constructor() {
    this.errors = [];
    this.warnings = [];
    this.isExistPorts = false;
  }

  addError = (error) => {
    if (!this.errors.includes(error)) {
      this.errors.push(error);
    }
  }

  getErrors = () => {
    return this.errors;
  }

  addWarning = (warn) => {
    if (!this.warnings.includes(warn)) {
      this.warnings.push(warn);
    }
  }

  getWarnings = () => {
    return this.warnings;
  }

  cleanWarnings = () => {
    this.warnings = [];
  }

  getSetupList = (ports_generate_setup_list, ports_generation_setup) => {
    let setupList = [];
    //ports_generation_setup { portType: "pin_group", referenceType: "pin" }
    //ports_generate_setup_list : andes, sierra,rocky

    if (ports_generation_setup) {
      setupList.push({
        setup: { ...ports_generation_setup }
      })
    } else {
      setupList = JSON.parse(JSON.stringify(ports_generate_setup_list));
    }

    for (let item of setupList) {
      const defaultPortType = permissionData.getRockyPortType()
      item.setup.portType = item.setup.portType || defaultPortType;
      item.setup.referenceType = item.setup.referenceType || (defaultPortType === GAP || defaultPortType === WAVE ? ALL_PINS : SINGLE_PIN);
      item.setup.searchDistance = (!item.setup.filterPinOptions) ||
        item.setup.filterPinOptions.indexOf('distance') < 0 ?
        null : this._distanceToMeter(item.setup.pinDistance);
      item.setup.maxPin = (!item.setup.filterPinOptions) ||
        item.setup.filterPinOptions.indexOf('number') < 0 ?
        null : Number(item.setup.pinNumber);
    }

    return setupList;
  }

  /**
   *  Auto generation port setups
   * @param {Array} port_setups [ { autoGenerationUsage, type, info, positive, negative }, ... ]
   * @param {Object} pcbInfo {  netsList, layers  }
   * @param {Object} setting { portType, referenceType, filterPinOptions , pinNumber, pinDistance }
   * portType -> "pin_group" / "gap" / "circuit"
   * if portType === "pin_group" / "gap"
   *      referenceType -> "pin" / "pinPerRefNet" / "nearbyPins" / "allPins", import
   *  if referenceType === "nearbyPins"
   *       filterPinOptions:[ "distance", "number" ]
   *       pinNumber -> "10"  (number)I
   *       pinDistance-> "10mm", (distance)
   * */
  autoGeneratePorts = (port_setups, pcbInfo, setting = {}) => {
    const { ports_generate_setup_list = [], applyComponents, ports_generation_setup, extractionType } = setting;
    const prev_port_setups = port_setups ? JSON.parse(JSON.stringify(port_setups)) : port_setups;
    // parse the input configurations
    const referenceNets = setting.referenceNets;
    const setupList = this.getSetupList(ports_generate_setup_list, ports_generation_setup);
    // collect the pin coordinates - TODO: include the unit in PCBInfo outside
    let layoutDb = LayoutData.getLayout(setting.designId);
    let unit = layoutDb.GetLayoutUnits();
    if (unit === "mils") {
      unit = "mil"
    }
    if (!pcbInfo) {
      return { port_setups, setupList };
    }
    pcbInfo.scaling = this._distanceToMeter('1 ' + unit);

    this._fillCompPinInfo(pcbInfo, layoutDb);

    // loop through the component pins on the signal nets
    let compRefPins = {};
    let layerRefPins = {};
    let compPinLayers = {};

    let compPortNets = {};

    for (let setup of port_setups) {
      setup = this.findCompPinPorts({
        applyComponents,
        setup,
        compRefPins,
        layerRefPins,
        compPinLayers,
        referenceNets,
        setupList,
        extractionType,
        compPortNets
      });

      const negative = setup.negative
      if (negative.pin || (negative.nets || []).length > 0 || (negative.pins || []).length > 0) {
        this.isExistPorts = true;
      }
    }
    //If referenceNets is greater than 1, the reference port of the pins of the same component must come from the same referenceNet
    if (referenceNets.length >= 2) {
      let reFindPortPins = [];
      for (let comp in compPortNets) {
        //Determine whether the reference ports of the pins of the current component come from the same net
        const allNets = compPortNets[comp].map(item => item.net);
        const nets = [...new Set(allNets)];
        if (nets.length > 1) {
          //Find the net with the most reference pins
          let objGroup = allNets.reduce(function (obj, name) {
            obj[name] = obj[name] ? ++obj[name] : 1;
            return obj;
          }, {});
          let targetNet = null, maxNetLength = 0;;
          for (let net in objGroup) {
            if (objGroup[net] > maxNetLength) {
              maxNetLength = objGroup[net];
              targetNet = net;
            }
          }
          //Re-match reference ports according to the net with the most reference pins
          reFindPortPins.push({
            comp,
            pins: compPortNets[comp].filter(item => item.net !== targetNet).map(item => item.pin),
            net: targetNet
          })
        }
      }

      let compRefPins = {};
      for (let comp of reFindPortPins) {
        for (let pin of comp.pins) {
          const index = port_setups.findIndex(item => item.positive.component === comp.comp && item.positive.pin === pin);
          if (index < 0) {
            continue;
          }
          // re-find the component reference pins
          port_setups[index] = this.findCompPinPorts({
            applyComponents,
            setup: port_setups[index],
            compRefPins,
            layerRefPins,
            compPinLayers,
            referenceNets: [comp.net],
            setupList,
            extractionType,
            compPortNets
          });

          const negative = port_setups[index].negative
          if (negative.pin || (negative.nets || []).length > 0 || (negative.pins || []).length > 0) {
            this.isExistPorts = true;
          }
        }
      }
    }

    let portsSetupList = JSON.parse(JSON.stringify(setupList));
    portsSetupList.forEach(item => { delete item.setup.searchDistance; delete item.setup.maxPin; });

    if (this.errors.length > 0) {
      return { port_setups: prev_port_setups, setupList: portsSetupList };
    }
    return { port_setups, setupList: portsSetupList };
  }

  findCompPinPorts = ({
    applyComponents,
    setup,
    compRefPins,
    layerRefPins,
    compPinLayers,
    referenceNets,
    setupList,
    extractionType,
    compPortNets
  }) => {
    if (setup.portGenerationUsage === "skip" || !includesComp(applyComponents, setup.positive.component)) {
      return setup;
    }

    // find the component reference pins
    let comp = setup.positive.component;
    let pin = setup.positive.pin;

    //get port setup 
    const portSetupIndex = setupList.length === 1 ? 0 : setupList.findIndex(item => item.component === comp);
    if (portSetupIndex < 0) {
      return setup;
    }
    const portSetup = setupList[portSetupIndex].setup;
    const portType = portSetup.portType,
      maxPin = portSetup.maxPin,
      searchDistance = portSetup.searchDistance;
    let refType = portSetup.referenceType;

    // sanity check - gap port with component reference not allowed if the same component have pins on different layers
    if ((portType === GAP && refType !== SINGLE_PIN) || portType === WAVE) {
      let isNewComp = false;
      if (!(comp in compPinLayers)) {
        isNewComp = true;
        compPinLayers[comp] = [];
        if (cachedPcb.compPins[comp] && cachedPcb.compPins[comp].pins) {
          for (let pin of cachedPcb.compPins[comp].pins) {
            if (compPinLayers[comp].indexOf(pin.layer) < 0) {
              compPinLayers[comp].push(pin.layer)
            }
          }
        }
      }

      if (compPinLayers[comp].length > 1) {
        // Automatically change the reference type to single pin, and issue a warning message

        if (portType === WAVE) {
          if (isNewComp) {
            this.addWarning("Component " + comp + " has pins on multiple layers. So wave port is not supported.");
          }
          return setup;
        }
        refType = SINGLE_PIN;
        setupList[portSetupIndex].setup.referenceType = refType;
        if (isNewComp) {
          this.addWarning("Component " + comp + " has pins on multiple layers. Reference type is changed to 'Single Pin'.");
        }
      }
    }

    setup.type = portSetup.portType;

    // overwrite the old port
    let posPinInfo = this._getPinInfo(comp, pin);
    if (!posPinInfo) {
      return setup;
    }
    if (!(comp in compRefPins)) {
      compRefPins[comp] = this._getCompRefPins(comp, referenceNets);
    }
    let refPinList = compRefPins[comp];

    let portRefPin = null;      // single reference pin
    let portRefPinList = null;  // multiple reference pins in the same component
    let portRefNets = null;     // all reference pins in the same component
    if (refType === SINGLE_PIN || portType === CIRCUIT || refPinList.length === 0) {
      // find the nearest pin
      let refPins = null;
      if (refPinList.length > 0) {
        // a reference pin is availabe in the component
        refPins = this._findRefPins(posPinInfo, refPinList, 1, null, null, true);
      }

      if (!refPins || refPins.length === 0) {
        // TODO - for circuit port and gap port, if a reference pin is
        //        not available in the same component, we should find
        //        a nearest pin or via, whichever is closer. Currently
        //        only we only search for a pin
        let pinLayer = posPinInfo.layer;
        if (!(pinLayer in layerRefPins)) {
          layerRefPins[pinLayer] = this._getLayerRefPins(pinLayer, referenceNets);
        }
        refPins = this._findRefPins(posPinInfo, layerRefPins[pinLayer], 1);
      }

      if ((!refPins || refPins.length === 0) && portType !== GAP) {
        // for circuit port and pin group port, we can search for a reference
        // pin on another layer as the last resort
        refPins = this._findRefPins(posPinInfo, refPinList, 1);
      }

      if (refPins && refPins.length > 0) {
        portRefPin = refPins[0];
      }
    }
    else if (refType === PIN_PER_REF_NET) {
      // one nearest pin per each reference net
      portRefPinList = this._findRefPins(posPinInfo, refPinList, null, null, referenceNets, true);
    }
    else if (refType === NEARBY_PINS) {
      // multiple reference pins satisfying the search criteria
      portRefPinList = this._findRefPins(posPinInfo, refPinList, maxPin, searchDistance, null, true);
    }
    else if (refType === ALL_PINS || portType === WAVE) {
      // use all reference pins as reference
      portRefNets = referenceNets;
    }
    else {
      this.addError("Internal error: unknown port reference pin type " + refType);
    }

    if (portType === CIRCUIT) {
      if (!portRefPin) {
        return setup;
      }
      // Circuit port, pin reference only
      setup.negative = new PortSetupNegative({
        type: "pin",
        component: portRefPin.comp,
        pin: portRefPin.number,
        NET: portRefPin.net
      });
      if (!compPortNets[comp]) {
        compPortNets[comp] = [];
      }
      compPortNets[comp].push({ pin, net: portRefPin.net });
    }
    else if (portType === GAP) {
      if (portRefNets) {
        // gap port, component reference with reference nets
        setup.negative = new PortSetupNegative({
          type: "component",
          pins: null,
          nets: referenceNets
        });
      }
      else if (portRefPinList) {
        // gap port, component reference with specified pins in the same component
        setup.negative = new PortSetupNegative({
          type: "component",
          pins: portRefPinList.map(pin => pin.number),
          nets: null,
          PINS_INFO: portRefPinList.map(pin => { return { component: pin.comp, pin: pin.number, net: pin.net } })
        });
        if (setup.negative.pins.length <= 1 && extractionType === "HFSS") {
          this.addWarning("Component " + comp + ": Since extraction is HFSS, it is better not to use a single pin reference.");
        }
      }
      else if (portRefPin) {
        // gap port, pin reference
        setup.negative = new PortSetupNegative({
          type: "pin",
          component: portRefPin.comp,
          pin: portRefPin.number,
          NET: portRefPin.net
        });

        if (!compPortNets[comp]) {
          compPortNets[comp] = [];
        }
        compPortNets[comp].push({ pin, net: portRefPin.net });

        if (extractionType === "HFSS") {
          this.addWarning("Component " + comp + ": Since extraction is HFSS, it is better not to use a single pin reference.");
        }
      }
    }
    else if (portType === PIN_GROUP) {
      if (portRefNets) {
        // pin group port, all pins in reference nets
        setup.negative = new PortSetupNegative({
          type: "pin_group",
          pins: null,
          nets: referenceNets
        });
      }
      else if (portRefPinList) {
        // pin group port, specified reference pins in the same component
        setup.negative = new PortSetupNegative({
          type: "pin_group",
          pins: portRefPinList.map(pin => pin.number),
          nets: null,
          PINS_INFO: portRefPinList.map(item => { return { component: item.comp, pin: item.number, net: item.net } })
        });
      }
      else if (portRefPin) {
        // pin group port, negative group with one pin
        setup.negative = new PortSetupNegative({
          type: "pin_group",
          component: portRefPin.comp,
          pins: [portRefPin.number],
          nets: null,
          PINS_INFO: [{ component: portRefPin.comp, pin: portRefPin.number, net: portRefPin.net }]
        });
        if (!compPortNets[comp]) {
          compPortNets[comp] = [];
        }
        compPortNets[comp].push({ pin, net: portRefPin.net });
      }
    }
    else if (portType === WAVE) {
      // wave port, all pins in reference nets
      setup.negative = new PortSetupNegative({
        type: "component",
        pins: null,
        nets: referenceNets
      });
    }
    else {
      this.addError("Internal error: unknown port type " + portType);
    }
    return setup;
  }

  /** Find the distance in meter from a string with unit
   *  @param distanceStr {String} - distance string with unit
   *  @returns {Number} distance in meter
   */
  _distanceToMeter = (distanceStr) => {
    return distanceStr ? evaluate(distanceStr + ' to m').value : distanceStr;
  };

  /* Find all the component pins and their name, coordinates, net
   * @param {Object} pcbInfo {  netsList, layers  }
   */
  _fillCompPinInfo = (pcbInfo, layoutDb) => {
    if (cachedPcb.pcbInfo === pcbInfo) {
      // the information has been cached before
      return;
    }

    // update the cached pcb
    cachedPcb.pcbInfo = pcbInfo;
    cachedPcb.compPins = {};
    let compPins = cachedPcb.compPins;

    // find all the components and their pins
    let compLayerMap = {};
    for (let layer of pcbInfo.layers || []) {
      if (!layer.mComponentLayer) {
        continue;
      }
      else {
        compLayerMap[layer.mName] = layer.mComponentLayer.mName;
      }
      for (let component of layer.mComponentLayer.mComponents) {
        let compName = component.mName;
        compPins[compName] = {
          pins: [],
          pinMap: {},
          pinNumMap: {}
        };

        if (!component || !layoutDb.mPartMgr) {
          continue;
        }
        // component shift and rotation matrix
        //let scaling = _distanceToMeter('1 ' + component.mPart.mPartDB.mUnit);
        let scaling = pcbInfo.scaling; // the mPartDB above scaling seems to be wrong
        compPins[compName].pins = getComponentPinLocation({
          scaling,
          component,
          compName,
          mPartMgr: layoutDb.mPartMgr,
          addError: this.addError
        })

        // also generate a map for quick search
        for (let pin of compPins[compName].pins) {
          compPins[compName].pinMap[pin.name] = pin;
          compPins[compName].pinNumMap[pin.number] = pin;
        }
      } // for (let component of layer.mComponentLayer.mComponents)
    } // for (let layer of pcbInfo.layers)

    // find the net of each pin
    for (let net of pcbInfo.netsList || []) {
      for (let pin of net.mPinList) {
        let compName = pin.mCompName;
        let pinNumber = pin.mPinNum;
        if (compName in compPins && pinNumber in compPins[compName].pinNumMap) {
          compPins[compName].pinNumMap[pinNumber].net = net.mName;
          if (pin.mMetalLayerName && pin.mMetalLayerName in compLayerMap) {
            compPins[compName].pinNumMap[pinNumber].layer = compLayerMap[pin.mLayerName];
          }
        }
      }
    }
  } // _fillCompPinInfo

  /* Get the information of a component pin by name
   * @param comp {String} component name
   * @param pin {String} pin name
   * @returns {Object} pin information with coordinates
   */
  _getPinInfo(comp, pin) {
    let compPins = cachedPcb.compPins;

    if (!(comp in compPins)) {
      this.addError("Invalid component " + comp);
    }
    else if (!(pin in compPins[comp].pinNumMap)) {
      this.addError("Invalid component pin " + comp + "-" + pin);
    }
    else {
      return compPins[comp].pinNumMap[pin];
    }
  }

  /** Find all the pins on the reference nets in a component
   *  @param comp {String} name of the component
   *  @returns {Array{Object}} reference pins
   */
  _getCompRefPins(comp, refNets = []) {
    if (!(comp in cachedPcb.compPins)) {
      this.addError("Component not found: " + comp);
    }
    return cachedPcb.compPins[comp].pins.filter(pin => refNets.includes(pin.net));
  }

  /** Find all the pins on the reference nets on a layer
   *  @param layer {String} name of the layer
   *  @returns {Array{Object}} reference pins
   */
  _getLayerRefPins(layer, refNets) {
    let refPins = [];
    for (let comp in cachedPcb.compPins) {
      refPins = refPins.concat(this._getCompRefPins(comp, refNets));
    }
    return refPins;
  }

  /** Find the reference pins sorted by distance
   *  @param pinInfo {Object} pin information
   *  @param refPinList {Array{Object}} list of candidate reference pins
   *  @param maxNum {Number} maximum number of pins to return
   *  @param maxDistance {Number} maximum distance from the target pin
   *  @param refNets {Array{String}} if provided, must include one pin per each net in the list
   *  @param sameLayer {bool} requires the reference pins to be on the same layer
   */
  _findRefPins(pinInfo, refPinList, maxNum = null, maxDistance = null, refNetList = null, sameLayer = false) {
    // find the distance between the target pin and all the reference pins
    let pinDistance = refPinList.map(refPin => {
      return {
        d2: (pinInfo.x - refPin.x) * (pinInfo.x - refPin.x) +
          (pinInfo.y - refPin.y) * (pinInfo.y - refPin.y),
        pin: refPin,
        net: refPin.net
      };
    })
      .sort((a, b) => a.d2 - b.d2);

    // filter the pins by layer
    if (sameLayer) {
      pinDistance = pinDistance.filter(pinDist => pinDist.pin.layer === pinInfo.layer);
    }

    // filter the pins by search criterion
    let filteredPinD;
    if (refNetList) {
      let netPin = {};
      for (let pinD of pinDistance) {
        if (!(pinD.net in netPin)) {
          netPin[pinD.net] = pinD;
        }
      }
      filteredPinD = Object.values(netPin);
    }
    else {
      let maxNumber = maxNum !== null && maxNum !== undefined ? maxNum : pinDistance.length;
      filteredPinD = pinDistance.slice(0, maxNumber);
      let maxD2 = maxDistance ? maxDistance * maxDistance : (pinDistance[pinDistance.length - 1] ? pinDistance[pinDistance.length - 1].d2 : null);
      filteredPinD = filteredPinD.filter(pinD => pinD.d2 <= maxD2);
    }

    if (filteredPinD.length === 0 && pinDistance.length > 0) {
      // make sure there is at least one pin
      filteredPinD = [pinDistance[0]];
    }

    return filteredPinD.map(pinD => pinD.pin);
  } // _findRefPins

}

function includesComp(applyComponents, component) {
  if (!applyComponents || applyComponents.includes(component)) {
    return true;
  }
}

function getRefPinsByDistance({
  distance,
  distanceUnit,
  allCompRefPins,
  componentPinInfo,
  maxPins
}) {
  let refPins = [];
  if (distanceUnit === "mm") {
    distance = distance * 39.37;
  }

  const pinLocation = getPinPoint(componentPinInfo.mLocation);
  for (let pin of allCompRefPins) {
    //location -> { xc, yc }
    const location = getPinPoint(pin.pinLocation);
    //find two points distance
    const pointDistance = getTwoPointDistance(location, pinLocation);

    if (pointDistance <= distance) {
      refPins.push({ ...pin, pointDistance });
    }
  }
  refPins = refPins.sort(function (a, b) {
    return a.pointDistance - b.pointDistance;
  })

  if (maxPins !== undefined && refPins.length > maxPins) {
    refPins = refPins.filter((item, index) => index < maxPins);
  }

  return refPins;
}

function getPinPoint(pinLocation) {
  let point = {};
  switch (pinLocation.shapeType) {
    case "circle":
      point = {
        xc: pinLocation.xc,
        yc: pinLocation.yc,
      };
      break;
    case "polygon":
      point = {
        xc: pinLocation.Xs[0],
        yc: pinLocation.Ys[0],
      };
      break;
    default: break;
  }
  return point;
}

/** Find the distance between two points
 * @param {Object} location1 {xc, yc}
 * @param {Object} location2 { xc, yc}
 */
function getTwoPointDistance(location1, location2, xType = "xc", yType = "yc") {
  let a = location1[xType] - location2[xType];
  let b = location1[yType] - location2[yType];

  return Math.sqrt(a * a + b * b);
}

function getViaList(referNetsInfo, componentPinInfo) {
  let viaList = [];

  const pinLocation = getPinPoint(componentPinInfo.mLocation);
  for (let net of referNetsInfo) {
    for (let via of net.mViaList) {
      //position -> {mX,mY}
      const position = via.mLocation.mPosition;
      //find two points distance
      const pointDistance = getTwoPointDistance({ xc: position.mX, yc: position.mY }, pinLocation);
      viaList.push({
        ...via,
        mX: position.mX,
        mY: position.mY,
        distance: Number(pointDistance.toFixed(2)),
        net: net.mName
      })
    }
  }
  return viaList;
}

function getViaListByDistance({
  allViaList,
  distanceUnit,
  distance
}) {
  let viaList = [];
  if (distanceUnit === "mm") {
    distance = distance * 39.37;
  }

  viaList = allViaList.filter(item => item.distance <= distance);
  return viaList;
}

function getComponentPinLocation({ scaling, component, compName, mPartMgr, addError, savePad = false }) {

  if (!component || !component.mLocation || !component.mLocation.mPosition) {
    return []
  }

  // component shift and rotation matrix
  let dx = component.mLocation.mPosition.mX * scaling;
  let dy = component.mLocation.mPosition.mY * scaling;
  // mFlipOnX: x = x, y = -y; 
  // mFlipOnY: x = -x, y = y;
  let xm = component.mLocation.mFlipOnY ? -scaling : scaling;
  let ym = component.mLocation.mFlipOnX ? -scaling : scaling;
  // mCW - true: Clockwise (-1), false: anti-clockwise (1)
  let angle = (component.mLocation.mCW ? -1 : 1) * component.mLocation.mRotAngle * Math.PI / 180.;
  let t = [[xm * Math.cos(angle), - ym * Math.sin(angle)],
  [xm * Math.sin(angle), ym * Math.cos(angle)]];

  // d - x|y
  function getCoordinate(d, x, y) {
    if (d === 'x') {
      return t[0][0] * x + t[0][1] * y + dx;
    } else {
      return t[1][0] * x + t[1][1] * y + dy;
    }
  }
  let pins = [];
  const footPrintSymbol = component.mPart.GetFootPrintSymbol(mPartMgr);
  var partPins = footPrintSymbol ? footPrintSymbol.GetPadGeometries() : [];
  if (partPins) {
    pins = partPins.map((pad, indPad) => {
      // find the pin coordinates
      let x, y;
      if ('mCenter' in pad) {
        x = pad.mCenter.mX;
        y = pad.mCenter.mY;
      }
      else if ('mXs' in pad) {
        x = pad.mXs.map(Number).reduce((a, b) => a + b) / pad.mXs.length;
        y = pad.mYs.map(Number).reduce((a, b) => a + b) / pad.mYs.length;
      }
      else {
        const err = "Unrecognized pad shape " + pad.mGeomType;
        addError ? addError(err) : console.error(err);
      }

      // TODO - find the pin layer if different from the component layer
      let info = {
        comp: compName,
        name: component.mPart.mPinList[indPad].mName,
        number: component.mPart.mPinList[indPad].mNumber,
        x: getCoordinate('x', x, y),
        y: getCoordinate('y', x, y),
        net: null,
        layer: component.mLayerName
      };
      if (savePad) {
        let _pad = pad.Clone();
        if (_pad.mXs && _pad.mYs) {
          _pad.mXs = _pad.mXs.map((_x, index) => getCoordinate('x', _x, pad.mYs[index]));
          _pad.mYs = _pad.mYs.map((_y, index) => getCoordinate('y', pad.mXs[index], _y));
        }
        info.pad = _pad;
      }
      return info;
    });
  }
  return pins;
}

export {
  AutoGeneratePorts,
  getRefPinsByDistance,
  getViaList,
  getViaListByDistance,
  getComponentPinLocation
}