import {
  createProject,
  getAndesV2ProjectList,
  getAndesV2Project,
  getAndesV2ProjectTrash,
  getAndesV2ProjectChildren,
  deleteAndesV2Design,
  deleteAndesV2ProjectByIds,
  clearAndesV2ProjectTrash,
  AndesCopyProject
} from '../../api/Andes_v2/project';
import apiProcess from "../../api/utility";
import { projectRename, getProjectServiceOptionsConfig, updateProjectServiceOptionsConfig } from '../../api/v2/projectCtrl';

/** 
* Get project list
* 2021.03.12
* @return Promise
*/
function getProjectList() {
  return apiProcess(getAndesV2ProjectList);
}

/** 
* Create project
* @param {object} data {id, name, version }
* 2021.03.12
* @return Promise
*/
function createProjectPromise(data) {
  return apiProcess(createProject, data);
}

/** 
* Get project info
* @param {string} projectId
* 2021.03.12
* @return Promise
*/
function getProjectPromise(projectId) {
  return apiProcess(getAndesV2Project, projectId);
}

/** 
* delete project by ids
* @param {array} ids
* 2021.03.23
* @return Promise
*/
function deleteProjectByIds(ids) {
  return apiProcess(deleteAndesV2ProjectByIds, ids);
}

/** 
* Get project all designs and verifications
* @param {string} projectId
* 2021.03.15
* @return Promise
*/
function getProjectChildren(projectId) {
  return apiProcess(getAndesV2ProjectChildren, projectId);
}

/** 
* delete design in project by designIds
* @param {array} designIds
* 2021.03.15
* @return Promise
*/
function deleteDesignInProject(designIds) {
  return apiProcess(deleteAndesV2Design, designIds);
}

/** 
 * get project trash
 * @param {String} typeName typeName
 * 2021.05.24
*/
function getProjectTrash() {
  return apiProcess(getAndesV2ProjectTrash);
}

/** 
 * clear up trash
 * 2021.05.24
*/
function clearProjectTrash() {
  return apiProcess(clearAndesV2ProjectTrash)
}

/** 
 * project rename
 * @param {String} projectId 
 * @param {String} projectName
 * 2021.06.03
*/
function AndesV2ProjectRename({ projectId, projectName }) {
  return apiProcess(projectRename, { projectId, projectName });
}

/** 
 * project copy
 * @param {String} projectId 
 * @param {String} name
 * 2021.07.12
*/
function copyProject(name, projectId) {
  return apiProcess(AndesCopyProject, { name, projectId });
}

/**
 * Get andes project service options config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
function getServiceConfig(projectId) {
  return apiProcess(getProjectServiceOptionsConfig, projectId)
}

/**
 * Save andes service options project config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
function updateProjectServiceConfig({ projectId, options }) {
  return apiProcess(updateProjectServiceOptionsConfig, { projectId, options })
}

export {
  createProjectPromise,
  getProjectList,
  getProjectPromise,
  getProjectChildren,
  deleteDesignInProject,
  deleteProjectByIds,
  getProjectTrash,
  clearProjectTrash,
  AndesV2ProjectRename,
  copyProject,
  getServiceConfig,
  updateProjectServiceConfig
}