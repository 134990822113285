import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TopMenu from '../TopMenu';
import { changeResultType } from '../../store/result/action';
import { recalculateTDRSBRWorkflow } from '../../store/simulation/action';
import ResultContent from '../resultContent';
import endToEndChannelConstructor from '../../../../services/Andes_v2/endToEndChannel/endToEndChannelConstructor';
import { getAndesMultiPCBChannelFinishedTime, getVerificationJson } from '../../../../services/Andes_v2/results'
import { END_TO_END_CHANNEL } from '../../../../constants/treeConstants';

class ChannelResult extends PureComponent {

  constructor() {
    super()
    this.state = {
      currentResultTime: null
    }
  }

  componentDidMount() {
    if (this.props.endToEndChannelId) {
      this.setCurrentTime()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.endToEndChannelId && this.props.endToEndChannelId !== prevProps.endToEndChannelId) {
      this.setCurrentTime(prevProps)
    }
    const { historyList } = this.props;
    if (prevProps.historyList && historyList.length !== prevProps.historyList.length) {
      const seaSimHistory = historyList.find(item => !!item.complianceResult);
      const adsHistory = historyList.find(item => !!item.adsResult);
      this.setState({
        seaSimHistory: seaSimHistory ? true : false,
        adsHistory: adsHistory ? true : false
      })
    }
  }

  async setCurrentTime(prevProps = {}) {
    const { endToEndChannelId, resultKey, historyList } = this.props;

    this.setupInfo = await getVerificationJson(endToEndChannelId, true);
    const adsSetupInfo = await getVerificationJson(endToEndChannelId, true, "ads");
    const complianceInfo = await getVerificationJson(endToEndChannelId, true, "compliance");
    let dataRate;

    if (this.setupInfo) {
      this.runSeasim = false;
      if (complianceInfo.endToEnd && complianceInfo.endToEnd.config && complianceInfo.endToEnd.config.analysis) {
        this.runSeasim = complianceInfo.endToEnd.config.analysis.run_statsim;
      };
      if (adsSetupInfo.endToEnd && adsSetupInfo.endToEnd.adsConfig && Object.keys(adsSetupInfo.endToEnd.adsConfig).length) {
        this.runAds = true;
      };
      if (this.setupInfo.config && this.setupInfo.config.analysis && this.setupInfo.config.analysis.options) {
        dataRate = this.setupInfo.config.analysis.options.datarate
      };
    } else {
      this.setupInfo = null;
      this.runSeasim = false;
      this.runAds = false;
    }

    if ((!this.runSeasim && resultKey === "eyediagram")
      || (!this.runAds && resultKey === "ads")) {
      this.props.changeResultType("channel");
    }

    if (prevProps.historyList && historyList.length !== prevProps.historyList.length) {
      const seaSimHistory = historyList.find(item => !!item.complianceResult);
      const adsHistory = historyList.find(item => !!item.adsResult);
      this.setState({
        seaSimHistory: seaSimHistory ? true : false,
        adsHistory: adsHistory ? true : false
      })
    }

    const time = await getAndesMultiPCBChannelFinishedTime(this.props.endToEndChannelId);
    this.setState({
      currentResultTime: time,
      dataRate
    })
  }

  render() {
    const { resultKey, changeResultType, historyList, endToEndChannelId, recalculateTDRSBRWorkflow, status, interfaceType, leftWidth, layoutLeftWidth } = this.props;
    const { currentResultTime, seaSimHistory, adsHistory, dataRate } = this.state;
    return (<div className='andes-v2-result-main' >
      <TopMenu
        selectedKey={resultKey}
        leftWidth={leftWidth}
        layoutLeftWidth={layoutLeftWidth}
        changeResultType={(key) => changeResultType(key, endToEndChannelId, END_TO_END_CHANNEL)}
        currentResultTime={currentResultTime}
        interfaceType={interfaceType}
        isMultiPCB={true}
        runSeasim={this.runSeasim || seaSimHistory}
        runAds={this.runAds || adsHistory}
      />
      <ResultContent
        resultKey={resultKey}
        id={endToEndChannelId}
        historyList={historyList}
        interfaceType={interfaceType}
        isEndToEnd={true}
        recalculateTDRSBRWorkflow={recalculateTDRSBRWorkflow}
        status={status}
        dataRate={dataRate}
      />
    </div>)
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { channelResultReducer, project: { treeItems, openProjectId } } } = state;
  const { resultKey, endToEndChannelId, historyList } = channelResultReducer;
  const status = getStatus(treeItems, openProjectId, endToEndChannelId);
  const interfaceType = getSerdesType(endToEndChannelId);
  return {
    resultKey,
    endToEndChannelId,
    historyList,
    status,
    interfaceType
  }
}

const mapDispatch = (dispatch) => ({
  changeResultType(key, id, setupType) {
    dispatch(changeResultType(key, id, setupType))
  },
  recalculateTDRSBRWorkflow(workflow, isEndToEnd) {
    dispatch(recalculateTDRSBRWorkflow(workflow, isEndToEnd))
  }
})

export default connect(mapState, mapDispatch)(ChannelResult);


function getStatus(treeItems, projectId, endToEndChannelId) {
  try {
    const item = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId);
    return item.status;
    // const projectItem = treeItems[1].children.find(d => d.id === projectId);
    // return projectItem.children[1].children.find(d => d.id === endToEndChannelId).status;
  } catch (error) {
    return null;
  }
}

function getSerdesType(endToEndChannelId) {
  try {
    const item = endToEndChannelConstructor.getEndToEndChannel(endToEndChannelId);
    return item.type;
  } catch (error) {
    return null;
  }
}