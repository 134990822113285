import { HURAY, HAMMERSTAD_JENSEN } from './index';

function HurayRoughness({
  type = HURAY,
  nodule_radius = "0.5um",
  surface_ratio = "1",
  setting = "low_loss"
}) {
  this.type = type;
  this.nodule_radius = nodule_radius;
  this.surface_ratio = surface_ratio;
  this.setting = setting;
}

function HammerstadJensenRoughness({
  type = HAMMERSTAD_JENSEN,
  rms_roughness = "1um"
}) {
  this.type = type;
  this.rms_roughness = rms_roughness;
}

export {
  HurayRoughness,
  HammerstadJensenRoughness
}

/* "type": enum: [none, hammerstad-jensen, huray],

"rms_roughness": string, length with unit, hammerstad-jensen only,

"nodule_radius": string, radius with unit, huray only

"surface_ratio": float, huray only */