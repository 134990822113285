import {
  createPDN,
  updatePDN,
  getPDNContent,
  deletePDNs,
  getPDNProject,
  createPacakageChip,
  updatePackageChip,
  deletePackageChip,
  parsePowerDomain,
  updatePDNLibraryId,
  getResultInfo,
  getPDNResultHistoryList,
  parseRNetworkName,
  saveCutDesign
} from '../api/pdn/PDNAPICtrl';
import apiProcess from '../api/utility';

/**
 * Create PDN
 * 2019.09.09
 *
 * @export
 * @param {String} designId Design ID
 * @param {JSON} pdnContent PDN content
 * @param {String} pdnName PDN name
 * @param {String} projectId Project ID
 * @returns Promise
 */
export function createPDNPromise({ designId, pdnContent, pdnName, projectId, pdnVersion, libraryId }) {
  return apiProcess(createPDN, { designId, pdnContent, pdnName, projectId, pdnVersion, libraryId }, false, true)
};

/**
 * Update PDN
 * 2019.09.09
 *
 * @export
 * @param {String} designId Design ID
 * @param {JSON} pdnContent PDN content
 * @param {String} pdnId PDN ID
 * @param {String} pdnName PDN name
 * @param {String} projectId Project ID
 * @returns XMLHttpRequest
 */
export function updatePDNPromise({ designId, pdnContent, pdnId, pdnName, projectId, verificationId, pdnVersion, libraryId }) {
  return apiProcess(updatePDN, { designId, pdnContent, pdnId, pdnName, projectId, verificationId, pdnVersion, libraryId })
}

/**
 * Update PDN Library Id
 * 2021.03.12
 *
 * @export
 * @param {String} pdnId PDN ID
 * @param {String} libraryId Library ID
 * @returns XMLHttpRequest
 */
export function updatePDNLibraryIdPromise({ pdnId, libraryId }) {
  return apiProcess(updatePDNLibraryId, { pdnId, libraryId })
}

/**
 * Get PDN content
 * 2019.09.09
 *
 * @export
 * @param {String} pdnId PDN ID
 * @returns Promise
 */
export function getPDNContentPromise(pdnId) {
  return apiProcess(getPDNContent, pdnId)
};

/**
 * Delete PDNs by PDN IDs
 * 2019.09.09
 *
 * @export
 * @param {Array} pdnIds
 * @returns Promise
 */
export function deletePDNsPromise(pdnIds) {
  return apiProcess(deletePDNs, pdnIds)
};

/**
 * Get PDN project by project ID
 * 2019.09.09
 *
 * @export
 * @param {String} projectId Project ID
 * @returns Promise
 */
export function getPDNProjectPromise(projectId) {
  return apiProcess(getPDNProject, projectId)
};

/**
 * Create package-chip
 * 2019.09.09
 *
 * @export
 * @param {JSON} packageParam 
 * {
 *  chip: "string",
 *  id: "",
 *  libraryId: "string",
 *  model: "string",
 *  modelType: "string"
 * }
 * @returns Promise
 */
function createPacakageChipPromise(packageParam) {
  return new Promise((resolve, reject) => {
    apiProcess(createPacakageChip, packageParam).then(res => {
      resolve(res);
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
};

/**
 * Update package-chip
 * 2019.09.09
 *
 * @export
 * @param {JSON} packageParam 
 * {
 *  chip: "string",
 *  id: "string",
 *  libraryId: "string",
 *  model: "string",
 *  modelType: "string"
 * }
 * @returns Promise
 */
export function updatePackageChipPromise(packageParam) {
  return new Promise((resolve, reject) => {
    apiProcess(updatePackageChip, packageParam).then(res => {
      resolve(res);
    }, error => {
      console.error(error);
      resolve(null);
    });
  });
};

/**
 * Delete Package-Chip
 * 2019.09.09
 *
 * @export
 * @param {Array} packageIds package ids
 * @returns Promise
 */
export function deletePackageChipPromise(packageIds) {
  return apiProcess(deletePackageChip, packageIds);
};

/**
 * Save Package-Chip
 * if exsit id, update PackageChip, else create PackageChip
 * 
 * 2019.09.20
 *
 * @export
 * @param {Object} param {
 *                          chip: "string",
 *                          id: "string",
 *                          libraryId: "string",
 *                          model: "string",
 *                          modelType: "string",
 *                          projectId: "string"
 *                        }
 * @returns Promise
 */
export function savePDNPackage(param) {
  return new Promise((resolve, reject) => {
    if (param.id) {
      updatePackageChipPromise(param).then(res => {
        resolve(res);
      });
    } else {
      createPacakageChipPromise(param).then(res => {
        resolve(res);
      });
    }
  })
};

export function getPowerDomainNets(libraryId, powerDomainName) {
  return new Promise((resolve, reject) => {
    apiProcess(parsePowerDomain, { libraryId, powerDomainName }).then(res => {
      if (res) {
        const { connections = [] } = res;
        resolve({ connections });
      } else {
        resolve({ connections: [] })
      }
    }, error => {
      console.error(error);
      resolve(null);
    });
  })
};

export function getPowerDomainRNetwork(libraryId, powerDomainName) {
  return apiProcess(parseRNetworkName, { libraryId, powerDomainName })
}

export function getResultInfoPromise(params) {
  return apiProcess(getResultInfo, params)
}

export function getPDNResultHistoryListPromise(verificationId) {
  return apiProcess(getPDNResultHistoryList, verificationId)
}

export function saveCutDesignPromise(param) {
  return apiProcess(saveCutDesign, param)
}