import React, { Component, createRef, Fragment } from "react";
import { uploadPCB, replacePCB } from '@/services/api/Design/design';
import { LAYOUT, PACKAGE } from '@/constants/designType';
import { PACKAGES } from '@/constants/treeConstants';
import {
  fakeProgress,
  splitDesignFileName
} from '@/services/helper/dataProcess';
import NP from 'number-precision';
import { AC_PRE_LAYOUT, DC_PRE_LAYOUT, ZIP_ACCEPT_STR } from "../../services/PCBHelper/pcbHelper";
import { createPortal } from "react-dom";
import { CloseOutlined, FileZipOutlined, FolderAddOutlined } from '@ant-design/icons';
import { Input, Progress, Upload, Spin, Select, Button } from "antd";
import Panel from "../Panel";
import JSZip from 'jszip';
import { REPLACE_PCB } from "@/constants/treeConstants";
import { SIERRA } from "../../constants/pageType";
import { PRE_LAYOUT } from "../../constants/designVendor";
import designConstructor from "../../services/helper/designConstructor";
import { getDefaultName } from "../../services/helper/setDefaultName";
import { UPLOAD_PCB } from "../../constants/treeConstants";
import "./index.css";

const { Dragger } = Upload;
const { Option, OptGroup } = Select;
class DesignUpload extends Component {

  constructor(props) {
    super(props);
    this.designType = null;
    const { data } = props;
    const { dataType } = data;
    this.designType = dataType === PACKAGES ? PACKAGE : LAYOUT;
    this.designTypeTitle = dataType === PACKAGES ? "Package" : "PCB";
    this.update = false;
    this.state = {
      acceptFormat: null,
      vendor: null,
      pcbList: [],
      uploadVisible: false,
      inputError: null
    }
    this.uploadRef = createRef();
    this.uploadDirectory = createRef();
    this.dialogRoot = document.getElementById("root");
    this.uploadFiles = [];
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { data, pcbVendorList, type } = this.props;
    const { dataType } = data;
    this.designType = dataType === PACKAGES ? PACKAGE : LAYOUT;
    this.designTypeTitle = dataType === PACKAGES ? "Package" : "PCB";

    if (type === UPLOAD_PCB) {
      this.designType = dataType === PACKAGES ? PACKAGE : LAYOUT;
      const findPCB = pcbVendorList.find(item => ![AC_PRE_LAYOUT, DC_PRE_LAYOUT, PRE_LAYOUT].includes(item.vendor));
      if (!findPCB) {
        return;
      }
      let pcbKey = "", accept = "";
      if (findPCB.children && findPCB.children.length) {
        const child = findPCB.children[0];
        pcbKey = child.key;
        accept = child.accept;
      } else {
        pcbKey = findPCB.key;
        accept = findPCB.accept;
      }
      this.setState({
        vendor: findPCB.vendor,
        acceptFormat: accept,
        pcbKey
      })
    }
  }

  uploadPCB = (vendor, format, update) => {
    const { data } = this.props;
    const { dataType } = data;
    this.designType = dataType === PACKAGES ? PACKAGE : LAYOUT;
    this.update = update;
    this.updateMonitorTab()
    this.setState({
      acceptFormat: format,
      vendor
    }, () => {
      if (format === 'directory') {
        const el = this.uploadDirectory.current;
        if (!el) return;
        el.click();
      } else {
        const ele = this.uploadRef.current;
        if (!ele) return;
        ele.click();
      }
    })
  }

  uploadChange = (e) => {
    const { acceptFormat } = this.state;
    let pcbList = []
    const files = e.target.files;
    if (files.length === 1 &&
      (['application/x-zip-compressed', 'application/zip', "application/x-compressed-tar"].includes(files[0].type)
        || acceptFormat === ZIP_ACCEPT_STR)) {
      const name = splitDesignFileName(files[0].name);
      pcbList.push({
        name: name,
        editName: name,
        suffix: "",
        files
      })
    } else {
      const number = files[0].webkitRelativePath.indexOf('/');
      const fileName = files[0].webkitRelativePath.substring(0, number);
      let name = fileName ? fileName : files[0].name;
      let suffix = "";

      if (acceptFormat !== "directory") {
        const { name: pcbName, suffix: pcbSuffix } = splitDesignFileName(files[0].name, true);
        name = pcbName;
        suffix = pcbSuffix;
      }

      pcbList.push({
        name: name,
        editName: name,
        suffix: suffix || "",
        files
      })
    }
    this.setState({
      pcbList,
      loading: pcbList.length ? "Loading file..." : false
    }, () => {
      if (!pcbList.length) {
        return
      }
      this.startUpload(pcbList[0])
    })
  }

  metadata = (metadata) => {
    clearInterval(this.compressTime)
    const progress = NP.strip(parseFloat(metadata.percent.toFixed(0)));
    let { compressProgress } = this.props;
    //Avoid progress updates too fast
    if ((progress - compressProgress) >= 10 || progress === 100) {
      this.props._updateCompressProgress(progress);
    }
  }

  updateMonitorTab = () => {
    //clear monitor box info and open monitor box
    this.props._cleanTabMonitorStatus();
    const { product, projectsDB, projectId } = this.props;
    let tabSelectKeys = ["pcb"], menuType = "simulation", findProject = null;
    if (product === SIERRA) {
      findProject = product === SIERRA ? projectsDB.get(projectId) : projectsDB.getProject(projectId);
      tabSelectKeys = ["monitor"];
      menuType = "upload"
    }

    let info = {
      tabSelectKeys,
      menuType,
      currentProjectId: projectId,
      projectName: findProject ? findProject.name : null,
    }

    this.props._changeTabMenu(info);
    this.props._openTabFooter();
  }

  uploadLayout = (pcbFileInfo) => {
    const { pcbList, vendor } = this.state;
    const fileInfo = pcbFileInfo ? pcbFileInfo : (pcbList && pcbList[0] ? pcbList[0] : null);
    if (!fileInfo) {
      return;
    }
    this.setState({
      loading: "Uploading..."
    })
    !pcbFileInfo && this.updateMonitorTab()
    const { data, _updateDesignName, clearReplacePCBInfo, uploadPromise, replaceDesignPromise, projectId } = this.props;

    const { dataType } = data;
    const uploadName = dataType === PACKAGES ? 'Package' : 'PCB';
    this.props._uploadPCBStart(projectId, uploadName);
    const fileName = `${fileInfo.name}${fileInfo.suffix || ""}`
    this.props._updateMsg(`==> Start uploading ${uploadName}: "${fileName}".`);
    _updateDesignName && _updateDesignName(fileInfo.name, this.designType);
    const _name = fileInfo.name;
    const _uploadPCB = uploadPromise ? uploadPromise : uploadPCB,
      _replacePCB = replaceDesignPromise ? replaceDesignPromise : replacePCB;

    if (this.update) {
      //  replace pcb
      clearReplacePCBInfo && clearReplacePCBInfo(data.id)
      _replacePCB({
        file: fileInfo.files[0],
        files: fileInfo.files,
        designId: data.id,
        name: data.name,
        isZip: true,
        projectId,
        type: this.designType,
        vendor: vendor,
        isNew: "true",
        metadata: this.metadata,
        config: this.config
      }).then(response => {
        this.clearUploadInput();
        this.props._translationFlow(response, data.id);
        this.props.closePanel()
      }, error => {
        const errorMsg = typeof (error) === "string" ? error : "Unknown reason.";
        this.props._updateMsg(`==> Replace failed: ${errorMsg}`);
        //clean upload pcb status(progress,visible,disabled)
        this.props._cleanUploadPCBStatus();
        console.error(error)
        this.clearUploadInput();
        this.props.closePanel();
      })
    } else {
      _uploadPCB({
        file: fileInfo.files[0],
        files: fileInfo.files,
        name: _name,
        isZip: true,
        projectId,
        type: this.designType,
        vendor: vendor,
        isNew: "true",
        config: this.config
      }).then(response => {
        this.clearUploadInput();
        this.props._translationFlow(response);
        this.props.closePanel()
      }, error => {
        const errorMsg = typeof (error) === "string" ? error : "Unknown reason.";
        this.props._updateMsg(`==> Upload failed: ${errorMsg}`);
        //clean upload pcb status(progress,visible,disabled)
        this.props._cleanUploadPCBStatus();
        console.error(error)
        this.clearUploadInput();
        this.props.closePanel();
      })
    }
  }

  clearUploadInput = () => {
    if (this.uploadDirectory && this.uploadDirectory.current) {
      this.uploadDirectory.current.value = '';
    }
    if (this.uploadRef && this.uploadRef.current) {
      this.uploadRef.current.value = '';
    }
    this.setState({
      loading: false
    })
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      // console.debug('onUploadProgress called with', arguments, 'Percent Completed:' + percentCompleted)
      this.props._updateUploadProgress(percentCompleted);
    }
  }

  fileClick = (e) => {
    e.stopPropagation();
  }

  closeModal = () => {
    this.props._cleanUploadPCBStatus();
    this.props.closePanel();
  }

  changeName = (e, name) => {
    const { pcbList } = this.state;
    let _pcbList = [...pcbList];
    const index = pcbList.findIndex(item => item.name === name);
    if (index < 0) {
      return;
    }
    _pcbList[index].editName = e.target.value;
    this.setState({
      pcbList: _pcbList,
      inputError: null
    })
  }

  saveName = (e, name) => {
    const { pcbList } = this.state;
    let _pcbList = [...pcbList];
    const index = pcbList.findIndex(item => item.name === name);
    const designNames = designConstructor.getDesignNames(this.props.projectId);
    //todo multiple design name check for uploading designs
    if (index < 0) {
      return;
    }
    let error = null;
    if (!error && designNames.includes(e.target.value)) {
      error = "Design name already exists!";
    }

    if (error) {
      _pcbList[index].editStatus = false;
      _pcbList[index].editName = name;
    } else {
      _pcbList[index].editStatus = false;
      _pcbList[index].editName = e.target.value;
      _pcbList[index].name = e.target.value;
    }
    this.setState({
      pcbList: _pcbList,
      inputError: error
    })

    setTimeout(() => {
      this.setState({
        inputError: null
      })
    }, 2000)
  }

  changeEditStatus = (name) => {
    const { pcbList } = this.state;
    let _pcbList = [...pcbList];
    const index = pcbList.findIndex(item => item.name === name);
    if (index < 0) {
      return;
    }
    _pcbList[index].editStatus = true;
    this.setState({
      pcbList: _pcbList
    }, () => {
      if (this.inputRef) {
        const { input } = this.inputRef;
        input && input.focus && input.focus();
      }
    })
  }

  delFile = (e, name) => {
    e && e.stopPropagation();
    const { pcbList } = this.state;
    let _pcbList = [...pcbList];
    _pcbList = pcbList.filter(item => item.name !== name);
    this.setState({
      pcbList: _pcbList
    })
  }

  handleFileUpload = (info) => {
    const { fileList } = info || {};
    if (this.uploadFiles.length === fileList.length) {
      const { acceptFormat } = this.state;
      let pcbList = [];

      for (let fileItem of fileList) {
        let name = fileItem.originFileObj.name, suffix = "";
        if (acceptFormat === "directory") {
          const number = fileItem.originFileObj.webkitRelativePath.indexOf('/');
          name = fileItem.originFileObj.webkitRelativePath.substring(0, number);
        } else {
          const { name: pcbName, suffix: pcbSuffix } = splitDesignFileName(name, true);
          name = pcbName;
          suffix = pcbSuffix;
        }

        const index = pcbList.findIndex(item => item.name === name);
        if (index > -1) {
          pcbList[index].files.push(fileItem.originFileObj);
        } else {
          pcbList.push({
            name,
            editName: name,
            suffix: suffix || '',
            files: [fileItem.originFileObj]
          })
        }
      }

      const designNames = designConstructor.getDesignNames(this.props.projectId);
      for (let item of pcbList) {
        if (designNames.includes(item.name)) {
          item.name = getDefaultName({ nameList: designNames, defaultKey: item.name, delimiter: "_", key: "", firstIndex: 1 });
          item.editName = item.name;
        }
        designNames.push(item.name);
      }

      this.setState({
        uploadVisible: false,
        pcbList
      }, () => {
        this.uploadFiles = [];
        //TODO - Support upload multiple pcb
        const fileInfo = pcbList[0];
        if (!fileInfo) {
          this.setState({
            loading: false
          })
          return;
        }
        this.startUpload(fileInfo);
      })
    }
  }

  customRequest = () => {
    return false
  }

  beforeUpload = (file, fileList) => {
    const { pcbList } = this.state;
    this.uploadFiles.push(file)
    this.setState({
      uploadVisible: true,
      loading: "Loading File..."
    })
    const number = file.webkitRelativePath.indexOf('/');
    const fileName = file.webkitRelativePath.substring(0, number);
    let _fileName = fileName ? fileName : file.name;
    const { name, suffix } = splitDesignFileName(_fileName, true);

    let _pcbList = [...pcbList];
    const index = pcbList.findIndex(item => item.name === name);
    if (index > -1) {
      _pcbList[index].files.push(file);
    } else {
      _pcbList.push({
        name,
        editName: name,
        suffix: suffix || '',
        files: [file]
      })
    }

    this.setState({
      pcbList: _pcbList
    })
    return false;
  }

  startUpload = (fileInfo) => {
    const { data, _updateDesignName } = this.props;
    const { dataType } = data;
    const { pcbList } = this.state;
    let _pcbList = [...pcbList];
    const uploadName = dataType === PACKAGES ? 'Package' : 'PCB';
    const { acceptFormat } = this.state;
    const files = fileInfo.files;
    let progress = 2, indexNum = 0;
    const designName = `${fileInfo.name}${fileInfo.suffix || ""}`;
    if (files.length === 1 &&
      (['application/x-zip-compressed', 'application/zip', "application/x-compressed-tar"].includes(files[0].type)
        || acceptFormat === ZIP_ACCEPT_STR)) {
      this.props._updateCompressProgress(-1);
      this.props._updateUploadProgress(0);
      this.setState({
        loading: false
      })
      if (this.update) {
        this.uploadLayout(fileInfo);
      }
    } else {
      if (this.update) {
        this.props._updateMsg(`==> Start compressing ${uploadName}: "${designName}".`);
        _updateDesignName && _updateDesignName(designName, this.designType);
      }
      this.props._updateCompressProgress(progress);
      this.setState({
        loading: "Compressing..."
      })
      this.compressTime = setInterval(() => {
        indexNum += 1;
        progress = fakeProgress(progress, indexNum);
        this.props._updateCompressProgress(progress)
      }, 2000);
      let zip = new JSZip();
      if (files.length === 1) {
        zip.file(designName, files[0]);
      } else {
        for (const file of files) {
          zip.file(file.webkitRelativePath, file);
        }
      }
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, this.metadata).then(blob => {
        const fileObj = new File([blob], `${fileInfo.name}.zip`);
        const index = _pcbList.findIndex(item => item.name === fileInfo.name)
        _pcbList[index].files = [fileObj];
        this.setState({
          pcbList: _pcbList,
          loading: false
        })
        this.props._updateCompressProgress(-1);
        if (this.update) {
          this.props._updateMsg(`==> Finish compressing ${uploadName}.`);
          this.uploadLayout(fileInfo);
        }
      })
    }
  }

  updateVendor = (key) => {
    const { pcbVendorList } = this.props;
    const findItem = pcbVendorList.find(item => {
      if (item.children) {
        return item.children.find(item => item.key === key)
      } else {
        return item.key === key
      }
    });
    if (!findItem || !findItem.vendor) {
      return;
    }
    let accept = findItem.accept;

    if (findItem && findItem.children) {
      const findChild = findItem.children.find(item => item.key === key);
      accept = findChild.accept;
    }
    this.setState({
      vendor: findItem.vendor,
      pcbKey: key,
      acceptFormat: accept
    })
  }

  contentClick = (e) => {
    e && e.stopPropagation && e.stopPropagation()
  }

  render() {
    const { acceptFormat, pcbList, uploadVisible, loading, pcbKey, inputError } = this.state;
    const { panelClassName, compressProgress, pcbVendorList, type, visiblePanel } = this.props;
    const uploadProps = acceptFormat === "directory" ? {
      directory: true,
      webkitdirectory: true
    } : { accept: acceptFormat }
    const _pcbVendorList = pcbVendorList.filter(item => ![AC_PRE_LAYOUT, DC_PRE_LAYOUT, PRE_LAYOUT].includes(item.vendor));
    const showUpload = !pcbList || !pcbList.length || uploadVisible;
    const content = (type !== REPLACE_PCB && visiblePanel ?
      <div onClick={(e) => this.contentClick(e)}>
        <Panel
          className={`upload-pcb-panel ${panelClassName}`}
          position='panel-center-left'
          title={`${this.designTypeTitle} Upload`}
          zIndex={2000}
          onCancel={this.closeModal}
          width={400}
          draggable
          minWidth={300}
          minHeight={180}
        >
          <div className="upload-PCB-content" onClick={(e) => this.contentClick(e)}>
            <div className="upload-pcb-vendor-item">
              <span>{this.designTypeTitle} Format</span>
              <Select
                className="aurora-select"
                value={pcbKey}
                placeholder={"Vendor"}
                optionLabelProp='label'

                onChange={(key) => this.updateVendor(key)}
                popupClassName="aurora-select-dropdown"
              >
                {_pcbVendorList.map(item =>
                  this.props.getPCBDisabled(item, false) ?
                    (item.children ? <OptGroup
                      key={item.key}
                      label={item.title}
                    >
                      {item.children.map(subItem => <Option
                        key={subItem.key}
                        className={`${subItem.className} upload-pcb-sub-option`}
                        value={subItem.key}
                        label={`${item.title} - ${subItem.title}`}
                      >{subItem.title}</Option>)}
                    </OptGroup>
                      : <Option
                        key={item.key}
                        className={item.className}
                        value={item.key}
                        label={item.title}
                      >{item.title}</Option>)
                    : null)}
              </Select>
            </div>
            {(compressProgress > 0) && <Progress
              size={{ height: 12 }}
              strokeColor={'#f5850d'}
              percent={compressProgress}
              format={(percent) => `Compressing: ${percent}%`}
              className="pcb-upload-progress-bar"
            />}
            <Spin spinning={loading ? true : false} tip={loading || ""}>
              {showUpload ?
                <div className="upload-pcb-box">
                  <Dragger
                    multiple={false}
                    {...uploadProps}
                    beforeUpload={this.beforeUpload}
                    customRequest={this.customRequest}
                    onChange={(info) => this.handleFileUpload(info)}
                    showUploadList={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <FolderAddOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag design file / Folder to this area to upload</p>
                  </Dragger>
                </div>
                : null}
              {pcbList && pcbList.length ? <div className="upload-pcb-list">
                {pcbList.map(item => (
                  <li className='upload-pcb-item' key={item.name}>
                    <div className="pcb-name-main">
                      <FileZipOutlined className='upload-pcb-file-icon' />
                      <span className='pcb-name-span'>
                        {item.editStatus ?
                          <Input
                            className='pcb-file-name-input'
                            ref={(input) => { this.inputRef = input; }}
                            defaultValue={item.name}
                            value={item.editName}
                            onChange={(e) => this.changeName(e, item.name)}
                            onBlur={(e) => this.saveName(e, item.name)}
                            onClick={(e) => this.contentClick(e)} />
                          :
                          <span
                            className={"pcb-file-name-content"}//item.error ? "pcb-file-name-content pcb-file-name-error-content" : "pcb-file-name-content"}
                            //title={item.error ? item.error : item.name}
                            onClick={() => { this.changeEditStatus(item.name) }}>{item.name}</span>}
                        {item.suffix || ".zip"}
                      </span>
                    </div>
                    <CloseOutlined
                      className='upload-pcb-del-icon'
                      onClick={(e) => this.delFile(e, item.name)} />
                  </li>
                ))}
              </div> : null}
              {inputError ? <div className="aurora-model-name-error-msg">{inputError}</div> : null}
              {pcbList && pcbList.length ? <div className='upload-pcb-file-button'>
                <Button type='primary' className='upload-pcb-button' onClick={() => this.uploadLayout()}>Upload</Button>
              </div> : null}
            </Spin>
          </div>
        </Panel>
      </div>
      : null
    )
    return type !== REPLACE_PCB && visiblePanel ?
      createPortal(content, this.dialogRoot)
      : <Fragment><input
        type='file'
        ref={this.uploadRef}
        style={{ display: 'none' }}
        accept={acceptFormat}
        onChange={this.uploadChange}
        onClick={(e) => this.fileClick(e)}
      />
        <input
          type='file'
          ref={this.uploadDirectory}
          style={{ display: 'none' }}
          onChange={this.uploadChange}
          webkitdirectory="true"
          directory="true"
          onClick={(e) => this.fileClick(e)}
        />
      </Fragment>;
  }
}

export default DesignUpload