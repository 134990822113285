
import { CustomizedEyeConstructor, EyeConfig } from './custEyeConstructor';

class CustomizedEyeDiagram {

  constructor() {
    // key - byte -> verificationId,
    //     - adr/cmd -> verificationId
    //value: { eyeConfig, eyeResultList }
    //  eyeConfig :{ plot:{ signals,location }, plot_config:{ type, ... } }
    //  eyeResultList:[ { name, hashId,  config, result:{read, write(memory) } }] 
    this.eyeDiagram = new Map(); // bytes, addr, cmd  customized eye diagram result data in all
  }

  getVerificationType(interfaces) {
    if (!interfaces.length) {
      return null;
    } else {
      const name = interfaces[0].Name;
      if (name.includes('Byte')) {
        return 'Byte';
      } else if (name.includes('ADR')) {
        return 'ADR';
      } else if (name.includes('CMD')) {
        return 'CMD';
      } else {
        return null;
      }
    }
  }


  /**
   *
   *
   * @param {*} { id, channelId, signals, memoryName,
   *     interfaces, verificationSubId, channelName, readWriteMode }
   * @param {*} sigLength signal length
   * @returns
   * @memberof EyediagramResult
   */
  getCustomEyeParam({ verificationId, configParams }) {
    return new Promise((resolve, reject) => {
      const eye = this.getResultFromCache(verificationId);
      if (!eye) {
        const customEye = new CustomizedEyeConstructor(configParams);
        this.eyeDiagram.set(verificationId, customEye);
      }
      if (eye && eye.eyeConfig) {
        resolve(eye.eyeConfig);
      } else {
        const customEye = this.eyeDiagram.get(verificationId);
        let eyeConfig = this.getEyeConfigById(verificationId);
        if (!eyeConfig) {
          eyeConfig = new EyeConfig(configParams);
          customEye.updateEyeParamConfig(eyeConfig);
        }
        resolve(eyeConfig);
      }
    });
  };

  // id -> verificationId
  getResultFromCache(id) {
    return this.eyeDiagram.get(id);
  }

  updateEyeConfig({ verificationId, eyeConfig }) {
    const customEye = this.eyeDiagram.get(verificationId);
    if (customEye) {
      customEye.updateEyeParamConfig(eyeConfig);
    }
  }

  updateEyeResult({ verificationId, eyeMode, pic, margin, eyeConfig, interfaceSignals, pngParam }) {
    const customEye = this.eyeDiagram.get(verificationId);
    if (customEye) {
      customEye.updateEyeParamResult({ eyeMode, pic, eyeConfig, margin, interfaceSignals, pngParam });
    }
  }

  getEyeConfigById(id) {
    const eye = this.getResultFromCache(id);
    return eye && eye.eyeConfig ? eye.eyeConfig : null;
  }

  getEyeResultList(id) {
    const eye = this.getResultFromCache(id);
    return eye && eye.eyeResultList ? eye.eyeResultList : [];
  };

  getColorMap(id, name) {
    try {
      const find = this.getEyeResultList(id).find(d => d.name === name);
      return find ? find.eyeConfig.plot_config.type : "line_with_colormap";
    } catch (error) {
      console.error(error);
      return "line_with_colormap"
    }
  }

  getHashId(id, name) {
    const find = this.getEyeResultList(id).find(d => d.name === name);
    return find ? find.hashId : name;
  }

  cleanCache(verificationIds) {
    if (verificationIds && verificationIds.length > 0) {
      const keys = Array.from(this.eyeDiagram.keys()).filter(key => verificationIds.some(id => key.includes(id)));
      keys.forEach(key => this.eyeDiagram.delete(key));
    } else {
      this.eyeDiagram = new Map();
    }
  }
};

const CustomizedEye = new CustomizedEyeDiagram();

export default CustomizedEye;