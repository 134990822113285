import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { ALIYUN } from '../../services/auroraVersion';
import { setPageType } from '../Login/action';
import { clearCCCStatus } from '../CCC/store/action';
import { isChrome } from '../../services/helper/browser';
import DelConfirm from '@/components/DelConfirm';
import { checkoutLicensePromise } from '../../services/License/licenseCtrl';
import history from '../../services/history';
import { showLicenseErrorMsg } from '../../services/api/utility';
import { getDescriptionPromise } from '../../services/authCtrl';
import { getVersionPromise } from '../../services/authCtrl';
import './style.css';

const { Content } = Layout;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browserVisible: false,
      licenseVisible: false,
      needDescription: false,
      height: 730,
      productHeight: 60
    }
  }

  componentWillMount() {
    this.props.setPageType(null);
    this.props.clearCCCStatus();
    getDescriptionPromise().then(res => {
      this.setState({
        needDescription: res.needDescription
      })
    })
  }

  componentDidMount = () => {
    this.resize()
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate = (prevProps) => {
    const { pages } = this.props;
    const { pages: prevPages } = prevProps;
    if ((!prevPages && pages) || (prevPages && pages && prevPages.length !== pages.length)) {
      this.resize()
    }
  }

  resize = () => {
    const mainEle = document.getElementById("root");
    if (mainEle) {
      const height = (mainEle.offsetHeight || 784) - 54;
      const { pages } = this.props;
      const length = pages.length || 1;
      let productHeight = ((height - 20) / length) - 56;
      if (productHeight > 60) {
        productHeight = 60;
      }
      this.setState({
        height: height,
        productHeight: productHeight
      })
    }
  }

  okConfirm = () => {
    this.setState({
      browserVisible: false
    })
  }

  linkClick = async (page, link) => {
    const license = await checkoutLicensePromise(page);
    const chrome = isChrome();
    this.setState({
      browserVisible: chrome ? false : true
    });
    if (!chrome) return;
    if (license) {
      history.push(link);
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const res = await getVersionPromise();
      const { systemVersion } = this.props;
      //eg:20230508 (simulation v1.99)
      if (res && res.version && (!systemVersion || res.version !== systemVersion)) {
        window.location.reload();
        window.location.href = link;
      }
    } else {
      showLicenseErrorMsg();
    }
  }

  render() {
    const { browserVisible, needDescription, productHeight, height } = this.state;
    const { pages, pageStyle } = this.props;
    const errorMsg = 'Non Chrome browser is not supported in the simulation platform.';
    const className = pageStyle === "LCFC" ? "home-banner-lcfc" : "home-banner-background";
    const flexClass = productHeight < 30 ? "aurora-home-flex" : "";
    const _productHeight = productHeight < 30 ? 30 : productHeight;
    return (
      <Content className="aurora-home-page-en" style={{ height }}>
        <div className={`home-banner ${className}`}>
          <div className="overlay"></div>
          <div
            className={`${pages.length !== 1 ? 'aurora-home' : ''} ${flexClass}`}
            style={{
              height: height - 20
            }}>
            {pages.map(page => (
              <div
                id={page.page}
                className={pages.length !== 1 ? 'home-page' : ''}
                key={page.page}
              >
                <div
                  onClick={() => this.linkClick(page.page, page.link)}
                  className={pages.length === 1 ? page.nameClass : `home-page-name ${page.logoClass}`}
                  style={flexClass || pages.length === 1 ? {} : { height: _productHeight + 20 }}
                >
                  <div
                    className={pages.length === 1 ? page.titleClass : ''}
                    style={flexClass || pages.length === 1 ? {} : {
                      height: pageStyle === "LCFC" ? _productHeight - 10 : _productHeight,
                      fontSize: pageStyle === "LCFC" ? _productHeight : _productHeight + 10,
                      lineHeight: `${pageStyle === "LCFC" ? _productHeight : _productHeight + 10}px`
                    }}
                  >
                    {page.title}
                  </div>
                </div>
                {(needDescription && !flexClass) &&
                  <div className={pages.length !== 1 ? 'home-description' : 'home-description-below'}
                    style={
                      pages.length === 1 ? {} : {
                        height: _productHeight + 20,
                        lineHeight: `${_productHeight + 20}px`,
                        left: (_productHeight + 10) * 4 + 70
                      }
                    }
                  >
                    <span>{page.descriptionInfo}</span>
                  </div>
                }
              </div>
            ))}
          </div>
          {this.props.version === ALIYUN && <div className="aliyunHome">
            <span className='aliyunHome-beianhao'>沪公网安备 31011002004820号</span>
            <a className='aliyunHome-beianhao-1' href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">沪ICP备19038855号-1</a>
          </div>}
        </div>
        {
          browserVisible ? <DelConfirm
            data={null}
            removeCancel={true}
            deleteConfirm={this.okConfirm}
            message={errorMsg}
          /> : null
        }
      </Content >
    );
  }
}

const mapState = (state) => {
  const { LoginReducer: { systemVersion } } = state;
  return {
    systemVersion
  }
}

const mapDispatch = (dispatch) => ({
  setPageType(type) {
    dispatch(setPageType(type))
  },
  clearCCCStatus() {
    dispatch(clearCCCStatus());
  }
});

export default connect(mapState, mapDispatch)(Home);