import { getVerificationContentPromise } from '../rockyCtrl';

class ByteSetupInfo {
  constructor() {
    this.getSetupInfo.bind(this);
    this.saveSetupInfo.bind(this);
    this.deleteSetupInfo.bind(this);
  }

  getSetupInfo(id) {
    return new Promise((resolve, reject) => {
      const info = this[id];
      if (info) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getVerificationContentPromise(id).then(res => {
          if (res) {
            resolve(res);
            this[id] = JSON.parse(JSON.stringify(res));
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  saveSetupInfo(id, info) {
    this[id] = { ...info };
  }

  deleteSetupInfo(id) {
    if (this[id]) {
      delete this[id]
    }
  }

}

const Info = new ByteSetupInfo();
export default Info;
