import { ON_DIE_SPICE, PCB_PDN_SPICE, PCB_SPICE, USER_DEFINED_NETLIST, SPICE, CUSTOM_POST_PROCESS, IBIS_AMI } from '../../../constants/libraryConstants';
import http from '../http';
import JSZip from 'jszip';

/**
 * Get rocky user library list
 * 2020/06/01
 * @export
 * @returns
 */
export function getRockyLibraryList() {
  return http.get(`/library/rocky`)
}

/**
 * Get rocky library by library id
 * 2024/08/05
 * @export
 * @returns
 */
export function getRockyLibraryById({libraryId}) {
  return http.get(`/library/${libraryId}/rocky/ibis-file-pkg/info`)
}

/**
 * Create Rocky user library list
 * 2020/05/30
 * 
 * @export
 * @param {array} files
 * @param {array[object]} libraries
 * @param {string} typeName /ibis/spice/vector
 * @param {string} libraryFormat  file/data
 * @returns
 */
export function createRockyLibrary({ files, libraries, typeName, libraryFormat, metaData, config }) {
  let formData = new FormData();
  if (libraryFormat === 'file') {
    //ibis/spice/vector file
    let zip = new JSZip();
    for (let file of files) {
      zip.file(file.fileName, file.file);
    }
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 }
    }, metaData).then(blob => {
      formData.append('file', new File([blob], 'file.zip'));
      formData.append('libraries', JSON.stringify(libraries));
      formData.append('typeName', typeName);
      formData.append('libraryFormat', libraryFormat);
      return http.post(`/library/rocky`, formData, config, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    });
  } else if (['zip', "folder"].includes(libraryFormat)) {
    files.forEach(file => {
      formData.append('file', file.file);
    })
    formData.append('libraries', JSON.stringify(libraries));
    formData.append('typeName', typeName);
    formData.append('libraryFormat', [SPICE, ON_DIE_SPICE, PCB_PDN_SPICE, USER_DEFINED_NETLIST, PCB_SPICE, CUSTOM_POST_PROCESS, IBIS_AMI].includes(typeName) ? libraryFormat : 'file');
    return http.post(`/library/rocky`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  } else if (libraryFormat === 'data') {
    //vector data
    formData.append('libraries', JSON.stringify(libraries));
    formData.append('typeName', typeName);
    formData.append('libraryFormat', libraryFormat);
    return http.post(`/library/rocky`, formData, config, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }
}

/**
 * update Rocky library data vector
 * 2020/05/30
 * 
 * @export
 * @param {data} data
 * @returns
 */
export function updateRockyLibraryData({ libraryId, libraries, typeName, libraryFormat, config }) {
  let formData = new FormData();
  formData.append('libraries', JSON.stringify(libraries));
  formData.append('typeName', typeName);
  formData.append('libraryFormat', libraryFormat);
  return http.put(`/library/${libraryId}/rocky`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

/**
 * delete rocky user library
 * 2020/06/01
 * @param userLibraryIds:array [] String
 * @export
 * @returns
 */
export function delRockyLibraryByIDs(rockyUserLibraryIds) {
  return http.delete(`/library/rocky`,
    {
      params: {
        rockyUserLibraryIds: rockyUserLibraryIds.toString()
      }
    });
}

/**
 * get library data  by library id
 * 2020/06/01
 * @export
 *  @param libraryId
 * @returns
 */
export function getRockyLibraryData(libraryId) {
  return http.get(`/library/${libraryId}/data/rocky`);
}

/**
 * Get rocky user library parse file. response: file
 * 
 * @export
 * @param {String} libraryId  library id
 * @returns
 */
export function getLibraryParseData(libraryId, fileName) {
  if (fileName) {
    return http.get(`/library/${libraryId}/rocky/file/parse?file=${fileName}`);
  }
  return http.get(`/library/${libraryId}/rocky/file/parse`);
}

/**
 * Get rocky library file by library id, response: file
 * 
 * @param libraryId
 * @param fileName folder children file name
 * @export
 * @returns
 */
export function getRockyLibraryFile(libraryId, fileName) {
  if (fileName) {
    return http.get(`/library/${libraryId}/rocky/file?fileName=${fileName}`);
  }
  return http.get(`/library/${libraryId}/rocky/file`);
}

/**
 * get rocky library data (VRM)
 * 
 * @param {string} libraryId
 * @export
 * @returns
 */
export function getRockyLibraryGeneralData(libraryId) {
  return http.get(`/library/${libraryId}/data/rocky/general`);
}

/**
 * get rocky library Macro Modeling status
 * 
 * @param {string} libraryId
 * @export
 * @returns
 */
export function getMacroModelingStatusByLibraryId(libraryId) {
  return http.get(`/library/${libraryId}/rocky/touchstone/status`);
}

/**
 * calculate Zdiff and Z0
 * 2021.04.12
 * @export
 * @returns
 */
export function calculateRockyImpedance(config) {
  return http.post(`/library/impedance/calculate`, {
    ...config
  });
}

/**
 * get trace/via template detail
 * 2021.04.12
 * @export
 * @returns
 */
export function getTemplateDetail(id) {
  return http.get(`/library/rocky/detail?id=${id}`);
}

/**
 * get ebd parse json
 * 2021.09.28
 */
export function getEBDJson(libraryId) {
  return http.get(`/library/${libraryId}/rocky/ebd/parse`);
}

/**
 * copy via and trace
 * 2022.09.05
 */
export function rockyViaTraceCopy({ libraryId, name }) {
  return http.get(`library/rocky/template-copy?libraryId=${libraryId}&name=${name}`)
}

/**
 * library rename
 * 
 * 2023/04/13
 * @param {string} id libraryId
 * @param {string} name new library name
 *  */
export function rockyLibraryRename({ id, name }) {
  return http.post(`library/rocky/rename`, { id, name })
}

/**
 * default decap
 * 
 * 2024/05/15
 * @param {string} 
 * @param {string} 
 *  */
export function getDefaultLibrary() {
  return http.get(`/library/rocky/default`)
}

export function updateDefaultLibrary({ libraryId, libraryType, name, product }) {
  return http.post(`/library/rocky/default`, { libraryId, libraryType, name, product })
}