import React, { PureComponent, Fragment, lazy } from 'react';
import { END_TO_END_CHANNEL, PCB, PCB_PRE_LAYOUT, PCB_CHANNEL, PCB_CHANNEL_RESULT, END_TO_END_CHANNEL_RESULT, PACKAGE, EXPERIMENTS, EXPERIMENTS_RESULT } from '@/constants/treeConstants';
import TopBar from '../TopBar';
import ChannelResult from '../results/channel';
import { topBarResize } from '@/services/helper/topBarResize';
import ContentLayoutLR from '@/components/ContentLayout/layoutLR';
import EndToEndResult from '../results/endToEnd';
import SweepResult from '../results/sweep';
import PCBLayout from './pcbLayout';
import { PCB_LEFT_RIGHT, LEFT_RIGHT_lAYOUT } from '../../../constants/layoutConstants';
import { connect } from 'react-redux';

const ChannelSetup = lazy(() => import("../Channel"));
const EndToEndChannel = lazy(() => import("../EndToEndChannel"));
const SweepContent = lazy(() => import("../Sweep"));
class LayoutLR extends PureComponent {

  getLeft = (width, bottomLinePosition) => {
    const { tabVisible, viewList, siderWidth } = this.props;
    const views = viewList.filter(item => ![PCB, PCB_PRE_LAYOUT, PACKAGE].includes(item));
    switch (views[0]) {
      case PCB_CHANNEL_RESULT:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-lr-result-main'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, overflow: 'hidden', width }}
        >
          <ChannelResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      case END_TO_END_CHANNEL:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <Fragment>
          <div
            id='andes-v2-content-main'
            className='my-aurora-content andes-v2-lr-content-main'
            style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
          >
            <EndToEndChannel />
          </div>
        </Fragment>
      case PCB_CHANNEL:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-lr-content-main'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <ChannelSetup />
        </div>
      case END_TO_END_CHANNEL_RESULT:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-lr-result-main'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, overflow: 'hidden', width }}
        >
          <EndToEndResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      case EXPERIMENTS:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-lr-result-main'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, width }}
        >
          <SweepContent />
        </div>
      case EXPERIMENTS_RESULT:
        return <div
          id='andes-v2-content-main'
          className='my-aurora-content andes-v2-lr-result-main'
          style={{ bottom: tabVisible ? bottomLinePosition.bottom : 0, overflow: 'hidden', width }}
        >
          <SweepResult leftWidth={siderWidth} layoutLeftWidth={width} />
        </div>
      default: return null;
    }
  }

  topBarResize = () => {
    topBarResize('andes-v2-top-bar', this.props.getTopBarWidth());
    topBarResize('andes-v2-prelayout-top-bar', 370);
  }

  getRight = (width) => {
    const { siderWidth } = this.props;
    setTimeout(() => {
      this.topBarResize();
    }, 100)
    return <PCBLayout siderWidth={siderWidth + width} />
  }

  topBar = (width) => {
    return <TopBar width={width} />
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PCB_PRE_LAYOUT, PACKAGE].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) {
      return 1;
    } else if (pcbs.length > 1 && pcbLayout === PCB_LEFT_RIGHT && layout === LEFT_RIGHT_lAYOUT) {
      return 3;
    } else {
      return 2;
    }
  }

  render() {
    const splitSize = this._getSplitSize();
    return (
      <ContentLayoutLR
        {...this.props}
        leftClassName="andes-v2-content-left"
        topBarResize={this.topBarResize}
        getLeft={this.getLeft}
        getRight={this.getRight}
        topBar={this.topBar}
        lineClassName='aurora-layout-split-screen'
        splitSize={splitSize}
      />
    )
  }
};

const mapState = (state) => {
  const { AndesV2Reducer } = state;
  const { project: { selectedKeys, pcbLayout, layout } } = AndesV2Reducer;
  return {
    selectedKeys,
    pcbLayout,
    layout
  };
}

export default connect(mapState, null)(LayoutLR);

