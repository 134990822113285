import { SHARED_BY_ME, SHARED_WITH_ME } from "../share/constants";

export default function treeItem({ name, key, dataType, ...restProps }) {
  this.name = name;
  this.key = key;
  this.dataType = dataType;
  this.children = [];
  Object.keys(restProps).map(key => this[key] = restProps[key])
};

function treeDataType(dataType, prefix) {
  if (prefix) {
    return prefix + '_' + dataType;
  };
  return dataType;
}

function getDataTypePrefix(key) {
  const arrToString = [SHARED_BY_ME, SHARED_WITH_ME].join('|');
  const reg = new RegExp(`^(${arrToString})`, 'ig');
  return key && key.match(reg) ? key.match(reg)[0] : null;
};
export { treeDataType, getDataTypePrefix }