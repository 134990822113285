
import { AURORA_AAF_DB, CADENCE_BRD, ALTIUM, ANSYS, CADENCE_MCM, CADENCE_SIP, ALLEGRO_ALG, PRE_LAYOUT, AEDB, SPD, ANSYS_EDB_FLOW, ODB_PP } from '@/constants/designVendor';
import { ROCKY, SIERRA, FASTPI, ANDES_V2, CASCADE } from '@/constants/pageType';
import { PACKAGES, CASCADE_PACKAGES, AC, DC } from '@/constants/treeConstants';
import getDevelopMode from '../api/cookies';
import { PCB } from '../../constants/treeConstants';
import { LAYOUT, PACKAGE as PCB_PACKAGE } from '../../constants/designType';

const ANF_V2_V4 = 'ANF_V2_V4' // ANF use AEDB flow
const ROCKY_PACKAGES = 'ROCKY_packages';
const ZIP_ACCEPT_STR = ".tgz,.zip,.rar,.7z,.tar,.gz,.tar.gz,.xz,.tar.xz,.tar.7z,.tar.bz2,.bz2";
const AC_PRE_LAYOUT = 'AC Pre-Layout', DC_PRE_LAYOUT = 'DC Pre-Layout';
function getPCBList(type, product) {
  switch (type) {
    case ANDES_V2:
      return [PRE_LAYOUT, CADENCE_BRD, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB];
    case ROCKY:
      return [PRE_LAYOUT, CADENCE_BRD, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB, ODB_PP];
    case PACKAGES:
      const defaultList = [CADENCE_BRD, CADENCE_MCM, CADENCE_SIP, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB, SPD];
      if (product === ANDES_V2) {
        defaultList.unshift(PRE_LAYOUT);
      }
      return defaultList;
    case FASTPI:
      return [CADENCE_BRD, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB, SPD, ODB_PP];
    case CASCADE:
      return [AC_PRE_LAYOUT, DC_PRE_LAYOUT, CADENCE_BRD, CADENCE_MCM, CADENCE_SIP, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB, SPD, ODB_PP];
    case CASCADE_PACKAGES:
      return [AC_PRE_LAYOUT, CADENCE_BRD, CADENCE_MCM, CADENCE_SIP, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB, SPD]
    case ROCKY_PACKAGES:
      return [PRE_LAYOUT, CADENCE_BRD, CADENCE_MCM, CADENCE_SIP, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB]
    case SIERRA:
      return [PRE_LAYOUT, CADENCE_BRD, CADENCE_MCM, CADENCE_SIP, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB, SPD, ODB_PP];
    default:
      return [CADENCE_BRD, ANSYS, ANF_V2_V4, AEDB, AURORA_AAF_DB];
  }
}

function getPCBItem(vendor) {
  switch (vendor) {
    case PRE_LAYOUT:
      return {
        title: "Pre-Layout",
        vendor: PRE_LAYOUT,
        accept: "pre_layout",
        key: "pre_layout",
        className: "pcb-pre-layout"
      }
    case AC_PRE_LAYOUT:
      return {
        title: AC_PRE_LAYOUT,
        vendor: PRE_LAYOUT,
        accept: AC,
        key: "ac_pre_layout",
        className: "pcb-pre-layout"
      }
    case DC_PRE_LAYOUT:
      return {
        title: DC_PRE_LAYOUT,
        vendor: PRE_LAYOUT,
        accept: DC,
        key: "dc_pre_layout",
        className: "pcb-pre-layout"
      }
    case CADENCE_BRD:
      return {
        title: "Allegro BRD",
        vendor: CADENCE_BRD,
        accept: ".brd,.zip",
        key: "allegro"
      }
    case CADENCE_MCM:
      return {
        title: "Allegro MCM",
        vendor: CADENCE_MCM,
        accept: ".mcm,.zip",
        key: "allegro_mcm",
        // develop: true
      }
    case CADENCE_SIP:
      return {
        title: "Allegro SIP",
        vendor: CADENCE_SIP,
        accept: ".sip,.zip",
        key: "allegro_sip",
      }
    case AURORA_AAF_DB:
      return {
        title: "Aurora",
        vendor: AURORA_AAF_DB,
        accept: "directory",
        key: "Aurora"
      }
    case ALTIUM:
      return {
        title: "Altium PCB ASCII",
        vendor: ALTIUM,
        accept: ".pcbdoc",
        key: "altiumPCB"
      }
    case ANSYS:
      return {
        title: "Ansys ANF (v2)",
        vendor: ANSYS,
        accept: "directory",
        key: "Ansys"
      }
    case ANF_V2_V4:
      return {
        title: "Ansys ANF v2 Via EDB",
        vendor: ANSYS_EDB_FLOW,
        accept: "directory",
        key: ANF_V2_V4
      }
    case ALLEGRO_ALG: // Development Mode
      return {
        title: "Allegro Alg",
        vendor: ALLEGRO_ALG,
        accept: ".alg",
        key: "allegroAlg"
      }
    case AEDB:
      return {
        title: "Ansys EDB",
        vendor: AEDB,
        accept: "directory",
        key: "AEDB",
        children: [{
          title: "Folder",
          vendor: AEDB,
          accept: "directory",
          key: "AEDB_folder"
        }, {
          title: "File (*.def)",
          vendor: AEDB,
          accept: ".def",
          key: "AEDB_file"
        }]
      }
    case SPD:
      return {
        title: "Cadence SPD",
        vendor: SPD,
        accept: ".spd",
        key: "SPD"
      }
    case ODB_PP:
      return {
        title: "ODB++",
        vendor: ODB_PP,
        accept: "directory",
        key: "ODB_PP",
        children: [{
          title: "Folder",
          vendor: ODB_PP,
          accept: "directory",
          key: "ODB_PP_folder"
        }, {
          title: "File",
          vendor: ODB_PP,
          accept: ".tgz,.zip,.rar,.7z,.tar,.gz,.tar.gz,.xz,.tar.xz,.tar.7z,.tar.bz2,.bz2",
          key: "ODB_PP_file"
        }]
      }
    default: return {};
  }
}

function getPCBUploadList(type, product) {
  let pcbList = getPCBList(type, product).map(vendor => {
    return getPCBItem(vendor)
  })
  if (getDevelopMode()) {
    pcbList.push(getPCBItem(ALLEGRO_ALG));
  }
  return pcbList;
}

function postLayoutItem() {
  return {
    title: "Post-Layout",
    key: "post_layout"
  }
}

function getDisplayDesignList(designs = []) {
  return [
    { name: PCB, type: LAYOUT },
    { name: "Package", type: PCB_PACKAGE }
  ].map(item => {
    return { name: item.name, children: designs.filter(it => it.type === item.type || it.designType === item.type) }
  })
}

export {
  getPCBUploadList,
  getDisplayDesignList,
  ZIP_ACCEPT_STR,
  AC_PRE_LAYOUT,
  DC_PRE_LAYOUT,
  postLayoutItem
}