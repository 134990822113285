import {
  calcDistanceOfTwoPoints,
  isTwoLinesIntersect,
  calculatePlumbLine,
  calcDistance,
  getPoint
} from '../helper/calcPointsDistance';
import { SERDES_TYPES } from '../PCBHelper';
import { getPreLayoutAggressorsBySignal } from './preLayout';

class Aggressors {
  constructor() {
    this.netsPosition = new Map();
    this.signalsInfo = new Map();
    this.signalGroups = new Map();
    this.signalGroupNameMap = new Map();
  }

  getSignalGroups = (signalName) => {
    const groupName = this.signalGroupNameMap.get(signalName);
    return this.signalGroups.get(groupName);
  }
  /**
   * Calculate the intersection of signal nets */
  calcIntersectionOfSignalNets = (ICComps, channelSignals, netsList) => {
    if (!ICComps.length || !channelSignals.length) {
      return []
    };

    let _signals = JSON.parse(JSON.stringify(channelSignals));
    //group signals by connected components
    _signals.forEach(signal => {
      const filterComps = ICComps.filter(item => item.pins.find(it => it.signal === signal.name));
      signal.connComps = filterComps.map(item => item.name);
      const group = signal.connComps.join("_");
      const prevGroups = this.signalGroups.get(group);
      this.signalGroupNameMap.set(signal.name, group);
      if (!prevGroups) {
        this.signalGroups.set(group, [signal]);
      } else {
        this.signalGroups.set(group, [...prevGroups, signal]);
      }
    })

    const groupList = Array.from(this.signalGroups.values());
    for (let signals of groupList) {
      const { k, b, x, y } = this.getPlumbLineOfSignals(signals, netsList);
      for (let sigItem of signals) {
        if (!sigItem.nets_P.length && !sigItem.nets_N.length) {
          this.signalsInfo.set(sigItem.name, {
            nets_P: [...sigItem.nets_P],
            nets_N: [...sigItem.nets_N],
            plumbLine: { k, b, x, y },
            intersectCoordinates: []// list
          });
          continue;
        }
        //calculateInter intersect Coordinates
        const intersectCoordinates = this.calculateIntersectionPoints([...sigItem.nets_P, ...sigItem.nets_N], { k, b, x, y })
        this.signalsInfo.set(sigItem.name, {
          nets_P: [...sigItem.nets_P],
          nets_N: [...sigItem.nets_N],
          plumbLine: { k, b, x, y },
          intersectCoordinates // list
        })
      }
    }
  }

  getAggressorsByNet = (ICComps, signal, signals) => {
    if (!ICComps.length || !signal || !signals.length) {
      return []
    };
    const currentSignal = this.signalsInfo.get(signal.name);
    if (!currentSignal) {
      return [];
    }
    /*  nets_P: [...signal.nets_P],
        nets_N: [...signal.nets_N],
        intersectCoordinates: [ { mX, mY, layerID }, { mX, mY, layerID }, { mX, mY, layerID}] */

    const intersectCoordinates = currentSignal.intersectCoordinates;
    if (!intersectCoordinates) {
      return [];
    }
    const _midpointLayerIDs = intersectCoordinates.map(item => item.layerID);

    let distanceList = [];

    for (let sigItem of signals) {
      const _signalInfo = this.signalsInfo.get(sigItem.name);
      if (signal.name === sigItem.name || !_signalInfo.intersectCoordinates) {
        continue;
      }
      //filter points by layer ID
      const targetPoints = _signalInfo.intersectCoordinates.filter(item => _midpointLayerIDs.includes(item.layerID));
      if (!targetPoints.length) {
        continue;
      }

      //calculate distance
      const distance = calcDistance(intersectCoordinates, targetPoints);
      distanceList.push({
        name: sigItem.name,
        distance
      })
    }
    distanceList = distanceList.sort((a, b) => a.distance - b.distance);
    //Find the two closest signals
    return distanceList.filter((item, index) => index <= 1).map(item => item.name);
  }

  /**
   * Calculate the intersection pointS of line segment and mid-perpendicular
   * @param {Number} k Plumb line slope, y = kx + b
   * @param {Number} b Plumb line intercept, y = kx + b
   * @param {Number} x Plumb Line parallel to the y axis, x = n;
   * @param {Number} y Plumb Line parallel to the x axis,  y = n;
   * @param {Array} allNets nets list
   *  */
  calculateIntersectionPoints = (allNets, { k, b, x, y }) => {
    let intersectionPoints = [];
    for (let net of allNets) {
      const findNet = this.netsPosition.get(net);
      /*findNet:  [{
          net,
          start: { ...geometry.mStart },
          end: { ...geometry.mEnd },
          layerID
        }] */
      if (!findNet) {
        continue;
      }
      let intersectionPoint = null;
      for (let item of findNet) {
        const isIntersect = isTwoLinesIntersect({
          k, b, x, y,
          start: item.start,
          end: item.end
        })

        if (isIntersect) {
          const _intersectionPoint = getPoint({
            k, b, x, y,
            start: item.start,
            end: item.end
          })
          intersectionPoint = { ..._intersectionPoint, layerID: item.layerID }
          break;
        }
      }
      if (intersectionPoint) {
        intersectionPoints.push(intersectionPoint)
      }
    }
    return intersectionPoints;
  }


  /**
   * get Signals Plumb Line
   * @param {*} signals all signals [ { name, group, nets_P, nets_N }]
   * @param {*} netsList pcb nets list
   * @returns 
   */
  getPlumbLineOfSignals = (signals, netsList) => {
    /*  /* 
    netsList:
    mGeomList:[
    { mRefGeom:{
      mGeometry:{
        mGeomType: "Line",
        mStart:{
          mGeomType: "Pnt"
          mX: 3527.26
          mY: 521
        },
        mEnd:{
          mGeomType: "Pnt"
          mX: 3539.25
          mY: 532.99
        }
      }
    }}
    ] */
    const allSignalNets = signals.reduce((prev, curr) => { return [...prev, ...curr.nets_P, ...curr.nets_N] }, [])

    let maxGeometry = { geometryLength: -Infinity };
    for (let net of allSignalNets) {
      //find net by pcb nets list
      const findNet = netsList.find(item => item.mName === net);
      if (!findNet) {
        continue;
      }
      let netsPositionList = [];
      for (let item of findNet.mGeomList) {
        const geometry = item.GetNetRefGeom().mGeometry;
        if (geometry && (["Line", "Larc"].includes(geometry.mGeomType))) {
          //calculate line length by start point and end point
          const geometryLength = calcDistanceOfTwoPoints(geometry.mStart, geometry.mEnd);
          //get max length point
          if (geometryLength > maxGeometry.geometryLength) {
            maxGeometry = {
              start: { ...geometry.mStart },
              end: { ...geometry.mEnd },
              geometryLength,
              layerID: item.GetLayerID()
            }
          }
          netsPositionList.push({
            net,
            start: { ...geometry.mStart },
            end: { ...geometry.mEnd },
            layerID: item.GetLayerID()
          });
        }
      }
      this.netsPosition.set(net, netsPositionList);
    }
    //Calculate the vertical line of the longest trace
    if (maxGeometry.start === undefined || maxGeometry.end === undefined) {
      return { k: null, b: null, x: null, y: null };
    }
    const { k, b, x, y } = calculatePlumbLine(maxGeometry.start, maxGeometry.end);
    return { k, b, x, y };
  }
}

function updateAggressorsBySelectedSignals({
  signals,
  components,
  netsList,
  isPreLayoutChannel,
  selectedSignals,
  analysisChannels,
  adsSignals,
  type = "victim"
}) {
  let list = [];
  if (type === "victim") {
    list = [...analysisChannels]
  } else if (type === "signalName") {
    list = [...adsSignals]
  }

  const _signals = signals.filter(item => selectedSignals.includes(item.name));
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));

  const aggressors = new Aggressors();

  if (!isPreLayoutChannel) {
    //calculate signal nets plump line and calculate the intersection of plump line and net trace 
    aggressors.calcIntersectionOfSignalNets(ICComps, _signals, netsList);
  }

  for (let signalItem of list) {
    if (!selectedSignals.includes(signalItem[type])) {
      continue;
    }
    let signalAggressors = [];
    const signal = signals.find(it => it.name === signalItem[type]) || {};
    if (isPreLayoutChannel) {
      signalAggressors = getPreLayoutAggressorsBySignal(ICComps, signal, _signals);
    } else {
      //get same connected components signals
      const sameGroupSignals = aggressors.getSignalGroups(signalItem[type]) || [..._signals];
      signalAggressors = aggressors.getAggressorsByNet(ICComps, signal, sameGroupSignals);
    }

    signalItem.aggressors = [...signalAggressors];
  }
  return list;
}

export default Aggressors;
export {
  updateAggressorsBySelectedSignals
}