import React, { Component } from "react";
import EditableTable from "../../../../components/EditableTable";
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Divider, Button } from 'antd';
import { getIbisPackageList, getPartLibraryListBySearch } from "../../../../services/Sierra/library";
import { PART_IBIS_LIBRARY } from "../../../../constants/libraryConstants";
import "./index.css";

const columns = [{
  title: "Part Number",
  dataIndex: "partNumber",
  key: "partNumber",
  width: "30%",
  textWrap: 'word-break',
  ellipsis: true,
  sorter: (a, b) => a && a.partNumber && b && b.partNumber && a.partNumber.localeCompare(b.partNumber),
},
{
  title: "IBIS File",
  dataIndex: "file",
  key: "file",
  width: "35%",
  textWrap: 'word-break',
  ellipsis: true,
},
{
  title: "Component",
  dataIndex: "component",
  key: "component",
  width: "35%",
  textWrap: 'word-break',
  ellipsis: true,
}]
class IBISLibrary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      componentVisible: false,
      ibisComponentList: [],
      libraryExist: false
    }

    columns[0].title = () => {
      return <div>
        <span>Part Number</span>
        {/*  <Tooltip
          overlayClassName="icon-tooltip"
          title="Add New Part Number">
          <Icon
            type="plus-square"
            className="sierra-part-library-add-icon"
            onClick={(e) => this.addNewPart(e)}
          />
        </Tooltip> */}
      </div>
    }

    columns[0].onCell = (record) => {
      return {
        edit: true,
        dataIndex: "partNumber",
        record,
        handleSave: this.editPartNumber
      }
    }

    columns[0].render = (text, record) => {
      return (
        <div className="sierra-part-library-table-part-text">
          <span title={text}>{text}</span>
          <Tooltip
            overlayClassName="icon-tooltip"
            title="Delete the partNumber."
          >
            <CloseOutlined
              className="sierra-part-library-delete-icon"
              onClick={(e) => this.deletePart(e, record)} />
          </Tooltip>
        </div >
      );
    }

    columns[1].render = (text, record) => {
      const { checkFiles } = this.props;
      const fileNotExist = (text && checkFiles && checkFiles.length && checkFiles.includes(text));
      return {
        children: <div className="sierra-part-library-table-text">
          <span title={text} style={{ paddingRight: fileNotExist ? 16 : 0 }}>{text}</span>
          {fileNotExist ? <Tooltip
            overlayClassName="icon-tooltip"
            title="The file is not uploaded to the library.">
            <QuestionCircleOutlined className="sierra-part-library-help-icon" />
          </Tooltip> : null}
        </div>,
        props: {
          tdClick: (e) => this.openTableCell(e, record, "file")
        }
      };
    }

    columns[1].onCell = (record) => {
      if (!record.partNumber) {
        return {
          edit: false
        }
      }
      return {
        edit: true,
        dataIndex: "file",
        record,
        handleSave: this.editFileName
      }
    }

    columns[2].render = (text, record) => {
      const { checkFiles } = this.props;
      const fileNotExist = !record.partNumber || (record.file && checkFiles && checkFiles.length && checkFiles.includes(record.file));
      return {
        children: <div className="sierra-part-library-table-text">
          <span title={text}>{text}</span>
        </div>,
        props: {
          tdClick: (e) => this.openTableCell(e, record, fileNotExist ? "notExist" : "component")
        }
      }
    }

    columns[2].onCell = (record) => {
      if (!record.partNumber || !record.file) {
        return {
          edit: false
        }
      }
      const { componentVisible } = this.state;
      const { checkFiles } = this.props;
      if (checkFiles && checkFiles.length && checkFiles.includes(record.file)) {
        return {
          edit: true,
          dataIndex: "component",
          record,
          handleSave: this.editFileComponent
        }
      } else if (componentVisible !== record.partNumber) {
        return {
          edit: true,
          record: { ...record },
          dataIndex: 'component'
        }
      } else if (componentVisible && componentVisible === record.partNumber) {
        const { ibisComponentList } = this.state;
        return {
          edit: "select",
          options: [...(ibisComponentList || [])],
          dataIndex: "component",
          record,
          handleSave: this.editFileComponent
        }
      }
    }
  }

  addNewPart = (e) => {
    e && e.stopPropagation()
    const { data } = this.props
    let _data = [...data];
    if (!!_data.find(item => !item.id)) {
      return;
    }
    const itemData = {
      id: "",
      partNumber: "",
      file: "",
      component: ""
    }
    _data.unshift(itemData)
    this.setState({
      componentVisible: false,
      ibisComponentList: []
    })
    this.props.saveData({ data: _data })
  }

  deletePart = (e, record) => {
    e && e.stopPropagation()
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.partNumber === record.partNumber);
    if (index < 0) {
      return;
    }
    const delPart = record.id ? { ..._data[index] } : null;
    _data.splice(index, 1)
    this.setState({
      componentVisible: false,
      ibisComponentList: []
    })
    this.props.saveData({ data: _data, delPart })
  }

  editPartNumber = async (record, prevRecord) => {
    const { data } = this.props;
    if (prevRecord.partNumber === record.partNumber) {
      return
    }
    let _data = [...data];
    const index = _data.findIndex(item => (prevRecord.partNumber && item.partNumber === prevRecord.partNumber)
      || (!prevRecord.partNumber && !item.id && !item.partNumber));
    if (index < 0) {
      return;
    }

    const modelList = await getPartLibraryListBySearch({ partNumbers: [record.partNumber], type: PART_IBIS_LIBRARY });
    const findModel = (modelList || []).find(item => item.partNumber === record.partNumber);
    if (findModel) {
      this.props.updateMessage("This part number already exists.")
      return;
    }

    _data[index].partNumber = record.partNumber;
    this.setState({
      componentVisible: false,
      ibisComponentList: []
    })
    this.props.saveData({ data: _data, editedPart: { ..._data[index] }, index })
  }

  openTableCell = async (e, record, type) => {
    let visible = this.state[`${type}Visible`];

    if (visible === record.partNumber) {
      return;
    }
    if (["notExist", "file"].includes(type)) {
      this.setState({
        componentVisible: false,
        ibisComponentList: []
      })
      return;
    }
    let ibisComponentList = [];
    const modelList = await getPartLibraryListBySearch({ partNumbers: [record.partNumber], type: PART_IBIS_LIBRARY });
    const findModel = (modelList || []).find(item => item.partNumber === record.partNumber);

    const libraryExist = findModel && findModel.libraryId;
    const res = findModel && findModel.libraryId ? await getIbisPackageList(findModel.libraryId) : null;
    if (res && res.length > 0) {
      ibisComponentList = res.map(item => item.component)
    }
    this.setState({
      [`${type}Visible`]: record.partNumber,
      ibisComponentList,
      libraryExist
    })
  }

  editFileName = (record, prevRecord) => {
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.partNumber === record.partNumber);
    if (index < 0) {
      return;
    }
    _data[index].file = record.file;
    this.setState({
      componentVisible: false,
      ibisComponentList: []
    })
    this.props.saveData({ data: _data, editedPart: { ..._data[index] }, prevFile: prevRecord.file, checked: true })
  }

  editFileComponent = (record) => {
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.partNumber === record.partNumber);
    if (index < 0) {
      return;
    }
    _data[index].component = record.component;
    this.setState({
      ibisComponentList: [],
      componentVisible: null
    })
    this.props.saveData({ data: _data, editedPart: { ..._data[index] } })
  }

  render = () => {
    const { data, pageSize, page, totalElements } = this.props;
    return <div>
      <div className="part-library-menu-list">
        {this.props.uploadExcelRender()}
        <Divider type="vertical" />
        <Button type="link" className="part-library-add-button" onClick={(e) => this.addNewPart(e)}>Add Part Number</Button>
        <Divider type="vertical" />
        {this.props.checkFileButton()}
      </div>
      <div>
        <EditableTable
          columns={columns}
          dataSource={data || []}
          rowKey={(record) => `${record.partNumber}_${record.component}`}
          size="small"
          pagination={{
            position: "bottom",
            pageSize,
            current: page,
            hideOnSinglePage: true,
            onChange: this.props.onPageChange,
            total: totalElements
          }}
        />
      </div>
    </div>
  }
}

export default IBISLibrary;