import CeGeomTools from './CeGeomTools';
import CeGeometry from './CeGeometry';
import CeCircle from './CeCircle';
import CeVertex from './CeVertex';
import CeVertexArc from './CeVertexArc';
import CeArc from './CeArc';
import CeSquare from './CeSquare';
import CeLocation from './CeLocation';
import GCPolygon from './GCPolygon';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeLine = function () {
  CeGeometry.call(this, "Line");
  this.mStart = new CeVertex();
  this.mEnd = new CeVertex();
}

// subclass extends superclass
CeLine.prototype = Object.create(CeGeometry.prototype);
CeLine.prototype.constructor = CeLine;

CeLine.prototype.SetData = function (x1, y1, x2, y2) {
  this.mStart.SetData(x1, y1);
  this.mEnd.SetData(x2, y2);
}

CeLine.prototype.CopyFrom = function (fromObj) {
  this.SetData(fromObj.mStart.mX, fromObj.mStart.mY, fromObj.mEnd.mX, fromObj.mEnd.mY);
}

CeLine.prototype.Clone = function () {
  var clnObj = new CeLine();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeLine.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.GetDataString();
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeLine.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

CeLine.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mStart.GetDataString());
  defData.push(this.mEnd.GetDataString());
  return defData;
}

CeLine.prototype.UpdateByString = function (dataVals) {
  // inner-diameter, outer-diameter
  if (dataVals.size() !== 4)
    return false;

  var startVals = new StringList();
  startVals.push(dataVals.get(0));
  startVals.push(dataVals.get(1));
  this.mStart = new CeVertex();
  this.mStart.UpdateByString(startVals);

  var endVals = new StringList();
  endVals.push(dataVals.get(2));
  endVals.push(dataVals.get(3));
  this.mEnd = new CeVertex();
  this.mEnd.UpdateByString(endVals);

  return true;
}

CeLine.prototype.equals = function (obj) {
  if (!(obj instanceof CeLine))
    return false;

  if (this.mStart.equals(obj.mStart) === false ||
    this.mEnd.equals(obj.mEnd) === false)
    return false;

  return true;
}

CeLine.prototype.ConvertToGCPolygon = function () {
  var xArray = [];
  var yArray = [];

  xArray[0] = this.mStart.mX;
  yArray[0] = this.mStart.mY;
  xArray[1] = this.mEnd.mX;
  yArray[1] = this.mEnd.mY;
  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(2, xArray, yArray, false);
  return gcPolygon;
}

CeLine.prototype.ConvertToGCPolygons = function (section) {

  var polyList = [];
  if (section == null) {
    var gcPoly = this.ConvertToGCPolygon();
    polyList.push(gcPoly);
    return polyList;
  }

  var length = this.mStart.ToPointDistance(this.mEnd);
  //        length = DoubleRounding.RoundDecimal(length);
  var angle = CeGeomTools.PointToXaxisAngle(this.mEnd.mX - this.mStart.mX, this.mEnd.mY - this.mStart.mY);
  angle = angle * 180.0 / Math.PI;
  var gcPolygon = null;
  if (section instanceof CeCircle) {
    var circle = section;
    var startPnt1 = new CeVertex();
    startPnt1.SetData(0.0, 0.5 * circle.mDiameter);
    var endPnt1 = new CeVertexArc();
    endPnt1.SetData(0.0, -0.5 * circle.mDiameter, 0.0, 0.0, true);
    var halfCircle1 = CeArc.ArcToLineSegments(startPnt1, endPnt1);
    var num1 = halfCircle1.mNumPnts;

    var startPnt2 = new CeVertex();
    startPnt2.SetData(length, -0.5 * circle.mDiameter);
    var endPnt2 = new CeVertexArc();
    endPnt2.SetData(length, 0.5 * circle.mDiameter, length, 0.0, true);
    var halfCircle2 = CeArc.ArcToLineSegments(startPnt2, endPnt2);
    var num2 = halfCircle2.mNumPnts;
    var numPnts = num1 + num2;
    var xArray = [];
    var yArray = [];
    for (var i = 0; i < num1; i++) {
      xArray[i] = halfCircle1.mXs[i];
      yArray[i] = halfCircle1.mYs[i];
    }
    for (var i = 0; i < num2; i++) {
      xArray[num1 + i] = halfCircle2.mXs[i];
      yArray[num1 + i] = halfCircle2.mYs[i];
    }
    gcPolygon = new GCPolygon();
    gcPolygon.SetData(numPnts, xArray, yArray);
  } else if (section instanceof CeSquare) {
    var square = section;
    var xArray = [];
    var yArray = [];
    xArray[0] = this.mStart.mX;
    yArray[0] = this.mStart.mY - 0.5 * square.mDiameter;
    xArray[1] = this.mStart.mX + length;
    yArray[1] = this.mStart.mY - 0.5 * square.mDiameter;
    xArray[2] = this.mStart.mX + length;
    yArray[2] = this.mStart.mY + 0.5 * square.mDiameter;
    xArray[3] = this.mStart.mX;
    yArray[3] = this.mStart.mY + 0.5 * square.mDiameter;
    gcPolygon = new GCPolygon();
    gcPolygon.SetData(4, xArray, yArray);
  }

  if (gcPolygon != null) {
    var loc = new CeLocation();
    loc.SetData(this.mStart.mX, this.mStart.mY, angle, true);
    gcPolygon.Transform(loc);
    polyList.push(gcPolygon);
  }

  return polyList;
}

CeLine.prototype.mConvertToGCPolygon = function (section) {
  var polyList = [];
  var xArray = [];
  var yArray = [];
  xArray[0] = this.mStart.mX;
  yArray[0] = this.mStart.mY;
  xArray[1] = this.mEnd.mX;
  yArray[1] = this.mEnd.mY;
  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(2, xArray, yArray);
  var width = 0.5 * section.mDiameter;
  gcPolygon.mWidth = width;
  polyList.push(gcPolygon);
  return polyList;
}

CeLine.prototype.Scaling = function (unitFactor) {
  this.mStart.Multiply(unitFactor);
  this.mEnd.Multiply(unitFactor);
}

CeLine.prototype.GetEnd = function () {
  return this.mEnd;
}

CeLine.prototype.GetStart = function () {
  return this.mStart;
}

CeLine.prototype.Transform = function (celocation) {
  this.mStart.Transform(celocation.mRotAngle, celocation.mCW, celocation.mPosition);
  this.mEnd.Transform(celocation.mRotAngle, celocation.mCW, celocation.mPosition);
}


export default CeLine;