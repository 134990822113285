import { call, put, takeEvery, fork, all, select } from 'redux-saga/effects';
import { DELETE_ROLE, UPDATE_ROLE } from './actionType';
import { deleteRole, updateRole } from '@/services/api/v2/roles';
import { jumpToPageAction, getRoleAndPermissionList } from '../../actionCreators';
import { SUCCESS, CHECK_FAIL } from '@/constants/returnCode';
import { message } from 'antd';
import checkError from '@/services/api/checkError';

function* deleteUserRole(action) {
  yield takeEvery(DELETE_ROLE, function* (action) {
    try {
      const { id } = action;
      const { reducer: { pagination: { current } } } = yield select();
      const responseData = (yield call(deleteRole, id)).data;
      if (responseData.code === SUCCESS) {
        yield put(getRoleAndPermissionList());
        yield put(jumpToPageAction(current, 'role'));
        message.success('Delete role successfully!', 2);
      } else if (responseData.code === CHECK_FAIL) {
        // yield put() error msg
        message.error(responseData.msg, 2);
      } else {
        message.error('Delete role failed!', 2);
        console.error(responseData.msg);
      }
    } catch (error) {
      message.error('Delete role failed!', 2);
      if (checkError(error) !== 401) {
        console.error(error);
      }
    }
  })
}

function* editRole(action) {
  yield takeEvery(UPDATE_ROLE, function* (action) {
    try {
      const { reducer: { pagination: { current } } } = yield select();
      const { id, information } = action;
      const { name, description, permissions } = information;
      const data = {
        name,
        description,
        permissions
      }
      const responseData = (yield call(updateRole, id, data)).data;
      if (responseData.code === SUCCESS) {
        yield put(getRoleAndPermissionList());
        yield put(jumpToPageAction(current, 'role'));
        message.success('Update role successfully!', 2);
      } else if (responseData.code === CHECK_FAIL) {
        // yield put() error msg
        message.error('Update role failed!', 2);
      } else {
        message.error('Update role failed!', 2);
        console.error(responseData.msg);
      }
    } catch (error) {
      message.error('Update role failed!', 2);
      if (checkError(error) !== 401) {
        console.error(error);
      }
    }
  })
}


function* AdminRoleSagas() {
  yield all([
    fork(deleteUserRole),
    fork(editRole),
  ])
}

export default AdminRoleSagas;
