import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Radio, Switch } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { saveTemplateConfig } from '../store/SignOffTemplate/action';
import { numberCheck } from '../../../services/helper/dataProcess';
import './index.css';

class RLSpecIntervalPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rlMin: '1',
      rlMax: ''
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 550),
      maxHeight: getPanelMaxHeight(offset, 400)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { config = {} } = this.props;
    this.setState({
      rlMin: config.rlMin || '1',
      rlMax: config.rlMax || ''
    })
  }

  closeModal = () => {
    const { rlMin, rlMax } = this.state;
    const { config } = this.props;
    this.props.saveConfig({ ...config, rlMax, rlMin });
    this.props.closeModal(false);
  }

  changeValue = (e, type) => {
    const value = e.target.value;
    this.setState({
      [type]: value
    })
  }

  inputBlur = (e, type) => {
    const value = this.state[type];
    const error = numberCheck(value);
    if (error) {
      this.setState({
        [type]: ""
      })
    }
  }

  render() {
    const { maxWidth, maxHeight, rlMin, rlMax } = this.state;
    const setting = { defaultWidth: 400 }
    const content = (
      <Panel
        className='cascade-top-template-option-panel'
        title={<div className='cascade-top-template-option-panel-name'>Impedance Spec Check Frequency Range</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="200"
      >
        <div className='cascade-top-template-option-panel-main'>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main template-title-item template-rl-spec-span'>Min</span>
            <Input
              className='template-rl-spec-input'
              value={rlMin}
              size='small'
              addonAfter={"MHz"}
              onChange={(e) => this.changeValue(e, 'rlMin')}
              onBlur={(e) => this.inputBlur(e, 'rlMin')}
            />
          </div>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main template-title-item template-rl-spec-span'>Max</span>
            <Input
              className='template-rl-spec-input'
              value={rlMax}
              size='small'
              addonAfter={"MHz"}
              onChange={(e) => this.changeValue(e, 'rlMax')}
              onBlur={(e) => this.inputBlur(e, 'rlMax')}
            />
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    SignOffTemplate: { data = {} },
  } } = state;
  const { config = {} } = data || {};

  return {
    config
  };
}

const mapDispatch = (dispatch) => ({
  saveConfig(config) {
    dispatch(saveTemplateConfig(config, true))
  }
})

export default connect(mapState, mapDispatch)(RLSpecIntervalPanel);