import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Table from '@/components/EditableTable';
import { getInterface, getInterfaces, deleteInterface } from '../../store/actionCreators';
import { reloadInterface } from '../../../../Verification/store/action';

const columns = [{
  title: 'Name',
  dataIndex: 'name',
  width: '25%'
}, {
  title: 'Type',
  dataIndex: 'type',
  width: '25%'
}, {
  title: 'Number of Lanes',
  dataIndex: 'lane_numbers',
  width: '25%'
}, {
  title: 'Action',
  dataIndex: 'action',
  width: '25%'
}]

class Interfaces extends PureComponent {
  constructor(props) {
    super(props);

    columns[3].render = (text, record, index) => (
      <span><a onClick={() => this.editInterface(record)}>Edit</a> | <a onClick={() => this.deleteInterface(record)}>Delete</a></span>
    )
  }

  componentDidMount() {
    this.props.getInterfacesList();
  }

  render() {
    const { interfaces } = this.props;
    return (
      <Table
        rowKey={record => record.name}
        dataSource={interfaces}
        columns={columns}
        size='middle'
        bordered={false}
        pagination={false}
        className='serdes-table-auto'
      />
    )
  }

  editInterface = (record) => {
    const { name, lane_numbers } = record;
    this.props.getInterface({ name, lane_numbers });
  }

  deleteInterface = (record) => {
    const { name } = record;
    this.props.deleteInterface(name);
    this.props.reloadInterface(true);
  }
}

const mapState = (state) => {
  const { interfaces } = state.explorerSerdes;
  return {
    interfaces
  }
}

const mapDispatch = (dispatch) => ({
  getInterfacesList() {
    dispatch(getInterfaces());
  },
  getInterface({ name, lane_numbers }) {
    dispatch(getInterface({ name, lane_numbers }));
  },
  deleteInterface(name) {
    dispatch(deleteInterface(name));
  },
  reloadInterface(reload) {
    dispatch(reloadInterface(reload))
  }
})

export default connect(mapState, mapDispatch)(Interfaces);