import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { LEFT_RIGHT_lAYOUT, SINGLE_PAGE_LAYOUT, TOP_BOTTOM_LAYOUT } from '@/constants/layoutConstants';
import { setPageType } from '../../pages/Login/action';
import { PCB, PACKAGE, PCB_PRE_LAYOUT } from '../../constants/treeConstants';

class ContentLayout extends PureComponent {

  componentWillMount() {
    const { pageType } = this.props;
    this.props._setPageType(pageType);
  }

  getLayout = () => {
    const { layout, viewList } = this.props;
    if (viewList.length > 1 && viewList.every(v => [PCB, PACKAGE, PCB_PRE_LAYOUT].includes(v))) {
      return this.props.layoutSingle();
    } else if (viewList.length > 1 && layout === LEFT_RIGHT_lAYOUT) {
      return this.props.layoutLR();
    } else if (viewList.length > 1 && layout === TOP_BOTTOM_LAYOUT) {
      return this.props.layoutTB();
    } else if (viewList.length <= 1 || layout === SINGLE_PAGE_LAYOUT) {
      return this.props.layoutSingle();
    } else {
      return null;
    }
  }

  render() {
    return this.getLayout();
  }
}

const mapDispatch = (dispatch) => ({
  _setPageType(type) {
    dispatch(setPageType(type));
  }
})

export default connect(null, mapDispatch)(ContentLayout);