import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import DelConfirm from '@/components/DelConfirm';
import TreeSider from './treeSider';
import { getPopMessage } from '@/services/helper/popMessage';
import {
  renderTitle,
  getLibraryDetail,
  createLibrary
} from '../../../services/Himalayas';
import {
  updateProjectMenu,
  addProject,
  createProject,
  cancelCreateProject,
  deleteProject,
  updateExpand,
  renameChannel,
  renameProject,
  cleanTrash,
  updateTrash
} from '../store/project/action';
import {
  updateLibraryMenu,
  deleteLibrary,
  saveEditedLibrary,
  openLibraryFolder
} from '../store/library/action';
import {
  EDIT,
  LIBRARY,
  LIBRARY_FILE,
  LIBRARY_FOLDER,
  PROJECT,
  PROJECTS,
  SPICE_CARD_TEMPLATE,
  SPICE_CARD_TEMPLATES,
  TRASH
} from '../../../constants/treeConstants';
import LibraryUploadPanel from '../../../components/LibraryUpload';
import FileContentPanel from "../../../components/LibraryUpload/FileContentPanel";
import libraryConstructor from '../../../services/Himalayas/library/libraryConstructor';
import { HIMALAYAS } from '../../../constants/pageType';
import {
  getLibraryAccept,
  getLibrarySpecial,
  getLibraryTitle,
  getLibraryTitleSuffix,
  getLibrarySelectOptions
} from '../../../services/Library';
import {
  parseFileAndCreateSpiceCard,
  deleteSpiceCard
} from '../store/spiceCardTemplate/action';
import "./index.css";
import { PCB_SUBCKT, SOC_SPICE, SOC_WRAPPER } from '../../../constants/libraryConstants';
import LibraryEditPanel from '../Library/libraryEditPanel';

class HimalayasTree extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: {
        delConfirmVisible: false,//confirm of deleting item(pcb,project,...) panel visible
        editVisible: false // library add and edit panel visible
      }
    }
    this.delConfirmInfo = null;
    this.uploadItemData = null;
    this.uploadRef = createRef();
  }

  componentDidMount = () => {
    //init tree, get library and project list and trash list
    this.props._updateLibraryMenu();
    this.props._updateProjectMenu();
    this.props._updateTrash();
  }

  deleteConfirm = (e, data) => {
    this.setState({
      visible: { ...this.state.visible, delConfirmVisible: false }
    })
    this.delConfirmInfo = null;
    this.delItem(null, data);
  }

  cancelDel = () => {
    this.setState({
      visible: { ...this.state.visible, delConfirmVisible: false }
    });
    this.delConfirmInfo = null;
  }

  addItem = (e, itemData) => {
    e && e.stopPropagation();
    switch (itemData.dataType) {
      case PROJECTS:
        this.props._addProject();
        break;
      case LIBRARY:
        this.addLibrary(itemData);
        break;
      case SPICE_CARD_TEMPLATES:
        this.uploadSpiceCardClick(e, itemData);
        break;
      default: break;
    }
  }

  addVdeItem = (itemData, vde) => {
    this.addLibrary({ ...itemData, vde });
  }

  editItem = (e, itemData) => {
    e && e.stopPropagation();
    switch (itemData.dataType) {
      case LIBRARY_FILE:
        this.showLibraryContent(itemData);
        break;
      case LIBRARY_FOLDER:
        this.props._openLibraryFolder(itemData, itemData.type);
        break;
      default: break;
    }
  }

  delItem = (e, itemData) => {
    e && e.stopPropagation();
    switch (itemData.dataType) {
      case PROJECT:
        this.props._deleteProject(itemData.id);
        break;
      case LIBRARY_FILE:
      case LIBRARY_FOLDER:
        this.props._deleteLibrary(itemData);
        break;
      case SPICE_CARD_TEMPLATE:
        this.props._deleteSpiceCard(itemData);
        break;
      case TRASH:
        this.props._cleanTrash();
        break;
      default: break;
    }
  }

  deleteClick = (e, itemData) => {
    e && e.stopPropagation();
    this.delConfirmInfo = { ...itemData }
    this.setState({
      visible: {
        delConfirmVisible: true
      }
    })
  }

  renameClick = (e, itemData) => {
    e && e.stopPropagation();
    const type = itemData.dataType === PROJECT ? "Project" : "Channel";
    this.setState({
      [`rename${type}Info`]: { ...itemData }
    })
  }

  saveRename = (itemData, type) => {
    //type -> Project/Channel
    if (this.props[`_rename${type}`]) {
      this.props[`_rename${type}`](itemData)
    }
    this.setState({
      [`rename${type}Info`]: null
    })
  }

  cancelRename = (type) => {
    this.setState({
      [`rename${type}Info`]: null
    })
  }

  addLibrary = (itemData) => {
    //itemData -> {key,name}
    //key -> library type
    this.setState({
      visible: {
        ...this.state.visible,
        editVisible: true
      }
    })
    this.libraryInfo = {
      modelType: itemData.key,
      modelName: itemData.name,
      vde: itemData.vde
    }
  }

  editClick = (e, itemData) => {
    e && e.stopPropagation();
    if (itemData.dataType === LIBRARY_FILE && [PCB_SUBCKT, SOC_SPICE, SOC_WRAPPER].includes(itemData.type)) {
      this.libraryInfo = {
        modelType: itemData.type,
        modelName: itemData.name,
        library: {
          id: itemData.id,
          name: itemData.name,
        },
        type: EDIT
      }
      this.setState({
        visible: {
          ...this.state.visible,
          editVisible: true
        }
      })
    }
  }

  showLibraryContent = (itemData) => {
    //itemData -> {key,name}
    //key -> library type
    this.setState({
      visible: {
        ...this.state.visible,
        editVisible: true
      }
    })
    this.libraryInfo = {
      modelType: itemData.type,
      modelName: itemData.name,
      library: {
        id: itemData.id,
        name: itemData.name,
      }
    }
  }

  _closeLibraryPanel = (filesInfo) => {
    this.setState({
      visible: {
        ...this.state.visible,
        editVisible: false
      }
    })
    this.props._openLibraryFolder(null, this.libraryInfo.modelType, filesInfo);
    this.libraryInfo = null;

  }

  getPanel = () => {
    const { modelType, library, type } = this.libraryInfo || {};
    const { expandedKeys } = this.props;

    const libraryList = libraryConstructor.getLibraryValues(modelType);
    const title = getLibraryTitle(modelType);
    const suffix = getLibraryTitleSuffix(modelType);
    const libraryId = library ? library.id : null;
    if (libraryId && type === EDIT) {
      //show library file content
      return <LibraryEditPanel
        title={`Edit - ${library.name}`}
        closeModal={this._closeLibraryPanel}
        saveLibrary={this.props._saveEditedLibrary}
        libraryId={libraryId}
        libraryName={library.name}
        modelType={modelType}
        editType={type}
        library={libraryConstructor.getLibrary(modelType, libraryId)}
      />
    }
    if (libraryId && type !== EDIT) {
      //show library file content
      return <FileContentPanel
        title={`${title} - ${library.name}`}
        getLibraryFileContent={getLibraryDetail}
        closeModal={this._closeLibraryPanel}
        libraryId={libraryId}
        fileName={library.name}
        modelType={modelType}
      />
    }

    return <LibraryUploadPanel
      {...this.libraryInfo}
      uploadTitle={title.match("Netlist") ? `Import ${title}` : `Import ${title} ${suffix}`}
      title={title}
      product={HIMALAYAS}
      importText={suffix}
      accept={getLibraryAccept(modelType)}
      special={getLibrarySpecial(modelType)}
      libraryOptions={getLibrarySelectOptions(modelType)}
      libraryList={libraryList}
      expandedKeys={expandedKeys}
      createLibrary={createLibrary}
      closeLibraryPanel={this._closeLibraryPanel}
      updateLibraryMenu={this.props._updateLibraryMenu}
      expandMenu={this.props._updateExpand}
    />
  }

  uploadSpiceCardClick = (e, itemData) => {
    e && e.stopPropagation();
    const ele = this.uploadRef.current;
    this.uploadItemData = { ...itemData };
    if (!ele) return;
    ele.click();
  }

  uploadSpiceCardChange = (e) => {
    const file = e.target.files[0];
    const number = file.webkitRelativePath.indexOf('/');
    let fileName = file.webkitRelativePath.substring(0, number);
    fileName = fileName ? fileName : file.name;
    //get api to parse file and create spice card template
    this.props._parseFileAndCreateSpiceCard({ fileName, file, uploadItemData: this.uploadItemData });
    this.uploadRef.current.value = '';
  }

  render = () => {
    const { visible: { delConfirmVisible, editVisible }, renameProjectInfo } = this.state;
    let errorMsg = this.delConfirmInfo ? getPopMessage(this.delConfirmInfo.dataType, this.delConfirmInfo.dataType) : "";
    return (
      <Fragment>
        <TreeSider
          renderTitle={(item) => renderTitle(item, this.actions(), { ...this.props })}
          renameProjectInfoId={renameProjectInfo && renameProjectInfo.id}
        />
        {delConfirmVisible ? <DelConfirm
          data={this.delConfirmInfo}
          deleteConfirm={this.deleteConfirm}
          cancelDel={this.cancelDel}
          message={errorMsg}
        /> : null}
        {editVisible ? this.getPanel() : null}
        <input
          type='file'
          ref={this.uploadRef}
          style={{ display: 'none' }}
          accept={".xlsm"}
          onChange={(e) => this.uploadSpiceCardChange(e)}
        />
      </Fragment>
    )
  }

  actions = () => {
    return {
      addItem: this.addItem,
      addVdeItem: this.addVdeItem,
      deleteClick: this.deleteClick,
      delItem: this.delItem,
      editItem: this.editItem,
      renameClick: this.renameClick,
      saveRename: this.saveRename,
      cancelRename: this.cancelRename,
      editClick: this.editClick,
      createProject: this.props._createProject,
      cancelCreateProject: this.props._cancelCreateProject,
      renameProjectInfo: this.state.renameProjectInfo,
      renameChannelInfo: this.state.renameChannelInfo
    }
  }
}

const mapState = (state) => {
  const { HimalayasReducer: { project, } } = state;
  const { expandedKeys, openProjectId } = project;
  return {
    expandedKeys,
    openProjectId
  }
}

const mapDispatch = (dispatch) => ({
  _updateProjectMenu() {
    dispatch(updateProjectMenu())
  },
  _updateLibraryMenu() {
    dispatch(updateLibraryMenu())
  },
  _addProject() {
    dispatch(addProject())
  },
  _createProject(data) {
    dispatch(createProject(data))
  },
  _cancelCreateProject() {
    dispatch(cancelCreateProject())
  },
  _deleteProject(id) {
    dispatch(deleteProject(id))
  },
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _deleteLibrary(itemData) {
    dispatch(deleteLibrary(itemData))
  },
  _parseFileAndCreateSpiceCard({ fileName, file, uploadItemData }) {
    dispatch(parseFileAndCreateSpiceCard({ fileName, file, uploadItemData }))
  },
  _deleteSpiceCard(itemData) {
    dispatch(deleteSpiceCard(itemData))
  },
  _renameChannel(itemData) {
    dispatch(renameChannel(itemData))
  },
  _renameProject(itemData) {
    dispatch(renameProject(itemData))
  },
  _cleanTrash() {
    dispatch(cleanTrash())
  },
  _updateTrash() {
    dispatch(updateTrash())
  },
  _saveEditedLibrary(library) {
    dispatch(saveEditedLibrary(library))
  },
  _openLibraryFolder(itemData, libraryType, filesInfo) {
    dispatch(openLibraryFolder(itemData, libraryType, filesInfo))
  }
});

export default connect(mapState, mapDispatch)(HimalayasTree);