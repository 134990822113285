import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd'
import TopMenu from '../TopMenu';
import { changeResultType } from '../../store/result/action';
import ResultContent from '../resultContent';
import { getVerificationJson } from '../../../../services/Andes_v2/results';
import { recalculateTDRSBRWorkflow } from '../../store/simulation/action';
import channelConstructor from '../../../../services/Andes_v2/channel/channelConstructor';
import sweepConstructor from '../../../../services/Andes_v2/sweep/sweepConstructor';
import { EXPERIMENTS } from '../../../../constants/treeConstants';
import { updateExperimentIds } from '../../store/sweep/action';
import { VERIFY_SUCCESS } from '../../../../constants/verificationStatus';
import './result.css';

const { Option } = Select;
class SweepResult extends PureComponent {

  constructor() {
    super()
    this.state = {
      experimentList: [],
      updateExperimentListStatus: false
    }
  }

  componentDidMount() {
    const { channelId, experimentTables } = this.props;
    if (channelId) {
      this.getChannelResultInfo();
    }

    if (experimentTables && experimentTables.length > 0) {
      const experimentList = experimentTables.filter(item => item.status === VERIFY_SUCCESS).map(it => ({ id: it.id, name: it.name }));
      this.setState({ experimentList, updateExperimentListStatus: 'first' });
    }
  }

  componentDidUpdate(prevProps) {
    const { channelId } = this.props;
    if (prevProps.channelId && channelId !== prevProps.channelId) {
      this.getChannelResultInfo();
    }

    // if (!_.isEqual(experimentTables, prevProps.experimentTables)){
    //   const experimentList = experimentTables.filter(item => item.status === VERIFY_SUCCESS).map(it => ({ id: it.id, name: it.name }));
    // }
  }

  async getChannelResultInfo() {
    const { channelId, resultKey } = this.props;

    this.setupInfo = await getVerificationJson(channelId);
    const adsSetupInfo = await getVerificationJson(channelId, false, "ads");
    const complianceInfo = await getVerificationJson(channelId, false, "compliance");

    if (this.setupInfo) {
      this.interfaceType = this.setupInfo.type;
      if (complianceInfo && complianceInfo.config && complianceInfo.config.analysis) {
        this.runSeasim = complianceInfo.config.analysis.run_statsim;
      };
      if (adsSetupInfo && adsSetupInfo.adsConfig && Object.keys(adsSetupInfo.adsConfig).length) {
        this.runAds = true;
      };
      if (this.setupInfo.config && this.setupInfo.config.analysis && this.setupInfo.config.analysis.options) {
        this.dataRate = this.setupInfo.config.analysis.options.datarate
      };
    } else {
      this.setupInfo = null;
      this.runSeasim = false;
      this.runAds = false;
      this.dataRate = '';
    }

    if ((!this.runSeasim && resultKey === "compliance")
      || (!this.runAds && resultKey === "ads")) {
      this.props.changeResultType("channel");
    }
  }

  changeExperimentKey = (value) => {
    const { experimentTables } = this.props;
    const experimentList = experimentTables.filter(item => value.includes(item.id));
    this.setState({ experimentList, updateExperimentListStatus: true });
  }

  changeUpdateExperimentListStatus = () => {
    this.setState({ updateExperimentListStatus: false })
  }

  caclMaxTag = (value) => {
    let maxWidth = 240, count = 0; // Select box length - Tag Placeholder length
    const tagLength = 36; // Length of tag except characters
    for (let key of value) {
      let length = key.length < 12 ? key.length * 8 : 12 * 8; // 7 = one character length
      maxWidth = maxWidth - length - tagLength;
      if (maxWidth < 0) {
        break;
      } else {
        count = count + 1;
      }
    }
    return count;
  }

  getExperimentShowList = () => {
    const { experimentList } = this.state;
    const { experimentTables } = this.props;
    const experimentIds = experimentList.map(item => item.id);
    const selectedExperimentsList = experimentTables.filter(item => experimentIds.includes(item.id));
    const maxTagCount = this.caclMaxTag(selectedExperimentsList.map(item => item.name));
    return <div className="andes-v2-result-experiment-display">
      <Select
        className="aurora-select andes-v2-result-experiment-select"
        size='small'
        mode='multiple'
        value={experimentIds}
        maxTagCount={maxTagCount}
        maxTagTextLength={12}    
        maxTagPlaceholder={`+${experimentIds.length - maxTagCount}`}
        onChange={this.changeExperimentKey}
      >
        {experimentTables.map(item => (
          <Option key={item.id} disabled={item.status !== VERIFY_SUCCESS}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  }

  render() {
    const { experimentList, updateExperimentListStatus } = this.state;
    const { resultKey, changeResultType, recalculateTDRSBRWorkflow, interfaceType, leftWidth, layoutLeftWidth, channelId, sweepId, status, experimentTables, sweepStatus } = this.props;
    return (<div className='andes-v2-result-main'>
      <TopMenu
        selectedKey={resultKey}
        leftWidth={leftWidth}
        layoutLeftWidth={layoutLeftWidth}
        changeResultType={(key) => changeResultType(key, sweepId, EXPERIMENTS)}
        getExperimentShowList={experimentTables && experimentTables.length > 0 ? this.getExperimentShowList : null}
        isSweep={true}
      />
      <ResultContent
        resultKey={resultKey}
        id={channelId}
        sweepId={sweepId}
        interfaceType={interfaceType}
        isEndToEnd={false}
        recalculateTDRSBRWorkflow={recalculateTDRSBRWorkflow}
        historyList={[]}
        experimentList={experimentList}
        status={status}
        sweepStatus={sweepStatus}
        resultPageType={EXPERIMENTS}
        updateExperimentListStatus={updateExperimentListStatus}
        changeUpdateExperimentListStatus={this.changeUpdateExperimentListStatus} />
    </div>)
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: {
    channelResultReducer,
    sweep: { sweepId, experimentData: { variablesTables = [] } }
  } } = state;
  const { resultKey } = channelResultReducer;
  const { channelId } = sweepConstructor.getSweep(sweepId) || {};
  const status = getStatus(channelId);
  const sweepStatus = getSweepStatus(sweepId)
  const interfaceType = getSerdesType(channelId);
  // experimentId: filter success result
  const experimentTables = variablesTables.map(item => ({ id: item.id, name: item.name, status: item.status }));
  return {
    resultKey,
    sweepId,
    channelId,
    status,
    interfaceType,
    experimentTables,
    sweepStatus
  }
}

const mapDispatch = (dispatch) => ({
  changeResultType(key, id, setupType) {
    dispatch(changeResultType(key, id, setupType))
  },
  recalculateTDRSBRWorkflow(workflow, isEndToEnd) {
    dispatch(recalculateTDRSBRWorkflow(workflow, isEndToEnd, EXPERIMENTS))
  },
  _updateExperimentIds(experimentIds) {
    dispatch(updateExperimentIds(experimentIds))
  }
})

export default connect(mapState, mapDispatch)(SweepResult);

function getStatus(channelId) {
  try {
    const item = channelConstructor.getChannel(channelId);
    return item.status;
  } catch (error) {
    return null;
  }
}

function getSweepStatus(sweepId) {
  try {
    const item = sweepConstructor.getSweep(sweepId);
    return item.status
  } catch (error) {
    return null;
  }
}

function getSerdesType(channelId) {
  try {
    const item = channelConstructor.getChannel(channelId);
    return item.type;
  } catch (error) {
    return null;
  }
}