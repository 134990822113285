const LIBRARY_TYPE = 'Cascade';

export const UPDATE_LIBRARY_MENU = `${LIBRARY_TYPE}/update_library_menu`;
export const UPDATE_LIBRARY_DATA = `${LIBRARY_TYPE}/update_library_data`;
export const DELETE_LIBRARY = `${LIBRARY_TYPE}/delete_library_data`;
export const UPDATE_LIBRARY_STATUS = `${LIBRARY_TYPE}/update_library_status`;
export const AFTER_IMPORT_LIBRARY = `${LIBRARY_TYPE}/after_import_library`;
export const GET_LIBRARY_TREE = `${LIBRARY_TYPE}/get_library_tree`;

// system library
export const INIT_SYS_LIBRARY = `${LIBRARY_TYPE}/init_system_library`;
export const UPDATE_SYS_LIBRARY = `${LIBRARY_TYPE}/update_systema_library`;

export const SAVE_DECAP_GENERIC = `${LIBRARY_TYPE}/save_decap_generic`;
export const SAVE_LIBRARY_LIST = `${LIBRARY_TYPE}/save_library_list`;

// custom library
export const UPDATE_CUSTOM_LIBRARY = `${LIBRARY_TYPE}/update_custom_library`;

// PMIC Part
export const SET_OPEN_PART_PANEL = `${LIBRARY_TYPE}/set_open_part_panel`;
export const UPDATE_PIN_MAP_LIST = `${LIBRARY_TYPE}/update_pin_map_list`;