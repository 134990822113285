import { updateRockyPreLayout, getRockyPreLayoutDetail, updateRockyModelPreLayout } from '../../api/Rocky/rockyDesign';
import { prelayoutImport, prelayoutExport } from '../../api/v2/fileCtrl';
import checkError from '../../api/checkError';
import apiProcess from '../../api/utility';



/** 
* Get pre layout content
* 2021.04.19
* @return Promise
*/
function getPreLayoutInfoById(id) {
  return apiProcess(getRockyPreLayoutDetail, { id });
}

/** 
* update pre layout content
* @param {object} data {id, name, version }
* 2021.03.17
* @return Promise
*/
function updatePreLayout({ preLayout, identify }) {
  return apiProcess(updateRockyPreLayout, { preLayout, identify });
}

function exportRockyPrelayout({ designId }) {
  return new Promise((resolve, reject) => {
    if (!designId) {
      resolve(null);
      return;
    }
    prelayoutExport(designId).then(res => {
      resolve(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,` + btoa(
        new Uint8Array(res.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }, error => {
      resolve(null);
      checkError(error, false);
    })
  })
};

function importRockyPrelayout({ designId, file }) {
  return apiProcess(prelayoutImport, { designId, file })
}

function updateModelPreLayout({ preLayout }) {
  return apiProcess(updateRockyModelPreLayout, { preLayout })
}

export {
  getPreLayoutInfoById,
  updatePreLayout,
  exportRockyPrelayout,
  importRockyPrelayout,
  updateModelPreLayout
}