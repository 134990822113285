import React, { Component, Fragment } from 'react';

import {
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { Select, Input, Popover, Switch } from "antd";
import { strDelimited } from '@/services/helper/split';
import { SIERRA, ROCKY } from "../../constants/pageType";
import TreeSelect from '@/components/TreeSelect';
import { getPopoverPlacement } from '@/services/helper/htmlElement';
import { getPinHeight } from '@/services/helper/packageHelper';
import { CARD } from '../../constants/treeConstants';
import { MEMORY } from '../../constants/componentType';
import AutomaticPanel from '@/components/PortMatch';
import { getSelectedPorts } from '../../services/helper/packageModelHelper/portsHelper'
import '@/publicCss/netlist.css';

const { Option, OptGroup } = Select;
const packageModelType = "Package";
const MultiPinSPICE = "MultiPinSPICE";
const Repeater = "Repeater";
const Connector = "Connector";
const TOUCHSTONE_SPICE = "Touchstone / SPICE";
const hasFolder = [MultiPinSPICE, Repeater, 'SPICE'];
const horizontalList = [packageModelType, Repeater, Connector];
const PINV = 'pinValue', PINS = 'pinSubckt', PINL = 'pinLibraryId', PINF = 'pinFileName', PIN = 'pin', PINK = "pinModelKey";
class NetListModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFile: "",//nodes select box selected "libraryId" / "subckt::libraryId"
      searchValue: "",
      top: 50,
      placement: "top",
      automaticInfo: null,
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const PopoverRoot = document.getElementsByClassName('spice-node-select-Popover')[0];
    const selectRoot = document.getElementsByClassName('nodes-list-file-dropdown')[0];

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || (PopoverRoot && !PopoverRoot.contains(target) && (!selectRoot || !selectRoot.contains(target)))) {
      this.closeNodesSelection();
    }
  }

  selectSpiceSubckt = (key, fileIndex) => {
    this.props._selectSpiceSubckt(key, fileIndex);
  }

  deleteFileSelect = (type, fileIndex, deleteLine) => {
    this.props._delFileSelect(type, fileIndex, deleteLine);
  }

  addNewFileSelect = (e) => {
    e.stopPropagation();
    this.props._addNewFileSelect();
  }

  _selectFile = (key, fileIndex) => {
    //key -> {key:id::fileName(id), label:"name" }, key: folder -> id::fileName , file -> id
    this.props._selectFile(key, fileIndex);
  }

  getSelectList() {
    const { fileList, files, subcktList, modelType, product } = this.props;
    const modelTitle = (!files || files.length === 0) ? "Model File" : "";
    const placeholder = modelType === packageModelType ? "Touchstone / SPICE File" : "Model File";
    return (
      <Fragment>
        {files && files.length > 0 ? files.map((file, index) => {
          const modelTitle = index === 0 ? "Model File" : "";
          if (hasFolder.includes(file.type)) {
            /* find subckt select list */
            const currentSubckt = subcktList ? subcktList.find(item => (item.libraryId === file.libraryId && item.fileName === file.fileName)) : null;
            const subckts = currentSubckt ? currentSubckt.models : [];

            let fileName = file.fileName ? file.fileName : undefined;
            if (file.folder && fileName) {
              fileName = `${file.folder}::${fileName}`;
            }
            return (
              <div className='spice-model-multi-select' key={index}>
                {/* spice file */}
                {this.getSelectComponent({
                  type: modelTitle,
                  width: "calc(50% + 45px)",
                  placeholder,
                  value: fileName ? fileName : '',
                  selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
                  list: fileList,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : fileName,
                  tree: product !== SIERRA ? false : true
                })}
                {/* subckt */}
                {this.getSelectComponent({
                  type: "Subckt",
                  width: "calc(50% - 55px)",
                  placeholder: 'Subckt',
                  value: file.subckt ? file.subckt : '',
                  selectHandler: this.selectSpiceSubckt,
                  list: subckts,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : file.subckt,
                  tree: false
                })}
                <CloseOutlined
                  title={'Delete the file'}
                  className='spice-model-delete-file-icon'
                  onClick={() => { this.deleteFileSelect('file', index, true) }} />
              </div>
            );
          } else {
            return (
              <div className='spice-model-single-select' key={index}>
                {/* touchstone file or file not select */}
                {this.getSelectComponent({
                  type: modelTitle,
                  placeholder,
                  value: file.fileName ? file.fileName : '',
                  selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
                  list: fileList,
                  fileIndex: index,
                  fileId: file.libraryId ? file.libraryId : file.fileName,
                  tree: product !== SIERRA ? false : true
                })}
                <CloseOutlined
                  title={'Delete the file'}
                  className='spice-model-delete-file-icon'
                  onClick={() => { this.deleteFileSelect('file', index, true) }} />
              </div>
            );
          }
        }) : <div className='spice-model-single-select'>{this.getSelectComponent({
          type: modelTitle,
          placeholder,
          value: '',
          selectHandler: product !== SIERRA ? this._selectFile : this.props._selectByFolder,
          list: fileList,
          fileIndex: -1,
          fileId: '',
          tree: product !== SIERRA ? false : true
        })}</div>}
      </Fragment>
    );
  }

  getSelectForComponents({ _value, placeholder, value, selectHandler, fileIndex, type, list, tree }) {
    return tree ? <TreeSelect
      showSearch
      labelInValue
      placeholder={placeholder}
      value={_value}
      fileIndex={fileIndex}
      onSelectItem={selectHandler}
      className={`spice-netlist-model-selection ${type}_select`}
      popupClassName='spice-netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, fileIndex) }} /> }}
      getPopupContainer={() => document.getElementById('root')}
      fileList={list}
      showFileFolder={true}
      selected={_value.key}
    /> : <Select
      labelInValue
      placeholder={placeholder}
      value={_value}
      onChange={option => option && selectHandler({ label: option.label, key: option.value }, fileIndex)}
      className={`spice-netlist-model-selection ${type}_select`}
      popupClassName='spice-netlist-model-dropdown'
      popupMatchSelectWidth={false}
      allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => { this.deleteFileSelect(type, fileIndex) }} /> }}
      getPopupContainer={() => document.getElementById('root')}
    >
      {list.map((item, index) => (
        item.type === 'folder' ? <OptGroup key={index} label={item.fileName}>
          {item.children.map(it => <Option
            className={value === `${item.name}::${it.name}` ? "ant-select-dropdown-menu-item-selected" : ''}
            key={`${it.name}`}
            value={`${item.id}::${it.name}`}
            title={it.name}
          >{it.name}</Option>)}
        </OptGroup> :
          <Option
            key={item.name}
            value={item.id ? item.id : item.name}
            title={item.name}
          >{item.name}</Option>
      ))}
    </Select>;
  }

  getSelectComponent({ type, width, placeholder, value, selectHandler, list, fileIndex, fileId, tree }) {
    let _value = { key: fileId, label: value };
    return (
      <div className='spice-netlist-model-select' style={{ width }}>
        {type === "Model File" ?
          <div className='spice-model-file-add-box'>
            <span>{type}</span>
            <PlusCircleOutlined
              title={'Add new file'}
              className='spice-model-add-file-icon'
              onClick={(e) => { this.addNewFileSelect(e) }} />
          </div>
          : <span className={type}>{type === 'Subckt' ? "" : type}</span>}
        {this.getSelectForComponents({ _value, placeholder, value, selectHandler, fileIndex, type, list, tree })}
      </div >
    );
  }

  openSelectNode = (pin, value, type, currentItem) => {
    const libraryId = currentItem[PINL] ? currentItem[PINL] : "";
    const subckt = currentItem[PINS] ? currentItem[PINS] : "";
    const fileName = currentItem[PINF] ? currentItem[PINF] : "";
    const { maxWidth, maxHeight } = this.props;
    const { leftPlacement, rightPlacement } = getPopoverPlacement({ cssId: pin, type, maxWidth, maxHeight })
    this.setState({
      leftPlacement,
      rightPlacement
    })

    const { files, modelType, pkgType } = this.props;
    const { selectFile } = this.state;
    let _selectFile = selectFile;
    if (libraryId) {
      _selectFile = subckt ? `${libraryId}::${subckt}` : libraryId;
      //Folder fileName
      _selectFile = fileName ? `${_selectFile}::${fileName}` : _selectFile;
    } else {
      //find current select File exist
      const [id, name, _fileName] = strDelimited(_selectFile, "::");
      const find = files.find(item => id && item.libraryId === id && (!name || name === item.subckt) && (!_fileName || _fileName === item.fileName));
      if (!find) {
        _selectFile = "";
        //select first file
        //touchstone file or spice file and subckt exist

        let findFile = null;
        for (let file of files) {
          if (file && (file.type === 'Touchstone' || (pkgType === 'EBD' && file.type === 'EBD')) && file.libraryId) {
            findFile = file;
            break;
          }

          if (file && (hasFolder.includes(file.type)) && file.libraryId && file.subckt) {
            findFile = file;
            break;
          }
        }

        if (findFile) {
          _selectFile = findFile.subckt ? `${findFile.libraryId}::${findFile.subckt}` : findFile.libraryId;

          if (![packageModelType, Connector].includes(modelType)) {
            //Folder fileName
            _selectFile = findFile.fileName ? `${_selectFile}::${findFile.fileName}` : _selectFile;
          }
        }
      }
    }

    this.setState({
      editNode: pin,
      searchValue: value,
      selectFile: _selectFile
    })
  }

  closeNodesSelection = () => {
    this.setState({
      editNode: null,
      searchValue: ""
    })
  }

  searchNode = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  selectPinNode = ({ port, pin, type, currentPortInfo, compPin, net }) => {
    //type: "pin" / "pinDie"
    const subckt = currentPortInfo.subckt, libraryId = currentPortInfo.libraryId, fileName = currentPortInfo.fileName;
    const { pinList, pairs, portsObj, modelType, modelPinType, record } = this.props;
    let _portsObj = portsObj ? JSON.parse(JSON.stringify(portsObj)) : {};
    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];
    //find current libraryId ports
    let currentFiles = portsObj[libraryId] ? portsObj[libraryId] : [];
    if (currentFiles.length === 0) {
      return;
    }

    let _ports = [];
    if (subckt) {
      //spice
      //(!fileName || item.fileName === fileName) repeater and spice has fileName field, package spice not fileName field
      const fileIndex = currentFiles.findIndex(item => item.subckt === subckt && (!fileName || item.fileName === fileName));
      _ports = currentFiles[fileIndex] && currentFiles[fileIndex].ports ? currentFiles[fileIndex].ports : [];
    } else {
      //touchstone
      _ports = currentFiles[0] && currentFiles[0].ports ? currentFiles[0].ports : [];
    }


    let prevNode = "", prevSubckt = "", prevLibraryId = "", prevFileName = "";

    //update pinList
    const indexList = _pinList.map((item, i) => { if (item.net === net) { return i }; return null }).filter(item => item !== null);
    let index = -1, index_child = -1;
    for (let item of indexList) {
      if (!_pinList[item] || !_pinList[item][type]) {
        continue;
      }
      const _index_child = _pinList[item][type].findIndex(it => it.pin === pin);
      if (_index_child > -1) {
        index = item;
        index_child = _index_child;
        break;
      }
    }
    if (index > -1 && index_child > -1) {
      _pinList[index][type][index_child][PINV] = port;
      //SPICE subckt
      _pinList[index][type][index_child][PINS] = subckt ? subckt : "";
      _pinList[index][type][index_child][PINL] = libraryId;
      if (![packageModelType, Connector].includes(modelType)) {
        _pinList[index][index_child][PINF] = fileName;
      }
    }
    //update pairs

    const _index = _pairs.findIndex(item => item.pin === pin);
    if (_index > -1) {
      //get prev node and subckt and libraryId
      prevNode = _pairs[_index].node;
      prevSubckt = _pairs[_index].subckt;
      prevLibraryId = _pairs[_index].libraryId;
      prevFileName = _pairs[_index].fileName;
      //update new node
      _pairs[_index].node = port;
      _pairs[_index].subckt = subckt ? subckt : "";
      _pairs[_index].libraryId = libraryId;
      if (![packageModelType, Connector].includes(modelType)) {
        _pairs[_index].fileName = fileName;
      }
    }

    //update ports list select status
    const portIndex = _ports.findIndex(item => (item.port === port));
    if (portIndex > -1) {
      _ports[portIndex].select = true;
    }
    if (subckt) {
      const _fileIndex = currentFiles.findIndex(item => item.subckt === subckt && (!fileName || item.fileName === fileName))
      if (_fileIndex > -1) {
        currentFiles[_fileIndex].ports = _ports;
      }
    } else {
      currentFiles[0].ports = _ports;
    }
    _portsObj[libraryId] = currentFiles;

    // //prev libraryId exist, update prev port select status
    // if (prevLibraryId && _portsObj[prevLibraryId]) {//prev libraryId exist
    //   let _prevPorts = [];
    //   let subcktIndex = -1;
    //   if (subckt) {
    //     const subcktIndex = _portsObj[prevLibraryId].findIndex(item => item.subckt === prevSubckt && (!prevFileName || item.fileName === prevFileName));
    //     if (subcktIndex > -1) {
    //       _prevPorts = _portsObj[prevLibraryId][subcktIndex].ports;
    //     }
    //   } else {
    //     _prevPorts = _portsObj[prevLibraryId][0].ports;
    //   }
    //   //prev node select update
    //   const prevPortIndex = _prevPorts.findIndex(item => prevNode && item.port === prevNode);
    //   if (prevPortIndex > -1) {
    //     _prevPorts[prevPortIndex].select = false;
    //   }
    //   if (subckt && subcktIndex > -1) {
    //     _portsObj[prevLibraryId][subcktIndex].ports = _prevPorts;
    //   } else if (_portsObj[prevLibraryId][0]) {
    //     _portsObj[prevLibraryId][0].ports = _prevPorts;
    //   }
    // }

    this.props._selectNodes({
      pinList: _pinList,
      pairs: _pairs,
      portsObj: _portsObj
    });

    this.setState({
      searchValue: port
    }, () => {
      //The in and out of the driver model cannot be set to different subckt
      const { usage } = record;
      if (modelPinType === MultiPinSPICE && usage === 'Driver' && compPin) {
        const otherPinType = type === 'pinLeft' ? 'pinRight' : 'pinLeft',
          //current select node pin (eg: C3_in )
          currentPinSubckt = _pinList[index][type][index_child][PINS],
          currentPinLibraryId = _pinList[index][type][index_child][PINL],
          currentPinFileName = _pinList[index][type][index_child][PINF],

          //another pin (eg: C3_u )
          anotherPin = _pinList[index][otherPinType],
          anotherPinNode = _pinList[index][otherPinType][PINV],
          anotherPinSubckt = _pinList[index][otherPinType][PINS],
          anotherPinLibraryId = _pinList[index][otherPinType][PINL],
          anotherPinFileName = _pinList[index][otherPinType][PINF];
        if (anotherPin && anotherPinNode && (currentPinLibraryId !== anotherPinLibraryId || currentPinFileName !== anotherPinFileName || currentPinSubckt !== anotherPinSubckt)) {
          this.deleteSelectNode(null, _pinList[index], otherPinType, net);
        }
      }

      //Open the next pin with no port selected
      //find next pin with no port selected
      let { nextType, sidePin } = this.findNextPin(_pinList, type, index);
      if (sidePin) {
        //open next pin of not select port
        this.scrollView(sidePin.pin, nextType, sidePin);
      } else {
        //close nodes select panel
        this.closeNodesSelection();
      }
    })
  }

  findNextPin = (pinList, type, index) => {
    const anotherType = type === "pinLeft" ? "pinRight" : "pinLeft";
    const { modelType } = this.props;

    const findNext = (_index) => {
      const find = pinList.find((item, i) => i === _index);
      return find;
    }

    const findNoValue = (sidePinList, _type) => {
      for (let _pin of sidePinList) {
        if (!_pin[PINV]) {
          return { nextPin, nextType: _type, sidePin: _pin };
        }
      }
      return null
    }

    let nextIndex = index;
    let nextPin = findNext(index);
    let _find = null;
    if (horizontalList.includes(modelType) && nextPin) {
      //pinL find pinR, pinR find pinL
      if (nextPin[type]) {
        _find = findNoValue(nextPin[type], type);
        if (_find) return _find;
      }
      if (nextPin[anotherType]) {
        _find = findNoValue(nextPin[anotherType], anotherType);
        if (_find) return _find;
      }
    }

    if (horizontalList.includes(modelType)) {
      while (nextPin && nextIndex <= pinList.length) {
        nextIndex += 1;
        nextPin = findNext(nextIndex);
        if (nextPin && nextPin.pinLeft) {
          _find = findNoValue(nextPin.pinLeft, 'pinLeft');
          if (_find) return _find;
        }

        if (nextPin && nextPin.pinRight) {
          _find = findNoValue(nextPin.pinRight, 'pinRight');
          if (_find) return _find;
        }
      }
    } else {
      while (nextPin && nextPin[type] && nextIndex <= pinList.length) {
        nextIndex += 1;
        nextPin = findNext(nextIndex);
        if (nextPin && nextPin[type]) {
          _find = findNoValue(nextPin[type], type);
          if (_find) return _find;
        }
      }
    }

    return { nextPin: null, nextType: null, sidePin: null };
  }

  scrollView = (anchorId, type, sidePin) => {
    const { modelType, scrollId } = this.props;
    let scrollElement = document.getElementById(scrollId);  // The scroll container corresponding to the id
    let anchorElement = document.getElementById(anchorId);  // Need to locate the anchor element you see
    if (scrollElement && anchorElement) {
      const offsetTop = anchorElement.offsetTop;
      const top = modelType === MultiPinSPICE ? offsetTop - 148 : offsetTop - 104;

      const offsetLeft = anchorElement.offsetLeft;
      const left = offsetLeft - 34;
      scrollElement.scrollTo({ top, left, behavior: "smooth" });
    }
    setTimeout(() => {
      this.openSelectNode(sidePin[PIN], sidePin[PINV], type, sidePin);
    }, 340);
  }

  getDisplayPortList = (currentItem, type) => {
    const { portsObj, pkgType, pinList } = this.props;
    const { searchValue, selectFile } = this.state;
    if (!portsObj || Object.keys(portsObj).length === 0) {
      return [];
    }
    //current pin connected port
    const port = currentItem[PINV] ? currentItem[PINV] : "";
    //current pin connected port libraryId
    const currentLibraryId = currentItem[PINL] ? currentItem[PINL] : "";

    let libraryId = "", subckt = "", fileName = "";
    if (!selectFile) {
      return [];
    }
    //325t43466434::subckt1::file
    const [id, name, _fileName] = strDelimited(selectFile, "::");
    if (!id) {
      return [];
    } else {
      libraryId = id;
      subckt = name;
      fileName = _fileName;
    }
    //find current libraryId ports
    let currentFiles = portsObj[libraryId] ? portsObj[libraryId] : [];

    let value = searchValue;
    let valueList = [];
    //Add escape characters to special characters
    strDelimited(value).forEach(item => {
      if (/[^a-zA-Z0-9]/ig.test(item)) {
        valueList.push(`\\${item}`);
      } else {
        valueList.push(item);
      }
    });
    value = valueList.join("");
    let reg = new RegExp(`(${value})`, 'i');

    //find ports
    let ports = [];
    if (!subckt) {
      ports = currentFiles[0] && currentFiles[0].ports ? currentFiles[0].ports : [];
    } else {
      const findSubckt = currentFiles.find(item => item.subckt === subckt && (!fileName || fileName === item.fileName));
      ports = findSubckt && findSubckt.ports ? findSubckt.ports : [];
    }

    const selectedPorts = getSelectedPorts(libraryId, pinList, '');

    //sort ports
    let _portList = [], selectedList = [], currentPort = [], searchList = [], notSelectedList = [];
    ports.forEach(item => {
      if (currentLibraryId === libraryId && port && item.port === port) {
        // selected port of current pin
        // currentPort.push({ ...item, libraryId, fileName });
        currentPort.push({ ...item, libraryId, fileName, select: true });
      } else if (searchValue && (currentLibraryId !== libraryId || port !== searchValue) && item.info.match(reg)) {
        //searched ports
        searchList.push({ ...item, libraryId, fileName });
        // } else if (item.select) {
      } else if (selectedPorts.includes(item.port)) {
        //Ports that have been selected by other pins
        // selectedList.push({ ...item, libraryId, fileName });
        selectedList.push({ ...item, libraryId, fileName, select: true });
      } else {
        //No selected ports
        // notSelectedList.push({ ...item, libraryId, fileName });
        notSelectedList.push({ ...item, libraryId, fileName, select: false });
      }
    });

    const _searchSelectedList = searchList.filter(item => item.select);
    const _searchNotSelectedList = searchList.filter(item => !item.select);
    const _searchList = [..._searchNotSelectedList, ..._searchSelectedList];
    //current pin select port + searched ports + notSelected ports + selected ports
    _portList = [...currentPort, ..._searchList, ...notSelectedList, ...selectedList];
    if (pkgType === 'EBD') {
      _portList = type === 'pinLeft' ? _portList.filter(port => port.name)
        : _portList.filter(port => !port.name)
    }
    return _portList;
  }

  selectNodesFile = (key) => {
    this.setState({
      selectFile: key
    })
  }

  getSelectedFileList = () => {
    const { modelType, files } = this.props;
    let _files = [...files];
    let list = [];
    _files.forEach(item => {
      if (hasFolder.includes(item.type) && item.subckt) {
        let key = `${item.libraryId}::${item.subckt}`;
        let value = `${item.subckt}::${item.fileName}`;

        if (![packageModelType, Connector].includes(modelType)) {
          key = `${key}::${item.fileName}`;
          value = item.folder ? `${value}::${item.folder}` : value;
        }
        list.push({
          key,
          value,
        })
      } else if ([packageModelType, Connector].includes(modelType)) {
        if (item.type === 'Touchstone' || item.type === 'EBD') {
          list.push({
            key: item.libraryId,
            value: item.fileName,
          });
        }
      }
    });

    return list;
  }

  nodeSelectRender = (pin, type, port, placeholder, currentItem, net) => {
    const { searchValue, selectFile } = this.state;
    const _portList = this.getDisplayPortList(currentItem, type);
    const displayPin = currentItem[`pinNodeDisplay`] ? currentItem[`pinNodeDisplay`] : pin;
    const compPin = currentItem.compPin ? currentItem.compPin : null;
    const selectFileList = this.getSelectedFileList();
    const { maxWidth, maxHeight, pkgType } = this.props;
    return (
      <div className='nodes-content' style={{ maxHeight: maxHeight - 210 > 200 ? maxHeight - 210 : 200, maxWidth: maxWidth - 200 > 100 ? maxWidth - 200 : 100 }}>
        <div className='nodes-content-header'>
          <span className='nodes-content-header-span'>Component Pin {displayPin}</span>
        </div>
        <div className='node-content-close' onClick={() => this.closeNodesSelection()}>
          <CloseOutlined className='node-content-close-icon' />
        </div>
        <div className="node-list-body-with-search">
          <div className="node-list-body-search-wrapper">
            <Select
              placeholder={`Select File/Subckt`}
              className='nodes-list-file-select'
              allowClear
              value={selectFile}
              popupClassName='nodes-list-file-dropdown'
              popupMatchSelectWidth={false}
              onChange={(key) => this.selectNodesFile(key)}
            >
              {selectFileList.map(item => (
                <Option
                  key={item.key}
                  title={item.value}
                  className='spice-nodes-content-file'
                >
                  {item.value}
                </Option>
              ))
              }
            </Select>
            <Input
              placeholder={`Search ${placeholder} `}
              allowClear
              suffix={!searchValue ? <SearchOutlined style={{ color: 'rgba(0, 0, 0, .25)' }} /> : null}
              value={searchValue}
              onChange={(e) => this.searchNode(e)}
            />
            <ul className='node-list-ul' style={{ maxHeight: maxHeight - 310 > 100 ? maxHeight - 310 : 100 }}>
              {_portList.map(item =>
                <li
                  key={item.port}
                  title={item.info}
                  className={item.port === port && item.select ? 'current-pin-selected-node-li' : (item.select ? 'node-li-selected' : '')}
                  onClick={!item.select ? () => this.selectPinNode({ port: item.port, type, pin, currentPortInfo: item, compPin, net }) : null}
                >
                  {pkgType === 'EBD' ? <Fragment>{item.info}</Fragment>
                    : item.info !== item.port ? <Fragment>{item.port}&nbsp;&nbsp;&nbsp;&nbsp;{item.info}</Fragment> : item.port}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  deleteSelectNode = (e, item, type, net) => {
    //type: "pinLeft" / "pinRight"
    e && e.stopPropagation();
    const pin = item[PIN], prevNode = item[PINV], libraryId = item[PINL], subckt = item[PINS], fileName = item[PINF];
    const { pinList, pairs, portsObj, modelType } = this.props;
    let _portsObj = portsObj ? JSON.parse(JSON.stringify(portsObj)) : {};

    //update pinList
    //delete current pinL/pinR port in pinList
    let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : [];

    const indexList = _pinList.map((item, i) => { if (item.net === net) { return i }; return null }).filter(item => item !== null);
    let itemIndex = -1, index = -1;
    for (let item of indexList) {
      if (!_pinList[item] || !_pinList[item][type]) {
        continue;
      }
      const _index_child = _pinList[item][type].findIndex(it => it[PIN] === pin);
      if (_index_child > -1) {
        itemIndex = item;
        index = _index_child;
        break;
      }
    }
    if (index > -1) {
      _pinList[itemIndex][type][index][PINV] = "";
      _pinList[itemIndex][type][index][PINL] = "";
      _pinList[itemIndex][type][index][PINS] = "";
      if (![packageModelType, Connector].includes(modelType)) {
        _pinList[itemIndex][type][index][PINF] = "";
      }
    }

    //update pairs
    //delete current pin/pin_die node in pairs
    let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : [];
    const _index = _pairs.findIndex(item => item.pin === pin);
    if (_index > -1) {
      _pairs[_index].node = "";
      _pairs[_index].subckt = "";
      _pairs[_index].libraryId = "";
      if (![packageModelType, Connector].includes(modelType)) {
        _pairs[_index].fileName = "";
      }
    }

    if (libraryId && _portsObj[libraryId]) {
      let currentFiles = _portsObj[libraryId];
      let subcktIndex = -1, _ports = [];
      if (subckt) {
        //spice
        subcktIndex = currentFiles.findIndex(item => (!fileName || item.fileName === fileName) && item.subckt === subckt);
        _ports = currentFiles[subcktIndex] && currentFiles[subcktIndex].ports ? currentFiles[subcktIndex].ports : [];
      } else {
        //touchstone
        _ports = currentFiles[0] && currentFiles[0].ports ? currentFiles[0].ports : [];
      }

      //prev node select status improvement
      const prevNodeIndex = _ports.findIndex(item => prevNode && item.port === prevNode);

      if (prevNodeIndex > -1) {
        _ports[prevNodeIndex].select = false;
      }

      if (subckt && subcktIndex > -1) {
        _portsObj[libraryId][subcktIndex].ports = _ports;
      } else {
        _portsObj[libraryId][0].ports = _ports;
      }
    }

    this.props._selectNodes({
      pinList: _pinList,
      pairs: _pairs,
      portsObj: _portsObj
    });
  }

  getMultiRankSetting = () => {
    const { rank } = this.props;
    return <Fragment>
      <div className="spice-model-single-select">
        <div className="spice-netlist-model-select">
          <span>Multi Rank</span>
          <Switch
            checked={rank ? true : false}
            onChange={(checked, e) => this.props.switchRankSetting(checked, e)}
            size="small"
            className="aurora-switch-small"
          />
        </div>
        {rank && <div className="spice-multi-rank-setting">
          <span className="spice-multi-rank-span">Rank Number</span>
          <Input
            value={rank.number}
            onChange={(e) => this.props.changeRankNumber(e)}
            onBlur={(e) => this.props.checkRankNumber(e)}
          />
          <span className="spice-multi-rank-span">Active</span>
          <Select
            notFoundContent="No Data"
            getPopupContainer={trigger => trigger.parentNode}
            value={rank.active}
            onSelect={this.props.changeRankActive}
            style={{ height: 22 }}
          >
            {
              [...Array(Number(rank.number))].map((item, index) => {
                return <Option key={String(index + 1)}>Rank {index + 1}</Option>
              })
            }
          </Select>
        </div>}
      </div>
    </Fragment>
  }

  getLeftPinDisplay = ({ item, editNode, placeholder, leftPlacement }) => {
    return item.pinLeft ? item.pinLeft.map(pinL => (<div key={pinL.pin} className='spice-total-pin-box'>
      {/* pin L connect port */}
      <div className='spice-pin-die-value-box'>
        {!item.displayType && editNode && editNode === pinL.pin ?
          <Popover
            overlayClassName='spice-node-select-Popover'
            content={this.nodeSelectRender(pinL.pin, 'pinLeft', pinL.pinValue, placeholder, pinL, item.net)}
            title=""
            trigger="click"
            open={true}
            placement={leftPlacement ? leftPlacement : "top"}
          ><Input
              className='spice-pin-input'
              placeholder={placeholder}
              value={pinL.pinValue}
              id={pinL.pin}
              onClick={() => this.openSelectNode(pinL.pin, pinL.pinValue, "pinL", pinL)}
            /></Popover> :
          pinL.pinValue ?
            <div className='spice-pin-input' id={pinL.pin} title={pinL.pinValue} onClick={() => this.openSelectNode(pinL.pin, pinL.pinValue, "pinL", pinL)}>
              <span>{pinL.pinValue}</span>
              <CloseCircleFilled
                className='spice-pin-node-delete-icon'
                onClick={(e) => { this.deleteSelectNode(e, pinL, "pinLeft", item.net) }} />
            </div>
            : !item.displayType && <Input
              className='spice-pin-input'
              placeholder={placeholder}
              value={pinL.pinValue}
              id={pinL.pin}
              onClick={() => this.openSelectNode(pinL.pin, pinL.pinValue, "pinL", pinL)}
            />
        }
      </div>
      {/* pin_L */}
      <div className='spice-pin-die-box' title={pinL.pinDisplay ? pinL.pinDisplay : pinL.pin}>{pinL.pinDisplay ? pinL.pinDisplay : pinL.pin}</div>
      {/* - */}
      <div className='spice-pin-line'></div>
      <div className='spice-pin-circle'></div>
    </div>)) : null;
  }

  getRightPinDisplay = ({ item, editNode, placeholder, rightPlacement }) => {
    return item.pinRight ? item.pinRight.map(pinR => (<div key={pinR.pin} className='spice-total-pin-box'><div className='spice-pin-circle'></div>
      <div className='spice-pin-line'></div>
      {/* pin R */}
      {!item.displayType && <div className='spice-pin-box' title={pinR.pinDisplay ? pinR.pinDisplay : pinR.pin}>{pinR.pinDisplay ? pinR.pinDisplay : pinR.pin}</div>}
      {/* pin R connect port */}
      <div className='spice-pin-value-box'>
        {editNode && editNode === pinR.pin ? <Popover
          overlayClassName='spice-node-select-Popover'
          content={this.nodeSelectRender(pinR.pin, 'pinRight', pinR.pinValue, placeholder, pinR, item.net)}
          title=""
          trigger="click"
          open={true}
          placement={rightPlacement ? rightPlacement : "top"}
        ><Input
            className='spice-pin-input'
            placeholder={placeholder}
            value={pinR.pinValue}
            id={pinR.pin}
            onClick={() => this.openSelectNode(pinR.pin, pinR.pinValue, "pinR", pinR)}
          /></Popover>
          : pinR.pinValue ?
            <div className='spice-pin-input' id={pinR.pin} title={pinR.pinValue} onClick={() => this.openSelectNode(pinR.pin, pinR.pinValue, "pinR", pinR)}>
              <span>{pinR.pinValue}</span>
              <CloseCircleFilled
                className='spice-pin-node-delete-icon'
                onClick={(e) => { this.deleteSelectNode(e, pinR, "pinRight", item.net) }} />
            </div>
            : <Input
              className='spice-pin-input'
              placeholder={placeholder}
              id={pinR.pin}
              value={pinR.pinValue}
              onClick={() => this.openSelectNode(pinR.pin, pinR.pinValue, "pinR", pinR)}
            />}
      </div></div>)) : null;
  }

  updateAutomaticPanelVisible = (info) => {
    this.setState({
      automaticInfo: info
    })
  }

  automaticMatchPort = (fileInfo, type, save) => {
    const { automaticInfo } = this.state;

    if (save) {
      this.props._automaticMatchPort(fileInfo, type, automaticInfo)
    }
    this.updateAutomaticPanelVisible(null)
  }

  cleanPort = () => {
    // Clear port data
    this.props._clearPinNode()
  }

  render() {
    const { pinList, errorMsg, displayType, modelPinType, product, record, interfaceType, modelType, files, fileList, subcktList, matchClear, pkgType } = this.props;
    const { editNode, leftPlacement, rightPlacement, automaticInfo } = this.state;
    const placeholder = 'Node';
    const allPinL = pinList.filter(item => !!item.pinL);
    const allPinR = pinList.filter(item => !!item.pinR);
    let className = "spice-pin-connect-node-item";
    if (!allPinL || allPinL.length === 0 || !allPinR || allPinR.length === 0 || modelPinType === 'Stimulus') {
      className = "spice-pin-connect-node-item spice-pin-connect-node-item-center";
    }
    return (
      <div className='spice-netlist-model-content'>
        <div className='spice-netlist-model-select-content' id="spice-netlist-model-select-content-id">
          {this.getSelectList()}
          {product === ROCKY && interfaceType !== CARD && record.type === MEMORY && this.getMultiRankSetting()}
          {errorMsg ? <span className="spice-model-file-error-msg">{errorMsg}</span> : null}
        </div>
        {displayType === 'model' && <div className='spice-connect-content'>
          <ul className='spice-pin-list-ul'>
            {matchClear &&
              <div className={`spice-pin-connect-node-title`}>
                {pkgType === TOUCHSTONE_SPICE && <span title="Ports Match" className='iconfont icon-xinpian_chip' onClick={() => this.updateAutomaticPanelVisible({ modelType: modelType, modelFileKey: modelType })}></span>}
                <DeleteOutlined
                  title="Clean Ports"
                  onClick={() => this.cleanPort({ modelType: modelType, modelFileKey: modelType })} />
              </div>
            }
            {pinList.map((item, index) =>
              <div className={className} key={index} style={{ height: getPinHeight(item) }}>
                <div>
                  {this.getLeftPinDisplay({ item, editNode, placeholder, leftPlacement })}
                </div>
                {/* net box */}
                <div
                  className={index === 0 ? 'spice-box spice-box-top' : (index === (pinList.length - 1) ? 'spice-box spice-box-bottom' : 'spice-box')}
                  title={`${item.signalDisplay}::${item.netDisplay}`}
                >
                  <span className='spice-box-signal-text'>{item.signalDisplay}</span>
                  <span className='spice-box-net-text'>{item.netDisplay}</span>
                </div>
                {/* - */}
                <div>
                  {this.getRightPinDisplay({ item, editNode, placeholder, rightPlacement })}
                </div>
              </div>)}
          </ul>
        </div>}
        {automaticInfo && Object.keys(automaticInfo).length && automaticInfo.modelType ? <AutomaticPanel
          automaticInfo={automaticInfo}
          closePanel={this.automaticMatchPort}
          libraryListObj={{ [`${automaticInfo.modelType.toLowerCase()}List`]: fileList }}
          getFileParse={this.props.getFileParse}
          // newModelFilesObj={newModelFilesObj}
          modelType={modelType}
          files={files}
          subcktList={subcktList}
        /> : null}
      </div >
    );
  }
}

export default NetListModel;