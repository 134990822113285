import { COMP_REPEATER, MultiPinSPICE } from '../../../pages/Sierra/constants';
import { strDelimited } from '@/services/helper/split';

function getIBISPackageText(pkg) {
  let text = "";
  if (pkg && pkg.type === 'IBIS') {
    text = pkg.component ? pkg.component : '';
    if (pkg.component && pkg.model) {
      text = `${pkg.component}::${pkg.model}`;
      if (pkg.model === 'Generic' && pkg.modelInfo) {
        text = `${pkg.component}::${pkg.model}(R:${pkg.modelInfo.R}, L:${pkg.modelInfo.L}, C:${pkg.modelInfo.C})`;
      } else if (pkg.model === 'Matrix' && pkg.modelInfo) {
        text = `${pkg.component}::${pkg.model}(${pkg.modelInfo})`;
      }
    }
  }
  return text;
}

function getSpicePackageText(pkg, record = {}) {
  const files = pkg.files && pkg.files.length ? pkg.files : [];
  let fileNames = files.map(item => item.fileName);
  let deleteText = "", updateText = "";
  if (record.packageFileNotExist) {
    const { deleteList, updateList } = record.packageFileNotExist;
    deleteText = deleteList && deleteList.length ? deleteList.join(", ") : "";
    updateText = updateList && updateList.length ? updateList.join(", ") : "";

    fileNames = fileNames.filter(item => !deleteList.includes(item) && !updateList.includes(item));
  }
  const text = fileNames.length ? fileNames.join(", ") : "";
  return { text, deleteText, updateText };
}

function getProbePinsText(record) {
  const { comps } = record;
  if (!comps || !comps.length) {
    return "";
  }
  //comps length ===1, display probe pins
  /*   if (comps.length === 1 && comps[0] && comps[0].probePins && comps[0].probePins.length) {
      return comps[0].probePins.join(", ");
    } */
  const probeComps = comps && comps.filter(item => item.probePins && item.probePins.length);
  /*  let probePins = []; */
  /*  probeComps.forEach(comp => {
     const allPinsProbe = comp.pins.filter(item => comp.probePins.includes(item.pin));
     if (allPinsProbe && allPinsProbe.length === comp.pins.length) {
       //if all pins is selected to probe, display comp name
       probePins.push(comp.name);
     } else {
        //display comp name::(pin1,pin2)
       probePins.push(`${comp.name}::(${comp.probePins.join(", ")})`);
     }
   }) */
  const probeCompNames = probeComps.map(item => item.name);
  return probeCompNames.join(", ");
}

function getRepeaterText(record) {
  if (!record || !record.model) {
    return "";
  }
  let text = "";
  const model = { ...record.model };
  if (model && model.id) {
    text = model.name;
    return text;
  }

  if (model.files && model.files.length > 0) {
    let fileNames = [];
    model.files.forEach(item => {
      let name = "";
      if (item.folder && item.fileName) {
        name = `${item.folder}::${item.fileName}`;
      } else if (item.fileName) {
        name = item.fileName;
      }
      if (!fileNames.includes(name) && name) {
        fileNames.push(name);
      }
    });
    text = fileNames.join(", ");
  }
  return text;
}

function getModelFileText(record) {
  let fileNames = record.model && record.model.files && record.model.files.length ? record.model.files.filter(item => !!item.fileName).map(item => { return item.folder ? `${item.folder}::${item.fileName}` : item.fileName }) : [];
  fileNames = [...new Set([...fileNames])];
  const text = Array.isArray(fileNames) ? fileNames.join(", ") : "";
  return text;
}

// pin table
const pinColumns = [{
  title: 'Signal',
  dataIndex: 'signal',
  width: '15%',
  sorter: (a, b) => a.signal.localeCompare(b.signal)
}, {
  title: 'Component - Pin',
  dataIndex: 'pin',
  width: '15%'
}, {
  title: 'Usage',
  dataIndex: 'usage',
  width: '15%',
}, {
  title: 'Model',
  dataIndex: 'model',
  width: '20%'
}, {
  title: 'Stimulus',
  dataIndex: 'inputStimulus',
  width: '20%',
}, {
  title: 'Corner',
  dataIndex: 'corner',
  width: '15%'
}];



function getRepeaterModel(record) {
  let model = { files: [], pairs: [] };
  if (record.model && record.model.id) {
    model.files[0] = {
      folder: "",
      fileName: record.model.name,
      libraryId: record.model.id,
      subckt: record.model.subcktName,
      type: COMP_REPEATER
    }
  } else if (record.model.files) {
    model = { ...record.model }
  }
  return model;
}

function getRepeaterNode(model, pin) {
  if (!model || !model.pairs || model.pairs.length === 0) {
    return "";
  }
  let text = "";
  const currentPin = model.pairs.find(item => item.pin === pin);
  if (currentPin && currentPin.node) {
    text = `${currentPin.subckt} - ${currentPin.node} `;
  }
  return text;
}

function driverReceiverModelText(record, { compModel = null }) {
  const { pin, usage, model } = record;
  let text = "";
  if (model && model.libType !== MultiPinSPICE && model.modelName) {
    text = model.modelName;
    return text;
  }
  if (model && model.libType === MultiPinSPICE) {
    if (!compModel) {
      return text;
    }
    const pairs = compModel.pairs || [];
    const pinInName = `${pin}_in`, pinDieName = `${pin}_u`;
    const pinIn = pairs ? pairs.find(item => item.pin === pinInName) : {};
    const pinDie = pairs ? pairs.find(item => item.pin === pinDieName) : {};
    const pinInText = pinIn && pinIn.node ? pinIn.subckt : "";
    const pinOutText = pinDie && pinDie.node ? pinDie.subckt : "";
    if (usage === 'Receiver') {
      text = pinOutText;
    }

    if (usage === 'Driver') {
      text = (pinInText && pinOutText && pinInText !== pinOutText) ? `${pinInText}, ${pinOutText}` : (pinInText || pinOutText)
    }

  }
  return text;
}

function getStimulusText(record) {
  const { pinModels } = record;
  if (!pinModels) {
    return "";
  }
  const ND_IN = pinModels.find(item => item.pinName === 'nd_in');
  if (!ND_IN) {
    return "";
  }

  if (ND_IN && ND_IN.stimulus && ND_IN.stimulus.node) {
    return ND_IN.stimulus.subckt;
  }
}

function getPinStimulusText(pinModels) {
  let inputStimulus = "";
  if (pinModels && pinModels.length > 0) {
    const ND_IN = pinModels.find(item => item.pinName === "nd_in");
    const ND_EN = pinModels.find(item => item.pinName === "nd_en");
    if (ND_EN) {
      inputStimulus = ND_EN.type || ND_IN.type;
    } else {
      inputStimulus = ND_IN.type.includes('PRBS') && ND_IN.tabs && ND_IN.tabs.length ? `${ND_IN.type}[${ND_IN.tabs.join(',')}]` : ND_IN.type;
    }
  }
  return inputStimulus;
}

function getPinModels(pinModels) {
  let newPinModels = pinModels ? JSON.parse(JSON.stringify(pinModels)) : [];
  let _index = newPinModels.findIndex(item => item.pinName === "nd_in");
  const ND_IN = newPinModels[_index];
  if (ND_IN) {
    if (ND_IN.type) {
      //-pinModels: [{ stimulus: {}, pinName, type:type-seed, voltage,}],
      //-newPinModels: [{ stimulus: {}, pinName, type, voltage ,seed}],
      let [str, str1, newSeed] = strDelimited(ND_IN.type, "-");
      let newType = `${str}-${str1}`;
      if (newType === 'PRBS-SDR' || newType === 'PRBS-DDR') {
        newPinModels[_index].type = newType;
        newPinModels[_index].seed = newSeed;
      }
    }
  }
  return newPinModels;
}

function getComponentsByPCB(Interfaces, pcb, type = "components") {
  if (!pcb) {
    return []
  }
  const findInterface = (Interfaces || []).find(item => item.pcb === pcb);
  if (!findInterface || !findInterface.pcbId || !findInterface.content || !findInterface.content[type]) {
    return []
  }
  const components = JSON.parse(JSON.stringify(findInterface.content[type])).map(item => {
    item.pcb = pcb;
    item.pcbId = findInterface.pcbId;
    return item
  })
  return components;
}

export {
  getIBISPackageText,
  getSpicePackageText,
  getProbePinsText,
  getRepeaterText,
  getModelFileText,
  pinColumns,
  getRepeaterModel,
  getRepeaterNode,
  driverReceiverModelText,
  getStimulusText,
  getPinModels,
  getPinStimulusText,
  getComponentsByPCB
}