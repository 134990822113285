import { Connector, ConnectorPin, ExternalPort, ICCompPinModel, ICComponent } from '../IntegratedInterface';
import { SPICE } from '@/constants/libraryConstants';
import { CONNECTOR } from '@/pages/Sierra/constants';
import designConstructor from '../../helper/designConstructor';
import { IC } from '../../PCBHelper';
import { setDefaultPortData } from '../../userDefaultSetting/userDefaultSettingCtrl';

function updateConnector(connector, Interfaces) {
  // update connector component json from old version(3.2.1)
  const externalPort = new ExternalPort({});
  let pins = [];

  for (let p of connector.pins) {
    const { pin, net, signal } = p;
    pins.push(new ConnectorPin({ pin, net, signal, externalPort }));
  }

  const { part, modelName, name, value, libraryId, pkg } = connector;
  const models = libraryId ? [{ libraryId, subckt: modelName, file: pkg, type: SPICE }] : [];
  let [pcb, connector_comp] = value.split('::');
  // const pcbId = Interfaces.fi
  let connect = { pcbId: "", componentName: "" };
  if (pcb && connector_comp) {
    const pcbId = Interfaces.find(i => i.pcb === pcb).pcbId;
    connect = { pcbId, componentName: connector_comp };
  }
  const newConnector = new Connector({ models, pins, name, part, connect });
  return newConnector;
}

function getDefaultConnector(components) {
  const { part, name, pins = [] } = components;
  let newPins = pins.map(_pin => {
    const { pin, net, signal } = _pin;
    return new ConnectorPin({ pin, net, signal })
  })
  const newConnector = new Connector({ pins: newPins, name, part });
  return newConnector;
}

function getConnectionsInfo(components, Interfaces, init, notUpdateOrder, preConnections = []) {
  let connections = [], pcbConnections = [], selectedComp = [], updateConnector = false;
  let _newConnector = [], updatePCBIds = [];

  // get connections list
  let connection_index = 1;
  for (let comp of components) {//ALL PCB connections
    if (!selectedComp.find(s => s.pcbId === comp.pcbId && s.name === comp.name)) {
      const { name, pcbId, connect, cableModels, models, pins } = comp;
      selectedComp.push({ name, pcbId });
      const designName = Interfaces.find(i => i.pcbId === pcbId).pcb;
      let channel1 = { designId: pcbId, designName }, channel2 = { designId: "", designName: "" };
      let connector1 = {}, connector2 = {};
      let signal_connections_map = pins.map(p => ({ name: p.signal }));
      let pins_1 = pins, pins_2 = [], usedPin = [];
      if (connect && connect.pcbId) {
        const connectInterface = Interfaces.find(i => i.pcbId === connect.pcbId);
        if (!connectInterface) {
          channel2 = { designId: "", designName: "" }
        } else {
          const _designName = Interfaces.find(i => i.pcbId === connect.pcbId).pcb;
          channel2 = { designId: connect.pcbId, designName: _designName }
        }
        selectedComp.push({ name: connect.componentName, pcbId: connect.pcbId });
        const another = components.find(c => c.name === connect.componentName && c.pcbId === connect.pcbId);
        if (another) {
          pins_1 = [];
          for (let p of pins) {
            const anotherPin = another.pins.find(_p => _p.signal === p.signal && !usedPin.includes(_p.pin));
            const pin_map = p.pin_map ? p.pin_map : anotherPin ? anotherPin.pin : null;
            if (!p.pin_map && pin_map) {
              updateConnector = true;
              updatePCBIds.push(pcbId, connect.pcbId)
            }
            anotherPin && usedPin.push(anotherPin.pin)
            pins_1.push({ ...p, pin_map })
          }
          pins_2 = another.pins;
          if (another.pins.length > signal_connections_map.length) {
            signal_connections_map = another.pins.map(p => ({ name: p.signal }))
          }
          connector2 = { component: another.name, models: another.models ? [...another.models] : [], pins: pins_2 };
        }

        connector1 = { component: name, models: models ? [...models] : [], pins: pins_1 };
        const connection = { cableModels, connector1, connector2, signal_connections_map }

        function getValue({ _newConnector, channel1, channel2, connection, connection_index, CONNECTION_ID }) {
          let findIndex = _newConnector.findIndex(item => item.channel1.designId === channel1.designId && item.channel2.designId === channel2.designId)
          if (findIndex > -1) {
            _newConnector[findIndex].connectionList.push({ ...connection, CONNECTION_ID: connection_index })
          } else {
            findIndex = _newConnector.findIndex(item => item.channel2.designId === channel1.designId && item.channel1.designId === channel2.designId)
            if (findIndex > -1) {
              _newConnector[findIndex].connectionList.push({ ...connection, connector1: connection.connector2, connector2: connection.connector1, CONNECTION_ID: connection_index })
            } else {
              _newConnector.push({
                channel1,
                channel2,
                connectionList: [{ ...connection, CONNECTION_ID: connection_index }],
                CONNECTION_ID
              })
            }
          }
          return _newConnector;
        }

        //If the connector data is updated
        //Do not change the order of display
        if (notUpdateOrder) {
          let changeConnector = true;
          for (let preConnectionInfo of preConnections) {
            // Do not modify the order
            const { channel1: _channel1, channel2: _channel2, connectionList: _connectionList = {}, CONNECTION_ID } = preConnectionInfo;
            if (_channel1.designId === channel1.designId
              && _channel2.designId === channel2.designId) {
              const findConnectionData = _connectionList.find(item => (
                (!item.connector1.component && !connector1.component) || item.connector1.component === connector1.component)
                && ((!item.connector2.component && !connector2.component) || item.connector2.component === connector2.component))
              _newConnector = getValue({ _newConnector, channel1, channel2, connection, connection_index: findConnectionData && findConnectionData.CONNECTION_ID ? findConnectionData.CONNECTION_ID : null, CONNECTION_ID })
              changeConnector = false
              break;

            } else if (_channel1.designId === channel2.designId
              && _channel2.designId === channel1.designId) {
              const findConnectionData = _connectionList.find(item =>
                ((!item.connector2.component && !connector1.component) || item.connector2.component === connector1.component)
                && ((!item.connector1.component && !connector2.component) || item.connector1.component === connector2.component))

              let _connector2Pins = connector2.pins;
              if (connector2.pins && connector2.pins.length) {
                _connector2Pins = connector2.pins.map(item => {
                  const { pin_map, pin } = item;
                  let _pin_map = pin_map;
                  if (!_pin_map) {
                    const findData = connector1.pins.find(it => it.pin_map === pin)
                    if (findData) { _pin_map = findData.pin }
                  }
                  return { ...item, pin_map: _pin_map }
                })
              }
              const _connection = { cableModels, connector1: { ...connector2, pins: _connector2Pins }, connector2: connector1, signal_connections_map }
              _newConnector = getValue({ _newConnector, channel1: channel2, channel2: channel1, connection: _connection, connection_index: findConnectionData && findConnectionData.CONNECTION_ID ? findConnectionData.CONNECTION_ID : null, CONNECTION_ID })
              changeConnector = false
              break;
            }
          }
          if (changeConnector) {
            _newConnector = getValue({ _newConnector, channel1, channel2, connection })
          }
        } else {
          _newConnector = getValue({ _newConnector, channel1, channel2, connection })
        }

        connections.push({
          channel1,
          channel2,
          connection,
          CONNECTION_ID: connection_index
        })
        connection_index++
      }
    }
  }

  if (init) {
    connections.sort((conn1, conn2) => {
      return conn1.channel1.designId && conn1.channel2.designId && (!conn2.channel1.designId || !conn2.channel2.designId) ? -1 : 1
    });
  }

  if (notUpdateOrder && preConnections.length) {
    // Sort according to previous order
    function getSort(list) {
      let _list = [...list]
      _list.sort((conn1, conn2) => {
        if (conn1.CONNECTION_ID && !conn2.CONNECTION_ID) { return -1 }
        if (!conn1.CONNECTION_ID && conn2.CONNECTION_ID) { return 1 }
        if (conn1.CONNECTION_ID && conn2.CONNECTION_ID) { return conn1.CONNECTION_ID - conn2.CONNECTION_ID }
      })
      return _list
    }
    _newConnector = getSort(_newConnector);

    _newConnector.forEach(item => {
      item.connectionList = getSort(item.connectionList)
    })
  }

  let connectionList_index = 0;
  _newConnector.forEach((conn, index) => {
    conn.connectionList = conn.connectionList.map(item => {
      connectionList_index++
      return { ...item, CONNECTION_ID: connectionList_index }
    })
    conn.CONNECTION_ID = (index + 1).toString()
  });

  // get pcb info
  selectedComp = [...new Set(selectedComp.map(selected => selected.pcbId))];
  pcbConnections = selectedComp.map(designId => {
    const current = Interfaces.find(i => i.pcbId === designId);
    const designName = current ? current.pcb : '';
    return { designId, designName }
  })

  return { connections: _newConnector, pcbConnections, updateConnector, newConnections: connections, updatePCBIds }
}

function connectorHandle(Interfaces) {
  let _Interfaces = [...Interfaces], notConnect = [];

  _Interfaces.forEach((_interface, interfaceIndex) => {
    _interface.content.components.forEach((comp) => {
      if (comp.type === CONNECTOR) {
        if (comp.connect && comp.connect.pcbId) {
          const connIndex = _Interfaces.findIndex(inter => inter.pcbId === comp.connect.pcbId);
          const connectInterface = connIndex > -1 ? _Interfaces[connIndex] : null;
          const compIndex = connectInterface && connectInterface.content && connectInterface.content.components ? connectInterface.content.components.findIndex(_comp => _comp.type === CONNECTOR && _comp.name === comp.connect.componentName) : -1;
          const findConnComp = compIndex > -1 ? connectInterface.content.components[compIndex] : null;
          if (!findConnComp) {
            comp.connect = {};
            comp.pins.forEach(pin => {
              pin.pin_map = "";
            })
            if (comp.cableModels && comp.cableModels.length) {
              comp.cableModels = [];
              comp.pins.forEach(pin => {
                if (pin.external_map) {
                  pin.external_map.cableModelId = "";
                  pin.external_map.cablePort = "";
                }
              })
            }
          } else {
            comp.pins.forEach(pin => {
              const index = pin.pin_map
                ? (findConnComp.pins || []).findIndex(item => item.pin === pin.pin_map)
                : -1;

              if (index < 0
                || (index > -1 && findConnComp.pins[index].signal !== pin.signal)) {
                pin.pin_map = "";
              }

              const map_index = (findConnComp.pins || []).findIndex(item => item.pin_map === pin.pin);
              if (map_index > -1 && findConnComp.pins[map_index].signal !== pin.signal) {
                findConnComp.pins[map_index].pin_map = "";
                _Interfaces[connIndex].content.components[compIndex].pins[map_index].pin_map = "";
              }
            })
          }
        }

        if (!comp.connect || !comp.connect.pcbId) {
          if (!notConnect.length) {
            notConnect.push({ pcbId: _interface.pcbId, name: comp.name, interfaceIndex })
          } else {
            let remove = -1;
            for (let i = 0; i < notConnect.length; i++) {
              if (_interface.pcbId !== notConnect[i].pcbId) {
                remove = i;
                break;
              }
            }
            if (remove >= 0) {
              const _connect = notConnect[remove];
              comp.connect = { pcbId: _connect.pcbId, name: _connect.name };
              _Interfaces[_connect.interfaceIndex].content.components.find(item => item.name === _connect.name).connect = { pcbId: _interface.pcbId, componentName: comp.name };
              notConnect.splice(remove, 1);
            } else {
              notConnect.push({ pcbId: _interface.pcbId, name: comp.name, interfaceIndex })
            }
          }
        }
      }
    })
  })
  return _Interfaces;
}

function getConnCompList({ CONNECTION_ID, connections, Interfaces }) {
  //Left and right switchable PCB
  //1. Filter existing combinations
  //2. Filter the selected PCB on the other side

  let leftCompList = [], rightCompList = [];
  if (CONNECTION_ID) {
    const connection = connections.find(conn => conn['CONNECTION_ID'] === CONNECTION_ID);
    if (connection) {
      for (let i = 1; i < 3; i++) {
        const channel = connection[`channel${i}`];
        const Interface = Interfaces.find(i => i.pcbId === channel.designId);
        if (i === 1 && Interface) {
          leftCompList = Interface.content.components.filter(component => component.type === CONNECTOR);
        } else if (Interface) {
          rightCompList = Interface.content.components.filter(component => component.type === CONNECTOR);
        }
      }
    }
  }
  return { leftCompList, rightCompList }
}

function saveConnectorComp({ connectorComp, cableModels, anotherChannel, another, connector, clear = false }) {

  if (!connectorComp) {
    return;
  }

  let newComp = { ...connectorComp };
  newComp.connect = clear ? {} : { pcbId: anotherChannel.designId, componentName: another.component };
  newComp.cableModels = clear ? [] : cableModels;
  newComp.models = clear ? [] : connector.models;

  let newPins = [];
  for (let pin of newComp.pins) {
    const currentPin = connector && connector.pins ? connector.pins.find(p => p.pin === pin.pin) : null;
    newPins.push(currentPin ? clear ? { pin: pin.pin, signal: pin.signal, net: pin.net } : { ...pin, ...currentPin } : pin)
  }

  newComp.pins = [...newPins];

  return newComp
}

function changePCBInConnector(connection, value, channelIndex) {
  let _connection = JSON.parse(JSON.stringify(connection))
  let channel = _connection[`channel${channelIndex}`];
  let anotherIndex = channelIndex === 1 ? 2 : 1;

  channel = value ? { designId: value.key, designName: value.label } : { designId: "", designName: "" };
  _connection.connection[`connector${channelIndex}`] = { component: "", pins: [], models: [] };
  _connection.connection[`signal_connections_map`] = _connection.connection[`connector${anotherIndex}`] && _connection.connection[`connector${anotherIndex}`].pins
    ? _connection.connection[`connector${anotherIndex}`].pins.map(pin => ({ name: pin.signal })) : [];
  _connection[`channel${channelIndex}`] = channel;

  return _connection;
}

class defaultConnectionConfig {
  constructor(cableModels = [], connector1 = {}, connector2 = {}, signal_connections_map = []) {
    this.cableModels = cableModels;
    this.connector1 = connector1;
    this.connector2 = connector2;
    this.signal_connections_map = signal_connections_map
  }
}

function getPcbList(Interfaces, anotherChannel, connections, CONNECTION_ID, channel) {
  let selectedPcb = [];
  for (let conn of connections) {
    if (conn[`CONNECTION_ID`] !== CONNECTION_ID) {
      if (anotherChannel.designId && conn.channel1.designId === anotherChannel.designId) {
        selectedPcb.push(conn.channel2.designId)
      } else if (anotherChannel.designId && conn.channel2.designId === anotherChannel.designId) {
        selectedPcb.push(conn.channel1.designId)
      }
    }
  }
  let list = [];
  // Filter existing combinations, connect PCBs on the other side, and PCBs without unconnected connector comps
  for (let item of Interfaces) {
    const findConnectorComp = item.content.components.find(comp => comp.type === CONNECTOR && (!comp.connect || !comp.connect.pcbId))
    if (findConnectorComp) {
      if (item.pcbId !== anotherChannel.designId && !selectedPcb.includes(item.pcbId)) {
        list.push({ key: item.pcbId, label: item.pcb })
      }
    } else if (item.pcbId === channel.designId) {
      list.push({ key: item.pcbId, label: item.pcb })
    }
  }
  return list
}

function getCompTypeByConnections({ connections, type, name, pcbId }) {
  if (type === CONNECTOR || !connections.length) {
    return type;
  }
  const designName = designConstructor.getDesignName(pcbId);
  const filterConns = connections.filter(item =>
    (item.Aboard === designName && item.Aconn && item.Aconn.component === name)
    || (item.Bboard === designName && item.Bconn && item.Bconn.component === name));

  if (filterConns.length) {
    return CONNECTOR;
  }

  return type;
}

function connectorInterfacePCB({ interfaceParams, connections }) {
  let designNames = [], allConnectorComps = [];

  if (interfaceParams.length === 1) {
    //single pcb, connector to ic
    for (let _interface of interfaceParams) {
      const ports_generate_setup_list = _interface.content.ports_generate_setup_list || [];
      const extractionType = _interface.content.channel.type;
      _interface.content.components = _interface.content.components.map((comp) => {
        if (comp.type === CONNECTOR) {
          const part = comp.part;
          comp.pins = comp.pins.map(it => new ICCompPinModel({ pin: it.pin, net: it.net, signal: it.signal }));
          comp = new ICComponent({ name: comp.name, type: IC, pins: comp.pins });
          comp.part = part;
          const findPortSetup = ports_generate_setup_list.find(item => item.component === comp.name);
          if (findPortSetup && findPortSetup.setup && findPortSetup.setup.portType) {
            comp = setDefaultPortData(comp, findPortSetup.setup.portType, extractionType, _interface.pcbId)
          }
        }
        return comp;
      })
    }
    return interfaceParams
  }

  if (!connections || !connections.length) {
    return interfaceParams
  }

  let connPCBObj = {}
  for (let inItem of interfaceParams) {
    const designName = designConstructor.getDesignName(inItem.designId);
    designNames.push(designName);
    const connCompList = inItem.content.components.filter(item => item.type === CONNECTOR).map(item => {
      return {
        designId: inItem.designId,
        designName,
        component: item.name,
        pins: JSON.parse(JSON.stringify(item.pins))
      }
    })
    connPCBObj[designName] = connCompList || [];
    allConnectorComps.push(...connCompList);
  }

  const filterConnections = connections.filter(item =>
    (designNames.includes(item.Aboard)
      && item.Aconn &&
      (connPCBObj[item.Aboard] || []).find(comp => comp.component === item.Aconn.component))
    || (designNames.includes(item.Bboard)
      && item.Bconn &&
      (connPCBObj[item.Bboard] || []).find(comp => comp.component === item.Bconn.component)));

  let connsObj = {};
  for (let conn of filterConnections) {
    if (!connsObj[conn.Aboard]) {
      connsObj[conn.Aboard] = {}
    }

    if (!connsObj[conn.Aboard][conn.Aconn.component]) {
      connsObj[conn.Aboard][conn.Aconn.component] = []
    }
    connsObj[conn.Aboard][conn.Aconn.component].push({
      pin: conn.Aconn.pin,
      connPCB: conn.Bboard,
      connComp: conn.Bconn.component,
      connPin: conn.Bconn.pin,
    });

    if (!connsObj[conn.Bboard]) {
      connsObj[conn.Bboard] = {}
    }
    if (!connsObj[conn.Bboard][conn.Bconn.component]) {
      connsObj[conn.Bboard][conn.Bconn.component] = []
    }
    connsObj[conn.Bboard][conn.Bconn.component].push({
      pin: conn.Aboard.pin,
      connPCB: conn.Bboard,
      connComp: conn.Aconn.component,
      connPin: conn.Aconn.pin,
    });
  }

  let connCompObj = {}
  for (let _interface of interfaceParams) {
    const designName = designConstructor.getDesignName(_interface.designId);
    const ports_generate_setup_list = _interface.content.ports_generate_setup_list || [];
    const extractionType = _interface.content.channel.type;
    _interface.content.components = _interface.content.components.map((comp) => {
      if (comp.type === CONNECTOR) {

        for (let pinItem of comp.pins) {
          let findConn = null;

          if (connCompObj[`${designName}::${comp.name}::${pinItem.pin}`]) {
            findConn = { ...connCompObj[`${designName}::${comp.name}::${pinItem.pin}`] }
          } else {
            findConn = getConnPinInfo({
              connsObj,
              designName,
              compName: comp.name,
              pin: pinItem.pin,
              pinName: pinItem.pinName,
              allConnectorComps
            }) || {};

            if (!findConn || !findConn.designId || !findConn.designName || !findConn.component || !findConn.pin) {
              continue;
            }
            connCompObj[`${designName}::${comp.name}::${pinItem.pin}`] = { ...findConn };
            connCompObj[`${findConn.designName}::${findConn.component}::${findConn.pin}`] = { designName, designId: _interface.designId, component: comp.name, pin: pinItem.pin };
          }

          pinItem.pin_map = findConn.pin;

          if (!comp.connect
            || comp.connect.componentName !== findConn.component
            || comp.connect.pcbId !== findConn.designId) {
            comp.connect = {
              pcbId: findConn.designId,
              componentName: findConn.component,
            }
          }
        }
        // no pcb connection , connector to ic
        if (!comp.connect
          || !comp.connect.componentName
          || !comp.connect.pcbId) {
          const part = comp.part;
          comp.pins = comp.pins.map(it => new ICCompPinModel({ pin: it.pin, net: it.net, signal: it.signal }));
          comp = new ICComponent({ name: comp.name, type: IC, pins: comp.pins });
          comp.part = part;
          const findPortSetup = ports_generate_setup_list.find(item => item.component === comp.name);
          if (findPortSetup && findPortSetup.setup && findPortSetup.setup.portType) {
            comp = setDefaultPortData(comp, findPortSetup.setup.portType, extractionType, _interface.pcbId)
          }
        }
      }
      return comp;
    })
  }
  return interfaceParams;
}


function getConnPinInfo({ connsObj, designName, compName, pin, pinName, allConnectorComps }) {
  if (!connsObj || !connsObj[designName] || !connsObj[designName][compName] || !connsObj[designName][compName].length) {
    return {}
  }
  let findConn = null;

  //1 Pin exist and conn pin exist
  let findPins = connsObj[designName][compName].filter(item => !!item.pin
    && (item.pin === pin || item.pin === pinName)
    && !!item.connPin);

  let findConnPin = null;
  findConn = allConnectorComps.find(comp => {
    const connPin = findPins.find(it => it.connPCB === comp.designName
      && it.connComp === comp.component
      && comp.pins.find(pin => (pin.pin === it.connPin || pin.pinName === it.connPin)))
    if (!connPin) {
      return false
    }
    findConnPin = (comp.pins.find(pin => (pin.pin === connPin.connPin || pin.pinName === connPin.connPin)) || {}).pin
    return true;
  }
  );

  if (findConn) {
    return {
      designName: findConn.designName,
      component: findConn.component,
      designId: findConn.designId,
      pin: findConnPin
    };
  }

  //2 pin exist and conn pin not exist
  const find1Pins = connsObj[designName][compName].filter(item => !!item.pin
    && (item.pin === pin || item.pin === pinName)
    && !item.connPin);

  //3 pin not exist and conn pin exist
  const find2Pins = connsObj[designName][compName].filter(item => !item.pin
    && !!item.connPin && (item.connPin === pin || item.connPin === pinName));

  //43= pin not exist and conn pin not exist
  const find3Pins = connsObj[designName][compName].filter(item => !item.pin && !item.connPin);

  findPins = [...find1Pins, ...find2Pins, ...find3Pins]

  findConn = allConnectorComps.find(comp => {
    const connPin = findPins.find(it => it.connPCB === comp.designName
      && it.connComp === comp.component
      && comp.pins.find(pinItem => (pinItem.pin === pin || (pinItem.pinName && pinItem.pinName === pinName))))
    if (!connPin) {
      return false
    }
    return true
  });

  if (findConn) {
    return {
      designName: findConn.designName,
      component: findConn.component,
      designId: findConn.designId,
      pin: (findConn.pins.find(pinItem => (pinItem.pin === pin || (pinItem.pinName && pinItem.pinName === pinName))) || {}).pin
    }
  }

  return {}
}

function getConnectorList(Interfaces) {
  if (!Interfaces || !Interfaces.length) {
    return []
  }
  let connectorList = []
  for (let info of Interfaces) {
    if (!info.content || !info.content.components) {
      continue;
    }
    const connComps = info.content.components.filter(item => item.type === CONNECTOR);
    connectorList.push(...connComps.map(item => {
      item.pcb = info.pcb;
      item.pcbId = info.pcbId;
      return item
    }))
  }
  return connectorList;
}

function updateReplaceConnections({ Interfaces, previousPCBId, newPCBId, connectionComps }) {
  if (!Interfaces || Interfaces.length <= 1) {
    return Interfaces;
  }

  const newInterfaces = JSON.parse(JSON.stringify(Interfaces));

  for (let info of newInterfaces) {
    if (!info.content || !info.content.components) {
      continue;
    }
    const findConn = info.content.components.find(item => item.type === CONNECTOR);
    if (!findConn) {
      continue;
    }
    for (let comp of info.content.components) {
      if (comp.type !== CONNECTOR || !comp.pins) {
        continue;
      }
      if (!comp.connect || !comp.connect.pcbId || !comp.connect.componentName) {
        //only update pcbId
        const prevConnId = comp.connect && comp.connect.pcbId ? comp.connect.pcbId : "";
        comp.connect = {
          pcbId: prevConnId && prevConnId === previousPCBId ? newPCBId : prevConnId,
          componentName: "",
        }
        comp.pins.forEach(pin => {
          pin.pin_map = "";
        })
        continue;
      }
      //update pcbId and connector info
      if (comp.connect.pcbId === previousPCBId) {
        comp.connect.pcbId = newPCBId;
      }
      const findConnComp = connectionComps.find(item => item.name === comp.connect.componentName && item.pcbId === comp.connect.pcbId);
      if (!findConnComp) {
        comp.connect = {
          pcbId: comp.connect.pcbId,
          componentName: "",
        }
        comp.pins.forEach(pin => {
          pin.pin_map = "";
        })
        continue;
      }

      const connPins = findConnComp.pins || [];

      comp.pins.forEach(pin => {
        const findPinMap = connPins.find(item => item.pin === pin.pin_map);
        pin.pin_map = findPinMap ? findPinMap.pin : "";
      })
    }
  }

  return newInterfaces;
}

export {
  updateConnector,
  getDefaultConnector,
  getConnectionsInfo,
  connectorHandle,
  getConnCompList,
  saveConnectorComp,
  changePCBInConnector,
  defaultConnectionConfig,
  getPcbList,
  getCompTypeByConnections,
  connectorInterfacePCB,
  getConnectorList,
  updateReplaceConnections
}