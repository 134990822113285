import {
  CHANGE_RESULT_KEY,
  RESULT_CHANNEL,
  CHANGE_EYE_DIAGRAM_PROGRESS,
  SAVE_SELECT_SIGNALS,
  SAVE_EYE_DIAGRAM_SIGNALS,
  SAVE_EYE_DIAGRAM_IMG,
  UPDATE_EYE_DIAGRAM_CONFIG,
  SAVE_SIMULATION_CONFIG,
  SAVE_EYE_UNIT_VALUE,
  UPDATE_EYE_DIAGRAM_CLOCKS,
  RESULT_PAGE_INFO,
  UPDATE_RECALC,
  UPDATE_SWEEP_RECALC,
  UPDATE_SWEEP_MEASUREMENT_PROGRESS
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import {
  CLOCK_FREQUENCY,
  DELAY,
  T_SHIFT
} from '../../constants';

const defaultState = {
  currentResultKey: 'impedance',
  progress: [],
  selectedClocks: [],
  selectedSignals: [],
  clocks: [],
  signals: [],
  receiverSignals: [],
  DDRSignals: [],
  imgSrc: null,
  channelList: [],
  resultInfo: {},
  eyeDiagramConfig: { eye_config: {}, plot_config: {} },
  simulationConfig: null,
  eyeDiagramUnitValue: {
    [DELAY]: {
      value: "",
      unit: "ps"
    },
    [T_SHIFT]: {
      value: "",
      unit: "ps"
    },
    [CLOCK_FREQUENCY]: {
      value: "",
      unit: "MHz"
    }
  },
  clockPositive: "",
  clockNegative: "",
  eyeClockType: "",
  recalc: false,
  allSignalPins: [],
  clockPPin: null,
  clockNPin: null,
  sweepMeasureProgress: -1
}

export const resultReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_RESULT_KEY:
      return {
        ...state,
        currentResultKey: action.key
      };
    case RESULT_CHANNEL:
      return {
        ...state,
        channelList: action.channelList,
        currentResultKey: ((state.currentResultKey === 'channel' && !action.channelList.length) && 'impedance') || state.currentResultKey
      }
    case CHANGE_EYE_DIAGRAM_PROGRESS:
      const { progress } = state;
      let newProgress = [...progress];
      const index = newProgress.findIndex(item => item.verificationId === action.verificationId);
      if (typeof (index) !== 'boolean' && index > -1) {
        newProgress[index].progress = action.progress;
      } else {
        newProgress.push({
          progress: action.progress,
          verificationId: action.verificationId
        });
      }
      return {
        ...state,
        progress: [...newProgress]
      };
    case SAVE_EYE_DIAGRAM_SIGNALS:
      return {
        ...state,
        clocks: action.clocks,
        signals: action.signals,
        receiverSignals: action.receiverSignals ? action.receiverSignals : [],
        DDRSignals: action.DDRSignals ? action.DDRSignals : [],
        allSignalPins: action.allSignalPins || []
      }
    case SAVE_SELECT_SIGNALS:
      return {
        ...state,
        selectedClocks: action.selectedClocks ? action.selectedClocks : state.selectedClocks,
        selectedSignals: action.selectedSignals ? action.selectedSignals : state.selectedSignals
      }
    case SAVE_EYE_DIAGRAM_IMG:
      return {
        ...state,
        imgSrc: action.imgSrc
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_EYE_DIAGRAM_CONFIG:
      return {
        ...state,
        eyeDiagramConfig: action.eyeDiagramConfig
      }
    case UPDATE_EYE_DIAGRAM_CLOCKS:
      return {
        ...state,
        clockPositive: action.clockPositive !== undefined ? action.clockPositive : state.clockPositive,
        clockNegative: action.clockNegative !== undefined ? action.clockNegative : state.clockNegative,
        eyeClockType: action.eyeClockType !== undefined ? action.eyeClockType : state.eyeClockType,
        clockPPin: action.clockPPin !== undefined ? action.clockPPin : state.clockPPin,
        clockNPin: action.clockNPin !== undefined ? action.clockNPin : state.clockNPin
      }
    case SAVE_SIMULATION_CONFIG:
      return {
        ...state,
        simulationConfig: action.simulationConfig
      }
    case SAVE_EYE_UNIT_VALUE:
      return {
        ...state,
        eyeDiagramUnitValue: action.eyeDiagramUnitValue
      }
    case RESULT_PAGE_INFO:
      return {
        ...state,
        resultInfo: {
          verificationId: action.verificationId,
          verificationSubId: action.verificationSubId,
          name: action.name,
          status: action.status
        }
      }
    case UPDATE_RECALC:
      return {
        ...state,
        recalc: action.calc
      }
    case UPDATE_SWEEP_RECALC:
      return {
        ...state,
        sweepReCalc: action.sweepReCalc
      }
    case UPDATE_SWEEP_MEASUREMENT_PROGRESS:
      return {
        ...state,
        sweepMeasureProgress: action.progress
      }
    default: return state;
  }
};