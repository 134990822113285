import { ROUGHNESS_NONE } from "./Roughness";
import { METAL } from "./stackupConstants";

class StackupLayer {
  constructor({
    name = "",
    type = METAL,
    thickness = "",
    material = "",
    roughness = { type: ROUGHNESS_NONE },
    bot_roughness,
    side_roughness,
    etch_factor = "",
    laminated,
    fill_material = ""
  }, isSave) {
    this.name = name;
    this.type = type; //enum: [Dielectric, Metal]
    this.thickness = thickness;
    this.material = material;
    if (type === METAL) {
      this.roughness = roughness;
      if (!isSave || (bot_roughness && side_roughness)) {
        this.bot_roughness = bot_roughness || { type: ROUGHNESS_NONE };
        this.side_roughness = side_roughness || { type: ROUGHNESS_NONE };
      }
      this.fill_material = fill_material || "";
    }
    this.setLayerType = this.setLayerType.bind(this);
    this.setThickness = this.setThickness.bind(this);
    this.setMaterial = this.setMaterial.bind(this);
    this.getLayerThickness = this.getLayerThickness.bind(this);
    if (etch_factor) {
      this.etch_factor = etch_factor
    }
    if (laminated !== undefined) {
      this.laminated = laminated !== undefined ? laminated : true;
    }
  }

  setLayerType(type) {
    this.type = type;
  }

  setThickness(thickness) {
    this.thickness = thickness;
  }

  setMaterial(material) {
    this.material = material;
  }

  getLayerThickness() {
    return this.thickness;
  }
}

function StackupJson({
  layers = [],
  version = "",
  unit = "",
  materials = [],
  zones,
  stackups,
  bends
}) {
  this.layers = layers;
  this.version = version;
  this.unit = unit;
  this.materials = materials;
  if (zones) {
    this.zones = zones;
  }
  if (stackups) {
    this.stackups = stackups;
  }
  if (bends) {
    this.bends = bends;
  }
}

export {
  StackupLayer,
  StackupJson
}