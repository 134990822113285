import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getLibraryFileContent, getTouchstoneFile } from '@/services/Sierra/library';
import { EditOutlined } from '@ant-design/icons';
import { Spin, Input, Tooltip, Button, Space } from 'antd';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { getDefaultName } from '../../../services/helper/setDefaultName'
import { LIBRARY, LIBRARY_FILE, LIBRARY_FOLDER } from '../../../constants/treeConstants';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import './sierraPanel.css';
import {
  IBIS, SPICE, CONNECTOR_SPICE_SIERRA, CONNECTOR_TOUCHSTONE, VECTOR,
  PASSIVE_TOUCHSTONE, PASSIVE_SPICE, REPEATER,
  PKG_TOUCHSTONE, PKG_SPICE,
  PCB_SPICE
} from '@/constants/libraryConstants';

const { TextArea } = Input;
const SpaceCompact = Space.Compact;

function getLibrarySuffix(modelType, n = 2) {
  switch (modelType) {
    case IBIS:
      return ".ibs";
    case SPICE:
    case PASSIVE_SPICE:
    case REPEATER:
    case CONNECTOR_SPICE_SIERRA:
    case PKG_SPICE:
    case PCB_SPICE:
      return ".sp";
    case CONNECTOR_TOUCHSTONE:
    case PASSIVE_TOUCHSTONE:
    case PKG_TOUCHSTONE:
      return `.s${n}p`
    case VECTOR:
      return ".vec";
    default: return ""
  }
}

class FileContent extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.width = this.getDefaultWidth();
    this.state = {
      width: this.width - 32,
      linePosition: {
        position: "absolute",
        left: 280
      },
      height: null,
      fileLoding: false,
      isEdit: false
    }
  }

  getDefaultWidth = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    const _width = width * 0.7;
    return _width > 1000 ? 1000 : _width;
  }

  changePanelSize = (obj) => {
    setTimeout(() => {
      this.setState({
        height: obj.height,
        width: obj.width - 36
      })
    }, 100);
  }

  setFileInfo = ({ title, fileId, dataType, modelFileType }) => {
    if (fileId && dataType !== LIBRARY_FOLDER) {
      this.setState({
        fileLoding: true,
        fileContent: null,
        isEdit: false
      });
      let getFile = getLibraryFileContent;
      if ([CONNECTOR_TOUCHSTONE, PKG_TOUCHSTONE, PASSIVE_TOUCHSTONE].includes(this.props.modelFileType)) {
        getFile = getTouchstoneFile;
      }
      getFile(fileId, title).then(res => {
        if (res) {
          this.setState({
            fileContent: res,
            fileLoding: false
          })
        }
      });
    }

    if (dataType === LIBRARY || dataType === LIBRARY_FOLDER) {
      let defaultName = `${modelFileType}${getLibrarySuffix(modelFileType)}'`
      sierraLibrary.get({ id: dataType === LIBRARY ? '-1' : fileId, type: modelFileType }).then(res => {
        const libraryList = res.filter(item => item.libraryType !== 'folder');
        defaultName = getDefaultName({ nameList: libraryList, firstIndex: 1, defaultKey: defaultName.split('.')[0], delimiter: '_', endDelimiter: getLibrarySuffix(modelFileType) });
        this.setState({
          fileContent: null,
          editName: defaultName.split('.')[0],
          isEdit: true,
          editFileContent: '',
          fileSuffix: getLibrarySuffix(modelFileType) === '.s2p' ? 2 : getLibrarySuffix(modelFileType)
        })
      })
    }
  }

  componentDidMount() {
    const { title, fileId, dataType, modelFileType } = this.props;
    this.setFileInfo({ title, fileId, dataType, modelFileType })
  }

  componentDidUpdate(prevProps) {
    const { title, fileId, isUpdate, dataType, modelFileType } = this.props;
    if (prevProps.fileId !== fileId || prevProps.title !== title || (isUpdate && prevProps.isUpdate !== isUpdate)) {
      this.setFileInfo({ title, fileId, dataType, modelFileType })
      isUpdate && this.props.updateFileContent(false)
    }
  }

  onChangeEditFileContent = ({ target: { value } }) => {
    this.setState({
      editFileContent: value
    })
  };

  onChangeEditFileName = ({ target: { value } }) => {
    this.setState({
      editName: value
    })
  }

  onChangeEditFileSuffix = ({ target: { value } }) => {
    const patrn = /^[0-9]$/;
    if (patrn.exec(parseInt(value)) == null && value) {
      this.setState({
        errorMsg: 'Please enter 0-9!'
      })
      return
    } else {
      this.setState({
        fileSuffix: value
      })
    }
  }

  saveEditFileName = (e) => {
    e.stopPropagation()
    const { editName, fileSuffix } = this.state;
    const { fileId, dataType, modelFileType } = this.props;
    let errorMsg = checkNameFormat(editName);
    if (errorMsg) {
      this.setState({
        errorMsg: errorMsg
      });
      return;
    }

    sierraLibrary.get({ id: dataType === LIBRARY ? '-1' : fileId, type: modelFileType }).then(res => {
      const libraryList = res.filter(item => item.libraryType !== 'folder');
      const names = libraryList.map(item => item.name);
      if (names.includes(editName + getLibrarySuffix(modelFileType, fileSuffix))) {
        this.setState({
          errorMsg: `${editName + getLibrarySuffix(modelFileType, fileSuffix)} already exists!`
        });
        return;
      }
      this.setState({ isEditName: false })
    })
  }

  getTitle = (title) => {
    const { dataType, modelFileType } = this.props
    const { fileContent, editName, isEditName, errorMsg, fileSuffix } = this.state
    return (
      <div>
        {
          dataType === LIBRARY || dataType === LIBRARY_FOLDER ? isEditName ?
            <Tooltip
              className='aurora-error-tooltip'
              title={errorMsg ? errorMsg : null}
              overlayClassName='aurora-error-msg-tooltip'
              open={errorMsg ? true : false}
            >
              <SpaceCompact>
                <Input
                  value={editName}
                  onChange={this.onChangeEditFileName}
                  onBlur={(e) => this.saveEditFileName(e)}
                  style={{ width: getLibrarySuffix(modelFileType) === '.s2p' ? '200px' : '150px' }}
                />
                {
                  getLibrarySuffix(modelFileType) === '.s2p' ?
                    <Input
                      suffix="p" prefix=".s"
                      onChange={this.onChangeEditFileSuffix}
                      style={{ width: '70px' }}
                      value={fileSuffix}
                      onBlur={() => this.setState({ isEditName: false })}
                    /> : <span className='file-title' style={{ lineHeight: '32px' }}>{fileSuffix}</span>
                }
              </SpaceCompact>
            </Tooltip>
            :
            <span className='file-title-span' onClick={() => this.setState({ isEditName: true })}>{editName}{getLibrarySuffix(modelFileType, fileSuffix)}</span>
            :
            <span className='file-title'>{title}</span>
        }
        {
          dataType === LIBRARY_FILE ?
            <Tooltip
              placement="top"
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
              title={'Edit File Content'}
              overlayClassName='aurora-tooltip'
            >
              <EditOutlined
                className='sierra-repeater-edit-icon'
                onClick={() => this.setState({ isEdit: true, editFileContent: fileContent })} />
            </Tooltip>
            : null
        }
      </div>
    );
  }

  saveFileContent = (type) => {
    const { title, parentId, fileId, modelFileType } = this.props
    const { editFileContent } = this.state
    if (type === 'create') {
      let defaultName = ''
      sierraLibrary.get({ id: parentId, type: modelFileType }).then(res => {
        const libraryList = res.filter(item => item.libraryType !== 'folder');
        defaultName = getDefaultName({ nameList: libraryList, firstIndex: 1, defaultKey: title.split('.')[0], delimiter: '_', endDelimiter: `.${title.split('.').pop()}` });
        this.props.saveLibraryFile({ fileId: null, folderId: parentId, name: defaultName, content: editFileContent, libraryType: modelFileType })
      })
    } else {
      this.setState({
        fileLoding: true,
        fileContent: null
      });
      this.props.saveLibraryFile({ fileId, folderId: parentId, name: title, content: editFileContent, libraryType: modelFileType })
    }
    this.setState({
      isEdit: false,
      confirmVisible: false,
      editFileContent: null
    })
  }

  clickSave = () => {
    const { dataType, fileId, modelFileType } = this.props;
    const { editFileContent, editName, fileSuffix } = this.state;
    // Click on the title to create a new blank file
    if (dataType === LIBRARY) {
      this.props.saveLibraryFile({ fileId: null, folderId: '-1', name: editName + getLibrarySuffix(modelFileType, fileSuffix), content: editFileContent, libraryType: modelFileType })
      this.props.closeModal()
    } else if (dataType === LIBRARY_FOLDER) {
      // Click on the folder to create a new blank file
      this.props.saveLibraryFile({ fileId: null, folderId: fileId, name: editName + getLibrarySuffix(modelFileType, fileSuffix), content: editFileContent, libraryType: modelFileType })
      this.props.closeModal()
    } else {
      this.saveFileContent('edit')
    }
  }

  clickSaveAs = () => {
    this.saveFileContent('create')
  }


  clickCancel = () => {
    const { dataType } = this.props;
    if (dataType === LIBRARY || dataType === LIBRARY_FOLDER) {
      this.props.closeModal();
    } else {
      this.setState({ isEdit: false });
    }
  }

  render() {
    const { title, closeModal, dataType } = this.props;
    const { height, width, fileContent, fileLoding, isEdit, editFileContent } = this.state;
    const _height = height ? height - 78 : 400;

    const content = (
      <Panel
        className='file-content-panel sierra-panel'
        id='file-content-view'
        position='panel-center-left'
        title={this.getTitle(title)}
        zIndex={2000}
        onCancel={closeModal}
        width={this.width}
        draggable
        resize={this.changePanelSize}
        minHeight={200}
        minWidth={200}
      >
        <div className='file-content-display'>
          <Spin spinning={fileLoding} tip="Loading...">
            {
              isEdit ?
                <>
                  <TextArea
                    value={editFileContent}
                    onChange={this.onChangeEditFileContent}
                    className='file-textarea'
                    style={{ margin: 0, overflow: 'auto', height: _height, width: width }}
                  />
                  <div className='edit-button-group'>
                    <Button type="primary" onClick={() => this.clickSave()}>Save</Button>
                    {
                      dataType !== LIBRARY && dataType !== LIBRARY_FOLDER ? <Button type="primary" onClick={() => this.clickSaveAs()}>Save As</Button> : null
                    }
                    <Button onClick={() => this.clickCancel()}>Cancel</Button>
                  </div>
                </>
                :
                <pre style={{ margin: 0, overflow: 'auto', height: _height, width: width }} className='file-content'>
                  {fileContent}
                </pre>
            }
          </Spin>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default FileContent;