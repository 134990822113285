import React, { Fragment } from 'react';
import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileSearchOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  PlusSquareOutlined,
  PushpinFilled,
  PushpinOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Tooltip, Tag, Popover } from 'antd';
import {
  LIBRARY,
  PROJECTS,
  ADD,
  DELETE,
  PCBS,
  UPLOAD_PCB,
  PCB,
  MY_LIBRARY,
  PROJECT,
  RENAME,
  COPY,
  DOWNLOAD,
  DCR,
  IMPEDANCE,
  LIBRARY_OPEN,
  DEFAULT,
  IR_EXPLORER,
  DEVICE,
  SYS_LIBRARY,
  SYS_LIBRARY_FILE,
  SYS_LIBRARY_FOLDER,
  CUSTOM_LIBRARY_FOLDER,
  CUSTOM_LIBRARY_FILE,
  CUSTOM_LIBRARY_DATA,
  VERIFICATION_CREATE,
  PACKAGES,
  PACKAGE,
  TRASH,
  CLEAN,
  PCB_SETTING,
  POWER_TREE,
  UPLOAD,
  SIGN_OFF_TEMPLATE,
  LIBRARY_DATA,
  LIBRARY_FILE,
  SIGN_OFF_TEMPLATES,
  STATUS,
  TREE_SIGN_OFF_TEMPLATE,
  DESIGN_TREE,
  IMPORTEXPORT,
  SINGLE_TREE,
  LAYOUT_CHECKOUT,
  PCB_PRE_LAYOUT,
  TAG,
  AC,
  DC,
  MORE,
  FILE_SEARCH,
  SEARCH
} from '../../../constants/treeConstants';
import { VERIFY_RUNNING, WAITING, VERIFY_SUCCESS } from '@/constants/verificationStatus';
import cascadeProjects from '../DB/cascadeProject';
import CreateItem from '@/components/CreateItem';
import { PROJECT_CREATE } from '../../../pages/PDN/constants';
import UploadPCB from '@/pages/Cascade/CascadeSider/uploadPCB';
import { DESIGN_FAILED, DESIGN_SUCCESS } from '../../../constants/designCategory'
import { SIGN_OFF_RUNNING, SIGN_OFF_UPDATE } from '../../../constants/signOffTemplateStatus';
import { CUSTOM, CUSTOM_SPICE, CUSTOM_TOUCHSTONE, DECAP_RLC, DECAP_TOUCHSTONE, DECAP_SPICE, DECAP, PIN_MAP, HFSS_OPTIONS, SIWAVE_OPTIONS, EXTRACTION_OPTIONS, PMIC_PART, POWER_SWITCH_PART, POWERSI_OPTIONS, POWERDC_OPTIONS, CPM_SPICE } from '../../../constants/libraryConstants';
import IconCollection from '../../../components/TreeIconCollection';
import { PDF, EXCEL } from '../../../constants/fileType';

const DCR_GROUP = `${DCR}_group`, IR_GROUP = `${IR_EXPLORER}_group`, IMPEDANCE_GROUP = `${IMPEDANCE}_group`,
  POWER_TREE_GROUP = `${POWER_TREE}_group`, DESIGN_TREE_GROUP = `${DESIGN_TREE}_group`, SINGLE_TREE_GROUP = `${SINGLE_TREE}_group`,
  DCR_RENAME = `${DCR}_rename`, IR_RENAME = `${IR_EXPLORER}_rename`, IMPEDANCE_RENAME = `${IMPEDANCE}_rename`, PROJECT_RENAME = `${PROJECT}_rename`,
  POWER_TREE_RENAME = `${POWER_TREE}_rename`, SINGLE_TREE_RENAME = `${SINGLE_TREE}_rename`, SIGN_OFF_TEMPLATE_RENAME = "sign_off_template_rename",
  PRE_LAYOUT_RENAME = `${PCB_PRE_LAYOUT}_rename`;
const libraryList = [LIBRARY_DATA, DECAP_RLC, DECAP_TOUCHSTONE, LIBRARY_FILE, CUSTOM_SPICE, CUSTOM_TOUCHSTONE, HFSS_OPTIONS, SIWAVE_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS]
const IconKey = {
  1: [ADD],
  2: [DELETE, RENAME],
  3: [UPLOAD_PCB],
  4: [DELETE, DOWNLOAD],
  5: [DELETE],
  6: [DELETE, DEFAULT],
  7: [CLEAN],
  8: [DELETE, PCB_SETTING, LAYOUT_CHECKOUT],
  9: [DELETE],
  10: [DEFAULT],
  11: [DELETE, STATUS, RENAME],
  12: [STATUS],
  13: [FILE_SEARCH],
  14: [IMPORTEXPORT],
  15: [IMPORTEXPORT, ADD],
  16: [MORE, DOWNLOAD],
  17: [DELETE, STATUS, COPY, RENAME],
  18: [DELETE],
  19: [DELETE, RENAME, TAG],
  20: [SEARCH],
  0: []
}

function getIconKey(item) {
  switch (item.dataType) {
    case PROJECTS:
      return 15;
    case MY_LIBRARY:
      return 14;
    case LIBRARY:
    case DCR_GROUP:
    case IR_GROUP:
    case IMPEDANCE_GROUP:
    case POWER_TREE_GROUP:
    case SINGLE_TREE_GROUP:
      return item.key === PIN_MAP ? 0 : 1;
    case SIGN_OFF_TEMPLATES:
      return 16;
    case DCR:
    case IR_EXPLORER:
    case IMPEDANCE:
    case POWER_TREE:
    case SINGLE_TREE:
      return 17;
    case PROJECT:
      return 2;
    case PCBS:
    case PACKAGES:
      return 3;
    case PCB:
    case PACKAGE:
      return 8;
    case HFSS_OPTIONS:
    case SIWAVE_OPTIONS:
    case POWERSI_OPTIONS:
    case POWERDC_OPTIONS:
      return 4;
    case DECAP_RLC:
      return 9;
    case LIBRARY_FILE:
    case DECAP_TOUCHSTONE:
    case LIBRARY_DATA:
      return 5;
    case DECAP_SPICE:
      return 6;
    case TRASH:
      return 7;
    case SYS_LIBRARY_FILE:
    case CUSTOM_LIBRARY_FILE:
      return item.parentId && item.parentId === "0" ? 10 : 0;
    case SYS_LIBRARY_FOLDER:
      return item.parentId && item.parentId === "-1" ? 10 : 0;
    case SIGN_OFF_TEMPLATE:
      return 11;
    case DESIGN_TREE:
      return 12;
    case CUSTOM_LIBRARY_FOLDER:
      return 13;
    case CUSTOM_TOUCHSTONE:
    case CUSTOM_SPICE:
    case CPM_SPICE:
      return 18;
    case PCB_PRE_LAYOUT:
      return 19;
    case PMIC_PART:
    case POWER_SWITCH_PART:
      return 20;
    case SYS_LIBRARY:
    case CUSTOM_LIBRARY_DATA:
    case DESIGN_TREE_GROUP:
    default: return 0
  }
}

function getType(dataType) {
  switch (dataType) {
    case PCB:
      return PCB;
    case PACKAGE:
      return PACKAGE;
    case PROJECT:
      return PROJECT;
    case DCR:
      return DCR;
    case IR_EXPLORER:
      return IR_EXPLORER;
    case IMPEDANCE:
      return IMPEDANCE;
    case POWER_TREE:
      return POWER_TREE;
    case SINGLE_TREE:
      return SINGLE_TREE;
    case DESIGN_TREE:
      return DESIGN_TREE;
    case LIBRARY_DATA:
      return LIBRARY_DATA;
    case DECAP_TOUCHSTONE:
    case DECAP_RLC:
    case DECAP_SPICE:
      return DECAP;
    case SIGN_OFF_TEMPLATE:
      return SIGN_OFF_TEMPLATE
    case PCB_PRE_LAYOUT:
      return PCB_PRE_LAYOUT;
    case CUSTOM_TOUCHSTONE:
    case CUSTOM_SPICE:
      return CUSTOM
    case HFSS_OPTIONS:
    case SIWAVE_OPTIONS:
    case POWERDC_OPTIONS:
    case POWERSI_OPTIONS:
      return EXTRACTION_OPTIONS
    case CPM_SPICE:
      return CPM_SPICE
    default: return false;
  }
}

function renderTitle(item, actions, props) {
  const { projectCreate, verificationCreate, verificationRename, projectRename, preLayoutRename } = actions
  switch (item.dataType) {
    case SYS_LIBRARY:
    case LIBRARY:
    case MY_LIBRARY:
    case PROJECTS:
    case PCBS:
    case IR_EXPLORER:
    case IMPEDANCE:
    case POWER_TREE:
    case DESIGN_TREE:
    case SINGLE_TREE:
    case DCR:
    case DEVICE:
    case LIBRARY_OPEN:
    case DCR_GROUP:
    case IR_GROUP:
    case IMPEDANCE_GROUP:
    case POWER_TREE_GROUP:
    case DESIGN_TREE_GROUP:
    case SINGLE_TREE_GROUP:
    case PACKAGES:
    case TRASH:
    case SIGN_OFF_TEMPLATES:
    case SIGN_OFF_TEMPLATE:
    case TREE_SIGN_OFF_TEMPLATE:
    case PMIC_PART:
    case POWER_SWITCH_PART:
      return outTitle({ item, actions, props });
    case PROJECT:
      return projectTitle({ item, actions, props });
    case PROJECT_CREATE:
      return projectCreate(item);
    case VERIFICATION_CREATE:
      return verificationCreate(item)
    case LIBRARY_DATA:
    case DECAP_RLC:
    case DECAP_TOUCHSTONE:
    case DECAP_SPICE:
    case LIBRARY_FILE:
    case CUSTOM_SPICE:
    case CUSTOM_TOUCHSTONE:
    case HFSS_OPTIONS:
    case SIWAVE_OPTIONS:
    case CPM_SPICE:
    case POWERSI_OPTIONS:
    case POWERDC_OPTIONS:
      return libraryTitle({ item, actions, props, className: "cascade-library-title" });
    case SYS_LIBRARY_FILE:
    case CUSTOM_LIBRARY_FILE:
    case CUSTOM_LIBRARY_DATA:
      return libraryTitle({ item, actions, props, });
    case DCR_RENAME:
    case IMPEDANCE_RENAME:
    case IR_RENAME:
    case POWER_TREE_RENAME:
    case SINGLE_TREE_RENAME:
    case SIGN_OFF_TEMPLATE_RENAME:
      return verificationRename(item);
    case PROJECT_RENAME:
      return projectRename(item);
    case PRE_LAYOUT_RENAME:
      return preLayoutRename(item);
    case CUSTOM_LIBRARY_FOLDER:
      return item.parentId === "-1" ?
        outTitle({ item, actions, props })
        : defaultTitle({ item, actions, props });
    case SYS_LIBRARY_FOLDER:
    default:
      return defaultTitle({ item, actions, props });
  }
}

function getIcon({ key, item, actions, props, index }) {
  const { delItem, _addItem, _deleteClick, renameStatusChange, updateSettingPCB, uploadClick, searchClick, importExportFolder, copyContent, inputClick, taskCopyVisibleChange, taskCopyId, uploadTemplate, searchAction } = actions;
  const { iconDisabled = true, defaultDecap, simulationDesigns, copyLoadingList } = props;
  let isDefaultLibrary = false;
  if (defaultDecap && item.id === defaultDecap.libraryId) {
    isDefaultLibrary = true;
  }
  const hasCopy = [DESIGN_TREE, POWER_TREE, DCR, IMPEDANCE, IR_EXPLORER, SINGLE_TREE].includes(item.dataType);
  switch (key) {
    case ADD:
      let title = iconDisabled ? '' : 'Please add PCB first.';
      return (
        <PlusSquareOutlined
          className={`aurora-tree-add-icon ${iconDisabled ? '' : 'aurora-disabled-color aurora-cursor-disabled'} `}
          id={`cascade-add-${item.key}-icon`}
          key={`${item.key}-add`}
          title={title}
          onClick={(e) => iconDisabled ? _addItem(e, item) : null} />
      );
    case DELETE:
      const type = getType(item.dataType);
      if (libraryList.includes(item.dataType)) {
        return item.deletable ? <CloseOutlined
          className="aurora-tree-del-icon"
          key={` ${item.key}-delete`}
          onClick={(e) => type ? _deleteClick(e, item) : delItem(e, item)} /> : null;
      }
      return (
        <CloseOutlined
          className="aurora-tree-del-icon"
          key={` ${item.key}-delete`}
          onClick={(e) => type ? _deleteClick(e, item) : delItem(e, item)} />
      );
    case UPLOAD_PCB:
      return <UploadPCB key={item.key} data={item} type={key} />
    case DOWNLOAD:
      return downloadRender({ key, item, props, actions });
    // IMPORTEXPORT icon
    case IMPORTEXPORT:
      return importOrExportRender({ key, item, props, actions })
    case DEFAULT:
      return isDefaultLibrary ?
        <Tooltip key={key} title='Cancel default decap library' classNames={{ root: 'aurora-tooltip' }}>
          <PushpinFilled
            className={`aurora-tree-set-icon cascade-tree-icon-location-${index}`}
            onClick={(e) => actions.changeDecap(e, {}, props)} />
        </Tooltip>
        :
        <Tooltip key={key} title='Set as default decap library' classNames={{ root: 'aurora-tooltip' }}>
          <PushpinOutlined
            className={`aurora-tree-set-icon cascade-tree-icon-location-${index}`}
            onClick={(e) => actions.changeDecap(e, item, props)} />
        </Tooltip>;
    case RENAME:
      return (
        <Tooltip key={key} title='Rename' classNames={{ root: 'aurora-tooltip' }}>
          <EditOutlined
            className={`aurora-tree-project-rename-icon ${hasCopy ? "cascade-rename-icon-hasCopy" : "cascade-rename-icon"}`}
            key={key}
            onClick={(e) => renameStatusChange(e, item, true)} />
        </Tooltip>
      );
    case COPY:
      return copyLoadingList.includes(item.id) ?
        <LoadingOutlined
          key='copy-loading'
          className='copy-loading-icon-visible'
          style={{ marginRight: hasStatusIcon(item.status) ? '30px' : '5px' }} />
        :
        <Popover
          key={key}
          content={copyContent(item)}
          trigger="click"
          onClick={inputClick}
          open={taskCopyId === item.id ? true : false}
          classNames={{ root: 'aurora-copy-popover' }}
        >
          <Tooltip key={key} title='Copy' classNames={{ root: 'aurora-tooltip' }}>
            <CopyOutlined
              title='Copy Current Task'
              className='aurora-tree-copy-icon cascade-copy-icon'
              key={key}
              onClick={(e) => taskCopyVisibleChange(e, item)} />
          </Tooltip>
        </Popover>;

    case CLEAN:
      return (
        <DeleteOutlined
          key={item.key}
          className='aurora-tree-del-icon'
          onClick={(e) => _deleteClick(e, item)} />
      );
    case PCB_SETTING:
      return item.category === DESIGN_SUCCESS ? <span
        className="iconfont icon-icons8-circuit aurora-tree-pcb-setting-icon cascade-pcb-setting-icon"
        key={item.key}
        title="Component Setting"
        onClick={(e) => updateSettingPCB(item, e)}
      /> : null;
    case UPLOAD:
      return (
        <UploadOutlined
          key={item.key}
          className='aurora-tree-upload-icon'
          title="Upload template xlsx"
          onClick={(e) => uploadClick(e, item)} />
      );
    case STATUS:
      return getStatus(item);
    case FILE_SEARCH:
      if (item.parentId === "-1") {
        return (
          <FileSearchOutlined
            key={item.key}
            className='aurora-tree-add-icon'
            title="Search library"
            onClick={(e) => searchClick(e, item)} />
        );
      }
      return;
    case LAYOUT_CHECKOUT:
      return simulationDesigns.includes(item.id) ?
        <Tooltip key={key} title='Checking Layout' classNames={{ root: 'aurora-tooltip' }}>
          <LoadingOutlined key={key} className={`aurora-tree-layout-check-loading-icon`} />
        </Tooltip >
        : null;
    case TAG:
      const isPreLayout = item.dataType === PCB_PRE_LAYOUT;
      if (!isPreLayout) {
        return;
      }
      const electric = item.content.electric || AC;
      return <Tag key={key} className="cascade-tree-tag" color={electric === AC ? 'magenta' : 'geekblue'}>{electric}</Tag>;
    case MORE:
      return <IconCollection
        title={"Upload template."}
        icon="add"
        menu={[{ title: 'Excel', func: uploadTemplate, params: { ...item, type: EXCEL } }, { title: 'Vendor PDF', func: uploadTemplate, params: { ...item, type: PDF } }]}
        placement="bottomLeft"
      />
    case SEARCH:
      return <SearchOutlined
        key={item.key}
        className='aurora-tree-add-icon'
        title="Search PMIC"
        onClick={(e) => searchAction(e, item)}
      />
    default: return;
  }
}

function hasStatusIcon(status) {
  switch (status) {
    case VERIFY_RUNNING:
    case WAITING:
    case VERIFY_SUCCESS:
      return true;
    default: return false;
  }
}

function getStatus(itemData = {}) {
  const hasCopy = [DESIGN_TREE, POWER_TREE, DCR, IMPEDANCE, IR_EXPLORER, SINGLE_TREE].includes(itemData.dataType);
  if (itemData.dataType === SIGN_OFF_TEMPLATE && [SIGN_OFF_RUNNING, SIGN_OFF_UPDATE].includes(itemData.status)) {
    return <Tooltip
      key={itemData.key}
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0}
      title={"The task created by the current template is running..."}
      classNames={{ root: 'aurora-tooltip' }}
    >
      <span
        key={itemData.key}
        onClick={(e) => { e && e.stopPropagation() }}
        className={`iconfont icon-zhengzaiyunhang aurora-status-icon cascade-sign-off-status-icon`}
        style={{ color: "#31aaf3" }} ></span>
    </Tooltip>
  } else if ([DESIGN_TREE, POWER_TREE, DCR, IMPEDANCE, IR_EXPLORER, SINGLE_TREE].includes(itemData.dataType)) {
    const statusItem = getExplorerStatus(itemData.status, itemData.key, hasCopy)
    return statusItem && <Tooltip
      key={itemData.key}
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0}
      title={statusItem.title}
      classNames={{ root: 'aurora-tooltip' }}
    >
      {statusItem.render}
    </Tooltip>
  }
  return null;
}

function getExplorerStatus(status, key, hasCopy) {
  switch (status) {
    case VERIFY_RUNNING:
    case WAITING:
      return {
        title: "Running...",
        render: <span
          key={key}
          onClick={(e) => { e && e.stopPropagation() }}
          className={`iconfont icon-zhengzaiyunhang aurora-status-icon ${hasCopy ? "cascade-sim-status-icon-hasCopy" : "cascade-sim-status-icon"}`}
          style={{ color: "#31aaf3" }} ></span>
      }
    case VERIFY_SUCCESS:
      return {
        title: "Results Available",
        render: <span key={key}
          onClick={(e) => { e && e.stopPropagation() }}
          className={`iconfont icon-icon_tab_already aurora-status-icon ${hasCopy ? "cascade-sim-status-icon-hasCopy" : "cascade-sim-status-icon"}`}
          style={{ color: "#31aaf3" }} ></span>
      }
    default: return null;
  }
}

function importOrExportRender({ key, item, props, actions }) {
  const { importExportFolder, showImportExportModel } = actions
  // uploading: {key: string,status: boolean}
  const { uploading } = props
  return uploading && uploading.key === item.key && uploading.status
    ?
    <Tooltip key={key} title='Files are being imported or exported' classNames={{ root: 'aurora-tooltip' }}>
      <Loading3QuartersOutlined
        spin
        onClick={(e) => { e && e.stopPropagation(); showImportExportModel(true) }}
        key={key}
        className={item.dataType === MY_LIBRARY ? `aurora-tree-import-export-loading-icon aurora-tree-import-export-library-loading` : `aurora-tree-import-export-loading-icon aurora-tree-import-export-project-loading`} />
    </Tooltip>
    :
    <span
      key={key}
      title='Import Or Export'
      onClick={(e) => importExportFolder(e, item)}
      className={item.dataType === MY_LIBRARY ? `iconfont icon-import-export aurora-tree-import-export-library-icon` : `iconfont icon-import-export aurora-tree-import-export-project-icon`}
      style={{ color: '#1890ff' }}
    ></span>;
}

function downloadRender({ key, item, actions }) {
  switch (item.dataType) {
    case SIGN_OFF_TEMPLATES:
      return signoffDownload({ key, item, actions });
    default:
      return downloadIcon({ key, item, actions })
  }
}

function signoffDownload({ key, item, actions }) {
  const { downloadSignoffTemplate } = actions;
  return (
    <Tooltip key={key} title='Download the document of Sign-off Template.' classNames={{ root: 'aurora-tooltip' }}>
      <DownloadOutlined
        className="cascade-sign-off-download-icon aurora-tree-download-icon"
        onClick={(e) => downloadSignoffTemplate(e, item)} />
    </Tooltip>
  );
}

function downloadIcon({ key, item, actions }) {
  const { downloadLibraryInfo, downloadFile } = actions;
  return (!downloadLibraryInfo || downloadLibraryInfo.id !== item.id) ?
    <Tooltip key={key} title='Download' classNames={{ root: 'aurora-tooltip' }}>
      <DownloadOutlined
        style={!item.deletable ? { marginRight: 0 } : { marginRight: 22 }}
        className="aurora-tree-download-icon"
        onClick={(e) => downloadFile(e, item)} />
    </Tooltip> : null;
}

function outTitle({ item, actions, props }) {
  const Keys = IconKey[getIconKey(item)];
  let className = "out-title-color"
  if ([DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE].includes(item.dataType)) {
    className = "cascade-tree-channel-name";
  }
  if (item.dataType === SIGN_OFF_TEMPLATE && [SIGN_OFF_RUNNING, SIGN_OFF_UPDATE].includes(item.status)) {
    className = className + " cascade-tree-sign-off-running-name"
  }

  if (item.dataType === SIGN_OFF_TEMPLATE) {
    className = className + " cascade-tree-sign-off-name"
  }

  if (item.dataType === TREE_SIGN_OFF_TEMPLATE) {
    className = "cascade-tree-sign-off-title-name"
  }
  return <span>
    <span className={`${className}`}>{item.name}</span>
    <Fragment>{Keys.map((key, index) => { return getIcon({ index, key, item, actions, props: { ...props, iconDisabled: item.iconDisabled } }) })}</Fragment>
  </span>
}

function projectTitle({ item, actions, props }) {
  const { renameProjectInfo, saveRename, cancelRename } = actions;
  if (renameProjectInfo && renameProjectInfo.id === item.id) {
    return <CreateItem
      itemData={item}
      createNodeId="cascade-check-project-icon"
      cancelNodeId="cascade-close-project-icon"
      createConfirm={(data) => saveRename(data, "Channel")}
      projectList={cascadeProjects.getProjectValues().filter(it => it.id !== item.id)}
      cancelCreate={() => cancelRename("Project")}
    />
  }
  return defaultTitle({ item, actions, props });
}

function libraryTitle({ item, actions, props, className = "" }) {
  const Keys = IconKey[getIconKey(item)];
  const { downloadLibraryInfo, downloadProgress } = actions;
  const customModelTag = [CUSTOM_SPICE, CUSTOM_TOUCHSTONE].includes(item.dataType) && item.tag;
  const decapClass = item.id === props.defaultDecap.libraryId || customModelTag ? "cascade-library-default-decap-title" : ""
  return <span
    className={downloadLibraryInfo && downloadLibraryInfo.id === item.id ?
      (downloadProgress === 100 ? 'download-progress-bar download-progress-bar-success' : 'download-progress-bar')
      : ""}
    style={{ "--percent": downloadProgress }}>
    <div
      className={item.id === props.defaultDecap.libraryId || customModelTag ? "cascade-library-default-decap-title-main" : ""}
    >
      <Tooltip
        key={item.key}
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={item.name}
        className={`aurora-icon-color aurora-tooltip-span ${className} ${decapClass}`}
        classNames={{ root: 'aurora-tooltip' }}
        onClick={(e) => actions.editItem(e, item)}
      >{item.name}
      </Tooltip>
      {customModelTag ? <Tag color="default" className="cascade-custom-tag">{item.tag}</Tag> : null}
      {item.id === props.defaultDecap.libraryId ?
        <Tag color="#fff" className="cascade-decap-default">Default</Tag> : null}
      <Fragment>{Keys.map((key, index) => { return getIcon({ index, key, item, actions, props }) })}</Fragment>
    </div>
  </span>
}

function defaultTitle({ item, actions, props }) {
  const Keys = IconKey[getIconKey(item)];
  const failedClassName = item.category === DESIGN_FAILED ? "cascade-tooltip-failed" : "";
  const type = item.dataType === PACKAGE ? "Package" : "PCB"
  const decapClass = item.id === props.defaultDecap.libraryId ? "cascade-library-default-decap-title" : ""
  const pcbClassName = [PACKAGE, PCB].includes(item.dataType) ? "cascade-tree-pcb-name" : ""
  return (
    <span className={item.id === props.defaultDecap.libraryId ? "cascade-library-default-decap-title-main" : ""}>
      <Tooltip
        key={item.key}
        placement="topLeft"
        mouseEnterDelay={0.8}
        mouseLeaveDelay={0}
        title={item.name}
        className={`cascade-default-tree-title ${failedClassName} cascade-tooltip-span ${pcbClassName} ${decapClass}`}
        classNames={{ root: 'aurora-tooltip' }}
      >{item.name}</Tooltip>
      {item.category === DESIGN_FAILED &&
        <Tooltip title={`${type} upload failed`} classNames={{ root: 'aurora-tooltip aurora-design-tooltip' }}>
          <ExclamationCircleOutlined className="debug-design-icon" />
        </Tooltip>
      }
      {item.id === props.defaultDecap.libraryId ?
        <Tag color="#fff" className="cascade-decap-default">Default</Tag> : null}
      <Fragment>{Keys.map((key, index) => { return getIcon({ index, key, item, actions, props }) })}</Fragment>
    </span>
  );
}

export {
  renderTitle,
  outTitle,
  projectTitle,
  defaultTitle,
  getType,
  libraryTitle
}