import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip, Row, Col } from 'antd';
import Panel from '@/components/Panel';
import { closePanel } from '../store/actionCreators';
import LayoutData from '@/services/CeLayoutDB/newCeLayoutData';
import { SIERRA, FASTPI } from '@/constants/pageType';
import './index.css';

let COMPONENTS = [], NETS = [];

const compColumns = [{
  title: 'Comp',
  dataIndex: 'name',
  width: '25%',
  sorter: (a, b) => a.name.localeCompare(b.name),
}, {
  title: 'Part',
  dataIndex: 'part',
  render: (text) => (
    <Tooltip placement="topLeft" title={text}>
      <span className="component-part">{text}</span>
    </Tooltip>
  ),
  width: '50%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Pin#',
  dataIndex: 'pin',
  sorter: (a, b) => a.pin - b.pin,
  width: '25%',
}];

const netColumns = [{
  title: 'Net',
  dataIndex: 'name',
  render: (text) => (
    <Tooltip placement="topLeft" title={text}>
      < span className="net-name"> {text}</span>
    </Tooltip>
  ),
  width: 170,
  fixed: 'left',
  // fixed: 'left'
  sorter: (a, b) => a.name.localeCompare(b.name),
}, {
  title: 'Comp#',
  dataIndex: 'comp',
  width: 70,
  sorter: (a, b) => {
    return a.comp - b.comp;
  },
}, {
  title: 'Pin#',
  dataIndex: 'pin',
  width: 70,
  sorter: (a, b) => {
    return a.pin - b.pin;
  },
}, {
  title: 'Via#',
  dataIndex: 'via',
  width: 70,
  sorter: (a, b) => {
    return a.via - b.via;
  },
}, {
  title: 'Layer#',
  dataIndex: 'layer',
  sorter: (a, b) => {
    return a.layer - b.layer;
  },
}];

class DesignInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      metalLayers: 0,
      compNum: '',
      netNum: '',
      loading: true,
      table: 'comp',
    }

    this.setComponent = this.setComponent.bind(this);
    this.setNet = this.setNet.bind(this);
  }

  componentDidMount() {
    const metalLayers = LayoutData.getLayout().GetLayerManager().mMetalLayers;
    this.setState({
      metalLayers: metalLayers.length,
    });
    setTimeout(() => {
      COMPONENTS = LayoutData.getComponentsList();
      NETS = LayoutData.getNetsList();
      this.setState({
        loading: false,
        compNum: COMPONENTS.length,
        netNum: NETS.length,
      })
    }, 10)
  }

  setComponent(e) {
    e.stopPropagation();
    e.preventDefault();
    const { table } = this.state;
    if ('comp' === table) return;
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.setState({
        table: 'comp',
        loading: false
      });
    }, 10)
  }

  setNet(e) {
    e.stopPropagation();
    e.preventDefault();
    const { table } = this.state;
    if ('net' === table) return;
    this.setState({
      loading: true
    })
    setTimeout(() => {
      this.setState({
        table: 'net',
        loading: false
      });
    }, 10)
  }

  render() {
    const { defaultLeft, defaultTop } = this.props;
    const { metalLayers, compNum, netNum, loading, table } = this.state;
    const scroll = { y: 396 };
    if (table === 'net') {
      scroll.x = 480;
    }
    return (
      <Panel
        title='General Info'
        visible
        onCancel={this.props.closePanel}
        width='320px'
        position='panel-top-left'
        className='menu-panel'
        draggable
        left={defaultLeft}
        top={defaultTop}
        minHeight={250}
      >
        <Row className='detail-info'>
          <Col span={16}>Metal Layers:</Col>
          <Col span={8}>{metalLayers}</Col>
          <Col span={16}># of <a onClick={this.setComponent}>Components:</a></Col>
          <Col span={8}>{compNum}</Col>
          <Col span={16}># of <a onClick={this.setNet}>Nets:</a></Col>
          <Col span={8}>{netNum}</Col>
        </Row>
        <div>
          <Table
            rowKey='name'
            loading={loading}
            columns={table === 'comp' && compColumns || netColumns}
            pagination={false}
            scroll={scroll}
            size="small"
            dataSource={table === 'comp' && COMPONENTS || NETS}
            bordered
          />
        </div>
      </Panel>
    )
  }
}

const mapState = (state) => {
  const LoginReducer = state.LoginReducer;
  if (LoginReducer && LoginReducer.pageType === FASTPI) {
    const { defaultLeft, defaultTop } = state.PanelStatus;
    return { defaultLeft, defaultTop }
  } else if (LoginReducer && LoginReducer.pageType === SIERRA) {
    return {
      defaultLeft: "",
      defaultTop: ""
    }
  } else {
    const { defaultLeft, defaultTop } = state.PanelStatus;
    return { defaultLeft, defaultTop }
  }
}


const mapDispatch = (dispatch) => ({
  closePanel() {
    dispatch(closePanel())
  },
})

export default connect(mapState, mapDispatch)(DesignInfo);