import {
  ADD_EXPERIMENT,
  UPDATE_EXPERIMENT_INFO,
  UPDATE_SWEEP_CREATE_STATUS,
  UPDATE_SINGLE_EXPERIMENT,
  CREATE_EXPS_BY_MODEL,
  ADD_EXPERIMENTS_BY_PASSIVE,
  UPDATE_EXPERIMENT_COMP,
  REDUCE_SWEEP_TABLE_COLUMN,
  UPDATE_EXPERIMENTS_STATUS,
  CREATE_EXPS_BY_TRACE,
  UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO,
  GET_ALL_EXP_MEASUREMENT_CONFIG,
  UPDATE_SWEEP_MEASUREMENT_DISPLAY_INFO
} from "./actionTypes";

export const addExperiment = () => ({
  type: ADD_EXPERIMENT
})

export const updateExperimentInfo = (info, currentType) => ({
  type: UPDATE_EXPERIMENT_INFO,
  info,
  currentType
});

export const updateSweepCreateStatus = (status) => ({
  type: UPDATE_SWEEP_CREATE_STATUS,
  status
});

export const updateSingleExperiment = ({ currentType, params }) => ({
  type: UPDATE_SINGLE_EXPERIMENT,
  currentType,
  params
});

export const createExpsByModel = (modelsList, interfaceIndex) => ({
  type: CREATE_EXPS_BY_MODEL,
  modelsList,
  interfaceIndex
});

export const addExperimentsByPassive = (R_values, record) => ({
  type: ADD_EXPERIMENTS_BY_PASSIVE,
  R_values,
  record
});

export const updateExperimentComp = ({ base, experiments }) => ({
  type: UPDATE_EXPERIMENT_COMP,
  base,
  experiments
});

export const reduceSweepColumn = (key, parentIndex) => ({
  type: REDUCE_SWEEP_TABLE_COLUMN,
  key,
  parentIndex
});

export const updateExperimentStatus = ({ experimentIds, status }) => ({
  type: UPDATE_EXPERIMENTS_STATUS,
  experimentIds,
  status
})

export const createExpByTrace = (newExps) => ({
  type: CREATE_EXPS_BY_TRACE,
  newExps
})

export const updateSweepSchematicPreLayoutInfo = (schematicPreLayoutInfo) => ({
  type: UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO,
  schematicPreLayoutInfo
});

export const getAllExpMeasurementConfig = (info) => ({
  type: GET_ALL_EXP_MEASUREMENT_CONFIG,
  info
});

export const updateSweepMeasurementDisplayInfo = (sweepMeasurementInfo) => ({
  type: UPDATE_SWEEP_MEASUREMENT_DISPLAY_INFO,
  sweepMeasurementInfo
})