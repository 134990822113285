import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Select, InputNumber, message, Dropdown, Space } from 'antd';
import { UNIT } from '@/services/data/newStackupData';
import LayoutData from '@/services/data/LayoutData';
import { downloadFile } from '@/services/helper/downloadHelper'
import { stackupErrorCheck } from '@/services/Stackup';
import { parseStackupFile } from '@/services/api/designFile';
import StackupJsonData from '@/services/data/stackupJsonData';
import { changeStackupTable, settingUnit, saveData, updateStackupColumnsStatus, updateStackupErrorMsg, removeStackupData } from './store/actionCreators';
import { getStackupCSVFile, getStackupFileByType } from '../../../../services/api/designFile';
import DelConfirm from "@/components/DelConfirm";
import { getThicknessScale, updateThicknessByUnit } from '../../../../services/Stackup';

const Option = Select.Option;
const SpaceCompact = Space.Compact;
const xmlProductList = ['sierra', 'andes', 'rocky', 'cascade']

class StackupFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settingButon: 'block',
      settingInput: 'none',
      type: null,
      range: null,
      thickness: null
    }
    this.stackupRef = createRef();
  }

  showMetalSetting = (type, range) => {
    this.setState({
      type: type,
      range: range,
      settingButon: 'none',
      settingInput: 'block'
    })
  }

  showAllDielectric(type) {
    this.setState({
      type: type,
      settingButon: 'none',
      settingInput: 'block'
    })
  }

  cancelClick = () => {
    this.setState({
      settingButon: 'block',
      settingInput: 'none',
      thickness: null,
      type: null,
      range: null,
      etchFactor: null
    })
  }

  settingUnitChange = (value) => {
    const { data, unit, designID } = this.props;
    this.props.settingUnit(value, designID);
    const scale = getThicknessScale(value, unit);
    let updateLayers = [...data];
    updateLayers.forEach(layer => {
      layer.thickness = updateThicknessByUnit(scale, layer.thickness)
    })
    this.props.changeTable(updateLayers, value, designID);
  }

  selectValueChange = (value, type) => {
    this.setState({
      [type]: value
    })
  }

  setLayersProperty = () => {
    const { data, unit, designID } = this.props;
    /* let newData = [].concat(data); */
    let newData = [];//Deep copy
    for (let i = 0; i < data.length; i++) {
      let item = { ...data[i] };
      newData.push(item);
    }

    let { thickness, range, type, etchFactor } = this.state;
    //error check
    // let error = stackupItemErrorCheck(THICKNESS, thickness);

    // if (error) {
    //   this.props._updateStackupErrorMsg(error);
    //   this.props.updateErrorDisplay("upload");
    //   this.cancelClick();
    //   return;
    // }

    let startIndex = 0, lastIndex = newData.length - 1;
    startIndex = newData.findIndex(item => item.type === type);
    let typeData = newData.map(item => item.type);
    lastIndex = typeData.lastIndexOf(type);
    for (let i = 0; i < newData.length; i++) {
      if ((range === 'Outer' && i !== startIndex && i !== lastIndex) ||
        (range === 'Inner' && (i === startIndex || i === lastIndex))) {
        continue;
      }
      let item = newData[i];
      if (item.type === type) {
        if (thickness) {
          item.thickness = thickness;
        }
        if (etchFactor) {
          item.etch_factor = etchFactor;
        }
      }
    };
    this.props.changeTable(newData, unit, designID);
    this.cancelClick();
  }

  updateError = (error) => {
    const { designID } = this.props;
    this.props._updateStackupErrorMsg({
      error,
      type: "upload"
    }, designID);
    this.props.updateErrorDisplay("upload");
  }

  uploadStackup = (e) => {
    const el = this.stackupRef.current;
    if (!el) return;
    el.click();
  }

  onStackupChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const regexpRule = xmlProductList.includes(this.props.product) ? /\.csv|\.dat|\.json|\.xml$/i : /\.csv|\.dat|\.json$/i;
      const regexpMatchName = file.name.match(regexpRule);
      let extensionName = regexpMatchName ? regexpMatchName[0] : null;

      if (extensionName === null) {
        const errorMsg = xmlProductList.includes(this.props.product) ? "Unrecognized file type. We only support .json .csv .xml and .dat files. Upload stackup file failed." : "Unrecognized file type. We only support .json .csv and .dat files. Upload stackup file failed.";
        this.updateError(errorMsg);
      } else {
        this.uploadFile = file;
        if (file.name.match(/\.csv|\.dat|\.xml$/i)) {
          this.setState({
            uploadFileConfirm: true
          })
        } else {
          this.uploadFileFn();
        }
      }
    }

    // Fix the same path file can not be repeated upload problem : empty file value
    this.stackupRef.current.value = '';
  }

  uploadFileFn = () => {
    if (!this.uploadFile) {
      return;
    }
    parseStackupFile({ file: this.uploadFile, fileName: this.uploadFile.name }).then(res => {
      if (!res) {
        this.updateError("Unrecognized file. Upload stackup file failed.");
      } else {
        const stackupData = new StackupJsonData();
        stackupData.initStackup(res, this.props.designID);
        const unit = stackupData.getUnitString(true);
        this.InitializeData(stackupData, unit, true);
      }
    }, error => {
      const message = error ? error : "Unrecognized file";
      this.updateError(`${message}. Upload stackup file failed.`);
    });
  }

  InitializeData = (stackupData, unit, clear) => {
    const { designID, pageType } = this.props;
    const tableData = stackupData.getStackupTableData();

    const designInfo = LayoutData.getLayout(designID)
    const layoutList = designInfo && designInfo.mLayerMgr && designInfo.mLayerMgr.mMetalLayers ? designInfo.mLayerMgr.mMetalLayers : [];
    const _prevLayers = layoutList.map(item => { return { name: item.mName, type: "Metal" } })
    //error check
    const error = stackupErrorCheck({
      ...tableData,
      layers: tableData.tableData,
      materialList: tableData.materialList,
      unit,
      prevLayers: _prevLayers,
      isUpload: true,
      pageType
    });

    if (error && error.length) {
      this.props._updateStackupErrorMsg({
        error: `${error[0].error} Upload stackup failed.`,
        type: error[0].dataType
      }, designID);
      this.props.updateErrorDisplay("upload");
      return;
    }
    message.success("Upload stackup file succeeded.");

    if (clear) {
      this.props.removeStackupData(designID);
    }

    this.props.setStackupData({
      data: tableData.tableData,
      materialList: tableData.materialList,
      stackups: tableData.stackups,
      zones: tableData.zones,
      bends: tableData.bends,
      unit, designID
    });
    this.props.updateStackupColumnsStatus(true, designID);
    //show zoom
    this.props._updateShowZonesStatus && this.props._updateShowZonesStatus(tableData.zones ? true : false, designID);
    //save to server
    const _stackupData = LayoutData.saveStackupJson(designID, {
      data: tableData.tableData,
      materialList: tableData.materialList,
      stackups: tableData.stackups,
      zones: tableData.zones,
      bends: tableData.bends,
      unit
    });
    this.props._saveStackupToServer(_stackupData, designID);
  }

  downloadStackup = (format) => {
    let filename;
    let stackupData = null;
    const { data, materialList, unit, designID, stackups, zones, bends } = this.props;
    stackupData = LayoutData.saveStackupJson(designID, { data, materialList, stackups, zones, unit, bends });
    if (format === "json") {
      let _stackupData = JSON.parse(JSON.stringify(stackupData));
      //if not multi-zone ,remove "laminated" field in layers
      if (!_stackupData.zones && _stackupData.layers) {
        _stackupData.layers.forEach(item => { delete item.laminated });
      }
      _stackupData = JSON.stringify(_stackupData, null, 2);
      if (_stackupData === null) {
        this.updateError(`Download stackup.json failed.`);
        return;
      }

      filename = 'stackup.json';
      let type = 'data:text/json;charset=utf-8;';
      downloadFile(_stackupData, filename, type);

    } else if (format === "csv") {
      getStackupCSVFile(stackupData).then(fileContent => {
        if (!fileContent) {
          this.updateError(`Download stackup.csv failed.`);
          return;
        }

        filename = 'stackup.csv';
        let type = 'text/csv;charset=utf-8;';
        downloadFile(fileContent, filename, type);
      }, error => {
        const message = error ? `${error}. Download stackup.csv failed.` : `Download stackup.csv failed.`;
        this.updateError(message);
      })
    } else if (format === "xml") {
      getStackupFileByType({ stackupData, fileType: 'xml' }).then(fileContent => {
        if (!fileContent) {
          this.updateError(`Download stackup.xml failed.`);
          return;
        }

        filename = 'stackup.xml';
        let type = 'text/xml';
        downloadFile(fileContent, filename, type);
      })
    }
  }

  cancelUpload = () => {
    this.setState({
      uploadFileConfirm: false
    })
  }

  uploadConfirm = () => {
    this.cancelUpload();
    this.uploadFileFn();
  }

  render() {
    const { settingButon, settingInput, uploadFileConfirm, type } = this.state;
    const { unit, zIndex } = this.props;
    const menu = [
      { key: 'Upload Stackup', label: 'Upload Stackup', onClick: this.uploadStackup },
      {
        key: 'Download Stackup', label: 'Download Stackup', popupClassName: 'stackup-subMenu', children: xmlProductList.includes(this.props.product) ? [
          { key: 'CSV', label: 'CSV', onClick: () => this.downloadStackup("csv") }, { key: 'JSON', label: 'JSON', onClick: () => this.downloadStackup("json") }, { key: 'xml', label: 'XML', onClick: () => this.downloadStackup("xml") }
        ] : [
          { key: 'CSV', label: 'CSV', onClick: () => this.downloadStackup("csv") }, { key: 'JSON', label: 'JSON', onClick: () => this.downloadStackup("json") }
        ]
      },
      { type: 'divider' },
      { key: 'All Metal Layers', label: 'All Metal Layers', onClick: () => this.showMetalSetting('Metal', 'All') },
      { key: 'Outer Metal Layers', label: 'Outer Metal Layers', onClick: () => this.showMetalSetting('Metal', 'Outer') },
      { key: 'Inner Metal Layers', label: 'Inner Metal Layers', onClick: () => this.showMetalSetting('Metal', 'Inner') },
      { type: 'divider' },
      { key: 'All Dielectric Layers', label: 'All Dielectric Layers', onClick: () => this.showAllDielectric('Dielectric') }
    ]
    const _accept = xmlProductList.includes(this.props.product) ? ".csv,.dat,.json,.xml" : ".csv,.dat,.json";
    return (
      <div className="stack-footer stackup-clear">
        <div className="stackup-footer-value-setting" style={{ display: settingButon }}>
          <Dropdown menu={{ items: menu }} trigger={['click']} placement="topRight">
            <Button
              type="primary"
              style={{ float: "right" }}
            >
              Menu
            </Button>
          </Dropdown>
          <input
            type='file'
            ref={this.stackupRef}
            style={{ display: 'none' }}
            accept={_accept}
            onChange={this.onStackupChange}
          />
        </div>
        <div className="stackup-input-row stackup-clear" style={{ display: settingInput }}>
          <div className="stackup-input-col stackup-clear">
            <span className="stackup-thickness-name">Thickness</span>
            <SpaceCompact className="stackup-input-group">
              <InputNumber
                min={0}
                onChange={(value) => this.selectValueChange(value, 'thickness')}
                value={this.state.thickness}
                width={100}
              />
              <Select
                defaultValue={unit}
                value={unit}
                onChange={this.settingUnitChange}
                style={{ width: '64px' }}
              >
                {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
              </Select>
            </SpaceCompact>
          </div>
          {type === 'Metal' ?
            <div className="stackup-input-col stackup-clear etch-factor-box">
              <span className="stackup-thickness-name">Etch Factor</span>
              <InputNumber
                min={0}
                onChange={(value) => this.selectValueChange(value, 'etchFactor')}
                value={this.state.etchFactor}
                width={100}
              />
            </div> : null}
          <div className="stackup-input-col stackup-clear">
            <Button type="primary" className="stackup-set-button" onClick={this.setLayersProperty}>Set</Button>
            <Button type="primary" onClick={this.cancelClick}>Cancel</Button>
          </div>
          {uploadFileConfirm ? <DelConfirm
            message={"The advanced material properties such as frequency dependency and surface roughness will be reset after uploading the csv/dat/xml file."}
            okTitle={"Upload"}
            cancelTitle={"Cancel"}
            width={374}
            cancelDel={(e) => this.cancelUpload(e)}
            deleteConfirm={(e) => this.uploadConfirm(e)}
          /> : null}
        </div>
      </div>
    )
  }
}

/* const mapState = (state) => {
  const { stackupObj } = state.StackupReducer;
  return {stackupObj }
} */

const mapDispatch = (dispatch) => ({
  changeTable(data, unit, designID) {
    dispatch(changeStackupTable(data, unit, designID));
  },
  settingUnit(unit, designID) {
    dispatch(settingUnit(unit, designID));
  },
  setStackupData({ data, materialList, stackups, bends, zones, unit, designID }) {
    dispatch(saveData({ data, materialList, stackups, bends, zones, unit, designID }));
  },
  updateStackupColumnsStatus(status, designID) {
    dispatch(updateStackupColumnsStatus(status, designID))
  },
  _updateStackupErrorMsg(errorObj, designID) {
    dispatch(updateStackupErrorMsg(errorObj, designID))
  },
  removeStackupData(designID) {
    dispatch(removeStackupData(designID))
  }
})

export default connect(null, mapDispatch)(StackupFooter);