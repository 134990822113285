import React, { Component } from 'react';
import { Tooltip } from 'antd';
import Table from "@/components/EditableTable";
import './index.css';
import { CMC, CMC_COMMENT, IGNORE } from '../../../../constants/componentType';

const columns = [{
  key: "part",
  dataIndex: "part",
  title: "Part Number",
  width: "28%"
},
{
  key: "component",
  dataIndex: "component",
  title: "Components",
  width: "18%"
},
{
  key: "type",
  dataIndex: "type",
  title: "Usage",
  width: "24%"
},
{
  key: "pin",
  dataIndex: "pin",
  title: "Input Pin",
  width: "15%"
}, {
  key: "pinMap",
  dataIndex: "pinMap",
  title: "Output Pin",
  width: "15%"
}]
class CMCComponentTable extends Component {

  constructor(props) {
    super(props);

    columns[0].render = (text, record) => {
      const obj = {
        children: <Tooltip
          overlayClassName="aurora-tooltip"
          title={text}
        >
          <span className='create-channel-component-part-span'>{text}</span>
        </Tooltip>,
        props: {},
      }
      if (record.display) {
        obj.props.rowSpan = 2;
      } else {
        obj.props.rowSpan = 0;
      }
      return obj;
    }

    columns[1].render = (text, record) => {
      const obj = {
        children: <span className="cmc-components-usage-columns">{record.comps ? record.comps.join(", ") : null}</span>,
        props: {},
      }
      if (record.display) {
        obj.props.rowSpan = 2;
      } else {
        obj.props.rowSpan = 0;
      }
      return obj;
    }


    columns[2].onCell = (record) => {
      return {
        record,
        edit: "select",
        options: [{ key: CMC, value: CMC_COMMENT }, { key: IGNORE, value: IGNORE }],
        dataIndex: "type",
        handleSave: (_record) => { this._editComponentType(_record) },
      }
    }

    columns[2].render = (value, record) => {
      const text = value === CMC ? CMC_COMMENT : value;
      const obj = {
        children: <Tooltip
          overlayClassName="aurora-tooltip"
          title={text}
        >
          <div className="cmc-components-usage-columns">{text}</div>
        </Tooltip>,
        props: {},
      }
      if (record.display) {
        obj.props.rowSpan = 2;
      } else {
        obj.props.rowSpan = 0;
      }
      return obj;
    }

    columns[3].render = (value, record) => {
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {record.pinInfo.mNumber}</div>
          <div>Pin Name: {record.pinInfo.mName}</div>
        </div>}
      >
        <div className="cmc-components-pins-columns">{record.pinInfo.mNumber}</div>
      </Tooltip>
    }

    columns[3].onCell = (record) => {
      const options = record.pins.map(item => item.mNumber);
      return {
        record,
        edit: "select",
        options: options,
        dataIndex: "pin",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "cmc-components-pins-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "pin") },
      }
    }

    columns[4].onCell = (record) => {
      const options = record.pins.map(item => item.mNumber);
      return {
        record,
        edit: "select",
        options: options,
        dataIndex: "pinMap",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "cmc-components-pins-columns-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editPinMap(_record, prevRecord, "pinMap") },
      }
    }

    columns[4].render = (value, record) => {
      return <Tooltip
        overlayClassName="aurora-tooltip"
        title={<div>
          <div>Pin Number: {record.pinMapInfo.mNumber}</div>
          <div>Pin Name: {record.pinMapInfo.mName}</div>
        </div>}
      >
        <div className="cmc-components-pins-columns">{record.pinMapInfo.mNumber}</div>
      </Tooltip>
    }
  }

  editPinMap = (record, prevRecord, type) => {
    const { cmcComponents } = this.props;
    let _cmcComponents = [...cmcComponents];
    const otherType = type === 'pin' ? "pinMap" : 'pin';
    for (let comp of _cmcComponents) {
      if (comp.part !== record.part) {
        continue;
      }
      const prevPin = prevRecord[type];
      const prevIndex = comp.pinsConnection.findIndex(item => item[type] === prevRecord[type]);
      const newPin = record[type];
      const newIndex = comp.pinsConnection.findIndex(item => item[otherType] === newPin || item[type] === newPin);
      if (comp.pinsConnection[newIndex][type] === newPin) {
        comp.pinsConnection[newIndex][type] = prevPin;
      } else {
        comp.pinsConnection[newIndex][otherType] = prevPin;
      }
      comp.pinsConnection[prevIndex][type] = newPin;
    }
    this.props._updateCMCComponents(_cmcComponents);
  }

  _editComponentType = (record) => {
    const { cmcComponents } = this.props;
    let _cmcComponents = [...cmcComponents];
    for (let comp of _cmcComponents) {
      if (comp.part !== record.part) {
        continue;
      }
      comp.type = record.type;
    }
    this.props._updateCMCComponents(_cmcComponents);
  }

  getTablePins = () => {
    const { cmcComponents } = this.props;
    let partList = [], compList = [];
    for (let comp of cmcComponents) {
      const { part, mCompName, pins, type, pinsConnection } = comp;
      if (pinsConnection.length !== 2) {
        continue;
      }
      //according to type and part separate comp.
      if (partList.includes(part)) {
        const _findIndex = compList.findIndex(item => item.part === part && !!item.display);
        if (_findIndex > -1) {
          compList[_findIndex].comps.push(mCompName);
        }
      } else {
        partList.push(part);
        compList.push({
          comps: [mCompName],
          part,
          type,
          pinsConnection,
          display: true,
          pins,
          pinInfo: pins.find(item => item.mNumber === pinsConnection[0].pin) || {},
          pin: pinsConnection[0].pin,
          pinMapInfo: pins.find(item => item.mNumber === pinsConnection[0].pinMap) || {},
          pinMap: pinsConnection[0].pinMap
        },
          {
            comps: [mCompName],
            part,
            pins,
            type,
            pinInfo: pins.find(item => item.mNumber === pinsConnection[1].pin) || {},
            pin: pinsConnection[1].pin,
            pinMapInfo: pins.find(item => item.mNumber === pinsConnection[1].pinMap) || {},
            pinMap: pinsConnection[1].pinMap,
            display: false
          })
      }
    }
    return compList;

  }

  render = () => {
    const { cmcComponents } = this.props;
    const dataList = this.getTablePins(cmcComponents);
    return <div className='create-channel-signals-table-content'>
      <span>Common Mode Choke Components</span>
      <Table
        ref={(ref) => this.table = ref}
        columns={columns}
        dataSource={dataList}
        className="channel-component-table space-10 channel-create-component-table"
        rowKey={record => `${record.part}-${record.type}`}
        tableLayout='fixed'
      />
    </div>
  }
}


export default CMCComponentTable;