import React from 'react';
import { ICTypes } from '../constants';
import designConstructor from '../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import { JUMPER } from '../../../constants/componentType';
import { RLC_TYPES } from '../../../services/PCBHelper';
import { checkPassive, checkPins, checkPkgFile, getConnCheck, getExtractionPortErrorCheck, getPowerCompsCheck, getRepeaterErrorCheck, getRLCValueCheck, preLayoutInterfaceErrorCheck } from './ErrorCheck';
import sierraLibrary from '../../../services/Sierra/library/libraryStorage';
import { getVirtualCompsErrors } from '../../../services/VirtualComponent/errorCheck';


export function getSierraInterfaceErrorCheck(Interface) {
  let warning = [];
  let error = [];
  if (!Interface) {
    error.push(<p style={{ margin: 0 }}>Does not set the signal.</p>)
    return { error };
  }
  const pcb = Interface.pcb;
  const { components, powerNets, powerComponents, channel, ports_generate_setup_list = [], virtualComps = [] } = Interface.content;
  if (!components || components.length === 0) {
    error.push(<p style={{ margin: 0 }}>Does not set signal nets.</p>)
  }

  let errorPowerGndNets = [];

  const vendor = designConstructor.getDesignVendor(Interface.pcbId)
  if (vendor !== PRE_LAYOUT) {
    // Power/Ground not found in PCB
    if (powerNets.length === 0) {
      error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{pcb}</span>" does not set power, ground nets.</p>)
    } else {
      const GND = powerNets.find(net => parseFloat(net.value) === 0 || net.name.match(/gnd/i));
      if (!GND) {
        error.push(<p style={{ margin: 0 }}>"<span className="font-bold">{pcb}</span>" does not set ground net.</p>)
      }
    }
  } else {
    const signals = Interface.content && Interface.content.signals ? Interface.content.signals : [];
    const preLayoutError = preLayoutInterfaceErrorCheck({ content: Interface, signals, components });
    error.push(...preLayoutError);
  }

  // model
  const ICs = components.filter(item => ICTypes.includes(item.type));
  if (ICs.length > 0) {
    ICs.forEach(comp => {
      if (comp.pkg) {
        let _error = checkPkgFile(comp.pkg, `${pcb}::${comp.name}`);
        error.push(..._error)
      }
      //port setup
      if (vendor !== PRE_LAYOUT) {
        const portsError = getExtractionPortErrorCheck(ports_generate_setup_list, comp, channel, pcb);
        error.push(...portsError);
      }
      const compModel = comp.model;
      const _error = checkPins({
        pins: comp.pins,
        compModel,
        name: `${pcb}::${comp.name}`,
        sweep: false
      })
      error.push(..._error)
    });
  }

  const repeaterError = getRepeaterErrorCheck({ components, pcb });
  error.push(...repeaterError);

  // RES/IND/CAP/JUMPER components value
  const rlcError = getRLCValueCheck({ components, powerNets, errorPowerGndNets, pcb });
  error.push(...rlcError);

  // components model
  const passiveModels = components.filter(item => [...RLC_TYPES, JUMPER].includes(item.type));
  if (passiveModels.length > 0) {
    const _error = checkPassive(passiveModels, pcb);
    error.push(..._error)
  }

  // powerComponents value check
  const _pwrCompError = getPowerCompsCheck({ powerComponents, channel, pcb })
  error.push(..._pwrCompError);

  const connsError = getConnCheck(components, pcb);
  error.push(...connsError);

  //virtual components error check
  if (virtualComps && virtualComps.length) {
    const errors = getVirtualCompsErrors(virtualComps, { getCheckFile: sierraLibrary.checkFile, pcbName: pcb })
    if (errors && errors.length) {
      error.push(...errors);
    }
  }

  if (warning.length === 0 && error.length === 0) {
    return null;
  } else {
    return {
      //warning, 
      error
    }
  }
}