const makeCancelable = (promise) => {
  let hasCancel = false;
  const newPromise = new Promise((resolve, reject) => {
    promise.then((val) => {
      hasCancel ? reject({ isCancel: true }) : resolve(val)
    });
    promise.catch((error) => {
      hasCancel ? reject({ isCancel: true }) : reject(error)
    });
  });

  return {
    promise: newPromise,
    cancel() {
      hasCancel = true;
    }
  }
};

export default makeCancelable;