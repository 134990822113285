import {
  GET_CARD_VERIFICATION_CONTENT,
  UPDATE_CARD_INFO,
  OPEN_CARD_CHANNEL,
  UPDATE_CARD_EXTRACTION_CONFIG,
  GET_CARD_CHANNEL_CONFIG,
  CLOSE_CARD_CHANNEL,
  SAVE_CARD_EXTRACTION_CONFIG,
  SAVE_CARD_PORT_SETUPS,
  UPDATE_CARD_ERROR_CHECK_LIST,
  START_CARD_VERIFICATIONS_MODELING,
  SAVE_CARD_COMP_PACKAGE_MODEL,
  SAVE_CARD_COMP_RLC_MODEL,
  SAVE_CARD_TO_LIBRARY,
  CHANGE_CARD_COMPONENT_TYPE
} from './actionTypes';

export const getCardVerificationContent = (id) => ({
  type: GET_CARD_VERIFICATION_CONTENT,
  id
});

export const updateCardInfo = (info) => ({
  type: UPDATE_CARD_INFO,
  info
});

export const openCardChannel = (id, key) => ({
  type: OPEN_CARD_CHANNEL,
  id,
  key
});

export const updateCardExtractionConfig = (extraction, isOpenExtractionPanel) => ({
  type: UPDATE_CARD_EXTRACTION_CONFIG,
  extraction,
  isOpenExtractionPanel
});

export const getCardChannelConfig = (id) => ({
  type: GET_CARD_CHANNEL_CONFIG,
  id
});

export const closeCardChannel = () => ({
  type: CLOSE_CARD_CHANNEL
});

export const saveCardExtractionConfig = (extraction, prevExtraction) => ({
  type: SAVE_CARD_EXTRACTION_CONFIG,
  extraction,
  prevExtraction
});

export const saveCardPortSetups = (data, ComponentSetups) => ({
  type: SAVE_CARD_PORT_SETUPS,
  data,
  ComponentSetups
});

export const updateCardErrorCheckList = (cardErrorCheck) => ({
  type: UPDATE_CARD_ERROR_CHECK_LIST,
  cardErrorCheck
});

export const startCardVerificationsModeling = (verificationIds, verificationId, isOpenNewVe) => ({
  type: START_CARD_VERIFICATIONS_MODELING,
  verificationIds,
  verificationId,
  isOpenNewVe
});

export const saveCardCompPackageModel = (pkg, compName) => ({
  type: SAVE_CARD_COMP_PACKAGE_MODEL,
  pkg,
  compName
});

export const saveCardCompRLCModel = (value, part, compType, model) => ({
  type: SAVE_CARD_COMP_RLC_MODEL,
  value,
  part,
  compType,
  model
});

export const saveCardToLibrary = (designId) => ({
  type: SAVE_CARD_TO_LIBRARY,
  designId
});

export const changeCardComponentType = (record) => ({
  type: CHANGE_CARD_COMPONENT_TYPE,
  record
})