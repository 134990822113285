import React, { Component, Fragment } from 'react';
import { Button, Progress, Collapse, Checkbox } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '../../../../../components/Panel';
import { getWaveFormJsonConfig, mergeProcessData } from '../../../../../services/Sierra/results';
import DelConfirm from '../../../../../components/DelConfirm';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../../../services/helper/panelSizeHelper';
import MeasurementTable from './measurementTable';
import SettingFooter from './settingFooter';
import { initMeasurementResultData } from '../../../../../services/Sierra/helper/measurementHelper';
import '../new.css';
/* 
const CheckboxGroup = Checkbox.Group; */
class MeasurementPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      warning: false,
      setting: {
        "voutlUnit": "%",
        "vouthUnit": "%",
        "vinlUnit": "%",
        "vinhUnit": "%"
      },
      settingApply: false,
      maxHeight: 600,
      maxWidth: 1200,
      applyAllList: [],
      activeKey: [],
      checkedExps: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    this.initData()
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 600) - 80,
      maxWidth: getPanelMaxWidth(offset, 1150)
    })
  }

  componentDidUpdate = (prevProps) => {
    const { tableDataList } = this.props;
    if ((!prevProps.tableDataList && tableDataList && tableDataList.length)
      || (prevProps.tableDataList && tableDataList && prevProps.tableDataList.length !== tableDataList.length)) {
      this.initData()
    }
  }

  initData = () => {
    const { tableDataList, sweep } = this.props;
    const allList = sweep ? tableDataList.map(item => item.id) : [];
    this.setState({
      tableData: tableDataList ? JSON.parse(JSON.stringify(tableDataList)) : [],
      applyAllList: [],
      activeKey: [...allList],
      checkedExps: [...allList]
    })
  }

  update = (isClose) => {
    const { sweep, _updateSweepReCalc, recalcMeasure, _updateProgress } = this.props;
    const { tableData, checkedExps, filterUpdate } = this.state;
    this.setState({
      warning: false
    })
    let sweepReCalc = [];
    let includesIds = [], key = "id";
    if (sweep && !isClose) {
      includesIds = [...checkedExps]
    }
    if (sweep && isClose && filterUpdate) {
      includesIds = [...filterUpdate];
      key = "name"
    }
    let reCalcConfigList = [];
    for (let item of tableData) {
      if (sweep && !includesIds.includes(item[key])) {
        continue;
      }

      if (/* item.isUpdate &&  */item.postProcessConfig && Object.keys(item.postProcessConfig).length) {
        _updateProgress && _updateProgress({ verificationId: item.id, progress: 0 });
        const newProcessConfig = mergeProcessData(item.postProcessConfig, item.measurementInfo);
        //  this.props.recalcMeasure && this.props.recalcMeasure(newProcessConfig, item.verificationId, sweep, item.id)
        reCalcConfigList.push({
          config: newProcessConfig,
          verificationId: item.verificationId,
          id: item.id
        })
        sweep && sweepReCalc.push({ id: item.id, verificationId: item.verificationId });
        item.isUpdate = false;
      }
    }
    _updateSweepReCalc && _updateSweepReCalc(sweepReCalc);
    recalcMeasure && recalcMeasure(reCalcConfigList, sweep)
    this.props.saveTableData(tableData)
    this.setState({
      filterUpdate: null,
      tableData
    })
    /*   this.props.changeMeasureVisible(false) */
  }

  closeModal = () => {
    const { tableData } = this.state;
    const filterUpdate = tableData.filter(item => !!item.isUpdate).map(item => item.name);
    if (!filterUpdate.length) {
      this.props.changeMeasureVisible(false);
      return;
    }
    this.setState({
      warning: true,
      filterUpdate
    })
  }

  showSetting = (key) => {
    this.setState({
      settingApply: key
    })
  }

  changeSettingInput = (e, key) => {
    const { applyAllList } = this.state;
    this.setState({
      setting: { ...this.state.setting, [key]: e.target.value },
      showMsg: !applyAllList.length ? true : false
    })
  }

  changeSettingUnit = (unit, key) => {
    this.setState({
      setting: { ...this.state.setting, [`${key}Unit`]: unit, [key]: "" }
    })
  }

  updateSettingApply = (settingApply) => {
    this.setState({
      settingApply
    })
  }

  changeApplyAll = (values) => {
    this.setState({
      applyAllList: values
    })
  }

  applyAllUpdate = () => {

  }


  footRender = () => {
    const { setting, tableData, applyAllList, showMsg } = this.state;
    const { progress, recalc, sweep, experimentList } = this.props;
    return <Fragment>
      <SettingFooter
        type={"result"}
        setting={setting}
        tableData={tableData}
        applyAllList={applyAllList}
        isSweep={sweep}
        showMsg={showMsg}
        experiments={sweep ? experimentList : []}
        updateSettingApply={this.updateSettingApply}
        updateTableData={this.updateTableData}
        changeSettingUnit={this.changeSettingUnit}
        changeSettingInput={this.changeSettingInput}
        changeApplyAll={this.changeApplyAll}
        applyAllUpdate={this.applyAllUpdate}
      />

      <div className='measurement-footer-button-content'>
        {recalc && progress !== false && <Progress
          percent={progress >= 0 ? progress : 100}
          className="sierra-measure-progress" />}
        <Button
          onClick={() => this.update()}
          size="small"
          disabled={recalc}
        >Calculate</Button>
      </div>
    </Fragment>
  }

  changeDisplayPanelVisible = (status) => {
    this.setState({
      calcDefinitionVisible: status
    })
  }

  updateTableData = (tableData) => {
    this.setState({
      tableData
    })
  }

  updateTableDataById = (data, isUpdate, verificationId) => {
    let _tableData = [...this.state.tableData];
    const index = _tableData.findIndex(item => item.verificationId === verificationId);
    if (index < 0) {
      return;
    }
    _tableData[index].measurementInfo = data;
    _tableData[index].isUpdate = isUpdate || _tableData[index].isUpdate;
    this.setState({
      tableData: _tableData
    })
  }

  collapseChangeKeys = (activeKey) => {
    this.setState({
      activeKey
    })
  }

  changeCheckExps = (e, id) => {
    e.stopPropagation();
    let _checkedExps = [...this.state.checkedExps];
    if (e.target.checked) {
      _checkedExps.push(id);
    } else {
      _checkedExps = _checkedExps.filter(item => item !== id);
    }
    this.setState({
      checkedExps: [..._checkedExps]
    })
  }

  updateModelType = async (e, verificationId) => {
    const { tableData } = this.state;
    let _tableData = [...tableData];
    const index = _tableData.findIndex(item => item.verificationId === verificationId);
    if (index < 0) {
      return;
    }
    try {
      let data = await getWaveFormJsonConfig(verificationId, e.target.value);
      const { _tableData: _measurementInfo, update } = initMeasurementResultData(data, _tableData[index].measurementInfo);
      _tableData[index].modelType = data.modelType || e.target.value;
      _tableData[index].measurementInfo = _measurementInfo;
      _tableData[index].isUpdate = update;
      this.setState({
        tableData: _tableData,
      })
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { tableData, warning, maxHeight, maxWidth, activeKey, checkedExps, filterUpdate } = this.state;
    const { sweep, sweepReCalc, singleProgress } = this.props;
    const currData = sweep ? {} : tableData && tableData[0] ? tableData[0] : {};
    const content = (
      <Panel
        className='sierra-measure-panel sierra-panel'
        title='Measurement Conditions'
        onCancel={this.closeModal}
        zIndex={1000}
        width={getPanelWidth(maxWidth, { defaultWidth: 1150 })}
        maxHeight={maxHeight}
        position='panel-center-top'//panel-top-right
        draggable
        minHeight={100}
        minWidth={800}
        maxWidth={maxWidth}
        //defaultTop={200}
        footerHeight={sweep ? 114 : 80}//settingApply ? 90 : 50}
        footer={this.footRender()}
      >
        <div className='waveFrom-post-process-content waveFrom-post-process-measure-content'>
          {sweep ?
            <div className='waveFrom-post-process-measure-sweep-content'>
              {/*  <div className='waveFrom-post-process-measure-sweep-left'>
                <CheckboxGroup
                  value={checkedExps}
                  className='waveFrom-post-process-measure-sweep-checkbox-group'
                  onChange={(values) => this.changeCheckExps(values)}
                >
                  {tableData.map(item => <Checkbox
                    key={item.id}
                    value={item.id}
                    className='waveFrom-post-process-measure-sweep-checkbox'
                  >{item.name}</Checkbox>)}
                </CheckboxGroup>

              </div> */}
              <Collapse
                bordered={false}
                activeKey={activeKey}
                onChange={this.collapseChangeKeys}
                items={tableData.map(data => {
                  const findProgress = (singleProgress || []).find(item => item.verificationId === data.id);
                  return {
                    key: data.id,
                    collapsible: "icon",
                    label: <div>
                      <Checkbox
                        key={data.id}
                        value={data.id}
                        checked={checkedExps.includes(data.id)}
                        onChange={(e) => this.changeCheckExps(e, data.id)}
                        className='waveFrom-post-process-measure-sweep-checkbox'
                      />
                      <span>{data.name}</span>
                      {sweepReCalc && sweepReCalc.includes(data.id) && findProgress && findProgress.progress >= 0 ? <Progress percent={findProgress.progress} size="small" status="active" className='waveFrom-post-process-measure-sweep-progress' /> : null}
                    </div>,
                    children:
                      <MeasurementTable
                        updateTableData={(_data, isUpdate) => this.updateTableDataById(_data, isUpdate, data.verificationId)}
                        tableData={data.measurementInfo || []}
                        type="result"
                        modelType={data.modelType}
                        updateModelType={(e) => this.updateModelType(e, data.verificationId)}
                      />
                  }
                })}
              />
            </div>
            : <MeasurementTable
              updateTableData={(data, isUpdate) => this.updateTableDataById(data, isUpdate, currData.verificationId)}
              tableData={currData.measurementInfo ? currData.measurementInfo : []}
              type="result"
              modelType={currData.modelType}
              updateModelType={(e) => this.updateModelType(e, currData.verificationId)}
            />}
        </div>
        {
          warning ? <DelConfirm
            type='delete'
            deleteConfirm={() => this.update(true)}
            cancelDel={() => this.props.changeMeasureVisible(false)}
            okTitle={'Calculate'}
            cancelTitle={'Close'}
            message={sweep && filterUpdate ? `The table content of [${filterUpdate.join(", ")}] have changed. Does ${filterUpdate.length > 1 ? "them" : "it"} need to be recalculated` : "The table content has changed. Does it need to be recalculated?"}
          />
            : null
        }
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default MeasurementPanel;