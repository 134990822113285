import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import SierraTree from './sierraTree';
import {
  LIBRARY, PROJECT, PROJECTS,
  LIBRARY_FILE, VERIFICATION, LIBRARY_DATA, PCB, TRASH,
  RESULT, LIBRARY_FOLDER, EXPERIMENTS, EXPERIMENTS_CREATE, EXPERIMENTS_RESULT,
  PCB_PRE_LAYOUT, VERIFICATIONS, MULTI_INTERFACE_SETUP, VERIFICATION_GROUP
} from '@/constants/treeConstants';
import LibraryUploadPanel from '../LibraryPanel/LibraryUploadPanel';
import VectorPanel from '../LibraryPanel/vectorPanel';
import {
  clearCurrentProject, updateProjectMenu, openPage, updateTrashMenu, showResult,
  changeTreeSelected, openProject, changeViewList, deletePCB
} from '../store/project/action';
import {
  openLibraryFolder,
  getLibraryTreeByType,
  afterImportLibrary,
  updateFileContent,
  saveLibraryFileContent,
  updateRepeaterFileStatus,
  savePreLayoutTemplate
} from '../store/library/action';
import {
  expandMenu,
  saveCurrentVerification,
  updateInterfaceLibrary,
  changeIdentificationVisible,
  createInterfaceIdentification,
  updateVerificationSetting,
  updateIdentificationMassage,
  updateSetupAfterCloseSettings,
  updateSetupAfterClosePartLibrary,
  updateCompSettingsStatus,
  updatePartRepeater,
  updatePartRepeaterLog,
  closePartRepeaterPanel
} from '../store/sierra/action';
import {
  createProjectPromise,
  deleteProjectPromise,
  createVerificationPromise,
  delVerification,
  changeInterfaceName,
  deleteExsitResult,
  changeProjectName,
  editExperimentGroupList,
  deleteExperimentGroupList,
  SweepData,
  combineInterfacePromise
} from '../../../services/Sierra'
import {
  delLibraryList,
  editSierraLibraryFolder,
  deleteSierraLibraryFolder,
  searchPowerNetsByKeywords,
  getTraceTemplateLibrary,
  calculateTraceImpedance,
  getTraceImpedanceData
} from '../../../services/Sierra/library';
import LayoutData from '@/services/data/LayoutData';
import { changeTabMenu, changeVerification, openTabFooter, closeTabFooter } from '../../tabMonitor/action';
import {
  changeVerificationList,
  startSierraVerification,
  getCurrentLog,
  changeUploadMes,
  existResult
} from '../store/simulation/action';
import { cleanupTrash } from '@/services/api/v2/projectCtrl';
import FileContentPanel from '../LibraryPanel/FileContentPanel';
import { getDefaultName } from '../../../services/helper/setDefaultName';
import { strDelimited } from '@/services/helper/split';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import {
  getLibraryAccept,
  getLibraryTitleSuffix,
  getLibrarySpecial,
  getLibraryTitle
} from '../../../services/Library';
import ShowModelsPanel from '../LibraryPanel/ShowModelsPanel';
import { closeUploadProgressModal } from './Upload/store/action'
import CreateIdentification from '../Content/Identification'
import { SINGLE_PAGE_LAYOUT } from '../../../constants/layoutConstants';
import ImportExportPanel from '../../../components/ImportExport';
import ComponentsSetting from '@/components/ComponentsSetting'
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import compTableHelper from '@/services/Sierra/helper/compTableHelper';
import { parseSpiceModelSelector } from '@/services/LibraryHelper/SpiceModelHelper';
import { SIERRA } from '@/constants/pageType'
import CompPinMap from '../Content/compPinMap';
import {
  IBIS,
  PART_IBIS_LIBRARY,
  PART_REPEATER_LIBRARY,
  PASSIVE_SPICE,
  PASSIVE_TOUCHSTONE,
  REPEATER,
  NETS_FILTER_LIBRARY,
  TRACE,
  VECTOR
} from '../../../constants/libraryConstants';
import PartLibraryPanel from '../LibraryPanel/PartLibraryPanel';
import {
  VERIFY_RUNNING,
  WAITING
} from '@/constants/verificationStatus';
import { getLibraryFileContent } from '@/services/Sierra/library';
import powerStore from '../../../services/Sierra/helper/powerNetsTableHelper';
import NetsFilterPanel from '../LibraryPanel/NetsFilterPanel';
import { CUSTOM } from '../../../services/PCBHelper/constants';
import projectVerifications from '../../../services/Sierra/data/projectVerifications';
import { ALL_INTERFACES } from '../../../services/Sierra/multiInterfaceSetup';
import { updateMultiInterfaceSetupStatus } from '../store/multiInterface/action';
import { EXPERIMENT_GROUP_VERSION } from '../../../version';
import TraceLibraryPanel from '@/components/PreLayoutLibrary';
import { SierraTraceConfig } from '../../../services/Sierra/prelayout/Schematic';
import preLayoutLibraryData from '../../../services/Sierra/library/PreLayoutData';
import { updateTreeSelectKeys } from '../../../services/helper/filterHelper';

message.config({
  duration: 2,
  maxCount: 3,
});

class SierraSider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      modelType: '',
      modelName: '',
      parentId: '-1',
      inputVerificationName: '',
      defaultLibName: null,
      fileId: null,
      fileName: null,
      updateLibrary: {},
      removedModels: [],
      createIdentificationInfo: {},
      settingPCB: {},
      uploading: {
        key: '',
        status: false
      },
      importExportModelVisible: true,
      partRepeaterVisible: false,
      libList: []
    }
  }

  addItem = ({ key, dataType, item }) => {
    switch (dataType) {
      case LIBRARY:
      case LIBRARY_FOLDER:
        this.addLibrary(key, item);
        break;
      default: return;
    };
  }

  editItem = async (e, { key, dataType, item }) => {
    e.stopPropagation();
    switch (dataType) {
      case LIBRARY_DATA:
        if (item.fileType === TRACE) {
          this.setState({
            editVisible: true,
            modelType: TRACE,
            modelName: item.name,
            fileId: item.id,
            libList: await sierraLibrary.get({ id: item.id, type: key })
          });
          break;
        }
        this.setState({
          fileId: item.id,
          editVisible: true,
          modelType: 'vector',
          modelName: 'Vector',
          parentId: item.parentId
        })
        break;
      case LIBRARY:
        if (key === PART_REPEATER_LIBRARY) {
          this.setState({
            partRepeaterVisible: true
          })
        }
        if ([PART_IBIS_LIBRARY, NETS_FILTER_LIBRARY].includes(key)) {
          this.setState({
            editVisible: true,
            modelType: key,
            modelName: key
          })
        }
        break;
      default: return;
    };
  }

  addLibrary = (key, item) => {
    let defaultLibName = null;
    const name = item.name;
    const id = item.id ? item.id : '-1';
    if ([VECTOR, TRACE].includes(key)) {
      sierraLibrary.get({ id, type: key }).then(res => {
        const libList = res.filter(item => item.libraryType !== 'folder');
        defaultLibName = getDefaultName({ nameList: libList, defaultKey: key === TRACE ? "Trace" : "Vector" });
        this.setState({
          editVisible: true,
          modelType: key,
          modelName: name,
          parentId: id,
          defaultLibName,
          updateLibrary: {},
          libList,
          fileId: null
        });
      })
    } else {
      this.setState({
        editVisible: true,
        modelType: key,
        modelName: name,
        parentId: id,
        updateLibrary: {}
      });
    }
  }

  updateLibraryItem = (e, item) => {
    e.stopPropagation();
    const { id, fileName, fileType, type, parentId } = item;
    const types = (/\.(.(?!\\.))*$/).exec(fileName);
    const accept = type === 'folder' ? '.zip' : types ? types[0] : '*';
    const updateLibrary = { id, name: fileName, accept };
    this.setState({
      editVisible: true,
      modelType: fileType,
      modelName: fileName,
      parentId: parentId,
      updateLibrary
    })
  }

  closeLibraryPanel = (modelType) => {
    if (modelType === PART_REPEATER_LIBRARY) {
      this.setState({
        partRepeaterVisible: false
      })
      return;
    }
    this.setState({
      editVisible: false,
      defaultLibName: null,
      fileId: null,
      fileName: null,
      modelType: '',
      partRepeaterVisible: modelType === PART_REPEATER_LIBRARY ? false : this.state.partRepeaterVisible
    })
    if ([REPEATER, IBIS].includes(modelType)) {
      if (modelType === REPEATER) {
        console.log("Clear all component setting cache after uploading repeater file")
        componentSetting.clearAllSetting();
        this.props._updateCompSettingsStatus(true)
        if (this.state.partRepeaterVisible) {
          this.props._updateRepeaterFileStatus(true)
        }
      }
      const { viewList } = this.props;
      const view = viewList.find(v => [VERIFICATION, MULTI_INTERFACE_SETUP].includes(v))
      if (view) {
        console.log(`Reload interface after uploading ${modelType} file`)
        this.props._updateSetupAfterCloseSettings(view);
      }
    }
  }

  openRemoveModelsPanel = (msg) => {
    this.setState({
      editVisible: true,
      removedModels: msg,
      modelType: msg ? 'showModels' : ''
    })
  }

  changeUploading = (status) => {
    this.setState({
      uploading: {
        key: this.state.uploading.key,
        status
      }
    })
  }

  showImportExportModel = (visible) => {
    this.setState({
      importExportModelVisible: visible
    })
  }

  getLibraryList = (type) => {
    if ([PASSIVE_SPICE, PASSIVE_TOUCHSTONE].includes(type)) {
      return sierraLibrary.getTree(type)
    }
    return []
  }

  getPartPanel = () => {
    const { modelType, libraryTreeInfo, partRepeaterVisible, isDelRepeater } = this.state;
    const { closePartRepeater, updatePartRepeaterLogs, isUpdateRepeaterFile } = this.props;

    const _modelType = partRepeaterVisible ? PART_REPEATER_LIBRARY : modelType;
    return <PartLibraryPanel
      modelType={_modelType}
      isUpdateFile={isUpdateRepeaterFile}
      isDelRepeater={isDelRepeater}
      libraryTreeInfo={libraryTreeInfo}
      closePartRepeater={closePartRepeater}
      updatePartRepeaterLogs={updatePartRepeaterLogs}
      closeLibraryPanel={this.closeLibraryPanel}
      _updatePartRepeaterLog={this.props._updatePartRepeaterLog}
      _updateSetupAfterClosePartLibrary={this.props._updateSetupAfterClosePartLibrary}
      _updatePartRepeater={this.props._updatePartRepeater}
      _closePartRepeaterPanel={this.props._closePartRepeaterPanel}
      updateRepeaterFileStatus={this.props._updateRepeaterFileStatus}
      updateDelRepeaterStatus={this.updateDelRepeaterStatus}
    />
  }

  _saveLibraryModelFile = (params) => {
    this.props._saveLibraryFile({ ...params, partRepeaterVisible: this.state.partRepeaterVisible })
  }

  getPanel = (modelType) => {
    const { modelName, defaultLibName, fileId, fileName, modelFileType, parentId, updateLibrary,
      removedModels, settingPCB, libraryTreeInfo, importExportModelVisible, dataType, key, libList } = this.state;
    const { projectList, UpdateProjectMenu, clearCurrentProject, changeViewList, closeTabFooter, currentProjectId, _updateFileContent, updateFileContent, updateSettingStatus } = this.props;
    const modelTitle = getLibraryTitle(modelType);
    const suffix = getLibraryTitleSuffix(modelType);
    const accept = updateLibrary && updateLibrary.id ? updateLibrary.accept : getLibraryAccept(modelType);
    const special = updateLibrary && updateLibrary.id ? "" : getLibrarySpecial(modelType, 'sierra');
    switch (modelType) {
      case VECTOR:
        return <VectorPanel
          modelType={modelType}
          title={modelTitle}
          uploadTitle={`Import ${modelTitle} ${suffix}`}
          importText={suffix}
          accept={accept}
          parentId={parentId}
          closeLibraryPanel={this.closeLibraryPanel}
          defaultVectorName={defaultLibName}
          fileId={fileId}
          vectorList={sierraLibrary.getTree(VECTOR)}
          openRemoveModelsPanel={this.openRemoveModelsPanel}
          updateLibrary={updateLibrary}
        />
      case 'fileContent':
        return <FileContentPanel
          title={modelName}
          closeModal={this.closeContentModal}
          parentId={parentId}
          fileId={fileId}
          fileName={fileName}
          modelFileType={modelFileType || key}
          saveLibraryFile={this._saveLibraryModelFile}
          isUpdate={updateFileContent}
          updateFileContent={_updateFileContent}
          dataType={dataType}
        />
      case 'showModels':
        return <ShowModelsPanel
          closeModal={this.closeLibraryPanel}
          removed={removedModels}
        />
      case 'importExportLibrary':
      case 'importExportProject':
        return <ImportExportPanel
          closeModal={this.closeLibraryPanel}
          getLibraryTreeByType={this.props.getLibraryTreeByType}
          afterImportLibrary={this.props.afterImportLibrary}
          product='sierra'
          title={modelType === 'importExportLibrary' ? 'Library' : 'Project'}
          importExportModelVisible={importExportModelVisible}
          projectList={projectList}
          updateProjectMenu={UpdateProjectMenu}
          clearCurrentProject={clearCurrentProject}
          changeViewList={changeViewList}
          closeTabFooter={closeTabFooter}
          changeUploading={this.changeUploading}
          showImportExportModel={this.showImportExportModel}
        />
      case 'componentsSetting':
        return <ComponentsSetting
          pcbId={settingPCB.id}
          closeModal={() => this.updateSettingPCB(false)}
          pcbName={settingPCB.name}
          projectId={currentProjectId}
          updateSetting={this.closeSettingPCB}
          settingStore={componentSetting}
          doNotStuffStore={componentDoNotStuff}
          product={SIERRA}
          CompPinMap={CompPinMap}
          tableStore={compTableHelper}
          libraryTreeInfo={libraryTreeInfo}
          parseSpiceModelSelector={parseSpiceModelSelector}
          getLibraryFileContent={getLibraryFileContent}
          getLibraryList={this.getLibraryList}
          powerStore={powerStore}
          updateSettingStatus={updateSettingStatus}
          _updateCompSettingsStatus={this.props._updateCompSettingsStatus}
          ReSearchNets={NetsFilterPanel}
          searchPowerNetsByKeywords={searchPowerNetsByKeywords}
        />
      case PART_IBIS_LIBRARY:
        return this.getPartPanel();
      case NETS_FILTER_LIBRARY:
        return <NetsFilterPanel
          closeLibraryPanel={this.closeLibraryPanel}
        />
      case TRACE:
        return <TraceLibraryPanel
          product={SIERRA}
          modelType={modelType}
          modelName={modelTitle}
          library={fileId ? { name: modelName, id: fileId } : null}
          singleMode={true}
          defaultLibraryName={defaultLibName}
          closeLibraryPanel={this.closeLibraryPanel}
          getLibraryDetail={getTraceTemplateLibrary}
          calculateImpedance={calculateTraceImpedance}
          getTraceImpedanceData={getTraceImpedanceData}
          libraryList={libList || []}
          libraryId={fileId}
          TraceConfig={SierraTraceConfig}
          _updateLibraryData={this.props._savePreLayoutTemplate}
        />
      default:
        return <LibraryUploadPanel
          modelType={modelType}
          title={modelTitle}
          uploadTitle={`Import ${modelTitle} ${suffix}`}
          parentId={parentId}
          importText={suffix}
          accept={accept}
          special={special}
          closeLibraryPanel={this.closeLibraryPanel}
          openRemoveModelsPanel={this.openRemoveModelsPanel}
          updateLibrary={updateLibrary}
        />
    }
  }

  createProject = (name, item) => {
    this.props.clearCurrentProject(true);
    const { projectList } = this.props;
    let _filter = projectList.filter(i => i.id !== item.id);
    let names = _filter.map(item => item.name);
    if (names.includes(name)) {
      message.error('Project:' + name + ' already exists!');
      this.props.UpdateProjectMenu();
      return;
    }
    createProjectPromise(name).then(res => {
      if (res && res.id) {
        this.props.UpdateProjectMenu({ openProjectId: res.id });
      } else {
        const msg = res && res.msg ? ` - ${res.msg}` : ""
        message.error('Project creation failed!' + msg);
      }
    }, (error) => {
      message.error('Project creation failed!');
      console.error(error)
    })
  };

  createFolder = (item) => {
    const { parentId, type, name, id } = item;
    sierraLibrary.get({ id: parentId, type }).then(res => {
      const findItem = res.find(it => it.name === name);
      if (findItem) {
        message.error('Folder:' + name + ' already exists!');
        return;
      }
      editSierraLibraryFolder({ id, name, parentId, type }).then(response => {
        this.props.openLibraryFolder(parentId, type, true);
        this.props.getLibraryTreeByType([type]);
      })
    })
  }

  renameFolder = (value, item) => {
    const { parentId, type, id } = item;
    if (item.name === value) {
      return;
    }
    sierraLibrary.get({ id: parentId, type }).then(res => {
      const findItem = res.find(it => it.name === value);
      if (findItem) {
        message.error('Folder:' + value + ' already exists!');
        return;
      }
      editSierraLibraryFolder({ id, name: value, parentId, type }).then(response => {
        this.props.getLibraryTreeByType([type]);
        this.props.openLibraryFolder(parentId, type, true);
      })
    })
  }

  openPCB = () => {
    const { layout, viewList } = this.props;
    let list = [];
    if (layout !== SINGLE_PAGE_LAYOUT) {
      list = [...viewList, 'PCB'];
      list = [...new Set(list)];
    } else {
      list = ['PCB'];
      this.props.closeTabFooter()
    }
    this.props.changeViewList(list);
  }

  delItem = (e, item) => {
    e.stopPropagation();
    const { dataType } = item;
    switch (dataType) {
      case PROJECT:
        this.deleteProject(item);
        break;
      case LIBRARY_FILE:
      case LIBRARY_DATA:
        this.deleteLibrary(item);
        break;
      case VERIFICATION:
        this.deleteVerification(item);
        break;
      case PCB:
      case PCB_PRE_LAYOUT:
        this.props._deletePCB(item);
        break;
      case TRASH:
        this.cleanupTrash();
        break;
      case RESULT:
        this.deleteResult(item);
        break;
      case LIBRARY_FOLDER:
        this.deleteLibraryFolder(item);
        break;
      case EXPERIMENTS:
        this.deleteExperimentGroup(item);
        break;
      case VERIFICATIONS:
        this.batchDeleteInterfaces(item);
        break;
      default: return;
    }
  }

  deleteLibraryFolder = (item) => {
    const { id, parentId, type } = item;
    deleteSierraLibraryFolder([id]).then(res => {
      this.props.getLibraryTreeByType([type]);
      this.props.openLibraryFolder(parentId, type, true)
      type === REPEATER && this.updateDelRepeaterStatus(true)
    })
  }

  updateDelRepeaterStatus = (isDelRepeater) => {
    this.setState({
      isDelRepeater
    })
  }

  deleteResult = (item) => {
    const id = strDelimited(item.key, "-", { returnIndex: 1 });
    const { verificationId, currentProjectId } = this.props;
    deleteExsitResult(id).then(res => {
      if (id === verificationId) {
        this.props.existResult(false);
      }
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
    });
  }

  cleanupTrash = () => {
    cleanupTrash('PinToPin').then(res => {
      this.props.updateTrashMenu();
    })
  }

  deleteProject = (item) => {
    const { id } = item;
    let pcbId = null;
    const { currentProjectId, verificationId } = this.props;
    if (item.children && item.children.length > 0) {
      const pcbList = item.children.filter(item => item.name === 'PCB');
      if (pcbList[0].children && pcbList[0].children.length > 0) {
        pcbId = pcbList[0].children[0].id;
      }
    }
    if (pcbId) {
      LayoutData.cleanLayoutInfo(pcbId);
    }

    deleteProjectPromise([id]).then(res => {

      this.props.updateTrashMenu();
      if (currentProjectId === id) {
        this.props.UpdateProjectMenu();
        this.props.clearCurrentProject(true);
        // this.props.cleanTabMonitorMsg();
        let { expandedKeys } = this.props;
        let keys = [...expandedKeys];
        let deleteKey = `project-${id}`;
        const delIndex = keys.findIndex(item => item === deleteKey);
        if (delIndex > -1) {
          keys.splice(delIndex, 1);
        }

        this.props.expandMenu(keys);
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
      }
    });

    if (this.props.uploadProjectId === id) {
      this.props._closeUploadProgressModal()
    }
  }

  deleteLibrary = (item) => {
    const { id, fileType, fileName, parentId } = item;
    let deLIds = [];
    deLIds.push(id);
    delLibraryList(deLIds, fileType).then(res => {
      this.props.openLibraryFolder(parentId, fileType, true);
      this.props.getLibraryTreeByType([fileType]);
      if (fileType === TRACE) {
        preLayoutLibraryData.deleteLibrary(id, fileType);
      }
      //update current opened interface library file
      const { verificationId } = this.props;
      if (verificationId) {
        let libraryFile = { fileName, id };
        this.props.updateInterfaceLibrary(fileType, libraryFile);
      }
      if (fileType === REPEATER) {
        this.updateDelRepeaterStatus(true)
      }
    })
  }

  deleteVerification = (item) => {
    const { id } = item;
    const { verificationId, currentProjectId, viewList, selectedKeys } = this.props;
    delVerification([id]).then(res => {
      /*  this.props.UpdateProjectMenu({ openProjectId: currentProjectId }); */

      if (id === verificationId) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
        this.props.clearCurrentProject();

        //Close current open Interface
        let list = viewList.filter(item => ![RESULT, VERIFICATION].includes(item));
        this.props.changeViewList(list)
        // update selectedKey
        let newSelectedKey = selectedKeys.filter(k => ![`${VERIFICATION}-${id}`, `${RESULT}-${id}`].includes(k));
        this.props.changeTreeSelected(newSelectedKey);

        this.props.closeTabFooter();
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
      }
    });
  }

  deleteExperimentGroup = (item) => {
    const { id, verificationId } = item;
    const { currentProjectId, experimentsId, viewList, selectedKeys } = this.props;
    deleteExperimentGroupList(id).then(res => {
      SweepData.delete(verificationId, id);
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      if (id === experimentsId) {
        //Close current open Interface
        let list = viewList.filter(item => ![EXPERIMENTS, EXPERIMENTS_RESULT].includes(item));
        this.props.changeViewList(list);
        // update selectedKey
        let newSelectedKey = selectedKeys.filter(k => ![`${EXPERIMENTS}-${id}`, `${EXPERIMENTS_RESULT}-${id}`].includes(k));
        this.props.changeTreeSelected(newSelectedKey);
        this.props.closeTabFooter();
      }
    })
  }

  createVerification = (value, type, item) => {
    const VName = value;
    const { currentProjectVerifications, viewList, layout } = this.props;
    const _filter = currentProjectVerifications.filter(verification => verification.id !== item.id);
    const exsitV = _filter.map(item => item.name);
    const projectId = strDelimited(item.key, "-", { returnIndex: 1 })
    if (exsitV.includes(VName)) {
      this.props.openProject(projectId);
      message.error('Verification:' + VName + ' already exists!');
      return;
    }
    createVerificationPromise({ name: VName, tag: type || CUSTOM, projectId, readyForSim: 0 }).then(res => {
      if (!res) return;
      const verificationId = res.id;
      // this.props.createConfig(verificationId);
      this.props.UpdateProjectMenu({ openProjectId: projectId, verificationId, verificationName: res.name });

      const { name } = res;
      const { selectedKeys } = this.props;
      this.props._openPage(VERIFICATION, { verificationId });
      let list = [...viewList, VERIFICATION];
      list = [...new Set(list)];
      list = list.filter(item => ![RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
      if (layout === 3) {
        list = [VERIFICATION]
      }
      this.props.changeViewList(list);
      this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId });
      this.props.changeVerification(name);
      this.props.openTabFooter();
      this.props.getCurrentLog(verificationId, name);

      if (selectedKeys.length > 1) {
        let newKeys = [];
        selectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (![RESULT, VERIFICATION, EXPERIMENTS_RESULT, RESULT, VERIFICATION_GROUP].includes(key)) {
            newKeys.push(item);
          }
        });
        newKeys.push(`${VERIFICATION}-${verificationId}`);
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([`${VERIFICATION}-${verificationId}`])
      }
    });
  }

  createExperiments = (name, item) => {
    const { currentProjectVerifications, currentProjectId, viewList, layout } = this.props;
    const { verificationId } = item;
    const currentVerification = currentProjectVerifications.find(item => item.id === verificationId);
    if (currentVerification) {
      const names = currentVerification.children.map(item => item.dataType !== EXPERIMENTS_CREATE && item.name);
      if (names.includes(name)) {
        this.props.openProject(currentProjectId);
        message.error('Experiment:' + name + ' already exists!');
        return;
      }
    }
    editExperimentGroupList({ name, verificationId, version: EXPERIMENT_GROUP_VERSION }).then(res => {
      const { id, version } = res;
      SweepData.add(verificationId, { id, name, rootVerificationId: verificationId, version })
      const { selectedKeys } = this.props;
      this.props._openPage(EXPERIMENTS, { verificationId, id });
      let list = [...viewList, EXPERIMENTS];
      list = [...new Set(list)];
      list = list.filter(item => ![RESULT, VERIFICATION, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
      if (layout === 3) {
        list = [EXPERIMENTS]
      }
      this.props.changeViewList(list);
      this.props.openProject(currentProjectId);

      if (selectedKeys.length > 0) {
        let newKeys = [];
        selectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (![EXPERIMENTS, EXPERIMENTS_RESULT, VERIFICATION, RESULT, VERIFICATION_GROUP].includes(key)) {
            newKeys.push(item);
          }
        });
        newKeys.push(`${EXPERIMENTS}-${id}`);
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([`${EXPERIMENTS}-${id}`])
      }
    })
  }

  openVerification = (verificationId, status) => {
    const { currentProjectVerifications, currentProjectId, viewList, layout, pcbLayout } = this.props;
    const index = currentProjectVerifications.findIndex(item => item.id === verificationId);
    let name = null;
    if (typeof (index) !== 'boolean' && index > -1) {
      name = currentProjectVerifications[index].name;
    }
    this.props._openPage(VERIFICATION, { verificationId });
    // cleanCompsInfo();
    let list = [...viewList, VERIFICATION];
    list = [...new Set(list)];
    list = list.filter(item => ![RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
    if (layout === 3) {
      list = [VERIFICATION]
    }
    const { selectedKeys } = this.props;
    if (status) {
      /*  if (selectedKeys.length > 0) {
         let newKeys = [];
         selectedKeys.forEach((item, index) => {
           const [key] = strDelimited(item, "-");
           if (key !== VERIFICATION && key !== 'result' && key !== VERIFICATION_GROUP) {
             newKeys.push(item);
           }
         });
         newKeys.push(`${VERIFICATION}-${verificationId}`);
         this.props.changeTreeSelected([...newKeys]);
       } else {
         this.props.changeTreeSelected([`${VERIFICATION}-${verificationId}`])
       } */
      const selecteds = updateTreeSelectKeys({
        prevSelectedKeys: [...(selectedKeys || [])],
        key: VERIFICATION,
        eventKey: `${VERIFICATION}-${verificationId}`,
        selected: true,
        layout,
        pcbLayout,
        productType: SIERRA
      });
      this.props.changeTreeSelected([...selecteds]);
    }
    this.props.changeViewList(list);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId });
    this.props.changeVerification(name);
    this.props.openTabFooter();
    this.props.changeUploadMes(null);
  }

  openExperiment = (experimentId) => {
    const { viewList, layout } = this.props;
    let list = [...viewList, EXPERIMENTS];
    list = [...new Set(list)];
    list = list.filter(item => ![RESULT, VERIFICATION, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
    if (layout === 3) {
      list = [EXPERIMENTS]
    }
    this.props.changeViewList(list);
    this.props._openPage(EXPERIMENTS, { id: experimentId });
  }

  openPreLayout = (id) => {
    const { viewList, layout } = this.props;
    let list = [...viewList, EXPERIMENTS];
    list = [...new Set(list)];
    list = list.filter(item => ![PCB].includes(item));
    if (layout === 3) {
      list = [PCB_PRE_LAYOUT];
      this.props.closeTabFooter();
    }
    this.props.changeViewList(list);
    const { selectedKeys } = this.props;
    if (selectedKeys.length > 0) {
      let newKeys = [];
      selectedKeys.forEach((item, index) => {
        const [key] = strDelimited(item, "-");
        if (key !== PCB_PRE_LAYOUT && key !== PCB) {
          newKeys.push(item);
        }
      });
      newKeys.push(`${PCB_PRE_LAYOUT}-${id}`);
      this.props.changeTreeSelected([...newKeys]);
    } else {
      this.props.changeTreeSelected([`${PCB_PRE_LAYOUT}-${id}`])
    }
    this.props._openPage(PCB_PRE_LAYOUT, { id });
  }

  showExperimentsResult = (experimentId) => {
    const { viewList, layout, closeTabFooter, tabVisible } = this.props;
    let list = [...viewList, EXPERIMENTS_RESULT];
    list = [...new Set(list)];
    list = list.filter(item => ![RESULT, EXPERIMENTS, VERIFICATION, MULTI_INTERFACE_SETUP].includes(item));
    if (layout === 3) {
      list = [EXPERIMENTS_RESULT]
    }
    this.props.changeViewList(list);
    this.props._openPage(EXPERIMENTS_RESULT, { id: experimentId });
    if (tabVisible) {
      closeTabFooter();
    }
  }

  changeSimulateCheck = (e, key, verification) => {
    e.stopPropagation();
    const { simulateKeys, refNetsLoading, verificationId } = this.props;
    let keys = JSON.parse(JSON.stringify(simulateKeys));
    if (verification.dataType === VERIFICATION_GROUP) {
      const ids = verification.children.filter(item =>
        !(item.status === VERIFY_RUNNING
          || item.status === WAITING
          || item.simStatus
          || (verificationId === item.id && refNetsLoading && refNetsLoading.loading))
      ).map(item => item.id);
      const addKeys = keys.filter(item => !ids.includes(item.verificationId))
      keys = e.target.checked ? [...addKeys, ...ids.map(it => { return { verificationId: it } })] : [...addKeys];
    } else {
      const index = keys.findIndex(item => item.verificationId === verification.id);

      if (typeof (index) !== 'boolean' && index > -1) {

        if (!e.target.checked) {
          keys.splice(index, 1)
        }
      } else {

        if (e.target.checked) {
          keys.push({
            verificationId: verification.id
          })
        }
      }
    }
    this.props.changeVerificationList(keys);
  }

  startVerification = (e, key, item) => {
    e.stopPropagation();
    const { simulateKeys, verificationId, selectedKeys, currentProjectId, currentProjectVerifications } = this.props;
    if (simulateKeys.length === 0) {
      return;
    }
    let verificationIds = simulateKeys.map(item => item.verificationId);
    if (!verificationIds.includes(verificationId) || selectedKeys.includes(`result-${verificationId}`)) {
      this.openVerification(verificationIds[0], true);
      this.props.startSierraVerification(verificationIds);
    } else {
      //save current verification ,and do simulation
      this.props.saveCurrentVerification(verificationIds, verificationId);
      //open monitor box
      const index = currentProjectVerifications.findIndex(item => item.id === verificationId);
      let name = null;
      if (index > -1) {
        name = currentProjectVerifications[index].name;
      }
      this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId });
      this.props.changeVerification(name);
      this.props.openTabFooter();
    }
  }

  batchDeleteInterfaces = (item) => {
    const { simulateKeys, verificationId, selectedKeys, currentProjectId, viewList, multiSetupGroup, multiSetupResult } = this.props;
    if (simulateKeys.length === 0) {
      return;
    }
    let verificationIds = simulateKeys.map(item => item.verificationId);
    delVerification(verificationIds).then(res => {

      if (viewList.includes(MULTI_INTERFACE_SETUP)) {
        let children = null, refresh = false;
        if (multiSetupGroup === ALL_INTERFACES) {
          const allVers = projectVerifications.getVerifications(currentProjectId) || [];
          children = allVers.filter(item => !verificationIds.includes(item.id));
          refresh = true;
        } else {
          const verGroups = projectVerifications.getVerGroups(currentProjectId) || [];
          const findGroup = verGroups.find(item => item.name === multiSetupGroup) || {};
          children = (findGroup.children || []).filter(item => !verificationIds.includes(item.id));
          if (children.length && children.length !== findGroup.children.length)
            refresh = true;
        }

        if (children && !children.length) {
          const list = viewList.filter(item => MULTI_INTERFACE_SETUP !== item);
          this.props.changeViewList(list);
          // update selectedKey
          let newSelectedKey = selectedKeys.filter(k => k !== `${VERIFICATION_GROUP}-${multiSetupGroup}`);
          this.props.changeTreeSelected(newSelectedKey);
          this.props.closeTabFooter();
        } else if (children && children.length && refresh) {
          this.props._updateSetupAfterCloseSettings(MULTI_INTERFACE_SETUP);
        }
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
        return;
      }

      if (verificationIds.includes(verificationId)) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
        this.props.clearCurrentProject();
        //Close current open Interface
        let list = viewList.filter(item => ![RESULT, VERIFICATION].includes(item));
        this.props.changeViewList(list)
        // update selectedKey
        let newSelectedKey = selectedKeys.filter(k => ![`${VERIFICATION}-${verificationId}`, `${RESULT}-${verificationId}`].includes(k));
        this.props.changeTreeSelected(newSelectedKey);

        this.props.closeTabFooter();
        multiSetupResult && selectedKeys.includes(`${VERIFICATION_GROUP}-${multiSetupResult}`) && this.props._updateMultiInterfaceSetupStatus(multiSetupResult)
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
      }
    });
  }

  combineInterface = () => {
    const { simulateKeys, currentProjectId, viewList, layout, selectedKeys } = this.props;
    let verificationIds = simulateKeys.map(item => item.verificationId);
    combineInterfacePromise(verificationIds).then(res => {
      if (res && res.length) {
        const verificationId = res[0].verificationId;
        let list = [...viewList, VERIFICATION];
        list = [...new Set(list)];
        list = list.filter(item => ![RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
        if (layout === 3) {
          list = [VERIFICATION]
        }
        this.props.changeViewList(list);

        if (selectedKeys.length > 1) {
          let newKeys = [];
          selectedKeys.forEach((item, index) => {
            const [key] = strDelimited(item, "-");
            if (![RESULT, VERIFICATION, EXPERIMENTS_RESULT, RESULT, VERIFICATION_GROUP].includes(key)) {
              newKeys.push(item);
            }
          });
          newKeys.push(`${VERIFICATION}-${verificationId}`);
          this.props.changeTreeSelected([...newKeys]);
        } else {
          this.props.changeTreeSelected([`${VERIFICATION}-${verificationId}`])
        }

        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
        this.props._openPage(VERIFICATION, { verificationId });
      }
    })
  }

  showSierraResult = (id) => {
    const { verificationId, viewList, layout, currentProjectVerifications } = this.props;
    const verificationName = (currentProjectVerifications.find(item => item.id === id) || {}).name;
    if (id !== verificationId) {
      this.props.showResult('result', { verificationId: id, verificationName, getStatus: true });
    } else {
      this.props.showResult('result', { verificationId: id, verificationName });
    }
    let list = [...viewList, RESULT];
    list = [...new Set(list)];
    list = list.filter(item => ![VERIFICATION, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
    if (layout === 3) {
      list = [RESULT]
    }
    this.props.changeViewList(list);
    const { closeTabFooter, tabVisible } = this.props;
    if (tabVisible) {
      closeTabFooter();
    }
  }

  showLibraryFile = (item) => {
    this.setState({
      modelType: 'fileContent',
      modelName: item.fileName,
      editVisible: true,
      fileId: item.id,
      modelFileType: item.fileType,
      dataType: item.dataType,
      key: item.key,
      parentId: item.parentId
    });
  }

  closeContentModal = () => {
    this.setState({
      fileId: null,
      fileName: null,
      modelType: '',
      modelName: '',
      editVisible: false
    })
  }

  deleteVerificationCreate = (e, item) => {
    e.stopPropagation();
    const { currentProjectId, verificationId } = this.props;
    this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
  }

  deleteCreateProject = (e) => {
    const { currentProjectId, expandedKeys, verificationId } = this.props;
    let openProject = false;
    expandedKeys.forEach(item => {
      const [key] = strDelimited(item, "-");

      if (key === 'project') {
        openProject = true;
        return null;
      }
    })

    if (openProject) {
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
    } else {
      this.props.UpdateProjectMenu();
    }
  };

  SaveInterfaceName = (name, tag, item) => {
    const { currentProjectId, verificationId, verificationName, viewList } = this.props;
    if (!tag) {
      tag = ""
    }
    changeInterfaceName(item.id, name, tag).then(res => {
      if (item.id === verificationId && !viewList.includes('result')) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId, verificationName: name });
        this.props._openPage(VERIFICATION, { verificationId, rename: true });
      } else if (item.id === verificationId && viewList.includes('result')) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId, verificationName: name });
        this.props.showResult('result', { verificationId, verificationName: name, getStatus: true, rename: true });
      } else if (item.id !== verificationId) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId, verificationName });
      }
    })
  }

  SaveProjectName = (name, item) => {
    const { currentProjectId, verificationId, viewList } = this.props;
    changeProjectName(item.id, name).then(res => {
      if (verificationId && viewList.includes(VERIFICATION)) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId, verificationName: name });
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      }
    })
  }

  addIdentification = (item) => {
    this.props._changeIdentificationVisible(true)
    this.setState({
      createIdentificationInfo: { ...item }
    })
  }

  identificationCreate = () => {
    const { currentProjectId, currentProjectVerifications, identificationCreateMsg } = this.props;
    const { createIdentificationInfo } = this.state;
    return <CreateIdentification
      channelList={currentProjectVerifications}
      openProjectId={currentProjectId}
      data={createIdentificationInfo}
      closePanel={this.props._changeIdentificationVisible}
      identificationCreateMsg={identificationCreateMsg}
      createInterfaceIdentification={this.props._createInterfaceIdentification}
      updateIdentificationMassage={this.props._updateIdentificationMassage}
    />
  }

  importExportData = (item) => {
    this.setState({
      uploading: {
        key: item.key,
        status: this.state.uploading.status
      }
    })
    let modelType = 'importExportLibrary';
    if (item.dataType === PROJECTS) {
      modelType = 'importExportProject'
    }
    this.setState({
      editVisible: true,
      modelType: modelType,
      modelName: modelType,
      updateLibrary: {}
    });
  }

  updateSettingPCB = (item, e) => {
    e && e.stopPropagation();
    const { id, name } = item;
    if (item) {
      this.setState({
        settingPCB: { id, name },
        modelType: 'componentsSetting',
        editVisible: true
      })
    } else {
      this.setState({
        settingPCB: {},
        modelType: '',
        editVisible: false
      })
    }
  }

  closeSettingPCB = (updateStatus, prevInfo) => {
    /* const { settingPCB } = this.state;
    const { id } = settingPCB; */
    const { viewList } = this.props;
    const view = viewList.find(v => [VERIFICATION].includes(v));
    const _view = viewList.find(v => [VERIFICATION, MULTI_INTERFACE_SETUP].includes(v))
    if (view && updateStatus) {
      // The method is only called if the interface contains a modified pcb
      this.props.updateVerificationSetting(prevInfo)
    } else if (_view) {
      this.props._updateSetupAfterCloseSettings(_view);
    }
    this.closeContentModal()
  }

  render() {
    const { modelType, editVisible, uploading, partRepeaterVisible } = this.state;
    const { identificationCreateVisible } = this.props;
    return (
      <Fragment>
        <SierraTree
          addItem={this.addItem}
          delItem={this.delItem}
          editItem={this.editItem}
          createProject={this.createProject}
          openPCB={this.openPCB}
          createVerification={this.createVerification}
          createExperiments={this.createExperiments}
          openVerification={this.openVerification}
          openExperiment={this.openExperiment}
          changeSimulateCheck={this.changeSimulateCheck}
          startVerification={this.startVerification}
          showSierraResult={this.showSierraResult}
          projectNameChange={this.projectNameChange}
          VerificationNameChange={this.VerificationNameChange}
          showLibraryFile={this.showLibraryFile}
          deleteVerificationCreate={this.deleteVerificationCreate}
          deleteCreateProject={this.deleteCreateProject}
          SaveInterfaceName={this.SaveInterfaceName}
          copyProject={this.copyProject}
          SaveProjectName={this.SaveProjectName}
          createFolder={this.createFolder}
          renameFolder={this.renameFolder}
          updateLibraryItem={this.updateLibraryItem}
          showExperimentsResult={this.showExperimentsResult}
          addIdentification={this.addIdentification}
          importExportData={this.importExportData}
          updateSettingPCB={this.updateSettingPCB}
          combineInterface={this.combineInterface}
          openPreLayout={this.openPreLayout}
          uploading={uploading}
          showImportExportModel={this.showImportExportModel}
        />
        {editVisible && this.getPanel(modelType)}
        {partRepeaterVisible ? this.getPartPanel() : null}
        {identificationCreateVisible ? this.identificationCreate() : null}
      </Fragment>
    )
  }
};

const mapState = (state) => {
  const { SierraReducer: { project, sierra, simulationReducer,
    SierraUploadReducer: { uploadProjectId },
    library: { libraryTreeInfo, updateFileContent, isUpdateRepeaterFile },
    multiInterfaceSetup: { multiSetupGroup, multiSetupResult },
    sweep } } = state;
  const { treeItems, currentProjectId, currentProjectVerifications,
    verificationId, selectedKeys, layout, projectList, viewList } = project;
  const { simulateKeys } = simulationReducer;
  const { tabVisible, verificationName } = state.TabMonitorReducer;
  const { experimentInfo = {} } = sweep;
  const { identificationCreateVisible, identificationCreateMsg, closePartRepeater, updatePartRepeaterLogs } = sierra;
  return {
    treeItems,
    expandedKeys: sierra.expandedKeys,
    currentProjectId,
    currentProjectVerifications,
    simulateKeys,
    verificationId,
    tabVisible,
    selectedKeys,
    layout,
    projectList,
    viewList,
    verificationName,
    sierraInfo: sierra.sierraInfo,
    experimentsId: experimentInfo.id,
    uploadProjectId,
    identificationCreateVisible,
    identificationCreateMsg,
    refNetsLoading: sierra.refNetsLoading,
    libraryTreeInfo,
    updateSettingStatus: sierra.updateSettingStatus,
    updateFileContent,
    closePartRepeater,
    updatePartRepeaterLogs,
    isUpdateRepeaterFile,
    multiSetupGroup,
    multiSetupResult
  }
}

const mapDispatch = (dispatch) => ({
  _openPage(view, info) {
    dispatch(openPage(view, info));
  },
  clearCurrentProject(status) {
    dispatch(clearCurrentProject(status));
  },
  UpdateProjectMenu(obj) {
    dispatch(updateProjectMenu(obj));
  },
  openLibraryFolder(libraryId, key, update) {
    dispatch(openLibraryFolder(libraryId, key, update));
  },
  updateTrashMenu() {
    dispatch(updateTrashMenu());
  },
  expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId, projectName }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId, projectName }))
  },
  changeVerification(name) {
    dispatch(changeVerification(name));
  },
  openTabFooter() {
    dispatch(openTabFooter())
  },
  changeVerificationList(simulateKeys) {
    dispatch(changeVerificationList(simulateKeys))
  },
  startSierraVerification(verificationIds, currentVerificationId) {
    dispatch(startSierraVerification(verificationIds, currentVerificationId));
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  getCurrentLog(verificationId, verificationName) {
    dispatch(getCurrentLog(verificationId, verificationName))
  },
  showResult(view, info) {
    dispatch(showResult(view, info));
  },
  changeTreeSelected(selectedKeys) {
    dispatch(changeTreeSelected(selectedKeys))
  },
  changeUploadMes(msg) {
    dispatch(changeUploadMes(msg));
  },
  openProject(projectId) {
    dispatch(openProject(projectId));
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList));
  },
  existResult(exist) {
    dispatch(existResult(exist));
  },
  saveCurrentVerification(verificationIds, currentVerificationId) {
    dispatch(saveCurrentVerification(verificationIds, currentVerificationId))
  },
  updateInterfaceLibrary(libraryType, libraryFile) {
    dispatch(updateInterfaceLibrary(libraryType, libraryFile))
  },
  getLibraryTreeByType(type) {
    dispatch(getLibraryTreeByType(type))
  },
  _closeUploadProgressModal() {
    dispatch(closeUploadProgressModal())
  },
  _changeIdentificationVisible(visible) {
    dispatch(changeIdentificationVisible(visible))
  },
  _createInterfaceIdentification(info) {
    dispatch(createInterfaceIdentification(info))
  },
  _deletePCB(item) {
    dispatch(deletePCB(item))
  },
  afterImportLibrary() {
    dispatch(afterImportLibrary())
  },
  updateVerificationSetting(prevInfo) {
    dispatch(updateVerificationSetting(prevInfo))
  },
  _updateIdentificationMassage(msg) {
    dispatch(updateIdentificationMassage(msg))
  },
  _updateSetupAfterCloseSettings(view) {
    dispatch(updateSetupAfterCloseSettings(view))
  },
  _updateSetupAfterClosePartLibrary(libraryType) {
    dispatch(updateSetupAfterClosePartLibrary(libraryType))
  },
  _updateCompSettingsStatus(status) {
    dispatch(updateCompSettingsStatus(status))
  },
  _saveLibraryFile({ fileId, folderId, name, content, libraryType, partRepeaterVisible }) {
    dispatch(saveLibraryFileContent({ fileId, folderId, name, content, libraryType, partRepeaterVisible }))
  },
  _updateFileContent(isUpdate) {
    dispatch(updateFileContent(isUpdate))
  },
  _updatePartRepeater(addRepeaterParts) {
    dispatch(updatePartRepeater(addRepeaterParts))
  },
  _updatePartRepeaterLog(log) {
    dispatch(updatePartRepeaterLog(log))
  },
  _closePartRepeaterPanel(status) {
    dispatch(closePartRepeaterPanel(status))
  },
  _updateRepeaterFileStatus(status) {
    dispatch(updateRepeaterFileStatus(status))
  },
  _updateMultiInterfaceSetupStatus(group) {
    dispatch(updateMultiInterfaceSetupStatus(group))
  },
  _savePreLayoutTemplate(modelType, data) {
    dispatch(savePreLayoutTemplate(modelType, data))
  }
});

export default connect(mapState, mapDispatch)(SierraSider);