import { getPadstack, setPadstack } from "../../api/Design/design";
import apiProcess from "../../api/utility";

const padStackVersion = '1.0';

class PadStackDB {
  constructor() {
    this.padStack = new Map(); // designId - stackObj { padstacks: { editPadstack, globalPlating }, version }
  }

  async getStack(designId) {
    let data = this.padStack.get(designId);
    if (data) {
      return backendPadstackHandle(data);
    } else {
      try {
        data = await getPadStack(designId);
        if (data) {
          this.padStack.set(designId, data);
          return backendPadstackHandle(data)
        }
      } catch (error) {
        console.error(error);
        return { editPadstack: [], globalPlating: "100", getError: true };
      }
    }
  }

  saveStack(designId, stackObj) {
    try {
      const data = this.padStack.get(designId) || {};
      const { padstacks = {}, version = padStackVersion } = data;
      setPadStack(designId, { padstacks: { ...padstacks, ...stackObj }, version });
      this.padStack.set(designId, { padstacks: { ...padstacks, ...stackObj }, version });
    } catch (error) {
      console.error(error)
    }
  }
}

const PadStack = new PadStackDB();

export default PadStack;

function getPadStack(designId) {
  return apiProcess(getPadstack, designId);
}

function setPadStack(designId, stackObj) {
  return apiProcess(setPadstack, { designId, stackObj });
}

function backendPadstackHandle(data) {
  const { padstacks = {} } = data;
  const { editPadstack, globalPlating = "100" } = padstacks;
  if (!editPadstack) {
    return { editPadstack: [], globalPlating }
  }
  let _editPadstack = [];
  for (let padstack of editPadstack) {
    _editPadstack.push({ plating: '', ...padstack })
  }
  return { editPadstack: _editPadstack, globalPlating }
}

function frontendPadstackHandle(editPadstackTable) {
  return [...editPadstackTable]
}

export {
  frontendPadstackHandle
}