import NP from 'number-precision';

const lUnits = ["p", "n", "u"],
  rUnits = ["m", 'K', "M", "G", "u", 'U'];

/**
 * get library data  format
 * @param {object} data: {R:"",L:"",C:""}
 */
function changeLibraryFormat(data) {
  let RValue = "", LValue = "", CValue = "";
  let RUnit = 'Ω', LUnit = 'nH', CUnit = 'nF';
  if ((data.R && (typeof (data.R) === 'string')) || (typeof (data.R) === 'number')) {
    const Rmatch = data.R.toString().match(/[A-Za-z]$/ig);
    if (Rmatch) {
      RValue = data.R.slice(0, data.R.length - 1);
      const _RUnit = data.R.slice(-1);
      RUnit = rUnits.includes(_RUnit) ? _RUnit + "Ω" : "Ω";
    } else {
      RValue = data.R;
      RUnit = 'Ω';
    }
  }
  if ((data.L && (typeof (data.L) === 'string')) || (typeof (data.L) === 'number')) {
    const Lmatch = data.L.toString().match(/[A-Za-z]$/ig);
    if (Lmatch) {
      LValue = data.L.slice(0, data.L.length - 1);
      const _Unit = (data.L.slice(-1)).toLowerCase();
      LUnit = lUnits.includes(_Unit) ? _Unit + "H" : "nH";
    } else {
      LValue = NP.strip(NP.divide(data.L, 1e-9)).toString();
      LUnit = 'nH';
    }
  }
  if ((data.C && (typeof (data.C) === 'string')) || (typeof (data.C) === 'number')) {
    const Cmatch = data.C.toString().match(/[A-Za-z]$/ig);
    if (Cmatch) {
      CValue = data.C.slice(0, data.C.length - 1);
      CUnit = data.C.slice(-1).toLowerCase();
      CUnit = lUnits.includes(CUnit) ? CUnit + "F" : "nF";
    } else {
      CValue = data.C
      LUnit = 'nF';
    }
  }
  const libraryData = {
    R: RValue,
    L: LValue,
    C: CValue
  }
  return { libraryData, RUnit, LUnit, CUnit };
}

export { changeLibraryFormat };