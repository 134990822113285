import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Switch, Spin } from 'antd';
import { selectNets, voltageChange, closeNetsErrorMsg, findPowerDomainLoading, findVRMLoading, updatePDNInterface, updateIncludeExtended } from '../../store/pdn/action';
import { selectChange } from '../../../LayoutExplorer/store/FastPI/actionCreators';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import './index.css';

const PowerDomainColumns = [{
  title: 'Power Nets',
  dataIndex: 'powerNets',
  width: '40%'
}, {
  title: 'Reference Nets',
  dataIndex: 'referenceNets',
  width: '40%'
}, {
  title: 'Nominal Voltage',
  dataIndex: 'voltage',
  width: '20%'
}];
const REFERENCE = 'Reference', POWER = 'Power';
class PowerDomain extends Component {
  constructor(props) {
    super(props);

    PowerDomainColumns[0].render = (nets) => {
      return <span>{nets.join(', ')}</span>
    }

    PowerDomainColumns[1].render = (nets) => {
      return <span>{nets.join(', ')}</span>
    }

    PowerDomainColumns[0].onCell = (record) => {
      const options = this.props.nets;
      return {
        record,
        edit: 'aurora-select',
        options: options,
        dataIndex: "powerNets",
        selectMode: 'multiple',
        handleSave: (_record) => this.netSelection(_record, POWER),
        onFocus: () => this.netSelectFocus(record, POWER),
        onChange: (list, record, setFieldsValue) => this.changeSelected(list, record, setFieldsValue, POWER),
        clearSelected: (value, record, setFieldsValue) => this.clearSelected(value, record, setFieldsValue, POWER),
        ModeStatus: true,
        getPopupContainer: document.getElementById("pdn-content-main"),
        dropdownMenuClassName: 'pdn-select-dropdown-menu',
        loadingMsg: !options || !options.length ? 'Loading Nets...' : "",
        closeList: true
      };
    };

    PowerDomainColumns[1].onCell = (record) => {
      return {
        record,
        edit: 'aurora-select',
        options: this.props.nets,
        dataIndex: "referenceNets",
        selectMode: 'multiple',
        handleSave: (_record) => this.netSelection(_record, REFERENCE),
        onFocus: () => this.netSelectFocus(record, REFERENCE),
        onChange: (list, record, setFieldsValue) => this.changeSelected(list, record, setFieldsValue, REFERENCE),
        clearSelected: (value, record, setFieldsValue) => this.clearSelected(value, record, setFieldsValue, REFERENCE),
        ModeStatus: true,
        getPopupContainer: document.getElementById("pdn-content-main"),
        dropdownMenuClassName: 'pdn-select-dropdown-menu',
        loadingMsg: 'Loading Nets...',
        closeList: true
      };
    };

    PowerDomainColumns[2].render = (voltage) => {
      return voltage || voltage === 0 ? <span>{voltage}V</span> : null;
    }

    PowerDomainColumns[2].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: "voltage",
        handleSave: this.voltageSave
      }
    }
  };

  voltageSave = (record) => {
    this.props.saveVoltage(record.voltage);
  }

  netSelection = (record, type) => {
    const { selectedDesignIDs, pcbId } = this.props;
    let nets = [];
    if (type === REFERENCE) {
      nets = record.referenceNets;
    } else if (type === POWER) {
      nets = record.powerNets;
    };
    const _nets = nets.filter(net => this.props.nets.includes(net));
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ nets: [...nets] }, pcbId);
    };
    this.props.selectNets([{ powerType: type, nets: _nets }]);
  }

  netSelectFocus = (record, type) => {
    const { selectedDesignIDs, pcbId } = this.props;
    let nets = [];
    if (type === REFERENCE) {
      nets = record.referenceNets;
    } else if (type === POWER) {
      nets = record.powerNets;
    };
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ nets: [...nets] }, pcbId);
    }
  }

  changeSelected = (list, record, setFieldsValue, type) => {
    const { selectedDesignIDs, pcbId } = this.props;
    let nets = [];
    if (type === REFERENCE) {
      nets = record.referenceNets;
    } else if (type === POWER) {
      nets = record.powerNets;
    };
    if (list.length === 1 && nets.includes(list[0])) {
      nets = nets.filter(item => item !== list[0]);
    } else {
      nets = [...new Set([...nets, ...list])];
    }
    if (selectedDesignIDs.includes(pcbId)) {
      this.props._selectChange({ nets: [...nets] }, pcbId);
    };
    if (setFieldsValue) {
      const _type = type === REFERENCE ? 'referenceNets' : 'powerNets';
      setFieldsValue({ [_type]: nets });
    }
    this.props.selectNets([{ powerType: type, nets }]);
  }

  clearSelected = (value, record, setFieldsValue, type) => {
    let nets = [];
    if (type === REFERENCE) {
      nets = record.referenceNets;
    } else if (type === POWER) {
      nets = record.powerNets;
    };
    const newNets = nets.filter(item => item !== value);
    if (setFieldsValue) {
      const _type = type === REFERENCE ? 'referenceNets' : 'powerNets';
      setFieldsValue({ [_type]: newNets });
    }
    this.props.selectNets([{ powerType: type, nets: newNets }]);
  }


  getErrorMsg = () => {
    const { netsError } = this.props;
    let msg = [];
    if (netsError) {
      if (netsError.power >= 4) {
        msg.push({ type: 'power', msg: 'The number of power nets greater than or equal to 4.' });
      }
      if (netsError.reference >= 4) {
        msg.push({ type: 'reference', msg: 'The number of reference nets greater than or equal to 4.' });
      }
      if (netsError.power === 0 || netsError.reference === 0) {
        msg.push({ type: 'empty', msg: 'Cannot automatically recognize power domain.' });
      }
      if (netsError.repeat) {
        if (netsError.repeat.length > 1) {
          msg.push({ type: 'repeat', msg: <span>Nets <span className="pdn-powerdomain-error-net">{netsError.repeat.join(', ')}</span> are repeated.</span> });
        } else {
          msg.push({ type: 'repeat', msg: <span>Net <span className="pdn-powerdomain-error-net">{netsError.repeat[0]}</span> is repeated.</span> });
        }
      }
    }
    return msg.length ? <Tooltip title={<div className="pdn-powerdomain-error-tip">
      <span>Maybe using an incorrect SPIM library</span>
      <Tooltip title="Ignore" overlayClassName='aurora-tooltip'>
        <CloseCircleOutlined className="pdn-powerdomain-error-icon" onClick={this.closeErrorMsg} />
      </Tooltip>
      {/* <ul className="pdn-powerdomain-error-list">
        {msg.map(item => <li key={item.type}>- {item.msg}</li>)}
      </ul> */}
    </div>} overlayClassName='aurora-tooltip'>
      <ExclamationCircleOutlined className="pdn-powerdomain-error-msg" />
    </Tooltip> : null;
  }

  closeErrorMsg = () => {
    this.props._closeNetsErrorMsg();
  }

  changeIncludeExtended = (value) => {
    this.props._updatePDNInterface({ includeExtended: value });
    this.props._findPowerDomainLoading(true)
    this.props._findVRMLoading(true)
    setTimeout(() => {
      this.props._updateIncludeExtended(value);
    }, 300)
  }

  render() {
    const { dataSource, mainNetsLoading, pcbLoading, includeExtended, powerDomainFindLoading } = this.props;
    return (
      <Fragment>
        <Row>
          <Col span={24}>
            <span className="font-bold pdn-setup-title-color">Power Domain</span>
            {this.getErrorMsg()}
            <div className='pdn-include-extended-content'>
              <span className="pdn-include-extended-title">Include Extended Nets</span>
              <Tooltip title={(mainNetsLoading || pcbLoading) ? 'Loading PCB...' : ''} overlayClassName='aurora-tooltip'>
                <Switch
                  size="small"
                  className="aurora-switch-small"
                  checked={includeExtended ? true : false}
                  onChange={this.changeIncludeExtended}
                  disabled={(mainNetsLoading || pcbLoading) ? true : false}
                />
              </Tooltip>
            </div>
            <div className='space-10'>
              <Spin spinning={powerDomainFindLoading}>
                <EditableTable
                  rowKey={(record) => record.powerNets.join('-')}
                  columns={PowerDomainColumns}
                  size="small"
                  dataSource={dataSource}
                  className='pdn-powerdomain-table'
                />
              </Spin>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { PDNReducer: {
    project: { treeSelectedKeys },
    pdn: { netsError, powerDomainFindLoading, pdnInfo }
  } } = state;
  const pdnContent = pdnInfo ? pdnInfo.pdnContent : null
  const includeExtended = pdnContent && typeof (pdnContent.includeExtended) === 'boolean' ? pdnContent.includeExtended : false;
  const pcbId = pdnInfo && pdnInfo.designId;
  return {
    netsError,
    powerDomainFindLoading,
    includeExtended,
    pcbId,
    selectedDesignIDs: getSelectedDesignIDs(treeSelectedKeys),
  }
};


const mapDispatch = (dispatch) => ({
  selectNets(type, nets) {
    dispatch(selectNets(type, nets))
  },
  saveVoltage(voltage) {
    dispatch(voltageChange(voltage))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _closeNetsErrorMsg() {
    dispatch(closeNetsErrorMsg())
  },
  _findPowerDomainLoading(loading) {
    dispatch(findPowerDomainLoading(loading))
  },
  _updatePDNInterface(data) {
    dispatch(updatePDNInterface(data))
  },
  _updateIncludeExtended(includeExtended) {
    dispatch(updateIncludeExtended(includeExtended))
  },
  _findVRMLoading(loading) {
    dispatch(findVRMLoading(loading))
  }
})

export default connect(mapState, mapDispatch)(PowerDomain);