import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import SeaSimConfigPanel from '../SeasimConfigPanel';
import { saveSeaSimConfig, updateMultiComplianceConfig } from '../store/endToEndChannel/action';
import {
  getPCBSignalConnections,
} from '@/services/Andes_v2/endToEndChannel';
import {
  getEndToEndSeaSimPCB
} from '@/services/Andes_v2/seaSim';

class SeaSim extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      signals: [],
      components: [],
      selectedSignals: []
    };
  }

  componentDidMount = async () => {
    const { pcbConnections, connections } = this.props;
    const endToEndSignals = getPCBSignalConnections(pcbConnections, connections);
    const { components, signals, selectedSignals } = await getEndToEndSeaSimPCB(pcbConnections, connections, endToEndSignals);
    this.setState({ components, signals, selectedSignals });
  }

  render() {
    const { config, id } = this.props;

    const { components, signals, selectedSignals, } = this.state;
    return <SeaSimConfigPanel
      id={id}
      config={config}
      components={components}
      signals={signals}
      selectedSignals={selectedSignals}
      saveConfig={this.props._saveSeaSimConfig}
      closePanel={this.props.closePanel}
      updateConfig={this.props._updateMultiComplianceConfig}
      isEndToEnd={true}
    />
  }

}

const mapState = (state) => {
  const { AndesV2Reducer: { endToEndChannel: { endToEndChannelInfo } } } = state;
  const config = endToEndChannelInfo.config ? endToEndChannelInfo.config : {};
  const { pcbConnections, connections } = endToEndChannelInfo.content || {};

  return {
    id: endToEndChannelInfo.id,
    config,
    pcbConnections,
    connections
  }
};


const mapDispatch = (dispatch) => ({
  _saveSeaSimConfig(simulateId) {
    dispatch(saveSeaSimConfig(simulateId))
  },
  _updateMultiComplianceConfig(configObj) {
    dispatch(updateMultiComplianceConfig(configObj))
  }
})

export default connect(mapState, mapDispatch)(SeaSim)