import {
  getCascadeReportConfig,
  generateCascadeReport,
  getCascadeReportFile,
  getCascadeReportStatus,
  saveCascadeReportConfig
} from "../../api/Cascade/report";
import checkError from "../../api/checkError";
import apiProcess from "../../api/utility";


function getReportConfig(params) {
  return apiProcess(getCascadeReportConfig, params)
}

/**
 * Generate channel report
 * 2022/12/06
 * @param {string} projectId
 * @param {*} param
 * @returns
 */
function generateReport(projectId, param) {
  return apiProcess(generateCascadeReport, { projectId, param }, false, true)
};

/**
 * Get report file by format
 * 2022/12/06
 * @param {string} projectId
 * @param {*} param
 * @returns
 */
function getReportFileByFormat({ projectId, format, mime }) {
  return new Promise((resolve, reject) => {
    if (!projectId) {
      resolve(null);
      return;
    }
    getCascadeReportFile({ projectId, format }).then(res => {
      if (!res) {
        resolve(null);
        return;
      }
      resolve(`data:${mime};base64,` + btoa(
        new Uint8Array(res.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }, error => {
      resolve(null);
      checkError(error, false);
    })
  })
}

/**
 * Get report status
 * 2022/12/06
 * @param {string} projectId
 * @returns
 */
function getReportStatus(projectId) {
  return apiProcess(getCascadeReportStatus, projectId, false, true);
};

/**
 * save report config
 * 2022/12/09
 * @param {string} projectId
 * @param {Object} config - config
 * @returns
 */
function saveReportConfigPromise({ projectId, config }) {
  return apiProcess(saveCascadeReportConfig, { projectId, config });
}

export {
  getReportConfig,
  generateReport,
  getReportFileByFormat,
  getReportStatus,
  saveReportConfigPromise
}