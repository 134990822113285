import { CONTROLLER, MEMORY } from '../../PCBHelper';
import getIBISCharacterizationData from './helper/IBISResultHelper';

export default function getIBISCharacterization({ verificationSubId, verificationId, channelId, channelName, type, verificationName, fileName, pinIBISBuffers }) {
  return getIBISCharacterizationData({ verificationSubId, verificationId, channelId, channelName, type, verificationName, fileName, pinIBISBuffers });
};

function columnsData(type) {
  switch (type) {
    case 'signalType':
      return {
        title: 'Signal Type',
        dataIndex: 'signalType',
        key: 'signalType',
        width: '20%',
      }
    case 'modelName':
      return {
        title: 'IBIS Models',
        dataIndex: 'modelName',
        key: 'modelName',
        width: '25%',
      }
    case 'odt':
      return {
        title: 'ODT (ohm)',
        dataIndex: 'odt',
        key: 'odt',
        width: '20%',
      }
    case 'zout':
      return {
        title: 'Zout (ohm)',
        dataIndex: 'zout',
        key: 'zout',
        width: '15%',
      }
    case 'riseTime':
      return {
        title: 'Rise Time (ps)',
        dataIndex: 'riseTime',
        key: 'riseTime',
        width: '15%',
      }
    case 'fallTime':
      return {
        title: 'Fall Time (ps)',
        dataIndex: 'fallTime',
        key: 'fallTime',
        width: '15%',
      }
    default: return {}
  }
}

export function getIBISColumns(title) {
  let typeList = []
  if (title.includes('Drivers')) {
    typeList = ['signalType', 'modelName', 'zout', 'riseTime', 'fallTime']
  } else {
    typeList = ['signalType', 'modelName', 'odt']
  }
  const columns = typeList.map(item => { return columnsData(item) })
  return columns
}

export function isShowResultIBISInfo(interfaces) {
  const pinIbisBuffers = getSignalPinsBuffers(interfaces);
  return pinIbisBuffers && pinIbisBuffers.length;
}

export function getSignalPinsBuffers(interfaces) {
  let pinIbisBuffers = [];
  for (let info of interfaces) {
    if (!info.Content || !info.Content.Components || !info.Content.Components.length) {
      continue;
    }
    const icComps = info.Content.Components.filter(item => [CONTROLLER, MEMORY].includes(item.type));
    for (let comp of icComps) {
      for (let pinItem of comp.pins || []) {
        if (pinItem.model && pinItem.model.libType === "IBIS") {
          pinIbisBuffers.push(pinItem.model.modelName)
        }
      }
    }
  }
  return pinIbisBuffers;
}

function getIsIBISAMIInfoData(interfaces) {
  for (let info of interfaces) {
    if (!info.Content || !info.Content.Components || !info.Content.Components.length) {
      continue;
    }
    const icComps = info.Content.Components.filter(item => [CONTROLLER, MEMORY].includes(item.type));
    for (let comp of icComps) {
      for (let pinItem of comp.pins || []) {
        if (pinItem.model && pinItem.model.libType === "IBIS_AMI") {
          return true
        }
      }
    }
  }
  return false;
}

export {
  getIsIBISAMIInfoData
}