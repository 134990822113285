import {
  CloseCircleFilled,
  CloseOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Select, Tooltip } from 'antd';
import React, { Component, Fragment } from 'react';
import {
  getDisplayPinList,
  addConnectorPinsByComp,
  deleteConnectorComp,
  getConnectorChannelIndex,
  clearPortsInConnectorPins,
  getConnSelectComps,
  ConnectorPin
} from '@/services/helper/connectorHelper';
import PinPortRender from '@/components/PinPortRender';
import { TOUCHSTONE, SPICE, CONNECTOR_SPICE_SIERRA } from '@/constants/libraryConstants';
import { SIERRA } from '@/constants/pageType';
import TreeSelect from '@/components/TreeSelect';
import './index.css';

const leftIndex = 1, rightIndex = 2;

const { Option } = Select;
class ModelAssign extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nameDisplayBelow: props.product === SIERRA ? true : false,
    };
  }

  selectComp = (key, type, anotherType) => {
    if (!key) {
      this.deleteConnectionComp(type, anotherType);
      setTimeout(() => { this.resize() }, 100)
      return;
    }
    const newConnectorObj = addConnectorPinsByComp({
      key,
      type,
      anotherType,
      propsInfo: this.props,
      ConnectorPin: ConnectorPin
    })

    this.props.updateConnector(newConnectorObj);
    setTimeout(() => { this.resize() }, 100)
  }

  deleteConnectionComp = (type, anotherType) => {
    const newConnectorObj = deleteConnectorComp(type, anotherType, this.props)
    this.props.updateConnector(newConnectorObj);
  }

  addNewFileSelect = (e, type) => {
    let propsInfo = this.props;
    let cableModels = propsInfo.cableModels;
    if (type === "cable") {
      if (!cableModels.length) {
        cableModels.push({
          type: "",
          libraryId: "",
          file: ""
        })
      }
      cableModels.push({
        type: "",
        libraryId: "",
        file: ""
      })
    } else {
      const index = getConnectorChannelIndex(type);
      let prevModels = propsInfo[`connector${index}`].models || [];
      if (!prevModels.length) {
        prevModels = [{
          type: "",
          libraryId: "",
          file: ""
        }]
      }
      const files = [...prevModels, {
        type: "",
        libraryId: "",
        file: ""
      }];
      propsInfo[`connector${index}`].models = files;

    }

    this.props.updateConnector({
      connector1: propsInfo.connector1,
      connector2: propsInfo.connector2,
      cableModels
    });
  }

  displayErrorMsg = (fileName, type) => {
    this.timers && clearTimeout(this.timers);
    this.setState({
      errorMsg: {
        type,
        error: `${fileName} has been selected.`
      }
    });
    this.timers = setTimeout(() => {
      this.setState({
        errorMsg: null
      });
    }, 3000);
  }

  selectModel = (key, type, anotherType, modelIndex) => {
    let propsInfo = this.props;
    const index = getConnectorChannelIndex(type);
    const connectorFileList = propsInfo.connectorFileList;
    const model = connectorFileList.find(item => item.name === key);

    const prevModels = propsInfo[`connector${index}`].models || [];

    if (modelIndex > 0 && !prevModels[modelIndex]) {
      return;
    }
    //
    const sameModel = prevModels.find(item => key && item.file === key);
    if (sameModel && sameModel.type === TOUCHSTONE) {
      this.displayErrorMsg(sameModel.file, type);
      return;
    }
    const prevModel = prevModels[modelIndex] ? JSON.parse(JSON.stringify(prevModels[modelIndex])) : {
      type: "",
      libraryId: "",
      file: "",
    };
    let files = [...prevModels];
    let newFile = key ? {
      type: TOUCHSTONE,
      libraryId: model.id,
      file: model.name,
      subckt: ""
    } : {
      type: "",
      libraryId: "",
      file: "",
      subckt: ""
    };

    files[modelIndex] = newFile;

    propsInfo[`connector${index}`].models = files;

    if (prevModel && prevModel.libraryId) {
      //clear selected ports
      propsInfo[`connector${index}`].pins = clearPortsInConnectorPins(propsInfo[`connector${index}`].pins, "connector", prevModel.libraryId);
    }

    this.props.updateConnector({
      connector1: propsInfo.connector1,
      connector2: propsInfo.connector2
    });
    newFile && this.props.getFileParse([newFile]);
    setTimeout(() => { this.resize() }, 100)
  }

  selectCableModel = (key, type, anotherType, modelIndex) => {
    const { connector1, connector2, cableModels, cableFileList } = this.props;
    let _cableModels = [...cableModels];
    if (!cableModels[modelIndex] && modelIndex > 0) {
      return;
    }
    const model = cableFileList.find(item => key && item.name === key);

    const cableModel = model ? {
      libraryId: model.id,
      file: model.name,
      type: TOUCHSTONE,
      version: model.version,
      subckt: ""
    } : {
      libraryId: "",
      file: "",
      type: TOUCHSTONE,
      version: "",
      subckt: ""
    };

    const findModel = _cableModels.find(item => model && item.libraryId === cableModel.libraryId && cableModel.type === TOUCHSTONE);
    if (findModel) {
      this.displayErrorMsg(findModel.file, type);
      return;
    }
    const prevModel = _cableModels[modelIndex] ? JSON.parse(JSON.stringify(_cableModels[modelIndex])) : null;
    _cableModels[modelIndex] = cableModel;

    let _connector1 = { ...connector1 }, _connector2 = { ...connector2 };

    if (prevModel && prevModel.libraryId) {
      _connector1.pins = clearPortsInConnectorPins(connector1.pins, "cable", prevModel.libraryId);
      _connector2.pins = clearPortsInConnectorPins(connector2.pins, "cable", prevModel.libraryId);
    }
    this.props.updateConnector({
      cableModels: _cableModels,
      connector1: _connector1,
      connector2: _connector2
    });
    setTimeout(() => { this.resize() }, 100)
  }

  selectSubckt = (key, type, anotherType, modelIndex) => {
    let propsInfo = this.props;
    const index = getConnectorChannelIndex(type);
    if (!propsInfo[`connector${index}`].models[modelIndex]) {
      return;
    }
    if (propsInfo[`connector${index}`].models[modelIndex]['subckt']) {
      //clear selected ports
      propsInfo[`connector${index}`].pins = clearPortsInConnectorPins(propsInfo[`connector${index}`].pins, "connector", propsInfo[`connector${index}`].models[modelIndex].libraryId);
    }
    propsInfo[`connector${index}`].models[modelIndex]['subckt'] = key;

    this.props.updateConnector({
      connector1: propsInfo.connector1,
      connector2: propsInfo.connector2
    });
  }

  selectFileByTree = (file, fileIndex, type, modelIndex) => {
    let propsInfo = this.props;
    let cableModels = propsInfo.cableModels;
    let model = file ? {
      file: file.name,
      libraryId: file.id,
      type: file.type === CONNECTOR_SPICE_SIERRA ? SPICE : TOUCHSTONE,
      version: file.version
    } : {
      file: "",
      libraryId: "",
      type: "",
      version: "",
    };
    if (type === "cable") {
      model = {
        ...model,
        name: file ? file.name : "",
        id: file ? file.id : "",
      };
      if (modelIndex > 0 && !cableModels[modelIndex]) {
        return;
      }
      if (cableModels[modelIndex] && cableModels[modelIndex].libraryId) {
        propsInfo.connector1.pins = clearPortsInConnectorPins(propsInfo.connector1.pins, "cable", cableModels[modelIndex].libraryId);
        propsInfo.connector2.pins = clearPortsInConnectorPins(propsInfo.connector2.pins, "cable", cableModels[modelIndex].libraryId);
      }
      cableModels[modelIndex] = model;

      if (file) {
        cableModels[modelIndex] = model;
      } else {
        cableModels = [];
      }
    } else {
      const index = getConnectorChannelIndex(type);
      let prevModels = propsInfo[`connector${index}`].models;
      if ((modelIndex > 0 && !prevModels[modelIndex]) || !propsInfo[`connector${index}`].component) {
        // If the connector is not set, it is not allowed to add the model
        return;
      }

      if (prevModels[modelIndex] && prevModels[modelIndex].libraryId) {
        //clear selected ports
        propsInfo[`connector${index}`].pins = clearPortsInConnectorPins(propsInfo[`connector${index}`].pins, "connector", prevModels[modelIndex].libraryId);
      }

      if (file) {
        propsInfo[`connector${index}`].models[modelIndex] = model;
      } else {
        propsInfo[`connector${index}`].models = [];
      }
    }
    this.props.updateConnector({
      connector1: propsInfo.connector1,
      connector2: propsInfo.connector2,
      cableModels
    });
    model && this.props.getFileParse([model]);
    setTimeout(() => { this.resize() }, 100)
  }

  updateCableSubckt = (key, type, anotherType, modelIndex) => {
    const { connector1, connector2, cableModels } = this.props;
    let _cableModels = [...cableModels];
    if (!_cableModels[modelIndex]) {
      return;
    }

    _cableModels[modelIndex] = { ..._cableModels[modelIndex], subckt: key };
    let _connector1 = { ...connector1 }, _connector2 = { ...connector2 };
    _connector1.pins = clearPortsInConnectorPins(connector1.pins, "cable", _cableModels[modelIndex].libraryId);
    _connector2.pins = clearPortsInConnectorPins(connector2.pins, "cable", _cableModels[modelIndex].libraryId);

    this.props.updateConnector({
      cableModels: _cableModels,
      connector1: _connector1,
      connector2: _connector2
    });
    setTimeout(() => { this.resize() }, 100)
  }

  getConnectorModels = (connector) => {
    const models = connector.models && connector.models.length ? connector.models : [];
    return models;
  }

  deleteFileSelect = (e, type, modelIndex) => {
    let propsInfo = this.props;
    let cableModels = propsInfo.cableModels;
    if (type === "cable") {
      if (cableModels[modelIndex] && cableModels[modelIndex].libraryId) {
        propsInfo.connector1.pins = clearPortsInConnectorPins(propsInfo.connector1.pins, "cable", cableModels[modelIndex].libraryId);
        propsInfo.connector2.pins = clearPortsInConnectorPins(propsInfo.connector2.pins, "cable", cableModels[modelIndex].libraryId);
      }
      cableModels = cableModels.filter((item, index) => index !== modelIndex);
    } else {
      const index = getConnectorChannelIndex(type);
      let models = propsInfo[`connector${index}`].models || [];

      if (models[modelIndex] && models[modelIndex].libraryId) {
        //clear selected ports
        propsInfo[`connector${index}`].pins = clearPortsInConnectorPins(propsInfo[`connector${index}`].pins, "connector", models[modelIndex].libraryId);
      }
      models = models.filter((item, index) => index !== modelIndex);
      propsInfo[`connector${index}`].models = models;
    }

    this.props.updateConnector({
      cableModels,
      connector1: propsInfo.connector1,
      connector2: propsInfo.connector2
    });
  }

  getModelListLength = (models, type) => {
    const { errorMsg } = this.state;
    let length = 0;
    for (let model of models) {
      if (!model.type || model.type === TOUCHSTONE) {
        length += 1;
      }
      if (model.type === SPICE) {
        length += 2;
      }
    }
    if (errorMsg && errorMsg.type === type) {
      length += 1;
    }
    return length;
  }

  getModelBoxTop = (connector1Models, connector2Models, type) => {
    const { cableModels = [] } = this.props;
    if (type === 'cable') {
      let cableLength = this.getModelListLength(cableModels, "cable");
      return cableLength * 40;
    }
    let connector1Length = this.getModelListLength(connector1Models, "left"),
      connector2Length = this.getModelListLength(connector2Models, "right");
    const maxLength = Math.max(connector1Length, connector2Length) || 1;
    return maxLength * 58;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    this.setState = () => false;
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentDidUpdate = (prevProps) => {
    const { leftX } = this.state;
    if (!leftX && (document.getElementsByClassName('connection-pin-l-value-box')[0] || document.getElementsByClassName('connection-pin-r-value-box')[0])) {
      this.resize();
    }
  }

  resize = () => {
    const { CONNECTION_INDEX, product } = this.props;
    this.leftBox = document.getElementsByClassName('connection-left-channel')[0];
    this.leftBoxContent = document.getElementsByClassName(`connector-model-box-left-${CONNECTION_INDEX}`)[0];

    this.connectionSetupRef = document.getElementsByClassName('aurora-setup-border')[0];

    let leftX = 0, rightX = 0;

    this.rightBox = document.getElementsByClassName('connection-right-channel')[0];
    this.rightBoxContent = document.getElementsByClassName(`connector-model-box-right-${CONNECTION_INDEX}`)[0];

    if (this.leftBox) {
      const leftContentRect = this.leftBoxContent ? this.leftBoxContent.getBoundingClientRect() : null;
      const leftBoxRect = this.leftBox.getBoundingClientRect();

      leftX = leftContentRect ? (leftBoxRect.x + leftBoxRect.width) - (leftContentRect.x + leftContentRect.width) : 30;
    }

    if (this.rightBox) {
      const rightContentRect = this.rightBoxContent ? this.rightBoxContent.getBoundingClientRect() : null;
      const rightBoxRect = this.rightBox.getBoundingClientRect();
      rightX = rightContentRect ? rightContentRect.x - rightBoxRect.x : 30;
    }

    if (this.connectionSetupRef) {
      let width = this.connectionSetupRef.offsetWidth;
      if (width < 1470 || product === SIERRA) {
        this.setState({
          nameDisplayBelow: true
        });
      } else {
        this.setState({
          nameDisplayBelow: false
        })
      }
    }

    this.setState({
      leftX: leftX,
      rightX: rightX,
    })
  }

  connectorModelContent = (connectorIndex, _top) => {
    const { connector1, connector2, getAddIcon, CONNECTION_ID } = this.props;
    const connector1Models = this.getConnectorModels(connector1);
    const connector2Models = this.getConnectorModels(connector2);
    const top = this.getModelBoxTop(connector1Models, connector2Models) + 76;
    const maxPinsLength = connector1.pins.length > connector2.pins.length ? connector1.pins.length : connector2.pins.length;
    let channelHeight = maxPinsLength * 62;
    if (channelHeight < top) {
      channelHeight = top
    }

    const { leftX, rightX } = this.state
    /* let leftStyle = connectorIndex === leftIndex ? leftX : rightX; */
    let style = {
      height: channelHeight,
      top: _top + 3
    }
    if (connectorIndex === leftIndex) {
      style.right = leftX
    } else {
      style.left = rightX
    }
    return <Fragment>
      <div className='connector-model-content'>
        {getAddIcon && getAddIcon(connectorIndex, CONNECTION_ID)}
        <div className="connector-model-first-box" style={{ ...style }}>
          <div>
            {connectorIndex === leftIndex ?
              this.leftContent(connector1Models, top) :
              this.rightContent(connector2Models, top)
            }
          </div>
        </div> <div className="connector-model-box"></div>
      </div>
    </Fragment>
  }

  leftContent(connector1Models, top) {
    const {
      connector1, connector2, channel1Comps, portsObj,
      connectorFileList, pcbConnections, connections,
      CONNECTION_ID, connectorCompList = {} } = this.props;
    const { leftCompList } = connectorCompList;
    return <Fragment>
      <div className="connection-component-select-main" style={{ top: top }}>
        {this.getCompSelect({
          type: "left",
          anotherType: "right",
          selectType: "component",
          value: connector1.component,
          fileExist: true,
          list: leftCompList ? leftCompList.map(comp => ({ name: comp.name, disabled: (connector1.component !== comp.name && comp.connect.pcbId) ? true : false })) : getConnSelectComps({ channel1Comps, pcbConnections, connections, CONNECTION_ID }),
          placeholder: "Connector",
          className: "connection-component-left-select",
          selectChange: this.selectComp,
          antherValue: connector2.component
        })}
      </div>
      <div>
        {connector1Models.length ? connector1Models.map((model, index) => (
          <Fragment key={index}>
            {this.getCompSelect({
              modelIndex: index,
              type: "left",
              anotherType: "right",
              selectType: "model",
              value: model ? model.file : "None",
              libraryId: model ? model.libraryId : null,
              list: connectorFileList,
              defaultValue: "None",
              fileExist: model && model.libraryId ? this.props.judgeFileExsit(connectorFileList, model.libraryId) : true,
              placeholder: "Connector Model",
              className: "connection-component-left-select",
              selectChange: this.selectModel
            })}
            {model.type === SPICE && this.getCompSelect({
              modelIndex: index,
              type: "left",
              anotherType: "right",
              selectType: "subckt",
              value: model ? model.subckt : "",
              libraryId: model ? model.libraryId : null,
              list: portsObj[model.libraryId] ? portsObj[model.libraryId].map(subckt => ({ name: subckt.name })) : [],
              defaultValue: "",
              fileExist: true,
              placeholder: "Subckt",
              className: "connection-component-left-select",
              selectChange: this.selectSubckt
            })}
          </Fragment>
        ))
          :
          this.getCompSelect({
            modelIndex: 0,
            type: "left",
            anotherType: "right",
            selectType: "model",
            value: "None",
            libraryId: null,
            list: connectorFileList,
            defaultValue: "None",
            fileExist: true,
            placeholder: "Connector Model",
            className: "connection-component-left-select",
            selectChange: this.selectModel
          })
        }
        {/* {errorMsg && errorMsg.type === "left" ?
          <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null} */}
      </div>

    </Fragment>
  }
  rightContent(connector2Models, top) {
    const {
      connector1, connector2, channel2Comps, portsObj,
      connectorFileList, pcbConnections, connections,
      CONNECTION_ID, connectorCompList = {} } = this.props;
    const { rightCompList } = connectorCompList;
    return <Fragment>
      <div className="connection-component-select-main" style={{ top: top }}>
        {this.getCompSelect({
          type: "right",
          anotherType: "left",
          selectType: "component",
          value: connector2.component,
          fileExist: true,
          list: rightCompList ? rightCompList.map(comp => ({ name: comp.name, disabled: (connector2.component !== comp.name && comp.connect.pcbId) ? true : false })) : getConnSelectComps({ channel2Comps, pcbConnections, connections, CONNECTION_ID }),
          placeholder: "Connector",
          selectChange: this.selectComp,
          antherValue: connector1.component
        })}
      </div>
      <div>
        {connector2Models.length ? connector2Models.map((model, index) => (
          <Fragment key={index}>
            {this.getCompSelect({
              modelIndex: index,
              type: "right",
              anotherType: "left",
              selectType: "model",
              value: model ? model.file : "None",
              libraryId: model ? model.libraryId : null,
              defaultValue: "None",
              list: connectorFileList,
              fileExist: model && model.libraryId ? this.props.judgeFileExsit(connectorFileList, model.libraryId) : true,
              placeholder: "Connector Model",
              selectChange: this.selectModel
            })}
            {model.type === SPICE && this.getCompSelect({
              modelIndex: index,
              type: "right",
              anotherType: "left",
              selectType: "subckt",
              value: model ? model.subckt : "",
              libraryId: model ? model.libraryId : null,
              list: portsObj[model.libraryId] ? portsObj[model.libraryId].map(subckt => ({ name: subckt.name })) : [],
              defaultValue: "",
              fileExist: true,
              placeholder: "Subckt",
              className: "connection-component-left-select",
              selectChange: this.selectSubckt
            })}
          </Fragment>
        ))
          :
          this.getCompSelect({
            modelIndex: 0,
            type: "right",
            anotherType: "left",
            selectType: "model",
            value: "None",
            libraryId: null,
            defaultValue: "None",
            list: connectorFileList,
            fileExist: true,
            placeholder: "Connector Model",
            selectChange: this.selectModel
          })}
        {/* {errorMsg && errorMsg.type === "right" ?
          <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null} */}
      </div>
    </Fragment>
  }

  render() {
    const {
      connector1, connector2,
      channel1Comps, channel2Comps,
      maxWidth, maxHeight, cableModels,
      portsObj, signal_connections_map,
      connectorFileList, cableFileList,
      CONNECTION_INDEX, product, channel1, channel2 } = this.props;
    const { nameDisplayBelow } = this.state;

    const connector1Models = this.getConnectorModels(connector1);
    const connector2Models = this.getConnectorModels(connector2);
    let top = this.getModelBoxTop(connector1Models, connector2Models, 'cable') || 40;
    let connectorModelTop = this.getModelBoxTop(connector1Models, connector2Models) + 76;
    const maxPinsLength = connector1.pins.length > connector2.pins.length ? connector1.pins.length : connector2.pins.length;
    // if (errorMsg && errorMsg.type === "cable" && errorMsg.error) {
    //   top = top + 30;
    // }
    let channelHeight = maxPinsLength * 62 + top + 40;
    if (channelHeight < connectorModelTop + top) {
      channelHeight = connectorModelTop + top + 30
    }

    if (channelHeight < 175) { channelHeight = 175 }
    const channel_signal = product === SIERRA ? false : true;
    const _selectFileList = [...connector1Models, ...connector2Models, ...cableModels].map(model => { return model ? { id: model.libraryId, name: model.file, type: model.type } : {} }).filter(model => model && model.id);

    return (
      <div className='connection-model-assign-content'>
        <div className='connection-left-channel' style={{ height: channelHeight }}>
          {this.connectorModelContent(leftIndex, top)}
          {/* {errorMsg && errorMsg.type === "left" ?
            <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null} */}
        </div>
        <div className={cableModels && cableModels.length ? "connection-cable-model-content" : "connection-cable-model-content connection-cable-model-none-content"}>
          {cableModels.length ? cableModels.map((model, index) => (
            <Fragment key={index}>
              {this.getCompSelect({
                modelIndex: index,
                type: "cable",
                anotherType: "",
                selectType: "model",
                value: model ? model.file : "None",
                libraryId: model ? model.libraryId : null,
                list: cableFileList,
                defaultValue: "None",
                fileExist: model && model.libraryId ? this.props.judgeFileExsit(cableFileList, model.libraryId) : true,
                placeholder: "Cable Model",
                selectChange: this.selectCableModel
              })}
              {model && model.type === SPICE && this.getCompSelect({
                modelIndex: index,
                type: "cable",
                anotherType: "",
                selectType: "subckt",
                value: model ? model.subckt : "",
                libraryId: model ? model.libraryId : null,
                list: portsObj[model.libraryId] ? portsObj[model.libraryId].map(subckt => ({ name: subckt.name })) : [],
                defaultValue: "",
                fileExist: true,
                placeholder: "Subckt",
                className: "connection-component-left-select",
                selectChange: this.updateCableSubckt
              })}
            </Fragment>
          ))
            :
            this.getCompSelect({
              modelIndex: 0,
              type: "cable",
              anotherType: "",
              selectType: "model",
              value: "None",
              libraryId: null,
              list: cableFileList,
              defaultValue: "None",
              fileExist: true,
              placeholder: "Cable Model",
              selectChange: this.selectCableModel
            })
          }
          {/* {errorMsg && errorMsg.type === "cable" ?
            <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null} */}
        </div>
        <div className='connection-right-channel' style={{ height: channelHeight }}>
          {this.connectorModelContent(rightIndex, top)}
          {/* {errorMsg && errorMsg.type === "right" ?
            <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null} */}
        </div>
        <div>
          <PinPortRender
            top={top ? top : 72}
            displayType={'model'}
            connector1={connector1}
            connector2={connector2}
            updateConnector={this.props.updateConnector}
            pinList={getDisplayPinList({ connector1, channel1Comps, connector2, channel2Comps, signal_connections_map, channel_signal, product })}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            channel1DesignId={channel1.designId}
            channel2DesignId={channel2.designId}
            connector1Models={connector1Models}
            connector2Models={connector2Models}
            cableModels={cableModels}
            connectorFileList={connectorFileList}
            cableFileList={cableFileList}
            _selectFileList={_selectFileList}
            portsObj={portsObj}
            product={product}
            CONNECTION_INDEX={CONNECTION_INDEX}
            nameDisplayBelow={nameDisplayBelow}
          />
        </div>
      </div >
    )
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  getCompSelect = ({ modelIndex, value, defaultValue, list, type, placeholder, anotherType, className, selectChange, fileExist, libraryId, selectType, antherValue }) => {
    return (
      <div
        className={!fileExist ? "connection-component-select-content connection-file-error" : "connection-component-select-content"}>
        <div className="connection-component-select-title">
          {modelIndex > 0 && selectType === "model" ? null : <span>{placeholder}</span>}
          {modelIndex === 0 && this.props.product !== SIERRA ? <PlusCircleOutlined
            title={'Add new file'}
            className='connector-model-add-file-icon'
            onClick={(e) => { this.addNewFileSelect(e, type) }} /> : null}
        </div>

        {this._getCompSelect({ value, defaultValue, placeholder, selectChange, modelIndex, type, anotherType, className, list, libraryId, selectType, antherValue })}
        {!fileExist ? <Tooltip
          title={`File ${value} has been deleted, the model is invalid.`}
          overlayClassName='aurora-tooltip'
        ><QuestionCircleOutlined className='aurora-file-check-icon' onClick={(e) => this.spanClick(e)} />
        </Tooltip> : null}
        {modelIndex > 0 && selectType === "model" ?
          <CloseOutlined
            title={'Delete the file'}
            className='connector-model-close-file-icon'
            onClick={(e) => { this.deleteFileSelect(e, type, modelIndex); }} /> : null}
      </div>
    );
  }

  _getCompSelect = ({ value, defaultValue, placeholder, selectChange, modelIndex, type, anotherType, className, list, libraryId, selectType, antherValue }) => {
    const { product } = this.props;
    const _value = value || defaultValue;
    return product === SIERRA && selectType === 'model' ?
      <TreeSelect
        value={_value}
        onSelectItem={(file, fileIndex) => this.selectFileByTree(file, fileIndex, type, modelIndex)}
        size='small'
        showSearch
        popupClassName='connector-select-dropdown-menu'
        className={`aurora-select connection-single-select ${className}`}
        allowClear={{ clearIcon: <CloseCircleFilled onClick={(e) => this.selectFileByTree(null, null, type, modelIndex)} /> }}
        fileList={list}
        popupMatchSelectWidth={false}
        getPopupContainer={() => document.getElementById('root')}
        selected={libraryId}
      />
      : <Select
        value={_value === "None" ? "" : _value}
        placeholder={placeholder}
        allowClear
        showSearch
        onChange={(key) => selectChange(key, type, anotherType, modelIndex)}
        popupMatchSelectWidth={false}
        className={`aurora-select connection-single-select ${className}`}
        popupClassName="connector-select-dropdown-menu"
        getPopupContainer={() => document.getElementById('root')}
      >
        {list.map((item) =>
          <Option
            key={item.name}
            value={item.name}
            title={item.name}
            disabled={item.disabled}
          >{item.name}</Option>)}
      </Select>;
  }
}

export default ModelAssign;