import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ResultData } from '@/services/Sierra';
import { getTotalTraceCapacitance, reCalcCapacitanceByFreq, getTotalTraceCapacitanceTableData } from '../../../../services/Sierra/results';
import TotalCapacitance from '../components/totalCapacitance';
import { JUMPER, RLC_TYPES } from '../../../../services/PCBHelper';
import { CapacitancePlot } from '../../../../services/Sierra/results/totalTraceCapacitance/capacitancePlot';
import "../index.css";
import designConstructor from '../../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../../constants/designVendor';

class TotalTraceCapacitance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      designs: [],
      frequency: "",
      freqUnit: "M",
      loading: false,
      pathObj: {},
      isUpdateData: false,
      minFreq: 1e3
    }
    this.capacitancePlot = new CapacitancePlot();
  }

  componentDidMount = () => {
    const { verificationSubId, verificationId } = this.props;
    if (verificationId && verificationSubId) {
      this.getData()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { verificationSubId, verificationId } = this.props;
    if (verificationId !== prevProps.verificationId && verificationSubId) {
      this.getData()
    }
  }

  getData = async () => {
    const { verificationSubId, currentProjectId, verificationId } = this.props;
    let designs = [], _logSweepMin = 1e3;
    this.capacitancePlot = new CapacitancePlot();
    try {
      const res = await ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId);
      if (res) {
        let currentInterfaces = ResultData.getInterfaces() || [];
        designs = currentInterfaces.map(item => {
          const isPreLayout = designConstructor.getDesignVendor(item.PCBID) === PRE_LAYOUT;
          if (isPreLayout) {
            return null
          }
          const components = item.content && item.content.components ? item.content.components.filter(it => ![...RLC_TYPES, JUMPER].includes(it.type)).map(it => it.name) : []
          const itemLogSweepMin = item.content && item.content.extraction && !isNaN(item.content.extraction.logSweepMin) ? item.content.extraction.logSweepMin : 1e9
          if (itemLogSweepMin < _logSweepMin) {
            _logSweepMin = itemLogSweepMin
          };
          return {
            id: item.pcbId,
            name: item.pcb,
            components
          };
        }).filter(item => !!item)
      }
      if (designs.length > 1) {
        //MULTI PCB todo
        /* designs.push({
          id: "multi_pcb",
          name: "Multi-PCB"
        }) */
      }
      this.setState({
        designs,
        loading: true,
        logSweepMin: _logSweepMin,
        pathObj: {}
      }, () => {
        this.getCapacitance()
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false,
        isUpdateData: true,
        designs: [],
        pathObj: {}
      })
    }
  }

  getCapacitance = async () => {
    const { verificationId } = this.props;
    const { designs } = this.state;

    let _designs = [...designs || []], frequency = null, freqUnit = "", colorIndex = 0, pathObj = {};
    for (let item of _designs) {
      try {
        const capacitorJson = await getTotalTraceCapacitance(verificationId, item.id);
        const { tableData, hasSegment, curveContent, colorIndex: newColorIndex, } = getTotalTraceCapacitanceTableData({
          content: capacitorJson,
          pcbId: item.id,
          colorIndex,
          components: item.components,
          pathObj,
          verificationId
        });
        colorIndex = newColorIndex;
        item.tableData = tableData || [];
        const freq = capacitorJson.frequency || "";
        const unit = freq.match(/K|M|G/ig);
        frequency = freq.replace(/K|M|G/ig, "");
        freqUnit = unit && unit[0] ? unit[0] : "";
        item.capacitorJson = capacitorJson || {};
        item.curveContent = curveContent;
        item.hasSegment = hasSegment;
        item.isMulti = item.id === "multi_pcb";
      } catch (error) {
        console.error(error)
        continue;
      }
    }
    const { verificationId: _verificationId } = this.props;
    //loading slow -> result cover
    if (verificationId !== _verificationId) {
      return;
    }

    this.setState({
      designs,
      frequency,
      freqUnit,
      loading: false,
      pathObj,
      isUpdateData: true
    })
  }

  updateFrequency = async (value, isUnit) => {
    if (isUnit) {
      this.setState({
        frequency: value
      })
      return;
    }
    this.setState({
      frequency: value,
      loading: true
    }, async () => {
      await this.reCalcCapacitance();
    })
  }

  reCalcCapacitance = async () => {
    const { freqUnit, frequency } = this.state;
    const { verificationId } = this.props;
    try {
      await reCalcCapacitanceByFreq({ frequency: `${frequency}${freqUnit}`, verificationId });
      this.getCapacitance()
    } catch (error) {
      this.setState({
        loading: false
      })
    }
  }

  changeFreqUnit = (unit) => {
    this.setState({
      freqUnit: unit,
      /*    loading: true */
    }/* , async () => {
      await this.reCalcCapacitance();
    } */)
  }

  updateStatus = (isUpdateData) => {
    this.setState({
      isUpdateData
    })
  }

  updateDesignData = (designs) => {
    this.setState({
      designs
    })
  }

  render() {
    const { designs, frequency, freqUnit, loading, pathObj, isUpdateData, logSweepMin } = this.state;
    const { verificationId } = this.props;

    return <div className='sierra-trace-capacitance-content'>
      <TotalCapacitance
        designs={designs}
        frequency={frequency}
        freqUnit={freqUnit}
        loading={loading}
        verificationId={verificationId}
        pathObj={pathObj}
        isUpdateData={isUpdateData}
        logSweepMin={logSweepMin}
        capacitancePlot={this.capacitancePlot}
        updateFrequency={this.updateFrequency}
        changeFreqUnit={this.changeFreqUnit}
        updateStatus={this.updateStatus}
        updateDesignData={this.updateDesignData}
      />
    </div>
  }
}

const mapState = (state) => {
  const { project, resultReducer: { resultInfo } } = state.SierraReducer;
  const { currentProjectId } = project;
  const { verificationSubId, verificationId } = resultInfo;
  return {
    verificationId,
    currentProjectId,
    verificationSubId
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(TotalTraceCapacitance);