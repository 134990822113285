class ProjectVerifications {
  constructor() {
    this.verifications = new Map();//id:verificationId,value:{verificationObj}
    this.ProjectVers = new Map();//id:projectId, value:verificationList
    this.ProjectVerGroups = new Map();//id:projectId, value:verificationList includes group [{group,children}]
  }

  setProject = (projectId, verifications, verGroups) => {
    if (!projectId || !verifications) {
      return
    }
    this.ProjectVers.set(projectId, verifications);
    this.ProjectVerGroups.set(projectId, verGroups || []);
  }

  setVerification = (id, ver) => {
    this.verifications.set(id, ver);
  }

  getVer = (id) => {
    return this.verifications.get(id);
  }

  getVerGroups = (projectId, dataType) => {
    if (dataType) {
      return (this.ProjectVerGroups.get(projectId) || []).filter(item => item.dataType === dataType);
    }
    return this.ProjectVerGroups.get(projectId);
  }

  getVerifications = (projectId) => {
    return this.ProjectVers.get(projectId);
  }

  clearProject = (projectId) => {
    this.ProjectVers.delete(projectId);
    this.ProjectVerGroups.delete(projectId);
    const filters = Array.from(this.verifications.values()).filter(item => item.projectId === projectId);
    for (let item of filters) {
      this.verifications.delete(item.id)
    }
  }

  clearAll = () => {
    this.verifications = new Map();
    this.ProjectVers = new Map();
    this.ProjectVerGroups = new Map();
  }
}

const projectVerifications = new ProjectVerifications()
export default projectVerifications;