import React from 'react';
import { getDefaultName } from "../../helper/setDefaultName";
import _ from 'lodash';
import { strFormatChange } from '../../helper/stringHelper';

function getSelectedInterfaces({
  name,
  pcbInterfaceInfo,
  selectedSignalKeys,
  mergeAllSignal,
  connInterfaces,
  interfaceList,
  type
}) {

  if (type === "single") {
    return singleInterfaceCreate({
      name,
      pcbInterfaceInfo,
      mergeAllSignal
    })
  }
  let selectedInterfaces = [];

  if (interfaceList && interfaceList.length) {
    selectedInterfaces = JSON.parse(JSON.stringify(interfaceList))
    for (let info of selectedInterfaces) {
      for (let intItem of info.interfaces || []) {
        let newContent = [];
        intItem.content.forEach(item => {
          if (!newContent.find(it => _.isEqual(it, item))) {
            newContent.push(item)
          }
        }
        );
        intItem.content = newContent;
      }
    }
    return selectedInterfaces;
  } else if (connInterfaces && connInterfaces.length && selectedSignalKeys.length) {
    if (mergeAllSignal) {
      let interfaceObj = {};
      for (let _conn of connInterfaces) {

        if (!selectedSignalKeys.includes(_conn.index)) {
          continue;
        }
        for (let conn of _conn.dataList || []) {

          for (let pcbId of conn.pcbConns || []) {

            const signals = conn.signalList[pcbId] || [];
            const signal = signals[0] || {};
            const nets = [...signals.map(item => item.nets).flat(2)];
            if (!interfaceObj[pcbId]) {
              interfaceObj[pcbId] = {
                content: [{ name: conn.signalName, nets: [...nets] }],
                type: signal.type,
                interfaceName: conn.signalName || name || signal.type,
                designId: pcbId
              }
            } else {
              const newSignal = { name: conn.signalName, nets: [...nets] };
              if (!interfaceObj[pcbId].content.find(item => _.isEqual(item, newSignal))) {
                interfaceObj[pcbId].content.push({ name: conn.signalName, nets: [...nets] });
              }
            }
          }
        }
      }
      const interfaces = Object.keys(interfaceObj).map(item => interfaceObj[item]);
      let interfaceName = interfaces[0] ? interfaces[0].interfaceName : "";

      selectedInterfaces.push({
        interfaceName: name || interfaceName,
        interfaces,
        type: interfaces[0] ? interfaces[0].type : ""
      })
    } else {
      for (let conn of connInterfaces) {
        let interfaceObj = {};
        if (!selectedSignalKeys.includes(conn.index)) {
          continue;
        }
        const dataList = conn.dataList || [];
        for (let i = 0; i < dataList.length; i++) {
          const signalItem = dataList[i];
          if (!signalItem) {
            continue;
          }
          for (let pcbId of signalItem.pcbConns) {
            if (!signalItem.signalList) {
              continue;
            }
            const pcbSignals = signalItem.signalList[pcbId] || [];
            const signalName = signalItem.signalName;

            const _signal = pcbSignals[0] || {};
            const nets = pcbSignals.length ? [...pcbSignals.map(item => item.nets).flat(2)] : [];

            if (!interfaceObj[pcbId]) {
              interfaceObj[pcbId] = {
                content: [{ name: signalName, nets: [...nets] }],
                type: _signal.type,
                interfaceName: signalName || _signal.type,
                designId: pcbId
              }
            } else {
              const newSignal = { name: signalName, nets: [...nets] };
              if (!interfaceObj[pcbId].content.find(item => _.isEqual(item, newSignal))) {
                interfaceObj[pcbId].content.push({ name: signalName, nets: [...nets] });
              }
            }
          }
        }
        const interfaces = Object.keys(interfaceObj).map(item => interfaceObj[item]);
        let interfaceName = interfaces[0].interfaceName;

        selectedInterfaces.push({
          interfaceName: interfaceName,
          interfaces,
          type: interfaces[0].type
        })
      }
    }
    return selectedInterfaces;
  }
  return selectedInterfaces;
}

function singleInterfaceCreate({
  name,
  pcbInterfaceInfo,
  mergeAllSignal
}) {
  let selectedInterfaces = [];
  if (mergeAllSignal) {
    let interfaces = [], type = "", signalNames = [];
    for (let pcbId of Object.keys(pcbInterfaceInfo)) {
      let filterSelectedInterfaces = (pcbInterfaceInfo[pcbId].allInterfaceCompNetData || []).filter(item => (pcbInterfaceInfo[pcbId].selectKeys || []).includes(item.interfaceKey));
      let newInterface = {};

      for (let info of JSON.parse(JSON.stringify(filterSelectedInterfaces))) {
        //add first interface
        if (!Object.keys(newInterface).length) {
          newInterface = {
            type: info.type,
            interfaceName: info.name,
            designId: pcbId,
            content: []
          }
          type = !type ? info.type : type;
        }

        ///add signals
        for (let item of info.content) {
          item.name = item.signalMergeName;
          //get signal name
          if (signalNames.includes(item.signalMergeName)) {
            item.name = getDefaultName({
              nameList: signalNames,
              firstIndex: 1,
              key: "",
              delimiter: "_",
              defaultKey: item.signalMergeName
            })
          }
          signalNames.push(item.name);

          newInterface.content.push({ name: item.name, nets: [...item.nets] });
        }
      }
      interfaces.push(newInterface);
    }

    selectedInterfaces.push({
      interfaces,
      interfaceName: name || type,
      type
    })
  } else {

    for (let pcbId of Object.keys(pcbInterfaceInfo)) {
      selectedInterfaces.push(...(pcbInterfaceInfo[pcbId].allInterfaceCompNetData || []).filter(item => (pcbInterfaceInfo[pcbId].selectKeys || []).includes(item.interfaceKey)).map(item => {
        let signalNames = [], interfaceName = "";
        const content = item.content.map(it => {
          it.name = it.signalMergeName || it.nets[0];
          //get signal name
          if (signalNames.includes(it.name)) {
            it.name = getDefaultName({
              nameList: signalNames,
              firstIndex: 1,
              key: "",
              delimiter: "_",
              defaultKey: it.name
            })
          }
          signalNames.push(it.name);
          if (!interfaceName) {
            interfaceName = it.name;
          }
          return { name: it.name, nets: [...it.nets] }
        })
        return {
          interfaces: [{
            type: item.type,
            interfaceName,
            designId: pcbId,
            content
          }],
          type: item.type,
          interfaceName
        }
      }))
    }
  }
  return selectedInterfaces;
}

function getSearchAdvancedSetup({ pcbConfig, applySetupAll, pcbId }) {
  if (!applySetupAll || !pcbConfig) {
    return {};
  }
  const pcbIds = Object.keys(pcbConfig || {});
  if (!pcbIds.length) {
    return {};
  }
  const setup = pcbId && pcbConfig[pcbId] ? pcbConfig[pcbId] : pcbConfig[pcbIds[0]];

  return { type: setup.interfaceType, setting: { ...(setup.advancedConfig || {}) } }
}

function filterExistedSignals(signals, filterConns) {
  let filterSignals = [];
  for (let signal of signals) {
    const findSignal = filterConns.find(item => signal.nets.every(net => item.nets.includes(net)));
    if (!findSignal) {
      filterSignals.push(signal)
    }
  }
  return filterSignals;
}

const NEW_INTERFACE = 2;
function addSelectedSignalsToInterface({ interfaceList, interfaceName, pcbInterfaceInfo, channelList = [] }) {
  let _interfaceList = [...interfaceList], _pcbInterfaceInfo = { ...pcbInterfaceInfo };
  const interfaceNames = [...interfaceList.map(item => item.interfaceName), ...channelList.map(item => item.name)];
  const index = interfaceName === NEW_INTERFACE ? -1 : _interfaceList.findIndex(item => item.interfaceName === interfaceName);

  if (index < 0 && interfaceName !== NEW_INTERFACE) {
    return { _interfaceList, _pcbInterfaceInfo }
  }
  const interfaces = interfaceName === NEW_INTERFACE ? [] : _interfaceList[index].interfaces || [];

  const signalNames = interfaces.map(item => item.content).flat(2).map(item => item.name);
  let verType = null;

  for (let pcbId of Object.keys(_pcbInterfaceInfo)) {
    if (!_pcbInterfaceInfo[pcbId].selectKeys.length) {
      continue;
    }
    const selectKeys = [..._pcbInterfaceInfo[pcbId].selectKeys];
    const dataList = _pcbInterfaceInfo[pcbId].allInterfaceCompNetData.filter(item => selectKeys.includes(item.interfaceKey))
    _pcbInterfaceInfo[pcbId].selectKeys = [];
    if (!dataList.length) {
      continue;
    }
    const interIndex = interfaces.findIndex(item => item.designId === pcbId);
    const signals = dataList.map(item => item.content).flat(2);
    verType = !verType ? dataList[0].type : verType;
    if (interIndex < 0) {
      interfaces.push({
        content: [...signals.map(item => {
          let signalName = item.signalMergeName;
          if (signalNames.includes(signalName)) {
            signalName = getDefaultName({ nameList: signalNames, key: "", defaultKey: signalName, firstIndex: 1, delimiter: "_" });
          }
          signalNames.push(signalName)
          return {
            name: signalName,
            nets: [...item.nets]
          }
        })],
        designId: pcbId,
        interfaceName,
        type: dataList[0].type
      })
    } else {
      const filterSignals = filterExistedSignals(signals, interfaces[interIndex].content);
      interfaces[interIndex].content.push(...filterSignals.map(item => {
        let signalName = item.signalMergeName;
        if (signalNames.includes(signalName)) {
          signalName = getDefaultName({ nameList: signalNames, key: "", defaultKey: signalName, firstIndex: 1, delimiter: "_" });
        }
        signalNames.push(signalName)
        return {
          name: signalName,
          nets: [...item.nets]
        }
      }))
    }
  }
  if (interfaceName === NEW_INTERFACE) {
    let newInterfaceName = verType || "Interface";
    if (interfaceNames.includes(newInterfaceName)) {
      newInterfaceName = getDefaultName({ nameList: interfaceNames, key: "", defaultKey: newInterfaceName, firstIndex: 1 })
    }
    _interfaceList.push({
      type: verType,
      interfaceName: newInterfaceName,
      interfaces,
      signalNames: []
    })
  } else {
    _interfaceList[index].interfaces = interfaces;
  }

  return { _interfaceList, _pcbInterfaceInfo }
}

function addSelectedSignalsToConnTable({ connInterfaces, interfaceName, pcbInterfaceInfo }) {
  let _connInterfaces = [...connInterfaces], _pcbInterfaceInfo = { ...pcbInterfaceInfo };
  const index = _connInterfaces.find(item => item.interfaceName === interfaceName);
  if (index < 0) {
    return { _connInterfaces, _pcbInterfaceInfo }
  }

  const signalNames = _connInterfaces.map(item => item.signalName);

  for (let pcbId of Object.keys(_pcbInterfaceInfo)) {
    if (!_pcbInterfaceInfo[pcbId].selectKeys.length) {
      continue
    }
    const selectKeys = [..._pcbInterfaceInfo[pcbId].selectKeys];
    const dataList = _pcbInterfaceInfo[pcbId].allInterfaceCompNetData.filter(item => selectKeys.includes(item.interfaceKey))
    _pcbInterfaceInfo[pcbId].selectKeys = [];
    if (!dataList.length) {
      continue;
    }

    const signals = dataList.map(item => item.content.map(it => { return { ...it, interfaceKey: item.interfaceKey, type: item.type } })).flat(2);
    const filterConns = _connInterfaces.filter(item => !!item.signalList[pcbId]).map(item => item.signalList[pcbId]).flat(2);
    const filterSignals = filterExistedSignals(signals, filterConns);
    if (!filterSignals.length) {
      continue;
    }

    for (let signal of filterSignals) {
      let signalName = signal.signalMergeName;
      if (signalNames.includes(signalName)) {
        signalName = getDefaultName({ nameList: signalNames, key: "", defaultKey: signalName, firstIndex: 1, delimiter: "_" });
      }
      _connInterfaces.push({
        signalType: "add",
        allConnKeys: [],//not need
        index: null,//no need
        pcbConns: [pcbId],
        signal: signal.name,
        signalList: {
          [pcbId]: [{
            name: signal.name,
            signalMergeName: signal.signalMergeName,
            nets: [...signal.nets],
            type: signal.type,
            interfaceKey: signal.interfaceKey
          }]
        },
        signalName,
        signalNetName: signal.signalMergeName
      })
      signalNames.push(signalName)
    }
  }
  return { _connInterfaces, _pcbInterfaceInfo }
}

function getFilterIdentificationLog({
  searchKey,
  messageList
}) {
  let netSearchList = [], searchList = [], logList = [];
  const reg = new RegExp(`${strFormatChange(searchKey)}`, "ig");
  let index = 0;
  for (let log of messageList) {
    const matchLog = log.match(reg);
    index += 1;
    if (!matchLog) {
      logList.push(log);
      continue;
    }
    const matchStr = matchLog[0];
    const logArr = log.split('"');
    let netName = logArr[1] || "", diffNetName = "";
    if (log.match("differential nets")) {
      diffNetName = logArr[3] || ""
    }

    const logArrBySearch = log.split(matchStr);

    if (netName.match(reg) || diffNetName.match(reg)) {
      const searchEles = getSearchSpanList(logArrBySearch, matchLog, index);
      netSearchList.push(<span>{searchEles.map(item => item)}</span>)
    } else {
      const searchEles = getSearchSpanList(logArrBySearch, matchLog, index);
      searchList.push(<span>{searchEles.map(item => item)}</span>)
    }
  }
  return { searchList: [...netSearchList, ...searchList], logList }
}

function getSearchSpanList(logArrBySearch, matchLog, index) {
  let searchEles = [];
  for (let i = 0; i < logArrBySearch.length; i++) {
    let searchEle = <span key={`${index}_${i}`}>{logArrBySearch[i]}{matchLog[i] ? <font style={{ background: "#ff0" }}>{matchLog[i]}</font> : null}</span>
    searchEles.push(searchEle)
  }
  return searchEles
}

export {
  getSelectedInterfaces,
  getSearchAdvancedSetup,
  addSelectedSignalsToInterface,
  addSelectedSignalsToConnTable,
  getFilterIdentificationLog
}