class DesignChannels {
  constructor() {
    this.designs = new Map(); // key - designId, value - channels

    this.set = this.set.bind(this);
    this.getDesignIds = this.getDesignIds.bind(this);
    this.getChannelsByDesignId = this.getChannelsByDesignId.bind(this);
    this.delete = this.delete.bind(this);
    this.delChannel = this.delChannel.bind(this);
    this.clearCache = this.clearCache.bind(this);
  };

  set(designId, channels) {
    this.designs.set(designId, channels);
  };

  getDesignIds() {
    return this.designs.keys();
  };

  getChannelsByDesignId(designId) {
    return this.designs.get(designId);
  };

  delete(designId) {
    this.designs.delete(designId);
  }

  delChannel(designId, channelId) {
    let channelIds = this.designs.get(designId);
    channelIds = channelIds.filter(item => item !== channelId);
    this.designs.set(designId, channelIds);
  }

  clearCache() {
    this.designs = new Map();
  }
};

const designChannelsConstructor = new DesignChannels();
export default designChannelsConstructor;