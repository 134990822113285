import { getMergeChannelResultListPromise } from "../simulationCtrl";

class MergeChannelResultInfo {
  constructor() {
    this.pcbResultChannelInfo = new Map(); // key - pcbId/packageId, value - pcbChannelInfo
    this.packageResultChannelInfo = new Map();
  }

  setPCBInfo = (id, pdnInfo) => {
    this.pcbResultChannelInfo.set(id, pdnInfo)
  }

  setPackageInfo = (id, pdnInfo) => {
    this.packageResultChannelInfo.set(id, pdnInfo)
  }

  getPCBInfo = (id) => {
    return this.pcbResultChannelInfo.get(id)
  }


  getPackageInfo = (id) => {
    return this.packageResultChannelInfo.get(id)
  }

  getMergePCBChannelInfo = (id, verificationId, update = false) => {
    return new Promise((resolve, reject) => {
      const info = this.pcbResultChannelInfo.get(id)
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getMergeChannelResultListPromise({ verificationId: verificationId, category: "verification_pcb_json" }).then(res => {
          if (res) {
            this.setPCBInfo(id, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  getMergePackageChannelInfo = (id, verificationId, update = false) => {
    return new Promise((resolve, reject) => {
      const info = this.packageResultChannelInfo.get(id)
      if (info && !update) {
        resolve(JSON.parse(JSON.stringify(info)));
      } else {
        getMergeChannelResultListPromise({ verificationId: verificationId, category: "verification_pkg_json" }).then(res => {
          if (res) {
            this.setPackageInfo(id, { ...res })
            resolve(res);
          } else {
            resolve(null);
          }
        }, error => {
          console.error(error);
          resolve(null);
        })
      }
    })
  }

  getMergeInfo = async (id, verificationId, update) => {
    const pcbInfo = await this.getMergePCBChannelInfo(id, verificationId, update);
    const packageInfo = await this.getMergePackageChannelInfo(id, verificationId, update);
    return { pcbInfo, packageInfo }
  }

  clearInfo = () => {
    this.packageResultChannelInfo = new Map();
    this.pcbResultChannelInfo = new Map();
  }
}

const MergeChannelResultInfoConstructor = new MergeChannelResultInfo();
export default MergeChannelResultInfoConstructor;