import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { closePanel } from '../store/actionCreators';
import Interfaces from './Setting/Interfaces/Interfaces';
import InterfacesFooter from './Setting/Interfaces/InterfacesFooter';
import InterfaceConfig from './Setting/InterfaceConfig/InterfaceConfig';
import InterfaceFooter from './Setting/InterfaceConfig/InterfaceFooter';
import { updateView } from './store/actionCreators';
import './index.css';

const INTERFACES = 0;
const INTERFACE_CREATE = 1;

const Serdes = (props) => {
  const { panelType, view, closePanel, search, selectedComps, selectedNets, defaultTop, defaultLeft } = props;

  let footer, content, title;
  if (view === INTERFACES) {
    title = 'PCIe Interfaces';
    content = <Interfaces />;
    footer = <InterfacesFooter />;
  } else if (view === INTERFACE_CREATE) {
    title = 'PCIe Interface Configuration';
    content = <InterfaceConfig />
    footer = <InterfaceFooter />
  }
  let className = '';
  if (search && search.type === 'signal' && (Object.keys(selectedComps).length > 0 || Object.keys(selectedNets).length > 0)) {
    className = 'change-opacity'
  }

  return (
    <Panel
      title={title}
      className={className}
      onCancel={() => closePanel(view)}
      draggable
      footer={footer}
      left={defaultLeft}
      top={defaultTop}
    >
      {content}
    </Panel>
  )
}

const mapState = (state) => {
  const { panelType, search, selectedComps, selectedNets } = state.default;
  const { view } = state.explorerSerdes;
  const { defaultTop, defaultLeft } = state.PanelStatus;
  return { panelType, view, search, selectedComps, selectedNets, defaultTop, defaultLeft };
}


const mapDispatch = (dispatch) => ({
  closePanel(view) {
    if (view > 0) {
      dispatch(updateView(view - 1));
    } else {
      dispatch(closePanel());
    }
  }
})

export default connect(mapState, mapDispatch)(Serdes);