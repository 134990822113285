import React from 'react';
import dayjs from 'dayjs';
import LayoutData from '@/services/data/LayoutData';

function timeReplace(log) {
  let _log = '';
  let logList = log.split(/[(\r\n)\r\n]+/);

  for (let i = 0; i < logList.length; i++) {
    let item = logList[i];
    if (item.search('Pre-process End') !== -1) {
      break;
    }
    let reg = /(\d{2})-(\d{1,2})-(\d{1,2}) (\d{2}):(\d{2}):(\d{2})/;
    let time = item.match(reg);
    if (time) {
      let logTime = time[0];
      logTime = logTime.replace(/[ ]/g, "T")
      //TODO
      let newTime = new Date('20' + logTime.toString() + '+0000');
      newTime.setUTCSeconds(newTime.getUTCSeconds());
      let currentTime = dayjs(newTime.toUTCString()).format('YY-MM-DD HH:mm:ss');
      item = item.replace(time[0], currentTime);
      logList[i] = item;
    }
  }
  _log = logList.join('\n');
  return _log;
}

function stackupDataProcess(stackup) {
  let metalList = [], dielectricList = [], typeList = [];
  if (Array.isArray(stackup)) {
    stackup.forEach(item => {
      if (item.mItemValues) {
        let values = item.mItemValues.mValues;
        if (item.mName === 'Metal') {
          let [name = '', thickness = null, conductivity = null] = values;
          metalList.push({ name, thickness, conductivity });
        } else if (item.mName === 'Dielectric') {
          let [name = '', thickness = null, constant = null, lossTangent = null] = values;
          dielectricList.push({ name, thickness, constant, lossTangent });
        }
        typeList.push(item.mName);
      } else {
        let layerType = item.type;
        if (layerType === 'Metal') {
          const { name, thickness, conductivity } = item;
          metalList.push({ name, thickness, conductivity });
        } else if (layerType === 'Dielectric') {
          const { name, thickness, delta, epsilon } = item;
          dielectricList.push({ name, thickness, constant: epsilon, lossTangent: delta });
        }
        typeList.push(layerType);
      }
    });
  }
  return { metalList, dielectricList, typeList };
}

function stackupCheck(stackup) {
  let error = [];
  const errorStyle1 = { margin: 0, marginBottom: 8 }, errorStyle2 = { marginLeft: 10 };
  if (stackup && stackup.length) {
    let mEmpty = [], dEmpty = [],
      mThicknessList = [], dThicknessList = [],
      conductivityList = [], lossTangentList = [], constantList = [];
    let { metalList, dielectricList, typeList } = stackupDataProcess(stackup);
    //error check
    metalList.forEach(item => {
      let { name, thickness, conductivity } = item;
      if (!thickness && thickness !== 0) {
        mEmpty.push({ name, value: 'Thickness' });
      } else if (thickness <= 0) {//range >0
        mThicknessList.push(name);
      }

      if (!conductivity && conductivity !== 0) {
        mEmpty.push({ name, value: 'Conductivity' });
      } else if (conductivity < 1.0e5 || conductivity > 1.0e10) { //range 1.0e5 ~ 1.0e10
        conductivityList.push(name);
      }
    });

    dielectricList.forEach(item => {
      let { name, thickness, constant, lossTangent } = item;
      if (!thickness && thickness !== 0) {
        dEmpty.push({ name, value: 'Thickness' });
      } else if (thickness <= 0) {//range >0
        dThicknessList.push(name);
      }

      if (!constant && constant !== 0) {
        dEmpty.push({ name, value: 'Dielectric Constant' })
      } else if (constant < 1 || constant > 10) {//range 1.0 ~ 10.0
        constantList.push(name)
      }

      if (!lossTangent && lossTangent !== 0) {
        dEmpty.push({ name, value: 'Loss Tangent' })
      } else if (lossTangent < 0 || lossTangent > 0.1) {//range 0 ~ 0.1
        lossTangentList.push(name)
      }
    })

    //output
    if (mEmpty.length > 0 || dEmpty.length > 0) {
      error.push(<div style={errorStyle1}>
        Value cannot be empty.
        {mEmpty.length > 0 ?
          <div style={errorStyle2}>
            <span className="font-bold">Metal: </span>
            ({mEmpty.map((item, index) => (
              <span key={`${item.name}-${item.value}`}>{item.name}-{item.value}{mEmpty.length - 1 === index ? '' : ', '}</span>
            ))})
          </div> : null}
        {dEmpty.length > 0 ?
          <div style={errorStyle2}>
            <span className="font-bold">Dielectric: </span>
            ({dEmpty.map((item, index) => (
              <span key={`${item.name}-${item.value}`}>{item.name}-{item.value}{dEmpty.length - 1 === index ? '' : ', '}</span>
            ))})
          </div> : null}
      </div>);
    }

    if (mThicknessList.length > 0 || dThicknessList.length > 0) {
      error.push(<div style={errorStyle1}>
        Thickness should be greater than 0.
        {mThicknessList.length > 0 ?
          <div style={errorStyle2}>
            <span className="font-bold">Metal: </span>
            ({mThicknessList.join(', ')})
          </div> : null}
        {dThicknessList.length > 0 ?
          <div style={errorStyle2}>
            <span className="font-bold">Dielectric: </span>
            ({dThicknessList.join(', ')})
          </div> : null}
      </div>);
    }

    if (conductivityList.length > 0) {
      error.push(<div style={errorStyle1}>
        Conductivity should be between 1.0e5 ~ 1.0e10.
        <div style={errorStyle2}>
          <span className="font-bold">Metal: </span>
          ({conductivityList.join(', ')})
        </div>
      </div>);
    }

    if (constantList.length > 0) {
      error.push(<div style={errorStyle1}>
        Constant should be between 2.0 ~ 10.0.
        <div style={errorStyle2}>
          <span className="font-bold">Dielectric: </span>
          ({constantList.join(', ')})
        </div>
      </div>);
    }

    if (lossTangentList.length > 0) {
      error.push(<div style={errorStyle1}>
        Loss Tangent should be between 0 ~ 0.1.
        <div style={errorStyle2}>
          <span className="font-bold">Dielectric: </span>
          ({lossTangentList.join(', ')})
        </div>
      </div>);
    }

    for (let layerIndex = 0; layerIndex < typeList.length - 1; layerIndex++) {
      if (typeList[layerIndex] === 'Metal' && typeList[layerIndex + 1] === 'Metal') {
        error.push(<div style={errorStyle1}>
          At least one dielectric layer is required between the metal layers.
        </div>);
        break;
      }
    }
  } else {
    const errorMsg = !stackup ? 'Stackup data not exist.' : 'Stackup is missing layers. The Aurora platform will automatically generate layers. Please modify it in the Stackup panel.';
    error.push(<div style={errorStyle1}>{errorMsg}</div>)
  }
  return error.length === 0 ? null : { error };
}

//Solve floating point errors
//num type must be 'number'
function numConversion(num, precision = 12) {
  return parseFloat(num.toPrecision(precision));
}

const ErrorMsg = ['value cannot be empty!',
  'value cannot contain spaces!',
  'value must be a number!'
];
const capitalErrorMsg = ['Value cannot be empty!',
  'Value cannot contain spaces!',
  'Value must be a number!'
];
function _numberCheckIndex(num) {
  if (typeof (num) === 'string') {
    if (!num) {
      return 0;
    } else if (num.indexOf(" ") !== -1 && !isNaN(num)) {
      return 1;
    } else if (isNaN(num)) {
      return 2;
    }
  } else if (typeof (num) === 'number') {
    if (isNaN(num)) {
      return 2;
    }
  } else {
    return 2;
  };
  return -1;
};

//Check if it is a number.
function numberCheck(num, capital) {
  const index = _numberCheckIndex(num);
  if (index > -1) {
    return capital ? capitalErrorMsg[index] : ErrorMsg[index];
  } else {
    return null;
  }
}

/**
 *
 *
 * @export
 * @param {*} prevUnit   //Original unit
 * @param {*} unit    //Modified unit
 * @param {*} scale
 */
function unitConversion(unit, prevUnit, scale = 1e3) {
  return Math.pow(scale, prevUnit - unit);
}

// K = 1e3, M = 1e6, G = 1e9, p = 1e-12, n = 1e-9, u = 1e-6, m = 1e-3
// Support 2K5, 2E5, 2e-5, 2.5[K, M, G, p, n, u, m], 0.1UF -> 0.1u
// Unsupport R200K and Other
// - TEST 
// console.log(checkRLCValue(value));
// const value = 3 + 'K' + 88 + 'R' || 3 + 'K' + 3 || 1 + 'K' || 0 || 5 + 'UF' || 3e5 || 4e-5 + 'K' || 4e-5 + 'm' || 6 + 'P' || 8 + 'U'||'s/\[d.4e-5K'
// result = 3.88K || 3.3K || 1K || 0 || 5u || 300000 || 0.00004K || 0.00004m || 6p || 8u || 0.00004K
// const value = 4.3e5K || 4.3e+5K || 4.3e-5K || 0.03e+5K ||   0.04e4m ||   0.2 4e4m || s/\[d.45K || s/\[d0.45K
// result = 430000K || 430000K || 0.000043K || 3000K || 400m || 0.2 || 45K || 0.45K
const UNIT = ['K', 'k', 'M', 'G', 'g', 'P', 'p', 'N', 'n', 'U', 'u', 'm', "R", "r", "V", "v"];
const removeUnit = ["R", "r", "V", "v"];
/* const values = ['4.3e5K', "sewdfg", undefined, "undefined", false, true, [1, 2, 3], { value: "1K" }, "22.K", "1.00k", 22, 1e3, "2e2K4", "s/\[d0.45K", ".4k", "1.2meg", " 2MEG", '3   Meg', "10  k"];
const results = values.map(item => {
  console.log(checkRLCValue(item))
  return checkRLCValue(item);
})
console.log(results) */
//results = ['430000K', '0', '0', '0', '0', '0', '0', '0', 22, '1K', '22', '1000', '200.4K', '0.45K',  '0.4K', '1.2Meg', '2Meg', '3Meg', '10K'];

function checkRLCValue(rlc) {
  if (!rlc) {
    return "0";
  }

  if (typeof (rlc) !== 'string' && typeof (rlc) !== 'number') {
    return "0";
  }

  if (!String(rlc).match(/^[0-9]\d*(\.\d+)?[epk](\-|\+)?\d+|^[0-9]\d*(\.\d+)?([UPNKMGΩHF]*)/ig)) {
    return "0";
  }

  const value = rlc.toString();
  //Matches the first digit in the string
  let firstNum = getNum(value);

  //Find the index position of the first number
  const firstNumIndex = value.indexOf(firstNum);
  //Truncate the string from the position of the first digit
  let numStr = value.slice(firstNumIndex);
  //Remove all spaces
  numStr = numStr.replace(/\s/ig, '');

  //Check if the prefix is "."
  let prevStr = value.slice(firstNumIndex - 1, firstNumIndex);
  //Remove spaces
  prevStr = prevStr.replace(/\s/ig, '');

  if (prevStr === '.') {
    numStr = `0${prevStr}${numStr}`
  }

  if (!isNaN(parseFloat(numStr))) {
    let num = numStr;
    // num = parseFloat(numStr).toString();
    // check float number
    // const findNum = /^[0-9]+\.[0-9]+/gi;

    //Match numbers in a string(Including integers, floating point numbers, scientific notation)
    const findNum = /^[0-9]\d*(\.\d+)?e(\-|\+)?\d+|^[0-9]\d*(\.\d+)?/gi;
    //Match the scientific notation digits in the string
    //const findNum1 = /^[0-9]\d*e(\-|\+)?\d+/gi;

    const numMatch = numStr.match(findNum);

    if (numMatch) {
      num = numMatch[0];

      if (num.length === numStr.length) {
        if (parseFloat(num) === Infinity || parseFloat(num) === -Infinity) {
          //4e5 => 40000
          return "0";
        } else {
          return parseFloat(num).toString();
        }
      }
    } else {
      num = parseFloat(numStr).toString();

      //.4K
      /* if (prevStr === '.') {
        let str_new = numStr.slice(1);
        const findStr_new = /[^0-9]/ig;
        const strMatch = str_new.match(findStr_new);
        let new_str = strMatch.join("")
        str = new_str;
      } else { */
      // str = numStr.slice(num.length, numStr.length);
      /*  } */
    }

    //4e4m5 => m5
    //4.4K  => K
    let str = numStr.slice(num.length, numStr.length);

    if (parseFloat(num) === Infinity || parseFloat(num) === -Infinity) {
      //4e5 => 40000
      num = "0";
    } else {
      num = parseFloat(num).toString();
    }

    if (str.length === 1) {
      const unitIndex = UNIT.indexOf(str);
      if (unitIndex > -1) {
        let unit = null;
        if (unitIndex > 4) {
          unit = str.toLowerCase();
        } else {
          unit = str.toUpperCase();
        };
        //51R / 51r ==> 51
        if (removeUnit.includes(unit)) {
          unit = "";
        }
        return num + unit;
      } else {
        return num;
      }
    } else if (str.length > 1) {
      if (str.toLowerCase() === 'meg') {
        return num + "Meg";
      }
      const firstStr = str.slice(0, 1);
      const _unitIndex = UNIT.indexOf(firstStr);

      if (_unitIndex > -1) {
        // test 2k5 -> 2.5K
        //test 4e4K5 => 40000.5K
        const res = new RegExp("^[0-9]+" + firstStr + "[0-9]+", "ig");

        //const result = numStr.match(res);
        // num=40000,str=m5   => 40000m5
        //num=4.12,str=K  => 4.12K
        const result = `${num}${str}`.match(res);
        let unit;
        if (_unitIndex > 4) {
          unit = firstStr.toLowerCase();
        } else {
          unit = firstStr.toUpperCase();
        };
        //51R / 51r ==> 51
        if (removeUnit.includes(firstStr)) {
          unit = "";
        }
        if (result) {
          return (parseFloat(result[0].replace(firstStr, '.'))).toString() + unit;
        } else {
          return num + unit;
        }
      } else {
        return num;
      }
    } else {
      return num;
    }
  } else {
    return '0';
  }
}

function getNum(str) {
  //Matches the first digit in the string
  var pattern = new RegExp("[0-9]+");
  var num = str.match(pattern);
  return num;
}

//console.log(checkRLCValueFormat({ value: '0.K' }))
// const value = 22.K || 22.0K || 4.3e-5K || 0.03e+5K ||   0.04e4m ||   0.2 4e4m || s/\[d.45K || 0.100K || 0.00K || 0.K || 0.34Meg
// result = false || true || true || true || false || false || false || true || true || false || true
const _UNIT = ['PF', 'NF', 'UF', 'PH', 'NH', 'UH', "MEG"];
/* const values = ['4.3e5K', "sewdfg", undefined, "undefined", false, true, [1, 2, 3], { value: "1K" }, "22.K", "1.00k", 22, 1e3, "2e2K4", "s/\[d0.45K"];
const results = values.map(item => {
  return checkRLCValueFormat(item);
})
console.log(results) */
//results = [ true, false, false,  false,  false,  false,  false,  false,  false,  true,  true,  true,  false,  false ]
/* console.log(checkRLCValueFormat("0u", "Cap")) */
function checkRLCValueFormat(rlc, type) {

  if (typeof (rlc) !== 'string' && typeof (rlc) !== 'number') {
    return false;
  }

  if (typeof (rlc) === 'string' && !rlc) {
    return false;
  }

  const value = rlc.toString();
  if (!isNaN(parseFloat(value))) {
    let num = parseFloat(value).toString();

    ////Match numbers in a string(Including integers, floating point numbers, scientific notation)
    const findNum = /^[0-9]\d*(\.\d+)?e(\-|\+)?\d+|^[0-9]\d*(\.\d+)?/gi;
    // var numArr = value.match(/\d+(.\d+)?/g);
    var numArr = value.match(findNum);
    if (numArr && numArr.length > 0) {
      num = numArr[0];
    }

    if (type && (type === 'Cap' || type === 'Ind' || type === 'Res')) {
      if (parseFloat(num) === 0) {
        return "0";
      }
    }

    if (numArr && numArr.length > 1) {
      return false;
    }
    if (num.length === value.length) {
      return true;
    }
    const str = value.slice(num.length, value.length);

    if (str.length === 1) {
      const unitIndex = UNIT.indexOf(str);
      if (unitIndex > -1) {
        return true;
      } else {
        return false;
      }
    } else if (str.length === 2) {
      const upStr = str.toUpperCase();
      const _unitIndex = _UNIT.indexOf(upStr);
      if (_unitIndex > -1) {
        return true;
      } else {
        return false;
      }
    } else if (str.length === 3) {
      const upStr = str.toUpperCase();
      const _unitIndex = _UNIT.indexOf(upStr);
      if (_unitIndex > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

//Compare version 
//currentVersion < basicVersion  return true   else return false
// currentVersion= 0.1.8 ,basicVersion= 0.1.8, return false
// currentVersion= 0.1.6 ,basicVersion= 0.1.8, return true
//currentVersion= 0.2.6 ,basicVersion= 0.2.0,return false
//currentVersion= 0.2.6 ,basicVersion= 0.1.8,return false
//currentVersion= 0.0.6 ,basicVersion= 0.1.8,return true
//currentVersion=1.0.6 basicVersion= 0.1.8,  false
/* console.log(versionCompareSize('0.0.6',"0.1.8")) */
//versionCompareSize(currentVersion,basicVersion)  if currentVersion < basicVersion return true
function versionCompareSize(currentVersion, basicVersion) {
  if (currentVersion && basicVersion) {
    let [c1, c2, c3] = currentVersion.split('.').map(item => Number(item));
    let [b1, b2, b3] = basicVersion.split('.').map(item => Number(item));
    if (c1 < b1) {
      return true;
    } else if (c1 === b1) {
      if (c2 < b2) {
        return true;
      } else if (c2 === b2) {
        if (c3 < b3) {
          return true;
        }
      }
    }
    return false;
  }
}

// 0.0.0 - type: major.minor.patch
// 0.0.0 - { type: patch, range: 2 }, update: 0.0.2
// 0.0.2 - { type: minor, range: 1 }, update: 0.1.0
// 0.1.0 - { type: minor, range: 2 }, update: 0.3.0
// 0.3.0 - { type: major, range: 2 }, update: 2.0.0
function versionUpdate(version, params = {}) {
  const { type = 'patch', range = 1 } = params;
  let currentVersion = version ? version : '0.0.0';
  let [majorVer, minorVer, patchVer] = currentVersion.split('.').map(item => Number(item));
  switch (type) {
    case 'major':
      majorVer = majorVer + range;
      minorVer = 0;
      patchVer = 0;
      break;
    case 'minor':
      minorVer = minorVer + range;
      minorVer = 0;
      break;
    case 'patch':
      patchVer = patchVer + range;
      break;
    default: break;
  }
  return `${majorVer}.${minorVer}.${patchVer}`
}

function fakeProgress(progress, indexNum, currentProgress) {
  if (currentProgress === 100) {
    return 100;
  };
  let _progress = progress;
  if (_progress <= 20) {
    _progress = parseInt((_progress + Math.random()) * 10) / 10;
  } else if (_progress > 20 && _progress < 40) {

    if (indexNum % 2 === 0) {
      _progress = parseInt((_progress + Math.random()) * 10) / 10;
    }
  } else if (_progress >= 40 && _progress < 60) {

    if (indexNum % 3 === 0) {
      _progress = parseInt((_progress + Math.random()) * 10) / 10;
    }
  } else if (_progress >= 60 && _progress < 80) {

    if (indexNum % 4 === 0) {
      _progress = parseInt((_progress + Math.random()) * 10) / 10;
    }
  } else {

    if (indexNum % 5 === 0) {
      _progress = parseInt((_progress + Math.random()) * 10) / 10;
    }
  }

  if (_progress >= 90) {
    _progress = 90;
  }

  if (currentProgress && currentProgress > _progress) {
    _progress = currentProgress;
  }

  return _progress;
}

/* split design name/Remove the suffix
eg: design.brd ==> design */
function splitDesignFileName(text, returnSuffix = false) {
  var pattern = /\.{1}[a-zA-Z]{1,}$/;
  let name = "", suffix = ""
  if (pattern.exec(text) !== null) {
    name = (text.slice(0, pattern.exec(text).index));
    suffix = text.slice(pattern.exec(text).index);
  } else {
    name = text;
  }
  return returnSuffix ? { name, suffix } : name;
}

const UNIT_TO_NUM = {
  p: 1e-12,
  n: 1e-9,
  u: 1e-6,
  m: 1e-3,
  K: 1e3,
  M: 1e6,
  G: 1e9
}
function unitToNumber(unit) {
  const unitIndex = UNIT.indexOf(unit);
  if (unitIndex > -1) {
    let _unit = null;
    if (unitIndex > 4) {
      _unit = unit.toLowerCase();
    } else {
      _unit = unit.toUpperCase();
    };
    return UNIT_TO_NUM[_unit];
  } else {
    return 1;
  }
};

// function testUnitToNumber() {
//   ['K', 'k', 'M', 'G', 'g', 'P', 'p', 'N', 'n', 'U', 'u', 'm', 'X', null, undefined, 1, '1', '_'].forEach(item => {
//     console.log(item, unitToNumber(item))
//   })
// }
// testUnitToNumber()

function roundFun(value, n) {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
}

function preLayoutJsonErrorCheck(signalGroup) {
  if (!signalGroup) {
    return { error: ['preLayout.json file does not exist!'] }
  }
  let error = [];
  const RLCList = ['capacitor', 'resistor', 'inductor'];
  for (let group of signalGroup) {
    const groupName = group.name;
    for (let signal of group.signals) {
      const signalName = signal.name;
      let hasError = false;
      for (let section of signal.sections) {
        if (RLCList.includes(section.type)) {
          if (!section.value) {
            error.push(<div>There are sections in {groupName}-{signalName} without setting value.</div>)
            hasError = true;
          }
        } else {
          if (!section.libId) {
            error.push(<div>There are sections in {groupName}-{signalName} without setting template.</div>)
            hasError = true;
          }

          if (!section.length) {
            error.push(<div>There are sections in {groupName}-{signalName} without setting length.</div>)
            hasError = true;
          }
        }
        if (hasError) break;
      }
    }
  }
  return error.length === 0 ? null : { error };
}

function numberToScientific(num, fixed = 2, units) {
  let _num = Number(num);
  if (_num === 0) return '0';
  const p = 1e-12, n = 1e-9, u = 1e-6, m = 1e-3, o = 1, K = 1e3, M = 1e6, G = 1e9;
  const unit = units ? units : {
    1e-12: 'p',
    1e-9: 'n',
    1e-6: 'u',
    1e-3: 'm',
    1: '',
    1e3: 'K',
    1e6: 'M',
    1e9: 'G'
  }
  const arr = [p, n, u, m, o, K, M, G];
  for (let i = 0; i < arr.length; i++) {
    if (_num < (arr[0] * 1e4)) {
      _num = (_num * (1 / arr[i])).toFixed(fixed) + unit[arr[i]];
      return _num;
    } else if (_num < (arr[i] * 100)) {
      if (_num < arr[i] * 10) {
        return _num = (_num * (1 / arr[i - 1])).toFixed(fixed) + unit[arr[i - 1]];
      } else {
        return _num = (_num * (1 / arr[i])).toFixed(fixed) + unit[arr[i]];
      }
    } else if (_num > arr[arr.length - 1] && i === arr.length - 1) {
      return _num = (_num * (1 / arr[i])).toFixed(fixed) + unit[arr[i]];
    }
  }
}

function updateSelected(selected, action) {
  let newSelectedList = [];
  let stateSelectList = JSON.parse(JSON.stringify(selected));
  let _action = { ...action };
  _action.nets.forEach(item => {
    const index = stateSelectList.findIndex(i => i.name === item);
    if (index < 0) {
      newSelectedList.push({
        type: 'net',
        name: item,
        checked: true
      });
    }
  });

  _action.comps.forEach(item => {
    const index = stateSelectList.findIndex(i => i.name === item);
    if (index < 0) {
      newSelectedList.push({
        type: 'comp',
        name: item,
        checked: true
      });
    }
  });

  stateSelectList.forEach(item => {
    if (_action.nets.includes(item.name) || _action.comps.includes(item.name)) {
      item.checked = true;
    } else {
      item.checked = false;
    }
  });
  return [...stateSelectList, ...newSelectedList]
}

function selectedAll(selections, designID, checked) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  let selected = prevSection ? (prevSection.selected || []) : []
  selected.forEach(d => d.checked = checked);
  if (prevSection) {
    _selections[designID].selected = selected;
  } else {
    _selections[designID] = {
      selected: selected
    }
  }
  return _selections;
}

function locationChange(selections, designID, location) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    _selections[designID].location = location;
  } else {
    _selections[designID] = { location: location }
  }
  return _selections;
}

function layerChange(selections, designID, layers) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    _selections[designID].layers = layers;
  } else {
    _selections[designID] = { layers: layers }
  }
  return _selections;
}

function selectionCheckboxChange(selections, designID, selected) {
  let _selections = JSON.parse(JSON.stringify(selections));
  if (_selections[designID]) {
    _selections[designID].selected = selected;
  } else {
    _selections[designID] = {
      selected: [...selected]
    }
  }
  return _selections;
}

function changeSelectedDisplay(selections, designID, show) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    _selections[designID].displayBoxShow = show;
  } else {
    _selections[designID] = { displayBoxShow: show }
  }
  return _selections;
}

function showSelected(selections, designID) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    let nets = [], comps = [];
    prevSection.selected.forEach(net => {
      if (net.type === 'net' && net.checked) {
        nets.push(net.name)
      }
    });
    prevSection.selected.forEach(comp => {
      if (comp.type === 'comp' && comp.checked) {
        comps.push(comp.name)
      }
    });
    _selections[designID].selectedNets = [...nets];
    _selections[designID].selectedComps = [...comps];
  }
  return _selections;
}

function hideSelected(selections, designID) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    _selections[designID].selectedNets = [];
    _selections[designID].selectedComps = [];
    _selections[designID].selectedPins = {};
  } else {
    _selections[designID] = {
      selectedNets: [],
      selectedComps: [],
      selectedPins: {}
    };
  }
  return _selections;
}

function selectionClearOne(selections, designID, name) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];

  if (prevSection) {
    const newSelComps = prevSection.selectedComps ? prevSection.selectedComps.filter(c => c !== name) : []
    const newSelNets = prevSection.selectedNets ? prevSection.selectedNets.filter(n => n !== name) : []
    const newSelected = prevSection.selected ? prevSection.selected.filter(s => s.name !== name) : []
    const newSelPins = getNewSelPins(designID, name, prevSection)
    _selections[designID].selectedPins = { ...newSelPins };
    _selections[designID].selectedComps = [...newSelComps];
    _selections[designID].selectedNets = [...newSelNets];
    _selections[designID].selected = [...newSelected];
  }
  return _selections;
}

function selectionClear(selections, designID) {
  let _selections = JSON.parse(JSON.stringify(selections));

  if (_selections[designID]) {
    _selections[designID].selectedPins = {};
    _selections[designID].selectedComps = [];
    _selections[designID].selectedNets = [];
    _selections[designID].selected = [];
  }
  return _selections
}

function getNewSelPins(designID, name, prevSection) {
  const { selectedNets, selectedComps, selectedPins } = prevSection;
  let _selectedPins = JSON.parse(JSON.stringify(selectedPins));

  if (selectedNets.includes(name)) {
    const _LayoutData = LayoutData.getLayout(designID);
    const netInfo = _LayoutData.mNetManager.GetNetFromName(name);
    if (!netInfo) { return _selectedPins }
    // Delete net, delete the pin related to it in comp
    for (let pinInfo of netInfo.mPinList) {
      const { mCompName, mPinNum } = pinInfo;
      if (selectedPins[mCompName] && selectedPins[mCompName].includes(mPinNum)) {
        _selectedPins[mCompName] = selectedPins[mCompName].filter(item => item !== mPinNum)
      }
    }
  } else if (selectedComps.includes(name)) {
    // If delete comp delete all pins on comp
    if (_selectedPins[name]) {
      delete _selectedPins[name];
    }
  }
  return _selectedPins
}

function updatePanelType(panelType, designID, panel) {
  let _panelType = { ...panelType };
  _panelType[designID] = panel;
  return _panelType;
}

function updateColorBy(colorBy, designID, value) {
  let _colorBy = { ...colorBy };
  _colorBy[designID] = value;
  return _colorBy;
}

function cleanPCBStatus(colorBy, panelType, selections, designID) {
  let _colorBy = { ...colorBy }, _panelType = { ...panelType }, _selections = JSON.parse(JSON.stringify(selections));
  if (designID === 'all') {
    _colorBy = {};
    _panelType = {};
    _selections = {};
  } else {
    delete _colorBy[designID];
    delete _panelType[designID];
    delete _selections[designID];
  }
  return {
    colorBy: _colorBy,
    panelType: _panelType,
    selections: _selections
  }
}

function getSelectedDesignIDs(selectedKeys) {
  return selectedKeys.filter(k => k.match(/PCB-|package-|card-/)).map(d => {
    if (d.includes('package')) {
      return d.split('package-')[1];
    } else if (d.includes('card')) {
      return d.split('card-')[1];
    } else {
      return d.split('PCB-')[1];
    }
  })
}

function selectChange(selections, designID, action) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  const selected = prevSection ? (prevSection.selected || []) : [];
  const newSelected = updateSelected(selected, action);
  if (prevSection) {
    _selections[designID].selectedNets = [...action.nets];
    _selections[designID].selectedComps = [...action.comps];
    _selections[designID].selected = [...newSelected];
    _selections[designID].selectedPins = { ...action.pinsObj };
  } else {
    _selections[designID] = {
      selectedNets: [...action.nets],
      selectedComps: [...action.comps],
      selected: [...newSelected],
      selectedPins: { ...action.pinsObj }
    }
  }
  return _selections;
}

function updateRefreshStatus(selections, designID, action) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    _selections[designID].refresh = action.status;
  } else {
    _selections[designID] = { refresh: action.status }

  }
  return _selections;
}

function changeShowNameStatus(selections, designID, status) {
  let _selections = JSON.parse(JSON.stringify(selections));
  const prevSection = _selections[designID];
  if (prevSection) {
    _selections[designID].showNameStatus = status;
  } else {
    _selections[designID] = {
      showNameStatus: status
    };
  }
  return _selections;
}

function getCompsAndNetsCrossPins({ nets, comps, designId }) {
  if (!nets.length || !comps.length) {
    return {};
  }
  const _LayoutData = LayoutData.getLayout(designId);
  const pins = {};
  for (let net of nets) {
    const netInfo = _LayoutData.mNetManager.GetNetFromName(net);
    if (!netInfo) {
      continue;
    }
    for (let comp of comps) {
      const _pins = netInfo.mPinList.filter(item => item.mCompName === comp);
      if (pins[comp]) {
        pins[comp].push(..._pins.map(item => item.mPinNum))
      } else {
        pins[comp] = _pins.map(item => item.mPinNum)
      }
    }
  }
  return pins;
}

export {
  unitToNumber,
  checkRLCValue,
  versionCompareSize,
  fakeProgress,
  splitDesignFileName,
  checkRLCValueFormat,
  unitConversion,
  numberCheck,
  numConversion,
  _numberCheckIndex,
  stackupCheck,
  timeReplace,
  stackupDataProcess,
  roundFun,
  preLayoutJsonErrorCheck,
  numberToScientific,
  updateSelected,
  selectedAll,
  locationChange,
  layerChange,
  selectionCheckboxChange,
  changeSelectedDisplay,
  showSelected,
  hideSelected,
  selectionClearOne,
  updatePanelType,
  updateColorBy,
  cleanPCBStatus,
  versionUpdate,
  getSelectedDesignIDs,
  selectChange,
  updateRefreshStatus,
  changeShowNameStatus,
  getCompsAndNetsCrossPins,
  selectionClear
};