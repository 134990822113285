import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { select } from 'd3-selection';

function drawIRBarChart(svgElement, chartData, chartXType, chartYType, resultKey, title, yText, xText) {
  let _chartData = [...chartData]
  _chartData.forEach(item => item[chartXType] = Math.abs(item[chartXType]))
  if (!svgElement) {
    return
  }
  const svgWidth = svgElement.parentElement.clientWidth;
  const svgHeight = svgElement.parentElement.clientHeight;

  let margin = {
    top: 40,
    left: 75,
    right: 20,
    bottom: 120
  };
  if (resultKey === 'via') {
    margin = {
      top: 50,
      left: 100,
      right: 30,
      bottom: 290
    };
  }

  const plotWidth = svgWidth - margin.left - margin.right;
  const plotHeight = svgHeight - margin.top - margin.bottom;

  const xDomain = _chartData.map(function (d) { return d[chartYType]; });

  let _xDomain = [];
  const domainLength = xDomain.length;
  if (domainLength) {
    const value = Math.ceil(domainLength / 22);
    for (let index = 0; index <= xDomain.length - 1; index++) {
      if (index === 0 || index === xDomain.length - 1) {
        _xDomain.push(xDomain[index])
      } else if ((index % value) === 0 && index < (xDomain.length - Math.ceil(value / 2))) {
        _xDomain.push(xDomain[index])
      }
    }
  }

  const xScale = scaleBand()
    .domain(xDomain)
    .range([0, plotWidth])
    .paddingInner(0.4)
    .paddingOuter(0.2)

  var maxXNameLength = Math.max(0, ...xDomain.map(s => s ? s.length : 0));//Get the longest name of xDomain
  let dy = (maxXNameLength * 0.45 - 2.85).toFixed(2) + 'em';

  const yScale = scaleLinear().range([plotHeight, 0]);

  const xAxis = axisBottom(xScale)
    .ticks(10)
    .tickValues([..._xDomain])

  const yAxis = axisLeft(yScale)
    .ticks(10)

  const svg = select(svgElement)
    .attr('class', 'plot full-fill')
  svg.selectAll('g').remove();

  // The bar chart
  const gRoot = svg.append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  xScale.domain();

  let maxData = _chartData && _chartData[0] ? _chartData[0][chartXType] : 0;
  let minData = _chartData && _chartData[0] ? _chartData[0][chartXType] : 0;
  for (const data of _chartData) {
    if (data[chartXType] > maxData) {
      maxData = data[chartXType];
    }
    if (data[chartXType] < minData) {
      minData = data[chartXType];
    }
    data.color = '#3489ad'
  }

  let multiple = 10
  if (maxData && maxData > 1) {
    // Get the multiple of the maximum value of y
    let _maxData = JSON.parse(JSON.stringify(maxData))
    let number = 0
    while (_maxData > 1) {
      _maxData = _maxData / 10;
      number++;
    }
    multiple = Math.pow(10, number - 1)
  }

  let minY = Math.floor(minData * multiple) / multiple;
  if (minY < 0) {
    minY = 0
  }

  let _maxY = Math.ceil(maxData)
  if (_maxY < 15) {
  } else if (_maxY < 32) {
    _maxY = Math.ceil(maxData / 2) * 2
  } else if (_maxY < 50) {
    _maxY = Math.ceil(maxData / 5) * 5
  } else if (_maxY < 100) {
    _maxY = Math.ceil(maxData / 10) * 10
  } else {
    _maxY = Math.ceil(maxData / multiple) * multiple;
  }
  yScale.domain([0, _maxY]);

  // axes
  let xTransform = 'translate(-0)'
  if (xScale.domain().length > 10) {
    xTransform = 'translate(-20 10) rotate(-45)'
  }

  let dx = '10px';
  if (xDomain[0]) {
    dx = xDomain[0].length * 4 + 'px'
  }

  gRoot.append("g")
    .attr("class", "axis ir-bar-chart-xAxis")
    .attr("transform", "translate(0," + plotHeight + ")")
    .call(xAxis)
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", dx)
    .attr('dy', "1.3em")
    .attr('font-size', 13)
    .attr('transform', xTransform);

  gRoot.append("g")
    .attr("class", "axis ir-bar-chart-yAxis")
    .call(yAxis)
    .attr('font-size', 13);

  gRoot.selectAll("rect")
    .data(_chartData)
    .enter().append("rect")
    .style("fill", function (d) { return d.color; })
    .attr("x", function (d, i) {
      if (xDomain.length < 9) {
        return ((plotWidth / (xDomain.length * 2)) * (2 * i + 1)) - 25
      }
      return xScale(d[chartYType]);
    })
    .attr("class", "ir-bar-chart-y-width")
    .attr("width", xDomain.length < 9 ? 50 : xScale.bandwidth())
    .attr("y", function (d) {
      return yScale(d[chartXType]);
    })
    .attr("height", function (d) {
      return plotHeight - yScale(d[chartXType]);
    })
    .on('mouseover', function (d, i) {
      gRoot.append('text')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 13)
        .attr('text-anchor', 'middle')
        .attr('class', 'divergence')
        .attr('x', function (x, y) {
          if (xDomain.length < 9) {
            return ((plotWidth / (xDomain.length * 2)) * (2 * i + 1))
          }
          return xScale(d[chartYType]);
        })
        .attr('y', yScale(d[chartXType]) - 20)
        .attr('dx', '8px')
        .text(`${d[chartYType]} (${d[chartXType]})`);
    })
    .on('mouseout', () => {
      svg.selectAll('.divergence').remove();
    });

  const yLabel = 'translate(' + -55 + ' ' + plotHeight / 2 + ') rotate(-90)';

  gRoot.append('text')
    .text(yText)
    .attr('class', 'ir-bar-chart-yAxis-name')
    .style('text-anchor', 'middle')
    .attr('font-size', 14)
    .attr('fill', '#000000a6')
    .attr('transform', yLabel);

  gRoot.append('text')
    .text(xText)
    .attr('class', 'ir-bar-chart-xAxis-name')
    .attr('x', plotWidth / 2)
    .attr('y', plotHeight + 80)
    .attr('fill', '#000000a6')
    .attr('font-size', 14)
    .attr('dy', dy)
    .style('text-anchor', 'middle');
}

export default drawIRBarChart;