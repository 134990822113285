import {
  BUFFERIndex, ConnectorIndex, repeaterIndex, IBISIndex, SPICEIndex, vectorIndex,
  passiveIndex, packageIndex, childTouchstoneIndex, childSpiceIndex, customIndex,
  pcbSpiceIndex, PCBModelIndex,
  TRACE_INDEX
} from '../../../pages/Sierra/constants';
import {
  IBIS,
  SPICE,
  BUFFER,
  VECTOR,
  PASSIVE,
  PASSIVE_TOUCHSTONE,
  PASSIVE_SPICE,
  PACKAGE,
  PKG_TOUCHSTONE,
  PKG_SPICE,
  REPEATER,
  CONNECTOR_TOUCHSTONE,
  CONNECTOR_SPICE_SIERRA,
  PCB_SPICE,
  /* CUSTOM_COMPONENT, */
  CUSTOM_SPICE,
  CUSTOM_TOUCHSTONE,
  PART_LIBRARY,
  PART_IBIS_LIBRARY,
  PART_REPEATER_LIBRARY,
  NETS_FILTER_LIBRARY,
  TRACE
} from '@/constants/libraryConstants';
import {
  MY_LIBRARY,
  LIBRARY,
  PROJECTS,
  TRASH,
  LIBRARY_OPEN,
  LIBRARY_FOLDER_FILE,
  LIBRARY_FOLDER,
  LIBRARY_DATA,
  LIBRARY_FILE,
  ADD_INTERFACE
} from '@/constants/treeConstants';

function treeItem(item, fileType) {
  this.name = item.libraryStructure.name;
  this.type = item.libraryStructure.type;
  this.id = item.id;
  this.dataType = item.type === TRACE ? LIBRARY_DATA : item.libraryStructure.type === 'data' ? LIBRARY_DATA : LIBRARY_FILE;
  this.fileName = item.libraryStructure.name;
  this.key = `${item.id}-${item.libraryStructure.name}`;
  this.fileType = fileType;
  this.parentId = item.libraryFolderId;
  this.libraryType = item.libraryType;
  this.icon = item.type === TRACE ? null : (item.libraryStructure.type === 'folder' ? 'file-folder' : 'file');
  this.status = item.libraryStructure ? item.libraryStructure.status : null;
};

function folderItem(item, fileType) {
  this.name = item.name;
  this.type = item.type;
  this.id = item.id;
  this.libraryType = item.libraryType;
  this.dataType = LIBRARY_FOLDER;
  this.key = `${item.type}-${item.id}`;
  this.path = item.path;
  this.parentId = item.parentId;
  this.icon = 'folder';
  this.fileType = fileType;
  this.children = [];
}

function getLibraryArray(key, arr) {
  if (!Array.isArray(arr) || !arr.length) {
    return []
  }

  switch (key) {
    case IBIS:
      return getLibraryItem(arr, IBIS, false);
    case SPICE:
      return getLibraryItem(arr, SPICE, false/*/.sp$/i */);
    case CONNECTOR_SPICE_SIERRA:
      return getLibraryItem(arr, CONNECTOR_SPICE_SIERRA, /* /.sp$/i */);
    case CONNECTOR_TOUCHSTONE:
      return getLibraryItem(arr, CONNECTOR_TOUCHSTONE, /.s[0-9]+p$/i);
    case VECTOR:
      return getLibraryItem(arr, VECTOR, false);
    case REPEATER:
      return getLibraryItem(arr, REPEATER, false,/* /.sp$/i */);
    case PASSIVE_TOUCHSTONE:
      return getLibraryItem(arr, PASSIVE_TOUCHSTONE, /.s[0-9]+p$/i);
    case PASSIVE_SPICE:
      return getLibraryItem(arr, PASSIVE_SPICE, /* /.sp$/i */);
    case PKG_TOUCHSTONE:
      return getLibraryItem(arr, PKG_TOUCHSTONE, /.s[0-9]+p$/i);
    case PKG_SPICE:
      return getLibraryItem(arr, PKG_SPICE, /* /.sp$/i */);
    case PCB_SPICE:
      return getLibraryItem(arr, PCB_SPICE, /* /.sp$/i */);
    case CUSTOM_TOUCHSTONE:
      return getLibraryItem(arr, CUSTOM_TOUCHSTONE, /.s[0-9]+p$/i);
    case CUSTOM_SPICE:
      return getLibraryItem(arr, CUSTOM_SPICE, /* /.sp$/i */);
    case TRACE:
      return getLibraryItem(arr, TRACE, false);
    default: return [];
  }
}

function getLibraryItem(arr, fileType, regexp) {
  let res = arr.map(item => {
    return item.libraryType === 'file'
      ? new treeItem(item, fileType)
      : new folderItem(item, fileType)
  });
  let list;
  if (regexp) {
    list = res.filter(item => item.libraryType === 'folder' || item.name.match(regexp));
  } else {
    list = res;
  }

  return list;
}

function getLibraryChildrenArray(key, arr, fatherId) {
  if (!Array.isArray(arr) || !arr.length) {
    return []
  }

  let list = [];

  switch (key) {
    case SPICE:
      list = arr.map(item => (new treeChildrenItem(item, SPICE, fatherId)));
      break;
    case REPEATER:
      list = arr.map(item => (new treeChildrenItem(item, REPEATER, fatherId)));
      break;
    default: return [];
  }

  return list;
}

function treeChildrenItem(item, fileType, fatherId) {
  this.name = item.fileName;
  this.type = item.type;
  this.id = fatherId;
  this.fatherId = fatherId;
  this.dataType = LIBRARY_FOLDER_FILE;
  this.fileDataType = item.type;
  this.fileName = item.fileName;
  this.key = `${fatherId}-${item.fileName}`;
  this.fileType = fileType;
};

function getLibraryIndex(type) {
  switch (type) {
    case IBIS:
      return { index: [BUFFERIndex, IBISIndex], listName: 'ibisList' };
    case SPICE:
      return { index: [BUFFERIndex, SPICEIndex], listName: 'spiceList' };
    case CONNECTOR_SPICE_SIERRA:
      return { index: [ConnectorIndex, childSpiceIndex], listName: 'connSpiceList' };
    case CONNECTOR_TOUCHSTONE:
      return { index: [ConnectorIndex, childTouchstoneIndex], listName: 'connTouchstoneList' };
    case REPEATER:
      return { index: [repeaterIndex], listName: 'repeaterList' };
    case VECTOR:
      return { index: [vectorIndex], listName: 'vectorList' };
    case PASSIVE_TOUCHSTONE:
      return { index: [passiveIndex, childTouchstoneIndex], listName: 'passiveTouchstoneList' };
    case PASSIVE_SPICE:
      return { index: [passiveIndex, childSpiceIndex], listName: 'passiveSpiceList' };
    case PKG_TOUCHSTONE:
      return { index: [packageIndex, childTouchstoneIndex], listName: 'pkgTouchstoneList' };
    case PKG_SPICE:
      return { index: [packageIndex, childSpiceIndex], listName: 'pkgSpiceList' };
    case PCB_SPICE:
      return { index: [PCBModelIndex, pcbSpiceIndex], listName: 'pcbSpiceList' };
    case CUSTOM_TOUCHSTONE:
      return { index: [customIndex, childTouchstoneIndex], listName: 'customTouchstoneList' };
    case CUSTOM_SPICE:
      return { index: [customIndex, childSpiceIndex], listName: 'customSpiceList' };
    case TRACE:
      return { index: [TRACE_INDEX], listName: 'traceList' };
    default: return { index: [], listName: '' };
  }
}

const sierraDefaultTree = [{
  name: 'Library',
  key: 'library',
  dataType: MY_LIBRARY,
  nodeClass: 'expand-icon-large',
  children: [{
    name: 'Buffer',
    key: BUFFER,
    dataType: LIBRARY_OPEN,
    nodeClass: 'expand-icon-middle',
    children: [
      {
        name: 'IBIS',
        key: IBIS,
        dataType: LIBRARY,
        children: []
      }, {
        name: 'SPICE',
        key: SPICE,
        dataType: LIBRARY,
        children: []
      }
    ]
  },
  {
    name: 'PCB Model',
    key: 'PCB_Model_Folder',
    nodeClass: 'expand-icon-middle',
    dataType: LIBRARY_OPEN,
    children: [
      {
        name: 'SPICE',
        key: PCB_SPICE,
        dataType: LIBRARY,
        children: []
      }
    ]
  },
  {
    name: 'Connector',
    key: 'Connector_Folder',
    nodeClass: 'expand-icon-middle',
    dataType: LIBRARY_OPEN,
    children: [
      {
        name: 'Touchstone',
        key: CONNECTOR_TOUCHSTONE,
        dataType: LIBRARY,
        children: []
      }, {
        name: 'SPICE',
        key: CONNECTOR_SPICE_SIERRA,
        dataType: LIBRARY,
        children: []
      }
    ]
  },
  {
    name: 'Repeater',
    key: REPEATER,
    dataType: LIBRARY,
    nodeClass: 'expand-icon-middle',
    children: []
  }, {
    name: 'Vector',
    key: VECTOR,
    dataType: LIBRARY,
    nodeClass: 'expand-icon-middle',
    children: []
  }, {
    name: 'Passive',
    key: PASSIVE,
    dataType: LIBRARY_OPEN,
    nodeClass: 'expand-icon-middle',
    children: [
      {
        name: 'Touchstone',
        key: PASSIVE_TOUCHSTONE,
        dataType: LIBRARY,
        children: []
      }, {
        name: 'SPICE',
        key: PASSIVE_SPICE,
        dataType: LIBRARY,
        children: []
      }
    ]
  }, {
    name: 'Package',
    key: PACKAGE,
    dataType: LIBRARY_OPEN,
    nodeClass: 'expand-icon-middle',
    children: [
      {
        name: 'Touchstone',
        key: PKG_TOUCHSTONE,
        dataType: LIBRARY,
        children: []
      }, {
        name: 'SPICE',
        key: PKG_SPICE,
        dataType: LIBRARY,
        children: []
      }
    ]
  },
  {
    name: 'Trace Template',
    key: TRACE,
    dataType: LIBRARY,
    nodeClass: 'expand-icon-middle',
    children: []
  },/*  {
    name: 'Custom Component',
    key: CUSTOM_COMPONENT,
    dataType: LIBRARY_OPEN,
    children: [
      {
        name: 'Touchstone',
        key: CUSTOM_TOUCHSTONE,
        dataType: LIBRARY,
        children: []
      },
      {
        name: 'SPICE',
        key: CUSTOM_SPICE,
        dataType: LIBRARY,
        children: []
      }
    ]
  }, */
  {
    name: 'Part',
    key: PART_LIBRARY,
    dataType: LIBRARY_OPEN,
    nodeClass: 'expand-icon-middle',
    children: [
      {
        name: 'Buffer',
        key: PART_IBIS_LIBRARY,
        dataType: LIBRARY,
        children: []
      }, {
        name: 'Repeater',
        key: PART_REPEATER_LIBRARY,
        dataType: LIBRARY,
        children: []
      }
    ]
  },
  {
    name: 'Power Nets Filter',
    key: NETS_FILTER_LIBRARY,
    dataType: LIBRARY,
    nodeClass: 'expand-icon-middle',
    children: []
  }]
}, {
  name: 'Project',
  key: PROJECTS,
  dataType: PROJECTS,
  children: [],
  nodeClass: 'expand-icon-large',
}, {
  name: 'Trash',
  key: TRASH,
  dataType: TRASH,
  nodeClass: 'expand-icon-large',
}
]

function addIdentificationAction(designId) {
  this.name = 'Add Interface';
  this.id = designId;
  this.dataType = ADD_INTERFACE;
  this.key = ADD_INTERFACE + '-' + designId;
  this.nodeClass = "tree-node-add-interface-name"
}

export { getLibraryArray, getLibraryChildrenArray, getLibraryIndex, sierraDefaultTree, addIdentificationAction };