import { formList, tempList } from '@/constants/systemLibrary';
import { DECAP_SPICE } from '@/constants/libraryConstants';
import { SYS_LIBRARY_FILE, SYS_LIBRARY_FOLDER } from '@/constants/treeConstants';
import { getLibraryFileInfo, getSysLibraryFile, getCustomLibByMatch } from '../../Cascade/library/libraryCtrl'
import { getLibraryFileContent } from '../../Rocky/library/index';
import { getSysLibByBulkSearch } from '../../helper/systemLibraryHelper';
import { parseSPModelSelector } from '../../Library';
import { partCapValueMatch } from '../../helper/RLCValue';
import { unitChange } from '../../helper/mathHelper';
import { CASCADE, ROCKY } from '../../../constants/pageType';
import { CAP, IPD } from '@/constants/componentType';
import { getPartNumber } from '@/services/PCBHelper';
import { getPartAttributesValue } from '@/services/PCBHelper/partHelper';
import auroraDBJson from '../../Designs/auroraDbData';

class DefaultDecap {
  constructor() {
    this.decaps = new Map(); // libraryId - subckts
  }

  getDecap = async (libraryId, system, product) => {
    const data = this.decaps.get(libraryId);
    if (!data) {
      const res = product === ROCKY ? await getLibraryFileContent(libraryId) : system ? await getSysLibraryFile(libraryId) : await getLibraryFileInfo(libraryId);
      const subckts = parseSPModelSelector(res);
      this.decaps.set(libraryId, subckts);
      return subckts;
    } else {
      return data;
    }
  }
}

const defaultDecap = new DefaultDecap();

function matchPartNumber(partNumber, libraryType, modelInfo) {
  if (modelInfo && modelInfo.parameter) {
    let { Size, Cnom, Volt } = modelInfo.parameter;
    return {
      formFactor: Size,
      capacitor: Cnom,
      voltageRating: Volt,
      vdc: ''
    }
  }
  let num = '', f = '', v = '', temp = '', vdc = '';
  partNumber = partNumber.replace(/[^\da-zA-Z.]/g, '_')
  let params = partNumber.split('_');
  switch (libraryType) {
    case SYS_LIBRARY_FILE:
      params.forEach(param => {
        if (param.match(/(\d+\.)*\d+[UPN]F*/ig)) {
          f = partCapValueMatch({ param, f });
        }
      })
      if (!f) {
        return null;
      }
      return {
        capacitor: f,
      }
    case DECAP_SPICE:
      params.forEach(param => {
        if (param.match(/\d{4}/g)) {
          num = param.match(/\d{4}/g)[0];
        } else if (param.match(/\d+(\.|\p)?\d*V(?!dc)/ig)) {
          v = param.match(/\d+(\.|\p)?\d*V(?!dc)/ig)[0].toUpperCase().replace(/V.*/g, 'V')
        } else if (param.match(/(\d+\.)*\d+[UPN]F*(?!\d)/ig)) {
          f = partCapValueMatch({ param, f });
        } else if (param.match(/\d+(\.|\p)?\d*Vdc/ig)) {
          vdc = param.match(/\d+(\.|\p)?\d*Vdc/ig)[0].toUpperCase().replace('P', '.')
        }
      })
      if (!num && !f && !v) {
        return {
          formFactor: '',
          capacitor: '',
          voltageRating: '',
          vdc: ''
        };
      }
      return {
        formFactor: num,
        capacitor: f,
        voltageRating: v,
        vdc: vdc
      }
    default:
      params.forEach(param => {
        const numCode = param.replace(/[^\d]/g, '');
        const complateCode = formList.find(ele => {
          return ele.split('/')[1] === numCode;
        })
        if (complateCode) {
          num = complateCode;
        } else if (param.match(/(\d\.)*\d{1}V/g)) {
          v = param.match(/(\d+\.)*\d+V/g)[0].toUpperCase().replace(/V.*/g, 'V')
        } else if (param.match(/(\d+\.)*\d+[UPN]F*/ig)) {
          f = partCapValueMatch({ param, f });
        } else if (tempList.find(ele => ele === param)) {
          temp = param
        }
      });
      if (!num && !temp && !f && !v) {
        return {
          formFactor: '',
          capacitor: '',
          voltageRating: '',
          vdc: ''
        };
      }
      return {
        formFactor: num,
        capacitor: f,
        voltageRating: v,
        temperature: temp
      }
  }
};

async function searchModelBySystemLibraryFile({ libraryType, libraryId, name, parts, findCustom, product }) {
  const subckts = await defaultDecap.getDecap(libraryId, true, product);

  for (let partInfo of parts) {
    //find model by custom library and part 
    if (findCustom) {
      const model = await getModelByCustom({ part: partInfo.part, partNumber: partInfo.partNumber });
      if (model && Object.keys(model).length) {
        partInfo.model = { ...model };
        continue;
      }
    }
    //find model by part number
    let _findModel = findModelByPartNumber(partInfo.partNumber, subckts.models);
    if (!_findModel) {
      //find model by part name
      _findModel = findModelByPartNumber(partInfo.part, subckts.models);
    }

    if (_findModel) {
      partInfo.model = {
        id: libraryId,
        name: name,
        type: "System",
        subcktName: _findModel.name,
        libraryType: "generic"
      }
      continue;
    }

    const searchValue = getSearchValueByPart(partInfo.part, libraryType, partInfo.cValue);
    if (!searchValue) {
      continue;
    }

    const findModel = subckts.models.find(model => model.name.toLowerCase() === (searchValue.capacitor || "").toLowerCase());
    if (findModel) {
      partInfo.model = {
        id: libraryId,
        name: name,
        type: "System",
        subcktName: findModel.name,
        libraryType: "generic"
      }
      continue;
    }
  }
  return parts;
}

function getSearchValueByPart(part, libraryType, cValue) {
  let searchValue = matchPartNumber(part, libraryType);
  if (!searchValue && !cValue) {
    return null;
  }

  // /^(\d+\.)*(\d+)?(e)?(-|\+)?\d+[UPN]F?$/ig ->> "11.6UF" / "13pF" / "1e3U"
  // /^\d+[UPN]+(\d+)$/ig ->> "11U6" / "13p"
  if (cValue && (cValue.match(/^(\d+\.)*(\d+)?(e)?(-|\+)?\d+[UPN]F?$/ig) || cValue.match(/^\d+[UPN]+(\d+)$/ig))) {

    const capacitor = partCapValueMatch({ param: cValue, f: "" });
    const capUnit = getCapUnit(capacitor)
    if (!searchValue && capacitor) {
      searchValue = { capacitor, capUnit };
    } else {
      searchValue.capacitor = capacitor;
      searchValue.capUnit = capUnit;
    }
  }

  return searchValue;
}

async function searchModelBySystemLibraryFolder({ libraryType, parts, findCustom }) {
  let searchParts = [], customParts = [];
  for (let partInfo of parts) {
    //find model by custom library and part 
    if (findCustom) {
      const model = await getModelByCustom({ part: partInfo.part, partNumber: partInfo.partNumber });
      if (model && Object.keys(model).length) {
        partInfo.model = { ...model };
        customParts.push(partInfo);
        continue;
      }
    }
    const searchValue = getSearchValueByPart(partInfo.part, libraryType, partInfo.cValue);
    if (!searchValue) {
      continue;
    }
    const { formFactor, capacitor, voltageRating, temperature } = searchValue;
    const partNumberObj = partInfo.partNumber ? [{
      page: 0,
      keyword: {
        name: partInfo.partNumber
      }
    }] : []
    searchParts.push(
      {
        part: partInfo.part,
        info: [
          ...partNumberObj,
          {
            page: 0,
            keyword: {
              name: partInfo.part
            }
          },
          {
            page: 0,
            keyword: {
              formFactor,
              capacitor,
              voltageRating,
              temperature
            }
          },
          {
            page: 0,
            keyword: {
              formFactor,
              capacitor,
              voltageRating
            }
          },
          {
            page: 0,
            keyword: {
              formFactor,
              capacitor
            }
          }]
      }
    );
  }

  if (!searchParts.length) {
    return [...customParts];
  }

  const res = await getSysLibByBulkSearch(searchParts);
  if (res && Array.isArray(res)) {
    res.forEach(item => {
      const findModel = item.info.find(it => it.libraries && it.libraries.librariesData && it.libraries.librariesData.length);
      const findPart = parts.find(it => it.part === item.part);
      item.indexList = findPart.indexList || [];
      if (findModel) {
        item.model = {
          id: findModel.libraries.librariesData[0].id,
          name: findModel.libraries.librariesData[0].name,
          type: 'System',
          subcktName: '',
          libraryType: "sparameter",
        }
      }
      delete item.info;
    })
    return [...customParts, ...res];
  }

  return [...customParts];
}

async function searchModelByDecapSpice({ libraryType, libraryId, name, parts, findCustom, voltage, product }) {
  const subckts = await defaultDecap.getDecap(libraryId, false, product);
  for (let partInfo of parts) {
    //find model by custom library and part 
    if (findCustom) {
      const model = await getModelByCustom({ part: partInfo.part, partNumber: partInfo.partNumber });
      if (model && Object.keys(model).length) {
        partInfo.model = { ...model };
        continue;
      }
    }
    //find model by part number
    let findModel = findModelByPartNumber(partInfo.partNumber, subckts.models);
    if (!findModel) {
      //find model by part name
      findModel = findModelByPartNumber(partInfo.part, subckts.models);
    }
    if (findModel) {
      partInfo.model = {
        id: libraryId,
        name: name,
        type: "Decap",
        subcktName: findModel.name,
        libraryType: DECAP_SPICE
      }
      continue;
    }

    const searchValue = getSearchValueByPart(partInfo.part, libraryType, partInfo.cValue);
    if (!searchValue) {
      continue;
    }

    const subckt_available = subckts.models.map(model => {
      const modelParams = matchPartNumber(model.name, libraryType, model);
      let isSameFormFactor = 0
      let isSameCapacitor = 0
      let isSameVoltage = 0
      let isSameVdc = 0
      isSameFormFactor = searchValue.formFactor === modelParams.formFactor ? 3 : 0;
      const _capUnit = getCapUnit(modelParams.capacitor);
      if (searchValue.capUnit && _capUnit !== searchValue.capUnit) {
        const newCap = unitChange({ num: modelParams.capacitor.replace(_capUnit, ''), oldUnit: _capUnit, newUnit: searchValue.capUnit }).number;
        modelParams.capacitor = `${newCap}${searchValue.capUnit}`;
      }
      if (searchValue.capacitor === modelParams.capacitor) {
        isSameCapacitor = 2
      } else if (searchValue.capacitor.match(/[A-z]{2}/) && modelParams.capacitor.match(/[A-z]{2}/) && searchValue.capacitor.match(/[A-z]{2}/)[0] === modelParams.capacitor.match(/[A-z]{2}/)[0]) {

        searchValue.capacitor.match(/\d+(\.\d{1,2})*/)[0] - modelParams.capacitor.match(/\d+(\.\d{1,2})*/)[0] > 0 ?
          (isSameCapacitor = searchValue.capacitor.match(/\d+(\.\d{1,2})*/)[0] / modelParams.capacitor.match(/\d+(\.\d{1,2})*/)[0] <= 10 ? 1 : 0) :
          (isSameCapacitor = modelParams.capacitor.match(/\d+(\.\d{1,2})*/)[0] / searchValue.capacitor.match(/\d+(\.\d{1,2})*/)[0] <= 10 ? 1 : 0)
      }
      if (searchValue.voltageRating === modelParams.voltageRating) {
        isSameVoltage = 2
      } else if (searchValue.voltageRating && searchValue.voltageRating.match(/\d+(\.\d{1,2})*/) && modelParams.capacitor.match(/\d+(\.\d{1,2})*/)) {
        isSameVoltage = Math.abs(searchValue.voltageRating.match(/\d+(\.\d{1,2})*/) - modelParams.capacitor.match(/\d+(\.\d{1,2})*/)) < 3 ? 1 : 0
      }
      if (modelParams.vdc === `${voltage}VDC`) {
        isSameVdc = 2
      }
      let countAvailable = 1 + isSameFormFactor + isSameCapacitor + isSameVoltage + isSameVdc
      model = {
        ...model,
        countAvailable
      }
      return model

    }).sort(function (a, b) {
      return b.countAvailable - a.countAvailable
    })
    const __subckt = subckt_available[0]
    if (__subckt) {
      partInfo.model = {
        id: libraryId,
        name: name,
        type: "Decap",
        subcktName: __subckt.name,
        libraryType: DECAP_SPICE,
        matchWeights: __subckt.countAvailable
      }
    }
  }
  return parts;
}

async function chooseModel({ libraryType, libraryId, name, parts, findCustom, voltage, product }) {

  switch (libraryType) {
    case SYS_LIBRARY_FILE:
      return await searchModelBySystemLibraryFile({ libraryType, libraryId, name, parts, findCustom });
    case SYS_LIBRARY_FOLDER:
      return await searchModelBySystemLibraryFolder({ libraryType, libraryId, name, parts, findCustom });
    case DECAP_SPICE:
      return await searchModelByDecapSpice({ libraryType, libraryId, name, parts, findCustom, voltage, product });
    default: return [];
  }
}


async function getModelByCustom({ partNumber, part }) {
  //find model by part number
  let model = null;
  if (partNumber) {
    let _res = await getCustomLibByMatch(partNumber).catch(e => {
      _res = null;
    })
    if (_res && _res.id) {
      model = {
        id: _res.id,
        name: _res.name,
        type: 'Custom',
        subcktName: '',
        libraryType: _res.type,
        path: _res.type === "data" ? `${_res.folderPath}/${_res.name}` : _res.path
      }
      return model;
    }
  }

  //find model by part name
  let _res = await getCustomLibByMatch(part).catch(e => {
    _res = null;
  })
  if (_res && _res.id) {
    model = {
      id: _res.id,
      name: _res.name,
      type: 'Custom',
      subcktName: '',
      libraryType: _res.type,
      path: _res.type === "data" ? `${_res.folderPath}/${_res.name}` : _res.path
    }
    return model;
  }
  return null;
}

function findModelByPartNumber(partNumber, models) {
  const findModel = models.find(item => item && item.parameter && item.parameter.ModelName && item.parameter.ModelName === partNumber);
  if (findModel) {
    return findModel
  }
  let reg = new RegExp(`(${partNumber})`, 'i');
  return models.find(item => item.name.match(reg));
}

function getCapUnit(cap) {
  const unit = cap.match(/[UPN]F?/ig);
  return unit ? unit[0] : 'uF';
}

async function updateCompDecapModel({
  components = [],
  prevComps = [],
  designId,
  type,
  existModelParts = [],
  newNoModelCompNames,
  voltage,
  defaultDecap,
  applyModelList = [],
  customLibrary,
  product
}) {
  let allParts = existModelParts && existModelParts.length ? JSON.parse(JSON.stringify(existModelParts)) : [], parts = [];
  for (let i = 0; i < components.length; i++) {
    const comp = components[i];
    if (comp.isPreLayout) {
      continue;
    }
    if ((comp.COMP_TYPE !== CAP && comp.COMP_TYPE !== IPD) || (newNoModelCompNames && !newNoModelCompNames.includes(comp.name))) {
      continue;
    }
    //find model in prev comp
    const findComp = prevComps.find(item => item.name === comp.name || item.part === comp.part);
    if (product === CASCADE) {
      if (findComp && findComp.models && findComp.models.length === 1 && findComp.models[0].id) {
        comp.model = JSON.parse(JSON.stringify(findComp.models[0]));
        allParts.push({ part: comp.part, model: JSON.parse(JSON.stringify(findComp.models[0])) });
        components[i] = comp;
        continue;
      }
    } else if (findComp && findComp.model && findComp.model.id) {
      comp.model = JSON.parse(JSON.stringify(findComp.model));
      allParts.push({ part: comp.part, model: JSON.parse(JSON.stringify(findComp.model)) });
      components[i] = comp;
      continue;
    }
    const partIndex = allParts.findIndex(item => item.part === comp.part);
    const findPart = partIndex > -1 ? allParts[partIndex] : null;

    //find model in allParts
    if (findPart && findPart.model) {
      comp.model = JSON.parse(JSON.stringify(findPart.model));
      components[i] = comp;
      continue;
    }
    //find model in apply all parts decap models
    const find = type === "re-assign" ? null : applyModelList.find(model => model.partName === comp.part);
    if (find && find.model && Object.keys(find.model).length && (!find.defaultId || find.defaultId === defaultDecap.libraryId)) {
      comp.model = JSON.parse(JSON.stringify(find.model));
      allParts.push({ part: comp.part, model: JSON.parse(JSON.stringify(find.model)) });
      components[i] = comp;
      continue;
    }

    //push part name to search model
    const _index = parts.findIndex(it => it.part === comp.part);
    if (_index > -1) {
      parts[_index].indexList.push(i)
    } else {
      const partNumber = product === CASCADE ? auroraDBJson.getPartNumberByPartName(designId, comp.part) : getPartNumber(comp.part, designId);
      const cValue = product === CASCADE ? auroraDBJson.getPartValueByPartName(designId, comp.part) : getPartAttributesValue(comp.part, designId);
      parts.push({ indexList: [i], part: comp.part, partNumber, cValue })
    }
  }

  if (!parts.length) {
    return {
      components,
      existModelParts: allParts
    };
  }
  let _applyModelList = [...applyModelList];
  try {
    const partModels = await chooseModel({ ...defaultDecap, parts, findCustom: customLibrary && customLibrary.length, voltage, product });

    for (let part of partModels || []) {
      if (!part.model || !Object.keys(part.model).length) {
        continue;
      }
      for (let index of part.indexList || []) {
        if (!components[index]) {
          continue;
        }
        components[index].model = JSON.parse(JSON.stringify(part.model));
        allParts.push({ part: components[index].part, model: JSON.parse(JSON.stringify(part.model)) });
      }

      //update apply all model list
      const findIndex = applyModelList.findIndex(model => model.partName === part.part);
      const applyModel = {
        model: part.model,
        partName: part.part,
        defaultId: defaultDecap.libraryId
      };
      if (findIndex > -1) {
        _applyModelList[findIndex] = { ...applyModel }
      } else {
        _applyModelList = [...applyModelList, { ...applyModel }]
      }
    }
  } catch (error) {
    console.error(error)
  }

  return {
    components,
    existModelParts: allParts,
    applyModelList: _applyModelList
  }
}

export {
  chooseModel,
  updateCompDecapModel
}