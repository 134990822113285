const PROJECT_TYPE = 'Andes_v2';

export const UPDATE_EXPAND_KEYS = `${PROJECT_TYPE}/update_expand_keys`;
export const UPDATE_SELECTED_KEYS = `${PROJECT_TYPE}/update_selected_keys`;
export const UPDATE_TREE_LIST = `${PROJECT_TYPE}/update_tree_list`;
export const UPDATE_PROJECT_MENU = `${PROJECT_TYPE}/update_project_menu`;
export const CLEAR_CURRENT_PROJECT = `${PROJECT_TYPE}/clear_current_project`;
export const OPEN_PROJECT = `${PROJECT_TYPE}/open_project`;
export const REFRESH_PCB = `${PROJECT_TYPE}/refresh_pcb`;
export const SAVE_OPEN_PROJECT_INFO = `${PROJECT_TYPE}/save_open_project_info`;
export const UPDATE_VIEW_LIST = `${PROJECT_TYPE}/update_view_list`;
export const OPEN_PAGE = `${PROJECT_TYPE}/open_page`;
export const OPEN_PAGE_INFO = `${PROJECT_TYPE}/open_page_info`;
export const CLOSE_PROJECT = `${PROJECT_TYPE}/close_project`;
export const ADD_PROJECT = `${PROJECT_TYPE}/add_project`;
export const CREATE_PROJECT = `${PROJECT_TYPE}/create_project`;
export const DELETE_PROJECT = `${PROJECT_TYPE}/delete_project`;
export const UPDATE_PAGE_LAYOUT = `${PROJECT_TYPE}/update_page_layout`;
export const UPDATE_TRASH_LIST = `${PROJECT_TYPE}/update_trash_list`;
export const CLEAR_TRASH = `${PROJECT_TYPE}/clear-trash`;
export const PCB_REPLACE = `${PROJECT_TYPE}/pcb-replace`;
export const PROJECT_RENAME = `${PROJECT_TYPE}/project_rename`;
export const COPY_PROJECT = `${PROJECT_TYPE}/copy_project`;
export const CHANGE_COPY_LOADING_ID = `${PROJECT_TYPE}/change_copy_loading_id`;

export const DELETE_PCB = `${PROJECT_TYPE}/delete_pcb`;
export const UPDATE_PCBS_LAYOUT_DB = `${PROJECT_TYPE}/update_pcbs_layout_db`;
export const UPDATE_REPORT_INFO = `${PROJECT_TYPE}/update_report_info`;
export const CLEAR_REPORT_INFO = `${PROJECT_TYPE}/clear_report_info`;
export const SAVE_REPORT_CONFIG = `${PROJECT_TYPE}/save_report_config`;
export const GET_REPORT_CONFIG = `${PROJECT_TYPE}/get_report_config`;
export const GET_REPORT = `${PROJECT_TYPE}/get_report`;
export const UPDATE_PCB_LAYOUT = `${PROJECT_TYPE}/update_pcb_layout`;
export const UPDATE_VIEWLIST = `${PROJECT_TYPE}/update_viewlist`;
/* service options */
export const UPDATE_SERVICE_CONFIG = `${PROJECT_TYPE}/update_service_config`;
export const SAVE_SERVICE_OPTION = `${PROJECT_TYPE}/save_service_option`;

export const UPDATE_STACKUP_STATUS_LIST = `${PROJECT_TYPE}/update_stackup_status_list`;