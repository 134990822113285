import http from "../http";

/**
 * Andes v2 channel extraction start
 * 2021/04/23
 * @param {array} channelIds
 * @return
 */
export function AndesV2ChannelExtraction({ channelIds, runSeasim, runSimulation, setupOnly }) {
  // return http.post(`/andes/simulation/channel/extraction/start`, channelIds);
  return http.post(`/andes/simulation/channel/simulation/start`, {
    channelIds,
    runSeasim,
    runSimulation,
    setupOnly
  });
}

/**
 * Get Andes v2 channel extraction log
 * 2021/04/26
 * @param {string} verificationId
 * @return
 */
export function getAndesV2ChannelExtractionLog(verificationId) {
  return http.get(`/andes/channel/extraction/log?verificationId=${verificationId}`);
}
