import { getFolderFileDetail, getLibraryRepeaterFile } from ".";
import { REPEATER } from "../../../constants/libraryConstants";
import { getDefaultIndex } from "../../helper/setDefaultName";
import sierraLibrary from "./libraryStorage";

async function setDefaultRepeaterModelNodes(libraryList) {
  let isUpdate = false, editParts = [];
  try {
    const modelObj = {}//key:id::fileName,
    for (let data of libraryList) {
      if (!data.model || !data.model.files || !data.model.files.length || !data.model.pairs || !data.model.pairs.length) {
        continue;
      }
      const files = data.model.files.filter(item => !!item.fileName);
      if (files.length !== 1) {
        continue;
      }

      let isUpdateItem = false;

      const file = files[0];
      if (file.libraryId || (!file.libraryId && file.fileName)) {
        const fileExist = file.libraryId ? sierraLibrary.checkFile(REPEATER, file.libraryId, file.folder ? file.fileName : null) : false;
        if (!fileExist) {
          const findFile = sierraLibrary.findFileByName(REPEATER, file.fileName, file.folder);
          file.libraryId = findFile && findFile.id ? findFile.id : file.libraryId;
          data.model.files = [file]
          isUpdate = true;
          isUpdateItem = true;
        }
      }
      let models = [];
      if (modelObj[`${file.libraryId}::${file.fileName}`]) {
        models = modelObj[`${file.libraryId}::${file.fileName}`];
      } else {
        const res = await getFilesParseList(files);
        if (!res || !Array.isArray(res)) {
          isUpdateItem && editParts.push(data)
          continue;
        }

        const currentFileInfo = res.find(item => item.libraryId === file.libraryId);
        if (file.folder) {
          //folder
          const folderFiles = currentFileInfo && Array.isArray(currentFileInfo.files) ? [...currentFileInfo.files] : [];
          const currentFile = Array.isArray(folderFiles) ? folderFiles.find(item => item.fileName === file.fileName) : {};
          models = currentFile && Array.isArray(currentFile.models) ? [...currentFile.models] : [];
        } else {
          //file
          models = currentFileInfo && Array.isArray(currentFileInfo.models) ? [...currentFileInfo.models] : [];
        }
        modelObj[`${file.libraryId}::${file.fileName}`] = models;
      }

      if (!models || !models.length) {
        isUpdateItem && editParts.push(data)
        continue;
      }

      let ports = [];
      if (file.subckt) {
        const findModel = models.find(it => it.name === file.subckt);
        ports = findModel && findModel.ports ? findModel.ports : [];
      }

      if (!ports.length) {
        ports = models[0].ports || [];
        file.subckt = models[0].name;
        isUpdate = true;
        isUpdateItem = true;
        data.model.files = [file];
      }

      if (!ports || ports.length !== 2) {
        isUpdateItem && editParts.push(data)
        continue;
      }

      //file has one subckt and only two nodes
      let inNode = "", outNode = "";
      const findSelect = data.model.pairs.find(it => it.input && it.output && it.input.node && it.output.node);
      if (findSelect) {
        inNode = ports.includes(findSelect.input.node) ? findSelect.input.node : "";
        outNode = ports.includes(findSelect.output.node) ? findSelect.output.node : "";
      }

      if (!inNode || !outNode) {
        const nodeInfo = getRepeaterModelNodes(models);
        inNode = nodeInfo.inNode;
        outNode = nodeInfo.outNode;
        isUpdate = true;
        isUpdateItem = true;
      }

      let modelKeys = [], modelKey = "1";
      for (let conn of data.connections) {
        modelKey = getDefaultIndex(modelKey, modelKeys);
        const findInIndex = data.model.pairs.findIndex(it => conn.input && conn.input.includes(it.pin));
        const findOutIndex = data.model.pairs.findIndex(it => conn.output && conn.output.includes(it.pin));
        if (findInIndex > -1) {
          const inPair = data.model.pairs[findInIndex];

          if (inNode !== inPair.node || inPair.libraryId !== file.libraryId
            || inPair.fileName !== file.fileName
            || inPair.subckt !== file.subckt
            || inPair.modelKey !== modelKey
          ) {
            isUpdate = true;
            isUpdateItem = true;
          }
          data.model.pairs[findInIndex] = {
            ...data.model.pairs[findInIndex],
            node: inNode,
            libraryId: file.libraryId,
            fileName: file.fileName,
            subckt: file.subckt,
            modelKey,
          }
        }

        if (findOutIndex > -1) {
          const outPair = data.model.pairs[findInIndex];

          if (outNode !== outPair.node || outPair.libraryId !== file.libraryId
            || outPair.fileName !== file.fileName
            || outPair.subckt !== file.subckt
            || outPair.modelKey !== modelKey
          ) {
            isUpdate = true;
            isUpdateItem = true;
          }
          data.model.pairs[findOutIndex] = {
            ...data.model.pairs[findOutIndex],
            node: outNode,
            libraryId: file.libraryId,
            fileName: file.fileName,
            subckt: file.subckt,
            modelKey,
          }
        }
        modelKeys.push(modelKey);
      }
      data.model.files = [file];
      isUpdateItem && editParts.push(data)
    }
  } catch (error) {
    console.error(error);
  }

  return { libraryList, isUpdate, editParts };
}

async function getFilesParseList(files) {
  let _files = [];
  try {
    //file filter
    //_files  folder {libraryId, folder, fileNames:[fileName1,fileName2, ... ]}
    //_files file {libraryId, folder:"", fileNames:[fileName]}
    files.forEach(file => {
      const index = _files.findIndex(item => item.libraryId === file.libraryId);
      if (index > -1) {
        const findFile = _files[index].fileNames.find(item => item === file.fileName);
        if (file.folder && !findFile) {
          _files[index].fileNames.push(file.fileName);
        }
      } else {
        _files.push({ libraryId: file.libraryId, folder: file.folder, fileNames: [file.fileName] })
      }
    })
    const promiseList = _files.map(item => {
      if (item.folder) {
        return getFolderFileDetail({ libraryId: item.libraryId, fileNames: item.fileNames });
      } else {
        return getLibraryRepeaterFile({ libraryId: item.libraryId });
      }
    });
    return Promise.all(promiseList);
  } catch (error) {
    console.error(error);
  }
  return Promise.all([]);
}

function getRepeaterModelNodes(models) {
  let inNode = "", outNode = "";
  if (!models || !models.length) {
    return { inNode, outNode }
  }
  const findIn = models[0].ports.find(it => it.match(/in|input/ig));
  const findOut = models[0].ports.find(it => it.match(/out|output/ig) && it !== findOut);
  if (findIn) {
    inNode = findIn;
    outNode = models[0].ports.find(it => it !== findIn)
  }
  if (findOut) {
    outNode = findOut;
    inNode = inNode ? inNode : models[0].ports.find(it => it !== findOut)
  }

  if (!findIn && !findOut) {
    inNode = models[0].ports[0];
    outNode = models[0].ports[1];
  }
  return { inNode, outNode }
}

async function autoAssignRepeaterPorts({
  componentSetting,
  designId,
  _pairs,
  _pinList,
  modelPorts,
  file,
  part,
  component
}) {
  const pins = _pairs.map(item => item.pin);
  const { inNode, outNode } = getRepeaterModelNodes([{ name: file.subckt, ports: modelPorts }]);
  const compPinMap = await componentSetting.getCompPinMap(designId) || {};
  const repeater = (compPinMap.repeater || []).filter(item => item.part === part && (!item.components || !item.components.length || item.components.includes(component)))
  const findRepeater = repeater.find(item => pins.every(pin =>
    item.pinMap.find(it => (it.input || []).includes(pin) || (it.output || []).includes(pin)))
  );

  let groupList = [];
  let modelKeys = [];

  if (findRepeater && findRepeater.pinMap) {

    for (let item of findRepeater.pinMap) {
      const modelKey = getDefaultIndex(modelKeys.length + 1, modelKeys);
      groupList.push({
        input: [...(item.input || [])],
        output: [...(item.output || [])],
        modelKey
      })
      modelKeys.push(modelKey)
    }

  } else {
    let flag = "input", obj = { input: [], output: [] }
    for (let pin of pins) {
      if (flag === "input") {
        const modelKey = getDefaultIndex(modelKeys.length + 1, modelKeys);
        obj.input.push(pin);
        obj.modelKey = modelKey;
        flag = "output";
        modelKeys.push(modelKey)
        continue;
      }

      if (flag === "output") {
        obj.output.push(pin)
        flag = "input";
        groupList.push({ ...obj });
        obj = { input: [], output: [] };
      }
    }
  }

  for (let item of _pairs) {
    if (!item.pin) {
      continue;
    }
    const findInPin = groupList.find(it => it.input.includes(item.pin));
    const findOutPin = groupList.find(it => it.output.includes(item.pin));
    const newNode = findInPin ? inNode : (findOutPin ? outNode : "");
    if (!newNode) {
      continue;
    }

    item.node = newNode;
    item.libraryId = file.libraryId;
    item.fileName = file.fileName;
    item.subckt = file.subckt;
    item.modelKey = findInPin ? findInPin.modelKey : (findOutPin ? findOutPin.modelKey : "");

    const index = _pinList.findIndex(it => it.pinL === item.pin);
    if (index > -1) {
      _pinList[index] = {
        ..._pinList[index],
        pinLValue: item.node,
        pinLLibraryId: item.libraryId,
        pinLFileName: item.fileName,
        pinLSubckt: item.subckt,
        pinLModelKey: item.modelKey
      }
    }
    const rIndex = _pinList.findIndex(it => it.pinR === item.pin);
    if (rIndex > -1) {
      _pinList[rIndex] = {
        ..._pinList[rIndex],
        pinRValue: item.node,
        pinRLibraryId: item.libraryId,
        pinRFileName: item.fileName,
        pinRSubckt: item.subckt,
        pinRModelKey: item.modelKey
      }
    }
  }

  return { _pairs, _pinList }
}


export {
  setDefaultRepeaterModelNodes,
  getRepeaterModelNodes,
  getFilesParseList,
  autoAssignRepeaterPorts
}