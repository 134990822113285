import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { Select, Checkbox, Progress, Button } from 'antd';
import { getCascadeDebugDownloadList } from '@/services/helper/debugHelper';
import Panel from '@/components/Panel';
import { SIMULATION_DEBUG, PCB_PARSING, IR_EXPLORER, IMPEDANCE, DCR, POWER_TREE, DESIGN_TREE, REPORT, SINGLE_TREE } from '@/services/helper/debugHelper';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { getDebugDownloadProgress } from '@/services/helper/downloadHelper';
import { DESIGN_FAILED } from '@/constants/designCategory';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { SIGN_OFF_TEMPLATE } from '@/constants/treeConstants';
import { getDownloadFileId } from '../../../../../services/helper/downloadHelper';
import { DCR_TITLE, FULL_TITLE, IMPEDANCE_TITLE, IR_TITLE, TEMPLATE_TITLE, TREE_TITLE } from '../../../../../services/Cascade/constants';
import { userDefaultSettings } from '../../../../../services/userDefaultSetting/userDefaultSettingCtrl';
import '@/publicCss/style.css';
import '@/publicCss/debug.css';

const CheckboxGroup = Checkbox.Group
const { Option, OptGroup } = Select;

class DebugDownloadPanel extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      maxWidth: 1000,
      maxHeight: 304,
      menuType: PCB_PARSING,
      designId: "",
      downloadList: [],
      currentCheckValues: [],
      PCBProgress: -1,
      pathRProgress: -1,
      irProgress: -1,
      impedanceProgress: -1,
      powerTreeProgress: -1,
      designTreeProgress: -1,
      progress: -1,
      reportProgress: -1,
      irVerificationId: '',
      pathRVerificationId: '',
      impVerificationId: '',
      powerTreeVerificationId: '',
      designTreeVerificationId: '',
      singleVerificationId: '',
      reportSelectName: 'Sign-off Template',
      DCRList: [],
      IRList: [],
      ImpedanceList: [],
      PowerTreeList: [],
      DesignTreeList: [],
      signOffTemplateList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  getHeight = () => {
    const content = document.getElementById("debug-content-id");
    if (content) {
      const height = content.offsetHeight + 70 || 242;
      return height > 242 ? height : 242;
    }
    return 242;
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    const { currentProjectDesigns } = this.props;
    this.resize();
    const designId = currentProjectDesigns.length > 0 ? currentProjectDesigns[0].id : "";
    this.getList();
    const { view } = this.props;
    this.setState({
      designId,
      menuType: [PCB_PARSING, DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SINGLE_TREE].includes(view) ? view : PCB_PARSING
    }, () => {
      this.getDownloadOptions();
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { projectId, updateDebug } = this.props;
    if (projectId !== prevProps.projectId || updateDebug) {
      this.getList()
    }
  }

  updateProgress = () => {
    const { menuType, irProgress, pathRProgress, PCBProgress, impedanceProgress,
      downloadPCBMsg, downloadPahtRMsg, downloadIRMsg, downloadImpMsg, designTreeProgress,
      downloadTreeMsg, reportProgress, downloadReportMsg
    } = this.state;
    let progress = PCBProgress, downloadMessage = downloadPCBMsg;
    switch (menuType) {
      case DCR:
        progress = pathRProgress;
        downloadMessage = downloadPahtRMsg;
        break;
      case IR_EXPLORER:
        progress = irProgress;
        downloadMessage = downloadIRMsg;
        break;
      case IMPEDANCE:
        progress = impedanceProgress;
        downloadMessage = downloadImpMsg;
        break;
      case POWER_TREE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        progress = designTreeProgress;
        downloadMessage = downloadTreeMsg;
        break;
      case REPORT:
        progress = reportProgress;
        downloadMessage = downloadReportMsg;
        break;
      case PCB_PARSING:
      default:
        progress = PCBProgress;
        downloadMessage = downloadPCBMsg;
        break;
    }
    return {
      progress,
      downloadMessage
    }
  }

  getList = () => {
    const { projectId, irVerificationId, pathRVerificationId, impVerificationId, treeVerificationId } = this.props;
    const { irVerificationId: irId, pathRVerificationId: pathRId, impVerificationId: ImpId, designTreeVerificationId } = this.state;
    this.props.updateListStatus(false);
    const DCRList = CascadeChannels.getList(DCR, projectId);
    const _pathRVerificationId = pathRId ? pathRId : pathRVerificationId ? pathRVerificationId : DCRList.length ? DCRList[0].id : '';
    const IRList = CascadeChannels.getList(IR_EXPLORER, projectId);
    const _irVerificationId = irId ? irId : irVerificationId ? irVerificationId : IRList.length ? IRList[0].id : '';
    const ImpedanceList = CascadeChannels.getList(IMPEDANCE, projectId);
    const _impVerificationId = ImpId ? ImpId : impVerificationId ? impVerificationId : ImpedanceList.length ? ImpedanceList[0].id : '';
    const PowerTreeList = CascadeChannels.getList(POWER_TREE, projectId);
    const _powerVerificationId = designTreeVerificationId ? designTreeVerificationId : treeVerificationId ? treeVerificationId : PowerTreeList.length ? PowerTreeList[0].id : '';
    const DesignTreeList = CascadeChannels.getList(DESIGN_TREE, projectId);
    const _treeVerificationId = designTreeVerificationId ? designTreeVerificationId : treeVerificationId ? treeVerificationId : DesignTreeList.length ? DesignTreeList[0].id : '';
    const SingleTreeList = CascadeChannels.getList(SINGLE_TREE, projectId);
    const _singleVerificationId = designTreeVerificationId ? designTreeVerificationId : treeVerificationId ? treeVerificationId : SingleTreeList.length ? SingleTreeList[0].id : '';
    const signOffTemplateList = CascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId);
    this.setState({
      DCRList,
      IRList,
      ImpedanceList,
      PowerTreeList,
      DesignTreeList,
      SingleTreeList,
      pathRVerificationId: _pathRVerificationId,
      irVerificationId: _irVerificationId,
      impVerificationId: _impVerificationId,
      powerTreeVerificationId: _powerVerificationId,
      designTreeVerificationId: _treeVerificationId,
      singleVerificationId: _singleVerificationId,
      signOffTemplateList
    })
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 304),
      maxWidth: getPanelMaxWidth(offset, 750)
    })
  }

  selectClick = (e) => {
    e.stopPropagation();
  }

  debugDesignChange = (value) => {
    this.setState({
      designId: value
    }, () => {
      this.getDownloadOptions()
    })
  }

  changeDebugCheckList = (values) => {
    this.setState({
      currentCheckValues: values
    })
  }

  changeDebugItem = (e, type) => {
    e.stopPropagation();
    this.setState({
      menuType: type
    }, () => {
      this.getDownloadOptions()
    })
  }

  getDownloadButtonDisabled = () => {
    const { currentCheckValues, menuType, designId,
      pathRVerificationId,
      irVerificationId,
      impVerificationId
    } = this.state;
    let disabled = false;
    switch (menuType) {
      case DCR:
        disabled = !pathRVerificationId ? true : false;
        break;
      case IR_EXPLORER:
        disabled = !irVerificationId ? true : false;
        break;
      case IMPEDANCE:
        disabled = !impVerificationId ? true : false;
        break;
      case PCB_PARSING:
      default:
        disabled = !designId ? true : false;
        break;
    }
    if (currentCheckValues.length === 0) {
      disabled = true
    }
    return disabled
  }

  getDownloadOptions = () => {
    const { menuType } = this.state
    const vendor = this.getVendor()
    const _menuType = [PCB_PARSING, REPORT, IMPEDANCE, DCR].includes(menuType) ? menuType : SIMULATION_DEBUG
    const result = [IR_EXPLORER].includes(menuType) ? true : false;
    const simulation = [IMPEDANCE].includes(menuType) ? true : false;
    const { downloadList, currentCheckValues } = this._getDownloadList({ menuType: _menuType, vendor, result, simulation })
    this.setState({
      downloadList,
      currentCheckValues
    });
  }

  getVendor = () => {
    const { designId } = this.state;
    const { currentProjectDesigns } = this.props;
    let vendor = designId ? currentProjectDesigns.filter(item => item.id === designId)[0].vendor : null;
    return vendor
  }

  _getDownloadList = (params) => {
    let downloadList = [];
    if (params.menuType === REPORT) {
      const { reportSelectName, signOffTemplateList, ImpedanceList } = this.state
      const dataList = reportSelectName === 'Sign-off Template' ? signOffTemplateList : ImpedanceList;
      downloadList = dataList.map(item => { return { name: item.name, option: item.id } })
    } else {
      downloadList = getCascadeDebugDownloadList({ ...params });
    }
    let currentCheckValues = [];
    for (let item of downloadList) {
      if (item.default) {
        currentCheckValues.push(item.option);
      }
    }
    return { downloadList, currentCheckValues }
  }

  closeModal = () => {
    this.props.closeModel()
  }

  downloadItem = (url, key, progressKey, msgKey, timeKey) => {
    let progress = 0, indexNum = 0;
    this.setState({
      [progressKey]: 0,
      [msgKey]: null
    })
    this[timeKey] = setInterval(() => {
      indexNum += 1;
      progress = getDebugDownloadProgress(progress);
      if (indexNum % 3 === 0) {
        this.setState({
          [progressKey]: progress
        })
      }
    }, 800);
    if (url) {
      try {
        if (["Report", "PCB"].includes(key)) {
          this.downloadFileFn({ url, timeKey, progressKey, msgKey, key });
          return;
        }
        this.setState({
          [msgKey]: `Preparing file...`
        })

        getDownloadFileId(url).then(res => {
          if (res && res.status === "success") {
            clearInterval(this[timeKey]);
            const token = localStorage.getItem('token')
            const a = document.createElement('a');
            // a.download = fileName;
            a.href = `api/v3/download/file?downloadKey=${res.key}&access_token=${token}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.setState({
              [progressKey]: 100,
              [msgKey]: `Prepared file successfully and waiting for download!`
            })
            setTimeout(() => {
              this.setState({
                [msgKey]: `Download ${key} file successfully!`
              })
            }, 8000);
          } else {
            clearInterval(this[timeKey]);
            this.setState({
              [progressKey]: -1,
              [msgKey]: `Download ${key} zip file failed! ${res.error || ""}`
            })
          }
        })
      } catch (error) {
        clearInterval(this[timeKey]);
        this.setState({
          [progressKey]: -1,
          [msgKey]: `Download ${key} zip file failed! ${error || ""}`
        })
      }
      setTimeout(() => {
        this.setState({
          [progressKey]: -1,
        })
      }, 2000);
    }
  }

  downloadFileFn = ({ url, timeKey, progressKey, msgKey, key }) => {
    const a = document.createElement('a');
    // a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    clearInterval(this[timeKey]);
    setTimeout(() => {
      this.setState({
        [progressKey]: 100,
        [msgKey]: `Prepared file successfully and waiting for download!`
      })
    }, 1000);
    setTimeout(() => {
      this.setState({
        [msgKey]: `Download ${key} file successfully!`
      })
    }, 8000);
    setTimeout(() => {
      this.setState({
        [progressKey]: -1,
      })
    }, 2000);
  }

  DownloadDebugClick = (e) => {
    const { menuType, designId, currentCheckValues,
      irVerificationId, pathRVerificationId, impVerificationId,
      designTreeVerificationId } = this.state;
    // if(verificationId)
    let options = currentCheckValues && currentCheckValues.length > 0 ? [...currentCheckValues] : ['empty']
    const token = localStorage.getItem('token')

    if (menuType === PCB_PARSING) {
      const url = `api/v3/project/design/debug?designId=${designId}&options=${options}&access_token=${token}`;
      this.downloadItem(url, 'PCB', 'PCBProgress', 'downloadPCBMsg', 'pcbParsingTime')
    } else if (menuType === DCR) {
      const url = `/Cascade/dcr/debug?options=${options}&verificationId=${pathRVerificationId}&access_token=${token}`
      this.downloadItem(url, 'Path-R', 'pathRProgress', 'downloadPathRMsg', 'pathRTime')
    } else if (menuType === IR_EXPLORER) {
      const url = `/Cascade/ir/debug?options=${options}&verificationId=${irVerificationId}&access_token=${token}`
      this.downloadItem(url, 'IR', 'irProgress', 'downloadIRMsg', 'irTime')
    } else if (menuType === IMPEDANCE) {
      const url = `/Cascade/impedance/debug?options=${options}&verificationId=${impVerificationId}&access_token=${token}`
      this.downloadItem(url, 'Impedance', 'impedanceProgress', 'downloadImpMsg', 'impTime')
    } else if (menuType === DESIGN_TREE || menuType === POWER_TREE || menuType === SINGLE_TREE) {
      const url = `/Cascade/powertree/debug?options=${options}&verificationId=${designTreeVerificationId}&access_token=${token}`
      this.downloadItem(url, 'Power Tree', 'designTreeProgress', 'downloadTreeMsg', 'treeTime')
    } else if (menuType === REPORT) {
      const url = `api/v3/cascade/report/debug?verificationIds=${options}&access_token=${token}`
      this.downloadItem(url, 'Report', 'reportProgress', 'downloadReportMsg', 'reportTime')
    }
  }

  debugVerificationChange = (id, idType) => {
    this.setState({
      [idType]: id
    }, () => {
      this.getDownloadOptions()
    })
  }

  render() {
    const { maxWidth, maxHeight, menuType, designId,
      currentCheckValues, downloadList,
      irVerificationId, pathRVerificationId, impVerificationId,
      DCRList, IRList, ImpedanceList, designTreeVerificationId, DesignTreeList,
      PowerTreeList, SingleTreeList,
      reportSelectName
    } = this.state;
    const { downloadMessage, progress } = this.updateProgress()
    const { currentProjectDesigns } = this.props
    const messageClassName = downloadMessage && (downloadMessage.indexOf('successfully') > -1 || downloadMessage.indexOf('Preparing') > -1)
      ? 'download-success'
      : 'download-error';
    const height = this.getHeight();
    const disabled = this.getDownloadButtonDisabled();
    const showFullPowerTree = userDefaultSettings.getUseFullPCBPowerTree()

    const content = (
      <Panel
        className='aurora-debug-panel panel-x-scroll-hidden'
        title={<div className='aurora-debug-title'>Debug</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 950 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <div className='aurora-debug-content clear' id="debug-content-id">
          <div className='aurora-debug-menu'>
            <div className={menuType === PCB_PARSING ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, 'PCBParsing')}>
              <span className='debug-menu-item-span'>Layout Parsing</span>
              {menuType === PCB_PARSING && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={designId}
                  disabled={menuType !== PCB_PARSING ? true : false}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={this.debugDesignChange}
                >
                  {currentProjectDesigns.map(item => {
                    return (
                      <Option className={item.category === DESIGN_FAILED && 'design-failed-Option'} key={item.name} value={item.id} title={item.name}>{item.name}</Option>
                    )
                  })}
                </Select>
              </span>}
            </div>
            <div className={menuType === DESIGN_TREE || menuType === POWER_TREE || menuType === SINGLE_TREE ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, DESIGN_TREE)}>
              <span className='debug-menu-item-span'>Power Tree</span>
              {(menuType === DESIGN_TREE || menuType === POWER_TREE || menuType === SINGLE_TREE) && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={designTreeVerificationId}
                  disabled={menuType === DESIGN_TREE || menuType === POWER_TREE || menuType === SINGLE_TREE ? false : true}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={(id) => this.debugVerificationChange(id, 'designTreeVerificationId')}
                >
                  {
                    showFullPowerTree ? <OptGroup label={FULL_TITLE}>
                      {DesignTreeList.map(item =>
                        <Option
                          key={item.id}
                          title={item.name}
                        >
                          {item.name}
                        </Option>
                      )}
                    </OptGroup> : null
                  }
                  {/* <OptGroup label={SINGLE_TITLE}>
                    {SingleTreeList.map(item =>
                      <Option
                        key={item.id}
                        title={item.name}
                      >
                        {item.name}
                      </Option>
                    )}
                  </OptGroup> */}
                  <OptGroup label={TREE_TITLE}>
                    {PowerTreeList.map(item =>
                      <Option
                        key={item.id}
                        title={item.name}
                      >
                        {item.name}
                      </Option>
                    )}
                  </OptGroup>
                </Select>
              </span>}
            </div>
            <div className={menuType === DCR ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, DCR)}>
              <span className='debug-menu-item-span'>{DCR_TITLE}</span>
              {menuType === DCR && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={pathRVerificationId}
                  disabled={!DCRList.length ? true : false}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={(id) => this.debugVerificationChange(id, 'pathRVerificationId')}
                >
                  {DCRList.map(item =>
                    <Option
                      key={item.id}
                      title={item.name}
                    >
                      {item.name}
                    </Option>
                  )}
                </Select>
              </span>}
            </div>
            <div className={menuType === IR_EXPLORER ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, IR_EXPLORER)}>
              <span className='debug-menu-item-span'>{IR_TITLE}</span>
              {menuType === IR_EXPLORER && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={irVerificationId}
                  disabled={!IRList.length ? true : false}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={(id) => this.debugVerificationChange(id, 'irVerificationId')}
                >
                  {IRList.map(item =>
                    <Option
                      key={item.id}
                      title={item.name}
                    >
                      {item.name}
                    </Option>
                  )}
                </Select>
              </span>}
            </div>
            {/* <div className={menuType === POWER_TREE ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, POWER_TREE)}>
              <span className='debug-menu-item-span'>Power Tree Explorer</span>
              {menuType === POWER_TREE && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={powerTreeVerificationId}
                  disabled={menuType !== POWER_TREE ? true : false}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={(id) => this.debugVerificationChange(id, 'powerTreeVerificationId')}
                >
                  {PowerTreeList.map(item =>
                    <Option
                      key={item.id}
                      title={item.name}
                    >
                      {item.name}
                    </Option>
                  )}
                </Select>
              </span>}
            </div> */}
            <div className={menuType === IMPEDANCE ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, IMPEDANCE)}>
              <span className='debug-menu-item-span'>{IMPEDANCE_TITLE}</span>
              {menuType === IMPEDANCE && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={impVerificationId}
                  disabled={!ImpedanceList.length ? true : false}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={(id) => this.debugVerificationChange(id, 'impVerificationId')}
                >
                  {ImpedanceList.map(item =>
                    <Option
                      key={item.id}
                      title={item.name}
                    >
                      {item.name}
                    </Option>
                  )}
                </Select>
              </span>}
            </div>
            <div className={menuType === REPORT ? 'aurora-debug-menu-item-selected' : "aurora-debug-menu-item"}
              onClick={(e) => this.changeDebugItem(e, REPORT)}>
              <span className='debug-menu-item-span'>Report</span>
              {menuType === REPORT && <span onClick={(e) => this.selectClick(e)}>
                <Select
                  value={reportSelectName}
                  disabled={menuType !== REPORT ? true : false}
                  className="aurora-debug-select-simulation-width aurora-select aurora-debug-select"
                  popupClassName='aurora-debug-select-down-drop'
                  popupMatchSelectWidth={false}
                  onChange={(id) => this.debugVerificationChange(id, 'reportSelectName')}
                >
                  {[TEMPLATE_TITLE, IMPEDANCE_TITLE].map(item =>
                    <Option
                      key={item}
                      title={item}
                    >
                      {item}
                    </Option>
                  )}
                </Select>
              </span>}
            </div>
          </div>
          <div className='aurora-debug-list-content'>
            <CheckboxGroup
              value={currentCheckValues}
              onChange={(values) => this.changeDebugCheckList(values)}
              className='debug-download-checkbox-group-box'
            >
              {downloadList.map(item => {
                return (
                  <Checkbox
                    key={item.option}
                    value={item.option}
                    className='debug-download-checkbox-group'
                  >
                    <span>{item.name}</span>
                  </Checkbox>
                )
              })}
            </CheckboxGroup>
            {downloadMessage && progress === -1 ? <div className={`${messageClassName} aurora-debug-download-message`}>{downloadMessage}</div> : null}
            {progress === -1 ?
              <div className="debug-download-button">
                <Button
                  title={currentCheckValues.length === 0 ? "Choose at least one option to download." : "download"}
                  disabled={disabled}
                  className='debug-download-btn'
                  type="primary"
                  ghost
                  onClick={(e) => this.DownloadDebugClick(e)}
                >Download</Button>
              </div> :
              <Progress
                size={{ height: 10 }}
                className='aurora-debug-progress'
                percent={progress}>
              </Progress>
            }
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default DebugDownloadPanel