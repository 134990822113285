import React, { Component, createRef, Fragment } from "react";
import { createPortal } from 'react-dom';
import { Radio, Checkbox, Tabs, Input, Layout, Menu, Tooltip, Divider, Switch } from "antd";
import { CloseOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { parseSPModelSelector } from '@/services/Library';
import Panel from '@/components/Panel';
import { DECAP_TOUCHSTONE, DECAP_SPICE, DECAP_RLC } from '@/constants/libraryConstants';
import SystemLibrary from "./SystemLibrary";
import UserLibrary from "./UserLibrary";
import { CUSTOM_LIBRARY } from "../../constants/treeConstants";
import CustomLibrary from './CustomLibrary';
import { getTextWidth } from "../../services/helper/getTextWidth";
import { getPanelMaxWidth } from "../../services/helper/panelSizeHelper";
import Line from "../Line";
import '@/publicCss/style.css';
import './index.css';
import { CASCADE } from "../../constants/pageType";

const CUSTOM = "Custom"
const { Sider, Content } = Layout;
export default class ModelSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //UI setting
      maxHeight: 600,
      maxWidth: 690,
      //switch
      loading: false,
      //init data
      vendor: 'Murata',
      libraryType: '',
      systeamLibraryType: 'vendor',
      userLibraryType: 'SPICE',
      fileType: 'sparameter',
      genericSubckt: [],
      DecapGeneric: props.DecapGeneric,
      selectedPartNumber: props.selectedPartNumber,
      model: props.record.model ? JSON.parse(JSON.stringify(props.record.model)) : {
        name: "",
        subcktName: "",
        id: "",
        type: "",
        libraryType: ""
      },
      rlcValue: props.record.value ? props.record.value : { r: '', l: '', c: '' },
      apply: true,
      customModelList: [],
      sweepList: [],
      applySweep: props.record.applySweep,
      siderWidth: 220
    }
    this.dialogRoot = document.getElementById('root');
    this.inputFileRef = createRef()
    this.userLibraryRef = createRef()
    this.sysLibraryRef = createRef();
  }

  componentDidMount() {
    let { record, product } = this.props;
    if (product === CASCADE) {
      let modelList = record.models && record.models.length ? JSON.parse(JSON.stringify(record.models)) : [{
        editName: "Model1",
        key: 0
      }]
      modelList.forEach((model, index) => {
        if (!model.editName) {
          model.editName = this.getModelName(model) || `Model${index + 1}`
        }
      })
      const sweepList = modelList.map(model => model.editName)
      this.setState({
        sweepList,
        modelList,
        sweepKey: sweepList[0],
        model: modelList[0]
      })
      this.setModel(modelList[0])
    } else {
      let model = record.model ? JSON.parse(JSON.stringify(record.model)) : {
        name: "",
        subcktName: "",
        id: "",
        type: "",
        libraryType: ""
      }
      this.setModel(model)
    }
  }

  getModelName = (model) => {
    let name = ''
    if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
      name = model.subcktName ? model.subcktName : ''
    } else {
      name = model.name ? model.name : ''
    }
    return name
  }

  setModel = (model) => {
    let { lastTypeInfo, noSystemLibrary } = this.props;
    if (model.libraryType === DECAP_SPICE && model.subcktName === "") {
      this.setState({ model: {}, subcktDisable: true })
    }
    let libraryType = ''
    let systeamLibraryType = ''
    let userLibraryType = ''
    let fileType = ''

    if (noSystemLibrary) {
      libraryType = 'user';
      userLibraryType = model.libraryType ? model.libraryType === DECAP_TOUCHSTONE ? 'touchstone' : 'SPICE' : 'SPICE';
      this.setState({ libraryType, systeamLibraryType, userLibraryType, fileType, model })
      return;
    }

    if (!model.libraryType) {
      libraryType = lastTypeInfo.libraryType
      systeamLibraryType = lastTypeInfo.systeamLibraryType
      userLibraryType = lastTypeInfo.userLibraryType
      fileType = lastTypeInfo.fileType
      if (libraryType === "user" && userLibraryType === "SPICE") {
        model = {
          name: lastTypeInfo.name,
          libraryType: lastTypeInfo.modelLibraryType,
          id: lastTypeInfo.modelId
        }
      } else if (libraryType === "user") {
        model = {
          libraryType: lastTypeInfo.modelLibraryType,
          id: lastTypeInfo.modelId
        }
      } else if (libraryType === "system" && systeamLibraryType === "generic") {
        model = {
          name: lastTypeInfo.name,
          libraryType: lastTypeInfo.modelLibraryType,
          id: lastTypeInfo.modelId,
        }
      }
    } else {
      switch (model.libraryType) {
        case 'generic':
          libraryType = 'system'
          systeamLibraryType = 'generic'
          break;
        case DECAP_SPICE:
          libraryType = 'user'
          userLibraryType = 'SPICE'
          break;
        case DECAP_TOUCHSTONE:
          libraryType = 'user'
          userLibraryType = 'touchstone'
          break;
        case DECAP_RLC:
          libraryType = 'user'
          userLibraryType = 'RLC'
          break;
        default:
          libraryType = 'system'
          systeamLibraryType = 'vendor'
          if (model.libraryType === 'netlist') {
            fileType = 'netlist'
          } else {
            fileType = 'sparameter'
          }
          break;
      }
      if (model.type === CUSTOM_LIBRARY) {
        libraryType = "system";
        systeamLibraryType = CUSTOM;
        this.getCustomLibrarySearchList(systeamLibraryType)
      }
    }
    this.setState({ libraryType, systeamLibraryType, userLibraryType, fileType, model }, () => {
      libraryType === 'system' && systeamLibraryType === 'vendor' &&
        this.sysLibraryRef.current.getInitPartNumber(model.name)
    })
    if (model.libraryType && model.libraryType === 'generic') {
      model.id && this.getSubcktList(model.id)
    } else if (systeamLibraryType !== CUSTOM && this.sysLibraryRef && this.sysLibraryRef.current) {
      this.sysLibraryRef.current.defaultPageList()
    }
  }

  getCustomLibrarySearchList = async (systeamLibraryType) => {
    const { record: { part, partNumber }, getCustomLibBySearch, getCustomLibraryPath } = this.props;
    if (!getCustomLibBySearch) {
      return;
    }
    let customModelList = [];
    if (partNumber) {
      const res = await getCustomLibBySearch(partNumber);
      customModelList = res ? [...res] : [];
    }

    if (!customModelList.length && part) {
      const res = await getCustomLibBySearch(part);
      customModelList = res ? [...res] : [];
    }

    if (customModelList.length) {
      customModelList.forEach(library => {
        library.path = library.type === "data" ? `${library.folderPath}/${library.name}` : library.path;
        library.displayPath = getCustomLibraryPath(library.path);
      })
      const maxNameLibrary = customModelList.length ? customModelList.sort((a, b) => { return b.name.length - a.name.length })[0] : { name: "" };
      let maxName = maxNameLibrary.name || ""
      maxName = maxName.length > "ManufacturerPartNumber".length ? maxName : "ManufacturerPartNumber";
      this.nameWidth = getTextWidth(maxName, 14, 350, "bold") + 20;

      const maxManufacturerLibrary = customModelList.length ? customModelList.sort((a, b) => { return b.manufacturer.length - a.manufacturer.length })[0] : { manufacturer: "" };
      const maxManufacturer = maxManufacturerLibrary.manufacturer || ""
      const manufacturerTitleWidth = getTextWidth("Manufacturer", 14, 350, "bold") + 20;
      const manufacturerWidth = getTextWidth(maxManufacturer, 14, 350) + 20;
      this.manufacturerWidth = manufacturerWidth > manufacturerTitleWidth ? manufacturerWidth : manufacturerTitleWidth;
    } else {
      this.nameWidth = getTextWidth("ManufacturerPartNumber", 14, 350, "bold") + 20;
      this.manufacturerWidth = getTextWidth("Manufacturer", 14, 350, "bold") + 20;
    }
    let maxWidth = this.state.maxWidth;
    if (systeamLibraryType === CUSTOM && customModelList.length) {
      const offset = this.dialogRoot.getBoundingClientRect();
      maxWidth = getPanelMaxWidth(offset);
    }
    this.setState({
      customModelList,
      maxWidth
    })
  }

  getSubcktList = (id) => {
    this.props.getSystemLibraryFile(id).then(res => {
      const { models: subckts } = parseSPModelSelector(res);
      this.setState({
        genericSubckt: subckts
      })
    })
  }

  fileTypeChange = (type) => {
    this.setState({ fileType: type })
  }

  libraryTypeChange = (e) => {
    const { product } = this.props
    const setting = {
      libraryType: e.target.value,
      selectedPartNumber: {},
      model: {},
      subcktDisable: true,
    }
    if (e.target.value === "system") {
      this.setState({
        systeamLibraryType: "vendor",
        fileType: "sparameter",
        ...setting
      }, () => {
        this.sysLibraryRef.current.defaultPageList()
      })
    } else {
      const offset = this.dialogRoot.getBoundingClientRect();
      const maxWidth = getPanelMaxWidth(offset);

      this.setState({
        userLibraryType: "SPICE",
        maxWidth: maxWidth > 750 ? 750 : maxWidth,
        ...setting
      })
    }

    if (product === CASCADE) {
      this.updateModelList(setting.model)
    }
  }

  systemLibraryTypeChange = (key) => {
    const { product } = this.props
    if (key !== CUSTOM) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const maxWidth = getPanelMaxWidth(offset);
      this.setState({
        maxWidth: maxWidth > 750 ? 750 : maxWidth
      })
    }

    this.setState({
      systeamLibraryType: key,
      model: {}
    }, () => {
      if (key === CUSTOM) {
        this.getCustomLibrarySearchList(key);
      }
      if (product === CASCADE) {
        this.updateModelList({})
      }
    })
  }

  userLibraryTypeChange = (key) => {
    this.setState({
      userLibraryType: key
    })
  }

  saveModel = () => {
    const { rlcValue, model, apply, modelList, applySweep } = this.state;
    const { product } = this.props
    if (product === CASCADE) {
      let value = rlcValue;
      let models = []
      modelList.forEach(item => {
        let _model = item;
        if ((item.libraryType === "decap_spice" && !item.subcktName)
          || ((item.libraryType === "decap_rlc" || item.libraryType === "decap_touchstone") && !item.name)
          || (item.libraryType === "generic" && !item.subcktName)
          || (item.type === "Custom" && (!item.id || !item.name))) {
          _model = {}
        }
        if (_model && Object.keys(item).filter(key => key !== 'editName' && key !== 'key').length) {
          models.push({ ..._model, key: models.length })
        }
      })
      if (!models.length) {
        return
      }
      const { record } = this.props;
      this.props.saveDecapModel(record, value, models, apply, applySweep);
    } else {
      let value = rlcValue;
      let _model = model;
      if ((model.libraryType === "decap_spice" && !model.subcktName)
        || ((model.libraryType === "decap_rlc" || model.libraryType === "decap_touchstone") && !model.name)
        || (model.libraryType === "generic" && !model.subcktName)
        || (model.type === "Custom" && (!model.id || !model.name))) {
        this.setState({
          model: {}
        })
        _model = {}
      }
      if (!_model || !Object.keys(model).length) {
        return
      }
      const { record } = this.props;
      this.props.saveDecapModel(record, value, _model, apply);
    }
  }

  closeModelSelect = (e) => {
    const { vendor, libraryType, systeamLibraryType, userLibraryType, fileType, model } = this.state
    e.stopPropagation();
    this.props.lastTypeInfoChange && this.props.lastTypeInfoChange(vendor, libraryType, systeamLibraryType, userLibraryType, fileType, model)
    this.saveModel();
    this.closeModal()
  }

  modelChange = (model) => {
    const { product } = this.props
    if (product === CASCADE) {
      this.updateModelList(model)
    }
    this.setState({ model: { ...model } }, () => {
      /* this.saveModel() */
    })
  }

  closeModal = () => {
    this.props.save()
  }

  getUniqueSweepName = (name, excludeName = null) => {
    const { sweepList } = this.state;
    let newName = name;
    let counter = 1;
    while (sweepList.includes(newName) && newName !== excludeName) {
      newName = `${name}_${counter}`;
      counter++;
    }
    return newName;
  };

  selectSweepKey = (key) => {
    const { modelList, sweepKey } = this.state
    if (sweepKey === key) {
      return
    }
    const model = modelList.find(item => item.editName === key)
    this.setModel(model)
    this.sysLibraryRef && this.sysLibraryRef.current && this.sysLibraryRef.current.resetSearchValue()
    this.setState({
      sweepKey: key,
      model,
    })
  };

  sweepNewModel = (model) => {
    const { sweepList, modelList } = this.state
    const modelName = this.getModelName(model) || `Sweep Model${sweepList.length + 1}`;
    const newSweepName = this.getUniqueSweepName(modelName);
    this.setModel(model)
    this.setState({
      sweepList: [...sweepList, newSweepName],
      sweepKey: newSweepName,
      modelList: [...modelList, { ...model, editName: newSweepName }],
      model
    });
  }

  renameSweepKey = (e, key) => {
    e && e.stopPropagation()
    this.setState({
      editingKey: key,
      inputValue: key
    })
  };

  onInputChange = (e) => {
    e && e.stopPropagation()
    this.setState({
      inputValue: e.target.value
    })
  }

  updateModelList = (model) => {
    const { sweepKey, modelList, sweepList } = this.state
    const modelName = this.getModelName(model)
    let _sweepKey = sweepKey
    if (modelName && modelName !== sweepKey) {
      _sweepKey = this.getUniqueSweepName(modelName)
      const _sweepList = sweepList.map((key) => (key === sweepKey ? _sweepKey : key));
      this.setState({
        sweepKey: _sweepKey,
        sweepList: _sweepList
      })
    }
    this.setState({
      modelList: modelList.map((item) =>
        item.editName === sweepKey ? { ...model, editName: _sweepKey } : item
      )
    })
  };

  addSweepKey = () => {
    const { sweepList, modelList } = this.state
    const baseName = `Sweep Model${sweepList.length + 1}`;
    const newSweepName = this.getUniqueSweepName(baseName);
    const newModel = {}
    this.setModel(newModel)
    this.setState({
      sweepList: [...sweepList, newSweepName],
      sweepKey: newSweepName,
      modelList: [...modelList, { ...newModel, editName: newSweepName }],
      model: newModel
    });
  };

  onBlurOrEnter = (e) => {
    e && e.stopPropagation()
    let { sweepList, editingKey, inputValue, sweepKey, modelList } = this.state;
    if (inputValue.trim() !== "" && inputValue !== editingKey) {
      const newSweepName = this.getUniqueSweepName(inputValue, editingKey);
      sweepList = sweepList.map((key) => (key === editingKey ? newSweepName : key));
      modelList = modelList.map((model) =>
        model.editName === editingKey ? { ...model, editName: newSweepName } : model
      );

      this.setState({
        sweepList,
        sweepKey: sweepKey === editingKey ? newSweepName : sweepKey,
        modelList
      });
    }
    this.setState({ editingKey: null });
  };

  removeSweepKey = (e, targetKey) => {
    e && e.stopPropagation()
    const { sweepList, sweepKey, modelList } = this.state
    let newSweepKey = sweepKey;
    const newSweepList = sweepList.filter((key) => key !== targetKey);
    const newModelList = modelList.filter((model) => model.editName !== targetKey);
    if (newSweepKey === targetKey) {
      const targetIndex = sweepList.indexOf(targetKey);
      newSweepKey = targetIndex > 0 ? newSweepList[targetIndex - 1] : newSweepList[0] || "";
    }
    const model = newModelList.find(item => item.editName === newSweepKey)
    this.setModel(model)
    this.setState({
      sweepList: newSweepList,
      sweepKey: newSweepKey,
      modelList: newModelList,
      model
    });
  };

  onSweepApplyChange = (bool) => {
    this.setState({
      applySweep: bool
    })
  }

  changeWidth = (_width) => {
    const { siderWidth } = this.state;
    this.setState({
      siderWidth: siderWidth + _width > 400 ? 400 : siderWidth + _width < 100 ? 100 : siderWidth + _width
    })
  }

  renderDialog = () => {
    let { maxHeight, maxWidth, libraryType, apply, sweepList, sweepKey, inputValue, editingKey, applySweep, siderWidth } = this.state;
    const { record: { part }, noSystemLibrary, notApply, supportSweep } = this.props;
    const title = `Model Select${part ? " - " + part : ""}`;
    const content = (
      <Panel
        className='decap-select-model-panel'
        title={<span
          className='decap-select-model-title'
          style={{ maxWidth: maxWidth - 50 }}
          title={title}
        >{title}</span>}
        onCancel={this.closeModelSelect}
        zIndex={2000}
        width={supportSweep ? maxWidth + 220 : maxWidth}
        position='panel-center'
        draggable
        minWidth={450 > maxWidth ? 450 : maxWidth}
        minHeight={800}
        maxHeight={maxHeight + 83}
        overflow={"hidden"}
      >
        <Layout className="decap-model-content-layout">
          {supportSweep && <Sider
            className='decap-model-content-sider'
            width={siderWidth}
          >
            <div className="decap-model-sweep-apply">
              <span className="decap-model-sweep-apply-title">Enable Sweeping</span>
              <Switch
                checked={sweepList.length > 1 ? applySweep : false}
                onChange={this.onSweepApplyChange}
                disabled={!(sweepList.length > 1)}
                size="small"
              />
            </div>
            <Divider className="decap-model-sweep-divider" />
            <Menu
              selectedKeys={[sweepKey]}
              mode="vertical"
              className="decap-model-content-menu"
              onSelect={(e) => { this.selectSweepKey(e.key) }}
              items={sweepList.map((key) => ({
                key,
                label: <div className="decap-model-content-menu-item">
                  {editingKey === key ? (
                    <Input
                      className="decap-model-content-menu-input"
                      value={inputValue}
                      onChange={(e) => this.onInputChange(e)}
                      onBlur={(e) => this.onBlurOrEnter(e)}
                      onPressEnter={(e) => this.onBlurOrEnter(e)}
                      autoFocus
                    />
                  ) : (
                    <div className="decap-model-content-menu-label">
                      <Tooltip
                        title={key}
                        overlayClassName="aurora-tooltip"
                        zIndex={2002}
                        placement="top"
                        autoAdjustOverflow={false}
                      >
                        <span className="decap-model-content-menu-label-text">{key}</span>
                      </Tooltip>
                      <EditOutlined
                        className="menu-icon"
                        onClick={(e) => { this.renameSweepKey(e, key); }}
                      />
                      {sweepList.length > 1 && (
                        <CloseOutlined
                          className="menu-icon"
                          onClick={(e) => { this.removeSweepKey(e, key); }}
                        />
                      )}
                    </div>
                  )}
                </div>
              }))} />
            <div className="decap-model-content-add" onClick={this.addSweepKey}>
              <PlusOutlined /> Add Sweep Model
            </div>
            <Line
              position={{ position: 'absolute', top: 0, bottom: 0, right: 0 }}
              resize={(width) => this.changeWidth(width)}
              width={1}
              showLine={true}
            />
          </Sider>}
          <Content className="decap-model-content">
            {!noSystemLibrary && <div className="decap-select-model-content" style={{ maxHeight: maxHeight }}>
              <div className="select-model-content-row">
                <div className="select-model-content-cal">
                  <Radio.Group onChange={this.libraryTypeChange} value={libraryType}>
                    <Radio value='system'>System Library</Radio>
                    <Radio value='user'>User Library</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>}
            {this.getLibraryRender(libraryType)}
            {!noSystemLibrary && !notApply && <div className="decap-select-model-content" style={{ maxHeight: maxHeight }}>
              <div className="select-model-content-row">
                <div className="select-model-content-cal">
                  <span className="apply-model-title">Apply model to same part</span>
                  <Checkbox
                    checked={apply}
                    onChange={(e) => this.onApplyChange(e)}>
                  </Checkbox>
                </div>
              </div>
            </div>}
          </Content>
        </Layout>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  getLibraryRender = (libraryType) => {
    let { maxHeight, systeamLibraryType, vendor, fileType, model,
      DecapGeneric, genericSubckt, selectedPartNumber, loading, customModelList
    } = this.state;
    const systemLibraryProps = {
      maxHeight, systeamLibraryType, vendor,
      fileType, DecapGeneric, genericSubckt,
      libraryType, selectedPartNumber, loading
    }
    const systemLibraryFunc = {
      changePartNumber: this.props.changePartNumber,
      getSubcktList: this.getSubcktList,
      modelChange: this.modelChange,
      systemLibraryTypeChange: this.systemLibraryTypeChange,
      fileTypeChange: this.fileTypeChange
    }
    const userLibraryProps = {
      model,
      decapList: this.props.decapList,
      trigger: this.props.trigger,
      userLibraryType: this.state.userLibraryType,
      noSystemLibrary: this.props.noSystemLibrary,
      typeOptions: this.props.typeOptions,
    }
    const userLibraryFunc = {
      modelChange: this.modelChange,
      getLibraryFile: this.props.getLibraryFile,
      userLibraryTypeChange: this.userLibraryTypeChange,
    }
    if (model.type === DECAP_TOUCHSTONE && model.libraryType === "folder") {
      model.name = model.folderName
    }
    switch (libraryType) {
      case "system":
        return systeamLibraryType === CUSTOM ?
          <CustomLibrary
            model={model}
            customModelList={customModelList}
            nameWidth={this.nameWidth}
            manufacturerWidth={this.manufacturerWidth}
            modelChange={this.modelChange}
            getCustomLibraryPath={this.props.getCustomLibraryPath}
            systemLibraryTypeChange={this.systemLibraryTypeChange} />
          : <SystemLibrary
            {...systemLibraryProps}
            {...systemLibraryFunc}
            model={model}
            notIncludesCustom={this.props.notIncludesCustom}
            ref={this.sysLibraryRef}
            sweep={this.props.supportSweep}
            sweepNewModel={this.sweepNewModel}
          >
          </SystemLibrary>
      default:
        return <UserLibrary
          {...userLibraryProps}
          {...userLibraryFunc}
          ref={this.userLibraryRef}>
        </UserLibrary>
    }
  }

  onApplyChange = (e) => {
    this.setState({
      apply: e.target.checked
    })
  }

  render() {
    const { inputRef } = this;
    const { text } = this.props;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}
