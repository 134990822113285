import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, InfoCircleOutlined, RedoOutlined, RetweetOutlined, ExperimentOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import EditableTable from '@/components/EditableTable';
import ModelSelect from '@/components/ModelSelect/ModelSelect';
import { getTemplateComponents } from '../../../services/Cascade/SignOffTemplate';
import { getLibraryFileInfo, getSysLibraryFile, getCustomLibBySearch } from '@/services/Cascade/library';
import { Tooltip, Descriptions } from 'antd';
import SystemLibDetail from '@/services/Cascade/DB/systemLibDetail';
import { saveTemplateCapModel, reTemplateAssignDecapModel, updateCompTableDisplay, updateDecapModel } from '../store/SignOffTemplate/action';
import { CUSTOM_LIBRARY } from '../../../constants/treeConstants';
import { getCustomLibraryPath } from '../../../services/Cascade/helper/match';
import { IGNORE } from '../../../constants/componentType';
import { MODEL_MATCH_WEIGHTS } from '../../../services/Cascade/constants';
import DecapSettingPanel from '../components/decapSettingPanel';
import { COMPONENTBASED, PARTBASED } from '../../../constants/resolution';
import './index.css';
import { CASCADE } from '../../../constants/pageType';

const columns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '20%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'components',
  width: '35%',
}, {
  title: 'Usage',
  dataIndex: 'usage',
  width: '10%'
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '20%',
}];

const DescItem = Descriptions.Item;
class SignOffContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayPartNumber: true,
      data: [],
      selectedPartNumber: {},
      modelUpdate: false,
      lastTypeInfo: {
        vendor: "",
        libraryType: 'system',
        systeamLibraryType: 'vendor',
        userLibraryType: 'SPICE',
        fileType: 'sparameter',
        name: '',
        modelLibraryType: "",
        modelId: 0,
      },
      decapSettingVisible: false
    }
  }

  componentDidMount = () => {
    const { components, designId, componentsTableDisplay } = this.props;

    const data = getTemplateComponents({ components, designId, componentsTableDisplay })
    this.setState({
      data
    }, () => {
      this.setDefaultColumns();
      this.getInitModelList()
    })

  }

  componentDidUpdate = (prevProps) => {
    const { loading, verificationId, components, designId, modelAssignLoading, templateSetupUpdate, contentLoading, componentsTableDisplay } = this.props;
    if ((!loading && loading !== prevProps.loading)
      || (!contentLoading && contentLoading !== prevProps.contentLoading)
      || verificationId !== prevProps.verificationId
      || designId !== prevProps.designId
      || JSON.stringify(components || []) !== JSON.stringify(prevProps.components || [])
      || (prevProps.modelAssignLoading && modelAssignLoading !== prevProps.modelAssignLoading)
      || (templateSetupUpdate && templateSetupUpdate !== prevProps.templateSetupUpdate)
      || componentsTableDisplay !== prevProps.componentsTableDisplay) {
      const data = getTemplateComponents({ components, designId, componentsTableDisplay })
      this.setState({
        data
      }, () => {
        this.setDefaultColumns();
        this.getInitModelList()
      })
    }

    if (verificationId !== prevProps.verificationId) {
      this.setState({
        displayPartNumber: true
      })
    }
  }

  getInitModelList = async () => {
    let { data, modelUpdate } = this.state;
    if (!data || !data.length) {
      return;
    }
    let names = [];
    for (let item of data) {
      if (item.model && item.model.type === 'System' && item.model.name) {
        names.push(item.model.name);
      }
    }
    await SystemLibDetail.saveSystemModel([...new Set(names)]);
    this.setState({
      modelUpdate: !modelUpdate
    })
  }

  setDefaultColumns = () => {
    columns[0].title = () => {
      const { displayPartNumber, data } = this.state;
      const filterPartNumber = data.filter(item => !!item.partNumber);
      return (
        <div>
          <span>Part {displayPartNumber && filterPartNumber.length ? "Number" : "Name"}</span>
          {filterPartNumber.length ? <Tooltip
            title='Toggle to display part name or part number.'
            mouseLeaveDelay={0} mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >
            <RetweetOutlined
              className="impedance-part-switch-icon"
              onClick={(e) => this.changePartDisplay(e)} />
          </Tooltip> : null}
        </div>
      );
    }

    columns[0].render = (text, record) => {
      const { displayPartNumber } = this.state;
      const partText = displayPartNumber && record.partNumber ? record.partNumber : record.part;

      return <Tooltip title={record.partNumber ? this.displayPartRender(record) : null}
        mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
        <span className='cascade-part-name'>{partText}</span>
      </Tooltip>
    }

    columns[1].render = (text, record) => {
      return <div>{record.components ? record.components.map(item => item.name).join(", ") : ""}</div>
    }

    columns[3].title = () => {
      const { modelAssignLoading } = this.props;
      return (
        <Fragment>
          <span className="cascade-decap-model-title">Model</span>
          <Tooltip
            title='Re-assign decap model.'
            mouseLeaveDelay={0}
            mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >

            {modelAssignLoading ?
              <span className="cascade-decap-model-assigning">Assigning...</span>
              : <RedoOutlined
                className="cascade-decap-re-match-icon"
                onClick={this.props._reTemplateAssignDecapModel} />}
          </Tooltip>
        </Fragment>
      );
    }

    columns[2].render = (usage) => {
      return <div className={usage === IGNORE ? 'cascade-ignore-color' : ''}>{usage}</div>
    }

    columns[3].render = (text, record) => {
      const { value, model, models, applySweep } = record;
      if (record.usage === IGNORE) {
        return "";
      }
      if (value && (value.r || value.l || value.c)) {
        return <span>
          R = {(value.r && (value.r + 'Ω')) || '0'}, L = {(value.l && (value.l + 'H')) || '0H'}, C = {(value.c && (value.c + 'F')) || '0F'}
        </span>
      } else {
        if (models && models.length) {
          if (models.length === 1) {
            const model = models[0]
            let matchWeights = model.matchWeights || null;
            let name = ""
            if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
              name = model.subcktName ? model.subcktName : ''
            } else {
              name = model.name ? model.name : ''
            }
            const modelPath = model.type === 'Custom' ? getCustomLibraryPath(model.path) : null;
            return (
              <span>
                <Tooltip placement="left"
                  overlayClassName='aurora-tooltip system-library-tooltip'
                  title={model.type === CUSTOM_LIBRARY ? (modelPath || name) : this.modelContent(name)}
                  style={{ zIndex: 100000000 }}>
                  <span className="cascade-component-model-span"
                  >{name}</span>
                </Tooltip>
                {matchWeights && matchWeights <= MODEL_MATCH_WEIGHTS ? <Tooltip
                  placement="topRight"
                  overlayClassName='aurora-tooltip'
                  title="The matching similarity between the currently automatically selected Model and Part Number is low. It is recommended to reselect manually."
                >
                  <InfoCircleOutlined className="cascade-component-model-info-icon" />
                </Tooltip> : null}
              </span>
            );
          } else {
            const title = models.map(model => {
              let name = model.editName
              if (!name) {
                if (model.libraryType === 'generic' || model.libraryType === 'decap_spice') {
                  name = model.subcktName ? model.subcktName : ''
                } else {
                  name = model.name ? model.name : ''
                }
              }
              return name
            }).join(', ')
            return <Fragment>
              <Tooltip
                title={title}
                overlayClassName='aurora-tootip'
              >
                <div className='cascade-decap-sweep-model-column'>{title} </div>
              </Tooltip>
              {applySweep &&
                <Tooltip
                  title='Sweeping'
                  overlayClassName='aurora-tooltip'
                  placement='topRight'
                >
                  <ExperimentOutlined className='cascade-component-model-info-icon' />
                </Tooltip>}
            </Fragment>
          }
        }
        return ''
      }
    }

    columns[3].onCell = (record) => {
      const { DecapList, DecapGeneric } = this.props;
      const { selectedPartNumber } = this.state

      return record.usage !== IGNORE ? {
        record,
        edit: true,
        customInput: ModelSelect,
        decapList: DecapList,
        dataIndex: 'modelName',
        getLibraryFile: getLibraryFileInfo,
        getSystemLibraryFile: getSysLibraryFile,
        getSelectedModel: () => {
          return null;
        },
        saveDecapModel: this.saveDecapModel,
        onClick: (e) => this.stopProps(e),
        product: CASCADE,
        DecapGeneric: DecapGeneric,
        selectedPartNumber: selectedPartNumber,
        changePartNumber: this.changePartNumber,
        getCustomLibraryPath: getCustomLibraryPath,
        getCustomLibBySearch: getCustomLibBySearch,
        lastTypeInfo: this.state.lastTypeInfo,
        lastTypeInfoChange: this.lastTypeInfoChange,
        supportSweep: true
      } : {
        edit: false
      }
    }
  }

  modelContent = (name) => {
    let model = SystemLibDetail.getSystemModel(name);
    if (model) {
      return (
        <Descriptions size="small" column={2} bordered={true}>
          <DescItem label="Form Factor" span={2}>{model.formFactor}</DescItem>
          <DescItem label="Capacitance" span={2}>{model.capacitorValue}</DescItem>
          <DescItem label="Voltage Ratinge" span={2}>{model.voltageRating}</DescItem>
          <DescItem label="DC Bias Voltage" span={2}>{model.dcBiasVoltage}</DescItem>
          <DescItem label="Temperature" span={2}>{model.temperature}</DescItem>
        </Descriptions>
      )
    }
  }


  changePartDisplay = (e) => {
    e && e.stopPropagation();
    this.setState({
      displayPartNumber: !this.state.displayPartNumber
    })
  }

  displayPartRender = (record) => {
    const { displayPartNumber } = this.state;
    let partText = record.partNumber, partType = "Number";
    if (displayPartNumber) {
      partText = record.part;
      partType = "Name"
    }

    return <div>
      <span className="font-bold">Part {partType}: </span>
      <span>{partText}</span>
    </div>
  }

  changePartNumber = (item) => {
    this.setState({
      selectedPartNumber: item
    })
  }

  stopProps = (e) => {
    e.stopPropagation();
  }

  lastTypeInfoChange = (vendor, libraryType, systeamLibraryType, userLibraryType, fileType, model) => {
    this.setState({
      lastTypeInfo: {
        vendor,
        libraryType,
        systeamLibraryType,
        userLibraryType,
        fileType,
        name: model.name,
        modelLibraryType: model.libraryType,
        modelId: model.id
      }
    })
  }

  saveDecapModel = (record, value, models, apply, applySweep) => {
    const { applyModelList } = this.props;
    let _applyModelList = [...applyModelList]
    if (models && models.length === 1) {
      const findIndex = applyModelList.findIndex(m => m.partName === record.part);
      const applyModel = { model: models[0], partName: record.part };
      if (findIndex > -1) {
        _applyModelList[findIndex] = { ...applyModel }
      } else {
        _applyModelList = [...applyModelList, { ...applyModel }]
      }
    }
    this.props._saveTemplateCapModel(record, value, models, apply, _applyModelList, applySweep);
  }

  updateDecapSettingPanel = (boolean) => {
    this.setState({
      decapSettingVisible: boolean
    })
  }

  changeComponentsTableDisplay = (display, clearModel) => {
    this.props._updateComponentsTableDisplay(display, clearModel)
  }

  handleUpload = (option, display) => {
    this.props._updateDecapModel(option.file, display)
  }

  render() {
    const { loading, contentLoading, componentsTableDisplay } = this.props;
    const { data = [], decapSettingVisible } = this.state;
    const _data = data.map((d, index) => ({ ...d, rowIndex: index }))
    return (
      <div className='cascade-sign-off-template-table'>
        <Spin size='small' spinning={loading || contentLoading} tip={contentLoading ? "Loading..." : "Loading PCB..."}>
          <span className="font-bold cascade-setup-title-color">Cap Components</span>
          <Tooltip
            title='Click to set components.'
            overlayClassName='icon-tooltip'
          >
            <EditOutlined
              className="cascade-imp-icon"
              onClick={() => this.updateDecapSettingPanel(true)} />
          </Tooltip>
          <div className="space-10">
            <EditableTable
              rowKey={record => `${record.rowIndex}`}
              columns={columns || []}
              size="small"
              dataSource={_data || []}
              tableLayout="fixed"
              className="cascade-components-table"
            />
          </div>
        </Spin>
        {decapSettingVisible &&
          <DecapSettingPanel
            componentsTableDisplay={componentsTableDisplay}
            updateDecapSettingPanel={this.updateDecapSettingPanel}
            hasDecapGroup={false}
            handleUpload={this.handleUpload}
            changeComponentsTableDisplay={this.changeComponentsTableDisplay}
          />
        }
      </div>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId, applyModelList },
    SignOffTemplate: { data = {}, verificationId, loading, contentLoading, modelAssignLoading, templateSetupUpdate, templateComponentsTableDisplay },
    library: { DecapList, DecapGeneric }
  } } = state;
  const { designId, signOffTemplate, components, fileName } = data || {};
  const componentsDisplay = data.componentsTableDisplay || templateComponentsTableDisplay
  return {
    openProjectId,
    designId,
    signOffTemplate,
    fileName,
    verificationId,
    loading,
    components: components || [],
    DecapList,
    DecapGeneric,
    applyModelList,
    contentLoading,
    modelAssignLoading,
    templateSetupUpdate,
    componentsTableDisplay: [COMPONENTBASED, PARTBASED].includes(componentsDisplay) ? componentsDisplay : PARTBASED
  };
}

const mapDispatch = (dispatch) => ({
  _saveTemplateCapModel(record, value, models, apply, applyModelList, applySweep) {
    dispatch(saveTemplateCapModel(record, value, models, apply, applyModelList, applySweep))
  },
  _reTemplateAssignDecapModel() {
    dispatch(reTemplateAssignDecapModel())
  },
  _updateComponentsTableDisplay(display, clearModel) {
    dispatch(updateCompTableDisplay(display, clearModel))
  },
  _updateDecapModel(file, display) {
    dispatch(updateDecapModel(file, display))
  }
})

export default connect(mapState, mapDispatch)(SignOffContent);