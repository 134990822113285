import React, { Component } from "react";
import EditableTable from "../../../../components/EditableTable";
import { CloseOutlined } from '@ant-design/icons';
import { Tooltip, Divider, Button } from 'antd';
import { newNanoId } from "../../../../services/helper/idHelper";
import "./index.css";

const columns = [{
  title: "Filter",
  dataIndex: "filter",
  key: "filter",
  width: "40%",
  textWrap: 'word-break',
  ellipsis: true
},
{
  title: "Voltage (V)",
  dataIndex: "voltage",
  key: "voltage",
  width: "30%",
  textWrap: 'word-break',
  ellipsis: true,
},
{
  title: "Note",
  dataIndex: "note",
  key: "note",
  width: "30%",
  textWrap: 'word-break',
  ellipsis: true,
}]
class PowerNets extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

    columns[0].onCell = (record) => {
      return {
        edit: true,
        dataIndex: "filter",
        record,
        handleSave: this.editFilter
      }
    }

    columns[0].render = (text, record) => {
      return (
        <div className="sierra-nets-filter-library-table-part-text">
          <span title={text}>{text}</span>
          <Tooltip
            overlayClassName="icon-tooltip"
            title="Delete the filter."
          >
            <CloseOutlined
              className="sierra-nets-filter-library-delete-icon"
              onClick={(e) => this.deleteFilter(e, record)} />
          </Tooltip>
        </div >
      );
    }

    columns[1].onCell = (record) => {
      if (!record.filter) {
        return {
          edit: false
        }
      }
      return {
        edit: true,
        dataIndex: "voltage",
        record,
        handleSave: this.editVoltage
      }
    }

    columns[2].onCell = (record) => {
      if (!record.filter || !record.voltage) {
        return {
          edit: false
        }
      }
      return {
        edit: true,
        dataIndex: "note",
        record,
        handleSave: this.editNote
      }
    }
    columns[2].render = (text) => {
      return <span title={text}>{text}</span>
    }
  }

  addNewFilter = (e) => {
    e && e.stopPropagation()
    const { data } = this.props
    let _data = [...data];
    if (!!_data.find(item => !item.id)) {
      return;
    }
    const itemData = {
      id: "",
      filter: "",
      voltage: "",
      note: "",
      netGroup: "POWER",
      rowId: newNanoId(6)
    }
    _data.unshift(itemData)
    this.props.saveData({ data: _data })
  }

  deleteFilter = (e, record) => {
    e && e.stopPropagation()
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.filter === record.filter);
    if (index < 0) {
      return;
    }
    const delFilter = record.id ? { ..._data[index] } : null;
    _data.splice(index, 1)
    this.props.saveData({ data: _data, delFilter })
  }

  editFilter = async (record, prevRecord) => {
    const { data } = this.props;
    if (prevRecord.filter === record.filter) {
      return
    }
    let _data = [...data];
    const index = _data.findIndex(item => (prevRecord.filter && item.filter === prevRecord.filter)
      || (!prevRecord.filter && !item.id && !item.filter));
    if (index < 0) {
      return;
    }

    const findItem = (_data || []).find(item => item.filter === record.filter);
    if (findItem) {
      this.props.updateMessage("This filter already exists.")
      return;
    }

    _data[index].filter = record.filter;
    this.props.saveData({ data: _data, editedFilter: { ..._data[index] }, index })
  }

  editVoltage = (record) => {
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.filter === record.filter);
    if (index < 0) {
      return;
    }
    _data[index].voltage = record.voltage;
    this.props.saveData({ data: _data, editedFilter: { ..._data[index] } })
  }

  editNote = (record) => {
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.filter === record.filter);
    if (index < 0) {
      return;
    }
    _data[index].note = record.note;
    this.props.saveData({ data: _data, editedFilter: { ..._data[index] } })
  }

  render = () => {
    const { data, isReSearch, selectedFilters } = this.props;
    const rowSelection = isReSearch ? {
      columnWidth: 36,
      selectedRowKeys: selectedFilters || [],
      onChange: (selectedRowKeys, selectedRows) => {
        this.props._updateSelectedFilters(selectedRowKeys);
      }
    } : null
    return <div>
      <div className="nets-filter-menu-list">
        {this.props.uploadExcelRender()}
        <Divider type="vertical" />
        <Button type="link" className="nets-filter-library-add-button" onClick={(e) => this.addNewFilter(e)}>Add New Filter</Button>
      </div>
      <div>
        <EditableTable
          className={`nets-filter-library-table ${isReSearch ? "nets-filter-library-search-table" : ""}`}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data || []}
          rowKey={(record) => `power_nets::${record.rowId}::${record.filter}`}
          size="small"
        />
      </div>
    </div>
  }
}

export default PowerNets;