import { RANK_MARGIN } from '../../BMA/helper/result';
import http from '../http';

/**
 * Get BMA result Json
 * @param  bmaChannelId
 * @param  simulationType
 * @export
 * @returns XMLHttpRequest
 */
export function getBMAResultJson({ bmaChannelId, simulationType }) {
  return http.get(`bma/channel/result?bmaChannelId=${bmaChannelId}&simulationType=${simulationType}`)
}

/**
* Get BMA result file
* @param  bmaChannelId
* @param  simulationType
* @export
* @returns XMLHttpRequest
*/
export function getBMAResultJsonFile({ bmaChannelId, simulationType, file, urlType }) {
  if (simulationType === RANK_MARGIN || urlType === "json") {
    return http.post(`bma/channel/result/file`, {
      bmaChannelId,
      simulationType,
      file
    })
  }
  return http.post(`bma/channel/result/file`, {
    bmaChannelId,
    simulationType,
    file
  }, {
    responseType: 'arraybuffer'
  })
}

/**
* download channel result info
* @param  bmaChannelId
* @param  simulationTypes
* @export
* @returns XMLHttpRequest
*/
export function downloadBMAResult({ bmaChannelId, simulationTypes }) {
  return http.post(`bma/channel/result/download`, { bmaChannelId, simulationTypes })
}

/**
* delete channel result info
* @param  bmaChannelId
* @param  simulationTypes
* @export
* @returns XMLHttpRequest
*/
export function deleteBMAResult({ bmaChannelId, simulationType, folders }) {
  return http.post(`bma/channel/result/file/delete`, { bmaChannelId, simulationType, folders })
}