import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Input, message, Divider, Checkbox } from 'antd';
import ResultLayout from '@/services/Result/Public/resultLayout';
import { getImpedanceRdieCdie } from '@/services/Cascade/Impedance';
import { unitChange } from '@/services/helper/mathHelper';
import { numberCheck } from '@/services/helper/dataProcess';
import defaultColor from '@/constants/defaultColors';
import _ from 'lodash';
import { getAdaptWidthName } from '../../../../../services/helper/getTextWidth';
import RdieCdieCanvas from './rdieCdieCanvas';
import RdieCdieTable from './rdieCdieTable';

class RdieCdie extends Component {

  constructor(props) {
    super(props)
    this.state = {
      frequency: 10,
      calcValue: 10,
      newEffectValue: false,
      rdieValue: 0,
      cdieValue: 0,
      min: 0.0001,
      max: 500,
      loading: true,
      redraw: false,
      showCurrent: false
    }
    this.mapInfo = {}
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.getRdieCdieValue();
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if ((id && id !== prevProps.id)) {
      this.getRdieCdieValue();
    }
  }

  getRdieCdieValue = async () => {
    const { id } = this.props;
    const { frequency, color } = this.state;
    const newFreq = unitChange({ num: frequency, oldUnit: 'MHz', newUnit: 'Hz' }).number;
    try {
      const data = await getImpedanceRdieCdie({ verificationId: id, frequency: newFreq });
      if (data) {
        this.setState({
          rdieValue: data.rdieValue,
          cdieValue: data.cdieValue,
          showCurrent: true,
          color: color ? color : defaultColor[0]
        })
      }
      this.setState({ loading: false })
    } catch (err) {
      console.error(err)
    }
  }

  changeCalcValue = (e) => {
    this.setState({
      calcValue: e.target.value
    })
  }

  onPressEnter = (e) => {
    e && e.target.blur();
  }

  getNewFrequency = (e) => {
    const { calcValue, frequency, min, max } = this.state;
    let error = numberCheck(calcValue, true);

    if (!error && (calcValue < min || calcValue > max)) {
      error = `Value must be between ${min}MHz and ${max}MHz!`;
    }

    if (error) {
      message.error(error, 6);
      this.setState({
        calcValue: frequency
      });
      return;
    }

    this.setState({
      frequency: calcValue,
      newEffectValue: true,
      loading: true
    }, () => {
      this.getRdieCdieValue()
    })
  }

  showBar = (e, ids) => {
    e && e.stopPropagation();
    const { showCurrent } = this.state;
    this.setState({
      showCurrent: !showCurrent
    })
  }

  colorChange = (e) => {
    this.setState({
      color: e.target.value
    })
  }

  changeWidthCB = () => {
    this.setState({
      redraw: !this.state.redraw
    })
  }

  nameWidthCheck = (name) => {
    const rightItem = document.getElementById('cascade-result-effect-right-key') || {};
    const rightWidth = rightItem.clientWidth || 400;
    return getAdaptWidthName(name, rightWidth - 140, 14, 'bold')
  }

  settingRender = () => {
    const { calcValue } = this.state;
    return <div className="cascade-result-effect-setting">
      <span className="cascade-result-effect-title">Calculate Rdie & Cdie @</span>
      <Input
        addonAfter="MHz"
        className="cascade-result-effect-input"
        onChange={this.changeCalcValue}
        onPressEnter={this.onPressEnter}
        onBlur={this.getNewFrequency}
        value={calcValue}
      />
    </div>
  }

  resultNameRender = ({ name, check, level = 0 }) => {
    const { color, showCurrent } = this.state;
    const _name = this.nameWidthCheck(name)
    return <Divider orientation="left" className={`cascade-effective-result-vertically-divider`}>
      <div className='cascade-effective-result-title'>
        {check && <Checkbox
          className='cascade-effective-result-checkout-box'
          checked={showCurrent}
          onClick={(e) => this.showBar(e)}
        />}
        {color && <input
          type='color'
          className="color-select"
          value={color}
          onChange={(e) => this.colorChange(e)}
        />}
        <span className='cascade-effective-result-menu-title' style={{ paddingRight: 0 }}>
          <span title={name}>{_name}</span>
        </span>
      </div>
    </Divider>
  }

  canvasRender = () => {
    const { newEffectValue, redraw, rdieValue, cdieValue, color } = this.state;
    const { targetDie } = this.props
    return <RdieCdieCanvas
      color={color}
      loading={newEffectValue}
      redraw={redraw}
      rdieValue={rdieValue}
      cdieValue={cdieValue}
      targetDie={targetDie}
    />
  }

  tableRender = (item) => {
    const { rdieValue, cdieValue } = this.state
    const { targetDie } = this.props
    const rdie = Number(rdieValue).toFixed(2);
    const cdie = unitChange({ num: cdieValue, oldUnit: 'F', newUnit: 'pF' }).number.toFixed(2);
    const tableData = [{ die: targetDie, rdieValue: rdie, cdieValue: cdie }]
    return <RdieCdieTable
      tableData={tableData}
    />
  }

  resultLeft = () => {
    const { loading, showCurrent } = this.state;
    return <div className="cascade-result-effect-left">
      <Spin spinning={loading}>

        <div className="cascade-result-effect-left-box">
          {showCurrent && <div className='cascade-setup-border cascade-position-relative' id={`cascade-effect-box`}>
            {this.canvasRender()}
            <Divider />
            {this.tableRender()}
          </div>}
        </div>
      </Spin>
    </div>
  }

  resultRight = () => {
    return <div className="cascade-result-effect-right" id="cascade-result-effect-right-key">
      {this.settingRender()}
      {this.resultNameRender({ name: 'Current', check: true })}
    </div>
  }

  render() {
    return <ResultLayout
      defaultRightWidth={330}
      className='impedace-result-effective-layout'
      resultLeft={this.resultLeft}
      resultRight={this.resultRight}
      changeWidthCB={this.changeWidthCB}
    />
  }
}

const mapState = (state) => {
  const { CascadeReducer: { Impedance: { targetDie } } } = state
  return {
    targetDie
  };
}
export default connect(mapState)(RdieCdie)