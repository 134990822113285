import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Radio, Switch, Input, Select } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { SIGN_OFF_TEMPLATE_OPTIONS } from '../../../services/Cascade/SignOffTemplate';
import { saveTemplateConfig } from '../store/SignOffTemplate/action';
import { KEEPDECAPWITHVALUENOLESS, KEEPDECAPLIST } from '../../../services/Cascade/Impedance';
import { fastPIValueCheck } from '../../../services/helper/fastPIValueCheck';
import { numExponentialFormat } from '../../../services/helper/numberHelper';
import './index.css';

const { Option } = Select
class TemplateOptionPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      option: props.config.option || "all",
      vrmOpen: false,
      includeSense: false,
      includeDecapModel: false,
      minimizeLargeDecaps: false,
      targetMargin: '10',
      keepDecapWithValueNoLess: '100',
      keepDecapList: [],
      decapOptimizationOption: KEEPDECAPWITHVALUENOLESS
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 950),
      maxHeight: getPanelMaxHeight(offset, 400)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    const { config = {} } = this.props;
    this.setState({
      option: config.option || "all",
      vrmOpen: config.vrmOpen || false,
      includeSense: config.includeSense || false,
      includeDecapModel: config.includeDecapModel || false,
      minimizeLargeDecaps: config.minimizeLargeDecaps || false,
      targetMargin: config.targetMargin || '10',
      keepDecapWithValueNoLess: config.keepDecapWithValueNoLess || (config.decapOptimizationOption === KEEPDECAPLIST ? '' : '100'),
      keepDecapList: config.keepDecapList || [],
      decapOptimizationOption: config.decapOptimizationOption || KEEPDECAPWITHVALUENOLESS
    })
  }

  radioChange = (e, type) => {
    let value = e.target.value;
    const state = { [type]: value }
    const { error } = this.state;
    if (type === 'decapOptimizationOption' && value === KEEPDECAPWITHVALUENOLESS) {
      state['keepDecapList'] = []
      state['keepDecapWithValueNoLess'] = '100'
    } else if (type === 'decapOptimizationOption' && value === KEEPDECAPLIST) {
      state['keepDecapWithValueNoLess'] = ''
      const newError = error && error.type === 'keepDecapWithValueNoLess' ? null : error;
      this.setState({ error: newError })
    }
    this.setState({
      ...state
    });
  }

  closeModal = () => {
    const { option, vrmOpen, includeSense, includeDecapModel, minimizeLargeDecaps, targetMargin, keepDecapWithValueNoLess, keepDecapList, decapOptimizationOption } = this.state;
    this.props.saveConfig({ option, vrmOpen, includeSense, includeDecapModel, minimizeLargeDecaps, targetMargin, keepDecapWithValueNoLess, keepDecapList, decapOptimizationOption });
    this.props.closeModal(false);
  }

  changeOptions = (checked, type) => {
    const { error, minimizeLargeDecaps, includeDecapModel } = this.state
    let params = {}
    if (type === 'minimizeLargeDecaps' && !checked) {
      const newError = error && ['targetMargin', 'keepDecapWithValueNoLess'].includes(error.type) ? null : error
      params.error = newError
      this.setState({ error: newError })
    }
    if (type === 'minimizeLargeDecaps' && checked && includeDecapModel) {
      params.includeDecapModel = false
    }
    if (type === 'includeDecapModel' && checked && minimizeLargeDecaps) {
      params.minimizeLargeDecaps = false
    }
    this.setState({
      ...params,
      [type]: checked
    })
  }

  changeValue = (e, type) => {
    let value = e.target.value;
    const { error } = this.state;
    const newError = error && error.type === type ? null : error;
    this.setState({
      [type]: value,
      error: newError
    });
  }

  inputBlur = (e, inputType) => {
    const value = this.state[inputType];
    let _check = null;
    const { error } = this.state;
    _check = fastPIValueCheck({ value, inputType, product: 'Cascade' });
    if (_check) {
      e.target.focus();
      this.setState({
        error: { type: inputType, error: _check }
      });
    } else {
      let _value = value;
      if (value) {
        _value = numExponentialFormat(value);
      }
      const newError = error && error.type === inputType ? null : error;
      this.setState({
        [inputType]: _value,
        error: newError
      });
    }
  }

  changeSelectValue = (value, type) => {
    this.setState({
      [type]: value
    })
  }

  decapOptimization = () => {
    const { componentList } = this.props
    const { minimizeLargeDecaps, targetMargin, keepDecapWithValueNoLess, keepDecapList, decapOptimizationOption } = this.state
    return (
      <Fragment>
        <div className='cascade-top-template-option-item'>
          <span className='template-title-item-main template-title-item'>
            Minimize the large decaps
          </span>
          <Switch
            size="small"
            className="aurora-switch-small"
            checked={minimizeLargeDecaps}
            onChange={(checked) => this.changeOptions(checked, 'minimizeLargeDecaps')}
          />
        </div>
        {minimizeLargeDecaps && <Fragment>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main template-title-item template-title-item-sub-span'>
              Target margin
            </span>
            <Input
              value={targetMargin}
              addonAfter={'%'}
              className='template-title-item-input cascade-option-input-addonAfter'
              onChange={(e) => this.changeValue(e, 'targetMargin')}
              onBlur={(e) => this.inputBlur(e, 'targetMargin')}
            />
          </div>
          <Radio.Group value={decapOptimizationOption} onChange={(e) => this.radioChange(e, 'decapOptimizationOption')}>
            <div className='cascade-top-template-option-item'>
              <span className='template-title-item-main template-title-item template-title-item-sub-span'>
                <Radio value={KEEPDECAPWITHVALUENOLESS} >Keep the decap with value no less than</Radio>
              </span>
              <Input
                value={keepDecapWithValueNoLess}
                addonAfter={'uF'}
                disabled={decapOptimizationOption !== KEEPDECAPWITHVALUENOLESS}
                className='template-title-item-input cascade-option-input-addonAfter'
                onChange={(e) => this.changeValue(e, 'keepDecapWithValueNoLess')}
                onBlur={(e) => this.inputBlur(e, 'keepDecapWithValueNoLess')}
              />
            </div>
            <div className='cascade-top-template-option-item'>
              <span className='template-title-item-main template-title-item template-title-item-sub-span'>
                <Radio value={KEEPDECAPLIST}>Keep the following decap components</Radio>
              </span>
              <Select
                value={keepDecapList}
                disabled={decapOptimizationOption !== KEEPDECAPLIST}
                onChange={(value) => { this.changeSelectValue(value, 'keepDecapList') }}
                mode='multiple'
                className='aurora-select template-title-item-input cascade-top-option-panel-content-input'
                popupClassName='aurora-select-dropdown'
              >
                {componentList.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </div>
          </Radio.Group>
        </Fragment>
        }
      </Fragment>
    );
  }

  render() {
    const { option, maxWidth, maxHeight, vrmOpen, includeSense, includeDecapModel, error } = this.state;
    const setting = { defaultWidth: 950 }
    const content = (
      <Panel
        className='cascade-top-template-option-panel'
        title={<div className='cascade-top-template-option-panel-name'>Options</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        maxHeight={maxHeight}
        overflow={"auto"}
        minHeight="200"
      >
        <div className='cascade-top-template-option-panel-main'>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main'>Specify power domain simulation method</span>
            <Radio.Group
              value={option}
              onChange={(e) => this.radioChange(e, 'option')}
            >
              {SIGN_OFF_TEMPLATE_OPTIONS.map(item =>
                <Radio
                  value={item.key}
                  key={item.key}
                  className='template-radio-item'
                >
                  {item.title}</Radio>
              )}
            </Radio.Group>
          </div>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main template-title-item'>VRM Open</span>
            {/* <div className='template-option-item'>
              <span className='template-title-item'>VRM Open</span> */}
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={vrmOpen}
              onChange={(checked) => this.changeOptions(checked, "vrmOpen")}
            />
            {/* </div> */}
          </div>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main template-title-item'>Include Sense Ports</span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={includeSense}
              onChange={(checked) => this.changeOptions(checked, "includeSense")}
            />
            {/* </div> */}
          </div>
          <div className='cascade-top-template-option-item'>
            <span className='template-title-item-main template-title-item'>Include Decap Models in Extraction</span>
            <Switch
              size="small"
              className="aurora-switch-small"
              checked={includeDecapModel}
              onChange={(checked) => this.changeOptions(checked, "includeDecapModel")}
            />
            {/* </div> */}
          </div>
          {this.decapOptimization()}
          {error && <span className='cascade-top-option-panel-error-msg'>Error: {error.error}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    SignOffTemplate: { data = {} },
  } } = state;
  const { config = {}, components } = data || {};
  const componentList = components && components.length ? components.map(item => item.name) : []
  return {
    config,
    componentList
  };
}

const mapDispatch = (dispatch) => ({
  saveConfig(config) {
    dispatch(saveTemplateConfig(config))
  }
})

export default connect(mapState, mapDispatch)(TemplateOptionPanel);