import { CHECKBOX, INPUT, INPUTANDSELECT, SELECT } from "../../../pages/Andes_v2/AMIModel/SetupComponents/SetupInputGroup";
import { CPHY, GENERIC, HDMI, PCIE } from "../../PCBHelper/constants";

const SET_ALL_DATA_SELECT_LIST = [
  "Typ",
  "Slow",
  "Fast",
  /*  "Min",
   "Max",
   "Default" */
];

const ADS_TX = "TX", ADS_RX = "RX",
  AMI_CONTROLLER = "controller", AMI_DEVICE = 'device',
  AMI_POSITIVE = "positive", AMI_NEGATIVE = "negative",
  PRBS = "prbs",
  EYE_PROBE = "eyeProbe",
  ENCODER = "encoder",
  PARALLEL = "parallel",
  SERIES = "series";

const TX_SJ = "Tx_Sj",
  TX_DJ = "Tx_Dj",
  TX_RJ = "Tx_Rj",
  TX_SJ_FREQUENCY = "Tx_Sj_Frequency",
  TX_PJ_FREQUENCY = "Tx_PJ_Frequency",
  TX_DCD = "Tx_DCD",
  RX_SJ = "Rx_Sj",
  RX_DJ = "Rx_Dj",
  RX_RJ = "Rx_Rj",
  RX_NOISE = "Rx_Noise",
  RX_DCD = "Rx_DCD",
  TX_JITTER_PDF = "Tx_JitterPDF",
  RX_JITTER_PDF = "Rx_JitterPDF",
  RANDOM = "Random",
  DJRJ = "DJRJ",
  DUAL_DIRAC = "Dual_Dirac",
  TX_PJ_AMPLITUDE = "Tx_PJ_Amplitude",
  TX_CLOCK_DCD = "Tx_Clock_DCD",
  SIGMA = "sigma",
  JITTER_PDF_MIN = "min",
  JITTER_PDF_MAX = "max",
  JITTER_PDF_MEAN1 = "mean1",
  JITTER_PDF_MEAN2 = "mean2",
  RX_SJ_AMPLITUDE = "Rx_SJ_Amplitude",
  RX_AMPLITUDE_NOISE = "Rx_Amplitude_Noise";

const TX_JITTERS = [
  TX_SJ,
  TX_DJ,
  TX_RJ,
  TX_SJ_FREQUENCY,
  TX_DCD
],
  RX_JITTERS = [
    RX_SJ,
    RX_DJ,
    RX_RJ,
    RX_NOISE,
    RX_DCD
  ],
  PCIE_2 = "PCIe 2.0",
  PCIE_3 = "PCIe 3.0",
  PCIE_4 = "PCIe 4.0",
  PCIE_5 = "PCIe 5.0",
  MAXIMAL_LENGTH_LFSR = "Maximal Length LFSR",
  REGISTER_LENGTH = "registerLength",
  USER_DEFINED_LFSR = "User Defined LFSR",
  TAPS = "taps",
  SEED = "seed",
  USER_DEFINED_SEQUENCE = "User Defined Sequence",
  BIT_SEQUENCE = "bitSequence",
  NO_ENCODER = "No encoder",
  ENCODER_8B10B = "8B10B",
  ENCODER_64B66B = "64B66B",
  ENCODER_128B130B = "128B130B",
  ENCODER_128B132B = "128B132B",
  ENCODER_8B9B = "8B9B",
  ENCODER_16B18B = "16B18B",
  AMI_SIMULATION = "simulation",
  AMI_ADSOPTION = "ymlOptionLibraryId",
  BIT_BY_BIT = "Bit-by-bit",
  STATISTICAL = "Statistical",
  NUMBER_OF_BIT = "numberOfBits",
  ENABLE_ULTRA_LOW_BER_SIMULATION = "enableUltraLowBerSimulation",
  TOLERANCE = "tolerance",
  ADSOPTION = "adsOption",
  RELAX = "Relax",
  AUTO = "Auto",
  STRICT = "Strict",
  ERROR_BIT_RATE = "errorBitRate",
  EXTRAPOLATE_IN_BIT_BY_BIT_MODE = "extrapolateInBitByBitMode",
  BIT_RATE = "bitRate",
  TMDS_14 = "TMDS 1.4",
  TMDS_2 = "TMDS 2.0",
  FRL = "FRL",
  CLOCK_BIT_RATE = "clockBitRate",
  NOTPPU = "notppu",
  ENFORCE_PASSIVITY = "enforcePassivity",
  EYEMASK = 'eyeMask',
  DFETAPUSED = "DFETapUsed",
  PROTOCOL = "protocol",
  JITTER_PDF_LIST = [
    {
      key: RANDOM,
      list: ["Sigma"]
    },
    {
      key: DJRJ,
      list: ["Sigma", "Min", "Max"]
    },
    {
      key: DUAL_DIRAC,
      list: ["Sigma", "Mean1", "Mean2"]
    }],
  ERROR_BIT_RATE_LIST = 'berList',
  PROBERX = "probeRx",
  PROBETX = "probeTx",
  PROBE_LIST = [PROBERX, PROBETX];

const AMI_INTERFACE_TYPE_LIST = [{
  key: PCIE_2,
  title: PCIE_2,/* <Fragment>
    <span className="andes-ami-type-title">PCIe 2.0 </span>
    (5 Gbps)
  </Fragment> */
  bitRate: "5 Gbps",
  encoder: ENCODER_8B10B
},
{
  key: PCIE_3,
  title: PCIE_3,/* <Fragment>
    <span className="andes-ami-type-title">PCIe 3.0 </span>
    (8 Gbps)
  </Fragment>, */
  bitRate: "8 Gbps",
  encoder: ENCODER_128B130B
},
{
  key: PCIE_4,
  title: PCIE_4,/* <Fragment>
    <span className="andes-ami-type-title">PCIe 4.0 </span>
    (16 Gbps)
  </Fragment>, */
  bitRate: "16 Gbps",
  encoder: ENCODER_128B130B
},
{
  key: PCIE_5,
  title: PCIE_5,/*  <Fragment>
    <span className="andes-ami-type-title">PCIe 5.0 </span>
    (32 Gbps)
  </Fragment>, */
  bitRate: "32 Gbps",
  encoder: ENCODER_128B130B
}];

const AMI_HDMI_TYPE_LIST = [{
  key: TMDS_14,
  title: "TMDS (HDMI Version <=1.4)",
  bitRate: "3.4 Gbps",
  clockBitRate: "340 Mbps",
  encoder: ENCODER_8B10B
}, {
  key: TMDS_2,
  title: "TMDS (HDMI Version 2.0)",
  bitRate: "6.0 Gbps",
  clockBitRate: "600 Mbps",
  encoder: ENCODER_8B10B
},
{
  key: FRL,
  title: "FRL (HDMI Version 2.1+)",
  bitRate: "6 Gbps",
  encoder: ENCODER_16B18B
}];

const AMI_PRBS_MODE_LIST = [{
  key: MAXIMAL_LENGTH_LFSR,
  title: MAXIMAL_LENGTH_LFSR,
  children: [{
    key: REGISTER_LENGTH,
    title: "Register length",
    type: "input",
    defaultValue: 8
  }]
},
{
  key: USER_DEFINED_LFSR,
  title: USER_DEFINED_LFSR,
  children: [{
    key: TAPS,
    title: "Taps",
    type: "input",
    defaultValue: "10001110"
  }, {
    key: SEED,
    title: "Seed",
    type: "input",
    defaultValue: "10101010"
  }]
},
{
  key: USER_DEFINED_SEQUENCE,
  title: USER_DEFINED_SEQUENCE,
  children: [{
    key: BIT_SEQUENCE,
    title: "Bit Sequence",
    type: "input",
    defaultValue: "1010101010101"
  }]
}];

const CPHY_AMI_PRBS_MODE_LIST = [{
  key: MAXIMAL_LENGTH_LFSR,
  title: MAXIMAL_LENGTH_LFSR,
  children: [{
    key: REGISTER_LENGTH,
    title: "Register length",
    type: "input",
    defaultValue: 9
  }]
},
{
  key: USER_DEFINED_LFSR,
  title: USER_DEFINED_LFSR,
  children: [{
    key: TAPS,
    title: "Taps",
    type: "input",
    defaultValue: "100011100"
  }, {
    key: SEED,
    title: "Seed",
    type: "input",
    defaultValue: "101010100"
  }]
},
{
  key: USER_DEFINED_SEQUENCE,
  title: USER_DEFINED_SEQUENCE,
  children: [{
    key: BIT_SEQUENCE,
    title: "Bit Sequence",
    type: "input",
    defaultValue: "1010101010101"
  }]
}];

const AMI_ENCODER_LIST = [{
  key: NO_ENCODER,
  title: "No Encoder",
}, {
  key: ENCODER_8B10B,
  title: "8b/10b"
}, {
  key: ENCODER_64B66B,
  title: "64b/66b"
}, {
  key: ENCODER_128B130B,
  title: "128b/130b"
}, {
  key: ENCODER_128B132B,
  title: "128b/132b"
}, {
  key: ENCODER_8B9B,
  title: "8b/9b"
}];

const AMI_SIM_MODE_LIST = [{
  key: BIT_BY_BIT,
  title: BIT_BY_BIT,
  children: [{
    key: NUMBER_OF_BIT,
    title: "Number of bits",
    type: "input",
    defaultValue: "10000"
  }/* , {
    key: ENABLE_ULTRA_LOW_BER_SIMULATION,
    title: "Enable ultra low BER (<1e-16) simulation",
    type: "checkbox",
    defaultValue: false
  } */]
},
{
  key: STATISTICAL,
  title: STATISTICAL,
  children: []
}];

const TOLERANCE_LIST = [{
  key: RELAX,
  title: RELAX
}, {
  key: AUTO,
  title: AUTO
}, {
  key: STRICT,
  title: STRICT
}];

const FIR_TAPS = "FIR taps",
  DE_EMPHASIS = "De-emphasis",
  CTLE = "CTLE",
  FFE = "FFE",
  ENABLE_CTLE_OR_FFE = "enableCTLEOrFFE",
  NUMBER_OF_PRECURSOR_TAPS = "numberOfPrecursorTaps",
  NUMBER_OF_POSTCURSOR_TAPS = "numberOfPostcursorTaps",
  TAP_INTERVAL = "tapInterval",
  PRECURSOR_TAPS = "precursorTaps",
  POSTCURSOR_TAPS = "postcursorTaps",
  DE_EMPHASIS_VALUE = "deEmphasis",
  CTLE_TYPE = "CTLEType",
  DC_GAIN = "dcGain",
  AC_GAIN = "acGain",
  POLE1 = "pole1",
  POLE2 = "pole2",
  ZERO1 = "zero1",
  NUMBER_OF_POLES = "numberOfPoles",
  NUMBER_OF_ZEROS = "numberOfZeros",
  POLES = "poles",
  ZEROS = "zeros",
  FFE_TYPE = "FFEType",
  ADAPTIVE_EQUALIZATION = "adaptiveEqualization",
  DFE_TYPE = "DFEType",
  DFE_ADAPTIVE_EQUALIZATION = "DFEAdaptiveEqualization",
  NUMBER_OF_TAPS = "numberOfTaps",
  SLICER_OUTPUT = "slicerOutput",
  DFE = "DFE",
  PRESET = 'preset',
  PREFACTOR = 'prefactor',
  EQ_NONE = "None",
  OPTIMIZED = "Optimized",
  MANUAL = "Manual";

const TX_EQ_TYPES = [
  {
    key: EQ_NONE,
    title: EQ_NONE
  }, {
    key: FIR_TAPS,
    title: "FIR-taps"
  }, {
    key: DE_EMPHASIS,
    title: "De-emphasis"
  }],
  RX_EQ_TYPES = [{
    key: EQ_NONE,
    title: EQ_NONE
  }, {
    key: CTLE,
    title: CTLE
  }, {
    key: FFE,
    title: FFE
  }],
  FIR_TAPS_OPTIONS = [
    {
      key: NUMBER_OF_PRECURSOR_TAPS,
      title: "Number of Pre Cursor Taps",
      type: "input",
      sub: {
        key: PRECURSOR_TAPS,
        title: "Tap Values",
        type: "tagsInput"
      }
    },
    {
      key: NUMBER_OF_POSTCURSOR_TAPS,
      title: "Number of Post Cursor Taps",
      type: "input",
      sub: {
        key: POSTCURSOR_TAPS,
        title: "Tap Values",
        type: "tagsInput"
      }
    },
    {
      key: TAP_INTERVAL,
      title: "Tap Interval",
      type: "input",
      value: "1.0"
    }
  ],
  DE_EMPHASIS_OPTIONS = [
    {
      key: DE_EMPHASIS_VALUE,
      title: "De Emphasis (dB)",
      type: "input",
      value: "0"
    },
    {
      key: TAP_INTERVAL,
      title: "Tap Interval",
      type: "input",
      value: "1.0"
    }
  ],
  CTLE_OPTIONS = [{
    key: CTLE_TYPE,
    title: 'Setting',
    type: "select",
    list: ["Preset", "Custom"],
    value: "Preset"
  }],
  CTLE_DEFAULT_OPTIONS = [
    {
      key: DC_GAIN,
      title: "DC Gain",
      type: "input",
      value: "1.0",
      disabled: true
    }, {
      key: POLE1,
      title: "Pole 1",
      type: "input",
      value: "18.6 GHz",
      unit: "GHz",
      disabled: true
    }, {
      key: POLE2,
      title: "Pole 2",
      type: "input",
      value: "14.1 GHz",
      unit: "GHz",
      disabled: true
    }, {
      key: ZERO1,
      title: "Zero 1",
      type: "input",
      value: "10.5 GHz",
      unit: "GHz",
      disabled: true
    }
  ],
  // This variable is not being used
  // CTLE_PRESET_OPTIONS = getCtlePresetOptions(),
  CTLE_CUSTOM_OPTIONS = [{
    key: PREFACTOR,
    title: "Prefactor",
    type: "input",
    value: "1.0",
  }, {
    key: NUMBER_OF_POLES,
    title: "Number of Poles",
    type: "input",
    value: "2",
    sub: {
      key: POLES,
      title: "Frequencies",
      type: "tagsInput",
      unit: "GHz",
      unitOptions: ["GHz", "MHz", "KHz", "Hz"],
      value: ["18.6 GHz", "14.1 GHz"]
    }
  }, {
    key: NUMBER_OF_ZEROS,
    title: "Number of Zeros",
    type: "input",
    value: "1",
    sub: {
      key: ZEROS,
      title: "Frequencies",
      type: "tagsInput",
      unit: "GHz",
      unitOptions: ["GHz", "MHz", "KHz", "Hz"],
      value: ["10.5 GHz"]
    }
  }],
  FFE_OPTIONS = [{
    key: FFE_TYPE,
    title: 'Setting',
    type: "select",
    list: ["Optimized", "Manual"],
    value: "Optimized"
  }],
  FFE_MANUAL_OPTIONS = [{
    key: ADAPTIVE_EQUALIZATION,
    title: "Adaptive Equalization",
    type: "checked",
    value: "no"
  }, {
    key: NUMBER_OF_PRECURSOR_TAPS,
    title: "Number of Pre Cursor Taps",
    type: "input",
    sub: {
      key: PRECURSOR_TAPS,
      title: "Tap Values",
      type: "tagsInput"
    }
  },
  {
    key: NUMBER_OF_POSTCURSOR_TAPS,
    title: "Number of Post Cursor Taps",
    type: "input",
    sub: {
      key: POSTCURSOR_TAPS,
      title: "Tap Values",
      type: "tagsInput"
    }
  }],
  DFE_OPTIONS = [{
    key: DFE_TYPE,
    title: 'DFE',
    type: "select",
    list: ["Custom", "Protocol"] /* ["Optimized", "Manual"] */,
    value: "Custom"
  }],
  DFE_OPTIMIZED_OPTIONS = [{
    key: NUMBER_OF_TAPS,
    title: "Tap Values",
    type: "input",
    inputHide: true,
    value: '6',
    sub: {
      key: TAPS,
      title: "",
      type: "tagsInput"
    }
  }, {
    key: DFE_ADAPTIVE_EQUALIZATION,
    title: "Adaptive Equalization",
    type: "checked",
    value: "no"
  }, {
    key: SLICER_OUTPUT,
    title: "Slicer Output",
    type: "select",
    list: [{ key: 'yes', title: '1/-1' }, { key: 'no', title: '1/0' }],
    // type: "checked",
    value: "yes"
  }],
  DFE_SETUP_OPTIONS = [{
    key: PRESET,
    title: "Protocol",
    type: "select",
    list: [],
    value: ""
  }, {
    key: NUMBER_OF_TAPS,
    title: "Number of Taps",
    type: "input",
    value: '6',
  }, {
    key: TAPS,
    title: "Tap Values",
    type: "tagsInput"
  }, {
    key: DFE_ADAPTIVE_EQUALIZATION,
    title: "Adaptive Equalization",
    type: "checked",
    value: "no"
  }, {
    key: SLICER_OUTPUT,
    title: "Slicer Output",
    type: "select",
    list: [{ key: 'yes', title: '1/-1' }, { key: 'no', title: '1/0' }],
    // type: "checked",
    value: "yes"
  }],
  DFE_ALL_OPTIONS = [DFE_TYPE, PRESET, DFE_ADAPTIVE_EQUALIZATION, NUMBER_OF_TAPS, TAPS, SLICER_OUTPUT, DFETAPUSED],
  DFE_FILTER_OPTIONS = [DFE_ADAPTIVE_EQUALIZATION, NUMBER_OF_TAPS, TAPS, SLICER_OUTPUT];
const ALLOW_VALUE_EMPTY_PARAMS = [
  JITTER_PDF_MIN,
  JITTER_PDF_MAX,
  JITTER_PDF_MEAN1,
  JITTER_PDF_MEAN2,
  NUMBER_OF_PRECURSOR_TAPS,
  NUMBER_OF_POSTCURSOR_TAPS,
  NUMBER_OF_TAPS,
  NUMBER_OF_ZEROS,
  NUMBER_OF_POLES
],
  IBIS_TX_JITTERS = [
    TX_DCD,
    TX_CLOCK_DCD,
    TX_PJ_AMPLITUDE,
    TX_PJ_FREQUENCY,
    TX_JITTER_PDF
  ],
  IBIS_RX_JITTERS = [
    RX_SJ_AMPLITUDE,
    RX_AMPLITUDE_NOISE,
    RX_JITTER_PDF
  ],
  EQ_TAPS = [
    NUMBER_OF_PRECURSOR_TAPS,
    NUMBER_OF_POSTCURSOR_TAPS,
    NUMBER_OF_TAPS,
    NUMBER_OF_ZEROS,
    NUMBER_OF_POLES],
  CPHY_RX_EQ_TYPES = [{
    key: false,
    title: EQ_NONE
  }, {
    key: true,
    title: CTLE
  }];


function getDCGainDefaultValue(DBNumber) {
  switch (DBNumber) {
    case "0 dB":
      return "1";
    case "1 dB":
      return "0.89125";
    case "2 dB":
      return "0.79433";
    case "3 dB":
      return "0.70795";
    case "4 dB":
      return "0.63096";
    case "5 dB":
      return "0.56234";
    case "6 dB":
      return "0.50119";
    case "7 dB":
      return "0.44668";
    case "8 dB":
      return "0.39811";
    case "9 dB":
      return "0.35481";
    case "10 dB":
      return "0.31623";
    case "11 dB":
      return "0.28184";
    case "12 dB":
      return "0.25119";
    default: return null;
  }
}

function getPoleDefaultValue(genNumber, unit) {
  let poleList = [], zeroList = [];
  switch (genNumber) {
    case "Gen2":
      poleList = ['1.5', '5'];
      break;
    case "Gen3":
      poleList = ['2', '8'];
      break;
    case "Gen4":
      poleList = ['2', '16'];
      break;
    case "Gen5":
      poleList = ['0.7425', '9.5', '28', '28'];
      zeroList = ['0.45'];
      break;
    default: break;
  }

  const poles = poleList.map(item => { return `${item} ${unit}` });
  const zeros = zeroList.map(item => { return `${item} ${unit}` });

  return { poles, zeros }
}

function getList(type, genList, dbList) {
  let list = [];
  genList.forEach(genNumber => {
    const { poles, zeros } = getPoleDefaultValue(`Gen${genNumber}`, 'GHz');
    dbList.forEach(dbNumber => {
      const dcGain = getDCGainDefaultValue(`${dbNumber} dB`);
      const option = `${type} Gen${genNumber} (${dbNumber} dB)`;

      let value = {
        poles,
        [AC_GAIN]: type === 'PCIE' ? '1' : '1.41254',
        [DC_GAIN]: dcGain,
        numberOfPoles: poles && poles.length ? poles.length.toString() : null
      }
      if (zeros && zeros.length) {
        value.zeros = zeros;
        value.numberOfZeros = zeros.length.toString()
      }
      list.push({
        option,
        value,
      })
    })
  })
  return list
}
// const usb3List = getList('HDMI', ['3,6,8,10', '12'], ['0', '1', '2', '3', '4', '5', '6'])
function getPresetOption(serdesType) {
  // PCIe 3/4/5
  const pcieList = getList('PCIE', ['3', '4', '5'], ['6', '7', '8', '9', '10', '11', '12'])

  // USB3
  let list = [{
    option: 'USB3 Gen1 Short Channel',
    value: {
      poles: ['0.65 GHz', '10 GHz'],
      zeros: ['0.65 GHz'],
      [DC_GAIN]: '1',
      [AC_GAIN]: '1',
      numberOfPoles: '2',
      numberOfZeros: '1'
    }
  },
  {
    option: 'USB3 Gen1 Long Channel',
    value: {
      poles: ['1.95 GHz', '5 GHz'],
      zeros: ['0.65 GHz'],
      [DC_GAIN]: '0.667',
      [AC_GAIN]: '1',
      numberOfPoles: '2',
      numberOfZeros: '1'
    }
  }];
  const usb3List = getList('USB3', ['2'], ['0', '1', '2', '3', '4', '5', '6'])

  let HDMIList = getHDMIPresetOptions();
  const cphyList = getCPHYPresetOptions();
  switch (serdesType) {
    case PCIE:
      return pcieList;
    case HDMI:
      return HDMIList;
    case CPHY:
      return cphyList;
    case GENERIC:
      return [...pcieList, ...list, ...usb3List, ...HDMIList]
    default: return []
  }
}

function getCPHYPresetOptions() {
  function getSameValue(type, pole1) {
    return {
      option: `MIPI CPHY ${type} Ch. (Pole ${pole1}G Informative)`,
      value: {
        numberOfPoles: '2',
        numberOfZeros: '1'
      }
    }
  }

  const optionList = [];
  const typePole1List = ['Long 1.6', 'Long 1.8', 'Long 1.9', 'Long 2.1', 'Long 2.2', 'Long 2.4',
    'Short 2.8', 'Short 3.1', 'Short 3.4', 'Short 3.6', 'Short 3.9', 'Short 4.2',
    'Std 2.0', 'Std 2.2', 'Std 2.4', 'Std 2.6', 'Std 2.8', 'Std 3.0'
  ];

  for (const typePole1 of typePole1List) {
    const [type, pole1] = typePole1.split(' ');
    const sameValue = getSameValue(type, pole1);
    const value = getCPHYDCGainZeroPole(`${type}${pole1}`);
    optionList.push({ option: sameValue.option, value: { ...sameValue.value, ...value } });
  }

  return optionList;
}

function getCPHYDCGainZeroPole(typePole1) {
  switch (typePole1) {
    case 'Long1.6':
      return {
        [DC_GAIN]: '0.562341',
        zeros: ['0.8 GHz'],
        poles: ['1.6 GHz', '10 GHz']
      };
    case 'Long1.8':
      return {
        [DC_GAIN]: '0.530884',
        zeros: ['0.8 GHz'],
        poles: ['1.8 GHz', '10 GHz']
      };
    case 'Long1.9':
      return {
        [DC_GAIN]: '0.501187',
        zeros: ['0.8 GHz'],
        poles: ['1.9 GHz', '10 GHz']
      };
    case 'Long2.1':
      return {
        [DC_GAIN]: '0.473151',
        zeros: ['0.8 GHz'],
        poles: ['2.1 GHz', '10 GHz']
      };
    case 'Long2.2':
      return {
        [DC_GAIN]: '0.446684',
        zeros: ['0.8 GHz'],
        poles: ['2.2 GHz', '10 GHz']
      };
    case 'Long2.4':
      return {
        [DC_GAIN]: '0.421697',
        zeros: ['0.8 GHz'],
        poles: ['2.4 GHz', '10 GHz']
      };
    case 'Short2.8':
      return {
        [DC_GAIN]: '0.562341',
        zeros: ['1.4 GHz'],
        poles: ['2.8 GHz', '14 GHz']
      };
    case 'Short3.1':
      return {
        [DC_GAIN]: '0.530884',
        zeros: ['1.4 GHz'],
        poles: ['3.1 GHz', '14 GHz']
      };
    case 'Short3.4':
      return {
        [DC_GAIN]: '0.501187',
        zeros: ['1.4 GHz'],
        poles: ['3.4 GHz', '14 GHz']
      };
    case 'Short3.6':
      return {
        [DC_GAIN]: '0.473151',
        zeros: ['1.4 GHz'],
        poles: ['3.6 GHz', '14 GHz']
      };
    case 'Short3.9':
      return {
        [DC_GAIN]: '0.446684',
        zeros: ['1.4 GHz'],
        poles: ['3.9 GHz', '14 GHz']
      };
    case 'Short4.2':
      return {
        [DC_GAIN]: '0.421697',
        zeros: ['1.4 GHz'],
        poles: ['4.2 GHz', '14 GHz']
      };
    case 'Std2.0':
      return {
        [DC_GAIN]: '0.562341',
        zeros: ['1 GHz'],
        poles: ['2.0 GHz', '10 GHz']
      };
    case 'Std2.2':
      return {
        [DC_GAIN]: '0.530884',
        zeros: ['1 GHz'],
        poles: ['2.2 GHz', '10 GHz']
      };
    case 'Std2.4':
      return {
        [DC_GAIN]: '0.501187',
        zeros: ['1 GHz'],
        poles: ['2.4 GHz', '10 GHz']
      };
    case 'Std2.6':
      return {
        [DC_GAIN]: '0.473151',
        zeros: ['1 GHz'],
        poles: ['2.6 GHz', '10 GHz']
      };
    case 'Std2.8':
      return {
        [DC_GAIN]: '0.446684',
        zeros: ['1 GHz'],
        poles: ['2.8 GHz', '10 GHz']
      };
    case 'Std3.0':
      return {
        [DC_GAIN]: '0.421697',
        zeros: ['1 GHz'],
        poles: ['3.0 GHz', '10 GHz']
      };
    default: return {};
  }
}

function getHDMIPresetOptions(genKeys, gainKeys) {
  if (!genKeys) {
    genKeys = [`3,6,8,10`, '12']
  }
  if (!gainKeys) {
    gainKeys = ['1', '2', '3', '4', '5', '6', '7', '8']
  }
  let HDMIList = [];
  for (let genInfo of genKeys) {
    for (let gainInfo of gainKeys) {
      const acGain = getHDMIAcGain(gainInfo);
      HDMIList.push({
        option: `HDMI ${genInfo}Gbps (${gainInfo} dB)`,
        value: {
          poles: ['3.5 GHz', '10 GHz'],
          [AC_GAIN]: acGain,
          numberOfPoles: '2'
        }
      })
    }
  }
  return HDMIList;
}

function getHDMIAcGain(DBNumber) {
  switch (DBNumber) {
    case "1":
      return "1.35";
    case "2":
      return "1.57";
    case "3":
      return "1.8";
    case "4":
      return "2.05";
    case "5":
      return "2.32";
    case "6":
      return "2.63";
    case "7":
      return "2.97";
    case "8":
      return "3.34";
    default: return null;
  }
}

function getPresetInfo(preset, serdesType) {
  const list = getPresetOption(serdesType);

  const presetList = list.map(item => item.option);
  let index = 0;
  if (preset) {
    const findIndex = list.findIndex(item => item.option === preset)
    if (findIndex > -1) { index = findIndex }
  }
  const { option, value } = list[index];
  return { presetList, option, value }
}

function getCtlePresetOptions(preset, serdesType) {
  const { presetList, option, value } = getPresetInfo(preset, serdesType)
  const optionsList = [{
    key: PRESET,
    title: "Preset",
    type: "select",
    list: presetList,
    value: option
  }, {
    key: DC_GAIN,
    title: "DC Gain",
    type: "input",
    value: value[DC_GAIN],
    disabled: !value[DC_GAIN] ? true : false,
  }, {
    key: AC_GAIN,
    title: "AC Gain",
    type: "input",
    value: value[AC_GAIN],
    disabled: !value[AC_GAIN] ? true : false,
  }, {
    key: NUMBER_OF_POLES,
    title: "Number of Poles",
    type: "input",
    value: value[NUMBER_OF_POLES],
    disabled: !value[POLES] ? true : false,
    sub: {
      key: POLES,
      title: "Frequencies",
      type: "tagsInput",
      unit: "GHz",
      unitOptions: ["GHz", "MHz", "KHz", "Hz"],
      value: value[POLES]
    }
  }, {
    key: NUMBER_OF_ZEROS,
    title: "Number of Zeros",
    type: "input",
    value: value[NUMBER_OF_ZEROS],
    disabled: !value[ZEROS] ? true : false,
    sub: {
      key: ZEROS,
      title: "Frequencies",
      type: "tagsInput",
      unit: "GHz",
      unitOptions: ["GHz", "MHz", "KHz", "Hz"],
      value: value[ZEROS]
    }
  }]
  return optionsList
}

function getFFEOptions(type) {
  let list = JSON.parse(JSON.stringify(FFE_MANUAL_OPTIONS))
  if (type !== "Manual") {
    list.forEach(item => {
      if (item.key === NUMBER_OF_PRECURSOR_TAPS) {
        item.value = "2";
        delete item.sub;
        item.notDisplaySub = true;
      } else if (item.key === NUMBER_OF_POSTCURSOR_TAPS) {
        item.value = "4";
        delete item.sub;
        item.notDisplaySub = true;
      }
    })
  }
  return list;
}

function getPresetType(preset) {
  if (!preset) { return }
  const data = preset.split(' ');
  let presetType = ''
  if (data && data.length > 0) {
    presetType = `${data[0]} ${data[1]}`
  }
  return presetType;
}

function getTapNum(type) {
  const presetType = getPresetType(type)
  switch (presetType) {
    case 'PCIE Gen3':
      return "1";
    case 'PCIE Gen4':
      return "2";
    case 'PCIE Gen5':
      return "3";
    case 'USB3 Gen1':
      return "NO"
    case 'USB3 Gen2':
      return "1"
    case 'HDMI 3,6,8,10Gbps':
      return 'NO'
    case 'HDMI 12Gbps':
      const splitType = type.split(' ');
      if (splitType.length && splitType.length > 2 && ['(1', '(2'].includes(splitType[2])) {
        return 'NO'
      }
      return '1'
    default: return "1";
  }
}

function getDFEOptions(type, preset, isDisplay, serdesType) {
  let list = [
    ...JSON.parse(JSON.stringify(DFE_SETUP_OPTIONS))
  ];
  const { presetList, option } = getPresetInfo(preset, serdesType);
  list[0].list = presetList;
  list[0].value = option;

  if (type === OPTIMIZED && !isDisplay) {
    list = list.filter(item => item.key !== TAPS)
  }
  return list
}

const UNIT_OPTIONS = {
  time: [
    { value: 'fsec', label: 'fs' },
    { value: 'psec', label: 'ps' },
    { value: 'nsec', label: 'ns' },
    { value: 'usec', label: 'us' },
    { value: 'msec', label: 'ms' },
    { value: 'sec', label: 's' }
  ],
  frequency: [
    { value: 'Hz', label: 'Hz' },
    { value: 'KHz', label: 'KHz' },
    { value: 'MHz', label: 'MHz' },
    { value: 'GHz', label: 'GHz' },
    { value: 'THz', label: 'THz' }
  ],
  resistance: [
    { value: "mOhm", label: 'mΩ' },
    { value: "Ohm", label: 'Ω' },
    { value: "KOhm", label: 'KΩ' },
    { value: "MOhm", label: 'MΩ' }
  ],
  voltage: [
    { value: "mV", label: 'mV' },
    { value: "V", label: 'V' },
    { value: "KV", label: 'KV' },
  ]
}

const WAVEFORM = 'Waveform', JITTER = 'jitter', TX_EQ = 'Tx_EQ', ELECTRICAL = 'cphyElectrical', EYEPROBE = 'EyeProbe';
const wavefromLabelList = {
  risetime: [{
    label: 'Low -> Mid',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    labelWidth: 100,
    eleName: 'riseTimeLowToMid'
  }, {
    label: 'Mid -> High',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    labelWidth: 100,
    eleName: 'riseTimeMidToHigh'
  }, {
    label: 'Low -> High',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    labelWidth: 100,
    eleName: 'riseTimeLowToHigh'
  }],
  falltime: [{
    label: 'High -> Mid',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    labelWidth: 100,
    eleName: 'fallTimeHighToMid'
  }, {
    label: 'Mid -> Low',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    labelWidth: 100,
    eleName: 'fallTimeMidToLow'
  }, {
    label: 'High -> Low',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    labelWidth: 100,
    eleName: 'fallTimeHighToLow'
  }],
  advanced: [{
    label: 'Risetime Within EQ Sublevels',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'riseTimeWithinEQSubLevel'
  }, {
    label: 'Falltime Within EQ SubLevels',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'fallTimeWithinEQSubLevel'
  }, {
    label: 'Transit Reference',
    value: '',
    type: SELECT,
    options: [{ value: 0, label: '0% - 100%' }, { value: 10, label: '10% - 90%' }, { value: 20, label: '20% - 80%' }, { value: 30, label: '30% - 70%' }, { value: 40, label: '40% - 60%' }],
    eleName: 'transitReference',
    eleType: 'number'
  }, {
    label: 'Edge Shape',
    value: '',
    type: SELECT,
    options: [{ value: 0, label: 'Linear Transition' }, { value: 1, label: 'Raised Cosine Transition' }, { value: 2, label: 'Error Function Transition' }],
    eleName: 'edgeShape',
    eleType: 'number'
  }, {
    label: 'Delay of Node A',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'delayA'
  }, {
    label: 'Delay of Node B',
    value: '', type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'delayB'
  }, {
    label: 'Delay of Node C',
    value: '',
    type: INPUTANDSELECT,
    unit: 'psec',
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'delayC'
  }],
  necessary: [{
    label: 'Vhigh',
    value: '',
    unit: 'V',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.voltage,
    eleName: 'vHigh'
  }, {
    label: 'Vmid',
    value: '',
    unit: 'V',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.voltage,
    eleName: 'vMid'
  }, {
    label: 'Vlow',
    value: '',
    unit: 'V',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.voltage,
    eleName: 'vLow'
  }
  ]
}

const jitterLabelList = {
  enableRJ: [{
    label: 'RJbw (RJ Bandwidth)',
    value: '',
    unit: 'Hz',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.frequency,
    eleName: 'RJbw'
  }, {
    label: 'RJrms (RJ Standard Deviation)',
    value: '',
    unit: 'psec',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'RJrmsClock'
  }, {
    label: 'RJrmsA (RJrms of Node A)',
    value: '',
    unit: 'psec',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'RJrmsA'
  }, {
    label: 'RJrmsB (RJrms of Node B)',
    value: '',
    unit: 'psec',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'RJrmsB'
  }, {
    label: 'RJrmsC (RJrms of Node C)',
    value: '',
    unit: 'psec',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'RJrmsC'
  }],
  enablePJ: [{
    label: 'PJamp(PJ Amplitude)',
    value: '',
    unit: 'psec',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.time,
    eleName: 'PJamp'
  }, {
    label: 'PJfreq(PJ Frequency)',
    value: '',
    unit: 'MHz',
    type: INPUTANDSELECT,
    suffixOptions: UNIT_OPTIONS.frequency,
    eleName: 'PJfreq'
  }, {
    label: 'PJwave(PJ wave shape)',
    value: '',
    type: SELECT,
    options: [{ value: 0, label: 'Sinusoid' }, { value: 1, label: 'Square' }, { value: 2, label: 'Triangle' }],
    eleName: 'PJwave',
    eleType: 'number'
  }],
  enableClockDCD: [{
    label: 'Clock DCD (UI)',
    value: '',
    type: INPUT,
    eleName: 'clockDCDinUI',
    eleType: 'number'
  }]
}

const EQLabelList = [{
  label: 'EQm1+',
  value: '',
  unit: 'dB',
  type: INPUT,
  eleName: 'EQm1p'
}, {
  label: 'EQm1-',
  value: '',
  unit: 'dB',
  type: INPUT,
  eleName: 'EQm1m'
}, {
  label: 'EQh1',
  value: '',
  unit: 'dB',
  type: INPUT,
  eleName: 'EQh1'
}, {
  label: 'EQh0',
  value: '',
  unit: 'dB',
  type: INPUT,
  eleName: 'EQh0'
}, {
  label: 'EQl1',
  value: '',
  unit: 'dB',
  type: INPUT,
  eleName: 'EQl1'
}, {
  label: 'EQl0',
  value: '',
  unit: 'dB',
  type: INPUT,
  eleName: 'EQl0'
}]

const electricalLabelList = [{
  label: 'Rout at Node A',
  value: '',
  type: INPUTANDSELECT,
  unit: 'Ω',
  suffixOptions: UNIT_OPTIONS.resistance,
  eleName: 'routA'
}, {
  label: 'Rout at Node B',
  value: '',
  type: INPUTANDSELECT,
  unit: 'Ω',
  suffixOptions: UNIT_OPTIONS.resistance,
  eleName: 'routB'
}, {
  label: 'Rout at Node C',
  value: '',
  type: INPUTANDSELECT,
  unit: 'Ω',
  suffixOptions: UNIT_OPTIONS.resistance,
  eleName: 'routC'
}]

const pinModelLabelList = [{
  pinLabel: "Pin A",
  pinValue: "",
  modelValue: "",
  parentEleName: "pinA",
  eleName: "pin"
}, {
  pinLabel: "Pin B",
  pinValue: "",
  modelValue: "",
  parentEleName: "pinB",
  eleName: "pin"
}, {
  pinLabel: "Pin C",
  pinValue: "",
  modelValue: "",
  parentEleName: "pinC",
  eleName: "pin"
}]

const eyeProbeLabelList = [{
  label: 'Vidth (High Threshold Voltage)',
  value: '',
  type: INPUTANDSELECT,
  unit: 'V',
  suffixOptions: UNIT_OPTIONS.voltage,
  eleName: 'vidth',
  labelWidth: 500
}, {
  label: 'Vidtl (Low Threshold Voltage)',
  value: '',
  type: INPUTANDSELECT,
  unit: 'V',
  suffixOptions: UNIT_OPTIONS.voltage,
  eleName: 'vidtl',
  labelWidth: 500
}, {
  label: 'Eye Ramp Width',
  value: '',
  type: INPUTANDSELECT,
  unit: 'psec',
  suffixOptions: UNIT_OPTIONS.time,
  eleName: 'eyeRampTime',
  labelWidth: 500
}, {
  label: 'Eye Mid-section Width',
  value: '',
  type: INPUTANDSELECT,
  unit: 'psec',
  suffixOptions: UNIT_OPTIONS.time,
  eleName: 'eyeMidTime',
  labelWidth: 500
}, {
  label: 'Use default Eye Ramp Width and Eye Mid Width calculated from Symbol Rate',
  value: '',
  type: CHECKBOX,
  eleName: 'useDefaultEye',
  labelWidth: 500
}, {
  label: 'Wait Time for CDR zero crossing detection(in UI fraction)',
  value: '',
  type: INPUT,
  eleName: 'waitTimeInUI',
  labelWidth: 500
}, {
  label: 'Triggered Eye',
  value: '',
  type: CHECKBOX,
  eleName: 'triggeredEye',
  labelWidth: 500
}]


export {
  SET_ALL_DATA_SELECT_LIST,
  ADS_TX,
  ADS_RX,
  AMI_CONTROLLER,
  AMI_DEVICE,
  AMI_POSITIVE,
  AMI_NEGATIVE,
  TX_JITTERS,
  RX_JITTERS,
  TX_SJ_FREQUENCY,
  RX_NOISE,
  AMI_INTERFACE_TYPE_LIST,
  EYE_PROBE,
  PRBS,
  ENCODER,
  AMI_PRBS_MODE_LIST,
  CPHY_AMI_PRBS_MODE_LIST,
  AMI_ENCODER_LIST,
  AMI_SIMULATION,
  AMI_ADSOPTION,
  AMI_SIM_MODE_LIST,
  TOLERANCE_LIST,
  TOLERANCE,
  ADSOPTION,
  ERROR_BIT_RATE,
  BIT_BY_BIT,
  NUMBER_OF_BIT,
  BIT_SEQUENCE,
  REGISTER_LENGTH,
  TAPS,
  SEED,
  ENABLE_ULTRA_LOW_BER_SIMULATION,
  BIT_RATE,
  MAXIMAL_LENGTH_LFSR,
  PCIE_2,
  PCIE_3,
  PCIE_4,
  PCIE_5,
  ENCODER_128B130B,
  NO_ENCODER,
  ENCODER_8B10B,
  USER_DEFINED_LFSR,
  USER_DEFINED_SEQUENCE,
  AMI_HDMI_TYPE_LIST,
  TMDS_14,
  TMDS_2,
  FRL,
  CLOCK_BIT_RATE,
  TX_JITTER_PDF,
  RX_JITTER_PDF,
  JITTER_PDF_LIST,
  TX_PJ_FREQUENCY,
  TX_PJ_AMPLITUDE,
  RANDOM,
  DJRJ,
  DUAL_DIRAC,
  TX_EQ_TYPES,
  RX_EQ_TYPES,
  FIR_TAPS_OPTIONS,
  DE_EMPHASIS_OPTIONS,
  DE_EMPHASIS,
  FIR_TAPS,
  CTLE,
  FFE,
  CTLE_DEFAULT_OPTIONS,
  CTLE_OPTIONS,
  CTLE_CUSTOM_OPTIONS,
  // CTLE_PRESET_OPTIONS,
  PRECURSOR_TAPS,
  POSTCURSOR_TAPS,
  NUMBER_OF_POLES,
  NUMBER_OF_ZEROS,
  NUMBER_OF_PRECURSOR_TAPS,
  NUMBER_OF_POSTCURSOR_TAPS,
  ZEROS,
  POLES,
  FFE_OPTIONS,
  FFE_MANUAL_OPTIONS,
  DFE_OPTIONS,
  DFE_OPTIMIZED_OPTIONS,
  DFE,
  NUMBER_OF_TAPS,
  DFE_TYPE,
  DFE_ALL_OPTIONS,
  DC_GAIN,
  AC_GAIN,
  DE_EMPHASIS_VALUE,
  TAP_INTERVAL,
  TX_DCD,
  TX_CLOCK_DCD,
  SIGMA,
  FFE_TYPE,
  CTLE_TYPE,
  ALLOW_VALUE_EMPTY_PARAMS,
  RX_SJ_AMPLITUDE,
  RX_AMPLITUDE_NOISE,
  IBIS_TX_JITTERS,
  IBIS_RX_JITTERS,
  SLICER_OUTPUT,
  ADAPTIVE_EQUALIZATION,
  DFE_ADAPTIVE_EQUALIZATION,
  ENABLE_CTLE_OR_FFE,
  EQ_TAPS,
  PRESET,
  PREFACTOR,
  NOTPPU,
  ENFORCE_PASSIVITY,
  EYEMASK,
  DFE_SETUP_OPTIONS,
  DFETAPUSED,
  PROTOCOL,
  DFE_FILTER_OPTIONS,
  OPTIMIZED,
  MANUAL,
  getPresetOption,
  getCtlePresetOptions,
  getFFEOptions,
  getDFEOptions,
  getTapNum,
  getHDMIPresetOptions,
  PARALLEL,
  SERIES,
  EXTRAPOLATE_IN_BIT_BY_BIT_MODE,
  ERROR_BIT_RATE_LIST,
  PROBERX,
  PROBETX,
  PROBE_LIST,
  UNIT_OPTIONS,
  WAVEFORM,
  JITTER,
  TX_EQ,
  ELECTRICAL,
  EYEPROBE,
  wavefromLabelList,
  jitterLabelList,
  EQLabelList,
  electricalLabelList,
  pinModelLabelList,
  eyeProbeLabelList,
  CPHY_RX_EQ_TYPES
}