import React, { PureComponent, lazy } from 'react';
import {
  PCB, DCR, IR_EXPLORER, IR_EXPLORER_RESULT,
  IMPEDANCE, IMPEDANCE_RESULT, PACKAGE, POWER_TREE, DESIGN_TREE,
  SIGN_OFF_TEMPLATE, SINGLE_TREE, PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import TopBar from './TopBar';
import ContentLayoutTB from '@/components/ContentLayout/layoutTB';
import { topBarResize } from '@/services/helper/topBarResize';
import IRContent from '../IRExplorer';
import IRResultContent from '../results/IR';
import ImpedanceContent from '../Impedance';
import ImpedanceResult from '../results/Impedance';
import DesignTreeContent from '../DesignTree';
import { PCB_TOP_BOTTOM, TOP_BOTTOM_LAYOUT } from '../../../constants/layoutConstants';
import { connect } from 'react-redux';
import SignOffTemplateContent from '../SignOffTemplate';

const DCRContent = lazy(() => import("../DCR"));
const PCBLayout = lazy(() => import("./pcbLayout"));

class LayoutTB extends PureComponent {

  topBarResize = () => {
    topBarResize('cascade-top-bar', this.props.getTopBarWidth(this.props.viewList));
  }

  topBar = (width) => {
    return <TopBar width={width} openMenu={this.props.openMenu} />
  }

  getBottomSetup = (width) => {
    const { viewList, siderWidth } = this.props;
    const views = viewList.filter(item => ![PCB, PACKAGE, PCB_PRE_LAYOUT].includes(item))
    let content = null, className = '';
    switch (views[0]) {
      case DCR:
        content = <DCRContent />;
        className = 'cascade-tb-setup-main';
        break;
      case IR_EXPLORER:
        content = <IRContent contentWidth={width} />
        className = 'cascade-tb-setup-main';
        break;
      case IR_EXPLORER_RESULT:
        content = <IRResultContent leftWidth={siderWidth} layoutLeftWidth={width} />
        className = 'cascade-tb-result-main';
        break;
      case IMPEDANCE:
        content = <ImpedanceContent contentWidth={width} />
        className = 'cascade-tb-setup-main';
        break;
      case IMPEDANCE_RESULT:
        content = <ImpedanceResult leftWidth={siderWidth} layoutLeftWidth={width} />
        className = 'cascade-tb-result-main';
        break;
      case POWER_TREE:
        content = <DesignTreeContent contentWidth={width} />
        className = 'cascade-tb-setup-main';
        break;
      case SIGN_OFF_TEMPLATE:
        content = <SignOffTemplateContent contentWidth={width} />
        className = 'cascade-tb-setup-main';
        break;
      case DESIGN_TREE:
      case SINGLE_TREE:
        content = <DesignTreeContent contentWidth={width} />
        className = 'cascade-tb-setup-main';
        break;
      default: return null;
    }
    setTimeout(() => {
      this.topBarResize();
    }, 100)
    return <div
      className={className}
      style={{ width }}
      id="cascade-content-main"
    >
      {content}
    </div>
  }

  getTop = (height) => {
    const { siderWidth, viewList } = this.props;
    const view = viewList.find(item => [PCB, PACKAGE, PCB_PRE_LAYOUT].includes(item));
    switch (view) {
      case PCB:
      case PACKAGE:
      case PCB_PRE_LAYOUT:
        return <PCBLayout siderWidth={siderWidth} bottomHeight={height} />;
      default: return null
    }
  }

  _getSplitSize = () => {
    const { selectedKeys, pcbLayout, layout } = this.props;
    let pcbs = [];
    selectedKeys.forEach(ele => {
      const arr = ele.split('-');
      if ([PCB, PACKAGE, PCB_PRE_LAYOUT].includes(arr[0])) {
        pcbs.push({ key: arr[0], id: arr[1] });
      }
    });
    if (!pcbs.length) {
      return 1;
    } else if (pcbs.length > 1 && pcbLayout === PCB_TOP_BOTTOM && layout === TOP_BOTTOM_LAYOUT) {
      return 3;
    } else {
      return 2;
    }
  }

  render() {
    const splitSize = this._getSplitSize();
    return (
      <ContentLayoutTB
        {...this.props}
        bottomClassName={"cascade-content-bottom"}
        topBarResize={this.topBarResize}
        getTop={this.getTop}
        getBottomSetup={this.getBottomSetup}
        topBar={this.topBar}
        splitSize={splitSize}
      />
    )
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { pcbLayout, layout, selectedKeys } } } = state;
  return {
    pcbLayout,
    layout,
    selectedKeys
  }
}

export default connect(mapState, null)(LayoutTB);
