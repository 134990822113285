import React, { PureComponent } from 'react';
import { UploadOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Divider, Tooltip, Progress } from 'antd';
import ResultFile from './fileContent';
import { IMPORT } from '../../constants';

class Import extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      importShow: false
    }
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props.importData;
    if (files && prevProps.importData.files !== files) {
      const importFiles = files.filter(file => file.type === 'import');
      if (importFiles && importFiles.length > 0) {
        this.setState({ importShow: true });
      }
    }
  }

  importTitleClick = () => {
    const { importShow } = this.state;
    this.setState({
      importShow: !importShow
    });
  }


  inputClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { importActions, importData } = this.props;
    const { fileNameClick, changePort, colorChange, deleteFile, portNameClick, UploadFile } = importActions;
    const { files, importProgress, portSelect, verificationId, importDisabled } = importData;
    const { importShow } = this.state;
    const importFiles = files.filter(file => file.type === 'import');
    const Icon = importShow ? DownOutlined : RightOutlined
    return (
      <div className='pdn-result-upload' id='pdn-sparameter-import'>
        <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
          <div style={{ paddingRight: importProgress > -1 ? 106 : 0 }} className='result-import-title'>
            <Icon className="title-expand-icon" onClick={this.importTitleClick} />
            <span className='pdn-result-menu-title' onClick={this.importTitleClick}>Import</span>
            <Tooltip title='Import Sparameter File' overlayClassName='aurora-tooltip' onClick={this.inputClick} getPopupContainer={() => document.getElementById('pdn-sparameter-import')}>
              <span className='result-upload-file-button'>
                <UploadOutlined
                  className={importDisabled ? 'icon-disabled' : ''}
                  onClick={(e) => UploadFile(e)} />
              </span>
            </Tooltip>
            {importProgress > -1 && <Progress
              size={{ height: 14 }}
              strokeColor={'#1890ff'}
              percent={importProgress}
              className="result-progress-bar"
            />}
          </div>
        </Divider>
        <Row className='pdn-result-upload-files' style={{ display: importShow ? 'block' : 'none' }}>
          {importFiles.length > 0 ? importFiles.map((file, fileIndex) =>
            <ResultFile
              file={file}
              fileNameClick={fileNameClick}
              fileIndex={fileIndex}
              portSelect={portSelect}
              changePort={changePort}
              verificationId={verificationId}
              colorChange={colorChange}
              portNameClick={portNameClick}
              deleteFile={deleteFile}
              key={file.id}
              type={IMPORT}
            />
          ) : null}
        </Row>
      </div>
    );
  }
}

export default Import;