import { combineReducers } from 'redux';
import { AndesProjectReducer } from './project';
import { AndesLibraryReducer } from './library';
import AndesV2UploadReducer from '../AndesSider/uploadPCB/store/reducer';
import { default as explorerReducer } from '../../LayoutExplorer/store/Andes_v2/reducer';
import { AndesPreLayoutReducer } from './preLayout';
import { AndesChannelReducer } from './channel';
import { AndesEndToEndChannelReducer } from './endToEndChannel';
import { AndesSimulationReducer } from './simulation';
import { AndesChannelResultReducer } from './result/channelReducer';
import { AndesSweepReducer } from './sweep';

const rootReducer = combineReducers({
  project: AndesProjectReducer,
  library: AndesLibraryReducer,
  AndesV2UploadReducer,
  explorer: explorerReducer,
  preLayout: AndesPreLayoutReducer,
  channel: AndesChannelReducer,
  simulation: AndesSimulationReducer,
  endToEndChannel: AndesEndToEndChannelReducer,
  channelResultReducer: AndesChannelResultReducer,
  sweep: AndesSweepReducer
});

export default rootReducer;