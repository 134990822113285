import { SortFn } from "../../helper/sort";

class MultiSetupDataBase {
  constructor() {
    this.AllData = [];
    this.pageData = new Map();
  }

  clear = () => {
    this.AllData = [];
    this.pageData = new Map();
  }

  getPage = (page) => {
    return this.pageData.get(page);
  }

  initData = (data, pageSize) => {
    //sort data by group
    const groups = [...new Set(data.map(item => {
      if (!item.group) {
        item.group = ""
      }
      return item.group
    }).filter(item => !!item))];
    data = SortFn(data, [...groups, ""], "group");

    this.AllData = data;
    this.pageData = new Map();
    if (data.length) {
      this.resetPage(pageSize || 20)
    }
  }

  getData = () => {
    return this.AllData;
  }

  resetPage = (pageSize) => {
    pageSize = pageSize || 20;
    let pages = Math.ceil(this.AllData.length / pageSize);
    for (let i = 1; i <= pages; i++) {
      const currPageData = this.AllData.filter((item, index) => index >= (i - 1) * pageSize && index < i * pageSize)
      this.pageData.set(i, currPageData);
    }
    this.pages = pages;
  }

  setPage = (page, data) => {
    this.pageData.set(page, data);
  }

  getPages = () => {
    return this.pages;
  }

  getAllDataLength = () => {
    return this.AllData.length;
  }

  setData = (data) => {
    this.AllData = data;
  }

  getVerificationIds = () => {
    return [...new Set((this.AllData || []).map(item => item.verificationId).filter(item => !!item))];
  }
}

const multiSetupDataBase = new MultiSetupDataBase();
export default multiSetupDataBase;