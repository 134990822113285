import LayoutData from './LayoutData';
import { XLSXTOCSV, uploadStackup } from '../api/designFile';
import { CeElectricLayer } from '../CeLayoutDB/CeLayerStack';

const stackup = {};

let layData = null;
let stackupData = null;

// property list layout
var layProperty = [{
  item: 'Name',
  value: ''
}, {
  item: 'Type',
  value: ''
}, {
  item: 'Thickness',
  value: ''
}, {
  item: 'Material',
  value: ''
}, {
  item: 'Conductivity',
  value: ''
}];

stackup.getLayer = getLayer;

stackup.saveLayer = saveLayer;

stackup.getLayProperty = getLayProperty;

stackup.setData = setData;

stackup.setLayProperty = setLayProperty;

stackup.applyLayProperties = applyLayProperties;

stackup.setLayerVisibility = setLayerVisibility;

stackup.parseUploadFile = parseUploadFile;

stackup.checkUnit = checkUnit;

stackup.checkData = checkData;

stackup.reExtractionStackupCheck = reExtractionStackupCheck;

stackup.updateBeforeUpload = updateBeforeUpload;

stackup.updateDielectricLayer = updateDielectricLayer;

//-------------------------------------------------------------------------------------------
/** return the layer list for table display
 *  @param showDielectric  boolean flag, whether or not to include the dielectric layers
 *  @param showComponent   boolean flag, whether or not to include the component layers
 */
function filterLayer(showDielectric, showComponent) {
  // filter the layers based on request parameters
  var filteredLayers = [];
  for (var i in layData) {
    if (layData[i].type === 'Metal') {
      filteredLayers.push(layData[i]);
    }
    else if (layData[i].type === "Dielectric" && showDielectric) {
      filteredLayers.push(layData[i]);
    }
    else if (layData[i].type === 'COMPONENT' && showComponent) {
      filteredLayers.push(layData[i]);
    }
  }
  return filteredLayers;
}

/** return a promise to the layer list for table display
 *  @param showDielectric  boolean flag, whether or not to include the dielectric layers
 *  @param showComponent   boolean flag, whether or not to include the comonent layers
 */
function getLayer(showDielectric, showComponent, reSet, pcbId, determineMetalLayer) {

  if (!layData || reSet) {
    return setData(pcbId, true, determineMetalLayer).then(() => {
      // when the set data promise is fulfilled, return a promise with the grid display data
      return filterLayer(showDielectric, showComponent);
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve(filterLayer(showDielectric, showComponent))
    })
  }
};

// update layer
/*
* number: The number to be deleted
* layers: Need to add layer
*/
function updateDielectricLayer(startItem, number, layers = [], fix = 0, isFirst) {
  if (startItem) {
    const layDataIndex = layData.findIndex(item => item.name === startItem.name);
    let mLayersIndex = stackupData.mLayers.findIndex(item => item.mLayerName === startItem.name);
    if (mLayersIndex < 0 && layDataIndex >= 0) {
      // When a metal layer is missing, add it to the data
      let newCeElectricLayer = new CeElectricLayer(startItem.name);
      newCeElectricLayer.setLayerType("Metal");
      newCeElectricLayer.mThickness = startItem.thickness
      newCeElectricLayer.mMaterialName = "Copper";
      newCeElectricLayer.mMaterialVendor = "Generic";
      newCeElectricLayer.mConductivity = startItem.conductivity
      stackupData.mLayers.splice(layDataIndex, 0, newCeElectricLayer)
      mLayersIndex = stackupData.mLayers.findIndex(item => item.mLayerName === startItem.name);
    }
    if (layDataIndex >= 0 && mLayersIndex >= 0) {
      const { startStackup, startTable } = getStartIndex(layData, isFirst);
      layData.splice(layDataIndex + fix, number, ...layers);
      let indexCount = 0;
      for (let newItem of layData) {
        if (newItem.indexStackup >= 0) {
          newItem.indexStackup = startStackup + indexCount;
          newItem.indexTable = startTable + indexCount;
          indexCount++;
        }
      }
      if (mLayersIndex < -1) { }
      const newMLayData = layers.map(item => {
        let newCeElectricLayer = new CeElectricLayer(item.name);
        newCeElectricLayer.mMaterialName = item.material;
        return newCeElectricLayer;
      })

      stackupData.mLayers.splice(mLayersIndex + fix, number, ...newMLayData);
    }
  }
}

/** save the stackup in the table back to the server 
 *  @param layers  the data items from the kendo-grid
 */
function saveLayer(layers, unit, pcbId) {
  if (unit) stackupData.mUnit = "Length_" + unit;
  for (let i in layers) {
    if (layers[i].indexStackup >= 0) {
      const { thickness, name, conductivity, epsilon, delta } = layers[i];
      const _lay = layData[layers[i].indexTable];
      const _mLayer = stackupData.mLayers[layers[i].indexStackup];
      if (_lay && _mLayer) {
        // we also need to update the original data since Kendo-grid makes a copy of
        // it and does not synchronize it automatically
        _lay.thickness = thickness;
        _lay.name = name;

        // update the original stackup data
        _mLayer.mThickness = thickness;
        _mLayer.mLayerName = name;

        // update conductivity
        if (conductivity) {
          _lay.conductivity = conductivity;
          // update the original stackup data
          _mLayer.mConductivity = conductivity;
        }
        if (epsilon) {
          _lay.epsilon = epsilon;
          _lay.delta = delta;
          _mLayer.mPermittivity = epsilon;
          _mLayer.mLossTangent = delta;
        }
      }
    }
  }
  return LayoutData.saveStackup(pcbId);

}; // saveLayer

// Before upload, update stackupData and layData
function updateBeforeUpload(layers) {
  const metalArr = layers.filter(item => item.type === 'Metal');
  // replace: The data that needs to be deleted from the original array
  let i, insertArr = [], replace;
  // check the dielectric layer before the first layer of metal
  for (i = 0; i < layers.length && layers[i].type === 'Dielectric'; i++) {
    insertArr.push(layers[i]);
  }
  replace = getReplaceDielectrics(metalArr[0], -1, 0);
  updateDielectricLayer(layData[replace.index - replace.num], replace.num, insertArr, 0, true);
  // check the dielectric layer between the two metal layers
  // When updating, jinshu should be checked
  for (i = 0; i < metalArr.length - 1; i++) {
    insertArr = layers.filter(item => item.indexStackup > metalArr[i].indexStackup && item.indexStackup < metalArr[i + 1].indexStackup);
    replace = getReplaceDielectrics(metalArr[i], 1, true);
    updateDielectricLayer(metalArr[i], replace.num, insertArr, 1);
  }
  // check the dielectric layer after the first layer of metal
  insertArr = [];
  for (i = layers.length - 1; i >= 0 && layers[i].type === 'Dielectric'; i--) {
    insertArr.unshift(layers[i]);
  }
  replace = getReplaceDielectrics(metalArr[metalArr.length - 1], 1, true);
  updateDielectricLayer(layData[replace.index], replace.num, insertArr, 1);
}
// Find the dielectric layer between two metal layers
// direction: the dielectrics above or blow the metal
function getReplaceDielectrics(metalInfo, direction) {
  let findIndex = layData.findIndex(item => item.name === metalInfo.name);
  // replaceNum: the need replace number
  if (findIndex < 0) {
    // No metal layer found, added to layData
    layData.splice(metalInfo.indexTable, 0, metalInfo)
    findIndex = layData.findIndex(item => item.name === metalInfo.name);
  }
  let replaceNum = 0, index = findIndex;
  while (layData[index]) {
    index = index + direction;
    if (layData[index] && layData[index].type === 'Dielectric') {
      replaceNum++;
    } else {
      break;
    }
  }
  return { num: replaceNum, index: findIndex };
}

function getLayProperty() {
  return layProperty;
}

function setLayProperty(layers) {

  layProperty = [{
    item: 'Name',
    value: ''
  },
  {
    item: 'Type',
    value: ''
  },
  {
    item: 'Thickness',
    value: ''
  },
  {
    item: 'Material',
    value: ''
  },
  {
    item: 'Conductivity',
    value: ''
  }
  ];

  if (layers && layers.length > 0) {

    layProperty[0].value = layers[0].name;
    layProperty[1].value = layers[0].type;
    if (layers[0].thickness !== undefined) {
      layProperty[2].value = layers[0].thickness;
    }
    if (layers[0].material !== undefined) {
      layProperty[3].value = layers[0].material;
    }

    for (var i = 1; i < layers.length; i++) {

      // cascade the names
      layProperty[0].value = layProperty[0].value + ', ' + layers[i].name;

      // take the common values for the rest of the elements
      if (layProperty[1].value !== layers[i].type) {
        layProperty[1].value = '';
      }
      if (layers[i].thickness !== layProperty[2].value) {
        layProperty[2].value = '';
      }
      if (layers[i].material !== layProperty[3].value) {
        layProperty[3].value = '';
      }
    };
  };
};

/** Apply the layer property data to the original data
 *  @param layers The selected data items in the layer table. Note that these are
 *                not the original data.
 */
function applyLayProperties(layers, pcbId) {

  if (!layers || layers.length === 0)
    return;

  var newThickness = layProperty[2].value;
  for (var i = 0; i < layers.length; i++) {

    if (layers[i].indexStackup >= 0) {
      // this is the dataItem from the Kendo-grid
      layers[i].thickness = newThickness;

      // we also need to update the original data since Kendo-grid makes a copy of
      // it and does not synchronize it automatically
      layData[layers[i].indexTable].thickness = newThickness;

      // update the original stackup data
      stackupData.mLayers[layers[i].indexStackup].mThickness = newThickness;
    }

  } // for (var i = 0; i < layers.length; i++)

  LayoutData.saveStackup(pcbId);

} // function applyLayProperties(layers, properties)

/** get the stackup data from the design folder, and convert it into table display
 *  format
 *  @returns a promise of operation completion
 */
function setData(pcbId, reload, determineMetalLayer) {

  return LayoutData.getStackup(pcbId, reload, null, determineMetalLayer).then(layerStack => {

    // save a local reference of the stackup data
    stackupData = layerStack;

    // generate the layer table data
    var layerManager = LayoutData.getLayout(pcbId).GetLayerManager();
    var layoutSettings = LayoutData.getLayout(pcbId).GetLayoutSettings();

    layData = [];
    var indexTable = 0;
    var indexMetal = 0;
    for (var i = 0, len = layerStack.mLayers.length; i < len; i++) {

      var electricLayer = layerStack.mLayers[i];
      var layoutLayer = electricLayer.mLayoutLayer;
      var compLayer = null;
      if (layoutLayer) {
        compLayer = layerManager.GetMetalCompLayer(electricLayer.mLayerName);
        indexMetal++;
      }

      if (i === 0 && compLayer) {
        // top component layer
        layData.push({
          indexStackup: -1,  // not a stackup layer
          indexMetal: indexMetal,
          indexTable: indexTable,
          visible: layoutSettings.IsLayerVisible(compLayer.GetName()),
          name: compLayer.GetName(),
          type: "COMPONENT",
        });
        indexTable++;
      }

      // this is a metal or dielectric layer
      layData.push({
        indexStackup: i,     // the index will be used for data write back
        indexMetal: layoutLayer ? indexMetal : null,
        indexTable: indexTable,
        visible: layoutLayer ? layoutSettings.IsLayerVisible(electricLayer.mLayerName) : undefined,
        name: electricLayer.mLayerName,
        type: electricLayer.mLayerType,
        thickness: electricLayer.mThickness,
        material: electricLayer.mMaterialName,
        epsilon: layoutLayer ? null : electricLayer.mPermittivity,
        delta: layoutLayer ? null : electricLayer.mLossTangent,
        conductivity: layoutLayer ? electricLayer.mConductivity : null
      });
      indexTable++;

      if (i === len - 1 && compLayer) {
        // bottom component layer
        layData.push({
          indexStackup: -1,  // not a stackup layer
          indexMetal: indexMetal,
          indexTable: indexTable,
          visible: layoutSettings.IsLayerVisible(compLayer.GetName()),
          name: compLayer.GetName(),
          type: "COMPONENT",
        });
        indexTable++;
      }
    };
  });
};

function setLayerVisibility(layerName, visible, pcbId) {
  LayoutData.getLayout(pcbId).GetLayoutSettings().SetLayerVisible(layerName, visible);
};

function parseUploadFile(readerFile, dataItem) {
  let unit = null;
  const uploadArr = CSVtoArray(readerFile);
  let thicknessNum = null, deltaNum = null, epsilonNum = null, nameNum = null, conductivityNum = null;
  for (let i in uploadArr[0]) {
    if (thicknessNum && deltaNum && epsilonNum && conductivityNum) {
      break;
    }

    const compare = uploadArr[0][i].toLowerCase();
    if (!nameNum && compare.indexOf('name') >= 0) {
      nameNum = i;
      continue;
    }

    if (!epsilonNum && (compare.indexOf('dielectric') >= 0 || compare.indexOf('epsilon') >= 0)) {
      epsilonNum = i;
      continue;
    }

    if (!deltaNum && (compare.indexOf('loss') >= 0 || compare.indexOf('delta') >= 0)) {
      deltaNum = i;
      continue;
    }

    if (!thicknessNum && (compare.indexOf('thick') >= 0 || compare.indexOf('height') >= 0)) {
      thicknessNum = i;
      // const reg = /(?<=\()[^()]*(?=\))/g;
      // Support safari
      const reg = /(?:\()[^()]*(?=\))/g;
      const matched = compare.match(reg);
      if (matched) {
        unit = matched[0].replace('(', '').trim();
      }
      continue;
    }

    if (!conductivityNum && (compare.indexOf('conductivity') >= 0)) {
      conductivityNum = i;
      continue;
    }

  }
  // check for duplicate names
  let nameCheck = [];
  for (let check of uploadArr) {
    if (nameCheck[check[nameNum]]) {
      return { errorMsg: 'Upload layers name is duplicated.' }
    }
    nameCheck[check[nameNum]] = true;
  }
  // check metal layers
  // It should be compared with the metal layer of the board

  const mMetalLayers = stackupData && stackupData.mLayoutLayerMgr && stackupData.mLayoutLayerMgr.mMetalLayers ? stackupData.mLayoutLayerMgr.mMetalLayers : [];
  let currentMetalName = dataItem.filter(item => item.type === 'Metal' && item.indexStackup >= 0).map(item => item.name);
  const uploadMetalName = uploadArr.filter(item => item[conductivityNum]).map(item => item[nameNum]);

  if (mMetalLayers.length) {
    currentMetalName = stackupData.mLayoutLayerMgr.mMetalLayers.map(item => item.mName)
  }

  const { startStackup, startTable } = getStartIndex(dataItem);

  uploadMetalName.shift();
  if (currentMetalName.length !== uploadMetalName.length) {
    return { errorMsg: 'Metal layers mismatch.' }
  }
  for (let order in currentMetalName) {
    if (currentMetalName[order] !== uploadMetalName[order]) {
      return { errorMsg: 'Metal layers mismatch.' }
    }
  }

  // check every Dielectric layer
  // check the dielectric layer before the first layer of metal
  let newData = [];
  for (let i = 1; i < uploadArr.length; i++) {
    if (uploadArr[i][nameNum] && !uploadArr[i][conductivityNum]) {
      newData.push(getUploadDielectric(uploadArr[i], nameNum, deltaNum, epsilonNum, thicknessNum));
    } else {
      break;
    }
  }
  // check the dielectric layer between the two metal layers
  for (let mItem in currentMetalName) {
    let addArr = [], check = false;
    for (let upload of uploadArr) {
      if (currentMetalName[mItem] === upload[nameNum]) {
        // find all dielectric layers below this metal layer
        check = true;
      } else {
        if (check) {
          if (upload[nameNum] && !upload[conductivityNum]) {
            addArr.push(getUploadDielectric(upload, nameNum, deltaNum, epsilonNum, thicknessNum));
          } else { // next metal layer
            break;
          }
        }
      }
    }
    let _metal = dataItem.find(item => item.name === currentMetalName[mItem]);

    let newMetalIndex = mMetalLayers.findIndex(item => item.mName === currentMetalName[mItem])
    if (newMetalIndex > -1 && !_metal) {
      _metal = {
        indexMetal: newMetalIndex + 1,
        name: currentMetalName[mItem],
        mMaterialName: "Copper",
        type: 'Metal',
        epsilon: null,
        delta: null,
      }
    }

    const _uploadMetal = uploadArr.find(item => item[nameNum] === currentMetalName[mItem]);
    // copy metal
    let newMetal = { ..._metal };
    newMetal.thickness = parseFloat(_uploadMetal[thicknessNum]);
    newMetal.conductivity = parseFloat(_uploadMetal[conductivityNum]);
    // save to new array
    [newMetal, ...addArr].forEach(item => { newData.push(item) })
  }
  // update index
  for (let newIndex = 0; newIndex < newData.length; newIndex++) {
    newData[newIndex].indexStackup = startStackup + newIndex;
    newData[newIndex].indexTable = startTable + newIndex;
  }
  return { data: newData, unit };
}

function getStartIndex(data, isFirst) {
  if (!isFirst || (data && data.length)) {
    for (let item of data) {
      const { indexStackup, indexTable } = item;
      if (indexStackup >= 0) {
        return { startStackup: indexStackup, startTable: indexTable };
      }
    }
  }
  return { startStackup: 0, startTable: 0 }
}

function getUploadDielectric(item, name, delta, epsilon, thickness) {
  return {
    conductivity: null,
    delta: parseFloat(item[delta]),
    epsilon: parseFloat(item[epsilon]),
    indexMetal: null,
    material: "FR-4",
    name: item[name],
    thickness: parseFloat(item[thickness]),
    type: "Dielectric"
  }
}

function checkUnit(unit) {
  return UNIT.includes(unit);
}

function checkData(data) {
  // prevLayerType: previous layer type in array
  let error = null, prevLayerType;
  for (let item of data) {
    if (!item.thickness && item.thickness !== 0) {
      error = 'Thickness cannot be empty.';
      break;
    } else if (parseFloat(item.thickness) <= 0) {
      error = 'Thickness should be greater than 0.';
      break;
    }

    //check metal
    if (item.type === 'Metal') {
      if (!item.conductivity && item.conductivity !== 0) {
        error = 'Conductivity cannot be empty.';
        break;
      } else if (parseFloat(item.conductivity) < 1.0e5 || parseFloat(item.conductivity) > 1.0e10) { //range 1.0e5 ~ 1.0e10
        error = 'Conductivity should be between 1.0e5 ~ 1.0e10.';
        break;
      }
      // check there is at least one dielectric layer between the two layers of metal
      if (prevLayerType === 'Metal') {
        error = 'At least one layer of Dielectric is required between Metal.';
        break;
      }
    }

    //check Dielectric
    if (item.type === 'Dielectric') {
      // check name
      if (/[^0-9a-zA-Z_$-]/i.test(item.name)) {
        error = 'Dielectric name may only contain the following characters: number, letters, underscores, minus.';
        break;
      }

      if (!item.epsilon && item.epsilon !== 0) {
        error = 'Dielectric Constant cannot be empty.';
        break;
      } else if (parseFloat(item.epsilon) < 1 || parseFloat(item.epsilon) > 10) {//range 1-10
        error = 'Dielectric Constant should be between 1 ~ 10.';
        break;
      }

      if (!item.delta && item.delta !== 0) {
        error = 'Loss Tangent cannot be empty.';
        break;
      } else if (parseFloat(item.delta) < 0 || parseFloat(item.delta) > 0.1) { //range 0-0.1
        error = 'Loss Tangent should be between 0 ~ 0.1.';
        break;
      }
    }
    prevLayerType = item.type;
  }

  return error;
}

function reExtractionStackupCheck(prevData, updateData) {
  if (prevData && updateData && prevData.length !== updateData.length) {
    return true;
  }

  for (let item of prevData) {
    let current = updateData.find(i => i.indexStackup === item.indexStackup);
    if (item.thickness !== current.thickness) {
      return true;
    }
    if (item.type === 'Metal') {
      if (item.conductivity !== current.conductivity) {
        return true;
      }
    }

    if (item === 'Dielectric') {

      if (item.epsilon !== current.epsilon) {
        return true;
      }

      if (item.delta !== current.delta) {
        return true;
      }
    }
  }
}

export default stackup;

export const UNIT = ['mil', 'um', 'mm'];

export function changeExcelToCSV(file) {
  return new Promise((resolve, reject) => {
    XLSXTOCSV(file).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
    })
  })
}

export function uploadDatFile(file, pcbId) {
  return new Promise((resolve, reject) => {
    uploadStackup(pcbId, file).then(res => {
      resolve(res)
    }, error => {
      reject(error);
    })
  })
}

function CSVtoArray(strData, strDelimiter) {

  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  let objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  let arrData = [
    []
  ];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(strData)) {

    // Get the delimiter that was found.
    let strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter) {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    let strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"");
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return (arrData);

} // CSVtoArray