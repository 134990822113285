
import { AndesSaga } from './andes';
import { AndesSimulationSaga } from './simulation';
import { AndesProjectSaga } from '../store/project';
import AndesDesignUploadSaga from '../AndesSider/Upload/sagas';

export default [
  AndesSaga,
  AndesSimulationSaga,
  AndesProjectSaga,
  AndesDesignUploadSaga,
]
