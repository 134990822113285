import React, { Component, createRef } from 'react';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { getPanelWidth } from '@/services/helper/panelSizeHelper';
import TransferCom from './secondary/transfer';
import DelConfirm from '@/components/DelConfirm';
import { versionUpdate } from '../../services/helper/dataProcess';
import designConstructor from '../../services/helper/designConstructor';
import auroraDBJson from '../../services/Designs/auroraDbData';
import './index.css';

const MERGE = 2, REPLACE = 1;
class DoNotStuff extends Component {

  constructor(props) {
    super(props);
    this.state = {
      compName: [],
      selectedNetsList: [],
      value: [],
      loading: true,
      confirmVisible: false
    };
    this.uploadFileRef = createRef();
  }

  componentDidMount() {
    const { pcbId } = this.props;
    if (pcbId) {
      this.setDefaultData();
    }
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    const { pcbId, update } = this.props;
    if (pcbId !== prevProps.pcbId || (update && update !== prevProps.update)) {
      this.setDefaultData();
    }
  }

  setDefaultData = async () => {
    const { doNotStuffStore, pcbId } = this.props;
    if (pcbId) {
      await this.props.setDefaultData();
      const compsInfo = auroraDBJson.getComponents(pcbId)
      const data = await doNotStuffStore.getDoNotStuff(pcbId)
      const compName = [...compsInfo.keys()];
      const selectedNetsList = JSON.parse(JSON.stringify(data && data.length ? data : []));
      this.setState({
        compName: compName,
        selectedNetsList
      }, () => {
        this.setState({
          loading: false
        })
      })
    }
  }

  saveNotStuff = async () => {
    const { pcbId, doNotStuffStore, settingStore, tableStore } = this.props;
    const { selectedNetsList } = this.state;
    const { save, newUnStuff, newStuff } = await doNotStuffStore.compareData(pcbId, selectedNetsList, true);
    if (save) {
      await doNotStuffStore.updateDoNotStuffSetting([{ designId: pcbId, doNotStuff: selectedNetsList }]);
      //update component setting version and re search vrm
      if (settingStore && settingStore.getVersion) {
        const settingVersion = await settingStore.getVersion(pcbId);
        const newSettingVersion = versionUpdate(settingVersion);
        await settingStore.updateVersion(pcbId, newSettingVersion, null, true);
        if (tableStore.updateTableByDoNotStuff) {
          await tableStore.updateTableByDoNotStuff({ pcbId, newUnStuff, newStuff })
        }
      }
      return true
    }
    return false
  }

  moveSelected = (nextTargetKeys) => {
    this.props.changeSave(true);
    this.setState({ selectedNetsList: nextTargetKeys });
  }

  uploadClick = () => {
    const el = this.uploadFileRef.current;
    if (!el) return;
    el.click();
  }

  delConfirmClick = async (type) => {
    const { pcbId, doNotStuffStore, settingStore, tableStore } = this.props;
    const { compName } = this.state;
    let _type = type === 'replace' ? REPLACE : MERGE;
    const data = await doNotStuffStore.uploadFile({ designId: pcbId, file: this.file, type: _type, compName, settingStore, tableStore });
    if (typeof data === 'string') {
      message.error(data);
    } else {
      this.setState({
        selectedNetsList: data
      })
    }
    this.file = {};
    this.setState({
      confirmVisible: false
    })
  }

  onUploadFile = async (e) => {
    const { selectedNetsList, compName } = this.state;
    const { pcbId, doNotStuffStore, settingStore, tableStore } = this.props;
    const file = e.target.files[0];
    await this.saveNotStuff()
    if (selectedNetsList.length) {
      this.setState({
        confirmVisible: true
      })
      this.file = file;
    } else {
      const data = await doNotStuffStore.uploadFile({ designId: pcbId, file, type: MERGE, compName, settingStore, tableStore });
      if (typeof data === 'string') {
        message.error(data)
      } else {
        this.setState({
          selectedNetsList: data
        })
      }
      this.file = {}
    }
    this.uploadFileRef.current.value = "";
  }

  downloadClick = async () => {
    const { pcbId, doNotStuffStore } = this.props;

    await this.saveNotStuff();

    const txtFile = await doNotStuffStore.downloadFile(pcbId);

    if (!txtFile) {
      message.error('Download DNS file error!');
      return;
    }

    // create virtual a tag
    const designName = designConstructor.getDesignName(pcbId) || 'design';
    const a = document.createElement('a');
    a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(txtFile)}`;
    a.download = `${designName}_doNotStuff.txt`;
    a.style.display = 'none';

    // add a tag and click
    document.body.appendChild(a);
    a.click();

    // clear a tag
    document.body.removeChild(a);
  }

  uploadAndDownloadIcon = () => {
    return (
      <span>
        <DownloadOutlined
          title="Download"
          className='component-stuff-upload-icon'
          onClick={() => this.downloadClick()} />
        <UploadOutlined
          title="Upload"
          className='component-stuff-upload-icon'
          onClick={() => this.uploadClick(true)} />
        <strong>Not Stuff</strong>
      </span>
    );
  }

  render() {
    const { compName = [], loading, selectedNetsList, confirmVisible } = this.state;
    const compNameObj = compName.map(item => { return { key: item, title: item } })
    return <Spin spinning={loading} tip={loading ? `Loading Components...` : ''}>
      <div className="component-stuff-content">
        <TransferCom
          compNameObj={compNameObj}
          targetKeys={selectedNetsList}
          listStyle={{
            width: getPanelWidth('48%', { defaultWidth: 240 }),
            height: '100%',
          }}
          titles={[<strong>All Components</strong>, this.uploadAndDownloadIcon()]}
          locale={{
            itemUnit: 'Components',
            itemsUnit: 'Components',
            searchPlaceholder: 'Search Components'
          }}
          onChange={this.moveSelected}
          disabled={false}
          showSearch={true}
        />
      </div>
      <input
        type='file'
        ref={this.uploadFileRef}
        style={{ display: 'none' }}
        onChange={(e) => this.onUploadFile(e)}
      />
      {confirmVisible ? <DelConfirm
        type="uploadDNS"
        closePanel={() => this.uploadClick(false)}
        maskStyle={true}
        delConfirmClick={this.delConfirmClick}
        confirmButton="uploadDNS"
        message={"Keep the existing setup?"}
      /> : null}
    </Spin>
  }
}

export default DoNotStuff;