class VerificationInfo {
  constructor() {
    this.getVerificationInfo.bind(this);
    this.saveVerificationInfo.bind(this);
    this.deleteVerificationInfo.bind(this);
  }

  getVerificationInfo(verificationId) {
    const channelId = this.verificationList ? this.verificationList[verificationId] : null;
    return channelId;
  }

  saveVerificationInfo(channels) {
    let list = {};
    if (channels) {
      const _channels = JSON.parse(JSON.stringify(channels));
      for (const item of _channels) {
        item.children.forEach(ve => {
          list[ve.id] = item.id;
        })
      }
    }
    this.verificationList = list;
  }

  deleteVerificationInfo() {
    if (this.verificationList) {
      delete this.verificationList
    }
  }
}

const Info = new VerificationInfo();
export default Info;