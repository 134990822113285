import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import { Spin } from 'antd'
import { createPortal } from 'react-dom';
import PortSetup from '../../../components/PortSetup';
import { CASCADE } from '../../../constants/pageType';

class CascadePortSetup extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
    this.state = {
      panelWidth: 600,
      panelHeight: 600,
      layoutData: {},
      loading: true,
      statePorts: [],
      error: false,
    }
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width = 1000, height = 1000 } = offset;
    this.setState({
      panelWidth: width * 0.8,
      panelHeight: height * 0.8,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.props.onPortsRef(this);
    this.setState({
      statePorts: this.props.ports || []
    })
    setTimeout(() => {
      this.setLayoutData();
    }, 100)
  }

  setLayoutData = () => {
    const { targetIC, PowerNets, ReferenceNets, pcbId } = this.props;
    this.setState({
      layoutData: {
        targetIC,
        pcbId,
        PowerNets,
        ReferenceNets
      },
      loading: false
    })
  }

  saveStatePorts = (id, ports) => {
    const { multiPCB } = this.props
    this.setState({
      statePorts: ports
    })
    if (multiPCB) {
      if (!ports.some(port => port.referencePins.length === 0)) {
        this.setState({ error: false })
      }
      this.props.savePorts(id, ports)
    }
  }

  savePorts = (id, ports) => {
    if (!ports.some(port => port.referencePins.length === 0)) {
      this.setState({ error: false })
    }
    this.setState({
      statePorts: ports
    })
    this.props.savePorts(id, ports)
  }

  closeModal = () => {
    const { statePorts } = this.state;
    const { notIncludeTarget, onPortsRef, save, savePortsAndClose, multiPCB } = this.props;
    if (multiPCB) {
      if (statePorts.some(port => port.referencePins.length === 0)) {
        this.setState({ error: 'Port require at least one power pin and one ground pin' })
      } else {
        this.setState({ error: false })
        onPortsRef(null);
        save();
        savePortsAndClose && savePortsAndClose(statePorts)
      }
    } else if (notIncludeTarget) {
      onPortsRef(null);
      save();
      savePortsAndClose && savePortsAndClose(statePorts)
    } else if (statePorts.some(port => port.referencePins.length === 0)) {
      this.setState({ error: 'Port require at least one power pin and one ground pin' })
    } else {
      this.setState({ error: false })
      onPortsRef(null);
      save();
      savePortsAndClose && savePortsAndClose(statePorts)
    }
  }

  renderDialog() {
    const { panelWidth, panelHeight, layoutData, loading, error } = this.state;
    const { targetIC = "", pcbId = "", PowerNets = [], ReferenceNets = [] } = layoutData;
    const title = this.props.title || `Port Generation${PowerNets.length ? ` - ${PowerNets[0]}` : ''}`
    const content = (
      <Fragment>
        <Panel
          id='imp-port-panel'
          className='imp-port-panel'
          position='panel-center-left'
          title={title}
          zIndex={1000}
          onCancel={this.closeModal}
          width={panelWidth > 600 ? panelWidth : 600}
          height={panelHeight}
          draggable
          minWidth={600}
          minHeight={600}
        >
          {loading && <Spin spinning={loading} size="large" tip="Loading...">
            <div style={{ width: "100%", height: 600 }}></div>
          </Spin>}
          {pcbId && <PortSetup
            {...this.props}
            product={CASCADE}
            designId={pcbId}
            PowerNets={PowerNets}
            ReferenceNets={ReferenceNets}
            chip={targetIC}
            savePorts={this.props.notIncludeTarget ? this.saveStatePorts : this.savePorts}
            error={error}
          />}
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }

  render() {
    const { text } = this.props;
    return <Fragment>
      <div className="editable-cell-value-wrap" ref={this.inputRef}>
        {text}
      </div>
      {this.renderDialog()}
    </Fragment>
  }
}

export default CascadePortSetup;