import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import SetSPNodeModel from './multiPinSPICEModel';
import StimulusSetup from './stimulusSetup';
import './index.css';
import { getDefaultIndex } from '../../services/helper/setDefaultName';

const REPEATER = 'Repeater', STIMULUS = 'Stimulus';

class PinNodeConnect extends Component {
  constructor(props) {
    super(props);
    const { model, modelType } = props;
    let _model = model ? JSON.parse(JSON.stringify(model)) : { files: [], pairs: [] };
    const _pairs = _model.pairs && _model.pairs.length > 0 ? this.getPairs(modelType, _model.pairs) : this.getPairs(modelType);
    this.state = {
      type: modelType,// Repeater / stimulus(todo)
      model: _model,
      files: Array.isArray(_model.files) ? _model.files : [],
      pairs: _pairs,
      height: 0,
      maxHeight: 600,
      maxWidth: 690
    };
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  getMaxHeight = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { height } = offset;
      if (!height) return 800;
      const _height = (height - 100) * 0.77;
      return _height > 1200 ? 1200 : _height;
    }
  }

  getMaxWidth = () => {
    if (this.dialogRoot) {
      const offset = this.dialogRoot.getBoundingClientRect();
      const { width } = offset;
      if (!width) return 690;
      const _width = width * 0.8;
      return _width > 690 ? 690 : _width;
    }
  }

  getPairs = (modelType, modelPairs = []) => {
    let pairs = [];
    const { pins = [], model } = this.props;
    const files = model ? (model.files || []) : [];
    switch (modelType) {
      case REPEATER:
        const modelObj = {};
        const modelKeys = [];
        files.forEach((item, index) => {
          if (!modelObj[`${item.libraryId}::${item.fileName}::${item.subckt}`]) {
            modelObj[`${item.libraryId}::${item.fileName}::${item.subckt}`] = index;
            modelKeys.push(index);
          }
        })
        pins.forEach(item => {
          let pin = {
            pin: item.pin,
            node: "",
            libraryId: "",
            fileName: "",
            subckt: "",
            modelKey: ""
          };
          const findPair = modelPairs.find(it => it.pin === item.pin);
          let modelKey = findPair ? findPair.modelKey : ""
          if (findPair) {
            if (!findPair.modelKey && findPair.node && findPair.libraryId && findPair.fileName && findPair.subckt) {
              if (!modelObj[`${findPair.libraryId}::${findPair.fileName}::${findPair.subckt}`]) {
                modelKey = getDefaultIndex(modelKeys.length, modelKeys);
                modelObj[`${findPair.libraryId}::${findPair.fileName}::${findPair.subckt}`] = modelKey;
                modelKeys.push(modelKey);
              } else {
                modelKey = modelObj[`${findPair.libraryId}::${findPair.fileName}::${findPair.subckt}`]
              }
            }
            pin = {
              pin: item.pin,
              node: findPair.node || "",
              libraryId: findPair.libraryId || "",
              fileName: findPair.fileName || "",
              subckt: findPair.subckt || "",
              modelKey
            }
          }
          pairs = [...pairs, pin];
        });
        break;
      case STIMULUS:
        pins.forEach(item => {
          let pin = {
            pin: `${item.pin}_in`,
            node: "",
            libraryId: "",
            fileName: "",
            subckt: ""
          };
          const findPair = modelPairs.find(it => it.pin === `${item.pin}_in`);
          if (findPair) {
            pin = {
              pin: `${item.pin}_in`,
              node: findPair.node || "",
              libraryId: findPair.libraryId || "",
              fileName: findPair.fileName || "",
              subckt: findPair.subckt || "",
            }
          }
          pairs = [...pairs, pin];
        });
        break;
      default: break;
    }
    return pairs;
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    window.addEventListener('resize', this.resize);
    //update panel body height
    this.resize();
    this.setContentHeight(0);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({
      maxHeight: this.getMaxHeight(),
      maxWidth: this.getMaxWidth()
    })
  }

  componentDidUpdate = (prevProps) => {
    const { verificationId } = this.props;
    if (verificationId !== prevProps.verificationId) {
      this.props.save();
    }
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { className } = this.props;
    const inputElements = document.getElementsByClassName(className);
    if (target && target.className && inputElements) {
      //open other repeater setup panel , close current panel
      const findTargetList = Array.prototype.filter.call(inputElements, function (testElement) {
        return testElement.className === target.className;
      });
      if (findTargetList && findTargetList.length > 0) {
        this.closeModal();
      }
    }
  }

  closeModal = () => {
    const { files, pairs, type } = this.state;
    let model = { files, pairs }, stopClose = false;
    if (type === REPEATER) {
      const _modelInfo = this.Child.saveModel();
      model = { files: _modelInfo.files, pairs: _modelInfo.pairs };
      this.props.saveLibraryModel(model, this.props.record);
    } else if (type === STIMULUS) {
      stopClose = this.stimulusChild.savePinValue && this.stimulusChild.savePinValue();
    }

    if (!stopClose) {
      this.props.save();
    } else {
      this.setContentHeight(0);
    }
  }

  onRef = (ref) => {
    this.Child = ref;
  }

  onChildRef = (ref) => {
    this.stimulusChild = ref;
  }

  setContentHeight = (time) => {
    let _time = 300;
    if (time !== undefined) {
      _time = time;
    }
    const { modelType, displayType, compName } = this.props;
    const prevHeight = this.state.height;
    setTimeout(() => {
      //set panel height
      let content = document.getElementById(`spice-model_${compName}_${modelType}_${displayType}_component`);
      let height = content ? content.offsetHeight + 36 : prevHeight && 150;
      this.setState({
        height
      })
    }, _time);
  }

  renderDialog() {
    const { files, pairs, height, maxWidth, maxHeight } = this.state;
    const { libraryList, modelType, displayType, compName, applyAll = false, product } = this.props;
    const _height = height > maxHeight ? maxHeight : height;
    const content = (
      <Panel
        className={`spice-model-panel ${(product || "").toLowerCase()}-panel`}
        title={<div className='spice-model-panel-title'>{compName} {modelType} Model</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={maxWidth}
        minWidth={200}
        minHeight={200}
        position='panel-center'
        draggable
        height={_height + 32}
        overflow={"auto"}
      >
        <div className='spice-model-content' id="spice-scrollId">
          <div id={`spice-model_${compName}_${modelType}_${displayType}_component`} className='spice-model-content-sub-box'>
            {modelType === REPEATER && <SetSPNodeModel
              onRef={this.onRef}
              files={files}
              pairs={pairs}
              fileList={libraryList}
              modelPinType={REPEATER}
              scrollId={"spice-scrollId"}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              {...this.props}
              {...this.actions}
            />}
            {modelType === STIMULUS && <StimulusSetup
              onChildRef={this.onChildRef}
              files={files}
              pairs={pairs}
              fileList={libraryList}
              componentName={compName}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              scrollId={"spice-scrollId"}
              setContentHeight={this.setContentHeight}
              applyAll={applyAll}
              {...this.props}
              {...this.actions}
            />}
          </div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  actions = {
    setContentHeight: this.setContentHeight
  }

  render() {
    const { inputRef } = this;
    const { text } = this.props;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default PinNodeConnect;