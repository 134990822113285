import React, { Fragment } from 'react';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export const TargetNameEdit = (props) => {
  const { nameStatus, targetName, changeName, saveNewTargetName, saveTempName, extraDom, onRef, maxWidth = '95%' } = props;
  return !nameStatus ? <Fragment>
    <span className='font-bold imp-target-value-span' style={{ maxWidth }}>
      <span>{targetName}</span>
      <EditOutlined
        title="Change Target Name"
        className='cascade-imp-edit-icon imp-target-table-icon'
        onClick={(e) => changeName(e, true)} />
    </span>
    {extraDom ? extraDom : null}
  </Fragment> : <Fragment>
    <Input
      className='imp-target-name-input'
      ref={(ref) => onRef(ref)}
      placeholder="Target Name"
      defaultValue={targetName}
      onChange={(e) => saveTempName(e)}
      onPressEnter={(e) => saveNewTargetName(e)}
    />
    <CheckOutlined className='cascade-imp-icon' onClick={(e) => saveNewTargetName(e)} />
    <CloseOutlined className='cascade-imp-icon' onClick={(e) => changeName(e, false)} />
  </Fragment>;
}