import React, { Component, Fragment, Suspense } from 'react';
import { Spin } from 'antd';
import BottomLine from '../BottomLine';
import { getReSizeTopBottom, getBodySize, updateContentHeight } from '@/services/helper/documentReSize';


class TB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 400,
      topHeight: null,
      linePosition: {
        position: "absolute",
        bottom: 400
      },
      contentResize: true,
      loading: true
    }
  }

  componentDidMount() {
    this.screenChange();
    this.parentEle = document.getElementsByClassName('aurora-content')[0];
    const splitSize = this.props.splitSize ? this.props.splitSize : 2;
    if (this.parentEle) {
      const height = this.parentEle.offsetHeight / splitSize;
      this.setState({
        height: height,
        topHeight: height,
        linePosition: {
          position: "absolute",
          bottom: height,
        },
      });
    }

    this.setState({
      loading: false
    })
  }

  componentDidUpdate = (prevProps) => {
    const { tabVisible, tabSelectKeys, height } = this.props;
    const { height: stateHeight } = this.state;
    if (height && height !== prevProps.height && height !== stateHeight) {
      this.changeHeight(height);
    }
    if (tabVisible && !prevProps.tabVisible && tabSelectKeys.includes('ccc')) {
      this.props._getCCCMsg();
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.getSize();
  }

  getSize = () => {
    const { bottomClassName } = this.props;
    const { height, topHeight, linePosition } = getReSizeTopBottom('aurora-content', bottomClassName, this.props.splitSize);
    this.setState({
      height,
      topHeight,
      linePosition: { ...linePosition },
    });
    this.props.changeHeight && this.props.changeHeight(height);
  }

  changeHeight = (height) => {
    const { bodyClientHeight } = getBodySize();
    height = updateContentHeight(height, 120);
    let position = {
      position: "absolute",
      bottom: height + 1,
      minHeight: 40,
      maxHeight: bodyClientHeight - 120
    }
    this.setState({
      height,
      topHeight: this.parentEle.offsetHeight - height,
      linePosition: position
    })
    this.props.changeHeight && this.props.changeHeight(height);
  }

  changeSize = (height) => {
    this.changeHeight(height);
  }

  render() {
    const { linePosition, height, topHeight, loading } = this.state;
    const { bottomClassName } = this.props;
    return (
      <Fragment>
        <Spin size='large' spinning={loading}>
          <div
            className='aurora-content-top'
            style={{ bottom: height }}>
            <Suspense fallback={null}>
              {this.props.getTop(topHeight)}
            </Suspense>
          </div>
          <BottomLine
            position={linePosition}
            changeHeight={(height) => this.changeHeight(height)}
            height={height}
            resize={this.changeSize}
          />
          <div className={bottomClassName} style={{ height: height }}>
            <Suspense fallback={null}>
              {this.props.getBottom(topHeight)}
            </Suspense>
          </div>
        </Spin>
      </Fragment>
    )
  }
};

export default TB;