import http from "../http";

/**
 * get channel list by designId
 * 2021.03.24
 * @param {string} designId
 * @return
 */
export function getAndesV2ChannelListByDesignId(designId) {
  return http.get(`/andes/channel/list?designId=${designId}`);
}

/** 
 * create channel
 * 2021.03.24
 * @param {object} channel
 * @return
 */
export function createAndesV2Channel(channel) {
  return http.post(`/andes/channel/create`, { ...channel });
}

/** 
 * delete channel
 * 2021.03.24
 * @param {string} channelId
 * @return
 */
export function deleteAndesV2ChannelByChannelId(channelId) {
  return http.post(`/andes/channel/delete`, { id: channelId });
}

/** 
 * get channel content by id
 * 2021.03.26
 * @param {string} channelId
 * @return
 */
export function getAndesV2Detail(channelId) {
  return http.get(`/andes/channel/detail?id=${channelId}`);
}

/** 
 * update channel content or rename channel
 * 2021.03.26
 * @param {object} content -> channel content {id, name, version, designVersion, content:{}, ... }
 * @return
 */
export function updateAndesV2Detail(content) {
  return http.post(`/andes/channel/edit`, content)
}

/** 
 * channel copy
 * @param {String} channelId 
 * @param {String} name
 * 2021.07.12
*/
export function AndesCopyChannel({ name, channelId }) {
  return http.post(`/andes/channel/copy`, {
    id: channelId,
    name: name
  })
}

/**
 * get channel report config
 * 2022/04/06
 * @param {String} channelId
 * @param {String} projectId
 *  */
export function getAndesChannelReportConfig({ projectId, channelId }) {
  return http.get(`/andes/${projectId}/${channelId}/report/config`);
}

/** 
 * save report config
 * 2022/04/06
 * @param {String} channelId
 * @param {String} projectId
 * @param {Object} config
 */
export function saveAndesChannelReportConfig({ projectId, channelId, config }) {
  return http.post(`/andes/${projectId}/${channelId}/report/config`, config)
}


/**
 * Generate channel report
 * 2022/04/06
 * @export
 * @param {*} param
 * @returns
 */
export function generateAndesChannelReport({ projectId, param }) {
  return http.post(`/andes/${projectId}/report`, param);
}

/**
 * Get andes report status
 * 2022/04/06
 * @export
 * @param {string} projectId
 * @param {string} channelId
 * @returns
 */
export function getAndesReportStatus({ projectId, channelId }) {
  return http.get(`/andes/${projectId}/${channelId}/report/status`);
}

/**
 * get project report, response: file
 * @export
 * @param  {string} projectId
 * @param  {string} channelId
 * @param  {string} format
 * @returns 
 */
export function getAndesChannelReport({ projectId, channelId, format }) {
  return http.get(`/andes/${projectId}/${channelId}/andes/report/${format}`, {
    responseType: 'arraybuffer'
  });
}