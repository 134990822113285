import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  editChannelType,
  saveExtraction,
  updateOptions,
  saveReExtraction
} from '../../store/sierra/action';
import ExtractionOptionsContent from './extractionOptionsContent';

class ExtractionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      extraction: {},
      ifDoExtraction: 0,
      maxHeight: 600,
      maxWidth: 830,
      pcbId: null,
      channelType: "Default",
      portImpedance: "50",
      update: false
    }
    this.dialogRoot = document.getElementById('root');
  };

  componentDidMount() {
    this.getExtractionValue()
  }

  componentDidUpdate(prevProps) {
    const { current } = this.props;

    if (prevProps.current !== current) {
      this.getExtractionValue()
    }
  }

  getExtractionValue = () => {
    const { Interfaces, current } = this.props;
    let currentInterface = Interfaces.find(item => item.pcb === current);
    //extraction
    const extraction = currentInterface && currentInterface.content ? currentInterface.content.extraction : null;
    const pcbId = currentInterface.pcbId;
    let _channel = currentInterface.content && currentInterface.content.channel ? currentInterface.content.channel : {};
    const channelType = Object.keys(_channel).length > 0 ? _channel.type : 'Default';
    const portImpedance = currentInterface && currentInterface.content && currentInterface.content.port_setups && currentInterface.content.port_setups.length ? currentInterface.content.port_setups[0].z0 : "50";

    //ifDoExtraction
    const ifDoExtraction = currentInterface && currentInterface.ifDoExtraction ? 1 : 0;
    this.setState({
      extraction,
      ifDoExtraction,
      pcbId,
      channelType,
      portImpedance,
      update: true
    })
  }

  updateReExtraction = ({ ifDoExtraction, closePanel }) => {
    const { current, Interfaces } = this.props;
    const index = Interfaces.findIndex(item => item.pcb === current);
    if (index < 0 && closePanel) {
      this.props.closeModal();
      return;
    }
    const interfaceId = Interfaces[index].interfaceId;
    this.props.saveReExtraction(interfaceId, ifDoExtraction);
  }

  updateStatus = () => {
    this.setState({
      update: false
    })
  }

  render() {
    const { currentConfig, Interfaces, current, apply } = this.props;
    const { extraction, ifDoExtraction, pcbId, channelType, portImpedance, update } = this.state;
    const currentInterface = Interfaces.find(item => item.pcb === current);
    return <ExtractionOptionsContent
      onRef={this.props.onRef}
      ifDoExtraction={ifDoExtraction}
      extraction={extraction}
      pcbId={pcbId}
      current={current}
      currentConfig={currentConfig}
      channelType={channelType}
      portImpedance={portImpedance}
      currentInterface={currentInterface}
      update={update}
      apply={apply}
      editChannelType={this.props.editChannelType}
      saveReExtraction={this.props.saveReExtraction}
      saveExtraction={this.props.saveExtraction}
      _updateOptions={this.props._updateOptions}
      closeModal={this.props.closeModal}
      updateReExtraction={this.updateReExtraction}
      updateStatus={this.updateStatus}
    />
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra, project: { viewList } } } = state;
  const { sierraInfo, currentConfig } = sierra;
  let Interfaces = [];
  if (sierraInfo) {
    Interfaces = sierraInfo.Interfaces;
  }
  return {
    Interfaces,
    currentConfig,
    viewList
  }
};


const mapDispatch = (dispatch) => ({
  editChannelType(info) {
    dispatch(editChannelType(info));
  },
  saveReExtraction(interfaceId, ifDoExtraction) {
    dispatch(saveReExtraction(interfaceId, ifDoExtraction))
  },
  saveExtraction(extraction, pcb, channel, apply) {
    dispatch(saveExtraction(extraction, pcb, channel, apply))
  },
  _updateOptions(type, value, update) {
    dispatch(updateOptions(type, value, update));
  },
})

export default connect(mapState, mapDispatch)(ExtractionPanel);