import { connect } from 'react-redux';
import Profile from '@/components/Profile';

const mapState = (state) => {
  const { simulationReducer: { profile } } = state.PDNReducer;
  return {
    profileLog: profile || []
  }
}

export default connect(mapState, null)(Profile);
