import { METAL, DIELECTRIC } from "../stackupConstants";

const materialTypeList = [METAL, DIELECTRIC];
const SIGMA = "sigma",
  DELTA = "delta",
  EPS_R = "eps_r",
  MIU_R = "miu_r",
  FREQ_DEP = "freq_dep",
  FREQUENCY = "frequency",
  FREQ_CORNER = "freq_corner",
  USE_DC_EPS = "use_dc_eps",
  EPS_DC = "eps_dc",
  NEW_MATERIAL = "New Material",
  COPPER = "Copper", 
  SOLDER = "Solder";
const dielectricList = [{
  key: EPS_R,
  title: "Relative Permittivity"
},
{
  key: DELTA,
  title: "Loss Tangent"
},
{
  key: FREQ_DEP,
  title: "Frequency Dependent (Djordjevic-Sarkar Model)",
  type: "checkBox"
},
{
  key: FREQUENCY,
  title: "Frequency",
  unit: "GHz",
  unitList: ["MHz", "GHz"]
},
{
  key: FREQ_CORNER,
  title: "High Frequency Corner",
  unit: "GHz",
  unitList: ["MHz", "GHz"]
}, {
  key: USE_DC_EPS,
  title: "DC Permittivity​",
  subKey: EPS_DC,
  subTitle: "​DC Permittivity​"
},
{
  key: SIGMA,
  title: "DC Conductivity",
  unit: "S/m​"
}
]

export {
  materialTypeList,
  SIGMA,
  dielectricList,
  FREQ_DEP,
  FREQUENCY,
  USE_DC_EPS,
  FREQ_CORNER,
  EPS_DC,
  EPS_R,
  MIU_R,
  DELTA,
  NEW_MATERIAL,
  COPPER,
  SOLDER
}

/* "name": unique material name,

"type": enum: [dielectric, metal],

"eps_r": float, dielectric constant,

"delta": float, loss tangent,

"sigma": float, conductivity, or DC conductivity for djordjevic-Sarkar model

"freq_dep": enum: [no, djordjevic-sarkar],

"frequency": float, default = 1GHz, djordjevic-Sarkar model only

"freq_corner": float, default = 150GHz, djordjevic-Sarkar model only

"use_dc_eps": Boolean, default = No, djordjevic-Sarkar model only

"eps_dc": float, dielectric constant at DC, djordjevic-Sarkar model only

}

] ​ */