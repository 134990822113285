import { getTextWidth } from "../../helper/getTextWidth";
import { strDelimited } from "../../helper/split";

const IF_NAME = { key: "ifName", name: "IF_NAME", color: "#fabf8f" },
  SP_FILE_NAME = { key: "spFileName", name: "SP_FILE_NAME", color: "#fabf8f" },
  SIMID = { key: "siMid", name: "SIMID", color: "#fabf8f" },
  MODE = { key: "mode", name: "MODE", color: "#fabf8f" },
  VSS_PWRCELL_CKT_NAME = { key: "vssPwrCellCktName", name: "VSS_PWRCELL_CKT_NAME", color: "#00b0f0" },
  VSS_PWRCELL_NUM = { key: "vssPwrCellNum", name: "VSS_PWRCELL_NUM", color: "#00b0f0" },
  VDE18_PWRCELL_CKT_NAME = { key: "vde18PwrCellCktName", name: "VDE18_PWRCELL_CKT_NAME", color: "#fc0" },
  VDE18_PWRCELL_NUM = { key: "vde18PwrCellNum", name: "VDE18_PWRCELL_NUM", color: "#fc0" },
  VDE18_VSS_ODCAP_C0 = { key: "vde18VssOdCapC0", name: "VDE18_VSS_ODCAP_C0", color: "#fc0" },
  VDE18_VSS_ODCAP_C = { key: "vde18VssOdCapC", name: "VDE18_VSS_ODCAP_C", color: "#fc0" },
  VDE18_VSS_ODCAP_R = { key: "vde18VssOdCapR", name: "VDE18_VSS_ODCAP_R", color: "#fc0" },
  VDE18_PKG_R = { key: "vde18PkgR", name: "VDE18_PKG_R", color: "#fc0" },
  VDE18_PKG_L = { key: "vde18PkgL", name: "VDE18_PKG_L", color: "#fc0" },
  VDE18_PCB_R = { key: "vde18PcbR", name: "VDE18_PCB_R", color: "#fc0" },
  VDE18_PCB_L = { key: "vde18PcbL", name: "VDE18_PCB_L", color: "#fc0" },
  VDE_PWRCELL_CKT_NAME = { key: "vdePwrCellCktName", name: "VDE_PWRCELL_CKT_NAME", color: "#fc0" },
  VDE_PWRCELL_NUM = { key: "vdePwrCellNum", name: "VDE_PWRCELL_NUM", color: "#fc0" },
  VDE_VSS_ODCAP_C0 = { key: "vdeVssOdCapC0", name: "VDE_VSS_ODCAP_C0", color: "#fc0" },
  VDE_VSS_ODCAP_C = { key: "vdeVssOdCapC", name: "VDE_VSS_ODCAP_C", color: "#fc0" },
  VDE_VSS_ODCAP_R = { key: "vdeVssOdCapR", name: "VDE_VSS_ODCAP_R", color: "#fc0" },
  VDE_PKG_R = { key: "vdePkgR", name: "VDE_PKG_R", color: "#fc0" },
  VDE_PKG_L = { key: "vdePkgL", name: "VDE_PKG_L", color: "#fc0" },
  VDE_PCB_R = { key: "vdePcbR", name: "VDE_PCB_R", color: "#fc0" },
  VDE_PCB_L = { key: "vdePcbL", name: "VDE_PCB_L", color: "#fc0" },
  VDD_PWRCELL_CKT_NAME = { key: "vddPwrCellCktName", name: "VDD_PWRCELL_CKT_NAME", color: "#fc0" },
  VDD_PWRCELL_NUM = { key: "vddPwrCellNum", name: "VDD_PWRCELL_NUM", color: "#fc0" },
  VDD_VSS_ODCAP_C = { key: "vddVssOdCapC", name: "VDD_VSS_ODCAP_C", color: "#fc0" },
  VDD_VSS_ODCAP_R = { key: "vddVssOdCapR", name: "VDD_VSS_ODCAP_R", color: "#fc0" },
  VDD_PKG_R = { key: "vddPkgR", name: "VDD_PKG_R", color: "#fc0" },
  VDD_PKG_L = { key: "vddPkgL", name: "VDD_PKG_L", color: "#fc0" },
  VDD_PCB_R = { key: "vddPcbR", name: "VDD_PCB_R", color: "#fc0" },
  VDD_PCB_L = { key: "vddPcbL", name: "VDD_PCB_L", color: "#fc0" },
  /*   VOL_MODE = "volMode", */
  PVT_SLOW = { key: "pvtSlow", name: "PVT_SLOW", color: "#c4d79b" },
  PVT_TYP = { key: "pvtTyp", name: "PVT_TYP", color: "#c4d79b" },
  PVT_FAST = { key: "pvtFast", name: "PVT_FAST", color: "#c4d79b" },
  PVT_COND_FILE = { key: "pvtCondFile", name: "PVT_COND_FILE", color: "#c4d79b" },
  VOL_MODE = { key: "volMode", name: "VOL_MODE", color: "#c4d79b" },
  SWEEP_START = { key: "sweepStart", name: "SWEEP_START", color: "#3cc" },
  SWEEP_STOP = { key: "sweepStop", name: "SWEEP_STOP", color: "#3cc" },
  SWEEP_STEP = { key: "sweepStep", name: "SWEEP_STEP", color: "#3cc" },
  SIG = { key: "sig", name: "SIG", color: "#95b3d7" },
  IO_M = { key: "ioM", name: "IO_M", color: "#95b3d7" },
  IOCELL_NAME = { key: "ioCellName", name: "IOCELL_NAME", color: "#95b3d7" },
  TERMINAL_SETTING = { key: "terminalSetting", name: "TERMINAL_SETTING", color: "#95b3d7" },
  INPUT_CAPACITANCE = { key: "inputCap", name: "INPUT_CAPACITANCE", color: "#95b3d7" },
  PAD_CAPACITANCE = { key: "padCap", name: "PAD_CAPACITANCE", color: "#95b3d7" },
  TRAN_STEP = { key: "tranStep", name: "TRAN_STEP", color: "#fbffcc" },
  TRAN_TIME = { key: "tranTime", name: "TRAN_TIME", color: "#fbffcc" },
  FREQ = { key: "freq", name: "FREQ", color: "#fbffcc" },
  TIME_DELAY = { key: "timeDelay", name: "TIME_DELAY", color: "#fbffcc" },
  RISE_FALL_TIME = { key: "riseFallTime", name: "RISE_FALL_TIME", color: "#fbffcc" },
  INITIAL_TRANSITION = { key: "initialTransition", name: "INITIAL_TRANSITION", color: "#fbffcc" },
  A_PKG_R = { key: "pkgRes", name: "A_PKG_R", color: "#fc0" },
  A_PKG_L = { key: "pkgInd", name: "A_PKG_L", color: "#fc0" },
  A_PKG_C = { key: "pkgCap", name: "A_PKG_C", color: "#fc0" },
  PCB1_SUBCKT_NAME = { key: "pcb1SubCktName", name: "PCB1_SUBCKT_NAME", color: "#9c0" },
  PCB1_LENGTH = { key: "pcb1Length", name: "PCB1_LENGTH", color: "#9c0" },
  PCB1_DUMP = { key: "pcb1Dump", name: "PCB1_DUMP", color: "#9c0" },
  PCB2_SUBCKT_NAME = { key: "pcb2SubCktName", name: "PCB2_SUBCKT_NAME", color: "#9c0" },
  PCB2_LENGTH = { key: "pcb2Length", name: "PCB2_LENGTH", color: "#9c0" },
  PCB2_DUMP = { key: "pcb2Dump", name: "PCB2_DUMP", color: "#9c0" },
  PCB3_SUBCKT_NAME = { key: "pcb3SubCktName", name: "PCB3_SUBCKT_NAME", color: "#9c0" },
  PCB3_LENGTH = { key: "pcb3Length", name: "PCB3_LENGTH", color: "#9c0" },
  PCB3_DUMP = { key: "pcb3Dump", name: "PCB3_DUMP", color: "#9c0" },
  PCB4_SUBCKT_NAME = { key: "pcb4SubCktName", name: "PCB4_SUBCKT_NAME", color: "#9c0" },
  PCB4_LENGTH = { key: "pcb4Length", name: "PCB4_LENGTH", color: "#9c0" },
  PCB4_DUMP = { key: "pcb4Dump", name: "PCB4_DUMP", color: "#9c0" },
  D_IO_CAP = { key: "ioCap", name: "D_IO_CAP", color: "#ff0" },
  MEAS_V_VIH = { key: "measVVih", name: "MEAS_V_VIH", color: "#ccc0da" },
  MEAS_V_VIL = { key: "measVVil", name: "MEAS_V_VIL", color: "#ccc0da" };


const VDE2_OPTIONS = [VDE18_PWRCELL_CKT_NAME, VDE18_PWRCELL_NUM, VDE18_VSS_ODCAP_C0, VDE18_VSS_ODCAP_C,
  VDE18_VSS_ODCAP_R, VDE18_PKG_R, VDE18_PKG_L, VDE18_PCB_R, VDE18_PCB_L];

const VDE_OPTIONS = [VDE_PWRCELL_CKT_NAME, VDE_PWRCELL_NUM, VDE_VSS_ODCAP_C0, VDE_VSS_ODCAP_C,
  VDE_VSS_ODCAP_R, VDE_PKG_R, VDE_PKG_L, VDE_PCB_R, VDE_PCB_L];

const VDD_OPTIONS = [VDD_PWRCELL_CKT_NAME, VDD_PWRCELL_NUM, VDD_VSS_ODCAP_C, VDD_VSS_ODCAP_R, VDD_PKG_R, VDD_PKG_L, VDD_PCB_R, VDD_PCB_L]

const SIGNALS_PCB_OPTIONS = [PCB1_SUBCKT_NAME, PCB1_LENGTH, PCB1_DUMP,
  PCB2_SUBCKT_NAME, PCB2_LENGTH, PCB2_DUMP,
  PCB3_SUBCKT_NAME, PCB3_LENGTH, PCB3_DUMP,
  PCB4_SUBCKT_NAME, PCB4_LENGTH, PCB4_DUMP,]

const SIGNAL_OPTIONS = [SIG, IO_M, IOCELL_NAME, TERMINAL_SETTING,
  INPUT_CAPACITANCE, PAD_CAPACITANCE, TRAN_STEP, TRAN_TIME,
  FREQ, TIME_DELAY, RISE_FALL_TIME, INITIAL_TRANSITION, A_PKG_R, A_PKG_L, A_PKG_C,
  ...SIGNALS_PCB_OPTIONS,
  D_IO_CAP, MEAS_V_VIH, MEAS_V_VIL];



const tableKeys = [
  IF_NAME, SP_FILE_NAME, SIMID, MODE, VSS_PWRCELL_CKT_NAME, VSS_PWRCELL_NUM,
  ...VDE_OPTIONS,
  ...VDD_OPTIONS,
  PVT_SLOW, PVT_TYP, PVT_FAST, PVT_COND_FILE, VOL_MODE,
  SWEEP_START, SWEEP_STOP, SWEEP_STEP,
  ...SIGNAL_OPTIONS];


const MERGE_VALUE_LIST = [IF_NAME, SP_FILE_NAME, SIMID, MODE,
  VSS_PWRCELL_CKT_NAME, VSS_PWRCELL_NUM, ...VDE2_OPTIONS, ...VDE_OPTIONS, ...VDD_OPTIONS,
  PVT_SLOW, PVT_TYP, PVT_FAST, PVT_COND_FILE, VOL_MODE, SWEEP_START, SWEEP_STOP, SWEEP_STEP].map(item => item.key);

const EMPTY_VALUE_DISABLED_LIST = [
  PCB1_SUBCKT_NAME, PCB1_LENGTH, PCB1_DUMP,
  PCB2_SUBCKT_NAME, PCB2_LENGTH, PCB2_DUMP,
  PCB3_SUBCKT_NAME, PCB3_LENGTH, PCB3_DUMP,
  PCB4_SUBCKT_NAME, PCB4_LENGTH, PCB4_DUMP,
].map(item => item.key);



function getSpiceCardTableData(spiceCardTemplate) {
  if (!spiceCardTemplate || !spiceCardTemplate.length) {
    return [];
  }
  let _tableKeys = JSON.parse(JSON.stringify(tableKeys));
  const tempKeys = Object.keys(spiceCardTemplate[0] || {});
  const findVDE18 = tempKeys.find(item => !!VDE2_OPTIONS.find(it => it.key === item));
  if (findVDE18) {
    const index = _tableKeys.findIndex(item => item.key === "vdePwrCellCktName");
    _tableKeys.splice(index, 0, ...VDE2_OPTIONS);
  }
  let data = [];
  for (let { key, name, color } of _tableKeys) {
    let obj = { key, name, color };
    for (let item of spiceCardTemplate) {
      const columnKey = getIfNameKey({
        ifName: item.ifName,
        spFileName: item.spFileName,
        siMid: item.siMid
      }).key;
      obj[`${columnKey};;${item.index}`] = item[key] || ""
    }
    data.push(obj)
  }
  return data;
}

function getIfNameKey({ ifName, spFileName, siMid }) {
  let title = "", key = "";
  if (ifName) {
    title = ifName;
    key = ifName;
  }
  if (spFileName) {
    title = title + spFileName;
    key = key ? `${key}::${spFileName}` : spFileName;
  }
  if (siMid) {
    title = title + siMid;
    key = key ? `${key}::${siMid}` : siMid;
  }
  return { key, title }
}

function getSpiceCardTableColumns({ spiceCardTemplate, columns, stateTableExtendKeys, isExpand }) {
  const mainEle = document.getElementById("himalayas-content-main");
  let mainWidth = 0;
  if (mainEle && mainEle.offsetWidth) {
    mainWidth = mainEle.offsetWidth - 80;
    mainWidth = mainWidth > 520 ? mainWidth : 520
  }
  let ifNameList = [], ifNameAllList = [], ifNameObj = {}, tableExtendKeys = [];
  for (let item of spiceCardTemplate) {
    const { title, key } = getIfNameKey({
      ifName: item.ifName,
      spFileName: item.spFileName,
      siMid: item.siMid
    })
    if (!ifNameList.find(it => it.key === key)) {
      ifNameList.push({
        key,
        title
      });
    }
    //ifName::spFileName::siMid::index
    ifNameAllList.push(`${key};;${item.index}`);
  }

  ifNameAllList = [...new Set(ifNameAllList)];

  for (let item of ifNameList) {
    const filterList = ifNameAllList.filter(_item => _item.split(";;")[0] === item.key);
    ifNameObj[item.key] = {
      key: item.key,
      title: item.title,
      length: filterList.length,
      firstCol: filterList[0]
    }
  }

  const expandIfNameList = ifNameAllList.filter(item => stateTableExtendKeys.includes(item));
  const isHideAll = !expandIfNameList.length;
  const list = ifNameList.map((item, index) => {
    const findItem = ifNameAllList.find(it => it.split(";;")[0] === item.key);
    const otherItems = ifNameAllList.filter(it => it !== findItem && it.split(";;")[0] === item.key);
    const colSpan = spiceCardTemplate.filter(key => getIfNameKey({ ifName: key.ifName, spFileName: key.spFileName, siMid: key.siMid }).key === item.key).length;
    tableExtendKeys.push(findItem);
    const isHide = isExpand && !stateTableExtendKeys.includes(findItem) ? true : false;
    const columnWidth = getColumnWidth({
      columnKey: findItem,
      ifNameKey: item.title,
      spiceCardTemplate,
      isHide
    })
    const width = isHide ? 90 : columnWidth / (otherItems.length + 1);
    return [{
      title: item.title,
      dataIndex: findItem,
      key: findItem,
      colSpan: colSpan,
      className: index !== ifNameList.length - 1 ? "himalayas-spice-card-split-line-div" : "",
      ifNameKey: item.title,
      width: width,
      isHide
    },
    ...otherItems.map((key, _index) => ({
      title: key,
      dataIndex: key,
      key: key,
      colSpan: 0,
      width: isHide ? 0 : width,
      isHide,
      tdClassName: index !== ifNameList.length - 1 && _index === otherItems.length - 1 ? "himalayas-spice-card-split-line-div" : ""
    }))
    ]
  })
  columns.push(...list.flat(2));
  //all column width
  const allWidth = columns.map(item => item.width).filter(item => typeof (item) === "number");
  let _allWidth = 0;
  allWidth.forEach(item => _allWidth += item);

  if (mainWidth > _allWidth) {
    const contentWidth = mainWidth - 224;//224 is left fix column width
    _allWidth = _allWidth - 224;
    if (isHideAll) {
      //hide column
      const itemWidth = contentWidth / (columns.filter(item => item.key !== "name" && item.colSpan !== 0).length || 1);
      columns.forEach((item, index) => {
        if (index !== 0 && item.colSpan !== 0) {
          item.width = itemWidth;
        } else if (index !== 0) {
          item.width = 0;
        }
      })
    } else {
      const showList = columns.filter(item => item.key !== "name" && !item.isHide);
      const itemAddWidth = (contentWidth - _allWidth) / showList.length;
      columns.forEach((item, index) => {
        if (index !== 0 && !item.isHide) {
          item.width = item.width + itemAddWidth;
        }
      })
    }
  }
  return { columns, ifNameObj, tableExtendKeys }
}

function getTableScrollWidth(spiceCardTemplate, tableExtendKeys, columns) {
  if (!spiceCardTemplate || !spiceCardTemplate.length) {
    return 300;
  }
  const _columnTitles = columns.filter(item => item.colSpan !== 0 && item.dataIndex !== "name");
  let width = 0;
  for (let column of _columnTitles) {
    let isHide = false;
    if (!tableExtendKeys.includes(column.key)) {
      isHide = true;
    }
    const columnWidth = getColumnWidth({
      columnKey: column.key,
      ifNameKey: column.ifNameKey,
      spiceCardTemplate,
      isHide
    })
    width += columnWidth;
  }
  return width
}

function getColumnWidth({ columnKey, ifNameKey, spiceCardTemplate, isHide }) {
  if (isHide) {
    return 90;
  }
  let width = 0;
  const key = strDelimited(columnKey, ";;", { returnIndex: 0 });
  const filterList = spiceCardTemplate.filter(item => key === getIfNameKey({ ifName: item.ifName, spFileName: item.spFileName, siMid: item.siMid }).key);
  const titleWidth = getTextWidth(ifNameKey || "", 14) + 40;
  const bodyWidth = (filterList.length || 1) * 140;
  if (titleWidth > bodyWidth) {
    width = titleWidth;
  } else {
    width = bodyWidth;
  }
  return width;
}

function getSpiceCardData(spiceCardTemplate) {
  let tabDataObj = {}, tabList = [];
  if (!spiceCardTemplate || !spiceCardTemplate.length) {
    return { tabDataObj, tabList }
  }

  const tempKeys = Object.keys(spiceCardTemplate[0] || {});
  const findVDE18 = tempKeys.find(item => !!VDE2_OPTIONS.find(it => it.key === item));

  for (let item of spiceCardTemplate) {
    const { title, key } = getIfNameKey({
      ifName: item.ifName,
      spFileName: item.spFileName,
      siMid: item.siMid
    })
    if (!tabList.find(it => it.key === key)) {
      tabList.push({
        key,
        title
      });
    }
    if (!tabDataObj[key]) {
      const data = groupSpiceCardTableData(item, findVDE18);
      tabDataObj[key] = data;
    } else {
      const signal = getSpiceCardIoSignal(item);
      tabDataObj[key].ioSignals.push(signal)
    }
  }
  return { tabDataObj, tabList }
}

function groupSpiceCardTableData(itemData, findVDE18) {
  let dataGroups = {
    name: [],
    PDN: [],
    ioSignals: [],
    configuration: []
  };

  dataGroups.name = getTableItem([[IF_NAME, SIMID], [SP_FILE_NAME, MODE]], itemData);
  dataGroups.PDN = getTableItem(getPDNTableKeys(findVDE18), itemData);
  dataGroups.configuration = getTableItem([[SWEEP_START, SWEEP_STOP, SWEEP_STEP], [PVT_SLOW, PVT_TYP, PVT_FAST], [PVT_COND_FILE, VOL_MODE, {}]], itemData);
  dataGroups.ioSignals = [getSpiceCardIoSignal(itemData)];
  return dataGroups;
}

function getPDNTableKeys(findVDE18) {
  let vdd_vss_options = [...VDD_OPTIONS, VSS_PWRCELL_CKT_NAME, VSS_PWRCELL_NUM];
  return findVDE18 ? vdd_vss_options.map((item, index) => {
    return [VDE2_OPTIONS[index] || {}, VDE_OPTIONS[index] || {}, item];
  })
    : [
      [VDE_PWRCELL_CKT_NAME, VDE_PKG_L, VDD_VSS_ODCAP_R],
      [VDE_PWRCELL_NUM, VDE_PCB_R, VDD_PKG_R],
      [VDE_VSS_ODCAP_C0, VDE_PCB_L, VDD_PKG_L],
      [VDE_VSS_ODCAP_C, VDD_PWRCELL_CKT_NAME, VDD_PCB_R],
      [VDE_VSS_ODCAP_R, VDD_PWRCELL_NUM, VDD_PCB_L],
      [VDE_PKG_R, VDD_VSS_ODCAP_C, VSS_PWRCELL_CKT_NAME],
      [{}, {}, VSS_PWRCELL_NUM]];
}

function getTableItem(list, itemData) {
  //  const list = [[IF_NAME, SIMID], [SP_FILE_NAME, MODE]];
  let data = [];
  for (let i = 0; i < list.length; i++) {
    const itemList = list[i];
    let dataObj = {};
    for (let j = 0; j < itemList.length; j++) {
      const item = itemList[j] || {};
      dataObj[`key${j}`] = { ...item, type: "key" };
      dataObj[`value${j}`] = { ...item, value: itemData[item.key], type: "value" };

    }
    data.push(dataObj);
  }
  return data;
}

function getSpiceCardIoSignal(itemData) {
  let signal = {};
  for (let item of SIGNAL_OPTIONS) {
    signal[item.key] = itemData[item.key] || null;
  }
  return signal;
}

function getIOSignalsTableData(signalList) {
  let dataList = [];

  for (let { key, name, color } of SIGNAL_OPTIONS.filter(it => it.key !== SIG.key)) {
    let obj = {
      key,
      name,
      color
    }

    const findItem = signalList.find(item => !!item[key]);
    if (!findItem) {
      continue;
    }
    for (let item of signalList) {
      obj[item.sig] = item[key];
    }
    dataList.push(obj);
  }
  return dataList;
}

export {
  getSpiceCardTableData,
  MERGE_VALUE_LIST,
  getSpiceCardTableColumns,
  getTableScrollWidth,
  EMPTY_VALUE_DISABLED_LIST,
  getSpiceCardData,
  getIOSignalsTableData
}