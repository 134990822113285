import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Input } from 'antd';
import SignalsNetsTable from './SignalsNetsTable';
import PowerNetsTable from './PowerNetsTable';
import ComponentTable from './ComponentTable';
import ChannelSelect from './ChannelSelect';
import Advanced from './advance';
import { saveComponentsNetsInProject, refreshPCB } from '../../store/project/action';
import DesignInfo from '@/services/Andes/pcbInfo';
import { changeSerdesType, changeDataRate } from '../../store/andes/action';
import '../index.css';

const Option = Select.Option;
const typeOptions = ['General', 'PCIe'];
class Setup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      below: false,
      PCBNets: {},
      dataRate: ''
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible, Interfaces } = this.props;
    this.bottomRef = document.getElementsByClassName('andes-content-bottom')[0];

    if (this.bottomRef) {
      let width = this.bottomRef.offsetWidth;
      if (width < 1000 || tabVisible) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }

    this.leftRef = document.getElementsByClassName('andes-content-left')[0];

    if (this.leftRef) {
      let width = this.leftRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }

    if (!this.state.current && Interfaces.length > 0) {
      this.setState({
        current: Interfaces[0].pcb
      })
    }
    const { datarate } = this.props;
    if (!this.state.dataRate) {
      this.setState({
        dataRate: datarate
      })
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const { tabVisible } = this.props;
    this.bottomRef = document.getElementsByClassName('andes-content-bottom')[0];
    this.leftRef = document.getElementsByClassName('andes-content-left')[0];
    this.mainRef = document.getElementsByClassName('aurora-content')[0];
    if (this.bottomRef) {
      let width = this.bottomRef.offsetWidth;
      if (width < 1000 || tabVisible) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    } else if (this.leftRef) {
      let width = this.leftRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    } else {
      let width = this.mainRef.offsetWidth;
      if (width < 1000) {
        this.setState({
          below: true
        });
      } else {
        this.setState({
          below: false
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { tabVisible, andesResize, Interfaces, designId } = this.props;
    if (designId && designId !== prevProps.designId) {
      this.loadPCB(designId);
    };
    this.bottomRef = document.getElementsByClassName('andes-content-bottom')[0];
    if (this.bottomRef && prevProps.tabVisible !== tabVisible) {
      let width = this.bottomRef.offsetWidth, below = false;
      if (width < 1000 || tabVisible) {
        below = true;
      }
      this.setState({
        below
      });
    }

    if (andesResize !== prevProps.andesResize) {
      this.contentRef = document.getElementById('andes-content-main');
      let width = this.contentRef.offsetWidth, below = false;
      if (width < 1000) {
        below = true;
      }
      this.setState({
        below
      });
    }

    if (!Interfaces.length) {
      this.setState({
        current: ""
      });
    } else if (!prevProps.Interfaces.length || Interfaces[0].interfaceId !== prevProps.Interfaces[0].interfaceId) {
      this.setState({
        current: Interfaces[0].pcb
      })
    }

    if (Interfaces.length && !this.state.current) {
      this.setState({
        current: Interfaces[0].pcb
      })
    }

    const { currentPCBs, pcbComponentsNets } = this.props;
    const { refreshStatus } = this.props;
    const { PCBNets } = this.state;
    if (currentPCBs.length > 0) {
      currentPCBs.forEach(pcbId => {
        if (!PCBNets[pcbId] && pcbComponentsNets.includes(pcbId)) {
          const info = DesignInfo.getPCBInfo(pcbId);
          if (info && info.netsList) {
            const netsList = DesignInfo[pcbId].netsList;
            this.setState((prevState) => {
              prevState.PCBNets[pcbId] = netsList;
              return {
                PCBNets: prevState.PCBNets
              }
            });
          }
        } else if (refreshStatus && refreshStatus !== prevProps.refreshStatus && pcbComponentsNets.includes(pcbId)) {
          const info = DesignInfo.getPCBInfo(pcbId);
          if (info && info.netsList) {
            const netsList = DesignInfo[pcbId].netsList;
            this.setState((prevState) => {
              prevState.PCBNets[pcbId] = netsList;
              return {
                PCBNets: prevState.PCBNets
              }
            });
          }
          this.props.refreshPCB(false)
        }
      })
    } else if (pcbComponentsNets && pcbComponentsNets.length > 0) {
      pcbComponentsNets.forEach(pcbId => {
        if (!PCBNets[pcbId]) {
          const info = DesignInfo.getPCBInfo(pcbId);
          if (info && info.netsList) {
            const netsList = DesignInfo[pcbId].netsList;
            this.setState((prevState) => {
              prevState.PCBNets[pcbId] = netsList;
              return {
                PCBNets: prevState.PCBNets
              }
            });
          }
        } else if (refreshStatus && refreshStatus !== prevProps.refreshStatus) {
          const info = DesignInfo.getPCBInfo(pcbId);
          if (info && info.netsList) {
            const netsList = DesignInfo[pcbId].netsList;
            this.setState((prevState) => {
              prevState.PCBNets[pcbId] = netsList;
              return {
                PCBNets: prevState.PCBNets
              }
            });
          }
          this.props.refreshPCB(false)
        }
      })
    }

    const { datarate, openVerificationId } = this.props;
    if (prevProps.openVerificationId !== openVerificationId) {
      this.setState({
        dataRate: datarate
      });
    }
  }

  loadPCB = (pcbId) => {
    this.props.saveComponentsNetsInProject([pcbId]);
  };

  onTypeChange = (value) => {
    this.props.changeSerdesType({ serdesType: value });
  }

  onDataRateChange = (e) => {
    this.setState({
      dataRate: e.target.value
    })
  }

  saveDataRate = (e) => {
    const { dataRate } = this.state;
    if (dataRate !== this.props.datarate) {
      this.props.changeDataRate({ dataRate: dataRate });
    }
  }

  render() {
    const { extraction, serdesType } = this.props;
    const { below, current, PCBNets, dataRate } = this.state;
    return (
      <div className='andes-content-setting'>
        <Row>
          <Col span={24}>
            <div className='margin-top-20'>
              <span className="font-bold">Serdes Type</span>
              <Select
                placeholder="Type"
                className='andes-type-select'
                value={serdesType}
                disabled
                onChange={(value) => this.onTypeChange(value)}
                popupMatchSelectWidth={false}
                getPopupContainer={() => document.getElementById('andes-content-main')}
              >
                {typeOptions.map((option, i) => <Option value={option} key={i} title={option}>{option}</Option>)}
              </Select>
              <span className="font-bold">Data Rate</span>
              <Input
                placeholder="Data Rate"
                className='andes-type-input'
                value={dataRate}
                onChange={(e) => this.onDataRateChange(e)}
                onBlur={(e) => this.saveDataRate(e)}
                onPressEnter={(e) => this.saveDataRate(e)}
              />
              <span>Gbps</span>
            </div>
          </Col>
        </Row>
        {below ?
          <Fragment>
            <Row className='margin-top-20'>
              <Col span={24}>
                <SignalsNetsTable below={below} PCBNets={PCBNets} />
              </Col>
            </Row>
            <Row className='margin-top-20'>
              <Col span={24}>
                <span className="font-bold">Reference Nets</span>
                {/* <Icon type="plus-circle" className='signal-add-icon' onClick={this.powerGndNets} /> */}
                <PowerNetsTable below={below} PCBNets={PCBNets} />
              </Col>
            </Row>
          </Fragment>
          : <Row className='margin-top-20'>
            <Col span={14}>
              <SignalsNetsTable below={below} PCBNets={PCBNets} />
            </Col>
            <Col span={10}>
              <span className="font-bold" style={{ marginLeft: 20 }}>Reference Nets</span>
              {/*<Icon type="plus-circle" className='signal-add-icon' onClick={this.powerGndNets} /> */}
              <PowerNetsTable below={below} PCBNets={PCBNets} />
            </Col>
          </Row>}
        <Row className='margin-top-20'>
          <Col span={24}>
            <span className="font-bold">Components</span>
            <ComponentTable current={current} />
          </Col>
        </Row>
        <Row className='margin-top-20'>
          <Col span={24}>
            <span className="font-bold">Electromagnetic Modeling</span>
            <ChannelSelect current={current} />
          </Col>
        </Row>
        <Row className='margin-top-20'>
          <Col span={24}>
            <Advanced saveExtraction={this.props.saveExtraction} extraction={extraction} />
          </Col>
        </Row>
        <div id='andes-model-dialog'></div>
      </div>

    )
  }
}

const mapState = (state) => {
  const { AndesReducer: { andes, project: { verificationId, currentProjectDesigns, pcbComponentsNets, refreshStatus } } } = state;
  const { tabVisible } = state.TabMonitorReducer;
  const andesInfo = andes.andesInfo;
  let channel = {}, Interfaces = [], extraction = null, designId = null, currentPCBs = [], serdesType = 'General', datarate = '', openVerificationId = null;
  if (andesInfo) {
    if (andesInfo.info) {
      channel = andesInfo.info.channel;
      serdesType = andesInfo.info.info.type;
      datarate = andesInfo.info.info.datarate;
    };
    Interfaces = andesInfo.Interfaces;
    //TODO re_extraction
    /*extraction = andesInfo.Interfaces.length && andesInfo.Interfaces[0].content.extraction; */
    currentPCBs = andesInfo.Interfaces && andesInfo.Interfaces.map(item => item.pcbId);
    openVerificationId = andesInfo.verificationId;
  }
  if (currentProjectDesigns && currentProjectDesigns.length > 0) {
    designId = currentProjectDesigns[0].id;
  }
  return {
    channel,
    tabVisible,
    Interfaces,
    verificationId,
    extraction,
    designId,
    pcbComponentsNets,
    currentPCBs,
    serdesType,
    datarate,
    refreshStatus,
    openVerificationId
  };
}

const mapDispatch = (dispatch) => ({
  saveComponentsNetsInProject(pcbIds) {
    dispatch(saveComponentsNetsInProject(pcbIds))
  },
  changeSerdesType({ serdesType }) {
    dispatch(changeSerdesType({ serdesType }))
  },
  changeDataRate({ dataRate }) {
    dispatch(changeDataRate({ dataRate }))
  },
  refreshPCB(refreshStatus) {
    dispatch(refreshPCB(refreshStatus))
  }
})

export default connect(mapState, mapDispatch)(Setup);
