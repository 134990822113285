import React, { Component, Fragment } from "react";
import { Radio, Switch, Input } from 'antd';
import PreviewBtn from "./previewBtn";
import './style.css';
import { numberCheck } from "../../services/helper/dataProcess";

class ClipDesignPanel extends Component {
  constructor() {
    super()
    this.state = {
      clipping: null,
      clipSizeUnit: null,
      clipSize: null,
      clipSizeInput: null
    }
  }

  previewBtnOnRef = (ref) => {
    this.previewBtnChild = ref;
  }

  componentDidMount() {
    const { clipping, clipSizeUnit, clipSize, useDesignClip, forcePreviewShow } = this.props;
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.setState({
      clipping,
      clipSizeUnit,
      clipSize,
      useDesignClip,
      clipSizeInput: clipSize
    })

    this.previewBtnChild.setPreviewShowState(forcePreviewShow);
  }

  componentDidUpdate(prevProps, prevState) {
    const { clipping, clipSizeUnit, clipSize, useDesignClip, forcePreviewShow, isShowHybridPreview, hybrid } = this.props;
    if (clipping !== null && clipSizeUnit !== prevProps.clipSizeUnit) {
      this.setState({
        clipping: clipping
      })
    }
    if (clipSizeUnit !== null && clipSizeUnit !== prevProps.clipSizeUnit) {
      this.setState({
        clipSizeUnit: clipSizeUnit
      })
    }

    if (clipSize !== null && clipSize !== prevProps.clipSize) {
      this.setState({
        clipSize: clipSize,
        clipSizeInput: clipSize
      })
    }
    if (useDesignClip !== null && useDesignClip !== prevProps.useDesignClip) {
      this.setState({
        useDesignClip: useDesignClip
      })
    }
    //switch channel , close panel
    if (this.props.channelId !== prevProps.channelId) {
      this.props.closePanel();
    }

    if (!prevProps.forcePreviewShow && forcePreviewShow) {
      this.previewBtnChild.setPreviewBtnLoadState(false)
      this.previewBtnChild.setPreviewShowState(forcePreviewShow)
    }

    //hybrid update
    let _clipSize_ = clipSize;
    if (hybrid && hybrid !== prevProps.hybrid && (!prevState.useDesignClip || !prevState.clipping)) {
      const { clipSizeUnit, clipSize } = this.state;
      let _clipSize = `${clipSize}${clipSizeUnit}`;
      if (clipSizeUnit === "ratio") {
        _clipSize = "3mm";
        _clipSize_ = 3;
        this.setState({ clipSize: "3", clipSizeUnit: "mm", useDesignClip: true, clipping: true, clipSizeInput: "3" });
      } else {
        _clipSize_ = clipSize;
        this.setState({ useDesignClip: true, clipping: true })
      }
      this.props.saveOptions({
        useDesignClip: true,
        clipSize: _clipSize,
        clipping: true,
        clipSizeInput: _clipSize
      })
    }

    if (!prevProps.isShowHybridPreview && isShowHybridPreview) {
      this.previewBtnChild.setPreviewBtnLoadState(true)
      this.props.showPreview(true, { clipSize: _clipSize_, showHybrid: true });
    }
  }

  changeClip = (checked) => {
    this.setState({
      clipping: checked,
    })
    this.previewBtnChild.setPreviewShowState(!checked ? false : this.previewBtnChild.state.previewShow);
    if (!checked) {
      this.props.showPreview(false);
    }
  }

  changeClipSizeRadio = (e) => {
    const value = e.target.value;
    const clipSize = value === 'mm' ? '3' : '0.3';
    this.setState({
      clipSizeUnit: value,
      clipSize,
      clipSizeInput: clipSize
    })
  }

  changeClipType = (e) => {
    const { clipSizeUnit, clipSize } = this.state;
    const _clipSize = clipSizeUnit === 'ratio' ? '3' : clipSize;
    this.setState({
      useDesignClip: e.target.value,
      clipSizeUnit: 'mm',
      clipSize: _clipSize,
      clipSizeInput: _clipSize
    })
    this.previewBtnChild.setPreviewShowState(false);
    if (!e.target.value) {
      this.props.showPreview(false);
    }
  }

  changeConfigValue = (e) => {
    this.setState({
      clipSizeInput: e.target.value
    })
  }

  closeModal = () => {
    this.saveOptions();
    this.props.closePanel();
  }

  changeClipValue = (value) => {
    if (value) {
      this.setState({
        ...value
      }, () => {
        this.saveOptions(true)
      })
    }
  }

  saveOptions = (saveToServer) => {
    const { clipping, clipSizeUnit, clipSize, useDesignClip } = this.state;
    this.props.saveOptions({
      clipping,
      clipSize: clipSizeUnit === 'mm' ? clipSize + clipSizeUnit : clipSize,
      useDesignClip
    }, saveToServer);
  }

  // saveToServer - true/false
  saveAndShow = (saveToServer) => {
    this.saveOptions(saveToServer);
    const { clipSize } = this.state;
    if (this.previewBtnChild.state.previewShow) {
      this.props.showPreview(true, { clipSize, showHybrid: this.props.hybrid });
    } else {
      this.props.showPreview(false);
    }
  }

  inputBlur = () => {
    const { clipSize, clipSizeInput } = this.state;
    if (numberCheck(clipSizeInput) || parseFloat(clipSizeInput) < 0) {
      this.setState({
        clipSizeInput: clipSize
      })
      return;
    }
    this.setState({
      clipSize: clipSizeInput
    }, () => {
      this.saveAndShow(false);
    })
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  updatePreviewButtonStatus = (load = false, show = false) => {
    if (this.previewBtnChild) {
      this.previewBtnChild.setPreviewBtnLoadState(load)
      this.previewBtnChild.setPreviewShowState(show)
    }
  }

  render() {
    const { hybrid, disabledPreview } = this.props;
    const { clipping, clipSizeUnit, useDesignClip, clipSizeInput } = this.state;
    return <Fragment>
      <div className='clip-design-panel-content clear'>
        <span className='clip-title'>Clip design</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          checked={clipping}
          disabled={hybrid}
          onChange={this.changeClip}
        />
        <Radio.Group
          className='radio-clip-type-group'
          value={useDesignClip}
          onChange={this.changeClipType}
          disabled={!clipping}
        >
          <Radio
            value={true}
            className='extraction-clip-radio-item'
          >
            Built-in</Radio>
          <Radio
            value={false}
            disabled={hybrid}
            className='extraction-clip-radio-item'
          >
            Ansys</Radio>
        </Radio.Group>
        <Radio.Group
          onChange={this.changeClipSizeRadio}
          value={clipSizeUnit}
          className='radio-clip-group'
          disabled={!clipping}
        >
          <Radio
            value={'mm'}
            className='extraction-clip-radio-item'
          >
            Distance</Radio>
          <Radio
            disabled={useDesignClip || hybrid}
            value={'ratio'}
            className='extraction-clip-radio-item'
          >
            Ratio</Radio>
        </Radio.Group>
        <Input
          value={clipSizeInput}
          className='extraction-input clip-design-input'
          onChange={(e) => this.changeConfigValue(e)}
          onBlur={this.inputBlur}
          onKeyDown={e => this.handleKeyDown(e)}
          disabled={!clipping}
          addonAfter={clipSizeUnit === "mm" ? "mm" : null}
        />
        <PreviewBtn
          disabled={!useDesignClip || !clipping || disabledPreview}
          disabledPreview={disabledPreview}
          savePreviewStatus={this.props.savePreviewStatus}
          saveAndShow={this.saveAndShow}
          onRef={this.previewBtnOnRef}
        />
      </div>
    </Fragment>
  }
}

export default ClipDesignPanel;