import CompRLCPrefixLib from '../helper/componentsHelper/componentRLCPrefix';
import { ExtractionOptions } from './extractionOptions';
import { OptOptions } from './optimization';

export class IntegratedInterface {
  constructor(name = '') {
    this.Name = name;
    this.PowerNets = [];
    this.ReferenceNets = [];
    this.Components = []; // RLComponent
    this.VRM = []; // [{vrm1},{vrm2}...]
    this.Config = new ExtractionOptions({});
    this.Optimization = new OptOptions();
    // this.BGAConnName = "";
    this.COMP_PREFIX_LIB = new CompRLCPrefixLib(); // 0.1.6 custom component reference designator
    this.BGAConnName = ""; // 0.1.5
  };
};

/*******************************************/
/*                 VRM                     */
/*******************************************/
export class VRM {
  constructor() {
    this.powerPin = [];
    this.groundPin = []; // [Pin]
    this.model = {
      name: "",
      id: ""
    };
    this.voltage = "";
  };
};

export class Pin {
  constructor({ comp = '', pin = '' }) {
    this.comp = comp;
    this.pin = pin;
  };
};


/*******************************************/
/*               Component                 */
/*******************************************/
export class RLComponent {
  constructor({ usage = '', part = '', name = '', pins = [], value = '' }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins;
    this.value = value;
    this.COMP_TYPE = usage;
  };
};

export class CapComponent {
  constructor({ usage = '', part = '', name = '', pins = [] }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins; // [{ pin, net }]
    this.value = {}; // { r, l, c }
    this.model = {}; // { Model }
    this.COMP_TYPE = usage;
    this.models = [];
  };
};



export class BasicComponent {
  constructor({ usage = '', part = '', name = '', pins = [] }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins;
    this.COMP_TYPE = usage;
  };
};

export class ChipComponent {
  constructor({ usage = '', part = '', name = '', pins = [] }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins;
    this.pkg = {
      id: "",
      name: "",
      type: ""
    }
    this.COMP_TYPE = usage;
  }
}

export class Model {
  constructor({ type = '', name = '', subcktName = '' }) {
    this.id = "";
    this.type = type;
    this.name = name;
    this.subcktName = subcktName;
    this.libraryType = "";
  };
};

export function SimConfig(layer) {
  this.CPU_TOP = layer;
  this.AC = 1;
  this.DC = 1;
}