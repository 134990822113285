import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import DcPlot from './dcPlot';
import './index.css';

class DcPreLayout extends Component {

  render() {
    return <div className='cascade-pre-layout-body'>
      <Row>
        <Col span={24} className='cascade-pre-layout-setup-border'>
          <DcPlot/>
        </Col>
      </Row>
    </div>
  }

}

const mapState = (state) => {
  const { CascadeReducer: { prelayout } } = state;
  return {
  }
}

const mapDispatch = (dispatch) => ({})

export default connect(mapState, mapDispatch)(DcPreLayout);