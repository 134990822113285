class CardChannelsConstructor {
  constructor() {
    this.cardChannelList = new Map();// designId -> cardChannels [{ id, name, ... }]
    this.channelVerificationList = new Map(); // channelId -> verifications [{ id, name, ... }]
    this.channelsInfo = new Map(); // channelId -> { id, name, ... }
    this.verifications = new Map();
  }

  setChannelInfo = (channelId, channelInfo) => {
    this.channelsInfo.set(channelId, channelInfo);
  }

  setCardChannels = (designId, cardChannels) => {
    this.cardChannelList.set(designId, cardChannels);
  }

  setChannelVerifications = (channelId, verifications) => {
    this.channelVerificationList.set(channelId, verifications);
    for (let ver of verifications) {
      this.verifications.set(ver.id, ver);
    }
  }

  getChannels = (cardDesignId) => {
    return this.cardChannelList.get(cardDesignId) || [];
  }

  getChannelIds = (cardDesignId) => {
    return (this.cardChannelList.get(cardDesignId) || []).map(item => item.id);
  }

  getVerificationsByDesignId = (designId) => {
    let list = [];
    for (let item of (this.cardChannelList.get(designId) || [])) {
      const _verificationList = this.channelVerificationList.get(item.id) || [];
      list = [...list, ..._verificationList];
    }
    return list;
  }

  getVerifications = (channelId) => {
    return this.channelVerificationList.get(channelId) || [];
  }

  getChannel = (channelId) => {
    return this.channelsInfo.get(channelId);
  }

  getVerification = (verificationId) => {
    return this.verifications.get(verificationId);
  }

  clearCardCache = () => {
    this.cardChannelList = new Map();
    this.channelVerificationList = new Map();
    this.channelsInfo = new Map();
    this.verifications = new Map();
  }

  delCard = (designId) => {
    const channels = this.cardChannelList.get(designId) || [];
    for (let channel of channels) {
      this.channelsInfo.delete(channel.id);
      this.channelVerificationList.delete(channel.id);
    }
    for (let key of Array.from(this.verifications.keys() || [])) {
      const verification = this.verifications.get(key);
      if (verification && verification.designId === designId) {
        this.verifications.delete(key);
      }
    }
    this.cardChannelList.delete(designId);
  }
}

let cardChannelsConstructor = new CardChannelsConstructor();
export default cardChannelsConstructor;