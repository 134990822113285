import { getVerificationContentPromise } from '../verificationCtrl'

class VerifySetup {

  constructor(type) {
    this.type = type;
    this.id = window.location.search.split('=')[1];
    this.verifications = []; // [{ verificationId, pcb }]
    this.verificationsInfo = []; // [{ Config, Explores, Interfaces: [{ content:{}, name, pcb }] }]
  }

  getDesignsByVerificationId = (verificationId) => {
    let designs = [];
    const _Interfaces = this.verificationsInfo.length > 0 ? this.verificationsInfo.find(item => item.VerificationID === verificationId) : null;
    if (_Interfaces) {
      designs = _Interfaces.Interfaces.map(item => ({ pcb: item.pcb, pcbId: item.pcbId }));
    }
    return designs;
  }

  // Get the verification content by verification id.
  getVerificationContent = (verificationId) => {
    return new Promise((resolve, reject) => {
      const content = this.exsitInterface(verificationId);
      if (content) {
        resolve(content);
      } else {
        getVerificationContentPromise(verificationId).then(res => {
          const newVerification = { ...res };
          this.verificationsInfo.push(newVerification);
          const arr = res.Interfaces.map(item => item.pcb)
            .map(pcb => ({ pcb, verificationId }));
          this.verifications.push(...arr)
          resolve(newVerification);
        }, error => {
          resolve(null);
        });
      };
    })
  }

  saveOption = (type, value, verificationId) => {
    if (this.verificationsInfo.length > 0) {
      const _index = this.verificationsInfo.findIndex(info => info.VerificationID === verificationId);
      if (_index > -1) {
        if (this.verificationsInfo[_index].Config) {
          this.verificationsInfo[_index].Config[type] = value;
        } else {
          this.verificationsInfo[_index].Config = { [type]: value }
        }
      }
    }
  }

  exsitInterface = (verificationId) => {
    const verifications = this.verificationsInfo.length > 0 ? this.verificationsInfo.find(item => item.VerificationID === verificationId) : null;
    return verifications;
  }

  getInterfaceContent = (verificationId, pcb) => {
    const info = this.exsitInterface(verificationId);
    if (info) {
      const index = info.Interfaces.findIndex(item => item.pcb === pcb);
      return info.Interfaces[index];
    };
    return;
  }

  /**
   * Save edited value
   *
   * @param {String} verificationId
   * @param {*} value
   * @param {String} type - components, models, signals, sourceNets
   * @param {String} designName - PCB name
   */
  saveValue = (verificationId, value, type, designName) => {
    let info = this.exsitInterface(verificationId);
    const index = info.Interfaces.findIndex(item => item.pcb === designName);
    info.Interfaces[index].content[type] = value;
  }

  clearCache = () => {
    this.verifications = [];
    this.verificationsInfo = [];
  }

  cleanInterfaceInfo = (verificationId) => {
    const index = this.verificationsInfo.findIndex(info => info.VerificationID === verificationId);
    if (index > -1) {
      this.verificationsInfo.splice(index, 1);
    }
    this.verifications = [];
  }
}

// - basicData: Object Array; - rules: Array ;
export function SortFn(basicData, SORT_RULES, attr) {
  let arr = [], newData = [];
  SORT_RULES.forEach(rule => arr[rule] = []);
  arr['NA'] = []; // Undefined
  basicData.forEach(basic => SORT_RULES.includes(basic[attr]) ? arr[basic[attr]].push(basic) : arr['NA'].push(basic));
  newData.push(...arr['NA']);
  SORT_RULES.forEach(item => newData.push(...arr[item]));
  return newData;
}

export default VerifySetup;