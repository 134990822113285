import React from "react";
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import "./index.css";

function RelatedNetsRender({
  form,
  record,
  selectedNets,
  type,
  relatedShow,
  selectNets,
  getNetsOptions,
  relatedShowChange,
  clearInputValue
}) {
  const { relatedNets, nets } = getNetsOptions(record, type);
  return (
    <div className='related-nets-select-div'
      onMouseDown={(e) => {
        e.preventDefault()
        return false
      }}
    >
      <span className='related-nets-select-span'>Nets:</span>
      <ul className='related-nets-select-ul-1'>
        {nets.map(item => <li
          className={selectedNets.includes(item) ? `related-nets-select-li related-nets-selected` : 'related-nets-select-li'}
          key={item}
          onClick={(e) => { clearInputValue(); selectNets({ e, record, value: item, type, form }) }}
        >{item}
        </li>)}
      </ul>
      {relatedNets.length > 0 && <span
        className='related-nets-select-span'
        style={{ marginBottom: !relatedShow ? 8 : 0 }}
        onClick={(e) => relatedShowChange(e)}>
        {relatedShow ? <DownOutlined className="related-nets-expand-icon" /> : <RightOutlined className="related-nets-expand-icon" />}
        Connected Nets:
      </span>}
      {relatedShow && <ul className='related-nets-select-ul-2'>
        {relatedNets.map(item => <li
          className={selectedNets.includes(item) ? `related-nets-select-li related-nets-selected` : 'related-nets-select-li'}
          onClick={(e) => { clearInputValue(); selectNets({ e, record, value: item, type, form }) }}
          key={item}>{item}
        </li>)}
      </ul>}
    </div>
  );
}

export default RelatedNetsRender;