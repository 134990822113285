import http from '../http';
import JSZip from 'jszip';
import { getTranslationMode } from '../cookies';
import apiProcess from '../utility';
import { ANSYS, ANSYS_EDB_FLOW } from '../../../constants/designVendor';

// folder and project operation
function createFolder({ id, name }) {
  return http.post('/my-project/folders', { id, name });
}

function createProject({ id, name }) {
  return http.post('/my-project/projects', { id, name });
}

function getFolderItems({ id, page, size }) {
  return http.get(`/my-project/folders/${id}`, {
    params: { page, size }
  })
}

function deleteFoldersProjects(ids) {
  return http.delete('/my-project', { data: { ids } });
}

function renameFolderProject({ id, name }) {
  return http.put('/my-project/rename', { id, name })
}

// design operation
function getDesignsInProject(id) {
  return http.get(`/my-project/project/${id}`);
}

function deleteDesigns({ ids, isNew }) {
  return http.delete('/my-project/designs', { data: { ids, isNew } });
}

function renameDesign({ id, name }) {
  return http.put('/my-project/designs/rename', { id, name });
}

function getDesignInfo(id) {
  return http.get(`/my-project/designs/${id}`)
}

function upload({ file, name, projectId, type, vendor, config, isNew }) {
  //true ('OFF') ==> pcb_processing, false ('ON') ==> translation
  const _vendor = getVendor(vendor);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('projectId', projectId);
  formData.append('type', type);
  formData.append('vendor', _vendor);
  formData.append('isNew', isNew);
  formData.append('transSwitch', getTransSwitch(vendor));
  return http.post('/my-design/upload', formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }

  })
}

//trash operation
function getFilesInTrash({ page, size }) {
  return http.get('/trash', {
    params: { page, size }
  })
}

function restoreFilesFromTrash(data) {
  return http.put('/my-project/restore', data)
}

export function cleanupTrash() {
  return http.delete('/trash')
}

export function getTransSwitch(vendor) {
  //true ==> pcb_processing //false ==> translation
  if (vendor === ANSYS_EDB_FLOW) {
    return 'true';
  } else if (vendor === ANSYS) {
    return 'false';
  } else {
    return getTranslationMode() === 'ON' ? 'false' : 'true';
  }
}

export function getVendor(vendor) {
  if (vendor === ANSYS_EDB_FLOW) {
    return ANSYS;
  } else {
    return vendor;
  }
}

function updateDesign({ designId, file, name, projectId, type, vendor, config, isNew = '' }) {
  const _vendor = getVendor(vendor);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('projectId', projectId);
  formData.append('type', type);
  formData.append('vendor', _vendor);
  formData.append('isNew', isNew);
  formData.append('transSwitch', getTransSwitch(vendor));
  return http.post(`/my-design/${designId}/update`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

function replaceDesign({ designId, file, name, projectId, type, vendor, config, isNew = '' }) {
  const _vendor = getVendor(vendor);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('projectId', projectId);
  formData.append('type', type);
  formData.append('vendor', _vendor);
  formData.append('isNew', isNew);
  formData.append('transSwitch', getTransSwitch(vendor));
  return http.post(`/my-project/my-design/${designId}/replace`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
};

export function getUnitFromLayoutDB(designId) {
  return http.get(`/my-project/design/${designId}/unit`);
}

/**
 * andes v2 upload pcb 
 * */
export function uploadPCB({ files, isZip, name, projectId, type, vendor, metadata, config, isNew = "true" }) {
  return designFormData({
    files,
    isZip,
    name,
    projectId,
    type,
    vendor,
    metadata,
    config,
    isNew,
    path: "/my-design/upload"
  })
}

/**
 * andes v2, rocky replace pcb 
 * */
export function replacePCB({ files, designId, isZip, name, projectId, type, vendor, metadata, config, isNew = "true" }) {
  return designFormData({
    files,
    isZip,
    name,
    projectId,
    type,
    vendor,
    metadata,
    config,
    isNew,
    path: `/my-project/my-design/${designId}/replace`
  })
}

function designFormData({ files, isZip, name, projectId, type, vendor, config, metadata, path, isNew = "true" }) {
  //true ==> pcb_processing //false ==> translation
  const _vendor = getVendor(vendor);
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    if (isZip) {
      for (const file of files) {
        formData.append('file', file);
      }
      formData.append('name', name);
      formData.append('projectId', projectId);
      formData.append('type', type);
      formData.append('vendor', _vendor);
      formData.append('isNew', isNew);
      formData.append('transSwitch', getTransSwitch(vendor));
      resolve(http.post(path, formData, config, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }))
    } else {
      let zip = new JSZip();
      let fileObj;
      if (files.length === 1) {
        zip.file(files[0].name, files[0]);
      } else {
        for (const file of files) {
          zip.file(file.webkitRelativePath, file);
        }
      }
      zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 }
      }, metadata).then(blob => {
        fileObj = new File([blob], "design.zip");
        formData.append('file', fileObj);
        formData.append('name', name);
        formData.append('projectId', projectId);
        formData.append('type', type);
        formData.append('vendor', _vendor);
        formData.append('isNew', isNew);
        formData.append('transSwitch', getTransSwitch(vendor));
        resolve(http.post(path, formData, config, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }))
      })
    }
  })
}

function getDesignCriteria(designId) {
  return http.get(`/my-project/design/search-criteria?designId=${designId}`)
}

/* {
  "designId": "string",
  "exclude": [
    "string"
  ]
} */
function updateDesignCriteria({ designId, config }) {
  return http.post(`/my-project/design/search-criteria`, {
    designId,
    ...config
  })
}

//--- export
export function createFolderPromise(id, name) {
  return apiProcess(createFolder, { id, name });
}

export function createProjectPromise(id, name) {
  return apiProcess(createProject, { id, name })
}

export function getFolderItemsPromise(id, page = 0, size = 10) {
  return apiProcess(getFolderItems, { id, page, size })
}

export function deleteFolders_Projects(ids) {
  return apiProcess(deleteFoldersProjects, ids)
}

export function renameFolder_Project(id, name) {
  return apiProcess(renameFolderProject, { id, name })
}

export function getDesignsInProjectPromise(id) {
  return apiProcess(getDesignsInProject, id)
}

export function deleteDesignsPromise(ids, isNew) {
  return apiProcess(deleteDesigns, { ids, isNew })
}

export function renameDesignPromise(id, name) {
  return apiProcess(renameDesign, { id, name })
}

export function getDesignInfoPromise(id) {
  return apiProcess(getDesignInfo, id)
}

export function uploadPromise({ file, name, projectId, type, vendor, config, isNew = '' }) {
  return apiProcess(upload, { file, name, projectId, type, vendor, config, isNew })
}

export function getFilesInTrashPromise(page = 0, size = 10) {
  return apiProcess(getFilesInTrash, { page, size })
}

export function restoreFilesFromTrashPromise(data) {
  return apiProcess(restoreFilesFromTrash, data)
}

export function updateDesignPromise(params) {
  return apiProcess(updateDesign, params)
}

export function replaceDesignPromise(params) {
  return apiProcess(replaceDesign, params)
}

export function replacePCBPromise(params) {
  return apiProcess(replacePCB, params)
}
/**
 *  get design translation log
 * 2021/06/04
 * @param {String} designId design id
 *  */
export function getDesignTranslationLog(designId) {
  return http.get(`/my-project/my-design/trans/log?designId=${designId}`);
}

export function getPMICList(designId) {
  return http.get(`/pmic/part?designId=${designId}`);
}

export function getPMICPartPin() {
  return http.get(`/pmic/pin/map`);
}

function cleanupDesign({ designId, snap }) {
  return http.post(`/my-project/design/zone/clean?designId=${designId}&snap=${snap}`)
}

export function cleanupDesignPromise({ designId, snap }) {
  return apiProcess(cleanupDesign, { designId, snap })
}

export function getPadstack(designId) {
  return http.get(`/my-project/design/padstack?designId=${designId}`)
}

export function setPadstack({ designId, stackObj }) {
  return http.post(`/my-project/design/update/padstack/${designId}`, {
    ...stackObj
  })
}

export function getDesignSearchCriteria(designId) {
  return apiProcess(getDesignCriteria, designId);
}

export function updateDesignSearchCriteria(designId, config) {
  return apiProcess(updateDesignCriteria, { designId, config });
}

/**
 * save package design wire bond profile
 * @exports
 * @param {string} designId package design ID
 * @param {string} libraryId selected wire bond profile library id
 * @return
 *  */
function savePkgWireBondProfile({ designId, libraryId }) {
  return http.post(`/my-project/design/wire-bond`, { designId, libraryId });
}


/**
 * get package design wire bond profile
 * @exports
 * @param {string} designId package design ID
 * @return
 *  */
function getPkgWireBondProfile(designId) {
  return http.get(`/my-project/design/wire-bond?designId=${designId}`);
}

/**
 * save package design wire bond profile
 * @exports
 * @param {string} designId package design ID
 * @param {string} libraryId selected wire bond prbuzofile library id
 * @return
 *  */
export function savePackageWireBondProfile({ designId, libraryId }) {
  return apiProcess(savePkgWireBondProfile, { designId, libraryId });
}

/**
 * get package design wire bond profile
 * @exports
 * @param {string} designId package design ID
 * @return
 *  */
export function getPackageWireBondProfile(designId) {
  return apiProcess(getPkgWireBondProfile, designId);
}

// layout check
function checkProjecyLayout(id) {
  return http.post(`/my-project/design/layout/check`, { id })
}

function getLayoutWorkflow(designId) {
  return http.get(`/my-project/design/layout/check/last/workflow?designId=${designId}`)
}

function getLayoutLog(designId) {
  return http.get(`/my-project/design/layout/check/log?designId=${designId}`)
}

function getLayoutResult(designId) {
  return http.get(`/my-project/design/layout/check/result?designId=${designId}`)
}

export function layoutSimulation(id) {
  return apiProcess(checkProjecyLayout, id)
}

export function getLayoutWorkflowInfo(designId) {
  return apiProcess(getLayoutWorkflow, designId)
}

export function getLayoutCheckLog(designId) {
  return apiProcess(getLayoutLog, designId)
}

export function getLayoutCheckResult(designId) {
  return apiProcess(getLayoutResult, designId)
}

// get parse auroraDB data
function getAuroraDBData(designId) {
  return http.get(`my-project/design/aurora-json?designId=${designId}`);
}

export function getAuroraPCBData(designId) {
  return apiProcess(getAuroraDBData, designId);
}