import {
  ADS_RX,
  ADS_TX,
  ERROR_BIT_RATE,
  NUMBER_OF_BIT,
  REGISTER_LENGTH,
  TAPS,
  SEED,
  BIT_SEQUENCE,
  RX_JITTERS,
  TX_JITTERS,
  TX_SJ_FREQUENCY,
  RX_NOISE,
  BIT_RATE,
  AMI_SIMULATION,
  BIT_BY_BIT,
  MAXIMAL_LENGTH_LFSR,
  USER_DEFINED_LFSR,
  USER_DEFINED_SEQUENCE,
  CORNER,
  RANGE,
  TX_PJ_FREQUENCY,
  CLOCK_BIT_RATE,
  TMDS_14,
  TMDS_2,
  JITTER_PDF_LIST,
  PRECURSOR_TAPS,
  POSTCURSOR_TAPS,
  NUMBER_OF_PRECURSOR_TAPS,
  NUMBER_OF_POSTCURSOR_TAPS,
  ZEROS,
  POLES,
  NUMBER_OF_POLES,
  NUMBER_OF_ZEROS,
  NUMBER_OF_TAPS,
  DC_GAIN,
  DE_EMPHASIS_VALUE,
  TAP_INTERVAL,
  TX_DCD,
  TX_CLOCK_DCD,
  SIGMA,
  ALLOW_VALUE_EMPTY_PARAMS,
  RX_SJ_AMPLITUDE,
  RX_AMPLITUDE_NOISE,
  IBIS_TX_JITTERS,
  IBIS_RX_JITTERS,
  TX_JITTER_PDF,
  RX_JITTER_PDF,
  DJRJ,
  DUAL_DIRAC,
  FIR_TAPS,
  DE_EMPHASIS_OPTIONS,
  DE_EMPHASIS,
  FIR_TAPS_OPTIONS,
  TX_PJ_AMPLITUDE,
  DFE_TYPE,
  FFE,
  CTLE,
  CTLE_TYPE,
  CTLE_CUSTOM_OPTIONS,
  FFE_TYPE,
  ADAPTIVE_EQUALIZATION,
  DFE_ADAPTIVE_EQUALIZATION,
  SLICER_OUTPUT,
  ENABLE_CTLE_OR_FFE,
  getFFEOptions,
  PRESET,
  AC_GAIN,
  getCtlePresetOptions,
  OPTIMIZED,
  MANUAL,
  getHDMIPresetOptions,
  DFETAPUSED,
  PROTOCOL,
  STRING,
  INTEGER,
  getSignalAmiCompBySignalName
} from ".";
import { GENERIC_TOUCHSTONE, IBIS, IBIS_AMI, SPICE } from "../../../constants/libraryConstants";
import { END_TO_END_CHANNEL } from "../../../constants/treeConstants";
import { numberCheck, unitConversion } from "../../helper/dataProcess";
import { SortFn } from "../../helper/sort";
import { SERDES_TYPES } from "../../PCBHelper";
import libraryConstructor from "../library/libraryConstructor";
import { AMISignalConfig, setDefaultAMIJitters } from "./IntegratedConfig";
import NP from 'number-precision';
import { scaleKeys } from "../../helper/constants";
import _ from 'lodash';
import { ELECTRICAL, JITTER, TX_EQ, WAVEFORM, wavefromLabelList, jitterLabelList, EQLabelList, electricalLabelList, pinModelLabelList, eyeProbeLabelList, EYEPROBE, ERROR_BIT_RATE_LIST } from "./constants";
import { CPHY } from "../../PCBHelper/constants";
import { getCPHYIBISModelList, getIbisModelParse } from "../library";

function AMIDisplaySignals({
  IbisHasAMI = "yes",
  signal,
  controller,
  device,
  txModel,
  rxModel,
  rcModel,
  aggressors = [],
  TXModelFileError,
  RXModelFileError,
  prbs,
  txCircuitModel,
  rxCircuitModel,
  TXCircuitModelFileError,
  RXCircuitModelFileError,
  cphyRxModel,
  cphyTxModel,
  serdesType
}) {
  this.signal = signal;
  this.controller = controller || new AMIDisplayComp({ serdesType });
  this.device = device || new AMIDisplayComp({ serdesType });

  if (IbisHasAMI === "yes") {
    this.txAmiModel = txModel || {};
    this.rxAmiModel = rxModel || {};
  }
  if (IbisHasAMI === "no") {
    this.txModel = txModel || {};
    this.rxModel = rxModel || {};
  }

  if (rcModel) {
    this.rcModel = rcModel || {};
  }

  if (txCircuitModel) {
    this.txCircuitModel = txCircuitModel || {};
  }

  if (rxCircuitModel) {
    this.rxCircuitModel = rxCircuitModel || {};
  }

  // CPHY model
  if (cphyTxModel) {
    this.cphyTxModel = cphyTxModel || {};
  }

  if (cphyRxModel) {
    this.cphyRxModel = cphyRxModel || {};
  }
  this.aggressors = aggressors || [];
  this.IbisHasAMI = IbisHasAMI || "yes";
  this.TXModelFileError = TXModelFileError;
  this.RXModelFileError = RXModelFileError;
  this.TXCircuitModelFileError = TXCircuitModelFileError;
  this.RXCircuitModelFileError = RXCircuitModelFileError;
  this.prbs = prbs
}

function AMIDisplayComp({ component, positive, negative, lineA, lineB, lineC, serdesType }) {
  this.component = component || "";

  const defaultInfo = { pin: "", net: "" };

  if (serdesType === CPHY) {
    this.lineA = lineA || { ...defaultInfo }
    this.lineB = lineB || { ...defaultInfo }
    this.lineC = lineC || { ...defaultInfo }
  } else {
    this.positive = positive || { ...defaultInfo }
    this.negative = negative || { ...defaultInfo }
  }
}

function libraryCheck({ signalName, model, type, modelKey = "", libraryList = [] }) {
  let error = null;
  const modelTitle = modelKey === "circuit" ? modelKey : modelKey.toUpperCase();
  if (model && model.libraryId && !libraryList.find(it => it.id === model.libraryId)) {
    error = `The ${type} ${modelTitle} model library ${model.libraryName || model.libraryFile || model.fileName} of ${signalName} is not exist.`;
  }
  return error;
}

function getCompPinBySignal({ signal, pins = [] }) {
  let positive = {
    pin: "",
    net: ""
  },
    negative = {
      pin: "",
      net: ""
    };
  const findPins = pins.filter(item => item.signal === signal.name);
  if (!findPins) {
    return { positive, negative }
  }

  const findPositive = findPins.find(item => signal.nets_P.includes(item.net));
  const findNegative = findPins.find(item => signal.nets_N.includes(item.net));
  if (findPositive) {
    positive = {
      pin: findPositive.pin,
      net: findPositive.net
    };
  }

  if (findNegative) {
    negative = {
      pin: findNegative.pin,
      net: findNegative.net
    };
  }

  return {
    positive,
    negative
  }
}

function getCPHYCompPinBySignal({ signal, pins = [] }) {
  const defaultLine = { pin: "", net: "" }
  const findPins = pins.filter(item => item.signal === signal.name);
  if (!findPins) {
    return { lineA: defaultLine, lineB: defaultLine, lineC: defaultLine }
  }

  const findLineA = findPins.find(item => signal.nets_A.includes(item.net)) || defaultLine;
  const findLineB = findPins.find(item => signal.nets_B.includes(item.net)) || defaultLine;
  const findLineC = findPins.find(item => signal.nets_C.includes(item.net)) || defaultLine;

  return {
    lineA: { pin: findLineA.pin, net: findLineA.net },
    lineB: { pin: findLineB.pin, net: findLineB.net },
    lineC: { pin: findLineC.pin, net: findLineC.net },
  }
}

function getJittersList(dirType) {
  if (dirType === ADS_TX) {
    return TX_JITTERS;
  }

  if (dirType === ADS_RX) {
    return RX_JITTERS;
  }
  return [];
}

function getJitterUnit(name) {
  if ([TX_SJ_FREQUENCY, RX_NOISE].includes(name)) {
    return "Float";
  }
  return "UI";
}

function getAdsTitleByType(type) {
  switch (type) {
    case NUMBER_OF_BIT:
      return "Number of bits";
    case ERROR_BIT_RATE:
      return "BER for measurement";
    case REGISTER_LENGTH:
      return "Register Length";
    case TAPS:
      return "Taps";
    case SEED:
      return "Seed";
    case BIT_SEQUENCE:
      return "Bit Sequence";
    case BIT_RATE:
      return "Data Rate";
    case NUMBER_OF_PRECURSOR_TAPS:
      return "Number of Pre Cursor Taps";
    case NUMBER_OF_POSTCURSOR_TAPS:
      return "Number of Post Cursor Taps";
    case NUMBER_OF_TAPS:
      return "Number of Taps";
    case PRECURSOR_TAPS:
      return "Pre Cursor Taps";
    case POSTCURSOR_TAPS:
      return "Post Cursor Taps";
    case NUMBER_OF_POLES:
      return "Number of Poles";
    case NUMBER_OF_ZEROS:
      return "Number of Zeros";
    case POLES:
      return "Poles";
    case ZEROS:
      return "Zeros";
    case DC_GAIN:
      return "DC Gain";
    case DE_EMPHASIS_VALUE:
      return DE_EMPHASIS;
    case TAP_INTERVAL:
      return "Tap Interval";
    case TX_DCD:
      return "Tx DCD";
    case TX_CLOCK_DCD:
      return "Tx Clock DCD";
    case TX_PJ_AMPLITUDE:
      return "Tx PJ Amplitude";
    case TX_PJ_FREQUENCY:
      return "Tx PJ Frequency";
    case RX_SJ_AMPLITUDE:
      return "Rx SJ Amplitude";
    case RX_AMPLITUDE_NOISE:
      return "Rx Amplitude Noise";
    case DFE_ADAPTIVE_EQUALIZATION:
      return 'DFE Adaptive Equalization';
    case SLICER_OUTPUT:
      return 'DFE Slicer Output';
    case ERROR_BIT_RATE_LIST:
      return 'BER list for contour plotting';
    default: return type;
  }
}

function adsConfigItemErrorCheck({ value, type, parentType = "", bitRate, modelType, unit, pj_amplitude }) {

  if (ALLOW_VALUE_EMPTY_PARAMS.includes(type) && !value) {
    return { error: null, errorType: null }
  }
  let error = ![TX_PJ_FREQUENCY, ERROR_BIT_RATE_LIST].includes(type) ? numberCheck(value) : null;
  const title = getAdsTitleByType(type);
  let errorType = "error";

  if (error) {
    return { error: `${parentType} ${title} ${error}`, errorType };
  }

  switch (type) {
    case NUMBER_OF_BIT:
      if (parseFloat(value) < 1000) {
        error = `${title} value should be greater than or equal to 1000.`;
      }
      break;
    case ERROR_BIT_RATE:
      if (parseFloat(value) > 1e-6) {
        error = `${title} value should be less than or equal to 1e-6.`;
      }
      break;
    case TAPS:
    case SEED:
      const reg = /^[01]+$/;
      if (!reg.test(value)) {
        error = `${title} value is not in the correct format.`;
      }
      break;
    case TX_DCD: // 0 ~1.0
    case TX_CLOCK_DCD:
      if (modelType === IBIS && parseFloat(value) < 0) {
        error = `${title} value should be greater than or equal to 0.`;
      }
      if (modelType === IBIS && parseFloat(value) >= 1) {
        error = `${title} value should be less than 1.`;
      }
      break;
    case TX_PJ_AMPLITUDE:
      const _errorInfo = jitterPjAmplitudeErrorCheck({ value, title, bitRate, unit });
      error = _errorInfo.error;
      errorType = _errorInfo.errorType;
      break;
    case TX_PJ_FREQUENCY:
      const errorInfo = jitterPjFreqErrorCheck({
        value, pj_amplitude, title, bitRate, unit
      });
      error = errorInfo.error;
      errorType = errorInfo.errorType;
      break;
    case SIGMA:
      if (parseFloat(value) < 0 || parseFloat(value) > 100) {
        error = `${parentType} ${title} value should be between 0 ~ 100.`;
      }
      break;
    case RX_SJ_AMPLITUDE:
    case RX_AMPLITUDE_NOISE:
      if (parseFloat(value) < 0 || parseFloat(value) > 1) {
        error = `${title} value should be between 0 ~ 1.`;
      }
      break;
    case DE_EMPHASIS_VALUE:
      if (parseFloat(value) < 0) {
        error = `${title} value should be greater than 0.`;
        errorType = "error";
        break;
      }
      if (parseFloat(value) > 40) {
        error = `${title} value should be less than or equal to 40dB.`;
        errorType = "error";
        break;
      }
      if (parseFloat(value) > 20) {
        error = `${title} value is best less than or equal to 20dB.`;
        errorType = "warning";
      }
      break;
    case TAP_INTERVAL:
      if (parseFloat(value) < 0.1 || parseFloat(value) > 10) {
        error = `${title} value should be between 0.1 ~ 10.`;
      }
      break;
    case NUMBER_OF_PRECURSOR_TAPS:
    case NUMBER_OF_POSTCURSOR_TAPS:
    case NUMBER_OF_TAPS:
    case NUMBER_OF_ZEROS:
    case NUMBER_OF_POLES:
      if (parseFloat(value) < 0) {
        error = `${title} value should be greater than 0.`;
        errorType = "error";
      }
      break;
    case ERROR_BIT_RATE_LIST:
      if (!value || value.length === 0) {
        error = `${title} value should have at least one data.`;
      }
      break;
    default: break;
  }
  return { error, errorType };
}

function jitterPjFreqErrorCheck({ value, pj_amplitude, title, bitRate, unit }) {
  let error = null, errorType = "error";

  //if pj_amplitude is set,frequency must be set.
  if (!value && pj_amplitude) {
    error = `When the TX PJ Amplitude value is set, the Tx PJ Frequency should be set.`;
    return { error, errorType }
  }

  error = numberCheck(value);
  if (error) {
    return { error, errorType }
  }
  //get data rate  - 8Gbps to 8e9Hz
  const { bitRate: _bitRate } = getBitRate(bitRate, false, "Hz");

  //10 * data rate
  const _max = NP.times(10, parseFloat(_bitRate));

  //change value to Hz
  const _scale = unitConversion(0, scaleKeys[unit]);
  const _value = value * _scale;

  //get display max value, Hz to ui unit.
  const __scale = unitConversion(scaleKeys[unit], 0);
  const _d_max = NP.times(_max, __scale);

  if (parseFloat(_value) < 0) {
    error = `${title} value is should be greater than 0.`;
    errorType = "error";
    return { error, errorType }
  }

  if (parseFloat(_value) > _max) {
    error = `${title} value is best less than or equal to ${_d_max}${unit}, (10 * Data Rate(${bitRate})).`;
    errorType = "warning";
  }
  return { error, errorType }
}

function jitterPjAmplitudeErrorCheck({ value, title, bitRate, unit }) {
  let error = null, errorType = "error";
  //get data rate  - 8Gbps to 8e9Hz
  const { bitRate: _bitRate } = getBitRate(bitRate, false, "Hz");

  //change value to s
  const scale = unitConversion(0, scaleKeys[unit]);
  const _value = value * scale;
  //max value = 1 / data rate
  const max = NP.divide(1, parseFloat(_bitRate));

  //get display max value, s to ui unit.
  const scale1 = unitConversion(scaleKeys[unit], 0);
  const d_max = parseFloat(NP.times(max, scale1).toExponential()).toFixed(2);

  if (parseFloat(_value) < 0 || parseFloat(_value) > max) {
    error = `${title} value should be between 0 ~ ${d_max}${unit}, (1 / Data Rate(${bitRate})).`;
  }
  return { error, errorType }
}

function getTableAmiParameters(amiParameters = [], tableDisplay = false, model = {}, fileInfo = {}) {
  let dataList = [];
  let sortList = amiParameters.map(item => item.sort).sort((a, b) => Number(a) - Number(b));
  dataList = SortFn(amiParameters, sortList, "sort");
  if (!tableDisplay) {
    return dataList;
  }

  const modelName = model.modelName;
  const amis = fileInfo.ibisAmi ? fileInfo.ibisAmi.amis : [];
  const models = fileInfo.ibisAmi ? fileInfo.ibisAmi.models : [];
  const findModel = models.find(item => item.name === modelName);
  const algorithmicModels = findModel ? findModel.algorithmicModels : [];
  const algorithmicModelsItem = algorithmicModels && algorithmicModels.length ? algorithmicModels[0] : {};
  const parameterName = algorithmicModelsItem.parameterFile;
  const findAmis = amis.find(item => item.name === parameterName);
  const ibisParameters = findAmis ? findAmis.parameters : {};

  let list = [], typeList = [];
  for (let param of dataList) {
    const findParam = ibisParameters[param.name] || {};
    let groupLength = 0;
    if (!typeList.includes(param.type)) {
      typeList.push(param.type);
      groupLength = dataList.filter(item => item.type === param.type).length;
    }

    list.push({
      ...param,
      description: getDescription(findParam),
      groupLength,
      valueType: findParam.valueType,
      valueList: findParam.valueList ? findParam.valueList : [],
      disable: findParam.disable,
      valueFormat: findParam.Type,
      displayValue: findParam.displayValue
    })
  }
  return list;
}

function getDescription(param) {
  let des = param.Description || "";
  switch (param.valueType) {
    case CORNER:
      des += param[CORNER] ? ` Corner: ${param[CORNER]}` : "";
      break;
    case RANGE:
      des += param[RANGE] ? ` Range: ${param[RANGE]}` : "";
      break;
    default: break;

  }
  return des;
}

function getBitRate(_bitRate, isClock, unit) {
  let bitRate = "", bitRateUnit = isClock ? "Mbps" : "Gbps";
  const arr = _bitRate ? _bitRate.split(" ") : [];
  bitRate = arr.length ? arr[0] : "";
  bitRateUnit = arr[1] ? arr[1] : bitRateUnit;

  if (unit && bitRateUnit === "Gbps") {
    bitRate = NP.times(parseFloat(bitRate), 1e9);
  }
  return { bitRate, bitRateUnit }
}

function adsConfigErrorCheck(adsConfig, selectedSignals = [], isMultiPcb = false, serdesType) {
  let settingErrors = [], errors = [];

  if (!adsConfig) {
    settingErrors.push(`Simulation setup is not set.`);
    errors.push({
      title: "[Simulation Settings]",
      monitor: settingErrors
    })
    return errors;
  }

  if (!adsConfig.controller || !adsConfig.device) {
    settingErrors.push("The component of signals AMI setup are not selected.");
    errors.push({
      title: "[Simulation Settings]",
      monitor: settingErrors
    })
    return errors;
  }

  //simulation setting error check
  if (!adsConfig.prbs[BIT_RATE] && serdesType !== CPHY) {
    const { bitRate } = getBitRate(adsConfig.prbs[BIT_RATE]);
    const bitRateError = numberCheck(bitRate);
    settingErrors.push(`The error bit rate ${bitRateError} `);
  }

  //simulation setting error check
  if ([TMDS_14, TMDS_2].includes(adsConfig.prbs.type) && !adsConfig.prbs[CLOCK_BIT_RATE]) {
    const { bitRate } = getBitRate(adsConfig.prbs[CLOCK_BIT_RATE], true);
    const bitRateError = numberCheck(bitRate);
    settingErrors.push(`The error clock bit rate ${bitRateError} `);
  }

  if (!adsConfig.eyeProbe[ERROR_BIT_RATE] && serdesType !== CPHY) {
    const { error: errorBitRateError } = adsConfigItemErrorCheck({ value: adsConfig.eyeProbe[ERROR_BIT_RATE], type: ERROR_BIT_RATE })
    errorBitRateError && settingErrors.push(errorBitRateError);
  }

  if (adsConfig.eyeProbe[ERROR_BIT_RATE_LIST] && serdesType !== CPHY) {
    const { error: errorBitRateError } = adsConfigItemErrorCheck({ value: adsConfig.eyeProbe[ERROR_BIT_RATE_LIST], type: ERROR_BIT_RATE_LIST })
    errorBitRateError && settingErrors.push(errorBitRateError);
  }

  if (!adsConfig[AMI_SIMULATION].mode === BIT_BY_BIT) {
    const { error: numberBitsError } = adsConfigItemErrorCheck({ value: adsConfig[AMI_SIMULATION][NUMBER_OF_BIT], type: NUMBER_OF_BIT })
    numberBitsError && settingErrors.push(numberBitsError);
  }

  if (settingErrors.length) {
    errors.push({
      title: "[Simulation Settings]",
      monitor: settingErrors
    });
    return errors;
  }

  //signals ami model error check
  let modelErrors = [];
  if (serdesType !== CPHY) {
    modelErrors = adsConfigModelCheck({ adsConfig, selectedSignals, isMultiPcb, serdesType });
  } else {
    modelErrors = cphyAdsConfigModelCheck({ adsConfig, selectedSignals, isMultiPcb, serdesType });
  }

  if (modelErrors.length) {
    errors.push(...modelErrors);
  }

  return errors.length ? errors : null;
}

function adsConfigModelCheck({ adsConfig, selectedSignals, isMultiPcb, serdesType }) {
  let errors = [];
  let libraryType = IBIS_AMI;
  if (adsConfig.signals[0] && adsConfig.signals[0].IbisHasAMI === "no") {
    libraryType = IBIS;
  }

  const libraryList = libraryConstructor.getLibraryValues(libraryType) || [];
  const SPICElibraryList = libraryConstructor.getLibraryValues(SPICE) || [];
  const genericTouchstoneList = libraryConstructor.getLibraryValues(GENERIC_TOUCHSTONE) || [];
  //signals ami model error check
  for (let signal of adsConfig.signals) {

    if (!selectedSignals.includes(signal.signalName)) {
      continue;
    }

    let signalErrors = [];
    if (signal.prbs) {
      const _errors = prbsErrorCheck(signal.prbs, signal.signalName);
      signalErrors = _errors.length ? [...signalErrors, ..._errors] : signalErrors;
    }
    const modelKey = signal.IbisHasAMI === "no" ? "" : "Ami";
    let errorExist = false;
    let modelKeyD = modelKey.toLowerCase();
    modelKeyD = modelKeyD ? ` ${modelKeyD}` : "";

    if (!signal[`tx${modelKey}Model`] || !signal[`tx${modelKey}Model`].modelName) {
      errorExist = true;
      signalErrors.push(`The TX${modelKeyD} model of ${signal.signalName} is not set.`);
    }

    const txError = libraryCheck({
      signalName: signal.signalName,
      model: signal[`tx${modelKey}Model`],
      type: ADS_TX,
      libraryList
    })

    if (txError) {
      signalErrors.push(txError);
      errorExist = true;
    }
    //tx circuit model error check
    if (signal.txCircuitModel && signal.txCircuitModel.libraryId) {
      const txCircuitError = libraryCheck({
        signalName: signal.signalName,
        model: signal.txCircuitModel,
        type: ADS_TX,
        modelKey: "circuit",
        libraryList: [...SPICElibraryList, ...genericTouchstoneList]
      })
      if (txCircuitError) {
        signalErrors.push(txCircuitError);
        errorExist = true;
      }

      const txPairsCircuitError = spicePairModelCheck({
        signalName: signal.signalName,
        model: signal.txCircuitModel,
        type: ADS_TX,
        modelKey: "circuit"
      })

      if (txPairsCircuitError && txPairsCircuitError.length) {
        signalErrors.push(...txPairsCircuitError);
        errorExist = true;
      }
    }

    let isUsedRC = !signal[`rx${modelKey}Model`] && signal.rcModel ? true : false;

    if ((!signal[`rx${modelKey}Model`] || !signal[`rx${modelKey}Model`].modelName) && !isUsedRC) {
      errorExist = true;
      signalErrors.push(`The RX${modelKeyD} model of ${signal.signalName} is not set.`);
    } else if ((signal.rcModel && !signal.rcModel.subckt) && isUsedRC) {
      errorExist = true;
      signalErrors.push(`The RX Termination model of ${signal.signalName} is not set.`);
    }

    const rxError = libraryCheck({
      signalName: signal.signalName,
      model: isUsedRC ? signal.rcModel : signal[`rx${modelKey}Model`],
      type: ADS_RX,
      libraryList: isUsedRC ? SPICElibraryList : libraryList
    })

    if (rxError) {
      signalErrors.push(rxError);
      errorExist = true;
    }

    //rx circuit model error check
    if (signal.rxCircuitModel && signal.rxCircuitModel.libraryId) {
      const rxCircuitError = libraryCheck({
        signalName: signal.signalName,
        model: signal.rxCircuitModel,
        type: ADS_RX,
        modelKey: "circuit",
        libraryList: [...SPICElibraryList, ...genericTouchstoneList]
      })
      if (rxCircuitError) {
        signalErrors.push(rxCircuitError);
        errorExist = true;
      }

      const rxPairsCircuitError = spicePairModelCheck({
        signalName: signal.signalName,
        model: signal.rxCircuitModel,
        type: ADS_RX,
        modelKey: "circuit"
      })

      if (rxPairsCircuitError && rxPairsCircuitError.length) {
        signalErrors.push(...rxPairsCircuitError);
        errorExist = true;
      }
    }

    if (errorExist) {
      signalErrors.length && errors.push({
        title: `[Signal - ${signal.signalName}]`,
        monitor: signalErrors
      });
      continue;
    }

    const txModelComp = signal[`tx${modelKey}Model`] ? signal[`tx${modelKey}Model`].component : null,
      rxModelComp = isUsedRC && signal.rcModel ? signal.rcModel.component : !isUsedRC && signal[`rx${modelKey}Model`] ? signal[`rx${modelKey}Model`].component : null;

    if ((!txModelComp || !rxModelComp)
      || (!isMultiPcb && txModelComp === rxModelComp)
      || ![adsConfig.controller, adsConfig.device].includes(txModelComp)
      || ![adsConfig.controller, adsConfig.device].includes(rxModelComp)
    ) {
      signalErrors.push(`The${modelKeyD} model of ${signal.signalName} is not set.`);
    }
    if (signalErrors.length) {
      errors.push({
        title: `[Signal - ${signal.signalName}]`,
        monitor: signalErrors
      });
    }

    if (signal.IbisHasAMI === "yes" && !isUsedRC) {
      continue;
    }
    //ibis model jitters and EQ error check.
    if (signal.IbisHasAMI === "no") {
      //tx model
      //jitters
      signalErrors = jittersErrorCheck({
        errors: signalErrors,
        type: ADS_TX,
        jitters: signal[`tx${modelKey}Model`].jitters,
        bitRate: adsConfig.prbs.bitRate
      })
      //EQ
      signalErrors = txEqErrorCheck({
        errors: signalErrors,
        type: ADS_TX,
        bitRate: adsConfig.prbs.bitRate,
        EQ: signal[`tx${modelKey}Model`].EQ,
      })
    }

    //rx model
    signalErrors = jittersErrorCheck({
      errors: signalErrors,
      type: ADS_RX,
      jitters: isUsedRC ? signal.rcModel.jitters : signal[`rx${modelKey}Model`].jitters,
      bitRate: adsConfig.prbs.bitRate
    });

    //rx model
    signalErrors = rxEqErrorCheck({
      errors: signalErrors,
      type: ADS_RX,
      bitRate: adsConfig.prbs.bitRate,
      EQ: isUsedRC ? signal.rcModel.EQ : signal[`rx${modelKey}Model`].EQ,
      serdesType
    });

    if (signalErrors.length) {
      const signalIndex = errors.findIndex(item => item.title === signal.signalName);
      if (signalIndex > -1) {
        errors[signalIndex].monitor = [...errors[signalIndex].monitor, ...signalErrors];
      } else {
        errors.push({
          title: `[Signal - ${signal.signalName}]`,
          monitor: signalErrors
        });
      }
    }
  }
  return errors;
}

function cphyAdsConfigModelCheck({ adsConfig, selectedSignals, isMultiPcb, serdesType }) {
  let errors = [];
  const libraryList = [...libraryConstructor.getLibraryValues(IBIS), ...libraryConstructor.getLibraryValues(IBIS_AMI)];
  for (let signal of adsConfig.signals) {

    if (!selectedSignals.includes(signal.signalName)) {
      continue;
    }

    let signalErrors = [];
    if (signal.prbs) {
      const _errors = prbsErrorCheck(signal.prbs, signal.signalName);
      signalErrors = _errors.length ? [...signalErrors, ..._errors] : signalErrors;
    }
    let errorExist = false;

    if (!signal.cphyTxModel) {
      errorExist = true;
      signalErrors.push(`The Tx model of ${signal.signalName} is not set.`);
    }

    if (!signal.cphyRxModel) {
      errorExist = true;
      signalErrors.push(`The Rx model of ${signal.signalName} is not set.`);
    }

    // model file error check
    if (signal.cphyTxModel.specifyIbis) {
      const txError = libraryCheck({
        signalName: signal.signalName,
        model: signal.cphyTxModel,
        type: ADS_TX,
        libraryList,
        modelKey: ""
      })

      if (txError) {
        signalErrors.push(txError);
        errorExist = true;
      }

      const txPinModelError = pinModelCheck({
        pinModelInfo: signal.cphyTxModel.pinModel,
        type: ADS_TX
      })

      if (txPinModelError) {
        errorExist = true;
        signalErrors.push(...txPinModelError);
      }
    }

    if (signal.cphyRxModel.specifyIbis) {
      const rxError = libraryCheck({
        signalName: signal.signalName,
        model: signal.cphyRxModel,
        type: ADS_RX,
        libraryList,
        modelKey: ""
      })

      if (rxError) {
        signalErrors.push(rxError);
        errorExist = true;
      }

      const rxPinModelError = pinModelCheck({
        pinModelInfo: signal.cphyRxModel.pinModel,
        type: ADS_RX
      })

      if (rxPinModelError) {
        errorExist = true;
        signalErrors.push(...rxPinModelError);
      }
    }

    if (errorExist) {
      signalErrors.length && errors.push({
        title: `[Signal - ${signal.signalName}]`,
        monitor: signalErrors
      });
      continue;
    }

    // check component
    const txModelComp = signal.cphyTxModel ? signal.cphyTxModel.component : null,
      rxModelComp = signal.cphyRxModel ? signal.cphyRxModel.component : null;

    if ((!txModelComp || !rxModelComp)
      || (!isMultiPcb && txModelComp === rxModelComp)
      || ![adsConfig.controller, adsConfig.device].includes(txModelComp)
      || ![adsConfig.controller, adsConfig.device].includes(rxModelComp)
    ) {
      signalErrors.push(`The model of ${signal.signalName} is not set.`);
    }
    if (signalErrors.length) {
      errors.push({
        title: `[Signal - ${signal.signalName}]`,
        monitor: signalErrors
      });
    }

    if (signalErrors.length) {
      const signalIndex = errors.findIndex(item => item.title === signal.signalName);
      if (signalIndex > -1) {
        errors[signalIndex].monitor = [...errors[signalIndex].monitor, ...signalErrors];
      } else {
        errors.push({
          title: `[Signal - ${signal.signalName}]`,
          monitor: signalErrors
        });
      }
    }
  }
  return errors;
}

function pinModelCheck({ pinModelInfo = {}, type }) {
  const pinModelError = [];
  if (!Object.keys(pinModelInfo).length) {
    pinModelError.push(`${type} Model - The pin model is not set.`);
  } else {
    for (const key of ['pinA', 'pinB', 'pinC']) {
      const { isPinDiff, nonPin, pin, pinModel } = pinModelInfo[key] || {};
      // pin
      if (!pin) {
        pinModelError.push(`${type} Model - The pin of ${key} is not set.`);
      }
      // diffpin
      if (isPinDiff && !nonPin) {
        pinModelError.push(`${type} Model - The nonPin of ${key} is not set.`);
      }
      // pinModel
      if (!pinModel) {
        pinModelError.push(`${type} Model - The model of ${key} is not set.`);
      }
    }
  }

  return pinModelError.length ? pinModelError : null;
}

function jittersErrorCheck({ errors, type, jitters, bitRate }) {
  const jitterOptions = type === ADS_TX ? IBIS_TX_JITTERS : IBIS_RX_JITTERS;
  for (let key of jitterOptions) {
    const find = jitters.find(item => item.name === key);
    if (!find) {
      continue;
    }
    let parentType = null, pj_amplitude = null;

    if ([TX_JITTER_PDF, RX_JITTER_PDF].includes(key)) {
      parentType = key;
      //sigma error check
      const { error: sigmaError, errorType } = adsConfigItemErrorCheck({
        value: find.sigma,
        type: SIGMA,
        parentType: key,
        modelType: IBIS
      });
      sigmaError && errorType === "error" && errors.push(`${type} model - ${sigmaError}`);
      let options = find.type === DJRJ ? ["min", "max"] : [];
      options = find.type === DUAL_DIRAC ? ["mean1", "mean2"] : [];

      for (let pdfKey of options) {
        const { error: pdfParamError } = adsConfigItemErrorCheck({
          value: find[pdfKey],
          type: pdfKey,
          parentType: key,
          modelType: IBIS
        });
        pdfParamError && errors.push(`${type} model - ${pdfParamError}`);
      }
    } else {
      if (key === TX_PJ_FREQUENCY) {
        const findPjAmplitude = jitters.find(item => item.name === TX_PJ_AMPLITUDE);
        pj_amplitude = findPjAmplitude ? findPjAmplitude.value : "";
      }

      const { error, errorType: _errorType } = adsConfigItemErrorCheck({
        value: find.value,
        type: key,
        parentType,
        bitRate,
        modelType: IBIS,
        unit: find.unit,
        pj_amplitude
      });
      error && _errorType === "error" && errors.push(`${type} model - ${error}`);
    }
  }
  return errors;
}

function txEqErrorCheck({ errors, EQ, bitRate }) {
  if (!EQ || EQ.type === 'None') {
    return errors;
  }

  let options = EQ.type === FIR_TAPS ? [...FIR_TAPS_OPTIONS] : [];
  options = EQ.type === DE_EMPHASIS ? [...DE_EMPHASIS_OPTIONS] : options;

  for (let itemData of options) {
    const { error, errorType } = adsConfigItemErrorCheck({
      value: EQ[itemData.key],
      type: itemData.key,
      bitRate,
      modelType: IBIS
    });
    error && errorType === "error" && errors.push(`TX model - ${error}`);

    if (!itemData.sub) {
      continue;
    }
    if (Array.isArray(EQ[itemData.sub.key])) {
      //Taps,poles,zeros error check
      const list = EQ[itemData.sub.key];
      let { num, numType } = getTagsNumber(itemData.sub.key, EQ);

      if (list.length === num) {
        const _numErrors = list.filter(item => !!numberCheck(splitEQValue(item).value));
        let _error = _numErrors.length ? `${itemData.sub.title} value must be a number.` : null;
        _error && errors.push(`TX model - ${_error}`);
      } else if (list.length < num) {
        errors.push(`TX model - The number of ${itemData.sub.title} should be equal to "${numType}".`);
      } else {
        errors.push(`TX model - ${itemData.sub.title} cannot exceed ${numType}.`);
      }
    } else {
      const { error, errorType } = adsConfigItemErrorCheck({
        value: EQ[itemData.sub.key],
        type: itemData.sub.key,
        bitRate,
        modelType: IBIS
      });
      error && errorType === "error" && errors.push(`TX model - ${error}`);
    }
  }
  return errors;
}

function rxEqErrorCheck({ errors, bitRate, EQ, serdesType }) {
  if (!EQ || (EQ[ENABLE_CTLE_OR_FFE] === 'None' && (!EQ[DFE_TYPE] || EQ[DFE_TYPE] === "None"))) {
    return errors;
  }

  let options = EQ[ENABLE_CTLE_OR_FFE] === FFE ? getFFEOptions(EQ[FFE_TYPE]) : [];
  options = EQ[ENABLE_CTLE_OR_FFE] === CTLE ? EQ[CTLE_TYPE] === "Custom" ? [...CTLE_CUSTOM_OPTIONS] : getCtlePresetOptions(EQ[PRESET], serdesType) : options;

  for (let itemData of options) {
    if ([ADAPTIVE_EQUALIZATION, DFE_ADAPTIVE_EQUALIZATION, SLICER_OUTPUT, PRESET].includes(itemData.key)) {
      continue;
    }
    if ([DC_GAIN, AC_GAIN].includes(itemData.key) && itemData.disabled) {
      continue
    }
    const { error, errorType } = adsConfigItemErrorCheck({
      value: EQ[itemData.key],
      type: itemData.key,
      bitRate,
      modelType: IBIS
    });
    error && errorType === "error" && errors.push(`RX model - ${error}`);

    if (!itemData.sub) {
      continue;
    }
    if (Array.isArray(EQ[itemData.sub.key])) {
      //Taps,poles,zeros error check
      const list = EQ[itemData.sub.key];
      let { num, numType } = getTagsNumber(itemData.sub.key, EQ);

      if (list.length === num) {
        const _numErrors = list.filter(item => !!numberCheck(splitEQValue(item).value));
        let _error = _numErrors.length ? `${itemData.sub.title} value must be a number.` : null;
        _error && errors.push(`RX model - ${_error}`);
      } else if (list.length < num) {
        errors.push(`RX model - The number of ${itemData.sub.title} should be equal to "${numType}".`);
      } else {
        errors.push(`RX model - ${itemData.sub.title} cannot exceed ${numType}.`);
      }
    } else {
      if (([ZEROS, POLES].includes(itemData.sub.key) && !EQ[itemData.key]) || [PRESET].includes(itemData.sub.key)) { continue }
      const { error, errorType } = adsConfigItemErrorCheck({
        value: EQ[itemData.sub.key],
        type: itemData.sub.key,
        bitRate,
        modelType: IBIS
      });
      error && errorType === "error" && errors.push(`RX model - ${error}`);
    }
  }

  if ([MANUAL, OPTIMIZED].includes(EQ[DFE_TYPE])) {
    errors = dfeErrorCheck(EQ, bitRate, errors)
  }
  return errors;
}

function dfeErrorCheck(EQ, bitRate, errors) {

  for (let key of [DFE_ADAPTIVE_EQUALIZATION, SLICER_OUTPUT]) {
    if (!EQ[key]) {
      errors.push(`RX model DFE - ${getAdsTitleByType(key)} is not set.`)
    }
  }
  if (EQ[DFE_TYPE] === OPTIMIZED) {
    if (!EQ[NUMBER_OF_TAPS] || EQ[NUMBER_OF_TAPS] === "0" || EQ[NUMBER_OF_TAPS] === "NO") {
      errors.push(`RX model DFE - Number of Taps is not set.`)
    }
  }

  if (EQ[DFE_TYPE] === MANUAL) {
    if (!EQ[TAPS] || !EQ[TAPS].length) {
      errors.push(`RX model DFE - Tap Values is not set.`);
    } else {
      const taps = EQ[TAPS];
      let { num, numType } = getTagsNumber(TAPS, EQ);

      if (taps.length === num) {
        const _numErrors = taps.filter(item => !!numberCheck(splitEQValue(item).value));
        let _error = _numErrors.length ? `Tap values must be a number.` : null;
        _error && errors.push(`RX model DFE - ${_error}`);
      } else if (taps.length < num) {
        errors.push(`RX model DFE - The number of "Tap values" should be equal to "${numType}".`);
      } else {
        errors.push(`RX model DFE - "Tap values" cannot exceed ${numType}.`);
      }
    }
  }
  return errors;
}

function prbsErrorCheck(prbs) {
  let errors = [];
  switch (prbs.mode) {
    case MAXIMAL_LENGTH_LFSR:
      const { error: registerLengthError } = adsConfigItemErrorCheck({ value: prbs[REGISTER_LENGTH], type: REGISTER_LENGTH })
      registerLengthError && errors.push(registerLengthError);
      break;
    case USER_DEFINED_LFSR:
      const { error: tapsError } = adsConfigItemErrorCheck({ value: prbs[TAPS], type: TAPS })
      tapsError && errors.push(tapsError);
      const { error: seedError } = adsConfigItemErrorCheck({ value: prbs[SEED], type: SEED })
      seedError && errors.push(seedError);
      break;
    case USER_DEFINED_SEQUENCE:
      const { error: bitSequenceError } = adsConfigItemErrorCheck({ value: prbs[BIT_SEQUENCE], type: BIT_SEQUENCE })
      bitSequenceError && errors.push(bitSequenceError);
      break;
    default: return errors;
  }
  return errors;
}

function getSelectICComps({
  interfaceType,
  components = [],
  controllerICComps = [],
  deviceICComps = []
}) {
  if (interfaceType === END_TO_END_CHANNEL) {
    return { contrComps: [...controllerICComps], deviceComps: [...deviceICComps] };
  }
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
  return { contrComps: [...ICComps], deviceComps: [...ICComps] };
}

function amiParameterValueCheck(value, range, title, record) {
  const numError = numberCheck(value);
  if (numError) {
    return `${title} ${numError} `;
  }

  if (record.valueFormat === INTEGER) {
    //is integer
    if (!/^((\d+)|(-\d+)|(0+))$/g.test(value)) {
      return `${title} value must be a integer. `
    }
  }

  if (!range || range.length < 2) {
    return null;
  }
  if (parseFloat(value) < parseFloat(range[0]) || parseFloat(value) > parseFloat(range[1])) {
    return `${title} value should be between ${range[0]} ~ ${range[1]}.`;
  }
  return null;
}

function getJittersUnits(name) {
  switch (name) {
    case TX_SJ_FREQUENCY:
    case TX_PJ_FREQUENCY:
      return ["Hz", "KHz", "MHz", "GHz"];
    case TX_PJ_AMPLITUDE:
      return ["s", "ms", "ns", "ps"];
    default: return [];
  }
}

function getJitterPDFByType(record) {
  const find = JITTER_PDF_LIST.find(item => item.key === record.type)
  if (find) {
    let newRecord = {
      name: record.name,
      type: record.type,
      unit: record.unit || "UI",
    }
    find.list.forEach(item => {
      const key = item.toLowerCase();
      newRecord[key] = key === "sigma" ? record.sigma : "";
    });
    return newRecord;
  }
  return record;
}

function setAMIModel({ model, modelInfo, dirType, fileInfo }) {
  model.modelName = modelInfo.name;
  const algorithmicModelsItem = modelInfo.algorithmicModels && modelInfo.algorithmicModels.length ? modelInfo.algorithmicModels[0] : {};
  const parameterName = algorithmicModelsItem.parameterFile;

  const findModel = fileInfo.ibisAmi.amis.find(item => item.name === parameterName)
  let amiParameters = [];
  if (findModel && findModel.parameters) {
    for (let item in findModel.parameters) {
      amiParameters.push({
        name: item,
        value: findModel.parameters[item].Value,
        type: findModel.parameters[item].type,
        sort: findModel.parameters[item].sort
      })
    }
  }
  model.amiParameters = getTableAmiParameters(amiParameters);
  model.jitters = setDefaultAMIJitters(dirType, amiParameters) || [];
  //Pin
  const findPin = modelInfo.pins.find(it => it.pinType === "pin");
  const findInvPin = modelInfo.pins.find(it => it.pinType === "invPin");
  model.pin = findPin ? findPin.pin : "";
  model.invPin = findInvPin ? findInvPin.pin : "";
  //update dataType to default
  model.dataType = "Typ";

  return model;
}

function getTagsNumber(type, EQ, isDFE) {
  let num = 0, numType = "", compareLength = true;
  switch (type) {
    case PRECURSOR_TAPS:
      num = parseInt(EQ[NUMBER_OF_PRECURSOR_TAPS]) || 0;
      numType = "Number of Pre Cursor Taps";
      break;
    case POSTCURSOR_TAPS:
      num = parseInt(EQ[NUMBER_OF_POSTCURSOR_TAPS]) || 0;
      numType = "Number of Post Cursor Taps";
      break;
    case POLES:
      num = parseInt(EQ[NUMBER_OF_POLES]) || 0;
      numType = "Number of Poles";
      break;
    case ZEROS:
      num = parseInt(EQ[NUMBER_OF_ZEROS]) || 0;
      numType = "Number of Zeros";
      break;
    case TAPS:
      num = parseInt(EQ[NUMBER_OF_TAPS]) || 0;
      numType = "Number of Taps";
      compareLength = false;
      if (isDFE) {
        const specifiedPresets = getHDMIPresetOptions(["12"], ["3", "4", "5", "6", "7", "8"]);
        const findPreset = specifiedPresets.find(item => item.option === EQ[PRESET]);
        if (findPreset && EQ[DFE_TYPE] === MANUAL && EQ[DFETAPUSED] === PROTOCOL && parseFloat(EQ[NUMBER_OF_TAPS])) {
          num = EQ[NUMBER_OF_TAPS] || 0;
          compareLength = true;
        }
      }

      break;
    default: break;
  }
  return { num, numType, compareLength };
}

function getEQTitleWidth(options) {
  const titleList = options.map(item => item.title).sort((a, b) => b.length - a.length);
  let width = titleList[0] ? titleList[0].length * 8 : 100;
  return width < 100 ? 100 : width;
}

function splitEQValue(valueStr = "") {
  const arr = valueStr.split(" ");
  let value = "", unit = "";
  if (arr.length) {
    value = arr[0];
    unit = arr[1] || "";
  }
  return { value, unit };
}

function getTagValues(type) {
  let key = "", title = "";
  switch (type) {
    case NUMBER_OF_PRECURSOR_TAPS:
      key = PRECURSOR_TAPS;
      title = "Tap Values";
      break;
    case NUMBER_OF_POSTCURSOR_TAPS:
      key = POSTCURSOR_TAPS;
      title = "Tap Values";
      break;
    case NUMBER_OF_POLES:
      key = POLES;
      title = "Frequencies";
      break;
    case NUMBER_OF_ZEROS:
      key = ZEROS;
      title = "Frequencies";
      break;
    case NUMBER_OF_TAPS:
      key = TAPS;
      title = "Tap Values";
      break;
    default: break;
  }
  return { key, title };
}

function updateTapValuesByNumber(type, EQ) {
  let error = null;
  const num = EQ[type];
  const { key, title } = getTagValues(type);
  const values = EQ[key] || [];
  let numTitle = getAdsTitleByType(type);

  //number not set, remove values
  if (!num) {
    EQ[key] = [];
  }

  const numError = num ? numberCheck(num) : null;

  //number error, remove values
  if (numError) {
    error = `${numTitle} value must be a number.`;
    EQ[key] = [];
  }

  //number less than values length,remove values
  if (parseInt(num) < values.length) {
    error = `${title} cannot exceed ${numTitle}.`;
    EQ[key] = EQ[key].filter((item, index) => index < parseInt(num));
  }
  return { EQ, error }
}

function getTableAmiParametersValue(record) {
  if (record.valueFormat === STRING) {
    //`"6.0" to 6.0
    return record.value.replace(/^"|"$/g, "");
  }
  return record.value;
}

function spicePairModelCheck({
  signalName,
  model,
  type,
  modelKey
}) {
  if (!model || !model.libraryId) {
    return null;
  }

  if (!model.pairs || !model.pairs.length) {
    return [`The ${type} ${modelKey} model ports is not exist.`];
  }

  let errors = [];
  for (let pair of model.pairs) {
    if (!pair.node) {
      errors.push(`The ${type} ${modelKey} model ${model.component}-${pair.pin}${pair.position ? ` ${pair.position}` : ""} port is not set.`)
    }
  }
  return errors.length ? errors : null;
}

function updateModelCompByDir({
  key,
  signalItem,
  modelKey,
  _adsConfig,
  type,
  antherType,
  interfaceType,
  adsSignals,
  rxModelKey,
  typePkgExist,
  anotherTypePkgExist,
  serdesType
}) {

  if (serdesType === CPHY) {
    return updateCPHYModelCompByDir({ key, signalItem, _adsConfig, type, antherType, interfaceType, typePkgExist, anotherTypePkgExist });
  }

  // shift rcModel pairs
  const finsSignalInfo = adsSignals.find(it => it.signal === signalItem.signalName)
  if (finsSignalInfo && rxModelKey === 'rcModel' && signalItem[rxModelKey].pairs && [ADS_RX, ADS_TX].includes(key)) {
    signalItem[rxModelKey].pairs = updateCircuitAndRcPairs({
      prevPairs: signalItem[rxModelKey].pairs,
      key,
      type: key === ADS_RX ? antherType : type,
      antherType: key === ADS_RX ? type : antherType,
      finsSignalInfo
    })
  }

  if (key === ADS_TX) {

    signalItem[`tx${modelKey}Model`].component = _adsConfig[type];
    signalItem[rxModelKey].component = _adsConfig[antherType];
    if (interfaceType === END_TO_END_CHANNEL) {
      signalItem[`tx${modelKey}Model`].channelId = _adsConfig[`${type}Channel`].channelId;
      signalItem[rxModelKey].channelId = _adsConfig[`${antherType}Channel`].channelId;
    }

    if (typePkgExist) {
      signalItem[`tx${modelKey}Model`].usePackage = false;
    }

    if (anotherTypePkgExist) {
      signalItem[rxModelKey].usePackage = false;
    }

    if (signalItem.txCircuitModel && signalItem.txCircuitModel.component) {
      signalItem.txCircuitModel.component = _adsConfig[type];
      signalItem.txCircuitModel.pairs = updateCircuitAndRcPairs({
        prevPairs: signalItem.txCircuitModel.pairs,
        key,
        type: antherType,
        antherType: type,
        finsSignalInfo
      });
      if (interfaceType === END_TO_END_CHANNEL) {
        signalItem.txCircuitModel.channelId = _adsConfig[`${type}Channel`].channelId;
      }
    }
    if (signalItem.rxCircuitModel && signalItem.rxCircuitModel.component) {
      signalItem.rxCircuitModel.component = _adsConfig[antherType];
      signalItem.rxCircuitModel.pairs = updateCircuitAndRcPairs({
        prevPairs: signalItem.rxCircuitModel.pairs,
        key,
        type,
        antherType,
        finsSignalInfo
      });
      if (interfaceType === END_TO_END_CHANNEL) {
        signalItem.rxCircuitModel.channelId = _adsConfig[`${antherType}Channel`].channelId;
      }
    }
  }
  if (key === ADS_RX) {
    signalItem[`tx${modelKey}Model`].component = _adsConfig[antherType];
    signalItem[rxModelKey].component = _adsConfig[type];

    if (interfaceType === END_TO_END_CHANNEL) {
      signalItem[`tx${modelKey}Model`].channelId = _adsConfig[`${antherType}Channel`].channelId;
      signalItem[rxModelKey].channelId = _adsConfig[`${type}Channel`].channelId;
    }

    if (anotherTypePkgExist) {
      signalItem[`tx${modelKey}Model`].usePackage = false;
    }

    if (typePkgExist) {
      signalItem[rxModelKey].usePackage = false;
    }

    if (signalItem.txCircuitModel && signalItem.txCircuitModel.component) {
      signalItem.txCircuitModel.component = _adsConfig[antherType];
      signalItem.txCircuitModel.pairs = updateCircuitAndRcPairs({
        prevPairs: signalItem.txCircuitModel.pairs,
        key,
        type,
        antherType,
        finsSignalInfo
      });
      if (interfaceType === END_TO_END_CHANNEL) {
        signalItem.txCircuitModel.channelId = _adsConfig[`${antherType}Channel`].channelId;
      }
    }
    if (signalItem.rxCircuitModel && signalItem.rxCircuitModel.component) {
      signalItem.rxCircuitModel.component = _adsConfig[type];
      signalItem.rxCircuitModel.pairs = updateCircuitAndRcPairs({
        prevPairs: signalItem.rxCircuitModel.pairs,
        key,
        type: antherType,
        antherType: type,
        finsSignalInfo
      });
      if (interfaceType === END_TO_END_CHANNEL) {
        signalItem.rxCircuitModel.channelId = _adsConfig[`${type}Channel`].channelId;
      }
    }
  }
  return signalItem;
}

function updateCPHYModelCompByDir({
  key,
  signalItem,
  _adsConfig,
  type,
  antherType,
  interfaceType,
  typePkgExist,
  anotherTypePkgExist
}) {

  if (key === ADS_TX) {
    signalItem.cphyTxModel.component = _adsConfig[type];
    signalItem.cphyRxModel.component = _adsConfig[antherType];
    if (interfaceType === END_TO_END_CHANNEL) {
      signalItem.cphyTxModel.channelId = _adsConfig[`${type}Channel`].channelId;
      signalItem.cphyRxModel.channelId = _adsConfig[`${antherType}Channel`].channelId;
    }

    if (typePkgExist) {
      signalItem.cphyTxModel.usePackage = false;
    }

    if (anotherTypePkgExist) {
      signalItem.cphyRxModel.usePackage = false;
    }
  }
  if (key === ADS_RX) {
    signalItem.cphyTxModel.component = _adsConfig[antherType];
    signalItem.cphyRxModel.component = _adsConfig[type];

    if (interfaceType === END_TO_END_CHANNEL) {
      signalItem.cphyTxModel.channelId = _adsConfig[`${antherType}Channel`].channelId;
      signalItem.cphyRxModel.channelId = _adsConfig[`${type}Channel`].channelId;
    }

    if (anotherTypePkgExist) {
      signalItem.cphyTxModel.usePackage = false;
    }

    if (typePkgExist) {
      signalItem.cphyRxModel.usePackage = false;
    }
  }

  return signalItem;
}

function updateCircuitAndRcPairs({ prevPairs, key, type, antherType, finsSignalInfo }) {
  let newPairs = [];
  for (let pair of prevPairs) {
    // pair
    const { pin } = pair;
    let typeKey = type, _antherType = antherType;
    if (finsSignalInfo[typeKey].negative.pin === pin && finsSignalInfo[_antherType].negative.pin) {
      newPairs.push({ ...pair, pin: finsSignalInfo[_antherType].negative.pin })
    } else if (finsSignalInfo[typeKey].positive.pin === pin && finsSignalInfo[_antherType].positive.pin) {
      newPairs.push({ ...pair, pin: finsSignalInfo[_antherType].positive.pin })
    }
  }
  return newPairs;
}

function updateAdsConfigSignals({ adsConfig, signals, signal_map, interfaceType }) {
  let save = false;
  let signalNames = [];
  const adsSignalNames = adsConfig.signals.map(item => item.signalName);
  if (interfaceType === END_TO_END_CHANNEL) {
    //signals - signal_map
    signalNames = (signal_map || []).map(item => item.name);
  } else {
    signalNames = (signals || []).map(item => item.name);
  }
  if (_.isEqual(signalNames, adsSignalNames)) {
    return { adsConfig, save: false }
  }
  const addSignals = signalNames.filter(item => !adsSignalNames.includes(item));
  const delSignals = adsSignalNames.filter(item => !signalNames.includes(item));
  if (delSignals.length) {
    adsConfig.signals = adsConfig.signals.filter(item => signalNames.includes(item.signalName));
    save = true;
  }

  if (addSignals.length) {

    const IbisHasAMI = adsConfig.signals.length ? adsConfig.signals[0].IbisHasAMI : 'yes';
    adsConfig.signals.push(...addSignals.map(item => {
      const { txComp, rxComp } = getSignalAmiCompBySignalName({
        signalName: item,
        controller: { comp: adsConfig.controller, channel: { ...(adsConfig.controllerChannel || {}) } },
        device: { comp: adsConfig.device, channel: { ...(adsConfig.deviceChannel || {}) } }
      });
      return new AMISignalConfig({
        type: interfaceType,
        signalName: item,
        controller: txComp.comp || "",
        controllerChannelId: txComp.channel ? txComp.channel.channelId : null,
        device: rxComp.comp || "",
        deviceChannelId: rxComp.channel ? rxComp.channel.channelId : null,
        IbisHasAMI
      })
    }))
    save = true;
    adsConfig.signals = SortFn(adsConfig.signals, signalNames, "signalName");
  }

  return { adsConfig, save }
}

function getProbeType(probe) {
  switch (probe) {
    case "ProbeRx":
      return "RX-IN";
    case "ProbeTx":
      return "TX-OUT";
    default:
      return "RX-OUT"
  }
}

/**
 * 
 * @param {String} type waveform/jitter/txEQ/cphyElectrical
 * @param {*} info {data, dataType}
 * @param {String} dirType tx/rx
 * @returns 
 */
function getModelSetupDataList(type, { data, dataType }, dirType) {
  let defaultDataList = [];

  switch (type) {
    case WAVEFORM:
      defaultDataList = wavefromLabelList[dataType];
      break;
    case JITTER:
      defaultDataList = jitterLabelList[dataType];
      break;
    case TX_EQ:
      defaultDataList = EQLabelList;
      break;
    case ELECTRICAL:
      defaultDataList = dirType === ADS_TX ? electricalLabelList
        : electricalLabelList.map(item => ({
          ...item,
          label: item.label.replace('Rout', 'Rin'),
          eleName: item.eleName.replace('rout', 'rin')
        }));
      break;
    case EYEPROBE:
      defaultDataList = eyeProbeLabelList.map(item => {
        if (["eyeRampTime", "eyeMidTime"].includes(item.eleName) && data.useDefaultEye) {
          return { ...item, disabled: data.useDefaultEye }
        }
        return item;
      })
      break;
    default:
      break;
  }

  if (!data) {
    return [];
  }

  const dataList = (defaultDataList || []).map(item => {
    if (item.unit) {
      const [value, unit] = String(data[item.eleName]).split(' ');
      return { ...item, value, unit }
    }
    return { ...item, value: data[item.eleName] }
  })

  return dataList;
}

function getPinModelDataList(pinModel) {
  const defaultDataList = pinModelLabelList;

  if (!pinModel) {
    return [];
  }

  const dataList = (defaultDataList || []).map(item => {
    const pinInfo = pinModel[item.parentEleName] || {};
    return { ...item, pinValue: pinInfo[item.eleName], modelValue: pinInfo[`${item.eleName}Model`] }
  })

  return dataList
}

async function getCPHYIBISModelSelect(file, model) {
  const libraryModels = await getIbisModelParse(file.id) || {};
  const packages = libraryModels.packages || [], modelSelectors = libraryModels.models.map(item => item.name);
  const pinsGroup = packages.map(item => {
    const pins = item.pinSection.map(pinInfo => {
      const findPin = item.diffPins.find(it => it.pin === pinInfo.pin || it.invPin === pinInfo.pin);
      const model_name = modelSelectors.includes(pinInfo.model_name) ? pinInfo.model_name : ""
      if (findPin && findPin.pin === pinInfo.pin) {
        return { ...pinInfo, model_name, isPinDiff: true, nonPin: findPin.pin, invPin: findPin.invPin }
      } else if (findPin && findPin.invPin === pinInfo.pin) {
        return { ...pinInfo, model_name, isPinDiff: true, nonPin: findPin.pin, invPin: findPin.invPin }
      }
      return { ...pinInfo, model_name, isPinDiff: false }
    });
    return pins;
  })

  // models : [{AInfo:{pin,signal_name,model_name},BInfo,CInfo}]
  let models = getCPHYIBISModelList(pinsGroup), _model = { ...model };
  const libraryComponents = libraryModels && libraryModels.packages ? libraryModels.packages.map(item => item.component) : []
  if (models.length === 0) {
    _model.ibisComponent = libraryComponents[0];
  } else {
    const { AInfo = {}, BInfo = {}, CInfo = {} } = models[0] || {};
    _model = {
      ...model,
      ibisComponent: libraryComponents[0],
      pinModel: {
        pinA: {
          pin: AInfo.pin,
          pinModel: AInfo.model_name || modelSelectors[0],
          isPinDiff: AInfo.isPinDiff,
          nonPin: AInfo.nonPin,
          invPin: AInfo.invPin
        },
        pinB: {
          pin: BInfo.pin,
          pinModel: BInfo.model_name || modelSelectors[0],
          isPinDiff: BInfo.isPinDiff,
          nonPin: BInfo.nonPin,
          invPin: BInfo.invPin
        },
        pinC: {
          pin: CInfo.pin,
          pinModel: CInfo.model_name || modelSelectors[0],
          isPinDiff: CInfo.isPinDiff,
          nonPin: CInfo.nonPin,
          invPin: CInfo.invPin
        }
      }
    };
  }
  models = pinsGroup.flat(2);
  return {
    libraryComponents,
    libraryModels: models,
    model: _model,
    modelSelectors
  }
}

function getCPHYIBISAMIModelSelect(file, stateModel) {
  const ibisAmi = file.ibisAmi || {};
  let libraryModels = [], libraryComponents = [];
  let ibisComponent = "", _model = { ...stateModel }, modelSelectors = ibisAmi.models.map(item => item.name);
  if (ibisAmi.components && ibisAmi.components.length === 1) {
    ibisComponent = ibisAmi.components[0];
    const pins = ibisAmi.pins.map(item => {
      const findPin = ibisAmi.diffPins.find(it => it.pin === item.pin || it.invPin === item.pin);
      const modelName = modelSelectors.includes(item.modelName) ? item.modelName : ""
      if (findPin && findPin.pin === item.pin) {
        return { ...item, modelName, isPinDiff: true, nonPin: findPin.pin, invPin: findPin.invPin }
      } else if (findPin && findPin.invPin === item.pin) {
        return { ...item, modelName, isPinDiff: true, nonPin: findPin.pin, invPin: findPin.invPin }
      }
      return { ...item, modelName, isPinDiff: false }
    });

    libraryModels = getCPHYIBISModelList([[...pins]]);
    if (libraryModels.length > 0) {
      const { AInfo = {}, BInfo = {}, CInfo = {} } = libraryModels[0] || {};
      const pinModel = {
        pinA: {
          pin: AInfo.pin,
          pinModel: AInfo.model_name || modelSelectors[0],
          isPinDiff: AInfo.isPinDiff,
          nonPin: AInfo.nonPin,
          invPin: AInfo.invPin
        },
        pinB: {
          pin: BInfo.pin,
          pinModel: BInfo.model_name || modelSelectors[0],
          isPinDiff: BInfo.isPinDiff,
          nonPin: BInfo.nonPin,
          invPin: BInfo.invPin
        },
        pinC: {
          pin: CInfo.pin,
          pinModel: CInfo.model_name || modelSelectors[0],
          isPinDiff: CInfo.isPinDiff,
          nonPin: CInfo.nonPin,
          invPin: CInfo.invPin
        }
      }
      _model.pinModel = pinModel;
    }
    _model.ibisComponent = ibisComponent;
    libraryModels = pins.flat(2);
  }

  libraryComponents = ibisAmi && ibisAmi.components ? [...ibisAmi.components] : [];
  return {
    libraryComponents,
    libraryModels,
    model: _model,
    modelSelectors
  }
}

export {
  AMIDisplaySignals,
  AMIDisplayComp,
  libraryCheck,
  getCompPinBySignal,
  getJittersList,
  getJitterUnit,
  adsConfigItemErrorCheck,
  getTableAmiParameters,
  getBitRate,
  adsConfigErrorCheck,
  getSelectICComps,
  amiParameterValueCheck,
  getJittersUnits,
  getJitterPDFByType,
  setAMIModel,
  getTagsNumber,
  getEQTitleWidth,
  splitEQValue,
  updateTapValuesByNumber,
  getTableAmiParametersValue,
  updateModelCompByDir,
  updateAdsConfigSignals,
  getProbeType,
  getCPHYCompPinBySignal,
  getModelSetupDataList,
  getPinModelDataList,
  getCPHYIBISModelSelect,
  getCPHYIBISAMIModelSelect
}