import { MIL } from "@/services/PreLayout/PreLayoutLibrary";
import { SINGLE, DIFF } from "@/constants/libraryConstants";
import { DDR3, DDR3L, DDR4, LPDDR4, LPDDR5 } from '../constants';
import { CAPACITOR, INDUCTOR, RESISTOR } from '@/services/PreLayout';
import { unitChange } from '../../helper/mathHelper';
import { PACKAGE } from "../../../constants/treeConstants";

export const SCHEMATIC = 'Schematic', MODEL = 'Model',
  FLYBY = 'Fly-by', T = 'T', POINT_TO_POINT = 'Point-to-Point',
  X8 = 'x8', X16 = 'x16', X32 = "x32",
  CONTROLLER = 'Controller', MEMORY = 'Memory';

// config
export const PRELAYOUT = 'prelayout',
  UNIT = 'unit',
  WIDTH = 'width',
  TOPOLOGY = 'topology',
  MEMORYS = 'memory',
  NUMBER_OF_CHANNEL = 'numberOfChannel',
  INCLUDES_RES = 'includeRes';

// config list
export const
  preLayoutTypes = [SCHEMATIC, MODEL],
  topologyType = [FLYBY],
  LPDDRTopologyType = [T, POINT_TO_POINT],
  memoryBus = [X8, X16],
  packageDieBus = [X8, X16, X32];

// columns dataIndex
export const
  BYTE = 'byte',
  NET = 'net',
  INSERTIONLOSS = 'insertionLoss',
  CLK = 'CLK',
  ADR = 'ADR',
  CMD = 'CMD',
  DQS = 'DQS',
  SIGNALSPACING = 'signal_spacing',
  SIGNAL = 'signal';

export const PTR = 'parallel-termination-resistor';

// signals
export const
  BYTE_NETS = ['DQS', 'DQ0', 'DQ1', 'DQ2', 'DQ3', 'DQ4', 'DQ5', 'DQ6', 'DQ7', 'DM'],
  BYTE_PINS = ['DQSP', 'DQSN', 'DQ0', 'DQ1', 'DQ2', 'DQ3', 'DQ4', 'DQ5', 'DQ6', 'DQ7', 'DM'],
  CLK_NETS = ['CLK'],
  CLK_PINS = ['CLKP', 'CLKN'],
  DDR3_ADR = ['A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'A11', 'A12', 'A13'],
  DDR3_CMD = ['BA0', 'BA1', 'BA2', 'RAS', 'CAS', 'WE', 'CS', 'CKE', 'ODT'],
  DDR4_ADR = ['A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'A11', 'A12', 'A13', 'A14', 'A15', 'A16'],
  DDR4_CMD_78 = ['BA0', 'BA1', 'CS', 'CKE', 'ODT', 'BG0', 'BG1', 'ACT'],
  DDR4_CMD_96 = ['BA0', 'BA1', 'CS', 'CKE', 'ODT', 'BG0', 'ACT'],
  LPDDR4_ADR = ['CA0', 'CA1', 'CA2', 'CA3', 'CA4', 'CA5'],
  LPDDR4_CMD = ['CS0', 'CS1', 'CKE0', 'CKE1'],
  RLCList = [CAPACITOR, INDUCTOR, RESISTOR];

// default library file
export const
  Library_constant = {
    "id": "",
    "name": "",
    "fileName": ""
  },
  Microstrip_constant = {
    "LossTangent": "0.005",
    "Thickness": 1.6,
    "Z0": "58.041",
    "Conductivity": "5.959e7",
    "Height": 5,
    "Unit": "mil",
    "Er": "3.5",
    "Width": 7.75,
    "Spacing": 5,
    "type": SINGLE
  },
  Microstrip_DIFF = {
    "Z0": "59.9482",
    "Zdiff": "93.0444",
    "type": DIFF
  },
  Stripline_constant = {
    "LossTangent": "0.005",
    "Thickness": 0.6,
    "Z0": "39.4458",
    "Conductivity": "5.959e7",
    "Height1": 3,
    "Height2": 9.5,
    "Unit": "mil",
    "Er": "4.2",
    "Width": 5.6,
    "Spacing": 5,
    "type": SINGLE
  },
  Stripline_DIFF = {
    "Z0": "40.5935",
    "Zdiff": "71.2907",
    "type": DIFF
  },
  Via_constant = {
    "PlatingThickness": 2,
    "AntiPadDiameter": 40,
    "PadDiameter": 20,
    "ViaToViaPitch": 12.75,
    "Unit": "mil",
    "DrillDiameter": 10,
    "Er": "3.6"
  };

// default library file data 
function getLibraryData(unit) {
  const via_template = getNewUnitData(unit, Via_constant);
  const Via = { ...Library_constant, libraryData: { ...via_template, ViaToViaPitch: "" } };
  const Via_diff = { ...Library_constant, libraryData: { ...via_template } };
  const microstrip_template = getNewUnitData(unit, Microstrip_constant);
  const Microstrip = { ...Library_constant, libraryData: { ...microstrip_template } };
  const Microstrip_diff = { ...Library_constant, libraryData: { ...microstrip_template, ...Microstrip_DIFF } };
  delete Microstrip.libraryData.Spacing;
  const stripline_template = getNewUnitData(unit, Stripline_constant);
  const Stripline = { ...Library_constant, libraryData: { ...stripline_template } };
  const Stripline_diff = { ...Library_constant, libraryData: { ...stripline_template, ...Stripline_DIFF } };
  delete Stripline.libraryData.Spacing;
  return { Via, Via_diff, Microstrip, Microstrip_diff, Stripline, Stripline_diff }
}

function getNewUnitData(unit, data) {
  let newData = {}
  for (let key in data) {
    let value = data[key];
    if (typeof value === 'number') {
      value = unitChange({ num: value, newUnit: unit, decimals: 4 }).number;
    } else if (key === 'Unit') {
      value = unit;
    }
    newData[key] = value.toString();
  }
  return newData;
}

// Signal class
function PreLayoutSignal({ name, index, sections = [], nets }) {
  this.name = name;
  this.index = index;
  this.sections = sections && sections.length === 0 ? [] : sections;
  this.insertionLoss = "";
  this.nets = [nets]; //["net"]
}

// Diff signal class
function PreLayoutDiffSignal({ name, index, sections = [], nets }) {
  this.name = name;
  this.index = index;
  this.sections = sections && sections.length === 0 ? [] : sections;
  this.insertionLoss = "";
  this.nets_N = [`${nets}N`];
  this.nets_P = [`${nets}P`];
}

// pre layout class
function DefaultPreLayout({ type, component }) {
  this.prelayout = SCHEMATIC;
  this.type = type;
  this.unit = MIL;
  this.memory = null;
  this.width = "";
  this.topology = FLYBY;
  this.lib = "";
  this.models = [];
  this.signal_groups = [];
  this.components = component;
}

function DefaultModelPreLayout({ type, component, pcbType }) {
  this.prelayout = MODEL;
  this.type = type;
  this.memory = null;
  this.topology = [LPDDR4, LPDDR5].includes(type) ? POINT_TO_POINT : FLYBY;
  this.models = [];
  this.components = component;
  this.numberOfChannel = '';
  this.width = pcbType === PACKAGE && [LPDDR4, LPDDR5].includes(type) ? X32 : X16;
  this.packageId = "";
  this.includeRes = false;
}

// memory branch select
function findSelectList(type, width) {
  let adrList = [], cmdList = [];
  switch (type) {
    case DDR3:
    case DDR3L:
      adrList = DDR3_ADR.map(item => ({ value: item, label: item }));
      cmdList = DDR3_CMD.map(item => ({ value: item, label: item }));
      break;
    case DDR4:
      adrList = DDR4_ADR.map(item => ({ value: item, label: item }));
      cmdList = (width === X16 ? DDR4_CMD_96 : DDR4_CMD_78).map(item => ({ value: item, label: item }));
      break;
    case LPDDR4:
      adrList = LPDDR4_ADR.map(item => ({ value: item, label: item }));
      cmdList = LPDDR4_CMD.map(item => ({ value: item, label: item }));
      break;
    default: break;
  }

  let list = [{
    value: CLK,
    label: CLK,
    children: [{
      value: 'CLKP',
      label: 'CLKP'
    }, {
      value: 'CLKN',
      label: 'CLKN',
    }]
  }, {
    value: ADR,
    label: ADR,
    children: adrList
  }, {
    value: CMD,
    label: CMD,
    children: cmdList
  }];

  return list;
}

function getTopologyType(type) {
  if ([LPDDR4, LPDDR5].includes(type)) {
    return [POINT_TO_POINT/* , T */]
  }
  return [FLYBY]
}

function getDefaultNumberChannel(type, pcbType, width) {
  if ([LPDDR4, LPDDR5].includes(type) && pcbType === PACKAGE && width === X16) {
    return 1;
  }
  if ([LPDDR4, LPDDR5].includes(type)) {
    return 2
  }
  return 1
}

export const
  DDR_PIN = {
    DDR3_x8_PIN: {
      DQ0: "B3",
      DQ1: "C7",
      DQ2: "C2",
      DQ3: "C8",
      DQ4: "E3",
      DQ5: "E8",
      DQ6: "D2",
      DQ7: "E7",
      DQSP0: "C3", // DQSP
      DQSN0: "D3", // DQSN
      DM0: "B7",
      CLKP: "F7",
      CLKN: "G7",
      A0: "K3",
      A1: "L7",
      A2: "L3",
      A3: "K2",
      A4: "L8",
      A5: "L2",
      A6: "M8",
      A7: "M2",
      A8: "N8",
      A9: "M3",
      A10: "H7",
      A11: "M7",
      A12: "K7",
      A13: "N3",
      BA0: "J2",
      BA1: "K8",
      BA2: "J3",
      RAS: "F3",
      CAS: "G3",
      WE: "H3",
      CS: "H2",
      CKE: "G9",
      ODT: "G1",
    },
    DDR3_x16_PIN: {
      DQ0: "E3",
      DQ1: "F7",
      DQ2: "F2",
      DQ3: "F8",
      DQ4: "H3",
      DQ5: "H8",
      DQ6: "G2",
      DQ7: "H7",
      DQSP0: "F3", // DPS0P
      DQSN0: "G3", // DQS0N
      DM0: "E7",
      DQ8: "D7",
      DQ9: "C3",
      DQ10: "C8",
      DQ11: "C2",
      DQ12: "A7",
      DQ13: "A2",
      DQ14: "B8",
      DQ15: "A3",
      DQSP1: "C7", // DQS1P
      DQSN1: "B7", // DQS1N
      DM1: "D3",
      CLKP: "J7",
      CLKN: "K7",
      A0: "N3",
      A1: "P7",
      A2: "P3",
      A3: "N2",
      A4: "P8",
      A5: "P2",
      A6: "R8",
      A7: "R2",
      A8: "T8",
      A9: "R3",
      A10: "L7",
      A11: "R7",
      A12: "N7",
      A13: "T3",
      BA0: "M2",
      BA1: "N8",
      BA2: "M3",
      RAS: "J3",
      CAS: "K3",
      WE: "L3",
      CS: "L2",
      CKE: "K9",
      ODT: "K1",
    },
    DDR4_x8_PIN: {
      DQ0: "C2",
      DQ1: "B7",
      DQ2: "D3",
      DQ3: "D7",
      DQ4: "D2",
      DQ5: "D8",
      DQ6: "E3",
      DQ7: "E7",
      DQSP0: "C3", // DQSP
      DQSN0: "B3", // DQSN
      DM0: "A7",
      CLKP: "F7",
      CLKN: "F8",
      A0: "L3",
      A1: "L7",
      A2: "M3",
      A3: "K7",
      A4: "K3",
      A5: "L8",
      A6: "L2",
      A7: "M8",
      A8: "M2",
      A9: "M7",
      A10: "J3",
      A11: "N2",
      A12: "J7",
      A13: "N8",
      A14: "H2",
      A15: "H7",
      A16: "H8",
      BA0: "K2",
      BA1: "K8",
      CS: "G7",
      CKE: "G3",
      ODT: "F3",
      BG0: "J2",
      BG1: "J8",
      ACT: "H3"
    },
    DDR4_x16_PIN: {
      DQ0: "G2",
      DQ1: "F7",
      DQ2: "H3",
      DQ3: "H7",
      DQ4: "H2",
      DQ5: "H8",
      DQ6: "J3",
      DQ7: "J7",
      DQSP0: "G3", // DQS0P
      DQSN0: "F3", // DQS0N
      DM0: "E7",
      DQ8: "A3",
      DQ9: "B8",
      DQ10: "C3",
      DQ11: "C7",
      DQ12: "C2",
      DQ13: "C8",
      DQ14: "D3",
      DQ15: "D7",
      DQSP1: "B7", // DQS1P
      DQSN1: "A7", // DQS1N
      DM1: "E2",
      CLKP: "K7",
      CLKN: "K8",
      A0: "P3",
      A1: "P7",
      A2: "R3",
      A3: "N7",
      A4: "N3",
      A5: "P8",
      A6: "P2",
      A7: "R8",
      A8: "R2",
      A9: "R7",
      A10: "M3",
      A11: "T2",
      A12: "M7",
      A13: "T8",
      A14: "L2",
      A15: "M8",
      A16: "L8",
      BA0: "N2",
      BA1: "N8",
      CS: "L7",
      CKE: "K2",
      ODT: "K3",
      BG0: "M2",
      ACT: "L3"
    }
  }

export {
  DefaultPreLayout,
  PreLayoutSignal,
  findSelectList,
  PreLayoutDiffSignal,
  getLibraryData,
  getTopologyType,
  getDefaultNumberChannel,
  DefaultModelPreLayout
}