import React, { Component } from 'react';
import AutoGenerationPort from './AutoGenerationPort';
import { GAP, getPCBComponentPinList, WAVE, checkPortSetups } from '../../services/ExtractionPortsHelper';
import PortsTable from './PortsTable';
import DelConfirm from '@/components/DelConfirm';
import { ANDES_V2 } from '../../constants/pageType';
import { EXTRACTION_OPTIONS } from '../../services/TopBarHelper';
import '../../publicCss/aurora.css';
import './index.css';

class ExtractionPorts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmVisible: false,
      renderPortType: null,
      settingHeight: props.product === ANDES_V2 ? 486 : 400,
      isClose: false
    }
  }

  componentWillMount = () => {
    const { designId, DesignInfo } = this.props;
    this.pcbInfo = DesignInfo.getPCBInfo(designId) || {};
    this.pcbComponentList = getPCBComponentPinList({ layers: this.pcbInfo.layers || [] });
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { ports_generate_setup_list: setupList } = this.props;
    setTimeout(() => {
      this.setState({
        renderPortType: setupList && setupList[0] ? setupList[0].setup.portType : null
      }, () => {
        this.setContentHeight();
      })
    }, 50)
  }

  updatePortRenderValue = () => {
    const { designId, DesignInfo } = this.props;
    this.pcbInfo = DesignInfo.getPCBInfo(designId) || {};
    this.pcbComponentList = getPCBComponentPinList({ layers: this.pcbInfo.layers || [] });
    const { ports_generate_setup_list: setupList } = this.props;
    if (this.generateRef) {
      this.generateRef.updateAutoPortInfo()
    }
    setTimeout(() => {
      this.setState({
        renderPortType: setupList && setupList[0] ? setupList[0].setup.portType : null
      }, () => {
        this.setContentHeight();
      })
    }, 50)
  }

  savePorts = (type) => {
    this.setState({
      saveType: type
    });
    if (this.generateRef && this.generateRef.judgeErrorMsgExist()) {
      return /* true */;
    }

    if (this.generateRef && this.generateRef.judgeIfUpdate()) {
      this.setState({
        confirmVisible: true
      });
      return true;
    }
    const errors = checkPortSetups(this.props.port_setups);
    if (errors && errors.length) {
      this.generateRef.updateErrorList(true, errors);
      return;
    }
    type === "close" && this.props.closePanel();
  }

  onRef = (ref) => {
    this.generateRef = ref;
  }

  onFix = () => {
    this.setState({
      confirmVisible: false
    });
    this.generateRef.generatePort(this.state.saveType);
    if (!this.state.saveType && this.props.updateCurrent) {
      this.props.updateCurrent()
    }
  }

  close = () => {
    this.setState({
      confirmVisible: false
    })
    const { saveType } = this.state;
    this.changeSetupBySaveType(saveType);
    if (!saveType && this.props.updateCurrent) {
      this.props.updateCurrent()
    }
  }

  changeSetupBySaveType = (saveType) => {
    switch (saveType) {
      case "close":
        this.props.closePanel();
        break;
      case "changePage":
        this.props.changePage(EXTRACTION_OPTIONS);
        break;
      case "modeling":
        this.props.startModeling();
        break;
      case "changePCB":
        this.props.updateSetupBySaveType && this.props.updateSetupBySaveType();
        break;
      default: break;
    }
  }

  updateRenderPortType = (renderPortType) => {
    this.setState({
      renderPortType
    })
  }

  setContentHeight = () => {
    const { renderPortType } = this.state;
    const { product, height: prevHeight } = this.props;
    const defaultHeight = [GAP, WAVE].includes(renderPortType) ? 450 : 400;
    //set panel height
    let content = document.getElementById(`extraction-ports-reference-main`);
    const _settingHeight = product === ANDES_V2 ? 180 : 130;
    let settingHeight = content ? content.offsetHeight + _settingHeight : (prevHeight || defaultHeight);
    this.setState({
      settingHeight
    })
  }

  _updatePortSetups = (port_setups, portsSetupList, saveType) => {
    this.generateRef.updateErrorList && this.generateRef.updateErrorList(false, null);
    this.props.updatePortSetups(port_setups, portsSetupList, saveType);
  }

  render() {
    const { confirmVisible, renderPortType, settingHeight } = this.state;
    const { referenceNets, port_setups, designId, DesignInfo, maxHeight, loadingPCB } = this.props;
    return (
      <div className='extraction-ports-content' id="extraction-ports-content-id">
        {/* TODO */}
        {/* <div className='extraction-port-button'>
            <div className='ports-component-pin-add-content'>
              <span>Add Port</span>
              <Icon type="plus-square" onClick={(e) => this.addComponentPinPort(e)} />
            </div>
          </div> */}
        <AutoGenerationPort
          {...this.props}
          onRef={this.onRef}
          renderPortType={renderPortType}
          port_setups={port_setups}
          designId={designId}
          DesignInfo={DesignInfo}
          updateZ0={this.updateZ0}
          z0OnBlur={this.z0OnBlur}
          loadingPCB={loadingPCB}
          updateReferenceNets={this.props.updateReferenceNets}
          updatePortSetups={this._updatePortSetups}
          referenceNets={referenceNets}
          updateGenerateRefNets={this.props.updateGenerateRefNets}
          updateRenderPortType={this.updateRenderPortType}
          setContentHeight={this.setContentHeight}
          applyRockyPortSetup={this.props.applyRockyPortSetup}
        />
        <PortsTable
          {...this.props}
          scroll={{ y: (maxHeight - settingHeight) > 60 ? (maxHeight - settingHeight) : 60 }}
          referenceNets={referenceNets}
          pcbComponentList={this.pcbComponentList}
          pcbInfo={this.pcbInfo}
          port_setups={port_setups}
          updatePortSetups={this._updatePortSetups}
        />
        {confirmVisible ? <DelConfirm
          type="close"
          closePanel={this.close}
          maskStyle={true}
          onFix={this.onFix}
          confirmButton="Update"
          message={"Port Setups modified, whether to update ports?"}
        /> : null}
      </div>
    )
  }
}

export default ExtractionPorts;