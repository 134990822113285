class SerdesConfig {
  constructor(name = '') {
    this.Name = name;
    this.SerdesType = {
      type: 'PCIe',
      version: '',
      datarate: ''
    };
    this.Components = [];
    this.RefNets = [];
    this.NetPins = [];
    this.Signals = [];
    this.DiffPairs = [];
    this.Groups = [];
  }
}

export function Component(component, { type, part, value }) {
  this.component_name = component;
  this.type = type || '';
  this.part_name = part || '';
  this.value = value || '';
  this.pkg = null;
}

export function refNets(nets) {
  this.nets = nets || [];
}

export function NetPins(net) {
  this.net_name = net || '';
  this.pins = [];
}

NetPins.prototype.addPins = function (pins) {
  this.pins.push(...pins.map(item => new Pin(item)))
}

function Pin({ mCompName, mPinNum }) {
  this.component_name = mCompName;
  if (isString(mPinNum)) {
    this.pin_number = [mPinNum];
  } else if (isArray(mPinNum)) {
    this.pin_number = mPinNum;
  }
}

export function Signal({ name, dir, nets }) {
  this.signal_name = name;
  this.nets = nets || [];
  this.DIR = dir || '';
}

export function DiffPair({ name, positive, negative }) {
  this.pair_name = name || '';
  this.positive = positive || '';
  this.negative = negative || '';
}

export function Group({ ID, Pairs }) {
  this.ID = ID;
  this.Pairs = Pairs || [];
}

function isString(a) {
  return toString.call(a) === '[object String]';
}

function isArray(b) {
  if (Array.isArray) {
    return Array.isArray(b);
  } else {
    return toString.call(b) === "[object Array]";
  }
}


export default SerdesConfig

