const PROJECT_TYPE = 'ROCKY';

/* Multiple byte*/
export const GET_MULTIPLE_CHANNEL_CONTENT = `${PROJECT_TYPE}/get_multiple_channel_content`;
export const RUN_MULTIPLE_BYTE = `${PROJECT_TYPE}/run_multiple_byte`;
export const UPDATE_MULTIPLE_LIST = `${PROJECT_TYPE}/update_multiple_list`;
export const UPDATE_MULTIPLE_SELECT_VERIFICATION = `${PROJECT_TYPE}/update_multiple_select_verification`;
export const OPEN_MULTIPLE_RESULT_CONTENT = `${PROJECT_TYPE}/open_multiple_result_content`;
export const DELETE_INTERFACES = `${PROJECT_TYPE}/delete_interfaces`;
export const AUTO_MULTIPLE_GET_VERIFICATIONS = `${PROJECT_TYPE}/auto_multiple_get_verifications`;
export const UPDATE_MULTIPLE_STATUS = `${PROJECT_TYPE}/update_multiple_status`;
export const UPDATE_RESULT_MENU_STATUS = `${PROJECT_TYPE}/update_result_menu_status`;