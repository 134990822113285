import { getSierraPreLayout } from "../SierraCtrl";
import { SCHEMATIC } from "./prelayoutConstants";

class PreLayoutData {
  constructor(props) {
    this.store = new Map();
  }

  getLocalPrelayout = (designId) => {
    let prelayout = this.store.get(designId);
    return prelayout || null;
  }

  getPreLayout = async (designId) => {
    let prelayout = this.store.get(designId);
    if (!prelayout) {
      prelayout = await getSierraPreLayout(designId);
      if (prelayout) {
        prelayout.id = designId;
      }
      this.savePreLayout(designId, prelayout);
    }
    return prelayout;
  }

  savePreLayout = (designId, data) => {
    this.store.set(designId, data)
  }

  getNets = (designId) => {
    let prelayout = this.store.get(designId);
    if (!prelayout || !prelayout.content) return;

    const components = prelayout.content.components || [];
    const pinNets = components.map(item => item.pins.map(pin => pin.net)).flat(2);

    return [...new Set(pinNets)];
  }

  isSchematic = (designId) => {
    let prelayout = this.store.get(designId);
    if (prelayout && prelayout.content && prelayout.content.prelayout === SCHEMATIC) {
      return true;
    }
    return false;
  }
}

const preLayoutData = new PreLayoutData();

export default preLayoutData;