import React, { Component, Fragment } from 'react';
import { Select, Button } from 'antd';
import { createPortal } from 'react-dom';
import Panel from '../../../../components/Panel';
import _LayoutData from '../../../../services/data/LayoutData';
import { getComponents } from '../../../../services/helper/setup/setupData';
import { FASTPI } from '../../../../constants/pageType';
import { IGNORE } from '../../../../constants/componentType';
import './index.css';

const Option = Select.Option;
class VRMSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vrms: [],
      selected: props.specVRM
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const { pcbId, COMP_PREFIX_LIB } = this.props;
    const layout = _LayoutData.getLayout(pcbId);
    const _netList = layout && layout.mNetManager ? layout.mNetManager.GetAllNets().mValues : [];
    const { Components = [] } = getComponents(_netList, pcbId, COMP_PREFIX_LIB, FASTPI);
    const ignores = Components.filter(item => item.usage === IGNORE)
    this.setState({
      vrms: ignores.map(item => item.name).sort()
    })
  }

  closeModal = () => {
    this.props.closeModal()
  }

  changeSelect = (value) => {
    this.setState({
      selected: value
    })
  }

  traceBackVRM = (e) => {
    e && e.stopPropagation();
    const { selected } = this.state;
    this.props.traceBackVRM(selected);
    this.closeModal()
  }

  render() {
    const { vrms, selected } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='pdn-vrm-setting-panel'
          title='Specify VRM'
          onCancel={this.closeModal}
          zIndex={2000}
          width={400}
          position='panel-center-left'
          draggable
          minHeight={220}
          minWidth={400}
        >
          <div className='pdn-vrm-setting-content'>
            <div className='pdn-vrm-setting-item'>
              <span className='pdn-vrm-setting-title'>VRM</span>
              <Select
                onChange={(value) => this.changeSelect(value)}
                value={selected}
                dropdownStyle={{ zIndex: 100000 }}
                mode='multiple'
                showSearch={true}
                className='pdn-vrm-setting-select'
                size='small'
              >
                {vrms.map(item => <Option title={item} key={item} value={item}>{item}</Option>)}
              </Select>
              <Button type="primary" size="small" className='pdn-vrm-setting-button' onClick={this.traceBackVRM}>Trace</Button>
            </div>
          </div>
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default VRMSetting;