import React, { PureComponent } from "react";
import { Button, Tooltip } from "antd";
import SelectionPanel from './SelectionPanel';
import '../index'
import '../Selection.css'

class Selection extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  onCreatePanel = () => {
    const { displayBoxShow } = this.props;
    this.props.changeDisplaySelected(!displayBoxShow)
  }

  panelClose = () => {
    this.props.changeDisplaySelected(false)
  }

  visibleChange = (visible) => {
    if (this.props.displayBoxShow) {
      visible = false;
    }
    this.setState({
      visible: visible,
    })
  }

  render() {
    const { width, leftWidth, displayBoxShow } = this.props;
    return (
      <div className='selectionButton'>
        <div>
          <Tooltip
            title='Selected For Display'
            overlayClassName='highlightobjects'
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            open={this.state.visible}
            onOpenChange={(visible) => { this.visibleChange(visible) }}
          // placement="leftBottom"
          >
            <Button
              placeholder='selection'
              className='selectionBtn'
              onClick={this.onCreatePanel}
              size='small'
              type='primary'
            >Selected</Button>
          </Tooltip>
        </div>
        {displayBoxShow && <SelectionPanel cancel={this.panelClose} width={width} leftWidth={leftWidth} />}
      </div>
    )
  }
}

export default Selection; 
