import StringList from '../utility/StringList';

var CeLayerBase = function (type) {
  this.mName = "Unknown";
  this.mID = -1;
  this.mType = type;
};

CeLayerBase.CeLayerType = {
  SIGNAL: "SIGNAL",
  POWER_GROUND: "POWER_GROUND",
  MIXED: "MIXED",
  COMPONENT: "COMPONENT",
  SILK_SCREEN: "SILK_SCREEN",
  SOLDER_PASTE: "SOLDER_PASTE",
  SOLDER_MASK: "SOLDER_MASK",
  DOCUMENT: "DOCUMENT",
  Unknown: "Unknown",
  isMetalLayer: function (layerType) {
    return layerType == this.SIGNAL || layerType == this.POWER_GROUND || layerType == this.MIXED;
  }
};

CeLayerBase.prototype.GetName = function () {
  return this.mName;
};

CeLayerBase.prototype.GetID = function () {
  return this.mID;
};

CeLayerBase.prototype.GetType = function () {
  return this.mType;
};

CeLayerBase.prototype.SetType = function (type) {
  this.mType = type;
}

CeLayerBase.prototype.SetName = function (name) {
  this.mName = name;
}

CeLayerBase.prototype.SetID = function (id) {
  this.mID = id;
}

CeLayerBase.prototype.WriteIntoIODataNode = function (layerBlock) {
  layerBlock.AddDataItem("Type", this.mType);
  var nameID = new StringList();
  nameID.add(this.mName);
  nameID.add(this.mID);
  layerBlock.AddDataItem("NameID", nameID);
}

CeLayerBase.prototype.ReadFromIODataNode = function (layerBlock) {
  this.mType = layerBlock.GetItemString("Type", CeLayerBase.CeLayerType.Unknown.toString());
  var defData = layerBlock.GetItemStringList("NameID");
  if (defData.size() >= 2) {
    this.mName = defData.get(0);
    this.mID = parseInt(defData.get(1), 10);
  }
}

export default CeLayerBase;