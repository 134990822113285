import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AndesTree from './andesTree';
import {
  LIBRARY,
  LIBRARY_DATA,
  PROJECT,
  PCB,
  PROJECTS,
  PCB_CHANNEL,
  LIBRARY_FILE,
  END_TO_END_CHANNEL,
  TRASH,
  LIBRARY_FOLDER,
  LIBRARY_FOLDER_FILE,
  PACKAGE
} from '@/constants/treeConstants';
import LibraryPanel from '@/components/PreLayoutLibrary';
import {
  updateLibraryMenu,
  deleteLibrary,
  updateLibraryData,
  addLibraryToCache,
  afterImportLibrary,
  openLibraryFolder
} from '../store/library/action';
import { getLibraryListByType } from '@/services/PreLayout/PreLayoutLibrary';
import {
  updateProjectMenu,
  deletePCB,
  deleteProject,
  addProject,
  updateExpand,
  updateTrash,
  clearTrash,
  clearCurrentProject,
  updateViewList,
} from '../store/project/action';
import {
  deleteChannel
} from '../store/channel/action';
import {
  deleteEndToEndChannel
} from '../store/endToEndChannel/action';
import { closeTabFooter } from '../../tabMonitor/action';
import { getDefaultName } from '@/services/helper/setDefaultName';
import libraryConstructor from '../../../services/Andes_v2/library/libraryConstructor';
import { calculateImpedance, getLibraryDetail, getFileContent, createLibrary } from '@/services/Andes_v2/library';
import { ANDES_V2 } from '../../../constants/pageType';
import LibraryUploadPanel from '../../../components/LibraryUpload';
import FileContentPanel from "../../../components/LibraryUpload/FileContentPanel";
import { BUFFER_SPICE, CPHY_EYE_MASK, EYE_MASK, HFSS_OPTIONS, SIWAVE_OPTIONS, SPICE, TRACE, VIA } from '../../../constants/libraryConstants';
import { strDelimited } from '../../../services/helper/split';
import { getLibraryAccept, getLibraryTitleSuffix, getLibrarySpecial, getLibraryTitle } from '../../../services/Library';
import MaskPanel from './eyeMask'
import ImportExportPanel from '@/components/ImportExport';
import projectConstructor from '../../../services/Andes_v2/project/projectConstructor';
import { getProjectChildren } from '../../../services/Andes_v2/project/projectCtrl';
import { getPanelLibraryList } from '../../../services/Andes_v2/library/libraryHelper';
import { addExperiment, deleteExperiment } from '../store/sweep/action';
import { EXPERIMENTS } from '../../../constants/treeConstants';

class AndesSider extends Component {
  constructor(props) {
    super(props);
    this.libraryInfo = {
      modelType: null, //trace/via
      modelName: null, //Trace Template / Via Template
      library: null, //{ id, name}
      eyeMaskDisplayInfo: '',
    }
    this.eyeMaskDisplayInfo = {
      type: null,
      display: false,
      eyeMaskType: null // eye_mask/cphy_eye_mask
    }
    this.state = {
      editVisible: false,// open/close panel
      uploading: {
        key: '',
        status: false
      },
      importExportModelVisible: true
    }
  }

  componentDidMount = () => {
    this.props._updateLibraryMenu(true);
    this.props._updateTrashMenu();
    const { expandedKeys } = this.props;
    //if open project exist
    if (expandedKeys.length > 2) {
      expandedKeys.forEach(item => {
        const [key, id] = strDelimited(item, "-");
        if (key === 'project') {
          this.props._updateProjectMenu({ firstLoad: true, openProjectId: id });
        }
      });
    } else {
      this.props._updateProjectMenu({ firstLoad: true });
    }
  }

  addItem = (itemData, type) => {
    const { dataType, key } = itemData;
    switch (dataType) {
      case LIBRARY:
        this.addLibrary(key, itemData.name, type);
        break;
      case PROJECTS:
        this.props._addProject();
        break;
      case PCB_CHANNEL:
        this.props._addExperiment(itemData);
        break;
      default: break;
    };
  }

  delItem = (itemData) => {
    const { dataType } = itemData;
    switch (dataType) {
      case LIBRARY_DATA:
      case LIBRARY_FILE:
      case LIBRARY_FOLDER:
        this.props._deleteLibrary(itemData);
        break;
      case PCB:
      case PACKAGE:
        this.props._deletePCB(itemData);
        break;
      case PROJECT:
        this.props._deleteProject(itemData);
        break;
      case PCB_CHANNEL:
        this.props._deleteChannel(itemData);
        break;
      case END_TO_END_CHANNEL:
        this.props._deleteEndToEndChannel(itemData);
        break;
      case TRASH:
        this.props._clearTrash();
        break;
      case EXPERIMENTS:
        this.props._deleteExperiment(itemData);
        break;
      default: break;
    }
  }

  editItem = (e, itemData) => {
    e.stopPropagation();
    switch (itemData.dataType) {
      case LIBRARY_DATA:
        this.editLibrary(itemData);
        break;
      case LIBRARY_FILE:
        this.getLibraryFileContent(itemData);
        break;
      case LIBRARY_FOLDER_FILE:
        this.getLibraryFileContent(itemData, LIBRARY_FOLDER_FILE);
        break;
      case LIBRARY_FOLDER:
        this.props._openLibraryFolder(itemData, itemData.type);
        break;
      default: return;
    };
  }

  addLibrary = (key, name, type = "") => {
    //key trace/via
    const { libraryList, libraryName } = getLibraryListByType(key, libraryConstructor);
    const defaultLibraryName = getDefaultName({ nameList: libraryList, defaultKey: libraryName });

    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: key,
      modelName: name,
      defaultLibraryName
    }
    if ([EYE_MASK, CPHY_EYE_MASK].includes(key)) {
      this.eyeMaskDisplayInfo = {
        type: type.startsWith('create') ? 'Json' : null,
        display: type.startsWith('create') ? true : false,
        eyeMaskType: type === 'create_cphy' ? CPHY_EYE_MASK : EYE_MASK,
      }
    }
  }

  editLibrary = (itemData) => {
    const { modelName } = getLibraryListByType(itemData.type, libraryConstructor);
    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: itemData.type,
      modelName,
      library: {
        id: itemData.id,
        name: itemData.name
      }
    }
    if ([EYE_MASK, CPHY_EYE_MASK].includes(itemData.type)) {
      this.eyeMaskDisplayInfo = {
        type: 'data',
        display: true,
        eyeMaskType: itemData.type
      }
    }
  }

  getLibraryFileContent = (itemData, type) => {
    const { modelName } = getLibraryListByType(itemData.type, libraryConstructor);
    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: itemData.type,
      modelName,
      library: {
        id: itemData.id,
        name: itemData.name,
        type
      }
    }
    if ([EYE_MASK, CPHY_EYE_MASK].includes(itemData.type)) {
      this.eyeMaskDisplayInfo = {
        type: itemData.format,
        display: true,
        eyeMaskType: itemData.type
      }
    }
  }

  importExportData = (item) => {
    this.setState({
      uploading: {
        key: item.key,
        status: this.state.uploading.status
      }
    })
    let modelType = 'importExportLibrary';
    if (item.dataType === PROJECTS) {
      modelType = 'importExportProject'
    }
    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: modelType,
      modelName: modelType,
      updateLibrary: {}
    };
  }

  changeUploading = (status) => {
    this.setState({
      uploading: {
        key: this.state.uploading.key,
        status
      }
    })
  }

  showImportExportModel = (visible) => {
    this.setState({
      importExportModelVisible: visible
    })
  }

  getPanel = () => {
    const { library, modelType, defaultLibraryName } = this.libraryInfo;
    const { expandedKeys, _updateLibraryMenu, _updateExpand, _updateProjectMenu, _closeTabFooter, _changeViewList, _afterImportLibrary, _clearCurrentProject } = this.props;
    const { type, display, eyeMaskType } = this.eyeMaskDisplayInfo;
    const libraryList = libraryConstructor.getLibraryValues(modelType);
    const title = getLibraryTitle(modelType, ANDES_V2);
    const suffix = getLibraryTitleSuffix(modelType);
    const libraryId = library ? library.id : null;
    if ([TRACE, VIA].includes(modelType)) {
      return <LibraryPanel
        product={ANDES_V2}
        {...this.libraryInfo}
        closeLibraryPanel={this._closeLibraryPanel}
        calculateImpedance={calculateImpedance}
        _updateLibraryData={this.props._updateLibraryData}
        getLibraryDetail={getLibraryDetail}
        libraryList={libraryList}
        libraryId={libraryId}
      />
    }

    if ([EYE_MASK, CPHY_EYE_MASK].includes(modelType) && display) {
      return <MaskPanel
        title={`Eye Mask`}
        closePanel={this._closeLibraryPanel}
        libraryId={libraryId}
        name={library && library.name}
        modelType={modelType}
        type={type}
        eyeMaskType={eyeMaskType}
        defaultLibraryName={defaultLibraryName}
        _updateLibraryData={this.props._updateLibraryData}
        getLibraryDetail={getLibraryDetail}
      />
    }

    if (libraryId) {
      return <FileContentPanel
        title={`${title} - ${library.name}`}
        getLibraryFileContent={getFileContent}
        closeModal={this._closeLibraryPanel}
        libraryId={libraryId}
        fileName={library && library.type === LIBRARY_FOLDER_FILE ? library.name : null}
        modelType={modelType}
      />
    }

    if (modelType === 'importExportLibrary' || modelType === 'importExportProject') {
      return <ImportExportPanel
        product={ANDES_V2}
        title={modelType === 'importExportLibrary' ? 'Library' : 'Project'}
        importExportModelVisible={this.state.importExportModelVisible}
        projectList={projectConstructor.getProjectValues()}
        updateProjectMenu={_updateProjectMenu}
        clearCurrentProject={_clearCurrentProject}
        libraryId={libraryId}
        libraryList={getPanelLibraryList()}
        updateLibraryMenu={_updateLibraryMenu}
        closeModal={this._closeLibraryPanel}
        closeTabFooter={_closeTabFooter}
        afterImportLibrary={_afterImportLibrary}
        changeViewList={_changeViewList}
        changeUploading={this.changeUploading}
        showImportExportModel={this.showImportExportModel}
        // createChildren={createChildren}
        getProjectChildren={getProjectChildren}
      />
    }

    return <LibraryUploadPanel
      {...this.libraryInfo}
      uploadTitle={title.match("Netlist") ? `Import ${title}` : `Import ${title} ${suffix}`}
      title={title}
      product={ANDES_V2}
      importText={suffix}
      accept={getLibraryAccept(modelType)}
      special={getLibrarySpecial(modelType, ANDES_V2)}
      libraryList={libraryList}
      expandedKeys={expandedKeys}
      createLibrary={createLibrary}
      closeLibraryPanel={this._closeLibraryPanel}
      updateLibraryMenu={_updateLibraryMenu}
      expandMenu={_updateExpand}
      checkDisplay={[HFSS_OPTIONS, SIWAVE_OPTIONS].includes(modelType) ? true : false}
      allowMulti={[HFSS_OPTIONS, SIWAVE_OPTIONS].includes(modelType) ? false : true}
    />
  }

  _closeLibraryPanel = (fileList, modelType) => {
    this.setState({
      editVisible: false
    })
    if ([BUFFER_SPICE, SPICE].includes(modelType)) {
      this.props._openLibraryFolder(null, this.libraryInfo.modelType, fileList);
    }
    this.libraryInfo = {
      defaultLibraryName: null,
      modelType: null,
      modelName: null,
      library: null
    }

    //save new files to cache
    if (fileList && fileList.length > 0) {
      this.props._addLibraryToCache({ modelType, fileList });
    }
  }

  render() {
    const { editVisible, modelType, uploading } = this.state;
    return (
      <Fragment>
        <AndesTree
          actions={this.actions}
          uploading={uploading}
        />
        {editVisible && this.getPanel(modelType)}
      </Fragment>
    )
  }

  actions = {
    addItem: this.addItem,
    delItem: this.delItem,
    editItem: this.editItem,
    importExportData: this.importExportData,
    showImportExportModel: this.showImportExportModel
  }
}


const mapState = (state) => {
  const { AndesV2Reducer: { project } } = state;
  const { openProjectId, viewList, expandedKeys } = project;
  return {
    openProjectId,
    viewList,
    expandedKeys
  }
}

const mapDispatch = (dispatch) => ({
  _updateLibraryMenu(firstLoad) {
    dispatch(updateLibraryMenu(firstLoad))
  },
  _updateProjectMenu(data) {
    dispatch(updateProjectMenu(data))
  },
  _deleteLibrary(data) {
    dispatch(deleteLibrary(data))
  },
  _deletePCB(data) {
    dispatch(deletePCB(data))
  },
  _deleteProject(data) {
    dispatch(deleteProject(data))
  },
  _addProject() {
    dispatch(addProject())
  },
  _addExperiment(itemData) {
    dispatch(addExperiment(itemData))
  },
  _deleteExperiment(data) {
    dispatch(deleteExperiment(data));
  },
  _deleteChannel(data) {
    dispatch(deleteChannel(data))
  },
  _updateLibraryData(libraryType, data) {
    dispatch(updateLibraryData(libraryType, data))
  },
  _deleteEndToEndChannel(data) {
    dispatch(deleteEndToEndChannel(data))
  },
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _updateTrashMenu() {
    dispatch(updateTrash())
  },
  _clearTrash() {
    dispatch(clearTrash())
  },
  _addLibraryToCache({ modelType, fileList }) {
    dispatch(addLibraryToCache({ modelType, fileList }))
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  },
  _clearCurrentProject() {
    dispatch(clearCurrentProject())
  },
  _changeViewList(viewList) {
    dispatch(updateViewList(viewList))
  },
  _afterImportLibrary() {
    dispatch(afterImportLibrary())
  },
  _openLibraryFolder(itemData, libraryType, filesInfo) {
    dispatch(openLibraryFolder(itemData, libraryType, filesInfo))
  }
});

export default connect(mapState, mapDispatch)(AndesSider);