import hash from 'object-hash';
import { getDefaultName } from '../../../../../helper/setDefaultName';
import { parseEyeParamJson } from '../eyeParam';

class CustomizedEyeConstructor {
  constructor(params) {
    this.eyeConfig = new EyeConfig(params);//currentConfig :{ plot:{ signals,location }, plot_config:{ type, ... } }
    this.eyeResultList = []; // eyeResultList:[ { name, hashId, eyeConfig, result:[read, write(memory) ] }] 
    //result: byte: [{write,pic,margin}, {read}]
    //result cmd /adr [{{componentName1},{componentName2},...}]
  }

  updateEyeParamConfig(config) {
    if (!config) {
      this.eyeConfig = new EyeConfig({});
    } else {
      const plot = config.plot;
      const plot_config = config.plot_config;
      this.eyeConfig = new EyeConfig({ ...config, ...plot, ...plot_config }); // eye diagram config
    }
  };

  updateEyeParamResult({ eyeMode, pic, margin, pngParam, eyeConfig, interfaceSignals }) {
    if (!this.eyeResultList) {
      this.eyeResultList = [];
    }

    const hashId = hash(eyeConfig);
    const signals = eyeConfig.plot.signals;
    //find all selected signals nets
    const filterSignals = interfaceSignals.filter(item => signals.includes(item.name));
    let signalNets = [];
    filterSignals.forEach(item => {
      signalNets = [...new Set([...signalNets, ...item.nets])];
    });
    const location = eyeConfig.plot.location;
    const signal = signals.join(", ");
    //find exist hashId
    let index = this.eyeResultList.findIndex(item => item.hashId === hashId);
    let name = '';
    if (index > -1) {
      name = this.eyeResultList[index].name;
    } else {
      name = getDefaultName({
        nameList: this.eyeResultList,
        defaultKey: `${signal}(${location})`,
        firstIndex: 1
      });
    }
    //parse margin
    const marginData = parseEyeParamJson(name, margin, pngParam);
    if (index > -1) {
      const picIndex = this.eyeResultList[index].result.findIndex(it => it.eyeMode === eyeMode);
      //if nets not exist, add signalNets
      if (!this.eyeResultList[index].signalNets || !this.eyeResultList[index].signalNets.length) {
        this.eyeResultList[index].signalNets = signalNets;
      }
      //parse margin
      if (picIndex > -1) {
        this.eyeResultList[index].result[picIndex].pic = pic;
        this.eyeResultList[index].result[picIndex].margin = marginData;
      } else {
        this.eyeResultList[index].result.push({
          eyeMode,
          pic,
          margin: marginData
        })
      }
    } else {
      this.eyeResultList.push({
        name,
        eyeConfig,
        hashId,
        result: [{
          eyeMode,
          pic,
          margin: marginData
        }],
        signalNets
      })
    }
  }
};

class EyeConfig {
  constructor({
    signals = [],
    components = [],
    location = "BGA",
    time_scale = "1e9",
    plot_clk = false,
    diff_clk = true,
    type = "line_with_colormap",
    v_min = "",
    v_max = "",
    background_color = "black" }) {
    this.plot = {
      signals,
      location // BGA / Die
    };
    this.plot_config = new plotConfig({ time_scale, plot_clk, diff_clk, type, v_min, v_max, background_color });
    this.components = components;
  }
}

class plotConfig {
  constructor({ time_scale = "1e9", plot_clk = false, diff_clk = true, type = "line_with_colormap", v_min = "", v_max = "", background_color = "black" }) {
    this.type = type; //line_with_colormap // colormap / line
    this.plot_clk = plot_clk; // {boolean}   whether clock signals should be overlayed, default = False
    this.diff_clk = diff_clk; //{boolean}   if differential clock, whether the differential signal should be plotted, default = True  (DQSP,DQSPN  DQSP-DQSN)
    this.time_scale = time_scale; // {float}   time scaling factor (ps ns,us) ps ==> 1e-12, ns ==> 1e-9, us ==> 1e-6
    this.v_min = v_min; //{float}     minimum voltage in volt, no default
    this.v_max = v_max; //{float}     maximum voltage in volt, no default
    this.x_label = 'Time (ns)'; //label string for the x axis
    this.y_label = 'Voltage (V)'; //label string for the y axis
    this.grid_x = 300; //for colormap plot, the x size in pixel, default = 200
    this.grid_y = 200; //for colormap plot, the y size in pixel, default = 150
    this.paper_x = 6.4; //{ float } figure x size on paper in inches, default = 6.4      ​
    this.paper_y = 4.8; //{ float } figure y size on paper in inches, default = 4.8
    this.dpi = 300; //{ int } dots per inch(dpi) on paper, default = 100
    this.background_color = background_color || "black"; //black white
  }
}

export {
  CustomizedEyeConstructor,
  EyeConfig
};