import { PRE_LAYOUT } from '../../../constants/designVendor';
import { PCB, PCB_CHANNEL, PCB_PRE_LAYOUT, PACKAGE } from '../../../constants/treeConstants';
import { getPageKeyList } from '../../helper/filterHelper';
import designChannelsConstructor from '../helper/designChannelConstructor';
import designConstructor from '@/services/helper/designConstructor';
import { PCIE, MIPI, ETHERNET, HDMI, DP, USB, GENERIC, CPHY } from '../../PCBHelper/constants';
import { PACKAGE as PCB_PACKAGE } from '../../../constants/designType';

function getSetupPage({
  _filterKeys,
  viewList,
  projectDesignID
}) {
  let newList = [...viewList], pageType, id;
  _filterKeys = getPageKeyList(PCB_CHANNEL);
  newList = viewList.filter(item => _filterKeys.includes(item));
  if (!newList.length) {
    const channels = designChannelsConstructor.getChannelsByDesignId(projectDesignID);
    if (!channels.length) {
      return { _filterKeys, pageType, id, newList };
    }
    pageType = PCB_CHANNEL;
    id = channels[0];
  }
  return { _filterKeys, pageType, id, newList }
}

function getPCBPage({
  _filterKeys,
  viewList,
  projectDesignID,
}) {
  let newList = [...viewList], pageType, id;
  _filterKeys = getPageKeyList(PCB_PRE_LAYOUT);
  newList = viewList.filter(item => _filterKeys.includes(item));
  if (!newList.length) {
    if (!projectDesignID) {
      return { _filterKeys, pageType, id, newList };
    }
    const design = designConstructor.getDesign(projectDesignID);
    if (design.vendor === PRE_LAYOUT) {
      pageType = PCB_PRE_LAYOUT
    } else if (design.type === PCB_PACKAGE) {
      pageType = PACKAGE;
    } else {
      pageType = PCB;
    }
    id = projectDesignID;
  }
  return { _filterKeys, pageType, id, newList }
}

function getChannelTypeWidth(typeList) {
  if (!typeList.length) {
    return null;
  }
  const _typeList = typeList.sort((a, b) => {
    return b.length - a.length;
  });

  switch (_typeList[0]) {
    case PCIE:
    case MIPI:
    case HDMI:
    case CPHY:
      return 50;
    case DP:
      return 40;
    case ETHERNET:
      return 64;
    case USB:
      return 47;
    case GENERIC:
      return 61;
    default: return null;
  }

}

export {
  getSetupPage,
  getPCBPage,
  getChannelTypeWidth
}