import React, { Component, createRef } from 'react';
import { Spin, Switch } from 'antd';
import LayoutData from '../../services/data/LayoutData';
import makeCancelable from '../../services/api/makeCancelable';
import { getNetsData } from '../../services/VirtualComponent/netLayoutHelper';
import vcCanvas from '../../services/VirtualComponent/vcCanvas';
import './index.css';

class LayoutCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNames: true,
      loading: false
    }
    this.svgRef = createRef();
    this.locationRef = createRef();
    this.layoutUnit = "";
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.setState({
      loading: true
    })
    document.addEventListener('click', this.handleClick, true);
    this.props.onRef && this.props.onRef(this)
    this.renderDesign()
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick, true);
  }

  handleClick = (e) => {
    const { target } = e;
    const canvasContent = document.querySelector('#layout-setup-canvas');
    const virtualCompCanvasContents = document.querySelectorAll('.virtual-component-canvas');
    const virtualRectContents = document.querySelectorAll('.rectangle');
    const virtualSelections = document.querySelectorAll('.virtual-selection-options');
    if (!canvasContent || !canvasContent.contains(target)) {
      return;
    }

    if (virtualCompCanvasContents) {
      for (let i = 0; i < virtualCompCanvasContents.length; i++) {
        if (virtualCompCanvasContents[i].contains(target)) {
          return;
        }
      }
    }

    if (virtualRectContents) {
      for (let i = 0; i < virtualRectContents.length; i++) {
        if (virtualRectContents[i].contains(target)) {
          return;
        }
      }
    }

    if (virtualSelections) {
      for (let i = 0; i < virtualSelections.length; i++) {
        if (virtualSelections[i].contains(target)) {
          return;
        }
      }
    }

    setTimeout(() => {
      if (!vcCanvas) return;
      vcCanvas.createDividingLine();
    }, 20)
  }

  renderDesign = async () => {
    const { designId, signals, components } = this.props;

    const loadLayoutDBPromise = LayoutData.LoadLayoutDB(designId);
    this.cancelableLoadLayout = makeCancelable(loadLayoutDBPromise);
    try {
      await this.cancelableLoadLayout.promise;
    } catch (error) {
      console.error(error);
      return;
    }
    const DesignData = LayoutData.getLayout(designId);
    const filterData = getNetsData(DesignData, signals, components);
    const { layerList, nets, comps, data, pinList } = filterData;
    const allComps = DesignData.mLayerMgr.GetAllLayerComponents() || [];
    const layoutCompNames = allComps.map(item => item.mName);
    this.layoutUnit = DesignData.GetLayoutUnits();

    if (this.layoutUnit === "mils") {
      this.layoutUnit = "mil";
    }
    this.setState({
      DesignData: data,
      layerList,
      nets,
      comps,
      pinList
    }, () => {
      this.renderLayout(layoutCompNames)
    })
  }

  canvasSelect = (canvasObj) => {
  }

  changeMouse = (x, y) => {
    this.setState({ location: x.toFixed(2) + ', ' + y.toFixed(2) });
  }

  renderLayout(layoutCompNames) {
    const { DesignData, layerList, pinList } = this.state;
    if (!DesignData) {
      return;
    }
    const events = {
      click: this.canvasSelect,
      mousemove: this.changeMouse,
      updateVcComponents: this.updateVcComponents,
      updateCanvasViewVCompsToTable: this.props.updateCanvasViewVCompsToTable,
      showVcErrors: this.props.showVcErrors
    }

    if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
      this.svgRef.current.removeChild(this.svgRef.current.children[0])
    }
    vcCanvas.initLayout({
      svg: this.svgRef.current,
      layoutDB: DesignData,
      locationSvg: this.locationRef.current,
      showPinList: pinList,
      events: { ...events },
      product: this.props.product,
      getOtherVirtualComps: this.getOtherVirtualComps
    });
    vcCanvas.updateLayers(layerList, []);
    vcCanvas.selectNetCompPin({ pinList })
    vcCanvas.initSignalsInfo({ DesignData, signals: this.props.signals, layoutCompNames })
    this.showNetCompNames(true);
    vcCanvas.initVcComps({ vcComps: this.props.virtualComps })
    this.setState({
      loading: false
    })
  }

  getOtherVirtualComps = () => {
    return this.props.otherVirtualComps || []
  }

  updateViewVirtualCompsPanel = (comps) => {
    this.props._updateViewVirtualCompsPanel({ comps, isUpdateCanvas: false })
  }

  updateVcComponents = (comps) => {
    this.props._updateVcComponents(comps);
  }

  switchVcCompsStatus = (params) => {
    vcCanvas.switchVcCompsStatus(params);
  }

  changeShowNetCompsName = (bool, e) => {
    e && e.stopPropagation();
    this.setState({
      showNames: bool
    })
    this.showNetCompNames(bool);
  }

  showNetCompNames = (bool) => {
    const { signals = [], designId, components } = this.props;
    if (bool) {
      const nets = signals.map(item => item.nets).flat(2);
      const comps = components.map(item => item.name);
      vcCanvas.showNetsAndCompsName(nets, comps, designId);
    } else {
      vcCanvas.removeNetsAndCompsName()
    }
  }

  render() {
    const { showNames, loading, location } = this.state;
    return <div id="layout-setup-canvas-main">
      <Spin spinning={loading} tip={loading ? `Loading...` : ''}>
        <div className="layout-setup-canvas-net-name">
          <span className="layout-setup-canvas-net-title">Nets and Components Name</span>
          <Switch
            className={`layout-setup-canvas-net-switch ${showNames ? "" : 'un-show-switch'}`}
            checked={showNames}
            onChange={this.changeShowNetCompsName} />
        </div>
        <svg id='layout-setup-canvas'
          className='layout-setup-canvas'
          /*  preserveAspectRatio="none" */
          /*    viewBox={this.state.viewBox} */
          ref={this.svgRef}
          tabIndex="0">
        </svg>
        <div className='virtual-component-layout-canvas-coord'>{location ? (`( ${location} ) ${this.layoutUnit} `) : location}</div>
      </Spin>
    </div>
  }
}

export default LayoutCanvas;