import { takeEvery, select, call, put, delay } from 'redux-saga/effects';
import {
  UPDATE_DIMM_CARD_MODEL,
  SAVE_CONTENT_TO_SERVER,
  CHANGE_SOCKET_MODEL
} from './actionTypes';
import { getProjectType } from './saga';
import RockySetup from '@/services/Rocky/helper/rockyDatabase';
import {
  switchRankStatus,
  updateRockyContentInfo,
  updateRockyInterfaces
} from './action';
import channelVerifications from '@/services/Rocky/helper/channelVerifications';
import projectChannels from '@/services/Rocky/helper/projectChannels';
import { initDefaultInterfaces, getSetupInfo } from './saga';
import { RDIMM_TYPES, ROCKY_PDN } from '../../../../services/Rocky/constants';
import { getErrorCheck } from '../../errorCheck/ErrorCheck';
import ByteSetupInfo from '@/services/Rocky/helper/rockySetupInfo';
import { ROCKY_SETUP_VERSION } from '@/version';
import { autoGetVerificationList } from '../project/action';
import {
  updateVerificationContent,
  copyComponentDimmModel,
  updateDimmStdModelsByRank,
  copyComponentSocketModel,
} from '@/services/Rocky';
import { MEMORY } from '../../../../constants/componentType';
import rankConstructor from '../../../../services/helper/rankConstructor';
import { getInterfaceType } from '../../../../services/Rocky';
const NONE = 'None'

function* _changeDimmCardModel(action) {
  const { dimmCardModel, record, applyAll } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { openVerificationId } } } = yield select();
  let _Interfaces = rockyInfo.Interfaces;
  const projectType = yield call(getProjectType);
  const interfaceType = rockyInfo && rockyInfo.verificationName ? getInterfaceType(rockyInfo.verificationName) : '';
  let rank = null;
  const isCLK = interfaceType === "CLK";
  const allowRanks = RDIMM_TYPES.includes(projectType)
  try {
    if (dimmCardModel.type !== NONE && allowRanks && (applyAll || !isCLK)) {
      const { id, type } = dimmCardModel
      rank = yield call(rankConstructor.getRankValues, { id, type })
    }
  } catch (error) {
    console.error(error)
  }
  _Interfaces.forEach(info => {
    if (applyAll) {
      info.Content.Components.forEach(item => {
        if (item.part === record.part || item.type === MEMORY) {
          item.dimmCardModel = { ...dimmCardModel }
          if (allowRanks) {
            item.pins = updateDimmStdModelsByRank(item, rank, isCLK)
          }
        }
      })
    } else {
      const index = info.Content.Components.findIndex(item => item.name === record.comps[0].name)
      if (index > -1) {
        info.Content.Components[index].dimmCardModel = { ...dimmCardModel }
        if (allowRanks) {
          info.Content.Components[index].pins = updateDimmStdModelsByRank(info.Content.Components[index], rank, isCLK)
        }
      }
    }
  });
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
  yield put(switchRankStatus(true));
  //assign to all bytes and ADR/CMD
  try {
    if (applyAll) {
      yield call(_copyModelToAllInterfaces, {
        model: dimmCardModel,
        openVerificationId,
        partName: record.part,
        compType: MEMORY,
        modelType: "dimm",
        rank
      })
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateSocketModel(action) {
  const { model, record, applyAll } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { openVerificationId } } } = yield select();
  let _Interfaces = rockyInfo.Interfaces;
  _Interfaces.forEach(info => {
    if (applyAll) {
      info.Content.Components.forEach(item => {
        if (item.part === record.part) {
          item.socketModel = { ...model };
        }
      })
    } else {
      const index = info.Content.Components.findIndex(item => item.name === record.comps[0].name);
      if (index > -1) {
        info.Content.Components[index].socketModel = { ...model };
      }
    }
  });
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
  // assign to all bytes and ADR/CMD
  if (applyAll) {
    yield call(_copyModelToAllInterfaces, {
      model,
      openVerificationId,
      partName: record.part,
      compType: MEMORY,
      modelType: "socket"
    })
  }
}

function* _copyModelToAllInterfaces(action) {
  const { model, openVerificationId, modelType, partName, compType, rank } = action;
  const { RockyReducer: { project: { currentProjectId, currentChannelId, ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, extraction, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList }, rocky: { currentConfig } } } = yield select();
  const channels = projectChannels.get(currentProjectId);
  let copySetupFinds = [];
  channels.forEach(channelId => {
    const verifications = channelVerifications.getVerificationsByChannelId(channelId) || [];
    let copyVerifications = verifications.filter(item => item.type !== ROCKY_PDN)/* .filter(item => item.status === VERIFY_NEVER) */;
    if (currentChannelId === channelId) {
      copyVerifications = copyVerifications.filter(item => item.id !== openVerificationId)
    }
    copySetupFinds.push(...copyVerifications)
  })
  const projectType = yield call(getProjectType);
  const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
  if (copySetupFinds && copySetupFinds.length > 0) {
    yield* copySetupFinds.map(function* (info) {
      const response = yield call(getSetupInfo, info.id);
      if (response) {

        let _Interfaces = response.interfaces;
        //update interface info by default
        _Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
        let copyInfo = null;
        if (modelType === "dimm") {
          copyInfo = copyComponentDimmModel({
            currentInterfaces: _Interfaces,
            model,
            partName,
            compType,
            rank,
            projectType
          });
        }

        if (modelType === "socket") {
          copyInfo = copyComponentSocketModel({
            currentInterfaces: _Interfaces,
            model,
            partName,
            compType
          });
        }
        if (copyInfo && copyInfo.copyStatus) {
          _Interfaces = copyInfo.Interfaces ? JSON.parse(JSON.stringify(copyInfo.Interfaces)) : _Interfaces;
          // error check update readyForSim
          let readyForSim = response.readyForSim;
          const SETUP = RockySetup;
          let setupInfo = SETUP.mergeInterfacesInfo(_Interfaces, response.name, projectType);
          const _rockyInfo = { info: setupInfo, Interfaces: JSON.parse(JSON.stringify(_Interfaces)) };
          const errorCheck = getErrorCheck(_rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList }, extraction.channelType, projectType);
          readyForSim = errorCheck ? 0 : 1;
          ByteSetupInfo.saveSetupInfo(info.id, {
            ...response,
            version: ROCKY_SETUP_VERSION,
            interfaces: JSON.parse(JSON.stringify(_Interfaces)),
            readyForSim,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
          });
          yield call(updateVerificationContent, {
            id: info.id,
            name: response.name,
            interfaces: JSON.parse(JSON.stringify(_Interfaces)),
            config: response.config,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
            readyForSim,
            version: ROCKY_SETUP_VERSION,
            designVersion: response.designVersion ? response.designVersion : "0"
          });
        }
      }
    })

    yield delay(1000);
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  }
}

export function* dimmSaga() {
  yield takeEvery(UPDATE_DIMM_CARD_MODEL, _changeDimmCardModel);
  yield takeEvery(CHANGE_SOCKET_MODEL, updateSocketModel);
};