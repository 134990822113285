import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Spin, Divider } from 'antd';
import { getSBRTDR, getComponentInfo, getAndesResultHistoryList, getVerificationJson, delResultHistory } from '@/services/Andes/results/result';
import WaveformSetting from '../../../../services/Result/Public/waveform/waveformSetting';
import TDRSelection from './TDRSelection';
import SBRSelection from './SBRSelection';
import HistoryData from '@/services/Andes/results/historyJsonData';
import WaveformPlot from '@/services/Andes/results/waveformPlot';
import getIndex from '@/services/helper/insertionSearch';
import { strDelimited } from '@/services/helper/split';
import ResultLayout from '@/services/Result/Public/resultLayout';
import '../index.css';

let historyData = null;
class Waveform extends Component {
  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.contentRef = createRef();
    this.state = {
      loading: true,
      compInfo: [],
      selectedComponentKeys: [],
      displayMode: 'Fast',
      historyShow: false,
      resultShow: true,
    }

    this.onComponentChange = this.onComponentChange.bind(this);
    this.colorChange = this.colorChange.bind(this);
    this.changeMouse = this.changeMouse.bind(this);
    this.cancelMove = this.cancelMove.bind(this);
    this.changeAxis = this.changeAxis.bind(this);
    this.plot2D = null;
  }

  onRef = (ref) => {
    this.waveformSetting = ref;
  }

  componentDidMount() {
    this.getWaveform();
  }

  componentDidUpdate = (prevProps) => {
    const { currentResultKey, verificationId, saveHistoryCount } = this.props;
    if (verificationId !== prevProps.verificationId ||
      currentResultKey !== prevProps.currentResultKey ||
      saveHistoryCount !== prevProps.saveHistoryCount) {
      this.getWaveform();
    }
  }

  resultTitleClick = () => {
    const { resultShow } = this.state;
    this.setState({
      resultShow: !resultShow
    });
  }

  historyTitleClick = () => {
    const { historyShow } = this.state;
    this.setState({
      historyShow: !historyShow
    });
  }

  historyItemTitleClick = (e, name, time) => {
    e.stopPropagation();
    const { compInfo } = this.state;
    let list = [...compInfo];
    const index = list.findIndex(item => item.time === time && item.name === name);
    list[index].show = !list[index].show;
    this.setState({
      compInfo: list
    })
  }

  getComponentInfoByType(type, curves, _time, _name) {
    let _compInfo = null, selectedComponentKeys = [];
    const compsInfo = getComponentInfo(curves, _time);
    const clkSignal = this.props.andesInfo.info.signals.map(signal => signal.name)[0];

    let compArr = compsInfo;
    if (type === 'TDR') {
      let ControllerArr = [], DeviceArr = [];
      for (const info of compsInfo) {
        if (!info.usage) continue;
        if (info.usage === 'Controller') {
          ControllerArr.push(info);
        } else if (info.usage === 'Device') {
          DeviceArr.push(info);
        }
      }
      compArr = [...ControllerArr, ...DeviceArr];
    }
    for (const info of compsInfo) {
      if (info.signal === clkSignal && info.usage) {
        selectedComponentKeys.push(info.curveId);
      }
    }

    const _signals = compsInfo ? compsInfo.map(item => item.signal) : [];
    const signals = [...new Set(_signals)];
    _compInfo = this.state.compInfo;
    let index = _compInfo.findIndex(item => item.time === _time && item.name === _name);
    if (index > -1) {
      _compInfo[index].compInfo = compArr;
      _compInfo[index].signals = signals;
    } else {
      _compInfo.push({
        time: _time,
        name: _name,
        compInfo: compArr,
        signals,
        show: _time === 'current' ? true : false
      })
    }
    return { compInfo: _compInfo, selectedComponentKeys };
  }

  getResults = (resultKey, pcbId, type, time, name) => {
    let axis = null;
    if (resultKey === 'SBR') {
      axis = { yUnit: 'V', yLabel: 'Voltage' };
    } else if (resultKey === 'TDR') {
      axis = { yUnit: 'Ω', yLabel: 'Impedance' };
    } else {
      return;
    }
    const { verificationId, currentProjectId } = this.props;
    getSBRTDR(verificationId, currentProjectId, pcbId, type, time, resultKey).then(waveform => {
      const events = {
        changeMouse: this.changeMouse,
        cancelMove: this.cancelMove,
        changeAxis: this.changeAxis,
      };
      this.plot2D = WaveformPlot.plotWaveform({ svgElement: this.svgRef.current, events, waveform, axis });
      const _time = time ? time : 'current';
      const _name = name ? name : 'current';
      let curves = WaveformPlot.getCurves();
      curves = curves && curves.length ? curves.filter(c => c.time === _time) : [];
      const { compInfo, selectedComponentKeys } = this.getComponentInfoByType(resultKey, curves, _time, _name);
      this.setState({
        loading: false,
        compInfo,
      });
      if (_time === 'current') {
        this.setState({
          selectedComponentKeys,
        });
        for (const item of selectedComponentKeys) {
          const splits = strDelimited(item, "-");
          curves[splits[1]].visible = true;
        }
      }
      this.plot2D.updatePlot();
    })
  }

  getWaveform() {
    WaveformPlot.cleanWaveformPlot();
    this.plot2D = null;
    this.setState({
      loading: true,
      compInfo: [],
      selectedComponentKeys: []
    }, async () => {
      try {
        const { verificationId, currentProjectId, currentResultKey } = this.props;
        const currentInterfaces = await getVerificationJson(verificationId, currentProjectId);
        if (!currentInterfaces || !currentInterfaces.Interfaces || !currentInterfaces.Interfaces.length) {
          throw new Error('Can\'t get interfaces');
        }
        const designId = currentInterfaces.Interfaces[0].pcbId;
        this.getResults(currentResultKey, designId, 'result');
        this.getHistory();
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    })
  }

  getHistory = async () => {
    const { verificationId, currentProjectId, currentResultKey } = this.props;
    const historyList = await getAndesResultHistoryList(verificationId);
    historyData = new HistoryData(verificationId);
    historyList.forEach(history => {
      historyData.getContent({ projectId: currentProjectId, verificationId, time: history.time }).then(res => {
        if (res && res.length) {
          res.forEach(item => {
            this.getResults(currentResultKey, item.pcbId, 'history', history.time, history.name)
          })
        }
      })
    })
  }

  onComponentChange(values, time) {
    let newKeys = [];
    const { selectedComponentKeys } = this.state;
    if (values.length > 0) {
      newKeys = [...values];
    }
    for (const key of selectedComponentKeys) {
      const splits = strDelimited(key, "-");
      if (splits.length && splits[0] !== time) {
        newKeys.push(key);
      }
    }

    this.setState({
      selectedComponentKeys: newKeys,
    }, () => {
      const curves = WaveformPlot.getCurves();
      if (curves && curves.length) {
        curves.forEach((curve, i) => {
          curve.visible = newKeys.includes(curve.curveId);
        });
      }
      this.plot2D.updatePlot();
    })
  }

  getTimeIndex(compInfo, time) {
    return compInfo.findIndex(item => item.time === time);
  }

  getComponentIndex(info, curveIndex) {
    return info.compInfo.findIndex(d => d.curveIndex === curveIndex);
  }

  colorChange(e, curveIndex, time) {
    e.persist()
    if (e.target.value) {
      const curves = WaveformPlot.getCurves();
      let { compInfo } = this.state;
      const timeIndex = this.getTimeIndex(compInfo, time);
      if (timeIndex > -1) {
        const _curveIndex = this.getComponentIndex(compInfo[timeIndex], curveIndex);
        compInfo[timeIndex].compInfo[_curveIndex].color = e.target.value;
      }
      let currentCurveIndex = curves && curves.length ? curves.findIndex(item => item.time === time && item.curveId === `${time}-${curveIndex}`) : -1;
      if (currentCurveIndex > -1) {
        curves[currentCurveIndex].color = e.target.value;
      }
      this.setState({
        compInfo
      }, () => {
        this.plot2D.updatePlot();
      });
    }
  }

  changeMouse(time, yPrefix) {
    const curves = WaveformPlot.getCurves();
    let { compInfo } = this.state;
    const { currentResultKey } = this.props;
    const unit = currentResultKey === 'TDR' ? 'Ω' : 'V';
    let index = null;
    if (!curves || !curves.length) return;
    for (const curve of curves) {
      // time: current/history date
      const timeIndex = this.getTimeIndex(compInfo, curve.time);
      if (timeIndex < 0) {
        continue;
      }
      const compIndex = this.getComponentIndex(compInfo[timeIndex], curve.curveIndex);
      if (curve.visible && curve.y && curve.y.length) {
        if (!index) {
          index = this._getIndex(curves[0].x, time);
        }
        const value = index > -1 ? yPrefix(curve.y[index]) + unit : '';
        compInfo[timeIndex].compInfo[compIndex].current = value;
      } else {
        compInfo[timeIndex].compInfo[compIndex].current = '';
      }
    };

    this.setState({
      compInfo
    })
  }

  cancelMove() {
    const curves = WaveformPlot.getCurves();
    let { compInfo } = this.state;
    if (curves && curves.length) {
      for (const curve of curves) {
        if (curve.visible) {
          let timeIndex = this.getTimeIndex(compInfo, curve.time);
          if (timeIndex > -1) {
            const compIndex = this.getComponentIndex(compInfo[timeIndex], curve.curveIndex);
            compInfo[timeIndex].compInfo[compIndex].current = '';
          }
        }
      };
      this.setState({
        compInfo
      })
    }
  }

  changeAxis(axis) {
    this.waveformSetting.setAxis(axis);
  }

  _getIndex(xPoints, time) {
    return getIndex(xPoints, time, 0, xPoints.length - 1);
  }

  axisChangeCB = (type, { _xMin, _xMax, _yMin, _yMax }) => {
    let plot = this.plot2D;
    if (type === 'xMin' || type === 'xMax') {
      let xMax = plot.options.xMax,
        xMin = plot.options.xMin,
        start = parseFloat(_xMin) * 1e-9,
        end = parseFloat(_xMax) * 1e-9;

      if (parseFloat(start).toString() === 'NaN') {
        start = 0;
      };
      if (parseFloat(end).toString() === 'NaN') {
        end = 0;
      };

      plot.xScale
        .domain([Math.min(start, end), Math.max(start, end)])
        .range([0, plot.size.width]);

      var xrange = plot.xScale.domain(),
        width = plot.size.width,
        startX = (xrange[0] - xMin) / (xMax - xMin) * width,
        endX = (xrange[1] - xMin) / (xMax - xMin) * width;
      plot.updateRange(startX, endX);
    } else {
      // y axis
      let yStart = parseFloat(_yMin),
        yEnd = parseFloat(_yMax);

      if (parseFloat(yStart).toString() === 'NaN') {
        yStart = 0;
      };
      if (parseFloat(yEnd).toString() === 'NaN') {
        yEnd = 0;
      };

      plot.yScale
        .domain([Math.max(yStart, yEnd), Math.min(yStart, yEnd)])
        .nice()
        .range([0, plot.size.height])
        .nice();
    }
    this.reloadAxisCB();
  }

  reloadAxisCB = () => {
    this.plot2D.updatePlot();
  }

  displayModeChange = (e) => {
    this.setState({ displayMode: e.target.value }, () => {
      this.getWaveform();
    });
  }

  deleteHistory = async (e, time) => {
    e.stopPropagation();
    const { verificationId } = this.props;
    await delResultHistory(verificationId, time);
    this.getWaveform();
  }

  changeWidthCB = () => {
    if (this.plot2D) {
      this.plot2D.redrawPlot(this.svgRef.current);
    }
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div className='andes-waveform-left'>
          <div className='andes-waveform-svg' style={{ width: "100%" }}>
            <svg ref={this.svgRef}></svg>
          </div>
        </div>
      </Spin>
    )
  }

  resultRight = () => {
    const { selectedComponentKeys, compInfo, resultShow, historyShow } = this.state;
    const { currentResultKey } = this.props;
    const _signals = compInfo ? compInfo.map(item => item.signal) : [];
    const signals = [...new Set(_signals)];
    return (
      <div className='andes-waveform-setting'>
        <div className='andes-result-waveform-selection'>
          {currentResultKey === 'TDR' ?
            <TDRSelection
              selectedComponentKeys={selectedComponentKeys}
              compInfo={compInfo}
              resultShow={resultShow}
              historyShow={historyShow}
              signals={signals}
              onComponentChange={this.onComponentChange}
              colorChange={this.colorChange}
              historyTitleClick={this.historyTitleClick}
              resultTitleClick={this.resultTitleClick}
              historyItemTitleClick={this.historyItemTitleClick}
              deleteHistory={this.deleteHistory}
            />
            : currentResultKey === 'SBR' &&
            <SBRSelection
              selectedComponentKeys={selectedComponentKeys}
              compInfo={compInfo}
              signals={signals}
              resultShow={resultShow}
              historyShow={historyShow}
              onComponentChange={this.onComponentChange}
              colorChange={this.colorChange}
              historyTitleClick={this.historyTitleClick}
              resultTitleClick={this.resultTitleClick}
              historyItemTitleClick={this.historyItemTitleClick}
              deleteHistory={this.deleteHistory}
            />}
        </div>
        <div className='andes-waveform-setting-box'>
          <Divider orientation="left" className='waveform-setting-title'>Setting</Divider>
          <WaveformSetting
            onRef={this.onRef}
            plot={this.plot2D}
            axisChangeCB={this.axisChangeCB}
            reloadAxisCB={this.reloadAxisCB}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='waveform waveform-clear'>
        <ResultLayout
          resultLeft={this.resultLeft}
          resultRight={this.resultRight}
          changeWidthCB={this.changeWidthCB}
        />
      </div>
    )
  }
}

const mapState = (state) => {
  const { project, andes, resultReducer: { currentResultKey } } = state.AndesReducer;
  const { andesInfo } = andes;
  const { currentProjectId, verificationId } = project;
  return {
    andesInfo,
    verificationId,
    currentProjectId,
    currentResultKey,
  }
}

export default connect(mapState, null)(Waveform);