/**
 * Combine several operations into one operation
 *
 * @param {*} fn
 * @param {*} delay
 * @param {boolean} [immediate=false]
 * @returns
 */
let timers = {};
function debounce(fn, delay, immediate = false, timerType = 'common') {
  return function () {
    timers[timerType] && clearTimeout(timers[timerType]); // re-calculate time
    let context = this;
    let args = arguments;
    if (!timers[timerType] && immediate) {
      fn.apply(context, args);
    }
    timers[timerType] = setTimeout(function () {
      if (!immediate) {
        fn.apply(context, args);
      } else {
        timers[timerType] = null;
      }
    }, delay);
  }
};

export default debounce;