import {
  createAndesV2EndToEndChannel,
  getAndesV2EndToEndChannelList,
  getAndesV2EndToEndChannelContent,
  delAndesV2EndToEndChannel,
  updateAndesV2EndToEndChannel,
  startAndesV2EndToEndSimulation,
  getAndesV2EndToEndSimulationLog,
  AndesCopyEndToEndChannel,
  AndesCopyChannelIntoEndToEnd,
  AndesGetChannelsByEndToEnd
} from "../../api/Andes_v2/endToEndChannel";
import apiProcess from "../../api/utility";

/**
 * create end to end channel
 * 2021.05.10
 * @param {object} data
 * @param {string} projectId
 * @return promise
 */
export function createEndToEndChannelPromise(data) {
  return apiProcess(createAndesV2EndToEndChannel, data);
}

/** 
 * get end to end channel list by projectId
 * 2021.05.10
 * @param {string} projectId
 * @return promise
 */
export function getEndToEndChannelList(projectId) {
  return apiProcess(getAndesV2EndToEndChannelList, projectId);
}

/** 
 * get end to end channel content
 * 2021.05.10
 * @param {string} endToEndChannelId
 * @return promise
 */
export function getEndToEndChannelContent(endToEndChannelId) {
  return apiProcess(getAndesV2EndToEndChannelContent, endToEndChannelId);
}

/** 
 * delete end to end channel
 * 2021.05.10
 * @param {string} endToEndChannelId
 * @return promise
 */
export function delEndToEndChannel(endToEndChannelId) {
  return apiProcess(delAndesV2EndToEndChannel, endToEndChannelId);
}

/** 
 * update end to end channel
 * 2021.05.10
 * @param {object} endToEndChannelInfo {id,name, content,...}
 * @return promise
 */
export function saveEndToEndChannelContent(endToEndChannelInfo) {
  return apiProcess(updateAndesV2EndToEndChannel, endToEndChannelInfo);
}

/**
 * start end to end channel simulationSaga
 * 2021/08/30
 * @param {Array} endToEndChannelIds
 * @return promise
 */
export function endToEndStartSimulation({ endToEndChannelIds, runSeasim, runAds }) {
  return apiProcess(startAndesV2EndToEndSimulation, { endToEndChannelIds, runSeasim, runAds });
}


/**
 * Get end to end channel simulation log
 * 2021/09/03
 * @param {string} verificationId
 * @return Promise
 */
export function getEndToEndSimulationLog(verificationId) {
  return apiProcess(getAndesV2EndToEndSimulationLog, verificationId);
}

/** 
 * end to end channel copy
 * @param {String} channelId 
 * @param {String} name
 * 2021.09.07
*/
export function copyEndToEndChannel(name, endToEndChannelId) {
  return apiProcess(AndesCopyEndToEndChannel, { name, endToEndChannelId });
}

/** 
 * channel copy into end to end channel
 * @param {String} channelId 
 * @param {String} endToEndChannelId
 * 2021.09.23
*/
export function copyChannelIntoEndToEnd({ channelId, endToEndChannelId, originalChannelId }) {
  return apiProcess(AndesCopyChannelIntoEndToEnd, { channelId, endToEndChannelId, originalChannelId });
}

/** 
 * get channel list by end to end id 
 * @param {String} endToEndChannelId
 * 2021.09.23
*/
export function getChannelsByEndToEnd(endToEndChannelId) {
  return apiProcess(AndesGetChannelsByEndToEnd, endToEndChannelId);
}