import React, { PureComponent } from "react";
import { Input } from 'antd';
import { METAL, RMS_ROUGHNESS, stackupItemErrorCheck, rmsRoughnessUnitList } from '../../services/Stackup';
import UnitAddonAfter from "../UnitAddonAfter";
import { newValueUnitSplit } from "../../services/helper/valueUnitSplit";
class HammerstadRoughnessSetup extends PureComponent {

  addonAfter = (unit, type) => {
    return UnitAddonAfter({
      unit,
      changeUnit: (key) => this.changeUnit(key, type),
      list: rmsRoughnessUnitList
    })
  }

  changeUnit = (key, type) => {
    const { roughness } = this.props;
    const valueObj = newValueUnitSplit(roughness[type]);
    let _roughness = { ...roughness };
    _roughness[type] = `${valueObj.value}${key}`;
    this.props.updateRoughness(_roughness);
  }

  changeValue = (e, type) => {
    const value = e.target.value;
    const { errorMsg, roughness } = this.props;
    let _roughness = { ...roughness };
    const rmsRoughnessObj = newValueUnitSplit(roughness.rms_roughness);
    _roughness[type] = `${value}${rmsRoughnessObj.unit}`;
    const _errorMsg = errorMsg && errorMsg.type === type ? null : errorMsg;
    this.props.updateRoughness(_roughness);
    this.props.updateErrorMsg(_errorMsg);
  }

  saveValue = (e, type) => {
    const value = e.target.value;
    const { roughness } = this.props;
    const rmsRoughnessObj = newValueUnitSplit(roughness.rms_roughness);
    // Error check
    const error = stackupItemErrorCheck(type, `${value}${rmsRoughnessObj.unit}`, { type: METAL });
    if (error) {
      e.target.focus && e.target.focus();
      this.props.updateErrorMsg({ type, error: error.error });
      return error;
    }
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render() {
    const { roughness } = this.props;
    const rmsRoughnessObj = newValueUnitSplit(roughness.rms_roughness);
    return <div className='surface-roughness-item surface-roughness-item-tabs'>
      <label className='surface-roughness-edit-value-label'>RMS Roughness​</label>
      <Input
        className='aurora-input surface-roughness-input'
        placeholder='RMS Roughness​'
        value={rmsRoughnessObj.value}
        onChange={(e) => this.changeValue(e, RMS_ROUGHNESS)}
        onBlur={(e) => this.saveValue(e, RMS_ROUGHNESS)}
        onKeyDown={(e) => this.keyDown(e, RMS_ROUGHNESS)}
        addonAfter={this.addonAfter(rmsRoughnessObj.unit, RMS_ROUGHNESS)}
      />
    </div>
  }

}

export default HammerstadRoughnessSetup;