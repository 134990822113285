import React, { Component, Fragment } from 'react';
import { Checkbox, Collapse, Divider, Input, Select, Radio } from 'antd';
import TagsInput from '@/components/TagsInput';
import {
  ADS_TX,
  ADS_RX,
  TX_EQ_TYPES,
  RX_EQ_TYPES,
  updateEQOptionsByType,
  DFE_TYPE,
  adsConfigItemErrorCheck,
  getEQOptionsByType,
  getTagsNumber,
  DFE,
  getEQTitleWidth,
  splitEQValue,
  updateEQOptionsBySubType,
  FFE_TYPE,
  CTLE_TYPE,
  ENABLE_CTLE_OR_FFE,
  EQ_TAPS,
  updateTapValuesByNumber,
  updateEQOptionsByPreset,
  getEQDFEValue,
  OPTIMIZED,
  PRESET,
  NUMBER_OF_TAPS,
  MANUAL,
  updateDFEOptionsByType,
  PROTOCOL,
  DFETAPUSED,
  updateDFEProtocolOptionsBySetup,
  TAPS,
  CPHY_RX_EQ_TYPES
} from '../../../services/Andes_v2/AMIModelHelper';
import { numberCheck } from '../../../services/helper/dataProcess';
import { IBIS } from '../../../constants/libraryConstants';
import { CPHY } from '../../../services/PCBHelper/constants';

const INPUT = 'input', SELECT = "select", CHECKED = "checked", INPUTCOMBINATION = "InputCombination",
  TAGS_INPUT = "tagsInput";

const Option = Select.Option;

class IbisEQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  radioChange = (e, type) => {
    e && e.stopPropagation();
    const { model, dirType, serdesType } = this.props;
    let _model = { ...model };
    const typeKey = dirType === ADS_RX ? serdesType !== CPHY ? ENABLE_CTLE_OR_FFE : "enableCTLE" : "type";

    if (_model.EQ[typeKey] === type) {
      /*    _model.EQ[typeKey] = "None"; */
      return;
    } else {
      _model.EQ[typeKey] = type
    }

    _model.EQ = updateEQOptionsByType(_model.EQ[typeKey], _model.EQ, dirType, serdesType);
    this.props.saveModel(_model);
  }

  saveConfigValue = (key, type) => {
    const { model, dirType, serdesType } = this.props;
    let _model = { ...model };
    _model.EQ[type] = key;
    if ([CTLE_TYPE, DFE_TYPE, FFE_TYPE].includes(type)) {
      _model.EQ = updateEQOptionsBySubType(key, _model.EQ, type, dirType, serdesType);
    } else if (type === PRESET) {
      _model.EQ = updateEQOptionsByPreset(key, _model.EQ, type, dirType, serdesType);
    }
    this.props.saveModel(_model);
  }

  changeInputValue = (e, type) => {
    const { model, error } = this.props;
    let _model = { ...model };
    let value = e.target.value;
    _model.EQ[type] = value;
    if (error && error.type === type) {
      this.props.updateError(null)
    }
    if (type === NUMBER_OF_TAPS) {
      _model.EQ[NUMBER_OF_TAPS] = value
    }
    this.props.saveModel(_model);
  }

  inputBlur = (e, type) => {
    const { model, bitRate } = this.props;
    let _model = { ...model };
    let value = e.target.value;
    _model.EQ[type] = value;
    const { error, errorType } = adsConfigItemErrorCheck({ value, type, bitRate, modelType: IBIS });
    if (error) {
      this.props.updateError({ error, errorType, type });
    }
    if (EQ_TAPS.includes(type)) {
      const { EQ, error: tapError } = updateTapValuesByNumber(type, _model.EQ);
      _model.EQ = EQ;
      if (tapError && !error) {
        this.updateErrorMessage({ error: tapError, errorType: "error", type })
      } else if (tapError) {
        this.props.updateError({ error: tapError, errorType: "error", type });
      }
      if (type === NUMBER_OF_TAPS && !error && !!_model.EQ[NUMBER_OF_TAPS]) {
        if (_model.EQ[DFETAPUSED] === PROTOCOL && [MANUAL, "None"].includes(_model.EQ[DFE_TYPE])) {
          //if protocol is checked, update DFE type
          _model.EQ = updateDFEProtocolOptionsBySetup(NUMBER_OF_TAPS, _model.EQ);
        }
      }
    }

    this.props.saveModel(_model);
  }

  changeTagList = (type, list, focus, title) => {
    const { model, error } = this.props;
    let _model = { ...model };
    _model.EQ[type] = list;

    this.props.saveModel(_model);
    if (focus) {
      error && error.type === type && this.props.updateError(null);
    } else {
      this.tagInputBlur(type, title);
    }
  }

  tagInputBlur = (type, title, isDFE) => {
    const { model, error } = this.props;
    let _model = { ...model };
    const list = _model.EQ[type] || [];
    let errorMsg = "";
    let { num, numType, compareLength } = getTagsNumber(type, _model.EQ, isDFE);
    let isUpdate = false;

    if (list.length <= num) {
      const errors = list.filter(item => !!numberCheck(splitEQValue(item).value));
      let _error = errors.length
        ? `${title} value must be a number.`
        : list.length < num && compareLength ? `The number of ${title} should be equal to "${numType}".` : null;
      if (_error) {
        this.updateErrorMessage({ error: _error, type });
        _model.EQ[type] = list.filter((item) => !numberCheck(splitEQValue(item).value));
        this.props.saveModel(_model);
      } else {
        error && error.type === type && this.props.updateError(null);
        isUpdate = true;
      }
    } else if (compareLength) {
      errorMsg = `${title} cannot exceed ${numType}.`;
      _model.EQ[type] = list.filter((item, index) => index < num);
      this.props.saveModel(_model);
      this.updateErrorMessage({ type, error: errorMsg })
    }
    if (list.length > num && !compareLength) {
      isUpdate = true;
    }
    if (type === TAPS
      && isUpdate
      && _model.EQ[DFETAPUSED] === PROTOCOL
      && [OPTIMIZED, "None"].includes(_model.EQ[DFE_TYPE])) {
      //if protocol is checked, update DFE type
      _model.EQ = updateDFEProtocolOptionsBySetup(TAPS, _model.EQ);
      this.props.saveModel(_model);
    }
  }

  updateErrorMessage = (error) => {
    this.props.updateError(error);
    setTimeout(() => {
      this.props.updateError(null);
    }, 2000)
  }

  checkChange = (e, type) => {
    const { model, serdesType } = this.props;
    let _model = { ...model };
    const checked = e.target.checked;

    if (type === DFE_TYPE) {
      const key = checked ? OPTIMIZED : "None";
      _model.EQ[type] = key;
      _model.EQ = updateDFEOptionsByType(key, _model.EQ, false, null, serdesType);
      this.props.saveModel(_model);
    } else if (type === OPTIMIZED) {
      const prevType = _model.EQ[DFE_TYPE];
      _model.EQ[DFE_TYPE] = checked ? OPTIMIZED : MANUAL;
      _model.EQ = updateDFEOptionsByType(_model.EQ[DFE_TYPE], _model.EQ, true, prevType, serdesType);
      if (_model.EQ[DFE_TYPE] === OPTIMIZED && !_model.EQ[NUMBER_OF_TAPS]) {
        this.props.updateError({
          type: NUMBER_OF_TAPS,
          error: `"Number of taps" cannot be empty when "Optimized" is checked!`
        })
        setTimeout(() => {
          this.props.updateError(null)
        }, 3000)
      }
    } else {
      _model.EQ[type] = checked ? "yes" : "no";
    }
    this.props.saveModel(_model);
  }

  render = () => {
    const { model: { EQ = {} }, dirType, prbsType, serdesType } = this.props;
    const items = [{
      key: 'eq',
      label: <div className="ads-model-jitter-title">
        <span>EQ</span>
      </div>,
      children: this.getEQList(EQ, dirType, prbsType, serdesType)
    }]
    return <Collapse className="ami-model-collapse-content" items={items} />
  }

  getEQList = (EQ, dirType, prbsType, serdesType) => {
    const typeList = dirType === ADS_TX ? TX_EQ_TYPES : (dirType === ADS_RX ? serdesType === CPHY ? CPHY_RX_EQ_TYPES : RX_EQ_TYPES : []);
    const typeKey = dirType === ADS_RX ? serdesType !== CPHY ? ENABLE_CTLE_OR_FFE : "enableCTLE" : "type";
    const options = getEQOptionsByType(EQ[typeKey], EQ, true, serdesType) || [];
    const dfeOptions = dirType === ADS_RX && serdesType !== CPHY ? getEQOptionsByType(DFE, EQ, true, serdesType) : [];
    let width = getEQTitleWidth([...options, ...dfeOptions]);
    if (width < 170) { width = 170 }

    return <Fragment>
      <div className='ami-advanced-select-item ibis-advanced-select-item'>
        {typeList.map(item =>
          <Radio
            key={item.key}
            checked={item.key === EQ[typeKey]}
            onClick={(e) => this.radioChange(e, item.key)}
          >{item.title}</Radio>
        )}
      </div>
      {options.map(item => {
        return this.getEQItem({
          itemData: item,
          EQ,
          width,
          isChildren: true
        });
      })}
      {dfeOptions.length ? <Divider /> : null}
      {dfeOptions.map(item => {
        const { disabled, value } = getEQDFEValue(EQ, item)
        if (item.key === NUMBER_OF_TAPS) {
          return this.getInputCombination({
            itemData: item,
            EQ,
            width,
            isChildren: true,
            disabled,
            value,
            subItem: {
              checked: EQ[DFE_TYPE] === OPTIMIZED,
              title: OPTIMIZED,
              /* disabled: EQ[DFETAPUSED] === PROTOCOL, */
              key: OPTIMIZED
            }
          });
        }
        return this.getEQItem({
          itemData: item,
          EQ,
          width,
          isChildren: true,
          className: "ami-advanced-select-item ibis-advanced-select-item ibis-eq-setup-item ibis-eq-dfe-setup-item",
          disabled,
          value
        });
      })}
    </Fragment>
  }

  getEQItem = ({
    itemData,
    EQ,
    width,
    isChildren,
    className,
    disabled,
    value }) => {
    switch (itemData.type) {
      case INPUT:
        return this.getInput({
          title: itemData.title,
          type: itemData.key,
          value: value !== undefined ? value : EQ[itemData.key],
          unit: itemData.unit,
          width,
          disabled: disabled !== undefined ? disabled : itemData.disabled,
          subItem: itemData.sub,
          EQ,
          isChildren,
          notDisplaySub: itemData.notDisplaySub,
          inputHide: itemData.inputHide
        });
      case SELECT:
        return this.getSelection({
          title: itemData.title,
          type: itemData.key,
          value: value !== undefined ? value : EQ[itemData.key],
          list: itemData.list,
          width,
          isChildren,
          disabled,
          EQ
        });
      case CHECKED:
        return this.getCheckBox({
          title: itemData.title,
          type: itemData.key,
          checked: EQ[itemData.key] === "yes" ? true : false,
          width,
          isChildren,
          disabled
        });
      case INPUTCOMBINATION:
        return this.getInputCombination({
          title: itemData.title,
          type: itemData.key,
          value: EQ[itemData.key],
          unit: itemData.unit,
          width,
          disabled: itemData.disabled,
          subItem: itemData.sub,
          EQ,
          isChildren,
          notDisplaySub: itemData.notDisplaySub,
          list: itemData.list,
        });
      case TAGS_INPUT:
        return this.getTagsInput({
          title: itemData.title,
          type: itemData.key,
          valueList: value !== undefined ? value || [] : EQ[itemData.key] || [],
          unit: itemData.unit,
          unitOptions: itemData.unitOptions,
          width,
          className,
          disabled,
          isDFE: true
        });
      default: return null;
    }
  }

  getSelection = ({
    title,
    value,
    type,
    list,
    width,
    isChildren,
    disabled
  }) => {
    const childrenClassName = isChildren && type !== DFE_TYPE ? "ibis-eq-setup-item" : "";
    const left = type === DFE_TYPE ? 26 : 0;
    return <div className={`ami-advanced-select-item ibis-advanced-select-item ${childrenClassName}`} key={type}>
      {type === DFE_TYPE ? <Checkbox
        style={{ width }}
        checked={(!value || value === "None") ? false : true}
        onChange={e => this.checkChange(e, type)}
      >
        DFE
      </Checkbox>
        : <span style={{ width }}>{title}</span>}
      {type === DFE_TYPE && (!value || value === "None") ? null
        : <Select
          value={value}
          showSearch
          onChange={(key) => this.saveConfigValue(key, type)}
          placeholder={title}
          disabled={disabled || false}
          className='aurora-select'
          style={{ width: `calc(100% - ${width + left}px`, marginLeft: left }}
          popupClassName='aurora-select-dropdown'
        >
          {list.map(item =>
            typeof (item) === "string" ? <Option key={item} value={item}>{item}</Option>
              : <Option key={item.key} value={item.key}>{item.title}</Option>
          )}
        </Select>
      }
    </div>
  }

  getInput = ({
    title,
    value,
    type,
    unit,
    width,
    disabled,
    subItem,
    EQ,
    isChildren,
    notDisplaySub,
    inputHide
  }) => {
    const { value: _value, unit: _unit } = splitEQValue(value);
    const childrenClassName = isChildren ? "ibis-eq-setup-item" : "";
    return <div className={`ami-advanced-select-item ibis-advanced-select-item ${childrenClassName}`} key={type}>
      <span style={{ width }}>{title}</span>
      {inputHide ? null : <Input
        className='aurora-input'
        style={{ width: subItem || notDisplaySub ? 100 : `calc(100% - ${width}px` }}
        value={_value}
        disabled={disabled || false}
        onChange={(e) => this.changeInputValue(e, type)}
        onBlur={(e) => this.inputBlur(e, type)}
        addonAfter={_unit || unit}
      >
      </Input>}
      {subItem && !subItem.notDisplay ? this.getTagsInput({
        title: subItem.title,
        type: subItem.key,
        valueList: EQ[subItem.key] || [],
        unit: subItem.unit,
        unitOptions: subItem.unitOptions,
        width: inputHide ? width : width + 110,
        inputHide
      }) : null}
    </div>
  }

  getInputCombination = ({
    itemData,
    EQ,
    width,
    isChildren,
    disabled,
    value,
    subItem
  }) => {
    const childrenClassName = isChildren ? "ibis-eq-setup-item" : "";
    return <div
      className={`ami-advanced-select-item ibis-advanced-select-item ami-advanced-input-combination-item ${childrenClassName}`}
      key={itemData.key}>
      <span style={{ width }}>{itemData.title}</span>
      <Input
        className='aurora-input'
        style={{ width: `calc(60% - ${width}px)` }}
        value={value || EQ[itemData.key]}
        disabled={disabled || false}
        onChange={(e) => this.changeInputValue(e, itemData.key)}
        onBlur={(e) => this.inputBlur(e, itemData.key)}
      ></Input>
      <span style={{ width: subItem.width || 82 }}>{subItem.title}</span>
      <Checkbox
        style={{ width: 40 }}
        disabled={subItem.disabled}
        checked={(!subItem || !subItem.checked) ? false : true}
        onChange={e => this.checkChange(e, subItem.key)}
      />
    </div>
  }

  getTagsInput = ({
    title,
    valueList,
    type,
    unit,
    width,
    unitOptions = [],
    inputHide,
    className,
    disabled,
    isDFE
  }) => {
    const { model = { EQ: {} } } = this.props;
    const { num, compareLength } = getTagsNumber(type, model.EQ);
    const spanWidth = className ? width : title.length * 8;
    const inputHideClassName = inputHide ? 'ibis-model-eq-input-hide-tags' : ''
    return <div className={`ibis-model-eq-tags ${inputHideClassName} ${className}`} style={{ width: !className ? `calc(100% - ${width}px` : null }} key={type}>
      {inputHide ? null : <span style={{ width: spanWidth }}>{title}</span>}
      <TagsInput
        tagList={valueList}
        className="ibis-model-eq-tags-input"
        type={type}
        allowedDuplicates={true}
        width={`calc(100% - ${spanWidth}px`}
        disabled={disabled || (num <= 0 && compareLength) ? true : false}
        changeTagList={(type, list, focus) => this.changeTagList(type, list, focus, title)}
        displayInput={true}
        tagInputBlur={(type) => this.tagInputBlur(type, title, isDFE)}
        isAddonAfter={unit ? true : false}
        unit={unit}
        unitOptions={unitOptions || []}
      />
    </div>
  }

  getCheckBox = ({
    title,
    checked,
    type,
    width,
    isChildren,
    disabled
  }) => {
    const childrenClassName = isChildren ? "ibis-eq-setup-item" : "";
    return <div className={`ami-advanced-select-item ibis-advanced-select-item ${childrenClassName}`} key={type}>
      <span style={{ width }}>{title}</span>
      <Checkbox
        style={{ width: `calc(100% - ${width}px` }}
        checked={checked}
        disabled={disabled || false}
        onChange={e => this.checkChange(e, type)}
      />
    </div>
  }
}

export default IbisEQ;