import React, { PureComponent } from "react";
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from "antd";
import { checkboxChange, selectedAll, clearSelection, cancelSelectedAll, showSelected } from '../store/actionCreators';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import '../Selection.css';
import { SIERRA, FASTPI } from '@/constants/pageType';

class SelectionPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      indeterminate: true,
      checkAll: false,
    };

    if (this.props.pageType && this.props.pageType === FASTPI) {
      const top = document.getElementsByClassName('aurora-content-top')[0];
      const right = document.getElementsByClassName('aurora-content-right')[0];

      if (top) {
        this.root = top;
      } else if (right) {
        this.root = right;
      } else {
        this.root = document.getElementsByClassName('aurora-content')[0]
      }
    } else {
      this.root = document.getElementById('verify-content');
    }

  }

  onCancel = () => { this.props.cancel(); }

  onSelectedAll = (e) => {
    const chooseFlag = e.target.checked;
    if (chooseFlag) {
      this.props.selectedAll();
    } else {
      this.props.cancelSelectedAll();
    }
  }

  onSelect = (e, type, item) => {
    const { selected } = this.props;
    const chooseFlag = e.target.checked;
    let selects = [...selected];
    selects.forEach(select => {
      if (select.name === item.name) {
        select.checked = chooseFlag;
      }
    });
    this.props.checkboxChanged({ selected: [...selects] });
  }

  onShowSelection = () => {
    this.props.showSelected();
  }


  onClearSelection = (e) => {
    this.props.clearSelection();
  }

  oncheckAllChange = (e) => {
    this.onSelectedAll(e);
  }

  renderResult = () => {
    const { selected } = this.props;
    let plainOptions = [];
    const defaultCheckedList = selected.filter(item => item.checked);
    const checkedAllFlag = defaultCheckedList.length > 0 && defaultCheckedList.length === selected.length;
    const indeterminateFlag = checkedAllFlag ? false : defaultCheckedList.length ? true : false;

    let selectionlist;
    selected.map((item) => { plainOptions.push(item.name) });
    selectionlist = (selected && selected.length === 0) ? (<div className="result-title-nothing" > Nothing selected </div>
    ) : (
      <div className='selectionlist'>
        {<div className='result-selected' >
          <Checkbox
            key={checkedAllFlag}
            indeterminate={indeterminateFlag}
            onChange={this.oncheckAllChange}
            checked={checkedAllFlag}
          >All
          </Checkbox>
          <div className='seleted-icon' style={{ float: 'right' }} >
            <Tooltip title='Display'>
              <EyeOutlined
                className='dispaly-selection'
                style={{ fontSize: '16px' }}
                onClick={(e) => this.onShowSelection(e)} />
            </Tooltip>
            <Tooltip title='Delete'>
              <DeleteOutlined
                className='clear-selection'
                onClick={(e) => this.onClearSelection(e)}
                style={{ fontSize: '16px' }} />
            </Tooltip>
          </div>
        </div>}
        {selected && selected.length > 0 && (<ul className='result-list'>
          {selected.map((item, i) =>
            <div key={item.name + i}>
              <Checkbox
                onChange={(e) => this.onSelect(e, item.type, item)}
                checked={item.checked}
              >{item.name}
              </Checkbox>
            </div>)}
        </ul>)}
      </div>
    )
    return (
      <div className="seletion-result" >
        {selectionlist}
      </div>
    )
  }

  render() {
    const { defaultLeft, defaultTop, width, leftWidth } = this.props;
    const content = (
      <Panel title={<label className='selection-panel-title' >Net and Component Search</label>}
        visible
        onCancel={this.onCancel}
        width='300px'
        position='panel-bottom-right'
        className='selection-panel'
        left={width ? leftWidth : defaultLeft}
        top={defaultTop}
        draggable
        minHeight={200}
      >
        <div>
          {this.renderResult()}
        </div>
      </Panel>
    )
    return createPortal(content, this.root)
  }
}

const mapState = (state) => {
  const LoginReducer = state.LoginReducer;
  if (LoginReducer && LoginReducer.pageType === FASTPI) {
    return {
      selected: [],
      defaultLeft: "",
      defaultTop: "",
      pageType: ""
    }
  } else if (LoginReducer && LoginReducer.pageType === SIERRA) {
    return {
      selected: [],
      defaultLeft: "",
      defaultTop: "",
      pageType: ""
    }
  } else {
    const { selected } = state.default;
    const { defaultLeft, defaultTop } = state.PanelStatus;
    const { pageType } = state.LoginReducer;
    return { selected, defaultLeft, defaultTop, pageType }
  }
}


const mapDispatch = (dispatch) => ({
  checkboxChanged(canvasObj) {
    dispatch(checkboxChange(canvasObj))
  },
  clearSelection() {
    dispatch(clearSelection())
  },
  selectedAll() {
    dispatch(selectedAll())
  },
  cancelSelectedAll() {
    dispatch(cancelSelectedAll())
  },
  showSelected() {
    dispatch(showSelected())
  },
})
export default connect(mapState, mapDispatch)(SelectionPanel);