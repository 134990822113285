import React from "react";

export default function higherOrderComponent({ LayoutExplorer, ...otherProps }) {
  return class extends React.Component {
    constructor(props) {
      super()
    }

    render() {
      return <LayoutExplorer
        {...otherProps}
        {...this.props}
      />;
    }
  };
}