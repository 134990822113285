function getDefaultRepeaterPinMap(pinList, settings = {}) {
  const { numberKey = "mNumber", nameKey = "mName" } = settings || {};
  if (!pinList) {
    return []
  }
  let pinConnections = [];
  let existPin = [];
  for (let item of pinList) {
    if (existPin.includes(item[numberKey])
      || (item[nameKey].match && item[nameKey].match(/(VCC)|(VREF)|(VDD)(GND)/ig))) {
      continue;
    }
    let aOutName = "", keyOutName = "", numOutName = "";
    //*IN ---- *OUT
    if (item[nameKey].match(/(.*IN$)/ig)) {
      keyOutName = item[nameKey].replace(/IN$/ig, "OUT");
      if (!keyOutName) {
        keyOutName = item[nameKey].replace(/IN$/ig, "OUTPUT");
      }
    }

    //*A ---- *B
    if (item[nameKey].match(/(.*A$)/ig)) {
      aOutName = item[nameKey].replace(/A$/ig, "B");
    }

    if (item[nameKey].match(/(IN)|(INPUT)/ig)) {
      const inName = item[nameKey].replace(/(IN)|(INPUT)/ig, "IN");
      const findOutput = pinList.find(it => it[nameKey].match(/(OUT)|(OUTPUT)/ig) && it[nameKey].replace(/(OUT)|(OUTPUT)/ig, "IN") === inName && !existPin.includes(it[numberKey]));
      if (findOutput) {
        existPin.push(item[numberKey], findOutput[numberKey]);
        pinConnections.push({ input: item[numberKey], output: findOutput[numberKey] })
        continue;
      }
    }
    //*1 ---- *2
    if (item[nameKey].match(/(.*1$)/ig) && item[nameKey].length > 1) {
      numOutName = item[nameKey].replace(/1$/ig, "2");
    }

    const outName = keyOutName || aOutName || numOutName;
    const findOUT = pinList.find(it => it[nameKey] === outName && !existPin.includes(it[numberKey]));
    if (findOUT) {
      existPin.push(item[numberKey], findOUT[numberKey]);
      pinConnections.push({ input: item[numberKey], output: findOUT[numberKey] })
    }
  }

  if (!pinConnections.length || !pinConnections.find(item => !!item.input && !!item.output)) {
    pinConnections = getPinMapByPinNum({
      pinList,
      numberKey
    })
  }

  pinConnections = pinConnections.map(item => {
    return {
      input: item.input ? [item.input] : [],
      output: item.output ? [item.output] : []
    }
  })
  return pinConnections;
}

function getConnectedPin({ pinNum, pinLength, pinMap = [], notDefaultPinMap = false }) {
  let _pinMap = pinMap ? pinMap : [];

  if (pinNum === null || pinNum === undefined) {
    return null
  }
  const findPin = _pinMap.find(item => item.input && item.input[0] && item.input[0].toString() === pinNum.toString());
  if (findPin && findPin.output && findPin.output[0]) {
    return findPin.output[0]
  }
  const findPinMap = _pinMap.find(item => item.output && item.output[0] && item.output[0].toString() === pinNum.toString());
  if (findPinMap && findPinMap.input && findPinMap.input[0]) {
    return findPinMap.input[0]
  }

  if (notDefaultPinMap) {
    return null;
  }

  const _pinNum = parseInt(pinNum);

  if (isNaN(_pinNum) || _pinNum > pinLength) {
    return null;
  }
  /* 
  pinLength: 4
     1   4
     2   3
  
  pinLength: 8 
     1   8
     2   7
     3   6
     4   5
   */
  return pinLength - _pinNum + 1;
}

function getPinMapByPinNum({ pinList, numberKey }) {
  let pinConnections = [];
  const _pinList = pinList.map(item => item[numberKey]).sort((a, b) => Number(a) - Number(b));

  for (let i = 0; i < Math.floor(_pinList.length / 2); i++) {
    const outputNum = getConnectedPin({
      pinNum: _pinList[i],
      pinLength: pinList.length
    });
    let output = outputNum ? outputNum.toString() : "";
    if (!_pinList.includes(output)) {
      const index = _pinList.length - i - 1;
      output = _pinList[index] ? _pinList[index] : "";
    }
    pinConnections.push({ input: _pinList[i], output: output });
  }
  return pinConnections;
}

export {
  getDefaultRepeaterPinMap,
  getConnectedPin
}