import {
  UPDATE_LIBRARY_MENU,
  TOUCHSTONE_RUNNING_LIST,
  LIBRARY_TREE_NEED_UPDATE,
  LIBRARY_FOLDER_CREATE,
  OPEN_LIBRARY_FOLDER,
  GET_LIBRARY_TREE,
  AFTER_IMPORT_LIBRARY,
  GET_PART_LIBRARY,
  SAVE_LIBRARY_FILE_CONTENT,
  UPDATE_FILE_CONTENT,
  UPDATE_DEFAULT_LIBRARY,
  SET_DEFAULT_LIBRARY,
  UPDATE_REPEATER_FILE_STATUS,
  SAVE_PRE_LAYOUT_TEMPLATE
} from './actionTypes';

export const updateLibraryMenu = () => ({
  type: UPDATE_LIBRARY_MENU
});

export const saveTouchstoneRunningList = (list) => ({
  type: TOUCHSTONE_RUNNING_LIST,
  list
});

export const updateLibraryTree = (treeType) => ({
  type: LIBRARY_TREE_NEED_UPDATE,
  treeType
});

export const setFolderCreate = (createInfo) => ({
  type: LIBRARY_FOLDER_CREATE,
  createInfo
})

export const openLibraryFolder = (libraryId, key, update = false) => ({
  type: OPEN_LIBRARY_FOLDER,
  libraryId,
  key,
  update
});

export const getLibraryTreeByType = (typeList) => ({
  type: GET_LIBRARY_TREE,
  typeList
});

export const afterImportLibrary = () => ({
  type: AFTER_IMPORT_LIBRARY,
});

export const getPartLibrary = () => ({
  type: GET_PART_LIBRARY,
})

export const saveLibraryFileContent = ({ fileId, folderId, name, content, libraryType, partRepeaterVisible }) => ({
  type: SAVE_LIBRARY_FILE_CONTENT,
  fileId,
  folderId,
  name,
  content,
  libraryType,
  partRepeaterVisible
})

export const updateFileContent = (isUpdate) => ({
  type: UPDATE_FILE_CONTENT,
  isUpdate
});

export const updateDefaultLibrary = (info) => ({
  type: UPDATE_DEFAULT_LIBRARY,
  info
});

export const changeDefaultLibrary = ({ libraryId, name, libraryType }) => ({
  type: SET_DEFAULT_LIBRARY,
  libraryId,
  name,
  libraryType
});

export const updateRepeaterFileStatus = (isUpdateRepeaterFile) => ({
  type: UPDATE_REPEATER_FILE_STATUS,
  isUpdateRepeaterFile
})

export const savePreLayoutTemplate = (modelType, data) => ({
  type: SAVE_PRE_LAYOUT_TEMPLATE,
  modelType,
  data
})
