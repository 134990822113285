import { getProjectsByTypeNameVersion, createProject, deleteProjectsByIDs, getTrashList, sierraCopyProject, projectRename, sierraCopyInterface, uploadProjectConnections, getProjectConnections, saveProjectConnections } from '../api/v2/projectCtrl';
import checkError from '../api/checkError';
import { SUCCESS, CHECK_FAIL } from '../../constants/returnCode';
import { deleteDesignsPromise, getUnitFromLayoutDB } from '../api/Design/design';
import LayoutData from '../data/LayoutData';
import {
  doSimulation, UploadSierraDebugFile, debugSierraVerify, getSierraLog, saveReExtraction, changeSierraInterfaceName,
  uploadTR0File, getProjectInfo, getDebugList, getProjectSearchConfig, updateProjectSearchConfig,
  getSierraReportStatus,
  getSierraReportFile,
  generateSierraReport,
  saveSierraReportConfig,
  getSierraReportConfig,
  getSierraReportLog
} from '../api/sierra';
import {
  editExperimentGroup, getExperimentGroup, deleteExperimentGroup, getExperiments, editExperiment, simulateSweeping,
  deleteExperiment, deleteExperiments, getExperimentLog, getExperimentMonitor, getExperimentResult, getExperimentSNPFile, getExperimentChannel,
  cancelExperiment, getExperimentsResultExsit, postExperimentColumns, experimentDebugOption, getSierraAllExperimentsMeasurement
} from '../api/v2/experimentCtrl';
import { SIERRA_SETUP_VERSION } from '../../version';
import { debugDownloadMsg } from '../api/v2/verificationCtrl';
import apiProcess from '../api/utility';

const SIERRA_VERSION = '3.0.0';
/**
 * Get project list by type name
 *
 * @export
 * @returns Promise
 */
export function getProjectList() {
  return new Promise((resolve, reject) => {
    getProjectsByTypeNameVersion('PinToPin', SIERRA_VERSION).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve([]);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve([]);
    });
  });
};

/**
 * Get project by project IdName
 * @export
 * @param projectId
 * @returns Promise
 */
export function getProjectByProjectId(projectId) {
  return new Promise((resolve, reject) => {
    if (projectId) {
      getProjectInfo(projectId).then(res => {
        if (res.data && res.data.code === SUCCESS) {
          resolve(res.data.data);
        } else {
          resolve(null);
        }
      }, error => {
        console.error(error);
        checkError(error);
        resolve(null);
      });
    } else {
      resolve(null);
    }
  });
}

/**
 * Create PDN project
 *
 * @export
 * @param {String} name Project name
 * @returns Promise
 */
export function createProjectPromise(name) {
  return new Promise((resolve, reject) => {
    createProject({ name, typeName: 'PinToPin', version: SIERRA_SETUP_VERSION }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
};

/**
 * Delete project promise
 *
 * @export
 * @param {String} ids Project ID list
 * @returns Promise
 */
export function deleteProjectPromise(ids) {
  return new Promise((resolve, reject) => {
    deleteProjectsByIDs(ids).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
}

export function deleteDesignInProject(id) {
  return new Promise((resolve, reject) => {
    deleteDesignsPromise([id], 'true').then(res => {
      LayoutData.cleanLayoutInfo(id);
      resolve(true);
    }, error => {
      checkError(error);
      resolve(true);
    })
  })
}

export function getSierraTrashList(id) {
  return new Promise((resolve, reject) => {
    getTrashList('PinToPin').then(res => {
      if (res.data.data) {
        const filterTrash = res.data.data.filter(item => item.typeName === 'PinToPin');
        resolve(filterTrash);
      } else {
        resolve([]);
      }
    }, error => {
      checkError(error);
      resolve([]);
    })
  })
}

export function doPinToPinSimulation(verificationIds) {
  return new Promise((resolve, reject) => {
    doSimulation(verificationIds).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else if (res.data.code === CHECK_FAIL || res.data.msg) {
        resolve({ msg: res.data.msg, code: CHECK_FAIL });
      } else {
        resolve(false)
      }
    }, error => {
      if (error && error.response && error.response.data && error.response.data.msg) {
        resolve({ msg: error.response.data.msg, code: CHECK_FAIL });
      } else {
        resolve(false);
      }
    })
  })
};

/**
 * Get sierra pintopin log
 * 2019.11.20
 *
 * @export
 * @param {string} projectId
 * @param {*} path path
 * @returns Promise
 */
export function getLogInSierraPinToPinProject(verificationId) {
  return new Promise((resolve, reject) => {
    getSierraLog(verificationId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(false)
      }
    }, error => {
      resolve(false)
    });
  })
}

/**
 * Upload debug file sierra nevada
 * 2019/11/29

 * @export
 * @param {String} verificationId  verificationId 
 * @returns Promise
 */
export function UploadDebug(file, verificationId, step, UploadProgress) {
  return new Promise((resolve, reject) => {
    UploadSierraDebugFile(file, verificationId, step, UploadProgress).then(res => {
      if (res.data && res.data.code === 0) {
        resolve({ status: 'success', msg: res.data.msg });
      } else {
        resolve({ status: 'fail' });
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve({ status: 'fail' });
    });
  });
}

/**
 * debug verify (sierra nevada) 
 * 2019/11/29
 * @export
 * @param {string} step /1(setup)/2(extraction)/3(simulation)
 * @param {string} verificationId verificationId 
 * @returns Promise
 */
export function doDebugVerify({ step, verificationId }) {
  return new Promise((resolve, reject) => {
    debugSierraVerify(step, verificationId).then(res => {
      if (res.data && res.data.code === 0) {
        if (res.data.data) {
          resolve({ code: 0, ...res.data.data });
        } else {
          resolve({ code: 0, msg: res.data.msg });
        }
      } else if (res.data && res.data.code === 1) {
        resolve({ code: 1, msg: res.data.msg });
      } else {
        resolve({ code: 1, msg: res.data && res.data.msg ? res.data.msg : 'Do simulation failed!' });
      }
    }, error => {
      console.error(error);
      resolve({ code: 1, msg: 'Do simulation failed!' });
    });
  });
}

/**
 * If do extraction 
 *
 * 2020/02/06
 * @param interfaceId
 * @param ifDoExtraction
 * @export
 * @returns Promise
 */
export function reExtraction(interfaceId, ifDoExtraction) {
  return new Promise((resolve, reject) => {
    saveReExtraction(interfaceId, ifDoExtraction).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve(null);
    });
  });
};

/**
 * Change interface name
 * 2020.02.11
 * @param verificationId
 * @param interfaceName
 * @export
 * @returns XMLHttpRequest
 */
export function changeInterfaceName(verificationId, name, tag = "") {
  return new Promise((resolve, reject) => {
    changeSierraInterfaceName(verificationId, name, tag).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  });
};

/**
 * Upload TR0 file
 *
 * @export
 * @param {*} file TR0 file
 * @param {*} verificationId
 * @param {*} UploadProgress
 * @returns
 */
export function UploadTR0(file, verificationId, UploadProgress) {
  return new Promise((resolve, reject) => {
    uploadTR0File(file, verificationId, UploadProgress).then(res => {
      if (res.data && res.data.code === 0) {
        resolve({ status: 'success', msg: res.data.msg });
      } else {
        resolve({ status: 'fail' });
      }
    }, error => {
      console.error(error);
      checkError(error);
      resolve({ status: 'fail' });
    });
  });
}

/**
 * sierra copy project
 * 2020.03.04
 * @param projectId
 * @export
 * @returns XMLHttpRequest

 */
export function sierraProjectCopy(projectId, projectName) {
  return apiProcess(sierraCopyProject, { projectId, projectName });
};

export function sierraInterfaceCopy(verificationId, interfaceName) {
  return apiProcess(sierraCopyInterface, { verificationId, interfaceName });
};

/**
 * sierra  project rename
 * 2020.05.14
 * @param projectId
 * @export
 * @returns XMLHttpRequest

 */
export function changeProjectName(projectId, projectName) {
  return new Promise((resolve, reject) => {
    projectRename({ projectId, projectName }).then(res => {
      if (res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      } else {
        resolve(null)
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    });
  });
};

/**
 * Debug download log message
 *
 * @export
 * @param {*} verificationId
 * @param {*} type
 * @returns
 */
export function debugDownMsg(verificationId, type) {
  return new Promise((resolve, reject) => {
    debugDownloadMsg(verificationId, type).then(res => {
      if (res && res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      console.error(error);
      checkError(error, false);
      resolve(null);
    })
  })
}

/**
 * Get design unit from layout.db
 *
 * @export
 * @param {String} id design id
 * @returns
 */
export function getDesignUnitFromLayoutDB(id) {
  return apiProcess(getUnitFromLayoutDB, id)
};

const SIERRA = 'Sierra';

export function editExperimentGroupList({ id = "", name, version, verificationId }) {
  return apiProcess(editExperimentGroup, { data: { id, name, version, verificationId }, product: SIERRA })
}

export function getExperimentGroupList(verificationId) {
  return apiProcess(getExperimentGroup, { verificationId })
}

export function deleteExperimentGroupList(experimentGroupId) {
  return apiProcess(deleteExperimentGroup, { experimentGroupId })
}

export function getExperimentsList(experimentGroupId) {
  return apiProcess(getExperiments, { experimentGroupId })
}

export function updateExperiment(params) { //params = { id, groupId, name, content, version, projectId }
  return apiProcess(editExperiment, { params, product: SIERRA })
}

export function runSweeping(experimentIds) {
  return apiProcess(simulateSweeping, { experimentIds }, false, true)
}

export function deleteExperimentById(experimentId) {
  return apiProcess(deleteExperiment, { experimentId })
}

export function deleteExperimentByIds(deleteArr) {
  return apiProcess(deleteExperiments, deleteArr)
}

export function getRunningExperimentLog(experimentId) {
  return apiProcess(getExperimentLog, { experimentId })
}

export function getCurrentExperimentLog(experimentId) {
  return apiProcess(getExperimentMonitor, { experimentId }, false, false, true)
}

export function getExperimentResultPromise({ experimentId, option, designId }) {
  let path = `experimentId=${experimentId}&option=${option}`;
  if (designId) {
    path = `${path}&designId=${designId}`;
  }
  return apiProcess(getExperimentResult, path, false, false, true);
}

export function getExperimentChannelList(experimentId) {
  return apiProcess(getExperimentSNPFile, { experimentId })
}

export function getExperimentCurve({ designId, experimentId, path, cols, rows }) {
  return apiProcess(getExperimentChannel, { designId, experimentId, path, cols, rows }, false, false, true)
}

export function cancelCurrentExperiment(experimentId) {
  return apiProcess(cancelExperiment, { experimentId })
}

export function getSweepResultsExsit(experimentGroupId) {
  return apiProcess(getExperimentsResultExsit, { experimentGroupId })
}

export function saveSweepColumns({ id, tableColumns }) {
  return apiProcess(postExperimentColumns, { id, tableColumns })
}

export function getExperimentDebugOption(id) {
  return apiProcess(experimentDebugOption, { id })
}

export function getSierraDebugItemList(projectId) {
  return apiProcess(getDebugList, projectId)
}

export function uploadSierraConnections({ projectId, file, fileName }) {
  return apiProcess(uploadProjectConnections, { projectId, file, fileName })
}

export function getSierraConnections(projectId) {
  return apiProcess(getProjectConnections, projectId)
}

export function updateSierraConnections({ projectId, connections, append }) {
  return apiProcess(saveProjectConnections, { projectId, connections, append })
}

export function getProjectConfig(projectId) {
  return apiProcess(getProjectSearchConfig, projectId);
}

export function updateProjectConfig(projectId, config) {
  return apiProcess(updateProjectSearchConfig, { projectId, config });
}

export function getReportStatus(projectId) {
  return apiProcess(getSierraReportStatus, projectId, false, true)
};

export function generateReport(projectId, param) {
  return apiProcess(generateSierraReport, { projectId, param }, false, true)
};

/**
 * Get sierra project report file
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
export function getReportFile({ projectId }) {
  return apiProcess(getSierraReportFile, { projectId });
}

export function getReportConfigPromise(projectId) {
  return apiProcess(getSierraReportConfig, projectId);
}

export function saveReportConfigPromise({ projectId, config }) {
  return apiProcess(saveSierraReportConfig, { projectId, config });
}

export function getReportLog(projectId) {
  return apiProcess(getSierraReportLog, projectId);
}

export function getAllExperimentsMeasurement(verificationIds) {
  return apiProcess(getSierraAllExperimentsMeasurement, verificationIds)
}