import React, { Component, Fragment } from 'react';
import { Input, Spin } from 'antd';
import Panel from '@/components/Panel';
import { updateLibraryData } from '../../../services/Cascade/library';
import PinMapStore, { pinMapLibraryVersion } from '../../../services/Cascade/library/pinMapHelper';
import { createPortal } from 'react-dom';
import { PIN_MAP } from '../../../constants/libraryConstants';
import {
  deleteCurrPinInfo,
} from '../../../services/Designs/pinMapHelper';
import PinMapTable from './pinMapTable';

class PinMapPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pinTable: [],
      loading: true,
      pinStore: [],
      errorMsg: '',
      save: false,
      showExtra: false,
      pinComp: '',
      libraryLoading: true
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    this.getLibraryData();
  }

  componentDidUpdate = (prevProps) => {
    const { libraryInfo, disabled } = this.props;
    const { libraryId } = libraryInfo;
    if ((libraryId && libraryId !== prevProps.libraryInfo.libraryId) || disabled !== prevProps.disabled) {
      this.getLibraryData();
    }
  }

  getLibraryData = async () => {
    const { libraryInfo, pinMapList, designId } = this.props;
    const { partNumber, libraryId, type } = libraryInfo;
    const pinMapName = pinMapList ? pinMapList.map(item => item.name) : [];
    let pinTable = [], libraryName = '';
    if (libraryId) {
      const res = await PinMapStore.getPinMapLibrary({ libraryId, partNumber, type }, designId);
      const { name, config } = res || {};
      pinTable = config && config.pinTable ? config.pinTable : [...pinTable];
      libraryName = name;
    } else {
      libraryName = `${partNumber}`
      let index = 1;
      while (pinMapName.includes(libraryName)) {
        index = index + 1;
        libraryName = `${partNumber}_${index}`
      }
    }
    this.setState({
      name: libraryName,
      pinTable: pinTable,
      loading: false,
      libraryLoading: false
    })
  }

  closeModal = async () => {
    const { errorMsg, name, save } = this.state;
    const { libraryInfo, disabled, designId } = this.props;
    const { libraryId, partNumber } = libraryInfo;
    const pinTable = JSON.parse(JSON.stringify(this.state.pinTable));

    if (!pinTable.length || (pinTable.length === 1 && !pinTable[0].output.length)) {
      this.props.closeWithoutSave && this.props.closeWithoutSave();
      return;
    }

    if (errorMsg) {
      return;
    }

    this.setState({
      loading: true
    })

    let res = {}
    const _pinTable = deleteCurrPinInfo(pinTable);
    if (!disabled) {
      const data = {
        id: libraryId || '',
        name,
        config: { pinTable: _pinTable },
        version: pinMapLibraryVersion,
        type: PIN_MAP,
        partName: partNumber
      }

      try {
        res = await updateLibraryData({ data, config: this.config });
        PinMapStore.savePinMap({ ...res, config: { pinTable: _pinTable } }, designId, partNumber);
      } catch (error) {
        console.error(error);
        this.setState({
          loading: true
        })
      }
    }

    this.props.closeModal(res, libraryInfo, save);
  }

  changeName = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  saveName = () => {
    const { name } = this.state;
    const { pinMapList, libraryInfo } = this.props;
    const { libraryId } = libraryInfo
    const nameList = pinMapList.filter(item => item.id !== libraryId).map(item => item.name);
    const errorMsg = nameList.includes(name) ? 'Current library name has already exists!' : ''
    this.setState({
      errorMsg
    })
  }

  savePinTable = (table) => {
    this.setState({
      pinTable: table,
      save: true
    })
  }

  render() {
    const { name, loading, errorMsg, pinTable, libraryLoading } = this.state;
    const { disabled } = this.props;
    const content = (
      <Fragment>
        <Panel
          className='library-data-panel'
          position='panel-center-left'
          title={name}
          zIndex={2000}
          onCancel={() => { this.closeModal() }}
          width={700}
          draggable
          minHeight={200}
          maxHeight={800}
        >
          <div className='library-data-panel-content-box'>
            <Spin spinning={loading} tip='Loading...'>
              <div>
                <Input
                  className='model-input'
                  placeholder='Name'
                  value={name}
                  disabled={disabled}
                  onChange={(e) => this.changeName(e)}
                  onBlur={(e) => this.saveName(e)}
                />
              </div>
              <div className="cascade-pin-map-content">
                <PinMapTable
                  {...this.props}
                  pinTable={pinTable}
                  savePinTable={this.savePinTable}
                  libraryLoading={libraryLoading}
                />
              </div>
              {errorMsg && <span className='model-name-error-msg' style={{ marginTop: 10 }}>{errorMsg}</span>}
            </Spin>
          </div>
        </Panel>
        <div id='cascade-pin-map-dialog'></div>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default PinMapPanel
