import React, { Component, Fragment } from 'react';
import Table from '@/components/EditableTable';
import designConstructor from '../../../../services/helper/designConstructor';
import { CloseOutlined, RetweetOutlined } from '@ant-design/icons';
import { Checkbox, Input, Tooltip, Tabs } from 'antd';
import { getTextWidth } from '../../../../services/helper/getTextWidth';
import { checkNameFormat } from '../../../../services/helper/nameFormatCheck';

const selectSignalsColumns = [
  {
    dataIndex: "check",
    key: "check",
    width: 80
  },
  {
    title: 'Signal Name',
    dataIndex: 'signalName',
    width: '26%'
  },
  {
    title: 'Signal',
    dataIndex: 'net',
    children: []
  }
],
  interfaceColumns = [
    {
      title: 'Signal Name',
      dataIndex: 'name',
      width: '26%'
    },
    {
      title: 'Signal',
      dataIndex: 'net',
      children: []
    }
  ]
class InterfaceCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connDataList: [],
      interfacesData: [],
      connDisplay: "Net",
      interfaceDisplay: "Net"
    }
  }

  componentDidMount = () => {
    this.getSignalsInfo()
    this.getInterfacesInfo();
    this.props.updateConnectionStatus(false)
  }

  componentDidUpdate = (prevProps) => {
    const { updateConnStatus } = this.props;
    if (updateConnStatus && updateConnStatus !== prevProps.updateConnStatus) {
      this.getSignalsInfo();
      this.getInterfacesInfo();
      this.props.updateConnectionStatus(false)
    }
  }

  getSignalsInfo = () => {
    const { connInterfaces } = this.props;
    let existPCB = [], data = [], signalNames = [];
    const columns = JSON.parse(JSON.stringify(selectSignalsColumns));

    const _connInterfaces = connInterfaces.map(item => {
      return [
        ...item.dataList.map((it, index) => { return { ...it, groupIndex: item.index, groupLength: index === 0 ? item.dataList.length : 0 } })
      ]
    }).flat(2);

    for (let item of _connInterfaces || []) {

      const pcbList = [...new Set(item.pcbConns)].filter(item => !existPCB.includes(item));
      existPCB.push(...item.pcbConns);

      columns[2].children.push(...pcbList.map(it => {
        return {
          title: designConstructor.getDesignName(it),
          dataIndex: it,
          key: it,
          render: (text, record) => {
            if (!record.signalList[it]) {
              return <span className='aurora-table-row-disabled'></span>
            }
            const { connDisplay } = this.state;
            const signalData = (record.signalList[it] || []).map(_item => _item.signalMergeName).join(", ");
            const signalNets = (record.signalList[it] || []).map(_item => _item.nets).flat(2).join(", ");
            return connDisplay === "Net" ?
              <span title={`Signal: ${signalData}`}>{signalNets}</span>
              : <span title={`Nets: ${signalNets}`}>{signalData}</span>
          }
        }
      }));
      data.push({
        pcbConns: [...new Set(item.pcbConns)],
        signal: item.signal,
        signalName: item.signalName,
        signalNetName: item.signalNetName,
        signalList: item.signalList,
        signalLength: 1,
        groupLength: item.groupLength,
        groupIndex: item.groupIndex
      });

      /*   if (item.pcbConnectionList && item.pcbConnectionList.length > 1) {
          let signalLength = item.pcbConnectionList.length;
          let i = 0;
          for (let conn of item.pcbConnectionList) {
            let _signalList = {};
            for (let it of conn) {
              _signalList[it] = item.signalList[it]
            }
            data.push({
              pcbConns: [...conn],
              signal: item.signal,
              signalName: item.signalName,
              signalNetName: item.signalNetName,
              signalList: _signalList,
              signalLength: i === 0 ? signalLength : 0
            });
            i++;
          }
        } else {
          data.push({ ...item, signalLength: 1 });
        } */

      signalNames.push(item.signalName);
    }
    const _signalName = signalNames.sort((a, b) => b.length - a.length)[0];
    columns[1].width = _signalName ? getTextWidth(_signalName, 14) + 60 : "26%";

    columns[0].title = () => {
      const { checked, indeterminate, selectedProportion } = this.getCheckBoxStatus(null)
      return <Fragment>
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onChange={(e) => this.checkSignals(e, 'all')}
        />
        <span className="select-checkbox-title-proportion">{selectedProportion}</span>
      </Fragment>
    }

    columns[0].render = (check, record) => {
      const { checked } = this.getCheckBoxStatus(record.groupIndex)
      return <div>
        <Checkbox
          checked={checked}
          onChange={(e) => this.checkSignals(e, record.groupIndex)}
        />
      </div>
    }

    columns[0].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.groupLength,
        tdClassName: "interface-creation-table-check-td"
      }
    }

    columns[1].render = (name, record) => {
      return <div
        className='interface-creation-conn-signal-content'>
        <span title={record.signalName}>{record.signalName}</span>
        <CloseOutlined
          title="Delete the signal"
          onClick={(e) => this.deleteConnSignals(e, record.groupIndex, record.signalName)}
          className='interface-creation-delete-signal-icon' />
      </div>;
    }

    columns[1].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'signalName',
        rowSpan: record.signalLength,
        handleSave: (row) => this.updateConnSignalName(row, record)
      }
    }

    this.setState({
      connColumns: columns,
      connDataList: data
    })
  }

  getInterfacesInfo = () => {
    const { interfaceList = [], expandedInterfaceKey } = this.props;
    let interfacesData = [], _expandedInterfaceKey = "";

    for (let item of interfaceList || []) {
      const columns = JSON.parse(JSON.stringify(interfaceColumns));
      let signals = [], designs = [], columnsObj = {};
      for (let info of item.interfaces) {

        if (!columnsObj[info.designId]) {
          columnsObj[info.designId] = true;
          designs.push(info.designId);
        }
        for (let signal of info.content) {
          const index = signals.findIndex(it => it.name === signal.name);
          if (index > -1) {
            signals[index].signalList[info.designId] = [...signal.nets];
          } else {
            signals.push({
              name: signal.name,
              signalList: {
                [info.designId]: [...signal.nets]
              }
            })
          }
        }
      }
      for (let pcbId of designs) {
        columns[1].children.push({
          title: designConstructor.getDesignName(pcbId),
          dataIndex: pcbId,
          key: pcbId,
          render: (text, record) => {
            if (!record.signalList[pcbId]) {
              return <span className='aurora-table-row-disabled'></span>
            }
            const { interfaceDisplay } = this.state;

            const signalNets = (record.signalList[pcbId] || []).join(", ");
            return interfaceDisplay === "Net" ?
              <span title={`Signal: ${record.name}`}>{signalNets}</span>
              : <span title={`Nets: ${signalNets}`}>{record.name}</span>
          }
        })
      }

      columns[0].onCell = (record) => {
        return {
          record,
          edit: true,
          dataIndex: 'name',
          handleSave: (row) => this.updateInterfaceSignalName(row, record, item.interfaceName)
        }
      }

      columns[0].render = (name, record) => {
        return (
          <div
            className='interface-creation-conn-signal-content'>
            <span title={record.name}>{record.name}</span>
            <CloseOutlined
              title="Delete the signal"
              onClick={(e) => this.deleteInterfaceSignals(e, record.name, item.interfaceName)}
              className='interface-creation-delete-signal-icon' />
          </div>
        );
      }

      const _signal = signals.sort((a, b) => b.name.length - a.name.length)[0];
      columns[0].width = _signal ? getTextWidth(_signal.name, 14) + 60 : "26%";

      interfacesData.push({
        columns,
        interfaceName: item.interfaceName,
        editName: item.interfaceName,
        signals
      })
    }
    const names = interfacesData.map(item => item.interfaceName);
    _expandedInterfaceKey = expandedInterfaceKey && names.includes(expandedInterfaceKey) ? expandedInterfaceKey : names[0];
    this.setState({
      interfacesData
    })

    this.props.interfaceExpandChange(_expandedInterfaceKey)
  }

  getCheckBoxStatus = (signalName) => {
    const { selectedSignalKeys = [], connInterfaces } = this.props;
    const columnsIndexList = connInterfaces.map(item => item.index);

    let checked = false, indeterminate = false;
    if (signalName) {
      if (selectedSignalKeys.includes(signalName)) { checked = true }
      else { checked = false }
      return { checked }
    }
    const allIndexList = [...columnsIndexList];
    const currSelectedSignals = selectedSignalKeys.filter(item => allIndexList.includes(item));

    const selectedProportion = `${currSelectedSignals.length}/${allIndexList.length}`;
    if (currSelectedSignals.length === 0) { return { checked, indeterminate, selectedProportion }; }
    if (allIndexList.length === currSelectedSignals.length) {
      checked = true;
      indeterminate = false;
    } else {
      checked = false;
      indeterminate = true;
    }
    return { checked, indeterminate, selectedProportion };
  }

  checkSignals = (e, groupIndex, key) => {
    e && e.stopPropagation();
    const { selectedSignalKeys, connInterfaces } = this.props;
    let _selectedSignalKeys = [...selectedSignalKeys];
    const columnsIndexList = connInterfaces.map(item => item.index);
    const currSelectedKeys = columnsIndexList.filter(item => _selectedSignalKeys.includes(item));
    if (groupIndex === "all") {
      if (currSelectedKeys.length === columnsIndexList.length) {
        //uncheck all
        _selectedSignalKeys = [];
      } else {
        //check all
        _selectedSignalKeys = [...columnsIndexList];
      }
    } else {
      if (currSelectedKeys.includes(groupIndex)) {
        _selectedSignalKeys = _selectedSignalKeys.filter(item => item !== groupIndex);
      } else {
        _selectedSignalKeys.push(groupIndex)
      }
    }
    this.props.updateSelectSignalKeys(_selectedSignalKeys);
  }

  updateConnSignalName = (record, prevRecord) => {
    const { connDataList } = this.state;
    const { connInterfaces } = this.props;

    let _connInterfaces = [...connInterfaces];
    let _connDataList = [...connDataList];

    const prevNames = connDataList.map(item => item.signalName);

    if (checkNameFormat(record.signalName) || prevNames.includes(record.signalName)) {
      return;
    }

    const connIndex = _connInterfaces.findIndex(item => item.index === record.index);

    const dataIndex = connIndex > -1 ? _connInterfaces[connIndex].dataList.findIndex(item => item.signalName === prevRecord.signalName) : -1;
    if (connIndex > -1 && dataIndex > -1) {
      _connInterfaces[connIndex].dataList[dataIndex].signalName = record.signalName;
    }

    const _connIndex = _connDataList.findIndex(item => item.signalName === prevRecord.signalName);
    if (_connIndex > -1) {
      _connDataList[_connIndex].signalName = record.signalName;
    }
    this.props.updateConnInterfaces(_connInterfaces);
    this.setState({
      connDataList: _connDataList
    })
  }

  interfaceNameChange = (e, prevName) => {
    const { interfacesData } = this.state;
    let _interfacesData = [...interfacesData];
    const index = _interfacesData.findIndex(item => item.interfaceName === prevName);
    if (index < 0) {
      return;
    }
    _interfacesData[index].editName = e.target.value;
    _interfacesData[index].error = null;
    this.setState({
      interfacesData: _interfacesData
    })
  }

  inputBlur = (e, prevName) => {
    const { interfaceList, channelList = [], expandedInterfaceKey, selectedCombineInterface } = this.props;
    const { interfacesData } = this.state;
    let _interfaceList = [...interfaceList],
      _interfacesData = [...interfacesData],
      _expandedInterfaceKey = expandedInterfaceKey;
    const index = _interfaceList.findIndex(item => item.interfaceName === prevName);
    if (index < 0) {
      return;
    }
    const names = _interfaceList.map(item => item.interfaceName).filter(item => item !== prevName);
    const name = e.target.value;
    let error = checkNameFormat(name)

    const findChannel = channelList.find(item => item.name === name);
    if (findChannel || names.includes(name)) {
      error = `${name} already exists!`;
    }
    const _index = _interfacesData.findIndex(item => item.interfaceName === prevName);
    if (error) {
      _interfacesData[_index].error = error;
    } else {
      _interfaceList[index].interfaceName = name;
      _interfacesData[_index].interfaceName = name;
      _interfacesData[_index].editing = false;
      _interfacesData[_index].editName = false;
      if (expandedInterfaceKey === prevName) {
        _expandedInterfaceKey = name;
      }
      this.props.updateInterfaceList(_interfaceList);
      this.props.interfaceExpandChange(_expandedInterfaceKey)
      if (selectedCombineInterface === prevName) {
        this.props.selectCombineInterface(name)
      }
    }

    this.setState({
      interfacesData: _interfacesData
    })
  }

  deleteInterface = (e, interfaceName) => {
    e.stopPropagation()
    const { interfaceList, expandedInterfaceKey, selectedCombineInterface } = this.props;
    const { interfacesData } = this.state;
    let _interfaceList = [...interfaceList],
      _interfacesData = [...interfacesData],
      _expandedInterfaceKey = expandedInterfaceKey;

    _interfacesData = _interfacesData.filter(item => item.interfaceName !== interfaceName);
    _interfaceList = _interfaceList.filter(item => item.interfaceName !== interfaceName);
    if (_expandedInterfaceKey === interfaceName) {
      _expandedInterfaceKey = _interfaceList[0] ? _interfaceList[0].interfaceName : "";
    }

    if (selectedCombineInterface === interfaceName) {
      this.props.selectCombineInterface(2) // 2 === NEW_INTERFACE
    }

    this.props.updateInterfaceList(_interfaceList);
    this.props.interfaceExpandChange(_expandedInterfaceKey)
    this.setState({
      interfacesData: _interfacesData
    })
  }

  editingInterfaceName = (interfaceName) => {
    const { interfacesData } = this.state;

    let _interfacesData = [...interfacesData];

    const index = _interfacesData.findIndex(item => item.interfaceName === interfaceName);

    _interfacesData[index].editName = interfaceName;
    _interfacesData[index].editing = true;
    this.setState({
      interfacesData: _interfacesData
    })
  }

  getInterfaceNameEdit = (itemData) => {
    const { editName, interfaceName, error, editing } = itemData || {};
    const width = getTextWidth(editName || interfaceName, 14);
    return (
      <div className='sierra-interface-creation-name-edit'>
        {editing ? <Tooltip
          className='aurora-error-tooltip'
          title={error ? error : null}
          overlayClassName='aurora-error-msg-tooltip'
          open={error ? true : false}
        >
          <Input
            className='aurora-input'
            value={editName || ""}
            style={{ width: width + 44 }}
            onChange={(e) => this.interfaceNameChange(e, interfaceName)}
            onPressEnter={(e) => this.inputBlur(e, interfaceName)}
            onBlur={(e) => this.inputBlur(e, interfaceName)}
          />
        </Tooltip>
          : <label
            style={{ width: width + 20 }}
            className='sierra-interface-creation-name-main'
            onDoubleClick={() => this.editingInterfaceName(interfaceName)}
          >{interfaceName}</label>}
        <CloseOutlined
          className="sierra-interface-delete-icon"
          title="Delete the interface"
          onClick={(e) => this.deleteInterface(e, interfaceName)} />
      </div>
    );
  }

  updateInterfaceSignalName = (record, prevRecord, interfaceName) => {
    const { interfaceList } = this.props;
    const { interfacesData } = this.state;
    let _interfaceList = [...interfaceList],
      _interfacesData = [...interfacesData];
    const index = _interfaceList.findIndex(item => item.interfaceName === interfaceName);
    if (index < 0) {
      return;
    }
    const interfaces = _interfaceList[index].interfaces || [];
    const prevSignalNames = interfaces.map(item => item.content).flat(2).map(item => item.name);

    if (checkNameFormat(record.name) || prevSignalNames.includes(record.name)) {
      return;
    }

    for (let info of interfaces) {
      const _index = info.content.findIndex(item => item.name === prevRecord.name);
      if (_index > -1) {
        info.content[_index].name = record.name;
      }
    }
    _interfaceList[index].interfaces = interfaces;
    this.props.updateInterfaceList(_interfaceList);

    const dataIndex = _interfacesData.findIndex(item => item.interfaceName === interfaceName);
    if (dataIndex < 0) {
      return;
    }

    const _index = _interfacesData[dataIndex].signals.findIndex(item => item.name === prevRecord.name);
    if (_index > -1) {
      _interfacesData[dataIndex].signals[_index].name = record.name;
    }

    this.setState({
      interfacesData: _interfacesData
    })
  }

  changeNetDisplay = (e, type) => {
    const prevValue = this.state[type];
    const newValue = prevValue === "Net" ? "Signal" : "Net";
    this.setState({
      [type]: newValue
    })
  }

  deleteConnSignals = (e, groupIndex, signalName) => {
    e.stopPropagation();
    const { connDataList } = this.state;
    const { selectedSignalKeys, connInterfaces } = this.props;
    let _selectedSignalKeys = [...selectedSignalKeys];
    let _connInterfaces = [...connInterfaces];
    let _connDataList = [...connDataList];

    _connDataList = _connDataList.filter(item => item.signalName !== signalName);
    let flag = true;
    for (let item of _connDataList) {
      if (item.groupIndex !== groupIndex) {
        continue;
      }
      if (flag) {
        item.groupLength = _connDataList.filter(item => item.groupIndex === groupIndex).length
        flag = false;
      } else {
        item.groupLength = 0;
      }
    }


    const index = _connInterfaces.findIndex(item => item.index === groupIndex);
    if (index > -1) {
      _connInterfaces[index].dataList = _connInterfaces[index].dataList.filter(item => item.signalName !== signalName);
      if (!_connInterfaces[index].dataList.length) {
        _selectedSignalKeys = _selectedSignalKeys.filter(item => item !== groupIndex);
      }
      _connInterfaces.splice(index, 1)
    }

    this.props.updateConnInterfaces(_connInterfaces);
    this.props.updateSelectSignalKeys(_selectedSignalKeys);
    this.setState({
      connDataList: _connDataList
    }, () => {
      if (!_connInterfaces.length) {
        this.props.updateConnectionStatus(true)
        this.props.updatePCBSetupExpanded(true)
      }
    })
  }

  deleteInterfaceSignals = (e, signalName, interfaceName) => {
    e.stopPropagation();
    const { interfaceList, expandedInterfaceKey } = this.props;
    const { interfacesData } = this.state;
    let _interfaceList = [...interfaceList],
      _interfacesData = [...interfacesData],
      _expandedInterfaceKey = expandedInterfaceKey;
    const index = _interfaceList.findIndex(item => item.interfaceName === interfaceName);
    if (index < 0) {
      return;
    }
    let interfaces = _interfaceList[index].interfaces || [];

    for (let info of interfaces) {
      info.content = info.content.filter(item => item.name !== signalName);
    }
    interfaces = interfaces.filter(item => !!item.content.length);

    if (!interfaces.length) {
      _interfaceList.splice(index, 1);
      _expandedInterfaceKey = _expandedInterfaceKey === interfaceName ? _interfaceList[0] ? _interfaceList[0].interfaceName : "" : _expandedInterfaceKey
      this.props.interfaceExpandChange(_expandedInterfaceKey)
    } else {
      _interfaceList[index].interfaces = interfaces;
    }

    this.props.updateInterfaceList(_interfaceList);

    const dataIndex = _interfacesData.findIndex(item => item.interfaceName === interfaceName);
    if (dataIndex < 0) {
      return;
    }
    _interfacesData[dataIndex].signals = _interfacesData[dataIndex].signals.filter(item => item.name !== signalName)
    if (!_interfacesData[dataIndex].signals.length) {
      _interfacesData.splice(dataIndex, 1);
    }
    this.setState({
      interfacesData: _interfacesData
    })
  }

  render = () => {
    const { contentMaxHeight, expandedInterfaceKey, getInterfaceHeight, PCBList, maxWidth } = this.props;
    const { connDataList, interfacesData, connColumns, connDisplay, interfaceDisplay } = this.state;
    return (
      <div className='interface-creation-table-main' style={{
        maxHeight: contentMaxHeight - (getInterfaceHeight() || 0),
        height: `calc(100% - ${(getInterfaceHeight() || 0)}px)`
      }}>
        {PCBList.length > 1 ? <Fragment>
          <label className='interface_creation-table-label'>Multi-board Signals</label>
          <RetweetOutlined
            className="interface-creation-retweet-icon"
            title={connDisplay === "Net" ? "Display Signals" : "Display Nets"}
            onClick={(e) => this.changeNetDisplay(e, "connDisplay")} />
          {PCBList.length > 1 ? this.props.getConnectionsButton(true) : null}
          <Table
            columns={connColumns || []}
            dataSource={connDataList || []}
            rowKey={(record) => `${record.signal}`}
            size="small"
            pagination={false}
            scroll={connDataList.length ? { x: maxWidth } : {}}
            className="identification-select-table identification-creation-table"
          />
          {/*  {connDataList.length > 0 ? this.props.getCombineButtons() : null} */}
        </Fragment> : null}
        {interfacesData && interfacesData.length ? <Fragment>
          <label className='interface_creation-table-label'>Interfaces</label>
          <RetweetOutlined
            title={interfaceDisplay === "Net" ? "Display Signals" : "Display Nets"}
            className="interface-creation-retweet-icon"
            onClick={(e) => this.changeNetDisplay(e, "interfaceDisplay")} />
          <Tabs
            bordered={false}
            type="card"
            activeKey={expandedInterfaceKey || ""}
            onChange={this.props.interfaceExpandChange}
            className="creation-interface-tabs interface-creation-content-item"
            items={
              interfacesData.map(item =>
              ({
                key: item.interfaceName,
                label: this.getInterfaceNameEdit(item),
                children: <Table
                  columns={item.columns || []}
                  dataSource={item.signals || []}
                  rowKey={(record) => `${record.name}`}
                  size="small"
                  pagination={false}
                  scroll={item.signals.length ? { x: maxWidth } : {}}
                  className="identification-select-table identification-creation-interface-table"
                />
              })
              )
            }
          />
        </Fragment> : null}
      </div>
    );
  }
}

export default InterfaceCreation;