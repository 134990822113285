import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { QuestionCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import { Tooltip, Popover, message } from 'antd';
import EditableTable from '@/components/EditableTable';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import {
  updateInterfaces,
  editComponentType,
  saveRepeater,
  saveChip,
  saveRLC,
  saveCompPackageModel,
  updateCompComponents,
  saveSplitComponents,
  saveMergeComponents,
  updateProbePins,
  updateAssignStatus,
  updateGetCompPrefixStatus
} from '../../store/sierra/action';
import { getLibraryRepeaterFile, getIbisModelList, getSpiceModelList, getFolderFileDetail,/*  getSpiceParseModels, getTouchstoneParsePorts, */ getTouchstoneParse, getPkgSpiceModelList, getIbisPackageList } from '@/services/Sierra/library';
import {
  PackageModelInfo,
  PackageModelFileInfo,
  getIBISPackageText,
  getSpicePackageText,
  getProbePinsText,
  getRepeaterText,
  getModelFileText,
  getComponentsByPCB
} from '@/services/Sierra'
import { selectChange, selectLayer, changeDisplaySelected } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import { SortFn } from '@/services/helper/sort';
import { getLibraryFileContent } from '@/services/Sierra/library';
import { autoSelectTouchstonePorts } from '@/services/helper/touchstoneHelper';
import PinNodeConnect from '@/components/pinNodeConnect';
import TableTag from '@/components/TableTag';
import { isMac } from '@/services/api/userAgent';
import {
  ICTypes,
  probePinsPointTypes
} from '../../constants';
import {
  CAP,
  IND,
  RES,
  IC,
  COMP_REPEATER,
  CONNECTOR,
  TEST_POINT,
  UNUSED,
  IGNORE,
  JUMPER,
  CHIP,
  TEST_POINT_DISPLAY
} from '@/constants/componentType';
import PackageModel from '@/components/PackageModel';
import ICModelPanel from '@/components/ICModelPanel';
import { SIERRA } from '@/constants/pageType';
import ProbePinsPanel from '@/components/ProbePinsPanel';
import { getSplitPartName } from '@/services/helper/setDefaultName';
import { parseSpiceModelSelector } from '../../../../services/LibraryHelper/SpiceModelHelper';
import RLCCompModel from '@/components/RLCCompModel';
import { RLCModelInfo, getRLCValue, PassiveModel, getModelValue, getModelTypeName, formatRLCValue } from '../../../../services/RLCModel';
import canvas from '@/services/LayoutCanvas';
import componentSetting from '../../../../services/helper/componentsHelper/compSettingHelper';
import { autoAssignRepeaterPorts } from '../../../../services/Sierra/library';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import compTableHelper from '../../../../services/Sierra/helper/compTableHelper';
import { getCompType } from '../../../../services/Designs/compTypeHelper';
import '../index.css';

const RLCType = ['RLC', CAP, IND, RES, JUMPER];
const ChipType = [CHIP, COMP_REPEATER, IC, CONNECTOR];

const compColumns = [{
  title: 'Part Name',
  dataIndex: 'part',
  width: '25%',
  sorter: (a, b) => a.part.localeCompare(b.part),
}, {
  title: 'Components',
  dataIndex: 'comps',
  width: '15%',
}, {
  title: 'Usage',
  dataIndex: 'type',
  width: '10%',
  sorter: (a, b) => a.type.localeCompare(b.type),
}, {
  title: 'Model',
  dataIndex: 'value',
  width: '20%'
}, {
  title: 'Package',
  dataIndex: 'pkg',
  width: '20%'
}, {
  title: 'Probes',
  dataIndex: 'probes',
  width: '15%'
}];

class ComponentTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mergePartList: [],
      mergeVisible: false,
      mergeType: '',
      displayPartNumber: false
    }

    this.getCompColumns();
  }

  getCompColumns = () => {

    compColumns[0].title = () => {
      const { displayPartNumber } = this.state;
      return <div>
        <span className='sierra-comp-table-part-title'>Part {displayPartNumber ? "Number" : "Name"}</span>
        <Tooltip
          title='Toggle to display part name or part number.'
          mouseLeaveDelay={0}
          mouseEnterDelay={0.3}
          overlayClassName='icon-tooltip'
        >
          <RetweetOutlined
            className="sierra-comp-table-part-switch-icon"
            onClick={(e) => this.changePartDisplay(e)}
          />
        </Tooltip>
      </div>
    }
    compColumns[0].render = (text, record) => {
      const { mergeVisible, mergePartList, displayPartNumber } = this.state;
      const partText = displayPartNumber && record.partNumber ? record.partNumber : record.part;
      if (RLCType.includes(record.type) && mergePartList && mergePartList.length > 0 && mergePartList[0] === record.part) {
        return <Popover
          placement="topLeft"
          overlayClassName='merge-part-popover'
          getPopupContainer={() => document.getElementById('sierra-component-row')}
          content={<Fragment>
            <Tooltip title='Merge Part' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className='merge-part-popover-span' onClick={(e) => this.MergePart(e, record)}>Merge</span>
            </Tooltip>
          </Fragment>}
          open={mergeVisible}
        >
          <span onClick={(e) => this.tableOnRow(e, record)} className='sierra-part-name cursor-pointer'>{partText}</span>
        </Popover>
      } else if (RLCType.includes(record.type)) {
        return <span className='cursor-pointer sierra-part-name' onClick={(e) => this.tableOnRow(e, record)}>{partText}</span>
      } else {
        return <span className='sierra-part-name'>{partText}</span>
      }
    }

    compColumns[1].onCell = (record) => {
      const { comps } = record;
      let compsName = [];
      comps.map(item => {
        if (item.type !== UNUSED) {
          compsName.push({
            name: item.name,
            checked: true
          });
        } else {
          compsName.push({
            name: item.name,
            checked: false
          });
        }
        return null;
      });

      return RLCType.includes(record.type) ? {
        record,
        compsName,
        edit: true,
        customInput: TableTag,
        dataIndex: "comps",
        popupContainer: record.comps.length > 1 ? 'sierra-component-row' : 'sierra-content-main',
        changeCompsChecked: this.changeCompsChecked,
        //changeDecapRemove: this.changeDecapRemove,
        select: this.componentSelect,
        splitComps: this.splitComps,
        onClick: (e) => this.spanClick(e),
        isPCBOpened: this.isPCBOpened
      } : null
    }

    compColumns[1].render = (comps, record) => {
      const mac = isMac();
      let tdRef = document.getElementById(`td-${record.index}`);
      const height = tdRef && tdRef.offsetHeight;
      let names = [];
      const isRLC = RLCType.includes(record.type);
      comps.forEach(item => {
        if (item.type !== UNUSED || !isRLC) {
          names.push(item.name);
        }
      })
      return <div
        className='sierra-table-comp' id='sierra-component-row'>
        <span className={!isRLC ? 'sierra-comp-usage-rlc' : ""}
          onClick={!isRLC ? () => this.componentSelect(record, names) : null}>
          {names.length > 0 ? names.join(', ') : "Unstuffed"}
        </span>
        {mac && height && height > 88 ? <div className='sierra-component-arrow'></div> : null}
      </div>
    };

    compColumns[2].onCell = (record) => {
      if (!this.props.pcbLoading && !RLCType.includes(record.COMP_TYPE)) {
        const options = this.getCompType(record.type, record.COMP_TYPE);
        return {
          record: { ...record, type: record.type === TEST_POINT ? TEST_POINT_DISPLAY : record.type },
          edit: 'select',
          options: options,
          dataIndex: 'type',
          handleSave: this.editComponentType
        };
      } else {
        return {
          edit: false
        }
      }
    }

    compColumns[2].render = (text, record) => {
      if (!RLCType.includes(record.COMP_TYPE)) {
        const displayType = record.type === TEST_POINT ? 'Test Point' : record.type;
        const className = this.props.pcbLoading ? "sierra-comp-usage-rlc" : "";
        return <span className={className}>{displayType}</span>
      } else {
        return <span className="sierra-comp-usage-rlc">{record.type}</span>
      }
    }

    compColumns[3].render = (text, record) => {
      if (RLCType.includes(record.type)) {
        return <span>{formatRLCValue(record.type, text, record.model)}</span>;
      } else if (record.type === COMP_REPEATER) {
        const text = <span className='sierra-repeater-editable-cell-value-wrap'>{getRepeaterText(record)}</span>
        return <span>{text}</span>
      } else if (ICTypes.includes(record.type)) {
        //model:[files:[ { fileName, folder, libraryId, type,subckt } ]]
        const text = getModelFileText(record);
        const component = record.model.files && record.model.files.length > 0 && record.model.files[0].libraryId && record.model.files[0].component
        return <span className='sierra-ic-editable-cell-value-wrap'>{text}{component ? `:: ${component}` : ''}</span>
      }
    }

    compColumns[3].onCell = (record) => {
      if (RLCType.includes(record.type)) {
        const { passiveSpiceList, passiveTouchstoneList } = this.props;
        return {
          edit: true,
          text: <span>{formatRLCValue(record.type, record.value, record.model)}</span>,
          record: { ...record, model: this.getRLCModel(record) },
          customInput: RLCCompModel,
          dataIndex: 'model',
          powerNets: this.getNetsOptions(record),
          saveRLCModel: this.saveRLCModel,
          spiceList: passiveSpiceList,
          touchstoneList: passiveTouchstoneList,
          getFileContent: getLibraryFileContent,
          parseModelSelector: parseSpiceModelSelector,
          product: SIERRA
        }
      } else if (record.type === COMP_REPEATER) {
        let model = {};
        if (record.model && record.model.id) {
          model = { files: [], pairs: [] };
          model.files[0] = {
            folder: "",
            fileName: record.model.name,
            libraryId: record.model.id,
            subckt: record.model.subcktName,
            type: COMP_REPEATER
          }
        } else if (record.model.files) {
          model = { ...record.model }
        }
        const pins = record.comps && record.comps[0] && Array.isArray(record.comps[0].pins) ? record.comps[0].pins : [];
        const compName = record.comps && record.comps[0] ? record.comps[0].name : "";
        const text = getRepeaterText(record);
        const { verificationId, repeaterList } = this.props;
        return {
          edit: true,
          verificationId,
          record,
          model,
          pins,
          text,
          compName,
          product: SIERRA,
          displayType: "file",
          className: 'sierra-repeater-editable-cell-value-wrap',
          customInput: PinNodeConnect,
          libraryList: repeaterList ? repeaterList : [],
          dataIndex: 'model',
          saveLibraryModel: this.saveRepeaterModel,
          getLibraryFile: getLibraryRepeaterFile,
          getFolderFileDetail,
          modelType: COMP_REPEATER,
          componentSetting,
          designId: record.pcbId,
          autoAssignRepeaterPorts
        }
      } else if (ICTypes.includes(record.type)) {
        const componentName = record.comps && record.comps[0] ? record.comps[0].name : null;
        const { verificationId, ibisList, spiceList, currentProjectId, autoAssignStatus, updateAssignStatus } = this.props;
        const pins = record.comps && record.comps[0] && Array.isArray(record.comps[0].pins) ? record.comps[0].pins : [];
        const compModel = this.getComponentModel(componentName, record);
        const component = record.model.files && record.model.files.length > 0 && record.model.files[0].libraryId && record.model.files[0].component
        const text = getModelFileText(record) + (component ? `:: ${component}` : '');
        return {
          edit: true,
          verificationId,
          record,
          componentName,
          compModel,
          currentProjectId,
          autoAssignStatus,
          customInput: ICModelPanel,
          product: SIERRA,
          pins,
          displayType: "file",
          className: 'sierra-ic-editable-cell-value-wrap',
          text,
          ibisList,
          spiceList,
          dataIndex: 'model',
          applyModelObj: record.applyModelObj,
          saveLibraryModel: this.saveChipModel,
          updateAssignStatus: updateAssignStatus,
          getIbisModelList: getIbisModelList,
          getIbisPackageList: getIbisPackageList,
          getSpiceModelList: getSpiceModelList,
          getFolderFileDetail
        }
      } else {
        return {
          edit: false,
        }
      }
    }

    compColumns[4].onCell = (record) => {
      const { pcbId, comps } = record;
      const componentName = comps && comps[0] ? comps[0].name : "";
      const pinList = record.comps && record.comps[0] ? this.getPinList(componentName, pcbId) : [];
      const { verificationId, pkgSpiceList, pkgTouchstoneList } = this.props;
      const text = getIBISPackageText(record.pkg);
      if (ICTypes.includes(record.type)) {
        return {
          edit: true,
          record,
          componentName,
          pinList,
          text,
          customInput: PackageModel,
          dataIndex: 'pkg',
          product: SIERRA,
          savePackageModel: this._savePackageModel,
          spiceList: pkgSpiceList,
          touchstoneList: pkgTouchstoneList,
          PackageModelInfo: PackageModelInfo,
          PackageModelFileInfo: PackageModelFileInfo,
          getIbisPackageList: getIbisPackageList,
          getTouchstoneParse: getTouchstoneParse,
          getSPiceParse: getPkgSpiceModelList,
          autoSelectPorts: autoSelectTouchstonePorts,
          verificationId,
          modelType: 'Package',
          displayType: 'model'
        }
      } else {
        return {
          edit: false
        }
      }
    }

    compColumns[4].render = (text, record) => {
      const pkg = record.pkg;
      if (pkg && ICTypes.includes(record.type)) {
        if (pkg.type === 'None') {
          return <span>{pkg.type}</span>
        } else if (pkg.type === 'IBIS') {
          const text = getIBISPackageText(pkg);
          return record.packageFileNotExist ? <div className='sierra-file-check-div'>
            {text}
            <Tooltip
              title={`File ${record.model && record.model.fileName ? record.model.fileName : ""} has been ${record.packageFileNotExist}, the model is invalid.`}
              overlayClassName='aurora-tooltip'
            ><QuestionCircleOutlined className='sierra-file-check-icon' onClick={(e) => this.spanClick(e)} />
            </Tooltip>
          </div> : <span>{text}</span>;
        } else if (pkg.type === "SPICE" || pkg.type === "Touchstone" || pkg.type === "Touchstone / SPICE") {
          const { text, deleteText, updateText } = getSpicePackageText(pkg, record);
          return (deleteText || updateText) ? <div className='sierra-file-check-div'>
            {text ? <span className='sierra-file-text'>{text}, </span> : null}
            <span>{deleteText} {updateText && deleteText ? `, ${updateText}` : null}</span>
            <Tooltip
              title={<Fragment>
                {deleteText ? `Files ${deleteText} has been deleted, the model is invalid.` : null}
                {updateText ? ` Files ${updateText} has been updated, the model is invalid.` : null}
              </Fragment>}
              overlayClassName='aurora-tooltip'
            ><QuestionCircleOutlined className='sierra-file-check-icon' onClick={(e) => this.spanClick(e)} />
            </Tooltip>
          </div> : <span>{text}</span>;
        }
      } else {
        return <span className='sierra-corner-row-disabled'></span>
      }
    }

    compColumns[5].onCell = (record) => {
      const { verificationId } = this.props;
      if (!probePinsPointTypes.includes(record.type)) {
        const text = getProbePinsText(record);
        return {
          edit: true,
          record,
          text,
          product: SIERRA,
          components: record.comps ? record.comps : [],
          customInput: ProbePinsPanel,
          verificationId,
          className: 'sierra-component-probes-td-content',
          dataIndex: 'probes',
          closeModal: this.closeProbeModal
        };
      } else {
        return {
          edit: false
        }
      }
    }

    compColumns[5].render = (text, record) => {
      if (probePinsPointTypes.includes(record.type)) {
        return <span className='sierra-corner-row-disabled'></span>
      } else {
        const text = getProbePinsText(record);
        return <Tooltip
          overlayClassName='aurora-tooltip'
          title={text}
        >
          <span className='sierra-component-probes-td'>{text}</span>
        </Tooltip>
      }
    }
  }

  changePartDisplay = (e) => {
    e && e.stopPropagation();
    this.setState({
      displayPartNumber: !this.state.displayPartNumber
    })
  }

  isPCBOpened = (record) => {
    const { pcbId } = record;
    const { selectedDesignIDs } = this.props;
    return selectedDesignIDs.includes(pcbId);
  }

  getRLCModel = (record) => {
    const { model = {}, value, type } = record;
    let _value = getRLCValue(type, value);
    if (type === JUMPER) { _value = { r: value } }
    const passiveModel = new PassiveModel({ ...model });
    const _model = new RLCModelInfo({ modelType: model.type ? model.type.toLowerCase() : model.type, value: _value, passiveModel });
    return { ..._model };
  }

  getNetsOptions = (record) => {
    const { Interfaces } = this.props;
    let currentInterface = Interfaces ? Interfaces.find(item => item.pcbId === record.pcbId) : null;
    let nets = [], signalNets = [], pwrGndNets = [];
    if (currentInterface && currentInterface.content) {
      if (currentInterface.content.signals) {
        //get signal nets
        currentInterface.content.signals.forEach(item => {
          if (item.nets && item.nets.length > 0) {
            signalNets = [...signalNets, ...item.nets]
          }
        })
      }

      //get power gnd nets
      if (currentInterface.content.powerNets) {
        currentInterface.content.powerNets.forEach(item => {
          if (item.name) {
            pwrGndNets.push(item.name);
          }
        })
      }
    }
    nets = [...signalNets, ...pwrGndNets];
    return nets;
  }

  tableOnRow = (e, record) => {
    e.stopPropagation();
    const { part } = record;
    const { mergePartList, mergeVisible, mergeType } = this.state;
    if (mergePartList && mergePartList.length > 0) {
      if (record.type !== mergeType) {
        message.info("Please select components of the same type to merge")
        return;
      }
    }
    let partList = [...mergePartList], visible = mergeVisible;
    if (!mergePartList.includes(part)) {
      partList.push(part);
    } else {
      partList = partList.filter(item => item !== part);
    }
    if (partList.length > 1) {
      visible = true;
    } else {
      visible = false;
    }
    this.setState({
      mergePartList: partList,
      splitPartName: null,
      mergeVisible: visible,
      mergeType: record.type
    })
  }


  getPinList = (compName, pcbId) => {
    const { Interfaces } = this.props;
    let pinList = [];
    if (!compName || !Interfaces || !Interfaces.length) {
      return pinList;
    }

    const currentInterface = Interfaces.find(item => item.pcbId === pcbId);
    let Components = [];
    if (currentInterface && currentInterface.content && currentInterface.content.components) {
      Components = currentInterface.content.components;
    }
    const Comp = Components.find(item => item.name === compName);
    if (Comp && Comp.pins) {
      Comp.pins.forEach(item => {
        pinList.push({
          pin: item.pin,
          net: item.net,
          signal: item.signal
        })
      })
    }
    return pinList;
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  _savePackageModel = (pkg, record) => {
    const { comps, pcb, pcbId } = record;
    if (comps && comps[0]) {
      this.props._saveCompPackageModel(pkg, comps[0].name, pcb, pcbId);
    }
  }

  getComponentModel = (compName, record) => {
    const { pcbId } = record;
    const { Interfaces } = this.props;
    const currentInterface = (Interfaces || []).find(item => item.pcbId === pcbId);
    let components = [];
    if (currentInterface && currentInterface.content && currentInterface.content.components) {
      components = currentInterface.content.components;
    }
    let model = null;
    const compFind = components.find(item => item.name === compName);
    if (compFind && compFind.model) {
      model = compFind.model;
    }
    return model;
  }

  componentSelect = (record, names) => {
    const { pcbId } = record;
    if (this.isPCBOpened(record)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer(names);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...names] }, pcbId);
      this.props.changeDisplaySelected(true, pcbId);
    }
  }

  saveDecapModel = (record, value) => {
    const { comps, pcb, pcbId } = record;
    const { Interfaces } = this.props;
    let _Interfaces = [...Interfaces];
    const _index = _Interfaces.findIndex(item => item.pcbId === pcbId);
    const editInterface = _Interfaces[_index].content;
    let components = [...editInterface.components];
    comps.forEach(item => {
      const compIndex = components.findIndex(comp => comp.name === item.name);
      components[compIndex].value = value;
    })

    _Interfaces[_index].content.components = [...components];
    this.props._updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId });
  }

  saveRepeaterModel = (model, record) => {
    const { pcb, pcbId, comps } = record;
    if (comps && Array.isArray(comps) && comps[0]) {
      const name = comps[0].name;//component name
      this.props._saveRepeater({ model, name, pcb, pcbId });
    }
  }

  saveChipModel = async ({ files, libType, pairs, record, applyModelObj }) => {
    const { pcb, pcbId, comps } = record;
    this.props._saveChip({ files, libType, pairs, pcb, pcbId, name: comps[0].name, applyModelObj });
  }

  saveRLCModel = (model, record) => {
    const { pcb, pcbId, part, comps } = record;
    const _model = { type: getModelTypeName(model.type), ...model.passiveModel };
    const value = getModelValue(record.type, model.value);
    this.props._saveRLC({ model: _model, pcb, pcbId, value, part, comps });
  }

  getCompType = (type, COMP_TYPE) => {
    let options = [];
    switch (type) {
      case RES:
      case IND:
      case CAP:
      case JUMPER:
        options = [COMP_TYPE, UNUSED];
        break;
      case COMP_REPEATER:
      case IC:
      case CHIP:
      case CONNECTOR:
        options = [IC, COMP_REPEATER, CONNECTOR, UNUSED];
        break;
      case TEST_POINT:
        options = [TEST_POINT_DISPLAY, UNUSED, CONNECTOR];
        break;
      case UNUSED:

        if (RLCType.includes(COMP_TYPE)) {
          options = [COMP_TYPE, UNUSED];
          break;
        }
        if (ChipType.includes(COMP_TYPE)) {
          options = [IC, COMP_REPEATER, CONNECTOR, UNUSED];
          break;
        }

        if (COMP_TYPE === TEST_POINT) {
          options = [TEST_POINT_DISPLAY, UNUSED, CONNECTOR];
          break;
        }

        options = [IC, COMP_REPEATER, CONNECTOR, TEST_POINT_DISPLAY, CAP, IND, RES, JUMPER, UNUSED];
        break;
      // case JUMPER:
      //   options = [type, UNUSED];
      //   break
      default: break;
    }
    return options;
  }

  editComponentType = (row) => {
    const { type, pcbId, comps } = row;
    this.props._editComponentType({ type, comps, pcbId });
  }

  changeCompsChecked = (compsName, checked, record) => {
    const { part, pcb, pcbId, type } = record;
    let chips = [...compsName];
    this.props._updateCompComponents({ part, chips, checked, _type: type, pcb, pcbId });
  }

  splitComps = (compList, record) => {
    const { part, pcb, pcbId } = record;
    let partName = part;
    const comps = this.getComponents();
    const newPartName = getSplitPartName({ components: comps, partName });
    this.setState({
      splitPartName: newPartName,
      mergePartList: []
    });
    this.props.saveSplitComponents({ part, splitPart: newPartName, comps: compList, pcb, pcbId });
    setTimeout(() => {
      this.setState({
        splitPartName: null
      });
    }, 3000);
  }

  MergePart = (e, record) => {
    e.stopPropagation();
    const { mergePartList } = this.state;
    const { pcb, pcbId } = record;
    if (mergePartList.length === 0) {
      return;
    }
    let partName = mergePartList[0];
    this.props.saveMergeComponents({ part: partName, partList: mergePartList, pcb, pcbId });
    this.setState({
      mergePartName: partName,
      mergePartList: []
    });
    setTimeout(() => {
      this.setState({
        mergePartName: null
      });
    }, 3000);
  }

  getComponents = () => {
    const { current, compPrefixLibInfo, compPinMap = {}, Interfaces } = this.props;
    let _components = getComponentsByPCB(Interfaces, current);
    let _partComps = []; // [{part, comps, value, type}]
    _components.forEach((comp) => {
      const { part, name, pins, probePins = [], applyModelObj, pcbId, pcb } = comp;
      const partNumber = auroraDBJson.getPartNumberByPartName(pcbId, part);
      //ignore to unused
      const type = comp.type === IGNORE ? UNUSED : comp.type;
      const pinList = auroraDBJson.getCompPinList(pcbId, name);
      const allPinsLength = pinList.length;
      const passiveTable = compTableHelper.get(pcbId);
      let compType = type;
      const findPassive = passiveTable.find(item => item.part === part);
      if (findPassive && findPassive.usage) {
        compType = findPassive.usage;
      } else {
        compType = getCompType({ compName: name, pinLength: allPinsLength, COMP_PREFIX_LIB: compPrefixLibInfo[pcbId], partName: part, product: SIERRA, compPinMap: compPinMap[pcbId] })
      }

      if (![UNUSED, IGNORE].includes(compType) && ![UNUSED, IGNORE].includes(type) && compType !== type) {
        //compType === Res, type === Cap
        compType = type;
      }
      //ignore to unused
      compType = compType === IGNORE ? UNUSED : compType;

      const rlcIndex = _partComps.findIndex(item => item.part === part);
      if (RLCType.includes(compType)) {
        if (rlcIndex > -1 && _partComps[rlcIndex].type === compType) {
          //according to type and part separate comp.
          _partComps[rlcIndex].comps.push({ name, pins, type, probePins, allPinsLength });
        } else {
          _partComps.push({
            part,
            partNumber,
            comps: [{ name, pins, type, probePins, allPinsLength }],
            type: compType,
            COMP_TYPE: compType,
            value: comp.value,
            model: comp.model,
            pcb,
            pcbId
          });
        }
      } else if (RLCType.includes(type)) {
        if (rlcIndex > -1 && _partComps[rlcIndex].type === type) {
          //according to type and part separate comp.
          _partComps[rlcIndex].comps.push({ name, pins, type, probePins, allPinsLength });
        } else {
          _partComps.push({
            part,
            partNumber,
            comps: [{ name, pins, type, probePins, allPinsLength }],
            type,
            COMP_TYPE: compType,
            value: comp.value,
            model: comp.model,
            pcb,
            pcbId
          });
        }
      } else if (ChipType.includes(type)) {
        //const notExist = comp.model && comp.model.fileName ? this.getFileExist(comp.model) : false;
        //const packageFileNotExist = comp.pkg && comp.pkg.type ? this.getPackageFileExist(comp.pkg) : false;
        _partComps.push({
          part,
          partNumber,
          comps: [{ name, pins, type, allPinsLength }],
          type,
          COMP_TYPE: type,
          model: comp.model,
          pcb,
          pcbId,
          pkg: comp.pkg ? comp.pkg : null,
          applyModelObj
        });
      } else {
        _partComps.push({
          part,
          partNumber,
          comps: [{ name, pins, type, probePins, allPinsLength }],
          type,
          COMP_TYPE: type,
          value: comp.value,
          model: comp.model,
          pcb,
          pcbId,
          pkg: comp.pkg ? comp.pkg : null,
          applyModelObj
        });
      }
    });
    const sort = [CHIP, IC, COMP_REPEATER, CONNECTOR, CAP, RES, JUMPER, IND, TEST_POINT, UNUSED];
    _partComps = SortFn(_partComps, sort, 'COMP_TYPE');
    return _partComps;
  }

  closeProbeModal = (probePinsList, record) => {
    const { part, pcbId } = record;
    this.props._updateProbePins({ probePinsList, part, pcbId });
  }

  render() {
    const { mergePartList, splitPartName, mergePartName } = this.state;
    const dataList = this.getComponents() || [];
    let scrollX = null, scrollY = null, scroll = {};
    if (dataList && dataList.length && dataList.length > 9) {
      scrollY = 342;
    }
    if (scrollY && scrollX) {
      scroll = {
        x: scrollX,
        y: scrollY
      }
    }

    if (!scrollY && scrollX) {
      scroll = {
        x: scrollX
      }
    }

    if (scrollY && !scrollX) {
      scroll = {
        y: scrollY
      }
    }
    return (
      <EditableTable
        rowKey={(record) => `${record.pcbId}-${record.part}-${record.comps.map(item => item.name).join('-')}`}
        columns={compColumns}
        size="small"
        dataSource={dataList}
        scroll={scroll}
        className='sierra-component-table space-10'
        onRow={record => {
          let rowClassName = '';
          if (record.part === splitPartName && mergePartList.length === 0 && !mergePartName) {
            rowClassName = 'sierra-component-split-row'
          } else if (mergePartList.length > 0 && mergePartList.includes(record.part) && !mergePartName) {
            rowClassName = 'sierra-component-select-row'
          } else if (mergePartName && record.part === mergePartName) {
            rowClassName = 'sierra-component-merge-row'
          }
          return RLCType.includes(record.type) && {
            className: rowClassName
          };
        }}
      />
    )
  }
}

const mapState = (state) => {
  const { SierraReducer: { sierra, project: { currentProjectId, verificationId, selectedKeys } } } = state;
  const autoAssignStatus = sierra.autoAssignStatus
  const sierraInfo = sierra.sierraInfo;
  let Interfaces = [], currentPCBs = [];
  if (sierraInfo) {
    Interfaces = sierraInfo.Interfaces || [];
    currentPCBs = sierraInfo.Interfaces ? sierraInfo.Interfaces.map(item => item.pcbId) : [];
  }
  return {
    Interfaces,
    currentPCBs,
    currentProjectId,
    verificationId,
    pcbLoading: sierra.pcbLoading,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    autoAssignStatus,
    updateCompPrefixLibStatus: sierra.updateCompPrefixLibStatus
  }
};

const mapDispatch = (dispatch) => ({
  _editComponentType({ type, comps, pcbId }) {
    dispatch(editComponentType({ type, comps, pcbId }));
  },
  _updateInterfaces({ Interfaces, pcb, pcbId }) {
    dispatch(updateInterfaces({ Interfaces, pcb, pcbId }));
  },
  _saveRepeater({ model, name, pcb, pcbId }) {
    dispatch(saveRepeater({ model, name, pcb, pcbId }));
  },
  _saveChip({ /* model, fileInfo, use, */files, libType, pairs, pcb, pcbId, name, pkg, applyModelObj }) {
    dispatch(saveChip({ /* model, fileInfo, use, */files, libType, pairs, pcb, pcbId, name, pkg, applyModelObj }));
  },
  _saveRLC({ model, pcb, pcbId, value, part, comps }) {
    dispatch(saveRLC({ model, pcb, pcbId, value, part, comps }));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  _updateCompComponents({ part, chips, checked, _type, pcb, pcbId }) {
    dispatch(updateCompComponents({ part, chips, checked, _type, pcb, pcbId }))
  },
  _saveCompPackageModel(pkg, component, pcb, pcbId) {
    dispatch(saveCompPackageModel(pkg, component, pcb, pcbId))
  },
  saveSplitComponents({ part, splitPart, comps, pcb, pcbId }) {
    dispatch(saveSplitComponents({ part, splitPart, comps, pcb, pcbId }))
  },
  saveMergeComponents({ part, partList, pcb, pcbId }) {
    dispatch(saveMergeComponents({ part, partList, pcb, pcbId }))
  },
  _updateProbePins({ probePinsList, part, pcbId }) {
    dispatch(updateProbePins({ probePinsList, part, pcbId }))
  },
  updateAssignStatus(status) {
    dispatch(updateAssignStatus(status))
  },
  _updateGetCompPrefixStatus(status) {
    dispatch(updateGetCompPrefixStatus(status))
  }
})

export default connect(mapState, mapDispatch)(ComponentTable);
