const TYPE = "Cascade/DesignTree";

// Setup
export const GET_POWER_TREE_CONTENT = `${TYPE}/get_power_tree_content`;
export const SET_POWER_TREE_DATA = `${TYPE}/set_power_tree_data`;
export const RESET_SETUP = `${TYPE}/reset_setup`;


// PCB
export const SELECT_PCB = `${TYPE}/select_pcb`;
export const COMPONENT_SETTING_UPDATE = `${TYPE}/component_setting_update`;

// Power Trees
export const UPDATE_POWER_TREE = `${TYPE}/update_power_tree`;
export const OPEN_PIN_SELECT_PANEL = `${TYPE}/open_pin_select_panel`;
export const UPDATE_TREE_LOADING = `${TYPE}/update_tree_loading`;
export const DRAW_TREE = `${TYPE}/draw_tree`;
export const DELETE_TREE_ITEM = `${TYPE}/delete_tree_item`;
export const INIT_DESIGN_TREE = `${TYPE}/init_design_tree`;
export const DELETE_POWER_TREE = `${TYPE}/delete_power_tree`;
export const SET_TREE_TIP = `${TYPE}/set_tree_tip`;

// Others
export const UPDATE_LOADING_PAGE = `${TYPE}/update_loading_page`;
export const UPDATE_ERROR = `${TYPE}/update_error`;
export const UPDATE_COMP_PREFIX = `${TYPE}/update_comp_prefix`;
export const UPLOAD_SPEC = `${TYPE}/upload_spec`;
export const UPDATE_RUNNING_STATUS = `${TYPE}/update_running_status`;

// Setting
export const SAVE_OPTION = `${TYPE}/save_option`;
export const SAVE_EXTRACTION = `${TYPE}/save_extraction`;
export const UPDATE_TREE_SETTING = `${TYPE}/save_tree_setting`;

// Results
export const GET_RESULTS = `${TYPE}/get_results`;
export const SHOW_RESULTS = `${TYPE}/show_results`;
export const UPDATE_TREE_MONITOR = `${TYPE}/update_tree_monitor`;
export const GET_SPICE_RESULTS = `${TYPE}/get_spice_results`;