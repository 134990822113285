import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { Button, Spin, Switch, Transfer } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import hash from 'object-hash';

class PowerSelectPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 500,
      maxHeight: 500,
      value: props.select,
      selectedNetsList: props.select,
      prevHash: hash(props.select.sort()),
      currentHash: hash(props.select.sort()),
      showAllNets: false
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 500),
      maxHeight: getPanelMaxHeight(offset, 500),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  closeModal = () => {
    this.props.closePanel();
  }

  moveSelected = (nextTargetKeys) => {
    this.setState({ selectedNetsList: nextTargetKeys });
  }

  selectNets = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ value: [...sourceSelectedKeys, ...targetSelectedKeys] });
  }

  changeSwitch = (checked) => {
    this.setState({
      showAllNets: checked
    })
  }

  render() {
    const { value, maxWidth, maxHeight, selectedNetsList, showAllNets } = this.state;
    const { allPowerNets, targetIC, openTargetICLoading, allNets } = this.props;
    let allPowerNetsObj = (showAllNets ? allNets : allPowerNets).map((item) => { return { key: item, title: item } })
    const content = (
      <Panel
        className='cascade-power-select-panel'
        title={<div className='cascade-power-select-title'>{`${targetIC} - Select Power Nets`}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 600 })}
        height={500}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
        minHeight={500}
        minWidth={600}
        defaultTop={200}
      >
        <Fragment>
          <Spin spinning={openTargetICLoading}>
            <div className='power-select-content cascade-power-transfer-outside'>
              <Transfer
                className='cascade-power-transfer'
                showSearch
                dataSource={allPowerNetsObj}
                titles={[<strong>All Nets</strong>, <strong>Selected</strong>]}
                targetKeys={selectedNetsList}
                selectedKeys={value}
                onChange={this.moveSelected}
                onSelectChange={this.selectNets}
                render={item => item.title}
                selectionsIcon={<span></span>}
                filterOption={(inputValue, item) =>
                  (item.title || "").toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1
                }
                listStyle={{
                  width: `calc(50% - 20px)`,
                  height: '100%',
                }}
                locale={{
                  itemUnit: 'Net',
                  itemsUnit: 'Nets',
                  searchPlaceholder: 'Search Nets'
                }}
              />
            </div>
            <div className='power-select-content power-select-content-button'>
              <div className='power-select-switch'>
                <Switch size='small' checked={showAllNets} onChange={this.changeSwitch} />
                <span> Show All Nets</span>
              </div>
              <Button
                onClick={() => this.props.refresh(selectedNetsList)}
                size="small"
                className='cascade-power-select-btn'
              >Update</Button>
            </div>
          </Spin>
        </Fragment>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
};


export default PowerSelectPanel;
