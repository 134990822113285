import UploadMonitor from './UploadMonitor';
import SimulationMonitor from './SimulationMonitor';
import DebugMsg from './DebugMsg';
import ProfileTable from './profileTable';
import { monitorChild } from '@/components/MonitorFooter';

function _monitorChild({
  menuType,
  tabVisible,
  tabSelectKeys,
  projectId,
  getCCCVerifications,
  getCurrentVerification,
  width = undefined }) {
  return monitorChild({ menuType, tabVisible, tabSelectKeys, projectId, getCCCVerifications, getCurrentVerification, width },
    UploadMonitor,
    SimulationMonitor,
    DebugMsg,
    ProfileTable
  )
}

export default _monitorChild;