import http from "../http";

/**
 * save card to library
 *
 * @export
 * @param {string} designId
 * @returns
 */
function saveCardToLibraryCtrl(designId) {
  return http.post(`library/rocky/card/save?designId=${designId}`);
}

export {
  saveCardToLibraryCtrl
}