export const Hz = 0, KHz = 1, MHz = 2, GHz = 3, s = 0, ohm = 0, m = -1, u = -2, n = -3, p = -4;
export const scaleKeys = {
  Hz: Hz,
  K: KHz,
  M: MHz,
  G: GHz,
  s: s,
  ohm: ohm,
  m: m,
  u: u,
  n: n,
  p: p,
  ms: m,
  us: u,
  ns: n,
  ps: p,
  KHz,
  MHz,
  GHz,
  mm: m,
  um: u
};
export const MDFLM_List = ['10ps', '20ps', '30ps', '50ps'];