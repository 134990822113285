import React, { Component } from 'react';
import {
  DownOutlined,
  ExperimentOutlined,
  FileOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { Tree, ConfigProvider } from 'antd';
import { isMac } from '@/services/api/userAgent';
import { sortProjectPromise } from '@/services/project';
import { PROJECT, PROJECTS } from '../../constants/treeConstants'
import { strDelimited } from '@/services/helper/split';
import _ from 'lodash';
import './index.css';

class TreeForm extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     data: props.treeData ? this.renderTreeData(props.treeData) : [],
  //     treeData: props.treeData ? JSON.parse(JSON.stringify(props.treeData)) : []
  //   }
  // }

  // componentDidMount = () => {
  //   const { treeData = [] } = this.props;
  //   this.setState({
  //     data: this.renderTreeData(treeData),
  //     treeData: treeData ? JSON.parse(JSON.stringify(treeData)) : []
  //   })
  // }

  // componentDidUpdate = () => {
  //   const { treeData = [] } = this.props;
  //   if (!_.isEqual(this.state.treeData, JSON.parse(JSON.stringify(treeData)))) {
  //     this.setState({
  //       data: this.renderTreeData(treeData),
  //       treeData: treeData ? JSON.parse(JSON.stringify(treeData)) : []
  //     })
  //   }
  // }

  onDragStart = (info) => {
    const { node } = info
    const { disableDragProjectId } = this.props
    try {
      if (node.dataRef.id === disableDragProjectId) {
        info.event.preventDefault()
      }
      const dataType = node ? node.dataRef.dataType : undefined
      const isDraggable = dataType === PROJECT
      if (!isDraggable) {
        info.event.preventDefault()
      }
    } catch (err) {
      info.event.preventDefault()
    }
  };

  onDrop = (info) => {
    const { node, dragNode } = info
    try {
      const dropKey = node ? node.key : undefined;
      const dragKey = dragNode ? dragNode.key : undefined;
      const dropDataType = strDelimited(dropKey, '-', { returnIndex: 0 })
      const dragDataType = strDelimited(dragKey, '-', { returnIndex: 0 })
      if (dragDataType !== PROJECT || ![PROJECT, PROJECTS].includes(dropDataType) || dropKey === dragKey) {
        return
      }

      const { treeData } = this.props
      const projectsIndex = treeData.findIndex(item => item.key === PROJECTS)
      const { children } = treeData[projectsIndex]
      let dropIndex = dropKey === PROJECTS ? -1 : children.findIndex(item => item.key === dropKey)
      const dragIndex = children.findIndex(item => item.key === dragKey)
      let projectIds = children.map(item => {
        return item.id
      })

      // Handle drag and drop along the line
      if (node.dragOverGapBottom && dropIndex < dragIndex) {
        dropIndex = dropIndex + 1
      }
      if (node.dragOverGapTop && dropIndex > dragIndex) {
        dropIndex = dropIndex - 1
      }
      if (dropIndex + 1 === dragIndex) {
        return
      }

      const deleteNode = projectIds.splice(dragIndex, 1)
      projectIds.splice(dropIndex + 1, 0, deleteNode[0])
      const { updateProjectMenu } = this.props
      sortProjectPromise(projectIds).then(() => {
        updateProjectMenu()
      }, error => {
        console.error(error);
      })
    } catch (err) {
      return
    }
  }

  renderTreeData = (treeData) => {
    const { renderTitle, expandedKeys } = this.props;
    return treeData.map(node => {
      const switchIconClassName = node.icon ? "tree-icon-node" : ""
      return {
        title: renderTitle(node),
        key: node.key || node.id,
        dataRef: node,
        className: `${(node.nodeClass && `tree-node ${node.nodeClass} ${switchIconClassName}`) || `tree-node ${switchIconClassName}`}`,
        switcherIcon: node.icon && this.switchIcon(node, expandedKeys),
        isLeaf: node.isLeaf,
        children: node.children && node.children.length ? this.renderTreeData(node.children) : []
      }
    })
  }

  switchIcon = (item, expandedKeys) => {
    let iconClass = expandedKeys.includes(item.key) ? `my-tree-switch-icon my-tree-opened-icon` : `my-tree-switch-icon`;
    if (item.icon === 'folder') {
      iconClass = iconClass + " my-tree-folder-icon";
    }
    switch (item.icon) {
      case 'folder':
        return expandedKeys.includes(item.key) ?
          <FolderOpenOutlined className={iconClass} /> :
          <FolderOutlined className={iconClass} />;
      case 'file-folder':
        return <span className={`${iconClass} iconfont icon-folder-2 tree-file-folder-icon`} ></span>;
      case 'file':
        return <FileOutlined className={iconClass} />;
      case 'experiments':
        return <ExperimentOutlined className={iconClass} />;
      default: break;
    }

  }

  render() {
    const { treeData, defaultSelectedKeys, checkable, className, updateProjectMenu, ...restProps } = this.props;
    // const { data } = this.state;
    return (
      <ConfigProvider theme={{ token: { motionBase: 0.05 }, Component: { Tree: { titleHeight: 24 } } }}>
        <Tree
          blockNode={true}
          checkable={checkable}
          multiple
          defaultExpandAll
          draggable={{
            icon: false,
            nodeDraggable: (node) => {
              return updateProjectMenu && node.key && node.key.includes(`${PROJECT}-`) ? true : false
            }
          }}
          onDragStart={this.onDragStart}
          onDrop={this.onDrop}
          defaultSelectedKeys={defaultSelectedKeys}
          switcherIcon={<DownOutlined />}
          className={className ? `my-tree ${className}` : 'my-tree'}
          style={(isMac() && { overflow: 'auto' }) || {}}
          treeData={this.renderTreeData(treeData)}
          virtual={false}
          {...restProps}
        />
      </ConfigProvider>
    );
  }
}
export default TreeForm;