import http from './http';

export function getInterfacesList(id) {
  return http.get(`/${id}/services/project/interfaces`)
}

export function getProjectInfoByProjectId(projectId) {
  return http.get(`/my-project/project-info/${projectId}`);
}

/**
 * 
 * @param {String} mainType - 'SerDes'
 */
export function getFilesInProject({ projectId, mainType, type, path }) {
  return http.get(`/project/${projectId}/${mainType}/${type}/file?path=${path}`)
}

export function getDownloadInProject({ projectId, mainType, type, path }) {
  return http.get(`/project/${projectId}/${mainType}/${type}/file?path=${path}`, {
    responseType: 'arraybuffer'
  })
}

// workflow log controller
// Get all workflow simulation log
export function getSimulationMonitor(workflowId) {
  return http.get(`/workflow/simulation/log/${workflowId}`)
}

export function getRunningWorkFlow({ product, workflowId, verificationId }) {
  return http.get(`${product}/workflow/running/log?verificationId=${verificationId}&workflowId=${workflowId}`)
}