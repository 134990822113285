import React, { Component, Fragment, createRef } from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createPortal } from 'react-dom';
import Table from '@/components/EditableTable';
import Panel from '@/components/Panel';
import auroraDBJson from '../../../services/Designs/auroraDbData';
import { CAP, IGNORE } from '../../../constants/componentType';

const VRMColumns = [
  {
    title: 'Eq. Pwr Pin',
    dataIndex: 'powerComp',
    width: '30%',
  }, {
    title: 'Eq. Gnd Pin',
    dataIndex: 'groundComp',
    width: '30%',
  }, {
    title: 'Component',
    dataIndex: 'vrmComp',
    width: '40%'
  }
]
class VRMCompPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vrms: [],
      searchIndex: null
    }
    this.initColumns();
    this.inputRef = createRef();
    this.dialogRoot = document.getElementById('root');
  }

  componentDidUpdate(prevProps) {
    const { close, noPCB, searchVRM } = this.props;
    if (close && close !== prevProps.close) {
      this.closeModal()
    }
    if (noPCB !== prevProps.noPCB) {
      this.initColumns()
    }
    if (searchVRM && searchVRM !== prevProps.searchVRM) {
      this.props.searchVRMLoading(false);
      this.refreshVRMInfo();
    }
  }

  initColumns = () => {

    VRMColumns[0].render = (text, record) => {
      const { powerPins = [] } = record;
      return <div style={{ position: 'relative', minHeight: 20 }}>
        <span>{text}{powerPins.length ? ` - ${powerPins.join(', ')}` : ''}</span>
      </div>
    }

    VRMColumns[0].onCell = (record) => {
      const { noPCB } = this.props;
      const { powerComp, powerPins = [] } = record;
      const { powerList = [] } = this.state;
      return !noPCB ? {
        edit: 'aurora-select',
        record,
        dataIndex: 'powerComp',
        options: powerList,
        selectType: "powerComp",
        text: `${powerComp}${powerPins.length ? ` - ${powerPins.join(', ')}` : ''}`,
        getPopupContainer: document.getElementById("root"),
        handleSave: (record) => this.saveVRMPin(record, 'powerComp')
      } : {
        edit: false
      }
    }


    VRMColumns[1].render = (text, record) => {
      const { groundPins = [] } = record;
      return <div style={{ position: 'relative', minHeight: 20 }}>
        <span>{text}{groundPins.length ? ` - ${groundPins.join(', ')}` : ''}</span>
      </div>
    }


    VRMColumns[1].onCell = (record) => {
      const { noPCB } = this.props;
      const { groundComp, groundPins = [] } = record;
      const { groundList = [] } = this.state;
      return !noPCB ? {
        edit: 'aurora-select',
        record,
        dataIndex: 'groundComp',
        options: groundList,
        selectType: "groundComp",
        text: `${groundComp}${groundPins.length ? ` - ${groundPins.join(', ')}` : ''}`,
        getPopupContainer: document.getElementById("root"),
        handleSave: (record) => this.saveVRMPin(record, 'groundComp')
      } : {
        edit: false
      }
    }

    VRMColumns[2].render = (text, record) => {
      return (
        <div style={{ position: 'relative', minHeight: 20 }}>
          <span>{text}</span>
          <CloseOutlined
            className={text ? "impedance-vrm-delete-icon" : "ir-empty-vrm-delete-icon"}
            onClick={(e) => this.deleteRow(e, record)} />
        </div>
      );
    }

    VRMColumns[2].onCell = (record) => {
      const { noPCB, vrm } = this.props;
      const { vrmComp } = record;
      const { vrms } = this.state;
      const vrmComps = vrms.map(item => item.vrmComp)
      return !noPCB && !vrmComp ? {
        edit: 'aurora-select',
        record,
        dataIndex: 'vrmComp',
        options: vrm.filter(v => !vrmComps.includes(v)),
        selectType: "vrmComp",
        text: vrmComp,
        getPopupContainer: document.getElementById("root"),
        handleSave: (record) => this.saveVRMComp(record)
      } : {
        edit: false
      }
    }
  }

  componentDidMount = () => {
    this.initVRMTable()
  }

  initVRMTable = () => {
    const { record, targetIC, designId } = this.props;
    if (!record) {
      return;
    }
    const { vrmComps, inductance, gndNets, allPowerNet } = record;
    const vrms = vrmComps.map(vrm => {
      const _inductance = inductance[vrm] || [];
      return _inductance;
    }).flat().map((vrm, index) => ({ ...vrm, index }));
    const powerNetsComps = auroraDBJson.getComponentsByNets(designId, allPowerNet);
    const powerList = powerNetsComps.filter(item => item.type !== CAP && item.name !== targetIC).map(item => item.name).sort()
    const caps = auroraDBJson.getComponentsByType(designId, CAP);
    const gndComps = auroraDBJson.getComponentsByNets(designId, gndNets).filter(item => item.type === IGNORE);
    const groundList = [...caps, ...gndComps].filter(item => [...item.pins.values()].some(pin => gndNets.includes(pin.net))).map(item => item.name).sort()
    this.setState({
      vrms,
      powerList,
      groundList
    })
  }

  refreshVRMInfo = () => {
    const { record, targetIC, designId } = this.props;
    if (!record) {
      return;
    }
    const { inductance, gndNets, allPowerNet } = record;
    const { vrms, searchIndex } = this.state;
    let _vrms = [...vrms]
    if (searchIndex && searchIndex > 0) {
      const vrmComp = _vrms[searchIndex].vrmComp;
      _vrms[searchIndex] = { ..._vrms[searchIndex], ...(inductance[vrmComp] && inductance[vrmComp][0] ? inductance[vrmComp][0] : {}) }
    }
    const powerNetsComps = auroraDBJson.getComponentsByNets(designId, allPowerNet);
    const powerList = powerNetsComps.filter(item => item.type !== CAP && item.name !== targetIC).map(item => item.name).sort()
    const caps = auroraDBJson.getComponentsByType(designId, CAP);
    const gndComps = auroraDBJson.getComponentsByNets(designId, gndNets).filter(item => [IGNORE, CAP].includes(item.type));
    const groundList = [...caps, ...gndComps].filter(item => [...item.pins.values()].some(pin => gndNets.includes(pin.net))).map(item => item.name).sort()
    this.setState({
      vrms: _vrms,
      powerList,
      groundList
    })
  }

  closeModal = () => {
    const { vrms } = this.state;
    this.props.saveVrms(vrms);
    this.props.save();
  }

  addRow = (e) => {
    e.stopPropagation();
    const { vrms } = this.state;
    const newVrm = { powerComp: '', powerPins: [], groundComp: '', groundPins: [], vrmComp: '' }
    this.setState({
      vrms: [...vrms, newVrm].map((item, index) => ({ ...item, index })),
    })
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const index = record.index;
    const { vrms } = this.state;
    this.setState({
      vrms: vrms.filter(vrm => vrm.index !== index)
    });
  }

  saveVRMPin = (record, type) => {
    const value = record[type];
    const { vrms } = this.state;
    const propsRecord = this.props.record;
    const { index } = record;
    const { gndNets = [], allPowerNet = [] } = propsRecord;
    const newVrms = JSON.parse(JSON.stringify(vrms));
    let findIndex = newVrms.findIndex(item => item.index === index);
    if (findIndex > -1) {
      newVrms[findIndex][type] = value;
      const { designId } = this.props;
      const current = auroraDBJson.getComponent(designId, value)
      if (type === 'powerComp') {
        if (current) {
          let currentPins = [...current.pins.values()].filter(pin => !gndNets.includes(pin.net));
          const findPowerNet = allPowerNet.find(net => currentPins.some(pin => pin.net === net));
          currentPins = findPowerNet ? currentPins.filter(pin => pin.net === findPowerNet).map(pin => pin.pin) : [];
          const pins = currentPins ? currentPins : [...current.pins.values()].filter(p => !gndNets.includes(p.net)).map(p => p.pin).sort();
          newVrms[findIndex].powerPins = [...pins];
        }
      } else if (type === 'groundComp') {
        if (current) {
          const currentPins = [...current.pins.values()].filter(pin => gndNets.includes(pin.net) && !allPowerNet.includes(pin.net)).map(p => p.pin);
          const pins = currentPins.length ? currentPins : [...current.pins.values()].filter(p => !allPowerNet.includes(p.net)).map(p => p.pin).sort();
          newVrms[findIndex].groundPins = [...pins];
        }
      }
      this.setState({
        vrms: newVrms
      })
    }
  }

  saveVRMComp = (record) => {
    const { vrmComp, index } = record;
    const { vrms } = this.state;
    const { record: propsRecord } = this.props;
    const newVrms = JSON.parse(JSON.stringify(vrms));
    let findIndex = newVrms.findIndex(item => item.index === index);
    if (findIndex > -1) {
      newVrms[findIndex].vrmComp = vrmComp;
      this.setState({
        vrms: newVrms,
        searchIndex: index
      }, () => {
        this.props.saveIRVRMComponent(propsRecord, vrmComp)
      })
    }
  }

  renderDialog = () => {
    const { vrms } = this.state;
    const content = (
      <Panel
        className='impedance-panel'
        id='impedance-vrm-panel'
        title={<div className='impedance-panel-title'>VR Port Setting
          <PlusCircleOutlined className='impedance-comp-add-icon' onClick={(e) => this.addRow(e)} />
        </div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={1000}
        minWidth={200}
        minHeight={200}
        position='panel-center'
        draggable
        overflow={"auto"}
      >
        <Table
          columns={VRMColumns}
          dataSource={vrms}
          size="small"
          rowKey={(record) => record.index}
          className='impedance-vrm-table cascade-imp-table'
          tablePadding={true}
        />
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }

  render() {
    const { text } = this.props;
    return <Fragment>
      <div className="editable-cell-value-wrap" ref={this.inputRef}>
        {text}
      </div>
      {this.renderDialog()}
    </Fragment>
  }
}

export default VRMCompPanel;
