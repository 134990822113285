import { getSysLibByBulkSearch } from '../../helper/systemLibraryHelper';

class SystemLibDetail {
  constructor() {
    this.modelList = new Map();
  }

  saveSystemModel = async (names) => {
    const filterNames = names.filter(item => !this.modelList.get(item));

    const searchList = filterNames.map(item => {
      return {
        part: item,
        info: [{
          page: 0,
          keyword: { name: item }
        }]
      }
    })
    if (!searchList.length) {
      return "Init Model";
    }
    const res = await getSysLibByBulkSearch(searchList);
    if (res && Array.isArray(res)) {
      for (let item of res) {
        const libraries = item.info && item.info[0] ? item.info[0].libraries : null;
        const pageData = libraries && libraries.librariesData.length ? libraries.librariesData[0] : undefined;
        if (pageData) {
          this.modelList.set(item.part, pageData);
        }
      }
    }
    return "Init Model"
  }

  getSystemModel = (name) => {
    return this.modelList.get(name);
  }
}

const systemLibDetail = new SystemLibDetail();

export default systemLibDetail;