import http from "./http"

/**
 * Get simulation monitor list
 * 2021/09/27
 *
 * @export
 * @returns
 */
function getSimulationMonitorList({ workflowId, verificationId, product }) {
  return http.get(`/${product}/workflow/running/log?verificationId=${verificationId}&workflowId=${workflowId}`);
}



/**
 * Get result exist or not
 * 2021/12/14
 *
 * @export
 * @returns
 */
function isExistSimulationResult({ product, verificationId }) {
  return http.get(`/${product}/verification/result/exist?verificationId=${verificationId}`);
}

export {
  getSimulationMonitorList,
  isExistSimulationResult
}