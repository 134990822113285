
function getCMDADRSignals(signalNames, verificationType, interfaceSignals) {
  //Filter CLKP CLKN
  const _interfaceSignals = interfaceSignals.filter(item => !item.name.match(/CLK/ig));
  //Current selected signals
  let _signals = signalNames.map(item => { return { name: item } });

  //find current selected signal path
  //A0 -> addr0
  for (let i = 0; i < _interfaceSignals.length; i++) {
    const sig = _interfaceSignals[i];
    let signalName = sig.name;
    const index = _signals.findIndex(it => it.name === sig.name);
    if (index < 0) {
      continue;
    }
    if (verificationType === 'ADR') {
      signalName = 'addr' + i;
    } else if (verificationType === 'CMD') {
      signalName = 'cmds' + i; // cmds0 - cmdsN
    }
    _signals[index].pathSignal = signalName;
  }
  const resultSignalNames = _signals.map(item => { return item.pathSignal });
  return resultSignalNames;
}

function getCustomEyeModes({ verificationType, interfaces, _eyeConfig, interfaceSignals }) {
  let results = [], eyeConfig = _eyeConfig;
  if (verificationType === 'BYTE') {
    results = interfaces;
  } else {
    const components = eyeConfig.components || [];
    const signalNames = eyeConfig.plot.signals || [];
    results = [...components];
    //change signal name A0 -> addr0
    const signals = getCMDADRSignals(signalNames, verificationType, interfaceSignals);
    eyeConfig.plot.signals = [...signals];
  }
  return { results, eyeConfig };
}

function getCustomEyeParams({ verificationId, info, interfaces, verificationType, adjusted }) {
  let params = { verificationId, adjusted };
  let eyeMode = "";
  if (verificationType === 'BYTE') {
    const isWrite = info.name.match(/write/ig);
    eyeMode = isWrite ? "Write" : "Read";
    params["interfaceId"] = info.id;
  } else {
    params["component"] = info;
    params["interfaceId"] = interfaces[0].id;
    eyeMode = info;
  }
  return { params, eyeMode };
}

export {
  getCMDADRSignals,
  getCustomEyeParams,
  getCustomEyeModes
}