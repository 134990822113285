
import { getArraySimilar } from '../arrayHelper';
// custom component RLC prefix library (frontend)
export default class CompRLCPrefixLib {
  constructor() {
    this.Res = ['R', 'MR', 'PR'];
    this.Ind = ['L', 'PL'];
    this.Cap = ['C', 'PC'];
  };
};
// This VRM is automatically recognized, not added
/**
 *
 *
 * @param {*} vrms
 * @returns true - display, false - hidden
 */
function prefixCheckByVRM(vrms) {
  if (!vrms.length) return true;
  const groundPins = vrms.map(item => item.groundPin ? item.groundPin.length : 0);
  const powerPins = vrms.map(item => item.powerPin ? item.powerPin.length : 0);
  if (powerPins.some(len => len === 0)) {
    return true;
  };
  if (groundPins.some(len => len === 0)) {
    return true;
  };
  return false;
}

export { prefixCheckByVRM };
