import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import Panel from '@/components/Panel';
import { Checkbox } from "antd";

const CheckboxGroup = Checkbox.Group;

const options = [{
  title: "Apply direction to all signals of same group.",
  key: "applyToGroupSignals"
},
{
  title: "Apply direction to all signals.",
  key: "applyToAll"
}]


class DirectionOptions extends PureComponent {
  constructor(props) {
    super(props);
    const { applyToGroupSignals, applyToAll } = this.props;
    let checkedOptions = [];
    applyToGroupSignals && checkedOptions.push("applyToGroupSignals");
    applyToAll && checkedOptions.push("applyToAll");
    this.state = {
      checkedOptions
    }
    this.dialogRoot = document.getElementById('root');
  }

  changeOptions = (values) => {
    this.setState({
      checkedOptions: values
    })
  }

  closeModal = () => {
    this.props.closePanel(this.state.checkedOptions);
  }

  render() {
    const { checkedOptions } = this.state;
    const { isEndToEnd } = this.props;
    const _options = isEndToEnd ? options.filter(item => item.key === "applyToAll") : options;
    const content = (
      <Panel
        className='andes-v2-channel-direction-panel panel-x-scroll-hidden'
        title={<div className='andes-v2-channel-direction-title'>Direction Setting</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={340}
        position='panel-center'
        draggable
        maxHeight={200}
        overflow="hidden"
      >
        <div className='direction-setting-main'>
          <CheckboxGroup
            value={checkedOptions}
            className='direction-setting-checkbox-group-box'
            onChange={(values) => this.changeOptions(values)}
          >

            {_options.map(child => (
              <Checkbox
                key={child.key}
                value={child.key}
                className=''
              >
                <span>{child.title}</span>
              </Checkbox>
            ))}
          </CheckboxGroup>

        </div>
      </Panel>)
    return createPortal(content, this.dialogRoot);
  }
}



export default DirectionOptions;