import React, { Fragment, PureComponent } from "react";
import { Button, Progress } from "antd";
import { PCB_CHANNEL } from "@/constants/treeConstants";
import { REPORT, RESULT } from '@/services/helper/debugHelper';
import '@/publicCss/style.css';
import '@/publicCss/debug.css';
import { numberCheck } from "../../services/helper/dataProcess";
import { DOWNLOAD_RESULT, EXPERIMENT_S_PARAMETER_DOWNLOAD, PACKAGE_DEBUG, S_PARAMETER_DOWNLOAD } from "../../services/helper/debugHelper";
import { HIMALAYAS, ROCKY } from "../../constants/pageType";
import { BMA_CHANNEL_RESULT, EXPERIMENTS, PACKAGE_PDN, PCB_CHANNEL_RESULT } from "../../constants/treeConstants";

class DebugFooter extends PureComponent {

  getDownloadDisabled = () => {
    const {
      menuType,
      menuKey,
      currentCheckValues,
      debugMenuList,
      info,
      productType
    } = this.props;
    if (!currentCheckValues.length) {
      return true;
    }

    if (menuType === DOWNLOAD_RESULT) {
      return info && info.selectInfo && info.selectInfo.channelIds && info.selectInfo.channelIds.length ? false : true;
    }
    if (menuType === EXPERIMENT_S_PARAMETER_DOWNLOAD) {
      const checkValues = currentCheckValues.map(item => item.children).flat(2);
      return checkValues && checkValues.length > 0 ? false : true;
    }

    if (menuType === BMA_CHANNEL_RESULT) {
      return currentCheckValues && currentCheckValues.length > 0 ? false : true;
    }

    const findEmptyChildrenValues = currentCheckValues.filter(item => ((menuType === REPORT && numberCheck(item.name)) || (menuType !== REPORT && !numberCheck(item.name))) && (item.children && !item.children.length));
    if (findEmptyChildrenValues.length) {
      return true;
    }

    const _info = info && productType === ROCKY ? info : debugMenuList.find(item => item.key === (menuKey || menuType)).info;

    if (!_info) {
      return true;
    }

    if ([S_PARAMETER_DOWNLOAD].includes(menuType) && productType === ROCKY) {
      if (_info.selectInfo && _info.selectInfo.dataType && _info.selectInfo.channelId && _info.selectInfo.dataType === PCB_CHANNEL_RESULT) {
        return false;
      }
      return _info.selectInfo && _info.selectInfo.verificationIds && _info.selectInfo.verificationIds.length ? false : true;
    }

    if ([REPORT, RESULT].includes(menuType) && _info.notSelected) {
      return false
    }

    if (menuType === PACKAGE_DEBUG && _info.selectInfo && (_info.selectInfo.dataType === PACKAGE_PDN || _info.selectInfo.notChannel)) {
      return false;
    }

    if (menuType === PCB_CHANNEL && _info.selectInfo && _info.selectInfo.channelId) {
      return false
    }

    if (!_info.selectInfo || !_info.selectInfo.id) {
      return true;
    }

    if (menuType === PCB_CHANNEL && (!_info.selectChild || !_info.selectChild.id)) {
      return true;
    }

    if (menuType === EXPERIMENTS && (!_info.selectSweep || !_info.selectSweep.id))
      return true;
  }

  render() {
    const {
      menuType,
      downloadMsgInfo,
      downloadProgressInfo,
      type = 'line',
      width = "default",
      product
    } = this.props;
    const progress = downloadProgressInfo[menuType] !== undefined ? downloadProgressInfo[menuType] : -1;
    const downloadMessage = downloadMsgInfo[menuType];
    const messageClassName = downloadMessage &&
      (downloadMessage.indexOf('successful') > -1
        || downloadMessage.indexOf('Preparing') > -1
        || downloadMessage.indexOf('Loading') > -1
        || downloadMessage.indexOf('Downloading') > -1)
      ? 'download-success'
      : 'download-error';
    const disabled = this.getDownloadDisabled();
    const buttonTitle = disabled ? "Choose at least one option to download." : "download";

    return <Fragment>
      {downloadMessage && (product === HIMALAYAS || progress === -1) ? <div
        className={`${messageClassName} aurora-debug-download-message`}
        style={{ bottom: product === HIMALAYAS && progress > -1 ? 66 : 50 }}
      >{downloadMessage}</div> : null}
      {progress === -1 ? <div className='debug-download-button'>
        <Button
          title={buttonTitle}
          type="primary"
          ghost
          disabled={disabled}
          onClick={(e) => this.props.DownloadDebugClick(e)}
          className='debug-download-btn'
        >Download</Button>
      </div> :
        type === 'circle' ? <Progress
          className='aurora-debug-progress'
          percent={progress}
          type={type}
          strokeWidth={10}
          size="small"
        /> : <Progress
          className='aurora-debug-progress'
          percent={progress}
          type={type}
          size={{ width }}
        />}
    </Fragment>
  }
}

export default DebugFooter;