import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import AndesSider from './AndesSider';
import AndesContent from './Content';
import Line from '@/components/Line';
import { clearCCCStatus } from '../CCC/store/action';
import { changeLeft, changeTop } from "../panelStore/action";
import { closeTabFooter, openTabFooter, cleanTabMonitorStatus } from '../MonitorStore/action';
import { isChrome } from '../../services/helper/browser';
import DelConfirm from '@/components/DelConfirm';
import SideFooter from '@/components/SideFooter';
import { updatePageLayout, updatePCBLayout } from './store/project/action';
import { SINGLE_PAGE_LAYOUT } from '../../constants/layoutConstants';
import { ANDES_V2 } from '../../constants/pageType';
import { changeProduct } from '../LayoutExplorer/LayoutStore/action';
import '@/publicCss/style.css';
import '@/publicCss/tree.css';
import '@/publicCss/libraryStyle.css';
import '@/publicCss/aurora.css';
import '@/publicCss/auroraContent.css';
import '@/publicCss/extraction.css';
import './andes.css';

const { Content } = Layout;
const errorMsg = 'Non Chrome browser is not supported in the simulation platform.';

class AndesPage extends Component {
  constructor(props) {
    super(props);
    this.version = props.version;
    this.servicesAccount = props.servicesAccount;
    this.state = {
      width: 280,
      linePosition: {
        position: "absolute",
        left: 280
      },
      openMenu: true,
      chrome: true,
      browserVisible: false
    }
  }

  componentWillMount() {
    //clear CCC status
    this.props._clearCCCStatus();
  }

  changeWidth(width) {
    let position = {
      position: "absolute",
      left: width,
      minWidth: 56,
      maxWidth: 1000
    }
    this.setState({
      linePosition: position,
      width
    })
    //page left sider
    this.props._changeLeft(width);
  }

  componentDidMount = () => {
    //page left sider
    this.props._changeLeft(280);
    //page top menu
    this.props._changeTop(54);
    //clear monitor reducer
    this.props._cleanTabMonitorStatus();
    this.props.changeProduct(ANDES_V2);
    const chrome = isChrome();
    this.setState({
      chrome,
      browserVisible: chrome ? false : true
    })
  }

  componentDidUpdate = (prevProps) => {
    const { pageType } = this.props;
    if (pageType !== prevProps.pageType) {
      //switch product, clear monitor reducer
      this.props._cleanTabMonitorStatus();
    }
  }

  changeSize = (width) => {
    let newWidth = width;
    const prevWidth = this.state.width;
    if (prevWidth === 56 && width > 56) {
      this.setState({
        openMenu: true
      });
      newWidth = 255;
      this.changeWidth(newWidth);
    } else {
      width = width < 255 ? 56 : width;
      width = width > 1000 ? 1000 : width;
      if (width === 56) {
        this.setState({
          openMenu: false
        });
      }
      this.changeWidth(width);
    }
  }

  openMenu = (e) => {
    e.stopPropagation();
    this.setState({
      openMenu: true,
      width: 255,
      linePosition: {
        position: "absolute",
        left: 255,
        minWidth: 56,
        maxWidth: 1000,
      }
    });
  }

  pcbLayoutChange = (type) => {
    const { pcbLayout } = this.props;
    this.props._updatePCBLayout(type, pcbLayout);
  }

  layoutClick = (layoutType, singleType) => {
    if (singleType) {
      this.viewClick(singleType);
      return;
    }
    this.props._updatePageLayout(layoutType);
  }

  viewClick = (type) => {
    this.props._updatePageLayout(SINGLE_PAGE_LAYOUT, type);
  }

  changeTabFooter = () => {
    const { _openTabFooter, _closeTabFooter, tabVisible } = this.props;
    tabVisible ? _closeTabFooter() : _openTabFooter();
  }

  render = () => {
    const { width, linePosition, openMenu, chrome, browserVisible } = this.state;
    return (
      <Fragment>
        {chrome ? <Layout className="aurora-layout">
          {/* --- Andes Sider(left) --- */}
          <div className='aurora-sider'
            style={{ width: width }}
          >
            {openMenu ?
              /* --- Andes Sider tree(when width is > 56)--- */
              <div className='andes-v2-sider-menu-tree aurora-sider-menu-tree'>
                <AndesSider siderWidth={width} />
              </div>
              :
              /* --- Andes Sider icon(when width is < 255)--- */
              <div className='aurora-sider-menu'>
                <MenuOutlined onClick={(e) => this.openMenu(e)} />
              </div>
            }
            {/* --- Andes Sider footer display icon--- */}
            <SideFooter
              actions={this.sideFooterActions()}
            />
          </div>
          <Line
            //Position is used to locate the Line
            position={linePosition}
            changeWidth={(width) => this.changeWidth(width)}
            width={width}
            resize={this.changeSize}
          />
          {/* --- Andes Content(right) --- */}
          <Content className='aurora-content andes-content' style={{ left: width, overflow: '' }}>
            <AndesContent
              siderWidth={width}
              version={this.version}
              servicesAccount={this.servicesAccount}
            />
          </Content>
        </Layout> : null
        }
        {/* isChrome */
          browserVisible ? <DelConfirm
            data={null}
            removeCancel={true}
            deleteConfirm={this.okConfirm}
            message={errorMsg}
          /> : null
        }
      </Fragment >
    );
  }

  sideFooterActions = () => {
    const { openMenu } = this.state;
    const { viewList, tabVisible, layout, openProjectId, pcbLayout } = this.props;
    return {
      openMenu,
      viewList,
      tabVisible,
      layout,
      pcbLayout,
      currentProjectId: openProjectId,
      pcbLayoutChange: this.pcbLayoutChange,
      layoutClick: this.layoutClick,
      changeTabFooter: this.changeTabFooter
    }
  }
}

const mapState = (state) => {
  const { PanelStatus, LoginReducer: { pageType }, AndesV2Reducer } = state;
  const { defaultTop, defaultLeft } = PanelStatus;
  const { tabVisible } = state.MonitorInfoReducer;
  const { project: { openProjectId, viewList, layout, pcbLayout } } = AndesV2Reducer;
  return {
    pageType,
    defaultTop,
    defaultLeft,
    tabVisible,
    openProjectId,
    viewList,
    layout,
    pcbLayout
  }
}

const mapDispatch = (dispatch) => ({
  _clearCCCStatus() {
    dispatch(clearCCCStatus());
  },
  _changeLeft(width) {
    dispatch(changeLeft(width));
  },
  _changeTop(top) {
    dispatch(changeTop(top));
  },
  _cleanTabMonitorStatus() {
    dispatch(cleanTabMonitorStatus());
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  },
  _updatePageLayout(layout, singleType) {
    dispatch(updatePageLayout(layout, singleType))
  },
  _updatePCBLayout(pcbLayout, prePcbLayout) {
    dispatch(updatePCBLayout(pcbLayout, prePcbLayout))
  },
  changeProduct(product) {
    dispatch(changeProduct(product))
  }
})

export default connect(mapState, mapDispatch)(AndesPage);