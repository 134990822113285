import http from '../http';
import unzipFile from '../../helper/unzip';
import JSZipUtils from 'jszip-utils';

export function getChannelSetting(channelId, type) {
  const params = type ? { channelId, type } : { channelId }
  return http.get(`/andes/simulation/channel/setting`, { params })
}

/**
 *
 *
 * @export
 * @param {*} { channelId, type } type - RETURN_LOSS, INSERTION_LOSS, DIFF_TO_COMM, TDR, SBR, DELAY
 * @returns
 */
export function getChannelExtractionResult({ channelId, type, pair, signal }) {
  return http.get(`/andes/simulation/channel/extraction/results`, { params: { channelId, type, pair, signal } })
}

export function getChannelWaveformResultList({ channelId, displayMode }) {
  return http.get(`/andes/channel/hspice/waveform`, { params: { channelId, displayMode } })
}

export function getHistoryChannelWaveformResultList({ historyId, displayMode }) {
  return http.get(`andes/result/history/hspice/waveform`, { params: { historyId, displayMode } })
}

/**
 * Get sparameter curve
 *
 * @export
 * @param {*} channelId
 * @param {*} resultDataParam
 * @returns
 */
export function getResultCurveData({ channelId, resultDataParam, signal, pair, couplingType }) {
  let otherParam = '';
  if (signal && pair) {
    otherParam += `&signal=${signal}&pair=${pair}`;
  }
  if (couplingType) {
    otherParam += `&type=${couplingType}`;
    return http.post(`/andes/channel/curve/data?channelId=${channelId}${otherParam}`, {})
  }
  return http.post(`/andes/channel/curve/data?channelId=${channelId}${otherParam}`, resultDataParam)
}

/**
 * Generate TDR, SBR
 * 
 * @export
 * @param {*} params
 * {
  "channelId": "string",
  "datarate": "string",
  "delay_risetime": "string",
  "duration_ratio": 0,
  "sbr_delay": "string",
  "sbr_risetime": "string",
  "tdr_delay": "string",
  "tdr_risetime": "string"
}
 * @returns
 */
export function generateTDRSBR(params) {
  return http.post(`/andes/simulation/channel/result-parser`, params)
}

/**
 *
 * Get TDR, SBR Setting
 * 
 * @export
 * @param {*} channelId - channel id
 * @returns
 * { 
 *  "data": {
 *   "channelId": "string",
 *   "datarate": "string",
 *   "delay_risetime": "string",
 *   "duration_ratio": 0,
 *   "sbr_delay": "string",
 *   "sbr_risetime": "string",
 *   "tdr_delay": "string",
 *   "tdr_risetime": "string"
 *  } 
 * }
 */
export function getTDRSBRSetting(channelId) {
  return http.get(`/andes/simulation/channel/result/setting`, { params: { channelId } })
}

/**
 * Delete history folder by ids
 *
 * @export
 * @param {*} ids
 * @returns
 */
export function deleteHistoryFolder(ids) {
  return http.post(`/andes/result/history/delete`, { ids })
}

/**
 * Get history list by channel id
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getHistoryList(channelId) {
  return http.get(`/andes/result/history/list`, { params: { channelId } })
}

/**
 * Rename result history
 *
 * @export
 * @param {*} id
 * @param {*} name
 * @returns
 */
export function renameResultHistory(id, name) {
  return http.post(`/andes/result/history/rename`, { id, name })
}

/**
 * Get history results by history id and type
 *
 * @export
 * @param {*} { historyId, type }
 * type - RETURN_LOSS, INSERTION_LOSS, DIFF_TO_COMM, TDR, SBR, DELAY, SPARAMETER
 * @returns
 */
export function getHistoryResults({ historyId, type, pair, signal }) {
  return http.get(`/andes/result/history/results`, { params: { historyId, type, pair, signal } })
}

/**
 *  Save current results to history
 *
 * @export
 * @param {*} { channelId, name }
 * @returns
 */
export function saveToHistory({ channelId, name }) {
  return http.post(`/andes/result/history/save`, {
    channelId,
    name
  })
}

/**
 * Get history setting
 *
 * @export
 * @param {*} historyId
 */
export function getHistorySetting(historyId, type) {
  const params = type ? { historyId, type } : { historyId }
  return http.get(`/andes/result/history/setting`, { params })
}

/**
 * Get channel last simulation finished time
 *
 * @export
 * @param {*} channelId
 * @returns
 */
export function getChannelFinishedTime(channelId) {
  return http.get(`/andes/result/history/time`, { params: { channelId } })
}

export function getHistoryCurveData({ historyId, signal, pair, resultDataParam, couplingType }) {
  let otherParam = '';
  if (signal && pair) {
    otherParam = `&signal=${signal}&pair=${pair}`
  }
  if (couplingType) {
    otherParam += `&type=${couplingType}`;
    return http.post(`/andes/result/history/curve/data?historyId=${historyId}${otherParam}`, {});
  }
  return http.post(`/andes/result/history/curve/data?historyId=${historyId}${otherParam}`, resultDataParam)
}

export function getEndToEndChannelSetting(endToEndChannelId, type) {
  return http.get(`/andes/end-to-end/channel/setting`, { params: { endToEndChannelId, type } })
}

/**
 *
 *
 * @export
 * @param {*} { channelId, type } type - RETURN_LOSS, INSERTION_LOSS, DIFF_TO_COMM, TDR, SBR, DELAY
 * @returns
 */
export function getEndToEndChannelResult({ endToEndChannelId, type, pair, signal }) {
  return http.get(`/andes/end-to-end/channel/results`, { params: { endToEndChannelId, type, pair, signal } })
}

export function getEndToEndCurveData({ endToEndChannelId, resultDataParam, signal, pair, couplingType }) {
  let otherParam = '';
  if (signal && pair) {
    otherParam += `&signal=${signal}&pair=${pair}`;
  }
  if (couplingType) {
    otherParam += `&type=${couplingType}`;
    return http.post(`/andes/end-to-end/channel/curve/data?endToEndChannelId=${endToEndChannelId}${otherParam}`, {})
  }
  return http.post(`/andes/end-to-end/channel/curve/data?endToEndChannelId=${endToEndChannelId}${otherParam}`, resultDataParam)
}

export function getEndToEndTDRSBRSetting(id) {
  return http.get(`/andes/end-to-end/channel/raw/setting`, { params: { channelId: id } })
}

export function generateEndToEndTDRSBR(params) {
  return http.post(`/andes/end-to-end/channel/result-parser`, params)
}

function getChannelsEyeZip(id, resultType) {
  const token = localStorage.getItem('token');
  if (resultType === "current") {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(`/api/v3/andes/channel/result/seasim?channelId=${id}&access_token=${token}`, (error, data) => {
        if (error || !data.byteLength) {
          resolve(null);
        }
        resolve(data);
      });
    })
  } else {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(`/api/v3/andes/result/history/seasim?historyId=${resultType}&access_token=${token}`, (error, data) => {
        if (error || !data.byteLength) {
          resolve(null);
        }
        resolve(data);
      });
    })
  }
}


function getEndToEndChannelsEyeZip(id, resultType) {
  const token = localStorage.getItem('token');
  if (resultType === "current") {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(`/api/v3/andes/end-to-end/channel/result/seasim?endToEndChannelId=${id}&access_token=${token}`, (error, data) => {
        if (error || !data.byteLength) {
          resolve(null);
        }
        resolve(data);
      });
    })
  } else {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(`/api/v3/andes/end-to-end/channel/result/seasim?endToEndChannelId=${id}&access_token=${token}`, (error, data) => {
        if (error || !data.byteLength) {
          resolve(null);
        }
        resolve(data);
      });
    })
  }

}


export function getChannelsEye(id, resultType) {
  return new Promise((resolve, reject) => {
    getChannelsEyeZip(id, resultType).then(res => {
      if (!res) {
        resolve(null); return;
      }
      unzipFile(res, "arraybuffer").then(response => {
        const keys = Object.keys(response);
        if (keys && keys.length) {
          resolve(response);
        } else {
          resolve(null)
        }
      }, error => {
        resolve(null);
      })
    })
  })
}

export function getEndToEndChannelEye(id, resultType) {
  return new Promise((resolve, reject) => {
    getEndToEndChannelsEyeZip(id, resultType).then(res => {
      if (!res) {
        resolve(null); return;
      }
      unzipFile(res, "arraybuffer").then(response => {
        const keys = Object.keys(response);
        if (keys && keys.length) {
          resolve(response);
        } else {
          resolve(null)
        }
      }, error => {
        resolve(null);
      })
    })
  })
}

/**
 * get pre layout setup json by channelId
 *  */
export function getAndesPreLayoutJson(channelId) {
  return http.get(`/andes/channel/prelayout/detail?channelId=${channelId}`);
}

/**
 * get channel ads result eye diagram
 *  */
/* function getAndesAdsResultEyeDiagram({ channelId, signal }) {
  return http.get(`/andes/channel/ads/result/eyediagram?channelId=${channelId}&signalName=${signal}`);
} */
function getAndesAdsResultEyeDiagram({ channelId, signal, probe }) {
  return http.get(`/andes/channel/ads/result/eyediagram`, {
    params: {
      channelId,
      signalName: signal,
      probe
    },
    responseType: 'arraybuffer'
  });
}

export function getAdsResultEyeDiagram({ channelId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesAdsResultEyeDiagram({ channelId, signal, probe }).then(res => {
      if (res && res.data && res.data.byteLength) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

function getAndesAdsResultHistoryEyeDiagram({ historyId, signal, probe }) {
  return http.get(`/andes/result/history/ads/eye-diagram`, {
    params: {
      historyId,
      signal,
      probe
    },
    responseType: 'arraybuffer'
  });
}

export function getAdsResultHistoryEyeDiagram({ historyId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesAdsResultHistoryEyeDiagram({ historyId, signal, probe }).then(res => {
      if (res && res.data && res.data.byteLength) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

/**
 * get channel ads result eye diagram parameters
 *  */
function getAndesAdsResultEyeParameters({ channelId, signal, probe }) {
  return http.get(`/andes/channel/ads/result/parameter`, { params: { channelId, signalName: signal, probe } })
}

export function getAdsResultEyeParameters({ channelId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesAdsResultEyeParameters({ channelId, signal, probe }).then(res => {
      if (res && res.data) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

/**
 * get channel ads result eye diagram parameters
 *  */
function getAndesAdsResultHistoryEyeParameters({ historyId, signal, probe }) {
  return http.get(`/andes/result/history/ads/parameter`, { params: { historyId, signal, probe } })
}

export function getAdsResultHistoryEyeParameters({ historyId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesAdsResultHistoryEyeParameters({ historyId, signal, probe }).then(res => {
      if (res && res.data) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}


/**
 * get multi pcb ads result eye diagram
 *  */
function getAndesEndToEndAdsEyeDiagram({ endToEndChannelId, signal, probe }) {
  return http.get(`/andes/end-to-end/channel/ads/result/eye-diagram`, {
    params: {
      endToEndChannelId,
      signalName: signal,
      probe
    },
    responseType: 'arraybuffer'
  });
}

export function getEndToEndAdsEyeDiagram({ endToEndChannelId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesEndToEndAdsEyeDiagram({ endToEndChannelId, signal, probe }).then(res => {
      if (res && res.data && res.data.byteLength) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

function getAndesEndToEndAdsHisEyeDiagram({ historyId, signal, probe }) {
  return http.get(`/andes/end-to-end/channel/history/ads/eye-diagram`, {
    params: {
      historyId,
      signal,
      probe
    },
    responseType: 'arraybuffer'
  });
}

export function getEndToEndAdsHisEyeDiagram({ historyId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesEndToEndAdsHisEyeDiagram({ historyId, signal, probe }).then(res => {
      if (res && res.data && res.data.byteLength) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

/**
 * get channel ads result eye diagram parameters
 *  */
function getAndesEndToEndAdsEyeParameters({ endToEndChannelId, signal, probe }) {
  return http.get(`/andes/end-to-end/channel/ads/result/parameter`, { params: { endToEndChannelId, signalName: signal, probe } })
}

export function getEndToEndAdsEyeParameters({ endToEndChannelId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getAndesEndToEndAdsEyeParameters({ endToEndChannelId, signal, probe }).then(res => {
      if (res && res.data) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

/**
 * get channel ads result eye diagram parameters
 *  */
function getEndToEndAndesAdsHistoryEyeParameters({ historyId, signal, probe }) {
  return http.get(`/andes/end-to-end/channel/history/ads/parameter`, { params: { historyId, signal, probe } })
}

export function getEndToEndAdsHistoryEyeParameters({ historyId, signal, probe }) {
  return new Promise((resolve, reject) => {
    getEndToEndAndesAdsHistoryEyeParameters({ historyId, signal, probe }).then(res => {
      if (res && res.data) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}

/** 
 * 2024.06.27
 * @param {*} { experimentId, type } type - RETURN_LOSS, INSERTION_LOSS, DIFF_TO_COMM, TDR, SBR, DELAY
 * @return
 */
export function getSweepExtractionResult({ experimentId, type }) {
  return http.get(`/andes/simulation/sweep-channel/extraction/results`, { params: { experimentId, type } });
}

export function getExperimentSetting(experimentId, type) {
  const params = type ? { experimentId, type } : { experimentId }
  return http.get(`/andes/simulation/sweep-channel/setting`, { params })
}

export function getExperimentCurveData({ experimentId, resultDataParam }) {
  return http.post(`/andes/channel/curve/sweep/result?experimentId=${experimentId}`, resultDataParam)
}

export function getSweepTDRSBRSetting(sweepId) {
  return http.get(`/andes/simulation/sweep-channel/result/setting?sweepId=${sweepId}`)
}

export function generateSweepTDRSBR({ params, sweepId }) {
  return http.post(`/andes/simulation/sweep-channel/result-parser?sweepId=${sweepId}`, params)
}

function getAndesHspiceResultEyeDiagram({ channelId, signal }) {
  return http.get(`/andes/channel/hspice/eye`, {
    params: {
      channelId,
      signalName: signal
    },
    responseType: 'arraybuffer'
  });
}

export function getHspiceResultEyeDiagram({ channelId, signal }) {
  return new Promise((resolve, reject) => {
    getAndesHspiceResultEyeDiagram({ channelId, signal }).then(res => {
      if (res && res.data && res.data.byteLength) {
        resolve(res.data);
      }
      resolve(null);
    }, error => {
      resolve(null);
    })
  })
}