
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import AndesTree from './andesTree';
import { PROJECT, VERIFICATION, PCB, TRASH, RESULT } from '../constants';
import {
  clearCurrentProject, updateProjectMenu, changeView, updateTrashMenu, showResult,
  changeTreeSelected, openProject, changeViewList
} from '../store/project/action';
import { expandMenu, saveCurrentVerification } from '../store/andes/action';
import {
  createProjectPromise,
  deleteProjectPromise,
  createVerificationPromise,
  delVerification,
  deleteDesignInProject,
  changeProjectName
} from '@/services/Andes';
import LayoutData from '@/services/data/LayoutData';
import { changeTabMenu, changeVerification, openTabFooter, closeTabFooter } from '../../tabMonitor/action';
import { changeVerificationList, startAndesVerification, getCurrentLog, cancelShow, saveCurrentLog } from '../store/simulation/action';
import { cleanupTrash } from '@/services/api/v2/projectCtrl';
import { strDelimited } from '@/services/helper/split';

message.config({
  duration: 2,
  maxCount: 3,
});

class AndesSider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      modelType: '',
      modelName: '',
      loading: false,
      autoFocus: false,
      inputVerificationName: '',
      defaultVectorName: null,
      errorMsg: '',
      repeatedName: [],
      doubleName: '',
      VectorData: {},
      fileContent: null
    }
  }

  editItem = (e, { key, dataType, item }) => {
    e.stopPropagation();
  }

  changeLoading = () => {
    this.setState({
      loading: false
    })
  }

  createProject = (e, item) => {
    e.stopPropagation();
    const { name } = item;
    this.props.clearCurrentProject(true);
    this.setState({
      autoFocus: false
    })
    const { projectList } = this.props;
    let _filter = projectList.filter(i => i.id !== item.id);
    let names = _filter.map(item => item.name);
    if (names.includes(name)) {
      message.error('Project:' + name + ' already exists!');
      this.props.UpdateProjectMenu();
      return;
    }
    createProjectPromise(name).then(res => {
      this.props.UpdateProjectMenu({ openProjectId: res.id });
      this.setState({
        autoFocus: false
      })
    })
  };

  openPCB = (designId) => {
    this.props.viewChange('PCB', { designId });
    const { layout, viewList } = this.props;

    let list = [];
    if (layout !== 3) {
      list = [...viewList, 'PCB'];
      list = new Set(list);
    } else {
      list = ['PCB'];
      this.props.closeTabFooter()
    }
    this.props.changeViewList(list);
  }

  setAutoFocus = (autoFocus) => {
    this.setState({ autoFocus });
  }


  delItem = (e, key, item) => {
    e.stopPropagation();
    const { dataType } = item;
    switch (dataType) {
      case PROJECT:
        this.deleteProject(item);
        break;
      case VERIFICATION:
        this.deleteVerification(item);
        break;
      case PCB:
        this.deletePCB(item);
        break;
      case TRASH:
        this.cleanupTrash();
        break;
      default: break;
    }
  }

  cleanupTrash = () => {
    cleanupTrash('Andes').then(res => {
      this.props.updateTrashMenu();
    })
  }

  deleteProject = (item) => {
    const { id } = item;
    let pcbId = null;
    const { currentProjectId } = this.props;
    if (item.children && item.children.length > 0) {
      const pcbList = item.children.filter(item => item.name === 'PCB');
      if (pcbList[0].children && pcbList[0].children.length > 0) {
        pcbId = pcbList[0].children[0].id;
      }
    }
    if (pcbId) {
      LayoutData.cleanLayoutInfo(pcbId);
    }

    deleteProjectPromise([id]).then(res => {
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      this.props.updateTrashMenu();
      if (currentProjectId === id) {
        this.props.clearCurrentProject(true);
        // this.props.cleanTabMonitorMsg();
        let { expandedKeys } = this.props;
        let keys = [...expandedKeys];
        let deleteKey = `project-${id}`;
        const delIndex = keys.findIndex(item => item === deleteKey);
        if (delIndex > -1) {
          keys.splice(delIndex, 1);
        }

        this.props.expandMenu(keys);
      }
    });
  }

  deleteVerification = (item) => {
    const { id } = item;
    const { verificationId, currentProjectId, viewList } = this.props;
    delVerification([id]).then(res => {
      if (id === verificationId) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
        this.props.clearCurrentProject();

        //Close current open Interface
        let list = viewList.filter(item => item !== VERIFICATION);
        this.props.changeViewList(list)

        this.props.closeTabFooter();
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
      }
    });
  }

  deletePCB = (item) => {
    const { id } = item;
    const { currentProjectId, verificationId, selectedKeys, viewList, designID } = this.props;
    deleteDesignInProject(id).then(res => {
      // Update project menu list
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      // Open current project
      /*  this.props.openProject(currentProjectId); */
      // Clear the right content page status
      this.props.clearCurrentProject();

      if (designID === id) {
        //Close current open PCB
        let list = viewList.filter(item => item !== PCB);
        this.props.changeViewList(list);
      }
      //close monitor box
      this.props.closeTabFooter();
      if (selectedKeys.length > 0) {
        let newKeys = [];
        selectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (key !== VERIFICATION && key !== 'result') {
            newKeys.push(item);
          }
        });
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([])
      }
    }, error => {
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
    })
  }

  createVerification = (value, item) => {
    const VName = value;
    const { currentProjectVerifications, viewList, layout, verificationId } = this.props;
    const _filter = currentProjectVerifications.filter(verification => verification.id !== item.id);
    const exsitV = _filter.map(item => item.name);
    const projectId = strDelimited(item.key, "-", { returnIndex: 1 });
    if (exsitV.includes(VName)) {
      /*  this.props.openProject(projectId); */
      message.error('Verification:' + VName + ' already exists!');
      this.props.UpdateProjectMenu({ openProjectId: projectId, verificationId });
      return;
    }
    createVerificationPromise({ name: VName, projectId }).then(res => {
      const verificationId = res.id;
      // this.props.createConfig(verificationId);
      this.props.UpdateProjectMenu({ openProjectId: projectId, verificationId, verificationName: res.name });

      const { name } = res;
      const { selectedKeys } = this.props;
      this.props.viewChange(VERIFICATION, { verificationId });
      let list = [...viewList, VERIFICATION];
      list = [...new Set(list)];
      list = list.filter(item => item !== RESULT);
      if (layout === 3) {
        list = [VERIFICATION]
      }
      this.props.changeViewList(list);
      this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId });
      this.props.changeVerification(name);
      this.props.openTabFooter();
      this.props.cancelShow(false);
      /* this.props.getCurrentLog(verificationId); */
      this.props.saveCurrentLog(null);

      if (selectedKeys.length > 0) {
        let newKeys = [];
        selectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (key !== VERIFICATION && key !== 'result') {
            newKeys.push(item);
          }
        });
        newKeys.push(`${VERIFICATION}-${verificationId}`);
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([`${VERIFICATION}-${verificationId}`])
      }
    });
  }

  openVerification = (verificationId, status) => {
    const { currentProjectVerifications, currentProjectId, viewList, layout } = this.props;
    const index = currentProjectVerifications.findIndex(item => item.id === verificationId);
    let name = null;
    if (typeof (index) !== 'boolean' && index > -1) {
      name = currentProjectVerifications[index].name;
    }
    this.props.viewChange(VERIFICATION, { verificationId });
    // cleanCompsInfo();
    let list = [...viewList, VERIFICATION];
    list = [...new Set(list)];
    list = list.filter(item => item !== RESULT);
    if (layout === 3) {
      list = [VERIFICATION]
    }

    const { selectedKeys } = this.props;
    if (status) {
      if (selectedKeys.length > 0) {
        let newKeys = [];
        selectedKeys.forEach((item, index) => {
          const [key] = strDelimited(item, "-");
          if (key !== VERIFICATION && key !== 'result') {
            newKeys.push(item);
          }
        });
        newKeys.push(`${VERIFICATION}-${verificationId}`);
        this.props.changeTreeSelected([...newKeys]);
      } else {
        this.props.changeTreeSelected([`${VERIFICATION}-${verificationId}`])
      }
    }
    this.props.changeViewList(list);
    this.props._changeTabMenu({ selectKeys: ['monitor'], menuType: 'simulation', verificationId, projectId: currentProjectId });
    this.props.changeVerification(name);
    this.props.openTabFooter();
    this.props.cancelShow(false);
    if (!status) {
      this.props.getCurrentLog(verificationId);
    }
  }

  changeErrorMsg = (msg) => {
    this.setState({
      errorMsg: msg
    })
  }

  changeSimulateCheck = (e, key, verification) => {
    e.stopPropagation();
    const { simulateKeys } = this.props;
    let keys = [...simulateKeys];
    const index = keys.findIndex(item => item.verificationId === verification.id);

    if (typeof (index) !== 'boolean' && index > -1) {

      if (!e.target.checked) {
        keys.splice(index, 1)
      }
    } else {

      if (e.target.checked) {
        keys.push({
          verificationId: verification.id
        })
      }
    }
    this.props.changeVerificationList(keys);
    /*    if (keys.length === 1 && (!singleVerifyInfo || !singleVerifyInfo.verificationId)) {
         this.props.changeVerifyStatus(false);
       } else {
         this.props.changeVerifyStatus(true);
       } */
  }

  startVerification = (e, key, item) => {
    e.stopPropagation();
    const { simulateKeys, verificationId, selectedKeys, singleVerifyInfo } = this.props;
    if (simulateKeys.length === 0) {
      return;
    }

    if (singleVerifyInfo && singleVerifyInfo.verificationId) {
      message.error('Already have an interface in simulating!');
      return;
    }
    let verificationIds = simulateKeys.map(item => item.verificationId);
    if (verificationIds.length === 1 && (!singleVerifyInfo || (!singleVerifyInfo.verificationId && !singleVerifyInfo.workflowId))) {
      if (verificationIds[0] !== verificationId || selectedKeys.includes(`result-${verificationIds[0]}`)) {
        this.openVerification(verificationIds[0], true);
        this.props.startAndesVerification(verificationIds);
      } else {
        //save current verification ,and do simulation
        this.props.saveCurrentVerification(verificationIds, verificationId);
      }
    }
  }

  closeContentModal = () => {
    this.setState({
      fileContent: null,
      modelType: '',
      modelName: '',
      editVisible: false
    })
  }

  deleteVerificationCreate = (e) => {
    e.stopPropagation();
    const { currentProjectId, verificationId } = this.props;
    this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
  }

  deleteCreateProject = () => {
    const { currentProjectId, expandedKeys, verificationId } = this.props;
    let openProject = false;
    for (const item of expandedKeys) {
      const [key] = strDelimited(item, "-");
      if (key === 'project') {
        openProject = true;
        break;
      }
    }
    if (openProject) {
      this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId });
    } else {
      this.props.UpdateProjectMenu();
    }
  };

  showAndesResult = (id) => {
    const { verificationId, viewList, layout, currentProjectVerifications } = this.props;
    const verificationName = currentProjectVerifications.find(item => item.id === id).name;
    if (id !== verificationId) {
      this.props.showResult('result', { verificationId: id, verificationName, getStatus: true });
    } else {
      this.props.showResult('result', { verificationId: id, verificationName });
    }
    let list = [...viewList, RESULT];
    list = [...new Set(list)];
    list = list.filter(item => item !== VERIFICATION);
    if (layout === 3) {
      list = [RESULT]
    }
    this.props.changeViewList(list);
    const { closeTabFooter, tabVisible } = this.props;
    if (tabVisible) {
      closeTabFooter();
    }
  }


  SaveProjectName = (name, item) => {
    const { currentProjectId, verificationId, viewList } = this.props;
    changeProjectName(item.id, name).then(res => {
      if (verificationId && viewList.includes(VERIFICATION)) {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId, verificationId, verificationName: name });
      } else {
        this.props.UpdateProjectMenu({ openProjectId: currentProjectId });
      }
    })
  }


  render() {
    const { autoFocus, inputValue, inputVerificationName } = this.state;
    return (
      <Fragment>
        <AndesTree
          delItem={this.delItem}
          editItem={this.editItem}
          autoFocus={autoFocus}
          inputValue={inputValue}
          setAutoFocus={this.setAutoFocus}
          createProject={this.createProject}
          openPCB={this.openPCB}
          inputVerificationName={inputVerificationName}
          createVerification={this.createVerification}
          openVerification={this.openVerification}
          changeSimulateCheck={this.changeSimulateCheck}
          startVerification={this.startVerification}
          showAndesResult={this.showAndesResult}
          deleteVerificationCreate={this.deleteVerificationCreate}
          deleteCreateProject={this.deleteCreateProject}
          SaveProjectName={this.SaveProjectName}
        />
      </Fragment>
    )
  }
};

const mapState = (state) => {
  const { AndesReducer: { project, andes, simulationReducer } } = state;
  const { treeItems, currentProjectId, currentProjectVerifications,
    ibisList = [], verificationId, selectedKeys, layout, projectList, viewList, designID } = project;
  const { simulateKeys, singleVerifyInfo } = simulationReducer;
  const { tabVisible } = state.TabMonitorReducer;
  return {
    treeItems,
    expandedKeys: andes.expandedKeys,
    currentProjectId,
    currentProjectVerifications,
    ibisList,
    simulateKeys,
    verificationId,
    singleVerifyInfo,
    tabVisible,
    selectedKeys,
    layout,
    projectList,
    viewList,
    designID
  }
}

const mapDispatch = (dispatch) => ({
  viewChange(view, info) {
    dispatch(changeView(view, info));
  },
  clearCurrentProject(status) {
    dispatch(clearCurrentProject(status));
  },
  UpdateProjectMenu(obj) {
    dispatch(updateProjectMenu(obj));
  },
  updateTrashMenu() {
    dispatch(updateTrashMenu());
  },
  expandMenu(expandedKeys) {
    dispatch(expandMenu(expandedKeys));
  },
  _changeTabMenu({ selectKeys, menuType, verificationId, projectId }) {
    dispatch(changeTabMenu({ selectKeys, menuType, verificationId, projectId }))
  },
  changeVerification(name) {
    dispatch(changeVerification(name));
  },
  openTabFooter() {
    dispatch(openTabFooter())
  },
  changeVerificationList(simulateKeys) {
    dispatch(changeVerificationList(simulateKeys))
  },
  startAndesVerification(verificationIds) {
    dispatch(startAndesVerification(verificationIds));
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  getCurrentLog(verificationId) {
    dispatch(getCurrentLog(verificationId))
  },
  showResult(view, info) {
    dispatch(showResult(view, info));
  },
  changeTreeSelected(selectedKeys) {
    dispatch(changeTreeSelected(selectedKeys))
  },
  cancelShow(status) {
    dispatch(cancelShow(status));
  },
  openProject(projectId) {
    dispatch(openProject(projectId));
  },
  changeViewList(viewList) {
    dispatch(changeViewList(viewList));
  },
  saveCurrentLog(log) {
    dispatch(saveCurrentLog(log))
  },
  saveCurrentVerification(verificationIds, currentVerificationId) {
    dispatch(saveCurrentVerification(verificationIds, currentVerificationId))
  }
});

export default connect(mapState, mapDispatch)(AndesSider);