import { getDetailData } from '../library';
import { TRACE, VIA } from '@/constants/libraryConstants';

class PreLayoutLibraryData {
  constructor() {
    this.trace = new Map(); //key - library id, value :{ id ,name, type, version }
    this.via = new Map(); //key - library id, value :{ id ,name, type, version }
  }

  getLibrary = (type, id) => {
    return this[type].get(id);
  }

  addLibrary = (type, id, data) => {
    this[type].set(id, data);
  }

  getLibraryConfig = (id, type) => {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(null);
      }
      const library = this.getLibrary(type, id);
      if (!library || !library.config) {
        getDetailData(id).then(res => {
          this.addLibrary(type, id, res);
          resolve(res);
        })
      } else {
        resolve(library);
      }
    })
  }

  updateLibraryConfig = (id, type) => {
    if (id) {
      getDetailData(id).then(res => {
        const newType = type === VIA ? VIA : TRACE;
        this.addLibrary(newType, id, res);
      })
    }
  }

  clearAllCache = () => {
    this.trace = new Map();
    this.via = new Map();
  }
}

let TemplateData = new PreLayoutLibraryData();
export default TemplateData;