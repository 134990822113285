import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExtractionPorts from '@/components/ExtractionPorts/portsRender';
import { getSignalNetInfo, PortsGenerationSetup, updateSetupComponentsByPortType } from '@/services/ExtractionPortsHelper';
import DesignInfo from '@/services/Sierra/pcbInfo';
import { SIERRA } from '@/constants/pageType';
import { sierraExtractionPortsType } from '../constants';
import { saveMultiSetupExtractionPorts, updateMultiSetupExtractionStatus } from '../store/multiInterface/action';

class MultiSetupExtractionPorts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      port_setups: [],
      referenceNets: [],
      generateRefNets: [],
      ports_generate_setup_list: [],
      setupComponents: []
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this, this.props.current);
    }
    this.initPortSetups();
  }

  componentDidUpdate = (prevProps) => {
    const { isUpdateExtraction, portsStatus } = this.props;
    if (isUpdateExtraction && prevProps.isUpdateExtraction !== isUpdateExtraction) {
      this.initPortSetups();
      this.props._updateMultiSetupExtractionStatus(false)
      this.props.closeLoading();
    }
    if (!portsStatus && prevProps.portsStatus && portsStatus !== prevProps.portsStatus) {
      this.props.closeModal();
    }
  }

  initPortSetups = () => {
    const { portsInfo, current } = this.props;
    const _portsInfo = portsInfo || {};
    const { port_setups, ports_generate_setup_list, referenceNets, components } = _portsInfo[current] || {};
    let setupList = ports_generate_setup_list;
    if (!ports_generate_setup_list || !ports_generate_setup_list.length) {
      setupList = (components || []).filter(item => sierraExtractionPortsType.includes(item.type)).map(item => item.name).map(item => { return { component: item, setup: new PortsGenerationSetup({}) } });
    }
    const _port_setup_list = port_setups && port_setups.length ? JSON.parse(JSON.stringify(port_setups)) : [];
    this.setState({
      port_setups: _port_setup_list,
      referenceNets: referenceNets || [],
      generateRefNets: referenceNets ? [...referenceNets] : [],
      ports_generate_setup_list: setupList,
      setupComponents: JSON.parse(JSON.stringify(components || [])),
      update: false
    }, () => {
      if (this.portsRenderRef) {
        this.portsRenderRef.updatePortRenderValue()
      }
    })
  }

  savePortsSetup = (saveType) => {
    const confirm = this.portsRenderRef.savePorts(saveType);
    if (saveType === "changePCB" && !confirm && this.state.update) {
      this.saveSetup(saveType);
    }
    if (saveType === "changePCB" && !confirm && !this.state.update) {
      this.props.updateCurrent();
    }
    return confirm;
  }

  _updatePortSetups = (port_setups, generationSetup, saveType) => {
    const { ports_generate_setup_list } = this.state;
    this.setState({
      port_setups,
      ports_generate_setup_list: generationSetup ? generationSetup : ports_generate_setup_list,
      update: true
    }, () => {
      this.saveSetup(saveType);
      /* isClose && this.props.closeModal(); */
    })
  }

  saveSetup = (saveType) => {
    const { portsInfo = {}, current } = this.props;
    const { port_setups, generateRefNets, ports_generate_setup_list, setupComponents } = this.state;
    const _setupComponents = updateSetupComponentsByPortType({
      components: setupComponents,
      ports_generate_setup_list,
      designId: current,
      extractionType: portsInfo[current].channel ? portsInfo[current].channel.type : ""
    });
    const { applyIds, applyType } = saveType ? this.props.getApplyVIds() : {};
    this.props._saveMultiSetupExtractionPort({
      port_setups,
      referenceNets: generateRefNets,
      ports_generate_setup_list,
      components: _setupComponents,
      saveType,
      pcbId: current,
      applyIds,
      applyType
    });
  }

  updateReferenceNets = (keys) => {
    this.setState({
      referenceNets: keys
    })
  }

  updateGenerateRefNets = (nets) => {
    this.setState({
      generateRefNets: nets
    })
  }

  closePanel = () => {
    this.state.update && this.saveSetup("close");
    if (!this.state.update) {
      this.props.closeModal();
    }
  }

  updateSetupComponents = (setupComponents) => {
    this.setState({
      setupComponents
    })
  }

  onRef = (ref) => {
    this.portsRenderRef = ref;
  }

  updateSetupBySaveType = () => {
    if (this.state.update) {
      this.saveSetup("changePCB");
    } else {
      this.props.updateCurrent();
    }
  }

  render() {
    const { port_setups, referenceNets, ports_generate_setup_list,
      generateRefNets, setupComponents } = this.state;
    const { maxHeight, portsInfo, current, loadingPCB } = this.props;
    const _portsInfo = portsInfo || {};
    const { signals = [], powerNets = [], channel } = _portsInfo[current] || {};
    return (<ExtractionPorts
      designId={current}
      product={SIERRA}
      ports_generate_setup_list={ports_generate_setup_list}
      port_setups={port_setups}
      DesignInfo={DesignInfo}
      components={setupComponents}
      signals={signals}
      referenceNets={referenceNets}
      generateRefNets={generateRefNets}
      extractionType={channel ? channel.type : ""}
      allReferenceNetList={powerNets.map(item => item.name)}
      getSignalNetInfo={getSignalNetInfo}
      closePanel={this.closePanel}
      updateCurrent={this.props.updateCurrent}
      updatePortSetups={this._updatePortSetups}
      updateReferenceNets={this.updateReferenceNets}
      updateGenerateRefNets={this.updateGenerateRefNets}
      updateSetupComponents={this.updateSetupComponents}
      updateSetupBySaveType={this.updateSetupBySaveType}
      maxHeight={maxHeight}
      onRef={this.onRef}
      loadingPCB={loadingPCB}
    />)
  }
}

const mapState = (state) => {
  const { SierraReducer: { multiInterfaceSetup: { portsInfo, loadingPCB, isUpdateExtraction, portsStatus } } } = state;
  return {
    portsInfo,
    loadingPCB,
    isUpdateExtraction,
    portsStatus
  };
}

const mapDispatch = (dispatch) => ({
  _saveMultiSetupExtractionPort(info) {
    dispatch(saveMultiSetupExtractionPorts(info))
  },
  _updateMultiSetupExtractionStatus(status) {
    dispatch(updateMultiSetupExtractionStatus(status))
  }
})

export default connect(mapState, mapDispatch)(MultiSetupExtractionPorts);