import http from "../http";

/** 
* Get project list
* 2023/05/18
* @return
*/
function getHimalayasProjectList() {
  return http.get(`my-project/type/Himalayas`);
}

/** 
* create new project
* 2023/05/19
* @param {object} data: { name }
* @return
*/
function createHimalayasProject({ data }) {
  return http.post(`/my-project/`, {
    ...data,
    typeName: "Himalayas"
  });
}

/** 
* get project children
* 2023/05/22
* @param {string} projectId
* @return
*/
function getHimalayasProjectChildren(projectId) {
  return http.get(`/Himalayas/project/${projectId}`)
}

/** 
* delete projects by ids
* 2023/05/22
* @param {string} projectIds
* @return
*/
function delHimalayasProject(ids) {
  return http.delete(`/my-project/`, { data: ids })
}

/** 
* channel (spice card and io sim ) rename
* 2023/06/01
* @param {string} verificationId
* @param {string} name -> new name
* @return
*/
function renameHimalayasChannel({ verificationId, name }) {
  return http.post(`/Himalayas/channel/rename`, {
    verificationId,
    name
  })
}

/**
 * Get himalayas project report config
 *
 * @export
 * @param {string} projectId
 * @returns
 */
function getHimalayasReportConfig(projectId) {
  return http.get(`/Himalayas/report/${projectId}/config`);
}

/**
 * Get himalayas project report file
 *
 * @export
 * @param {string} projectId
 * @param {string} format pptx/pdf
 * @returns promise
 */
function getHimalayasReportFile({ projectId, format }) {
  return http.get(`/Himalayas/report/${projectId}/${format}`);
}

/**
 * Get himalayas project report status
 *
 * @export
 * @param {string} projectId
 * @returns
 */
function getHimalayasReportStatus(projectId) {
  return http.get(`/Himalayas/report/${projectId}/status`);
}

/**
 * Update himalayas project report config
 *
 * @export
 * @param {string} projectId
 * @param {Object} params :{ channelIds, customs, fileName, format, language, projectId, templateId }
 * @returns
 */
function updateHimalayasReportConfig({ projectId, config }) {
  return http.post(`/Himalayas/report/${projectId}/config`, { ...config });
}

/**
 * Generate himalayas project report
 *
 * @export
 * @param {string} projectId
 * @param {Object} params :{ channelIds, customs, fileName, format, language, projectId, templateId }
 * @returns
 */
function generateHimalayasReport(params) {
  return http.post(`/Himalayas/report`, { ...params });
}

export {
  getHimalayasProjectList,
  createHimalayasProject,
  getHimalayasProjectChildren,
  delHimalayasProject,
  renameHimalayasChannel,
  getHimalayasReportConfig,
  getHimalayasReportFile,
  getHimalayasReportStatus,
  updateHimalayasReportConfig,
  generateHimalayasReport
}