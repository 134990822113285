import {
  LAYER_CHANGE,
  LOCATION_CHANGE,
  NET_CHANGE,
  COMP_CHANGE,
  CLEAR_SELECTION,
  OPEN_PANEL,
  CLOSE_PANEL,
  SELECT_SIGNAL,
  CREATE_SIGNAL,
  CANCEL_SIGNAL,
  COLORBY_CHANGE,
  OPEN_DESIGN_INFO_PANEL,
  CLOSE_DESIGN_INFO_PANEL,
  SIMULATION_FINISHED,
  SELECTED_ALL,
  CANCEL_SELECTED_ALL,
  SHOW_SELECTED,
  SELECTION_CHECKBOX_CHANGE,
  SELECTION_CLEAR,
  CLEAN_STATUS,
  CHANGE_SELECTED_DISPLAY,
  SELECT_CHANGE
} from './actionTypes';

export const updateLocation = (location) => ({
  type: LOCATION_CHANGE,
  location,
})

export const selectLayer = (layers) => ({
  type: LAYER_CHANGE,
  layers
})

export const canvasSelect = (selection) => {
  if (selection && (selection.nets || selection.comps)) {
    return {
      type: selection.nets && selection.comps ? SELECT_CHANGE : selection.nets ? NET_CHANGE : COMP_CHANGE,
      ...selection
    }
  } else {
    return {
      type: CLEAR_SELECTION
    }
  }
}

export const openPanel = (panelType, designID) => ({
  type: OPEN_PANEL,
  panel: panelType,
  designID
})

export const closePanel = () => ({
  type: CLOSE_PANEL
})

export const selectSignal = (selected = [], name = '', from = '') => ({
  type: SELECT_SIGNAL,
  selected,
  name,
  from
})

export const createSignal = (nets, createType) => ({
  type: CREATE_SIGNAL,
  nets,
  createType
})

export const cancelSignal = () => ({
  type: CANCEL_SIGNAL,
})

export const changeColorBy = (colorBy) => ({
  type: COLORBY_CHANGE,
  colorBy
})

export const openDesignInfo = () => ({
  type: OPEN_DESIGN_INFO_PANEL,
})

export const closeDesignInfo = () => ({
  type: CLOSE_DESIGN_INFO_PANEL,
})

export const simulationFinished = (status) => ({
  type: SIMULATION_FINISHED,
  status,
})

export const clearSelection = () => ({
  type: SELECTION_CLEAR,
})

export const showSelected = () => ({
  type: SHOW_SELECTED
})

export const selectedAll = () => ({
  type: SELECTED_ALL,
})

export const cancelSelectedAll = () => ({
  type: CANCEL_SELECTED_ALL,
})

export const checkboxChange = (canvasObj) => {
  return {
    type: SELECTION_CHECKBOX_CHANGE,
    payload: canvasObj
  };
}

export const cleanStatus = () => ({
  type: CLEAN_STATUS,
})

export const changeDisplaySelected = (show) => ({
  type: CHANGE_SELECTED_DISPLAY,
  show
})
