import {
  IntegratedInterface
} from './IntegratedInterface';

export function Extraction() {
  this.clipping = true;
  this.logSweepMin = "1000";
  this.logSweepMax = "1e7";
  this.logSweepSAPD = "10";
  this.linearSweepMax = "5e9";
  this.linearSweepFreqStep = "5e6";
  this.portImpedance = "50";
  this.maxFreq = "";
  this.clipSize = "3mm"; //float >= 0.1, unit: ratio
  this.includeDC = false; //true/false
  this.exactDC = false; //true/false
  this.discreteSweep = false; //true/false
  this.meshFrequency = "5"; //float >1
  this.maxPasses = "10"; // only show if modeling with HFSS
  this.maxDelta = "0.02"; //float [0.001,1] only show if modeling with HFSS
};

export class AndesInterface extends IntegratedInterface {
  constructor(props) {
    super(props);
    this.extraction = new Extraction();
    this.channel = {
      type: 'SIwave'
    }
  }
};