import React, { Component } from 'react';
import { Button } from 'antd';
import Panel from '@/components/Panel';
import Table from '../../../../components/EditableTable/EditableTable';
import './index.css';

const columns = [{
  title: 'Error Type',
  dataIndex: 'errorType',
  width: '15%',
}, {
  title: 'Location',
  dataIndex: 'location',
  width: '20%',
}, {
  title: 'Detail',
  dataIndex: 'detail',
  width: '65%'
}];

class LayoutError extends Component {

  constructor(props) {
    super(props);
    this.state = {
      results: []
    }
  }

  componentDidMount() {
    this.setDefaultColumns();
    this.setResults()
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  setDefaultColumns = () => {
    columns[0].render = (text) => {
      return <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
    }

    columns[1].render = (text) => {
      return <span className="aurora-cursor" onClick={(e) => this.selectLocation(e, text)}>{text}</span>
    }

    columns[2].render = (text, record) => {
      const { item } = record;
      return <span className="aurora-cursor" onClick={(e) => this.selectElement(e, item)}>{Array.isArray(item) ? `` : `${item} - `}{text}</span>
    }
  }

  setResults = () => {
    const { result = {} } = this.props;
    if (!result) {
      return
    }

    const keys = Object.keys(result);
    let results = [];
    for (let key of keys) {
      results.push(...result[key].map(item => ({ ...item, errorType: key })))
    }
    this.setState({
      results
    })
  }

  selectElement = (e, text) => {
    e && e.stopPropagation()
    const element = Array.isArray(text) ? text : text.split(', ');
    const canvasObj = this.props.onJudge(element)
    if (canvasObj) {
      this.props.onSelect(canvasObj)
    }
  }

  selectLocation = (e, text) => {
    e && e.stopPropagation()
    if (!text.includes('None')) {
      this.props.onSearch(text)
    }
  }

  closePanel = () => {
    const { designId } = this.props;
    this.props.closePanel(designId);
  }

  recheckLayout = (e) => {
    this.closePanel();
    this.props.layoutCheck(e)
  }

  render() {
    const { leftWidth, topWidth, defaultLeft, defaultTop, maxWidth, designId, maxHeight } = this.props;
    const { results } = this.state;
    const positionProps = {
      position: 'panel-top-left',
      left: leftWidth ? leftWidth : defaultLeft,
      top: topWidth ? topWidth : defaultTop,
      defaultLeft: 50
    }
    const data = results.map((record, index) => ({ ...record, index }))
    return (
      <Panel
        {...positionProps}
        title={'Layout Check'}
        visible
        onCancel={() => this.closePanel()}
        width={maxWidth < 800 ? maxWidth : 800}
        className='menu-panel aurora-menu-panel-layout-errors'
        draggable
        minHeight={200}
        minWidth={500}
        maxWidth={maxWidth}
        zIndex={10}
        overflow='hidden'
        redirectDom={`aurora-PCB-layout-content-${designId}`}
        footer={<Button type="primary" onClick={(e) => this.recheckLayout(e)}>Recheck Layout</Button>}
      >
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.index}
          size="small"
          scroll={maxHeight - 200 < results.length * 40 ? { y: maxHeight - 200 } : {}}
        />
      </Panel>
    )
  }
}

export default LayoutError;