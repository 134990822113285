import { call, put, takeEvery, select, delay, takeLatest } from 'redux-saga/effects';
import { getWorkFlow, cancelWorkflow, cancelVerificationWorkflow } from '@/services/api/v2/workflowCtrl';
import { workflowWaitingIndex, getMonitor, checkVerificationStatus } from '@/services/workflow/workflow';
import * as taskStatus from '@/constants/workflowStatus';
import { VERIFY_SUCCESS, VERIFY_NEVER } from '@/constants/verificationStatus';
import dayjs from 'dayjs';
import { getInterfaceWorkStatus } from '@/services/project';
import {
  START_ANDES_VERIFICATION,
  GET_SINGLE_MONITOR,
  CANCEL_WORKFLOW,
  DEBUG_VERIFY,
  VERIFICATION_FLOW,
  GET_CURRENT_LOG,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_CURRENT_PROFILE,
  GET_INTERFACE_MONITOR
} from './actionTypes';
import {
  updateProgress,
  singleVerifyInfo,
  getSingleMonitor,
  cleanSingleProgress,
  updateSingleMonitor,
  cleanMonitor,
  runningShow,
  cancelShow,
  existResult,
  updateSimulationMsg,
  changeVerificationList,
  verificationFlow,
  saveCurrentLog,
  waitingIndexAction,
  updateErrorCheckList,
  updatePreProcessLog,
  getCurrentProfile,
  saveCurrentProfileLog
} from './action';
import { getAndesErrorCheck } from '../../errorCheck/ErrorCheck';
import AndesVerify from '@/services/Andes/AndesVerifyDatabase';
import { updateReadyForSim } from '@/services/api/interface';
import {
  doAndesSimulation,
  doDebugVerify, getVerificationContentPromise,
  updateInterfacePromise
} from '@/services/Andes';
import { autoGetVerificationList } from '../project/action';
import { updateNetsComps } from '../andes/action';
import { getAndesSimulationLog } from '@/services/api/Andes';
import { fakeProgress } from '@/services/helper/dataProcess';
import { PCBVersionChanged, updateContentAfterPCBUpdate } from '../andes/saga';
import monitorLog from '@/services/helper/monitorLog';
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';

function* startVerification(action) {
  try {
    let { verificationIds } = action;
    yield put(changeVerificationList([]));
    if (verificationIds.length === 0) {
      return;
    }
    const { AndesReducer: { project } } = yield select();
    const { currentProjectId } = project;
    let currentSimInfo = verificationIds.map(_verificationId => ({ verificationId: _verificationId, status: 'checking' }));
    yield* verificationIds.map(function* (item) {
      yield put(cleanMonitor(item));
      yield put(updateSimulationMsg({ msg: null, verificationId: item }));
    })
    yield put(autoGetVerificationList(currentProjectId, currentSimInfo));
    yield* verificationIds.map(function* (id) {
      const responseContent = yield call(getVerificationContentPromise, id);
      const SETUP = AndesVerify;
      // { name, signals, components, mddels, stimuli, powerNets, channel, powerComponents }
      let Interfaces = responseContent.Interfaces;
      let mergeInfo = SETUP.mergeInterfacesInfo(Interfaces, responseContent.Name);

      // check design version
      try {
        const { AndesReducer: { project: { currentProjectDesigns } } } = yield select();
        if (PCBVersionChanged(currentProjectDesigns, Interfaces)) {
          const obj = yield call(updateContentAfterPCBUpdate, { Interfaces, name: responseContent.Name, update: false });
          const { deleteNets, deleteComponents, } = obj;
          mergeInfo = obj.andesInfo.info;
          Interfaces = obj.andesInfo.Interfaces;
          const { AndesReducer: { andes: { updateNetsComponents } } } = yield select();
          let netsComponents = [...updateNetsComponents];
          let updateIndex = netsComponents.findIndex(item => item.verificationId === id);
          if (updateIndex > -1) {
            if (!deleteNets.length && !deleteComponents.length) {
              netsComponents.splice(updateIndex, 1)
            } else {
              netsComponents[updateIndex].deleteNets = deleteNets;
              netsComponents[updateIndex].deleteComponents = deleteComponents;
            }
          } else {
            netsComponents.push({
              deleteNets,
              deleteComponents,
              verificationId: id
            })
          }
          yield put(updateNetsComps(netsComponents));
        }
      } catch (error) {
        console.error(error);
      }

      const PCBsInInterface = Interfaces.map(item => item.pcbId);
      const andesInfo = {
        Interfaces,
        PCBsInInterface,
        info: mergeInfo,
        verificationId: id,
      };
      const errorCheck = getAndesErrorCheck(andesInfo);
      let readyForSim = 1;
      if (errorCheck) {
        readyForSim = 0;
      };
      yield call(saveInterfaces, { Interfaces, id, currentProjectId, readyForSim });

      const { AndesReducer: { simulationReducer } } = yield select();
      let { andesInfoErrorCheck } = simulationReducer;
      if (errorCheck) {
        const { AndesReducer: { project: { currentProjectId } } } = yield select();
        currentSimInfo.forEach(item => {
          if (item.verificationId === id) {
            item.status = 'error'
          }
        })
        yield put(autoGetVerificationList(currentProjectId, currentSimInfo));
        yield* Interfaces.map(function* (item) {
          yield call(updateReadyForSim, item.interfaceId, 0);
        });
        const index = andesInfoErrorCheck.findIndex(item => item.verificationId === id);
        if (index > -1) {
          andesInfoErrorCheck[index].errorCheck = { ...errorCheck };
        } else {
          andesInfoErrorCheck.push({
            verificationId: id,
            errorCheck: errorCheck
          })
        }
        yield put(updateErrorCheckList(andesInfoErrorCheck));
        yield put(updateSimulationMsg({ msg: null, verificationId: id }));
      } else {
        const index = andesInfoErrorCheck.findIndex(item => item.verificationId === id);
        if (index > -1) {
          andesInfoErrorCheck.splice(index, 1);
        }
        yield put(updateErrorCheckList(andesInfoErrorCheck));
        currentSimInfo.forEach(item => {
          if (item.verificationId === id) {
            item.status = 'success'
          }
        })
        yield put(autoGetVerificationList(currentProjectId, currentSimInfo));
      }
    });

    const { AndesReducer: { simulationReducer: { andesInfoErrorCheck } } } = yield select();
    if (andesInfoErrorCheck.length > 0) {
      let ids = andesInfoErrorCheck.map(item => item.verificationId);
      verificationIds = verificationIds.filter(item => !ids.includes(item));
    }

    if (verificationIds.length === 0 || !verificationIds) {
      yield put(autoGetVerificationList(currentProjectId));
      return;
    }
    let verificationId = verificationIds[0];
    yield* verificationIds.map(function* (item) {
      yield put(cleanMonitor(item));
      let { AndesReducer: { simulationReducer: { preProcessLog } } } = yield select();
      const preIndex = preProcessLog.findIndex(i => i.verificationId === item);
      if (typeof (preIndex) !== 'boolean' && preIndex > -1) {
        preProcessLog[preIndex].log = null;
      }
      yield put(updatePreProcessLog(preProcessLog));
      yield put(updateSimulationMsg({ msg: null, verificationId: item }));
    })
    yield put(autoGetVerificationList(currentProjectId));
    let response = null;
    try {
      response = yield call(doAndesSimulation, verificationIds);
      yield put(cleanMonitor(verificationId));
      yield put(saveCurrentLog(null));
      yield put(runningShow(true));
      yield put(cancelShow(false));
      yield put(updateSimulationMsg({ msg: null, verificationId }));
    } catch (error) {
      yield put(updateProgress({ verificationId, progress: -1 }));
      yield put(cleanSingleProgress(verificationId));
      yield put(changeVerificationList([]));
      yield put(runningShow(false));
      let msg = error ? error : 'Simulation failed!';
      const errorMsg = `==> ${msg}`;
      yield put(updateSimulationMsg({ msg: errorMsg, verificationId }));
      yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
      yield put(autoGetVerificationList(currentProjectId));
      return;
    }

    try {
      // fake progress
      // id: workflowId
      const res = response.find(item => item.verificationId === verificationId);

      yield call(simulationWorkflow, { res, currentProjectId, verificationId })
    } catch (error) {
      console.error(error);
      yield delay(1000);
      yield put(updateProgress({ verificationId, progress: -1 }));
      yield put(cleanSingleProgress(verificationId));
      yield put(changeVerificationList([]));
      yield put(runningShow(false));
      const promise = yield call(checkVerificationStatus, verificationId);
      let resultExist = false;

      if (promise && promise.status) {
        if (promise.status === VERIFY_SUCCESS) {
          resultExist = true;
        }
      };
      yield put(existResult(resultExist));
    }
  } catch (error) {
    console.error(error);
    return;
  }
}

function* simulationWorkflow(action) {
  try {
    const { res, currentProjectId, verificationId } = action;
    let { id, status, progress, taskList } = res;
    const getProfile = shouldGetProfile(taskList);
    yield put(autoGetVerificationList(currentProjectId));

    yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
    let waitingIndex = -1;
    waitingIndex = yield call(workflowWaitingIndex, id);
    while (waitingIndex >= 0) {
      // ==> Check whether it is in the current Monitor
      // Get opened monitor verification id
      const { TabMonitorReducer } = yield select();
      // Switch account or switch PDN
      if (TabMonitorReducer.currentVerificationId !== verificationId) {
        // <- update status
        // TODO - If the switch time is less than 3s, it will not return
        // -> update status end
        return;
      }

      let time = new Date();
      time.setUTCSeconds(time.getUTCSeconds());
      let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
      const { AndesReducer: { simulationReducer: { waitingQueue } } } = yield select();
      let queue = [...waitingQueue];
      const n = queue.findIndex(item => item.verificationId === verificationId);
      if (n > -1) {
        queue[n].queueIndex = waitingIndex;
        queue[n].waitingTime = waitingTime;
      } else {
        queue.push({
          queueIndex: waitingIndex,
          verificationId,
          waitingTime
        })
      }
      yield put(waitingIndexAction(queue));
      yield delay(5000);
      // update waiting index
      waitingIndex = yield call(workflowWaitingIndex, id);
    };

    if (waitingIndex === -2) {//-2  simulation cancel
      yield put(cleanSingleProgress(verificationId));
      yield put(runningShow(false));
      yield put(cancelShow(true));
      yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
      return;
    }

    if (waitingIndex === -3) {//-3 simulation complete
      yield put(cleanSingleProgress(verificationId));
      yield put(runningShow(false));
      yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
      yield put(updateSimulationMsg({ msg: `==> Simulation completed!`, verificationId }));
      return;
    }

    const { AndesReducer: { simulationReducer: { waitingQueue } } } = yield select();
    let queue = [...waitingQueue];
    const n = queue.findIndex(item => item.verificationId === verificationId);
    if (n > -1) {
      queue.splice(n, 1);
    }
    yield put(waitingIndexAction(queue));

    if (progress === 0) {
      progress += 2;
      yield put(updateProgress({ verificationId, progress }));
    }
    let currentVerificationId = verificationId;
    yield put(getSingleMonitor(id, verificationId));
    let indexNum = 0;
    while (status === taskStatus.RUNNING && verificationId === currentVerificationId) {
      // ==> Check whether it is in the current Monitor
      // Get opened monitor verification id
      const { TabMonitorReducer } = yield select();
      // Switch account or switch PDN
      if (TabMonitorReducer.currentVerificationId !== verificationId) {
        // <- update status
        // TODO - If the switch time is less than 3s, it will not return
        // -> update status end
        return;
      }
      if (getProfile && indexNum % 6 === 0) {
        yield put(getCurrentProfile(verificationId));
      }
      yield delay(5000);
      const { data: { data } } = yield call(getWorkFlow, id);
      const { AndesReducer: { project } } = yield select();
      currentVerificationId = project.verificationId;
      status = data.status;
      indexNum += 1;

      progress = fakeProgress(progress, indexNum, data.progress);

      yield put(updateProgress({ verificationId, progress }));
    }
    yield put(getCurrentProfile(verificationId));
    if (status === taskStatus.CANCEL) {
      yield put(cleanSingleProgress(verificationId));
      yield put(runningShow(false));
    } else {
      yield put(updateProgress({ verificationId, progress }));
    }
    yield delay(2000);
    yield put(updateProgress({ verificationId, progress: -1 }));
    yield put(cleanSingleProgress(verificationId));
    yield put(runningShow(false));
    yield put(changeVerificationList([]));

    yield call(updateStatus, { status, verificationId });
  } catch (error) {
    console.error(error);
    return;
  }
}

function* saveInterfaces(action) {
  const { Interfaces, id, currentProjectId, readyForSim } = action;
  yield* Interfaces.map(function* (_interface) {
    yield call(updateInterfacePromise, {
      designId: _interface.pcbId,
      interfaceId: _interface.interfaceId,
      interfaceName: _interface.name,
      projectId: currentProjectId,
      verificationId: id,
      verificationName: _interface.name,
      content: _interface.content,
      readyForSim: readyForSim,
      version: _interface.version,
      ifDoExtraction: _interface.ifDoExtraction,
      designVersion: _interface.designVersion
    });
  })
}

function* getMonitorLog(action) {
  const { workflowId, verificationId } = action;
  let first = true, active = true;
  while (active) {
    // ==> Check whether it is in the current Monitor
    // Get opened monitor verification id
    const { TabMonitorReducer } = yield select();
    // Switch account or switch PDN
    if (TabMonitorReducer.currentVerificationId !== verificationId) {
      // <- update status
      // TODO - If the switch time is less than 3s, it will not return
      // -> update status end
      return;
    }
    if (!first) {
      yield delay(5000);
    }
    first = false;
    let { AndesReducer } = yield select();
    const { simulationReducer: { singleProgress } } = AndesReducer
    let progress = [];
    progress = singleProgress.filter(item => item.verificationId === verificationId);
    if (progress && progress.length > 0) {
      active = progress[0].progress > -1 && progress[0].progress <= 100;
    } else {
      active = false;
    }
    // get log
    const log = yield call(getMonitor, workflowId);
    if (log.length > 0) {
      yield put(updateSingleMonitor({ workflowId, monitor: monitorLog(log), verificationId }));
    }
  }
}

function* startDebugVerify(action) {
  const { verificationId, step } = action;
  yield put(cleanMonitor(verificationId));
  yield put(saveCurrentLog(null));
  yield put(runningShow(true));
  yield put(cancelShow(false));
  yield put(updateSimulationMsg({ msg: null, verificationId }));
  const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
  if (interfaceStatus) {
    yield put(verificationFlow({ verificationId, interfaceStatus }));
    return;
  }

  try {
    let andesInfo = null, num = 1, time;
    while (!andesInfo || (andesInfo && Object.keys(andesInfo).length === 0) || (andesInfo.verificationId !== verificationId)) {
      yield delay(500);
      const { AndesReducer: { andes } } = yield select();
      andesInfo = andes.andesInfo || null;
      num += 1;
      if (num === 5) {
        break;
      }
    }
    const { AndesReducer: { project, simulationReducer: { andesInfoErrorCheck } } } = yield select();
    let { currentProjectId } = project;
    const errorCheck = getAndesErrorCheck(andesInfo);
    if (errorCheck) {
      const index = andesInfoErrorCheck.findIndex(item => item.verificationId === verificationId);
      if (index > -1) {
        andesInfoErrorCheck[index].errorCheck = { ...errorCheck };
      } else {
        andesInfoErrorCheck.push({
          verificationId: verificationId,
          errorCheck: errorCheck
        })
      }
      yield put(updateErrorCheckList(andesInfoErrorCheck));
    } else {
      const index = andesInfoErrorCheck.findIndex(item => item.verificationId === verificationId);
      if (index > -1) {
        andesInfoErrorCheck.splice(index, 1);
      }
      yield put(updateErrorCheckList(andesInfoErrorCheck));
      let response = null;
      yield put(singleVerifyInfo({ workflowId: null, verificationId: verificationId }));
      yield delay(2000);
      try {
        response = yield call(doDebugVerify, { step, verificationId });
        if (step === 'setup' || step === 'pproc') {
          yield put(updateSimulationMsg({ msg: `==> Verify ${step} successfully!`, verificationId }));
          yield put(changeVerificationList([]));
          yield put(updateProgress({ verificationId, progress: -1 }));
          yield put(runningShow(false));
          yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
          yield put(autoGetVerificationList(currentProjectId));
          return;
        }
      } catch (error) {
        yield put(updateSimulationMsg({ msg: `==> Simulation failed: ${error}`, verificationId }));
        yield put(runningShow(false));
        yield put(changeVerificationList([]));
        yield put(updateProgress({ verificationId, progress: -1 }));
        yield put(cleanSingleProgress(verificationId));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
        yield put(autoGetVerificationList(currentProjectId));
        return;
      }
      // id: workflowId
      let { id, status, progress, taskList } = response;
      const getProfile = shouldGetProfile(taskList);
      if (!id) {
        return;
      }
      yield put(autoGetVerificationList(currentProjectId));
      yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
      let waitingIndex = -1;
      waitingIndex = yield call(workflowWaitingIndex, id);
      // while()
      while (waitingIndex >= 0) {
        // ==> Check whether it is in the current Monitor
        // Get opened monitor verification id
        const { TabMonitorReducer } = yield select();
        // Switch account or switch PDN
        if (TabMonitorReducer.currentVerificationId !== verificationId) {
          // <- update status
          // TODO - If the switch time is less than 3s, it will not return
          // -> update status end
          return;
        }

        time = new Date();
        time.setUTCSeconds(time.getUTCSeconds());
        let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
        const { AndesReducer: { simulationReducer: { waitingQueue } } } = yield select();
        let queue = [...waitingQueue];
        const n = queue.findIndex(item => item.verificationId === verificationId);
        if (n > -1) {
          queue[n].queueIndex = waitingIndex;
          queue[n].waitingTime = waitingTime;
        } else {
          queue.push({
            queueIndex: waitingIndex,
            verificationId,
            waitingTime
          })
        }
        yield put(waitingIndexAction(queue));
        yield delay(5000);
        // update waiting index
        waitingIndex = yield call(workflowWaitingIndex, id);
      };

      if (waitingIndex === -2) {//-2  simulation cancel
        yield put(cleanSingleProgress(verificationId));
        yield put(runningShow(false));
        yield put(cancelShow(true));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
        return;
      }

      if (waitingIndex === -3) {//-3 simulation complete
        yield put(cleanSingleProgress(verificationId));
        yield put(runningShow(false));
        yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
        yield put(updateSimulationMsg({ msg: `==> Simulation completed!`, verificationId }));
        return;
      }

      const { AndesReducer: { simulationReducer: { waitingQueue } } } = yield select();
      let queue = [...waitingQueue];
      const n = queue.findIndex(item => item.verificationId === verificationId);
      if (n > -1) {
        queue.splice(n, 1);
      }
      yield put(waitingIndexAction(queue));

      if (progress === 0) {
        progress += 2;
        yield put(updateProgress({ verificationId, progress }));
      }
      yield put(getSingleMonitor(id, verificationId));
      let indexNum = 0;
      while (status === taskStatus.RUNNING) {
        // ==> Check whether it is in the current Monitor
        // Get opened monitor verification id
        const { TabMonitorReducer } = yield select();
        // Switch account or switch PDN
        if (TabMonitorReducer.currentVerificationId !== verificationId) {
          // <- update status
          // TODO - If the switch time is less than 3s, it will not return
          // -> update status end
          return;
        }
        if (getProfile && indexNum % 6 === 0) {
          yield put(getCurrentProfile(verificationId));
        }
        yield delay(5000);
        const { data: { data } } = yield call(getWorkFlow, id);
        status = data.status;
        indexNum += 1;

        progress = fakeProgress(progress, indexNum, data.progress);

        yield put(updateProgress({ verificationId, progress }));
      }
      yield put(getCurrentProfile(verificationId));
      if (status === taskStatus.CANCEL) {
        yield put(cleanSingleProgress(verificationId));
        yield put(runningShow(false));
      } else {
        yield put(updateProgress({ verificationId, progress }));
      }
      yield delay(2000);
      yield put(updateProgress({ verificationId, progress: -1 }));
      yield put(cleanSingleProgress(verificationId));
      yield put(runningShow(false));
      yield put(changeVerificationList([]));

      yield call(updateStatus, { status, verificationId });
    }
  } catch (error) {
    console.error(error);
    yield delay(1000);
    yield put(updateProgress({ verificationId, progress: -1 }));
    yield put(cleanSingleProgress(verificationId));
    yield put(changeVerificationList([]));
    yield put(runningShow(false));
    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(existResult(resultExist));
  }
}

function* updateStatus(action) {
  const { status, verificationId } = action;
  switch (status) {
    case taskStatus.CANCEL:
      yield put(cancelShow(true));
      break;
    case taskStatus.SUCCEED:
      yield put(updateSimulationMsg({ msg: '==> Simulation completed!', verificationId }));
      break;
    case taskStatus.FAILED:
      yield put(updateSimulationMsg({ msg: '==> Simulation failed!', verificationId }));
      break;
    default:
      break;
  };
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
}

function* cancelWork(action) {
  const { workflowId } = action;
  yield call(cancelWorkflow, workflowId);
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
}

function* cancelVerificationWork(action) {
  const { verificationId } = action;
  yield call(cancelVerificationWorkflow, verificationId);
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
}

function* verificationWorkFlow(action) {
  const { verificationId, interfaceStatus } = action;
  yield put(updateSimulationMsg({ msg: null, verificationId }));
  if (!interfaceStatus || interfaceStatus.length === 0) {
    return;
  }
  let { id, status, progress, currentTaskIndex, taskList } = interfaceStatus[interfaceStatus.length - 1];
  const getProfile = shouldGetProfile(taskList);
  let time;
  yield put(saveCurrentLog(null));
  yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));

  if (currentTaskIndex < 2) {
    let waitingIndex = -1;
    waitingIndex = yield call(workflowWaitingIndex, id);
    // while()
    while (waitingIndex >= 0) {
      // ==> Check whether it is in the current Monitor
      // Get opened monitor verification id
      const { TabMonitorReducer } = yield select();
      // Switch account or switch PDN
      if (TabMonitorReducer.currentVerificationId !== verificationId) {
        // <- update status
        // TODO - If the switch time is less than 3s, it will not return
        // -> update status end
        return;
      }

      time = new Date();
      time.setUTCSeconds(time.getUTCSeconds());
      let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
      const { AndesReducer: { simulationReducer: { waitingQueue } } } = yield select();
      let queue = [...waitingQueue];
      const n = queue.findIndex(item => item.verificationId === verificationId);
      if (n > -1) {
        queue[n].queueIndex = waitingIndex;
        queue[n].waitingTime = waitingTime;
      } else {
        queue.push({
          queueIndex: waitingIndex,
          verificationId,
          waitingTime
        })
      }
      yield put(waitingIndexAction(queue));
      yield delay(5000);
      // update waiting index
      waitingIndex = yield call(workflowWaitingIndex, id);
    };

    if (waitingIndex === -2) {//-2  simulation cancel
      yield put(cleanSingleProgress(verificationId));
      yield put(runningShow(false));
      yield put(cancelShow(true));
      yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
      return;
    }

    if (waitingIndex === -3) {//-3 simulation complete
      yield put(cleanSingleProgress(verificationId));
      yield put(runningShow(false));
      yield put(singleVerifyInfo({ workflowId: null, verificationId: null, }));
      yield put(updateSimulationMsg({ msg: `==> Simulation completed!`, verificationId }));
      return;
    }
  }
  const { AndesReducer: { simulationReducer: { waitingQueue } } } = yield select();
  let queue = [...waitingQueue];
  const n = queue.findIndex(item => item.verificationId === verificationId);
  if (n > -1) {
    queue.splice(n, 1);
  }
  yield put(waitingIndexAction(queue));

  let andesInfo = null, num = 1;
  while (!andesInfo || (andesInfo && Object.keys(andesInfo).length === 0) || (andesInfo.verificationId !== verificationId)) {
    yield delay(500);
    const { AndesReducer: { andes } } = yield select();
    andesInfo = andes.andesInfo || null;
    num += 1;
    if (num === 5) {
      break;
    }
  }
  yield put(singleVerifyInfo({ workflowId: id, verificationId: verificationId }));
  yield put(runningShow(true));
  const { AndesReducer: { simulationReducer: { singleProgress } } } = yield select();
  const currentProgress = singleProgress.find(item => item.verificationId === verificationId);
  if (currentProgress) {
    if (currentProgress.progress >= 0 && currentProgress.progress < 100) {
      progress = currentProgress.progress;
    }
  }
  if (progress === 0) {
    progress += 2;
    yield put(updateProgress({ verificationId, progress }));
  } else {
    yield put(updateProgress({ verificationId, progress }));
  }
  yield put(getSingleMonitor(id, verificationId));
  let indexNum = 0;
  while (status === taskStatus.RUNNING) {
    // ==> Check whether it is in the current Monitor
    // Get opened monitor verification id
    const { TabMonitorReducer } = yield select();
    // Switch account or switch PDN
    if (TabMonitorReducer.currentVerificationId !== verificationId) {
      // <- update status
      // TODO - If the switch time is less than 3s, it will not return
      // -> update status end
      return;
    }
    if (getProfile && indexNum % 6 === 0) {
      yield put(getCurrentProfile(verificationId));
    }
    yield delay(5000);
    const { data: { data } } = yield call(getWorkFlow, id);
    status = data.status;
    indexNum += 1;

    progress = fakeProgress(progress, indexNum, data.progress);
    yield put(updateProgress({ verificationId, progress }));
  }
  yield put(getCurrentProfile(verificationId));
  if (status === taskStatus.CANCEL) {
    yield put(cleanSingleProgress(verificationId));
  } else {
    yield put(updateProgress({ verificationId, progress }));
  }

  yield delay(2000);
  yield put(updateProgress({ verificationId, progress: -1 }));
  yield put(cleanSingleProgress(verificationId));
  yield put(runningShow(false));
  yield put(changeVerificationList([]));

  yield call(updateStatus, { status, verificationId });
}

function* getAndesLog(action) {
  const { verificationId } = action;
  let log = '', promise = null;
  try {
    promise = yield call(checkVerificationStatus, verificationId);
    let existLog = false;
    if (promise && promise.status) {
      if (promise.status !== VERIFY_NEVER) {
        existLog = true;
      }
    };
    if (existLog) {
      let res = yield call(getAndesSimulationLog, verificationId);
      if (res && res.data) {
        log = res.data;
      }
      yield put(getCurrentProfile(verificationId));
    }
  } catch (error) {
    console.error(error);
  }
  yield put(saveCurrentLog(log));
}

function* getProfileLog(action) {
  const { verificationId } = action;
  let profileLog = [];
  try {
    profileLog = yield call(getProfileData, verificationId);
  } catch (error) {
    console.error(error)
  }
  yield put(saveCurrentProfileLog(parseProfile(profileLog)))
}

function* getRunningInterfaceMonitor(action) {
  const { verificationId } = action;
  const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
  if (interfaceStatus) {
    yield put(verificationFlow({ verificationId, interfaceStatus }));
  }
}

function* andesSimulationSaga() {
  yield takeEvery(START_ANDES_VERIFICATION, startVerification);
  yield takeLatest(GET_SINGLE_MONITOR, getMonitorLog);
  yield takeEvery(CANCEL_WORKFLOW, cancelWork);
  yield takeLatest(VERIFICATION_FLOW, verificationWorkFlow);
  yield takeEvery(GET_CURRENT_LOG, getAndesLog);
  yield takeEvery(DEBUG_VERIFY, startDebugVerify);
  yield takeEvery(CANCEL_VERIFICATION_WORKFLOW, cancelVerificationWork);
  yield takeLatest(GET_CURRENT_PROFILE, getProfileLog);
  yield takeLatest(GET_INTERFACE_MONITOR, getRunningInterfaceMonitor)
}

export default andesSimulationSaga;