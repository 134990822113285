import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import {
  GET_IR_EXPLORER_INFO,
  UPDATE_IR_EXPLORER_INFO,
  IR_EXPLORER_SELECT_NETS,
  DELETE_IR_DATA,
  UPDATE_COMP_RLC_PREFIX,
  IGNORE_COMPONENTS,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS,
  SAVE_COMPONENTS,
  UPLOAD_IR,
  CHANGE_TARGET_IC,
  SAVE_VRM_COMP,
  REFRESH_IR,
  CHANGE_PCB,
  SAVE_EXTRACTION
} from './actionType';
import { IR_EXPLORER } from '@/constants/treeConstants';
import { saveIRExplorerInfo, updateLoadingPCB, uploadIRTableloading, updateSelectPowerNets, updatePCB, updateIRComponentSetting, updateExtraction, searchVRMLoading } from './action';
import projectDesigns from '@/services/helper/projectDesigns';
import { getIRDataPromise, updateIRDataPromise, deleteCascadeIRDataPromise, importIRExplorerPromise, saveIrSetting } from '@/services/Cascade/IRExplorer/IRCtrl'
import LayoutData from '@/services/data/LayoutData';
import { _getVRM, getPowerDomain, getExtendedNetByVRM, getPMIC, getNets, CascadeCompPrefixVersion } from '@/services/Cascade/helper/setupData';
import JsonData from '@/services/Cascade/helper/IRJsonData';
import IRTableDatas from '@/services/Cascade/helper/IRData';
import { LoadData } from '@/services/Cascade/helper/IRData';
import { updateDesignStatus, updatePCBLog, updateExpand, updateViewList, updateSelectKeys, updatePageLayout } from '../project/action';
import { getVerificationWorkflow } from '../simulation/action';
import { changeTabMenu, openTabFooter } from '../../../MonitorStore/action';
import { getNominalNumber } from '@/services/Cascade/helper/match';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { versionCompareSize } from '@/services/helper/dataProcess';
import { selectTaskPCB } from '@/services/Cascade/project';
import componentSetting, { compareCompPrefixLib } from '../../../../services/Cascade/helper/compSettingHelper';
import { getPortData } from '@/services/helper/portCanvasHelper';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import compTableHelper from '@/services/Cascade/helper/compTableHelper.js';
import { updateLibraryMenu } from '../library/action';
import { LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT, SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import pinMapStore from '@/services/Cascade/library/pinMapHelper';
import compPinMap from '../../../../services/Cascade/helper/compPinMap';
import designConstructor from '../../../../services/helper/designConstructor';
import { DCExtraction, POWERDC } from '../../../../services/Cascade/helper/setupClass';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import { SPD } from '../../../../constants/designVendor';
import { POWER_SWITCH } from '../../../../constants/componentType';
import { getLibraryDataInfo } from '../../../../services/Cascade/library';

function* _getIRInfo() {
  const { CascadeReducer: { project: { openProjectId }, IR: { verificationId, version } } } = yield select();
  const list = CascadeChannels.getList(IR_EXPLORER, openProjectId);
  const currentItem = list.find(i => i.id === verificationId);
  yield put(changeTabMenu({
    tabSelectKeys: ["monitor"],
    currentVerificationId: verificationId,
    verificationName: currentItem ? currentItem.name : IR_EXPLORER,
    menuType: "simulation"
  }))
  yield put(updatePCBLog([]))
  yield put(updateIRComponentSetting(false));
  let updateCompPrefix = false;
  try {
    yield put(updateSelectPowerNets({ open: false, select: [], allPowerNets: [], allNets: [] }))
    yield put(updateLoadingPCB(true));
    yield put(updateDesignStatus(true))
    let res = yield call(getIRDataPromise, verificationId);
    yield put(getVerificationWorkflow(verificationId));
    let save = false;
    let newInfo = yield call(updateDataByVersion, res);
    if (newInfo.save) {
      res = newInfo.newData;
      save = newInfo.save;
    }
    let designId = res.designId ? res.designId : '', designName = res.designName ? res.designName : '';
    const PCBList = projectDesigns.getAvailablePostLayouts(openProjectId);
    let compPrefixLib = res.compPrefixLib && res.compPrefixLib.version ? { version: res.compPrefixLib.version } : { version: '0.0.0' };
    if (!designId && PCBList.length === 1) {
      designId = PCBList[0].id;
      yield call(selectTaskPCB, { verificationId, designId });
      const comp_version = yield call(componentSetting.getVersion, designId);
      compPrefixLib = { version: comp_version }
    }
    const designExist = projectDesigns.getDesignExist(openProjectId, designId)
    if (designId && designExist) {
      const setting = yield call(componentSetting.getSetting, { designId });
      if (compPrefixLib.version === '0.0.0') {
        const save = compareCompPrefixLib(res.compPrefixLib, setting.compPrefixLib);
        if (save && res.dcIrs && res.dcIrs.length) {
          updateCompPrefix = true;
        }
      } else if (versionCompareSize(compPrefixLib.version, setting.version)) {
        updateCompPrefix = true;
      }
    }

    let _data = new IRTableDatas()
    _data.toIrTableData(res.dcIrs, compPrefixLib)
    if (!designName && designId) {
      designName = designConstructor.getDesignName(designId);
      save = true;
    }
    yield put(saveIRExplorerInfo(_data));
    if (save) {
      yield call(updateIRDataPromise, { ...res, compPrefixLib, version, designName });
    }
    yield put(openTabFooter());

    if (designExist) {
      try {
        yield call(getAuroraDB, designId);
        const setting = yield call(componentSetting.getSetting, { designId });
        if (!setting.init || !Object.keys(setting.init).length || Object.values(setting.init).some(item => item === false)) {
          yield call(componentSetting.getSettingFromBackend, { designId })
          yield put(updateLibraryMenu())
        }
      } catch (error) {
        console.error(error);
      }
    }
    const vendor = designConstructor.getDesignVendor(designId)
    if (!res.config) {
      const extraction = vendor === SPD ? new DCExtraction({ simulator: POWERDC }) : new DCExtraction()
      if (!res.designId && extraction && extraction.clipping && auroraDBJson.isFlexBoard(designId)) {
        extraction.clipping = false
      }
      yield call(saveExtraction, { extraction })
    } else {
      const extraction = vendor === SPD ? new DCExtraction({ ...res.config, simulator: POWERDC }) : new DCExtraction(res.config || {})
      if (!res.designId && extraction && extraction.clipping && auroraDBJson.isFlexBoard(designId)) {
        extraction.clipping = false
      }
      yield put(updateExtraction(extraction))
    }
    yield put(updateIRComponentSetting(updateCompPrefix))

    const { CascadeReducer: { IR: { verificationId: _verificationId } } } = yield select();
    if (verificationId === _verificationId) {
      yield put(updatePCB(designId, designName));
      yield put(updateDesignStatus(false));
    }

    const { irLoadSelect, IRTableData } = _data;
    const targetIC = irLoadSelect.length ? irLoadSelect[0].comp : "";
    if (targetIC && designExist) {
      const setting = yield call(componentSetting.getPrefixLib, designId);
      const { powerNets, allNets } = getPowerDomain({ chip: targetIC, designId, COMP_PREFIX_LIB: setting });
      let selectNets = [];
      IRTableData.forEach(item => {
        selectNets.push(...item.powerNets)
      })
      yield put(updateSelectPowerNets({ select: selectNets, allPowerNets: [...new Set([...powerNets, ...selectNets])], allNets }))
    }

    const { CascadeReducer: { project: { layout, selectedKeys, viewList, expandedKeys } } } = yield select();
    if ([LEFT_RIGHT_lAYOUT, TOP_BOTTOM_LAYOUT].includes(layout)) {
      if (designId && designExist) {
        let _selectedKeys = [...selectedKeys];
        let _viewList = [...viewList]
        if (projectDesigns.getAvailableDesignsLength(openProjectId) > 0) {
          _viewList = _viewList.includes('PCB') ? _viewList : [...viewList, 'PCB'];
          let _expandedKeys = [...expandedKeys];
          _selectedKeys = _selectedKeys.filter(key => !key.includes('PCB'))
          _selectedKeys.push(`PCB-${designId}`);
          let expandedKey = `PCBs-${openProjectId}`;

          if (!_expandedKeys.includes(expandedKey)) {
            _expandedKeys.push(expandedKey)
            yield put(updateExpand(_expandedKeys));
          }
        }
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updateViewList(_viewList));
      } else {
        let _viewList = viewList.filter(item => item !== 'PCB');
        let _selectedKeys = selectedKeys.filter(item => !item.includes('PCB'))
        yield put(updateViewList(_viewList));
        yield put(updateSelectKeys(_selectedKeys));
        yield put(updatePageLayout(SINGLE_PAGE_LAYOUT, 'setup'))
      }
    }

    yield put(updateLoadingPCB(false));
  } catch (error) {
    console.error(error)
  }
}

function* saveIRInfo(data) {
  const { dcIrs, compPrefixLib } = data;
  const { CascadeReducer: { IR: { version, IRExplorerInfo: { irLoadSelect }, designName } } } = yield select();
  let _data = new IRTableDatas()
  _data.toIrTableData(dcIrs, compPrefixLib, irLoadSelect)
  yield put(saveIRExplorerInfo({
    allComponents: _data.allComponents,
    IRTableData: _data.IRTableData,
    version,
    COMP_PREFIX_LIB: _data.COMP_PREFIX_LIB,
    detailMessage: _data.detailMessage,
    designName
  }))
}

function* getAuroraDB(designId) {
  try {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (isPreLayout) {
      yield call([preLayoutData, preLayoutData.getPreLayout], designId);
      return;
    }
    const setting = yield call([componentSetting, componentSetting.getSetting], { designId });
    yield call([auroraDBJson, auroraDBJson.getAuroraJson], designId, setting);
  } catch (e) {
    console.error(e);
  }
}

function getLayoutDB(id, onlyCompLayer) {
  return new Promise((resolve, reject) => {
    try {
      LayoutData.LoadLayoutDB(id, onlyCompLayer).then(res => {
        resolve(true);
      }, error => {
        reject(error);
      })
    } catch (error) {
      reject(error);
    }
  })
}

function* _updateIRData(action) {
  const { data } = action;
  const { CascadeReducer: { IR: { verificationId, version, IRExplorerInfo, designName } } } = yield select();
  const COMP_PREFIX_LIB = IRExplorerInfo.COMP_PREFIX_LIB || new CascadeCompPrefixVersion('0.0.1');
  const irLoadSelect = IRExplorerInfo.irLoadSelect;
  try {
    let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName })
    _data.toJson(data, irLoadSelect)
    const res = yield call(updateIRDataPromise, _data)
    yield call(saveIRInfo, { ...res, irLoadSelect })
  } catch (error) {
    console.error(error);
  }
}

function* _deleteIRData(action) {
  const { irId, updateSelect = true } = action;
  const { CascadeReducer: { IR: { verificationId, IRExplorerInfo: { irLoadSelect, IRTableData }, powerNetSelect: { select: powerNets } } } } = yield select();
  try {
    if (updateSelect) {
      let _powerNets = [...powerNets];
      irId.forEach(id => {
        const _data = IRTableData.find(item => item.id === id);
        if (_data) {
          _powerNets = _powerNets.filter(item => !_data.powerNets.includes(item));
        }
      })
      yield put(updateSelectPowerNets({ select: _powerNets }))
    }
    const res = yield call(deleteCascadeIRDataPromise, { irId, verificationId })
    yield call(saveIRInfo, { ...res, irLoadSelect })
  } catch (error) {
    console.error(error);
  }
}

function* _irSelectNets(action) {
  const { netType, nets, id, deleteNet } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo, verificationId, version, designId, designName } } } = yield select();
  const IRTableData = IRExplorerInfo.IRTableData
  const irLoadSelect = IRExplorerInfo.irLoadSelect;
  const currentData = IRTableData.filter(item => item.id === id);
  let GroundNets = [], PowerNets = [], ExtendNets = [], connectInductance = true, vrm = [];
  if (currentData.length) {
    GroundNets = [...currentData[0].gndNets];
    PowerNets = [...currentData[0].powerNets];
    ExtendNets = [...currentData[0].extendNets];
    vrm = [...currentData[0].vrmComps]
  }


  if (netType === 'gndNets') {
    GroundNets = nets;
  } else if (netType === 'allPowerNet') {
    if (deleteNet) {
      connectInductance = false;
      if (PowerNets.includes(deleteNet)) {
        // delete powerNet
        PowerNets = PowerNets.filter(net => net !== deleteNet)
        ExtendNets = [];
      } else if (ExtendNets.includes(deleteNet)) {
        // delete extendedNet
        ExtendNets = ExtendNets.filter(net => net !== deleteNet);
      }
    } else {
      const allNets = [...PowerNets, ...ExtendNets]
      const newNet = nets.filter(function (n) {
        return allNets.indexOf(n) === -1
      })
      PowerNets = [...PowerNets, ...newNet]
    }
  } else { return }

  if (!PowerNets.length || !GroundNets.length) {
    try {
      const _version = yield call(componentSetting.getVersion, designId);
      let _data = new JsonData({ verificationId, compPrefixLib: { version: _version }, version, designName })
      const jsonData = [{ ...currentData[0], gndNets: GroundNets, powerNets: PowerNets, extendNets: [], components: [], vrmComps: [], detail: [], inductance: {} }]
      _data.toJson(jsonData, irLoadSelect)
      const res = yield call(updateIRDataPromise, _data);
      yield call(saveIRInfo, { ...res, irLoadSelect });
    } catch (error) {
      console.error(error);
    }
    return;
  }
  const setting = yield call(componentSetting.getPrefixLib, designId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
  const table = yield call(compTableHelper.getTableData, designId);
  const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
  const _pinConnection = yield call(compPinMap.getPinConnection, designId);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const pinMap = yield call(getIRPinMap, designId);
  let { extendNets, vrmComps, components, DEBUG_MONITOR, inductance } = yield call(_getVRM, {
    designId,
    GroundNets,
    PowerNets,
    ExtendNets,
    COMP_PREFIX_LIB: setting,
    loadSelect: irLoadSelect,
    connectInductance,
    vrm,
    PMIC: getPMIC(setting),
    powerSwitch: setting.powerSwitch,
    findExtend: true,
    isAC: false,
    buckConverter: setting.discreteBuckConverter || [],
    doNotStuff,
    table,
    pinMapSense,
    pinConnection,
    pinMap
  })
  try {
    const _version = yield call(componentSetting.getVersion, designId);
    let _data = new JsonData({ verificationId, compPrefixLib: { version: _version }, version, designName })
    const jsonData = [{ ...currentData[0], gndNets: GroundNets, powerNets: PowerNets, extendNets, components, vrmComps, detail: DEBUG_MONITOR, inductance }]
    _data.toJson(jsonData, irLoadSelect)
    const res = yield call(updateIRDataPromise, _data);
    yield call(saveIRInfo, { ...res, irLoadSelect })
  } catch (error) {
    console.error(error);
  }
}

function* _updateCompRLCPrefix(action) {
  const { COMP_PREFIX_LIB, update = true } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, IRTableData }, verificationId, version, designName } } } = yield select();
  let debugMonitor = []
  let newIRTableData = [];
  yield put(updateIRComponentSetting(false))
  yield delay(100)

  const res = yield call(getIRDataPromise, verificationId);
  let compPrefixLib = res.compPrefixLib && res.compPrefixLib.version ? { version: res.compPrefixLib.version } : { version: '0.0.0' };
  let _data = new IRTableDatas()
  _data.toIrTableData(res.dcIrs, compPrefixLib)
  yield put(saveIRExplorerInfo(_data));

  if (versionCompareSize(compPrefixLib.version, COMP_PREFIX_LIB.version)) {
    if (update) {
      const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, IRTableData }, designId } } } = yield select();
      const setting = yield call(componentSetting.getPrefixLib, designId);
      const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
      const table = yield call(compTableHelper.getTableData, designId);
      const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
      const _pinConnection = yield call(compPinMap.getPinConnection, designId);
      const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
      const pinMap = yield call(getIRPinMap, designId);
      yield* IRTableData.map(function* (item) {
        const { gndNets, powerNets } = item;
        if (gndNets.length || powerNets.length) {
          let { vrmComps, components, extendNets, DEBUG_MONITOR, inductance } = yield call(_getVRM, {
            designId,
            GroundNets: gndNets,
            PowerNets: powerNets,
            ExtendNets: [],
            COMP_PREFIX_LIB: setting,
            loadSelect: irLoadSelect,
            connectInductance: true,
            PMIC: getPMIC(setting),
            powerSwitch: setting.powerSwitch,
            findExtend: true,
            isAC: false,
            buckConverter: setting.discreteBuckConverter || [],
            doNotStuff,
            table,
            pinMapSense,
            pinConnection,
            pinMap
          })
          debugMonitor = [...debugMonitor, ...DEBUG_MONITOR]
          newIRTableData.push({ ...item, components, vrmComps, extendNets, detail: DEBUG_MONITOR, inductance })
        }
      })
    } else {
      newIRTableData.push(...IRTableData);
    }
  }

  try {
    let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName })
    _data.toJson(newIRTableData, irLoadSelect)
    const res = yield call(updateIRDataPromise, _data);
    yield call(saveIRInfo, { ...res, irLoadSelect })

  } catch (error) {
    console.error(error);
  }
}

function* postIRData(data) {
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect } } } } = yield select();
  try {
    const res = yield call(updateIRDataPromise, data)
    yield call(saveIRInfo, { ...res, irLoadSelect })
  } catch (error) {
    console.error(error);
  }
}

function* ignoreComponents(action) {
  const { components, checked } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, COMP_PREFIX_LIB, IRTableData }, verificationId, version, designName } } } = yield select();
  let _IRTableData = [];
  let _allComponents = [], _allComponentsName = [];
  IRTableData.forEach(item => {
    let changeData = false
    const _components = item.components;
    _components.forEach(comp => {
      if (components.includes(comp.name)) {
        comp.usage = checked ? comp.type : 'Unused';
        changeData = true;
      }
      if (!_allComponentsName.includes(comp.name)) {
        _allComponents.push(comp)
        _allComponentsName.push(comp.name)
      }
    })
    if (changeData) {
      _IRTableData.push(item)
    }
  })

  const info = {
    IRTableData,
    allComponents: _allComponents,
  }
  yield put(saveIRExplorerInfo(info))

  let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName })
  _data.toJson(_IRTableData, irLoadSelect)
  try {
    yield call(updateIRDataPromise, _data)
  } catch (error) {
    console.error(error);
  }
}

function* saveSplitComponents(action) {
  const { part, splitPart, comps } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, COMP_PREFIX_LIB, IRTableData }, verificationId, version, designName } } } = yield select();
  let _IRTableData = [];
  IRTableData.forEach(item => {
    let changeData = false
    const _components = item.components;
    const partList = _components.map(it => it.part);
    let compNames = comps.map(it => it.name)
    if (splitPart && !partList.includes(splitPart)) {
      _components.forEach(comp => {
        if (comp.part === part && compNames.includes(comp.name)) {
          comp.part = splitPart;
          changeData = true;
        }
      })
    } else if (splitPart && partList.includes(splitPart)) {
      let currentSplit = _components.filter(it => it.part === splitPart);
      _components.forEach(comp => {
        if (comp.part === splitPart && compNames.includes(comp.name)) {
          comps.part = splitPart;
          comps.model = currentSplit[0].model;
          comps.value = currentSplit[0].value;
          comps.modelName = currentSplit[0].modelName;
          changeData = true
        }
      })
    }
    if (changeData) {
      _IRTableData.push(item)
    }
  })
  let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName })
  _data.toJson(_IRTableData, irLoadSelect)
  yield call(postIRData, _data)
}

function* saveMergeComponents(action) {
  const { part, partList } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, COMP_PREFIX_LIB, IRTableData }, verificationId, version } } } = yield select();
  let _IRTableData = [], current = [];

  for (let irData of IRTableData) {
    if (irData.components && irData.components.length) {
      const filterComponents = irData.components.filter(item => item.part === part);
      current = [...current, ...filterComponents]
    }
  }

  IRTableData.forEach(item => {
    let changeData = false
    const _components = item.components;
    _components.forEach(comp => {
      if (partList.includes(comp.part) && current.length) {
        comp.part = part;
        comp.value = current[0].value;
        comp.model = current[0].model;
        changeData = true;
      }
    })

    if (changeData) {
      _IRTableData.push(item)
    }
  })
  let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version })
  _data.toJson(_IRTableData, irLoadSelect)
  yield call(postIRData, _data)
}

function* saveComponents(action) {
  const { part, model, value } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, COMP_PREFIX_LIB, IRTableData }, verificationId, version, designName } } } = yield select();
  let _IRTableData = [];
  let _allComponents = [], _allComponentsName = [];
  IRTableData.forEach(item => {
    let changeData = false
    const _components = item.components;
    _components.forEach(comp => {
      if (comp.part === part) {
        comp.model = model;
        comp.value = value;
        changeData = true;
      }
      if (!_allComponentsName.includes(comp.name)) {
        _allComponents.push(comp)
        _allComponentsName.push(comp.name)
      }
    })
    if (changeData) {
      _IRTableData.push(item)
    }
  })

  try {
    const info = {
      IRTableData,
      allComponents: _allComponents,
    }
    yield put(saveIRExplorerInfo(info))
    let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName })
    _data.toJson(_IRTableData, irLoadSelect)
    yield call(updateIRDataPromise, _data)
  } catch (error) {
    console.error(error);
  }
}

function* _uploadIR(action) {
  const { verificationId, file, importType } = action;
  const { CascadeReducer: { IR: { IRExplorerInfo } } } = yield select();
  const irLoadSelect = IRExplorerInfo.irLoadSelect;
  try {
    yield call(importIRExplorerPromise, { verificationId, file, importType });
    const res = yield call(getIRDataPromise, verificationId);
    yield call(saveIRInfo, { ...res, irLoadSelect })

  } catch (error) {
  }
}

function* _changeTargetIC(action) {
  const { value } = action;
  yield put(updateSelectPowerNets({ select: [], allPowerNets: [], allNets: [], open: false }))
  const { CascadeReducer: { IR: { IRExplorerInfo: { COMP_PREFIX_LIB, IRTableData }, verificationId, version, designId, designName } } } = yield select();
  let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName });

  let new_irLoadSelect = [new LoadData(`load_0`, value)];
  // update irLoadSelect
  yield put(saveIRExplorerInfo({ irLoadSelect: new_irLoadSelect }))
  let addTableData = []
  const deleteTableDatas = IRTableData.filter(item => {
    const data = item.loads.filter(item => item.comp !== new_irLoadSelect[0].comp);
    if (!item.loads.length || !item.loads[0].comp) {
      addTableData.push(item)
    } else if (data.length) {
      return item
    }
    return false
  })

  try {
    if (deleteTableDatas.length) {
      // delete load different power domain
      const response = yield call(deleteCascadeIRDataPromise, { irId: deleteTableDatas.map(item => item.id), verificationId })
      let _datas = new IRTableDatas()
      _datas.toIrTableData(response.dcIrs, response.compPrefixLib, new_irLoadSelect)
      yield put(saveIRExplorerInfo({ ..._datas, irLoadSelect: new_irLoadSelect }))
    }
    if (addTableData.length) {
      _data.toJson(addTableData, new_irLoadSelect);
      yield call(updateIRDataPromise, _data);
    }
  } catch (error) {
    console.error(error)
  }
  const setting = yield call(componentSetting.getPrefixLib, designId);
  const { powerNets, allNets } = getPowerDomain({ chip: value, designId, COMP_PREFIX_LIB: setting });
  yield put(updateSelectPowerNets({ select: [], allPowerNets: [...powerNets], allNets, open: value ? true : false }))
}

function* updateVRM() {
  yield put(uploadIRTableloading(true));
  const { CascadeReducer: { IR: { designId, powerNetSelect: { select: powerNets }, IRExplorerInfo: { COMP_PREFIX_LIB, irLoadSelect }, verificationId, version, designName } } } = yield select();
  let debugMonitor = [];
  let _data = new JsonData({ verificationId, compPrefixLib: COMP_PREFIX_LIB, version, designName });
  const targetIC = irLoadSelect.length ? irLoadSelect[0].comp : '';
  const setting = yield call(componentSetting.getPrefixLib, designId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
  const { groundNet } = getPowerDomain({ chip: targetIC, designId, COMP_PREFIX_LIB: setting });
  const table = yield call(compTableHelper.getTableData, designId);
  const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
  const _pinConnection = yield call(compPinMap.getPinConnection, designId);
  const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
  const pinMap = yield call(getIRPinMap, designId);
  for (let powerNet of powerNets) {
    let { extendNets, vrmComps, components, DEBUG_MONITOR, inductance } = yield call(_getVRM, {
      designId,
      GroundNets: [groundNet],
      PowerNets: [powerNet],
      ExtendNets: [],
      COMP_PREFIX_LIB: setting,
      loadSelect: irLoadSelect,
      connectInductance: true,
      findExtend: true,
      isAC: false,
      PMIC: getPMIC(setting),
      powerSwitch: setting.powerSwitch,
      buckConverter: setting.discreteBuckConverter || [],
      doNotStuff,
      table,
      pinMapSense,
      pinConnection,
      pinMap
    })
    debugMonitor = [...debugMonitor, ...DEBUG_MONITOR]
    const nominal = getNominalNumber(powerNet, extendNets)
    _data.toJson([{ gndNets: [groundNet], powerNets: [powerNet], extendNets, components, vrmComps, detail: DEBUG_MONITOR, inductance, nominal }], irLoadSelect);
    try {
      // upload power domain
      const res = yield call(updateIRDataPromise, _data);
      let _datas = new IRTableDatas()
      _datas.toIrTableData(res.dcIrs, res.compPrefixLib, irLoadSelect)
      yield put(saveIRExplorerInfo({ ..._datas, irLoadSelect: irLoadSelect }))
    } catch (error) {
      console.error(error)
    }
  }
  yield put(uploadIRTableloading(false))
}

function* saveComponentByVRM(action) {
  const { vrm, row } = action;
  let newData = { ...row };

  const { powerNets, gndNets, extendNets: prevExtendNets = [] } = newData
  const { CascadeReducer: { IR: { designId, IRExplorerInfo: { irLoadSelect } } } } = yield select();
  const setting = yield call(componentSetting.getPrefixLib, designId);
  const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, designId);
  const table = yield call(compTableHelper.getTableData, designId);
  const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
  const pinConnection = yield call(compPinMap.getPinConnection, designId);
  const pinMap = yield call(getIRPinMap, designId);

  let { extendNets, vrmComps, components, DEBUG_MONITOR, inductance } = yield call(_getVRM, {
    designId,
    GroundNets: [...gndNets],
    PowerNets: [...powerNets],
    ExtendNets: [],
    COMP_PREFIX_LIB: setting,
    loadSelect: irLoadSelect,
    connectInductance: true,
    findExtend: true,
    isAC: false,
    PMIC: [],
    powerSwitch: setting.powerSwitch,
    buckConverter: setting.discreteBuckConverter || [],
    doNotStuff,
    table,
    pinMapSense,
    pinConnection,
    pinMap,
    specify: [{ name: vrm }],
    fuzzyMatch: true
  })
  const _extendNets = extendNets.filter(net => !prevExtendNets.includes(net));
  newData.extendNets && newData.extendNets.push(..._extendNets);
  newData.allPowerNet && newData.allPowerNet.push(..._extendNets);
  newData.detail && newData.detail.push(...DEBUG_MONITOR);
  newData.vrmComps = [...new Set([...newData.vrmComps, ...vrmComps])];
  newData.inductance = { ...newData.inductance || {}, ...inductance }
  const existComponents = newData.components.map(item => item.name)
  const _components = components.filter(comp => !existComponents.includes(comp.name));
  newData.components.push(..._components);
  yield call(_updateIRData, { data: [newData] });
  yield delay(50);
  yield put(searchVRMLoading(true))
}

function* refreshIR(action) {
  const { CascadeReducer: { IR: { IRExplorerInfo: { irLoadSelect, IRTableData } } } } = yield select();
  const { selectNets } = action;
  const value = irLoadSelect && irLoadSelect.length ? irLoadSelect[0].comp : '';
  if (value) {
    const irId = IRTableData.map(item => item.id);
    if (irId.length) {
      yield call(_deleteIRData, { irId, updateSelect: false })
    }
    yield put(updateSelectPowerNets({ select: selectNets, open: false }))
    yield call(updateVRM)
  }
}

function* updateDataByVersion(res) {
  let newData = { ...res }, save = false;
  const { CascadeReducer: { project: { openProjectId } } } = yield select();
  let version = res.version || '0.0.1';
  if (versionCompareSize(version, '0.0.2')) {
    if (!res.designId) {
      const designId = projectDesigns.getAvailablePCBsFirstId(openProjectId);
      newData.designId = designId
      yield call(selectTaskPCB, { verificationId: newData.verificationId, designId })
    }
    save = true
  }

  if (versionCompareSize(version, '0.0.3')) {
    if (newData.designId) {
      let newDCIrs = [];
      for (let ir of newData.dcIrs) {
        let newContent = { ...ir.content };
        const designExist = projectDesigns.getDesignExist(openProjectId, newData.designId)
        const { loads, powerNets, gndNets } = newContent;
        const current = loads[0].current;
        if (current) {
          if (designExist) {
            try {
              yield call(getLayoutDB, newData.designId, false);
            } catch (error) {
              console.error(error);
            }
          }
          const DesignData = LayoutData.getLayout(newData.designId);
          const filterData = getPortData(DesignData, loads[0].comp, powerNets, gndNets);
          const { powerPins, referencePins } = filterData;
          const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
          const reference = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
          newContent.loads[0].current = [{
            powerPins: power,
            referencePins: reference,
            port: "1",
            current
          }]
        } else {
          newContent.loads[0].current = [];
        }
        newDCIrs.push({ ...ir, content: newContent });
      }
      newData.dcIrs = newDCIrs;
      save = true
    }
  }

  if (versionCompareSize(version, '0.0.4')) {
    if (newData.designId) {
      yield call(getAuroraDB, newData.designId);
      let newIrs = []
      for (let ir of newData.dcIrs) {
        let newContent = { ...ir.content };
        if (!newContent.vrmComps || !newContent.vrmComps.length) {
          newIrs.push({ ...ir, content: newContent });
          continue;
        }
        try {
          let inductance = {}
          newContent.vrmComps.forEach(vrm => {
            const _inductance = newContent.detail.filter(d => d[d.length - 1].name === vrm).map(d => {
              const vrm = d[d.length - 1].name, net = d[d.length - 2].name;
              const netInfo = auroraDBJson.getNet(newData.designId, net);
              const powerPins = netInfo.pins.get(vrm);
              const ground = newContent.gndNets && newContent.gndNets.length ? newContent.gndNets[0] : ''
              const gndInfo = auroraDBJson.getNet(newData.designId, ground)
              const groundPins = ground ? gndInfo.pins.get(vrm) : []
              return { powerPins, groundPins, powerComp: vrm, groundComp: vrm, vrmComp: vrm }
            });
            inductance[vrm] = _inductance
          })
          newIrs.push({ ...ir, content: { ...newContent, inductance } });
        } catch (e) {
          console.error(e);
          newIrs.push({ ...ir, content: newContent });
          continue;
        }
      }
      newData.dcIrs = newIrs;
      save = true
    }
  }

  return { newData, save };
}

function* changeIrPCB(action) {
  const { pcbId } = action;
  const { CascadeReducer: { IR: { designId, verificationId, version, extraction, IRExplorerInfo: { irLoadSelect, IRTableData } } } } = yield select();
  yield put(updateLoadingPCB(true));
  yield put(uploadIRTableloading(true));
  yield put(updateDesignStatus(true));
  let pcbLog = [], designName = "";
  try {
    yield call(selectTaskPCB, { verificationId, designId: pcbId })
    if (pcbId) {
      yield call(getAuroraDB, pcbId);
      if (extraction && extraction.clipping && auroraDBJson.isFlexBoard(pcbId)) {
        yield call(saveExtraction, { ...extraction, clipping: false })
      }
    }
    const targetIC = irLoadSelect.length ? irLoadSelect[0].comp : '';
    let _COMP_PREFIX_LIB = { version: '0.0.1' };
    if (pcbId) {
      const _version = yield call(componentSetting.getVersion, pcbId);
      _COMP_PREFIX_LIB = { version: _version };
      designName = designConstructor.getDesignName(pcbId);
      yield put(saveIRExplorerInfo({ COMP_PREFIX_LIB: _COMP_PREFIX_LIB }))
    }
    if (designId && designId !== pcbId) {
      const setting = yield call(componentSetting.getPrefixLib, pcbId);
      const doNotStuff = yield call(componentDoNotStuff.getDoNotStuff, pcbId);
      // update COMP_PREFIX_LIB
      const netsInfo = getNets(pcbId);
      if (!netsInfo || !netsInfo.loadComponents || !netsInfo.loadComponents.map(comp => comp.name).includes(targetIC)) {
        targetIC && pcbLog.push("[Update] TargetIC does not exist.")
        yield call(updateIRDataPromise, { verificationId, compPrefixLib: _COMP_PREFIX_LIB, version, dcIrs: [], designName });
        yield call(_changeTargetIC, { value: "" })
      } else {
        const { powerNets, groundNet, allNets } = getPowerDomain({ chip: targetIC, designId: pcbId, COMP_PREFIX_LIB: setting });
        let deleteTableDatas = [], select = [];
        IRTableData.forEach(data => {
          if (!data.powerNets.every(item => netsInfo.nets.includes(item))) {
            pcbLog.push(`[Update][Power Domains] ${data.powerNets.join(',')} does not exist.`)
            deleteTableDatas.push(data.id)
          } else if (!data.gndNets.includes(groundNet)) {
            pcbLog.push(`[Update][Power Domains] ${data.gndNets.join(',')} does not exist.`)
            deleteTableDatas.push(data.id)
          } else {
            select.push(...data.powerNets)
          }
        })

        const newIRTableData = IRTableData.filter(item => !deleteTableDatas.includes(item.id))

        const response = yield call(deleteCascadeIRDataPromise, { irId: IRTableData.map(item => item.id), verificationId })
        let _datas = new IRTableDatas()
        _datas.toIrTableData(response.dcIrs, response.compPrefixLib, irLoadSelect)
        yield put(saveIRExplorerInfo({ ..._datas, irLoadSelect }))

        let _data = new JsonData({ verificationId, compPrefixLib: _COMP_PREFIX_LIB, version, designName });
        let debugMonitor = [], addComp = [], deleteComp = [];
        const table = yield call(compTableHelper.getTableData, designId);
        const pinMapSense = yield call([pinMapStore, pinMapStore.getPinMapSense], designId, "pinName");
        const _pinConnection = yield call(compPinMap.getPinConnection, designId);
        const pinConnection = yield call(getPowerSwitchPinMap, _pinConnection);
        const pinMap = yield call(getIRPinMap, designId);
        for (let row of newIRTableData) {
          const { powerNets, gndNets, components: _components, load_0, maxBgaI, nominal, maxVia, maxBgaDv } = row;
          const values = { maxBgaI, nominal, maxVia, maxBgaDv, load_0 };
          let { extendNets, vrmComps, components, DEBUG_MONITOR, inductance } = yield call(_getVRM, {
            designId: pcbId,                         
            GroundNets: [...gndNets],
            PowerNets: [...powerNets],
            ExtendNets: [],
            COMP_PREFIX_LIB: setting,
            loadSelect: irLoadSelect,
            connectInductance: true,
            findExtend: true,
            isAC: false,
            PMIC: getPMIC(setting),
            powerSwitch: setting.powerSwitch,
            buckConverter: setting.discreteBuckConverter || [],
            doNotStuff,
            table,
            pinMapSense,
            pinConnection,
            pinMap
          })
          debugMonitor = [...debugMonitor, ...DEBUG_MONITOR]
          components.forEach(comp => {
            const _comp = _components.find(item => item.name === comp.name);
            if (_comp) {
              comp.usage = _comp.usage;
              comp.value = _comp.value;
              comp.model = _comp.model
            }
          })
          addComp = components.filter(item => !_components.find(c => c.name === item.name)).map(item => item.name);
          deleteComp = _components.filter(item => !components.find(c => c.name === item.name)).map(item => item.name);

          _data.toJson([{ gndNets: [...gndNets], powerNets: [...powerNets], extendNets, components, vrmComps, detail: DEBUG_MONITOR, inductance, ...values }], irLoadSelect);
          try {
            // upload power domain
            const res = yield call(updateIRDataPromise, _data);
            let _datas = new IRTableDatas()
            _datas.toIrTableData(res.dcIrs, res.compPrefixLib, irLoadSelect)
            yield put(saveIRExplorerInfo({ ..._datas, irLoadSelect: irLoadSelect }))
          } catch (error) {
            console.error(error)
          }
        }
        addComp = [...new Set(addComp)]
        deleteComp = [...new Set(deleteComp)]
        if (addComp.length) {
          pcbLog.push(`[Update][Components] ${addComp.join(',')} ${addComp.length > 1 ? 'have' : 'has'} been added.`)
        }
        if (deleteComp.length) {
          pcbLog.push(`[Update][Components] ${deleteComp.join(',')} ${deleteComp.length > 1 ? 'have' : 'has'} been removed.`)
        }
        yield put(updateSelectPowerNets({ select, allPowerNets: [...new Set([...powerNets, ...select])], allNets, open: false }))
      }
    } else {
      let _data = new JsonData({ verificationId, compPrefixLib: _COMP_PREFIX_LIB, version, designName });
      try {
        // upload power domain
        const res = yield call(updateIRDataPromise, _data);
        let _datas = new IRTableDatas()
        _datas.toIrTableData(res.dcIrs, res.compPrefixLib, irLoadSelect)
        yield put(saveIRExplorerInfo({ ..._datas, irLoadSelect: irLoadSelect }))
      } catch (error) {
        console.error(error)
      }
    }
    yield put(updatePCB(pcbId, designName))
  } catch (error) {
    console.error(error)
  }
  if (pcbLog.length) {
    yield put(openTabFooter())
  }
  yield put(updatePCBLog(pcbLog))
  yield put(updateDesignStatus(false))
  yield put(updateLoadingPCB(false));
  yield put(uploadIRTableloading(false));

}

function* saveExtraction(action) {
  const { extraction } = action;
  const config = new DCExtraction(extraction);
  const { CascadeReducer: { IR: { verificationId } } } = yield select();
  try {
    yield call(saveIrSetting, { verificationId, setup: { config } });
    yield put(updateExtraction(config))
  } catch (error) {
    console.error(error)
  }
}

function* getIRPinMap(pcbId) {
  let pinMap = yield call(compPinMap.getPinMapData, pcbId);

  let pinMapList = []
  yield* pinMap.map(function* (_map) {
    let data = [];
    if (_map.libraryId) {
      try {
        const res = yield call([pinMapStore, pinMapStore.getPinMapLibrary], _map, pcbId, "pinName")
        data = res && res.config && res.config.pinTable ? [...res.config.pinTable] : [];
      } catch (error) {
        console.error("Can not find library file");
      }
    }
    pinMapList.push({ ..._map, data })
  })
  return pinMapList
}

function* getPowerSwitchPinMap(pinConnection) {
  let _pinConnection = [];
  yield* pinConnection.map(function* (connection) {
    if (connection.type === POWER_SWITCH && connection.libraryId) {
      try {
        const libraryData = yield call(getLibraryDataInfo, connection.libraryId);
        const { config = {} } = libraryData;
        const { pinTable = [] } = config;
        _pinConnection.push({ ...connection, pinMap: pinTable })
      } catch (e) {
        _pinConnection.push(connection)
      }
    } else {
      _pinConnection.push(connection)
    }
  })
  return _pinConnection;
}

function* IRSage() {
  yield takeEvery(GET_IR_EXPLORER_INFO, _getIRInfo);
  yield takeEvery(UPDATE_IR_EXPLORER_INFO, _updateIRData);
  yield takeEvery(IR_EXPLORER_SELECT_NETS, _irSelectNets);
  yield takeEvery(DELETE_IR_DATA, _deleteIRData);
  yield takeEvery(UPDATE_COMP_RLC_PREFIX, _updateCompRLCPrefix);
  yield takeEvery(IGNORE_COMPONENTS, ignoreComponents);
  yield takeEvery(SAVE_SPLIT_COMPONENTS, saveSplitComponents);
  yield takeEvery(SAVE_MERGE_COMPONENTS, saveMergeComponents);
  yield takeEvery(SAVE_COMPONENTS, saveComponents);
  yield takeEvery(UPLOAD_IR, _uploadIR);
  yield takeEvery(CHANGE_TARGET_IC, _changeTargetIC);
  yield takeEvery(SAVE_VRM_COMP, saveComponentByVRM);
  yield takeEvery(REFRESH_IR, refreshIR);
  yield takeEvery(CHANGE_PCB, changeIrPCB);
  yield takeEvery(SAVE_EXTRACTION, saveExtraction)
}

export default IRSage