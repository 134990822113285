import { getNoteDetail, saveNote } from '../../api/v2/projectCtrl';
import apiProcess from '../../api/utility';
class RichTextEditor {
  constructor() {
    this.textData = new Map(); // key - product-id(sierra-verificationId), value - text 
  }

  getKey = (product, type, id) => {
    return `${product}-${type}-${id}`;
  }

  getData(product, type, id) {
    return new Promise((resolve, reject) => {
      const key = this.getKey(product, type, id);
      const data = this.textData.get(key);
      if (data === undefined) {
        apiProcess(getNoteDetail, { product, type, id }).then(res => {
          this.textData.set(key, res ? res : '');
          resolve(res);
        })
      } else {
        resolve(data);
      }
    })
  }

  getHtml(product, type, id) {
    const key = this.getKey(product, type, id);
    return this.textData.get(key);
  }

  save({ product, type, id, data }) {
    const key = this.getKey(product, type, id);
    this.textData.set(key, data);
    apiProcess(saveNote, { product: product, id, type, note: data })
  }

}

const richTextData = new RichTextEditor();

export default richTextData;