import React, { Component, Fragment, createRef } from 'react';
import { Divider } from 'antd';
import { RdieCdieBarCanvas } from '@/services/Cascade/result';
import { unitChange } from '../../../../../services/helper/mathHelper';

class RdieCdieCanvas extends Component {
  constructor(props) {
    super(props);
    this.rdieRef = createRef();
    this.cdieRef = createRef();
  }

  componentDidMount() {
    this.screenChange();
    this.initCanvas();
  }

  componentDidUpdate(prevProps) {
    const { color, loading, redraw } = this.props;
    if (color.length !== prevProps.color || (!loading && loading !== prevProps.loading)) {
      this.initBarColor()
    }

    if (redraw !== prevProps.redraw) {
      this.cdieCanvas && this.cdieCanvas.draw();
      this.rdieCanvas && this.rdieCanvas.draw();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    setTimeout(() => {
      this.drawBarChart()
    }, 50)
  }

  initCanvas = () => {
    const { targetDie } = this.props
    const title = `Ports`
    const rdieCanvas = new RdieCdieBarCanvas({
      svgEle: this.rdieRef.current,
      title,
      yTitle: 'Rdie (Ω)',
      key: 'rdie',
      die: targetDie
    });
    this.rdieCanvas = rdieCanvas;

    const cdieCanvas = new RdieCdieBarCanvas({
      svgEle: this.cdieRef.current,
      title,
      yTitle: 'Cdie (pF)',
      key: 'cdie',
      die: targetDie
    });
    this.cdieCanvas = cdieCanvas;

    this.initBarColor();
  }

  initBarColor = () => {
    const { color } = this.props;
    this.rdieCanvas.setColor(color);
    this.cdieCanvas.setColor(color);
    this.drawBarChart();
  }

  drawBarChart = () => {
    const { rdieValue, cdieValue } = this.props;
    this.rdieCanvas.setData(Number(rdieValue));
    this.rdieCanvas.draw();

    const newCdieValue = unitChange({ num: cdieValue, oldUnit: 'F', newUnit: 'pF' }).number;
    this.cdieCanvas.setData(newCdieValue);
    this.cdieCanvas.draw();
  }

  render() {
    return (
      <Fragment>
        <div className=" cascade-result-effect-canvas">
          <div className="canvas-content">
            <svg ref={this.rdieRef} style={{ height: 500, width: '100%', overflow: 'visible' }}></svg>
          </div>
          <Divider type="vertical" className="cascade-result-effect-divider" />
          <div className="canvas-content">
            <svg ref={this.cdieRef} style={{ height: 500, width: '100%', overflow: 'visible' }}></svg>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default RdieCdieCanvas