import http from '../http';

/**
 * Do simulation by verification id
 * 2019.11.19
 *
 * @export
 * @param {string} verificationId verification id
 * @returns XMLHttpRequest 
 */
export function doSimulation(verificationIds) {
  return http.post(`/project/verification/simulation/sierra/v2`, [{ verificationIds }]);
}

/**
 * Get log sierra 
 * 2019.12/05
 * @param {string} verificationId
 */
export function getSierraLog(verificationId) {
  return http.get(`/project/verification/${verificationId}/sierra/download/log`);
}

/**
 * Get Project Info (1:ready 3:copying)
 * 2021/1/22
 * @param {string} projectId
*/
export function getProjectInfo(projectId) {
  return http.get(`/sierra/project/${projectId}`);
}

/**
 * Get file sierra PinToPin project 
 * 2019.11/19
 * @param {string} projectId
 * @param {String} path
 */
export function getFileInSierraPinToPinProject(projectId, path) {
  return http.get(`/project/${projectId}/PinToPin/file?path=${path}`);
}

/**
 * upload debug file sierra nevada
 * 2019.11/29
 *
 * @export
 * @param {String} verificationId verificationId
 * @param {file} file debug file
 * @returns XMLHttpRequest
 */
export function UploadSierraDebugFile(file, verificationId, step, UploadProgress) {
  let formData = new FormData();
  formData.append('file', file);
  return http.post(`/project/verification/${verificationId}/sierra/upload/${step}`, formData, UploadProgress, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * debug  simulation sierra nevada
 * 2019.11/29
 *
 * @export
 * @param {string} step  /1(setup)/2(extraction)/3(simulation)
 * @param {string} verificationId verificationId 
 * @returns XMLHttpRequest
 */
export function debugSierraVerify(step, verificationId) {
  return http.post(`/project/verification/${verificationId}/sierra/verify/${step}`);
};

/**
 * 
 * Get sierra sparamter files extraction by SIWave or HFSS
 * 
 * @param {String} verificationId verificationId
 * @export 
 */
export function getSierraTouchstoneFile(verificationId) {
  return http.get(`/project/verification/${verificationId}/sierra/snpFileList`);
};

/**
 * Get sierra touchstone curve
 *
 * @export
 * @param {*} verificationSubId verification subId
 * @param {*} fileName file name: pcb_pcbId_verificationId.s*p
 * @param {String} path pdn.sam  pdn.sph  pdn.yam  pdn.yph pdn.zam  pdn.zph
 * @param {Array} cols
 * @param {Array} rows
 * @returns
 */
export function getSierraTouchStoneCurve({ verificationId, fileName, cols, rows, path }) {
  return http.post(`/project/verification/${verificationId}/PinToPin/${fileName}/data`, {
    cols,
    path,
    rows
  });
};

/**
 * Get sierra eyeDiagrams workflow
 * 2019/12/31
 * 
 * @export
 * @param {*} verificationId verification id
 * @param {*} clocks
 * @param {*} signals
 * @returns XMLHttpRequest
 */
export function eyeDiagrams({ verificationId, clocks, signals }) {
  return http.post(`/project/verification/${verificationId}/download/eyeDiagram`, {
    clks: clocks,
    signals: signals
  }, {
    responseType: 'arraybuffer'
  });
}

/**
 * If do extraction 
 *
 * 2020/02/06
 * @param interfaceId
 * @param ifDoExtraction 0/1  1:do   0:false
 * @export
 * @returns Promise
 */
export function saveReExtraction(interfaceId, ifDoExtraction) {
  return http.post(`/project/interface/${interfaceId}/reExtraction/sierra?ifDoExtraction=${ifDoExtraction}`);
};

/**
 * Change sierra interface name
 *
 * 2020/02/11
 * @param verificationId
 * @param verificationName 
 * @export
 * @returns Promise
 */
export function changeSierraInterfaceName(verificationId, name, tag) {
  if (!tag) {
    return http.put(`/project/verification/${verificationId}/rename/sierra?verificationName=${name}`);
  }
  return http.put(`/project/verification/${verificationId}/rename/sierra?verificationName=${name}&tag=${tag}`);
};

/**
 * Remove exsit results
 * 
 * 2020/02/19
 *
 * @export
 * @param {String} verificationId verification id
 * @returns
 */
export function removeExsitResult(verificationId) {
  return http.delete(`/project/verification/${verificationId}/sierra/deleteResult`);
};

/**
 * Upload TR0 file
 *
 * @export
 * @param {*} file
 * @param {*} verificationId
 * @param {*} UploadProgress
 * @returns
 */
export function uploadTR0File(file, verificationId, UploadProgress) {
  let formData = new FormData();
  formData.append('file', file);
  return http.post(`/project/verification/${verificationId}/Hspice/tr0Parse`,
    formData, UploadProgress, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * get eye diagram config
 *
 * @export
 * @param {*string} verificationId
 * @returns
 */
export function getSierraEyeDiagramConfig(verificationId) {
  return http.get(`/sierra/eye-diagram/config?verificationId=${verificationId}`);
}

/**
 * save eye diagram config
 *
 * @export
 * @param {*string} verificationId
 * @param {*object} eyeDiagramConfig
 * @returns
 */
export function saveSierraEyeDiagramConfig(verificationId, eyeDiagramConfig) {
  return http.post(`/sierra/eye-diagram/config`, {
    eyeDiagramConfig,
    verificationId
  });
}

/**
 * generate eye diagram
 *
 * @export
 * @param {*string} verificationId
 * @param {*object} eyeDiagramConfig
 * @returns
 */
export function generateSierraEyeDiagram({ verificationId, eyeDiagramConfig }) {
  return http.post(`/sierra/eye-diagram/generate`, {
    eyeDiagramConfig,
    verificationId
  }/* , {
    responseType: 'arraybuffer'
  } */);
}

/**
 * get generate eye diagram log message
 *
 * @export
 * @param {*string} verificationId
 * @returns
 */
export function getSierraDownloadEyeGenerateLog(verificationId) {
  return http.get(`/sierra/eye-diagram/log?verificationId=${verificationId}`);
}

/**
 * generate eye diagram
 *
 * @export
 * @param {*string} verificationId
 * @param {*object} eyeDiagramConfig
 * @returns
 */
export function getSierraEyeDiagramImg(verificationId) {
  return http.get(`/sierra/eye-diagram/result?verificationId=${verificationId}`, {
    responseType: 'arraybuffer'
  });
}

/**
 * get debug list
 */

export function getDebugList(projectId) {
  return http.get(`/sierra/project/structure?projectId=${projectId}`)
}

/**
 * get WaveForm Post Process config
 */
export function getSierraPostProcessConfig({ verificationId }) {
  return http.get(`/sierra/verification/${verificationId}/simulation/waveform`)
}

/**
 * get WaveForm Post Process edges config
 */
export function getSierraPostProcessEdgesInfo({ verificationId }) {
  return http.get(`/sierra/verification/${verificationId}/simulation/edges`)
}

export function getSierraPostProcessJsonConfig({ verificationId, modelType }) {
  if (modelType) {
    return http.get(`/sierra/verification/${verificationId}/simulation/pproc-config?modelType=${modelType}`)
  }
  return http.get(`/sierra/verification/${verificationId}/simulation/pproc-config`)
}

export function saveSierraPostProcessJsonConfig({ verificationId, config }) {
  return http.post(`/sierra/verification/${verificationId}/simulation/pproc-config`, {
    ...config
  })
}

/**
 * Get end to end result ports info 
 * 
 * endToEndList: [{
 *    "driver": {component,pin, pcb},
 *    "receiver": {component,pin, pcb},
 *    "signal":"signalName"
 *  }]
 * @export
 * @param {*} verificationId verification id
 * @returns Promise
 */
export function getSierraEndToEndResultList(verificationId) {
  return http.get(`/project/verification/${verificationId}/sierra/endToEndList`)
}


/**
 * Get sierra end to end touchstone curve
 *
 * @export
 * @param {*} verificationSubId verification subId
 * @param {*} folderName name: driverPcb_driverComponent_driverPin_receiverPcb_receiverComponent_receiverPin
 * @param {String} path end-to-end.sam
 * @param {Array} cols
 * @param {Array} rows
 * @returns
 */
export function getSierraEndToEndSparameterCurve({ verificationId, folderName, cols, rows, path }) {
  return http.post(`/project/verification/${verificationId}/sierra/endToEnd/${folderName}/data`, {
    cols,
    path,
    rows
  });
};

export function getProjectSearchConfig(projectId) {
  return http.get(`/sierra/project/config?projectId=${projectId}`)
}

/* {
  "projectId": "string",
  "searchApplyAll": true/false
} */
export function updateProjectSearchConfig({ projectId, config }) {
  return http.post(`/sierra/project/config`, {
    projectId,
    ...config
  })
}

/** 
 * Get total trace capacitance 
 * 20240708
 * @param {string} verificationId
 * @param {string} designId
 * **/
export function getTotalTraceCapacitanceResult({ verificationId, pcbId }) {
  return http.get(`/sierra/verification/${verificationId}/capacitance?pcbId=${pcbId}`)
}

/** 
 * generate total trace capacitance 
 * 20240723
 * @param {string} verificationId
 * @param {string} frequency
 * **/
export function reCalcTotalCapacitanceByFreq({ verificationId, frequency }) {
  return http.post(`/sierra/verification/${verificationId}/capacitance?frequency=${frequency}`)
}

/** 
 * get total trace capacitance csv
 * 20240723
 * @param {string} verificationId
 * @param {string} fileName "xxx.csv"
 * **/
export function getTotalTraceCapacitanceCSVFile({ verificationId, fileName }) {
  return http.get(`/sierra/verification/${verificationId}/capacitance/${fileName}`)
}


/** 
 * get setup verification measurement config
 * 20240914
 * @param {string} verificationId
 * @param {string} modelType Open-drain / Push-pull
 * **/
export function getSetupMeasurementConfig({ verificationId, modelType }) {
  if (modelType) {
    return http.get(`/sierra/verification/${verificationId}/setup/pproc-config?modelType=${modelType}`)
  } else {
    return http.get(`/sierra/verification/${verificationId}/setup/pproc-config`)
  }
}

/** 
 * update setup verification measurement config
 * 20240914
 * @param {string} verificationId
 * @param {object} config 
 * **/
export function updateSetupMeasurementConfig({ verificationId, config }) {
  return http.post(`/sierra/verification/${verificationId}/setup/pproc-config`, config)
}

/**
 * get report config
 * 2024/09/02
 * @param {String} projectId
 *  */
export function getSierraReportConfig(projectId) {
  return http.get(`sierra/${projectId}/report/config`);
}

/** 
 * save report config
 * 2024/09/02
 * @param {String} projectId
 * @param {Object} config
 */
export function saveSierraReportConfig({ projectId, config }) {
  return http.post(`sierra/${projectId}/report/config`, config)
}


/**
 * Generate sierra report
 * 2024/09/02
 * @export
 * @param {*} param
 * @returns
 */
export function generateSierraReport({ projectId, param }) {
  return http.post(`sierra/${projectId}/report`, param);
}

/**
 * Get sierra report status
 * 2024/09/02
 * @export
 * @param {string} projectId
 * @returns
 */
export function getSierraReportStatus(projectId) {
  return http.get(`sierra/${projectId}/report/status`);
}

/**
 * get project report, response: file
 * 2024/09/02
 * @export
 * @param  {string} projectId
 * @returns 
 */
export function getSierraReportFile({ projectId }) {
  return http.get(`sierra/${projectId}/report`);
}

/**
 * Get sierra report log message
 * 2024/09/24
 * @export
 * @param {string} projectId
 * @returns
 */
export function getSierraReportLog(projectId) {
  return http.get(`sierra/${projectId}/report/runningLogs`);
}

/** 
 * Get result display setting 
 * 20240929
 * @param {string} verificationId
 * **/
export function getSierraResultDisplaySettings(verificationId) {
  return http.get(`/verification/${verificationId}/result-settings`);
}

/** 
 * Get result display setting 
 * 20240929
 * @param {string} verificationId
 * @param {object} setting {waveform:{displayMode}, sParameter}
 * **/
export function saveSierraResultDisplaySettings({ verificationId, setting }) {
  return http.post(`/verification/${verificationId}/result-settings`, setting);
}