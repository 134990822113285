import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '../Panel';
import { getPanelMaxWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { getTextWidth } from '../../services/helper/getTextWidth';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { downloadFile } from '../../services/helper/downloadHelper';
import './index.css';

class MsgDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: null,
      maxHeight: null,
      lockScroll: false
    }
    this.lockRef = createRef();
    this.dialogRoot = document.getElementById(props.root || 'root');
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.scrollToBottom();
  }

  componentDidUpdate = (prevProps) => {
    const { messageList } = this.props;
    const { lockScroll } = this.state;
    if (prevProps.messageList.length !== messageList.length) {
      this.resize();
      this.hasLockScroll()
    }
    if (this.scrollRef && !lockScroll) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { root } = this.props;
    const maxHeight = root ? getPanelMaxHeight(offset) - 50 : getPanelMaxHeight(offset) - 200;

    this.setState({
      maxHeight: maxHeight < 100 ? 100 : maxHeight,
      maxWidth: getPanelMaxWidth(offset)
    })
  }

  hasLockScroll = () => {
    if (this.lockRef) {
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5) { this.setState({ lockScroll: false }); }
      else { this.setState({ lockScroll: true }); }
    }
  }

  clickOkButton = () => {
    this.props.clickOkButton && this.props.clickOkButton()
  }

  clickDownloadButton = async () => {
    const { messageList, isGetDownloadMsg, getDownloadLog, downloadParams } = this.props;
    const filename = this.props.fileName || "message.log";
    let log = messageList.join("\n");
    if (isGetDownloadMsg && getDownloadLog) {
      try {
        let _log = await getDownloadLog({ ...downloadParams });
        if (_log && typeof _log === "string") {
          log = _log;
        }
      } catch (error) {
        console.error(error);
      }
    }
    downloadFile(log, filename)
  }

  getFooter = () => {
    const { allowButton, allowDownload, type } = this.props;
    if (!allowButton) {
      return null;
    }
    if (type === "log") {
      return <div className='aurora-msg-footer-main'>
        <Button type="link" onClick={this.clickOkButton}>Close</Button>
        {allowDownload ? <Button
          type="link"
          onClick={this.clickDownloadButton}
          className='aurora-msg-dialog-download-button'
        >Download</Button> : null}
      </div>
    }
    return <div className='aurora-msg-footer-main'>
      <Button onClick={this.clickOkButton}>OK</Button>
      {allowDownload ? <Button
        onClick={this.clickDownloadButton}
        className='aurora-msg-dialog-download-button'
      >Download</Button> : null}
    </div>
  }

  render = () => {
    const { maxWidth, maxHeight } = this.state;
    const { messageList, title, maskStyle, mask, type = "msg", showChildren, className = "", position } = this.props;
    const maxMessages = showChildren ? messageList[0].children || [] : [...messageList].sort((a, b) => { return b.length - a.length });
    let width = getTextWidth(maxMessages[0], "14px") + 90;
    width = width < 300 ? 300 : width;
    const content = (
      <Panel
        className={maskStyle ? `aurora-msg-dialog-mask-panel aurora-msg-dialog-panel ${className}` : `aurora-msg-dialog-panel ${className}`}
        zIndex={2000}
        width={width + 20}
        position={position || 'panel-center'}
        mask={mask === undefined ? true : mask}
        maskStyle={maskStyle}
        draggable={false}
        minWidth={300}
        createWH
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        overflow={'hidden'}
        noHeader={true}
        footerHeight={40}
        footer={this.getFooter()}
      >
        <div className='aurora-msg-dialog-main'
          style={position ? { maxHeight: maxHeight - 40 } : {}}
        >
          {title && type !== "error" ? <div className='aurora-msg-dialog-title'>
            {title}
          </div> : null}
          {type === "error" && !title ?
            <div className='aurora-msg-error-dialog-title'>
              <img src="/lib/imgs/warning.png" className='aurora-msg-warning-png' alt="" />
            </div>
            : null}
          <div className='aurora-msg-dialog-content'
            style={position ? { maxHeight: maxHeight - 56 } : {}}
            onScroll={() => this.hasLockScroll()}
            ref={c => { this.lockRef = c; }}
          >
            {messageList && messageList.length ?
              showChildren ? this.getMsgs(messageList) : messageList.map((item, index) =>
                type === "error"
                  ? this.getErrorItem({ index, item })
                  : this.getMsgItem({ index, messageList, item, type }))
              : null}
          </div>
          <div ref={(el) => { this.scrollRef = el; }}></div>
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }

  getMsgItem = ({ index, messageList, item, type }) => {
    return <div key={index} className="aurora-msg-dialog-item" >
      {type !== "log" ? <Fragment>
        {(index < messageList.length - 1) ? <CheckCircleOutlined
          className='message-dialog-ready-icon'
        /> :
          <LoadingOutlined
            className='message-dialog-loading-icon'
          />}
      </Fragment> : null}
      <span>{item}</span>
    </ div>
  }

  getErrorItem = ({ index, item }) => {
    return <div key={index} className='aurora-msg-dialog-item aurora-error-msg-dialog-item'>
      <span>{item}</span>
    </div>
  }

  getMsgs = (messageList) => {
    return messageList.map((item, index) =>
      <Fragment key={index}>
        <span>{item.title}</span>
        {item.children.map((child, i) =>
          <div key={`${index}_${i}`} className='aurora-msg-dialog-item aurora-msg-children-dialog-item'>
            <span>{child}</span>
          </div>)}
      </Fragment>

    )
  }
}


export default MsgDialog;