import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  updateProjectMenu, addProject, deleteProject, deletePCB,
  addVerification, updateExpand, deleteVerification, updateTrash, clearCurrentProject,
  updateViewList
} from '../store/project/action';
import CascadeTree from './cascadeTree/index.js';
import {
  LIBRARY,
  PROJECTS,
  PROJECT,
  SYS_LIBRARY_FILE,
  DCR,
  IR_EXPLORER,
  IMPEDANCE,
  PACKAGE,
  TRASH,
  POWER_TREE,
  LIBRARY_DATA,
  PCB,
  SIGN_OFF_TEMPLATE,
  DESIGN_TREE,
  SINGLE_TREE,
  CUSTOM_LIBRARY_FILE,
  CUSTOM_LIBRARY_FOLDER,
  PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import LibraryUploadPanel from '../../../components/LibraryUpload';
import { VRM, DECAP_RLC, DECAP_TOUCHSTONE, DECAP_SPICE, DECAP_MODEL_MAP, PIN_MAP, CUSTOM_SPICE, CUSTOM_TOUCHSTONE, HFSS_OPTIONS, SIWAVE_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS, CPM_SPICE } from '../../../constants/libraryConstants';
import { CASCADE } from '../../../constants/pageType';
import { getLibraryAccept, getLibraryTitleSuffix, getLibrarySpecial, getLibraryTitle } from '../../../services/Library';
import { createLibrary, getPanelLibraryList, getLibraryFileInfo, getSysLibraryFile, getCustomLibraryByLibraryId, getCustomLibBySearch } from '../../../services/Cascade/library'
import { updateLibraryData, updateLibraryMenu, deleteLibrary, initSystemLibrary, afterImportLibrary } from '../store/library/action';
import { getDefaultName } from '@/services/helper/setDefaultName';
import LibraryDataPanel from '../libraryPanel/LibraryDataPanel'
import libraryConstructor from '@/services/Cascade/helper/libraryConstructor'
import FileContentPanel from "@/components/LibraryUpload/FileContentPanel";
import CpmFileContent from '../libraryPanel/CpmFileContentPanel';
import { cleanCascadeTrash, getProjectChildren } from '@/services/Cascade/project';
import { strDelimited } from '../../../services/helper/split';
import PinMapPanel from '../libraryPanel/PinMapPanel';
import SearchLibraryPanel from '@/components/LibraryPanel/SearchLibraryPanel';
import ImportExportPanel from '../../../components/ImportExport';
import cascadeProjects from '@/services/Cascade/DB/cascadeProject';
import { closeTabFooter } from '../../tabMonitor/action';
import { TagList } from '../../../services/Cascade/constants';
import { createChildren } from '../../../services/Cascade/helper/exportTreeHelper'
import './cascadeSider.css'

class CascadeSider extends Component {
  constructor(props) {
    super(props);
    this.libraryInfo = {
      modelType: null,
      modelName: null,
      library: null
    }
    this.state = {
      editVisible: false,
      uploading: {
        key: '',
        status: false
      },
      importExportModelVisible: true
    }
  }

  componentDidMount = () => {
    this.props._updateLibraryMenu(true);
    this.props.initSystemLibrary();
    this.props.updateTrash();
    const { expandedKeys } = this.props;
    if (expandedKeys.length > 2) {
      expandedKeys.forEach(item => {
        const [key, id] = strDelimited(item, "-");
        if (key === 'project') {
          this.props._updateProjectMenu({ firstLoad: true, openProjectId: id });
        }
      })
    } else {
      this.props._updateProjectMenu({ firstLoad: true });
    }
  }

  addItem = (itemData) => {
    const { dataType, key } = itemData;
    switch (dataType) {
      case LIBRARY:
        this.addLibrary(key, itemData.name);
        break;
      case PROJECTS:
        this.props._addProject();
        break;
      default: break;
    }
    if (dataType.includes('group')) {
      this.props.addVerification(key)
    }
  }

  addLibrary = (key, name) => {
    const libraryList = libraryConstructor.getLibraryValues(key)
    let defaultLibraryName = "";
    const defaultKey = key === VRM ? 'VRM'
      : key === DECAP_RLC ? 'Decap'
        : key === PIN_MAP ? 'Pin Map'
          : ''
    defaultLibraryName = getDefaultName({ nameList: libraryList, defaultKey });

    this.setState({
      editVisible: true
    })

    this.libraryInfo = {
      modelType: key,
      modelName: name,
      defaultLibraryName
    }
  }

  _closeLibraryPanel = (type, data) => {
    this.setState({
      editVisible: false
    })
    this.libraryInfo = {
      modelType: null,
      modelName: null,
      library: {}
    }
  }

  importExportData = (item) => {
    this.setState({
      uploading: {
        key: item.key,
        status: this.state.uploading.status
      }
    })
    let modelType = 'importExportLibrary';
    if (item.dataType === PROJECTS) {
      modelType = 'importExportProject'
    }
    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: modelType,
      modelName: modelType,
      updateLibrary: {}
    };
  }

  changeUploading = (status) => {
    this.setState({
      uploading: {
        key: this.state.uploading.key,
        status
      }
    })
  }

  showImportExportModel = (visible) => {
    this.setState({
      importExportModelVisible: visible
    })
  }

  getPanel = (type) => {
    const { modelType, library, defaultLibraryName } = this.libraryInfo;
    const title = getLibraryTitle(modelType, CASCADE);
    const suffix = getLibraryTitleSuffix(modelType);
    const { expandedKeys, _updateLibraryMenu, _updateExpand } = this.props;
    const { _updateProjectMenu, _clearCurrentProject, _closeTabFooter, afterImportLibrary, changeViewList } = this.props;
    const libraryList = libraryConstructor.getLibraryValues(modelType);
    const libraryId = library ? library.id : null;
    const libraryName = library ? library.name : null;
    const formatMatch = libraryName ? libraryName.match(/\.([^.]+)$/) : null;
    const libraryFormat = formatMatch ? formatMatch[1] : null;
    if ([VRM, DECAP_RLC].includes(modelType)) {
      return <LibraryDataPanel
        title={title}
        closeLibraryPanel={this._closeLibraryPanel}
        name={libraryName}
        defaultVRMName={defaultLibraryName}
        modelType={modelType}
        libraryId={libraryId}
        libraryList={libraryList}
        expandedKeys={expandedKeys}
        expandMenu={_updateExpand}
        updateLibraryMenu={_updateLibraryMenu}
      />
    }

    if (modelType === PIN_MAP) {
      return <PinMapPanel
        libraryInfo={{ libraryId, libraryName, type: 'all' }}
        disabled={true}
        closeModal={this._closeLibraryPanel}
        closeWithoutSave={this._closeLibraryPanel}
      />
    }

    if (modelType === CUSTOM_LIBRARY_FOLDER) {
      return <SearchLibraryPanel
        title="Custom Library Search"
        getCustomLibSearch={getCustomLibBySearch}
        closeModal={this._closeLibraryPanel}
      />
    }

    if (modelType === CPM_SPICE && libraryId && libraryFormat === 'sp') {
      return <CpmFileContent
        title={`${title} - ${library.name}`}
        getLibraryFileContent={this.getFileContentFn(modelType)}
        closeModal={this._closeLibraryPanel}
        libraryId={libraryId}
      />
    }

    if (libraryId) {
      return <FileContentPanel
        title={`${title} - ${library.name}`}
        getLibraryFileContent={this.getFileContentFn(modelType)}
        closeModal={this._closeLibraryPanel}
        libraryId={libraryId}
      />
    }
    // add import/export icon
    if (modelType === "importExportLibrary" || modelType === "importExportProject") {
      return <ImportExportPanel
        product={CASCADE}
        title={modelType === 'importExportLibrary' ? 'Library' : 'Project'}
        importExportModelVisible={this.state.importExportModelVisible}
        projectList={cascadeProjects.getProjectValues()}
        updateProjectMenu={_updateProjectMenu}
        clearCurrentProject={_clearCurrentProject}
        libraryId={libraryId}
        libraryList={getPanelLibraryList()}
        updateLibraryMenu={_updateLibraryMenu}
        closeModal={this._closeLibraryPanel}
        closeTabFooter={_closeTabFooter}
        afterImportLibrary={afterImportLibrary}
        changeViewList={changeViewList}
        changeUploading={this.changeUploading}
        showImportExportModel={this.showImportExportModel}
        createChildren={createChildren}
        getProjectChildren={getProjectChildren}
      />
    }

    return <LibraryUploadPanel
      {...this.libraryInfo}
      uploadTitle={`Import ${title} ${suffix}`}
      tagList={[CUSTOM_SPICE, CUSTOM_TOUCHSTONE].includes(modelType) ? TagList : []}
      title={title}
      product={CASCADE}
      importText={suffix}
      libraryList={libraryList}
      accept={getLibraryAccept(modelType)}
      special={getLibrarySpecial(modelType, 'cascade')}
      checkDisplay={[HFSS_OPTIONS, SIWAVE_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS].includes(modelType) ? true : false}
      allowMulti={[HFSS_OPTIONS, SIWAVE_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS].includes(modelType) ? false : true}
      createLibrary={createLibrary}
      updateLibraryMenu={_updateLibraryMenu}
      expandMenu={_updateExpand}
      expandedKeys={expandedKeys}
      closeLibraryPanel={this._closeLibraryPanel}
    />
  }

  getFileContentFn = (type) => {
    switch (type) {
      case SYS_LIBRARY_FILE:
        return getSysLibraryFile;
      case CUSTOM_LIBRARY_FILE:
        return getCustomLibraryByLibraryId;
      default:
        return getLibraryFileInfo;
    }
  }

  delItem = (itemData) => {
    const { dataType, key } = itemData;
    switch (dataType) {
      case PROJECT:
        this.props._deleteProject(itemData);
        break;
      case PCB:
      case PACKAGE:
      case PCB_PRE_LAYOUT:
        this.props._deletePCB(itemData);
        break;
      case LIBRARY_DATA:
      case DECAP_RLC:
      case DECAP_SPICE:
      case DECAP_TOUCHSTONE:
      case DECAP_MODEL_MAP:
      case CUSTOM_SPICE:
      case CUSTOM_TOUCHSTONE:
      case HFSS_OPTIONS:
      case SIWAVE_OPTIONS:
      case CPM_SPICE:
      case POWERSI_OPTIONS:
      case POWERDC_OPTIONS:
        this.props._deleteLibrary(itemData);
        break;
      case DCR:
      case IR_EXPLORER:
      case IMPEDANCE:
      case POWER_TREE:
      case SIGN_OFF_TEMPLATE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        const [type, id] = key.split('-')
        this.props.deleteVerification(type, id);
        break;
      case TRASH:
        this.cleanTrash();
        break;
      default: break;
    }
  }

  editItem = (e, itemData) => {
    e.stopPropagation();
    switch (itemData.dataType) {
      case LIBRARY_DATA:
      case DECAP_RLC:
        this.editLibrary(itemData);
        break;
      case DECAP_TOUCHSTONE:
      case DECAP_SPICE:
      case SYS_LIBRARY_FILE:
      case CUSTOM_LIBRARY_FILE:
      case CUSTOM_TOUCHSTONE:
      case CUSTOM_SPICE:
      case HFSS_OPTIONS:
      case SIWAVE_OPTIONS:
      case CPM_SPICE:
      case POWERSI_OPTIONS:
      case POWERDC_OPTIONS:
        if (itemData.format === "Folder") {
          return;
        }
        this.getLibraryFileContent(itemData);
        break;
      default: return
    }
  }
  editLibrary = (itemData) => {
    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: itemData.type,
      library: {
        id: itemData.id,
        name: itemData.name,
        dataType: itemData.dataType
      }
    }
  }

  getLibraryFileContent = (itemData) => {
    this.setState({
      editVisible: true
    })
    this.libraryInfo = {
      modelType: itemData.type,
      library: {
        id: itemData.id,
        name: itemData.name,
        dataType: itemData.dataType
      }
    }
  }

  cleanTrash = () => {
    cleanCascadeTrash().then(res => {
      this.props.updateTrash();
    })
  }

  searchClick = (e, itemData) => {
    e && e.stopPropagation();
    if (itemData.dataType === CUSTOM_LIBRARY_FOLDER) {
      this.setState({
        editVisible: true
      })
      this.libraryInfo = {
        modelType: CUSTOM_LIBRARY_FOLDER,
        library: {
          id: itemData.id,
          name: itemData.name
        }
      }
    }
  }

  changeDecap = (e, item, props) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    if (item && item.id) {
      props.updateDefaultLibrary(item.id, item.dataType, item.name)
    } else {
      props.delDefaultLibrary();
    }
  }

  render = () => {
    const { editVisible, modelType, uploading } = this.state
    return (
      <Fragment>
        <CascadeTree
          actions={this.actions}
          uploading={uploading}
        />
        {editVisible && this.getPanel(modelType)}
      </Fragment>
    )
  }

  actions = {
    addItem: this.addItem,
    delItem: this.delItem,
    editItem: this.editItem,
    changeDecap: this.changeDecap,
    searchClick: this.searchClick,
    importExportData: this.importExportData,
    showImportExportModel: this.showImportExportModel
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { treeItems, expandedKeys } } } = state
  return {
    treeItems,
    expandedKeys
  }
}
const mapDispatch = (dispatch) => ({
  _updateLibraryMenu(firstLoad) {
    dispatch(updateLibraryMenu(firstLoad))
  },
  _updateProjectMenu(data) {
    dispatch(updateProjectMenu(data))
  },
  _addProject() {
    dispatch(addProject())
  },
  _clearCurrentProject() {
    dispatch(clearCurrentProject())
  },
  changeViewList(viewList) {
    dispatch(updateViewList(viewList))
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  },
  afterImportLibrary() {
    dispatch(afterImportLibrary())
  },
  _deleteProject(data) {
    dispatch(deleteProject(data))
  },
  _deletePCB(data) {
    dispatch(deletePCB(data))
  },
  _updateLibraryData(libraryType, data) {
    dispatch(updateLibraryData(libraryType, data))
  },
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _deleteLibrary(data) {
    dispatch(deleteLibrary(data))
  },
  initSystemLibrary() {
    dispatch(initSystemLibrary())
  },
  addVerification(key) {
    dispatch(addVerification(key))
  },
  deleteVerification(dataType, id) {
    dispatch(deleteVerification(dataType, id))
  },
  updateTrash() {
    dispatch(updateTrash())
  }
})

export default connect(mapState, mapDispatch)(CascadeSider)