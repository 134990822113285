import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import Panel from '@/components/Panel';
import StackupFooter from "./footer";
import StackupTable from './stackupTable';
import '@/publicCss/aurora.css';
import './index.css';

class StackupPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      height: props.maxHeight,
      errorDisplay: false
    }
    this.dialogRoot = document.getElementsByClassName(`aurora-PCB-layout-content-${props.designID}`)[0] || document.getElementById('root');
  }

  stackupRef = (ref) => {
    this.stackupChild = ref;
  };

  componentDidUpdate = (prevProps) => {
    const { maxHeight } = this.props;
    if (prevProps.maxHeight !== maxHeight) {
      this.setState({
        height: maxHeight
      })
    }
  }

  componentDidMount = () => {
    this.dialogRoot = document.getElementsByClassName(`aurora-PCB-layout-content-${this.props.designID}`)[0];
  }

  closePanel = () => {
    // Only when the panel is closed, it is judged whether it is the same as the original dat. If it is not the same, need to do re-extraction
    this.stackupChild.closeStackupPanel(this.props._closePanel);
  }

  getPanelHeight = (height) => {
    this.setState({ height })
  }

  getPanelWidth = (width) => {
    this.setState({ width })
  }

  getRealHeight = (stackup) => {
    const length = stackup ? stackup.length : 5;
    return length * 40;
  }


  render = () => {
    const { selectingSignal, defaultLeft, defaultTop, leftWidth, topWidth, maxWidth, maxHeight, designID, stackupObj } = this.props;
    const visible = !selectingSignal;
    const { height, width } = this.state;
    const { data = [], unit, loading } = stackupObj[designID] || {};
    const content = (
      <Panel
        className='stackup-panel'
        title="Stackup"
        footer={<StackupFooter
          {...this.props}
          data={data || []}
          unit={unit}
          loading={loading}
        />}
        visible={visible}
        onCancel={() => this.closePanel()}
        position={'panel-center'}
        draggable
        left={leftWidth ? leftWidth : defaultLeft}
        top={topWidth ? topWidth : defaultTop}
        width={maxWidth || null}
        minHeight={100}
        maxHeight={maxHeight || null}
        redirectDom={`aurora-PCB-layout-content-${designID}`}
        getPanelHeight={this.getPanelHeight.bind(this)}
        getPanelWidth={this.getPanelWidth.bind(this)}
      >
        <StackupTable
          {...this.props}
          data={data || []}
          unit={unit}
          loading={loading}
          scrollY={height - 183}
          onRef={this.stackupRef}
          width={width ? width : maxWidth}
          getRealHeight={this.getRealHeight}
        />
      </Panel>)
    return this.dialogRoot ? createPortal(content, this.dialogRoot) : null;
  }
}

export default StackupPanel;