import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '../../../components/Panel';
import { createPortal } from "react-dom";
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../services/helper/panelSizeHelper';
import {
  getMultiSetupBufferData,
  saveICCompModel,
  saveICPinModel,
  savePinValue,
  savePowerOff,
  updateComponentPinUsage,
  applyMultiBufferUsageChange,
  changeMultiBufferPage,
  getMultiInterfaceSetup,
  reAssignBufferModel,
  clearBufferData,
  saveCopyStimulus
} from '../store/multiInterface/action';
import Table from "@/components/EditableTable";
import { CopyOutlined, LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Spin, Switch, Tooltip } from 'antd';
import {
  driverReceiverModelText,
  getModelFileText,
  getPinModels,
  getPinStimulusText,
  settingUnit
} from '../../../services/Sierra';
import ICModelPanel from '../../../components/ICModelPanel';
import { SIERRA } from '../../../constants/pageType';
import { getFolderFileDetail, getIbisModelList, getSpiceModelList } from '../../../services/Sierra/library';
import { getPins } from '../../../services/Sierra/library/IbisModelHelper';
import PinNodeConnect from '@/components/pinNodeConnect';
import { SPI_TYPES } from '@/services/PCBHelper/constants';
import sierraLibrary from '../../../services/Sierra/library/libraryStorage';
import { IBIS, SPICE, VECTOR } from '../../../constants/libraryConstants';
import { copyToClipboard } from '../../../services/helper/htmlElement';
import { updateLibraryTree } from '../store/library/action';
import './index.css';

const CLK_NAME_TYPES = ['CLK', 'SCK', 'SCLK', 'SCL'];
const PIN_TYPE = ['Driver', 'Receiver'];
const columns = [{
  title: "PCB",
  dataIndex: "pcb",
  key: 'pcb',
  textWrap: 'word-break',
  ellipsis: true,
  width: 180
},
{
  title: "Part",
  dataIndex: "part",
  key: 'part',
  textWrap: 'word-break',
  ellipsis: true,
  width: 180
}, {
  title: "Component",
  dataIndex: "component",
  key: 'component',
  width: 100
},
{
  title: "File:Component",
  dataIndex: "componentModel",
  key: 'componentModel'
},
{
  title: "Pin",
  dataIndex: "pin",
  key: 'pin',
  width: 70
},
{
  title: "Usage",
  dataIndex: "usage",
  key: 'usage',
  width: 90
},
{
  title: "Model",
  dataIndex: "model",
  key: 'model'
},
{
  title: "Stimulus",
  dataIndex: "pinModels",
  key: 'pinModels'
},
{
  title: "Interfaces",
  dataIndex: "interfaceNames",
  key: 'interfaceNames'
}];

class BufferEditPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      maxWidth: 0,
      ibisList: sierraLibrary.getTree(IBIS),
      spiceList: sierraLibrary.getTree(SPICE),
      vectorList: sierraLibrary.getTree(VECTOR)
    }
    this.dialogRoot = document.getElementById("root");

    columns[0].render = (text, record) => {
      return <span title={text}>{text}</span>
    }

    columns[0].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.mergePcbLength
      }
    }

    columns[1].render = (text, record) => {
      return <span>{text}</span>
    }

    columns[1].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.mergeComponentLength
      }
    }

    columns[2].render = (text, record) => {
      return <span>{text}</span>
    }

    columns[2].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.mergeComponentLength
      }
    }

    columns[3].title = () => {
      const { modelAssignLoading } = this.props;
      return (
        <div>
          <span className='multi-setup-buffer-model-title'>File:Component</span>
          <Tooltip
            title={modelAssignLoading ? "Assigning..." : 'Re-assign buffer model'}
            mouseLeaveDelay={0}
            mouseEnterDelay={0.3}
            overlayClassName='icon-tooltip'
          >

            {modelAssignLoading ?
              <LoadingOutlined className="multi-setup-buffer-model-re-match-icon" />
              : <RedoOutlined
                className="multi-setup-buffer-model-re-match-icon"
                onClick={this.props._reAssignBufferModel} />}
          </Tooltip>
        </div>
      );
    }

    columns[3].render = (text, record) => {
      //model:[files:[ { fileName, folder, libraryId, type,subckt } ]]
      const file = getModelFileText({ model: record.componentModel });
      const component = record.componentModel.files && record.componentModel.files.length > 0 && record.componentModel.files[0].libraryId && record.componentModel.files[0].component
      const _text = `${file}${component ? `: ${component}` : ''}`;

      return <span className='sierra-ic-editable-cell-value-wrap'
        title={_text}
      >
        {_text}
      </span>;
    }

    columns[3].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.mergeComponentLength
      }
    }

    columns[5].onCell = (record) => {
      return {
        record,
        edit: 'select',
        options: PIN_TYPE,
        dataIndex: 'usage',
        getPopupContainer: document.getElementById("multi-buffer-content-id"),
        handleSave: this.editComponentPinUsage
      }
    };

    columns[6].render = (text, record) => {
      return <span>{driverReceiverModelText(record, {})}</span>;
    }

    columns[6].onCell = (record) => {
      const { component, usage, componentModel } = record;
      const { ibisList, spiceList } = this.state;
      const text = driverReceiverModelText(record, {});
      return usage ? {
        edit: true,
        record: { ...record, pinModels: JSON.parse(JSON.stringify(record.pinModels || [])) },
        componentName: component,
        compModel: componentModel,
        customInput: ICModelPanel,
        className: 'sierra-ic-editable-cell-value-wrap',
        product: SIERRA,
        text,
        pins: [],
        dataIndex: 'model',
        displayType: "model",
        ibisList,
        spiceList,
        isMultiSetup: true,
        getIbisModelList: getIbisModelList,
        getSpiceModelList: getSpiceModelList,
        getFolderFileDetail,
        getPins,
        assignModel: this.assignModel,
        saveModelPins: () => { }/*  this.saveModelPins */,
        savePowerOff: this._savePowerOff,
        saveLibraryModel: this.saveChipModel
      } :
        {
          edit: false,
        }
    }

    columns[7].render = (text, record) => {
      const copyStimulusId = `stimulus-copy-${record.pcb}::${record.component}::${record.pin}`;
      const pasteStimulusId = `stimulus-paste-${record.pcb}::${record.component}::${record.pin}`;
      const allowCopy = record.pinModels && record.pinModels.find(item => item.pinName === "nd_in" && !!item.type)
      const { copyStimulus } = this.props;
      const copyDisplay = copyStimulus && copyStimulus.id !== copyStimulusId;
      const className = allowCopy && copyDisplay ? "multi-setup-buffer-stimulus-text-1" : (allowCopy || copyDisplay) ? "multi-setup-buffer-stimulus-text-2" : ""
      return (
        <div className="multi-setup-buffer-stimulus-content">
          <span
            className={`multi-setup-buffer-stimulus-text ${className}`}
          >{getPinStimulusText(record.pinModels)}</span>
          {copyDisplay ? <span
            title="Paste Stimulus"
            id={pasteStimulusId}
            className={`iconfont icon-niantie stimulus-paste-setup-icon ${allowCopy ? "" : "stimulus-paste-setup-icon-1"}`}
            onClick={(e) => this.pasteStimulus(e, record, pasteStimulusId)}
          ></span> : null}
          {allowCopy ? <CopyOutlined
            title="Copy Stimulus"
            id={copyStimulusId}
            className={copyDisplay ? "stimulus-copy-setup-icon" : "stimulus-copy-setup-icon stimulus-copy-setup-icon-1"}
            onClick={(e) => this.copyRecordStimulus(e, record, copyStimulusId)} /> : null}
        </div>
      );
    }

    columns[7].onCell = (record) => {
      const { usage, component, componentModel, model, interfaceType, clock } = record;
      const type = usage === 'Driver' ? 'tx' : 'rx';
      const { vectorList, spiceList } = this.state;
      const pinModels = getPinModels(record.pinModels);
      const text = getPinStimulusText(record.pinModels);
      let _clock = {};
      if (typeof clock === "string" && clock) {
        _clock = settingUnit(clock, "M");
      }
      let defaultDelayValue = SPI_TYPES.includes(interfaceType) && CLK_NAME_TYPES.find(item => (record.signal || "").includes(item)) ? { type: 'percent', value: '35' } : null;
      return type === 'tx' && record.model ? {
        record,
        edit: true,
        text,
        modelType: "Stimulus",
        pins: [],
        compName: component,
        compModel: componentModel,
        product: SIERRA,
        model,
        isMultiSetup: true,
        className: 'sierra-stimulus-editable-cell-value-wrap',
        stimulusModel: {/* model */ },
        customInput: PinNodeConnect,
        pinModels: JSON.parse(JSON.stringify(record.pinModels || [])),//{type:type-seed}
        modelPins: JSON.parse(JSON.stringify(pinModels || [])),//{type,seed}
        displayType: "model",
        vectorList: vectorList,
        dataIndex: 'inputStimulus',
        libraryList: spiceList,
        getPins: getPins,
        getLibraryFile: getSpiceModelList,
        getFolderFileDetail,
        stimulus: this.getStimulus(record),
        saveModelPins: this.saveModelPins,
        savePinStimulusModel: () => { },
        clock: _clock || {},
        applyAll: false,
        defaultDelayValue
      } : {
        edit: false,
      }
    }

    columns[8].render = (text, record) => {
      return <span>{(record.interfaceNames || []).join(", ")}</span>;
    }
  }

  getStimulus(record) {
    const pins = record.pinModels;
    let stimulus, pinName;
    if (!pins) {
      return { stimulus, pinName };
    }
    for (let pin of pins) {
      if (pin.stimulus) {
        pinName = pin.pinName;
        stimulus = pin.stimulus;
      }
    };
    return { stimulus, pinName }
  }

  copyRecordStimulus = (e, record, copyId) => {
    e && e.stopPropagation();
    copyToClipboard(e, "", copyId);
    this.props._saveCopyStimulus({ id: copyId, pinModels: JSON.parse(JSON.stringify(record.pinModels || [])) })
  }

  pasteStimulus = (e, record) => {
    e && e.stopPropagation();
    const { copyStimulus } = this.props;
    if (!copyStimulus || !copyStimulus.pinModels || !copyStimulus.pinModels.length) {
      return;
    }
    const pinModels = JSON.parse(JSON.stringify(copyStimulus.pinModels));
    pinModels.forEach(item => {
      const findItem = record.pinModels.find(it => it.pinName === item.pinName && ["nd_pu", "nd_pd"].includes(item.pinName));
      if (findItem) {
        item.voltage = findItem.voltage;
      }
    })
    this.props._savePinValue({ record, pinModels, applyAll: false });
  }

  assignModel = ({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }) => {
    this.props.saveModel({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff });
  }

  saveModelPins = ({ record, pinModels, applyAll, applyAllDriver }) => {
    this.props._savePinValue({ record, pinModels, applyAll, applyAllDriver });
  }

  _savePowerOff = ({ record, powerOff, applyAll }) => {
    //this.props.savePowerOffMode(record, powerOff, applyAll);
  }

  saveChipModel = ({ files, libType, record }) => {
    const { pcb, component } = record;
    this.props._saveICCompModel({ files, libType, pcb, component });
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    this.resize()
    window.addEventListener('resize', this.resize);
    this.props._getMultiSetupBufferData(this.props.verificationId)
  }

  componentDidUpdate = (prevProps) => {
    const { libraryTreeInfo } = this.props;
    if (libraryTreeInfo &&
      (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length)
      && (libraryTreeInfo.includes(IBIS)
        || libraryTreeInfo.includes(SPICE)
        || libraryTreeInfo.includes(VECTOR))) {
      this.getLibraryList();
      this.props._updateLibraryTree(null);
    }
  }

  getLibraryList = () => {
    this.setState({
      ibisList: sierraLibrary.getTree(IBIS),
      spiceList: sierraLibrary.getTree(SPICE),
      vectorList: sierraLibrary.getTree(VECTOR)
    })
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 800),
      maxWidth: getPanelMaxWidth(offset, 1300)
    })
  }

  editComponentPinUsage = (record) => {
    this.props._updateComponentPinUsage(record)
  }

  closeModal = () => {
    this.props.save && this.props.save();
    this.props.closePanel && this.props.closePanel();
    this.props._getMultiInterfaceSetup()
    this.props._clearBufferData()
  }

  onPageChange = (page) => {
    this.props._changeMultiBufferPage(page)
  }

  render() {
    const { loading, pageData, pageSize, page, allDataLength, usageApplyAll, interfaceName } = this.props;
    const { maxWidth, maxHeight } = this.state;
    const content = (
      <Fragment>
        <Panel
          title={`${interfaceName ? `${interfaceName} ` : ""}Buffer Model`}
          className='sierra-multi-buffer-panel sierra-panel'
          position='panel-center-top'
          minHeight={200}
          minWidth={100}
          width={getPanelWidth(maxWidth, { defaultWidth: 1300 })}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onCancel={() => { this.closeModal() }}
          draggable
          zIndex={2000}
        >
          <div className="multi-buffer-content" id="multi-buffer-content-id">
            <Spin spinning={loading} tip={'Loading...'}>
              <div className='sierra-multi-buffer-content'>
                <div className="sierra-multi-buffer-copy-usage">
                  <span>Apply the same usage setting to all the pins in the component</span>
                  <Switch
                    size="small"
                    className="aurora-switch-small sierra-multi-buffer-copy-usage-switch"
                    checked={usageApplyAll}
                    onChange={this.props.applyUsageChange}
                  />
                </div>
                <Table
                  columns={columns}
                  dataSource={JSON.parse(JSON.stringify(pageData || []))}
                  className="space-10 sierra-multi-buffer-table"
                  rowKey={(record) => `${record.pcb}_${record.component}_${record.pin}`}
                  size="small"
                  pagination={{
                    position: "top",
                    pageSize,
                    current: page,
                    hideOnSinglePage: true,
                    onChange: this.onPageChange,
                    total: allDataLength
                  }}
                />
              </div>
            </Spin>
          </div>
        </Panel>
      </Fragment>
    );
    return createPortal(content, this.dialogRoot)
  }

}

const mapState = (state) => {
  const { SierraReducer: { multiInterfaceSetup: { bufferData, copyStimulus, modelAssignLoading }, library: { libraryTreeInfo } } } = state;
  const { pageData, page, pageSize, allDataLength, loading, usageApplyAll } = bufferData || {};
  return {
    pageData,
    page,
    pageSize,
    allDataLength,
    loading,
    usageApplyAll,
    copyStimulus,
    libraryTreeInfo,
    modelAssignLoading
  }
}

const mapDispatch = (dispatch) => ({
  _getMultiSetupBufferData(verificationId) {
    dispatch(getMultiSetupBufferData(verificationId))
  },
  _changeMultiBufferPage(page) {
    dispatch(changeMultiBufferPage(page))
  },
  _saveICCompModel({ files, pcb, component }) {
    dispatch(saveICCompModel({ files, pcb, component }))
  },
  saveModel({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }) {
    dispatch(saveICPinModel({ record, model, deviceVcc, pinModelsInfo, applyAll, applySignal, powerOff }))
  },
  _savePinValue({ record, pinModels, applyAll, applyAllDriver }) {
    dispatch(savePinValue({ record, pinModels, applyAll, applyAllDriver }));
  },
  savePowerOffMode(record, powerOff, applyAll) {
    dispatch(savePowerOff(record, powerOff, applyAll));
  },
  _updateComponentPinUsage(record) {
    dispatch(updateComponentPinUsage(record))
  },
  applyUsageChange(checked) {
    dispatch(applyMultiBufferUsageChange(checked))
  },
  _getMultiInterfaceSetup() {
    dispatch(getMultiInterfaceSetup())
  },
  _reAssignBufferModel() {
    dispatch(reAssignBufferModel())
  },
  _clearBufferData() {
    dispatch(clearBufferData())
  },
  _saveCopyStimulus(copyStimulus) {
    dispatch(saveCopyStimulus(copyStimulus))
  },
  _updateLibraryTree(type) {
    dispatch(updateLibraryTree(type))
  },
})

export default connect(mapState, mapDispatch)(BufferEditPanel);
