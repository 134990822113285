import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import Input from 'antd/es/input/Input';
import { numberCheck } from "@/services/helper/dataProcess";
import './index.css';

class TransientTimePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stepTime: '10',
      stopTime: '0',
      startTime: '0'
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    this.initData()
  }

  initData = () => {
    const { targetICData } = this.props
    const dieCurrent = targetICData.dieCurrent || null
    this.setState({
      stepTime: dieCurrent && dieCurrent.stepTime ? dieCurrent.stepTime : '10',
      stopTime: dieCurrent && dieCurrent.stopTime ? dieCurrent.stopTime : '0',
      startTime: dieCurrent && dieCurrent.startTime ? dieCurrent.startTime : '0',
    })
  }

  closeModal = () => {
    const { stepTime, stopTime, startTime } = this.state
    this.props.closeModal(startTime, stopTime, stepTime)
  }

  valueBlur = (e, key) => {
    let value = e.target.value;
    if (value) {
      const error = numberCheck(value);
      if (error) {
        e.target.focus();
      }
    }
    this.setState({
      [key]: value
    })
  }

  changeSettingValue = (e, type) => {
    this.setState({
      [type]: e.target.value,
    })
  }

  renderDialog = () => {
    const { stepTime, stopTime, startTime } = this.state
    const content = (
      <Panel
        className='transient-time-panel'
        id='transient-time-panel'
        title={'Transient Time Setting'}
        onCancel={this.closeModal}
        zIndex={2000}
        width={400}
        minWidth={300}
        minHeight={200}
        position='panel-center-left'
        draggable
        overflow={"auto"}
      >
        <div className='imp-transient-setting-content'>
          <div className='imp-transient-setting-body'>
            <span className='imp-transient-setting-title'>Transient start time</span>
            <Input
              value={startTime}
              onChange={(e) => this.changeSettingValue(e, 'startTime')}
              addonAfter={<div className='imp-current-after'>ns</div>}
              onBlur={(e) => this.valueBlur(e, 'startTime')}
            />
          </div>
          <div className='imp-transient-setting-body'>
            <span className='imp-transient-setting-title'>Transient stop time</span>
            <Input
              value={stopTime}
              onChange={(e) => this.changeSettingValue(e, 'stopTime')}
              addonAfter={<div className='imp-current-after'>ns</div>}
              onBlur={(e) => this.valueBlur(e, 'stopTime')}
            />
          </div>
          <div className='imp-transient-setting-body'>
            <span className='imp-transient-setting-title'>Transient step time</span>
            <Input
              value={stepTime}
              onChange={(e) => this.changeSettingValue(e, 'stepTime')}
              addonAfter={<div className='imp-current-after'>ps</div>}
              onBlur={(e) => this.valueBlur(e, 'stepTime')}
            />
          </div>
        </div>
      </Panel>
    );
    return createPortal(content, this.dialogRoot);
  }

  render() {
    return <Fragment>
      {this.renderDialog()}
    </Fragment>
  }
}

export default TransientTimePanel;
