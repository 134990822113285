function getPinListBySignal({ pinInfo, pinList, pinSpiceModel }) {

  const signalTypes = pinInfo.signal.match(/^(DQS)|^(WCK)|^(RDQS)|^(CLK)/ig);
  // let node = { in: "", out: "" };
  if (signalTypes && signalTypes[0]) {
    const signalType = signalTypes[0];
    const _pinList = pinList.filter(item => item.signal.match(signalType));
    _pinList.forEach(pin => {
      const isSame = pin.model && pinSpiceModel.libraryId === pin.model.libraryId
        && pinSpiceModel.fileName === pin.model.fileName
        && pinSpiceModel.modelName === pin.model.modelName;
      pin.model = {
        ...pinSpiceModel,
        node: isSame && pin.model.node ? { in: pin.model.node.in || "", out: pin.model.node.out, vddq: pin.model.node.vddq || "", vss: pin.model.node.vss || "" } : { in: "", out: "", vddq: "", vss: "" }
      }
    });
    return _pinList;
  }
  const findPin = pinList.find(item => item.pin === pinInfo.pin) || {};
  const isSame = findPin.model && pinSpiceModel.libraryId === findPin.model.libraryId
    && pinSpiceModel.fileName === findPin.model.fileName
    && pinSpiceModel.modelName === findPin.model.modelName;
  return [{
    ...pinInfo,
    model: {
      ...pinSpiceModel,
      node: isSame && findPin && findPin.model && findPin.model.node
        ? { in: findPin.model.node.in || "", out: findPin.model.node.out, vddq: findPin.model.node.vddq || "", vss: findPin.model.node.vss || "" }
        : { in: "", out: "", vddq: "", vss: "" }
    }
  }]
}

function getPinSpiceModel({
  pinsModelList,
  comp,
  component,
  pin,
  pinItem
}) {

  const signalTypes = pinItem.signal.match(/^(DQS)|^(WCK)|^(RDQS)|^(CLK)/ig);
  const signalType = signalTypes && signalTypes.length ? signalTypes[0] : null;
  let findPin = null;
  if (signalType) {
    findPin = pinsModelList.find(item => item.signal === pinItem.signal);
    if (!findPin) {
      const signalList = getSignalList(pinItem.signal);
      findPin = pinsModelList.find(item => signalList.includes(item.signal));
    }
  } else {
    findPin = pinsModelList.find(item => item.component === component && item.pin === pin);
  }
  if (findPin) {
    return findPin.model || {};
  }
}

function getSignalList(signal) {
  let pSignals = ["DQSP", "CLKP", "RDQS_T", "WCK_T"],
    nSignals = ["DQSN", "CLKN", "RDQS_C", "WCK_C"];

  if (pSignals.includes(signal)) {
    return pSignals;
  }

  if (nSignals.includes(signal)) {
    return nSignals;
  }
  return []
}

export {
  getPinListBySignal,
  getPinSpiceModel
}