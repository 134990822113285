import {
  UPDATE_SIGN_OFF_TEMPLATE_INFO,
  SAVE_SIGN_OFF_VERIFICATION_ID,
  SELECT_SIGN_OFF_TASK,
  UPDATE_SIGN_OFF_TASK_ERROR,
  UPDATE_SIGN_OFF_CREATE_TASK_STATUS,
  RESET_TEMPLATE_SETUP,
  UPDATE_TEMPLATE_ERRORS,
  UPDATE_TEMPLATE_PCB_LOADING,
  UPDATE_TEMPLATE_CONTENT,
  UPDATE_TEMPLATE_COMPONENTS_SETTING_STATUS,
  UPDATE_TEMPLATE_CONTENT_LOADING,
  UPDATE_MODEL_ASSIGN_LOADING,
  UPDATE_CREATE_TASK_MSG,
  UPDATE_TEMPLATE_SETUP,
  UPDATE_TEMPLATE_SETUP_VALUE,
  UPDATE_TEMPLATE_STACKUP_ERROR,
  UPDATE_DESIGN,
  UPDATE_SIGN_OFF_TEMPLATE_LOGS,
  SAVE_TEMPLATE_DISPLAY,
  UPDATE_TEMPLATE_PDF_PANEL
} from './actionType';
import { CLEAR_CURRENT_PROJECT } from '../project/actionType';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import { PARTBASED } from '../../../../constants/resolution';

const defaultState = {
  data: {},
  verificationId: null,
  designId: null,
  signOffTaskId: null,
  signOffTaskErrorMap: null,
  signOffPathTaskErrorMap: null,
  signOffCreateTaskStatus: false,
  templateError: null,
  loading: false,
  templateCompSettingStatus: false,
  contentLoading: false,
  modelAssignLoading: false,
  createTaskMsgList: [],
  templateSetupUpdate: false,
  templateValueUpdate: false,
  signOffTemplateLogs: [],
  templateComponentsTableDisplay: PARTBASED,
  templatePDFPanel: {}
};

export const SignOffTemplateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SIGN_OFF_TEMPLATE_INFO:
      return {
        ...state,
        data: action.data,
        designId: action.data.designId
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case SAVE_SIGN_OFF_VERIFICATION_ID:
      return {
        ...state,
        verificationId: action.id
      }
    case SELECT_SIGN_OFF_TASK:
      return {
        ...state,
        signOffTaskId: action.id
      }
    case UPDATE_SIGN_OFF_TASK_ERROR:
      return {
        ...state,
        signOffTaskErrorMap: action.taskErrorMap ? action.taskErrorMap : state.signOffTaskErrorMap,
        signOffPathTaskErrorMap: action.pathTaskErrorMap ? action.pathTaskErrorMap : state.signOffPathTaskErrorMap
      }
    case UPDATE_SIGN_OFF_CREATE_TASK_STATUS:
      return {
        ...state,
        signOffCreateTaskStatus: action.status
      }
    case RESET_TEMPLATE_SETUP:
      return {
        ...state,
        designId: null,
        signOffTaskId: null,
        signOffCreateTaskStatus: false
      }
    case UPDATE_TEMPLATE_ERRORS:
      return {
        ...state,
        templateError: action.error
      }
    case UPDATE_TEMPLATE_PCB_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case UPDATE_TEMPLATE_CONTENT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.info
        }
      }
    case UPDATE_TEMPLATE_COMPONENTS_SETTING_STATUS:
      return {
        ...state,
        templateCompSettingStatus: action.status
      }
    case UPDATE_TEMPLATE_CONTENT_LOADING:
      return {
        ...state,
        contentLoading: action.loading
      }
    case UPDATE_MODEL_ASSIGN_LOADING:
      return {
        ...state,
        modelAssignLoading: action.loading
      }
    case UPDATE_CREATE_TASK_MSG:
      return {
        ...state,
        createTaskMsgList: action.msgList
      }
    case UPDATE_TEMPLATE_SETUP:
      return {
        ...state,
        templateSetupUpdate: action.status
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...defaultState
      }
    case UPDATE_TEMPLATE_SETUP_VALUE:
      return {
        ...state,
        templateValueUpdate: action.templateValueUpdate
      }
    case UPDATE_TEMPLATE_STACKUP_ERROR:
      return {
        ...state,
        templateStackupErrors: action.templateStackupErrors
      }
    case UPDATE_DESIGN:
      return {
        ...state,
        designId: action.designId
      }
    case UPDATE_SIGN_OFF_TEMPLATE_LOGS:
      return {
        ...state,
        signOffTemplateLogs: action.clear ? [] : [...state.signOffTemplateLogs, ...action.logs]
      }
    case SAVE_TEMPLATE_DISPLAY:
      return {
        ...state,
        templateComponentsTableDisplay: action.compTableDisplay
      }
    case UPDATE_TEMPLATE_PDF_PANEL:
      return {
        ...state,
        templatePDFPanel: action.replace ? action.templateInfo : {
          ...state.templatePDFPanel,
          ...action.templateInfo
        }
      }
    default: return state
  }
} 