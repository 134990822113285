import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import {
  SIMULATION_DEBUG,
  getHimalayasDownloadSimulationUrl,
  getHimalayasDebugDownloadList
} from '@/services/helper/debugHelper';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import DebugDownloadContent from '@/components/DebugDownload_v2';
import { IO_SIM, IO_SIM_GROUPS } from '../../../constants/treeConstants';
import { getDebugReportData, getDebugSimulationData } from '../../../services/Himalayas';
import himalayasChannels from '../../../services/Himalayas/project/channelsConstructor';
import { HIMALAYAS } from '../../../constants/pageType';
import { REPORT } from '../../../services/helper/debugHelper';
import himalayasProjects from '../../../services/Himalayas/project/projectConstructor';
import '@/publicCss/debug.css';
import "./index.css";


const DEBUG_ITEMS = [/* SPICE_CARD_TEMPLATE, */ IO_SIM, REPORT];
class DebugPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 750,
      maxHeight: 304,
      debugMenuList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 304),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getDownloadOptions();
  }

  componentDidUpdate = (prevState) => {
    const { verificationId } = this.props;
    if (verificationId !== prevState.verificationId) {
      this.getDownloadOptions();
    }
  }

  getDownloadOptions = () => {
    const { projectId, view, channelId, verificationId } = this.props;
    let debugMenuList = [];

    for (let key of DEBUG_ITEMS) {
      if (key === REPORT) {
        const reportData = getDebugReportData({
          treeList: himalayasChannels.getList(IO_SIM_GROUPS, projectId),
          menuType: REPORT,
          view,
          channelId,
          projectId,
          currentProjectName: (himalayasProjects.getProject(this.props.projectId) || {}).name
        })
        debugMenuList.push(reportData);
      } else {
        const simulationData = getDebugSimulationData({
          treeList: himalayasChannels.getList(IO_SIM_GROUPS, projectId),
          selectList: himalayasChannels.getList(IO_SIM, projectId),
          menuType: SIMULATION_DEBUG,
          key,
          view,
          channelId,
          verificationId,
          projectId,
          currentProjectName: (himalayasProjects.getProject(this.props.projectId) || {}).name
        })
        debugMenuList.push(simulationData);
      }
    }
    this.setState({
      debugMenuList
    })
  }

  getHeight = () => {
    const content = document.getElementById("debug-content-id");
    if (content) {
      const height = content.offsetHeight + 70 || 242;
      return height > 242 ? height : 242;
    }
    return 242;
  }

  render() {
    const { maxWidth, maxHeight, debugMenuList } = this.state;
    const height = this.getHeight();
    const content = (
      <Panel
        className='aurora-debug-panel panel-x-scroll-hidden himalayas-debug-panel'
        title={<div className='aurora-debug-title'>Debug</div>}
        onCancel={this.props.closePanel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 800 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <DebugDownloadContent
          product={HIMALAYAS}
          debugMenuList={debugMenuList}
          getDownloadList={getHimalayasDebugDownloadList}
          getDownloadSimulationURL={getHimalayasDownloadSimulationUrl}
        />
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}
export default DebugPanel;