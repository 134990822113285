import { call, put, fork, take, cancel, takeEvery, select, delay, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  GET_VERIFICATION_CONTENT,
  EDIT_SIGNAL_NAME,
  SAVE_CONTENT_TO_SERVER,
  AUTO_SAVE_CONTENT_TO_SERVER,
  EDIT_SIGNAL_NETS,
  OPEN_PAGE,
  DELETE_SIGNAL,
  UPDATE_INTERFACES,
  ADD_POWER_GROUNDS,
  POWER_NET_SELECTION,
  SAVE_EXTRACTION,
  SAVE_PIN_MODEL,
  SAVE_POWER_OFF,
  SAVE_MODEL_PINS,
  SAVE_STIMULUS,
  SAVE_CURRENT_VERIFICATION,
  SAVE_SIMULATION_OPTION,
  SAVE_SERVICE_OPTION,
  SAVE_COMP_MODEL,
  APPLY_SETUP_TO_SELECT,
  GET_LIBRARY_FILE_PARSE,
  SAVE_COMP_PACKAGE_MODEL,
  UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS,
  UPDATE_ADR_CMD_STIMULUS,
  UPDATE_CHANNEL_BY_VERSION,
  UPDATE_PORT_SETUPS_TO_SERVER,
  CLEAR_STANDBY_MODEL,
  APPLY_ROCKY_PORT_SETUP,
  STIMULUS_RANDOM,
  SELECT_PACKAGE_LAYOUT_MODEL,
  GET_PACKAGE_CONTENT,
  SAVE_CURRENT_PACKAGE_VERIFICATION,
  UPDATE_MEMORY_SELECTIONS,
  UPDATE_PACKAGE_SIGNAL_INFO,
  DELETE_PACKAGE_SIGNAL,
  CHANGE_PACKAGE_SIGNAL_NAME,
  ADD_PACKAGE_VERIFICATION,
  ADD_PACKAGE_CHANNEL,
  COMBINE_PACKAGE_INTERFACE,
  SAVE_ROCKY_PACKAGE_CONTENT_SERVER,
  UPDATE_REF_NETS,
  SAVE_VIRTUAL_COMPONENT,
  EDIT_PACKAGE_COMPONENT_TYPE,
  GET_CLK_VERIFICATION_CONTENT,
  SAVE_ROCKY_PACKAGE_PDN_SERVER,
  SAVE_BALL_SIZE,
  UPDATE_PACKAGE_NETS,
  UPDATE_PACKAGE_COMPONENTS,
  UPDATE_PORTS,
  SWITCH_PACKAGE_PORTS,
  SPLIT_COMPONENT_PART,
  MERGE_COMPONENTS,
  UPDATE_COMP_USAGE,
  REMOVE_COMPONENTS,
  SAVE_DECAP_MODEL,
  SAVE_PKG_PDN_EXTRACTION,
  SAVE_PKG_PDN_COMPONENT_SETTING,
  ADD_POWER_DOMAIN,
  DELETE_POWER_DOMAIN,
  UPDATE_PACKAGE_PORT_AND_BALL,
  UPDATE_VRM,
  UPDATE_VOLTAGE,
  SAVE_VRMS,
  UPDATE_INCLUDE_EXTEND_NETS,
  SAVE_PACKAGE_CONTENT_AND_CLEAR,
  SAVE_PCB_CHANNEL_CONTENT,
  UPDATE_TARGET_IC,
  UPDATE_PKG_DIE_PORTS_BY_CPM,
  START_MODELING_EXTRACTION,
  RE_ASSIGN_DECAP_MODEL,
  GET_PCB_CHANNEL_CONTENT,
  GET_PCB_AND_PACKAGE_CHANNEL_INFO,
  SAVE_CHANNEL_DECAP_MODEL,
  RE_ASSIGN_CHANNEL_DECAP_MODEL,
  UPDATE_POWER_COMPONENTS_AFTER_REF_NETS,
  APPLY_POWER_COMPONENTS,
  UPDATE_CHANNEL_CONTENT,
  UPDATE_CHANNEL_COMP_USAGE,
  REMOVE_CHANNEL_COMPONENTS,
  SPLIT_CHANNEL_COMPONENT_PART,
  MERGE_CHANNEL_COMPONENTS,
  UPDATE_CHANNEL_REFERENCE_NETS
} from './actionTypes';
import RockySetup from '@/services/Rocky/helper/rockyDatabase';
import {
  getVerificationContentPromise,
  updateVerificationContent,
  getComponentsWithNetList,
  deleteCompsConnectWithNets,
  updateInterfaceInfo,
  checkExistNets,
  ICComponent,
  ICCompModel,
  BasicCompModel,
  RLCCONComponent,
  BasicComponent,
  getPowerComponents,
  findPowerNetsByRes,
  SourceNets,
  Extraction,
  judgeExtraction,
  Model,
  getPins,
  checkSignalName,
  updateChannelConfig,
  getCopySetupContent,
  copyComponentModel,
  getIBISModelName,
  getIBISModelList,
  /* checkLibraryInInterface, */ //todo
  getLibraryFileList,
  exsitEnableVoltage,
  copyComponentPackageModel,
  findVTTNets,
  updateProjectConfig,
  getInterfaceType,
  getCmd2TMode,
  judgeUpdateGapPortSetup,
  updateExtractionGapPortsSetup,
  addPRBSTabs,
  updatePortsComponentsByPortType,
  getDefaultPortsGenerateSetupList,
  getChannelConfig,
  getHavePackageVerificationPromise,
  getPackageExtractionPromise,
  updatePackageExtractionPromise,
  updatePackageVerificationPromise,
  getPackageVerificationInfoPromise,
  addActiveToDIMMComponents,
  changeStdModelToDimmStdModels,
  PACKAGE_INDEX,
  PROJECT_INDEX,
  packageChannelItem,
  judgeDimmActiveExist,
  addActiveToLPDDR4MemoryComponents,
  judgeDimmStdModelExist,
  PinSpiceModel,
  getPinSpiceModel,
  getRockyPackagePDNInfo,
  updateRockyPackagePDNInfo,
  updatePCBChannelContentPromise,
  getRockyProjectPromise,
  getRockyPackagePDN,
  getPackageVerificationListPromise
} from '@/services/Rocky';
import { checkVerificationStatus } from '@/services/workflow/workflow';
import projectDesigns from '@/services/helper/projectDesigns';
import { getIbisModelList, getSpiceModelList, getTouchstoneParse, getLibraryMacroModelingStatus, getPkgSpiceModelList, getFolderFileDetail } from '@/services/Rocky/library';
import {
  updateRockyContent,
  updateRockyContentInfo,
  updateRockyInterfaces,
  getVerificationContent,
  updateCurrentLibraryFileList,
  getLibraryFileParse,
  updateComponentLoading,
  updateErrorCheckList,
  cleanSetupInfo,
  updateTouchstoneStatusList,
  updateTouchstoneMacroModelingStatus,
  updateInterfaces,
  updateADRCMDStimulus,
  updatePackageLoading,
  updatePackageMsg,
  getPackageLayoutContent,
  updateRockyPackageLayoutInfo,
  updatePackageSetupLoading,
  updatePackageContentInfo,
  updateApplySetupStatus,
  updateSaveStatus,
  updatePackagePDNInfo,
  updatePCBChannelContent,
  updateSSNPackageInfo,
  updateModelAssignLoading,
  updatePowerComponentsApplyLoading,
  updateDecapModelSetupLoading,
  isUpdatePackagePinStatus,
  updateContentLoading,
  updateLoading
} from './action';
import { ROCKY_SETUP_VERSION, ROCKY_PACKAGE_SETUP_VERSION } from '@/version';
import DesignInfo from '@/services/Rocky/pcbInfo';
import {
  ddrDMTypes,
  ROCKY_PDN,
  _2TModeDDRTypes,
  no2TModeSignalsReg,
  portSavePath,
  LPDDR5,
  PACKAGE_CHANNEL,
  DIMM_DDR_TYPES
} from '@/services/Rocky/constants';
import {
  PKG_SPICE, PKG_TOUCHSTONE,
} from '@/constants/libraryConstants';
import {
  PCB_PRE_LAYOUT,
  VERIFICATION,
  PCB,
  RESULT,
  PACKAGE_VERIFICATION,
  PACKAGE_RESULT,
  CARD_RESULT,
  PACKAGE,
  CARD,
  PACKAGE_CHANNEL_CREATE,
  CARD_VERIFICATION,
  PACKAGE_PDN_RESULT,
  CHANNEL,
  PDN
} from '@/constants/treeConstants';
import { openPageInfo, saveComponentsNetsInProject, updatePCBComponentsNets, autoGetVerificationList, openProject, projectMenu, updateCurrentPackageId, updateViewList, updateTreeSelectedKeys, expandMenu, defaultChannelConfig, saveOpenProjectInfo } from '../project/action';
import { checkRLCValue, versionCompareSize } from '@/services/helper/dataProcess';
import LayoutData from '@/services/data/LayoutData';
import { startRockyVerification, getInterfaceMonitor, updateStimulationMessage, startRockyPackageVerification, startSeparateExtraction, updateSimulationReducer } from '../simulation/action';
import { getRockyPreLayout } from '../prelayout/action';
import { openResultPageInfo, openPackageResultPageInfo, openCardResultPageInfo, cleanResultPageInfo } from '../result/action';
import ByteSetupInfo from '@/services/Rocky/helper/rockySetupInfo';
import { getErrorCheck } from '../../errorCheck/ErrorCheck';
import { getChannelPDNContent, updateRockyPDNContent } from '../rockyPdn/action';
import { SAVE_ROCKY_PDN_TO_SERVER } from '../rockyPdn/actionTypes';
import { saveRockyPDNContentToServer } from '../rockyPdn/saga';
import * as taskStatus from '@/constants/workflowStatus';
import { PRE_LAYOUT as PRELAYOUT } from '@/constants/designVendor';
import { _getLayoutDB, getPCBInterfaces, getVerificationsByChannel } from '../project/saga';
import { VERIFY_RUNNING, WAITING } from '@/constants/verificationStatus';
import { isRockyPuppeteer } from '@/services/helper/browser';
import channelVerifications from '@/services/Rocky/helper/channelVerifications';
import projectChannels from '@/services/Rocky/helper/projectChannels';
import rockyChannels from '@/services/Rocky/helper/channels';
import { getDefaultResultConfig } from '@/services/Rocky/result';
import {
  getDefaultReferenceNets,
  getDefaultPortSetupList,
  getPortGenerateSetupList
} from "@/services/ExtractionPortsHelper";
import { splitComponentName } from '../../../../services/helper/splitComponent';
import { getCopyPortSetupAllInterfaces, getCopyInterfaces, getCopyPackageInterface } from '@/services/Rocky';
import { isPowerGND, RLC_TYPES, checkCompsType } from "@/services/PCBHelper";
import { findReferenceNetsBySignal, getReferenceNetsBySignal } from "@/services/api/refNets";
import {
  FIND_REF_NET_RUNNING,
  FIND_REF_NET_SUCCESS,
  FIND_REF_NET_FAILED,
} from "@/constants/findRefNetsConstants";
import packageVerificationConstructor from '@/services/Rocky/PackageHelper';
import { getCardVerificationContent, saveCardPortSetups } from '../card/action';
import { saveCardContentToServer } from '../card/saga';
import { RockyPackageInterface } from '@/services/Rocky/helper/IntegratedInterface'
import { BGA, CAP, CONTROLLER, DIE, IGNORE, IPD, SPECIALIZED, LOAD, RES, IND, REMOVED, UNUSED } from '../../../../constants/componentType';
import permissionData from '../../../../services/helper/data/permissionData';
import { setDefaultPortData } from '@/services/userDefaultSetting/userDefaultSettingCtrl.js'
import { MEMORY } from '../../../../constants/componentType';
import { closeCountTime, getCountTime } from '@/services/helper/hasOperate';
import { changeTabMenu, closeTabFooter } from '../../../MonitorStore/action';
import { strDelimited } from '@/services/helper/split';
import cardChannelsConstructor from '../../../../services/Rocky/cardHelper';
import { DDR5_RDIMM, RDIMM_TYPES, DDR5_SODIMM, LPDDR4, SSN_VERIFICATION, PACKAGE_PDN, SINGLE_PATTERN, SIGNAL_EXTRACTION, PDN_EXTRACTION } from '../../../../services/Rocky/constants';
import { isByte } from '../../../../services/Rocky/result/helper/eyediagram/displayHelper';
import { GAP, PortsGenerationSetup, WAVE, addPortSetups, SPHEROID, getUpdateCompBallInfoByPinSize, PIN_GROUP } from '../../../../services/ExtractionPortsHelper';
import designConstructor from '../../../../services/helper/designConstructor';
import { getPowerVoltage, signalGroupSortFun, sortContentFromCompType, sortPinOrPinSetUpFormSignals } from '../../../../services/Rocky/helper/setupData';
import { PROJECT_V2 } from '../../../../constants/projectVersion';
import { getCentricVerificationContent, changeSelectInterfaceInfo } from '../rockySSN/action';
import { PCB_CHANNEL, PCB_CHANNEL_RESULT, PCB_PDN, PCB_PDN_RESULT, SSN_RESULT, PACKAGE_PRE_LAYOUT, BMA_CHANNEL, BMA_CHANNEL_RESULT, PCB_PRE_LAYOUT_RESULT, PACKAGE_PRE_LAYOUT_RESULT } from '../../../../constants/treeConstants';
import { getSSNResultContent } from '../result/ssn/action'
import { ON_DIE_TOUCHSTONE, PCB_PDN_TOUCHSTONE, PCB_TOUCHSTONE } from '../../../../constants/libraryConstants';
import { PCBPDNRow, PackageExtraction, PackagePDNRow, RockyPCBCompRLCPrefixLib, RockyPackageCompRLCPrefixLib } from '../../../../services/Rocky/helper/setupClass';
import { getPackageComponent, getDiePortsByCPM } from '../../../../services/Rocky/helper/packagePDNHelper';
import { getComponents } from '../../../../services/helper/setup/setupData';
import { autoSplitRefeByPower, getPortData } from '../../../../services/helper/portCanvasHelper';
import _ from 'lodash';
import { newNanoId } from '../../../../services/helper/idHelper';
import { BallSize } from '../../../../services/Rocky/helper/ballSize';
import { MULTIPLE_CHANNEL } from '../../../../constants/treeConstants';
import { getMultipleChannelContent } from '../multiple/action'
import { ALL_PINS, BALL_TYPE_NONE, BALL_TYPE_NONE_LIST, USE_BALL_LIST } from '../../../../services/ExtractionPortsHelper/portTableHelper';
import { getChannelJson } from '../../../../services/Rocky/projectCtrl';
import { _getVRM, getAllRockyComponents, getRockyMainPowerNet } from '../../../../services/Rocky/helper/pcbPDNHelper';
import { getPMICPartType } from '../../../../services/helper/componentsHelper/compSettingHelper';
import { checkConnectCompsPinsAndNets } from '../../../../services/Rocky/helper/sevSetupData';
import PackagePCBPdnInfo from '../../../../services/Rocky/SSN/pdnInfo';
import { openTabFooter } from '../../../MonitorStore/action';
import PCBChannelContentConstructor from '../../../../services/Rocky/SSN/pcbChannelInfo';
import { startMergeExtraction } from '../simulation/ssn/action';
import RockySSNChannelInfo from '@/services/Rocky/SSN/channelsInfo';
import { getPdnSimulationErrorCheck, getSignalGroupSimulationErrorCheck, stackupCheck } from '../simulation/saga';
import { updateCompDecapModel } from '../../../../services/helper/decapHelper/MatchModel';
import { ROCKY } from '../../../../constants/pageType';
import RockyChannels from '../../../../services/Rocky/helper/channels';
import { getPCBPrelayoutContent } from '../prelayout/saga';
import bmaChannelsConstructor from '@/services/BMA/constructors/BMAChannelsConstructor';
import { getBMAChannelContent, updateBMAContentLoading, updateDataMenuKey } from '../../../BMA/store/channel/action';
import { getBMAChannelResult } from '../../../BMA/store/result/action';
import { DATA_CONTENT } from '../../../../services/BMA/helper/result';
import auroraDBJson from '../../../../services/Designs/auroraDbData'
import { applyPowerComponentsHelper } from '../../../../services/Rocky/helper/powerComponentsHelper';
import { CYLINDER, NONE } from '../../../../services/helper/ballSize';
import { SOLDER } from '../../../../services/Stackup/Material';
import preLayoutContentConstructor from '../../../../services/Rocky/prelayoutConstructor';
import { AutoGeneratePorts } from '../../../../services/ExtractionPortsHelper';
import { createPackageMultiInterfacePromise } from '../../../../services/Rocky';

const ChipTypes = ['Controller', 'Memory'];
const IBIS_AMI_FILE_MODEL = 'IBIS_AMI_FILE_MODEL', AMI_OBJ_PARAM = 'AMI_OBJ_PARAM', IBIS_ODT = 'IBIS_ODT', CUSTOM_ODT = 'CUSTOM_ODT';
let updateRefTask = false;

export function* getProjectType() {
  const { RockyReducer: { project: { currentProjectId, projectList } } } = yield select();
  const currentProject = projectList.find(item => item.id === currentProjectId);
  return currentProject ? currentProject.type : "";
}

function* getContent(action) {
  const { verificationId, status } = action;
  //update component model and pin table loading
  try {
    yield put(updateComponentLoading(false));
    const response = yield call(getVerificationContentPromise, verificationId);
    if (!response) {
      return;
    }
    let { RockyReducer: { rocky: { currentConfig } } } = yield select();
    let Interfaces = JSON.parse(JSON.stringify(response.interfaces));
    const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;

    //update interface info by default
    Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
    yield call(_getContent, {
      Interfaces,
      response,
      verificationId,
      loadPCB: (!response.version || response.version === "0") ? false : true,
      status
    })
  } catch (error) {
    console.error(error)
  }
}

function vttNetsExist(Interfaces) {
  try {
    return Interfaces[0].Content.VTTNets;
  } catch (error) {
    return false;
  }
}

function initInterfaces(Interfaces, netsList) {
  let save = false;
  let _interfaces = [...Interfaces];
  _interfaces.forEach(item => {
    item.Content.Components.forEach(comp => {
      if (ChipTypes.includes(comp.type)) {
        if (!comp.pkg) {
          save = true;
          comp.pkg = {
            type: 'None'
          }
        }
        //Add powerOff (Compatible with the old version)
        comp.pins.forEach(pin => {
          if (pin.usage === "Driver" && !pin.powerOff) {
            save = true;
            pin.powerOff = "0";
          }
        });
      }
    });

    //v0.0.2 delete Extraction,channel
    if (item.Extraction) {
      delete item.Extraction;
      save = true;
    }

    //v0.0.2 delete Extraction,channel
    if (item.Channel) {
      delete item.Channel;
      save = true;
    }

    //v0.0.2 add VTTNets []
    if (!item.Content.VTTNets) {
      const VTTNets = findVTTNets(item.Content.Components, netsList);
      item.Content.VTTNets = VTTNets ? JSON.parse(JSON.stringify(VTTNets)) : [];
      save = true;
    }
  });
  return { _interfaces, save };
}

function* _getContent(action) {
  let { Interfaces, response, loadPCB, verificationId, status } = action;
  const SETUP = RockySetup;
  const { RockyReducer: { project: { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, extraction, currentProjectId, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, projectVersion, onDieSpiceList }, rocky: { currentConfig } } } = yield select();
  let newInterfaces = [...Interfaces];
  let saveToServer = false;
  const ddrType = yield call(getProjectType);
  if (versionCompareSize(response.version, '0.0.8')) {
    newInterfaces = addPRBSTabs(Interfaces);
    saveToServer = true;
  }
  ////DDR5 DIMM interface: DIMM components add "active" field
  if (DIMM_DDR_TYPES.includes(ddrType) && (versionCompareSize(response.version, '0.0.15') || !judgeDimmActiveExist(newInterfaces))) {
    newInterfaces = addActiveToDIMMComponents(newInterfaces, ddrType);
    saveToServer = true;
  }
  //LPDDR4 interface: If the memory components are greater than one, add "active" field
  if (ddrType === LPDDR4 && versionCompareSize(response.version, '0.0.16') && isByte(response.name)) {
    const info = addActiveToLPDDR4MemoryComponents(newInterfaces, saveToServer);
    newInterfaces = info.newInterfaces;
    saveToServer = info.saveToServer;
  }

  if (ddrType === DDR5_RDIMM && (versionCompareSize(response.version, '0.0.17') || !judgeDimmStdModelExist(newInterfaces))) {
    newInterfaces = yield call(changeStdModelToDimmStdModels, newInterfaces)
    saveToServer = true
  }

  const usageTypes = ['Controller', 'Memory']
  // Device corner
  newInterfaces.forEach(info => {
    const { Content } = info;
    Content.Components.forEach(comp => {
      if (usageTypes.includes(comp.type) && !comp.corner) {
        if (currentConfig.corners && currentConfig.corners.length) {
          // corners   Previous case
          const name = splitComponentName(comp.name)
          let data = currentConfig.corners.find(item => item.component === name);
          if (data && data.corner) {
            comp.corner = data.corner
          }
        } else {
          // Newly created case
          comp.corner = 'typ';
        }
        saveToServer = true;
      }
      //remove clk_adr dimm std models (RDIMM)
      comp.pins.forEach(pin => {
        if (pin.dimmStdModels && pin.dimmStdModels.length > 1 && info.name && info.name.match("CLK")) {
          pin.dimmStdModels = [pin.dimmStdModels[0]]
        }
      })
    });
  })
  let info = SETUP.mergeInterfacesInfo(newInterfaces, response.name, ddrType, projectVersion);

  let pcbInfo = {};

  // get extraction ports data
  //delete ports_generation_setup
  const index = newInterfaces.findIndex(i => portSavePath.find(p => i.name.match(p)));
  const content = newInterfaces[index].Content;
  let { ReferenceNets, Port_setups, Ports_generation_setup, Ports_generate_setup_list, Components } = content;

  //update ports generation setup format
  if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
    Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
    newInterfaces[index].Content.Ports_generate_setup_list = Ports_generate_setup_list;
    saveToServer = true;
  }

  if (content && content.Ports_generation_setup) {
    //delete ports_generation_setup
    delete newInterfaces[index].Content.Ports_generation_setup;
    saveToServer = true;
  }

  const portsExists = versionCompareSize(response.version, '0.0.9') || !ReferenceNets || !Port_setups || !Port_setups.length ? false : true;

  const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId);
  let isPreLayout = vendor === PRELAYOUT ? true : false
  let data = { ReferenceNets, Port_setups, Ports_generate_setup_list }
  if (!isPreLayout && (!vttNetsExist(newInterfaces) || !portsExists || judgeUpdateGapPortSetup(data, extraction))) {
    pcbInfo = yield call(getLayoutPCBInfo, response.designId);
  } else {
    pcbInfo = DesignInfo.getPCBInfo(response.designId);
  }
  if (!isPreLayout) {
    if (!portsExists) {
      data.ReferenceNets = getDefaultReferenceNets(content.PowerNets);
      const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
        components: content.Components,
        signals: content.Signals,
        pcbInfo: pcbInfo,
        referenceNets: data.ReferenceNets,
        designId: response.designId,
        ports_generate_setup_list: data.Ports_generate_setup_list,
        types: ChipTypes,
        extractionType: extraction.channelType
      });
      data.Port_setups = port_setups;
      content.ReferenceNets = data.ReferenceNets;
      content.Port_setups = port_setups;
      content.Ports_generate_setup_list = setupList;
      newInterfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = content;
      saveToServer = true;
    } else {
      // when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
      // re generate port setups by port type is GAP, reference type is All pins
      if (judgeUpdateGapPortSetup(data, extraction)) {
        const updateInfo = updateExtractionGapPortsSetup(data, response.designId);
        data = updateInfo.newData;
        newInterfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = { ...content, ...updateInfo.newData };
        saveToServer = true;
      }
    }

    //update GAP port generate setup for components setup, gap_size
    if (versionCompareSize(response.version, '0.0.11')) {
      newInterfaces = updatePortsComponentsByPortType(newInterfaces, data.Ports_generate_setup_list, response.designId, extraction.channelType);
      saveToServer = true;
    }
  }



  info = { ...info, ...data };
  const netsList = pcbInfo.netsList;

  const init = initInterfaces(newInterfaces, netsList);
  newInterfaces = init._interfaces;
  const save = init.save;

  const rockyInfo = {
    config: response.config,
    Interfaces: newInterfaces,
    info,
    verificationId,
    verificationName: response.name,
    ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
    readyForSim: response.readyForSim ? response.readyForSim : 0,
    designVersion: response.designVersion,
    designId: response.designId,
    version: ROCKY_SETUP_VERSION
  };
  yield put(updateRockyContent(rockyInfo));

  if (status) {//after simulation, get content
    ByteSetupInfo.saveSetupInfo(verificationId, {
      ...response,
      interfaces: newInterfaces,
      ifDoExtraction: rockyInfo.ifDoExtraction,
      readyForSim: rockyInfo.readyForSim,
      version: rockyInfo.version,
      designVersion: rockyInfo.designVersion
    });
    return;
  }
  const { RockyReducer: { project } } = yield select();
  //check library file if exist and update current verification library list
  yield put(getLibraryFileParse());
  let readyForSim = rockyInfo.readyForSim;

  if (loadPCB) {
    let pcbComponentsNets = project.pcbComponentsNets;
    let addPCB = [];
    if (!pcbComponentsNets.includes(response.designId)) {
      addPCB.push(response.designId)
    }
    yield put(saveComponentsNetsInProject(addPCB));
    // error check update readyForSim
    const errorCheck = getErrorCheck(rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, ddrType);
    readyForSim = errorCheck ? 0 : 1;
  }

  ByteSetupInfo.saveSetupInfo(verificationId, {
    ...response,
    interfaces: newInterfaces,
    ifDoExtraction: rockyInfo.ifDoExtraction,
    readyForSim: rockyInfo.readyForSim,
    version: rockyInfo.version,
    designVersion: rockyInfo.designVersion
  });

  if (!loadPCB || readyForSim !== rockyInfo.readyForSim || save || saveToServer) {
    let updateStatus = true;
    if (readyForSim !== rockyInfo.readyForSim) {
      updateStatus = false;
    }
    yield put({ type: SAVE_CONTENT_TO_SERVER, updateStatus });
  }

  if (isRockyPuppeteer()) {
    // Update enableVoltage & modelType
    try {
      const { RockyReducer } = yield select();
      let Interfaces = RockyReducer.rocky.rockyInfo.Interfaces;
      const _Interfaces = yield call(updateSetup, Interfaces);
      yield put(updateInterfaces({ Interfaces: _Interfaces }));
    } catch (error) {
      console.error(error);
      return;
    }
  }
}

// for puppeteer - update modelType, enableVoltage
async function updateSetup(Interfaces) {
  try {
    const files = [...new Set(Interfaces.reduce((prev, cur) => {
      // Filter RLC
      const components = cur.Content.Components.filter(d => ChipTypes.includes(d.type) && d.model && d.model.libraryId);
      return [...prev, ...components.map(item => item.model)]
    }, []).map(item => item.libraryId))];
    const list = files.map(async id => {
      const obj = {
        libraryId: id,
        usage: 'Tx'
      };
      return await getIbisModelList(obj);
    });
    await Promise.all(list);
    return Interfaces.map((_interface) => {
      _interface.Content.Components.map((comp) => {
        if (ChipTypes.includes(comp.type)) {
          comp.pins.map(async (pin) => {
            // Only memory and controller have pin.model
            let model = pin.model;
            if (model) {
              const obj = {
                libraryId: model.libraryId || null,
                usage: pin.usage === 'Driver' ? 'Tx' : 'Rx'
              };
              const ibisModel = await getIbisModelList(obj);
              if (ibisModel && ibisModel.models) {
                const findModel = ibisModel.models.find(item => item.name === model.modelName);
                if (findModel) {
                  model.modelType = findModel.type;
                  model.enableVoltage = exsitEnableVoltage(findModel.type) ? (findModel.enable === 'NA' ? '1' : findModel.enable) : ""
                }
              }
            }
            return pin;
          });
        }
        return comp;
      })
      return _interface;
    });
  } catch (error) {
    console.error(error)
    return Interfaces;
  }
}

export function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([AUTO_SAVE_CONTENT_TO_SERVER, SAVE_CONTENT_TO_SERVER, SAVE_ROCKY_PDN_TO_SERVER, SAVE_ROCKY_PACKAGE_CONTENT_SERVER, SAVE_ROCKY_PACKAGE_PDN_SERVER]);
    yield put(updateSaveStatus("Editing"))
    let delayTime = 3000;
    if (newAction.type === AUTO_SAVE_CONTENT_TO_SERVER || newAction.type === SAVE_CONTENT_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveVerificationContentToServer, newAction.updateStatus);
    } else if (newAction.type === SAVE_ROCKY_PDN_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveRockyPDNContentToServer, newAction);
    } else if (newAction.type === SAVE_ROCKY_PACKAGE_CONTENT_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveRockyPackageContentToServer, newAction);
    } else if (newAction.type === SAVE_ROCKY_PACKAGE_PDN_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveRockyPackagePDNContentToServer, newAction);
    }
  }
}

function* debounce(time, callback, updateStatus) {
  yield delay(time);
  yield call(callback, updateStatus);
  updateRefTask = false
}

export function* saveRockyPackageContentToServer(action) {
  try {
    const { isUpdateRefNets, isClean } = action
    const { RockyReducer: { project: { currentProjectId }, rocky: { rockyPackageInfo } } } = yield select();
    if (!rockyPackageInfo || !Object.keys(rockyPackageInfo).length || !rockyPackageInfo.content || !Object.keys(rockyPackageInfo.content).length) {
      return
    }
    let content = JSON.parse(JSON.stringify(rockyPackageInfo.content));
    const { Signals: signals } = content;
    const { designId } = rockyPackageInfo

    if (isUpdateRefNets) {
      // get Power Nets
      const packageInfo = yield call(findRefNets, { pcbId: designId, signals: signals, isSave: true, channelInfo: { ...rockyPackageInfo, Content: rockyPackageInfo.content } });
      content = packageInfo.Content
    }

    //  Update backend data
    let backSignal = content.Signals ? JSON.parse(JSON.stringify(content.Signals)) : [];
    // If there are no nets, do not save the signal
    backSignal = backSignal.filter(item => item.nets && item.nets.length);
    yield call(updatePkgVerification, { response: rockyPackageInfo, content: { ...content, Signals: backSignal }, currentProjectId })
    // Update front-end data
    if (isClean) {
      yield put(updateRockyPackageLayoutInfo({}));
    } else {
      yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content }));
    }
  } catch (error) {
    console.error(error)
  }
}

export function* saveRockyPackagePDNContentToServer() {
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  if (!packagePDNInfo || !Object.keys(packagePDNInfo).length) {
    return;
  }
  let _packagePDNInfo = JSON.parse(JSON.stringify(packagePDNInfo));

  try {
    //  Update backend data
    yield call(updateRockyPackagePDNInfo, _packagePDNInfo)
    PackagePCBPdnInfo.setInfo(_packagePDNInfo.id, _packagePDNInfo)
  } catch (e) {
    console.error(e)
  }
}

export function* saveVerificationContentToServer(updateStatus) {
  const { RockyReducer: { rocky: { rockyInfo, infoErrorCheck }, project } } = yield select();
  if (!rockyInfo) return;
  yield put(updateSaveStatus("Saving"))
  const { currentProjectId, currentChannelId, ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, extraction, customTouchstoneList, customSpiceList, onDieSpiceList, projectVersion } = project;
  const Interfaces = rockyInfo.Interfaces;
  const verificationId = rockyInfo.verificationId;
  const name = rockyInfo.verificationName;
  let response = null;
  if (!Interfaces || !Interfaces.length) return;

  //DM model modify
  const interfaceType = getInterfaceType(name);
  const projectType = yield call(getProjectType);
  //DDR3/DDR3L/DDR4/DDR5/DDR4L  DM unidirectional
  //LPDDR4 DM  Bidirectional
  //LPDDR5 DQS unidirectional
  if (interfaceType === 'BYTE' && (ddrDMTypes.includes(projectType) || projectType === LPDDR5)) {
    let regWrite = new RegExp(`(Write)`, 'ig');
    let regRead = new RegExp(`(Read)`, 'ig');

    let writeInterface = Interfaces.find(item => item.name.match(regWrite));

    let readIndex = Interfaces.findIndex(item => item.name.match(regRead));

    if (readIndex > -1) {
      Interfaces[readIndex].Content.Components.forEach(comp => {
        let findComp = writeInterface.Content.Components.find(item => item.name === comp.name) || null;
        comp.pins.forEach(pin => {
          const findPin = findComp ? findComp.pins.find(item => item.pin === pin.pin) : null;
          //find DM delete DM read usage
          let DMReg = new RegExp(`^(DM)`, 'ig');
          const words = projectType === LPDDR5 ? pin.signal.match(/(WCK)|(DQS)/g) : pin.signal.match(DMReg);
          if (words && !pin.signal.includes('DQS')) {
            if (comp.type === 'Memory') {
              pin.pinModels = [];
              pin.usage = 'Receiver';
              pin.model = findPin ? findPin.model : pin.model;
            } else if (comp.type === 'Controller') {
              pin.usage = 'Driver';
              pin.model = findPin ? findPin.model : pin.model;
              pin.pinModels = findPin ? findPin.pinModels : pin.pinModels;
              comp.deviceVcc = findComp ? findComp.deviceVcc : "";
            }
          } else if (words && !pin.signal.includes('WCK')) {
            if (comp.type === 'Controller') {
              pin.pinModels = [];
              pin.usage = 'Receiver';
              pin.model = findPin ? findPin.model : pin.model;
            } else if (comp.type === 'Memory') {
              pin.usage = 'Driver';
              pin.model = findPin ? findPin.model : pin.model;
              pin.pinModels = findPin ? findPin.pinModels : pin.pinModels;
              comp.deviceVcc = findComp ? findComp.deviceVcc : "";
            }
          }
        })
      })
    }
  }

  let readyForSim = rockyInfo.readyForSim;
  if (!updateStatus) {
    // error check update readyForSim
    const errorCheck = getErrorCheck(rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, projectType);
    let _infoErrorCheck = infoErrorCheck;
    if (errorCheck) {
      readyForSim = 0;
    } else {
      //update error message
      _infoErrorCheck = _infoErrorCheck.filter(item => (item.verificationId !== verificationId || item.PDNErrorCheck));
      readyForSim = 1;
    }
    yield put(updateErrorCheckList(_infoErrorCheck));
  }

  if (projectVersion === PROJECT_V2 && name.includes("CLK")) {
    for (let interfaceInfo of Interfaces) {
      const _interfaceInfo = JSON.parse(JSON.stringify(interfaceInfo))
      const { name: interfaceName, verificationId } = interfaceInfo;
      const rule = /CLK_(ADR_CMD|ADR|CMD)/g;
      let _name = name.replace(rule, interfaceName);
      delete _interfaceInfo.verificationId;
      const obj = {
        name: _name,
        interfaces: [_interfaceInfo],
        config: rockyInfo.config,
        ifDoExtraction: typeof (rockyInfo.ifDoExtraction) === 'number' ? rockyInfo.ifDoExtraction : 1,
        readyForSim,
        version: ROCKY_SETUP_VERSION,
        designVersion: rockyInfo.designVersion ? rockyInfo.designVersion : "0"
      }
      try {
        response = yield call(updateVerificationContent, { id: verificationId, ...obj });
        if (!response) {
          yield put(updateSaveStatus("Failed"))
        } else {
          yield put(updateSaveStatus("Saved"))
        }
        ByteSetupInfo.saveSetupInfo(verificationId, response);
      } catch (error) {
        console.error(error)
      }
    }
    return
  }
  try {
    Interfaces.forEach(item => {
      item.Content.Components.forEach(comp => {
        if ([RES, CAP, IND].includes(comp.type) && !comp.model) {
          comp.model = { type: 'value' };
        }
      })
    })
  } catch (e) {
    console.error(e)
  }
  const obj = {
    name,
    interfaces: Interfaces,
    config: rockyInfo.config,
    ifDoExtraction: typeof (rockyInfo.ifDoExtraction) === 'number' ? rockyInfo.ifDoExtraction : 1,
    readyForSim,
    version: ROCKY_SETUP_VERSION,
    designVersion: rockyInfo.designVersion ? rockyInfo.designVersion : "0"
  };



  try {
    response = yield call(updateVerificationContent, { id: verificationId, ...obj });
    if (!response) {
      yield put(updateSaveStatus("Failed"))
    } else {
      yield put(updateSaveStatus("Saved"))
    }
    ByteSetupInfo.saveSetupInfo(verificationId, response);
  } catch (error) {
    console.error(error)
  }

  if (isRockyPuppeteer()) {
    console.log(`Finish update ${verificationId}!`);
  }
  //auto get list
  if (response) {
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  }

  return response;
}

function* getDDRType() {
  const { RockyReducer: { project: { currentProjectId, projectList } } } = yield select();
  const find = projectList && projectList.length ? projectList.find(item => item.id === currentProjectId) : null;
  return find ? find.type : null;
}

let autoSaveTask = null;
function* openRockyPage(action) {
  const { pageType, id, status, PDNID } = action;
  const { RockyReducer: { project: { currentChannelId, currentPackageDesignId } } } = yield select();
  if ([PCB, CARD].includes(pageType)) {
    return;
  }

  const findP = yield call(getDDRType);
  switch (pageType) {
    case PACKAGE:
      yield put(updateCurrentPackageId(id));
      return;
    case PCB_PRE_LAYOUT:
    case PACKAGE_PRE_LAYOUT:
      if (pageType === PACKAGE_PRE_LAYOUT) {
        yield put(updateCurrentPackageId(id));
      }
      yield put(getRockyPreLayout({ id, view: pageType }));
      yield put(openPageInfo({ verificationId: id, contentType: pageType }));
      return;
    case RESULT:
      const verifications = channelVerifications.getVerificationsByChannelId(currentChannelId) || [];
      const findVer = verifications.find(item => item.id === id) || {};
      // project reducer
      yield put(openPageInfo({ verificationId: id }));
      // update result info
      yield put(openResultPageInfo({
        verificationId: id,
        verificationSubId: findVer.subId,
        designSubId: findVer.designSubId,
        designID: findVer.designId,
        interfaces: findVer.interfaces,
        channelId: findVer.channelId,
        channelName: findVer.channelName,
        ddrType: findP
      }));
      // get interface log
      yield put(getInterfaceMonitor(id));
      return;
    case VERIFICATION:
    case ROCKY_PDN:
      let findV = null;
      if (autoSaveTask) {
        yield cancel(autoSaveTask);
        yield call(saveVerificationContentToServer);
        yield call(saveRockyPDNContentToServer);
      }

      //clear prev open verification setup content
      yield put(cleanSetupInfo(true));
      const _verifications = channelVerifications.getVerificationsByChannelId(currentChannelId) || [];
      findV = _verifications.find(item => item.id === id);
      if (!findV) return;
      if (pageType === VERIFICATION) {
        if (!status) {
          yield put(getInterfaceMonitor(id));
        }
        yield put(openPageInfo({ verificationId: id, contentType: VERIFICATION }));
        yield put(getVerificationContent(id));
        // update result info
        yield put(openResultPageInfo({
          verificationId: id,
          verificationSubId: findV.subId,
          designSubId: findV.designSubId,
          designID: findV.designId,
          interfaces: findV.interfaces,
          channelId: findV.channelId,
          channelName: findV.channelName,
          ddrType: findP
        }));
        autoSaveTask = yield fork(autoSave);
        yield put(updateRockyPDNContent(null));
        return
      }

      if (pageType === ROCKY_PDN) {
        yield put(openPageInfo({ verificationId: id, PDNID, contentType: ROCKY_PDN }));
        yield put(getChannelPDNContent(id, PDNID));
        // update result info
        // TODO
        yield put(openResultPageInfo({
          verificationId: null,
          verificationSubId: null,
          designSubId: null,
          designID: null,
          interfaces: [],
          channelId: null,
          channelName: null,
          ddrType: null
        }));
        autoSaveTask = yield fork(autoSave);
        yield put(updateRockyContent({}));
      }
      return;
    case PACKAGE_VERIFICATION:
      let timeInterVal = getCountTime();
      let isUpdateRefNets = timeInterVal ? true : false;
      closeCountTime();
      if (updateRefTask) {
        isUpdateRefNets = true;
      }
      timeInterVal = null;
      if (autoSaveTask) {
        yield cancel(autoSaveTask);
        yield call(saveRockyPackagePDNContentToServer)
      }
      yield call(saveRockyPackageContentToServer, { isUpdateRefNets });
      yield put(openPageInfo({ verificationId: id, PDNID, contentType: PACKAGE_VERIFICATION }));
      yield put(getPackageLayoutContent(id));
      let info = packageVerificationConstructor.getPackageVerifications(currentPackageDesignId, id);
      yield put(openPackageResultPageInfo({
        verificationId: id,
        designID: info.designId,
        name: info.name,
        packageVerificationId: info.verificationId,
        channelName: info.channelName,
        ddrType: findP,
        packageVerificationSubId: info.verificationSubId
      }));
      yield put(getInterfaceMonitor(info.verificationId));
      autoSaveTask = yield fork(autoSave);
      return;
    case PACKAGE_PDN:
      if (autoSaveTask) {
        yield cancel(autoSaveTask);
        yield call(saveRockyPackagePDNContentToServer)
      }
      yield put(openPageInfo({ verificationId: id, contentType: PACKAGE_PDN }));
      yield fork(getPackagePDNContent, id)
      yield put(openPackageResultPageInfo({
        verificationId: null,
        designID: null,
        name: null,
        packageVerificationId: null,
        channelName: null,
        ddrType: null,
        packageVerificationSubId: null
      }));
      autoSaveTask = yield fork(autoSave);
      yield put(updateRockyPackageLayoutInfo({}));
      return;
    case PACKAGE_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: PACKAGE_VERIFICATION }));
      const _findV = packageVerificationConstructor.getPackageVerifications(currentPackageDesignId, id);

      yield put(openPackageResultPageInfo({
        verificationId: id,
        designID: _findV.designId,
        name: _findV.name,
        packageVerificationId: _findV.verificationId,
        channelName: _findV.channelName,
        ddrType: findP,
        packageVerificationSubId: _findV.verificationSubId,
      }));
      return;
    case PACKAGE_PDN_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: PACKAGE_PDN_RESULT }));
      const _findPDN = packageVerificationConstructor.getPackagePDN(currentPackageDesignId);

      yield put(openPackageResultPageInfo({
        verificationId: id,
        designID: _findPDN.designId,
        name: _findPDN.name,
        packageVerificationId: _findPDN.verificationId,
        channelName: _findPDN.channelName,
        ddrType: findP,
        packageVerificationSubId: _findPDN.verificationSubId,
      }));
      return;
    case CARD_VERIFICATION:
      yield call(saveCardContentToServer);
      yield put(openPageInfo({ verificationId: id, PDNID: null, contentType: CARD_VERIFICATION }));
      yield put(getCardVerificationContent(id));
      //get modeling progress and log
      yield put(getInterfaceMonitor(id));
      return;
    case CARD_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: CARD_VERIFICATION }));
      const _findC = cardChannelsConstructor.getVerification(id) || {};
      yield put(openCardResultPageInfo({
        verificationId: id,
        verificationSubId: _findC.subId,
        designSubId: _findC.designSubId,
        designID: _findC.designId,
        interfaces: _findC.interfaces,
        channelId: _findC.channelId,
        channelName: _findC.channelName,
        ddrType: findP
      }));
      return;
    case SSN_VERIFICATION:
      //clear prev open verification setup content
      if (autoSaveTask) {
        yield cancel(autoSaveTask);
        yield call(saveRockyPackagePDNContentToServer)
      }
      yield put(cleanSetupInfo(true));
      yield put(changeSelectInterfaceInfo({}))
      yield put(openPageInfo({ verificationId: id, contentType: SSN_VERIFICATION, currentChannelId: id }))
      yield put(getCentricVerificationContent(id));
      yield call(checkChannelPowerComponents);
      autoSaveTask = yield fork(autoSave);
      return;
    case SSN_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: SSN_RESULT, currentChannelId: id }));
      yield put(getSSNResultContent(id));
      return
    case MULTIPLE_CHANNEL:
      yield put(openPageInfo({ verificationId: id, contentType: MULTIPLE_CHANNEL }));
      yield put(getMultipleChannelContent(id))
      yield put(cleanResultPageInfo())
      return;
    case PCB_CHANNEL:
      yield put(openPageInfo({ verificationId: id, contentType: PCB_CHANNEL, currentChannelId: id }));
      yield call(getPCBChannelContent, { id })
      yield put(cleanResultPageInfo())
      return;
    case PCB_CHANNEL_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: PCB_CHANNEL_RESULT, currentChannelId: id }));
      let res = yield call(PCBChannelContentConstructor.getPcbChannelInfo, id)
      if (res) {
        yield put(openResultPageInfo({
          verificationId: res.verificationId,
          designID: res.designId,
          name: res.channelName,
          channelName: res.channelName,
          ddrType: findP,
          channelId: id,
          verificationSubId: res.verificationSubId
        }));
      }
      return;
    case PCB_PDN:
      if (autoSaveTask) {
        yield cancel(autoSaveTask);
        yield call(saveRockyPackagePDNContentToServer)
      }
      yield put(openPageInfo({ verificationId: id, contentType: PCB_PDN }));
      yield fork(getPCBPDNContent, id)
      autoSaveTask = yield fork(autoSave);
      return;
    case PCB_PDN_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: PCB_PDN_RESULT }));
      try {
        // const res = yield call(getRockyPackagePDNInfo, id);
        const res = yield call(PackagePCBPdnInfo.getPCBPackagePdnInfo, id)

        yield put(openPackageResultPageInfo({
          verificationId: id,
          designID: res.designId,
          name: res.name,
          packageVerificationId: res.verificationId,
          channelName: res.name,
          ddrType: findP,
          packageVerificationSubId: res.verificationId,
        }));
      } catch (e) {
        console.error(e)
        yield put(openPackageResultPageInfo({
          verificationId: id,
          designID: null,
          name: null,
          packageVerificationId: null,
          channelName: null,
          ddrType: findP,
          packageVerificationSubId: null,
        }));
      }
      return;
    case BMA_CHANNEL:
      const { RockyReducer: { project: { projectList } } } = yield select();
      const currentProjectInfo = projectList.find(item => item.id === id);
      if (currentProjectInfo && currentProjectInfo.id) {
        yield put(saveOpenProjectInfo({
          id,
          name: currentProjectInfo.name,
          version: currentProjectInfo.projectVersion
        }));
      }

      const bmaInfo = yield call(bmaChannelsConstructor.getFirstBMAInfo, id);
      if (bmaInfo && bmaInfo.id) {
        yield put(openPageInfo({ verificationId: bmaInfo.id, contentType: BMA_CHANNEL }));
        yield put(updateDataMenuKey(DATA_CONTENT))
        yield put(updateBMAContentLoading(true))
        yield put(getBMAChannelContent(id, bmaInfo.id))
        yield put(cleanResultPageInfo())
      }
      return;
    case BMA_CHANNEL_RESULT:
      yield put(closeTabFooter())
      let list = [BMA_CHANNEL_RESULT];
      yield put(updateViewList(list))
      yield put(getBMAChannelResult(id))
      return;
    case PCB_PRE_LAYOUT_RESULT:
    case PACKAGE_PRE_LAYOUT_RESULT:
      yield put(openPageInfo({ verificationId: id, contentType: pageType }));
      const preLayoutInfo = yield call(preLayoutContentConstructor.getPreLayoutInfo, id);
      yield put(openResultPageInfo({
        verificationId: preLayoutInfo.verificationId,
        designID: preLayoutInfo.id,
        name: preLayoutInfo.name,
        channelName: preLayoutInfo.name,
        ddrType: findP,
        channelId: preLayoutInfo.channelId,
        verificationSubId: preLayoutInfo.verificationId
      }));
      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: preLayoutInfo ? preLayoutInfo.name : null,
        currentVerificationId: preLayoutInfo.verificationId,
        channelId: preLayoutInfo.channelId,
        dataType: preLayoutInfo.type === PCB ? PCB_PRE_LAYOUT : PACKAGE_PRE_LAYOUT,
      }))
      yield put(getInterfaceMonitor(preLayoutInfo.verificationId))
      return;
    default: return;
  }
}

function* saveVerification(action) {
  const { verificationIds, verificationId, options } = action;
  //save current verification json
  let _verificationIds = [...verificationIds];
  const response = yield call(saveVerificationContentToServer);
  if (response) {
    //check verification status
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status === VERIFY_RUNNING || promise.status === WAITING) {
        //running or waiting return
        _verificationIds = _verificationIds.filter(item => item !== verificationId);
      }
    }
    if (!_verificationIds.length) {
      return;
    }
    yield put(updateStimulationMessage(verificationId));
    yield put(startRockyVerification(_verificationIds, verificationId, undefined, undefined, undefined, options));
  }
}

function* savePackageVerification(action) {
  const { verificationIds, verificationId } = action;
  //save current verification json
  let _verificationIds = [...verificationIds];

  let timeInterVal = getCountTime();
  let isUpdateRefNets = timeInterVal ? true : false;
  //clear count update reference nets time
  closeCountTime();
  timeInterVal = null;
  if (updateRefTask) {
    isUpdateRefNets = true;
  }

  //check verification status
  yield call(saveRockyPackageContentToServer, { isUpdateRefNets });
  const response = yield call(getPackageVerificationInfoPromise, verificationId);
  if (response && response.status) {
    if (response.status === VERIFY_RUNNING || response.status === WAITING) {
      //running or waiting return
      _verificationIds = _verificationIds.filter(item => item.id !== verificationId);
    }
  }
  if (!_verificationIds.length) {
    return;
  }
  yield put(startRockyPackageVerification(_verificationIds, verificationId));
}

function* updateInterface(action) {
  const { Interfaces, ifDoExtraction, contentDTO, contentType } = action;
  const { RockyReducer: { rocky, project: { projectVersion } } } = yield select();
  if (contentType === PCB_CHANNEL) {
    let pcbChannelInfo = { ...rocky.pcbChannelInfo };
    let _content = { ...pcbChannelInfo.contentDTO, ...contentDTO };
    const channelInfo = {
      ...pcbChannelInfo,
      contentDTO: _content,
      version: ROCKY_SETUP_VERSION
    }
    PCBChannelContentConstructor.setInfo(pcbChannelInfo.id, channelInfo)
    yield call(updatePCBChannelContentPromise, { ...channelInfo })
    yield put(updatePCBChannelContent({ ...channelInfo }));
  } else {
    let rockyInfo = { ...rocky.rockyInfo };
    const SETUP = RockySetup;
    let info = SETUP.mergeInterfacesInfo(Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
    yield put(updateRockyContentInfo({ ...info }));
    if (typeof (ifDoExtraction) === 'number') {
      yield put(updateRockyInterfaces({ Interfaces, ifDoExtraction }));
    } else {
      yield put(updateRockyInterfaces({ Interfaces }));
    }
    yield put({ type: SAVE_CONTENT_TO_SERVER });
  }
}

function* updateSignalName(action) {
  const { name, prevName } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = rockyInfo.Interfaces;
  const verificationName = rockyInfo.verificationName;
  _Interfaces.forEach(info => {
    let signalIndex = info.Content.Signals.findIndex(item => item.name === prevName);

    if (signalIndex > -1) {
      info.Content.Signals[signalIndex].name = name;
      for (let comp of info.Content.Components) {
        for (let pin of comp.pins) {
          if (pin.signal === prevName) {
            pin.signal = name;
          }
        }
      }
    }
  });

  const SETUP = RockySetup;
  // if(projectVersion === PROJECT_V2 && verificationName.includes("CLK")){}
  const contentInfo = SETUP.mergeInterfacesInfo(_Interfaces, verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...contentInfo }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

const RLCType = ['RLC', 'Cap', 'Ind', 'Res'];
function* updateSignalNets(action) {
  //Temporarily not supported
  const { nets, signalName } = action;
  const { RockyReducer: { rocky, project: { projectVersion } } } = yield select();
  const { rockyInfo } = rocky;
  const pcbId = rockyInfo && rockyInfo.designId;
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  const { netsList, layers } = pcbInfo;
  let _Interfaces = [...rockyInfo.Interfaces];
  if (_Interfaces && _Interfaces.length > 0) {

    let currentInterface = JSON.parse(JSON.stringify(_Interfaces[0]));
    let signals = [...currentInterface.Content.Signals],
      editedSignalIndex = signals.findIndex(item => item.name === signalName);
    //Current edit signal
    const currentSignal = signals[editedSignalIndex];
    // All exsit signal net name
    let allSignalNetNameList = [];
    signals.forEach(item => allSignalNetNameList.push(...item.nets));

    let existNets = [];
    let components = [...currentInterface.Content.Components];
    let powerNets = [...currentInterface.Content.PowerNets];
    const prevNets = currentSignal.nets;

    let prevPowerNets = [];
    for (let comp of components) {
      if (comp.type !== 'Res') {
        continue;
      };
      for (let pin of comp.pins) {
        if (!pin.signal) {
          prevPowerNets.push(pin.net);
        }
      }
    };
    prevPowerNets = [...new Set(prevPowerNets)];

    // 1. Delete the components connected with deletedNets
    if (prevNets.length > 0) {
      // 1.Delete component
      // Delete components which are only connected with the deleted nets or delete pinModel
      const deletedNets = prevNets.filter(net => !nets.includes(net));
      if (deletedNets.length > 0) {
        // { netList, pcbNetsList, layers, pcbId }
        const deletedComps = getComponentsWithNetList({ netList: deletedNets, pcbNetsList: netsList, layers, pcbId });
        const update = deleteCompsConnectWithNets({ signalName, deletedComps, components })
        components = update.components;
      }
      // Check whether the new add nets is selected by other signals.
      const newAddNets = nets.filter(net => !prevNets.includes(net));
      existNets = checkExistNets(newAddNets, allSignalNetNameList);
      if (existNets && existNets.length > 0) {
        message.error('Net(s) : ' + existNets.join(',') + ' has been selected!');
      }
    } else {
      // Check whether the net is selected by other signals.
      existNets = checkExistNets(nets, allSignalNetNameList);
      if (existNets && existNets.length > 0) {
        message.error('Net(s) : ' + existNets.join(',') + ' has been selected!');
      }
    }

    // 2. Add nets, filter exist nets
    currentSignal.nets = nets.filter(net => !existNets.includes(net));
    if (editedSignalIndex > -1) {
      signals[editedSignalIndex] = currentSignal;
    } else {
      signals.push(currentSignal);
    }

    // 3. Add components - RLCCONComponent, BasicComponent()
    let existComponentsName = components.map(component => component.name);
    // Get components with add nets
    const addNets = nets.filter(net => !prevNets.includes(net) && !existNets.includes(net));
    // { netList, pcbNetsList, layers, pcbId }
    const componentsList = getComponentsWithNetList({ netList: addNets, pcbNetsList: netsList, layers, pcbId });
    for (const comp of componentsList) {
      const { pin, name, net, value, type, part } = comp;
      const _index = existComponentsName.indexOf(name);
      if (_index < 0) {
        // Not exist component
        let newComp;
        if (RLCType.includes(type)) {
          let _value = value;
          _value = checkRLCValue(value);
          // Cap 
          if (type === 'Cap') {
            _value = {
              r: "0",
              l: "0",
              c: _value || "0"
            }
          }
          newComp = new RLCCONComponent({ name, type, value: _value });
        } else if (ChipTypes.includes(type)) {
          newComp = new ICComponent({ name, type });
        } else {
          newComp = new BasicComponent({ name, type });
        }
        if (ChipTypes.includes(type)) {
          newComp.pins = [new ICCompModel({ pin, net, signal: signalName })];
        } else {
          newComp.pins = [new BasicCompModel({ pin, net, signal: signalName })];
        }
        newComp.part = part;
        if (type !== 'Connector' && newComp.pkg) {
          delete newComp.pkg;
        }
        components.push(newComp);
        // Update components name list
        existComponentsName.push(name);
      } else {
        // Exsit component
        if (ChipTypes.includes(components[_index].type)) {
          components[_index].pins.push(new ICCompModel({ pin, net, signal: signalName }));
        } else {
          components[_index].pins.push(new BasicCompModel({ pin, net, signal: signalName }));
        }
      }
    };

    const _resFilter = components.filter(item => item.type === 'Res');
    const findPowerByRes = findPowerNetsByRes(_resFilter, netsList);
    const { comps } = findPowerByRes;
    comps.forEach(comp => {
      const index = components.findIndex(item => item.name === comp.name);
      if (index > -1) {
        components[index] = { ...comp };
      }
    });

    let newPowerNets = [];
    for (let comp of components) {
      if (comp.type !== 'Res') {
        continue;
      };
      for (let pin of comp.pins) {
        // power,ground nets
        if (!pin.signal) {
          newPowerNets.push(pin.net);
        }
      }
    };
    let updatePowerNets = powerNets.filter(item => !prevPowerNets.includes(item.name));
    newPowerNets.forEach(net => {
      const find = updatePowerNets.find(item => item.name === net)
      if (!find) {
        updatePowerNets.push({ name: net, value: "" })
      }
    });
    const autoGND = netsList.filter(item => item.mName.match(/gnd/ig));
    if (autoGND.length > 0) {
      const findGND = autoGND.find(item => item.mName === 'GND')
      if (findGND) {
        const find = updatePowerNets.find(item => item.name === 'GND');
        if (!find) {
          updatePowerNets.push({ name: 'GND', value: "0" });
        }
      } else {
        let via = 0, gndNet = null;
        for (let net of autoGND) {
          if (net.mViaList.length > via) {
            gndNet = net.mName;
            via = net.mViaList.length;
          }
        };
        const find = updatePowerNets.find(item => item.name === gndNet);
        if (!find) {
          updatePowerNets.push({ name: gndNet, value: "0" });
        }
      }
    };

    let signalNets = [];
    signals.forEach(item => {
      if (item.nets && item.nets.length > 0) {
        signalNets = [...signalNets, ...item.nets]
      }
    })
    //Filter power nets (not includes signal nets)
    updatePowerNets = updatePowerNets.filter(item => !signalNets.includes(item.name));
    updatePowerNets.forEach(item => {
      const find = powerNets.find(net => net.name === item.name);
      if (find) {
        item.value = find.value;
      }
    })
    const _powerNets = updatePowerNets.map(item => item.name);
    const newPowerComps = getPowerComponents({ nets: _powerNets, pcbNetsList: netsList, layers, pcbId });

    _Interfaces.forEach(item => {
      item.Content.PowerComponents = [...newPowerComps.Components];
      item.Content.Signals = [...signals];
      item.Content.Components = [...components];
      item.Content.PowerNets = [...updatePowerNets];
    })
  }

  // Update info
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces, ifDoExtraction: 1 }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* delSignal(action) {
  const { name } = action;
  const { RockyReducer: { rocky, project: { projectVersion } } } = yield select();
  let Interfaces = [...rocky.rockyInfo.Interfaces];
  let rockyInfo = { ...rocky.rockyInfo };
  Interfaces.forEach(info => {
    let signals = [...info.Content.Signals];
    let components = [...info.Content.Components];
    const pcbInfo = DesignInfo.getPCBInfo(rockyInfo.designId);
    const { netsList, layers } = pcbInfo;
    const signal = signals.find(item => item.name === name);
    if (signal && signal.nets && signal.nets.length > 0) {
      const nets = signal.nets;
      const deletedComps = getComponentsWithNetList({ netList: nets, pcbNetsList: netsList, layers, pcbId: rockyInfo.designId });
      const update = deleteCompsConnectWithNets({ signalName: name, deletedComps, components });
      info.Content.Components = update.components;
      info.Content.Signals = signals.filter(item => item.name !== name);
    } else {
      info.Content.Signals = signals.filter(item => item.name !== name);
    }
  });

  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: Interfaces, ifDoExtraction: 1 }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* addPowerGNDNets() {
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  if (_Interfaces.length > 0) {
    _Interfaces.forEach(item => {
      item.Content.PowerNets.push(new SourceNets());
    });
    const SETUP = RockySetup;
    let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
    yield put(updateRockyContentInfo({ ...info }));
    yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
    yield put({ type: SAVE_CONTENT_TO_SERVER });
  }
};

function* powerNetsSelection(action) {
  const { name, prevName } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  const pcbId = rockyInfo.designId;
  if (_Interfaces.length > 0) {

    let PowerNets = [..._Interfaces[0].Content.PowerNets];

    if (name) {
      const netIndex = PowerNets.findIndex(item => item.name === name);
      if (netIndex > -1) {
        message.error(`Net ${name} has been selected!`);
        return;
      }
    }
    if (prevName) {
      const netIndex = PowerNets.findIndex(item => item.name === prevName);
      PowerNets[netIndex].name = name;
    } else {
      const _netIndex = PowerNets.findIndex(item => item.name === '');
      PowerNets[_netIndex].name = name;
    };
    const nets = PowerNets.map(item => item.name);
    const pcbInfo = DesignInfo.getPCBInfo(pcbId);
    const { netsList, layers } = pcbInfo;
    const newPowerComps = getPowerComponents({ nets, pcbNetsList: netsList, layers, pcbId });
    _Interfaces.forEach(item => {
      item.Content.PowerComponents = [...newPowerComps.Components];
      item.Content.PowerNets = [...PowerNets];
    })
  }
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces, ifDoExtraction: 1 }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* updateExtraction(action) {
  let { extraction } = action;
  const { RockyReducer: { project: { projectList, currentProjectId, projectVersion }, rocky: { rockyInfo } } } = yield select();
  const currentProject = projectList.find(item => item.id === currentProjectId);
  let projectType = currentProject ? currentProject.type : "";
  let _Interfaces = [...rockyInfo.Interfaces];

  if (!_Interfaces.length) return;
  if (!extraction) {
    extraction = new Extraction(projectType, null, projectVersion);
  }
  _Interfaces.forEach(item => {
    if (item.Content) {
      item.Content.Extraction = judgeExtraction(extraction, projectType);
    }
  })
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces, ifDoExtraction: 1 }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* saveModel(action) {
  const { record: { component, pin, libType, usage, signal, modelType, rankId,
    amiModel, amiModelUse, amiODTValue, ifIbisAdvancedOpen, ibisAdvancedOptions, amiParameters },
    model, deviceVcc = "", pinModelsInfo, applyAll, applyAllMemory, pinsModelList } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = rockyInfo && rockyInfo.Interfaces ? [...rockyInfo.Interfaces] : [];
  //return;
  const projectType = yield call(getProjectType)
  const interfaceType = rockyInfo ? getInterfaceType(rockyInfo.verificationName) : '';
  const isFolderSpice = libType !== "IBIA_AMI" && pinsModelList && pinsModelList.length;
  const powerVoltage = getPowerVoltage(projectType);
  const _ibisAdvancedOptions = {
    delayRise: {
      value: ibisAdvancedOptions && ibisAdvancedOptions.delayRise ? ibisAdvancedOptions.delayRise.value : '',
      unit: ibisAdvancedOptions && ibisAdvancedOptions.delayRise ? ibisAdvancedOptions.delayRise.unit : 'ps',
      isDefault: ibisAdvancedOptions && ibisAdvancedOptions.delayRise ? ibisAdvancedOptions.delayRise.isDefault : true
    },
    delayFall: {
      value: ibisAdvancedOptions && ibisAdvancedOptions.delayFall ? ibisAdvancedOptions.delayFall.value : '',
      unit: ibisAdvancedOptions && ibisAdvancedOptions.delayFall ? ibisAdvancedOptions.delayFall.unit : 'ps',
      isDefault: ibisAdvancedOptions && ibisAdvancedOptions.delayFall ? ibisAdvancedOptions.delayFall.isDefault : true
    },
    tailsFall: {
      value: ibisAdvancedOptions && ibisAdvancedOptions.tailsFall ? ibisAdvancedOptions.tailsFall.value : '',
      unit: ibisAdvancedOptions && ibisAdvancedOptions.tailsFall ? ibisAdvancedOptions.tailsFall.unit : 'ps',
      isDefault: ibisAdvancedOptions && ibisAdvancedOptions.tailsFall ? ibisAdvancedOptions.tailsFall.isDefault : true
    },
    tailsRise: {
      value: ibisAdvancedOptions && ibisAdvancedOptions.tailsRise ? ibisAdvancedOptions.tailsRise.value : '',
      unit: ibisAdvancedOptions && ibisAdvancedOptions.tailsRise ? ibisAdvancedOptions.tailsRise.unit : 'ps',
      isDefault: ibisAdvancedOptions && ibisAdvancedOptions.tailsRise ? ibisAdvancedOptions.tailsRise.isDefault : true
    },
  };
  _Interfaces.forEach(info => {
    let components = [...info.Content.Components];
    components.forEach(comp => {
      //find current component
      if ((comp.name === component) || (applyAllMemory && interfaceType === 'CLK' && comp.type === "Memory")) {
        comp.pins.forEach(pinItem => {
          if (applyAll) {//Apply to all pins of same component
            if (signal && checkSignalName(signal, pinItem.signal, info.name)) {
              if (pinItem.usage === usage
                && !(projectType === DDR5_SODIMM && ["CLKP1", "CLKN1"].includes(pinItem.signal) && comp.type === "Memory")) {
                //sodimm CLKP/CLKN only support set standby model
                comp.deviceVcc = deviceVcc;
                if (amiModel === IBIS_AMI_FILE_MODEL) {
                  pinItem.amiModel = '0'
                } else if (amiModel === AMI_OBJ_PARAM) {
                  pinItem.amiModel = '1'
                } else {
                  pinItem.amiModel = ''
                }
                // amiModel   '0': IBIS_AMI_FILE_MODEL, '1': AMI_OBJ_PARAM
                if (amiModel === AMI_OBJ_PARAM) {
                  pinItem.amiModelUse = amiModelUse === IBIS_ODT ? '0' : amiModelUse === CUSTOM_ODT ? '1' : '';
                } else {
                  pinItem.amiModelUse = '';
                }
                //amiModelUse '0': Use V/I table in .ibs , '1': Use custom ODT
                if (amiModelUse === CUSTOM_ODT) {
                  pinItem.amiODTValue = amiODTValue;
                } else {
                  pinItem.amiODTValue = '';
                }
                pinItem.amiModelInfo = {
                  amiParameters: amiParameters || []
                }
                // ibis advanced options
                pinItem.ifIbisAdvancedOpen = libType === "IBIS" ? ifIbisAdvancedOpen || '0' : '0';
                pinItem.ibisAdvancedOptions = _ibisAdvancedOptions;
                //update model
                if (modelType !== 'Standby') {
                  if (isFolderSpice) {
                    pinItem.model = getPinSpiceModel({
                      pinsModelList,
                      comp,
                      component,
                      pin,
                      pinItem,
                      interfaceType
                    })
                    comp.deviceVcc = powerVoltage;
                  } else {
                    pinItem.model = new Model({ ...model, libType });
                  }
                }
                //update pinModels
                if (pinModelsInfo && usage === 'Driver') {
                  const { pu, pd, pinModels } = pinModelsInfo;
                  let _pinModels = pinModels;
                  const findPU = _pinModels.findIndex(item => item.pinName === 'nd_pu');
                  if (findPU > -1) {
                    _pinModels[findPU].voltage = pu;
                  };
                  const findPD = _pinModels.findIndex(item => item.pinName === 'nd_pd');
                  if (findPU > -1) {
                    _pinModels[findPD].voltage = pd || "0";
                  }

                  const findIN = _pinModels.findIndex(item => item.pinName === 'nd_in');
                  const prevFindIN = pinItem.pinModels ? pinItem.pinModels.findIndex(item => item.pinName === 'nd_in') : -1;
                  if (prevFindIN > -1 && findIN > -1) {
                    _pinModels[findIN] = pinItem.pinModels[prevFindIN];
                  } else if (findIN > -1) {
                    _pinModels[findIN].type = "";
                    _pinModels[findIN].stimulus = "";
                  }

                  pinItem.pinModels = JSON.parse(JSON.stringify(_pinModels));
                }
              }
              if (modelType === 'Standby') {
                if (RDIMM_TYPES.includes(projectType)) {
                  if (!pinItem.dimmStdModels) {
                    pinItem.dimmStdModels = []
                  }
                  if (pinItem.dimmStdModels && (!pinItem.dimmStdModels[0] || !pinItem.dimmStdModels[0].rankId)) { //rdimm case and not choose dimm
                    pinItem.dimmStdModels[0] = { ...new Model({ ...model, libType }), rankId }
                  } else if (pinItem.dimmStdModels) {  //rdimm case and choose dimm
                    pinItem.dimmStdModels = pinItem.dimmStdModels.map(item => {
                      return {
                        rankId: item.rankId,
                        ...new Model({ ...model, libType })
                      }
                    })
                  }
                } else {
                  //sodimm CLKP/CLKN not have std model 
                  if (!(projectType === DDR5_SODIMM && ["CLKP", "CLKN"].includes(pinItem.signal))) {
                    pinItem.stdModel = new Model({ ...model, libType });
                  }
                }
              }
            }
            if (!pinItem.model || !pinItem.model.libType || pinItem.model.libType !== 'IBIS_AMI') {
              pinItem.amiModel = ''
              pinItem.amiModelUse = ''
              pinItem.amiODTValue = ''
              pinItem.amiModelInfo = {
                amiParameters: []
              }
            }
          } else {
            //find current pin and usage === pinItem.usage(Driver/Receiver)
            const pinList = pinsModelList && pinsModelList.length ? pinsModelList.filter(item => item.component === component).map(item => item.pin) : []
            if (pinItem.pin === pin || pinList.includes(pinItem.pin)) {
              if (pinItem.usage === usage) {
                comp.deviceVcc = deviceVcc;
                if (amiModel === IBIS_AMI_FILE_MODEL) {
                  pinItem.amiModel = '0'
                } else if (amiModel === AMI_OBJ_PARAM) {
                  pinItem.amiModel = '1'
                } else {
                  pinItem.amiModel = ''
                }
                // amiModel   '0': IBIS_AMI_FILE_MODEL, '1': AMI_OBJ_PARAM
                if (amiModel === AMI_OBJ_PARAM) {
                  pinItem.amiModelUse = amiModelUse === IBIS_ODT ? '0' : amiModelUse === CUSTOM_ODT ? '1' : '';
                } else {
                  pinItem.amiModelUse = '';
                }
                //amiModelUse '0': Use V/I table in .ibs , '1': Use custom ODT
                if (amiModelUse === CUSTOM_ODT) {
                  pinItem.amiODTValue = amiODTValue;
                } else {
                  pinItem.amiODTValue = '';
                }
                pinItem.amiModelInfo = {
                  amiParameters: amiParameters || []
                }
                // advanced options
                pinItem.ifIbisAdvancedOpen = libType === "IBIS" ? ifIbisAdvancedOpen || '0' : '0';
                pinItem.ibisAdvancedOptions = _ibisAdvancedOptions;
                //update model
                if (modelType !== 'Standby') {
                  if (isFolderSpice) {
                    pinItem.model = getPinSpiceModel({
                      pinsModelList,
                      comp,
                      component,
                      pin,
                      pinItem
                    })
                    comp.deviceVcc = powerVoltage;
                  } else {
                    pinItem.model = new Model({ ...model, libType });
                  }
                }
                //update pinModels
                if (pinModelsInfo && usage === 'Driver') {
                  const { pu, pd, pinModels } = pinModelsInfo;
                  let _pinModels = pinModels;
                  const findPU = _pinModels.findIndex(item => item.pinName === 'nd_pu');
                  if (findPU > -1) {
                    _pinModels[findPU].voltage = pu;
                  };
                  const findPD = _pinModels.findIndex(item => item.pinName === 'nd_pd');
                  if (findPU > -1) {
                    _pinModels[findPD].voltage = pd || "0";
                  }
                  pinItem.pinModels = JSON.parse(JSON.stringify(_pinModels));
                }
              }

              if (modelType === 'Standby') {
                //rdimm case
                if (RDIMM_TYPES.includes(projectType)) {
                  if (pinItem.dimmStdModels && (!pinItem.dimmStdModels[0] || !pinItem.dimmStdModels[0].rankId)) { //rdimm case and not choose dimm
                    pinItem.dimmStdModels[0] = { ...new Model({ ...model, libType }), rankId }
                  } else if (pinItem.dimmStdModels) {  //rdimm case and choose dimm
                    const index = pinItem.dimmStdModels.findIndex(item => item.rankId === rankId)
                    pinItem.dimmStdModels[index] = { ...new Model({ ...model, libType }), rankId }
                  }
                } else {
                  pinItem.stdModel = new Model({ ...model, libType });
                }
              }
            }
            if (!pinItem.model || !pinItem.model.libType || pinItem.model.libType !== 'IBIS_AMI') {
              pinItem.amiModel = ''
              pinItem.amiModelUse = ''
              pinItem.amiODTValue = ''
              pinItem.amiModelInfo = {
                amiParameters: []
              }
            }
          }
        })
      }
    })
    info.Content.Components = [...components];
  })
  const SETUP = RockySetup;
  // _Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* savePowerOffMode(action) {
  const { record: { component, signal, pin, net, usage }, powerOff, applyAll, applyAllMemory } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  const interfaceType = rockyInfo ? getInterfaceType(rockyInfo.verificationName) : '';
  _Interfaces.forEach(info => {
    info.Content.Components.forEach(comp => {
      if (comp.name === component || (applyAllMemory && interfaceType === 'CLK' && comp.type === "Memory")) {
        if (applyAll) {//Apply to all pins of same component
          comp.pins.forEach(item => {
            if (item.usage === usage && item.signal && checkSignalName(signal, item.signal, info.name)) {
              item.powerOff = powerOff ? powerOff : "0";
            }
          });
        } else {//modify single pin
          const pinModelIndex = comp.pins.findIndex(item =>
            item.net === net && item.pin === pin && item.signal === signal && item.usage === usage
          );
          if (pinModelIndex > -1) {
            comp.pins[pinModelIndex].powerOff = powerOff ? powerOff : "0";
          }
        }
      }
    })

  })

  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* savePinModels(action) {
  const { record: { component, pin, net, signal, usage }, pinModels, applyAll, modifyType } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  _Interfaces.forEach(info => {
    const compIndex = info.Content.Components.findIndex(item => item.name === component);
    const comp = info.Content.Components[compIndex];
    if (applyAll) {//Apply to all pins of same component
      comp.pins.forEach(item => {
        if (item.usage === usage && signal && checkSignalName(signal, item.signal, info.name)) {
          //modify nd_pu,nd_pd
          if (modifyType && modifyType === 'model' && usage === 'Driver') {
            const findPU = pinModels.find(item => item.pinName === 'nd_pu');
            const findPD = pinModels.find(item => item.pinName === 'nd_pd');
            const itemFindPU = item.pinModels ? item.pinModels.findIndex(item => item.pinName === 'nd_pu') : -1;
            if (itemFindPU > -1) {
              item.pinModels[itemFindPU] = JSON.parse(JSON.stringify(findPU));
            } else {
              if (item.pinModels) {
                item.pinModels.push(JSON.parse(JSON.stringify(findPU)));
              } else if (usage === 'Driver') {
                item.pinModels = getPins({ usage });
                const PUIndex = item.pinModels.findIndex(item => item.pinName === 'nd_pu');
                item.pinModels[PUIndex] = JSON.parse(JSON.stringify(findPU));
              }
            }
            const itemFindPD = item.pinModels ? item.pinModels.findIndex(item => item.pinName === 'nd_pd') : -1;
            if (itemFindPD > -1) {
              item.pinModels[itemFindPD] = JSON.parse(JSON.stringify(findPD));
            } else {
              if (item.pinModels) {
                item.pinModels.push(JSON.parse(JSON.stringify(findPD)));
              } else if (usage === 'Driver') {
                item.pinModels = getPins({ usage });
                const PDIndex = item.pinModels.findIndex(item => item.pinName === 'nd_pd');
                item.pinModels[PDIndex] = JSON.parse(JSON.stringify(findPD));
              }
            }
          } else {
            //modify nd_pu,nd_pd,nd_in
            const findIn = pinModels.find(item => item.pinName === 'nd_in');
            if (findIn && (findIn.type !== 'INVERTER' || (findIn.type === 'INVERTER' && findIn.relative.pin !== item.pin))) {
              item.pinModels = JSON.parse(JSON.stringify(pinModels));
            }
          }
        }
      });
      info.Content.Components[compIndex] = comp;
    } else {
      //modify single pin
      info.Content.Components[compIndex].pins.forEach(p => {
        if (p.net === net && p.pin === pin && p.usage === usage) {
          p.pinModels = pinModels;
        }
      })
    }
  })
  const SETUP = RockySetup;
  //_Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* saveProjectConfig() {
  const { RockyReducer: { rocky: { hspiceOption, ansysOption, options }, project: { currentProjectId } } } = yield select();
  try {
    yield call(updateProjectConfig, { projectId: currentProjectId, options: { hspiceOption, ansysOption, options } });
  } catch (error) {
    message.error('Update service options failed! ' + error)
    return;
  }
}

function* saveStimuli(action) {
  const { record: { component, pin, net, signal, usage }, stimulus, pinName, applyAll } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  _Interfaces.forEach(info => {
    //find component
    const compIndex = info.Content.Components.findIndex(item => item.name === component);
    if (compIndex > -1) {
      const pins = info.Content.Components[compIndex].pins;
      if (applyAll) {
        pins.forEach(item => {
          if (item.usage === usage && signal && checkSignalName(signal, item.signal, info.name)) {
            const pinIndex = item.pinModels.findIndex(item => item.pinName === pinName);
            if (pinIndex > -1) {
              item.pinModels[pinIndex].stimulus = JSON.parse(JSON.stringify(stimulus));
            }
          }
        })
        info.Content.Components[compIndex].pins = pins;
      } else {
        //find pinModels
        const pinModelIndex = pins.findIndex(item => item.signal === signal && item.net === net && item.pin === pin && item.usage === usage);
        if (pinModelIndex > -1) {
          let pinModels = pins[pinModelIndex].pinModels;
          //find pin by pinName
          const pinIndex = pinModels.findIndex(item => item.pinName === pinName);
          if (pinIndex > -1) {
            //update stimulus
            pinModels[pinIndex].stimulus = stimulus;
            info.Content.Components[compIndex].pins[pinModelIndex].pinModels = pinModels;
          }
        }
      }
    }
  })
  const SETUP = RockySetup;
  //_Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
};

function* saveSimulationConfig(action) {
  const { apply, _2TModeChange } = action;
  const { RockyReducer: { rocky: { currentConfig, userDefinedNetlist, currentLibraryFileList, rockyInfo }, project: { currentChannelId, currentProjectId, projectVersion }, result: { resultConfig } } } = yield select();
  let Config = { ...currentConfig };
  let haveUseAMI = false;
  try {
    const _Interfaces = rockyInfo && rockyInfo.Interfaces ? rockyInfo.Interfaces : [];
    if (_Interfaces.length) {
      haveUseAMI = _Interfaces.find(i => i.Content.Components.find(comp => comp.pins.find(pin => pin.model && pin.model.libType ? pin.model.libType === 'IBIS_AMI' : false)));
    }
  } catch (e) {
    console.error(e);
  }

  let newConfig = {};
  newConfig.clock = {
    value: Config.clock && Config.clock.value ? Config.clock.value : '',
    unit: Config.clock && Config.clock.unit ? Config.clock.unit : 'M',
  }

  newConfig.timeStep = {
    value: Config.timeStep && Config.timeStep.value ? Config.timeStep.value : '',
    unit: Config.timeStep && Config.timeStep.unit ? Config.timeStep.unit : 'n',
  }

  if (Config.caClock) {
    newConfig.caClock = {
      value: Config.caClock && Config.caClock.value ? Config.caClock.value : '',
      unit: Config.caClock && Config.caClock.unit ? Config.caClock.unit : 'M',
    }
  }

  if (Config.caTimeStep) {
    newConfig.caTimeStep = {
      value: Config.caTimeStep && Config.caTimeStep.value ? Config.caTimeStep.value : '',
      unit: Config.caTimeStep && Config.caTimeStep.unit ? Config.caTimeStep.unit : 'n',
    }
  }
  // Config.simulateMode = haveUseAMI ? Config.simulateMode === 'StatEye' ? 'StatEye' : 'Transient' : 'Transient';
  Config.simulateMode = Config.simulateMode === 'StatEye' ? 'StatEye' : 'Transient';
  const isStateEye = Config.simulateMode === 'StatEye' ? true : false;
  const _config = {
    timeStep: newConfig.timeStep.value + newConfig.timeStep.unit,
    cycles: Config.cycles,
    clock: newConfig.clock.value + newConfig.clock.unit,
    simulate: Config.simulate,
    cmd_2t_mode: Config.cmd_2t_mode,
    ngspiceMDFLM: Config.ngspiceMDFLM,
    caClock: newConfig.caClock ? newConfig.caClock.value + newConfig.caClock.unit : '',
    caTimeStep: newConfig.caTimeStep ? newConfig.caTimeStep.value + newConfig.caTimeStep.unit : '',

    // simulateMode: haveUseAMI ? Config.simulateMode === 'StatEye' ? 'StatEye' : 'Transient' : 'Transient',
    simulateMode: Config.simulateMode === 'StatEye' ? 'StatEye' : 'Transient',
    statEyeConfig: isStateEye ? {
      transientEngine: Config.statEyeConfig.transientEngine ? Config.statEyeConfig.transientEngine : 'HPP',
      simulationMode: Config.statEyeConfig.simulationMode ? Config.statEyeConfig.simulationMode : 'EDGE',
      edgesNum: Config.statEyeConfig.edgesNum ? Config.statEyeConfig.edgesNum : '2',
      crosstalkType: Config.statEyeConfig.crosstalkType ? Config.statEyeConfig.crosstalkType : 'DDP',
      eyeDiagramTriggering: Config.statEyeConfig.eyeDiagramTriggering === 0 ? 0 : 1,
      eyeDiagramTriggeringClockMode: Config.statEyeConfig.eyeDiagramTriggeringClockMode ? Config.statEyeConfig.eyeDiagramTriggeringClockMode : 2,
      numberBits: Config.statEyeConfig.numberBits ? Config.statEyeConfig.numberBits : 100000,
    } : {
      transientEngine: '',
      simulationMode: '',
      edgesNum: '',
      crosstalkType: '',
      eyeDiagramTriggering: '',
      eyeDiagramTriggeringClockMode: '',
      numberBits: '',
    }
  };
  if (projectVersion === PROJECT_V2) {
    _config.hspiceLicenses = Config.hspiceLicenses ? Config.hspiceLicenses : 2;
  } else {
    _config.hspiceCores = Config.hspiceCores ? Config.hspiceCores : 2;
  }
  let _resultConfig = {};
  if (!resultConfig || Object.keys(resultConfig).length === 0) {
    const projectType = yield call(getProjectType);
    _resultConfig = getDefaultResultConfig(projectType)
  } else {
    _resultConfig = { ...resultConfig }
  }

  // save
  if (apply) {
    // apply simulation setup to all Interfaces
    const channels = projectChannels.get(currentProjectId);
    for (let channelId of channels) {
      let new_resultConfig = { ..._resultConfig }
      if (channelId !== currentChannelId) {
        // resultConfig do not change
        const response = yield call(getChannelConfig, channelId)
        if (response.config && response.config.resultConfig && Object.keys(response.config.resultConfig).length) {
          new_resultConfig = response.config.resultConfig
        }
      }
      yield call(updateChannelConfig, { channelId: channelId, config: _config, resultConfig: new_resultConfig, userDefinedNetlist: userDefinedNetlist || [] });
    }
  } else {
    yield call(updateChannelConfig, { channelId: currentChannelId, config: _config, resultConfig: _resultConfig, userDefinedNetlist: userDefinedNetlist || [] });
  }
  //if cmd_2t_mode updated and project type is [DDR3,DDR3L,DDR4]
  if (_2TModeChange && _2TModeDDRTypes.includes(yield call(getProjectType))) {
    //update stimulus nd_in type by cmd_2t_mode
    yield put(updateADRCMDStimulus(_config.cmd_2t_mode));
  }
}

function* saveComponentModel(action) {
  const { RockyReducer: { rocky: { rockyInfo, currentConfig }, project: { openVerificationId, projectVersion } } } = yield select();
  const { fileInfo, use, name } = action;
  let _Interfaces = rockyInfo.Interfaces;
  let compType = '', partName = '';
  const isAmi = projectVersion !== PROJECT_V2 && use === "IBIS_AMI" ? true : false;
  yield* _Interfaces.map(function* (info) {
    const compNameIndex = info.Content.Components.findIndex(item => item.name === name);
    if (compNameIndex < 0) {
      return;
    }
    compType = info.Content.Components[compNameIndex].type;
    partName = info.Content.Components[compNameIndex].part;
    yield* info.Content.Components.map(function* (comp) {
      //current component or (same type and same part components and model not set)
      if (comp.name === name
        || (!isAmi && compType === comp.type && partName === comp.part && (!comp.model || !comp.model.libraryId))
        || (isAmi && compType === comp.type && partName === comp.part && (!comp.amiModel || !comp.amiModel.libraryId))
      ) {
        const model = isAmi ? comp.amiModel : comp.model;
        if (!fileInfo) {
          if (isAmi) {
            comp.amiModel = {
              libType: use ? use : 'IBIS_AMI',
              fileName: "",
              libraryId: "",
              folder: ""
            }
          } else {
            comp.model = {
              libType: use ? use : 'IBIS',
              fileName: "",
              libraryId: "",
              folder: ""
            }
            if (comp.pkg && comp.pkg.type === 'IBIS') {
              comp.pkg = {
                type: 'None'
              }
            }
          }
        } else {
          const modelInfo = {
            libType: use,
            fileName: fileInfo.fileName,
            libraryId: fileInfo.id,
            folder: fileInfo.folder || ""
          };

          if (isAmi) {
            comp.amiModel = { ...modelInfo }
          } else {
            comp.model = { ...modelInfo }

            if (model && comp.pkg && comp.pkg.type === 'IBIS' && (model.fileName !== fileInfo.fileName || model.libraryId !== fileInfo.id)) {
              comp.pkg = {
                type: 'None'
              }
            }
          }

          yield* comp.pins.map(function* (item) {
            if (item.usage &&
              (
                (item.model && item.model.libType !== "IBIS_AMI" && (item.model.fileName !== fileInfo.fileName || item.model.libraryId !== fileInfo.id) && !isAmi)
                || (item.model && item.model.libType === "IBIS_AMI" && (item.model.fileName !== fileInfo.fileName || item.model.libraryId !== fileInfo.id) && isAmi)
                || (isAmi && (!item.model || !item.model.libraryId || !item.model.fileName))
                || (!item.model || !item.model.libraryId || !item.model.fileName)
                // || (!model || Object.keys(model).length === 0)
              )
            ) {
              if (fileInfo.folder && !isAmi && use === "SPICE") {
                item.model = new PinSpiceModel({
                  libType: use,
                  fileName: fileInfo.fileName,
                  libraryId: fileInfo.id,
                  folder: fileInfo.folder
                });
              } else {
                item.model = new Model({ libType: use, fileName: fileInfo.fileName, libraryId: fileInfo.id, folder: fileInfo.folder });
                //get default model name
                const libType = fileInfo.fileType === "ibis_ami" || item.model.libType === "IBIS_AMI" ? "IBIS_AMI" : item.model.libType;
                const modelObj = yield call(getIBISModelList, { type: item.usage, fileName: item.model.fileName, fileId: item.model.libraryId, libType })
                const { modelType, modelName, deviceVcc, enable } = getIBISModelName({
                  modelObj,
                  usage: item.usage,
                  signal: item.signal,
                  speed: currentConfig && currentConfig.clock ? currentConfig.clock.value : "800",
                  compType: comp.type,
                  projectType: yield call(getProjectType),
                  interfaceName: info.name
                })

                if (modelName && modelType) {
                  item.model.modelName = modelName;
                  item.model.modelType = modelType;
                  item.model.enableVoltage = exsitEnableVoltage(modelType) ? (enable === 'NA' ? '1' : enable) : "";

                  comp.deviceVcc = deviceVcc;
                  if (item.usage === 'Driver') {

                    if (!item.pinModels || item.pinModels.length === 0) {
                      item.pinModels = getPins({ usage: item.usage });
                    }
                    if (item.pinModels && item.pinModels.length > 0) {
                      item.pinModels.forEach(pinItem => {
                        switch (pinItem.pinName) {
                          case 'nd_pu':
                            pinItem.voltage = deviceVcc;
                            break;
                          case 'nd_pd':
                            pinItem.voltage = '0';
                            break;
                          default: break;
                        }
                      })
                    }
                  }
                }
              }

            }
          })
        }
      }
    })
  })

  const SETUP = RockySetup;
  //_Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  //update component model and pin table loading
  yield put(updateComponentLoading(false));
  yield put({ type: SAVE_CONTENT_TO_SERVER });

  if (fileInfo && fileInfo.fileName && compType && partName) {
    const libFileInfo = { libType: use, fileName: fileInfo.fileName, libraryId: fileInfo.id, folder: fileInfo.folder || null };
    yield call(_copyComponentModel, { libFileInfo, compType, openVerificationId, partName, isSSN: projectVersion === PROJECT_V2 ? true : false });
  }
}

function* _copyComponentModel(action) {
  const { libFileInfo, compType, openVerificationId, partName, isSSN } = action;
  const { RockyReducer: { rocky: { currentConfig }, project: { currentProjectId, currentChannelId, ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, extraction, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList, contentType, projectVersion } } } = yield select();
  const channels = projectChannels.get(currentProjectId);

  const copySetupFinds = yield call(getChannelVerifications, { openVerificationId, channels, currentChannelId, contentType, isAllChannel: true, isSSN })

  const projectType = yield call(getProjectType)
  const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
  if (copySetupFinds && copySetupFinds.length > 0) {
    yield* copySetupFinds.map(function* (info, veIndex) {
      const response = yield call(getSetupInfo, info.id);
      if (response) {
        let _Interfaces = response.interfaces;
        _Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
        let copyInfo = copyComponentModel({ currentInterfaces: _Interfaces, libFileInfo, compType, partName });
        const copyStatus = copyInfo.copyStatus;
        if (copyStatus) {
          _Interfaces = copyInfo.Interfaces ? JSON.parse(JSON.stringify(copyInfo.Interfaces)) : _Interfaces;
          //todo get model name
          yield* _Interfaces.map(function* (info) {
            for (let i = 0; i < info.Content.Components.length; i++) {
              let comp = info.Content.Components[i];
              if (ChipTypes.includes(comp.type) && !comp.corner) {
                comp.corner = 'typ';
              }
              if (!ChipTypes.includes(comp.type)) {
                continue;
              }
              yield* comp.pins.map(function* (item) {
                // if (item.model && item.model.libType === 'IBIS' && item.model.fileName && item.model.libraryId && !item.model.modelName && !item.model.modelType) {
                if (item.model && ['IBIS', 'IBIS_AMI'].includes(item.model.libType) && item.model.fileName && item.model.libraryId && !item.model.modelName && !item.model.modelType) {

                  const modelObj = yield call(getIBISModelList, { type: item.usage, fileName: item.model.fileName, fileId: item.model.libraryId, libType: item.model.libType })
                  const { modelType, modelName, deviceVcc, enable } = getIBISModelName({
                    modelObj,
                    usage: item.usage,
                    signal: item.signal,
                    speed: currentConfig && currentConfig.clock ? currentConfig.clock.value : "1200",
                    compType: comp.type,
                    projectType,
                    interfaceName: info.name
                  })
                  if (modelName && modelType) {
                    item.model.modelName = modelName;
                    item.model.modelType = modelType;
                    item.model.enableVoltage = exsitEnableVoltage(modelType) ? (enable === 'NA' ? '1' : enable) : "";
                    comp.deviceVcc = deviceVcc;
                    if (item.usage === 'Driver') {

                      if (!item.pinModels || item.pinModels.length === 0) {
                        item.pinModels = getPins({ usage: item.usage });
                      }
                      if (item.pinModels && item.pinModels.length > 0) {
                        item.pinModels.forEach(pinItem => {
                          switch (pinItem.pinName) {
                            case 'nd_pu':
                              pinItem.voltage = deviceVcc;
                              break;
                            case 'nd_pd':
                              pinItem.voltage = '0';
                              break;
                            default: break;
                          }
                        })
                      }
                    }
                  }
                }
              })
              info.Content.Components[i] = comp;
            }
          })
          // error check update readyForSim
          let readyForSim = response.readyForSim;
          const SETUP = RockySetup;
          let setupInfo = SETUP.mergeInterfacesInfo(_Interfaces, response.name, projectType, projectVersion);
          const _rockyInfo = { info: setupInfo, Interfaces: JSON.parse(JSON.stringify(_Interfaces)), verificationId: info.id };
          const errorCheck = getErrorCheck(_rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, projectType);
          readyForSim = errorCheck ? 0 : 1;

          _Interfaces.forEach(item => {
            //v0.0.2 delete Extraction,channel
            delete item.Extraction;
            delete item.Channel;
          });

          ByteSetupInfo.saveSetupInfo(info.id, {
            ...response,
            version: ROCKY_SETUP_VERSION,
            interfaces: JSON.parse(JSON.stringify(_Interfaces)),
            readyForSim,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
          });
          yield call(updateVerificationContent, {
            id: info.id,
            name: response.name,
            config: response.config,
            interfaces: JSON.parse(JSON.stringify(_Interfaces)),
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
            readyForSim,
            version: ROCKY_SETUP_VERSION,
            designVersion: response.designVersion ? response.designVersion : "0"
          });
        }
      }
    })

    yield delay(1000);
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  }
}

function* getChannelCmd2TMode(list, channelId) {
  let cmd_2t_mode = false;
  const findIndex = list.findIndex(item => item.id === channelId);
  if (list[findIndex]) {
    cmd_2t_mode = list[findIndex].cmd_2t_mode;
  } else {
    cmd_2t_mode = yield call(getCmd2TMode, { channelId });
    list.push({ id: channelId, cmd_2t_mode })
  }
  return { copy_cmd_2t_mode: cmd_2t_mode, copy_cmd_2t_mode_list: list };
}

function* rockyApplySetupToSelected(action) {
  const { copyVerifications, interfaceType } = action;
  const { RockyReducer: { rocky: { rockyInfo, currentConfig }, project: { currentProjectId, ibisList, ibisAmiList, spiceList, vectorList, currentChannelId, pkgSpiceList, pkgTouchstoneList, ebdList, extraction, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList, projectVersion } } } = yield select()
  const projectType = yield call(getProjectType)
  let applyRes = null;
  const copy_cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
  let copy_cmd_2t_mode_list = [{ id: currentChannelId, cmd_2t_mode: copy_cmd_2t_mode }];
  let channelIds = [];

  if (rockyInfo && rockyInfo.Interfaces && copyVerifications && copyVerifications.length > 0) {
    let isCopyPackageInfo = null;
    const { Content } = rockyInfo.Interfaces[0]
    const currentComp = Content.Components.find(item => item.type === "Controller" && item.pkg && item.pkg.type === "Layout" && item.pkg.packageLayoutInfo && item.pkg.packageLayoutInfo.channelId);
    if (currentComp && Object.keys(currentComp) && currentComp.pkg && currentComp.pkg.packageLayoutInfo) {
      const design = designConstructor.getDesign(currentComp.pkg.packageLayoutInfo.packageId) || {};
      if (design && design.vendor !== PRELAYOUT) {
        isCopyPackageInfo = { ...currentComp.pkg.packageLayoutInfo }
      }
    }

    yield* copyVerifications.map(function* (info, veIndex) {
      const response = yield call(getSetupInfo, info.id);
      if (response) {
        let _Interfaces = response.interfaces;
        const channelId = response.channelId;
        const channelIndex = channelIds.findIndex(it => it === channelId);
        if (channelIndex < 0) {
          channelIds.push(channelId);
        }
        const cmdModeInfo = yield call(getChannelCmd2TMode, copy_cmd_2t_mode_list, channelId);
        const cmd_2t_mode = cmdModeInfo.copy_cmd_2t_mode;
        copy_cmd_2t_mode_list = cmdModeInfo.copy_cmd_2t_mode_list;
        //update interface info by default

        _Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
        //copyInterfaces-> Interface being copied
        //Copy the setup copy of copyInterfaces to currentInterfaces

        let updatePkgInfo = {};
        if (isCopyPackageInfo && Object.keys(isCopyPackageInfo).length) {
          // when pkg.type === 'Layout' copy to other Interface.
          let currentChannelInfo = yield call(getCurrentChannelInfo, {
            projectId: currentProjectId,
            channelId: response.channelId
          });
          const _packageChannelId = yield call(getCurrentVerificationPkgInfo, { packageDesignId: isCopyPackageInfo.packageId, rockyInfo: { verificationId: response.id, verificationName: response.name }, currentChannelInfo, currentProjectId, compName: currentComp.name })
          updatePkgInfo = _packageChannelId ? {
            type: "Layout",
            packageLayoutInfo: {
              name: isCopyPackageInfo.name,
              packageId: isCopyPackageInfo.packageId,
              channelId: _packageChannelId
            }
          } : {}
        }

        // rockyInfo.Interfaces
        let copyInterfaces = rockyInfo.Interfaces || [], _interfaceType = interfaceType;
        if (projectVersion === PROJECT_V2 && ["ADR", "CMD"].includes(interfaceType)) {
          // copyInterfaces
          const name = _Interfaces && _Interfaces[0].name;
          if (name.includes("ADR")) {
            copyInterfaces = copyInterfaces.filter(item => item.name.includes("ADR"));
            _interfaceType = "ADR";
          } else if (name.includes("CMD")) {
            copyInterfaces = copyInterfaces.filter(item => item.name.includes("CMD"));
            _interfaceType = "CMD";
          }
        }

        _Interfaces = getCopySetupContent({
          currentInterfaces: _Interfaces,
          copyInterfaces: copyInterfaces,
          interfaceType: _interfaceType,
          cmd_2t_mode,
          copy_cmd_2t_mode,
          updatePkgInfo,
          projectType
        });

        // error check update readyForSim
        let readyForSim = response.readyForSim;
        const SETUP = RockySetup;
        let setupInfo = SETUP.mergeInterfacesInfo(_Interfaces, response.name, projectType, projectVersion);
        const _rockyInfo = { info: setupInfo, Interfaces: _Interfaces, verificationId: info.id };
        const errorCheck = getErrorCheck(_rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, projectType);
        readyForSim = errorCheck ? 0 : 1;

        _Interfaces.forEach(item => {
          //v0.0.2 delete Extraction,channel
          delete item.Extraction;
          delete item.Channel;
        });
        ByteSetupInfo.saveSetupInfo(info.id, {
          ...response,
          version: ROCKY_SETUP_VERSION,
          readyForSim,
          interfaces: _Interfaces,
          ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
        });
        const saveRes = yield call(updateVerificationContent, {
          id: info.id,
          name: response.name,
          interfaces: _Interfaces,
          config: response.config,
          ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
          readyForSim,
          version: ROCKY_SETUP_VERSION,
          designVersion: response.designVersion ? response.designVersion : "0"
        });
        if (veIndex === copyVerifications.length - 1) {
          applyRes = saveRes;
        }
      }
    })
    if (applyRes) {
      message.success('Apply setup successful!');
      yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
      //UPDATE tree verifications, update readyForSim
      yield call(getVerificationsByChannel, {
        channelIds: channelIds.filter(it => it !== currentChannelId),
        projectId: currentProjectId
      })
    }
  }
  yield put(updateApplySetupStatus(false))
}

function* _getLibraryFileParse() {
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  if (!rockyInfo || !rockyInfo.Interfaces) {
    return;
  }
  let _Interfaces = [...rockyInfo.Interfaces];

  //check library file if exist
  // _Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  if (!info) {
    return;
  }
  //update current verification library file list
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));

  //get library file parse
  yield* currentLibraryFileList.map(function* (item) {
    // if (item.type === 'IBIS') {
    if (['IBIS', 'IBIS_AMI'].includes(item.type)) {
      let obj = {
        libraryId: item.id,
        usage: 'Tx',
        fileName: item.name,
        libType: item.type
      }
      yield call(getIbisModelList, obj);
    }

    if (item.type === 'SPICE' && item.id) {
      let obj = {
        libraryId: item.id,
        usage: 'Tx',
      }
      if (item.folder) {
        yield call(getFolderFileDetail, { fileName: item.name, libraryId: item.id })
      } else {
        yield call(getSpiceModelList, obj);
      }
    }

    if ([PKG_TOUCHSTONE, PCB_PDN_TOUCHSTONE, PCB_TOUCHSTONE, ON_DIE_TOUCHSTONE].includes(item.type)) {
      yield call(getTouchstoneParse, item.id, item.name);
    }

    if (item.type === PKG_SPICE) {
      yield call(getPkgSpiceModelList, item.id);
    }
  })

  //todo
  /*   yield put(updateRockyContentInfo({ ...info }));
    yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
    yield put({ type: SAVE_CONTENT_TO_SERVER }); */
}

function* saveComponentPkgModel(action) {
  const { pkg, component } = action;
  const { RockyReducer: { rocky: { rockyInfo, TouchstoneStatusList }, project: { openVerificationId, projectVersion } } } = yield select();
  if (!rockyInfo || !rockyInfo.Interfaces) {
    return;
  }
  let _Interfaces = JSON.parse(JSON.stringify(rockyInfo.Interfaces));
  let compType = '', partName = '';

  _Interfaces.forEach(info => {
    const compIndex = info.Content.Components.findIndex(item => item.name === component);
    if (compIndex > -1) {
      compType = info.Content.Components[compIndex].type;
      partName = info.Content.Components[compIndex].part;
      let _pkg = pkg ? JSON.parse(JSON.stringify(pkg)) : null;
      if (projectVersion === PROJECT_V2 && info.name.match(/CLK_(CMD|ADR)/g) && _pkg.pairs && _pkg.pairs.length) {
        let filterPairs = [];
        info.Content.Components[compIndex].pins.forEach(item => {
          const { pin } = item;
          const filterPinPairs = _pkg.pairs.filter(pair => [pin, `${pin}_u`].includes(pair.pin));
          filterPairs.push(...filterPinPairs)
        })
        _pkg.pairs = filterPairs;
      }
      info.Content.Components[compIndex].pkg = _pkg ? _pkg : info.Content.Components[compIndex].pkg;
    }
    //copy to same part and same type components pkg
    info.Content.Components.forEach(comp => {
      if (compType === comp.type && partName === comp.part && (!comp.pkg || comp.pkg.type === 'None') && comp.model && pkg.libraryId === comp.model.libraryId) {
        comp.pkg = pkg ? JSON.parse(JSON.stringify(pkg)) : comp.pkg;
      }
    })
    return '';
  });

  const SETUP = RockySetup;
  // _Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  const _Components = info && info.Components ? info.Components : [];
  //if touchstone error message exist, update
  const statusIndex = TouchstoneStatusList.findIndex(it => it.verificationId === openVerificationId);
  if (statusIndex > -1) {
    yield put(updateTouchstoneMacroModelingStatus({ Components: _Components, verificationId: openVerificationId }));
  }
  yield put({ type: SAVE_CONTENT_TO_SERVER });

  // copy pkg to all component === component byte/ADR/CMD
  if (pkg && pkg.type && pkg.type === 'IBIS') {
    yield call(_copyComponentPkgModel, { pkg, component, partName, compType, openVerificationId });
  }
}

function* _copyComponentPkgModel(action) {
  const { pkg, openVerificationId, component, partName, compType } = action;
  const { RockyReducer: { project: { currentProjectId, currentChannelId, ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, extraction, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList, contentType, projectVersion }
    , rocky: { currentConfig } } } = yield select();
  const channels = projectChannels.get(currentProjectId);
  const copySetupFinds = yield call(getChannelVerifications, { openVerificationId, channels, currentChannelId, contentType, isAllChannel: true })

  const projectType = yield call(getProjectType);
  const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
  if (copySetupFinds && copySetupFinds.length > 0) {
    yield* copySetupFinds.map(function* (info) {
      const response = yield call(getSetupInfo, info.id);
      if (response) {

        let _Interfaces = response.interfaces;
        //update interface info by default
        _Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
        const copyInfo = copyComponentPackageModel({ currentInterfaces: _Interfaces, pkg, component, partName, compType });
        const copyStatus = copyInfo.copyStatus;
        if (copyStatus) {
          _Interfaces = copyInfo.Interfaces ? JSON.parse(JSON.stringify(copyInfo.Interfaces)) : _Interfaces;
          // error check update readyForSim
          let readyForSim = response.readyForSim;
          const SETUP = RockySetup;
          let setupInfo = SETUP.mergeInterfacesInfo(_Interfaces, response.name, projectType, projectVersion, projectVersion);
          const _rockyInfo = { info: setupInfo, Interfaces: JSON.parse(JSON.stringify(_Interfaces)), verificationId: info.id };
          const errorCheck = getErrorCheck(_rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, projectType);
          readyForSim = errorCheck ? 0 : 1;

          _Interfaces.forEach(item => {
            //v0.0.2 delete Extraction,channel
            delete item.Extraction;
            delete item.Channel;
          });
          ByteSetupInfo.saveSetupInfo(info.id, {
            ...response,
            version: ROCKY_SETUP_VERSION,
            interfaces: JSON.parse(JSON.stringify(_Interfaces)),
            readyForSim,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
          });
          yield call(updateVerificationContent, {
            id: info.id,
            name: response.name,
            interfaces: JSON.parse(JSON.stringify(_Interfaces)),
            config: response.config,
            ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
            readyForSim,
            version: ROCKY_SETUP_VERSION,
            designVersion: response.designVersion ? response.designVersion : "0"
          });
        }
      }
    })

    yield delay(1000);
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  }
}

export function* getLayoutPCBInfo(designId, fullLayers) {
  let { RockyReducer: { project: { pcbComponentsNets, currentProjectId } } } = yield select();
  const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId);
  const design = designConstructor.getDesign(designId) || {}
  if ((vendor === PRELAYOUT && design && design.dataType === PCB_PRE_LAYOUT)
    || design.dataType === PACKAGE_PRE_LAYOUT) {
    return {};
  }
  yield call(_getLayoutDB, { id: designId, save: false, fullLayers });
  const _DesginData = LayoutData.getLayout(designId);
  const pcbInfo = {
    netsList: [..._DesginData.mNetManager.mNetList.mVals],
    layers: [..._DesginData.mLayerMgr.mMetalLayers]
  };
  pcbComponentsNets.push(designId);
  DesignInfo.savePCBInfo(designId, pcbInfo);
  yield put(updatePCBComponentsNets(pcbComponentsNets));
  return pcbInfo;
}

export function* _UpdateTouchstoneStatus(action) {
  //update touchstone file macro modeling status list
  const { Components, verificationId } = action;
  let { RockyReducer: { rocky: { TouchstoneStatusList } } } = yield select();

  if (!Components || Components.length === 0) {
    return { msgList: [], TouchstoneStatusList };
  }

  let touchstoneFileStatusMsgList = [];
  const compPkgList = Array.isArray(Components) ? Components.filter(comp => ChipTypes.includes(comp.type)).map(it => it.pkg) : [];
  let pkgFileList = compPkgList.filter(it => it && it.files);
  let touchstonePkgList = [];
  for (let pkg of pkgFileList) {
    if (!pkg.files || pkg.files.length === 0) {
      continue;
    }

    const touchstones = Array.isArray(pkg.files) ? pkg.files.filter(it => it.type === 'Touchstone / SPICE') : [];
    touchstones.forEach(it => {
      const find = touchstonePkgList.find(file => file.libraryId === it.libraryId);
      if (!find) {
        touchstonePkgList.push(it);
      }
    })
  }

  if (!touchstonePkgList || touchstonePkgList.length === 0) {
    return { msgList: [], TouchstoneStatusList };
  }

  yield* touchstonePkgList.map(function* (file) {
    const libraryId = file.libraryId;
    const fileName = file.fileName;
    const fileStatusObj = yield call(getLibraryMacroModelingStatus, libraryId);
    let status = taskStatus.SUCCEED, msg = '';

    if (fileStatusObj) {
      status = fileStatusObj.status;
      switch (status) {
        case taskStatus.RUNNING:
        case taskStatus.WAITING:
          msg = `Touchstone file ${fileName} is not ready for simulation, please waiting...`;
          break;
        case taskStatus.FAILED:
        case taskStatus.CANCEL:
          msg = `Touchstone file ${fileName} is broken, please upload a new touchstone file for package model.`;
          break;
        default: break;
      }
    }
    msg && touchstoneFileStatusMsgList.push(msg);
  });

  if (touchstoneFileStatusMsgList && touchstoneFileStatusMsgList.length > 0) {
    const statusIndex = TouchstoneStatusList.findIndex(it => it.verificationId === verificationId);
    if (statusIndex > -1) {
      TouchstoneStatusList[statusIndex].msgList = [...touchstoneFileStatusMsgList];
    } else {
      TouchstoneStatusList.push({
        verificationId,
        msgList: [...touchstoneFileStatusMsgList]
      })
    }
  } else {
    TouchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== verificationId);
  }
  yield put(updateTouchstoneStatusList(TouchstoneStatusList));
  return { msgList: touchstoneFileStatusMsgList, TouchstoneStatusList };
}

export function getSetupInfo(id) {
  return new Promise((resolve, reject) => {
    ByteSetupInfo.getSetupInfo(id).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
    })
  })
}

function* getCurrentChannelInfo(action) {
  const { currentProjectInterfaceInfo, projectId, channelId } = action;
  const channels = projectChannels.get(projectId).map(id => rockyChannels.get(id));
  let currentChannelInfo = null;
  if (currentProjectInterfaceInfo && currentProjectInterfaceInfo.projectId === projectId && currentProjectInterfaceInfo[channelId]) {
    currentChannelInfo = currentProjectInterfaceInfo[channelId];
  } else {
    const _Info = yield call(getPCBInterfaces, { id: projectId, channels });
    if (_Info && _Info.id === projectId && _Info[channelId]) {
      currentChannelInfo = _Info[channelId];
    }
  }
  return currentChannelInfo;
}

const CLKName = ["ADR", "CMD"];
function* _updateADRCMDStimulusByMode(action) {
  const { cmd_2t_mode } = action;
  const stimulusType = cmd_2t_mode ? "PRBS-HDR-80" : "PRBS-SDR-80";
  const { RockyReducer: { rocky: { rockyInfo }, project: { currentChannelId, ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, extraction, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList, contentType, projectVersion } } } = yield select();
  const projectType = yield call(getProjectType)
  //ddr3,ddr3l,ddr4
  if (!_2TModeDDRTypes.includes(projectType)) {
    return;
  }
  const verificationName = rockyInfo && rockyInfo.verificationName ? rockyInfo.verificationName : null;
  // ADR / CMD
  const nameType = getInterfaceType(verificationName, true);
  const SETUP = RockySetup;
  let clkList = [...CLKName];
  //If current verification is ADR / CMD
  if (rockyInfo && verificationName && CLKName.includes(nameType) && rockyInfo.Interfaces && rockyInfo.Interfaces.length) {
    clkList = CLKName.filter(item => item !== nameType);
    let _Interfaces = rockyInfo.Interfaces;
    //update stimulus nd_in type to stimulusType
    _Interfaces = updateStimulus(_Interfaces, stimulusType);
    //update
    let info = SETUP.mergeInterfacesInfo(_Interfaces, verificationName, projectType, projectVersion);
    yield put(updateRockyContentInfo({ ...info }));
    yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
    yield put({ type: SAVE_CONTENT_TO_SERVER });
  }

  for (let name of clkList) {
    const verifications = yield call(getChannelVerifications, { channels: [currentChannelId], currentChannelId, contentType, isAllChannel: true })
    const verification = verifications.find(item => item.name && item.name.match(name));
    if (!verification) {
      continue;
    }
    let verificationId = verification.id;
    //get json
    const response = yield call(getSetupInfo, verificationId);
    if (response) {

      let _Interfaces = response.interfaces;
      let readyForSim = response.readyForSim;
      //if !version, update default setup
      if ((!response.version || response.version === "0") && response.interfaces && response.interfaces.length > 0) {
        //update interface info by default
        _Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });
      } else {
        //update nd_in stimulus type 
        _Interfaces = updateStimulus(_Interfaces, stimulusType);

        // error check update readyForSim
        let setupInfo = SETUP.mergeInterfacesInfo(_Interfaces, response.name, projectType, projectVersion);
        const _rockyInfo = { info: setupInfo, Interfaces: _Interfaces, verificationId };
        const errorCheck = getErrorCheck(_rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, projectType);
        readyForSim = errorCheck ? 0 : 1;

        _Interfaces.forEach(item => {
          //v0.0.2 delete Extraction,channel
          delete item.Extraction;
          delete item.Channel;
        });
      }

      //save setup to class
      ByteSetupInfo.saveSetupInfo(verificationId, {
        ...response,
        version: ROCKY_SETUP_VERSION,
        readyForSim,
        interfaces: _Interfaces,
        ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
      });
      //save setup to server
      yield call(updateVerificationContent, {
        id: verificationId,
        name: response.name,
        interfaces: _Interfaces,
        config: response.config,
        ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
        readyForSim,
        version: ROCKY_SETUP_VERSION,
        designVersion: response.designVersion ? response.designVersion : "0"
      });
    }
  }
}

function updateStimulus(Interfaces, stimulusType) {
  let _Interfaces = Interfaces ? JSON.parse(JSON.stringify(Interfaces)) : [];

  _Interfaces.forEach(info => {
    info.Content.Components.forEach(comp => {
      if (comp.type === 'Controller' && comp.pins && comp.pins.length) {
        comp.pins.forEach(pin => {
          const find = pin.pinModels.find(item => item.pinName === 'nd_in');
          if (!find) {
            pin.pinModels = getPins({ usage: pin.usage });
          }

          const index = pin.pinModels.findIndex(item => item.pinName === 'nd_in');
          if (index > -1 && pin.usage === 'Driver' && !pin.signal.match(no2TModeSignalsReg)) {
            pin.pinModels[index].type = stimulusType;
          }
        })
      }
    })
  })
  return _Interfaces;
}

export function* initDefaultInterfaces({ response, cmd_2t_mode, isReplace = false }) {
  let Interfaces = JSON.parse(JSON.stringify(response.interfaces));
  let { RockyReducer: { project: { currentProjectId, currentProjectInterfaceInfo, extraction } } } = yield select();
  if ((!response.version || response.version === "0" || isReplace) && Interfaces && Interfaces.length > 0) {
    const design = designConstructor.getDesign(response.designId) || {};
    const vendor = design && design.vendor ? design.vendor : projectDesigns.getAvailableDesignsVendor(currentProjectId);
    const pcbInfo = vendor === PRELAYOUT ? { netList: [], layers: [] } : yield call(getLayoutPCBInfo, response.designId);
    // auto find power nets and powerComponents
    //channel and extraction
    const channelId = response.channelId;
    const currentChannelInfo = yield call(getCurrentChannelInfo, {
      currentProjectInterfaceInfo,
      projectId: currentProjectId,
      channelId
    });
    Interfaces = updateInterfaceInfo({ Interfaces, pcbId: response.designId, pcbInfo, projectType: yield call(getProjectType), currentChannelInfo, cmd_2t_mode, isReplace });
    if (vendor !== PRELAYOUT) {
      //default generation ports
      const content = Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content;
      const _content = response.interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content;
      const ports_generate_setup_list = getPortGenerateSetupList({ components: content.Components });
      const _ports_generate_setup_list = _content.Ports_generate_setup_list && _content.Ports_generate_setup_list.length ? _content.Ports_generate_setup_list : ports_generate_setup_list;
      const referenceNets = getDefaultReferenceNets(content.PowerNets);
      const _referenceNets = _content.ReferenceNets && _content.ReferenceNets.length ? _content.ReferenceNets : referenceNets;
      const referenceZ0 = content.Port_setups && content.Port_setups.length ? content.Port_setups[0].z0 : 50
      const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
        components: content.Components,
        signals: content.Signals,
        pcbInfo: pcbInfo,
        referenceNets: _referenceNets,
        designId: response.designId,
        ports_generate_setup_list: _ports_generate_setup_list,
        types: ChipTypes,
        referenceZ0: referenceZ0,
        extractionType: extraction.channelType
      });
      content.ReferenceNets = _referenceNets;
      content.Port_setups = port_setups;
      content.Ports_generate_setup_list = setupList;
      content.Components.forEach(comp => {
        if (ChipTypes.includes(comp.type)) {
          comp = setDefaultPortData(comp, permissionData.getRockyPortType(), permissionData.getRockyExtraction(), response.designId)
        }
      })
      content.PowerNets.forEach(net => {
        net.value = net.value ? net.value : "0";
      })
      Interfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = content;
    }
  }
  return Interfaces;
}

function* updateVerificationsByVersion(action) {
  const { designVersion, verifications } = action;
  let { RockyReducer: { rocky: { currentConfig, rockyInfo: oldRockyInfo }, project: { projectVersion } } } = yield select();
  yield* verifications.rockyVerifications.map(function* (verification) {
    if (verification.designVersion !== designVersion) {
      if (verification.typeName !== ROCKY_PDN) {
        const response = yield call(getVerificationContentPromise, verification.id);
        if (!response) {
          return;
        }
        let Interfaces = JSON.parse(JSON.stringify(response.interfaces));
        const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;

        let rlcList = [];
        Interfaces.forEach(item => {
          rlcList = [...rlcList, ...item.Content.Components.filter(comp => !ChipTypes.includes(comp.type))]
          item.Content.Components = item.Content.Components.filter(comp => ChipTypes.includes(comp.type));
          delete item.Content.PowerNets;
          delete item.Content.VTTNets;
        })
        response.interfaces = [...Interfaces];
        //update interface info by default
        Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode, isReplace: true });
        if (rlcList && rlcList.length > 0) {
          Interfaces.forEach(item => {
            item.Content.Components = item.Content.Components.map(comp => {
              const oldComp = rlcList.find(item => item.name === comp.name);
              return oldComp ? { ...comp, value: oldComp.value } : { ...comp }
            })
          })
        }
        const rockyInfo = {
          interfaces: Interfaces,
          designVersion,
          config: verification.config,
          id: verification.id,
          ifDoExtraction: verification.ifDoExtraction,
          name: verification.name,
          readyForSim: verification.readyForSim,
          version: verification.version
        }
        if (oldRockyInfo && oldRockyInfo.verificationId === rockyInfo.id) {
          const SETUP = RockySetup;
          let info = SETUP.mergeInterfacesInfo(Interfaces, response.name, yield call(getProjectType), projectVersion);
          const newInfo = {
            config: verification.config,
            Interfaces,
            info,
            verificationId: verification.id,
            verificationName: verification.name,
            ifDoExtraction: verification.ifDoExtraction,
            readyForSim: verification.readyForSim,
            designVersion,
            designId: response.designId,
            version: ROCKY_SETUP_VERSION
          };
          yield put(updateRockyContent(newInfo));
        }
        yield fork(updateVerificationContent, rockyInfo);
      }
    }
  })
}

function* updatePortsSetup(action) {
  const { data, ComponentSetups, interfaceType } = action;
  const { RockyReducer: { rocky: { rockyInfo, rockyPackageInfo }, project: { currentProjectId, projectVersion } } } = yield select();

  if (interfaceType === VERIFICATION) {
    if (!rockyInfo || !rockyInfo.info) {
      return;
    }

    const newInterfaces = [...rockyInfo.Interfaces];
    const Ports_generate_setup_list = data.Ports_generate_setup_list || [];
    let content = newInterfaces.find(i => portSavePath.find(p => i.name.match(p))).Content;
    newInterfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = { ...content, ...data };
    newInterfaces.forEach(item => {
      item.Content.Components.forEach(comp => {
        const compName = splitComponentName(comp.name);
        const findComp = ComponentSetups.find(it => it.name === compName);
        const compPortSetup = Ports_generate_setup_list.find(it => it.component === compName) || { setup: {} };
        if (findComp) {
          if ([...USE_BALL_LIST].includes(compPortSetup.setup.portType)) {
            if (compPortSetup.setup.portType === GAP) {
              comp.gap_size = findComp.gap_size || "0";
            }
            comp.ball_type = findComp.ball_type;

            if (comp.ball_type === BALL_TYPE_NONE) {
              delete comp.ball_size;
              delete comp.ball_height;
              delete comp.ball_material;
            } else {
              comp.ball_size = findComp.ball_size;
              comp.ball_height = findComp.ball_height;
              comp.ball_material = findComp.ball_material;
            }

            if (comp.ball_type === SPHEROID) {
              comp.ball_mid_diameter = findComp.ball_mid_diameter;
            } else {
              delete comp.ball_mid_diameter;
            }
          } else {
            delete comp.gap_size;
            delete comp.ball_size;
            delete comp.ball_height;
            delete comp.ball_mid_diameter;
            delete comp.ball_type;
            delete comp.ball_material;
          }
        }
      })
    })
    yield put(updateRockyInterfaces({ Interfaces: newInterfaces }));

    const SETUP = RockySetup;
    let newInfo = SETUP.mergeInterfacesInfo(newInterfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
    newInfo = { ...newInfo, ...data };
    yield put(updateRockyContentInfo(newInfo));

    yield delay(300);
    yield call(saveVerificationContentToServer);
  } else if (interfaceType === PACKAGE_VERIFICATION) {
    if (!rockyPackageInfo || !rockyPackageInfo.content) { return }
    let content = { ...rockyPackageInfo.content, ...data };
    const Ports_generate_setup_list = data.Ports_generate_setup_list || [];

    content.Components.forEach(comp => {
      const compName = splitComponentName(comp.name);
      const findComp = ComponentSetups.find(it => it.name === compName);
      const compPortSetup = Ports_generate_setup_list.find(it => it.component === compName) || { setup: {} };
      if (findComp) {
        if ([...USE_BALL_LIST].includes(compPortSetup.setup.portType)) {
          if (compPortSetup.setup.portType === GAP) {
            comp.gap_size = findComp.gap_size || "0";
          }
          comp.ball_type = findComp.ball_type;
          if (comp.ball_type === BALL_TYPE_NONE) {
            delete comp.ball_size;
            delete comp.ball_height;
            delete comp.ball_material;
          } else {
            comp.ball_size = findComp.ball_size;
            comp.ball_height = findComp.ball_height;
            comp.ball_material = findComp.ball_material;
          }

          if (comp.ball_type === SPHEROID) {
            comp.ball_mid_diameter = findComp.ball_mid_diameter;
          } else {
            delete comp.ball_mid_diameter;
          }
        } else {
          delete comp.gap_size;
          delete comp.ball_size;
          delete comp.ball_height;
          delete comp.ball_mid_diameter;
          delete comp.ball_type;
          delete comp.ball_material;
        }
      }
    })

    yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content }));

    const newPackageInfo = {
      name: rockyPackageInfo.name,
      id: rockyPackageInfo.id,
      designId: rockyPackageInfo.designId,
      content,
      projectId: currentProjectId,
      version: ROCKY_PACKAGE_SETUP_VERSION,
      category: rockyPackageInfo.category
    }
    // update package verification Info
    yield call(updatePackageVerificationPromise, [newPackageInfo])
  } else if (interfaceType === CARD_VERIFICATION) {
    yield put(saveCardPortSetups(data, ComponentSetups));
  } else if (interfaceType === PCB_CHANNEL) {
    yield call(savePCBChannelPortSetups, { data, ComponentSetups });
  }
}

function* clearStandbyModel(action) {
  const { component } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  _Interfaces.forEach(info => {
    info.Content.Components.forEach(comp => {
      if (comp.name === component) {
        comp.pins.forEach(pin => pin.stdModel = null);
      }
    })
  })
  const SETUP = RockySetup;
  // _Interfaces = checkLibraryInInterface({ Interfaces: _Interfaces, libraryObj: { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } });
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  const currentLibraryFileList = getLibraryFileList(info);
  yield put(updateCurrentLibraryFileList(currentLibraryFileList));
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* applyPortSetup(action) {
  const { RockyReducer: { rocky: { rockyInfo }, project: { currentChannelId, currentProjectId, openVerificationId, currentProjectInterfaceInfo, extraction, contentType, packageExtraction, currentPackageDesignId } } } = yield select();

  if (contentType === VERIFICATION) {
    try {
      const { copyChannelList } = getCopyPortSetupAllInterfaces({
        currentProjectId,
        currentChannelId,
        verificationName: rockyInfo.verificationName,
        verificationId: openVerificationId,
      });

      yield* copyChannelList.map(function* (info, Index) {
        const response = yield call(getSetupInfo, info.id);
        if (response) {
          const channelId = response.channelId;
          const currentChannelInfo = yield call(getCurrentChannelInfo, {
            currentProjectInterfaceInfo,
            projectId: currentProjectId,
            channelId
          });
          const projectType = yield call(getProjectType);
          const ComponentSetups = JSON.parse(JSON.stringify(action.ComponentSetups))
          const { interfaces } = yield call(getCopyInterfaces, { info: action.info, interfaces: response.interfaces, ComponentSetups: ComponentSetups, currentChannelInfo, projectType, extraction })
          // update copyChannelList content
          try {
            yield call(updateVerificationContent, {
              id: response.id,
              name: response.name,
              interfaces: interfaces,
              config: response.config,
              ifDoExtraction: response.ifDoExtraction,
              readyForSim: response.readyForSim,
              version: response.version,
              designVersion: response.designVersion ? response.designVersion : "0"
            })
            ByteSetupInfo.saveSetupInfo(info.id, {
              ...response,
              interfaces: interfaces
            });

          } catch (error) {
            console.error(error)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  } else {
    try {
      let currentVerificationList = packageVerificationConstructor.getChannelVerification(currentPackageDesignId)
      currentVerificationList = currentVerificationList.filter(item => ['BYTE', 'CLK'].includes(getInterfaceType(item.name)) && item.id !== openVerificationId)
      let newPackageInfoList = [];
      yield* currentVerificationList.map(function* (info, Index) {
        const response = yield call(getPackageVerificationInfoPromise, info.id);
        if (response) {
          const ComponentSetups = JSON.parse(JSON.stringify(action.ComponentSetups))
          const content = yield call(getCopyPackageInterface, { info: action.info, content: response.content, ComponentSetups, extraction: packageExtraction })
          newPackageInfoList.push({
            name: response.name,
            id: response.id,
            designId: response.designId,
            content,
            projectId: currentProjectId,
            version: ROCKY_PACKAGE_SETUP_VERSION,
            category: response.category
          })
        }
      })

      // update packageInfoList
      yield call(updatePackageVerificationPromise, newPackageInfoList)
    } catch (error) {
      console.error(error)
    }
  }
}

function* stimulusRandom() {
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  const signalsRandomStimulus = getRandom();

  function getRandom() {
    let signals = {}, used = [];
    _Interfaces[0].Content.Signals.forEach(s => {
      let num = getRndInteger(1, 127);
      while (used.includes(num)) {
        num = getRndInteger(1, 127);
      }
      used.push(num);
      signals[s.name] = num;  // Math.pow(2, 7)
    })
    return signals;
  }

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  _Interfaces.forEach(info => {
    //find component
    for (let i = 0; i < info.Content.Components.length; i++) {
      if (ChipTypes.includes(info.Content.Components[i].type)) {
        for (let j = 0; j < info.Content.Components[i].pins.length; j++) {
          const signal = info.Content.Components[i].pins[j].signal;
          if (info.Content.Components[i].pins[j].pinModels.length) {
            let pinModel = info.Content.Components[i].pins[j].pinModels.find(d => d.pinName === 'nd_in');
            if (!pinModel) continue;
            const split = pinModel.type.split('-');
            if (split[0] !== 'PRBS') continue;
            pinModel.type = `${split[0]}-${split[1]}-${signalsRandomStimulus[signal]}`;
          }
        }
      }
    }
  })
  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* getCurrentVerificationPkgInfo({ packageDesignId, rockyInfo, currentChannelInfo, currentProjectId, compName }) {
  const { RockyReducer: { project: { projectVersion } } } = yield select();
  const category = projectVersion === PROJECT_V2 ? 2 : 1;
  const isHavePackageVerification = yield call(getHavePackageVerificationPromise, { designId: packageDesignId, verificationId: rockyInfo.verificationId, category });
  let channelId = '';

  if (isHavePackageVerification && Object.keys(isHavePackageVerification).length) {
    // There is existing package data
    channelId = isHavePackageVerification.id
  } else {
    // There is no existing package data, need to find the corresponding package Layout data
    yield call(_getLayoutDB, { id: packageDesignId, save: false, fullLayers: false });
    const PCBComponentList = LayoutData.getComponentsList(packageDesignId);
    const compPinList = LayoutData.getCompPinsNets(packageDesignId);

    // get extraction Data
    const config = yield call(getPackageExtractionPromise, packageDesignId);
    let extractionConfig = {}
    if (config && Object.keys(config).length) {
      extractionConfig = config;
    } else {
      // If there is no package extraction data, submit a new one
      extractionConfig = new Extraction('', PACKAGE)
      yield call(updatePackageExtractionPromise, { designId: packageDesignId, extractionConfig })
    }

    const { DDR, Name } = currentChannelInfo;

    let data = [], error = [];
    for (let DDRInfo of DDR) {
      const { content, errorCompInfo } = yield call(getNewPackageInterface, DDRInfo.Interfaces, PCBComponentList, compPinList, packageDesignId, extractionConfig, compName)
      if ((errorCompInfo && Object.keys(errorCompInfo).length) || !content) {
        error.push({
          channelName: DDRInfo.Name,
          errorCompInfo
        })
      }
      data.push({
        content,
        designId: packageDesignId,
        name: DDRInfo.Name,
        projectId: currentProjectId,
        groupName: Name,
        version: ROCKY_PACKAGE_SETUP_VERSION,
        category: 1
      })
    }

    if (error && error.length) {
      yield put(updatePackageMsg(error))
      yield put(updatePackageLoading(false))
    } else {
      const packageLayoutList = yield call(updatePackageVerificationPromise, data)
      const findInfo = packageLayoutList.find(item => item.name === rockyInfo.verificationName)
      channelId = findInfo.id;
    }
  }

  return channelId
}

function* selectPackageLayoutModel(action) {
  const { packageInfo, compName } = action;
  const { id: packageDesignId, name } = packageInfo

  // Obtain data through the interface to see if there is a created package channel
  const { RockyReducer: { rocky: { rockyInfo }, project: { currentProjectId, currentChannelId } } } = yield select();

  // loading package panel
  yield put(updatePackageLoading(true))

  try {
    // // channel Interface
    let currentChannelInfo = yield call(getCurrentChannelInfo, {
      projectId: currentProjectId,
      channelId: currentChannelId
    });

    const channelId = yield call(getCurrentVerificationPkgInfo, { packageDesignId, rockyInfo, currentChannelInfo, currentProjectId, compName })

    if (channelId) {
      const pkg = {
        type: "Layout",
        packageLayoutInfo: {
          name: name,
          packageId: packageDesignId,
          channelId
        }
      }
      yield call(saveComponentPkgModel, { pkg, component: compName })
      yield call(_copyLayoutPackageModel, { compName, packageName: name, packageDesignId })
      yield put(updatePackageLoading('close'))
      // update package tree
    }
  } catch (error) {
    yield put(updatePackageLoading(false))
    console.error(error)
  }
  // upload project Tree
  yield put(openProject({ id: currentProjectId }));
}

function* getChannelVerifications({ openVerificationId, channels, currentChannelId, contentType, isAllChannel }) {
  let copySetupFinds = [];
  yield* channels.map(function* (channelId) {
    // channels.map((channelId) => {
    if (isAllChannel || (!isAllChannel && channelId === currentChannelId)) {
      const verifications = channelVerifications.getVerificationsByChannelId(channelId) || [];
      // let verificationList = [];
      let verificationList = [...verifications]

      let copyVerifications = verificationList.filter(item => item.type !== ROCKY_PDN)
      if (currentChannelId === channelId && openVerificationId) {
        copyVerifications = copyVerifications.filter(item => item.id !== openVerificationId)
      }
      copySetupFinds.push(...copyVerifications)
    }
  })
  return copySetupFinds
}

function* _copyLayoutPackageModel(action) {
  const { compName, packageName, packageDesignId } = action;
  const { RockyReducer: { rocky: { currentConfig }, project: { currentProjectId, currentChannelId, openVerificationId, contentType } } } = yield select();
  const channels = projectChannels.get(currentProjectId);
  const copySetupFinds = yield call(getChannelVerifications, { openVerificationId, channels, currentChannelId, contentType, isAllChannel: false })

  const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
  try {
    if (copySetupFinds && copySetupFinds.length > 0) {
      yield* copySetupFinds.map(function* (info, veIndex) {
        const response = yield call(getSetupInfo, info.id);
        if (response) {
          let _Interfaces = response.interfaces;
          _Interfaces = yield call(initDefaultInterfaces, { response, cmd_2t_mode });

          let copyStatus = false;
          _Interfaces.forEach((Interface) => {
            Interface.Content.Components.forEach(comp => {
              if (comp.name === compName && (!comp.pkg || comp.pkg.type === 'None')) {
                copyStatus = true
              }
            })
          })
          const channelId = response.channelId;
          const currentChannelInfo = yield call(getCurrentChannelInfo, {
            projectId: currentProjectId,
            channelId
          });
          if (copyStatus) {
            // Get the ID of the corresponding package
            const currentChannelId = yield call(getCurrentVerificationPkgInfo, { packageDesignId, rockyInfo: { verificationId: response.id, verificationName: response.name }, currentChannelInfo, currentProjectId, compName })

            if (currentChannelId) {
              const pkg = {
                type: "Layout",
                packageLayoutInfo: {
                  name: packageName,
                  packageId: packageDesignId,
                  channelId: currentChannelId
                }
              }
              _Interfaces.forEach((Interface) => {
                Interface.Content.Components.forEach(comp => {
                  if (comp.name === compName && (!comp.pkg || comp.pkg.type === 'None')) {
                    comp.pkg = pkg ? JSON.parse(JSON.stringify(pkg)) : comp.pkg;
                  }
                })
              })

              ByteSetupInfo.saveSetupInfo(info.id, {
                ...response,
                version: ROCKY_SETUP_VERSION,
                interfaces: JSON.parse(JSON.stringify(_Interfaces)),
                readyForSim: response.readyForSim,
                ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
              });
              yield call(updateVerificationContent, {
                id: info.id,
                name: response.name,
                interfaces: JSON.parse(JSON.stringify(_Interfaces)),
                config: response.config,
                ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
                readyForSim: response.readyForSim,
                version: ROCKY_SETUP_VERSION,
                designVersion: response.designVersion ? response.designVersion : "0"
              });
            }
          }
        }
      })

      yield delay(1000);
      yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
    }
  } catch (error) {
    console.error(error)
  }
}

function* getNewPackageInterface(Interfaces, LayoutComponentList, compPinList, packageDesignId, extractionConfig, compName) {
  const pcbInfo = yield call(getLayoutPCBInfo, packageDesignId, true);
  const { Components: PCBComponents } = Interfaces[0].Content;
  const pcbComponent = PCBComponents.find(item => item.name === compName)

  let bgaCompInfo = {}, _Signals = [], signalNets = [], errorCompInfo = {};
  const pcbPinNameList = pcbComponent.pins.map(it => it.pin)
  const _LayoutComponentList = LayoutComponentList.filter(item => item.pinList.length >= pcbComponent.pins.length)
  for (let component of _LayoutComponentList) {
    const { pinList, name, part } = component;
    const pinNameList = pinList.map(item => item.mNumber)
    const samePins = pcbComponent.pins.filter(item => pinNameList.includes(item.pin))
    const pcbPinList = compPinList[name];
    if (Object.keys(bgaCompInfo).length) { continue }
    if (samePins.length !== pcbPinNameList.length) {
      errorCompInfo.comp = name
      errorCompInfo.pins = pcbComponent.pins.filter(item => !pinNameList.includes(item.pin)).map(item => item.pin)
      continue
    }

    let notMatchList = [];
    let _pinValue = samePins.map(pinInfo => {
      const { pin, signal } = pinInfo;

      const compInfo = pcbPinList[pin] ? pcbPinList[pin] : {};
      // mPinList
      _Signals.push({ name: signal, nets: [compInfo.mName] })
      signalNets.push(compInfo.mName)
      const isPowerNets = isPowerGND(compInfo);

      if (isPowerNets) {
        notMatchList.push(pin)
      }
      return { net: compInfo.mName, pin, signal }
    })
    if (notMatchList && notMatchList.length) {
      errorCompInfo.comp = name
      errorCompInfo.pins = notMatchList
      continue
    }
    errorCompInfo = {};
    bgaCompInfo = { pins: _pinValue, part, name, type: 'BGA', model: {} };
  }

  if (!bgaCompInfo || !Object.keys(bgaCompInfo).length) { return { errorCompInfo } }

  const _Interfaces = [{
    Content: {
      Components: [bgaCompInfo],
      Signals: _Signals,
      PowerNets: [],
      ReferenceNets: []
    }
  }]

  const new_Interfaces = updateInterfaceInfo({ Interfaces: _Interfaces, pcbId: packageDesignId, pcbInfo, projectType: '', packageType: 'DIE' });
  let content = new_Interfaces[0].Content;

  if (signalNets.length) {
    // getPowerNets
    const _channelInfo = yield call(findRefNets, { pcbId: packageDesignId, signals: _Signals, isSave: true, channelInfo: { ...new_Interfaces[0] } });
    content = _channelInfo.Content;
  }

  const ports_generate_setup_list = getPortGenerateSetupList({ components: content.Components, designType: PACKAGE });
  const referenceNets = getDefaultReferenceNets(content.PowerNets);
  const referenceZ0 = content.Port_setups && content.Port_setups.length ? content.Port_setups[0].z0 : 50
  const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
    components: content.Components,
    signals: content.Signals,
    pcbInfo: pcbInfo,
    referenceNets,
    designId: packageDesignId,
    ports_generate_setup_list: ports_generate_setup_list,
    referenceZ0: referenceZ0,
    extractionType: extractionConfig.type
  });
  content.ReferenceNets = referenceNets;
  content.Port_setups = port_setups;
  content.Ports_generate_setup_list = setupList;

  content.Components.forEach(comp => {
    if (comp.type === 'BGA' || comp.type === 'DIE') {
      const currentPortInfo = setupList.find(item => item.component === comp.name)
      const portType = currentPortInfo && currentPortInfo.setup && currentPortInfo.setup.portType ? currentPortInfo.setup.portType : WAVE;
      comp = setDefaultPortData(comp, portType, permissionData.getRockyExtraction(), packageDesignId)
    }
  })
  return { content, errorCompInfo };
}

function* updatePackageSignalInfo(action) {
  const { obj } = action;
  const { RockyReducer: { rocky: { rockyPackageInfo, projectVersion } } } = yield select();
  const { designId } = rockyPackageInfo;
  yield put(updatePackageSetupLoading(true))

  let content = JSON.parse(JSON.stringify(rockyPackageInfo.content));
  const { signalNet, signalName, signals, signalGroup } = obj;
  const pcbInfo = yield call(getLayoutPCBInfo, designId);
  // Add or modify net
  const currentPkgInfo = pcbInfo.netsList.find(item => item.mName === signalNet);
  let isUpdateTable = false;

  if (currentPkgInfo) {
    const { mName, mPinList } = currentPkgInfo
    let _components = [...content.Components];
    const PCBComponentList = LayoutData.getComponentsList(designId);

    for (let pinInfo of mPinList) {
      const { mCompName, mPinNum } = pinInfo;
      const currentCompInfo = PCBComponentList.find(item => item.name === mCompName);
      const componentsIndex = _components.findIndex(item => item.name === mCompName);
      const _pinValue = {
        pin: mPinNum,
        net: mName,
        signal: signalName,
        signalGroup: signalGroup
      }
      if (componentsIndex > -1) {
        let pinsList = _components[componentsIndex].pins.filter(item => item.signal !== signalName || (item.signal === signalName && item.net === signalNet))
        _components[componentsIndex].pins = [...pinsList, _pinValue]
      } else {
        let type = checkCompsType(currentCompInfo.name, {}, currentCompInfo.pinList.length, currentCompInfo.part);
        if ([IGNORE, 'Unused'].includes(type)) {
          if (currentCompInfo.name.includes(BGA) || currentCompInfo.part.includes(BGA)) {
            type = BGA
          } else {
            type = DIE
          }
        }
        const bgaCompInfo = {
          pins: [_pinValue],
          part: currentCompInfo.part,
          name: currentCompInfo.name,
          type,
          model: {}
        };
        _components.push(bgaCompInfo)
        isUpdateTable = true;
      }
    }

    let newComponents = [];
    for (let component of _components) {
      const { pins } = component;
      const filterPins = pins.filter(item => item.signal !== signalName || (item.signal === signalName && item.net === signalNet))
      if (filterPins.length) {
        newComponents.push({
          ...component,
          pins: filterPins
        })
      } else {
        isUpdateTable = true;
      }
    }

    const sortComponents = newComponents.sort(typeSort)
    function typeSort(a, b) {
      let order = [DIE, BGA]
      return order.indexOf(a.type) - order.indexOf(b.type);
    }
    let sortSignal = signals, newSortPinsComponents = [...sortComponents]

    sortSignal = signals.sort((a, b) => { return signalGroupSortFun(a, b, 'signalGroup') })
    let list = sortSignal.map(item => { return `${item.signalGroup}-${item.name}` });
    newSortPinsComponents = sortComponents.map(item => {
      const { pins } = item;
      let sortPins = pins.sort(function (a, b) {
        return sortPinOrPinSetUpFormSignals(a, b, list, 'pin')
      })
      return { ...item, pins: sortPins }
    });

    yield call(updatePackageInfo, { content, components: newSortPinsComponents, signals: sortSignal, isUpdateTable })
  }
}

let findRefNetTask = null;
function* _updateRefNets() {
  const { RockyReducer: { rocky: { rockyPackageInfo } } } = yield select();
  yield delay(3000)
  if (!rockyPackageInfo || !rockyPackageInfo.content) {
    return;
  }
  //Find signals
  let content = rockyPackageInfo.content;
  const { Signals } = content;
  if (!Signals || !Signals.length) {
    return;
  }

  //cancel prev find ref nets task
  if (findRefNetTask && findRefNetTask) {
    yield cancel(findRefNetTask);
  }

  const pcbId = rockyPackageInfo.designId;
  findRefNetTask = yield fork(findRefNets, { pcbId, signals: Signals, channelInfo: { id: rockyPackageInfo.id, Content: content } })
}

function* findRefNets(action) {
  const { pcbId, signals, isSave, channelInfo } = action;
  //signals :[ { pcbId, signals:[ { name, nets:[] } ] } ]
  try {
    const key = yield call(findReferenceNetsBySignal, { signals: [{ pcbId, signals }] });
    if (!key) {
      //failed
      return channelInfo;
    }
    yield delay(1000);
    let res = yield call(getReferenceNetsBySignal, { key });
    let { status = 0, data = null } = res ? res : {};
    while (status === FIND_REF_NET_RUNNING) {
      yield delay(2000);
      res = yield call(getReferenceNetsBySignal, { key });
      if (!res) {
        break;
      }
      status = res.status;
      data = res.data;
    }

    if (status === FIND_REF_NET_FAILED || !data || !Array.isArray(data)) {
      //failed
      return channelInfo;
    }

    if (status === FIND_REF_NET_SUCCESS) {
      const _channelInfo = yield call(updateRefNetsToPowerNets, { pcbId, data, isSave, channelInfo })
      if (isSave) {
        return _channelInfo;
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateRefNetsToPowerNets(action) {
  const { pcbId, data, isSave, channelInfo: actionChannelInfo } = action;

  //get signals
  let _channelInfo = { ...actionChannelInfo };
  if (!_channelInfo || !_channelInfo.Content) {
    return;
  }
  let _content = _channelInfo.Content;
  let powerNets = _channelInfo.Content.PowerNets ? [..._channelInfo.Content.PowerNets] : [];
  const components = _channelInfo.Content.Components ? [..._channelInfo.Content.Components] : [];
  let signalNets = [];

  let compPowerNets = [];
  for (let comp of components) {
    if (!RLC_TYPES.includes(comp.type)) {
      continue;
    };
    for (let pin of comp.pins) {
      // power,ground nets
      if (!pin.signal) {
        compPowerNets.push(pin.net);
      }
    }
  };

  const currentPcbRefNets = data.find(item => item.pcbId === pcbId);
  currentPcbRefNets.nets = currentPcbRefNets ? currentPcbRefNets.nets.filter(item => !signalNets.includes(item)) : null;
  if (!currentPcbRefNets || !Array.isArray(currentPcbRefNets.nets)) {
    return;
  }

  //reference and not includes rlc components power nets
  const refNets = powerNets.filter(item => !!item.reference && !compPowerNets.includes(item.name));
  //find deleted reference nets name
  let deleteRefNets = [];
  refNets.forEach(item => {
    const find = currentPcbRefNets.nets.find(it => it === item.name);
    if (!find) {
      deleteRefNets.push(item.name);
    }
  })

  yield call(_getLayoutDB, { id: pcbId, save: false, fullLayers: true });
  //filter deleted ref nets
  powerNets = powerNets.filter(item => !deleteRefNets.includes(item.name));
  //update power nets(add new reference nets) 
  currentPcbRefNets.nets.forEach(item => {
    const index = powerNets.findIndex(net => net.name === item);

    const _LayoutData = LayoutData.getLayout(pcbId);
    const _netInfo = _LayoutData.mNetManager.GetNetFromName(item)
    const isPowerNets = isPowerGND(_netInfo);
    if (isPowerNets) {
      if (index > -1) {
        // powerNets[index].reference = true;
        if (powerNets[index].custom) {
          delete powerNets[index].custom;
        }
      } else {
        // let value = item === 'GND' ? '0' : '';
        powerNets.push({
          name: item,
          value: "0",
          // reference: true
        })
      }
    }
  });
  _content.PowerNets = [...powerNets]
  if (!_content.ReferenceNets || !_content.ReferenceNets.length) {
    const { RockyReducer: { project: { packageExtraction } } } = yield select();
    const ports_generate_setup_list = getPortGenerateSetupList({ components: components, designType: PACKAGE });
    const referenceNets = getDefaultReferenceNets(_content.PowerNets);
    const referenceZ0 = _content.Port_setups && _content.Port_setups.length ? _content.Port_setups[0].z0 : 50
    const pcbInfo = yield call(getLayoutPCBInfo, pcbId);
    const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
      components: components,
      signals: _content.Signals,
      pcbInfo: pcbInfo,
      referenceNets,
      designId: pcbId,
      ports_generate_setup_list: ports_generate_setup_list,
      referenceZ0: referenceZ0,
      extractionType: packageExtraction.type
    });

    _content.ReferenceNets = referenceNets;
    _content.Port_setups = port_setups;
    _content.Ports_generate_setup_list = setupList;
  }

  if (isSave) {
    return { ..._channelInfo, Content: _content };
  } else {
    const { RockyReducer: { rocky: { rockyPackageInfo } } } = yield select();
    if (rockyPackageInfo && rockyPackageInfo.id && rockyPackageInfo.content && rockyPackageInfo.id === _channelInfo.id) {
      yield put(updatePackageContentInfo({ PowerNets: powerNets }))
      yield put({ type: SAVE_ROCKY_PACKAGE_CONTENT_SERVER });
    }
  }
}

export function* getPackageContent(action) {
  const { packageVerificationId, notSave } = action;
  let { RockyReducer: { project: { pcbComponentsNets, currentProjectId, projectList } } } = yield select();
  try {
    yield put(updateContentLoading(true))
    const currentProject = projectList.find(item => item.id === currentProjectId);
    const projectVersion = currentProject ? currentProject.projectVersion : '';
    const isSEV = projectVersion === PROJECT_V2;
    const response = yield call(getPackageVerificationInfoPromise, packageVerificationId);
    if (response) {
      const design = designConstructor.getDesign(response.designId);
      if (response.designId) {
        yield call(auroraDBJson.getAuroraJson, response.designId, {})
      }
      const isPreLayout = design.vendor === PRELAYOUT;
      // loading pcb
      const { content, isUpdate } = yield call(initDefaultPackageInterfaces, { response, isPreLayout, isSEV });
      const PowerNets = content.PowerNets ? content.PowerNets : [];
      const PowerComponents = content.PowerComponents ? content.PowerComponents : [];
      let addPCB = [];
      if (!pcbComponentsNets.includes(response.designId)) {
        addPCB.push(response.designId)
      }
      if (!notSave) {
        if (!isPreLayout) {
          yield call(getLayoutPCBInfo, response.designId);
        }
        yield put(updateRockyPackageLayoutInfo({ ...response, content, version: ROCKY_PACKAGE_SETUP_VERSION }));
        if (isSEV && !PowerComponents.length && PowerNets.length) {
          yield call(getChannelPowerComponents, { channelInfo: response, prevComps: [], ReferenceNets: PowerNets.map(item => item.name), rockyType: PACKAGE })
        }
      }

      if (isUpdate) {
        // update init package setup Data
        yield call(updatePkgVerification, { response, content, currentProjectId })
      }
    }
    yield put(updateContentLoading(false))
  } catch (error) {
    console.error(error)
    yield put(updateContentLoading(false))
  }
}

function* updatePkgVerification({ response, content, currentProjectId }) {
  try {
    const newPackageInfo = [{
      name: response.name,
      id: response.id,
      designId: response.designId,
      content,
      projectId: currentProjectId,
      version: ROCKY_PACKAGE_SETUP_VERSION,
      category: response.category
    }]
    yield call(updatePackageVerificationPromise, newPackageInfo)
  } catch (error) {
    console.error(error)
  }
}

function* initDefaultPackageInterfaces({ response, isReplace, isPreLayout, isSEV }) {
  let content = JSON.parse(JSON.stringify(response.content));
  let isUpdate = false;
  if ((!response.version || response.version === "0" || isReplace) && content && Object.keys(content)) {
    const packageDesignId = response.designId;
    if (!isPreLayout) {
      const pcbInfo = yield call(getLayoutPCBInfo, packageDesignId);

      const new_Interfaces = updateInterfaceInfo({ Interfaces: [{ Content: content }], pcbId: packageDesignId, pcbInfo, projectType: '', packageType: 'DIE' });
      content = new_Interfaces[0].Content
      const { RockyReducer: { project: { packageExtraction } } } = yield select();
      const ports_generate_setup_list = getPortGenerateSetupList({ components: content.Components, designType: PACKAGE });
      const referenceNets = getDefaultReferenceNets(content.PowerNets);
      const referenceZ0 = content.Port_setups && content.Port_setups.length ? content.Port_setups[0].z0 : 50
      const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
        components: content.Components,
        signals: content.Signals,
        pcbInfo: pcbInfo,
        referenceNets,
        designId: packageDesignId,
        ports_generate_setup_list: ports_generate_setup_list,
        referenceZ0: referenceZ0,
        extractionType: packageExtraction ? packageExtraction.type : 'SIwave'
      });
      content.ReferenceNets = referenceNets;
      content.Port_setups = port_setups;
      content.Ports_generate_setup_list = setupList;
      isUpdate = true;
      content.Components.forEach(comp => {
        if (comp.type === 'BGA' || comp.type === 'DIE') {
          const currentPortInfo = setupList.find(item => item.component === comp.name)
          const portType = currentPortInfo && currentPortInfo.setup && currentPortInfo.setup.portType ? currentPortInfo.setup.portType : WAVE;
          comp = setDefaultPortData(comp, portType, permissionData.getRockyExtraction(), packageDesignId)
        }
      })
    }
  }
  if (isSEV && content && !content.PowerComponents) {
    content.PowerComponents = yield call(initPowerComponents, { channelInfo: response, prevComps: [], ReferenceNets: content.PowerNets.map(item => item.name), rockyType: PACKAGE })
  }
  if (isSEV && content && content.PowerComponents) {
    content.PowerComponents.forEach(comp => {
      if (comp.model) {
        comp.model.libraryType = comp.model.libraryType ? comp.model.libraryType : comp.model.libType ? comp.model.libType : '';
        comp.model.subcktName = comp.model.subcktName ? comp.model.subcktName : comp.model.subckt ? comp.model.subckt : '';
        comp.model.libraryId = comp.model.libraryId ? comp.model.libraryId : comp.model.id ? comp.model.id : '';
        comp.model.fileName = comp.model.fileName ? comp.model.fileName : comp.model.name ? comp.model.name : '';
        isUpdate = true;
      }
      comp.usage = comp.usage ? comp.usage : comp.type ? comp.type : '';
    })
  }
  if (content && Object.keys(content)) {
    if (content.Components) {
      content.Components.forEach(comp => {
        if (comp.ball_type !== NONE && !comp.ball_material) {
          comp.ball_material = SOLDER
          isUpdate = true;
        }
      })
    }
    // filter VTTNets in PowerNets
    if (isSEV && content.PowerNets) {
      const VTTNets = content && content.VTTNets ? content.VTTNets : [];
      content.PowerNets.forEach(net => {
        net.value = VTTNets.includes(net.name) && net.value ? net.value : '0';
      })
      isUpdate = true;
    }
  }
  return { content, isUpdate };
}

function* _updateMemorySelection(action) {
  const { checked, memoryName } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  _Interfaces.forEach(info => {
    //only allowed one memory to simulation
    info.Content.Components.forEach(item => {
      if (item.name === memoryName) {
        item.active = checked;
      } else if (item.type === MEMORY && checked) {
        item.active = false;
      }
    })
  })

  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* deletePackageSignal(action) {
  const { record: { signal } } = action;
  let { RockyReducer: { rocky: { rockyPackageInfo } } } = yield select();
  const { content } = rockyPackageInfo;
  const { Components, Signals } = content;
  yield put(updatePackageSetupLoading(true))
  const selectedSignals = Signals.filter(item => item.name !== signal)
  let newComponents = [];
  for (let component of Components) {
    const { pins } = component;
    const filterPins = pins.filter(item => item.signal !== signal)
    if (filterPins.length) {
      newComponents.push({
        ...component,
        pins: filterPins
      })
    }
  }
  yield call(updatePackageInfo, { content, components: newComponents, signals: selectedSignals })
}

function* changePackageSignalName(action) {
  const { record, prevRecord } = action;
  yield put(updatePackageSetupLoading(true))
  const { RockyReducer: { rocky: { rockyPackageInfo } } } = yield select();
  const { content } = rockyPackageInfo;
  const { Signals, Components } = content;

  const newSignals = Signals.map(item => {
    const { name } = item;
    if (prevRecord.signal === name) {
      item.name = record.signal;
      item.signalGroup = record.signalGroup;
    }
    return item
  })

  let sortNewSignals = JSON.parse(JSON.stringify(newSignals));
  sortNewSignals = sortNewSignals.sort(function (a, b) {
    if (!a.nets || !a.nets.length || !b.nets || !b.nets.length) {
      return 0
    }
    return signalGroupSortFun(a, b, 'signalGroup')
  })

  let list = sortNewSignals.map(item => { return `${item.signalGroup}-${item.name}` })

  let pinKeyList = []
  const newComponents = Components.map(item => {
    const { pins, name } = item;
    pins.forEach(pin => { if (pin.signal === prevRecord.signal) { pin.signal = record.signal; pin.signalGroup = record.signalGroup } })
    let sortPins = JSON.parse(JSON.stringify(pins))
    sortPins = sortPins.sort(function (a, b) {
      return sortPinOrPinSetUpFormSignals(a, b, list, 'pin')
    })
    pinKeyList = [...pinKeyList, ...sortPins.map(item => { return `${name}-${item.pin}` })]
    return { ...item, pins: sortPins }
  })
  let _Port_setups = JSON.parse(JSON.stringify(content.Port_setups));
  _Port_setups = _Port_setups.sort(function (a, b) {
    return sortPinOrPinSetUpFormSignals(a, b, pinKeyList)
  })

  const _content = {
    ...content,
    Signals: sortNewSignals,
    Components: newComponents,
    Port_setups: _Port_setups
  }

  // update package Info

  yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content: _content }));
  yield put(updatePackageSetupLoading(false))
  yield put({ type: SAVE_ROCKY_PACKAGE_CONTENT_SERVER });
}

function* updatePackageInfo({ content, components, signals, isUpdateTable }) {
  const { RockyReducer: { project: { packageExtraction }, rocky: { rockyPackageInfo } } } = yield select();
  const { designId } = rockyPackageInfo
  const pcbInfo = yield call(getLayoutPCBInfo, designId);

  const _Interfaces = [{
    Content: {
      ...content,
      Components: components,
      Signals: signals
    }
  }];
  const new_Interfaces = updateInterfaceInfo({ Interfaces: _Interfaces, pcbId: designId, pcbInfo, projectType: '' });
  content = {
    ...new_Interfaces[0].Content,
    PowerNets: content.PowerNets,
    ReferenceNets: content.ReferenceNets
  }

  // if (signals.length) {
  //   // get Power Nets
  //   const _channelInfo = yield call(findRefNets, { pcbId: designId, signals: signals, isSave: true, channelInfo: { ...new_Interfaces[0] } });
  //   content.PowerNets = _channelInfo.Content.PowerNets;
  // } else {
  //   content.PowerNets = []
  // }
  if (!signals.length) {
    content.PowerNets = []
  }

  const ports_generate_setup_list = getPortGenerateSetupList({ components: components, designType: PACKAGE });
  const referenceNets = getDefaultReferenceNets(content.PowerNets, content.ReferenceNets);
  const referenceZ0 = content.Port_setups && content.Port_setups.length ? content.Port_setups[0].z0 : 50
  const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
    components: components,
    signals: content.Signals,
    pcbInfo: pcbInfo,
    referenceNets,
    designId: designId,
    ports_generate_setup_list: ports_generate_setup_list,
    referenceZ0: referenceZ0,
    extractionType: packageExtraction.type
  });

  content.ReferenceNets = referenceNets;
  content.Port_setups = port_setups;
  content.Ports_generate_setup_list = setupList;

  yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content }));
  if (isUpdateTable) {
    yield put(isUpdatePackagePinStatus(true))
  }
  yield put(updatePackageSetupLoading(false))
  yield put({ type: SAVE_ROCKY_PACKAGE_CONTENT_SERVER });
}

function* addPackageVerification(action) {
  const { currentPackageChannelName, currentPackageDesignId, name } = action.obj;

  const { RockyReducer: { project: { currentProjectId, viewList, layout, selectedKeys, expandedKeys, projectVersion } } } = yield select();

  const content = new RockyPackageInterface()
  let data = [{
    content,
    designId: currentPackageDesignId,
    name: name,
    projectId: currentProjectId,
    groupName: currentPackageChannelName,
    version: ROCKY_PACKAGE_SETUP_VERSION,
    category: projectVersion === PROJECT_V2 ? 2 : 0
  }]

  const response = yield call(updatePackageVerificationPromise, data)
  packageVerificationConstructor.addVerificationsToPackageInfo(currentPackageDesignId, response[0])

  yield put(openProject({ id: currentProjectId }));
  yield delay(500)
  const currentInfo = response[0]
  if (currentInfo && Object.keys(currentInfo).length) {
    // After adding a new verification, you need to open Add Verification
    let selectKeys = selectedKeys.filter(item => {
      if (item) {
        const [_key] = strDelimited(item, "-");
        if (![PACKAGE_VERIFICATION, PACKAGE_RESULT, VERIFICATION, RESULT, PACKAGE_PDN_RESULT, PACKAGE_PDN].includes(_key)) {
          return item;
        }
      };
      return false;
    });
    selectKeys.push(`${PACKAGE_VERIFICATION}-${currentInfo.id}`);
    yield put(updateTreeSelectedKeys(selectKeys))

    let list = [];
    if (layout !== 3) {
      list = [...viewList, PACKAGE_VERIFICATION];
      list = [...new Set(list)];
      list = list.filter(item => ![RESULT, VERIFICATION, PACKAGE_RESULT, PACKAGE_PDN_RESULT].includes(item))
    } else {
      list = [PACKAGE_VERIFICATION];
    }
    yield put(updateViewList(list))


    let packageInfo = packageVerificationConstructor.getPackageInfo(currentPackageDesignId)
    let channelIndex = 0;
    channelIndex = packageInfo.findIndex(item => item.groupName === currentInfo.groupName)

    let new__expandedKeys = [...expandedKeys];
    if (!expandedKeys.includes(`${PACKAGE_CHANNEL}-${channelIndex}-${currentPackageDesignId}`)) {
      new__expandedKeys.push(`${PACKAGE_CHANNEL}-${channelIndex}-${currentPackageDesignId}`)
      yield put(expandMenu([...new__expandedKeys]));
    }

    yield put(changeTabMenu({
      menuType: "simulation",
      tabSelectKeys: ['monitor'],
      verificationName: currentInfo.name,
      currentVerificationId: currentInfo.verificationId,
      channelName: currentInfo.groupName,
      dataType: PACKAGE_VERIFICATION
    }))

    yield call(openRockyPage, { pageType: PACKAGE_VERIFICATION, id: currentInfo.id })
  }
}

function* addPackageChannel(action) {
  const { currentPackageDesignId, name } = action.obj;
  const { RockyReducer: { project: { currentProjectId, treeItems } } } = yield select();

  let _treeItems = [...treeItems]
  const projectIndex = treeItems[PROJECT_INDEX].children.findIndex(it => it.id === currentProjectId)
  const packageIndex = treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children.findIndex(it => it.id === currentPackageDesignId)
  let channelInfo = treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children[packageIndex].children
  const newPackageChannel = new packageChannelItem({ name, dataTypePrefix: 'package', id: currentPackageDesignId, index: channelInfo.length })

  channelInfo = channelInfo.filter(item => item.dataType !== PACKAGE_CHANNEL_CREATE)
  _treeItems[PROJECT_INDEX].children[projectIndex].children[PACKAGE_INDEX].children[packageIndex].children = [...channelInfo, newPackageChannel]
  yield put(projectMenu({ treeItems: [..._treeItems] }));
}

function* combinePackageInterface(action) {
  const { currentPackageDesignId, combineList } = action.obj
  const { RockyReducer: { project: { currentProjectId, viewList, layout, selectedKeys, expandedKeys, projectVersion } } } = yield select();

  try {

    yield put(updateLoading("packageCombineLoading", currentPackageDesignId))
    const currentInfo = yield call(createPackageMultiInterfacePromise, combineList)
    yield put(updateLoading("packageCombineLoading", null))
    if (currentInfo && Object.keys(currentInfo).length) {
      // After adding a new verification, you need to open Add Verification
      // update verification status list
      yield put(autoGetVerificationList({
        projectId: currentProjectId,
        currentPkgDesignId: currentPackageDesignId,
        verificationType: PACKAGE
      }));
      packageVerificationConstructor.addSEVVerificationsToPackageInfo(currentPackageDesignId, currentInfo)

      yield delay(500)
      let selectKeys = selectedKeys.filter(item => {
        if (item) {
          const [_key] = strDelimited(item, "-");
          if (![PACKAGE_VERIFICATION, PACKAGE_RESULT, VERIFICATION, RESULT, PACKAGE_PDN_RESULT, PACKAGE_PDN].includes(_key)) {
            return item;
          }
        };
        return false;
      });
      selectKeys.push(`${PACKAGE_VERIFICATION}-${currentInfo.id}`);
      yield put(updateTreeSelectedKeys(selectKeys))

      let list = [];
      if (layout !== 3) {
        list = [...viewList, PACKAGE_VERIFICATION];
        list = [...new Set(list)];
        list = list.filter(item => ![RESULT, VERIFICATION, PACKAGE_RESULT, PACKAGE_PDN_RESULT].includes(item))
      } else {
        list = [PACKAGE_VERIFICATION];
      }
      yield put(updateViewList(list))

      yield put(changeTabMenu({
        menuType: "simulation",
        tabSelectKeys: ['monitor'],
        verificationName: currentInfo.name,
        currentVerificationId: currentInfo.verificationId,
        channelName: currentInfo.name,
        dataType: PACKAGE_VERIFICATION,
      }))

      yield call(openRockyPage, { pageType: PACKAGE_VERIFICATION, id: currentInfo.id })
    } else {
      message.error('Combine Package Interface failed');
    }
  } catch (e) {
    console.error(e)
    yield put(updateLoading("packageCombineLoading", null))
  }

}

function* _saveVirtualComps(action) {
  const { virtualComponents } = action;
  const { RockyReducer: { rocky: { rockyInfo }, project: { projectVersion } } } = yield select();
  let _Interfaces = [...rockyInfo.Interfaces];
  const index = _Interfaces.findIndex(i => portSavePath.find(p => i.name.match(p)));
  _Interfaces[index].Content.VirtualComps = JSON.parse(JSON.stringify(virtualComponents));
  //todo auto generate virtual components ports setup

  const SETUP = RockySetup;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, rockyInfo.verificationName, yield call(getProjectType), projectVersion);
  yield put(updateRockyContentInfo({ ...info }));
  yield put(updateRockyInterfaces({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONTENT_TO_SERVER });
}

function* _editPKGCompType(action) {
  const { record } = action;
  const { RockyReducer: { rocky: { rockyPackageInfo } } } = yield select();
  const { content, designId } = rockyPackageInfo;
  const { Signals, Components, Port_setups, Ports_generate_setup_list, ReferenceNets, RefNets } = content;
  let _Components = [...Components],
    _Port_setups = [...Port_setups],
    _Ports_generate_setup_list = [...Ports_generate_setup_list];
  let isUpdateTable = false;

  try {
    const compName = record.comps[0].name;
    const index = _Components.findIndex(item => item.name === compName);
    if (index < 0) {
      return;
    }
    if ((record.type === IGNORE && [BGA, DIE].includes(_Components[index].type)) || (_Components[index].type === IGNORE && [BGA, DIE].includes(record.type))) {
      isUpdateTable = true;
    }
    _Components[index].type = record.type;
    if (record.type === IGNORE) {
      //remove port setups
      _Ports_generate_setup_list = _Ports_generate_setup_list.filter(item => item.component !== compName);
      _Port_setups = _Port_setups.filter(item => item.positive.component !== compName)
    } else if ([BGA, DIE].includes(record.type)) {
      //Add port setups

      const findPort = _Ports_generate_setup_list.find(item => item.component === compName);
      // BGA <-> DIE not need add port setups;
      if (!findPort) {
        const prevPortSetup = _Ports_generate_setup_list.length ? _Ports_generate_setup_list[0].setup : new PortsGenerationSetup({});

        _Ports_generate_setup_list.push({
          component: compName,
          setup: JSON.parse(JSON.stringify(prevPortSetup))
        });

        if (prevPortSetup.portType === GAP) {
          _Components[index].gap_size = "0";
        }
        if (BALL_TYPE_NONE_LIST.includes(prevPortSetup.portType)) {
          _Components[index].ball_type = BALL_TYPE_NONE;
        } else {
          const updateBallInfo = getUpdateCompBallInfoByPinSize(_Components[index], designId);
          Object.keys(updateBallInfo).forEach(key => { _Components[index][key] = updateBallInfo[key] })
        }
        const { RockyReducer: { project: { packageExtraction } } } = yield select();
        //add extraction ports by new comps
        const resInfo = addPortSetups({
          newComps: [_Components[index]],
          port_setups: _Port_setups,
          ports_generate_setup_list: _Ports_generate_setup_list,
          getDefaultPortSetupList,
          signals: Signals,
          referenceNets: ReferenceNets && ReferenceNets.length ? ReferenceNets : RefNets && RefNets.length ? RefNets : [],
          pcbInfo: DesignInfo.getPCBInfo(designId),
          designId,
          extractionType: packageExtraction ? packageExtraction.type : 'SIwave'
        });

        _Port_setups = resInfo.port_setups;
        _Ports_generate_setup_list = resInfo.ports_generate_setup_list;
      }
    }
    // update package Info
    const obj = sortContentFromCompType(_Components, _Port_setups, _Ports_generate_setup_list)
    const _content = {
      ...content,
      ...obj
    }

    yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content: _content }));
    yield put(updatePackageSetupLoading(false))
    yield put({ type: SAVE_ROCKY_PACKAGE_CONTENT_SERVER });
    if (isUpdateTable) {
      yield put(isUpdatePackagePinStatus(true))
    }
  } catch (error) {
    console.error(error)
  }
}


function* getCLKVerificationContent(action) {
  const { idList, status } = action;
  let newInterfaces = [], newResponse = {}, verificationId = null, responseList = [];
  for (let id of idList) {
    const response = yield call(getVerificationContentPromise, id);
    if (!response) { continue }
    // let Interfaces = JSON.parse(JSON.stringify(response.interfaces));

    let Interfaces = yield call(initDefaultInterfaces, { response });
    newInterfaces.push({ ...Interfaces[0], verificationId: response.id })

    responseList.push(response)

    const rule = /CLK_(ADR|CMD)/g;
    const _name = response.name.replace(rule, "CLK_ADR_CMD")
    newResponse = {
      ...response,
      name: _name
    }
    if (response.name.includes("CLK_ADR") || !verificationId) {
      verificationId = response.id;
    }
    delete newResponse.interfaces;
    delete newResponse.id;
    delete newResponse.subId;
  }

  yield call(_getSSNContent, {
    Interfaces: newInterfaces,
    response: newResponse,
    responseList: responseList,
    verificationId,
    loadPCB: (!newResponse.version || newResponse.version === "0") ? false : true,
    status
  })
}

function* _getSSNContent(action) {
  let { Interfaces, response, loadPCB, verificationId, responseList } = action;
  const SETUP = RockySetup;
  const { RockyReducer: { project: { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, extraction, currentProjectId, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, rocky: { currentConfig } } } = yield select();
  let newInterfaces = [...Interfaces];
  let saveToServer = false;
  const ddrType = yield call(getProjectType);
  // Currently supported layouts
  const usageTypes = ['Controller', 'Memory'];

  // Device corner
  newInterfaces.forEach(info => {
    const { Content } = info;
    Content.Components.forEach(comp => {
      if (usageTypes.includes(comp.type) && !comp.corner) {
        if (currentConfig.corners && currentConfig.corners.length) {
          // corners   Previous case
          const name = splitComponentName(comp.name)
          let data = currentConfig.corners.find(item => item.component === name);
          if (data && data.corner) {
            comp.corner = data.corner
          }
        } else {
          // Newly created case
          comp.corner = 'typ';
        }
        saveToServer = true;
      }
    });
  })
  let info = SETUP.mergeInterfacesInfo(newInterfaces, response.name, ddrType, PROJECT_V2);

  let pcbInfo = {};

  const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId);
  let isPreLayout = vendor === PRELAYOUT ? true : false;

  if (!isPreLayout) {
    pcbInfo = yield call(getLayoutPCBInfo, response.designId);
  } else {
    pcbInfo = DesignInfo.getPCBInfo(response.designId);
  }

  // pcb
  for (let index = 0; index < newInterfaces.length; index++) {
    const content = newInterfaces[index].Content;
    let { ReferenceNets, Port_setups, Ports_generate_setup_list } = content;

    let data = { ReferenceNets, Port_setups, Ports_generate_setup_list }
    if (!isPreLayout) {
      // when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
      // re generate port setups by port type is GAP, reference type is All pins
      if (judgeUpdateGapPortSetup(data, extraction)) {
        const updateInfo = updateExtractionGapPortsSetup(data, response.designId);
        data = updateInfo.newData;
        newInterfaces.find(i => portSavePath.find(p => i.name.match(p))).Content = { ...content, ...updateInfo.newData };
        saveToServer = true;
      }
    }
  }

  info = { ...info };
  // CurrentVerificationInfo.setInfo(item, info)
  const netsList = pcbInfo.netsList;

  const init = initInterfaces(newInterfaces, netsList);
  newInterfaces = init._interfaces;
  const save = init.save;

  const rockyInfo = {
    config: response.config,
    Interfaces: newInterfaces,
    info,
    verificationId,
    verificationName: response.name,
    ifDoExtraction: typeof (response.ifDoExtraction) === 'number' ? response.ifDoExtraction : 1,
    readyForSim: response.readyForSim ? response.readyForSim : 0,
    designVersion: response.designVersion,
    designId: response.designId,
    version: ROCKY_SETUP_VERSION
  };
  yield put(updateRockyContent(rockyInfo));

  const { RockyReducer: { project } } = yield select();
  //check library file if exist and update current verification library list
  yield put(getLibraryFileParse());
  let readyForSim = rockyInfo.readyForSim;

  if (loadPCB) {
    let pcbComponentsNets = project.pcbComponentsNets;
    let addPCB = [];
    if (!pcbComponentsNets.includes(response.designId)) {
      addPCB.push(response.designId)
    }
    yield put(saveComponentsNetsInProject(addPCB));
    // error check update readyForSim
    const errorCheck = getErrorCheck(rockyInfo, { ibisList, ibisAmiList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList, ebdList, socketTouchstoneList, socketSpiceList, customTouchstoneList, customSpiceList, onDieSpiceList }, extraction.channelType, ddrType);
    readyForSim = errorCheck ? 0 : 1;
  }

  if (readyForSim !== rockyInfo.readyForSim || save || saveToServer) {
    let updateStatus = true;
    if (readyForSim !== rockyInfo.readyForSim) {
      updateStatus = false;
    }
    yield put({ type: SAVE_CONTENT_TO_SERVER, updateStatus });
  }

  if (isRockyPuppeteer()) {
    // Update enableVoltage & modelType
    try {
      const { RockyReducer } = yield select();
      let Interfaces = RockyReducer.rocky.rockyInfo.Interfaces;
      const _Interfaces = yield call(updateSetup, Interfaces);
      yield put(updateInterfaces({ Interfaces: _Interfaces }));
    } catch (error) {
      console.error(error);
      return;
    }
  }

}

function* getPackagePDNContent(id) {
  const { RockyReducer: { project: { currentPackageDesignId } } } = yield select();
  try {
    yield put(updatePackagePDNInfo({ id, name: 'PDN', type: PACKAGE, content: {}, loading: true }));
    yield delay(100)
    yield call(_getLayoutDB, { id: currentPackageDesignId, save: false, fullLayers: true });
    const layoutComponent = LayoutData.getComponent(currentPackageDesignId, "VSS");
    const res = yield call(getRockyPackagePDNInfo, id);
    yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], currentVerificationId: res.verificationId || id, verificationName: res.name || '', channelName: null, dataType: PACKAGE_PDN }));
    yield put(getInterfaceMonitor(res.verificationId));
    yield call(initPackagePDN, res);
  } catch (e) {
    console.error(e)
    yield put(updatePackageInfo({ id, name: 'PDN', type: PACKAGE, content: {} }))
  }
}

function* initPackagePDN(res, notSave, returnIsSave) {
  const { designId, content } = res;
  let data = { ...content }, save = false, isUpdatePackage = false;

  const { DIE, BGA } = getPackageComponent(designId);
  if (!data.componentSetting) {
    const setting = new RockyPackageCompRLCPrefixLib({ Die: DIE && DIE.name ? [DIE.name] : [], Bga: BGA && BGA.name ? [BGA.name] : [] });
    data.componentSetting = setting;
    save = true
  }

  if (!data.powerDomains || !data.powerDomains.length) {
    data.powerDomains = [new PackagePDNRow()];
    save = true;
    isUpdatePackage = true;
  } else if (data.powerDomains.length) {
    const newDomains = [];
    for (let domain of data.powerDomains) {
      let _domain = { ...domain };
      if (!_domain.id) {
        _domain.id = newNanoId();

        if (!_domain.Components || !domain.Components.length) {
          const { PowerNets = [], ReferenceNets = [] } = _domain;
          let _components = getPowerDomainComponents({ PowerNets, ReferenceNets, designId, setting: data.componentSetting })
          // update decap model
          _components = yield call(updateComponentDecapModel, {
            components: _components,
            prevComps: [],
            designId: designId
          })

          _domain.Components = _components;
        }

        save = true;
      }
      newDomains.push(_domain);
    }
    if (save) {
      data.powerDomains = newDomains;
    }
  }

  if (!data.ballSize || Object.keys(data.ballSize).length !== 10) {
    data.ballSize = new BallSize(data.ballSize ? { ...data.ballSize, DIE, BGA, designId } : { DIE, BGA, designId })
    save = true
  }

  if (!data.extraction) {
    data.extraction = new PackageExtraction()
    save = true
  }

  if (!notSave) {
    const info = { ...res, type: PACKAGE, content: { ...data }, loading: false };
    if (save) {
      const isSava = yield call(getPackagePDNFromPcbPDN, { packageId: designId, packageRes: { ...info } })
      if (!isSava) { return }
    }

    yield put(updatePackagePDNInfo({ ...info }));
    PackagePCBPdnInfo.setInfo(info.id, info)
    if (save) {
      yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
    }
  } else {
    return returnIsSave ? { ...res, content: { ...data }, save } : { ...res, content: { ...data } }
  }
}

function* saveBallSize(action) {
  const { key, shape, size, height, middle, material, componentName } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let prevBallSize = content.ballSize || {}
  let _ballSize = key === 'DIE' ? { dieSize: size, dieHeight: height, dieShape: shape, dieMiddle: middle, dieMaterial: material } : { bgaSize: size, bgaHeight: height, bgaShape: shape, bgaMiddle: middle, bgaMaterial: material }
  if (key === 'IC') {
    _ballSize = { [componentName]: { ballSize: size, ballHeight: height, ballShape: shape, ballMiddle: middle, ballMaterial: material } }
    prevBallSize = content.ballSizeMap || {}
  }
  let ballSize = { ...prevBallSize, ..._ballSize }
  if (key === 'IC') {
    delete ballSize.bgaSize;
    delete ballSize.bgaHeight;
    delete ballSize.bgaShape;
    delete ballSize.bgaMiddle;
    delete ballSize.bgaMaterial;
    delete ballSize.dieSize;
    delete ballSize.dieHeight;
    delete ballSize.dieShape;
    delete ballSize.dieMiddle;
    delete ballSize.dieMaterial;
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, ballSizeMap: { ...ballSize } } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  } else {
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, ballSize: { ...ballSize } } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* updatePackageNets(action) {
  const { key, nets, id } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);
  if (findIndex > -1) {
    powerDomains[findIndex][key] = nets;
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* updatePDNVrm(action) {
  const { id, vrm } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);
  if (findIndex > -1) {
    powerDomains[findIndex].VRM = vrm;
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* updatePDNVoltage(action) {
  const { record } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === record.id);
  if (findIndex > -1) {
    powerDomains[findIndex].voltage = record.voltage;
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function getPowerDomainComponents(action) {
  const { PowerNets, ReferenceNets, designId, setting, Components } = action
  if (!PowerNets.length || !ReferenceNets.length) {
    return []
  }

  const components = getComponents([...PowerNets, ...ReferenceNets], designId, setting);
  const { Ignore, Die, Bga, Ipd } = setting;

  const getType = (name) => {
    if (Die.includes(name)) {
      return DIE
    }
    if (Bga.includes(name)) {
      return BGA
    }
    if (Ipd.includes(name)) {
      return IPD
    }
    return null
  }

  const _components = components.Components.filter(item => !Ignore.includes(item.name) && item.pins.some(p => PowerNets.includes(p.net))).map(item => {
    const type = getType(item.name) || item.COMP_TYPE;
    const value = type === CAP ? "" : item.value || "";
    const findComp = (Components || []).find(comp => comp.part === item.part && comp.model && comp.model.id);
    return { ...item, usage: type, COMP_TYPE: type, value, model: findComp ? { ...findComp.model } : item.model }
  })
  return _components
}

function* updatePackageComps() {
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const { content = {}, designId } = packagePDNInfo
  const { powerDomains = [], componentSetting } = content;
  const _setting = componentSetting || new RockyPackageCompRLCPrefixLib();

  let _powerDomains = [];
  for (let domain of powerDomains) {
    const { PowerNets, ReferenceNets, Components } = domain;
    const _components = getPowerDomainComponents({ PowerNets, ReferenceNets, designId, setting: _setting, Components })
    _powerDomains.push({ ...domain, Components: _components })
  }

  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains: _powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* updatePackagePorts(action) {
  const { id, ports, portType } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);
  if (findIndex > -1) {
    powerDomains[findIndex][portType] = ports;
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* switchPackagePorts(action) {
  const { lumped, compType } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const portType = compType === BGA ? 'bgaPorts' : 'diePorts';
  const { content = {}, designId } = packagePDNInfo;
  let powerDomains = content.powerDomains || [];
  if (lumped) {
    powerDomains.forEach(domain => domain[portType] = [])
  } else {
    try {
      yield call(_getLayoutDB, { id: designId, save: false, fullLayers: true });
    } catch (error) {
      console.error(error);
      return;
    }

    let _powerDomains = []
    for (let data of powerDomains) {
      if (data[portType] && data[portType].length) {
        _powerDomains.push(data)
        continue;
      }
      const { PowerNets, ReferenceNets, Components } = data

      const component = Components.find(item => item.COMP_TYPE === compType);
      if (!component) {
        _powerDomains.push(data)
        continue;
      }

      const DesignData = LayoutData.getLayout(designId);
      const filterData = getPortData(DesignData, component.name, PowerNets, ReferenceNets);
      const { powerPins, referencePins, data: PCBData } = filterData;
      if (PowerNets.length === 1 && powerPins[PowerNets[0]] && powerPins[PowerNets[0]].length === 1 &&
        ReferenceNets.length === 1 && referencePins[ReferenceNets[0]] && referencePins[ReferenceNets[0]].length === 1) {
        _powerDomains.push({ ...data, [portType]: [{ port: "1", powerPins: powerPins[PowerNets[0]], referencePins: referencePins[ReferenceNets[0]] }] })
      } else {
        const powers = Object.values(powerPins).flat(2), refe = Object.values(referencePins).flat(2);
        const pinsInfo = PCBData.getComponent(component.name).mPinsLocationList;
        const powerInfo = pinsInfo.filter(pin => powers.includes(pin.pin)).map(pin => ({ ...pin, ...pin.mLocation })),
          refeInfo = pinsInfo.filter(pin => refe.includes(pin.pin)).map(pin => ({ ...pin, ...pin.mLocation }));

        const powerInfoWithRefe = autoSplitRefeByPower(powerInfo, refeInfo, [], false);
        let portsTable = powers.map((p, index) => {
          const find = powerInfoWithRefe.find(item => item.pinNumber === p);
          return { port: String(index + 1), powerPins: [p], referencePins: find ? [...find.refePins] : [] }
        });
        _powerDomains.push({ ...data, [portType]: [...portsTable] })
      }
    }
    powerDomains = [..._powerDomains];
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* splitComponentPart(action) {
  const { id, compList, part, newPart } = action;

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);

  if (findIndex > -1) {
    powerDomains[findIndex].Components.forEach(item => {
      if (item.part === part && compList.includes(item.name)) {
        item.part = newPart;
      }
    })
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* mergeComponents(action) {
  const { mergeList, id } = action;

  if (!mergeList.length) {
    return;
  }

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);

  if (findIndex > -1) {
    const comp = powerDomains[findIndex].Components.find(item => item.part === mergeList[0]) || {}
    powerDomains[findIndex].Components.forEach(item => {
      if (mergeList.includes(item.part)) {
        item.part = mergeList[0];
        item.value = comp.value || '';
        item.model = comp.model || {};
      }
    })
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* updateCompUsage(action) {
  const { id, compsName, part, checked } = action;

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);

  if (findIndex > -1) {
    powerDomains[findIndex].Components.forEach(item => {
      if (item.part === part && compsName.includes(item.name)) {
        item.usage = checked ? item.COMP_TYPE : 'Unused';
      }
    })

    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* removeComponents(action) {
  const { id, comps, part } = action;

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);

  if (findIndex > -1) {
    powerDomains[findIndex].Components.forEach(item => {
      if (item.part === part && comps.includes(item.name)) {
        item.usage = 'Removed';
      }
    })

    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* saveDecapModel(action) {
  const { record, model, value } = action

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === record.id);

  if (findIndex > -1) {
    const compNames = record.components.map(item => item.name)
    powerDomains[findIndex].Components.forEach(comp => {
      if (compNames.includes(comp.name)) {
        comp.value = value;
        comp.model = { ...model }
      }
    })

    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  }
}

function* savePackagePDNExtraction(action) {
  const { extraction } = action;

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}

  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, extraction: extraction } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* savePackageSetting(action) {
  const { componentSetting } = action;

  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  const { componentSetting: prevSetting = {} } = content;

  if (!_.isEqual(componentSetting, prevSetting)) {
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, componentSetting } }));
    yield delay(100)
    yield call(updatePackageComps)
  }
}

function* updatePowerDomainsComps(action) {
  const { id } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();

  if (packagePDNInfo.type === PCB) {
    yield delay(100)
    yield call(getVRMs, [id], true)
    return;
  }

  const content = packagePDNInfo.content || {};
  const designId = packagePDNInfo.designId;
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const { componentSetting } = content;
  const _setting = componentSetting || new RockyPackageCompRLCPrefixLib();
  const findIndex = powerDomains.findIndex(item => item.id === id);
  if (findIndex > -1) {
    const { PowerNets, ReferenceNets, Components } = powerDomains[findIndex];
    let _components = getPowerDomainComponents({ PowerNets, ReferenceNets, designId, setting: _setting, Components })
    _components = yield call(updateComponentDecapModel, {
      components: _components,
      prevComps: [],
      designId: designId
    })
    powerDomains[findIndex].Components = _components;
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* addNewPowerDomain() {
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const newRow = packagePDNInfo.type === PCB ? new PCBPDNRow() : new PackagePDNRow();
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains: [...powerDomains, newRow] } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* deletePowerDomain(action) {
  const { id } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {}
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const _powerDomains = powerDomains.filter(item => item.id !== id);

  if (packagePDNInfo.type === PCB) {
    // Update package PDN after deletion
    yield call(getPackagePDNFromPcbPDN, { res: { ...packagePDNInfo, content: { ...content, powerDomains: _powerDomains } } });
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains: _powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* updatePackagePortAndBall(action) {
  // packageVerificationConstructor
  const { oldLibraryId, libraryId } = action;
  const { RockyReducer: { project: { currentPackageDesignId, contentType, currentProjectId }, rocky: { packagePDNInfo, rockyPackageInfo } } } = yield select();
  let packageInfo = packageVerificationConstructor.getPackageInfo(currentPackageDesignId)
  let _packagePDNInfo = packageVerificationConstructor.getPackagePDN(currentPackageDesignId)
  const pcbInfo = yield call(getLayoutPCBInfo, currentPackageDesignId);

  let isWireBond = !oldLibraryId && libraryId ? true : false;
  let updateInterfaceList = [];
  if (packageInfo && packageInfo.length) {
    for (let channelInfo of packageInfo) {
      if (channelInfo && channelInfo.channels && channelInfo.channels.length) {
        updateInterfaceList.push(...channelInfo.channels)
      }
    }
  }

  // update pdn ball shape info in package
  if (_packagePDNInfo && _packagePDNInfo.id) {
    let pdnInfo = null;
    if (contentType === PACKAGE_PDN && _packagePDNInfo.id === packagePDNInfo.id) {
      pdnInfo = { ...packagePDNInfo }
    } else {
      let res = yield call(getRockyPackagePDNInfo, _packagePDNInfo.id);
      pdnInfo = yield call(initPackagePDN, res, true);
    }
    const { designId, content } = pdnInfo;
    let newPdnContent = JSON.parse(JSON.stringify(content))
    if (isWireBond) {
      newPdnContent.ballSize = {
        ...newPdnContent.ballSize,
        bgaShape: BALL_TYPE_NONE,
        dieShape: BALL_TYPE_NONE
      }
    } else {
      newPdnContent.ballSize = new BallSize(newPdnContent.ballSize ? { ...newPdnContent.ballSize, dieShape: null, bgaShape: null, DIE, BGA, designId } : { DIE, BGA, designId })
    }

    if (contentType === PACKAGE_PDN && _packagePDNInfo.id === packagePDNInfo.id) {
      yield put(updatePackagePDNInfo({ ...pdnInfo, content: { ...newPdnContent } }));
      yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
    } else {
      yield call(updateRockyPackagePDNInfo, { ...pdnInfo, content: { ...newPdnContent } })
    }
  }


  // update package verification ball info and port type.
  yield* updateInterfaceList.map(function* (interfaceInfo) {
    const { RockyReducer: { project: { packageExtraction } } } = yield select();
    const { id: packageVerificationId, designId } = interfaceInfo;
    let response = null, isCurrentInfo, content = null;
    if (contentType === PACKAGE_VERIFICATION && rockyPackageInfo && rockyPackageInfo.id && rockyPackageInfo.id === packageVerificationId) {
      response = { ...rockyPackageInfo };
      content = JSON.parse(JSON.stringify(rockyPackageInfo.content));
      isCurrentInfo = true;
    } else {
      response = yield call(getPackageVerificationInfoPromise, packageVerificationId);
      const initInfo = yield call(initDefaultPackageInterfaces, { response });
      content = JSON.parse(JSON.stringify(initInfo.content));
    }

    let ports_generate_setup_list = { ...content.Ports_generate_setup_list }
    if (isWireBond) {
      ports_generate_setup_list = content.Ports_generate_setup_list.map(item => {
        let setup = {}
        if (isWireBond) {
          setup = {
            portType: PIN_GROUP,
            referenceType: ALL_PINS
          }
        }
        return { ...item, setup };
      })
    } else {
      ports_generate_setup_list = getPortGenerateSetupList({ components: content.Components, designType: PACKAGE });
    }

    const referenceZ0 = content.Port_setups && content.Port_setups.length ? content.Port_setups[0].z0 : 50
    const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
      components: content.Components,
      signals: content.Signals,
      pcbInfo: pcbInfo,
      referenceNets: content.RefNets,
      designId: designId,
      ports_generate_setup_list: ports_generate_setup_list,
      referenceZ0: referenceZ0,
      extractionType: packageExtraction ? packageExtraction.type : 'SIwave'
    });
    content.Port_setups = port_setups;
    content.Ports_generate_setup_list = setupList;

    content.Components.forEach(comp => {
      if (isWireBond) {
        comp.ball_type = BALL_TYPE_NONE;
        delete comp.ball_size;
        delete comp.ball_height;
        delete comp.ball_mid_diameter;
        delete comp.gap_size;
      } else {
        if ([BGA, DIE].includes(comp.type)) {
          const currentPortInfo = setupList.find(item => item.component === comp.name)
          const portType = currentPortInfo && currentPortInfo.setup && currentPortInfo.setup.portType ? currentPortInfo.setup.portType : WAVE;
          comp = setDefaultPortData(comp, portType, permissionData.getRockyExtraction(), designId)
        }
      }
    })

    if (isCurrentInfo) {
      yield put(updateRockyPackageLayoutInfo({ ...rockyPackageInfo, content, version: ROCKY_PACKAGE_SETUP_VERSION }));
    }
    yield call(updatePkgVerification, { response, content, currentProjectId })
  })
}

function* initPCBChannelInfo({ res, currentProjectInterfaceInfo, currentProjectId, extraction, currentConfig, pcbInfo, isSEV }) {
  let channelInfo = { ...res }
  if ((!res.version || res.version === "0") && res.contentDTO) {
    // init value
    const { contentDTO, channelId, designId } = res;
    const currentChannelInfo = yield call(getCurrentChannelInfo, {
      currentProjectInterfaceInfo,
      projectId: currentProjectId,
      channelId
    });
    const cmd_2t_mode = currentConfig && currentConfig.cmd_2t_mode ? currentConfig.cmd_2t_mode : false;
    let Interfaces = [{ Content: contentDTO, name: "Byte_CLK" }]
    Interfaces = updateInterfaceInfo({ Interfaces, pcbId: designId, pcbInfo, projectType: yield call(getProjectType), currentChannelInfo, cmd_2t_mode, isAllInterface: true });
    const content = Interfaces[0].Content;
    const _content = res.contentDTO;
    const ports_generate_setup_list = getPortGenerateSetupList({ components: content.Components });
    const _ports_generate_setup_list = _content.Ports_generate_setup_list && _content.Ports_generate_setup_list.length ? _content.Ports_generate_setup_list : ports_generate_setup_list;
    const referenceNets = getDefaultReferenceNets(content.PowerNets);
    const _referenceNets = _content.ReferenceNets && _content.ReferenceNets.length ? _content.ReferenceNets : referenceNets;
    const referenceZ0 = content.Port_setups && content.Port_setups.length ? content.Port_setups[0].z0 : 50
    const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
      components: content.Components,
      signals: content.Signals,
      pcbInfo: pcbInfo,
      referenceNets: _referenceNets,
      designId: designId,
      ports_generate_setup_list: _ports_generate_setup_list,
      types: ChipTypes,
      referenceZ0: referenceZ0,
      extractionType: extraction && extraction.channelType ? extraction.channelType : "SIwave"
    });
    content.ReferenceNets = _referenceNets;
    content.Port_setups = port_setups;
    content.Ports_generate_setup_list = setupList;
    content.Components.forEach(comp => {
      if (ChipTypes.includes(comp.type)) {
        comp = setDefaultPortData(comp, permissionData.getRockyPortType(), permissionData.getRockyExtraction(), designId)
      }
    })
    if (isSEV) {
      const VTTNets = content && content.VTTNets ? content.VTTNets : [];
      content.PowerNets.forEach(net => {
        net.value = VTTNets.includes(net.name) && net.value ? net.value : "0";
      })
    }

    channelInfo = {
      ...res,
      contentDTO: content,
      version: ROCKY_SETUP_VERSION
    }

    if (isSEV && channelInfo.contentDTO && !channelInfo.contentDTO.PowerComponents) {
      const content = channelInfo.contentDTO;
      content.PowerComponents = yield call(initPowerComponents, { channelInfo: channelInfo, prevComps: [], ReferenceNets: content.PowerNets.map(item => item.name), rockyType: PCB });
      channelInfo = {
        ...channelInfo,
        contentDTO: content
      }
    }
    if (isSEV && channelInfo && channelInfo.contentDTO.PowerComponents) {
      const content = channelInfo.contentDTO;
      content.PowerComponents.forEach(comp => {
        if (comp.model) {
          comp.model.libraryType = comp.model.libraryType ? comp.model.libraryType : comp.model.libType ? comp.model.libType : '';
          comp.model.subcktName = comp.model.subcktName ? comp.model.subcktName : comp.model.subckt ? comp.model.subckt : '';
          comp.model.libraryId = comp.model.libraryId ? comp.model.libraryId : comp.model.id ? comp.model.id : '';
          comp.model.fileName = comp.model.fileName ? comp.model.fileName : comp.model.name ? comp.model.name : '';
        }
        comp.usage = comp.usage ? comp.usage : comp.type ? comp.type : '';
      })
      channelInfo = {
        ...channelInfo,
        contentDTO: content,
      }
    }
    PCBChannelContentConstructor.setInfo(res.id, channelInfo)
    yield call(updatePCBChannelContentPromise, channelInfo)
  }
  return channelInfo
}

function* getPCBChannelContent(action) {
  const { id, notUpdateMonitor } = action;
  try {
    yield put(updateContentLoading(true))
    const { RockyReducer: { rocky: { currentConfig }, project: { currentProjectInterfaceInfo, currentProjectId, extraction, projectList } } } = yield select();
    let res = yield call(PCBChannelContentConstructor.getPcbChannelInfo, id, true)
    if (res) {
      const currentProject = projectList.find(item => item.id === currentProjectId)
      const projectVersion = currentProject && currentProject.projectVersion ? currentProject.projectVersion : '';
      const isSEV = projectVersion === PROJECT_V2;
      const pcbInfo = yield call(getLayoutPCBInfo, res.designId);
      if (res.designId) {
        yield call(auroraDBJson.getAuroraJson, res.designId)
      }
      let channelInfo = yield call(initPCBChannelInfo, { res, currentProjectInterfaceInfo, currentProjectId, extraction, currentConfig, pcbInfo, isSEV })
      const PowerComponents = channelInfo && channelInfo.contentDTO && channelInfo.contentDTO.PowerComponents ? channelInfo.contentDTO.PowerComponents : []
      const PowerNets = channelInfo && channelInfo.contentDTO && channelInfo.contentDTO.PowerNets ? channelInfo.contentDTO.PowerNets : []
      if (!notUpdateMonitor) {
        yield put(openTabFooter())
        yield put(changeTabMenu({
          menuType: "simulation",
          tabSelectKeys: ['monitor'],
          verificationName: channelInfo.channelName,
          currentVerificationId: channelInfo.verificationId,
          channelName: channelInfo.channelName,
          currentChannelId: channelInfo.verificationId,
          dataType: PCB_CHANNEL
        }))
        yield put(getInterfaceMonitor(channelInfo.verificationId, PCB_CHANNEL))
      }
      yield put(updatePCBChannelContent({ ...channelInfo, loading: false }));
      if (isSEV && (!PowerComponents || !PowerComponents.length) && PowerNets.length) {
        yield call(getChannelPowerComponents, { channelInfo, prevComps: [], ReferenceNets: PowerNets.map(item => item.name), rockyType: PCB })
      }
    }
    yield put(updateContentLoading(false))
  } catch (error) {
    yield put(updateContentLoading(false))
    console.error(error)
  }
}

function* savePCBChannelPortSetups(action) {
  const { data, ComponentSetups } = action;
  try {
    const { RockyReducer: { rocky: { pcbChannelInfo } } } = yield select();
    if (!pcbChannelInfo) { return }
    const Ports_generate_setup_list = data.Ports_generate_setup_list || [];
    let _content = { ...pcbChannelInfo.contentDTO, ...data }
    _content.Components.forEach(comp => {
      const compName = splitComponentName(comp.name);
      const findComp = ComponentSetups.find(it => it.name === compName);
      const compPortSetup = Ports_generate_setup_list.find(it => it.component === compName) || { setup: {} };
      if (findComp) {
        if ([...USE_BALL_LIST].includes(compPortSetup.setup.portType)) {
          if (compPortSetup.setup.portType === GAP) {
            comp.gap_size = findComp.gap_size || "0";
          }
          comp.ball_type = findComp.ball_type;

          if (comp.ball_type === BALL_TYPE_NONE) {
            delete comp.ball_size;
            delete comp.ball_height;
            delete comp.ball_material;
          } else {
            comp.ball_size = findComp.ball_size;
            comp.ball_height = findComp.ball_height;
            comp.ball_material = findComp.ball_material;
          }

          if (comp.ball_type === SPHEROID) {
            comp.ball_mid_diameter = findComp.ball_mid_diameter;
          } else {
            delete comp.ball_mid_diameter;
          }
        } else {
          delete comp.gap_size;
          delete comp.ball_size;
          delete comp.ball_height;
          delete comp.ball_mid_diameter;
          delete comp.ball_type;
          delete comp.ball_material;
        }
      }
    })
    const channelInfo = {
      ...pcbChannelInfo,
      contentDTO: _content,
      version: ROCKY_SETUP_VERSION
    }
    PCBChannelContentConstructor.setInfo(pcbChannelInfo.id, channelInfo)
    yield call(updatePCBChannelContentPromise, { ...channelInfo })
    yield put(updatePCBChannelContent({ ...channelInfo }));
  } catch (error) {
    console.error(error)
  }
}

function* savePcbChannelContent(action) {
  const { info } = action;
  try {
    const { RockyReducer: { rocky: { pcbChannelInfo, infoErrorCheck } } } = yield select();
    if (!pcbChannelInfo || !info) { return }

    const channelInfo = {
      ...pcbChannelInfo,
      contentDTO: info,
      version: ROCKY_SETUP_VERSION
    }
    if (infoErrorCheck && infoErrorCheck.length) {
      const findCurrentErrorCheck = infoErrorCheck.find(item => item.verificationId === channelInfo.verificationId)
      if (findCurrentErrorCheck) {
        const { error: signalError } = yield call(getSignalGroupSimulationErrorCheck, { content: channelInfo.contentDTO, contentType: PCB, getStackError: true, currentDesignId: channelInfo && channelInfo.designId ? channelInfo.designId : null })
        let _infoErrorCheck = infoErrorCheck.filter(it => it.verificationId !== channelInfo.verificationId);
        if (signalError && signalError.length) {
          _infoErrorCheck.push({
            verificationId: channelInfo.verificationId,
            errorCheck: { error: signalError },
          })
        }
        yield put(updateErrorCheckList(_infoErrorCheck))
      }
    }

    PCBChannelContentConstructor.setInfo(pcbChannelInfo.id, channelInfo)
    yield call(updatePCBChannelContentPromise, { ...channelInfo })
    yield put(updatePCBChannelContent({ ...channelInfo }));
  } catch (error) {
    console.error(error)
  }

}

// PCB PDN
function* getPCBPDNContent(id) {
  try {
    yield put(updatePackagePDNInfo({ id, name: 'PDN', type: PCB, content: {}, loading: true }));
    yield delay(100)
    const res = yield call(getRockyPackagePDNInfo, id);
    yield call(_getLayoutDB, { id: res.designId, save: false, fullLayers: true });

    yield put(changeTabMenu({ menuType: "simulation", tabSelectKeys: ['monitor'], currentVerificationId: res.verificationId || id, verificationName: res.name || '', channelName: null, dataType: PCB_PDN }));
    yield put(getInterfaceMonitor(res.verificationId));

    yield call(initPCBPDN, res);
  } catch (e) {
    console.error(e)
    yield put(updatePackageInfo({ id, name: 'PDN', type: PCB, content: {} }))
  }
}

function* initPCBPDN(res, getInit) {
  const { content, designId } = res;
  let data = { ...content }, save = false, isUpdatePackage = false;
  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  const json = yield call(getChannelJson, currentProjectId);
  const { InterfaceList } = json;

  if (!data.componentSetting) {
    const setting = new RockyPCBCompRLCPrefixLib({})
    data.componentSetting = setting;
    save = true
  }

  if (!data.targetIC) {
    const targetIC = InterfaceList.map(item => item.PowerComponents).flat(2).find(item => item && item.usage === CONTROLLER);
    if (targetIC) {
      data.targetIC = targetIC.name;
      save = true;
    }
  }

  if (!data.vrms) {
    data.vrms = [];
    save = true;
  }

  if (!data.powerDomains || !data.powerDomains.length) {
    let _GroundNets = [], powerDomains = [], _PowerNets = [];
    for (let Interface of InterfaceList) {
      const { PowerNets = [], GroundNets = [] } = Interface;
      _PowerNets = [...new Set([..._PowerNets, ...PowerNets])]
      _GroundNets = [...new Set([..._GroundNets, ...GroundNets])]
    }

    _PowerNets = getRockyMainPowerNet(_PowerNets, data.targetIC, designId, data.componentSetting);
    const rowNets = _PowerNets.map(PowerNet => ({ PowerNets: [PowerNet], GroundNets: _GroundNets }))

    for (let rowNet of rowNets) {
      const { PowerNets, GroundNets } = rowNet;
      const row = new PCBPDNRow();
      row.PowerNets = PowerNets;
      row.ReferenceNets = GroundNets;
      const { componentSetting, targetIC } = data;
      const VRMInfo = yield call(getVRM, {
        PowerNets,
        ReferenceNets: GroundNets,
        includeExtended: true,
        ExtendPowerNets: [],
        setting: componentSetting,
        targetIC, designId
      });

      powerDomains.push({ ...row, ...VRMInfo })
    }
    data.powerDomains = powerDomains;
    save = true;
    isUpdatePackage = true;
  }

  if (!data.extraction) {
    data.extraction = new PackageExtraction()
    save = true
  }
  if (!data.ballSizeMap) {
    data.ballSizeMap = {
      [data.targetIC]: { ballSize: '300um', ballHeight: '300um', ballShape: CYLINDER, ballMiddle: '300um', ballMaterial: SOLDER }
    };
    save = true
  }
  const info = { ...res, type: PCB, loading: false, content: { ...data } }
  if (getInit) {
    if (save) {
      yield call(updateRockyPackagePDNInfo, info)
      return { pcbInfo: info };
    }
    return { pcbInfo: info };
  } else {
    if (isUpdatePackage) {
      yield call(getPackagePDNFromPcbPDN, { res: { ...info } })
    }

    PackagePCBPdnInfo.setInfo(info.id, info)
    yield put(updatePackagePDNInfo({ ...info }));
    if (save) {
      yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
    }
  }

}

function* getVRM(action) {
  const { designId, PowerNets, ReferenceNets, setting, targetIC, ExtendPowerNets = [], includeExtended, specify = [] } = action
  const loadSelect = [{ comp: targetIC }];
  const allComponents = getAllRockyComponents({ pcbId: designId, COMP_PREFIX_LIB: setting, isAC: true });
  const parts = specify.map(item => {
    const comp = allComponents.find(c => item === c.name);
    if (!comp) return null;
    return comp.part
  }).filter(item => !!item);
  let PMIC = [];
  yield* [...new Set(parts)].map(function* (part) {
    const type = yield call(getPMICPartType, part);
    const comps = allComponents.filter(item => item.part === part && specify.includes(item.name)).map(item => item.name);
    PMIC.push({ partName: part, type: type || SPECIALIZED, comps })
  })
  let VRMInfo = yield call(_getVRM, {
    designId,
    GroundNets: ReferenceNets,
    PowerNets: [...PowerNets],
    ExtendNets: [...ExtendPowerNets],
    COMP_PREFIX_LIB: setting,
    loadSelect,
    connectInductance: false,
    findExtend: includeExtended,
    isAC: true,
    powerSwitch: setting.powerSwitch,
    buckConverter: setting.discreteBuckConverter || [],
    doNotStuff: [],
    table: [],
    pinConnection: [],
    pinMap: [],
    PMIC
  })
  const { Components, DEBUG_MONITOR, PowerNets: _PowerNets, VRM } = VRMInfo;

  let _Components = yield call(updateComponentDecapModel, {
    components: Components,
    prevComps: [],
    designId: designId
  })

  return {
    Components: _Components,
    DEBUG_MONITOR,
    ExtendPowerNets: _PowerNets.filter(item => !PowerNets.includes(item)),
    VRM
  }
}

function* saveVrms(action) {
  const { vrms } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {};
  const _vrms = content.vrms;
  if (!_.isEqual(vrms, _vrms)) {
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, loading: true, content: { ...content, vrms } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
    yield delay(100)
    yield call(getVRMs)
  }
}

function* changeTargetIC(action) {
  const { value } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {};
  const _targetIC = content.targetIC;
  if (!_.isEqual(value, _targetIC)) {
    yield put(updatePackagePDNInfo({ ...packagePDNInfo, loading: true, content: { ...content, targetIC: value } }));
    yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
    yield delay(100)
    yield call(getVRMs)

  }
}

function* getVRMs(ids, updatePackagePdn) {
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, loading: true }));
  yield delay(100);
  const content = packagePDNInfo.content || {};
  const designId = packagePDNInfo.designId;
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const { componentSetting, targetIC, vrms } = content;
  for (let i = 0; i < powerDomains.length; i++) {
    const { PowerNets, ReferenceNets, includeExtended, id, Components } = powerDomains[i];
    if ((ids && ids.length && !ids.includes(id)) || !PowerNets.length || !ReferenceNets.length) {
      continue;
    }
    const VRMInfo = yield call(getVRM, {
      PowerNets,
      ReferenceNets,
      includeExtended: includeExtended,
      ExtendPowerNets: [],
      setting: componentSetting,
      targetIC,
      designId,
      specify: vrms
    });

    let partModel = []
    for (let component of Components) {
      if (!partModel.find(item => component && item.part === component.part)) {
        partModel.push({ part: component.part, model: component.model, value: component.value })
      }
    }
    VRMInfo.Components = VRMInfo.Components.map(comp => {
      const data = partModel.find(item => item.part === comp.part);
      return data ? { ...comp, ...data } : { ...comp }
    })
    powerDomains[i] = { ...powerDomains[i], ...VRMInfo };
  }
  if (updatePackagePdn) {
    yield call(getPackagePDNFromPcbPDN, { res: { ...packagePDNInfo, content: { ...content, powerDomains } } });
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, loading: false, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
}

function* updateIncludeExtend(action) {
  const { id, includeExtend } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  const content = packagePDNInfo.content || {};
  let powerDomains = content.powerDomains ? JSON.parse(JSON.stringify(content.powerDomains)) : [];
  const findIndex = powerDomains.findIndex(item => item.id === id);
  if (findIndex > -1) {
    powerDomains[findIndex].includeExtended = includeExtend;
  }
  yield put(updatePackagePDNInfo({ ...packagePDNInfo, content: { ...content, powerDomains } }));
  yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
  yield delay(100)
  yield call(getVRMs, [id])
}

export function* initPCBPackageContent(action) {
  const { initDataCheck, ssn, designId, channelId } = action;
  const { pcbPdn, packageSignal, packagePdn, pcbSignal } = initDataCheck;
  const { RockyReducer: { project: { currentProjectId, currentProjectInterfaceInfo, extraction }, rocky: { currentConfig } } } = yield select();
  if (!currentProjectId) { return }
  try {
    let packageId = "", _designId = "", pkgChannelId = "";
    // get pcb/package layout info
    const designInfo = designConstructor.getDesign(designId) || {};
    if (designInfo && designInfo.vendor !== PRELAYOUT) {
      _designId = designId;
      if (pcbPdn || pcbSignal || packagePdn) {
        yield call(_getLayoutDB, { id: _designId, save: false, fullLayers: true });
      }
    }

    if (packagePdn || pcbPdn || packageSignal) {
      let findPackageId = "";
      if (ssn && ssn[0] && ssn[0].soc && ssn[0].soc.pkg) {
        findPackageId = ssn[0].soc.pkg.packageId ? ssn[0].soc.pkg.packageId : "";
        pkgChannelId = ssn[0].soc.pkg.channelId ? ssn[0].soc.pkg.channelId : "";
      }
      const packageInfo = designConstructor.getDesign(findPackageId) || {};
      if (packageInfo && packageInfo.dataType === PACKAGE) {
        packageId = findPackageId
        yield call(_getLayoutDB, { id: packageId, save: false, fullLayers: true });
      }
    }

    /* pdn */
    if (pcbPdn && _designId) {
      const _pcbPDNInfo = designConstructor.getAvailableDesignPdnInfo(currentProjectId);
      if (_pcbPDNInfo && _pcbPDNInfo.id) {
        let pcbPDNRes = yield call(getRockyPackagePDNInfo, _pcbPDNInfo.id);
        // init pcb pdn
        const value = yield call(initPCBPDN, pcbPDNRes, true);
        if (value && value.pcbInfo) {
          const { pcbInfo } = value;
          let powerDomains = pcbInfo && pcbInfo.content && pcbInfo.content.powerDomains ? pcbInfo.content.powerDomains : [];
          let packageList = designConstructor.getAvailablePackageDesignValues(currentProjectId);
          if (packageList && packageList.length) {
            // Initialize package PDN via pcb PDN
            yield call(getPackagePdn, { designId, packages: packageList, pcbPowerDomains: powerDomains })
          }
        }
      }
    } else if (packagePdn && packageId) {
      let _packagePDNInfo = packageVerificationConstructor.getPackagePDN(packageId)
      if (_packagePDNInfo && _packagePDNInfo.id) {
        let res = yield call(getRockyPackagePDNInfo, _packagePDNInfo.id);
        // init package pdn
        const info = yield call(initPackagePDN, res, true);
        const _pcbPDNInfo = designConstructor.getAvailableDesignPdnInfo(currentProjectId);
        if (_pcbPDNInfo && _pcbPDNInfo.id) {
          let pcbPDNRes = yield call(getRockyPackagePDNInfo, _pcbPDNInfo.id);
          // Initialize package PDN via pcb PDN
          if (pcbPDNRes && pcbPDNRes.content && pcbPDNRes.content.powerDomains) {
            const newContent = yield call(getPowerDomain, { packagePdnInfo: info, packagDesigneId: packageId, pcbPowerDomains: pcbPDNRes.content.powerDomains, designId: _designId })
            const _packagePDNContentInfo = {
              ...info,
              content: {
                ...newContent
              }
            }
            PackagePCBPdnInfo.setInfo(_packagePDNContentInfo.id, _packagePDNContentInfo)
            yield call(updateRockyPackagePDNInfo, _packagePDNContentInfo)
          }
        } else {
          // Initialize package PDN without PCB PDN
          PackagePCBPdnInfo.setInfo(info.id, info)
          yield call(updateRockyPackagePDNInfo, info)
        }
      }
    }

    /*signal*/
    if (packageSignal && packageId) {
      // init package sinal data
      let Config = yield call(getPackageExtractionPromise, packageId)
      if (!Config || !Object.keys(Config)) {
        Config = new Extraction('', PACKAGE);
        yield call(updatePackageExtractionPromise, { designId: packageId, extractionConfig: Config })
      }
      let packageSignalInfo = yield call(getPackageVerificationInfoPromise, pkgChannelId);
      if (packageSignalInfo) {
        const { content, isUpdate } = yield call(initDefaultPackageInterfaces, { response: packageSignalInfo });
        if (isUpdate) {
          yield call(updatePkgVerification, { response: packageSignalInfo, content, currentProjectId })
        }
      }
    }

    if (pcbSignal && _designId) {
      // init pcb signal data
      const pcbSignalInfo = yield call(PCBChannelContentConstructor.getPcbChannelInfo, channelId, true)
      if (pcbSignalInfo) {
        const pcbInfo = yield call(getLayoutPCBInfo, pcbSignalInfo.designId);
        yield call(initPCBChannelInfo, { res: pcbSignalInfo, currentProjectInterfaceInfo, currentProjectId, extraction, currentConfig, pcbInfo })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export function* getPDNBeforeGetContent(action) {
  // PCB PDN not initialized, all packages PDN will be initialized
  // Package PDN not been initialized, initialize the package;
  const { designId, ssn } = action || {};
  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  try {
    if (ssn && ssn[0] && ssn[0].soc && ssn[0].soc.pkg && ssn[0].soc.pkg.packageId) {
      const currentPackageId = ssn[0].soc.pkg.packageId;
      yield call(_getLayoutDB, { id: currentPackageId, save: false, fullLayers: true });

      const packageInfo = designConstructor.getDesign(currentPackageId)
      if (packageInfo && packageInfo.dataType === PACKAGE) {
        let _packagePDNInfo = packageVerificationConstructor.getPackagePDN(currentPackageId)
        if (!_packagePDNInfo || !_packagePDNInfo.id) { return false }

        let res = yield call(getRockyPackagePDNInfo, _packagePDNInfo.id);
        const info = yield call(initPackagePDN, res, true, true);
        if (info && info.save) {
          const isSava = yield call(getPackagePDNFromPcbPDN, { packageId: currentPackageId, packageRes: { ...info }, updateImmediately: true })
          if (!isSava) { return true }
          yield put(updatePackagePDNInfo({ ...info }));
          PackagePCBPdnInfo.setInfo(info.id, info)
          yield call(updateRockyPackagePDNInfo, info)
          return true;
        } else {
          yield call(_getLayoutDB, { id: designId, save: false, fullLayers: true });
          const _pcbPDNInfo = designConstructor.getAvailableDesignPdnInfo(currentProjectId)
          if (!_pcbPDNInfo || !_pcbPDNInfo.id) { return false; }
          let pcbPDNRes = yield call(getRockyPackagePDNInfo, _pcbPDNInfo.id);

          const value = yield call(initPCBPDN, pcbPDNRes, true);
          if (value && value.pcbInfo) {
            // PDN not initialized, all packages will be initialized
            // When clicking on package PDN, if the PCB PDN has not been initialized yet, all packages will be initialized
            const { pcbInfo } = value;
            let powerDomains = pcbInfo && pcbInfo.content && pcbInfo.content.powerDomains ? pcbInfo.content.powerDomains : [];

            let packageIds = designConstructor.getAvailablePackageDesignValues(currentProjectId);
            if (packageIds && packageIds.length) {
              yield call(getPackagePdn, { designId, packages: packageIds, pcbPowerDomains: powerDomains })
            }
            return true;
          } else {
            yield put(updatePackagePDNInfo({ ...info }));
            PackagePCBPdnInfo.setInfo(info.id, info)
            yield call(updateRockyPackagePDNInfo, info)
          }
        }
        return false;
      }
    }
  } catch (error) {
    console.error(error)
    return false;
  }
}

export function* getPackagePDNFromPcbPDN(action) {
  const { res, packageRes, updateImmediately } = action;
  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  let powerDomains = [], designId = null, updateAllPackage = false;
  try {
    if (res && res.content && res.content.powerDomains) {
      //1. Click on the PCB PDN, where the data is not initialized. Initialize the PCB PDN and initialize the data of all PCKAGE PDNs
      //2. Modify the power net in the PCB PDN to change the data in the package PDN accordingly
      powerDomains = res.content.powerDomains;
      designId = res.designId;
    } else {
      // First upload the PCB and then upload the package. Initialize the package PDN through the PCB
      // If the uploaded PCB is a layout or if no PCB has been uploaded, return
      const pcbInfo = designConstructor.getAvailableDesignPdnInfo(currentProjectId)
      if (!pcbInfo || !pcbInfo.id) { return true }
      const _res = yield call(getRockyPackagePDNInfo, pcbInfo.id);
      // let _res = yield call(PackagePCBPdnInfo.getPCBPackagePdnInfo, pdnInfo.id)
      yield call(_getLayoutDB, { id: _res.designId, save: false, fullLayers: true });
      const value = yield call(initPCBPDN, _res, true);
      if (value && value.pcbInfo) {
        // When clicking on package PDN, if the PCB PDN has not been initialized yet, all packages will be initialized
        const { pcbInfo } = value;
        powerDomains = pcbInfo && pcbInfo.content && pcbInfo.content.powerDomains ? pcbInfo.content.powerDomains : [];
        updateAllPackage = true;
      } else {
        // When clicking on package PDN, if the PCB PDN has already been initialized, the opened package will be initialized
        powerDomains = _res && _res.content && _res.content.powerDomains ? _res.content.powerDomains : [];
      }
      designId = _res && _res.designId ? _res.designId : null;
    }

    if (!powerDomains) { return true }
    if (packageRes) {
      const newContent = yield call(getPowerDomain, { packagePdnInfo: packageRes, packagDesigneId: packageRes.designId, pcbPowerDomains: powerDomains, designId })
      let info = { ...packageRes, content: { ...newContent } };
      if (updateImmediately) {
        yield call(updateRockyPackagePDNInfo, info)
        PackagePCBPdnInfo.setInfo(info.id, info)
      } else {
        yield put(updatePackagePDNInfo({ ...info }));
        PackagePCBPdnInfo.setInfo(info.id, info)
        yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
      }
    }

    let packageIds = designConstructor.getAvailablePackageDesignValues(currentProjectId);
    let _packageIds = [];
    if (!packageRes) {
      _packageIds = [...packageIds]
    } else if (updateAllPackage) {
      _packageIds = packageIds.filter(item => item.id !== packageRes.designId)
    }

    if (_packageIds && _packageIds.length) {
      yield call(getPackagePdn, { designId, packages: _packageIds, pcbPowerDomains: powerDomains })
    }
  } catch (error) {
    console.error(error)
    return true;
  }
}

function* getPackagePdn(action) {
  const { packages, pcbPowerDomains, designId } = action;
  try {
    yield* packages.map(function* (info) {
      const { id, dataType } = info;
      if (dataType === PACKAGE_PRE_LAYOUT) { return }
      yield call(_getLayoutDB, { id, save: false, fullLayers: true });
      const currentVerification = packageVerificationConstructor.getPackagePDN(id) || {};
      if (!currentVerification || !currentVerification.id) { return }

      const res = yield call(PackagePCBPdnInfo.getPCBPackagePdnInfo, currentVerification.id)

      const newContent = yield call(getPowerDomain, { packagePdnInfo: res, packagDesigneId: id, pcbPowerDomains, designId })
      const _packagePDNInfo = {
        ...res,
        content: {
          ...newContent
        }
      }
      PackagePCBPdnInfo.setInfo(_packagePDNInfo.id, _packagePDNInfo)
      yield call(updateRockyPackagePDNInfo, _packagePDNInfo)
    })
  } catch (error) {
    console.error(error)
  }
}

function* getPowerDomain({ packagePdnInfo, packagDesigneId, pcbPowerDomains, designId }) {
  const content = JSON.parse(JSON.stringify(packagePdnInfo.content));
  const { RockyReducer: { project: { currentProjectId } } } = yield select();
  const { powerDomains } = content
  let _componentSetting = content.componentSetting;

  const { DIE, BGA } = getPackageComponent(packagDesigneId);
  if (!content.componentSetting) {
    _componentSetting = new RockyPackageCompRLCPrefixLib({ Die: DIE && DIE.name ? [DIE.name] : [], Bga: BGA && BGA.name ? [BGA.name] : [] });
    content.componentSetting = _componentSetting;
  }

  if (!content.ballSize || Object.keys(content.ballSize).length !== 8) {
    content.ballSize = new BallSize(content.ballSize ? { ...content.ballSize, DIE, BGA, designId: packagDesigneId } : { DIE, BGA, designId: packagDesigneId })
  }

  if (!content.extraction) {
    content.extraction = new PackageExtraction()
  }

  const PCBComponentList = LayoutData.getComponentsList(packagDesigneId);
  const _LayoutData = LayoutData.getLayout(packagDesigneId);
  const netList = _LayoutData && _LayoutData.mNetManager && _LayoutData.mNetManager.mNetList && _LayoutData.mNetManager.mNetList.mKeys ? _LayoutData.mNetManager.mNetList.mKeys : []

  let findInfo = [], ReferenceNets = [];
  if (netList.includes("VSS")) {
    ReferenceNets.push("VSS")
  }

  for (let component of PCBComponentList) {
    const { name } = component;
    for (let powerDomainInfo of pcbPowerDomains) {
      const controllerComp = powerDomainInfo.Components.find(item => item.usage === LOAD);
      if (!controllerComp) { continue }
      const basicInfo = {
        name: controllerComp && controllerComp.name ? controllerComp.name : null,
        designId: designId,
        nets: powerDomainInfo.PowerNets
      }
      const { nets } = checkConnectCompsPinsAndNets({ ...basicInfo }, { designId: packagDesigneId, name: name });
      if (nets && nets.length) {
        for (let PowerNet of nets) {
          const findIndex = findInfo.findIndex(item => item.powerNets.includes(PowerNet));
          if (findIndex < 0) {
            findInfo.push({ powerNets: [PowerNet], component: name })
          }
        }
      }
    }
  }

  let new_powerDomains = [], existModelParts = [];
  for (let info of findInfo) {
    const { powerNets } = info;
    let _components = getPowerDomainComponents({ PowerNets: [...powerNets], ReferenceNets: [...ReferenceNets], designId: packagDesigneId, setting: _componentSetting })

    // If PowerNets and ReferenceNets are the same, use the previous data directly
    let findPowerDomainInfo = powerDomains && powerDomains.length ? powerDomains.find(item => _.isEqual(item.PowerNets, powerNets) && _.isEqual(item.ReferenceNets, ReferenceNets)) : null;
    if (findPowerDomainInfo && findPowerDomainInfo.Components && findPowerDomainInfo.Components.length) {
      if (!findPowerDomainInfo.id) {
        findPowerDomainInfo.id = newNanoId();
      }
      new_powerDomains.push({ ...findPowerDomainInfo })
    } else {
      const newInfo = new PackagePDNRow();
      const info = yield call(updateComponentDecapModel, {
        designId: packagDesigneId,
        components: _components,
        prevComps: [],
        existModelParts,
        type: "re-assign",
        saveLib: false,
      });
      let _Components = info.components;
      existModelParts = info.existModelParts;

      new_powerDomains.push({
        ...newInfo,
        Components: _Components,
        PowerNets: [...powerNets],
        ReferenceNets: [...ReferenceNets]
      })
    }
  }
  return { ...content, powerDomains: new_powerDomains }
}

function* savePackageContentAndClear() {

}

function* _updateDiePortsByCPMPairs(action) {
  // onDie/Spice => CSM
  // change to "Pin Group"
  // CSM => onDie/Spice
  // change to "Lumped"
  const { cpmPairs, packageId, lumped } = action;
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  try {
    const packagePDN = packageVerificationConstructor.getPackagePDN(packageId);
    if (!packagePDN || !packagePDN.id) {
      return;
    }
    const pdnId = packagePDN.id;
    let _packagePDNInfo = packagePDNInfo;
    if (!packagePDNInfo || !packagePDNInfo.content || packagePDNInfo.id !== pdnId) {
      let res = yield call(getRockyPackagePDNInfo, pdnId);
      res = yield call(initPackagePDN, res, true);
      if (!res) {
        return;
      }
      _packagePDNInfo = { ...res, type: PACKAGE, };
    }
    if (!_packagePDNInfo || !_packagePDNInfo.content || !_packagePDNInfo.content.powerDomains) {
      return;
    }
    const powerDomains = _packagePDNInfo.content.powerDomains || [];

    yield call(_getLayoutDB, { id: packageId, save: false, fullLayers: true });
    for (let domain of powerDomains) {
      const { PowerNets = [], ReferenceNets = [], Components = [] } = domain;
      const component = Components.find(item => item.usage === "DIE");
      if (!component) {
        continue;
      }
      if (lumped) {
        domain.diePorts = [];
        continue
      }

      const cpmPins = cpmPairs.filter(item => [...PowerNets, ...ReferenceNets].includes(item.net));

      if (!cpmPins.length) {
        continue;
      }

      const newPorts = getDiePortsByCPM({
        packageId,
        component,
        PowerNets,
        ReferenceNets,
        cpmPins
      });
      domain.diePorts = newPorts || domain.diePorts;
    }

    //  Update backend data
    yield call(updateRockyPackagePDNInfo, _packagePDNInfo)
    PackagePCBPdnInfo.setInfo(_packagePDNInfo.id, _packagePDNInfo)
    yield put(updatePackagePDNInfo({ ..._packagePDNInfo }));
  } catch (error) {
    console.error(error)
  }
}

export function* getPCBPackageErrorCheck({ obj, ssn, channelId }) {
  const { signal, pdn, pcbSignal, pcbPDN, packageSignal, packagePdn, pcbId } = obj;
  const { RockyReducer: { rocky: { currentConfig }, project: { currentProjectInterfaceInfo, currentProjectId, extraction } } } = yield select();
  const interfaceList = ssn || [];
  const packageLayout = interfaceList && interfaceList.length ? interfaceList[0].soc : null;
  const packageId = packageLayout && packageLayout.pkg ? packageLayout.pkg.packageId : null;
  const pkgChannelId = packageLayout && packageLayout.pkg ? packageLayout.pkg.channelId : null;

  let packageSignalInfo = null, packagePdnInfo = null, pcbSignalInfo = null, pcbPdnInfo = null;
  let error = [], _stackupError = [];
  if (signal || pcbSignal) {
    pcbSignalInfo = yield call(PCBChannelContentConstructor.getPcbChannelInfo, channelId, true)
    if (pcbSignalInfo) {
      const pcbInfo = yield call(getLayoutPCBInfo, pcbSignalInfo.designId);
      yield call(initPCBChannelInfo, { res: pcbSignalInfo, currentProjectInterfaceInfo, currentProjectId, extraction, currentConfig, pcbInfo })

      const { error: signalError } = yield call(getSignalGroupSimulationErrorCheck, { content: pcbSignalInfo.contentDTO, contentType: PCB })
      if (signalError && signalError.length) {
        error.push(`--------------------- PCB Signal Group error ---------------------`, ...signalError)
      }

      if (!pcbPDN && !pdn) {
        const stackupError = yield call(stackupCheck, pcbSignalInfo.designId);
        if (stackupError && stackupError.length) {
          _stackupError.push(...stackupError)
        }
      }
    }
  }

  if ((signal || packageSignal) && packageId) {
    let Config = yield call(getPackageExtractionPromise, packageId)
    if (!Config || !Object.keys(Config)) {
      Config = new Extraction('', PACKAGE);
      yield call(updatePackageExtractionPromise, { designId: packageId, extractionConfig: Config })
    }

    packageSignalInfo = yield call(getPackageVerificationInfoPromise, pkgChannelId);
    if (packageSignalInfo) {
      const { content, isUpdate } = yield call(initDefaultPackageInterfaces, { response: packageSignalInfo });
      if (isUpdate) {
        yield call(updatePkgVerification, { response: packageSignalInfo, content, currentProjectId })
      }

      const { error: signalError } = yield call(getSignalGroupSimulationErrorCheck, { content: packageSignalInfo.content, contentType: PACKAGE })
      if (signalError && signalError.length) {
        error.push(`--------------------- Package Signal Group error ---------------------`, ...signalError)
      }

      if (!packagePdn && !pdn) {
        const stackupError = yield call(stackupCheck, packageSignalInfo.designId);
        if (stackupError && stackupError.length) {
          _stackupError.push(...stackupError)
        }
      }
    }
  }


  if (pdn || pcbPDN) {
    yield call(_getLayoutDB, { id: pcbId, save: false, fullLayers: true });
    const _pcbPDNInfo = designConstructor.getAvailableDesignPdnInfo(currentProjectId)
    if (!_pcbPDNInfo || !_pcbPDNInfo.id) { return {}; }
    pcbPdnInfo = yield call(getRockyPackagePDNInfo, _pcbPDNInfo.id);
    const { error: pdnError, stackupError } = yield call(getPdnSimulationErrorCheck, { id: pcbPdnInfo.id, contentType: PCB_PDN })
    if (stackupError && stackupError.length) {
      _stackupError.push(...stackupError)
    }
    if (pdnError && pdnError.error) {
      error.push(`--------------------- PCB PDN error ---------------------`, ...pdnError.error)
    }
  }

  if ((pdn || packagePdn) && packageId) {
    yield call(_getLayoutDB, { id: packageId, save: false, fullLayers: true });
    packagePdnInfo = packageVerificationConstructor.getPackagePDN(packageId);
    const { error: pdnError, stackupError } = yield call(getPdnSimulationErrorCheck, { id: packagePdnInfo.id, contentType: PACKAGE_PDN })
    if (stackupError && stackupError.length) {
      _stackupError.push(...stackupError)
    }
    if (pdnError && pdnError.error) {
      error.push(`--------------------- Package PDN error ---------------------`, ...pdnError.error)
    }
  }

  return { error, _stackupError, packageSignalInfo, packagePdnInfo, pcbSignalInfo, pcbPdnInfo }
}

function* startModelingExtraction(action) {
  const { RockyReducer: { project: { currentProjectId }, rockySSN: { ssn, verificationId, channelId, id } } } = yield select();
  const { obj } = action;
  const { signal, pdn, pcbSignal, pcbPDN, packageSignal, packagePdn, pcbId, mergeVerificationId } = obj;
  const interfaceList = ssn || [];
  const packageLayout = interfaceList && interfaceList.length ? interfaceList[0].soc : null;
  const packageId = packageLayout && packageLayout.pkg ? packageLayout.pkg.packageId : null;

  yield put(openTabFooter())
  yield put(changeTabMenu({
    menuType: "simulation",
    tabSelectKeys: ['monitor'],
    verificationName: "SSN",
    currentVerificationId: verificationId,
    channelId: channelId,
    channelName: RockyChannels.getName(channelId),
    dataType: pdn ? PDN_EXTRACTION : signal ? SIGNAL_EXTRACTION : SINGLE_PATTERN
  }))

  const { error, _stackupError, packageSignalInfo, packagePdnInfo, pcbSignalInfo, pcbPdnInfo } = yield call(getPCBPackageErrorCheck, { obj, ssn, channelId })

  let { RockyReducer: { rocky: { infoErrorCheck }, simulationReducer } } = yield select();
  let _infoErrorCheck = infoErrorCheck.filter(item => item.verificationId !== verificationId);
  if ((error && error.length) || (_stackupError && _stackupError.length)) {
    if ((_stackupError && _stackupError.length)) {
      let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};
      delete newSimulationReducer[verificationId];
      newSimulationReducer[verificationId] = {
        stackupError: _stackupError
      }
      yield put(updateSimulationReducer(newSimulationReducer));
    }


    if ((error && error.length)) {
      _infoErrorCheck.push({
        verificationId: verificationId,
        errorCheck: { error: error },
      })
    }
    yield put(updateErrorCheckList(_infoErrorCheck));
    return;
  }
  yield put(updateErrorCheckList(_infoErrorCheck));

  if (signal || pdn) {
    yield put(startMergeExtraction({ signal, pdn, verificationId, id, mergeVerificationId }))
  } else {
    yield put(startSeparateExtraction({ pcbSignal, pcbPDN, packageSignal, packagePdn, pcbId, packageId, packageSignalInfo, packagePdnInfo, pcbSignalInfo, pcbPdnInfo, currentProjectId }))
  }
}

function* reAssignDecapModel() {
  const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
  try {
    yield put(updateModelAssignLoading(true));
    if (packagePDNInfo && packagePDNInfo.content && packagePDNInfo.content.powerDomains) {
      let data = JSON.parse(JSON.stringify(packagePDNInfo.content.powerDomains));
      let designId = packagePDNInfo.designId;
      let existModelParts = [];
      yield* data.map(function* (item) {
        const info = yield call(updateComponentDecapModel, {
          designId,
          components: item.Components,
          prevComps: [],
          existModelParts,
          type: "re-assign",
          saveLib: false,
        });
        item.Components = info.components;
        existModelParts = info.existModelParts;
      })

      const _packagePDNInfo = {
        ...packagePDNInfo,
        content: {
          ...packagePDNInfo.content,
          powerDomains: data,
        }
      }
      yield put(updatePackagePDNInfo({ ..._packagePDNInfo }));
      yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
    }
    yield put(updateModelAssignLoading(false));
  } catch (error) {
    console.error(error)
  }
}

function* updateComponentDecapModel({
  components = [],
  prevComps = [],
  designId,
  type,
  existModelParts = [],
  newNoModelCompNames,
  voltage
}) {
  const { RockyReducer: { project: { defaultDecap } } } = yield select()
  let _components = [], _existModelParts = []
  try {
    const value = yield call(updateCompDecapModel, {
      components,
      prevComps,
      designId,
      type,
      existModelParts,
      newNoModelCompNames,
      voltage,
      defaultDecap,
      product: ROCKY
    })
    _components = value.components;
    _existModelParts = value.existModelParts;
  } catch (error) {
    console.error(error)
  }

  return type === "re-assign" ? {
    components: _components,
    existModelParts: _existModelParts,
  } : _components;
}

function* getPcbPackageChannelInfo(action) {
  const { channelId, packageChannelId, pcbPreLayoutId } = action;
  try {
    if (channelId) {
      yield call(getPCBChannelContent, { id: channelId, notUpdateMonitor: true })
    }
    if (packageChannelId) {
      yield call(getPackageContent, { packageVerificationId: packageChannelId })
    }
    if (pcbPreLayoutId) {
      yield call(getPCBPrelayoutContent, { pcbPreLayoutId })
    }
    yield put(updateSSNPackageInfo(true))
  } catch (error) {
    console.error(error)
    yield put(updateSSNPackageInfo(false))
  }

}

function* saveChannelDecapModel(action) {
  const { record, model, value, rockyType } = action
  yield put(updateDecapModelSetupLoading(true));
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let PowerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  let find = false;
  PowerComponents.forEach((item, index) => {
    if (item.part === record.part) {
      find = true;
      item.model = model;
      item.value = value;
      if (model) {
        item.model.libType = model.libraryType ? model.libraryType : '';
        item.model.subckt = model.subcktName ? model.subcktName : '';
        item.model.libraryId = model.id ? model.id : '';
        item.model.fileName = model.fileName ? model.fileName : model.name ? model.name : '';
      }
    }
  })
  if (find) {
    const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
    const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
    yield call(updateChannelContent, { contentType, updateContent: { PowerComponents: PowerComponents }, updateVerificationId });
  }
  yield put(updateDecapModelSetupLoading(false));
}

function* reAssignChannelDecapModel(action) {
  const { rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let PowerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  try {
    yield put(updateModelAssignLoading(true));
    if (content && PowerComponents) {
      const designId = rockyType === PCB ? pcbChannelInfo.designId : rockyPackageInfo.designId;
      let existModelParts = [];
      const info = yield call(updateComponentDecapModel, {
        designId,
        components: PowerComponents,
        prevComps: [],
        existModelParts,
        type: "re-assign",
        saveLib: false,
      });
      PowerComponents = info.components;
      existModelParts = info.existModelParts;
      PowerComponents.forEach((item, index) => {
        item.modelName = item.model && item.model.subcktName ? item.model.subcktName : item.model && item.model.name ? item.model.name : '';
        if (item.model) {
          item.model.libType = item.model.libType ? item.model.libType : item.model.libraryType ? item.model.libraryType : '';
          item.model.subckt = item.model.subckt ? item.model.subckt : item.model.subcktName ? item.model.subcktName : '';
          item.model.libraryId = item.model.libraryId ? item.model.libraryId : item.model.id ? item.model.id : '';
          item.model.fileName = item.model.fileName ? item.model.fileName : item.model.name ? item.model.name : '';
        }
      })
      const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
      const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
      yield call(updateChannelContent, { contentType, updateContent: { PowerComponents: PowerComponents }, updateVerificationId });
    }
    yield put(updateModelAssignLoading(false));
  } catch (error) {
    console.error(error)
  }
}

function* getChannelPowerComponents(action) {
  const { channelInfo, prevComps, ReferenceNets, rockyType } = action;
  const { RockyReducer: { rocky: { rockyPackageInfo } } } = yield select();
  if (rockyType === PCB) {
    let components = yield call(initPowerComponents, { channelInfo: channelInfo, prevComps: prevComps, ReferenceNets: ReferenceNets, rockyType: PCB });
    const _channelInfo = {
      ...channelInfo,
      contentDTO: { ...channelInfo.contentDTO, PowerComponents: components }
    }
    const content = _channelInfo.contentDTO || {};
    const compNames = content && content.Components ? content.Components.map(item => item.name) : [];
    components = components.filter(item => !compNames.includes(item.name));
    yield put(updatePCBChannelContent({ ..._channelInfo, loading: false }));
    yield call(updatePCBChannelContentPromise, { ..._channelInfo });
  } else {
    let components = yield call(initPowerComponents, { channelInfo: channelInfo, prevComps: prevComps, ReferenceNets: ReferenceNets, rockyType: PACKAGE });
    const content = rockyPackageInfo && rockyPackageInfo.content ? rockyPackageInfo.content : {};
    const compNames = content && content.Components ? content.Components.map(item => item.name) : [];
    components = components.filter(item => !compNames.includes(item.name));
    yield put(updatePackageContentInfo({ PowerComponents: components, loading: false }));
    yield put({ type: SAVE_ROCKY_PACKAGE_CONTENT_SERVER });
  }
}

function* updatePowerComponents(action) {
  const { nets, rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  yield put(updatePCBChannelContent({ ...pcbChannelInfo, loading: true }));
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let PowerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  yield call(getChannelPowerComponents, { channelInfo: rockyType === PCB ? pcbChannelInfo : rockyPackageInfo, prevComps: PowerComponents, ReferenceNets: nets, rockyType })
}

function* applyPowerComponents(action) {
  const { rockyType, currentType, tabKey, copyPowerComponents, copyPowerDomains } = action;
  yield put(updatePowerComponentsApplyLoading(true));
  if (rockyType === PCB) {
    if (currentType === CHANNEL) {
      const { packagePDNInfo } = yield call(getPCBChannelPDNPowerComponents, { rockyType, currentType, copyPowerComponents });
      if (packagePDNInfo && Object.keys(packagePDNInfo).length) {
        yield put(updatePackagePDNInfo({ ...packagePDNInfo }));
        yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
        message.success('Apply setup successful!');
      }
    } else if (currentType === PDN) {
      const { channelInfo } = yield call(getPCBChannelPDNPowerComponents, { rockyType, currentType, tabKey, copyPowerDomains });
      if (channelInfo && Object.keys(channelInfo).length) {
        yield put(updatePCBChannelContent({ ...channelInfo }));
        yield call(updatePCBChannelContentPromise, { ...channelInfo });
        message.success('Apply setup successful!');
      }
    }
  } else if (rockyType === PACKAGE) {
    if (currentType === CHANNEL) {
      const { packagePDNInfo } = yield call(getPackageChannelPDNPowerComponents, { currentType, copyPowerComponents });
      if (packagePDNInfo && Object.keys(packagePDNInfo).length) {
        yield put(updatePackagePDNInfo({ ...packagePDNInfo }));
        yield put({ type: SAVE_ROCKY_PACKAGE_PDN_SERVER });
        message.success('Apply setup successful!');
      }
    } else if (currentType === PDN) {
      const { channelInfo } = yield call(getPackageChannelPDNPowerComponents, { currentType, tabKey, copyPowerDomains });
      if (channelInfo && Object.keys(channelInfo).length) {
        yield call(updatePackageVerificationPromise, [{ ...channelInfo }]);
        yield put(updateRockyPackageLayoutInfo({ ...channelInfo }));
        message.success('Apply setup successful!');
      }
    }
  }
  yield put(updatePowerComponentsApplyLoading(false));
}

function* getPackageChannelPDNPowerComponents(action) {
  const { rockyType, currentType, tabKey, copyPowerComponents, copyPowerDomains } = action;
  const { RockyReducer: { project: { currentProjectId, projectList }, rocky: { packagePDNInfo, rockyPackageInfo } } } = yield select();
  if (currentType === CHANNEL) {
    //get pdn info
    const currentProject = projectList.find(item => item.id === currentProjectId);
    const PCBPKGInfo = currentProject.children.find(item => item.name === PACKAGE);
    const designId = PCBPKGInfo && PCBPKGInfo.children && PCBPKGInfo.children.length ? PCBPKGInfo.children[0].id : rockyPackageInfo && rockyPackageInfo.designId ? rockyPackageInfo.designId : null;
    const pdnInfo = yield call(getRockyPackagePDN, designId);
    const pdnId = pdnInfo && pdnInfo.id ? pdnInfo.id : null;
    let _packagePDNInfo = yield call(getRockyPackagePDNInfo, pdnId);
    _packagePDNInfo = yield call(initPackagePDN, _packagePDNInfo, true, true);
    const pdnContent = _packagePDNInfo && _packagePDNInfo.content ? _packagePDNInfo.content : {};
    const powerDomains = pdnContent && pdnContent.powerDomains ? pdnContent.powerDomains : [];

    const PowerComponents = [...copyPowerComponents];
    const { powerDomains: _powerDomains } = yield call(applyPowerComponentsHelper, { powerDomains, PowerComponents, currentType });
    const applyPackagePDNInfo = { ..._packagePDNInfo, type: rockyType, loading: false, content: { ...pdnContent, powerDomains: _powerDomains } };
    return { packagePDNInfo: applyPackagePDNInfo }
  } else if (currentType === PDN) {
    let _tabKey = tabKey.replace(/\s+/g, "");
    _tabKey = _tabKey.split(',');
    const designId = packagePDNInfo && packagePDNInfo.designId ? packagePDNInfo.designId : '';
    const channelList = yield call(getPackageVerificationListPromise, designId);
    const _channelId = channelList && channelList.length && channelList[0].channels && channelList[0].channels[0] ? channelList[0].channels[0].id : '';

    const currentProject = projectList.find(item => item.id === currentProjectId);
    const PCBPKGInfo = currentProject.children.find(item => item.name === PACKAGE);
    const currentChannel = PCBPKGInfo && PCBPKGInfo.children && PCBPKGInfo.children.length ? PCBPKGInfo.children.find(item => item.id === designId) : null;
    const currentPackage = currentChannel && currentChannel.children && currentChannel.children.length ? currentChannel.children.find(item => item.name !== PDN) : null;
    const channelId = currentPackage && currentPackage.id ? currentPackage.id : _channelId ? _channelId : null;
    let channelInterface = yield call(getPackageVerificationInfoPromise, channelId);
    const { content } = yield call(initDefaultPackageInterfaces, { response: channelInterface })
    if (content) {
      channelInterface = { ...channelInterface, content: { ...content } }
    }
    const channelContent = channelInterface && channelInterface.content ? channelInterface.content : {};
    const PowerComponents = channelContent && channelContent.PowerComponents ? channelContent.PowerComponents : [];

    const powerDomains = [...copyPowerDomains]
    const currentComponent = powerDomains.find(item => JSON.stringify(item.PowerNets) === JSON.stringify(_tabKey));
    const components = currentComponent && currentComponent.Components ?
      currentComponent.Components.filter(item => [CAP, RES, IND, REMOVED, UNUSED].includes(item.COMP_TYPE) || [CAP, RES, IND, REMOVED, UNUSED].includes(item.usage)) : [];
    const { PowerComponents: _PowerComponents } = yield call(applyPowerComponentsHelper, { powerDomains: components, PowerComponents, currentType });
    const applyChannelInfo = {
      ...channelInterface,
      content: { ...channelContent, PowerComponents: _PowerComponents }
    }
    return { channelInfo: applyChannelInfo }
  }
}

function* getPCBChannelPDNPowerComponents(action) {
  const { rockyType, currentType, tabKey, copyPowerComponents, copyPowerDomains } = action;
  const { RockyReducer: { project: { currentProjectId, projectList, currentProjectInterfaceInfo, extraction }, rocky: { currentConfig } } } = yield select();
  if (currentType === CHANNEL) {
    const currentProject = projectList.find(item => item.id === currentProjectId);
    const PCBPKGInfo = currentProject.children.find(item => item.name === PCB);
    const designId = PCBPKGInfo && PCBPKGInfo.children && PCBPKGInfo.children.length ? PCBPKGInfo.children[0].id : null;
    //get pdn info
    const pdnInfo = yield call(getRockyPackagePDN, designId);
    const pdnId = pdnInfo && pdnInfo.id ? pdnInfo.id : null;
    let _packagePDNInfo = yield call(getRockyPackagePDNInfo, pdnId);
    const value = yield call(initPCBPDN, _packagePDNInfo, true);
    if (value && value.pcbInfo) {
      _packagePDNInfo = value.pcbInfo;
    }
    const pdnContent = _packagePDNInfo && _packagePDNInfo.content ? _packagePDNInfo.content : {};
    const powerDomains = pdnContent && pdnContent.powerDomains ? pdnContent.powerDomains : [];
    //get channel info
    const PowerComponents = [...copyPowerComponents];
    const { powerDomains: _powerDomains } = yield call(applyPowerComponentsHelper, { powerDomains, PowerComponents, currentType });
    const applyPackagePDNInfo = { ..._packagePDNInfo, type: rockyType, loading: false, content: { ...pdnContent, powerDomains: _powerDomains } };
    return { packagePDNInfo: applyPackagePDNInfo }
  } else if (currentType === PDN) {
    let _tabKey = tabKey.replace(/\s+/g, "");
    _tabKey = _tabKey.split(',');
    //get channel info
    const channelInfo = yield call(getRockyProjectPromise, currentProjectId);
    const channelId = channelInfo && channelInfo.channels && channelInfo.channels.length ? channelInfo.channels[0].id : null;
    let channelInterface = yield call(PCBChannelContentConstructor.getPcbChannelInfo, channelId, true)
    const pcbInfo = yield call(getLayoutPCBInfo, channelInterface.designId);
    const initChannelInfo = yield call(initPCBChannelInfo, { res: channelInterface, currentProjectInterfaceInfo, currentProjectId, extraction, currentConfig, pcbInfo })
    if (initChannelInfo) {
      channelInterface = initChannelInfo;
    }
    const channelContentDTO = channelInterface && channelInterface.contentDTO ? channelInterface.contentDTO : {};
    const PowerComponents = channelContentDTO && channelContentDTO.PowerComponents ? channelContentDTO.PowerComponents : [];

    const powerDomains = [...copyPowerDomains];
    const currentComponent = powerDomains.find(item => JSON.stringify(item.PowerNets) === JSON.stringify(_tabKey));
    const components = currentComponent && currentComponent.Components ?
      currentComponent.Components.filter(item => [CAP, RES, IND, REMOVED, UNUSED].includes(item.COMP_TYPE) || [CAP, RES, IND, REMOVED, UNUSED].includes(item.usage)) : [];
    const { PowerComponents: _PowerComponents } = yield call(applyPowerComponentsHelper, { powerDomains: components, PowerComponents, currentType });
    const _channelInfo = {
      ...channelInterface,
      contentDTO: { ...channelContentDTO, PowerComponents: _PowerComponents }
    }
    return { channelInfo: _channelInfo };
  }
}

function* checkChannelPowerComponents() {
  const { RockyReducer: { project: { currentProjectId, projectList, currentChannelId } } } = yield select();
  const currentProject = projectList.find(item => item.id === currentProjectId);
  const pcbChannelId = currentChannelId;
  try {
    const packageChildren = currentProject.children.find(item => item.name === PACKAGE);
    const packageInfo = packageChildren && packageChildren.children && packageChildren.children.length ? packageChildren.children[0] : null;
    const packageChannel = packageInfo && packageInfo.children ? packageInfo.children.find(item => item.name !== PDN) : null;
    const packageChannelId = packageChannel && packageChannel.id ? packageChannel.id : null;

    const pcbChannelInterface = pcbChannelId ? yield call(PCBChannelContentConstructor.getPcbChannelInfo, pcbChannelId, true) : null;
    const pkgChannelInterface = packageChannelId ? yield call(getPackageVerificationInfoPromise, packageChannelId) : null;
    const pcbPowerComponents = pcbChannelInterface && pcbChannelInterface.contentDTO && pcbChannelInterface.contentDTO.PowerComponents ? pcbChannelInterface.contentDTO.PowerComponents : undefined;
    const pcbPowerNets = pcbChannelInterface && pcbChannelInterface.contentDTO && pcbChannelInterface.contentDTO.PowerNets ? pcbChannelInterface.contentDTO.PowerNets : [];
    const pkgPowerComponents = pkgChannelInterface && pkgChannelInterface.content && pkgChannelInterface.content.PowerComponents ? pkgChannelInterface.content.PowerComponents : undefined;
    const pkgPowerNets = pkgChannelInterface && pkgChannelInterface.content && pkgChannelInterface.content.PowerNets ? pkgChannelInterface.content.PowerNets : [];
    if (pcbPowerComponents && pkgPowerComponents) {
      return
    }
    if (!pcbPowerComponents && pcbChannelId) {
      yield call(getChannelPowerComponents, { channelInfo: pcbChannelInterface, prevComps: [], ReferenceNets: pcbPowerNets.map(item => item.name), rockyType: PCB });
    }
    if (!pkgPowerComponents && packageChannelId) {
      yield call(getChannelPowerComponents, { channelInfo: pkgChannelInterface, prevComps: [], ReferenceNets: pkgPowerNets.map(item => item.name), rockyType: PACKAGE });
    }
  } catch (error) {
    console.error(error)
  }
}

function* initPowerComponents(action) {
  const { channelInfo, prevComps, ReferenceNets, rockyType } = action;
  const pcbId = channelInfo && channelInfo.designId;
  if (pcbId) {
    const auroraJson = yield call(auroraDBJson.getAuroraJson, pcbId, {})
    if (!ReferenceNets.length || ReferenceNets.length === 1) {
      return [];
    } else {
      let content = rockyType === PCB ? channelInfo.contentDTO || {} : channelInfo.content || {};
      const compNames = content && content.Components ? content.Components.map(item => item.name) : [];
      let components = auroraJson && auroraJson.components ? Array.from(auroraJson.components.values()) : [];
      components = components.filter(item => [CAP, RES, IND, REMOVED, UNUSED].includes(item.type) || [CAP, RES, IND, REMOVED, UNUSED].includes(item.usage));
      components.forEach(item => {
        item.pins = item.pins.size ? Array.from(item.pins.values()) : item.pins;
        item.model = item.model ? item.model : {};
        item.COMP_TYPE = item.COMP_TYPE ? item.COMP_TYPE : item.type;
        item.part = item.part ? item.part : item.partName;
        item.usage = item.usage ? item.usage : item.type ? item.type : '';
      })
      components = components.filter(component => {
        const { pins } = component;
        const _pins = pins.size ? Array.from(pins.values()) : pins;
        const matchPins = _pins.filter(pin => ReferenceNets.includes(pin.net));
        const matchCount = matchPins ? matchPins.length : 0;
        return matchCount > 1;
      })
      components = components.length ? components : [];
      components = yield call(updateComponentDecapModel, {
        components: components,
        prevComps: prevComps,
        designId: pcbId
      })
      components.forEach((item, index) => {
        if (item.model) {
          item.model.libType = item.model.libraryType ? item.model.libraryType : item.model.libType ? item.model.libType : '';
          item.model.libraryType = item.model.libraryType ? item.model.libraryType : item.model.libType ? item.model.libType : '';
          item.model.subckt = item.model.subcktName ? item.model.subcktName : item.model.subckt ? item.model.subckt : '';
          item.model.subcktName = item.model.subcktName ? item.model.subcktName : item.model.subckt ? item.model.subckt : '';
          item.model.fileName = item.model.fileName ? item.model.fileName : item.model.name ? item.model.name : '';
          item.model.libraryId = item.model.libraryId ? item.model.libraryId : item.model.id ? item.model.id : '';
        }
        if (CAP === item.usage) {
          item.value = "";
        }
        delete item.partName;
        delete item.partNumber;
        delete item.type;
        delete item.symbol;
        delete item.layer;
        delete item.location;
      })
      components = components.filter(item => !compNames.includes(item.name));
      return components;
    }
  } else {
    return [];
  }
}

function* updateChannelContent(action) {
  try {
    const { contentType, updateContent, updateVerificationId } = action;
    const { RockyReducer: { rocky: { rockyPackageInfo, pcbChannelInfo } } } = yield select();

    if (contentType === PACKAGE_VERIFICATION) {
      const { content, verificationId } = rockyPackageInfo;
      if (verificationId !== updateVerificationId) {
        return
      }
      yield put(updatePackageContentInfo({ ...updateContent }));
      yield call(updatePackageVerificationPromise, [{ ...rockyPackageInfo, content: { ...content, ...updateContent } }]);
    } else if (contentType === PCB_CHANNEL) {
      const { contentDTO, verificationId } = pcbChannelInfo;
      if (verificationId !== updateVerificationId) {
        return
      }
      const channelInfo = {
        ...pcbChannelInfo,
        contentDTO: { ...contentDTO, ...updateContent },
      }
      PCBChannelContentConstructor.setInfo(pcbChannelInfo.id, channelInfo)
      yield call(updatePCBChannelContentPromise, { ...channelInfo })
      yield put(updatePCBChannelContent({ ...channelInfo }));
    }

  } catch (error) {
    console.error(error)
  }
}

function* updateChannelCompUsage(action) {
  const { compsName, part, checked, rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let powerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  powerComponents.forEach(item => {
    if (item.part === part && compsName.includes(item.name)) {
      item.usage = checked ? item.COMP_TYPE : 'Unused';
    }
  })
  const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
  const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
  yield call(updateChannelContent, { contentType, updateContent: { PowerComponents: powerComponents }, updateVerificationId });
}

function* removeChannelComponents(action) {
  const { comps, part, rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let powerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  powerComponents.forEach(item => {
    if (item.part === part && comps.includes(item.name)) {
      item.usage = 'Removed';
    }
  })
  const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
  const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
  yield call(updateChannelContent, { contentType, updateContent: { PowerComponents: powerComponents }, updateVerificationId });
}

function* splitChannelComponentPart(action) {
  const { compList, part, newPart, rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let powerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  powerComponents.forEach(item => {
    if (item.part === part && compList.includes(item.name)) {
      item.part = newPart;
    }
  })
  const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
  const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
  yield call(updateChannelContent, { contentType, updateContent: { PowerComponents: powerComponents }, updateVerificationId });
}

function* mergeChannelComponents(action) {
  const { mergeList, rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  let powerComponents = content.PowerComponents ? JSON.parse(JSON.stringify(content.PowerComponents)) : [];
  const comp = powerComponents.find(item => item.part === mergeList[0]) || {};
  powerComponents.forEach(item => {
    if (mergeList.includes(item.part)) {
      item.part = mergeList[0];
      item.value = comp.value || '';
      item.model = comp.model || {};
    }
  })
  const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
  const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
  yield call(updateChannelContent, { contentType, updateContent: { PowerComponents: powerComponents }, updateVerificationId });
}

function* updateChannelReferenceNets(action) {
  const { nets, rockyType } = action;
  const { RockyReducer: { rocky: { pcbChannelInfo, rockyPackageInfo } } } = yield select();
  const contentType = rockyType === PCB ? PCB_CHANNEL : PACKAGE_VERIFICATION;
  const updateVerificationId = rockyType === PCB ? pcbChannelInfo.verificationId : rockyPackageInfo.verificationId;
  if (!nets.length) {
    return;
  }
  const PowerNets = nets.map(item => {
    return {
      name: item.name,
      value: item.value || '0'
    }
  });
  const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
  const ReferenceNets = content.ReferenceNets ? JSON.parse(JSON.stringify(content.ReferenceNets)) : [];
  let refNetsChange = false;
  const updateNetsList = nets.map(item => item.name);
  ReferenceNets.forEach((item, index) => {
    if (!updateNetsList.includes(item)) {
      ReferenceNets.splice(index, 1);
      refNetsChange = true;
    }
  })
  let updateContent = { PowerNets };
  if (refNetsChange) {
    const content = rockyType === PCB ? pcbChannelInfo.contentDTO || {} : rockyPackageInfo.content || {};
    const designId = rockyType === PCB ? pcbChannelInfo.designId : rockyPackageInfo.designId;
    const port_setups = content.Port_setups ? content.Port_setups : [];
    const autoGenerationPort = new AutoGeneratePorts();
    let info = {
      referenceNets: ReferenceNets,
      designId: designId,
      extractionType: content && content.Extraction ? content.Extraction.type : '',
      ports_generate_setup_list: content && content.Ports_generate_setup_list ? content.Ports_generate_setup_list : [],
      applyComponents: content && content.Components ? content.Components.map(item => item.name) : []
    }
    const pcbInfo = DesignInfo.getPCBInfo(designId);
    const { port_setups: _port_setups, setupList: portsSetupList } = autoGenerationPort.autoGeneratePorts(
      port_setups,
      pcbInfo,
      {
        ...info
      }
    );
    updateContent = { ...updateContent, Port_setups: _port_setups, Ports_generate_setup_list: portsSetupList, ReferenceNets: ReferenceNets }
  }
  yield call(updateChannelContent, { contentType, updateContent: updateContent, updateVerificationId });
}

function* rockySaga() {
  yield takeEvery(GET_VERIFICATION_CONTENT, getContent);
  yield takeEvery(EDIT_SIGNAL_NAME, updateSignalName);
  yield takeEvery(EDIT_SIGNAL_NETS, updateSignalNets);
  yield takeEvery(UPDATE_INTERFACES, updateInterface);
  yield takeEvery(OPEN_PAGE, openRockyPage);
  yield takeEvery(DELETE_SIGNAL, delSignal);
  yield takeEvery(ADD_POWER_GROUNDS, addPowerGNDNets);
  yield takeEvery(POWER_NET_SELECTION, powerNetsSelection);
  yield takeEvery(SAVE_EXTRACTION, updateExtraction);
  yield takeEvery(SAVE_PIN_MODEL, saveModel);
  yield takeEvery(SAVE_POWER_OFF, savePowerOffMode);
  yield takeEvery(SAVE_MODEL_PINS, savePinModels);
  yield takeEvery(SAVE_STIMULUS, saveStimuli);
  yield takeEvery(SAVE_CURRENT_VERIFICATION, saveVerification);
  yield takeEvery(SAVE_SIMULATION_OPTION, saveSimulationConfig);
  yield takeEvery(SAVE_SERVICE_OPTION, saveProjectConfig);
  yield takeEvery(SAVE_COMP_MODEL, saveComponentModel);
  yield takeEvery(APPLY_SETUP_TO_SELECT, rockyApplySetupToSelected);
  yield takeEvery(GET_LIBRARY_FILE_PARSE, _getLibraryFileParse);
  yield takeEvery(SAVE_COMP_PACKAGE_MODEL, saveComponentPkgModel);
  yield takeEvery(UPDATE_TOUCHSTONE_FILE_MACRO_MODELING_STATUS, _UpdateTouchstoneStatus);
  yield takeEvery(UPDATE_ADR_CMD_STIMULUS, _updateADRCMDStimulusByMode);
  yield takeEvery(UPDATE_CHANNEL_BY_VERSION, updateVerificationsByVersion);
  yield takeEvery(UPDATE_PORT_SETUPS_TO_SERVER, updatePortsSetup);
  yield takeEvery(CLEAR_STANDBY_MODEL, clearStandbyModel);
  yield takeEvery(APPLY_ROCKY_PORT_SETUP, applyPortSetup);
  yield takeLatest(STIMULUS_RANDOM, stimulusRandom)
  yield takeEvery(SELECT_PACKAGE_LAYOUT_MODEL, selectPackageLayoutModel)
  yield takeEvery(GET_PACKAGE_CONTENT, getPackageContent)
  yield takeEvery(UPDATE_MEMORY_SELECTIONS, _updateMemorySelection);
  yield takeEvery(SAVE_CURRENT_PACKAGE_VERIFICATION, savePackageVerification)
  yield takeEvery(UPDATE_PACKAGE_SIGNAL_INFO, updatePackageSignalInfo)
  yield takeEvery(DELETE_PACKAGE_SIGNAL, deletePackageSignal)
  yield takeEvery(CHANGE_PACKAGE_SIGNAL_NAME, changePackageSignalName)
  yield takeEvery(ADD_PACKAGE_VERIFICATION, addPackageVerification)
  yield takeEvery(ADD_PACKAGE_CHANNEL, addPackageChannel)
  yield takeEvery(COMBINE_PACKAGE_INTERFACE, combinePackageInterface)
  yield takeEvery(UPDATE_REF_NETS, _updateRefNets)
  yield takeEvery(SAVE_VIRTUAL_COMPONENT, _saveVirtualComps)
  yield takeEvery(EDIT_PACKAGE_COMPONENT_TYPE, _editPKGCompType)
  yield takeEvery(GET_CLK_VERIFICATION_CONTENT, getCLKVerificationContent)
  yield takeEvery(SAVE_BALL_SIZE, saveBallSize)
  yield takeEvery(UPDATE_PACKAGE_NETS, updatePackageNets)
  yield takeEvery(UPDATE_PACKAGE_COMPONENTS, updatePowerDomainsComps)
  yield takeEvery(UPDATE_PORTS, updatePackagePorts)
  yield takeEvery(SWITCH_PACKAGE_PORTS, switchPackagePorts)
  yield takeEvery(SPLIT_COMPONENT_PART, splitComponentPart)
  yield takeEvery(MERGE_COMPONENTS, mergeComponents)
  yield takeEvery(UPDATE_COMP_USAGE, updateCompUsage)
  yield takeEvery(REMOVE_COMPONENTS, removeComponents)
  yield takeEvery(SAVE_DECAP_MODEL, saveDecapModel)
  yield takeEvery(SAVE_PKG_PDN_EXTRACTION, savePackagePDNExtraction)
  yield takeEvery(SAVE_PKG_PDN_COMPONENT_SETTING, savePackageSetting)
  yield takeEvery(ADD_POWER_DOMAIN, addNewPowerDomain)
  yield takeEvery(DELETE_POWER_DOMAIN, deletePowerDomain)
  yield takeEvery(UPDATE_PACKAGE_PORT_AND_BALL, updatePackagePortAndBall)
  yield takeEvery(UPDATE_VRM, updatePDNVrm);
  yield takeEvery(UPDATE_VOLTAGE, updatePDNVoltage);
  yield takeEvery(SAVE_VRMS, saveVrms);
  yield takeEvery(UPDATE_INCLUDE_EXTEND_NETS, updateIncludeExtend)
  yield takeEvery(SAVE_PACKAGE_CONTENT_AND_CLEAR, savePackageContentAndClear)
  yield takeEvery(SAVE_PCB_CHANNEL_CONTENT, savePcbChannelContent)
  yield takeEvery(UPDATE_TARGET_IC, changeTargetIC);
  yield takeEvery(UPDATE_PKG_DIE_PORTS_BY_CPM, _updateDiePortsByCPMPairs)
  yield takeEvery(RE_ASSIGN_DECAP_MODEL, reAssignDecapModel);
  yield takeEvery(START_MODELING_EXTRACTION, startModelingExtraction);
  yield takeEvery(GET_PCB_CHANNEL_CONTENT, getPCBChannelContent);
  yield takeEvery(GET_PCB_AND_PACKAGE_CHANNEL_INFO, getPcbPackageChannelInfo)
  yield takeEvery(SAVE_CHANNEL_DECAP_MODEL, saveChannelDecapModel)
  yield takeEvery(RE_ASSIGN_CHANNEL_DECAP_MODEL, reAssignChannelDecapModel)
  yield takeEvery(UPDATE_POWER_COMPONENTS_AFTER_REF_NETS, updatePowerComponents)
  yield takeEvery(APPLY_POWER_COMPONENTS, applyPowerComponents);
  yield takeEvery(UPDATE_CHANNEL_CONTENT, updateChannelContent);
  yield takeEvery(UPDATE_CHANNEL_COMP_USAGE, updateChannelCompUsage);
  yield takeEvery(REMOVE_CHANNEL_COMPONENTS, removeChannelComponents);
  yield takeEvery(SPLIT_CHANNEL_COMPONENT_PART, splitChannelComponentPart);
  yield takeEvery(MERGE_CHANNEL_COMPONENTS, mergeChannelComponents);
  yield takeEvery(UPDATE_CHANNEL_REFERENCE_NETS, updateChannelReferenceNets);
}

export default rockySaga;