import {
  GET_POWER_TREE_CONTENT,
  COMPONENT_SETTING_UPDATE,
  RESET_SETUP,
  SET_POWER_TREE_DATA,
  UPDATE_LOADING_PAGE,
  UPDATE_ERROR,
  SELECT_PCB,
  UPDATE_POWER_TREE,
  OPEN_PIN_SELECT_PANEL,
  UPDATE_TREE_LOADING,
  DRAW_TREE,
  UPDATE_COMP_PREFIX,
  DELETE_TREE_ITEM,
  INIT_DESIGN_TREE,
  UPLOAD_SPEC,
  UPDATE_RUNNING_STATUS,
  GET_RESULTS,
  DELETE_POWER_TREE,
  SHOW_RESULTS,
  SAVE_OPTION,
  SAVE_EXTRACTION,
  SET_TREE_TIP,
  UPDATE_TREE_MONITOR,
  GET_SPICE_RESULTS,
  UPDATE_TREE_SETTING
} from "./actionType"

export const getDesignTreeContent = (verificationId, loadPCB = true) => ({
  type: GET_POWER_TREE_CONTENT,
  verificationId,
  loadPCB
})

export const powerTreeUpdateCompSetting = (bool) => ({
  type: COMPONENT_SETTING_UPDATE,
  update: bool
})

export const resetSetup = () => ({
  type: RESET_SETUP
})

export const setPowerTreeData = (params) => ({
  type: SET_POWER_TREE_DATA,
  params
})

export const updateLoading = (loading) => ({
  type: UPDATE_LOADING_PAGE,
  loading
})

export const updateError = (error) => ({
  type: UPDATE_ERROR,
  error
})

export const updatePCB = (designId) => ({
  type: SELECT_PCB,
  designId
})

export const updateCurrentTree = (key, id, params) => ({
  type: UPDATE_POWER_TREE,
  key,
  id,
  params
})

export const updatePinSelectPanelId = (id) => ({
  type: OPEN_PIN_SELECT_PANEL,
  id
})


export const updateTreeLoading = (id, tip = "") => ({
  type: UPDATE_TREE_LOADING,
  id, tip
})

export const updateDrawType = (id, bool) => ({
  type: DRAW_TREE,
  id, bool
})

export const updateTreeCompRLCPrefix = (version) => ({
  type: UPDATE_COMP_PREFIX,
  version
})

export const deleteTreeItem = (id, treeItems, columnIndex) => ({
  type: DELETE_TREE_ITEM,
  id,
  treeItems,
  columnIndex
})

export const resetDesignTree = (total) => ({
  type: INIT_DESIGN_TREE,
  total
})

export const uploadTreeSpec = (verificationId, file) => ({
  type: UPLOAD_SPEC,
  verificationId,
  file
})

export const updateRunning = (status) => ({
  type: UPDATE_RUNNING_STATUS,
  status
})

export const updateResults = (results) => ({
  type: GET_RESULTS,
  results
})

export const delDesignTree = (id) => ({
  type: DELETE_POWER_TREE,
  id
})

export const showDesignTreeResult = (show) => ({
  type: SHOW_RESULTS,
  show
})

export const saveOption = (option) => ({
  type: SAVE_OPTION,
  option
})

export const saveExtraction = (extraction) => ({
  type: SAVE_EXTRACTION,
  extraction
})

export const setTreeTip = (tip) => ({
  type: SET_TREE_TIP,
  tip
})

export const updateTreeMonitor = (logs, replace = false) => ({
  type: UPDATE_TREE_MONITOR,
  logs,
  replace
})

export const saveSpiceResults = (results) => ({
  type: GET_SPICE_RESULTS,
  results
})

export const updateTreeSetting = (setting, { redraw, result, close }) => ({
  type: UPDATE_TREE_SETTING,
  setting,
  redraw,
  result,
  close
})