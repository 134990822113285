import { BUFFER_SPICE, CPHY_EYE_MASK, IBIS, SPICE } from "../../../constants/libraryConstants";
import { INPUT, INPUTANDSELECT, SELECT, SWITCH } from "../../../pages/Andes_v2/AMIModel/SetupComponents/SetupInputGroup";
import { parseSpiceModelSelector } from "../../LibraryHelper/SpiceModelHelper";
import { SERDES_TYPES } from "../../PCBHelper";
import { UNIT_OPTIONS } from "../AMIModelHelper";
import { getFileContent, getIbisModelParse, getLibraryFolderChildren } from "../library";
import libraryConstructor from "../library/libraryConstructor";
import { HspiceSignalConfig, handleComponents } from "./IntegratedHspiceConfig";

function getNodeSelectOption(modelType, pairs = [], subckt = {}) {
  if (pairs.length === 0 || (subckt.ports || []).length === 0) return [];

  switch (modelType) {
    case 'txModel':
    case 'rxModel':
    case 'rxCpadModel':
    case 'ctleModel':
      const { ports } = subckt;
      const nodeValues = pairs.map(item => item.node)
      return (ports || []).map(item => {
        if (nodeValues.includes(item)) {
          return { name: item, disabled: true }
        }
        return { name: item, disabled: false }
      })
    default:
      return [];
  }
}

// According to the modelName input value find, the modelName containing the lookup field
function getModelNameFilterList(modelNameSelectList, modelSearchValue) {
  if (!modelSearchValue) return modelNameSelectList;
  return modelNameSelectList.filter(item => item.name.includes(modelSearchValue))
}

// Rx selects IBIS file to obtain optional modelSelector, modelName, modelType, fill the model data
async function getRxSelectList({ libraryId: fileId, modelType, modelSelector }) {
  if (!fileId) {
    return {
      modelNameSelectList: [],
      modelTypeSelectList: [],
      selectorModels: [],
      modelSearchValue: ''
    }
  }

  const libraryModels = await getIbisModelParse(fileId) || {};

  const allSelectorModels = libraryModels.selectorModels || [];
  let models = libraryModels.models || [];
  if (modelSelector) {
    const selectorModel = allSelectorModels.find(item => item.selector === modelSelector) || {};
    models = selectorModel.models.map(item => {
      const modelInfo = models.find(it => it.name === item.name) || {};
      return {
        ...item,
        type: modelInfo.type
      }
    })
  }

  if (modelType) {
    models = models.filter(item => item.type === modelType);
  }

  // {name,type,description}
  const modelNameSelectList = models.map(model => {
    return {
      ...model,
      value: model.name,
      title: model.name,
      key: `${model.type} ${model.name}`
    }
  })

  const modelTypes = (libraryModels.models || []).map(item => item.type);
  return {
    modelNameSelectList,
    modelTypeSelectList: [...new Set(modelTypes)],
    selectorModels: [...allSelectorModels],
    modelSearchValue: ''
  }
}

function getModelSelector(selectorModels, modelName) {
  if (!selectorModels || !selectorModels.length) return "";

  for (const item of selectorModels) {
    const model = item.models.find(item => item.name === modelName);
    if (model) {
      return item.selector;
    }
  }

  return "";
}

function getValueAndUnit(param = "") {
  try {
    const [value, unit] = String(param).split(' ');
    return [value, unit]
  } catch (error) {
    return ['', '']
  }
}

async function getSubcktByPairInfo({ libraryId, fileName, subckt }) {
  if (!libraryId || !fileName || !subckt) return {}
  const res = await getFileContent(libraryId, fileName);
  if (!res) return {};
  const subcktList = parseSpiceModelSelector(res);
  const subcktInfo = subcktList.find(item => item.name === subckt) || {};
  return subcktInfo;
}

// To obtain Node data, we need to improve the logic of Net Chapin and then backfill value
function getNodeDataList({ signalName, index, hspiceSignalConfig, signals = [], device, controller }) {
  const signalInfo = signals.find(item => item.name === signalName);
  if (!signalInfo || !hspiceSignalConfig || !Object.keys(hspiceSignalConfig).length) {
    return { txNodeDataList: [], txNetDataList: [], z0DataList: [], ctleDataList: [], rxModelDataList: [], rxCpadDataList: [] }
  }

  const { txModel = {}, termination = {}, ctleModel = {}, rxCpadModel = {}, rxModel = {} } = hspiceSignalConfig;
  const rxModelInfo = rxModel.type === SPICE ? rxModel.pairs : rxModel.pinModels,
    rxCpadModelInfo = rxCpadModel.type === SPICE ? rxCpadModel.pairs : rxCpadModel.pinModels;
  const txDataList = [];
  const leftDataList = [], rightDataList = [], terminationDataList = [], rxCpadValueList = [], rxDataList = [];
  for (const netName of ['nets_A', 'nets_B', 'nets_C']) {
    const nets = signalInfo[netName];
    // tx
    const modelValue = (txModel.pairs || []).find(item => nets.includes(item.net));
    if (!modelValue) {
      txDataList.push({});
    } else {
      txDataList.push({
        net: modelValue.net,
        node: modelValue.node,
        pin: modelValue.pin,
        allInfo: modelValue
      })
    }
    // termination
    const terminationValue = (termination.pinModels || []).find(item => nets.includes(item.net));
    if (!terminationValue) {
      terminationDataList.push({});
    } else {
      const [value, unit] = getValueAndUnit(terminationValue.impedance);
      terminationDataList.push({
        pin: terminationValue.pin,
        value,
        unit
      })
    }
    // ctle
    const controllerPairIndex = (ctleModel.pairs || []).findIndex(item => item.component === controller && nets.includes(item.net));
    const devicePairIndex = (ctleModel.pairs || []).findIndex(item => item.component === device && nets.includes(item.net));
    if (controllerPairIndex < 0) {
      leftDataList.push({});
    } else {
      leftDataList.push({ ...ctleModel.pairs[controllerPairIndex], pairIndex: controllerPairIndex });
    }
    if (devicePairIndex < 0) {
      rightDataList.push({});
    } else {
      rightDataList.push({ ...ctleModel.pairs[devicePairIndex], pairIndex: devicePairIndex });
    }
    // rxCpad
    const rxCpadValue = (rxCpadModelInfo || []).find(item => nets.includes(item.net)) || {};
    rxCpadValueList.push(rxCpadValue);
    // rx
    const rxValue = (rxModelInfo || []).find(item => nets.includes(item.net)) || {};
    rxDataList.push(rxValue);
  }

  const z0DataList = [{
    key: 'z0_A-' + index,
    left: '810px',
    top: `${100 + 400 * index}px`,
    pin: terminationDataList[0].pin,
    value: terminationDataList[0].value,
    unit: terminationDataList[0].unit
  }, {
    key: 'z0_B-' + index,
    left: '810px',
    top: `${200 + 400 * index}px`,
    pin: terminationDataList[1].pin,
    value: terminationDataList[1].value,
    unit: terminationDataList[1].unit
  }, {
    key: 'z0_C-' + index,
    left: '810px',
    top: `${300 + 400 * index}px`,
    pin: terminationDataList[2].pin,
    value: terminationDataList[2].value,
    unit: terminationDataList[2].unit
  }, {
    key: 'Cap-' + index,
    left: '910px',
    top: `${350 + 400 * index}px`,
    value: getValueAndUnit(termination.terminationCap)[0],
    unit: getValueAndUnit(termination.terminationCap)[1]
  }];

  const txNodeDataList = [{
    key: 'txNodeA-' + index,
    left: '110px',
    top: `${55 + 400 * index}px`,
    value: txDataList[0].node,
    pin: txDataList[0].pin,
    allInfo: txDataList[0].allInfo
  }, {
    key: 'txNodeB-' + index,
    left: '110px',
    top: `${119 + 400 * index}px`,
    value: txDataList[1].node,
    pin: txDataList[1].pin,
    allInfo: txDataList[1].allInfo
  }, {
    key: 'txNodeC' + index,
    left: '110px',
    top: `${185 + 400 * index}px`,
    value: txDataList[2].node,
    pin: txDataList[2].pin,
    allInfo: txDataList[2].allInfo
  }];

  const txNetDataList = [{
    key: 'txNetA-' + index,
    left: '190px',
    top: `${40 + 400 * index}px`,
    value: txDataList[0].net
  }, {
    key: 'txNetB-' + index,
    left: '190px',
    top: `${104 + 400 * index}px`,
    value: txDataList[1].net
  }, {
    key: 'txNetC-' + index,
    left: '190px',
    top: `${170 + 400 * index}px`,
    value: txDataList[2].net
  }];

  const ctleDataList = [{
    key: 'ctleA-' + index,
    left: '950px',
    top: `${10 + 400 * index}px`,
    leftValue: leftDataList[0],
    rightValue: rightDataList[0]
  }, {
    key: 'ctleB-' + index,
    left: '950px',
    top: `${108 + 400 * index}px`,
    leftValue: leftDataList[1],
    rightValue: rightDataList[1]
  }, {
    key: 'ctleC-' + index,
    left: '950px',
    top: `${206 + 400 * index}px`,
    leftValue: leftDataList[2],
    rightValue: rightDataList[2]
  }];

  const rxModelDataList = [{
    key: 'hspice-rxModelA-' + index,
    left: '546px',
    top: `${70 + 400 * index}px`,
    modelName: rxDataList[0].modelName,
    node: rxDataList[0].node
  }, {
    key: 'hspice-rxModelB-' + index,
    left: '546px',
    top: `${170 + 400 * index}px`,
    modelName: rxDataList[1].modelName,
    node: rxDataList[1].node
  }, {
    key: 'hspice-rxModelC-' + index,
    left: '546px',
    top: `${270 + 400 * index}px`,
    modelName: rxDataList[2].modelName,
    node: rxDataList[2].node
  }];

  const rxCpadDataList = [{
    key: 'rxCRapA-' + index,
    left: '745px',
    top: `${65 + 400 * index}px`,
    cpadValue: rxCpadValueList[0].cpadValue,
    node: rxCpadValueList[0].node
  }, {
    key: 'rxCRapB-' + index,
    left: '745px',
    top: `${165 + 400 * index}px`,
    cpadValue: rxCpadValueList[1].cpadValue,
    node: rxCpadValueList[1].node
  }, {
    key: 'rxCRapC-' + index,
    left: '745px',
    top: `${265 + 400 * index}px`,
    cpadValue: rxCpadValueList[2].cpadValue,
    node: rxCpadValueList[2].node
  }];
  return { txNodeDataList, txNetDataList, z0DataList, ctleDataList, rxModelDataList, rxCpadDataList }
}

function getCtleNodePairList(pairs = [], signalInfo, controller, device) {
  const leftDataList = [], rightDataList = [];
  for (const netName of ['nets_A', 'nets_B', 'nets_C']) {
    const nets = signalInfo[netName];
    const controllerPairIndex = (pairs || []).findIndex(item => item.component === controller && nets.includes(item.net));
    const devicePairIndex = (pairs || []).findIndex(item => item.component === device && nets.includes(item.net));
    if (controllerPairIndex < 0) {
      leftDataList.push({});
    } else {
      leftDataList.push({ ...pairs[controllerPairIndex], pairIndex: controllerPairIndex });
    }
    if (devicePairIndex < 0) {
      rightDataList.push({});
    } else {
      rightDataList.push({ ...pairs[devicePairIndex], pairIndex: devicePairIndex });
    }
  }
  if (pairs.length < 6) return [];
  return [
    { left: { ...leftDataList[0] }, right: { ...rightDataList[0] } },
    { left: { ...leftDataList[1] }, right: { ...rightDataList[1] } },
    { left: { ...leftDataList[2] }, right: { ...rightDataList[2] } }
  ]
}

function getSettingDataList(settingInfo = {}, eyeMaskList, userDefinedNetlistList) {
  const eyeMaskValue = settingInfo.eyeMask || {};
  const userDefinedNetlistValue = settingInfo.userDefinedNetlist || {};
  const eyeMaskNotExist = eyeMaskValue.libraryId && !eyeMaskList.find(item => item.id === eyeMaskValue.libraryId) ? true : false
  const userDefinedNetlistNotExist = userDefinedNetlistValue.libraryId && !userDefinedNetlistList.find(item => item.id === userDefinedNetlistValue.libraryId) ? true : false
  return [{
    label: 'Symbol Rate',
    value: getValueAndUnit(settingInfo.symbolRate)[0],
    unit: getValueAndUnit(settingInfo.symbolRate)[1],
    type: INPUT,
    eleName: 'symbolRate',
    // suffixOptions: UNIT_OPTIONS.frequency
  }, {
    label: 'Initial Delay',
    value: getValueAndUnit(settingInfo.initialDelay)[0],
    unit: getValueAndUnit(settingInfo.initialDelay)[1],
    type: INPUTANDSELECT,
    eleName: 'initialDelay',
    suffixOptions: UNIT_OPTIONS.time
  }, {
    label: 'Time Step',
    value: getValueAndUnit(settingInfo.timeStep)[0],
    unit: getValueAndUnit(settingInfo.timeStep)[1],
    type: INPUTANDSELECT,
    eleName: 'timeStep',
    suffixOptions: UNIT_OPTIONS.time
  }, {
    label: 'Cycles',
    value: settingInfo.cycles,
    type: INPUT,
    eleName: 'cycles',
    eleType: 'number'
  }, {
    label: 'Number of Cores',
    value: settingInfo.hspiceCores,
    type: INPUT,
    eleName: 'hspiceCores',
    eleType: 'number',
    tooltip: "Every 2 cores require one HSPICE license."
  }, {
    label: 'Eye Mask',
    value: eyeMaskNotExist ? eyeMaskValue.fileName : eyeMaskValue.libraryId || "Default",
    type: SELECT,
    eleName: 'eyeMask',
    options: eyeMaskList.map(item => ({ label: item.name, value: item.id })),
    allowClear: true,
    notExist: eyeMaskNotExist
  }, {
    label: 'User Defined Netlist',
    value: userDefinedNetlistNotExist ? userDefinedNetlistValue.fileName : userDefinedNetlistValue.libraryId,
    type: SELECT,
    eleName: 'userDefinedNetlist',
    options: userDefinedNetlistList.map(item => ({ label: item.name, value: item.id })),
    allowClear: true,
    notExist: userDefinedNetlistNotExist
  }, {
    label: 'Test Mode',
    value: settingInfo.testMode,
    type: SWITCH,
    eleName: 'testMode',
    tooltip: "Test mode will perform the HSPICE check Task directly and skip Modeling."
  }]
}

function getIsShow(hspiceConfig = {}) {
  const { signals = [] } = hspiceConfig;
  try {
    const isShowRx = signals[0].rxModel.type === 'None' ? false : true;
    const isShowRxCpad = signals[0].rxCpadModel.type === 'None' ? false : true;
    const isShowCtle = signals[0].ctleModel.type === 'None' ? false : true;
    return { isShowRx, isShowCtle, isShowRxCpad }
  } catch (error) {
    return { isShowRx: false, isShowCtle: false, isShowRxCpad: false }
  }
}

function getModelKey({ files, pairIndex, modelKey, spiceType }) {
  let _modelKey = modelKey;
  if (!_modelKey) {
    _modelKey = spiceType === 'only' ? (files[pairIndex % 3] || {}).modelKey : (files[0] || {}).modelKey;
  }
  return _modelKey;
}

// clear subckt
function handleSelectSubckt(modelType, model, subckt, modelKey, file) {
  const _files = [...model.files], _pairs = [...model.pairs];
  const fileIndex = _files.findIndex(item => item.modelKey === modelKey) || 0;
  if (file.subckt !== subckt.name) {
    _pairs.forEach((pair) => {
      if ((modelType === 'txModel' || pair.modelKey === modelKey)
        && pair.subckt === file.subckt && pair.libraryId === file.libraryId && pair.fileName === file.fileName) {
        pair.node = "";
        delete pair.fileName;
        delete pair.libraryId;
        delete pair.subckt;
        delete pair.modelKey;
      }
    })
    _files[fileIndex] = file
  }

  _files[fileIndex] = { ..._files[fileIndex], subckt: subckt.name }
  return { files: _files, pairs: _pairs }
}

function getInorOutPorts(ports) {
  // in / out
  let inPorts = [], outPorts = [], otherPorts = [];
  for (const port of ports) {
    if (/in/i.test(port)) {
      inPorts.push(port)
    } else if (/out/i.test(port)) {
      outPorts.push(port)
    } else {
      otherPorts.push(port)
    }
  }
  return { inPorts, outPorts, otherPorts }
}

// ctleModel auto match node // byName
function autoMatchNode({ signal, controller, device, model = {}, subcktList, spiceType, autoMatchType = "byName", isSwap }) {
  const { files, pairs } = model;
  if (!files.length) return pairs;
  const pairList = getCtleNodePairList(pairs, signal, controller, device);

  if (spiceType === "only") {
    pairList.forEach((pair, index) => {
      const file = files[index] || {};
      const _modelKey = getModelKey({ spiceType, files, pairIndex: pair.left.pairIndex, modelKey: pair.left.modelKey });
      const ports = (subcktList[_modelKey] || []).ports;
      if (ports && ports.length) {
        let inPorts = [], outPorts = [], startIndex = -1, endIndex = -1;
        if (autoMatchType === 'byName') {
          const namePorts = getInorOutPorts(ports);
          inPorts = namePorts.inPorts;
          outPorts = namePorts.outPorts;
        } else {
          const indexInfo = getPortIndex(autoMatchType, ports.length, isSwap);
          startIndex = indexInfo.startIndex;
          endIndex = indexInfo.endIndex;
        }

        const leftNode = autoMatchType === 'byName' ? inPorts[0] : ports[startIndex];

        pair.left = {
          ...pair.left,
          node: leftNode || "",
          fileName: file.fileName,
          libraryId: file.libraryId,
          modelKey: file.modelKey,
          subckt: file.subckt
        }
        const rightNode = autoMatchType === 'byName' ? outPorts[0] : ports[endIndex];
        pair.right = {
          ...pair.right,
          node: rightNode || "",
          fileName: file.fileName,
          libraryId: file.libraryId,
          modelKey: file.modelKey,
          subckt: file.subckt
        }
      }
    })
  } else {
    const file = files[0] || {};
    const _modelKey = getModelKey({ spiceType, files });
    const ports = (subcktList[_modelKey] || []).ports;
    if (!ports || !ports.length) {
      return pairs;
    }
    // auto match node
    let inPorts = [], outPorts = [], startIndex = -1, endIndex = -1, startAdd = 0, endAdd = 0;
    if (autoMatchType === 'byName') {
      const namePorts = getInorOutPorts(ports);
      inPorts = namePorts.inPorts;
      outPorts = namePorts.outPorts;
    } else {
      const indexInfo = getPortIndex(autoMatchType, ports.length, isSwap);
      startIndex = indexInfo.startIndex;
      endIndex = indexInfo.endIndex;
      startAdd = indexInfo.startAdd;
      endAdd = indexInfo.endAdd;
    }

    let portNumber = 0;
    pairList.forEach((pair, index) => {
      const leftNode = autoMatchType === 'byName' ? inPorts[index] : portNumber < ports.length ? ports[startIndex] : "";
      pair.left = {
        ...pair.left,
        node: leftNode || "",
        fileName: file.fileName,
        libraryId: file.libraryId,
        modelKey: file.modelKey,
        subckt: file.subckt
      }
      if (ports[startIndex]) portNumber++;
      const rightNode = autoMatchType === 'byName' ? outPorts[index] : portNumber < ports.length ? ports[endIndex] : "";
      pair.right = {
        ...pair.right,
        node: rightNode || "",
        fileName: file.fileName,
        libraryId: file.libraryId,
        modelKey: file.modelKey,
        subckt: file.subckt
      }
      if (ports[endIndex]) portNumber++;

      endIndex += endAdd;
      startIndex += startAdd;
    })
  }

  const leftPairs = pairList.map(pair => {
    return pair.left;
  });
  const rightPairs = pairList.map(pair => {
    return pair.right;
  });
  return [...leftPairs, ...rightPairs];
}

function getPortIndex(type, portLength, isSwap) {
  switch (type) {
    case 0:
      return {
        startIndex: isSwap ? 1 : 0,
        endIndex: isSwap ? 0 : 1,
        startAdd: 2,
        endAdd: 2
      }
    case 1:
      return {
        startIndex: isSwap ? Math.ceil(portLength / 2) : 0,
        endIndex: isSwap ? 0 : Math.ceil(portLength / 2),
        startAdd: 1,
        endAdd: 1
      }
    case 2:
      const defaultEndIndex = portLength - 1 === 0 ? -1 : portLength - 1;
      return {
        startIndex: isSwap ? defaultEndIndex : 0,
        endIndex: isSwap ? 0 : defaultEndIndex,
        startAdd: isSwap ? -1 : 1,
        endAdd: isSwap ? 1 : -1
      }
    default:
      return null
  }
}

function handleChangeSpiceType(model, type, subcktList = []) {
  let _files = [...model.files], _pairs = [...model.pairs], _subcktList = { ...subcktList };
  const fileEle = _files.length > 0 ? { ..._files[0] } : {}, subcktInfo = { ..._subcktList[0] };
  if (type === 'share') {
    _files = [{ ...fileEle, modelKey: 1 }];
    // After processing, clear the data about the pairs file selection
    _subcktList = { 1: subcktInfo };
  } else {
    _files = [{ ...fileEle, modelKey: 1 }, { ...fileEle, modelKey: 2 }, { ...fileEle, modelKey: 3 }];
    _subcktList = { 1: subcktInfo, 2: subcktInfo, 3: subcktInfo };
  }
  _pairs.forEach(pair => {
    pair.node = "";
    delete pair.fileName;
    delete pair.libraryId;
    delete pair.subckt;
    delete pair.modelKey;
  })
  return { files: _files, pairs: _pairs, subcktList: _subcktList };
}

function handleSelectFile(modelType, model, file, fileIndex) {
  const _files = [...model.files], _pairs = [...model.pairs];
  // add first file
  if (fileIndex === -1) {
    _files.push({ ...file, modelKey: 1 })
  } else {
    const oldFile = model.files[fileIndex];
    // Select the old files within the processing pairs
    _pairs.forEach((pair, index) => {
      if (modelType === 'txModel' || pair.modelKey === oldFile.modelKey) {
        pair.node = "";
        delete pair.fileName;
        delete pair.libraryId;
        delete pair.subckt;
        delete pair.modelKey;
      }
    })
    _files[fileIndex] = file;
    if (file) {
      _files[fileIndex].modelKey = fileIndex + 1;
    }
  }

  return { files: [..._files], pairs: [..._pairs] }
}

function handleSelectNode(model, spiceType, node, pairIndex, modelKey) {
  const { files = [] } = model;
  const _modelKey = getModelKey({ files, pairIndex, modelKey, spiceType })
  const pairs = [...model.pairs], file = spiceType === 'only' ? files.find(file => file.modelKey === _modelKey) : files[0];
  pairs[pairIndex] = {
    ...pairs[pairIndex],
    node: node,
    fileName: file.fileName,
    libraryId: file.libraryId,
    subckt: file.subckt,
    modelKey: file.modelKey
  }
  return pairs;
}

function getTooltipPrefix(hspiceSignalConfig) {
  const { rxModel = {}, rxCpadModel = {} } = hspiceSignalConfig || {};
  return {
    rxPrefix: rxModel.type === SPICE ? "Node: " : "Model: ",
    rxCpadPrefix: rxCpadModel.type === SPICE ? "Node: " : "Value: "
  }
}

// The data were postprocessed after modifying the signalName
function updateSignalNameInHspiceConfig(hspiceConfig, prevName, name) {
  if (!hspiceConfig || !hspiceConfig.signals) {
    return hspiceConfig;
  }

  for (let sigItem of hspiceConfig.signals) {
    if (sigItem.signalName === prevName) {
      sigItem.signalName = name;
    }
  }

  return hspiceConfig;
}

// Data were post-processed after signal
function delSignalInHspiceConfig(hspiceConfig, signal) {
  if (!hspiceConfig || !hspiceConfig.signals) {
    return hspiceConfig;
  }
  hspiceConfig.signals = hspiceConfig.signals.filter(item => item.signalName !== signal);

  return hspiceConfig;
}

// If the currently used component is deleted, the controller and device are removed
function updateHspiceConfigComp({ hspiceConfig, components }) {
  if (!hspiceConfig || !hspiceConfig.signals) {
    return hspiceConfig;
  }

  const compNames = components.map(item => item.name);
  if (hspiceConfig.controller && !compNames.includes(hspiceConfig.controller)) {
    hspiceConfig.controller = "";
  }
  if (hspiceConfig.device && !compNames.includes(hspiceConfig.device)) {
    hspiceConfig.device = "";
  }
  return hspiceConfig;
}

// select Net
function updateHSPICEModel(channelInfo, signalName) {
  let hspiceConfig = channelInfo.hspiceConfig ? { ...channelInfo.hspiceConfig } : {};
  const signals = channelInfo.content.signals || [];
  if (!Object.keys(hspiceConfig).length) {
    return hspiceConfig;
  }
  const components = channelInfo.content.components || [];
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));

  // If there is no component available, empty data within the signals
  if (!ICComps.length) {
    hspiceConfig.controller = "";
    hspiceConfig.device = "";

    hspiceConfig.signals = hspiceConfig.signals.map(item => {
      return new HspiceSignalConfig({
        ...item,
        txModel: {},
        rxModel: {},
        rxCpadModel: {},
        termination: {},
        ctleModel: {}
      });
    })
    return hspiceConfig;
  }

  let controller = hspiceConfig.controller, device = hspiceConfig.device, hspiceSignals = [...hspiceConfig.signals];
  const compNames = ICComps.map(item => item.name);
  if (!hspiceConfig.controller || !compNames.includes(hspiceConfig.controller)) {
    const _icComps = compNames.filter(item => item !== device);
    controller = _icComps.length ? _icComps[0] : "";
  }

  if (!hspiceConfig.device || !compNames.includes(hspiceConfig.device)) {
    const _icComps = compNames.filter(item => item !== controller);
    device = _icComps.length ? _icComps[0] : "";
  }
  // Check the modified signal and get the relationship between comp, pin, net
  const signal = signals.find(item => item.name === signalName);
  const hspiceSignalIndex = hspiceSignals.findIndex(item => item.signalName === signalName);
  if (hspiceSignalIndex > -1) {
    const _components = handleComponents(ICComps, signal);
    const _item = new HspiceSignalConfig(hspiceSignals[hspiceSignalIndex]);
    _item.checkModel(controller, device, _components);
    hspiceSignals[hspiceSignalIndex] = _item;
  }

  return {
    ...hspiceConfig,
    controller,
    device,
    signals: hspiceSignals
  };
}

// select pin
function updateHSPICEPin(hspiceConfig, newPin, prevPin) {
  if (!Object.keys(hspiceConfig).length) {
    return hspiceConfig;
  }
  let hspiceSignals = [...hspiceConfig.signals];
  const hspiceSignalIndex = hspiceConfig.signals.findIndex(item => item.signalName === newPin.signal);
  if (hspiceSignalIndex > -1) {
    const _item = new HspiceSignalConfig(hspiceSignals[hspiceSignalIndex]);
    _item.checkPin(newPin, prevPin);
    hspiceSignals[hspiceSignalIndex] = _item;
  }

  return {
    ...hspiceConfig,
    signals: hspiceSignals
  }
}

// change compType
function updateHSPICECompType(channelInfo) {
  const hspiceConfig = channelInfo.hspiceConfig ? { ...channelInfo.hspiceConfig } : {};
  const signals = channelInfo.content.signals || [];
  if (!Object.keys(hspiceConfig).length) {
    return hspiceConfig;
  }
  const components = channelInfo.content.components || [];
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));

  if (!ICComps.length) {
    hspiceConfig.controller = "";
    hspiceConfig.device = "";

    hspiceConfig.signals = hspiceConfig.signals.map(item => {
      return new HspiceSignalConfig({
        ...item,
        txModel: {},
        rxModel: {},
        rxCpadModel: {},
        termination: {},
        ctleModel: {}
      });
    })
    return hspiceConfig;
  }

  let replaceController = false, replaceDevice = false, controller = hspiceConfig.controller, device = hspiceConfig.device, hspiceSignals = [...hspiceConfig.signals];
  const compNames = ICComps.map(item => item.name);
  if (!hspiceConfig.controller || !compNames.includes(hspiceConfig.controller)) {
    const _icComps = compNames.filter(item => item !== hspiceConfig.device);
    controller = _icComps.length ? _icComps[0] : "";
    replaceController = true;
  }

  if (!hspiceConfig.device || !compNames.includes(hspiceConfig.device)) {
    const _icComps = compNames.filter(item => item !== hspiceConfig.controller);
    device = _icComps.length ? _icComps[0] : "";
    replaceDevice = true;
  }

  if (replaceController || replaceDevice) {
    hspiceSignals = hspiceSignals.map(item => {
      let _item = new HspiceSignalConfig(item);
      const signal = signals.find(item => item.name === _item.signalName);
      const _components = handleComponents(ICComps, signal)
      _item = _item.checkComp(controller, device, _components, replaceController, replaceDevice);
      return _item;
    })
  }

  return {
    ...hspiceConfig,
    controller,
    device,
    signals: hspiceSignals
  };
}

async function hspiceConfigErrorCheck(hspiceConfig, selectedSignals) {
  let settingErrors = [], errors = [];

  if (!hspiceConfig) {
    settingErrors.push(`Simulation setup is not set.`);
    errors.push({
      title: "[Simulation Settings]",
      monitor: settingErrors
    })
    return errors;
  }

  if (!hspiceConfig.controller || !hspiceConfig.device) {
    settingErrors.push("The component of signals AMI setup are not selected.");
    errors.push({
      title: "[Simulation Settings]",
      monitor: settingErrors
    })
    return errors;
  }

  // check eyeMask
  if (hspiceConfig && hspiceConfig.eyeMask && hspiceConfig.eyeMask.libraryId) {
    const eyeMaskList = libraryConstructor.getLibraryValues(CPHY_EYE_MASK);
    const eyeMask = eyeMaskList.find(item => item.id === hspiceConfig.eyeMask.libraryId);
    if (!eyeMask) {
      settingErrors.push(`The Eye Mask file is not exist.`);
      errors.push({
        title: "[Simulation Settings]",
        monitor: settingErrors
      })
      return errors;
    }
  }

  const ibisLibraryList = libraryConstructor.getLibraryValues(IBIS);
  const bufferSpiceLibraryList = libraryConstructor.getLibraryValues(BUFFER_SPICE);
  const genericSpiceLibraryList = libraryConstructor.getLibraryValues(SPICE);

  // txModel / rxModel / rxCpadModel / termination / ctleModel
  for (const signal of hspiceConfig.signals) {
    if (!selectedSignals.includes(signal.signalName)) {
      continue;
    }
    // txModel
    let signalErrors = [], errorExist = false;
    if (!signal.txModel) {
      errorExist = true;
      signalErrors.push(`The TxModel of ${signal.signalName} is not set.`);
    } else {
      // check file
      const txFileError = await libraryCheck({
        signalName: signal.signalName,
        model: signal.txModel,
        modelType: "TxModel",
        libraryList: [...bufferSpiceLibraryList, ...genericSpiceLibraryList]
      })

      if (txFileError) {
        signalErrors.push(txFileError);
        errorExist = true;
      }
      // check pairs
      const txPairsError = pairsCheck({
        signalName: signal.signalName,
        model: signal.txModel,
        modelType: "TxModel"
      })
      if (txPairsError) {
        signalErrors.push(txPairsError);
        errorExist = true;
      }
    }
    // rxModel
    if (signal.rxModel.type !== 'None') {
      if (!signal.rxModel) {
        errorExist = true;
        signalErrors.push(`The RxModel of ${signal.signalName} is not set.`);
      } else if (signal.rxModel.type === SPICE) {
        // check file
        const rxFileError = await libraryCheck({
          signalName: signal.signalName,
          model: signal.rxModel,
          modelType: "RxModel",
          libraryList: signal.rxModel.type === SPICE ? [...bufferSpiceLibraryList, ...genericSpiceLibraryList] : ibisLibraryList
        })

        if (rxFileError) {
          signalErrors.push(rxFileError);
          errorExist = true;
        }

        // check pairs
        const rxPairsError = pairsCheck({
          signalName: signal.signalName,
          model: signal.rxModel,
          modelType: "RxModel"
        })

        if (rxPairsError) {
          signalErrors.push(rxPairsError);
          errorExist = true;
        }
      } else if (signal.rxModel.type === IBIS) {
        // check file
        const rxFileError = await libraryCheck({
          signalName: signal.signalName,
          model: signal.rxModel,
          modelType: "RxModel",
          libraryList: ibisLibraryList
        })

        if (rxFileError) {
          signalErrors.push(rxFileError);
          errorExist = true;
        }

        // check pinModels
        const rxPinModelsError = pinModelCheck({
          signalName: signal.signalName,
          model: signal.rxModel,
          modelType: "RxModel"
        })

        if (rxPinModelsError) {
          signalErrors.push(rxPinModelsError);
          errorExist = true;
        }
      }
    }
    // rxCpadModel
    if (signal.rxCpadModel.type !== 'None') {
      if (!signal.rxCpadModel) {
        errorExist = true;
        signalErrors.push(`The RxCpadModel of ${signal.signalName} is not set.`);
      } else if (signal.rxCpadModel.type === SPICE) {
        // check file and pair/pinModel
        const rxCpadFileError = await libraryCheck({
          signalName: signal.signalName,
          model: signal.rxCpadModel,
          modelType: "RxCpadModel",
          libraryList: [...bufferSpiceLibraryList, ...genericSpiceLibraryList]
        })
        if (rxCpadFileError) {
          signalErrors.push(rxCpadFileError);
          errorExist = true;
        }
        // check pairs
        const rxCpadPairsError = pairsCheck({
          signalName: signal.signalName,
          model: signal.rxCpadModel,
          modelType: "RxCpadModel"
        })
        if (rxCpadPairsError) {
          signalErrors.push(rxCpadPairsError);
          errorExist = true;
        }
      }
    }
    // termination
    if (!signal.termination) {
      errorExist = true;
      signalErrors.push(`The termination of ${signal.signalName} is not set.`);
    }
    // ctleModel
    if (signal.ctleModel.type !== 'None') {
      if (!signal.ctleModel) {
        errorExist = true;
        signalErrors.push(`The CTLE model of ${signal.signalName} is not set.`)
      } else {
        // check file
        const ctleFileError = await libraryCheck({
          signalName: signal.signalName,
          model: signal.ctleModel,
          modelType: "CtleModel",
          libraryList: genericSpiceLibraryList
        })
        if (ctleFileError) {
          signalErrors.push(ctleFileError);
          errorExist = true;
        }
        // check pinModel
        const ctlePairsError = pairsCheck({
          signalName: signal.signalName,
          model: signal.ctleModel,
          modelType: "CtleModel"
        })
        if (ctlePairsError) {
          signalErrors.push(ctlePairsError);
          errorExist = true;
        }
      }
    }

    if (errorExist) {
      signalErrors.length && errors.push({
        title: `[Signal - ${signal.signalName}]`,
        monitor: signalErrors
      });
    }
  }
  return errors;
}

async function libraryCheck({ signalName, model, modelType, libraryList = [] }) {
  const files = model.files || [];
  const notExitFile = [];
  for (const file of files) {
    if (file && file.libraryId) {
      const library = libraryList.find(it => it.id === file.libraryId)
      if (!library) {
        if (file.path) {
          const fileInfo = await getFileContent(file.libraryId);
          if (!fileInfo) {
            notExitFile.push(file.fileName);
          }
        } else {
          notExitFile.push(file.fileName);
        }
      }
    }
  }

  if (!notExitFile.length) {
    return null;
  }

  const notExitFileStr = notExitFile.join(", ");
  return `The ${modelType} library ${notExitFileStr} of ${signalName} is not exist.`;
}

function pairsCheck({ signalName, model, modelType }) {
  const pairs = model.pairs || [];
  for (const pair of pairs) {
    if (pair && !pair.node) {
      return `The ${modelType} node of ${signalName} is not set.`;
    }
  }
}

function pinModelCheck({ signalName, model, modelType }) { // only check rxModel
  const pinModels = model.pinModels || [];
  for (const pinModel of pinModels) {
    if (pinModel && !pinModel.modelName) {
      return `The ${modelType} model of ${signalName} is not set.`;
    }
  }
}

async function getTreeChildren(rootId) {
  const childrenRes = (await getLibraryFolderChildren(rootId)) || {};
  const childrenData = [...(childrenRes[SPICE] || []), ...(childrenRes[BUFFER_SPICE] || [])]
  // If there are no subfiles, return directly
  if (!childrenData || !childrenData.length) {
    return [];
  } else {
    const childrenTreeData = childrenData.map(item => {
      const isFolder = item.fileType.toLowerCase() === "folder"
      return {
        id: item.id,
        pId: rootId,
        title: item.name,
        value: item.id,
        selectable: !isFolder,
        isLeaf: !isFolder,
        // icon: isFolder ? "folder" : "file",
        fileInfo: item
      };
    });

    return childrenTreeData;
  }
}

async function getFileTreeData(fileTypes, files = []) {
  const fileTreeData = [];
  const pathIds = files.map(item => {
    let pathIds = item.path ? item.path.split('/') : [];
    pathIds = pathIds.filter(item => !!item)
    return pathIds;
  }).flat(2).filter(it => it);
  for (const type of fileTypes) {
    const fileList = libraryConstructor.getLibraryValues(type);
    for (const file of fileList) {
      const isFolder = file.fileType.toLowerCase() === "folder"
      fileTreeData.push({
        id: file.id,
        pId: type,
        title: file.name,
        value: file.id,
        selectable: !isFolder,
        isLeaf: !isFolder,
        // icon: isFolder ? "folder" : "file",
        fileInfo: { ...file, rootId: isFolder ? file.id : null }
      });
    }

    fileTreeData.push({
      id: type,
      pId: 0,
      title: type === SPICE ? "Generic Model" : "Buffer Model",
      value: type,
      selectable: false,
      isLeaf: false,
      fileInfo: {}
    });
  }


  for (const rootId of Array.from(new Set(pathIds))) {
    if (rootId) {
      const children = await getTreeChildren(rootId);
      fileTreeData.push(...children);
    }
  }

  return fileTreeData;
}

export {
  getNodeSelectOption,
  getModelNameFilterList,
  getRxSelectList,
  getNodeDataList,
  getCtleNodePairList,
  getValueAndUnit,
  getSettingDataList,
  getSubcktByPairInfo,
  getIsShow,
  handleSelectSubckt,
  handleChangeSpiceType,
  handleSelectFile,
  handleSelectNode,
  getTooltipPrefix,
  updateSignalNameInHspiceConfig,
  delSignalInHspiceConfig,
  updateHspiceConfigComp,
  updateHSPICEModel,
  updateHSPICEPin,
  getModelKey,
  updateHSPICECompType,
  hspiceConfigErrorCheck,
  getModelSelector,
  autoMatchNode,
  getFileTreeData
}