import { getFileInRockyVerification, getRockyPackageTDR, generateTDR, getTDRConfig, getPackageTDRConfig, getPTSPWaveform, getPTSWDiffSignalName, getSSNChannelTDRPowerSumResult } from '../../api/Rocky/rockyCtrl';
import WaveformResultHelper from './helper/WaveformResultHelper';
import ResultData from './helper/resultPublic';
import { getColor } from '../../Result/Public/waveform/Plot2D';
import { splitComponentName } from '../../helper/splitComponent';
import { getPCBCompAndPin } from '../../helper/resultPublic';
import PackageVerificationInfoConstructor from '@/services/Rocky/Package/packageVerificationHelper'
import apiProcess from '../../api/utility';
import { PACKAGE_PRE_LAYOUT_RESULT, PACKAGE_RESULT, PCB_CHANNEL, PCB_CHANNEL_RESULT, PCB_PRE_LAYOUT_RESULT } from '../../../constants/treeConstants';
import { getSSNWaveform } from '../../api/Rocky/rockySSNCtrl';
import { SSN_RESULT } from '../constants';
import { PACKAGE_SEV_CHANNEL_RESULT, PATTERN_SWEEP } from '../../../services/Rocky/result/constant';
import waveFormDataHelper from '@/services/Rocky/result/helper/waveFormDataHelper';
import { COMP_INFO, WAVE_FORM_LIST, DIFF_SIGNAL_NAME, EXIST_COLOR, HAVE_EXPANDED_SIGNAL, SELECTED_COMPONENT_KEYS, SELECTED_PATTERN_KEYS, PATTERN_SWEEP_LIST } from '../../../services/Rocky/result/constant';
import preLayoutContentConstructor from '../prelayoutConstructor';
import MergeChannelResultInfoConstructor from '../SSN/mergeResultInfo';

/**
* Get the waveform result of the interface.
* 
* @param {String} interfaceName - Interface name
* @param {String} name - Design and component name e.g. 'Design_Comp'
* @param {Boolean} original - true - Hiresolution, false - Fast Display
* @returns  Return a promise, promise resolve waveform
*/
export function getWaveform({ verificationSubId,
  verificationId,
  channelId,
  interfaceName,
  original,
  getSSN }) {
  let path = `${verificationSubId}/result/${interfaceName}/data/PinToPinSim.raw`;
  if (original) {
    path = `${verificationSubId}/result/${interfaceName}/data/original/PinToPinSim.raw`;
  }
  return new Promise((resole, reject) => {
    getFileInRockyVerification({ channelId, filePath: path }).then((res) => {
      // get file success, parse the curve file
      let waveform = new WaveformResultHelper();
      getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName }).then(pinsInfo => {
        waveform.parseRawCurve(res.data, pinsInfo, false, true, getSSN);
        resole(waveform);
      })
    }, (error) => {
      if (original) {
        // 404
        getFileInRockyVerification({ channelId, filePath: `${verificationSubId}/result/${interfaceName}/data/PinToPinSim.raw` }).then((res) => {
          // get file success, parse the curve file
          let waveform = new WaveformResultHelper();
          getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName }).then(pinsInfo => {
            waveform.parseRawCurve(res.data, pinsInfo, false, true, getSSN);
            resole(waveform);
          })
        }, (error) => {
          let waveform = new WaveformResultHelper();
          console.error(error)
          resole(waveform);
        })
      } else {
        let waveform = new WaveformResultHelper();
        console.error(error)
        resole(waveform);
      }
    })
  });
}

export function getPTSPWaveformPromise({ interfaceName,
  original,
  getSSN,
  ssnChannelId,
  byteVerificationSubId,
  channelId,
  victimNet,
  patternName,
  option,
  type,
  displayMode,
  apiType
}) {
  const cacheType = type.toLowerCase() + '_' + displayMode + victimNet + patternName + option;
  return new Promise((resole, reject) => {
    const cache_waveform = waveFormDataHelper.getWaveFormInfo(cacheType, WAVE_FORM_LIST);
    if (cache_waveform && cache_waveform.signals && cache_waveform.signals.length) {
      resole(cache_waveform)
      return;
    }
    getPTSPWaveform({ channelId: ssnChannelId, victimNet, patternName, option, type, displayMode }).then((res) => {
      if (!res.data) {
        let waveform = new WaveformResultHelper();
        waveFormDataHelper.saveWaveFormInfo(cacheType, waveform, WAVE_FORM_LIST);
        resole(waveform);
      }
      // get file success, parse the curve file
      let waveform = new WaveformResultHelper();
      getComponentPinsInfo({ interfaceName, channelId, verificationSubId: byteVerificationSubId, verificationId: byteVerificationSubId, apiType }).then(pinsInfo => {
        waveform.parseJsonCurve(res.data.data, pinsInfo, false, true, getSSN);
        waveFormDataHelper.saveWaveFormInfo(cacheType, waveform, WAVE_FORM_LIST);
        resole(waveform);
      })
    }, (error) => {
      if (original) {
        // 404
        getPTSPWaveform({ channelId, victimNet, patternName, option, type, displayMode }).then((res) => {
          if (!res.data) {
            let waveform = new WaveformResultHelper();
            waveFormDataHelper.saveWaveFormInfo(cacheType, waveform, WAVE_FORM_LIST);
            resole(waveform);
          }
          // get file success, parse the curve file
          let waveform = new WaveformResultHelper();
          getComponentPinsInfo({ interfaceName, channelId, verificationSubId: byteVerificationSubId, verificationId: byteVerificationSubId, apiType }).then(pinsInfo => {
            waveform.parseJsonCurve(res.data.data, pinsInfo, false, true, getSSN);
            waveFormDataHelper.saveWaveFormInfo(cacheType, waveform, WAVE_FORM_LIST);
            resole(waveform);
          })
        }, (error) => {
          let waveform = new WaveformResultHelper();
          console.error(error)
          resole(waveform);
        })
      } else {
        let waveform = new WaveformResultHelper();
        console.error(error)
        resole(waveform);
      }
    })
  });
}

export function getPTSPDiffSignalName({ verificationId, channelId, type }) {
  const cacheType = verificationId + channelId + type;
  const cache_diffSignalName = waveFormDataHelper.getWaveFormInfo(cacheType, DIFF_SIGNAL_NAME);
  return new Promise((resolve, reject) => {
    if (cache_diffSignalName && cache_diffSignalName.data && cache_diffSignalName.data.length) {
      resolve(cache_diffSignalName)
      return;
    }
    getPTSWDiffSignalName({ verificationId, channelId, type }).then(res => {
      if (res && res.data && res.data.data && res.data.data.length) {
        waveFormDataHelper.saveWaveFormInfo(cacheType, res.data, DIFF_SIGNAL_NAME);
      }
      resolve(res.data)
    }, error => {
      console.error(error)
      resolve([])
    })
  })
}

export function getSSNWaveformPromise({ displayMode, type, verificationId }) {
  return new Promise((resole, reject) => {
    getSSNWaveform({ displayMode, type, verificationId }).then((res) => {
      let waveform = new WaveformResultHelper();
      if (res && res.data) {
        waveform.parseRawCurve(res.data, [], null, true, true);
      }
      resole(waveform);
    }, (error) => {
      let waveform = new WaveformResultHelper();
      console.error(error)
      resole(waveform);
    })
  })
}

export function getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName, resultType, apiType, isSEV }) {
  if ([PACKAGE_RESULT, PACKAGE_SEV_CHANNEL_RESULT].includes(resultType)) {
    return getPackageComponentPinsInfo(channelId)
  } else if ([PACKAGE_PRE_LAYOUT_RESULT, PCB_PRE_LAYOUT_RESULT].includes(resultType)) {
    return getPreLayoutComponentPinsInfo(verificationId)
  } else if (resultType === SSN_RESULT) {
    return getSSNComponentsPinsInfo(channelId, verificationId)
  } else {
    return getChannelComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName, apiType, isSEV })
  }
}

function getSSNComponentsPinsInfo(channelId, verificationId) {
  return new Promise((resolve, reject) => {
    MergeChannelResultInfoConstructor.getMergePCBChannelInfo(channelId, verificationId).then(info => {
      if (!info || !info.Interfaces || !info.Interfaces.length) {
        resolve([]);
        return;
      }
      // Read/Write interface
      const _interface = info.Interfaces[0];
      if (!_interface) {
        resolve([]);
        return;
      }
      let pins = [];
      try {
        resolve(parsePins(_interface));
      } catch (error) {
        console.error(error);
        resolve(pins)
      }
      return;
    })
  })
}

function getPreLayoutComponentPinsInfo(verificationId) {
  return new Promise((resolve, reject) => {
    preLayoutContentConstructor.getPreLayoutResultJson(verificationId).then(info => {
      if (!info || !info.content || !info.content.components || !info.content.components.length) {
        resolve([]);
        return;
      }
      const Components = info.content.components;
      const Chips = Components.filter(item => ['Memory', 'Controller', 'IC', 'Connector', "BGA", "DIE"].includes(item.type));
      let pins = [];
      if (Chips.length > 0) {
        Chips.forEach(comp => {
          comp.pins.forEach(pinModel => {
            const { signal, usage, pin, net } = pinModel;
            let ndEn = false;
            if (pinModel.pinModels && pinModel.pinModels.length > 0) {
              const findEn = pinModel.pinModels.find(item => item.pinName === 'nd_en');
              if (findEn && findEn.type) {
                ndEn = true;
              }
            }
            pins.push({ component: splitComponentName(comp.name), componentDisplayName: comp.name, signal, usage: usage, pin, ndEn, net, type: comp.type })
          });
        });
      };
      try {
        resolve(pins);
      } catch (error) {
        console.error(error);
        resolve(pins)
      }
      return;
    });
  })
}
export function getChannelComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName, apiType, isSEV }) {
  return new Promise((resolve, reject) => {
    if (apiType === 'isPatternSweep') {
      ResultData.getSSNVerificationJsonPromise({ verificationId, verificationSubId }).then(info => {
        if (!info || !info.interfaces || !info.interfaces.length) {
          resolve([]);
          return;
        }
        // Read/Write interface
        const _interface = info.interfaces.find(data => data.name === interfaceName);
        if (!_interface) {
          resolve([]);
          return;
        }
        let pins = [];
        try {
          resolve(parsePins(_interface));
        } catch (error) {
          console.error(error);
          resolve(pins)
        }
        return;
      });
    } else {
      ResultData.getVerificationJsonPromise({ verificationId, channelId, verificationSubId }).then(info => {
        if (!info || !info.Interfaces || !info.Interfaces.length) {
          resolve([]);
          return;
        }

        // Read/Write interface
        let _interface = info.Interfaces.find(data => data.Name === interfaceName);

        if (isSEV && !_interface && info.Interfaces.length === 1) {
          _interface = info.Interfaces[0];
        }

        if (!_interface) {
          resolve([]);
          return;
        }
        let pins = [];
        try {
          resolve(parsePins(_interface));
        } catch (error) {
          console.error(error);
          resolve(pins)
        }
        return;
      })
    }
  })
}

export function getPackageComponentPinsInfo(verificationId) {
  return new Promise((resolve, reject) => {
    PackageVerificationInfoConstructor.getPackageResultInfo(verificationId).then(info => {
      if (!info || !info.Interfaces || !info.Interfaces.length) {
        resolve([]);
        return;
      }
      // Read/Write interface
      const _interface = info.Interfaces[0];
      if (!_interface) {
        resolve([]);
        return;
      }
      let pins = [];
      try {
        resolve(parsePins(_interface));
      } catch (error) {
        console.error(error);
        resolve(pins)
      }
      return;
    })
  })
}

export function parsePins(_interface) {
  let pins = [];
  const Components = _interface.Content ? _interface.Content.Components : [];
  const dimmSignalTopology = _interface.Content ? _interface.Content.dimmSignalTopology : [];
  const Chips = Components.filter(item => ['Memory', 'Controller', 'IC', 'Connector', "BGA", "DIE"].includes(item.type));
  if (Chips.length > 0) {
    Chips.forEach(comp => {
      comp.pins.forEach(pinModel => {
        const { signal, usage, pin, net } = pinModel;
        let ndEn = false;
        if (pinModel.pinModels && pinModel.pinModels.length > 0) {
          const findEn = pinModel.pinModels.find(item => item.pinName === 'nd_en');
          if (findEn && findEn.type) {
            ndEn = true;
          }
        }

        let usedCardValue = false;
        if (dimmSignalTopology && dimmSignalTopology.length) {
          const findInfo = dimmSignalTopology.find(item => item.signalName === signal);
          if (findInfo && findInfo.dimmMemoryPins && findInfo.pcbMemoryPins && findInfo.pcbMemoryPins.find(item => item.componentName === comp.name)) {
            for (let dimmMemoryPin of findInfo.dimmMemoryPins) {
              const { componentName, pinName } = dimmMemoryPin;
              usedCardValue = true;
              pins.push({
                component: splitComponentName(componentName),
                componentDisplayName: componentName,
                signal,
                usage: usage,
                pin: pinName,
                ndEn,
                cardDriver: { component: comp.name, pin: pin },
                net,
                type: comp.type
              })
            }
          }
        }
        if (!usedCardValue) {
          pins.push({ component: splitComponentName(comp.name), componentDisplayName: comp.name, signal, usage: usage, pin, ndEn, net, type: comp.type })
        }
      });
    });
  };
  return pins;
}
/**
 * Exports a component information array list
 * 
 */
export function getComponentInfo({ curves, channelId, verificationSubId, verificationId, interfaceName, resultType, resultPageType,
  apiType, readWriteMode, displayMode, victimNet, patternName, option, isSEV }) {
  return new Promise((resolve, reject) => {
    let cacheType = null;
    if (resultType === PATTERN_SWEEP) {
      cacheType = readWriteMode.toLowerCase() + '_' + displayMode + victimNet + patternName + option;
      const cache_compInfo = waveFormDataHelper.getWaveFormInfo(cacheType, COMP_INFO);
      if (cache_compInfo && cache_compInfo.length && curves.length === cache_compInfo.length) {
        resolve(cache_compInfo)
        return;
      }
    }
    getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName, resultType, apiType, readWriteMode, displayMode, curves, isSEV }).then(pins => {
      let memoryList = [], memoryType = "Driver";
      for (let pinInfo of pins) {
        if (["Memory", "DIE"].includes(pinInfo.type) && !memoryList.includes(pinInfo.component)) {
          memoryList.push(pinInfo.component)
          memoryType = pinInfo.usage
        }
      }
      const compInfo = curves.map((curve, i) => {
        const { name, color, type } = curve;
        let item = {
          curveName: name,
          curveIndex: i,
          color,
          selectCurveName: name
        }
        if (isSEV && curve.libraryId) {
          item.libraryId = curve.libraryId;
          item.fileName = curve.fileName;
        }
        //if (name.includes('_in)')) return item;
        const indexStart = name.indexOf('('), indexEnd = name.lastIndexOf(')');
        if (indexStart > -1 && indexEnd > -1) {
          // v(pcb_)
          const _name = name.slice(indexStart + 1, indexEnd);
          let { compPinName, firstIncludePCB } = getPCBCompAndPin(_name);
          if (!firstIncludePCB) {
            // vddq curve  COMP_vddq
            if (_name.match(/_vddq$/ig)) {
              let vddqCompName = _name;
              const vddqComp = _name.split(/_vddq$/ig);
              if (vddqComp.length > 0) {
                const _pin = pins.find(pin =>
                  vddqComp[0].toLowerCase() === pin.component.toLowerCase()
                );
                if (_pin) {
                  vddqCompName = _pin.component;
                }
              };
              return { ...item, vddqCompName };
            }
          }
          let pinInfo = pins.find(pin =>
            compPinName.toLowerCase() === `${pin.component.toLowerCase()}_${pin.pin.toLowerCase()}`
          );
          if (pinInfo && name.includes('_in)')) {
            pinInfo.usage = 'Stimulus in';
          }
          if (pinInfo && name.includes('_en')) {
            if (pinInfo.ndEn) {
              pinInfo.usage = 'Stimulus en';
            } else {
              pinInfo.usage = '';
            }
          }
          let pkg = false;
          if (_name.match(/_u$/ig)) {
            pkg = true;
          }
          item = { ...item, ...pinInfo, pkg };
        } else if (type === 'ssn_pdn') {
          item = { ...item, usage: 'power', signal: "PDN", diffCompDisplayName: name }
          return item;
        } else {
          let { compPinName, firstIncludePCB } = getPCBCompAndPin(name);
          if (!firstIncludePCB) {
            // vddq curve  COMP_vddq
            if (name.match(/_vddq$/ig)) {
              let vddqCompName = name;
              const vddqComp = name.split(/_vddq$/ig);
              if (vddqComp.length > 0) {
                const _pin = pins.find(pin =>
                  vddqComp[0].toLowerCase() === pin.component.toLowerCase()
                );
                if (_pin) {
                  vddqCompName = _pin.component;
                }
              };
              return { ...item, vddqCompName };
            } else {
              // dq8_a_pn_sg
              const spliceInfo = name ? name.split("_") : [];
              const spliceNet = isSEV && spliceInfo.length > 1 ? spliceInfo[1] : spliceInfo[0];

              const memoryInRule = /_d(\d+)_io_a/i, memoryPkgRule = /_d(\d+)_io_sg/i, memoryRule = /_d(\d+)_pn_sg/i;
              const socInRule = /_a_io_a/i, socPkgRule = /_a_bp_sg/i, socRule = /_a_pn_sg/i;

              const matchValue = name.match(memoryInRule) || name.match(memoryPkgRule) || name.match(memoryRule) || null;
              let findUsage = memoryType === "Driver" ? "Receiver" : "Driver", memoryCompName = '';
              if (matchValue) {
                findUsage = memoryType;
                const index = matchValue[1] || 0;
                memoryCompName = memoryList ? memoryList.length > index ? memoryList[index] : memoryList[0] : "";
              }

              let findPinInfo = pins.find(pin => {
                const { net, usage, component, signal } = pin;
                const replaceReg = new RegExp(`${spliceNet}$|${spliceNet}_`, "i");
                let matches = net.match(replaceReg) || signal.match(replaceReg);
                if (matches && usage === findUsage && (usage !== memoryType || (!memoryCompName || memoryCompName === component))) {
                  return true
                }
                if (isSEV && matches) {
                  return true;
                }
                return false
              })
              let newFindPinInfo = findPinInfo ? JSON.parse(JSON.stringify(findPinInfo)) : ""

              if (newFindPinInfo && (name.match(memoryInRule) || name.match(socInRule))) {
                newFindPinInfo.usage = 'Stimulus in';
              }
              let pkg = false;
              if (newFindPinInfo && (name.match(memoryPkgRule) || name.match(socPkgRule))) {
                pkg = true;
              }

              let io = false;
              if (newFindPinInfo && name.match(/_a_io_sg/i)) {
                io = true;
              }


              return { ...item, ...newFindPinInfo, pkg, io }
            }
          };
          let pinInfo = pins.find(pin =>
            compPinName.toLowerCase() === `${pin.component.toLowerCase()}_${pin.pin.toLowerCase()}`
          );
          if (pinInfo && name.includes('_in')) {
            pinInfo.usage = 'Stimulus in';
          }
          if (pinInfo && name.includes('_en')) {
            if (pinInfo.ndEn) {
              pinInfo.usage = 'Stimulus en';
            } else {
              pinInfo.usage = '';
            }
          };
          let pkg = false;
          if (name.match(/_u$/ig)) {
            pkg = true;
          }
          item = { ...item, ...pinInfo, pkg };
        }
        return item;
      });
      if (resultPageType === SSN_RESULT) {
        let newCompInfo = getDiffSignalInfo(compInfo, pins)
        resolve(newCompInfo)
      } else {
        resolve(compInfo);
      }
    })
  })

};

function getDiffSignalInfo(compInfo) {
  const positive = compInfo.filter(item => item.signal && item.signal.match(/P$|P[A-Z]$|(_T)$|T[A-Z]$/ig));
  const negative = compInfo.filter(item => item.signal && item.signal.match(/N$|N[A-Z]$|(_C)$|C[A-Z]$/ig));

  const _compInfo = compInfo.map(curvesInfo => {
    if (curvesInfo && curvesInfo.signal && curvesInfo.signal.match(/CLK|DQS|RDQS|WCK|(DQS[0-9]+P)|(DQS[0-9]+N)/ig)) {
      const diffRule = /P$|P[A-Z]$|(_T)$|T[A-Z]$|N$|N[A-Z]$|(_C)$|C[A-Z]$/ig;
      if (!curvesInfo.curveName.match(diffRule) && !curvesInfo.curveName.match(/a_io_a/ig)) {

        let newPinInfo = JSON.parse(JSON.stringify(curvesInfo))
        const { component, usage, pkg, curveName, componentDisplayName, pin, io, signal } = newPinInfo;
        let signalName = '';

        const findNCurves = negative.filter(item => item.component === component && item.usage === usage && item.pkg === pkg && item.io === io);
        let findNCurve = findNCurves[0];
        const findPCurves = positive.filter(item => item.component === component && item.usage === usage && item.pkg === pkg && item.io === io);
        let findPCurve = findPCurves[0];
        if (signal.match(/^[DQS/CLK].*[P]$|[DQS/CLK].*[P][A-Z]$/)) {
          const nName = signal.replace("P", 'N');
          signalName = `${signal} - ${nName}`
        } else if (signal.match(/^([RDQS|WCK]+(_T))|^([RDQS|WCK]+[0-9]+T)/ig)) {
          const nName = signal.replace("T", 'C');
          signalName = `${signal} - ${nName}`
        } else if (signal.match(/^(CLKP+[A-Z])$|^(CLKP)/ig)) {
          const nName = signal.replace("P", 'N');
          signalName = `${signal} - ${nName}`
        } else {
          switch (signal) {
            case 'DQSP':
              signalName = 'DQSP - DQSN';
              break;
            case 'CLKP':
              signalName = 'CLKP - CLKN';
              break;
            case 'WCK_T':
              signalName = 'WCK_T - WCK_C';
              break;
            case 'RDQS_T':
              signalName = 'RDQS_T - RDQS_C';
              break;
            default: break;
          }
        }


        delete newPinInfo.type;
        delete newPinInfo.net;
        delete newPinInfo.pin;
        newPinInfo = {
          ...newPinInfo,
          curveName: pkg ? `${signalName}_${component}_${usage}_u` : `${signalName}_${component}_${usage}`,
          curves: [findPCurve && findPCurve.curveName ? findPCurve.curveName : curveName, findNCurve && findNCurve.curveName ? findNCurve.curveName : curveName],
          signal: signalName,
          diffCompDisplayName: findNCurves.length > 1 && usage === "Receiver" ? `${componentDisplayName}::${pin}  - ${findNCurve.pin}` : null,
          selectCurveName: curveName
        };
        return newPinInfo
      }
    }
    return curvesInfo;
  })
  return _compInfo
}

export function addDiffSignal(compInfo) {
  let _compInfo = [...compInfo];
  const diffSignals = compInfo.filter(item => item.signal && item.signal.match(/CLK|DQS|RDQS|WCK/ig));
  const positive = diffSignals.filter(item => item.signal && item.signal.match(/P$|(_T)$/ig));
  const negative = diffSignals.filter(item => item.signal && item.signal.match(/N$|(_C)$/ig));

  for (let p of positive) {
    const { component, usage, pkg, curveIndex, io } = p;
    const findNCurves = negative.filter(item => item.component === component && item.usage === usage && item.pkg === pkg && item.io === io);
    if (!findNCurves.length) {
      continue;
    }
    let findNCurve = findNCurves[0];
    if (findNCurves.length > 1) {
      const _findNCurves = findNCurves.filter(item => item.curveIndex > curveIndex).sort((a, b) => (a.curveIndex - b.curveIndex));
      findNCurve = _findNCurves.length ? _findNCurves[0] : findNCurve;
    }

    let signalName = '';
    switch (p.signal) {
      case 'DQSP':
        signalName = 'DQSP - DQSN';
        break;
      case 'CLKP':
        signalName = 'CLKP - CLKN';
        break;
      case 'WCK_T':
        signalName = 'WCK_T - WCK_C';
        break;
      case 'RDQS_T':
        signalName = 'RDQS_T - RDQS_C';
        break;
      default: break;
    }
    const curveItem = {
      color: getColor(_compInfo.length),
      component: p.component,
      componentDisplayName: p.componentDisplayName,
      curveIndex: _compInfo.length,
      curveName: p.pkg ? `${signalName}_${p.component}_${p.usage}_u` : `${signalName}_${p.component}_${p.usage}`,
      curves: [p.curveName, findNCurve.curveName],
      pkg: p.pkg,
      signal: signalName,
      usage: p.usage,
      diffCompDisplayName: findNCurves.length > 1 && usage === "Receiver" ? `${p.componentDisplayName}::${p.pin}  - ${findNCurve.pin}` : null,
      cardDriver: p.cardDriver && p.cardDriver.component ? { component: p.cardDriver.component } : null,
      io: p.io
    };
    _compInfo.push(curveItem);
  }
  return _compInfo;
}

async function getPreLayoutInfo({ fileList, readWriteMode, verificationId, channelId, verificationSubId, interfaceName, resultType }) {
  let TDRInfo = new WaveformResultHelper();
  try {
    let data = [];
    for (let fileInfo of fileList) {
      const { libraryId, fileName } = fileInfo;
      let res = await getSSNChannelTDRPowerSumResult({ type: readWriteMode, verificationId, libraryId, option: "tdr" });
      if (res && res.data) {
        data.push({ data: res.data, libraryId, fileName })
      }
    }

    TDRInfo.parsePreLayoutRawCurve(data);
    return TDRInfo;
  } catch (e) {
    console.error(e);
    return TDRInfo;
  }
}

export function getTDRSettingData({ verificationSubId, channelId, interfaceName, verificationId, fileName, channelName, resultType, readWriteMode, fileList }) {
  if (resultType === 'channel_result') {
    let path = `${verificationSubId}/result/${channelName}/data/waveform/${fileName}`;
    return new Promise((resole, reject) => {
      getFileInRockyVerification({ channelId, filePath: path }).then((res) => {
        let TDRInfo = new WaveformResultHelper();
        getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName }).then(pinsInfo => {
          TDRInfo.parseRawCurve(res.data, pinsInfo, undefined, undefined, true);
          resole(TDRInfo);
        })
      }, (error) => {
        let TDRInfo = new WaveformResultHelper();
        console.error(error)
        resole(TDRInfo);
      })
    })
  } else if ([PCB_PRE_LAYOUT_RESULT, PACKAGE_PRE_LAYOUT_RESULT].includes(resultType)) {
    return new Promise((resole, reject) => {
      getPreLayoutInfo({ fileList, readWriteMode, verificationId, channelId, verificationSubId, interfaceName, resultType }).then((res) => {
        resole(res);
      }, (error) => {
        console.error(error)
      })
    })
  } else if ([PCB_CHANNEL_RESULT, "package_sev_channel_result", SSN_RESULT].includes(resultType)) {
    return new Promise((resole, reject) => {
      getSSNChannelTDRPowerSumResult({ type: readWriteMode, verificationId, option: "tdr" }).then((res) => {
        let TDRInfo = new WaveformResultHelper();
        getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName, resultType, isSEV: true }).then(pinsInfo => {
          TDRInfo.parseRawCurve(res.data, pinsInfo, undefined, undefined, true);
          resole(TDRInfo);
        })
      }, (error) => {
        let TDRInfo = new WaveformResultHelper();
        console.error(error)
        resole(TDRInfo);
      })
    })
  } else {
    return new Promise((resole, reject) => {
      getRockyPackageTDR(channelId, readWriteMode).then((res) => {
        let TDRInfo = new WaveformResultHelper();
        PackageVerificationInfoConstructor.getPackageResultInfo(channelId).then(info => {
          getComponentPinsInfo({ verificationId, channelId, verificationSubId, interfaceName, resultType }).then(pinsInfo => {
            TDRInfo.parseRawCurve(res.data, pinsInfo, undefined, undefined, true);
            resole(TDRInfo);
          })
        })
      }, (error) => {
        let TDRInfo = new WaveformResultHelper();
        console.error(error)
        resole(TDRInfo);
      })
    })
  }
}

export function getTDRConfigPromise(verificationId) {
  return apiProcess(getTDRConfig, verificationId)
}

export function getPackageTDRConfigPromise(channelId) {
  return apiProcess(getPackageTDRConfig, channelId)
}

export function generateTDRResult(params) {
  return apiProcess(generateTDR, params, false, true)
}