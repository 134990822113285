import React, { Component } from 'react';
import EditableTable from '@/components/EditableTable';
import "../index.css";

const columns = [{
  title: "CSM Instance Signal",
  dataIndex: "csmSignal",
  width: "24%",
  sorter: ((a, b) => { return sortFunction(a, b, 'csmSignal') }),
}, {
  title: "CSM SPICE Node Names (Write)",
  dataIndex: "csmInst",
  width: "25%"
}, {
  title: "CSM SPICE Node Names (Read)",
  dataIndex: "csmReadInst",
  width: "25%"
}, {
  title: "Channel Signal",
  dataIndex: "pkgSignal",
  width: "14%",
  sorter: ((a, b) => { return sortFunction(a, b, 'pkgSignal') }),
}, {
  title: "PKG Net",
  dataIndex: "net",
  width: "20%",
  sorter: ((a, b) => { return sortFunction(a, b, 'net') }),
},
{
  title: "PCB Net",
  dataIndex: "pcbNet",
  width: "16%",
}]

const list = ["CK", "CS", "CA", "DM", "CLK", "DQS", "DQ"];
function sortFunction(a, b, key) {
  const aIndex = list.findIndex(it => a[key] && a[key].includes(it))
  const bIndex = list.findIndex(it => b[key] && b[key].includes(it))
  if (aIndex > -1 && bIndex > -1) {
    if (aIndex === bIndex) {
      const aNumber = a[key] && a[key].match(/\d+/g);
      const bNumber = b[key] && b[key].match(/\d+/g);
      return aNumber - bNumber;
    }
    return bIndex - aIndex
  } else if (aIndex > -1) {
    return 1;
  } else if (bIndex > -1) {
    return -1
  }
  return 0
}
class CSMSignalsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    columns[0].onCell = (record) => {
      const options = this.getPortsOptions(record);
      return {
        edit: "select",
        record: { ...record, csmSignal: record.csmSignal ? `${record.csmInstanceSignal} :: ${record.csmSignal} :: ${record.pad} :: ${record.in} :: ${record.readPad}` : "" },
        options,
        displaySelectStatus: true,
        dataIndex: "csmSignal",
        getPopupContainer: document.getElementById('root'),
        dropdownMenuClassName: "aurora-select-dropdown",
        handleSave: (_record, prevRecord) => { this.editCSMSignal(_record, prevRecord) },
      }
    }

    columns[0].render = (text, record) => {
      const name = record.csmInstanceSignal ? `${record.csmInstanceSignal} :: ${record.csmSignal}` : record.csmSignal;
      return <span>{name}</span>
    }

    columns[1].render = (text, record) => {
      const name = record.pad && record.in ? `${record.pad}, ${record.in}` : record.pad ? record.pad : record.in;
      return <span>{name}</span>
    }

    columns[2].render = (text, record) => {
      return <span>{record.readPad}</span>
    }

    columns[4].render = (text, record) => {
      return <span>{(record.nets || []).join(", ")}</span>
    }

    columns[5].render = (text, record) => {
      return <span>{(record.pcbNets || []).join(", ")}</span>
    }
  }

  showPinAndNode = (record, type) => {
    let pins = [], nodes = [];
    pins.push(record.pkgPin);
    if (record.csmSignal) {
      nodes.push(record.csmSignal);
    }
    if (this.props.updateShowPinNodeObj) {
      this.props.updateShowPinNodeObj({
        showPinNodeObj: {
          pins,
          nodes
        },
        isShow: true
      })
    }
  }

  editCSMSignal = (record, prevRecord) => {
    const { signalMapping, portList } = this.props;
    const [csmInstanceSignal, csmSignal, PAD, IN, READ_PAD] = record.csmSignal ? record.csmSignal.split(" :: ") : null;
    if (!csmSignal) {
      return;
    }
    let _signalMapping = [...signalMapping];
    const index = _signalMapping.findIndex(item => item.pkgSignal === record.pkgSignal);
    if (index < 0) {
      return;
    }
    const findPort = portList.find(item => item.name === csmSignal && item.port.PAD === PAD && item.port.IN === IN && item.port.READ_PAD === READ_PAD);
    if (!findPort) {
      return;
    }
    //remove exist csm signal in other signals
    const prevIndex = _signalMapping.findIndex(item => item.csmSignal === csmSignal && item.pad === PAD && item.in === IN);
    if (prevIndex > -1) {
      _signalMapping[prevIndex] = {
        ..._signalMapping[prevIndex],
        csmSignal: "",
        pad: "",
        in: "",
        readPad: "",
        csmInstanceSignal: ""
      }
    }
    _signalMapping[index] = {
      ..._signalMapping[index],
      csmSignal: findPort.name,
      pad: findPort.port.PAD,
      in: findPort.port.IN,
      readPad: findPort.port.READ_PAD,
      csmInstanceSignal: findPort.net
    }
    this.props.editSignalMapping(_signalMapping)
  }


  getPortsOptions = (record) => {
    const { portList, signalMapping } = this.props;
    let selectedList = [], curr = {}, list = [];
    for (let port of portList) {
      const { port: portInfo } = port;
      const key = `${port.net} :: ${port.name} :: ${portInfo.PAD} :: ${portInfo.IN} :: ${portInfo.READ_PAD}`;

      if (port.name === record.csmSignal && portInfo.PAD === record.pad && portInfo.IN === record.in) {
        curr = {
          key,
          title: key,
          currSelected: true
        }
        continue;
      }
      const findPort = signalMapping.find(item => item.csmSignal === port.name && item.pad === portInfo.PAD && item.in === portInfo.IN);

      if (findPort) {
        selectedList.push({
          key,
          title: key,
          selected: true
        })
      } else {
        list.push({
          key,
          title: key,
          selected: false
        })
      }
    }

    return curr ? [curr, ...list, ...selectedList] : [...list, ...selectedList]
  }

  render() {
    const { signalMapping, maxHeight } = this.props;
    return <EditableTable
      className="cpm-connection-edit-table"
      columns={columns}
      style={{ maxHeight: (maxHeight - 78) }}
      rowKey={(record) => record.pkgSignal}
      dataSource={signalMapping}
      scroll={{ y: (maxHeight - 116) }}
      onRow={record => {
        return {
          onClick: e => this.showPinAndNode(record),
        };
      }}
    />
  }
}
export default CSMSignalsTable;