import http from './http';

export function getUsers(page = 0, size = 10) {
  return http.get('/admin/users/v2', {
    params: { page, size }
  })
}

export function createUserWithRole(data) {
  return http.post('/admin/user/create', data);
}

export function updateUser({ id, data }) {
  return http.put(`/admin/users/${id}`, data)
}

export function deleteUser(id) {
  return http.post(`/admin/user/delete?userId=${id}`);
}

/**
 * 2019/10/11
 * 
 * Update user password
 * 
 * @export
 * @param {*} { confirmPassword, password, userId } - password, user id
 * @returns
 */
export function updatePassword({ confirmPassword, password, userId }) {
  return http.post(`/admin/users/password`, {
    confirmPassword,
    password,
    userId
  })
}

export function getUserEmailList() {
  return http.get(`/admin/users/emails`);
}

export function getUserRoleList() {
  return http.get(`/admin/roles`);
}