import React, { Component, Fragment } from 'react'
import Panel from '@/components/Panel';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Select, Input, Divider } from 'antd';
import { createPortal } from 'react-dom';
import { numberCheck } from '@/services/helper/dataProcess';
import { DriverPoints, getDefaultList, ReceiverPoints } from '../../services/Andes_v2/constants';
import { PCIE, CPHY } from '../../services/PCBHelper/constants';
import { INSERTIONLOSS, RETURNLOSS, COMRETURN, COMINSERTION, DIFFTOCOM } from '../../services/helper/reportHelper';
import './index.css';

const { Option } = Select

class MaskInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayInput: true,
      dataRate: props.dataRate,
      _maskList: [],
      error: '',
      cphyInsertionLossSpec: props.cphyInsertionLossSpec || 'Short',
    }
    this.dialogRoot = document.getElementById('root');
  }

  // componentDidMount() {
  //   const { defaultDataRate, maskList, type, dataRate, PCIeType } = this.props;
  //   if ((!maskList || maskList.length === 0)
  //     && ((PCIeType === PCIE && (type === INSERTIONLOSS || type === RETURNLOSS || type === COMRETURN)) || PCIeType === CPHY)) {
  //     this.setState({
  //       dataRate: defaultDataRate
  //     }, () => {
  //       this.getDefaultMaskList(defaultDataRate);
  //     })
  //   } else {
  //     this.setState({
  //       dataRate,
  //       _maskList: maskList,
  //     })
  //   }
  // }

  componentDidUpdate() {
    const { type, updateMaskTypes, maskList } = this.props;
    // The parent component update the symbolRate or frequency
    if (updateMaskTypes && updateMaskTypes.includes(type)) {
      this.setState({ _maskList: maskList });
      this.props.changeUpdateMaskTypes([]);
    }
  }

  getDefaultMaskList = (defaultRate, isChangeDataRate, isChangeCPHYInsertionLossSpec, isChangeSymbolRate) => {
    const { cphyInsertionLossSpec } = this.state;
    const { type, PCIeType, dataRate, symbolRate, frequency: { fLPMax, fh, fMax, fINTMin }, pointType } = this.props;
    const _defaultRate = defaultRate || dataRate;
    const defaultList = getDefaultList({ PCIeType, type, dataRate: _defaultRate, symbolRate, fLPMax, fh, fMax, fINTMin, cphyInsertionLossSpec, pointType });
    this.setState({
      _maskList: [...defaultList]
    })
    const list = defaultList.map(item => {
      return {
        x: item.x.map(_x => Number(_x)),
        y: item.y.map(_y => Number(_y))
      }
    })
    this.props.changeMaskList({ type, list, dataRate: _defaultRate, isChangeDataRate, cphyInsertionLossSpec, isChangeCPHYInsertionLossSpec, symbolRate, isChangeSymbolRate });
  }

  closeModal = () => {
    const { type } = this.props;
    const { dataRate, _maskList } = this.state;
    let error = '';
    for (const item of _maskList) {
      if (!!numberCheck(item.x[0]) || !!numberCheck(item.x[1]) || !!numberCheck(item.y[0]) || !!numberCheck(item.y[1])
        || item.x[0] < 0 || item.x[1] < 0 || item.y[0] > 0 || item.y[1] > 0) {
        error = 'Please enter correct data.';
        break;
      }
    }
    if (error) {
      this.setState({ error })
    } else {
      this.setState({ displayInput: true })
      const list = _maskList.filter(item => {
        if (item.x && item.x.length === 2 && item.y && item.y.length === 2) {
          return {
            x: item.x.map(_x => Number(_x)),
            y: item.y.map(_y => Number(_y))
          }
        }
      })
      this.props.changeMaskList({ type, list, dataRate });
    }
  }

  addMask = () => {
    const { _maskList } = this.state;
    const list = [..._maskList];
    list.push({ x: [], y: [] });
    this.setState({
      _maskList: [...list]
    })
  }

  deleteMask = (index) => {
    const { _maskList } = this.state;
    const list = [..._maskList];
    list.splice(index, 1)
    this.setState({
      _maskList: [...list]
    })
  }

  inputChange = (e, index, valueType) => {
    e.stopPropagation();
    const { _maskList } = this.state;
    const list = [..._maskList];
    const value = e.target.value;
    this.setState({ error: null })
    if (valueType === 'xMin') {
      list[index].x[0] = value
    } else if (valueType === 'xMax') {
      list[index].x[1] = value
    } else if (valueType === 'yMin') {
      list[index].y[0] = value
    } else {
      list[index].y[1] = value
    }
    this.setState({
      _maskList: [...list]
    })
  }

  dataRateChange = (value) => {
    this.setState({ dataRate: String(value) }, () => {
      this.getDefaultMaskList(value, true);
    })
  }

  changeCPHYInsertionLossSpec = (value) => {
    this.setState({ cphyInsertionLossSpec: value }, () => {
      this.getDefaultMaskList('', false, true);
    })
  }

  CPHYConfigRender = (type, pointType) => {
    const { cphyInsertionLossSpec } = this.state;
    return <Fragment>
      {
        (type === INSERTIONLOSS || type === COMINSERTION) && ![DriverPoints, ReceiverPoints].includes(pointType) && <div className='advance-mask-dataRate'>
          <label className='advance-mask-dataRate-title'>Line Type</label>
          <Select
            size='small'
            value={cphyInsertionLossSpec}
            className='advance-mask-dataRate-select'
            getPopupContainer={() => document.getElementById('root')}
            popupClassName='advance-mask-dataRate-select-dropdown'
            onChange={(value) => this.changeCPHYInsertionLossSpec(value)}
          >
            {["Short", "Standard", "Long"].map(item =>
              <Option value={item} key={item}>{`${item + ' Reference'}`}</Option>
            )}
          </Select>
        </div>
      }
      <Divider className='advance-mask-dataRate-divider' />
    </Fragment>
  }


  renderDialog = () => {
    const { displayInput, _maskList, error, dataRate } = this.state;
    const _dataRate = String(dataRate);
    const { type, typeName, PCIeType, pointType } = this.props;
    const dataRateList = ["2.5", "5", "8", "16", "32"];
    // const dropdownMenuHeight = ['100px', '100px', '110px', '130px'];
    const content = (
      displayInput ? null : <Panel
        className={`mask-${type}-input-panel`}
        title={<div className='advance-mask-panel-title'>{`${typeName}  Mask`}</div>}
        onCancel={() => this.closeModal()}
        width={600}
        draggable
        minHeight={300}
        zIndex={3000}
        position='panel-center'
      >
        {PCIeType === PCIE && (type === INSERTIONLOSS || type === RETURNLOSS || type === COMRETURN) && <Fragment>
          <div className='advance-mask-dataRate'>
            <label className='advance-mask-dataRate-title'>Data Rate</label>
            <Select
              size='small'
              value={_dataRate}
              className='advance-mask-dataRate-select'
              getPopupContainer={() => document.getElementById('root')}
              popupClassName='advance-mask-dataRate-select-dropdown'
              // dropdownMenuStyle={{ height: (_maskList && _maskList.length <= 3) ? dropdownMenuHeight[_maskList.length] : '100%' }}
              onChange={(value) => this.dataRateChange(value)}
            >
              {dataRateList.map(item =>
                <Option value={item} key={item}>{`${item}GT/s`}</Option>
              )}
            </Select>
          </div>
          <Divider className='advance-mask-dataRate-divider' />
        </Fragment>}
        {PCIeType === CPHY && this.CPHYConfigRender(type, pointType)}
        <div className='advance-mask-inputGroupList-box'>
          <PlusCircleOutlined
            title='add mask'
            className='advance-mask-add-icon'
            onClick={() => { this.addMask() }} />
          <div className='advance-mask-inputGroupList'>
            {_maskList.map((item, index) => {
              const _x = item.x
              const _y = item.y
              return (
                <div key={index} className='advance-mask-inputGroup'>
                  <div className='mask-min-inputGroup'>
                    <Input className='mask-panel-input' id='yMin' size='small' value={_y[0]} onChange={(e) => { this.inputChange(e, index, 'yMin') }}></Input>
                    <span>dB@</span>
                    <Input className='mask-panel-input' id='xMin' size='small' value={_x[0]} onChange={(e) => { this.inputChange(e, index, 'xMin') }}></Input>
                    <span>GHz</span>
                  </div>
                  <div className='mask-link'></div>
                  <div className='mask-max-inputGroup'>
                    <Input className='mask-panel-input' id='yMax' size='small' value={_y[1]} onChange={(e) => { this.inputChange(e, index, 'yMax') }}></Input>
                    <span>dB@</span>
                    <Input className='mask-panel-input' id='xMax' size='small' value={_x[1]} onChange={(e) => { this.inputChange(e, index, 'xMax') }}></Input>
                    <span>GHz</span>
                  </div>
                  <CloseOutlined
                    title='delete mask'
                    className='advance-mask-delete-icon'
                    onClick={() => { this.deleteMask(index) }}></CloseOutlined>
                </div>
              );
            })}
          </div>
        </div>
        {error ? <span className={"aurora-error-msg-span"}>{error}</span> : null}
      </Panel>)
    return createPortal(content, this.dialogRoot)
  }

  openPanel = () => {
    const { maskList, dataRate } = this.props;
    this.setState({
      dataRate,
      _maskList: maskList,
      displayInput: false
    })
  }

  render() {
    const { maskList, pointType, type } = this.props;
    const { displayInput } = this.state;
    let disabled = false;
    if (!displayInput || (pointType === DriverPoints && type !== RETURNLOSS) || (pointType === ReceiverPoints && ![RETURNLOSS, COMRETURN, DIFFTOCOM].includes(type))) {
      disabled = true;
    }
    return (
      <Fragment>
        <div
          className={'advance-mask-id'}
          onClick={() => this.openPanel()}
        >
          {
            !maskList || maskList.length === 0 ?
              <Input className='mask-input-show-noData' disabled={disabled} />
              : <div className='mask-input-show'>
                <ul>
                  {maskList.map((mask, index) =>
                    <li key={`${mask}_${index}`}>
                      {`[${mask.y[0]}dB@${mask.x[0]}G,${mask.y[1]}dB@${mask.x[1]}G]`}
                    </li>
                  )}
                </ul>
              </div>
          }
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default MaskInput