import { getExperimentGroupList } from '../projectCtrl';
import { getExpTreeNode } from '../helper/sweepingHelper';

class SweepData {

  constructor() {
    this.data = new Map(); //key-verificationId, value [sweep]
    this.experiments = new Map();
    this.preLayoutInfo = new Map();
  }

  getLocalData = (verificationId) => {
    return this.data.get(verificationId) || [];
  }

  getData = (verificationId) => {
    return new Promise((resolve, reject) => {
      const data = this.data.get(verificationId);
      if (!data) {
        getExperimentGroupList(verificationId).then(res => {
          if (res) {
            const experiments = getExpTreeNode(res);
            this.data.set(verificationId, experiments);
            for (let item of experiments) {
              this.experiments.set(item.id, item)
            }
            resolve(experiments);
          } else {
            resolve([])
          }
        })
      } else {
        resolve(data)
      }
    })
  }

  delete = (verificationId, sweepId) => {
    const data = this.data.get(verificationId);
    if (data && data.length) {
      const newData = data.filter(item => item.id !== sweepId);
      this.data.set(verificationId, newData)
      this.experiments.delete(sweepId);
    }
  }

  add = (verificationId, sweepRes) => {
    let data = this.data.get(verificationId);
    if (Array.isArray(data)) {
      data.push(...getExpTreeNode([sweepRes]))
    } else {
      data = getExpTreeNode([sweepRes])
    }
    this.data.set(verificationId, data)
  }

  rename = (sweepId, name, verificationId) => {
    let data = this.data.get(verificationId);
    if (data) {
      let findIndex = data.findIndex(item => item.id === sweepId);
      if (findIndex > -1) {
        data[findIndex].name = name;
        this.data.set(verificationId, data)
      }
    }
  }

  getSweep = (sweepId, verificationId) => {
    let data = this.data.get(verificationId);
    if (data) {
      let findIndex = data.findIndex(item => item.id === sweepId);
      if (findIndex > -1) {
        return data[findIndex]
      }
    }
    return null;
  }

  getExperiment = (expId) => {
    return this.experiments.get(expId);
  }

  getExpRootVerificationId = (expId) => {
    const exp = this.experiments.get(expId);
    //rootVerificationId
    return exp ? exp.verificationId : null
  }
}


const sweepData = new SweepData();

export default sweepData