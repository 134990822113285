export const OPEN_UPLOAD_PROGRESS_MODAL = 'PDN/open_upload_progress_modal';
export const UPLOAD_PROGRESS_CHANGE = 'PDN/upload_progress_changed';
export const SHOW_ERROR_MSG = 'PDN/show_error_msg';
export const CLOSE_UPLOAD_PROGRESS_MODAL = 'PDN/close_upload_progress_modal';

export const TRANSLATION_SUCCESS = 'PDN/translation_success';
export const TRANSLATION_RUNNING = 'PDN/translation_running';
export const TRANSLATION_FAILED = 'PDN/translation_failed';

export const GET_WORK_FLOWS = 'PDN/get_work_flows';
export const TRANSLATION_MONITOR = 'PDN/translation_monitor';
export const GET_TRANSLATION_MONITOR = 'PDN/get_translation_monitor';
export const UPLOAD_PROJECT_ID = 'PDN/upload_project_id';
export const ADD_MSG = 'PDN/add_msg';
export const CURRENT_UPLOAD_DESIGN_ID = 'PDN/current_upload_design_Id';
export const SAVE_CURRENT_PCB_KEY = 'PDN/save_current_pcb_key';

export const TRANSLATION_DEBUG = 'PDN/translation_debug';
export const CANCEL_UPLOAD_WORKFLOW = 'PDN/cancel_upload_workflow';

export const CHANGE_DISABLE_STATUS = 'PDN/change_disable_status';

export const UPDATE_COMPRESS_PROGRESS = 'PDN/update_compress_progress';