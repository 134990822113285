import { TERMINATION_DDR_TYPES } from '../constants';
import { MEMORY, BYTE, NET, INSERTIONLOSS, SIGNALSPACING, SIGNAL } from './preLayoutConfig';
import { SectionColumn } from '../../PreLayout';

// create columns for data buses table
function getDataBusesColumns(signalGroup) {
  let columns = [];
  let sectionsList = [];
  let sectionsColumns = [];

  if (signalGroup && signalGroup.length > 0 && signalGroup[0].signals.length > 0) {
    sectionsList = signalGroup[0].signals[0].sections ? signalGroup[0].signals[0].sections : [];
  }
  const allowDel = sectionsList.length === 1 ? false : true
  sectionsList.forEach((section, index) => {
    sectionsColumns.push(SectionColumn({ sectionIndex: section.id, allowDel, index: index + 1 }))
  })


  if (!sectionsColumns || sectionsColumns.length === 0) {
    sectionsColumns.push(SectionColumn({ sectionIndex: 1, allowDel: false }))
  }

  const leftColumns = [{
    title: 'Byte',
    dataIndex: BYTE,
    key: BYTE,
    width: 100
  }, {
    title: 'Net',
    dataIndex: NET,
    key: NET,
    width: 80
  }, {
    title: 'Signal Spacing',
    dataIndex: SIGNALSPACING,
    key: SIGNALSPACING,
    width: 80
  }];

  const rightColumns = [{
    title: 'Insertion Loss (dB)',
    dataIndex: INSERTIONLOSS,
    key: INSERTIONLOSS,
    width: 150
  }]

  columns = [...leftColumns, ...sectionsColumns];

  return columns;
}

// CLK table
function getCLKColumns(components, type, partLength) {
  if (!partLength || partLength.length === 0) {
    return [];
  }
  const leftColumns = [{
    title: 'Signal',
    dataIndex: SIGNAL,
    key: SIGNAL,
    width: 100
  }, {
    title: 'Net',
    dataIndex: NET,
    key: NET,
    width: 80
  }, {
    title: 'Signal Spacing',
    dataIndex: SIGNALSPACING,
    key: SIGNALSPACING,
    width: 80
  }];

  let mainColumns = [];
  let sectionIndex = 1;
  for (let i = 0; i < components.length - 1; i++) {
    let sectionColumn = {
      title: `${components[i].name} --> ${components[i + 1].name}`,
      dataIndex: `${components[i + 1].name}`,
      key: `${components[i].name}`,
      index: i,
      children: []
    };
    let section = partLength.find(part => part.name === sectionColumn.title);
    if (section) {
      let num = Number(section['numberOfSections']);
      for (let i = 0; i < num; i++) {
        sectionColumn.children.push(SectionColumn({ sectionIndex, allowDel: num > 1 }))
        sectionIndex++;
      }
      mainColumns.push(sectionColumn)
    }
  }

  if (!TERMINATION_DDR_TYPES.includes(type) && components.length > 0) {
    let _length = components.length;
    let lastColumn = {
      title: `${components[_length - 1].name} --> Termination`,
      dataIndex: `ToTermination`,
      key: `${components[_length - 1].name}`,
      index: _length - 1,
      children: []
    }
    let termination = partLength.find(part => part.name === lastColumn.title);
    if (termination) {
      let num = Number(termination['numberOfSections']);
      for (let i = 0; i < num; i++) {
        lastColumn.children.push(SectionColumn({ sectionIndex, allowDel: num > 1 }))
        sectionIndex++;
      }
      mainColumns.push(lastColumn)
    }
    let terminationColumn = {
      title: `Termination`,
      dataIndex: `Termination`,
      key: `Termination`,
      index: _length,
      children: [SectionColumn({ sectionIndex, value: true })]
    }
    mainColumns.push(terminationColumn)
  }

  return [...leftColumns, ...mainColumns];
}

// branch panel
function getBranchColumns(length) {
  const headColumns = [{
    title: MEMORY,
    dataIndex: MEMORY,
    key: MEMORY,
    width: 80
  }]

  let sectionColumns = [];
  for (let i = 1; i <= length; i++) {
    sectionColumns.push(SectionColumn({ sectionIndex: i, allowDel: false, lengthWidth: 80, templateWidth: 100 }))
  }

  return [...headColumns, ...sectionColumns];
}

export {
  getDataBusesColumns,
  getCLKColumns,
  getBranchColumns
}