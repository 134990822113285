function parseXmlFile(fileContent) {

  if (!fileContent || typeof (fileContent) !== "string") {
    return null
  }
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(fileContent, 'text/xml')
  return xmlDoc;
}

const NAME = "profile_name",
  DIRECTION = "profile_direction",
  DIAMETER = "profile_diameter",
  MATERIAL = "profile_material",
  POINT = "profile_point";
function parseWireBondProFile(xmlDoc) {
  if (!xmlDoc) {
    return null;
  }
  const defList = xmlDoc.getElementsByTagName("wire_profile_def");

  if (!defList.length) {
    return null
  }
  let list = []
  for (let defItem of defList) {
    if (!defItem.children || !defItem.children.length) {
      continue;
    }
    let defChildren = [];
    for (let item of defItem.children) {
      const nodeName = item.nodeName;
      const key = nodeName.split("_")[1];
      const title = getNodeNameTitle(key);
      if (nodeName === POINT) {
        defChildren.push({ title, key, value: getProfilePointChildren(item) })
      } else {
        defChildren.push({ title, key, value: item.textContent })
      }
    }
    list.push(defChildren)
  }
  return list;
}

function getNodeNameTitle(str) {
  const firstLetter = str.charAt(0);
  const remainingLetters = str.slice(1)
  const firstLetterCap = firstLetter.toUpperCase();
  return firstLetterCap + remainingLetters;
}

function getProfilePointChildren(pointEle) {
  let children = [], childrenObj = {}
  for (let item of pointEle.children || []) {
    const nodeName = item.nodeName;
    const [key, keyType] = nodeName.split("_");
    const title = getNodeNameTitle(key);
    if (keyType === "type") {
      childrenObj[key] = {
        title,
        key
      }
    } else if (keyType === "value") {
      childrenObj[key] = {
        ...childrenObj[key],
        value: item.textContent
      }
      children.push(childrenObj[key])
    }
    /*     children.push({ title, key, value: item.textContent }) */
  }
  return children;
}

export {
  parseXmlFile,
  parseWireBondProFile
}