import apiProcess from '../../api/utility';
import {
  uploadCascadeLibrary,
  getCascadeLibraryList,
  saveCascadeLibraryData,
  getCascadeLibraryFileInfo,
  getCascadeLibraryDataInfo,
  updateCascadeLibraryData,
  deleteCascadeLibrary,
  getDefaultLibrary,
  updateDefaultLibrary,
  getLibrarySetting,
  updateLibrarySetting,
  getPinMap,
  getPartDetail,
  deletePart,
  updatePart
} from '../../api/Cascade/library'
import {
  getSystemLibraryList,
  getSystemLibraryFile,
  getSystemGeneric,
  getCustomLibraryList,
  getCustomLibraryById,
  getCustomLibraryMatch,
  getCustomLibrarySearch,
  getFolderChildren
} from '../../api/v2/libraryCtrl';
import { getPMICPartPin } from '../../api/Design/design';

function createLibrary({ files, fileType, typeName, metadata, config, tags }) {
  return apiProcess(uploadCascadeLibrary, { files, fileType, typeName, metadata, config, tags })
}

function getLibraryList() {
  return apiProcess(getCascadeLibraryList)
}

function saveLibraryData(data) {
  return apiProcess(saveCascadeLibraryData, data);
}

function getLibraryFileInfo(libraryId) {
  return apiProcess(getCascadeLibraryFileInfo, libraryId, false, false, true);
}

function getLibraryDataInfo(libraryId) {
  return apiProcess(getCascadeLibraryDataInfo, libraryId)
}

function updateLibraryData({ data, config }) {
  return apiProcess(updateCascadeLibraryData, { data, config })
}

function deleteLibrary(libraryId, partName) {
  return apiProcess(deleteCascadeLibrary, { libraryId, partName })
}

function getSystemLibrary(folderId) {
  return apiProcess(getSystemLibraryList, folderId)
}

function getSysLibraryFile(libraryId) {
  return apiProcess(getSystemLibraryFile, libraryId, false, false, true)
}

function getSysGeneric() {
  return apiProcess(getSystemGeneric)
}

function getLibSetting(projectId) {
  return apiProcess(getLibrarySetting, projectId)
}

function updateLibSetting({ librarySettings, projectId }) {
  return apiProcess(updateLibrarySetting, { librarySettings, projectId })
}

function getDefaultDecap() {
  return apiProcess(getDefaultLibrary)
}

function setDefaultDecap({ libraryId, libraryType, name, product }) {
  return apiProcess(updateDefaultLibrary, { libraryId, libraryType, name, product })
}

function getPMICPartName() {
  return apiProcess(getPMICPartPin)
}

function getCustomLibrary(folderId) {
  return apiProcess(getCustomLibraryList, folderId)
}

function getCustomLibraryByLibraryId(libraryId) {
  return apiProcess(getCustomLibraryById, libraryId, false, false, true)
}

function getCustomLibByMatch(partNumber) {
  return apiProcess(getCustomLibraryMatch, partNumber);
}

function getCustomLibBySearch(partNumber) {
  return apiProcess(getCustomLibrarySearch, partNumber);
}

function getLibraryFolderChildren({ id }) {
  return apiProcess(getFolderChildren, { id, productType: "cascade" });
}

function getCascadePinMap() {
  return apiProcess(getPinMap);
}

function getCascadePartDetail(id) {
  return apiProcess(getPartDetail, id)
}

function getCascadePartDelete(id) {
  return apiProcess(deletePart, id)
}

function updateCascadePart({ designId, id, partName, type, value }) {
  return apiProcess(updatePart, { designId, id, partName, type, value })
}

export {
  createLibrary,
  getLibraryList,
  saveLibraryData,
  getLibraryFileInfo,
  getLibraryDataInfo,
  updateLibraryData,
  deleteLibrary,
  getSystemLibrary,
  getSysLibraryFile,
  getSysGeneric,
  getLibSetting,
  updateLibSetting,
  getDefaultDecap,
  setDefaultDecap,
  getPMICPartName,
  getCustomLibrary,
  getCustomLibraryByLibraryId,
  getCustomLibByMatch,
  getCustomLibBySearch,
  getLibraryFolderChildren,
  getCascadePinMap,
  getCascadePartDetail,
  getCascadePartDelete,
  updateCascadePart
}