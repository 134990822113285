import React, { Component } from 'react';
import LayoutSingle from '@/components/ContentLayout/layoutSingle';
import TopBar from '../TopBar';
import { topBarResize } from '@/services/helper/topBarResize';
import { IO_SIM, SPICE_CARD_TEMPLATE } from '../../../constants/treeConstants';
import SpiceCardContent from '../SpiceCardTemplate';

class LayoutContent extends Component {
  topBarResize = () => {
    topBarResize('himalayas-top-bar', this.props.getTopBarWidth(this.props.viewList));
  }

  getContent = (height, view) => {
    //const { siderWidth } = this.props;
    switch (view) {
      case SPICE_CARD_TEMPLATE:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <SpiceCardContent height={height} siderWidth={this.props.siderWidth} />
      case IO_SIM:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return null/*  <IoSimContent /> */
      default: return null;
    }
  }

  topBar = () => {
    return <TopBar openMenu={this.props.openMenu} />
  }

  getClassName = (view) => {
    if ([SPICE_CARD_TEMPLATE, IO_SIM].includes(view)) {
      return "himalayas-setup-normal";
    }
    return "";
  }

  render() {
    const { viewList } = this.props;
    const view = viewList.length ? viewList[0] : null;
    return (
      <LayoutSingle
        {...this.props}
        id="himalayas-content-main"
        className={this.getClassName(view)}
        getContent={(height) => this.getContent(height, view)}
        topBar={this.topBar}
        topBarResize={this.topBarResize}
      />
    )
  }
}

export default LayoutContent;