import React, { Component, Fragment } from "react";
import { Select, Input } from 'antd';
import { numberCheck } from "../../../services/helper/dataProcess";
import UnitAddonAfter from "../../UnitAddonAfter";
import { PORT_REFERENCE_PIN } from "../../../services/ExtractionPortsHelper/portsSetup";
import { getPinsWithNetListAndComp } from '../../../services/PCBHelper';
import { getRefPinsByDistance } from '../../../services/ExtractionPortsHelper';
import './index.css';


const Option = Select.Option;
class ReferencePin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      distance: 10,
      distanceUnit: "mm",
      componentPinList: []
    }
  }

  addonAfter = () => {
    const unit = this.state.distanceUnit;
    return UnitAddonAfter({
      unit,
      changeUnit: (key) => this.changeUnit(key),
      list: ["mm", "mil"]
    });
  }

  changeUnit = (unit) => {
    this.setState({
      distanceUnit: unit
    }, () => {
      this.getComponentPinListByDistance()
    })
  }

  componentDidMount = () => {
    const { reference: { component } } = this.props;
    this.getComponentPinList(component);
  }

  getComponentPinList = (component) => {
    const { referenceNets, pcbInfo, componentPinInfo } = this.props;
    const { distance, distanceUnit } = this.state;
    const allCompRefPins = getPinsWithNetListAndComp({
      netList: referenceNets,
      pcbNetsList: pcbInfo.netsList,
      layers: pcbInfo.layers,
      compName: component
    });
    const compPins = getRefPinsByDistance({
      componentPinInfo,
      distance,
      distanceUnit,
      allCompRefPins
    })
    this.setState({
      allCompRefPins,
      componentPinList: compPins.filter(item => referenceNets.includes(item.net))
    })
  }

  getComponentPinListByDistance = () => {
    const { referenceNets, componentPinInfo } = this.props;
    const { distance, distanceUnit, allCompRefPins } = this.state;
    const compPins = getRefPinsByDistance({
      componentPinInfo,
      distance,
      distanceUnit,
      allCompRefPins
    })
    this.setState({
      componentPinList: compPins.filter(item => referenceNets.includes(item.net))
    })
  }

  updateValue = (e) => {
    this.setState({
      distance: e.target.value
    });
    const { errorMsg } = this.props;
    if (errorMsg && errorMsg.type === "distance") {
      this.props.updateError(null);
    }
  }

  valueOnBlur = (e, type) => {
    const value = e.target.value;
    const error = numberCheck(value);
    if (error) {
      this.props.updateError({ type, error: `Search Distance ${error}` });
    } else {
      this.getComponentPinListByDistance()
    }
  }

  changeComponent = (key) => {
    this.props.changeReference(PORT_REFERENCE_PIN, { component: key, pin: "" });
    this.getComponentPinList(key);
  }

  changePin = (key) => {
    const { reference } = this.props;
    this.props.changeReference(PORT_REFERENCE_PIN, { component: reference.component, pin: key });
  }

  render() {
    const { distance, componentPinList } = this.state;
    const { reference, pcbComponentList = [] } = this.props;
    const { component, pin } = reference;
    return <Fragment>
      {this.getSelectComponent({
        label: "Reference Component​",
        value: component,
        changeFn: this.changeComponent,
        options: pcbComponentList,
        type: "component"
      })}
      {this.getSelectComponent({
        label: "Reference Pin",
        value: pin,
        type: "pin",
        changeFn: this.changePin,
        options: componentPinList || []
      })}
      <div className="signal-port-edit-item">
        <label>Search distance​</label>
        <Input
          className='aurora-input'
          value={distance}
          onChange={(e) => this.updateValue(e)}
          onBlur={(e) => this.valueOnBlur(e)}
          addonAfter={this.addonAfter()}
        />
      </div>
    </Fragment>
  }


  getSelectComponent = ({
    label,
    value,
    changeFn,
    options,
    type
  }) => {
    return <div className='signal-port-edit-item'>
      <label>{label}</label>
      <Select
        className='aurora-select'
        value={value}
        showSearch
        onChange={(key) => changeFn(key)}
        popupMatchSelectWidth={false}
        popupClassName='aurora-select-dropdown'
      >
        {options.map(item => {
          if (type === "component") {
            return <Option key={item.name} title={item.name} > {item.name}</Option>
          }
          if (type === "pin") {
            return <Option key={item.pin} title={item.pin} > {item.pin}</Option>
          }
          return null;
        })}
      </Select>
    </div >
  }
}

export default ReferencePin;