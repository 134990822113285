import { getNetsListByComps, getComponentsByNets, _getNetsListByComp, getNetPinList } from './componentData';
import LayoutData from '../../data/LayoutData';
import GCPolygon from '../../geometry/GCPolygon';
import CeCircle from '../../geometry/CeCircle';
import { timeFormat } from '../../helper/timeFormat';
import { componentFilter } from '../../helper/componentsHelper';
import { filterComps } from '../../Rocky';
import { getComponentPinLocation } from '../../ExtractionPortsHelper';
import { IND, RES } from '../../PCBHelper';

let allCompsInfo = {};
const maxLevel = 3;

function getVRMs({
  VRM,
  Components,
  ReferenceNets,
  PowerNets,
  findVRMComps,
  findCaps,
  defaultVRM,
  pcbId,
  pcbInfo,
  COMP_PREFIX_LIB,
  findExtend = true,
  getLayoutComponents,
  getPowerComponentsByNets,
  getReferenceComps,
  chipList }) {
  // components: findVRMComps, filter nets: [...ReferenceNets, ...PowerNets]
  let DEBUG_MONITOR = [],
    _VRM = [...VRM],
    _PowerNets = [...PowerNets],
    _Components = JSON.parse(JSON.stringify(Components));
  let vrms = null, _findCaps = [...findCaps];

  const _compNames = _Components.map(item => item.name);
  // List{Res, Ind}
  const _findVRMComps = findVRMComps.filter(comp => _compNames.indexOf(comp.name) > -1);
  // FastPI - Chip, Rocky - Controller, Memory
  const chipTypes = ['Chip', 'Controller', 'Memory'];
  const _chips = chipList ? chipList : _Components.filter(it => chipTypes.includes(it.usage)).map(d => d.name);
  let updateComponents = [...Components];
  try {
    if (_PowerNets.length > 0) {
      const findVRMInfoObj = autoFindVRM({
        beginComps: _findVRMComps,
        filterNets: [...ReferenceNets, ..._PowerNets],
        pcbId,
        COMP_PREFIX_LIB,
        PowerNets: _PowerNets,
        ReferenceNets,
        getLayoutComponents,
        pcbInfo,
        chips: _chips,
        level: 1
      });
      DEBUG_MONITOR = findVRMInfoObj.DEBUG_MONITOR;
      const extendedNets = findVRMInfoObj.extendedNets;

      let eqPowerPin = findVRMInfoObj.eqPowerPin;
      // find caps only conntect reference nets
      // 2021/10/29 Allow all the capacitors that are connected to the reference net of the power domain (November's release)
      const { findCaps } = getPowerComponentsByNets({ ReferenceNets, PowerNets: [], pcbInfo, pcbId, COMP_PREFIX_LIB });

      _findCaps = [...findCaps]
      if (pcbInfo) {
        _findCaps = _findCaps.filter(comp => _compNames.indexOf(comp.name) > -1);
      }
      //first: first find vrm (FastPI) or first open pdn (Rocky)
      if (extendedNets.length > 0) {
        DEBUG_MONITOR.push(`==> Find extended nets: ${extendedNets.join(', ')}`);
        DEBUG_MONITOR.push(`==> Add ${extendedNets.join(', ')} ${extendedNets.length === 1 ? 'net' : 'nets'} to the Power Nets`);
        if (findExtend) {
          _PowerNets = [...new Set([..._PowerNets, ...extendedNets])];
        }

        const { Components, findVRMComps } = getPowerComponentsByNets({
          ReferenceNets,
          PowerNets: _PowerNets,
          pcbId,
          COMP_PREFIX_LIB,
          pcbInfo
        });
        let newPowerComponents = componentFilter({ Components, PowerNets: _PowerNets, ReferenceNets });
        // Update Components - Copy the usage/model/value of components
        updateComponents = newPowerComponents.map(d => {
          const findInComponents = _Components.find(c => c.name === d.name);
          if (findInComponents) {
            // Unused or Removed Component
            if (['Unused', 'Removed'].includes(findInComponents.usage)) {
              d.usage = findInComponents.usage;
            }
            if (findInComponents.model !== undefined) {
              d.model = findInComponents.model;
            };
            if (findInComponents.value !== undefined) {
              d.value = findInComponents.value;
            };
            if (findInComponents.pkg !== undefined) {
              d.pkg = findInComponents.pkg;
            };
            if (findInComponents.die !== undefined) {
              d.die = findInComponents.die;
            }
            if (findInComponents.models !== undefined) {
              d.models = findInComponents.models;
            };
          } else {
            const findValueInSamePart = _Components.find(comp => comp.part === d.part);
            if (findValueInSamePart) {
              if (d.value !== undefined && findValueInSamePart.value !== undefined) {
                d.value = findValueInSamePart.value;
              };
              if (d.model !== undefined && findValueInSamePart.model !== undefined) {
                d.model = findValueInSamePart.model;
              };
              if (d.models !== undefined && findValueInSamePart.models !== undefined) {
                d.models = findValueInSamePart.models;
              };
            }
          };
          return d;
        });
        const _filterComps = filterComps(findVRMComps, findCaps, newPowerComponents);
        //find extended nets vrm
        if (findExtend) {
          const __compNames = updateComponents.map(item => item.name);

          const __findVRMComps = _filterComps._findVRMComps.filter(comp => __compNames.indexOf(comp.name) > -1);
          const refindVRMInfoObj = autoFindVRM({
            beginComps: __findVRMComps,
            filterNets: [...ReferenceNets, ..._PowerNets],
            pcbId,
            COMP_PREFIX_LIB,
            PowerNets: _PowerNets,
            ReferenceNets,
            getLayoutComponents,
            pcbInfo,
            chips: _chips,
            level: 1
          });
          // update eq power pin
          eqPowerPin = refindVRMInfoObj.eqPowerPin;
          // Update find caps
          _findCaps = _filterComps._findCaps
          if (pcbInfo) {
            _findCaps = _filterComps._findCaps.filter(comp => __compNames.indexOf(comp.name) > -1);
          }
          // Update debug monitor
          DEBUG_MONITOR = [...DEBUG_MONITOR, ...refindVRMInfoObj.DEBUG_MONITOR];
        }
      };
      if (_findCaps.length > 0 && eqPowerPin.length > 0) {
        const obj = autoFindVRMGnd({
          findCaps: _findCaps,
          eqPowerPin,
          pcbId,
          DEBUG_MONITOR,
          pcbInfo,
          getLayoutComponents,
          ReferenceNets,
          PowerNets: _PowerNets
        });
        DEBUG_MONITOR = obj.DEBUG_MONITOR;
        vrms = obj.comps;
      } else {
        // No eq ground pin
        vrms = eqPowerPin.map(item => ({ pwr: item.name, gnd: '', vrmComp: item.vrm }));
      }
    };

    if (Array.isArray(_VRM)) {
      _VRM = [{
        groundPin: [],
        model: { name: defaultVRM ? defaultVRM.name : '', id: defaultVRM ? defaultVRM.libraryId : '' },
        powerPin: [],
        voltage: ""
      }];
      if (vrms && vrms.length > 0) {
        _VRM = [];
        for (const vrm of vrms) {
          const { pwr, gnd, vrmComp } = vrm;
          let _gndPins = [], _pwrPins = [];
          if (gnd) {
            let gndCompInfo;
            if (pcbInfo) {
              gndCompInfo = updateComponents.find(comp => comp.name === gnd);
            } else {
              const { referenceComps } = getReferenceComps({ ReferenceNets, pcbId, COMP_PREFIX_LIB, VRM_COMPS: [gnd], findCaps, pcbInfo })
              gndCompInfo = referenceComps.find(comp => comp.name === gnd)
            }
            if (gndCompInfo) {
              const gndPins = gndCompInfo.pins.filter(pin => ReferenceNets.indexOf(pin.net) > -1).map(item => item.pin);
              _gndPins.push({ comp: gnd, pins: gndPins });
            };
          }
          if (pwr) {
            const pwrCompInfo = updateComponents.find(comp => comp.name === pwr);
            //filter pins by powerNets
            //findExtended === false : _PowerNets only includes main power nets
            //findExtended === true : _PowerNets includes main power nets and extended nets
            if (pwrCompInfo) {
              const pwrPins = pwrCompInfo.pins.filter(item => item.pin && _PowerNets.includes(item.net)).map(item => item.pin);
              _pwrPins.push({ comp: pwr, pins: pwrPins });
            };
          }
          let _model = { name: "", id: "" };
          if (defaultVRM) {
            _model.name = defaultVRM.name;
            _model.id = defaultVRM.libraryId;
          }
          _VRM.push({
            groundPin: _gndPins,
            model: _model,
            powerPin: _pwrPins,
            voltage: "1",
            VRM_COMP: vrmComp
          })
        }
      }
    };
  } catch (error) {
    console.error(error);
  };
  return {
    DEBUG_MONITOR,
    VRM: _VRM,
    PowerNets: _PowerNets,
    Components: updateComponents,
    vrms
  }
};

function getLayoutNetManager(pcbId) {
  const layout = LayoutData.getLayout(pcbId);
  if (layout && layout.mNetManager) {
    return layout.mNetManager.mNetList;
  } else {
    return [];
  }
}

function getLayoutPartManager(pcbId) {
  const layout = LayoutData.getLayout(pcbId);
  if (layout) {
    return layout.mPartMgr;
  } else {
    return null;
  }
}
/**
 * {1}. {Chip}-{Res}-{Res}-{Ind}-{VRM}
 * {2}. {Chip}-{Res}-{Ind}-{VRM}
 * {3}. {Chip}-{Ind}-{VRM}
 *  
 *                  {Chip}
 *                    |PWR
 *          {Res}-----------{Ind}     Begin Comp
 *            |               |       Nets  (a)
 *    {Res}------{Ind}      {VRM}     Comps (a)
 *      |          |                  Nets  (b)
 *    {Ind}      {VRM}                Comps (b)
 *      |                             Nets  (c)
 *    {VRM} (pininfo: XW,IN)          Comps (c)      
 *
 * @export
 * @param {Array} comps Res|Ind
 * @param {Array} filterNets PWR, GND
 * @returns
 */
const SpaceFour = '\xa0\xa0\xa0\xa0';
function autoFindVRM({
  beginComps, // Res, Ind
  pcbId,
  COMP_PREFIX_LIB,
  PowerNets,
  ReferenceNets,
  pcbInfo,
  getLayoutComponents,
  chips,
  level }) {
  const { layers } = pcbInfo ? pcbInfo : {};
  const NET_LIST = getLayoutNetManager(pcbId);
  const PART_INFO = getLayoutPartManager(pcbId);
  // default filterNets - [ReferenceNets, PowerNets]
  let filterNets = [...ReferenceNets, ...PowerNets];
  // net(a) - { compName: [nets] }
  let VRMComps = [], VRMCompsName = [], debugMonitor = [], findExtendedNets = [];
  debugMonitor.push(`${timeFormat()} ==> Tracing VRM component for \n${SpaceFour}${PowerNets.join(', ')}\n`);
  // -------  [filterNets](power,gnd)
  // Res Ind  [beginComps]
  //  |   |   [connectNets] (netsA)
  const connectNets = getNetsListByComps(beginComps, filterNets, pcbId); // { beginComp:[connectNets]}
  // beginComps
  const filterComps = Object.keys(connectNets);
  if (filterComps.length > 0) {
    for (let compName of filterComps) {
      const beginComp = beginComps.find(item => item.name === compName);
      const compType = beginComp.usage;
      const netsA = connectNets[compName]; // Filtered
      if (!netsA) {
        continue;
      }
      // Filter reference nets like GND net, [...new Set()]: Fix power net name repeated bug
      const BeginNetsName = [...new Set(beginComp.pins.map(pin => pin.net).filter(it => !ReferenceNets.includes(it)))];
      // DEBUG INFO
      const beginConnInfo = `\t[Net] ${BeginNetsName.join(', ')} -> [Dev] ${compName} ->\n`;
      const connectsCompNetInfo = BeginNetsName.map(net => ({ net: net, comp: compName }));
      const componentsA = getComponentsByNets({ netList: netsA, pcbId, COMP_PREFIX_LIB, getLayoutComponents, layers });
      const netsAInfo = netsA.map(net => NET_LIST.getValue(net)); // Filtered
      if (compType === 'Ind') { // begin component type
        // {3}
        indCheck({
          comps: componentsA,
          netsInfo: netsAInfo,
          VRMCompsName,
          VRMComps,
          compName,
          debugMonitor,
          prevConnInfo: beginConnInfo,
          connectsCompNetInfo: connectsCompNetInfo,
          PART_INFO,
          findExtendedNets,
          eqPowerPinComp: beginComp,
          pcbId,
          chips,
          level: 1
        });
      } else if (compType === 'Res') { // begin component type
        loopFindVRMInd({
          pcbId,
          pcbInfo,
          COMP_PREFIX_LIB,
          components: componentsA,
          compName,
          prevComp: compName,
          getLayoutComponents,
          filterNets,
          level: level + 1,
          prevConnInfo: beginConnInfo,
          prevNets: netsA,
          connectsCompNetInfo,
          chips,
          VRMComps,
          VRMCompsName,
          debugMonitor,
          findExtendedNets
        })
      }
    };
  };
  // Remove power nets, filterNets - power nets
  const _level = VRMComps.reduce((prev, curr) => {
    return curr.level < prev ? curr.level : prev;
  }, Infinity)
  const _vrmComps = VRMComps.filter(d => d.level === _level);
  const filterPowerNets = findExtendedNets.filter(item => item.level === _level && !filterNets.includes(item.net)).map(d => d.net);
  const _extendedNets = [...new Set(filterPowerNets)];
  return { eqPowerPin: _vrmComps, DEBUG_MONITOR: debugMonitor, extendedNets: _extendedNets };
}

function loopFindVRMInd({
  pcbId,
  pcbInfo,
  COMP_PREFIX_LIB,
  components,
  prevComp,
  compName,
  getLayoutComponents,
  filterNets,
  level,
  prevConnInfo,
  prevNets,
  connectsCompNetInfo,
  chips,
  VRMComps,
  VRMCompsName,
  debugMonitor,
  findExtendedNets
}) {

  if (level > maxLevel) {
    return;
  }

  const { layers } = pcbInfo ? pcbInfo : {};
  const NET_LIST = getLayoutNetManager(pcbId);
  const PART_INFO = getLayoutPartManager(pcbId);
  let _prevConnInfo = prevConnInfo;
  // Filter Res and Ind
  // Ind level 2
  const _compsAInd = components.filter(comp => comp.name !== prevComp && comp.name !== compName && comp.type === 'Ind');
  for (let _compA of _compsAInd) {
    const netsB = _getNetsListByComp(_compA.name, [...filterNets, ...prevNets], pcbId);  // Filtered
    const componentsB = getComponentsByNets({ netList: netsB, pcbId, COMP_PREFIX_LIB, layers, getLayoutComponents });
    const netsBInfo = netsB.map(net => NET_LIST.getValue(net));

    // DEBUG INFO
    const connAInfo = `\t[Net] ${_compA.net} -> [Dev] ${_compA.name} ->\n`;

    if (!netsB) {
      continue;
    };
    // {2}
    _prevConnInfo = _prevConnInfo + connAInfo;
    const __connectsCompNetInfo = [...connectsCompNetInfo, { net: _compA.net, comp: _compA.name }]
    indCheck({
      comps: componentsB,
      netsInfo: netsBInfo,
      VRMCompsName,
      VRMComps,
      compName,
      debugMonitor,
      PART_INFO,
      prevConnInfo: _prevConnInfo,
      connectsCompNetInfo: __connectsCompNetInfo,
      findExtendedNets,
      eqPowerPinComp: _compA,
      pcbId,
      chips,
      level: level
    });
  };

  // Res level 3
  const _compsARes = components.filter(comp => comp.name !== prevComp && comp.name !== compName && comp.type === 'Res');
  for (let _compA of _compsARes) {
    const netsB = _getNetsListByComp(_compA.name, [...filterNets, ...prevNets], pcbId);  // Filtered
    const componentsB = getComponentsByNets({ netList: netsB, pcbId, COMP_PREFIX_LIB, layers, getLayoutComponents });

    // DEBUG INFO
    const connAInfo = `\t[Net] ${_compA.net} -> [Dev] ${_compA.name} ->\n`;
    const __connectsCompNetInfo = [...connectsCompNetInfo, { net: _compA.net, comp: _compA.name }]

    if (!netsB) {
      continue;
    };

    loopFindVRMInd({
      pcbId,
      pcbInfo,
      COMP_PREFIX_LIB,
      components: componentsB,
      prevComp: _compA.name,
      getLayoutComponents,
      filterNets,
      level: level + 1,
      prevConnInfo: _prevConnInfo + connAInfo,
      prevNets: netsB,
      connectsCompNetInfo: __connectsCompNetInfo,
      chips,
      VRMComps,
      VRMCompsName,
      debugMonitor,
      findExtendedNets,
      compName
    })
  };
}

function autoFindVRMGnd({
  findCaps,
  eqPowerPin,
  pcbId,
  DEBUG_MONITOR,
  pcbInfo,
  getLayoutComponents,
  ReferenceNets,
  PowerNets
}) {
  const { layers } = pcbInfo ? pcbInfo : {};
  if (!allCompsInfo[pcbId]) {
    allCompsInfo[pcbId] = getLayoutComponents({ pcbId, layers }); // { Comp: { name, part, value, type} }
  };

  let CompsInfo = allCompsInfo[pcbId];

  DEBUG_MONITOR.push('\t==> Automatically find Eq. Gnd Pin.\n');
  let comps = eqPowerPin.map(comp => ({ pwr: comp.name, gnd: null, vrmComp: comp.vrm }));
  for (let item of comps) {
    //get pins location
    const db = LayoutData.getLayout(pcbId);
    let pwrLocation = getComponentPinLocation({
      scaling: 1,
      component: db.getComponent(item.pwr),
      compName: item.pwr,
      mPartMgr: db.mPartMgr,
    })
    //Find the pins of the component of the Eq.Pwr Pin connected to the Power nets
    const netPinList = getNetPinList(PowerNets, pcbId, item.pwr);
    pwrLocation = pwrLocation.filter(item => netPinList.find(it => it.mPinNum === item.number))

    let vrmComponents = [];
    if (Array.isArray(item.vrmComp)) {
      item.vrmComp.forEach(it => {
        vrmComponents.push(CompsInfo[it])
      })
    } else {
      vrmComponents = CompsInfo[item.vrmComp]
    }
    const referenceComps = findCaps.concat(vrmComponents)
    const layer = CompsInfo[item.pwr].layer;
    //find Eq.Gnd pin of same layer
    item.gnd = getGndPinByLocationAndLayer({
      referenceComps,
      layer,
      CompsInfo,
      pwrLocation,
      isSameLayer: true,
      ReferenceNets,
      pcbId
    });
    if (!item.gnd) {
      item.gnd = getGndPinByLocationAndLayer({
        referenceComps,
        layer,
        CompsInfo,
        pwrLocation,
        isSameLayer: false,
        ReferenceNets,
        pcbId
      })
    }

    DEBUG_MONITOR.push(`\t${SpaceFour}Eq. Pwr Pin: ${item.pwr}, Eq. Gnd Pin: ${item.gnd}.\n`);
  };
  DEBUG_MONITOR.push(`\n`);
  return { comps, DEBUG_MONITOR: DEBUG_MONITOR }
};

function getGndPinByLocationAndLayer({
  referenceComps,
  layer,
  CompsInfo,
  pwrLocation,
  isSameLayer = false,
  ReferenceNets,
  pcbId
}) {
  let gnd = null;
  let minLocation = Infinity, minXLength = Infinity, minYLength = Infinity, length = Infinity;
  let minNameList = [];
  for (let comp of referenceComps) {
    // Each Cap,Res in one VRM needs to be on the same layer
    // 2021/05/08 - Relax the constraint that VRM port capacitor needs to be at the same layer as the inductor.
    // 2022/09/09 - Fix LCFC issues, Prefer pwr pin and gnd pin on the same layer
    if (isSameLayer && layer !== CompsInfo[comp.name].layer) {
      continue;
    }

    //get pins location
    const db = LayoutData.getLayout(pcbId);
    let pinLocationList = getComponentPinLocation({
      scaling: 1,
      component: db.getComponent(comp.name),
      compName: comp.name,
      mPartMgr: db.mPartMgr,
    })

    //Find the pins of the component of the Eq.Gnd Pin connected to the Reference nets
    if (comp.usage !== "Cap") {
      const netPinList = getNetPinList(ReferenceNets, pcbId, comp.name);
      if (!netPinList.length) {
        continue;
      }
      pinLocationList = pinLocationList.filter(item => netPinList.find(it => it.mPinNum === item.number))
    } else {
      pinLocationList = pinLocationList.filter(item => comp.pins && comp.pins.find(it => it.pin === item.number))
    }

    for (let pin of pinLocationList) {
      for (let pwrPin of pwrLocation) {
        const _XLength = Math.abs(pwrPin.x - pin.x), _YLength = Math.abs(pwrPin.y - pin.y);
        if (_YLength > minYLength && _XLength > minXLength) {
          continue;
        } else {
          length = _XLength * _XLength + _YLength * _YLength;
          if (length < minLocation) {
            minNameList = []
            minNameList.push(comp.name)
            minXLength = _XLength;
            minYLength = _YLength;
            minLocation = length;
            gnd = comp.name;
          } else if (length === minLocation) {
            minNameList.push(comp.name)
          } else {
            continue
          }
        }
      }
    }
  }
  minNameList.forEach(it => {
    if (layer === CompsInfo[it].layer) {
      gnd = it;
    }
  })
  return gnd;
}

function findVRM(options) {

  // Determine if the pinName of components matches
  // vrm - comp { name, net, type, part, pinList }
  let VRMs = [];
  //filter VRMs by pin name length
  // VRMs : pin name length >= 6 and pin name length <= 200
  for (let vrm of options.isVRMs) {
    // pin name
    const pinLength = vrm.pinList ? vrm.pinList.length : 0;
    //filter vrm by pin name length
    if (pinLength >= 5 && pinLength <= 200) {
      VRMs.push(vrm);
    } else {
      continue;
    }
  }

  //Find VRM by VRMs with pin name length >= 5 && <= 24
  _findVRM({ ...options, VRMs });
}

// VRM component identification
// VRM_FOUND = A && D && (B || (CI || CII))  [&& is logic AND, || is logic or]
// A. Number of pins between 6 ~ 24 (include 6,24)  √
// B. The component has pin names “SW”, “VIN”   √
// C. The connection between the inductor net and the potential VRM is either  
//      i) through multiple pins  √
//      ii) or a “Multi-finger” pin 
// D. The distance between Begin Comp and VRM is less than 10 * VRM width and height [Res] - [VRM] < 10 * [\]  √
function _findVRM({
  VRMs,
  VRMCompsName,
  VRMComps,
  compName,
  debugMonitor,
  prevConnInfo,
  netsInfo,
  PART_INFO,
  connectsCompNetInfo,
  findExtendedNets,
  eqPowerPinComp,
  pcbId,
  level
}) {
  for (let vrm of VRMs) {
    // pin name
    const pinNames = vrm.pinList.map(item => item.mName);
    // Rule A
    if (pinNames.length < 5) {
      continue;
    }

    const findNetInfo = netsInfo.find(it => it.mName === vrm.net);
    let compConnectNetPins = [];
    if (findNetInfo) {
      // Rule C I  - multiple pins
      compConnectNetPins = findNetInfo.mPinList.filter(item => item.mCompName === vrm.name).map(item => item.mPinNum);
    };
    if (!compConnectNetPins.length) { continue; }

    // Rule D - notReasonableDistance(PART_INFO, eqPowerPinComp, vrm)
    if (notReasonableDistance(PART_INFO, eqPowerPinComp, vrm)) {
      continue;
    }
    // Rule B
    const ruleB = pinNames.some(name => matchPinName(name, VRM_CHARACTERISTIC));
    // Rule C
    let ruleCI = false, ruleCII = false;
    if (compConnectNetPins.length >= 2) {
      ruleCI = true;
    } else if (compConnectNetPins.length === 1) {
      // There are multiple pins connected to the same net
      const checkNets = _getNetsListByComp(vrm.name, [], pcbId);
      const setCheckNets = [...new Set(checkNets)];
      if (checkNets.length !== setCheckNets.length) {
        ruleCII = true;
      };
      // TODO Multi-finger
      // Rule C - II
      // partd.db 
      // FootPrintSymbol { MetalLayer { PartPad {
      // 	      PadIDs 7 1  // PDAID - TempateID } } }
      // const tempateID = getTempateIDByPadID(compConnectNetPins[0], footPrintSymbol).toString();
      // const footPrintGeo = footPrintSymbol.GetPadTemplate(tempateID);
    };
    if (ruleB || ruleCII || ruleCI) {
      // One net may connect multiple VRMs, remove filter - if (VRMCompsName.includes(compName)) { continue; }
      // [Inductor] - net ———— VRM1
      //                   └── VRM2
      // VRMCompsName  - eq pwr components

      //  Only one port for one inductor, list both VRM controllers(VRM1,VRM2) in one same cell
      if (VRMCompsName.includes(compName)) {
        const VRMCompIndex = VRMComps.findIndex(eqPerPin => eqPerPin.name === compName);
        let vrmArr = [];
        if (typeof VRMComps[VRMCompIndex].vrm === "string") {
          vrmArr.push(VRMComps[VRMCompIndex].vrm);
        } else if (Array.isArray(VRMComps[VRMCompIndex].vrm)) {
          vrmArr = [...VRMComps[VRMCompIndex].vrm];
        };
        vrmArr.push(vrm.name);
        const new_vrmArr = new Set(vrmArr)
        VRMComps[VRMCompIndex].vrm = [...new_vrmArr];
        const _log = `${SpaceFour}* VRM${VRMCompsName.length} - ${vrm.name}\n${prevConnInfo}\t[Net] ${vrm.net} -> [Dev] ${vrm.name} \n`;
        debugMonitor.push(_log);
        // extendedNets = extendedNets + power nets, There will be duplicate nets
        const extendedNets = connectsCompNetInfo.map(item => ({ net: item.net, level }));
        findExtendedNets.push(...extendedNets);
        continue;
      };
      VRMCompsName.push(compName);
      VRMComps.push({ name: compName, vrm: vrm.name, level });
      const _log = `${SpaceFour}* VRM${VRMCompsName.length} - ${vrm.name}\n${prevConnInfo}\t[Net] ${vrm.net} -> [Dev] ${vrm.name} \n`;
      debugMonitor.push(_log);

      // extendedNets = extendedNets + power nets, There will be duplicate nets
      const extendedNets = connectsCompNetInfo.map(item => ({ net: item.net, level }));
      findExtendedNets.push(...extendedNets);
    } else {
      const log = `${SpaceFour}* Unrecognized VRM device - ${vrm.name}\n${prevConnInfo}\t[Net] ${vrm.net} -> [Dev] ${vrm.name} \n`;
      debugMonitor.push(log);
      // TODO
      // Can't judge according to pin number or multi-finger, Save to array, User can choose a VRM component
    }
  }
  return VRMComps;
}

/**
 * Not reasonable distance: distance between begin component (R,L) to VRM component larger than 10 * size of VRM component
 *
 * @param {*} PART_INFO
 * @param {*} eqPowerPinComp
 * @param {*} vrm
 * @returns
 */
function notReasonableDistance(PART_INFO, eqPowerPinComp, vrm) {
  // CePart { mInfo: PartInfo, mPinList: Array, mSymbolGrps: Array, mFutprtList: StringList }
  const part = PART_INFO.GetPart(vrm.part);
  // partd.db Part { FootPrintSymbols footPrintSymbolID }
  //          FootPrintSymbol { SymbolID footPrintSymbolID }
  const footPrintSymbolID = part.GetUsedFootprints().get(0);
  // FootPrintSymbol { mID: "String", mOutline: null, mMetalLayerList: Array, mPadTemplates: CeLinkedMap }
  const footPrintSymbol = PART_INFO.GetFootPrintSymbol(footPrintSymbolID);
  // Calculate the approximate size of the component, using the size of the diagonal as the size of the component
  const { padXArr, padYArr } = getPadLocationList(footPrintSymbol);
  const x_PadMin = Math.min(...padXArr),
    x_PadMax = Math.max(...padXArr),
    y_PadMin = Math.min(...padYArr),
    y_PadMax = Math.max(...padYArr);
  // Get footprint geometry location
  var partPins = footPrintSymbol ? footPrintSymbol.GetPadGeometries() : [];
  const { xMax, yMax } = getFootprintGeoLocation(partPins);
  const xVRM = Math.abs(x_PadMax - x_PadMin) + 2 * xMax,
    yVRM = Math.abs(y_PadMax - y_PadMin) + 2 * yMax;
  const VRMSize = xVRM * xVRM + yVRM * yVRM;
  // Calculate Component END
  const beginCompLocation = eqPowerPinComp.location;
  const vrmCompLocation = vrm.location;

  const reduceX = beginCompLocation.mX - vrmCompLocation.mX;
  const reduceY = beginCompLocation.mY - vrmCompLocation.mY;
  // distance between begin component (R,L) to VRM component;
  const distanceBtBeginToVRM = reduceX * reduceX + reduceY * reduceY;
  return distanceBtBeginToVRM > VRMSize * 10;
};

// -net-comps
// comps, VRMCompsName, VRMComps, compName, debugMonitor, prevConnInfo, netsInfo, PART_INFO, eqPowerPinComp, chips
function indCheck(options) {
  // filter chip components and find ignore components
  const isVRMs = ignoreFilter(options.comps, options.chips);
  // Determine if the pinName of components matches
  findVRM({ isVRMs, ...options });
}

function ignoreFilter(comps, chips) {
  return comps.filter(item => item.type === 'Ignore' && !chips.includes(item.name));
}

//characteristic
const VRM_CHARACTERISTIC = ['SW', 'VIN']
function matchPinName(matchStr, KEYARR) {
  try {
    if (KEYARR === 'undefined' || !(KEYARR instanceof Array) || KEYARR.length < 1) return false;
    const arrTxt = KEYARR.join('|'),
      regObj = new RegExp(arrTxt, 'ig');
    if (!regObj || !matchStr) {
      return false;
    }
    const _match = matchStr.match(regObj);
    if (_match && _match.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
}

function getPadLocationList(footPrintSymbol) {
  let padXArr = [], padYArr = [];
  if (footPrintSymbol) {
    for (const layerInfo of footPrintSymbol.mMetalLayerList) {
      for (const padInfo of layerInfo.mPadList) {
        padXArr.push(padInfo.mLocation.mPosition.mX);
        padYArr.push(padInfo.mLocation.mPosition.mY);
      }
    };
  }
  return { padXArr, padYArr };
}

function getFootprintGeoLocation(padGeometries) {
  let xMax = 0, yMax = 0;
  for (const geo of padGeometries) {
    if (geo instanceof GCPolygon) {
      xMax = Math.max((xMax, Math.max(...geo.mXs), Math.abs(Math.min(...geo.mXs))));
      yMax = Math.max((yMax, Math.max(...geo.mYs), Math.abs(Math.min(...geo.mYs))));
    } else if (geo instanceof CeCircle) {
      xMax = Math.max(xMax, geo.mCenter.mX);
      yMax = Math.max(yMax, geo.mCenter.mY);
    } else {
      // TODO - other geometry
      continue;
    }
  }
  xMax = Math.abs(xMax);
  yMax = Math.abs(yMax);
  return { xMax, yMax }
}

function getTraceByVRM({
  pcbId,
  defaultVRM,
  COMP_PREFIX_LIB,
  PowerNets,
  ReferenceNets,
  includeExtended,
  chipList,
  specVRM,
  _Components,
  getLayoutComponents,
  getPowerComponentsByNets,
  getReferenceComps }) {
  let paths = [], debugMonitor = [], findExtendedNets = [], filterNets = [...ReferenceNets], eqPowerPin = [];
  debugMonitor.push(`${timeFormat()} ==> Tracing VRM component for \n${SpaceFour}${PowerNets.join(', ')}\n`);
  const connectNets = getNetsListByComps(specVRM.map(item => ({ name: item })), filterNets, pcbId);
  const constParams = {
    pcbId,
    PowerNets,
    chipList,
    COMP_PREFIX_LIB,
    getLayoutComponents
  }
  let indChecks = [], vrmChecks = [], resCheck = false;
  for (let vrm of specVRM) {
    const nets = connectNets[vrm];
    if (!nets || !nets.length) {
      continue;
    }
    const _nets = [...new Set(nets)];
    for (let net of _nets) {
      const components = getComponentsByNets({ netList: [net], pcbId, COMP_PREFIX_LIB, getLayoutComponents });
      const ind = components.filter(item => item.type === IND && item.name !== vrm);
      if (ind.length) {
        for (let indComp of ind) {
          indChecks.push({
            prevComp: indComp,
            prevNet: net,
            filterNets,
            paths,
            vrm,
            path: [{ type: 'net', name: net }, { type: 'comp', name: indComp.name, compType: indComp.type }],
            level: 1,
            pcbId,
            constParams
          })
        }
        continue;
      }

      if (PowerNets.includes(net)) {
        vrmChecks.push({
          filterNets: [net],
          paths,
          vrm,
          level: 1,
          net: net,
          path: [{ type: 'net', name: net }],
          constParams
        })
        continue;
      }
    }
    if (!indChecks.length && !vrmChecks.length) {
      for (let net of _nets) {
        const components = getComponentsByNets({ netList: [net], pcbId, COMP_PREFIX_LIB, getLayoutComponents });
        const res = components.filter(item => item.type === RES && item.name !== vrm);
        for (let resComp of res) {
          indChecks.push({
            prevComp: resComp,
            prevNet: net,
            filterNets,
            paths,
            vrm,
            path: [{ type: 'net', name: net }, { type: 'comp', name: resComp.name, compType: resComp.type }],
            level: 1,
            constParams
          })
        }
      }
      resCheck = true
    }
  }

  if (!indChecks.length && vrmChecks.length) {
    debugMonitor.push(`${SpaceFour}Find VRM: ${vrmChecks.map(v => v.vrm).join(', ')}`);
    for (let vrmConst of vrmChecks) {
      chipCheck(vrmConst)
    }
  } else {
    debugMonitor.push(`${SpaceFour}Trace Components: ${indChecks.map(p => p.prevComp ? p.prevComp.name : '').join(', ')}`);
    loopTraceByVRM(indChecks, 1);
  }

  let resChecks = [];
  if (!resCheck && !paths.length) {
    for (let vrm of specVRM) {
      const nets = connectNets[vrm];
      if (!nets || !nets.length) {
        continue;
      }
      const _nets = [...new Set(nets)];
      for (let net of _nets) {
        const components = getComponentsByNets({ netList: [net], pcbId, COMP_PREFIX_LIB, getLayoutComponents });
        const res = components.filter(item => item.type === RES && item.name !== vrm);
        for (let resComp of res) {
          resChecks.push({
            prevComp: resComp,
            prevNet: net,
            filterNets,
            paths,
            vrm,
            path: [{ type: 'net', name: net }, { type: 'comp', name: resComp.name, compType: resComp.type }],
            level: 1,
            constParams
          })
        }
      }
    }
    debugMonitor.push(`${SpaceFour}Error: The above Components trace fails.`);
    debugMonitor.push(`${SpaceFour}Trace Components: ${indChecks.map(p => p.prevComp ? p.prevComp.name : '').join(', ')}`);
    foundPowerNets = []
    loopTraceByVRM(resChecks, 1);
  }

  for (let pathObj of paths) {
    const { path, vrm, level } = pathObj;
    if (path.length < 2) {
      continue;
    }
    let log = `${SpaceFour}* VRM - ${vrm}\n`;
    for (let i = 1; i < path.length; i = i + 2) {
      log = `${log}\t\t[Net] ${path[i].name} -> [Dev] ${path[i + 1].name} \n`
    }
    debugMonitor.push(log);
    let pwrPinIndex = path.findLastIndex(item => item.compType === IND);
    const _findExtendedNets = path.slice(1, pwrPinIndex).filter(item => item.type === 'net' && !PowerNets.includes(item.name)).map(item => item.name);
    findExtendedNets = [...new Set([...findExtendedNets, ..._findExtendedNets])];
    const eqName = !indChecks.length && vrmChecks.length ? vrm : !includeExtended && path[2] ? path[2].name : resChecks.length || pwrPinIndex < 0 ? vrm : path[pwrPinIndex].name;
    const newEq = { name: eqName, vrm: vrm, level }
    if (!eqPowerPin.find(item => item.name === newEq.name && item.vrm === newEq.vrm)) {
      eqPowerPin.push(newEq)
    }
  }
  foundPowerNets = []

  const vrmObj = VRMTraceHandle({
    DEBUG_MONITOR: debugMonitor,
    eqPowerPin,
    extendedNets: findExtendedNets,
    PowerNets,
    ReferenceNets,
    pcbInfo: null,
    pcbId,
    COMP_PREFIX_LIB,
    findExtend: includeExtended,
    _Components,
    defaultVRM
  }, {
    getPowerComponentsByNets,
    getLayoutComponents,
    getReferenceComps
  })

  return vrmObj
}

let traceBackMaxLevel = 10, foundPowerNets = [];
function loopTraceByVRM(checks, level) {
  let checkedNets = [], nextChecks = [];
  if (level > traceBackMaxLevel) {
    return;
  }
  for (let check of checks) {
    const { prevComp, prevNet, filterNets, paths, vrm, path, constParams } = check;
    const { pcbId, PowerNets, COMP_PREFIX_LIB, getLayoutComponents } = constParams;
    const _foundPowerNets = [...foundPowerNets];
    if (PowerNets.every(net => _foundPowerNets.includes(net))) {
      continue;
    }
    let _filterNets = JSON.parse(JSON.stringify([...filterNets, prevNet]));
    let _path = JSON.parse(JSON.stringify(path))
    const connectNets = getNetsListByComps([prevComp], _filterNets, pcbId);
    const nets = connectNets[prevComp.name] || [];

    if (!nets.length) {
      continue;
    }
    for (let net of nets) {
      if (foundPowerNets.includes(net)) {
        continue;
      }

      if (PowerNets.includes(net)) {
        checkedNets.push(net);
        chipCheck({
          filterNets,
          paths,
          vrm,
          level,
          net,
          path: [..._path, { type: 'net', name: net }],
          constParams
        });
        continue;
      }

      const components = getComponentsByNets({ netList: [net], pcbId, COMP_PREFIX_LIB, getLayoutComponents });
      const pathComp = _path.filter(item => [IND, RES].includes(item.compType)).map(item => item.name)
      const traceComponents = components.filter(item => item.name !== prevComp.name && !pathComp.includes(item.name) && [IND, RES].includes(item.type));
      for (let comp of traceComponents) {
        nextChecks.push({
          prevComp: comp,
          prevNet: net,
          filterNets: _filterNets,
          paths,
          vrm,
          path: [..._path, { type: 'net', name: net }, { type: 'comp', name: comp.name, compType: comp.type }],
          level: level + 1,
          constParams
        })
      }
    }
  }
  foundPowerNets = [...new Set([...foundPowerNets, ...checkedNets])];

  if (!nextChecks.length) {
    return;
  }
  loopTraceByVRM(nextChecks, level + 1)
}

function chipCheck(params) {
  const { paths, vrm, level, path, net, constParams } = params;
  const { pcbId, COMP_PREFIX_LIB, getLayoutComponents, chipList } = constParams;
  const components = getComponentsByNets({ netList: [net], pcbId, COMP_PREFIX_LIB, getLayoutComponents });
  const chips = chipList.filter(chip => components.find(item => item.name === chip));

  for (let chip of chips) {
    const _path = [{ type: 'comp', name: vrm, compType: 'vrm' }, ...path, { type: 'comp', name: chip, compType: 'chip' }].reverse()
    paths.push({
      path: _path,
      level,
      vrm: vrm
    })
  }
}

function VRMTraceHandle(params, fn) {
  const { PowerNets, ReferenceNets, pcbInfo, pcbId, COMP_PREFIX_LIB, findExtend, _Components, defaultVRM } = params;
  const { getPowerComponentsByNets, getLayoutComponents, getReferenceComps } = fn;
  let DEBUG_MONITOR = [], updateComponents = [], _findCaps = [], _PowerNets = [...PowerNets], vrms = [], _VRM = []
  if (PowerNets.length > 0) {
    DEBUG_MONITOR = params.DEBUG_MONITOR;
    const extendedNets = params.extendedNets || [];

    let eqPowerPin = params.eqPowerPin;
    const { Components } = getPowerComponentsByNets({
      ReferenceNets,
      PowerNets: _PowerNets,
      pcbId,
      COMP_PREFIX_LIB,
      pcbInfo
    });
    let newPowerComponents = componentFilter({ Components, PowerNets: _PowerNets, ReferenceNets });
    updateComponents = newPowerComponents.map(d => {
      const findInComponents = _Components.find(c => c.name === d.name);
      if (findInComponents) {
        // Unused or Removed Component
        if (['Unused', 'Removed'].includes(findInComponents.usage)) {
          d.usage = findInComponents.usage;
        }
        if (findInComponents.model !== undefined) {
          d.model = findInComponents.model;
        };
        if (findInComponents.value !== undefined) {
          d.value = findInComponents.value;
        };
        if (findInComponents.pkg !== undefined) {
          d.pkg = findInComponents.pkg;
        };
        if (findInComponents.die !== undefined) {
          d.die = findInComponents.die;
        }
        if (findInComponents.models !== undefined) {
          d.models = findInComponents.models;
        };
      } else {
        const findValueInSamePart = _Components.find(comp => comp.part === d.part);
        if (findValueInSamePart) {
          if (d.value !== undefined && findValueInSamePart.value !== undefined) {
            d.value = findValueInSamePart.value;
          };
          if (d.model !== undefined && findValueInSamePart.model !== undefined) {
            d.model = findValueInSamePart.model;
          };
          if (d.models !== undefined && findValueInSamePart.models !== undefined) {
            d.models = findValueInSamePart.models;
          };
        }
      };
      return d;
    });
    // find caps only conntect reference nets
    // 2021/10/29 Allow all the capacitors that are connected to the reference net of the power domain (November's release)
    const { findCaps } = getPowerComponentsByNets({
      ReferenceNets,
      PowerNets: findExtend ? [...new Set([..._PowerNets, ...extendedNets])] : [],
      pcbInfo,
      pcbId,
      COMP_PREFIX_LIB
    });
    _findCaps = [...findCaps];
    //first: first find vrm (FastPI) or first open pdn (Rocky)
    if (extendedNets.length > 0) {
      DEBUG_MONITOR.push(`==> Find extended nets: ${extendedNets.join(', ')}`);
      DEBUG_MONITOR.push(`==> Add ${extendedNets.join(', ')} ${extendedNets.length === 1 ? 'net' : 'nets'} to the Power Nets`);
      if (findExtend) {
        _PowerNets = [...new Set([..._PowerNets, ...extendedNets])];
      }

      const { Components } = getPowerComponentsByNets({
        ReferenceNets,
        PowerNets: _PowerNets,
        pcbId,
        COMP_PREFIX_LIB,
        pcbInfo
      });
      let newPowerComponents = componentFilter({ Components, PowerNets: _PowerNets, ReferenceNets });
      // Update Components - Copy the usage/model/value of components
      updateComponents = newPowerComponents.map(d => {
        const findInComponents = _Components.find(c => c.name === d.name);
        if (findInComponents) {
          // Unused or Removed Component
          if (['Unused', 'Removed'].includes(findInComponents.usage)) {
            d.usage = findInComponents.usage;
          }
          if (findInComponents.model !== undefined) {
            d.model = findInComponents.model;
          };
          if (findInComponents.value !== undefined) {
            d.value = findInComponents.value;
          };
          if (findInComponents.pkg !== undefined) {
            d.pkg = findInComponents.pkg;
          };
          if (findInComponents.die !== undefined) {
            d.die = findInComponents.die;
          }
          if (findInComponents.models !== undefined) {
            d.models = findInComponents.models;
          };
        } else {
          const findValueInSamePart = _Components.find(comp => comp.part === d.part);
          if (findValueInSamePart) {
            if (d.value !== undefined && findValueInSamePart.value !== undefined) {
              d.value = findValueInSamePart.value;
            };
            if (d.model !== undefined && findValueInSamePart.model !== undefined) {
              d.model = findValueInSamePart.model;
            };
            if (d.models !== undefined && findValueInSamePart.models !== undefined) {
              d.models = findValueInSamePart.models;
            };
          }
        };
        return d;
      });
    };
    if (_findCaps.length > 0 && eqPowerPin.length > 0) {
      const obj = autoFindVRMGnd({
        findCaps: _findCaps,
        eqPowerPin,
        pcbId,
        DEBUG_MONITOR,
        pcbInfo,
        getLayoutComponents,
        ReferenceNets,
        PowerNets: _PowerNets
      });
      DEBUG_MONITOR = obj.DEBUG_MONITOR;
      vrms = obj.comps
    }
  };

  if (Array.isArray(_VRM)) {
    _VRM = [{
      groundPin: [],
      model: { name: defaultVRM ? defaultVRM.name : '', id: defaultVRM ? defaultVRM.libraryId : '' },
      powerPin: [],
      voltage: ""
    }];
    if (vrms && vrms.length > 0) {
      _VRM = [];
      for (const vrm of vrms) {
        const { pwr, gnd, vrmComp } = vrm;
        let _gndPins = [], _pwrPins = [];
        if (gnd) {
          let gndCompInfo;
          if (pcbInfo) {
            gndCompInfo = updateComponents.find(comp => comp.name === gnd);
          } else {
            const { referenceComps } = getReferenceComps({ ReferenceNets, pcbId, COMP_PREFIX_LIB, VRM_COMPS: [gnd], findCaps: _findCaps, pcbInfo })
            gndCompInfo = referenceComps.find(comp => comp.name === gnd)
          }
          if (gndCompInfo) {
            const gndPins = gndCompInfo.pins.filter(pin => ReferenceNets.indexOf(pin.net) > -1).map(item => item.pin);
            _gndPins.push({ comp: gnd, pins: gndPins });
          };
        }
        if (pwr) {
          const pwrCompInfo = updateComponents.find(comp => comp.name === pwr);
          //filter pins by powerNets
          //findExtended === false : _PowerNets only includes main power nets
          //findExtended === true : _PowerNets includes main power nets and extended nets
          if (pwrCompInfo) {
            const pwrPins = pwrCompInfo.pins.filter(item => item.pin && _PowerNets.includes(item.net)).map(item => item.pin);
            _pwrPins.push({ comp: pwr, pins: pwrPins });
          };
        }
        let _model = { name: "", id: "" };
        if (defaultVRM) {
          _model.name = defaultVRM.name;
          _model.id = defaultVRM.libraryId;
        }
        _VRM.push({
          groundPin: _gndPins,
          model: _model,
          powerPin: _pwrPins,
          voltage: "1",
          VRM_COMP: vrmComp
        })
      }
    }
  };
  return {
    DEBUG_MONITOR,
    VRM: _VRM,
    PowerNets: _PowerNets,
    Components: updateComponents,
    vrms
  }
}

export {
  getVRMs,
  getLayoutNetManager,
  getLayoutPartManager,
  autoFindVRM,
  indCheck,
  ignoreFilter,
  notReasonableDistance,
  matchPinName,
  getGndPinByLocationAndLayer,
  getTraceByVRM
};