import React, { Component, Fragment } from 'react';
import { Transfer } from 'antd';
import '../index.css';
class PaginationTransfer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      selectPageNum: 1
    }
  }

  changePage = (number, direction) => {
    const key = direction === 'left' ? 'pageNum' : 'selectPageNum';
    this.setState({
      [key]: number
    })
  }

  tableTransfer = () => {
    const { locale, compNameObj, targetKeys, disabled, showSearch, titles } = this.props
    return <Transfer
      dataSource={compNameObj}
      showSelectAll={true}
      targetKeys={targetKeys}
      disabled={disabled}
      showSearch={showSearch}
      onChange={this.props.onChange}
      render={(item) => item.title}
      filterOption={(inputValue, item) =>
        (item.title || "").toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1
      }
      className='components-stuff-transfer'
      locale={locale}
      titles={titles}
      pagination
    />
  }

  render() {
    return (
      <Fragment>
        {this.tableTransfer()}
      </Fragment>
    );
  }
}

export default PaginationTransfer;