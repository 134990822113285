import React, { PureComponent } from 'react';
import { getSignalDelay } from '@/services/Sierra/results';
import { connect } from 'react-redux';
import makeCancelable from '@/services/api/makeCancelable';
import DelayComp, { sortDelayData } from '../components/Delay';
import { ResultData } from '@/services/Sierra';

class DelayResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      delayData: [],
    }
  }

  componentDidMount() {
    const { verificationSubId, verificationId } = this.props;
    if (verificationId && verificationSubId) {
      this.getDelay()
    }
  }

  componentDidUpdate(prevProps) {
    const { verificationSubId, verificationId } = this.props;
    if (verificationId !== prevProps.verificationId && verificationSubId) {
      this.getDelay()
    }
  }

  getDelay = async () => {
    const { verificationSubId, currentProjectId, verificationId } = this.props;
    let designs = [];
    ResultData.getVerificationJsonPromise(verificationSubId, verificationId, currentProjectId).then(res => {
      if (res) {
        let currentInterfaces = ResultData.getInterfaces();
        currentInterfaces.forEach(item => {
          designs.push({
            id: item.pcbId,
            name: item.pcb
          });
        })
        const promise = getSignalDelay({ verificationId, designs, projectId: currentProjectId, verificationSubId })
        this.cancelable = makeCancelable(promise);
        this.cancelable.promise.then(
          (response) => {
            const delay = response;

            this.setState({
              delayData: sortDelayData(delay, currentInterfaces),
            })
          },
          (error) => {
            this.setState({
              delayData: [],
            })
            console.error(error);
          }
        );
      }
    })
  }

  render() {
    const delayData = this.state.delayData;
    return DelayComp({ delayData, key: 'Delay' });
  }
}

const mapState = (state) => {
  const { project, resultReducer: { resultInfo } } = state.SierraReducer;
  const { currentProjectId } = project;
  const { verificationSubId, verificationId } = resultInfo;
  return {
    verificationId,
    currentProjectId,
    verificationSubId
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(DelayResult);