import { PACKAGE_PDN as CONST_PACKAGE_PDN } from "../../constants/treeConstants";

export const
  MASK = 'mask',
  MASK_DEFAULT = 'mask_default',
  MASK_CUSTOMIZED = 'mask_customized',

  /* --- project --- */
  CHANNELS = 'channels',
  CHANNEL = 'channel',
  VERIFICATIONS = 'verifications',
  VERIFICATION = 'verification',
  CHANNEL_CREATE = 'channel_create',
  ROCKY_PDN = "Rocky_PDN",
  PACKAGES = 'packages',
  PACKAGE_PDN = CONST_PACKAGE_PDN,
  PACKAGE_CHANNEL = 'package_channel',
  CARDS = "cards",
  CARD_CHANNEL = "card_channel",
  CARD = "card",
  CARD_VERIFICATION = "card_verification",
  CARD_RESULT = "card_result",
  SSN_VERIFICATION = "ssn_verification",
  SSN_RESULT = "ssn_result",
  SSN_CHANNEL = "ssn_channel",
  BMAS = "BMAs",
  BMA = "BMA";

export const
  SINGLE_PATTERN = "single_pattern",
  PATTERN_SWEEP = "pattern_sweep",
  PDN_EXTRACTION = "pdn_extraction",
  SIGNAL_EXTRACTION = "signal_extraction",
  SSN_PATTERN_TYPE_LIST = [SINGLE_PATTERN, PATTERN_SWEEP],
  MERGE_TYPE_LIST = [PDN_EXTRACTION, SIGNAL_EXTRACTION],
  SSN_CHANNEL_TYPE_LIST = [SINGLE_PATTERN, PATTERN_SWEEP, PDN_EXTRACTION, SIGNAL_EXTRACTION];

export const
  SIMULATION = 'simulation';
export const TRANSIENT = 'Transient';
export const STATEYE = 'StatEye';

/* --- pre-layout --- */


/* eye diagram */
export const ALL = "All", ALL_2T = "All 2T";

/* --- DDR type ---  */
export const DDR3 = "DDR3",
  DDR3L = "DDR3L",
  NVDDR3 = "NV-DDR3",
  DDR4 = "DDR4",
  DDR4L = "DDR4L",
  DDR4MR = "DDR4-MR",
  LPDDR4 = "LPDDR4",
  DDR5 = "DDR5",
  LPDDR5 = "LPDDR5",
  DDR5_RDIMM = "DDR5-RDIMM",
  DDR5_SODIMM = "DDR5-SODIMM",
  DDR5_UDIMM = "DDR5-UDIMM",
  DDR5_RDIMM_CARD = "DDR5-RDIMM-CARD",
  DDR5_SODIMM_CARD = "DDR5-SODIMM-CARD",
  DDR5_UDIMM_CARD = "DDR5-UDIMM-CARD",
  DDR5_RDIMM_X4 = "DDR5-RDIMM-X4";

export const DIMM_DDR_TYPES = [DDR5_RDIMM, DDR5_SODIMM, DDR5_UDIMM, DDR5_RDIMM_X4];
export const DDRTYPE = [
  DDR3, DDR3L, DDR4,
   /* DDR4L, */ LPDDR4,
  DDR5, LPDDR5,
  ...DIMM_DDR_TYPES
];//DDR4L not support
export const DEBUG_DDRTYPE = [
  DDR3, DDR3L, DDR4, DDR4MR, DDR5, LPDDR4, LPDDR5, NVDDR3,
  ...DIMM_DDR_TYPES];
export const PRELAYOUT_DDRTYPE = [DDR3, DDR3L, DDR4, LPDDR4, LPDDR5, DDR5];
export const RDIMM_TYPES = [DDR5_RDIMM, DDR5_RDIMM_X4];

//DDR5 Not support 2T
export const _2TModeDDRTypes = ["DDR3", "DDR3L", "DDR4"],
  prbsTypes = ['PRBS-SDR', 'PRBS-DDR', 'PRBS-HDR'],
  no2TModeSignalsReg = new RegExp(`^(CS)|^(CKE)|^(ODT)|^(CLK)`, 'ig');

//DDR3/DDR3L/DDR4/DDR5/DDR4L  DM unidirectional
//LPDDR4 DM  Bidirectional
export const ddrDMTypes = [
  DDR3, DDR3L, DDR4, DDR4MR, DDR5, DDR4L, NVDDR3,
  ...DIMM_DDR_TYPES];

export const MEMORY_CLK_PROJECT_TYPES = [
  DDR3, NVDDR3, DDR3L, DDR4, DDR4MR, DDR4L, LPDDR4, DDR5, LPDDR5,
  ...DIMM_DDR_TYPES];
export const MEMORY_BYTE_PROJECT_TYPES = [
  DDR3, NVDDR3, DDR3L, DDR4, DDR4MR, DDR4L, LPDDR4, DDR5, LPDDR5,
  ...DIMM_DDR_TYPES];

export const TERMINATION_DDR_TYPES = [LPDDR4, DDR5];

export const DDR3_CLOCKs = ["400", "533", "667", "800", "933", "1067"],// DDR3/DDR3L/LPDDR3 (MHz)
  DDR4_CLOCKs = ["800", "933", "1067", "1200", "1333", "1466", "1600"],// DDR4/DDR4L (MHz)
  LPDDR4_CLOCKs = ["800", "933", "1067", "1200", "1333", "1466", "1600", "1800", "1866.5", "2133"],// LPDDR4 (MHz)
  DDR5_CLOCKs = ["1600", "1800", "2000", "2400", "2600", "2800", "3000", "3200", "3400", "3600", "3800", "4000", "4200"],//DDR5 (MHz)
  LPDDR5_CLOCKs = ["1867", "2134", "2750", "3000", "3200", "3750", "4266", "4800", "5333"],// DDR5/LPDDR5 (MHz)
  CA_CLOCKS = ["467", "533", "600", "688", "750", "800", "937.5", "1066.5", "1200", "1333"];//LPDDR5 CA CLOCK (MHz)

//DDR3 
export const DDR3_78_pwrPinList = ["B9", "C1", "E2", "E9"],
  DDR3_78_gndPinList = ["B8", "C9", "D1", "D9"],

  DDR3_96_pwrPinList = ["A1", "A8", "C1", "C9", "D2", "E9", "F1", "H2", "H9"],
  DDR3_96_gndPinList = ["B1", "B9", "D1", "D8", "E2", "E8", "F9", "G1", "G9"],

  //DDR4
  DDR4_78_pwrPinList = ["B2", "B8", "C1", "C9", "E2", "E8"],
  DDR4_78_gndPinList = ["A2", "A8", "D1", "D9"],

  DDR4_96_pwrPinList = ["A1", "A9", "C1", "D9", "F2", "F8", "G1", "G9"],
  DDR4_96_gndPinList = ["A2", "A8", "C9", "D2", "D8", "E3", "E8", "F1", "H1", "H9"],

  //DDR5
  DDR5_78_pwrPinList = ["B2", "B8", "D1", "D9"],
  DDR5_78_gndPinList = ["A2", "A8", "D2", "D8"],

  DDR5_96_pwrPinList = ["B2", "B8", "D1", "D9"],
  DDR5_96_gndPinList = ["A2", "A8", "C1", "C9"],

  //LPDDR4 
  LPDDR4_200_pwrPinList = ["B3", "B5", "B8", "B10"],
  LPDDR4_200_gndPinList = ["A3", "A10", "C1", "C5"],

  LPDDR4_272_pwrPinList = ["A5", "A7", "A13", "A15"],
  LPDDR4_272_gndPinList = ["B1", "B4", "B6", "B8"],

  LPDDR4_366_pwrPinList = ["D1", "D3", "D5", "D10"],
  LPDDR4_366_gndPinList = ["F1", "F2", "F28", "F29"],

  LPDDR4_432_pwrPinList = ["A1", "B1", "C1", "D1"],
  LPDDR4_432_gndPinList = ["B10", "D10", "F10", "H10"];

export const PLOTS = 'plot',
  PLOT_CONFIG = 'plot_config',
  TIME_SCALE = "time_scale",
  V_MIN = "v_min",
  V_MAX = "v_max",
  LOCATIONS = "location",
  BACKGROUND_COLOR = "background_color",
  COLOR_LINE = "line_with_colormap",
  timeScaleList = [{
    name: "1e6",
    display: "us",
    unit: "1e-6"
  },
  {
    name: "1e9",
    display: "ns",
    unit: "1e-9"
  },
  {
    name: "1e12",
    display: "ps",
    unit: "1e-12"
  }],
  plotTypeList = [{
    name: "line_with_colormap",
    display: 'Colored Line'
  }, {
    name: 'colormap',
    display: 'Colormap'
  }, {
    name: 'line',
    display: 'Line'
  },],
  locationsList = ["BGA", "Die"],
  advancedList = [TIME_SCALE, V_MIN, V_MAX],
  portSavePath = ['Write', 'CLK_ADR', 'CLK_CMD'],
  backgroundList = [{
    name: "black",
    display: 'Black'
  }, {
    name: "white",
    display: 'White'
  }];