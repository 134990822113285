import http from "../http";

/**
 * create/update experiment group
 * 2021.11.15
 * params: { id, name, productType, verificationId, version}
 */

function editExperimentGroup({ data, product }) {
  return http.post(`/experiment/group/edit_group`, {
    ...data,
    productType: product
  })
}

/**
 * get experiment group list
 * 2021.11.15
 * params: { verificationId }
 */

function getExperimentGroup({ verificationId }) {
  return http.get(`/experiment/group/list?verificationId=${verificationId}`)
}


/**
 * delete experiment group
 * 2021.11.15
 * params: { experimentGroupId }
 */

function deleteExperimentGroup({ experimentGroupId }) {
  return http.post(`/experiment/group/delete?experimentGroupId=${experimentGroupId}`)
}

/**
 * get experiments list
 * 2021.11.16
 * params: { experimentGroupId }
 */
function getExperiments({ experimentGroupId }) {
  return http.get(`/experiment/list?experimentGroupId=${experimentGroupId}`)
}


/**
 * create/update experiment
 * 2021.11.16
 * params: { id, groupId, name, productType, content, version, projectId}
 */
function editExperiment({ params, product }) {
  return http.post(`/experiment/edit/experiment`, {
    ...params,
    productType: product
  })
}


/**
 * delete experiment
 * 2021.11.16
 * params: { experimentId }
 */
function deleteExperiment({ experimentId }) {
  return http.post(`/experiment/delete?experimentId=${experimentId}`)
}

function deleteExperiments(deleteArr) {
  return http.post(`/experiment/delete`, deleteArr)
}

/**
 * sweeping simulation
 * 2021.11.17
 * params: { experimentIds }
 */
function simulateSweeping({ experimentIds }) {
  return http.post(`/sweeping/simulation/run`, {
    experimentIds
  })
}

function cancelExperiment({ experimentId }) {
  return http.post(`/sweeping/simulation/cancel`, {
    experimentId
  })
}


/**
 * sweeping simulation log
 * 2021.11.17
 * params: { experimentId }
 */
function getExperimentLog({ experimentId }) {
  return http.get(`/experiment/running/log?experimentId=${experimentId}`)
}

function getExperimentMonitor({ experimentId }) {
  return http.get(`/experiment/monitor/log?experimentId=${experimentId}`)
}

/**
 * sweeping simulation log
 * 2021.21.1
 * params: path(experimentId, option, designId): experimentId=*&option=*
 */
function getExperimentResult(path) {
  return http.get(`/experiment/result?${path}`)
}

function getExperimentSNPFile({ experimentId }) {
  return http.get(`/experiment/result/snp_file_list?experimentId=${experimentId}`)
}

function getExperimentChannel({ designId, experimentId, path, cols, rows }) {
  return http.post(`/experiment/result/channel/curve`, {
    cols, path, rows, experimentId, designId
  })
}

function getExperimentsResultExsit({ experimentGroupId }) {
  return http.get(`/experiment/result/exist?experimentGroupId=${experimentGroupId}`)
}

function postExperimentColumns({ id, tableColumns }) {
  return http.post(`/experiment/group/table_column`, {
    id, tableColumns
  })
}

function experimentDebugOption({ id }) {
  return http.get(`/sierra/sweeping/debug/option?experimentId=${id}`);
}

function getSierraAllExperimentsMeasurement(verificationIds) {
  return http.get(`/sierra/sweep/measurement/config?verificationIds=${verificationIds}`)
}

export {
  editExperimentGroup,
  getExperimentGroup,
  deleteExperimentGroup,
  getExperiments,
  editExperiment,
  simulateSweeping,
  deleteExperiment,
  deleteExperiments,
  getExperimentLog,
  getExperimentMonitor,
  getExperimentResult,
  getExperimentSNPFile,
  getExperimentChannel,
  cancelExperiment,
  getExperimentsResultExsit,
  postExperimentColumns,
  experimentDebugOption,
  getSierraAllExperimentsMeasurement
}