function getPortByTouchstone(key) {
  if (key && typeof key === 'string') {
    const match = key.match(/.s[0-9]{1,}p$/i);
    const N = match ? match[0].slice(2, -1) : 0;
    let port = [];
    for (let i = 1; i <= N; i++) {
      port.push(i.toString());
    }

    return port;
  } else {
    return []
  }
}

export { getPortByTouchstone }