import React, { Component, Fragment } from 'react';
import { DashOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { getPkgNetDisplay } from '../../../services/helper/packageModelHelper';
import { getPinHeight } from '@/services/helper/packageHelper';
import './index.css';
import { ANDES_V2 } from '../../../constants/pageType';
import { CPHY } from '../../../services/PCBHelper/constants';

const { Option } = Select;

function getPinDisplay(signalInfo, fromSignalList) {
  let signalPinList = signalInfo, pinDisplay = true;
  if (!signalInfo || !signalInfo.length) {
    signalPinList = fromSignalList;
    pinDisplay = false;
  }
  return { pinDisplay, signalPinList }
}

class CopySignalPinsPortSetup extends Component {

  render() {

    const { fromSignalList = [], toSignal, fromSignal, signalKey, modelType, toSignals = [], toSignalList = [],
      applySignals = [], copyModelKey, copyModelKey2, isMultiInfo, differentDisplay, rank, product, topology, serdesType, copyTerminationModelKey } = this.props;
    const { topNet, bottomNet } = getPkgNetDisplay(toSignalList);
    const className = differentDisplay ? "single-pin-port-content" : "";
    const portClassName = isMultiInfo ? 'pkg-multi-model-port-pins' : '';

    let style = {}, pkgClassName = '';
    if (rank && rank.number && modelType === "Package" && toSignalList && toSignalList.length) {
      let height = getPinHeight(toSignalList[0]) - 30
      if (height > 100) {
        style = { height };
        pkgClassName = 'pkg-model-select-ports-item-flex'
      }
    }

    if (product === ANDES_V2 && modelType === "DIE" && topology === 'parallel') {
      pkgClassName = 'pkg-model-select-ports-main-fit-content';
    }

    return <div className={`pkg-model-select-ports-main ${pkgClassName}`} key={signalKey} style={{ ...style }}>
      <div className={`pkg-model-port-pins ${portClassName}`}>
        <div
          className={`pkg-model-long-line-content ${className}`}>
          {toSignalList.map((item, index) => (
            <div key={index} className='pkg-pin-long-line-item'>
              <div className='pkg-pin-long-line'></div>
            </div>))}
        </div>
        {this.getSignalPinLeftRender({ signalInfo: toSignalList, fromSignalList, className, isMultiInfo, rank })}
        <div className={`pkg-model-port-copy-signal-main ${serdesType === CPHY ? "pkg-model-port-signal-main-cphy" : ""}`}>
          {topNet ? <div className="pkg-model-port-net">( + ) {topNet}</div> : null}
          <div className="pkg-model-port-copy-signal">
            <Select
              value={toSignal}
              showSearch
              onChange={(key) => this.props.changeCopySignal(key, fromSignal, copyModelKey, copyModelKey2, copyTerminationModelKey)}
              popupMatchSelectWidth={false}
              className={`aurora-select`}
              popupClassName="pkg-select-dropdown-menu"
              getPopupContainer={() => document.getElementById('root')}
            >
              {toSignals.map((item) => <Option
                key={item}
                value={item}
                title={item}
                className={applySignals.includes(item) ? "pkg-selected-apply-signals" : ""}
              >{item}</Option>)}
            </Select></div>
          {!differentDisplay && bottomNet ? <div className="pkg-model-port-net">( - ) {bottomNet}</div> : null}
          {differentDisplay ? <div className="pkg-model-port-net">{bottomNet}</div> : null}
        </div>
        {product === ANDES_V2 && modelType === "DIE" && topology === 'parallel' ? null : this.getSignalPinRightRender({ signalInfo: toSignalList, fromSignalList, className, isMultiInfo })}
        {product === ANDES_V2 && modelType === "DIE" && topology !== 'parallel' ? this.getSignalPinTerminationRender({ signalInfo: toSignalList, fromSignalList, className, isMultiInfo }) : null}
      </div>
    </div>
  }

  portPopover = ({ pinItem, pinType, placeholder }) => {
    const cssId = `${pinItem}::${pinType}`;
    if (pinItem.pinValue) {
      return <div
        className='pkg-model-pin-input-div'
        id={cssId}
        title={pinItem.pinValue}
      >
        <div className='connection-pin-input-pin-port'>
          <span className='connection-pin-input-pin-port-text'>
            {pinItem.pinValue}
          </span>
        </div>
      </div>
    }

    return this.connectorPinPortInput({
      pinPort: pinItem.pinValue,
      cssId,
      placeholder
    })
  }

  connectorPinPortInput = ({
    pinPort,
    cssId,
    placeholder = "Port"
  }) => {
    return <Input
      className='pkg-model-pin-input'
      placeholder={placeholder}
      value={pinPort}
      id={cssId}
    />
  }

  getSignalPinLeftRender = ({ signalInfo, fromSignalList, className, isMultiInfo, rank }) => {
    const { signalPinList, pinDisplay } = getPinDisplay(signalInfo, fromSignalList);
    return (
      <div className={`pkg-model-pin-list ${className} pkg-model-left-pin-list`}>
        {signalPinList.map(item => (
          item.pinLeft.map((pin, index) => (
            <div key={index} className="pkg-model-pin-item">
              {isMultiInfo && <Fragment>
                <div className="pkg-model-port-left-pin-content">
                  <div className="pkg-model-port-left-pin-title" title={pin.pinDisplay} style={{ marginRight: 10 }}>{pin.pinDisplay}</div>
                  <div className="pkg-model-port-left-pin-title"><DashOutlined /></div>
                </div>
              </Fragment>}
              <div className="pkg-model-port-pin">
                {this.portPopover({
                  pinItem: pin,
                  pinType: "pinLeft"
                })}
              </div>
              {rank && rank.number &&
                <div className='spice-pin-line-box'>
                  <div className='spice-pin-line'></div>
                </div>
              }
              {!isMultiInfo &&
                <div className="pkg-model-port-pin-content">
                  <div className="pkg-model-port-pin-title" title={pinDisplay ? pin.pinDisplay : ''}>{pinDisplay ? pin.pinDisplay : ""}</div>
                </div>}
            </div>
          ))
        ))}
      </div>
    );
  }

  getSignalPinRightRender = ({ signalInfo, fromSignalList, className, isMultiInfo }) => {
    const { signalPinList, pinDisplay } = getPinDisplay(signalInfo, fromSignalList);
    const { modelType, product } = this.props;
    const otherClassName = product === ANDES_V2 && modelType === "DIE" ? "pkg-die-model-pin-item" : ""
    return <div className={`pkg-model-pin-list ${className}`}>
      {signalPinList.map(item => (
        item.pinRight.map((pin, index) => (
          <div key={index} className={`pkg-model-pin-item ${otherClassName}`}>
            {product === ANDES_V2 && modelType === "DIE" ? null
              : <div className="pkg-model-port-pin-content" style={{ zIndex: !pin.pinDisplay ? -1 : 1 }}>
                {!isMultiInfo && <div className="pkg-model-port-pin-title" title={pinDisplay ? pin.pinDisplay : ''}>{pinDisplay ? pin.pinDisplay : ""}</div>}
              </div>}
            <div className="pkg-model-port-pin">
              {this.portPopover({
                pinItem: pin,
                pinType: "pinRight"
              })}
            </div>
          </div>
        ))
      ))}
    </div>
  }

  getSignalPinTerminationRender = ({ signalInfo, fromSignalList, className }) => {
    const { terminationType } = this.props;
    const { signalPinList } = getPinDisplay(signalInfo, fromSignalList);
    return <div className={`pkg-model-pin-list ${className}`}>
      {signalPinList.map(item => (
        item.pinTermination.map((pin, index) => (
          <div key={index} className="pkg-model-pin-item pkg-die-model-pin-item">
            {
              terminationType === 'value'
                ? <Input
                  suffix="Ω"
                  size="small"
                  value={pin.pinRes}
                />
                : <div className="pkg-model-port-pin">
                  {this.portPopover({
                    pinItem: pin,
                    pinType: "pinRight",
                    placeholder: "Node"
                  })}
                </div>
            }
          </div>
        ))
      ))}
    </div>
  }
}

export default CopySignalPinsPortSetup;