import React, { Fragment, PureComponent } from "react";
import { createPortal } from "react-dom";
import Panel from '@/components/Panel';
import { Select, Checkbox, Divider } from 'antd';
import '@/publicCss/style.css';
import '@/publicCss/aurora.css';
import './index.css';
import {
  BOT_ROUGHNESS,
  SIDE_ROUGHNESS,
  TOP_ROUGHNESS,
  roughnessTypeList,
  getRoughnessByType,
  HAMMERSTAD_JENSEN,
  HURAY,
  getApplyAllType,
  getCheckApplyAll,
  ROUGHNESS_NONE
} from "../../services/Stackup";
import RoughnessTabs from "./Tabs";
import HammerstadRoughnessSetup from './HammerstadRoughnessSetup';
import HurayRoughnessSetup from "./HurayRoughnessSetup";

const Option = Select.Option;
class SurfaceRoughnessPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      tabKey: TOP_ROUGHNESS,
      same_roughness: false,
      roughness: {},
      bot_roughness: {},
      side_roughness: {},
      applyAll: false,
      applyAllOuter: false,
      applyAllInner: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  closeModal = () => {
    const { same_roughness, roughness, bot_roughness, side_roughness, applyAll, applyAllOuter, applyAllInner, errorMsg } = this.state;
    if (errorMsg) {
      return;
    }
    const applyAllType = getApplyAllType({ applyAll, applyAllOuter, applyAllInner });
    this.props.closePanel({ applyAllType, same_roughness, roughness, bot_roughness, side_roughness }, this.props.record);
    this.props.save();
  }

  updateErrorMsg = (errorMsg) => {
    this.setState({
      errorMsg
    })
  }

  componentDidMount = () => {
    const { record } = this.props;
    let roughness = record.roughness || { type: ROUGHNESS_NONE };

    this.setState({
      same_roughness: record.bot_roughness ? false : true,
      roughness: { ...roughness },
      bot_roughness: record.bot_roughness || { ...roughness },
      side_roughness: record.side_roughness || { ...roughness }
    })
  }

  changeTab = (key) => {
    this.setState({
      tabKey: key
    })
  }

  onNameRef = (ref) => {
    this.NameChild = ref;
  }

  checkChange = (e) => {
    const checked = e.target.checked;
    this.setState({
      same_roughness: checked
    })
  }

  changeRoughnessType = (key) => {
    const roughness = getRoughnessByType(key);
    this.updateRoughness(roughness);
    this.setState({
      errorMsg: null
    })
  }

  updateRoughness = (_roughness) => {
    const { tabKey, same_roughness } = this.state;
    if (same_roughness) {
      this.setState({
        roughness: {
          ..._roughness
        },
        bot_roughness: {
          ..._roughness
        },
        side_roughness: {
          ..._roughness
        }
      })
    } else {
      this.setState({
        [tabKey]: {
          ..._roughness
        }
      })
    }
  }

  applyChange = (e, type) => {
    const checked = e.target.checked;
    let { applyAllOuter, applyAllInner, applyAll } = this.state;
    if (checked) {
      const applyInfo = getCheckApplyAll(type, { applyAll, applyAllOuter, applyAllInner });
      this.setState({
        applyAll: applyInfo.applyAll,
        applyAllInner: applyInfo.applyAllInner,
        applyAllOuter: applyInfo.applyAllOuter
      })
    } else {
      this.setState({
        [type]: checked
      })
    }
  }

  renderDialog() {
    const { errorMsg, tabKey, same_roughness, applyAll, applyAllOuter, applyAllInner } = this.state;
    const { record } = this.props;
    const content = (
      <Panel
        className='surface-roughness-panel panel-x-scroll-hidden'
        title={<div className='surface-roughness-title'>Surface Roughness - {record.name}</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={500}
        position='panel-center'
        draggable
        minHeight={200}
      >
        <div className='surface-roughness-content'>
          {this.getCheckboxItem({
            checked: same_roughness,
            checkChange: this.checkChange,
            type: "same_roughness",
            text: "Same setting applies to top bottom and side",
            style: { height: 32 }
          })}
          {same_roughness ? this.getRoughnessChildren() :
            <RoughnessTabs
              tabKey={tabKey}
              changeTab={this.changeTab}
              children={this.getRoughnessChildren()}
            />
          }
          {this.getCheckboxItem({
            checked: applyAll,
            checkChange: this.applyChange,
            type: "applyAll",
            text: "Apply to all metal layers"
          })}
          {this.getCheckboxItem({
            checked: applyAllOuter,
            checkChange: this.applyChange,
            type: "applyAllOuter",
            text: "Apply to outer metal layers"
          })}
          {this.getCheckboxItem({
            checked: applyAllInner,
            checkChange: this.applyChange,
            type: "applyAllInner",
            text: "Apply to inner metal layers"
          })}
          {errorMsg && errorMsg.error ? <span className='aurora-model-name-error-msg'>{errorMsg.error}</span> : null}
        </div>
      </Panel >)
    return createPortal(content, this.dialogRoot);
  }

  getRoughnessChildren = () => {
    const { tabKey, same_roughness, errorMsg } = this.state;
    const roughness = same_roughness ? this.getRoughnessValue(TOP_ROUGHNESS) : this.getRoughnessValue(tabKey);
    if (!roughness) {
      return null;
    }
    return <Fragment><div className='surface-roughness-item surface-roughness-item-tabs'>
      <label className='surface-roughness-edit-value-label'>Model</label>
      <Select
        value={roughness.type}
        onChange={this.changeRoughnessType}
        placeholder={"Select Roughness Type"}
        className='aurora-select surface-roughness-select'
        popupClassName='aurora-select-dropdown'
      >
        {roughnessTypeList.map(item =>
          <Option key={item.key}>{item.name}</Option>
        )}
      </Select>
    </div>
      {roughness.type === HURAY ? <HurayRoughnessSetup
        roughness={roughness}
        errorMsg={errorMsg}
        updateRoughness={this.updateRoughness}
        updateErrorMsg={this.updateErrorMsg}
      /> : null}
      {roughness.type === HAMMERSTAD_JENSEN ? <HammerstadRoughnessSetup
        roughness={roughness}
        errorMsg={errorMsg}
        updateRoughness={this.updateRoughness}
        updateErrorMsg={this.updateErrorMsg}
      /> : null}
    </Fragment>
  }

  getCheckboxItem = ({ checked, checkChange, type, text, style }) => {
    return <div className='surface-roughness-item surface-roughness-item-apply' style={style}>
      <Checkbox
        className='surface-roughness-item-checkbox'
        checked={checked}
        onChange={(e) => checkChange(e, type)}
      />
      <label className='surface-roughness-edit-value-label surface-roughness-edit-value-label-long'>{text}</label>
    </div>
  }

  getRoughnessValue = (tabKey) => {
    const { roughness, bot_roughness, side_roughness } = this.state;
    switch (tabKey) {
      case TOP_ROUGHNESS:
        return roughness;
      case BOT_ROUGHNESS:
        return bot_roughness;
      case SIDE_ROUGHNESS:
        return side_roughness;
      default: return null;
    }
  }

  render() {
    const { inputRef } = this;
    const { text } = this.props;
    return (
      <Fragment>
        <div className='editable-cell-value-wrap' ref={inputRef}>
          {text}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default SurfaceRoughnessPanel;