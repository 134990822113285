import { checkRLCValue } from '../../helper/dataProcess';
import {
  RLCCONComponent,
  BasicCompModel,
  BasicComponent,
  Model,
  WaveformConfig,
  PinSpiceModel
} from './IntegratedInterface';
import { _isPowerGND } from './pcbNetsHelper';
import { getPins } from './IbisModelHelper';
import {
  DDR3, DDR3L, DDR4, DDR5, DDR4L, LPDDR4, LPDDR5, ddrDMTypes, _2TModeDDRTypes, no2TModeSignalsReg,
  DDR5_RDIMM, DDR5_SODIMM, DDR5_UDIMM, DDR5_RDIMM_CARD, DDR5_SODIMM_CARD, DDR5_UDIMM_CARD,
  // DDR3_78_pwrPinList, DDR3_78_gndPinList, DDR3_96_pwrPinList, DDR3_96_gndPinList,
  // DDR4_78_pwrPinList, DDR4_78_gndPinList, DDR4_96_pwrPinList, DDR4_96_gndPinList,
  // DDR5_78_pwrPinList, DDR5_78_gndPinList, DDR5_96_pwrPinList, DDR5_96_gndPinList,
  // LPDDR4_200_pwrPinList, LPDDR4_200_gndPinList, LPDDR4_272_pwrPinList, LPDDR4_272_gndPinList,
  // LPDDR4_366_pwrPinList, LPDDR4_366_gndPinList, LPDDR4_432_pwrPinList, LPDDR4_432_gndPinList,
  prbsTypes,
  DIMM_DDR_TYPES,
  RDIMM_TYPES,
  DDR5_RDIMM_X4
} from '../constants';
import { PKG_TOUCHSTONE, PKG_SPICE } from '../../../constants/libraryConstants';
import { splitComponentName } from '../../helper/splitComponent';
import { getDDRSignalType } from '../../helper/getSignalType';
import { getLayoutComponents, getTypeFromPartName } from "@/services/PCBHelper";
import rankConstructor from '../../helper/rankConstructor';
import { isByte } from '../result/helper/eyediagram/displayHelper';
import { IGNORE } from '../../PCBHelper';
import { getCompTypeByPrefixLib } from '../../Designs/helper';
import { BGA, DIE } from '../../../constants/componentType';

const NONE = 'None'
const CONTROLLER = 'Controller', MEMORY = 'Memory';
const RLCType = ['RLC', 'Cap', 'Ind', 'Res'];
const RLCTypes = ['Cap', 'Ind', 'Res'];
export const Types = [CONTROLLER, MEMORY];
const ND_IN = "nd_in";
const IBIS_AMI = "IBIS_AMI";

/* config format */
export function changeConfigFormat(config) {
  let { clock, timeStep, slewRate, caClock, caTimeStep } = config;
  if (typeof clock === "string" && clock) {
    config.clock = settingUnit(clock, "M");
  }

  if (caClock && typeof caClock === "string") {
    config.caClock = settingUnit(caClock, "M");
  }

  if (typeof timeStep === "string" && timeStep) {
    config.timeStep = settingUnit(timeStep, "n");
  }

  if (typeof caTimeStep === "string" && caTimeStep) {
    config.caTimeStep = settingUnit(caTimeStep, "n");
  }

  if (typeof slewRate === "string" && slewRate) {
    config.slewRate = settingUnit(slewRate, "n");
  }

  return config;
}

export function settingUnit(param, defaultUnit) {
  let value, unit;
  // If param is a number, the number 1 is expressed as 1MHz.
  if (!isNaN(Number(param))) {
    return { value: param, unit: defaultUnit }
  } else {
    if (param.indexOf('e') > -1) {
      value = parseFloat(param).toExponential();
    } else {
      value = parseFloat(param);
    }
    unit = param.slice(-1);
    if ((unit && !isNaN(Number(unit))) || !unit) {
      // If the last character of param is still a number, set the default.
      unit = defaultUnit;
    }
    // If value is NaN
    if (isNaN(value)) {
      value = " ";
    }
    return { value, unit }
  }
}

function RockyCtrl() {
  this.pcbId = '';
  this.CompsInfo = null;
}
let ctrl = new RockyCtrl();

export function cleanCtrlInfo() {
  ctrl.pcbId = '';
  ctrl.CompsInfo = null;
}

export function getComponentsWithNetList({ netList, pcbNetsList, layers, pcbId, COMP_PREFIX_LIB }) {
  //filter net list
  const _netList = Array.isArray(netList) ? [...new Set([...netList])] : [];
  let CompsInfo = null;
  if (ctrl.pcbId !== pcbId) {
    CompsInfo = getLayoutComponents({ layers, COMP_PREFIX_LIB });
    ctrl.pcbId = pcbId;
    ctrl.CompsInfo = CompsInfo;
  } else {
    CompsInfo = ctrl.CompsInfo;
  }

  let components = []; // [{pin,name,net,value,type}]
  _netList.forEach(netName => {
    const pinList = getPinList(netName, pcbNetsList);
    if (Array.isArray(pinList)) {
      const list = pinList.map(pin => {
        const compInfo = CompsInfo[pin.mCompName];
        let _compInfo = compInfo;
        if (!compInfo) {
          _compInfo = {
            type: "Ignore",
            value: "",
            part: "UNKNOW"
          }
        }
        let { type, value, part, location, name } = _compInfo;

        if (!name) {
          return null;
        }

        if (type === "Ignore" && name.match(/^MR_?[0-9]+/g)) {
          type = 'Res'
        }

        if (COMP_PREFIX_LIB) {
          //update component type by custom component reference designator
          type = getCompTypeByPrefixLib(pin.mCompName, COMP_PREFIX_LIB);
        }

        if (!COMP_PREFIX_LIB || (COMP_PREFIX_LIB && type === 'Ignore')) {
          const _type = getTypeFromPartName(compInfo.part)
          if (_type) {
            type = _type
          }
        }
        return {
          pin: pin.mPinNum,
          name: pin.mCompName,
          net: netName,
          value: ((type === 'Res' || type === 'Ind' || type === 'Cap') && value) || "",
          type: type,
          part,
          location
        }
      });
      components.push(...list.filter(item => !!item));
    }
  });
  return components;
};

function getPinList(netName, netList) {
  if (!netName || !netList) {
    console.log('Can not find pin list of ', netName);
    return [];
  }
  const netObj = Array.isArray(netList) ? netList.find(item => item.mName === netName) : null;
  // netObj.mPinList - mLayerName, mCompName, mPinNum, mMetalLayerName
  if (netObj && netObj.mPinList) {
    return netObj.mPinList;
  }
  console.log('Can not find pin list of ', netName);
  return [];
}

export function deleteCompsConnectWithNets() {

}

function getWCKComponentInfo({ projectType, interfaceType, _currentChannelInfo }) {
  let bytesDDRList = [], wckControllerCompPins = [], wckMemoryCompPins = [];
  if (projectType === LPDDR5 && interfaceType === "BYTE" && _currentChannelInfo && _currentChannelInfo.DDR) {
    bytesDDRList = _currentChannelInfo.DDR.filter(item => item.Name && item.Name.match("Byte"));
    for (let ddr of bytesDDRList) {
      if (!ddr.Interfaces || !ddr.Interfaces.length) {
        continue;
      }
      const _interface = ddr.Interfaces[0];
      if (!_interface.Content || !_interface.Content.Components) {
        continue;
      }
      const controllerCompPins = _interface.Content.Components.filter(item => item.type === CONTROLLER).map(item => {
        item.pins.forEach(it => {
          it.name = item.name;
          it.byteName = ddr.Name;
        })
        return item.pins;
      }).flat(2).filter(item => item.signal && item.signal.match("WCK"));
      const memoryCompPins = _interface.Content.Components.filter(item => item.type === MEMORY).map(item => {
        item.pins.forEach(it => {
          it.name = item.name;
          it.byteName = ddr.Name;
        })
        return item.pins;
      }).flat(2).filter(item => item.signal && item.signal.match("WCK"));

      wckControllerCompPins.push(...controllerCompPins);
      wckMemoryCompPins.push(...memoryCompPins);

    }
  }
  return { wckControllerCompPins, wckMemoryCompPins }
}
/* 
find powerNets and PowerComponents
Add Channel and Extraction
 */
export function updateInterfaceInfo({
  Interfaces,
  pcbId,
  pcbInfo, projectType,
  currentChannelInfo,
  cmd_2t_mode,
  isReplace,
  packageType,
  isCard = false,
  isAllInterface = false
}) {
  const { netsList, layers } = pcbInfo;
  let _Interfaces = JSON.parse(JSON.stringify(Interfaces));
  const _currentChannelInfo = currentChannelInfo ? JSON.parse(JSON.stringify(currentChannelInfo)) : null;
  const VTTNets = _currentChannelInfo ? _currentChannelInfo.VTTNets : null;
  const PowerNets = _currentChannelInfo ? _currentChannelInfo.PowerNets : null;
  const GroundNets = _currentChannelInfo ? _currentChannelInfo.GroundNets : null;
  const interfaceName = Interfaces && Interfaces.length > 0 ? Interfaces[0].name : "";
  const interfaceType = getInterfaceType(interfaceName);
  const { wckControllerCompPins, wckMemoryCompPins } = getWCKComponentInfo({ projectType, interfaceType, _currentChannelInfo });
  _Interfaces.forEach(item => {
    let netList = [];
    let components = JSON.parse(JSON.stringify(item.Content.Components));
    //filter components
    //removed null pin
    components = components.filter(comp => !!comp);

    //remove signal with same net
    let sameNet = [];
    const ctrlIndex = components.findIndex(item => item.type === CONTROLLER);
    if (ctrlIndex >= 0) {
      let newPins = [];
      components[ctrlIndex].pins.forEach(item => {
        const newPin = newPins.find(_new => _new.net === item.net);
        if (newPin && newPin.pin === item.pin) {
          sameNet.push({ net: newPin.net, signal: newPin.signal });
        } else {
          newPins.push(item);
        }
      })
      components[ctrlIndex].pins = newPins.filter(pin => (!!pin) && pin.usage && pin.signal);
    }

    for (let i = 0; i < components.length; i++) {
      let comp = components[i];
      if (!MEMORY.includes(comp.type)) {
        continue;
      }

      if (!comp.pins) {
        comp.pins = [];
      } else {
        comp.pins = comp.pins.filter(pin => (!!pin) && pin.usage && pin.signal);
        if (sameNet && sameNet.length) {
          comp.pins.forEach(pin => {
            const same = sameNet.find(s => s.net === pin.net);
            pin.signal = same ? same.signal : pin.signal;
          })
        }
      }
      components[i] = comp;
    }

    let newSignals = []
    item.Content.Signals.forEach(net => {
      let merge = true;
      if (sameNet && sameNet.length) {
        sameNet.forEach(pin => {
          if (net.nets.includes(pin.net) && net.name !== pin.signal) {
            merge = false;
          }
        })
      };
      if (merge) {
        newSignals.push(net);
        netList = [...netList, ...net.nets];
      }
    });
    item.Content.Signals = newSignals;
    //if ddr type is LPDDR5, update WCK memory component pins,Solve the issue of WCK's topology is one-to-two.
    //If the same controller component pin exists in different bytes, then merge the pins of the memory component to each byte
    if (projectType === LPDDR5 && interfaceType === "BYTE") {
      const currControllerCompPins = JSON.parse(JSON.stringify(components.filter(it => it.type === CONTROLLER))).map(_item => {
        return {
          name: _item.name,
          pins: _item.pins.filter(it => it.signal.match("WCK"))
        }
      }).map(it => {
        it.pins.forEach(_it => {
          _it.name = it.name;
        });
        return it.pins;
      }).flat(2);
      for (let controller of currControllerCompPins) {

        const filterByteContrCompPins = wckControllerCompPins.filter(_item =>
          !interfaceName.match(_item.byteName)
          && _item.name === controller.name
          && _item.pin === controller.pin
          && _item.signal === controller.signal
        );
        const filterByteList = filterByteContrCompPins.map(_item => _item.byteName);
        const filterByteMemoryCompPins = wckMemoryCompPins.filter(_item =>
          filterByteList.includes(_item.byteName)
          && _item.signal === controller.signal
        )
        components.forEach(comp => {
          if (comp.type === MEMORY
            && !!comp.pins.find(it => it.signal === controller.signal)) {
            const usage = comp.pins[0].usage;
            const pins = comp.pins.map(item => item.pin);
            const filterPins = filterByteMemoryCompPins.filter(_it => !(_it.name === comp.name && pins.includes(_it.pin)))
            comp.pins.push(...filterPins.map(_it => {
              return {
                pin: _it.pin,
                usage: usage,
                net: _it.net,
                signal: _it.signal
              }
            }))
          }
        })
      }
    }
    //
    let capConnectPowerNets = [];
    //update power nets
    let updatePowerNets = [];//power nets

    if (pcbInfo && layers && netsList) {
      const componentNames = item.Content.Components.map(comp => splitComponentName(comp.name));
      const componentsList = getComponentsWithNetList({ netList: netList, pcbNetsList: netsList, layers, pcbId });
      let dieComp = [];
      if (packageType) {
        dieComp = componentsList.filter(item => item.name.match(/DIE/ig) || item.part.match(/DIE/ig)).map(item => item.name);
      }
      for (const comp of componentsList) {
        const { pin, name, net, value, type, part } = comp;
        const compName = splitComponentName(name);
        if (componentNames.includes(compName)) {
          continue;
        }

        let _index = components.findIndex(c => c.name === name);
        if (_index < 0) {
          // Not exist component
          let newComp;
          if (RLCType.includes(type)) {
            let _value = value;
            _value = checkRLCValue(value);
            // Cap 
            if (type === 'Cap') {
              _value = {
                r: "0",
                l: "0",
                c: _value || "0"
              }
            }
            newComp = new RLCCONComponent({ name, type, value: _value });
          } else {
            let _type = type;
            if (packageType) {
              _type = dieComp.length && !dieComp.includes(name) ? IGNORE : packageType;
            }
            newComp = new BasicComponent({ name, type: _type })
          }

          let signal = item.Content.Signals.find(s => s.nets.includes(net));
          newComp.pins = [new BasicCompModel({ pin, net, signal: signal.name })];

          newComp.part = part;
          components.push(newComp);
        } else {
          // Exsit component
          let signal = item.Content.Signals.find(s => s.nets.includes(net));
          let compIndex = components.findIndex(c => c.name === name);
          components[compIndex].pins.push(new BasicCompModel({ pin, net, signal: signal.name }));
        }
      }

      // find power nets connect rlc
      const _rlcFilter = components.filter(item => RLCTypes.includes(item.type));
      const findPowerByRes = findPowerNetsByRes(_rlcFilter, netsList);
      const { comps } = findPowerByRes;
      comps.forEach(comp => {
        const index = components.findIndex(item => item.name === comp.name);
        if (index > -1) {
          components[index] = { ...comp };
        }
      });

      comps.forEach(comp => {
        if (Array.isArray(comp.pins)) {
          for (let item of comp.pins) {
            if (item.net && !item.signal) {
              if (comp.type === 'Cap') {       //find cap component connect power net
                capConnectPowerNets.push(item.net);
              }
              updatePowerNets.push(item.net);
            } else {
              continue;
            }
          }
        }
      })
    }

    updatePowerNets = [...new Set(updatePowerNets)].map(item => ({ name: item, value: "" }))

    //Add vtt nets
    let _VTTNets = [];
    if (interfaceType === 'CLK' || isAllInterface) {
      if (VTTNets && Array.isArray(VTTNets)) {
        _VTTNets = VTTNets && Array.isArray(VTTNets) ? JSON.parse(JSON.stringify(VTTNets)) : [];
      } else {
        //Find VTT nets by component Res
        const newVTTNets = pcbInfo ? findVTTNets(components, netsList) : null;
        _VTTNets = newVTTNets && Array.isArray(newVTTNets) ? JSON.parse(JSON.stringify(newVTTNets)) : [];
      }
    }

    //add refNets to powerNets
    if (item.Content.RefNets) {
      item.Content.RefNets.forEach(ref => {
        const findIndex = updatePowerNets.findIndex(item => item.name === ref);
        if (findIndex < 0) {
          updatePowerNets.push({
            name: ref,
            value: ""
          })
        }
      })
    }

    // Add Interface.PowerNets to PowerNets
    if (Array.isArray(PowerNets)) {
      PowerNets.forEach(net => {
        const findIndex = updatePowerNets.findIndex(item => item.name === net);
        let value = "";
        //If there is cap connected to the current power net, set power net voltage by DDR type
        if (capConnectPowerNets.includes(net)) {
          value = getPowerVoltage(projectType);
        }
        if (findIndex < 0) {
          updatePowerNets.push({
            name: net,
            value
          })
        } else {
          updatePowerNets[findIndex].value = value;
        }
      })
    }

    // Add Interface.GroundNets to PowerNets, Set voltage = 0
    if (Array.isArray(GroundNets)) {
      GroundNets.forEach(net => {
        const findIndex = updatePowerNets.findIndex(item => item.name === net);
        if (findIndex > -1) {
          updatePowerNets[findIndex].value = "0";
        } else {
          updatePowerNets.push({
            name: net,
            value: "0"
          })
        }
      })
    }

    //Add VTT Nets to powerNets and set VTT nets voltage
    _VTTNets.forEach(net => {
      const findIndex = updatePowerNets.findIndex(item => item.name === net);
      let value = getVTTNetVoltage(projectType);
      if (findIndex > -1) {
        updatePowerNets[findIndex].value = value;
      } else {
        updatePowerNets.push({
          name: net,
          value
        });
      }
    });
    let signalNets = [];
    item.Content.Signals.forEach(item => {
      if (item.nets && item.nets.length > 0) {
        signalNets = [...signalNets, ...item.nets]
      }
    })
    //Filter power nets (not includes signal nets)
    updatePowerNets = updatePowerNets.filter(item => !signalNets.includes(item.name));

    //update interfaces (powerComponents and PowerNets)
    //Byte.PowerNets = Interface.PowerNets + Interface.GroundNets + Interface.VTTNets + Byte.ReferenceNets
    item.Content.PowerNets = updatePowerNets ? JSON.parse(JSON.stringify(updatePowerNets)) : [];
    item.Content.Components = components ? JSON.parse(JSON.stringify(components)) : item.Content.Components;

    let newComponents = isReplace || isCard ? item.Content.Components : setDefaultPinModels({
      Components: item.Content.Components,
      projectType,
      interfaceName,
      cmd_2t_mode
    });

    if (!isCard) {
      newComponents = newComponents.map(item => {
        if (Types.includes(item.type)) {
          if (!item.pkg) {
            item.pkg = { type: 'None' }
          }
        }
        return item;
      })
    }

    item.Content.Components = JSON.parse(JSON.stringify(newComponents));
    item.Content.VTTNets = JSON.parse(JSON.stringify(_VTTNets));
  })
  //add default active
  const memoryList = (_Interfaces[0].Content.Components || []).filter(item => item.type === MEMORY);

  if (((DIMM_DDR_TYPES.includes(projectType) && interfaceType !== "CLK") || (projectType === LPDDR4 && isByte(_Interfaces[0].name))) && memoryList.length > 1) {
    _Interfaces.forEach(info => {
      info.Content.Components.forEach(item => {
        if (item.type === MEMORY) {
          item.active = item.name === memoryList[0].name ? true : false;
        }
      })
    })
  }

  return _Interfaces;
}

function setDefaultPinModels({ Components, projectType, interfaceName, cmd_2t_mode }) {
  let comps = JSON.parse(JSON.stringify(Components));
  //TODO CLK
  comps.forEach(comp => {

    if (Types.includes(comp.type)) {
      if (!comp.pins) {
        comp.pins = [];
      }
      comp.pins.forEach(pin => {

        //Add default powerOff
        if (pin.usage === 'Driver') {
          pin.powerOff = "0";
        }

        //default pinModels [{}]
        //usage Driver/Receiver
        pin.pinModels = getPins({ usage: pin.usage });

        //find DM delete DM read usage
        let DMReg = new RegExp(`^(DM)`, 'ig');
        const words = pin.signal ? pin.signal.match(DMReg) : null;
        if ((words && ddrDMTypes.includes(projectType)) || (projectType === LPDDR5 && pin.signal.includes('WCK'))) {
          //DDR3/DDR3L/DDR4/DDR5/DDR4L  DM unidirectional
          //LPDDR4 DM  Bidirectional
          //LPDDR5 WCK unidirectional Conntroller => Memory
          if (pin.usage === 'Driver' && comp.type === 'Memory') {
            pin.pinModels = [];
            pin.usage = 'Receiver';
          } else if (pin.usage === 'Receiver' && comp.type === 'Controller') {
            pin.usage = 'Driver';
            pin.pinModels = getPins({ usage: pin.usage });
          }
        }

        if (projectType === LPDDR5 && pin.signal.includes('DQS')) {
          if (pin.usage === 'Driver' && comp.type === 'Controller') {
            pin.pinModels = [];
            pin.usage = 'Receiver';
          } else if (pin.usage === 'Receiver' && comp.type === 'Memory') {
            pin.usage = 'Driver';
            pin.pinModels = getPins({ usage: pin.usage });
          }
        }
        let _index = pin.pinModels.findIndex(item => item.pinName === 'nd_in');
        if (_index > -1) {
          const value = getStimulusDefaultValue({
            signalName: pin.signal,
            interfaceName,
            cmd_2t_mode,
            projectType
          });
          pin.pinModels[_index].type = value.type;
          if (value && value.tabs) {
            pin.pinModels[_index].tabs = value.tabs;
          }
          if (value && value.type && (value.type.match("PRBS") || value.type.match("CLK"))) {
            pin.pinModels[_index].delay = { type: "absolute", value: "1ns" };
          }
        }
      })
    }
  });

  return comps;
}

//get powerComponent
export function getPowerComponents({ ReferenceNets, PowerNets, pcbInfo, pcbId, COMP_PREFIX_LIB }) {
  let newPowerComps = [], findVRMComps = [], findCaps = [];
  const { pcbNetsList, layers } = pcbInfo;
  const nets = [...ReferenceNets, ...PowerNets];
  const componentsList = getComponentsWithNetList({ netList: nets, pcbNetsList, layers, pcbId, COMP_PREFIX_LIB });
  let existComponentsName = [];
  for (const comp of componentsList) {
    const { pin, name, net, value, type, part } = comp;
    const _index = existComponentsName.indexOf(name);
    if (type === CONTROLLER || type === MEMORY) continue;
    if (_index < 0) {
      // Not exist component
      let newComp;
      if (RLCType.includes(type)) {
        let _value = checkRLCValue(value);
        if (type === 'Cap') {
          _value = { r: "0", l: "0", c: _value }
        }
        newComp = new RLCCONComponent({ name, usage: type, value: _value, pins: [{ pin, net }] });
        if (type === 'Res' || type === 'Ind') {
          findVRMComps.push({ ...newComp, usage: type, location: comp.location });
        } else if (type === 'Cap') {
          findCaps.push({ ...newComp, usage: type, location: comp.location });
        }
      } else {
        newComp = new RLCCONComponent({ name, usage: "Ignore", value: "", pins: [{ pin, net }] });
      }
      newComp.part = part;
      newPowerComps.push(newComp);
      // Update newPowerComps name list
      existComponentsName.push(name);
    } else {
      // Exsit component
      newPowerComps[_index].pins.push({ pin, net });
    }
  };
  /*  if (findVRM) { */
  const Components = JSON.parse(JSON.stringify(newPowerComps));
  return { Components, findVRMComps, findCaps };
}

export function filterComps(findVRMComps, findCaps, newPowerComponents) {
  const newComponentsNames = newPowerComponents.map(d => d.name);
  const _findVRMComps = findVRMComps.filter(d => newComponentsNames.includes(d.name));
  // const _findCaps = findCaps.filter(d => newComponentsNames.includes(d.name));
  const _findCaps = findCaps
  return { _findVRMComps, _findCaps };
}

export function checkExistNets(nets, netNameList) {
  if (netNameList.length > 0) {
    const checkNets = nets.filter(net => netNameList.includes(net));
    return checkNets;
  }
  return [];
}

export function findPowerNetsByRes(comps, pcbNetsList) {
  let _comps = comps ? JSON.parse(JSON.stringify(comps)) : [];
  const filterNoneNets = Array.isArray(pcbNetsList) ? pcbNetsList.filter(item => item.mName !== 'NONET') : [];
  _comps.forEach(item => {
    const compName = item.name;
    const filterNets = Array.isArray(item.pins) ? item.pins.map(item => item.net) : [];
    const nets = _getNetsListByComp({ compName, filterNets, pcbNetsList: filterNoneNets }).filter(net => _isPowerGND(net));
    nets.forEach(info => {
      const find = Array.isArray(info.mPinList) ? info.mPinList.find(item => item.mCompName === compName) : null;
      if (find) {
        const pin = find.mPinNum;
        const net = info.mName;
        item.pins.push({ signal: "", pin, net })
      }
    });
  });
  return { comps: _comps };
}

export function _getNetsListByComp({ compName, filterNets, pcbNetsList, findVRM }) {
  const netsList = Array.isArray(pcbNetsList) ? pcbNetsList.filter(item =>
    !filterNets.includes(item.mName)
    && item.mName !== 'NONET') : [];

  let arr = [];
  for (let net of netsList) {
    const components = Array.isArray(net.mPinList) ? net.mPinList.map(item => item.mCompName) : []
    for (let comp of components) {
      if (comp === compName) {
        arr.push(net)
      };
    }
  };
  //filter not power net
  //resistor array component: power net will appear multiple times on the same component
  if (arr.length > 1) {
    arr = duplicatesArr(arr);
  }
  if (findVRM) {
    return arr.map(item => item.mName);
  }
  return arr;
}

function duplicatesArr(arr) {
  var result = [];
  arr.forEach(item => {
    if (arr.indexOf(item) !== arr.lastIndexOf(item) && result.indexOf(item) === -1)
      result.push(item);
  })
  return result;
}

// check signal name type
export function checkSignalName(standardSignalName, currentSignal, interfaceName) {
  let regArr = [];
  //find DQ0-DQN(DQ0,DQ9) and DM
  let DQRegObj = new RegExp(`^(DQ[0-9]+)|^(DM)|^(DM[0-9]+)`, 'ig');
  regArr.push(DQRegObj);

  //find DQSP/DQSN
  let DQSRegObj = new RegExp(`^(DQSP)|^(DQSN)|^(RDQS_T)|^(WCK_T)|^(RDQS_C)|^(WCK_C)|^(DQS[0-9]+P)|^(DQS[0-9]+N)|^([RDQS|WCK]+[0-9]+[T|C])`, 'ig');

  regArr.push(DQSRegObj);

  //find A0-AN(A0,A9)
  let ARegObj = new RegExp(`^(A[0-9]+)|^(CA[0-9]+)`, 'ig');
  regArr.push(ARegObj);

  //find A0-AN(A0,A9)/RAS/CAS/WE
  let BARegObj = new RegExp(`^(BA[0-9]+)|^(RAS)|^(CAS)|^(WE)`, 'ig');
  regArr.push(BARegObj);

  //find CLKP/CLKN
  let CLKRegObj = new RegExp(`^(CLKP)|^(CLKN)|^(CLKP[0-9]+)|^(CLKN[0-9]+)`, 'ig');
  regArr.push(CLKRegObj);

  const interfaceType = getInterfaceType(interfaceName);
  if (interfaceType === 'CLK' && !currentSignal.match(CLKRegObj) && !standardSignalName.match(CLKRegObj)) {
    return true;
  }

  for (let item of regArr) {
    if (standardSignalName.match(item) && currentSignal.match(item)) {
      return true;
    }
  }
}

export function getInterfaceType(name, isCLK) {
  if (!name || typeof (name) !== 'string') {
    return "";
  }
  //Byte
  let regByte = new RegExp(`^(Byte)`, 'ig');
  const byteWords = name.match(regByte);
  //CLK
  let regCLK = new RegExp(`^(CLK)`, 'ig');
  const clcWords = name.match(regCLK);

  if (isCLK) {
    let regCLK_CMD = new RegExp(`CMD`, 'ig');
    const clcCMDWords = name.match(regCLK_CMD);
    if (clcCMDWords) {
      return 'CMD';
    }

    let regCLK_ADR = new RegExp(`ADR`, 'ig');
    const clcADRWords = name.match(regCLK_ADR);
    if (clcADRWords) {
      return 'ADR';
    }
  }

  if (byteWords) {
    return 'BYTE';
  } else if (clcWords) {
    return 'CLK';
  }
}

function copyByteContent({ _Interfaces, _copyInterfaces, updatePkgInfo, projectType }) {
  const regWrite = new RegExp(`(Write)`, 'ig');
  const regRead = new RegExp(`(Read)`, 'ig');
  function getDQ4Reg(value) {
    let match = value.match(/\d+/);
    let num = match ? match[0] : 0;
    return num % 8 === 4 && num >= 4
  }

  _Interfaces.forEach((info) => {
    //check read/write
    const writeWords = info.name.match(regWrite);
    const readWords = info.name.match(regRead);

    //find copy interface
    let copyInterface = {};
    if (writeWords) {
      copyInterface = _copyInterfaces.find(item => item.name.match(regWrite));
    } else if (readWords) {
      copyInterface = _copyInterfaces.find(item => item.name.match(regRead));
    }

    if (copyInterface) {

      const copyComponents = copyInterface.Content.Components;
      info.Content.Components.forEach(comp => {
        //find controller/memory
        if (Types.includes(comp.type)) {
          const findComp = copyComponents.find(item => item.name === comp.name) || copyComponents.find(item => item.type === comp.type)
          if (findComp) {
            //copy comp model={libType,fileName,libraryId}
            comp.model = findComp.model ? JSON.parse(JSON.stringify(findComp.model)) : comp.model;
            if (findComp.amiModel) {
              comp.amiModel = JSON.parse(JSON.stringify(findComp.amiModel))
            }
            // copy corner
            comp.corner = findComp.corner ? findComp.corner : comp.corner;
            // copy dimmCardModel
            if (DIMM_DDR_TYPES.includes(projectType)) {
              comp.dimmCardModel = findComp.dimmCardModel ? JSON.parse(JSON.stringify(findComp.dimmCardModel)) : comp.dimmCardModel
            }
            const findPins = findComp.pins;

            //default pkg
            if (!comp.pkg) {
              comp.pkg = {
                type: 'None'
              }
            }

            //if libraryId !==prev libraryId, remove package model
            if (comp.pkg && comp.pkg.type === 'IBIS' && comp.model && comp.pkg.libraryId && comp.model.libraryId !== comp.pkg.libraryId) {
              comp.pkg = {
                type: 'None'
              }
            }

            if (updatePkgInfo && updatePkgInfo.type && updatePkgInfo.type === 'Layout' && findComp.pkg && findComp.pkg.type === 'Layout') {
              comp.pkg = { ...updatePkgInfo }
            }

            //copy deviceVcc=""
            comp.deviceVcc = findComp.deviceVcc ? findComp.deviceVcc : (comp.deviceVcc || '');

            if (findPins) {
              const copyDQPins = findPins.filter(pin => isDQ(pin.signal));
              const currentDQPins = comp.pins.filter(pin => isDQ(pin.signal));
              comp.pins.forEach((pinItem, pinIndex) => {
                let copyPin = null;
                //get signal type (not DQ) //DM,DQSP,DQSN
                const signalTypeFn = getSignalType(pinItem.signal);
                if (signalTypeFn) {
                  //find DM,DQSP,DQSN
                  const findTargetPin = findPins.find(pin => signalTypeFn(pin.signal));
                  if (findTargetPin) {
                    copyPin = findTargetPin;
                  } else if (isDM(pinItem.signal)) {
                    //if not find DM, copy DQ0
                    const findDQ = findPins.find(pin => isDQ(pin.signal));
                    copyPin = findDQ;
                  }
                }

                if (isDQ(pinItem.signal)) {
                  //find dq4, dq4 pinModel "nd_in" type !== other dq
                  if (getDQ4Reg(pinItem.signal)) {
                    const findDQ4 = findPins.find(pin => getDQ4Reg(pin.signal));
                    copyPin = findDQ4;
                  } else {
                    //find dq index in current pins DQ list
                    const currentDQIndex = currentDQPins.findIndex(pin => pin.pin && pinItem.pin === pin.pin);
                    //find dq is exist in copy pins DQ list
                    if (copyDQPins && copyDQPins[currentDQIndex]) {
                      copyPin = copyDQPins[currentDQIndex];
                    } else {
                      //if not find same Index DQ, copy DQ0
                      copyPin = currentDQPins && currentDQPins[0] ? currentDQPins[0] : null;
                    }
                  }
                }

                if (pinItem.usage === 'Driver') {
                  //copy powerOff= "1" / "0"
                  pinItem.powerOff = copyPin && copyPin.powerOff ? copyPin.powerOff : (pinItem.powerOff && "0");
                }

                //copy pin model={ modelType, enableVoltage, modelName, fileName, libType, libraryId }
                pinItem.model = copyPin && copyPin.model ? JSON.parse(JSON.stringify(copyPin.model)) : pinItem.model;
                //copy pinModels=[{ pinName, stimulus={}/"", voltage = "";type = ""}]
                pinItem.pinModels = copyPin && copyPin.pinModels ? JSON.parse(JSON.stringify(copyPin.pinModels)) : pinItem.pinModels;

                if (pinItem.model && pinItem.model.libType === IBIS_AMI) {
                  pinItem.amiModel = copyPin && copyPin.amiModel ? JSON.parse(JSON.stringify(copyPin.amiModel)) : pinItem.amiModel;
                  pinItem.amiModelUse = copyPin && copyPin.amiModelUse ? JSON.parse(JSON.stringify(copyPin.amiModelUse)) : pinItem.amiModelUse;
                  pinItem.amiODTValue = copyPin && copyPin.amiODTValue ? JSON.parse(JSON.stringify(copyPin.amiODTValue)) : pinItem.amiODTValue;
                  pinItem.amiModelInfo = copyPin && copyPin.amiModelInfo ? JSON.parse(JSON.stringify(copyPin.amiModelInfo)) : pinItem.amiModelInfo;
                  pinItem.amiParameters = copyPin && copyPin.amiParameters ? JSON.parse(JSON.stringify(copyPin.amiParameters)) : pinItem.amiParameters;
                } else {
                  pinItem.amiModel = null;
                  pinItem.amiModelUse = null;
                  pinItem.amiODTValue = null;
                  pinItem.amiModelInfo = {};
                  pinItem.amiParameters = [];
                }
                // advanced options 
                pinItem.ifIbisAdvancedOpen = copyPin && copyPin.ifIbisAdvancedOpen ? JSON.parse(JSON.stringify(copyPin.ifIbisAdvancedOpen)) : pinItem.ifIbisAdvancedOpen;
                pinItem.ibisAdvancedOptions = copyPin && copyPin.ibisAdvancedOptions ? JSON.parse(JSON.stringify(copyPin.ibisAdvancedOptions)) : pinItem.ibisAdvancedOptions;

                //if interface is dimm, copy stdModel - { modelType, enableVoltage, modelName, fileName, libType, libraryId }
                if (RDIMM_TYPES.includes(projectType)) {
                  pinItem.dimmStdModels = copyPin && copyPin.dimmStdModels ? JSON.parse(JSON.stringify(copyPin.dimmStdModels)) : pinItem.dimmStdModels
                } else if (DIMM_DDR_TYPES.includes(projectType)) {
                  pinItem.stdModel = copyPin && copyPin.stdModel ? JSON.parse(JSON.stringify(copyPin.stdModel)) : pinItem.stdModel
                }
                pinItem = updateInverter(pinItem, comp);
              })
            }
          }
        }
      })
    }
  });
  return _Interfaces;
}

function copyADRCMDContent({
  _Interfaces,
  _copyInterfaces,
  interfaceType,
  cmd_2t_mode,
  copy_cmd_2t_mode,
  updatePkgInfo,
  projectType }) {
  _Interfaces.forEach((info) => {
    const currentInterfaceType = getInterfaceType(info.name, true);
    //false -> only copy CLKP / CLKN
    //true -> copy CLKP / CLKN / A / BA ,... (all signals)
    const isCopyAllSignal = currentInterfaceType === interfaceType;
    //find copy interface
    let copyInterface = _copyInterfaces[0];
    let CLKPRegObj = new RegExp(`(CLKP)`, 'ig');
    let CLKNRegObj = new RegExp(`(CLKN)`, 'ig');
    let CLKPNumRegObj = new RegExp(`CLKP[0-9]+`, 'ig');
    let CLKNNumRegObj = new RegExp(`CLKN[0-9]+`, 'ig');
    if (copyInterface) {
      const copyComponents = copyInterface.Content.Components;
      let copyMemoryCompNames = [];
      info.Content.Components.forEach((comp, index) => {
        //find controller/memory
        if (Types.includes(comp.type)) {
          let findComp = copyComponents.find(item => item.type === comp.type && (comp.type === CONTROLLER || !copyMemoryCompNames.includes(item.name)));

          if (!findComp) {
            //if not find memory comp index, get first memory
            findComp = copyComponents.find(item => item.name === comp.name) || copyComponents.find(item => item.type === comp.type);
          }

          if (findComp) {
            copyMemoryCompNames.push(findComp.name);
            //copy comp model={libType,fileName,libraryId}
            comp.model = findComp.model ? JSON.parse(JSON.stringify(findComp.model)) : comp.model;
            if (findComp.amiModel) {
              comp.amiModel = JSON.parse(JSON.stringify(findComp.amiModel))
            }
            comp.corner = findComp.corner ? findComp.corner : comp.corner;
            if (DIMM_DDR_TYPES.includes(projectType)) {
              comp.dimmCardModel = findComp.dimmCardModel ? findComp.dimmCardModel : comp.dimmCardModel
            }

            //default pkg
            if (!comp.pkg) {
              comp.pkg = {
                type: 'None'
              }
            }

            //if libraryId !==prev libraryId, remove package model
            if (comp.pkg && comp.pkg.type === 'IBIS' && comp.model && comp.pkg.libraryId && comp.model.libraryId !== comp.pkg.libraryId) {
              comp.pkg = {
                type: 'None'
              }
            }

            // if pkg.type === 'Layout   package copy to other interface
            if (updatePkgInfo && updatePkgInfo.type && updatePkgInfo.type === 'Layout' && findComp.pkg && findComp.pkg.type === 'Layout') {
              comp.pkg = { ...updatePkgInfo }
            }

            //copy deviceVcc=""
            comp.deviceVcc = findComp.deviceVcc ? findComp.deviceVcc : (comp.deviceVcc || '');

            //Be copied pins
            const findPins = findComp.pins;
            //Be copied pins CLKPPin, CLKNPin
            const CLKPPin = findPins.find(item => item.signal.match(CLKPRegObj));
            const CLKNPin = findPins.find(item => item.signal.match(CLKNRegObj));

            if (findPins) {
              comp.pins.forEach((pinItem) => {
                let copyPin = null;
                if (pinItem.signal.match(CLKPNumRegObj)) {
                  copyPin = findPins.find(item => item.signal.match(CLKPNumRegObj));
                } else if (pinItem.signal.match(CLKNNumRegObj)) {
                  copyPin = findPins.find(item => item.signal.match(CLKNNumRegObj));
                } else if (pinItem.signal.match(CLKPRegObj)) {
                  copyPin = CLKPPin;
                } else if (pinItem.signal.match(CLKNRegObj)) {
                  copyPin = CLKNPin;
                }

                if (isCopyAllSignal && !copyPin) {
                  copyPin = findCopyPins(findPins, pinItem);
                }

                if (copyPin) {

                  if (pinItem.usage === 'Driver') {
                    //copy powerOff= "1" / "0"
                    pinItem.powerOff = copyPin && copyPin.powerOff ? copyPin.powerOff : (pinItem.powerOff && "0");
                  }

                  //copy pin model={ modelType, enableVoltage, modelName, fileName, libType, libraryId }
                  pinItem.model = copyPin.model ? JSON.parse(JSON.stringify(copyPin.model)) : pinItem.model;
                  //if interface is dimm, copy stdModel - { modelType, enableVoltage, modelName, fileName, libType, libraryId }
                  if (RDIMM_TYPES.includes(projectType)) {
                    pinItem.dimmStdModels = copyPin && copyPin.dimmStdModels ? JSON.parse(JSON.stringify(copyPin.dimmStdModels)) : pinItem.dimmStdModels
                  } else if (DIMM_DDR_TYPES.includes(projectType)) {
                    pinItem.stdModel = copyPin && copyPin.stdModel ? JSON.parse(JSON.stringify(copyPin.stdModel)) : pinItem.stdModel
                  }
                  //copy pinModels=[{ pinName, stimulus={}/"", voltage = "";type = ""}]
                  if (!pinItem.signal.match(no2TModeSignalsReg) && cmd_2t_mode !== copy_cmd_2t_mode && copyPin.pinModels) {
                    //if cmd_2t_mode diff, not need to copy nd_in model
                    const find_ND_IN = copyPin.pinModels.find(it => it.pinName === ND_IN);
                    for (let stItem of pinItem.pinModels) {
                      if (stItem.pinName === ND_IN) {
                        stItem = pinModelsCopy({ cmd_2t_mode, stItem, find_ND_IN });
                      } else {
                        const _find = copyPin.pinModels.find(it => it.pinName === stItem.pinName);
                        stItem = _find ? JSON.parse(JSON.stringify(_find)) : stItem;
                      }
                    }
                  } else {
                    pinItem.pinModels = copyPin.pinModels ? JSON.parse(JSON.stringify(copyPin.pinModels)) : pinItem.pinModels;
                  }
                  pinItem = updateInverter(pinItem, comp);
                }
              })
            }
          }
        }
      })
    }
  });
  return _Interfaces;
}

function updateInverter(pinItem, comp) {
  let _pinItem = { ...pinItem }
  // change stimulus = INVERTER
  const findIndex = pinItem.pinModels.findIndex(_pinModel => _pinModel.pinName === ND_IN && _pinModel.type === "INVERTER")
  if (findIndex > -1 && pinItem.pinModels[findIndex]) {
    const new_relative = pinItem.pinModels[findIndex].relative;
    const currentSignal = comp.pins.find(p => p.signal === new_relative.signal);
    if (currentSignal) {
      new_relative.component = comp.name;
      new_relative.pin = currentSignal.pin;
      pinItem.pinModels[findIndex].relative = new_relative;
    }
  }
  return _pinItem
}

//copy setup
//currentInterfaces
//copyInterfaces copy by
//interfaceType BYTE/CLK
export function getCopySetupContent({ currentInterfaces, copyInterfaces, interfaceType, cmd_2t_mode, copy_cmd_2t_mode, updatePkgInfo, projectType }) {
  let _Interfaces = JSON.parse(JSON.stringify(currentInterfaces));
  let _copyInterfaces = JSON.parse(JSON.stringify(copyInterfaces));

  if (interfaceType === 'BYTE') {
    _Interfaces = copyByteContent({
      _Interfaces,
      _copyInterfaces,
      updatePkgInfo,
      projectType
    });
  } else if (["ADR", "CMD"].includes(interfaceType)) {
    _Interfaces = copyADRCMDContent({
      _Interfaces,
      _copyInterfaces,
      interfaceType,
      cmd_2t_mode,
      copy_cmd_2t_mode,
      updatePkgInfo,
      projectType
    });
  }
  return _Interfaces;
}

function pinModelsCopy({ cmd_2t_mode, stItem, find_ND_IN }) {
  if (!find_ND_IN) {
    return stItem;
  }

  switch (cmd_2t_mode) {
    case true:
      if (!find_ND_IN.type.match("SDR")) {
        stItem = JSON.parse(JSON.stringify(find_ND_IN));
      }
      break;
    case false:
      if (!find_ND_IN.type.match("HDR")) {
        stItem = JSON.parse(JSON.stringify(find_ND_IN));
      }
      break;
    default: break;
  }
  return stItem;
}

/* find pin by signal name,
if not find same signal name and signal is [A[0-9],BA[0-9],CA[0-9]] , find first pin */
function findCopyPins(findPins, pinItem) {
  //find pin by pin signal name
  let findPin = findPins.find(item => item.signal === pinItem.signal);
  if (!findPin) {
    let _reg = new RegExp(`^(A[0-9]+)|^(CA[0-9]+)|^(BA[0-9]+)|^(CS[0-9]+)|^(CKE[0-9]+)`, 'ig');
    const value = pinItem.signal.match(_reg)
    if (value && value[0] && pinItem.channelKeyword) {
      const _channelKeyword = findPins[0].channelKeyword;
      if (_channelKeyword) {
        const rule = new RegExp(`${value[0]}${_channelKeyword}`, 'ig');
        findPin = findPins.find(item => item.signal.match(rule));
      }
    } else if (value && value[0]) {
      // preLayout donot have channelKeyword
      const rule = new RegExp(`${value[0]}+[A-Z]`, 'ig');
      findPin = findPins.find(item => item.signal.match(rule));
    }

    if (!findPin) {
      //find A0-AN(A0,A9) BA ,CA
      let reg = new RegExp(`^(A[0-9]+)|^(CA[0-9]+)|^(BA[0-9]+)`, 'ig');
      findPin = pinItem.signal.match(reg) ? findPins.find(item => item.signal.match(reg)) : null;
    }

  }
  return findPin;
}

function isDQ(signal) {
  const DQReg = /^(DQ[0-9]+)/ig;
  if (signal.match(DQReg)) {
    return true;
  }
}

function isDQSP(signal) {
  const DQSPReg = /^(DQSP)|^(RDQS_T)|(^DQS[0-9]+P)|^(RDQS[0-9]+T)/ig;
  if (signal.match(DQSPReg)) {
    return true;
  }
}

function isDQSN(signal) {
  const DQSNReg = /^(DQSN)|^(RDQS_C)|(^DQS[0-9]+N)|^(RDQS[0-9]+C)/ig;
  if (signal.match(DQSNReg)) {
    return true;
  }
}

function isWCKP(signal) {
  const WCKReg = /^(WCK_T)|^(WCK[0-9]+T)/ig;
  if (signal.match(WCKReg)) {
    return true;
  }
}

function isWCKN(signal) {
  const WCKReg = /^(WCK_C)|^(WCK[0-9]+C)/ig;
  if (signal.match(WCKReg)) {
    return true;
  }
}

function isDM(signal) {
  const DMReg = /^(DM)/ig;
  if (signal.match(DMReg)) {
    return true;
  }
}

function getSignalType(signal) {
  if (isDQSP(signal)) {
    return isDQSP;
  }

  if (isDQSN(signal)) {
    return isDQSN;
  }

  if (isWCKP(signal)) {
    return isWCKP;
  }

  if (isWCKN(signal)) {
    return isWCKN;
  }

  if (isDM(signal)) {
    return isDM;
  }
}

//vtt net default voltage
export function getVTTNetVoltage(projectType) {
  let value = "";
  switch (projectType) {
    case DDR3:
      value = '0.75';
      break;
    case DDR3L:
      value = '0.675';
      break;
    case DDR4:
      value = '0.6';
      break;
    case DDR4L:
      value = '0.525';
      break;
    case LPDDR4:
      value = '0.55';
      break;
    case DDR5:
    case DDR5_RDIMM:
    case DDR5_SODIMM:
    case DDR5_UDIMM:
    case DDR5_RDIMM_CARD:
    case DDR5_SODIMM_CARD:
    case DDR5_UDIMM_CARD:
    case DDR5_RDIMM_X4:
      value = '0.55';
      break;
    case LPDDR5:
      value = '0.525';
      break;
    default:
      value = '';
      break;
  };
  return value;
}

//power net default voltage
export function getPowerVoltage(projectType) {
  let value = "";
  switch (projectType) {
    case DDR3:
      value = '1.5';
      break;
    case DDR3L:
      value = '1.35';
      break;
    case DDR4:
      value = '1.2';
      break;
    case DDR4L:
      value = '1.05';
      break;
    case LPDDR4:
      value = '1.1';
      break;
    case DDR5:
    case DDR5_RDIMM:
    case DDR5_SODIMM:
    case DDR5_UDIMM:
    case DDR5_RDIMM_CARD:
    case DDR5_SODIMM_CARD:
    case DDR5_UDIMM_CARD:
    case DDR5_RDIMM_X4:
      value = '1.1';
      break;
    case LPDDR5:
      value = '1.05';
      break;
    default:
      value = '';
      break;
  };
  return value;
}

//get pinModels=>nd_in default type
export function getStimulusDefaultValue({ signalName, interfaceName, cmd_2t_mode, projectType }) {
  const clkStimulusType = cmd_2t_mode ? "PRBS-HDR-80" : "PRBS-SDR-80";
  let regArr = [];
  //find DQ0-DQN(DQ0,DQ9)  //PRBS-DDR-80
  //DQ4==> PRBS-DDR-17
  const DefaultTabs = { tabs: ["7", "6"] }
  const DQRegObj = new RegExp(`^(DQ[0-9]+)`, 'ig');
  regArr.push({ reg: DQRegObj, value: { type: "PRBS-DDR-80", ...DefaultTabs } });

  //find DM  //PRBS-SDR-80
  const SDRRegObj = new RegExp(`^(DM)`, 'ig');
  regArr.push({ reg: SDRRegObj, value: { type: "PRBS-DDR-80", ...DefaultTabs } });

  //find DQSP/CLKP //CLK+
  const CLKPRegObj = new RegExp(`^(DQSP)|^(CLKP)|^(WCK_T)|^(WCK[0-9]+T)|^(WCKP)|(DQS_T)|(DQS[0-9]+T)|^(CLKP[0-9]+)|^(DQS[0-9]+P)`, 'ig');
  regArr.push({ reg: CLKPRegObj, value: { type: "CLK+" } });

  //find CLKN/DQSN //CLK-
  const CLKNRegObj = new RegExp(`^(DQSN)|^(CLKN)|^(WCK_C)|^(WCK[0-9]+C)|^(WCKN)|(DQS_C)|(DQS[0-9]+C)|^(CLKN[0-9]+)|^(DQS[0-9]+N)`, 'ig');
  regArr.push({ reg: CLKNRegObj, value: { type: "CLK-" } });

  //DQ4==> PRBS-DDR-17
  const DQ4RegObj = new RegExp(`^(DQ4)`, 'ig');

  const interfaceType = getInterfaceType(interfaceName, true);
  if ((interfaceType === 'CMD' || interfaceType === 'ADR') && !signalName.match(CLKPRegObj) && !signalName.match(CLKNRegObj)) {
    if (!signalName.match(no2TModeSignalsReg) && _2TModeDDRTypes.includes(projectType)) {
      return { type: clkStimulusType, ...DefaultTabs };
    } else {
      return projectType === LPDDR5 ? { type: "PRBS-DDR-80", ...DefaultTabs } : { type: "PRBS-SDR-80", ...DefaultTabs };
    }
  }

  if (signalName.match(DQ4RegObj)) {
    return { type: "PRBS-DDR-17", ...DefaultTabs };
  } else {
    for (let item of regArr) {
      if (signalName.match(item.reg)) {
        return item.value;
      }
    }
  }
  return {}
}

//copy component model  model={libType,fileName,libraryId}
export function copyComponentModel({ currentInterfaces, libFileInfo, compType, partName }) {
  let _Interfaces = JSON.parse(JSON.stringify(currentInterfaces));
  let copyStatus = false;
  if (partName && compType && libFileInfo) {

    _Interfaces.forEach((info) => {

      info.Content.Components.forEach(comp => {
        //find controller/memory
        if (Types.includes(comp.type) && compType === comp.type && partName === comp.part) {

          if (!comp.model || !comp.model.fileName) {
            //copy comp model={libType,fileName,libraryId}
            if (libFileInfo.libType === IBIS_AMI) {
              comp.amiModel = {
                libType: libFileInfo.libType,
                fileName: libFileInfo.fileName,
                libraryId: libFileInfo.libraryId,
                folder: libFileInfo.folder
              }
            } else {
              comp.model = {
                libType: libFileInfo.libType,
                fileName: libFileInfo.fileName,
                libraryId: libFileInfo.libraryId,
              }
              if (libFileInfo.folder) {
                comp.model.folder = libFileInfo.folder;
              }
            }

            copyStatus = true;

            comp.pins.forEach((pinItem) => {
              //model is not exist
              if (!pinItem.model || (!pinItem.model.fileName || !pinItem.model.libraryId)) {
                //copy pin model={ modelType, enableVoltage, modelName, fileName, libType, libraryId }
                if (libFileInfo.folder && libFileInfo.libType !== IBIS_AMI) {
                  pinItem.model = new PinSpiceModel({
                    libType: libFileInfo.libType,
                    fileName: libFileInfo.fileName,
                    libraryId: libFileInfo.libraryId,
                    folder: libFileInfo.folder
                  });
                } else {
                  pinItem.model = new Model({
                    fileName: libFileInfo.fileName,
                    libType: libFileInfo.libType,
                    libraryId: libFileInfo.libraryId,
                    folder: libFileInfo.folder
                  });
                }
              }
            })
          }
        }
      })
    });
  }
  return { Interfaces: _Interfaces, copyStatus };
}

//get ibis model name key by signal
export function getModelNameRegBySignal(signalName, interfaceName) {
  const interfaceType = getInterfaceType(interfaceName, true);
  const signalRegObj = getDDRSignalType(signalName) || {};
  if ((interfaceType === 'CMD' || interfaceType === 'ADR') && !signalName.match(signalRegObj.reg)) {
    return interfaceType;
  }
  return signalRegObj.type;
}

//library file exist check in interfaces
export function checkLibraryInInterface({ Interfaces, libraryObj }) {
  const { ibisList, spiceList, vectorList, pkgSpiceList, pkgTouchstoneList } = libraryObj;
  let _Interfaces = JSON.parse(JSON.stringify(Interfaces));
  _Interfaces.forEach(item => {

    let components = item.Content.Components ? [...item.Content.Components] : [];

    components.forEach(comp => {

      //check ibis/spice model
      if (Types.includes(comp.type)) {
        if (comp.model && comp.model.fileName) {
          let modelIndex = comp.model.libType === 'IBIS' ?
            ibisList.findIndex(ibis => ibis.id === comp.model.libraryId && ibis.fileName === comp.model.fileName)
            : spiceList.findIndex(sp => sp.id === comp.model.libraryId && sp.fileName === comp.model.fileName)
          if (modelIndex < 0) {
            let findModel = comp.model.libType === 'IBIS' ?
              ibisList.find(ibis => ibis.fileName === comp.model.fileName)
              : spiceList.find(sp => sp.fileName === comp.model.fileName);
            if (findModel) {
              comp.model.libraryId = findModel.id;
            }
          }
        }

        if (comp.pkg) {
          if (comp.pkg.type === 'IBIS') {
            let modelIndex = ibisList.findIndex(ibis => ibis.id === comp.pkg.libraryId);
            if (modelIndex < 0) {
              let findModel = comp.model && comp.model.libType === 'IBIS' && comp.model.libraryId ?
                ibisList.find(ibis => ibis.fileName === comp.model.fileName) : null
              if (findModel) {
                comp.pkg.libraryId = findModel.id;
              }
            }
          } else if (Array.isArray(comp.pkg.files)) {
            comp.pkg.files.forEach(file => {
              if (file.fileName) {
                const libraryList = file.type === 'Touchstone' ? pkgTouchstoneList : pkgSpiceList;
                let modelIndex = libraryList.findIndex(it => it.id === file.libraryId);
                const libraryId = file.libraryId;
                if (modelIndex < 0) {
                  let findModel = libraryList.find(it => it.fileName === file.fileName);
                  if (findModel) {
                    file.libraryId = findModel.id;
                  }
                  //delete subckt and selected nodes
                  file.subckt = "";
                  comp.pkg.pairs.forEach(item => {
                    if (item.libraryId === libraryId) {
                      item.libraryId = "";
                      item.node = "";
                      item.subckt = "";
                    }
                  });
                }
              }
            });
          }
        }

        comp.pins.forEach(pinItem => {

          const model = pinItem.model;
          const pinModels = pinItem.pinModels;

          if (model && model.fileName) {
            let modelIndex = model.libType === 'IBIS' ?
              ibisList.findIndex(ibis => ibis.id === model.libraryId && ibis.fileName === model.fileName)
              : spiceList.findIndex(sp => sp.id === model.libraryId && sp.fileName === model.fileName)
            if (modelIndex < 0) {
              let findModel = model.libType === 'IBIS' ?
                ibisList.find(ibis => ibis.fileName === model.fileName)
                : spiceList.find(sp => sp.fileName === model.fileName);
              if (findModel) {
                pinItem.model.libraryId = findModel.id;
              }
            }

            if (pinModels && pinModels.length > 0) {
              const ND_IN = pinModels.find(item => item.pinName === "nd_in");
              let inputStimulus = null, vector = null;
              inputStimulus = ND_IN.type;
              vector = ND_IN;
              if (inputStimulus === 'VEC') {
                if (vector.stimulus && vector.stimulus.fileName) {
                  const stimulusModel = vector.stimulus;
                  if (stimulusModel) {
                    let modelExist = vectorList.find(vec => vec.id === stimulusModel.libraryId && vec.name === stimulusModel.fileName);
                    if (!modelExist) {
                      let findVector = vectorList.find(vec => (vec.name === stimulusModel.fileName) || (vec.id === stimulusModel.libraryId));
                      if (findVector) {
                        const ND_INIndex = pinItem.pinModels.findIndex(item => item.pinName === "nd_in");
                        pinItem.pinModels[ND_INIndex].stimulus.libraryId = findVector.id;
                        pinItem.pinModels[ND_INIndex].stimulus.fileName = findVector.name;
                      }
                    }
                  }
                }
              }
            }
          }
        })
      }
    })

    item.Content.Components = JSON.parse(JSON.stringify(components));
  })
  return _Interfaces
}

//update current open verification library file list
export function getLibraryFileList(info) {
  let libraryFileList = [];//[{name,id}]
  if (!info) {
    return libraryFileList;
  }

  if (info.Components && info.Components.length > 0) {
    const components = JSON.parse(JSON.stringify(info.Components));
    components.forEach(comp => {
      if (Types.includes(comp.type)) {
        if (comp.model && comp.model.fileName) {
          let findIndex = libraryFileList.findIndex(item => item.name === comp.model.fileName);
          if (findIndex === -1) {
            libraryFileList.push({
              name: comp.model.fileName,
              id: comp.model.libraryId,
              type: comp.model.libType,
              folder: comp.model.folder
            })
          }
        }

        if (comp.amiModel && comp.amiModel.fileName) {
          let findIndex = libraryFileList.findIndex(item => item.name === comp.amiModel.fileName);
          if (findIndex === -1) {
            libraryFileList.push({
              name: comp.amiModel.fileName,
              id: comp.amiModel.libraryId,
              type: comp.amiModel.libType,
              folder: comp.amiModel.folder
            })
          }
        }

        const pkgFiles = comp.pkg && comp.pkg.files ? comp.pkg.files : [];
        if (pkgFiles && pkgFiles.length > 0) {
          pkgFiles.forEach(file => {
            let findIndex = libraryFileList.findIndex(item => item.name === file.fileName);
            if (findIndex === -1) {
              libraryFileList.push({
                name: file.fileName,
                id: file.libraryId,
                type: file.type === 'Touchstone' ? PKG_TOUCHSTONE : PKG_SPICE
              })
            }
          })
        }

        comp.pins.forEach(pinItem => {
          if (pinItem.driver) {
            const model = pinItem.driver.model;
            const pinModels = pinItem.driver.pinModels;
            if (model && model.fileName) {
              let findIndex = libraryFileList.findIndex(item => item.name === model.fileName);
              if (findIndex === -1) {
                libraryFileList.push({
                  name: model.fileName,
                  id: model.libraryId,
                  type: model.libType,
                  folder: model.folder
                })
              }
            }

            if (pinModels && pinModels.length > 0) {
              const ND_IN = pinModels.find(item => item.pinName === 'nd_in');

              if (ND_IN && ND_IN.type === 'VEC') {
                const stimulus = ND_IN.stimulus;
                let findIndex = libraryFileList.findIndex(item => stimulus && item.name === stimulus.fileName);
                if (findIndex === -1) {
                  libraryFileList.push({
                    name: stimulus.fileName,
                    id: stimulus.libraryId,
                    type: 'vector'
                  })
                }
              }
            }
          }

          if (pinItem.receiver) {
            const model = pinItem.receiver.model;
            if (model && model.fileName) {
              let findIndex = libraryFileList.findIndex(item => item.name === model.fileName);
              if (findIndex === -1) {
                libraryFileList.push({
                  name: model.fileName,
                  id: model.libraryId,
                  type: model.libType,
                  folder: model.folder
                })
              }
            }
          }
        })
      }
    })
  }

  return libraryFileList;
}

//copy pkg in model libraryId===pkg.libraryId interfaces
export function copyComponentPackageModel({ currentInterfaces, pkg, component, compType, partName }) {
  let _Interfaces = JSON.parse(JSON.stringify(currentInterfaces));
  let copyStatus = false;
  if (pkg && pkg.type) {
    _Interfaces.forEach((info) => {
      for (let i = 0; i < info.Content.Components.length; i++) {
        let comp = info.Content.Components[i];
        //type not exist or type is not Memory and Controller
        if (!comp.type || !Types.includes(comp.type)) {
          continue;
        }
        //find controller/memory
        if (comp.name === component || (compType === comp.type && partName === comp.part)) {

          if ((!comp.pkg || comp.pkg.type === 'None') && comp.model && comp.model.libraryId && comp.model.libraryId === pkg.libraryId) {
            copyStatus = true;
            info.Content.Components[i].pkg = JSON.parse(JSON.stringify(pkg));
          }
        }
      }
    });
  }
  return { Interfaces: _Interfaces, copyStatus };
}

export function findVTTNets(components, netsList) {
  // find power nets
  const _resFilter = components.filter(item => item.type === 'Res');
  const findPowerByRes = findPowerNetsByRes(_resFilter, netsList);
  const { comps } = findPowerByRes;
  comps.forEach(comp => {

    const index = components.findIndex(item => item.name === comp.name);
    if (index > -1) {
      components[index] = { ...comp };
    }
  });

  let newPowerNets = [];
  for (let comp of components) {
    if (comp.type !== 'Res') {
      continue;
    };
    for (let pin of comp.pins) {
      if (!pin.signal) {
        newPowerNets.push(pin.net);
      }
    }
  };

  newPowerNets = [...new Set([...newPowerNets])];
  const VTTNets = newPowerNets ? JSON.parse(JSON.stringify(newPowerNets)) : [];
  return VTTNets;
}

/* find pwr nets and gnd nets (abandon)*/
// export function getPowerGndNetsByPinLength({ netsList, projectType, componentList, pcbId }) {
//   let pwrNetList = [], gndNetList = [];
//   let _comps = JSON.parse(JSON.stringify(componentList));
//   const filterNoneNets = Array.isArray(netsList) ? netsList.filter(item => item.mName !== 'NONET') : [];

//   _comps.forEach(item => {
//     let pwrNets = [], gndNets = [];
//     //item { name, part, pin: pinLength }
//     //find pwrPins and gndPins by DDR type and pin length
//     const { pwrPinList, gndPinList } = getPinsByTypeAndPinLength(projectType, item.pin);
//     const compName = item.name;
//     filterNoneNets.forEach(netList => {
//       Array.isArray(netList.mPinList) && netList.mPinList.forEach(pin => {
//         //find pwr net
//         for (let pinName of pwrPinList) {
//           if (pinName && pin.mPinNum === pinName && compName === pin.mCompName) {
//             pwrNets.push(netList);
//             break;
//           }
//         }

//         //find gnd net
//         for (let pinName of gndPinList) {
//           if (pinName && pin.mPinNum === pinName && compName === pin.mCompName) {
//             gndNets.push(netList);
//             break;
//           }
//         }
//       })
//     })
//     pwrNetList = pwrNets ? [...pwrNetList, ...pwrNets.map(pwr => pwr.mName)] : pwrNetList;
//     gndNetList = gndNets ? [...gndNetList, ...gndNets.map(gnd => gnd.mName)] : gndNetList;
//   })
//   pwrNetList = [...new Set([...pwrNetList])];
//   gndNetList = [...new Set([...gndNetList])];
//   return { pwrNetList, gndNetList }
// }

// //find pin name list by ddr type and pin length list
// function getPinsByTypeAndPinLength(type, pinLength) {
//   let pwrPinList = [], gndPinList = [];
//   switch (pinLength) {
//     case 78:
//       if (type === DDR3 || type === DDR3L || type === NVDDR3) {
//         pwrPinList = [...DDR3_78_pwrPinList];
//         gndPinList = [...DDR3_78_gndPinList];
//       } else if (type === DDR4 || type === DDR4L || type === DDR4MR) {
//         pwrPinList = [...DDR4_78_pwrPinList];
//         gndPinList = [...DDR4_78_gndPinList];
//       } else if (type === DDR5) {
//         pwrPinList = [...DDR5_78_pwrPinList];
//         gndPinList = [...DDR5_78_gndPinList];
//       }
//       break;
//     case 96:
//       if (type === DDR3 || type === DDR3L || type === NVDDR3) {
//         pwrPinList = [...DDR3_96_pwrPinList];
//         gndPinList = [...DDR3_96_gndPinList];
//       } else if (type === DDR4 || type === DDR4L || type === DDR4MR) {
//         pwrPinList = [...DDR4_96_pwrPinList];
//         gndPinList = [...DDR4_96_gndPinList];
//       } else if (type === DDR5) {
//         pwrPinList = [...DDR5_96_pwrPinList];
//         gndPinList = [...DDR5_96_gndPinList];
//       }
//       break;
//     case 200:
//       if (type === LPDDR4) {
//         pwrPinList = [...LPDDR4_200_pwrPinList];
//         gndPinList = [...LPDDR4_200_gndPinList];
//       }
//       break;
//     case 272:
//       if (type === LPDDR4) {
//         pwrPinList = [...LPDDR4_272_pwrPinList];
//         gndPinList = [...LPDDR4_272_gndPinList];
//       }
//       break;
//     case 366:
//       if (type === LPDDR4) {
//         pwrPinList = [...LPDDR4_366_pwrPinList];
//         gndPinList = [...LPDDR4_366_gndPinList];
//       }
//       break;
//     case 432:
//       if (type === LPDDR4) {
//         pwrPinList = [...LPDDR4_432_pwrPinList];
//         gndPinList = [...LPDDR4_432_gndPinList];
//       }
//       break;
//     default: break;
//   }
//   return { pwrPinList, gndPinList };
// }


export function addPRBSTabs(Interfaces) {
  let newInterfaces = [...Interfaces];
  newInterfaces.forEach(item => {
    item.Content.Components.forEach(comp => {
      comp.pins.forEach(pin => {
        if (pin.usage === 'Driver' && pin.pinModels) {
          const findPin = pin.pinModels.find(m => m.pinName === "nd_in");
          if (findPin && prbsTypes.find(type => findPin.type.match(type))) {
            findPin.tabs = ["7", "6"]
          }
        }
      })
    })
  })
  return newInterfaces;
}

//DDR5 DIMM interface: DIMM components add "active" field
export function addActiveToDIMMComponents(Interfaces) {
  let newInterfaces = [...Interfaces];
  if (!newInterfaces.length) {
    return newInterfaces;
  }

  const activeDIMM = newInterfaces[0].Content && newInterfaces[0].Content.Components ? newInterfaces[0].Content.Components.find(item => item.type === MEMORY) : null;
  if (!activeDIMM) {
    return newInterfaces;
  }
  const activeDIMMComp = activeDIMM.name;
  newInterfaces.forEach(item => {
    item.Content.Components.forEach(comp => {
      if (comp.type === MEMORY) {
        //DDR5 DIMM interface: DIMM components add "active" field
        comp.active = activeDIMMComp === comp.name ? true : false;
      }
    })
  })
  return newInterfaces;
}

export async function changeStdModelToDimmStdModels(Interfaces) {
  if (!Interfaces || !Interfaces.length) {
    return Interfaces;
  }

  let newInterfaces = [...Interfaces];

  const interfaceType = getInterfaceType(Interfaces[0].name);

  if (interfaceType !== "CLK") {
    //get dimm model ranks - promise
    await getDimmModels(newInterfaces);
  }

  newInterfaces.forEach(async item => {
    item.Content.Components.forEach(async comp => {
      if (comp.type === MEMORY) {
        let rank = null
        if (comp.dimmCardModel && comp.dimmCardModel.type !== NONE && interfaceType !== "CLK") {
          if (comp.dimmCardModel.id && comp.dimmCardModel.type) {
            rank = await rankConstructor.getRankValues({ id: comp.dimmCardModel.id, type: comp.dimmCardModel.type })
          }
          rank = isNaN(rank) || rank < 1 ? 1 : rank;
          comp.pins.forEach(pin => {
            let dimmStdModels = [];
            for (let i = 0; i < rank; i++) {
              dimmStdModels.push({ rankId: i + 1, ...(pin.stdModel || new Model({})) })
            }
            delete pin.stdModel;
            pin.dimmStdModels = dimmStdModels;
          })
        } else {
          comp.pins.forEach(pin => {
            const dimmStdModels = []
            dimmStdModels.push({ rankId: null, ...(pin.stdModel || new Model({})) });
            delete pin.stdModel;
            pin.dimmStdModels = dimmStdModels;
          })
        }
      }
    })
  })
  return Promise.all(newInterfaces);
}

function getDimmModels(newInterfaces) {
  let dimmModels = {}
  for (let item of newInterfaces) {
    for (let comp of item.Content.Components) {
      if (comp.dimmCardModel && comp.dimmCardModel.type !== NONE && !dimmModels[comp.dimmCardModel.id]) {
        dimmModels[comp.dimmCardModel.id] = { id: comp.dimmCardModel.id, type: comp.dimmCardModel.type }
      }
    }
  }
  const models = Object.keys(dimmModels).map(item => dimmModels[item]);

  const promiseList = models.map((item, index) => {
    return rankConstructor.getRankValues({ id: item.id, type: item.type })
  })
  return Promise.all(promiseList);

}

export function judgeDimmActiveExist(Interfaces) {
  if (!Interfaces || !Interfaces.length) {
    return true;
  }
  let newInterfaces = [...Interfaces];


  const dimmComps = newInterfaces[0].Content && newInterfaces[0].Content.Components ? newInterfaces[0].Content.Components.filter(item => item.type === MEMORY) : [];
  if (dimmComps.length < 2) {
    return true;
  }
  const findActive = dimmComps.find(item => !!item.active);
  if (!findActive) {
    return false;
  }
  return true;
}

export function addActiveToLPDDR4MemoryComponents(Interfaces, saveToServer) {
  let newInterfaces = [...Interfaces], _saveToServer = saveToServer;

  if (!newInterfaces.length || !newInterfaces[0] || !newInterfaces[0].Content || !newInterfaces[0].Content.Components || !newInterfaces[0].Content.Components.length) {
    return { newInterfaces, saveToServer: _saveToServer };
  }
  const activeCompList = newInterfaces[0].Content.Components.filter(item => item.type === MEMORY);
  if (activeCompList.length < 2) {
    return { newInterfaces, saveToServer: _saveToServer };
  }

  const activeDIMMComp = activeCompList[0].name;
  newInterfaces.forEach(item => {
    item.Content.Components.forEach(comp => {
      if (comp.type === MEMORY) {
        //LPDDR4 interface: If the memory components are greater than one, add "active" field
        comp.active = activeDIMMComp === comp.name ? true : false;
      }
    })
  })
  _saveToServer = true;
  return { newInterfaces, saveToServer: _saveToServer };
}

export const CROSSTALK_RISE_TIME = "crosstalkRiseTime",
  CROSSTALK_FALL_TIME = "crosstalkFallTime",
  VOLTAGE_PULSE = "voltagePulse";
export function getDefaultWaveformConfig({ waveformConfig, ddrType, isPrevCase, includesUnit = false }) {
  let _waveformConfig = { ...waveformConfig || {} };
  if (!waveformConfig || !Object.keys(waveformConfig).length) {
    _waveformConfig = new WaveformConfig({ ddrType, isPrevCase });
  }
  if (!includesUnit) {
    return _waveformConfig;
  }
  let unitObj = {
    [`${CROSSTALK_RISE_TIME}Unit`]: _waveformConfig[CROSSTALK_RISE_TIME].match("ps") ? "ps" : "ratio",
    [`${CROSSTALK_FALL_TIME}Unit`]: _waveformConfig[CROSSTALK_FALL_TIME].match("ps") ? "ps" : "ratio",
  }
  return { ..._waveformConfig, ...unitObj }
}



const list = ["Signal", "DM", "CKE", "CS", "WCK", "DQSN", "DQSP", "DQS", "CA", "CK", "CLKN", "CLKP", "CLK", "DQ"];

function sortFunction(aInfo, bInfo, key) {
  function sortIndex(a, b) {
    const aMatch = a.match(/\d+/), bMatch = b.match(/\d+/);
    if (aMatch && aMatch[0] && bMatch && bMatch[0]) {
      return parseInt(aMatch[0]) - parseInt(bMatch[0]);
    } else if (aMatch && aMatch[0]) {
      return -1
    } else if (bMatch && bMatch[0]) {
      return 1
    }
    return a.localeCompare(b)
  }

  let a = key ? aInfo[key] : aInfo, b = key ? bInfo[key] : bInfo;
  const aIndex = list.findIndex(it => a && a.includes(it))
  const bIndex = list.findIndex(it => b && b.includes(it))
  if (aIndex > -1 && bIndex > -1) {
    if (aIndex === bIndex) {
      return sortIndex(a, b)
    }
    return bIndex - aIndex
  } else if (aIndex > -1) {
    return -1;
  } else if (bIndex > -1) {
    return 0
  }

  const aSplitNum = a.match(/([a-zA-Z]+)(\d+)(.*)/), bSplitNum = b.match(/([a-zA-Z]+)(\d+)(.*)/);
  if (aSplitNum && bSplitNum && aSplitNum.length > 2 && bSplitNum.length > 2) {
    if (aSplitNum[1] === bSplitNum[1]) {
      return aSplitNum[2] - bSplitNum[2];
    } else {
      return aSplitNum[1].localeCompare(bSplitNum[1])
    }
  } else {
    return a.localeCompare(b)
  }
}

export function signalGroupSortFun(aInfo, bInfo, key) {
  let a = key ? aInfo[key] : aInfo, b = key ? bInfo[key] : bInfo;
  let order = ['Byte', 'DM', "CLK", "ADR", "CMD", 'CA_A', "CA_B", 'CA'];
  if (!a || !b) {
    if (aInfo.name && bInfo.name) {
      return sortFunction(aInfo, bInfo, "name")
    }
    return 0
  }

  const aSplitChannel = a.match(/_[a-zA-Z]$/)
  const bSplitChannel = b.match(/_[a-zA-Z]$/)
  if (aSplitChannel && aSplitChannel.length && bSplitChannel && bSplitChannel.length && aSplitChannel[0] !== bSplitChannel[0]) {
    return aSplitChannel[0].localeCompare(bSplitChannel[0])
  }

  const aSplitNum = a.match(/([a-zA-Z]+)(\d+)(.*)/);
  const bSplitNum = b.match(/([a-zA-Z]+)(\d+)(.*)/);
  if (a === b) {
    return sortFunction(aInfo, bInfo, "name")
  }

  function getIndex(aInfo, bInfo, aIndex, bIndex) {
    if (aIndex > -1 && bIndex > -1) {
      return aIndex - bIndex;
    } else if (aIndex > -1) {
      return -1
    } else if (bIndex > -1) {
      return 1
    } else {
      return aInfo.localeCompare(bInfo);
    }
  }
  if (aSplitNum && bSplitNum && aSplitNum.length > 2 && bSplitNum.length > 2) {
    if (aSplitNum[1] === bSplitNum[1]) {
      if (aSplitNum[2] === bSplitNum[2]) {
        if (aSplitNum[3] && bSplitNum[3]) {
          return aSplitNum[3].localeCompare(bSplitNum[3]);
        } else if (aSplitNum[3]) {
          return 0
        } else if (bSplitNum[3]) {
          return -1
        }
        return 0
      } else {
        return aSplitNum[2] - bSplitNum[2];
      }

    }

    const aIndex = order.findIndex(item => aSplitNum[1].includes(item));
    const bIndex = order.findIndex(item => bSplitNum[1].includes(item));
    return getIndex(aSplitNum[1], bSplitNum[1], aIndex, bIndex)
  } else if (aSplitNum && aSplitNum.length > 2) {
    const aIndex = order.findIndex(item => aSplitNum[1].includes(item));
    const bIndex = order.findIndex(item => b.includes(item));
    if (aIndex === bIndex) {
      return 1
    }
    return getIndex(aSplitNum[1], b, aIndex, bIndex)
  } else if (bSplitNum && bSplitNum.length > 2) {
    const aIndex = order.findIndex(item => a.includes(item));
    const bIndex = order.findIndex(item => bSplitNum[1].includes(item));
    if (aIndex === bIndex) {
      return -1;
    }
    return getIndex(a, bSplitNum[1], aIndex, bIndex)
  } else {
    const aIndex = order.findIndex(item => a.includes(item));
    const bIndex = order.findIndex(item => b.includes(item));
    return getIndex(a, b, aIndex, bIndex)
  }
}

export function sortPinOrPinSetUpFormSignals(a, b, list, type) {
  let aKey = "", bKey = "";
  if (type === "pin") {
    aKey = `${a.signalGroup}-${a.signal}`;
    bKey = `${b.signalGroup}-${b.signal}`;
  } else {
    aKey = a.positive && a.positive.pin ? `${a.positive.component}-${a.positive.pin}` : "";
    bKey = b.positive && b.positive.pin ? `${b.positive.component}-${b.positive.pin}` : "";
  }
  const aIndex = list.findIndex(it => aKey === it)
  const bIndex = list.findIndex(it => bKey === it)
  if (aIndex > -1 && bIndex > -1) {
    return aIndex - bIndex
  } else if (aIndex > -1) {
    return -1;
  } else if (bIndex > -1) {
    return 1
  }
  return 0;
}

export function sortContentFromCompType(components, port_setups, Ports_generate_setup_list) {

  function typeSort(a, b, order, key) {
    const aValue = a[key] ? a[key] : "";
    const bValue = b[key] ? b[key] : "";

    return order.indexOf(aValue) - order.indexOf(bValue);
  }
  let order = [DIE, BGA]
  const sortComponents = components.sort((a, b) => { return typeSort(a, b, order, 'type') })
  const compName = sortComponents.map(item => item.name);

  const sortPorts_generate_setup_list = Ports_generate_setup_list.sort((a, b) => { return typeSort(a, b, compName, 'component') })

  const sortPort_setups = port_setups.sort((a, b) => {
    const aValue = a.positive && a.positive.component ? a.positive.component : "";
    const bValue = b.positive && b.positive.component ? b.positive.component : "";
    return compName.indexOf(aValue) - compName.indexOf(bValue);
  })

  return {
    Components: sortComponents,
    Port_setups: sortPort_setups,
    Ports_generate_setup_list: sortPorts_generate_setup_list
  }
}