import React, { Component, Fragment } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Input, Popover, Tooltip } from 'antd';
import ModelSelect from './modelSelect';
import { N_PORT } from '../../services/VirtualComponent';

class MoreModelAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelPinInfoObj: {},
      selectAllSignals: false,
      automaticInfo: {}
    };
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  signalClick = ({ modelItem, pinObj, selectAllSignals, fileId, index }) => {
    let _modelPinInfoObj = { ...this.state.modelPinInfoObj };
    const { modelInfoList, newModelFilesObj } = this.props;
    const currentModelInfo = modelInfoList[index];
    if (currentModelInfo.modelType !== modelItem) { return }
    if (!pinObj) {
      const findPin = fileId ? currentModelInfo.modelPinList.find(item =>
        item.pinLeft.find(it => it.pinLibraryId === fileId)
        || item.pinRight.find(it => it.pinLibraryId === fileId)) : null;

      if (fileId) {
        selectAllSignals = false;
      }
      pinObj = findPin || currentModelInfo.modelPinList[0] || {}
    }
    const { modelType, modelFileKey } = currentModelInfo;
    const files = newModelFilesObj[modelType] && newModelFilesObj[modelType][modelFileKey] ? newModelFilesObj[modelType][modelFileKey] : currentModelInfo.files;

    const value = {
      files: files,
      pinList: currentModelInfo.modelPinList,
      pairs: currentModelInfo.pairs,
      modelPinInfo: pinObj,
      index,
      modelFileKey: currentModelInfo.modelFileKey
    };

    // In the case of multiple connectors, click net and modify the data in the model panel. The saved data is incorrect
    // _modelPinInfoObj[modelItem] Convert from object to array
    if (_modelPinInfoObj[modelItem] && _modelPinInfoObj[modelItem].length) {
      const findIndex = _modelPinInfoObj[modelItem].findIndex(item => item.index === index)
      if (findIndex > -1) {
        _modelPinInfoObj[modelItem][findIndex] = value;
      } else {
        _modelPinInfoObj[modelItem].push(value)
      }
    } else {
      _modelPinInfoObj[modelItem] = [value]
    }
    this.setState({ modelPinInfoObj: _modelPinInfoObj, selectAllSignals })
  }

  savePinModels = ({ pinList, pairs, files }, type, index) => {
    this.props._saveModel({
      pinList,
      pairs,
      files
    }, type, index);
    let _modelPinInfoObj = { ...this.state.modelPinInfoObj };
    const findIndex = _modelPinInfoObj[type].findIndex(item => item.index === index);
    if (findIndex > -1) {
      _modelPinInfoObj[type].splice(findIndex, 1)
    }
    if (!_modelPinInfoObj[type].length) {
      delete _modelPinInfoObj[type];
    }
    this.setState({
      modelPinInfoObj: _modelPinInfoObj,
      selectAllSignals: false
    })
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
  }

  render = () => {
    const { modelInfoList = [], modelPinList = [], libraryListObj = {},
      pins, portsObj, maxWidth, maxHeight, className, editNode, editType, leftPlacement, rightPlacement, editMoreAssignIndex, signals, product, subcktList, serdesType } = this.props;
    const { modelPinInfoObj, selectAllSignals } = this.state;

    return (
      <Fragment>
        {modelInfoList.map((itemInfo, index) => {
          const { modelType: modelItem, files } = itemInfo;
          const pinObj = modelPinList[index];
          const pinLeft = pinObj && pinObj.pinLeft && pinObj.pinLeft[0] ? pinObj.pinLeft[0] : {};
          const pinRight = pinObj && pinObj.pinRight && pinObj.pinRight[0] ? pinObj.pinRight[0] : {};
          const pinLeftFileInfo = pinLeft && pinLeft.pinLibraryId ? files.find(item => item.libraryId === pinLeft.pinLibraryId) : {};
          const pinRightFileInfo = pinRight && pinRight.pinLibraryId ? files.find(item => item.libraryId === pinRight.pinLibraryId) : {};
          const lastClassName = index === modelInfoList.length - 1 ? "spice-netlist-last-model-ports" : "";
          return (
            <div
              className={index % 2 !== 0 ? `spice-netlist-model-ports ${lastClassName} ${className}` : `spice-netlist-model-ports spice-netlist-model-ports-background ${lastClassName} ${className}`}
              key={index}
            >
              <div className='spice-pin-half-line'></div>
              <div className='spice-pin-value-box'>
                {editNode && editType === modelItem && editNode === pinLeft.pin && editMoreAssignIndex === index ?
                  <Popover
                    overlayClassName='spice-node-select-Popover'
                    content={this.props.nodeSelectRender({
                      pin: pinLeft.pin,
                      type: "pinLeft",
                      port: pinLeft.pinValue,
                      placeholder: "Port",
                      currentItem: pinLeft,
                      net: pinObj.net,
                      editType: modelItem,
                      modelInfoListIndex: index
                    })}
                    title=""
                    trigger="click"
                    open={true}
                    placement={leftPlacement ? leftPlacement : "top"}
                  ><Input
                      className='spice-pin-input'
                      placeholder={"Port"}
                      value={pinLeft.pinValue}
                      id={`${pinLeft.pin}_${modelItem}_pinLeft`}
                      onClick={() => this.props.openSelectNode({
                        pin: pinLeft.pin,
                        value: pinLeft.pinValue,
                        type: "pinLeft",
                        currentItem: pinLeft,
                        editType: modelItem,
                        pinInfo: pinObj,
                        modelInfoListIndex: index
                      })}
                    /></Popover>
                  : pinLeft.pinValue ?
                    <Tooltip overlayClassName="aurora-tooltip" onClick={(e) => this.stopPropagation(e)} title={<Fragment>{pinLeftFileInfo && pinLeftFileInfo.fileName && <div>FileName:{pinLeftFileInfo.fileName}</div>}<div>Port:{pinLeft.pinValue}</div></Fragment>}>
                      <div className='pkg-pin-node-input'
                        id={`${pinLeft.pin}_${modelItem}_pinLeft`}
                        // title={pinLeft.pinValue}
                        onClick={() => this.props.openSelectNode({
                          pin: pinLeft.pin,
                          value: pinLeft.pinValue,
                          type: "pinLeft",
                          currentItem: pinLeft,
                          editType: modelItem,
                          pinInfo: pinObj,
                          modelInfoListIndex: index
                        })}
                      >
                        <span>{pinLeft.pinValue}</span>
                        <CloseCircleFilled
                          className='spice-pin-node-delete-icon'
                          onClick={(e) => { this.props.deleteSelectNode(e, pinLeft, "pinLeft", pinObj.net, modelItem, index) }} />
                      </div>
                    </Tooltip> : <Input
                      className='pkg-pin-node-input'
                      placeholder="Port"
                      id={`${pinLeft.pin}_${modelItem}_pinLeft`}
                      value={pinLeft.pinValue || ""}
                      onClick={() => this.props.openSelectNode({
                        pin: pinLeft.pin,
                        value: pinLeft.pinValue,
                        type: "pinLeft",
                        currentItem: pinLeft,
                        editType: modelItem,
                        pinInfo: pinObj,
                        modelInfoListIndex: index
                      })}
                    />}
              </div>
              <div className='spice-pin-half-line'></div>
              <div
                className='spice-box-signal'
                onClick={modelItem === N_PORT ? null : () => this.signalClick({ modelItem, pinObj, index })}
                title={`${pinObj.signal}`}
                style={{ width: pinObj.width }}
              >
                <span className='spice-box-signal-text'>{pinObj.signal}</span>
              </div>
              <div className='spice-pin-half-line'></div>
              <div className='spice-pin-value-box'>
                {editNode && editType === modelItem && editNode === pinRight.pin && editMoreAssignIndex === index ?
                  <Popover
                    overlayClassName='spice-node-select-Popover'
                    content={this.props.nodeSelectRender({
                      pin: pinRight.pin,
                      type: "pinRight",
                      port: pinRight.pinValue,
                      placeholder: "Port",
                      currentItem: pinRight,
                      net: pinObj.net,
                      editType: modelItem,
                      modelInfoListIndex: index
                    })}
                    title=""
                    trigger="click"
                    open={true}
                    placement={rightPlacement ? rightPlacement : "top"}
                  ><Input
                      className='spice-pin-input'
                      placeholder={"Port"}
                      value={pinRight.pinValue}
                      id={`${pinRight.pin}_${modelItem}_pinRight`}
                      onClick={() => this.props.openSelectNode({
                        pin: pinRight.pin,
                        value: pinRight.pinValue,
                        type: "pinRight",
                        currentItem: pinRight,
                        editType: modelItem,
                        pinInfo: pinObj,
                        modelInfoListIndex: index
                      })}
                    /></Popover> :
                  pinRight.pinValue ?
                    <Tooltip overlayClassName="aurora-tooltip" onClick={(e) => this.stopPropagation(e)} title={<Fragment>{pinRightFileInfo && pinRightFileInfo.fileName && <div>FileName:{pinRightFileInfo.fileName}</div>}<div>Port:{pinRight.pinValue}</div></Fragment>}>
                      <div className='pkg-pin-node-input'
                        id={`${pinRight.pin}_${modelItem}_pinRight`}
                        // title={pinRight.pinValue} 
                        onClick={() => this.props.openSelectNode({
                          pin: pinRight.pin,
                          value: pinRight.pinValue,
                          type: "pinRight",
                          currentItem: pinRight,
                          editType: modelItem,
                          pinInfo: pinObj,
                          modelInfoListIndex: index
                        })}
                      >
                        <span>{pinRight.pinValue}</span>
                        <CloseCircleFilled
                          className='spice-pin-node-delete-icon'
                          onClick={(e) => { this.props.deleteSelectNode(e, pinRight, "pinRight", pinObj.net, modelItem, index) }}
                        />
                      </div>
                    </Tooltip> :
                    <Input
                      className='pkg-pin-node-input'
                      placeholder="Port"
                      id={`${pinRight.pin}_${modelItem}_pinRight`}
                      value={pinRight.pinValue || ""}
                      onClick={() => this.props.openSelectNode({
                        pin: pinRight.pin,
                        value: pinRight.pinValue,
                        type: "pinRight",
                        currentItem: pinRight,
                        editType: modelItem,
                        pinInfo: pinObj,
                        modelInfoListIndex: index
                      })}
                    />}
              </div>
              {index !== modelInfoList.length - 1 && <div className='spice-pin-half-line'></div>}
            </div>
          )
        })}
        {Object.keys(modelPinInfoObj).map(type =>
          modelPinInfoObj[type] && modelPinInfoObj[type].length ? modelPinInfoObj[type].map((item, index) => {
            return <ModelSelect
              key={index}
              pins={pins}
              modelPinInfo={item.modelPinInfo}
              pinList={item.pinList}
              files={item.files}
              pairs={item.pairs}
              fileList={libraryListObj[`${type.toLowerCase()}List`] || []}
              portsObj={portsObj}
              selectAllSignals={selectAllSignals}
              modelType={type}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              savePinModels={(info) => this.savePinModels(info, type, item.index)}
              getFileParse={this.props.getFileParse}
              isMultiInfo={true}
              modelFileKey={item.modelFileKey}
              libraryListObj={libraryListObj}
              isMatchPort={true}
              signals={signals}
              product={product}
              subcktList={subcktList}
              updatePortsObj={this.props.updatePortsObj}
              serdesType={serdesType}
            />
          }) : null
        )}
      </Fragment>
    )
  }
}

export default MoreModelAssign;