import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { DownOutlined, LeftOutlined, RedoOutlined, RightOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { EXTRACTION_OPTIONS, EXTRACTION_PORTS_SETUP } from '../../../../services/TopBarHelper';
import ExtractionOptions from './extractionOptions';
import ExtractionPorts from './extractionPorts';
import DelConfirm from '@/components/DelConfirm';
import { PACKAGE } from '@/constants/designType';
import './index.css';
import { SOLDER } from '../../../../services/Stackup/Material';
import { METAL } from '../../../../services/Stackup';

class ExtractionPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 450,
      maxWidth: 830,
      pageOption: EXTRACTION_OPTIONS,
      simulationloading: true,
      setupOnly: false,
      hybridErrorMsg: '',
      restoreVisible: false,
      stackupData: {}
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate = (prevProps) => {
    //switch channel , close panel
    if (this.props.channelId !== prevProps.channelId) {
      this.props.closePanel();
    }
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 450),
      maxWidth: getPanelMaxWidth(offset, 830)
    })
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  closeModal = (saveType, setupOnly) => {
    const { pageOption } = this.state;
    const _saveType = saveType ? saveType : "close";
    this.saveSetup(pageOption, _saveType, setupOnly);
  }

  saveSetup = (pageOption, saveType, setupOnly) => {
    if (pageOption === EXTRACTION_OPTIONS && this.optionsRef) {
      let _info = this.optionsRef.state;
      let extraction = _info && _info.extractionConfig ? _info.extractionConfig : {};
      if (!_info || !_info.extractionConfig) {
        _info = this.optionsRef.props;
        extraction = _info && _info.extraction ? _info.extraction : {};
      }
      const hybridRegions = this.optionsRef.props.hybridRegions;
      if (this.props.designType !== PACKAGE) {
        let hybridErrorMsg = this.getHybridRegionsHFSSError(hybridRegions, null, extraction)
        if (hybridErrorMsg) { return }
      }
      const info = this.optionsRef.closeModal(saveType);
      saveType === "close" && this.props.closePanel();
      saveType === "modeling" && this.startModeling(setupOnly, info);
    } else if (pageOption === EXTRACTION_PORTS_SETUP && this.portsRef) {
      const confirm = this.portsRef.savePortsSetup(saveType);
      return confirm;
    }
  }

  getHybridRegionsHFSSError = (hybridRegions, time, extraction) => {
    //  Two adjacent regions cannot be set to HFSS at the same time
    let hybridErrorMsg = ''
    if (extraction && extraction.type === "SIwave" && extraction.hybrid && extraction.siwave && extraction.siwave.suggestHFSSRegions) {
      //At this point, suggestHFSSRegions was selected instead of using hybridRegions
      return
    }

    if (extraction && extraction.type === 'SIwave' && extraction.hybrid) {
      if (!hybridRegions.length) {
        hybridErrorMsg = 'HFSS Regions definition cannot be empty.';
      } else if (!hybridRegions.find(item => item.extraction === "HFSS")) {
        hybridErrorMsg = 'HFSS Regions must have at least one region set to "HFSS"'
      }
    }

    for (let i = 0; i < hybridRegions.length; i++) {
      const oneApartNumber = i + 1
      if (oneApartNumber < hybridRegions.length && hybridRegions[i].extraction === 'HFSS' && hybridRegions[oneApartNumber].extraction === 'HFSS') {
        hybridErrorMsg = "Two adjacent regions cannot be set to HFSS at the same time."
      }
    }

    this.setState({
      hybridErrorMsg
    })
    if (time) {
      setTimeout(() => {
        this.setState({
          hybridErrorMsg: ''
        })
      }, 4000);
    }
    return hybridErrorMsg
  }

  clearHybridErrorMsg = () => {
    this.setState({
      hybridErrorMsg: ''
    })
  }

  startModeling = (setupOnly, extractionInfo) => {
    const { channelId, pcbChannelSimSelectKeys } = this.props;
    if (channelId) {
      const selectedKeys = pcbChannelSimSelectKeys.length ? pcbChannelSimSelectKeys : [channelId];
      const config = extractionInfo && extractionInfo.hybrid ? { hybridInfo: extractionInfo, setupOnly } : { setupOnly }
      this.props._startChannelExtraction([...selectedKeys], { ...config });
    }
    this.props.closePanel()
  }

  onRef = (ref) => {
    this.optionsRef = ref;
  }

  onPortsRef = (ref) => {
    this.portsRef = ref;
  }

  setStackupData = (stackupData) => {
    this.setState({ stackupData })
  }

  updateMaterials = () => {
    const { stackupData } = this.state;
    if (stackupData && stackupData.materials) {
      const materials = [...stackupData.materials];
      if (!stackupData.materials.find(item => item.name === SOLDER)) {
        materials.push({ name: SOLDER, sigma: "7000000", miu_r: '1', type: METAL });
        this.setStackupData({ ...stackupData, materials });
      }
    }
  }

  render() {
    const { maxHeight, maxWidth, pageOption, setupOnly, hybridErrorMsg, restoreVisible, stackupData } = this.state;
    const content = (
      <Panel
        className='andes_v2-channel-extraction-panel panel-x-scroll-hidden'
        title={<div className='andes_v2-channel-extraction-option-title'>Extraction Setup</div>}
        onCancel={() => this.closeModal()}
        zIndex={2000}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={470}
        width={getPanelWidth(maxWidth, { defaultWidth: 830 })}
        maxHeight={maxHeight}
        overflow={"auto"}
        footer={this.footerOptions(pageOption)}
      >
        {pageOption === EXTRACTION_OPTIONS ?
          <ExtractionOptions
            onRef={this.onRef}
            hybridErrorMsg={hybridErrorMsg}
            getHybridRegionsHFSSError={this.getHybridRegionsHFSSError}
            clearHybridErrorMsg={this.clearHybridErrorMsg}
            setStackupData={this.setStackupData}
          /> : null}
        {pageOption === EXTRACTION_PORTS_SETUP ?
          <ExtractionPorts
            onRef={this.onPortsRef}
            maxHeight={maxHeight}
            setupOnly={setupOnly}
            changePage={(key) => this.changePage(key, true)}
            closePanel={this.props.closePanel}
            startModeling={this.startModeling}
            stackupData={stackupData}
            materialList={stackupData.materials || []}
            setStackupData={this.setStackupData}
            updateMaterials={this.updateMaterials}
          /> : null}

        {restoreVisible ? <DelConfirm
          maskStyle={true}
          deleteConfirm={this.restoreConfirmClick}
          cancelDel={() => this.channelConfirmShow(false)}
          okTitle={'Restore'}
          message={"Are you sure you want to restore the default settings?"}
        /> : null}
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  changePage = (key, notSave) => {
    if (!notSave) {
      const confirm = this.saveSetup(this.state.pageOption, "changePage");
      if (confirm) {
        return;
      }
    }

    this.setState({
      pageOption: key
    })
  }

  getSIwaveHFSSError = () => {
    const info = this.optionsRef.state;
    const _info = this.optionsRef.props
    let hybridErrorMsg = "";
    if (!info) { return hybridErrorMsg }
    const { extractionConfig } = info;
    const hybridRegions = _info.hybridRegions
    if (extractionConfig && extractionConfig.type === 'SIwave' && extractionConfig.hybrid) {
      if (extractionConfig.siwave && extractionConfig.siwave.suggestHFSSRegions) { return hybridErrorMsg }
      if (!hybridRegions.length) {
        hybridErrorMsg = 'HFSS Regions definition cannot be empty.';
      } else if (!hybridRegions.find(item => item.extraction === "HFSS")) {
        hybridErrorMsg = 'HFSS Regions must have at least one zone set to "HFSS"'
      }
    }

    this.setState({
      hybridErrorMsg
    })
    return hybridErrorMsg
  }

  simulationClick = () => {
    // Prevent clicking too fast
    if (!this.state.simulationloading) {
      return
    }
    const hybridErrorMsg = this.getSIwaveHFSSError();
    if (hybridErrorMsg) { return }

    this.setState({
      simulationloading: false
    })
    setTimeout(() => {
      this.setState({
        simulationloading: true
      })
    }, 1000);

    this.closeModal("modeling", this.state.setupOnly);
  }

  updateSetupOnly = (e) => {
    e.domEvent.stopPropagation();
    const { setupOnly } = this.state;
    this.setState({
      setupOnly: !setupOnly
    })
  }

  menu = () => {
    const { setupOnly } = this.state;
    return [{ key: setupOnly ? "Extract Model" : "Setup Extraction Only", label: setupOnly ? "Extract Model" : "Setup Extraction Only", onClick: (e) => this.updateSetupOnly(e) }]
  }

  restoreDefaultsClick = (e) => {
    this.setState({
      restoreVisible: true
    })
  }

  restoreConfirmClick = () => {
    if (this.optionsRef) {
      this.optionsRef.restoreExtractionInfo();
    }
    this.setState({
      restoreVisible: false
    })
  }
  channelConfirmShow = () => {
    this.setState({
      restoreVisible: false
    })
  }

  footerOptions = (pageOption) => {
    return (
      <div className='extraction-setup-footer-options'>
        <div>
          {pageOption === EXTRACTION_PORTS_SETUP ? <div
            className="extraction-setup-left-options"
            onClick={() => this.changePage(EXTRACTION_OPTIONS)}
          >
            <LeftOutlined />
            <span>Extraction Options</span>
          </div> : <div size="small" className="extraction-setup-left-options extraction-restore-button" onClick={() => this.restoreDefaultsClick()}>
            <RedoOutlined /><span>Restore defaults</span>
          </div>}
        </div>
        <div className="extraction-setup-middle-options">
          {!this.props.isEndToEndChildren ?
            <Fragment>
              <div size="small" className="modeling-button" onClick={() => this.simulationClick()}>
                <span>{this.state.setupOnly ? "Setup Extraction Only" : "Extract Model"}</span>
              </div>
              <Dropdown
                menu={{ items: this.menu() }}
                trigger={["click"]}
                placement="bottom"
                onClick={(e) => e.stopPropagation()}
                overlayClassName="modeling-aedt-menu">
                <div className="andes-extraction-modeling-adet-icon-content" >
                  <DownOutlined title="Advanced" className="andes-extraction-modeling-adet-icon" />
                </div>
              </Dropdown>
            </Fragment> : null}
        </div>
        <div>
          {pageOption === EXTRACTION_OPTIONS && <div
            className="ami-setup-right-options"
            onClick={() => this.changePage(EXTRACTION_PORTS_SETUP)}
          >
            <span>Ports Setup</span>
            <RightOutlined />
          </div>}
        </div>
      </div >
    );
  }
}

export default ExtractionPanel;