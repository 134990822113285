import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import EditableTable from '@/components/EditableTable';
import { saveVRM, savePDNContent, traceBackVRM } from '../../store/pdn/action';
import { selectChange, selectLayer } from '../../../LayoutExplorer/store/FastPI/actionCreators';
import { getVRM } from '@/services/PDN/PDNSetting/vrm';
import { CloseOutlined, PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { message, Spin, Tooltip } from 'antd';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import canvas from '@/services/LayoutCanvas';
import VRMSetting from './vrmSetting';
import { IGNORE } from '../../../../constants/componentType';
import './index.css';

const VRMColumns = [{
  title: 'Eq. Pwr Pin',
  dataIndex: 'powerPins',
  width: '20%',
}, {
  title: 'Eq. Gnd Pin',
  dataIndex: 'groundPins',
  width: '20%',
}, {
  title: 'Component',
  dataIndex: 'VRM_COMP',
  width: '20%'
}, {
  title: 'VRM Model',
  dataIndex: 'vrm',
  width: '40%',
  className: 'vrm-model-seletion'
}];

const VRMColumns_Cal = [{
  title: 'Eq. Pwr Pin',
  dataIndex: 'powerPins',
  width: '20%',
}, {
  title: 'Eq. Gnd Pin',
  dataIndex: 'groundPins',
  width: '14%',
}, {
  title: 'Component',
  dataIndex: 'VRM_COMP',
  width: '14%'
}, {
  title: 'VRM Model',
  dataIndex: 'vrm',
  width: '30%',
  className: 'vrm-model-seletion'
}, {
  title: 'Calculated VRM Model',
  dataIndex: 'calc',
  width: '22%',
}];

class VRMSetup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columnsUpdate: false,
      visible: false
    }
    this.columns = JSON.parse(JSON.stringify(VRMColumns))
  }

  componentDidMount() {
    this.getColumns()
  }

  componentDidUpdate(prevProps) {
    const { VRMModel } = this.props;
    const { VRMModel: prevModel } = prevProps;
    if (VRMModel !== prevModel) {
      this.getColumns()
    }
  }

  getColumns = () => {
    const { VRMModel } = this.props;

    this.columns = VRMModel ? JSON.parse(JSON.stringify(VRMColumns_Cal)) : JSON.parse(JSON.stringify(VRMColumns))

    this.columns[0].render = (data, record) => {
      return record.powerPin.length > 0
        && <span>{[...new Set(record.powerPin)].join(", ")}</span>
    };

    this.columns[0].onCell = (record) => {
      const names = this.getNames('powerPin');
      const { pdnContent } = this.props;
      const comps = (pdnContent && pdnContent.Components) || [];
      const ignores = comps.filter(item => item.usage === IGNORE).map(item => item.name)
      const options = record.VRM_COMP && comps.find(item => item.name === record.VRM_COMP) ? [...new Set([...names, record.VRM_COMP, ...ignores])] : [...new Set([...names, ...ignores])]
      return {
        record,
        edit: "aurora-select",
        options,
        dataIndex: "powerPin",
        selectType: "powerPin",
        getPopupContainer: document.getElementById("pdn-content-main"),
        handleSave: (record) => this.saveVRMPin(record, 'powerPin'),
        onFocus: () => this.vrmSelectFocus(record, "powerPin"),
        /*   selectMode: 'tags', */
        /* dropdownMenuClassName: 'pdn-select-dropdown-menu' */
      }
    };

    this.columns[1].render = (data, record) => {
      return record.groundPin.length > 0
        && <span>{[...new Set(record.groundPin)].join(", ")}</span>
    };

    this.columns[1].onCell = (record) => {
      const names = this.props.capsConnectRefNet.map(item => item.name);
      const { pdnContent } = this.props;
      const comps = (pdnContent && pdnContent.Components) || [];
      const ignores = comps.filter(item => item.usage === IGNORE).map(item => item.name)
      return {
        record,
        edit: "aurora-select",
        options: record.VRM_COMP ? [...new Set([...names, record.VRM_COMP, ...ignores])] : [...new Set([...names, ...ignores])],
        dataIndex: "groundPin",
        selectType: "groundPin",
        getPopupContainer: document.getElementById("pdn-content-main"),
        handleSave: (record) => this.saveVRMPin(record, 'groundPin'),
        onFocus: () => this.vrmSelectFocus(record, 'groundPin'),
        /*  selectMode: 'tags',
         dropdownMenuClassName: 'pdn-select-dropdown-menu' */
      }
    }

    this.columns[3].render = (text, record) => {
      const { config } = this.props;
      const { CVRM_MODEL } = config;
      return CVRM_MODEL === 1 ? <span>Compact VRM Model with Feedback</span> : <span>{text}</span>
    }

    this.columns[3].onCell = (record) => {
      const { config } = this.props;
      const { CVRM_MODEL } = config;
      return CVRM_MODEL === 1 ? {
        edit: false
      } : {
        record,
        edit: "select",
        options: this.props.VRMNameList,
        dataIndex: "vrm",
        getPopupContainer: document.getElementById("pdn-content-main"),
        handleSave: (record) => this.vrmSelection(record),
      }
    }

    if (this.columns.length - 1 === 3) {
      this.columns[this.columns.length - 1].render = (data, record, index) => {
        let show = false;
        if (this.props.pdnContent && this.props.pdnContent.VRM && Array.isArray(this.props.pdnContent.VRM)) {
          show = true;
        }
        const { config } = this.props;
        const { CVRM_MODEL } = config;
        return (
          <div className="vrm-model-seletion">
            {CVRM_MODEL === 1 ? <span>Compact VRM Model with Feedback</span> : <span>{data}</span>}
            {show && <CloseOutlined className='VRM-delete-icon' onClick={(e) => this.deleteVRM(e, index)} />}
          </div>
        );
      }
    } else {
      this.columns[3].render = (text, record) => {
        const { config } = this.props;
        const { CVRM_MODEL } = config;
        return CVRM_MODEL === 1 ? <span>Compact VRM Model with Feedback</span> : <span>{text}</span>
      }

      this.columns[this.columns.length - 1].render = (data, record, index) => {
        let show = false;
        if (this.props.pdnContent && this.props.pdnContent.VRM && Array.isArray(this.props.pdnContent.VRM)) {
          show = true;
        }
        return (
          <div className="vrm-model-seletion">
            <span>{data}</span>
            {show && <CloseOutlined className='VRM-delete-icon' onClick={(e) => this.deleteVRM(e, index)} />}
          </div>
        );
      }
    }

    const { columnsUpdate } = this.state;
    this.setState({
      columnsUpdate: !columnsUpdate
    })
  };

  deleteVRM = (e, index) => {
    e.stopPropagation();
    const { pdnContent } = this.props;
    let vrm = pdnContent ? pdnContent.VRM : [];
    vrm.splice(index, 1);
    this.props._savePDNContent({ VRM: vrm });
  }

  getNames = (type) => {
    if (!this.props.pdnContent) {
      return [];
    }
    const { PowerNets = [], ReferenceNets = [] } = this.props.pdnContent;
    const { COMP_PREFIX_LIB } = this.props;
    let nets = [], rules = null;
    //When COMP_PREFIX_LIB is modified, re-acquire the names
    let res = [...COMP_PREFIX_LIB.Res], ind = [...COMP_PREFIX_LIB.Ind], cap = [...COMP_PREFIX_LIB.Cap];
    let resRules = "", indRules = "", capRules = "";
    res.forEach((item, index) => {
      if (index === 0) {
        resRules = "^" + item;
      } else {
        resRules = resRules + "|^" + item;
      }
    })

    ind.forEach((item, index) => {
      if (index === 0) {
        indRules = "^" + item;
      } else {
        indRules = indRules + "|^" + item;
      }
    })

    cap.forEach((item, index) => {
      if (index === 0) {
        capRules = "^" + item;
      } else {
        capRules = capRules + "|^" + item;
      }
    });

    //resRules eg: ^R|RT
    //indRules eg: ^L
    //indRules eg: ^C|CT
    if (type === 'powerPin') {
      nets = PowerNets || [];
      //rules = /^R|^L/i;
      if (resRules && indRules) {
        rules = new RegExp(`${resRules}|${indRules}`, 'i');

      } else if (resRules && !indRules) {

        rules = new RegExp(`${resRules}`, 'i');
      } else if (!resRules && indRules) {

        rules = new RegExp(`${indRules}`, 'i');
      } else if (res.length === 0 && ind.length === 0) {
        return [];
      }
    } else {
      nets = ReferenceNets || [];
      //rules = /^C/i;
      if (capRules) {
        rules = new RegExp(`${capRules}`, 'i');
      } else {
        return [];
      }

    };
    // Filter components connect to power or ground
    return this.props.components.filter(comp => {
      const _nets = comp.pins.map(item => item.net);
      for (const net of _nets) {
        // Only Cap will have Removed usage
        if (comp.usage !== 'Removed' && nets.includes(net) && rules.test(comp.name)) {
          return comp;
        }
      };
      return false;
    }).map(item => item.name);
  }

  vrmSelection = (record) => {
    const vrm = record.vrm;
    const { VRMNames } = this.props;
    const id = VRMNames.find(item => item.name === vrm).id;
    const model = {
      name: vrm,
      id
    };
    this.props.saveVRM('model', model, record.index);
  }

  saveVRMPin = (record, type) => {
    const { pdnContent, selectedDesignIDs, pcbId } = this.props;
    if (type === 'powerPin' && record.index) {
      let vrm = pdnContent ? pdnContent.VRM : [];
      let powerPins = [];
      vrm.forEach((item, i) => {
        if (i !== record.index - 1 && item.powerPin.length > 0) {
          powerPins.push(item.powerPin[0].comp);
        }
      })
      if (powerPins.length > 0 && powerPins.includes(record[type])) {
        message.error('Power pin already exists!');
        return;
      }
    }
    const comps = record[type];
    this.props.saveVRM(type, comps, record.index);
    if (selectedDesignIDs.includes(pcbId)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer([comps]);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [comps] }, pcbId);
    }
  }

  vrmSelectFocus = (record, type) => {
    const { selectedDesignIDs, pcbId } = this.props;
    let comp = null;

    if (type === 'powerPin') {
      comp = record.powerPin[0];
    } else if (type === 'groundPin') {
      comp = record.groundPin[0];
    }

    if (selectedDesignIDs.includes(pcbId)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer([comp]);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [comp] }, pcbId);
    }
  }

  addVRM = (e) => {
    const { pdnContent, voltage, defaultVRM } = this.props;
    let vrm = pdnContent ? pdnContent.VRM : [];
    let _model = { name: "", id: "" };
    if (defaultVRM) {
      _model.name = defaultVRM.name;
      _model.id = defaultVRM.libraryId;
    }
    vrm.push({
      powerPin: [],
      groundPin: [],
      model: _model,
      voltage: voltage
    });
    this.props._savePDNContent({ VRM: vrm });
  }

  openVisible = (e) => {
    e && e.stopPropagation();
    this.setState({
      visible: true
    })
  }

  closeVisible = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { pdnContent, vrmFindLoading, VRMModel, showPrefix, pcbId, COMP_PREFIX_LIB, loading } = this.props;
    const { visible } = this.state;
    let vrm = pdnContent ? pdnContent.VRM : [];
    const specVRM = pdnContent ? pdnContent.specVRM : [];
    const data = getVRM(vrm, VRMModel);
    const _data = data.map((d, index) => ({ ...d, rowIndex: index }))
    return (
      <Fragment>
        <div className='vrm-box'>
          <span className="font-bold pdn-setup-title-color pdn-vrm-title">VRM</span>
          <PlusCircleOutlined className='VRM-add-icon' onClick={(e) => this.addVRM(e)} />
          {(showPrefix || !data.length) && <Fragment>
            <SettingOutlined
              className={loading ? 'pdn-component-advance-disable VRM-setting-icon' : 'VRM-setting-icon'}
              onClick={(e) => this.openVisible(e)}
              title={loading ? 'Loading PCB...' : ''} />
            <Tooltip
              title={"Click to set VRM and trace."}
              overlayClassName="aurora-tooltip"
            >
              <i className="iconfont icon-iconfontpcqipaotubiao pdn-component-prefix-prompt"></i>
            </Tooltip>
          </Fragment>}
          <div className='space-10'>
            <Spin spinning={vrmFindLoading}>
              <EditableTable
                rowKey={(record) => record.rowIndex}
                columns={this.columns}
                size="small"
                dataSource={_data}
                className={`pdn-vrm-tables`}
                tablePadding={true}
              />
            </Spin>
          </div>
        </div>
        {
          visible &&
          <VRMSetting
            closeModal={this.closeVisible}
            specVRM={specVRM}
            pcbId={pcbId}
            COMP_PREFIX_LIB={COMP_PREFIX_LIB}
            traceBackVRM={this.props.traceBackVRM}
          />
        }
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { PDNReducer: { pdn, project, explorer } } = state;
  const pdnContent = pdn.pdnInfo ? pdn.pdnInfo.pdnContent : null;
  const config = pdnContent && pdnContent.Config ? pdnContent.Config : {}
  const VRMModel = pdn.VRMModel;
  const channelPDNVRMIds = pdn.channelPDNVRMIds
  const { VRMNames = [], currentProjectId, PDNID, treeSelectedKeys } = project;
  const defaultVRM = pdn.defaultVRM || { name: "", libraryId: "" };
  const pcbId = pdn.pdnInfo && pdn.pdnInfo.designId;
  let COMP_PREFIX_LIB = { Res: [], Ind: [], Cap: [] };
  if (pdnContent && pdnContent.COMP_PREFIX_LIB) {
    COMP_PREFIX_LIB = pdnContent.COMP_PREFIX_LIB;
  }
  const VRMIds = VRMNames.filter(obj => channelPDNVRMIds.includes(obj.id))
  const otherVRMIds = VRMNames.filter(obj => !channelPDNVRMIds.includes(obj.id))
  const VRMModelIds = [...VRMIds, ...otherVRMIds]

  return {
    pdnContent,
    VRMNames,
    VRMNameList: VRMModelIds.map(item => item.name),
    defaultVRM,
    pcbId,
    COMP_PREFIX_LIB,
    vrmFindLoading: pdn.vrmFindLoading,
    currentProjectId,
    PDNID,
    VRMModel,
    capsConnectRefNet: pdn.capsConnectRefNet,
    selectedDesignIDs: getSelectedDesignIDs(treeSelectedKeys),
    config,
    showPrefix: pdn.showPrefix,
  }
};


const mapDispatch = (dispatch) => ({
  saveVRM(type, data, index) {
    dispatch(saveVRM(type, data, index));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  _savePDNContent(data) {
    dispatch(savePDNContent(data))
  },
  traceBackVRM(vrms) {
    dispatch(traceBackVRM(vrms))
  }
})

export default connect(mapState, mapDispatch)(VRMSetup);