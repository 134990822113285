import {
  OPEN_IO_SIM_PAGE,
  UPDATE_IO_SIM_INFO,
  CLEAR_IO_SIM_INFO,
  SAVE_SIM_OPTIONS
} from './actionTypes';


export const openIoSimPage = (id) => ({
  type: OPEN_IO_SIM_PAGE,
  id
});

export const updateIoSimInfo = (data) => ({
  type: UPDATE_IO_SIM_INFO,
  data
});

export const clearIoSimInfo = () => ({
  type: CLEAR_IO_SIM_INFO
});

export const saveSimOptions = (id, options, allInterfaces) => ({
  type: SAVE_SIM_OPTIONS,
  id,
  options,
  allInterfaces
})