import http from '../http';
import JSZip from 'jszip';

/**
 * Get pdn user library list
 * 2019/09/09
 * @export
 * @returns
 */
export function getPdnLibraryList() {
  return http.get(`/library`);
}


export function createPdnLibraryList({ formData, config }) {
  return http.post(`/library`, formData, config, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}


/**
 * Create pdn user library list
 * 2019/09/09
 * @export
 * @param {String} data RLC data
 * @param {file} file  upload file
 * @param {String} id id 
 * @param {String} typeName /Inter_SPIM/VRM/Decap
 * @param {String} name  name
 * @returns
 */
export function _createPdnLibraryList({ typeName, files, libraries, libraryFormat }) {
  let formData = new FormData();
  if (libraryFormat === 'file') {
    let zip = new JSZip();
    if (typeName === 'Decap') {
      for (let file of files) {
        zip.file(file.fileName, file.file);
      }

    } else {
      //if the file is zip file,keep file name consistent.
      zip.file(files.file.name, files.file);
    }
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 }
    }).then(blob => {
      formData.append('file', new File([blob], 'file.zip'));
      formData.append('libraries', JSON.stringify(libraries));
      formData.append('typeName', typeName);
      formData.append('libraryFormat', libraryFormat);
      return http.post(`/library`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    })
  }
  if (libraryFormat === 'data') {
    formData.append('libraries', JSON.stringify(libraries));
    formData.append('typeName', typeName);
    formData.append('libraryFormat', libraryFormat);
  }
  return http.post(`/library`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
 * delete pdn user library list
 * 2019/09/09
 * @param userLibraryIds:array [] String
 * @export
 * @returns
 */
export function delPdnLibraryList(userLibraryIds) {
  return http.delete(`/library`,
    {
      params: {
        userLibraryIds: userLibraryIds.toString()
      }
    });
}

/**
 * update pdn user library list
 * 2019/09/09
 * @param {String} data RLC data
 * @param {file} file  upload file
 * @param {String} id id 
 * @param {String} typeName /Inter_SPIM/VRM/Decap
 * @param {String} name  name
 * @export
 * @returns
 */
export function updatePdnLibraryList({ typeName, files, libraries, libraryFormat }) {
  let formData = new FormData();
  if (libraryFormat === 'file') {
    let zip = new JSZip();
    if (typeName === 'Decap') {
      for (let file of files) {
        zip.file(file.fileName, file.file);
      }

    } else {
      //if the file is zip file,keep file name consistent.
      zip.file(files.file.name, files.file);
    }
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 }
    }).then(blob => {
      formData.append('file', new File([blob], 'file.zip'));
      formData.append('libraries', JSON.stringify(libraries));
      formData.append('typeName', typeName);
      formData.append('libraryFormat', libraryFormat);
      return http.put(`/library`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    })
  }
  if (libraryFormat === 'data') {
    formData.append('libraries', JSON.stringify(libraries));
    formData.append('typeName', typeName);
    formData.append('libraryFormat', libraryFormat);
  }
  return http.put(`/library`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

/**
 * get pdn user library data
 * 2019/09/10
 * @param libraryId:String
 * @export
 * @returns
 */
export function getPdnLibraryData(libraryId) {
  return http.get(`/library/${libraryId}/data`);
}

/**
 * Get VRM model by SPIM power domain
 * 2019/12/16
 *
 * Returns data: {id,powerDomainName,spimName,userId,userLibraryId}
 * 
 * @export
 * @param {String} powerDomainName
 * @param {String} spimName
 * @returns 
 */
export function getVRMModelParsedBySPIM({ powerDomainName, spimName }) {
  return http.get(`/library/SPIM/parse`, {
    params: {
      powerDomainName,
      spimName
    }
  })
};

/**
 * Get decap parse
 * 2020/01/16
 *
 * @export
 * @param {String} libraryId PDN lirary Id
 * @param {String} parserVersion DECAP_PARSE
 * @returns
 */
export function decapParse({ libraryId, parserVersion }) {
  return http.get(`/library/${libraryId}/decap/parse`, {
    params: {
      parserVersion
    }
  })
};

/**
 * Get decap touchstone curve
 * 2019/12/19
 *
 * @export
 * @param {String} libraryId
 * @param {Array} cols 
 * @param {String} rows
 * @param {String} path - subcktName/subcktName.npi .sph .zam .s1p .yam .zph .sam .yph
 */
export function getDecapTouchstoneCurve({ libraryId, cols, rows, path }) {
  return http.post(`/library/${libraryId}/decap/curve`, {
    cols: [
      ...cols
    ],
    path: path,
    rows: [
      ...rows
    ]
  })
};

/**
 * Get library file by path, response: file
 *
 * @export
 */
export function getLibraryFileByPath({ libraryId, path }) {
  return http.get(`/library/libraryId/${libraryId}/file`, {
    params: {
      path
    }
  })
};

/**
 * Decap re-characerization
 *
 * @export
 * @param {*} userLibraryId
 * @returns
 */
export function decapReCharacterization(userLibraryId) {
  return http.get(`/library/${userLibraryId}/decap/parse/reParser`);
}

/**
 * Get library touchstone file by path
 * @param libraryId
 * @param path:  eg: Cap_0201_1uF/Cap_0201_1uF.s3p
 * @export
 */
export function getLibraryTouchstoneFile({ libraryId, path }) {
  return http.get(`/library/libraryId/${libraryId}/touchstone/title?path=${path}`)
}

/**
 * Get default decap
 */
export function getDefault() {
  return http.get(`/library/default`, {
    params: {
      typeName: "decap"
    },
  })
}

/**
 * Set default decap
 * @param libraryId
 * @param typeName
 */
export function setDefault({ libraryId, typeName }) {
  return http.post(`/library/default/set`, {
    libraryId: libraryId,
    typeName: typeName
  })
}

export function getPDNVRMModelParsedBySPIM({ powerDomainName, libraryId }) {
  return http.get(`/library/spim/list/parse`, {
    params: {
      powerDomainName,
      libraryId
    }
  })
};

/**
 * download pdn user library file
 * 2021/10/28
 * @param libraryId: String
 * @export
 * @returns
 */
export function PdnLibraryDownload(libraryId) {
  return http.get(`/library/${libraryId}/file/download`);
}

export function getTouchstoneFileNames() {
  return http.post(`library/decapTouchstone/folder/list`)
}

export function getPdnLibraryDecapFile({ libraryId, fileName }) {
  return http.get(`/library/${libraryId}/title`, {
    params: {
      fileName
    }
  });
}

/**
 * get pdn user library file
 * 2019/09/10
 * @param libraryId: String
 * @export
 * @returns
 */
export function getPdnLibraryFile(libraryId) {
  return http.get(`/library/${libraryId}/file`);
}

export function getSPIMLog(libraryId) {
  return http.get(`/library/spim/log?libraryId=${libraryId}`);
}