const PRE_LAYOUT = 'SIERRA_PRE_LAYOUT';

// create
export const CREATE_PRE_LAYOUT = `${PRE_LAYOUT}/create_pre_layout`;

// update
export const UPDATE_PRE_LAYOUT_CONTENT = `${PRE_LAYOUT}/update_pre_layout_content`;
export const SAVE_PRE_LAYOUT = `${PRE_LAYOUT}/save_pre_layout`;
export const SAVE_PRE_LAYOUT_SETTING = `${PRE_LAYOUT}/save_pre_layout_setting`;
export const UPDATE_PRE_LAYOUT_COMPONENTS = `${PRE_LAYOUT}/update_pre_layout_components`;
export const UPDATE_CANVAS = `${PRE_LAYOUT}/canvas_update`;
export const SAVE_PRE_LAYOUT_MODEL = `${PRE_LAYOUT}/save_pre_layout_model`;
export const UPDATE_CANVAS_CONFIG = `${PRE_LAYOUT}/update_canvas_config`;
export const UPDATE_PRE_LAYOUT_DATA = `${PRE_LAYOUT}/update_pre_layout_data`;

// open
export const OPEN_PRE_LAYOUT = `${PRE_LAYOUT}/open_pre_layout`;
export const FIRST_OPEN_PRE_LAYOUT = `${PRE_LAYOUT}/first_open`;
export const COMP_TABLE_LOADING = `${PRE_LAYOUT}/component_table_loading`;
export const SCHEMATIC_LOADING = `${PRE_LAYOUT}/schematic_loading`;

// create
export const CREATE_PRELAYOUT_INTERFACE = `${PRE_LAYOUT}/create_prelayout_interface`;
export const CREATE_LOADING = `${PRE_LAYOUT}/create_loading`;
