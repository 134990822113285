import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import AMIModelSetup from './amiModelSetup';
import IBISModelSetup from './IBISModelSetup';
import TerminationSetup from './TerminationSetup'
import { Select } from 'antd';
import { TerminationModelConfig, AMIModelConfig } from '@/services/Andes_v2/AMIModelHelper';
import CircuitModelSetup from './CircuitModelSetup';
import "./index.css";
import { CPHY } from '../../../services/PCBHelper/constants';
import CPHYIBISModelSetup from './IBISModelSetup/CPHYIBISModelSetup';

const { Option } = Select;

const AMI = "AMI", IBIS = "IBIS", TERMINATION = "Termination", CIRCUIT = "Circuit";

class ModelSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 730,
      maxHeight: 320,
      modelType: "",
      modelInfo: this.props.modelInfo,
      modelOptionList: [],
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 320),
      maxWidth: getPanelMaxWidth(offset, 730)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.updateInfo()
  }

  componentDidUpdate = (prevProps) => {
    const { modelInfo } = this.props;
    const { signal, dirType } = modelInfo;
    const prevModelType = this.getModelType(prevProps);
    const newModelType = this.getModelType(this.props);
    if (prevProps.modelInfo.signal !== signal || prevProps.modelInfo.dirType !== dirType
      || prevModelType !== newModelType) {
      this.updateInfo(newModelType);
    }

  }

  getModelType = (props) => {
    const { modelInfo = {}, modelType } = props;
    let _modelType = modelType;
    if (modelInfo.modelType === 'rcModel') {
      _modelType = TERMINATION
    } else if (modelInfo.modelType === CIRCUIT) {
      _modelType = CIRCUIT
    }
    return _modelType;
  }

  updateInfo = (newModelType) => {
    const { modelType, modelInfo } = this.props;
    let _modelType = newModelType ? newModelType : this.getModelType(this.props);
    let modelOptionList = [{ key: TERMINATION, title: "Termination Model", disabled: modelInfo.disabledTermination ? true : false }]
    if (modelType === AMI) {
      modelOptionList.unshift({ key: AMI, title: 'AMI Model' })
    } else {
      modelOptionList.unshift({ key: IBIS, title: "IBIS Buffer" })
    }

    // modeList
    this.setState({
      modelType: _modelType,
      modelInfo,
      modelOptionList
    })
  }

  closeModal = () => {
    const { modelType } = this.state;
    switch (modelType) {
      case AMI:
        this.AMIModelRef.closeModal()
        return;
      case IBIS:
        this.IBISModelRef.closeModal()
        return;
      case TERMINATION:
        this.TerminationModelRef.closeModal()
        return;
      case CIRCUIT:
        this.CircuitModelRef.closeModal()
        return;
      default: return;
    }
  }

  onAMIRef = (ref) => {
    this.AMIModelRef = ref;
  }

  onIBISRef = (ref) => {
    this.IBISModelRef = ref;
  }

  onTerminationRef = (ref) => {
    this.TerminationModelRef = ref;
  }

  onCircuitRef = (ref) => {
    this.CircuitModelRef = ref;
  }

  getContent = () => {
    const { prbs, saveModel, componentPkgInfo, serdesType } = this.props;
    const { modelType, modelInfo } = this.state;
    switch (modelType) {
      case AMI:
        return <AMIModelSetup
          onRef={this.onAMIRef}
          modelInfo={modelInfo}
          bitRate={prbs.bitRate}
          componentPkgInfo={componentPkgInfo}
          closeModal={saveModel}
          serdesType={serdesType}
        />;
      case IBIS:
        return serdesType === CPHY
          ? <CPHYIBISModelSetup
            onRef={this.onIBISRef}
            modelInfo={modelInfo}
            bitRate={prbs.bitRate}
            componentPkgInfo={componentPkgInfo}
            closeModal={saveModel}
            prbsType={prbs.type}
            serdesType={serdesType}
          />
          : <IBISModelSetup
            onRef={this.onIBISRef}
            modelInfo={modelInfo}
            bitRate={prbs.bitRate}
            componentPkgInfo={componentPkgInfo}
            closeModal={saveModel}
            prbsType={prbs.type}
            serdesType={serdesType}
          />;
      case TERMINATION:
        return <TerminationSetup
          onRef={this.onTerminationRef}
          modelInfo={modelInfo}
          bitRate={prbs.bitRate}
          closeModal={saveModel}
          prbsType={prbs.type}
          serdesType={serdesType}
        />;
      case CIRCUIT:
        return <CircuitModelSetup
          onRef={this.onCircuitRef}
          modelInfo={modelInfo}
          closeModal={saveModel}
        />
      default: return;
    }
  }

  selectChange = (value) => {
    const { modelInfo } = this.state;
    let _model = { ...modelInfo.model || {} }

    const { channelId, component, interfaceType } = modelInfo
    // Restore the default value of model
    if (value === TERMINATION) {
      _model = new TerminationModelConfig({ component: component, type: interfaceType, channelId })
    } else {
      _model = new AMIModelConfig({
        type: interfaceType,
        component,
        channelId,
        modelType: value,
        modelDir: modelInfo.dirType
      })
    }
    this.setState({
      modelType: value,
      modelInfo: {
        ...modelInfo,
        model: _model
      }
    })
  }

  selectModelType = () => {
    const { modelType, modelOptionList } = this.state;
    return <div className='ads-model-select-item'>
      <span>Model Type</span>
      <Select
        value={modelType}
        onChange={(key) => this.selectChange(key)}
        popupMatchSelectWidth={false}
        className={`aurora-select`}
        popupClassName="aurora-select-dropdown"
        getPopupContainer={() => document.getElementById('root')}
      // disabled={disabled}
      >
        {modelOptionList.map((item) => (
          <Option
            key={item.key}
            value={item.key}
            title={item.title}
            disabled={item.disabled || false}
          >{item.title}</Option>))}
      </Select>
    </div>
  }

  render() {
    const { serdesType } = this.props;
    const { maxWidth, maxHeight, modelInfo: { signal, dirType, modelType } } = this.state;
    const content = (
      <Panel
        className='andes-ads-model-setup-panel panel-x-scroll-hidden'
        title={<div className='andes-ads-model-setup-panel-title'>{signal} {dirType} {modelType === CIRCUIT ? "Circuit" : "Buffer"} Model</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        width={getPanelWidth(maxWidth, { defaultWidth: 730 })}
        maxHeight={maxHeight}
        overflow={"auto"}
      >
        <div className='andes-ads-model-setup-main'>
          {dirType === 'RX' && modelType !== CIRCUIT && serdesType !== CPHY ? this.selectModelType() : null}
          {this.getContent()}
        </div>
      </Panel >
    )
    return createPortal(content, this.dialogRoot);
  }
}

export default ModelSetup;