import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import NetsConnection from './netsConnection';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import './index.css';

class netsConnectionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 800,
      maxHeight: 800,
      contentWidth: 600,
      contentHeight: 500
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const contentRoot = document.getElementById('cascade-net-connection-content') || {}
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 800),
      maxHeight: getPanelMaxHeight(offset, 800),
      contentWidth: contentRoot.scrollWidth || 600,
      contentHeight: contentRoot.scrollHeight || 500
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  closeModal = () => {
    if (this.netsRef) {
      const { nets, save } = this.netsRef.getNetsAndSave();
      this.props.closeModal(nets, save);
      return;
    }
    this.props.closeModal([], false)
  }

  onNetsRef = (ref) => {
    this.netsRef = ref;
  }

  render() {
    const { maxWidth, maxHeight, contentWidth, contentHeight } = this.state;
    const content = (
      <Panel
        className='cascade-power-select-panel'
        title={<div className='cascade-power-select-title'>Connect Nets</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 800 })}
        height={600}
        maxHeight={maxHeight}
        position='panel-center'
        draggable
        minHeight={500}
        minWidth={800}
        defaultTop={200}
        resizeEnd={this.resize}
      >
        <div className='cascade-position-relative'>
          <NetsConnection {...this.props} contentWidth={contentWidth} contentHeight={contentHeight} onRef={this.onNetsRef} />
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
};


export default netsConnectionPanel;
