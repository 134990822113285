import { LIBRARY_DATA, LIBRARY_FILE } from '@/constants/treeConstants';
import { TRACE } from '@/constants/libraryConstants';

/**
 *
 *
 * @param {*} key
 * @param {*} { name, type, id, fileName } type - file|data
 */
function libraryItem(key, { name, type, id, fileName, typeName, nodeClass, status, folderChildren, deletable }) {
  this.name = name;
  this.type = type;
  this.id = id;
  this.dataType = type === 'data' ? LIBRARY_DATA : LIBRARY_FILE;
  this.fileName = fileName;
  this.key = this.id + '-' + this.name;
  this.fileType = key === TRACE ? typeName : key;
  this.nodeClass = nodeClass;
  this.status = status;
  this.deletable = deletable;
  this.folderChildren = folderChildren || false
  this.icon = key === "SPICE" ? type === "folder" ? 'file-folder' : 'file' : null
}

/**
 * Get library array
 *
 * @param {String} key IBIS|
 * @param {Array} dataArr [{ id: "", libraryStructure: { name: "", type: "file|data" }, name: "", typeName: "ibis|vector|spice", userId: "" }]
 * @returns [libraryItem]
 */
function getLibraryArray(key, dataArr, oldDataArr) {
  if (!Array.isArray(dataArr) || !dataArr.length) {
    return [];
  }

  const _arr = dataArr.map(item => new libraryItem(key, {
    name: item.libraryStructure ? item.libraryStructure.name : item.name,
    type: item.libraryStructure ? item.libraryStructure.type : 'file',
    id: item.id,
    fileName: item.libraryStructure ? item.libraryStructure.name : item.name,
    typeName: item.typeName ? item.typeName : null,
    nodeClass: 'tree-library-file',
    status: item.libraryStructure ? item.libraryStructure.status : null,
    deletable: item.libraryStructure ? item.libraryStructure.deletable : true,
  }));
  return _arr;
}

function getDefaultPatternInfo(name = "", signalGroup) {
  this.name = name;
  let victimSignalGroup = [["DQ", 'DM'], ["ADR", "CMD"]], aggressorSignalGroup = [["DQ", 'DM'], ["ADR", "CMD"], ['DQSP', 'CLKP'], ['DQSN', 'CLKN']];
  if (signalGroup && signalGroup.length) {
    victimSignalGroup = signalGroup;
    aggressorSignalGroup = signalGroup;
  }
  this.Victim = victimSignalGroup.map(item => new defaultVictimAggressorInfo(item));
  this.Aggressor = aggressorSignalGroup.map(item => new defaultVictimAggressorInfo(item));
}

function defaultVictimAggressorInfo(signals = []) {
  this.signals = signals;
  this.pinModels = [];
}

export const LIBRARY_INDEX = 1,
  BUFFER_INDEX = 0,
  VECTOR_INDEX = 1,
  VRM_INDEX = 2,
  PKG_INDEX = 3,
  MASK_INDEX = 4,
  TRACE_INDEX = 5,
  VIA_INDEX = 6,
  SYSTEM_LIBRARY_INDEX = 0,
  SYS_DECAP_INDEX = 0,
  SYS_DECAP_VENDOR_INDEX = 1,
  SYS_DECAP_GENERIC_INDEX = 0,
  SYS_CUSTOM_INDEX = 1;
export const PROJECT_INDEX = 2;
export { getLibraryArray, libraryItem, getDefaultPatternInfo, defaultVictimAggressorInfo };