import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { Progress, Row, Col, Tooltip, Dropdown, message } from 'antd';
import getDesignFile from '@/services/api/designFile';
import { cancelUploadWorkflow, changeDisableStatus } from '../../AndesSider/Upload/action';
import FileSaver from 'file-saver';
import { getResBlob, downloadFile } from '@/services/helper/downloadHelper';
import './style.css';

class UploadMonitor extends Component {

  constructor(props) {
    super(props);
    this.scrollRef = createRef();
  }


  scrollToBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "auto" });
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
  }

  download = (e) => {
    /*  e.target.blur(); */
    const { uploadDesignId } = this.props;
    getDesignFile(uploadDesignId, 'lay_trans/log/layout_trans.log').then((response) => {
      const log = response ? response.data : null;
      this.downloadLog(log);
    }, (error) => {
      this.downloadLog('')
    })
  }

  downloadTransLayoutFiles = (e) => {
    /*   e.target.blur(); */
    const { uploadDesignId } = this.props;
    const token = localStorage.getItem('token');
    const url = `/api/v3/project/designs/${uploadDesignId}/layout/debug?access_token=${token}`;
    getResBlob(url).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, 'design.zip');
      } else {
        message.error('Download failed!');
      }
    })
  }

  downloadLog(monitor) {
    const filename = 'translation.log'
    downloadFile(monitor, filename)
  }

  debugMenu = () => {
    return [
      { key: "download_log_file", label: 'Log', onClick: (e) => this.download(e) },
      { key: "download_debug", label: 'Debug', onClick: (e) => this.downloadTransLayoutFiles(e) },
    ]
  }

  cancelWorkflow = (e) => {
    e.stopPropagation();
    //cancel upload workflow
    const { uploadWorkflowId } = this.props;
    if (uploadWorkflowId) {
      this.props.cancelUploadWorkflow(uploadWorkflowId);
      this.props.changeDisableStatus(false);
    }
  }

  render() {
    const { visible, msg, uploadProgress, transProgress, monitor,
      endMsg, projectName,
      uploadProjectId, currentProjectId } = this.props;
    const progressStyle = monitor ? 0 : 20;
    return (
      <Fragment>
        <Row className='my-andes-monitor-holygrail' span={visible ? 18 : 4}>
          <Col className='andes-simulation-msg'
            span={7}>
            {projectName && <div className='andes-simulation-span andes-simulation-project'>
              <span className='font-bold span-msg-project'>Project </span>
              <span className='span-msg'>{projectName}</span>
            </div>}
          </Col>
          <Col className='my-andes-middle' span={12} style={{ marginLeft: progressStyle }}>
            {uploadProjectId === currentProjectId && visible && <div className='my-andes-monitor-progress'>
              <Progress
                size={{ height: 12 }}
                strokeColor={'#1890ff'}
                percent={uploadProgress}
                success={{ percent: transProgress }}
                format={(percent, successPercent) => `Uploading: ${percent}% / Compiling: ${successPercent}%`}
                className="monitor-upload-progress-bar"
              />
            </div>}
          </Col>
          <div className='andes-simulation-btn'>
            {uploadProjectId === currentProjectId && transProgress > -1 &&
              <div className='andes-simulation-button'>
                <Tooltip title='Cancel upload PCB' overlayClassName='icon-tooltip'>
                  <span className='iconfont icon-cancel9' onClick={(e) => this.cancelWorkflow(e)}></span>
                </Tooltip>
              </div>}
            {uploadProjectId === currentProjectId && monitor &&
              <div className='andes-simulation-button'>
                <Dropdown menu={{ items: this.debugMenu(), className: "upload-dropdown-button" }} trigger={['click']}>
                  <Tooltip title='Download debug' overlayClassName='icon-tooltip'>
                    <span className="iconfont icon-Debug andes-button-icon"></span>
                  </Tooltip>
                </Dropdown>
              </div>}
          </div>
        </Row>
        <div className='my-andes-monitor'>
          <div className='my-andes-monitor-log'>
            {uploadProjectId === currentProjectId && (monitor || msg) && <pre style={{ margin: 20, overflow: 'unset' }}>
              {msg}
              <br />
              {<ul style={{ paddingLeft: 34, margin: 0 }}>
                {monitor && monitor.length > 0 && monitor.map(item =>
                  <li key={item.id} style={{ listStyle: 'none', width: '100%' }}>
                    <p style={{ margin: 0 }}>{item.time}  {item.log}</p>
                  </li>)}
              </ul>}
              {endMsg}
            </pre>}
            <div ref={(el) => { this.scrollRef = el; }}></div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { project: { currentProjectId } } = state.AndesReducer;
  const { visible, uploadProgress, transProgress, msg, monitor, uploadProjectId,
    endMsg, uploadDesignId, uploadWorkflowId } = state.AndesReducer.upload;
  const { tabVisible, projectName } = state.TabMonitorReducer;
  return {
    visible,
    msg,
    uploadProgress,
    transProgress,
    monitor,
    uploadProjectId,
    endMsg,
    uploadDesignId,
    tabVisible,
    projectName,
    currentProjectId,
    uploadWorkflowId,
  }
}

const mapDispatch = (dispatch) => ({
  cancelUploadWorkflow(workflowId) {
    dispatch(cancelUploadWorkflow(workflowId));
  },
  changeDisableStatus(disabled) {
    dispatch(changeDisableStatus(disabled))
  }
})

export default connect(mapState, mapDispatch)(UploadMonitor);