import React, { Fragment } from "react";
import { Divider } from 'antd';
import ErrorCheck from "../../ErrorCheck";

function Monitor({
  monitor,
  log,
  monitorDisplay,
  logDisplay,
  className,
  monitorTitle }) {

  return <Fragment>
    {monitorDisplay ? <ul className={`aurora-monitor-ul ${className}`}>
      {monitor.map((item, index) => {
        if (item.id) {
          return <li key={item.id}>
            <p>{item.log}</p>
          </li>
        } else {
          return <li key={index}>
            <p>{monitorTitle ?
              <span>{monitorTitle} </span>
              : null}
              {item}</p>
          </li>
        }
      }
      )}
    </ul> : null}
    {logDisplay ? log : null}
  </Fragment>
}

function displayReplaceMonitor(replaceMonitor, errorType = "Channel update:", className, dividerExist = true) {
  return replaceMonitor && replaceMonitor.length ?
    <div className={`aurora-repeater-monitor-content ${className}`}>
      {errorType ? <span className='aurora-repeater-monitor-title'>{errorType}</span> : null}
      <Monitor
        monitor={replaceMonitor}
        monitorDisplay={true}
        className="aurora-monitor-error"
      />
      {dividerExist ? <Divider dashed /> : null}
    </div> : null
}

function displayStackupError(designStackupErrorList, title) {
  return designStackupErrorList && designStackupErrorList.length ?
    <div className='aurora-stackup-error-monitor-content'>
      <span className="aurora-stackup-error-span">{title ? `${title}: ` : "Stackup: "}</span>
      <ul className='aurora-monitor-ul aurora-monitor-error'>
        {designStackupErrorList.map((item, index) => {
          return <li key={index}>
            <p>
              {item.layerName ? <span className="font-bold">"{item.layerName}" </span> : null}
              {item.error}</p>
          </li>
        })}
      </ul>
    </div> : null
}

function displayPortsError({ portGenerateErrors, portGenerateWarnings }) {
  return (portGenerateErrors && portGenerateErrors.length) || (portGenerateWarnings && portGenerateWarnings.length) ?
    <div className={`aurora-repeater-monitor-content`}>
      <span className='aurora-repeater-monitor-title'>Extraction ports:</span>
      <Monitor
        monitor={portGenerateErrors}
        monitorDisplay={true}
        monitorTitle="[Error]"
        className="aurora-monitor-error"
      />
      <Monitor
        monitor={portGenerateWarnings}
        monitorDisplay={true}
        monitorTitle="[Warning]"
        className="aurora-monitor-error"
      />
      <Divider dashed />
    </div>
    : null
}

function displayWarning({ warning, title, error }) {
  return warning && warning.length ?
    <div className={`aurora-repeater-monitor-content ${error ? `aurora-stackup-error-monitor-content` : ''}`}>
      <span className='aurora-repeater-monitor-title'>{title}</span>
      <Monitor
        monitor={warning}
        monitorDisplay={true}
        monitorTitle={error ? "[Error]" : null}
        className="aurora-monitor-error"
      />
      <Divider dashed />
    </div> : null;
}

function displayErrorList(list) {
  return list.map((item, index) => {
    //stackupError
    if ((item.errorCheck && item.errorCheck.length)
      || (item.preLayoutErrors && item.preLayoutErrors.length)
      || (item.stackupError && item.stackupError.length)) {
      return <div className='aurora-stackup-error-monitor-content' key={index}>
        <span>{item.displayName}: </span>
        {item.errorCheck && item.errorCheck.length ?
          <div className="aurora-error-list-display-content">
            <ErrorCheck error={item.errorCheck} /></div>
          : null}
        {item.preLayoutErrors && item.preLayoutErrors.length ?
          <div className="aurora-error-list-display-content">
            {displayErrors({
              errors: item.preLayoutErrors,
              title: "Pre layout setup:",
              className: "aurora-error-content"
            })}</div> : null}
        {item.stackupError && item.stackupError.length ?
          <div className="aurora-error-list-display-content">
            {displayStackupError(item.stackupError)}</div> : null}
      </div>
    }
    return null;
  })
}

function displayErrors({ errors, title, className }) {
  return errors && errors.length ?
    <div className={`aurora-repeater-monitor-content ${className}`}>
      {title ? <span className='aurora-repeater-monitor-title'>{title}</span> : null}
      <ErrorCheck error={errors} />
      <Divider dashed />
    </div> : null
}

function monitorDisplayRender({
  waitingMsg,
  logDisplay,
  startMsg,
  endMsg,
  monitorDisplay,
  props,
  monitor
}) {
  return <Fragment>
    {startMsg ? <span className='debug-mes-span'>{startMsg}</span> : null}
    {waitingMsg ? <span className='debug-mes-span'>{waitingMsg}</span> : null}
    <Monitor
      {...props}
      monitor={monitor}
      monitorDisplay={monitorDisplay}
      logDisplay={logDisplay}
    />
    {endMsg ? <span className='debug-mes-span'>{endMsg}</span> : null}
  </Fragment>
}

function displayReplaceMonitorList(list, title, className) {
  return list && list.length ?
    <div className={`aurora-repeater-monitor-content ${className}`}>
      <span className='aurora-repeater-monitor-title'>{title}</span>
      {list.map((item, index) =>
        <Fragment key={index}>
          {displayReplaceMonitor(item.monitor, item.title, "aurora-repeater-monitor-children")}
        </Fragment>
      )}
    </div> : null
}

export {
  displayPortsError,
  displayStackupError,
  displayReplaceMonitor,
  Monitor,
  displayWarning,
  displayErrorList,
  monitorDisplayRender,
  displayReplaceMonitorList,
  displayErrors
}