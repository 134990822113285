import { DIE_CURRENT, IMPEDANCE_DIE } from "../constants";

class OverviewPlot {
  constructor(props) {
    const { id, width, height, data, font, customModelType } = props;
    this.id = id;
    this.width = width;
    this.height = height;
    this.data = data;
    this.ctx = this.initCtx(id);
    this.font = font || 12;
    this.virtualCanvas = document.createElement("canvas");
    this.customModelType = customModelType
  }

  initCtx = (id) => {
    const { width, height } = this;
    let canvas = document.getElementById(id);
    const virtualCanvas = document.createElement("canvas");
    virtualCanvas.width = width || canvas.width;
    virtualCanvas.height = height || canvas.height;
    this.virtualCanvas = virtualCanvas;
    const ctx = virtualCanvas.getContext('2d');
    ctx.lineWidth = 1;
    ctx.font = `normal ${this.font}px Arial`;
    ctx.fillStyle = '#000000';
    return ctx;
  }

  clear = () => {
    const { ctx, width, height, id } = this;
    ctx.clearRect(0, 0, width, height);
    this.ctx = this.initCtx(id);
    let canvas = document.getElementById(id);
    canvas.width = width;
  }

  reset = ({ width, height, customModelType }) => {
    this.clear();
    this.width = width;
    this.height = height;
    this.ctx = this.initCtx(this.id);
    this.customModelType = customModelType
  }

  set = (param, value) => {
    this[param] = value;
  }

  redraw = () => {
    this.clear();
    this.draw(false);
  }

  draw = (reverse) => {
    this.drawNets(reverse)
    this.drawToTrueCanvas();
  }

  drawToTrueCanvas = () => {
    const { id, virtualCanvas } = this;
    let canvas = document.getElementById(id);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(virtualCanvas, 0, 0);
  }

  drawBackground = () => {
    const { ctx, width, height } = this;
    ctx.beginPath();
    ctx.fillStyle = '#000000';
    ctx.rect(0, 0, width, height);
    ctx.fill();
    ctx.closePath();
    ctx.fillStyle = '#000000';
  }

  getNetsLocation = () => {
    const { data } = this;
    let nets = [];
    for (let comp of data) {
      const usage = comp.data.usage || null
      const to = comp.data.to || [];
      const model = comp.data.model || {}
      for (let toItem of to) {
        const { components, designId } = toItem;
        components.forEach(name => {
          const _comp = data.find(item => item.name === name && item.designId === designId);
          if (_comp) {
            nets.push({
              x: comp.name === IMPEDANCE_DIE ? comp.left : comp.left + 100,
              y: comp.top,
              _x: _comp.left + 5,
              _y: _comp.top,
              name: comp.name,
              usage,
              modelType: model.type || null
            })
          }
        })
      }
    }
    return nets;
  }

  drawDieCurrent = (reverse, net) => {
    const { x, y, _x, _y, modelType } = net;
    const { ctx, customModelType } = this
    ctx.strokeStyle = '#889FBA';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(x + 20, y - 20);
    ctx.lineTo(x + 20, _y);
    ctx.stroke();

    ctx.moveTo(x + 20, y + 20);
    ctx.lineTo(x + 20, y + 40);
    ctx.stroke();
    if (reverse) {
      ctx.moveTo(x - 20, y + 40);
      ctx.lineTo(x - 20, y + 50);
      ctx.stroke();

      ctx.moveTo(x - 30, y + 50);
      ctx.lineTo(x - 10, y + 50);
      ctx.stroke();

      if (modelType === 'CPM') {
        ctx.moveTo(x + 20, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();

        ctx.moveTo(x + 20, y + 40);
        ctx.lineTo(x - 200, y + 40);
        ctx.stroke();

        ctx.moveTo(x - 200, _y);
        ctx.lineTo(x - 200, y + 40);
        ctx.stroke();
      } else if (customModelType === 'unified') {
        ctx.moveTo(x + 20, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();

        ctx.moveTo(x + 20, y + 40);
        ctx.lineTo(x - 116, y + 40);
        ctx.stroke();

        ctx.moveTo(x - 116, _y);
        ctx.lineTo(x - 116, _y + 10);
        ctx.stroke();

        ctx.moveTo(x - 116, _y + 68);
        ctx.lineTo(x - 116, y + 40);
        ctx.stroke();
      } else {
        ctx.moveTo(x + 20, _y);
        ctx.lineTo(x - 160, _y);
        ctx.stroke();

        ctx.moveTo(x + 20, y + 40);
        ctx.lineTo(x - 116, y + 40);
        ctx.stroke();

        ctx.moveTo(x - 50, _y);
        ctx.lineTo(x - 50, _y + 10);
        ctx.stroke();

        ctx.moveTo(x - 50, _y + 68);
        ctx.lineTo(x - 50, y + 40);
        ctx.stroke();

        ctx.moveTo(x - 116, _y);
        ctx.lineTo(x - 116, _y + 25);
        ctx.stroke();
        ctx.closePath()

        this.drawVR(x - 116, _y + 25)

        ctx.beginPath();
        ctx.moveTo(x - 116, _y + 55);
        ctx.lineTo(x - 116, y + 40);
        ctx.stroke();
        ctx.closePath();

        this.drawHR(x - 190, _y)

        ctx.beginPath();
        ctx.moveTo(x - 190, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();
        ctx.closePath();
      }

      if (modelType === 'value') {
        this.drawVR(x - 50, _y + 10)
        ctx.beginPath();
        ctx.moveTo(x - 50, _y + 40);
        ctx.lineTo(x - 50, _y + 60);
        ctx.stroke();
        ctx.closePath()
        this.drawC(x - 50, _y + 60)
      }
    } else {
      ctx.moveTo(x + 60, y + 40);
      ctx.lineTo(x + 60, y + 50);
      ctx.stroke();

      ctx.moveTo(x + 70, y + 50);
      ctx.lineTo(x + 50, y + 50);
      ctx.stroke();

      if (modelType === 'CPM') {
        ctx.moveTo(x + 20, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();

        ctx.moveTo(x + 20, y + 40);
        ctx.lineTo(x + 240, y + 40);
        ctx.stroke();

        ctx.moveTo(x + 240, _y);
        ctx.lineTo(x + 240, y + 40);
        ctx.stroke();
      } else if (customModelType === 'unified') {
        ctx.moveTo(x + 20, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();

        ctx.moveTo(x + 20, y + 40);
        ctx.lineTo(x + 156, y + 40);
        ctx.stroke();

        ctx.moveTo(x + 156, _y);
        ctx.lineTo(x + 156, _y + 10);
        ctx.stroke();

        ctx.moveTo(x + 156, _y + 68);
        ctx.lineTo(x + 156, y + 40);
        ctx.stroke();
      } else {
        ctx.moveTo(x + 20, _y);
        ctx.lineTo(x + 200, _y);
        ctx.stroke();

        ctx.moveTo(x + 20, y + 40);
        ctx.lineTo(x + 156, y + 40);
        ctx.stroke();

        ctx.moveTo(x + 90, _y);
        ctx.lineTo(x + 90, _y + 10);
        ctx.stroke();

        ctx.moveTo(x + 90, _y + 68);
        ctx.lineTo(x + 90, y + 40);
        ctx.stroke();

        ctx.moveTo(x + 156, _y);
        ctx.lineTo(x + 156, _y + 25);
        ctx.stroke();
        ctx.closePath();

        this.drawVR(x + 156, _y + 25)

        ctx.beginPath();
        ctx.moveTo(x + 156, _y + 55);
        ctx.lineTo(x + 156, y + 40);
        ctx.stroke();
        ctx.closePath();

        this.drawHR(x + 200, _y);

        ctx.beginPath();
        ctx.moveTo(x + 230, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();
        ctx.closePath();
      }

      if (modelType === 'value') {
        this.drawVR(x + 90, _y + 10)
        ctx.beginPath();
        ctx.moveTo(x + 90, _y + 40);
        ctx.lineTo(x + 90, _y + 60);
        ctx.stroke();
        ctx.closePath()
        this.drawC(x + 90, _y + 60)
      }
    }
  }

  drawUnifiedDie = (reverse, net) => {
    const { x, y, _x, _y } = net;
    const { ctx } = this
    ctx.strokeStyle = '#889FBA';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(x + 90, y + 50);
    ctx.lineTo(x + 90, y + 60);
    ctx.stroke();

    ctx.moveTo(x + 76, y + 60);
    ctx.lineTo(x + 104, y + 60);
    ctx.stroke();

    if (reverse) {
      ctx.moveTo(x + 2, _y);
      ctx.lineTo(_x, _y);
      ctx.stroke();

      ctx.moveTo(x + 63, y - 20);
      ctx.lineTo(x + 2, y - 20);
      ctx.stroke();

      ctx.moveTo(x + 63, y - 20);
      ctx.lineTo(x + 63, y + 50);
      ctx.stroke();

      ctx.moveTo(x + 135, y - 40);
      ctx.lineTo(x + 2, y - 40);
      ctx.stroke();

      ctx.moveTo(x + 135, y - 40);
      ctx.lineTo(x + 135, y + 50);
      ctx.stroke();
      ctx.closePath()
    } else {
      ctx.moveTo(x + 180, _y);
      ctx.lineTo(_x, _y);
      ctx.stroke();

      ctx.moveTo(x + 45, y - 40);
      ctx.lineTo(x + 180, y - 40);
      ctx.stroke();

      ctx.moveTo(x + 45, y - 40);
      ctx.lineTo(x + 45, y + 50);
      ctx.stroke();

      ctx.moveTo(x + 117, y - 20);
      ctx.lineTo(x + 180, y - 20);
      ctx.stroke();

      ctx.moveTo(x + 117, y - 20);
      ctx.lineTo(x + 117, y + 50);
      ctx.stroke();
      ctx.closePath()
    }
  }

  drawNets = (reverse) => {
    const nets = this.getNetsLocation();
    const { ctx, font, customModelType } = this;
    this.netName = []
    ctx.font = `normal ${font}px Arial`;
    ctx.translate(0.5, 0.5);
    for (let net of nets) {
      const { x, y, _x, _y } = net;
      if (net.name === IMPEDANCE_DIE) {
        customModelType === 'unified' ? this.drawUnifiedDie(reverse, net) : this.drawDieCurrent(reverse, net)
        continue;
      }
      ctx.strokeStyle = '#a8c4e6';
      ctx.lineWidth = 3;
      if (x === _x) {
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(_x, _y);
        ctx.stroke();
        ctx.closePath()
      } else {
        const __x = 0.5 * (_x - x);
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x + __x, y);
        ctx.stroke();
        ctx.moveTo(x + __x, y);
        ctx.lineTo(x + __x, _y);
        ctx.stroke();
        ctx.moveTo(x + __x, _y);
        ctx.lineTo(_x, _y);
        ctx.stroke();
        ctx.closePath()
      }
    }
    ctx.translate(1, 1)
  }

  drawC = (x, y) => {
    const { ctx } = this
    ctx.beginPath();
    ctx.moveTo(x - 8, y);
    ctx.lineTo(x + 8, y);
    ctx.stroke();
    ctx.moveTo(x - 8, y + 8);
    ctx.lineTo(x + 8, y + 8);
    ctx.stroke();
    ctx.closePath();
  }

  drawVR = (x, y) => {
    const { ctx } = this
    const leftX = x - 6, rightX = x + 6
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(leftX, y + 6);
    ctx.stroke();

    ctx.moveTo(leftX, y + 6);
    ctx.lineTo(rightX, y + 12);
    ctx.stroke();

    ctx.moveTo(rightX, y + 12);
    ctx.lineTo(leftX, y + 18);
    ctx.stroke();

    ctx.moveTo(leftX, y + 18);
    ctx.lineTo(rightX, y + 24);
    ctx.stroke();

    ctx.moveTo(rightX, y + 24);
    ctx.lineTo(x, y + 30);
    ctx.stroke();
    ctx.closePath()
  }

  drawHR = (x, y) => {
    const { ctx } = this
    const topY = y - 6, bottomY = y + 6
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + 6, topY);
    ctx.stroke();

    ctx.moveTo(x + 6, topY);
    ctx.lineTo(x + 12, bottomY);
    ctx.stroke();

    ctx.moveTo(x + 12, bottomY);
    ctx.lineTo(x + 18, topY);
    ctx.stroke();

    ctx.moveTo(x + 18, topY);
    ctx.lineTo(x + 24, bottomY);
    ctx.stroke();

    ctx.moveTo(x + 24, bottomY);
    ctx.lineTo(x + 30, y);
    ctx.stroke();
    ctx.closePath()
  }
}

export {
  OverviewPlot
}