import http from "../http";

/**
 * get workFlow Status
 * 2019.08.14
 *
 * @export
 * @param {string} verificationId 
 * @returns XMLHttpRequest
 */
export const getWorkFlowStatus = (verificationId) => {
  return http.get(`/services/workflows/verification/${verificationId}/running`)
}

/**
 * get workflow
 * 2019.08.15
 *
 * @export
 * @param {string} id workflowId 
 * @returns XMLHttpRequest
 */
export const getWorkFlow = (id) => {
  return http.get(`/services/workflows/${id}`)
}

/**
 * cancel workflow
 * 2019.08.15
 *
 * @export
 * @param {string} workflowId 
 * @returns XMLHttpRequest
 */

export const cancelWorkflow = (workflowId) => {
  return http.delete(`/services/workflows/${workflowId}`);
}


/**
 * Get workflow waiting index
 *
 * @export
 * @param {*} workflowId workflow id
 * @returns
 */
export function workflowWaitingIndexByID(workflowId) {
  return http.get(`/services/workflows/${workflowId}/queue/index`);
}

/**
 * Get workflow log in queue 
 * 2019.10.25
 *
 * @export
 * @returns XMLHttpRequest
 */
export function getQueueLog() {
  return http.get(`/services/workflows/queue/log`);
}

/**
 * Get running queue
 * 2019.12.10
 *
 * @export
 * @returns XMLHttpRequest
 */
export function getRunningQueueInfo() {
  return http.get(`/services/workflows/RQInfo`);
}

/**
 * delete task
 * 2020/04/13
 *
 * @export
 * @returns XMLHttpRequest
 */
export function cancelServerTask(id) {
  return http.delete(`/services/tasks/${id}`)
}

/**
 * cancel current verification workflow
 * 2020/04/28
 *
 * @export
 * @param {*} verificationId
 * @returns
 */
export function cancelVerificationWorkflow(verificationId) {
  return http.delete(`/verification/${verificationId}/cancel`)
}