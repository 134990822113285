import { _createLibraryList, updateLibraryList } from './index';
import VRMData from './getVRMData';
import DecapData from './getDecapData';

const IntelSPIM_FILE = 'intelSPIM_file',
  VRM_DATA = 'vrm_data',
  DECAP_DATA = 'decap_data',
  DECAP_FILE = 'decap_file',
  POWER_DOMAIN_FILE = 'power_domain_file', // targetZ, ckt, example_deck
  POWER_DOMAIN_TOUCHSTONE = 'power_domain_touchstone', // package touchstone
  DECAP_TOUCHSTONE = 'decap_touchstone';

/**
 * get library data list
 * 2019/09/09
 * @param {object} item  libraryData
 * @param {object} id  library id
 * @param {object} type  SPIM/VRM/Decap
 * @return array
 */

export function getLibraryDataList({ item, id, type, name, DataType, touchstoneChildren }) {
  function _sortFn(a, b) {
    if (a.name.length === b.name.length) {
      return a.name.localeCompare(b.name);
    } else {
      return a.name.length - b.name.length
    }
  }
  let dataType = null;
  switch (type) {
    case 'SPIM': dataType = IntelSPIM_FILE; break;
    case 'VRM': dataType = VRM_DATA; break;
    case 'Decap': dataType = item.type === 'data' ? DECAP_DATA : DECAP_FILE; break;
    case 'DecapTouchstone': dataType = DECAP_TOUCHSTONE; break;
    default: break;
  }
  if (DataType) {
    dataType = DataType;
  }
  if (item.children && item.children.length > 0 && item.children[0].type === 'folder') {
    item.dataType = dataType;
    item.key = `${id}-${item.name}`;
    item.id = id;
    item.nodeClass = 'tree-library-file';
    item.children.forEach(i => {
      // Cannot delete children files or folders in the library
      getLibraryDataList({ item: i, id, type, DataType: 'library_file' });
    })
    if (item.displayChildren) {
      item.displayChildren = item.displayChildren.map(child => {
        const dataType = (child.name.match(/\.s\d+p/ig) && child.name.match(/(pkg|package)/ig)) ? POWER_DOMAIN_TOUCHSTONE : POWER_DOMAIN_FILE
        return {
          name: child.name,
          key: `${id}-${child.name}`,
          id: id,
          dataType,
          type: 'library_file',
          nodeClass: 'tree-node-spim-file-name tree-library-file'
        }
      })
    }
  } else if (item.children && item.children.length > 0 && DataType === 'library_file' && type === 'SPIM') {
    const _child = JSON.parse(JSON.stringify(item.children));
    // 1 .ckt
    let ckt = _child.filter(i => i.name.match(/\.ckt$/ig)).sort(_sortFn);
    ckt.forEach(it => it.dataType = POWER_DOMAIN_FILE);
    // 2. _TargetZ_.s1p
    let targetZ = _child.filter(i => i.name.match(/.s1p$/ig) && i.name.match(/targetz/ig)).sort(_sortFn);
    targetZ.forEach(it => it.dataType = POWER_DOMAIN_FILE);
    // 3.Package S-parameter Touchstone file - (Product_Rail_PKG.sNp / Product_Rail_package.sNp)
    let pkgSNP = _child.filter(i => i.name.match(/.s[0-9]{1,}p$/ig) && i.name.match(/(pkg|package)/ig)).sort(_sortFn);
    pkgSNP.forEach(it => it.dataType = POWER_DOMAIN_TOUCHSTONE);
    // 4. _ExampleDeck_.sp ||  _Example_Deck_.sp
    let example_deck = _child.filter(i => i.name.match(/.sp$/ig) && i.name.match(/example|deck/ig)).sort(_sortFn);
    example_deck.forEach(it => it.dataType = POWER_DOMAIN_FILE);
    // 5. _PINS.txt
    let pins_txt = _child.filter(i => i.name.match(/_pins.txt$/ig)).sort(_sortFn);
    pins_txt.forEach(it => it.dataType = POWER_DOMAIN_FILE);

    const _childFilter = [...ckt, ...targetZ, ...pkgSNP, ...example_deck, ...pins_txt];
    let msg = [], _msg = '';
    if (ckt.length === 0) { msg.push("pin group mapping file (_PKG.ckt)") }
    if (targetZ.length === 0) { msg.push("target impedance Touchstone file") }
    if (pkgSNP.length === 0) { msg.push("package S-parameter Touchstone model") }
    if (example_deck.length === 0) { msg.push("example deck file") }
    _msg = `Unrecognized SPIM rail folder – missing ${msg.join(' and ')}.`
    item.key = `${id}-${item.name}-SPIM`;
    item.id = id;
    item.dataType = dataType;
    item.type = 'folder';
    item.nodeClass = 'tree-node-power-domain-folder-name tree-library-file';
    item.children = _childFilter.map(child => {
      return {
        name: child.name,
        key: `${id}-${child.name}`,
        id: id,
        dataType: child.dataType,
        type: 'library_file',
        folderName: item.name,
        nodeClass: 'tree-node-spim-file-name tree-library-file'
      }
    });
    item.msg = _msg
  } else if (item.children && item.children.length > 0) {
    item.key = `${id}-${item.name}`;
    item.id = id;
    item.dataType = dataType;
    item.type = 'folder';
    item.nodeClass = 'tree-library-file';
    delete item.children;
  } else {
    if (item.type === 'file') {
      item.id = id;
      item.dataType = dataType;
      item.name = name;
      item.nodeClass = 'tree-library-file'
      item.fileName = `${name}.sp`;
      if (item.dataType === "decap_touchstone") {
        item.fileName = name
      }
    } else if (item.type === 'data') {
      item.id = id;
      item.dataType = dataType;
      item.nodeClass = 'tree-library-file'
    } else if (dataType === 'decap_touchstone' && item.type === 'folder') {
      item.key = `${id}-${item.name}`;
      item.id = id;
      item.dataType = dataType;
      item.type = 'folder';
      item.nodeClass = 'tree-library-file';
      item.name = name;
      item.fileName = `${name}`;
      if (touchstoneChildren && touchstoneChildren.length > 0) {
        item.children = touchstoneChildren.map(child => {
          return {
            name: child.fileName,
            key: `${id}-${child.fileName}`,
            id: id,
            dataType: 'decap_touchstone_file',
            type: 'library_file',
            folderName: item.name,
            nodeClass: 'tree-node-spim-file-name tree-library-file',
            fileName: child.fileName
          }
        });
      }
    }
    item.key = `${id}-${item.name}`;
  }
  return item;
}

/**
 * save library data 
 * 2019/09/10
 * 
 */
export function saveLibraryData(type, data) {
  return new Promise((resolve, reject) => {
    if (type === 'SPIM') {
      let file = [...data.fileList][0];
      let libraries = [{
        id: '',
        name: file.name,
        fileName: file.name,
        libraryData: {}
      }];
      _createLibraryList({ typeName: 'Intel_SPIM', files: file.file, libraries, libraryFormat: 'file' }).then(res => {
        resolve(res)
      }, error => {
        resolve(null)
      })
    } else if (type === 'VRM') {
      const name = data.name ? data.name : null;
      let VRMData = data.VRMData;
      const RUnit = data.RUnit.slice(0, 1) === 'Ω' ? '' : data.RUnit.slice(0, 1);
      const LUnit = data.LUnit.slice(0, 1);
      if (VRMData.R) {
        VRMData.R = `${VRMData.R}${RUnit}`;
      }
      if (VRMData.L) {
        VRMData.L = `${VRMData.L}${LUnit}`;
      }
      let id = '';
      if (VRMData.key !== 'create') {
        id = VRMData.key;
      }
      let libraries = [{
        id: id,
        name: name,
        fileName: '',
        libraryData: { ...VRMData }
      }]
      if (!id) {
        delete libraries[0].libraryData.key;
        _createLibraryList({ typeName: 'VRM', files: null, libraries, libraryFormat: 'data' }).then(res => {
          resolve(res)
        }, error => {
          resolve(null)
        })
      } else {
        delete libraries[0].libraryData.key;
        updateLibraryList({ typeName: 'VRM', files: null, libraries, libraryFormat: 'data' }).then(res => {
          resolve(res)
        }, error => {
          resolve(null)
        })
      }
    } else if (type === 'decap') {
      const dataType = data.type;
      if (dataType === 'Upload') {
        let fileList = [...data.fileList];
        let libraries = [];
        let files = []
        fileList.forEach(file => {
          libraries.push({
            id: '',
            name: file.name,
            fileName: file.fileName,
            libraryData: {}
          });
          files.push(file.file);
        })
        /*  const decapFile = data.file;
         let fileName = decapFile.fileName ? decapFile.fileName : null;
         let name = decapFile.name ? decapFile.name : null;
         if (name) {
           fileName = `${name}.sp`;
         } */
        _createLibraryList({ typeName: 'Decap', files: files, libraries, libraryFormat: 'file' }).then(res => {
          resolve(res)
        }, error => {
          resolve(null)
        })
      } else {
        let decapData = { ...data.DecapDataList };
        const name = data.name ? data.name : 'Decap';
        const RUnit = data.RUnit.slice(0, 1) === 'Ω' ? '' : data.RUnit.slice(0, 1);
        const LUnit = data.LUnit.slice(0, 1);
        const CUnit = data.LUnit.slice(0, 1);
        if (decapData.R) {
          decapData.R = `${decapData.R}${RUnit}`;
        }
        if (decapData.L) {
          decapData.L = `${decapData.L}${LUnit}`;
        }
        if (decapData.C) {
          decapData.C = `${decapData.C}${CUnit}`;
        }
        let id = '';
        if (decapData.key !== 'create') {
          id = decapData.key;
        }
        let libraries = [{
          id: id,
          name: name,
          fileName: '',
          libraryData: { ...decapData }
        }]
        if (id === '') {
          delete libraries[0].libraryData.key;
          _createLibraryList({ typeName: 'Decap', files: null, libraries, libraryFormat: 'data' }).then(res => {
            resolve(res)
          }, error => {
            resolve(null)
          })
        } else {
          delete libraries[0].libraryData.key;
          updateLibraryList({ typeName: 'Decap', files: null, libraries, libraryFormat: 'data' }).then(res => {
            resolve(res)
          }, error => {
            resolve(null)
          })
        }
      }
    }
  })
}

export function repeatedNames(names) {
  let hash = {};
  for (var i in names) {
    if (hash[names[i]]) {
      return names[i];
    }
    hash[names[i]] = true;
  }
  return false;
}

//Check if it is a number.
export function numberCheck(num) {
  let error = null;
  if (typeof (num) === 'string') {
    if (!num) {
      error = 'value cannot be empty!';
      return error;
    } else if (num.indexOf(" ") !== -1 && !isNaN(num)) {
      error = 'value cannot contain spaces!';
      return error;
    } else if (isNaN(num)) {
      error = 'value must be a number!';
      return error;
    }
  } else if (typeof (num) === 'number') {
    if (isNaN(num)) {
      error = 'value must be a number!';
      return error;
    }
  } else {
    error = 'value must be a number!';
    return error;
  }
}

export function getVRMDataIds(pdnContent) {
  let VRM = [], vrmIds = [], vrmInfo = [];
  if (pdnContent && pdnContent.VRM && Array.isArray(pdnContent.VRM)) {
    VRM = [...pdnContent.VRM];
  } else if (pdnContent && pdnContent.VRM) {
    VRM = { ...pdnContent.VRM };
  }

  if (Array.isArray(VRM)) {
    for (let item of VRM) {

      if (item.model && Object.keys(item.model).length > 0) {
        if (item.model.name && item.model.id) {
          vrmIds.push(item.model.id);
          vrmInfo.push({
            id: item.model.id,
            name: item.model.name
          })
        }
      }
    }
  } else {
    if (VRM.model && VRM.model.name && VRM.model.id) {
      vrmIds.push(VRM.model.id);
      vrmInfo.push({
        id: VRM.model.id,
        name: VRM.model.name
      })
    }
  }

  vrmIds = [...new Set([...vrmIds])];
  let newInfo = [];
  vrmIds.forEach(item => {
    let info = vrmInfo.find(vrm => vrm.id === item);
    if (info) {
      newInfo.push(info)
    }
  })
  return { vrmIds, vrmInfo: newInfo };
}

export function getDecapDataIds(pdnContent) {
  let components = [], decapIds = [], decapInfo = [];
  if (pdnContent && pdnContent.Components) {
    components = [...pdnContent.Components];
  }
  for (let item of components) {
    if (item.usage === 'Cap') {
      for (let model of (item.models || [])) {
        if (model && model.id && model.name && model.type === "rlc") {
          decapIds.push(model.id);
          decapInfo.push({
            id: model.id,
            name: model.name
          })
        }
      }
    }
  }
  decapIds = [...new Set([...decapIds])];
  let newInfo = [];
  decapIds.forEach(item => {
    let info = decapInfo.find(decap => decap.id === item);
    if (info) {
      newInfo.push(info)
    }
  })
  return { decapIds, decapInfo: newInfo };
}


export function getVRMDataInfo(vrmIds) {
  return new Promise((resolve, reject) => {
    let vrmData = null;
    if (vrmIds.length > 0) {
      VRMData.getContent(vrmIds).then(res => {
        vrmData = res;
        resolve(vrmData);
      }, error => {
        resolve(vrmData);
      })
    } else {
      resolve(vrmData)
    }
  })
}

export function getDecapDataInfo(decapIds) {
  return new Promise((resolve, reject) => {
    let decapData = null;
    if (decapIds.length > 0) {
      DecapData.getContent(decapIds).then(res => {
        decapData = res;
        resolve(decapData);
      }, error => {
        resolve(decapData);
      })
    } else {
      resolve(decapData)
    }
  })
}
