import LayoutData from '@/services/CeLayoutDB/newCeLayoutData';
import { XLSXTOCSV, uploadStackup } from '../api/designFile';

const stackup = {};

let layData = null;
let stackupData = null;

// property list layout
var layProperty = [{
  item: 'Name',
  value: ''
}, {
  item: 'Type',
  value: ''
}, {
  item: 'Thickness',
  value: ''
}, {
  item: 'Material',
  value: ''
}];

stackup.getLayer = getLayer;

stackup.saveLayer = saveLayer;

stackup.getLayProperty = getLayProperty;

stackup.setData = setData;

stackup.setLayProperty = setLayProperty;

stackup.applyLayProperties = applyLayProperties;

stackup.setLayerVisibility = setLayerVisibility;

stackup.checkError = checkError;

stackup.parseUploadFile = parseUploadFile;

stackup.checkUnit = checkUnit;

//-------------------------------------------------------------------------------------------
/** return the layer list for table display
 *  @param showDielectric  boolean flag, whether or not to include the dielectric layers
 *  @param showComponent   boolean flag, whether or not to include the component layers
 */
function filterLayer(showDielectric, showComponent) {
  // filter the layers based on request parameters
  var filteredLayers = [];
  for (var i in layData) {
    if (layData[i].type == 'Metal') {
      filteredLayers.push(layData[i]);
    }
    else if (layData[i].type == "Dielectric" && showDielectric) {
      filteredLayers.push(layData[i]);
    }
    else if (layData[i].type == 'COMPONENT' && showComponent) {
      filteredLayers.push(layData[i]);
    }
  }
  return filteredLayers;
}

/** return a promise to the layer list for table display
 *  @param showDielectric  boolean flag, whether or not to include the dielectric layers
 *  @param showComponent   boolean flag, whether or not to include the comonent layers
 */
function getLayer(showDielectric, showComponent, reSet) {

  if (!layData || reSet) {
    return setData().then(function () {
      // when the set data promise is fulfilled, return a promise with the grid display data
      return filterLayer(showDielectric, showComponent);
    });
  }
  else {
    return new Promise((resolve, reject) => {
      resolve(filterLayer(showDielectric, showComponent))
    })
  }
};

/** save the stackup in the table back to the server 
 *  @param layers  the data items from the kendo-grid
 */
function saveLayer(layers, unit) {
  if (unit) {
    stackupData.mUnit = "Length_" + unit;
  }
  for (var i in layers) {

    if (layers[i].indexStackup >= 0) {

      // we also need to update the original data since Kendo-grid makes a copy of
      // it and does not synchronize it automatically
      layData[layers[i].indexTable].thickness = layers[i].thickness;

      // update the original stackup data
      stackupData.mLayers[layers[i].indexStackup].mThickness = layers[i].thickness;

      if (layers[i].epsilon) {
        layData[layers[i].indexTable].epsilon = layers[i].epsilon;
        layData[layers[i].indexTable].delta = layers[i].delta;
        stackupData.mLayers[layers[i].indexStackup].mPermittivity = layers[i].epsilon;
        stackupData.mLayers[layers[i].indexStackup].mLossTangent = layers[i].delta;
      }
    }
  }
  return LayoutData.saveStackup();

}; // saveLayer

function getLayProperty() {
  return layProperty;
}

function setLayProperty(layers) {

  layProperty = [{
    item: 'Name',
    value: ''
  },
  {
    item: 'Type',
    value: ''
  },
  {
    item: 'Thickness',
    value: ''
  },
  {
    item: 'Material',
    value: ''
  }];

  if (layers && layers.length > 0) {

    layProperty[0].value = layers[0].name;
    layProperty[1].value = layers[0].type;
    if (layers[0].thickness != undefined)
      layProperty[2].value = layers[0].thickness;
    if (layers[0].material != undefined)
      layProperty[3].value = layers[0].material;

    for (var i = 1; i < layers.length; i++) {

      // cascade the names
      layProperty[0].value = layProperty[0].value + ', ' + layers[i].name;

      // take the common values for the rest of the elements
      if (layProperty[1].value != layers[i].type)
        layProperty[1].value = '';
      if (layers[i].thickness != layProperty[2].value)
        layProperty[2].value = '';
      if (layers[i].material != layProperty[3].value)
        layProperty[3].value = '';

    } // // for (var i = 0; i < layers.length; i++)

  } // if (layers && layers.length > 0)

} // function setLayProperty(layers)

/** Apply the layer property data to the original data
 *  @param layers The selected data items in the layer table. Note that these are
 *                not the original data.
 */
function applyLayProperties(layers) {

  if (!layers || layers.length == 0)
    return;

  var newThickness = layProperty[2].value;
  for (var i = 0; i < layers.length; i++) {

    if (layers[i].indexStackup >= 0) {
      // this is the dataItem from the Kendo-grid
      layers[i].thickness = newThickness;

      // we also need to update the original data since Kendo-grid makes a copy of
      // it and does not synchronize it automatically
      layData[layers[i].indexTable].thickness = newThickness;

      // update the original stackup data
      stackupData.mLayers[layers[i].indexStackup].mThickness = newThickness;
    }

  } // for (var i = 0; i < layers.length; i++)

  LayoutData.saveStackup();

} // function applyLayProperties(layers, properties)

/** get the stackup data from the design folder, and convert it into table display
 *  format
 *  @returns a promise of operation completion
 */
function setData() {

  return LayoutData.getStackup().then(function (layerStack) {

    // save a local reference of the stackup data
    stackupData = layerStack;

    // generate the layer table data
    var layerManager = LayoutData.getLayout().GetLayerManager();
    var layoutSettings = LayoutData.getLayout().GetLayoutSettings();

    layData = [];
    var indexTable = 0;
    var indexMetal = 0;
    for (var i = 0, len = layerStack.mLayers.length; i < len; i++) {

      var electricLayer = layerStack.mLayers[i];
      var layoutLayer = electricLayer.mLayoutLayer;
      var compLayer = null;
      if (layoutLayer) {
        compLayer = layerManager.GetMetalCompLayer(electricLayer.mLayerName);
        indexMetal++;
      }

      if (i == 0 && compLayer) {
        // top component layer
        layData.push({
          indexStackup: -1,  // not a stackup layer
          indexMetal: indexMetal,
          indexTable: indexTable,
          visible: layoutSettings.IsLayerVisible(compLayer.GetName()),
          name: compLayer.GetName(),
          type: "COMPONENT",
        });
        indexTable++;
      }

      // this is a metal or dielectric layer
      layData.push({
        indexStackup: i,     // the index will be used for data write back
        indexMetal: layoutLayer ? indexMetal : null,
        indexTable: indexTable,
        visible: layoutLayer ? layoutSettings.IsLayerVisible(electricLayer.mLayerName) : undefined,
        name: electricLayer.mLayerName,
        type: electricLayer.mLayerType,
        thickness: electricLayer.mThickness,
        material: electricLayer.mMaterialName,
        epsilon: layoutLayer ? null : electricLayer.mPermittivity,
        delta: layoutLayer ? null : electricLayer.mLossTangent
      });
      indexTable++;

      if (i == len - 1 && compLayer) {
        // bottom component layer
        layData.push({
          indexStackup: -1,  // not a stackup layer
          indexMetal: indexMetal,
          indexTable: indexTable,
          visible: layoutSettings.IsLayerVisible(compLayer.GetName()),
          name: compLayer.GetName(),
          type: "COMPONENT",
        });
        indexTable++;
      }

    } // for (var i = 0, len = layerStack.mLayers.length; i < len; i++) {

  }); // LayoutData.getStackup().then(function(layerStack) {

}; // setData

function setLayerVisibility(layerName, visible) {
  LayoutData.getLayout().GetLayoutSettings().SetLayerVisible(layerName, visible);
};

function checkError(data) {
  var error = '';
  var metalLayerNames = layData.filter(function (layer) {
    return layer.type === "Metal";
  });

  var i = data.length, names = [];
  while (i) {
    i--;
    var layer = data[i];
    if (layer.type.toLowerCase() === 'metal') {
      if (i && data[i - 1].type.toLowerCase() === "metal") {
        error = "Two metal layers cannot touch each other";
        return error;
      }

      if (names.indexOf(layer.name) < 0) {
        names.push(layer.name);
      } else {
        error = "Duplicate layer name in the stackup";
        return error;
      }

    }


  }
  if (names.length !== metalLayerNames.length)
    error = "The number of metal layers in the stackup file does not match with the layout";

  return error;
}

function parseUploadFile(readerFile, dataItem) {
  let unit = null;
  const array = LayoutData.CSVtoArray(readerFile);
  let thicknessNum = null, deltaNum = null, epsilonNum = null, name = null;
  for (let i = 0; i < array[0].length; i++) {
    if (thicknessNum && deltaNum && epsilonNum) {
      break;
    }

    if (!name && array[0][i].toLowerCase().indexOf('name') >= 0) {
      name = i;
      continue;
    }

    if (!epsilonNum && (array[0][i].toLowerCase().indexOf('dielectric') >= 0 || array[0][i].toLowerCase().indexOf('epsilon') >= 0)) {
      epsilonNum = i;
      continue;
    }

    if (!deltaNum && (array[0][i].toLowerCase().indexOf('loss') >= 0 || array[0][i].toLowerCase().indexOf('delta') >= 0)) {
      deltaNum = i;
      continue;
    }

    if (!thicknessNum && (array[0][i].toLowerCase().indexOf('thick') >= 0 || array[0][i].toLowerCase().indexOf('height') >= 0)) {
      thicknessNum = i;
      // Support safari
      const reg = /(?:\()[^()]*(?=\))/g;
      const matched = array[0][i].match(reg);
      if (matched) {
        unit = matched[0].replace('(', '').trim().toLowerCase();
      }
      continue;
    }
  }
  dataItem.forEach((item, i) => {
    const info = array.find(arrItem => item.name === arrItem[name]);
    item.thickness = parseFloat(info[thicknessNum]);
    if (item.type = "Dielectric") {
      item.delta = parseFloat(info[deltaNum]);
      item.epsilon = parseFloat(info[epsilonNum]);
    }
  });
  return { data: dataItem, unit };
}

function checkUnit(unit) {
  return UNIT.includes(unit);
}

export default stackup;

export const UNIT = ['mil', 'um', 'mm'];

export function changeExcelToCSV(file) {
  return new Promise((resolve, reject) => {
    XLSXTOCSV(file).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
    })
  })
}

export function uploadDatFile(file) {
  return new Promise((resolve, reject) => {
    uploadStackup(LayoutData.getDesignId(), file).then(res => {
      resolve(res)
    }, error => {
      reject(error);
    })
  })
}