import { getHimalayasSimulationLog } from "../api/Himalayas/simulationCtrl";
import apiProcess from "../api/utility";

/**
 * get simulation log 
 * 2023/05/30
 * @export
 * @param {string} verificationId verificationId 
 * @returns Promise
 */
export function getSimulationLog(verificationId) {
  return apiProcess(getHimalayasSimulationLog, verificationId, false, false, true)
}