import { PMIC, IC, DIE, BGA, LOAD } from "../../../constants/componentType";
import { AC, PACKAGE } from "../../../constants/treeConstants";
import { newNanoId } from "../../helper/idHelper";
import { CONNECTOR } from "../constants";
import { DCPreComponent, Decap, DecapGroup, PreLayoutComponent, PreLayoutContent } from "./prelayoutClass";

function initNewPreLayout(designType, electric) {
  if (electric === AC) {
    return initACPreLayout(designType);
  }
  return initDCPreLayout()
}

function initACPreLayout(designType) {
  const powerNets = designType === PACKAGE ? ['VCC'] : ['VDD'];
  const referenceNets = designType === PACKAGE ? ['VSS'] : ['GND'];
  const pins = createPins(powerNets, referenceNets);
  const ic = new PreLayoutComponent({
    name: designType === PACKAGE ? 'BGA' : 'U1',
    type: designType === PACKAGE ? BGA : IC,
    pins
  })
  const pmic = new PreLayoutComponent({
    name: designType === PACKAGE ? 'DIE' : 'U2',
    type: designType === PACKAGE ? DIE : PMIC,
    pins
  })
  return new PreLayoutContent({ powerNets, referenceNets, components: [ic, pmic] })
}

function createPins(powerNets, referenceNets) {
  const nets = [...powerNets, ...referenceNets];
  return nets.map(net => ({ pin: net, pinName: net, net: net, node: "" }))
}

function initDecapGroup(powerNet, referenceNet, groupName) {
  const decap = new Decap({ name: 'Decap_1', number: "1", l_layout: "", r_layout: "" })
  return new DecapGroup({ name: groupName, powerNet, referenceNet, l_group: "", r_group: "", decaps: [decap] })
}

function initDCPreLayout() {
  const powerNet = 'VDD';
  const referenceNet = 'GND';
  const pins = createPins([powerNet], [referenceNet]);
  const ic = new DCPreComponent({
    id: newNanoId(8),
    name: 'U1',
    type: LOAD,
    pins,
    row: 0,
    column: 1
  })
  const connector = new DCPreComponent({
    id: newNanoId(8),
    name: 'J1',
    type: CONNECTOR,
    pins,
    row: 0,
    column: 0
  })
  return {
    components: [connector, ic],
    nets: [
      { id: newNanoId(8), name: 'VDD', components: [connector.name, ic.name], value: '0', level: 0 },
      { id: newNanoId(8), name: 'GND', components: [connector.name, ic.name], value: '0', level: 0 , isGnd: true }
    ]
  }
}

export {
  initNewPreLayout,
  initDecapGroup
}