
class EyeConstructor {
  constructor() {
    this.eyeParameterList = null;
    this.eyePicList = null;
  }

  updateEyeParamList(eyeParamList) {
    if (this.eyeParameterList) {
      const updateData = new Map([...this.eyeParameterList.getData(), ...eyeParamList.getData()]);
      this.eyeParameterList.updateData(updateData);
    } else {
      this.eyeParameterList = eyeParamList; // DdrResultEyeParam class
    }
  };

  updateEyePinList(eyePicList) {
    if (this.eyePicList) {
      const updateData = new Map([...this.eyePicList.getData(), ...eyePicList.getData()]);
      this.eyePicList.updateData(updateData);
    } else {
      this.eyePicList = eyePicList;  // DdrResultEyePic class
    }
  }
};

export default EyeConstructor;