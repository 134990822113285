import {
  CLOSE_TAB_FOOTER, OPEN_TAB_FOOTER, CHANGE_TAB_MENU, CHANGE_PROJECT,
  CHANGE_VERIFICATION, CLEAN_MONITOR_MSG, DEBUG_MONITOR,
  CHANGE_CCC_MENU,
  CLEAN_TAB_MONITOR_STATUS
} from './actionTypes';
import { USER_LOGOUT } from '../Login/actionTypes';

const defaultState = {
  tabVisible: false,
  tabSelectKeys: ['monitor'],
  projectName: null,
  verificationName: null,
  menuType: 'simulation',
  currentVerificationId: null,
  debugMonitor: [],
  cccMenu: [],
  currentProjectId: null
}

export const TabMonitorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLOSE_TAB_FOOTER:
      return {
        ...state,
        tabVisible: false
      }
    case OPEN_TAB_FOOTER:
      return {
        ...state,
        tabVisible: true
      }
    case CHANGE_TAB_MENU:
      return {
        ...state,
        tabSelectKeys: action.selectKeys,
        menuType: action.menuType,
        currentVerificationId: action.verificationId,
        currentProjectId: action.projectId,
        verificationName: action.verificationName ? action.verificationName : state.verificationName,
        projectName: action.projectName ? action.projectName : state.projectName,
      }
    case CHANGE_PROJECT:
      return {
        ...state,
        projectName: action.projectName,
        verificationName: null,
        currentVerificationId: null
      }
    case CHANGE_VERIFICATION:
      return {
        ...state,
        tabVisible: true,
        verificationName: action.verificationName,
        currentVerificationId: action.verificationId ? action.verificationId : state.currentVerificationId,
        currentProjectId: action.projectId ? action.projectId : state.currentProjectId
      }
    case CLEAN_MONITOR_MSG:
      return {
        ...state,
        projectName: null,
        verificationName: null,
        currentVerificationId: null,
        tabVisible: false,
      }
    case DEBUG_MONITOR:
      return {
        ...state,
        debugMonitor: [...state.debugMonitor, action.msg]
      }
    case CHANGE_CCC_MENU:
      return {
        ...state,
        cccMenu: action.cccMenu
      }
    case CLEAN_TAB_MONITOR_STATUS:
      return {
        ...defaultState
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    default: return state
  }
};