import CeLayoutDB from '../CeLayoutDB/CeLayoutDB';
import CeLayerStack from '../CeLayoutDB/CeLayerStack';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import CePartDB from '../partlib/CePartDB';
import getDesignFile, { getAuroraDBZipFile, getDesignStackupJson, getDesignLayers } from '../api/designFile';
import { getDesignInfoPromise } from '../api/Design/design';
import { LAYOUT } from '../../constants/designType';
import * as vendorMap from '@/constants/designVendor';
import { message } from 'antd';
import { uploadStackup } from '../api/designFile';
import { ReExtractionByDesign, editDesignStackup, ReExtractionByDesignNew } from '../api/designFile';
import unzipFile from '../helper/unzip';
import StackupJsonData from './stackupJsonData';
import CanvasObjectList from '../LayoutCanvas/CanvasObjectList';
import CanvasObject from '../LayoutCanvas/CanvasObject';
import GCPolygon from '../geometry/GCPolygon';
import CeCircle from '../geometry/CeCircle';
import CePolygon from '../geometry/CePolygon';
import CeRectangle from '../geometry/CeRectangle';
import CeLine from '../geometry/CeLine';
import CanvasPath from '../LayoutCanvas/CanvasPath';
import hash from 'object-hash';
import { getLayoutAllComponents } from '../PCBHelper/net';

function DesignData() {
  this.partDB = null; // CePartDB()
  this.layoutDB = null;  // CeLayoutDB();
  this.stackup = null;
  this.LayoutInfo = null;
  this.componentsList = null;
  this.netsList = null;
}

const msg1 = 'Translation failed: can not load layout DB',
  msg2 = 'Translation failed: can not load part DB';
class LayoutData {
  constructor() {
    this.designDB = new Map();
    this.loadLayers = new Map();
  }

  cleanLayoutInfo = (pcbId) => {
    this.designDB.delete(pcbId);
    this.loadLayers.delete(pcbId);
  }

  loadAllMetalLayers(metalLayerNames, pcbId) {
    return new Promise((resolve, reject) => {
      getAuroraDBZipFile(pcbId, 'layers').then(layersRes => {
        // partsRes - ArrayBuffer
        unzipFile(layersRes).then(layers => {
          let _designDB = this.designDB.get(pcbId);
          let errorMsg = '';
          const loadLayers = this.loadLayers.get(pcbId) || [];
          metalLayerNames.filter(layer => !loadLayers.includes(layer)).forEach(layerName => {
            const layerContent = layers[`layers/${layerName}.lyr`]
            let { layoutDB } = _designDB;
            try {
              if (layoutDB.GetLayerManager().ReadMetalLayerData(layerName, layerContent, layoutDB)) {
                console.log('success to load metallayer ' + layerName);
                const index = layoutDB.mLayerMgr.mMetalLayers.findIndex(item => item.mName === layerName);
                layoutDB.mLayerMgr.mMetalLayers[index].load = true;
                _designDB.layoutDB = layoutDB;
                this.designDB.set(pcbId, _designDB);
              } else {
                const emsg = 'Failed to load metallayer ' + layerName;
                console.log(emsg);
                errorMsg += emsg + '\n';
              }
            } catch (error) {
              reject('Failed to load metallayer ' + layerName + '. ' + error)
            }
          });
          this.prepareData(_designDB);
          this.setNewLoadLayers(metalLayerNames, pcbId)
          if (errorMsg) {
            reject(errorMsg)
          } else {
            resolve(true)
          }
        }, error => {
          reject(error)
        }); // unzipFile(layersRes).then(layers => { ... })
      }, error => {
        reject(error)
      }); // getAuroraDBZipFile(pcbId, 'layers').then(layersRes => { ... })
    })
  }

  loadSpecifyLayers = (layers, designId) => {
    return new Promise((resolve, reject) => {
      const loadLayers = this.loadLayers.get(designId) || [];
      const _layers = layers.filter(item => !loadLayers.includes(item));
      if (!_layers.length) {
        resolve(true)
        return;
      }
      getDesignLayers({ designId, layers: _layers }).then(layersRes => {
        unzipFile(layersRes).then(layersData => {
          let _designDB = this.designDB.get(designId);
          let errorMsg = '', newLayers = [];
          layers.forEach(layerName => {
            const layerContent = layersData[`${layerName}.lyr`]
            let { layoutDB } = _designDB;
            try {
              if (layoutDB.GetLayerManager().ReadMetalLayerData(layerName, layerContent, layoutDB)) {
                console.log('success to load metallayer ' + layerName);
                const index = layoutDB.mLayerMgr.mMetalLayers.findIndex(item => item.mName === layerName);
                layoutDB.mLayerMgr.mMetalLayers[index].load = true;
                _designDB.layoutDB = layoutDB;
                this.designDB.set(designId, _designDB);
                newLayers.push(layerName)
              } else {
                const emsg = 'Failed to load metallayer ' + layerName;
                console.log(emsg);
                errorMsg += emsg + '\n';
              }
            } catch (error) {
              reject('Failed to load metallayer ' + layerName + '. ' + error)
            }
          });
          this.prepareData(_designDB);
          this.setNewLoadLayers(newLayers, designId)
          if (errorMsg) {
            reject(errorMsg)
          } else {
            resolve(true)
          }
        }, error => {
          reject(error)
        }); // unzipFile(layersRes).then(layers => { ... })
      }, error => {
        reject(error)
      })
    })
  }

  setNewLoadLayers = (newLayers, designId) => {
    const oldLayers = this.loadLayers.get(designId) || [];
    this.loadLayers.set(designId, [...new Set([...newLayers, ...oldLayers])])
  }

  prepareData = (_designDB) => {
    if (!_designDB || !_designDB.layoutDB) {
      return;
    }
    const mLayerMgr = _designDB.layoutDB.mLayerMgr;
    const mPartMgr = _designDB.layoutDB.mPartMgr;
    var compList = mLayerMgr.GetAllLayerComponents();
    // get the unit, it will influence the calculation of outline
    var unit = _designDB.layoutDB.mUnits;
    if (compList == null) {
      console.log("Unable to get the component layer from metal layer.");
      return;
    }

    _designDB.compObjList = [];
    for (var i = 0; i < compList.length; i++) {
      //mLayerName
      const layerName = compList[i].mLayerName;

      var compName = compList[i].GetName();
      var cePart = compList[i].mPart;
      var footPrintSymbol = cePart.GetFootPrintSymbol(mPartMgr);
      var compObj = {
        name: compName,
        svgGrp: null,
        outlineObj: null,
        objList: new CanvasObjectList(),
        selected: false
      };
      _designDB.compObjList.push(compObj);

      // create a template object that contains detailed object information
      var templateObj = new CanvasObject();
      templateObj.layer = layerName;
      templateObj.type = 'pin';
      templateObj.comp = compName;

      // draw pads
      var pads = footPrintSymbol ? footPrintSymbol.GetPadGeometries() : [];
      var minY, minX = minY = Number.MAX_VALUE;
      var maxY, maxX = maxY = Number.MIN_VALUE;
      if (pads) {
        let pinLocations = [];
        for (var j = 0; j < pads.length; j++) {
          const num = footPrintSymbol.mMetalLayerList[0].mPadList[j].mPadID;
          const pinInfo = cePart.GetPinByPinNumber(num);
          const pinName = pinInfo.mName;
          const pinNumber = pinInfo.mNumber;
          const toolTip = `${compName}: ${pinNumber}:: ${pinName}`;

          templateObj.pin = pinName;
          templateObj.pinNumber = pinNumber;
          templateObj.toolTip = toolTip;
          const canvasObj = compObj.objList.addGeometry(pads[j], compList[i].mLocation, templateObj);
          //ADD pin location in list
          pinLocations.push({ pin: pinName, pinNumber, canvasObj, unit });

          if (pads[j] instanceof GCPolygon) {
            minX = Math.min(minX, Math.min.apply(null, pads[j].mXs));
            minY = Math.min(minY, Math.min.apply(null, pads[j].mYs));
            maxX = Math.max(maxX, Math.max.apply(null, pads[j].mXs));
            maxY = Math.max(maxY, Math.max.apply(null, pads[j].mYs));
          } else if (pads[j] instanceof CeCircle) {
            var centerX = pads[j].mCenter.getX();
            var centerY = pads[j].mCenter.getY();
            var r = pads[j].mDiameter / 2;
            minX = Math.min(minX, centerX - r);
            minY = Math.min(minY, centerY - r);
            maxX = Math.max(maxX, centerX + r);
            maxY = Math.max(maxY, centerY + r);
          } else {
            console.log(pads[j])
          }
        }

        //set component pins location list
        compList[i].SetPinsLocation(pinLocations);
      }

      // draw the outline
      // if(unit === 'mm'){
      // 	minX -= 0.2; minY-= 0.2; maxX += 0.2; maxY += 0.2;
      // }else{
      // 	minX -= 15; minY-= 15; maxX += 15; maxY += 15;
      // }

      var outline;
      if (footPrintSymbol && footPrintSymbol.mOutline) {
        outline = footPrintSymbol.mOutline.ConvertToGCPolygon();
      } else {

        outline = new CePolygon();
        if (!footPrintSymbol || !footPrintSymbol.mMetalLayerList || !footPrintSymbol.mMetalLayerList.length) {
          console.error('foot print symbol don\'t have metal layer: ' + (footPrintSymbol ? footPrintSymbol.mID : ""));

        } else {
          var logicLayers = footPrintSymbol.mMetalLayerList[0].mLogicLayers;
          if (footPrintSymbol.mMetalLayerList.length > 1) console.log(footPrintSymbol.mMetalLayerList.length)
          for (var iLayer = 0; iLayer < logicLayers.length; iLayer++) {
            //
            if (logicLayers[iLayer].GetType() !== "DOCUMENT") continue;
            var geomList = logicLayers[iLayer].mGeomList.slice(0);
            //if (geomList.length < 3) console.log('geomList: '+geomList.length);
            var start, end;
            for (var iGeom = 0; iGeom < geomList.length; iGeom++) {
              var geometry = geomList[iGeom].mGeometry;
              if (geometry instanceof CeRectangle) {
                var center = geometry.mCenter;
                start = {
                  mX: geometry.mCenter.mX - geometry.mWidth / 2,
                  mY: geometry.mCenter.mY - geometry.mHeight / 2
                };

                end = {
                  mX: geometry.mCenter.mX + geometry.mWidth / 2,
                  mY: geometry.mCenter.mY + geometry.mHeight / 2
                };
              } else if (geometry instanceof CeLine) {
                start = geometry.GetStart();
                end = geometry.GetEnd();
              } else {
                console.log('error: don\'t support this geometry')
              }

              minX = Math.min(minX, start.mX, end.mX);
              minY = Math.min(minY, start.mY, end.mY);
              maxX = Math.max(maxX, start.mX, end.mX);
              maxY = Math.max(maxY, start.mY, end.mY);
            };
          }
        }
        outline.AddVertex(minX, minY);
        outline.AddVertex(minX, maxY);
        outline.AddVertex(maxX, maxY);
        outline.AddVertex(maxX, minY);
        outline = outline.ConvertToGCPolygon();
      }

      if (compList[i].mLocation) {
        compList[i].mLocation.TransformGCPolygon(outline);
      }

      compObj.outlineObj = new CanvasPath();
      compObj.outlineObj.pathData = outline.GetSvgPath();
      compObj.outlineObj.layer = layerName;
      compObj.outlineObj.type = 'component';
      compObj.outlineObj.comp = compName;

    }
    return _designDB;
  };

  getLayers = ({ layoutDB, pcbId, loadCurrentLayer }) => {
    return new Promise((resolve, reject) => {
      var layerMgr = layoutDB.GetLayerManager();
      var metalLayerNames = layerMgr.GetAllMetalLayers().GetJSArrary() || [];
      if (Array.isArray(loadCurrentLayer) && loadCurrentLayer.length) {
        const metalLayers = [...loadCurrentLayer];
        this.loadSpecifyLayers(metalLayers, pcbId).then(res => {
          resolve(true);
        }, error => {
          reject(error)
        })
      } else if (loadCurrentLayer) {
        const metalLayers = [metalLayerNames[0], metalLayerNames[metalLayerNames.length - 1]];
        this.loadSpecifyLayers(metalLayers, pcbId).then(res => {
          resolve(true);
        }, error => {
          reject(error)
        })
      } else {
        const loadLayers = this.loadLayers.get(pcbId) || [];
        if (loadLayers.length) {
          const _layers = metalLayerNames.filter(item => !loadLayers.includes(item));
          this.loadSpecifyLayers(_layers, pcbId).then(res => {
            resolve(true);
          }, error => {
            reject(error)
          })
        } else {
          this.loadAllMetalLayers(metalLayerNames, pcbId).then(res => {
            resolve(true);
          }, error => {
            reject(error)
          })
        }
      }
    })
  }

  getLayoutDB = ({ pcbId, layoutDB, partDB, _designDB, loadCurrentLayer }) => {
    return new Promise((resolve, reject) => {
      getAuroraDBZipFile(pcbId, 'layout.db').then(layoutRes => {
        unzipFile(layoutRes).then(layout => {
          const layoutContent = layout['layout.db'];
          console.log("Read layout DB file data.");
          var ceBlock = new CeIODataBlock("CeLayout");
          ceBlock.ReadBlockFromFile(layoutContent);

          layoutDB = new CeLayoutDB();
          layoutDB.SetPartDB(partDB);
          try {
            if (layoutDB.LoadDB(ceBlock, layoutDB)) {
              console.log("Succeed to load layout DB.");
              _designDB.layoutDB = layoutDB;
              this.designDB.set(pcbId, _designDB);
              this.getLayers({ layoutDB, pcbId, loadCurrentLayer }).then(res => {
                resolve(res)
              }, error => {
                reject(error)
                return;
              })
            } else {
              message.error(msg1)
              console.log("Fail to load layout DB.");
              reject(msg1);
              return;
            }
          } catch (error) {
            reject(msg1 + error)
            return;
          }
        }) // unzipFile(layoutRes).then(layout => {...})
      }, error => {
        //todo
        message.error(msg1)
        console.log("Fail to load layout DB." + error);
        reject(msg1 + error)
        return;
      })
    })
  }

  loadDB = ({ layoutDB, pcbId, partDB, _designDB, loadCurrentLayer }) => {
    return new Promise((resolve, reject) => {
      if (!layoutDB) {
        // Step 2
        // // get the layout DB file
        this.getLayoutDB({ pcbId, layoutDB, partDB, _designDB, loadCurrentLayer }).then(res => {
          resolve(true);
        }, error => {
          reject(error);
          return;
        })

      } else {
        // Step 3
        this.getLayers({ layoutDB, pcbId, loadCurrentLayer }).then(res => {
          resolve(res)
        }, error => {
          reject(error)
          return;
        })
      };
    })
  }

  LoadLayoutDB = (pcbId, loadCurrentLayer = false, onlyPartDB = false) => {
    // get the part DB file
    // check the design graphics

    // loadCurrentLayer: true - only load comp layer, false- load all layer, array - load current layer

    // mLayerIDNameMap
    return new Promise((resolve, reject) => {
      let _designDB = this.designDB.get(pcbId);
      let load = true;
      if (_designDB && _designDB.layoutDB) {
        const _layerMgr = _designDB.layoutDB.GetLayerManager();
        if (_layerMgr) {
          if (onlyPartDB && _designDB && _designDB.partDB) {
            load = false
          }
          if (!onlyPartDB) {
            const _metalLayerNames = _layerMgr.GetAllMetalLayers().GetJSArrary();
            if (_metalLayerNames.length === _designDB.layoutDB.mLayerMgr.mMetalLayers.length) {
              const map = _designDB.layoutDB.mLayerMgr.mMetalLayers.map(item => item.load);
              if (!loadCurrentLayer) {
                if (map.every(item => !!item)) {
                  load = false;
                }
              } else if (Array.isArray(loadCurrentLayer) && loadCurrentLayer.length) {
                load = true
              } else {
                if (map.length && map[0] && map[map.length - 1]) {
                  load = false;
                }
              }
            }
          }
        }
      }
      if (!load) {
        resolve(true);
      } else {
        if (!_designDB) {
          _designDB = new DesignData();
        }
        let { partDB, layoutDB } = _designDB;
        if (!partDB) {
          getAuroraDBZipFile(pcbId, 'parts.db').then(partsRes => {
            // partsRes - ArrayBuffer
            unzipFile(partsRes).then(parts => {
              const partsContent = parts['parts.db'];
              console.log("Read part DB file data.");
              var ceBlock = new CeIODataBlock("CeParts");

              ceBlock.ReadBlockFromFile(partsContent);
              partDB = new CePartDB();
              try {
                if (partDB.LoadDB(ceBlock)) {
                  console.log("Succeed to load part DB.");
                  _designDB.partDB = partDB;
                  this.designDB.set(pcbId, _designDB);
                } else {
                  message.error(msg2);
                  console.log("Fail to load part DB.");
                  reject(msg2);
                  return;
                };
              } catch (error) {
                message.error(msg2);
                console.log("Fail to load part DB.");
                reject(msg2 + error);
                return;
              }
              if (!onlyPartDB) {
                this.loadDB({ layoutDB, pcbId, partDB, _designDB, loadCurrentLayer }).then(res => {
                  resolve(true)
                }, error => {
                  reject(error)
                })
              } else {
                resolve(true)
              }
            }); // unzipFile(res).then(parts => {...})
          }); // getAuroraDBZipFile (pcbId, 'parts.db')
        } else if (!onlyPartDB) {
          this.loadDB({ layoutDB, pcbId, partDB, _designDB, loadCurrentLayer }).then(res => {
            resolve(true)
          }, error => {
            reject(error)
          })
        } else {
          resolve(true)
        };
      }
    });
  };

  getLayout(pcbId, notLoad) {
    if (this.designDB.has(pcbId)) {
      return this.designDB.get(pcbId).layoutDB;
    } else if (notLoad) {
      return undefined;
    } else {
      return new CeLayoutDB();
    }
  };

  /**
   *  @returns a promise object so that the call can handle the result
   */
  getStackup(pcbId, reload, version, determineMetalLayer) {
    return new Promise((resolve, reject) => {
      this.requestStackup(pcbId, reload, version, determineMetalLayer).then(res => {
        resolve(res);
      })
    });
  }; // LayoutData.getStackup

  requestStackup(pcbId, reload = false, version, determineMetalLayer) {
    //version FASTPI
    let _designDB = this.designDB.get(pcbId);
    return new Promise((resolve, reject) => {
      if (!_designDB) {
        resolve(null);
        return;
      }
      if (!reload && _designDB && _designDB.stackup) {
        resolve(_designDB.stackup);
      } else {
        getDesignFile(pcbId, 'stackup.dat').then((fileContent) => {
          // file download successful, create a stackup object and parse the file content
          const _LayerManager = _designDB.layoutDB.GetLayerManager();
          _designDB.stackup = new CeLayerStack(_LayerManager);
          var failed = false;
          var stackupBlock = new CeIODataBlock("Stackup");
          if (!stackupBlock.ReadBlockFromFile(fileContent.data)) {
            failed = true;
          } else if (!_designDB.stackup.ReadFromIODataNode(stackupBlock)) {
            failed = true;
          }
          if (!failed && determineMetalLayer) {
            if (!stackupBlock.mBlockData.find(item => ["Dielectric", "Metal"].includes(item.mName))) {
              //If no metal layer/dielectric layer data is found, update the data
              failed = true;
            }
          }
          if (failed) {
            // failed to get the remote file, create a default stackup
            var unit = _designDB.layoutDB.GetLayoutUnits().toLowerCase();
            if (!_designDB.stackup.createLayerStackFromLayout(62, unit, version)) {
              _designDB.stackup.createLayerStackFromLayout(93, unit, version);
            }
            // and save it to the remote server
            resolve(_designDB.stackup);
          } else {
            // TODO - for now the layout DB unit is simply a string, and is not using the definitions
            //        in CsUnits. On the other hand, CeLayerStack is using the CsUnits definitions.
            //        We need to merge the usage of units at some point.
            if (_designDB.stackup.getUnitString() === "Unknown") {
              _designDB.stackup.setUnitFromString(_designDB.layoutDB.GetLayoutUnits());
            }
            resolve(_designDB.stackup);
          }
          _designDB.stackupFileContent = fileContent.data;
          this.designDB.set(pcbId, _designDB);
        }, (error) => {
          _designDB.stackup = new CeLayerStack(_designDB.layoutDB.GetLayerManager());
          // failed to get the remote file, create a default stackup
          var unit = _designDB.layoutDB.GetLayoutUnits().toLowerCase();
          if (!_designDB.stackup.createLayerStackFromLayout(62, unit, version)) {
            _designDB.stackup.createLayerStackFromLayout(93, unit, version);
          }
          // and save it to the remote server
          this.saveStackup(pcbId);
          resolve(_designDB.stackup);
          this.designDB.set(pcbId, _designDB);
        });
      }
    })
  }

  getStackupMaterials = (pcbId) => {
    let _designDB = this.designDB.get(pcbId);
    if (!_designDB) {
      return null;
    }
    if (_designDB && _designDB.stackup && _designDB.stackup && _designDB.stackup.stackup && _designDB.stackup.stackup.materials) {
      return _designDB.stackup.stackup.materials;
    }
    return null;
  }

  /**
   *  @returns a promise object so that the call can handle the result
   */
  getStackupJson = ({ pcbId, version, pageType, reload = false, save = false, determineMetalLayer }) => {
    return new Promise((resolve, reject) => {
      this.requestStackupJson({ pcbId, version, pageType, reload, save, determineMetalLayer }).then(res => {
        resolve(res);
      })
    });
  };

  requestStackupJson = ({ pcbId, version = "1.0", pageType, reload = false, save = false, determineMetalLayer }) => {
    let _designDB = this.designDB.get(pcbId);
    return new Promise((resolve, reject) => {
      if (!_designDB) {
        _designDB = new DesignData();
        this.designDB.set(pcbId, _designDB)
        /* return; */
      }
      if (!reload && _designDB && _designDB.stackup) {
        resolve(_designDB.stackup);
      } else {
        getDesignStackupJson(pcbId).then((content) => {
          if (content) {
            const stackupData = new StackupJsonData();
            stackupData.initStackup(content, pcbId);
            _designDB.stackup = stackupData;
            if (determineMetalLayer && (!content.layers || !content.layers.length)) {
              _designDB.stackup = this.setDefaultStackup(_designDB, pageType, version);
              editDesignStackup({ designId: pcbId, stackup: { ..._designDB.stackup.stackup } })
            }
            resolve(_designDB.stackup);
          } else {
            _designDB.stackup = this.setDefaultStackup(_designDB, pageType, version);
            if (save) {
              editDesignStackup({ designId: pcbId, stackup: { ..._designDB.stackup.stackup } })
            }
            this.designDB.set(pcbId, _designDB)
            resolve(_designDB.stackup);
          }
        }, (error) => {
          _designDB.stackup = this.setDefaultStackup(_designDB, pageType, version);
          if (save) {
            editDesignStackup({ designId: pcbId, stackup: { ..._designDB.stackup.stackup } })
          }
          this.designDB.set(pcbId, _designDB)
          resolve(_designDB.stackup);
        });
      }
    })
  }

  setDefaultStackup = (_designDB, pageType, version) => {
    _designDB.stackup = new StackupJsonData();
    const _LayerManager = _designDB.layoutDB.GetLayerManager();
    const unit = _designDB.layoutDB.GetLayoutUnits().toLowerCase();
    _designDB.stackup.createStackup({ _LayerManager, boardThickness: 93, strUnitName: unit, version, pageType })
    return _designDB.stackup;
  }

  /** Save the stackup data to the web server. */
  saveStackupJson = (pcbId, { data, unit, stackups, zones, bends, materialList }, isRead = false) => {
    const _designDB = this.designDB.get(pcbId);
    // Compare stackup data
    if (!_designDB.stackup) {
      return;
    }
    let stackupData = _designDB.stackup;
    const oldStackup = JSON.parse(JSON.stringify(stackupData.getStackup()));
    const version = oldStackup.version;
    let _stackups = stackups, _zones = zones, _bends = bends;
    if (isRead) {
      const stackupInfo = stackupData.getStackup();
      _stackups = stackupInfo.stackups;
      _zones = stackupInfo.zones;
      _bends = stackupInfo.bends;
    }
    const stackup = stackupData.changeStackupData({ data, materialList, stackups: _stackups, zones: _zones, bends: _bends, unit, version: version });
    _designDB.stackup = stackupData;
    return stackup;
  };

  getLayoutInfo(pcbId) {
    let _designDB = this.designDB.get(pcbId);
    return new Promise((resolve, reject) => {
      if (_designDB && _designDB.LayoutInfo) {
        resolve(_designDB.LayoutInfo);
      } else {
        getDesignInfoPromise(pcbId).then(res => {
          const { type, name, projectId, vendor } = res;
          let layoutType;
          if (type === LAYOUT) {
            layoutType = 'layout';
          }
          const vendorNames = Object.keys(vendorMap);
          const vendorIds = Object.values(vendorMap);
          const index = vendorIds.indexOf(vendor);

          if (!_designDB) {
            _designDB = new DesignData();
          };
          _designDB.LayoutInfo = {
            layoutType: layoutType,
            designName: name,
            projectId: projectId,
            vendor: index > 0 ? vendorNames[index] : ''
          };
          this.designDB.set(pcbId, _designDB);
          resolve({ layoutType, designName: name, projectId, vendor: index > 0 ? vendorNames[index] : '' });
        }, error => {
          console.error(error);
        })
      }
    })
  }

  _getStackup(pcbId) {
    return new Promise((resolve, reject) => {
      getDesignFile(pcbId, 'stackup.dat').then(fileContent => {
        let stackupBlock = new CeIODataBlock("Stackup");
        stackupBlock.ReadBlockFromFile(fileContent.data);
        const layerItemList = stackupBlock.GetAllBlockItems();
        if (!layerItemList) {
          resolve(false);
        }
        let stackup = {
          unit: null,
          metalLayerList: [],
        };
        for (let iLayer of layerItemList) {
          const itemName = iLayer.GetName().toUpperCase();
          if (itemName === "UNIT") {
            stackup.unit = iLayer.GetItemValue().get(0);
          } else if (itemName === 'METAL') {
            let layerParams = iLayer.GetItemValue();
            let layer = {
              type: 'Metal',
              name: layerParams.get(0),
              mThickness: layerParams.size() > 1 ? Number(layerParams.get(1)) : 1.4,
              mMaterialName: "Copper",
              mMaterialVendor: "Generic"
            }
            stackup.metalLayerList.push(layer);
          }
        }
        resolve(stackup);
      }, error => {
        resolve(false);
      });
    });
  }

  saveComponentsList(pcbId, layoutDB) {
    const layout = pcbId ? this.getLayout(pcbId) : null;
    const metalLayers = layout && layout.GetLayerManager ? layout.GetLayerManager().mMetalLayers : layoutDB.GetLayerManager().mMetalLayers;
    const COMPS = [];
    for (const layer of metalLayers) {
      const { mComponentLayer } = layer;
      if (!mComponentLayer) continue;
      const { mComponents } = mComponentLayer;
      for (const component of mComponents) {
        COMPS.push({
          name: component.mName,
          part: component.mPart.mInfo.mPartName,
          pin: component.mPart.mPinList.length,
          layer: component.mLayerName,
          pinList: component.mPart.mPinList,
          mPinsLocationList: component.mPinsLocationList
        })
      }
    }
    return COMPS;
  }

  setCompPinsNets(pcbId) {
    const compPinsNets = getLayoutAllComponents({ LayoutData: this.getLayout(pcbId), isGetNet: true });

    let _designDB = this.designDB.get(pcbId);
    if (!_designDB) {
      _designDB = {}
    }
    _designDB.compPinsNets = compPinsNets || {};
    this.designDB.set(pcbId, _designDB);
  }

  getCompPinsNets(pcbId) {
    let _designDB = this.designDB.get(pcbId) || {};
    if (!_designDB.compPinsNets || !Object.keys(_designDB.compPinsNets).length) {
      this.setCompPinsNets(pcbId);
    }

    return this.designDB.get(pcbId).compPinsNets;
  }

  saveNetsList(pcbId) {
    const layout = this.getLayout(pcbId);
    if (!layout) {
      return
    }
    const netList = this.getLayout(pcbId).mNetManager.GetNetList().mVals;
    const NETS = [];
    for (const net of netList) {
      const { mName, mPinList, mViaList, mType } = net;
      const components = [], layers = [];
      for (const pin of mPinList) {
        const { mCompName, mLayerName } = pin;
        if (!components.includes(mCompName)) components.push(mCompName);
        if (!layers.includes(mLayerName)) layers.push(mLayerName);
      }
      NETS.push({
        name: mName,
        pin: mPinList.length,
        via: mViaList.length,
        type: mType,
        comp: components.length,
        layer: layers.length
      })
    };
    let _designDB = this.designDB.get(pcbId);
    _designDB.netsList = NETS
    this.designDB.set(pcbId, _designDB);
  }

  getComponentsList(pcbId) {
    if (this.designDB.has(pcbId)) {
      return this.saveComponentsList(pcbId) || [];
    }
    return [];
  }

  getComponent(pcbId, compName) {
    if (this.designDB.has(pcbId)) {
      const components = this.saveComponentsList(pcbId) || [];
      return components.find(item => item.name === compName);
    }
    return null;
  }

  getNetsList(pcbId) {
    if (this.designDB.has(pcbId)) {
      return this.designDB.get(pcbId).netsList || [];
    };
    return [];
  }

  reExtractionStackup(prev, update) {
    const CsUnitList = {
      Length_m: "m",
      Length_cm: "cm",
      Length_mm: "mm",
      Length_um: "um",
      Length_inch: "inch",
      Length_mil: "mil",
      Length_mils: "mil",
      Unknown: "Unknown"
    };
    let prevStack = {
      unit: '',
      layers: []
    };
    const layerNames = update.mLayers.map(item => item.mLayerName);
    const lines = prev.match(/[^\r\n]+/g);
    if (lines && lines.length) {
      for (let line of lines) {
        const _line = line.match(/[^\s\t]+/g);
        if (_line[0].toLowerCase() === 'unit') {
          if (_line[1] !== CsUnitList[update.mUnit]) {
            return true;
          };
          prevStack.unit = _line[1];
        } else if (_line[0].toLowerCase() === 'metal') {
          const name = _line[1], thickness = _line[2] || null, conductivity = _line[3] || null;
          const index = layerNames.indexOf(name);
          if (index < 0) {
            return true;
          };
          const item = update.mLayers[index];
          if (item.mThickness != thickness || item.mConductivity != conductivity) {
            return true;
          }
          prevStack.layers.push({
            type: 'Metal',
            name: name,
            thickness: thickness,
            conductivity: conductivity
          });
        } else if (_line[0].toLowerCase() === 'dielectric') {
          const name = _line[1], thickness = _line[2] || null, dielectric = _line[3] || null, loss = _line[4] || null;
          const index = layerNames.indexOf(name);
          if (index < 0) {
            return true;
          };
          const item = update.mLayers[index];
          if (item.mThickness != thickness || item.mLossTangent != loss || item.mPermittivity != dielectric) {
            return true;
          }
          prevStack.layers.push({
            type: 'Dielectric',
            name: name,
            thickness: thickness,
            dielectric: dielectric,
            loss: loss
          });
        };
      }
    }
    if (prevStack.layers.length !== update.mLayers.length) {
      return true;
    };
    return false;
  }
  /** Save the stackup data to the web server. */
  saveStackup(pcbId) {
    var stackupBlock = new CeIODataBlock("Stackup");
    const _designDB = this.designDB.get(pcbId);

    // Compare stackup data
    if (!_designDB.stackup.WriteIntoIODataNode(stackupBlock)) {
      return;
    }

    const blob = new Blob([stackupBlock.WriteBlockToBuffer(0)], {
      type: 'plain/text;charset=utf-8;'
    });

    return uploadStackup(pcbId, blob);
  }; // LayoutData.saveStackup
}

const _LayoutData = new LayoutData();
export default _LayoutData;

export function getStackupByPCBId(ID) {
  return new Promise((resolve, reject) => {
    getDesignFile(ID, 'stackup.dat').then(fileContent => {
      let stackupBlock = new CeIODataBlock("Stackup");
      stackupBlock.ReadBlockFromFile(fileContent.data);
      const layerItemList = stackupBlock.GetAllBlockItems();
      if (!layerItemList) {
        resolve(false);
      }
      let stackup = {
        unit: null,
        metalLayerList: [],
      };
      for (let iLayer of layerItemList) {
        const itemName = iLayer.GetName().toUpperCase();
        if (itemName === "UNIT") {
          stackup.unit = iLayer.GetItemValue().get(0);
        } else if (itemName === 'METAL') {
          let layerParams = iLayer.GetItemValue();
          let layer = {
            type: 'Metal',
            name: layerParams.get(0),
            mThickness: layerParams.size() > 1 ? Number(layerParams.get(1)) : 1.4,
            mMaterialName: "Copper",
            mMaterialVendor: "Generic"
          }
          stackup.metalLayerList.push(layer);
        }
      }
      resolve(stackup);
    }, error => {
      resolve(false);
    });
  });
}

export function getStackupDataByPCBId(ID) {
  return new Promise((resolve, reject) => {
    getDesignFile(ID, 'stackup.dat').then(fileContent => {
      let stackupBlock = new CeIODataBlock("Stackup");
      stackupBlock.ReadBlockFromFile(fileContent.data);
      const layerItemList = stackupBlock.GetAllBlockItems();
      if (!layerItemList) {
        resolve(false);
      }
      let data = layerItemList.filter(item => item.mName === 'Dielectric' || item.mName === 'Metal');
      resolve(data);
    }, error => {
      resolve(false);
    });
  });
}

//get stackup file data
export function getStackupFile(designId) {
  return new Promise((resolve, reject) => {
    getDesignFile(designId, 'stackup.dat').then(fileContent => {
      let stackupBlock = new CeIODataBlock("Stackup");
      if (stackupBlock.ReadBlockFromFile(fileContent.data)) {
        resolve(stackupBlock);
      } else {
        resolve(false);
      }
    }, error => {
      resolve(false);
    });
  });
}

/** Save the stackup data to the web server. */
export function saveStackup(pcbId, stackup) {
  const blob = new Blob([stackup.WriteBlockToBuffer(0)], {
    type: 'plain/text;charset=utf-8;'
  });

  //re_extraction
  if (stackup) {
    ReExtractionByDesign(pcbId);
  }
  return uploadStackup(pcbId, blob);
};//saveStackup