import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { getSweepWaveform, getComponentInfo } from '@/services/Sierra/results';
import NP from 'number-precision';
import { SweepResultData } from '@/services/Sierra';
import { PINTOPINSIM_RAW_FAST, PINTOPINSIM_RAW_HIRE } from '../../constants';
import defaultColor from '@/constants/defaultColors';
import { strDelimited } from '@/services/helper/split';
import { getDataList } from '@/services/Sierra/helper/WaveformResultHelper';
import WaveForm from '../components/WaveForm';
import { getWaveformDisplayMode, getWaveFormJsonConfig, getWaveFormPostProcessEdgesInfo, saveResultDisplaySettings } from '../../../../services/Sierra/results';
import { judgePostProcessSignalType } from '../../../../services/Sierra/helper/WaveformResultHelper';
import { recalcMeasure, updateSweepReCalc } from '../../store/result/action';
import { updateProgress } from '../../store/simulation/action';

NP.enableBoundaryChecking(false);
class WaveformSweep extends Component {
  constructor(props) {
    super(props);
    this.svgRef = createRef();
    this.contentRef = createRef();
    this.state = {
      loading: true,
      compInfo: [],
      currentSignals: null,
      displayMode: 'Hi-Re',
      resultList: [],
      dataList: { Waveform: { list: [], show: [] } },
      portSelect: {},
      allDesigns: [],
      isPostProcess: false,
      allSweepReCalc: [],
      sweepTimeShowList: []
    }
  }

  onRef = (ref) => {
    this.waveformSetting = ref;
  }

  componentDidMount = () => {
    this.screenChange();
    const { currentProjectId, sweepId } = this.props;
    if (currentProjectId && sweepId) {
      this.getData(true)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { sweepId, resultList, sweepReCalc } = this.props;
    if ((sweepId && sweepId !== prevProps.sweepId)) {
      this.getData(true)
    }

    if (resultList && resultList.length !== prevProps.resultList.length) {
      this.getData(false, true)
    }

    if (prevProps.sweepReCalc && prevProps.sweepReCalc.length
      && (!sweepReCalc || !sweepReCalc.length)) {
      this.reGetPostProcessResult()
    }
  }

  getData = (isFirst, keep) => {
    const { resultList } = this.props;
    this.setState({
      resultList: JSON.parse(JSON.stringify(resultList))
    }, () => {
      this.getJson(isFirst, keep);
    })
  }

  getJson = async (isFirst, keep) => {
    const { currentProjectId } = this.props;
    const { resultList } = this.state;
    const newResultList = [], signals = [];
    let allDesigns = [];
    for (let exp of resultList) {
      const res = await SweepResultData.getVerificationJsonPromise(exp.id, currentProjectId);
      if (res) {
        const designs = []
        let currentInterfaces = SweepResultData.getInterfaces(exp.id);
        currentInterfaces.forEach(item => {
          designs.push({
            id: item.pcbId,
            name: item.pcb
          });
        });

        let currentInterfaceInfo = SweepResultData.getInfo(exp.id);
        if (currentInterfaceInfo && currentInterfaceInfo.signals) {
          signals.push(...currentInterfaceInfo.signals.map(item => ({ name: `${item.name}` })));
        }
        newResultList.push({ ...exp, currentInterfaceInfo, designs });
        allDesigns = [...new Set([...allDesigns, ...designs.map(item => item.name)])]
      }
    }
    this.setState({
      resultList: newResultList,
      currentSignals: { signals },
      allDesigns: allDesigns.map((name, index) => ({ name, color: defaultColor[index + 1] }))
    }, () => {
      this.getWaveform(isFirst, keep);
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    if (this.waveform) {
      this.waveform.plot.redrawPlot(this.svgRef.current);
    }
  }

  updateState = (key, value) => {
    this.setState({ [key]: value });
  }

  getWaveform = async (isFirst, keep) => {
    this.setState({
      loading: true
    }, async () => {
      const { displayMode, resultList } = this.state;
      let _displayMode = displayMode;
      let sweepTimeShowList = []
      if (isFirst) {
        const verificationId = resultList && resultList[0] ? resultList[0].verificationId : null;
        _displayMode = verificationId ? await getWaveformDisplayMode(verificationId, _displayMode) : _displayMode;
      }
      const option = _displayMode === 'Hi-Re' ? PINTOPINSIM_RAW_HIRE : PINTOPINSIM_RAW_FAST;
      this.setState({
        displayMode: _displayMode
      })
      const events = {
        changeMouse: this.childComp.changeMouse,
        cancelMove: this.childComp.cancelMove,
        changeAxis: this.childComp.changeAxis,
      }
      let compInfo = [], waveform = null;

      let isPostProcess = false;

      for (let item of resultList) {
        const _waveform = await getSweepWaveform(item.id, option, keep);
        _waveform.plotWaveform({ svgElement: this.svgRef.current, events });
        let { postProcessDataList = {}, postProcessConfig = null, isPostProcess: itemIsPostProcess } = await this.getPostProcessResult(item.verificationId);
        if (itemIsPostProcess) {
          isPostProcess = true;
          sweepTimeShowList.push(item.name)
        }
        const curves = _waveform.getCurves();
        let _compInfo = getComponentInfo(item.currentInterfaceInfo, curves, item.designs);
        const compInfoLength = compInfo.length;
        _compInfo = _compInfo.map((c, index) => ({
          ...c,
          curveName: `${c.curveName}_${item.id}`,
          curveIndex: index + compInfoLength,
          experiment: item.name,
          postProcessData: postProcessDataList && postProcessDataList[c.curveName] ? { ...postProcessDataList[c.curveName], id: c.curveName } : {},
          postProcessConfig
        }));
        compInfo.push(..._compInfo);
        if (waveform) {
          waveform.curveData = [...waveform.curveData, ..._waveform.curveData];
          waveform.signals = [...waveform.signals, ..._waveform.signals.map(signal => ({ ...signal, name: `${signal.name}_${item.id}` }))]
          waveform.plot && waveform.plot.curves.push(..._waveform.plot.curves.map((cu, index) => ({
            ...cu,
            name: `${cu.name}_${item.id}`,
            color: defaultColor[(index + compInfoLength) % defaultColor.length]
          })))
        } else {
          _waveform.signals = _waveform.signals.map(signal => ({ ...signal, name: `${signal.name}_${item.id}` }));
          _waveform.plot.curves = [..._waveform.plot.curves.map((cu, index) => ({
            ...cu,
            name: `${cu.name}_${item.id}`
          }))]
          waveform = _waveform;
        }
      }
      if (compInfo.length && waveform) {
        waveform.plotWaveform({ svgElement: this.svgRef.current, events });
        this.waveform = waveform;
        compInfo = compInfo.map((ci, index) => ({ ...ci, color: defaultColor[index % defaultColor.length] }));
        let { _compInfo, dataList, portSelect, allPortSelect } = getDataList(compInfo);
        portSelect = keep ? this.state.portSelect : portSelect;
        this.setState({
          loading: false,
          compInfo: _compInfo,
          dataList,
          portSelect,
          allPortSelect,
          isPostProcess,
          sweepTimeShowList
        }, () => {
          this.getCurves()
        });
      }
    })
  }

  getPostProcessResult = async (verificationId) => {
    let postProcessDataList = {}, postProcessConfig = null, isPostProcess = false;
    try {
      postProcessDataList = await getWaveFormPostProcessEdgesInfo(verificationId)
      isPostProcess = true
    } catch (error) {
      console.error("Get edges file error: ", error);
    }
    try {
      postProcessConfig = await getWaveFormJsonConfig(verificationId)
    } catch (error) {
      console.error("Get pproc-config file error: ", error);
    }
    if (postProcessConfig) {
      postProcessDataList = judgePostProcessSignalType(postProcessDataList, postProcessConfig)
    }
    return { postProcessDataList, postProcessConfig, isPostProcess }
  }

  reGetPostProcessResult = async () => {
    const { allSweepReCalc } = this.state;
    if (!allSweepReCalc) {
      return;
    }

    const { compInfo, isPostProcess } = this.state;
    let _compInfo = [...compInfo], pprocConfigInfo = {}, _isPostProcess = isPostProcess;
    for (let item of allSweepReCalc) {
      let { postProcessDataList = {}, postProcessConfig = null, isPostProcess: itemIsPostProcess } = await this.getPostProcessResult(item.verificationId);
      pprocConfigInfo[item.id] = {
        postProcessDataList,
        postProcessConfig
      }
      if (itemIsPostProcess) {
        _isPostProcess = itemIsPostProcess
      }
    }

    for (let info of _compInfo) {
      const curveNames = info.curveName.split("_");
      const id = curveNames[curveNames.length - 1];
      if (!pprocConfigInfo[id]) {
        continue;
      }
      const curveName = curveNames.filter((it, index) => index < curveNames.length - 1).join("_");
      info.postProcessConfig = pprocConfigInfo[id].postProcessConfig || {};
      const currData = pprocConfigInfo[id].postProcessDataList && pprocConfigInfo[id].postProcessDataList[curveName] ? { ...pprocConfigInfo[id].postProcessDataList[curveName], id: curveName } : {};
      const newPostProcessData = {
        ...currData,
        curveName: info.postProcessData.curveName,
        title: info.postProcessData.title,
        signal: info.postProcessData.signal,
        displayTitle: info.postProcessData.displayTitle
      }
      info.postProcessData = newPostProcessData;
    }
    this.setState({
      compInfo: _compInfo,
      allSweepReCalc: null,
      isPostProcess: _isPostProcess
    })
  }

  displayModeChange = (e) => {
    this.setState({ displayMode: e.target.value }, () => {
      this.getWaveform();
      try {
        for (let item of this.state.resultList || []) {
          if (!item.verificationId) {
            continue;
          }
          saveResultDisplaySettings({
            verificationId: item.verificationId,
            setting: { waveform: { displayMode: e.target.value } }
          });
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  colorChange = (e, key, indexs) => {
    const value = e.toHexString()
    if (value) {
      const { compInfo } = this.state;
      const [experiment] = strDelimited(key, '@');
      const curveIndex = indexs[experiment];
      const curves = this.waveform.getCurves();
      if (curveIndex > -1) {
        compInfo[curveIndex].color = value;
        curves[curveIndex].color = value;
        const { _compInfo, dataList } = getDataList(compInfo);
        this.waveform.updataRawCurvePlot();
        this.setState({
          compInfo: _compInfo,
          dataList,
        })
      }
    }
  }

  changeAllPorts = (allChecked, sweepList, rowName) => {
    const { portSelect } = this.state;
    if (allChecked) {
      const idList = sweepList.map(item => item._id);
      portSelect[rowName] = portSelect[rowName].filter(item => !idList.includes(item));
    } else {
      const idList = sweepList.filter(item => !item.checked).map(item => item._id);
      if (portSelect[rowName]) {
        portSelect[rowName].push(...idList)
      } else {
        portSelect[rowName] = [...idList]
      }
    }

    this.setState({
      portSelect: portSelect
    }, () => {
      this.getCurves()
    })
  }

  changeSelectSignalAll = (columnData) => {
    const { portSelect } = this.state;
    const { signal, allChecked } = columnData

    portSelect[signal] = this.getPortSelect(columnData, portSelect[signal], allChecked)
    this.setState({
      portSelect: portSelect
    }, () => {
      this.getCurves()
    })
  }

  getPortSelect = (data, signalPortSelect = [], allChecked) => {
    let _signalPortSelect = [...signalPortSelect]
    const { selectPortData, allPortData } = data
    if (allChecked) {
      _signalPortSelect = _signalPortSelect.filter(item => !selectPortData.includes(item));
    } else {
      const idList = allPortData.filter(item => !selectPortData.includes(item))
      if (_signalPortSelect) {
        _signalPortSelect.push(...idList)
      } else {
        _signalPortSelect = [...idList]
      }
    }
    return _signalPortSelect
  }

  changeSelectAll = (e, group, fileId, verticalListData) => {
    const check = e.target.checked;
    const { rowName, groupName } = group;
    let { portSelect } = this.state;
    let portList = [];

    if (check) {
      if (verticalListData && verticalListData.length) {
        for (let verticalInfo of verticalListData) {
          const { name, dataList } = verticalInfo;
          if (!groupName.includes(name)) { continue }
          const currentDataInfo = dataList.find(item => item.signal === rowName);
          if (currentDataInfo) {
            portList = [...portList, ...currentDataInfo.allPortData]
          }
        }
      } else {
        portList = group.children.map(it => it.id)
      }
    }
    portSelect[rowName] = portList;
    this.setState({
      portSelect: portSelect
    }, () => {
      this.getCurves()
    })
  }

  changeSelectRowAll = (columnData) => {
    const { dataList, allChecked } = columnData;
    let { portSelect } = this.state;
    for (let data of dataList) {
      const { signal } = data;
      portSelect[signal] = this.getPortSelect(data, portSelect[signal], allChecked)
    }
    this.setState({
      portSelect: portSelect
    }, () => {
      this.getCurves()
    })
  }

  changePort = (value, key, fileId) => {
    const { portSelect } = this.state;
    portSelect[key] = value;
    this.setState({ portSelect }, () => this.getCurves())
  }

  clearPort = (currentId, rowName) => {
    this.setState({ portSelect: {} }, () => {
      this.changePort([currentId], rowName)
    })
  }

  getCurves = () => {
    const { portSelect, compInfo } = this.state;
    const keyMap = Object.keys(portSelect);
    let selectedComponentKeys = [];
    for (let key of keyMap) {
      const select = portSelect[key];
      select.forEach(s => {
        const info = compInfo.find(comp => comp.portSelectId === s);
        if (info) {
          selectedComponentKeys.push(info.curveIndex);
        }
      })
    }
    const curves = this.waveform.getCurves();
    curves.forEach((curve, i) => {
      curve.visible = selectedComponentKeys.includes(i);
    });
    this.waveform.updataRawCurvePlot();
  }

  _rowNameClick = (displayMode, value) => {
    let _dataList = { ...this.state.dataList };
    if (_dataList[displayMode]) {
      let _show = [..._dataList[displayMode].show];
      if (_show.includes(value)) {
        _show = _show.filter(item => item !== value);
      } else {
        _show.push(value);
      }
      _dataList[displayMode].show = _show;
      this.setState({
        dataList: _dataList
      });
    }
  };

  _updateSweepReCalc = (sweepReCalc) => {
    this.setState({
      allSweepReCalc: JSON.parse(JSON.stringify(sweepReCalc || []))
    })
    this.props._updateSweepReCalc([...(sweepReCalc || []).map(item => item.id)]);
  }

  showSweepTime = (name, expand) => {
    const { sweepTimeShowList } = this.state;
    let _sweepTimeShowList = [...sweepTimeShowList];
    if (expand) {
      _sweepTimeShowList.push(name)
    } else {
      _sweepTimeShowList = _sweepTimeShowList.filter(item => item !== name);
    }
    this.setState({
      sweepTimeShowList: _sweepTimeShowList
    })
  }


  render() {
    const { loading, compInfo, displayMode, currentSignals, resultList, dataList, portSelect, allDesigns, allPortSelect, isPostProcess, sweepTimeShowList } = this.state;
    return <WaveForm
      {...this.props}
      {...this.resultListAction}
      compRef={(ref) => { this.childComp = ref }}
      onRef={this.onRef}
      loading={loading}
      compInfo={compInfo}
      displayMode={displayMode}
      currentInterfaceInfo={currentSignals}
      waveform={this.waveform}
      svgRef={this.svgRef}
      sweepTimeShowList={sweepTimeShowList}
      updateState={this.updateState}
      waveformSetting={this.waveformSetting}
      displayModeChange={this.displayModeChange}
      verificationSubId={this.props.sweepId}
      resultList={resultList}
      dataList={dataList}
      portSelect={portSelect}
      allDesigns={allDesigns}
      sweep={true}
      allPortSelect={allPortSelect}
      isPostProcess={isPostProcess}
      clearPort={this.clearPort}
      recalcMeasure={this.props.recalcMeasure}
      _updateSweepReCalc={this._updateSweepReCalc}
      _updateProgress={this.props._updateProgress}
      showSweepTime={this.showSweepTime}
    />
  }

  resultListAction = {
    colorChange: this.colorChange,
    changePort: this.changePort,
    _rowNameClick: this._rowNameClick,
    changeAllPorts: this.changeAllPorts,
    changeSelectSignalAll: this.changeSelectSignalAll,
    changeSelectRowAll: this.changeSelectRowAll,
    _changeSelectAll: this.changeSelectAll
  }
}

const mapState = (state) => {
  const { project, sweep: { experimentInfo }, simulationReducer: { singleProgress }, resultReducer: { sweepReCalc } } = state.SierraReducer;
  const { currentProjectId } = project;
  const { id = "" } = experimentInfo;
  return {
    currentProjectId,
    sweepId: id,
    singleProgress,
    sweepReCalc
  }
}

const mapDispatch = (dispatch) => ({
  recalcMeasure(configList, isSweep) {
    dispatch(recalcMeasure(configList, isSweep))
  },
  _updateSweepReCalc(sweepReCalc) {
    dispatch(updateSweepReCalc(sweepReCalc))
  },
  _updateProgress({ verificationId, progress }) {
    dispatch(updateProgress({ verificationId, progress }))
  }
})

export default connect(mapState, mapDispatch)(WaveformSweep);