import { SUCCESS } from "../../constants/returnCode"
import http from "../api/http";

function getBlob(url) {
  return new Promise((resolve) => {
    let that = this
    const xhr = new XMLHttpRequest()

    xhr.open('GET', url, true)

    xhr.addEventListener(
      'progress',
      function* (evt) {
        if (evt.lengthComputable) {
          let percentComplete = evt.loaded / evt.total
          that.percentage = percentComplete * 100
        }
      },
      false,
    )

    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }

    xhr.send()
  })
}

function getResBlob(url, setting) {
  const { type = "GET", params, token, pageType } = setting || {};
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open(type, url, true);

    xhr.responseType = "blob";
    if (type === "POST") {
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    }
    // export sign-off-template
    if (token && pageType) {
      type !== "POST" && xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Page-Type", `${pageType}`);
    }
    xhr.onload = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          resolve(null)
        }
      }
    };
    if (params) {
      xhr.send(JSON.stringify(params));
    } else {
      xhr.send();
    }
  });
}

function getDebugDownloadProgress(progress) {
  if (progress < 60) {
    progress = parseInt((progress + Math.random()) * 10) / 8
    progress = parseFloat(progress.toFixed(1))
  } else {
    progress = parseInt((progress + Math.random()) * 10) / 10
    progress = parseFloat(progress.toFixed(1))
  }
  if (progress >= 99) {
    progress = 99
  }
  return progress
}

function downloadFile(data, name, typeFile) {
  let type = 'plain/text;charset=utf-8;'
  if (typeFile) {
    type = typeFile
  }

  let url = null;
  if (typeFile === 'canvas') {
    url = data.toDataURL('image/png')
  } else {
    const blob = new Blob([data], {
      type: type,
    })
    url = URL.createObjectURL(blob)
  }
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * get download file key 
 * prepared download folder
 * return downloadKey, get download file by downloadKey */
function getDownloadFileId(url) {
  return new Promise((resolve, reject) => {
    return http.get(url).then(res => {
      if (!res || !res.data) {
        reject({ status: "failed" })
        return;
      }
      if (res.data.code === SUCCESS && res.data.data) {
        resolve({ status: "success", key: res.data.data })
      } else {
        reject({ status: "failed", error: res.data.msg })
      }
    }, error => {
      reject({ status: "failed", error })
    })
  })
}

/**
 * get download file key ,http post
 * prepared download folder
 * return downloadKey, get download file by downloadKey */
function getDownloadFileIdForPost(url, params = {}) {
  return new Promise((resolve, reject) => {
    return http.post(url, Array.isArray(params) ? [...params] : {
      ...params
    }).then(res => {
      if (!res || !res.data) {
        reject({ status: "failed" })
        return;
      }
      if (res.data.code === SUCCESS && res.data.data) {
        resolve({ status: "success", key: res.data.data })
      } else {
        reject({ status: "failed", error: res.data.msg })
      }
    }, error => {
      reject({ status: "failed", error })
    })
  })
}

/**
 * get download file size 
 * prepared download folder
 * return downloadKey, get download file size by downloadKey */
function getDownloadFileSize(downloadKey) {
  return new Promise((resolve, reject) => {
    return http.get(`download/file/size?downloadKey=${downloadKey}`).then(res => {
      if (!res || !res.data) {
        reject({ status: "failed" })
        return;
      }
      if (res.data.code === SUCCESS && res.data.data) {
        resolve({ status: "success", size: res.data.data })
      } else {
        reject({ status: "failed", error: res.data.msg })
      }
    }, error => {
      reject({ status: "failed", error })
    })
  })
}

function getDownloadFileChunk({ start, end, downloadKey }) {
  return http.get(`download/file/chunk?downloadKey=${downloadKey}`, {
    headers: { Range: `bytes=${start}-${end}` },
    responseType: "blob",
  })
}

function deleteDownloadTemporaryFile(downloadKey) {
  return http.get(`/download/file/delete?downloadKey=${downloadKey}`)
}

async function asyncPool(poolLimit, array, iteratorFn) {
  const ret = []; // all workers
  const executing = []; // running workers
  for (const item of array) {
    // iteratorFn
    const p = Promise.resolve().then(() => iteratorFn(item, array));
    ret.push(p);

    if (poolLimit <= array.length) {
      // remove completed worker
      const e = p.then(() => executing.splice(executing.indexOf(e), 1));
      executing.push(e); //save
      if (executing.length >= poolLimit) {
        await Promise.race(executing); // waiting
      }
    }
  }
  return Promise.all(ret);
}

async function downloadFileByChunk({
  fileSize,
  downloadKey,
  fileName,
  downloadMsgInfo,
  menuType,
  downloadProgressInfo,
  updateDownloadProgress,
  clearProgressInterval }) {
  try {
    updateDownloadProgress({
      downloadMsgInfo,
      menuType,
      message: "Loading file...",
    })
    const SIZE = 200 * 1024 * 1024;
    let chunks = Math.ceil(fileSize / SIZE);
    let chunkKeys = [...new Array(chunks).keys()];
    const getBinaryContent = async (start, end, i) => {
      let result = await getDownloadFileChunk({ start, end, downloadKey });
      return { index: i, data: result };
    };

    let results = await asyncPool(3, chunkKeys, async (i) => {
      let start = i * SIZE;
      let end = i + 1 === chunks ? fileSize : (i + 1) * SIZE - 1;
      return getBinaryContent(start, end, i);
    })
    updateDownloadProgress({
      downloadMsgInfo,
      menuType,
      message: "Prepared file successfully and waiting for download!"
    })
    results.sort((a, b) => a.index - b.index)
    let arr = results.map((item) => item.data);
    arr = (await Promise.all(arr)).map(item => item.data)
    updateDownloadProgress({
      downloadMsgInfo,
      menuType,
      message: "Downloading file..."
    })
    let buffers = new Blob(arr)
    const saveAs = (name, buffers, mime = "application/octet-stream") => {
      const blob = new Blob([buffers], { type: mime });
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.download = name
      a.href = blobUrl;
      a.click();
      URL.revokeObjectURL(blob);
    };

    saveAs(fileName, buffers)
    clearProgressInterval && clearProgressInterval(menuType)
    setTimeout(() => {
      updateDownloadProgress({
        downloadMsgInfo,
        menuType,
        message: "Download successful!",
        downloadProgressInfo,
        progress: 100,
        status: "success"
      })
      //delete temporary files
      deleteDownloadTemporaryFile(downloadKey)
    }, 1000)
    setTimeout(() => {
      updateDownloadProgress({
        downloadProgressInfo,
        menuType,
        progress: -1,
        status: "success"
      })
    }, 3000)
  } catch (error) {
    console.error(error)
    setTimeout(() => {
      updateDownloadProgress({
        downloadProgressInfo,
        menuType,
        progress: -1,
        downloadMsgInfo,
        message: "Download failed!",
        status: "failed"
      })
    }, 1000)
  }
}

export {
  getBlob,
  getResBlob,
  getDebugDownloadProgress,
  downloadFile,
  getDownloadFileId,
  getDownloadFileIdForPost,
  getDownloadFileSize,
  getDownloadFileChunk,
  asyncPool,
  downloadFileByChunk
}
