import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { SwapOutlined } from '@ant-design/icons';
import { Select, Radio, Button } from "antd";
import Panel from '../Panel';
import { TOUCHSTONE } from '@/constants/libraryConstants';
import './index.css';

const { Option, OptGroup } = Select;
const SPICE = "SPICE", hasFolder = [SPICE];

const list = [
  [
    { "portLIndex": 1, "portRIndex": 2 },
    { "portLIndex": 3, "portRIndex": 4 },
    { "portLIndex": 5, "portRIndex": 6 },
    { "portLIndex": 7, "portRIndex": 8 }
  ],
  [
    { "portLIndex": 1, "portRIndex": 5 },
    { "portLIndex": 2, "portRIndex": 6 },
    { "portLIndex": 3, "portRIndex": 7 },
    { "portLIndex": 4, "portRIndex": 8 }
  ],
  [
    { "portLIndex": 1, "portRIndex": 8 },
    { "portLIndex": 2, "portRIndex": 7 },
    { "portLIndex": 3, "portRIndex": 6 },
    { "portLIndex": 4, "portRIndex": 5 }
  ]
]

class AutomaticContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInfo: {},
      type: 1,
      swapMatchType: []
      // list: this.getList()
    };
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const { automaticInfo } = this.props;
    if (automaticInfo && Object.keys(automaticInfo).length) {
      this.getFileInfo()
    }
  }

  findNormalFiles = (fileList, selectFileList) => {
    for (let fileInfo of selectFileList) {
      if (!fileInfo || !fileInfo.libraryId) { continue }
      const findIndex = fileList.findIndex(item => item.id === fileInfo.libraryId);
      if (findIndex > -1) {
        return fileInfo
      }
    }
    return {}
  }

  componentDidUpdate = (prevProps) => {
    const { automaticInfo } = this.props;
    if (prevProps.automaticInfo && prevProps.automaticInfo.modelFileKey !== automaticInfo.modelFileKey) {
      // Change the selected data when switching to a different model match port
      this.getFileInfo()
    }
  }

  getFileInfo = () => {
    const { automaticInfo, newModelFilesObj, modelType: _modelType, files, libraryListObj } = this.props;
    const { modelType, modelFileKey } = automaticInfo;
    let selectFileInfo = {};
    const modelFiles = libraryListObj[`${automaticInfo.modelType.toLowerCase()}List`] || [];
    if (_modelType === modelType && _modelType === modelFileKey) {
      //Data in the first column
      let _files = [...files];
      if (!_files || !_files.length) {
        _files = newModelFilesObj && newModelFilesObj[modelType] && newModelFilesObj[modelType].allFiles ? newModelFilesObj[modelType].allFiles : [];
      }
      selectFileInfo = this.findNormalFiles(modelFiles, _files)
    } else {
      //Multi data added later
      let currentFiles = newModelFilesObj && newModelFilesObj[modelType] && newModelFilesObj[modelType][modelFileKey] ? newModelFilesObj[modelType][modelFileKey] : [];

      if ((!currentFiles || !currentFiles.length) && modelType === _modelType) {
        currentFiles = [...files]
      };
      selectFileInfo = this.findNormalFiles(modelFiles, currentFiles)
    }

    this.setState({
      fileInfo: selectFileInfo
    })
  }

  closeModal = (save) => {
    const { fileInfo, type } = this.state;
    let _save = save;
    if (!fileInfo.libraryId || (hasFolder.includes(fileInfo.type) && !fileInfo.subckt)) {
      _save = false
    }
    this.props.closePanel(fileInfo, type, _save)
  }

  selectHandler = (option = {}) => {
    let selectFileInfo = {};
    const { value } = option;
    if (value) {
      const [id, folderId] = value.split("::");
      const { automaticInfo, libraryListObj } = this.props;
      const modelFiles = libraryListObj[`${automaticInfo.modelType.toLowerCase()}List`] || [];
      let fileInfo = modelFiles.find(item => item.id === id);
      if (folderId) {
        const findFolder = modelFiles.find(item => item.id === folderId) || {};
        fileInfo = (findFolder.children || []).find(item => item.id === id);
      }
      if (!fileInfo) {
        return;
      }

      selectFileInfo = {
        fileName: fileInfo.fileName ? fileInfo.fileName : fileInfo.name,
        libraryId: fileInfo.libraryId ? fileInfo.libraryId : fileInfo.id,
        type: (fileInfo.type || "").match("touchstone") || (fileInfo.fileType || "").match("touchstone") ? TOUCHSTONE : SPICE,
        subckt: "",
        version: fileInfo.version
      }
      this.props.getFileParse([{ ...selectFileInfo }]);
    }

    this.setState({
      fileInfo: selectFileInfo
    })
  }


  selectSubcktHandler = (value) => {
    const { fileInfo } = this.state;
    const selectFileInfo = {
      ...fileInfo,
      subckt: value
    }

    this.props.getFileParse([{ ...selectFileInfo }]);

    this.setState({
      fileInfo: selectFileInfo
    })
  }

  getSelect = ({ value, optionList, onChange, width, labelInValue }) => {
    return <Select
      labelInValue={labelInValue}
      value={value}
      onChange={onChange}
      className="automatic-model-selection"
      popupClassName='aurora-select-dropdown'
      popupMatchSelectWidth={false}
      allowClear={true}
      getPopupContainer={() => document.getElementById('root')}
      style={{ width }}
    >
      {optionList.map((item, index) => {
        if (item.type === "folder" && item.children && item.children.length) {
          return <OptGroup key={item.name} label={item.name}>
            {item.children.map(it => (<Option
              key={`${it.id || it.name}::${item.id || item.name}`}
              value={`${it.id || it.name}::${item.id || item.name}`}
              title={it.name}
            >{it.name}</Option>))}</OptGroup>
        }
        return <Option
          key={item.id ? item.id : item.name}
          value={item.id ? item.id : item.name}
          title={item.name}
        >{item.name}</Option>
      })}
    </Select>
  }

  selectComponents = () => {
    const { fileInfo } = this.state;
    const { libraryListObj, automaticInfo, subcktList } = this.props;
    const { modelType } = automaticInfo;
    const modelFiles = libraryListObj[`${modelType.toLowerCase()}List`] || [];
    let subckts = [], isSelectSubckt = false;
    if (hasFolder.includes(fileInfo.type)) {
      const currentSubckt = subcktList ? subcktList.find(item => (item.libraryId === fileInfo.libraryId && item.fileName === fileInfo.fileName)) : null;
      subckts = currentSubckt ? currentSubckt.models : [];
      isSelectSubckt = true
    }
    if (!fileInfo.fileName) {
      fileInfo.fileName = (modelFiles.find(item => item.id === fileInfo.libraryId) || {}).name || "";
    }
    return <div className='automatic-model-file-select'>
      <div className='automatic-model-file-add-box'>
        <span>Model File</span>
      </div>
      <div className="automatic-model-selection-div">
        {this.getSelect({
          value: { value: fileInfo.libraryId || "", label: fileInfo.fileName || "" },
          optionList: modelFiles,
          onChange: this.selectHandler,
          width: isSelectSubckt ? "calc(50% - 6px)" : null,
          labelInValue: true
        })}
        {hasFolder.includes(fileInfo.type) && this.getSelect({
          value: fileInfo.subckt,
          optionList: subckts,
          onChange: this.selectSubcktHandler,
          width: isSelectSubckt ? "calc(50% - 6px)" : null,
          labelInValue: false
        })}
      </div>
    </div>
  }

  onChange = (e) => {
    this.setState({
      type: e.target.value
    })
  }

  radioContent = () => {
    const { type } = this.state;
    return <div className='automatic-model-file-select'>
      <div className='automatic-model-file-add-box automatic-model-file-type-box'>
        <span>Match Type</span>
      </div>
      <Radio.Group onChange={this.onChange} value={type} className="automatic-match-ports-radio-group">
        {this.optionPattern()}
      </Radio.Group>
    </div>
  }

  // getPortIndex(type, index, length) {
  //   let portRIndex, portLIndex;
  //   switch (type) {
  //     case 1:
  //       portLIndex = index * 2 + 1;
  //       portRIndex = index * 2 + 2;
  //       break;
  //     case 2:
  //       portLIndex = index + 1;
  //       portRIndex = length + index + 1;
  //       break;
  //     case 3:
  //       portLIndex = index + 1;
  //       portRIndex = length * 2 - index;
  //       break;
  //     default:
  //       break;
  //   }
  //   return { portRIndex, portLIndex }
  // }

  // getList = () => {
  //   let list = [];
  //   const length = 4;
  //   [1, 2, 3].forEach(type => {
  //     let typeList = [];
  //     for (let i = 0; i < length; i++) {
  //       const { portRIndex, portLIndex } = this.getPortIndex(type, i, length);
  //       typeList.push({ portLIndex, portRIndex })
  //     }
  //     list.push(typeList)
  //   })
  //   return list
  // }

  optionPattern = () => {
    // const { list } = this.state;
    // const list = this.getList();
    const { swapMatchType } = this.state;
    const { allowManual } = this.props;
    return (
      <Fragment>
        <div className="automatic-match-ports-radio">
          {list.map((item, listIndex) => {
            const portRText = item.map(item => item.portRIndex).join("");
            const portLText = item.map(item => item.portLIndex).join("");
            return (
              <Radio value={listIndex + 1} key={`auto-match-radio-${listIndex}`}>
                <div className="automatic-match-ports-radio-content">
                  <div className="automatic-match-ports-vLine-left">{swapMatchType.includes(listIndex + 1) ? portRText : portLText}</div>
                  <div className="automatic-match-ports-vLine"></div>
                  <div className="automatic-match-ports-vLine-right">{swapMatchType.includes(listIndex + 1) ? portLText : portRText}</div>
                  {item.isSwap ? <SwapOutlined onClick={(e) => this.swapMatchType(e, listIndex + 1)} /> : null}
                </div>
              </Radio>
            );
          })}
        </div>
        {allowManual ? <div className="automatic-match-ports-radio-bottom">
          <Radio value={"manual"} key={"manual"}>Manual</Radio>
          <div></div>
          <div></div>
        </div> : null}
      </Fragment>
    );
  }

  getContentFooter = () => {
    return <Fragment>
      <div className="automatic-footer-content">
        <Button key="ok" type="primary" onClick={() => this.closeModal(true)}>OK</Button>
        <Button key="cancel" style={{ marginLeft: 30 }} onClick={() => this.closeModal()} >Cancel</Button>
      </div>
    </Fragment>
  }

  render = () => {
    const { automaticInfo } = this.props;
    const { modelType } = automaticInfo;
    const content = (
      <Panel
        className='automatic-match-ports-panel'
        position='panel-center-left'
        title={`${modelType} Ports Match`}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={600}
        draggable
      // height={210}
      >
        <div className='automatic-match-ports-content'>
          {this.selectComponents()}
          {this.radioContent()}
          {this.getContentFooter()}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default AutomaticContent;