import React, { Component, Fragment } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Select } from 'antd';
import { CURRENT_PWL_FILE } from '../../../../services/Cascade/constants';
import { getLibraryFileInfo } from '../../../../services/Cascade/library';
import { parseSPModelSelector } from '../../../../services/Library';
import '../index.css';

const { Option } = Select
class CurrentUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      libraryId: '',
      subckt: '',
      subckts: [],
      error: null
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initState();
  }

  initState = async () => {
    const { dieCurrent = {} } = this.props;
    const libraryId = dieCurrent && dieCurrent.librariId ? dieCurrent.librariId : ''
    const subckt = dieCurrent && dieCurrent.subckt ? dieCurrent.subckt : ''
    let subckts = []
    if (libraryId) {
      const res = await getLibraryFileInfo(libraryId);
      const { models } = parseSPModelSelector(res);
      subckts = models
    }
    this.setState({
      error: null,
      save: false,
      libraryId,
      subckt,
      subckts
    }, () => {
      this.props.savePoints([]);
    })
  }

  saveCurrent = (prevType, closePanel) => {
    const { libraryId = '', subckt, save } = this.state;
    const { customSpiceList } = this.props
    if (libraryId && !subckt) {
      this.setState({ error: 'Subckt cannot be empty.' })
      return false;
    }
    const libraryFile = customSpiceList.find(item => item.id === libraryId)
    const libraryName = libraryFile ? libraryFile.name : ''
    const dieCurrent = {
      libraryId,
      libraryName,
      subckt,
      currentType: CURRENT_PWL_FILE,
      currentPoints: []
    }
    if (prevType !== CURRENT_PWL_FILE || save) {
      this.props.updateCurrent(dieCurrent, closePanel)
    } else if (closePanel) {
      this.props.filterEmptyCurrent()
    };
    return true;
  }

  fileSelect = async (value) => {
    const res = await getLibraryFileInfo(value);
    const { models: subckts } = parseSPModelSelector(res);
    this.setState({
      libraryId: value,
      subckts,
      subckt: '',
      error: null
    })
  }

  subcktSelect = (value) => {
    this.setState({
      subckt: value,
      save: true,
      error: null
    })
  }

  deleteFileSelect = () => {
    this.setState({
      libraryId: '',
      subckt: '',
      subckts: [],
      save: true
    })
  }

  deleteSubcktSelect = () => {
    this.setState({
      subckt: '',
      save: true
    })
  }

  render() {
    const { subckts, libraryId, subckt, error } = this.state;
    const { customSpiceList } = this.props
    return (
      <Fragment>
        <div className='imp-current-pwl'>
          <div className='imp-current-file-content'>
            <span className='imp-current-file-title'>File</span>
            <Select
              value={libraryId}
              onSelect={this.fileSelect}
              className='imp-current-select aurora-select'
              popupClassName='netlist-model-dropdown'
              popupMatchSelectWidth={false}
              allowClear={{ clearIcon: <CloseCircleFilled onClick={this.deleteFileSelect} /> }}
              getPopupContainer={() => document.getElementById('root')}
            >
              {customSpiceList.map(item => <Option
                key={item.id}
                value={item.id}
              >{item.name}</Option>)}
            </Select>
          </div>
          <div className='imp-current-file-content'>
            <span className='imp-current-file-title'>Subckt</span>
            <Select
              value={subckt}
              onSelect={this.subcktSelect}
              className='imp-current-select aurora-select'
              popupClassName='netlist-model-dropdown'
              popupMatchSelectWidth={false}
              allowClear={{ clearIcon: <CloseCircleFilled onClick={this.deleteSubcktSelect} /> }}
              getPopupContainer={() => document.getElementById('root')}
              disabled={!libraryId}
            >
              {subckts.map(item => <Option
                key={item.name}
                value={item.name}
              >{item.name}</Option>)}
            </Select>
          </div>
        </div>
        {error && <span className='imp-current-name-error-msg'>{error}</span>}
      </Fragment>
    );
  }
}

export default CurrentUpload;