import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SettingOutlined, PlayCircleOutlined, DownloadOutlined, RightOutlined } from '@ant-design/icons'
import { Dropdown, Popover, Radio } from 'antd';
import { topBarResize } from '@/services/helper/topBarResize';
import { ANDES_V2_TOP_BAR_OPTIONS, SIMULATE, DEBUG, SEASIM, MODELING, TOP_BAR_RESULT, AMI_SETUP, REPORT, SERVICES_OPTIONS, RUN, DOWNLOAD, HSPICE_SIMULATE, HSPICE_SIMULATION, PADSTACKS, LAYOUT, STACKUP } from '../../../services/TopBarHelper';
import ExtractionOptionPanel from '../Channel/ExtractionOptionPanel';
import { PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS } from '../../../constants/treeConstants';
import { startChannelExtraction } from '../store/simulation/extraction/action';
import { startEndToEndSimulation } from '../store/simulation/endToEndChannel/action';
import DebugPanel from './Debug';
import ChannelSeasimConfig from '../Channel/SeaSim';
import EndToEndSeasimConfig from '../EndToEndChannel/SeaSim';
import {
  getTopBarWidth,
  getTopMenuBackground,
  getTopMenuDisplay,
  getTopMenuDisabled,
  isEndToEndChildrenChannel,
  getDebugType,
  resultDisabled
} from '@/services/Andes_v2';
import { getReportConfig, openPage, updateReportInfo, updateStackupStatusList } from '../store/project/action';
import Report from './Report';
import ChannelAMIModel from '../Channel/AMIModel';
import EndToEndAMIModel from '../EndToEndChannel/AMIModel';
import { changeSimulationSolver, updateHybridStatus } from '../store/channel/action';
import canvas from '@/services/LayoutCanvas';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import ServiceOptionsPanel from './serviceOptionsPanel';
import endToEndChannelConstructor from '@/services/Andes_v2/endToEndChannel/endToEndChannelConstructor';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import { startSweepExtraction } from '../store/simulation/sweep/action';
import ExperimentDownloadPanel from '../Sweep/DownLoad';
import { VERIFY_SUCCESS } from '../../../constants/verificationStatus';
import getIcon from '../../../services/helper/iconHelper';
import { CPHY } from '../../../services/PCBHelper/constants';
import { ADS, HSPICE } from '../../../services/Andes_v2/simulation';
import SettingPanel from '../SimulationContent/SettingPanel';
import PadstackPanel from '../../../components/PadstackPanel';
import StackupPanel from '../../../components/StackupPanel';
import { ANDES_V2 } from '../../../constants/pageType';
import Stackup from '../../LayoutExplorer/components/Stackup_v1';
import { getNewStackupList, STACKUP_CLOSE, STACKUP_OPEN } from '../../../services/Andes_v2/helper/stackupUIHelper';
import designConstructor from '../../../services/helper/designConstructor';
import './index.css';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: {
        [MODELING]: false,
        [REPORT]: false,
      },
      simulationloading: true
    }
  }

  screenChange() {
    window.addEventListener('resize', this._changeTopBarSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._changeTopBarSize);
  }

  componentDidMount() {
    const { forcePreviewShow } = this.props;
    this._changeTopBarSize();
    this.screenChange();
    if (forcePreviewShow) {
      let visible = this.state.visible;
      visible[MODELING] = true;
      this.setState({
        visible
      })
    }
  }

  _changeTopBarSize = () => {
    const { channelId, viewList, endToEndChannelId } = this.props;
    topBarResize('andes-v2-top-bar', getTopBarWidth({ channelId, viewList, endToEndChannelId }));
  }

  componentDidUpdate = (prevProps) => {
    const { channelId, endToEndChannelId, viewList } = this.props;
    const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL].includes(item));
    const prevView = prevProps.viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL].includes(item));

    if (channelId !== prevProps.channelId || endToEndChannelId !== prevProps.endToEndChannelId || view !== prevView) {
      this.setState({
        visible: {
          ...this.state.visible,
          [MODELING]: false,
          [SEASIM]: false,
          [AMI_SETUP]: false,
          [STACKUP]: false,
          [PADSTACKS]: false,
          [HSPICE_SIMULATION]: false,
          [REPORT]: false,
          [SERVICES_OPTIONS]: false,
        }
      });
      this._changeTopBarSize();
      this.cleanAllCanvasBoundaries();
      this.props._updateHybridStatus(false);
      this.updateStackupList()
    }
  }

  cleanAllCanvasBoundaries = () => {
    const { selectedDesignIDs } = this.props;
    selectedDesignIDs.forEach(id => {
      const layout = canvas.getLayout(id);
      if (layout) {
        layout.cleanHybridBoundaries();
        layout.removeHybridAllPointsByName();
      }
    });
  }

  updateStackupList = () => {
    const { selectedKeys, channelDesignId } = this.props
    const allIds = { selectedKeys, channelDesignId, currentID: channelDesignId }

    // update stackupList when page change
    let { stackupList } = this.props
    stackupList = getNewStackupList(stackupList, STACKUP_CLOSE, allIds, false)
    this.props._updateStackupStatusList(stackupList)
  }

  openPanel = (key) => {
    const { projectId, channelId, endToEndChannelId, channelLoading } = this.props;
    let { visible } = this.state;
    switch (key) {
      case DEBUG:
      case REPORT:
        if (!projectId) {
          return;
        }
        break;
      case SEASIM:
      case AMI_SETUP:
        if ((channelLoading || !channelId) && !endToEndChannelId) {
          return;
        }
        break;
      case MODELING:
        if (channelLoading || !channelId) {
          return;
        }
        break;
      case SERVICES_OPTIONS:
        if (visible[SERVICES_OPTIONS]) {
          return;
        }
        break;
      case DOWNLOAD:
        if (visible[DOWNLOAD]) {
          return;
        }
        break;
      case HSPICE_SIMULATION:
        if (visible[HSPICE_SIMULATION]) {
          return;
        }
        break;
      case PADSTACKS:
        if (visible[PADSTACKS]) {
          return;
        }
        break;
      case STACKUP:
        this.updateStackupPanel(true);
        return;
      default: break;
    }
    visible[key] = true;
    this.setState({
      visible
    }, () => {
      if (key === REPORT) {
        this.getReportConfig();
      }
    });
  }

  updateStackupPanel = (status) => {
    const { visible } = this.state;
    let { stackupStatusList = [] } = this.props
    const { selectedKeys, channelDesignId } = this.props
    const allIds = { selectedKeys, channelDesignId, currentID: channelDesignId }

    if (status) {
      let existSamePanel = stackupStatusList.find(item => item.designId === channelDesignId && item.type === 'PCB')

      if (existSamePanel && existSamePanel.status === STACKUP_OPEN) {
        stackupStatusList = getNewStackupList(stackupStatusList, STACKUP_CLOSE, allIds, false)
      } else {
        stackupStatusList = getNewStackupList(stackupStatusList, STACKUP_OPEN, allIds, false)
        this.setState({
          visible: {
            ...visible,
            [STACKUP]: status
          }
        })
      }
    } else {
      stackupStatusList = getNewStackupList(stackupStatusList, STACKUP_CLOSE, allIds, false)
      this.setState({
        visible: {
          ...visible,
          [STACKUP]: status
        }
      })
    }

    this.props._updateStackupStatusList(stackupStatusList)
  }

  getReportConfig = (_channelOrEndToEndId, isMultiPCB) => {
    const { channelId, projectId, endToEndChannelId, viewList, dataRate } = this.props;
    let _isEndToEnd = viewList.includes(END_TO_END_CHANNEL) ? true : false,
      _channelId = channelId, _endToEndChannelId = endToEndChannelId, interfaceType;

    if (_channelOrEndToEndId) {
      _channelId = !isMultiPCB ? _channelOrEndToEndId : '';
      _endToEndChannelId = isMultiPCB ? _channelOrEndToEndId : '';
      _isEndToEnd = isMultiPCB;
    }
    interfaceType = getSerdesType(_channelId || _endToEndChannelId, _isEndToEnd);

    this.props._getReportConfig({
      channelId: _channelId,
      endToEndChannelId: _endToEndChannelId,
      isEndToEnd: _isEndToEnd,
      dataRate,
      projectId,
      interfaceType
    });
  }

  closePanel = (key) => {
    /*     let { visible } = this.state; */
    let visible = { ...this.state.visible };
    visible[key] = false;
    this.setState({
      visible
    });
  }

  simulationClick = () => {
    const { viewList, channelId, endToEndChannelId, pcbChannelSimSelectKeys, endToEndSimSelectKeys, experimentIds } = this.props;
    const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(item));

    // Prevent clicking too fast
    if (!this.state.simulationloading) {
      return
    }
    this.setState({
      simulationloading: false
    })
    setTimeout(() => {
      this.setState({
        simulationloading: true
      })
    }, 1000);

    switch (view) {
      case PCB_CHANNEL:
        if (channelId) {
          const selectedKeys = pcbChannelSimSelectKeys.length ? pcbChannelSimSelectKeys : [channelId];
          this.props._startChannelExtraction([...selectedKeys], {});
        }
        break;
      case END_TO_END_CHANNEL:
        if (!endToEndChannelId) {
          break;
        }
        const selectedKeys = endToEndSimSelectKeys.length ? endToEndSimSelectKeys : [endToEndChannelId];
        this.props._startEndToEndSimulation([...selectedKeys])
        break;
      case EXPERIMENTS:
        this.props._startSweepExtraction(experimentIds, {});
        break;
      default: break;
    }
  }

  topBarItemClick = (e, key) => {
    switch (key) {
      case SIMULATE:
      case RUN:
        this.simulationClick();
        break;
      case MODELING:
      case DEBUG:
      case SEASIM:
      case AMI_SETUP:
      case SERVICES_OPTIONS:
      case DOWNLOAD:
      case HSPICE_SIMULATION:
        this.openPanel(key);
        break;
      case TOP_BAR_RESULT:
        this.openResult();
        break;
      default: break;
    }
  }

  openResult = () => {
    const { channelId, endToEndChannelId, viewList, channelResultExist, endToEndResultExist, sweepId } = this.props;
    const view = viewList.find(item => [PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(item));
    const result = resultDisabled({
      view,
      channelId,
      channelResultExist,
      endToEndChannelId,
      endToEndResultExist,
      sweepId
    });
    if (!result) {
      return;
    }

    this.props._openPage({ pageType: result.pageType, id: result.id });
  }

  getDisabled = (key) => {
    const { channelId, viewList, endToEndChannelId, projectId, channelLoading, channelEndToEndId, channelResultExist, endToEndResultExist, experimentIds, sweepId } = this.props;
    return getTopMenuDisabled({
      key,
      channelId,
      viewList,
      endToEndChannelId,
      projectId,
      channelLoading,
      channelEndToEndId,
      channelResultExist,
      endToEndResultExist,
      experimentIds,
      sweepId
    })
  }

  getDisplay = (key) => {
    const { channelId, isEndToEndChildren, viewList, endToEndChannelId, channelResultExist, endToEndResultExist, simulationSolver } = this.props;
    return getTopMenuDisplay({
      key,
      channelId,
      isEndToEndChildren,
      viewList,
      endToEndChannelId,
      channelResultExist,
      endToEndResultExist,
      simulationSolver
    })
  }

  getIcon = (type) => {
    switch (type) {
      case "play-circle":
        return <PlayCircleOutlined />
      case "setting":
        return <SettingOutlined />
      case "download":
        return <DownloadOutlined />
      case "right":
        return <RightOutlined />
      default:
        return <SettingOutlined />
    }
  }

  render() {
    const { width, viewList, channelId, endToEndChannelId, projectId, channelEndToEndId, pcbChannelSimSelectKeys, isEndToEndChildren, sweepId, experimentList,
      channelDesignId, layout } = this.props;
    const { visible } = this.state;
    const debugType = getDebugType({ viewList, channelId, endToEndChannelId, channelEndToEndId, sweepId });
    const currentPCBs = designConstructor.getDesignValues(projectId);

    return (
      <Fragment>
        <div id='andes-v2-top-bar' style={{ width: width ? width : '100%' }}>
          {ANDES_V2_TOP_BAR_OPTIONS.map(item => {
            if (!this.getDisplay(item.key)) {
              return null;
            }
            return this.getItemMenu(item);
          })}
        </div>
        {visible[MODELING] && <ExtractionOptionPanel
          closePanel={() => this.closePanel(MODELING)}
          channelId={channelId}
          pcbChannelSimSelectKeys={pcbChannelSimSelectKeys}
          isEndToEndChildren={isEndToEndChildren}
          _startChannelExtraction={this.props._startChannelExtraction}
        />}
        {visible[DEBUG] && <DebugPanel
          closePanel={() => this.closePanel(DEBUG)}
          debugType={debugType}
          channelId={channelId}
          projectId={projectId}
          sweepId={sweepId}
          endToEndChannelId={channelId && channelEndToEndId && debugType === END_TO_END_CHANNEL ? channelEndToEndId : endToEndChannelId}
        />}
        {visible[SEASIM] && this.getSeaSimPanel()}
        {visible[AMI_SETUP] && this.getAMISetupPanel()}
        {visible[REPORT] && this.getReportPanel()}
        {visible[SERVICES_OPTIONS] && <ServiceOptionsPanel closePanel={() => this.closePanel(SERVICES_OPTIONS)} />}
        {visible[DOWNLOAD] && <ExperimentDownloadPanel
          downloadData={experimentList.filter(item => item.status === VERIFY_SUCCESS)}
          sweepId={sweepId}
          closePanel={() => this.closePanel(DOWNLOAD)}
        />}
        {visible[HSPICE_SIMULATION] && <SettingPanel closePanel={() => this.closePanel(HSPICE_SIMULATION)} />}
        {visible[PADSTACKS] && <PadstackPanel
          designId={channelDesignId}
          closePanel={() => this.closePanel(PADSTACKS)}
          layout={layout}
        />}
        {visible[STACKUP] && <StackupPanel
          {...this.props}
          pageType={ANDES_V2}
          designID={channelDesignId}
          Stackup={Stackup}
          designList={currentPCBs}
          closeModal={this.updateStackupPanel} />}
      </Fragment>
    )
  }

  getSeaSimPanel = () => {
    const { viewList, channelId, endToEndChannelId } = this.props;
    if (channelId && viewList.includes(PCB_CHANNEL)) {
      return <ChannelSeasimConfig
        isEndToEndChildren={this.props.isEndToEndChildren}
        closePanel={() => this.closePanel(SEASIM)}
      />
    }

    if (endToEndChannelId && viewList.includes(END_TO_END_CHANNEL)) {
      return <EndToEndSeasimConfig
        closePanel={() => this.closePanel(SEASIM)}
      />
    }
    return null;
  }

  getExtractionOptions = (itemData) => {
    const disabled = this.props.channelLoading
    return <Dropdown
      key={itemData.key}
      menu={{ items: this.extractionMenu(itemData), className: "extraction-dropdown-button", onClick: ({ key }) => this.topBarItemClick(null, key) }}
      disabled={disabled}
      trigger={['click']} >
      {this.getItemMenu(itemData)}
    </Dropdown >
  }

  extractionMenu = (itemData) => {
    return itemData.children.map(item => ({ key: item.key, label: item.title }))
  }

  getSerdesType = () => {
    const { channelId, endToEndChannelId, viewList } = this.props;
    const isEndToEnd = viewList.includes(END_TO_END_CHANNEL) ? true : false;
    const serdesType = getSerdesType(channelId || endToEndChannelId, isEndToEnd);

    return serdesType;
  }

  getItemMenu = (itemData) => {
    const { channelId, viewList } = this.props;
    const { visible } = this.state;
    const disabledClassName = this.getDisabled(itemData.key);
    const serdesType = this.getSerdesType();
    switch (itemData.key) {
      case REPORT:
        return this.getReportTitle(itemData, disabledClassName);
      case LAYOUT:
        return this.getLayoutTitle(itemData.children);
      default:
        const isUseHspice = channelId && viewList.includes(PCB_CHANNEL) && serdesType === CPHY && itemData.key === AMI_SETUP;
        return <div
          key={itemData.key}
          className={`andes-v2-top-bar-item ${disabledClassName} ${itemData.className}`}
          onClick={(e) => isUseHspice ? null : this.topBarItemClick(e, itemData.key)}
          style={getTopMenuBackground(itemData.key, visible)}
          title={disabledClassName && itemData.key === TOP_BAR_RESULT ? "No Result." : itemData.title
          }
        >
          {this.getMenuItemTitle(itemData, isUseHspice)}
        </div >
    }
  }

  getMenuItemTitle = (itemData, isUseHspice) => {
    if (itemData.key === TOP_BAR_RESULT) {
      return <Fragment>
        <span className='aurora-top-bar-vertical-line'></span>
        <div className='andes-v2-top-bar-result-title'>
          <span className='andes-v2-top-bar-item-title'>{itemData.title}</span>
          <span className={itemData.icon.className}>&gt;</span>
        </div>
      </Fragment>
    } else {
      const Icon = itemData.icon.iconType === "iconfont" ? null : getIcon(itemData.icon.type)
      return <Fragment>
        {isUseHspice ? this.simulationDropdown(itemData)
          : <>
            {itemData.icon.iconType === "iconfont" ? <span className={`iconfont ${itemData.icon.className}`} title={itemData.title}></span>
              : <Icon type={itemData.icon.type} className={itemData.icon.className} />}
            <span className='andes-v2-top-bar-item-title'>{itemData.title}</span>
          </>}
      </Fragment>
    }
  }

  selectSimulationType = (e, preKey, key) => {
    if (preKey !== key) {
      const value = key === HSPICE_SIMULATE ? HSPICE : ADS;
      this.props._changeSimulationSolver(value);
    }
    this.topBarItemClick(e, key);
    this.setState({ popoVerVisible: false })
  }

  simulationDropdown = (itemData) => {
    const { popoVerVisible } = this.state;
    const { simulationSolver } = this.props;
    const value = simulationSolver === HSPICE ? HSPICE_SIMULATE : AMI_SETUP;
    const content = (<Radio.Group
      value={value}
      onChange={(e) => this.selectSimulationType(e, value, e.target.value)}
    >
      {[{ title: 'ADS', key: AMI_SETUP }, { title: 'HSPICE', key: HSPICE_SIMULATE }].map(item =>
        <Radio
          key={`simulation-radio-${item.key}`}
          className="andes-v2-simulation-radio"
          value={item.key}
          onClick={(e) => this.selectSimulationType(e, value, item.key)}>
          {item.title}
        </Radio>
      )}
    </Radio.Group>)
    const Icon = itemData.icon.iconType === "iconfont" ? null : getIcon(itemData.icon.type)
    return <Popover
      placement="bottomLeft"
      content={content}
      trigger="click"
      overlayClassName='andes-v2-simulation-popover'
      open={popoVerVisible}
      onOpenChange={(visible) => this.setState({ popoVerVisible: visible })}
    >
      <div>{itemData.icon.iconType === "iconfont" ?
        <span className={`iconfont ${itemData.icon.className}`} title={itemData.title}></span>
        : <Icon type={itemData.icon.type} className={itemData.icon.className} />}
        <span className='andes-v2-top-bar-item-title'>{itemData.title}</span></div>
    </Popover >
  }

  getReportTitle = (itemData, disabledClassName) => {
    const { visible } = this.state;
    const { reportProgress, reportVisible } = this.props;
    return reportVisible && !visible[REPORT] ? <Fragment key={itemData.key}>
      <div className={`andes-v2-top-bar-item andes-v2-top-sus-report-item ${disabledClassName}`}
        onClick={() => !disabledClassName ? this.openPanel(REPORT) : null}
        style={reportVisible ? { background: '#c7e0f4' } : {}}
        title={itemData.title}
      >
        <div className='andes-v2-top-sus-report'>
          <span className="iconfont icon-baogao andes-v2-top-sus-report-icon andes-v2-top-sus-icon-diff" title={itemData.title}></span>
          <span className="andes-v2-top-bar-item-title">{itemData.title}</span>
        </div>
        <div className='andes-v2-top-sus-report-progress' style={{ width: `${reportProgress}%` }} />
      </div>
      <span className='andes-v2-report-progress-span'>{reportProgress}%</span>
    </Fragment>
      :
      <div className={`andes-v2-top-bar-item ${disabledClassName}`}
        key={itemData.key}
        onClick={!disabledClassName ? () => this.openPanel(REPORT) : null}
        style={getTopMenuBackground(itemData.key, visible)}
        title={itemData.title}
      >
        <div className='andes-v2-top-sus-report'>
          <span className="iconfont icon-baogao andes-v2-top-sus-report-icon andes-v2-top-sus-icon-diff" title={itemData.title}></span>
          <span className="andes-v2-top-bar-item-title">{itemData.title}</span>
        </div>
      </div>
  }

  getLayoutTitle = (children) => {
    const { channelDesignId } = this.props;
    const PCBExist = designConstructor.getDesign(channelDesignId);
    const disabled = PCBExist ? false : true;

    const menuItems = children.map(child => ({ key: child.key, label: child.title, disabled, onClick: () => this.openPanel(child.key) }))

    return <div
      className={!disabled ? 'andes-v2-top-bar-item' : 'andes-v2-top-bar-item andes-v2-top-bar-item-disabled'}
      key='Layout'
      title='Layout'
    >
      <Dropdown menu={{ items: !disabled ? menuItems : [] }} trigger={['click']}>
        <div onClick={e => e.preventDefault()}>
          <span className="iconfont icon-stack aurora-top-bar-stackup-icon" />
          <span className="andes-v2-top-bar-item-title">Layout</span>
        </div>
      </Dropdown>
    </div >
  }

  getAMISetupPanel = () => {
    const { viewList, channelId, endToEndChannelId } = this.props;
    if (channelId && viewList.includes(PCB_CHANNEL)) {
      return <ChannelAMIModel
        isEndToEndChildren={this.props.isEndToEndChildren}
        closePanel={() => this.closePanel(AMI_SETUP)}
      />
    }

    if (endToEndChannelId && viewList.includes(END_TO_END_CHANNEL)) {
      return <EndToEndAMIModel
        closePanel={() => this.closePanel(AMI_SETUP)}
      />
    }
    return null;
  }

  getReportPanel = () => {
    const { components } = this.props;
    const serdesType = this.getSerdesType();
    return <Report
      closePanel={() => this.closePanel(REPORT)}
      getReportConfig={this.getReportConfig}
      serdesType={serdesType}
      components={components}
    />
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: {
    channel: { channelId, channelLoading, channelInfo: { endToEndId, designId, config, content = {}, type }, resultExist: channelResultExist, forcePreviewShow },
    project: { viewList, openProjectId, reportInfo = {}, selectedKeys, layout, stackupStatusList },
    endToEndChannel: { endToEndChannelId, resultExist: endToEndResultExist },
    simulation: { pcbChannelSimSelectKeys, endToEndSimSelectKeys },
    sweep: { experimentIds, sweepId, experimentData }
  } } = state;
  let dataRate = '16';
  if (config && config.analysis) {
    const { options } = config.analysis;
    if (options && options.datarate) {
      dataRate = options.datarate && options.datarate.replace('GT/s', '');
    }
  }

  const { components = [], simulationSolver } = content;
  return {
    channelId,
    viewList,
    projectId: openProjectId,
    channelLoading,
    endToEndChannelId,
    pcbChannelSimSelectKeys,
    endToEndSimSelectKeys,
    channelEndToEndId: endToEndId,
    isEndToEndChildren: isEndToEndChildrenChannel({ endToEndId }) ? true : false,
    channelResultExist,
    endToEndResultExist,
    forcePreviewShow,
    reportProgress: reportInfo.reportProgress,
    reportVisible: reportInfo.reportVisible,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    channelDesignId: designId,
    dataRate,
    experimentIds,
    sweepId,
    experimentList: experimentData ? experimentData.variablesTables : [],
    components,
    serdesType: type,
    simulationSolver,
    layout,
    selectedKeys,
    stackupStatusList
  }
}

const mapDispatch = (dispatch) => ({
  _startChannelExtraction(channelIds, config) {
    dispatch(startChannelExtraction(channelIds, config))
  },
  _startEndToEndSimulation(endToEndChannelIds) {
    dispatch(startEndToEndSimulation(endToEndChannelIds, {}))
  },
  _openPage({ pageType, id }) {
    dispatch(openPage({ pageType, id }))
  },
  _updateReportInfo(info) {
    dispatch(updateReportInfo(info))
  },
  _updateHybridStatus(status) {
    dispatch(updateHybridStatus(status))
  },
  _getReportConfig({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }) {
    dispatch(getReportConfig({ channelId, projectId, endToEndChannelId, isEndToEnd, dataRate, interfaceType }))
  },
  _startSweepExtraction(experimentIds) {
    dispatch(startSweepExtraction(experimentIds))
  },
  _changeSimulationSolver(simulationSolver) {
    dispatch(changeSimulationSolver(simulationSolver))
  },
  _updateStackupStatusList(stackupStatusList) {
    dispatch(updateStackupStatusList(stackupStatusList))
  }
});

function getSerdesType(channelOrEndToEndId, isMultiPCB) {
  try {
    if (isMultiPCB) {
      const item = endToEndChannelConstructor.getEndToEndChannel(channelOrEndToEndId);
      return item.type;
    } else {
      const item = channelConstructor.getChannel(channelOrEndToEndId);
      return item.type;
    }

  } catch (error) {
    return null;
  }
}

export default connect(mapState, mapDispatch)(TopBar)