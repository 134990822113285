import { SERDES_TYPES, getLayoutComponents, CONNECTOR, IC } from "../../PCBHelper";
import { findPinsBySignal } from '.';
import Aggressors from "../aggressors";
import { getPreLayoutAggressorsBySignal } from "../preLayout";
import { BGA, DIE } from "../../../constants/componentType";

function judgeSeaSimConfigUpdate(channelInfo) {
  if (!channelInfo.config || !channelInfo.config.channels) {
    return true;
  }
}

function getDirectionBySignalName(name) {
  if (name.match(/TX/ig)) {
    return "TX";
  } else if (name.match(/RX/ig)) {
    return "RX";
  }
}

/**
 * get controller and device components by comp type and comp pin list
 * 1. ic component only one -> is controller
 * 2. connector component only one -> is device
 * 3. The controller with the largest number of pins is the device, and the smallest is the device
 *  */
function getContAndDevCompByUsagePinList({
  layers,
  components,
  isPreLayoutChannel }) {
  let controllerComp = null, deviceComp = null;
  const icComps = components.filter(item => item.type === IC || item.type === BGA);
  const connComps = components.filter(item => item.type === CONNECTOR || item.type === DIE);

  if (icComps.length === 1) {
    //ic is controller
    controllerComp = JSON.parse(JSON.stringify(icComps[0]));
  }

  if (connComps.length === 1) {
    //connector is device
    deviceComp = JSON.parse(JSON.stringify(connComps[0]));
  }

  if (controllerComp && deviceComp) {
    return { controllerComp, deviceComp }
  }

  if (isPreLayoutChannel) {
    const comps = components.filter(item => SERDES_TYPES.includes(item.type));
    return {
      controllerComp: comps[0] ? JSON.parse(JSON.stringify(comps[0])) : null,
      deviceComp: comps[1] ? JSON.parse(JSON.stringify(comps[1])) : null
    }
  }

  const CompsInfo = getLayoutComponents({ layers }); // { Comp: { name, part, value, type, pinList, location} }
  let compMap = [];
  components.forEach(comp =>
    compMap.push({
      ...comp,
      pinLength: CompsInfo[comp.name] && CompsInfo[comp.name].pinList ? CompsInfo[comp.name].pinList.length : 0
    }));
  //sort comps bt comp pin list length
  const sortComps = Array.from(compMap.values()).sort((a, b) => { return a.pinLength - b.pinLength });

  if (!controllerComp) {
    //max pin length
    controllerComp = sortComps[sortComps.length - 1];
  }

  if (!deviceComp) {

    //min pin length
    deviceComp = sortComps[0];
  }

  return { controllerComp, deviceComp }
}

/**
 * get seasim config channels 
 *  channels :[ signal, controller, device, aggressors]
 *  */
function getSeaSimChannels({ signals, components, netsList, layers, isPreLayoutChannel, selectedSignals }) {
  let _signals = signals.filter(item => selectedSignals.includes(item.name));

  let channels = [], analysisChannels = [];
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
  //get controller / device
  const { controllerComp, deviceComp } = getContAndDevCompByUsagePinList({ layers, components: ICComps, isPreLayoutChannel });
  const aggressors = new Aggressors();
  if (!isPreLayoutChannel) {
    //calculate signal nets plump line and calculate the intersection of plump line and net trace 
    aggressors.calcIntersectionOfSignalNets(ICComps, signals, netsList);
  }

  for (let signal of signals) {
    let controller = { component: "", pins: [] }, device = { component: "", pins: [] };

    if (controllerComp) {
      //find controller pins by signal
      const pins = findPinsBySignal({ pins: controllerComp.pins || [], signals, signal: signal.name });
      controller = {
        component: pins.length ? controllerComp.name : "",
        pins
      }
    }

    if (deviceComp) {
      //find device pins by signal
      const pins = findPinsBySignal({ pins: deviceComp.pins || [], signals, signal: signal.name });
      device = {
        component: pins.length ? deviceComp.name : "",
        pins
      }
    }

    channels.push({
      signal: signal.name,
      controller: { ...controller },
      device: { ...device },
      direction: getDirectionBySignalName(signal.name)
    });
    let signalAggressors = [];
    if (isPreLayoutChannel) {
      signalAggressors = getPreLayoutAggressorsBySignal(ICComps, signal, _signals);
    } else {
      //get same connected components signals
      const sameGroupSignals = aggressors.getSignalGroups(signal.name) || [..._signals];
      signalAggressors = aggressors.getAggressorsByNet(ICComps, signal, sameGroupSignals)
    }

    analysisChannels.push({
      victim: signal.name,
      //get aggressors by signal nets distance
      aggressors: [...signalAggressors]
    })
  }
  return { channels, analysisChannels };
}

export {
  judgeSeaSimConfigUpdate,
  getSeaSimChannels
}
