import { RDIMM_TYPES, prbsTypes } from '../constants';
import { getIBISParseModels, getSpiceParseModels, getFolderSpiceParseModels } from '../library';
import { strDelimited } from '@/services/helper/split';
import { MEMORY } from '../../PCBHelper';
import { getPins } from './IbisModelHelper';
import { PROJECT_V2 } from '../../../constants/projectVersion';

//Rocky pin table
const pinRankColumns = [{
  title: 'Signal',
  dataIndex: 'signal',
  width: '8%',
  sorter: (a, b) => a.signal.localeCompare(b.signal)
}, {
  title: 'Nets',
  dataIndex: 'signalNets',
  width: '8%'
}, {
  title: 'Component - Pin',
  dataIndex: 'pin',
  width: '10%'
}, {
  title: 'Driver Model',
  dataIndex: 'driver',
  width: '16%',
}, {
  title: 'Receiver Model',
  dataIndex: 'receiver',
  width: '16%'
}, {
  title: 'Standby Model',
  dataIndex: 'stdModel',
  width: '16%'
}, {
  title: 'Stimulus',
  dataIndex: 'inputStimulus',
  width: '16%',
}];
const pinShowRankColumns = [{
  title: 'Signal',
  dataIndex: 'signal',
  width: '8%',
  sorter: (a, b) => a.signal.localeCompare(b.signal)
}, {
  title: 'Nets',
  dataIndex: 'signalNets',
  width: '8%'
}, {
  title: 'Component - Pin',
  dataIndex: 'pin',
  width: '10%'
}, {
  title: 'Rank ID in DIMM Card',
  dataIndex: 'rankId',
  width: '10%'
}, {
  title: 'Driver Model',
  dataIndex: 'driver',
  width: '16%',
}, {
  title: 'Receiver Model',
  dataIndex: 'receiver',
  width: '16%'
}, {
  title: 'Standby Model',
  dataIndex: 'stdModel',
  width: '16%'
}, {
  title: 'Stimulus',
  dataIndex: 'inputStimulus',
  width: '16%',
}];
const pinNormalColumns = [{
  title: 'Signal',
  dataIndex: 'signal',
  width: '8%',
  sorter: (a, b) => a.signal.localeCompare(b.signal)
}, {
  title: 'Nets',
  dataIndex: 'signalNets',
  width: '14%'
}, {
  title: 'Component - Pin',
  dataIndex: 'pin',
  width: '14%'
}, {
  title: 'Driver Model',
  dataIndex: 'driver',
  width: '22%',
}, {
  title: 'Receiver Model',
  dataIndex: 'receiver',
  width: '22%'
}, {
  title: 'Stimulus',
  dataIndex: 'inputStimulus',
  width: '20%',
}];
const NONE = "None";

function getPinColumns({ components, projectType, isHaveStandby, interfaceType, projectVersion }) {
  let columns = [], rankId = false, stanByModel = false
  if (isHaveStandby && RDIMM_TYPES.includes(projectType) && interfaceType !== "CLK" &&
    components.findIndex(item => item.dimmCardModel && item.dimmCardModel.type !== NONE) > -1) {
    columns = [...pinShowRankColumns];
    rankId = true;
    stanByModel = true;
  } else {
    const findRank = components.find(comp => comp.pkg && comp.pkg.rank);
    if (findRank || isHaveStandby) {
      columns = [...pinRankColumns]
      stanByModel = true
    } else {
      columns = [...pinNormalColumns]
    }
  }

  const isDisplayPkg = projectVersion === PROJECT_V2 && components.find(item => item.type === "Controller" && item.pkg && item.pkg.packageLayoutInfo && item.pkg.packageLayoutInfo.packageId) ? true : false;
  if (isDisplayPkg) {
    columns.splice(2, 0, {
      title: 'Package Nets',
      dataIndex: 'packageNets',
      width: '8%',
    })
    if (!rankId && !stanByModel) {
      columns[1].width = "12%";
      columns[2].width = "12%";
      columns[4].width = "18%";
      columns[5].width = "18%";
      columns[6].width = "18%";
    } else {
      for (let i = columns.length - 4; i < columns.length; i++) {
        columns[i].width = "14%";
      }
    }
  }
  return columns;
}

export class PinInfo {
  constructor(name, nets) {
    this.component = "";
    this.pin = "";
    this.net = nets && nets.length ? nets[0] : "";
    this.signal = name;
    this.type = "";
    this.signalNets = nets || [];
  }
}

function getPackagePinTableData(components, signals) {
  // package pin table
  let pins = [];
  const Types = ['Controller', 'Memory', 'BGA', 'DIE'];
  if (!signals || !signals.length) return pins;
  const ICComps = components.filter(item => Types.includes(item.type));
  let _pins = [], _signalNames = [];
  for (let signalInfo of signals) {
    const { nets, name } = signalInfo;
    if (ICComps.length && nets.length) {
      for (let comp of ICComps) {
        const filterPins = comp.pins.filter(item => item.signal === name)
        let info = [];
        if (filterPins && filterPins.length) {
          info = filterPins.map(item => {
            return {
              component: comp.name,
              pin: item.pin,
              net: item.net,
              signal: item.signal,
              type: comp.type,
              signalNets: nets || []
            }
          })
        }

        let _index = _signalNames.indexOf(name)
        if (_index > -1) {
          _pins[_index].push(...info);
        } else {
          _signalNames.push(name);
          _pins.push(info);
        }
      }
    } else {
      // no signal
      _signalNames.push(name);
      _pins.push([new PinInfo(name, nets), new PinInfo(name, nets)]);
    }
  }

  _pins.forEach(item => {
    if (item.length) {
      item[0].signalLength = item.length;
    }
    pins.push(...item);
  });
  return pins;
}

function getSevPackagePinTableData(components, signals, prevSignal) {
  // package pin table
  let pins = [];
  const Types = ['Controller', 'Memory', 'BGA', 'DIE'];
  if (!signals || !signals.length) return pins;
  const ICComps = components.filter(item => Types.includes(item.type));
  let _pins = [];
  for (let signalInfo of signals) {
    const { nets, name, signalGroup } = signalInfo;
    if (ICComps.length && nets.length) {

      let pinList = [];
      for (let comp of ICComps) {
        const filterPins = comp.pins.filter(item => item.signal === name)

        let info = [];
        if (filterPins && filterPins.length) {
          info = filterPins.map(item => {
            return {
              component: comp.name,
              pin: item.pin,
              net: item.net,
              signal: item.signal,
              type: comp.type,
              signalNets: nets || [],
              signalGroup: item.signalGroup ? item.signalGroup : signalGroup || ""
            }
          })
        }

        pinList.push(...info)
      }
      let net = pinList && pinList.length ? pinList[0].net : nets.length ? nets[0] : "";
      const _info = {
        signal: name,
        signalNets: nets || [],
        pinList: pinList,
        net: net,
        signalGroup: pinList && pinList.length ? pinList[0].signalGroup : signalGroup || ""
      };
      _pins.push(_info)

    } else {
      // no signal
      const findSignal = prevSignal && prevSignal.length ? prevSignal.find(item => item.name === name) : null;
      _pins.push({
        signal: name,
        signalGroup: findSignal && findSignal.signalGroup ? findSignal.signalGroup : signalInfo.signalGroup ? signalInfo.signalGroup : signalGroup || "Byte0",
        pinList: [],
        net: nets && nets.length ? nets[0] : "",
        signalNets: nets || []
      })
    }
  }

  let lastSignal = null, color = false;
  pins = _pins.map(item => {
    item.signalLength = 1;
    if (item.signalGroup !== lastSignal) {
      color = !color
      lastSignal = item.signalGroup;
    }
    item.color = color;
    return item;
  });
  return pins;
}

function getPinTableData(components, signals, libraryList = {}, projectType) {
  let pins = [];
  const Types = ['Controller', 'Memory', 'BGA', 'DIE'];
  if (components.length === 0) return pins;
  const ICComps = components.filter(item => Types.includes(item.type));
  let _pins = [], _signalNames = [];
  if (ICComps.length > 0) {
    ICComps.forEach(comp => {
      comp.pins.forEach(pin => {
        let info = [], Driver = null, Receiver = null, Standby = null, rankId = null, rank = null;
        if (pin.driver) {
          let inputStimulus = "", stimulus = null, newPinModels = [], notStimulusExist = false, inputTabs = "", inputSignal = "";
          if (pin.driver.pinModels && pin.driver.pinModels.length > 0) {
            newPinModels = JSON.parse(JSON.stringify(pin.driver.pinModels));
            let _index = pin.driver.pinModels.findIndex(item => item.pinName === "nd_in");
            const ND_IN = pin.driver.pinModels[_index];
            if (ND_IN) {
              if (ND_IN.type) {
                //-pinModels: [{ stimulus: {}, pinName, type:type-seed, voltage,}],
                //-newPinModels: [{ stimulus: {}, pinName, type, voltage ,seed}],
                let [str, str1, newSeed] = strDelimited(ND_IN.type, "-");
                let newType = `${str}-${str1}`;
                if (prbsTypes.includes(newType)) {
                  newPinModels[_index].type = newType;
                  newPinModels[_index].seed = newSeed;
                }

                if (ND_IN.type === 'VEC') {
                  notStimulusExist = getStimulusFileExist(ND_IN.stimulus, libraryList)
                }
              }
              inputStimulus = ND_IN.type;
              inputTabs = ND_IN.tabs ? ND_IN.tabs : [];
              stimulus = ND_IN.stimulus;
              inputSignal = ND_IN.relative && ND_IN.relative.signal ? ND_IN.relative : ""
            }
          }
          const notExist = pin.driver.model && pin.driver.model.fileName ? getModelFileExist(pin.driver.model, "Driver", libraryList) : false;
          Driver = {
            ...pin.driver,
            inputStimulus,
            inputTabs,
            inputSignal,
            stimulus,
            model: pin.driver.model,
            pinModels: newPinModels,
            powerOff: pin.driver.powerOff,
            notExist,
            notStimulusExist
          }
        }
        if (pin.receiver) {
          const notExist = pin.receiver.model && pin.receiver.model.fileName ? getModelFileExist(pin.receiver.model, "Receiver", libraryList) : false;
          Receiver = {
            ...pin.receiver,
            pinModels: [],
            notExist
          }
        }
        if (pin.stdModel) {
          const notExist = pin.stdModel && pin.stdModel.fileName ? getModelFileExist(pin.stdModel, "Receiver", libraryList) : false;
          Standby = {
            model: pin.stdModel,
            pinModels: [],
            notExist
          }
        }

        const signalFind = signals.find(item => item.name === pin.signal);
        if (RDIMM_TYPES.includes(projectType) && pin.dimmStdModels && pin.dimmStdModels.length > 0) {
          for (let dimmModelInfo of pin.dimmStdModels) {
            const notExist = dimmModelInfo.fileName ? getModelFileExist(dimmModelInfo, "Receiver", libraryList) : false;
            const _Standby = {
              model: dimmModelInfo,
              pinModels: [],
              notExist
            }
            const dimmInfo = {
              component: comp.name,
              pin: pin.pin,
              net: pin.net,
              signal: pin.signal,
              Driver,
              Receiver,
              Standby: _Standby,
              rankId: dimmModelInfo.rankId,
              rank: pin.dimmStdModels.length,
              deviceVcc: comp.deviceVcc || "",
              type: comp.type,
              pkg: comp.pkg,
              signalNets: signalFind ? signalFind.nets : [],
              packageNets: signalFind ? signalFind.packageNets : [],
              active: comp.active,
              dimmCardModel: comp.dimmCardModel,
            }
            info.push(dimmInfo)
          }
        } else {
          info.push({
            component: comp.name,
            pin: pin.pin,
            net: pin.net,
            signal: pin.signal,
            Driver,
            Receiver,
            Standby,
            rankId,
            rank,
            deviceVcc: comp.deviceVcc || "",
            type: comp.type,
            pkg: comp.pkg,
            signalNets: signalFind ? signalFind.nets : [],
            packageNets: signalFind ? signalFind.packageNets : [],
            active: comp.active,
            dimmCardModel: comp.dimmCardModel
          })
        }
        const _index = _signalNames.indexOf(pin.signal)
        if (_index > -1) {
          _pins[_index].push(...info);
        } else {
          _signalNames.push(pin.signal);
          _pins.push([...info]);
        }

      });
    });
  };
  const rule = /^(DQS)|^(WCK)|^(RDQS)|^(CLK)/ig;
  _pins = _pins.sort((a, b) => {
    const aMatchesRule = a[0].signal.match(rule) ? 1 : 0;
    const bMatchesRule = b[0].signal.match(rule) ? 1 : 0;
    return bMatchesRule - aMatchesRule;
  });

  _pins.forEach(item => {
    item[0].signalLength = item.length;
    pins.push(...item);
  });

  return pins;
}

function getModelFileExist(model, type, libraryList) {
  if (!model.modelName) {
    return false;
  }
  const { ibisList, spiceList, onDieSpiceList = [], ibisAmiList } = libraryList;

  let _libraryList = [];
  if (model.folder && model.libType === "SPICE") {
    let folderIndex = spiceList.findIndex(sp => sp.id === model.libraryId && sp.fileName === model.folder);
    _libraryList = folderIndex > -1 ? spiceList[folderIndex].children || [] : []
    if (folderIndex < 0) {
      folderIndex = (onDieSpiceList || []).findIndex(sp => sp.id === model.libraryId && sp.fileName === model.folder);
      _libraryList = folderIndex > -1 ? onDieSpiceList[folderIndex].children || [] : []
    }
  } else if (model.folder && ["IBIS_AMI", "IBIS"].includes(model.libType)) {
    const list = model.libType === "IBIS" ? [...ibisAmiList, ...ibisList] : [...ibisAmiList];
    const folderIndex = list.findIndex(it => it.id === model.libraryId && it.fileName === model.folder);
    _libraryList = folderIndex > -1 ? list[folderIndex].children || [] : [];
  } else {
    _libraryList = model.libType === 'IBIS' ? ibisList : spiceList;
  }
  const modelIndex = _libraryList.findIndex(file => file.id === model.libraryId && file.fileName === model.fileName)

  if (modelIndex < 0) {
    let findModel = _libraryList.find(file => file.fileName === model.fileName);
    if (!findModel) {
      return "deleted";
    } else {
      return "updated";
    }
  } else {
    let notExist = false;
    if (['IBIS', "IBIS_AMI"].includes(model.libType)) {
      let libraryId = model.libraryId;
      if ((model.libType === "IBIS_AMI" && model.libraryId && model.fileName)
        || (model.libType === "IBIS" && model.libraryId && model.fileName && _libraryList[modelIndex].type === "folder" && _libraryList[modelIndex].fileType === "ibis_ami")) {
        libraryId = `${model.libraryId}-${model.fileName}`
      }
      let obj = {
        libraryId: libraryId,
        usage: type === 'Driver' ? 'Tx' : 'Rx',
      }
      const res = getIBISParseModels(obj);
      if (res && res.models && res.models.length > 0 && model.modelName) {
        let find = res.models.find(item => item.name === model.modelName && item.type === model.modelType);
        if (find) {
          notExist = false;
        } else {
          notExist = "updated";
        }
      }
      return notExist;
    } else if (model.libType === 'SPICE') {
      let obj = {
        libraryId: model.libraryId,
        usage: type === 'Driver' ? 'Tx' : 'Rx',
      }
      const res = !model.folder ? getSpiceParseModels(obj) : getFolderSpiceParseModels({ libraryId: model.libraryId, fileName: model.fileName });
      if (res && res.length > 0 && model.modelName) {
        let find = res.find(item => item.name === model.modelName);
        if (find) {
          notExist = false;
        } else {
          notExist = "updated";
        }
      }
      return notExist;
    }
  }
}

function getStimulusFileExist(stimulus, libraryList) {
  const { vectorList } = libraryList;
  if (stimulus && stimulus.fileName) {
    let modelIndex = vectorList.findIndex(vec => vec.id === stimulus.libraryId && vec.name === stimulus.fileName)
    if (modelIndex < 0) {
      let findStimulus = vectorList.find(vec => vec.name === stimulus.fileName);
      if (!findStimulus) {
        return 'deleted';
      } else {
        return 'updated';
      }
    }
  }
  return false;
}

function getCompModelPinList(Components, compName) {
  let pinList = [];
  if (!Components) {
    return [];
  }
  const Comp = Components.find(item => item.name === compName);
  if (Comp && Comp.pins) {
    Comp.pins.forEach(item => {
      let pinItem = {
        pin: item.pin,
        net: item.net,
        signal: item.signal
      };
      pinList.push(pinItem)
    })
  }
  return pinList;
}

function getSocketModel(Components) {
  let pinList = [];
  const dimmConnectorComps = Components.filter(item => item.type === MEMORY);

  for (let comp of dimmConnectorComps) {
    let pins = comp.pins.map(item => {
      return {
        pin: item.pin,
        net: item.net,
        signal: item.signal,
        component: comp.name
      }
    });
    pinList.push(...pins)
  }
  return {
    compNames: dimmConnectorComps.map(item => item.name),
    pinList
  }
}

function getPinBufferModelText(record, type) {
  let _value = "";
  if (type === "driver" && record.Driver && record.Driver.model) {
    const value = record.Driver.model.modelName || "";
    const node = record.Driver && record.Driver.model && record.Driver.model.node ? record.Driver.model.node : {};
    const hasNode = node.in || node.out ? true : false;
    _value = `${value}${hasNode ? " (" : ""}${node ? `${node.in ? `IN:${node.in}` : ""}${node.out ? `, OUT:${node.out}` : ""}` : null}${hasNode ? ")" : ""}`
  } else if (type === "receiver" && record.Receiver && record.Receiver.model) {
    const value = record.Receiver.model.modelName || "";
    const node = record.Receiver && record.Receiver.model && record.Receiver.model.node ? record.Receiver.model.node : {};
    const hasNode = node.out ? true : false;
    _value = `${value}${hasNode ? " (" : ""}${node ? `${node.out ? `OUT:${node.out}` : ""}` : null}${hasNode ? ")" : ""}`
  }
  return _value;
}

function getInfo(defaultValue, libraryList) {
  let dataSource = [];
  for (let key of ["Victim", "Aggressor"]) {
    const data = defaultValue[key];
    let signalLength = data.length;

    for (let info of data) {
      // const { pinModels } = info;
      let _pinModels = info.pinModels
      if (!_pinModels || !_pinModels.length) {
        _pinModels = getPins({ usage: "Driver" })
      }
      const Driver = getPinModelDriveInfo(_pinModels, libraryList)
      dataSource.push({ ...info, signalLength, type: key, Driver, pinModels: _pinModels });
      signalLength = 0;
    }
  }
  return dataSource;
}

function getPinModelDriveInfo(pinModels, libraryList) {
  let inputStimulus = "", stimulus = null, newPinModels = [], notStimulusExist = false, inputTabs = "", inputSignal = "";
  if (pinModels && pinModels.length > 0) {
    newPinModels = JSON.parse(JSON.stringify(pinModels));
    let _index = pinModels.findIndex(item => item.pinName === "nd_in");
    const ND_IN = pinModels[_index];
    if (ND_IN) {
      if (ND_IN.type) {
        let [str, str1, newSeed] = strDelimited(ND_IN.type, "-");
        let newType = `${str}-${str1}`;
        if (prbsTypes.includes(newType)) {
          newPinModels[_index].type = newType;
          newPinModels[_index].seed = newSeed;
        }

        if (ND_IN.type === 'VEC') {
          notStimulusExist = getStimulusFileExist(ND_IN.stimulus, libraryList)
        }
      }
      inputStimulus = ND_IN.type;
      inputTabs = ND_IN.tabs ? ND_IN.tabs : [];
      stimulus = ND_IN.stimulus;
      inputSignal = ND_IN.relative && ND_IN.relative.signal ? ND_IN.relative : ""
    }
  }
  const Driver = {
    // ...pin.driver,
    inputStimulus,
    inputTabs,
    inputSignal,
    stimulus,
    pinModels: newPinModels,
    notStimulusExist
  }
  return Driver;
}

export {
  getPinTableData,
  getPinColumns,
  getCompModelPinList,
  getSocketModel,
  getPackagePinTableData,
  getPinBufferModelText,
  getInfo,
  getPinModelDriveInfo,
  getSevPackagePinTableData
}