import React, { Component } from 'react';
import { UNIT } from '@/services/data/newStackupData';
import StackupTable from '@/components/EditableTable';
import { EyeOutlined } from '@ant-design/icons';
import { message, Select, Radio } from 'antd';
import LayoutData from '@/services/data/LayoutData';
import {
  DIELECTRIC,
  getStackupColumns,
  stackupErrorCheck,
  StackupLayer,
  getStackupData,
  getStackupColumnsList,
  LAMINATE,
  MULTI_ZONE,
  updateThicknessByUnit,
  getThicknessScale
} from '../../../../../services/Stackup';
import MaterialPanel from '@/components/Material';
import SurfaceRoughnessPanel from '@/components/SurfaceRoughness';
import EtchFactorHelpPanel from './etchFactorHelpPanel'
import { THICKNESS } from '../../../../../services/Stackup/stackupTableHelper';
import { ANDES_V2, CASCADE, SIERRA } from '../../../../../constants/pageType';

const Option = Select.Option;
const RadioGroup = Radio.Group

class Stackup extends Component {
  constructor(props) {
    super(props);
    const isMultiZones = props.zones ? true : false;
    const isLaminated = isMultiZones ? true : false;
    const stackupTypes = (props.stackups || []).map(item => item.name);
    this.state = {
      loading: false,
      StackupColumns: getStackupColumnsList(props.pageType, isMultiZones, isLaminated, stackupTypes),
      errorDisplay: false,
      errorMsg: null,
      etchFactorHelpVisible: false,
      tableTitleHeight: 50,
      stackupMode: LAMINATE,
      newInsertIndex: null,
      addRowLightClass: false
    }
  }

  StackupColumnsOnCell = (unit) => {
    const { materialList } = this.props;
    this.setState((prevState) => {
      prevState.StackupColumns = getStackupColumns({
        StackupColumns: prevState.StackupColumns,
        unit,
        materialList,
        saveData: this.saveData,
        settingUnitChange: this.settingUnitChange,
        updateDielectric: this.updateDielectric,
        MaterialPanel,
        _saveMaterial: this._saveMaterial,
        SurfaceRoughnessPanel,
        _saveRoughness: this._saveRoughness,
        openMaterialMenu: this.props.openMaterialMenu,
        pageType: this.props.pageType,
        stackupEtchFactor: this.stackupEtchFactor,
        changeZoneType: this.changeZoneType,
        checkLaminated: this.checkLaminated,
        checkAllZoneType: this.checkAllZoneType
      });
      return {
        StackupColumns: prevState.StackupColumns,
      }
    }, () => {
      const tableTitleTh = document.querySelector("#stackup-panel-main .stackup-table .ant-table-header .ant-table-fixed");
      if (tableTitleTh) {
        this.setState({
          tableTitleHeight: tableTitleTh.offsetHeight + 2
        })
      }
    })
  }

  stackupEtchFactor = (e) => {
    this.setState({
      etchFactorHelpVisible: true
    })
  }
  closeEtchFactor = (e) => {
    this.setState({
      etchFactorHelpVisible: false
    })
  }

  changeZoneType = (e, type, record) => {
    const checked = e.target.checked;

    const { data, designID, stackups } = this.props;
    const newIndex = stackups.findIndex(item => item.name === type);
    if (newIndex > -1) {
      if (checked) {
        stackups[newIndex].layers.push(record.name);
        stackups[newIndex].layers = [...new Set(stackups[newIndex].layers)]
      } else {
        stackups[newIndex].layers = stackups[newIndex].layers.filter(item => item !== record.name);
      }
    }

    const newInsertIndex = data.findIndex(item => item.name === record.name);
    data[newInsertIndex].stackupLayerTypes = checked ? [...data[newInsertIndex].stackupLayerTypes, type]
      : data[newInsertIndex].stackupLayerTypes.filter(item => item !== type);
    if (data[newInsertIndex].stackupLayerTypes && data[newInsertIndex].stackupLayerTypes.length && !data[newInsertIndex].laminated) {
      data[newInsertIndex].laminated = true;
    }
    this.props.setStackupData({
      data: data,
      stackups,
      designID
    });
  }

  checkLaminated = (checked, record) => {
    const { data, designID, stackups } = this.props;
    const newIndex = data.findIndex(item => item.name === record.name);
    if (newIndex > -1) {
      data[newIndex].laminated = checked;
      data[newIndex].stackupLayerTypes = [];
    }

    for (let item of (stackups || [])) {
      item.layers = item.layers.filter(it => it !== record.name)
    }

    this.props.setStackupData({
      data: data,
      designID,
      stackups
    });
  }

  checkAllZoneType = (checked, type) => {
    //todo
  }

  _saveMaterial = ({ newMaterial, applyAllType, selectMaterialName, record, dataIndex }) => {
    const { designID } = this.props;
    this.props.saveMaterial({ newMaterial, applyAllType, selectMaterialName, record, designID, dataIndex });
  }

  _saveRoughness = (roughnessObj, record) => {
    const { designID } = this.props;
    this.props.saveRoughness(roughnessObj, record, designID);
  }

  saveData = (row, dataType) => {
    const { designID } = this.props;
    this.props._saveStackupTableData(row, dataType, designID);
  }

  InitializeData = (stackupData, unit, designID) => {
    const data = stackupData.getStackupTableData();
    this.props.setStackupData({
      data: data.tableData,
      materialList: data.materialList,
      stackups: data.stackups,
      zones: data.zones,
      unit,
      designID,
      bends: data.bends
    });
  }

  settingUnitChange = (value) => {
    const { data, unit, designID } = this.props;
    this.props.settingUnit(value, designID);
    const scale = getThicknessScale(value, unit);
    let updateLayers = [...data];
    updateLayers.forEach(layer => {
      layer.thickness = updateThicknessByUnit(scale, layer.thickness)
    })
    this.props.changeTable(updateLayers, value, designID);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    };
    const { designID, pageType } = this.props;
    LayoutData.getStackupJson({ pcbId: designID, reload: true, determineMetalLayer: true }).then(stackupData => {
      if (!stackupData || !stackupData.stackup) {
        this.StackupColumnsOnCell();
        return;
      }
      const totalHight = this.props.getRealHeight(stackupData.stackup);
      this.setState({ totalHight })
      const unit = stackupData.getUnitString();
      this.InitializeData(stackupData, unit, designID);
      const isMultiZones = stackupData.stackup.zones ? true : false;
      const isLaminated = isMultiZones ? true : false;
      const stackupTypes = (stackupData.stackup.stackups || []).map(item => item.name);
      const StackupColumns = getStackupColumnsList(pageType, isMultiZones, isLaminated, stackupTypes);
      this.setState({
        StackupColumns,
        stackupMode: LAMINATE
      }, () => {
        this.StackupColumnsOnCell(unit)
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps) {
    const { stackupColumnsStatus, unit, designID, zones, pageType, stackups } = this.props;
    if (prevProps.unit && unit !== prevProps.unit) {
      this.setState((prevState) => {
        const index = prevState.StackupColumns.findIndex(item => item.dataIndex === THICKNESS);
        if (index > -1) {
          prevState.StackupColumns[index].title = () => {
            return <span>
              Thickness <Select
                value={this.props.unit}
                onChange={this.settingUnitChange}
                className='stackup-unit-selection'
                popupClassName='stackup-unit-option'
              >
                {UNIT.map(unit => <Option value={unit} key={unit}>{unit}</Option>)}
              </Select>
            </span>
          };
        }
        return {
          StackupColumns: prevState.StackupColumns,
        }
      })
    }

    if (stackupColumnsStatus && stackupColumnsStatus !== prevProps.stackupColumnsStatus) {
      const isMultiZones = zones ? true : false;
      const isLaminated = isMultiZones ? true : false;
      const stackupTypes = (stackups || []).map(item => item.name);
      const StackupColumns = getStackupColumnsList(pageType, isMultiZones, isLaminated, stackupTypes);
      this.setState({
        StackupColumns,
        stackupMode: LAMINATE
      }, () => {
        this.StackupColumnsOnCell(unit)
      })
      this.props.updateStackupColumnsStatus(false, designID);
    }
  }

  getTotalThickness(data) {
    if (data.length === 0) {
      return null;
    }
    let sum = 0;
    data.forEach(function (item) {
      sum += parseFloat(item.thickness);
    });
    return sum.toFixed(3);
  }

  closeStackupPanel = (save) => {
    const { data, designID, materialList, stackups, pageType, zones, unit, bends } = this.props;
    if (!save) {
      //error check
      const error = stackupErrorCheck({
        layers: data,
        materialList: materialList,
        unit,
        stackups,
        zones,
        pageType,
        bends
      });

      if (error && error.length) {
        this.props._updateStackupErrorMsg({
          error: error[0].layerName ? `${error[0].layerName}: ${error[0].error}` : error[0].error,
          type: error[0].dataType || error[0].type
        }, designID);
        return true;
      }
    }

    const stackupData = LayoutData.saveStackupJson(designID, {
      data,
      materialList,
      unit,
      stackups,
      zones,
      bends
    });
    this.props._saveStackupToServer(stackupData, designID);
  }

  // action: add or delete
  // fix: Adjust the position of adding or deleting, 0 or 1
  updateDielectric = (dielectric, action, fix) => {
    const stackupName = dielectric.name;
    const stackupLayerTypes = dielectric.stackupLayerTypes;
    const { stackups } = this.props;
    // deep copy data array
    let newData = this.props.data.map(item => {
      return { ...item }
    })
    const startStackup = newData[0].indexStackup, startTable = newData[0].indexTable;
    // targetLayer: Determine where to add
    // insertLayer: The layer to be inserted
    let targetLayer = newData[dielectric.indexStackup] || newData[newData.length - 1], insertLayers = [];
    if (!targetLayer) {
      return message.error(`${action} failed.`);
    }
    if (action === 'add') {
      // DielectricCount: dielectric layer total
      // DielectricName: exist dielectric layer name
      // newLayerName: will insert layer name
      let DielectricCount = 2, DielectricName = [], prevMetalName, nextMetalName, newLayerName;
      for (let i = targetLayer.indexStackup + fix - 1; i >= 0; i--) {
        if (newData[i].type === 'Metal') {
          prevMetalName = newData[i].name;
          break;
        }
      }
      for (let i = targetLayer.indexStackup + fix; i < newData.length; i++) {
        if (newData[i].type === 'Metal' && newData[i].name !== prevMetalName) {
          nextMetalName = newData[i].name;
          break;
        }
      }
      if (prevMetalName && nextMetalName) {
        newLayerName = `D_${prevMetalName}_${nextMetalName}`;
      } else {
        newLayerName = `D_${prevMetalName ? prevMetalName : nextMetalName}`;
      }
      // filter similar name
      const similarNameRegExp = new RegExp(newLayerName);
      const similarName = newData.filter(item => similarNameRegExp.test(item.name)).map(item => item.name.slice(newLayerName.length + 1));
      if (similarName.length > 0) {
        let updateName = false;
        similarName.forEach(item => {
          if (!isNaN(Number(item))) {
            DielectricName[item] = true;
            updateName = true;
          }
        })
        if (updateName) {
          while (DielectricName[DielectricCount]) { DielectricCount++ };
          newLayerName = `${newLayerName}_${DielectricCount}`;
        }
      }
      const newLayer = new StackupLayer({ name: newLayerName, type: DIELECTRIC });
      const findStackupTypes = (this.props.stackups || []).filter(it => it.layers && it.layers.find(i => i === newLayer.name));
      insertLayers[0] = {
        indexMetal: null,
        name: newLayer.name,
        type: newLayer.type,
        thickness: newLayer.thickness,
        material: newLayer.material,
        stackupLayerTypes: findStackupTypes ? findStackupTypes.map(it => it.name) : [], //"FLEX" / "RIGID" / "PRIMARY"
        laminated: newLayer.laminated
      }
    }
    // update data and index
    newData.splice(dielectric.indexStackup + fix, action === 'add' ? 0 : 1, ...insertLayers);
    for (let newIndex = 0; newIndex < newData.length; newIndex++) {
      newData[newIndex].indexStackup = newIndex + startStackup;
      newData[newIndex].indexTable = newIndex + startTable;
    }
    if (!stackups) {
      // Update data to reducer
      const { unit, designID } = this.props;
      this.props.changeTable(newData, unit, designID);
    } else {
      let _stackups = [...stackups];
      _stackups.forEach(stackup => {
        if (stackupLayerTypes.includes(stackup.name)) {
          stackup.layers = action === 'add' ? [...stackup.layers] : stackup.layers.filter(item => item !== stackupName);
        }
      })
      const { unit, designID } = this.props;
      this.props.setStackupData({
        data: newData,
        designID,
        stackups: _stackups,
        unit
      });
    }
    if (action === 'add') {
      setTimeout(() => {
        const newInsertIndex = newData.findIndex(item => item.name === insertLayers[0].name);
        this.setState({ addRowLightClass: true, newInsertIndex });
        setTimeout(() => {
          this.setState({ addRowLightClass: false, newInsertIndex: null });
        }, 3000);
      }, 0)
    }
  }

  changeStackupType = (e) => {
    const { zones, pageType, unit, data, stackups } = this.props;

    if (e.target.value === LAMINATE) {
      data.forEach(item => {
        item.laminated = item.laminated === undefined ? true : item.laminated
      })
    }

    // this.props.setStackupData({
    //   data,
    //   stackupMode: e.target.value,
    //   designID
    // });
    const isMultiZones = zones ? true : false;
    const isLaminated = isMultiZones && (!e.target.value || e.target.value === LAMINATE) ? true : false;
    const stackupTypes = (stackups || []).map(item => item.name);
    const StackupColumns = getStackupColumnsList(pageType, isMultiZones, isLaminated, stackupTypes);
    this.setState({
      StackupColumns,
      stackupMode: e.target.value
    }, () => {
      this.StackupColumnsOnCell(unit)
    })
  }

  displayZones = () => {
    const { designID, stackupZonePanelShow } = this.props;
    this.props._changeStackupZonePanelShow(!stackupZonePanelShow, designID)
  }

  getMultiZoneType = () => {
    const { stackupMode } = this.state;
    return (
      <div className='stackup-multi-zone-radio-group'>
        <EyeOutlined
          className="aurora-multi-zone-stackup-icon"
          title={'Display multi-zone to layout.'}
          onClick={() => this.displayZones()} />
        <RadioGroup onChange={this.changeStackupType} value={stackupMode} size="small" buttonStyle="solid">
          <Radio.Button value={MULTI_ZONE}>Multi-Zone</Radio.Button>
          <Radio.Button value={LAMINATE}>{LAMINATE}</Radio.Button>
        </RadioGroup>
      </div>
    );
  }

  render() {
    const { data, loading, scrollY, materialList, width, zones, pageType } = this.props;
    const { StackupColumns, totalHight, etchFactorHelpVisible, tableTitleHeight, newInsertIndex, addRowLightClass } = this.state;
    const totalThickness = this.getTotalThickness(data);
    const stackupData = getStackupData(data, materialList);
    const _scrollY = scrollY - tableTitleHeight;
    const isMultiZones = zones && [CASCADE, ANDES_V2, SIERRA].includes(pageType);
    const scrollX = isMultiZones ? 1300 : 1000;
    let scroll = { y: totalHight ? totalHight > _scrollY ? _scrollY : false : _scrollY, x: width && width < scrollX ? "100%" : false };
    return (
      <div className="stackup-table">
        {isMultiZones ? this.getMultiZoneType() : null}
        <StackupTable
          loading={loading}
          dataSource={stackupData}
          columns={StackupColumns}
          scroll={scroll}
          tableLayout='fixed'
          rowKey={record => record.name}
          footer={() => <TotalThickness
            thickness={totalThickness}
          />}
          size="small"
          rowClassName={(record, index) => {
            if (record.type === "Dielectric" && index === newInsertIndex && addRowLightClass) {
              return "stackup-table-row-light stackup-all-layer"
            } else if (record.type === "Dielectric" && (index === 0 || index === data.length - 1)) {
              return "stackup-out-dielectric-color stackup-all-layer"
            } else if (record.type === 'Dielectric') {
              return "stackup-in-dielectric-color stackup-all-layer"
            } else if (record.type === 'Metal') {
              return "stackup-all-metal-color stackup-all-layer"
            }
          }}
        />
        {etchFactorHelpVisible ? <EtchFactorHelpPanel closeEtchFactor={this.closeEtchFactor} /> : null}
      </div>
    )
  }
}

const TotalThickness = (props) => {
  return (
    <div className='stackup-total-thickness-content'>
      <div>Total Thickness : </div>
      <div>{props.thickness}</div>
    </div>
  )
}

export default Stackup;