import { checkRLCValue } from './dataProcess';

function splitValue(rlc, defV, defU, _unit) {
  let value, unit;
  rlc = checkRLCValue(rlc).toString();
  if (!isNaN(Number(rlc))) {
    return { value: rlc, unit: parseFloat(rlc) !== 0 ? _unit : defU }
  } else {
    unit = rlc.match(/\D$/);
    if (unit) {
      unit = unit[0];
    }
    value = rlc.slice(0, -(unit.length));
    if ((unit && !isNaN(Number(unit))) || !unit) {
      unit = defU;
    }
    if (isNaN(value)) {
      value = defV;
    }
    return { value: value.toString(), unit }
  }
}

function checkTypeof(type) {
  return (typeof type) === 'string' || (typeof type) === 'number';
}

function splitRLCValue(rlc) {
  let res = { R: {}, L: {}, C: {} };
  if (rlc && checkTypeof(rlc.R)) {
    // 0 default value, 'Ω' default unit
    const resR = splitValue(rlc.R, 0, 'Ω', 'Ω');
    res.R = { ...resR };
  }
  if (rlc && checkTypeof(rlc.L)) {
    const resL = splitValue(rlc.L, 0, 'n', 'H');
    res.L = { ...resL };
  }
  if (rlc && checkTypeof(rlc.C)) {
    const resC = splitValue(rlc.C, 0.1, 'n', 'F');
    res.C = { ...resC }
  }
  return res;
}

export { splitRLCValue };