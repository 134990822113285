import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, PlusCircleOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Spin, message } from "antd";
import Table from "@/components/EditableTable";
import { addSignal, updateAndesInterface, editSignalName, editSignal, deleteSignal } from '../../store/andes/action';
/* import { saveComponentsNetsInProject } from '../../store/project/action'; */
import { autoFilterSignalNets } from '@/services/Andes';
import { canvasSelect, changeDisplaySelected } from '../../../LayoutExplorer/store/Andes/actionCreators';
import { getRelatedNets } from '../../../../services/Andes/search';
/* import LayoutData from '@/services/CeLayoutDB/newCeLayoutData'; */
import LayoutData from '@/services/data/LayoutData';

import '../index.css';

const columns = [
  {
    title: "Signal",
    dataIndex: "name",
    width: "20%"
  },
  {
    title: "P_Nets",
    dataIndex: "positive",
    width: "40%"
  },
  {
    title: "N_Nets",
    dataIndex: "negative",
    width: "40%"
  }
];

class SignalsNetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onSearchValue: null,
      relatedShow: true
    }

    columns[0].render = (value, row, index) => {
      const obj = {
        children: <span>{value}{<CloseOutlined
          className='signal-delete-icon'
          onClick={(e) => this.delSignal(e, row, index)} />}</span>,
        props: {},
      }
      if (row.signalLength && row.signalLength > 0) {
        obj.props.rowSpan = row.signalLength;
        obj.props.tdClassName = 'signalName'
      } else if (!row.pcb) {
        obj.props.rowSpan = 1;
      } else {
        obj.props.rowSpan = 0;
      }
      return obj;
    }

    columns[0].onCell = (record, index) => ({
      record,
      edit: true,
      dataIndex: 'name',
      className: 'andes-signal-name-cell',
      handleSave: (row) => this.saveSignal(row, record),
    });

    columns[1].render = (value) => {
      return <span>{value.join(', ')}</span>
    }

    columns[1].onCell = (record) => {
      return {
        record,
        edit: 'select',
        netSelect: true,
        dataIndex: "positive",
        selectMode: 'multiple',
        handleSave: this.selectPositiveNets,
        selectType: 'positive',
        getPopupContainer: document.getElementById("andes-content-main"),
        onFocus: () => this.netSelectFocus(record, 'positive'),
        dropdownRender: (form, clearInputValue) => this.dropdownRender(form, record, 'positive', clearInputValue),
        onSearch: (value) => this.nzOnSearch(value),
        clearSelectStatus: () => this.clearSelectStatus(),
        dropdownMenuClassName: 'andes-select-dropdown-menu'
      };
    };

    columns[2].onCell = (record) => {
      return {
        record,
        edit: 'select',
        netSelect: true,
        dataIndex: "negative",
        selectMode: 'multiple',
        handleSave: this.selectNegativeNets,
        selectType: 'negative',
        onFocus: () => this.netSelectFocus(record, 'negative'),
        dropdownRender: (form, clearInputValue) => this.dropdownRender(form, record, 'negative', clearInputValue),
        onSearch: (value) => this.nzOnSearch(value),
        clearSelectStatus: () => this.clearSelectStatus(),
        dropdownMenuClassName: 'andes-select-dropdown-menu'
      };
    };

    columns[2].render = (value) => {
      return <span>{value.join(', ')}</span>
    }
  }

  delSignal = (e, row, index) => {
    /* e.stopPropagation(); */
    this.props.deleteSignal({ name: row.name })
  }

  netSelectFocus = (record, type) => {
    const { pcbId, positive, negative } = record;
    const { designID, viewList } = this.props;
    let nets = type === 'positive' ? positive : negative;
    if (designID === pcbId && viewList && viewList.length > 1) {
      this.props.select({ nets: [...nets] });
      this.props.changeDisplaySelected(true)
    }
  }

  clearSelectStatus = () => {
    this.setState({
      onSearchValue: null
    });
  }

  selectPositiveNets = (record) => {
    const { name, positive, pcbId } = record;
    const options = this.getNetsList(record);
    const newNets = positive.filter(item => options.includes(item));
    const { designID, viewList, designId } = this.props;
    if (pcbId) {
      if (pcbId === designID && viewList && viewList.length > 1) {
        this.props.select({ nets: [...newNets] });
      }
      this.props.editSignal({ nets: [...newNets], pcbId, signalName: name, netType: 'positive' });
    } else {
      if (designId === designID && viewList && viewList.length > 1) {
        this.props.select({ nets: [...newNets] });
      }
      this.props.editSignal({ nets: [...newNets], pcbId: designId, signalName: name, netType: 'positive' });
    }
  }

  selectNegativeNets = (record) => {
    const { name, negative, pcbId } = record;
    const options = this.getNetsList(record);
    const newNets = negative.filter(item => options.includes(item));
    const { designID, viewList, designId } = this.props;
    if (pcbId) {
      if (pcbId === designID && viewList && viewList.length > 1) {
        this.props.select({ nets: [...newNets] });
      }
      this.props.editSignal({ nets: [...newNets], pcbId, signalName: name, netType: 'negative' });
    } else {
      if (designId === designID && viewList && viewList.length > 1) {
        this.props.select({ nets: [...newNets] });
      }
      this.props.editSignal({ nets: [...newNets], pcbId: designId, signalName: name, netType: 'negative' });
    }
  }

  getNetsList = (record) => {
    const { designId, PCBNets } = this.props;
    let netList = PCBNets[designId] || [];
    return autoFilterSignalNets(netList) || [];
  }

  getNetsOptions = (record, type) => {
    const { designId, PCBNets } = this.props;
    const { positive, negative } = record;
    const { onSearchValue } = this.state;
    let netList = PCBNets[designId] || [];
    let nets = autoFilterSignalNets(netList) || [];
    let relatedNets = [];
    let related = type === 'positive' ? positive : negative;
    const _LayoutData = LayoutData.getLayout(designId);
    related.forEach(item => {
      let list = getRelatedNets(item, _LayoutData);
      relatedNets = [...relatedNets, ...list.filter(i => !related.includes(i))];
    });
    if (!onSearchValue) {
      let PCIeNets = [], otherNets = [];
      let reg = new RegExp('PCIE', 'i');
      nets.forEach(item => {
        if (item.match(reg)) {
          PCIeNets.push(item);
        } else {
          otherNets.push(item)
        }
      });
      return { relatedNets, nets: [...PCIeNets, ...otherNets] }
    } else {
      let newOptions = [];
      let reg = new RegExp(onSearchValue, 'i');
      nets.forEach(item => {
        if (item.match(reg)) {
          newOptions.push(item);
        }
      });
      return { relatedNets, nets: [...newOptions] }
    }
  }

  nzOnSearch = (value) => {
    this.setState({
      onSearchValue: value
    })
  }

  addPCBs = (record) => {
    const { pcb, name, pcbId } = record;
    this.props.addPCBInSignals(pcb, name, pcbId);
  }

  addSignal = (e) => {
    this.props.addSignal();
  }

  saveSignal = (row, prev) => {
    const prevName = prev.name;
    const name = row.name;
    const { signals } = this.props;
    let newSignals = [...signals];
    if (!row.pcb) {
      let reName = newSignals.find(item => item.name !== prevName && item.name === name);
      if (reName) {
        message.error('Signal name cannot be repeated.');
        return;
      }
      newSignals.map(item => {
        if (item.name === prevName) {
          item.name = name;
        }
        return item;
      });

      this.props.updateAndesInterface({ signals: [...newSignals] });
      return;
    }
    this.props.editSignalName(name, prevName);
  }

  selectNets = (e, record, value, type, form) => {
    e.stopPropagation();
    if (type === 'positive') {
      const { name, positive, pcbId } = record;
      const options = this.getNetsList(record);
      let nets = [];
      if (positive.includes(value)) {
        nets = positive.filter(item => item !== value);
      } else {
        nets = [...positive, value]
      }
      const newNets = nets.filter(item => options.includes(item));
      const { designID, viewList, designId } = this.props;
      if (pcbId) {
        if (pcbId === designID && viewList && viewList.length > 1) {
          this.props.select({ nets: [...newNets] });
        }
        this.props.editSignal({ nets: [...newNets], pcbId, signalName: name, netType: 'positive' });
      } else {
        if (designId === designID && viewList && viewList.length > 1) {
          this.props.select({ nets: [...newNets] });
        }
        this.props.editSignal({ nets: [...newNets], pcbId: designId, signalName: name, netType: 'positive' });
      }
    } else {
      const { name, negative, pcbId } = record;
      const options = this.getNetsList(record);
      let nets = [];
      if (negative.includes(value)) {
        nets = negative.filter(item => item !== value);
      } else {
        nets = [...negative, value]
      }
      const newNets = nets.filter(item => options.includes(item));
      const { designID, viewList, designId } = this.props;
      if (pcbId) {
        if (pcbId === designID && viewList && viewList.length > 1) {
          this.props.select({ nets: [...newNets] });
        }
        this.props.editSignal({ nets: [...newNets], pcbId, signalName: name, netType: 'negative' });
      } else {
        if (designId === designID && viewList && viewList.length > 1) {
          this.props.select({ nets: [...newNets] });
        }
        this.props.editSignal({ nets: [...newNets], pcbId: designId, signalName: name, netType: 'negative' });
      }
    }
    form.resetFields();
  }

  relatedShowChange = (e) => {
    e.stopPropagation();
    const { relatedShow } = this.state;
    this.setState({
      relatedShow: !relatedShow
    })
  }

  dropdownRender = (form, record, type, clearInputValue) => {
    const { relatedNets, nets } = this.getNetsOptions(record, type);
    const { positive, negative } = record;
    let selectedNets = type === 'positive' ? positive : negative;
    const { relatedShow } = this.state;
    return (
      <div className='andes-select-nets-div'
        onMouseDown={(e) => {
          e.preventDefault()
          return false
        }}
      >
        <span className='andes-select-span'>Nets:</span>
        <ul className='andes-select-nets-ul-1'>
          {nets.map(item => <li
            className={selectedNets.includes(item) ? `andes-select-nets-li andes-nets-selected` : 'andes-select-nets-li'}
            key={item}
            onClick={(e) => { clearInputValue(); this.selectNets(e, record, item, type, form) }}
          >{item}
          </li>)}
        </ul>
        {relatedNets.length > 0 && <span
          className='andes-select-span'
          style={{ marginBottom: !relatedShow ? 8 : 0 }}
          onClick={(e) => this.relatedShowChange(e)}>
          {relatedShow ? <DownOutlined className="andes-nets-expand-icon" /> : <RightOutlined className="andes-nets-expand-icon" />}
          Connected Nets:
        </span>}
        {relatedShow && <ul className='andes-select-nets-ul-2'>
          {relatedNets.map(item => <li
            className={selectedNets.includes(item) ? `andes-select-nets-li andes-nets-selected` : 'andes-select-nets-li'}
            onClick={(e) => { clearInputValue(); this.selectNets(e, record, item, type, form) }}
            key={item}>{item}
          </li>)}
        </ul>}
      </div>
    );
  }

  render() {
    const { signals } = this.props;
    const { loading } = this.state;
    return (
      <Spin spinning={loading}>
        <span className="font-bold">Signal Nets</span>
        <PlusCircleOutlined className='signal-add-icon' onClick={this.addSignal} />
        <Table
          columns={columns}
          className="andes-signal-nets-table"
          dataSource={signals}
          rowKey={(record, index) => index}
          expandIconAsCell={false}
        />
      </Spin>
    );
  }
}

const mapState = (state) => {
  const { AndesReducer: { andes, project: { currentProjectDesigns, pcbComponentsNets, designID, viewList } } } = state;
  const andesInfo = andes.andesInfo;
  let signals = [], currentPCBs = [], designId = null;
  if (andesInfo) {
    signals = andesInfo.info.signals;
    currentPCBs = andesInfo.Interfaces && andesInfo.Interfaces.map(item => item.pcbId);
  }
  const PCBNames = currentProjectDesigns.map(item => item.name);
  if (currentProjectDesigns && currentProjectDesigns.length > 0) {
    designId = currentProjectDesigns[0].id;
  }
  return {
    signals,
    PCBNames,
    pcbComponentsNets,
    currentPCBs,
    designID,
    viewList,
    designId
  }
};

const mapDispatch = (dispatch) => ({
  addSignal() {
    dispatch(addSignal());
  },
  editSignalName(name, prevName) {
    dispatch(editSignalName(name, prevName));
  },
  editSignal({ nets, pcbId, signalName, netType }) {
    dispatch(editSignal({ nets, pcbId, signalName, netType }));
  },
  select(canvasObj) {
    dispatch(canvasSelect(canvasObj))
  },
  deleteSignal({ name }) {
    dispatch(deleteSignal({ name }))
  },
  updateAndesInterface(data) {
    dispatch(updateAndesInterface(data))
  },
  changeDisplaySelected(show) {
    dispatch(changeDisplaySelected(show))
  }
})

export default connect(mapState, mapDispatch)(SignalsNetsTable);
