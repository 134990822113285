import React, { PureComponent } from 'react'
import DCMenu from './DCMenu';
import { viaColumns } from '@/services/Result/Public/DCTable/tableData';
import DCTable from './resultContent';


function ViaResultContent() {
  return (
    <div>
      <DCMenu />
      <DCTable
        tableColumns={viaColumns}
        chartXType='I'
        chartYType='components'
        svgHeight='600'
        rowKey='components'
        chartVisible={false}
      />
    </div>
  )
}

export default ViaResultContent
