import { takeEvery, cancel, fork, put, select, call, delay } from "redux-saga/effects";
import {
  START_NEW_WORKFLOW,
  GET_WORKFLOW_MONITOR,
  CANCEL_WORKFLOW,
  GET_SIMULATION_LOG,
  GET_VERIFICATION_WORKFLOW
} from "./actionTypes";
import {
  updateSimulationInfo,
  getWorkflowMonitor,
  initSimulationInfo,
  getVerificationLog
} from "./action";
import * as taskStatus from '@/constants/workflowStatus';
import { checkVerificationStatus, getMonitor, getVerificationWorkFlow, workflowWaitingIndex } from "../../../../services/workflow/workflow";
import monitorLog from "../../../../services/helper/monitorLog";
import { cancelVerificationWorkflow, getWorkFlow } from "../../../../services/api/v2/workflowCtrl";
import { VERIFY_NEVER } from "../../../../constants/verificationStatus";
import { getSimulationLog } from "../../../../services/Himalayas";
import { HIMALAYAS } from "../../../../constants/pageType";
import dayjs from 'dayjs';
import { fakeProgress } from "../../../../services/helper/dataProcess";

let workflowTask = null;
export function* startNewWorkflow(action) {
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  //TODO: update tree item verification status
  workflowTask = yield fork(verificationWorkFlow, { ...action });
}

function* verificationWorkFlow(action) {
  const { verificationId, interfaceStatus } = action;
  if (!interfaceStatus || !interfaceStatus.length) {
    return
  }
  let { id, status, progress } = interfaceStatus[0] || {};

  yield put(updateSimulationInfo(verificationId, {
    startMsg: "==> Start simulating...",
    progress
  }));

  try {
    const stopWaiting = yield call(getWaitingIndex, { verificationId, id });
    if (stopWaiting) {
      yield put(initSimulationInfo(verificationId));
      return;
    }
    if (progress === 0) {
      progress += 2;
    }
    yield put(updateSimulationInfo(verificationId, {
      startMsg: "==> Simulation running...\n==> Data processing...",
      progress
    }));

    // simulation running
    const stopWorkflowPolling = yield call(getSimulationRunning, {
      status,
      verificationId,
      id,
      progress
    })

    if (stopWorkflowPolling) {
      yield put(initSimulationInfo(verificationId));
      return;
    }

    yield put(updateSimulationInfo(verificationId, {
      progress: status === taskStatus.SUCCEED ? 100 : -1
    }));

    yield put(getWorkflowMonitor(id, verificationId));
    // simulation completed
    yield put(updateSimulationInfo(verificationId, {
      progress: -1,
      startMsg: null,
      endMsg: null
    }));
  } catch (error) {
    console.error(error);
  }
}

function* getWaitingIndex(action) {
  const { verificationId, id } = action;//id -> workflowId
  let waitingIndex = -1, time;
  waitingIndex = yield call(workflowWaitingIndex, id);

  while (waitingIndex >= 0) { // wait
    const stopWorkflowPolling = yield call(doesStopWorkflowPolling, { verificationId })
    if (stopWorkflowPolling) {
      return true;
    }
    time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    yield put(updateSimulationInfo(verificationId, {
      waitingIndex,
      waitingTime
    }));

    yield delay(3000);
    waitingIndex = yield call(workflowWaitingIndex, id);
  }

  while (waitingIndex === -2) { //simulation cancel
    yield put(updateSimulationInfo(verificationId, {
      waitingIndex: -1,
      waitingTime: null,
      startMsg: null,
      progress: -1,
      endMsg: null
    }))
    yield delay(3000);
    yield put(getWorkflowMonitor(id, verificationId));
    return;
  }

  while (waitingIndex === -3) { //simulation completed
    yield put(updateSimulationInfo(verificationId, {
      waitingIndex: -1,
      waitingTime: null,
      startMsg: null,
      progress: -1,
      endMsg: null
    }))
    yield delay(3000);
    yield put(getWorkflowMonitor(id, verificationId));
    return;
  }

  yield put(updateSimulationInfo(verificationId, {
    waitingIndex: -1,
    waitingTime: null
  }));
}

function* getSimulationRunning(action) {
  let { status, verificationId, id, progress } = action; //id -> workflowId
  let indexNum = 0;
  while (status === taskStatus.RUNNING) {
    const stopWorkflowPolling = yield call(doesStopWorkflowPolling, { verificationId });
    if (stopWorkflowPolling) {
      return true;
    }

    yield put(getWorkflowMonitor(id, verificationId));
    yield delay(4000);
    let _progress = progress;
    try {
      const { data: { data } } = yield call(getWorkFlow, id);
      if (!data) {
        status = taskStatus.FAILED;
      } else {
        status = data.status;
        _progress = data.progress;
      }
    } catch (error) {
      console.error(error)
    }

    indexNum += 1;
    progress = fakeProgress(progress, indexNum, _progress);
    yield put(updateSimulationInfo(verificationId, { progress }));
  }
}

function* _getWorkflowMonitor(action) {
  const { workflowId, verificationId } = action;
  try {
    const log = yield call(getMonitor, workflowId);
    if (log.length > 0) {
      yield put(updateSimulationInfo(verificationId, { monitor: monitorLog(log) }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* _cancelWorkflow(action) {
  const { verificationId } = action;
  try {
    yield call(cancelVerificationWorkflow, verificationId);
    yield put(updateSimulationInfo(verificationId, {
      startMsg: null,
      endMsg: "==> Simulation cancelled!",
      progress: -1
    }));
    //delay 5s, clear cancel message
    yield delay(5000);
    yield put(updateSimulationInfo(verificationId, { endMsg: null }));
  } catch (error) {
    console.error(error);
  }
}

function* _getSimulationLog(action) {
  const { verificationId } = action;
  let _log = "", isGetLog = false;
  try {
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      //judge verification status
      if (promise.status !== VERIFY_NEVER) {
        isGetLog = true;
      }
    }

    if (!isGetLog) {
      return;
    }

    let log = yield call(getSimulationLog, verificationId)
    if (log) {
      _log = log ? log : "";
    }
  } catch (error) {
    console.error(error)
  }

  yield put(updateSimulationInfo(verificationId, {
    log: _log,
    monitor: null
  }))
}

function* doesStopWorkflowPolling({ verificationId }) {
  const { LoginReducer: { pageType } } = yield select();
  const { MonitorInfoReducer: { monitorScreenInfo: { currentVerificationId } } } = yield select();
  // The verification ID of the currently open page is not the ID of workflow polling
  // Switch pages
  if (pageType !== HIMALAYAS || verificationId !== currentVerificationId) {
    return true;
  }
  return false;
}

function* _getVerificationWorkflow(action) {
  const { verificationId, channelId, workType } = action;
  try {
    const promise = yield call(checkVerificationStatus, verificationId);
    const verifyStatus = promise ? promise.status : null;

    //if never run simulation, return
    if (verifyStatus && verifyStatus === VERIFY_NEVER) {
      return;
    }

    const interfaceStatus = yield call(getVerificationWorkFlow, verificationId);
    if (interfaceStatus && interfaceStatus.length > 0) {
      yield call(startNewWorkflow, {
        workType,
        verificationId,
        channelId,
        interfaceStatus: interfaceStatus
      });
      return;
    } else {
      //clear
      yield put(initSimulationInfo(verificationId));
      //get monitor / log
      const { HimalayasReducer } = yield select();
      let simulation = HimalayasReducer.simulation;

      if (simulation[verificationId] && simulation[verificationId].monitor && simulation[verificationId].monitor.length > 0) {
        let workflowId = simulation[verificationId].monitor[0].workflowId;
        const log = workflowId ? yield call(_getWorkflowMonitor, { workflowId, verificationId, workType }) : null;
        if (!log || !log.length) {
          // get log
          yield put(getVerificationLog(verificationId));
        }
      } else {
        // GET log
        yield put(getVerificationLog(verificationId));
      }
    }
  } catch (error) {
    console.error(error);
    yield call(initSimulationInfo, verificationId);
    return;
  }
}

export default function* HimalayasSimulationSaga() {
  yield takeEvery(START_NEW_WORKFLOW, startNewWorkflow);
  yield takeEvery(GET_WORKFLOW_MONITOR, _getWorkflowMonitor);
  yield takeEvery(CANCEL_WORKFLOW, _cancelWorkflow);
  yield takeEvery(GET_SIMULATION_LOG, _getSimulationLog);
  yield takeEvery(GET_VERIFICATION_WORKFLOW, _getVerificationWorkflow);
}