import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '../../../components/Panel';
import { createPortal } from 'react-dom';
import { Input, Select, message } from 'antd';
import { numberCheck } from '@/services/PDN/library/libraryData';
import NP from 'number-precision';
import './pdnPanel.css';

const { Option } = Select;
const VRMColumns = [{
  title: 'Resistance',
  dataIndex: 'R',
  width: '50%',
}, {
  title: 'Inductance',
  dataIndex: 'L',
  width: '50%',
}];
const RUnit = ['uΩ', 'mΩ', 'Ω'];
const LUnit = ['pH', 'nH', 'uH'];
class VRMPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      VRMDataList: { key: 'create', R: '', L: '' },
      RUnit: 'Ω',
      LUnit: 'nH',
      name: null,
      RValue: null,
      LValue: null
    }

    this.dialogRoot = document.getElementById('root');

    VRMColumns[0].title = () => {
      return <span className='unit-span'>Resistance
        <Select dropdownStyle={{ zIndex: 10000 }} className='unit-select' defaultValue={this.state.RUnit} onChange={(e) => this.changeRUnit(e)}>
          {RUnit.map(item =>
            <Option value={item} key={item}>{item}</Option>
          )}
        </Select>
      </span>
    }
    VRMColumns[1].title = () => {
      return <span className='unit-span'>Inductance
        <Select dropdownStyle={{ zIndex: 10000 }} className='unit-select' defaultValue={this.state.LUnit} onChange={(e) => this.changeLUnit(e)}>
          {LUnit.map(item =>
            <Option value={item} key={item}>{item}</Option>
          )}
        </Select>
      </span>
    }

    VRMColumns[0].onCell = (record) => ({
      record,
      edit: true,
      dataIndex: 'R',
      handleSave: this.saveRValue,
      tooltip: true,
    })

    VRMColumns[1].onCell = (record) => ({
      record,
      edit: true,
      dataIndex: 'L',
      handleSave: this.saveIValue,
      tooltip: true,
    })
  }

  selectRAfter = () => {
    return (
      <Select dropdownStyle={{ zIndex: 10000 }} className='unit-select' value={this.state.RUnit} onChange={(e) => this.changeRUnit(e)}>
        {RUnit.map(item =>
          <Option value={item} key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  selectLAfter = () => {
    return (
      <Select dropdownStyle={{ zIndex: 10000 }} className='unit-select' value={this.state.LUnit} onChange={(e) => this.changeLUnit(e)}>
        {LUnit.map(item =>
          <Option value={item} key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  changeRUnit = (key) => {
    this.setState({
      RUnit: key
    })
  }

  changeLUnit = (key) => {
    this.setState({
      LUnit: key
    })
  }

  saveRValue = (e) => {
    this.props.changeErrorMsg(null);
    this.setState({
      RValue: e.target.value
    })
  }

  saveIValue = (e) => {
    this.props.changeErrorMsg(null);
    this.setState({
      LValue: e.target.value
    })
  }

  saveValue = (e, type) => {
    let value = e.target.value;
    //Check the value format
    let error = numberCheck(value);

    if (error) {

      if (type === 'R') {
        this.props.changeErrorMsg(`Resistance ${error}`);
      } else if (type === 'L') {
        this.props.changeErrorMsg(`Inductance ${error}`);
      }
    }
  }

  componentDidMount() {
    const { libraryData, defaultVRMName } = this.props;
    if (libraryData && Object.keys(libraryData).length > 0) {
      this.getLibraryDataInfo()
    } else {
      this.setState({
        name: defaultVRMName
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { libraryData, defaultVRMName } = this.props;
    if ((libraryData && Object.keys(libraryData).length > 0 && prevProps.libraryData && Object.keys(prevProps.libraryData).length > 0 && libraryData.id !== prevProps.libraryData.id)
      || (libraryData && Object.keys(libraryData).length > 0 && (!prevProps.libraryData || Object.keys(prevProps.libraryData).length === 0))) {
      this.getLibraryDataInfo()
    } else if (prevProps.libraryData && (!libraryData || Object.keys(libraryData).length === 0)) {
      this.setState({
        name: defaultVRMName,
        VRMDataList: { key: 'create', R: '', L: '' },
        RUnit: 'Ω',
        LUnit: 'nH',
        RValue: null,
        LValue: null
      })
    }
  }

  getLibraryDataInfo = () => {
    const { libraryData } = this.props;
    let data = {
      R: libraryData.data.R,
      L: libraryData.data.L,
      key: libraryData.id
    }
    let rUnit = libraryData.RUnit;
    if (libraryData.RUnit === 'KΩ') {
      rUnit = 'Ω';
      data.R = NP.strip(NP.times(libraryData.data.R, 1000));
      /* data.R = libraryData.data.R * 1000; */
    }

    if (libraryData.RUnit === 'MΩ') {
      rUnit = 'Ω';
      data.R = NP.strip(NP.times(libraryData.data.R, 1e6));
      /* data.R = libraryData.data.R * 1e6; */
    }

    this.setState({
      VRMDataList: data,
      name: libraryData.name,
      RUnit: rUnit,
      LUnit: libraryData.LUnit,
      RValue: libraryData.data.R,
      LValue: libraryData.data.L
    })
  }

  changeName = (e) => {
    if (e.target.value) {
      this.setState({
        name: e.target.value
      })
    } else {
      this.setState({
        name: null
      })
    }
  }

  saveName = (e) => {
    const { VRMNames } = this.props;
    if (e.target.value) {
      const { VRMDataList } = this.state;
      const index = VRMNames.findIndex(item => item.name === e.target.value && item.id !== VRMDataList.key);
      if (typeof (index) !== 'boolean' && index > -1) {
        e.target.focus();
        this.props.changeErrorMsg('VRM model name cannot be repeated.');
        this.setState({
          name: null
        });
      } else {
        //Whether the file name is legal.
        const errorWords = e.target.value.match(/[^0-9a-zA-Z_-]+/g);
        if (errorWords && errorWords.length > 0) {
          this.props.changeErrorMsg('VRM name may only contain the following characters: numbers, letters, underscores, minus.');
        } else {
          this.setState({
            name: e.target.value
          });
          this.props.changeErrorMsg('');
        }
      }
    } else {
      e.target.focus();
      this.props.changeErrorMsg('VRM model name not set.');
      this.setState({
        name: null
      })
    }
  }

  closeModal = () => {
    let { VRMDataList, name, RUnit, LUnit, RValue, LValue } = this.state;
    let data = { ...VRMDataList };
    data.R = RValue;
    data.L = LValue;
    //Whether the file name is legal.
    const errorWords = name.match(/[^0-9a-zA-Z_-]+/g);
    if (errorWords && errorWords.length > 0) {
      this.props.changeErrorMsg('VRM name may only contain the following characters: numbers, letters, underscores, minus.');
      return;
    }
    this.props.changeErrorMsg('')
    if (VRMDataList.key === 'create' && !RValue && !LValue) {
      this.props.closeModal('VRM', { name, VRMData: { ...data }, RUnit, LUnit });
    } else {

      let error = numberCheck(RValue);

      if (error) {
        this.props.changeErrorMsg(`Resistance ${error}`);
        return;
      }

      //Check the Inductance value format
      error = numberCheck(LValue);

      if (error) {
        this.props.changeErrorMsg(`Inductance ${error}`);
        return;
      }
      this.props.closeModal('VRM', { name, VRMData: { ...data }, RUnit, LUnit });
    }
  }

  render() {
    const { title, errorMsg } = this.props;
    const { name, RValue, LValue } = this.state;
    const content = (
      <Panel
        className='VRM-panel'
        position='panel-center-left'
        title={title}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        width={400}
        draggable
        minWidth={400}
        minHeight={200}
        overflow='hidden'
      >
        <div className='vrm-content-box'>
          <Input className='model-input' placeholder='Name' value={name} onChange={(e) => this.changeName(e)} onBlur={(e) => this.saveName(e)} />
          <Input className='rlc-input' value={RValue} addonAfter={this.selectRAfter()} placeholder='Resistance' onChange={(e) => this.saveRValue(e)} onBlur={(e) => this.saveValue(e, 'R')} />
          <Input className='rlc-input' value={LValue} addonAfter={this.selectLAfter()} placeholder='Inductance' onChange={(e) => this.saveIValue(e)} onBlur={(e) => this.saveValue(e, 'L')} />
          {errorMsg && <span className='model-name-error-msg'>{errorMsg}</span>}
        </div>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { PDNReducer: { project } } = state;
  return {
    VRMNames: project.VRMNames
  }
}


export default connect(mapState, null)(VRMPanel);