import { getTextWidth } from "../../../helper/getTextWidth";

// Init Signal Ports
function initSignalPorts(ports = [], channels, adsConfigSignals) {
  // [{signal, txPort: [{componentP, pinP, componentN, pinN}], rxPort: [{componentP, pinP, componentN, pinN}]}]
  const portData = [];
  const diffPorts = ports.filter(port => port.portType === 'Diff');
  const groupMap = new Map();
  const compMap = adsConfigSignals.length > 0 ? getAdsConfigComMap(adsConfigSignals) : getDefaultComMap(channels);
  for (const port of diffPorts) {
    const signal = port.signal;
    const oldValue = groupMap.get(signal) || {};
    const txPort = oldValue.txPort || [],
      rxPort = oldValue.rxPort || [];
    const signalConfig = compMap.get(signal);
    const { controller } = signalConfig;
    if (controller === port.comp) {
      txPort.push({
        componentP: port.comp,
        pinP: port.positivePin,
        componentN: port.comp,
        pinN: port.negativePin
      })
    } else {
      rxPort.push({
        componentP: port.comp,
        pinP: port.positivePin,
        componentN: port.comp,
        pinN: port.negativePin
      })
    }
    groupMap.set(signal, {
      txPort,
      rxPort
    })
  }

  for (const [key, value] of groupMap) {
    portData.push({
      signal: key,
      txPort: value.txPort,
      rxPort: value.rxPort
    })
  }

  return portData;
}

function getAdsConfigComMap(adsConfigSignals) {
  const compMap = new Map();
  for (const signalConfig of adsConfigSignals) {
    const { signalName, rxAmiModel = {}, txAmiModel = {} } = signalConfig;
    compMap.set(signalName, { controller: rxAmiModel.component, device: txAmiModel.component });
  }

  return compMap;
}

function getDefaultComMap(channels) {
  const compMap = new Map();
  for (const channel of channels) {
    const { signal, controller, device } = channel;
    compMap.set(signal, { controller: controller.component, device: device.component });
  }

  return compMap;
}

// Current signal RxPort -> Target Signal TxPort
function getResultObject(list = [], signalPorts = []) {
  const _list = JSON.parse(JSON.stringify(list));

  for (const item of _list) {
    const { children, rowName: currSignal } = item;
    const signalPort = signalPorts.find(port => port.signal === currSignal);
    const _children = children.filter(child => {
      if (signalPort.rxPort.find(it => it.componentP === child.comp)) {
        const targetSignalPort = signalPorts.find(port => port.signal === child._signal);
        if (targetSignalPort.txPort.find(it => it.componentP === child._comp)) {
          return true;
        }
      }
      return false;
    })
    item.children = _children;
  }

  return _list;
}

function getDisplaySignalPorts(signalPorts, components) {
  const ICComp = components.filter(comp => comp.type === 'IC').map(comp => comp.name);
  const portList = [];
  let txWidth = 0, rxWidth = 0;
  for (const signalPort of signalPorts) {
    const { signal, txPort, rxPort } = signalPort;
    const portNum = txPort.length;
    const ports = [];
    for (const _index in (new Array(portNum).fill(0).map((_, i) => i))) {
      const _txPort = txPort[_index] || {}, _rxPort = rxPort[_index] || {};
      const txPortText = `${_txPort.componentP}<+${_txPort.pinP}, -${_txPort.pinN}>`,
        rxPortText = `${_rxPort.componentP}<+${_rxPort.pinP}, -${_rxPort.pinN}>`;
      txWidth = Math.max(getTextWidth(txPortText, 14), txWidth);
      rxWidth = Math.max(getTextWidth(rxPortText, 14), rxWidth);
      // Whether the component of txPort is an IC type
      let isTxIC = false;
      if (ICComp.includes(_txPort.componentP)) {
        isTxIC = true;
      }
      ports.push({
        leftPortText: isTxIC ? txPortText : rxPortText,
        leftType: isTxIC ? "TX" : "RX",
        rightPortText: isTxIC ? rxPortText : txPortText,
        rightType: isTxIC ? "RX" : "TX"
      })
    }
    portList.push({ signal, ports })
  }
  return { portList, txWidth, rxWidth };
}

export {
  initSignalPorts,
  getResultObject,
  getDisplaySignalPorts
}