import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { InfoCircleOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import { Progress, Row, Col, Tooltip } from 'antd';
import {
  cancelWorkflow, changeUploadMes, debugVerify, updateProgress, getCurrentPDNLog
} from '../../store/simulation/action';
import ErrorCheck from '../../errorCheck';
import './style.css';

class SimulationMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null,
      topWidth: 666,
      lockScroll: true
    }
    this.uploadDebugRef = createRef();
    this.dialogRoot = document.getElementById('root');
    this.lockRef = createRef();
  }


  scrollToBottom = () => {
    if (this.lockRef) {
      //this.scrollRef.scrollIntoView({ behavior: "auto" });
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }


  componentDidMount() {
    this.screenChange()
    const { tabVisible } = this.props;
    //monitor scroll always bottom
    if (tabVisible && this.scrollRef) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { tabVisible, log } = this.props;
    const { topWidth, lockScroll } = this.state;
    const { log: prevlog } = prevProps;
    //If scroll stays at the bottom , monitor scroll always bottom
    if (tabVisible && this.scrollRef && !lockScroll) {
      this.scrollToBottom();
    }
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
    if (log !== prevlog) {
      this.hasLockScroll();
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  UploadProgress = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const { currentVerificationId } = this.props;
      const percentCompleted = Math.round((loaded * 100) / total);
      this.props.updateProgress({ verificationId: currentVerificationId, progress: percentCompleted })
    }
  }

  cancelProgress = (e) => {
    e.stopPropagation();
    //cancel simulation
    const { singleVerifyInfo } = this.props;
    if (singleVerifyInfo && singleVerifyInfo.workflowId && singleVerifyInfo.verificationId) {
      this.props.cancelWorkflow(singleVerifyInfo.verificationId);
    }
  }

  hasLockScroll = () => {
    if (this.lockRef) {
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5) { this.setState({ lockScroll: false }); }
      else { this.setState({ lockScroll: true }); }
    }
  }

  render() {
    const {
      currentVerificationId, singleVerifyInfo, monitor, progress, projectName, verificationName,
      uploadDebugMes, verifySetupMsg, errorCheck, queueIndex, waitingTime, log, stackupError,
      vrmError, decapError, startMsg, endMsg
    } = this.props;
    const { topWidth, lockScroll } = this.state;
    const { scrollHeight, clientHeight } = this.lockRef;
    let error = null;
    if (errorCheck) {
      error = errorCheck;
    }

    let stackupErrorExist = false;
    if (verificationName && stackupError && stackupError.verificationId === currentVerificationId) {
      stackupErrorExist = true;
    }

    let isCurrentVerification = false;
    if (verificationName && singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId) {
      isCurrentVerification = true;
    }

    let logView = false;
    if (verificationName && !uploadDebugMes && !verifySetupMsg && projectName && !error && !stackupErrorExist && queueIndex < 0 && (!singleVerifyInfo || (singleVerifyInfo && currentVerificationId !== singleVerifyInfo.verificationId))) {
      logView = true;
    }
    return (
      <Fragment>
        <div className='pdn-simulation-title'>
          <div className='pdn-simulation-holygrail'>
            <div>
              <Tooltip
                title={topWidth <= 680 ? <Fragment>{projectName &&
                  <span className='pdn-span-msg-tooltip'><span className='font-bold'>Project</span> {projectName}</span>}
                  {verificationName &&
                    <span className='pdn-span-msg-tooltip'><span className='font-bold'>PDN</span> {verificationName} </span>}
                </Fragment> : null}
                overlayClassName='icon-tooltip'
                placement="topLeft"
              >
                {(topWidth > 502 || (queueIndex < 0 && progress < 0 && topWidth > 280)) ? <div className='pdn-simulation-msg' style={{ width: '100%' }}>
                  {projectName && <div className='pdn-simulation-span pdn-simulation-project'>
                    <span className='span-msg-project font-bold'>Project </span>
                    <span className='span-msg'>{projectName}</span>
                  </div>}
                  {verificationName && <div className='pdn-simulation-span'>
                    <span className='span-msg-project font-bold'>PDN </span>
                    <span className='span-msg'>{verificationName} </span>
                  </div>}
                </div> : <div className='span-msg-icon-div'><InfoCircleOutlined className='span-msg-icon' /></div>}
              </Tooltip>
            </div>
            {progress > -1 ?
              <div className='pdn-simulation-middle'>
                <div className='pdn-simulation-progress'>
                  <Progress
                    size={{ height: 14 }}
                    strokeColor={'#1890ff'}
                    percent={progress}
                    className="simulation-progress-bar"
                  />
                </div>
              </div> : (queueIndex >= 0 &&
                <div className='pdn-simulation-middle'>
                  {topWidth > 717 && <span className='simulation-waiting-title'> Simulation waiting: {queueIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
                  {topWidth <= 717 && <span className='simulation-waiting-title'>Waiting: {queueIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
                </div>)}
          </div>
          <div className='pdn-simulation-btn'
          >
            {(progress > -1 || queueIndex >= 0) &&
              <Fragment>
                <div className='pdn-cancel-button'>
                  <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
                    <span className='iconfont icon-cancel9' onClick={(e) => this.cancelProgress(e)}></span>
                  </Tooltip>
                </div>
              </Fragment>}
          </div>
        </div>
        <div className='my-pdn-monitor' onScroll={() => this.hasLockScroll()} ref={c => { this.lockRef = c; }}>
          {stackupError && stackupError.verificationId === currentVerificationId ?
            <Fragment>
              <div className='pdn-stackup-error-dialog font-bold'>Stackup Error:</div>
              <ErrorCheck
                errorCheck={stackupError.error}
              /> </Fragment> : null}
          {vrmError && vrmError.verificationId === currentVerificationId ?
            <Fragment>
              <div className='pdn-stackup-error-dialog font-bold'>VRM Model Error:</div>
              <ErrorCheck
                errorCheck={vrmError.errorCheck}
              /> </Fragment> : null}
          {decapError && decapError.verificationId === currentVerificationId ?
            <Fragment>
              <div className='pdn-stackup-error-dialog font-bold'>Decap Model Error:</div>
              <ErrorCheck
                errorCheck={decapError.errorCheck}
              /> </Fragment> : null}
          <ErrorCheck
            errorCheck={error}
          />
          <pre style={{ margin: 0, overflow: 'unset', padding: '0 20px', paddingTop: '14px' }}>
            {uploadDebugMes && verificationName ? <span className='debug-mes-span'>{uploadDebugMes} </span> : null}
            {startMsg && isCurrentVerification ? <span className='debug-mes-span'>{startMsg}</span> : null}
            {queueIndex === 0 ? <span className='debug-mes-span'>==&gt; The current task is on the top of the waiting queue and ready to run.</span> : null}
            {queueIndex === 1 ? <span className='debug-mes-span'>==&gt; The current task is in the waiting queue and there is one task ahead.</span> : null}
            {queueIndex > 1 ? <span className='debug-mes-span'>==&gt; The current task is in the waiting queue and there are {queueIndex} tasks ahead.</span> : null}
            {!error && !stackupErrorExist && queueIndex < 0 && (isCurrentVerification || monitor.length > 0) ? <ul className='pdn-monitor-ul'>
              {monitor.length > 0 ? monitor.map(item =>
                <li key={item.id} style={{ listStyle: 'none', width: '100%' }}>
                  <p style={{ margin: 0 }}>{item.log}</p>
                </li>) : null}
            </ul> : (logView ? log : null)}
            {verificationName && endMsg ? <span className='debug-mes-span'>{endMsg}</span> : null}
          </pre>
          {(singleVerifyInfo && currentVerificationId === singleVerifyInfo.verificationId) || monitor.length > 0 ? <div ref={(el) => { this.scrollRef = el; }}></div> : null}
        </div>
        {lockScroll && scrollHeight !== clientHeight ? <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'><VerticalLeftOutlined className="pdn-monitor-backbottom" onClick={this.scrollToBottom} /></Tooltip> : null}
      </Fragment >
    );
  }
}

const mapState = (state) => {
  const { project: { currentProjectId, currentProjectPackages }, simulationReducer, pdn } = state.PDNReducer;
  const { TabMonitorReducer: { projectName, verificationName, currentVerificationId, tabVisible }, LoginReducer: { systemVersion } } = state;
  const {
    singleProgress, singleMonitor, singleVerifyInfo,
    uploadDebugMes, endMsg,
    errorCheck, waitingQueue, log,
    stackupError, vrmError, decapError,
    startMsg
  } = simulationReducer;
  const pdnContent = pdn.pdnInfo ? pdn.pdnInfo.pdnContent : null;
  let monitor = [], progress = -1, queueIndex = -1, waitingTime = '';
  const i = singleMonitor && singleMonitor.length > 0 && singleMonitor.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (i) !== 'boolean' && i > -1) {
    monitor = singleMonitor[i].monitor;
  }
  const n = singleProgress && singleProgress.length > 0 && singleProgress.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (n) !== 'boolean' && n > -1) {
    progress = singleProgress[n].progress;
  }

  let q = waitingQueue && waitingQueue.length > 0 && waitingQueue.findIndex(item => verificationName && item.verificationId === currentVerificationId);
  if (typeof (q) !== 'boolean' && q > -1) {
    queueIndex = waitingQueue[q].queueIndex;
    waitingTime = waitingQueue[q].waitingTime;
  }
  return {
    projectName,
    verificationName,
    currentVerificationId,
    currentProjectId,
    monitor,
    progress,
    singleVerifyInfo,
    uploadDebugMes,
    endMsg,
    tabVisible,
    pdnContent,
    errorCheck,
    currentProjectPackages,
    queueIndex,
    log,
    waitingTime,
    stackupError,
    vrmError,
    decapError,
    systemVersion,
    startMsg
  }
}

const mapDispatch = (dispatch) => ({
  cancelWorkflow(verificationId) {
    dispatch(cancelWorkflow(verificationId));
  },
  changeUploadMes(mes) {
    dispatch(changeUploadMes(mes));
  },
  debugVerify(status, verificationId) {
    dispatch(debugVerify(status, verificationId))
  },
  updateProgress({ verificationId, progress }) {
    dispatch(updateProgress({ verificationId, progress }));
  },
  getCurrentPDNLog(verificationId) {
    dispatch(getCurrentPDNLog(verificationId));
  }
})

export default connect(mapState, mapDispatch)(SimulationMonitor);