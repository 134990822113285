import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { getDelayData, getAndesResultHistoryList, getVerificationJson } from '@/services/Andes/results/result';
import makeCancelable from '@/services/api/makeCancelable';
import HistoryData from '@/services/Andes/results/historyJsonData';
import delayTable from '../../../services/Result/Andes/Delay';

let historyData = null;
const { Option } = Select;
const defaultStatus = {
  delayDate: null,
  topUnit: 'ps',
  bottomUnit: 'ps',
  topDelayData: null,
  bottomDelayData: null,
  topValue: { name: 'Current Result', time: 'current' },
  bottomValue: null
}
class Delay extends PureComponent {
  historyList = [];
  designId = null;
  constructor(props) {
    super(props);
    this.state = { ...defaultStatus };
  }

  componentDidMount() {
    this.getResult();
  }

  componentDidUpdate(prevProps) {
    const { verificationId, saveHistoryCount } = this.props;
    if (verificationId !== prevProps.verificationId ||
      saveHistoryCount !== prevProps.saveHistoryCount) {
      this.getResult();
    }
  }

  getResult = () => {
    this.setState({
      ...defaultStatus
    }, async () => {
      try {
        const { verificationId, currentProjectId } = this.props;
        const historyList = await getAndesResultHistoryList(verificationId);
        this.historyList = historyList;
        const currentInterfaces = await getVerificationJson(verificationId, currentProjectId);
        if (!currentInterfaces || !currentInterfaces.Interfaces || !currentInterfaces.Interfaces.length) {
          throw new Error('Can\'t get Interfaces');
        }
        const designId = currentInterfaces.Interfaces[0].pcbId;
        this.designId = designId;
        this.getData({ time: 'current', name: 'Current Result', table: 'top' });
        if (historyList && historyList.length > 0 && historyList[0].verificationId === verificationId) {
          historyData = new HistoryData(verificationId);
          historyData.getContent({ projectId: currentProjectId, verificationId, time: historyList[0].time }).then(res => {
            if (res && res.length) {
              this.getData({ time: historyList[0].time, name: historyList[0].name, designId: res[0].pcbId, table: 'bottom' });
            }
          })
        }
      } catch (error) {
        this.setState({
          ...defaultStatus
        })
      }
    })
  }

  getData = ({ designId, time, name, table }) => {
    // table - top/bottom
    const { verificationId, currentProjectId } = this.props;
    const promise = getDelayData({ verificationId, projectId: currentProjectId, designId: designId || this.designId, time: time });
    this.cancelable = makeCancelable(promise);
    this.cancelable.promise.then(res => {
      const { Data, unit } = res;
      this.setState({
        [`${table}DelayData`]: Data,
        [`${table}Unit`]: unit,
        [`${table}Value`]: { name, time },
      })
    })
  }

  changeSelect = (value, table) => {
    if (!this.designId || !this.historyList) return;
    const { verificationId, currentProjectId } = this.props;
    if (!this.designId) return;
    if (value === 'current') {
      this.getData({ time: value, name: 'Current Result', table });
    } else {
      let index = this.historyList.findIndex(item => item.time === value);
      historyData = new HistoryData(verificationId);
      historyData.getContent({ projectId: currentProjectId, verificationId, time: value }).then(res => {
        if (res && res.length) {
          this.getData({ time: value, name: this.historyList[index].name, designId: res[0].pcbId, table });
        }
      })
    }
  }

  render() {

    const { topDelayData, bottomDelayData, topValue, bottomValue } = this.state;
    let selectList = [{ name: 'Current Result', time: 'current' }, ...this.historyList];
    return (
      <Fragment>
        <div className='andes-delay-table'>
          <Select value={topValue && topValue.name} onChange={(value) => this.changeSelect(value, 'top')} className='andes-result-delay-select' popupClassName='andes-delay-result-select-dropdown'>
            {selectList.map(item =>
              topValue && topValue.time !== item.time &&
              <Option key={item.time} title={item.name} > {item.name}</Option>
            )}
          </Select>
          {delayTable(topDelayData)}
        </div>
        {this.historyList && this.historyList.length ?
          (<div className='andes-delay-table'>
            <Select value={bottomValue && bottomValue.name} onChange={(value) => this.changeSelect(value, 'bottom')} className='andes-result-delay-select' popupClassName='andes-delay-result-select-dropdown'>
              {selectList.map(item =>
                bottomValue && bottomValue.time !== item.time &&
                <Option key={item.time} title={item.name} > {item.name}</Option>
              )}
            </Select>
            {delayTable(bottomDelayData)}
          </div>) : null}
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { project, resultReducer: { currentResultKey } } = state.AndesReducer;
  const { currentProjectId, verificationId } = project;
  return {
    verificationId,
    currentProjectId,
    currentResultKey,
  }
}

export default connect(mapState, null)(Delay);
