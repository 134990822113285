import {
  CHANGE_RESULT_KEY,
  GET_RESULT_LIST,
} from './actionTypes';

export const changeResultKey = (key) => ({
  type: CHANGE_RESULT_KEY,
  key
});

export const getResultList = ({ currentResult }) => ({
  type: GET_RESULT_LIST,
  currentResult,
});
