import { HISTORY, IMPORT, OPTIMIZATION, SWEEPMODEL } from '../../../components/Sparameter/resultList';
import defaultColor, { defaultColor_dark, defaultColor_light } from '../../../constants/defaultColors';
import { VERIFY_FAILED, VERIFY_RUNNING, WAITING } from '../../../constants/verificationStatus';
import ParameterData from '../../Result/Public/sparameter/parameterData';
import {
  getImpedanceResultFile, getImpedanceNpi, getImpedanceCurves,
  getImpedanceHistoryCurves, getImpedanceHistoryNpi, getImpedanceHistoryFile,
  getImpResultImportNpi, getImpResultImportCurves,
  getImpedanceOptCurves,
  getImpedanceSweepCurves
} from './impedanceCtrl';
import { npiFileParseCascade } from './impedanceHelper';

class ImpedanceParameterData extends ParameterData {
  constructor(props) {
    super(props)
    switch (props.type) {
      case HISTORY:
        this.initGetNpiFileFn(getImpedanceHistoryNpi);
        this.initGetCurve(getImpedanceHistoryCurves);
        break;
      case IMPORT:
        this.initGetNpiFileFn(getImpResultImportNpi);
        this.initGetCurve(getImpResultImportCurves);
        break;
      default:
        this.initGetNpiFileFn(getImpedanceNpi);
        this.initGetCurve(getImpedanceCurves);
        this.initGetOptCurve(getImpedanceOptCurves)
        this.initGetSweepCurve(getImpedanceSweepCurves)
        break;
    }
    this.initParseNpiFile(npiFileParseCascade);
    this.id = props.verificationId;
    this.name = props.verificationId;
    this.type = props.type;
    this.targets = props.targets;
    this.status = props.status;
    this.portList = props.portList || [];
    this.fileName = '';
    this.colorType = props.colorType || null;
  }

  getCurve = async (col, row, value, curveType, wordIndex) => {
    let _row = row, _fileName = this.fileName;
    const currentPort = this.ports.find(item => (item.index - 1) === Number(row))
    if (currentPort && currentPort.portType === 'target') {
      const [targetPower, index] = currentPort.fileName.split('@');
      const target = this.targets.find(item => item.power === targetPower);
      if (target && target.axis.length) {
        let curve = target.axis[index].impedance;
        const freq = target.axis[index].frequency;
        let min = curve[0], minIndex = 0, max = curve[freq.length - 1], maxIndex = freq.length - 1, _curve = JSON.parse(JSON.stringify(curve));
        if (freq.length > 1) {
          for (let i = 1; i < freq.length - 1; i++) {
            if (freq[i] < this.freq[0]) {
              min = curve[i];
              minIndex = i;
            } else if (freq[i] > this.freq[this.freq.length - 1]) {
              max = curve[i];
              maxIndex = i;
            }
          }
          _curve = _curve.map((item, index) => index <= minIndex ? min : index >= maxIndex ? max : item);
        }
        return [{
          curve: _curve,
          col: _row,
          row: _row
        }]
      }
    }
    const type = 'zam';
    let sweepKey = currentPort.model && currentPort.model.key ? currentPort.model.key : null
    let _getCurve = null;
    if (curveType === OPTIMIZATION) {
      _getCurve = getImpedanceOptCurves
    } else if (curveType === SWEEPMODEL) {
      _getCurve = getImpedanceSweepCurves
    } else {
      switch (this.basicType || this.type) {
        case HISTORY:
          _getCurve = getImpedanceHistoryCurves;
          break;
        case IMPORT:
          _getCurve = getImpResultImportCurves;
          break;
        default:
          _getCurve = getImpedanceCurves;
          break;
      }
    }
    const reqRow = wordIndex ? [wordIndex] : [_row]
    return await _getCurve({
      cols: reqRow, rows: reqRow,
      type: type,
      verificationId: this.id,
      fileName: _fileName,
      sweepKey
    })
  }

  getNpiFile = async (index, params, components) => {
    try {
      let fileName = '', targetFile = []
      if (this.type === HISTORY || this.type === 'simulation') {
        const _getFile = this.type === HISTORY ? getImpedanceHistoryFile : getImpedanceResultFile;
        const files = await _getFile(params);
        const simFile = files ? files.find(item => item.type === 'simulation') : [];
        fileName = simFile ? simFile.fileName : '';
        targetFile = files ? files.filter(item => item.type === 'target') : [];
      }
      if ([WAITING, VERIFY_FAILED, VERIFY_RUNNING].includes(this.status)) {
        this.ports = [];
        this.freq = [];
      } else {
        const res = await this.getNpiFileFn(params, fileName);
        const parseData = await this.parseNpiFile(res, components, this.id, fileName, this.targets, this.type, this.portList);
        this.ports = parseData.ports;
        this.freq = parseData.freq;
        this.targetFrequency = { ...this.targetFrequency, ...parseData.targetFrequency }
      }
      this.fileName = fileName;
      this.targetFile = targetFile;
      this.initMatrix(index);
    } catch (error) {
      console.error(error)
    }
    return this;
  }

  initMatrix = (index) => {
    let n = index ? index : 0
    this.matrix = [];
    for (let i = 0, num = this.ports.length; i < num; i++) {
      this.matrix[i] = [];
      this.matrix[i][0] = {
        display: true,
        color: this.getColor(0 * num + i + n),
      }
      this.matrix[i][1] = {
        display: true,
        color: this.getColor(1 * num + i + n),
      }
    }
  }

  getCurveData = async ({ row, col, param, value, fileIndex, curveType, fileName = '', wordIndex }) => {

    let _col = col, freq = this.freq;
    const currentPort = this.ports.find(item => (item.index - 1) === Number(row));
    if (currentPort && currentPort.portType === 'target') {
      const [targetPower, index] = currentPort.fileName.split('@');
      const target = this.targets.find(item => item.power === targetPower);
      if (target && target.axis.length) {
        freq = target.axis[index].frequency;
        let minFreq = Math.min(...freq);
        let maxFreq = Math.max(...freq);
        let minLimit = this.freq[0];
        let maxLimit = this.freq[this.freq.length - 1];

        if (minFreq < minLimit || maxFreq > maxLimit) {
          let _freq = [minLimit];
          for (let i = 1; i < freq.length - 1; i++) {
            if (freq[i] < minLimit) {
              _freq.push(minLimit);
            } else if (freq[i] > maxLimit) {
              _freq.push(maxLimit);
            } else {
              _freq.push(freq[i]);
            }
          }
          _freq.push(maxLimit);
          freq = _freq;
        }
      }
    }

    if (!this.matrix || !this.matrix[row] || !this.matrix[row][_col]) {
      this.initMatrix(fileIndex);
    }

    let item = this.matrix[row][_col], type = param + value;
    if (item[type]) {
      return {
        type,
        color: item.color,
        curve: item[type],
        freq
      }
    } else {
      if (value === 'am' || value === 'ph' || value === 'db') {
        const _value = value === 'db' ? 'am' : value;
        const response = await this.getCurve(col, row, _value, curveType, wordIndex);
        item[type] = response && response[0] && response[0].curve;
        return {
          type,
          color: item.color,
          curve: item[type],
          freq
        }
      } else {
        // re, im
        const amData = await this.getCurveData({ row, col, param, value: 'am', curveType, fileName, wordIndex });
        const phData = await this.getCurveData({ row, col, param, value: 'ph', curveType, fileName, wordIndex });
        const cos = Math.cos;
        const sin = Math.sin;
        item[param + 're'] = amData.curve.map((am, i) => am * cos(phData.curve[i]));
        item[param + 'im'] = amData.curve.map((am, i) => am * sin(phData.curve[i]));
        return {
          type,
          color: item.color,
          curve: item[type],
          freq
        }
      }
    }
  }


  getColor = (i) => {
    const colors = this.colorType === 'dark' ? defaultColor_dark : this.colorType === 'light' ? defaultColor_light : defaultColor;
    if (this.colorCount === colors.length) {
      this.colorCount = 0;
      this.colorArr = [];
    }
    let colorIndex = i % colors.length;
    while (this.colorArr[colorIndex]) {
      colorIndex = (colorIndex + 1) % colors.length;
    }
    this.colorCount++;
    this.colorArr[colorIndex] = true;
    return colors[colorIndex];
  }
}

export default ImpedanceParameterData;