export const RES = 'Res',
  IND = 'Ind',
  CAP = 'Cap',
  CONNECTOR = 'Connector',
  TEST_POINT = 'testPoint',
  CHIP = 'Chip',
  IC = 'IC',
  COMP_REPEATER = 'Repeater',
  UNUSED = 'Unused',
  IGNORE = 'Ignore',
  MultiPinSPICE = "MultiPinSPICE",
  TEST_POINT_DISPLAY = "Test Point",
  ICTypes = ['Chip', 'IC'],
  RLCTypes = ["RLC", RES, IND, CAP, 'Jumper'],
  probePinsPointTypes = [CHIP, COMP_REPEATER, IC, TEST_POINT, CONNECTOR, UNUSED, IGNORE],
  sierraExtractionPortsType = [CHIP, CONNECTOR, IC];

/* eye diagram */
export const DELAY = 'delay',
  T_SHIFT = 't_shift',
  TIME_SCALE = 'time_scale',
  UI = 'ui',
  CLOCKS = 'clocks',
  V_REF = "v_ref",
  FRAME_SIZE = 'frame_size',
  EYE_CONFIG = 'eye_config',
  PLOT_CONFIG = 'plot_config',
  CLOCK_POSITIVE = 'clockPositive',
  CLOCK_NEGATIVE = 'clockNegative',
  SINGLE = "Single",
  DIFFERENTIAL = "Differential",
  NO_CLOCK = 'Periodic (No Clock Signal)',
  CLOCK_PINS = "clockPins",
  SIGNAL_PINS = "signalPins",
  TRIGGER = 'trigger',
  RISING_EDGE = "rising_edge",
  FALLING_EDGE = "falling_edge",
  DOUBLE_EDGE = "double_edge",
  SINGLE_DATA_RATE = "single_data_rate",
  DOUBLE_DATA_RATE = "double_data_rate",
  V_MIN = "v_min",
  V_MAX = "v_max",
  CLOCK_FREQUENCY = "clockFrequency",
  eyeUnitList = [DELAY, T_SHIFT, CLOCK_FREQUENCY],
  eyeClockTypeList = [NO_CLOCK, SINGLE, DIFFERENTIAL],
  eyeScaleList = ["us", "ns", "ps"],
  clockFrequencyList = ["GHz", "MHz", "KHz", "Hz"],
  timeScaleList = [{
    name: "1e6",
    display: "us",
    unit: "1e-6"
  },
  {
    name: "1e9",
    display: "ns",
    unit: "1e-9"
  },
  {
    name: "1e12",
    display: "ps",
    unit: "1e-12"
  }],
  clockTriggerList = [{
    name: RISING_EDGE,
    display: 'Rising Edge',
    tooltip: "Triggered when the clock rising edge crosses the reference voltage​."
  }, {
    name: FALLING_EDGE,
    display: 'Falling Edge',
    tooltip: "Triggered when the clock falling edge crosses the reference voltage."

  }, {
    name: DOUBLE_EDGE,
    display: 'Double Edge',
    tooltip: "Triggered when the clock rising or falling edge crosses the reference voltage (double data rate).​"
  }],
  clockDiffTriggerList = [{
    name: RISING_EDGE,
    display: 'Rising Edge (Clock Positive)',
    tooltip: "Triggered when the rising edge of the positive clock signal crosses with the falling edge of the negative clock signal​."
  }, {
    name: FALLING_EDGE,
    display: 'Falling Edge (Clock Positive)',
    tooltip: "Triggered when the falling edge of the positive clock signal crosses with the rising edge of the negative clock signal​."

  }, {
    name: DOUBLE_EDGE,
    display: 'Double Edge',
    tooltip: "Triggered when the positive clock signal crosses with the negative clock signal (double data rate)​​."
  }],
  noClockTriggerList = [{
    name: 'single_data_rate',
    display: 'Single Data Rate'
  }, {
    name: 'double_data_rate',
    display: 'Double Data Rate',

  }],
  plotTypeList = [{
    name: 'colormap',
    display: 'Colormap'
  }, {
    name: 'line',
    display: 'Line'
  },],
  advancedList = [DELAY, T_SHIFT, FRAME_SIZE, V_MIN, V_MAX];

export const Modes = [{
  name: 'General',
  value: 'Default'
}, {
  name: 'Return Loss',
  value: 'RL'
}, {
  name: 'Insertion Loss',
  value: 'IL'
}, {
  name: 'NEXT',
  value: 'NEXT'
}, {
  name: 'FEXT',
  value: 'FEXT'
}];

export const BUFFERIndex = 0,
  PCBModelIndex = 1,
  ConnectorIndex = 2,
  repeaterIndex = 3,
  IBISIndex = 0,
  SPICEIndex = 1,
  vectorIndex = 4,
  passiveIndex = 5,
  packageIndex = 6,
  childTouchstoneIndex = 0,
  childSpiceIndex = 1,
  pcbSpiceIndex = 0,
  customIndex = 8,
  TRACE_INDEX = 7;

export const VERIFICATION_JSON = "verification_json",
  IMPEDANCE_DAT = "impedance_dat",
  CHANNELSKEW_DAT = "channelskew_dat",
  PINTOPINSIM_RAW_FAST = "pintopinsim_raw",
  PINTOPINSIM_RAW_HIRE = "pintopinsim_raw_hire",
  PDN_NPI = "pdn_npi";

export const CREATE_SUCCESS = 2,
  CREATE_RUNNING = 1,
  CREATE_FAILED = 3;