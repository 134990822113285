import React, { Component, Fragment } from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Button, Checkbox, Select, Tabs, Switch, Tooltip } from 'antd';
import UnitAddonAfter from "../UnitAddonAfter";
import {
  AutoGeneratePorts,
  SINGLE_PIN,
  NEARBY_PINS,
  PORT_TYPE_LIST,
  CIRCUIT,
  PIN_GROUP,
  GAP,
  ALL_PINS,
  WAVE,
  getCompBallSizeByPinSize,
  BALL_SIZE,
  BALL_HEIGHT,
  GAP_SIZE,
  portRenderSetupList,
  nearbyPinsSetup,
  WAVE_BALL_TYPE_LIST,
  BALL_MIDDLE,
  CYLINDER,
  SPHEROID,
  getBallInfo,
  NEW_WAVE_BALL_TYPE_LIST,
} from '../../services/ExtractionPortsHelper';
import { numberCheck } from "../../services/helper/dataProcess";
import { valueUnitSplit } from "../../services/helper/valueUnitSplit";
import { unitChange } from '@/services/helper/mathHelper';
import { splitComponentName } from "@/services/helper/splitComponent";
import { ROCKY, ANDES_V2 } from "../../constants/pageType";
import '../../publicCss/aurora.css';
import './index.css';
import { BGA, DIE } from "../../constants/componentType";
import { BALL_TYPE_NONE, BALL_TYPE_NONE_LIST, USE_BALL_LIST } from "../../services/ExtractionPortsHelper/portTableHelper";
import MaterialPanel from '@/components/Material';
import { MATERIAL } from "../../constants/libraryConstants";
import { METAL } from "../../services/Stackup";
import { SOLDER } from "../../services/Stackup/Material";
import referencePinGroupRender, { getInputComponent } from "./referencePinGroupRender";

const Option = Select.Option;
const units = ["mm", "um", "mil"];
class AutoGenerationPort extends Component {

  constructor(props) {
    super(props);
    this.state = {
      z0: "50",
      setupList: [],
      applyAllComponents: false,
      errorMsg: null,
      generateErrors: null,
      errorVisible: false,
      generateWarnings: null,
      saveSettingToAllComps: false,
      applyAllInterfaces: false,
      ballMaterialVisible: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { ports_generate_setup_list: setupList, components, port_setups, referenceNets } = this.props;
    const firstSetup = setupList.length ? setupList[0] : {};
    const applyComponents = setupList.map(item => item.component);
    let portComponents = components.filter(item => applyComponents.includes(splitComponentName(item.name)));
    const z0 = port_setups.length ? port_setups[0].z0 : "50";
    const componentSetups = this.getComponentSetup(portComponents, setupList)
    const _components = this.getComponents(componentSetups);
    this.props.updateSetupComponents(_components);
    this.prevSetupList = JSON.parse(JSON.stringify({ setupList, referenceNets, componentSetups }));

    const portSetupList = JSON.parse(JSON.stringify(setupList));
    this.setState({
      z0,
      setupList: portRenderSetupList(portSetupList),
      compTabMode: firstSetup.component || "",
      errorMsg: null,
      generateErrors: null,
      errorVisible: false,
      generateWarnings: null,
      componentSetups
    })
  }

  updateAutoPortInfo = () => {
    const { ports_generate_setup_list: setupList, components, port_setups, referenceNets } = this.props;
    const firstSetup = setupList.length ? setupList[0] : {};
    const applyComponents = setupList.map(item => item.component);
    let portComponents = components.filter(item => applyComponents.includes(splitComponentName(item.name)));
    const z0 = port_setups.length ? port_setups[0].z0 : "50";
    const componentSetups = this.getComponentSetup(portComponents, setupList)
    this.prevSetupList = JSON.parse(JSON.stringify({ setupList, referenceNets, componentSetups }));

    const portSetupList = JSON.parse(JSON.stringify(setupList));
    this.setState({
      z0,
      setupList: portRenderSetupList(portSetupList),
      compTabMode: firstSetup.component || "",
      errorMsg: null,
      generateErrors: null,
      errorVisible: false,
      generateWarnings: null,
      componentSetups
    })
  }

  getComponentSetup = (portComponents, setupList) => {
    let componentSetups = [];
    const { product } = this.props;
    for (let item of portComponents) {
      const compName = splitComponentName(item.name);
      const find = componentSetups.find(comp => comp.name === compName);
      if (find) {
        continue;
      }
      const { value: gap_size, unit: gap_sizeUnit } = valueUnitSplit(item.gap_size);
      const { value: ball_size, unit: ball_sizeUnit } = valueUnitSplit(item.ball_size);
      const { value: ball_height, unit: ball_heightUnit } = valueUnitSplit(item.ball_height);
      const { value: ball_mid_diameter, unit: ball_mid_diameterUnit } = valueUnitSplit(item.ball_mid_diameter);
      const findSetupInfo = setupList.find(it => it.component === item.name);

      let defaultBallType = CYLINDER;
      if (findSetupInfo && findSetupInfo.setup && findSetupInfo.setup.portType && BALL_TYPE_NONE_LIST.includes(findSetupInfo.setup.portType)) {
        defaultBallType = BALL_TYPE_NONE;
      }

      const obj = {
        name: compName,
        gap_size,
        gap_sizeUnit: gap_sizeUnit || "um",
        ball_size,
        ball_sizeUnit: ball_sizeUnit || "um",
        ball_height,
        ball_heightUnit: ball_heightUnit || "um",
        ball_mid_diameter,
        ball_mid_diameterUnit: ball_mid_diameterUnit || "um",
        ball_type: item.ball_type || defaultBallType
      }

      if (product === ANDES_V2 || product === ROCKY) {
        obj.ball_material = item.ball_material || SOLDER;
      }

      componentSetups.push(obj);
    }
    return componentSetups;
  }

  addonAfter = ({ unit, key, disabled, unitOptions, component }) => {
    return UnitAddonAfter({
      unit,
      disabled,
      changeUnit: (e) => this.changeUnit(e, key, component),
      list: unitOptions,
      className: "extraction-ports-unit-select"
    })
  }

  changeUnit = (unit, key, component) => {
    if ([BALL_SIZE, BALL_HEIGHT, GAP_SIZE, BALL_MIDDLE].includes(key)) {
      this.changeComponentSetup(unit, key, component, true);
    }

    const { setupList } = this.state;
    let _setupList = [...setupList];
    const index = _setupList.findIndex(item => item.component === component);
    if (index < 0) {
      return;
    }

    if (key === "pinDistance") {
      _setupList[index].setup[key] = unitChange({
        num: _setupList[index].setup[key],
        oldUnit: _setupList[index].setup[`${key}Unit`],
        newUnit: unit,
        decimals: 3
      }).number;
    }

    _setupList[index].setup[`${key}Unit`] = unit;
    this.setState({
      setupList: _setupList
    }, () => {
      this.applyPortToAllComps();
    });
    this.clearGenerateMessage();
  }

  clearGenerateMessage = () => {
    this.setState({
      errorVisible: false,
      generateErrors: null,
      generateWarnings: null
    })
  }

  changeInputValue = (e, type, component) => {
    if ([BALL_SIZE, BALL_HEIGHT, GAP_SIZE, BALL_MIDDLE].includes(type)) {
      this.changeComponentSetup(e, type, component);
      return;
    }
    const { errorMsg, setupList } = this.state;
    let _setupList = [...setupList];
    const index = _setupList.findIndex(item => item.component === component);
    if (index < 0) {
      return;
    }
    _setupList[index].setup[type] = e.target.value;
    this.setState({
      setupList: _setupList,
      errorMsg: errorMsg && errorMsg.type === type ? null : errorMsg
    }, () => {
      this.applyPortToAllComps();
    });
    this.clearGenerateMessage();
  }

  inputOnBlur = (e, type, typeText) => {
    const value = e.target.value;
    const error = numberCheck(value);
    const { extractionType } = this.props;
    if (error) {
      this.setState({
        errorMsg: {
          type,
          error: `${typeText} ${error}`
        }
      })
    } else if ([BALL_SIZE, BALL_HEIGHT, GAP_SIZE, BALL_MIDDLE].includes(type) && parseFloat(value) < 0) {
      this.setState({
        errorMsg: {
          type,
          error: `${typeText} should be greater than or equal to 0.`
        }
      })
    } else if (extractionType === "HFSS" && type === GAP_SIZE && parseFloat(value) <= 0) {
      this.setState({
        errorMsg: {
          type,
          error: `When extraction is HFSS, ${typeText.toLowerCase()} should be greater than 0.`
        }
      })
    }
  }

  getComponents = (componentSetups) => {
    let _components = [...this.props.components];
    for (let comp of componentSetups) {
      const index = _components.findIndex(item => splitComponentName(item.name) === comp.name);
      if (index < 0) {
        continue;
      }
      _components[index].ball_type = comp.ball_type;
      // add ball_material
      _components[index].ball_material = comp.ball_material;
      _components[index].gap_size = comp.gap_size && Number(comp.gap_size) !== 0 ? `${comp.gap_size}${comp.gap_sizeUnit}` : (comp.gap_size || "");
      if (comp.ball_type === BALL_TYPE_NONE) {
        delete _components[index].ball_size
        delete _components[index].ball_height
        delete _components[index].ball_material
      } else {
        _components[index].ball_size = comp.ball_size ? `${comp.ball_size}${comp.ball_sizeUnit}` : "";
        _components[index].ball_height = comp.ball_height ? `${comp.ball_height}${comp.ball_heightUnit}` : "";
        _components[index].ball_material = comp.ball_material ? comp.ball_material : SOLDER;
      }

      if (comp.ball_type === SPHEROID) {
        _components[index].ball_mid_diameter = comp.ball_mid_diameter ? `${comp.ball_mid_diameter}${comp.ball_mid_diameterUnit}` : "";
      } else {
        delete _components[index].ball_mid_diameter
      }
    }
    return _components
  }

  changeComponentSetup = (e, type, component, isUnit) => {
    let value = "", valueType = "";
    if (isUnit) {
      value = e;
      valueType = `${type}Unit`;
    } else {
      value = e.target.value;
      valueType = type;
    }

    const { errorMsg, componentSetups, saveSettingToAllComps } = this.state;
    let _componentSetups = [...componentSetups];
    if (saveSettingToAllComps) {
      _componentSetups.forEach(item => {
        if (isUnit) {
          item[type] = unitChange({
            num: item[type],
            oldUnit: item[valueType],
            newUnit: value,
            decimals: 3
          }).number;
        }
        item[valueType] = value;
      });
    } else {
      const index = _componentSetups.findIndex(comp => comp.name === component);
      if (index < 0) {
        return;
      }
      if (isUnit) {
        _componentSetups[index][type] = unitChange({
          num: _componentSetups[index][type],
          oldUnit: _componentSetups[index][valueType],
          newUnit: value,
          decimals: 3
        }).number;
      }
      _componentSetups[index][valueType] = value;
    }

    this.setState({
      componentSetups: _componentSetups,
      errorMsg: errorMsg && errorMsg.type === type ? null : errorMsg
    });
    this.clearGenerateMessage();
  }

  errorCheck = ({ pinNumber, pinDistance, filterPinOptions }) => {
    if (filterPinOptions.length === 0) {
      return { type: "filterPinOptions", error: "Select at least one nearby pins option." }
    }
    const numberError = numberCheck(pinNumber);
    if (filterPinOptions.includes("number") && numberError) {
      return { type: "pinNumber", error: `Max number of pins ${numberError}` }
    }

    const distanceError = numberCheck(pinDistance);
    if (filterPinOptions.includes("distance") && distanceError) {
      return { type: "pinDistance", error: `Reference pins distance ${distanceError}` }
    }
  }

  updateZ0 = (e) => {
    const { errorMsg } = this.state;
    this.setState({
      z0: e.target.value,
      errorMsg: errorMsg && errorMsg.type === "z0" ? null : errorMsg
    })
    this.clearGenerateMessage();
  }

  z0OnBlur = (e, isGenerate) => {
    const value = e.target.value;
    //check number format
    const error = numberCheck(value);
    if (error) {
      this.setState({
        errorMsg: { error: `Z0 ${error}`, type: "z0" }
      })
    } else if (!isGenerate) {
      this.saveZ0(value)
    }
    return error;
  }

  saveZ0 = (value, isGenerate) => {
    const { port_setups } = this.props;
    let _port_setups = [...port_setups];
    _port_setups.forEach(item => {
      item.z0 = value;
    });
    !isGenerate && this.props.updatePortSetups(_port_setups);
    return _port_setups;
  }

  componentDidUpdate = (prevProps) => {
    const { referenceNets } = this.props;
    if (referenceNets && prevProps.referenceNets && referenceNets.length !== prevProps.referenceNets.length) {
      const { errorMsg } = this.state;
      this.setState({
        errorMsg: errorMsg && errorMsg.type === "referenceNets" ? null : errorMsg
      })
    }
  }

  judgeIfUpdate = () => {
    let currentPortSetupList = this.getPortSetup();
    if (!Array.isArray(currentPortSetupList)
      || JSON.stringify(this.prevSetupList) !== JSON.stringify({
        setupList: currentPortSetupList,
        referenceNets: this.props.referenceNets,
        componentSetups: this.state.componentSetups
      })) {
      return true;
    }
  }

  judgeErrorMsgExist = () => {
    if (this.state.errorMsg) {
      return true;
    }
  }

  getPortSetup = (isUpdate) => {
    const { setupList } = this.state;
    let _setupList = JSON.parse(JSON.stringify(setupList));
    for (let item of _setupList) {
      const portSetup = { ...item.setup };
      if ([GAP, PIN_GROUP].includes(portSetup.portType) && portSetup.referenceType === NEARBY_PINS) {
        //error check
        const error = portSetup.referenceType === NEARBY_PINS ? this.errorCheck({
          pinNumber: portSetup.pinNumber,
          pinDistance: portSetup.pinDistance,
          filterPinOptions: portSetup.filterPinOptions || []
        }) : null;
        if (error) {
          isUpdate && this.setState({
            errorMsg: { type: error.type, error: error.error }
          });
          return;
        }

        item.setup.pinDistance = `${portSetup.pinDistance}${portSetup.pinDistanceUnit || "mm"}`;
        delete item.setup.pinDistanceUnit;
      }
    }
    return _setupList;
  }

  generatePort = (saveType) => {
    const { referenceNets, designId, DesignInfo, extractionType } = this.props;
    const { z0, componentSetups, applyAllInterfaces } = this.state;
    const pcbInfo = DesignInfo.getPCBInfo(designId);
    if (!referenceNets.length) {
      this.setState({
        errorMsg: { type: "referenceNets", error: "Select at least one reference net." }
      });
      return;
    }
    //z0 error check
    const z0Error = this.z0OnBlur({ target: { value: z0 } }, true);
    if (z0Error) {
      return;
    }

    let port_setups = this.saveZ0(z0, true) || this.props.port_setups;

    const _setupList = this.getPortSetup(true);
    if (!Array.isArray(_setupList)) {
      return;
    }

    let info = {
      referenceNets,
      referenceZ0: z0,
      designId,
      extractionType
    }

    info.ports_generate_setup_list = _setupList;
    info.applyComponents = _setupList.map(item => item.component);
    const autoGenerationPort = new AutoGeneratePorts();
    const { port_setups: _port_setups, setupList: portsSetupList } = autoGenerationPort.autoGeneratePorts(
      port_setups,
      pcbInfo,
      {
        ...info
      }
    )
    let warnings = autoGenerationPort.getWarnings();
    if (warnings && warnings.length) {
      this.setState({
        generateWarnings: warnings
      });
    }

    const errors = autoGenerationPort.getErrors();
    if (errors && errors.length) {
      this.setState({
        errorVisible: errors && errors.length,
        generateErrors: errors
      });
      return;
    }

    this.prevSetupList = JSON.parse(JSON.stringify({
      setupList: portsSetupList,
      referenceNets,
      componentSetups
    }));

    let _saveType = false;
    if (saveType && (!warnings || !warnings.length) && (!errors || !errors.length)) {
      _saveType = saveType;
    }
    const _components = this.getComponents(componentSetups);
    this.props.updateSetupComponents(_components);
    this.props.updateGenerateRefNets(referenceNets);
    this.props.updatePortSetups(_port_setups, portsSetupList, _saveType);

    // Auto copy to other interfaces.
    if (applyAllInterfaces) {
      this.props.applyRockyPortSetup(info, _components)
    }
    //update port setup
    this.setState({
      setupList: JSON.parse(JSON.stringify(portRenderSetupList(portsSetupList)))
    })
  }

  portTypeChange = (key, compName) => {
    const { componentSetups, setupList } = this.state;
    const { components, designId, extractionType, product } = this.props;
    let _setupList = [...setupList];
    const index = _setupList.findIndex(item => item.component === compName);
    if (index < 0) {
      return;
    }
    let _componentSetups = [...componentSetups];
    _setupList[index].setup = {
      portType: key,
      referenceType: SINGLE_PIN
    }
    let compIndex = _componentSetups.findIndex(item => item.name === compName);
    if (compIndex < 0) {
      _componentSetups.push({ name: compName });
      compIndex = _componentSetups.length - 1;
    }

    let gapSetupInfo = {};

    if (key === GAP) {
      gapSetupInfo = {
        gap_size: extractionType === "HFSS" ? "10" : "0",
        gap_sizeUnit: "um",
      }
    }

    if ([WAVE, GAP].includes(key)) {
      _setupList[index].setup.referenceType = ALL_PINS;
    }

    const comp = components.find(it => splitComponentName(it.name) === compName);
    let defaultBallType = CYLINDER;
    if (comp.type === BGA) {
      defaultBallType = SPHEROID;
    } else if (comp.type === DIE) {
      defaultBallType = CYLINDER
    } else {
      defaultBallType = key === WAVE ? CYLINDER : BALL_TYPE_NONE;
    }

    const pinSize = getCompBallSizeByPinSize(comp, designId, true, true);
    const ballInfo = getBallInfo(defaultBallType, pinSize);
    _componentSetups[compIndex] = {
      ...gapSetupInfo,
      name: compName,
      ball_type: defaultBallType,
      ...ballInfo
    }

    if (product === ANDES_V2 || product === ROCKY) {
      _componentSetups[compIndex].ball_material = SOLDER;
      this.props.updateMaterials && this.props.updateMaterials();
    }

    this.setState({
      setupList: _setupList,
      errorMsg: null,
      componentSetups: _componentSetups
    }, () => {
      this.props.updateRenderPortType(key);
      this.props.setContentHeight();
      this.applyPortToAllComps();
      this.applyAllAvoidSinglePinGroupChange(false);
    })
    this.clearGenerateMessage();
  }

  applyPortToAllComps = () => {
    const { setupList, compTabMode, applyAllComponents } = this.state;
    const { isUsedBall } = this.props;
    if (!applyAllComponents) {
      return;
    }
    let _setupList = [...setupList];
    const findComp = _setupList.find(item => item.component === compTabMode);
    if (!findComp || !findComp.setup) {
      return;
    }

    for (let item of _setupList) {
      item.setup = JSON.parse(JSON.stringify(findComp.setup));
    }

    if ([GAP, WAVE].includes(findComp.setup.portType) || (isUsedBall && USE_BALL_LIST.includes(findComp.setup.portType))) {
      this.setDefaultCompGapAndBallSize(_setupList.map(item => item.component), compTabMode, findComp.setup.portType);
    }
    this.setState({
      setupList: _setupList
    });
  }

  refTypeChange = (key, component) => {
    const { setupList } = this.state;
    let _setupList = [...setupList];
    const index = _setupList.findIndex(item => item.component === component);
    if (index < 0) {
      return;
    }
    _setupList[index].setup.referenceType = key;
    if (key === NEARBY_PINS) {
      _setupList[index].setup = { ..._setupList[index].setup, ...nearbyPinsSetup(_setupList[index].setup) };
    } else {
      delete _setupList[index].setup.pinDistance;
      delete _setupList[index].setup.pinDistanceUnit;
      delete _setupList[index].setup.filterPinOptions;
      delete _setupList[index].setup.pinNumber;
    }
    this.setState({
      setupList: _setupList
    }, () => {
      this.applyPortToAllComps();
      this.props.setContentHeight();
    })
    this.clearGenerateMessage();
  }

  checkChange = (checkedKeys, component) => {
    const { errorMsg, setupList } = this.state;
    let _setupList = [...setupList];
    const index = _setupList.findIndex(item => item.component === component);
    if (index < 0) {
      return;
    }
    _setupList[index].setup.filterPinOptions = checkedKeys;

    this.setState({
      setupList: _setupList,
      errorMsg: errorMsg && errorMsg.type === "filterPinOptions" ? null : errorMsg
    }, () => {
      this.applyPortToAllComps();
    })
    this.clearGenerateMessage();
  }

  applyChange = (checked) => {
    this.setState({
      applyAllComponents: checked
    }, () => {
      this.applyPortToAllComps();
      this.applyAllAvoidSinglePinGroupChange()
    });
    this.clearGenerateMessage();
  }

  applyGroupChange = (checked, component) => {
    const { avoidSinglePinGroup } = this.props;
    let _avoidSinglePinGroup = { ...(avoidSinglePinGroup || {}) };
    _avoidSinglePinGroup[component] = checked;
    this.props.updateAvoidSinglePinGroup(_avoidSinglePinGroup)
    this.applyAllAvoidSinglePinGroupChange(checked)
  }

  applyAllAvoidSinglePinGroupChange = (checked) => {
    const { avoidSinglePinGroup, product } = this.props;
    const { setupList, compTabMode, applyAllComponents } = this.state;
    if (!applyAllComponents || (product !== ANDES_V2 && product !== ROCKY)) {
      return;
    }
    const findComp = setupList.find(item => item.component === compTabMode);
    if (!findComp || !findComp.setup || findComp.setup.portType !== PIN_GROUP) {
      checked = false
    }
    let _avoidSinglePinGroup = { ...(avoidSinglePinGroup || {}) };
    for (let item of setupList) {
      _avoidSinglePinGroup[item.component] = checked !== undefined ? checked : _avoidSinglePinGroup[findComp.component] || false;
    }
    this.props.updateAvoidSinglePinGroup && this.props.updateAvoidSinglePinGroup(_avoidSinglePinGroup)
  }

  ChangeApplyInterfaces = () => {
    this.setState({
      applyAllInterfaces: !this.state.applyAllInterfaces
    })
  }

  setDefaultCompGapAndBallSize = (comps, compName, portType) => {
    const { componentSetups } = this.state;
    const { extractionType, components, designId } = this.props;
    const currentCompSetup = componentSetups.find(item => item.name === compName)
    let _componentSetups = componentSetups.map(item => {
      const { type } = item;
      if (compName === item.name || !comps.includes(item.name)) {
        return item;
      }
      if (portType === GAP) {
        item.gap_size = extractionType === "HFSS" ? "10" : "0";
        item.gap_sizeUnit = "um";
      }
      if ([...USE_BALL_LIST].includes(portType)) {
        if (!item.ball_type
          || item.ball_type !== currentCompSetup.ball_type
          || (item.ball_type !== BALL_TYPE_NONE && (!item.ball_size || !item.ball_height))) {
          const comp = components.find(it => splitComponentName(it.name) === item.name);
          const pinSize = getCompBallSizeByPinSize(comp, designId, true, true);
          let ball_type = CYLINDER;
          if (currentCompSetup && currentCompSetup.ball_type) {
            ball_type = currentCompSetup.ball_type;
          } else if (![BGA, DIE].includes(type) && BALL_TYPE_NONE_LIST.includes(portType)) {
            ball_type = BALL_TYPE_NONE;
          }
          item.ball_type = ball_type;
          const ballInfo = getBallInfo(item.ball_type, pinSize);
          item = { ...item, ...ballInfo }
          if (item.ball_material === SOLDER) {
            this.props.updateMaterials && this.props.updateMaterials();
          }
        }
      }
      return item
    })
    this.setState({
      componentSetups: _componentSetups
    })
  }

  changeCompTab = (key) => {
    this.setState({
      compTabMode: key,
      ballMaterialVisible: false
    })
  }

  updateErrorList = (errorVisible, errors) => {
    this.setState({
      errorVisible: errorVisible,
      generateErrors: errors
    })
  }

  onSearch = (value) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.searchNets(value)
    }, 500);
  }


  searchNets = (value) => {
    const { allPCBNets } = this.props;
    if (!value || value.match(/^\s+$/g)) {
      this.setState({
        searching: false,
        filterNets: []
      })
      return;
    }
    const reg = new RegExp(value, 'ig');
    const filterNets = allPCBNets.filter(item => item.match(reg));
    this.setState({
      searching: true,
      filterNets
    })
  }

  onSelectBlur = () => {
    this.setState({
      searching: false
    });
  }

  render() {
    const {
      errorMsg,
      z0,
      errorVisible,
      generateErrors,
      generateWarnings, searching, filterNets } = this.state;
    const { referenceNets, allReferenceNetList, loadingPCB, selectPowerNets } = this.props;
    const _allReferenceNetList = allReferenceNetList.filter(item => !!item);
    const selectOption = searching ? filterNets || [] : _allReferenceNetList;
    return <div className='extraction-ports-reference-main' id="extraction-ports-reference-main">
      <div className='extraction-ports-auto-setup'>
        <div className='extraction-ports-input-content'>
          <label>Reference Nets</label>
          <Select
            className='port-select aurora-select'
            mode={"multiple"}
            showSearch
            value={referenceNets}
            onChange={(keys) => this.props.updateReferenceNets(keys)}
            popupMatchSelectWidth={false}
            popupClassName='aurora-select-dropdown'
            onSearch={selectPowerNets ? this.onSearch : null}
            options={selectOption.map(item => ({ value: item, label: <span>{item}</span> }))}
          >
          </Select>
        </div>
        <div className='extraction-ports-z0-content'>
          <label>Z<span className='extraction-ports-z0-sub'>0</span></label>
          <Input
            className='aurora-input'
            value={z0}
            onChange={(e) => this.updateZ0(e)}
            onBlur={(e) => this.z0OnBlur(e)}
            addonAfter={"Ω"}
          />
        </div>
        {this.getComponentSetupMenu()}
        <div className={`extraction-ports-generate-button ${this.judgeIfUpdate() ? "extraction-ports-generate-button-active" : ""}`}>
          {this.copyInterfaces()}
          <Button
            onClick={loadingPCB ? null : () => this.generatePort()}
            size="small"
            disabled={loadingPCB ? true : false}
            title={loadingPCB ? "Loading PCB..." : ""}
          >Update</Button>
        </div>
        {errorMsg ? <span className="aurora-model-name-error-msg">{errorMsg.error}</span> : null}
        {errorVisible && generateErrors && generateErrors.length ?
          <div className="aurora-model-name-error-msg">
            {generateErrors.map((item, index) => (
              <div key={index} className="aurora-model-error-item">{item}</div>
            ))}
          </div>
          : null}
        {generateWarnings && generateWarnings.length ?
          <div className="aurora-model-name-warning-msg">
            {generateWarnings.map((item, index) => (
              <div key={index} className="aurora-model-warning-item">{item}</div>
            ))}
          </div>
          : null}
      </div>
    </div>
  }

  copyInterfaces = () => {
    const { applyAllInterfaces } = this.state;
    const { product, isNotCopy } = this.props
    if (product === ROCKY && !isNotCopy) {
      return <div className="extraction-ports-copy-content">
        <span className="extraction-port-content-body">Apply port setup to all Interfaces</span>
        <Checkbox
          checked={applyAllInterfaces}
          onChange={this.ChangeApplyInterfaces}
          style={{ verticalAlign: 'middle' }}
        />
      </div >
    }
  }

  getComponentSetupMenu = () => {
    const { setupList, compTabMode } = this.state;
    return <Tabs
      tabPosition="top"
      className="aurora-extraction-tabs"
      activeKey={compTabMode}
      type="card"
      onChange={this.changeCompTab}
      items={setupList.map(comp => ({ key: comp.component, label: comp.component, children: this.getComponentSetupItem(comp) }))}
    />
  }

  getComponentSetupItem = (compInfo) => {
    const { extractionType, isUsedBall, product, avoidSinglePinGroup } = this.props;
    const { componentSetups, applyAllComponents, ballMaterialVisible, compTabMode } = this.state;
    const compSetup = compInfo.setup;
    const gapWaveCompSetup = componentSetups.find(item => item.name === compInfo.component);
    const portTypeList = extractionType === 'PowerSI' ? PORT_TYPE_LIST.filter(item => [PIN_GROUP, CIRCUIT].includes(item.key)) : PORT_TYPE_LIST
    return <Fragment>
      {this.getSelectComponent({
        label: "Port Type",
        value: compSetup.portType,
        changeFn: (key) => this.portTypeChange(key, compInfo.component),
        options: portTypeList,
        type: "portType"
      })}
      {referencePinGroupRender({
        compSetup,
        compInfo,
        extractionType,
        refTypeChange: this.refTypeChange,
        checkChange: this.checkChange,
        addonAfter: this.addonAfter,
        inputOnBlur: this.inputOnBlur,
        changeInputValue: this.changeInputValue
      })}
      {/*   {![CIRCUIT, WAVE].includes(compSetup.portType) ? this.getSelectComponent({
        label: "Reference Pin Grouping",
        value: compSetup.referenceType,
        changeFn: (key) => this.refTypeChange(key, compInfo.component),
        options: REFERENCE_TYPE_LIST,
        type: "referenceType",
        portType: compSetup.portType,
        extractionType
      }) : null}
      {compSetup.referenceType === NEARBY_PINS && <Checkbox.Group
        className="signal-port-edit-item-checkbox"
        onChange={(keys) => this.checkChange(keys, compInfo.component)}
        value={compSetup.filterPinOptions}
      >
        {this.getCheckBox(compSetup, compInfo.component)}
      </Checkbox.Group>} */}
      {[GAP, WAVE, CIRCUIT, PIN_GROUP].includes(compSetup.portType) ? <Fragment>
        {this.getComponentBallSetupItem(compSetup.portType, gapWaveCompSetup, isUsedBall, ballMaterialVisible, compTabMode, product)}
      </Fragment> : null}
      {(product === ANDES_V2 || product === ROCKY) && compSetup.portType === PIN_GROUP ? <div className='signal-port-edit-item'>
        <label className='signal-port-edit-item-apply-label'>Do not group the positive pin</label>
        <div className="signal-port-edit-item-switch">
          <Switch
            size="small"
            checked={avoidSinglePinGroup ? avoidSinglePinGroup[compInfo.component] || false : false}
            onChange={(checked) => this.applyGroupChange(checked, compInfo.component)}
          />
        </div>
      </div> : null}
      <div className='signal-port-edit-item'>
        <label className='signal-port-edit-item-apply-label'>Apply port type to all components</label>
        <div className="signal-port-edit-item-switch">
          <Switch
            size="small"
            checked={applyAllComponents}
            onChange={this.applyChange}
          />
        </div>
      </div>
    </Fragment >
  }

  getSelectComponent = ({
    label,
    value,
    changeFn,
    options,
    portType,
    extractionType,
    multiple
  }) => {
    return <div className='signal-port-edit-item'>
      <label>{label}</label>
      <Select
        className='aurora-select'
        value={value}
        showSearch
        mode={multiple}
        onChange={(key) => changeFn(key)}
        popupMatchSelectWidth={false}
        popupClassName='aurora-select-dropdown'
      >
        {options.map(item => {
          if (item.disabled && item.disabled === portType) {
            return null;
          } else if (typeof (item) === "string") {
            return <Option
              key={item}
              value={item}
              title={item}
            >{item}</Option>
          } else {
            return <Option
              key={item.key}
              value={item.key}
              title={item.name}
            >{item.name}</Option>
          }
        })}
      </Select>
    </div>
  }

  /*   getCheckBox = (compSetup, compName) => {
      return <Fragment><div className='signal-port-edit-item extraction-ports-auto-checkbox-main extraction-ports-auto-checkbox-1'>
        <Checkbox
          value="number"
          key="number"
          className='extraction-ports-sub-checkbox'
        >Max number of pins</Checkbox>
        {this.getInput({
          value: compSetup.pinNumber,
          disabled: !compSetup.filterPinOptions.includes("number"),
          type: "pinNumber",
          typeText: "Max number of pins",
          component: compName
        })}
      </div>
        <div className='signal-port-edit-item extraction-ports-auto-checkbox-main'>
          <Checkbox
            value="distance"
            key="distance"
            className='extraction-ports-sub-checkbox'
          >Reference pins within</Checkbox>
          {this.getInput({
            value: compSetup.pinDistance,
            disabled: !compSetup.filterPinOptions.includes("distance"),
            type: "pinDistance",
            typeText: "Reference pins distance",
            className: "extraction-ports-sub-right-input",
            unit: compSetup.pinDistanceUnit,
            unitOptions: units,
            component: compName
          })}
        </div>
      </Fragment>
    } */

  ballTypeChange = (key, compName) => {
    const { componentSetups } = this.state;
    const { components, designId, product } = this.props;
    let _componentSetups = [...componentSetups];
    let compIndex = _componentSetups.findIndex(item => item.name === compName);
    const comp = components.find(it => splitComponentName(it.name) === compName);
    const pinSize = getCompBallSizeByPinSize(comp, designId, true, true);
    const ballInfo = getBallInfo(key, pinSize);
    _componentSetups[compIndex] = {
      name: compName,
      ball_type: key,
      ...ballInfo,
      gap_size: componentSetups[compIndex].gap_size,
      gap_sizeUnit: componentSetups[compIndex].gap_sizeUnit
    }

    if (product === ANDES_V2 || product === ROCKY) {
      _componentSetups[compIndex].ball_material = componentSetups[compIndex].ball_material || SOLDER;
      _componentSetups[compIndex].ball_material === SOLDER && this.props.updateMaterials && this.props.updateMaterials();
    }

    this.setState({
      errorMsg: null,
      componentSetups: _componentSetups
    }, () => {
      this.props.updateRenderPortType(key);
      this.props.setContentHeight();
      this.applyPortToAllComps();
    })
    this.clearGenerateMessage();
  }

  ballMaterialInput = (ball_material, ballMaterialVisible) => {
    const { materialList } = this.props;
    const isExist = ball_material === '' || (materialList && materialList.findIndex(item => item.name === ball_material)) > -1 ? true : false;
    return <Fragment>
      <Input
        className="aurora-input aurora-extraction-ports-ball-material-input"
        value={ball_material}
        onClick={() => this.setState({ ballMaterialVisible: true })}
        // onChange={(e) => this.changeInputValue(e, type)}
        disabled={ballMaterialVisible}
        style={{ color: isExist ? '' : 'red' }}
      />
      {!isExist ? <Tooltip
        title={<Fragment>
          {`${ball_material} has been deleted, the material is invalid.`}
        </Fragment>}
        overlayClassName='aurora-tooltip'
      >
        <QuestionCircleOutlined className='aurora-extraction-ports-question-circle-icon' onClick={(e) => { e && e.stopPropagation() }} />
      </Tooltip> : null}
    </Fragment>
  }

  ballMaterialPanel = (ball_material, compName, product) => {
    const { materialList } = this.props;
    const record = {
      type: METAL,
      selectMaterialName: ball_material,
      material: ball_material,
      name: ball_material,
      dataIndex: MATERIAL
    }

    return <MaterialPanel
      record={record}
      dataIndex={MATERIAL}
      materialList={materialList || []}
      closePanel={(material) => this.saveBallMaterial(material, compName)}
      pageType={product}
      editType="ball_material"
    />
  }

  saveBallMaterial = (materialValue, compName) => {
    const { componentSetups } = this.state;
    this.setState({ ballMaterialVisible: false });
    // save stackup info
    this.props.saveStackupToServer({ ...materialValue });
    // change origin data
    const compIndex = componentSetups.findIndex(item => item.name === compName);
    let _componentSetups = [...componentSetups];
    _componentSetups[compIndex] = {
      ...componentSetups[compIndex],
      ball_material: materialValue.newMaterial.name
    }
    this.setState({ componentSetups: _componentSetups });
  }

  getComponentBallSetupItem = (portType, compSetup, isUsedBall, ballMaterialVisible, compTabMode, product) => {
    return <Fragment>
      {portType === GAP ?
        <div className="aurora-extraction-ports-input-main">
          <span>Reference Plane Distance</span>
          {this.getInput({
            value: compSetup.gap_size || "",
            unit: compSetup.gap_sizeUnit || "um",
            unitOptions: units,
            component: compSetup.name,
            type: "gap_size",
            typeText: "Reference Plane Distance"
          })}
        </div>
        : null}
      {isUsedBall || portType === WAVE ?
        <Fragment>
          <div className="aurora-extraction-ports-input-main">
            {this.getSelectComponent({
              label: "Ball Shape",
              value: compSetup.ball_type,
              changeFn: (key) => this.ballTypeChange(key, compSetup.name),
              options: isUsedBall ? NEW_WAVE_BALL_TYPE_LIST : WAVE_BALL_TYPE_LIST,
              type: "ball_type"
            })}
          </div>
          {compSetup.ball_type !== BALL_TYPE_NONE && <div className="aurora-extraction-ports-input-main">
            <span>Ball Diameter</span>
            {this.getInput({
              value: compSetup.ball_size || "",
              unit: compSetup.ball_sizeUnit || "um",
              unitOptions: units,
              component: compSetup.name,
              type: "ball_size",
              typeText: "Ball Diameter"
            })}
          </div>}
          {compSetup.ball_type !== BALL_TYPE_NONE && <div className="aurora-extraction-ports-input-main">
            <span>Ball Height</span>
            {this.getInput({
              value: compSetup.ball_height || "",
              unit: compSetup.ball_heightUnit || "um",
              unitOptions: units,
              component: compSetup.name,
              type: "ball_height",
              typeText: "Ball Height"
            })}
          </div>}
          {compSetup.ball_type === SPHEROID &&
            <div className="aurora-extraction-ports-input-main">
              <span>Ball Middle</span>
              {this.getInput({
                value: compSetup.ball_mid_diameter || "",
                unit: compSetup.ball_mid_diameterUnit || "um",
                unitOptions: units,
                component: compSetup.name,
                type: "ball_mid_diameter",
                typeText: "Ball Middle"
              })}
            </div>}
          {(product === ANDES_V2 || product === ROCKY) && compSetup.ball_type !== BALL_TYPE_NONE && <div className="aurora-extraction-ports-input-main aurora-extraction-ports-ball-material-main">
            <span>Ball Material</span>
            {
              <Fragment>
                {this.ballMaterialInput(compSetup.ball_material, ballMaterialVisible)}
                {ballMaterialVisible && compSetup.name === compTabMode ? this.ballMaterialPanel(compSetup.ball_material, compSetup.name, product) : null}
              </Fragment>
            }
          </div>}
        </Fragment>
        : null}
    </Fragment>
  }

  getInput = ({
    value,
    disabled,
    type,
    typeText,
    unit,
    unitOptions,
    className,
    component
  }) => {
    return getInputComponent({
      value,
      disabled,
      type,
      typeText,
      unit,
      unitOptions,
      className,
      component,
      inputOnBlur: this.inputOnBlur,
      changeInputValue: this.changeInputValue,
      addonAfter: this.addonAfter
    })
  }
}

export default AutoGenerationPort;
