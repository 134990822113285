
/**
 * Do not distinguish between numbers and strings
 * 
 * @param {Array[String]} a
 * @param {Array[String]} b
 * @return {*} 
 */
export function equal(a, b) {
  const _a = [].concat(a), _b = [].concat(b);
  return _a.sort().toString() === _b.sort().toString();
}