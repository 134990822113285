import React, { Component } from "react";
import EditableTable from "../../../../components/EditableTable";
import { CloseOutlined, PlusSquareOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button, Divider } from 'antd';
import { editPartLibrary, getPartLibraryListBySearch } from "../../../../services/Sierra/library";
import { PART_REPEATER_LIBRARY } from "../../../../constants/libraryConstants";
import _ from 'lodash';
import RepeaterConnection from "./repeaterConnection";
import "./index.css";
import RepeaterModel from "./repeaterModel";
import { getRepeaterText } from "../../../../services/Sierra";

const columns = [{
  title: "Part Number",
  dataIndex: "partNumber",
  key: "partNumber",
  width: "30%",
  textWrap: 'word-break',
  ellipsis: true,
  sorter: (a, b) => a && a.partNumber && b && b.partNumber && a.partNumber.localeCompare(b.partNumber),
},
{
  title: "Position",
  dataIndex: "position",
  key: "position",
  textWrap: 'word-break',
  width: "10%",
  ellipsis: true,
},
{
  title: "Connection",
  dataIndex: "connections",
  key: "connections",
  textWrap: 'word-break',
  width: "30%",
  ellipsis: true,
},
{
  title: "Model",
  dataIndex: "model",
  key: "model",
  textWrap: 'word-break',
  width: "15%",
  ellipsis: true,
}]
class RepeaterLibrary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subcktList: []
    }

    columns[0].title = () => {
      return <div>
        <span className="sierra-part-repeater-part-title">Part Number</span>
        {/*  <Tooltip
          overlayClassName="icon-tooltip"
          title="Add New Part Number">
          <Icon
            type="plus-square"
            className="sierra-part-library-add-icon"
            onClick={(e) => this.addNewPart(e)}
          />
        </Tooltip> */}
      </div>
    }

    columns[0].onCell = (record) => {
      return {
        edit: true,
        dataIndex: "partNumber",
        record,
        handleSave: this.editPartNumber
      }
    }

    columns[0].render = (text, record) => {
      return (
        <div className="sierra-part-library-table-part-text">
          <span title={text}>{text}</span>
          <Tooltip
            overlayClassName="icon-tooltip"
            title="Delete the partNumber."
          >
            <CloseOutlined
              className="sierra-part-library-delete-icon"
              onClick={(e) => this.deletePart(e, record)} />
          </Tooltip>
        </div>
      );
    }


    columns[1].render = (text, record) => {
      return (
        <div className="sierra-part-library-table-part-text">
          <span title={text}>{text}</span>
          <Tooltip
            overlayClassName="icon-tooltip"
            title={"Add New Position"}
          >
            <PlusSquareOutlined
              onClick={(e) => this.addNewPosition(e, record)}
              className="sierra-part-library-position-add-icon" />
          </Tooltip>
        </div>
      );
    }

    columns[2].render = (text, record) => {
      const pinMapText = this.getPinMapText(record)
      return <div className='part-repeater-pin-library-cell' title={pinMapText}>
        <span className='part-repeater-pin-library-value'>
          {pinMapText}
        </span>
      </div>;
    }

    columns[2].onCell = (record) => {
      const { partNumber, connections = [], position, model } = record;
      return {
        edit: true,
        customInput: RepeaterConnection,
        record,
        dataIndex: 'connections',
        partNumber,
        connections: connections,
        position: position,
        model,
        saveConnectionInfo: this.saveConnectionInfo
      }
    }

    columns[3].render = (text, record) => {
      const { checkFiles } = this.props;
      const modelText = getRepeaterText({ ...record, model: typeof (record.model) !== "object" ? {} : record.model });
      const notExistFiles = record.model ? (record.model.files || []).filter(item => !item.folder && checkFiles.includes(item.fileName)).map(item => item.fileName) : []
      return (
        <div className="sierra-part-library-table-text">
          <span title={modelText} style={{ paddingRight: notExistFiles && notExistFiles.length ? 16 : 0 }}>{modelText}</span>
          {notExistFiles && notExistFiles.length ? <Tooltip
            overlayClassName="icon-tooltip"
            title={`This ${notExistFiles.length === 1 ? "file" : "files"} ${notExistFiles.join(", ")} ${notExistFiles.length === 1 ? "is" : "are"} not uploaded to the library.`}>
            <QuestionCircleOutlined className="sierra-part-library-help-icon" />
          </Tooltip> : null}
        </div>
      );
    }

    columns[3].onCell = (record) => {
      if (!record.partNumber) {
        return {
          edit: false
        }
      }
      const { maxHeight, maxWidth, libraryTreeInfo } = this.props;
      const modelText = getRepeaterText({ ...record, model: typeof (record.model) !== "object" ? {} : record.model });
      return {
        edit: true,
        dataIndex: "model",
        record,
        text: modelText,
        partNumber: record.partNumber,
        position: record.position,
        maxHeight,
        maxWidth,
        libraryTreeInfo,
        customInput: RepeaterModel,
        saveModelInfo: this.saveModelInfo
      }
    }
  }

  addNewPart = (e) => {
    e && e.stopPropagation()
    const { data } = this.props
    let _data = [...data];
    if (!!_data.find(item => !item.id)) {
      return;
    }
    const itemData = {
      id: "",
      partNumber: "",
      connections: [],
      position: "",
      model: { files: [], pairs: [] },
    }
    _data.unshift(itemData)
    this.setState({
      subcktVisible: false,
      subcktList: []
    })
    this.props.saveData({ data: _data })
  }

  deletePart = (e, record) => {
    e && e.stopPropagation()
    const { data } = this.props
    let _data = [...data];
    const index = _data.findIndex(item => item.partNumber === record.partNumber && (!record.position || record.position === item.position));
    if (index < 0) {
      return;
    }
    const delPart = record.id ? { ..._data[index] } : null;
    _data.splice(index, 1)
    this.setState({
      componentVisible: false,
      ibisComponentList: []
    })
    this.props.saveData({ data: _data, delPart })
  }

  addNewPosition = async (e, record) => {
    e && e.stopPropagation();
    this.props.updateStatus(true, "saving");
    const { data, prevRepeaterList } = this.props;
    let _data = [...data], newIndex = -1;
    const index = _data.findIndex(item => item.partNumber === record.partNumber);
    const modelList = await getPartLibraryListBySearch({ partNumbers: [record.partNumber], type: PART_REPEATER_LIBRARY });

    let position;
    if (modelList && modelList.length === 1) {
      if (_data[index].position !== "1") {
        //update prev list position
        let _prevRepeaterList = [...prevRepeaterList];
        const prevIndex = _prevRepeaterList.findIndex(item => item.partNumber === record.partNumber && item.position === _data[index].position);
        if (prevIndex > -1) {
          _prevRepeaterList[prevIndex].position = "1";
          this.props.updatePrevRepeaterList(_prevRepeaterList);
        }

        _data[index].position = "1";
        await editPartLibrary({ type: PART_REPEATER_LIBRARY, editedParts: [{ ..._data[index] }] });
      }
      position = "2";
      newIndex = index;
    } else {
      position = 1;
      let dataIndex = -1;
      for (let item of _data) {
        dataIndex += 1;
        if (item.partNumber !== record.partNumber) {
          continue;
        }
        item.position = `${position}`;
        if (item.position !== `${position}`) {
          await editPartLibrary({ type: PART_REPEATER_LIBRARY, editedParts: [{ ...item }] });
        }

        position += 1;
        newIndex = dataIndex;
      }
    }

    const editPart = {
      partNumber: record.partNumber,
      position: position.toString(),
      connections: [],
      model: {
        files: record.model && record.model.files && record.model.files.length ? JSON.parse(JSON.stringify(record.model.files)) : [],
        pairs: []
      },
    };

    const res = await editPartLibrary({ type: PART_REPEATER_LIBRARY, editedParts: [{ ...editPart }] });
    const id = res && res[0] ? res[0].id : null;
    if (!id) {
      return;
    }
    editPart.id = id;
    _data.splice(newIndex + 1, 0, editPart)
    this.props.saveData({ data: _data })
    this.props.updateStatus(true, "repeaterUpdate")
    this.props.updateStatus(true)
    this.props.updateStatus(false, "saving");

  }

  editPartNumber = async (record, prevRecord) => {
    const { data } = this.props;
    if (prevRecord.partNumber === record.partNumber) {
      return
    }
    let _data = [...data];
    const index = _data.findIndex(item => (prevRecord.partNumber && item.partNumber === prevRecord.partNumber)
      || (!prevRecord.partNumber && !item.id && !item.partNumber));
    if (index < 0) {
      return;
    }
    const modelList = await getPartLibraryListBySearch({ partNumbers: [record.partNumber], type: PART_REPEATER_LIBRARY });
    const findModel = (modelList || []).find(item => item.partNumber === record.partNumber);
    if (findModel) {
      this.props.updateMessage("This part number already exists.")
      return;
    }

    _data[index].partNumber = record.partNumber;
    this.setState({
      subcktVisible: false,
      subcktList: []
    })
    this.props.saveData({ data: _data, editedPart: { ..._data[index] }, index }, PART_REPEATER_LIBRARY)
  }

  saveModelInfo = (record) => {
    const { data } = this.props;
    const { partNumber, position, model } = record
    let _data = [...data];
    const index = _data.findIndex(item => item.partNumber === partNumber && (!position || item.position === position));
    if (index < 0) {
      return;
    }
    if (_.isEqual((_data[index].model || {}), model)) {
      return
    }
    _data[index].model = model;
    this.props.saveData({ data: _data, editedPart: { ..._data[index] } }, PART_REPEATER_LIBRARY)
  }

  saveConnectionInfo = ({ partNumber, connections, position, model }) => {
    const { data } = this.props;
    let _data = [...data];
    const index = _data.findIndex(item => item.partNumber === partNumber && (!position || item.position === position));
    if (index < 0) {
      return;
    }
    if (_.isEqual(_data[index].connections, connections)) {
      return
    }

    _data[index].connections = connections;
    _data[index].model = model;
    this.props.saveData({ data: _data, editedPart: { ..._data[index] } }, PART_REPEATER_LIBRARY)
  }

  getPinMapText = (record) => {
    let text = "";
    for (let item of record.connections || []) {
      if (!item.input || !item.input.length || !item.output || !item.output.length) {
        continue;
      }
      text += text ? `, (${item.input[0]}, ${item.output[0]})` : `(${item.input[0]}, ${item.output[0]})`;
    }
    return text;
  }

  render = () => {
    const { data, pageSize, page, totalElements } = this.props;
    return <div>
      <div className="part-library-menu-list">
        {this.props.uploadExcelRender()}
        <Divider type="vertical" />
        <Button type="link" className="part-library-add-button" onClick={(e) => this.addNewPart(e)}>Add Part Number</Button>
        <Divider type="vertical" />
        {this.props.checkFileButton()}
      </div>
      <div>
        <EditableTable
          columns={columns}
          dataSource={data || []}
          rowKey={(record) => `${record.partNumber}-${record.position}`}
          size="small"
          pagination={{
            position: "bottom",
            pageSize,
            current: page,
            hideOnSinglePage: true,
            onChange: this.props.onPageChange,
            total: totalElements /* totalPage * pageSize */
          }}
        />
      </div>
    </div>
  }
}

export default RepeaterLibrary;