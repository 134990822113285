import React, { Component, createRef } from 'react';
import NetListModel from './NetListModel';
import { getDefaultModelsObj, getDefaultPortSetting, getPinRightDisplay, clearPairsPortsByType, getModelTypePins, getAutoFillNode } from '../../services/helper/packageModelHelper';
import { ANDES_V2, ROCKY } from '../../constants/pageType';
import { getDefaultIndex } from '../../services/helper/setDefaultName';
import { autoSelectEBDPorts, findSelectedEBDPorts } from '@/services/helper/ebdHelper';
import { numberCheck } from '@/services/helper/dataProcess';
import { N_PORT } from '../../services/VirtualComponent';
import './index.css';

const TOUCHSTONE = "Touchstone", SPICE = "SPICE", EBD = 'EBD', PreLayout = 'PreLayout';
function filesInfo({ fileName = "", libraryId = "", subckt = "", type = "", version = "", modelKey = "" }) {
  return { fileName, libraryId, subckt, type, version, modelKey };
}

class SetPKGNodeModel extends Component {
  constructor(props) {
    super(props);
    const { pkg, pins, signals, newModelsObj, modelType, product } = props;
    const pairs = pkg.pairs && pkg.pairs.length > 0 ? this.getPairs(pkg.pairs, pkg.rank) : this.getPairs();
    const { modelInfoList, newModelFilesObj } = getDefaultModelsObj({ pins, signals, newModelsObj, startModelType: modelType });
    let files = pkg.files && pkg.files.length > 0 ? [...pkg.files] : [];

    this.updateFilesInfo(files, pairs)

    this.state = {
      //type: modelType,// Repeater / SPICE(todo) / "Package"(todo)
      files: files,
      pairs: pairs, //{ pin:"", node:"", subckt:"", libraryId:""}
      // pinList: this.getPins(pairs),
      pinList: getModelTypePins(pairs, pins, modelType, signals, product, pkg.rank, pkg.termination),
      subcktList: [], //[ { libraryId:"", fileName, models:[{ name:"", ports:[ port:"", info:"" , ...] } ], } ]
      portsObj: {}, //{ libraryId: [{ fileName, type, subckt, ports:[{ port:"",info:"", select: true/false}] } }] }
      errorMsg: null,
      top: 0,
      modelInfoList,
      newModelFilesObj,
      rank: pkg && pkg.rank ? pkg.rank : null,
      termination: this.getTermination(pkg.termination),
      topology: pkg && pkg.topology ? pkg.topology : "series", // series / parallel
    };
    this.inputRef = createRef();
  }

  updateFilesInfo = (files, pairs) => {
    // Add modelKey to data
    let _files = [...files];
    let _pairs = [...pairs]
    const { product, modelType } = this.props;
    if (product === ROCKY && modelType === "Package" && _files.length) {
      let modelKeys = _files.map(item => item.modelKey);
      modelKeys = [...new Set([...modelKeys])]
      _files.forEach(item => {
        if (!item.modelKey) {
          const modelKey = getDefaultIndex(modelKeys.length, modelKeys)
          item.modelKey = modelKey;
          modelKeys = [...modelKeys, modelKey]
        }
        //add new file select
      })

      _pairs.forEach(pair => {
        if (!pair.modelKey) {
          const currentInfo = _files.find(item => item.libraryId === pair.libraryId)
          if (currentInfo) {
            pair.modelKey = currentInfo.modelKey
          }
        }
      })
    }
    return { _files, _pairs };
  }

  updatePKGModelInfo = () => {
    const { pkg, pins, signals, newModelsObj, modelType, product } = this.props;
    const { rank } = this.state;
    const pairs = pkg.pairs && pkg.pairs.length > 0 ? this.getPairs(pkg.pairs) : this.getPairs();
    const { modelInfoList, newModelFilesObj } = getDefaultModelsObj({ pins, signals, newModelsObj, startModelType: modelType });
    let files = pkg.files && pkg.files.length > 0 ? [...pkg.files] : [];

    let modelFiles = pkg && pkg.files && pkg.files.length > 0 ? JSON.parse(JSON.stringify(pkg.files)) : [];
    for (let modelInfo of newModelsObj) {
      if (modelInfo.files && modelInfo.files.length) {
        modelFiles.push(...modelInfo.files)
      }
    }
    modelFiles.length && this.getFileParse(modelFiles);

    this.setState({
      files: files,
      pairs: pairs, //{ pin:"", node:"", subckt:"", libraryId:""}
      // pinList: this.getPins(pairs),
      pinList: getModelTypePins(pairs, pins, modelType, signals, product, rank, pkg.termination),
      errorMsg: null,
      top: 0,
      modelInfoList,
      newModelFilesObj
    });
  }

  switchRankSetting = (checked, e) => {
    let rank = null;
    if (checked) {
      rank = { number: "2", active: "1" };
      this.updatePairs(2);
    } else {
      this.updatePairs();
      this.setState({ errorMsg: "" });
    }
    this.setState({
      rank
    })
    this.props.setContentHeight(0);
  }

  checkRankNumber = (e) => {
    const { rank } = this.state;
    const { number, active } = rank;
    let error = numberCheck(number);
    error = error ? error : Number(number) < 1 ? 'Multi rank - rank number must be greater than 0' : '';
    this.setState({
      errorMsg: error ? `Multi rank - rank number ${error}` : ''
    })
    if (error) {
      e.target.focus();
    } else {
      if (Number(active) > Number(number)) {
        this.setState({
          rank: { ...rank, active: "1" }
        })
      }
      this.updatePairs(Number(rank.number));
    }
  }

  updatePairs = (number = null) => {
    let newPairs = [];
    const { pins, modelType, product, signals } = this.props;
    const { pairs, files, termination } = this.state;
    const num = number ? number : 1;
    pins.forEach(item => {
      for (let i = 0; i <= num; i++) {
        const pinName = i > 0 ? i === 1 ? `${item.pin}_u` : `${item.pin}_u_${i}` : item.pin;
        const pair = pairs.find(p => p.pin === pinName);
        newPairs.push(pair ? pair : { pin: pinName, node: "", subckt: "", libraryId: "", modelKey: "" });
      }
    });
    this.setState({
      pairs: newPairs,
      pinList: getModelTypePins(pairs, pins, modelType, signals, product, { number }, termination),
    }, () => {
      this.getFileParse(files, true);
    })
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    const { pkg, newModelsObj = [], modelType, product } = this.props;

    let modelFiles = pkg && pkg.files && pkg.files.length > 0 ? JSON.parse(JSON.stringify(pkg.files)) : [];

    for (let modelInfo of newModelsObj) {
      if (modelInfo.files && modelInfo.files.length) {
        modelFiles.push(...modelInfo.files)
      }
    }

    if (product === ANDES_V2 && modelType === "DIE") {
      const { termination = {} } = pkg;
      const { files = [] } = termination;
      modelFiles.push(...files);
    }

    modelFiles.length && this.getFileParse(modelFiles);

    //update pin node connect box top position
    this.props.setContentHeight(0);
  }

  getModelFilesExist = () => {
    const { modelInfoList } = this.state;
    if (modelInfoList && modelInfoList.length) {
      return true;
    }
  }

  getNetListModel = () => {
    const { files, pairs, rank, errorMsg, termination, topology } = this.state;
    return {
      files,
      pairs: Array.isArray(pairs) ? pairs : this.getPairs(),
      rank,
      isSave: errorMsg && errorMsg.includes("Multi rank") ? false : true,
      termination,
      topology
    }
  }

  getPreLayoutPairs = (prevPairs, pins) => {
    let pairs = [];
    pins.forEach(item => {
      const findLPrev = prevPairs.find(it => it.pin === item.pin && it.component === item.leftComp);
      const findRPrev = prevPairs.find(it => it.pin === item.rightPin && it.component === item.rightComp);
      if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
        let pinR = { pin: item.rightPin, node: "", subckt: "", libraryId: "", modelKey: "", component: item.rightComp };
        let pinL = { pin: item.pin, node: "", subckt: "", libraryId: "", modelKey: "", component: item.leftComp };
        if (findLPrev) {
          pinL = { ...pinL, ...findLPrev };
        }
        if (findRPrev) {
          pinR = { ...pinR, ...findRPrev };
        }
        pairs = [...pairs, pinL, pinR];
      }
    });
    return pairs;
  }

  getNPortPairs = (prevPairs, pins) => {
    let pairs = [];
    pins.forEach(item => {

      const findPrev = prevPairs.find(it => it.pin === item.pin);

      if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
        let pin = { pin: item.pin, node: "", subckt: "", libraryId: "", modelKey: "" };
        if (findPrev) {
          pin = { ...pin, ...findPrev };
        }
        pairs = [...pairs, pin];
      }
    });
    return pairs;
  }

  getPairs = (prevPairs = [], rank = {}) => {
    let pairs = [];
    const { pins, modelType } = this.props;
    const num = rank && rank.number ? rank.number : 1;
    if (modelType === PreLayout) { return this.getPreLayoutPairs(prevPairs, pins) }
    if (modelType === N_PORT) { return this.getNPortPairs(prevPairs, pins) }
    pins.forEach(item => {

      if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
        let pairList = []
        for (let i = 0; i <= num; i++) {
          const { pinName: rightPinSuffix } = getPinRightDisplay(modelType, item.pin);
          const pinName = i > 0 ? i === 1 ? rightPinSuffix : `${rightPinSuffix}_${i}` : item.pin;
          const pair = prevPairs.find(p => p.pin === pinName);
          const pinInfo = { pin: pinName, node: "", subckt: "", libraryId: "", modelKey: "" }
          pairList.push(pair ? { ...pinInfo, ...pair } : { ...pinInfo });
        }
        pairs = [...pairs, ...pairList];
      }
    });

    if (prevPairs && pairs && prevPairs.length !== pairs.length) {
      // When the number of signals changes, the modified data needs to be stored
      setTimeout(() => this.props.isPageDisplay && this.props.savePackageModelInfo(), 200)
    }
    return pairs;
  }

  getTermination = (termination = {}) => {
    const { pairs: prevPairs, files = [], type = "value" } = termination;
    let pairs = [];
    const { pins } = this.props;
    pins.forEach(item => {
      if (pairs.findIndex(pair => pair.pin === item.pin) < 0) {
        let pairList = []
        const pinName = item.pin;
        const pair = (prevPairs || []).find(p => p.pin === pinName);
        const pinInfo = { pin: pinName, node: "", subckt: "", libraryId: "", modelKey: "", r: "50 ohm" }
        pairList.push(pair ? { ...pinInfo, ...pair } : { ...pinInfo });
        pairs = [...pairs, ...pairList];
      }
    });

    if (prevPairs && pairs && prevPairs.length !== pairs.length) {
      // When the number of signals changes, the modified data needs to be stored
      setTimeout(() => this.props.isPageDisplay && this.props.savePackageModelInfo(), 200)
    }
    return {
      type,
      files,
      pairs
    };
  }

  onRef = (ref) => {
    this.modelChild = ref;
  }

  getFileParse = (files, isUpdate, fileType, isGetSubcktList) => {
    if (!files || files.length === 0) {
      return;
    }
    //filter libraryId not exist files
    const _files = files.filter(item => !!item.libraryId);
    const { portsObj, subcktList, pairs, pinList, rank } = this.state;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let _pairs = [...pairs], _pinList = [...pinList], isUpdatePairsPinInfo = false, autoFillInfo = null;
    const { autoSelectPorts, pins } = this.props;

    //get files ports
    return this.getFilesParseList(_files).then(res => {
      if (res && Array.isArray(res)) {
        _files.forEach(file => {
          const libraryId = file.libraryId;
          let _ports = [];
          const currentFileInfo = res.find(it => it && it.libraryId === libraryId);
          if (file.type === TOUCHSTONE) {
            _ports = currentFileInfo && Array.isArray(currentFileInfo.ports) ? currentFileInfo.ports : [];
            if (isUpdate && autoSelectPorts) {
              const clearInfo = clearPairsPortsByType({
                clearPinList: _pinList,
                clearPairs: _pairs,
                delFile: false,
                newFile: false
              })
              //  Only the package model of rocky supports automatic port matching
              const info = autoSelectPorts({ pairs: clearInfo.clearPairs, libraryId, pins, pinList: clearInfo.clearPinList, ports: _ports, modelKey: file.modelKey, rank });
              _pairs = info.pairs;
              _pinList = info.pinList;
              isUpdatePairsPinInfo = true;
            }

            //update file portsObj
            _portsObj[libraryId] = [{ fileName: file.fileName, type: TOUCHSTONE, ports: _ports }];
          } else if (file.type === SPICE) {
            const models = currentFileInfo && Array.isArray(currentFileInfo.models) ? [...currentFileInfo.models] : [];
            //update state subckt list
            const findIndex = _subcktList.findIndex(it => it.libraryId === libraryId);
            if (findIndex > -1) {
              _subcktList[findIndex] = {
                libraryId,
                fileName: file.fileName,
                models
              }
            } else {
              _subcktList.push({
                libraryId,
                fileName: file.fileName,
                models
              });
            }

            if (file.subckt) {
              const currentSubckt = models.find(item => item.name === file.subckt);
              let selectedPairs = pairs.filter(item => item.node && item.libraryId === libraryId && item.subckt === file.subckt);
              let selectedPorts = selectedPairs.map(item => item.node);
              //update ports
              if (currentSubckt && Array.isArray(currentSubckt.ports)) {
                currentSubckt.ports.forEach(item => {
                  _ports.push({
                    port: item,
                    info: item,
                    subckt: file.subckt,
                    select: selectedPorts.includes(item) ? true : false
                  })
                });
              }

              //update ports
              if (_portsObj[libraryId]) {
                //find current subckt ports
                const index = _portsObj[libraryId].findIndex(item => item.subckt === file.subckt);
                if (index > -1) {
                  //update current subckt ports
                  _portsObj[libraryId][index] = { fileName: file.fileName, type: SPICE, subckt: file.subckt, ports: _ports };
                } else {
                  //Add current subckt ports
                  _portsObj[libraryId] = [..._portsObj[libraryId], { fileName: file.fileName, type: SPICE, subckt: file.subckt, ports: _ports }];
                }
              } else {
                //Add current file and current subckt ports
                _portsObj[libraryId] = [{ fileName: file.fileName, type: SPICE, subckt: file.subckt, ports: _ports }];
              }
            }

            if (fileType === 'termination') {
              const subcktInfo = _subcktList.find(item => item.libraryId === file.libraryId);
              if (subcktInfo && subcktInfo.models.length === 1) {
                const { name } = subcktInfo.models[0];
                autoFillInfo = { modelKey: file.modelKey, subckt: name };
              }
            }
          } else if (file.type === EBD) {
            _ports = currentFileInfo && currentFileInfo.ports ? JSON.parse(JSON.stringify(currentFileInfo.ports)) : [];
            _ports = findSelectedEBDPorts({ ports: _ports, pairs: _pairs, libraryId });
            const rankList = this.props.getEBDRankList(libraryId);
            if (isUpdate) {
              const info = autoSelectEBDPorts({ ports: _ports, pairs: _pairs, libraryId, pinList: _pinList, rankList, updateModelKey: true, modelKey: file.modelKey });
              _pairs = info.pairs;
              _pinList = info.pinList;
              _ports = findSelectedEBDPorts({ ports: _ports, pairs: _pairs, libraryId });
              isUpdatePairsPinInfo = true;
            }
            if (_ports.length) {
              _portsObj[libraryId] = [{ fileName: file.fileName, type: EBD, ports: _ports }];
            }
          }
        });
        let matchLibInfo = this.autoMatchLibraryPins({ files: [...files], isUpdate: true, prePairs: _pairs, prePinList: _pinList });
        _pairs = matchLibInfo.pairs;
        _pinList = matchLibInfo.pinList;
        if (isUpdatePairsPinInfo) {
          this.setState({
            pairs: _pairs,
            pinList: _pinList
          })
        }
        this.setState({
          portsObj: _portsObj,
          subcktList: _subcktList
        }, () => {
          if (autoFillInfo) {
            const { subckt, modelKey } = autoFillInfo;
            this.selectSpiceSubckt({ label: subckt }, modelKey, fileType, _subcktList);
          }
        })

        if (isGetSubcktList) return _subcktList;
      }
    })
  }

  getFilesParseList = (files) => {
    const { getTouchstoneParse, getSPiceParse, getEBDParse } = this.props;
    const promiseList = files.map(item => {
      if (item.type === TOUCHSTONE) {
        return getTouchstoneParse(item.libraryId, item.fileName);
      } else if (item.type === SPICE) {
        return getSPiceParse && getSPiceParse(item.libraryId);
      } else if (item.type === EBD) {
        return getEBDParse(item.libraryId);
      }
      return null;
    }).filter(item => !!item);
    return Promise.all(promiseList);
  }

  saveModel = ({ pinList, pairs, files, termination }) => {
    const { newModelFilesObj } = this.state;
    const { modelType } = this.props;
    let _pinList = [...pinList], _pairs = [...pairs], _files = [...files];
    if (newModelFilesObj && newModelFilesObj[modelType]) {
      // If the selected pcb has the same settings;
      const allFiles = [...this.state.files, ...newModelFilesObj[modelType].allFiles]
      const newValueObj = this.getNewValue(files, this.state.files, allFiles, pinList, pairs)
      _pinList = newValueObj._pinList;
      _pairs = newValueObj._pairs;
      _files = newValueObj._files;
    }
    this.setState({
      pinList: _pinList,
      pairs: _pairs,
      files: _files,
      termination
    }, () => {
      this.props.isPageDisplay && this.props.savePackageModelInfo()
      this.props.setContentHeight(0);
    })
  }

  clearPinNode = (params, clearReuseFiles) => {
    const { fileType, delFile, newFile } = params ? params : {};
    const { pairs, pinList, modelInfoList, files: defaultFiles, termination } = this.state;
    const { modelType, pins, signals, product } = this.props;
    const { pairs: terminationPairs, files: terminationFiles } = termination;

    if (fileType && fileType !== 'termination') {
      // delete package
      let _modelInfoList = [];

      for (let modelInfo of modelInfoList) {
        let { pairs, modelPinList } = modelInfo
        const clearInfo = clearPairsPortsByType({
          clearPinList: modelPinList,
          clearPairs: pairs,
          delFile,
          newFile
        })
        _modelInfoList.push({ ...modelInfo, pairs: clearInfo.clearPairs, modelPinList: clearInfo.clearPinList })
      }
      this.setState({
        modelInfoList: _modelInfoList
      }, () => {
        if (newFile && newFile.libraryId) {
          this.getFileParse([newFile]);
        }
        this.props.isPageDisplay && this.props.savePackageModelInfo()
      })
    } else {
      let _pairs = pairs ? JSON.parse(JSON.stringify(pairs)) : this.getPairs();
      let _pinList = pinList ? JSON.parse(JSON.stringify(pinList)) : getModelTypePins([], pins, modelType, signals, product);
      let files = defaultFiles;

      if (product === ANDES_V2 && modelType === "DIE" && fileType === 'termination') {
        _pairs = terminationPairs ? JSON.parse(JSON.stringify(terminationPairs)) : this.getTermination().pairs;
        files = terminationFiles;
      }
      const clearInfo = clearPairsPortsByType({
        clearPinList: _pinList,
        clearPairs: _pairs,
        delFile,
        newFile,
        modelType,
        product,
        clearTerminationPairs: terminationPairs
      });
      _pinList = clearInfo.clearPinList;
      _pairs = clearInfo.clearPairs;

      if (modelInfoList.length) {
        const findIndex = modelInfoList.findIndex(item => item.modelType === modelType)
        if (findIndex > -1) {
          let _modelInfoList = [...modelInfoList]
          let { pairs, modelPinList } = modelInfoList[findIndex]
          const clearInfo = clearPairsPortsByType({
            clearPinList: modelPinList,
            clearPairs: pairs,
            delFile,
            newFile
          })
          _modelInfoList[findIndex] = { ...modelInfoList[findIndex], pairs: clearInfo.clearPairs, modelPinList: clearInfo.clearPinList }
          this.setState({
            modelInfoList: _modelInfoList,
          })
        }
      }

      let newFiles = [], newTerminationFiles = []
      if (clearReuseFiles) {
        // Delete all reused files when clicking the clear button
        let libraryIdList = []
        for (let file of files) {
          if (!libraryIdList.includes(file.libraryId)) {
            libraryIdList.push(file.libraryId)
            newFiles.push(file)
          }
        }

        libraryIdList = []
        for (let file of terminationFiles) {
          if (!libraryIdList.includes(file.libraryId)) {
            libraryIdList.push(file.libraryId)
            newTerminationFiles.push(file)
          }
        }

      } else {
        newFiles = [...files];
        newTerminationFiles = [...terminationFiles];
      }
      if (product === ANDES_V2 && modelType === "DIE" && fileType === 'termination') {
        this.setState({
          pinList: _pinList,
          termination: {
            ...termination,
            pairs: _pairs,
            files: [...newFiles]
          }
        }, () => {
          if (newFile && newFile.libraryId) {
            this.getFileParse([newFile], true, fileType);
          }
          this.props.isPageDisplay && this.props.savePackageModelInfo()
        })
      } else {
        this.setState({
          pairs: _pairs,
          pinList: _pinList,
          files: [...newFiles],
          // clear all ports
          termination: {
            ...termination,
            pairs: clearInfo.clearTerminationPairs,
            files: newTerminationFiles
          }
        }, () => {
          if (newFile && newFile.libraryId) {
            this.getFileParse([newFile], true);
          }
          this.props.isPageDisplay && this.props.savePackageModelInfo()
        })
      }
    }
  }

  autoMatchLibraryPins = ({ files, isUpdate, prePairs, prePinList }) => {
    const { pairs, pinList } = this.state;
    const { libraryInfoList } = this.props;
    if (!files || files.length === 0 || !libraryInfoList || libraryInfoList.length === 0) {
      return { pairs: prePairs, pinList: prePinList };
    }

    const _files = files.filter(item => !!item.libraryId);
    const findLibraries = libraryInfoList.filter(item => item.file === _files[0].fileName);

    if (!findLibraries.length) {
      if (_files[0].type !== TOUCHSTONE) {
        const clearInfo = clearPairsPortsByType({
          clearPinList: prePinList,
          clearPairs: prePairs,
          delFile: false,
          newFile: false
        });
        return { pairs: clearInfo.clearPairs, pinList: clearInfo.clearPinList };
      } else {
        return { pairs: prePairs, pinList: prePinList };
      }
    }
    const _pairs = JSON.parse(JSON.stringify(pairs));
    const _pinList = JSON.parse(JSON.stringify(pinList));
    let matchMoreLibrary = null;
    let matchCount = 0;
    const pins = new Set(_pairs.map(item => item.pin));
    //use the most matched library
    findLibraries.forEach(item => {
      let count = item.pairs.reduce((acc, pair) => {
        if (pins.has(pair.pin)) {
          return acc + 1;
        }
        return acc;
      }, 0);
      if (count > matchCount) {
        matchCount = count;
        matchMoreLibrary = item;
      }
    })
    if (!matchMoreLibrary) {
      return { pairs: prePairs, pinList: prePinList };
    }
    const matchPairs = matchMoreLibrary.pairs;

    _pairs.forEach(pair => {
      const findPair = matchPairs.find(item => item.pin === pair.pin);
      if (findPair) {
        pair.node = findPair.node;
        pair.libraryId = _files[0].libraryId;
        pair.modelKey = _files[0].modelKey;
      }
    });

    _pinList.forEach(pin => {
      if (pin.pinLeft[0]) {
        const leftPair = _pairs.find(pair => pair.pin === pin.pinLeft[0].pin);
        if (leftPair) {
          pin.pinLeft[0].pinValue = leftPair.node;
        }
      }
      if (pin.pinRight[0]) {
        const rightPair = _pairs.find(pair => pair.pin === pin.pinRight[0].pin);
        if (rightPair) {
          pin.pinRight[0].pinValue = rightPair.node;
        }
      }
    });

    return { pairs: _pairs, pinList: _pinList };
  }

  selectFile = (key, modelKey, fileType, isAutoMatchPort, isReuseUse) => {
    if (!key) {
      return;
    }
    const { key: id, label: name, subckt } = key;
    const {
      touchstoneList,
      spiceList,
      packageList,
      connectorList,
      cableList,
      pcbList,
      ebdList,
      pkgType } = this.props;

    const { files, portsObj, subcktList, newModelFilesObj, termination } = this.state;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let libraryList = [...touchstoneList, ...spiceList];
    const libraryObj = {
      packageList,
      connectorList,
      cableList,
      pcbList
    }
    let _files = fileType === 'termination' ? JSON.parse(JSON.stringify(termination.files || [])) : JSON.parse(JSON.stringify(files || []));

    if (pkgType === EBD) {
      libraryList = [...ebdList];
    } else if (fileType && fileType !== 'termination') {
      libraryList = libraryObj[`${fileType.toLowerCase()}List`] || [];
      _files = JSON.parse(JSON.stringify(newModelFilesObj[fileType].allFiles || []))
    }
    const index = _files.findIndex(item => item.modelKey === modelKey);
    let delFile = index > -1 ? _files[index] : null;
    let newFile = null;

    const findF = libraryList.find(it => it.id === id);
    if (findF) {
      let type = pkgType === EBD ? EBD : TOUCHSTONE;

      if (findF.type === "spice" || (findF.fileType && findF.fileType.match('spice'))) {
        type = SPICE;
        if (!_portsObj[findF.id]) {
          _portsObj[findF.id] = [];
        }
      } else {
        _portsObj[findF.id] = [];
      }

      newFile = filesInfo({ fileName: findF.name || name, libraryId: findF.id, type, modelKey, subckt: (isAutoMatchPort || isReuseUse) && subckt ? subckt : "" });
    }

    if (!newFile) {
      return;
    }

    this.setNewFile(modelKey, newFile, delFile, _files, _portsObj, _subcktList, fileType, isReuseUse);
  }

  selectByFolder = (file, modelKey, fileType) => {
    //file:{id,name,type}, modelKey, fileType
    if (!file) {
      return;
    }
    const { id, name, type } = file;
    const { files, portsObj, subcktList } = this.state;
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let _files = files ? JSON.parse(JSON.stringify(files)) : [];

    const index = _files.findIndex(item => item.modelKey === modelKey);
    let delFile = index > -1 ? _files[index] : null;
    let newFile = null;

    let _type = TOUCHSTONE;

    if (type.match(/spice/i)) {
      _type = SPICE;
      if (!_portsObj[id]) {
        _portsObj[id] = [];
      }
    } else {
      _portsObj[id] = [];
    }

    newFile = filesInfo({ fileName: name, libraryId: id, type: _type, modelKey });


    if (!newFile) {
      return;
    }

    this.setNewFile(modelKey, newFile, delFile, _files, _portsObj, _subcktList, fileType);
  }

  addFileToFiles = (modelKey, files, newFile, delFile) => {
    let _files = JSON.parse(JSON.stringify(files))
    const index = _files.findIndex(item => item.modelKey === modelKey);
    if (index > -1) {
      _files[index] = newFile;
      _files = delFile && delFile.libraryId ? _files.filter(item => item.libraryId !== delFile.libraryId) : _files;
    } else {
      _files = delFile && delFile.libraryId ? _files.filter(item => item.libraryId !== delFile.libraryId) : _files;
      _files.push(newFile);
    }
    return _files
  }

  // isReuseUse: s1p/s2p Reusing the same file requires saving as different Modelkeys
  setNewFile = (modelKey, newFile, delFile, _files, _portsObj, _subcktList, fileType, isReuseUse) => {
    const { modelType } = this.props;
    const find = _files.find(item => ([TOUCHSTONE, EBD].includes(newFile.type)) && item.libraryId === newFile.libraryId && item.modelKey !== modelKey);
    if (find && !isReuseUse) {
      let errorMsg = "Selecting the same model is not allowed here. To do it, please click the gray boxes with signal names.";
      if (find.type !== TOUCHSTONE) {
        errorMsg = "The current file has been selected.";
      }
      if (find)
        this.setState({
          errorMsg
        }, () => {
          //update pin node connect box top position
          this.props.setContentHeight(0);
        })
      return;
    }

    const { newModelFilesObj } = this.state;
    let currentTypeFileInfo = {};
    if (fileType && newModelFilesObj[fileType]) {
      currentTypeFileInfo = JSON.parse(JSON.stringify(newModelFilesObj[fileType] || {}))
    } else if (newModelFilesObj[modelType]) {
      currentTypeFileInfo = JSON.parse(JSON.stringify(newModelFilesObj[modelType] || {}))
    }

    _files = this.addFileToFiles(modelKey, _files, newFile, delFile)
    const keys = Object.keys(currentTypeFileInfo)
    if (keys && keys.length) {
      for (let key of keys) {
        const currentFiles = currentTypeFileInfo[key];
        currentTypeFileInfo[key] = this.addFileToFiles(modelKey, currentFiles, newFile, delFile)
      }
    }

    //delete prev select file portsObj and subcktList
    if (delFile && delFile.libraryId) {
      const findList = _files.filter(item => item.libraryId === delFile.libraryId);
      if (findList.length === 0) {
        //delete portsObj
        delete _portsObj[delFile.libraryId];
        //delete subckt
        _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
      } else {
        //delete current delete file current subckt ports
        _portsObj[delFile.libraryId] = delFile.subckt ? _portsObj[delFile.libraryId].filter(item => (item.subckt !== delFile.subckt)) : _portsObj[delFile.libraryId];
      }
    }
    const { files, termination } = this.state;
    let stateFiles = [...files], _newModelFilesObj = { ...newModelFilesObj };

    if (fileType && fileType !== 'termination') {
      _newModelFilesObj[fileType] = currentTypeFileInfo;
    } else {
      stateFiles = _files;
      if (_newModelFilesObj && _newModelFilesObj[modelType]) {
        _newModelFilesObj[modelType] = currentTypeFileInfo;
      }
    }

    if (fileType === 'termination') {
      this.setState({
        termination: { ...termination, files: stateFiles },
        portsObj: _portsObj,
        subcktList: _subcktList,
        errorMsg: null
      }, () => {
        this.clearPinNode({ delFile, newFile, fileType });
        this.props.setContentHeight(0);
      });
    } else {
      this.setState({
        files: stateFiles,
        portsObj: _portsObj,
        subcktList: _subcktList,
        errorMsg: null,
        newModelFilesObj: _newModelFilesObj
      }, () => {
        this.clearPinNode({ delFile, newFile, fileType });
        this.props.setContentHeight(0);
      });
    }
  }

  deleteFileFromFiles = (deleteLine, files, delFile, modelKey) => {
    let _files = JSON.parse(JSON.stringify(files))
    if (deleteLine) {
      //delete current file line
      if (delFile && delFile.type === SPICE && delFile.libraryId) {
        // _files = _files.filter(item => !(item.modelKey === delFile.modelKey && item.libraryId === delFile.libraryId))
        _files = _files.filter(item => !(((!item.subckt && !delFile.subckt) || (item.subckt === delFile.subckt)) && item.libraryId === delFile.libraryId))
      } else {
        _files = delFile && delFile.libraryId ?
          _files.filter(item => item.libraryId !== delFile.libraryId)
          : _files.filter(item => item.modelKey !== delFile.modelKey);
      }
    } else {
      //delete current file
      const index = _files.findIndex(item => item.modelKey === modelKey);
      _files[index] = filesInfo({ modelKey });
      if (delFile && delFile.type === SPICE && delFile.libraryId) {
        _files = _files.filter(item => !(((!item.subckt && !delFile.subckt) || (item.subckt === delFile.subckt)) && item.libraryId === delFile.libraryId))
      } else {
        _files = delFile && delFile.libraryId ?
          _files.filter(item => item.libraryId !== delFile.libraryId)
          : _files;
      }

    }
    return _files
  }

  delFileSelect = (type, modelKey, fileType, deleteLine, delLibraryId) => {
    const { portsObj, files, subcktList, newModelFilesObj, termination } = this.state;
    const { modelType } = this.props;
    let _files = fileType === 'termination' ? JSON.parse(JSON.stringify(termination.files || [])) : JSON.parse(JSON.stringify(files || []));
    let _portsObj = { ...portsObj }, _subcktList = [...subcktList];
    let stateFiles = [...files], _newModelFilesObj = { ...newModelFilesObj };
    if (!modelKey) {
      return;
    }
    let currentTypeFileInfo = JSON.parse(JSON.stringify(newModelFilesObj[modelType] || {}))
    if (fileType && fileType !== 'termination') {
      _files = JSON.parse(JSON.stringify(newModelFilesObj[fileType].allFiles || []));
      currentTypeFileInfo = JSON.parse(JSON.stringify(newModelFilesObj[fileType] || {}))
    } else if (newModelFilesObj && newModelFilesObj[modelType]) {
      const newModelFilesObjFile = JSON.parse(JSON.stringify(newModelFilesObj[modelType].allFiles || []));
      if (newModelFilesObjFile && newModelFilesObjFile.length) {
        _files = [..._files, ...newModelFilesObjFile];
      }
    }
    if (type !== "Subckt") {
      //delete file
      const delFile = _files.find(item => item.modelKey === modelKey && (!delLibraryId || (delLibraryId && item.libraryId === delLibraryId)));

      if (delFile && delFile.libraryId) {
        const otherFiles = _files.filter(item => modelKey !== item.modelKey && item.libraryId === delFile.libraryId);
        if (otherFiles.length === 0) {
          delete _portsObj[delFile.libraryId];
          _subcktList = _subcktList.filter(item => item.libraryId !== delFile.libraryId);
        } else if (delFile.subckt) {
          _portsObj[delFile.libraryId] = _portsObj[delFile.libraryId].filter(item => item.subckt !== delFile.subckt);
        }
      }
      _files = this.deleteFileFromFiles(deleteLine, _files, delFile, modelKey)

      const keys = Object.keys(currentTypeFileInfo)
      if (keys && keys.length) {
        for (let key of keys) {
          const currentFiles = currentTypeFileInfo[key];
          currentTypeFileInfo[key] = this.deleteFileFromFiles(deleteLine, currentFiles, delFile, modelKey)
        }
      }

      if (fileType && fileType !== 'termination') {
        _newModelFilesObj[fileType] = currentTypeFileInfo;
      } else {
        let cleanModelKeyList = [];
        stateFiles = _files.filter(item => {
          if (!item.libraryId) {
            if (cleanModelKeyList.includes(item.modelKey)) { return false }
            cleanModelKeyList.push(item.modelKey)
          }
          return item
        });
        if (_newModelFilesObj && _newModelFilesObj[modelType]) {
          _newModelFilesObj[modelType] = currentTypeFileInfo;
        }
      }

      if (fileType === 'termination') {
        this.setState({
          termination: {
            ...termination,
            files: stateFiles
          },
          newModelFilesObj: _newModelFilesObj,
          portsObj: _portsObj,
          subcktList: _subcktList,
          errorMsg: null,
        }, () => {
          this.clearPinNode({ delFile, fileType });
        })
      } else {
        this.setState({
          files: stateFiles,
          newModelFilesObj: _newModelFilesObj,
          portsObj: _portsObj,
          subcktList: _subcktList,
          errorMsg: null,
        }, () => {
          deleteLine && this.props.setContentHeight(0);
          this.clearPinNode({ delFile, fileType });
        })
      }

    } else {
      if (!modelKey) {
        return;
      }
      //delete current file subckt
      const index = _files.findIndex(item => item.modelKey === modelKey);
      const delFile = _files[index] ? { ..._files[index] } : null;
      const libraryId = _files[index].libraryId;
      _portsObj[libraryId] = _portsObj[libraryId].filter(item => item.subckt !== _files[index].subckt);
      _files[index].subckt = "";
      // Filter all files with the same file and the same subckt
      _files = _files.filter(item => !(item.modelKey !== modelKey && item.libraryId === libraryId && item.subckt === delFile.subckt))
      stateFiles = _files;

      if (fileType === 'termination') {
        this.setState({
          termination: {
            ...termination,
            files: stateFiles
          },
          portsObj: _portsObj,
          errorMsg: null
        }, () => {
          this.clearPinNode({ delFile, fileType });
        })
      } else {
        this.setState({
          files: stateFiles,
          portsObj: _portsObj,
          errorMsg: null
        }, () => {
          this.clearPinNode({ delFile, fileType });
        })
      }
    }
  }

  addNewFileSelect = (fileType, isSelectFile, selectedFile, isAutoMatchPort) => {
    const { files, newModelFilesObj, termination } = this.state;
    const { modelType } = this.props;
    let _files = fileType === 'termination' ? JSON.parse(JSON.stringify(termination.files || [])) : JSON.parse(JSON.stringify(files || [])), stateFiles = files || [], modelTypeFiles = [];
    let _newModelFilesObj = { ...newModelFilesObj };
    let currentTypeFileInfo = JSON.parse(JSON.stringify(newModelFilesObj[modelType] || {}))
    if (fileType && fileType !== 'termination') {
      _files = _newModelFilesObj[fileType] ? JSON.parse(JSON.stringify(_newModelFilesObj[fileType].allFiles || [])) : []
      currentTypeFileInfo = JSON.parse(JSON.stringify(newModelFilesObj[fileType] || {}))
    } else {
      modelTypeFiles = _newModelFilesObj[modelType] ? JSON.parse(JSON.stringify(_newModelFilesObj[modelType].allFiles || [])).map(item => item.modelKey) : [];
    }

    if (_files.length === 0 && !isSelectFile) {
      //default one select
      _files.push(filesInfo({ modelKey: "1" }));
    }

    let modelKeys = _files.map(item => item.modelKey);
    modelKeys = [...new Set([...modelKeys, ...modelTypeFiles])]
    const index = modelKeys.length ? modelKeys.length : 1;
    const modelKey = getDefaultIndex(index, modelKeys)
    //add new file select
    _files.push(filesInfo({ modelKey }))

    const keys = Object.keys(currentTypeFileInfo)
    if (keys && keys.length) {
      for (let key of keys) {
        let currentFiles = currentTypeFileInfo[key];
        if (currentFiles.length === 0) {
          currentFiles.push(filesInfo({ modelKey: "1" }))
        }
        currentFiles.push(filesInfo({ modelKey }))
        currentTypeFileInfo[key] = currentFiles;
      }
    }

    if (fileType && fileType !== 'termination') {
      _newModelFilesObj[fileType] = currentTypeFileInfo;
    } else {
      stateFiles = _files;
      if (_newModelFilesObj && _newModelFilesObj[modelType]) {
        _newModelFilesObj[modelType] = currentTypeFileInfo;
      }
    }

    if (fileType === 'termination') {
      this.setState({
        termination: {
          ...termination,
          files: stateFiles
        },
        errorMsg: null
      }, () => {
        this.props.setContentHeight(0);
        this.props.isPageDisplay && this.props.savePackageModelInfo()
        if (isSelectFile && selectedFile) {
          this.selectFile(selectedFile, modelKey, fileType, isAutoMatchPort)
        }
      });
    } else {
      this.setState({
        files: stateFiles,
        newModelFilesObj: _newModelFilesObj,
        errorMsg: null
      }, () => {
        this.props.setContentHeight(0);
        this.props.isPageDisplay && this.props.savePackageModelInfo()
        if (isSelectFile && selectedFile) {
          this.selectFile(selectedFile, modelKey, fileType, isAutoMatchPort)
        }
      });
    }
  }

  _updateModelObj = ({ modelInfoList, newModelFilesObj }) => {
    this.setState({
      modelInfoList: modelInfoList !== undefined ? modelInfoList : this.state.modelInfoList,
      newModelFilesObj: newModelFilesObj !== undefined ? newModelFilesObj : this.state.newModelFilesObj
    }, () => {
      const { isPageDisplay } = this.props;
      if (isPageDisplay) {
        this.props.savePackageModelInfo()
      }
    })
  }

  getNewValue = (files, oldFiles, allFiles, pinList, pairs) => {
    const addFiles = files.filter(item => !oldFiles.find(it => item.libraryId === it.libraryId));

    let _pinList = [...pinList], _pairs = [...pairs], _files = [...files];
    let oldModelKeyList = allFiles.map(item => item.modelKey);
    oldModelKeyList = [...new Set(oldModelKeyList)];
    if (addFiles && addFiles.length) {
      //The set modelList already exists
      //To modify the set modelKey
      for (let info of addFiles) {
        const { modelKey } = info;
        if (oldModelKeyList.includes(modelKey)) {
          const newModeKey = getDefaultIndex(oldModelKeyList.length, oldModelKeyList);

          _files.forEach(item => { if (item.modelKey === modelKey) { item.modelKey = newModeKey } })
          _pinList.forEach(item => {
            const { pinLeft, pinRight } = item;
            pinLeft.forEach(it => { if (it.pinModelKey === modelKey) { it.pinModelKey = newModeKey } })
            pinRight.forEach(it => { if (it.pinModelKey === modelKey) { it.pinModelKey = newModeKey } })
          })

          _pairs.forEach(item => {
            if (item.modelKey === modelKey) { item.modelKey = newModeKey }
          })
        }
      }
    }
    return { _pinList, _pairs, _files, addNewFiles: addFiles }
  }

  automaticMatchPort = (fileInfo, type, automaticInfo) => {
    if (type === "manual") {
      const fileType = this.props.modelType !== automaticInfo.modelType ? automaticInfo.modelType : "";
      const { files, newModelFilesObj } = this.state;
      let _files = files ? JSON.parse(JSON.stringify(files)) : [];
      if (fileType) {
        _files = newModelFilesObj[fileType] ? JSON.parse(JSON.stringify(newModelFilesObj[fileType].allFiles || [])) : []
      }
      const fileExist = _files.find(item => item.libraryId === fileInfo.libraryId);
      const findEmptyFile = _files.find(item => !item.libraryId && !item.fileName && item.modelKey);
      const modelKey = findEmptyFile ? findEmptyFile.modelKey : null;
      if (!fileExist) {
        if (modelKey) {
          this.selectFile({ key: fileInfo.libraryId, label: fileInfo.fileName, subckt: fileInfo.subckt }, modelKey, fileType, true);
          return
        }

        this.addNewFileSelect(fileType, true, { key: fileInfo.libraryId, label: fileInfo.fileName, subckt: fileInfo.subckt }, true)
      }
      return;
    }
    const { modelInfoList, newModelFilesObj, portsObj, files, pairs, pinList, rank, termination, topology } = this.state;
    const { pins, signals, modelType: _modelType, product } = this.props;
    let _modelInfoList = [...modelInfoList], _newModelFilesObj = { ...newModelFilesObj };
    let _files = [...files], _pairs = [...pairs], _pinList = [...pinList], lastPairs = [];

    const { modelType, modelFileKey } = automaticInfo;

    const index = modelInfoList.findIndex(item => item.modelFileKey === modelFileKey);
    if (!modelType) { return }
    let allFiles = [], portModelType = "";
    if (index > -1) {
      portModelType = 'moreModel'
      allFiles = [..._newModelFilesObj[automaticInfo.modelType].allFiles]
      lastPairs = [...modelInfoList[index].pairs]
    } else if (_modelType === automaticInfo.modelFileKey && _modelType === automaticInfo.modelType) {
      portModelType = _modelType
      const addFiles = _newModelFilesObj[_modelType] && _newModelFilesObj[_modelType].allFiles ? _newModelFilesObj[_modelType].allFiles.filter(item => !files.find(it => it.libraryId === item.libraryId && it.modelKey === item.modelKey)) : [];
      allFiles = [...files, ...addFiles];
      lastPairs = [...pairs]
    }
    let selectFileInfo = allFiles.find(item => item.libraryId === fileInfo.libraryId && (!fileInfo.subckt || (fileInfo.subckt && fileInfo.subckt === item.subckt)));
    if (!selectFileInfo) {
      let oldModelKeyList = allFiles.map(item => item.modelKey);
      oldModelKeyList = [...new Set(oldModelKeyList)];
      const newModeKey = getDefaultIndex(oldModelKeyList.length, oldModelKeyList);
      selectFileInfo = {
        ...fileInfo,
        modelKey: newModeKey === "0" ? "1" : newModeKey,
      }
    }

    let direction = (product === ROCKY && modelType === 'Package') || (product === ANDES_V2 && (modelType === 'Package' || modelType === 'DIE') && portModelType !== 'moreModel') ? 'opposite' : '';
    const determineComp = modelType === PreLayout ? true : false;
    const _currentModelInfo = getDefaultPortSetting({
      portsObj,
      selectFileId: fileInfo.libraryId,
      currentModelInfo: automaticInfo,
      pins,
      type,
      selectFileInfo,
      signals,
      portModelType,
      lastPairs,
      direction,
      determineComp,
      rank,
      isParallel: topology === 'parallel'
    })

    if (index > -1) {
      _modelInfoList[index] = _currentModelInfo;

      const { modelType, modelFileKey } = _currentModelInfo
      _newModelFilesObj[modelType][modelFileKey] = _currentModelInfo.files;
      const keys = Object.keys(_newModelFilesObj[modelType]).filter(item => item !== 'allFiles');
      let _allFiles = [];
      for (let key of keys) {
        const addFiles = _newModelFilesObj[modelType][key].filter(item => !_allFiles.find(it => it.libraryId === item.libraryId && it.modelKey === item.modelKey))
        _allFiles = [..._allFiles, ...addFiles]
      }
      _newModelFilesObj[modelType].allFiles = _allFiles
    } else if (_modelType === modelFileKey && _modelType === modelType) {
      _pairs = _currentModelInfo.pairs;
      _files = _currentModelInfo.files;
      // _pinList = this.getPins(_pairs)
      _pinList = getModelTypePins(_pairs, pins, _modelType, signals, product, rank, termination);
    }

    this.setState({
      newModelFilesObj: _newModelFilesObj,
      modelInfoList: _modelInfoList,
      pinList: _pinList,
      pairs: _pairs,
      files: _files
    }, () => {
      this.props.isPageDisplay && this.props.savePackageModelInfo()
    })
  }

  saveModelByType = ({ pinList = [], pairs = [], files = [] }, type, index) => {
    if (typeof type === 'boolean' && type) return;
    const { modelInfoList, newModelFilesObj } = this.state;
    const { modelType } = this.props;
    let _modelInfoList = [...modelInfoList],
      _newModelFilesObj = { ...newModelFilesObj };

    let _modelFileKey = '';
    let allFiles = [], oldFiles = [];
    if (type === modelType) {
      // pcb  
      allFiles = _newModelFilesObj[type] && _newModelFilesObj[type].allFiles ? [...this.state.files, ..._newModelFilesObj[type].allFiles] : [...this.state.files];
    } else {
      allFiles = [..._newModelFilesObj[type].allFiles]
    }

    if (index > -1) {
      _modelFileKey = _modelInfoList[index] && _modelInfoList[index].modelFileKey
      oldFiles = _newModelFilesObj[type] && _newModelFilesObj[type][_modelFileKey] ? [..._newModelFilesObj[type][_modelFileKey]] : []
    }

    const { _pinList, _pairs, _files } = this.getNewValue(files, oldFiles, allFiles, pinList, pairs)


    if (index > -1) {
      _modelInfoList[index] = {
        ..._modelInfoList[index],
        files: _files,
        modelPinList: _pinList,
        pairs: _pairs
      }
    }

    let currentTypeFileInfo = _newModelFilesObj[type];
    if (currentTypeFileInfo) {
      let allFiles = [];
      for (let key of Object.keys(currentTypeFileInfo)) {
        if (key === 'allFiles') { continue };
        let newFiles = currentTypeFileInfo[key]
        if (key === _modelFileKey) {
          newFiles = [..._files]
          _newModelFilesObj[type][key] = [..._files]
        }
        const addFiles = newFiles.filter(item => !allFiles.find(it => it.libraryId === item.libraryId && it.modelKey === item.modelKey))
        allFiles = [...allFiles, ...addFiles]
      }
      _newModelFilesObj[type].allFiles = [...allFiles];
    }

    this.setState({
      modelInfoList: _modelInfoList,
      newModelFilesObj: _newModelFilesObj
    }, () => {
      this.props.isPageDisplay && this.props.savePackageModelInfo()
    })
  }

  getModelsObj = () => {
    const { modelInfoList, newModelFilesObj } = this.state;
    let modelList = [];

    for (let item of modelInfoList) {
      const { modelType, pairs, type, modelFileKey } = item;
      const _files = newModelFilesObj[modelType] && newModelFilesObj[modelType][modelFileKey] ? newModelFilesObj[modelType][modelFileKey] : [];
      modelList.push({
        files: _files,
        modelType,
        pairs,
        type
      })
    }
    return modelList
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  changePKGType = () => {
    this.clearPinNode();
    this.setState({
      files: [],
      subcktList: [],
      portsObj: {},
      modelInfoList: [],
      newModelFilesObj: {}
    }, () => {
      this.props.resize()
    })
  }

  selectNodes = ({ pinList, pairs, files }, isUpdateFiles, updateFilesParams) => {
    this.setState({
      pinList,
      pairs,
      files: files !== undefined ? files : this.state.files
    }, () => {
      this.props.isPageDisplay && this.props.savePackageModelInfo()
      if (isUpdateFiles) {
        const { key, modelKey, fileType, } = updateFilesParams;
        this.selectFile(key, modelKey, fileType, false, true);
      }
    })
  }

  selectSpiceSubckt = (key, modelKey, fileType, paramsSubcktList) => {
    if (!key) {
      return;
    }
    const { label: name } = key;
    const { subcktList: stateSubcktList, files, portsObj, termination, pinList } = this.state;
    const subcktList = paramsSubcktList || stateSubcktList;
    let _portsObj = { ...portsObj };
    let _files = fileType === 'termination' ? JSON.parse(JSON.stringify(termination.files || [])) : JSON.parse(JSON.stringify(files || []));

    const index = _files.findIndex(item => item.modelKey === modelKey);
    let currentFile = index > -1 && _files[index] ? { ..._files[index] } : null;
    if (!currentFile) {
      return;
    }

    const libraryId = currentFile.libraryId, fileName = currentFile.fileName, prevSubckt = currentFile.subckt;
    const find = _files.find(item => item.libraryId === libraryId && item.subckt && item.subckt === name);
    if (find) {
      // Duplicate subckt selected
      this.setState({
        errorMsg: "Selecting the same subckt is not allowed here. To do it, please click the gray boxes with signal names."
      }, () => {
        //update pin node connect box top position
        this.props.setContentHeight(0);
      })
      return;
    }
    _files[index].subckt = name;
    // add current subckt ports to portsObj
    const findSubckt = subcktList.find(it => it.libraryId === libraryId);
    const models = findSubckt && findSubckt.models ? findSubckt.models : [];
    const subcktFind = models.find(it => it.name === name);
    const ports = subcktFind && Array.isArray(subcktFind.ports) ? subcktFind.ports : [];
    let _ports = [];
    ports.forEach(port => {
      _ports.push({
        port,
        info: port,
        subckt: name
      });
    });
    //filter prev subckt ports
    _portsObj[libraryId] = _portsObj[libraryId] ? _portsObj[libraryId].filter(it => (it.subckt !== name) && (it.subckt !== prevSubckt)) : [];
    //add current select subckt ports
    if (name) {
      _portsObj[libraryId] = [..._portsObj[libraryId], { fileName, type: SPICE, subckt: name, ports: _ports }];
    }

    if (fileType === 'termination') {
      let autoFillInfo = null;
      if (_ports.length === 1) {
        autoFillInfo = getAutoFillNode({ pinList, pairs: termination.pairs, files: _files, selectFile: _files[index], ports: _ports });
      }
      this.setState({
        termination: {
          ...termination,
          pairs: autoFillInfo ? autoFillInfo._pairs : termination.pairs,
          files: autoFillInfo ? autoFillInfo._files : _files,
        },
        pinList: autoFillInfo ? autoFillInfo._pinList : pinList,
        errorMsg: null,
        portsObj: _portsObj
      }, () => {
        prevSubckt && this.clearPinNode({ delFile: { ...currentFile } });
        this.props.setContentHeight(0);
      })
    } else {
      this.setState({
        files: _files,
        portsObj: _portsObj,
        errorMsg: null
      }, () => {
        prevSubckt && this.clearPinNode({ delFile: { ...currentFile } });
        this.props.setContentHeight(0);
      })
    }
  }

  updatePortsObj = ({ currSelectFile }) => {
    // Update portsObj data after selecting subckt
    const { libraryId, subckt, fileName } = currSelectFile;
    const { subcktList, portsObj } = this.state;
    let _portsObj = { ...portsObj }
    if (!_portsObj[libraryId]) { _portsObj[libraryId] = [] }
    const currentPortInfo = _portsObj[libraryId];
    const find = currentPortInfo.find(item => item.subckt === subckt);
    if (find) { return }

    const findSubckt = subcktList.find(it => it.libraryId === libraryId);
    const models = findSubckt && findSubckt.models ? findSubckt.models : [];
    const subcktFind = models.find(it => it.name === subckt);
    const ports = subcktFind && Array.isArray(subcktFind.ports) ? subcktFind.ports : [];
    let _ports = [];
    ports.forEach(port => {
      _ports.push({
        port,
        info: port,
        subckt: subckt
      });
    });

    _portsObj[libraryId] = [..._portsObj[libraryId], { fileName, type: SPICE, subckt, ports: _ports }];

    this.setState({
      portsObj: _portsObj,
    })
    return _ports;
  }

  changeRankNumber = (e) => {
    const number = e.target.value;
    this.setState({
      rank: { ...this.state.rank, number }
    })
  }

  changeRankActive = (value) => {
    const { rank } = this.state;
    if (value && Number(value) <= Number(rank.number)) {
      this.setState({
        rank: { ...rank, active: value }
      })
    }
  }

  changeTermination = ({ pairs, files, type, topology, pinList }, isUpdateFiles, updateFilesParams) => {
    const { termination, topology: prevTopology, pinList: prevPinList } = this.state;
    const _termination = {
      pairs: [...(pairs || termination.pairs)],
      files: [...(files || termination.files)],
      type: type || termination.type
    }
    this.setState({
      termination: _termination,
      topology: topology || prevTopology,
      pinList: [...(pinList || prevPinList)]
    }, () => {
      if (isUpdateFiles) {
        const { key, modelKey, fileType, } = updateFilesParams;
        this.selectFile(key, modelKey, fileType, false, true);
      }
      this.props.setContentHeight(0);
    })
  }

  getLibraryListObj = () => {
    const { spiceList, touchstoneList, product, connectorList, cableList, packageList, pcbList, modelType, ebdList, pkgType, isPageDisplay } = this.props;
    if (modelType === "DIE" && product === ANDES_V2) {
      return {
        fileList: [...touchstoneList],
        libraryListObj: {
          [`${modelType.toLowerCase()}List`]: [...touchstoneList],
          terminationList: [...spiceList]
        }
      }
    }
    const fileList = pkgType === EBD ? [...ebdList] : isPageDisplay ? [...touchstoneList, ...spiceList] : [...spiceList, ...touchstoneList];
    return {
      fileList,
      libraryListObj: {
        packageList,
        connectorList,
        cableList,
        pcbList,
        [`${modelType.toLowerCase()}List`]: fileList
      }
    }
  }

  render() {
    const { files, pinList, pairs, portsObj, subcktList, errorMsg, top,
      modelInfoList, newModelFilesObj, rank, termination, topology } = this.state;
    const { scrollId, maxWidth, maxHeight, product, modelType } = this.props;
    const { fileList, libraryListObj } = this.getLibraryListObj();
    return (
      <NetListModel
        {...this.props}
        onRef={this.onRef}
        files={files}
        pinList={pinList}
        pairs={pairs}
        fileList={fileList}
        portsObj={portsObj}
        subcktList={subcktList}
        top={top}
        errorMsg={errorMsg}
        scrollId={scrollId}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        product={product}
        libraryListObj={libraryListObj}
        rank={rank}
        modelInfoList={modelInfoList}
        newModelFilesObj={newModelFilesObj}
        determineComp={modelType === PreLayout ? true : false}
        updatePortsObj={this.updatePortsObj}
        {...this.actions}
        {...this.rankActions}
        termination={termination}
        topology={topology}
      />
    )
  }

  actions = {
    _clearPinNode: this.clearPinNode,
    _selectFile: this.selectFile,
    _delFileSelect: this.delFileSelect,
    _selectNodes: this.selectNodes,
    _addNewFileSelect: this.addNewFileSelect,
    getFileParse: this.getFileParse,
    _saveModel: this.saveModel,
    _updateModelObj: this._updateModelObj,
    saveModelByType: this.saveModelByType,
    _automaticMatchPort: this.automaticMatchPort,
    _selectSpiceSubckt: this.selectSpiceSubckt,
    _selectByFolder: this.selectByFolder,
    _changeTermination: this.changeTermination
  }

  rankActions = {
    changeRankNumber: this.changeRankNumber,
    switchRankSetting: this.switchRankSetting,
    checkRankNumber: this.checkRankNumber,
    changeRankActive: this.changeRankActive
  }
}

export default SetPKGNodeModel;