import { getComponentPinMap, updateComponentPinMap } from '../../api/compSetting';
import { versionCompareSize } from '../../helper/dataProcess';
import componentSetting from './compSettingHelper';
import apiProcess from '../../api/utility';
import debounce from '../../helper/debounceFn';
import projectDesigns from '../../helper/projectDesigns';
import { getPartByProject } from '../../api/Cascade/project';
import { versionUpdate } from '../../helper/dataProcess';
import designConstructor from '../../helper/designConstructor';

class CompPinMap {
  constructor() {
    this.componentPinMap = new Map();  // key - designId, value - { map, version }
  }

  savePinMap(designId, pinMap) {
    this.componentPinMap.set(designId, pinMap);
  }

  getPinMap = async (designId, fromBackEnd = false) => {
    const isPreLayout = designConstructor.isPreLayout(designId);
    if (!designId || isPreLayout) {
      return {};
    }
    let obj = this.componentPinMap.get(designId);
    const setting = await componentSetting.getSetting({ designId });
    const mapVersion = obj && obj.version ? obj.version : '0.0.0';
    if (!obj || versionCompareSize(mapVersion, setting.version) || fromBackEnd) {
      try {
        obj = await getCompPinMap(designId, setting.compPrefixLib);
        obj = {
          version: setting.version,
          map: setPartType(obj.map, setting.compPrefixLib),
          pinConnection: obj.pinConnection || []
        }
        this.savePinMap(designId, obj);
      } catch (err) {
        console.error(err);
      }
    }
    return obj
  }

  getPinMapData = async (designId) => {
    const obj = await this.getPinMap(designId);
    return obj && obj.map ? obj.map : [];
  }

  getPinConnection = async (designId) => {
    const obj = await this.getPinMap(designId);
    return obj && obj.pinConnection ? obj.pinConnection : [];
  }

  getVersion = async (designId) => {
    const obj = await this.getPinMap(designId);
    return obj && obj.version ? obj.version : '0.0.1';
  }

  updatePinMap(componentPinMap, debounceKey = 'cascadeUpdatePinMap') {
    try {
      debounce(() => {
        updateCompPinMap(componentPinMap);
        componentPinMap.forEach(obj => {
          const { designId, pinMap } = obj;
          this.savePinMap(designId, pinMap);
        })
      }, 500, false, debounceKey)()
    } catch (error) {
      console.error(error)
    }
  }

  applyAllPinMap = async (pcbId, projectId, pinMap) => {
    const pinMaps = await applyCompPinMap(pcbId, projectId, pinMap);
    if (pinMaps && pinMaps.length) {
      this.updatePinMap(pinMaps, 'cascadeApplyPinMap')
    }
  }

  clearPinMap(designId) {
    this.savePinMap(designId, null);
  }
}

function getCompPinMap(designId) {
  return apiProcess(getComponentPinMap, { designId });
}

function updateCompPinMap(componentPinMap) {
  return apiProcess(updateComponentPinMap, componentPinMap);
}

function setPartType(map, compPrefixLib) {
  let _map = [...map];
  const keys = Object.keys(compPrefixLib);
  _map.forEach(m => {
    if (!m.type) {
      for (let key of keys) {
        if (compPrefixLib[key].includes(m.partNumber)) {
          m.type = key;
          break;
        }
      }
    }
  })
  return _map;
}

async function applyCompPinMap(pcbId, projectId, pinMap) {
  const partNames = await getAllPartNames(projectId);
  const designList = projectDesigns.getAvailablePCBs(projectId).filter(item => item.id !== pcbId);
  const componentPinMaps = [];
  for (let design of designList) {
    const partName = partNames.find(p => p.designId === design.id);
    if (partName) {
      const {
        pinConnection = [],
        map = []
      } = pinMap;
      const _partName = [...pinConnection, ...map];
      if (_partName.length && _partName.every(p => partName.parts.includes(p.partNumber))) {
        const setting = await getCompPinMap(design.id);
        const version = versionUpdate(setting.version)
        componentPinMaps.push({ designId: design.id, pinMap: { pinConnection, map, version } })
      }
    }
  }
  return componentPinMaps
}

function getAllPartNames(projectId) {
  return apiProcess(getPartByProject, projectId)
}

const compPinMap = new CompPinMap();

export default compPinMap;