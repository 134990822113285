import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import {
  DOWNLOAD_RESULT,
  getHimalayasDownloadSimulationUrl,
  getHimalayasDebugDownloadList
} from '@/services/helper/debugHelper';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import DebugDownloadContent from '@/components/DebugDownload_v2';
import { IO_SIM, IO_SIM_GROUPS, HIMALAYAS_WRITE, HIMALAYAS_READ } from '../../../constants/treeConstants';
import { getProjectChildTitle } from '../../../services/Himalayas';
import himalayasChannels from '../../../services/Himalayas/project/channelsConstructor';
import { HIMALAYAS } from '../../../constants/pageType';
import himalayasProjects from '../../../services/Himalayas/project/projectConstructor';
import ioSimsConstructor from '../../../services/Himalayas/ioSim/ioSimsConstructor';
import '@/publicCss/debug.css';
import "./index.css";


const DOWNLOAD_ITEMS = [IO_SIM];
class DownloadPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 750,
      maxHeight: 304,
      debugMenuList: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 304),
      maxWidth: getPanelMaxWidth(offset, 1000)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getDownloadOptions();
  }

  componentDidUpdate = (prevState) => {
    const { verificationId } = this.props;
    if (verificationId !== prevState.verificationId) {
      this.getDownloadOptions();
    }
  }

  getDownloadOptions = () => {
    const { view, verificationId, channelId, projectId } = this.props;
    let debugMenuList = [];

    for (let key of DOWNLOAD_ITEMS) {
      const id = view === key ? verificationId : null;
      const _view = key === IO_SIM ? IO_SIM_GROUPS : key;
      const simulationData = this.getData({
        verificationId: id,
        treeList: himalayasChannels.getList(_view, projectId),
        selectList: himalayasChannels.getList(key, projectId),
        menuType: DOWNLOAD_RESULT,
        key,
        channelId
      });
      debugMenuList.push(simulationData);
    }
    this.setState({
      debugMenuList
    })
  }

  getData = ({ verificationId, treeList = [], channelId, selectList = [], menuType, key }) => {
    const currentProjectData = verificationId ? selectList.find(item => item.id === channelId) : selectList[0];
    let currentId = '';
    if (currentProjectData) {
      currentId = currentProjectData.id;
    }
    const { downloadList, downloadMenus, currentCheckValues, defaultCheckExpandKeys } = this.getDownloadList({ menuType });

    return {
      title: getProjectChildTitle(key),
      key: `${menuType}-${key}`,
      menuType: menuType,
      info: {
        selectList: [...selectList],
        isMultiple: true,
        selectInfo: {
          channelIds: [currentId],
        },
        selectAssemblyList: {
          channelIds: treeList.map(item => {
            return {
              title: item.name,
              value: item.id,
              key: item.id,
              children: item.children.map(item => ({ title: item.name, value: item.id, key: item.id, id: item.id }))
            }
          })
        },
        multiSelectedKeys: ["channelIds"]
      },
      downloadList,
      downloadMenus,
      currentCheckValues,
      defaultCheckExpandKeys,
      currentProjectName: (himalayasProjects.getProject(this.props.projectId) || {}).name
    }
  }

  updateSelectInfo = (info, type, value, menuType) => {
    let _info = { ...info };
    if (_info.selectInfo) {
      _info.selectInfo.channelIds = value;
    }
    return _info;
  }

  getDownloadList = ({ menuType, _info }) => {
    const { ioSimMode } = this.props;
    let mode = ioSimMode;
    let modeList = [mode];
    if (_info) {
      const channelIds = _info.selectInfo ? _info.selectInfo.channelIds || [] : [];
      const selectAssemblyChannels = _info.selectAssemblyList && _info.selectAssemblyList.channelIds ? _info.selectAssemblyList.channelIds : []
      const allChannelIds = channelIds.map(id => {
        const findItem = selectAssemblyChannels.find(it => it.value === id);
        if (findItem && findItem.children) {
          return findItem.children.map(it => it.value)
        }
        return [id]
      }).flat(2);
      modeList = allChannelIds.map(item => (ioSimsConstructor.get(item) || {}).mode);
      /*   mode = modeList.includes(HIMALAYAS_READ) && modeList.includes(HIMALAYAS_WRITE) ? HIMALAYAS_READ : HIMALAYAS_WRITE; */
    }

    const downloadList = getHimalayasDebugDownloadList(menuType, mode);
    let currentCheckValues = [], defaultCheckExpandKeys = [];
    for (let item of downloadList) {
      if (item.default) {
        currentCheckValues.push({
          name: item.option,
          children: item.children ? item.children.map(it => it.option) : null
        });
      }
      defaultCheckExpandKeys.push(item.option)
    }
    let downloadMenus = {};
    if (modeList.includes(HIMALAYAS_WRITE) && !modeList.includes(HIMALAYAS_READ)) {
      downloadMenus.SSN = downloadList.filter(item => ["DP", "SSN", "AC", "input"].includes(item.option));
    } else if (modeList.includes(HIMALAYAS_READ) && !modeList.includes(HIMALAYAS_WRITE)) {
      downloadMenus.Delay = downloadList.filter(item => ["DELAY_WRITE", "DELAY_ZL", "DELAY_ZH", "DELAY_READ", "input"].includes(item.option))
    } else {
      downloadMenus = {
        SSN: downloadList.filter(item => ["DP", "SSN", "AC", "input"].includes(item.option)),
        Delay: downloadList.filter(item => ["DELAY_WRITE", "DELAY_ZL", "DELAY_ZH", "DELAY_READ"].includes(item.option)),
        Input: [downloadList.find(item => item.option === "input")]
      }
    }

    return { downloadList, downloadMenus, currentCheckValues, defaultCheckExpandKeys }
  }

  getHeight = () => {
    const content = document.getElementById("debug-content-id");
    if (content) {
      const height = content.offsetHeight + 70 || 242;
      return height > 242 ? height : 242;
    }
    return 242;
  }

  render() {
    const { maxWidth, maxHeight, debugMenuList } = this.state;
    const height = this.getHeight();
    const content = (
      <Panel
        className='aurora-debug-panel panel-x-scroll-hidden himalayas-debug-panel'
        title={<div className='aurora-debug-title'>Download</div>}
        onCancel={this.props.closePanel}
        zIndex={2000}
        width={getPanelWidth(maxWidth, { defaultWidth: 560 })}
        position='panel-center'
        draggable
        minHeight={200}
        minWidth={200}
        maxHeight={maxHeight}
        height={maxHeight > height ? null : height}
        overflow={"auto"}
      >
        <DebugDownloadContent
          product={HIMALAYAS}
          debugMenuList={debugMenuList}
          getDownloadSimulationURL={getHimalayasDownloadSimulationUrl}
          updateSelectInfo={this.updateSelectInfo}
          getDownloadList={this.getDownloadList}
        />
      </Panel>
    )
    return createPortal(content, this.dialogRoot);
  }
}
export default DownloadPanel;