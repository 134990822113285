import React from 'react';
import { numberCheck } from '@/services/PDN/library/libraryData';

export function getVRMErrorCheck(vrmData, VRMNames, vrmInfo) {
  let error = [];

  if (vrmData && vrmData.length > 0) {
    vrmData.forEach(item => {
      Object.keys(item)
      if (Object.keys(item) && Object.keys(item).length > 0) {
        let key = Object.keys(item)[0];
        //{R: '',  L: '',}
        const data = item[key];
        let currentInfo = VRMNames.find(vrm => vrm.id === key);

        //current vrm not exist
        if (!currentInfo) {
          const info = vrmInfo.find(item => item.id === key);
          if (info) {
            error.push(<p style={{ margin: 0 }}>VRM model <span className="font-bold">{info.name}</span> does not exist.</p>);
          }
        }

        if (data && currentInfo) {
          //Check the Resistance value format
          let errorMsg = numberCheck(data.R);

          if (errorMsg) {
            error.push(<p style={{ margin: 0 }}>The value of VRM model <span className="font-bold">{currentInfo.name}</span> Resistance {errorMsg}</p>);
          }

          //Check the Inductance value format
          errorMsg = numberCheck(data.L);

          if (errorMsg) {
            error.push(<p style={{ margin: 0 }}>The value of VRM model <span className="font-bold">{currentInfo.name}</span> Inductance {errorMsg}</p>);
          }
        }
      }
    })
  }

  if (error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }
}