import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { VerticalLeftOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import ErrorCheck from '../../errorCheck';
import { TitleRender } from '@/components/Monitor/TitleRender_v2';
import { getSierraMultiSetupMonitorTree } from '@/services/Sierra/helper/monitorHelper';
import { displayStackupError } from '../../../../components/Monitor/SimulationMonitor/MonitorErrorCheck';
import './style.css';

class MultiSetupMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: null,
      topWidth: 666,
      lockScroll: true,
      libraryError: [],
      monitorTree: []
    }
    this.lockRef = createRef();
  }

  scrollToBottom = () => {
    if (this.lockRef) {
      this.lockRef.scrollTop = this.lockRef.scrollHeight;
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    const { currentVerificationId, projectName, verificationName } = this.props;
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
    let monitorTree = getSierraMultiSetupMonitorTree({
      projectName,
      verificationId: currentVerificationId,
      verificationName: verificationName,
    });
    this.setState({
      monitorTree
    })
  }

  componentDidUpdate(prevProps) {
    const { currentVerificationId, projectName, verificationName } = this.props;
    const { topWidth } = this.state;
    const { currentVerificationId: prevCurrentVerificationId, } = prevProps;

    if ((currentVerificationId && currentVerificationId !== prevCurrentVerificationId)
      || (projectName && projectName !== prevProps.projectName)) {
      let monitorTree = getSierraMultiSetupMonitorTree({
        projectName,
        verificationId: currentVerificationId,
        verificationName: verificationName,
      });
      this.setState({
        monitorTree
      })
    }

    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
  };

  hasLockScroll = () => {
    if (this.lockRef) {
      const { scrollHeight, scrollTop, clientHeight } = this.lockRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5) { this.setState({ lockScroll: false }); }
      else { this.setState({ lockScroll: true }); }
    }
  }

  render() {
    const {
      simCheckLogs, currentVerificationId, projectName
    } = this.props;
    const { topWidth, lockScroll, monitorTree } = this.state;
    const { scrollHeight, clientHeight } = this.lockRef;

    return (
      <Fragment>
        <div className='sierra-simulation-title'>
          <Row className='sierra-simulation-holygrail' span={24}>
            {TitleRender({
              topWidth,
              monitorTree,
              selectedKey: [projectName, currentVerificationId]
            })}
          </Row>
        </div>
        <div className='my-sierra-monitor multi-setup-monitor' onScroll={() => this.hasLockScroll()} ref={c => { this.lockRef = c; }}>
          {(simCheckLogs || []).map((item, index) =>
            <Fragment key={index}>
              {item.title ? <div className='multi-setup-monitor-title'>{item.title}</div> : null}
              {!item.title && item.logs && item.logs.length ? item.logs.map(log => <div key={log} className="multi-setup-monitor-logs">{log}</div>) : null}
              <div className='multi-setup-monitor-content'>
                {item.title && item.logs && item.logs.length ? item.logs.map(log => <div key={log} className="multi-setup-monitor-sub-logs">{log}</div>) : null}
                {item.stackupErrors && item.stackupErrors.length > 0 ?
                  <div className='sierra-stackup-error-check-main'>
                    <span className="aurora-stackup-error-span">Stackup: </span>
                    {item.stackupErrors.map((it, i) => <Fragment key={i}>
                      {displayStackupError(it.stackupError, it.designName)}
                    </Fragment>)}
                  </div>
                  : null}
                {item.errors ? <ErrorCheck
                  errorCheck={{ error: item.errors }}
                /> : null}
              </div>
            </Fragment>)}

        </div>
        {lockScroll && scrollHeight !== clientHeight ? <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'><VerticalLeftOutlined className="sierra-monitor-backbottom" onClick={this.scrollToBottom} /></Tooltip> : null}
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { projectName, verificationName, currentVerificationId, tabVisible } = state.TabMonitorReducer;
  const {
    project: { currentProjectId },
    multiInterfaceSetup: { simCheckLogs }
  } = state.SierraReducer;
  return {
    projectName,
    verificationName,
    currentVerificationId,
    currentProjectId,
    tabVisible,
    simCheckLogs
  }
}

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(MultiSetupMonitor);