import { GENERATION, GEN3, /* DATA_RATE */ } from "../constants";
import { CPHY, ETHERNET, GENERIC, HDMI, PCIE } from '../../PCBHelper/constants';
import { CMC, CONNECTOR, getSerdesType, IC } from "../../PCBHelper";
import { LAYOUT, PACKAGE } from "../../../constants/designType";

function ChannelConfig({ type }) {
  /*   this[DATA_RATE] = "2.5Gb/s"; */
  if (type === PCIE) {
    this[GENERATION] = GEN3;
  }
}

function Extraction(params = {}) {
  this.type = "SIwave"; // SIwave / HFSS
  this.clipping = true; //true/false
  this.clipSize = "3mm"; //string ,default distance 3mm
  this.includeDC = false; //true/false
  this.exactDC = false; //true/false
  this.logSweepMin = "1000"; //float [1e-5, 1e9]
  this.logSweepMax = "1e7"; //float [1e-5, 200e9]
  this.logSweepSAPD = "10"; //integer [1, 10000]
  this.linearSweepMax = "10e9"; //float ["", 200e9]
  this.linearSweepFreqStep = "5e6"; //float [1e-3,1e9] 
  this.maxFreq = "50e9"; //float [“”,200e9]，default is "50e9"
  this.discreteSweep = false; //true/false
  // this.meshFrequency = "10"; //float [1.0, 200.0]

  this.portImpedance = "42.5";
  if (params.designType === PACKAGE) {
    this.backdrillVias = false;
  } else {
    this.backdrillVias = true;
    this.backdrillStubSize = "8mil";// [1, 50],unit mil
  }

  // this.content = { designClip: true } //useDesignClip
  this.useDesignClip = true; //useDesignClip
  this.enableLogSweep = true;//Allow the user to turn off the log sweep.​ If log sweep is turned off, gray out the first frequency of the Linear Sweep and make it the same as the step frequency
  this.errorTolerance = "0.005"; // float [0.001, 0.1]
  this.maxSolution = "250";// [50, 1000]
  this.saveResultFolder = false;// Allow saving the AEDT result folder after Ansys extraction.

  this.siwave = new SIwaveInfo()
}

function HPCOptions() {
  this.num_tasks = "1";
  this.num_cores = "4";
  this.num_gpus = "0";
  this.ram_percent = "90";
}

function LicenseWait() {
  this.lic_wait = "1";
  this.lic_wait_unit = "min";
}

function SIwaveInfo(adaptiveFrequency = "50e9") {
  this.siSliderPos = 1;//integer 0/1/2    SIwave AC accuracy slide bar position    
  this.dcSliderPos = 1; //integer 0/1/2    SIwave DC accuracy slide bar position    
  this.minVoidArea = "2";//small void filling threshold
  this.minPadAreaToMesh = "1";//small pad meshing threshold
  this.minPlaneAreaToMesh = "6.25e-6";//plane meshing threshold
  this.snapLengthThreshold = "2.5";//vertice snapping threshold
  this.meshAutomatic = true;//determine the meshing frequency automatically
  this.meshFrequency = adaptiveFrequency;
  this.suggestHFSSRegions = false;
}

function HFSSInfo(maxPasses = "20", maxDelta = "0.02", adaptiveFrequency = '50e9') {
  this.adaptiveFrequency = adaptiveFrequency; //adaptive meshing frequency
  this.maxPasses = maxPasses;//float [1, 50]    Maximum iterations in HFSS adaptive meshing  
  this.maxDelta = maxDelta;//float [0.001, 0.02]    Maximum result difference allowed in the adaptive meshing  
  this.orderBasis = -1;//integer 0/1/2/-1    order of HFSS basis functions  
  this.viaNumSides = "6";//>=3    number of sides to model vias   
  this.airHorExt = '0.15';//HFSS airbox horizontal extension ratio or size
  this.airPosZExt = "2";//HFSS airbox vertical extension ratio or size
  this.advDCExtrapolation = false;//Use advanced DC extrapolation for HFSS
  this.minSolvedFreq = "10e7";//minimum solved frequency in HFSS
  this.generateStateSpace = false;//enforce HFSS result causality
  this.specifyMinSolvedFreq = false; //Let user specify the minimum solve frequency
}

function ChannelContent({
  type = "PostLayout",
  signals = [],
  components = [],
  selectedSignals = [],
  powerNets = [],
  referenceNets = [],
  port_setups = [],
  portGenerationSetup = {},
  designType
}) {
  this.signals = signals;
  this.components = components;
  this.selectedSignals = selectedSignals;
  this.powerNets = powerNets;
  if (type === "PostLayout") {
    this.extraction = new Extraction({ designType });
    this.port_setups = port_setups;
    this.referenceNets = referenceNets;
    this.portGenerationSetup = portGenerationSetup;
  }
}

function ChannelSignals({ name, group, nets_P = [], nets_N = [] }) {
  this.name = name;
  this.group = group;
  this.nets_P = [...nets_P];
  this.nets_N = [...nets_N];
}

function ChannelCPHYSignals({ name, group, nets_A = [], nets_B = [], nets_C = [] }) {
  this.name = name;
  this.group = group;
  this.nets_A = [...nets_A];
  this.nets_B = [...nets_B];
  this.nets_C = [...nets_C];
}

function ChannelComponents({ name, type, part, pins, selectComponent, pinsConnection }) {
  this.name = name;
  this.type = type;
  this.part = part;
  this.pins = pins.map(item => new ChannelCompPin(item));
  this.model = {};
  if (type === IC) {
    this.pkg = { type: "None" };
  }
  if (type === CONNECTOR) {
    this.connectorModel = { type: "None" };
  }

  if (type === CMC) {
    this.pinsConnection = pinsConnection && pinsConnection.length ? pinsConnection : [{ pin: "1", pinMap: "4" }, { pin: "2", pinMap: "3" }];
  }

  if (selectComponent === name) {
    this.SELECT_COMP = true;
  }
}

function ChannelRLCComponents({ name, type, part, pins, model = {} }) {
  this.name = name;
  this.type = type;
  this.part = part;
  this.pins = pins.map(item => new ChannelCompPin(item));
  this.model = { ...model };
}

function ChannelCompPin({ pin, net, signal }) {
  this.pin = pin;
  this.net = net;
  this.signal = signal;
  /*   this.usage = "";
    this.model = {}; */
}

function RLCValue({ r = "", l = "", c = "" }) {
  this.r = r;
  this.l = l;
  this.c = c;
}

function DefaultChannelAdvanceConfig({
  designType = LAYOUT,
  type,
  positiveFlags = ["P", "+"],
  negativeFlags = ["N", "-", "M"],
  txFlag = "TX",
  rxFlag = "RX",
  addCLK = false,
  ALineFlags = ["A"],
  BLineFlags = ["B"],
  CLineFlags = ["C"],
}) {
  this.type = type;
  this.netNamePrefix = type === GENERIC || designType === PACKAGE ? [] : getNetNamePrefix(type);
  this.positiveFlags = positiveFlags;
  this.negativeFlags = negativeFlags;

  if (type === CPHY) {
    this.ALineFlags = ALineFlags;
    this.BLineFlags = BLineFlags;
    this.CLineFlags = CLineFlags;
  }

  if (type !== PCIE && type !== ETHERNET) {
    this.txFlag = "";
    this.rxFlag = "";
    this.addCLK = type === HDMI ? true : false;
  } else {
    this.txFlag = txFlag;
    this.rxFlag = rxFlag;
    this.addCLK = addCLK;
  }
}

function getNetNamePrefix(type) {
  if (type === HDMI) {
    return [getSerdesType(type), "TMDS", "FRL"];
  } else if (type === CPHY) {
    return [getSerdesType(type), "CSI", "DSI"];
  }
  return [getSerdesType(type)];
}

/* Package /connector model */
function PackageConnModelInfo(type = "") {
  this.type = type;
  this.files = []; //{ type, fileName, libraryId, subckt }
  this.pairs = []; // [ { pin, node, subckt, libraryId } ,{ pin, node, subckt, libraryId }, ... ]
}

function PackageDieModelInfo(type = "") {
  this.type = type;
  this.files = []; //{ type, fileName, libraryId, subckt }
  this.pairs = []; // [ { pin, node, subckt, libraryId } ,{ pin, node, subckt, libraryId }, ... ]
  this.topology = "parallel"; // series / parallel
  this.termination = {
    type: "value",
    files: [],
    pairs: []
  }
}

function PackageConnModelFileInfo() {
  this.type = "";
  this.fileName = "";
  this.libraryId = "";
  this.subckt = "";
}

export {
  ChannelConfig,
  ChannelContent,
  ChannelSignals,
  ChannelComponents,
  Extraction,
  ChannelRLCComponents,
  RLCValue,
  DefaultChannelAdvanceConfig,
  PackageConnModelInfo,
  PackageConnModelFileInfo,
  HPCOptions,
  LicenseWait,
  SIwaveInfo,
  HFSSInfo,
  ChannelCPHYSignals,
  PackageDieModelInfo
}