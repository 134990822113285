import React, { Component } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Row, Divider, Select } from 'antd';
import { getTDRSBRSettingData, generateTDRSBRResult } from '@/services/Andes_v2/results/waveform';
import Setting, { keys, maxFreqHzToGHz, valueMatch } from '@/services/Andes_v2/results/waveform/TDRSBRSetting';
import ResultGenerateBtn from '@/components/ResultGenerateBtn';
import { WAITING, VERIFY_RUNNING } from "@/constants/verificationStatus";

const Option = Select.Option;
class TDRSBRSetting extends Component {
  constructor() {
    super()
    this.state = {
      resultShow: true,
      datarate: null,
      duration_ratio: null,
      risetime: null,
      dataRateUnit: 'Gbps',
      error: null,
      loading: false
    }
    this.setting = null;
  }

  componentDidMount() {
    const { channelId } = this.props;
    if (channelId) {
      this.getSettingData();
    }
  }

  componentDidUpdate(prevProps) {
    const { channelId, maxFreq, status, resultKey } = this.props;
    if ((channelId && channelId !== prevProps.channelId) || resultKey !== prevProps.resultKey) {
      this.getSettingData();
    }

    if (maxFreq !== '-1' && maxFreq !== prevProps.maxFreq) {
      this.updateDataRate()
    }

    if (prevProps.status !== status && status) {
      if (![WAITING, VERIFY_RUNNING].includes(status)) {
        this.setState({
          loading: false
        })
      }
    }
  }

  updateDataRate = () => {
    if (!this.setting.datarate) {
      const { maxFreq } = this.props;
      let value = maxFreqHzToGHz(maxFreq) / 2;
      this.setting.datarate = value + 'Gbps';
      this.setState({
        datarate: value,
        dataRateUnit: "Gbps"
      })
    }
    return this.setting.datarate;
  }

  async getSettingData() {
    const { channelId, isEndToEnd, resultKey, maxFreq, sweepId } = this.props;

    this.setting = new Setting(channelId);
    const data = await getTDRSBRSettingData(channelId, isEndToEnd, sweepId);
    this.setting.updateData(data);

    if (!this.setting.datarate && maxFreq !== -1) {
      let value = maxFreqHzToGHz(maxFreq) / 2;
      this.setting.datarate = value + 'Gbps';
    }

    // const keys = data ? Object.keys(data).filter(item => item !== 'terminateUnused') : [];
    // if (!keys || !keys.length) {
    // const delayData = await getDelayData(channelId, isEndToEnd);
    // const dataRate = this.updateDataRate();
    // this.setting.updateSettingData(delayData, dataRate)
    // }
    const match = this.setting.datarate.match(/(M|G|T)bps/g);
    const matchDataRate = match ? match[0] : 'Gbps';
    this.setState({
      datarate: valueMatch(this.setting.datarate),
      duration_ratio: valueMatch(this.setting[`${resultKey.toLowerCase()}_duration_ratio`]),
      risetime: valueMatch(this.setting[`${resultKey.toLowerCase()}_risetime`]),
      dataRateUnit: matchDataRate
    })

  }

  recalculate = () => {
    const { dataRateUnit } = this.state;
    const { isEndToEnd, sweepId } = this.props;
    this.setState({
      error: '',
      loading: true
    })
    generateTDRSBRResult(this.setting.getSetting({ dataRateUnit, generate_powersum: false, generate_waveform: true }), isEndToEnd, sweepId).then(res => {
      try {
        const workflow = res;
        this.props.recalculateTDRSBRWorkflow(workflow, isEndToEnd);
      } catch (error) {
        this.setState({
          error: `Generate failed! ${error}`,
          loading: false
        })
      }
    }, error => {
      this.setState({
        error: error,
        loading: false
      })
    })
  }

  resultTitleClick = () => {
    this.setState((prevState) => {
      return {
        resultShow: !prevState.resultShow
      }
    })
  }

  // datarate only
  changeUnit = (unit) => {
    // value range check
    this.setState({
      dataRateUnit: unit
    }, () => {
      this.errorCheck('datarate', this.state.datarate)
    })
  }

  selectAfter = () => {
    const { dataRateUnit } = this.state;
    return (
      <Select dropdownStyle={{ zIndex: 100000 }} value={dataRateUnit} onChange={this.changeUnit}>
        {['Mbps', 'Gbps', 'Tbps'].map(item =>
          <Option key={item}>{item}</Option>
        )}
      </Select>
    )
  }

  getInputAfter = (key) => {
    if (key === 'datarate') {
      return this.selectAfter();
    } else if (key === 'duration_ratio') {
      return 'ui';
    } else {
      return 'ps';
    }
  }

  valueChange = (e, key) => {
    const value = e.target.value;
    const { resultKey } = this.props;
    switch (key) {
      case "risetime":
        this.setting[`${resultKey.toLowerCase()}_risetime`] = value;
        break;
      case "duration_ratio":
        this.setting[`${resultKey.toLowerCase()}_duration_ratio`] = value;
        break;
      default:
        this.setting[key] = value;
        break;
    }

    this.setState({
      [key]: value
    })
    this.errorCheck(key, value);
  }

  errorCheck = (key, value) => {
    const { maxFreq, isPreLayout } = this.props;
    const { dataRateUnit } = this.state;
    let _value = value;
    // to Gbps
    if (key === 'datarate') {
      if (dataRateUnit === 'Tbps') {
        _value = _value * 1e3;
      } else if (dataRateUnit === 'Mbps') {
        _value = _value / 1e3;
      }
    }
    const error = this.setting.checkRange(key, _value, maxFreq, isPreLayout);
    if (error) {
      this.setState({
        error
      })
    } else {
      this.setState({
        error: ''
      })
    }
  }

  render() {
    const { resultShow, error, loading } = this.state;
    return (
      <div className='tdr-sbr-setting'>
        <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className='result-import-title' onClick={this.resultTitleClick}>
            {resultShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
            <span className='sparameter-result-menu-title'>Setup</span>
          </div>
        </Divider>
        {resultShow && (
          <>
            {keys.map(item => {
              return <Row key={item.key}>
                <span className='config-title'>{item.name}</span>
                <Input
                  value={this.state[item.key]}
                  addonAfter={this.getInputAfter(item.key)}
                  onChange={(e) => this.valueChange(e, item.key)}
                  className='aurora-input'
                />
              </Row>
            })}
            {error ? <div className='error-msg'>{error}</div> : null}
            {ResultGenerateBtn({
              onClick: this.recalculate,
              loading: 'btn',
              ifLoading: loading,
              disabled: error ? true : false,
              btnName: 'Generate'
            })}
          </>
        )}
      </div>
    );
  }
}

export default TDRSBRSetting;