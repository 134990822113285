class ProjectChannels {
  constructor() {
    this.project = new Map(); // key - projectId, value - channelIds

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
  };

  set(id, channels) {
    this.project.set(id, channels);
  }

  get(id) {
    return this.project.get(id) || [];
  }

};

const RockyProjectChannels = new ProjectChannels();
export default RockyProjectChannels;