import {
  PCBS,
  PROJECT,
  PCB,
  DCR,
  IR_EXPLORER,
  IMPEDANCE,
  PACKAGES,
  POWER_TREE,
  SIGN_OFF_TEMPLATES,
  SIGN_OFF_TEMPLATE,
  DESIGN_TREE,
  SINGLE_TREE,
  PCB_PRE_LAYOUT
} from '@/constants/treeConstants';
import CascadeChannels from '../DB/cascadeChannels';
import { splitArrayToArrays } from '../../helper/arrayHelper'
import { LAYOUT } from '../../../constants/designType';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import { userDefaultSettings } from '../../userDefaultSetting/userDefaultSettingCtrl';
import { DCR_TITLE, FULL_TITLE, IMPEDANCE_TITLE, IR_TITLE, PACKAGE_TITLE, PCB_TITLE, SINGLE_TITLE, TEMPLATE_TITLE, TREE_TITLE } from '../constants';

// 2024.9.20 remove Single Power Tree
const explorerItem = [PCB_TITLE, PACKAGE_TITLE, FULL_TITLE, TREE_TITLE, TEMPLATE_TITLE, DCR_TITLE, IR_TITLE, IMPEDANCE_TITLE]
/* Project item*/
class projectItem {
  constructor({ id, name, category, children, nodeClass }) {
    this.id = id;
    this.name = name;
    this.key = PROJECT + '-' + this.id;
    this.dataType = PROJECT; // tree type
    this.nodeClass = 'tree-node-project-name ' + nodeClass;
    this.children = []; // [projectChild]
    this.category = category;
    this.children = children;
  }
}

function childClass(dataType) {
  const showFullPCBPowerTree = userDefaultSettings.getUseFullPCBPowerTree()
  switch (dataType) {
    case SIGN_OFF_TEMPLATES:
    case `${DCR}_group`:
      return 'cascade-package-node';
    case `${DESIGN_TREE}_group`:
      return !showFullPCBPowerTree ? 'display-none' : 'cascade-package-node'
    case `${POWER_TREE}_group`:
      return !showFullPCBPowerTree ? 'cascade-package-node' : '';
    default: return '';
  }
}

function getExplorerList() {
  const hasFullPCBPowerTree = userDefaultSettings.getUseFullPCBPowerTree();
  if (hasFullPCBPowerTree) {
    return explorerItem
  }
  return explorerItem
}

class projectChild {
  constructor({ name, id, dataType, category, iconDisabled }) {
    this.name = name; // 'PCB' || 'Device' || 'Path-R Explorer'(DCR) || 'IR Explorer' || 'Impedance Explorer'(PDN)
    this.key = dataType + '-' + id; // id - projectId
    this.dataType = dataType; // dataType
    this.nodeClass = childClass(dataType);
    this.category = category;
    this.iconDisabled = iconDisabled;
  }
}

// const projectChildTypes = [PCBS, Packages, DCR, IR_EXPLORER, IMPEDANCE];
const projectChildTypes = [PCBS, PACKAGES, `${DESIGN_TREE}_group`, `${POWER_TREE}_group`, SIGN_OFF_TEMPLATES, `${DCR}_group`, `${IR_EXPLORER}_group`, `${IMPEDANCE}_group`]

function getDefaultProjectTree(arr) {
  if (!Array.isArray(arr)) {
    return [];
  }
  const _explorerItem = getExplorerList();
  const projects = arr.map(item => {
    return new projectItem({
      id: item.id,
      name: item.name,
      category: item.category,
      nodeClass: "",
      children: _explorerItem.map((name, index) => new projectChild({
        name: name,
        id: item.id,
        category: item.category,
        dataType: projectChildTypes[index]
      }))
    })
  })
  return { projects }
}

class designItem {
  constructor({ id, name, projectId, designVersion, vendor, category, type = PCB, designType = LAYOUT, multiZone = false, layoutCheck, content = {} }) {
    this.id = id;
    this.name = name;
    this.key = type + '-' + id;
    this.dataType = type;
    this.projectId = projectId;
    this.designVersion = designVersion;
    this.vendor = vendor;
    this.children = []; //channels
    this.nodeClass = 'tree-node-pcb-name';
    this.addIconClass = "tree-node-pcb-add-icon";
    this.category = category;
    this.type = designType;
    this.multiZone = multiZone;
    this.layoutCheck = layoutCheck;
    this.content = content;
  }
};

class channelItem {
  constructor({ verificationId, name, designId, signOffId, signOffStatus, verificationStatus, type }, dataType) {
    if (type === 'Power_Tree') {
      dataType = POWER_TREE;
    }
    this.id = verificationId;
    this.name = name;
    this.key = dataType + '-' + verificationId;
    this.dataType = dataType;
    this.designId = designId;
    this.children = [];
    this.nodeClass = 'tree-node-channel-name';
    this.status = verificationStatus;
    switch (dataType) {
      case IMPEDANCE:
        this.signOffId = signOffId;
        break;
      case DESIGN_TREE:
        this.nodeClass = 'tree-node-channel-name cascade-tree-node-design-tree';
        break;
      case SIGN_OFF_TEMPLATE:
        this.status = signOffStatus;
        this.nodeClass = 'tree-node-channel-name cascade-tree-node-sign-off-template';
        break;
      case DCR:
        this.signOffId = signOffId;
        break;
      default: break;
    }
  }
}

function getDesignTree(arr, type) {
  if (!Array.isArray(arr)) {
    return [];
  };
  const designs = arr.map(item => {
    const _type = item.vendor === PRE_LAYOUT ? PCB_PRE_LAYOUT : type;
    return new designItem({
      id: item.id,
      name: item.name,
      projectId: item.projectId,
      designVersion: item.designVersion,
      vendor: item.vendor,
      category: item.category,
      type: _type,
      designType: item.type,
      multiZone: item.multiZone,
      layoutCheck: item.layoutCheck,
      content: item.content
    })
  });
  return designs;
};

function getExplorerTree(explorer = [], type, projectId, prevList = []) {
  const list = explorer.map(item => {
    const channel = new channelItem(item, type);
    const prev = prevList.find(it => channel.id === it.id);
    if (prev) {
      channel.dataType = prev.dataType || channel.dataType;
    }
    return channel;
  });
  if (!(type === IMPEDANCE || type === DCR)) {
    return { list, treeTaskList: list }
  }
  const listId = list.map(l => l.id)
  prevList.forEach(item => {
    if (item.dataType !== "tree-" + SIGN_OFF_TEMPLATE && !listId.includes(item.id)) {
      list.push(item)
    }
  })
  //sign off template tree
  let signOffTemplateMap = new Map(), impedanceList = [];
  for (let item of JSON.parse(JSON.stringify(list))) {
    if (!item.signOffId) {
      impedanceList.push(item);
      continue;
    }
    const prev = prevList.find(it => item.signOffId === it.id);
    const prevIm = prev && prev.children ? prev.children.find(it => it.id === item.id) : null;
    if (prevIm) {
      item.dataType = prevIm.dataType || item.dataType;
    }
    if (signOffTemplateMap.has(item.signOffId)) {
      signOffTemplateMap.set(item.signOffId, [...signOffTemplateMap.get(item.signOffId), item]);
    } else {
      signOffTemplateMap.set(item.signOffId, [item]);
    }
  }

  let treeTaskList = [];
  for (let key of signOffTemplateMap.keys()) {
    const signOffItem = CascadeChannels.getVerification(SIGN_OFF_TEMPLATE, projectId, key) || {};
    treeTaskList.push({
      id: key,
      name: signOffItem.name,
      key: "tree-" + SIGN_OFF_TEMPLATE + key + type,
      dataType: "tree-" + SIGN_OFF_TEMPLATE,
      designId: signOffItem.designId,
      nodeClass: 'tree-node-channel-name',
      children: [...signOffTemplateMap.get(key)]
    })
  }

  return { list, treeTaskList: [...treeTaskList, ...impedanceList] };
}

function updateProjectChild(projectItemChildren, arr, id) {
  const _explorerItem = getExplorerList();
  const children = _explorerItem.map((name, index) => {
    let _projectChild = new projectChild({
      ...projectItemChildren[index],
      id,
      // 2022.06 support upload multi pcbs
      iconDisabled: [PCB_TITLE, PACKAGE_TITLE].includes(name) ? false
        : [IMPEDANCE_TITLE].includes(name) && arr[1].length ? true
          : arr[0].length > 0 ? true : false
    })
    if (name === FULL_TITLE) {
      _projectChild.children = getPowerTreeItem(arr[index], id, DESIGN_TREE);
    } else if (name === TREE_TITLE) {
      _projectChild.children = getPowerTreeItem(arr[index], id, POWER_TREE);
    } else if (name === SINGLE_TITLE) {
      _projectChild.children = getPowerTreeItem(arr[index], id, SINGLE_TREE);
    } else {
      _projectChild.children = arr[index]
    }
    return _projectChild
  })
  return children
}

function getPowerTreeItem(arr, id, type) {
  const rule = item => {
    return item.dataType === type;
  }
  const { trueArray } = splitArrayToArrays({ array: arr, rule });
  return [...trueArray]
}

export {
  getDefaultProjectTree,
  getDesignTree,
  updateProjectChild,
  getExplorerTree,
  getPowerTreeItem
}