import { getFileInRockyVerification } from '../../../api/Rocky/rockyCtrl';


function getIBISCharacterizationData({ verificationSubId, verificationId, channelId, channelName, type, verificationName, fileName, pinIBISBuffers }) {
  let path = `${verificationSubId}/result/${channelName}/${type}/ibis/${verificationName.toLowerCase()}/${fileName}`;
  return new Promise((resolve, reject) => {
    getFileInRockyVerification({ channelId, filePath: path }).then((response) => {
      if (type === 'decks') {
        const data = parseIBISTxtFile(response.data, pinIBISBuffers)
        resolve(data)
      } else {
        const data = parseModelInfo(response.data)
        resolve(data)
      }
    }, error => {
      reject(error)
    })
  });
}

function getValueKey(data) {
  switch (data) {
    case 'Zout':
      return 'zout';
    case 'rise time':
      return 'riseTime';
    case 'fall time':
      return 'fallTime';
    case 'Z_odt':
      return 'odt';
    default: return '';
  }
}

function parseModelInfo(data) {
  let matchData = data.match(/[^\r\n]+/g), ModelInfo = {};
  for (let data of matchData) {
    const reg = /[^\d.]/g
    const splitData = data.split(": ")
    if (!splitData.length) { continue }
    const valueKey = getValueKey(splitData[0])
    if (!valueKey) { continue }
    const value = data.replace(reg, '')
    ModelInfo[valueKey] = value
  }
  return ModelInfo;
}

function getModelListData(data, signal, modelName) {
  const modelNameList = data.map(it => {
    if (it.modelName === modelName) {
      const signals = [...new Set([...it.signals, signal])]
      return { ...it, signals }
    }
    return it
  })
  return modelNameList
}

function parseIBISTxtFile(data, pinIBISBuffers) {
  let matchData = data.match(/[^\r\n]+/g);
  let _modelNameList = { controllerDrivers: {}, controllerReceivers: {}, memoryDrivers: {}, memoryReceivers: {} };
  let signalDataList = { rx: [], tx: [] }
  let signalType = '', signal = '';
  for (let info of matchData) {
    if (info.includes('IBIS Information')) {
      const splitData = info.split(" ")
      signal = splitData[0];
      if (['CLKP', 'CLKN'].includes(splitData[0])) {
        signalType = 'CLK'
      } else {
        const splitUnderscore = splitData[0].split('_')
        if (splitUnderscore.length > 1) {
          signalType = splitUnderscore[0]
        } else {
          signalType = splitData[0].replace(/[0-9]+/g, "")
        }
      }
    }
    if (info.includes('TX model') || info.includes(' RX model')) {
      const splitData = info.split(": ")
      if (splitData && splitData.length > 1) {
        const modelName = splitData[1];
        let direction = 'rx', _type = 'memoryReceivers';
        if (splitData[0].includes('SoC TX')) {
          direction = 'tx';
          _type = 'controllerDrivers'
        } else if (splitData[0].includes('SoC RX')) {
          direction = 'rx';
          _type = 'controllerReceivers'
        } else if (splitData[0].includes('DDR TX')) {
          direction = 'tx';
          _type = 'memoryDrivers'
        }

        if (!modelName) { continue }

        if (pinIBISBuffers && pinIBISBuffers.length && !pinIBISBuffers.includes(modelName)) {
          //filter spice model
          continue;
        }

        if (!signalDataList[direction].includes(modelName)) {
          signalDataList[direction].push(modelName)
        }

        if (!_modelNameList[_type][signalType]) {
          _modelNameList[_type][signalType] = [{ modelName, signals: [signal] }]
          continue
        }

        const currentSignals = _modelNameList[_type][signalType].find(item => item.modelName === modelName)
        if (currentSignals) {
          _modelNameList[_type][signalType] = getModelListData(_modelNameList[_type][signalType], signal, modelName)
        } else {
          _modelNameList[_type][signalType].push({ modelName, signals: [signal] })
        }
      }
    }
  }
  return { modelNameList: _modelNameList, signalDataList }
}

export default getIBISCharacterizationData;
