import CeRectangle from './CeRectangle';
import StringHelper from '../utility/StringHelper';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeOval = function (cx, cy, w, h) {
  CeRectangle.call(this, "Oval");
  if (arguments.length === 0) {
    this.SetData(0, 0, 0, 0);
  } else if (arguments.length === 2) {
    this.SetData(0, 0, arguments[0], arguments[1]);
  } else if (arguments.length === 4) {
    this.SetData(cx, cy, w, h);
  } else {
    console.error("arguments don't meet the requirment when creating CeOval");
  }

}

// subclass extends superclass
CeOval.prototype = Object.create(CeRectangle.prototype);
CeOval.prototype.constructor = CeOval;

CeOval.prototype.Clone = function () {
  var clnObj = new CeOval();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeOval.prototype.WriteIntoIODataNode = function () {
  var dataVals = CeRectangle.prototype.GetDataString.call(this);
  dataVals.add(StringHelper.BoolToString(this.mRightArc));
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeOval.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

export default CeOval;