import React, { Component, Fragment } from 'react';
import { Tooltip, Table } from 'antd';
import ChartContent from './ChartContent';
import './style.css'

class ResultTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateDataStatus: false,
      tableColumns: this.props.tableColumns,
      resultTableData: [...this.props.tableData],
      ISortIconStatus: '',
      RSortIconStatus: '',
      chartTableData: [],
      title: '',
      yText: '',
      xText: '',
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation()
  }

  componentDidMount() {
    this.getTableColumns()
  }

  componentDidUpdate(prevProps) {
    const { selectedKey, tableData } = this.props;
    if (prevProps.selectedKey !== selectedKey || prevProps.tableData.length !== tableData.length) {
      this.getTableColumns()
    }
  }

  getClassName = (record, type) => {
    const { resultKey, resultPowerDomainData = [] } = this.props;
    let className = ''
    const filterData = resultPowerDomainData.filter(item => item.allPowerNet.includes(record.net))
    if (type === 'I') {
      const value = Math.abs(record.I)
      if (resultKey === 'via') {
        if (filterData.length && filterData[0].maxVia && value > filterData[0].maxVia) {
          className = 'dc-result-current-row-red'
        }
      } else if (resultKey === 'pin') {
        if (filterData.length && filterData[0].maxBgaI && value > filterData[0].maxBgaI) {
          className = 'dc-result-current-row-red'
        }
      }
      if (filterData.length && filterData[0].maxBgaI && value > filterData[0].maxBgaI) {
        className = 'dc-result-current-row-red'
      }
    } else if (type === 'voltageDrop') {
      const value = Math.abs(record.V)
      if (filterData.length && filterData[0].maxBgaDv && filterData[0].nominal && Math.abs((value - (filterData[0].nominal * 1000)) / (filterData[0].nominal * 1000)) > filterData[0].maxBgaDv) {
        className = 'dc-result-current-row-red'
      }
    }
    return className
  }

  getTableColumns = () => {
    const { tableData, compareTypes = [], resultKey, resultPowerDomainData } = this.props;
    const { updateDataStatus } = this.state;

    if (tableData && tableData.length) {
      const viaHeaderData = tableData.shift();
      if (!tableData.length) {
        return;
      }
      if (viaHeaderData.X.includes('X') && viaHeaderData.Y.includes('Y')) {
        let _tableColumns = [...this.state.tableColumns]


        let chartTableData = [], title = 'Voltage Drop', yText = 'Voltage Drop (mV)', xText = 'Location';
        if (resultKey === 'pin' || resultKey === 'bump') {
          const component = tableData[0].components.split('-')[0]
          xText = resultKey[0].toUpperCase() + resultKey.substr(1)
          xText = `${component} ${xText}`
          if (!resultPowerDomainData.length) {
            title = 'Voltage';
            yText = 'Voltage (mV)'
          }
          const filterPowerData = resultPowerDomainData.filter(item => item.powerNets.includes(tableData[0].domain))
          const filterGroundData = resultPowerDomainData.filter(item => item.gndNets.includes(tableData[0].domain))
          if (filterPowerData.length) {
            title = 'Voltage Drop';
            yText = 'Voltage Drop (mV)'
          } else if (filterGroundData.length) {
            title = 'Voltage Bounces'
            yText = 'Voltage Bounces (mV)'
          }
          chartTableData = tableData.map(item => { return { ...item } })
          // } else if (resultKey === 'via') {
          //   chartTableData = tableData.map(item => { return { ...item } })
        }

        _tableColumns.forEach(item => {
          if (compareTypes.includes(item.dataIndex)) {
            item.render = (value, record) => {
              const className = this.getClassName(record, item.dataIndex)
              return <span className={className}>{value}</span>
            }
          }
          switch (item.dataIndex) {
            case 'I':
              item.filterIcon = () => {
                const tooltipImg = <div onClick={(e) => this.stopPropagation(e)}>
                  <img src={`/lib/imgs/svgImgs/ir_via.svg`} alt="" style={{ height: 50 }} />
                </div>
                return <Tooltip
                  overlayClassName='aurora-tooltip'
                  title={tooltipImg}
                >
                  <div className="dc-result-table-title-content" title='' onClick={(e) => this.stopPropagation(e)}>
                    <img src={`/lib/imgs/svgImgs/ir_via.svg`} alt="" className="dc-result-table-title-img" />
                  </div>
                </Tooltip>
              }
              item.filterDropdown = ({ confirm }) => { return <div></div> }
              break;
            case 'X':
            case 'Y':
              item.title = viaHeaderData[item.dataIndex]
              break;
            case 'voltageDrop':
              if (resultKey === 'pin' || resultKey === 'bump') {
                item.title = `${title} (mV)`
              }
              if (!resultPowerDomainData.length) {
                item.render = () => { return }
                item.title = `Voltage Drop (mV)`
              }
              break;
            default: break;
          }
        })

        this.setState({
          tableColumns: _tableColumns,
          resultTableData: tableData,
          updateDataStatus: !updateDataStatus,
          chartTableData,
          title,
          yText,
          xText
        })
      }
    }
  }

  handleChange = (pagination, filters, sorter) => {
    let _tableColumns = [...this.state.tableColumns]
    _tableColumns.forEach(item => {
      if (item.dataIndex === sorter.columnKey) {
        item.sortOrder = sorter.order
      } else {
        item.sortOrder = false
      }
    })
    this.setState({
      tableColumns: _tableColumns
    });
  };

  clearSortIconStatus = () => {
    this.setState({
      ISortIconStatus: '',
      RSortIconStatus: ''
    })
  }

  render() {
    const { updateDataStatus, tableColumns, resultTableData, chartTableData, title, yText, xText } = this.state;
    const { scroll, verificationId, resultKey, selectedKey, chartYType, chartXType, svgHeight, rowKey, chartVisible } = this.props;
    const SETTINGS = {
      minIndex: 0,
      maxIndex: resultTableData.length,
      startIndex: 0,
      itemHeight: 80,
      amount: 50,
      tolerance: 10
    }
    return <Fragment>
      <div className="dc-result-chart-body">
        {chartVisible &&
          <ChartContent
            verificationId={verificationId}
            tableData={chartTableData}
            resultKey={resultKey}
            selectedKey={selectedKey}
            chartXType={chartXType}
            chartYType={chartYType}
            svgHeight={svgHeight}
            title={title}
            yText={yText}
            xText={xText}
          />
        }
      </div>
      {/* <VirtualTable
        rowKey={rowKey}
        loading={false}
        columns={tableColumns}
        pagination={false}
        size="small"
        dataSource={resultTableData}
        bordered
        setting={SETTINGS}
        height={height}
        virtualHeaderClassname='component-virtual-header'
        virtualBodyClassname='component-virtual-body'
        clearSortIconStatus={this.clearSortIconStatus}
        updateDataStatus={updateDataStatus}
        className='dc-result-table'
        handleChange={this.handleChange}
      /> */}
      <Table
        virtual
        bordered
        rowKey={rowKey}
        loading={false}
        columns={tableColumns}
        pagination={false}
        size="small"
        dataSource={resultTableData}
        scroll={scroll}
        className='dc-result-table'
      />
    </Fragment>
  }
}

export default ResultTable