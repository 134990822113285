import Parameter, { DEFINITION, FORMULA, MEASURED, TABLE } from './parameters';

// { id, name, dimension, unit, definition, dependency, available }

const MaskObjectKeys = ['id', 'name', 'dimension', 'unit', 'definition', 'dependency', 'available'];
class DDRParamMask extends Parameter {
  constructor(props) {
    super(props);
    this.dependency = [];
    this.available = [];
  }

  initMask(param) {
    this.available = param.available || [];
    this.dependency = param.dependency || [];
    this.paramInit(param);
  }

  eyeMaskToJson() {
    let obj = {};
    MaskObjectKeys.forEach(key => {
      obj[key] = this.getObjectByMaskKey(key)
    });
    return obj;
  };

  getObjectByMaskKey(key) {
    if (key === DEFINITION) {
      return this[key].definitionToJson();
    }
    return this[key];
  };

  // parameters - Class Parameters DDR eye mask parameters spec
  calculateValue = (parameters, dataRate) => {
    try {
      return this.definition.getDefinitionValue(parameters, dataRate)
    } catch (error) {
      console.error(111, error);
      return NaN;
    }
  }

  setDefinitionValue(value) {
    this.definition.setValue(value);
  }
};

export default DDRParamMask;