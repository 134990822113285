import React, { Component, Fragment } from 'react';
import { Input, Select } from 'antd';
import { getPinPortInfo, getPinTypeDisplay } from "@/services/helper/connectorHelper";
import './index.css';

const { Option } = Select;

class CopySignalPinsPortSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getPinList = (toSignal, portType) => {
    const _netType = portType === "cable" ? "pinL" : portType;
    // pin1: positive/Line A pin2: negative/Line B pin3: Line B
    let pin1 = {}, pin2 = {}, pin3 = null
    for (const item of (toSignal.pins || [])) {
      const netType = item[`${_netType}Type`]
      if (["positive", "Line A"].includes(netType)) {
        pin1 = { pinInfo: item, netType };
      } else if (["negative", "Line B"].includes(netType)) {
        pin2 = { pinInfo: item, netType };
      } else if (["Line C"].includes(netType)) {
        pin3 = { pinInfo: item, netType };
      }
    }

    return [pin1, pin2, pin3].filter(item => item);
  }

  render() {
    const { fromSignal = {}, toSignals = [], toSignal = {}, applySignals = [], portType, signalType, copyModelKey, copyModelKey2 } = this.props;
    const signalName = fromSignal[`${signalType}`];
    const pinList = this.getPinList(toSignal, portType);
    return <div className="connector-model-select-ports-main" key={signalName}>
      {/* <div className="connector-model-port-signal">{signalName}</div> */}
      {<div className="connector-model-port-copy-signal">
        <Select
          value={toSignal[`${signalType}`]}
          showSearch
          onChange={(key) => this.props.changeCopySignal(key, signalName, copyModelKey, copyModelKey2)}
          popupMatchSelectWidth={false}
          className={`aurora-select`}
          popupClassName="connector-select-dropdown-menu"
          getPopupContainer={() => document.getElementById('root')}
        >
          {toSignals.map((item) => <Option
            key={item}
            value={item}
            title={item}
            className={applySignals.includes(item) ? "connector-selected-apply-signals" : ""}
          >{item}</Option>)}
        </Select>
      </div>
      }
      <div className="connector-model-port-copy-pins">
        {pinList.map((pinItem, index) => {
          return <div key={`connector-model-copy-pin-${index}`}>
            {this.getSignalPinsRender({ pinInfo: pinItem.pinInfo, portType, fromSignal, netType: pinItem.pinInfo })}
          </div>
        })}
      </div>
    </div>
  }

  portPopover = ({ pin, pinPort, pinType }) => {
    const cssId = `${pin}::${pinType}`;
    if (pinPort) {
      return <div
        className='connection-model-pin-input-div'
        id={cssId}
        title={pinPort}
      >
        <div className='connection-pin-input-pin-port'>
          <span className='connection-pin-input-pin-port-text'>
            {pinPort}
          </span>
        </div>
      </div>
    }

    return this.connectorPinPortInput({
      pinPort,
      cssId
    })
  }

  connectorPinPortInput = ({
    pinPort,
    cssId,
  }) => {
    return <Input
      className='connection-model-pin-input'
      placeholder="Port"
      value={pinPort}
      id={cssId}
    />
  }

  getSignalPinsRender = ({ pinInfo = {}, portType, fromSignal, netType }) => {
    const { pinLWidth, pinRWidth } = this.props;
    const widthObj = { pinLWidth, pinRWidth }
    const { leftPinInfo, rightPinInfo } = getPinPortInfo(portType, pinInfo, fromSignal, netType);
    return <div className="connector-model-port-pin">
      {portType === "cable" ?
        <Fragment>
          <div className="connector-model-port-pin-content">
            <div className="connector-model-pin-type-span">{getPinTypeDisplay(pinInfo.pinLType)}</div>
            <div className="connector-model-port-pin-title"
              title={pinInfo.pinL}
              style={{ width: pinLWidth + 24 }}
            >{pinInfo.pinL}</div>
          </div>
          <div className='connection-pin-long-line'></div>
          {this.portPopover({
            pinItem: pinInfo,
            pinType: portType,
            ...leftPinInfo
          })}
          {this.portPopover({
            pinItem: pinInfo,
            ...rightPinInfo
          })}
          <div className="connector-model-port-pin-content">
            <div className="connector-model-pin-type-span">{getPinTypeDisplay(pinInfo.pinRType)}</div>
            <div className="connector-model-port-pin-title"
              title={pinInfo.pinR}
              style={{ width: pinRWidth + 24 }}
            >{pinInfo.pinR}</div>
          </div>
        </Fragment>
        :
        <Fragment>
          <div className="connector-model-port-pin-content">
            <div className="connector-model-pin-type-span">{getPinTypeDisplay(pinInfo[`${portType}Type`])}</div>
            <div className="connector-model-port-pin-title"
              title={pinInfo[`${portType}`]}
              style={{ width: widthObj[`${portType}Width`] + 24 }}
            >{pinInfo[`${portType}`]}</div>
          </div>
          <div className='connection-pin-long-line'></div>
          {this.portPopover({
            pinItem: pinInfo,
            pinType: portType,
            ...leftPinInfo
          })}
          {this.portPopover({
            pinItem: pinInfo,
            ...rightPinInfo
          })}
        </Fragment>
      }
    </div>
  }
}

export default CopySignalPinsPortSetup;