import React, { Component, Fragment } from 'react';
import VirtualComponentTable from './virtualComponentTable';
import VirtualCompEditPanel from './VirtualCompEditPanel';
import _ from 'lodash';
import "./index.css";

class VirtualComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentList: [],
      virtualComponents: [],
      unit: "mil",
      editVisible: false
    }
  }

  componentDidMount = () => {
    this.initVcComps()
  }

  componentDidUpdate = (prevProps) => {
    const { virtualComponents, verificationId, designId } = this.props;
    if (prevProps.verificationId !== verificationId
      || prevProps.virtualComponents.length !== virtualComponents.length
      || prevProps.designId !== designId
      || !_.isEqual(prevProps.virtualComponents, virtualComponents)) {
      this.initVcComps()
    }
  }

  initVcComps = () => {
    const { virtualComponents } = this.props;
    this.setState({
      virtualComponents: JSON.parse(JSON.stringify(virtualComponents || [])),
      unit: virtualComponents && virtualComponents[0] ? virtualComponents[0].unit || "mil" : "mil"
    })
  }

  addVirtualComponent = () => {
    //add
    this.setState({
      editVisible: true
    })
  }

  updateVirtualComponents = (virtualComponents) => {
    this.setState({ virtualComponents: JSON.parse(JSON.stringify(virtualComponents || [])) })
    this.props.saveVirtualComponent(virtualComponents);
  }

  closePanel = () => {
    this.setState({
      editVisible: false
    })
  }

  render = () => {
    const { designId, signals, components, product, otherVirtualComps = [] } = this.props;
    const { virtualComponents, unit, editVisible } = this.state;
    return <Fragment>
      <VirtualComponentTable
        {...this.props}
        designId={designId}
        componentList={virtualComponents}
        unit={unit}
        addVirtualComponent={this.addVirtualComponent}
        updateVirtualComponents={this.updateVirtualComponents}
      />
      {editVisible ?
        <VirtualCompEditPanel
          designId={designId}
          otherVirtualComps={otherVirtualComps || []}
          virtualComponents={virtualComponents}
          unit={unit}
          signals={signals}
          components={components}
          product={product}
          updateVirtualComponents={this.updateVirtualComponents}
          closePanel={this.closePanel}
        />
        : null}
    </Fragment>
  }
}

export default VirtualComponent;