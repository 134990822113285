import {
  getSierraEyeDiagramConfig,
  saveSierraEyeDiagramConfig,
  generateSierraEyeDiagram,
  getSierraDownloadEyeGenerateLog
} from '../../api/sierra';
import { CHECK_FAIL, SUCCESS } from '../../../constants/returnCode';
import {
  ICTypes,
  DOUBLE_EDGE,
  DOUBLE_DATA_RATE,
  NO_CLOCK,
  SINGLE,
  DIFFERENTIAL
} from '../../../pages/Sierra/constants';
import NP from 'number-precision';
import { numberCheck, roundFun, unitConversion } from '../../helper/dataProcess';
import { settingUnit } from '../index';
import { scaleKeys, Hz } from '@/services/helper/constants';
import apiProcess from '../../api/utility';
import designConstructor from '../../helper/designConstructor';

/**
 * get eye diagram config
 *
 * @param {*string} verificationId
 * @returns Promise
 */
function getEyeDiagramConfig(verificationId) {
  return new Promise((resolve, reject) => {
    getSierraEyeDiagramConfig(verificationId).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      }
    }, error => {
      reject(null);
    })
  });
}

/**
 * save eye diagram config
 *
 * @param {*string} verificationId
 * @returns Promise
 */
function saveEyeDiagramConfig({ verificationId, eyeDiagramConfig }) {
  return new Promise((resolve, reject) => {
    saveSierraEyeDiagramConfig(verificationId, eyeDiagramConfig).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve(res.data.data);
      }
    }, error => {
      reject(null);
    })
  });
}

/**
 * generate eye diagram config
 *
 * @param {*string} verificationId
 * @returns Promise
 */
function generateEyeDiagram({ verificationId, eyeDiagramConfig }) {
  return new Promise((resolve, reject) => {
    generateSierraEyeDiagram({ verificationId, eyeDiagramConfig }).then(res => {
      if (res && res.data && res.data.code === SUCCESS) {
        resolve({ status: 'success' });
      } else if (res && res.data && res.data.code === CHECK_FAIL) {
        resolve({ status: 'failed', error: res.data.msg });
      }
    }, error => {
      reject({ status: "failed", error: error.response && error.response.data && error.response.data.msg ? error.response.data.msg : null });
    })
  });
}

/**
 * generate eye diagram log message
 *
 * @param {*string} verificationId
 * @returns Promise
 */
function getDownloadEyeGenerateLog({ verificationId }) {
  return apiProcess(getSierraDownloadEyeGenerateLog, verificationId, false, false, true);
}


/**
 * Exports a clocks and signals array list
 * 
 * @param {Object} components - components
 * @exports clocks,signals,receiverSignals,DDRSignals
 */
const CLK = "CLK", CLKP = "CLK+", CLKN = "CLK-";
function getEyeDiagramSignals(components, interfaces) {
  const isMultiPCB = interfaces.length > 1;
  let clocks = [], signals = [], receiverSignals = [], DDRSignals = [], clkPositiveList = [], clkNegativeList = [], clkList = [], allSignalPins = [];
  const IN = ["nd_in", "nd_en"];

  if (!components) {
    return { clocks, signals, receiverSignals, DDRSignals, allSignalPins }
  }
  let comps = components.filter(item => ICTypes.includes(item.type) && item.pins && item.pins.length);
  if (!comps.length) {
    return { clocks, signals, receiverSignals, DDRSignals, allSignalPins };
  }
  let portInfo = {}, preLayoutPcbs = [];
  for (let info of interfaces) {
    //PCBID is PCBId, pcbId is SubId
    const isPreLayout = designConstructor.isPreLayout(info.PCBID);
    if (isPreLayout) {
      preLayoutPcbs.push(info.pcbId);
      continue;
    }
    const port_setups = info.content && info.content.port_setups ? info.content.port_setups : [];
    portInfo[info.pcbId] = port_setups.map(item => { return item.positive ? { component: item.positive.component, pin: item.positive.pin } : null }).filter(item => !!item)
  }

  comps.forEach(comp => {
    //all signals
    const ports = portInfo[comp.pcbId] || [];

    const allPins = comp.pins.filter(item => (item.usage === 'Receiver' || item.usage === 'Driver')
      && (preLayoutPcbs.includes(comp.pcbId) || ports.find(it => it.component === comp.name && it.pin === item.pin)));

    let newSignals = allPins.map(item => item.signal);
    signals = [...new Set([...signals, ...newSignals])];
    allSignalPins = [...allSignalPins, ...allPins.filter(item => item.usage === 'Receiver').map(item => {
      const display = isMultiPCB ? `${comp.name} - ${item.pin} :: ${comp.pcb}` : `${comp.name} - ${item.pin}`;
      return {
        component: comp.name,
        pin: item.pin,
        signal: item.signal,
        pcbId: comp.pcbId,
        pcb: comp.pcb,
        name: `${comp.name}-${item.pin}::${comp.pcbId}`,
        display,
      }
    })];

    //receiver signals
    let receiverPins = allPins.filter(item => item.usage === 'Receiver');
    let newReceiverSignals = receiverPins.map(item => item.signal);
    receiverSignals = [...new Set([...receiverSignals, ...newReceiverSignals])];

    //clocks -- driver and nd_in / nd_en === CLK
    let DriverPins = comp.pins.filter(item => item.usage === 'Driver' && item.pinModels && item.pinModels.length);

    DriverPins.forEach(pin => {
      pin.pinModels.forEach(item => {

        if (IN.includes(item.pinName)) {

          if (item.type === CLKP) {
            clkPositiveList.push(pin.signal);
          }
          if (item.type === CLKN) {
            clkNegativeList.push(pin.signal);
          }
          if (item.type === CLK) {
            clkList.push(pin.signal);
          }

          if (item.type && item.type.match("DDR")) {
            DDRSignals.push(pin.signal);
          }
        }
      })
    })
  });

  clkPositiveList = [...new Set([...clkPositiveList])];
  clkNegativeList = [...new Set([...clkNegativeList])];
  clkList = [...new Set([...clkList])];
  const clkPositive = clkPositiveList && clkPositiveList.length ? clkPositiveList[0] : "";
  const clkNegative = clkNegativeList && clkNegativeList.length ? clkNegativeList[0] : "";

  if (!clkPositive && !clkNegative) {
    clocks = [...clkList];
  } else {
    clocks = [clkPositive, clkNegative].filter(item => !!item);
    clocks = [...clocks, ...clkList];
  }
  clocks = clocks.filter((item, index) => index < 2);

  clocks = [...new Set([...clocks])];
  DDRSignals = [...new Set([...DDRSignals])];
  receiverSignals = receiverSignals.filter(item => !clocks.includes(item));
  return { clocks, signals, receiverSignals, DDRSignals, allSignalPins }
}

/**
 * Exports a clocks and signals array list
 * 2019/12/31
 * 
 * @param {Object} components - components
 * @exports clocks,signals
 */
function getEyeDiagramsInfo(components) {
  /* let clocks = [], signals = [];

  if (!components) {
    return { clocks, signals }
  }
  let comps = components.filter(item => item.type === 'Chip' && item.pins && item.pins.length);
  if (!comps.length) {
    return { clocks, signals };
  }
  comps.forEach(comp => {
    let DriverPins = comp.pins.filter(item => item.usage === 'Driver' && item.pinModels && item.pinModels.length);
    if (!DriverPins.length) {
      return { clocks, signals };
    }
    DriverPins.forEach(pin => {

      pin.pinModels.forEach(item => {

        if (item.pinName === 'nd_in' || item.pinName === 'nd_en') {

          if (item.type === 'CLK') {
            clocks.push(pin.signal)
          } else {
            signals.push(pin.signal)
          }
        }
      })
    })
  })
  clocks = [...new Set(clocks)];
  signals = [...new Set(signals)];
  return { clocks, signals } */
  let signals = [];

  if (!components) {
    return []
  }
  let comps = components.filter(item => ICTypes.includes(item.type) && item.pins && item.pins.length);
  if (!comps.length) {
    return [];
  }
  comps.forEach(comp => {
    let DriverPins = comp.pins.filter(item => item.usage === 'Driver' && item.pinModels && item.pinModels.length);
    if (!DriverPins.length) {
      return [];
    }
    DriverPins.forEach(pin => {
      signals.push(pin.signal)
    })
  })
  signals = [...new Set(signals)];
  return signals;
}

/**  
 * get default ui by trigger 
 * When 'Double Data Rate' or 'Double Edge' is selected, the UI should be set to half of the clock cycle. Otherwise it should be a full clock cycle.
 * ui ===> Double == 1/2 * (1/Clock) 
 * ui ===> Single == 1/Clock
 */
function getEyeDefaultUI(clock, trigger) {
  let ui = "";
  if ([DOUBLE_EDGE, DOUBLE_DATA_RATE].includes(trigger)) {
    ui = NP.times(0.5, NP.divide(1, clock));
  } else {
    ui = NP.divide(1, clock);
  }

  if (ui >= 0.001) {
    ui = roundFun(ui, 3);
  } else {
    ui = ui.toPrecision(3);
  }
  return ui.toString();
}


function getEyeDefaultVRef(currentInterfaceInfo) {
  const powerNets = currentInterfaceInfo && currentInterfaceInfo.powerNets ? currentInterfaceInfo.powerNets.filter(item => (!!item.value) && !numberCheck(item.value)) : [];
  if (!powerNets || !powerNets.length) {
    return "0";
  }
  const powerNetsVoltage = powerNets.map(item => item.value);

  if (!powerNetsVoltage || !powerNetsVoltage.length) {
    return "0";
  }
  const maxVoltage = Math.max(...powerNetsVoltage)
  const voltage = maxVoltage ? maxVoltage : 0;
  let v_ref = NP.times(0.5, voltage);

  if (v_ref >= 0.001) {
    v_ref = roundFun(v_ref, 3);
  } else {
    v_ref = v_ref.toPrecision(3);
  }
  return v_ref.toString();
}

/**  
 * get default time_scale by ui 
 * No input from user, just select from ps(1e-12), ns(1e-9) or us(1e-6), default to the time unit closest to the UI
 */
function getDefaultTimeScale(ui) {
  let timeScaleList = ["1e-12", "1e-9", "1e-6"];
  timeScaleList.sort(function (a, b) {
    return Math.abs(a - ui) - Math.abs(b - ui);
  });
  let time_scale = timeScaleList[0];
  //1e-12 ==> 1e12
  time_scale = time_scale.replace("-", "");
  return time_scale;
}
/**  
 * get Simulation Clock 
 * simulationConfig:{ clock: "100M" }
 */
function getSimulationClock(simulationConfig) {
  const clockObj = simulationConfig && simulationConfig.clock ? settingUnit(simulationConfig.clock) : { value: 1, unit: "M" };
  //unit conversion scale
  const scale = unitConversion(Hz, scaleKeys[clockObj.unit]);
  const clock = NP.times(clockObj.value, scale);
  return clock;
}

function checkEyeDiagramError({ eyeDiagramConfig, eyeClockType, clockPositive, clockNegative, }) {
  let errors = [], signalCheck = false;
  if (!eyeDiagramConfig || !eyeDiagramConfig.eye_config) {
    errors.push("No eyeDiagramConfig, please check.")
  }
  switch (eyeClockType) {
    case NO_CLOCK:
      signalCheck = true;
      break;
    case SINGLE:
      if (!eyeDiagramConfig.eye_config.clocks || !eyeDiagramConfig.eye_config.clocks.length) {
        errors.push("No clock signal selected.")
      }
      signalCheck = true;
      break;
    case DIFFERENTIAL:
      if (!clockPositive || !clockPositive.length) {
        errors.push("No positive clock signal selected.")
      }
      if (!clockNegative || !clockNegative.length) {
        errors.push("No negative clock signal selected.")
      }
      signalCheck = true;
      break;
    default: break;
  }
  if (signalCheck &&
    (!eyeDiagramConfig.eye_config.signals
      || !eyeDiagramConfig.eye_config.signals.length
    )) {
    errors.push("No signals selected.")
  }
  return errors.length ? errors : null;
}

export {
  getEyeDiagramConfig,
  saveEyeDiagramConfig,
  generateEyeDiagram,
  getEyeDiagramSignals,
  getEyeDiagramsInfo,
  getEyeDefaultUI,
  getEyeDefaultVRef,
  getDefaultTimeScale,
  getSimulationClock,
  checkEyeDiagramError,
  getDownloadEyeGenerateLog
}