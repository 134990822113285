import { MODEL, TX, RX, CLK } from ".";
import { CPHY, ETHERNET, GENERIC, HDMI, PCIE } from "../../PCBHelper/constants";
import { updateComponentPinsBySignal } from "./preLayoutHelper";
import { DefaultPreLayout } from './IntegratedPreLayout';
import { PreLayoutSignal, PreLayoutComponent, PreLayoutSignalGroup, preLayoutModelInfo, PreLayoutSignalCPHY } from '../../PreLayout';
import { strDelimited } from "../../helper/split";
import { getDefaultName } from "../../helper/setDefaultName";
import { numberCheck, unitConversion } from "../../helper/dataProcess";
import { scaleKeys } from "../../helper/constants";
import { unitChange } from "../../helper/mathHelper";
import { IC, CONNECTOR, DIE, BGA } from '../../../constants/componentType';
import { TOUCHSTONE_SPICE } from "../../../constants/libraryConstants";
import { PACKAGE } from '../../../constants/designType'

const U1 = { name: "U1", type: IC, layer: "Top" }, U2 = { name: "U2", type: CONNECTOR, layer: "Top" }, J1 = { name: "J1", type: CONNECTOR, layer: "Top" },
  DIE_Comp = { name: "DIE", type: DIE, layer: "Top" }, BGA_Comp = { name: "BGA", type: BGA, layer: "Top" };
/** 
 * default pre layout components
*/
function preLayoutDefaultComps(signal_groups, type, designType) {
  const compData = designType === PACKAGE ? [DIE_Comp, BGA_Comp] : type === CPHY ? [U1, J1] : [U1, U2]
  const components = compData.map(item => new PreLayoutComponent(item.name, { ...item }));
  //add components pins by signals
  /*   const newSignals = signal_groups.reduce((prev, curr) => {
      if (prev.signals) {
        return [...prev.signals, ...curr.signals]
      }
      return [...prev, ...curr.signals]
    }); */
  const newSignals = getPreLayoutSignalList(signal_groups);
  let _components = updateComponentPinsBySignal({ newSignals, components });
  return _components;
}

function getPreLayoutSignalList(signal_groups = []) {
  const _signal_groups = JSON.parse(JSON.stringify(signal_groups));
  let list = [];
  for (let group of _signal_groups) {
    for (let signal of group.signals) {
      list.push({
        group: group.name,
        ...signal
      })
    }
  }
  return list;
}

/** 
 * default pre layout signal_groups
 * @param {string} prelayout -> physical / model
 * @param {string} type -> PCIe / Ethernet / HDMI / ...
 * @param {string} width -> PCIe -> x1, x2, x3, x4, ... x32
*/
function getPreLayoutSignalGroups({ prelayout, type, width, diffPairsNumber, netPrefix, selectedGroups, lanesNumber }) {
  let signal_groups = [];
  if (!prelayout || !type) {
    return signal_groups;
  }
  const { groupList, diffPairsNum } = getDefaultGroupList({ type, width, diffPairsNumber, netPrefix, selectedGroups, lanesNumber });

  signal_groups = groupList.map(group => {
    return new PreLayoutSignalGroup({
      group,
      signals: physicalSignals(type, diffPairsNum, group, netPrefix)
    })
  });
  return signal_groups;
}

function getDefaultGroupList({ type, width, diffPairsNumber, netPrefix, selectedGroups, lanesNumber }) {
  let groupList = [], diffPairsNum = "0";
  switch (type) {
    case PCIE:
    case ETHERNET:
      groupList = [...selectedGroups];
      diffPairsNum = width ? strDelimited(width, "", { startSubscript: 1 }).join("") : "0";
      break;
    case GENERIC:
      groupList = netPrefix ? [netPrefix] : [type];
      diffPairsNum = diffPairsNumber || "0";
      break;
    case HDMI:
      groupList = [...selectedGroups];
      diffPairsNum = diffPairsNumber || "0";
      break;
    case CPHY:
      groupList = [type];
      diffPairsNum = String(lanesNumber) || "0";
      break;
    default: break;
  }
  return { groupList, diffPairsNum }
}


/** 
 * default pre layout physical signals
 * @param {string} type -> PCIe / Ethernet / HDMI / ...
 * @param {string} diffPairsNum -> diff pairs number
 * @param {string} group -> TX / RX / CLK
*/
function physicalSignals(type, diffPairsNum, group, netPrefix) {
  const sigName = getSignalName({ type, group, netPrefix });
  let signalLength = parseInt(diffPairsNum);
  let signalList = [];

  if (group === CLK) {
    //add CLK signal
    // eg ->  net: PCIEREFCLK+
    const clk = preLayoutAddSignal({
      signalType: CLK,
      name: sigName,
      type,
      index: 0,
      group,
      netPrefix
    });
    signalList.push(clk);
    return signalList;
  }

  for (let i = 0; i < signalLength; i++) {
    const signalName = `${sigName}${i}`;
    //add signal by group
    const _signal = preLayoutAddSignal({
      signalType: "signal",
      name: signalName,
      type,
      index: i,
      group,
      netPrefix
    });
    signalList.push(_signal);
  }

  return signalList;
}

function preLayoutAddSignal({ signalType, name, type, index, group, allNetNames, netPrefix }) {

  if (type === CPHY) {
    const net_A = netName(signalType, { type, index, dir: "A", netPrefix }),
      net_B = netName(signalType, { type, index, dir: "B", netPrefix }),
      net_C = netName(signalType, { type, index, dir: "C", netPrefix });

    const signal = new PreLayoutSignalCPHY({
      name,
      index,
      nets_A: net_A ? [net_A] : [],
      nets_B: net_B ? [net_B] : [],
      nets_C: net_C ? [net_C] : []
    });
    return signal;
  } else {
    const net_P = netName(signalType, {
      type,
      index,
      group,
      dir: "P",
      allNetNames,
      netPrefix
    }), net_N = netName(signalType, {
      type,
      index,
      group,
      dir: "N",
      allNetNames,
      netPrefix
    });

    //add TX
    const signal = new PreLayoutSignal({
      name,
      index,
      nets_P: net_P ? [net_P] : [],
      nets_N: net_N ? [net_N] : []
    });
    return signal;
  }
}

/* default net name */
function netName(signalType, { type, index, group, dir, allNetNames, netPrefix }) {
  switch (type) {
    case PCIE:
      //PCIEREFCLK
      return signalType === CLK ? `PCIEREFCLK_${dir}` : `PCIE${index}_${group}_${dir}`;
    case ETHERNET:
      return signalType === CLK ? `ETHERREFCLK_${dir}` : `ETHER${index}_${group}_${dir}`;
    case GENERIC:
      const defaultKey = netPrefix ? netPrefix : type;
      let _name = `${defaultKey}${index}_${dir}`;
      if (allNetNames) {
        _name = getDefaultName({ nameList: allNetNames, defaultKey, key: "", firstIndex: Number(index), endDelimiter: dir });
      }
      return _name;
    case HDMI:
      const _netPrefix = netPrefix ? netPrefix : HDMI;
      return signalType === CLK ? `${_netPrefix}_CLK_${dir}` : `${_netPrefix}${index}_${dir}`;
    case CPHY:
      const _netPrefixCPHY = netPrefix ? netPrefix : CPHY;
      return `${_netPrefixCPHY}${index}_${dir}`;
    default: return null;
  }
}

function getSignalName({ type, group, netPrefix }) {
  let obj = { sigTXName: TX, sigRXName: RX, sigCLKName: CLK };
  switch (type) {
    case PCIE:
    case ETHERNET:
      obj = {
        sigTXName: TX,
        sigRXName: RX,
        sigCLKName: CLK
      }
      break;
    case GENERIC:
      const sigGenericName = netPrefix ? netPrefix : type;
      if (group) {
        return sigGenericName;
      } else {
        return { sigGenericName }
      }
    case HDMI:
      const signalPrefix = netPrefix ? netPrefix : HDMI;
      obj = {
        sigHDMIName: signalPrefix,
        sigCLKName: `${signalPrefix}_CLK`
      }
      break;
    case CPHY:
      const signalPrefixCPHY = netPrefix ? netPrefix : HDMI;
      obj = { sigCPHYName: signalPrefixCPHY }
      break;
    default: break;
  }
  if (group) {
    return obj[`sig${group}Name`];
  }

  return obj;
}

// 初始化 pre layout info
function getDefaultPreLayoutInfo({
  prelayout = MODEL,
  type = PCIE,
  width = "x1",
  diffPairsNumber = "",
  netPrefix = "",
  selectedGroups = [TX, RX],
  notClearPairs = false,
  lanesNumber = 1,
  designType
}) {
  const signal_groups = getPreLayoutSignalGroups({ prelayout, type, width, diffPairsNumber, netPrefix, selectedGroups, lanesNumber });
  const components = preLayoutDefaultComps(signal_groups, type, designType);
  const preLayoutInfo = new DefaultPreLayout({
    signal_groups,
    components,
    prelayout,
    type,
    width,
    diffPairsNumber,
    netPrefix,
    selectedGroups: signal_groups.map(item => item.name),
    designType
  });

  if (!notClearPairs) {
    preLayoutInfo.preLayoutModel = prelayout === MODEL ? new preLayoutModelInfo(TOUCHSTONE_SPICE) : {}
  }
  return preLayoutInfo;
}

/** 
 * mil,mm,um 
 * 1mil = 0.0254mm
 * 1mil = 25.4um
 * 1um = 0.03937mil
 * 1mm = 39.3701mil
 * 1mm = 1e3um
 * 1um = 1e-3mm
 * mm > mil > um
 */

const UM = 'um', MM = "mm"/* , MIL = "mil" */;
function getPreLayoutUnitScale(unit, prevUnit) {
  if ([UM, MM].includes(unit) && [UM, MM].includes(prevUnit)) {
    return unitConversion(scaleKeys[unit], scaleKeys[prevUnit]);
  } else if ([UM, MM].includes(unit)) {
    if (unit === UM) {
      return 25.4;
    }
    if (unit === MM) {
      return 0.0254;
    }
  } else if ([UM, MM].includes(prevUnit)) {
    if (prevUnit === UM) {
      return 0.0393701;
    }
    if (prevUnit === MM) {
      return 39.3701;
    }
  }
}

function updateSignalSectionUnit(signal_groups, unit, prevUnit) {
  let _signal_groups = [...signal_groups];

  for (let group of _signal_groups) {
    for (let signal of group.signals) {
      signal.sections.forEach(item => {
        if (item.length && !numberCheck(item.length)) {
          item.length = unitChange({ num: item.length, oldUnit: prevUnit, newUnit: unit, decimals: 4 }).number;
        }
      })
    }
  }
  return _signal_groups;
}

function getDefaultPreLayoutInfoByGroups(preLayoutInfo, selectedGroups) {
  let _preLayoutInfo = { ...preLayoutInfo };
  let _signal_groups = [...preLayoutInfo.signal_groups],
    _components = [...preLayoutInfo.components];
  const { type, width, netPrefix } = preLayoutInfo;
  //remove signal_groups and components pins by selected Groups
  _signal_groups = _signal_groups.filter(item => selectedGroups.includes(item.name));
  //signal_group
  _components.forEach(comp => {
    comp.pins = comp.pins.filter(item => selectedGroups.includes(item.signal_group))
  })

  const newGroups = selectedGroups.filter(item => !_signal_groups.find(it => it.name === item));
  let new_signal_groups = [];
  if (newGroups.length) {
    //add new groups
    const diffPairsNum = width ? strDelimited(width, "", { startSubscript: 1 }).join("") : "0";
    new_signal_groups = newGroups.map(group => {
      return new PreLayoutSignalGroup({
        group,
        signals: physicalSignals(type, diffPairsNum, group, netPrefix)
      })
    });
    //add pins by new signals
    const newSignals = getPreLayoutSignalList(new_signal_groups);
    _components = updateComponentPinsBySignal({
      newSignals,
      components: _components
    });
  }

  _signal_groups = [..._signal_groups, ...new_signal_groups];
  _preLayoutInfo.signal_groups = _signal_groups;
  _preLayoutInfo.components = _components;
  _preLayoutInfo.selectedGroups = selectedGroups;
  return _preLayoutInfo;
}

function getPreLayoutCompPinList(components, signals) {
  let findICComp = components.find(item => [IC, DIE].includes(item.type));
  let findConnectorComp = components.find(item => [CONNECTOR, BGA].includes(item.type));

  let pinList = [];
  if (!findICComp) { // connector is not empty and IC is empty
    findICComp = components.find(item => [CONNECTOR, DIE].includes(item.type) && item.name !== (findConnectorComp || {}).name);
  } else if (!findConnectorComp) { // IC is not empty and connector is empty
    findConnectorComp = components.find(item => [IC, DIE].includes(item.type) && item.name !== (findICComp || {}).name);
  }

  if (!findICComp || !findConnectorComp) {
    return []
  }

  const rightPins = [...findConnectorComp.pins];
  for (let pinInfo of findICComp.pins) {
    const { net } = pinInfo;
    const rightInfo = rightPins.find(item => item.net === net);
    pinList.push({ ...pinInfo, rightPin: rightInfo.pin, rightComp: findConnectorComp.name, leftComp: findICComp.name })
  }

  const nets = signals.map(item => {
    return [
      ...(item.nets_P || []),
      ...(item.nets_N || []),
      ...(item.nets_A || []),
      ...(item.nets_B || []),
      ...(item.nets_C || [])
    ]
  }).flat();
  pinList.sort((a, b) => {
    const aIndex = nets.findIndex(item => item === a.net),
      bIndex = nets.findIndex(item => item === b.net);
    return aIndex - bIndex;
  })
  return pinList
}

function getPreLayoutSignals(signal_groups, type) {
  let _signals = [];
  for (let signalInfo of signal_groups) {
    const { name, signals } = signalInfo;
    signals.forEach(item => {
      if (type === CPHY) {
        _signals.push({ nets_A: item.nets_A, nets_B: item.nets_B, nets_C: item.nets_C, name: item.name, group: name })
      } else {
        _signals.push({ nets_P: item.nets_P, nets_N: item.nets_N, name: item.name, group: name })
      }
    })
  }
  return _signals
}

export {
  preLayoutDefaultComps,
  getPreLayoutSignalGroups,
  getDefaultPreLayoutInfo,
  updateSignalSectionUnit,
  getPreLayoutUnitScale,
  getDefaultPreLayoutInfoByGroups,
  getPreLayoutSignalList,
  getPreLayoutCompPinList,
  getPreLayoutSignals
}