import React, { Component, Fragment } from "react";
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import Table from '@/components/EditableTable';
import {
  getPortsTableData,
  getPortColumns,
  getReferenceText,
  getPortType,
  PIN_GROUP,
  SINGLE_PIN,
  NEARBY_PINS,
  GAP,
  CIRCUIT,
  PIN_PER_REF_NET
} from '../../services/ExtractionPortsHelper';
import EditPortPanel from "./EditPort";
import PortReferencePinEdit from './portReferencePinEdit';

class PortsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openEditPortInfo: null,
      editVisible: false,
      tableCellVisible: false
    }
    this.portsColumns = getPortColumns();
    this.columnsOnCell();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  columnsOnCell = () => {
    this.portsColumns.forEach((item, index) => {
      switch (item.key) {
        /*  case "portGenerationUsage":
           item.onCell = (record) => {
             return {
               edit: "select",
               record,
               options: PORT_GENERATION_USAGE_LIST,
               dataIndex: item.dataIndex,
               handleSave: (record) => { this.editGenerationUsage(record) },
             }
           };
           break; */
        case "pin":
          item.render = (data, record) => {
            return <span>{record.component} - {record.pin}</span>
          }
          break;
        case "edit":
          item.render = (data, record) => {
            return (
              <div className="extraction-port-edit">
                <EditOutlined onClick={(e) => this.editPort(e, record)} />
              </div>
            );
          }
          break;
        case "reference":
          item.title = () => {
            const allowEdit = this.props.ports_generate_setup_list.find(item => [PIN_GROUP, GAP, CIRCUIT].includes(item.setup.portType) && [SINGLE_PIN, NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType));
            return (
              <div>
                <span>Reference Pin</span>
                {allowEdit ? <EditOutlined
                  title="Edit Reference Pin"
                  className="port-setup-edit-reference-icon"
                  onClick={this.openEditReferencePin} /> : null}
              </div>
            );
          }

          item.render = (data, record) => {
            const { generateRefNets } = this.props;
            const text = getReferenceText(data, record, generateRefNets);
            return {
              children: <div
                className='extraction-ports-table-reference'
                title={text}
              >{text}</div>,
              props: {
                tdClick: (e) => this.updateTableCell(e, true)
              }
            }
          }

          item.onCell = (record) => {
            const { generateRefNets, port_setups, referenceNets, ports_generate_setup_list } = this.props;
            const text = getReferenceText(record.reference, record, generateRefNets);
            const components = ports_generate_setup_list.filter(item => [PIN_GROUP, GAP, CIRCUIT].includes(item.setup.portType) && [SINGLE_PIN, NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)).map(item => item.component);

            return components.length && components.includes(record.component) ? {
              ...this.props,
              record,
              edit: true,
              customInput: PortReferencePinEdit,
              dataIndex: 'reference',
              text,
              isSinglePositivePin: record,
              port_setups,
              generateRefNets,
              referenceNets,
              setupList: ports_generate_setup_list,
              editVisible: this.state.editVisible,
              closeEditRefPin: this.closeEditRefPin,
              updateTableCell: this.updateTableCell
            } : { edit: false }
          }
          break;
        case "portType":
          item.render = (data, record) => {
            return getPortType(data);
          };
          break;
        case "portIndex":
          if (this.props.viewPinsIconVisible) {
            item.title = () => {
              return (
                <div>Port Index
                  <EyeOutlined
                    className="aurora-select-nets-icon"
                    title={'Display all nets to layout.'}
                    onClick={() => this.netSelect(true)} />
                </div>
              );
            }
          }
          break;
        default: break;
      }
    })
  }

  netSelect = (allSelect, record) => {
    const { product, netSelectChange } = this.props;
    if (product === 'ROCKY') {
      netSelectChange(allSelect, record)
    }
  }

  editGenerationUsage = (record) => {
    const { component, pin, portGenerationUsage } = record;
    const { port_setups } = this.props;
    let _port_setups = [...port_setups];
    const index = _port_setups.findIndex(item => item.positive && item.positive.component === component && item.positive.pin === pin);
    if (index < 0) {
      return;
    }
    _port_setups[index].portGenerationUsage = portGenerationUsage;
    this.props.updatePortSetups(_port_setups);
  }

  editPort = (e, record) => {
    this.setState({
      openEditPortInfo: { ...record }
    })
  }

  closeEditPort = (portInfo) => {
    const { component, pin, info, portType, reference } = portInfo;
    const { port_setups } = this.props;
    let _port_setups = [...port_setups];
    const index = _port_setups.findIndex(item => item.positive && item.positive.component === component && item.positive.pin === pin);
    if (index < 0) {
      return;
    }
    _port_setups[index] = {
      ..._port_setups[index],
      info: { ...info },
      type: portType,
      negative: { ...reference }
    };
    this.props.updatePortSetups(_port_setups);
    //Update port
    this.setState({
      openEditPortInfo: null
    })
  }

  openEditReferencePin = () => {
    this.setState({
      editVisible: true
    })
  }

  closeEditRefPin = () => {
    this.setState({
      editVisible: false
    })
  }

  updateTableCell = (e, tableCellVisible) => {
    e && e.stopPropagation();
    this.setState({
      tableCellVisible
    })
  }

  render() {
    const { openEditPortInfo, editVisible, tableCellVisible } = this.state;
    const { port_setups, scroll, generateRefNets, ports_generate_setup_list } = this.props;
    const dataList = getPortsTableData(port_setups);
    return (
      <Fragment>
        <Table
          rowKey={record => record.portIndex}
          columns={this.portsColumns}
          size="small"
          dataSource={dataList ? dataList : []}
          scroll={scroll}
          className='extraction-ports-table space-10'
          Table
          onRow={record => {
            return {
              onClick: e => this.netSelect(false, record),
            };
          }}
        />
        {openEditPortInfo ? <EditPortPanel
          {...this.props}
          {...openEditPortInfo}
          portTable={true}
          closeEditPort={this.closeEditPort}
        /> : null}
        {editVisible ? <PortReferencePinEdit
          {...this.props}
          generateRefNets={generateRefNets}
          setupList={ports_generate_setup_list}
          port_setups={port_setups}
          tableCellVisible={tableCellVisible}
          closeEditRefPin={this.closeEditRefPin}
          updateTableCell={this.updateTableCell}
        /> : null}
      </Fragment>
    )
  }
}

export default PortsTable;