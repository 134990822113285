import React, { Component } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import TextEditor from './Editor';
import richTextData from '@/services/helper/data/richTextData';
import './index.css';

class RichTextEditor extends Component {

  getRichTextEditor = (item) => {
    const { product, type } = this.props;
    return <div onClick={this.inputClick}>
      <TextEditor
        onRef={this.onTextEditorRef}
        updateTextEditorStatus={this.props.updateTextEditorStatus}
        product={product}
        type={type}
        editId={item.id}
      />
    </div>
  }

  onTextEditorRef = (ref) => {
    this.textEditorRef = ref;
  }

  closeTextEditor = (visible) => {
    if (!visible && this.textEditorRef) {
      this.textEditorRef.submitContent();
      this.props.updateTextEditorStatus();
    }
  }

  inputClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { item, product, type } = this.props;
    const htmlData = richTextData.getHtml(product, type, item.id);
    return <Popover
      placement="rightTop"
      content={this.getRichTextEditor(item)}
      trigger="click"
      onClick={this.inputClick}
      overlayClassName="text-editor-popover"
      getPopupContainer={() => document.getElementById('root')}
      onOpenChange={(visible) => { this.closeTextEditor(visible) }}
    >
      <Tooltip
        overlayClassName="aurora-tooltip rich-text-tooltip"
        onClick={this.inputClick}
        title={htmlData ? <div className="text-editor-tooltip" dangerouslySetInnerHTML={{ __html: htmlData }}></div> : ""}
      >
        <FileTextOutlined
          className={`aurora-tree-info-icon`}
          onClick={this.inputClick}
          title={"Note"} />
      </Tooltip>
    </Popover>
  }
}

export default RichTextEditor;