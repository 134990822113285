import LayoutRenderer from './LayoutRenderer';
import { strFormatChange } from '@/services/helper/stringHelper'

class LayoutCanvas {
  constructor() {
    this.layout = null; // layoutDB
    this.layoutRenderer = null;
    this.clickMousePosition = {};
  }

  /**
   * create and initial layerRenderer by layout db
   * @param {dom} svg dom element to be rendered
   * @param {object} layoutDB layout data object
   * @param {dom} locationSvg location dom element
   * @param {object} events zoom and mousemove event functions
 */
  initLayoutRender = (svg, layoutDB, locationSvg, events) => {
    this.layout = layoutDB;
    this.layoutRenderer = new LayoutRenderer(svg, layoutDB, locationSvg, events, this.updateClickMousePosition);
    this.layoutRenderer.drawStackupZonesBox();
    this.layoutRenderer.resetCanvas();
    this.layoutRenderer.fitView();
    this.layoutRenderer.addSelectionLyr();
    this.layout.GetLayerManager().GetAllMetalLayers().mValues.forEach(name => {
      this.layoutRenderer.addMetalLayer(name);
    });
    // init layer select
    const settings = this.layout.GetLayoutSettings();
    if (settings.mLayerVisibility
      && settings.mLayerVisibility.mKeys
      && settings.mLayerVisibility.mKeys.length
    ) {
      settings.mLayerVisibility.mKeys.forEach(d => {
        settings.SetLayerVisible(d, false);
      })
    }

    let nets = this.layout.mNetManager.mNetList.mKeys, components = [];
    const metalLyrs = this.layout.mLayerMgr.mMetalLayers;
    if (metalLyrs && metalLyrs.length) {
      for (let metalItem of metalLyrs) {

        if (!metalItem.mComponentLayer) {
          continue;
        }
        const layer = metalItem.mComponentLayer;

        if (!layer.mComponents || !layer.mComponents.length) {
          continue;
        }
        components = components.concat(layer.mComponents.map(function (comp) {
          return comp.mName;
        }));
      }
    }

    nets = [...new Set(nets)];
    components = [...new Set(components)];

    this.nets = nets.map(function (net) {
      return net;
    });
    this.components = components.map(function (comp) {
      return comp;
    })
  }

  rebindSvg = (svg, locationSvg, events) => {
    console.time('test for rebindSvg')
    this.layoutRenderer.resetSvg(svg, locationSvg, events, this.updateClickMousePosition);
    this.layoutRenderer.resetCanvasAfterSvgNodeChange();
    this.layoutRenderer.fitView();
    this.layoutRenderer.addSelectionLyr();
    console.timeEnd('test for rebindSvg')
  }

  /**
   * draw shownLayers and hide hiddenLayers
   * @param {array} shownLayers the display layer names
   * @param {array} hiddenLayers the hidden layer names
   */
  updateLayers = (shownLayers, hiddenLayers, showName = false) => {
    const settings = this.layout.GetLayoutSettings();
    hiddenLayers.forEach(name => {
      settings.SetLayerVisible(name, false);
    });
    shownLayers.forEach(name => {
      settings.SetLayerVisible(name, true);
    });
    if (showName) {
      // When a layer is hidden, the name of the comp of that layer is also hidden
      this.layoutRenderer.changLayerCompsName(shownLayers, hiddenLayers);
    }
    this.layoutRenderer.updateLayerVisibility();
    this.layoutRenderer.updateColoring();
  }

  /**
 * find components in which layers
 * @param {array} names selected components' names
 */
  findCurrentLayer = (names) => {
    if (names.length && names.length > 0) {
      let layers = this.layoutRenderer.getCurrentLayer(names);
      return layers;
    } else {
      return [];
    }
  }

  fitView = () => {
    this.layoutRenderer.fitView();
  }

  zoomIn = () => {
    this.layoutRenderer.zoom(1.272);
  }

  zoomOut = () => {
    this.layoutRenderer.zoom(1 / 1.272);
  }

  search = (value) => {
    const _search = (arr) => {
      if (!value) {
        return arr || [];
      } else {
        const _value = strFormatChange(value)
        let reg = new RegExp(`(${_value})`, 'ig');
        return arr ? arr.filter(function (item) {
          return item.match(reg);
        }) : []
      }
    }
    const reg = /^[-\+]?\d+(\.\d+)?\,[-\+]?\d+(\.\d+)?$/;
    const keyword = value.replace(/[( )]/g, "");
    if (keyword.match(reg) !== null) {
      const [x, y] = keyword.split(',');
      this.layoutRenderer.zoomToLocate(parseFloat(x), parseFloat(y));
    } else if (value) {
      return {
        nets: _search(this.nets),
        components: _search(this.components)
      };
    }
  }

  /**
   * change the color model in canvas 
   * @param {'layer' | 'net' } colorBy 
   */
  changeColorMode = (colorBy) => {
    this.layout.GetLayoutSettings().SetColorByLayer(colorBy === 'layer');
    this.layoutRenderer.updateColoring();
  }

  getLayerColor = (layer) => {
    return this.layout.GetLayoutSettings().GetLayerColor(layer);
  }

  selectNetCompPin = ({ nets = [], comps = [], pins = {}, showName = false, layoutDB, designId, connectPins = {} }, rate) => {
    let _nets = [].concat(nets), _comps = [].concat(comps), _pins = pins ? JSON.parse(JSON.stringify(pins)) : {}, _connectPins = connectPins ? JSON.parse(JSON.stringify(connectPins)) : {};
    // nets
    // 1. Highlight component layer
    let opacity = true;
    if ((!_nets.length && !_comps.length && !Object.keys(pins).length) || _comps.length) {
      opacity = false
    }
    this.layoutRenderer.removePort();
    this.layoutRenderer.transparentCompLyr(opacity);
    // 2. Highlight or darken all net
    if (_nets.length || _comps.length) {
      this.layoutRenderer.darkenAllNets();
    } else {
      this.layoutRenderer.unDarkenAllNets();
    }
    // 3. Select net
    this.layoutRenderer.deselectWholeNet();
    this.layoutRenderer.highlightSelectedNets(_nets, showName, designId, layoutDB);
    // 4. Select component
    this.layoutRenderer.deselectComponents();
    this.layoutRenderer.highlightSelectedCompsPins(_comps, _pins, showName, _connectPins);
    // 5. Zoom
    this.layoutRenderer.zoomToCompNetSelectionBox({ nets: _nets, comps: _comps }, rate);
  }

  previewBoundary = (boundaries, vertices) => {
    let times = 0;
    const timeout = setInterval(() => {
      times++;
      if (this.layoutRenderer) {
        this.layoutRenderer.drawBoundaries(boundaries, vertices);
        clearInterval(timeout)
      }
      if (times === 120) {
        clearInterval(timeout)
      }
    }, 1000)
  }

  cleanPreview = () => {
    if (this.layoutRenderer) {
      this.layoutRenderer.cleanBoundaries();
    }
  }

  /**
   * Select ports groups
   * 
   * ports - { [comp]: [pin], [comp1]: [pin1, pin2] }
   *
   * @param {*} portsGroups - { normalPorts = {}, positivePorts = {}, negativePorts = {} }
   */
  selectPortsGroups(portsGroups) {
    this.layoutRenderer.darkenAllNets();
    this.layoutRenderer.transparentCompLyr(true);
    this.layoutRenderer.highlightPortsGroups(portsGroups);
  }

  selectSignalNetGroup = ({ nets, comp, pinsObj }, isPower, pcbId) => {
    if (nets && nets.length) {
      this.layoutRenderer.removeNetName()
      this.layoutRenderer.showNetsName(nets, isPower, pcbId)
      this.layoutRenderer.removePortBox()
    }
    this.layoutRenderer.highlightCompGroups(comp, pinsObj)
  }

  previewHybridBoundaries = (boundaries, centerPoint, name) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.drawHybridBoundaries(boundaries, centerPoint, name);
    }
  }

  addHybridRegionBox = () => {
    if (this.layoutRenderer) {
      this.layoutRenderer.drawHybridRegionBox();
      return;
    }
    let times = 0;
    const timeout = setInterval(() => {
      times++;
      if (this.layoutRenderer) {
        this.layoutRenderer.drawHybridRegionBox();
        clearInterval(timeout)
      }
      if (times === 120) {
        clearInterval(timeout)
      }
    }, 900)
  }

  cleanHybridBoundaries = (name) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.cleanHybridBoundaries(name);
    }
  }

  removeHybridAllPointsByName = (lineName) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.removeHybridPoints(lineName);
    }
  }

  /**
   * show hybrid lines and points
   * 
   * points - 
   *
   * @param {*} lines []
   */
  showHybridPoints = ({ points, lineName, unit, notShowSave }, events) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.highlightHybridPoints({ points, lineName, unit, notShowSave }, events);
    }
  }

  updateClickMousePosition = ({ x, y }) => {
    this.clickMousePosition = { x, y };
  }

  getClickMousePosition = () => {
    return this.clickMousePosition;
  }

  drawStackupZonesBox = () => {
    if (this.layoutRenderer) {
      this.layoutRenderer.drawStackupZonesBox();
      return;
    }
    let times = 0;
    const timeout = setInterval(() => {
      times++;
      if (this.layoutRenderer) {
        this.layoutRenderer.drawStackupZonesBox();
        clearInterval(timeout)
      }
      if (times === 120) {
        clearInterval(timeout)
      }
    }, 900)
  }

  /**
   * show stackup zone
   * 
   *
   * @param {string} zoneName name
   * @param {object} zone
   */
  showZoneBoundaries = (zoneName, zone) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.highlightStackupZone(zoneName, zone);
    }
  }

  /**
 * remove stackup zone
 * 
 *
 * @param {string} zoneName name
 */
  clearZoneBoundaries = (zoneName) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.removeStackupZone(zoneName);
    }
  }

  selectViaHoles = (addName, clearName) => {
    if (clearName) {
      this.layoutRenderer.unHighlightVias(clearName);
    }
    if (addName) {
      this.layoutRenderer.darkenAllNets();
      this.layoutRenderer.transparentCompLyr(true);
      this.layoutRenderer.highlightVias(addName);
    } else {
      this.layoutRenderer.unDarkenAllNets();
      this.layoutRenderer.transparentCompLyr(false);
    }
  }

  judgeLayoutElement = (element) => {
    const nets = [], comps = [];
    for (let ele of element) {
      if (this.nets.includes(ele)) {
        nets.push(ele)
      } else if (this.components.includes(ele)) {
        comps.push(ele)
      }
    }
    return {
      nets, comps
    }
  }
  /**
 * show stackup bends
 * 
 *
 * @param {object} bends
 */
  showBends = (bends, showIndexs) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.removeStackupBends();
      for (let i = 0; i < bends.length; i++) {
        if (showIndexs.includes(i)) {
          this.layoutRenderer.highlightStackupBend(bends[i], i);
        }
      }
    }
  }

  removeBends = (bendIndexs) => {
    if (this.layoutRenderer) {
      this.layoutRenderer.removeStackupBends();
    }
  }

  getHiddenComps = (hiddenComps) => {
    this.layoutRenderer.getHiddenComps(hiddenComps);
  }

  deleteHiddenComp = (hiddenComp) => {
    this.layoutRenderer.deleteHiddenComp(hiddenComp);
  }
}

export default LayoutCanvas;
