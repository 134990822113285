const defaultColor_dark = [
  "#0c80a4", "#1c9b3b", "#da7d01",
  "#4a5d9c", "#004000", "#cc0000",
  "#006666", "#800080", "#808000",
  "#333333", "#cc6600", "#006633",
  "#6600cc", "#66cc00", "#0066cc",
  "#cc0052", "#0087a9", "#a3700d",
  "#0c7a2f", "#0c487a", "#7a0c3c",
  "#746a77", "#3d3f2f", "#cc0066",
  "#00cc00", "#00cc99", "#660000",
  "#cc1900", "#965348", "#006480",
  "#00574f", "#1d273b", "#cc33cc",
  "#cc4c33", "#8b6d2a", "#a89759",
  "#15305e", "#cc7d64", "#ad5f33",
  "#091537", "#2e2a36", "#b3a200",
  "#5c406e", "#00553d", "#363c5a",
  "#af0f36", "#6f5c73", "#234d3a"
];

const defaultColor_light = [
  "#65c9e1", "#8ff072", "#ffa833",
  "#a4b5e6", "#00b800", "#ff3333",
  "#33ffff", "#e200e2", "#e2e200",
  "#808080", "#ffaa33", "#33ff66",
  "#ff66ff", "#66ff33", "#33aaff",
  "#ff3380", "#3fd6eb", "#e4bf4d",
  "#65e98f", "#65a3e9", "#e965a1",
  "#c5c3bf", "#77784d", "#ff4dff",
  "#66ff66", "#66ffcc", "#ff3333",
  "#ff471a", "#c68167", "#33a9c0",
  "#2ea397", "#394b69", "#ff66ff",
  "#ff8566", "#d1ac6d", "#e4d2aa",
  "#344788", "#e99f8a", "#f3a157",
  "#294c82", "#49465a", "#f0e800",
  "#9978a2", "#00b66e", "#5a547d",
  "#ec596b", "#a891b3", "#495d46"
];

const defaultColor = [
  '#15a5ea', '#2ed146', '#fd9f02',
  '#7082f0', '#008000', '#ff0000',
  '#00c0c0', '#c000c0', '#c0c000',
  '#404040', '#ff8000', '#00ff80',
  '#8000ff', '#80ff00', '#0080ff',
  '#ff0080', '#02C6D4', '#CB8815',
  '#1F9E38', '#1F5C9E', '#9E1F52',
  '#8c8297', '#4d4f36', '#ff00a0',
  '#00ff00', '#00ffff', '#800000',
  '#ff2000', '#bd6758', '#008792',
  '#007d65', '#293047', '#ff40ff',
  '#ff6040', '#ad8b3d', '#c1a173',
  '#1b315e', '#c99979', '#de773f',
  '#11264f', '#3c3645', '#decb00',
  '#7d5886', '#006c54', '#444693',
  '#d71345', '#867892', '#2b6447',
  // "#0c80a4", "#1c9b3b", "#da7d01",
  ...defaultColor_dark,
  ...defaultColor_light
];

export default defaultColor;
export {
  defaultColor_dark,
  defaultColor_light
}