import {
  UPDATE_SIERRA_CONTENT,
  UPDATE_SIERRA_INTERFACE,
  EXPAND_MENU,
  UPDATE_SIERRAINFO,
  UPDATE_NETS_COMPS,
  UPDATE_CURRENT_CONFIG,
  UPDATE_INTERFACE_LIBRARY_MSG,
  UPDATE_TOUCHSTONE_STATUS_LIST,
  UPDATE_GET_REFERENCE_NETS_LOADING,
  FIND_INTERFACE_REFERENCE_NETS,
  UPDATE_USAGE_APPLY_ALL,
  CHANGE_PCB_LOADING,
  CONNECTOR_STATUS,
  CHANGE_IDENTIFICATION_CREATE_VISIBLE,
  UPDATE_INTERFACE_IDENTIFICATION_MASSAGE,
  UPDATE_PCB_REPLACE_INFO,
  UPDATE_ASSIGN_STATUS,
  UPDATE_COMP_PREFIX_STATUS,
  COMPONENT_SETTING_UPDATE,
  UPDATE_CREATE_PANEL_LOADING,
  UPDATE_COMP_SETTINGS_STATUS,
  UPDATE_PART_REPEATER_LOG,
  CLOSE_PART_REPEATER_PANEL,
  UPDATE_BUFFER_MODEL_STATUS,
  UPDATE_MEASURE_CONFIG_STATUS,
  UPDATE_DUPLICATION_VERIFICATIONS,
  UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO,
  UPDATE_INTERFACE_DESIGN_MESSAGE,
  UPDATE_CREATE_INTERFACE_STATUS,
  UPDATE_PRE_INFO_REPLACE_INFO
} from './actionTypes';
import { CLEAR_CURRENT_PROJECT } from '../project/actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  expandedKeys: ['library', 'projects'],
  updateNetsComponents: [],
  currentConfig: {},
  interfaceLibraryError: null,
  TouchstoneStatusList: [],
  refNetsLoading: {
    loading: false
  },
  newReferenceNets: null,
  usageApplyAll: false,
  pcbLoading: false,
  connectorStatus: false,
  identificationCreateVisible: false,
  identificationCreateMsg: null,
  updateCompPrefixLibStatus: false,
  pcbReplaceInfo: { loading: null, msgInfo: { netsComponents: [], msg: null }, panelVisible: false },
  createdLoading: false,
  updatePartRepeaterLogs: null,
  closePartRepeater: false,
  isUpdateMeasure: false,
  isUpdateMeasureType: false,
  duplicationVerificationInfo: null,
  createVerificationInfo: null,
  schematicPreLayoutInfo: null,
  preLayoutReplaceInfo: null
}

export const SierraReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_SIERRA_CONTENT:
      return {
        ...state,
        sierraInfo: {
          ...action.info
        }
      }
    case UPDATE_SIERRA_INTERFACE:
      return {
        ...state,
        sierraInfo: {
          ...state.sierraInfo,
          info: {
            ...(state.sierraInfo.info || {}),
            ...action.data
          }
        }
      }
    case EXPAND_MENU:
      return {
        ...state,
        expandedKeys: action.expandKeys
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...defaultState,
        expandedKeys: state.expandedKeys
      }
    case UPDATE_SIERRAINFO:
      return {
        ...state,
        sierraInfo: {
          ...state.sierraInfo,
          ...action.data
        }
      }
    case UPDATE_NETS_COMPS:
      return {
        ...state,
        updateNetsComponents: action.updateNetsComponents
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case UPDATE_CURRENT_CONFIG:
      return {
        ...state,
        currentConfig: action.currentConfig
      }
    case UPDATE_INTERFACE_LIBRARY_MSG:
      return {
        ...state,
        interfaceLibraryError: action.interfaceLibraryError
      }
    case UPDATE_TOUCHSTONE_STATUS_LIST:
      return {
        ...state,
        TouchstoneStatusList: [...action.list]
      }
    case UPDATE_GET_REFERENCE_NETS_LOADING:
      return {
        ...state,
        refNetsLoading: {
          loading: action.loading
        }
      }
    case FIND_INTERFACE_REFERENCE_NETS:
      return {
        ...state,
        newReferenceNets: action.newReferenceNets
      }
    case UPDATE_USAGE_APPLY_ALL:
      return {
        ...state,
        usageApplyAll: action.usageApplyAll
      }
    case CHANGE_PCB_LOADING:
      return {
        ...state,
        pcbLoading: action.pcbLoading
      }
    case CONNECTOR_STATUS:
      return {
        ...state,
        connectorStatus: action.bool
      }
    case CHANGE_IDENTIFICATION_CREATE_VISIBLE:
      return {
        ...state,
        identificationCreateVisible: action.visible,
        identificationCreateMsg: null
      }
    case UPDATE_INTERFACE_IDENTIFICATION_MASSAGE:
      return {
        ...state,
        identificationCreateMsg: action.msg
      }
    case UPDATE_PCB_REPLACE_INFO:
      return {
        ...state,
        pcbReplaceInfo: {
          ...state.pcbReplaceInfo,
          ...action.info
        }
      }
    case UPDATE_ASSIGN_STATUS:
      return {
        ...state,
        autoAssignStatus: action.status
      }
    case UPDATE_COMP_PREFIX_STATUS:
      return {
        ...state,
        updateCompPrefixLibStatus: action.status
      }
    case COMPONENT_SETTING_UPDATE:
      return {
        ...state,
        updateCompPrefix: action.updateCompPrefix
      }
    case UPDATE_CREATE_PANEL_LOADING:
      return {
        ...state,
        createdLoading: action.loading
      }
    case UPDATE_COMP_SETTINGS_STATUS:
      return {
        ...state,
        updateSettingStatus: action.status
      }
    case UPDATE_PART_REPEATER_LOG:
      return {
        ...state,
        updatePartRepeaterLogs: action.log
      }
    case CLOSE_PART_REPEATER_PANEL:
      return {
        ...state,
        closePartRepeater: action.closePartRepeater
      }
    case UPDATE_BUFFER_MODEL_STATUS:
      return {
        ...state,
        assignBufferModelStatus: action.status
      }
    case UPDATE_MEASURE_CONFIG_STATUS:
      return {
        ...state,
        [action.key]: action.status
      }
    case UPDATE_DUPLICATION_VERIFICATIONS:
      return {
        ...state,
        duplicationVerificationInfo: action.info
      }
    case UPDATE_CREATE_INTERFACE_STATUS:
      return {
        ...state,
        createVerificationInfo: action.info
      }
    case UPDATE_SWEEP_SCHEMATIC_PRE_LAYOUT_INFO:
      return {
        ...state,
        schematicPreLayoutInfo: action.schematicPreLayoutInfo
      }
    case UPDATE_INTERFACE_DESIGN_MESSAGE:
      return {
        ...state,
        currInterfaceDesignMessage: action.message
      }
    case UPDATE_PRE_INFO_REPLACE_INFO:
      return {
        ...state,
        preLayoutReplaceInfo: action.updatePreInfo
      }
    default: return state
  }
};