import { getChannelConfig } from "../projectCtrl";
import { PIN_GROUP } from '../../ExtractionPortsHelper';

function getCmd2TMode({ channelId }) {
  return new Promise((resolve, reject) => {
    getChannelConfig(channelId).then(res => {
      const config = parseConfig(res)
      resolve(config.cmd_2t_mode);
    }, error => {
      resolve(false);
    })
  })
}

function parseConfig(response) {
  let _currentConfig = { cmd_2t_mode: false };
  if (response.config.config && Object.keys(response.config.config).length) {
    // response:{id:'', config:{ config:{}, resultConfig:{}, extraction: {} }}
    _currentConfig = { cmd_2t_mode: false, ...response.config.config };
  } else if (response.config.timeStep) {
    // response:{id:'', config:{ clock:'' , timeStep:'',corners:[], simulate:'' }}
    _currentConfig = { cmd_2t_mode: false, ...response.config };
  }

  return _currentConfig;
}

function updatePortAvoidSinglePinGroup({ port_setups, ports_generate_setup_list, avoidSinglePinGroup, clear = false }) {
  //Update avoid_single_pin_group
  let _port_setups = [...port_setups];

  if (clear) {
    for (let item of _port_setups) {
      if (item.positive && item.positive.component) {
        const findSetup = ports_generate_setup_list.find(it => it.component === item.positive.component);
        if (!findSetup || !findSetup.setup || findSetup.setup.portType !== PIN_GROUP) {
          delete item.positive.avoid_single_pin_group;
        }
      }
    }
    return _port_setups
  }

  for (let item of _port_setups) {
    if (item.positive && item.positive.component) {
      const findSetup = ports_generate_setup_list.find(it => it.component === item.positive.component);
      if (findSetup && findSetup.setup && findSetup.setup.portType === PIN_GROUP && avoidSinglePinGroup[item.positive.component]) {
        item.positive.avoid_single_pin_group = avoidSinglePinGroup[item.positive.component]
      } else {
        delete item.positive.avoid_single_pin_group;
      }
    }
  }
  return _port_setups;
}

export {
  getCmd2TMode,
  updatePortAvoidSinglePinGroup
}