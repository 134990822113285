import React, { Component, Fragment, createRef } from 'react';
import './index.css';

const borderName = [
  ['leftBorder', 'topBorder', 'upprLeftCorner'],
  ['leftBorder', 'bottomBorder', 'lowerLeftCorner'],
  ['rightBorder', 'topBorder', 'upprRightCorner'],
  ['rightBorder', 'bottomBorder', 'lowerRightCorner']
];

let ignore = false;

class ScalableBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerHeight: null,
      prePosition: {
        widthBorder: 0,
        heightBorder: 0,
        mouseX: 0,
        mouseY: 0
      },
      width: null,
      height: null,
      panelBorderColor: [false, false, false],
      mirrorLeft: this.props.mirrorLeft,
      mirrorTop: this.props.mirrorTop,
      borders: borderName[3]
    }
    this.boxRef = createRef()
  }

  componentDidMount() {
    const { mirrorLeft, mirrorTop } = this.state;
    if (mirrorLeft) {
      if (mirrorTop) {
        this.setState({ borders: borderName[3] })
      }
      else {
        this.setState({ borders: borderName[2] })
      }
    }
    else {
      if (mirrorTop) {
        this.setState({ borders: borderName[1] })
      }
      else {
        this.setState({ borders: borderName[0] })
      }
    }
  }

  changeShapeStart(i, e) {
    e.stopPropagation();
    let colorList = this.state.panelBorderColor
    colorList[i] = true;
    this.setState({ panelBorderColor: colorList });
    this.props.changeStart();
    const { clientX, clientY } = e;
    const borders = this.boxRef.current;
    const widthBorder = borders.children[0];
    const heightBorder = borders.children[1];
    const { mirrorLeft, mirrorTop, panelWidth, panelHeight } = this.props;
    let preRight = widthBorder.offsetLeft;
    let preBottom = heightBorder.offsetTop;
    let headerHeight = this.props.getHeaderHeight();
    let footerHeight = this.props.getFooterHeight();
    let prePosition = {
      widthBorder: mirrorLeft ? preRight : panelWidth,
      heightBorder: mirrorTop ? preBottom : panelHeight,
      mouseX: clientX,
      mouseY: clientY
    }

    this.setState({
      prePosition,
      headerHeight,
      footerHeight
    })
    ignore = true;
  }

  changeShapeEnd(i, e) {
    let colorList = this.state.panelBorderColor
    colorList[i] = false;
    this.setState({ panelBorderColor: colorList });
    e.stopPropagation();
    e.preventDefault();
    const { headerHeight, footerHeight, width, height } = this.state;
    this.props.changeSize(i, width, height, footerHeight, headerHeight);
    this.props.changeEnd();
  }

  changeShape(e) {
    e.stopPropagation();
    e.preventDefault();
    const { clientX, clientY } = e;
    const { mouseX, mouseY, widthBorder, heightBorder } = this.state.prePosition
    const { mirrorLeft, mirrorTop } = this.state;
    if (clientX === 0 && clientY === 0) {
      return;
    }
    const width = this.judgeWidth(mirrorLeft ? (clientX - mouseX + widthBorder) : (mouseX - clientX + widthBorder));
    const height = this.judgeHeight(mirrorTop ? (clientY - mouseY + heightBorder) : (mouseY - clientY + heightBorder));
    let footerHeight = this.props.getFooterHeight();
    if (ignore) {
      ignore = false;
      return;
    }

    this.setState({
      width,
      height,
      footerHeight
    })
  }

  // Determine whether the width limit is exceeded
  judgeWidth(num) {
    const { minWidth, maxWidth } = this.props
    if (minWidth && num < minWidth) {
      num = minWidth;
    } else if (maxWidth && num > maxWidth) {
      num = maxWidth;
    }

    return num;
  }

  // Determine whether the height limit is exceeded
  judgeHeight(num) {
    const { minHeight, maxHeight } = this.props
    if (minHeight && num < minHeight) {
      num = minHeight;
    }
    else if (maxHeight && num > maxHeight) {
      num = maxHeight;
    }

    return num;
  }

  render() {
    const { borders, panelBorderColor } = this.state;
    return (
      <Fragment>
        <div
          className="boxSide"
          ref={this.boxRef}
        >
          {
            borders.map((border, i) => (
              <div
                draggable="true"
                className={[border, panelBorderColor[i] ? "panel-border-color" : ""].join(' ')}
                key={i}
                onDragStart={(e) => this.changeShapeStart(i, e)}
                onDrag={(e) => this.changeShape(e)}
                onDragEnd={(e) => this.changeShapeEnd(i, e)}
              >
              </div>
            ))
          }
        </div>
      </Fragment>
    )
  }
}

export default ScalableBox;