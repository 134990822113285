import React, { Component, Fragment } from 'react';
import { CloseCircleOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Checkbox, Select, Collapse, Tooltip } from 'antd';
import {
  AMIModelConfig,
  SET_ALL_DATA_SELECT_LIST,
  getIbisAmiModelList,
  getTableAmiParameters,
  adsConfigItemErrorCheck,
  ADS_TX,
  AMISignalPRBSConfig,
  ibisAMIModelParamsParse,
  RANGE,
  CORNER,
  BOOLEAN,
  LIST,
  updateParamsValueByCorner,
  amiParameterValueCheck,
  setAMIModel,
  getJittersList,
  STRING,
  getTableAmiParametersValue,
  INTEGER,
  FLOAT,
  TAP,
  GAUSSIAN,
  PARAMETER_DUAL_DIRAC,
  PARAMETER_DJRJ,
  AMI_PRBS_MODE_LIST,
  updatePRBSOptions
} from '../../../../services/Andes_v2/AMIModelHelper';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import { IBIS_AMI } from '@/constants/libraryConstants';
import TreeSelect from '@/components/TreeSelect';
import EditableTable from '@/components/EditableTable';
import FileContentPanel from '@/components/LibraryUpload/FileContentPanel';
import { getFileContent } from '@/services/Andes_v2/library';
import JittersTable from '../jittersTable';
import { END_TO_END_CHANNEL } from '../../../../constants/treeConstants';
import PRBSSetup from '../SetupComponents/PRBSSetup';
import "./index.css";

const amiParametersColumns = [{
  key: "type",
  dataIndex: "type",
  title: "Type",
  width: "20%"
}, {
  key: "name",
  dataIndex: "name",
  title: "Name",
  width: "40%"
}, {
  key: "value",
  dataIndex: "value",
  title: "Value",
  width: "40%"
}], jittersColumns = [
  {
    key: "name",
    title: "Name"
  },
  {
    key: "value",
    title: "Value"
  }
];;

const { Option } = Select;

class AMIModelSetup extends Component {
  constructor(props) {
    super(props);
    const { modelInfo: { model, component, prbs, interfaceType, channelId }, serdesType } = props;
    this.state = {
      applyAll: false,
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new AMIModelConfig({
        type: interfaceType,
        component,
        channelId,
        modelType: "AMI"
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      fileVisible: false,
      amiParamError: null
    }
    this.selectModels = [];
    this.components = [];
    this.fileInfo = {};

    amiParametersColumns[0].render = (name, record) => {
      return {
        children: <span>{name}</span>,
        props: {
          rowSpan: record.groupLength
        }
      }
    };

    amiParametersColumns[1].render = (name, record) => {
      return <Tooltip
        overlayClassName='aurora-tooltip'
        title={record.description ? `Description: ${record.description}` : null}
      >
        <span className="ami-parameters-title">{name}</span>
      </Tooltip>
    }

    amiParametersColumns[2].onCell = (record) => {
      if (record.disable && record.disable === "true") {
        return {
          edit: false
        }
      }
      if (record.valueType === BOOLEAN
        || record.valueType === LIST
        || (record.valueType === CORNER && !this.state.model.setAllData)) {
        /*  const options = record.valueList; */
        const options = record.valueType === CORNER
          ? record.valueList.map(item => { return { value: item.value, title: `${item.type}: ${item.value}` } })
          : record.valueList
        return {
          record,
          edit: "select",
          options: options || [],
          dataIndex: "value",
          handleSave: (_record) => { this._editParameterValue(_record, true) },
        }
      }

      if ([GAUSSIAN, PARAMETER_DUAL_DIRAC, PARAMETER_DJRJ].includes(record.valueType)) {
        return {
          edit: false
        }
      }

      if (record.valueType !== CORNER) {
        return {
          record: { ...record, value: getTableAmiParametersValue(record) },
          edit: true,
          dataIndex: 'value',
          handleSave: (_record) => { this._editParameterValue(_record) }
        }
      }
    }

    amiParametersColumns[2].render = (name, record) => {
      /*   if (record.valueType === CORNER) {
          const findV = record.valueList.find(item => item.value === record.value);
          return <span>{findV ? `${findV.value} (${findV.type})` : record.value}</span>
        } */
      if ([GAUSSIAN, PARAMETER_DUAL_DIRAC, PARAMETER_DJRJ].includes(record.valueType)) {
        return <span className="andes-ami-parameters-value"
          title={record.displayValue}
        >{record.displayValue}</span>
      }
      return <span className="andes-ami-parameters-value" title={name}>{name}</span>
    }
  }

  _editParameterValue = (record, isSelection) => {
    const { model } = this.state;
    let _model = { ...model };
    const index = _model.amiParameters.findIndex(item => item.name === record.name);
    if (record.valueType === RANGE || (!isSelection && [INTEGER, FLOAT, TAP].includes(record.valueFormat))) {
      const error = amiParameterValueCheck(record.value, record.valueList, record.name, record);
      if (error) {
        this.updateAmiParamError(error)
        return;
      }
    }
    if (!isSelection && record.valueFormat === STRING) {
      //6.0 -> "6.0"
      record.value = `"${record.value}"`;
    }
    _model.amiParameters[index].value = record.value;

    this.setState({
      model: _model
    })
  }

  updateAmiParamError = (error) => {
    this.setState({
      amiParamError: error
    })
    setTimeout(() => {
      this.setState({
        amiParamError: null
      })
    }, 3000)
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.defaultModel();
  }

  componentDidUpdate = (prevProps) => {
    const { modelInfo: { signal, dirType } } = this.props;
    const prevSignal = prevProps.modelInfo ? prevProps.modelInfo.signal : null;
    const prevDirType = prevProps.modelInfo ? prevProps.modelInfo.dirType : null;

    if ((prevSignal !== signal) || (dirType !== prevDirType)) {
      this.defaultModel();
    }
  }

  closeModal = (isClose) => {
    const { applyAll, model, prbs, error } = this.state;
    if (error) { return }
    const { modelInfo: { dirType, signal } } = this.props;
    const { jitters } = model;
    const findEmptyData = jitters.find(item => !item.name);
    if (findEmptyData) {
      // Check if there are any unfilled jitter data
      this.setState({
        error: { type: "jitter", error: "The data in the Additional Jitters cannot be empty", errorType: 'error' }
      })
      return;
    }
    this.props.closeModal({ model, prbs, signal, dirType, applyAll, isClose });
  }

  defaultModel = () => {
    const { modelInfo: { model, component, prbs, interfaceType, channelId }, serdesType } = this.props;
    this.selectModels = [];
    this.components = [];
    this.fileInfo = {};

    if (!model || !model.libraryId) {
      this.setState({
        model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new AMIModelConfig({
          type: interfaceType,
          component,
          channelId,
          modelType: "AMI"
        }),
        prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
        fileVisible: false
      })
      return;
    }

    const fileList = libraryConstructor.getLibraryValues(IBIS_AMI) || [];
    const file = fileList.find(item => item.id === model.libraryId);
    const childFile = file && file.children ? file.children.find(item => item.fileName === model.libraryFile) : null;
    let ibisAmi = childFile && childFile.ibisAmi ? childFile.ibisAmi : {};
    this.selectModels = getIbisAmiModelList(ibisAmi);
    this.components = ibisAmi && ibisAmi.components ? [...ibisAmi.components] : [];
    //parse ami parameters
    ibisAmi = ibisAMIModelParamsParse(ibisAmi);
    if (file) {
      this.fileInfo = {
        id: file.id,
        name: file.name,
        fileName: childFile.fileName,
        ibisAmi: ibisAmi
      }
    }
    this.setState({
      model: model && Object.keys(model).length ? JSON.parse(JSON.stringify(model)) : new AMIModelConfig({
        type: interfaceType,
        component,
        channelId,
        modelType: "AMI"
      }),
      prbs: prbs ? { ...prbs } : new AMISignalPRBSConfig(serdesType),
      fileVisible: false
    })
  }

  selectFileByTree = (file) => {
    const { model } = this.state;
    const { modelInfo } = this.props;
    let _model = { ...model };

    if (!file) {
      this.components = [];
      this.fileInfo = {
        id: "",
        name: "",
        fileName: "",
        ibisAmi: {}
      }
      _model = new AMIModelConfig({
        type: modelInfo.interfaceType,
        component: modelInfo.component,
        channelId: modelInfo.channelId,
        libraryId: "",
        libraryName: "",
        libraryFile: "",
        ibisComponent: "",
        modelType: "AMI"
      });
      this.setState({
        model: _model
      }, () => {
        this.closeModal(false);
      })
      return;
    }

    if (file && file.id === _model.libraryId && file.childFile === _model.libraryFile) {
      return;
    }

    const currFile = file.children.find(item => item.fileName === file.childFile) || {};
    const ibisAmi = currFile.ibisAmi || {};
    let ibisComponent = "";
    if (ibisAmi.components && ibisAmi.components.length === 1) {
      ibisComponent = ibisAmi.components[0];
      this.selectModels = getIbisAmiModelList(ibisAmi);
    }
    this.components = ibisAmi && ibisAmi.components ? [...ibisAmi.components] : [];
    this.fileInfo = {
      id: file.id,
      name: file.name,
      fileName: file.childFile,
      ibisAmi: ibisAMIModelParamsParse(ibisAmi)
    }
    _model = new AMIModelConfig({
      type: modelInfo.interfaceType,
      component: modelInfo.component,
      channelId: modelInfo.channelId,
      libraryId: file.id,
      libraryName: file.name,
      libraryFile: file.childFile,
      ibisComponent,
      modelType: "AMI"
    });
    const defaultModel = this.selectModels[0];

    _model = setAMIModel({
      model: _model,
      modelInfo: defaultModel,
      dirType: modelInfo.dirType,
      fileInfo: this.fileInfo
    });

    this.setState({
      model: _model
    }, () => {
      this.closeModal(false);
    })
  }

  selectChange = (key, type) => {
    const { model } = this.state;
    let _model = { ...model };
    _model[type] = key;
    if (type === "dataType" && _model.setAllData) {
      _model = updateParamsValueByCorner({ model: _model, corner: key, fileInfo: this.fileInfo });
    }
    this.setState({
      model: _model
    })
  }

  setAllDataChanged = (e) => {
    const { model } = this.state;
    let _model = { ...model };
    _model.setAllData = e.target.checked;
    if (e.target.checked) {
      //reset amiParameters values by corner, dataType is corner
      _model = updateParamsValueByCorner({ model: _model, corner: _model.dataType, fileInfo: this.fileInfo });
    }
    this.setState({
      model: _model
    })
  }

  selectModel = (e, modelInfo) => {
    e && e.stopPropagation();
    const { model } = this.state;
    const { modelInfo: { dirType } } = this.props;
    let _model = { ...model };

    _model = setAMIModel({
      model: _model,
      modelInfo,
      dirType,
      fileInfo: this.fileInfo
    });

    this.setState({
      model: _model
    })
  }

  addJitters = (e) => {
    e && e.stopPropagation();
    const { model } = this.state;
    let _model = { ...model };
    const emptyJitter = _model.jitters.find(item => !item.name);
    if (emptyJitter) {
      return;
    }
    _model.jitters.push({ name: "", value: "", unit: "" })
    this.setState({
      model: _model
    })
  }

  applyChange = (e) => {
    this.setState({
      applyAll: e.target.checked
    })
  }

  saveConfigValue = (value, type) => {
    const { prbs } = this.state;
    let _prbs = prbs ? { ...prbs } : {};
    _prbs[type] = value;

    _prbs = updatePRBSOptions(_prbs, value)
    this.setState({
      prbs: _prbs
    })
  }


  inputChange = (e, type) => {
    const value = e.target.value;
    const { error } = this.state;
    this.setState({
      error: error && error.type === type ? null : error
    })
    this.saveConfigValue(value, type);
  }

  inputBlur = (e, type) => {
    const value = e.target.value;
    const { error: _error } = adsConfigItemErrorCheck({ value, type });
    if (_error) {
      e.target.focus();
      this.setState({
        error: { type, error: _error }
      })
    } else {
      const { error } = this.state;
      this.setState({
        error: error && error.type === type ? null : error
      })
      this.saveConfigValue(value, type);
    }
  }

  showFileContent = (fileVisible) => {
    this.setState({
      fileVisible
    })
  }

  usePkgCheckBox = (usePackage) => {
    const { componentPkgInfo, modelInfo: { component, channelId, interfaceType } } = this.props;
    const key = interfaceType === END_TO_END_CHANNEL ? `${component}::${channelId}` : component;
    const disabled = componentPkgInfo && componentPkgInfo[key];
    return <div
      className={`ads-model-select-item`}
      title={disabled ? `Component "${component}" has already set the package model.` : ""}>
      <span>Use Package</span>
      <Checkbox
        className='ami-model-select-checkbox'
        checked={usePackage}
        disabled={disabled}
        onChange={(e) => this.changeUsePkg(e)} />
    </div>
  }

  changeUsePkg = (e) => {
    const { model } = this.state;
    let _model = { ...model };
    _model.usePackage = e.target.checked;
    this.setState({
      model: _model
    })
  }

  render() {
    const { modelInfo: { dirType } } = this.props;
    const { model, applyAll, prbs, error, fileVisible } = this.state;
    return <Fragment>
      {this.getSelectComponent({
        title: "IBIS File",
        value: model.libraryFile,
        type: "libraryFile",
        libraryId: model.libraryId,
        list: libraryConstructor.getLibraryValues(IBIS_AMI) || [],
        allowClear: true
      })}
      {this.getSelectComponent({
        title: "Component",
        type: "ibisComponent",
        value: model.ibisComponent,
        list: this.components
      })}
      {this.usePkgCheckBox(model.usePackage)}
      {model.libraryFile ? this.modelSelectRender(model) : null}
      {model.modelName ? this.modelParametersRender(model) : null}
      {model.modelName ? this.modelJittersRender(model) : null}
      {dirType === ADS_TX ? this.modelPRBSRender(prbs) : null}
      {error ? <span className={error.errorType === "warning" ? "aurora-model-name-warning-msg" : "aurora-error-msg-span"}>{error.error}</span> : null}
      {<div className="ads-model-apply-all-content">
        <span>Apply setting to all {dirType} model</span>
        <Checkbox
          checked={applyAll}
          onChange={(e) => this.applyChange(e)}
        />
      </div>}
      {fileVisible && model.libraryFile.length ? <FileContentPanel
        title={model.libraryFile}
        getLibraryFileContent={getFileContent}
        closeModal={() => this.showFileContent(false)}
        libraryId={model.libraryId}
        fileName={model.libraryFile}
      /> : null}
    </Fragment>
  }

  modelSelectRender = (_model) => {
    const items = [{
      key: "models",
      label: <div className="ads-model-jitter-title">
        <span>Models</span>
      </div>,
      children: <div className="ads-select-model-content">
        <div className="ads-select-model-item ads-select-model-header">
          <div className="ads-select-model-name-title">Model Name</div>
          <div className="ads-select-model-pins-title">
            <div className="ami-select-model-signal-title">Signal Name</div>
            <div className="ami-select-model-pin-title">Pin</div>
          </div>
        </div>
        {this.selectModels.map(model => {
          const height = (model.pins.length || 1) * 28;
          return <div
            key={model.name}
            className={`ads-select-model-item ${_model.modelName === model.name ? "ads-select-model-selected" : ""}`}
            onClick={(e) => this.selectModel(e, model)}
            style={{ height: height }}
          >
            <Checkbox
              checked={_model.modelName === model.name}
              style={{ lineHeight: `${height}px` }}
              className='ads-model-checkbox'
            />
            <div className="ads-select-model-item-title" style={{ height: height, lineHeight: `${height}px` }}>{model.name}</div>
            <div className="ami-select-model-pins-title" style={{ height: height }}>
              {model.pins.map((item, index) =>
                <div key={index} className="ami-select-model-pin-item-title">
                  <div className="ami-select-model-signal-title">{item.signalName}</div>
                  <div className="ami-select-model-pin-title">{item.pin}</div>
                </div>
              )}
            </div>
          </div>
        })}
      </div>
    }]
    return <Collapse className="ami-model-collapse-content ads-model-collapse-main" defaultActiveKey={["models"]} items={items} />
  }

  modelParametersRender = (_model) => {
    const { amiParamError } = this.state;
    const items = [{
      key: "parameters",
      label: <div className="ami-model-collapse-title">
        {_model.modelName}
      </div>,
      children: <Fragment>
        {this.getSelectComponent({
          title: "Corner",
          type: "dataType",
          value: _model.dataType,
          setAllData: _model.setAllData,
          list: SET_ALL_DATA_SELECT_LIST,
          disabled: !_model.setAllData,
          className: "ami-model-corner-content"
        })}
        {amiParamError ? <span className='aurora-model-name-error-msg'>{amiParamError}</span> : null}
        <EditableTable
          rowKey={record => `${_model.modelName}-${record.name}`}
          columns={amiParametersColumns}
          dataSource={getTableAmiParameters(_model.amiParameters, true, _model, this.fileInfo) || []}
          size="small"
          className='ami-model-parameters-table'
        />
      </Fragment>
    }]
    return <Collapse className="ami-model-collapse-content" items={items} />
  }

  saveModel = (model, type) => {
    if (type === 'jitter') {
      const { error } = this.state;
      this.setState({
        error: error && error.type === 'jitter' ? null : error
      })
    }
    this.setState({
      model
    })
  }

  updateError = (error) => {
    this.setState({
      error
    })
  }

  modelJittersRender = (_model) => {
    const { bitRate, modelInfo } = this.props;
    const options = getJittersList(modelInfo.dirType);
    const _options = options.filter(item => !_model.jitters.find(it => it.name === item) && !_model.amiParameters.find(it => it.name === item));
    const disabledClassName = !_options || !_options.length ? 'icon-disabled' : '';
    return <JittersTable
      model={_model}
      modelInfo={modelInfo}
      type={"ami"}
      bitRate={bitRate}
      tableClassName="ami-model-jitters-table"
      jitters={_model.jitters}
      jittersColumns={jittersColumns}
      header={
        <div className="ads-model-jitter-title">
          <span>Additional Jitters</span>
          <PlusCircleOutlined
            className={`ami-model-jitters-add-icon ${disabledClassName}`}
            onClick={(e) => !disabledClassName && this.addJitters(e)}
          />
        </div>}
      saveModel={this.saveModel}
      updateError={this.updateError}
    />
  }

  modelPRBSRender = (prbs) => {
    return <PRBSSetup
      prbs={prbs}
      getSelectComponent={this.getSelectComponent}
      saveConfigValue={this.saveConfigValue}
      inputBlur={this.inputBlur}
      inputChange={this.inputChange}
      modelList={AMI_PRBS_MODE_LIST}
    />
  }

  getSelectComponent = ({
    title,
    value,
    type,
    list = [],
    className,
    libraryId,
    setAllData,
    saveConfigValue,
    disabled,
    allowClear
  }) => {
    return (
      <div className={`ads-model-select-item ${className}`}>
        <span>{title}</span>
        {type === "dataType" ? <Checkbox
          className='ami-model-select-checkbox'
          checked={setAllData}
          onChange={(e) => this.setAllDataChanged(e)} /> : null}
        {type === "libraryFile" ?
          <Fragment>
            <TreeSelect
              value={value}
              onSelectItem={(file) => this.selectFileByTree(file)}
              size='small'
              showSearch
              popupClassName='aurora-select-dropdown'
              className={value ? "aurora-select ads-model-file-select" : "aurora-select"}
              fileList={list}
              popupMatchSelectWidth={false}
              allowClear={{ clearIcon: <CloseCircleOutlined theme="filled" onClick={(e) => { e.stopPropagation(); this.selectFileByTree(null) }} /> }}
              getPopupContainer={() => document.getElementById('root')}
              selected={libraryId}
            />
            {value ?
              <Tooltip overlayClassName="aurora-tooltip" title="Show File Content">
                <InfoCircleOutlined className="ads-model-file-content-icon" onClick={() => this.showFileContent(true)} />
              </Tooltip>
              : null}
          </Fragment>
          :
          <Select
            value={value}
            onChange={(key) => saveConfigValue ? saveConfigValue(key, type) : this.selectChange(key, type)}
            popupMatchSelectWidth={false}
            className={`aurora-select`}
            popupClassName="aurora-select-dropdown"
            getPopupContainer={() => document.getElementById('root')}
            disabled={disabled}
            allowClear={allowClear}
          >
            {list.map((item) => (
              type === "mode" ? <Option
                key={item.key}
                value={item.key}
                title={item.title}
              >{item.title}</Option> :
                <Option
                  key={item}
                  value={item}
                  title={item}
                >{item}</Option>))}
          </Select>}
      </div>
    );
  }
}


export default AMIModelSetup;