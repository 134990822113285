import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { changeImpedancePage, createNewLayout, deleteLayout, deleteLayoutConnector, deleteShip, traceOverview, updateImpedanceStatus, createDie, saveOnDieModel, saveMultiPCBTarget, saveDieCurrent, savePMICVoltage, switchPackage, saveInterposer, saveInterposerNode, saveHasInterposer, updateCurrentStatus, saveRleakAndRrdl, saveTransientTimeSetting } from '../store/Impedance/action';
import { CloseOutlined, ImportOutlined, PlayCircleOutlined, PlusCircleOutlined, ArrowDownOutlined, RetweetOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Tooltip, Spin, Button, Tag, Input, message, Select, TreeSelect, Checkbox } from 'antd';
import { sortOverviewComps, reverseOverviewComps } from '../../../services/Cascade/Impedance';
import PCBPanel from './pcbPanel';
import ConnectorPanel from './pcbPanel/connectorPanel';
import { BGA, CONNECTOR, DIE, PMIC, VRM } from '../../../constants/componentType';
import { PACKAGE } from '../../../constants/treeConstants';
import { OverviewPlot } from '../../../services/Cascade/Impedance/overviewCanvas';
import { IMPEDANCE_DIE, IMPEDANCE_PACKAGE, IMPEDANCE_PCB, TARGET_DIE_INDEX, TARGET_PACKAGE_INDEX } from '../../../services/Cascade/constants';
import { CASCADE } from '../../../constants/pageType';
import CascadeOnDieModelPanel from '../../../components/OnDieFilePanel/cascadeOnDieModelPanel';
import { getCascadeCSMCPMSpiceModelList, getPkgSpiceModelList } from '../../../services/Cascade/library';
import designConstructor from '../../../services/helper/designConstructor';
import TargetPanel from './targetPanel';
import DieCurrentPanel from './dieCurrentPanel';
import { PCB_PACKAGE_DIE } from '../../../constants/resolution';
import { numberCheck } from '@/services/helper/dataProcess';
import { numExponentialFormat } from '@/services/helper/numberHelper';
import SwitchPkgPanel from './switchPkgPanel';
import CPMCSMContent from '../../../components/CPMCSMSetup/cpmCsmContent';
import CpmCsmSelectPanel from '../../../components/CPMCSMSetup/CpmCsmSelectPanel';
import InterPoserPanel from './interposerPanel';
import { IC } from '../../Sierra/constants';
import { getAutoConnectorNets } from '../../../services/Cascade/helper/setupData';
import RleakRdlPanel from './rleakRdlPanel';
import TransientTimePanel from './transientTimePanel';

const { Option } = Select
class OverviewPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 300,
      height: 150,
      layoutList: [],
      positionList: [],
      addPCB: false,
      addConnector: false,
      onDie: false,
      openTarget: false,
      openCurrent: false,
      openSwitchPkg: false,
      currentPkgId: '',
      portInfo: new Map(),
      portSelect: new Map(),
      portCount: 0,
      openInterposer: false,
      nodes: [],
      maxStopTime: 0,
    }
  }

  resizeCanvas = () => {
    const content = document.getElementById('cascade-imp-overview-content');
    const contentOffset = content.getBoundingClientRect();
    const { width, height } = contentOffset;
    this.setState({
      width,
      height
    }, () => {
      this.getLinePosition()
    })
  }

  getLinePosition = () => {
    const { layoutList } = this.state;
    const { data, impedanceOverviewDisplay, hasInterposer, customModelType } = this.props;
    const overviewReverse = impedanceOverviewDisplay === PCB_PACKAGE_DIE
    let indexs = [...new Set(data.map(item => item.index))].sort();
    if (overviewReverse) {
      indexs = indexs.reverse()
    }
    let totalLeft = 0, positionList = [];
    for (let index of indexs) {
      const layouts = data.filter(item => item.index === index);
      let totalHeight = 0;
      for (let layout of layouts) {
        const { designId, designName: _designName } = layout;
        const designName = designConstructor.getDesignName(designId) || _designName;
        const id = `PCB-${designName.replace(/[^\w\d_]/g, '')}`;
        const currentLayout = layoutList.find(item => item.designId === designId);
        const { left, right } = currentLayout;
        let _totalHeight = totalHeight, _totalLeft = totalLeft;
        [...left, ...right].forEach(item => {
          const { name, index, side, data } = item;
          const _ele = name === 'Die' ? (customModelType === 'unified' ? document.getElementById(`layout-unified-die-${index}`) : document.getElementById(`layout-current-icon-${index}`)) : document.getElementById(`${id}-${name}-${index}`);
          if (_ele) {
            const top = _ele.offsetTop;
            const left = _ele.offsetLeft;
            const height = _ele.offsetHeight;
            positionList.push({
              // top: prevHeight + layout Margin + title Margin + title Height + add Connection Height + top + 0.5 height
              name, index, data, side, designId, top: _totalHeight + 10 + 28 + 40 + top + 0.5 * height, left: _totalLeft + left + 10
            })
          }
        })
        const ele = document.getElementById(id);
        if (ele) {
          totalHeight = totalHeight + ele.offsetHeight;
        }
      }
      if (hasInterposer) {
        const targetIndex = overviewReverse ? TARGET_PACKAGE_INDEX : TARGET_DIE_INDEX;
        totalLeft += (index === targetIndex) ? 600 : 300;
      } else {
        totalLeft += 300;
      }
    }
    this.setState({
      positionList
    }, () => {
      this.draw()
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeCanvas);
    this.getComponents();
    this.getPortTreeData()
    this.initDefaultCurrentStopTime()
  }

  componentDidUpdate(prevProps) {
    const { data, verificationId, page, status, impedanceOverviewDisplay, hasInterposer, customModelType } = this.props;
    if (data.length !== prevProps.data.length || verificationId !== prevProps.verificationId || page !== prevProps.page || status || impedanceOverviewDisplay !== prevProps.impedanceOverviewDisplay || hasInterposer !== prevProps.hasInterposer) {
      this.getComponents();
      this.initDefaultCurrentStopTime()
      this.props.updateImpedanceStatus(false);
    }
    if (data.length !== prevProps.data.length || verificationId !== prevProps.verificationId || page !== prevProps.page || impedanceOverviewDisplay !== prevProps.impedanceOverviewDisplay || hasInterposer !== prevProps.hasInterposer || customModelType !== prevProps.customModelType) {
      this.getPortTreeData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas)
  }

  getComponents = () => {
    const { data, impedanceOverviewDisplay } = this.props
    if (!data || !data.length) {
      this.setState({
        layoutList: []
      }, () => {
        this.resizeCanvas();
      })
      return;
    }
    const layoutList = impedanceOverviewDisplay === PCB_PACKAGE_DIE ? reverseOverviewComps(data) : sortOverviewComps(data);
    const { newLayoutList, dieShowData } = this.initDieLayoutData(layoutList)
    this.setState({
      layoutList: newLayoutList,
      dieShowData
    }, () => {
      this.resizeCanvas();
    })
  }

  initDefaultCurrentStopTime = () => {
    const { data } = this.props
    let maxStopTime = 0
    data.forEach(item => {
      item.ICs.forEach(ic => {
        if (ic.dieCurrent) {
          if (Number(ic.dieCurrent.stopTime) > maxStopTime) {
            maxStopTime = Number(ic.dieCurrent.stopTime)
          }
        }
      })
    })
    this.setState({
      maxStopTime
    })
  }

  initDieLayoutData = (layoutList) => {
    const { impedanceOverviewDisplay } = this.props
    const { portSelect } = this.state
    let newLayoutList = JSON.parse(JSON.stringify(layoutList))
    let dieShowData = []
    const findDieIndex = newLayoutList.findIndex(item => item.designId === 'Die')
    if (findDieIndex > -1) {
      const layout = newLayoutList[findDieIndex]
      let data = []
      let currentComp = null
      dieShowData = impedanceOverviewDisplay === PCB_PACKAGE_DIE ? JSON.parse(JSON.stringify(layout.left)) : JSON.parse(JSON.stringify(layout.right))
      dieShowData.forEach(item => {
        const comp = item.data.to[0].components[0]
        const compPort = portSelect.get(comp)
        const currentPort = compPort ? compPort.split('-') : []
        const powerNet = currentPort.length ? currentPort[0] : null
        const port = currentPort.length ? currentPort[1] : null
        if (currentComp !== comp) {
          if (powerNet && port) {
            if (item.data.powerNet === powerNet && item.data.portIndex === port) {
              data.push({ ...item, index: data.length })
              currentComp = comp
            }
          } else {
            data.push({ ...item, index: data.length })
            currentComp = comp
          }
        }
      })
      const interposer = data.length ? data[0].data.interposer : null
      this.initInterposerNodes(interposer)
      if (impedanceOverviewDisplay === PCB_PACKAGE_DIE) {
        layout.left = [...data]
      } else {
        layout.right = [...data]
      }
      layout.height = data.length * 100 + 30 + 40 + 40;
      newLayoutList[findDieIndex] = layout
    }
    return { newLayoutList, dieShowData }
  }

  changeImpedancePage = (e, designId) => {
    e && e.stopPropagation()
    if (designId === IMPEDANCE_DIE) {
      return;
    }
    this.props.changeImpedancePage(designId)
  }

  changeAddPCBVisible = (boolean) => {
    this.setState({
      addPCB: boolean
    })
  }

  changeAddConnVisible = (addConnector) => {
    this.setState({
      addConnector
    })
  }

  deleteLayout = (e, designId) => {
    e && e.stopPropagation();
    this.props.deleteLayout(designId);
  }

  deleteInterposer = (e) => {
    e && e.stopPropagation();
    this.props.saveHasInterposer(false)
  }

  switchPkg = (e, designId) => {
    e && e.stopPropagation();
    this.setState({
      currentPkgId: designId,
      openSwitchPkg: true
    })
  }

  deleteConnector = (e, designId, connector) => {
    e && e.stopPropagation();
    this.props.deleteConnector(designId, connector)
  }

  draw = () => {
    const { impedanceOverviewDisplay, customModelType } = this.props
    const reverse = impedanceOverviewDisplay === PCB_PACKAGE_DIE
    if (!this.brush) {
      const { width, height, positionList } = this.state;
      this.brush = new OverviewPlot({ id: 'impedance-overview', width, height, data: positionList, customModelType });
    }
    const { width, height, positionList } = this.state;
    this.brush.reset({ width, height, customModelType })
    this.brush.set('data', positionList);
    this.brush.draw(reverse);
  }

  closeOnDiePanel = (name, info, powerNet, targetDie, apply, customType, dieCurrentList) => {
    const { modelPowerNet, modelIndex } = this.state
    this.props.saveOnDieModel(info, targetDie, modelPowerNet, modelIndex, apply, customType, dieCurrentList)
    this.setState({
      onDie: false
    })
  }

  changeOnDie = async (targetDie, model, powerNet, portIndex) => {
    const { data } = this.props;
    const pkg = data.find(item => item.type === IMPEDANCE_PACKAGE);
    const die = data.find(item => item.type === IMPEDANCE_DIE);
    if (!pkg || !die) {
      return;
    }
    const targetPkg = pkg.powerDomains.find(domain => {
      return domain.content.Components.find(comp => comp.name === targetDie)
    })
    const powerNets = targetPkg.content.PowerNets
    const gndNets = targetPkg.content.ReferenceNets
    const ports = targetPkg.content.diePorts && targetPkg.content.diePorts.length ? targetPkg.content.diePorts : [{ port: '1' }]
    const customType = die.customType
    let _model = { ...model }
    if (customType === 'unified') {
      const mergedModel = {
        ...die.ICs[0].model,
        pairs: [],
      };
      die.ICs.forEach((item, index) => {
        if (item.powerNet === powerNet && item.to[0].components[0] === targetDie && item.model.pairs && item.model.pairs.length) {
          mergedModel.pairs.push(item.model.pairs[0]);
          if (index === die.ICs.length - 1) {
            mergedModel.pairs.push(item.model.pairs[1]);
          }
        }
      });
      _model = { ...mergedModel }
    }

    const dieCurrentList = die.ICs.map(item => item.dieCurrent || {})
    const pdnInfo = {
      gndNet: [...new Set(gndNets)],
      powerNets: [...new Set(powerNets)].map(powerNet => ({ powerNet, onDie: _model })),
      ports: ports,
      customType,
      dieCurrentList
    }
    this.setState({
      modelPowerNet: powerNet,
      modelIndex: portIndex,
      onDie: {
        pdnInfo,
        powerNet: [...new Set(powerNets)],
        targetDie,
        targetPkg,
        portIndex
      }
    })
  }

  contentRender = () => {
    const { data, impedanceOverviewDisplay } = this.props;
    let indexs = [...new Set(data.map(item => item.index))].sort();
    if (impedanceOverviewDisplay === PCB_PACKAGE_DIE) {
      indexs = indexs.reverse()
    }
    return indexs.map(index => {
      const layouts = data.filter(item => item.index === index);
      return this.layoutsRender(layouts, index)
    });
  }

  changeTargetPanel = (e, open) => {
    e && e.stopPropagation();
    this.setState({
      openTarget: open
    })
  }

  changeTransientPanel = (e, open) => {
    e && e.stopPropagation();
    this.setState({
      openTransientTime: open
    })
  }

  closeTransientTimePanel = (startTime, stopTime, stepTime) => {
    this.props.saveTransientTimeSetting(startTime, stopTime, stepTime)
    this.setState({
      openTransientTime: false
    })
  }

  openCurrentPanel = (e, dieCurrent, designId, name, powerNet, portIndex) => {
    e && e.stopPropagation()
    this.setState({
      dieCurrent,
      currentDesignId: designId,
      currentComp: name,
      currentPowerNet: powerNet,
      currentIndex: portIndex,
      openCurrent: true,
    })
  }

  changeCurrentPanel = (e, open) => {
    e && e.stopPropagation();
    this.setState({
      openCurrent: open
    })
  }

  changePkgPanel = (e, open) => {
    e && e.stopPropagation();
    this.setState({
      openSwitchPkg: open
    })
  }

  changeCpmCsmVisible = (visible) => {
    this.setState({
      cpmCsmVisible: visible
    })
  }

  clickPairs = (e, targetDie) => {
    e && e.stopPropagation();
    const { data } = this.props;
    const pkg = data.find(item => item.type === IMPEDANCE_PACKAGE);
    const die = data.find(item => item.type === IMPEDANCE_DIE);
    if (!pkg || !die) {
      return;
    }
    const ic = die.ICs.find(ic => ic.to[0].components[0] === targetDie)
    const model = ic ? ic.model : {}
    const targetPkg = pkg.powerDomains.find(domain => {
      return domain.content.Components.find(comp => comp.name === targetDie)
    })
    const powerNets = targetPkg.content.PowerNets
    const gndNets = targetPkg.content.ReferenceNets
    const pdnInfo = {
      gndNet: [...new Set(gndNets)],
      powerNets: [...new Set(powerNets)].map(powerNet => ({ powerNet, onDie: model }))
    }
    this.setState({
      pdnInfo,
      powerNet: [...new Set(powerNets)],
      targetDie,
      targetPkg
    })
    const _data = { powerNet: [...new Set(powerNets)], key: "CPM" }
    this.changeCpmCsmVisible(_data)
  }

  getPairs = (pairs) => {
    let _pairs = [];
    const length = pairs.length;
    if (length > 3) {
      _pairs = [pairs[0].node, "...", pairs[length - 1].node]
    } else {
      _pairs = pairs.map(item => item.node)
    }
    return _pairs;
  }

  getPairsNode = (cpmPairs, powerNets) => {
    if (cpmPairs && cpmPairs.length) {
      const powerPairs = cpmPairs.filter(item => powerNets.includes(item.net));
      const gndPairs = cpmPairs.filter(item => !powerNets.includes(item.net));
      const _pairs = [{
        top: '2px',
        list: this.getPairs(powerPairs),
        color: "#fd4242"
      },
      {
        top: '56px',
        list: this.getPairs(gndPairs),
        color: "#000"
      }]
      return _pairs
    }
  }

  getCPMCSMContent = (modelType, info, onRef) => {
    const { packageChannelId, channelId, packageId, pcb, cpmList } = this.props
    const { onDie } = this.state
    return <CPMCSMContent
      info={info}
      onRef={onRef}
      modelType={modelType}
      packageId={packageId}
      packageChannelId={packageChannelId}
      pcb={pcb}
      channelId={channelId}
      product={CASCADE}
      targetPkg={onDie.targetPkg}
      customSpiceList={cpmList}
      getCSMCPMSpiceModelList={getCascadeCSMCPMSpiceModelList}
    />
  }

  toInput = (e, name, type) => {
    e && e.stopPropagation();
    this.setState({
      [`${name}_${type}_input_status`]: true
    }, () => {
      const ele = document.getElementById(`${name}-${type}-input`);
      ele && ele.focus();
    })
  }

  saveValue = (e, prevValue, name, type, powerNet, portIndex) => {
    e && e.stopPropagation();
    let value = e.target.value;
    if (prevValue !== value) {
      let errorCheck = null;
      if (value) {
        errorCheck = numberCheck(value)
      }
      if (errorCheck) {
        message.error(`${type} - ${errorCheck}`);
      } else {
        if (value && (value > 100 || value < 0.001)) {
          value = numExponentialFormat(value);
        }
        if (type === 'voltage') {
          this.props.savePMICVoltage(value, name)
        }
      }
    }
    this.setState({
      [`${name}_${type}_input_status`]: false
    })
  }

  getPortTreeData = () => {
    const { data } = this.props
    const findPkgIndex = data.findIndex(item => item.index === TARGET_PACKAGE_INDEX)
    const portInfo = new Map()
    if (findPkgIndex > -1) {
      const pkgLayout = data[findPkgIndex]
      pkgLayout.powerDomains.forEach(domain => {
        let count = 0
        const { MAIN_POWER_NETS, diePorts, Components } = domain.content
        const dieComp = Components.find(item => item.usage === DIE)
        const die = dieComp && dieComp.name ? dieComp.name : null
        const net = MAIN_POWER_NETS[0]
        const port = diePorts.length ? diePorts.map(item => {
          return { title: `${item.portName ? item.portName : `Port ${item.port}`}`, value: `${net}-${item.port}`, key: `${net}-${item.port}` }
        }) : [{ title: 'Lumped port', value: `${net}-1`, key: `${net}-1` }]
        const treeObj = { title: net, children: port, selectable: false, key: net, value: net }
        count += port.length
        if (portInfo.get(die)) {
          const ports = JSON.parse(JSON.stringify(portInfo.get(die).treeData))
          const _count = JSON.parse(JSON.stringify(portInfo.get(die).count))
          count += _count
          ports.push(treeObj)
          portInfo.set(die, { treeData: ports, count: count })
        } else {
          portInfo.set(die, { treeData: [treeObj], count: count })
        }
      })
    }
    const portSelect = new Map()
    portInfo.keys().forEach(key => {
      const treeData = portInfo.get(key).treeData
      const select = treeData.length ? treeData[0].children[0].value : null
      portSelect.set(key, select)
    })
    this.setState({
      portInfo,
      portSelect
    }, () => {
      this.getComponents()
    })
  }

  onPortChange = (value, index, targetDie) => {
    const { impedanceOverviewDisplay } = this.props
    const { dieShowData, layoutList, portSelect } = this.state
    const overviewReverse = impedanceOverviewDisplay === PCB_PACKAGE_DIE
    let _layoutList = JSON.parse(JSON.stringify(layoutList))
    const findDieIndex = _layoutList.findIndex(item => item.designId === 'Die')
    if (findDieIndex > -1) {
      const layout = JSON.parse(JSON.stringify(_layoutList[findDieIndex]))
      const side = overviewReverse ? 'left' : 'right'
      const compIndex = layout[side].findIndex(item => item.index === index)
      if (compIndex > -1) {
        const data = value.split('-')
        if (data.length) {
          const comp = layout[side][compIndex].data.to[0].components[0]
          const powerNet = data[0]
          const port = data[1]
          const _data = dieShowData.find(item => {
            const { data } = item
            return data.powerNet === powerNet && data.to[0].components[0] === comp && data.portIndex === port
          })
          if (_data) {
            const interposer = _data.data && _data.data.interposer ? _data.data.interposer : null
            this.initInterposerNodes(interposer)
            _layoutList[findDieIndex][side][compIndex] = { ..._data, index };
          }
        }
        const _portSelect = new Map(portSelect)
        _portSelect.set(targetDie, value)
        this.setState({
          portSelect: _portSelect,
          layoutList: _layoutList
        }, () => {
          this.resizeCanvas()
        })
      }
    }
  }

  calcBgaPort = () => {
    const { data } = this.props
    const findPkgIndex = data.findIndex(item => item.index === TARGET_PACKAGE_INDEX)
    let count = 0
    if (findPkgIndex > -1) {
      const pkgLayout = data[findPkgIndex]
      pkgLayout.powerDomains.forEach(domain => {
        const { bgaPorts } = domain.content
        if (bgaPorts.length === 0) {
          count += 1
        } else {
          count += bgaPorts.length
        }
      })
    }
    return count
  }

  updateCurrent = (dieCurrent, apply) => {
    const { currentComp, currentPowerNet, currentIndex, currentDesignId } = this.state
    this.props.saveDieCurrent({ dieCurrent, currentDesignId, currentComp, currentPowerNet, currentIndex, apply })
  }

  changeCurrentStatus = (e, designId, name) => {
    e && e.stopPropagation()
    this.props.updateCurrentStatus(e.target.checked, designId, name)
  }

  openInterposerPanel = (interposer, targetDie, powerNet, portIndex) => {
    const { data } = this.props
    const pkg = data.find(item => item.type === IMPEDANCE_PACKAGE);
    if (!pkg) {
      return;
    }
    const targetPkg = pkg.powerDomains.find(domain => {
      return domain.content.Components.find(comp => comp.name === targetDie)
    })
    const powerNets = targetPkg.content.PowerNets
    const gndNets = targetPkg.content.ReferenceNets
    const pinsInfo = [...new Set(powerNets), ...new Set(gndNets)]
    this.setState({
      pinsInfo,
      interposer,
      interposerPowerNet: powerNet,
      interposerIndex: portIndex,
      interposerDie: targetDie,
      openInterposer: true
    })
  }

  closeInterposerPanel = (info, apply) => {
    const { interposerDie, interposerPowerNet, interposerIndex } = this.state
    this.props.saveInterposer(info, interposerDie, interposerPowerNet, interposerIndex, apply)
    this.setState({
      openInterposer: false
    })
  }

  getAutoConnectionNet = async (designId, connector) => {
    const { data, pcbId, targetIC } = this.props;
    const connectionNets = await getAutoConnectorNets(designId, data, connector, pcbId, targetIC);
    return connectionNets
  }

  changeRleakRdl = (e, rleak, rdl, targetDie, powerNet, portIndex) => {
    e && e.stopPropagation()
    this.setState({
      openRleakRdl: true,
      rleak,
      rdl,
      rComp: targetDie,
      rPowerNet: powerNet,
      rPortIndex: portIndex
    })
  }

  closeRleakRdlPanel = (rleak, rdl, apply) => {
    const { rComp, rPowerNet, rPortIndex } = this.state
    this.props.saveRleakAndRrdl({ rleak, rdl, apply, rComp, rPowerNet, rPortIndex })
    this.setState({
      openRleakRdl: false
    })
  }

  inputRender = (value, name, type, powerNet, portIndex) => {
    const inputStatus = this.state[`${name}_${type}_input_status`] || false
    return inputStatus ?
      <Input
        className="component-input"
        defaultValue={value}
        onBlur={(e) => this.saveValue(e, value, name, type, powerNet, portIndex)}
        onPressEnter={(e) => this.saveValue(e, value, name, type, powerNet, portIndex)}
        id={`${name}-${type}-input`}
      />
      : <span
        className={`component-span ${type === 'voltage' ? 'cascade-power-tree-value-title' : ''}`}
        style={{ cursor: 'pointer' }}
        onClick={(e) => this.toInput(e, name, type)}
      >{`${value ? value : '____'}`}</span>
  }

  saveNode = (value, key, name, powerNet, portIndex) => {
    this.props.saveInterposerNode(value, key, name, powerNet, portIndex)
    this.setState({
      [`${name}_${key}_select_status`]: false
    })
  }

  toSelect = (e, name, type) => {
    e && e.stopPropagation();
    this.setState({
      [`${name}_${type}_select_status`]: true
    }, () => {
      const ele = document.getElementById(`${name}-${type}-select`);
      ele && ele.focus();
    })
  }

  selectRender = (value, unusedNodes, name, type, powerNet, portIndex) => {
    const selectStatus = this.state[`${name}_${type}_select_status`] || false
    return selectStatus ?
      <Select
        value={value}
        className='aurora-select layout-node-select'
        id={`${name}-${type}-select`}
        onBlur={() => this.setState({ [`${name}_${type}_select_status`]: false })}
        onSelect={(value) => this.saveNode(value, type, name, powerNet, portIndex)}
        allowClear={true}
        clearIcon={<CloseCircleFilled onClick={(e) => { this.saveNode('', type, name, powerNet, portIndex) }} />}
        defaultOpen={true}
      >
        {unusedNodes.map(node => {
          return <Option key={node} value={node}>{node}</Option>
        })}
      </Select>
      : <span
        className={`component-span`}
        style={{ cursor: 'pointer' }}
        onClick={(e) => this.toSelect(e, name, type)}
      >{value ? value : <span className='layout-node-empty'></span>}
      </span>
  }

  initInterposerNodes = (model) => {
    if (model && model.libraryId && model.fileName) {
      getPkgSpiceModelList(model.libraryId, model.fileName, 'SPICE').then(res => {
        let subcktList = [];
        subcktList = res && res.models && Array.isArray(res.models) ? [...res.models] : []
        const subckt = subcktList.find(item => item.name === model.subckt)
        this.setState({
          nodes: subckt ? subckt.ports : [],
        })
      });
    } else {
      this.setState({
        nodes: []
      })
    }
  }

  interposerRender = (index) => {
    const { impedanceOverviewDisplay, targetDie } = this.props
    const { portSelect, layoutList, portInfo, nodes } = this.state
    const overviewReverse = impedanceOverviewDisplay === PCB_PACKAGE_DIE
    const die = targetDie[0]
    const _portInfo = portInfo.get(die)
    const _portSelect = portSelect.get(die)
    const portSelectInfo = _portSelect ? _portSelect.split('-') : []
    const powerNet = portSelectInfo.length ? portSelectInfo[0] : null
    const portIndex = portSelectInfo.length ? portSelectInfo[1] : null
    const layout = layoutList.find(item => item.designId === 'Die')
    const dieLayout = layout ? (overviewReverse ? layout.left : layout.right) : []
    const interposer = dieLayout.length ? dieLayout[0].data.interposer : null
    const diePort = interposer && interposer.pairs && interposer.pairs.length ? interposer.pairs.find(item => item.pin === 'diePort') : null
    const diePortNode = diePort ? diePort.node : null
    const pkgPort = interposer && interposer.pairs && interposer.pairs.length ? interposer.pairs.find(item => item.pin === 'pkgPort') : null
    const pkgPortNode = pkgPort ? pkgPort.node : null
    const usedNodes = interposer && interposer.pairs && interposer.pairs.length ? interposer.pairs.map(item => item.node) : []
    const unusedNodes = nodes.filter(item => !usedNodes.includes(item))
    const fileName = interposer && interposer.fileName ? interposer.fileName : null
    const subckt = interposer && interposer.subckt ? interposer.subckt : null
    return (
      (<div className="cascade-imp-overview-column" key={'interposer'}>
        <div className="cascade-imp-overview-layout" key='Interposer' id='Interposer'>
          <Tooltip overlayClassName='aurora-tooltip' title={'Interposer'}>
            <div className="layout-title">
              <span>{'Interposer'}</span>
              <CloseOutlined className="layout-delete-icon" onClick={(e) => this.deleteInterposer(e)} />
            </div>
          </Tooltip>
          <div className="layout-content" style={{ height: 210 }}>
            <div className="layout-components" style={{ top: 40 }}>
              <div style={{ top: -30 }}
                className={`${overviewReverse ? 'layout-port-select-reverse' : 'layout-port-select'}`}>
                <span className='layout-port-title'>Bump port</span>
                <TreeSelect
                  value={_portSelect}
                  dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                  treeData={_portInfo ? _portInfo.treeData : []}
                  placeholder="Please select"
                  className='aurora-select layout-port-select-box'
                  popupClassName="aurora-select-dropdown layout-port-treeSelect"
                  treeDefaultExpandAll
                  onChange={(value) => this.onPortChange(value, 0, die)}
                  popupMatchSelectWidth={false}
                />
                {/* <Icon type='table' className='cascade-imp-icon' /> */}
              </div>
              <div
                style={{ top: 140 }}
                className={`${overviewReverse ? 'layout-port-count-reverse' : 'layout-port-count'}`}
              >Port {portIndex} / {_portInfo ? _portInfo.count : null}</div>
              <div className={`${overviewReverse ? 'layout-die-node-reverse' : 'layout-die-node'}`} >
                {this.selectRender(diePortNode, unusedNodes, die, 'diePort', powerNet, portIndex)}
              </div>
              <div className={`${overviewReverse ? 'layout-pkg-node-reverse' : 'layout-pkg-node'}`}>
                {this.selectRender(pkgPortNode, unusedNodes, die, 'pkgPort', powerNet, portIndex)}
              </div>
              <div className='layout-interposer' onClick={() => this.openInterposerPanel(interposer, die, powerNet, portIndex)}>
                <div>
                  {fileName}
                </div>
                <div>
                  {subckt}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
    );
  }

  renderInterposer = (index) => {
    const { hasInterposer, impedanceOverviewDisplay } = this.props
    const overviewReverse = impedanceOverviewDisplay === PCB_PACKAGE_DIE
    if (hasInterposer &&
      ((overviewReverse && index === TARGET_PACKAGE_INDEX) ||
        (!overviewReverse && index === TARGET_DIE_INDEX))
    ) {
      return this.interposerRender(index);
    }
  }

  layoutsRender = (layouts, index) => {
    return <Fragment key={index}>
      <div className="cascade-imp-overview-column" key={index} style={{ left: `${(index - 1) * 300}px` }}>
        {layouts.map(layout => this.layoutRender(layout))}
      </div>
      {this.renderInterposer(index)}
    </Fragment>
  }

  layoutRender = (layout) => {
    const { designId, type, designName: layoutName, customType } = layout;
    const { layoutList, portSelect, portInfo } = this.state;
    const { pcbId, impedanceOverviewDisplay, hasPkg, targetDie: targetDies } = this.props;
    const overviewReverse = impedanceOverviewDisplay === PCB_PACKAGE_DIE
    const dieModelClassName = overviewReverse ? 'layout-die-model-reverse' : 'layout-die-model'
    const dieModelPairsClassName = overviewReverse ? 'layout-model-pairs-reverse' : 'layout-model-pairs'
    const dieModelCPMPairsClassName = overviewReverse ? 'layout-model-cpm-pairs-reverse' : 'layout-model-cpm-pairs'
    const dieIconClassName = overviewReverse ? 'layout-current-icon-reverse' : 'layout-current-icon'
    const currentLayout = layoutList.find(item => item.designId === designId);
    const designName = designConstructor.getDesignName(designId) || layoutName;
    const _designName = designName.replace(/[^\w\d_]/g, '')
    if (!currentLayout) {
      return null;
    }
    const { left, right, height } = currentLayout;
    const bgaPortCount = this.calcBgaPort()
    return (
      <div className="cascade-imp-overview-layout" key={designId} id={`PCB-${_designName}`}>
        <Tooltip overlayClassName='aurora-tooltip' title={designName}>
          <div className="layout-title" onClick={(e) => this.changeImpedancePage(e, designId)}>
            <span>{designName}</span>
            {type !== IMPEDANCE_DIE && <ImportOutlined className="layout-in-icon" />}
            {designId !== pcbId && type === IMPEDANCE_PACKAGE && <RetweetOutlined className="layout-switch-icon" onClick={(e) => this.switchPkg(e, designId)} />}
            {designId !== pcbId && <CloseOutlined className="layout-delete-icon" onClick={(e) => this.deleteLayout(e, designId)} />}
          </div>
        </Tooltip>
        <div className="layout-content" style={{ height }}>
          {type !== IMPEDANCE_DIE && <div className="layout-add-connector">
            {
              type === PACKAGE ? <Tag>Package</Tag> :
                <Tag>PCB</Tag>
            }
          </div>}

          <div className="layout-components" style={{ ...(type === IMPEDANCE_DIE ? { top: 40 } : {}) }} >
            {
              [...left, ...right].map(item => {
                const { color, name, index, side, usage, data } = item;
                const model = data.model || {}
                const voltage = data.voltage || '1'
                const targetDie = data.to && data.to[0] ? data.to[0].components[0] : null
                const rleak = data.rleak || '100'
                const rdl = data.rdl || '0.001'
                const powerNet = data.powerNet || null
                const portIndex = data.portIndex || null
                const dieCurrent = data.dieCurrent || null
                const applyCurrent = data.applyCurrent || false
                const portCount = portInfo.get(targetDie) && portInfo.get(targetDie).count || 0
                const { type: modelType, rdieValue, cdieValue, pairs, cpmPairs } = model
                let cpmPairsNode = []
                if (modelType === 'CPM') {
                  cpmPairsNode = this.getPairsNode(cpmPairs, data.powerNets)
                }
                return (
                  <Fragment key={`${name}-${index}`}>
                    {name === 'Die' ? <Fragment>
                      {customType !== 'unified' ? <Fragment>
                        <div className={modelType === 'value' ? dieModelClassName : modelType === 'CPM' ? dieModelCPMPairsClassName : dieModelPairsClassName}
                          style={{
                            top: 100 * index,
                            ...(customType === 'unified' ? overviewReverse ? { left: 106 } : { right: 106 } : {})
                          }}
                          onClick={() => this.changeOnDie(targetDie, model, powerNet, portIndex)}
                        >
                          {modelType === 'value' ? <Fragment>
                            <div className='layout-rdie'>
                              {`R:${rdieValue}Ω`}
                            </div>
                            <div className='layout-cdie'>
                              {`C:${cdieValue}F`}
                            </div>
                          </Fragment> : modelType === 'CPM' ? <Fragment>  {cpmPairsNode && cpmPairsNode.length ? <Fragment>
                            {cpmPairsNode.map((item, index) => {
                              const { list, color, top } = item;
                              if (!list.length) { return null }
                              return <div key={index} className="layout-cpm-item" style={{ color, top }}
                                onClick={(e) => this.clickPairs(e, targetDie)}
                              >
                                {list.map(name => <div key={name}>{name}</div>)}
                              </div>
                            })}
                          </Fragment> : null}</Fragment> :
                            <Fragment>
                              <div className='layout-node-1'>{pairs[0].node}</div>
                              <div className='layout-node-2'>{pairs[1].node}</div>
                            </Fragment>}
                        </div>
                        <div
                          style={{ top: 100 * index }}
                          className={`${dieIconClassName} ${customType === 'CPM' ? 'layout-current-icon-disabled' : ''}`}
                          id={`layout-current-icon-${index}`}
                          onClick={(e) => { customType !== 'CPM' && this.openCurrentPanel(e, dieCurrent, designId, targetDie, powerNet, portIndex) }}>
                          <ArrowDownOutlined style={{ fontSize: '20px', color: '#798DA6' }} />
                        </div>
                        {modelType !== 'CPM' && customType !== 'unified' && <Fragment>
                          <div
                            style={{ top: 94 + index * 100, cursor: 'pointer' }}
                            className={`${overviewReverse ? 'layout-rleak-reverse' : 'layout-rleak'}`}
                            onClick={(e) => this.changeRleakRdl(e, rleak, rdl, targetDie, powerNet, portIndex)}
                          >
                            R-leak:
                            <div className='component-value'>
                              <span
                                className={`component-span`}
                              >{`${rleak ? rleak : '____'}`}
                              </span>MΩ
                              {/* {this.inputRender(rleak, targetDie, 'rleak', powerNet, portIndex)}mΩ */}
                            </div>
                          </div>
                          <div
                            style={{ top: 40 + index * 100, cursor: 'pointer' }}
                            className={`${overviewReverse ? 'layout-rdl-reverse' : 'layout-rdl'}`}
                            onClick={(e) => this.changeRleakRdl(e, rleak, rdl, targetDie, powerNet, portIndex)}
                          >
                            R-rdl:
                            <div className='component-value'>
                              <span
                                className={`component-span`}
                              >{`${rdl ? rdl : '____'}`}
                              </span>mΩ
                              {/* {this.inputRender(rdl, targetDie, 'rdl', powerNet, portIndex)}mΩ */}
                            </div>
                          </div>
                        </Fragment>}
                        {targetDies.length === 1 && <Fragment>
                          {customType !== 'CPM' && <div style={{ top: -30 + index * 100 }}
                            className={`${overviewReverse ? 'layout-port-select-reverse' : 'layout-port-select'}`}>
                            <span className='layout-port-title'>Bump port</span>
                            <TreeSelect
                              value={portSelect.get(targetDie)}
                              dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                              treeData={portInfo.get(targetDie) && portInfo.get(targetDie).treeData || []}
                              placeholder="Please select"
                              className='aurora-select layout-port-select-box'
                              popupClassName="aurora-select-dropdown layout-port-treeSelect"
                              popupMatchSelectWidth={false}
                              treeDefaultExpandAll
                              onChange={(value) => this.onPortChange(value, index, targetDie)}
                            />
                            {/* <Icon type='table' className='cascade-imp-icon' /> */}
                          </div>}
                          <div
                            style={{ top: 140 + index * 100 }}
                            className={`${overviewReverse ? 'layout-port-count-reverse' : 'layout-port-count'}`}
                          > {customType !== 'CPM' ? (
                            `Port ${portSelect.get(targetDie) && portSelect.get(targetDie).split('-')[1] || 0} / ${portCount}`
                          ) : (
                            `${portCount} ${portCount > 1 ? 'ports' : 'port'}`
                          )}</div>
                        </Fragment>}
                      </Fragment> : <Fragment>
                        <div
                          style={{ top: 100 * index }}
                          className={`layout-unified-die${overviewReverse ? '-reverse' : ''}`}
                          id={`layout-unified-die-${index}`}
                          onClick={() => this.changeOnDie(targetDie, model, powerNet, portIndex)}
                        >
                          <div className={`layout-unified-current layout-unified-current-1${overviewReverse ? '-reverse' : ''}`} >
                            <ArrowDownOutlined style={{ fontSize: '16px', color: '#798DA6' }} />
                            <div className='layout-unified-current-name'>1</div>
                          </div>
                          <div className={`layout-unified-current layout-unified-current-2${overviewReverse ? '-reverse' : ''}`} >
                            ...
                          </div>
                          <div className={`layout-unified-current layout-unified-current-3${overviewReverse ? '-reverse' : ''}`}>
                            <ArrowDownOutlined style={{ fontSize: '16px', color: '#798DA6' }} />
                            <div className='layout-unified-current-name'>{portCount}</div>
                          </div>
                        </div>
                        <div
                          style={{ top: 40 + 100 * index }}
                          className={`layout-unified-node${overviewReverse ? '-reverse' : ''}`}
                        >
                          {`${portCount} ${portCount > 1 ? 'nodes' : 'node'}`}
                        </div>
                      </Fragment>
                      } </Fragment> : <Fragment>
                      <div
                        style={{ backgroundColor: color, top: 100 * index }}
                        className={`layout-item layout-item-${side} ${usage === CONNECTOR ? 'aurora-cursor' : ''}`}
                        onClick={() => type === IMPEDANCE_DIE ? this.changeOnDie() : usage === CONNECTOR ? this.changeAddConnVisible({ designId, designName, data }) : null}
                        key={name}
                        id={`PCB-${_designName}-${name}-${index}`}
                      >
                        {name}
                        {[PMIC, VRM].includes(usage) && <Fragment>
                          {usage === PMIC && <div className='component-value'>
                            {this.inputRender(voltage, name, 'voltage')}
                            <span className="cascade-component-value-title">&nbsp;V</span>
                          </div>}
                          <div className='layout-gnd-v-line'></div>
                          <div className='layout-gnd-h-line'></div>
                          <div className='layout-gnd-text'>GND</div>
                        </Fragment>
                        }
                        {usage === IC && !hasPkg && <Fragment>
                          <div className='layout-ic-current-box'>
                            <Checkbox checked={applyCurrent} onChange={e => this.changeCurrentStatus(e, designId, name)} />
                            <span className='layout-ic-current-title'>Current</span>
                            <Tooltip title={applyCurrent ? 'Open current setting' : 'Please enable current option'} overlayClassName='aurora-tooltip'>
                              <div className='layout-ic-current-icon-box' onClick={(e) => applyCurrent ? this.openCurrentPanel(e, dieCurrent, designId, name) : null}>
                                <span className={`iconfont icon-boxingtu  ${applyCurrent ? 'layout-ic-current-icon' : 'layout-ic-current-icon-disabled'}`} />
                              </div>
                            </Tooltip>
                          </div>
                        </Fragment>
                        }
                        {usage === CONNECTOR && <CloseOutlined className="delete-connector-icon layout-delete-icon" onClick={(e) => this.deleteConnector(e, designId, name)} />}
                      </div>
                    </Fragment>
                    }
                  </Fragment>
                );
              })
            }
          </div>
          {type === IMPEDANCE_PACKAGE && <div className={`${overviewReverse ? 'layout-bga-port-count-reverse' : 'layout-bga-port-count'}`}>{`${bgaPortCount} ${bgaPortCount > 1 ? 'ports' : 'port'}`}</div>}
          {type === IMPEDANCE_PCB ? <div className="layout-add-connector">
            <Button size='small' className="connector-button" type="primary" onClick={() => this.changeAddConnVisible({ designId, designName })}>+ CONN</Button>
          </div> : null}
        </div>
      </div>
    );
  }

  calcTargetICData = (data) => {
    const impPcbData = data.filter(item => item.type === IMPEDANCE_PCB);
    const impHasDie = data.find(item => item.designId === IMPEDANCE_DIE);
    if (!impPcbData.length) {
      return false
    } else {
      for (const impData of impPcbData) {
        const ic = impData.ICs.find(item => item.applyCurrent && item.dieCurrent && item.dieCurrent.currentPoints && item.dieCurrent.currentPoints.length)
        if (ic) {
          return ic
        }
      }
      if (!impHasDie) {
        return false
      } else {
        const ic = impHasDie.ICs.find(item => item.dieCurrent && item.dieCurrent.currentPoints && item.dieCurrent.currentPoints.length)
        if (ic) {
          return ic
        }
        return false
      }
    }
  }

  render() {
    const { loading, openProjectId, data, customSpiceList, customTouchstoneList, target, maxFreq, packageId, pcb, packageChannelId, channelId, hasInterposer, targetDie } = this.props;
    const { width, height, addPCB, addConnector, onDie, openTarget, openCurrent, currentPkgId, openSwitchPkg, cpmCsmVisible, targetPkg, pdnInfo, dieCurrent, openInterposer, pinsInfo, interposer, currentDesignId, maxStopTime, openRleakRdl, rleak, rdl, openTransientTime } = this.state;
    const designIds = data.map(d => d.designId);
    const hasDie = data.find(item => item.designId === IMPEDANCE_DIE);
    const targetText = target && target.length ? target.length > 1 ? `${target[0].targetName}, +${target.length - 1}` : target[0].targetName : ''
    const pkg = data.find(item => item.index === TARGET_PACKAGE_INDEX)
    const packageIsPrelayout = pkg ? designConstructor.isPreLayout(pkg.designId) : false;
    const targetICData = this.calcTargetICData(data)
    return <Fragment>
      <Spin spinning={loading ? true : false} tip={loading}>
        <div className="cascade-imp-overview">
          <div>
            <span className="font-bold cascade-setup-title-color">Overview</span>
            <div className="cascade-imp-overview-button-group">
              <Tooltip title={"Add new layout"} overlayClassName='aurora-tooltip'>
                <PlusCircleOutlined className='cascade-imp-icon' onClick={() => this.changeAddPCBVisible(true)} />
              </Tooltip>
              <Tooltip title={"Trace PMIC"} overlayClassName='aurora-tooltip'>
                <PlayCircleOutlined className='cascade-imp-icon' onClick={this.props.traceOverview} />
              </Tooltip>
            </div>
            {
              data.length > 1 ? <div className="cascade-imp-multi-pcb-target" onClick={(e) => this.changeTargetPanel(e, true)}>
                <span>Target {targetText ? `: ${targetText}` : ''}</span>
              </div> : null
            }
            {
              targetICData && <div className="cascade-imp-multi-pcb-target" onClick={(e) => this.changeTransientPanel(e, true)}>
                <span>Transient Time</span>
              </div>
            }
          </div>
          <div className="space-10 cascade-imp-overview-content" id="cascade-imp-overview-content">
            <div className="cascade-imp-overview-layouts-box">{this.contentRender()}</div>
            <canvas id={`impedance-overview`} className="impedance-overview-canvas" width={width} height={height} />
          </div>
        </div>
      </Spin>
      {
        addPCB && <PCBPanel
          closeModal={this.changeAddPCBVisible}
          projectId={openProjectId}
          createNewLayout={this.props.createNewLayout}
          designIds={designIds}
          deleteShip={this.props.deleteShip}
          hasDie={hasDie}
          createDie={this.props.createDie}
          hasInterposer={hasInterposer}
          saveHasInterposer={this.props.saveHasInterposer}
          getAutoConnectionNet={this.getAutoConnectionNet}
        />
      }
      {
        addConnector && <ConnectorPanel
          designIds={designIds}
          projectId={openProjectId}
          {...addConnector}
          closeModal={() => this.changeAddConnVisible(false)}
          createNewLayout={this.props.createNewLayout}
          deleteShip={this.props.deleteShip}
          getAutoConnectionNet={this.getAutoConnectionNet}
        />
      }
      {
        onDie && <CascadeOnDieModelPanel
          product={CASCADE}
          name={'onDie'}
          pdnInfo={onDie.pdnInfo}
          powerNet={onDie.powerNet}
          targetDie={onDie.targetDie}
          portIndex={onDie.portIndex}
          closePanel={this.closeOnDiePanel}
          onDieTouchstoneList={customTouchstoneList}
          onDieSpiceList={customSpiceList}
          getPkgSpiceModelList={getPkgSpiceModelList}
          packageIsPrelayout={packageIsPrelayout}
          getCPMCSMContent={this.getCPMCSMContent}
          maxStopTime={maxStopTime}
          multiTargetDie={targetDie.length > 1}
        />
      }
      {cpmCsmVisible && cpmCsmVisible.key && <CpmCsmSelectPanel
        cpmCsmVisible={cpmCsmVisible}
        changeCpmCsmVisible={this.changeCpmCsmVisible}
        pdnInfo={pdnInfo}
        packageId={packageId}
        packageChannelId={packageChannelId}
        closeFilePanel={this.closeOnDiePanel}
        pcb={pcb}
        channelId={channelId}
        product={CASCADE}
        targetPkg={targetPkg}
        modelType={'CPM'}
        getCSMCPMSpiceModelList={getCascadeCSMCPMSpiceModelList}
      />}
      {
        openTarget && <TargetPanel
          powerDomainId={'overview'}
          target={target}
          maxFreq={maxFreq}
          multiPCB={true}
          title={'Target'}
          updateTarget={this.props.saveMultiPCBTarget}
          closeTargetPanel={this.changeTargetPanel}
        />
      }
      {
        openCurrent && <DieCurrentPanel
          title={`${currentDesignId === 'Die' ? 'Die' : 'IC'} Current`}
          dieCurrent={dieCurrent}
          updateDieCurrent={this.updateCurrent}
          closeCurrentPanel={this.changeCurrentPanel}
          customSpiceList={customSpiceList}
          applyVisible={currentDesignId === 'Die' ? true : false}
          maxStopTime={maxStopTime}
        />
      }
      {
        openSwitchPkg && <SwitchPkgPanel
          projectId={openProjectId}
          designId={currentPkgId}
          switchPackage={this.props.switchPackage}
          closeSwitchPanel={this.changePkgPanel}
        />
      }
      {
        openInterposer && <InterPoserPanel
          closeModal={this.closeInterposerPanel}
          onDieSpiceList={customSpiceList}
          pinsInfo={pinsInfo}
          interposer={interposer}
          getPkgSpiceModelList={getPkgSpiceModelList}
        />
      }
      {
        openRleakRdl && <RleakRdlPanel
          closeModal={this.closeRleakRdlPanel}
          rleak={rleak}
          rdl={rdl}
        />
      }
      {
        openTransientTime && <TransientTimePanel
          closeModal={this.closeTransientTimePanel}
          targetICData={targetICData}
        />
      }
    </Fragment>
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    project: { openProjectId },
    Impedance: { designId, verificationId, loading, targetIC, targetDie = [], data = [], status, page, target = [], impedanceOverviewDisplay, hasInterposer },
    library: { customSpiceList, customTouchstoneList, cpmList } } } = state
  const layout = data.find(item => item.designId === designId);
  const hasPkg = data.find(item => item.index === TARGET_PACKAGE_INDEX)
  const hasDie = data.find(item => item.index === TARGET_DIE_INDEX)
  const customModelType = hasDie ? hasDie.customType : ''
  const packageId = hasPkg ? hasPkg.designId : ''
  const pcb = designConstructor.getDesign(designId)
  return {
    loading,
    openProjectId,
    verificationId,
    targetIC,
    data,
    status,
    pcbId: designId,
    page,
    customSpiceList,
    customTouchstoneList,
    target,
    targetDie,
    maxFreq: layout && layout.extraction ? (layout.extraction.FMAX || null) : null,
    impedanceOverviewDisplay,
    packageId,
    pcb,
    hasInterposer,
    hasPkg,
    customModelType,
    cpmList,
  };
}

const mapDispatch = (dispatch) => ({
  changeImpedancePage(page) {
    dispatch(changeImpedancePage(page))
  },
  createNewLayout(pcbId, connectors) {
    dispatch(createNewLayout(pcbId, connectors))
  },
  updateImpedanceStatus(status) {
    dispatch(updateImpedanceStatus(status))
  },
  deleteLayout(designId) {
    dispatch(deleteLayout(designId))
  },
  deleteConnector(designId, connector) {
    dispatch(deleteLayoutConnector(designId, connector))
  },
  traceOverview() {
    dispatch(traceOverview())
  },
  deleteShip(ship, connector) {
    dispatch(deleteShip(ship, connector))
  },
  createDie() {
    dispatch(createDie())
  },
  saveOnDieModel(model, targetDie, modelPowerNet, modelIndex, apply, customType, dieCurrentList) {
    dispatch(saveOnDieModel(model, targetDie, modelPowerNet, modelIndex, apply, customType, dieCurrentList))
  },
  saveMultiPCBTarget(target) {
    dispatch(saveMultiPCBTarget(target))
  },
  saveDieCurrent({ dieCurrent, currentDesignId, currentComp, currentPowerNet, currentIndex, apply }) {
    dispatch(saveDieCurrent({ dieCurrent, currentDesignId, currentComp, currentPowerNet, currentIndex, apply }))
  },
  savePMICVoltage(value, name) {
    dispatch(savePMICVoltage(value, name))
  },
  switchPackage(designId) {
    dispatch(switchPackage(designId))
  },
  saveInterposer(info, interposerDie, interposerPowerNet, interposerIndex, apply) {
    dispatch(saveInterposer(info, interposerDie, interposerPowerNet, interposerIndex, apply))
  },
  saveInterposerNode(value, key, name, powerNet, portIndex) {
    dispatch(saveInterposerNode(value, key, name, powerNet, portIndex))
  },
  saveHasInterposer(hasInterposer) {
    dispatch(saveHasInterposer(hasInterposer))
  },
  updateCurrentStatus(enable, designId, name) {
    dispatch(updateCurrentStatus(enable, designId, name))
  },
  saveRleakAndRrdl({ rleak, rdl, apply, rComp, rPowerNet, rPortIndex }) {
    dispatch(saveRleakAndRrdl({ rleak, rdl, apply, rComp, rPowerNet, rPortIndex }))
  },
  saveTransientTimeSetting(startTime, stopTime, stepTime) {
    dispatch(saveTransientTimeSetting(startTime, stopTime, stepTime))
  }
})

export default connect(mapState, mapDispatch)(OverviewPage);
