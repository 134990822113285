import { PCBS } from "../../../constants/treeConstants";
import { PROJECTS_INDEX } from "../index";

function sweepListToTree({ treeItems, projectId, designId, channelId, sweepInfo }) {
  const { projectIndex, PCBsIndex, designIndex, channelIndex } = getIndex({ treeItems, projectId, designId, channelId });
  if (designIndex < 0 || projectIndex < 0 || channelIndex < 0) {
    return treeItems;
  }

  const _treeItems = [...treeItems];
  _treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex].children[designIndex].children[channelIndex].children = [...sweepInfo];
  return _treeItems;
}

function getIndex({ treeItems, projectId, designId, channelId }) {
  const projectIndex = treeItems[PROJECTS_INDEX].children.findIndex(item => item.id === projectId);
  const PCBsIndex = projectIndex > -1 ? treeItems[PROJECTS_INDEX].children[projectIndex].children.findIndex(item => item.dataType === PCBS) : -1;
  const designIndex = PCBsIndex > -1 ? treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex].children.findIndex(item => item.id === designId) : -1;
  const channelIndex = designIndex > -1 ? treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex].children[designIndex].children.findIndex(item => item.id === channelId) : -1;

  return { projectIndex, PCBsIndex, designIndex, channelIndex };
}

export {
  sweepListToTree,
  getIndex
}