import LayoutData from '@/services/data/LayoutData';
import { probePinsPointTypes } from '../../../pages/Sierra/constants';
import { getComponentsWithNetList } from '@/services/Sierra'
import DesignInfo from '@/services/Sierra/pcbInfo';
import RelateNetsGroup from "../../PCBHelper/relateNets";
import { strFormatChange, strReplaceAll } from "../../helper/stringHelper";
import { typeSort, getSignalName, getAdvanceConfig } from './InterfaceHelper'
import { getInterDefaultName } from '@/services/helper/setDefaultName';
import { ESPI, QSPI, SPI } from '../../PCBHelper/constants';
import { COMP_REPEATER, IC } from '../../PCBHelper';
import { getCompTypeByRepeaterPinMap } from '../interfaceData';
import _ from 'lodash';
import { getDefaultName } from '../../helper/setDefaultName';
import { getCompType } from '../../Designs/compTypeHelper';
import { SIERRA } from '../../../constants/pageType';

const SPIQSPIeSPI = 'SPI/QSPI/eSPI', I2S = 'I2S', CLKTYPE = 'CLK', CUSTOM = 'Custom';
class FindInterface {
  constructor(pcbId) {
    this.type = '';
    this.advancedConfig = {};
    this.pcbId = pcbId;

    this.LayoutData = null;
    this.netsList = [];
    this.layers = null;
    this.componentNetList = [];
    this.logList = [];
    this.logs = [];
    this.netNamePrefixStrList = []; //net name prefix item str: ["I2C","I3C"] -> "(I2C)|(I3C)"
    this.netNamePrefixRegList = [];//net name prefix reg: /(I2C)|(I3C)/ig
    this.excludeNetsRegList = null;//exclude nets name prefix reg list: [/(I3C)/ig, /(I2C)/ig]
  }

  autoFilterInterfacePcbSignalNets({ advancedConfig, component, pcbs, currentPcbSetting }) {
    const { type, netNamePrefix, isRegular, EXCLUDE, excludeIsRegular } = advancedConfig;
    const { pcbId } = this;
    this.type = type;
    const { regList, strList } = this.setNetNamePrefixReg(netNamePrefix, isRegular) || {};
    this.netNamePrefixRegList = regList;
    this.netNamePrefixStrList = strList;
    this.excludeNetsRegList = this.getExcludeNetReg(EXCLUDE, excludeIsRegular);

    if (!this.netNamePrefixRegList || !this.netNamePrefixRegList.length) {
      return;
    }

    this.advancedConfig = { ...advancedConfig };
    this.currentPcbSetting = currentPcbSetting;

    const pcbInfo = DesignInfo.getPCBInfo(pcbId);
    const { netsList = [], layers = [] } = pcbInfo || {}
    this.netsList = netsList || [];
    this.layers = layers || [];
    // Filter nets that match the net name prefix
    let filterNetList = this.getNetsByNetNamePrefix(netsList);
    // Filter nets that match the selected component
    this.filterNetsBySelectedComps(component, filterNetList);

    this.LayoutData = LayoutData.getLayout(pcbId);
    this.compPinsNets = LayoutData.getCompPinsNets(pcbId);

    let signalsInfo = this.getSignalsInfo(pcbs)
    return signalsInfo
  }

  getLogList = () => {
    this.logObj = [];
    if (!this.logList) {
      return []
    }
    for (let item of this.logList) {
      const level = this.getLogLevel(item.type);
      if (this.selectNets.includes(item.net)) {
        continue;
      }

      if (!this.logObj[item.net] || level > this.logObj[item.net].level) {
        this.logObj[item.net] = {
          level: level,
          log: item.log
        }
      }
    }
    this.logs = Object.keys(this.logObj).map(item => this.logObj[item].log);
    return this.logs;
  }

  getLogLevel = (level) => {
    switch (level) {
      case "NetNamePrefix":
        return 1;
      case "Component":
        return 2;
      case "Power":
        return 3;
      case "CLK":
        return 4;
      case "Data":
        return 5;
      case "DifferenceNets":
        return 6;
      case "DataGroup":
        return 7;
      case "EXCLUDE":
        return 8;
      case "oneChip":
        return 9;
      default: return 0;
    }
  }

  setNetNamePrefixReg = (netNamePrefix, isRegular) => {
    let netRegList = [], netStr = "", strList = [];
    if (isRegular) {
      for (let item of netNamePrefix || []) {
        const regItem = regularNetName(item)
        if (regItem === false) { continue }
        netRegList.push(regItem);
        strList.push(item.toString());
      }
      /* const _netNamePrefix = regularNetName(netNamePrefix)
      if (_netNamePrefix === false) { return null }
      netReg = _netNamePrefix;
      netStr = _netNamePrefix.toString(); */
    } else {
      const { reg, str } = getStrListReg(netNamePrefix, true);
      if (reg) {
        const netReg = regularNetName(reg);
        netStr = str;
        strList = [str];
        netRegList = [netReg]
      }
    }
    return { regList: netRegList, str: netStr, strList }
  }

  getExcludeNetReg = (EXCLUDE, excludeIsRegular) => {
    if (excludeIsRegular) {
      const regList = [];
      for (let item of EXCLUDE || []) {
        const regItem = regularNetName(item)
        if (regItem === false) { continue }
        regList.push(regItem);
      }
      return regList.length ? regList : null;
    } else {
      const reg = getStrListReg(EXCLUDE);
      return reg ? [reg] : null;
    }
  }

  getNetsByNetNamePrefix = (netsList) => {
    let filterNetList = [];
    for (let net of netsList) {
      if (!net.mGeomList.length && !net.mPinList.length && !net.mViaList.length) {
        continue;
      }

      if (this.netNamePrefixRegList.find(item => !!net.mName.match(item))) {
        filterNetList.push(net);
      } else {
        this.logList.push({
          type: "NetNamePrefix",
          net: net.mName,
          log: `Net "${net.mName}" does not contain either net name prefix keywords.`
        })
      }
    }
    return filterNetList;
  }

  filterNetsBySelectedComps = (component, filterNetList) => {
    let componentNetList = [], componentNetInfoList = [];
    if (component && Object.keys(component).length) {
      for (let data of filterNetList) {
        const { mName, mPinList } = data;
        for (let mPin of mPinList) {
          if (component.name === mPin.mCompName && !componentNetList.includes(mName)) {
            componentNetInfoList = [...componentNetInfoList, data]
            componentNetList = [...new Set([...componentNetList, mName])]
          } else if (component.name !== mPin.mCompName) {
            this.logList.push({
              type: "Component",
              net: mName,
              log: `Net "${mName}" is not connected to selected component ${component.name}.`
            })
          }
        }
      }
    } else {
      // find all comp
      componentNetList = filterNetList.map(item => item.mName)
      componentNetInfoList = [...filterNetList]
    }

    this.componentNetList = componentNetList;
    this.componentNetInfoList = componentNetInfoList;
  }

  getRelateNetsByName(netName, LayoutData, currentPcbSetting, type) {
    const { pcbId } = this;
    this.relatedGroups = new RelateNetsGroup();
    this.relatedGroups.setCompPinsNets(pcbId);
    if (type) { this.type = type }
    this.currentPcbSetting = currentPcbSetting
    const netInfo = LayoutData.mNetManager.GetNetFromName(netName);
    const { relateNets } = this.getRelateNetsByNetInfo(netInfo)
    return relateNets;
  }

  getCompType = (compName, pinLength, partName, COMP_PREFIX_LIB, compPinMap) => {
    return getCompType({
      compName,
      COMP_PREFIX_LIB,
      pinLength,
      partName,
      product: SIERRA,
      compPinMap
    })
  }

  getRelateNetsByNetInfo(checkNetInfo, isMulti) {
    let relatedList = this.relatedGroups.getRelatedNetsByNet({
      net: checkNetInfo,
      designId: this.pcbId,
      setupComps: this.components,
      type: this.type,
      filterInterfaceNetRule: (configInfo, mSymbolMgr) => this.getIsPowerGNDNets(configInfo, mSymbolMgr, this.advancedConfig),
      currentPcbSetting: this.currentPcbSetting,
      checkCompsTypeFunction: this.getCompType,
      isPowerGNDFn: this.simplePowerGndJudge,
      isMulti: true
    })
    const doNotStuff = this.currentPcbSetting.doNotStuff || [];

    let relatedInfoList = [];

    for (let relatedItem of relatedList) {
      const { relatedNets = [], netTargetComponents = [], rlcComponents = [], repeaterComponents = [] } = relatedItem || {};
      const icComponents = [...new Set(netTargetComponents.filter(item => !rlcComponents.includes(item) && !repeaterComponents.includes(item) && !doNotStuff.includes(item)))];

      let relateNets = [checkNetInfo.mName];
      if (relatedNets && relatedNets.length) {
        relateNets = [...new Set([checkNetInfo.mName, ...relatedNets])]
      }
      const findExist = relatedInfoList.find(item => !!_.isEqual(relateNets, item.relateNets));
      if (findExist) { continue }
      const findIncludeContent = relatedInfoList.find(it => !!relateNets.every(item => !!it.relateNets.includes(item)));
      if (findIncludeContent) { continue }
      const includeIndex = relatedInfoList.findIndex(item => !!item.relateNets.every(_it => relateNets.includes(_it)) && relateNets.length > item.relateNets.length);
      if (includeIndex > -1) {
        relatedInfoList.splice(includeIndex, 1)
      }
      relatedInfoList.push({
        relateNets,
        icComponents
      })
    }

    if (!relatedInfoList.length) {
      relatedInfoList.push({
        relateNets: [checkNetInfo.mName],
        icComponents: []
      })
    }

    if (isMulti) {
      return relatedInfoList
    }
    return relatedInfoList[0] || { relateNets: [], icComponents: [] };
  }

  simplePowerGndJudge(configInfo = {}) {
    const { mPinList = [], mViaList = [] } = configInfo;
    if (mPinList.length > 10 && mViaList.length > 10) {
      return true;
    }
    return false;
  }

  getIsPowerGNDNets(configInfo, mSymbolMgr, advancedConfig = {}) {
    const { netNamePrefix, isRegular, allowVoltageKeywords } = advancedConfig;
    const { mName } = configInfo;
    const { powerTableNets = [] } = this.currentPcbSetting || {};

    //net includes net name prefix list
    if (!isRegular && netNamePrefix && netNamePrefix.length && netNamePrefix.includes(mName)) {
      return false;
    }

    if (powerTableNets && powerTableNets.includes(mName)) {
      this.logList.push({
        type: "Power",
        net: mName,
        log: `Net "${mName}" exists in power nets table.`
      });
      return true;
    }

    //net includes voltage definition in auroraDB
    if (configInfo.mType === "Power") {
      this.logList.push({
        type: "Power",
        net: mName,
        log: `Net "${mName}" has voltage.`
      });
      return true;
    }

    function matchNetNameVoltage(str) {
      if (str.match(/^\+v/ig) || str.match(/(VDD)|(VCC)|(GND)|(GROUND)/ig) || str.match(/\d+[PV]\d+/ig) || str.match(/[0-9]+V/ig)) {
        return true;
      }
      return false;
    }

    if (this.netNamePrefixRegList.find(it => !!mName.match(it))) {
      //net name match voltage and name prefix not match voltage
      if (!allowVoltageKeywords && matchNetNameVoltage(mName)) {

        const isPower = this.simplePowerGndJudge(configInfo);
        if (this.netNamePrefixStrList.find(str => matchNetNameVoltage(str)) && !isPower) {
          return false;
        } else {
          this.logList.push({
            type: "Power",
            net: mName,
            log: `Net "${mName}" is a power net (Includes voltage keywords${isPower ? " and the number of pins and vias is greater than 10" : ""}).`
          });
          return true;
        }
      }
      return false;
    }

    if (!allowVoltageKeywords && matchNetNameVoltage(mName)) {
      this.logList.push({
        type: "Power",
        net: mName,
        log: `Net "${mName}" is a power net (Includes voltage keywords).`
      });
      return true;
    }

    if (this.simplePowerGndJudge(configInfo)) {
      this.logList.push({
        type: "Power",
        net: mName,
        log: `Net "${mName}" is a power net (The number of pins and vias is greater than 10).`
      });
      return true;
    }

    return false;
  }

  getSignalsInfo(pcbs) {
    const { advancedConfig } = this;
    const { config, dataNameList, ISConnectTwoComp, types } = getAdvanceConfig(advancedConfig.type);
    let _returnData = [];
    if (config) {
      this.selectNets = []

      //CLK / Custom only search by net name prefix, other interface need to search clk/tx/rx/data signals
      let regList = [];
      if (config === "netNamePrefix") {
        //CLK / CUSTOM
        regList = this.netNamePrefixRegList;
      } else {
        //I2C/I3C/I3S/SPMI/SPI/QSPI/ESPI/UART
        const reg = getStrListReg(advancedConfig[config]);
        reg && regList.push(reg);
      }

      if (!regList || !regList.length) {
        return _returnData;
      }

      const signalGroupData = this.getCLKNetInfo({
        regList,
        pcbs,
        dataNameList,
        ISConnectTwoComp,
        types,
        clkKeys: advancedConfig[config]
      });

      if (signalGroupData && signalGroupData.length) {
        _returnData = [..._returnData, ...signalGroupData]
      }
    }
    _returnData = this.addMergeSignalNameToSignals(_returnData)
    return _returnData
  }

  addMergeSignalNameToSignals = (dataList) => {
    let signalNames = []
    for (let i = 0; i < dataList.length; i++) {
      const _interface = dataList[i];
      _interface.interfaceKey = `${_interface.type}-${i}`;
      _interface.content.forEach((item, index) => {
        //item ->{name, nets:[]}
        const filterNets = item.nets.filter(it => it.match(item.name));
        let _filterNets = filterNets.length ? filterNets : [];
        if (!_filterNets.length) {
          //Filter N net: N12345679/48N45678
          _filterNets = item.nets.filter(it => !it.match(/^([0-9]*)N([0-9]+)$/ig))
        }
        _filterNets = _filterNets.length ? _filterNets.sort((a, b) => a.length - b.length) : [...item.nets].sort((a, b) => a.length - b.length);
        item.signalMergeName = _filterNets.length ? _filterNets[0] : item.nets[0];
        if (signalNames.includes(item.signalMergeName)) {
          item.signalMergeName = getDefaultName({ nameList: signalNames, key: "", defaultKey: item.signalMergeName, firstIndex: 1, delimiter: "_" })
        }
        signalNames.push(item.signalMergeName);
        item.signalId = `${_interface.interfaceKey}::${item.signalMergeName}::${index}`;
      })
      dataList[i] = _interface;
    }
    return dataList;
  }

  getAllDataSignalNets(dataNets, CLKCompNetListName, ISConnectTwoComp, filterCLKNet) {
    let _dataNet = [], selectDataNets = [];
    for (let dataNet of dataNets) {
      const { nets } = dataNet;
      if (!nets || !nets.length || selectDataNets.includes(nets[0])) { continue }
      const configInfo = this.componentNetInfoList.find(item => item.mName === nets[0])
      let { relateNets: dataSignalNets, icComponents } = this.getRelateNetsByNetInfo(configInfo)
      dataSignalNets = dataSignalNets.filter(item => item !== filterCLKNet)
      const { nets: _nets, excludeNets } = this.filterExcludeNets(dataSignalNets);
      dataSignalNets = _nets;
      const icComps = this.getICComponents({
        nets: dataSignalNets,
        CLKCompNetListName,
        icComponents,
        excludeNets
      })

      if (icComps && icComps.length > 1) {
        selectDataNets = [...selectDataNets, ...dataSignalNets];
        _dataNet.push({ ...dataNet, nets: [...dataSignalNets] });
      } else {
        this.addOneChipNetsLog(dataSignalNets);
      }
    }
    return _dataNet
  }

  findDataNet({ filterCLKNet, dataKey, clkData }) {
    // example: UART8_RXD  UART8_TXD
    const { componentNetList, advancedConfig } = this
    const replaceReg = new RegExp(clkData, "ig");
    let dataNet = filterCLKNet.replace(replaceReg, dataKey);
    if (componentNetList.includes(dataNet)) { return dataNet }

    //example: MCASP1_AXR5/UART8_RXD  MCASP1_AXR6/UART8_TXD
    const netNameList = filterCLKNet.split("/");
    const name = netNameList.find(item => item.toLowerCase().includes(clkData.toLowerCase()))

    const dataName = name.replace(replaceReg, dataKey);
    let new_name = componentNetList.find(item => item.includes(dataName))
    if (new_name) {
      return new_name
    }
    // const filterCLKNet = 'GPPC_C12_AUART1_RXD_ISH_UART1_RXD', clkData = 'RX', type = 'UART', dataKey = 'TX';
    // const rule = 'SPI|I2C'
    // const filterCLKNet = 'QUP0_SE4_I2C_SCL_SPI_MOSI', clkData = 'SCL', type = 'I2CI3C', dataKey = 'SDA', I2CI3C = 'I2CI3C';
    // const componentNetList = ['GPPC_B9_I2C5_SDA_ISH_I2C2_SDA', 'GPPC_B5_ISH_I2C0_SDA', 'GPPC_C16_I2C0_SDA', 'GPPC_C12_I2C0_SDA', 'GPPC_C12_I2C1_SDA', 'QUP0_SE4_I2C_SDA_SPI_MISO', 'QUP0_SE4_I2C_SCL_SPI_MOSI']
    // const netNamePrefix = ['I2C', 'I3C']

    const { isRegular, netNamePrefix } = advancedConfig;
    let matchName = null;
    const _rule = `${clkData}`;
    const _regObj = new RegExp('(' + _rule + ')', 'ig');
    if (isRegular === false) {
      const new_rule = netNamePrefix.join('|');
      const netNamePrefixRegObj = new RegExp('((' + new_rule + ')(.*))', 'i');
      if (!filterCLKNet.match(netNamePrefixRegObj)) { return }
      matchName = filterCLKNet.match(netNamePrefixRegObj)[0];

      const sameData = filterCLKNet.split("_").filter(item => !item.match(_regObj));
      const _data = matchName.replace(_regObj, dataKey)
      const newNameList = componentNetList.filter(item => item.toLowerCase().includes(_data.toLowerCase()))
      new_name = this.findDataName(newNameList, sameData, dataKey)
      if (!new_name) {
        let filterData = filterCLKNet.split("_").filter(item => item.match(_regObj));
        filterData = [...new Set(filterData)]
        let newComponentNetList = [];
        filterData.forEach(item => {
          const newData = item.replace(_regObj, dataKey);
          const netList = componentNetList.filter(item => item.toLowerCase().includes(newData.toLowerCase()))
          newComponentNetList = [...new Set([...newComponentNetList, ...netList])]
        })
        new_name = this.findDataName(newComponentNetList, sameData, dataKey)
      }
      if (new_name) {
        return new_name
      }
    } else {
      const replaceCLKData = filterCLKNet.replace(_regObj, dataKey)
      const splitCLKNet = replaceCLKData.split('_');
      const bestMatchingNet = this.getBestMatchingNet(splitCLKNet, componentNetList, filterCLKNet, dataKey)
      if (bestMatchingNet) {
        return bestMatchingNet
      }
    }

  }

  getBestMatchingNet(splitCLKNet, componentNetList, filterCLKNet, dataKey) {
    let bestNetInfo = { number: 0, net: '' }
    for (let componentNet of componentNetList) {
      if (filterCLKNet === componentNet || !componentNet.includes(dataKey)) { continue }
      const splitNets = componentNet.split('_');
      const sameData = splitCLKNet.filter(it => splitNets.includes(it));
      if (!sameData.length) {
        continue;
      }
      if (sameData.length > bestNetInfo.number) {
        bestNetInfo = { number: sameData.length, net: componentNet }
      } else if (sameData.length === bestNetInfo.number && splitNets.length === splitCLKNet.length) {
        bestNetInfo = { number: sameData.length, net: componentNet }
      }
    }
    // if (bestNetInfo.number > splitCLKNet.length - 2) {
    //   return bestNetInfo.net
    // }
    return null
  }

  findDataName(nameList, sameData, dataKey) {
    function arrHasSameValue(arr1, arr2) {
      return arr1.filter(item => {
        if (arr2.indexOf(item) > -1) {
          return item;
        }
        return false;
      })
    }
    if (nameList && nameList.length) {
      let MinDataSplitData = 0, MaxSameListLength = 0, netName;
      const dataReg = new RegExp(dataKey, "i");
      // const a =Math.abs(8-10);
      const netPrefixRegList = this.netNamePrefixStrList.map(str => new RegExp(`^${str}$`, "ig"))
      for (let newName of nameList) {
        const dataSplitData = newName.split("_").filter(item => !item.includes(dataKey) && !netPrefixRegList.find(reg => item.match(reg)));
        const same = arrHasSameValue(sameData, dataSplitData)
        const sameLength = same && same.length ? same.length : 0;
        const dataSplitDataLength = dataSplitData && dataSplitData.length ? dataSplitData.length : 0;
        if (sameLength === 0) {
          continue;
        }
        /* if ((!MinDataSplitData || !MaxSameListLength) && newName.match(dataReg)) {
          MinDataSplitData = dataSplitDataLength;
          MaxSameListLength = sameLength;
          netName = newName
        } */
        if ((sameLength > MaxSameListLength) && newName.match(dataReg)) {
          MinDataSplitData = dataSplitDataLength;
          MaxSameListLength = sameLength;
          netName = newName;
        } else if (sameLength === MaxSameListLength && (Math.abs(dataSplitDataLength - sameData.length) < Math.abs(MinDataSplitData - sameData.length)) && newName.match(dataReg)) {
          MinDataSplitData = dataSplitDataLength;
          MaxSameListLength = sameLength;
          netName = newName
        }
      }
      return netName;
    }
  }

  getChipSelect({ filterCLKNet, CLKCompNetListName, dataContent, clkKey }) {
    const { advancedConfig } = this;
    if (!advancedConfig.ChipSelect || !advancedConfig.ChipSelect.length) { return { chipSelectContent: [], chipSelectNetList: [] } }
    const splitClkData = filterCLKNet.split("_");
    const splitData = dataContent[0].nets[0].split("_");
    const filterData = splitClkData.filter(info => splitData.includes(info));
    let chipContent = [], chipSelectNetList = [];
    const netNameList = filterCLKNet.split("/");
    const filterCLKNetFindName = netNameList.find(item => item.toLowerCase().includes(clkKey.toLowerCase()))

    advancedConfig.ChipSelect.forEach(item => {
      let checkNetList = [], checkNetInfoList = [];
      if (filterData.length) {
        // filter eligible nets
        for (let configInfo of this.componentNetInfoList) {
          const { mName: compNet } = configInfo;
          if (compNet === filterCLKNet) { continue }
          const splitCompNetDates = compNet.split("_");
          const CompareData = filterData.filter(it => !splitCompNetDates.includes(it))
          const filterDiffData = splitCompNetDates.filter(it => !filterData.includes(it))
          if (!CompareData.length) {
            const filterNet = filterDiffData.filter(it => it.includes(item))
            if (filterNet && filterNet.length) {
              checkNetList.push(configInfo)
            }
          } else {
            // const filterCLKNet = "MCASP2_AXR3/SPI3_CLK", compNet = 'MCASP2_AXR3/SPI3_CS3', clkKey = 'CLK', item = 'CS';
            const _dataName = filterCLKNetFindName.replace(clkKey, item)
            const configFindInfo = compNet.split("/").find(it => it.includes(item));
            const _reg = new RegExp('(^' + _dataName + '($|[0-9]))', 'i');
            if (_reg.test(configFindInfo)) {
              checkNetList.push(configInfo)
            }
          }
        }

        let usedCheckNets = [];
        for (let checkNetInfo of checkNetList) {
          const { mName: checkNetName } = checkNetInfo;
          if (usedCheckNets.includes(checkNetName) || this.getIsPowerGNDNets(checkNetInfo, this.LayoutData.mSymbolMgr, advancedConfig)) { continue }
          let { relateNets: dataSignalNets, icComponents } = this.getRelateNetsByNetInfo(checkNetInfo)

          let continueList = []
          // Filter to find nets that have appeared before
          for (let dataSignalNet of dataSignalNets) {
            if (usedCheckNets.includes(dataSignalNet)) {
              continueList.push(dataSignalNet)
            }
          }
          if (continueList.length) { continue }
          const { nets, excludeNets } = this.filterExcludeNets(dataSignalNets);
          dataSignalNets = nets;
          const _icComps = this.getICComponents({
            nets: dataSignalNets,
            icComponents,
            excludeNets,
            CLKCompNetListName
          })
          if (_icComps && _icComps.length > 1) {
            usedCheckNets = [...usedCheckNets, ...dataSignalNets]
            checkNetInfoList.push({ nets: [...dataSignalNets] })
          } else {
            this.addOneChipNetsLog(dataSignalNets);
          }
        }
      }

      if (checkNetInfoList.length > 1) {
        const _reg = new RegExp('(^' + item + '($|[0-9]))', 'i');
        let nameList = [];
        checkNetInfoList.forEach((it, index) => {
          const findName = it.nets[0].split('_').find(splitInfo => _reg.test(splitInfo))
          let name = findName && !nameList.includes(findName) ? findName : `${item}${index}`
          nameList.push(name)
          chipContent.push({ name: name, nets: it.nets })
          chipSelectNetList = [...chipSelectNetList, ...it.nets]
        })
      } else if (checkNetInfoList.length === 1) {
        chipContent.push({ name: item, nets: checkNetInfoList[0].nets })
        chipSelectNetList = [...chipSelectNetList, ...checkNetInfoList[0].nets]
      }
    })
    return { chipSelectContent: chipContent, chipSelectNetList }
  }

  getICComponents = ({ nets, CLKCompNetListName, excludeNets, icComponents }) => {
    if (icComponents && icComponents.length && !CLKCompNetListName && (!excludeNets || !excludeNets.length)) {
      return icComponents;
    }
    const { netsList, layers, pcbId, currentPcbSetting } = this;
    const { compPrefixLib, doNotStuff, compPinMap, passiveTable } = currentPcbSetting;
    const componentsList = getComponentsWithNetList({
      netList: nets || [],
      pcbNetsList: netsList,
      layers,
      pcbId,
      COMP_PREFIX_LIB: compPrefixLib,
      doNotStuff,
      passiveTable,
      compPinMap
    });
    const filterRepeater = componentsList.filter(item => item.type === COMP_REPEATER);
    const icComps = []
    if (filterRepeater && filterRepeater.length) {
      for (let item of filterRepeater) {
        const pins = componentsList.filter(it => it.name === item.name);
        const type = getCompTypeByRepeaterPinMap({ type: item.type, part: item.part, pins, compPinMap, compName: item.name });
        type === IC && icComps.push(item.name)
      }
    }

    const _filterData = componentsList.filter(compNet => probePinsPointTypes.includes(compNet.type) && (!CLKCompNetListName || CLKCompNetListName.includes(compNet.name)))
    return [...new Set([..._filterData.map(item => item.name), ...icComps])]
  }

  filterNetsByCLKKeywords = (regList) => {
    let filterConfigNetList = [];
    const { advancedConfig, type, componentNetInfoList } = this;
    if ([CLKTYPE, CUSTOM].includes(type)) {
      //rule - filter net name includes
      /* let rule = type === CLKTYPE ? /(SCLK)|(I2C)|(I3C)|(SPI)|(GPIO)|(I2S)/ : null; */
      const isDifferential = advancedConfig.isDifferential ? advancedConfig.isDifferential : false;
      filterConfigNetList = this.getCLKTypeClkNet({ isDifferential })
    } else {
      filterConfigNetList = componentNetInfoList.filter(item => {
        if (regList.find(it => !!item.mName.match(it))) {
          return item;
        } else {
          this.logList.push({
            type: "CLK",
            net: item.mName,
            log: `Net "${item.mName}" does not contain either CLK keywords.`
          });
          return false;
        }
      })
      if (type === I2S) {
        // If WS and CLK and MCLK have similar parts, place the net that meets WS and MCLK at the back of the team
        const wsNetNameReg = getStrListReg([...advancedConfig.WS, ...advancedConfig.MCLK]);
        filterConfigNetList = filterConfigNetList.sort((a, b) => {
          return wsNetNameReg.test(b.mName) ? -1 : 1
        })
      }
    }
    return filterConfigNetList;
  }

  getExcludeNets = (nets) => {
    if (this.excludeNetsRegList && this.excludeNetsRegList) {
      const filterNets = nets.filter(item => this.excludeNetsRegList.find(it => item.match(it)));
      return filterNets.length ? filterNets : false;
    }
    return false;
  }

  filterExcludeNets = (nets) => {
    const excludeNets = this.getExcludeNets(nets);
    if (excludeNets && excludeNets.length) {
      nets = nets.filter(item => !excludeNets.includes(item));
      for (let net of excludeNets) {
        this.logList.push({
          type: "EXCLUDE",
          net: net,
          log: `Net "${net}" is a EXCLUDE net.`
        });
      }
    }
    return { nets, excludeNets }
  }

  addOneChipNetsLog = (nets, netType) => {
    for (let net of (nets || [])) {
      let log = `The signal of net ${net} is connected to only one chip component.`;
      if (netType === "data") {
        log = `The CLK signal of data net ${net} is connected to only one chip component`;
      }
      this.logList.push({
        type: "oneChip",
        net: net,
        log
      });
    }
  }

  getCLKNetInfo({
    regList,
    clkKeys,
    pcbs,
    dataNameList,
    ISConnectTwoComp,
    types }) {
    const { advancedConfig, pcbId, type, componentNetList } = this;

    let filterConfigNetList = this.filterNetsByCLKKeywords(regList);
    let _returnData = [];

    for (let configInfo of filterConfigNetList) {
      const { mName: filterCLKNet } = configInfo;
      /*  if (this.selectNets.includes(filterCLKNet)) { continue } */
      const { excludeNets: clkExcludeNets } = this.filterExcludeNets([filterCLKNet]);
      if (clkExcludeNets && clkExcludeNets.includes(filterCLKNet)) {
        continue;
      }
      if (this.getIsPowerGNDNets(configInfo, this.LayoutData.mSymbolMgr, advancedConfig)) { continue }
      this.relatedGroups = new RelateNetsGroup();
      this.relatedGroups.setCompPinsNets(this.pcbId);

      let content = [], _interfaceType = '';
      if ([CLKTYPE, CUSTOM].includes(type)) {
        let relatedList = this.getRelateNetsByNetInfo(configInfo, true);

        for (let relateItem of relatedList) {
          let { relateNets: clkSignalNets, icComponents } = relateItem || {};

          const { nets, excludeNets } = this.filterExcludeNets(clkSignalNets);
          clkSignalNets = nets;
          const findContent = _returnData.find(it => !!it.content.find(item => _.isEqual([...clkSignalNets].sort(), [...item.nets].sort())))
          if (findContent) { continue }
          const findIncludeContent = _returnData.find(it => !!it.content.find(item => clkSignalNets.every(_it => item.nets.includes(_it))))
          if (findIncludeContent) { continue }
          _interfaceType = type;
          let name = filterCLKNet;
          if (advancedConfig.isRegular) {
            const findName = regList.find(item => filterCLKNet.match(item));
            const _name = findName ? filterCLKNet : type;

            const nameList = clkSignalNets.filter(item => !!regList.find(reg => item.match(reg))).sort((a, b) => a.length - b.length);
            name = nameList && nameList[0] ? nameList[0] : _name;
          } else {
            name = clkKeys.find(item => !!filterCLKNet.toLowerCase().includes(item.toLowerCase())) || clkKeys[0];
          }
          const icComps = this.getICComponents({
            nets: clkSignalNets,
            excludeNets,
            icComponents
          })
          if (icComps && icComps.length > 1) {
            //remove exist signal 
            const includeIndex = _returnData.findIndex(it => !!it.content.find(item => item.nets.every(_it => clkSignalNets.includes(_it)) && clkSignalNets.length > item.nets.length));
            if (includeIndex > -1) {
              _returnData.splice(includeIndex, 1)
            }
            this.selectNets = [...this.selectNets, filterCLKNet, ...clkSignalNets];
            _returnData.push({
              content: [{
                name, nets: [...clkSignalNets]
              }],
              pcbs,
              pcbId,
              type: _interfaceType
            })
          } else {
            this.addOneChipNetsLog(clkSignalNets);
          }
        }
      } else {
        if (this.selectNets.includes(filterCLKNet)) { continue }
        let { relateNets: clkSignalNets, icComponents } = this.getRelateNetsByNetInfo(configInfo)

        const { nets } = this.filterExcludeNets(clkSignalNets);
        clkSignalNets = nets;
        const filterClkKeys = clkKeys.filter(item => { const _reg = new RegExp(item, "ig"); return filterCLKNet.match(_reg) }).sort((a, b) => b.length - a.length);
        const clkKey = filterClkKeys[0] || clkKeys[0];

        let _componentNetList = [...componentNetList]
        //get data signals by clk
        const { dataContent, dataSignalNetList, interfaceType, newClkSignalNets } = this.getDataNetInfo({
          dataNameList,
          filterCLKNet,
          clkKey,
          clkSignalNets,
          ISConnectTwoComp,
          types,
          componentNetList: _componentNetList
        })
        if (newClkSignalNets && newClkSignalNets.length) {
          clkSignalNets = [...newClkSignalNets]
        }

        if (dataContent && dataContent.length) {
          _interfaceType = interfaceType;
          const { nets, excludeNets } = this.filterExcludeNets(clkSignalNets);
          clkSignalNets = nets;
          let name = clkKey;
          const icComps = this.getICComponents({
            nets: clkSignalNets,
            excludeNets,
            icComponents
          })
          if (icComps && icComps.length > 1) {
            this.selectNets = [...this.selectNets, filterCLKNet, ...clkSignalNets, ...dataSignalNetList]
            content = [
              { name: name, nets: [...clkSignalNets] },
              ...dataContent
            ]
          } else {
            this.addOneChipNetsLog(clkSignalNets);
            this.addOneChipNetsLog(dataSignalNetList, "data");
          }

        } else {
          this.logList.push({
            type: "Data",
            net: filterCLKNet,
            log: `CLK Net "${filterCLKNet}" does not match data nets.`
          });
        }
        if (content.length) {
          _returnData.push({ content, pcbs, pcbId, type: _interfaceType })
        }
      }
    }
    return _returnData;
  }

  getDataNetInfo({ dataNameList, filterCLKNet, clkKey, clkSignalNets, ISConnectTwoComp, types, componentNetList }) {
    const { advancedConfig, netsList, layers, pcbId, type, currentPcbSetting } = this;
    const { compPrefixLib, doNotStuff, passiveTable, compPinMap } = currentPcbSetting;

    const _clkComponentsList = getComponentsWithNetList({ netList: clkSignalNets, pcbNetsList: netsList, layers, pcbId, COMP_PREFIX_LIB: compPrefixLib, doNotStuff, passiveTable, compPinMap });
    const CLKCompNetList = _clkComponentsList.filter(compNet => probePinsPointTypes.includes(compNet.type));
    const CLKCompNetListName = CLKCompNetList.map(info => info.name);
    const splitNetData = filterCLKNet.split("_")
    let sameData = splitNetData.filter(item => item.toLowerCase() !== clkKey.toLowerCase());
    let newClkSignalNets = JSON.parse(JSON.stringify(clkSignalNets));
    let dataSignalNetList = [];

    if (type === I2S) {
      return this.getI2SDataNetInfo({
        filterCLKNet,
        componentNetList,
        splitNetData,
        clkKey,
        CLKCompNetListName,
        ISConnectTwoComp,
        dataNameList
      })
    } else {
      for (let dataName of dataNameList) {
        let content = [], allDataNets = [], allNets = [];
        if (type === SPIQSPIeSPI) {
          this.getSPIQSPIESPIDataNetInfo({
            filterCLKNet,
            componentNetList,
            dataName,
            splitNetData,
            sameData,
            clkKey,
            clkSignalNets,
            allDataNets,
            allNets
          })
        } else {
          for (let dataKey of advancedConfig[dataName]) {
            // Find one of the most eligible nets
            const dataNetName = this.findDataNet({ filterCLKNet, dataKey, clkData: clkKey })
            if (dataNetName && !allNets.includes(dataNetName) && !clkSignalNets.includes(dataNetName)) {
              allDataNets.push({ nets: [dataNetName], filterData: [dataKey] })
              allNets.push(dataNetName);
            } else {
              this.logList.push({
                type: "DataGroup",
                net: filterCLKNet,
                log: `Net "${filterCLKNet}" does not belong to any ${dataName} group.`
              });
            }
          }
        }

        if (allDataNets.length) {
          const new_data = this.getAllDataSignalNets(allDataNets, CLKCompNetListName, ISConnectTwoComp, clkSignalNets)
          let findNameList = [];
          for (let dataNetInfo of new_data) {
            const { nets, filterData } = dataNetInfo;
            const findName = filterData.find(it => advancedConfig[dataName].find(ite => it.toLowerCase().includes(ite.toLowerCase())))
            let name = [SPIQSPIeSPI].includes(type) ? getSignalName(findName, nets[0]) : findName;
            if (name) {
              if (findNameList.includes(name) && ![SPIQSPIeSPI].includes(type)) {
                name = getSignalName(findName, nets[0])
              }
              findNameList = [...findNameList, name];
              const sameNet = newClkSignalNets.filter(item => nets.includes(item));
              let new_nets = [...nets]
              if (sameNet.length) {
                // have the same nets
                newClkSignalNets = newClkSignalNets.filter(item => item.includes(clkKey) || !nets.includes(item))
                const _newClkSignalNets = newClkSignalNets;
                new_nets = nets.filter(item => !_newClkSignalNets.includes(item))
              }
              dataSignalNetList.push(...new_nets);
              content.push({ name, nets: [...new_nets] });
            }
          }
        }

        if (([QSPI, ESPI].includes(dataName) && content.length > 2)
          || (dataName === SPI && content.length > 1)
          || (![QSPI, ESPI, SPI].includes(dataName) && content.length)) {
          const { chipSelectContent, chipSelectNetList = [] } = this.getChipSelect({ filterCLKNet, allDataNets, CLKCompNetListName, dataContent: content, clkKey })
          const sortContent = content.sort(typeSort)
          let _type = types.find(item => filterCLKNet.includes(item))
          if (_type === SPI && content.length > 3) { _type = QSPI }
          // get Type
          if (!_type) { _type = dataName && type === SPIQSPIeSPI ? dataName : types[0] }
          return { dataContent: [...sortContent, ...chipSelectContent], dataSignalNetList: [...dataSignalNetList, ...chipSelectNetList], interfaceType: _type, newClkSignalNets }
        }

      }
    }
    return {}
  }

  getI2SDataNetInfo = ({
    filterCLKNet,
    componentNetList,
    splitNetData,
    clkKey,
    CLKCompNetListName,
    ISConnectTwoComp,
    dataNameList
  }) => {

    const { advancedConfig, type } = this;
    let allDataNets = [], content = [], dataSignalNetList = [];

    for (let dataName of dataNameList) {
      for (let compNet of componentNetList) {
        if (compNet === filterCLKNet) { continue }
        const sameData = splitNetData.filter(item => !item.toLowerCase().includes(clkKey.toLowerCase()));
        const splitCompNetDatas = compNet.split("_");
        let diffData = getArrDifference(sameData, splitCompNetDatas)
        let _diffData = [...diffData];

        if (_diffData && _diffData.length === 1) {
          //compNet: I2S_DAT_1 -> splitCompNetDatas:["I2S","DAT","1"] -> filterDataList: ["DAT"]
          const filterDataList = splitCompNetDatas.filter(it => !sameData.includes(it) && isNaN(Number(it)));

          if (filterDataList && filterDataList.length) {
            let filterData = filterDataList[0];

            if (advancedConfig[dataName].find(it => filterData.includes(it))) {
              allDataNets.push({ nets: [compNet], filterData })
            } else {
              this.logList.push({
                type: "DataGroup",
                net: compNet,
                log: `Net "${compNet}" does not belong to any ${type} group.`
              });
            }
          } else {
            this.logList.push({
              type: "DataGroup",
              net: compNet,
              log: `Net "${compNet}" does not belong to any ${type} group.`
            });
          }
        } else {
          this.logList.push({
            type: "DataGroup",
            net: compNet,
            log: `Net "${compNet}" does not belong to any ${type} group.`
          });
        }
      }
    }

    let _allDataNets = allDataNets.sort((a, b) => { return a.name ? -1 : 1 })

    if (_allDataNets.length) {
      const new_data = this.getAllDataSignalNets(_allDataNets, CLKCompNetListName, ISConnectTwoComp)
      let nameList = [];
      content = new_data.map(item => {
        dataSignalNetList = [...dataSignalNetList, ...item.nets];
        let newName = ""
        if (item.name && !nameList.includes(item.name)) {
          newName = item.name
        } else {
          const names = getInterDefaultName({ nameList, defaultKey: item.name ? item.name : item.filterData })
          newName = names[0];
        }
        nameList.push({ name: newName })
        return { name: newName, nets: item.nets }
      })
    }

    if (content && content.length) {
      return { dataContent: content, dataSignalNetList, interfaceType: type }
    }
    return {};
  }

  getSPIQSPIESPIDataNetInfo = ({
    filterCLKNet,
    componentNetList,
    dataName,
    splitNetData,
    sameData,
    clkKey,
    clkSignalNets,
    allDataNets,
    allNets
  }) => {
    const { advancedConfig } = this;

    const SPIRule = dataName === 'SPI' ? `(SPI)` : `(SPI)|(${dataName})`;
    const _rule = new RegExp(SPIRule, 'i');
    const findDataType = splitNetData.find(item => item.match(_rule));
    if (!findDataType) {
      this.logList.push({
        type: "Data",
        net: filterCLKNet,
        log: `Net "${filterCLKNet}" does not contain either ${dataName} keywords.`
      });
      return allDataNets;
    }

    //SPI_CLK, SPI_MISO_D0
    //SPI_CUSTOM_CLK, SPI_CUSTOM_D0_
    for (let compNet of componentNetList) {
      if (allNets.includes(compNet) || clkSignalNets.includes(compNet)) {
        continue;
      }
      const splitCompNetData = compNet.split("_");

      if (sameData.every(it => splitCompNetData.includes(it))) {
        const filterData = splitCompNetData.filter(it => !sameData.includes(it) && isNaN(Number(it)))
        // Find multiple groups of nets that meet the conditions
        if (filterData && filterData.length) {
          if (filterData.every(it => advancedConfig[dataName].find(ite => it.includes(ite)))) {
            allNets.push(compNet);
            allDataNets.push({ nets: [compNet], filterData })
          } else {
            this.logList.push({
              type: "DataGroup",
              net: compNet,
              log: `Net "${compNet}" does not belong to any ${dataName} group.`
            });
          }
        } else {
          this.logList.push({
            type: "DataGroup",
            net: compNet,
            log: `Net "${compNet}" does not belong to any ${dataName} group.`
          });
        }
      } else {
        this.logList.push({
          type: "DataGroup",
          net: compNet,
          log: `Net "${compNet}" does not belong to any ${dataName} group.`
        });
      }
    }

    if (!allDataNets.length) {
      // const filterCLKNet = 'MCASP2_AXR3/SPI3_CLK', clkKey = 'CLK', dataName = 'SPI', advancedConfig = { SPI: ['D'] };
      // const componentNetList = ['MCASP2_AXR3/SPI3_CLK', 'MCASP2_AFSX/SPI3_D0', 'MCASP2_ACLKX/SPI3_D1']
      // MCASP2_AXR3/SPI3_CLK   MCASP2_AFSX/SPI3_D0    MCASP2_ACLKX/SPI3_D1
      // When there is a slash in netName
      //find clk key MCASP2_AXR3/SPI3_CLK -> SPI3_CLK
      const newSplitCLKName = filterCLKNet.split("/").find(item => item.toLowerCase().includes(clkKey.toLowerCase()));

      for (let dataKey of advancedConfig[dataName]) {
        //SPI3_CLK -> SPI3_D0 / SPI_D / SPI_MISO
        const replaceReg = new RegExp(clkKey, "ig");
        const _dataName = (newSplitCLKName || filterCLKNet).replace(replaceReg, dataKey);
        //[MCASP2_AXR3/SPI3_D0, MCASP2_AXR3/SPI3_D, MCASP2_AXR3/SPI3_MISO]
        let newFilterDataName = componentNetList.filter(item => item.toLowerCase().includes(_dataName.toLowerCase()))
        if (newFilterDataName.length) {
          newFilterDataName.forEach(item => {
            //splitName -> SPI3_D0, SPI3_MISO, SPI3_DO1
            const splitName = item.split("/").find(it => it.toLowerCase().includes(dataKey.toLowerCase()))
            if (newSplitCLKName && splitName && newSplitCLKName.split('_').length === splitName.split('_').length) {
              allNets.push(item);
              allDataNets.push({ nets: [item], filterData: [dataKey] })
            } else {
              this.logList.push({
                type: "DataGroup",
                net: item,
                log: `Net "${item}" does not belong to any ${dataName} group.`
              });
            }
          })
        }
      }
    }

    if (!allDataNets.length) {
      // QUP0_SE4_SPI_SCLK
      // QUP0_SE4_I2C_SDA_SPI_MISO   QUP0_SE4_I2C_SCL_SPI_MOSI
      // QUP0_SE4_SPI_CS0_L QUP0_SE4_SPI_CS1_L
      for (let dataKey of advancedConfig[dataName]) {
        // Find one of the most eligible nets
        const dataNetName = this.findDataNet({ filterCLKNet, dataKey, clkData: clkKey })
        if (dataNetName && !allNets.includes(dataNetName) && !clkSignalNets.includes(dataNetName)) {
          allNets.push(dataNetName);
          allDataNets.push({ nets: [dataNetName], filterData: [dataKey] })
        } else {
          this.logList.push({
            type: "DataGroup",
            net: filterCLKNet,
            log: `Net "${filterCLKNet}" does not belong to any ${dataName} group.`
          });
        }
      }
    }
    return allDataNets;
  }

  getCLKTypeClkNet({ isDifferential }) {
    // fbest matching netilter CLK Net
    let filterNetList = [], notSelectNet = [];
    for (let configInfo of this.componentNetInfoList || []) {
      const { mName: filterCLKNet } = configInfo;
      if (notSelectNet.includes(filterCLKNet)) { continue }
      let find = '';
      const pReg = new RegExp(`(P)|(\\+)`, 'ig');
      const nReg = new RegExp(`(N)|(\\-)`, 'ig');
      const pNetStr = strReplaceAll(strReplaceAll(filterCLKNet, pReg, "N"), nReg, "N");
      //Find differential nets
      find = this.componentNetInfoList.find(net => net.mName !== filterCLKNet && strReplaceAll(strReplaceAll(net.mName, pReg, "N"), nReg, "N") === pNetStr);

      if (find) {
        notSelectNet = [...notSelectNet, find.mName, filterCLKNet];
        if (isDifferential) {
          filterNetList = [...filterNetList, find, configInfo];
        } else {
          this.logList.push({
            type: "DifferenceNets",
            net: filterCLKNet,
            log: `Net "${filterCLKNet}", "${find.mName}" are differential nets.`
          })
        }

      } else if (!isDifferential /* && (!rule || (rule && !rule.test(filterCLKNet))) */) {
        //custom rule is null, CLK -> filter i2c/i2s ...
        filterNetList = [...filterNetList, configInfo]
      } else {
        this.logList.push({
          type: "DifferenceNets",
          net: filterCLKNet,
          log: `Net "${filterCLKNet}" is not a differential net.`
        })
      }
    }
    return [...new Set(filterNetList)];
  }
}

function getStrListReg(list, returnStr = false) {
  let reg = "", str = "";
  if (!Array.isArray(list)) {
    return returnStr ? { reg, str } : reg;
  }

  const _list = list.map(item => strFormatChange(item))
  str = _list.join('|');
  if (str) {
    reg = new RegExp(`${str}`, "ig");
  }

  return returnStr ? { reg, str } : reg;
}

function getArrDifference(arr1, arr2) {
  return arr1.concat(arr2).filter(function (v, i, arr) {
    return arr.indexOf(v) === arr.lastIndexOf(v);
  });
}


function regularNetName(reg) {
  try {
    const isReg = eval(reg) instanceof RegExp;
    if (isReg) {
      return eval(reg)
    } else {
      const _reg = new RegExp('(' + reg + ')', 'i');
      if (eval(_reg) instanceof RegExp) {
        return _reg
      }
    }
    return false
  } catch (e) {
    try {
      const _reg = new RegExp('(' + reg + ')', 'i');
      if (eval(_reg) instanceof RegExp) {
        return _reg
      }
    } catch (e) {
    }
    return false
  }
}

export default FindInterface;