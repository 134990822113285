import {
  getRockyProjectList,
  deleteRockyProjectsByIDs,
  createProject,
  updateRockyChannelConfig,
  getRockyChannelConfig,
  generateRockyReportNew,
  getRockyReportStatus,
  getRockyReport,
  getRockyReportConfig,
  saveRockyReportConfig,
  updateRockyChannelExtractionConfig,
  CreateChannelPDN,
  getDesignsChannelInProject,
  getVerificationsInChannel,
  getRockyChannelJson,
  rockyCopyProject,
  rockyRenameProject,
  getProjectEyeMaskOption,
  editProjectEyeMaskOption,
  getRockyChannelJsonByDesignId,
  updatePcbAdvancedCtrl,
  getPcbAdvancedCtrl
} from '../api/Rocky/projectCtrl';
import checkError from '../api/checkError';
import { ROCKY_PROJECT_VERSION } from '../../version';
import { getUnitFromLayoutDB } from '../api/Rocky/rockyDesign';
import { deleteDesignsPromise, getPkgWireBondProfile, savePkgWireBondProfile } from '../api/Design/design';
import LayoutData from '../data/LayoutData';
import { getProjectServiceOptionsConfig, getTrashList, updateProjectServiceOptionsConfig } from '../api/v2/projectCtrl';
import apiProcess from '../api/utility';
import { message } from 'antd';
import { saveCardToLibraryCtrl } from '../api/Rocky/cardCtrl';

/**
 * Get project list
 *
 * @export
 * @returns Promise
 */
export function getProjectList() {
  return apiProcess(getRockyProjectList, null, false, true)
}


/**
 * Delete project
 * 2020/06/01
 * @export
 * @param {String} ids Project ID list
 * @returns Promise
 */
export function deleteProjectByIds(ids) {
  return apiProcess(deleteRockyProjectsByIDs, ids, false, true)
}

/**
 * Create Rocky project
 *
 * @export
 * @param {String} name Project name
 * @param {string} version project_version
 * @param {string} ddr_type "DDR3"/"DDR3L"/"DDR4"/"LPDDR4"/"DDR4L"
 * @returns Promise
 */
export function createProjectPromise(name, type, rockyVersion) {
  return apiProcess(createProject, { name, version: ROCKY_PROJECT_VERSION, ddrType: type, rockyVersion }, false, true);
};

export function getDesignUnitFromLayoutDB(pcbId) {
  return apiProcess(getUnitFromLayoutDB, pcbId);
}

/**
 * rocky copy project
 * 2020.03.04
 * @export
 * @param projectId
 * @param projectName
 * @returns
 */
export function rockyProjectCopy(projectId, projectName) {
  return apiProcess(rockyCopyProject, { projectId, projectName }, false, true)
}

/**
 * rocky  project rename
 * 2020.11.25
 * @param projectId
 * @export
 * @returns XMLHttpRequest

 */
export function changeProjectName({ projectId, projectName }) {
  return apiProcess(rockyRenameProject, { projectId, projectName }, false, true)
};

/**
 * delete design in Rocky project
 * 2020/06/02
 * @export
 * @param
 * @returns Promise
 */
export function deleteDesignInProject(id) {
  return new Promise((resolve, reject) => {
    if (!id) {
      resolve(true);
      return;
    }
    deleteDesignsPromise([id], "true").then(res => {
      LayoutData.cleanLayoutInfo(id);
      resolve(true);
    }, error => {
      checkError(error);
      resolve(true);
    })
  })
}

/**
 * Get all designs and verifications of current project
 * 2020/06/02
 * @export
 * @param  {string} id project id
 * @returns Promise
 */
export function getRockyProjectPromise(id) {
  return apiProcess(getDesignsChannelInProject, id)
}

/**
 * update channel config
 * @export
 * @param  {string} channelId
 * @param {object} config{clock,timeStep,cycles,simulator,corners}
 * @returns Promise
 */
export function updateChannelConfig({ channelId, config, resultConfig, waveformConfig, userDefinedNetlist }) {
  return new Promise((resolve, reject) => {
    const params = {};
    if (config) {
      params.config = config;
    }
    if (config) {
      params.resultConfig = resultConfig;
    }
    if (waveformConfig) {
      params.waveformConfig = waveformConfig;
    }
    if (userDefinedNetlist) {
      params.userDefinedNetlist = userDefinedNetlist;
    }
    apiProcess(updateRockyChannelConfig, { channelId, params }).then(res => {
      resolve(res)
    }, error => {
      resolve(null)
    })
  })
}


// TODO - backend need to improve the msg when channelId = 1. In this situation, the response code is 4, but hte msg is 'success'
/**
 * update channel config
 * @export
 * @param  {string} channelId
 * @returns Promise
 */
export function getChannelConfig(channelId) {
  return apiProcess(getRockyChannelConfig, channelId);
}


export function getRockyTrashList() {
  return new Promise((resolve, reject) => {
    getTrashList('Rocky').then(res => {
      if (res.data.data) {
        const filterTrash = res.data.data.filter(item => item.typeName === 'Rocky');
        resolve(filterTrash);
      } else {
        resolve([]);
      }
    }, error => {
      checkError(error, false);
      resolve([]);
    })
  })
}

export function generateReportNew(projectId, param) {
  return apiProcess(generateRockyReportNew, { projectId, param }, false, true)
};


export function getReportStatus(projectId) {
  return apiProcess(getRockyReportStatus, projectId, false, true)
};

export function getRockyReportData({ projectId, format, mime }) {
  return new Promise((resolve, reject) => {
    if (!projectId) {
      resolve(null);
      return;
    }
    getRockyReport(projectId, format).then(res => {
      resolve(`data:${mime};base64,` + btoa(
        new Uint8Array(res.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      ))
    }, error => {
      resolve(null);
      checkError(error, false);
    })
  })
};

/**
 * Get rocky report config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
export function getReportConfig(projectId, type) {
  return apiProcess(getRockyReportConfig, { projectId, type })
}

/**
 * Save rocky report config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
export function saveReportConfigPromise(projectId, config) {
  return apiProcess(saveRockyReportConfig, { projectId, config }, false, true)
}

/**
 * Get rocky project config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
export function getProjectConfig(projectId) {
  return apiProcess(getProjectServiceOptionsConfig, projectId)
}

/**
 * Save rocky project config
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
export function updateProjectConfig({ projectId, options }) {
  return apiProcess(updateProjectServiceOptionsConfig, { projectId, options })
}

/**
 * Save rocky report config
 *
 * @export
 * @param {string} channelId
 * @param {object} extractionConfig
 * @returns promise
 */
export function updateChannelExtractionConfig(channelId, extraction) {
  return new Promise((resolve) => {
    apiProcess(updateRockyChannelExtractionConfig, { channelId, extraction }).then(res => {
      resolve(res);
    }, error => {
      console.error(error)
      resolve(null)
    })
  })
};

/**
 * get channel interface.json, response: file
 *
 * @export
 * @param {string} projectId
 * @returns promise
 */
export function getChannelJson(projectId) {
  return new Promise((resolve, reject) => {
    getRockyChannelJson(projectId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        reject(null);
      }
    }, error => {
      reject(null);
    })
  })
};

/**
 * get channel interface.json by designId, response: file
 *
 * @export
 * @param {string} designId
 * @returns promise
 */
export function getChannelJsonByDesignId(designId) {
  return new Promise((resolve, reject) => {
    getRockyChannelJsonByDesignId(designId).then(res => {
      if (res.data) {
        resolve(res.data);
      } else {
        resolve(null);
      }
    }, error => {
      resolve(null);
    })
  })
}

export function CreateRockyChannelPDN({ channelId, designId, projectId, pdnContent, version }) {
  return new Promise((resolve, reject) => {
    apiProcess(CreateChannelPDN, { channelId, designId, projectId, pdnContent, version }).then(res => {
      resolve(res);
    }, error => {
      message.error('Create PDN failed! ' + error)
      resolve(null);
    })
  })
};

export function getRockyVerificationsInChannel(channelId) {
  return apiProcess(getVerificationsInChannel, channelId, false, true)
}

/**
 * Get project eye mask option
 *
 * @export
 * @param {*} projectId
 * @returns
 */
export function getProjectEyeMaskOptionPromise(projectId) {
  return apiProcess(getProjectEyeMaskOption, projectId)
};

/**
 * Edit project eye mask option
 *
 * @export
 * @param {*} data
 * @returns
 */
export function editProjectEyeMaskOptionPromise(data) {
  return apiProcess(editProjectEyeMaskOption, data, false, true)
}

/**
 * save card to library
 *
 * @export
 * @param {string} designId
 * @returns
 */
export function saveCardToLibraryPromise(designId) {
  return apiProcess(saveCardToLibraryCtrl, designId);
}

/**
 * get pcb advanced info
 *
 * @export
 * @param {string} designId
 * @returns
 */
export function getPcbAdvancedPromise(designId) {
  return apiProcess(getPcbAdvancedCtrl, designId);
}

/**
 * update pcb advanced info
 *
 * @export
 * @param {string} designId info
 * @returns
 */
export function updatePcbAdvancedPromise(designId, additionalNets) {
  return apiProcess(updatePcbAdvancedCtrl, { designId, additionalNets });
}