import { getComponentsWithNetList, } from '../../helper/setup/setupData'
import LayoutData from '../../data/LayoutData';
import {
  RLComponent,
  CapComponent,
  BasicComponent,
  Model
} from '../../helper/setup/integratedinterface';
import { checkRLCValue } from '../../helper/dataProcess';
import { CAP, IND, RES } from '../../PCBHelper';

function getComponents(netList, pcbId, COMP_PREFIX_LIB, specify = []) {
  let Components = [], exsitcomponentsName = [], findVRMComps = [], findCaps = [];
  const _Components = getComponentsWithNetList(netList, pcbId, COMP_PREFIX_LIB);
  for (const comp of _Components) {
    const { name, pin, net, value, type, part, pinName } = comp;
    const _index = exsitcomponentsName.indexOf(name);
    let _type = type;
    if (_index < 0) {
      // Not exsit component
      let newComp;
      if (_type === CAP) {
        newComp = new CapComponent({ name, usage: _type, part, pins: [{ pin, net, pinName, mName: pinName }] });
        newComp.value = ""
        newComp.model = new Model({ type: '', name: '', subcktName: '' });
        findCaps.push({ ...newComp, location: comp.location });
      } else if ([IND, RES].includes(_type)) {
        let _value = checkRLCValue(value)
        if (([RES].includes(_type))) {
          _value = 0
        }
        newComp = new RLComponent({ name, usage: _type, part, pins: [{ pin, net, pinName, mName: pinName }], value: _value });
        findVRMComps.push({ ...newComp, location: comp.location });
      } else {
        newComp = new BasicComponent({ name, usage: _type, part, pins: [{ pin, net, pinName, mName: pinName }], location: comp.location });
      }
      Components.push(newComp);
      // Update components name list
      exsitcomponentsName.push(name);
    } else {
      // Exsit component
      Components[_index].pins.push({ pin, net, pinName, mName: pinName });
    }
  }
  const vrmComp = Components.filter(item => specify.find(s => s.name === item.name))
  findVRMComps.push(...vrmComp.map(comp => {
    const nets = comp.pins.map(pin => pin.net)
    return { ...comp, location: comp.location, type: comp.usage, pinList: comp.pins, net: [...new Set(nets)] }
  }));
  return { Components, findVRMComps, findCaps };
}

function getAllComponents({ pcbId, COMP_PREFIX_LIB }) {
  const layout = LayoutData.getLayout(pcbId);
  const _netList = layout && layout.mNetManager ? layout.mNetManager.GetAllNets().mValues : [];
  const { Components } = getComponents(_netList, pcbId, COMP_PREFIX_LIB, []);
  return Components;
}

function getComponentsInfo({ pcbId, COMP_PREFIX_LIB, name }) {
  return getAllComponents({ pcbId, COMP_PREFIX_LIB }).find(item => item.name === name);
}

/**
 * 
 * @param {*} basicComp { designId, name, pins > nets > pinNames }
 * @param {*} connectComp { designId, name }
 * @returns 
 */
export function checkConnectCompsPinsAndNets(basicComp, connectComp) {
  const { designId: basicDesignId, pins: basicPins, nets: basicNets, name: basicName, pinNames: basicPinNames } = basicComp;
  const { designId, name } = connectComp;
  const compInfo = getComponentsInfo({ pcbId: designId, name });
  let pins = [], nets = [];

  if (!compInfo) {
    return { pins, nets }
  }

  if (basicPins) {
    const connectPins = compInfo.pins.filter(pin => basicPins.includes(pin.pin));
    if (connectPins.length) {
      pins = [...connectPins];
      nets = [...new Set(connectPins.map(pin => pin.net))];
      return { pins, nets }
    }
  }

  if (basicNets) {
    const basicCompInfo = getComponentsInfo({ pcbId: basicDesignId, name: basicName });
    if (!basicCompInfo) {
      return { pins, nets }
    }
    const _basicPins = basicCompInfo.pins.filter(pin => basicNets.includes(pin.net));
    const basicPinNumbers = _basicPins.map(pin => pin.pin)
    const connectPins = compInfo.pins.filter(pin => basicPinNumbers.includes(pin.pin));
    if (connectPins.length) {
      pins = [...connectPins];
      nets = [...new Set(connectPins.map(pin => pin.net))];
      return { pins, nets }
    }

    const _basicPinNames = _basicPins.map(pin => pin.pinName)
    const _connectPins = compInfo.pins.filter(pin => _basicPinNames.includes(pin.pin));
    if (connectPins.length) {
      pins = [..._connectPins];
      nets = [...new Set(_connectPins.map(pin => pin.net))];
      return { pins, nets }
    }
  }

  if (basicPinNames) {
    const connectPins = compInfo.pins.filter(pin => basicPinNames.includes(pin.pin));
    if (connectPins.length) {
      pins = [...connectPins];
      nets = [...new Set(connectPins.map(pin => pin.net))];
      return { pins, nets }
    }
  }

  return { pins, nets }
}