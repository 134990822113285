import { call, put, takeEvery, delay, select, takeLatest, fork, cancel, take } from 'redux-saga/effects';
import { } from './action';
import { GET_BMA_CHANNEL_RESULT } from './actionType';

function* getChannelResultInfo(action) {
  const { verificationId, selectInputFileIds } = action;
  try {

  } catch (error) {
    console.error(error)
  }
}

function* BMAResultSaga() {
  yield takeEvery(GET_BMA_CHANNEL_RESULT, getChannelResultInfo)
}
export default BMAResultSaga;