import { CAP, COMP_REPEATER, CONNECTOR, DIE, DIODE, FERRITE, IC, IGNORE, IND, JUMPER, LINEAR, LINEAR_SWITCH, LOAD, RES, SPECIALIZED, SWITCHING, TRANSISTOR } from "../../constants/componentType";
import { getPolygonPinSize } from "../ExtractionPortsHelper/portsSetupHelper";
import { getArraySimilar } from "../helper/arrayHelper";
import { unitChange } from "../helper/mathHelper";
import auroraDBJson from "./auroraDbData";
import { CIRCLE, POLYGEN, RECT } from "./constants";

//Judging usage by custom component reference designator.
function getCompTypeByPrefixLib(compName, COMP_PREFIX_LIB = {}) {
  let name = compName.toString(), type = IGNORE;
  //update component type by prefix
  const txtType = [RES, CAP, IND, JUMPER, FERRITE, DIODE, TRANSISTOR, COMP_REPEATER, CONNECTOR, LOAD, DIE, IC];
  const txtItem = txtType.map(txt => (COMP_PREFIX_LIB && COMP_PREFIX_LIB[txt] && COMP_PREFIX_LIB[txt].length > 0 ? COMP_PREFIX_LIB[txt].join('|') : null))

  for (let i = 0; i < txtItem.length; i++) {
    const txt = txtItem[i], _txtType = txtType[i];

    if (txt) {
      let regObj = new RegExp(`^(${txt})`, 'ig');
      const words = name.match(regObj);
      if (words && words.length > 0) {
        if (type !== IGNORE) {
          let oldSimilar = 0, newSimilar = 0;
          const oldItem = COMP_PREFIX_LIB[type] || [];
          const newItem = COMP_PREFIX_LIB[_txtType] || [];
          oldItem.forEach(item => {
            const similar = getArraySimilar(compName.toUpperCase(), item.toUpperCase());
            oldSimilar = oldSimilar > similar ? oldSimilar : similar;
          });
          newItem.forEach(item => {
            const similar = getArraySimilar(compName.toUpperCase(), item.toUpperCase());
            newSimilar = newSimilar > similar ? newSimilar : similar;
          });
          type = oldSimilar > newSimilar ? type : _txtType === LOAD ? IGNORE : _txtType;
        } else {
          type = _txtType === LOAD ? IGNORE : _txtType;
        }
      }
    }
  }

  return type;
}

function getCompPinSize(compObj, designId, returnNum, originalValue) {
  if (!compObj || !compObj.name) {
    return "";
  }

  const component = auroraDBJson.getComponent(designId, compObj.name);
  if (!component) {
    return "";
  }
  const symbolName = component.symbol;
  const symbol = auroraDBJson.getSymbol(designId, symbolName);
  if (!symbol) {
    return "";
  }

  let unit = auroraDBJson.getUnit(designId);
  if (unit === "mils") {
    unit = "mil";
  }
  const scale = unitChange({ num: 1, oldUnit: unit, newUnit: 'um' }).number;

  const pinShape = symbol.template.values().next().value;
  let pinSize = 0;
  if (pinShape && pinShape.geometry === POLYGEN) {
    //mLocation : {solid:“Y/N”, ccw:"Y/N"，points: [] }
    const Xs = pinShape.points.map(p => p.x ? Number(p.x) : null).filter(x => !!x);
    const Ys = pinShape.points.map(p => p.y ? Number(p.y) : null).filter(y => !!y);
    pinSize = getPolygonPinSize({ Xs, Ys });
  } else if (pinShape && pinShape.geometry === CIRCLE && pinShape.diameter) {
    //mLocation : {diameter:"", x:"", y:"" }
    pinSize = Number(pinShape.diameter);
  } else if ((pinShape && pinShape.geometry === RECT)) {
    pinSize = Math.min(Number(pinShape.width), Number(pinShape.height))
  }

  if (pinSize && pinSize > 0) {
    pinSize = originalValue ? pinSize * scale : pinSize * scale * (3 / 4);
    if (pinSize < 10) {
      pinSize = pinSize > 0.001 ? pinSize.toPrecision(2) : pinSize.toExponential(2);
    } else {
      pinSize = pinSize.toFixed(0);
    }
    return returnNum ? pinSize : `${pinSize}um`;
  }
  return "";
}

function getPMIC(compPMIC = {}) {
  const PMIC = [];
  const { linearSwitch = [], linearRegulator = [], switchingRegulator = [], specialized = [] } = compPMIC;
  if (compPMIC) {
    //LINEAR, SWITCHING, VOLTAGE
    PMIC.push(...linearSwitch.map(item => ({ type: LINEAR_SWITCH, partName: item })))
    PMIC.push(...linearRegulator.map(item => ({ type: LINEAR, partName: item })))
    PMIC.push(...switchingRegulator.map(item => ({ type: SWITCHING, partName: item })))
    PMIC.push(...specialized.map(item => ({ type: SPECIALIZED, partName: item })))
  }
  return PMIC
}

function getNearbyComponent({
  designId,
  target,
  components = [],
  targetNets = [],
  nets = [],
  sameLayer = false,
  returnList = false
}) {
  let gnd = null;
  const pwrInfo = auroraDBJson.getComponent(designId, target);
  const referenceComps = components.length ? auroraDBJson.getComponentsByNames(designId, components) : [...auroraDBJson.getComponents(designId).values()];
  const { location, layer } = pwrInfo;
  const { X: pwrX, Y: pwrY } = location;
  const pwrLocation = targetNets.length ? auroraDBJson.getComponentPins(designId, target).filter(pin => targetNets.includes(pin.net)) : [{ x: pwrX, y: pwrY }];

  let minLocation = Infinity, minXLength = Infinity, minYLength = Infinity, length = Infinity, minLayer = null, nearbyList = [];
  for (let { x, y } of pwrLocation) {
    for (let refe of referenceComps) {
      if (sameLayer && layer !== refe.layer && !returnList) {
        continue;
      }
      const { location, name } = refe;
      const { X: gndX, Y: gndY } = location;
      const gndLocation = nets.length ? auroraDBJson.getComponentPins(designId, name).filter(pin => nets.includes(pin.net)) : [{ x: gndX, y: gndY }];
      for (let { x: gx, y: gy } of gndLocation) {
        const _XLength = Math.abs(Number(x) - Number(gx)), _YLength = Math.abs(Number(y) - Number(gy));
        if (_YLength > minYLength && _XLength > minXLength) {
          if (returnList) {
            length = _XLength * _XLength + _YLength * _YLength;
            nearbyList.push({ name, layer: refe.layer, distance: length })
          }
          continue;
        } else {
          length = _XLength * _XLength + _YLength * _YLength;
          nearbyList.push({ name, layer: refe.layer, distance: length })
          if (length < minLocation) {
            minXLength = _XLength;
            minYLength = _YLength;
            minLocation = length;
            gnd = refe.name;
            minLayer = refe.layer
          } else if (length === minLocation) {
            if (layer === refe.layer && minLayer !== refe.layer) {
              minXLength = _XLength;
              minYLength = _YLength;
              minLocation = length;
              gnd = refe.name;
              minLayer = refe.layer
            }
          } else {
            continue
          }
        }
      }
    }
  }

  if (!returnList) {
    return gnd;
  }

  nearbyList = nearbyList.sort((a, b) => a.distance - b.distance)
  if (sameLayer) {
    nearbyList = nearbyList.sort((a, b) => a.layer === layer && b.layer !== layer ? -1 : 1)
  }
  return [...nearbyList.map(item => item.name)]
}

export {
  getCompTypeByPrefixLib,
  getCompPinSize,
  getPMIC,
  getNearbyComponent
}