import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { DownloadOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Checkbox, Spin, Divider, Menu, Tooltip, message } from 'antd';
import Parameters from '@/services/Andes/results/parameter';
import { db } from '@/services/helper/sparameter/utility';
import { convertArrayOfObjectsToCSV, generateCSVData, getAndesResultHistoryList, getVerificationJson, delResultHistory } from '@/services/Andes/results/result';
import { getSnpFileInAndes } from '@/services/api/Andes';
import History from './historySparameter';
import { getResultList } from '../../store/result/action';
import HistoryData from '@/services/Andes/results/historyJsonData';
import getIndex from '@/services/helper/insertionSearch';
import { strDelimited } from '@/services/helper/split';
import { downloadFile } from '@/services/helper/downloadHelper'
import SparameterSetting from '@/services/Result/Public/sparameter/SparameterSetting';
import ResultLayout from '@/services/Result/Public/resultLayout';
import { axisFormat } from '@/services/Result/Public/sparameter/dataHelper';
import './new.css';

let historyData = null;
const CheckboxGroup = Checkbox.Group;

function getPCBList(arr) {
  return arr.map(d => ({ pcb: d.pcb, pcbId: d.pcbId }));
}

function getRemoveCurves(portSelect) {
  let removeCurves = [];
  let select = Object.keys(portSelect);
  select.forEach(item => {
    portSelect[item].forEach(port => {
      const [_fileIndex, row, col] = strDelimited(port, "::");
      removeCurves.push({ row, col, id: _fileIndex });
    })
  });
  return removeCurves;
}
class SParameter extends Component {
  ExistResult = 0;
  currentInterfaces = null;
  constructor(props) {
    super(props);
    this.uploadSP = createRef();
    this.svgRef = createRef();
    this.settingRef = createRef();
    this.uploadSnpRef = createRef();
    this.state = {
      files: [],
      settingAction: '',
      curveSettingAct: '',
      portSelect: {},
      selectedTags: null,
      loading: false,
      fileListBottom: 318,
      resultShow: true,
      displayMode: 'Default',
      basicFiles: [],
      showDiffToComm: false,
      showReturnLoss: false,
      showInsertionLoss: false,
      showNearEndCrossTalk: false,
      showFarEndCrossTalk: false,
      historyShow: false,
      saveInputVisible: false,
      resultName: '',
      historyList: []
    }
    this.plot2d = null;
    this.resize = this.resize.bind(this);
  }

  onRef = (ref) => {
    this.sparameterSetting = ref;
  }

  _getIndex(xPoints, freq) {
    return getIndex(xPoints, freq, 0, xPoints.length - 1);
  }

  changeMouse = (frequence) => {
    let { files, portSelect } = this.state;
    const setting = this.getSetting();
    const { parameter, value } = setting;
    const list = Object.values(portSelect);
    for (const ports of list) {
      for (const port of ports) {
        const [fileId, row, col] = strDelimited(port, "::");
        let fileIndex = files.findIndex(item => item.id === fileId);
        if ((!fileIndex && fileIndex !== 0) || !row || !col) {
          continue;
        }
        const index = this._getIndex(files[fileIndex].freq, frequence);
        // When the mouse moves, the value of y is displayed.
        if (index > -1) {
          const fileData = files[fileIndex].matrix[row][col][parameter + value];
          if (fileData && fileData[index]) {
            if (parseInt(fileData[index]) < 1) {
              fileData[index] = parseFloat(fileData[index].toPrecision(3));
            }

            if (parseInt(fileData[index]) < 100 && parseInt(fileData[index]) >= 1) {
              fileData[index] = parseFloat(fileData[index].toFixed(2));
            }

            if (parseInt(fileData[index]) < 1000 && parseInt(fileData[index]) >= 100) {
              fileData[index] = parseFloat(fileData[index].toPrecision(4));
            }

            if (parseInt(fileData[index]) > 1000) {
              fileData[index] = parseInt(fileData[index]);
            }
            files[fileIndex].matrix[row][col].current = fileData[index];
          } else {
            files[fileIndex].matrix[row][col].current = '';
          }
        } else {
          files[fileIndex].matrix[row][col].current = '';
        }
      }
    }
    this.setState({
      files
    });
  }

  cancelMove = () => {
    let { files, portSelect } = this.state;
    const list = Object.values(portSelect);
    for (const ports of list) {
      for (const port of ports) {
        const [file, row, col] = strDelimited(port, "::");
        let fileIndex = files.findIndex(item => item.id === file);
        files[fileIndex].matrix[row][col].current = '';
      }
    }
    this.setState({
      files
    })
  }

  getXUnit = () => {
    return this.sparameterSetting.getXUnit();
  }

  changeAxis = (axis) => {
    const xUnit = this.getXUnit();
    this.sparameterSetting.setAxis(axisFormat(xUnit, axis));
  }

  componentDidUpdate(prevProps) {
    const { updatedVerificationID, currentResultKey, saveHistoryCount, currentResult } = this.props;
    // - Updated the interface list
    if (updatedVerificationID !== prevProps.updatedVerificationID ||
      currentResultKey !== prevProps.currentResultKey ||
      saveHistoryCount !== prevProps.saveHistoryCount ||
      currentResult !== prevProps.currentResult) {
      this.changeResult();
    }

    const { fileListBottom } = this.state;
    if (this.settingRef.current && fileListBottom !== this.settingRef.current.offsetHeight) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }

  findResult = (currentResultKey, basicFiles, res, time) => {
    try {
      if (!this.currentInterfaces) {
        throw new Error('Can\'t get interfaces!')
      }
      switch (currentResultKey) {
        case 'returnLoss': this.findReturnLoss(basicFiles, res, time); break;
        case 'insertionLoss': this.findInsertionLoss(basicFiles, res, time); break;
        case 'diffToComm': this.findDiffToComm(basicFiles, res, time); break;
        case 'nearEndCrossTalk': this.findNEXT(basicFiles, res, time); break;
        case 'farEndCrossTalk': this.findFEXT(basicFiles, res, time); break;
        default: break;
      }
    } catch (error) {
      console.error(error);
      if (this.ExistResult === 0) {
        message.error('No Result!')
        this.ExistResult++;
      }
      this.setState({
        loading: false,
      })
    }
  }

  componentDidMount() {
    this.changeResult();
    if (this.settingRef.current) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }

  changeResult = () => {
    this.currentInterfaces = null;
    const events = {
      changeMouse: this.changeMouse,
      cancelMove: this.cancelMove,
      changeAxis: this.changeAxis,
    }
    Parameters.cleanParameters();
    this.setState({
      loading: true,
      portSelect: {},
      historyList: []
    }, async () => {
      const { updatedVerificationID, projectId, currentResult } = this.props;
      if (!currentResult) return;
      const currentInterfaces = await getVerificationJson(updatedVerificationID, projectId);
      try {
        if (!currentInterfaces || !currentInterfaces.Interfaces) {
          throw new Error('Can\'t get interfaces');
        }
        this.currentInterfaces = currentInterfaces.Interfaces.map(d => ({ ...d, verificationId: updatedVerificationID }));
        let pcbList = getPCBList(this.currentInterfaces);
        const { parameter, value } = this.getSetting();
        this.plot2d = Parameters.createPlot(this.svgRef.current, { param: parameter, value }, events);
        Parameters.getParametersInfo({ verificationId: updatedVerificationID, projectId, pcbList, fileType: 'result', time: currentResult }).then(params => {
          const parametersArr = Array.from(params.values());
          if (parametersArr.length > 0) {
            let files = Array.from(params.values()).map(item => ({ ...item, show: true }));
            this.getAllNpi(files).then(res => {
              files = [...res.map(item => ({ ...item, show: true }))];
              this.setState({
                basicFiles: files,
                resultName: currentResult
              })
              const { currentResultKey } = this.props;
              this.findResult(currentResultKey, files);
              this.getHistoryFileList()
            }, error => {
              this.getHistoryFileList()
              console.error(error);
              throw new Error('Get npi files failed');
            })
          } else {
            throw new Error('Get parameters failed');
          }
        })
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          files: [],
          resultName: currentResult
        });
      }
      this.screenChange();
    })
  }

  getHistoryFileList = async () => {
    const { updatedVerificationID, projectId } = this.props;
    historyData = new HistoryData(updatedVerificationID);
    const historyList = await getAndesResultHistoryList(updatedVerificationID);
    this.setState({
      historyList
    })
    historyList.forEach(history => {
      historyData.getContent({ projectId, verificationId: updatedVerificationID, time: history.time }).then(res => {
        if (res && res.length) {
          const pcbList = getPCBList(res)
          Parameters.getParametersInfo({ verificationId: updatedVerificationID, projectId, pcbList, fileType: 'history', time: history.time }).then(params => {
            const parametersArr = Array.from(params.values());
            if (parametersArr.length > 0) {
              let files = Array.from(params.values()).filter(item => item.time === history.time);
              files = files.map(item => ({ ...item, show: true }));
              this.getAllHistoryNpi(files).then(response => {
                const filter = response.filter(item => !!item);
                let newFiles = [...this.state.basicFiles, ...filter.map(item => ({ ...item, show: false }))]
                let _files = [];
                newFiles.forEach(item => {
                  let index = _files.findIndex(i => i.id === item.id);
                  if (index === -1) {
                    _files.push(item);
                  }
                })
                this.setState({
                  basicFiles: _files
                })
                const { currentResultKey } = this.props;
                this.findResult(currentResultKey, _files, res, history.time);
              }, error => {
                let newFiles = [...this.state.basicFiles, ...files.map(item => ({ ...item, show: true }))]
                let _files = [];
                newFiles.forEach(item => {
                  let index = _files.findIndex(i => i.id === item.id);
                  if (index === -1) {
                    _files.push(item);
                  }
                })
                this.setState({
                  basicFiles: _files,
                  loading: false
                })
              })
            } else {
              this.setState({
                loading: false,
                basicFiles: [...this.state.basicFiles],
              });
            }
          })
        }
      })
    })
  }

  getAllNpi = (files) => {
    const promiseList = files.map((item, index) => {
      return item.getNpiFile(index);
    })
    return Promise.all(promiseList);
  }

  getAllHistoryNpi = (files) => {
    const promiseList = files.map((item, index) => {
      return item.getHistoryNpiFile(index);
    })
    return Promise.all(promiseList);
  }

  getSingleNpi = (file) => {
    const { files } = this.state;
    const promiseList = file.map((item, index) => {
      return item.getNpiFile(files.length);
    })
    return Promise.all(promiseList);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  screenChange = () => {
    window.addEventListener('resize', this.resize);
  }

  resize() {
    this.changeWidthCB();
    if (this.settingRef.current) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }

  downloadSingleFile = (e) => {
    e.stopPropagation();
    let filename;
    const { files } = this.state;
    if (!files || files.length === 0) {
      return;
    }
    let file = files[0];
    const { updatedVerificationID, interfaceName } = this.props;
    getSnpFileInAndes(updatedVerificationID, file.pcbId).then(res => {
      let content = ''
      if (res) {
        content = res.data;
      }
      const Suffix = file.ports.length;
      filename = `${file.pcb}_${interfaceName}.s${Suffix}p`;
      downloadFile(content, filename)
    }, error => {
      console.error(error);
      let content = ''
      const Suffix = file.ports.length;
      filename = `${file.pcb}_${interfaceName}.s${Suffix}p`;
      downloadFile(content, filename)
    });
  }

  resultTitleClick = () => {
    const { resultShow } = this.state;
    this.setState({
      resultShow: !resultShow
    });
  }

  historyTitleClick = () => {
    const { historyShow } = this.state;
    this.setState({
      historyShow: !historyShow
    });
  }

  findReturnLoss = (files, historyInterfaces, time) => {
    const { portSelect } = this.state;
    let newInterfaces = [...this.currentInterfaces];
    let fileType = 'result';
    if (historyInterfaces) {
      newInterfaces = [...historyInterfaces];
      fileType = 'history';
    }
    let _files = files, _portSelect = portSelect;
    newInterfaces.forEach(_interface => {
      const pcbId = _interface.pcbId;
      let fileIndex = -1;
      if (time) {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType && time === file.time);
      } else {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType);
      }
      if (fileIndex < 0) {
        return;
      }
      const findFile = files[fileIndex];
      let netList = [];
      let diffPorts = findFile.ports.filter(item => item.portType === 'Diff');
      let signalNames = [...new Set(diffPorts.map(item => item.signal))];
      signalNames.forEach(signal => {
        let currentSignalPorts = diffPorts.filter(item => item.signal === signal);
        let portsInfo = [];
        currentSignalPorts.forEach(item => {
          portsInfo.push({
            comp: item.comp,
            port: item.index,
            compType: item.compType
          })
        })
        netList.push({
          signal,
          ports: currentSignalPorts.map(item => item.index),
          portsInfo,
          show: true
        })
      })
      _files[fileIndex].netList = netList;
      findFile.matrix.forEach((matrix, index) => {
        matrix.forEach((_matrix, _index) => {
          if (_index === index) {
            _matrix.display = true;
          } else {
            _matrix.display = false;
          }
        });
      });
    });
    if (fileType === 'result') {
      Parameters.removeCurves(getRemoveCurves(_portSelect));
      _portSelect = {}
    }
    _files.forEach(file => {
      file.matrix.forEach((matrix, index) => {
        matrix.forEach((_matrix, _index) => {
          if (_index === index) {
            _matrix.display = true;
          } else {
            _matrix.display = false;
          }
        });
      });
    });
    this.setState({
      files: _files,
      loading: false,
      portSelect: _portSelect,
      showDiffToComm: false,
      showReturnLoss: true,
      showInsertionLoss: false,
      showNearEndCrossTalk: false,
      showFarEndCrossTalk: false,
    }, () => {
      if (fileType === 'result') {
        this.changePort([_files[0].id + '::0::0'], _files[0].id);
      }
    });
  }

  findInsertionLoss = (files, historyInterfaces, time) => {
    const { portSelect } = this.state;
    let _files = files, _portSelect = portSelect;
    let newInterfaces = [...this.currentInterfaces];
    let fileType = 'result';
    if (historyInterfaces) {
      newInterfaces = [...historyInterfaces];
      fileType = 'history';
    }
    newInterfaces.forEach(_interface => {
      const pcbId = _interface.pcbId;
      let fileIndex = -1;
      if (time) {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType && time === file.time);
      } else {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType);
      }

      if (fileIndex < 0) {
        return;
      }
      const findFile = files[fileIndex];
      let netList = [];
      let diffPorts = findFile.ports.filter(item => item.portType === 'Diff');
      let signalNames = [...new Set(diffPorts.map(item => item.signal))];
      signalNames.forEach(signal => {
        let currentSignalPorts = diffPorts.filter(item => item.signal === signal);
        let portsInfo = [];
        currentSignalPorts.forEach(item => {
          portsInfo.push({
            comp: item.comp,
            port: item.index,
            compType: item.compType
          })
        })
        netList.push({
          signal,
          ports: currentSignalPorts.map(item => item.index),
          portsInfo,
          show: true
        })
      })
      _files[fileIndex].netList = netList;
    });
    if (fileType === 'result') {
      Parameters.removeCurves(getRemoveCurves(_portSelect));
      _portSelect = {}
    }
    _files.forEach(file => {
      file.matrix.forEach((matrix, index) => {
        matrix.forEach((_matrix, _index) => {
          if (_index === index) {
            _matrix.display = false;
          } else {
            _matrix.display = true;
          }
        });
      });
    });
    this.setState({
      files: _files,
      loading: false,
      portSelect: _portSelect,
      showDiffToComm: false,
      showReturnLoss: false,
      showInsertionLoss: true,
      showNearEndCrossTalk: false,
      showFarEndCrossTalk: false,
    }, () => {
    });
  }

  findDiffToComm = (files, historyInterfaces, time) => {
    const { portSelect } = this.state;
    let _files = files, _portSelect = portSelect;
    let newInterfaces = [...this.currentInterfaces];
    let fileType = 'result';
    if (historyInterfaces) {
      newInterfaces = [...historyInterfaces];
      fileType = 'history';
    }
    newInterfaces.forEach(_interface => {
      const pcbId = _interface.pcbId;
      let fileIndex = -1;
      if (time) {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType && time === file.time);
      } else {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType);
      }

      if (fileIndex < 0) {
        return;
      }
      const findFile = files[fileIndex];
      let netList = [];
      let signalNames = [...new Set(findFile.ports.map(item => item.signal))];
      signalNames.forEach(signal => {
        let currentSignalPorts = findFile.ports.filter(item => item.signal === signal);
        let portsInfo = [];
        currentSignalPorts.forEach(item => {
          portsInfo.push({
            comp: item.comp,
            port: item.index,
            compType: item.compType,
            portType: item.portType
          })
        })
        netList.push({
          signal,
          ports: currentSignalPorts.map(item => item.index),
          portsInfo,
          show: true
        })
      })
      _files[fileIndex].netList = netList;
    });

    if (fileType === 'result') {
      Parameters.removeCurves(getRemoveCurves(_portSelect));
      _portSelect = {}
    }
    _files.forEach(file => {
      let portList = []
      file.netList.forEach((signal, index) => {
        let diffPorts = signal.portsInfo.filter(item => item.portType === 'Diff');
        let commPorts = signal.portsInfo.filter(item => item.portType === 'Comm');
        diffPorts.forEach((port, _index) => {
          commPorts.forEach(item => {
            if (item.compType !== port.compType) {
              portList.push({ diff: port.port - 1, comm: item.port - 1 })
            }
          })
        })
        file.matrix.forEach((matrix, matrixIndex) => {
          matrix.forEach((_matrix, _matrixIndex) => {
            let find = portList.find(item => item.diff === matrixIndex && item.comm === _matrixIndex);
            if (find) {
              _matrix.display = true;
            } else {
              _matrix.display = false;
            }
          });
        });
      });
    });
    this.setState({
      files: _files,
      loading: false,
      portSelect: _portSelect,
      showDiffToComm: true,
      showReturnLoss: false,
      showInsertionLoss: false,
      showNearEndCrossTalk: false,
      showFarEndCrossTalk: false,
    });
  }

  findFEXT = (files, historyInterfaces, time) => {
    const { portSelect } = this.state;
    let _files = files, _portSelect = portSelect;
    let newInterfaces = [...this.currentInterfaces];
    let fileType = 'result';
    if (historyInterfaces) {
      newInterfaces = [...historyInterfaces];
      fileType = 'history';
    }
    newInterfaces.forEach(_interface => {
      const pcbId = _interface.pcbId;
      let fileIndex = -1;
      if (time) {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType && time === file.time);
      } else {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType);
      }

      if (fileIndex < 0) {
        return;
      }
      const findFile = files[fileIndex];
      let netList = [];
      let signalNames = [...new Set(findFile.ports.map(item => item.signal))];
      let diffPorts = findFile.ports.filter(item => item.portType === 'Diff');
      signalNames.forEach(signal => {
        let currentSignalPorts = diffPorts.filter(item => item.signal === signal);
        let portsInfo = [];
        currentSignalPorts.forEach(item => {
          portsInfo.push({
            comp: item.comp,
            port: item.index,
            compType: item.compType,
            portType: item.portType
          })
        })
        netList.push({
          signal,
          ports: currentSignalPorts.map(item => item.index),
          portsInfo,
          show: true
        })
      })
      _files[fileIndex].netList = netList;
    });

    if (fileType === 'result') {
      Parameters.removeCurves(getRemoveCurves(_portSelect));
      _portSelect = {}
    }
    _files.forEach(file => {
      let portList = [];
      file.netList.forEach((info) => {
        let otherSignalNetList = file.netList.filter(item => item.signal !== info.signal);
        info.portsInfo.forEach(infoPort => {
          otherSignalNetList.forEach(item => {
            item.portsInfo.forEach(port => {
              if (infoPort.compType !== port.compType) {
                portList.push({ signal1: infoPort.port - 1, signal2: port.port - 1 })
              }
            })
          })
        })
        file.matrix.forEach((matrix, matrixIndex) => {
          matrix.forEach((_matrix, _matrixIndex) => {
            let find = portList.find(item => item.signal1 === matrixIndex && item.signal2 === _matrixIndex);
            if (find) {
              _matrix.display = true;
            } else {
              _matrix.display = false;
            }
          });
        });
      });
    });
    this.setState({
      files: _files,
      loading: false,
      portSelect: _portSelect,
      showInsertionLoss: false,
      showReturnLoss: false,
      showDiffToComm: false,
      showNearEndCrossTalk: false,
      showFarEndCrossTalk: true,
    });
  }

  findNEXT = (files, historyInterfaces, time) => {
    const { portSelect } = this.state;
    let _files = files, _portSelect = portSelect;
    let newInterfaces = [...this.currentInterfaces];
    let fileType = 'result';
    if (historyInterfaces) {
      newInterfaces = [...historyInterfaces];
      fileType = 'history';
    }
    newInterfaces.forEach(_interface => {
      const pcbId = _interface.pcbId;
      let fileIndex = -1;
      if (time) {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType && time === file.time);
      } else {
        fileIndex = files.findIndex(file => file.pcbId === pcbId && fileType === file.fileType);
      }

      if (fileIndex < 0) {
        return;
      }
      const findFile = files[fileIndex];
      let netList = [];
      let signalNames = [...new Set(findFile.ports.map(item => item.signal))];
      let diffPorts = findFile.ports.filter(item => item.portType === 'Diff');
      signalNames.forEach(signal => {
        let currentSignalPorts = diffPorts.filter(item => item.signal === signal);
        let portsInfo = [];
        currentSignalPorts.forEach(item => {
          portsInfo.push({
            comp: item.comp,
            port: item.index,
            compType: item.compType,
            portType: item.portType
          })
        })
        netList.push({
          signal,
          ports: currentSignalPorts.map(item => item.index),
          portsInfo,
          show: true
        })
      })
      _files[fileIndex].netList = netList;
    });

    if (fileType === 'result') {
      Parameters.removeCurves(getRemoveCurves(_portSelect));
      _portSelect = {}
    }
    _files.forEach(file => {
      let portList = [];
      file.netList.forEach((info) => {
        let otherSignalNetList = file.netList.filter(item => item.signal !== info.signal);
        info.portsInfo.forEach(infoPort => {
          otherSignalNetList.forEach(item => {
            item.portsInfo.forEach(port => {
              if (infoPort.compType === port.compType) {
                portList.push({ signal1: infoPort.port - 1, signal2: port.port - 1 })
              }
            })
          })
        })
        file.matrix.forEach((matrix, matrixIndex) => {
          matrix.forEach((_matrix, _matrixIndex) => {
            let find = portList.find(item => item.signal1 === matrixIndex && item.signal2 === _matrixIndex);
            if (find) {
              _matrix.display = true;
            } else {
              _matrix.display = false;
            }
          });
        });
      });
    });
    this.setState({
      files: _files,
      loading: false,
      portSelect: _portSelect,
      showInsertionLoss: false,
      showReturnLoss: false,
      showDiffToComm: false,
      showNearEndCrossTalk: true,
      showFarEndCrossTalk: false,
    });
  }

  netNameClick = (file, net, signalIndex) => {
    let { files } = this.state;
    const fileIndex = files.findIndex(item => item.id === file.id);
    let netList = files[fileIndex].netList[signalIndex].netList;
    const netIndex = netList.findIndex(item => item.net === net);
    netList[netIndex].show = !netList[netIndex].show;
    files[fileIndex].netList[signalIndex].netList = netList;
    this.setState({
      files: files
    })
  }

  signalNameClick = (file, signalIndex) => {
    let { files } = this.state;
    const fileIndex = files.findIndex(item => item.id === file.id);
    files[fileIndex].netList[signalIndex].show = !files[fileIndex].netList[signalIndex].show;
    this.setState({
      files: files
    })
  }

  portNameClick = (file, portIndex) => {
    let { files } = this.state;
    const fileIndex = files.findIndex(item => item.id === file.id);
    files[fileIndex].ports[portIndex].display = !files[fileIndex].ports[portIndex].display;
    this.setState({
      files: files
    })
  }

  crossTalk = (file) => {
    const { portSelect } = this.state;
    return (
      <CheckboxGroup
        value={portSelect[file.id]}
        key={file.id}
        onChange={(values) => this.changePort(values, file.id)}
        style={{ width: "100%" }}
      >
        {file.netList && file.netList.map((signalInfo, signalIndex) => {
          let otherList = file.netList.filter(i => i.signal !== signalInfo.signal)
          return (
            <Fragment key={signalInfo.signal}>
              <div>
                <div
                  style={{ display: file.netList.length > 1 ? 'block' : 'none' }}
                  onClick={() => this.signalNameClick(file, signalIndex)}
                  className='andes-channel-port-name andes-channel-port-name-general'
                >
                  {
                    signalInfo.show ? <DownOutlined className='andes-file-expand-icon' /> : <RightOutlined className='andes-file-expand-icon' />
                  }
                  <span>{signalInfo.signal}</span>
                </div>
                <div style={{ display: signalInfo.show ? 'block' : 'none' }}>
                  <Row style={{ display: signalInfo.show ? 'block' : 'none' }}>
                    {signalInfo.ports && signalInfo.ports.length && signalInfo.ports.map(portNumber => {
                      const portIndex = portNumber - 1;
                      const port = file.ports[portIndex];
                      return <Fragment key={port.name}>
                        {otherList.map(oSignalInfo => {
                          return oSignalInfo.ports.map(matrix => {
                            const matrixIndex = matrix - 1;
                            const _matrix = file.matrix[portIndex][matrixIndex];
                            return _matrix.display && <Checkbox
                              key={`${file.id}::${portIndex}::${matrixIndex}`}
                              value={`${file.id}::${portIndex}::${matrixIndex}`}
                              className='andes-result-checkbox-group'
                            >
                              <span className='andes-result-checkbox-group-name'>
                                <input
                                  type='color'
                                  className="color-select"
                                  value={_matrix.color}
                                  style={{ verticalAlign: 'middle' }}
                                  onChange={(e) => this.colorChange(e, { id: file.id, portIndex, matrixIndex })}
                                  id={`col::${file.id}::${portIndex}::${matrixIndex}`}
                                />
                                <label htmlFor={`${portIndex}::${matrixIndex}`} className='andes-result-checkbox-label'>
                                  {`S(${portNumber}, ${matrix}) (${port.signal}_${port.compType}_${port.comp}, ${file.ports[matrixIndex].signal}_${file.ports[matrixIndex].compType}_${file.ports[matrixIndex].comp})`}
                                </label>
                              </span>
                              <span className='andes-result-curve-current' style={{ color: _matrix.color }}>{_matrix.current}</span>
                            </Checkbox>
                          })
                        })}
                      </Fragment>
                    })
                    }
                  </Row>
                </div>
              </div>
            </Fragment>
          );
        })}
      </CheckboxGroup>
    );
  }

  downloadCSV = (e, file, time) => {
    e.stopPropagation();
    /*  e.domEvent.stopPropagation() */
    let filename;
    const { currentResultKey, interfaceName, updatedVerificationID } = this.props;
    generateCSVData(file, updatedVerificationID, currentResultKey, time).then(res => {
      let freq = file.freq;
      let csvData = freq.map((dataItem, index) => {
        let outputData = {
          frequency: dataItem || "",
        }
        res.forEach(item => {
          outputData[`(am)${item.name}`] = item.dbCurve[index] || "";
          outputData[`(db)${item.name}`] = db(item.dbCurve[index]) || "";
          outputData[`(ph)${item.name}`] = item.phCurve[index] || "";
        })
        return outputData;
      })
      let csv = convertArrayOfObjectsToCSV({
        data: csvData,
        pcb: file.pcb,
        interface: interfaceName,
        currentResultKey
      });
      if (csv === null) return;

      filename = `${interfaceName}_${currentResultKey}.csv`;

      csv = '#' + csv;

      let type = 'text/csv;charset=utf-8;'
      downloadFile(csv, filename, type)
    })
  }

  downloadMenu = (file) => {
    // return <Menu key="export">
    //   <MenuItem onClick={(e) => this.downloadSingleFile(e, file)} key='export_single_sparameter'>Export Single SParameter</MenuItem>
    //   <MenuItem onClick={(e) => this.downloadCSV(e, file)} key='export_csv'>Export CSV</MenuItem>
    // </Menu>
  }

  onClick = (e) => {
    e.stopPropagation()
  }

  historyItemTitleClick = (e, name, time) => {
    e.stopPropagation();
    const { historyList } = this.state;
    let list = [...historyList];
    const index = list.findIndex(item => item.time === time && item.name === name);
    list[index].show = !list[index].show;
    this.setState({
      historyList: list
    })
  }

  deleteHistory = async (e, time) => {
    e.stopPropagation();
    const { updatedVerificationID } = this.props;
    await delResultHistory(updatedVerificationID, time);
    this.changeResult();
  }

  getSetting = () => {
    return this.sparameterSetting.getSetting();
  }

  getAxis = () => {
    return this.sparameterSetting.getAxis();
  }

  settingChangeCB = () => {
    const { files } = this.state;
    const axis = this.getAxis();
    const setting = this.getSetting();
    Parameters.changeParameter({ parameters: files, setting, yScale: axis.yScale, xScale: axis.xScale });
  }

  changeFileListBottom = () => {
    if (this.settingRef.current) {
      this.setState({
        fileListBottom: this.settingRef.current.offsetHeight
      })
    }
  }

  axisChangeCB = (axis, value) => {
    const setting = this.getSetting();
    Parameters.changeAxis(axis, { param: setting.parameter, value: setting.value, scale: value })
  }

  updateRangeCB = (update, min, max) => {
    if (update === 'x') {
      Parameters.updateRange('x', { start: min, end: max })
    } else {
      const axis = this.getAxis();
      Parameters.updateRange('y', { start: axis['yMin'], end: axis['yMax'], yScale: axis['yScale'] })
    }
  }

  resultLeft = () => {
    const { loading } = this.state;
    return (
      <Spin spinning={loading} size='large'>
        <div className='andes-result-parameter-right'>
          <svg ref={this.svgRef}></svg>
        </div>
      </Spin>
    )
  }

  resultRight = () => {
    const { files, portSelect, fileListBottom, resultShow,
      showInsertionLoss, showReturnLoss, showDiffToComm, showNearEndCrossTalk, showFarEndCrossTalk, historyShow, historyList } = this.state;
    const { currentResultKey } = this.props;
    let width = null;
    if (showNearEndCrossTalk || showFarEndCrossTalk) {
      width = 500
    } else if (showDiffToComm || showInsertionLoss) {
      width = 425;
    }
    return (
      <div className='andes-result-parameter-left' >
        <div className='andes-result-parameter-file-list' style={{ bottom: fileListBottom }}>
          {/* Result */}
          <div className='andes-result-default'>
            <Divider orientation="left" style={{ marginTop: 0, marginBottom: 0 }}>
              <div className='andes-result-driver-title' onClick={this.resultTitleClick}>
                {resultShow ? <DownOutlined className="title-expand-icon" /> : <RightOutlined className="title-expand-icon" />}
                <span className='andes-result-menu-title'>Result</span>
              </div>
            </Divider>
            <div className='andes-result-file-content' style={{ display: resultShow ? 'block' : 'none', width: width }}>
              {files.length > 0 ? files.map((file, fileIndex) =>
                file.fileType === 'result' &&
                <div key={file.id}>
                  <Row key={file.id} className='andes-result-file-name'>
                    <Col span={24} onClick={() => this.fileNameClick(file.id)}>
                      {file.show ? <DownOutlined className="andes-file-expand-icon" /> : <RightOutlined className="andes-file-expand-icon" />}
                      <span className='span-file-name'>{file.pcb}</span>
                      {currentResultKey === 'returnLoss' || currentResultKey === 'insertionLoss' || currentResultKey === 'diffToComm' ?
                        <Tooltip title='Export CSV' overlayClassName='aurora-tooltip'>
                          <DownloadOutlined
                            onClick={(e) => this.downloadCSV(e, file)}
                            className="andes-file-download-icon" />
                        </Tooltip>
                        : null}
                    </Col>
                  </Row>
                  <Row
                    key={fileIndex}
                    style={{ display: file.show ? 'block' : 'none' }}
                    className='andes-result-ports-list'
                  >
                    {(showNearEndCrossTalk || showFarEndCrossTalk) ? this.crossTalk(file)
                      : (
                        <CheckboxGroup
                          value={portSelect[file.id]}
                          key={file.id}
                          onChange={(values) => this.changePort(values, file.id)}
                          style={{ width: "100%" }}
                        >
                          {file.netList && file.netList.map((signalInfo, signalIndex) => <Fragment key={signalInfo.signal}>
                            <div>
                              <div
                                style={{ display: file.netList.length > 1 ? 'block' : 'none' }}
                                onClick={() => this.signalNameClick(file, signalIndex)}
                                className='andes-channel-port-name andes-channel-port-name-general'
                              >
                                {signalInfo.show ? <DownOutlined className='andes-file-expand-icon' /> : <RightOutlined className='andes-file-expand-icon' />}
                                <span>{signalInfo.signal}</span>
                              </div>
                              <div style={{ display: signalInfo.show ? 'block' : 'none' }}>
                                <Row style={{ display: signalInfo.show ? 'block' : 'none' }}>
                                  {signalInfo.ports && signalInfo.ports.length && signalInfo.ports.map(portNumber => {
                                    const portIndex = portNumber - 1;
                                    const port = file.ports[portIndex];
                                    return <Fragment key={port.name}>
                                      {signalInfo.ports.map(matrix => {
                                        const matrixIndex = matrix - 1;
                                        const _matrix = file.matrix[portIndex][matrixIndex];
                                        return _matrix.display && <Checkbox
                                          key={`${file.id}::${portIndex}::${matrixIndex}`}
                                          value={`${file.id}::${portIndex}::${matrixIndex}`}
                                          className='andes-result-checkbox-group'
                                        >
                                          <span className='andes-result-checkbox-group-name'>
                                            <input
                                              type='color'
                                              className="color-select"
                                              value={_matrix.color}
                                              style={{ verticalAlign: 'middle' }}
                                              onChange={(e) => this.colorChange(e, { id: file.id, portIndex, matrixIndex })}
                                              id={`col::${file.id}::${portIndex}::${matrixIndex}`}
                                            />
                                            <label htmlFor={`${portIndex}::${matrixIndex}`} className='andes-result-checkbox-label'>
                                              {showReturnLoss ? `S(${portNumber}, ${matrix}) ${port.compType}_${port.comp}`
                                                : null
                                              }
                                              {showInsertionLoss || showDiffToComm ? `S(${portNumber}, ${matrix}) (${port.compType}_${port.comp}, ${file.ports[matrixIndex].compType}_${file.ports[matrixIndex].comp})`
                                                : null
                                              }
                                            </label>
                                          </span>
                                          <span className='andes-result-curve-current' style={{ color: _matrix.color }}>{_matrix.current}</span>
                                        </Checkbox>
                                      })}
                                    </Fragment>
                                  })
                                  }
                                </Row>
                              </div>
                            </div>
                          </Fragment>)}
                        </CheckboxGroup>
                      )}
                  </Row>
                </div>) : null}
            </div>
          </div>
          {/* History */}
          <History
            historyActions={
              {
                historyTitleClick: this.historyTitleClick,
                fileNameClick: this.fileNameClick,
                downloadCSV: this.downloadCSV,
                signalNameClick: this.signalNameClick,
                colorChange: this.colorChange,
                crossTalk: this.crossTalk,
                changePort: this.changePort,
                historyItemTitleClick: this.historyItemTitleClick,
                deleteHistory: this.deleteHistory
              }
            }
            historyData={
              {
                historyShow,
                historyList,
                portSelect,
                files,
                currentResultKey,
                showNearEndCrossTalk,
                showFarEndCrossTalk,
                showReturnLoss,
                showInsertionLoss,
                showDiffToComm,
                width
              }
            }
            portNameClick={this.portNameClick}
          />
        </div>
        <div ref={this.settingRef} className='andes-result-setting'>
          <Row className='parameter-right-toolbar'>
            <Col>
              <SparameterSetting
                onRef={this.onRef}
                portSelectLen={1}
                settingChangeCB={this.settingChangeCB}
                changeFileListBottom={this.changeFileListBottom}
                axisChangeCB={this.axisChangeCB}
                updateRangeCB={this.updateRangeCB}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  changeWidthCB = () => {
    if (this.plot2d) {
      this.plot2d.resetPlot();
      const axis = this.getAxis();
      const setting = this.getSetting();
      Parameters.changeAxis('x', { param: setting.parameter, value: setting.value, scale: axis.xScale });
      Parameters.changeAxis('y', { param: setting.parameter, value: setting.value, scale: axis.yScale });
    }
  }

  render() {

    return (
      <Fragment>
        <Tooltip title='Export Single-ended S-parameter' placement="topRight" overlayClassName='aurora-tooltip'>
          <div className='andes-download-single-file-div'><DownloadOutlined
            onClick={(e) => this.downloadSingleFile(e)}
            className="andes-single-file-download-icon" /></div>
        </Tooltip>
        <div className='andes-returnLoss-result-main'>
          <ResultLayout
            defaultRightWidth={330}
            resultLeft={this.resultLeft}
            resultRight={this.resultRight}
            changeWidthCB={this.changeWidthCB}
          />
        </div>
      </Fragment>
    );
  }

  fileNameClick = (id) => {
    const { files } = this.state;
    let index = files.findIndex(item => item.id === id);
    const parameter = files[index];
    if (parameter.ports && parameter.matrix) {
      this.setState((prevState) => {
        prevState.files[index].show = !prevState.files[index].show;
        return {
          files: prevState.files
        }
      });
      return;
    }
    this.getNpiFile(parameter, index);
  }

  getNpiFile = (parameter, index) => {
    const { interfaceName } = this.props;
    return new Promise((resolve, reject) => {
      parameter.getNpiFile(index, interfaceName).then(res => {
        this.setState((prevState) => {
          prevState.files[index] = { ...prevState.files[index], ...res, show: !prevState.files[index].show };
          return {
            files: prevState.files
          }
        }, () => {
          resolve(this.state.files);
        })
      })
    })
  }

  changePort = (values, id) => {
    let unSelect, newSelect = null;
    const { files } = this.state;
    const setting = this.getSetting();
    let { portSelect } = this.state;
    let pr = portSelect[id] || [];
    unSelect = pr.concat(values).filter(v => !values.includes(v));
    newSelect = values.filter(v => !pr.includes(v));
    let parameter = Parameters.getParameter(id);
    if (unSelect.length > 0) {
      const splits = strDelimited(unSelect[0], "::");
      const row = splits[1], col = splits[2];
      Parameters.removeCurve({ row, col, id });
    } else {
      const [fileId, row, col] = strDelimited(newSelect[0], "::");
      let fileIndex = files.findIndex(item => item.id === fileId);
      Parameters.showCurve({ parameter, fileIndex, row, col, param: setting.parameter, value: setting.value }).then(curves => {
        if (curves) {
          files[fileIndex].matrix[row][col][curves.type] = curves.y;
          curves.visible = true;
          this.plot2d.updateCurves(curves, true);
          const color = files[fileIndex].matrix[row][col].color;
          this.plot2d.resetColor({ id, row, col, color: color });
          this.changeWidthCB();
        }
      });
    };
    portSelect[id] = [...values];

    if (unSelect && unSelect.length > 0 && values.length === 0) {
      delete portSelect[id];
    }
    this.setState({
      portSelect: portSelect
    })
  }

  colorChange = (e, { id, portIndex, matrixIndex }) => {
    const { files } = this.state;
    let fileIndex = files.findIndex(item => item.id === id)
    e.persist();
    if (e.target.value) {
      let { files } = this.state;
      files[fileIndex].matrix[portIndex][matrixIndex].color = e.target.value;
      this.setState({
        files
      }, () => {
        this.plot2d.resetColor({ id, row: portIndex, col: matrixIndex, color: e.target.value })
        this.plot2d.redrawCurves();
      })
    }
  }
}

const mapState = (state) => {
  const { resultReducer: { currentResult, currentResultKey }, project } = state.AndesReducer;
  const { currentProjectId, verificationName, verificationId } = project;
  return {
    interfaceName: verificationName,
    projectId: currentProjectId,
    updatedVerificationID: verificationId,
    currentResult,
    currentResultKey,
  }
}

const mapDispatch = (dispatch) => ({
  getResultList({ currentResult }) {
    dispatch(getResultList({ currentResult }))
  }
})

export default connect(mapState, mapDispatch)(SParameter);