import CeLayoutDB from './CeLayoutDB';
import CeLayerStack from './CeLayerStack';
// import CeInterfaceFactory from './CeInterfaceFactory';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';
import CePartDB from '../partlib/CePartDB';
// import StringList from '../utility/StringList';
// import CeCircle from '../geometry/CeCircle';
// import CeLine from '../geometry/CeLine';
import getDesignFile from '../api/designFile';
import { uploadStackup } from '../api/designFile';
import checkError from '../api/checkError';
import { getDesignInfoPromise } from '../api/Design/design';
import { LAYOUT } from '../../constants/designType';
import * as vendorMap from '@/constants/designVendor';
import { message } from 'antd';

class NewLayoutData {
  constructor() {
    this.info = [];
    this.designID = null;
    this.projectDB = {
      partDB: new CePartDB(),
      layoutDB: new CeLayoutDB(),
      stackup: null,
      boM: null,
      interfaceList: null
    };
  }

  /** Load the data of a metal layer
   *  @param strLayerName - String, name of the metal layer
   */
  getDesignId = () => this.designID;

  getDesignID = (designID) => {
    this.designID = designID;
    return designID;
  }

  cleanLayoutInfo(designID) {
    const index = this.info.findIndex(item => item.designID === designID)
    if (index > -1) {
      this.info.splice(index, 1)
    }
  }

  loadMetalLayer = (layerName) => {
    // Use ASCII instead of '[' or ']'.
    let layer = layerName.replace(/\[/g, '%5B').replace(/\]/g, '%5D');
    const index = this.info.findIndex(item => item.designID === this.designID);
    if (index > -1
      && this.info[index].CePartsData
      && this.info[index].CeLayoutData
      && this.info[index].loadMetalLayerData
      && this.info[index].loadMetalLayerData.find(item => item.layerName === layerName)) {
      const currentData = this.info[index].loadMetalLayerData.find(item => item.layerName === layerName);
      const { layoutDB } = this.projectDB;
      layoutDB.GetLayerManager().ReadMetalLayerData(layerName, currentData.data, layoutDB);
      console.log('success to read metallayer ' + layerName);
    } else {
      return getDesignFile(this.designID, `layers/${layer}.lyr`).then(res => {
        const { layoutDB } = this.projectDB;
        if (layoutDB.GetLayerManager().ReadMetalLayerData(layerName, res.data, layoutDB)) {
          const i = this.info.findIndex(item => item.designID === this.designID);
          if (i > -1) {
            this.info[i].loadMetalLayerData.push({ layerName: layerName, data: res.data });
          }
          console.log('success to load metallayer ' + layerName);
        } else {
          //todo: file parsing error, broadcast error messagemap
          // {
          //   failed: strLayername
          // };
          console.log('failed to load metallayer ' + layerName)
        }
      }, error => {
        if (checkError(error) !== 401) {
          console.error(error);
        }
        //todo: error handler
      });
    }
  };

  LoadLayoutDB = () => {
    // get the part DB file
    // check the design graphics
    const { partDB, layoutDB } = this.projectDB;
    const index = this.info && this.info.findIndex(item => item.designID === this.designID)
    if (index > -1 && this.info[index].CePartsData && this.info[index].CeLayoutData) {
      return new Promise((resolve, reject) => {
        var ceBlock = new CeIODataBlock("CeParts");
        ceBlock.ReadBlockFromFile(this.info[index].CePartsData);
        if (partDB.LoadDB(ceBlock)) {
          /* console.log("Succeed to load part DB."); */
        }
        layoutDB.SetPartDB(partDB);
        /*  console.log("Read layout DB file data."); */
        var ceBlock = new CeIODataBlock("CeLayout");
        ceBlock.ReadBlockFromFile(this.info[index].CeLayoutData);
        if (layoutDB.LoadDB(ceBlock, layoutDB)) {
          /*  console.log("Succeed to load layout DB.") */
          var layerMgr = layoutDB.GetLayerManager();
          var metalLayerNames = layerMgr.GetAllMetalLayers().GetJSArrary();
          resolve(Promise.all(metalLayerNames.map(this.loadMetalLayer)))
        }
      })
    } else {
      return getDesignFile(this.designID, 'parts.db').then(res => {
        console.log("Read part DB file data.");
        var ceBlock = new CeIODataBlock("CeParts");
        ceBlock.ReadBlockFromFile(res.data);
        if (partDB.LoadDB(ceBlock)) {
          for (let i = 0; i < this.info.length; i++) {
            if (this.info[i].designID === this.designID) {
              this.info[i].CePartsData = res.data;
              break;
            }
          }
          console.log("Succeed to load part DB.");
        } else {
          message.error('Translation failed: can not load part DB');
          console.log("Fail to load part DB.");
        }

        // // get the layout DB file
        return getDesignFile(this.designID, 'layout.db').then(res => {
          layoutDB.SetPartDB(partDB);
          console.log("Read layout DB file data.");
          var ceBlock = new CeIODataBlock("CeLayout");
          ceBlock.ReadBlockFromFile(res.data);
          if (layoutDB.LoadDB(ceBlock, layoutDB)) {
            for (let i = 0; i < this.info.length; i++) {
              if (this.info[i].designID === this.designID) {
                this.info[i].CeLayoutData = res.data;
                break;
              }
            }
            console.log("Succeed to load layout DB.")
            var layerMgr = layoutDB.GetLayerManager();
            var metalLayerNames = layerMgr.GetAllMetalLayers().GetJSArrary();
            return Promise.all(metalLayerNames.map(this.loadMetalLayer));
          } else {
            message.error('Translation failed: can not load layout DB')
            console.log("Fail to load layout DB.");
          }
        }, error => {
          //todo
          message.error('Translation failed: can not load layout DB')
          console.log("Fail to load layout DB.");
          console.log(error);
        })
      }, error => {
        //todo
        if (checkError(error) !== 401) {
          console.error(error);
        }
      })
    }

  };

  saveComponentsList = () => {
    const metalLayers = this.getLayout().GetLayerManager().mMetalLayers;
    const COMPS = [];
    for (const layer of metalLayers) {
      const { mComponentLayer } = layer;
      if (!mComponentLayer) continue;
      const { mComponents } = mComponentLayer;
      for (const component of mComponents) {
        COMPS.push({
          name: component.mName,
          part: component.mPart.mInfo.mPartName,
          pin: component.mPart.mPinList.length,
          layer: component.mLayerName,
        })
      }
    }
    this.componentsList = COMPS;
  }

  saveNetsList = () => {
    const netList = this.getLayout().mNetManager.GetNetList().mVals;
    const NETS = [];
    for (const net of netList) {
      const { mName, mPinList, mViaList, mType } = net;
      const components = [], layers = [];
      for (const pin of mPinList) {
        const { mCompName, mLayerName } = pin;
        if (!components.includes(mCompName)) components.push(mCompName);
        if (!layers.includes(mLayerName)) layers.push(mLayerName);
      }
      NETS.push({
        name: mName,
        pin: mPinList.length,
        via: mViaList.length,
        type: mType,
        comp: components.length,
        layer: layers.length
      })
    };
    this.netsList = NETS;
  }

  getComponentsList = () => {
    return this.componentsList;
  }

  getNetsList = () => {
    return this.netsList;
  }

  getLayout = () => {
    return this.projectDB.layoutDB;
  };

  search = (value) => {
    if (!value) return [];

  };

  /**
   *  @returns a promise object so that the call can handle the result
   */
  getStackup = () => {
    return new Promise((resolve, reject) => {
      LayoutData.requestStackup().then(res => {
        resolve(res);
      })
    });
  }; // LayoutData.getStackup

  requestStackup = () => {
    return new Promise((resolve, reject) => {
      const index = this.info.findIndex(item => item.designID === this.designID);
      if (this.info[index].stackupFileContent && Object.keys(this.info[index].stackupFileContent).length > 0) {
        this.projectDB.stackup = new CeLayerStack(this.projectDB.layoutDB.GetLayerManager());
        var failed = false;
        var stackupBlock = new CeIODataBlock("Stackup");
        if (!stackupBlock.ReadBlockFromFile(this.info[index].stackupFileContent))
          failed = true;
        else if (!this.projectDB.stackup.ReadFromIODataNode(stackupBlock))
          failed = true;

        if (failed) {
          // failed to get the remote file, create a default stackup
          var unit = this.projectDB.layoutDB.GetLayoutUnits().toLowerCase();
          if (!this.projectDB.stackup.createLayerStackFromLayout(62, unit))
            this.projectDB.stackup.createLayerStackFromLayout(93, unit);
          // and save it to the remote server
          LayoutData.saveStackup();
          resolve(this.projectDB.stackup);

        } else {
          // TODO - for now the layout DB unit is simply a string, and is not using the definitions
          //        in CsUnits. On the other hand, CeLayerStack is using the CsUnits definitions.
          //        We need to merge the usage of units at some point.
          if (this.projectDB.stackup.getUnitString() == "Unknown")
            this.projectDB.stackup.setUnitFromString(this.projectDB.layoutDB.GetLayoutUnits());
          resolve(this.projectDB.stackup);
        }
      } else {
        getDesignFile(this.designID, 'stackup.dat').then((fileContent) => {
          // file download successful, create a stackup object and parse the file content
          this.info.map(item => {
            if (item.designID === this.designID) {
              item.stackupFileContent = fileContent.data;
            }
          })
          this.projectDB.stackup = new CeLayerStack(this.projectDB.layoutDB.GetLayerManager());
          var failed = false;
          var stackupBlock = new CeIODataBlock("Stackup");
          if (!stackupBlock.ReadBlockFromFile(fileContent.data))
            failed = true;
          else if (!this.projectDB.stackup.ReadFromIODataNode(stackupBlock))
            failed = true;

          if (failed) {
            // failed to get the remote file, create a default stackup
            var unit = this.projectDB.layoutDB.GetLayoutUnits().toLowerCase();
            if (!this.projectDB.stackup.createLayerStackFromLayout(62, unit))
              this.projectDB.stackup.createLayerStackFromLayout(93, unit);
            // and save it to the remote server
            LayoutData.saveStackup();
            resolve(this.projectDB.stackup);

          } else {
            // TODO - for now the layout DB unit is simply a string, and is not using the definitions
            //        in CsUnits. On the other hand, CeLayerStack is using the CsUnits definitions.
            //        We need to merge the usage of units at some point.
            if (this.projectDB.stackup.getUnitString() == "Unknown")
              this.projectDB.stackup.setUnitFromString(this.projectDB.layoutDB.GetLayoutUnits());
            resolve(this.projectDB.stackup);
          }
        }, (error) => {
          this.projectDB.stackup = new CeLayerStack(this.projectDB.layoutDB.GetLayerManager());
          // failed to get the remote file, create a default stackup
          var unit = this.projectDB.layoutDB.GetLayoutUnits().toLowerCase();
          if (!this.projectDB.stackup.createLayerStackFromLayout(62, unit))
            this.projectDB.stackup.createLayerStackFromLayout(93, unit);
          // and save it to the remote server
          LayoutData.saveStackup();
          resolve(this.projectDB.stackup);
        });
      }
    })
  }

  /** Save the stackup data to the web server. */
  saveStackup = () => {
    var stackupBlock = new CeIODataBlock("Stackup");
    if (!this.projectDB.stackup.WriteIntoIODataNode(stackupBlock))
      return;

    const blob = new Blob([stackupBlock.WriteBlockToBuffer(0)], {
      type: 'plain/text;charset=utf-8;'
    });

    // clean stackup file content
    const index = this.info.findIndex(item => item.designID === this.designID);
    this.info[index].stackupFileContent = null;

    return uploadStackup(this.designID, blob);
  }; // LayoutData.saveStackup

  CSVtoArray = function (strData, strDelimiter) {

    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      (
        // Delimiters.
        "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        // Standard fields.
        "([^\"\\" + strDelimiter + "\\r\\n]*))"
      ),
      "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [
      []
    ];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec(strData)) {

      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length &&
        strMatchedDelimiter !== strDelimiter) {

        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      var strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"");
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return (arrData);

  } // CSVtoArray


  getLayoutInfo = () => {
    return new Promise((resolve, reject) => {
      const index = this.info.findIndex(item => item.designID === this.designID);
      if (index > -1) {
        resolve(this.info[index].LayoutInfo);
      } else {
        getDesignInfoPromise(this.designID).then(res => {
          const { type, name, projectId, vendor } = res;
          let layoutType;
          if (type === LAYOUT) {
            layoutType = 'layout';
          }
          const vendorNames = Object.keys(vendorMap);
          const vendorIds = Object.values(vendorMap);
          const index = vendorIds.indexOf(vendor);

          let singleInfo = {
            designID: this.designID,
            LayoutInfo: {
              layoutType: layoutType,
              designName: name,
              projectId: projectId,
              vendor: index > 0 ? vendorNames[index] : ''
            },
            stackupFileContent: {},
            CeLayoutData: null,
            CePartsData: null,
            loadMetalLayerData: [],


          }
          this.info.push(singleInfo);
          resolve({ layoutType, designName: name, projectId, vendor: index > 0 ? vendorNames[index] : '' });
        }, error => {
          console.error(error);
        })
      }
    })
  }

}

const LayoutData = new NewLayoutData();
export default LayoutData;