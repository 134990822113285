import { isAuthed } from '../api/auth';
import { getUserPermissions } from '../api/v2/roles';
import getCustomizedSettings, { getInstallerShow, getPageType } from '../api/v2/settingCtrl';
import apiProcess from '../api/utility';

export function getUserPermissionsPromise() {
  return new Promise((resolve, reject) => {
    if (isAuthed()) {
      getUserPermissions().then(res => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data)
        } else {
          resolve([])
        }
      }, error => {
        resolve([])
      })
    } else {
      resolve([])
    }
  })
}

export function getSettings() {
  return new Promise((resolve, reject) => {
    if (isAuthed()) {
      getCustomizedSettings().then(res => {
        if (res && res.data) {
          resolve(res.data.data);
        } else {
          resolve(null)
        }
      }, error => {
        console.error(error);
        resolve(null)
      })
    } else {
      resolve(null)
    }
  })
}

export function getInstallerShowPromise() {
  return new Promise((resolve, reject) => {
    getInstallerShow().then(res => {
      if (res && res.data) {
        resolve(res.data.data);
      } else {
        resolve(false)
      }
    }, error => {
      console.error(error);
      resolve(false)
    })
  })
}

export function getPageTypePromise() {
  return new Promise((resolve, reject) => {
    getPageType().then(res => {
      if (res && res.data) {
        resolve(res.data.data);
      } else {
        resolve(false)
      }
    }, error => {
      console.error(error);
      resolve(false)
    })
  })
}