import StringList from '../utility/StringList';

var CeObject = function () {
  this.mID = 0;
  this.mName = "";
};

CeObject.prototype.GetID = function () {
  return this.mID;
}

CeObject.prototype.SetID = function (id) {
  this.mID = id;
}

CeObject.prototype.GetName = function () {
  return this.mName;
}

CeObject.prototype.SetName = function (name) {
  this.mName = name;
}

CeObject.prototype.GetDataStrings = function () {
  var vals = new StringList();
  vals.add(this.mID);
  vals.add(this.mName);
  return vals;
}

CeObject.prototype.UpdateByString = function (dataString) {
  if (dataString.size() < 2)
    return false;

  this.mID = parseInt(dataString.get(0), 10);
  this.mName = dataString.get(1);
  return true;
}

export default CeObject;