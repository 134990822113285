const TYPE = 'ROCKY';

/* channel PDN */
export const GET_CHANNEL_PDN_CONTENT = `${TYPE}/get_channel_pdn_content`;
export const UPDATE_ROCKY_PDN_CONTENT = `${TYPE}/update_rocky_pdn_content`;

export const SAVE_ROCKY_PDN_TO_SERVER = `${TYPE}/save_rocky_pdn_content_to_server`;
export const UPDATE_ROCKY_PDN_INFO = `${TYPE}/update_rocky_pdn_info`;

/* VRM */
export const SAVE_PDN_VRM = `${TYPE}/save_pdn_vrm`;

/* components */
export const UPDATE_COMPONENTS = `${TYPE}/update_components`;
export const COMP_REMOVED_CHANGE = `${TYPE}/comp_removed_change`;
export const SAVE_SPLIT_COMPS = `${TYPE}/save_split_comps`;
export const SAVE_MERGE_COMPS = `${TYPE}/save_merge_comps`;
export const SAVE_ROCKY_PDN_PKG_DIE_MODEL = `${TYPE}/save_rocky_pdn_pkg_die_model`;
export const UPDATE_COMPONENT_RLC_PREFIX = `${TYPE}/update_component_rlc_prefix`;

/* config */
export const SAVE_PDN_CONFIG = `${TYPE}/save_pdn-config`;

/* RE_EXTRACTION */
export const ROCKY_PDN_RE_EXTRACTION = `${TYPE}/rocky_pdn_re_extraction`;
export const UPDATE_ROCKY_PDN_IFDOEXTRACTION = `${TYPE}/update_rocky_pdn_ifdoextraction`;

export const UPDATE_ROCKY_PDN_ERROR_LIST = `${TYPE}/update_rocky_pdn_error_list`;

export const UPDATE_LOADING_PCB = `${TYPE}/update_loading_pcb`;
export const UPDATE_COMP_PREFIX_PROMPT = `${TYPE}/update_comp_prefix_prompt`;
export const ROCKY_PDN_SELECT_NETS = `${TYPE}/rocky_pdn_select_nets`;