import React, { Component, Fragment } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { Collapse, Spin } from 'antd';
import CreateContent from './createContent';
import SelectContent from './selectContent';
import {
  defaultIdentificationAdvanceConfig
} from '../../../../services/Sierra/helper/InterfaceHelper';
import { checkNameFormat } from '../../../../services/helper/nameFormatCheck';
import "./index.css";

const CREATE_INTERFACE = 'create_interface',
  NO_INTERFACE = "noInterface", COMPONENT = "component", INTERFACE_TYPE = "interfaceType";
const customPanelStyle = {
  background: '#ffffff',
  borderRadius: 4,
  marginBottom: 10,
  border: 0,
  overflow: 'hidden'
};

class PCBSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signalStatus: {}
    }
  }

  updateSignalStatus = (status, pcbId) => {
    this.setState({
      signalStatus: { ...this.state.signalStatus, [pcbId]: status }
    })
  }

  updateAdvancedConfig = (advancedConfig, pcbId, type) => {
    const { pcbConfig, applySetupAll } = this.props;
    let _pcbConfig = { ...pcbConfig };
    if (!_pcbConfig[pcbId]) {
      _pcbConfig[pcbId] = {}
    }
    if (_pcbConfig[pcbId].errorMsg && _pcbConfig[pcbId].errorMsg.type === NO_INTERFACE) {
      _pcbConfig[pcbId].errorMsg = null;
    }

    if (applySetupAll) {
      //Apply setup to all PCBs
      for (let id of Object.keys(_pcbConfig || {})) {
        _pcbConfig[id].advancedConfig = JSON.parse(JSON.stringify(advancedConfig))
      }
    } else {
      _pcbConfig[pcbId].advancedConfig = advancedConfig;
    }

    this.props.updatePCBConfig(_pcbConfig);
  }

  selectComponent = (key, pcbId) => {
    const { pcbComponents, pcbConfig } = this.props;
    let _pcbConfig = { ...pcbConfig };
    if (!_pcbConfig[pcbId]) {
      _pcbConfig[pcbId] = {}
    }
    const component = (pcbComponents[pcbId] || []).find(item => item.name === key);
    if (_pcbConfig[pcbId].errorMsg && [COMPONENT, NO_INTERFACE].includes(_pcbConfig[pcbId].errorMsg.type)) {
      _pcbConfig[pcbId].errorMsg = null;
    }
    _pcbConfig[pcbId].component = component || "";
    this.props.updatePCBConfig(_pcbConfig, pcbId, true);
  }

  selectChange = (key, type, pcbId) => {
    const { pcbConfig, applySetupAll } = this.props;
    let _pcbConfig = { ...pcbConfig };
    if (!_pcbConfig[pcbId]) {
      _pcbConfig[pcbId] = {}
    }

    if (applySetupAll) {
      //Apply setup to all PCBs
      for (let id of Object.keys(_pcbConfig || {})) {
        _pcbConfig[id][type] = key;
        if (type === INTERFACE_TYPE) {
          const exclude = _pcbConfig[id].advancedConfig ? [...(_pcbConfig[id].advancedConfig.EXCLUDE || [])] : []
          const newAdvancedConfig = new defaultIdentificationAdvanceConfig({ type: key, setting: { EXCLUDE: exclude } })
          _pcbConfig[id].advancedConfig = newAdvancedConfig;
          this.props.changeDefaultName(key)
        }
      }
    } else {
      _pcbConfig[pcbId][type] = key;
      if (type === INTERFACE_TYPE) {
        const exclude = _pcbConfig[pcbId].advancedConfig ? [...(_pcbConfig[pcbId].advancedConfig.EXCLUDE || [])] : []
        const _advancedConfig = new defaultIdentificationAdvanceConfig({ type: key, setting: { EXCLUDE: exclude } })
        _pcbConfig[pcbId].advancedConfig = _advancedConfig;
        this.props.changeDefaultName(key)
      }
    }
    if (_pcbConfig[pcbId].errorMsg && _pcbConfig[pcbId].errorMsg.type === NO_INTERFACE) {
      _pcbConfig[pcbId].errorMsg = null;
    }
    this.props.updatePCBConfig(_pcbConfig);
  }

  updateRegularValue = (isRegular, key, pcbId) => {
    const { pcbConfig, applySetupAll } = this.props;
    let _pcbConfig = { ...pcbConfig };
    if (!_pcbConfig[pcbId]) {
      _pcbConfig[pcbId] = {}
    }

    if (applySetupAll) {
      //Apply setup to all PCBs
      for (let id of Object.keys(_pcbConfig || {})) {
        let netNamePrefix = "";
        const advancedConfig = _pcbConfig[id].advancedConfig;
        if (!isRegular && key === "isRegular") {
          const _advancedConfig = new defaultIdentificationAdvanceConfig({ type: advancedConfig.type })
          netNamePrefix = _advancedConfig.netNamePrefix;
        }
        if (key === "isRegular") {
          advancedConfig.netNamePrefix = [...new Set([...advancedConfig.netNamePrefix, ...netNamePrefix])];
        } /* else if (key === "excludeIsRegular") {
          advancedConfig.EXCLUDE = [];
        } */

        advancedConfig[key] = isRegular;
        _pcbConfig[id].advancedConfig = advancedConfig;
      }
    } else {
      let netNamePrefix = "";
      const advancedConfig = { ...(_pcbConfig[pcbId].advancedConfig || {}) };
      if (!isRegular && key === "isRegular") {
        const _advancedConfig = new defaultIdentificationAdvanceConfig({ type: advancedConfig.type })
        netNamePrefix = _advancedConfig.netNamePrefix;
      }
      if (key === "isRegular") {
        advancedConfig.netNamePrefix = [...new Set([...advancedConfig.netNamePrefix, ...netNamePrefix])];
      } /* else if (key === "excludeIsRegular") {
        advancedConfig.EXCLUDE = [];
      } */
      advancedConfig[key] = isRegular;
      _pcbConfig[pcbId].advancedConfig = advancedConfig;
    }

    this.props.updatePCBConfig(_pcbConfig);
  }

  clearAllKeywords = (e, key, pcbId) => {
    e && e.stopPropagation();
    const { pcbConfig, applySetupAll } = this.props;
    let _pcbConfig = { ...pcbConfig };
    if (!_pcbConfig[pcbId]) {
      _pcbConfig[pcbId] = {}
    }

    if (applySetupAll) {
      //Apply setup to all PCBs
      for (let id of Object.keys(_pcbConfig || {})) {
        if (_pcbConfig[id].advancedConfig) {
          _pcbConfig[id].advancedConfig[key] = []
        }
      }
    } else {
      if (_pcbConfig[pcbId].advancedConfig) {
        _pcbConfig[pcbId].advancedConfig[key] = [];
      }
    }

    this.props.updatePCBConfig(_pcbConfig);
  }

  checkSignals = (e, key, pcbId) => {
    e.stopPropagation();
    const { pcbInterfaceInfo } = this.props;
    let _selectKeys = [];
    let _pcbInterfaceInfo = pcbInterfaceInfo ? { ...pcbInterfaceInfo } : {};
    if (!_pcbInterfaceInfo[pcbId]) {
      _pcbInterfaceInfo[pcbId] = {}
    }
    const allInterfaceCompNetData = _pcbInterfaceInfo[pcbId].allInterfaceCompNetData || [];
    const selectKeys = _pcbInterfaceInfo[pcbId].selectKeys || [];

    if (_pcbInterfaceInfo[pcbId].identificationCreateMsg) { this.props.updateIdentificationMassage(null) }
    if (key === 'all') {
      if (allInterfaceCompNetData.length === selectKeys.length) {
        _selectKeys = [];
      } else {
        _selectKeys = allInterfaceCompNetData.map(item => item.interfaceKey)
      }
    } else {
      if (selectKeys.includes(key)) {
        _selectKeys = selectKeys.filter(item => item !== key)
      } else {
        _selectKeys = [...selectKeys, key]
      }
    }
    _pcbInterfaceInfo[pcbId].selectKeys = _selectKeys;
    this.props.updatePCBInterfaceInfo(pcbInterfaceInfo);
  }

  updateSignalName = (record) => {
    const { pcbInterfaceInfo } = this.props;
    let _pcbInterfaceInfo = pcbInterfaceInfo ? { ...pcbInterfaceInfo } : {};
    if (!_pcbInterfaceInfo[record.pcbId]) {
      _pcbInterfaceInfo[record.pcbId] = {}
    }

    //name format error check
    if (checkNameFormat(record.signalMergeName)) {
      return;
    }
    const index = _pcbInterfaceInfo[record.pcbId].allInterfaceCompNetData.findIndex(item => item.interfaceKey === record.interfaceKey);
    if (index < 0) {
      return;
    }
    const prevNames = _pcbInterfaceInfo[record.pcbId].allInterfaceCompNetData[index].content.map(item => item.signalMergeName);
    if (prevNames.includes(record.signalMergeName)) {
      return;
    }
    const signalIndex = _pcbInterfaceInfo[record.pcbId].allInterfaceCompNetData[index].content.findIndex(item => item.signalId === record.signalId);
    if (signalIndex < 0) {
      return;
    }
    _pcbInterfaceInfo[record.pcbId].allInterfaceCompNetData[index].content[signalIndex].signalMergeName = record.signalMergeName;
    this.props.updatePCBInterfaceInfo(pcbInterfaceInfo);
    this.updateSignalStatus(true, record.pcbId);
  }

  render = () => {
    const { PCBList = [], pcbSelectionList = [], contentType, pcbConfig, showMergeName,
      pcbComponents, pcbInterfaceInfo, mergeAllSignal, expandedPCBKeys, contentMaxHeight,
      selectedDesignIDs, pcbLoading, pcbComponent, searchLogs, groupName } = this.props;
    const pcbList = PCBList.map(item => { return { id: item, name: pcbSelectionList.find(it => it.id === item).name } })
    const { signalStatus } = this.state;

    const footerHeight = PCBList.length > 1 ? 168 : 66;
    return (
      <Collapse
        bordered={false}
        activeKey={expandedPCBKeys || []}
        onChange={this.props.pcbExpandChange}
        className={contentType !== CREATE_INTERFACE ? "creation-interface-collapse creation-interface-collapse-main" : ''}
        style={{
          maxHeight: contentType !== CREATE_INTERFACE ? contentMaxHeight - footerHeight : null,
          /*   height: contentType !== CREATE_INTERFACE ? `calc(100% - ${footerHeight}px)` : null */
        }}
        items={pcbList.map(item => ({
          label: <div className='creation-interface-pcb-expand-title'>
            <div
              className={contentType !== CREATE_INTERFACE ? 'sierra-pcb-setup-title' : "sierra-pcb-setup-create-title"}
            >
              <span title={item.name}>
                {item.name}
              </span>
              {contentType !== CREATE_INTERFACE && searchLogs && searchLogs[item.id] && searchLogs[item.id].logs && searchLogs[item.id].logs.length ? <FileTextOutlined
                title="Show identification log"
                className='sierra-pcb-search-log-info-icon'
                onClick={(e) => this.props.showSearchLogs(e, item.id)} />
                : null}
            </div>
            {expandedPCBKeys.includes(item.id) && contentType !== CREATE_INTERFACE ? this.props.mergeSignalsToInterfaceButton(item.id) : null}
          </div>,
          key: item.id,
          styles: customPanelStyle,
          children: <Spin spinning={pcbLoading[item.id] && pcbLoading[item.id] ? true : false} tip={pcbLoading[item.id] && pcbLoading[item.id] ? "Loading PCB..." : ''}>
            {contentType === CREATE_INTERFACE ?
              <CreateContent
                {...this.props}
                groupName={groupName}
                pcbId={item.id}
                updateAdvancedConfig={(advancedConfig, type) => this.updateAdvancedConfig(advancedConfig, item.id, type)}
                selectComponent={(key) => this.selectComponent(key, item.id)}
                selectChange={(key, type) => this.selectChange(key, type, item.id)}
                updateRegularValue={(isRegular, key) => this.updateRegularValue(isRegular, key, item.id)}
                inputChange={(e, type) => this.props.inputChange(e, type)}
                checkValue={(value, type) => this.props.checkValue(value, type)}
                componentList={pcbComponents[item.id] || []}
                clearAllKeywords={(e, key) => this.clearAllKeywords(e, key, item.id)}
                config={{
                  ...(pcbConfig[item.id] || {}),
                  component: pcbConfig[item.id] && pcbConfig[item.id].component && pcbConfig[item.id].component.name ? pcbConfig[item.id].component : pcbComponent[item.id]
                }}
                advancedConfig={pcbConfig[item.id] ? pcbConfig[item.id].advancedConfig || {} : {}}
              />
              :
              <Fragment>
                {/*  {this.props.mergeSignalsToInterfaceButton(item.id)} */}
                <SelectContent
                  pcbId={item.id}
                  allInterfaceCompNetData={pcbInterfaceInfo[item.id] ? pcbInterfaceInfo[item.id].allInterfaceCompNetData || [] : []}
                  checkSignals={(e, type) => this.checkSignals(e, type, item.id)}
                  selectKeys={pcbInterfaceInfo[item.id] ? pcbInterfaceInfo[item.id].selectKeys || [] : []}
                  mergeAllSignal={mergeAllSignal}
                  showMergeName={showMergeName}
                  selectedDesignIDs={selectedDesignIDs}
                  updateSignalName={this.updateSignalName}
                  showNetsToLayout={this.props.showNetsToLayout}
                  signalStatus={signalStatus[item.id] || false}
                  updateSignalStatus={(status) => this.updateSignalStatus(status, item.id)}
                />
              </Fragment>}
          </Spin>
        })
        )}
      />
    );
  }
}


export default PCBSetup;