import { defaultTreeItems } from '@/services/Andes_v2';
import { SINGLE_PAGE_LAYOUT, PCB_ONLY } from '@/constants/layoutConstants';
import {
  UPDATE_EXPAND_KEYS,
  UPDATE_TREE_LIST,
  UPDATE_SELECTED_KEYS,
  CLEAR_CURRENT_PROJECT,
  REFRESH_PCB,
  SAVE_OPEN_PROJECT_INFO,
  UPDATE_VIEW_LIST,
  UPDATE_PAGE_LAYOUT,
  UPDATE_PCBS_LAYOUT_DB,
  CHANGE_COPY_LOADING_ID,
  UPDATE_REPORT_INFO,
  CLEAR_REPORT_INFO,
  UPDATE_PCB_LAYOUT,
  UPDATE_VIEWLIST,
  UPDATE_SERVICE_CONFIG,
  UPDATE_STACKUP_STATUS_LIST
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';

const defaultState = {
  treeItems: defaultTreeItems,
  selectedKeys: [],
  expandedKeys: ['library', 'projects'],
  viewList: [],
  layout: SINGLE_PAGE_LAYOUT,
  pcbLayout: PCB_ONLY,
  openProjectId: null,
  refreshDesignId: null,
  pcbComponentsNets: [],
  reportInfo: { reportConfigLoading: true },
  serviceOptions: {},
  stackupStatusList: []
}

export const AndesProjectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_EXPAND_KEYS:
      return {
        ...state,
        expandedKeys: action.expandedKeys
      }
    case UPDATE_SELECTED_KEYS:
      return {
        ...state,
        selectedKeys: action.selectedKeys,
      }
    case UPDATE_VIEWLIST:
      return {
        ...state,
        viewList: action.viewList
      }
    case UPDATE_TREE_LIST:
      return {
        ...state,
        treeItems: action.treeItems
      }
    case CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        viewList: [],
        openProjectId: null,
      }
    case REFRESH_PCB:
      return {
        ...state,
        refreshDesignId: action.refreshDesignId
      }
    case SAVE_OPEN_PROJECT_INFO:
      return {
        ...state,
        openProjectId: action.id ? action.id : state.openProjectId
      }
    case UPDATE_VIEW_LIST:
      return {
        ...state,
        viewList: action.viewList
      }
    case UPDATE_PAGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      }
    case UPDATE_PCBS_LAYOUT_DB:
      return {
        ...state,
        pcbComponentsNets: action.pcbsLayoutDB
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case CHANGE_COPY_LOADING_ID:
      return {
        ...state,
        copyProjectLoadingId: action.copyProjectLoadingId !== undefined ? action.copyProjectLoadingId : state.copyProjectLoadingId,
        copyChannelLoadingId: action.copyChannelLoadingId !== undefined ? action.copyChannelLoadingId : state.copyChannelLoadingId,
        copyEndToEndChannelLoadingId: action.copyEndToEndChannelLoadingId !== undefined ? action.copyEndToEndChannelLoadingId : state.copyEndToEndChannelLoadingId
      }
    case UPDATE_REPORT_INFO:
      return {
        ...state,
        reportInfo: { ...state.reportInfo, ...action.info }
      }
    case CLEAR_REPORT_INFO:
      return {
        ...state,
        reportInfo: {}
      }
    case UPDATE_PCB_LAYOUT:
      return {
        ...state,
        pcbLayout: action.pcbLayout
      }
    case UPDATE_SERVICE_CONFIG:
      return {
        ...state,
        serviceOptions: action.serviceOptions
      }
    case UPDATE_STACKUP_STATUS_LIST:
      return {
        ...state,
        stackupStatusList: action.stackupStatusList || []
      }
    default: return state;
  }
};