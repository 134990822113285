import React, { PureComponent } from "react";
import { Button, Tooltip } from "antd";
import SelectionPanel from './SelectionPanel';
import '../index'
import '../Selection.css';

class Selection extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  getDisplayBoxShow = () => {
    const { selections, designID } = this.props;
    return selections[designID] ? selections[designID].displayBoxShow : false;
  }

  onCreatePanel = () => {
    const { designID } = this.props;
    this.props.changeDisplaySelected(!this.getDisplayBoxShow(), designID)
  }

  panelClose = () => {
    const { designID } = this.props;
    this.props.changeDisplaySelected(false, designID)
  }

  visibleChange = (visible) => {
    if (this.getDisplayBoxShow()) {
      visible = false;
    }
    this.setState({
      visible: visible,
    })
  }

  render() {
    const { width, leftWidth, ...restProp } = this.props;
    return (
      <div className='selectionButton'>
        <div>
          <Tooltip
            title='Selected For Display'
            overlayClassName='highlightobjects'
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            open={this.state.visible}
            onOpenChange={(visible) => { this.visibleChange(visible) }}
          >
            <Button
              placeholder='selection'
              className='selectionBtn'
              onClick={this.onCreatePanel}
              size='small'
              type='primary'
            >Selected</Button>
          </Tooltip>
        </div>
        {this.getDisplayBoxShow() && <SelectionPanel cancel={this.panelClose} width={width} leftWidth={leftWidth} {...restProp} />}
      </div>
    )
  }
}

export default Selection; 
