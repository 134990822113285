const TOP_ROUGHNESS = "roughness",
  BOT_ROUGHNESS = "bot_roughness",
  SIDE_ROUGHNESS = "side_roughness";
const roughnessList = [{
  key: TOP_ROUGHNESS,
  title: "Top Roughness"
},
{
  key: BOT_ROUGHNESS,
  title: "Bottom Roughness"
},
{
  key: SIDE_ROUGHNESS,
  title: "Side Roughness"
}];

const ROUGHNESS_NONE = "no",
  HAMMERSTAD_JENSEN = "hammerstad-jensen",
  HURAY = "huray",
  SURFACE_RATIO = "surface_ratio",
  NODULE_RADIUS = "nodule_radius",
  RMS_ROUGHNESS = "rms_roughness";
const roughnessTypeList = [
  {
    key: ROUGHNESS_NONE,
    name: "None"
  },
  {
    key: HAMMERSTAD_JENSEN,
    name: "Hammerstad-Jensen"
  },
  {
    key: HURAY,
    name: "Huray"
  }
];

const LOW_LOSS = "low_loss", MEDIUM_LOSS = "medium_loss", HIGH_LOSS = "high_loss", CUSTOM = "custom";
const roughnessHuraySettingList = [
  {
    key: LOW_LOSS,
    name: "Low Loss"
  },
  {
    key: MEDIUM_LOSS,
    name: "Medium Loss"
  },
  {
    key: HIGH_LOSS,
    name: "High Loss"
  },
  {
    key: CUSTOM,
    name: "Custom"
  }];

const rmsRoughnessUnitList = ["mm", "um", "nm", "pm"],
  hurayUnitList = ["mm", "um", "nm", "pm"];

export {
  roughnessList,
  TOP_ROUGHNESS,
  BOT_ROUGHNESS,
  SIDE_ROUGHNESS,
  roughnessTypeList,
  HAMMERSTAD_JENSEN,
  HURAY,
  ROUGHNESS_NONE,
  SURFACE_RATIO,
  NODULE_RADIUS,
  RMS_ROUGHNESS,
  roughnessHuraySettingList,
  rmsRoughnessUnitList,
  hurayUnitList,
  CUSTOM,
  LOW_LOSS,
  MEDIUM_LOSS,
  HIGH_LOSS
}

export {
  getRoughnessByType,
  updateHurayRoughnessBySetting
} from './roughnessHelper';