import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Checkbox, Select, Radio, Input, Switch } from 'antd';
import Panel from '../../../components/Panel';
import EditableTable from '../../../components/EditableTable';
import SPICEFileSelect from './components/SPICEFileSelect';
import SubcktNodeSelect from './components/SubcktNodeSelect';
import libraryConstructor from '../../../services/Andes_v2/library/libraryConstructor';
import { BUFFER_SPICE, IBIS, SPICE } from '../../../constants/libraryConstants';
import {
  handleSelectSubckt,
  getNodeSelectOption,
  getRxSelectList,
  getSubcktByPairInfo,
  onlyModelTitle,
  handleChangeSpiceType,
  handleSelectFile,
  IBISPinAndModelColumns,
  cornerOptions,
  handleSelectNode,
  getModelKey,
  getModelSelector,
  getFileTreeData
} from '../../../services/Andes_v2/simulation';
import { numberCheck } from '../../../services/helper/dataProcess';

const { Option } = Select;

/**
 * IBIS: only support select 1 library file
 * SPICE：1 library file or 3 library file
 */
class RxModel extends Component {
  constructor() {
    super();
    this.state = {
      applyAll: false,
      spiceType: 'only', // only / share
      ibisLibraryList: libraryConstructor.getLibraryValues(IBIS) || [],
      fileTreeData: [],
      modelNameSelectList: [],
      modelTypeSelectList: [],
      selectorModels: [],
      model: {
        files: [],
        pairs: [],
        pinModels: [],
        type: "ibis"
      },
      modelSearchValue: '',
      nodeVisible: '',
      subcktList: {},
      IBISPinAndModelColumns,
      editPowerOff: {}
    }
    this.dialogRoot = document.getElementById('root');
  }

  async componentDidMount() {
    const { model } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside, true);
    const { files, type } = model;
    let spiceType = 'only', modelNameSelectList = [], modelTypeSelectList = [], selectorModels = [], IBISPinAndModelColumns = [];
    let fileTreeData = []
    if (type === IBIS) {
      const file = files[0] || {};
      const selectListInfo = await getRxSelectList(file);
      modelNameSelectList = selectListInfo.modelNameSelectList;
      modelTypeSelectList = selectListInfo.modelTypeSelectList;
      selectorModels = selectListInfo.selectorModels;
    } else {
      spiceType = files.length > 1 ? 'only' : 'share';
      fileTreeData = await getFileTreeData([SPICE, BUFFER_SPICE], model.files)
    }
    this.setState({
      model: JSON.parse(JSON.stringify(model)),
      spiceType,
      modelNameSelectList,
      modelTypeSelectList,
      selectorModels,
      IBISPinAndModelColumns,
      fileTreeData
    }, () => {
      type === IBIS && this.getIBISPinAndModelColumns();
      this.isMountedFlag = true;
    })
  }

  componentWillUnmount() {
    this.isMountedFlag = false;
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  async componentDidUpdate(prevProps) {
    const { libraryStatus, model } = this.props;
    if (prevProps.libraryStatus !== libraryStatus) {
      this.setState({
        ibisLibraryList: libraryConstructor.getLibraryValues(IBIS),
        fileTreeData: await getFileTreeData([SPICE, BUFFER_SPICE], model.files)
      })
    }
  }

  getIBISPinAndModelColumns = () => {
    const { modelNameSelectList, model: { pinModels } } = this.state;
    const powerOff = (pinModels[0] || {}).powerOff;
    const _IBISPinAndModelColumns = IBISPinAndModelColumns.filter(item => !['pullUpVoltage', 'pullDownVoltage'].includes(item.key) || powerOff)
    _IBISPinAndModelColumns.forEach((column, index) => {
      if (index === 1) {
        column.onCell = (record) => {
          return {
            record,
            edit: "select",
            options: modelNameSelectList,
            dataIndex: "modelName",
            getPopupContainer: document.getElementById('root'),
            dropdownMenuClassName: "aurora-select-dropdown",
            handleSave: (record, prevRecord) => { this.selectIBISModelInfo('modelName', { modelNameInfo: record }) },
          }
        }
      } else if (index === 2) {
        column.onCell = (record) => {
          return {
            record,
            edit: "select",
            options: cornerOptions,
            dataIndex: "corner",
            getPopupContainer: document.getElementById('root'),
            dropdownMenuClassName: "aurora-select-dropdown",
            handleSave: (record) => { this.selectCorner(record) },
          }
        }
      } else if (index === 3 || index === 4) {
        const { editPowerOff } = this.state;
        column.render = (_, record) => {
          const { pin } = record;
          const dataIndex = index === 3 ? "pullUpVoltage" : "pullDownVoltage";
          const value = record[dataIndex];
          return <Input
            value={editPowerOff.pin === pin && editPowerOff.dataIndex === dataIndex ? editPowerOff.value : value}
            addonAfter="V"
            onFocus={() => this.focusPowerOffInput({ pin: record.pin, dataIndex, value })}
            onChange={(e) => this.changeEditPowerOff(e.target.value)}
            onBlur={this.savePowerOff}
            onPressEnter={(e) => e.target.blur()}
          />;
        }
      }
    })

    this.setState({ IBISPinAndModelColumns: [..._IBISPinAndModelColumns] })
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const { nodeVisible } = this.state;
    const PopoverRoot = document.getElementById(nodeVisible);

    if (!PopoverRoot) {
      return;
    }

    if (!PopoverRoot || !PopoverRoot.contains(target)) {
      this.setState({ nodeVisible: '' });
    }
  }

  closeModal = () => {
    const { model, applyAll } = this.state;
    if (this.isMountedFlag) {
      this.props.closeModal(model, applyAll);
    }
  }

  selectIBISModelInfo = async (changeType, { libraryId, modelNameInfo, modelType, modelSelector }) => {
    const { ibisLibraryList, model, selectorModels: prevSelectorModels } = this.state;
    let _files = [...model.files], _pinModels = [...model.pinModels];
    const file = ibisLibraryList.find(item => item.id === libraryId) || {};
    switch (changeType) {
      case 'libraryId':
        _files[0] = { ..._files[0], libraryId: file.id, fileName: file.name, modelType: "", modelSelector: "" };
        _pinModels = [...model.pinModels].map(item => {
          return { ...item, libraryId: "", fileName: "", modelName: "", modelType: "" };
        })
        break;
      case 'modelType':
        _files[0] = { ..._files[0], modelType };
        break;
      case 'modelSelector':
        _files[0] = { ..._files[0], modelSelector };
        break;
      case 'modelName': // pinModels select modelName backfills modelType and modelSelector
        // modelNameInfo: {pin, net, libraryId, fileName, modelName: 'I/O DQS DQS_34_1066'}
        const [_modelType, modelName] = modelNameInfo.modelName.split(' ');
        const _modelSelector = getModelSelector(prevSelectorModels, modelName);
        _files[0] = { ..._files[0], modelType: _modelType, modelSelector: _modelSelector };
        _pinModels = [...model.pinModels].map(item => {
          if (item.pin === modelNameInfo.pin) {
            return { ...item, modelName, libraryId: _files[0].libraryId, fileName: _files[0].fileName, modelType: _modelType }
          }
          return item;
        })
        break;
      default:
        break;
    }

    const { modelNameSelectList, modelTypeSelectList, selectorModels } = await getRxSelectList(_files[0]);
    const IBISPinAndModelColumns = this.getIBISPinAndModelColumns(modelNameSelectList, _pinModels);

    this.setState({
      modelNameSelectList,
      modelTypeSelectList,
      selectorModels,
      model: { ...model, files: [..._files], pinModels: [..._pinModels] },
      modelSearchValue: '',
      IBISPinAndModelColumns
    }, () => {
      this.getIBISPinAndModelColumns()
    })
  }

  selectCorner = ({ corner, pin }) => {
    const { model } = this.state;
    const _pinModels = [...model.pinModels].map(item => {
      if (item.pin === pin) {
        return { ...item, corner };
      }
      return item;
    })

    this.setState({ model: { ...model, pinModels: [..._pinModels] } })
  }

  changeModelSearchValue = (value) => {
    this.setState({ modelSearchValue: value })
  }

  changeModelType = async (value) => {
    const { model, fileTreeData } = this.state;
    let _pinModels = [...model.pinModels], type = model.type, _pairs = [...model.pairs], _fileTreeData = [];

    // switch type post-processing, clear files, clear pinModels data about file selection
    if (type === IBIS) {
      _pinModels = _pinModels.map(item => {
        return { ...item, libraryId: "", fileName: "", modelName: "" }
      })
      if (!fileTreeData.length) {
        _fileTreeData = await getFileTreeData([SPICE, BUFFER_SPICE], [])
      }
    } else {
      _pairs = _pairs.map(item => {
        return { node: "", pin: item.pin, net: item.net }
      })
    }

    this.setState({
      model: {
        type: value,
        files: [],
        pinModels: [..._pinModels],
        pairs: [..._pairs]
      },
      spiceType: 'share',
      fileTreeData: [..._fileTreeData]
    }, () => {
      this.getIBISPinAndModelColumns();
    })
  }

  focusPowerOffInput = (data) => {
    this.setState({ editPowerOff: { ...data } })
  }

  changeEditPowerOff = (value) => {
    const { editPowerOff } = this.state;
    this.setState({ editPowerOff: { ...editPowerOff, value } }, () => {
      this.getIBISPinAndModelColumns();
    })
  }

  savePowerOff = () => {
    const { editPowerOff: { value, dataIndex, pin }, model } = this.state;
    // check number
    if (!numberCheck(value)) {
      const pinModels = [...model.pinModels];
      const index = pinModels.findIndex(item => item.pin === pin);
      pinModels[index][dataIndex] = value;
      this.setState({ model: { ...model, pinModels } })
    }
    this.setState({ editPowerOff: {} }, () => {
      this.getIBISPinAndModelColumns();
    })
  }

  switchPowerOff = (checked) => {
    const { model } = this.state;
    const _pinModels = model.pinModels.map(item => {
      return { ...item, powerOff: checked }
    })
    this.setState({ model: { ...model, pinModels: [..._pinModels] } }, () => {
      this.getIBISPinAndModelColumns();
    })
  }

  IBISRender = () => {
    const { ibisLibraryList, modelTypeSelectList, selectorModels, model: { files, pinModels = [] }, IBISPinAndModelColumns } = this.state;
    // const modelNameFilterList = getModelNameFilterList(modelNameSelectList, modelSearchValue)
    const file = files[0] || {};
    return (
      (<div>
        {/* Model File */}
        <div className='andes-v2-hspice-simulation-model-row'>
          {/* File - subckt */}
          <span>Model File</span>
          <Select
            allowClear
            value={file.libraryId}
            onChange={(value) => this.selectIBISModelInfo('libraryId', { libraryId: value })}
            className='andes-v2-hspice-simulation-model-select'
            popupClassName='aurora-select-dropdown'
            getPopupContainer={() => document.getElementById('root')}
          >
            {ibisLibraryList.map(item => <Option
              key={item.id}
              value={item.id}
              className='spice-nodes-content-file'
            >
              {item.name}
            </Option>)}
          </Select>
        </div>
        {/* Model Type | Model Selector*/}
        <div className='andes-v2-hspice-simulation-model-row'>
          <div className='andes-v2-hspice-simulation-model-row-item'>
            <span>Model Type</span>
            <Select
              allowClear
              value={file.modelType}
              onChange={(value) => this.selectIBISModelInfo('modelType', { modelType: value })}
              className='andes-v2-hspice-simulation-model-select'
              popupClassName='aurora-select-dropdown'
              getPopupContainer={() => document.getElementById('root')}
            >
              {modelTypeSelectList.map(item => <Option key={item} value={item}>{item}</Option>)}
            </Select>
          </div>
          <div className='andes-v2-hspice-simulation-model-row-item'>
            <span>Model Selector</span>
            <Select
              allowClear
              value={file.modelSelector}
              onChange={(value) => this.selectIBISModelInfo('modelSelector', { modelSelector: value })}
              className='andes-v2-hspice-simulation-model-select'
              popupClassName='aurora-select-dropdown'
              getPopupContainer={() => document.getElementById('root')}
            >
              {selectorModels.map(item => {
                return <Option
                  key={item.selector}
                  value={item.selector}
                  className='spice-nodes-content-file'
                >
                  {item.selector}
                </Option>
              })}
            </Select>
          </div>
        </div>
        {/* Switch PowerOff */}
        <div className='andes-v2-hspice-simulation-flex'>
          <span className='andes-v2-hspice-simulation-title'>Power Off</span>
          <Switch
            size="small"
            className='aurora-switch-small ibis-switch-top-7'
            onChange={this.switchPowerOff}
            checked={(pinModels[0] || {}).powerOff}
          />
        </div>
        {/* Pin - Model */}
        <EditableTable
          rowKey={(record, index) => `rx-model-pin-model-${index}`}
          columns={IBISPinAndModelColumns}
          dataSource={pinModels}
          size="small"
          className="rx-model-ibis-table"
        />
      </div>)
    );
  }

  changeSpiceType = (type) => {
    const { model, subcktList: prevSubcktList } = this.state;
    const { files, pairs, subcktList } = handleChangeSpiceType(model, type, prevSubcktList);
    this.setState({ spiceType: type, model: { ...model, files, pairs }, subcktList })
  }

  selectModelFile = (file, fileIndex) => {
    const { model, subcktList } = this.state;
    const { files, pairs } = handleSelectFile('rxModel', model, file, fileIndex);
    this.setState({ model: { ...model, files, pairs }, subcktList: { ...subcktList, [fileIndex]: {} } });
  }

  selectSubckt = (subckt, modelKey, file) => {
    const { model, subcktList } = this.state;
    const { files, pairs } = handleSelectSubckt('rxModel', model, subckt, modelKey, file);
    const _file = files.find(item => item.modelKey === modelKey) || {};
    this.setState({ model: { ...model, files, pairs }, subcktList: { ...subcktList, [modelKey]: { ...subckt, ..._file } } })
  }

  selectNode = (node, pairIndex, modelKey) => {
    const { model, spiceType } = this.state;
    const pairs = handleSelectNode(model, spiceType, node, pairIndex, modelKey);
    this.setState({ model: { ...model, pairs }, nodeVisible: '' })
  }

  clearNodeVisible = () => {
    this.setState({ nodeVisible: "" })
  }

  setNodeVisible = async (visible, pair, pairIndex) => {
    const { subcktList, model: { files }, spiceType } = this.state;
    const _modelKey = getModelKey({ spiceType, files, modelKey: pair.modelKey, pairIndex });
    const fileInfo = pair.node ? pair : files.length > 1 ? files.find(file => file.modelKey === _modelKey) : files[0];
    if (visible && !Object.keys(subcktList[_modelKey] || {}).length && fileInfo) {
      const subcktInfo = await getSubcktByPairInfo(fileInfo);
      this.setState({ subcktList: { ...subcktList, [_modelKey]: subcktInfo } });
    }
    this.setState({ nodeVisible: visible })
  }

  getNodeOption = (spiceType, sharePairs, onlyPairs, modelKey, pairIndex) => {
    const { subcktList, model: { files } } = this.state;
    const _modelKey = getModelKey({ spiceType, files, modelKey, pairIndex });
    const pairs = spiceType === 'only' ? onlyPairs : sharePairs;
    const subckt = subcktList[_modelKey];
    return getNodeSelectOption('rxModel', pairs, subckt)
  }

  updateFileTreeData = (children) => {
    const { fileTreeData } = this.state;
    this.setState({ fileTreeData: fileTreeData.concat(children) })
  }

  SPICERender = () => {
    const { spiceType, model: { files, pairs }, nodeVisible, fileTreeData } = this.state;
    return (<>
      {/* Model File */}
      {files.length !== 0 ? files.map((file, index) => <div key={`${file.libraryId}-${index}` || `rx-model-${index}`}>
        <SPICEFileSelect
          fileTreeData={fileTreeData}
          selectModelFile={(file) => this.selectModelFile(file, file.modelKey || index)}
          selectSubckt={(subckt) => this.selectSubckt(subckt, file.modelKey, file)}
          file={file}
          spiceType={spiceType}
          changeSpiceType={this.changeSpiceType}
          modelFileTitle={spiceType === 'only' ? onlyModelTitle[index] : "Model File"}
          updateFileTreeData={this.updateFileTreeData}
        />
      </div>) : <SPICEFileSelect
        fileTreeData={fileTreeData}
        selectModelFile={(file) => this.selectModelFile(file, -1)}
        selectSubckt={(subckt) => this.selectSubckt(subckt, -1)}
        file={{}}
        spiceType={spiceType}
        changeSpiceType={this.changeSpiceType}
        updateFileTreeData={this.updateFileTreeData}
      />}
      {/* Select Node */}
      <div className="andes-v2-hspice-simulation-rx-model">
        <div className='andes-v2-hspice-simulation-rx-model-title-item'>
          <span>Net</span>
          <span className='andes-v2-hspice-simulation-rx-model-pin'>Pin</span>
          <div className='andes-v2-hspice-simulation-rx-model-node-select'>Node</div>
        </div>
        {pairs.map((item, index) => (
          <div className='andes-v2-hspice-simulation-rx-model-item' key={`rx-model-${item.net}`}>
            {/* net */}
            <span>{item.net}</span>
            <hr />
            {/* pin */}
            <span className='andes-v2-hspice-simulation-rx-model-pin'>{item.pin}</span>
            {/* node */}
            <div className='andes-v2-hspice-simulation-rx-model-node-select'>
              <SubcktNodeSelect
                id={`rx-model-subckt-node-popover-${item.pin}`}
                nodeWidth={100}
                displayPin={item.pin}
                nodeValue={item.node}
                nodeList={this.getNodeOption(spiceType, pairs, [item], item.modelKey, index)}
                selectNode={(node) => this.selectNode(node, index, item.modelKey)}
                visible={nodeVisible === `rx-model-subckt-node-popover-${item.pin}`}
                clickNode={() => this.setNodeVisible(`rx-model-subckt-node-popover-${item.pin}`, item, index)}
                closeNodelSelect={() => this.clearNodeVisible()}
              />
            </div>
          </div>))}
      </div>
    </>)
  }

  applyChange = (e) => {
    this.setState({ applyAll: e.target.checked })
  }

  render() {
    const { applyAll, model: { type } } = this.state;
    const content = (
      <Panel
        className='component-CMC-model-panel'
        position='panel-center-left'
        title='Rx Model'
        zIndex={2000}
        onCancel={() => this.closeModal()}
        width={600}
        draggable
        minHeight={200}
      >
        <div className='andes-v2-hspice-simulation-model-content'>
          {/* Model Type */}
          <div className='andes-v2-hspice-simulation-flex'>
            <span className='andes-v2-hspice-simulation-title'>Model Type</span>
            <Radio.Group onChange={(e) => this.changeModelType(e.target.value)} value={type}>
              <Radio value={IBIS}>IBIS</Radio>
              <Radio value={SPICE}>SPICE</Radio>
            </Radio.Group>
          </div>
          {type === IBIS ? this.IBISRender() : this.SPICERender()}
          {<div className="ads-model-apply-all-content">
            <span>Apply setting to all Signal</span>
            <Checkbox
              checked={applyAll}
              onChange={(e) => this.applyChange(e)}
            />
          </div>}
        </div>
      </Panel >
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default RxModel;