import http from "../http";

/** 
 * create end to end channel
 * 2021.05.10
 * @param {object} data {id:"",name,type, version, ...}
 * @return
 */
export function createAndesV2EndToEndChannel(data) {
  return http.post(`/andes/end-to-end/channel/create`, { ...data });
}

/** 
 * get end to end channel list by projectId
 * 2021.05.10
 * @param {string} projectId
 * @return
 */
export function getAndesV2EndToEndChannelList(projectId) {
  return http.get(`/andes/end-to-end/channel/list?projectId=${projectId}`);
}


/** 
 * get end to end channel content
 * 2021.05.10
 * @param {string} endToEndChannelId 
 * @return
 */
export function getAndesV2EndToEndChannelContent(endToEndChannelId) {
  return http.get(`/andes/end-to-end/channel/detail?id=${endToEndChannelId}`);
}

/** 
 * delete end to end channel
 * 2021.05.10
 * @param {string} endToEndChannelId
 * @return
 */
export function delAndesV2EndToEndChannel(endToEndChannelId) {
  return http.post(`/andes/end-to-end/channel/delete`, { id: endToEndChannelId });
}


/** 
 * update end to end channel
 * @param {object} content
 * @returns
 */

export function updateAndesV2EndToEndChannel(endToEndChannelInfo) {
  return http.post(`/andes/end-to-end/channel/edit`, endToEndChannelInfo)
}

/** 
 * start end to end channel simulationSaga
 * 2021/08/30
 * @param {Array} endToEndChannelIds
 * @return
 */
export function startAndesV2EndToEndSimulation({ endToEndChannelIds, runSeasim, runAds }) {
  return http.post(`/andes/end-to-end/channel/end-to-end/simulation/start_v2`, {
    channelIds: endToEndChannelIds,
    runSeasim,
    runAds
  });
}

/** 
 * end to end channel copy
 * @param {String} channelId 
 * @param {String} name
 * 2021.09.07
*/
export function AndesCopyEndToEndChannel({ name, endToEndChannelId }) {
  return http.post(`/andes/end-to-end/channel/copy`, {
    id: endToEndChannelId,
    name
  })
}

/** 
 * channel copy into end to end channel
 * @param {String} channelId 
 * @param {String} endToEndChannelId
 * 2021.09.23
*/
export function AndesCopyChannelIntoEndToEnd({ channelId, endToEndChannelId, originalChannelId }) {
  return http.post(`/andes/channel/copy-to/end-to-end`, {
    channelId,
    endToEndChannelId,
    originalChannelId
  })
}

/** 
 * get channel list by end to end id 
 * @param {String} endToEndChannelId
 * 2021.09.23
*/
export function AndesGetChannelsByEndToEnd(endToEndChannelId) {
  return http.get(`/andes/channel/end-to-end/list?endToEndId=${endToEndChannelId}`)
}

/**
 * Get end to end channel simulation log
 * 2021/09/03
 * @param {string} verificationId
 * @return
 */
export function getAndesV2EndToEndSimulationLog(verificationId) {
  return http.get(`/andes/end-to-end/channel/complete/log?verificationId=${verificationId}`);
}
