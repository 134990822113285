import {
  ADS_TX,
  ADS_RX,
  MAXIMAL_LENGTH_LFSR,
  USER_DEFINED_LFSR,
  PCIE_3,
  ENCODER_128B130B,
  NO_ENCODER,
  ENCODER_8B10B,
  TMDS_14,
  TMDS_2,
  ENABLE_CTLE_OR_FFE,
  PARALLEL
} from ".";
import { END_TO_END_CHANNEL, PCB_CHANNEL } from "../../../constants/treeConstants";
import { HDMI, PCIE, GENERIC, CPHY } from "../../PCBHelper/constants";

function ADSConfig({
  serdesType,
  controller,
  device,
  signals,
  interfaceType = PCB_CHANNEL,
  controllerChannel = null,
  deviceChannel = null
}) {
  this.controller = controller || "";
  this.device = device || "";
  if (interfaceType === END_TO_END_CHANNEL) {
    this.controllerChannel = controllerChannel || { channelId: "", designId: "", designName: "" };
    this.deviceChannel = deviceChannel || { channelId: "", designId: "", designName: "" };
  }
  if (serdesType === CPHY) {
    this.symbolRate = "2 Gbps";
  }
  this.signals = signals || [];
  this.encoder = getDefaultEncoderByType(serdesType);
  this.prbs = getDefaultPRBSByType(serdesType);
  this.eyeProbe = getDefaultEyeProbe(serdesType);
  this.simulation = new SimulationConfig();
}

function PRBSConfig({ type = "", bitRate = "", clockBitRate = "" }) {
  this.type = type;
  this.bitRate = bitRate;
  if ([TMDS_14, TMDS_2].includes(type)) {
    this.clockBitRate = clockBitRate;
  }
}

function AMISignalPRBSConfig(serdesType) {
  this.mode = serdesType === CPHY ? USER_DEFINED_LFSR : MAXIMAL_LENGTH_LFSR;
  // MAXIMAL_LENGTH_LFSR
  this.registerLength = serdesType === CPHY ? 9 : 8;
  // USER_DEFINED_SEQUENCE
  this.bitSequence = serdesType === CPHY ? '1010101010101' : '1010101010101';
  // USER_DEFINED_LFSR
  this.taps = serdesType === CPHY ? '100011100' : '10001110';
  this.seed = serdesType === CPHY ? '101010100' : '10101010';
}

function EyeProbeConfig() {
  this.errorBitRate = "1e-12";
  this.berList = [
    "1e-12",
    "1e-11",
    "1e-10"];
  this.extrapolateInBitByBitMode = false;
  this.probeRx = true;
  this.probeTx = true;
}

function CPHYEyeProbeConfig() {
  this.vidth = "0.04 V";
  this.vidtl = "-0.04 V";
  this.eyeRampTime = "250 psec";
  this.eyeMidTime = "0 psec";
  this.useDefaultEye = true;
  this.waitTimeInUI = 0.5;
  this.triggeredEye = true;
  this.saveClockSignal = false;
  this.saveWaveform = false;
  this.saveDensity = true;
  this.saveSeparateDensity = false;
  this.saveEyeOpeningHeight = true;
  this.saveEyeOpeningWidth = true;
  this.saveCheckMaskViolation = false;
  this.saveJitterAndUI = true;
  this.saveCheckCphyMaskViolation = true;
}

function SimulationConfig() {
  this.mode = "Bit-by-bit";
  this.numberOfBits = 10000;
  this.statusLevel = 2;
  this.enableUltraLowBerSimulation = false;
  this.tolerance = "Auto";
  this.enforcePassivity = true;
  this.advancedSetting = {
    mirl: 1000,
    mirf: "",
    notppu: 64,
    sopbibibs: 1024,
    awsitp: 1,
    esp: false,
    scr: false
  };
  this.eyeMask = {
    libraryId: ""
  }

}

function AMISignalConfig({
  type = PCB_CHANNEL,
  signalName,
  IbisHasAMI,
  txAmiModel,
  rxAmiModel,
  aggressors = [],
  controller = "",
  controllerChannelId = "",
  device = "",
  deviceChannelId = "",
  txModel,
  rxModel
}) {
  this.signalName = signalName || "";
  this.IbisHasAMI = IbisHasAMI || "yes";//yes no
  if (this.IbisHasAMI === "no") {
    this.txModel = txModel || new AMIModelConfig({ type, component: controller, channelId: controllerChannelId, modelType: "IBIS" });
    this.rxModel = rxModel || new AMIModelConfig({ type, component: device, channelId: deviceChannelId, modelType: "IBIS", modelDir: ADS_RX });
  } else {
    this.txAmiModel = txAmiModel || new AMIModelConfig({ type, component: controller, channelId: controllerChannelId, modelType: "AMI" });
    this.rxAmiModel = rxAmiModel || new AMIModelConfig({ type, component: device, channelId: deviceChannelId, modelType: "AMI" });
  }

  this.aggressors = aggressors;
  this.prbs = new AMISignalPRBSConfig();
}

function AMIModelConfig({
  type = PCB_CHANNEL,
  modelType = "AMI",
  component,
  channelId,
  libraryId,
  libraryName,
  libraryFile,
  ibisComponent,
  pin,
  invPin,
  modelName,
  usePackage,
  dataType,
  setAllData = true,
  amiParameters = [],
  jitters = [],
  useRampData = false,
  waveformType,
  interpolationMode,
  useControllerRate = true,
  EQ,
  modelDir,
  libraryType
}) {
  this.component = component || "";
  if (type === END_TO_END_CHANNEL) {
    this.channelId = channelId || "";
  }
  this.libraryId = libraryId || "";
  this.libraryName = libraryName || "";
  this.libraryFile = libraryFile || "";
  this.libraryType = libraryType || ""; // IBIS / IBIS_AMI
  this.ibisComponent = ibisComponent || "";

  if (modelType === "AMI") {
    this.amiParameters = amiParameters || []; //[ { name, value }]
  }

  if (modelType === "IBIS") {
    this.EQ = EQ || (modelDir === ADS_RX ? { [ENABLE_CTLE_OR_FFE]: "None", DFEType: "None" } : { type: "None" });
  }

  this.pin = pin || "";
  this.invPin = invPin || "";
  this.jitters = jitters || [];  //[ { name, value, unit }]
  this.modelName = modelName || "";
  this.usePackage = usePackage || false;
  this.dataType = dataType || "Typ";
  this.setAllData = setAllData;
  this.useControllerRate = useControllerRate;
  this.interpolationMode = interpolationMode || "linear";
  this.waveformType = waveformType || "Rising/Falling Waveforms"
  this.useRampData = useRampData || false;
}

function TerminationModelConfig({ fileName, libraryId, pairs, subckt, component, type = PCB_CHANNEL, channelId, EQ }) {
  this.fileName = fileName || "";
  this.libraryId = libraryId || "";
  this.pairs = pairs || [];
  this.subckt = subckt || "";
  this.component = component || "";
  if (type === END_TO_END_CHANNEL) {
    this.channelId = channelId || "";
  }
  this.EQ = EQ || { [ENABLE_CTLE_OR_FFE]: "None", DFEType: "None" };
}

function CircuitModelConfig({ type, fileName, libraryId, pairs, subckt, component, interfaceType = PCB_CHANNEL, channelId, topology }) {
  this.type = type || "SPICE";
  this.fileName = fileName || "";
  this.libraryId = libraryId || "";
  this.pairs = pairs || [];
  this.subckt = subckt || "";
  this.component = component || "";
  if (interfaceType === END_TO_END_CHANNEL) {
    this.channelId = channelId || "";
  }
  this.topology = topology || PARALLEL //parallel/series
}

function setDefaultAMIJitters(type, amiParameters) {
  if (type === ADS_TX) {
    return defaultTXAMIJitters(amiParameters);
  }

  if (type === ADS_RX) {
    return defaultRXAMIJitters(amiParameters);
  }
}

function defaultTXAMIJitters(amiParameters) {
  const amiTXJitters = [
    {
      name: "Tx_Sj",
      value: "0",
      unit: "UI"
    },
    {
      name: "Tx_Dj",
      value: "0",
      unit: "UI"
    },
    {
      name: "Tx_Rj",
      value: "0",
      unit: "UI"
    },
    {
      name: "Tx_Sj_Frequency",
      value: "0",
      unit: "GHz"
    },
    {
      name: "Tx_DCD",
      value: "0",
      unit: "UI"
    }
  ];
  return amiTXJitters.filter(item => !amiParameters.find(it => it.name === item.name))
}

function defaultRXAMIJitters(amiParameters) {
  const amiRXJitters = [
    {
      name: "Rx_Sj",
      value: "0",
      unit: "UI"
    },
    {
      name: "Rx_Dj",
      value: "0",
      unit: "UI"
    },
    {
      name: "Rx_Rj",
      value: "0",
      unit: "UI"
    },
    {
      name: "Rx_Noise",
      value: "0",
      unit: "V"
    },
    {
      name: "Rx_DCD",
      value: "0",
      unit: "UI"
    }
  ];
  return amiRXJitters.filter(item => !amiParameters.find(it => it.name === item.name))
}

function setDefaultNoAmiJitters(dir) {
  if (dir === ADS_TX) {
    return defaultTXNoAMIJitters();
  }

  if (dir === ADS_RX) {
    return defaultRXNoAMIJitters();
  }
}

function defaultTXNoAMIJitters() {
  return [{
    name: "Tx_DCD",
    unit: "UI",
    value: "0" //max 1.0,  0 ~1.0
  },
  {
    name: "Tx_Clock_DCD",
    unit: "UI",
    value: "0",//max 1.0, 0 ~1.0
  },
  {
    name: "Tx_PJ_Amplitude",
    unit: "ps",
    value: "0" // 0 ~  1/bitrate
  },
  {
    name: "Tx_PJ_Frequency",
    sort: 0,
    unit: "GHz",
    value: "0"//if Tx_PJ_Amplitude select, no allowed null, 0 ~ 10*bitrate, >10*bitrate give a warning
  },
  {
    name: "Tx_JitterPDF",
    type: "Random", //"Random" / "DJRJ" / "Dual_Dirac"
    sigma: "0", //0 ~ 100 , warning
    unit: "UI",
    /*     min: "", //only DJPJ
        max: "", //only DJPJ */
    /*  mean1: "", //only Dual_Dirac
     mean2: "" // only Dual_Dirac */
  }]
}

function defaultRXNoAMIJitters() {
  return [
    {
      name: "Rx_SJ_Amplitude",
      unit: "UI",
      value: "0" // 0 ~ 1.0 UI
    },
    {
      name: "Rx_Amplitude_Noise",
      unit: "V",
      value: "0" // 0 ~ 10v
    },
    {
      name: "Rx_JitterPDF",
      type: "Random", //"Random" / "DJRJ" / "Dual_Dirac"
      sigma: "0",
      unit: "UI",
      /* min: "", //only DJPJ
      max: "", //only DJPJ */
      /*  mean1: "", //only Dual_Dirac
       mean2: "" // only Dual_Dirac */
    }]
}

function getDefaultEncoderByType(serdesType) {
  switch (serdesType) {
    case PCIE:
      return ENCODER_128B130B; // PCIE 3.0
    case HDMI:
      return ENCODER_8B10B; // TMDS 1.4
    default: return NO_ENCODER;
  }
}

function getDefaultPRBSByType(serdesType) {
  switch (serdesType) {
    case PCIE:
      return new PRBSConfig({ type: PCIE_3, bitRate: "8 Gbps" }); // PCIE 3.0
    case HDMI:
      return new PRBSConfig({ type: TMDS_14, bitRate: "3.4 Gbps", clockBitRate: "340 Mbps" }); // TMDS 1.4
    case GENERIC:
      return new PRBSConfig({ type: GENERIC, bitRate: "5 Gbps" }); //GENERIC
    default:
      return new PRBSConfig({})
  }
}

function getDefaultEyeProbe(serdesType) {
  switch (serdesType) {
    case CPHY:
      return new CPHYEyeProbeConfig();
    default:
      return new EyeProbeConfig();
  }
}

// CPHY
function CPHYIBISSignalConfig({
  type = PCB_CHANNEL,
  signalName,
  controller = "",
  controllerChannelId = "",
  device = "",
  deviceChannelId = "",
  cphyTxModel,
  cphyRxModel,
  snpNodeMap
}) {
  this.signalName = signalName || "";
  this.cphyTxModel = cphyTxModel || new CPHYIBISModelConfig({ type, component: controller, channelId: controllerChannelId, modelType: "IBIS", modelDir: ADS_TX });
  this.cphyRxModel = cphyRxModel || new CPHYIBISModelConfig({ type, component: device, channelId: deviceChannelId, modelType: "IBIS", modelDir: ADS_RX });

  this.snpNodeMap = snpNodeMap || {};
  this.prbs = new AMISignalPRBSConfig(CPHY);
}

const defaultCPHYWaveform = {
  "vHigh": "0.375 V",
  "vMid": "0.25 V",
  "vLow": "0.125 V",
  "riseTimeLowToMid": "10 psec",
  "riseTimeMidToHigh": "10 psec",
  "riseTimeLowToHigh": "10 psec",
  "fallTimeHighToMid": "10 psec",
  "fallTimeMidToLow": "10 psec",
  "fallTimeHighToLow": "10 psec",
  "delayA": "0 psec",
  "delayB": "0 psec",
  "delayC": "0 psec",
  "riseTimeWithinEQSubLevel": "10 psec",
  "fallTimeWithinEQSubLevel": "10 psec",
  "transitReference": 0,
  "edgeShape": 0
}

const defaultCPHYEQ = {
  "EQm1p": "1.75 dB",
  "EQm1m": "1.75 dB",
  "EQh1": "1.75 dB",
  "EQh0": "3.5 dB",
  "EQl1": "1.75 dB",
  "EQl0": "3.5 dB"
}

const defaultCPHYJitter = {
  "enableRJ": false,
  "RJbw": "1 THz",
  "RJrmsClock": "0.0 psec",
  "RJrmsA": "0.0 psec",
  "RJrmsB": "0.0 psec",
  "RJrmsC": "0.0 psec",
  "enablePJ": false,
  "PJwave": 0, // 0=Sinusoid,  1=Square,  2=Triangle
  "PJamp": "0.0 psec",
  "PJfreq": "100 MHz",
  "enableClockDCD": false,
  "clockDCDinUI": 0.0
}

const defaultCPHYTxElectrical = {
  "routA": "50 Ohm",
  "routB": "50 Ohm",
  "routC": "50 Ohm"
}

const defaultCPHYRxElectrical = {
  "rinA": "50 Ohm",
  "rinB": "50 Ohm",
  "rinC": "50 Ohm"
}

function CPHYIBISModelConfig({
  type = PCB_CHANNEL,
  component,
  channelId,
  libraryId,
  libraryName,
  libraryFile,
  ibisComponent,
  usePackage,
  dataType,
  setAllData = true,
  cphyJitter,
  useRampData = false,
  useControllerRate = true,
  enableEQ,
  EQ,
  pinModel,
  modelDir,
  cphyElectrical,
  waveformType,
  specifyIbis,
  libraryType
}) {
  this.specifyIbis = typeof specifyIbis === 'boolean' ? specifyIbis : false;
  this.ibisComponent = ibisComponent || "";
  this.usePackage = usePackage || false;
  this.dataType = dataType || "Typ";
  this.libraryId = libraryId || "";
  this.libraryFile = libraryFile || "";
  this.libraryName = libraryName || "";
  this.libraryType = libraryType || "";

  this.component = component || "";
  if (type === END_TO_END_CHANNEL) {
    this.channelId = channelId || "";
  }
  this.setAllData = setAllData;
  this.useControllerRate = useControllerRate;
  this.useRampData = useRampData || false;

  this.pinModel = pinModel || {};

  this.enableEQ = typeof specifyIbis === 'boolean' ? enableEQ : false;

  this.EQ = EQ || (modelDir === ADS_RX ? { enableCTLE: false } : defaultCPHYEQ);

  // jitters
  if (modelDir === ADS_TX) {
    this.cphyJitter = cphyJitter || defaultCPHYJitter;
    this.cphyWaveform = this.cphyWaveform || defaultCPHYWaveform;
  }

  // cphyElectrical
  this.cphyElectrical = cphyElectrical || (modelDir === ADS_RX ? defaultCPHYRxElectrical : defaultCPHYTxElectrical);

  this.waveformType = waveformType || "Rising/Falling Waveforms"
}


export {
  AMISignalConfig,
  ADSConfig,
  PRBSConfig,
  EyeProbeConfig,
  SimulationConfig,
  AMIModelConfig,
  setDefaultAMIJitters,
  AMISignalPRBSConfig,
  setDefaultNoAmiJitters,
  TerminationModelConfig,
  CircuitModelConfig,
  CPHYIBISSignalConfig,
  CPHYIBISModelConfig
}