import {
  UPDATE_PAGE_TYPE, GET_BMA_CHANNEL_CONTENT_INFO,
  UPDATE_UPLOAD_FILE_LIST, UPDATE_CONTENT_LOADING,
  UPDATE_CHANNEL_CONTENT, UPDATE_GET_FILES_STATUS,
  SAVE_BMA_CHANNEL_CONTENT_INFO, UPDATE_BMA_CONTENT_STATUS,
  CLEAR_ALL_BMA_INFO, UPDATE_DATA_MENU_KEY
} from './actionType';

export const updatePageType = (pageType) => ({
  type: UPDATE_PAGE_TYPE,
  pageType
})

export const getBMAChannelContent = (projectId, id) => ({
  type: GET_BMA_CHANNEL_CONTENT_INFO,
  projectId,
  id
})

export const saveBMAChannelContent = (updateContent, channelId) => ({
  type: SAVE_BMA_CHANNEL_CONTENT_INFO,
  channelId,
  updateContent
})

export const updateChannelInfo = (info) => ({
  type: UPDATE_CHANNEL_CONTENT,
  info
})

export const updateFilesInfo = (projectId, uploadType, list) => ({
  type: UPDATE_UPLOAD_FILE_LIST,
  projectId,
  uploadType,
  list
})

export const updateBMAContentLoading = (loading) => ({
  type: UPDATE_CONTENT_LOADING,
  loading
})

export const updateGetFilesStatus = (status) => ({
  type: UPDATE_GET_FILES_STATUS,
  status
})

export const updateBMAContentStatus = (key, status) => ({
  type: UPDATE_BMA_CONTENT_STATUS,
  status,
  key
})

export const clearAllBMAInfo = () => ({
  type: CLEAR_ALL_BMA_INFO,
})

export const updateDataMenuKey = (key) => ({
  type: UPDATE_DATA_MENU_KEY,
  key
})