import React, { Component, Fragment } from 'react';
import { Input, Tag, Select } from 'antd';
import { versionUpdate } from '@/services/helper/dataProcess';
import auroraDBJson from '../../services/Designs/auroraDbData';
import './index.css';

const Option = Select.Option
const boxShadow = 'rgba(9, 128, 247, 0.14) 0px 0px 0px 2px, rgba(13, 151, 249, 0.1) 0px 0px 7px 0px';
class PkgClassification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      capValue: '',
      className: null,
      COMP_PREFIX_LIB: {},
      components: [],
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount() {
    const { pcbId } = this.props;
    if (pcbId) {
      this.setDefaultData();
    }
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    const { pcbId, update } = this.props;
    if (pcbId !== prevProps.pcbId || (update && update !== prevProps.update)) {
      this.setDefaultData();
    }
  }

  setDefaultData = async () => {
    const { settingStore, pcbId, updateLibraryMenu } = this.props;
    if (pcbId) {
      await this.props.setDefaultData();
      const components = auroraDBJson.getComponents(pcbId);
      const componentSetting = await settingStore.getSetting({ designId: pcbId, updateLibraryMenu });
      const compPrefixLib = componentSetting && componentSetting.compPrefixLib ? componentSetting.compPrefixLib : {}
      this.setState({
        components: [...components.keys()].sort(),
        COMP_PREFIX_LIB: JSON.parse(JSON.stringify(compPrefixLib))
      }, () => {
        this.props.changeLoading(false);
      })
    }
  }

  inputClick = (e, type, ref) => {
    e.stopPropagation();
    if (ref) {
      ref.focus();
    }
    this.setState({
      className: type
    })
  }

  inputChange = (e, valueType) => {
    e.stopPropagation();
    let value = e.target.value;
    if (value === ',') {
      this.setState({
        capValue: '',
        error: ''
      })
      return;
    }
    this.setState({
      error: '',
      [valueType]: value
    })
  }

  onPressEnter = (e, type) => {
    e.stopPropagation();
    const { COMP_PREFIX_LIB } = this.state;
    let value = e.target.value;
    const prev = COMP_PREFIX_LIB[type] ? COMP_PREFIX_LIB[type] : []
    let prevList = [...prev]
    if (!value) {
      return;
    }

    if (value) {
      //Whether the value is legal.
      const errorWords = value.match(/[^0-9a-zA-Z_-]+/g);
      if (errorWords && errorWords.length > 0) {
        this.setState({
          error: 'Value may only contain the following characters: numbers, letters, underscores, minus.',
          capValue: '',
        });
        return;
      }
    }

    if (prevList.includes(value)) {
      return;
    }
    let newList = [...prevList, value];
    let compPrefixLib = this.state.COMP_PREFIX_LIB;
    compPrefixLib[type] = newList;
    this.props.changeSave(true);
    this.setState({
      capValue: '',
      error: '',
      COMP_PREFIX_LIB: compPrefixLib,
    })
  }

  handleDelete = (value, type) => {//delete item
    const { COMP_PREFIX_LIB } = this.state;
    const prev = COMP_PREFIX_LIB[type] ? COMP_PREFIX_LIB[type] : []
    let prevList = [...prev]

    if (!value) {
      return;
    }

    let newList = prevList.filter(item => item !== value);
    let compPrefixLib = this.state.COMP_PREFIX_LIB;
    compPrefixLib[type] = newList;
    this.props.changeSave(true);
    this.setState({
      COMP_PREFIX_LIB: compPrefixLib
    })
  }

  changeSelect = (value, type) => {
    const { COMP_PREFIX_LIB } = this.state;
    let list = Array.isArray(value) ? [...value] : [value];
    this.setState({
      COMP_PREFIX_LIB: { ...COMP_PREFIX_LIB, [type]: list },
    })
  }

  onKeyDown = (e, type) => {
    if (e.keyCode === 188) {
      this.onPressEnter(e, type);
    }
  }

  inputBlur = (e, type) => {
    this.setState({
      className: null
    })
    this.onPressEnter(e, type);
  }

  saveCompPrefix = async () => {
    const { pcbId, settingStore } = this.props;
    const { COMP_PREFIX_LIB } = this.state;
    const _COMP_PREFIX_LIB = { ...COMP_PREFIX_LIB };
    const save = settingStore.compareSetting(pcbId, _COMP_PREFIX_LIB);
    const version = await settingStore.getVersion(pcbId);
    if (save) {
      const newVersion = versionUpdate(version);
      let componentSetting = { compPrefixLib: _COMP_PREFIX_LIB, version: newVersion };
      const newSetting = { designId: pcbId, componentSetting }
      this.props.settingStore.updateSetting([newSetting]);
      return true
    }
    return false
  }

  customArray = () => {
    const { COMP_PREFIX_LIB: { Cap, Die = [], Bga = [], Ipd = [], Ignore = [] }, capValue } = this.state;
    return [
      { type: 'Bga', data: Bga, name: 'BGA', render: this.customPMICItem, selectType: false },
      { type: 'Die', data: Die, name: 'DIE', render: this.customPMICItem },
      { type: 'Cap', data: Cap, value: capValue, valueType: 'capValue', name: 'Capacitor', currentRef: 'capRef', key: 'capInput', render: this.customRLCItem },
      { type: 'Ipd', data: Ipd, name: 'IPD', render: this.customPMICItem },
      { type: 'Ignore', data: Ignore, name: 'Ignore', render: this.customPMICItem },
    ]
  }

  usedComponents = () => {
    const { COMP_PREFIX_LIB: { Die = [], Bga = [], Ipd = [], Ignore = [] } } = this.state;
    return [...Die, ...Bga, ...Ipd, ...Ignore]
  }

  customRLCItem = (rlcItem) => {
    const { error, className } = this.state;
    let { type, data = [], value, name, currentRef, key, valueType } = rlcItem;
    return <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
      <span className='component-RLC-title'>{name} </span>
      <div className='package-RLC-item-content component-RLC-item-content' onClick={(e) => this.inputClick(e, type, this[currentRef])} style={className === type ? { borderColor: '#40a9ff', boxShadow: boxShadow } : {}}>
        <ul className='component-RLC-tags'>
          {data.map((item, index) =>
            <li className='component-RLC-item-tag' key={item}>
              <Tag
                key={item}
                closable={true}
                onClose={() => this.handleDelete(item, type)}
              >
                {item}
              </Tag>
            </li>
          )}
          <li className='component-RLC-item-input-box' key={key}>
            <Input
              className='component-RLC-item-input'
              value={value}
              style={{ width: value ? value.length * 12 : '.75em' }}
              ref={(input) => { this[currentRef] = input; }}
              onChange={(e) => this.inputChange(e, valueType)}
              onPressEnter={(e) => this.onPressEnter(e, type)}
              onBlur={(e) => this.inputBlur(e, type)}
              onKeyDown={(e) => this.onKeyDown(e, type)}
            />
          </li>
        </ul>
      </div>
    </div>
  }

  customPMICItem = (rlcItem) => {
    const { error, components } = this.state;
    const { type, data, name, selectType = 'multiple' } = rlcItem;
    const used = this.usedComponents();
    return <div className='component-RLC-item' style={{ height: error ? '25%' : '33.33%' }} key={name}>
      <span className='component-RLC-title'>{name} </span>
      <Select
        onChange={(value) => this.changeSelect(value, type)}
        value={selectType ? data : data[0] || ''}
        dropdownStyle={{ zIndex: 100000 }}
        mode={selectType}
        showSearch={true}
        className='component-PMIC-item-content'
      >
        {components.filter(item => !used.includes(item)).map(item => <Option title={item} key={item} value={item}>{item}</Option>)}
      </Select>
    </div>
  }

  render = () => {
    const { error } = this.state;
    const list = this.customArray();
    return <Fragment>
      <div className={`component-animation-classfication`}>
        <div className={`component-RLC-sub-title`}>Custom Reference Setting</div>
        {
          list.map(item => {
            return <div className={`component-RLC-sub-content`} key={item.name}>
              {item.render(item)}
            </div>
          })
        }
      </div>

      {error && <div className='component-RLC-item' style={{ height: '25%' }}>
        <span className='model-name-error-msg'>{error}</span>
      </div>}
    </Fragment>
  }
}

export default PkgClassification;