import { CascadeCompPrefixVersion } from '@/services/Cascade/helper/setupData'
function IRTableDatas() {
  this.IRTableData = [];
  this.allComponents = [];
  this.irLoadSelect = [{ name: 'load_0', comp: '' }];
  this.COMP_PREFIX_LIB = new CascadeCompPrefixVersion('0.0.0');
  this.detailMessage = []
}

export function IRTableData({
  components = [],
  nominal = '',
  loads = [],
  gndNets = [],
  powerNets = [],
  extendNets = [],
  maxBgaDv = '0.05',
  maxBgaI = '1',
  maxVia = '1',
  vrmComps = [],
  id = '',
  inductance = {},
  detail = []
}) {
  this.components = components;
  this.nominal = nominal;
  this.loads = loads;
  this.gndNets = gndNets;
  this.powerNets = powerNets;
  this.extendNets = extendNets;
  this.maxBgaI = maxBgaI
  this.maxVia = maxVia
  this.maxBgaDv = maxBgaDv;
  this.vrmComps = vrmComps;
  this.id = id;
  this.allPowerNet = [...powerNets, ...extendNets];
  this.inductance = inductance;
  this.detail = detail;
}

IRTableData.prototype.addLoad = function (key, value) {
  this[key] = value;
}

export function LoadData(name, comp) {
  this.name = name;
  this.comp = comp
}

IRTableDatas.prototype.addIRTable = function () {
  this.IRTableData.push(new IRTableData({}))
}

IRTableDatas.prototype.toIrTableData = function (data, compPrefixLib, irLoadSelect = []) {
  let _irLoadSelect = [new LoadData(`load_0`, '')], irLoadSelectComp = [], _components = [], _allComponents = [], _allComponentsName = [], _tableData = [];
  // have only one load
  if (data.length) {
    _tableData = data.map(item => {
      const { content, id } = item
      let _IRTableData = new IRTableData({ ...content, id })
      if (content.loads.length) {
        _IRTableData.addLoad('load_0', content.loads[0].current)
        _irLoadSelect = [new LoadData(`load_0`, content.loads[0].comp)]
      } else {
        _IRTableData.addLoad(`load_0`, '')
      }
      _components = [..._components, ...content.components];
      return _IRTableData
    })

    // Remove duplicates from components
    _components.forEach((item, next) => {
      if (!_allComponentsName.includes(item.name)) {
        _allComponents.push(item)
        _allComponentsName.push(item.name)
      }
    });
  }
  this.allComponents = _allComponents;
  this.IRTableData = _tableData;
  this.irLoadSelect = _irLoadSelect;
  this.COMP_PREFIX_LIB = new CascadeCompPrefixVersion(compPrefixLib.version);
}

IRTableDatas.prototype.addLoadColumns = function (data = [], irLoadSelect = []) {
  let _irLoadSelect = [...irLoadSelect]
  data.forEach(item => {
    const addKey = `load_${irLoadSelect.length}`;
    item[addKey] = '';
  })
  _irLoadSelect.push(new LoadData(`load_${irLoadSelect.length}`, ''))
  this.IRTableData = data;
  this.irLoadSelect = _irLoadSelect;
}



export default IRTableDatas;

