import { call, put, takeEvery, delay, select, takeLatest, fork, cancel, take } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { updateSimulationReducer, updateCurrentSimulationInfo, updateCurrentSimulationPartInfo, getWorkflowMonitor, getVerificationLog } from './action';
import {
  RUN_BMA_CHANNEL, GET_CHANNEL_WORKFLOW,
  GET_WORKFLOW_MONITOR, GET_VERIFICATION_LOG,
  CANCEL_WORKFLOW, GET_VERIFICATION_WORKFLOW
} from './actionType';
import {
  checkVerificationStatus,
  getMonitor,
  getVerificationWorkFlow,
  workflowWaitingIndex,
  getRunningWorkFlowPromise
} from '@/services/workflow/workflow';
import getProfileData, { parseProfile, shouldGetProfile } from '@/services/helper/profileCtrl';
import { fakeProgress } from '@/services/helper/dataProcess';
import { getWorkFlow, cancelVerificationWorkflow } from '@/services/api/v2/workflowCtrl';
import monitorLog from '@/services/helper/monitorLog';
import { geSimulationLogPromise, runBMAChannelInfoPromise } from '../../../../services/BMA';
import * as taskStatus from '@/constants/workflowStatus';
import { ROCKY } from '../../../../constants/pageType';
import { BMA_CHANNEL } from '../../../../constants/treeConstants';
import { VERIFY_NEVER, VERIFY_SUCCESS } from '../../../../constants/verificationStatus';


let workflowTask = null;
function* runBMAChannel(action) {
  const { runSelectList } = action;
  let currentVerificationId = ""
  const { BMAReducer: { channel: { content }, simulation } } = yield select()
  const verificationId = content && content.verificationId ? content.verificationId : "";
  currentVerificationId = verificationId;

  const selectInputFileIds = content && content.selectInputFileIds ? content.selectInputFileIds : [];
  // if (!verificationId || !selectInputFileIds.length) { return }
  let newSimulationReducer = simulation ? JSON.parse(JSON.stringify(simulation)) : {};

  const verificationList = [verificationId];
  verificationList.forEach(item => {
    delete newSimulationReducer[item];
    const time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    newSimulationReducer[item] = {
      startMsg: `==> ${waitingTime} Checking setup...`,
    }
  })

  yield put(updateSimulationReducer(newSimulationReducer));

  try {
    yield call(runBMAChannelInfoPromise, { verificationId, selectInputFileIds, simulationTypes: runSelectList })
  } catch (error) {
    verificationList.forEach(item => {
      let msg = `==> ${error}`;
      newSimulationReducer[item] = { endMsg: msg };
    })
    yield put(updateSimulationReducer(newSimulationReducer));
  }

  const verificationStatus = yield call(getVerificationWorkFlow, currentVerificationId);
  if (verificationStatus && verificationStatus.length > 0) {
    if (workflowTask) {
      yield cancel(workflowTask);
    };
    workflowTask = yield fork(getChannelWorkflow, { verificationId: currentVerificationId, verificationWork: verificationStatus });
    return;
  };
}

export function* startNewWorkflow(action) {
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  workflowTask = yield fork(getChannelWorkflow, { ...action });
}

function* getChannelWorkflow(action) {
  const { verificationWork, verificationId } = action;
  if (!verificationWork || !verificationWork.length) {
    yield put(updateCurrentSimulationPartInfo({ verificationId, info: { progress: -1 } }));
    return
  }

  let { id, status, progress, taskList } = verificationWork[0];
  const getProfile = shouldGetProfile(taskList);

  const { BMAReducer: { simulation } } = yield select();
  const _simulation = { ...simulation }
  _simulation[verificationId] = {
    log: null,
    startMsg: "==> Start simulating...",
    progress,
    endMsh: null,
    monitor: null
  }

  yield put(updateSimulationReducer(_simulation))

  try {
    const stopWaiting = yield call(getWaitingIndex, { verificationId, id });
    if (stopWaiting) {
      return;
    }

    if (progress === 0) {
      progress += 2;
    }

    yield put(updateCurrentSimulationPartInfo({
      verificationId,
      info: {
        startMsg: "==> Simulation running...\n==> Data processing...",
        progress
      }
    }));

    // simulation running
    const stopWorkflowPolling = yield call(getSimulationRunning, { status, verificationId, id, progress, getProfile })

    if (stopWorkflowPolling) {
      return;
    }

    yield put(updateCurrentSimulationPartInfo({ verificationId, info: { progress: status === taskStatus.SUCCEED ? 100 : -1 } }));

    yield put(getWorkflowMonitor(id, verificationId));

    // simulation complate
    yield put(updateCurrentSimulationPartInfo({
      verificationId, info: {
        progress: -1,
        startMsg: null,
        endMsg: null
      }
    }));

    yield delay(3000);
    yield put(getVerificationLog(verificationId));
  } catch (error) {
    console.error(error)
  }
}

function* cancelWorkflowJudge({ verificationId }) {
  const { LoginReducer: { pageType }, RockyReducer: { project: { contentType } }, BMAReducer: { id } } = yield select();

  if (pageType === ROCKY && contentType === BMA_CHANNEL && verificationId === id) {
    return true;
  }
  return false;
}

function* getWaitingIndex(action) {
  const { verificationId, id } = action;
  let waitingIndex = -1, time;
  waitingIndex = yield call(workflowWaitingIndex, id);
  while (waitingIndex >= 0) { // wait
    const cancelWait = yield call(cancelWorkflowJudge, { verificationId })
    if (cancelWait) {
      return true;
    }
    time = new Date();
    time.setUTCSeconds(time.getUTCSeconds());
    let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
    yield put(updateCurrentSimulationPartInfo({
      verificationId,
      info: { waitingIndex, waitingTime }
    }));

    yield delay(3000);
    waitingIndex = yield call(workflowWaitingIndex, id);
  }

  while (waitingIndex === -2) { //simulation cancel
    yield put(updateCurrentSimulationPartInfo({
      verificationId,
      info: {
        waitingIndex: -1,
        waitingTime: null,
        startMsg: null,
        progress: -1,
        endMsg: null
      }
    }))
    yield delay(3000);
    yield put(getWorkflowMonitor(id, verificationId));//get log
    return;
  }

  while (waitingIndex === -3) { //simulation complate
    const promise = yield call(checkVerificationStatus, verificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(updateCurrentSimulationPartInfo({ verificationId, info: { resultExist } }));
    return;
  }

  yield put(updateCurrentSimulationPartInfo({
    verificationId,
    info: {
      waitingIndex: -1,
      waitingTime: null
    }
  }));
}

function* getSimulationRunning(action) {
  let { status, verificationId, id, progress, getProfile } = action
  let indexNum = 0;
  while (status === taskStatus.RUNNING) {
    const stopWorkflowPolling = yield call(cancelWorkflowJudge, { verificationId });
    if (stopWorkflowPolling) {
      return true;
    }

    yield put(getWorkflowMonitor(id, verificationId));

    yield delay(5000);

    let _progress = progress;

    try {
      const { data: { data } } = yield call(getWorkFlow, id);
      if (!data) {
        status = taskStatus.FAILED;
      } else {
        status = data.status;
        _progress = data.progress;
      }

      if (status !== taskStatus.RUNNING) {
        // yield call(getProfileLog, { verificationId });
      }
    } catch (error) {
      console.error(error)
    }

    indexNum += 1;
    progress = fakeProgress(progress, indexNum, _progress);
    yield put(updateCurrentSimulationPartInfo({ verificationId, info: { progress } }));
  }
}


function* getMonitorLog(action) {
  const { workflowId, verificationId } = action;
  try {
    const log = yield call(getMonitor, workflowId)
    if (log.length > 0) {
      yield put(updateCurrentSimulationPartInfo({ verificationId, info: { monitor: monitorLog(log) } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* _getVerificationLog(action) {
  const { verificationId } = action;
  let _log = '', isLog = false;
  try {
    const promise = yield call(checkVerificationStatus, verificationId);
    if (promise && promise.status) {
      if (promise.status !== VERIFY_NEVER) {
        isLog = true;
      }
    }
  } catch (error) {
    console.error(error);
    return;
  }

  if (isLog) {
    try {
      let log = yield call(geSimulationLogPromise, verificationId)
      if (log) {
        _log = log
      }
      // yield call(getProfileLog, { verificationId });
    } catch (error) {
      console.error(error)
    }
  }

  yield put(updateCurrentSimulationPartInfo({ verificationId, info: { log: _log, monitor: null } }))
}

function* cancelWorkflow(action) {
  const { verificationId } = action;
  try {
    yield call(cancelVerificationWorkflow, verificationId);
    yield put(updateCurrentSimulationPartInfo({
      verificationId,
      info: {
        startMsg: null,
        endMsg: "==> Simulation cancelled!",
        progress: -1
      }
    }));
    yield delay(5000);
    yield put(updateCurrentSimulationPartInfo({ verificationId, info: { endMsg: null } }));
  } catch (error) {
    console.error(error);
  }
}

function* getVerificationWorkflow(action) {
  const { verificationId } = action;
  try {
    yield call(cleanSimulationInfo, verificationId);
    const verificationStatus = yield call(getVerificationWorkFlow, verificationId);
    if (verificationStatus && verificationStatus.length > 0) {
      yield call(startNewWorkflow, {
        verificationId,
        verificationWork: verificationStatus
      })
    } else {
      yield put(getVerificationLog(verificationId));
    }

  } catch (error) {
    console.error(error)
    yield call(cleanSimulationInfo, verificationId);
  }
}

function* cleanSimulationInfo(verificationId) {
  yield put(updateCurrentSimulationInfo({
    verificationId,
    info: {
      startMsg: null,
      endMsg: null,
      progress: -1,
      waitingIndex: -1,
      waitingTime: null
    }
  }));
}

function* BMASimulationSaga() {
  yield takeEvery(RUN_BMA_CHANNEL, runBMAChannel);
  yield takeEvery(GET_CHANNEL_WORKFLOW, getChannelWorkflow);
  yield takeEvery(GET_WORKFLOW_MONITOR, getMonitorLog);
  yield takeEvery(GET_VERIFICATION_LOG, _getVerificationLog);
  yield takeEvery(CANCEL_WORKFLOW, cancelWorkflow);
  yield takeEvery(GET_VERIFICATION_WORKFLOW, getVerificationWorkflow);
}
export default BMASimulationSaga;