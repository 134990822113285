import http from '../http';

/**
 * get Rocky project list
 * 2020/06/01
 * 
 * @export
 * @returns
 */
export function getRockyProjectList() {
  return http.get(`/project/rocky`);
}


/**
 * Delete project
 * 2020/06/01
 * @export
 * @param {arr} ids Project ID list
 * @returns
 */
export function deleteRockyProjectsByIDs(ids) {
  return http.delete(`/project/rocky`, {
    data: ids
  })
}

/**
 * Create Rocky project
 *
 * @export
 * @param {String} name Project name
 * @param {string} version project_version
 * @param {string} ddr_type "DDR3"/"DDR4"/"LPDDR4"/"DDR4L"/"DDR4L"/"DDR3L"
 * @returns 
 */
export function createProject({ name, version, ddrType, rockyVersion }) {
  return http.post(`/project/rocky`, {
    name,
    ddrType,
    version,
    rockyVersion
  })
}

/**
 * get project config
 * @export
 * @param  {string} projectId
 * @returns 
 */
export function getRockyProjectConfig(projectId) {
  return http.get(`/my-project/project/${projectId}/option`);
}

/**
 * update channel config
 * @export
 * @param  {string} channelId
 * @param {object} config{clock,timeStep,cycles,simulator,corners}
 * @returns 
 */
export function updateRockyChannelConfig({ channelId, params }) {
  return http.put(`/channel/${channelId}/rocky/config`, {
    ...params
  });
}

/**
 * get channel config
 * @export
 * @param  {string} channelId
 * @returns 
 */
export function getRockyChannelConfig(channelId) {
  return http.get(`/channel/${channelId}/rocky/config`);
}


/**
 * get project report, response: file
 * @export
 * @param  {string} projectId
 * @param  {string} format
 * @returns 
 */
export function getRockyReport(projectId, format) {
  return http.get(`/project/${projectId}/report/${format}`, {
    responseType: 'arraybuffer'
  });
}

/**
 * Generate rocky report
 *
 * @export
 * @param {*} param
 * @returns
 */
export function generateRockyReportNew({ projectId, param }) {
  return http.post(`/project/${projectId}/rocky/report`, param);
}

/**
 * Get rocky report status
 *
 * @export
 * @param {string} projectId
 * @returns
 */
export function getRockyReportStatus(projectId) {
  return http.get(`/project/${projectId}/rocky/report/status`);
}

/**
 * Get rocky report config
 *
 * @export
 * @param {string} projectId
 * @returns
 */
export function getRockyReportConfig({ projectId, type }) {
  return http.get(`/project/${projectId}/rocky/report/config?type=${type}`);
}

/**
 * Get rocky report config
 *
 * @export
 * @param {string} projectId
 * @returns
 */
export function saveRockyReportConfig({ projectId, config }) {
  return http.post(`/project/${projectId}/rocky/report/config`, config);
}

/**
 * rocky project copy
 * 2020/11/24
 *
 * @export
 * @param {String} projectId Project id
 * @param {String} projectName new name
 * @returns
 */
export function rockyCopyProject({ projectId, projectName }) {
  return http.post(`/project/${projectId}/rocky/copy?projectName=${projectName}`);
}

/**
 * rocky project rename
 * 2020/11/25
 *
 * @export
 * @param {String} projectId Project id
 * @param {String} projectName new name
 * @returns
 */
export function rockyRenameProject({ projectId, projectName }) {
  return http.post(`/project/${projectId}/rocky/rename?projectName=${projectName}`);
}

/**
 * Update channel extraction config
 *
 * @export
 * @param {string} channelId 
 * @param {object} extraction
 * @returns
 */
export function updateRockyChannelExtractionConfig({ channelId, extraction }) {
  return http.put(`/channel/${channelId}/rocky/extraction/config`,
    {
      extraction
    }
  )
}

/**
 * create rocky channel pdn
 *
 * @export
 * @param {string} channelId
 * @param {object} pdnContent
 * @returns
 */
export function CreateChannelPDN({ channelId, designId, projectId, pdnContent, version }) {
  return http.post(`/rocky_pdn`, {
    channelId,
    designId,
    projectId,
    verificationName: 'PDN',
    content: pdnContent,
    rockyPDNName: 'PDN',
    version
  });
};

export function getDesignsChannelInProject(projectId) {
  return http.get(`/project/${projectId}/rocky/new`);
};

export function getVerificationsInChannel(channelId) {
  return http.get(`/project/channel/${channelId}/rocky`);
}

/**
 * get project pcb interface.json
 *
 * @export
 * @param {string} channelId
 * @returns
 */
export function getRockyChannelJson(projectId) {
  return http.get(`/debug/rocky/project/${projectId}/json`);
};

/**
 * Get project eye mask option
 *
 * @export
 * @param {*} projectId project id
 * @returns
 */
export function getProjectEyeMaskOption(projectId) {
  return http.get(`/project/rocky/eyemask/option/detail`, {
    params: { projectId }
  })
};

/**
 * Edit project eye mask option
 *
 * @export
 * @param {*} data -{ eyemaskId, eyemaskName, origin, projectId }
 */
export function editProjectEyeMaskOption(data) {
  return http.post(`/project/rocky/eyemask/option/edit`, data);
}

/**
 * get channel interface.json by designId, response: file
 *
 * 20230213
 * @export
 * @param {string} designId
 * @returns
 */
export function getRockyChannelJsonByDesignId(designId) {
  return http.get(`debug/rocky/design/interface-json?designId=${designId}`);
}

export function getPcbAdvancedCtrl(designId) {
  return http.get(`/my-project/design/${designId}/extraction/additional-nets`)
}

export function updatePcbAdvancedCtrl({ designId, additionalNets }) {
  return http.post(`/my-project/design/${designId}/extraction/additional-nets`, additionalNets)
}