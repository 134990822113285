import React, { Component, Fragment } from "react";
import { TableOutlined, DownOutlined, RightOutlined, LoadingOutlined, EyeOutlined } from '@ant-design/icons';
import { Checkbox, ColorPicker, Tooltip } from 'antd';
import { getMultiRowParallelData } from '@/services/Sierra'
import { getTextWidth } from "../../services/helper/getTextWidth";

const CheckboxGroup = Checkbox.Group;

/**
 *  ---- Data ----
 *  list - [{ rowName: "Port 1 (Diff_HDMI_LANE0_P6)", children: [{ color: "#15a5ea", id: "597125011354030080::0::0", name: "S(1, 1) ", value: "" }] }]
 *  show - List of expanded row name ["Port 1 (Diff_HDMI_LANE0_P6)"]
 *  rowId - file row id
 *  fileId - Optional, file id
 *  portSelect - Map of row name and selected curves { Port 1(Diff_HDMI_LANE0_P6): ["597125011354030080::0::0"] }
 */
class ResultCheckboxGroup extends Component {
  constructor(props) {
    super()
  }

  stopPropagation = (e) => {
    e && e.stopPropagation()
    e && e.preventDefault()
  }

  getResultCheckbox = ({ id, color, name, value, index, label, groupName, selected, rowName, child }) => {
    const { experimentList, designs = [], ResultPostProcess, isPostProcess, changePostProcessVisible, currentPostProcessData, recalc, sweepReCalc, sweepTimeShowList } = this.props;
    const isParallel = experimentList && experimentList.length ? true : false;
    let multiRowParallelData = [], indeterminate = false, allChecked = false;
    if (isParallel) {
      const data = getMultiRowParallelData(id, selected, groupName, color, experimentList);
      multiRowParallelData = data.multiRowParallelData;
      indeterminate = data.indeterminate;
      allChecked = data.allChecked;
    }
    const isTableHighlightClassName = isPostProcess && child.postProcessData && currentPostProcessData && currentPostProcessData.title === child.postProcessData.title && currentPostProcessData.signal === child.postProcessData.signal ? "aurora-result-highLight-table-icon" : "";
    const pcbColor = designs.find(item => item.name === child.pcb);
    const currentValueWidth = value ? getTextWidth(value, 14, 100, "bold") + 10 : 10;
    const labelWidth = `calc(100% - ${(14 + currentValueWidth)}px)`;
    return <div key={index} className={isParallel ? 'sparameter-sweep-result-group' : ''}>
      {isParallel ?
        <div className='sparameter-result-checkbox-group-name sparameter-result-checkbox-group sparameter-result-sweep-group'>
          {/* <label htmlFor={`col::${id}`}>{name}</label> */}
          <Checkbox
            value={id}
            className='sparameter-sweep-checkbox-group'
            indeterminate={indeterminate}
            checked={allChecked}
            onChange={(e) => this.props.changeAllPorts(allChecked, multiRowParallelData, rowName)}
          />
          {label ? <label>{label}</label> :
            <label>
              <Tooltip title={child.usage ? `${child.usage} - ${name} - ${child.pcb}` : name} overlayClassName='aurora-tooltip'>
                {child.usage && <span className="checkbox-group-usage">{child.usage}</span>}
                <span className="checkbox-group-title" style={pcbColor && pcbColor.color ? { color: pcbColor.color } : {}}>{name}</span>
              </Tooltip>
            </label>}
          <span className='result-curve-current' style={{ color }}>{isNaN(parseFloat(value)) ? '' : value}</span>
        </div>
        :
        <div className={isPostProcess ? "result-checkbox-group-name result-checkbox-group-name-flex" : "result-checkbox-group-name "}>
          <Checkbox
            value={id}
            className={isPostProcess ? 'sparameter-result-checkbox-group sparameter-result-checkbox-group-left' : 'sparameter-result-checkbox-group'}
          >
            <span className='sparameter-result-checkbox-group-name'>
              <ColorPicker
                value={color}
                className='aurora-color-picker-small'
                onChange={(value) => this.props.colorChange(value, id)}
                size='small'
                onClick={(e) => this.stopPropagation(e)}
                id={`col::${id}`}
              />
              {/* <label htmlFor={`col::${id}`}>{name}</label> */}
              {label ? <label
                htmlFor={`col::${id}`}
                style={{ width: labelWidth }}
              >{label}</label> :
                <label htmlFor={`col::${id}`}
                  style={{ width: labelWidth }}
                >
                  <Tooltip title={child.usage ? `${child.usage} - ${name} - ${child.pcb}` : name} overlayClassName='aurora-tooltip'>
                    {child.usage && <span className="checkbox-group-usage">{child.usage}</span>}
                    <span style={pcbColor && pcbColor.color ? { color: pcbColor.color, } : {}} className="checkbox-group-name">{name}</span>
                    {(!child.notShowPcbName && child.pcb) && <span className="checkbox-group-pcb">{child.pcb}</span>}
                  </Tooltip>
                </label>
              }
              <span className='result-curve-current' style={{ color }}>{isNaN(parseFloat(value)) ? '' : value}</span>
            </span>
            {(isPostProcess && child.postProcessData && Object.keys(child.postProcessData).length && changePostProcessVisible) ?
              (recalc ? <LoadingOutlined className="checkbox-group-postProcess-icon" title="Recalculating..." /> :
                <TableOutlined onClick={(e) => { changePostProcessVisible(e, '', child.postProcessData, rowName, id) }}
                  className={`checkbox-group-postProcess-icon ${isTableHighlightClassName}`}
                />) : null
            }
          </Checkbox>
          {ResultPostProcess && isPostProcess && ResultPostProcess(child.postProcessData)}
        </div>
      }
      {isParallel && multiRowParallelData.map((item, index) => {
        const { name, id, _id, checked, selectList, fileId } = item;
        const _value = child.currentValue ? child.currentValue[name] : "";
        const isParallelTableHighlightClassName = isPostProcess && child.postProcessData && currentPostProcessData && currentPostProcessData.curveName === item._id && currentPostProcessData.signal === child.postProcessData.signal ? "aurora-result-highLight-table-icon" : "";
        return <Fragment key={id}>
          <div
            className='sparameter-sweep-checkbox'
            style={index === 0 ? { marginLeft: 230 } : {}}
          >
            {groupName.includes(name) && color[name] && <Fragment>
              <Checkbox value={_id} checked={checked} onChange={(e) => this.props.changePort(selectList, rowName, `${name}@${fileId}`)} />
              <ColorPicker
                className='aurora-color-picker-small sweep-color-select'
                onChange={(value) => this.props.colorChange(value, _id, child.curveIndex)}
                size='small'
                value={color[name]}
                onClick={(e) => this.stopPropagation(e)}
                style={{ overflow: 'visible' }}
                id={`col::${id}`}
              />
              <span className='result-curve-current' style={{ color: color[name] }}>{isNaN(parseFloat(_value)) ? '' : _value}</span>
              {(isPostProcess && child.postProcessData && Object.keys(child.postProcessData).length && changePostProcessVisible) ?
                sweepReCalc && sweepReCalc.includes(id) ? <LoadingOutlined className="sparameter-sweep-table-icon" title="Recalculating..." />
                  : <TableOutlined className={`sparameter-sweep-table-icon ${isParallelTableHighlightClassName}`} onClick={(e) => { changePostProcessVisible(e, 'sweep', child.postProcessData, rowName, _id) }} style={{ verticalAlign: 'middle' }} /> : null
              }
            </Fragment>}
          </div>
          {(isPostProcess && ResultPostProcess && (sweepTimeShowList || []).includes(name)) ? ResultPostProcess(child.postProcessData) : null}
        </Fragment>
      })}
    </div>
  }

  render() {
    const { list, show, rowId, fileId, portSelect, isCheckAll, rowNameClick, changeSelectAll, changePort, customizeTitle, experimentList, verticalListData = [], changeSelectSignalAll, multiFiles, postProcessTitle, isPostProcess, showOptResult, optList = [], sweepTimeShowList } = this.props;
    const isParallel = experimentList && experimentList.length ? true : false;
    return (
      <Fragment>
        {list && list.length > 0 ?
          list.map((group, index) => {
            const { checkedAll, indeterminate } = isCheckAll(group, fileId, isParallel, verticalListData);
            const displayName = group.displayName ? group.displayName : group.rowName;
            return <Fragment key={group.rowName}>
              <div className={isParallel ? 'sparameter-channel-port-name sparameter-channel-display-inline-flex' : 'sparameter-channel-port-name'}>
                {changeSelectAll && <Checkbox
                  key={group.rowName}
                  checked={checkedAll}
                  indeterminate={indeterminate}
                  onChange={(e) => changeSelectAll(e, group, fileId, verticalListData)}
                  className={isPostProcess ? 'sparameter-channel-port-name-checkbox sparameter-channel-port-name-post-checkbox' : 'sparameter-channel-port-name-checkbox'}
                />}
                <div
                  onClick={() => rowNameClick(group.rowName, rowId)}
                  className={isParallel ? `sparameter-channel-display-row sparameter-sweep-channel-display-row` : isPostProcess ? 'sparameter-sweep-channel-post-display-row sparameter-channel-display-row' : 'sparameter-channel-display-row'}
                  style={isParallel ? (multiFiles ? { paddingLeft: 50 } : { paddingLeft: 0 }) : {}}
                >
                  {show.includes(group.rowName) ? <DownOutlined className='sparameter-file-expand-icon' /> : <RightOutlined className='sparameter-file-expand-icon' />}
                  <span title={displayName}>
                    {displayName}
                    {optList.includes(group.power) && <Tooltip title={'Optimization'} overlayClassName='aurora-tooltip'>
                      <EyeOutlined className="result-opt-icon" onClick={(e) => showOptResult(e, group.power)} />
                    </Tooltip>}
                  </span>
                  {/* <span>{customizeTitle ? customizeTitle(group.children[0], 'rowName') : group.rowName}</span> */}
                </div>
                {isParallel && verticalListData.map((item, index) => {
                  const data = item.dataList.find(item => item.signal === group.rowName)
                  if (data) {
                    return <Fragment key={item.name}>
                      <span
                        className='sparameter-result-menu-title sparameter-sweep-checkbox sparameter-signal-sweep-checkbox'
                        style={index === 0 ? { marginLeft: 230 } : {}}>
                        <Checkbox
                          indeterminate={data.indeterminate}
                          onChange={(e) => changeSelectSignalAll(data)}
                          checked={data.allChecked}
                        ></Checkbox>
                      </span>
                      {isPostProcess && postProcessTitle && (sweepTimeShowList || []).includes(item.name) ? <div className="sparameter-sweep-post-title-box">{postProcessTitle('', 'sweep')}</div> : null}
                    </Fragment>
                  }
                })}
                {!isParallel && isPostProcess && postProcessTitle ? postProcessTitle() : null}
              </div>
              {show.includes(group.rowName) && <CheckboxGroup
                value={portSelect[group.rowName]}
                onChange={(values) => !isParallel ? changePort(values, group.rowName, fileId) : false}
                style={{ width: "100%" }}
              >
                {group.children.map((item, index) => {
                  const { id, color, name, value } = item;
                  const groupName = group.groupName ? group.groupName : [];
                  return this.getResultCheckbox({
                    id,
                    color,
                    name,
                    value,
                    index,
                    label: customizeTitle ? customizeTitle(item) : null,
                    groupName,
                    selected: portSelect[group.rowName],
                    rowName: group.rowName, child: item
                  });
                })}
              </CheckboxGroup>}
            </Fragment>
          }
          ) : null}
      </Fragment>
    );
  }
}

export default ResultCheckboxGroup;
