import {
  UPDATE_PROJECT_MENU,
  ADD_PROJECT,
  UPDATA_TREE_LIST,
  CREATE_PROJECT,
  CLEAR_CURRENT_PROJECT,
  UPDATE_SELECTED_KEYS,
  UPDATE_EXPAND_KEYS,
  OPEN_PROJECT,
  CLOSE_PROJECT,
  SAVE_OPEN_PROJECT_INFO,
  OPEN_PAGE,
  UPDATE_VIEW_LIST,
  DELETE_PROJECT,
  DELETE_PCB,
  PCB_REPLACE,
  UPDATE_PAGE_LAYOUT,
  ADD_VERIFICATION,
  CANCEL_CREARTE_VERIFICATION,
  CREATE_VERIFICATION,
  DELETE_VERIFICATION,
  CHANGE_RENAME_STATUS,
  RENAME_VERIFICATION,
  COPY_VERIFICATION,
  UPDATE_LIST_STATUS,
  UPDATE_TRASH,
  RENAME_PROJECT,
  UPDATE_DESIGN_STATUS,
  UPDATE_CHANGE_PCB_LOG,
  UPDATE_UPLOAD_TEMPLATE_LOG,
  UPDATE_PCB_LAYOUT,
  LIBRARY_DEFAULT,
  UPDATA_DEFAULT_DECAP,
  UPDATE_LIBRARY_MODEL_SAVE,
  CHANGE_PCB_UPDATE_STATUS,
  UPDATE_SIMULATION_LIST,
  NEW_SIMULATION_CHANNEL,
  UPDATE_STACKUP_LIST,
  UPDATE_REPORT_INFO,
  SAVE_REPORT_CONFIG,
  GET_REPORT,
  CLEAR_REPORT_INFO,
  SAVE_PREVIEW_STATUS,
  FORCE_PREVIEW_SHOW,
  COMPONENT_SETTING_VISIBLE,
  ROOT_PANEL_VISIBLE,
  UPDATE_SIM_LOADING,
  START_LAYOUT_CHECK,
  GET_LAYOUT_CHECK_RESULT,
  RENAME_PRE_LAYOUT,
  UPDATE_COPY_LOADING_LIST,
  UPDATE_SERVICE_CONFIG,
  SAVE_SERVICE_OPTION,
  UPDATE_FULL_POWER_TREE_DISPLAY
} from './actionType'

export const updateProjectMenu = (data) => ({
  type: UPDATE_PROJECT_MENU,
  data
});

export const addProject = () => ({
  type: ADD_PROJECT
})

export const updateTreeList = ({ treeItems }) => ({
  type: UPDATA_TREE_LIST,
  treeItems
})

export const createProject = (data) => ({
  type: CREATE_PROJECT,
  data
})

export const clearCurrentProject = (clearAll) => ({
  type: CLEAR_CURRENT_PROJECT,
  clearAll
})

export const updateSelectKeys = (selectedKeys) => ({
  type: UPDATE_SELECTED_KEYS,
  selectedKeys
})

export const updateExpand = (expandedKeys) => ({
  type: UPDATE_EXPAND_KEYS,
  expandedKeys
});

export const openProject = (id, afterUploadPCBId) => ({
  type: OPEN_PROJECT,
  id,
  afterUploadPCBId
});

export const closeProject = () => ({
  type: CLOSE_PROJECT
});

export const saveOpenProjectInfo = ({ id }) => ({
  type: SAVE_OPEN_PROJECT_INFO,
  id
});

export const openPage = ({ pageType, id }) => ({
  type: OPEN_PAGE,
  pageType,
  id,
});

export const updateViewList = (viewList) => ({
  type: UPDATE_VIEW_LIST,
  viewList
});

export const deleteProject = (data) => ({
  type: DELETE_PROJECT,
  data
});

export const deletePCB = (data) => ({
  type: DELETE_PCB,
  data
});

export const replacePCB = (designId) => ({
  type: PCB_REPLACE,
  designId
});

export const updatePageLayout = (layout, singleType) => ({
  type: UPDATE_PAGE_LAYOUT,
  layout,
  singleType
});

export const addVerification = (key) => ({
  type: ADD_VERIFICATION,
  key
})

export const cancelCreateVerification = (dataType, id) => ({
  type: CANCEL_CREARTE_VERIFICATION,
  dataType,
  id
})

export const createVerification = (dataType, id, name) => ({
  type: CREATE_VERIFICATION,
  dataType,
  id,
  name
})


export const deleteVerification = (dataType, id) => ({
  type: DELETE_VERIFICATION,
  dataType,
  id
})

export const changeRenameStatus = (data, status) => ({
  type: CHANGE_RENAME_STATUS,
  data,
  status
})

export const renameVerification = (dataType, id, name) => ({
  type: RENAME_VERIFICATION,
  dataType,
  id,
  name
})

export const copyVerification = (dataType, id, name) => ({
  type: COPY_VERIFICATION,
  dataType,
  id,
  name
})

export const renamePreLayout = (dataType, id, name) => ({
  type: RENAME_PRE_LAYOUT,
  dataType,
  id,
  name
})

export const updateListStatus = (boolean) => ({
  type: UPDATE_LIST_STATUS,
  boolean
})

export const updateTrash = () => ({
  type: UPDATE_TRASH
})

export const renameProject = (dataType, id, name) => ({
  type: RENAME_PROJECT,
  dataType,
  id,
  name
})

export const updateDesignStatus = (status) => ({
  type: UPDATE_DESIGN_STATUS,
  status
})

export const updatePCBLog = (log) => ({
  type: UPDATE_CHANGE_PCB_LOG,
  log
})

export const updateTemplateLog = (log) => ({
  type: UPDATE_UPLOAD_TEMPLATE_LOG,
  log
})

export const updatePCBLayout = (pcbLayout, prePcbLayout) => ({
  type: UPDATE_PCB_LAYOUT,
  pcbLayout,
  prePcbLayout
});

export const libraryDefault = (libraryId, libraryType, name) => ({
  type: LIBRARY_DEFAULT,
  libraryId,
  libraryType,
  name
})

export const updateDefaultDecap = (libraryId, libraryType, name) => ({
  type: UPDATA_DEFAULT_DECAP,
  libraryId,
  libraryType,
  name
})

export const updateModelSetting = (applyModelList) => ({
  type: UPDATE_LIBRARY_MODEL_SAVE,
  applyModelList
});

export const changePCBUpdateStatus = () => ({
  type: CHANGE_PCB_UPDATE_STATUS
})

export const updateRunningList = (runningList) => ({
  type: UPDATE_SIMULATION_LIST,
  runningList
})

export const newSimulationChannel = ({ verificationId, key }) => ({
  type: NEW_SIMULATION_CHANNEL,
  verificationId,
  key
})

export const updateStackupList = (stackupList) => ({
  type: UPDATE_STACKUP_LIST,
  stackupList
});

export const updateReportInfo = (reportInfo, isUpdate) => ({
  type: UPDATE_REPORT_INFO,
  reportInfo,
  isUpdate
});

export const saveReportConfig = (projectId, typeName) => ({
  type: SAVE_REPORT_CONFIG,
  projectId,
  typeName
});

export const getReport = (params = {}) => ({
  type: GET_REPORT,
  ...params
});

export const clearReportInfo = () => ({
  type: CLEAR_REPORT_INFO
});

export const savePreviewStatus = (status) => ({
  type: SAVE_PREVIEW_STATUS,
  status
})

export const updateForcePreviewShow = (show) => ({
  type: FORCE_PREVIEW_SHOW,
  show
})

export const updateComponentSettingVisible = (boolean) => ({
  type: COMPONENT_SETTING_VISIBLE,
  boolean
})

export const updateRootPanelVisible = (boolean) => ({
  type: ROOT_PANEL_VISIBLE,
  boolean
})

export const updateSimulationLoading = (boolean) => ({
  type: UPDATE_SIM_LOADING,
  loading: boolean
})

export const startLayoutCheck = (designItem) => ({
  type: START_LAYOUT_CHECK,
  designItem
})

export const getLayoutCheckResult = (designId) => ({
  type: GET_LAYOUT_CHECK_RESULT,
  designId
})

export const updateCopyLoadingList = (copyLoadingList) => ({
  type: UPDATE_COPY_LOADING_LIST,
  copyLoadingList
})

export const updateServiceConfig = (serviceOptions) => ({
  type: UPDATE_SERVICE_CONFIG,
  serviceOptions
});

export const saveServiceOption = () => ({
  type: SAVE_SERVICE_OPTION,
})

export const updateFullPowerTreeDisplay = () => ({
  type: UPDATE_FULL_POWER_TREE_DISPLAY
})