import React, { Component, Fragment } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Select, Tooltip } from 'antd';
import ModelSetup from './modelSetup'
import {
  ADS_TX,
  ADS_RX,
  AMI_CONTROLLER,
  AMI_DEVICE,
  getSelectICComps,
  updateModelCompByDir
} from '../../../services/Andes_v2/AMIModelHelper';
import { END_TO_END_CHANNEL } from '../../../constants/treeConstants';
import './index.css';
import { CPHY, PCIE } from '../../../services/PCBHelper/constants';

const { Option } = Select;

class CPHYSignalSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelVisible: false,
      directionToGroup: true,
      directionToAll: false
    };
  }

  getPinsRender = ({ pinInfo, type }) => {
    return <Fragment>
      <div className="ami-model-pin-list">
        {["lineA", "lineB", "lineC"].map((item, index) => (
          <div key={index} className="ami-model-pin-item">
            {type === AMI_DEVICE ? <div className='ami-pin-long-line'></div> : null}
            <div className="ami-model-pin-content">
              <div className="ami-model-pin-title" title={(pinInfo[item] || {}).pin}>{(pinInfo[item] || {}).pin}</div>
            </div>
            {type === AMI_CONTROLLER ? <div className='ami-pin-long-line'></div> : null}
          </div>
        ))}
      </div>
    </Fragment>
  }

  getDirAndModel = (signal, comp) => {
    if (!comp || !comp.component) {
      return { model: {}, dirType: "", pins: [] };
    }

    let pins = [];
    for (const key of ["lineA", "lineB", "lineC"]) {
      if (comp[key] && comp[key].pin) {
        pins.push(comp[key].pin)
      }
    }

    let returnInfo = { model: {}, dirType: "", pins };
    if ((signal.cphyTxModel && signal.cphyTxModel.component === comp.component) && (!comp.channelId || (comp.channelId === signal.cphyTxModel.channelId))) {
      returnInfo = { model: signal.cphyTxModel, dirType: ADS_TX, pins, ModelType: 'cphyTxModel' }
    } else if (signal.cphyRxModel && signal.cphyRxModel.component === comp.component && (!comp.channelId || (comp.channelId === signal.cphyRxModel.channelId))) {
      returnInfo = { model: signal.cphyRxModel, dirType: ADS_RX, pins, ModelType: 'cphyRxModel' };
    }

    return returnInfo || { model: {}, dirType: "", pins };
  }

  getModelTitle = (pinModel, specifyIbis) => {
    if (!specifyIbis) return "";
    try {
      const { pinA, pinB, pinC } = pinModel || {};
      return pinA.pinModel || pinB.pinModel || pinC.pinModel || "";
    } catch (error) {
      return "";
    }
  }

  getAMIModelDisplay = ({
    type,
    model,
    signalInfo,
    dirType = ADS_TX,
    component,
    interfaceType,
    channelId,
    pins,
    modelType
  }) => {
    const errorClassName = signalInfo[`${dirType}ModelFileError`] ? "ami-model-file-error-title" : "";
    const title = this.getModelTitle(model.pinModel, model.specifyIbis);
    return (
      <div className="ami-model-setup-content">
        {type === AMI_DEVICE ? this.directionSelect({
          value: dirType,
          type,
          signal: signalInfo.signal,
          component
        }) : null}
        <div className="ami-model-title-content">
          <div
            className={dirType ? `ami-model-title ${errorClassName}` : "ami-model-title ami-model-title-disabled"}
            title={title}
            onClick={dirType ? (e) => this.openModelSetup({
              e,
              type,
              signal: signalInfo.signal,
              model,
              dirType,
              component,
              prbs: signalInfo.prbs,
              interfaceType,
              channelId,
              pins,
              modelType
            }) : null}>
            {title}
            {errorClassName && <Tooltip
              title={`Model ${model.libraryName} has been deleted, the model is invalid.`}
              overlayClassName='aurora-tooltip'
            >
              <QuestionCircleOutlined
                className='aurora-file-check-icon'
                onClick={(e) => { e.stopPropagation() }} />
            </Tooltip>}
          </div>
        </div>
        {type === AMI_CONTROLLER ? this.directionSelect({
          value: dirType,
          type,
          signal: signalInfo.signal,
          component
        }) : null}
      </div>
    );
  }

  getComponents = ({ adsConfig, interfaceType }) => {
    const { components = [], controllerICComps, deviceICComps, controllerInfo = {}, deviceInfo = {}, contrPCBIndex, devicePCBIndex } = this.props;
    const controller = adsConfig.controller, device = adsConfig.device;
    const { contrComps, deviceComps } = getSelectICComps({
      interfaceType,
      components,
      controllerICComps,
      deviceICComps
    });
    return <div className="andes-ads-component-content">
      <div className="ami-model-signal-aggressors"><span>IBIS Model</span></div>
      <div className="ami-model-pins">
        {this.getCompSelection({
          interfaceType,
          pcbIndex: contrPCBIndex,
          component: controller,
          type: "controller",
          anotherType: "device",
          comps: contrComps,
          compInfo: controllerInfo,
          className: "andes-ads-component-content-controller"
        })}
        <div className="ami-model-signal-main">Signal</div>
        {this.getCompSelection({
          interfaceType,
          pcbIndex: devicePCBIndex,
          component: device,
          type: "device",
          anotherType: "controller",
          comps: deviceComps,
          compInfo: deviceInfo,
          className: "andes-ads-component-content-device"
        })}
      </div>
      <div className="ami-model-signal-aggressors"><span>IBIS Model</span></div>
    </div >
  }

  getCompSelection = ({
    interfaceType,
    pcbIndex,
    component,
    type,
    anotherType,
    comps,
    compInfo,
    className
  }) => {
    return (
      <div className={className}>
        <Tooltip
          overlayClassName='aurora-tooltip'
          title={interfaceType === END_TO_END_CHANNEL ?
            <Fragment>
              <div>Design: PCB {pcbIndex + 1}</div>
              <div>Component: {component}</div>
            </Fragment>
            : ""}
        >
          <Select
            value={component}
            showSearch
            onChange={(key) => this.saveComponent(key, type, anotherType, comps, compInfo)}
            className={interfaceType === END_TO_END_CHANNEL ? 'end-to-end-ads-comp-select aurora-select' : 'aurora-select'}
            popupClassName='aurora-select-dropdown'
          >
            {(comps || []).map(item =>
              <Option
                key={item.name}
                value={item.name}
                title={item.pcb ? `${item.pcb} - ${item.name}` : item.name}
              >{item.pcb ? `${item.pcb} - ${item.name}` : item.name}</Option>
            )}
          </Select>
        </Tooltip>
      </div>
    );
  }

  directionSelect = ({
    signal,
    type,
    value,
    component
  }) => {
    const { directionToAll, directionToGroup } = this.state;
    const { interfaceType } = this.props;
    return (
      <div className="ami-model-direction-main">
        <Select
          value={value}
          onChange={(key) => this.updateDirection({ key, signal, type })}
          popupMatchSelectWidth={false}
          className={`aurora-select`}
          disabled={!component}
          popupClassName="aurora-select-dropdown"
          getPopupContainer={trigger => trigger.parentNode}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              {interfaceType !== END_TO_END_CHANNEL ? <div
                style={{ padding: '4px 8px', cursor: 'pointer' }}
                onMouseDown={e => { e.preventDefault() }}
              >
                <Checkbox
                  onChange={(e) => this.directionGroupAllChange(e, "Group")}
                  checked={directionToGroup}
                >
                  Apply direction to all signals of same group.
                </Checkbox>
              </div> : null}
              <div
                style={{ padding: '4px 8px', cursor: 'pointer' }}
                onMouseDown={e => { e.preventDefault() }}
              >
                <Checkbox
                  onChange={(e) => this.directionGroupAllChange(e, "All")}
                  checked={directionToAll}
                >
                  Apply direction to all signals.
                </Checkbox>
              </div>
            </div >
          )
          }
        >
          {[ADS_TX, ADS_RX].map((item) => (
            <Option
              key={item}
              value={item}
              title={item}
            >{item}</Option>))}
        </Select >
      </div >
    );
  }

  getModelSetup = (modelType) => {
    const { adsConfig: { prbs = {} }, componentPkgInfo, serdesType } = this.props;
    return <ModelSetup
      modelType={modelType}
      modelInfo={this.modelInfo}
      prbs={prbs}
      componentPkgInfo={componentPkgInfo}
      saveModel={this.saveModel}
      serdesType={serdesType}
    />
  }

  saveComponent = (key, type, anotherType, ICComps, setupInfo) => {
    /* setupInfo: channelSetup { id, name, designId,pcbName, ... } */
    const { adsConfig, interfaceType } = this.props;
    let _adsConfig = { ...adsConfig };
    _adsConfig[type] = key;

    if (interfaceType === END_TO_END_CHANNEL) {
      _adsConfig[`${type}Channel`] = { channelId: setupInfo.id, designId: setupInfo.designId, designName: setupInfo.pcbName }
    }

    if (_adsConfig[anotherType] === key && interfaceType !== END_TO_END_CHANNEL) {
      _adsConfig[anotherType] = "";
      if (ICComps.length === 2) {
        const comp = ICComps.find(item => item.name !== key) || {}
        _adsConfig[anotherType] = comp.name || "";
      }
    }

    this.props._updateADSConfig(_adsConfig);
    this.props.setComponentPkgInfo(_adsConfig);
  }

  directionGroupAllChange = (e, type) => {
    const checked = e.target.checked;
    this.setState({
      [`directionTo${type}`]: checked
    })
  }

  getCompPackageExist = (adsConfig, type) => {
    const { componentPkgInfo, interfaceType } = this.props;

    if (interfaceType === END_TO_END_CHANNEL) {
      const componentKey = adsConfig[type] && adsConfig[`${type}Channel`] ? `${adsConfig[type]}::${adsConfig[`${type}Channel`].channelId}` : null;
      return componentKey ? componentPkgInfo[componentKey] : false;
    } else {
      return componentPkgInfo[adsConfig[type]] || false
    }
  }

  updateDirection = ({ key, signal, type }) => {
    const { adsConfig, signals, interfaceType, selectedSignals = [], modelKey, adsSignals, serdesType } = this.props;
    const { directionToGroup, directionToAll } = this.state;
    let antherType = AMI_DEVICE;
    if (type === AMI_DEVICE) {
      antherType = AMI_CONTROLLER;
    }
    let _adsConfig = { ...adsConfig };
    const typePkgExist = this.getCompPackageExist(_adsConfig, type);
    const anotherTypePkgExist = this.getCompPackageExist(_adsConfig, antherType);
    if ((directionToGroup && interfaceType !== END_TO_END_CHANNEL && serdesType === PCIE) || directionToAll) {
      let sameGroupSignals = [];
      if (interfaceType === END_TO_END_CHANNEL) {
        sameGroupSignals = [...selectedSignals]
      } else {
        const channelSignal = signals.find(item => item.name === signal) || {};
        sameGroupSignals = directionToAll ? signals.map(item => item.name) : signals.filter(item => item.group === channelSignal.group).map(item => item.name);
      }

      for (let item of _adsConfig.signals) {
        if (!sameGroupSignals.includes(item.signalName)) {
          continue;
        }
        const signalInfo = updateModelCompByDir({
          key,
          signalItem: item,
          modelKey,
          _adsConfig,
          type,
          antherType,
          interfaceType,
          adsSignals,
          typePkgExist,
          anotherTypePkgExist,
          serdesType: CPHY
        })
        item.cphyTxModel = signalInfo.cphyTxModel;
        item.cphyRxModel = signalInfo.cphyRxModel;
      }
    } else {
      const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
      if (index < 0) {
        return;
      }
      const signalInfo = updateModelCompByDir({
        key,
        signalItem: _adsConfig.signals[index],
        modelKey,
        _adsConfig,
        type,
        antherType,
        interfaceType,
        adsSignals,
        typePkgExist,
        anotherTypePkgExist,
        serdesType: CPHY
      })
      _adsConfig.signals[index].cphyTxModel = signalInfo.cphyTxModel;
      _adsConfig.signals[index].cphyRxModel = signalInfo.cphyRxModel;
    }

    this.props._updateADSConfig(_adsConfig);
  }

  directionGroupAllChange = (e, type) => {
    const checked = e.target.checked;
    this.setState({
      [`directionTo${type}`]: checked
    })
  }

  getUsePackage = ({ _model, prevModel }) => {
    const { componentPkgInfo, interfaceType } = this.props;

    if (interfaceType === END_TO_END_CHANNEL) {
      const componentKey = `${prevModel.component}::${prevModel.channelId}`;
      if ((_model.component === prevModel.component && _model.channelId === prevModel.channelId) || !componentPkgInfo[componentKey]) {
        return _model.usePackage;
      }
    } else {
      if (_model.component === prevModel.component || !componentPkgInfo[prevModel.component]) {
        return _model.usePackage;
      }
    }

    return prevModel.usePackage
  }

  openModelSetup = ({ signal, type, component, dirType, model, prbs, interfaceType, channelId, pins, modelType, pinAndNets }) => {
    this.setState({ modelVisible: true });
    this.modelInfo = {
      signal,
      type,
      model,
      component,
      dirType,
      prbs,
      interfaceType,
      channelId,
      pins,
      modelType,
      pinAndNets
    }
  }

  saveModel = ({ model, prbs, signal, dirType, applyAll, isClose }) => {
    // save Model file
    if (typeof isClose === 'boolean' && !isClose) {
      this.saveModelFile({ model, signal, dirType })
      return;
    }

    const { adsConfig } = this.props;
    let _adsConfig = { ...adsConfig };
    const key = dirType === ADS_TX ? 'cphyTxModel' : 'cphyRxModel';
    if (applyAll) {
      _adsConfig.signals.forEach(item => {
        const channelId = item[key].channelId;

        let _model = JSON.parse(JSON.stringify(model))
        item[key] = {
          ...JSON.parse(JSON.stringify(_model)),
          component: item[key].component,
          usePackage: this.getUsePackage({ _model, prevModel: item[key] })
        }

        if (dirType === ADS_TX) {
          item.prbs = { ...prbs }
        }

        if (channelId) {
          item[key].channelId = channelId;
        }
      })
    } else {
      const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
      if (index < 0) {
        return;
      }

      if (dirType === ADS_TX) {
        _adsConfig.signals[index].prbs = { ...prbs }
      }
      _adsConfig.signals[index][key] = JSON.parse(JSON.stringify(model));
    }

    this.props._updateADSConfig(_adsConfig);
    this.setState({ modelVisible: isClose })
  }

  saveModelFile = ({ model, signal, dirType }) => {
    const { adsConfig } = this.props;
    let _adsConfig = { ...adsConfig };
    const key = dirType === ADS_TX ? 'cphyTxModel' : 'cphyRxModel';
    const index = _adsConfig.signals.findIndex(item => item.signalName === signal);
    if (index < 0) {
      return;
    }
    _adsConfig.signals[index][key] = JSON.parse(JSON.stringify(model));
    this.props._updateADSConfig(_adsConfig);
  }

  render = () => {
    const { adsSignals, interfaceType, modelType, adsConfig } = this.props;
    const { modelVisible } = this.state;

    return <Fragment>
      {this.getComponents({ adsConfig, interfaceType })}
      {(adsSignals || []).map(signal => {
        const {
          model: controllerModel,
          dirType: controllerDir,
          pins: controllerPins,
          ModelType: controllerModelType } = this.getDirAndModel(signal, signal.controller, signal.controllerChannelId);
        const {
          model: deviceModel,
          dirType: deviceDir,
          pins: devicePins,
          ModelType: deviceModelType } = this.getDirAndModel(signal, signal.device, signal.deviceChannelId);
        return <div key={signal.signal} className="andes-ami-model-signals-content andes-ami-model-signals-content-cphy">
          {this.getAMIModelDisplay({
            type: AMI_CONTROLLER,
            model: controllerModel,
            signalInfo: signal,
            dirType: controllerDir,
            component: signal.controller.component,
            interfaceType,
            channelId: signal.controller.channelId,
            pins: controllerPins,
            modelType: controllerModelType
          })}
          <div className="ami-model-pins">
            {this.getPinsRender({ pinInfo: signal.controller, type: AMI_CONTROLLER })}
            <div className="ami-model-signal-main">
              <div className="ami-model-signal" title={signal.signal}>{signal.signal}</div>
            </div>
            {this.getPinsRender({ pinInfo: signal.device, type: AMI_DEVICE })}
          </div>
          {this.getAMIModelDisplay({
            type: AMI_DEVICE,
            model: deviceModel,
            signalInfo: signal,
            dirType: deviceDir,
            component: signal.device.component,
            interfaceType,
            channelId: signal.device.channelId,
            pins: devicePins,
            modelType: deviceModelType
          })}
        </div>
      })}
      {modelVisible ? this.getModelSetup(modelType) : null}
    </Fragment>
  }
}
export default CPHYSignalSetup;