import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import DelConfirm from '@/components/DelConfirm';
import Panel from '@/components/Panel';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { FileTextOutlined } from '@ant-design/icons';
import { Button, Progress, Select, TreeSelect, Spin } from 'antd';
import ReportTemplate from '@/components/ReportTemplate/reportTemplate';
import { SIERRA } from '@/constants/pageType';
import projectVerifications from '@/services/Sierra/data/projectVerifications';
import { VERIFICATION_GROUP } from '@/constants/treeConstants';
import { updateReportInfo, clearReportInfo, getReportConfig, saveReportConfig, getReport } from '../../../../store/project/action';
import { timeFormat } from '@/services/helper/timeFormat';
import projects from '@/services/Sierra/data/projects';
import { generateReport } from '../../../../../../services/Sierra';
import MsgDialog from '../../../../../../components/MsgDialog';
import './index.css';
import ReportAdvance from './advance';

const { SHOW_PARENT } = TreeSelect;
function getTreeData(list) {
  return list.map(item => {
    if (item.dataType === VERIFICATION_GROUP) {
      return {
        title: item.name,
        value: item.id,
        group: item.id,
        children: item.children ? item.children.map(it => ({ title: it.name, value: it.id })) : []
      }
    }
    return {
      title: item.name,
      value: item.id
    }
  })
}

/* const referenceLinesColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%'
  },
  {
    title: 'Frequency (GHz)',
    dataIndex: 'x',
    key: 'x',
    width: '35%'
  },
  {
    title: 'Magnitude (dB)',
    dataIndex: 'y',
    key: 'y',
    width: '35%'
  },
]; */
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 500,
      maxHeight: 700,
      selectList: [],
      treeData: [],
      downloadLoading: false,
      logVisible: false
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 500),
      maxWidth: getPanelMaxWidth(offset, 700)
    })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getTreeData();
    this.initSelectList();
  }

  componentDidUpdate = (prevProps) => {
    const { reportConfigLoading, verificationId, projectId } = this.props;
    if (prevProps.verificationId !== verificationId) {
      this.initSelectList();
    }

    if (prevProps.projectId && prevProps.projectId !== projectId) {
      //clear prev project report info
      this.props._clearReportInfo();
      this.props.closePanel();
    }

    if (prevProps.reportConfigLoading !== reportConfigLoading && !reportConfigLoading) {
      this.setSelectList();
    }
  }

  getTreeData = () => {
    const { projectId, reportVisible, reportLog } = this.props;
    const verGroups = projectVerifications.getVerGroups(projectId);
    const treeData = [{ title: "All", value: "All", key: "All", children: getTreeData(verGroups) || [] }];
    this.setState({
      treeData,
      logVisible: reportVisible && reportLog && reportLog.length ? true : false
    })
  }

  initSelectList = () => {
    const { verificationId, projectId } = this.props;
    this.props._getReportConfig({ projectId, verificationId });
    this.setSelectList();
  }

  setSelectList = () => {
    const { verificationId, reportConfig } = this.props;
    const { verificationIds } = reportConfig || {};
    this.setState({
      selectList: verificationIds && verificationIds.length ? [...verificationIds] : (verificationId ? [verificationId] : [])
    })
  }

  onMinimize = () => {
    this.props.closePanel();
    // save report config
    const { selectList } = this.state;
    if (!selectList || !selectList.length) { return }
    this.props._saveReportConfig(selectList);
  }

  onTreeChange = (keys) => {
    let verificationIds = [];
    const { projectId, reportConfig } = this.props;
    //get verificationIds
    if (keys.length === 1 && keys[0] === "All") {
      verificationIds = (projectVerifications.getVerifications(projectId) || []).map(item => item.id);
    } else if (keys.length) {
      for (let item of keys) {
        if (projectVerifications.getVer(item)) {
          verificationIds.push(item)
        } else {
          //group
          const groups = projectVerifications.getVerGroups(projectId, VERIFICATION_GROUP) || [];
          const findGroup = groups.find(it => it.id === item) || {};
          verificationIds.push(...(findGroup.children || []).map(item => item.id))
        }
      }
    }
    this.setState({
      selectList: verificationIds
    })
    let config = { ...(reportConfig || {}) };
    config.verificationIds = [...verificationIds];
    // update the reportConfig in reducer
    this.props._updateReportInfo({ reportConfig: config });
  }

  updateReportConfig = ({ templateId }) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.templateId = templateId;
    this.props._updateReportInfo({ reportConfig: config });

    if (this.state.downloadLoading) {
      this.setState({
        downloadLoading: false
      })
    }
  }

  updateMessage = (message) => {
    this.props._updateReportInfo({ reportMessage: message });
  }

  changeReportType = (format) => {
    const { reportConfig } = this.props;
    let config = { ...(reportConfig || {}) };
    config.format = format;
    // update the reportConfig in reducer
    this.props._updateReportInfo({ reportConfig: config });
  }

  closeModal = () => {
    const { reportVisible } = this.props;
    if (reportVisible) {
      this.setState({
        closeVisible: true
      })
    } else {
      this.close()
    }
  }

  close = () => {
    // save report config
    const { selectList } = this.state;
    if (!selectList || !selectList.length) {
      this.props.closePanel();
      return;
    }
    this.props._saveReportConfig(selectList);
    //clear prev project report info
    this.props._clearReportInfo();
    this.props.closePanel();
  }

  cancelDel = () => {
    this.setState({
      closeVisible: false
    })
  }

  downloadClick = () => {
    const { selectList } = this.state;
    const { reportConfig } = this.props;
    if (!selectList || !selectList.length) { return }
    this.props._updateReportInfo({ reportVisible: true, reportProgress: 0 });
    const { projectId } = this.props;
    const find = projects.get(projectId);
    const projectName = find ? find.name : "";

    /* let mime; */
    const fileName = `${projectName}_report_${timeFormat('YYYYMMDD')}`;
    let format = reportConfig.format || 'PPTX';

    /*  if (format === 'PDF') {
       mime = 'application/pdf';
        fileName = `${_fileName}.pdf`;
     } else if (format === 'PPTX') {
       mime = 'application/vnd.ms-powerpoint';
         fileName = `${_fileName}.pptx`;
     } */

    const _config = reportConfig ? JSON.parse(JSON.stringify(reportConfig)) : {
      verificationIds: [],
      language: 'english',
      templateId: "",
      format,
      fileName: "",
      enableOptions: ["traceCapacitance"]
    };

    const config = {
      ..._config,
      language: _config.language ? _config.language : "english",
      verificationIds: _config.verificationIds || [],
      format,
      fileName
    };
    this.props._updateReportInfo({ reportConfig: config, reportLog: [] });
    generateReport(projectId, { ...config, fileName }).then(res => {
      this.props._getReport({ projectId, format, fileName });
      this.setState({
        logVisible: true
      })
    }, error => {
      this.props._updateReportInfo({ reportVisible: false, reportMessage: `Download report in ${format} format failed! ` + error });
    })
    this.props._saveReportConfig(_config.verificationIds);
  }

  changeReferenceLineData = (data, isXTable) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.custom.guideValue = data;
    config.custom.isXTable = isXTable
    this.props._updateReportInfo({ reportConfig: config });
  }

  changeIsXTable = (data) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.custom.isXTable = data
    this.props._updateReportInfo({ reportConfig: config });
  }

  closeLogPanel = () => {
    this.setState({
      logVisible: false
    })
  }

  openLogPanel = () => {
    this.setState({
      logVisible: true
    })
  }

  changeAdvanceData = (data, key) => {
    const { reportConfig } = this.props;
    this.props._updateReportInfo({ reportConfig: { ...reportConfig, [key]: data } });
  }

  render = () => {
    const { maxWidth, maxHeight, selectList, closeVisible, downloadLoading, treeData, logVisible } = this.state;
    const { reportVisible, reportConfig = {}, reportMessage, reportProgress, reportConfigLoading, reportLog } = this.props;
    const { /* language = 'english', */ format, /* custom */ } = reportConfig;
    const messageClassName = reportMessage && reportMessage.indexOf('successfully') > -1 ? 'download-success' : 'download-error';
    /*   const guideValue = custom && custom.guideValue ? custom.guideValue : [];
      const isXTable = custom ? custom.isXTable : false;//not support */
    const content = (
      <Fragment>
        <Panel
          className='sierra-report-panel aurora-x-scroll-hidden sierra-panel'
          title={<div className='report-panel-title'>Report Generation</div>}
          onCancel={this.closeModal}
          onMinimize={this.onMinimize}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { minWidth: 300, defaultWidth: 900 })}
          minWidth={300}
          minHeight={200}
          maxHeight={maxHeight}
          position='panel-center'
          draggable
          minimizeVisible={reportVisible ? true : false}
          overflow={"auto"}
          id="sierra-report-content-main"
        >
          <div className='report-content clear'>
            <Spin spinning={reportConfigLoading || false} tip="Loading">
              <div>
                <label className="report-item-title">Interface</label>
                <TreeSelect
                  treeData={treeData}
                  value={selectList}
                  onChange={this.onTreeChange}
                  placeholder='Please select'
                  treeCheckable={true}
                  allowClear
                  treeDefaultExpandAll={true}
                  showCheckedStrategy={SHOW_PARENT}
                  className='report-select aurora-select'
                  size='small'
                  dropdownStyle={{ maxHeight: 500 }}
                  getPopupContainer={() => document.getElementById('root')}
                  popupClassName='sierra-report-select-dropdown'
                />
              </div>
              <div>
                <label className="report-item-title">File Type</label>
                <Select
                  value={format}
                  onChange={(value) => this.changeReportType(value)}
                  className='aurora-select report-select'
                  popupClassName='report-select-dropdown'
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {["PPTX"/* , "PDF" */].map(key => <Select.Option key={key}>{key}</Select.Option>)}
                </Select>
              </div>
              {/*  <div>
              <label className="report-item-title">Language</label>
              <Select
                value={language}
                onChange={(value) => this.changeReportLanguage(value)}
                className='aurora-select report-select'
                popupClassName='report-select-dropdown'
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Select.Option key="english">English</Select.Option>
              </Select>
            </div> */}
              <div>
                <ReportAdvance
                  reportConfig={reportConfig}
                  changeAdvanceData={this.changeAdvanceData}
                />
              </div>
              <ReportTemplate
                reportConfig={reportConfig}
                reportConfigLoading={reportConfigLoading}
                updateReportConfig={this.updateReportConfig}
                updateReoprtMessage={this.updateMessage}
                type={SIERRA}
              />
              <div className='report-message-main .clear'>
                {!reportVisible ? <Fragment>
                  {reportMessage ? <div className={`${messageClassName} report-download-message`}>{reportMessage}</div> : null}
                  <div className='report-download-button clear'>
                    {reportLog && reportLog.length ? <FileTextOutlined
                      title={"Report download Log"}
                      onClick={(e) => this.openLogPanel(e)}
                      className='report-download-log-icon' /> : null}
                    <Button
                      title={"Download"}
                      type="primary"
                      ghost
                      disabled={reportVisible || !selectList.length || downloadLoading}
                      onClick={(e) => this.downloadClick(e)}
                      className='report-download-btn'
                    >Download</Button>
                  </div> </Fragment> :
                  <Fragment>
                    <Progress
                      size={{ height: 10 }}
                      className='report-progress'
                      percent={reportProgress}
                    />
                    {reportLog && reportLog.length ? <div className='report-download-button clear'>
                      <FileTextOutlined
                        title={"Report download Log"}
                        onClick={(e) => this.openLogPanel(e)}
                        className='report-download-log-icon' />
                    </div> : null}
                  </Fragment>
                }
              </div>
              {/* <div className="report-bottom-severed"></div> */}
            </Spin>
          </div>
        </Panel>
        {
          closeVisible ? <DelConfirm
            data={null}
            maskStyle={true}
            deleteConfirm={this.close}
            cancelDel={this.cancelDel}
            message={'After closing the panel, the download report will end. Are you sure you want to close it?'}
          /> : null
        }
        {logVisible && reportLog && reportLog.length ? <MsgDialog
          mask={false}
          type="log"
          allowButton={true}
          allowDownload={true}
          messageList={reportLog}
          position='panel-top-right-50'
          fileName="Report_download.log"
          root="sierra-report-content-main"
          className="aurora-msg-dialog-info-panel sierra-download-report-log-panel"
          clickOkButton={this.closeLogPanel}
        /> : null}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}

const mapState = (state) => {
  const {
    SierraReducer: {
      project: { reportInfo, viewList, currentProjectId, verificationId },
    } } = state;
  const { reportProgress, reportVisible, reportConfig = {}, reportMessage, reportConfigLoading, reportLog } = reportInfo || {};

  return {
    viewList,
    reportProgress,
    reportVisible,
    reportConfig,
    reportMessage,
    reportConfigLoading,
    projectId: currentProjectId,
    verificationId,
    reportLog
  };
}

const mapDispatch = (dispatch) => ({
  _updateReportInfo(info) {
    dispatch(updateReportInfo(info))
  },
  _clearReportInfo() {
    dispatch(clearReportInfo())
  },
  _getReportConfig({ projectId, verificationId }) {
    dispatch(getReportConfig({ projectId, verificationId }))
  },
  _saveReportConfig(selectList) {
    dispatch(saveReportConfig(selectList))
  },
  _getReport({ projectId, format, fileName }) {
    dispatch(getReport({ projectId, format, fileName }))
  }
})

export default connect(mapState, mapDispatch)(Report);