import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { createPortal } from "react-dom";
import Panel from '@/components/Panel';
import StackupFooter from "./footer";
import Stackup from './stackupContent';
import MaterialMenu from "./stackupContent/materialMenu";
import DelConfirm from "@/components/DelConfirm";
import { saveData } from './store/actionCreators';
import {
  changeStackupTable,
  settingUnit,
  saveMaterial,
  updateStackupColumnsStatus,
  saveStackupTableData,
  updateStackupErrorMsg,
  saveStackupToServer,
  saveRoughness,
  saveMenuMaterial,
  delMaterialList,
  changeStackupZonePanelShow
} from './store/actionCreators';
import { defaultStackupReducer } from "../../../../services/Stackup";
import LayoutData from '@/services/data/LayoutData';
import '@/publicCss/aurora.css';
import './index.css';

class StackupPanel extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      height: props.maxHeight,
      materialMenuVisible: false,
      parentLeft: 0,
      parentTop: 0,
      errorDisplay: false
    }
    this.dialogRoot = props.NotPCBStackup ? document.getElementById('root') : document.getElementsByClassName(`aurora-PCB-layout-content-${this.props.designID}`)[0] || document.getElementById('root');
  }

  stackupRef = (ref) => {
    this.stackupChild = ref;
  };

  componentDidUpdate = (prevProps) => {
    const { maxHeight } = this.props;
    if (prevProps.maxHeight !== maxHeight) {
      this.setState({
        height: maxHeight
      })
    }
  }

  componentDidMount = () => {
    const { NotPCBStackup, designID } = this.props
    this.dialogRoot = NotPCBStackup ? document.getElementById('root') : document.getElementsByClassName(`aurora-PCB-layout-content-${this.props.designID}`)[0];
    const { defaultLeft, defaultTop, leftWidth } = this.props;
    this.setState({
      parentLeft: (leftWidth || leftWidth === 0) ? leftWidth : defaultLeft,
      parentTop: defaultTop + 34
    })
    if (designID) {
      // loading LayoutData
      // When opening stackup, go get the layout data
      LayoutData.LoadLayoutDB(designID, true)
    }
  }

  closeModal = (save) => {
    const { designID, closePanel, NotPCBStackup } = this.props;
    const errorDisplay = this.stackupChild.closeStackupPanel(save);
    this.setState({
      errorDisplay
    });

    if (NotPCBStackup) !errorDisplay && closePanel(false);
    else !errorDisplay && closePanel(designID);
  }

  getPanelHeight = (height) => {
    this.setState({ height })
  }

  getPanelWidth = (width) => {
    this.setState({ width })
  }

  closeMaterialMenu = () => {
    this.setState({
      materialMenuVisible: false
    })
  }

  updateErrorDisplay = (errorDisplay) => {
    this.setState({
      errorDisplay
    })
  }

  updateLeftAndTop = ({ left, top }) => {
    const { defaultLeft, defaultTop, leftWidth } = this.props;
    const _defaultLeft = leftWidth ? leftWidth : defaultLeft;
    const _defaultTop = defaultTop + 34;
    this.setState({
      parentLeft: _defaultLeft + left,
      parentTop: _defaultTop + top
    })
  }

  openMaterialMenu = (e) => {
    e && e.stopPropagation();
    this.setState({
      materialMenuVisible: true
    })
  }

  close = (e, _close) => {
    e && e.stopPropagation();
    // _close - false: change, true: ignore
    this.setState({
      errorDisplay: false
    });
    if (_close) {
      this.closeModal(true);
    }
    this.props._updateStackupErrorMsg(null, this.props.designID);
  }

  closeUpload = (e) => {
    e && e.stopPropagation();
    this.setState({
      errorDisplay: false
    });
    this.props._updateStackupErrorMsg(null, this.props.designID);
  }

  getRealHeight = (stackup) => {
    const length = stackup && stackup.layers ? stackup.layers.length : 5;
    return length * 40;
  }

  getStackupDataByDesignId = () => {
    const { designID, stackupObj } = this.props;
    return stackupObj[designID] || defaultStackupReducer
  }

  render = () => {
    const { selectingSignal, defaultLeft, defaultTop, leftWidth, topWidth, maxWidth, maxHeight, designID, zIndex, NotPCBStackup, title } = this.props;
    const visible = !selectingSignal;
    const { height, materialMenuVisible, parentLeft, parentTop, errorDisplay, width } = this.state;
    const stackupInfo = { ...this.getStackupDataByDesignId() };
    const content = (
      <Panel
        className={NotPCBStackup ? 'aurora_stackup_fullscreen stackup-panel' : 'stackup-panel'}
        title={"Stackup" + (title ? ` - ${title}` : '')}
        footer={<StackupFooter
          {...this.props}
          {...stackupInfo}
          designID={designID}
          zIndex={zIndex}
          updateErrorDisplay={this.updateErrorDisplay}
        />}
        visible={visible}
        onCancel={() => this.closeModal(false)}
        position={'panel-center'}
        draggable
        left={(leftWidth || leftWidth === 0) ? leftWidth : defaultLeft}
        top={(topWidth || topWidth === 0) ? topWidth : defaultTop}
        width={maxWidth || null}
        minHeight={100}
        maxHeight={maxHeight || null}
        redirectDom={NotPCBStackup ? 'root' : `aurora-PCB-layout-content-${designID}`}
        getPanelHeight={this.getPanelHeight}
        updateLeftAndTop={this.updateLeftAndTop.bind(this)}
        getPanelWidth={this.getPanelWidth}
        zIndex={zIndex}
      >
        <div id="stackup-panel-main">
          <Stackup
            {...this.props}
            {...stackupInfo}
            scrollY={stackupInfo.zones ? height - 172 : height - 138}
            width={width ? width : maxWidth}
            onRef={this.stackupRef}
            openMaterialMenu={this.openMaterialMenu}
            getRealHeight={this.getRealHeight}
          />
          {materialMenuVisible ? <MaterialMenu
            {...this.props}
            {...stackupInfo}
            closeModal={this.closeMaterialMenu}
            left={parentLeft}
            top={parentTop}
          /> : null}
          {errorDisplay ? this.getConfirm(errorDisplay, stackupInfo) : null}
        </div>

      </Panel>)
    return this.dialogRoot ? createPortal(content, this.dialogRoot) : null;
  }

  getConfirm = (errorDisplay, stackupInfo = {}) => {
    const { stackupError } = stackupInfo;

    if (!stackupError || !stackupError.error) {
      return null;
    }
    if (errorDisplay === "upload") {
      return <DelConfirm
        message={stackupError ? stackupError.error : ""}
        type={'delete'}
        removeCancel={true}
        deleteConfirm={(e) => this.closeUpload(e)}
      />
    } else {
      return <DelConfirm
        message={stackupError ? stackupError.error : ""}
        type={'change'}
        onChange={(e) => this.close(e, false)}
        onIgnore={(e) => this.close(e, true)}
      />
    }
  }
}

const mapState = (state) => {
  const { stackupObj } = state.StackupReducer;
  const { defaultLeft, defaultTop } = state.PanelStatus;

  const { pageType } = state.LoginReducer;
  return {
    stackupObj,
    defaultLeft,
    defaultTop,
    product: pageType ? pageType.toLowerCase() : null
  }
}

const mapDispatch = (dispatch) => ({
  setStackupData({ data, materialList, stackups, zones, unit, designID, bends }) {
    dispatch(saveData({ data, materialList, stackups, zones, unit, designID, bends }));
  },
  changeTable(data, unit, designID) {
    dispatch(changeStackupTable(data, unit, designID));
  },
  settingUnit(unit, designID) {
    dispatch(settingUnit(unit, designID));
  },
  saveMaterial({ newMaterial, applyAllType, selectMaterialName, record, designID, dataIndex }) {
    dispatch(saveMaterial({ newMaterial, applyAllType, selectMaterialName, record, designID, dataIndex }))
  },
  updateStackupColumnsStatus(status, designID) {
    dispatch(updateStackupColumnsStatus(status, designID))
  },
  _saveStackupTableData(record, dataType, designID) {
    dispatch(saveStackupTableData(record, dataType, designID))
  },
  _updateStackupErrorMsg(errorObj, designID) {
    dispatch(updateStackupErrorMsg(errorObj, designID))
  },
  _saveStackupToServer(stackupData, designID) {
    dispatch(saveStackupToServer(stackupData, designID))
  },
  saveRoughness(roughnessObj, record, designID) {
    dispatch(saveRoughness(roughnessObj, record, designID))
  },
  _saveMenuMaterial({ newMaterial, prevMaterialInfo, designID }) {
    dispatch(saveMenuMaterial({ newMaterial, prevMaterialInfo, designID }))
  },
  _delMaterialList(materialName, designID) {
    dispatch(delMaterialList(materialName, designID))
  },
  _changeStackupZonePanelShow(show, designID) {
    dispatch(changeStackupZonePanelShow(show, designID))
  },
})


export default connect(mapState, mapDispatch)(StackupPanel);