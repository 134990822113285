
import { pdnSaga } from '../store/pdn';
import { simulationSaga } from './simulation';
import { projectSaga } from '../store/project';
import { resultSaga } from './result';
import PDNDesignUploadSaga from '../PdnSider/Upload/sagas';

export default [
  pdnSaga,
  simulationSaga,
  projectSaga,
  PDNDesignUploadSaga,
  resultSaga
]
