import {
  CREATE_PRE_LAYOUT,
  UPDATE_PRE_LAYOUT_CONTENT,
  OPEN_PRE_LAYOUT,
  SAVE_PRE_LAYOUT,
  SAVE_PRE_LAYOUT_SETTING,
  FIRST_OPEN_PRE_LAYOUT,
  COMP_TABLE_LOADING,
  UPDATE_PRE_LAYOUT_COMPONENTS,
  SCHEMATIC_LOADING,
  UPDATE_CANVAS,
  SAVE_PRE_LAYOUT_MODEL,
  CREATE_PRELAYOUT_INTERFACE,
  CREATE_LOADING,
  CREATE_DECAP_GROUP,
  GROUP_SHOW,
  DELETE_DECAP_GROUP,
  SAVE_DECAP_GROUP,
  UPDATE_VRM_VOLTAGE,
  UPDATE_BALL,
  UPDATE_DC_COMPONENTS,
  UPDATE_DC_NETS
} from './actionTypes';

export const createPreLayout = (designType, electric) => ({
  type: CREATE_PRE_LAYOUT,
  designType,
  electric
})

export const updatePreLayoutContent = (params) => ({
  type: UPDATE_PRE_LAYOUT_CONTENT,
  params
})

export const openPreLayoutPage = (id) => ({
  type: OPEN_PRE_LAYOUT,
  id
})

export const savePreLayout = (name, params) => ({
  type: SAVE_PRE_LAYOUT,
  name,
  params
})

export const savePreLayoutSetting = (name, setting) => ({
  type: SAVE_PRE_LAYOUT_SETTING,
  name,
  setting
})

export const firstOpenPreLayout = (boolean) => ({
  type: FIRST_OPEN_PRE_LAYOUT,
  boolean
})

export const updateCompTableLoading = (boolean) => ({
  type: COMP_TABLE_LOADING,
  boolean
})

export const updateComponents = (components) => ({
  type: UPDATE_PRE_LAYOUT_COMPONENTS,
  components
})

export const updateSchematicLoading = (boolean) => ({
  type: SCHEMATIC_LOADING,
  boolean
})

export const updateCanvasStatus = (boolean) => ({
  type: UPDATE_CANVAS,
  boolean
})

export const saveModel = (model, components, saveToBackend) => ({
  type: SAVE_PRE_LAYOUT_MODEL,
  model,
  saveToBackend,
  components
})

export const createPreLayoutInterface = () => ({
  type: CREATE_PRELAYOUT_INTERFACE
})

export const createLoading = (boolean) => ({
  type: CREATE_LOADING,
  boolean
})

export const createDecapGroup = () => ({
  type: CREATE_DECAP_GROUP
})

export const showDecapGroup = (groupName) => ({
  type: GROUP_SHOW,
  groupName
})

export const deleteDecapGroup = () => ({
  type: DELETE_DECAP_GROUP
})

export const saveDecapGroup = (group) => ({
  type: SAVE_DECAP_GROUP,
  group
})

export const updateVoltage = (name, voltage) => ({
  type: UPDATE_VRM_VOLTAGE,
  name,
  voltage
})

export const updateBall = (key, value) => ({
  type: UPDATE_BALL,
  key, value
})

export const updateDcComponents = (comp) => ({
  type: UPDATE_DC_COMPONENTS,
  comp
})

export const updateDcNets = (net) => ({
  type: UPDATE_DC_NETS,
  net
})