import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Tabs } from 'antd';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { choosePDFTable, updateTemplatePDFPanel } from '../store/SignOffTemplate/action';
import { deleteVerification } from '../store/project/action';
import { SIGN_OFF_TEMPLATE } from '../../../constants/treeConstants';
import './index.css';
import EditableTable from '../../../components/EditableTable';

class TemplateChoosePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "0"
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 1000),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.setState({
    })
  }

  closeModal = () => {
    const { verificationId } = this.props
    this.props.deleteVerification(SIGN_OFF_TEMPLATE, verificationId);
    this.props.updateTemplatePDFPanel({}, true)
  }

  changeTab = (key) => {
    this.setState({
      value: key
    })
  }

  chooseTable = () => {
    const { value } = this.state;
    this.props.choosePDFTable(value)
  }

  footerRender = () => {
    const { tables } = this.props;
    return <Button onClick={this.chooseTable} type="primary" disabled={tables && tables.length ? false : true}>Choose</Button>
  }

  tableRender = () => {
    const { value } = this.state;
    const { tables } = this.props;
    if (!tables) {
      return;
    }
    const items = tables.map(item => {
      const { sheetNum, sheetName, data, columns } = item;
      return {
        key: String(sheetNum),
        label: sheetName,
        children: this.tableContent(data, columns)
      }
    })
    return <Tabs activeKey={value} onChange={this.changeTab} items={items} />
  }

  tableContent = (data, columns) => {
    if (!data || !data.length) {
      return;
    }
    const columnWidth = 100 / columns.length;
    const _columns = columns.map(item => ({
      title: item.title,
      dataIndex: item.title,
      width: `${columnWidth}%`,
      render: (text) => <span>{text}</span>,
      children: item.children ? item.children.map(child => ({
        title: child.title,
        dataIndex: child.title,
        width: `${columnWidth / item.children.length}%`,
        render: (text) => <span>{text}</span>,
      })) : undefined
    }))
    return <EditableTable
      rowKey="rowIndex"
      columns={_columns}
      size="small"
      dataSource={data}
      className="cascade-template-pdf-choose-table"
      scroll={data.length > 8 ? { y: 800 } : {}}
    />
  }

  render() {
    const { maxWidth } = this.state;
    const { loading, loadingTip, failed, error } = this.props;
    const setting = { defaultWidth: 1000 }
    const content = (
      <Panel
        className='cascade-top-template-option-panel'
        title={<div className='cascade-top-template-option-panel-name'>Choose Table</div>}
        onCancel={this.closeModal}
        zIndex={2000}
        width={getPanelWidth(maxWidth, setting)}
        position='panel-center'
        draggable
        minWidth={200}
        minHeight={500}
        overflow={"auto"}
        footer={this.footerRender()}
      >
        <Spin spinning={loading} tip={loadingTip}>
          <div className="cascade-template-pdf-choose-content">
            {
              failed ? error : this.tableRender()
            }
          </div>
        </Spin>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    SignOffTemplate: { templatePDFPanel: { loading, loadingTip, tables, failed, verificationInfo = {}, error } },
  } } = state;
  const { res = {} } = verificationInfo;
  const { verificationId } = res;
  return {
    loading,
    loadingTip,
    tables,
    failed,
    verificationId,
    error
  };
}

const mapDispatch = (dispatch) => ({
  choosePDFTable(value) {
    dispatch(choosePDFTable(value))
  },
  deleteVerification(dataType, id) {
    dispatch(deleteVerification(dataType, id))
  },
  updateTemplatePDFPanel(info, replace) {
    dispatch(updateTemplatePDFPanel(info, replace))
  }
})

export default connect(mapState, mapDispatch)(TemplateChoosePanel);