import React, { Component, Fragment, createRef } from 'react';
import { VerticalLeftOutlined } from '@ant-design/icons';
import { Progress, Row, Col, Tooltip } from 'antd';
import { TitleRender } from './TitleRender';
import { RUNNING, WAITING } from '../../constants/workflowStatus';
import { getWaitingMsg, isSimulationRunning } from '../../services/workflow/workflowHelper';
import { Monitor } from './SimulationMonitor/MonitorErrorCheck';
import { getDisplayDesignList } from '../../services/PCBHelper/pcbHelper';
import '@/publicCss/style.css';
import '@/publicCss/monitorStyle.css';

class LayoutMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      topWidth: 666,
      lockScroll: true
    }
    this.scrollRef = createRef();
  }

  scrollToBottom = () => {
    if (this.scrollRef) {
      this.scrollRef.scrollTop = this.scrollRef.scrollHeight;
    }
  }


  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount() {
    const { tabVisible } = this.props;
    this.screenChange();
    tabVisible && this.scrollToBottom();
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { tabVisible, designInfo } = this.props;
    const { lockScroll } = this.state;
    const { log, startMsg } = designInfo;
    const { designInfo: prevDesignInfo = {} } = prevProps
    tabVisible && this.scrollToBottom();
    const { topWidth } = this.state;
    this.footerRef = document.getElementsByClassName('aurora-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }

    if (tabVisible && !lockScroll) {
      this.scrollToBottom();
    }

    if ((log !== prevDesignInfo.log) || (startMsg && startMsg !== prevDesignInfo.startMsg)) {
      this.hasLockScroll();
    }
  }

  changeDesign = (designId) => {
    this.props.changeLayoutDesign(designId);
  }

  cancelProgress = (e) => {
    e && e.stopPropagation()
    const { designId } = this.props;
    this.props.cancelCheck(designId)
  }

  getWaitSpan = () => {
    const { topWidth } = this.state;
    if (topWidth <= 528 && topWidth >= 438) {
      return 14;
    } else if (topWidth < 438) {
      return 16;
    }
    return 10;
  }

  hasLockScroll = () => {
    if (this.scrollRef) {
      const { designInfo } = this.props;
      const { progress, waitingIndex } = designInfo;
      const { scrollHeight, scrollTop, clientHeight } = this.scrollRef;
      //calculate the position of the scroll bar
      const scrollBottom = scrollHeight - scrollTop - clientHeight;
      //when the scroll bar is at the bottom, the lock is invalid
      if (scrollBottom < 5
        && (scrollHeight !== clientHeight || isSimulationRunning(waitingIndex, progress))
      ) {
        this.setState({ lockScroll: false });
      }
      else { this.setState({ lockScroll: true }); }
    }
  }

  backBottomIcon = () => {
    const { lockScroll } = this.state;
    const { scrollHeight, clientHeight } = this.scroll || {};
    return lockScroll && scrollHeight !== clientHeight ? <Tooltip title="Back to bottom" overlayClassName='icon-tooltip'>
      <VerticalLeftOutlined className={`aurora-monitor-backbottom`} onClick={this.scrollToBottom} />
    </Tooltip> : null;
  }

  render() {
    const { visible,
      projectName,
      pageType,
      subOptionType,
      projectLayout = [],
      designId,
      designInfo = {}
    } = this.props;
    const { topWidth } = this.state;
    const { startMsg, endMsg, progress, monitor, log = [], status, waitingIndex = -1, waitingTime } = designInfo;
    const subItem = projectLayout.find(item => item.id === designId) || {}
    const waitingMsg = getWaitingMsg(waitingIndex);
    const monitorDisplay = status === RUNNING && monitor && monitor.length ? true : false;
    const logDisplay = !monitorDisplay && ![RUNNING, WAITING].includes(status) && log && log.length ? true : false;
    const subList = subOptionType === 'group' ? getDisplayDesignList(projectLayout) : projectLayout;
    return (
      <Fragment>
        <div className='aurora-simulation-title' id="aurora-simulation-title-id">
          <Row className='aurora-simulation-holygrail' span={24} style={{ width: topWidth - 36 }}>
            {projectLayout.length ? TitleRender({
              topWidth,
              projectName,
              waitingIndex: -1,
              subName: subItem.name || 'pcb',
              subList,
              progress: visible ? 2 : -1,
              changeSubName: this.changeDesign,
              selectedKey: "pcb",
              // isUploading,
              pageType,
              subOptionType,
              subSelect: { id: designId }
            }) : null}
            {status === RUNNING ? ProgressRender({ topWidth, progress }) : null}
            {status === WAITING ? WaitingRender({ topWidth, waitSpan: this.getWaitSpan(), waitingIndex, waitingTime }) : null}
          </Row>
          {([RUNNING, WAITING].includes(status)) ? CancelButton({ status, cancelProgress: this.cancelProgress }) : null}
        </div>
        <div className="my-aurora-monitor aurora-simulation-monitor"
          onScroll={() => this.hasLockScroll()}
          ref={c => { this.scrollRef = c; }}
        >
          <pre className="my-aurora-monitor-pre">
            {startMsg ? <span className='debug-mes-span'>{startMsg}</span> : null}
            {waitingMsg ? <span className='debug-mes-span'>{waitingMsg}</span> : null}
            <Monitor
              log={log ? log.join('\n') : null}
              monitor={monitor}
              monitorDisplay={monitorDisplay}
              logDisplay={logDisplay}
            />
            {endMsg ? <span className='debug-mes-span'>{endMsg}</span> : null}
          </pre>
        </div>
        {this.backBottomIcon()}
      </Fragment>
    )
  }
}

function ProgressRender({ topWidth, progress }) {
  return <Col className='aurora-layout-simulation-progress' span={16}>
    <Progress
      size={{ height: 14 }}
      strokeColor={'#1890ff'}
      percent={progress}
      className="simulation-progress-bar"
    />
  </Col>
}

function WaitingRender({ topWidth, waitSpan, waitingIndex, waitingTime }) {
  return <Col className='aurora-simulation-middle' span={waitSpan}>
    {topWidth > 717 ? <span className='simulation-waiting-title'> Simulation waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>
      : <span className='simulation-waiting-title'>Waiting: {waitingIndex} <span className='waiting-time-span'>{waitingTime}</span></span>}
  </Col>
}

function CancelButton({ status, cancelProgress }) {
  return <div className='aurora-simulation-btn'>
    <div className='aurora-cancel-button'>
      <Tooltip title='Cancel simulation' overlayClassName='icon-tooltip'>
        <span className='iconfont icon-cancel9' onClick={(e) => cancelProgress(e)}></span>
      </Tooltip>
    </div>
  </div>
}

export default LayoutMonitor;