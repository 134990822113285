import {
  AMISignalConfig,
  ADSConfig,
  getSignalAmiCompBySignalName
} from "../../AMIModelHelper";
import { ANDES_V2_CHANNEL_VERSION } from "../../../../version";
import { CONNECTOR, IC, SERDES_TYPES } from "../../../PCBHelper";
import Aggressors from "../../aggressors";
import { getPreLayoutAggressorsBySignal } from "../../preLayout";
import { CPHY } from "../../../PCBHelper/constants";
import { CPHYIBISSignalConfig } from "../../AMIModelHelper/IntegratedConfig";

function setDefaultAMIModel(channelInfo, { netsList, isPreLayoutChannel, serdesType }) {
  if (!channelInfo || !channelInfo.content || !channelInfo.content.signals) {
    return channelInfo;
  }
  const { controller, device } = getAMIComponents(channelInfo.content.components);
  const adsSignals = getDefaultAdsSignals({
    signals: channelInfo.content.signals,
    ICComps: channelInfo.content.components.filter(item => SERDES_TYPES.includes(item.type)),
    controller,
    device,
    netsList,
    selectedSignals: channelInfo.content.selectedSignals,
    isPreLayoutChannel,
    serdesType
  })

  const adsConfig = new ADSConfig({
    serdesType,
    signals: adsSignals,
    controller,
    device
  });
  channelInfo.adsConfig = adsConfig;
  channelInfo.version = ANDES_V2_CHANNEL_VERSION;
  return channelInfo;
}

function getAMIComponents(components) {
  const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
  let controller = "", device = "";
  if (ICComps.length >= 2) {
    const controllerComp = ICComps.find(item => item.type === IC);
    controller = controllerComp ? controllerComp.name : ICComps[0].name;
    const deviceComp = ICComps.find(item => item.type === CONNECTOR);
    device = deviceComp ? deviceComp.name : ICComps[1].name;
  }
  return { controller, device }
}

function getDefaultAdsSignals({
  signals,
  ICComps,
  controller,
  device,
  netsList,
  selectedSignals,
  isPreLayoutChannel,
  serdesType
}) {
  let adsSignals = [];
  const _signals = signals.filter(item => selectedSignals.includes(item.name));

  if (serdesType === CPHY) {
    for (let signal of signals) {
      const { txComp, rxComp } = getSignalAmiCompBySignalName({ signalName: signal.name, controller, device });

      adsSignals.push(
        new CPHYIBISSignalConfig({
          signalName: signal.name,
          controller: txComp,
          device: rxComp,
        })
      )
    }
    return adsSignals;
  }

  const aggressors = new Aggressors();
  if (!isPreLayoutChannel) {
    //calculate signal nets plump line and calculate the intersection of plump line and net trace 
    aggressors.calcIntersectionOfSignalNets(ICComps, signals, netsList);
  }
  for (let signal of signals) {
    let signalAggressors = [];
    if (isPreLayoutChannel) {
      signalAggressors = getPreLayoutAggressorsBySignal(ICComps, signal, _signals);
    } else {
      //get same connected components signals
      const sameGroupSignals = aggressors.getSignalGroups(signal.name) || [..._signals];
      signalAggressors = aggressors.getAggressorsByNet(ICComps, signal, sameGroupSignals)
    }

    const { txComp, rxComp } = getSignalAmiCompBySignalName({ signalName: signal.name, controller, device });

    adsSignals.push(
      new AMISignalConfig({
        signalName: signal.name,
        controller: txComp,
        device: rxComp,
        aggressors: [...signalAggressors]
      })
    )
  }
  return adsSignals;
}

function updateAdsConfigComp({
  adsConfig,
  components,
  serdesType
}) {
  if (!adsConfig.signals) {
    return adsConfig;
  }

  if (serdesType === CPHY) {
    return updateCPHYAdsConfigComp({
      adsConfig,
      components
    });
  }
  const compNames = components.map(item => item.name);
  if (adsConfig.controller && !compNames.includes(adsConfig.controller)) {
    adsConfig.controller = "";
  }
  if (adsConfig.device && !compNames.includes(adsConfig.device)) {
    adsConfig.device = "";
  }
  for (let sigItem of adsConfig.signals) {
    const modelKey = sigItem.IbisHasAMI === "no" ? "" : "Ami";
    if (sigItem[`tx${modelKey}Model`] && sigItem[`tx${modelKey}Model`].component && !compNames.includes(sigItem[`tx${modelKey}Model`].component)) {
      sigItem[`tx${modelKey}Model`].component = "";
    }

    if (sigItem[`rx${modelKey}Model`] && sigItem[`rx${modelKey}Model`].component && !compNames.includes(sigItem[`rx${modelKey}Model`].component)) {
      sigItem[`rx${modelKey}Model`].component = "";
    } else if (sigItem.rcModel && sigItem.rcModel.component && !compNames.includes(sigItem.rcModel.component)) {
      sigItem.rcModel.component = "";
    }

    if (sigItem.txCircuitModel && sigItem.txCircuitModel.component && !compNames.includes(sigItem.txCircuitModel.component)) {
      sigItem.txCircuitModel.component = "";
    }
    if (sigItem.rxCircuitModel && sigItem.rxCircuitModel.component && !compNames.includes(sigItem.rxCircuitModel.component)) {
      sigItem.rxCircuitModel.component = "";
    }
  }
  return adsConfig;
}

function updateCPHYAdsConfigComp({
  adsConfig,
  components
}) {
  const compNames = components.map(item => item.name);
  if (adsConfig.controller && !compNames.includes(adsConfig.controller)) {
    adsConfig.controller = "";
  }
  if (adsConfig.device && !compNames.includes(adsConfig.device)) {
    adsConfig.device = "";
  }

  for (let sigItem of adsConfig.signals) {
    if (sigItem.cphyTxModel && sigItem.cphyTxModel.component && !compNames.includes(sigItem.cphyTxModel.component)) {
      sigItem.cphyTxModel.component = "";
    }

    if (sigItem.cphyRxModel && sigItem.cphyRxModel.component && !compNames.includes(sigItem.cphyRxModel.component)) {
      sigItem.cphyRxModel.component = "";
    }
  }

  return adsConfig;
}

export {
  setDefaultAMIModel,
  getAMIComponents,
  getDefaultAdsSignals,
  updateAdsConfigComp
}