import { call, put, takeEvery, cancel, select, fork, delay } from 'redux-saga/effects';
import { START_ROCKY_SSN_VERIFICATION, START_ROCKY_MERGE_EXTRACTION, RECALCULATE_POWER_SUM_WORKFLOW } from './actionType';
import { verificationWorkFlow, updateWaitingStartMsg, getWorkflowTask, simulationErrorCheck, updateWorkflowTask } from '../saga';
import {
  updateSimulationReducer,
  updateCurrentSimulationInfo,
  changeVerificationList,
  getVerificationLog,
  getInterfaceMonitor
} from '../action';
import {
  doRockySSNSimulation,
  doRockySSNExtraction,
  getRockyPackagePDNInfo
} from '@/services/Rocky';
import {
  checkVerificationStatus,
  getVerificationWorkFlow
} from '@/services/workflow/workflow';
import { VERIFY_SUCCESS } from '@/constants/verificationStatus';
import { API_RES_MESSAGE } from '../../../../../constants/returnCode';
import { ResultData } from '@/services/Rocky/result';
import {
  updateErrorCheckList,
  updateTouchstoneStatusList
} from '../../rocky/action';
import {
  autoGetVerificationList
} from '../../project/action';
import RockySSNChannelInfo from '../../../../../services/Rocky/SSN/channelsInfo';
import { ssnErrorCheck, ssnPrelayoutJsonErrorCheck, getCPMPkgSettingErrorCheck } from '../../../errorCheck/ErrorCheck';
import dayjs from 'dayjs';
import projectDesigns from '../../../../../services/helper/projectDesigns';
import { PRE_LAYOUT } from '../../../../../constants/designVendor';
import { getDesignStackupJson } from '../../../../../services/api/designFile';
import { stackupErrorCheck } from '../../../../../services/Stackup';
import { getPreLayoutInfoById } from '../../../../../services/Rocky/preLayout';
import { MODEL } from '../../../../../services/Rocky/preLayout/preLayoutConfig';
import { preLayoutJsonErrorCheck } from '../../../../../services/helper/dataProcess';
import designConstructor from '../../../../../services/helper/designConstructor';
import { SIGNAL_EXTRACTION, SINGLE_PATTERN, PDN_EXTRACTION, SSN_CHANNEL } from '../../../../../services/Rocky/constants';
import packageVerificationConstructor from '../../../../../services/Rocky/PackageHelper';
import { getPCBPackageErrorCheck } from '../../rocky/saga';
import { changeTabMenu, openTabFooter } from '../../../../MonitorStore/action';
import { PACKAGE_VERIFICATION, PCB_CHANNEL, SSN_RESULT } from '../../../../../constants/treeConstants';
import RockyChannels from '../../../../../services/Rocky/helper/channels';
import PackageVerificationInfoConstructor from '@/services/Rocky/Package/packageVerificationHelper'
import pcbChannelConstructor from '../../../../../services/Rocky/pcbChannelConstructor';

let workflowTask = getWorkflowTask();

function* startSSNVerification(action) {
  let { verificationIds, currentVerificationId, openSSNVerificationIds, isSSN, ssnVerificationId, /* packageIsPreLayout, pcbIsPreLayout  */ } = action;

  yield put(changeVerificationList([]));
  if (verificationIds.length === 0) {
    return;
  }
  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }

  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let { RockyReducer: { rocky: { infoErrorCheck, TouchstoneStatusList } } } = yield select();

  let currentChannels = [], currentSimInfo = [];
  currentSimInfo.push({
    verificationId: ssnVerificationId,
    status: 'checking'
  });
  const time = new Date();
  time.setUTCSeconds(time.getUTCSeconds());
  let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
  let logList = [];
  if (newSimulationReducer[ssnVerificationId].logList && newSimulationReducer[ssnVerificationId].logList.length) {
    logList = newSimulationReducer[ssnVerificationId].logList.filter(it => it.displayName !== "Simulation")
  }

  delete newSimulationReducer[ssnVerificationId];
  newSimulationReducer[ssnVerificationId] = {
    verificationId: ssnVerificationId,
    startMsg: `==> ${waitingTime} Checking setup...`,
    cancelDisabled: true,
    running: true,
    logList
  }
  verificationIds.forEach(item => {
    //clean prev simulation info by verificationId
    delete newSimulationReducer[item];
    newSimulationReducer[item] = {
      verificationId: item,
      startMsg: '==> Checking setup...',
      ssnVerificationId: ssnVerificationId
    }

    //clear prev error message
    infoErrorCheck = infoErrorCheck.filter(it => it.verificationId !== item);
    //clear prev verification touchstone macro modeling status message
    TouchstoneStatusList = TouchstoneStatusList.filter(it => it.verificationId !== item);
    //clear result data
    ResultData.cleanVerificationResultData(item);
  });

  //update verification error message
  yield put(updateErrorCheckList(infoErrorCheck));

  //update verification Touchstone macro modeling status message
  yield put(updateTouchstoneStatusList(TouchstoneStatusList));

  yield put(updateSimulationReducer(newSimulationReducer));
  const { RockyReducer: { project: { currentProjectId, currentChannelId, ssnSelectPDNIds } } } = yield select();
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId, currentSimInfo }));

  // ssn error list
  const { errorCheck, stackupError, prelayoutError, pkgStackupError, pkgPrelayoutError, pkgPdnError } = yield call(sevSimulationErrorCheck, { ssnVerificationId, verificationIds })

  //error check 
  //stackup / pdn / verification
  const info = yield call(simulationErrorCheck, { currentChannels, ssnSelectPDNIds, verificationIds, currentSimInfo, isSSN, openSSNVerificationIds, ssnVerificationId, notGetPkgConfig: true });
  //{ channelId, verificationIds: [id1,id2], pdnId }
  let channels = info ? info.channels : [];
  verificationIds = info ? info.verificationIds : [];
  let errorCheckIds = info ? info.errorCheckIds : [];

  if (ssnVerificationId && ((errorCheckIds && errorCheckIds.length)
    || (errorCheck && errorCheck.length)
    || (stackupError && stackupError.length)
    || prelayoutError
    || (pkgStackupError && pkgStackupError.length)
    || pkgPrelayoutError
    || pkgPdnError
  )) {
    // In the case of running SSN flow, if one cannot run, then none can run
    let nameList = [], errorList = [];
    if (pkgPdnError && pkgPdnError.error) {
      errorList.push(...pkgPdnError.error)
    }

    if (errorCheck && errorCheck.length) {
      errorList.push(...errorCheck)
    }

    let interfaceErrorList = []
    if (errorCheckIds && errorCheckIds.length) {
      if (openSSNVerificationIds && openSSNVerificationIds.length) {
        let list = yield call(RockySSNChannelInfo.getInterfaceList, currentChannelId);
        // nameList = list.filter(item => errorCheckIds.includes(item.id)).map(item => item.name);
        interfaceErrorList = list.filter(item => errorCheckIds.includes(item.id)).map(item => { return { name: item.name, id: item.id } });
        nameList = interfaceErrorList.map(item => item.name)
      }

      errorList.push(`==> The settings of ${nameList.join(', ')} are incomplete.`)
    }

    yield put(updateCurrentSimulationInfo({ currentVerificationId, info: null, item: "startMsg" }));
    let { RockyReducer: { rocky: { infoErrorCheck } } } = yield select();
    let _infoErrorCheck = infoErrorCheck
    _infoErrorCheck.push({
      verificationId: currentVerificationId,
      errorCheck: { error: errorList },
      interfaceErrorList: [...interfaceErrorList],
    })

    yield put(updateErrorCheckList(_infoErrorCheck));

    let { RockyReducer: { simulationReducer } } = yield select();
    verificationIds.forEach(item => {
      simulationReducer[item] = {};
    })
    simulationReducer[ssnVerificationId] = {
      stackupError: stackupError,
      prelayoutError: prelayoutError,
      pkgStackupError: pkgStackupError,
      pkgPrelayoutError: pkgPrelayoutError,
    };
    //update simulation reducer
    yield put(updateSimulationReducer(simulationReducer));
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
    return
  }

  if (!channels || channels.length === 0) {
    return;
  }

  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));

  // to do result
  // // clean result cache 
  // // eyedigram result cache
  // eyediagramResult.cleanCache(verificationIds);
  // //customized eye diagram cache
  // CustomizedEye.cleanCache(verificationIds);
  reducer = yield select();
  simulationReducer = reducer.RockyReducer.simulationReducer;
  channels.forEach(item => { item.ssnVerificationId = ssnVerificationId; delete item.channelId });
  let waitingTask = null;
  try {
    //const msgKey = !packageIsPreLayout && !pcbIsPreLayout ? "PCB | package" : (!pcbIsPreLayout ? "PCB" : !packageIsPreLayout ? "package" : "")
    //const msg = `Performing ${msgKey} S-parameter model port reduction. This process can take up to ${!packageIsPreLayout && !pcbIsPreLayout ? "4" : "2"} minutes...`
    const msg = `Performing S-parameter model port reduction...`

    waitingTask = yield fork(updateWaitingStartMsg, {
      verificationIds: [ssnVerificationId],
      _simulation: simulationReducer,
      isSSN: true,
      ssnMsg: msg
    })
    yield call(doRockySSNSimulation, ssnVerificationId);
    yield cancel(waitingTask);
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    if (ssnVerificationId) {
      verificationIds.forEach(item => {
        simulationReducer[item] = {};
      })
      simulationReducer[ssnVerificationId] = {}
      let msg = ""
      if (error && error.code === API_RES_MESSAGE) {
        msg = `==> ${error.msg}`;
        // get log
        yield put(getVerificationLog(ssnVerificationId));
        simulationReducer[ssnVerificationId].simulationMsg = msg;
      } else {
        msg = `==> ${error}`;
        simulationReducer[ssnVerificationId].endMsg = msg;
      }

    } else {
      yield* verificationIds.map(function* (item) {
        simulationReducer[item] = {};
        let msg = ""
        if (error && error.code === API_RES_MESSAGE) {
          msg = `==> ${error.msg}`;
          // get log
          yield put(getVerificationLog(item));
          simulationReducer[item].simulationMsg = msg;
        } else {
          msg = `==> ${error}`;
          simulationReducer[item].endMsg = msg;
        }
      })
    }

    //update simulation reducer
    yield put(updateSimulationReducer(simulationReducer));
    //update tree verification status
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));

    yield delay(1000);
    yield put(updateCurrentSimulationInfo({ currentVerificationId, item: "startMsg", info: null }));
    yield put(updateCurrentSimulationInfo({ currentVerificationId, item: "progress", info: -1 }));
    yield put(changeVerificationList([]));
    const promise = yield call(checkVerificationStatus, currentVerificationId);
    let resultExist = false;

    if (promise && promise.status) {
      if (promise.status === VERIFY_SUCCESS) {
        resultExist = true;
      }
    };
    yield put(updateCurrentSimulationInfo({ currentVerificationId, item: "resultExist", info: resultExist }));

    return;
  }

  // fake progress
  // id: workflowId
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  //update cancel disable status
  let newReducer = yield select();
  let _simulationReducer = newReducer.RockyReducer.simulationReducer;
  if (_simulationReducer[ssnVerificationId]) {
    _simulationReducer[ssnVerificationId].cancelDisabled = false;
    /*  _simulationReducer[ssnVerificationId].running = false; */
  }
  yield put(updateSimulationReducer(_simulationReducer));
  const verificationStatus = yield call(getVerificationWorkFlow, currentVerificationId);
  if (verificationStatus && verificationStatus.length > 0) {
    if (workflowTask) {
      yield cancel(workflowTask);
    };

    const _workflowTask = yield fork(verificationWorkFlow, { verificationId: currentVerificationId, verificationWork: verificationStatus, dataType: SINGLE_PATTERN });
    updateWorkflowTask(_workflowTask);
    return;
  };
}

function* sevSimulationErrorCheck(action) {
  const { ssnVerificationId, verificationIds } = action;
  let { RockyReducer: { project: { currentProjectId, onDieTouchstoneList, onDieSpiceList, pdnTouchstoneList, pdnSpiceList, pcbTouchstoneList, pcbSpiceList }, rockySSN: { ssn, content, verificationId, designId, channelId } } } = yield select();
  let errorCheck = null, pkgStackupError = null, pkgPrelayoutError = null, pkgPdnError = null;
  let stackupError = null, prelayoutError = null;
  const nameList = ssn.filter(item => verificationIds.includes(item.id)).map(item => item.name)
  let obj = {
    signal: false,
    pdn: false,
    pcbId: designId,
    pcbSignal: false,
    pcbPDN: false,
    packageSignal: false,
    packagePdn: false
  }

  if (ssnVerificationId === verificationId) {
    errorCheck = ssnErrorCheck({ ssn, content, libraryInfo: { onDieTouchstoneList, onDieSpiceList, pdnTouchstoneList, pdnSpiceList }, designId })
    const interfaceList = ssn || [];
    const packageLayout = interfaceList && interfaceList.length ? interfaceList[0].soc : null;
    const packageId = packageLayout && packageLayout.pkg ? packageLayout.pkg.packageId : null;
    const pkg = designConstructor.getDesign(packageId) || {};
    if (pkg.vendor === PRE_LAYOUT && pkg && pkg.id) {
      const checkInfo = yield call(stackupAndPreLayoutCheck, { vendor: pkg.vendor, designId: packageId, nameList, pcbTouchstoneList, pcbSpiceList });
      pkgStackupError = checkInfo.stackupError;
      pkgPrelayoutError = checkInfo.prelayoutError;
    } else if (pkg && pkg.id) {
      obj = {
        ...obj,
        packageSignal: true,
        packagePdn: content.includePdn ? true : false
      }
    }

    if (content && content.includePdn && content.pdn && content.pdn.onDies && content.pdn.onDies.find(item => item.onDie.type === "CSM")) {
      // If there is CSM, you need to make sure that the type of package PDN is pin group
      let packagePdnInfo = packageVerificationConstructor.getPackagePDN(packageId);
      const { RockyReducer: { rocky: { packagePDNInfo } } } = yield select();
      let res = {}
      if (packagePDNInfo && packagePDNInfo.id === packagePdnInfo.id) {
        res = { ...packagePDNInfo };
      } else {
        res = yield call(getRockyPackagePDNInfo, packagePdnInfo.id);
      }

      pkgPdnError = getCPMPkgSettingErrorCheck(res)
    }
  }
  //check stackup data (if pcb is prelayout, check signal group);

  //PCB pre layout
  const vendor = projectDesigns.getAvailableDesignsVendor(currentProjectId)

  if (vendor === PRE_LAYOUT && designId) {
    const checkInfo = yield call(stackupAndPreLayoutCheck, { vendor, designId, nameList, pcbTouchstoneList, pcbSpiceList });
    stackupError = checkInfo.stackupError;
    prelayoutError = checkInfo.prelayoutError;
  } else if (designId) {
    obj = {
      ...obj,
      pcbSignal: true,
      pcbPDN: content.includePdn ? true : false
    }
  }

  // Because onePass flow will automatically start package pdn/signal & pcb pdn/signal extraction, the data in it needs to be verified
  const { error, _stackupError } = yield call(getPCBPackageErrorCheck, { obj, ssn, channelId, verificationId })
  if ((_stackupError && _stackupError.length)) {
    stackupError = [..._stackupError]
  }

  if ((error && error.length)) {
    errorCheck.push(...error)
  }

  return { errorCheck, stackupError, prelayoutError, pkgStackupError, pkgPrelayoutError, pkgPdnError }
}

function* stackupAndPreLayoutCheck({
  vendor,
  designId,
  nameList,
  pcbTouchstoneList,
  pcbSpiceList
}) {
  try {
    let stackupError = null, prelayoutError = null;
    if (vendor !== PRE_LAYOUT) {
      //DESIGN stackup.json error check
      const stackup = yield call(getDesignStackupJson, designId);
      if (!stackup || !stackup.layers) {
        stackupError = [{ error: 'Stackup file does not exist!' }];
      } else {
        stackupError = stackupErrorCheck({
          ...stackup,
          layers: stackup.layers,
          materialList: stackup.materials,
          unit: stackup.unit,
        });
      }
    } else {
      const res = yield call(getPreLayoutInfoById, designId);
      prelayoutError = res && res.content ? res.content.prelayout === MODEL ? ssnPrelayoutJsonErrorCheck(res.content.components, nameList, pcbTouchstoneList, pcbSpiceList, res.content.model && res.content.model.modelType ? res.content.model.modelType : null) : preLayoutJsonErrorCheck(res.content['signal_groups']) : { error: ['preLayout.json file does not exist!'] };
    }
    return { stackupError, prelayoutError }
  } catch (error) {
    console.error(error)
    return { stackupError: null, prelayoutError: null }
  }
}

function* startMergeExtraction(action) {
  const { obj } = action;
  const { signal, pdn, verificationId, id, mergeVerificationId } = obj;
  if (!verificationId) {
    return
  }
  //cancel prev workflow task
  if (workflowTask) {
    yield cancel(workflowTask);
  }
  let reducer = yield select();
  let simulationReducer = reducer.RockyReducer.simulationReducer;
  let newSimulationReducer = simulationReducer ? JSON.parse(JSON.stringify(simulationReducer)) : {};

  let currentSimInfo = [];
  currentSimInfo.push({
    verificationId: verificationId,
    status: 'checking'
  });
  let logList = [];
  if (newSimulationReducer[verificationId].logList && newSimulationReducer[verificationId].logList.length) {
    logList = newSimulationReducer[verificationId].logList.filter(item => item.displayName === "Simulation")
  }
  delete newSimulationReducer[verificationId];

  const time = new Date();
  time.setUTCSeconds(time.getUTCSeconds());
  let waitingTime = dayjs(time.toUTCString()).format('HH:mm:ss');
  newSimulationReducer[verificationId] = {
    verificationId: verificationId,
    startMsg: `==> ${waitingTime} Checking setup...`,
    cancelDisabled: true,
    running: true,
    logList
  }
  ResultData.cleanVerificationResultData(id);
  yield put(updateSimulationReducer(newSimulationReducer));
  const { RockyReducer: { project: { currentProjectId, currentChannelId } } } = yield select();
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId, currentSimInfo }));

  let waitingTask = null;
  reducer = yield select();
  simulationReducer = reducer.RockyReducer.simulationReducer;
  try {
    waitingTask = yield fork(updateWaitingStartMsg, {
      verificationIds: [verificationId],
      _simulation: simulationReducer,
      isSSN: true,
    })
    const response = yield call(doRockySSNExtraction, { verificationId: mergeVerificationId, signal, pdn });
    yield cancel(waitingTask);
    if (!response) {
      newSimulationReducer[verificationId] = {};
      const title = signal && pdn ? "PDN and Signal" : signal ? "Signal" : "PDN";
      let msg = `==> Merge ${title} has been extracted completely, no need to extract again!`;
      newSimulationReducer[verificationId].simulationMsg = msg;
      yield put(getVerificationLog(verificationId, { pageType: SSN_CHANNEL }));
      yield put(updateSimulationReducer(newSimulationReducer));
      yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
      return
    }
  } catch (error) {
    if (waitingTask) { yield cancel(waitingTask); }
    newSimulationReducer[verificationId] = {};
    let msg = error ? `==> [ERROR] ${error}` : "==> Simulation failed!";
    newSimulationReducer[verificationId].endMsg = msg;
    //update simulation reducer
    yield put(updateSimulationReducer(newSimulationReducer));
    yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
    //update tree verification status
    return
  }

  // fake progress
  // id: workflowId
  yield put(autoGetVerificationList({ projectId: currentProjectId, channelId: currentChannelId }));
  //update cancel disable status
  let newReducer = yield select();
  let _simulationReducer = newReducer.RockyReducer.simulationReducer;
  if (_simulationReducer[verificationId]) {
    _simulationReducer[verificationId].cancelDisabled = false;
    /*  _simulationReducer[ssnVerificationId].running = false; */
  }
  yield put(updateSimulationReducer(_simulationReducer));
  const verificationStatus = yield call(getVerificationWorkFlow, mergeVerificationId);
  if (verificationStatus && verificationStatus.length > 0) {
    if (workflowTask) {
      yield cancel(workflowTask);
    };
    const _workflowTask = yield fork(verificationWorkFlow, { verificationId: verificationId, verificationWork: verificationStatus, dataType: signal ? SIGNAL_EXTRACTION : PDN_EXTRACTION });
    updateWorkflowTask(_workflowTask);
    return;
  };
}

function* recalculatePowerSumWorkflow(action) {
  try {
    const { workflow, verificationId, obj } = action;

    let dataType = null, mergeVerificationId = null, _verificationId = verificationId;
    const { resultPageType, resultType, channelId } = obj;
    if (resultPageType === SSN_RESULT) {
      let menuObj = {};
      if (resultType === "Merged") {
        dataType = SIGNAL_EXTRACTION;
        const mergeInfo = RockySSNChannelInfo.getInfo(channelId);
        mergeVerificationId = mergeInfo.mergeVerificationId;
        _verificationId = mergeInfo.verificationId;

        const ssnCurrent = RockyChannels.get(channelId);
        menuObj = {
          menuType: "simulation",
          tabSelectKeys: ['monitor'],
          channelName: ssnCurrent ? ssnCurrent.name : null,
          currentChannelId: "SSN",
          verificationName: ssnCurrent ? ssnCurrent.name : null,
          currentVerificationId: ssnCurrent ? ssnCurrent.verificationId : null,
          dataType: SIGNAL_EXTRACTION
        }
      } else if (resultType === "Separate PCB") {
        const currentInfo = pcbChannelConstructor.get(channelId)
        menuObj = {
          menuType: "simulation",
          tabSelectKeys: ['monitor'],
          verificationName: currentInfo ? currentInfo.name : null,
          currentVerificationId: currentInfo ? currentInfo.pcbChannelVerificationId : null,
          channelName: currentInfo ? currentInfo.name : null,
          currentChannelId: currentInfo ? currentInfo.id : null,
          dataType: PCB_CHANNEL
        }
      } else {
        const current = packageVerificationConstructor.getPackageVerification(channelId);
        menuObj = {
          menuType: "simulation", tabSelectKeys: ['monitor'],
          currentChannelId: current.id,
          verificationName: current.name,
          currentVerificationId: current.verificationId,
          dataType: PACKAGE_VERIFICATION
        }
      }

      yield put(changeTabMenu({
        ...menuObj
      }))
    }

    if (workflowTask) {
      yield cancel(workflowTask);
    }
    yield put(openTabFooter());
    const _workflowTask = yield fork(verificationWorkFlow, { verificationId: _verificationId, verificationWork: workflow, dataType, isMergeVerification: mergeVerificationId, notUpdateLog: true });
    updateWorkflowTask(_workflowTask);
  } catch (error) {
    console.error(error);
  }
}

function* rockySSNSimulationSaga() {
  yield takeEvery(START_ROCKY_SSN_VERIFICATION, startSSNVerification);
  yield takeEvery(START_ROCKY_MERGE_EXTRACTION, startMergeExtraction);
  yield takeEvery(RECALCULATE_POWER_SUM_WORKFLOW, recalculatePowerSumWorkflow);
}
export default rockySSNSimulationSaga;