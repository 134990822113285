import {
  PortsGenerationSetup,
  ALL_PINS,
  AutoGeneratePorts,
  GAP,
  NEARBY_PINS,
  PIN_PER_REF_NET,
  updateSetupComponentsByPortType
} from '@/services/ExtractionPortsHelper';
import { DDR_COMP_TYPES } from '../../PCBHelper';
import DesignInfo from '../pcbInfo';
import { splitComponentName } from '../../helper/splitComponent';

function judgeUpdateGapPortSetup(data, extraction) {
  // if (extraction.channelType !== "SIwave") {
  //   return false;
  // }
  const ports_generate_setup_list = data.Ports_generate_setup_list || [];

  for (let item of ports_generate_setup_list) {
    //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
    //re generate port setups by port type is GAP, reference type is All pins
    if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
      return true;
    }
  }
  return false;
}

function updateExtractionGapPortsSetup(data, designId) {
  let newData = { ...data };
  let ports_generate_setup_list = newData.Ports_generate_setup_list;
  const ReferenceNets = newData.ReferenceNets;
  let port_setups = newData.Port_setups;
  let applyComponents = [];

  // //when extraction type HFSS to SIwave, and port type is GAP,and reference type is nearby pins or single pin pre reference nets
  // //re generate port setups by port type is GAP, reference type is All pins

  ports_generate_setup_list.forEach(item => {
    if (item.setup.portType === GAP && [NEARBY_PINS, PIN_PER_REF_NET].includes(item.setup.referenceType)) {
      item.setup = new PortsGenerationSetup({
        portType: GAP,
        referenceType: ALL_PINS,
      });
      applyComponents.push(item.component);
    }
  });

  const autoPorts = new AutoGeneratePorts();
  const pcbInfo = DesignInfo.getPCBInfo(designId);
  const _data = autoPorts.autoGeneratePorts(
    port_setups,
    pcbInfo,
    {
      ports_generate_setup_list,
      applyComponents,
      referenceNets: ReferenceNets,
      referenceZ0: port_setups[0].z0,
      designId: designId
    }
  );
  port_setups = _data.port_setups;
  ports_generate_setup_list = _data.setupList;

  const warnings = autoPorts.getWarnings();
  const errors = autoPorts.getErrors();

  if (!errors || !errors.length) {
    newData.Ports_generate_setup_list = ports_generate_setup_list;
    newData.Port_setups = port_setups;
  }

  return { newData, warnings, errors };
}

function updatePortsComponentsByPortType(newInterfaces, Ports_generate_setup_list, designId, extractionType) {
  newInterfaces.forEach(item => {
    item.Content.Components = updateSetupComponentsByPortType({
      components: item.Content.Components,
      designId,
      ports_generate_setup_list: Ports_generate_setup_list,
      extractionType,
      isUsedBall:true
    });
  })
  return newInterfaces;
}

function getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components }) {
  let _ports_generation_setup = new PortsGenerationSetup({});

  if (Ports_generation_setup && Ports_generation_setup.portType) {
    _ports_generation_setup = Ports_generation_setup;
  }

  let ports_generate_setup_list = [];
  for (let comp of Components) {
    if (!DDR_COMP_TYPES.includes(comp.type)) {
      continue;
    }

    const compName = splitComponentName(comp.name);
    const find = ports_generate_setup_list.find(it => it.component === compName);
    if (!find) {
      ports_generate_setup_list.push({
        component: splitComponentName(comp.name),
        setup: JSON.parse(JSON.stringify(_ports_generation_setup))
      });
    }
  }
  return ports_generate_setup_list;
}

export {
  getDefaultPortsGenerateSetupList,
  judgeUpdateGapPortSetup,
  updatePortsComponentsByPortType,
  updateExtractionGapPortsSetup
}