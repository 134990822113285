import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Dropdown } from "antd";
import Table from '@/components/EditableTable';
import {
  getSignalPinColumns,
  getSignalPinsData,
  getRowClassName,
  getGroupList,
  getComponentRender,
  getSignalCheckBox,
  getSignalTitleCheckBox,
  getSignalColumnRender,
  checkICComp,
  getICCompNetPins
} from '@/services/Andes_v2/channel';
import {
  updateChannelContent,
  addSignal,
  saveSelectNets,
  changeSignalName,
  deleteSignal,
  changeGroupName,
  updateReferenceNets,
  checkedSignals,
  updateICCompPin
} from "../store/channel/action";
import { autoFilterSignalNets, SERDES_TYPES } from "../../../services/PCBHelper";
import RelateNetsGroup from '../../../services/PCBHelper/relateNets';
import RelatedNetsRender from "../../../components/RelatedNetsRender";
import { getNetsOptions, NEW_GROUP } from "../../../services/Andes_v2/channel";
import { PRE_LAYOUT } from "../../../constants/designVendor";
import { countTime, closeCountTime } from "../../../services/helper/hasOperate";
import { CPHY, HDMI } from "../../../services/PCBHelper/constants";
import { strDelimited } from "../../../services/helper/split";
class SignalPinTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      PCBNets: [],
      relatedShow: true,
      onSearchValue: null,
      signalPinColumns: getSignalPinColumns(props.components, props.serdesType)
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //init related nets group
    this.relatedGroups = new RelateNetsGroup(this.props.serdesType);
    this.columnsOnCell();
  }

  componentDidUpdate = (prevProps) => {
    const { components = [], channelId, serdesType } = this.props;

    //switch channel or ic / connector component updated, update signal columns
    if (channelId !== prevProps.channelId || checkICComp(prevProps.components, components) || serdesType !== prevProps.serdesType) {
      //update signalPinColumns
      this.columnsOnCell();
    }

    if (channelId !== prevProps.channelId) {
      //clear count update reference nets time
      closeCountTime();
    }
  }

  getPCBNets = () => {
    const { pcbNetsList, channelDesignId } = this.props;
    const filter = autoFilterSignalNets(pcbNetsList, true, channelDesignId);
    this.setState({
      PCBNets: filter
    });
  }

  columnsOnCell = () => {
    const { components = [], serdesType } = this.props;
    let signalPinColumns = getSignalPinColumns(components, serdesType);
    signalPinColumns.forEach((item, index) => {
      switch (item.dataIndex) {
        case "check":
          item.title = () => {
            const { selectedSignals, signals } = this.props;
            return getSignalTitleCheckBox({
              selectedSignals,
              signals,
              checkSignals: this.checkSignals
            })
          }

          item.render = (name, record) => {
            return getSignalCheckBox({ record, checkSignals: this.checkSignals, serdesType })
          };
          break;
        case "group":
          item.render = (name, record) => {
            return {
              children: <span>{name}</span>,
              props: {
                rowSpan: record.groupLength,
                tdClassName: 'aurora-group-background'
              }
            }
          };
          item.onCell = (record) => {
            const { designVendor } = this.props;
            if (designVendor !== PRE_LAYOUT) {
              return {
                record,
                edit: true,
                dataIndex: 'group',
                handleSave: this.props._changeGroupName
              }
            }
          }
          break;
        case "signal":
          item.title = () => {
            return (
              <Fragment>
                <span>Signal</span>
                {this.getEyeEnabled() ? <EyeOutlined
                  className="andes-v2-select-nets-icon"
                  title={'Display all signal nets to layout.'}
                  onClick={() => this.allNetsSelect()} /> : null}
              </Fragment>
            );
          }

          item.render = (name, record) => {
            const { designVendor } = this.props;
            return getSignalColumnRender({
              name,
              record,
              designVendor,
              delSignal: this.delSignal,
              signalSelect: this.signalSelect,
              serdesType
            });
          };

          item.onCell = (record) => {
            const { designVendor } = this.props;
            if (designVendor !== PRE_LAYOUT) {
              return {
                record,
                edit: true,
                dataIndex: 'signal',
                handleSave: this.props._changeSignalName
              }
            }
          }
          break;
        case "nets":
          item.render = (name, record) => {
            return {
              children: <span>{record.nets && record.nets.length ? record.nets.join(", ") : <span className='aurora-placeHolder'>{record.netPlaceHolder}</span>}</span>,
              props: {
                tdClick: (e) => this.netSelect(record.nets)
              }
            }
          };

          item.onCell = (record) => { return this.getNetSelectRender(record) }
          break;
        default: break;
      }
      if (item.dataIndex.match("component")) {
        item/* .children[0] */.render = (name, record) => { return getComponentRender(record, item.dataIndex) };

        item.onCell = (record) => {
          const { channelDesignId, compsInfo } = this.props;
          const compName = strDelimited(item.dataIndex, "_", { returnIndex: 1 });
          const compPinInfo = getICCompNetPins({ compName, channelDesignId, record, compsInfo });
          if (!compPinInfo || !compPinInfo.pins.length || compPinInfo.pins.length < 2) {
            return { edit: false }
          }
          return {
            edit: "select",
            record: { ...record, selectedPin: compPinInfo.selectedPin.pin },
            dataIndex: "selectedPin",
            options: compPinInfo.pins.map(item => item.pin) || [],
            handleSave: (_record) => this.updateComponentPins(_record, compName, compPinInfo.pins)
          }
        }
      }
    });

    this.setState({
      signalPinColumns
    })
  }
  checkSignals = (e, key, type) => {
    const checked = e && e.target ? e.target.checked : false;
    this.props._checkedSignals(checked, key, type);
  }

  delSignal = (e, record) => {
    e && e.stopPropagation();
    this.props._deleteSignal(record);
    countTime(this.gerRefNets, 10000);
  }

  updateComponentPins = (record, compName, pins) => {
    const findPin = pins.find(item => item.pin === record.selectedPin);
    if (!findPin) {
      return;
    }
    this.props._updateICCompPin(record, compName, findPin);
  }

  getEyeEnabled = () => {
    const { selectedDesignIDs, channelDesignId, viewList } = this.props;
    return selectedDesignIDs.includes(channelDesignId) && viewList && viewList.length > 1;
  }

  getNetSelectRender = (record) => {
    const { relatedShow, onSearchValue, PCBNets } = this.state;
    const { channelDesignId, serdesType, signals, designVendor, pcbNetsList, components } = this.props;
    if (designVendor !== PRE_LAYOUT) {
      return {
        record,
        edit: 'select',
        netSelect: true,
        dataIndex: "nets",
        selectMode: 'multiple',
        handleSave: this.saveSelectedNets,
        selectType: "net",
        onFocus: () => this.netSelect(record.nets),
        dropdownRender: (form, clearInputValue) => RelatedNetsRender({
          form,
          record,
          selectedNets: record.nets,
          type: record.netTypes,
          relatedShow,
          selectNets: this.saveNets,
          getNetsOptions: () => getNetsOptions({
            record,
            channelDesignId,
            PCBNets,
            serdesType,
            signals,
            onSearchValue,
            pcbNetsList,
            relatedGroups: this.relatedGroups,
            components
          }),
          relatedShowChange: this.relatedShowChange,
          clearInputValue
        }),
        onSearch: (value) => this.nzOnSearch(value),
        clearSelectStatus: () => this.clearSelectStatus(),
        dropdownMenuClassName: 'andes-v2-channel-select-dropdown-menu',
        getPopupContainer: document.getElementById("andes-v2-content-main"),
      }
    }
  }

  clearSelectStatus = () => {
    this.setState({
      onSearchValue: null
    });
  }

  saveSelectedNets = (record, prevRecord) => {
    const { nets } = record;
    const options = this.state.PCBNets || [];
    const newNets = nets.filter(item => options.includes(item));
    this.props._saveSelectNets({ newNets, record, prevNets: prevRecord.nets });
    countTime(this.gerRefNets, 10000);
  }

  saveNets = ({ e, record, value, form }) => {
    //value -. current click net name
    e && e.stopPropagation();
    const { nets } = record;
    const options = this.state.PCBNets || [];
    let _nets = [];
    if (nets.includes(value)) {
      //removed value
      _nets = nets.filter(item => item !== value);
    } else {
      //add new net (value)
      _nets = [...nets, value]
    }
    //filter nets by pcb nets
    const newNets = _nets.filter(item => options.includes(item));
    this.props._saveSelectNets({ newNets, record, prevNets: nets });
    form && form.resetFields();
    form && form.setFieldsValue({ nets: newNets });
    countTime(this.gerRefNets, 10000);
  }

  gerRefNets = () => {
    this.props._updateReferenceNets();
  }

  nzOnSearch = (value) => {
    this.setState({
      onSearchValue: value
    })
  }

  relatedShowChange = (e) => {
    e.stopPropagation();
    const { relatedShow } = this.state;
    this.setState({
      relatedShow: !relatedShow
    })
  }

  allNetsSelect = () => {
    const { signals, serdesType } = this.props;
    const comps = this.getSerdesComps();
    let nets = [];
    for (let item of signals) {
      nets = serdesType === CPHY ? [...nets, ...item.nets_A, ...item.nets_B, ...item.nets_C] : [...nets, ...item.nets_P, ...item.nets_N];
    }
    this.props.displayToLayout(comps, nets, this.getPinObj(comps, nets));
  }

  signalSelect = (signal) => {
    if (!this.getEyeEnabled()) {
      return;
    }
    const { signals, serdesType } = this.props;
    const comps = this.getSerdesComps();
    let nets = [];
    for (let item of signals) {
      if (item.name === signal) {
        nets = serdesType === CPHY ? [...item.nets_A, ...item.nets_B, ...item.nets_C] : [...item.nets_P, ...item.nets_N]
        break;
      }
    }
    this.props.displayToLayout(comps, nets, this.getPinObj(comps, nets));
  }

  getPinObj = (comps, pins) => {
    const { components } = this.props;
    let obj = {};
    comps.forEach(c => obj[c] = []);
    for (let comp of components) {
      if (obj[comp.name]) {
        obj[comp.name] = comp.pins.filter(d => pins.includes(d.net)).map(d => d.pin);
      }
    }
    return obj;
  }

  getSerdesComps = () => {
    const { components } = this.props;
    return components ? components.filter(item => SERDES_TYPES.includes(item.type)).map(item => item.name) : [];
  }

  netSelect = (nets) => {
    if (!this.getEyeEnabled()) {
      return;
    }
    const comps = this.getSerdesComps();
    this.props.displayToLayout(comps, nets, this.getPinObj(comps, nets));
  }

  addSignalMenu = () => {
    const groupList = getGroupList(this.props.signals);
    return groupList.map(item => ({ key: item, title: item === NEW_GROUP ? `Add new Signal to ${item}.` : `Add new Signal to ${item} Group.`, label: item }))
  }

  selectMenu = ({ key }) => {
    const groupList = getGroupList(this.props.signals);
    this.addSignal(key, groupList)
  }

  getGroupSignalLength = (group) => {
    const { signals } = this.props;
    const signalList = signals.filter(item => item.group === group);
    return signalList.length;
  }

  addSignal = (group, groupList) => {
    this.props._addSignal(group, groupList);
    //The table has a scroll bar to scroll to the bottom
    /*  setTimeout(() => {
       const signalLength = this.getGroupSignalLength(group);
       const table = ReactDOM.findDOMNode(this.table),
         tableBody = table ? table.querySelector('.aurora-table-body') : null;
       if (signalLength > 0) {
         tableBody.scrollTop = 78 * (signalLength - 1);
       } else if (tableBody && tableBody.scrollHeight) {
         tableBody.scrollTop = tableBody.scrollHeight;
       }
     }, 240); */
  }

  addClick = (e) => {
    const { serdesType, signals } = this.props;
    e.stopPropagation();
    if ([HDMI, CPHY].includes(serdesType)) {
      const groupList = getGroupList(signals);
      this.addSignal(NEW_GROUP, groupList)
    }
  }

  render() {
    const { components, signals, designVendor, selectedSignals, serdesType } = this.props;
    const { signalPinColumns } = this.state;
    const dataList = getSignalPinsData(components, signals, selectedSignals, serdesType);
    let scroll = {};
    const ICComps = components.filter(item => SERDES_TYPES.includes(item.type));
    if (dataList && dataList.length > 0 && ICComps.length > 5) {
      scroll = { x: 'max-content' }
    }
    const data = dataList ? dataList.map((record, index) => ({ ...record, index })) : []
    return (
      <div className="aurora-setup-border">
        <span className='out-title-color font-bold'>Signal and Pin Setup</span>
        {designVendor !== PRE_LAYOUT ? <Dropdown
          // disabled={serdesType === HDMI}
          disabled={[HDMI, CPHY].includes(serdesType)}
          menu={{ items: this.addSignalMenu(), className: "channel-add-signal-dropdown", onClick: this.selectMenu }}
          trigger={['click']}
        >
          <PlusCircleOutlined
            className="signal-add-icon channel-add-signal-icon"
            title="Add new signal"
            onClick={(e) => this.addClick(e)} />
        </Dropdown> : null}
        <Table
          ref={(ref) => this.table = ref}
          rowKey={(record) => `${record.signal}-${record.netType}`}
          columns={signalPinColumns}
          size="small"
          dataSource={data}
          scroll={scroll}
          className='andes-v2-table channel-signals-pins-table space-10'
          rowClassName={(record, index) => { return getRowClassName(record, index, serdesType) }}
        />
      </div>
    );
  }
}

const mapDispatch = (dispatch) => ({
  _updateChannelContent(data) {
    dispatch(updateChannelContent(data))
  },
  _addSignal(group, groupList) {
    dispatch(addSignal(group, groupList))
  },
  _saveSelectNets({ newNets, record, prevNets }) {
    dispatch(saveSelectNets({ newNets, record, prevNets }))
  },
  _changeSignalName(record, prevRecord) {
    dispatch(changeSignalName(record, prevRecord))
  },
  _deleteSignal(record) {
    dispatch(deleteSignal(record))
  },
  _changeGroupName(record, prevRecord) {
    dispatch(changeGroupName(record, prevRecord))
  },
  _updateReferenceNets() {
    dispatch(updateReferenceNets())
  },
  _checkedSignals(checked, key, group) {
    dispatch(checkedSignals(checked, key, group))
  },
  _updateICCompPin(record, compName, pinInfo) {
    dispatch(updateICCompPin(record, compName, pinInfo))
  }
})

export default connect(null, mapDispatch)(SignalPinTable);