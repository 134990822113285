import React, { Component, Fragment } from "react";
import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Row, Col, Radio, Tooltip, Spin } from "antd";
import { createPortal } from 'react-dom';
import Panel from "@/components/Panel";
import { getResBlob } from '@/services/helper/downloadHelper';
import FileSaver from 'file-saver';
import UploadTemplate from './uploadTemplate'
import { templateData, deleteReportTemplate } from '@/services/Template'
import './reportTemplate.css'
import { CPHY } from "../../services/PCBHelper/constants";

const Group = Radio.Group;
const defaultTemplate = 'Aurora_template.pptx';

class ReportTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      templateList: {},
      loading: true,
      totalLength: 0,
      nowThumbnail: 0,
      viewPanel: null,
      visiablePanel: false,
      currentTemplate: {}
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentDidMount = () => {
    templateData.getListPromise(this.props.type).then(res => {
      this.setState({
        templateList: res,
        totalLength: res.length,
        loading: false,
      })
      setTimeout(() => {
        this.updateCurrentTemplate(res);
      }, 500);
    })
  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }

  defaultTemplateId(list) {
    let id = '';
    let temp = list.find(item => item.name === defaultTemplate);
    if (temp) {
      id = temp.id;
    }
    return id;
  }

  updateCurrentTemplate = (list) => {
    const { reportConfig } = this.props;
    const { currentTemplate, totalLength, templateList } = this.state;
    let config = { ...reportConfig };
    var validation = Object.getOwnPropertyNames(templateList);
    if (validation.length === 0) { return }
    let now = templateList.findIndex(current => current.id === config.templateId);
    if (now + 3 >= totalLength) {
      now = totalLength - 3 >= 0 ? totalLength - 3 : 0;
    } else {
      now = 0;
    }
    let id = '', name = defaultTemplate;
    if (list && list.length > 0) {
      if (config.templateId) {
        let temp = list.find(item => item.id === config.templateId);
        if (temp) {
          id = temp.id;
          name = temp.name;
        }
      } else {
        if (currentTemplate.id) {
          id = currentTemplate.id;
          name = currentTemplate.name;
        }
      }
      if (!id) {
        id = this.defaultTemplateId(list);
        name = defaultTemplate;
      }
      this.setState({
        currentTemplate: { id, name },
        nowThumbnail: now
      });
      config.templateId = id;
      setTimeout(() => {
        this.props.updateReportConfig(config);
      }, 500);
    }
  }

  templateUpload = () => {
    this.setState({
      visiablePanel: true
    })
  }

  closeTemplatePanel = (willUpdate) => {
    this.setState({
      visiablePanel: false,
    });
    if (willUpdate) {
      this.setState({
        loading: true
      });
      setTimeout(() => {
        templateData.getListPromise(this.props.type, true).then(res => {
          this.setState({
            templateList: res,
            totalLength: res.length,
            loading: false
          })
          this.updateCurrentTemplate(res);
        }, 500);
      })
    }
  }

  uploadTemplateSave = (id) => {
    const { reportConfig, updateReportConfig } = this.props;
    let config = { ...reportConfig };
    if (id) {
      config.templateId = id;
      updateReportConfig(config);
    }
  }

  expandNowThumbnail = () => {
    let newThumbnail = this.state.nowThumbnail - 1
    if (newThumbnail < 0) return;
    this.setState({
      nowThumbnail: newThumbnail
    })
  }

  addNowThumbnail = () => {
    const { nowThumbnail, totalLength } = this.state;
    let newThumbnail = nowThumbnail + 1
    if (newThumbnail + 2 >= totalLength) return
    this.setState({
      nowThumbnail: newThumbnail
    })
  }

  changeReportTemplate = (e) => {
    const value = e.target.value
    const { templateList } = this.state;
    const { reportConfig, updateReportConfig, updateReoprtMessage } = this.props;
    let config = { ...reportConfig };
    let template = templateList.find(temp => temp.id === value);
    if (template) {
      this.setState({
        currentTemplate: { id: template.id, name: template.name }
      })
      config.templateId = template.id;
    }
    updateReportConfig(config);
    updateReoprtMessage("");
  }

  view = (current) => {
    this.setState({
      viewPanel: current,
    })
  }

  closeView = () => {
    this.setState({
      viewPanel: null,
    })
  }

  templateDownload = (current) => {
    const { id, name } = current;
    const token = localStorage.getItem('token');
    if (id && name && token) {
      let url = `/api/v3/user/report_template/file?templateId=${id}&type=${this.props.type}&access_token=${token}`;
      let fileName = `${name.replace(/(.pptx)|(.ppt)/g, '')}.pptx`;
      let msg = "Download report template failed!";
      getResBlob(url).then(blob => {
        if (blob) {
          FileSaver.saveAs(blob, fileName);
          msg = "Download report template successfully!";
        }
        this.props.updateReoprtMessage(msg);
      })
    }
  }

  templateDel = (current) => {
    this.setState({ loading: true })
    deleteReportTemplate([current.id]).then(res => {
      templateData.getListPromise(this.props.type, true).then(res => {
        this.setState({
          templateList: res,
          loading: false,
          totalLength: res.length,
        })
        this.updateCurrentTemplate(res);
      })
    });
  }

  getThumbnail = (nowThumbnail) => {
    const { templateList } = this.state;
    const { reportConfig } = this.props;
    let current = templateList[nowThumbnail]
    const name = current && (current.name === defaultTemplate ? 'Aurora template.pptx' : current.name)
    if (current !== undefined) {
      return (
        <div className="report-thumbnail-main">
          <Radio value={current.id}>
            <img
              src={current.thumbnail}
              alt={"No Thumbnail"}
              className={`${reportConfig.templateId === current.id ? "report-check-img report-thumbnail-img-select" : "report-check-img report-thumbnail-img"}`}
            />
          </Radio>
          <div className="report-thumbnail-desc">
            <Tooltip key='desc' title={name.replace(/(.pptx)|(.ppt)/g, '')} overlayClassName='aurora-tooltip'>
              <div>{name.replace(/(.pptx)|(.ppt)/g, '')}</div>
            </Tooltip>
            <Tooltip key='eye' title='View' overlayClassName='aurora-tooltip'>
              <EyeOutlined onClick={() => this.view(current)} />
            </Tooltip>
            <Tooltip key='download' title='Download current template' overlayClassName='aurora-tooltip'>
              <DownloadOutlined onClick={() => this.templateDownload(current)} />
            </Tooltip>
            {current.name !== defaultTemplate &&
              <Tooltip key='delete' title='Delete current template' overlayClassName='aurora-tooltip'>
                <CloseOutlined className="del-icon" onClick={() => this.templateDel(current)} />
              </Tooltip>}
          </div>
        </div>
      );
    }
  }


  thumbnailList = () => {
    const { nowThumbnail, totalLength } = this.state;
    const { reportConfig, serdesType, type } = this.props;
    return (
      <Row gutter={16}>
        {
          serdesType === CPHY && type === "ANDES" ?
            <Group name="thumbnail" value={reportConfig.templateId ? reportConfig.templateId : ''} onChange={(e) => this.changeReportTemplate(e)} >
              <Col span={6}> {this.getThumbnail(nowThumbnail)} </Col>
              <Col span={6}> {totalLength > 1 ? this.getThumbnail(nowThumbnail + 1) : <div style={{ width: '100%' }}></div>} </Col>
              <Col span={6}> {totalLength > 2 ? this.getThumbnail(nowThumbnail + 2) : <div style={{ width: '100%' }}></div>} </Col>
              <Col span={6}> {totalLength > 3 ? this.getThumbnail(nowThumbnail + 3) : <div style={{ width: '100%' }}></div>} </Col>
            </Group> :
            <Group name="thumbnail" value={reportConfig.templateId ? reportConfig.templateId : ''} onChange={(e) => this.changeReportTemplate(e)} >
              <Col span={8}> {this.getThumbnail(nowThumbnail)} </Col>
              <Col span={8}> {totalLength > 1 ? this.getThumbnail(nowThumbnail + 1) : <div style={{ width: '100%' }}></div>} </Col>
              <Col span={8}> {totalLength > 2 ? this.getThumbnail(nowThumbnail + 2) : <div style={{ width: '100%' }}></div>} </Col>
            </Group>
        }
      </Row>
    )
  }

  getViewPanel = () => {
    const { viewPanel } = this.state;
    const content = (
      <Panel
        position='panel-center-left'
        title={viewPanel.name !== defaultTemplate ? viewPanel.name.replace(/(.pptx)|(.ppt)/g, '') : 'Aurora template'}
        zIndex={2000}
        onCancel={this.closeView}
        width={600}
        draggable
        minWidth={300}
        minHeight={180}
      >
        <img
          src={viewPanel.thumbnail}
          alt={"No Thumbnail"}
          className="report-check-img"
        />
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }

  getChangeThumbnailIcon = (nowThumbnail, totalLength) => {
    const { serdesType, type } = this.props;
    let isUseLeftClassName, isUseRightClassName, isShowIcon
    if (nowThumbnail > 0) {
      isUseLeftClassName = true;
    }

    if ((serdesType === CPHY && type === 'ANDES' && nowThumbnail + 4 < totalLength)
      || (!(serdesType === CPHY && type === 'ANDES') && nowThumbnail + 3 < totalLength)) {
      isUseRightClassName = true;
    }

    if ((serdesType === CPHY && type === 'ANDES' && totalLength > 4)
      || (!(serdesType === CPHY && type === 'ANDES') && totalLength > 3)) {
      isShowIcon = true;
    }

    return { isUseLeftClassName, isUseRightClassName, isShowIcon };
  }

  render() {
    const { loading, totalLength, nowThumbnail, viewPanel, visiablePanel, templateList } = this.state;
    const { reportConfigLoading } = this.props;
    const { isUseLeftClassName, isUseRightClassName, isShowIcon } = this.getChangeThumbnailIcon(nowThumbnail, totalLength);
    return (
      <Fragment>
        <div className="report-template-content">
          <label className="report-template-item-title">Template</label>
          <Tooltip key="Upload" title="Upload template" overlayClassName='aurora-tooltip'>
            <UploadOutlined
              className="aurora-tree-download-icon report-template-upload-icon"
              onClick={() => this.templateUpload()} />
          </Tooltip>
          <div className="report-template-thumbnail">
            <Spin tip="Loading..." spinning={reportConfigLoading ? false : loading}>
              <Row gutter={16}>
                <Col span={1}
                  className={`change-thumbnail-icon ${isUseLeftClassName ? "change-thumbnail-icon-pointer" : ""}`}
                  onClick={() => { this.expandNowThumbnail() }}>
                  {isShowIcon && <LeftOutlined className={isUseLeftClassName ? "change-thumbnail-icon-color" : ""} />}
                </Col>
                <Col span={22}>{totalLength > 0 && this.thumbnailList()}</Col>
                <Col span={1}
                  className={`change-thumbnail-icon ${isUseRightClassName ? "change-thumbnail-icon-pointer" : ""}`}
                  onClick={() => { this.addNowThumbnail() }}>
                  {isShowIcon && <RightOutlined className={isUseRightClassName ? "change-thumbnail-icon-color" : ""} />}
                </Col>
              </Row>
            </Spin>
          </div>
        </div>
        {
          visiablePanel && <UploadTemplate
            title={`Upload Template`}
            closeTemplatePanel={this.closeTemplatePanel}
            uploadTemplateSave={this.uploadTemplateSave}
            templateList={templateList}
          />
        }
        {viewPanel && this.getViewPanel()}
      </Fragment>
    );
  }
}

export default ReportTemplate;
