const TYPE = 'ANDES_V2_pre_layout';

export const CREATE_PRE_LAYOUT = `${TYPE}/create_pre_layout`;
export const GET_PRE_LAYOUT_CONTENT = `${TYPE}/get_pre_layout_content`;
export const UPDATE_PRE_LAYOUT_CONTENT = `${TYPE}/update_pre_layout_content`;
export const UPDATE_PRE_LAYOUT_INFO = `${TYPE}/update_pre_layout_info`;
export const SAVE_PRE_LAYOUT_TO_SERVER = `${TYPE}/save_pre_layout_to_server`;
export const CLEAR_PRE_LAYOUT_CONTENT = `${TYPE}/clear_pre_layout_content`;

export const SELECT_SECTION_TEMPLATE = `${TYPE}/select_section_template`;
export const EDIT_INSERTION_LOSS = `${TYPE}/edit_insertion_loss`;
export const CHANGE_PRE_LAYOUT_CONFIG = `${TYPE}/change_pre_layout_config`;
export const UPDATE_SIGNALS_COLUMNS = `${TYPE}/update_signals_columns`;
export const EDIT_GROUP_NAME = `${TYPE}/edit_group_name`;
export const EDIT_SIGNAL_NAME = `${TYPE}/edit_signal_name`;
export const EDIT_NET_NAME = `${TYPE}/edit_net_name`;
export const CHANGE_LENGTH_UNIT = `${TYPE}/change_length_unit`;
export const EDIT_SIGNAL_SPACING = `${TYPE}/edit_signal_spacing`;
export const SAVE_PRE_LAYOUT_MODELS = `${TYPE}/save_pre_layout_models`;
export const FIRST_OPEN_PRE_LAYOUT_SETTING = `${TYPE}/first_open_pre_layout_setting`;
export const SAVA_PRELAYOUT_PCB_MODEL = `${TYPE}/save_preLayout_pcb_Model`;
export const PRELAYOUT_RENAME = `${TYPE}/prelayout_rename`;;
export const EDIT_PIN_OR_NET_NAME = `${TYPE}/edit_pin_or_net_name`;