import React from 'react';
import { checkRLCValueFormat } from '@/services/helper/dataProcess';
import { getPwrComponentErrorCheck } from './ErrorCheck';
import { CAP } from '../../../constants/componentType';
import { PACKAGE_PDN } from '../../../constants/treeConstants';
import { valueUnitSplit } from '../../../services/helper/valueUnitSplit';

const RLCTypes = ['Res', 'Ind', 'Cap'];
const ChipTypes = ['Controller', 'Memory'];

export function getRockyPdnErrorCheck(pdnContent) {

  if (!pdnContent) {
    return;
  }
  let error = [];
  let check = [], rlcCheck = {};
  const VRM = [...pdnContent.VRM];
  const powerComponents = [...pdnContent.PowerComponents];

  /*  if (PowerNets.length < 1) {
     check.push(<p style={{ margin: 0 }}> No power nets set.</p>);
   }
 
   if (ReferenceNets.length < 1) {
     check.push(<p style={{ margin: 0 }}>No reference nets set.</p>);
   } */

  /* if (VRM && VRM.length < 1) {
    error.push(<p style={{ margin: 0 }}>[VRM] VRM is not set.</p>);
  } */
  error = VRMCheck({ error, VRM });

  if (powerComponents.length > 0) {
    const rlcComponents = powerComponents.filter(item => RLCTypes.includes(item.usage));
    rlcCheck = getPwrComponentErrorCheck(rlcComponents);
    const Components = powerComponents.filter(item => ChipTypes.includes(item.usage));
    Components.forEach(comp => {
      //pkg
      const pkg = comp.pkg;
      if (!pkg) {
        error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{comp.name} </span>-Package model is not set.</p>);
      } else if (pkg.type === 'value') {
        let rValue = pkg.r;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue, 'Res');

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Package Resistance value format error.</p>);
        } else if (rCheck && rCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{comp.name} </span>- Package Resistance value cannot be 0.</p>);
        }

        //Check the Inductance value format
        let lValue = pkg.l;
        let lCheck = checkRLCValueFormat(lValue, 'Ind');

        if (!lCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Package Inductance value format error.</p>);
        } else if (lCheck && lCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{comp.name} </span>- Package Inductance value cannot be 0.</p>);
        }

        //Check the Capacitance value format
        let cValue = pkg.c;
        let cCheck = checkRLCValueFormat(cValue, "Cap");

        if (!cCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Package Capacitance value format error.</p>);
        } else if (cCheck && cCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Package Capacitance value cannot be 0.</p>);
        }
      }


      //die
      const die = comp.die;
      if (!die) {
        error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{comp.part} </span>-Die model is not set.</p>);
      } else if (die.type === 'value') {
        let rValue = die.r;
        //Check the Resistance value format
        let rCheck = checkRLCValueFormat(rValue, 'Res');

        if (!rCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Rdie value format error.</p>);
        } else if (rCheck && rCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] <span className="font-bold">{comp.name} </span>- Rdie value cannot be 0.</p>);
        }

        //Check the Capacitance value format
        let cValue = die.c;
        let cCheck = checkRLCValueFormat(cValue, "Cap");

        if (!cCheck) {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Cdie value format error.</p>);
        } else if (cCheck && cCheck === "0") {
          error.push(<p style={{ margin: 0 }}>[Components] "<span className="font-bold">{comp.name}</span>" Cdie value cannot be 0.</p>);
        }
      }
    })
  }

  error = [...check, ...error];
  if (rlcCheck && Array.isArray(rlcCheck.error)) {
    error = [...error, ...rlcCheck.error];
  }
  if (error.length === 0) {
    return null;
  } else {
    return {
      error
    }
  }
}

function VRMCheck({ error, VRM, isSev = false, powerNet, contentType }) {
  if (contentType !== PACKAGE_PDN && (!VRM || !VRM.length)) {
    error.push(<p style={{ margin: 0 }}><span className="font-bold">[VRM]{powerNet ? `[${powerNet}]` : ""}</span> VRM is not set.</p>);
  }
  for (let i = 0; i < VRM.length; i++) {
    const item = VRM[i];
    if(item.powerPin.length < 1 || item.powerPin.find(it => !it.comp) || item.groundPin.length < 1 || item.powerPin.find(it => !it.comp)){
      error.push(<p style={{ margin: 0 }}><span className="font-bold">[VRM]{powerNet ? `[${powerNet}]` : ""}</span> Cannot find the PMIC, please go to the PDN page of the PCB to set the VRM, or set the equivalent power pin and ground pin.</p>);
    }
    
    let vrmModelMsgName = "";
    if (item.powerPin.length > 0) {
      vrmModelMsgName += item.powerPin[0].comp;
    };
    if (item.groundPin.length > 0) {
      vrmModelMsgName += ' ' + item.groundPin[0].comp;
    };
    if (item.model && Object.keys(item.model).length > 0) {
      if (!item.model.name) {
        let vrmModelMsgName = '';
        if (vrmModelMsgName) {
          error.push(<p style={{ margin: 0 }}>[VRM]{powerNet ? `[${powerNet}]` : ""} {vrmModelMsgName} - VRM model is not set.</p>);
        };
      }
    } else if (isSev) {
      error.push(<p style={{ margin: 0 }}>[VRM]{powerNet ? `[${powerNet}]` : ""} {vrmModelMsgName} - VRM model is not set.</p>);
    }
  }
  return error;
}

export function sevPDNErrorCheck(pdnContent, contentType) {
  if (!pdnContent) {
    return;
  }
  const powerDomains = pdnContent.powerDomains || []
  let error = [];

  for (let powerDomain of powerDomains) {
    if (!powerDomain.PowerNets || !powerDomain.PowerNets.length) {
      continue;
    }
    const powerNet = powerDomain.PowerNets[0]
    const VRM = [...(powerDomain.VRM || [])];
    const Components = [...(powerDomain.Components || [])];

    error = VRMCheck({ error, VRM, isSev: true, powerNet, contentType });

    if (Components.length > 0) {
      let errorList = []
      for (let comp of Components) {
        if (errorList.includes(comp.part) || comp.usage !== CAP) {
          continue;
        }
        if (!comp.model || !comp.model.id || !comp.model.name) {
          error.push(<p style={{ margin: 0 }}><span className='font-bold'>[Components][{powerNet}]</span> {comp.part} - Decap model is not set.</p>)
          errorList.push(comp.part)
          continue;
        }

        if (comp.model.libraryType === "decap_spice" && !comp.model.subcktName) {
          error.push(<p style={{ margin: 0 }}><span className='font-bold'>[Components][{powerNet}]</span> {comp.part} - Decap model subckt is not set.</p>);
          errorList.push(comp.part)
        }
      }
    }
  }

  // ball_size
  const ballSize = pdnContent.ballSize || {};
  if (ballSize && Object.keys(ballSize).length) {
    const { bgaHeight,
      bgaMiddle,
      bgaShape,
      bgaSize,
      dieHeight,
      dieMiddle,
      dieShape,
      dieSize } = ballSize;
    error = getBallError(bgaShape, bgaHeight, bgaMiddle, bgaSize, "BGA", error)
    error = getBallError(dieShape, dieHeight, dieMiddle, dieSize, "DIE", error)
  }

  if (error.length === 0) {
    return null;
  } else {
    return { error }
  }
}

function getBallError(shape, height, middle, size, type, error) {
  const { value: heightValue } = valueUnitSplit(height);
  const { value: middleValue } = valueUnitSplit(middle);
  const { value: sizeValue } = valueUnitSplit(size);

  if (["Spheroid", "Cylinder"].includes(shape)) {
    if (!heightValue || heightValue == '0' || !sizeValue || sizeValue == '0' || ((!middleValue || middleValue == '0') && shape === "Spheroid")) {
      error.push(<p style={{ margin: 0 }}>The value in <span className='font-bold'>{type} Ball Size</span> cannot be 0</p>);
    }
  }
  return error;
}