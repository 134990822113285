import { CAP, IND, RES } from "../PCBHelper";
import { getDefaultRLCValue, partCapValueMatch } from "../helper/RLCValue";
import { checkRLCValue } from "../helper/dataProcess";
import NP from 'number-precision';

function getRLCValueByType({ newType, prevValue, part, addDefault = true }) {
  let newValue = "";
  switch (newType) {
    case CAP:
      if (typeof (prevValue) === "object") {
        newValue = { r: prevValue.r || "0", l: prevValue.l || "0", c: prevValue.c || "0" }
      } else {
        let _value = checkSierraRLCValue(prevValue, CAP);
        newValue = { r: "0", l: "0", c: _value }
      }

      if (!newValue.c || parseFloat(newValue.c) === 0) {
        newValue.c = partCapValueMatch({ param: part, f: "", returnValue: true });
      }
      //set default res value by cap value
      if (addDefault && (!newValue.r || newValue.r === 0 || newValue.r === '0')) {
        newValue.r = getDefaultRLCValue(newValue.c, RES);
      }

      //set default ind value by cap value
      if (addDefault && (!newValue.l || newValue.l === 0 || newValue.l === '0')) {
        newValue.l = getDefaultRLCValue(newValue.c, IND);
      }
      break;
    case RES:
      const defaultResValue = prevValue && prevValue.r ? prevValue.r : prevValue || "0";
      newValue = checkSierraRLCValue(defaultResValue, RES)
      break;
    case IND:
      const defaultIndValue = prevValue && prevValue.l ? prevValue.l : prevValue || "0";
      newValue = checkSierraRLCValue(defaultIndValue, IND)
      break;
    default:
      newValue = checkSierraRLCValue(prevValue);
      break;
  }
  return newValue;
}

function checkSierraRLCValue(value, type) {
  let newValue = checkRLCValue(value);
  if (typeof (newValue) !== "string") {
    return newValue;
  }

  switch (type) {
    case RES:
      if (newValue.match(/Meg/ig)) {
        newValue = newValue.replace(/Meg/ig, "M");
      }

      if (newValue.match(/G/ig)) {
        const findValue = newValue;
        let _value = findValue.replace(/G/ig, "").replace('Ω', '');;

        _value = NP.times(parseFloat(_value), 1e3);
        return `${_value}M`;
      }

      if (newValue.match(/p|u|n/ig)) {
        //p,u,n
        newValue = newValue.replace(/p|u|n/ig, "");
      }
      break;
    case IND:
      if (newValue.match(/m/g)) {
        const findValue = newValue;
        let _value = findValue.replace(/m/g, "").replace('H', '');

        _value = NP.times(parseFloat(_value), 1e3);
        return `${_value}u`;
      }
      if (newValue.match(/G|M|K|k/g)) {
        const findValue = newValue;
        let _value = findValue.replace(/G|M|K|k/g, "");

        return _value;
      }
      break;
    case CAP:
      if (newValue.match(/m/g)) {
        const findValue = newValue;
        let _value = findValue.replace(/m/g, "").replace('F', '');

        _value = NP.times(parseFloat(_value), 1e3);
        return `${_value}u`;
      }

      if (newValue.match(/G|M|K|k/g)) {
        const findValue = newValue;
        let _value = findValue.replace(/G|M|K|k/g, "");

        return _value;
      }
      break;
    default:
      break;
  }

  return newValue;
}

function getRLCCompValue({
  passiveTable,
  findPassive,
  part,
  value,
  type,
  isPowerComp,
  prevModel
}) {
  const _findComp = findPassive ? findPassive : (passiveTable || []).find(item => item.part === part);
  let _value = checkSierraRLCValue(value, type);
  // Cap 
  let model = {
    type: 'value',
    fileName: '',
    libraryId: '',
    subckt: '',
    pairs: []
  };

  if (prevModel && prevModel.type !== "value") {
    model = JSON.parse(JSON.stringify(prevModel))
  } else if (_findComp && _findComp.model && _findComp.model.type !== "value") {
    model = JSON.parse(JSON.stringify(_findComp.model))
  }

  if (type === CAP) {
    if (!_value || parseFloat(_value) === 0) {
      _value = partCapValueMatch({ param: part, f: "", returnValue: true });
    }
    _value = {
      r: "0",
      l: "0",
      c: _value
    }

    if (_findComp && _findComp.value && typeof (_findComp.value) === "object") {
      _value.r = _findComp.value.r || _value.r;
      _value.l = _findComp.value.l || _value.l;
      _value.c = _findComp.value.c || _value.c;
    }

    if (isPowerComp) {
      _value.r = parseFloat(_value.r) === 0 ? getDefaultRLCValue(_value.c, RES) : _value.r;
      _value.l = parseFloat(_value.l) === 0 ? getDefaultRLCValue(_value.c, IND) : _value.l;
    }
  } else {
    _value = _findComp && _findComp.value && parseFloat(_findComp.value) ? `${_findComp.value}${_findComp.unit || ""}` : _value;
  }
  return { value: _value, model }
}


export {
  getRLCValueByType,
  checkSierraRLCValue,
  getRLCCompValue
}