import {
  ApartmentOutlined,
  BranchesOutlined,
  DownloadOutlined,
  RedoOutlined,
  SettingOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Dropdown, message, Checkbox, Tooltip } from 'antd';
import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import projectDesigns from '@/services/helper/projectDesigns';
import { uploadDCR, changeResistanceTableLoading, saveExtraction as saveDCRExtraction } from '../../store/DCR/action';
import { startDCRSimulation } from '../../store/simulation/DCR/action'
import { getResBlob } from '@/services/helper/downloadHelper';
import FileSaver from 'file-saver';
import { openTabFooter } from '../../../MonitorStore/action';
import ImportPanel from './importPanel';
import ExtractionPanel from './extractionPanel';
import OptionPanel from './optionPanel_v2';
import { startIRSimulation } from '../../store/simulation/IR/action';
import { startImpedanceSimulation } from '../../store/simulation/Impedance/action';
import { startPowerTreeSimulation } from '../../store/simulation/PowerTree/action';
import { IR_EXPLORER, DCR, IR_EXPLORER_RESULT, IMPEDANCE, IMPEDANCE_RESULT, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE, CASCADE_IMPEDANCE, CASCADE_SIGN_OFF, PCB_PRE_LAYOUT } from '@/constants/treeConstants';
import { uploadIR, saveExtraction as saveIRExtraction } from '../../store/IRExplorer/action';
import { updateExtraction, updateOptimization, updateOptions, saveWireBond, changeImpedancePage } from '../../store/Impedance/action';
import { resetDesignTree, uploadTreeSpec, updateTreeCompRLCPrefix as updateDesignTreeCompRLCPrefix, showDesignTreeResult, saveOption, saveExtraction as saveTreeExtraction, updateTreeSetting } from '../../store/DesignTree/action';
import { openPage, updateListStatus, updatePCBLog, updateStackupList, updateReportInfo, updateComponentSettingVisible, updateRootPanelVisible, updateSimulationLoading } from '../../store/project/action';
import { saveConnectors, setPowerTreeData } from '../../store/PowerTree/action';
import DebugDownloadPanel from './Debug/DebugDownloadPanel';
import { topBarResize } from '@/services/helper/topBarResize';
import { createAndRunImpedanceTask, updateTemplateByCompSetting, updateSignExtraction, updateDCRExtraction } from '../../store/SignOffTemplate/action';
import { getSelectedSetupIDs } from '@/services/Cascade/helper/setupData';
import CascadeChannels from '@/services/Cascade/DB/cascadeChannels';
import { SIGN_OFF_RUNNING, SIGN_OFF_UPDATE } from '@/constants/signOffTemplateStatus';
import { updatePageLayout, updateViewList, updateSelectKeys, savePreviewStatus } from '../../store/project/action';
import { selectChange, changeShowNameStatus } from '../../../LayoutExplorer/store/Cascade/actionCreators';
import DelConfirm from '@/components/DelConfirm';
import TemplateOptionPanel from '../../SignOffTemplate/TemplateOptionPanel';
import StackupPanel from '@/components/StackupPanel';
import { getTopBarWidth } from '../../../../services/Cascade/helper/topBarHelper';
import { getNewStackupList } from '@/services/Cascade/stackup/stackupHelper.js';
import ReportPanel from './report';
import { reportTopBarTitle } from '../../../../components/Report';
import { getReportConfig } from '../../../../services/Cascade/project';
import { getReportGuideKeys, getReportGuideValue } from '@/services/helper/reportHelper'
import { getPowerNetsBoundaryArr } from '@/services/helper/cutDesignHelper';
import preview, { cleanPreview } from '@/services/helper/cutDesign/preview';
import { LEFT_RIGHT_lAYOUT, SINGLE_PAGE_LAYOUT } from '@/constants/layoutConstants';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { NetGroupSelection } from '@/services/PCBHelper';
import SParameterModelDownloadPanel from './sParameterModelDownloadPanel';
import ConnectionPanel from '../../components/connectionPanel';
import TreeOptionPanel from './treeOptionPanel';
import Schematic from '../../components/Schematic/schematics';
import DCExtractionPanel from './dcExtractionPanel';
import designConstructor from '../../../../services/helper/designConstructor';
import { DCExtraction } from '../../../../services/Cascade/helper/setupClass';
import { CASCADE } from '../../../../constants/pageType';
import Stackup from '../../../LayoutExplorer/components/Stackup_v1';
import { changeStackupZonePanelShow, updateSelectedZones } from '../../../LayoutExplorer/components/Stackup_v1/store/actionCreators';
import StackupZonesCanvas from '../../../../services/LayoutCanvas/StackupZonesCanvas';
import PadstackPanel from '../../../../components/PadstackPanel';
import { IMPEDANCE_DIE, IMPEDANCE_PACKAGE, IMPEDANCE_PCB, TARGET_DIE_INDEX, TARGET_PACKAGE_INDEX, TARGET_PCB_INDEX } from '../../../../services/Cascade/constants';
import WireBondPanel from '../../../../components/WireBond/WireBondPanel';
import { getLibraryFileInfo } from '../../../../services/Cascade/library';
import { OVERVIEW, getNetsAndCompsFromDetails } from '../../../../services/Cascade/Impedance';
import TreeSettingPanel from './treePlotSetting';
import ServiceOptionsPanel from './serviceOptionsPanel';
import permissionData from '../../../../services/helper/data/permissionData';
import _LayoutData from '../../../../services/data/LayoutData';
import { CAP } from '../../../../constants/componentType';
import './index.css';

const ADD_PATHR = 1;
const RESULT = 'resultRender', DEBUG = 'debugRender', RUN = 'runRender', IMPRUN = 'ImpedanceRunRender', EXCEL = 'excelRender', RESET = 'resetRender',
  EXTRACTION = 'extractionRender', OPTION = 'optionRender', SETTING = 'settingRender', STACKUP = 'stackupRender', CREATE = "createRender",
  TEMPLATE_OPTION = "templateOptionRender", SHOW_RESULT = "showResultRender", REPORT = "reportRender", S_PARAMETER_MODEL_DOWNLOAD = "sParameterModelDownloadRender",
  CONNECTION = 'connectionRender', ROOTPCB = 'rootPCBRender', TREE_OPTION = "treeOptionRender", SCHEMATICS = 'schematicsRender', EXTRACTION_DC = 'DCExtractionRender',
  EXTRACTION_MENU = 'extractionMenuRender', PADSTACKS = 'padstacksRender', WIRE_BOND_PROFILE = 'wireBondRender', LAYOUT = 'layoutRender', EXPORT = 'export', BACK_OVERVIEW = 'backOverviewRender',
  TREE_SETTING = 'treePlotSettingRender', SERVICE_OPTIONS = 'serviceOptionsRender';
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importDataPanelVisible: false,
      excelType: "",
      debugDownloadVisible: false,
      extractionVisible: false,
      extractionDCVisible: false,
      optionVisible: false,
      stackupVisible: false,
      resetVisible: false,
      templateOptionVisible: false,
      reportPanelVisible: false,
      treeOptionVisible: false,
      resultDropdown: false,
      multiZonePreview: false,
      treeSettingVisible: false,
      createConfig: {
        AC: true,
        DC: true
      },
      createImpConfig: {
        impedance: true,
        transient: true
      },
      connectionVisible: false,
      scheVisible: false,
      padstacksVisible: false,
      wireBondVisible: false,
      serviceOptionsVisible: false,
      runError: '',
      hasTransient: false
    };
    this.uploadFileRef = createRef();
    this.netGroupSelection = new NetGroupSelection();
    this.file = {};
    this.stackupZonesCanvas = {};
  }

  screenChange() {
    window.addEventListener('resize', this._changeTopBarSize);
  }

  componentDidMount() {
    this._changeTopBarSize()
    this.screenChange();
    if (this.props.previewStatus === 'multiZone') {
      this.setState({
        extractionVisible: true,
        multiZonePreview: true
      })
      const designId = this.getCurrentDesign();
      this.props.changeStackupZonePanelShow(false, designId);
      setTimeout(() => {
        this.showZoneStackUp()
      }, 1000)
    } else if (this.props.previewStatus === true) {
      this.setState({
        extractionVisible: true,
      })
      setTimeout(() => {
        const { impDesignList } = this.props;
        const designId = this.getCurrentDesign();
        const layout = impDesignList.find(item => item.id === designId);
        if (layout && layout.extraction && layout.extraction.ratio !== undefined) {
          this.changePreviewShowState(true);
          this.showBoundary(layout.extraction.ratio);
        }
      }, 50)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._changeTopBarSize);
  }

  componentDidUpdate(prevProps) {
    const { viewList, impVerificationId, verificationId, pathRVerificationId, signOffVerificationId, treeVerificationId, forcePreviewShow, initConn, powerVerificationId } = this.props;

    if ((!viewList.includes(IMPEDANCE) && prevProps.viewList.includes(IMPEDANCE))
      || (impVerificationId !== prevProps.impVerificationId)) {
      this.setState({
        stackupVisible: false,
        padstacksVisible: false
      })
      this.updateStackupList()
    }

    const view = viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(item));
    const prevView = prevProps.viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(item))
    if (view !== prevView || impVerificationId !== prevProps.impVerificationId
      || verificationId !== prevProps.verificationId
      || pathRVerificationId !== prevProps.pathRVerificationId) {
      this.setState({
        stackupVisible: false,
        debugDownloadVisible: false,
        serviceOptionsVisible: false,
        padstacksVisible: false
      })
      this.props.updateComponentSettingVisible(false);
      this.updateStackupList()
    }

    if (view !== prevView || impVerificationId !== prevProps.impVerificationId
      || verificationId !== prevProps.verificationId
      || pathRVerificationId !== prevProps.pathRVerificationId
      || signOffVerificationId !== prevProps.signOffVerificationId
      || treeVerificationId !== prevProps.treeVerificationId
      || powerVerificationId !== prevProps.powerVerificationId) {
      this.setState({
        extractionDCVisible: false,
        extractionVisible: false,
        optionVisible: false,
        treeOptionVisible: false,
        treeSettingVisible: false
      })
    }

    if ((!viewList.includes(SIGN_OFF_TEMPLATE) && prevProps.viewList.includes(SIGN_OFF_TEMPLATE))
      || (signOffVerificationId !== prevProps.signOffVerificationId)) {
      this.setState({
        templateOptionVisible: false,
        stackupVisible: false,
        padstacksVisible: false
      })
      this.updateStackupList()
    }

    if (treeVerificationId !== prevProps.treeVerificationId) {
      this.setState({
        stackupVisible: false,
        padstacksVisible: false
      })
      this.updateStackupList()
    }

    if (!prevProps.forcePreviewShow && forcePreviewShow) {
      this.previewBtnChild.setPreviewBtnLoadState(false);
    }

    const { connectionVisible, treeOptionVisible, scheVisible } = this.state;
    const { rootVisible } = this.props;
    if ((connectionVisible || rootVisible) && ((view !== POWER_TREE && view !== SINGLE_TREE) || (powerVerificationId !== prevProps.powerVerificationId))) {
      this.setState({
        connectionVisible: false,
      })
      this.props.updateRootPanelVisible(false)
    }

    if ((![POWER_TREE, DESIGN_TREE, SINGLE_TREE].includes(view) || (powerVerificationId !== prevProps.powerVerificationId) || (treeVerificationId !== prevProps.treeVerificationId))
      && (treeOptionVisible || scheVisible)) {
      this.setState({
        treeOptionVisible: false,
        scheVisible: false
      })
    }

    if (view === POWER_TREE && initConn) {
      this.props.updateRootPanelVisible(true)
      this.props.setPowerTreeData({ initConn: false })
    }
  }

  updateImpRunStatus = () => {
    const { impHasDie, impPcbData, impHasPackage } = this.props
    let { createImpConfig } = this.state
    let hasTransient = false
    let error = ''
    if (!impPcbData.length) {
      error = 'This task has no pcb so it cannot run transient'
    } else {
      for (const impData of impPcbData) {
        if (impData.ICs.some(item => item.applyCurrent && item.dieCurrent && item.dieCurrent.currentPoints && item.dieCurrent.currentPoints.length)) {
          hasTransient = true;
          break;
        }
      }

      if (!hasTransient) {
        if (!impHasDie) {
          error = 'This task does not set current so it cannot run transient'
        } else {
          const ic = impHasDie.ICs.find(item => !item.dieCurrent || !item.dieCurrent.currentPoints || !item.dieCurrent.currentPoints.length)
          if (ic) {
            let portName = ''
            impHasPackage.powerDomains.forEach(item => {
              const { MAIN_POWER_NETS, diePorts } = item.content
              if (MAIN_POWER_NETS[0] === ic.powerNet) {
                const portGroup = diePorts.find(port => port.port.toString() === ic.portIndex)
                portName = portGroup && portGroup.portName ? portGroup.portName : null
              }
            })
            error = `${portName ? portName : `Port ${ic.portIndex}`} does not set die current so it cannot run transient`
          } else {
            hasTransient = true
          }
        }
      }
    }
    createImpConfig['transient'] = hasTransient
    this.setState({
      runError: error,
      hasTransient,
      createImpConfig
    })
  }

  updateStackupList = () => {
    let currentID = this.getCurrentDesign()
    const { selectedKeys, treeDesignId, dcrDesignId, irDesignId, impDesignId, signOffDesignId } = this.props
    const allIds = { selectedKeys, treeDesignId, dcrDesignId, irDesignId, impDesignId, signOffDesignId, currentID }

    this.setState({
      stackupVisible: false
    })

    // update stackupList when page change
    let { stackupList } = this.props
    stackupList = getNewStackupList(stackupList, 'close', allIds, false)
    this.props.updateStackupList(stackupList)
  }

  _changeTopBarSize = () => {
    topBarResize('cascade-top-bar', getTopBarWidth(this.props.viewList));
  }

  getDCExtraction = (view) => {
    const { treeExtraction, dcrExtraction, IRExtraction, signOffDCExtraction } = this.props;
    switch (view) {
      case POWER_TREE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        return treeExtraction;
      case DCR:
        return dcrExtraction;
      case IR_EXPLORER:
        return IRExtraction;
      case SIGN_OFF_TEMPLATE:
        return signOffDCExtraction;
      default:
        return new DCExtraction();
    }
  }

  getExtractionSave = (view) => {
    switch (view) {
      case POWER_TREE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        return this.props.saveTreeExtraction;
      case DCR:
        return this.props.saveDCRExtraction;
      case IR_EXPLORER:
        return this.props.saveIRExtraction;
      case SIGN_OFF_TEMPLATE:
        return this.props.updateDCRExtraction;
      default:
        return null;
    }
  }

  getExtractionDesignVendor = () => {
    const design = this.getCurrentDesign();
    return designConstructor.getDesignVendor(design);
  }

  setupMenu = () => {
    return [{ key: 'Export', label: 'Export', onClick: this.exportFile }, { key: 'Import', label: 'Import', onClick: this.uploadFile }]
  }

  exportFile = () => {
    const { viewList } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER, POWER_TREE, DESIGN_TREE, SINGLE_TREE, SIGN_OFF_TEMPLATE].includes(item));
    switch (view) {
      case DCR:
        this.exportDCRFile()
        break;
      case IR_EXPLORER:
        this.exportIRFile();
        break;
      case POWER_TREE:
      case DESIGN_TREE:
      case SINGLE_TREE:
        this.exportTreeFile(view);
        break
      case SIGN_OFF_TEMPLATE:
        this.exportSignOffFile();
        break;
      default: break;
    }
  }

  exportDCRFile = () => {
    const { pathRVerificationId } = this.props;
    const token = localStorage.getItem('token');
    let url = `/api/v3/Cascade/verification/${pathRVerificationId}/dcr/export?access_token=${token}`;
    let fileName = 'PathR_Explorer.xlsx';
    this.downloadExcel(url, fileName)
  }

  exportIRFile = () => {
    const { verificationId } = this.props;
    const token = localStorage.getItem('token');
    let url = `/api/v3/Cascade/verification/${verificationId}/ir/export?access_token=${token}`;
    let fileName = 'IR_Explorer.xlsx';
    this.downloadExcel(url, fileName)
  }

  exportTreeFile = (view) => {
    const { treeVerificationId } = this.props;
    const token = localStorage.getItem('token');
    const verificationId = treeVerificationId;
    let url = `/api/v3/Cascade/powertree/spec/export?verificationId=${verificationId}&access_token=${token}`;
    let fileName = 'Power_Tree_Load_Spec.xlsx';
    this.downloadExcel(url, fileName)
  }

  exportSignOffFile = () => {
    const { signOffVerificationId } = this.props;
    const token = localStorage.getItem('token');
    const verificationId = signOffVerificationId;
    let url = `/api/v3/Cascade/template/export/${verificationId}`;
    let fileName = 'Sign_off_Template.xlsx';
    this.downloadExcel(url, fileName, { token, pageType: CASCADE })
  }

  downloadExcel = (url, fileName, setting) => {
    getResBlob(url, setting).then(blob => {
      if (blob) {
        FileSaver.saveAs(blob, fileName);
      } else {
        message.error('Download failed!')
      }
    })
  }

  uploadFile = () => {
    const el = this.uploadFileRef.current;
    if (!el) return;
    el.click();
  }

  runClick = () => {
    const { viewList, pathRVerificationId, irDesignId, impVerificationId, treeVerificationId } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SINGLE_TREE].includes(item));
    // Prevent clicking too fast
    this.props.updatePCBLog([])
    this.props._openTabFooter();
    this.props.updateSimulationLoading(true);
    switch (view) {
      case DCR:
        this.props._startDCRSimulation(pathRVerificationId)
        break;
      case IR_EXPLORER:
        this.props._startIRSimulation(irDesignId);
        break;
      case IMPEDANCE:
        this.props.startImpedanceSimulation(impVerificationId, { options: ['impedance'] });
        break;
      case POWER_TREE:
        this.props.startPowerTreeSimulation(treeVerificationId, 'power');
        break;
      case DESIGN_TREE:
        this.props.startPowerTreeSimulation(treeVerificationId, 'design');
        break;
      case SINGLE_TREE:
        this.props.startPowerTreeSimulation(treeVerificationId, 'single');
        break;
      default:
        this.props.updateSimulationLoading(false);
        break;
    }
  }

  onUploadFile = (e) => {
    const { resistanceData, viewList, IRTableData, pathRVerificationId, treeVerificationId } = this.props;
    const file = e.target.files[0];
    const view = viewList.find(item => [DCR, IR_EXPLORER, DESIGN_TREE, SINGLE_TREE].includes(item));
    let data = resistanceData;
    if (view === IR_EXPLORER) {
      data = IRTableData
    }

    if (data.length && view === DCR) {
      this.setState({
        importDataPanelVisible: true
      })
      this.file = file;
    } else {
      switch (view) {
        case DCR:
          this.uploadDCRFile(pathRVerificationId, file, ADD_PATHR)
          break;
        case IR_EXPLORER:
          this.uploadIRFile(file, ADD_PATHR);
          break;
        case DESIGN_TREE:
        case SINGLE_TREE:
          this.uploadTreeExcel(treeVerificationId, file);
          break;
        default: break;
      }
    }
    this.uploadFileRef.current.value = "";
  }

  uploadDCRFile = (pathRVerificationId, file, importType) => {
    this.props._changeResistanceTableLoading(true)
    this.file = {}
    this.props._uploadDCR(pathRVerificationId, file, importType);
  }

  uploadIRFile = (file, importType) => {
    // table loading
    this.props._uploadIR(this.props.verificationId, file, importType);
    this.file = {}
  }

  uploadTreeExcel = (verificationId, file) => {
    this.props.uploadTreeSpec(verificationId, file)
    this.file = {}
  }

  closeImportPanel = (e) => {
    this.setState({
      importDataPanelVisible: false
    })
  }

  importSelectClick = (e, importType) => {
    const { viewList, pathRVerificationId } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER].includes(item));
    switch (view) {
      case DCR:
        this.uploadDCRFile(pathRVerificationId, this.file, importType)
        break;
      case IR_EXPLORER:
        this.uploadIRFile(this.file, importType);
        break;
      default: break;
    }
    this.setState({
      importDataPanelVisible: false
    })
  }

  openDebugDownloadModal = () => {
    this.setState({
      debugDownloadVisible: true
    })
  }

  updateSParameterModelDownloadModal = (visible) => {
    this.setState({
      sParameterModelDownloadVisible: visible
    })
  }

  closeDebugDownloadPanel = () => {
    this.setState({
      debugDownloadVisible: false
    })
  }

  updateExtractionPanel = (boolean) => {
    this.setState({
      extractionVisible: boolean
    })
    if (!boolean) {
      const designId = this.getCurrentDesign();
      this.props.savePreviewStatus(false)
      cleanPreview(designId);
    }
  }

  updateDCExtractionPanel = (boolean) => {
    this.setState({
      extractionDCVisible: boolean
    })
  }

  updateServiceOptionsPanel = (boolean) => {
    this.setState({
      serviceOptionsVisible: boolean
    })
  }

  updateTreeOptionPanel = (boolean) => {
    this.setState({
      treeOptionVisible: boolean
    })
  }

  updateTreeSettingPanel = (boolean) => {
    this.setState({
      treeSettingVisible: boolean
    })
  }

  updateOptionPanel = (boolean) => {
    this.setState({
      optionVisible: boolean
    })
  }

  updateTemplateOptionPanel = (visible) => {
    this.setState({
      templateOptionVisible: visible
    })
  }

  updateComponentSettingPanel = (boolean) => {
    this.props.openMenu && this.props.openMenu()
    this.props.updateComponentSettingVisible(boolean);
  }

  changeRootPCBOpen = (boolean) => {
    this.props.openMenu && this.props.openMenu()
    this.props.updateRootPanelVisible(boolean)
  }

  updateReportPanel = async (visible) => {
    this.setState({
      reportPanelVisible: visible
    })
    await this._changeReportConfig();
  }

  _changeReportConfig = async (_typeName, verificationList = []) => {
    const { reportInfo, viewList, impVerificationId, signOffVerificationId } = this.props;
    let typeName = _typeName;
    if (!typeName) {
      typeName = viewList.includes(IMPEDANCE) ? CASCADE_IMPEDANCE : (viewList.includes(SIGN_OFF_TEMPLATE) ? CASCADE_SIGN_OFF : null);
    }
    if (!typeName) {
      return;
    }
    const designId = this.getCurrentDesign();
    let _reportInfo = {}, getConfigStatus = true;
    if (!reportInfo || (reportInfo.reportTypeName !== typeName && !reportInfo.reportVisible)) {
      _reportInfo = { reportConfig: {}, reportTypeName: typeName, designId };
    } else {
      getConfigStatus = false;
      let verificationIds = typeName === CASCADE_SIGN_OFF ? [signOffVerificationId] : [impVerificationId];
      verificationIds = verificationIds.filter(item => !!item);
      _reportInfo = _typeName || reportInfo.reportVisible ? { ...reportInfo } : { ...reportInfo, reportConfig: { ...reportInfo.reportConfig, verificationIds } };
    }

    this.props._updateReportInfo(_reportInfo, true);
    const updateVe = _typeName ? false : true;
    getConfigStatus && await this._getReportConfig(typeName, updateVe);
  }

  _getReportConfig = async (typeName, updateVe) => {
    const { projectId, impVerificationId, signOffVerificationId } = this.props;
    let config = await getReportConfig({ projectId, typeName });
    if (!config || Object.keys(config).length === 0) {
      const defaultGuideKey = getReportGuideKeys("cascade")
      const defaultGuideValue = getReportGuideValue("cascade", defaultGuideKey);
      let verificationIds = typeName === CASCADE_SIGN_OFF ? [signOffVerificationId] : [impVerificationId];
      verificationIds = verificationIds.filter(item => !!item);
      config = {
        verificationIds: [...verificationIds],
        language: 'english',
        templateId: "",
        format: "PPTX",
        fileName: "",
        typeName,
        custom: {
          guideValue: defaultGuideValue,
          isXTable: false
        }
      };
    } else if (updateVe) {
      const verificationIds = typeName === CASCADE_SIGN_OFF ? [signOffVerificationId] : [impVerificationId];
      config.verificationIds = verificationIds.filter(item => !!item);
    }
    this.props._updateReportInfo({ reportConfig: config, reportTypeName: typeName });
  }

  updateStackupPanel = (status) => {
    let currentID = this.getCurrentDesign()
    let { stackupList } = this.props
    const { selectedKeys, treeDesignId, dcrDesignId, irDesignId, impDesignId, signOffDesignId } = this.props
    const allIds = { selectedKeys, treeDesignId, dcrDesignId, irDesignId, impDesignId, signOffDesignId, currentID }

    stackupList = getNewStackupList(stackupList, 'open', allIds, false)

    if (status) {
      if (stackupList.length >= 2) {
        let existSamePanel = stackupList.find(item => item.designId === currentID && item.type === 'PCB')

        if (existSamePanel && existSamePanel.status === 'open') {
          stackupList = getNewStackupList(stackupList, 'close', allIds, false)
        } else {
          stackupList = getNewStackupList(stackupList, 'open', allIds, false)
          this.setState({
            stackupVisible: status
          })
        }
      }
      else {
        stackupList = getNewStackupList(stackupList, 'open', allIds, false)
        this.setState({
          stackupVisible: status
        })
      }
    }

    else {
      stackupList = getNewStackupList(stackupList, 'close', allIds, false)
      this.setState({
        stackupVisible: status
      })
    }

    this.props.updateStackupList(stackupList)
  }

  createClick = () => {
    const { viewList, signOffVerificationId, signOffDesignId } = this.props;
    let { createConfig: { AC, DC } } = this.state

    const view = viewList.find(item => item === SIGN_OFF_TEMPLATE);
    if (!view) {
      return;
    }
    const createConfig = { createImpTask: AC, createDCRTask: DC }
    this.props._createAndRunImpedanceTask({ signOffVerificationId, signOffDesignId, createConfig })
  }

  createImpClick = () => {
    const { viewList, impVerificationId } = this.props;
    let { createImpConfig: { impedance, transient } } = this.state

    const view = viewList.find(item => item === IMPEDANCE);
    if (!view) {
      return;
    }
    const options = []
    if (impedance) options.push('impedance')
    if (transient) options.push('transient')
    this.props.startImpedanceSimulation(impVerificationId, { options });
  }

  updateResetVisible = (boolean) => {
    this.setState({
      resetVisible: boolean
    })
  }

  updateConnectionPanel = (boolean) => {
    this.setState({
      connectionVisible: boolean
    })
  }

  updatePadstacksPanel = (boolean) => {
    this.setState({
      padstacksVisible: boolean
    })
  }

  resetItem = (total) => {
    this.props.updateDesignTree(total);
    this.updateResetVisible(false);
  }

  changeTreeShow = (e) => {
    e && e.preventDefault() && e.stopPropagation();
    const { treeResult = {}, hasTreeResult } = this.props;
    if (!hasTreeResult) {
      return;
    }
    const { pass, voltage, pairRes } = treeResult;
    let show = {};
    if (pass || voltage || pairRes) {
      show = {
        pass: false,
        voltage: false,
        pairRes: false
      }
    } else {
      show = {
        pass: true,
        voltage: true,
        pairRes: true
      }
    }

    this.props.showDesignTreeResult(show)
  }

  updateSingleResult = (type, boolean) => {
    const { treeResult = {}, hasTreeResult } = this.props;
    if (!hasTreeResult) {
      return;
    }

    this.props.showDesignTreeResult({ ...treeResult, [type]: boolean })
  }

  previewBtnOnRef = (ref) => {
    this.previewBtnChild = ref;
  }

  showPreview = (ratio, pcbId) => {
    const { layout, getSelectedDesignIDs, viewList, selectedKeys } = this.props;
    const designId = pcbId || this.getCurrentDesign();

    if (!this.previewBtnChild.state.previewShow) {
      cleanPreview(designId);
      return;
    }

    if (layout === SINGLE_PAGE_LAYOUT || !getSelectedDesignIDs.includes(designId)) {
      let list = [];
      list = [...viewList, 'PCB'];
      list = [...new Set(list)];
      this.props.updateViewList(list);

      let keys = [...selectedKeys];
      keys = keys.filter(d => !d.includes('PCB-'));
      keys.push(`PCB-${designId}`);
      this.props.updateSelectKeys(keys);
      this.props.updatePageLayout(LEFT_RIGHT_lAYOUT);
      return;
    }

    this.showBoundary(ratio, pcbId);
  }

  showBoundary = async (ratio, pcbId) => {
    const { impDesignList, targetIC } = this.props;
    const designId = pcbId || this.getCurrentDesign();
    const ImpData = impDesignList.find(item => item.id === designId);
    await _LayoutData.LoadLayoutDB(designId)
    if (!ImpData || !ImpData.powerDomains) {
      this.previewBtnChild.setPreviewBtnLoadState(false);
      return
    }
    const { nets = [], comps = [], referenceNets = [] } = getNetsAndCompsFromDetails(ImpData.powerDomains, targetIC, designId);
    if (nets && nets.length && designId) {
      const data = getPowerNetsBoundaryArr({ ratio, nets, designId, components: comps, referenceNets });
      preview(data, designId);
      this.showPowerNets(nets, comps.map(item => item.name), designId)
      this.previewBtnChild.setPreviewBtnLoadState(false);
    }
  }

  showPowerNets = (nets, comps, designId) => {
    this.props._selectChange({ nets, comps }, designId)
    this.props._changeShowNameStatus(true, designId)
    setTimeout(() => {
      this.netGroupSelection.selectNetGroup({ nets, isPower: true })
    }, 100);
  }

  changePreviewShowState = (state) => {
    this.previewBtnChild && this.previewBtnChild.setPreviewShowState(state);
  }

  previewMultiZone = () => {
    this.setState({
      multiZonePreview: true
    }, () => {
      const { layout, getSelectedDesignIDs, viewList, selectedKeys } = this.props;
      const designId = this.getCurrentDesign();
      if (layout === SINGLE_PAGE_LAYOUT || !getSelectedDesignIDs.includes(designId)) {
        let list = [];
        list = [...viewList, 'PCB'];
        list = [...new Set(list)];
        this.props.updateViewList(list);

        let keys = [...selectedKeys];
        keys = keys.filter(d => !d.includes('PCB-'));
        keys.push(`PCB-${designId}`);
        this.props.updateSelectKeys(keys);
        this.props.updatePageLayout(LEFT_RIGHT_lAYOUT);
        this.props.savePreviewStatus('multiZone');
        return;
      }

      this.showZoneStackUp()
    })
  }

  showZoneStackUp = () => {
    const designId = this.getCurrentDesign();
    this.props.changeStackupZonePanelShow(true, designId);

    let { stackupObj = {} } = this.props;
    const zones = stackupObj[designId] ? stackupObj[designId].zones || [] : [];
    let newSelectedZones = [], deletedZones = [], selectedZones = [];

    const prevNotShowZone = zones.find(item => !selectedZones.includes(item.name));
    if (prevNotShowZone) {
      selectedZones = zones.map(item => item.name);
      newSelectedZones = [...selectedZones];
    } else {
      selectedZones = [];
      newSelectedZones = [];
      deletedZones = zones.map(item => item.name);
    }

    this.props.updateSelectedZones(selectedZones, designId);
    if (this.stackupZonesCanvas[designId]) {
      this.stackupZonesCanvas[designId].updateShowZones({ selectedZones: newSelectedZones, deletedZones, zones });
    } else {
      this.stackupZonesCanvas[designId] = new StackupZonesCanvas({ designID: designId });
      this.stackupZonesCanvas[designId].updateShowZones({ selectedZones: newSelectedZones, deletedZones, zones });
    }

    this.setState({
      multiZonePreview: false
    })
  }

  getDisabled = () => {
    const { resultExist, verificationId, impVerificationId, viewList } = this.props;
    const view = viewList.find(v => [IMPEDANCE, IR_EXPLORER, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(v));
    if ((resultExist.id === verificationId || impVerificationId === resultExist.id) && resultExist.exist) {
      return ''
    } else if (view === IMPEDANCE) {
      return ''
    } else {
      return 'cascade-top-bar-item-disabled'
    }
  }

  openResult = () => {
    const { resultExist, verificationId, viewList, impVerificationId } = this.props;
    const view = viewList.find(v => [IMPEDANCE, IR_EXPLORER, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(v));
    if (view !== IMPEDANCE && ((resultExist.id !== verificationId && impVerificationId !== resultExist.id) || !resultExist.exist)) {
      return;
    }
    if (view === IR_EXPLORER) {
      this.props._openPage({ pageType: IR_EXPLORER_RESULT, id: verificationId });
    } else if (view === IMPEDANCE) {
      this.props._openPage({ pageType: IMPEDANCE_RESULT, id: impVerificationId });
    }
  }

  changeImpedancePage = () => {
    this.props.changeImpedancePage(OVERVIEW)
  }

  getTopBarItem = () => {
    const { viewList, designType, page, connectors } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE, PCB_PRE_LAYOUT].includes(item));
    const hasServiceOption = permissionData.hasRockyServiceOption();
    switch (view) {
      case DCR:
        return {
          left: [RUN, SETTING, LAYOUT, EXTRACTION_DC, EXCEL, DEBUG],
          right: [],
          children: {
            [LAYOUT]: [STACKUP, PADSTACKS]
          }
        };
      case IR_EXPLORER:
        return {
          left: [RUN, SETTING, LAYOUT, EXTRACTION_DC, DEBUG, RESULT],
          right: [],
          children: {
            [LAYOUT]: [STACKUP, PADSTACKS]
          }
        };
      case IMPEDANCE:
        const left = [IMPRUN, SETTING, LAYOUT, EXTRACTION, OPTION, REPORT, S_PARAMETER_MODEL_DOWNLOAD, DEBUG, RESULT]
        return {
          left: page === OVERVIEW ? left.filter(item => ![LAYOUT].includes(item)) : left,
          right: [],
          children: {
            [LAYOUT]: designType === IMPEDANCE_PACKAGE ? [STACKUP, PADSTACKS, WIRE_BOND_PROFILE] : [STACKUP, PADSTACKS]
          }
        };
      case POWER_TREE:
        return {
          left: connectors.length > 1 ? [RUN, SETTING, ROOTPCB, CONNECTION, EXTRACTION_DC, TREE_OPTION, DEBUG] : [RUN, SETTING, ROOTPCB, CONNECTION, EXTRACTION_DC, TREE_OPTION, SCHEMATICS, DEBUG],
          right: [SHOW_RESULT, TREE_SETTING, RESET],
          children: {}
        };
      case DESIGN_TREE:
        return {
          left: [RUN, SETTING, EXTRACTION_DC, TREE_OPTION, EXCEL, SCHEMATICS, DEBUG],
          right: [SHOW_RESULT, TREE_SETTING, RESET],
          children: {
            [LAYOUT]: [STACKUP, PADSTACKS]
          }
        };
      case SINGLE_TREE:
        return {
          left: [RUN, SETTING, ROOTPCB, EXTRACTION_DC, TREE_OPTION, EXCEL, SCHEMATICS, DEBUG],
          right: [SHOW_RESULT, TREE_SETTING, RESET],
          children: {}
        };
      case SIGN_OFF_TEMPLATE:
        return {
          left: [CREATE, SETTING, LAYOUT, EXTRACTION_MENU, TEMPLATE_OPTION, EXPORT, REPORT, S_PARAMETER_MODEL_DOWNLOAD],
          right: [],
          children: {
            [LAYOUT]: [STACKUP, PADSTACKS]
          }
        };
      case PCB_PRE_LAYOUT:
        return {
          left: [DEBUG],
          right: [],
          children: {}
        }
      default:
        return { left: hasServiceOption ? [DEBUG, SERVICE_OPTIONS] : [DEBUG], right: [], children: {} }
    }
  }

  getCurrentDesign = () => {
    const { viewList, dcrDesignId, irDesignId, impDesignId, signOffDesignId, treeDesignId, root } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE].includes(item));
    switch (view) {
      case DCR:
        return dcrDesignId;
      case IR_EXPLORER:
        return irDesignId;
      case IMPEDANCE:
        return impDesignId;
      case SIGN_OFF_TEMPLATE:
        return signOffDesignId;
      case DESIGN_TREE:
        return treeDesignId;
      case POWER_TREE:
      case SINGLE_TREE:
        return root.pcbId || null;
      default: return null;
    }
  }

  runRender = () => {
    const { projectId, simLoading } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    return (
      <div className={`cascade-top-bar-item ${PCBExist ? '' : 'cascade-top-bar-item-disabled'}`} onClick={PCBExist && !simLoading ? this.runClick : null} key='run' title='Run'>
        <div className="cascade-top-bar-setup">
          {simLoading ? <LoadingOutlined /> : <PlayCircleOutlined />}
          <span className='cascade-top-bar-item-hide'>Run</span>
        </div>
      </div>
    );
  }

  updateCreateConfig = (e, type) => {
    e && e.stopPropagation()
    e && e.preventDefault();
    let { createConfig } = this.state
    createConfig[type] = !createConfig[type]
    this.setState({
      createConfig
    })
  }

  updateImpCreateConfig = (e, type) => {
    e && e.stopPropagation()
    e && e.preventDefault();
    let { createImpConfig } = this.state
    createImpConfig[type] = !createImpConfig[type]
    this.setState({
      createImpConfig
    })
  }

  changeScheOpen = (boolean) => {
    this.setState({
      scheVisible: boolean
    })
  }

  _createClick = () => {
    this.createClick()
  }

  _createImpClick = () => {
    this.createImpClick()
  }

  showResultDropdown = (boolean) => {
    this.setState({
      resultDropdown: boolean
    })
  }

  changeWireBondOpen = (boolean) => {
    this.setState({
      wireBondVisible: boolean
    })
  }

  openLayoutPanel = (key) => {
    switch (key) {
      case STACKUP:
        this.updateStackupPanel(true);
        break;
      case PADSTACKS:
        this.updatePadstacksPanel(true)
        break;
      case WIRE_BOND_PROFILE:
        this.changeWireBondOpen(true)
        break;
      default:
        break;

    }
  }

  createMenu = () => {
    const { createConfig: { AC, DC } } = this.state;
    return [
      {
        key: 'AC',
        className: `ant-dropdown-menu-item create-menu-item`,
        label: <Fragment>
          <Checkbox
            className='dcr-dropdown-checkbox'
            checked={AC}
            onClick={(e) => this.updateCreateConfig(e, 'AC')}
          />
          <span>AC Impedance</span>
        </Fragment>,
      },
      {
        key: 'DC',
        className: `ant-dropdown-menu-item create-menu-item`,
        label: <Fragment>
          <Checkbox
            className='dcr-dropdown-checkbox'
            checked={DC}
            onClick={(e) => this.updateCreateConfig(e, 'DC')}
          />
          <span>DC Path-R</span>
        </Fragment>,
      },
      {
        key: 'Run',
        className: `dcr-dropdown-run ant-dropdown-menu-item ${AC || DC ? '' : 'dcr-dropdown-run-disabled'}`,
        label: 'Run',
        onClick: () => AC || DC ? this.createClick() : null
      }
    ]
  }

  createImpMenu = () => {
    const { runError, hasTransient, createImpConfig: { impedance, transient } } = this.state;
    const _simulateActive = impedance || transient ? true : false;
    return [
      {
        key: 'impedance',
        className: `ant-dropdown-menu-item create-menu-item`,
        label: <Fragment>
          <Checkbox
            className='dcr-dropdown-checkbox'
            checked={impedance}
            onClick={(e) => this.updateImpCreateConfig(e, 'impedance')}
          />
          <span>Impedance</span>
        </Fragment>,
      },
      {
        key: 'transient',
        className: `ant-dropdown-menu-item create-menu-item`,
        label: <Fragment>
          <Checkbox
            className='dcr-dropdown-checkbox'
            checked={transient}
            onClick={(e) => this.updateImpCreateConfig(e, 'transient')}
            disabled={!hasTransient}
          />
          <span>Transient
            {!hasTransient && <Tooltip title={runError} overlayClassName="aurora-tooltip" zIndex={3000}>
              <QuestionCircleOutlined className='cascade-imp-icon' />
            </Tooltip>}
          </span>
        </Fragment>,
      },
      {
        key: 'Run',
        className: `dcr-dropdown-run ant-dropdown-menu-item ${_simulateActive ? '' : 'dcr-dropdown-run-disabled'}`,
        label: 'Run',
        onClick: () => _simulateActive ? this._createImpClick() : null
      }
    ]
  }

  stopPropagation = (e) => {
    e && e.stopPropagation && e.stopPropagation()
    e && e.preventDefault && e.preventDefault()
  }

  createRender = () => {
    const { projectId, signOffVerificationId, signOffCreateTaskStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId);
    const list = CascadeChannels.getList(SIGN_OFF_TEMPLATE, projectId);
    const template = list.find(item => item.id === signOffVerificationId);
    const enabled = PCBExist && template && ![SIGN_OFF_RUNNING, SIGN_OFF_UPDATE].includes(template.status);

    return (
      <Fragment key="templateCreate">
        {PCBExist ? <Dropdown
          trigger={['click']}
          placement="bottom"
          menu={{ items: this.createMenu(), className: "cascade-top-bar-dropdown-menu ant-dropdown-menu" }}
        >
          <div className={enabled ? 'cascade-top-bar-item' : 'cascade-top-bar-item cascade-top-bar-item-disabled'}>
            <div className="cascade-top-bar-setup">
              {signOffCreateTaskStatus ? <LoadingOutlined /> : <PlayCircleOutlined />}
              <span className='cascade-top-bar-item-hide'>Run</span>
            </div>
          </div>
        </Dropdown> : null}</Fragment>
    );
  }

  impTransientRender = () => {
    const { simLoading, projectId } = this.props
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId);
    const enabled = PCBExist
    return <Fragment key="impedanceRunRender">
      <Dropdown
        menu={{ items: this.createImpMenu(), className: "cascade-top-bar-dropdown-menu ant-dropdown-menu" }}
        trigger={['click']}
        placement="bottomLeft"
        onOpenChange={this.updateImpRunStatus}
      >
        <div className={enabled ? 'cascade-top-bar-item' : 'cascade-top-bar-item cascade-top-bar-item-disabled'}>
          <div className="cascade-top-bar-setup">
            {simLoading ? <LoadingOutlined /> : <PlayCircleOutlined />}
            <span className='cascade-top-bar-item-hide'>Run</span>
          </div>
        </div>
      </Dropdown>
    </Fragment>
  }

  excelRender = () => {
    return <div className="cascade-top-bar-item" key='excel' title='Excel'>
      <Dropdown menu={{ items: this.setupMenu() }} title="Setup" trigger={['click']} >
        <div className="cascade-top-bar-setup">
          <span className={`iconfont icon-file-excel-2-line cascade-sus-report-icon`} ></span>
          <span className='cascade-top-bar-item-hide'>Excel</span>
        </div>
      </Dropdown>
    </div>
  }

  debugRender = () => {
    return <div className='cascade-top-bar-item' onClick={(e) => this.openDebugDownloadModal(e)} key='debug' title='Debug'>
      <div className="cascade-top-bar-setup">
        <i className='iconfont icon-Debug'></i>
        <span className='cascade-top-bar-item-hide'>Debug</span>
      </div>
    </div>
  }

  sParameterModelDownloadRender = () => {
    return (
      <div className='cascade-top-bar-item'
        onClick={() => this.updateSParameterModelDownloadModal(true)}
        key='download'
        title='Download'
      >
        <div className="cascade-top-bar-setup">
          <DownloadOutlined className="cascade-download-icon" />
          <span className='cascade-top-bar-item-hide'>Download</span>
        </div>
      </div>
    );
  }

  extractionRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    return (
      <div
        className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
        key='extraction'
        onClick={PCBExist && !designStatus ? () => this.updateExtractionPanel(true) : null}
        title='Extraction'
      >
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Extraction</span>
        </div>
      </div>
    );
  }

  DCExtractionRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId);
    return (
      <div
        className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
        key='dc_extraction'
        onClick={PCBExist && !designStatus ? () => this.updateDCExtractionPanel(true) : null}
        title='Extraction'
      >
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Extraction</span>
        </div>
      </div>
    );
  }

  treeOptionRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = !designId ? true : projectDesigns.getDesignExist(projectId, designId)
    return (
      <div
        className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
        key='tree_option'
        onClick={PCBExist && !designStatus ? () => this.updateTreeOptionPanel(true) : null}
        title='Options'
      >
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Options</span>
        </div>
      </div>
    );
  }

  treePlotSettingRender = () => {
    return <div
      className={`cascade-top-bar-item`}
      key='tree_setting'
      onClick={() => this.updateTreeSettingPanel(true)}
      title='Power Tree Setting'
    >
      <div className="cascade-top-bar-setup">
        <SettingOutlined className="cascade-setting-icon" />
        <span className='cascade-top-bar-item-hide'>Settings</span>
      </div>
    </div>
  }

  optionRender = () => {
    return (
      <div
        className={`cascade-top-bar-item`}
        key='options'
        title='Options'
        onClick={() => this.updateOptionPanel(true)}
      >
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Options</span>
        </div>
      </div>
    );
  }

  templateOptionRender = () => {
    return (
      <div
        className={`cascade-top-bar-item`}
        key='options'
        title='Options'
        onClick={() => this.updateTemplateOptionPanel(true)}
      >
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Options</span>
        </div>
      </div>
    );
  }

  resultRender = () => {
    const disableClassName = this.getDisabled();
    return <div className={`cascade-top-bar-result-item ${disableClassName}`} onClick={(e) => this.openResult()} key='result'>
      <span className='cascade-top-bar-result-vertical-line'></span>
      <div className='cascade-top-bar-result-title'>
        <span className='cascade-top-bar-item-hide'>Result</span>
        <span className='cascade-top-bar-result-icon'>&gt;</span>
      </div>
    </div>
  }

  backOverviewRender = () => {
    return <div className={`cascade-top-bar-result-item`} onClick={(e) => this.changeImpedancePage()} key='overview'>
      <div className='cascade-top-bar-result-title'>
        <span className='cascade-top-bar-result-icon mirror-icon'>&lt;</span>
        <span className='cascade-top-bar-item-hide'>Overview</span>
      </div>
      <span className='cascade-top-bar-result-vertical-line mirror-line'></span>
    </div>
  }

  settingRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    return <div
      className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
      onClick={() => PCBExist && !designStatus ? this.updateComponentSettingPanel(true) : null}
      key='component-setting'
      title={'Components'}
    >
      <div className="cascade-top-bar-setup">
        <span className="iconfont icon-icons8-circuit cascade-setting-icon" />
        <span className='cascade-top-bar-item-hide'>Components</span>
      </div>
    </div>
  }

  layoutRender = (children) => {
    const designId = this.getCurrentDesign();
    const { projectId, designStatus } = this.props;
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    const TOP_MENU_TITLE = {
      [PADSTACKS]: 'Via Padstacks',
      [STACKUP]: 'Stackup',
      [WIRE_BOND_PROFILE]: 'Wire Bond Profile'
    }
    const disabled = PCBExist && !designStatus ? false : true;
    const menu = children.map(key => ({ key, onClick: () => this.openLayoutPanel(key), disabled: disabled, label: TOP_MENU_TITLE[key] }))

    return <div
      className={!disabled ? 'cascade-top-bar-item' : 'cascade-top-bar-item cascade-top-bar-item-disabled'}
      key='Layout'
      title='Layout'
    >
      <Dropdown menu={{ items: !disabled ? menu : [] }} trigger={['click']}>
        <div className='cascade-top-bar-setup' onClick={e => e.preventDefault()}>
          <span className="iconfont icon-stack aurora-top-bar-stackup-icon" />
          <span className="cascade-top-bar-item-hide">Layout</span>
        </div>
      </Dropdown>
    </div>
  }

  stackupRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    return <div
      className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
      onClick={() => PCBExist && !designStatus ? this.updateStackupPanel(true) : null}
      key='stackup'
      title={'Stackup'}
    >
      <div className="cascade-top-bar-setup">
        <span className="iconfont icon-stack aurora-top-bar-stackup-icon" />
        <span className='cascade-top-bar-item-hide'>Stackup</span>
      </div>
    </div>
  }

  padstacksRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    return <div
      className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
      onClick={() => PCBExist && !designStatus ? this.updatePadstacksPanel(true) : null}
      key='padstacks'
      title={'Via Padstacks'}
    >
      <div className="cascade-top-bar-setup">
        <span className="iconfont icon-stack aurora-top-bar-stackup-icon" />
        <span className='cascade-top-bar-item-hide'>Via Padstacks</span>
      </div>
    </div>
  }

  resetRender = () => {
    return (
      <div
        className={`cascade-top-bar-item`}
        key='reset'
        title='Refresh'
        onClick={() => this.updateResetVisible(true)}
      >
        <div className="cascade-top-bar-setup">
          <RedoOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Refresh</span>
        </div>
      </div>
    );
  }

  resultMenu = () => {
    const { treeResult = {}, hasTreeResult } = this.props;
    const { pass, pairRes, voltage } = treeResult;
    return hasTreeResult ? [
      {
        key: 'pass',
        onClick: (e) => this.updateSingleResult('pass', !pass),
        label: <Fragment>
          <Checkbox className='dcr-dropdown-checkbox' checked={pass} />
          Device Voltage
        </Fragment>
      },
      {
        key: 'voltage',
        onClick: (e) => this.updateSingleResult('voltage', !voltage),
        label: <Fragment>
          <Checkbox className='dcr-dropdown-checkbox' checked={voltage} />
          Net Voltage
        </Fragment>
      },
      {
        key: 'pairRes',
        onClick: (e) => this.updateSingleResult('pairRes', !pairRes),
        label: <Fragment>
          <Checkbox className='dcr-dropdown-checkbox' checked={pairRes} />
          Path Resistance
        </Fragment>
      }
    ] : []
  }

  showResultRender = () => {
    const { treeResult = {}, hasTreeResult } = this.props;
    const { pass, pairRes, voltage } = treeResult;
    const checked = pass && pairRes && voltage ? true : false;
    return <Dropdown
      menu={{ items: this.resultMenu(), className: "upload-dropdown-button" }}
      trigger={['hover']}
      placement="bottomLeft"
      key='showResultMenu'
    >
      <div
        className={`cascade-top-bar-item ${hasTreeResult ? '' : 'cascade-top-bar-item-disabled'}`}
        key='showResult'
        title='Show Result'
        onClick={(e) => this.changeTreeShow(e)}
      >
        <div className="cascade-top-bar-setup">
          <Checkbox
            className="cascade-show-result-checkbox"
            indeterminate={hasTreeResult && !checked ? (pass || pairRes || voltage) : false}
            checked={hasTreeResult ? checked : false}
            disabled={!hasTreeResult}
            onChange={this.changeTreeShow}
          />
          <span className='cascade-top-bar-item-hide'>Show Result</span>
        </div>
      </div>
    </Dropdown>
  }

  reportRender = () => {
    const { reportPanelVisible } = this.state;
    const { reportInfo: { reportProgress, reportVisible } } = this.props;
    return reportTopBarTitle({
      reportPanelVisible,
      reportVisible,
      reportProgress,
      className: "cascade-top-bar-item-hide",
      updateReportPanel: this.updateReportPanel
    })
  }

  connectionRender = () => {
    const { root = {} } = this.props;
    return (
      <div
        className={`cascade-top-bar-item ${root.pcbId ? '' : 'cascade-top-bar-item-disabled'}`}
        onClick={() => root.pcbId && this.updateConnectionPanel(true)}
        key='connection'
        title={'Connection'}
      >
        <div className="cascade-top-bar-setup">
          <ApartmentOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Connection</span>
        </div>
      </div>
    );
  }

  rootPCBRender = () => {
    let designId = this.getCurrentDesign();
    const { projectId, designStatus } = this.props;
    const PCBExist = !designId ? true : projectDesigns.getDesignExist(projectId, designId);
    return (
      <div
        className={`cascade-top-bar-item  ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
        onClick={() => PCBExist && !designStatus ? this.changeRootPCBOpen(true) : null}
        key='rootPCB'
        title={PCBExist && !designStatus ? 'Root' : 'Loading PCB'}
      >
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Root</span>
        </div>
      </div>
    );
  }

  schematicsRender = () => {
    return (
      <div
        className={`cascade-top-bar-item`}
        onClick={() => this.changeScheOpen(true)}
        key='schematics'
        title={'Schematic'}
      >
        <div className="cascade-top-bar-setup">
          <BranchesOutlined className="cascade-setting-icon cascade-schematics-icon" />
          <span className='cascade-top-bar-item-hide'>Schematic</span>
        </div>
      </div>
    );
  }

  extractionMenu = (enabled) => {
    return [
      { key: 'ac', label: 'AC Impedance', onClick: enabled ? () => this.updateExtractionPanel(true) : null, className: `${enabled ? '' : 'cascade-top-bar-item-disabled'}` },
      { key: 'dc', label: 'DC Path-R', onClick: enabled ? () => this.updateDCExtractionPanel(true) : null, className: `${enabled ? '' : 'cascade-top-bar-item-disabled'}` }
    ]
  }

  extractionMenuRender = () => {
    const { projectId, designStatus } = this.props;
    let designId = this.getCurrentDesign();
    const PCBExist = projectDesigns.getDesignExist(projectId, designId)
    return (
      <Dropdown
        menu={{ items: this.extractionMenu(PCBExist && !designStatus), className: "upload-dropdown-button" }}
        trigger={['hover']}
        placement="bottomLeft"
        key='extractionMenu'
      >
        <div
          className={`cascade-top-bar-item ${PCBExist && !designStatus ? '' : 'cascade-top-bar-item-disabled'}`}
          key='extractionMenu'
          title='Extraction'
        >
          <div className="cascade-top-bar-setup">
            <SettingOutlined className="cascade-setting-icon" />
            <span className='cascade-top-bar-item-hide'>Extraction</span>
          </div>
        </div>
      </Dropdown>
    );
  }

  exportRender = () => {
    return <div className="cascade-top-bar-item" key='export' title='Export' onClick={() => this.exportFile()}>
      <div className="cascade-top-bar-setup">
        <span className={`iconfont icon-file-excel-2-line cascade-sus-report-icon`} ></span>
        <span className='cascade-top-bar-item-hide'>Export</span>
      </div>
    </div>
  }

  serviceOptionsRender = () => {
    return (
      <div className="cascade-top-bar-item" key='serviceOptions' title='Service Options' onClick={() => this.updateServiceOptionsPanel(true)}>
        <div className="cascade-top-bar-setup">
          <SettingOutlined className="cascade-setting-icon" />
          <span className='cascade-top-bar-item-hide'>Service Options</span>
        </div>
      </div>
    );
  }

  render() {
    const { width, verificationId, projectId, pathRVerificationId, Config, Options,
      impVerificationId, listStatus, powerTrees, powerVerificationId,
      treeVerificationId, viewList, signOffExtraction, updateExtraction, updateSignExtraction,
      connectors, root, treeOption, signOffVerificationId, layout, wireBondList, impDesignList,
      extractionHfssOptionsList, extractionSiwaveOptionsList, treeSetting, getSelectedDesignIDs,
      extractionPowerSIOptionsList, extractionPowerDCOptionsList,
      isImpSinglePCB, impComponents
    } = this.props;
    const view = viewList.find(item => [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE].includes(item));
    // const simulateActive = (selectedKeys && selectedKeys.length && selectedKeys.length === 1) ? true : false
    // const view = viewList.length ? viewList[0] : null;
    const currentProjectDesigns = projectDesigns.getDesigns(projectId);
    const currentPCBs = projectDesigns.getAvailablePCBs(projectId);
    const { importDataPanelVisible, debugDownloadVisible, extractionVisible, optionVisible,
      templateOptionVisible, stackupVisible, resetVisible, reportPanelVisible,
      sParameterModelDownloadVisible, connectionVisible, treeOptionVisible, scheVisible,
      extractionDCVisible, multiZonePreview, padstacksVisible, wireBondVisible, treeSettingVisible, serviceOptionsVisible } = this.state;
    const { left, right, children } = this.getTopBarItem();
    const pcbId = this.getCurrentDesign();

    return (
      <Fragment>
        {left.length || right.length ?
          <div id='cascade-top-bar' style={{ width: width ? width : '100%' }}>
            <Fragment>
              {left.map(item => {
                switch (item) {
                  case RUN: return this.runRender();
                  case IMPRUN: return this.impTransientRender()
                  case EXCEL: return this.excelRender();
                  case EXPORT: return this.exportRender();
                  case DEBUG: return this.debugRender();
                  case RESULT: return this.resultRender();
                  case BACK_OVERVIEW: return this.backOverviewRender();
                  case EXTRACTION: return this.extractionRender();
                  case EXTRACTION_DC: return this.DCExtractionRender()
                  case OPTION: return this.optionRender();
                  case SETTING: return this.settingRender();
                  case STACKUP: return this.stackupRender();
                  case PADSTACKS: return this.padstacksRender();
                  case CREATE: return this.createRender();
                  case TEMPLATE_OPTION: return this.templateOptionRender();
                  case REPORT: return this.reportRender();
                  case S_PARAMETER_MODEL_DOWNLOAD: return this.sParameterModelDownloadRender();
                  case CONNECTION: return this.connectionRender();
                  case ROOTPCB: return this.rootPCBRender();
                  case TREE_OPTION: return this.treeOptionRender();
                  case SCHEMATICS: return this.schematicsRender();
                  case EXTRACTION_MENU: return this.extractionMenuRender();
                  case LAYOUT: return this.layoutRender(children[LAYOUT] || []);
                  case SERVICE_OPTIONS: return this.serviceOptionsRender();
                  default: return null;
                }
              })}
              <div className='cascade-top-bar-right-item' id='cascade-right-top-bar'>
                {right.map(item => {
                  switch (item) {
                    case RESET: return this.resetRender();
                    case SHOW_RESULT: return this.showResultRender();
                    case TREE_SETTING: return this.treePlotSettingRender();
                    default: return null;
                  }
                })}
              </div>
            </Fragment>
          </div>
          : null
        }
        <input
          type='file'
          ref={this.uploadFileRef}
          style={{ display: 'none' }}
          onChange={(e) => this.onUploadFile(e)}
        />
        {(importDataPanelVisible) &&
          <ImportPanel importSelectClick={this.importSelectClick} />}
        {debugDownloadVisible &&
          <DebugDownloadPanel
            closeModel={this.closeDebugDownloadPanel}
            currentProjectDesigns={currentProjectDesigns}
            irVerificationId={verificationId}
            pathRVerificationId={pathRVerificationId}
            impVerificationId={impVerificationId}
            treeVerificationId={treeVerificationId}
            projectId={projectId}
            updateDebug={listStatus}
            updateListStatus={this.props.updateListStatus}
            view={view}
          />
        }
        {extractionVisible &&
          <ExtractionPanel
            Config={view === SIGN_OFF_TEMPLATE ? signOffExtraction : Config}
            designList={view === SIGN_OFF_TEMPLATE ? [] : impDesignList}
            saveConfig={view === SIGN_OFF_TEMPLATE ? updateSignExtraction : updateExtraction}
            pcbId={pcbId}
            view={view}
            closeModal={this.updateExtractionPanel}
            showPreview={this.showPreview}
            previewBtnOnRef={this.previewBtnOnRef}
            savePreviewStatus={this.props.savePreviewStatus}
            changePreviewShowState={this.changePreviewShowState}
            previewMultiZone={this.previewMultiZone}
            multiZonePreview={multiZonePreview}
            vendor={this.getExtractionDesignVendor()}
            extractionHfssOptionsList={extractionHfssOptionsList}
            extractionSiwaveOptionsList={extractionSiwaveOptionsList}
            extractionPowerSIOptionsList={extractionPowerSIOptionsList}
            layout={layout}
            getSelectedDesignIDs={getSelectedDesignIDs}
          />
        }
        {
          extractionDCVisible &&
          <DCExtractionPanel
            view={view}
            closeModal={this.updateDCExtractionPanel}
            Config={this.getDCExtraction(view)}
            saveConfig={this.getExtractionSave(view)}
            vendor={this.getExtractionDesignVendor()}
            extractionSiwaveOptionsList={extractionSiwaveOptionsList}
            extractionPowerDCOptionsList={extractionPowerDCOptionsList}
          />
        }
        {
          treeOptionVisible &&
          <TreeOptionPanel
            option={treeOption}
            view={view}
            closeModal={this.updateTreeOptionPanel}
            saveOption={this.props.saveOption}
            single={connectors.length > 1 ? false : true}
          />
        }
        {
          optionVisible &&
          <OptionPanel
            Options={Options}
            closeModal={this.updateOptionPanel}
            saveOptions={this.props.updateOptions}
            isImpSinglePCB={isImpSinglePCB}
            impComponents={impComponents}
          />
        }
        {
          treeSettingVisible &&
          <TreeSettingPanel
            setting={treeSetting}
            closeModal={this.updateTreeSettingPanel}
            updateTreeSetting={this.props.updateTreeSetting}
          />
        }
        {
          stackupVisible &&
          <StackupPanel
            {...this.props}
            pageType={CASCADE}
            designID={pcbId}
            Stackup={Stackup}
            designList={currentPCBs}
            closeModal={this.updateStackupPanel} />
        }
        {resetVisible ? <DelConfirm
          type='refresh'
          refreshConfirm={(total) => this.resetItem(total)}
          cancelRefresh={() => this.updateResetVisible(false)}
          // type='delete'
          // deleteConfirm={() => this.resetItem()}
          // cancelDel={() => this.updateResetVisible(false)}
          okTitle={'Refresh'}
          message={"Do you want to refresh the Power Tree?"}
        />
          : null}
        {templateOptionVisible ?
          <TemplateOptionPanel
            closeModal={this.updateTemplateOptionPanel}
          />
          : null}
        {
          reportPanelVisible ?
            <ReportPanel
              changeReportConfig={this._changeReportConfig}
              closeModal={() => this.updateReportPanel(false)}
            />
            : null}
        {sParameterModelDownloadVisible ?
          <SParameterModelDownloadPanel
            closeModal={() => this.updateSParameterModelDownloadModal(false)}
            view={view}
            verificationId={view === SIGN_OFF_TEMPLATE ? signOffVerificationId : view === IMPEDANCE ? impVerificationId : null}
          />
          : null}
        {connectionVisible ?
          <ConnectionPanel
            closeModal={() => this.updateConnectionPanel(false)}
            saveConnectors={this.props.saveConnectors}
            designList={currentPCBs}
            connectors={connectors}
            root={root}
            powerTrees={powerTrees || []}
          />
          : null}
        {scheVisible ?
          <Schematic
            closeModal={() => this.changeScheOpen(false)}
            pcbId={pcbId}
            powerTrees={powerTrees || []}
            treeVerificationId={treeVerificationId}
            powerVerificationId={powerVerificationId}
          />
          : null}
        {padstacksVisible ? <Fragment>
          <PadstackPanel
            designId={pcbId}
            closePanel={() => this.updatePadstacksPanel(false)}
            layout={layout}
          />
        </Fragment> : null}
        {wireBondVisible && <Fragment>
          <WireBondPanel
            wireBondProfileList={wireBondList}
            designId={pcbId}
            saveWireBondProfile={this.props.saveWireBond}
            getLibraryFileContent={getLibraryFileInfo}
            closePanel={() => this.changeWireBondOpen(false)}
          />
        </Fragment>}
        {serviceOptionsVisible && <Fragment>
          <ServiceOptionsPanel
            closePanel={() => this.updateServiceOptionsPanel(false)}
          />
        </Fragment>}
      </Fragment>
    )
  }
}

const mapState = (state) => {
  const { CascadeReducer: {
    library: { libraryStatus, wireBondList, extractionHfssOptionsList, extractionSiwaveOptionsList, extractionPowerSIOptionsList, extractionPowerDCOptionsList },
    project: { viewList, openProjectId, selectedKeys, listStatus, designStatus, stackupList, previewStatus, forcePreviewShow, reportInfo = {}, rootVisible, simLoading, layout },
    DCR: { verificationId: pathRVerificationId, resistanceData, designId: dcrDesignId, extraction: dcrExtraction },
    IR: { verificationId, resultExist, IRExplorerInfo: { IRTableData = [] }, designId: irDesignId, extraction: IRExtraction },
    Impedance: { Config, Optimization, verificationId: impVerificationId, resultExist: ImpResultExist, designId: impDesignId, Options, data: ImpData = [], targetIC, designType, page },
    PowerTree: { connectors, initConn, root, verificationId: powerVerificationId, results },
    DesignTree: { designId: treeDesignId, verificationId: treeVerificationId, showResult: treeResult, powerTrees, option: treeOption,
      results: { leafNodePassResults, pinPairResResults, pinVoltageResults }, extraction: treeExtraction, setting: treeSetting
    },
    SignOffTemplate: { designId: signOffDesignId, verificationId: signOffVerificationId, signOffCreateTaskStatus, data: signData }
  },
    StackupReducer: { stackupObj } } = state;
  let _resultExist = {
    id: "",
    exit: false
  }
  if (viewList.includes(IR_EXPLORER)) {
    _resultExist = resultExist
  } else if (viewList.includes(IMPEDANCE)) {
    _resultExist = ImpResultExist
  }
  const hasTreeResult = viewList.includes(POWER_TREE) ? results.length ? true : false
    : leafNodePassResults.length || pinPairResResults.length || pinVoltageResults.length ? true : false
  const signOffExtraction = signData.extraction || {}
  const signOffDCExtraction = signData.dcExtraction || new DCExtraction();
  const _ImpData = page === OVERVIEW ? ImpData.find(item => item.designId === impDesignId) : ImpData.find(item => item.designId === page);
  const impHasDie = ImpData.find(item => item.designId === IMPEDANCE_DIE);
  const impPcbData = ImpData.filter(item => item.type === IMPEDANCE_PCB);
  const impHasPackage = ImpData.find(item => item.index === TARGET_PACKAGE_INDEX);
  const impDesignList = ImpData.filter(item => item.type !== IMPEDANCE_DIE).map(item => ({ id: item.designId, name: item.designName, extraction: item.extraction, powerDomains: item.powerDomains }))
  const isImpSinglePCB = ImpData.filter(item => item.index !== TARGET_DIE_INDEX).length === 1
  let impComponents = []
  if (isImpSinglePCB) {
    ImpData[0].powerDomains.forEach(domain => {
      const capComps = domain.content.Components.filter(item => item.usage === CAP).map(item => item.name)
      impComponents.push(...capComps)
    })
    impComponents = [...new Set(impComponents)]
  }
  const _designType = page === OVERVIEW ? designType : _ImpData ? _ImpData.type : designType
  return {
    viewList,
    projectId: openProjectId,
    selectedKeys,
    resistanceData,
    verificationId,
    IRTableData,
    resultExist: _resultExist,
    pathRVerificationId,
    Config,
    Optimization,
    impVerificationId,
    listStatus,
    dcrDesignId,
    irDesignId,
    impDesignId: page === OVERVIEW ? impDesignId : page,
    signOffDesignId,
    signOffVerificationId,
    signOffCreateTaskStatus,
    selectedIds: getSelectedSetupIDs(selectedKeys),
    getSelectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    libraryStatus,
    treeDesignId,
    treeVerificationId,
    designStatus,
    treeResult,
    hasTreeResult,
    stackupList,
    reportInfo,
    Options,
    previewStatus,
    forcePreviewShow,
    signOffExtraction,
    connectors,
    initConn,
    root,
    powerVerificationId,
    powerTrees,
    rootVisible,
    treeOption,
    treeExtraction,
    dcrExtraction,
    IRExtraction,
    signOffDCExtraction,
    simLoading,
    stackupObj,
    targetIC,
    layout,
    designType: _designType,
    wireBondList,
    page,
    impDesignList,
    extractionHfssOptionsList,
    extractionSiwaveOptionsList,
    extractionPowerSIOptionsList,
    extractionPowerDCOptionsList,
    treeSetting,
    impPcbData,
    isImpSinglePCB,
    impComponents,
    impHasDie,
    impHasPackage
  }
}

const mapDispatch = (dispatch) => ({
  _uploadDCR(verificationId, file, importType) {
    dispatch(uploadDCR(verificationId, file, importType))
  },
  _changeResistanceTableLoading(loading) {
    dispatch(changeResistanceTableLoading(loading))
  },
  _startDCRSimulation(verificationId) {
    dispatch(startDCRSimulation(verificationId))
  },
  _openTabFooter() {
    dispatch(openTabFooter())
  },
  _startIRSimulation(designId) {
    dispatch(startIRSimulation(designId))
  },
  _uploadIR(verificationId, file, importType) {
    dispatch(uploadIR(verificationId, file, importType))
  },
  _openPage(pageType, id) {
    dispatch(openPage(pageType, id))
  },
  startImpedanceSimulation(designId, params) {
    dispatch(startImpedanceSimulation(designId, params))
  },
  updateExtraction(designId, extraction, apply) {
    dispatch(updateExtraction(designId, extraction, apply))
  },
  updateSignExtraction(extraction) {
    dispatch(updateSignExtraction(extraction))
  },
  updateOptimization(config, opt) {
    dispatch(updateOptimization(config, opt))
  },
  updateListStatus(bool) {
    dispatch(updateListStatus(bool))
  },
  updatePCBLog(log) {
    dispatch(updatePCBLog(log))
  },
  _createAndRunImpedanceTask({ signOffVerificationId, signOffDesignId, createConfig }) {
    dispatch(createAndRunImpedanceTask({ signOffVerificationId, signOffDesignId, createConfig }))
  },
  startPowerTreeSimulation(verificationId, runType) {
    dispatch(startPowerTreeSimulation(verificationId, runType))
  },
  updateDesignTree(total) {
    dispatch(resetDesignTree(total));
  },
  uploadTreeSpec(verificationId, file) {
    dispatch(uploadTreeSpec(verificationId, file))
  },
  updateDesignTreeCompRLCPrefix(version) {
    dispatch(updateDesignTreeCompRLCPrefix(version))
  },
  updateTemplateByCompSetting(update) {
    dispatch(updateTemplateByCompSetting(update))
  },
  showDesignTreeResult(show) {
    dispatch(showDesignTreeResult(show))
  },
  updateStackupList(stackupList) {
    dispatch(updateStackupList(stackupList))
  },
  _updateReportInfo(reportInfo, isUpdate) {
    dispatch(updateReportInfo(reportInfo, isUpdate))
  },
  updateOptions(options) {
    dispatch(updateOptions(options))
  },
  updatePageLayout(layout) {
    dispatch(updatePageLayout(layout))
  },
  updateViewList(viewList) {
    dispatch(updateViewList(viewList))
  },
  updateSelectKeys(selectedKeys) {
    dispatch(updateSelectKeys(selectedKeys))
  },
  savePreviewStatus(status) {
    dispatch(savePreviewStatus(status))
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _changeShowNameStatus(status, designID) {
    dispatch(changeShowNameStatus(status, designID))
  },
  saveConnectors(connectors, refresh) {
    dispatch(saveConnectors(connectors, refresh))
  },
  setPowerTreeData(params) {
    dispatch(setPowerTreeData(params))
  },
  updateComponentSettingVisible(boolean) {
    dispatch(updateComponentSettingVisible(boolean))
  },
  updateRootPanelVisible(boolean) {
    dispatch(updateRootPanelVisible(boolean))
  },
  saveOption(option) {
    dispatch(saveOption(option))
  },
  saveTreeExtraction(extraction) {
    dispatch(saveTreeExtraction(extraction))
  },
  saveDCRExtraction(extraction) {
    dispatch(saveDCRExtraction(extraction))
  },
  saveIRExtraction(extraction) {
    dispatch(saveIRExtraction(extraction))
  },
  updateDCRExtraction(extraction) {
    dispatch(updateDCRExtraction(extraction))
  },
  updateSimulationLoading(loading) {
    dispatch(updateSimulationLoading(loading))
  },
  changeStackupZonePanelShow(show, designId) {
    dispatch(changeStackupZonePanelShow(show, designId))
  },
  updateSelectedZones(selectedZones, designId) {
    dispatch(updateSelectedZones(selectedZones, designId))
  },
  saveWireBond(librariId, designId) {
    dispatch(saveWireBond(librariId, designId))
  },
  changeImpedancePage(page) {
    dispatch(changeImpedancePage(page))
  },
  updateTreeSetting(setting, params) {
    dispatch(updateTreeSetting(setting, params))
  }
})

export default connect(mapState, mapDispatch)(TopBar)