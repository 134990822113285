import { select, event, mouse, selectAll } from 'd3-selection';
import { DC_PLOT_COMP_HEIGHT, DC_PLOT_COMP_WIDTH, DC_PLOT_ROW_HEIGHT, DC_PLOT_WIDTH } from './constants';
import { getComponentColor } from '../helper/setupData';
import { getTextWidthAndHeight } from '../../helper/getTextWidth';

class Plot {
  constructor(props) {
    const { svg, events } = props;
    this.svgNode = svg;
    this.events = events;
    this.d3Root = select(this.svgNode)
      .append('g')
      .attr('fill-rule', 'evenodd');
  }

  drawGrid = () => {
    const width = this.svgNode.width.baseVal.value;
    const height = this.svgNode.height.baseVal.value;

    if (!this.gridRoot) {
      this.gridRoot = this.d3Root.append('g').attr('class', 'cascade-dc-pre-grid')
    }

    this.gridRoot
      .selectAll('.grid-line')
      .remove()

    if (height > DC_PLOT_ROW_HEIGHT) {
      const max = Math.ceil(height / DC_PLOT_ROW_HEIGHT);
      for (let i = 1; i < max; i++) {
        this.gridRoot
          .append('line')
          .attr('class', 'grid-line')
          .attr('x1', 0)
          .attr('y1', i * DC_PLOT_ROW_HEIGHT)
          .attr('x2', width)
          .attr('y2', i * DC_PLOT_ROW_HEIGHT)
          .attr('stroke-width', 1)
      }
    }

    if (width > DC_PLOT_WIDTH) {
      const max = Math.ceil(width / DC_PLOT_WIDTH);
      for (let i = 1; i < max; i++) {
        this.gridRoot
          .append('line')
          .attr('class', 'grid-line')
          .attr('x1', i * DC_PLOT_WIDTH)
          .attr('y1', 0)
          .attr('x2', i * DC_PLOT_WIDTH)
          .attr('y2', height)
      }
    }
  }

  drawComps = (comps) => {

    if (!this.compRoot) {
      this.compRoot = this.d3Root.append('g').attr('class', 'cascade-dc-pre-comps');
    }

    this.compRoot
      .selectAll('.dc-comp')
      .remove()

    // root group
    for (let comp of comps) {
      this.drawSingleComp(comp)
    }
  }

  drawNets = (nets, comps) => {

    if (!this.netRoot) {
      this.netRoot = this.d3Root.append('g').attr('class', 'cascade-dc-pre-nets');
    }

    this.netRoot
      .selectAll('.dc-net')
      .remove()

    this.netRoot
      .selectAll('.dc-gnd-net')
      .remove()

    for (let net of nets) {
      this.drawSingleNet(net, comps);
    }
  }

  drawComp = (comp) => {
    const { id } = comp;

    this.compRoot
      .select(`g[compid="${id}"]`)
      .remove()

    this.drawSingleComp(comp)
  }

  drawNet = (net, comps) => {
    const { id } = net;

    this.netRoot
      .select(`g[netid="${id}"]`)
      .remove()

    this.drawSingleNet(net, comps)
  }

  drawSingleComp = (comp) => {
    const { name, type, column, row, id } = comp
    const color = getComponentColor(type);

    const compG = this.compRoot
      .append('g')
      .attr('class', 'dc-comp')
      .attr('comp', name)
      .attr('compid', id)
      .on('click', () => {
        this.events.click && this.events.click('comp', id)
      })


    compG
      .append('rect')
      .attr('class', 'dc-comp-rect')
      .attr('x', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH))
      .attr('y', row * DC_PLOT_ROW_HEIGHT + 0.5 * (DC_PLOT_ROW_HEIGHT - DC_PLOT_COMP_HEIGHT))
      .attr('width', DC_PLOT_COMP_WIDTH)
      .attr('height', DC_PLOT_COMP_HEIGHT)
      .attr('fill', color)
      .attr('rx', 10)
      .attr('ry', 10)

    const { width: nameWidth } = getTextWidthAndHeight(name, { fontSize: 16, fontWeight: 'bold' })
    compG
      .append('text')
      .text(name)
      .attr('class', 'dc-comp-name')
      .attr('x', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH - 0.5 * nameWidth)
      .attr('y', row * DC_PLOT_ROW_HEIGHT + 0.5 * (DC_PLOT_ROW_HEIGHT))
      .style('fill', '#000000')
  }

  drawSingleNet = (net, comps) => {
    const { components: _comps, value, level, name, id, isGnd } = net;

    if (isGnd) {
      this.drawSingleGndNet(net, comps)
      return;
    }

    const compPosition = _comps.map(comp => {
      const info = comps.find(item => item.name === comp);
      if (!info) {
        return null;
      }
      const { row, column } = info;
      return { row, column }
    }).filter(item => !!item);

    const res = ` - ${value}mΩ`;
    const { width: nameWidth } = getTextWidthAndHeight(name, { fontSize: 12, fontWeight: 'bold' })
    const { width: resWidth } = getTextWidthAndHeight(res, { fontSize: 12 })
    const netG = this.netRoot
      .append('g')
      .attr('class', 'dc-net')
      .attr('net', name)
      .attr('netid', id)

    const row = compPosition.map(item => Number(item.row));
    const rowMin = Math.min(...row);
    const rowMax = Math.max(...row);
    if (rowMin !== rowMax) {
      netG
        .append('line')
        .attr('class', 'net-line net-line-column')
        .attr('x1', level * DC_PLOT_WIDTH * (level + 1))
        .attr('y1', (rowMin + 0.5) * DC_PLOT_ROW_HEIGHT)
        .attr('x2', level * DC_PLOT_WIDTH * (level + 1))
        .attr('y2', (rowMax + 0.5) * DC_PLOT_ROW_HEIGHT)
        .on('click', () => {
          this.events.click && this.events.click('net', id)
        })
    }
    for (let comp of compPosition) {
      const { row, column } = comp;
      if (column > level) {
        netG
          .append('line')
          .attr('class', 'net-line net-line-row')
          .attr('x1', column * DC_PLOT_WIDTH)
          .attr('y1', (row + 0.5) * DC_PLOT_ROW_HEIGHT)
          .attr('x2', (column + 0.1) * DC_PLOT_WIDTH)
          .attr('y2', (row + 0.5) * DC_PLOT_ROW_HEIGHT)
          .on('click', () => {
            this.events.click && this.events.click('net', id)
          })

        netG
          .append('text')
          .text(name)
          .attr('x', column * DC_PLOT_WIDTH - nameWidth - resWidth - 10)
          .attr('y', (row + 0.4) * DC_PLOT_ROW_HEIGHT)
          .attr('fill', '#000000')
          .attr('font-weight', 'bold')

        netG
          .append('text')
          .text(res)
          .attr('x', column * DC_PLOT_WIDTH - resWidth)
          .attr('y', (row + 0.4) * DC_PLOT_ROW_HEIGHT)
          .attr('fill', '#000000')
      } else {
        netG
          .append('line')
          .attr('class', 'net-line net-line-row')
          .attr('x1', (column + 0.1) * DC_PLOT_WIDTH)
          .attr('y1', (row + 0.5) * DC_PLOT_ROW_HEIGHT)
          .attr('x2', (column + 1) * DC_PLOT_WIDTH)
          .attr('y2', (row + 0.5) * DC_PLOT_ROW_HEIGHT)
          .on('click', () => {
            this.events.click && this.events.click('net', id)
          })
      }
    }
  }

  drawSingleGndNet = (net, comps) => {
    const { components: _comps, value, level, name, id } = net;

    const compPosition = _comps.map(comp => {
      const info = comps.find(item => item.name === comp);
      if (!info) {
        return null;
      }
      const { row, column } = info;
      return { row, column }
    }).filter(item => !!item);

    const res = ` - ${value}mΩ`;
    const { width: nameWidth } = getTextWidthAndHeight(name, { fontSize: 12, fontWeight: 'bold' })
    const { width: resWidth } = getTextWidthAndHeight(res, { fontSize: 12 })
    const netG = this.netRoot
      .append('g')
      .attr('class', 'dc-gnd-net')
      .attr('net', name)
      .attr('netid', id)

    const row = compPosition.map(item => Number(item.row));
    const rowMin = Math.min(...row);
    const rowMax = Math.max(...row);
    if (rowMin !== rowMax) {
      netG
        .append('line')
        .attr('class', 'net-line net-line-column')
        .attr('x1', level * DC_PLOT_WIDTH * (level + 1))
        .attr('y1', (rowMin + 0.5) * DC_PLOT_ROW_HEIGHT)
        .attr('x2', level * DC_PLOT_WIDTH * (level + 1))
        .attr('y2', (rowMax + 0.5) * DC_PLOT_ROW_HEIGHT)
        .on('click', () => {
          this.events.click && this.events.click('net', id)
        })
    }
    for (let comp of compPosition) {
      const { row, column } = comp;
      if (column > level) {
        netG
          .append('line')
          .attr('class', 'net-line net-line-row')
          .attr('x1', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH)
          .attr('y1', (row + 1) * DC_PLOT_ROW_HEIGHT - 0.5 * (DC_PLOT_ROW_HEIGHT - DC_PLOT_COMP_HEIGHT))
          .attr('x2', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH)
          .attr('y2', (row + 1) * DC_PLOT_ROW_HEIGHT)
          .on('click', () => {
            this.events.click && this.events.click('net', id)
          })

        netG
          .append('line')
          .attr('class', 'net-line net-line-row')
          .attr('x1', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH)
          .attr('y1', (row + 1) * DC_PLOT_ROW_HEIGHT)
          .attr('x2', 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH)
          .attr('y2', (row + 1) * DC_PLOT_ROW_HEIGHT)
          .on('click', () => {
            this.events.click && this.events.click('net', id)
          })
      } else {
        netG
          .append('line')
          .attr('class', 'net-line net-line-row')
          .attr('x1', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH)
          .attr('y1', (row + 1) * DC_PLOT_ROW_HEIGHT - 0.5 * (DC_PLOT_ROW_HEIGHT - DC_PLOT_COMP_HEIGHT))
          .attr('x2', column * DC_PLOT_WIDTH + 0.1 * (DC_PLOT_WIDTH - DC_PLOT_COMP_WIDTH) + 0.5 * DC_PLOT_COMP_WIDTH)
          .attr('y2', (row + 1) * DC_PLOT_ROW_HEIGHT)
          .on('click', () => {
            this.events.click && this.events.click('net', id)
          })

        netG
          .append('text')
          .text(name)
          .attr('x', (column + 1) * DC_PLOT_WIDTH - nameWidth - resWidth - 10)
          .attr('y', (row + 1.2) * DC_PLOT_ROW_HEIGHT)
          .attr('fill', '#000000')
          .attr('font-weight', 'bold')

        netG
          .append('text')
          .text(res)
          .attr('x', (column + 1) * DC_PLOT_WIDTH - resWidth)
          .attr('y', (row + 1.2) * DC_PLOT_ROW_HEIGHT)
          .attr('fill', '#000000')
      }
    }
  }
}

export default Plot;