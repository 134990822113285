import React, { Component } from 'react';
import Panel from '../../../../components/Panel';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button } from 'antd';

const REPLACE_PATHR = 0, ADD_PATHR = 1;
class ImportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { importSelectClick } = this.props;
    return (
      <Panel
        title="import"
        className="aurora-confirm-panel"
        zIndex={2000}
        position='panel-center'
        draggable={false}
        width={250}
        maxWidth={360}
        maxHeight={200}
      >
        <div className='aurora-confirm-main'>
          <div className='aurora-confirm-title'>
            <ExclamationCircleTwoTone className="cascade-confirm-Tips-icon" />
          </div>
          <div className='aurora-confirm-content import-panel-content'>
            Keep the existing setup?
          </div>
          <div className='aurora-confirm-button clear'>
            <Button
              className='aurora-confirm-ok-button'
              onClick={(e) => importSelectClick(e, ADD_PATHR)}
              style={{ width: '50%' }}
            >Add</Button>
            <Button
              className='aurora-confirm-cancel-button'
              onClick={(e) => importSelectClick(e, REPLACE_PATHR)}
            >Replace</Button>
          </div>
        </div>
      </Panel>
    );
  }
}

export default ImportPanel;