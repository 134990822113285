import {
  START_SIERRA_VERIFICATION,
  CHANGE_VERIFICATION_LIST,
  UPDATE_SIERRA_PROGRESS,
  CLEAN_SINGLE_PROGRESS,
  UPDATE_SINGLE_MONITOR,
  CLEAN_MONITOR,
  CHANGE_UPLOAD_MES,
  DEBUG_VERIFY,
  RESULT_EXIST,
  VERIFICATION_FLOW,
  SAVE_CURRENT_LOG,
  GET_CURRENT_LOG,
  WAITING_INDEX,
  UPDATE_ERROR_CHECK_LIST,
  UPDATE_PRE_PROCESS_LOG,
  GET_INTERFACE_MONITOR,
  CANCEL_VERIFICATION_WORKFLOW,
  GET_VERIFICATION_MONITOR,
  UPDATE_START_MSG,
  UPDATE_END_MSG,
  UPDATE_SIMULATION_MESSAGE,
  GET_CURRENT_PROFILE,
  SAVE_CURRENT_PROFILE,
  UPDATE_EXPERIMENT_QUEUE,
  GET_EXPERIMENT_LOG,
  SAVE_EXPERIMENT_QUEUE,
  CANCEL_EXPERIMENT,
  UPDATE_CONNECTOR_ERROR,
  RUN_MULTI_EXPERIMENT_SIM
} from './actionTypes';

export const startSierraVerification = (verificationIds, currentVerificationId, params = { updateConn: false, isMultiSim: false }) => ({
  type: START_SIERRA_VERIFICATION,
  verificationIds,
  currentVerificationId,
  updateConn: params.updateConn || false,
  isMultiSim: params.isMultiSim || false
});

export const changeVerificationList = (simulateKeys) => ({
  type: CHANGE_VERIFICATION_LIST,
  simulateKeys
});

export const updateProgress = ({ verificationId, progress }) => ({
  type: UPDATE_SIERRA_PROGRESS,
  verificationId,
  progress
});

export const cleanSingleProgress = (verificationId) => ({
  type: CLEAN_SINGLE_PROGRESS,
  verificationId,
});

export const updateSingleMonitor = ({ workflowId, monitor, verificationId }) => ({
  type: UPDATE_SINGLE_MONITOR,
  workflowId,
  monitor,
  verificationId
});

export const cleanMonitor = (verificationId) => ({
  type: CLEAN_MONITOR,
  verificationId,
});

export const changeUploadMes = (mes) => ({
  type: CHANGE_UPLOAD_MES,
  mes,
});

export const debugVerify = (step, verificationId) => ({
  type: DEBUG_VERIFY,
  step,
  verificationId
});

export const existResult = (existResult) => ({
  type: RESULT_EXIST,
  existResult
});

export const verificationFlow = ({ verificationId, interfaceStatus, verificationWork }) => ({
  type: VERIFICATION_FLOW,
  interfaceStatus,
  verificationId,
  verificationWork
});

export const updateCurrentLog = (log, verificationId) => ({
  type: SAVE_CURRENT_LOG,
  log,
  verificationId
});

export const getCurrentLog = (verificationId, verificationName) => ({
  type: GET_CURRENT_LOG,
  verificationId,
  verificationName
});

export const waitingIndexAction = (waitingQueue) => ({
  type: WAITING_INDEX,
  waitingQueue
});

export const updateErrorCheckList = (sierraInfoErrorCheck) => ({
  type: UPDATE_ERROR_CHECK_LIST,
  sierraInfoErrorCheck
});

export const updatePreProcessLog = (preProcessLog) => ({
  type: UPDATE_PRE_PROCESS_LOG,
  preProcessLog
});

export const getInterfaceMonitor = (verificationId) => ({
  type: GET_INTERFACE_MONITOR,
  verificationId
})

export const cancelVerificationWorkflow = (verificationId) => ({
  type: CANCEL_VERIFICATION_WORKFLOW,
  verificationId,
});

export const updateMonitor = (workflowId, verificationId) => ({
  type: GET_VERIFICATION_MONITOR,
  workflowId,
  verificationId
});

export const updateStartMsg = (startMsg) => ({
  type: UPDATE_START_MSG,
  startMsg
})

export const updateEndMsg = (endMsg) => ({
  type: UPDATE_END_MSG,
  endMsg
});

export const updateStimulationMessage = (verificationId) => ({
  type: UPDATE_SIMULATION_MESSAGE,
  verificationId
});

export const getCurrentProfile = (verificationId) => ({
  type: GET_CURRENT_PROFILE,
  verificationId
});

export const saveCurrentProfile = (profile) => ({
  type: SAVE_CURRENT_PROFILE,
  profile
})

export const updateRunSweeping = (forSim, clear = false, run = true) => ({
  type: UPDATE_EXPERIMENT_QUEUE,
  forSim,
  clear,
  run
})

export const saveExperimentRunning = (queue) => ({
  type: SAVE_EXPERIMENT_QUEUE,
  queue
})

export const getExperimentLog = (experimentId) => ({
  type: GET_EXPERIMENT_LOG,
  experimentId
})

export const cancelExperiment = (experimentId) => ({
  type: CANCEL_EXPERIMENT,
  experimentId
});

export const updateConnectorErrors = (connError) => ({
  type: UPDATE_CONNECTOR_ERROR,
  connError
})

export const runMultiExperimentSim = (experimentIds) => ({
  type: RUN_MULTI_EXPERIMENT_SIM,
  experimentIds
})
