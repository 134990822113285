import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DEFAULT_RESULT_CONFIG, SAVE_RESULT_CONFIG, GET_CUSTOMIZED_EYE_DIAGRAM } from './actionTypes';
import { updateResultConfig, updateEyeDiagramConfig, updateResultSignals } from './action';
import { updateChannelConfig, getInterfaceType, ConfigInfo } from '@/services/Rocky';
import { defaultChannelConfig } from '../project/action';
import { CustomizedEye, getDefaultResultConfig } from '@/services/Rocky/result';
import { PROJECT_V2 } from '../../../../constants/projectVersion';

function* setDefaultResultConfig() {
  //set default result config
  let { RockyReducer: { project: { currentChannelId, currentProjectId, projectList, projectVersion }, rocky: { currentConfig } } } = yield select();
  const currentProject = projectList.find(item => item.id === currentProjectId);
  let projectType = currentProject ? currentProject.type : "";
  let resultConfig = getDefaultResultConfig(projectType);
  yield put(updateResultConfig(resultConfig));
  if (!currentConfig || Object.keys(currentConfig).length === 0) {
    yield put(defaultChannelConfig(currentChannelId));
  } else {
    const _config = new ConfigInfo({
      timeStep: currentConfig.timeStep ? currentConfig.timeStep.value + currentConfig.timeStep.unit : null,
      cycles: currentConfig.cycles,
      clock: currentConfig.clock ? currentConfig.clock.value + currentConfig.clock.unit : null,
      simulate: currentConfig.simulate,
      ngspiceMDFLM: currentConfig.ngspiceMDFLM,
      caClock: currentConfig.caClock ? currentConfig.caClock.value + currentConfig.caClock.unit : null,
      caTimeStep: currentConfig.caTimeStep ? currentConfig.caTimeStep.value + currentConfig.caTimeStep.unit : null,
      cmd_2t_mode: currentConfig.cmd_2t_mode,
      hspiceCores: currentConfig.hspiceCores ? currentConfig.hspiceCores : 2,
      isSev: projectVersion === PROJECT_V2 ? true : false,
      hspiceLicenses: currentConfig.hspiceLicenses ? currentConfig.hspiceLicenses : 2
      /* xTalk: currentConfig.xTalk */
    });
    yield call(updateChannelConfig, { channelId: currentChannelId, config: _config, resultConfig });
  }
}

function* _saveRockyResultConfig() {
  let { RockyReducer: { project: { currentChannelId, projectVersion }, rocky: { currentConfig }, result: { resultConfig } } } = yield select();
  if (!currentConfig || Object.keys(currentConfig).length === 0) {
    yield put(defaultChannelConfig(currentChannelId));
  } else {
    const _config = {
      timeStep: currentConfig.timeStep.value + currentConfig.timeStep.unit,
      cycles: currentConfig.cycles,
      clock: currentConfig.clock.value + currentConfig.clock.unit,
      caClock: currentConfig.caClock ? currentConfig.caClock.value + currentConfig.caClock.unit : null,
      caTimeStep: currentConfig.caTimeStep ? currentConfig.caTimeStep.value + currentConfig.caTimeStep.unit : null,
      simulate: currentConfig.simulate,
      ngspiceMDFLM: currentConfig.ngspiceMDFLM,
      hspiceCores: currentConfig.hspiceCores ? currentConfig.hspiceCores : 2,
      isSev: projectVersion === PROJECT_V2 ? true : false,
      hspiceLicenses: currentConfig.hspiceLicenses ? currentConfig.hspiceLicenses : 2
      /* xTalk: currentConfig.xTalk */
    };
    yield call(updateChannelConfig, { channelId: currentChannelId, config: _config, resultConfig });
  }
}

const ICTypes = ['Memory'];
function* getCusEyeDiagram(action) {
  const { content, verificationId } = action;
  if (!content || !content.Interfaces || !content.Interfaces.length) {
    return;
  }

  const verificationType = getInterfaceType(content.Interfaces[0].Name);
  let signals = JSON.parse(JSON.stringify(content.Interfaces[0].Content.Signals));
  let componentNames = [];
  if (verificationType === 'CLK') {
    let components = content.Interfaces[0].Content.Components.filter(item => ICTypes.includes(item.type));
    const isGetActiveComp = components.find(item => { if (Object.keys(item).includes("active") && item.active) { return item } return false })
    if (isGetActiveComp) {
      components = components.filter(item => {
        // If there is an active field, filter if the active field is false
        if (item.active) { return item }
        return false
      })
    }
    componentNames = components.map(it => it.name);

    const dimmSignalTopology = content.Interfaces[0].Content.dimmSignalTopology;
    if (dimmSignalTopology && dimmSignalTopology.length) {
      let dimmComp = [];
      for (let dimmSignalInfo of dimmSignalTopology) {
        const { dimmMemoryPins } = dimmSignalInfo;
        const filterComp = dimmMemoryPins.filter(item => !dimmComp.includes(item.componentName) && item.active !== false).map(item => item.componentName)
        if (!filterComp || !filterComp.length) { continue }
        dimmComp = [...dimmComp, ...filterComp]
      }
      componentNames = [...dimmComp]
    }
  }
  const clkReg = /(CLK)|(DQS)|(RDQS)|(WCK)/ig;
  const signalNames = signals.filter(it => !it.name.match(clkReg)).map(it => it.name);
  const currentEyeConfig = yield call(getCustomEyeParamConfig, {
    verificationId,
    configParams: {
      signals: signalNames,
      localtion: "BGA",
      time_scale: "1e9",
      components: [...componentNames]
    }
  })

  if (currentEyeConfig) {
    const config = JSON.parse(JSON.stringify(currentEyeConfig));
    yield put(updateEyeDiagramConfig({ eyeDiagramConfig: config }));
  }
  yield put(updateResultSignals([...signalNames]));
}

function getCustomEyeParamConfig({ verificationId, configParams }) {
  return new Promise((resolve, reject) => {
    CustomizedEye.getCustomEyeParam({ verificationId, configParams }).then(res => {
      resolve(res);
    }, error => {
      resolve(null);
    })
  })
}

function* rockyResultSaga() {
  yield takeEvery(DEFAULT_RESULT_CONFIG, setDefaultResultConfig);
  yield takeEvery(SAVE_RESULT_CONFIG, _saveRockyResultConfig);
  yield takeEvery(GET_CUSTOMIZED_EYE_DIAGRAM, getCusEyeDiagram);
}

export default rockyResultSaga;