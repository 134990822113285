import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Input, Select, Spin } from 'antd';
import Panel from '@/components/Panel';
import { channelInputList } from '@/services/Andes_v2/constants';
import { PCIE } from '@/services/PCBHelper/constants';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import { getDefaultName } from '@/services/helper/setDefaultName';
import designConstructor from '@/services/helper/designConstructor';
import { DefaultChannelAdvanceConfig, getCreateChannelDefaultSignals, getAdvancedInputListByserdesType } from '@/services/Andes_v2/channel';
import { PRE_LAYOUT } from '@/constants/designVendor';
import { getLayoutComponents, RLC_TYPES } from '@/services/PCBHelper';
import DesignInfo from '@/services/Andes_v2/pcbInfo';
import { getLayoutDBInfo } from '@/services/PCBHelper/getLayoutDB';
import DelConfirm from '@/components/DelConfirm';
import { ANDES_V2_CHANNEL_VERSION } from '@/version';
import debounce from '@/services/helper/debounceFn';
import CreateChannelAdvanced from '@/components/createChannelContent/createAdvanced';
import SelectSignalNets from './selectSignalNets';
import { PACKAGE } from '../../../../constants/designType';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import CMCComponentTable from './CMCComponentTable';
import { createChannelContent } from '../../../../services/Andes_v2/channel';
import './index.css';

const NAME = "name";
const Option = Select.Option;
const confirmErrorMsg = "Component is missing. Channel will not be created."
const confirmPackageErrorMsg = "PCB data has not been loaded. Channel will not be created."
class CreateChannelPanel extends Component {

  constructor(props) {
    super(props);
    const data = props.data;
    const design = designConstructor.getDesign(data.id);
    this.designType = design.type;
    this.state = {
      name: null,
      serdesType: PCIE,
      errorMsg: null,
      vendor: null,
      component: null,
      loading: false,
      confirmVisible: false,
      advancedConfig: new DefaultChannelAdvanceConfig({ type: PCIE, designType: this.designType }),
      selectedSignals: [],
      signals: [],
      maxHeight: 450,
      maxWidth: 750,
      createSelectVisible: false
    }
    this.componentList = [];
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 450),
      maxWidth: getPanelMaxWidth(offset, 750)
    })
  }

  closeModal = () => {
    debounce(() => {
      this.createChannel();
    }, 1000, true, 'createChannel')();
  }

  createChannel = async () => {
    const { data: { id } } = this.props;//id -> designId
    const { errorMsg, name, serdesType, component, advancedConfig, loading, signals, selectedSignals, cmcComponents } = this.state;
    if (loading === "save" || errorMsg || this.checkValue(name, NAME)) {
      return;
    }
    if (this.designType === PACKAGE && loading === "pcb") {
      this.setState({
        confirmVisible: true
      });
      return;
    } else {
      this.setState({ loading: "save" })
      const _signals = signals.filter(item => selectedSignals.includes(item.name));
      const vendor = designConstructor.getDesign(id).vendor;

      const data = {
        id: null,
        name,
        type: serdesType,
        component,
        version: ANDES_V2_CHANNEL_VERSION,
        designVersion: designConstructor.getDesign(id).designVersion,
        designVendor: vendor
      }
      let content = {}, generationPortErrors = null, generationPortWarnings = null, netInfo = null, isExistPorts = true;
      if (vendor !== PRE_LAYOUT) {
        const info = await createChannelContent({ data, designId: id, advancedConfig, designType: this.designType, signals: _signals, cmcComponents })
        content = info.content;
        generationPortErrors = info.generationPortErrors;
        generationPortWarnings = info.generationPortWarnings;
        netInfo = info.netInfo;
        isExistPorts = info.isExistPorts;
      }
      this.createChannelInfo = {
        data,
        save: true,
        content,
        generationPortErrors,
        generationPortWarnings,
        netInfo
      }
      if (!isExistPorts && _signals.length > 0) {
        this.setState({
          loading: false,
          createSelectVisible: true
        });
        return;
      }
    }

    this.setState({
      loading: "save"
    }, () => {
      setTimeout(() => {
        this.props.closePanel(this.createChannelInfo);
      }, 50)
    })
  }

  componentDidMount = () => {
    //set default channel name
    this.setDefaultName();
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentDidUpdate = (prevProps) => {
    const { data: { id }, openProjectId } = this.props;//id -> designId
    const { data: { id: prevDesignId } } = prevProps;
    if (prevDesignId !== id) {
      //set default channel name
      this.setDefaultName();
    }

    //switch project, close panel
    if (openProjectId !== prevProps.openProjectId) {
      this.props.closePanel({ save: false });
    }
  }

  setDefaultName = () => {
    const { channelList, data: { id } } = this.props;
    const design = designConstructor.getDesign(id) || {};
    const vendor = design.vendor;
    const name = getDefaultName({ nameList: channelList, defaultKey: "Channel" });
    this.setState({
      name,
      vendor,
      serdesType: vendor === PRE_LAYOUT ? design.andesType : PCIE
    });
    if (vendor !== PRE_LAYOUT) {
      this.loadPCB(id);
    }
  }

  loadPCB = (id) => {
    this.setState({
      loading: "pcb"
    });
    getLayoutDBInfo(id).then(pcbInfo => {
      if (pcbInfo) {
        DesignInfo.savePCBInfo(id, pcbInfo);
        const compList = getLayoutComponents({ layers: pcbInfo.layers });
        let list = Object.values(compList);
        list = list.filter(item => item.part);
        // const reg = new RegExp("(^U)|(^J)|(^K)", "ig");
        const filterList = list.filter(item => !RLC_TYPES.includes(item.type)/*  && item.name.match(reg) */);
        const _filterList = filterList.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        this.componentList = _filterList;

        if (_filterList.length === 1) {
          this.setState({
            component: _filterList[0],
            loading: false
          }, () => {
            this.getDefaultSignals()
          })
        } else {
          this.setState({
            loading: false
          }, () => {
            this.getDefaultSignals()
          });
        }
      } else {
        this.componentList = [];
        this.setState({
          loading: false
        });
      }
    }, error => {
      console.error(error);
      this.componentList = [];
      this.setState({
        loading: false
      });
    })
  }

  getDefaultSignals = (type, config) => {
    const { advancedConfig, component, serdesType, cmcComponents: updatedCMCComponents } = this.state;
    const _serdesType = type || serdesType, _advancedConfig = config || advancedConfig;
    const { data: { id } } = this.props; //designId
    const { netsList, layers } = DesignInfo.getPCBInfo(id);
    const { signals, cmcComponents } = getCreateChannelDefaultSignals({
      component,
      designId: id,
      netsList,
      layers,
      type: _serdesType,
      advancedConfig: _advancedConfig,
      designType: this.designType,
      updatedCMCComponents
    })

    this.setState({
      signals,
      selectedSignals: signals.map(it => it.name),
      cmcComponents: JSON.parse(JSON.stringify(cmcComponents))
    })
  }

  inputChange = (e, type) => {
    const { errorMsg } = this.state;
    this.setState({
      [type]: e.target.value,
      errorMsg: errorMsg && errorMsg.type === type ? null : errorMsg
    });
  }

  selectChange = (key, type) => {
    let advancedConfig = { ...this.state.advancedConfig };
    if (type === "serdesType") {
      advancedConfig = new DefaultChannelAdvanceConfig({ type: key, designType: this.designType });
    }
    this.getDefaultSignals(key, advancedConfig);
    this.setState({
      [type]: key,
      advancedConfig
    });
  }

  selectComponent = (key) => {
    const component = this.componentList.find(item => item.name === key);
    const { errorMsg } = this.state;
    this.setState({
      component,
      errorMsg: errorMsg && errorMsg.type === "component" ? null : errorMsg
    }, () => {
      this.getDefaultSignals()
    })
  }

  checkData = (e, type) => {
    this.checkValue(e.target.value, type);
  }

  checkValue = (value, type) => {
    const { channelList } = this.props;
    let error = checkNameFormat(value);
    const findChannel = channelList.find(item => item.name === value);
    if (findChannel) {
      error = `${value} already exists!`;
    }

    this.setState({
      errorMsg: error ? { type, msg: error } : this.state.errorMsg
    });
    return error;
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  updateAdvancedConfig = (advancedConfig) => {
    this.setState({
      advancedConfig
    }, () => {
      this.getDefaultSignals()
    })
  }

  updateSignals = (signals) => {
    this.setState({
      signals
    })
  }

  updateSelectedSignals = (selectedSignals) => {
    this.setState({
      selectedSignals
    })
  }

  updateCMCComponents = (cmcComponents) => {
    this.setState({
      cmcComponents
    }, () => {
      this.getDefaultSignals()
    })
  }

  render = () => {
    const config = this.state;
    const { errorMsg, component, vendor, loading, advancedConfig, serdesType,
      confirmVisible, signals, selectedSignals, maxWidth, maxHeight, cmcComponents, createSelectVisible } = config;
    const content = (
      <Panel
        className='andes-create-channel-panel aurora-create-panel'
        position='panel-center-left'
        title={this.designType !== PACKAGE ? "PCB Channel" : "Package Channel"}
        zIndex={2000}
        onCancel={() => { this.closeModal() }}
        draggable
        width={getPanelWidth(maxWidth, { defaultWidth: 750 })}
        maxHeight={maxHeight}
        minWidth={400}
        overflow={"auto"}
      >
        <div className='create-channel-content'>
          <Spin spinning={loading ? true : false} tip={loading === "pcb" ? 'Loading...' : "Saving..."}>
            {channelInputList.map(item =>
              <div key={item.key} className="channel-input-item">
                <label className='channel-edit-input-label'>{item.title}</label>
                {item.key === NAME ? <Input
                  className='aurora-input channel-input'
                  value={config[item.key]}
                  placeholder={item.title}
                  onChange={(e) => this.inputChange(e, item.key)}
                  onKeyDown={(e) => this.keyDown(e)}
                  onBlur={(e) => this.checkData(e, item.key)}
                /> :
                  <Select
                    value={config[item.key]}
                    onChange={(key) => this.selectChange(key, item.key)}
                    placeholder={"Serdes Type"}
                    className='aurora-select channel-select'
                    popupClassName='aurora-select-dropdown'
                    disabled={vendor === PRE_LAYOUT}
                  >
                    {item.list.map(it =>
                      <Option key={it.key} disabled={it.disabled || false}>{it.title}</Option>
                    )}
                  </Select>
                }
              </div>)}
            {vendor !== PRE_LAYOUT ? <Fragment>
              {/* component select */}
              {this.designType !== PACKAGE && <Fragment><div className="channel-input-item">
                <label className='channel-edit-input-label'>Component</label>
                <Select
                  value={component ? component.name : ""}
                  showSearch
                  allowClear
                  onChange={(key) => this.selectComponent(key)}
                  placeholder={"Component"}
                  className='aurora-select channel-select'
                  popupClassName='aurora-select-dropdown'
                >
                  {this.componentList.map(item =>
                    <Option key={item.name}>{item.name}</Option>
                  )}
                </Select>
              </div>
                <CreateChannelAdvanced
                  serdesType={serdesType}
                  designType={this.designType}
                  advancedConfig={advancedConfig}
                  updateAdvancedConfig={this.updateAdvancedConfig}
                  advancedInputList={getAdvancedInputListByserdesType(serdesType)}
                />
              </Fragment>}
              {cmcComponents && cmcComponents.length ?
                <CMCComponentTable
                  cmcComponents={cmcComponents}
                  _updateCMCComponents={this.updateCMCComponents}
                />
                : null}
              {this.designType === PACKAGE || signals.length ?
                <SelectSignalNets
                  signals={signals}
                  selectedSignals={selectedSignals}
                  serdesType={serdesType}
                  designType={this.designType}
                  advancedConfig={advancedConfig}
                  updateAdvancedConfig={this.updateAdvancedConfig}
                  updateSignals={this.updateSignals}
                  updateSelectedSignals={this.updateSelectedSignals}
                />
                : null}
            </Fragment> : null}
            {errorMsg && errorMsg.msg ?
              <div className="channel-input-item">
                <span className='aurora-model-name-error-msg'>{errorMsg.msg}</span>
              </div> : null
            }
          </Spin>
        </div>
      </Panel>
    )
    return <Fragment>
      {createPortal(content, this.dialogRoot)}
      {confirmVisible ? <DelConfirm
        type="close"
        closePanel={this.close}
        maskStyle={true}
        onFix={this.onFix}
        message={this.designType === PACKAGE ? confirmPackageErrorMsg : confirmErrorMsg}
      /> : null}
      {createSelectVisible ? <DelConfirm
        type="close"
        onFix={() => this.props.closePanel(this.createChannelInfo)}
        closePanel={this.close}
        confirmButton="Create"
        message="All signal pins could not find any reference ports. Do you want to create the channel?"
      /> : null}
    </Fragment>
  }

  close = () => {
    this.props.closePanel({ save: false });
  }

  onFix = () => {
    this.setState({
      confirmVisible: false,
      loading: false
    })
  }
}


export default CreateChannelPanel;