function getRepeaterPinList({ pins, pairs, compName }) {
  let newPins = [], pinList = [];
  const signals = [...new Set([...pins.map(item => item.signal)])];
  signals.forEach(signalName => {
    const sameSignalPins = pins.filter(item => item.signal === signalName);
    const nets = [...new Set([...sameSignalPins.map(item => item.net)])];
    nets.forEach(net => {
      const sameSignalNetPins = sameSignalPins.filter(item => item.net === net);
      newPins.push({
        signal: signalName,
        net,
        pins: sameSignalNetPins
      })
    })
  });
  newPins.forEach(pinItem => {
    const leftPins = pinItem.pins.filter((item, index) => index % 2 === 0);
    const rightPins = pinItem.pins.filter((item, index) => index % 2 !== 0);
    leftPins.forEach((item, index) => {
      if (item.pin) {
        const leftPin = pairs.find(it => it.pin === item.pin) || {};
        let pinL = {
          pinL: item.pin,
          pinLValue: leftPin ? leftPin.node : "",
          pinLLibraryId: leftPin.libraryId ? leftPin.libraryId : "",
          pinLFileName: leftPin.fileName ? leftPin.fileName : "",
          pinLSubckt: leftPin.subckt ? leftPin.subckt : "",
          pinLModelKey: leftPin.modelKey ? leftPin.modelKey : ""
        }
        //find right pin
        const rightPin = rightPins[index] ? rightPins[index] : null;
        let pinR = null;
        if (rightPin) {
          const _pinR = pairs.find(it => it.pin === rightPin.pin) || {};
          pinR = {
            pinR: rightPin.pin,
            pinRValue: _pinR ? _pinR.node : "",
            pinRLibraryId: _pinR.libraryId ? _pinR.libraryId : "",
            pinRFileName: _pinR.fileName ? _pinR.fileName : "",
            pinRSubckt: _pinR.subckt ? _pinR.subckt : "",
            pinRModelKey: _pinR.modelKey ? _pinR.modelKey : ""
          }
        }

        const _Pin = pinR ? {
          ...pinL,
          component: compName,
          netDisplay: pinItem.net,
          signalDisplay: pinItem.signal,
          ...pinR,
        } : {
          ...pinL,
          component: compName,
          netDisplay: pinItem.net,
          signalDisplay: pinItem.signal
        };
        pinList.push(_Pin);
      }
    });
  })
  return pinList;
}

function getBufferPinList({ usage, pins, pairs, componentName }) {
  let pinList = [];
  pins.forEach(item => {
    if (item.pin) {
      const inName = `${item.pin}_in`, outName = `${item.pin}_u`;
      const pinIn = pairs.find(it => it.pin === inName);
      const pinOut = pairs.find(it => it.pin === outName);
      if (usage === 'Driver') {
        pinList.push({
          pinL: inName,
          pinLDisplay: 'in',
          pinLNodeDisplay: inName,
          pinLValue: pinIn && pinIn.node ? pinIn.node : "",
          pinLLibraryId: pinIn && pinIn.libraryId ? pinIn.libraryId : "",
          pinLFileName: pinIn && pinIn.fileName ? pinIn.fileName : "",
          pinLSubckt: pinIn && pinIn.subckt ? pinIn.subckt : "",

          pinR: outName,
          pinRDisplay: `out ${item.pin}`,/*  `${item.pin}_out` */
          pinRNodeDisplay: `${item.pin}_out`,
          pinRValue: pinOut && pinOut.node ? pinOut.node : "",
          pinRLibraryId: pinOut && pinOut.libraryId ? pinOut.libraryId : "",
          pinRFileName: pinOut && pinOut.fileName ? pinOut.fileName : "",
          pinRSubckt: pinOut && pinOut.subckt ? pinOut.subckt : "",

          net: item.net,
          signal: item.signal,
          component: componentName,
          netDisplay: item.net,
          signalDisplay: item.signal,
          compPin: item.pin
        });
      } else if (usage === 'Receiver') {
        pinList.push({
          pinL: outName,
          pinLDisplay: `in ${item.pin}`,
          pinLNodeDisplay: `${item.pin}_in`,
          pinLValue: pinOut && pinOut.node ? pinOut.node : "",
          pinLLibraryId: pinOut && pinOut.libraryId ? pinOut.libraryId : "",
          pinLFileName: pinOut && pinOut.fileName ? pinOut.fileName : "",
          pinLSubckt: pinOut && pinOut.subckt ? pinOut.subckt : "",

          net: item.net,
          signal: item.signal,
          component: componentName,
          netDisplay: item.net,
          signalDisplay: item.signal,
        });
      }
    }
  });
  return pinList;
}

function getStimulusPinList({ pins, pairs, componentName }) {
  let pinList = [];
  pins.forEach(item => {
    if (item.pin) {
      const inName = `${item.pin}_in`;
      const pinIn = pairs.find(it => it.pin === inName);
      if (item.usage === 'Driver') {
        pinList.push({
          pinL: item.pin,
          pinR: inName,
          pinRValue: pinIn && pinIn.node ? pinIn.node : "",
          pinRLibraryId: pinIn && pinIn.libraryId ? pinIn.libraryId : "",
          pinRFileName: pinIn && pinIn.fileName ? pinIn.fileName : "",
          pinRSubckt: pinIn && pinIn.subckt ? pinIn.subckt : "",

          net: item.net,
          signal: item.signal,
          component: componentName,
          netDisplay: item.net,
          signalDisplay: item.signal,
          displayType: "Stimulus"
        });
      }
    }
  });
  return pinList;
}

export {
  getRepeaterPinList,
  getBufferPinList,
  getStimulusPinList
}