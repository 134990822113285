import {
  UPDATE_PAGE_TYPE, UPDATE_CONTENT_LOADING,
  UPDATE_CHANNEL_CONTENT, UPDATE_GET_FILES_STATUS,
  UPDATE_BMA_CONTENT_STATUS, CLEAR_ALL_BMA_INFO,
  UPDATE_DATA_MENU_KEY
} from './actionType';
import { DATA_MANAGEMENT } from '@/services/Rocky/helper/topMenuHelper';
import { DATA_CONTENT } from '../../../../services/BMA/helper/result';

const defaultState = {
  projectId: "",
  id: "",
  pageType: DATA_MANAGEMENT,
  fileList: [],
  loading: false,
  fileListStatus: false,
  specStatus: false,
  dataMenuKey: DATA_CONTENT
};

export const BMAChannelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PAGE_TYPE:
      return {
        ...state,
        pageType: action.pageType
      }
    case UPDATE_CHANNEL_CONTENT:
      return {
        ...state,
        ...action.info
      }
    case UPDATE_CONTENT_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case UPDATE_GET_FILES_STATUS:
      return {
        ...state,
        fileListStatus: action.status
      }
    case UPDATE_BMA_CONTENT_STATUS:
      return {
        ...state,
        [action.key]: action.status
      }
    case UPDATE_DATA_MENU_KEY:
      return {
        ...state,
        dataMenuKey: action.key
      }
    case CLEAR_ALL_BMA_INFO: {
      return {
        ...defaultState
      }
    }
    default: return state
  }
};