import { BGA, DIE, DIODE } from "../../../constants/componentType";
import { getNetType } from "../../helper/connectorHelper";
import {
  CAP,
  CMC,
  CONNECTOR,
  getComponentByName,
  getConnectedPinNumber,
  IC, IGNORE, IND,
  RES,
  RLC_TYPES,
  SERDES_TYPES,
  getConnectedNetByCompPin,
  isPowerGND
} from "../../PCBHelper";
import RelateNetsGroup from "../../PCBHelper/relateNets";

class ChannelIdentify {
  constructor() {
    this.signals = [];
    this.components = [];
    this.designId = null;
    this.cmcComponents = [];
    this.filterComponents = [];
    this.modifiedComponents = [];
    this.SELECT_COMP = null;
    this.newCMCSignals = [];

  }

  getDefaultSignals({ signals, components, designId, netsList, modifiedComponents, serdesType }) {
    const compPins = this.getComponentPins(components);
    this.SELECT_COMP = components.find(item => !!item.SELECT_COMP && SERDES_TYPES.includes(item.type));
    if (!this.SELECT_COMP) {
      this.SELECT_COMP = components.find(item => SERDES_TYPES.includes(item.type));
    }
    for (let signal of signals) {
      this.signals.push({
        ...JSON.parse(JSON.stringify(signal)),
        pins: compPins.filter(item => item.signal === signal.name)
      })
    }
    this.prevSignals = JSON.parse(JSON.stringify(this.signals));
    this.components = JSON.parse(JSON.stringify(components));
    this.modifiedComponents = JSON.parse(JSON.stringify(modifiedComponents));
    this.cmcComponents = JSON.parse(JSON.stringify(components.filter(item => item.type === CMC)));
    this.filterComponents = JSON.parse(JSON.stringify(components.filter(item => ![CMC, ...RLC_TYPES].includes(item.type))));
    this.designId = designId;
    this.netsList = netsList;
    this.allCompsPins = JSON.parse(JSON.stringify(compPins));
    this.serdesType = serdesType;
    //init related nets group
    this.relatedGroups = new RelateNetsGroup();
  }

  getComponentPins = (components) => {
    let compsPins = [];
    for (let comp of components) {
      for (let pin of comp.pins) {
        if (!pin.signal) {
          continue;
        }
        compsPins.push({ ...pin, compName: comp.name, type: comp.type, pinsConnection: comp.pinsConnection })
      }
    }
    return compsPins;
  }

  updateSignalsByComp = () => {
    for (let comp of this.modifiedComponents) {
      switch (comp.type) {
        case CMC:
          this.updateSignalByCMCPins({ comp });
          /* this.updateSignalsByCMCPins({ comp }) */
          break;
        case IC:
        case CONNECTOR:
        case BGA:
        case DIE:
        case IGNORE:
          this.updateSignalByICPins({ comp });
          break;
        case RES:
        case IND:
        case CAP:
          this.updateSignalByRLCPins({ comp });
          break;
        default: break;
      }
    }
    return this.signals;
  }

  updateSignalByICPins = ({ comp }) => {
    for (let pin of comp.pins || []) {
      this.ICDeletedNets = [];
      const signalIndex = this.signals.findIndex(item => item.name === pin.signal);
      if (signalIndex < 0) {
        continue;
      }
      const netType = getNetType(this.signals[signalIndex], pin.net);
      this.findTargetCompAndPin({ pinInfo: { ...pin, compName: comp.name } });
      this.signals[signalIndex] = this.updateSignalInfo(this.signals[signalIndex], "deletedNets", this.ICDeletedNets);
      this.signals[signalIndex][netType] = this.signals[signalIndex][netType].filter(item => !this.ICDeletedNets.includes(item));
    }
  }

  updateSignalByCMCPins = ({ comp }) => {
    for (let pinGroup of comp.pinsConnection || []) {
      const findPin = comp.pins.find(item => item.pin === pinGroup.pin || pinGroup.pinMap === item.pin);
      if (!findPin) {
        continue;
      }
      const signalIndex = this.signals.findIndex(item => item.name === findPin.signal);
      if (signalIndex < 0) {
        continue;
      }
      const connPin = comp.pins.find(item => item.pin === pinGroup.pin) || findPin;

      const netType = getNetType(this.signals[signalIndex], connPin.net);
      //find connected pin number
      const connectedPinNum = this.getConnectedPinNumberByGroup(findPin.pin, pinGroup);
      //get connected nets of pin number

      if (!this.relatedGroups.compPinsNets || !Object.keys(this.relatedGroups.compPinsNets).length) {
        this.relatedGroups.setCompPinsNets(this.designId);
      }

      const netInfo = this.relatedGroups.compPinsNets[comp.name] ? this.relatedGroups.compPinsNets[comp.name][connectedPinNum.toString()] : null;
      if (!netInfo || isPowerGND(netInfo, null, this.designId)) {
        return;
      }

      let { relatedNets, cmcComponents: cmcComps } = this.relatedGroups.getRelatedNetsByNet({
        net: netInfo,
        designId: this.designId,
        setupComps: this.components,
        pinMap: comp.pinsConnection,
        type: this.serdesType
      })

      this.updateSignalNets({
        cmcComps,
        relatedNets,
        signalIndex,
        netInfo,
        netType
      });
    }
  }

  updateSignalsByCMCPins = ({ comp }) => {
    let signal = {};
    for (let i = 0; i < (comp.pinsConnection || []).length; i++) {
      const pinGroup = comp.pinsConnection[i];
      //1 <-> 4
      //2 <-> 3
      //connPin:1,connectedPinNum:4
      //connPin:2,connectedPinNum:3
      const connPin = comp.pins.find(item => item.pin === pinGroup.pin || pinGroup.pinMap === item.pin);
      if (!connPin) {
        continue;
      }

      const signalIndex = this.signals.findIndex(item => item.name === connPin.signal);
      if (signalIndex < 0) {
        continue;
      }

      const netType = getNetType(this.signals[signalIndex], connPin.net);
      //find connected pin number
      const connectedPinNum = this.getConnectedPinNumberByGroup(connPin.pin, pinGroup);
      //get connected nets of pin number

      if (!this.relatedGroups.compPinsNets || !Object.keys(this.relatedGroups.compPinsNets).length) {
        this.relatedGroups.setCompPinsNets(this.designId);
      }

      const netInfo = this.relatedGroups.compPinsNets[comp.name] ? this.relatedGroups.compPinsNets[comp.name][connectedPinNum.toString()] : null;
      if (!netInfo || isPowerGND(netInfo, null, this.designId)) {
        return;
      }

      let { relatedNets, cmcComponents: cmcComps } = this.relatedGroups.getRelatedNetsByNet({
        net: netInfo,
        designId: this.designId,
        setupComps: this.components,
        pinMap: comp.pinsConnection,
        type: this.serdesType
      })

      signal[`group${i}`] = {
        nets: [...relatedNets],
        cmcComps,
        signalIndex,
        netInfo,
        netType
      }
    }
    this.newCMCSignals.push(signal);
  }

  updateSignalNets = ({
    relatedNets,
    signalIndex,
    netInfo,
    netType,
    cmcComps
  }) => {

    if (!this.signals[signalIndex][netType]) {
      return;
    }
    let newNets = [];
    let _relatedNets = relatedNets ? relatedNets : [];
    //filter related nets by exist signal nets
    const filterNets = _relatedNets.filter(item => !this.signals[signalIndex][netType].includes(item));
    //get delete nets
    let _delNets = this.signals[signalIndex][netType].filter(item => !_relatedNets.includes(item) && item !== netInfo.mName);

    this.signals[signalIndex][netType] = this.signals[signalIndex][netType].filter(item => !_delNets.includes(item));

    newNets = [netInfo.mName, ...filterNets].filter(item => !this.signals[signalIndex][netType].includes(item));
    this.signals[signalIndex][netType].push(...newNets);

    const _netType = netType === "nets_P" ? "nets_N" : "nets_P";
    _delNets = _delNets.filter(item => !this.signals[signalIndex][_netType].includes(item));
    this.signals[signalIndex] = this.updateSignalInfo(this.signals[signalIndex], "newNets", newNets);
    this.signals[signalIndex] = this.updateSignalInfo(this.signals[signalIndex], "deletedNets", _delNets);
    this.signals[signalIndex] = this.updateSignalInfo(this.signals[signalIndex], "cmcComponents", cmcComps);
  }

  updateSignalByRLCPins = ({ comp }) => {
    const findComp = getComponentByName(this.designId, comp.name);
    if (!findComp) {
      return;
    }
    const pinList = findComp.mPart.mPinList || [];
    for (let pin of comp.pins || []) {

      const findPin = pinList.find(item => pin.pin !== item.mNumber);
      if (!findPin) {
        return;
      }
      const signalIndex = this.signals.findIndex(item => item.name === pin.signal);
      if (signalIndex < 0) {
        continue;
      }
      const netType = getNetType(this.signals[signalIndex], pin.net);

      if (!this.relatedGroups.compPinsNets || !Object.keys(this.relatedGroups.compPinsNets).length) {
        this.relatedGroups.setCompPinsNets(this.designId);
      }
      const netInfo = this.relatedGroups.compPinsNets[comp.name] ? this.relatedGroups.compPinsNets[comp.name][findPin.mNumber] : null;
      if (!netInfo || isPowerGND(netInfo, null, this.designId)) {
        return;
      }

      let { relatedNets, cmcComponents: cmcComps } = this.relatedGroups.getRelatedNetsByNet({
        net: netInfo,
        designId: this.designId,
        setupComps: this.components,
        type: this.serdesType
      })

      this.updateSignalNets({
        cmcComps,
        relatedNets,
        signalIndex,
        netInfo,
        netType
      })
    }
  }

  updateSignalInfo = (signal, key, nets = []) => {
    if (!signal[key]) {
      signal[key] = [...nets]
    } else {
      signal[key].push(...nets);
    }
    return signal;
  }

  getConnectedPinNumberByGroup = (pin, pinGroup) => {
    if (pinGroup.pin === pin) {
      return pinGroup.pinMap;
    }

    if (pinGroup.pinMap === pin) {
      return pinGroup.pin;
    }
  }

  findTargetCompAndPin({ pinInfo, prevNets = [] }) {
    const findNetConnectedPins = this.allCompsPins.filter(item =>
      item.compName !== pinInfo.compName &&
      item.net === pinInfo.net && item.signal === pinInfo.signal)
    const filterPins = findNetConnectedPins.filter(item => [...SERDES_TYPES, IGNORE].includes(item.type));
    const filterConnPins = findNetConnectedPins.filter(item => [...RLC_TYPES, CMC].includes(item.type));

    if (
      filterPins.find(item => item.compName === this.SELECT_COMP.name)
    ) {
      return;
    } else if (filterPins.length && !filterConnPins.length) {
      this.ICDeletedNets.push(...prevNets, pinInfo.net);
      return;
    } else {
      for (let pin of filterConnPins) {
        if (pin.type === CMC) {
          const connectionNum = getConnectedPinNumber({
            pinNum: pin.pin,
            pinMap: pin.pinsConnection,
            pinLength: 4
          });

          const findPin = this.allCompsPins.find(item => item.compName === pin.compName && Number(item.pin) === connectionNum)
          if (findPin) {
            this.findTargetCompAndPin({
              pinInfo: findPin,
              prevNets: prevNets ? [...prevNets, pinInfo.net] : [pinInfo.net]
            })
          }
        } else if (RLC_TYPES.includes(pin.type)) {
          const findPin = this.allCompsPins.find(item => item.compName === pin.compName && item.signal === pin.signal && item.pin !== pinInfo.pin)
          if (findPin) {
            this.findTargetCompAndPin({
              pinInfo: findPin,
              prevNets: prevNets ? [...prevNets, pinInfo.net] : [pinInfo.net]
            })
          }
        }
      }
    }
  }
}

function judgeReIdentify(prevCompType, compType) {

  if (getCompType(prevCompType) !== getCompType(compType)) {
    return true;
  }
}

function getCompType(compType) {
  switch (compType) {
    case CMC:
      return CMC;
    case IC:
    case CONNECTOR:
    case IGNORE:
    case BGA:
    case DIE:
    case DIODE:
      return IC;
    case RES:
    case IND:
    case CAP:
      return "RLC";
    default: return null;
  }
}

function getCMCPinsOptions({ record, type, allCompPins, anotherPins, cmcComp, pcbNetsList }) {
  const pinInfo = record[`${type}Info`];
  const compName = cmcComp.comps && cmcComp.comps[0] ? cmcComp.comps[0].name : null;
  //Find the pins on the same side by pin name
  const findIN = pinInfo.mName.match(/(IN)|(INPUT)/ig);
  if (findIN) {
    return allCompPins.filter(item => item.mName.match(/(IN)|(INPUT)/ig)).map(item => item.mNumber);
  }
  const findOUT = pinInfo.mName.match(/(OUT)|(OUTPUT)/ig);
  if (findOUT) {
    return allCompPins.filter(item => item.mName.match(/(OUT)|(OUTPUT)/ig)).map(item => item.mNumber);
  }

  //Find the pins on the same side by connected net and comp
  const netInfo = getConnectedNetByCompPin(pcbNetsList, compName, record[type]);

  const mCompList = netInfo && netInfo.mPinList ? netInfo.mPinList.filter(it => it.mCompName !== compName).map(item => item.mCompName) : [];
  let options = [];
  for (let item of allCompPins) {
    const _netInfo = getConnectedNetByCompPin(pcbNetsList, compName, item.mNumber);
    const itemCompList = _netInfo && _netInfo.mPinList ?
      _netInfo.mPinList.filter(it => it.mCompName !== compName).map(item => item.mCompName) : [];
    if (itemCompList.filter(it => mCompList.includes(it)).length) {
      options.push(item.mNumber)
    }
  }
  return options.filter(item => !anotherPins.includes(item));
}

export default ChannelIdentify;
export {
  judgeReIdentify,
  getCMCPinsOptions
}