import http from "../http";

export function notificationUserEdit({ ccList, notification, receiveEmail, userEmail, userId, userName, products }) {
  return http.post(`/notification/user/edit`, {
    ccList,
    notification,
    receiveEmail,
    userEmail,
    userId,
    userName,
    products
  })
}

/*   user notification detail */
export function getUserNotificationDetail() {
  return http.get(`/notification/user/detail`);
}

export function getSenderEmail() {
  return http.get(`/notification/sender/default`);
}

export function editSenderEmail({ id, name, email }) {
  return http.post(`/notification/sender/edit`, {
    id,
    name,
    email
  });
}

export function getNotificationProducts() {
  return http.get(`/notification/user/products`);
}