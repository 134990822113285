import { CHANNEL, PDN } from '../../../constants/treeConstants';

export function applyPowerComponentsHelper(action) {
  const { powerDomains, PowerComponents, currentType } = action;
  const partMap = new Map();
  if (currentType === CHANNEL) {
    PowerComponents.forEach(item => {
      if (item) {
        partMap.set(item.name, item);
      }
    })
    powerDomains.forEach(item => {
      item.Components.forEach(comp => {
        if (partMap.has(comp.name)) {
          const newComp = { ...comp, ...partMap.get(comp.name) };
          Object.assign(comp, newComp);
        }
      })
    })
  } else if (currentType === PDN) {
    powerDomains.forEach(item => {
      if (item.model) {
        const _model = { ...item.model };
        _model.libType = _model.libraryType ? _model.libraryType : _model.libType ? _model.libType : '';
        _model.libraryType = _model.libraryType ? _model.libraryType : _model.libType ? _model.libType : '';
        _model.subckt = _model.subcktName ? _model.subcktName : _model.subckt ? _model.subckt : '';
        _model.subcktName = _model.subcktName ? _model.subcktName : _model.subckt ? _model.subckt : '';
        _model.libraryId = _model.libraryId ? _model.libraryId : _model.id ? _model.id : '';
        _model.fileName = _model.fileName ? _model.fileName : _model.name ? _model.name : '';
        Object.assign(item.model, _model);
      }
      if (item) {
        partMap.set(item.name, item);
      }
    })
    PowerComponents.forEach(comp => {
      if (partMap.has(comp.name)) {
        const newComp = { ...comp, ...partMap.get(comp.name) };
        Object.assign(comp, newComp);
      }
    })
  }
  return { powerDomains, PowerComponents }
}