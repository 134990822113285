//default role
export const
  ROOT = 'ROOT',
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR';

//permissions
export const
  ROCKY = 'ROCKY',
  ROCKYSSN = 'ROCKYSSN',
  SIERRA = 'SIERRA',
  CCC = 'CCC',
  FASTPI = 'FASTPI',
  ANDES = 'ANDES_V1',
  HSPICE = 'HSPICE',
  SIWAVE = 'SIWAVE',
  HFSS = 'HFSS',
  XANDES = 'XANDES',
  ANDES_V2 = 'ANDES',
  ROCKY_SERVICE_OPTION = 'ROCKY_SERVICE_OPTION',
  CASCADE = 'CASCADE',
  AOI = 'AOI_CDS', // 2023/08/16 AOI --> AOI_CDS
  HIMALAYAS = "HIMALAYAS";