import { SUCCESS } from "../../../constants/returnCode";
import {
  parseCreateSignOffTemplate,
  getSignOffTemplateContent,
  updateSignOffTemplateContent,
  updateSignOffTemplateStatus,
  downloadSignOffTemplateS1P,
  selectTemplatePCB,
  selectTemplateServices,
  getSignOffTemplateUploadLog,
  uploadDecapModel,
  getTemplatePdfTable,
  selectTemplatePdfTable
} from "../../api/Cascade/SignOffTemplate";
import checkError from "../../api/checkError";
import apiProcess from "../../api/utility";

function parseAndCreateSignOffTask({ file, projectId, fileName, name, type }) {
  let formData = new FormData();
  formData.append('file', file, fileName);
  formData.append('projectId', projectId);
  formData.append('name', name);
  formData.append('type', type);
  return apiProcess(parseCreateSignOffTemplate, { formData }, false, false, true);
}

function getSignOffTempalteUploadLog({ projectId, verificationId }) {
  return apiProcess(getSignOffTemplateUploadLog, { projectId, verificationId }, false, false, true);
}

function getSignOffTemplateContentPromise({ verificationId }) {
  return apiProcess(getSignOffTemplateContent, { verificationId })
}

function updateSignOffTemplateInfoPromise(data) {
  return apiProcess(updateSignOffTemplateContent, { data })
}

function updateSignOffStatus({ verificationId }) {
  return apiProcess(updateSignOffTemplateStatus, { verificationId });
}

/**
 * 
 * 2022/12/01
 * download template result s1p file by power net */
function downloadTemplateS1P(downloadInfo) {
  return new Promise((resolve, reject) => {
    downloadSignOffTemplateS1P(downloadInfo).then(res => {
      if (!res || (res && typeof (res.data) === "object" && res.data.code !== SUCCESS)) {
        resolve(null);
      } else if (res && res.data) {
        let blob = new Blob([res.data], {
          type: 'application/json',
        });
        resolve(blob);
      } else {
        resolve(null);
      }
    }, error => {
      resolve(null);
      checkError(error, false);
    })
  })
};

/**
 * 2023/05/16
 * select template design
 *  */
function selectSignOffTemplatePCB({ verificationId, designId }) {
  return apiProcess(selectTemplatePCB, { verificationId, designId })
}

function selectTemplateTaskServices({ verificationId, pathR, impedance }) {
  return new Promise((resolve, reject) => {
    return apiProcess(selectTemplateServices, { verificationId, pathR, impedance }).then(res => {
      resolve('Set task service successfully!');
    }, error => {
      console.error(error);
      reject("Set task service failed!");
    });
  })
}

function uploadDecapModelExcel({ file, type }) {
  return apiProcess(uploadDecapModel, { file, type })
}

function chooseTemplateTable(verificationId) {
  return apiProcess(getTemplatePdfTable, verificationId)
}

function selectTemplateTable({ verificationId, sheetNumber }) {
  return apiProcess(selectTemplatePdfTable, { verificationId, sheetNumber })
}

export {
  parseAndCreateSignOffTask,
  getSignOffTemplateContentPromise,
  updateSignOffTemplateInfoPromise,
  updateSignOffStatus,
  downloadTemplateS1P,
  selectSignOffTemplatePCB,
  selectTemplateTaskServices,
  getSignOffTempalteUploadLog,
  uploadDecapModelExcel,
  chooseTemplateTable,
  selectTemplateTable
}