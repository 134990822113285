import { select } from 'd3-selection';
import LayerRenderer from './LayerRenderer';
import CanvasObjectList from './CanvasObjectList';
import CanvasObject from './CanvasObject';
import CanvasPath from './CanvasPath';
import CanvasPolyline from './CanvasPolyline';
import CeXMark from '@/services/geometry/CeXMark';
import CePolygon from '@/services/geometry/CePolygon';

/** The OverlayLyrRenderer draws selected objects in a design
 *  The main purpose of this renderer is to display all traces in a net
 */
var OverlayLyrRenderer = function (layoutRenderer, layerName, hidden) {
  LayerRenderer.call(this, layoutRenderer, layerName, hidden);
  this.layerSections = [];
  this.sectionObjList = [];
  this.d3NetGroups = null; // d3 drawing root
};

OverlayLyrRenderer.prototype = Object.create(LayerRenderer.prototype);
OverlayLyrRenderer.prototype.constructor = OverlayLyrRenderer;

OverlayLyrRenderer.prototype.prepareData = function (coupleData) {

  var self = this,
    netObjects = this.layoutRenderer.selectionLayer.netObjLists,
    layerList = self.layoutRenderer.layerList,
    searchNets = coupleData.searchNets;

  this.layerSections = coupleData.layerSections;
  this.traceWidth = coupleData.traceWidth;
  this.sectionObjList = [];
  for (var i = 0; i < this.layerSections.length; i++) {
    for (var j = 0, length = this.layerSections[i].sections.length; j < length; j++) {
      var section = this.layerSections[i].sections[j],
        sectionObj = {
          id: section.id,
          nets: '',
          layer: this.layerSections[i].layerName,
          outlineObj: new CanvasPath(),
          traceObjs: [],
          objList: new CanvasObjectList(),
          width: coupleData.traceWidth
        },
        outline = new CePolygon();

      for (var layerIndex = 1; layerIndex < layerList.length; layerIndex++) {
        if (layerList[layerIndex].layerName === sectionObj.layer) break;
      }
      layerIndex -= 1;

      this.sectionObjList.push(sectionObj);
      //add points to outline
      for (var iNet = 0; iNet < section.nets.length; iNet++) {
        var point = section.nets[iNet].points[0];
        outline.AddVertex(point.x, point.y);
      }
      for (; iNet > 0; iNet--) {
        var point = section.nets[iNet - 1].points[1];
        outline.AddVertex(point.x, point.y);
      }
      sectionObj.outlineObj.pathData = outline.GetSvgPath();

      let templateObj = new CanvasObject();
      //add traces
      for (var iNet = 0; iNet < section.nets.length; iNet++) {
        var traceObj = new CanvasPolyline();
        sectionObj.traceObjs.push(traceObj);

        var point0 = section.nets[iNet].points[0];
        var point1 = section.nets[iNet].points[1];

        traceObj.points = point0.x + ',' + point0.y + ' ' +
          point1.x + ',' + point1.y;
        traceObj.toolTip = section.nets[iNet].name + '\n' +
          '(' + point0.x + ',' + point0.y + ') \n' +
          '(' + point1.x + ',' + point1.y + ') \n' +
          'Section: ' + sectionObj.id + '\n' +
          'Layer: ' + sectionObj.layer + '\n';

        var netIndex = searchNets.indexOf(section.nets[iNet].name),
          netObject = netObjects[layerIndex * searchNets.length + netIndex];
        if (netObject && netObject.canvasLineList.length) {
          traceObj.width = netObject.canvasLineList.reduce(function (line0, line1) {
            return line0.width < line1.width ? line0 : line1;
          }).width;

          if (traceObj.width < sectionObj.width) sectionObj.width = traceObj.width;
        } else {
          traceObj.width = sectionObj.width;
        }

      }
      sectionObj.outlineObj.width = sectionObj.width / 4;

      //add point mark
      for (var iNet = 0; iNet < section.nets.length; iNet++) {
        if (sectionObj.nets.length) sectionObj.nets += ', ';
        sectionObj.nets += section.nets[iNet].name;
        var markGeom = new CeXMark();
        var point0 = section.nets[iNet].points[0];
        point1 = section.nets[iNet].points[1];
        if (Math.abs(point0.x - point1.x) < 0.1 ||
          Math.abs(point0.y - point1.y) < 0.1) {
          markGeom.rotate = 45;
        }

        templateObj.toolTip = section.nets[iNet].name + '\n' +
          '(' + point0.x + ',' + point0.y + ') \n' +
          'Section: ' + sectionObj.id + '\n' +
          'Layer: ' + sectionObj.layer + '\n';
        markGeom.SetData(point0.x, point0.y, sectionObj.width * 1.5);
        sectionObj.objList.addGeometry(markGeom, null, templateObj);

        templateObj.toolTip = section.nets[iNet].name + '\n' +
          '(' + point1.x + ',' + point1.y + ') \n' +
          'Section: ' + sectionObj.id + '\n' +
          'Layer: ' + sectionObj.layer + '\n';
        markGeom.SetData(point1.x, point1.y, sectionObj.width * 1.5);
        sectionObj.objList.addGeometry(markGeom, null, templateObj);
      }

    }
  }

}; // OverlayLyrRenderer.prototype.prepareData

var clickEventHandling = function (d) {

};

OverlayLyrRenderer.prototype.redraw = function () {

  // draw the objects
  var self = this;


  this.layerElement.selectAll('g').remove();

  this.d3NetGroups = this.layerElement
    .selectAll('g')
    .data(this.sectionObjList)
    .enter()
    .append('g')
    .on('click', function (sectionObj) {
      // $rootScope.$broadcast('SelectSectionObj', sectionObj.id)
    });
  //.attr('fill','magenta');



  this.d3NetGroups
    .data(self.sectionObjList)
    .each(function (sectionObj, i) {

      var outlineAttr = {
          'stroke-width': sectionObj.width / 4,
          'fill-opacity': 0,
          opacity: 0.8,
          'stroke-dasharray': sectionObj.width / 4 + ',' + sectionObj.width / 2,
          'stroke': 'yellow'
        },
        element = select(this);
      sectionObj.outlineObj.drawSVG(element, outlineAttr);
      sectionObj.traceObjs.forEach(function (traceObj) {
        var traceAttr = {
          'stroke-width': traceObj.width,
          stroke: 'magenta',
          'stroke-linecap': 'butt'
        }
        traceObj.drawSVG(element, traceAttr);
      });
      var group = select(this).append('g').attr('stroke', 'white');
      sectionObj.objList.drawSVG(group);
      select(this)
        .append('title')
        .text('Section: ' + sectionObj.id + '\nLayer: ' + sectionObj.layer);

    });

  // call the virtual function of the parent
  LayerRenderer.prototype.redraw.call(this);

}; // OverlayLyrRenderer.prototype.redraw

OverlayLyrRenderer.prototype.clear = function () {
  this.layerElement.selectAll('g').remove();
  this.sectionObjList = [];
  this.d3NetGroups = null;
};

OverlayLyrRenderer.prototype.selectSections = function (ids) {
  this.d3NetGroups
    .data(this.sectionObjList)
    .attr('hidden', function (sectionObj) {
      if (ids.indexOf(sectionObj.id) > -1) {
        return null;
      } else {
        return true;
      }
    });
};


export default OverlayLyrRenderer;