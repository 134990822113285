/**
 * class CeIODataNode
 */

// CeIODataNode(String name)
var CeIODataNode = function (name) {
    //  String mName;
    this.mName = name;
};

// static boolean mCompact = true;
CeIODataNode.mCompact = false;

// public static void SetCompactFormat(boolean flag)
CeIODataNode.setCompactFormat = function (flag) {
    CeIODataNode.mCompact = flag;
};

// public String GetName()
CeIODataNode.prototype.GetName = function () {
    return this.mName;
};

// public void SetName(String name)
CeIODataNode.prototype.SetName = function (name) {
    this.mName = name;
};

// public void WriteBlockItem(BufferedWriter output, int tabNum) throws IOException
CeIODataNode.prototype.WriteBlockItem = function(intTabNum) {
    var strOutput = "";

    if (!CeIODataNode.mCompact) {
        for(var i = 0; i < intTabNum; i++) {
            strOutput += '\t';
        }
    }

    return strOutput;
};

// public void WriteBlockItemToBytes(ByteArrayOutputStream out, int tabNum) throws IOException
// TODO

// public boolean IsBlock()
CeIODataNode.prototype.IsBlock = function () {
    return false;
};

// public void AppendData(String val)
// TODO

// public void AppendData(StringList vals)
CeIODataNode.prototype.appendData = function (valueOrValues) {
    // virtual function
};

export default CeIODataNode;
	

