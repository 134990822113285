import React, { Component, Fragment } from 'react';
import { Select, Tooltip, Spin, Collapse, TreeSelect } from 'antd';
import EditableTable from '@/components/EditableTable';
import {
  getAdsSettingData,
  getEyeSettingColumns,
  getEyeParamColumn,
  getComplianceColumns,
  getComplianceCollapseData
} from '../../../../services/Andes_v2/results/eyeDiagram';
import { CPHY } from '../../../../services/PCBHelper/constants';

const Option = Select.Option;

const cphyDataColumns = [{
  title: "PRBS",
  dataIndex: "prbs"
}, {
  title: "Simulation Method",
  dataIndex: "simMethod"
}]

class EyeDiagramRender extends Component {

  getSignalOptions = (signalSelect, signals, eye, eyeDiagrams, type, resultType, selectedIndex) => {
    return (
      <Select
        value={{ key: signalSelect || "", label: this.signalDisplay(signalSelect, eye, type) }}
        popupMatchSelectWidth={false}
        onChange={option => option && this.props.signalChange(option.value, resultType, selectedIndex)}
        className='signal-select'
        getPopupContainer={() => document.getElementsByClassName("andes-v2-result-main")[0]}
        popupClassName='signal-select-dropdown'
        dropdownStyle={{ maxHeight: 300 }}
        labelInValue
      >
        {signals.map((signal, i) => {
          const _eye = eyeDiagrams.find(it => it.signal === signal.name);
          return <Option key={signal.name}>
            <Tooltip
              title={this.signalTooltip(signal.name, _eye)}
              overlayClassName='aurora-tooltip' placement={'topLeft'}>
              {this.signalDisplay(signal.name, _eye, type)}
            </Tooltip>
          </Option>
        })}
      </Select>
    )
  }

  getSignalGroupOptions = (signals, eye, eyeDiagrams, type, resultType, selectedIndex, probeList) => {
    const { signal, probeType } = eye;
    return (
      <Fragment>
        <TreeSelect
          treeData={this.getChannelSelectList(signals, probeList, eyeDiagrams, type)}
          value={{ key: `${signal}~${probeType}` || "", value: `${signal}~${probeType}` || "", label: this.signalDisplay(signal, eye, type, true) }}
          onChange={(value) => this.props.signalTreeChange(value, resultType, selectedIndex)}
          labelInValue
          className='signal-select'
          treeDefaultExpandAll
          getPopupContainer={() => document.getElementsByClassName("andes-v2-result-main")[0]}
          popupClassName='signal-select-dropdown'
          dropdownStyle={{ maxHeight: 300 }}
          virtual={false}
        />
      </Fragment>
    )
  }

  getChannelSelectList = (signals, probeList, eyeDiagrams, type) => {
    const treeData = signals.map((signal, i) => {
      const { name } = signal;
      const _eye = eyeDiagrams.find(it => it.signal === name);
      const children = probeList.map(item => {
        return {
          title: item,
          value: `${name}~${item}`,
          key: `${name}~${item}`
        }
      })
      return {
        title: this.signalDisplay(name, _eye, type),
        value: name,
        key: `${name}`,
        children: children
      }
    })
    return treeData
  }

  signalDisplay = (signalSelect, eye, type, isProbe) => {
    if (eye && eye.direction && eye.controller && eye.device) {
      return <span className='andes-customized-title'>
        <span className='design-name'>{eye.signal}</span>
        <span className='component-pin-name'>&nbsp;{`<${eye.controller}, ${eye.device}>`}</span>
        <span className='signal-name'>&nbsp;{eye.direction}</span>
        {isProbe && eye.probeType ? <span className='signal-name'>&nbsp;{eye.probeType}</span> : null}
      </span>
    } else if ((type === "ads" || type === 'hspice') && eye.controller && eye.device) {
      return <span className='andes-customized-title'>
        <span className='design-name'>{eye.signal}</span>
        <span className='component-pin-name'>&nbsp;{`<${eye.controller} --> ${eye.device}>`}</span>
        {isProbe && eye.probeType ? <span className='signal-name'>&nbsp;{eye.probeType}</span> : null}
      </span>
    } else {
      return signalSelect;

    }
  }

  signalTooltip = (signalSelect, eye) => {
    if (eye && eye.direction && eye.controller && eye.device) {
      return <div>
        <div>Signal: {eye.signal} </div>
        <div>Direction: {eye.direction}</div>
        <div>Controller: {eye.controller}</div>
        <div>Device: {eye.device}</div>
      </div>
    } else {
      return signalSelect;
    }
  }

  complianceCollapseRender2 = (seaSimParameters) => {
    if (!seaSimParameters || !seaSimParameters.length) { return }
    const { dataSource, expandedRowKeys } = getComplianceCollapseData(seaSimParameters)
    const items = [{
      key: "compliance_setting",
      label: <div className="eye-setting-table-title">
        Compliance Setting
      </div>,
      children: <EditableTable
        key={'compliance_setting_table'}
        rowKey={(record) => record.paramName}
        columns={getComplianceColumns()}
        dataSource={dataSource}
        size="small"
        className="andes-eye-params-compliance-collapse-table"
        showHeader={false}
        expandedRowKeys={expandedRowKeys}
      />
    }]
    return <Collapse className="eye-setting-collapse-content eye-compliance-collapse-content" defaultActiveKey={["compliance_setting"]} items={items} />
  }

  render() {
    const { eye, signals, eyeDiagrams, type, resultType, selectedIndex, historySelectList, config, contentHeight, settingHeight, interfaceType, clockBitRateExist, probeList } = this.props;
    let imgHeight = contentHeight ? contentHeight - settingHeight - 80 : null;
    if (imgHeight < 300 && imgHeight > 0) {
      imgHeight = 300;
    }
    const imgStyle = { maxHeight: imgHeight }
    return (
      <div className='aurora-eye-item' key={eye.id}>
        <div className='aurora-eye-item-flex clear'>
          <div className='eyediagram-left' style={{ maxHeight: imgHeight }}>
            <Spin spinning={eye.loading || false} size='default' tip='Loading...' key={eye.id + '_load'}>
              <img
                id={eye.id + '_img'}
                src={eye.imgSrc}
                className='andes-eye-img'
                key={eye.id + '_img'}
                alt=""
                style={imgStyle} />
              <canvas id={eye.id} style={{ position: "absolute", top: 50, left: 0 }}></canvas>
            </Spin>
          </div>
          <div className='eyediagram-setting' id={selectedIndex === 0 ? "andes-eye-setting-main" : null}>
            <div className='eyediagram-result-select'>
              <span className='signal-select-title'>Result</span>
              <Select
                value={{ key: resultType, label: this.props.getName(resultType) }}
                labelInValue
                onChange={option => option && this.props.resultsSelect(option.value, selectedIndex)}
                className='signal-select'
                popupClassName='aurora-select-dropdown'
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {[{ id: 'current', name: 'Current' }, ...historySelectList].map(d =>
                  <Option key={d.id}>{d.name}</Option>
                )}
              </Select>
            </div>
            <div className='eyediagram-signals'>
              <span className='signal-select-title'>Signal</span>
              {probeList && probeList.length > 1 && interfaceType !== CPHY ?
                <Fragment>{this.getSignalGroupOptions(signals, eye, eyeDiagrams, type, resultType, selectedIndex, probeList)}</Fragment> :
                <Fragment>{this.getSignalOptions(eye.signal, signals, eye, eyeDiagrams, type, resultType, selectedIndex)}</Fragment>
              }
            </div>
            <EditableTable
              key={eye.id + '_tb'}
              rowKey={(record) => eye.id + '_' + record.key}
              columns={getEyeParamColumn(type, interfaceType)}
              dataSource={eye.parameter}
              size="small"
              className={type === "ads" ? "andes-eye-params-ads-table" : "andes_eye-params-compliance-table"}
              showHeader={type === "compliance" ? false : true}
            />
            {type === "ads" && <Collapse
              className="eye-setting-collapse-content"
              defaultActiveKey={["eye_setting"]}
              items={[{
                key: "eye_setting",
                label: <div className="eye-setting-table-title">
                  Simulation Setting
                </div>,
                children: <EditableTable
                  key={eye.id + '_setting'}
                  rowKey={(record) => eye.id + '_' + record.dataRate}
                  columns={interfaceType === CPHY ? cphyDataColumns : getEyeSettingColumns(clockBitRateExist)}
                  dataSource={getAdsSettingData(config, eye.signal)}
                  size="small"
                  className="andes-eye-diagram-setup-table"
                />
              }]}
            />}
            {type === "compliance" && this.complianceCollapseRender2(eye.seaSimParameters)}
          </div>
        </div>
      </div>
    )
  }
}

export default EyeDiagramRender;