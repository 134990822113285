import React from 'react';
import {
  getSpiceModelList, getFolderFileDetail, getIbisPackageList, getPkgSpiceModelList,
  getTouchstoneParse, getIbisModelList, getLibraryRepeaterFile, getLibraryConnectorFile,
  getLibraryFileContent
} from './library';
import {
  IBIS,
  SPICE,
  CONNECTOR_SPICE_SIERRA, CONNECTOR_TOUCHSTONE,
  VECTOR, PASSIVE_TOUCHSTONE, PASSIVE_SPICE, REPEATER, PKG_TOUCHSTONE, PKG_SPICE
} from '../../constants/libraryConstants';
import { MultiPinSPICE } from '../../pages/Sierra/constants';
import sierraLibrary from '@/services/Sierra/library/libraryStorage';
import { parseSpiceModelSelector } from "../LibraryHelper/SpiceModelHelper";

async function updateICModel({ file, pairs, comp, info, error, errorExist }) {
  let newFile = { ...file }, newPairs = [...pairs], newError = { ...error }, newErrorExist = errorExist;
  const childName = newFile.folder ? newFile.fileName : null;
  let modelExsit = newFile.type === 'IBIS' ? sierraLibrary.checkFile(IBIS, newFile.libraryId, childName) : sierraLibrary.checkFile(SPICE, newFile.libraryId, childName);
  if (!modelExsit) {
    //delete node
    newPairs = cleanPairs(newPairs, newFile);
    const fileText = newFile.folder ? `${newFile.folder}::${newFile.fileName}` : newFile.fileName;
    //add error message
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      error: <p style={{ margin: 0 }}>Model "<span className="font-bold">{info.pcb}::{comp.name}</span>" {newFile.type} file <span className="font-bold">{fileText}</span> has been deleted.</p>
    }
    newError.model = newError.model ? [...newError.model, currentError] : [currentError]
    newErrorExist = true;
    newFile.libraryId = "";
    newFile.fileName = "";
    if (newFile.folder) {
      newFile.folder = "";
    }
    if (newFile.subckt) {
      newFile.subckt = "";
    }
  } else if (fileNeedUpdate(newFile, modelExsit)) {
    if (newFile.type === 'MultiPinSPICE') {
      let model = null;
      if (newFile.folder) {
        const detail = await getFolderFileDetail({ libraryId: modelExsit.id, fileNames: [newFile.fileName] });
        newFile.folder = modelExsit.name;
        if (!detail) {
          newFile.fileName = "";
        }
        model = detail && detail.files && detail.files[0] && detail.files[0].models ?
          detail.files[0].models.find(m => m.name === newFile.subckt) : null;
      } else {
        const detail = await getSpiceModelList({ libraryId: modelExsit.id });
        newFile.fileName = modelExsit.name;
        model = detail && detail.models ? detail.models.find(m => m.name === newFile.subckt) : null;
      }
      if (!model) {
        newPairs = cleanPairs(newPairs, newFile);
      } else {
        newPairs.forEach(item => {
          if (item.libraryId === newFile.libraryId && item.fileName === newFile.fileName && item.subckt === newFile.subckt) {
            item.fileName = newFile.fileName;
            if (!model || !model.ports || !model.ports.includes(item.node)) {
              item.node = "";
            }
          }
        });
      }
    } else {
      newFile.fileName = modelExsit.name;
    }
    newFile.version = modelExsit.version;
    const fileText = newFile.folder ? `${newFile.folder}::${newFile.fileName}` : newFile.fileName;
    //add error message
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      error: <p style={{ margin: 0 }}>Model "<span className="font-bold">{info.pcb}::{comp.name}</span>" {newFile.type} file <span className="font-bold">{fileText}</span> has been updated.</p>
    }
    newError.model = newError.model ? [...newError.model, currentError] : [currentError]
    newErrorExist = true;
  }
  return { newFile, newPairs, newError, newErrorExist };
}

async function updateICPackageIbis({ file, comp, info, error, errorExist }) {
  let newFile = { ...file }, newError = { ...error }, newErrorExist = errorExist;
  let pkgExsit = sierraLibrary.checkFile(IBIS, newFile.libraryId);
  if (!pkgExsit) {
    newFile = {
      component: "",
      libraryId: "",
      model: "",
      modelInfo: "",
      type: ""
    }
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      error: <p style={{ margin: 0 }}>Package "<span className="font-bold">{info.pcb}::{comp.name}</span>" {newFile.type} file <span className="font-bold">{newFile.fileName}</span> has been deleted.</p>
    }
    newError.pkg = newError.pkg ? [...newError.pkg, currentError] : [currentError]
    newErrorExist = true;
  } else if (fileNeedUpdate(newFile, pkgExsit)) {
    const detail = await getIbisPackageList(newFile.libraryId);
    const model = detail && detail.length > 0 ? detail.find(m => m.component === newFile.component) : null;
    if (model) {
      if (newFile.model === "Generic") {
        newFile.modelInfo = {
          R: model.generic.find(g => g.name.match('R')).typ,
          L: model.generic.find(g => g.name.match('L')).typ,
          C: model.generic.find(g => g.name.match('C')).typ,
        }
      }
    } else {
      newFile = {
        component: "",
        libraryId: "",
        model: "",
        modelInfo: "",
        type: ""
      }
    }
    newFile.version = pkgExsit.version
    //add error message
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      error: <p style={{ margin: 0 }}>Package "<span className="font-bold">{info.pcb}::{comp.name}</span>" {newFile.type} file <span className="font-bold">{newFile.fileName}</span> has been updated.</p>
    }
    newError.pkg = newError.pkg ? [...newError.pkg, currentError] : [currentError]
    newErrorExist = true;
  }
  return { newFile, newError, newErrorExist };
}

async function updateICPackage({ file, pairs, comp, info, error, errorExist }) {
  let newFile = { ...file }, newPairs = [...pairs], newError = { ...error }, newErrorExist = errorExist;
  let pkgExsit = null;
  if (newFile.type === 'Touchstone') {
    pkgExsit = sierraLibrary.checkFile(PKG_TOUCHSTONE, newFile.libraryId);
  } else {
    pkgExsit = sierraLibrary.checkFile(PKG_SPICE, newFile.libraryId);
  }
  if (!pkgExsit) {
    //add error message
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      error: <p style={{ margin: 0 }}>Package "<span className="font-bold">{info.pcb}::{comp.name}</span>" {newFile.type} file <span className="font-bold">{newFile.fileName}</span> has been deleted.</p>
    }
    newError.pkg = newError.pkg ? [...newError.pkg, currentError] : [currentError];
    newErrorExist = true;
    newFile.libraryId = "";
    newFile.fileName = "";
    newPairs.forEach(item => {
      if (item.libraryId === newFile.libraryId && item.subckt === newFile.subckt) {
        item.libraryId = "";
        item.node = "";
        item.subckt = "";
      }
    })
  } else if (fileNeedUpdate(newFile, pkgExsit)) {
    let detail = null, ports = [], subckt = "";
    if (newFile.type === 'Touchstone') {
      detail = await getTouchstoneParse(newFile.libraryId, newFile.fileName);
      ports = detail ? detail.ports.map(p => p.port) : [];
    } else {
      detail = await getPkgSpiceModelList(newFile.libraryId);
      let model = detail ? detail.models.find(m => m.name === newFile.subckt) : null;
      subckt = model.name;
      if (!model) {
        newFile.fileName = "";
        newFile.libraryId = "";
        newFile.subckt = "";
        newFile.type = "";
      } else {
        ports = model.ports;
      }
    }
    newPairs.forEach(item => {
      if (item.libraryId === newFile.libraryId && (!item.subckt || item.subckt === subckt)) {
        if (!ports.includes(item.node)) {
          item.libraryId = "";
          item.node = "";
          item.subckt = "";
        }
      }
    })
    //add error message
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      error: <p style={{ margin: 0 }}>Package "<span className="font-bold">{info.pcb}::{comp.name}</span>" {file.type} file <span className="font-bold">{file.fileName}</span> has been updated.</p>
    }
    newError.pkg = newError.pkg ? [...newError.pkg, currentError] : [currentError]
    newErrorExist = true;
    newFile.fileName = pkgExsit.name;
  }
  return { newFile, newPairs, newError, newErrorExist };
}

async function updateICStimulus({ item, pin, comp, info, error, errorExist }) {
  let newFile = { ...item }, newError = { ...error }, newErrorExist = errorExist;
  let currentStimuli = newFile.stimulus;
  let modelExsit = null;
  const childFile = currentStimuli.folder ? currentStimuli.fileName : null;
  modelExsit = newFile.type === MultiPinSPICE ? sierraLibrary.checkFile(SPICE, currentStimuli.libraryId, childFile) : sierraLibrary.checkFile(VECTOR, currentStimuli.libraryId, childFile);
  const fileText = currentStimuli.folder ? `${currentStimuli.folder}::${currentStimuli.fileName}` : currentStimuli.fileName;
  if (!modelExsit) {
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      pin: pin.pin,
      error: <p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{info.pcb}::{comp.name}/{pin.pin}</span>" vector file <span className="font-bold">{fileText}</span> has been deleted.</p>
    }
    newError.stimuli = newError.stimuli ? [...newError.stimuli, currentError] : [currentError];
    newErrorExist = true;
    //delete stimuli
    newFile.stimulus = "";
  } else if (fileNeedUpdate(newFile.stimulus, modelExsit)) {
    if (newFile.type === 'VEC') {
      newFile.stimulus.fileName = modelExsit.name;
    } else if (newFile.type === 'MultiPinSPICE') {
      let model = null;
      if (newFile.stimulus.folder) {
        const detail = await getFolderFileDetail({ libraryId: modelExsit.id, fileNames: [newFile.stimulus.fileName] });
        if (!detail) {
          newFile.stimulus.fileName = "";
        }
        model = detail && detail.files && detail.files[0] && detail.files[0].models ?
          detail.files[0].models.find(m => m.name === newFile.stimulus.subckt) : null;
      } else {
        const detail = await getSpiceModelList({ libraryId: modelExsit.id });
        model = detail && detail.models ? detail.models.find(m => m.name === newFile.stimulus.subckt) : null;
      }
      if (model) {
        newFile.stimulus.node = model.ports.includes(newFile.stimulus.node) ? newFile.stimulus.node : "";
      } else {
        newFile.stimulus.node = "";
        newFile.stimulus.subckt = "";
      }
    }
    newFile.stimulus.version = modelExsit.version;
    let currentError = {
      pcb: info.pcb,
      compName: comp.name,
      pin: pin.pin,
      error: <p style={{ margin: 0 }}>{pin.usage} "<span className="font-bold">{info.pcb}::{comp.name}/{pin.pin}</span>" vector file <span className="font-bold">{fileText}</span> has been updated.</p>
    }
    newError.stimuli = newError.stimuli ? [...newError.stimuli, currentError] : [currentError];
    newErrorExist = true;
  }
  return { newFile, newError, newErrorExist };
}

async function updateICStimulusPin({ pin, comp, info, error, errorExist }) {
  let newPin = { ...pin }, newError = { ...error }, newErrorExist = errorExist;
  //check ibis/spice model
  if (newPin.model && typeof (newPin.model) === 'object' && newPin.model.libraryId) {
    const model = newPin.model;
    let modelExsit = model.libType === 'IBIS' ?
      sierraLibrary.checkFile(IBIS, model.libraryId) : sierraLibrary.checkFile(SPICE, model.libraryId);
    if (!modelExsit) {
      let currentError = {
        pcb: info.pcb,
        compName: comp.name,
        pin: newPin.pin,
        error: <p style={{ margin: 0 }}>{newPin.usage} "<span className="font-bold">{info.pcb}::{comp.name}/{newPin.pin}</span>" {model.modelType} file <span className="font-bold">{model.fileName}</span> has been deleted.</p>
      }
      newError.model = newError.model ? [...newError.model, currentError] : [currentError]
      newErrorExist = true;
      newPin.model = {};
      newPin.pinModels && newPin.pinModels.forEach(item => {
        if (item.pinName === 'nd_pu' || item.pinName === 'nd_pd') {
          item.voltage = ""
        } else {
          item.stimulus = "";
        }
      });
      newPin.powerOff = "0";
    } else if (fileNeedUpdate(model, modelExsit)) {
      let detail = model.libType === 'IBIS' ? await getIbisModelList({ libraryId: modelExsit.id }) : await getSpiceModelList({ libraryId: modelExsit.id });
      let _model = detail && detail.models ? detail.models.find(m => m.name === model.modelName) : null;
      if (_model) {
        model.enableVoltage = _model.enalbe ? _model.enalbe === 'NA' ? 1 : _model.enalbe : '';
        comp.deviceVcc = _model.deviceVcc ? parseFloat(_model.deviceVcc).toString() : comp.deviceVcc;
        model.modelType = _model.modelType ? _model.modelType : model.modelType;
      } else {
        //update model info
        newPin.model.modelType = "";
        newPin.model.modelName = "";
        newPin.model.enableVoltage = "";
        newPin.pinModels && newPin.pinModels.forEach(item => {
          if (item.pinName === 'nd_pu' || item.pinName === 'nd_pd') {
            item.voltage = ""
          }
        })
        newPin.powerOff = "0";
      }
      model.fileName = modelExsit.name;
      model.version = modelExsit.version;
      //add error message
      let currentError = {
        pcb: info.pcb,
        compName: comp.name,
        pin: newPin.pin,
        error: <p style={{ margin: 0 }}>{newPin.usage} "<span className="font-bold">{info.pcb}::{comp.name}/{newPin.pin}</span>" {model.modelType} file <span className="font-bold">{model.fileName}</span> has been updated.</p>
      }
      newError.model = newError.model ? [...newError.model, currentError] : [currentError]
      newErrorExist = true;
    }
  }
  return { newPin, newError, newErrorExist };
}

async function updateRepeater({ file, pairs, comp, info, error, errorExist }) {
  let newFile = { ...file }, newPairs = [...pairs], newError = { ...error }, newErrorExist = errorExist;

  if (!newFile.libraryId && newFile.fileName) {
    const findFile = sierraLibrary.findFileByName(REPEATER, newFile.fileName, newFile.folder);
    newFile.libraryId = findFile && findFile.id ? findFile.id : newFile.libraryId;
  }
  if (newFile.libraryId) {
    const childFile = newFile.folder ? newFile.fileName : null;
    let exist = sierraLibrary.checkFile(REPEATER, newFile.libraryId, childFile);
    if (!exist) {
      const findFile = sierraLibrary.findFileByName(REPEATER, newFile.fileName, newFile.folder);
      newFile.libraryId = findFile && findFile.id ? findFile.id : newFile.libraryId;
      exist = findFile && findFile.id ? { ...findFile } : false;
    }
    if (!exist) {
      let currentError = {
        pcb: info.pcb,
        compName: comp.name,
        error: <p style={{ margin: 0 }}><span className="font-bold">{info.pcb}::{comp.name}</span>" repeater file <span className="font-bold">{newFile.folder ? `${newFile.folder}::${newFile.fileName}` : newFile.fileName}</span> has been deleted.</p>
      }
      newError.repeater = newError.repeater ? [...newError.repeater, currentError] : [currentError];
      newErrorExist = true;
      //delete repeater model
      newPairs.forEach(item => {
        if (item.libraryId === newFile.libraryId) {
          item.node = "";
          item.libraryId = "";
          item.fileName = "";
          item.subckt = "";
        }
      })
      newFile.libraryId = "";
      newFile.fileName = "";
      newFile.subckt = "";
    } else if (exist && fileNeedUpdate(newFile, exist)) {
      let model = null;
      if (newFile.folder) {
        newFile.folder = exist.name;
        const detail = await getFolderFileDetail({ libraryId: exist.id, fileNames: [newFile.fileName] });
        if (!detail) {
          newFile.fileName = "";
        }
        model = detail && detail.files && detail.files[0] && detail.files[0].models ?
          detail.files[0].models.find(m => m.name === newFile.subckt) : null;
      } else {
        newFile.fileName = exist.name;
        const detail = await getLibraryRepeaterFile({ libraryId: exist.id });
        model = detail && detail.models ? detail.models.find(m => m.name === newFile.subckt) : null;
      }
      if (!model) {
        newFile.subckt = "";
        newPairs.forEach(item => {
          if (item.libraryId === newFile.libraryId) {
            item.node = "";
            item.fileName = exist.name;
            item.subckt = "";
          }
        })
      } else {
        newPairs.forEach(item => {
          if (item.libraryId === newFile.libraryId && !model.ports.includes(item.node)) {
            item.node = "";
            item.fileName = exist.name;
            item.subckt = "";
          } else {
            item.fileName = exist.name;
          }
        })
      }
      newFile.version = exist.version;
      let currentError = {
        pcb: info.pcb,
        compName: comp.name,
        error: <p style={{ margin: 0 }}><span className="font-bold">{info.pcb}::{comp.name}</span>" repeater file <span className="font-bold">{comp.model.name}</span> has been updated.</p>
      }
      newError.repeater = newError.repeater ? [...newError.repeater, currentError] : [currentError];
      newErrorExist = true;
    }
  }
  return { newFile, newPairs, newError, newErrorExist };
}

async function updateConnectorModels({ models, info, error, errorExist, comp }) {
  let newModels = [], newError = { ...error }, newErrorExist = errorExist;

  for (let model of models) {
    const fileType = model.type === SPICE ? CONNECTOR_SPICE_SIERRA : CONNECTOR_TOUCHSTONE;
    let exsit = sierraLibrary.checkFile(fileType, model.libraryId);

    if (!exsit) {
      let currentError = {
        pcb: info.pcb,
        compName: comp.name,
        error: <p style={{ margin: 0 }}>"<span className="font-bold">{info.pcb}::{comp.name}</span>" connector file <span className="font-bold">{model.file}</span> has been deleted.</p>
      }
      newError.connector = newError.connector ? [...newError.connector, currentError] : [currentError];
      newErrorExist = true;
      newModels.push(model)
    } else if (fileNeedUpdate(model, exsit)) {
      let newModel = { ...model }
      if (fileType === CONNECTOR_SPICE_SIERRA) {
        const detail = await getLibraryConnectorFile({ libraryId: exsit.id });
        const subckt = detail.find(m => m.name === newModel.subckt);
        if (!subckt) {
          newModel.subckt = "";
        }
      }
      newModel.file = exsit.name;
      newModel.version = exsit.version;
      let currentError = {
        pcb: info.pcb,
        compName: comp.name,
        error: <p style={{ margin: 0 }}>"<span className="font-bold">{info.pcb}::{comp.name}</span>" connector file <span className="font-bold">{newModel.file}</span> has been updated.</p>
      }
      newError.connector = newError.connector ? [...newError.connector, currentError] : [currentError];
      newErrorExist = true;
      newModels.push(newModel)
    } else {
      newModels.push(model)
    }
  }
  return { newModels, newError, newErrorExist };
}

async function updatePassive({ comp, info, error, errorExist }) {
  let newComp = { ...comp }, newError = { ...error }, newErrorExist = errorExist;
  let exsit = newComp.model.type === 'SPICE' ? sierraLibrary.checkFile(PASSIVE_SPICE, newComp.model.libraryId) :
    sierraLibrary.checkFile(PASSIVE_TOUCHSTONE, newComp.model.libraryId);
  if (!exsit) {
    let currentError = {
      pcb: info.pcb,
      compName: newComp.name,
      error: <p style={{ margin: 0 }}><span className="font-bold">{info.pcb}::{newComp.name}</span>" passive file <span className="font-bold">{newComp.model.name}</span> has been deleted.</p>
    }
    newError.passive = newError.passive ? [...newError.passive, currentError] : [currentError];
    newErrorExist = true;
    newComp.model.libraryId = "";
    newComp.model.fileName = "";
    newComp.model.pairs = [];
    newComp.model.subckt = "";
  } else if (fileNeedUpdate(newComp.model, exsit)) {
    if (newComp.model.type !== "Touchstone") {
      let detail = await getLibraryFileContent(exsit.id);
      detail = parseSpiceModelSelector(detail);
      let model = detail.find(m => m.name === newComp.model.subckt);
      if (!model) {
        newComp.model.subckt = "";
        newComp.model.pairs.forEach(item => {
          item.node = ""
        })
      } else {
        newComp.model.pairs.forEach(item => {
          if (!model.ports.includes(item.node)) {
            item.node = "";
          }
        })
      }
    }
    newComp.model.fileName = exsit.name;
    newComp.model.version = exsit.version;
    let currentError = {
      pcb: info.pcb,
      compName: newComp.name,
      error: <p style={{ margin: 0 }}><span className="font-bold">{info.pcb}::{newComp.name}</span>" passive file <span className="font-bold">{newComp.model.name}</span> has been updated.</p>
    }
    newError.passive = newError.passive ? [...newError.passive, currentError] : [currentError];
    newErrorExist = true;
  }
  return { newComp, newError, newErrorExist };
}

function fileNeedUpdate(oldFile, newFile) {
  return (!oldFile.version && newFile.version !== '1') || (oldFile.version && newFile.version !== oldFile.version) ? true : false;
}

function cleanPairs(pairs, file) {
  let newPairs = [...pairs];
  newPairs.forEach(item => {
    if (item.libraryId === file.libraryId && item.fileName === file.fileName && item.subckt === file.subckt) {
      item.libraryId = "";
      item.fileName = "";
      item.subckt = "";
      item.node = "";
    }
  });
  return newPairs;
}

export {
  updateICModel,
  updateICPackageIbis,
  updateICPackage,
  updateICStimulus,
  updateICStimulusPin,
  updateRepeater,
  updateConnectorModels,
  updatePassive
}