import { getIbisModelList, getIbisPackageList } from '@/services/Sierra/library'
import { getSierraProjectPromise } from '@/services/Sierra';
import { getPins } from '@/services/Sierra/library/IbisModelHelper.js'
import { ONLY_DRIVER_MODEL_TYPES, INPUT, IO } from '@/services/LibraryHelper'
import { getVerificationContentPromise, updateInterfacePromise } from '@/services/Sierra/SierraCtrl.js'
import { IC } from '../../PCBHelper';
import { userDefaultSettings } from '../../userDefaultSetting/userDefaultSettingCtrl';

const AUTO_ASSIGN_MODEL = "autoAssignModel",
  ASSIGN_MODEL_TO_ALL_INTERFACES = "assignModelToAllInterfaces",
  APPLY_MODEL_TO_ALL_INTERFACES = "applyModelToAllInterfaces",
  APPLY_MODEL_TO_SETUP = "applyModelToSetup",
  APPLY_PKG = "applyPKG";

function getUsage(type, prevUsage, ioBufferUsage) {
  if (prevUsage === 'Driver') return INPUT.includes(type) ? 'Receiver' : prevUsage
  if (prevUsage === 'Receiver') return ONLY_DRIVER_MODEL_TYPES.includes(type) ? 'Driver' : prevUsage;

  if (IO.includes(type) && ["Driver", "Receiver"].includes(ioBufferUsage)) {
    return ioBufferUsage;
  }
  return ONLY_DRIVER_MODEL_TYPES.includes(type) ? 'Driver' : 'Receiver'
}

export function delay(time) {
  return new Promise((resolve) => {
    setTimeout(() => { resolve() }, time)
  })
}

function existEnableVoltage(type) {
  const _type = type.toUpperCase();
  if (_type.includes('I/O') || _type.includes('IO') || _type === 'TRISTATE' || _type === '3-STATE' || _type === '3STATE') {
    return true;
  };
  return false;
}

function removeIBISPkg(file, currentComp) {
  const pkg = currentComp.pkg;
  if (pkg && pkg.type === "IBIS" && pkg.libraryId !== file.libraryId) {
    currentComp.pkg = { type: 'None' }
  }
  return currentComp;
}

function autoAssignIBISPkg(prevFile, currentComp) {
  const pkg = currentComp.pkg
  let _pkg = { type: 'None' }
  let regenerate = false

  const newFile = (currentComp.model && currentComp.model.files && currentComp.model.files[0]) || {}

  if (prevFile && prevFile.libraryId === newFile.libraryId && pkg.type === 'IBIS') {
    return currentComp
  } else {
    regenerate = true
  }

  if (regenerate) {
    if ((pkg && (pkg.type === 'None' || pkg.type === 'IBIS' || pkg.type === '')) || !pkg) {
      const { component, libraryId } = newFile
      if (!component) {
        _pkg = { type: "None" }
      } else {
        _pkg = {
          type: 'IBIS',
          component: component,
          libraryId: libraryId,
          model: 'Pin',
          modelInfo: ''
        }
      }

      currentComp.pkg = { ..._pkg }
    }
  }

  return currentComp
}

function clearSinglePinInfo(current) {
  if (!current || !current.model) {
    return current;
  }
  current.model.modelName = ''
  current.model.modelType = ''
  current.model.enableVoltage = ''
  current.powerOff = '0'

  return current
}

async function assignIBISModels({ libraryId, currentComp, version, allowClear = true, ioBufferUsage }) {
  let models = await getIbisModelList({ libraryId, usage: '' });
  models = models || {};
  const pins = currentComp.pins
  const component = currentComp.model.files[0].component
  const currentPkg = (models.packages || []).find(item => item.component === component)
  const pinSection = currentPkg && currentPkg.pinSection
  const selectorModels = models.selectorModels || [];

  // find selector from packages and find modelsName from selectorModels
  pins.forEach((item, index) => {
    const pinInfo = pinSection && pinSection.find(_item => _item.pin === item.pin)
    if (pinInfo) {
      const modelName = pinInfo.model_name
      // match selector
      const selector = selectorModels.find(item => item.selector === modelName)

      let modelInfo, default_model
      if (!selector) {
        modelInfo = (models.models || []).find(item => item.name === modelName)
      } else {
        const select_models = selector.models
        default_model = select_models[0] || {}
        modelInfo = (models.models || []).find(item => item.name === default_model.name)
      }

      //Terminator not support
      if (!modelInfo || modelInfo.type === "Terminator") {
        currentComp.pins[index] = clearSinglePinInfo(currentComp.pins[index])
      } else {
        const { type, deviceVcc, name } = modelInfo
        const { fileName } = currentComp.model.files[0];

        const default_pin_Model = {
          libType: 'IBIS',
          libraryId,
          fileName,
          modelType: type,
          modelName: name,
          enableVoltage: existEnableVoltage(type) ? (modelInfo.enable === 'NA' ? '1' : modelInfo.enable) : "",
          version: version || null,
        }

        const usage = getUsage(type, currentComp.pins[index].usage, ioBufferUsage);

        currentComp.pins[index].model = default_pin_Model
        currentComp.pins[index].usage = usage
        currentComp.pins[index].powerOff = '0'
        currentComp.deviceVcc = parseFloat(deviceVcc).toString();

        let pinModels = getPins({ type, usage })

        if (pinModels.length > 0) {
          pinModels.forEach(item => {
            if (item.pinName === 'nd_pu') {
              item.voltage = parseFloat(deviceVcc)
            }
            if (item.pinName === 'nd_pd') {
              item.voltage = "0"
            }
          })
        }

        currentComp.pins[index].pinModels = pinModels
      }
    } else if (allowClear) {
      // current pin is not in current component, so we should clear this existing pin model info
      currentComp.pins[index] = clearSinglePinInfo(currentComp.pins[index])
    }

  })
  return currentComp
}

function clearModels(currentComp) {
  const pins = currentComp.pins || []

  pins.forEach((item, index) => {
    currentComp.pins[index] = clearSinglePinInfo(item)
  })

  return currentComp
}

async function assignModelForInterfaces({ file, part, projectId, pcbId, updateAssignStatus, applyModelObj, verificationId }) {

  if (!projectId || !part || !file) return

  let current = 0
  const res = await getSierraProjectPromise(projectId)

  if (res === null) {
    updateAssignStatus({ finish: true, msg: 'Apply to all interfaces failed!' })
    await delay(2000)
    return
  }

  const userDefaultSetting = await userDefaultSettings.getSettings();
  const sierraSettings = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings : null;
  const ioBufferUsage = sierraSettings && sierraSettings.ioBufferUsage ? sierraSettings.ioBufferUsage : null;

  let verifications = res.verifications
  const interfacesLength = res.verifications.length

  for (const verification of verifications) {
    if (verificationId === verification.id) {
      continue;
    }
    const res = await getVerificationContentPromise(verification.id)

    if (res === null) {
      updateAssignStatus({ finish: true, msg: 'Apply to all interfaces failed!' })
      await delay(2000)
      return
    }

    const Interfaces = res.Interfaces
    for (const Interface of Interfaces) {
      if (Interface.pcbId !== pcbId) {
        continue;
      }
      let content = Interface.content
      if (!content || !content.components || !content.components.length) {
        continue;
      }
      const components = content.components

      let newComponents = []
      for (let comp of components) {
        const applyModel = applyModelObj[APPLY_MODEL_TO_ALL_INTERFACES];
        //apply model -> copy model to ic components
        //part -> copy and assign model to all same part components
        if (comp.type === IC && (applyModel || (applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES] && comp.part === part))) {
          // files
          const prevFile = comp.model && comp.model.files && comp.model.files[0] ? comp.model.files[0] : null;
          //apply buffer model to all interfaces
          if (!comp.model) {
            comp.model = {}
          }
          comp.model.files = [file]
          // pkg: set ibis file to pkg model
          if (applyModelObj[APPLY_PKG]) {
            comp = autoAssignIBISPkg(prevFile, comp)
          } else {
            comp = removeIBISPkg(file, comp)
          }

          if (comp.part !== part || !applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES]) {
            newComponents.push(comp)
            continue;
          }
          //copy and assign model to all same part components
          //auto assign all interfaces models(pin buffer model)
          comp = await assignIBISModels({ libraryId: file.libraryId, currentComp: comp, version: file.version, ioBufferUsage })
        }
        newComponents.push(comp)
      }

      content.components = newComponents

      // save
      const { pcbId: currPcbId, interfaceId, name: _name, readyForSim, designVersion, version, settingVersion } = Interface

      updateInterfacePromise({
        designId: currPcbId,
        interfaceId,
        interfaceName: _name,
        projectId,
        verificationId: verification.id,
        verificationName: verification.name,
        content,
        readyForSim,
        designVersion,
        version,
        settingVersion: settingVersion || ""
      })

      // update assign schedule status
      let status = ++current === interfacesLength ? { finish: true, msg: 'Already !' } : { finish: false, msg: `Currently Synchronizing ${_name}... ${current} / ${interfacesLength}` }
      updateAssignStatus(status)
    }
  }

  await delay(100)
  updateAssignStatus(null)
}

async function getComponentById(component, id) {
  const res = await getIbisPackageList(id)
  if (res && res.length > 0) {
    const components = res.map(item => item.component)
    component.model.files[0].component = components.length >= 1 ? components[0] : null
  }
  return component
}

async function applyModelToAllComponents({ file, Interfaces, applyModelObj, part, ioBufferUsage }) {
  for (const Interface of Interfaces) {
    let content = Interface.content
    if (!content || !content.components || !content.components.length) {
      continue;
    }
    const components = content.components

    let newComponents = []
    for (let comp of components) {
      //apply model -> copy model to ic components
      //part -> copy and assign model to all same part components
      if (comp.type === IC) {
        // files
        const prevFile = comp.model && comp.model.files && comp.model.files[0] ? comp.model.files[0] : null;
        //apply buffer model to all interfaces
        if (!comp.model) {
          comp.model = {}
        }
        comp.model.files = [file]
        // pkg: set ibis file to pkg model
        if (applyModelObj[APPLY_PKG] && file.component) {
          comp = autoAssignIBISPkg(prevFile, comp)
        } else {
          comp = removeIBISPkg(file, comp)
        }

        //copy and assign model to all same part components
        //auto assign all current setup interfaces models(pin buffer model)
        if (file.component &&
          (applyModelObj[ASSIGN_MODEL_TO_ALL_INTERFACES] || applyModelObj[AUTO_ASSIGN_MODEL])
          && comp.part === part) {
          comp = await assignIBISModels({ libraryId: file.libraryId, currentComp: comp, version: file.version, ioBufferUsage })
        }
      }
      newComponents.push(comp)
    }
    Interface.content.components = newComponents
  }
  return Interfaces;
}

export {
  assignIBISModels,
  autoAssignIBISPkg,
  clearModels,
  assignModelForInterfaces,
  getComponentById,
  AUTO_ASSIGN_MODEL,
  ASSIGN_MODEL_TO_ALL_INTERFACES,
  APPLY_MODEL_TO_ALL_INTERFACES,
  APPLY_MODEL_TO_SETUP,
  APPLY_PKG,
  applyModelToAllComponents
}