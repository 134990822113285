import { Select } from 'antd';
import React from 'react';

const Option = Select.Option;

function UnitAddonAfter({ unit, changeUnit, defaultUnit, list, className, popupClassName = "", style, disabled, id, getPopupContainer }) {
  return <Select
    value={unit || undefined}
    id={id}
    onChange={(key) => changeUnit(key)}
    className={`aurora-select ${className}`}
    popupClassName={`aurora-select-dropdown ${popupClassName}`}
    popupMatchSelectWidth={false}
    style={style}
    disabled={disabled}
    defaultValue={defaultUnit || undefined}
    getPopupContainer={() => getPopupContainer || document.body}
  >
    {list.map(item =>
      typeof (item) === "string" ?
        <Option key={item}>{item}</Option>
        : <Option key={item.key}>{item.title}</Option>
    )}
  </Select>
}

export default UnitAddonAfter;