import React from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { SWITCH, IND, JUMPER, RES, FERRITE } from "../../PCBHelper";

class RLModelInfo {
  constructor({ modelType = 'value', value = { r: "0", l: "0" }, passiveModel = null }) {
    this.type = modelType; // value/touchstone/spice
    this.value = { ...value };
    this.passiveModel = passiveModel || new PassiveModel({ type: modelType });
  }
}

function PassiveModel({ type = "", name = "", id = "", subcktName = "", pairs = [] }) {
  this.name = name;
  this.id = id;
  this.subcktName = subcktName;
  this.pairs = pairs; //[{ pin:"", node:"" }, ...]
}

function getRLValue(type, value) {
  switch (type) {
    case RES:
    case JUMPER:
    case SWITCH:
    case FERRITE:
      return { r: value };
    case IND:
      return { l: value };
    default:
      return null;
  }
}

function getModelValue(type, value) {
  switch (type) {
    case RES:
    case JUMPER:
    case SWITCH:
    case FERRITE:
      return value.r;
    case IND:
      return value.l;
    default:
      return value;
  }
}

function getModelTypeName(type = "") {
  switch (type.toUpperCase()) {
    case "VALUE":
      return "value";
    case "TOUCHSTONE":
      return "Touchstone";
    case "SPICE":
      return "SPICE";
    default:
      return type;
  }
}

const RLTypeList = [
  { title: 'Value', key: "value" },
  { title: 'SPICE', key: 'spice' },
  { title: 'Touchstone', key: 'touchstone' }
];
const defaultRLValue = {
  R: { value: '0', unit: 'Ω' },
  L: { value: '0', unit: 'n' },
};

function getRLModelText(model, compType, passiveFileNotExist) {
  if (!model) {
    return "";
  }
  switch (model.type) {
    case "value":
      return getValue(compType, model.value);
    case "touchstone":
    case "spice":
      return getModel(model.passiveModel, passiveFileNotExist);
    default: return "";
  }
}

function getModel(passiveModel, passiveFileNotExist) {
  if (!passiveModel) {
    return "";
  }
  let text = "";
  if (passiveModel.folder) {
    text = `${passiveModel.folder}::`
  }

  if (passiveModel.name) {
    text += passiveModel.name;
  }

  if (passiveModel.subcktName) {
    text += `::${passiveModel.subcktName}`;
  }

  if (passiveFileNotExist) {
    return (
      <div className='aurora-file-check-div'>
        <span>{text}</span>
        <Tooltip
          title={`Files ${text} has been ${passiveFileNotExist}, the model is invalid.`}
          overlayClassName='aurora-tooltip'
        ><QuestionCircleOutlined className='aurora-file-check-icon' onClick={(e) => spanClick(e)} />
        </Tooltip>
      </div>
    );
  }

  return text;
}

function spanClick(e) {
  e && e.stopPropagation();
}

function getValue(compType, value) {
  switch (compType) {
    case RES:
    case JUMPER:
    case SWITCH:
    case FERRITE:
      return value.r + "Ω";
    case IND:
      return value.l + "H";
    default: return "";
  }
}

function getRLModel(record) {
  const { model = {}, value, type } = record;
  let _value = getRLValue(type, value);
  const passiveModel = new PassiveModel({ ...model });
  const _model = new RLModelInfo({
    modelType: model.type ? model.type.toLowerCase() : model.type,
    value: _value,
    passiveModel
  });
  return { ..._model };
}

function formatRLValue(type, value, model) {
  if (!model || (model && model.type === 'value')) {
    return toString.call(value) === '[object Object]' ? `${_getRLValue(type, value)}` : `${value}${getDisplayUnit(type)}`
  } else {
    if (model && model.name) {
      return model.name
    } else {
      return ''
    }
  }
}

function getDisplayUnit(type) {
  switch (type) {
    case RES:
    case JUMPER:
    case SWITCH:
    case FERRITE:
      return "Ω"
    case IND:
      return "H";
    default: return "";
  }
}

function _getRLValue(type, value) {
  switch (type) {
    case RES:
    case JUMPER:
    case SWITCH:
    case FERRITE:
      return value.r + "Ω" || ""
    case IND:
      return value.l + "H";
    default: return "";
  }
}

function changeModelToNetListModel(passiveModel) {
  const { id: libraryId, name: fileName, subcktName: subckt, ...restModel } = passiveModel;
  const _passiveModel = {
    libraryId,
    fileName,
    subckt,
    ...restModel
  }
  return _passiveModel
}

function changeNetListModelToModel(passiveModel) {
  const { libraryId: id, fileName: name, subckt: subcktName, ...restModel } = passiveModel;
  const _passiveModel = {
    id,
    name,
    subcktName,
    ...restModel
  }
  return _passiveModel
}

export {
  RLModelInfo,
  RLTypeList,
  defaultRLValue,
  PassiveModel,
  getRLModelText,
  getRLValue,
  getModelValue,
  getModelTypeName,
  getRLModel,
  formatRLValue,
  changeModelToNetListModel,
  changeNetListModelToModel
}