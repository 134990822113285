import React, { Component, createRef } from 'react';
import { Switch } from 'antd';
import * as portCanvas from '@/services/LayoutCanvas/PortsCanvas';
import { CASCADE, ROCKY } from '../../constants/pageType';
import './index.css';

class PortCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNames: true,
      showAllPins: props.showPinList && props.showPinList.length ? false : true
    }
    this.svgRef = createRef();
    this.locationRef = createRef();
    this.layoutUnit = "";
  }

  componentDidMount() {
    window.addEventListener("contextmenu", this.rightMouseContextmenu, true)
    this.props.onRef && this.props.onRef(this)
    this.renderLayout();
    this.props.updateParentState('designUpdate', false);
  }

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.rightMouseContextmenu, true);
  }

  rightMouseContextmenu = (event) => {
    if (event && event.target) {
      const parentEle = document.getElementById("port-setup-canvas-main");
      if (parentEle.contains(event.target)) {
        event.preventDefault();
        return false;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { DesignData, designUpdate, updateSelected, product, updatePinSelected, showPinList } = this.props;
    if ((!prevProps.DesignData && DesignData) || designUpdate) {
      this.renderLayout()
      this.props.updateParentState('designUpdate', false)
    }

    if (updateSelected && updateSelected !== prevProps.updateSelected) {
      this.updateSelectedList();
      portCanvas.clearRightClickStatus();
      this.props.updateParentState('updateSelected', false)
    }

    if ([CASCADE, ROCKY].includes(product) && updatePinSelected && updatePinSelected !== prevProps.updatePinSelected) {
      this.updatePinSelectedList();
      portCanvas.clearRightClickStatus();
      this.props.updateParentState('updatePinSelected', false)
    }

    if (showPinList && showPinList.length && (!prevProps.showPinList || !prevProps.showPinList.length) && this.state.showAllPins) {
      this.setState({
        showAllPins: false
      }, () => {
        this.renderLayout()
      })
    }
  }

  updateSelectedList = () => {
    const { selectedBoxList, referencePins, deleteNegativePinsList, editingPort } = this.props;
    const refe = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
    portCanvas.updatePortSetupPinsInfo({ selectedBoxList, referencePins: refe, deleteNegativePinsList, editingPort });
  }

  updatePinSelectedList = () => {
    const { referencePins, powerPins, tableData } = this.props;
    const refe = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
    const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
    portCanvas.updatePortSetupPinsInfo({ selectedBoxList: tableData, referencePins: [...refe, ...power] });
  }

  getSelectedList = (selectedList, findPin) => {
    const { pinNumber } = findPin;
    const { powerPins } = this.props;
    const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
    const isPower = power.includes(pinNumber);
    let _selectedList = []
    if (isPower) {
      const find = selectedList.find(item => item.powerPins.includes(pinNumber));
      if (find) {
        _selectedList = [{ title: `Remove ${pinNumber} from power pins of port ${find.port} ${find.powerPins && find.powerPins.length ? `(${find.powerPins[0]})` : ''}`, pinType: 'powerPins', type: 'remove', port: find.port }]
      } else {
        _selectedList = selectedList.map(item => {
          return { title: `Add ${pinNumber} to power pins of port ${item.port} ${item.powerPins && item.powerPins.length ? `(${item.powerPins[0]})` : ''}`, pinType: 'powerPins', type: 'add', port: item.port }
        })
        _selectedList.push({ title: `Add ${pinNumber} to power pins of new port`, pinType: 'powerPins', type: 'add' })
      }
    } else {
      const findList = selectedList.filter(item => item.referencePins.includes(pinNumber));
      if (findList.length) {
        const ports = findList.map(i => i.port).sort();
        if (findList[0].referencePins.length === 1) {
          findList.forEach(f => {
            _selectedList.push({ title: `Remove ${pinNumber} from reference pins of port ${f.port} ${f.powerPins && f.powerPins.length ? `(${f.powerPins[0]})` : ''}`, pinType: 'referencePins', type: 'remove', port: findList[0].port });
          })
        } else {
          _selectedList.push({ title: `Remove ${pinNumber} from reference pins of port ${ports.join(', ')} ${findList[0].powerPins && findList[0].powerPins.length ? `(${findList[0].powerPins[0]})` : ''}`, pinType: 'referencePins', type: 'remove', port: findList[0].port });
        }
        _selectedList.push(
          ...selectedList.filter(item => !ports.includes(item.port)).map(item => {
            return { title: `Add ${pinNumber} to reference pins of port ${item.port} ${item.powerPins && item.powerPins.length ? `(${item.powerPins[0]})` : ''}`, pinType: 'referencePins', type: 'add', port: item.port }
          })
        )
      } else {
        _selectedList.push(
          ...selectedList.map(item => {
            return { title: `Add ${pinNumber} to reference pins of port ${item.port} ${item.powerPins && item.powerPins.length ? `(${item.powerPins[0]})` : ''}`, pinType: 'referencePins', type: 'add', port: item.port }
          })
        )
      }
      _selectedList.push({ title: `Add ${pinNumber} to reference pins of new port`, pinType: 'referencePins', type: 'add' })
    }
    return _selectedList.reverse()
  }

  canvasSelect = (canvasObj) => {
  }

  changeMouse = (x, y) => {
    this.setState({ location: x.toFixed(2) + ', ' + y.toFixed(2) });
  }

  savePortPin = (canvasObj, clickInfo) => {
    if (this.props.savePortSetupPin) {
      this.props.savePortSetupPin(canvasObj, clickInfo)
    }
  }

  deleteRefPin = (canvasObj) => {
    if (this.props.deletePortSetupPin) {
      this.props.deletePortSetupPin(canvasObj)
    }
  }

  renderLayout() {
    const { DesignData, layerList, product, showPinList } = this.props;
    const { showAllPins } = this.state;
    if (!DesignData) {
      return;
    }
    const events = {
      click: this.canvasSelect,
      mousemove: this.changeMouse,
      savePortPin: this.savePortPin,
      deleteRefPin: this.deleteRefPin
    }

    const extraEvents = [CASCADE, ROCKY].includes(product) ?
      {
        getSelectedList: this.getSelectedList
      } : {}

    if (this.svgRef && this.svgRef.current && this.svgRef.current.children && this.svgRef.current.children[0]) {
      this.svgRef.current.removeChild(this.svgRef.current.children[0])
    }

    portCanvas.initLayout(this.svgRef.current, DesignData, this.locationRef.current, { ...events, ...extraEvents }, false, showAllPins ? [] : showPinList);
    portCanvas.updateLayers(layerList, []);
    this.layoutUnit = DesignData.GetLayoutUnits();
    this.setPinColor();
    this.showPinNames(true);
    if ([CASCADE, ROCKY].includes(product)) {
      this.updatePinSelectedList();
    } else {
      this.updateSelectedList();
    }
    portCanvas.updatePortSetupEventFn({ ...events, ...extraEvents });
    portCanvas.hiddenNotDisplayPins(showAllPins ? [] : showPinList)
  }

  setPinColor() {
    const { chip, powerPins, referencePins, PowerNets, signalPins = {} } = this.props;
    portCanvas.setPinColorByNets({
      PowerPins: powerPins,
      ReferencePins: referencePins,
      SignalPins: signalPins,
      chip
    });
    portCanvas.setPowerNetsColor(PowerNets);
  }

  selectPins = (pins, isSelected, netType) => {
    const { chip, powerPins, referencePins, signalPins = {} } = this.props;
    portCanvas.selectPins({ pins, chip, isSelected, netType })
    if (netType === "SignalNet") {
      return;
    }
    const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
    const refe = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
    const signal = Object.keys(signalPins || {}).map(key => signalPins[key]).flat(2);
    const allPins = [...power, ...refe, ...signal].filter(item => !pins.includes(item));
    portCanvas.setPinsOpacity(allPins, chip, isSelected ? '0.6' : '1');
  }

  changeShowPinName = (bool, e) => {
    e && e.stopPropagation();
    this.setState({
      showNames: bool
    })
    this.showPinNames(bool);
  }

  showPinNames = (bool) => {
    if (bool) {
      const { chip, powerPins, referencePins, signalPins = {} } = this.props;
      const _power = [], _refe = [], signal = [];
      Object.values(powerPins).forEach(p => _power.push(...p));
      Object.values(referencePins).forEach(p => _refe.push(...p));
      Object.values(signalPins || {}).forEach(p => signal.push(...p));
      portCanvas.showPinsName({ [chip]: [..._power, ..._refe, ...signal] }, true)
    } else {
      portCanvas.showPinsName({}, false)
    }
  }

  drawGroup = (groupList, transparent = true) => {
    if (!this.state.showNames) {
      this.changeShowPinName(true)
    }

    portCanvas.selectPortsGroups(groupList, transparent);
    if (transparent) {
      //set opacity of other pins
      const { chip, powerPins, referencePins, signalPins = {} } = this.props;
      const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
      const refe = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
      const signal = Object.keys(signalPins || {}).map(key => signalPins[key]).flat(2);
      portCanvas.setAllPinsOpacity([...power, ...refe, ...signal], chip, '0.2', groupList);
    }
  }

  getPinsInfo = (pins) => {
    const { chip } = this.props;
    return portCanvas.getPinsDetail({ [chip]: pins })
  }

  drawGridLine = () => {
    const { gridColumn, gridRow, chip } = this.props
    portCanvas.drawGridLine(gridColumn, gridRow, chip)
  }

  getCurrentPoints = () => {
    return portCanvas.getCurrentPoints(this.props.chip)
  }

  getCurrentLines = () => {
    return portCanvas.getCurrentLines()
  }

  removeGridLines = () => {
    portCanvas.removeGridLines()
  }

  getPinLocation = (pin) => {
    return portCanvas.getPinLocation(pin)
  }

  getPinPath = (border, size, widthSize, heightSize) => {
    const { powerPins, referencePins, signalPins = {} } = this.props;
    const power = Object.keys(powerPins).map(key => powerPins[key]).flat(2);
    const refe = Object.keys(referencePins).map(key => referencePins[key]).flat(2);
    const signal = Object.keys(signalPins || {}).map(key => signalPins[key]).flat(2);
    const allPins = [...power, ...refe, ...signal];
    const pins = border === 'power' ? power : allPins
    portCanvas.getPinPath(pins, size, widthSize, heightSize);
  }

  removePinPath = () => {
    portCanvas.removePinPath()
  }

  showAllPinsSvg = () => {
    this.props.clearShowPinList();
    this.setState({
      showAllPins: true
    }, () => {
      this.renderLayout()
    })
  }

  render() {
    const { showNames, location, showAllPins } = this.state;
    return <div id="port-setup-canvas-main">
      <div className="port-setup-canvas-pin-name">
        <span className="port-setup-canvas-pin-title">Pin Number</span>
        <Switch className={`port-setup-canvas-pin-switch ${showNames ? {} : 'unshow-switch'}`} checked={showNames} onChange={this.changeShowPinName} />
      </div>
      {!showAllPins ? <div className="port-setup-canvas-pin-show-text">
        <span className="port-setup-canvas-pin-show-span">Only 1,000 Pins are currently displayed. If you need to display all pins, please click </span>
        <span className="port-setup-canvas-pin-show-span port-setup-canvas-pin-here-span" onClick={this.showAllPinsSvg}>here</span>
        <span className="port-setup-canvas-pin-show-span">.</span>
      </div> : null}
      <svg id='port-setup-canvas' className='canvas port-setup-canvas' ref={this.svgRef} />
      <div className='port-setup-layout-canvas-coord'>{location ? (`( ${location} ) ${this.layoutUnit} `) : location}</div>
    </div>
  }
}

export default PortCanvas;