import React, { Component, Fragment } from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Table from '@/components/EditableTable';
import { numberCheck } from "@/services/helper/dataProcess";
import { unitChange } from '@/services/helper/mathHelper';

const targetColumns = [
  {
    title: 'Time (UI)',
    dataIndex: 'time',
    width: '50%'
  }, {
    title: 'Voltage (V)',
    dataIndex: 'voltage',
    width: '50%'
  }
]

class EyeMaskTable extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.initColumns();
    this.initState();
  }

  inputRef = (ref) => {
    this.targetNameRef = ref;
  }

  initState = () => {
    const { pointsList } = this.props;
    let tableData = pointsList && pointsList.length ? pointsList : [];
    tableData.map((item, index) => item.index = index)
    this.setState({
      tableData,
      error: null,
      save: false,
    })
  }

  initColumns = () => {
    targetColumns[0].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'time',
        handleSave: (row) => this.saveValue(row, 'time')
      }
    }

    targetColumns[0].title = () => {
      const { unitValue } = this.props;
      return <div>Time ({unitValue})</div>
    }

    targetColumns[1].render = (text, record) => {
      return (
        <div>
          <span>{text}</span>
          <CloseOutlined
            className="eye-mask-delete-icon"
            onClick={(e) => this.deleteRow(e, record)} />
        </div>
      );
    }

    targetColumns[1].onCell = (record) => {
      return {
        record,
        edit: true,
        dataIndex: 'voltage',
        handleSave: (row) => this.saveValue(row, 'voltage')
      }
    }
  }

  getTimePoints = (errorReturn) => {
    const { tableData = [] } = this.state;
    let newPoints = [];
    this.setState({ error: null });
    for (let point of tableData) {
      const { time, voltage } = point;
      if (errorReturn === 'Error') {
        const checkArray = [{ msg: 'time must be a number.', value: time }, { msg: 'voltage must be a number.', value: voltage }]
        for (let check of checkArray) {
          let error = numberCheck(check.value);
          if (error) {
            this.setState({ error: check.msg });
            return errorReturn
          }
        }
      }
      newPoints.push({
        time: time || time === 0 ? Number(time) : '',
        voltage: voltage || voltage === 0 ? unitChange({ num: voltage, oldUnit: 'V', newUnit: 'V' }).number : ''
      })
    }
    return newPoints;
  }

  saveValue = (row, key) => {
    const currentIndex = row.index;
    const value = row[key];
    if (value) {
      let error = numberCheck(value)
      if (error) {
        this.setState({
          error: `${key} must be a number.`
        })
        return;
      }
    }
    const { tableData } = this.state;
    let newData = [...tableData];
    newData.find(item => item.index === currentIndex)[key] = value;
    this.setState({
      tableData: newData,
      save: true
    }, () => { this.props.refreshPlot() })
  }

  addTableRow = (e) => {
    e.stopPropagation();
    const { tableData } = this.state;
    let newIndex = tableData.length ? tableData[tableData.length - 1].index + 1 : 0;
    this.setState({
      tableData: [...tableData, { time: '', voltage: '', index: newIndex }],
      save: true
    }, () => { this.props.refreshPlot() })
  }

  deleteRow = (e, record) => {
    e.stopPropagation();
    const { index } = record;
    const { tableData } = this.state;
    let newData = tableData.filter(point => point.index !== index);
    this.setState({
      tableData: newData,
      save: true
    }, () => { this.props.refreshPlot() })
  }

  render() {
    const { error, tableData } = this.state;
    return (
      <Fragment>
        <div className='eye-mask-table-box aurora-setup-border'>
          <div className="eye-mask-data-table">
            <Table
              rowKey={(record) => record.index}
              columns={targetColumns}
              size="small"
              dataSource={tableData}
              scroll={
                tableData && tableData.length && tableData.length > 9
                  ? { y: 350 }
                  : {}
              }
              tablePadding={true}
            />
            <PlusCircleOutlined title="Add table row" onClick={(e) => this.addTableRow(e)} />
          </div>
        </div>
        {error && <span className='eye-mask-error-msg'>{error}</span>}
      </Fragment>
    );
  }
}

export default EyeMaskTable;