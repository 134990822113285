import {
  VRM,
  DECAP_TOUCHSTONE,
  DECAP_SPICE,
  DECAP_RLC,
  PKG_AC,
  PKG_DC,
  DECAP_MODEL_MAP,
  PIN_MAP,
  DRIVER,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  CUSTOM_SPICE,
  CUSTOM_TOUCHSTONE,
  POWERSI_OPTIONS,
  POWERDC_OPTIONS
} from "../../../constants/libraryConstants";

export const libraryTypeList = [
  VRM,
  DECAP_TOUCHSTONE,
  DECAP_SPICE,
  DECAP_RLC,
  PKG_AC,
  PKG_DC,
  PIN_MAP,
  DECAP_MODEL_MAP,
  DRIVER,
  CUSTOM_SPICE,
  CUSTOM_TOUCHSTONE,
  HFSS_OPTIONS,
  SIWAVE_OPTIONS,
  POWERSI_OPTIONS,
  POWERDC_OPTIONS
];

class LibraryConstructor {
  constructor() {
    for (let item of libraryTypeList) {
      this[item] = new Map(); //key - library id, value :{ id ,name, type, version ,config };
    }
  }

  getSize = (type) => {
    return this[type].size;
  };

  getLibraryList = (type) => {
    return this[type];
  }

  getLibraryValues = (type) => {
    let list = [];
    if (this[type]) {
      for (let val of this[type].values()) {
        list.push(val);
      }
    }
    return list;
    //return this[type].values().reduce((prev, curr) => { return [...prev, ...curr] });
  }

  getLibrary = (type, id) => {
    return this[type].get(id);
  }

  addLibrary = (type, id, data) => {
    this[type].set(id, data);
  }

  delLibrary = (type, id) => {
    this[type].delete(id);
  }

  checkFile = (type, libraryId) => {
    if (this[type].get(libraryId)) {
      return true;
    } else {
      return false;
    }
  }

  clearCache = (type) => {
    this[type] = new Map();
  }

  clearAllCache = () => {
    for (let item of libraryTypeList) {
      this[item] = new Map(); //key - library id, value :{ id ,name, type, version ,config };
    }
  }
};

let libraryConstructor = new LibraryConstructor();
export default libraryConstructor;