import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { CloseOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Select, Spin, Tooltip } from 'antd';
import EditableTable from '@/components/EditableTable';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { PinMapRow } from '@/services/Cascade/helper/setupClass';
import componentSetting from '../../../../services/Cascade/helper/compSettingHelper';
import PinMapPanel from '../../libraryPanel/PinMapPanel'
import { getAllCascadeComponents, replacePinMapTable, matchPinMap } from '../../../../services/Cascade/helper/setupData';
import PinMapStore from '../../../../services/Cascade/library/pinMapHelper';
import { DC, PCB, PCB_PRE_LAYOUT } from '../../../../constants/treeConstants';
import designConstructor from '../../../../services/helper/designConstructor';
import preLayoutData from '../../../../services/Cascade/prelayout/preLayoutData';
import { IGNORE, PMIC } from '../../../../constants/componentType';
import auroraDBJson from '../../../../services/Designs/auroraDbData';
import PinMapFileSelect from '../../libraryPanel/PinMapSelectFile';
import { deleteCurrPinInfo } from '../../../../services/Cascade/helper/pmicPinMapHelper';
import _ from 'lodash';
import { updateCascadePart } from '../../../../services/Cascade/library';
import './index.css';

const { Option } = Select;
const columns = [{
  title: 'Components',
  dataIndex: 'components',
  width: '25%',
  textWrap: 'word-break',
  ellipsis: true,
}, {
  title: 'Part Name',
  dataIndex: 'partNumber',
  width: '25%',
  sorter: (a, b) => a && a.part && b && b.part && a.part.localeCompare(b.part),
  textWrap: 'word-break',
  ellipsis: true,
}, {
  title: 'PMIC Pin Map',
  dataIndex: 'libraryName',
  width: '25%',
  textWrap: 'word-break',
  ellipsis: true,
}, {
  title: 'Nets',
  dataIndex: 'nets',
  width: '25%',
  textWrap: 'word-break',
  ellipsis: true,
}];
const NET = 'net', TABLE = 'table';
class rootPCBPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 1000,
      maxHeight: 1000,
      pcbId: "",
      pinMapTable: [],
      pmics: [],
      components: [],
      libraryInfo: {},
      libraryVisible: false,
      pinMapList: props.pinMapList,
      save: false,
      loading: false,
      tip: ''
    };
    this.dialogRoot = document.getElementById('root');
    this.pinMap = {}
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 1000),
      maxHeight: getPanelMaxHeight(offset, 1000),
    })
  }

  async componentDidMount() {
    window.addEventListener('resize', this.resize);
    const { root } = this.props;
    this.initColumns();
    const pcbId = root.pcbId;
    const pinMap = await this.props.getCascadePinMap();
    this.pinMap = pinMap;

    this.setState({
      pcbId: pcbId || '',
      pinMapTable: this.checkLibraryFile(root.pinMapTable, pcbId),
    }, async () => {
      setTimeout(async () => {
        const pmics = pcbId ? await this.getPCBPMICs(pcbId) : [];
        const components = pcbId ? getAllCascadeComponents({ pcbId }) : [];
        const _components = [...components.values()].filter(component => component.type === IGNORE)
        this.setState({
          pmics,
          components: _components
        }, () => {
          this.resize();
          this.setRowNets();
        })
      }, 300)
    })
  }

  componentDidUpdate = (prevProps) => {
    const { selectId } = this.state;
    const { checkbox } = this.props;
    if (!checkbox && prevProps.checkbox && selectId) {
      this.setNewPmicLibrary()
    }
  }

  checkLibraryFile = (pinMapTable = [], pcbId) => {
    const { pinMapList = [], } = this.state;
    const options = pinMapList.map(item => item.id);
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      return pinMapTable
    }
    const _pinMapTable = pinMapTable.map(record => {
      if (record.type === 'buckConverter') {
        if (!record.customPinMap && (!record.libraryId || !options.includes(record.libraryId))) {
          return { ...record, libraryId: "", libraryName: "", nets: [], type: "" }
        }
      } else {
        if (!record.customPinMap) {
          const map = this.pinMap[record.partNumber] || {};
          const find = Object.values(map.pinMap || []).find(item => item.libraryId === record.libraryId);
          if (find) {
            const prevPinTable = record.pinTable;
            const pinTable = find.pinTable;
            const oldLocal = !_.isEqual(prevPinTable, pinTable);
            return { ...record, libraryName: find.name, pinTable: oldLocal ? prevPinTable : pinTable, oldLocal, customPinMap: oldLocal }
          } else {
            return { ...record, libraryId: "", libraryName: "", customPinMap: true }
          }
        }
      }
      return record
    })
    return _pinMapTable
  }

  setRowNets = () => {
    const { pinMapTable = [] } = this.state;
    let _pinMapTable = JSON.parse(JSON.stringify(pinMapTable));
    _pinMapTable.forEach(record =>
      this.getAllNetsFromMap(record))
  }

  closeModal = () => {
    const { pcbId, pinMapTable, save } = this.state;
    this.props.saveRootInfo({ pcbId, pinMapTable }, save);
    this.props.closeModal()
  }

  initColumns = () => {
    columns[1].render = (text, record) => {
      return <div title={text}>
        <span className='cascade-root-pcb-library-value' style={{ width: '100%' }}>
          {text}
        </span>
      </div>
    }

    columns[1].onCell = (record) => {
      const { pmics = [] } = this.state;
      return {
        edit: 'select',
        options: pmics.map(item => item.part),
        record,
        text: record.partNumber || '',
        dataIndex: 'partNumber',
        allowClear: true,
        handleClear: () => this.savePartName({ ...record, partNumber: '' }),
        handleSave: this.savePartName,
        getPopupContainer: document.getElementById('cascade-root-pcb-dialog')
      }
    }

    columns[0].render = (text = [], record) => {
      return <div title={text}>
        <span className='cascade-root-pcb-library-value' style={{ width: '100%' }}>
          {text.join(', ')}
        </span>
      </div>
    }

    columns[0].onCell = (record) => {
      const { components, pinMapTable } = this.state;
      const { partNumber, id } = record;
      const existComps = pinMapTable.map(item => item.id === id ? [] : item.components).flat(2);
      let options = components.filter(comp => !existComps.includes(comp.name) && (!partNumber || partNumber === comp.partName)).map(comp => comp.name).sort()
      return {
        record: { components: [], ...record },
        edit: 'aurora-select',
        options: options,
        dataIndex: "components",
        selectMode: 'multiple',
        onChange: (list, record, setFieldsValue) => this.selectComponents(list, record, setFieldsValue),
        clearSelected: (value, record, setFieldsValue) => this.clearComponents(value, record, setFieldsValue),
        getPopupContainer: document.getElementById('cascade-root-pcb-dialog')
      }
    }

    columns[2].render = (text, record) => {
      const { pcbId, pmics = [] } = this.state;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      if (isPreLayout) {
        return <span className="cascade-table-row-disabled"></span>
      }
      const part = pmics.find(pmic => pmic.part === record.partNumber)
      if (part && part.type === 'buckConverter') {
        return <Fragment>
          <div className='cascade-root-pcb-library-cell'>
            <span className='cascade-root-pcb-library-value'>
              {text}
            </span>
            {record && record.libraryId ? <EditOutlined
              className='cascade-root-pcb-edit-icon'
              onClick={(e) => this.editPinMap(e, record)} /> : null}
          </div>
        </Fragment>
      }
      if (record.oldLocal) {
        return <Fragment>
          <div className='cascade-root-pcb-library-cell'>
            <Tooltip classNames={{ root: 'aurora-warning-tooltip' }} title="The Pin Map in the selected Library has been updated. The currently used Pin Map is the one before the update.">
              <span className='cascade-root-pcb-library-value'>
                <span className='cascade-tooltip-warning'>{text}</span>
              </span>
            </Tooltip>
          </div>
        </Fragment>
      }
      const noOutput = record.customPinMap && record.pinTable ? !record.pinTable.length || !record.pinTable.some(pin => pin.output && pin.output.length) ? true : false : false;
      if (noOutput) {
        return <Fragment>
          <div className='cascade-root-pcb-library-cell'>
            <Tooltip classNames={{ root: 'aurora-error-tooltip' }} title="Not set output pins.">
              <span className='cascade-root-pcb-library-value'>
                <span className='cascade-tooltip-failed'>{text}</span>
              </span>
            </Tooltip>
          </div>
        </Fragment>
      }
      return <Fragment>
        <div className='cascade-root-pcb-library-cell'>
          <span className='cascade-root-pcb-library-value'>
            {record.customPinMap ? record.pinTable && record.pinTable.length ? record.pinTable.map(pin => pin.output).flat().map(pin => pin.pinName).join(', ') : "Custom Pin Map" : text}
          </span>
        </div>
      </Fragment>
    }

    columns[2].onCell = (record) => {
      const { pcbId, pmics = [], pinMapList } = this.state;
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      if (isPreLayout) {
        return {
          edit: false
        }
      }
      const text = record.customPinMap ? record.pinTable && record.pinTable.length ? record.pinTable.map(pin => pin.output).flat().map(pin => pin.pinName).join(', ') : "Custom Pin Map" : record['libraryName'];
      const part = pmics.find(pmic => pmic.part === record.partNumber)
      if (part && part.type === 'buckConverter') {
        const options = pinMapList.map(item => item.name).sort();
        return record.partNumber ? {
          edit: 'select',
          options,
          record,
          text: '',
          dataIndex: 'libraryName',
          handleSave: this.savePinMapLibrary,
          dropdownRender: this.pinMapDropdown,
          getPopupContainer: document.getElementById('cascade-root-pcb-dialog'),
          popupMatchSelectWidth: 500
        } : {
          edit: false
        }
      }

      return record.partNumber ? {
        edit: true,
        text,
        record,
        customInput: PinMapFileSelect,
        dataIndex: 'libraryName',
        designId: pcbId,
        pinMap: this.pinMap,
        canUseLibrary: part ? true : false,
        changePinMapSelect: this.changePinMapSelect,
        changePinMap: this.changePinMap,
        savePinTable: this.savePinTable,
        savePinTableByNets: this.savePinTableByNets,
        closePinMapSelect: this.closePinMapSelect,
        openPartSelected: this.openPartSelected,
        selectedComps: record.components || [],
        NET,
        TABLE
      } : {
        edit: false
      }
    }

    columns[3].render = (text = [], record) => {
      return (
        <Fragment>
          <div className='cascade-root-pcb-library-cell'>
            <span className='cascade-root-pcb-library-value'>
              {text.map(t => t.net).join(', ')}
            </span>
          </div>
          <CloseOutlined
            className='cascade-root-pcb-edit-icon cascade-root-pin-edit-icon'
            onClick={(e) => this.delRow(e, record)} />
        </Fragment>
      );
    }

    columns[3].onCell = (record) => {
      const { pcbId } = this.state;
      const { id } = record;
      const netList = this.state[id] || [];
      const options = netList.filter(item => item.net !== 'NONET').map(item => ({ value: item.net, key: item.net, title: `${item.net}(${item.pinNames.join(', ')})` }))
      const values = record.nets ? record.nets.map(item => item.net) : [];
      const isPreLayout = designConstructor.isPreLayout(pcbId);
      return record.libraryId || record.customPinMap || isPreLayout ? {
        edit: 'select',
        selectMode: 'multiple',
        popupMatchSelectWidth: true,
        options,
        record: { ...record, nets: values },
        dataIndex: 'nets',
        handleSave: (feedback) => this.saveNets(feedback),
        clearSelected: this.clearSelectedNets,
        getPopupContainer: document.getElementById('cascade-root-pcb-dialog')
      } : {
        edit: false
      }
    }
  }

  getPCBPMICs = async (pcbId) => {
    if (!pcbId) {
      return [];
    }
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      const preData = await preLayoutData.getPreLayout(pcbId);
      const { content = {} } = preData;
      const pmics = (content.components || []).filter(item => item.type === PMIC).map(item => ({ part: item.name }));
      return pmics
    }
    const setting = await componentSetting.getSetting({ designId: pcbId });
    await auroraDBJson.getAuroraJson(pcbId, setting);
    const { compPrefixLib } = setting;
    const { discreteBuckConverter = [], linearRegulator = [], linearSwitch = [], specialized = [], switchingRegulator = [] } = compPrefixLib;
    return [...linearRegulator.map(item => ({ part: item })),
    ...linearSwitch.map(item => ({ part: item })),
    ...specialized.map(item => ({ part: item })),
    ...switchingRegulator.map(item => ({ part: item })),
    ...discreteBuckConverter.map(item => item.join(' - ')).map(item => ({ part: item, type: 'buckConverter' }))
    ]
  }

  addRow = (e) => {
    e && e.stopPropagation();
    const { pinMapTable } = this.state;
    this.setState({
      pinMapTable: [...pinMapTable, new PinMapRow()]
    })
  }

  delRow = (e, record) => {
    e && e.stopPropagation();
    const { pinMapTable } = this.state;
    this.setState({
      pinMapTable: pinMapTable.filter(item => item.id !== record.id)
    })
  }

  selectPCB = async (id) => {
    this.setState({
      loading: true,
      pcbId: id,
      tip: 'Loading PCB...'
    }, async () => {
      setTimeout(async () => {
        const pmics = await this.getPCBPMICs(id);
        const components = getAllCascadeComponents({ pcbId: id });
        const _components = [...components.values()].filter(component => component.type === IGNORE);
        this.setState({
          pmics,
          save: true,
          components: _components,
          tip: ''
        }, () => {
          setTimeout(async () => {
            const isPreLayout = designConstructor.isPreLayout(id);
            let table = [];
            if (!isPreLayout) {
              const { pinMapTable, pinMapList } = this.state;
              let _pinMapTable = await replacePinMapTable(_components, pinMapTable, id, pinMapList)
              for (let row of _pinMapTable) {
                await this.getAllNetsFromMap(row)
                let nets = row.libraryId ? row.nets : [];
                if (row.nets && row.nets.length) {
                  const netNames = nets.map(item => item.net)
                  const newNets = row.nets.filter(item => netNames.includes(item.net));
                  nets = newNets.length ? newNets : nets;
                }
                table.push({ ...row, nets })
              }
            }
            this.setState({
              loading: false,
              pinMapTable: table.length ? table : [new PinMapRow()]
            })
          }, 200)
        })
      }, 300)
    })
  }

  selectComponents = async (list = [], record, setFieldsValue) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(async () => {
        const { id } = record;
        const { pinMapTable, components: allComponents, pmics } = this.state;
        const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
        const findIndex = _dataSource.findIndex(item => item.id === id)
        if (findIndex > -1) {
          let components = record.components || [];
          if (list.length === 1 && components.find(net => net === list[0])) {
            components = components.filter(net => net !== list[0]);
          } else {
            components = [...new Set([...components, ...list])];
            if (!_dataSource[findIndex].partNumber && list[0]) {
              const compName = list[0];
              const comp = allComponents.find(item => item.name === compName);
              _dataSource[findIndex].partNumber = comp.partName;
              const isPMIC = pmics.map(item => item.part).includes(comp.partName) ? true : false
              if (!isPMIC) {
                _dataSource[findIndex].nets = [];
                _dataSource[findIndex].customPinMap = NET;
                _dataSource[findIndex].pinTable = []
              }
              if (isPMIC) {
                const partInfo = this.pinMap[comp.partName];
                if (partInfo && partInfo.pinMap) {
                  const findMap = Object.values(partInfo.pinMap)[0];
                  if (findMap) {
                    _dataSource[findIndex].libraryId = findMap.libraryId;
                    _dataSource[findIndex].libraryName = findMap.name;
                  }
                }
              }
              await this.getAllNetsFromMap({ id, partNumber: comp.partName, libraryId: _dataSource[findIndex].libraryId, type: _dataSource[findIndex].type, components })
              _dataSource[findIndex].nets = _dataSource[findIndex].nets || []
            }
          }
          if (setFieldsValue) {
            setFieldsValue({ components })
          }
          _dataSource[findIndex].components = components
          this.setState({
            pinMapTable: _dataSource,
            save: true,
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
        }

      }, 300)
    })
  }

  clearComponents = (value, record) => {
    const { id } = record;
    const { pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const findIndex = _dataSource.findIndex(item => item.id === id)
    if (findIndex > -1) {
      let components = record.components || [];
      components = components.filter(comp => comp !== value);
      _dataSource[findIndex].components = components
      this.setState({
        pinMapTable: _dataSource,
        save: true,
      })
    }
  }

  savePartName = async (record, p) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(async () => {
        const { pinMapTable, pmics } = this.state;
        const { partNumber, id, libraryId } = record;
        const pinMap = JSON.parse(JSON.stringify(pinMapTable));
        const index = pinMap.findIndex(item => item.id === id);
        const findPart = pmics.find(item => item.part === partNumber)
        if (index > -1) {
          pinMap[index].partNumber = partNumber;
          pinMap[index].type = findPart ? findPart.type || "" : "";
          pinMap[index].components = [];
          if (!partNumber) {
            pinMap[index].libraryId = "";
            pinMap[index].libraryName = "";
            pinMap[index].nets = [];
            pinMap[index].type = "";
          } else if (!libraryId) {
            const partInfo = this.pinMap[partNumber];
            if (partInfo && partInfo.pinMap) {
              const findMap = Object.values(partInfo.pinMap)[0];
              if (findMap) {
                pinMap[index].libraryId = findMap.libraryId;
                pinMap[index].libraryName = findMap.name;
              }
            }
            await this.getAllNetsFromMap({ id, partNumber, libraryId: pinMap[index].libraryId, type: pinMap[index].type, components: [], customPinMap: false })
            pinMap[index].nets = pinMap[index].nets || []
          }
          this.setState({
            pinMapTable: [...pinMap],
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
        }
      }, 300)
    })
  }

  editPinMap = (e, record) => {
    e && e.stopPropagation();
    this.setState({
      libraryInfo: { ...record },
      libraryVisible: true
    })
  }

  pinMapDropdown = (menu, record) => {
    return (
      <div>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={(e) => this.addPinMap(e, record)}
        >
          <PlusOutlined /> Add Pin Map
        </div>
      </div>
    );
  }

  addPinMap = (e, record) => {
    e && e.stopPropagation();
    this.setState({
      libraryInfo: { ...record, libraryId: '', libraryName: '' },
      libraryVisible: true
    })
  }

  savePinMapLibrary = async (record) => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(async () => {
        const { libraryName, partNumber, id, components, type } = record;
        const { pinMapTable, pinMapList } = this.state;
        const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
        const find = pinMapList.find(item => item.name === libraryName);
        const findIndex = _dataSource.findIndex(item => item.id === id)
        if ((find || libraryName === '') && findIndex > -1) {
          await this.getAllNetsFromMap({ id, partNumber, libraryId: find.id, type, components })
          const nets = _dataSource[findIndex].nets || []
          _dataSource[findIndex] = {
            partNumber,
            libraryName,
            libraryId: libraryName === '' ? '' : find.id,
            id,
            type,
            components,
            nets
          }
          this.setState({
            pinMapTable: _dataSource,
            save: true,
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
        }
      }, 100)
    })
  }

  getAllNetsFromMap = async ({ id, partNumber, libraryId, type, components: selectedComps, pinTable = [], customPinMap, nets = [] }) => {
    const { pcbId, components } = this.state;
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      const preData = await preLayoutData.getPreLayout(pcbId);
      const { content = {} } = preData;
      const { components = [], nets = [] } = content;
      const comp = components.find(item => item.name === partNumber);
      let preLayoutNetList = []
      if (comp) {
        const _nets = [...comp.pins.values()].filter(pin => {
          const net = nets.find(n => pin.net === n.name);
          return !net.isGnd
        }).map(pin => ({ net: pin.net, pinNames: [pin.pin] }))
        preLayoutNetList = _nets.filter(item => item.net !== 'NONET');
        this.setState({
          [id]: preLayoutNetList
        }, () => {
          this.filterNotExsitNets(id)
        })
      }
      return preLayoutNetList;
    }
    // const pinMap = await compPinMap.getPinMapData(pcbId);
    // const findMap = type === 'buckConverter' ? pinMap.find(item => item.partNumber.split(' -').every(comp => partNumber.includes(comp))) : pinMap.find(item => item.partNumber === partNumber);
    // console.log(partNumber, pinMap, findMap, type)
    let netList = [];
    if (type === 'buckConverter') {
      const res = await PinMapStore.getPinMapLibrary({ libraryId: libraryId, partNumber, type: type }, pcbId, 'pinName');
      const data = res && res.config ? res.config.pinTable : [];
      const outputs = data.map(item => item.output).flat(2);
      const comps = partNumber.split(' - ');
      const _comps = [...getAllCascadeComponents({ pcbId }).values()].filter(item => comps.includes(item.name))
      const pins = _comps.map(c => [...c.pins.values()].map(pin => ({ ...pin, pinName: `${c.name}_${pin.pinName}` }))).flat(2);
      const _pins = pins.filter(pin => outputs.includes(pin.pinName));
      _pins.forEach(pin => {
        const findIndex = netList.findIndex(n => n.net === pin.net);
        if (findIndex < 0) {
          netList.push({ net: pin.net, pinNames: [pin.pinName] });
        } else {
          netList[findIndex].pinNames.push(pin.pinName)
        }
      })
      this.setState({
        [id]: netList.filter(item => item.net !== 'NONET')
      }, () => {
        this.filterNotExsitNets(id)
      })
    } else {
      let _pinTable = customPinMap ? pinTable : this.getPinTableFromMap(partNumber, libraryId);
      const outputs = _pinTable.map(item => item.output).flat(2).map(pin => pin.pinName);
      const comp = components.find(item => item.partName === partNumber && (!selectedComps.length || selectedComps.includes(item.name)));
      if (comp) {
        const pins = [...comp.pins.values()].filter(pin => outputs.includes(pin.pinName));
        pins.forEach(pin => {
          const findIndex = netList.findIndex(n => n.net === pin.net);
          if (findIndex < 0) {
            netList.push({ net: pin.net, pinNames: [pin.pinName] });
          } else {
            netList[findIndex].pinNames.push(pin.pinName)
          }
        })
        this.setState({
          [id]: netList.filter(item => item.net !== 'NONET')
        }, () => {
          this.filterNotExsitNets(id)
        })
      }
    }
    return nets.length ? netList.filter(item => nets.some(net => net.net === item.net)) : netList.filter(item => item.net !== 'NONET');
  }

  getPinTableFromMap = (partNumber, libraryId) => {
    const pinMap = this.pinMap[partNumber];
    if (!pinMap || !pinMap.pinMap || !pinMap.pinMap[libraryId]) {
      return []
    }
    return pinMap.pinMap[libraryId].pinTable
  }

  saveNets = (feedback) => {
    const { nets, id } = feedback;
    const { pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const findIndex = _dataSource.findIndex(item => item.id === id);
    const _nets = this.state[id] ? this.state[id].filter(n => nets.includes(n.net)) : [];
    if (findIndex > -1) {
      _dataSource[findIndex].nets = _nets;
      this.setState({
        pinMapTable: _dataSource,
        save: true
      })
    }
  }

  changePinMapSelect = async (e, record) => {
    const { pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const id = record.id;
    const findIndex = _dataSource.findIndex(item => item.id === id);
    if (findIndex > -1) {
      _dataSource[findIndex].customPinMap = e.target.value;
      this.setState({
        pinMapTable: _dataSource,
        save: true
      })
    }
  }

  changePinMap = (value, record) => {
    const { pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const id = record.id;
    const findIndex = _dataSource.findIndex(item => item.id === id);
    if (findIndex > -1) {
      _dataSource[findIndex].libraryId = value;
      this.setState({
        pinMapTable: _dataSource,
        save: true
      })
    }
  }

  savePinTable = (table, record) => {
    const { pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const id = record.id;
    const findIndex = _dataSource.findIndex(item => item.id === id);
    if (findIndex > -1) {
      const _pinTable = deleteCurrPinInfo(table);
      _dataSource[findIndex].pinTable = _pinTable;
      this.setState({
        pinMapTable: _dataSource,
        save: true
      })
    }
  }

  savePinTableByNets = (value, compName, record) => {
    const { pcbId, pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const id = record.id;
    const findIndex = _dataSource.findIndex(item => item.id === id);
    if (findIndex > -1) {
      const component = auroraDBJson.getComponent(pcbId, compName);
      if (component) {
        const pinTable = [
          ...value.map((net, index) => {
            const pins = [...component.pins.values()].filter(item => item.net === net)
            const output = pins.map(pin => ({ pin: pin.pin, pinName: pin.pinName }))
            return { index: index, output, input: [], sense: [], gndSense: [], ground: [] }
          }),
          { index: 'extra', output: [], input: [], sense: [], gndSense: [], ground: [] }
        ]
        _dataSource[findIndex].pinTable = pinTable;
        this.setState({
          pinMapTable: _dataSource,
          save: true
        })
      }
    }
  }

  filterNotExsitNets = (id) => {
    const { pinMapTable } = this.state;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const findIndex = _dataSource.findIndex(item => item.id === id);
    if (findIndex > -1) {
      const netList = this.state[id] ? this.state[id].map(n => n.net) : [];
      const prevNetsLength = _dataSource[findIndex].nets.length
      _dataSource[findIndex].nets = _dataSource[findIndex].nets.filter(net => netList.includes(net.net));
      const netsLength = _dataSource[findIndex].nets.length
      if (prevNetsLength !== netsLength) {
        this.setState({
          pinMapTable: _dataSource,
          save: true
        })
      }
    }
  }

  closeWithoutSave = () => {
    this.setState({
      libraryVisible: false,
    })
  }

  closePinMapPanel = (res, record, saveFile) => {
    const { pinMapList, save } = this.state;
    this.props.updateLibraryMenu();
    const _pinMapList = [...pinMapList.filter(item => item.id !== res.id), res];
    this.setState({
      libraryVisible: false,
      pinMapList: _pinMapList,
      save: saveFile ? true : save
    }, () => {
      this.savePinMapLibrary({ ...record, libraryId: res.id, libraryName: res.name });
    })
  }

  closePinMapSelect = async (record) => {
    const netList = await this.getAllNetsFromMap(record);
    const { customPinMap, id } = record;
    if (customPinMap) {
      const { pinMapTable, save } = this.state;
      const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
      const findIndex = _dataSource.findIndex(item => item.id === id);
      if (findIndex > -1) {
        _dataSource[findIndex].nets = netList;
        _dataSource[findIndex].oldLocal = false;
        const isEqual = _.isEqual(_dataSource[findIndex].pinTable, record.pinTable);
        this.setState({
          pinMapTable: _dataSource,
          save: !isEqual ? true : save
        })
      }
    } else {
      if (record.oldLocal) {
        const { pinMapTable } = this.state;
        const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
        const findIndex = _dataSource.findIndex(item => item.id === id);
        _dataSource[findIndex].oldLocal = false;
        const map = this.pinMap[record.partNumber] || {};
        const find = Object.values(map.pinMap || []).find(item => item.libraryId === record.libraryId);
        if (find) {
          _dataSource[findIndex].pinTable = find.pinTable;
          const netList = await this.getAllNetsFromMap(_dataSource[findIndex]);
          _dataSource[findIndex].nets = netList;
        }
        this.setState({
          pinMapTable: _dataSource,
          save: true
        })
      }
    }
  }

  openPartSelected = async (record) => {
    const { id, libraryId, partNumber } = record;
    const { pcbId } = this.state;
    if (!this.pinMap || !this.pinMap[partNumber]) {
      const data = {
        id: "",
        designId: pcbId,
        partName: partNumber,
        value: "",
        type: ""
      }
      await updateCascadePart(data)
    }
    this.props.openPartPanel(partNumber, null, libraryId);
    this.setState({
      selectId: id
    })
  }

  setNewPmicLibrary = async () => {
    const { selectId, pinMapTable } = this.state;
    const { checkedPinMap } = this.props;
    const pinMap = await this.props.getCascadePinMap();
    this.pinMap = pinMap;
    const _dataSource = JSON.parse(JSON.stringify(pinMapTable));
    const findIndex = _dataSource.findIndex(item => item.id === selectId);
    if (findIndex > -1) {
      const map = pinMap[_dataSource[findIndex].partNumber] || {};
      const find = Object.values(map.pinMap || []).find(item => item.libraryId === checkedPinMap);
      _dataSource[findIndex].libraryId = find ? checkedPinMap : "";
      _dataSource[findIndex].libraryName = find ? find.name : "";
      _dataSource[findIndex].pinTable = find ? find.pinTable : [];
      _dataSource[findIndex].oldLocal = false;
      const netList = await this.getAllNetsFromMap(_dataSource[findIndex]);
      _dataSource[findIndex].nets = netList;
      this.setState({
        pinMapTable: _dataSource,
        save: true,
        selectId: ""
      })
    }
  }

  render() {
    const { maxWidth, maxHeight, pinMapTable, pcbId, libraryVisible, libraryInfo, pinMapList, loading, tip } = this.state;
    const { designList } = this.props;
    const _designList = designList.filter(item => item.dataType === PCB || (item.dataType === PCB_PRE_LAYOUT && item.content && item.content.electric === DC))
    const content = (
      <Fragment>
        <Panel
          className='cascade-power-select-panel'
          title={<div className='cascade-power-select-title'>Root PCB</div>}
          onCancel={this.closeModal}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { defaultWidth: 1000 })}
          maxHeight={maxHeight}
          position='panel-center'
          draggable
          minHeight={200}
          minWidth={500}
          defaultTop={200}
          resizeEnd={this.resize}
        >
          <div className="cascade-root-pcb-content">
            <div className="pcb-select">
              <span>PCB</span>
              <Select
                size="small"
                popupClassName='cascade-select-dropdown-list'
                value={pcbId}
                onChange={this.selectPCB}
              >
                {_designList.map(item => <Option key={item.id}>{item.name}</Option>)}
              </Select>
            </div>
            <div className="cascade-setup-border cascade-root-pcb-table-border">
              <span className="font-bold cascade-setup-title-color">PMIC</span>
              <PlusCircleOutlined className='cascade-imp-icon' onClick={(e) => this.addRow(e)} />
              <Spin spinning={loading} tip={tip ? tip : "Updating"}>
                <div className="space-10">
                  <EditableTable
                    rowKey={(record) => record.id}
                    columns={columns}
                    size="small"
                    dataSource={pinMapTable}
                    className="cascade-root-pcb-pin-map-table"
                    tablePadding={true}
                  />
                </div>
              </Spin>
            </div>
          </div>
        </Panel>
        <div id="cascade-root-pcb-dialog"></div>
        {libraryVisible && <PinMapPanel
          designId={pcbId}
          libraryInfo={libraryInfo}
          pinMapList={pinMapList}
          closeModal={this.closePinMapPanel}
          closeWithoutSave={this.closeWithoutSave}
        />}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}


export default rootPCBPanel;
