import { HIMALAYAS } from "../../../constants/pageType";
import {
  getHimalayasLibraryList,
  delHimalayasLibrary,
  getHimalayasLibraryDetail,
  uploadHimalayasLibrary
} from "../../api/Himalayas/library";
import apiProcess from "../../api/utility";
import { getFolderChildren, updateLibraryFile } from "../../api/v2/libraryCtrl";

/**
 * Get library list
 * 2023/05/22
 * @return Promise */
function getLibraryList() {
  return apiProcess(getHimalayasLibraryList);
}

/**
 * delete library by id
 * 2023/05/22
 * @param {string} libraryId
 * @return Promise */
function delLibrary(libraryId) {
  return apiProcess(delHimalayasLibrary, libraryId);
}

/**
 * get library detail content by id
 * 2023/05/23
 * @param {string} libraryId
 * @return Promise */
function getLibraryDetail(libraryId) {
  return apiProcess(getHimalayasLibraryDetail, libraryId, false, false, true);
}

/**
 * create library ,upload file
 * 2023/05/25
 * @param {files} files
 * @param {string} fileType //file / data
 * @param {string} typeName //spice_parameters / soc_spice / soc_wrapper / pvt / pcb_subckt
 * @return Promise */
function createLibrary({ files, fileType, libraries, typeName, metadata, config }) {
  return apiProcess(uploadHimalayasLibrary, { files, fileType, typeName, libraries, metadata, config })
}

/**
 * save edited library
 * 2023/06/16
 * @param {string} libraryId
 * @param {string} type ->library type : pcb_subckt / soc_wrapper / soc_spice
 * @param {string} vdeNum -> "1" / "2"
 * @return Promise */
function saveEditedLibraryPromise({ libraryId, type, vdeNum }) {
  let formData = new FormData();
  formData.append("libraryId", libraryId);
  formData.append("type", type);
  formData.append("vdeNum", vdeNum);
  return apiProcess(updateLibraryFile, { product: "himalayas", formData })
}

/**
 * get library children list by folder id
 * 2023/10/10
 * @param {string} id folder id
 * @return Promise */
function getLibraryFolderChildren(id) {
  return apiProcess(getFolderChildren, { productType: HIMALAYAS, id })
}

export {
  getLibraryList,
  delLibrary,
  getLibraryDetail,
  createLibrary,
  saveEditedLibraryPromise,
  getLibraryFolderChildren
}