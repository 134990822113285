import React, { Component, Fragment } from 'react';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Input, Checkbox, Collapse, Tooltip } from 'antd';
import {
  AMI_INTERFACE_TYPE_LIST,
  EYE_PROBE,
  PRBS,
  ERROR_BIT_RATE,
  ENCODER,
  AMI_ENCODER_LIST,
  AMI_SIMULATION,
  AMI_SIM_MODE_LIST,
  AMI_ADSOPTION,
  TOLERANCE_LIST,
  TOLERANCE,
  ADSOPTION,
  BIT_RATE,
  getBitRate,
  AMI_HDMI_TYPE_LIST,
  CLOCK_BIT_RATE,
  TMDS_14,
  TMDS_2,
  NOTPPU,
  ENFORCE_PASSIVITY,
  EYEMASK,
  EXTRAPOLATE_IN_BIT_BY_BIT_MODE,
  ERROR_BIT_RATE_LIST,
  PROBERX,
  PROBETX,
  BIT_BY_BIT
} from '@/services/Andes_v2/AMIModelHelper';
import { PCIE, HDMI, CPHY } from '../../../services/PCBHelper/constants';
import libraryConstructor from '@/services/Andes_v2/library/libraryConstructor';
import { ADS_OPTIONS, EYE_MASK } from '../../../constants/libraryConstants';
import TagsInput from '../../../components/TagsInput';
import './index.css';
import { SetupInputGroupItem } from './SetupComponents/SetupInputGroup';
import { EYEPROBE, getModelSetupDataList } from '../../../services/Andes_v2/AMIModelHelper';

const { Option } = Select;

class AMISimBasicSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getSubOptions = (mode, list) => {
    const findItem = list.find(item => item.key === mode);
    if (!findItem) return;
    return findItem.children;
  }

  render = () => {
    const { adsConfig: { prbs = {}, eyeProbe = {}, encoder, simulation = {}, ymlOptionLibraryId, ymlOptionLibraryName, symbolRate }, serdesType } = this.props;

    const subSimSOptions = this.getSubOptions(simulation.mode, AMI_SIM_MODE_LIST);
    const { bitRate, bitRateUnit } = getBitRate(prbs.bitRate);
    const { bitRate: symbolRateValue, bitRateUnit: symbolRateUnit } = getBitRate(symbolRate);
    const { bitRate: clockBitRate, bitRateUnit: clockBitRateUnit } = getBitRate(prbs.clockBitRate, true);
    const adsOptionLibraryList = libraryConstructor.getLibraryValues(ADS_OPTIONS);
    const simulationItems = [{
      label: <div className="ami-model-collapse-title">
        Simulation Settings
      </div>,
      key: "simulation_setup",
      children: <Fragment>
        {serdesType === PCIE ? this.getSelectComponent({
          title: "PCIe Protocol Type",
          type: "type",
          parentType: PRBS,
          value: prbs.type,
          label: prbs.type,
          list: AMI_INTERFACE_TYPE_LIST
        }) : null}
        {serdesType === HDMI ? this.getSelectComponent({
          title: "HDMI Mode",
          type: "type",
          parentType: PRBS,
          value: prbs.type,
          label: prbs.type,
          list: AMI_HDMI_TYPE_LIST
        }) : null}
        {serdesType === CPHY ? this.getInputComponent({
          title: "Symbol Rate",
          type: "symbolRate",
          value: symbolRateValue,
          unit: symbolRateUnit
        }) : this.getInputComponent({
          title: "Data Rate",
          type: BIT_RATE,
          parentType: PRBS,
          value: bitRate,
          unit: bitRateUnit
        })}
        {[TMDS_14, TMDS_2].includes(prbs.type) ? this.getInputComponent({
          title: "Clock Data Rate",
          type: CLOCK_BIT_RATE,
          parentType: PRBS,
          value: clockBitRate,
          unit: clockBitRateUnit
        }) : null}
        {serdesType !== CPHY ? this.getSelectComponent({
          title: "Encoder",
          type: ENCODER,
          value: encoder,
          list: AMI_ENCODER_LIST
        }) : null}
        {this.getSelectComponent({
          title: "Analysis Mode",
          type: "mode",
          parentType: AMI_SIMULATION,
          value: simulation.mode,
          list: serdesType === CPHY ? AMI_SIM_MODE_LIST.filter(item => item.key === BIT_BY_BIT) : AMI_SIM_MODE_LIST
        })}
        {subSimSOptions && subSimSOptions.length ?
          subSimSOptions.map(item => {
            return <Fragment key={item.key}>
              {this.getInputComponent({
                title: item.title,
                type: item.key,
                parentType: AMI_SIMULATION,
                value: simulation[item.key],
                className: "ami-advanced-sub-item"
              })}</Fragment>
          })
          : null}
        {/*  {this.getCheckBoxComponent({
        title: "Enable ultra low BER (<1e-16) simulation",
        type: ENABLE_ULTRA_LOW_BER_SIMULATION,
        parentType: AMI_SIMULATION,
        value: simulation[ENABLE_ULTRA_LOW_BER_SIMULATION],
      })} */}
        {/* tolerance */}
        {this.getSwitchComponent({
          title: "Enforce Passivity",
          type: ENFORCE_PASSIVITY,
          parentType: AMI_SIMULATION,
          value: simulation.enforcePassivity,
        })}
        {this.getSelectComponent({
          title: "Convolution Tolerance",
          type: TOLERANCE,
          parentType: AMI_SIMULATION,
          value: simulation.tolerance,
          list: TOLERANCE_LIST
        })}
        {serdesType !== CPHY ? this.getInputComponent({
          title: "Number of time points per UI",
          type: NOTPPU,
          parentType: AMI_SIMULATION,
          value: simulation.advancedSetting.notppu,
        }) : null}
        {serdesType !== CPHY ? this.getSelectComponent({
          title: "ADS Option File",
          type: ADSOPTION,
          parentType: AMI_ADSOPTION,
          value: ymlOptionLibraryId,
          label: ymlOptionLibraryName,
          isExist: adsOptionLibraryList.findIndex(item => item.id === ymlOptionLibraryId) > -1 || !ymlOptionLibraryName ? true : false,
          list: adsOptionLibraryList.map(item => { return { key: item.id, title: item.name } }),
          allowClear: true,
          tipIcon: <Tooltip title='Priority: Simulation Setup > YML file. If an option is duplicated in the YML file and the Simulation Setup, the setting in the Simulation Setup takes priority.' overlayClassName='aurora-tooltip'>
            <InfoCircleOutlined className="ami-info-circle-icon" />
          </Tooltip>,
          existIcon: <Tooltip
            title={<Fragment>
              {`File ${ymlOptionLibraryName} has been deleted, the file is invalid.`}
            </Fragment>}
            overlayClassName='aurora-tooltip'
          ><QuestionCircleOutlined className='aurora-file-check-icon' onClick={(e) => { e && e.stopPropagation() }} />
          </Tooltip>,
        }) : null}
      </Fragment>
    }]
    const eyeItems = [{
      key: "eye_probe_setup",
      label: <div className="ami-model-collapse-title">
        Eye Probe
      </div>,
      children: this.getEyeProbe(eyeProbe, simulation)
    }]
    return (
      <Fragment>
        <Collapse className="ami-model-collapse-content ami-model-simulation-content" defaultActiveKey={["simulation_setup"]} items={simulationItems} />
        <Collapse className="ami-model-collapse-content ami-model-simulation-content" defaultActiveKey={["eye_probe_setup"]} items={eyeItems} />
      </Fragment>
    );
  }

  saveConfigValue = (value, type, parentType) => {
    const key = value && value.key ? value.key : '';
    this.props.saveConfigValue(key, type, parentType)
  }

  getProbeList = (eyeProbe) => {
    let probeList = [];
    if (eyeProbe.probeTx) {
      probeList.push(PROBETX)
    }
    if (eyeProbe.probeRx) {
      probeList.push(PROBERX)
    }
    return probeList;
  }

  getEyeProbe = (eyeProbe, simulation) => {
    const { serdesType } = this.props;
    const eyeLibraryList = libraryConstructor.getLibraryValues(EYE_MASK);
    if (serdesType === CPHY) {
      return <>
        {/* Mask Height Parameters */}
        {/* Mask Width Parameters */}
        {/* Clock and Data Recovery */}
        <SetupInputGroupItem
          dataList={getModelSetupDataList(EYEPROBE, { data: eyeProbe })}
          onChange={(type, value) => this.props.inputChange({ target: { value } }, type, EYE_PROBE)}
        />
      </>
    }
    return <Fragment>
      {this.getInputComponent({
        title: "BER for measurement",
        type: ERROR_BIT_RATE,
        parentType: EYE_PROBE,
        value: eyeProbe.errorBitRate
      })}
      {this.getTagComponent({
        title: "BER list for contour plotting",
        type: ERROR_BIT_RATE_LIST,
        parentType: EYE_PROBE,
        value: eyeProbe.berList
      })}
      {this.getSwitchComponent({
        title: "Extrapolate in bit-by-bit mode",
        type: EXTRAPOLATE_IN_BIT_BY_BIT_MODE,
        parentType: EYE_PROBE,
        value: eyeProbe.extrapolateInBitByBitMode,
      })}
      {this.getSelectComponent({
        title: "Eye Mask",
        type: EYEMASK,
        parentType: AMI_SIMULATION,
        value: simulation.eyeMask && simulation.eyeMask.libraryId ? simulation.eyeMask.libraryId : '',
        list: eyeLibraryList.map(item => { return { key: item.id, title: item.name } }),
        /* titleClassName: 'ami-advanced-title-deepen-color', */
        allowClear: true
      })}
      {/* {this.getMultipleSelect({
        title: "Additional Probe",
        type: 'probeList',
        list: [{ key: PROBETX, title: 'Tx-out' }, { key: PROBERX, title: 'Rx-in' }],
        value: probeList,
        parentType: EYE_PROBE,
        allowClear: true
      })} */}
    </Fragment>
  }

  getSelectComponent = ({
    title,
    type,
    parentType,
    value,
    label,
    tipIcon,
    existIcon,
    list = [],
    titleClassName = '',
    allowClear = false,
    isExist = true
  }) => {
    const option = label ? label : (value ? list.find(item => item.key === value) : '');
    return <div className='ami-advanced-select-item'>
      <span className={titleClassName}>
        {title}
        {tipIcon}
      </span>
      <Select
        value={{ value, label: label || (option || {}).title }}
        showSearch
        onChange={(option = {}) => this.saveConfigValue({ label: option.label, key: option.value }, type, parentType)}
        placeholder={title}
        className='aurora-select'
        popupClassName='aurora-select-dropdown'
        labelInValue={true}
        allowClear={allowClear}
        style={{ color: isExist ? '' : 'red' }}
      >
        {list.map(item =>
          <Option key={item.key} value={item.key}>{item.title}</Option>
        )}
      </Select>
      {!isExist && existIcon}
    </div>
  }

  getInputComponent = ({
    title,
    type,
    parentType,
    value,
    className = "",
    unit,
    disabled
  }) => {
    return <div className={`ami-advanced-select-item ${className}`}>
      <span>{title}</span>
      <Input
        className='aurora-input'
        placeholder={title}
        value={value}
        disabled={disabled}
        onChange={(e) => this.props.inputChange(e, type, parentType, unit)}
        onBlur={(e) => this.props.inputBlur(e, type, parentType, unit)}
        addonAfter={unit || null}
      />
    </div>
  }

  getCheckBoxComponent = ({
    title,
    type,
    parentType,
    value,
    className = ""
  }) => {
    return <div className={`ami-advanced-select-item ${className}`}>
      <Checkbox
        className='ami-model-select-checkbox'
        checked={value}
        onChange={(e) => this.props.checkChange(e, type, parentType)}
      />
      <span className='ami-advanced-checkbox-span'>{title}</span>
    </div>
  }

  getSwitchComponent = ({
    title,
    type,
    parentType,
    value,
    className = ""
  }) => {
    return <div className={`ami-advanced-select-item ${className}`}>
      <span>{title}</span>
      <Checkbox
        className='ami-model-select-left-checkbox'
        checked={value}
        onChange={(e) => this.props.checkChange(e, type, parentType)}
      />
    </div>
  }

  getTagComponent = ({
    title,
    type,
    parentType,
    value,
    className = ""
  }) => {
    return <div className={`ami-advanced-select-item ${className}`}>
      <span>{title}</span>
      <TagsInput
        type={type}
        tagList={value}
        className="aurora-input"
        changeTagList={(e, value) => this.props.changeTagList(e, value, type, parentType)}
      />
    </div>
  }

  getMultipleSelect = ({
    title,
    type,
    parentType,
    value,
    list = [],
    titleClassName = '',
    allowClear = false
  }) => {
    return <div className='ami-advanced-select-item'>
      <span className={titleClassName}>{title}</span>
      <Select
        mode="multiple"
        placeholder={title}
        className='aurora-select'
        popupClassName='aurora-select-dropdown'
        onChange={(key) => this.props.saveMultiConfigValue(key, type, parentType)}
        value={value}
        allowClear={allowClear}
      >
        {
          list.map(item =>
            <Option key={item.key} value={item.key}>{item.title}</Option>
          )
        }
      </Select >
    </div>
  }
}
export default AMISimBasicSetup;