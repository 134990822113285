/* Signal Group class */
function PreLayoutSignalGroup({ group, signals, signal_spacing = "15" }) {
  this.name = group;
  this.signal_spacing = signal_spacing;
  this.signals = signals;
}

/* Signal class */
function PreLayoutSignal({ name, index, nets_P, nets_N }) {
  this.name = name; //signal name -> TX0 / RX0 , ...
  this.index = index; //index 0,1, ...
  this.nets_P = [...nets_P]; // [ PCIE0_TX+ ]
  this.nets_N = [...nets_N]; // [ PCIE0_TX- ]
  this.sections = [new TemplateSections({ id: "1" })];
  this.insertionLoss = ""; //3db
}

// CPHY Prelayout Signal
function PreLayoutSignalCPHY({ name, index, nets_A, nets_B, nets_C }) {
  this.name = name; //signal name -> CPHY + index
  this.index = index; //index 0,1, ...
  this.nets_A = [...nets_A];
  this.nets_B = [...nets_B];
  this.nets_C = [...nets_C];
  this.sections = [new TemplateSections({ id: "1" })];
  this.insertionLoss = ""; //3db
}

/* Template Sections class */
function TemplateSections({
  id,
  type = "",
  libType = "",
  libId = "",
  template = "",
  length = "",
  prev = "",
  next = []
}) {
  this.id = id;
  this.type = type; // trace / via
  this.libType = libType;
  this.libId = libId;
  this.template = template;
  this.length = length;
  this.prev = prev;
  this.next = next;
}

/* RLC Sections class */
function RLCSections({ id, type = "", value = "", prev = "", next = [] }) {
  this.id = id;
  this.type = type; //"capacitor", "resistor", "inductor"
  this.value = value;
  this.prev = prev;
  this.next = next;
}

/* component class */
function PreLayoutComponent(name, setting = {}) {
  const { type = "", layer = "" } = setting;
  this.name = name;
  this.layer = layer;// Top / Bottom
  this.type = type; // IC / Connector
  this.pins = [] // [ {pin,net,signal}]
}

// pin class
function PreLayoutPin({ pin, net, signal, port = "", modelId = "", sectionId = "", signalGroup = "" }) {
  this.pin = pin;
  this.net = net;
  this.signal = signal;
  this.port = port;
  this.modelId = modelId;
  this.sectionId = sectionId;
  this.signal_group = signalGroup;
}

/* preLayout model */
function preLayoutModelInfo(type = "") {
  this.type = type;
  this.files = []; //{ type, fileName, libraryId, subckt }
  this.pairs = []; // [ { pin, node, subckt, libraryId } ,{ pin, node, subckt, libraryId }, ... ]
}

export {
  PreLayoutSignal,
  TemplateSections,
  PreLayoutComponent,
  RLCSections,
  PreLayoutSignalGroup,
  PreLayoutPin,
  preLayoutModelInfo,
  PreLayoutSignalCPHY
}