import { VRM, DECAP_RLC, DECAP_TOUCHSTONE, DECAP_SPICE, PIN_MAP, DRIVER, WIRE_BOND_XML, CUSTOM_TOUCHSTONE, CUSTOM_SPICE, HFSS_OPTIONS, SIWAVE_OPTIONS, POWERSI_OPTIONS, POWERDC_OPTIONS, CPM_SPICE } from "@/constants/libraryConstants";
import { LIBRARY_DATA } from '@/constants/treeConstants';

function getDataType(libraryType) {
  switch (libraryType) {
    case VRM:
    case PIN_MAP:
    case DRIVER:
    case WIRE_BOND_XML:
      return LIBRARY_DATA;
    case DECAP_RLC:
    case DECAP_TOUCHSTONE:
    case DECAP_SPICE:
    case CUSTOM_TOUCHSTONE:
    case CUSTOM_SPICE:
    case HFSS_OPTIONS:
    case SIWAVE_OPTIONS:
    case CPM_SPICE:
    case POWERSI_OPTIONS:
    case POWERDC_OPTIONS:
      return libraryType;
    default: return;
  }
}

function libraryItem(key, { name, type, id, libraryType, nodeClass, tag, deletable, format }) {
  this.name = name;
  this.type = type;
  this.id = id;
  this.dataType = getDataType(libraryType)
  this.key = this.id + '-' + this.name;
  this.nodeClass = nodeClass;
  this.tag = tag;
  this.deletable = deletable;
  this.format = format
}

function getLibraryArray(key, dataArr) {
  if (!Array.isArray(dataArr) || !dataArr.length) {
    return [];
  }
  const _arr = dataArr.map(item => new libraryItem(key, {
    name: item.name,
    type: key,
    libraryType: item.type,
    id: item.id,
    nodeClass: 'tree-library-file',
    tag: item.tag || null,
    deletable: item.deletable,
    format: item.format
  }));
  return _arr;
}

export {
  getLibraryArray
}