import { connect } from 'react-redux';
import {
  selectLayer,
  selectChange,
  openPanel,
  closePanel,
  updateLocation,
  cancelSelectedAll,
  changeDisplaySelected
} from '../store/FastPI/actionCreators';
import { startLayoutCheck } from '../../PDN/store/project/action';
import SearchBox from './SearchBox';
import LayerTab from './LayerTab';
import Stackup from './stackup';
import ComponentTable from './DesignInfo/ComponentTable';
import NetTable from './DesignInfo/NetTable';
import higherOrderComponent from '../components/explorerHOC';
import LayoutExplorer from '../components/explorer';
import { FASTPI } from '../../../constants/pageType';
import PadStackInfo from './DesignInfo/PadStackInfo';
import PCBDownload from '../components/pcbDownload';
import LayoutError from '../components/DesignInfo/LayoutError';

const mapState = (state) => {
  const {
    panelType,
    colorBy,
    simulation,
    selections
  } = state.PDNReducer.explorer;
  const { defaultLeft, defaultTop } = state.PanelStatus;
  const { results, simulationDesigns } = state.LayoutReducer;
  return {
    panelType, colorBy,
    simulation, defaultLeft, defaultTop,
    selections,
    pageType: FASTPI,
    layoutResults: results,
    simulationDesigns
  }
}


const mapDispatch = (dispatch) => ({
  updateLocation(location, designID) {
    dispatch(updateLocation(location, designID))
  },
  select(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  selectLayer(checkedList, designID) {
    dispatch(selectLayer(checkedList, designID));
  },
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
  cancelSelectedAll(designID) {
    dispatch(cancelSelectedAll(designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  startLayoutCheck(id) {
    dispatch(startLayoutCheck({ id }))
  }
})

const LayoutExplorerHOC = higherOrderComponent({ LayoutExplorer, SearchBox, LayerTab, ComponentTable, NetTable, Stackup, PadStackInfo, PCBDownload, LayoutError });

export default connect(mapState, mapDispatch)(LayoutExplorerHOC);