import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import {
  getSpiceCardData
} from '../../../services/Himalayas';
import './index.css';
import NameTable from './NameTable';
import PDNTable from './PDNTable';
import ConfigTable from './ConfigTable';
import SignalsTable from './SignalsTable';

class SpiceCardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabList: [],
      tabDataObj: {},
      selectedTab: ""
    }
  }

  componentDidMount = () => {
    this.initSpiceCardData()
  }

  componentDidUpdate = (prevProps) => {
    const { spiceCardTemplate, verificationId } = this.props;
    if ((verificationId && verificationId !== prevProps.verificationId) ||
      (spiceCardTemplate && spiceCardTemplate.length && (!prevProps.spiceCardTemplate || prevProps.spiceCardTemplate.length !== spiceCardTemplate.length))) {
      this.initSpiceCardData();
    }
  }

  initSpiceCardData = () => {
    const { spiceCardTemplate } = this.props;
    const { tabList, tabDataObj } = getSpiceCardData(spiceCardTemplate);
    const selectedTab = tabList.length ? tabList[0].key : "";
    this.setState({
      tabList,
      tabDataObj,
      selectedTab
    })
  }

  changeTabKey = (key) => {
    this.setState({
      selectedTab: key
    })
  }

  render() {
    const { tabList, selectedTab, tabDataObj } = this.state;
    return <div className='himalayas-spice-card-table-content'>
      {tabList && tabList.length ?
        <Fragment>
          <Tabs activeKey={selectedTab}
            onChange={this.changeTabKey}
            className='himalayas-spice-card-tabs'
            items={tabList.map(item => ({ key: item.key, label: item.title }))}
          />
          <NameTable
            nameList={(tabDataObj[selectedTab] || {}).name || []}
          />
          <PDNTable
            PDNList={(tabDataObj[selectedTab] || {}).PDN || []}
          />
          <SignalsTable
            signalList={(tabDataObj[selectedTab] || {}).ioSignals || []}
            selectedTab={selectedTab}
          />
          <ConfigTable
            configList={(tabDataObj[selectedTab] || {}).configuration || []}
          />
        </Fragment> : null}
    </div>
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(null, mapDispatch)(SpiceCardTable);