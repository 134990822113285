import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { Input, message } from 'antd'
import PadStack, { frontendPadstackHandle } from '../../../../services/helper/DB/padStackDB';
import Table from '../../../../components/EditableTable/EditableTable';
import { numberCheck } from '../../../../services/helper/dataProcess';
import PadStackDialog from './PadStackDialog'
import _ from 'lodash';
import { splitNumUnit } from '../../../../services/helper/numberHelper';
import { unitChange } from '../../../../services/helper/mathHelper';
import './index.css';

const columns = [{
  title: 'Name',
  dataIndex: 'name',
  width: '35%',
  sorter: (a, b) => a.name.localeCompare(b.name)
}, {
  title: 'Via#',
  dataIndex: 'viaNum',
  width: '12.5%',
  sorter: (a, b) => Number(a.viaNum) > Number(b.viaNum) ? 1 : -1,
}, {
  title: 'Drill Type',
  dataIndex: 'holeType',
  width: '12.5%'
}, {
  title: 'Drill Size',
  dataIndex: 'holeParameter',
  width: '18%'
}, {
  title: 'Plating Thickness',
  dataIndex: 'plating',
  width: '22%',
}];
const { Search } = Input;
const POLYGON = 'polygon', ROUNDRECT = 'roundrectangle', CIRCLE = 'circle', SQUARE = 'square', RECT = 'rectangle', OVAL = 'oval';

class PadStackInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      height: 493,
      showStackList: [],
      globalPlating: "",
      searchValue: '',
      errors: {},
      selectedVia: ""
    };
    this.stackList = [];
    this.defaultStackList = [];
    this.defaultGlobalPlating = 20;
  }

  async componentDidMount() {
    const { designId } = this.props;
    this.setDefaultColumns();
    document.addEventListener("click", this.handleClickOutside, true);
    const stack = await PadStack.getStack(designId);
    if (stack.getError) {
      message.error('Get PCB padstacks info error, please try again.');
      this.props.closePanel(designId);
    }
    const { editPadstack, globalPlating = "100" } = stack;
    this.stackList = editPadstack;
    this.defaultStackList = JSON.parse(JSON.stringify(editPadstack));
    this.defaultGlobalPlating = globalPlating;
    this.setState({
      loading: false,
      showStackList: editPadstack,
      globalPlating
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  setDefaultColumns = () => {

    columns[0].render = (text) => {
      return <span onClick={() => this.onShowVias(text)} className={`layout-padstack-via-name`}>{text}</span>
    }

    columns[4].render = (text) => {
      return text ? String(text).match(/^-?\d+(\.\d+)?$/ig) ? `${text}%` : text : '';
    }

    columns[4].onCell = (record) => {
      const { plating } = record;
      const { num, unit } = splitNumUnit(plating);
      return {
        record: { ...record, value: num, unit: unit || '%' },
        edit: 'input-with-select',
        unitList: ['%', 'mil', 'um', 'mm'],
        dataIndex: 'value',
        allowEmpty: true,
        decimals: 3,
        handleSave: (row) => this.savePlating(row)
      }
    }

    columns[3].render = (text, record) => {
      const { holeType } = record;
      const title = () => {
        switch (holeType) {
          case ROUNDRECT:
            return `Width = ${text[0]}, Height = ${text[1]}, cornerRadius = ${text[2]}`
          default:
            return text.join(', ')
        }
      }
      return text && text.length ? <span title={title()}>{text.join(', ')}</span> : '';
    }

    columns[3].onCell = (record) => {
      const { holeType } = record;
      return ![CIRCLE, SQUARE, RECT].includes(holeType) ? {
        edit: false,
      } : {
        record,
        edit: true,
        customInput: PadStackDialog,
        trigger: "pad-stack-dialog",
        dataIndex: "holeParameter",
        shapIndex: "holeType",
        dialogSave: this.saveHoleParameter,
        onClick: (e) => this.stopProps(e),
      }
    }
  }

  stopProps = (e) => {
    e && e.stopPropagation()
  }

  closePanel = () => {
    const { designId } = this.props;
    const { globalPlating, selectedVia } = this.state;
    const isEqual = _.isEqual(this.defaultStackList, this.stackList) && this.defaultGlobalPlating === globalPlating
    if (!isEqual) {
      // save
      try {
        const editPadstack = frontendPadstackHandle(this.stackList);
        PadStack.saveStack(designId, { editPadstack, globalPlating })
      } catch (error) {
        console.error(error);
        message.error('Save padstack failed!');
      }
    }

    this.props.onShowVias && this.props.onShowVias('', selectedVia);
    this.props.closePanel(designId);
  }

  changeGlobalPlating = (e) => {
    e && e.stopPropagation()
    this.setState({
      globalPlating: e.target.value
    })
  }

  search = (e) => {
    e && e.stopPropagation()
    this.setState({
      searchValue: e.target.value,
      loading: true
    }, () => {
      this.getShowList()
    })
  }

  getShowList = () => {
    const { searchValue } = this.state;
    this.setState({
      loading: false,
      showStackList: this.stackList ? this.stackList.filter(item => item.name.includes(searchValue)) : []
    });
  }

  savePlating = (row) => {
    const { name, unit, value } = row;
    const index = this.stackList.findIndex(item => item.name === name);
    let newPlating = value ? unit !== '%' ? `${value}${unit}` : String(value) : ''
    if (index > -1) {
      this.stackList[index].plating = newPlating;
      this.getShowList();
      this.checkPlatingCheck(name, newPlating);
    }
  }

  checkPlatingCheck = (name, plating) => {
    if (!plating) {
      const { errors } = this.state;
      let _errors = { ...errors }
      delete _errors[name];
      this.setState({ errors: _errors })
    } else {
      const { unit } = splitNumUnit(plating);
      unit ? this.checkNumber(name, plating, true) : this.checkPrecent(name, plating)
    }
  }

  checkPrecent = (name, plating) => {
    const { errors } = this.state;
    let _errors = { ...errors }
    let error = numberCheck(plating);
    if (!error) {
      if (plating < 0 || plating > 100) {
        error = 'The value must between 0% and 100%.'
      }
    }
    if (!error) {
      delete _errors[name];
    } else {
      _errors[name] = `${name} - ${error}`;
    }
    this.setState({
      errors: _errors
    })
  }

  checkGlobalPlating = () => {
    const { globalPlating } = this.state;
    this.checkPrecent('Global Plating', globalPlating)
  }

  checkNumber = (name, plating, extraCheck) => {
    const index = this.stackList.findIndex(item => item.name === name);
    if (index > -1) {
      const { errors } = this.state;
      let _errors = { ...errors };
      const { num, unit } = splitNumUnit(plating);
      let error = numberCheck(num);
      if (!error) {
        if (this.stackList[index].holeType === CIRCLE && extraCheck) {
          const holeParameter = this.stackList[index].holeParameter[0];
          if (holeParameter) {
            const { num: holeNum, unit: holeUnit } = splitNumUnit(holeParameter);
            const _holeNum = unitChange({ num: holeNum, oldUnit: holeUnit, newUnit: 'mm' }).number;
            const _num = unitChange({ num: num, oldUnit: unit, newUnit: 'mm' }).number
            if (Number(_num) > (Number(_holeNum) / 2)) {
              error = `value must be less than half of Drill Size!`
            }
          }
        }
        if (!error) {
          delete _errors[name];
        } else {
          _errors[name] = `${name} - ${error}`;
        }
      } else {
        _errors[name] = `${name} - ${error}`;
      }
      this.setState({
        errors: _errors
      })
    }
  }

  handleClickOutside = (e) => {
    const { target } = e;
    const dialogBox = document.getElementById("pad-stack-dialog");
    if (
      dialogBox &&
      dialogBox.children.length > 0 &&
      !dialogBox.contains(target)
    ) {
      return;
    }
  };

  onShowVias = (text) => {
    if (!this.props.onShowVias) {
      return
    }
    const { selectedVia } = this.state;
    const newSelected = text === selectedVia ? '' : text;
    this.props.onShowVias(newSelected, selectedVia);
    this.setState({
      selectedVia: newSelected
    })
  }

  saveHoleParameter = (holeParameter, record) => {
    const { name } = record;
    const index = this.stackList.findIndex(item => item.name === name);
    if (index > -1) {
      this.stackList[index].holeParameter = holeParameter;
      this.getShowList();
      for (let parameter of holeParameter) {
        this.checkNumber(name, parameter, false)
      }
    }
  }

  render() {
    const { leftWidth, topWidth, defaultLeft, defaultTop, maxWidth, designId, maxHeight, isLayoutPage = true } = this.props;
    const { loading, showStackList, globalPlating, errors, selectedVia } = this.state;
    const positionProps = isLayoutPage ? {
      position: 'panel-top-left',
      left: leftWidth ? leftWidth : defaultLeft,
      top: topWidth ? topWidth : defaultTop,
      defaultLeft: 50
    } : {
      position: 'panel-center'
    }
    return (
      <Panel
        {...positionProps}
        title={'Via Padstacks'}
        visible
        onCancel={() => this.closePanel()}
        width={maxWidth < 700 ? maxWidth : 700}
        className='menu-panel aurora-menu-panel-padstack layout-padstack-panel'
        draggable
        minHeight={200}
        minWidth={500}
        maxWidth={maxWidth}
        zIndex={isLayoutPage ? 10 : 2000}
        overflow='hidden'
        redirectDom={isLayoutPage ? `aurora-PCB-layout-content-${designId}` : 'root'}
      >
        <div className='layout-padstack-content'>
          <Search
            placeholder="Search via name"
            onChange={e => this.search(e)}
            style={{ width: 200 }}
            size='small'
          />
        </div>
        <div className='layout-padstack-content'>
          <Table
            columns={columns}
            dataSource={showStackList}
            rowKey="name"
            rowClassName={(record) => record.name === selectedVia ? 'layout-padstack-via-selected' : ''}
            size="small"
            loading={loading}
            scroll={maxHeight - 200 < showStackList.length * 40 ? { y: maxHeight - 200 } : {}}
          />
        </div>
        <div className='layout-padstack-content layout-padstack-content-right'>
          <span className='plating-thickness-title'>Global Plating Thickness</span>
          <Input
            onChange={e => this.changeGlobalPlating(e)}
            onBlur={this.checkGlobalPlating}
            value={globalPlating}
            addonAfter='%'
            size='small'
            className='plating-thickness-input'
          />
        </div>
        {errors && Object.keys(errors) && Object.keys(errors).length ? <div className='layout-padstack-content'>
          <div className='layout-padstack-content-error'>{Object.values(errors)[0]}</div>
        </div> : null}
      </Panel>
    )
  }
}

export default PadStackInfo;