import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import './pdnPanel.css';

class ShowModelsPanel extends Component {

  constructor(props) {
    super(props);
    this.dialogRoot = document.getElementById('root');
  }

  render() {
    const { showSPIMMessage, showLog } = this.props;
    const content = (
      <Panel
        className="PDN-show-models"
        position='panel-center-left'
        title={showLog ? 'SPIM Log' : 'SPIM checking'}
        zIndex={2000}
        onCancel={() => this.props.closeModal('closeSPIMPanel')}
        width={showLog ? 800 : 400}
        draggable
        minHeight={200}
      >
        <pre className={showLog ? "pdn-show-log-content" : "show-model-content"}>{showSPIMMessage}</pre>
      </Panel>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default ShowModelsPanel;