import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '../../../components/Panel';
import { createPortal } from "react-dom";
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '../../../services/helper/panelSizeHelper';
import {
  getMultiSetupPassiveData,
  getMultiInterfaceSetup,
  savePassiveModel,
  clearPassiveData,
  changeMultiPassivePage,
  updatePassiveComps,
  saveSplitComponents,
  saveMergeComponents
} from '../store/multiInterface/action';
import Table from "@/components/EditableTable";
import { Spin, Popover, Tooltip, message } from 'antd';
import { SIERRA } from '../../../constants/pageType';
import {
  CAP,
  IND,
  RES,
  JUMPER,
  UNUSED
} from '@/constants/componentType';
import sierraLibrary from '../../../services/Sierra/library/libraryStorage';
import { PASSIVE_SPICE, PASSIVE_TOUCHSTONE } from '../../../constants/libraryConstants';
import { getLibraryFileContent } from '@/services/Sierra/library';
import { parseSpiceModelSelector } from '../../../services/LibraryHelper/SpiceModelHelper';
import { RLCModelInfo, getRLCValue, PassiveModel, getModelValue, getModelTypeName, formatRLCValue } from '../../../services/RLCModel';
import RLCCompModel from '@/components/RLCCompModel';
import { isMac } from '@/services/api/userAgent';
import TableTag from '@/components/TableTag';
import canvas from '@/services/LayoutCanvas';
import { selectChange, selectLayer, changeDisplaySelected } from '../../LayoutExplorer/store/Sierra/actionCreators'
import { getSplitPartName } from '@/services/helper/setDefaultName';
import { getSelectedDesignIDs } from '@/services/helper/dataProcess';
import { updateLibraryTree } from '../store/library/action';
import './index.css';

const RLCType = ['RLC', CAP, IND, RES];
const RLCJumperType = ['RLC', CAP, IND, RES, JUMPER];
const columns = [{
  title: "PCB",
  dataIndex: "pcb",
  key: 'pcb',
  textWrap: 'word-break',
  ellipsis: true,
  width: '20%'
}, {
  title: "Part",
  dataIndex: "part",
  key: 'part',
  textWrap: 'word-break',
  ellipsis: true,
  width: '20%'
}, {
  title: "Components",
  dataIndex: "components",
  width: '30%'
}, {
  title: "Usage",
  dataIndex: "type",
  key: 'type',
  width: 80,
},
{
  title: "Model",
  dataIndex: "value"
},];

class PassiveEditPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      maxWidth: 0,
      mergePartList: [],
      mergeVisible: false,
      mergeType: '',
      mergePCB: '',
      passiveSpiceList: sierraLibrary.getTree(PASSIVE_SPICE),
      passiveTouchstoneList: sierraLibrary.getTree(PASSIVE_TOUCHSTONE)
    }
    this.dialogRoot = document.getElementById("root");

    columns[0].render = (text, record) => {
      return <span title={text}>{text}</span>
    }

    columns[0].onCell = (record) => {
      return {
        edit: false,
        rowSpan: record.mergePcbLength
      }
    }

    columns[1].render = (text, record) => {
      const { mergeVisible, mergePartList } = this.state;
      if (RLCType.includes(record.type) && mergePartList && mergePartList.length > 0 && mergePartList[0] === record.part) {
        return <Popover
          placement="topLeft"
          overlayClassName='merge-part-popover'
          getPopupContainer={() => document.getElementById('sierra-component-row')}
          content={<Fragment>
            <Tooltip title='Merge Part' mouseLeaveDelay={0} mouseEnterDelay={0.3} overlayClassName='icon-tooltip'>
              <span className='merge-part-popover-span' onClick={(e) => this.MergePart(e, record)}>Merge</span>
            </Tooltip>
          </Fragment>}
          open={mergeVisible}
        >
          <span onClick={(e) => this.tableOnRow(e, record)} className='sierra-part-name cursor-pointer' title={record.part}>{record.part}</span>
        </Popover>
      } else if (RLCType.includes(record.type)) {
        return <span className='cursor-pointer sierra-part-name' onClick={(e) => this.tableOnRow(e, record)} title={record.part}>{record.part}</span>
      } else {
        return <span className='sierra-part-name' title={record.part}>{record.part}</span>
      }
    }

    columns[2].onCell = (record) => {
      const { components } = record;
      let compsName = [];
      components.map(item => {
        if (item.type !== UNUSED) {
          compsName.push({
            name: item.name,
            checked: true
          });
        } else {
          compsName.push({
            name: item.name,
            checked: false
          });
        }
        return null;
      });

      return RLCType.includes(record.type) ? {
        record,
        compsName,
        edit: true,
        customInput: TableTag,
        dataIndex: "components",
        popupContainer: props.pageData.length > 1 ? 'sierra-component-row' : 'multi-passive-content-id',
        changeCompsChecked: this.changeCompsChecked,
        select: this.componentSelect,
        splitComps: this.splitComps,
        onClick: (e) => this.spanClick(e),
        isPCBOpened: this.isPCBOpened
      } : null
    }

    columns[2].render = (components, record) => {
      const mac = isMac();
      let tdRef = document.getElementById(`td-${record.index}`);
      const height = tdRef && tdRef.offsetHeight;
      let names = [];
      const isRLC = RLCType.includes(record.type);
      components.forEach(item => {
        if (item.type !== UNUSED || !isRLC) {
          names.push(item.name);
        }
      })
      return <div
        className='sierra-table-comp' id='sierra-component-row'>
        <span className={!isRLC ? 'sierra-comp-usage-rlc' : ""}
          onClick={!isRLC ? () => this.componentSelect(record, names) : null}>
          {names.length > 0 ? names.join(', ') : "Unstuffed"}
        </span>
        {mac && height && height > 88 ? <div className='sierra-component-arrow'></div> : null}
      </div>
    };

    columns[3].render = (text, record) => {
      const className = this.props.loading ? "sierra-comp-usage-rlc" : "";
      return <span className={className}>{record.type}</span>
    }

    columns[4].render = (text, record) => {
      if (RLCJumperType.includes(record.type)) {
        return <span>{formatRLCValue(record.type, text, record.model)}</span>;
      }
    }

    columns[4].onCell = (record) => {
      if (RLCJumperType.includes(record.type)) {
        const { passiveSpiceList, passiveTouchstoneList } = this.state;
        let comps = [];
        const isRLC = RLCType.includes(record.type);
        record.components.forEach(item => {
          if (item.type !== UNUSED || !isRLC) {
            comps.push(item.name);
          }
        })
        return {
          edit: true,
          text: <span>{formatRLCValue(record.type, record.value, record.model)}</span>,
          record: { ...record, model: this.getRLCModel(record), comps },
          passivePins: record.pins,
          customInput: RLCCompModel,
          dataIndex: 'model',
          // powerNets: this.getNetsOptions(record),
          hideRefNet: true,
          saveRLCModel: this.saveRLCModel,
          spiceList: passiveSpiceList,
          touchstoneList: passiveTouchstoneList,
          getFileContent: getLibraryFileContent,
          parseModelSelector: parseSpiceModelSelector,
          product: SIERRA
        }
      } else {
        return {
          edit: false,
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { libraryTreeInfo } = this.props
    if (libraryTreeInfo && libraryTreeInfo.length && (!prevProps.libraryTreeInfo || libraryTreeInfo.length !== prevProps.libraryTreeInfo.length)
      && (libraryTreeInfo.includes(PASSIVE_SPICE) || libraryTreeInfo.includes(PASSIVE_TOUCHSTONE))) {
      this.getLibraryList();
      this.props._updateLibraryTree(null);
    }
  }

  getLibraryList = () => {
    this.setState({
      passiveSpiceList: sierraLibrary.getTree(PASSIVE_SPICE),
      passiveTouchstoneList: sierraLibrary.getTree(PASSIVE_TOUCHSTONE)
    })
  }

  // getNetsOptions = (record) => {
  //   const { Interfaces } = this.props;
  //   let currentInterface = Interfaces ? Interfaces.find(item => item.pcbId === record.pcbId) : null;
  //   let nets = [], signalNets = [], pwrGndNets = [];
  //   if (currentInterface && currentInterface.content) {
  //     if (currentInterface.content.signals) {
  //       //get signal nets
  //       currentInterface.content.signals.forEach(item => {
  //         if (item.nets && item.nets.length > 0) {
  //           signalNets = [...signalNets, ...item.nets]
  //         }
  //       })
  //     }

  //     //get power gnd nets
  //     if (currentInterface.content.powerNets) {
  //       currentInterface.content.powerNets.forEach(item => {
  //         if (item.name) {
  //           pwrGndNets.push(item.name);
  //         }
  //       })
  //     }
  //   }
  //   nets = [...signalNets, ...pwrGndNets];
  //   return nets;
  // }

  saveRLCModel = (model, record) => {
    const { pcb, pcbId, part } = record;
    const _model = { type: getModelTypeName(model.type), ...model.passiveModel };
    const value = getModelValue(record.type, model.value);
    this.props._saveRLC({ model: _model, pcb, pcbId, value, part });
  }

  getRLCModel = (record) => {
    const { model = {}, value, type } = record;
    let _value = getRLCValue(type, value);
    if (type === JUMPER) { _value = { r: value } }
    const passiveModel = new PassiveModel({ ...model });
    const _model = new RLCModelInfo({ modelType: model.type ? model.type.toLowerCase() : model.type, value: _value, passiveModel });
    return { ..._model };
  }

  changeCompsChecked = (compsName, checked, record) => {
    const { part, pcb, pcbId, type } = record;
    this.props._updateCompComponents({ part, compsName, checked, _type: type, pcb, pcbId });
  }

  splitComps = (compList, record) => {
    const { part, pcb, pcbId } = record;
    const { pageData } = this.props
    let partName = part;
    const components = pageData.map((item) => {
      return { part: item.part }
    })
    const newPartName = getSplitPartName({ components, partName });
    this.setState({
      splitPartName: newPartName,
      mergePartList: []
    });
    this.props._saveSplitComponents({ part, splitPart: newPartName, comps: compList, pcb, pcbId });
    setTimeout(() => {
      this.setState({
        splitPartName: null
      });
    }, 3000);
  }

  componentSelect = (record, names) => {
    const { pcbId } = record;
    if (this.isPCBOpened(record)) {
      const layout = canvas.getLayout(pcbId);
      let layers = layout.findCurrentLayer(names);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._selectChange({ comps: [...names] }, pcbId);
      this.props.changeDisplaySelected(true, pcbId);
    }
  }

  isPCBOpened = (record) => {
    const { pcbId } = record;
    const { selectedDesignIDs } = this.props;
    return selectedDesignIDs.includes(pcbId);
  }

  spanClick = (e) => {
    e.stopPropagation();
  }

  MergePart = (e, record) => {
    e.stopPropagation();
    const { mergePartList } = this.state;
    const { pcb, pcbId } = record;
    if (mergePartList.length === 0) {
      return;
    }
    let partName = mergePartList[0];
    this.props._saveMergeComponents({ part: partName, partList: mergePartList, pcb, pcbId });
    this.setState({
      mergePartName: partName,
      mergePartList: []
    });
    setTimeout(() => {
      this.setState({
        mergePartName: null
      });
    }, 3000);
  }

  tableOnRow = (e, record) => {
    e.stopPropagation();
    const { part } = record;
    const { mergePartList, mergeVisible, mergeType, mergePCB } = this.state;
    if (mergePartList && mergePartList.length > 0) {
      if (record.type !== mergeType) {
        message.info("Please select components of the same type to merge")
        return;
      }
      if (record.pcb !== mergePCB) {
        message.info("Please select components of the same pcb to merge")
        return;
      }
    }
    let partList = [...mergePartList], visible = mergeVisible;
    if (!mergePartList.includes(part)) {
      partList.push(part);
    } else {
      partList = partList.filter(item => item !== part);
    }
    if (partList.length > 1) {
      visible = true;
    } else {
      visible = false;
    }
    this.setState({
      mergePartList: partList,
      splitPartName: null,
      mergeVisible: visible,
      mergeType: record.type,
      mergePCB: record.pcb
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
  }

  componentDidMount = () => {
    const { verificationId } = this.props;
    this.resize()
    window.addEventListener('resize', this.resize);
    this.props._getMultiSetupPassiveData(verificationId)
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 800),
      maxWidth: getPanelMaxWidth(offset, 1300)
    })
  }

  closeModal = () => {
    this.props.save && this.props.save();
    this.props.closePanel && this.props.closePanel();
    this.props._getMultiInterfaceSetup()
    this.props._clearPassiveData()
  }

  onPageChange = (page) => {
    this.props._changeMultiPassivePage(page)
  }

  render() {
    const { loading, pageData, pageSize, page, allDataLength, interfaceName } = this.props;
    const { maxWidth, maxHeight, splitPartName, mergePartList, mergePartName } = this.state;
    const content = (
      <Fragment>
        <Panel
          title={`${interfaceName ? `${interfaceName} ` : ""}Passive Model`}
          className='sierra-multi-passive-panel sierra-panel'
          position='panel-center-top'
          minHeight={200}
          minWidth={100}
          width={getPanelWidth(maxWidth, { defaultWidth: 1300 })}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onCancel={() => { this.closeModal() }}
          draggable
          zIndex={2000}
          panelOverflow='visible'
        >
          <div className="multi-passive-content" id="multi-passive-content-id">
            <Spin spinning={loading} tip={'Loading...'}>
              <div className='sierra-multi-passive-content'>
                <Table
                  columns={columns}
                  dataSource={pageData || []}
                  className="space-10 sierra-multi-passive-table"
                  rowKey={(record) => `${record.pcb}_${record.part}`}
                  size="small"
                  pagination={{
                    position: "top",
                    pageSize,
                    current: page,
                    hideOnSinglePage: true,
                    onChange: this.onPageChange,
                    total: allDataLength
                  }}
                  onRow={record => {
                    let rowClassName = '';
                    if (record.part === splitPartName && mergePartList.length === 0 && !mergePartName) {
                      rowClassName = 'sierra-component-split-row'
                    } else if (mergePartList.length > 0 && mergePartList.includes(record.part) && !mergePartName) {
                      rowClassName = 'sierra-component-select-row'
                    } else if (mergePartName && record.part === mergePartName) {
                      rowClassName = 'sierra-component-merge-row'
                    }
                    return RLCType.includes(record.type) && {
                      className: rowClassName
                    };
                  }}
                  rowClassName={() => 'editable-row'}
                />
              </div>
            </Spin>
          </div>
        </Panel>
      </Fragment>
    );
    return createPortal(content, this.dialogRoot)
  }

}

const mapState = (state) => {
  const { SierraReducer: { multiInterfaceSetup: { passiveData }, project: { selectedKeys }, library: { libraryTreeInfo } } } = state;
  const { pageData, page, pageSize, allDataLength, loading } = passiveData || {};
  return {
    pageData,
    page,
    pageSize,
    allDataLength,
    loading,
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    libraryTreeInfo
  }
}

const mapDispatch = (dispatch) => ({
  _getMultiSetupPassiveData(verificationId) {
    dispatch(getMultiSetupPassiveData(verificationId))
  },
  _changeMultiPassivePage(page) {
    dispatch(changeMultiPassivePage(page))
  },
  _getMultiInterfaceSetup() {
    dispatch(getMultiInterfaceSetup())
  },
  _clearPassiveData() {
    dispatch(clearPassiveData())
  },
  _saveRLC({ model, pcb, pcbId, value, part }) {
    dispatch(savePassiveModel({ model, pcb, pcbId, value, part }));
  },
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID));
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  _updateCompComponents({ part, compsName, checked, _type: type, pcb, pcbId }) {
    dispatch(updatePassiveComps({ part, compsName, checked, _type: type, pcb, pcbId }));
  },
  _saveSplitComponents({ part, splitPart, comps, pcb, pcbId }) {
    dispatch(saveSplitComponents({ part, splitPart, comps, pcb, pcbId }));
  },
  _saveMergeComponents({ part, partList, pcb, pcbId }) {
    dispatch(saveMergeComponents({ part, partList, pcb, pcbId }))
  },
  _updateLibraryTree(type) {
    dispatch(updateLibraryTree(type))
  },
})

export default connect(mapState, mapDispatch)(PassiveEditPanel);
