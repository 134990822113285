const PRE_LAYOUT = 'ROCKY_PRE_LAYOUT';

// pre layout
export const ADD_PRE_LAYOUT = `${PRE_LAYOUT}/add_pre_layout`;
export const UPDATE_PRE_LAYOUT = `${PRE_LAYOUT}/update_pre_layout`;
export const OPEN_PRE_LAYOUT = `${PRE_LAYOUT}/open_pre_layout`;
export const GET_PRE_LAYOUT = `${PRE_LAYOUT}/get_pre_layout`;
export const SAVE_PRE_LAYOUT = `${PRE_LAYOUT}/save_pre_layout`;
export const CLEAR_PRE_LAYOUT = `${PRE_LAYOUT}/clear_pre_layout`;
export const UPDATE_MODEL_PRELAYOUT_CONTENT = `${PRE_LAYOUT}/update_model_preLayout_content`;
export const SAVE_MODEL_PRE_LAYOUT = `${PRE_LAYOUT}/save_model_pre_layout`;

// config
export const UPDATE_CONFIG = `${PRE_LAYOUT}/update_config`;
export const UPDATE_LAYER = `${PRE_LAYOUT}/update_layer`

// pre layout section
export const ADD_SECTION = `${PRE_LAYOUT}/add_section`;
export const CLK_SECTION_LENGTH = `${PRE_LAYOUT}/clk_section_length`;

// pre layout loading
export const LOADING_TYPE = `${PRE_LAYOUT}/change_loading_type`;

// pre layout locations
export const UPDATE_LOACTIONS = `${PRE_LAYOUT}/update_locations`;
export const FINISH_DRAW = `${PRE_LAYOUT}/finish_draw`;

// auto fill
export const AUTO_FILL = `${PRE_LAYOUT}/auto_fill`;
export const CHANGE_AUTO_FILL = `${PRE_LAYOUT}/change_auto_fill`;

// pre layout initialization
export const PRE_LAYOUT_INIT_STATUS = `${PRE_LAYOUT}/pre_layout_init_status`;

// library change
export const CHANGE_LIBRARY_FILE = `${PRE_LAYOUT}/change_library_file`;

// unit change
export const CHANGE_UNIT = `${PRE_LAYOUT}/change_unit`;
export const CHANGE_DATA_BY_UNIT = `${PRE_LAYOUT}/change_data_by_unit`;

// export excel
export const EXPORT_EXCEL = `${PRE_LAYOUT}/export_excel`;

// import excel
export const IMPORT_EXCEL = `${PRE_LAYOUT}/import_excel`;
export const PRELAYOUT_LOG = `${PRE_LAYOUT}/excel_log`;
export const PRELAYOUT_ERROR = `${PRE_LAYOUT}/excel_error`;

// model preLayout
export const SCHEMATIC_LOADING = `${PRE_LAYOUT}/schematic_loading`;
export const UPDATE_COMPONENTS = `${PRE_LAYOUT}/update_components`;
export const SAVE_MODEL_PRELAYOUT_INFO = `${PRE_LAYOUT}/save_model_preLayout_info`;
export const SAVE_PRE_LAYOUT_MODEL = `${PRE_LAYOUT}/save_pre_layout_model`;
export const ADD_RES_COMPONENTS = `${PRE_LAYOUT}/add_res_components`;
export const CHANGE_PRELAYOUT_TYPE = `${PRE_LAYOUT}/change_pre_layout_type`;
export const UPDATE_PRELAYOUT_PACKAGE_ID = `${PRE_LAYOUT}/update_preLayout_packageId`;
export const SAVE_PRE_LAYOUT_COMPONENTS = `${PRE_LAYOUT}/save_preLayout_components`;
export const CHANGE_INCLUDES_RES = `${PRE_LAYOUT}/change_include_res`;

export const RENAME_PRE_LAYOUT = `${PRE_LAYOUT}/rename_pre_layout`;