import { SERDES_TYPES } from "../../PCBHelper";
import { SortFn } from "../../helper/sort";
import {
  BER,
  PORT_TX_VPKPK,
  JIT_PWRJ,
  JIT_PWDDJ,
  JIT_HFRJ_NUI,
  JIT_LFRJ,
  JIT_LFDDJ,
  ADAPT_CSPACE,
  ADAPT_DC,
  ADAPT_POLE,
  NUI,
  TX_PRE_SHOOT,
  TX_DEEMPHASIS,
  LEQ_DC,
  LEQ_POLE,
  LEQ_DC2,
  LEQ_POLE2,
  ADAPT_PS,
  ADAPT_DE,
  ADAPT_DC2,
  ADAPT_POLE2,
  STATSIM_OPTIONS,
  ADAPT_EQ,
  LEQ_BW,
  LEQ_AC,
  LEQ_BW2,
  LEQ_AC2,
  ADAPT_NUI,
  VPT,
  PRE_CURSORS,
  NUI_IFFT,
  CURSOR_BLOCK,
  ADAPT_LEQ_INDEX1,
  ADAPT_LEQ_INDEX2,
  ADAPT_DFE_H1H0,
  ADAPT_DFE_MAG,
  RXCOEFF,
  LEQ_RESPONSE_INDEX2,
  LEQ_RESPONSE_INDEX1,
  PCIE_5,
  PCIE_4,
  PCIE_3
} from "./constants";
import { getSeasimDefaultValue } from './seaSimAnalysisConfig';
import _ from "lodash";
import { SeaSimChannel } from "./seaSimConfig";

function updateChannelsBySignalAndComp({ channels, signals, newComp, type, signal, isEndToEnd, record, components }) {

  if (isEndToEnd) {
    return updateEndToEndSeaSimChannelsBySignalAndComp({ channels, signals, newComp, type, signal, record, components })
  }

  let _channels = [...channels];
  const filterComps = components.filter(item => SERDES_TYPES.includes(item.type) && item.name !== newComp.name);
  if (signal) {
    const index = _channels.findIndex(item => item.signal === signal);
    if (index < 0) {
      return _channels;
    }
    const pins = findPinsBySignal({ pins: newComp.pins, signals, signal });
    _channels[index][type] = {
      component: pins.length ? newComp.name : "",
      pins
    }
    const anotherType = getControllerOrDevice(type);

    if (filterComps.length >= 1) {

      const pins = findPinsBySignal({ pins: filterComps[0].pins, signals, signal });
      _channels[index][anotherType] = {
        component: pins.length ? filterComps[0].name : "",
        pins
      }
    } else {
      _channels[index][anotherType] = {
        component: "",
        pins: []
      }
    }
    return _channels;
  }

  _channels.forEach(item => {
    const pins = findPinsBySignal({ pins: newComp.pins, signals, signal: item.signal });
    item[type] = {
      component: pins.length ? newComp.name : "",
      pins
    }
    const anotherType = getControllerOrDevice(type);

    if (filterComps.length >= 1) {
      const pins = findPinsBySignal({ pins: filterComps[0].pins, signals, signal: item.signal });
      item[anotherType] = {
        component: pins.length ? filterComps[0].name : "",
        pins
      }
    } else {
      item[anotherType] = {
        component: "",
        pins: []
      }
    }
  })
  return _channels;
}

function findPinsBySignal({ pins, signals, signal }) {
  const signalInfo = signals.find(it => it.name === signal) || { nets_P: [], nets_N: [] };
  const signalPins = pins.filter(it => it.signal === signal);
  const positivePin = findPinBySignal({
    pins: signalPins,
    signalInfo,
    type: "nets_P"
  }),
    negativePin = findPinBySignal({
      pins: signalPins,
      signalInfo,
      type: "nets_N"
    });
  let _pins = [];
  positivePin && _pins.push(positivePin);
  negativePin && _pins.push(negativePin);
  return _pins.length === 2 ? _pins : [];
}

function findPinBySignal({ pins, signalInfo, type }) {
  const find = pins.find(item => signalInfo[type].includes(item.net));
  if (find) {
    return find.pin;
  }
}

function updateEndToEndSeaSimChannelsBySignalAndComp({ channels, signals, newComp, type, signal, record, components }) {
  let _channels = [...channels];

  const filterComps = components.filter(item => SERDES_TYPES.includes(item.type) && newComp.designId !== item.designId);

  if (signal) {
    const pcbSignalInfo = getEndToEndSignal(newComp.pcbIndex, record);
    const index = _channels.findIndex(item => item.signal === signal);
    if (index < 0) {
      return _channels;
    }
    const pins = findPinsBySignal({ pins: newComp.pins, signals: [pcbSignalInfo], signal: pcbSignalInfo.name });
    _channels[index][type] = {
      component: pins.length ? newComp.name : "",
      pins,
      design: pins.length ? { designId: newComp.designId, channelId: newComp.channelId } : {}
    }
    const anotherType = getControllerOrDevice(type);

    if (filterComps.length >= 1) {
      const pcbSignalInfo = getEndToEndSignal(filterComps[0].pcbIndex, record);
      const pins = findPinsBySignal({ pins: filterComps[0].pins, signals: [pcbSignalInfo], signal: pcbSignalInfo.name });
      _channels[index][anotherType] = {
        component: pins.length ? filterComps[0].name : "",
        pins,
        design: pins.length ? { designId: filterComps[0].designId, channelId: filterComps[0].channelId } : {}
      }
    } else {
      _channels[index][anotherType] = {
        component: "",
        pins: [],
        design: {}
      }
    }
    return _channels;
  }

  _channels.forEach(item => {
    const findS = signals.find(it => it.name === item.signal);
    if (findS) {
      const pcbSignalInfo = getEndToEndSignal(newComp.pcbIndex, findS);
      const pins = findPinsBySignal({ pins: newComp.pins, signals: [pcbSignalInfo], signal: pcbSignalInfo.name });
      item[type] = {
        component: pins.length ? newComp.name : "",
        pins,
        design: pins.length ? { designId: newComp.designId, channelId: newComp.channelId } : {}
      }

      const anotherType = getControllerOrDevice(type);

      if (filterComps.length >= 1) {
        const pcbSignalInfo = getEndToEndSignal(filterComps[0].pcbIndex, findS);
        const pins = findPinsBySignal({ pins: filterComps[0].pins, signals: [pcbSignalInfo], signal: pcbSignalInfo.name });
        item[anotherType] = {
          component: pins.length ? filterComps[0].name : "",
          pins,
          design: pins.length ? { designId: filterComps[0].designId, channelId: filterComps[0].channelId } : {}
        }
      } else {
        item[anotherType] = {
          component: "",
          pins: [],
          design: {}
        }
      }
    }
  })
  return _channels;
}

function getEndToEndSignal(pcbIndex, record) {
  if (pcbIndex === 1) {
    return record.firstPCB;
  } else {
    return record.lastPCB;
  }
}

function getSeaSimDisplayText(type) {
  switch (type) {
    case BER:
      return "Bit Error Rate";
    case PORT_TX_VPKPK:
      return "Peak-to-peak Voltage";
    case JIT_PWRJ:
      return "Pulse Width Random Jitter";
    case JIT_PWDDJ:
      return "Pulse Width Dual-Dirac Jitter";
    case JIT_HFRJ_NUI:
      return "Edge RJ Nui earlier than cursor";
    case JIT_LFDDJ:
      return "Low Freq. Dual-Dirac Jitter";
    case JIT_LFRJ:
      return "Low Freq. Random Jitter";
    case ADAPT_CSPACE:
      return "TX FIR Equalization - Search space";
    case ADAPT_DC:
    case LEQ_DC:
      return "First Pole - DC Gain";
    case ADAPT_POLE:
    case LEQ_POLE:
      return "First Pole - Pole";
    case ADAPT_PS:
    case TX_PRE_SHOOT:
      return "Pre-shoot";
    case ADAPT_DE:
    case TX_DEEMPHASIS:
      return "De-emphasis";
    case NUI:
      return "Nui";
    case LEQ_DC2:
    case ADAPT_DC2:
      return "Second Pole - DC Gain";
    case LEQ_POLE2:
    case ADAPT_POLE2:
      return "Second Pole - Pole";
    case LEQ_BW:
      return "First Pole - Bandwidth";
    case LEQ_AC:
      return "First Pole - AC Gain";
    case LEQ_BW2:
      return "Second Pole - Bandwidth";
    case LEQ_AC2:
      return "Second Pole - AC Gain";
    case VPT:
      return "Number of voltage bins";
    case PRE_CURSORS:
      return "Number of pre-cursors for statistical eye";
    case NUI_IFFT:
      return "Minimum number of UI used for ifft";
    case CURSOR_BLOCK:
      return "Number of UI in cursor block";
    case ADAPT_NUI:
      return "Number of post-cursor UI for adaptation";
    case ADAPT_LEQ_INDEX1:
      return "LEQ response search index 1";
    case ADAPT_LEQ_INDEX2:
      return "LEQ response search index 2";
    case LEQ_RESPONSE_INDEX1:
      return "LEQ response index 1";
    case LEQ_RESPONSE_INDEX2:
      return "LEQ response index 2";
    case ADAPT_DFE_H1H0:
      return "DFE taps and max magnitude";
    case ADAPT_DFE_MAG:
      return "Max ratio between DFE h1 and h0";
    case RXCOEFF:
      return "DFE tap values";
    default: return;
  }
}

function updateSignalNameInSeaSimConfig(config, prevSignalName, signalName) {
  const _index = config.channels.findIndex(item => item.signal === prevSignalName);
  if (_index > -1) {
    config.channels[_index].signal = signalName;
  }

  config.analysis.channels.forEach(item => {
    if (item.victim === prevSignalName) {
      item.victim = signalName;
    }

    const index = item.aggressors.findIndex(it => it === prevSignalName);

    if (index > -1) {
      item.aggressors[index] = signalName;
    }
  })
  return config;
}

function delSignalInSeaSimConfig(config, signalName) {

  config.channels = config.channels.filter(item => item.signal !== signalName);

  config.analysis.channels = config.analysis.channels.filter(item => item.victim !== signalName);

  config.analysis.channels.forEach(item => {
    item.aggressors = item.aggressors.filter(it => it !== signalName);
  })
  return config;
}

function updateSeaSimConfigComp({ config, signal, signalName, deletedICConnComps, designId, components }) {
  const index = config.channels.findIndex(item => item.signal === signalName);
  if (index < 0) {
    return config;
  }

  let _channelItem = config.channels[index];
  for (let comp of deletedICConnComps) {
    if (_channelItem.controller
      && _channelItem.controller.component === comp.name
      && _channelItem.controller.pins.includes(comp.pin)
      && (!designId || (_channelItem.controller.design && designId === _channelItem.controller.design.designId))
    ) {
      _channelItem.controller = {
        component: "",
        pins: []
      }
      if (designId) {
        _channelItem.controller.design = {};
      }
    }

    if (_channelItem.device
      && _channelItem.device.component === comp.name
      && _channelItem.device.pins.includes(comp.pin)
      && (!designId || (_channelItem.device.design && designId === _channelItem.device.design.designId))
    ) {
      _channelItem.device = {
        component: "",
        pins: []
      }
      if (designId) {
        _channelItem.device.design = {};
      }
    }
  }

  if (designId) {
    return config;
  }

  const controllerComp = config.channels.find(item => item.controller && item.controller.component);
  const deviceComp = config.channels.find(item => item.device && item.device.component);

  if (controllerComp) {
    const comp = components.find(item => item.name === controllerComp.controller.component) || { pins: [] }
    const pins = findPinsBySignal({ pins: comp.pins, signals: [signal], signal: signal.name });
    config.channels[index].controller = {
      component: pins.length ? comp.name : "",
      pins
    }
  }

  if (deviceComp) {
    const comp = components.find(item => item.name === deviceComp.device.component) || { pins: [] }
    const pins = findPinsBySignal({ pins: comp.pins, signals: [signal], signal: signal.name });
    config.channels[index].device = {
      component: pins.length ? comp.name : "",
      pins
    }
  }

  return config;
}

function getChannels(params = {}) {
  const { channels, analysisChannels, selectedSignals, signals, isEndToEnd, components } = params;
  let list = [], controller = "", device = "";
  for (let item of channels) {
    if (!selectedSignals.includes(item.signal)) {
      continue;
    }
    const analysisChannel = analysisChannels.find(it => it.victim === item.signal) || { aggressors: [] };
    let listItem = {
      ...JSON.parse(JSON.stringify(item)),
      aggressors: analysisChannel.aggressors || []
    }
    if (isEndToEnd) {
      const signal = signals.find(it => it.name === item.signal) || {};
      listItem.firstPCB = signal.firstPCB || {};
      listItem.lastPCB = signal.lastPCB || {};
      let controllerComp = null, deviceComp = null;
      if (item.controller.component && item.controller.design && item.controller.design.designId) {
        const component = components.find(it => item.controller.component === it.name && it.designId === item.controller.design.designId) || {};
        listItem.controller.design = {
          ...listItem.controller.design,
          designName: `PCB_${component.pcbIndex}`
        }
        controllerComp = `PCB_${component.pcbIndex} - ${item.controller.component}`;
      }
      if (item.device.component && item.device.design && item.device.design.designId) {
        const component = components.find(it => item.device.component === it.name && it.designId === item.device.design.designId) || {};
        listItem.device.design = {
          ...listItem.device.design,
          designName: `PCB_${component.pcbIndex}`
        }
        deviceComp = `PCB_${component.pcbIndex} - ${item.device.component}`;
      }
      listItem.error = deviceComp === controllerComp;
      if (!controller) {
        controller = controllerComp;
      }

      if (!device) {
        device = deviceComp;
      }
    } else {
      //if controller comp === device comp, display error
      listItem.error = item.controller && item.controller.component && item.device && item.controller.component && item.controller.component === item.device.component;
      if (!controller) {
        controller = item.controller.component;
      }

      if (!device) {
        device = item.device.component;
      }
    }

    list.push({
      ...listItem
    })
  }
  return { controller, device, dataList: list };
}

function getControllerOrDevice(type) {
  if (type === "controller") {
    return "device";
  } else {
    return "controller";
  }
}

/* 1. When "Equalizer Adaptation" is turned off, the following options should all be a single value. When "Equalizer Adaptation" is on, multiple values can be set.
   1) "DC Gain​", "pole" of first pole and second Pole;
   2) "Pre-shoot​", "De-emphasis"; */
const updateKeys = [
  TX_PRE_SHOOT,
  TX_DEEMPHASIS,
  LEQ_DC,
  LEQ_POLE,
  LEQ_DC2,
  LEQ_POLE2];
function judgeSeasimConfigEQValue(config) {
  if (!config || !Object.keys(config).length || !config.analysis || !config.analysis.options || !config.analysis.options[STATSIM_OPTIONS]) {
    return false;
  }

  if (!config.analysis.options[STATSIM_OPTIONS][ADAPT_EQ]) {
    for (let key of updateKeys) {
      if (Array.isArray(config.analysis.options[STATSIM_OPTIONS][key])) {
        return true;
      }
    }
  }
  return false;
}
/* 1. When "Equalizer Adaptation" is turned off, the following options should all be a single value. When "Equalizer Adaptation" is on, multiple values can be set.
   1) "DC Gain​", "pole" of first pole and second Pole;
   2) "Pre-shoot​", "De-emphasis"; */
function updateSeasimConfigEQ(config) {
  for (let key of updateKeys) {
    if (Array.isArray(config.analysis.options[STATSIM_OPTIONS][key])) {
      const values = config.analysis.options[STATSIM_OPTIONS][key];
      config.analysis.options[STATSIM_OPTIONS][key] = values.length ? values[0] : "0"
    }
  }
  return config;
}

//Compatible with older versions of config, remove unnecessary fields according to "Equalizer Adaptation". Add required fields
function updateSeasimEQConfig(config) {
  if (!config || !Object.keys(config).length) {
    return config;
  }

  let save = false;
  const statsim_options = config.analysis.options.statsim_options || {};
  //statsim_options
  const statsim_options_keys = Object.keys(statsim_options);
  if (statsim_options.adapt_eq) {

    if ([TX_PRE_SHOOT, TX_DEEMPHASIS, LEQ_DC, LEQ_POLE, LEQ_DC2, LEQ_POLE2].filter(item => statsim_options_keys.includes(item)).length) {
      delete statsim_options[TX_PRE_SHOOT];
      delete statsim_options[TX_DEEMPHASIS];
      delete statsim_options[LEQ_DC];
      delete statsim_options[LEQ_POLE];
      delete statsim_options[LEQ_DC2];
      delete statsim_options[LEQ_POLE2];
      save = true;
    }

  } else {
    if ([ADAPT_PS, ADAPT_DE, ADAPT_DC, ADAPT_POLE, ADAPT_DC2, ADAPT_POLE2].filter(item => statsim_options_keys.includes(item)).length) {
      delete statsim_options[ADAPT_PS];
      delete statsim_options[ADAPT_DE];
      delete statsim_options[ADAPT_DC];
      delete statsim_options[ADAPT_POLE];
      delete statsim_options[ADAPT_DC2];
      delete statsim_options[ADAPT_POLE2];
      save = true;
    }
  }
  config.analysis.options.statsim_options = statsim_options;
  return { config, save };
}

function judgeSeasimConfigGeneralValue(config) {
  const updateKeys = [
    VPT,
    PRE_CURSORS,
    NUI_IFFT,
    CURSOR_BLOCK];
  if (!config || !Object.keys(config).length || !config.analysis || !config.analysis.options || !config.analysis.options[STATSIM_OPTIONS]) {
    return false;
  }

  const keyList = Object.keys(config.analysis.options[STATSIM_OPTIONS])
  for (let key of updateKeys) {
    if (!keyList.includes(key)) {
      return true
    }
  }
  return false;
}

function updateSeasimConfigInfo(config) {
  if (!config || !Object.keys(config).length) {
    return config;
  }
  let _config = JSON.parse(JSON.stringify(config))
  let save = false;
  let analysisOptions = config.analysis.options;
  let statsim_options = analysisOptions.statsim_options || {};

  if (!statsim_options.vpt) {
    const addStatsimOptions = getSeasimDefaultValue(analysisOptions.type, statsim_options.adapt_eq);
    statsim_options = {
      ...statsim_options,
      ...addStatsimOptions
    }
    analysisOptions.statsim_options = statsim_options
    save = true
  }
  _config.analysis.options = analysisOptions;
  return { config: _config, save };
}

function updateSeasimConfigSignals({ config, signals, isEndToEnd, components }) {
  let save = false;
  let signalNames = [];
  const seasimSignalNames = config.channels.map(item => item.signal);
  signalNames = (signals || []).map(item => item.name);
  if (_.isEqual(signalNames, seasimSignalNames) || !config || !config.channels) {
    return { config, save: false }
  }

  const addSignals = signalNames.filter(item => !seasimSignalNames.includes(item));
  const delSignals = seasimSignalNames.filter(item => !signalNames.includes(item));
  if (delSignals.length) {
    config.channels = config.channels.filter(item => signalNames.includes(item.signal));
    config.analysis.channels = config.analysis.channels.filter(item => signalNames.includes(item.victim));
    save = true;
  }

  const controllerComp = config.channels.length ? config.channels[0].controller || {} : {};
  const deviceComp = config.channels.length ? config.channels[0].device || {} : {};

  if (addSignals.length) {
    save = true;
    config.channels.push(...addSignals.map(name => {
      const controllerPins = getSeasimCompPins({ comp: controllerComp, components, signals, signal: name, isEndToEnd });
      const devicePins = getSeasimCompPins({ comp: deviceComp, components, signals, signal: name, isEndToEnd })
      return new SeaSimChannel({
        signal: name,
        controller: {
          component: controllerPins.length ? controllerComp.component : "",
          pins: [...controllerPins],
          design: {
            designId: controllerPins.length ? (controllerComp.design || {}).designId || "" : "",
            channelId: controllerPins.length ? (controllerComp.design || {}).channelId || "" : ""
          }
        },
        device: {
          component: devicePins.length ? deviceComp.component : "",
          pins: [...devicePins],
          design: {
            designId: devicePins.length ? (deviceComp.design || {}).designId || "" : "",
            channelId: devicePins.length ? (deviceComp.design || {}).channelId || "" : ""
          }
        }
      })
    }))
    config.analysis.channels.push(...addSignals.map(item => { return { victim: item, aggressors: [] } }))
    config.channels = SortFn(config.channels, signalNames, "signal");
  }

  return { config, save }
}

function getSeasimCompPins({ comp, components, signals, signal, isEndToEnd }) {
  let newComp = null;
  if (!comp || !comp.component) {
    return []
  }
  if (isEndToEnd) {
    newComp = components.find(item => comp.component === item.name
      && comp.design
      && item.designId === comp.design.designId
      && item.channelId === comp.design.channelId);
  } else {
    newComp = components.find(item => item.name === comp.component);
  }

  const pins = findPinsBySignal({ pins: newComp.pins, signals, signal })

  return pins && pins.length ? pins : [];
}

function updateAdsConfigDFEValue(options) {

  if (!options || !Object.keys(options).length) {
    return options;
  }

  let statsim_options = options.statsim_options ? JSON.parse(JSON.stringify(options.statsim_options)) : {};
  const type = options.type || '';
  statsim_options.use_leq_response = false;

  if (statsim_options.adapt_eq) {
    statsim_options.use_adapt_TX_preset = false;
    let adapt_TX_preset = [], adapt_DFE_mag = [], adapt_DFE_h1h0 = 1;
    if (type === PCIE_5) {
      adapt_TX_preset = ["P0", "P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9"];
      adapt_DFE_mag = [0.06, 0.02, 0.02];
      adapt_DFE_h1h0 = 0.8
    } else if (type === PCIE_4) {
      adapt_DFE_mag = [0.03, 0.02];
    } else if (type === PCIE_3) {
      adapt_DFE_mag = [0.03];
    }

    statsim_options = {
      ...statsim_options,
      adapt_TX_preset,
      adapt_DFE_mag,
      adapt_DFE_h1h0
    }
  } else {
    statsim_options.rxcoeff = [0];
  }
  options.statsim_options = statsim_options
  return options;

}

export {
  updateChannelsBySignalAndComp,
  getSeaSimDisplayText,
  updateSignalNameInSeaSimConfig,
  delSignalInSeaSimConfig,
  updateSeaSimConfigComp,
  findPinsBySignal,
  getChannels,
  judgeSeasimConfigEQValue,
  updateSeasimConfigEQ,
  updateSeasimEQConfig,
  updateSeasimConfigInfo,
  judgeSeasimConfigGeneralValue,
  updateSeasimConfigSignals,
  updateAdsConfigDFEValue
}