import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { EyeOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Select, Tooltip, Dropdown } from 'antd';
import EditableTable from '@/components/EditableTable';
import CalculateTable from './calculateTable';
import { getLibraryData } from '@/services/PDN/library';
import { changeLibraryFormat } from '@/services/helper/rlcValueFormat';
import { SortFn } from '@/services/helper/sort';
import { compareAndUpdateHeight, updateElePosition } from '@/services/helper/htmlElement';
import { resProcessing, getVRMData } from '@/services/helper/sparameter/resProcess';
import { DECAP_TOUCHSTONE } from '@/constants/libraryConstants';
import './new.css';

const { Option } = Select;
const ComponentsColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '18%'
}, {
  title: 'Components',
  dataIndex: 'selectComps',
  width: '40%',
}, {
  title: 'Quantity',
  dataIndex: 'quantity',
  width: '12%'
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '30%',
}];

const defaultColumns = [{
  title: 'Part Number',
  dataIndex: 'part',
  width: '25%',
}, {
  title: 'Components',
  dataIndex: 'selectComps',
  width: '25%',
}, {
  title: 'Quantity',
  dataIndex: 'quantity',
  width: '25%',
}, {
  title: 'Model',
  dataIndex: 'modelName',
  width: '25%',
}];

const VRMColumns = [{
  title: 'Eq. Pwr Pin',
  dataIndex: 'powerPins',
  width: '30%',
}, {
  title: 'Eq. Gnd Pin',
  dataIndex: 'groundPins',
  width: '30%',
}, {
  title: 'VRM Model',
  dataIndex: 'vrm',
  width: '40%',
  className: 'vrm-model-seletion'
}];

const VRMColumns_Cal = [{
  title: 'Eq. Pwr Pin',
  dataIndex: 'powerPins',
  width: '22%',
}, {
  title: 'Eq. Gnd Pin',
  dataIndex: 'groundPins',
  width: '22%',
}, {
  title: 'VRM Model',
  dataIndex: 'vrm',
  width: '30%',
  className: 'vrm-model-seletion'
}, {
  title: 'Calculated VRM Model',
  dataIndex: 'cacl',
  width: '36%',
  className: 'vrm-model-seletion'
}];

const currentResult = 'Current Result';
class HistoryPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maximize: false,
      panelWidth: null,
      panelHeight: null,
      basicVrmColumns: VRMColumns,
      compareVrmColumns: VRMColumns,
      compareInfo: { name: '' },
      compareVRMModel: '',
      compareVRMOpt: '',
      basicInfo: { name: '' },
      basicVRMModel: '',
      basicVRMOpt: '',
      optBasicInfo: null,
      optCompareInfo: null
    }
    this.dialogRoot = document.getElementById('root');

    VRMColumns[0].render = (data, record) => {
      return record.powerPin.length > 0
        ? <span>{record.powerPin.join(", ")}</span>
        : <span className='vrm-default-name'>Default</span>
    };

    VRMColumns[1].render = (data, record) => {
      return record.groundPin.length > 0
        ? <span>{record.groundPin.join(", ")}</span>
        : <span className='vrm-default-name'>Default</span>
    };

    VRMColumns[2].render = (data, record, index) => {
      return <Tooltip title={data} overlayClassName='aurora-tooltip'>
        <span className='pdn-result-history-model'>{data}</span>
      </Tooltip>
    };

    VRMColumns_Cal[0].render = (data, record) => {
      return record.powerPin.length > 0
        ? <span>{record.powerPin.join(", ")}</span>
        : <span className='vrm-default-name'>Default</span>
    };

    VRMColumns_Cal[1].render = (data, record) => {
      return record.groundPin.length > 0
        ? <span>{record.groundPin.join(", ")}</span>
        : <span className='vrm-default-name'>Default</span>
    };

    VRMColumns_Cal[2].render = (data, record, index) => {
      return <Tooltip title={data} overlayClassName='aurora-tooltip'>
        <span className='pdn-result-history-model'>{data}</span>
      </Tooltip>
    };

    VRMColumns_Cal[3].render = (data, record, index) => {
      return <Tooltip title={data} overlayClassName='aurora-tooltip'>
        <span className='pdn-result-history-model'>{data}</span>
      </Tooltip>
    };

    ComponentsColumns[0].render = (text, record) => {
      return <Tooltip title={text} overlayClassName='aurora-tooltip'>
        <div className='pdn-result-component-part'>{text}</div>
      </Tooltip>
    };

    ComponentsColumns[1].render = (selectComps, record) => {
      const CSSID = record.type === 'opt' ? `opt-td-${record.displayType}-${record.part}` : `td-${record.displayType}-${record.part}`;
      return <Fragment>
        <div className='pdn-component-td' id={CSSID}>
          <span className='pdn-result-verticalAlign-middle-span'></span>
          <span className='pdn-result-verticalAlign-middle-components-span'>{selectComps.join(', ')}</span>
        </div>
      </Fragment>
    };

    ComponentsColumns[2].render = (value, record) => {
      return <span>{record.selectComps.length}</span>
    }

    ComponentsColumns[3].render = (data, record) => {
      const { value, model, models } = record;
      if (record.usage === 'Cap') {
        if (models && models.length) {
          const modelTextList = models.map((item) => this.getModelName(item));
          return <Tooltip
            title={modelTextList.join(", ")}
            overlayClassName='aurora-tooltip'
            placement='topLeft'
          >
            <div>{modelTextList.join(", ")}</div>
          </Tooltip>
        } else if (value && (value.r || value.l || value.c)) {
          return <span>
            R = {(value.r && (value.r + 'Ω')) || '0'}, L = {(value.l && (value.l + 'H')) || '0H'}, C = {(value.c && (value.c + 'F')) || '0F'}
          </span>
        } else {
          const { libraryType, subcktName, name, libraryData, type } = model || {};
          if (libraryType && libraryType === 'file') {
            let showName = type === DECAP_TOUCHSTONE ? name : subcktName;
            let showTip = type === DECAP_TOUCHSTONE ? <div>Model: {name}</div> : <Fragment><div>Model: {name}</div><div>Subckt: {subcktName}</div></Fragment>
            return <Tooltip
              title={showTip}
              overlayClassName='aurora-tooltip'
              placement='topLeft'
            >
              <span className='pdn-result-history-model'>{showName}</span>
            </Tooltip>
          } else {
            let showTip = type === DECAP_TOUCHSTONE ? <div>Model: {name}</div>
              : libraryData ? <Fragment><div>R = {libraryData.R}, L = {libraryData.L}, C = {libraryData.C}</div></Fragment> : null
            return <Tooltip
              title={showTip}
              overlayClassName='aurora-tooltip'
              placement='topLeft'
            >
              <span>{name}</span>
            </Tooltip>
          }
        }
      } else if (record.usage !== 'Ignore' && record.usage !== 'Unused') {
        return <span>{value}</span>
      } else {
        return null;
      }
    }
  }

  getModelName = (model = {}) => {
    switch (model.type) {
      case "touchstone":
        return model.folderName ? `${model.folderName}::${model.name}` : model.name;
      case "spice":
        return `${model.name}::${model.subcktName}`;
      case "rlc":
        return model.libraryData ? `${model.name}: R = ${model.libraryData.R},L = ${model.libraryData.L},C = ${model.libraryData.C}` : `${model.name}`
      default: return null;
    }
  }

  getDefaultWidth = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 800;
    let _width = width * 0.7;
    _width = _width < 400 ? 400 : _width;
    return _width;
  }

  getDefaultHeight = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { height } = offset;
    if (!height) return 500;
    let _height = height * 0.7;
    _height = _height < 200 ? 200 : _height;
    _height = _height > 800 ? 800 : _height;
    return _height;
  }

  getComponent = (Components, type) => {
    Components.forEach(comp => {
      comp.displayType = type;
      if (comp.usage === "Cap" && comp.models && comp.models.length) {
        comp.models.forEach(item => {
          if (item.type === "rlc") {
            let id = item.id;
            getLibraryData(id).then(res => {
              if (res.libraryData) {
                const data = res.libraryData;
                const library = changeLibraryFormat(data);
                item.libraryData = {
                  R: `${library.libraryData.R}${library.RUnit}`,
                  L: `${library.libraryData.L}${library.LUnit}`,
                  C: `${library.libraryData.C}${library.CUnit}`,
                }
              }
            })
          }
        })
      }
      else if (comp.usage === 'Cap' && comp.model && comp.model.libraryType === 'data' && !comp.model.libraryData) {
        let id = comp.model.id;
        getLibraryData(id).then(res => {
          if (res.libraryData) {
            const data = res.libraryData;
            const library = changeLibraryFormat(data);
            comp.model.libraryData = {
              R: `${library.libraryData.R}${library.RUnit}`,
              L: `${library.libraryData.L}${library.LUnit}`,
              C: `${library.libraryData.C}${library.CUnit}`,
            }
          }
        })
      }
    })
    return Components;
  }

  Menu = (components) => {
    return [
      { key: 'view_stuffed', label: 'Stuffed', onClick: (e) => this.props.actions.viewStuffedDecap(e, components) },
      { key: 'view_unStuffed', label: 'UnStuffed', onClick: (e) => this.props.actions.viewUnStuffedDecap(e, components) }
    ]
  }

  getOptComponent = (CurrentInfo, optCurrentInfo, type) => {
    if (!CurrentInfo || !optCurrentInfo) {
      return;
    };
    let newDecaps = JSON.parse(JSON.stringify(CurrentInfo));
    const optNames = optCurrentInfo.decaps.map(item => item.name);
    // TODO: decap split
    newDecaps.forEach(item => {
      for (let comp of item.comps) {
        if (comp.usage === 'Unused') {
          continue;
        } else if (comp.usage === 'Cap') {
          if (optNames.includes(comp.name)) {
            continue;
          } else {
            comp.usage = 'Unused';
          }
        }
      };
      item.selectComps = item.comps.filter(comp => comp.usage === 'Cap').map(comp => comp.name);
      item.unusedComps = item.comps.filter(comp => comp.usage === 'Unused').map(comp => comp.name);
      item.type = 'opt';
      item.displayType = type;
    });
    return newDecaps;
  }

  componentDidMount = () => {
    const { existResult, VRMModel } = this.props;
    if (existResult) {
      this.getInfo('basic', 'current');
    }
    //update component table td height to ensure that the height of the compare component is consistent with the basic
    setTimeout(() => {
      this.updateCompTdHeight();
    }, 500);

    if (VRMModel) {
      this.setState({ basicVrmColumns: VRMColumns_Cal })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //update vrm columns
    const { basicVRMModel: prevBasicVRMModel, compareVRMModel: prevCompareVRMModel } = prevState;
    const { basicVRMModel, compareVRMModel } = this.state;

    if (basicVRMModel !== prevBasicVRMModel) {
      const basicVrmColumns = basicVRMModel ? VRMColumns_Cal : VRMColumns;
      this.setState({ basicVrmColumns });
    }

    if (compareVRMModel !== prevCompareVRMModel) {
      const compareVrmColumns = compareVRMModel ? VRMColumns_Cal : VRMColumns;
      this.setState({ compareVrmColumns });
    }
  }

  changeSizeMax = (e) => {
    e.stopPropagation();
    const { maximize } = this.state;
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    let _width = 600, _height = 600;
    if (!maximize) {

      if (!width) {
        _width = 1000;
      };
      _width = width - 40;

      if (!height) {
        _height = 1000
      };
      _height = height - 40;
    } else {
      _width = this.getDefaultWidth();
      _height = this.getDefaultHeight();
    }

    this.setState({
      maximize: !maximize,
      panelWidth: _width,
      panelHeight: _height
    }, () => {
      //update component table td height to ensure that the height of the compare component is consistent with the basic
      this.updateCompTdHeight();
    })
  }

  updateCompTdHeight = () => {
    const { basicComponents, compareComponents, optBasicComponents, optCompareComponents } = this.getTargetComponents();

    this.changeTdHeight(basicComponents, compareComponents, 'origin');
    this.changeTdHeight(optBasicComponents, optCompareComponents, 'opt');
  }

  getTargetComponents = () => {
    //update component table td height to ensure that the height of the compare component is consistent with the basic
    const { basicInfo, compareInfo, optBasicInfo, optCompareInfo } = this.state;
    //get components
    const basicComps = basicInfo.info && basicInfo.info.Components ? this.getComponent(basicInfo.info.Components, 'basic') : [];
    const compareComps = compareInfo.info && compareInfo.info.Components ? this.getComponent(compareInfo.info.Components, 'compare') : [];
    //sort by part
    const { basicComponents, compareComponents } = this.sortComponents(basicComps, compareComps);
    //get opt components
    const optBasicComps = optBasicInfo && basicInfo && basicInfo.info ? this.getOptComponent(basicInfo.info.Components, optBasicInfo, 'basic') : [];
    const optCompareComps = optCompareInfo && compareInfo && compareInfo.info ? this.getOptComponent(compareInfo.info.Components, optCompareInfo, 'compare') : [];
    //sort by part
    const compsObj = this.sortComponents(optBasicComps, optCompareComps);
    const optBasicComponents = compsObj.basicComponents;
    const optCompareComponents = compsObj.compareComponents;
    return { basicComponents, compareComponents, optBasicComponents, optCompareComponents };
  }

  changeTdHeight = (basicComponents, compareComponents, type) => {
    setTimeout(() => {
      //update component table td height
      //ensure that the height of the compare component td is consistent with the basic
      const basicParts = basicComponents.map(item => item.part);
      const compareParts = compareComponents.map(item => item.part);
      const sameParts = compareParts.filter(item => basicParts.includes(item));
      sameParts.forEach(item => {
        //update component table td height
        const basicCSSID = type === 'origin' ? `td-basic-${item}` : `opt-td-basic-${item}`;
        const compareCSSID = type === 'origin' ? `td-compare-${item}` : `opt-td-compare-${item}`;
        compareAndUpdateHeight(basicCSSID, compareCSSID);
      });

      //update basic div and compare div height 
      //ensure that the height of the compare div is consistent with the basic div
      const basicContentCSSID = "pdn-history-content-basic";
      const compareContentCSSID = "pdn-history-content-compare";
      const fatherCSSID = "pdn-result-history-main";
      compareAndUpdateHeight(basicContentCSSID, compareContentCSSID, fatherCSSID);

      //The positioning of components table and opt table ensures that basic and compare are consistent
      const basicTopCSSID = "pdn-result-history-basic-top";
      const compareTopCSSID = "pdn-result-history-compare-top";
      const basicCompCSSID = "pdn-result-history-basic-components";
      const compareCompCSSID = "pdn-result-history-compare-components";
      const basicOPTCSSID = "pdn-result-history-basic-opt";
      const compareOPTCSSID = "pdn-result-history-compare-opt";
      const basicVRMCSSID = "pdn-result-history-basic-vrm";
      const compareVRMCSSID = "pdn-result-history-compare-vrm"
      updateElePosition({ basicTopCSSID, compareTopCSSID, basicCompCSSID, compareCompCSSID, basicOPTCSSID, compareOPTCSSID, basicVRMCSSID, compareVRMCSSID });
    }, 200);
  }

  PanelResizeEnd = () => {
    //After the panel changes its size, update td height to ensure that the height of the compare component is consistent with the basic
    this.updateCompTdHeight();
  }

  sortComponents = (basicComps, compareComps) => {
    const basicParts = basicComps.map(item => item.part);
    const compareParts = compareComps.map(item => item.part);
    //find same part list
    let sameParts = compareParts.filter(item => basicParts.includes(item));
    const newBasicParts = [...sameParts, ...basicParts.filter(item => !sameParts.includes(item))];
    const newCompareParts = [...sameParts, ...compareParts.filter(item => !sameParts.includes(item))];
    //sort by part
    const newBasicComps = SortFn(basicComps, newBasicParts, 'part');
    const newCompareComps = SortFn(compareComps, newCompareParts, 'part');
    return { basicComponents: newBasicComps, compareComponents: newCompareComps }
  }

  getInfo = (type, key) => {
    const { historyNameList, files, historyData } = this.props.actions;
    const { currentProjectPDNs, pdnId, projectId } = this.props;
    let info = { name: "" };
    if (key === 'current') {
      info = { name: 'Current Result', time: 'current' };
    } else {
      let currentInfo = historyNameList.find(item => item.time === key);
      info = { name: currentInfo ? currentInfo.name : null, time: key };
    }
    let stateInfo = {};
    stateInfo[`${type}Info`] = info;
    this.setState({
      ...stateInfo
    });
    const _type = type === 'basic' ? 'Basic' : "Compare";

    let currentPdn = currentProjectPDNs.find(item => item.id === pdnId);
    let pdnName = currentPdn && currentPdn.name;
    historyData.getContent({ projectId, pdnId, time: key, pdnName }).then(res => {
      if (res) {
        stateInfo[`${type}Info`].info = { VRM: [...res.VRM], Components: [...res.Components] };
        this.setState({
          ...stateInfo
        });
      }
    });
    historyData.getVRMModel({ projectId, pdnId, time: key }).then(res => {
      let stateVrmInfo = {};
      if (res) {
        const data = res ? resProcessing(res) : '';
        stateVrmInfo[`${type}VRMModel`] = data;
      } else {
        stateVrmInfo[`${type}VRMModel`] = "";
      }
      this.setState({ ...stateVrmInfo });
    })

    let findFiles = files.filter(item => item.time === info.time);
    let fileTypes = findFiles.map(item => item.type);
    if (fileTypes && fileTypes.includes('opt')) {
      historyData.getOptCfg({ projectId, pdnId, time: info.time }).then(res => {
        if (res) {
          let stateOPTInfo = {};
          stateOPTInfo[`opt${_type}Info`] = res;
          this.setState({
            ...stateOPTInfo
          });
        }
      })
      historyData.getVRMOpt({ projectId, pdnId, time: info.time }).then(res => {
        let stateVrmOPTInfo = {};
        if (res) {
          const str = res ? resProcessing(res) : '';
          const data = getVRMData(str);
          stateVrmOPTInfo[`${type}VRMOpt`] = data;
        } else {
          stateVrmOPTInfo[`${type}VRMOpt`] = "";
        }
        this.setState({
          ...stateVrmOPTInfo
        });
      })
    } else {
      let _stateInfo = {};
      _stateInfo[`opt${_type}Info`] = null;
      _stateInfo[`${type}VRMOpt`] = "";
      this.setState({
        ..._stateInfo
      });
    }

    setTimeout(() => {
      //After the panel changes its size, update td height to ensure that the height of the compare component is consistent with the basic
      this.updateCompTdHeight();
    }, 400);
  }

  changeFirst = (key) => {
    this.getInfo('basic', key);
  }

  changeEnd = (key) => {
    this.getInfo('compare', key);
  }

  render() {
    const { historyNameList, closeModal } = this.props.actions;
    const { existResult, viewList } = this.props;
    const { basicVrmColumns, compareVrmColumns, basicVRMModel, compareVRMModel, basicInfo, compareInfo, basicVRMOpt, compareVRMOpt } = this.state;

    let basicVrm = basicInfo.info && basicInfo.info.VRM ? basicInfo.info.VRM : [];
    let compareVrm = compareInfo.info && compareInfo.info.VRM ? compareInfo.info.VRM : [];
    basicVrm = (basicVRMModel ? basicVrm.map(item => { return { ...item, cacl: basicVRMModel } }) : basicVrm).map((record, index) => ({ ...record, index }));
    compareVrm = (compareVRMModel ? compareVrm.map(item => { return { ...item, cacl: compareVRMModel } }) : compareVrm).map((record, index) => ({ ...record, index }));
    const { basicComponents, compareComponents, optBasicComponents, optCompareComponents } = this.getTargetComponents();
    let view = false;
    if (viewList && viewList.includes('PCB')) {
      view = true;
    }

    const width = this.getDefaultWidth();
    const { maximize, panelWidth, panelHeight } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='pdn-result-history-panel'
          position={maximize ? 'panel-center-max' : 'panel-center-left'}
          zIndex={2000}
          onCancel={() => { closeModal() }}
          draggable
          minWidth={388}
          minHeight={200}
          width={maximize ? panelWidth : width}
          height={maximize ? panelHeight : this.getDefaultHeight()}
          title={<Fragment>
            <span>History</span>
            {!maximize ?
              <FullscreenOutlined
                title='maximize'
                className='pdn-result-panel-maximize'
                onClick={(e) => { this.changeSizeMax(e) }} />
              : <FullscreenExitOutlined
                title='minimize'
                className='pdn-result-panel-maximize'
                onClick={(e) => { this.changeSizeMax(e) }} />
            }
          </Fragment>}
          resizeEnd={this.PanelResizeEnd}
        >
          <div className='pdn-result-history-panel-main clear' id='pdn-result-history-main'>
            <div style={{ background: '#f2f7fa' }} id='pdn-history-content-basic' className='pdn-history-panel-content-basic'>
              <div id='pdn-result-history-basic-top'>
                <div className='pdn-result-history-select-box'>
                  <span className="font-bold">Basic:</span>
                  <Select value={basicInfo && basicInfo.name} onChange={this.changeFirst} className='pdn-result-history-select' popupClassName='pdn-result-history-select-dropdown'>
                    {historyNameList.map(item =>
                      compareInfo && item.name !== compareInfo.name &&
                      <Option
                        key={item.time}
                        title={item.name}
                        className={basicInfo && basicInfo.name === item.name ? 'ant-select-dropdown-menu-item-selected' : ''}>
                        {item.name}
                      </Option>
                    )}
                    {existResult ? <Option
                      key={'current'}
                      className={basicInfo && basicInfo.name === currentResult ? 'ant-select-dropdown-menu-item-selected' : ''}
                      title={currentResult}
                    >{currentResult}</Option> : null}
                  </Select>
                </div>
                <span className="pdn-result-history-span">VRM</span>
                <div className='pdn-result-history-table'>
                  <EditableTable
                    rowKey={(record) => record.index}
                    columns={basicVrmColumns}
                    size="small"
                    dataSource={basicVrm}
                    scroll={basicVrm.length ? { x: 560 } : {}}
                    className={`pdn-vrm-tables`}
                  />
                </div>
              </div>
              <div id='pdn-result-history-basic-components'>
                <span>
                  <span className="pdn-result-history-span">Components</span>
                  <TotalDecap comps={basicComponents} />
                  {basicComponents.length > 0 ? <Dropdown menu={{ items: this.Menu(basicComponents), className: "view-dropdown-button" }} trigger={['click']} overlayClassName='pdn-result-history-dropdown' disabled={!view}>
                    <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                      <EyeOutlined className="pdn-result-view-icon" />
                    </Tooltip>
                  </Dropdown> : null}
                </span>
                <div className='pdn-result-history-table'>
                  <EditableTable
                    rowKey="part"
                    columns={basicComponents.length > 0 ? ComponentsColumns : defaultColumns}
                    size="small"
                    dataSource={basicComponents}
                    scroll={basicComponents.length ? { x: 710 } : {}}
                    className='pdn-result-components-table'
                  />
                </div>
              </div>
              {optBasicComponents && optBasicComponents.length > 0 ? <div id='pdn-result-history-basic-opt'><span>
                <span className="pdn-result-history-span">Decap optimization</span>
                <TotalDecap comps={optBasicComponents} />
                {optBasicComponents.length > 0 ? <Dropdown menu={{ items: this.Menu(optBasicComponents), className: "view-dropdown-button" }} trigger={['click']} overlayClassName='pdn-result-history-dropdown' disabled={!view}>
                  <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                    <EyeOutlined className="pdn-result-view-icon" />
                  </Tooltip>
                </Dropdown> : null}
              </span>
                <div className='pdn-result-history-table'>
                  <EditableTable
                    rowKey="part"
                    columns={optBasicComponents && optBasicComponents.length > 0 ? ComponentsColumns : defaultColumns}
                    size="small"
                    dataSource={optBasicComponents ? optBasicComponents : []}
                    scroll={optBasicComponents && optBasicComponents.length ? { x: 710 } : {}}
                    className='pdn-result-components-table'
                  />
                </div></div> : null}
              {basicVRMOpt ? <div className='pdn-result-history-vrm-table pdn-result-history-select-box' id='pdn-result-history-basic-vrm'>
                <CalculateTable
                  title='Calculated VRM After Optimization'
                  dataSource={basicVRMOpt}
                />
              </div> : null}
            </div>
            <div style={{ background: 'rgb(220, 241, 253)' }} id='pdn-history-content-compare' className='pdn-history-panel-content-compare'>
              <div id='pdn-result-history-compare-top'>
                <div className='pdn-result-history-select-box'>
                  <span className="font-bold">Compare:</span>
                  <Select value={compareInfo && compareInfo.name} onChange={this.changeEnd} className='pdn-result-history-select' popupClassName='pdn-result-history-select-dropdown'>
                    {historyNameList.map(item =>
                      basicInfo && item.name !== basicInfo.name &&
                      <Option
                        key={item.time}
                        title={item.name}
                        className={compareInfo && compareInfo.name === item.name ? 'ant-select-dropdown-menu-item-selected' : ''}
                      >{item.name}</Option>
                    )}
                  </Select>
                </div>
                <span className="pdn-result-history-span">VRM</span>
                <div className='pdn-result-history-table'>
                  <EditableTable
                    rowKey={(record) => record.index}
                    columns={compareVrmColumns}
                    size="small"
                    dataSource={compareVrm}
                    scroll={compareVrm.length ? { x: 560 } : {}}
                    className={`pdn-vrm-tables`}
                  />
                </div>
              </div>
              <div id='pdn-result-history-compare-components'>
                <span>
                  <span className="pdn-result-history-span">Components</span>
                  <TotalDecap comps={compareComponents} />
                  {compareComponents && compareComponents.length > 0 ? <Dropdown menu={{ items: this.Menu(compareComponents), className: "view-dropdown-button" }} trigger={['click']} overlayClassName='pdn-result-history-dropdown' disabled={!view}>
                    <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                      <EyeOutlined className="pdn-result-view-icon" />
                    </Tooltip>
                  </Dropdown> : null}
                </span>
                <div className='pdn-result-history-table'>
                  <EditableTable
                    rowKey="part"
                    columns={compareComponents && compareComponents.length > 0 ? ComponentsColumns : defaultColumns}
                    size="small"
                    dataSource={compareComponents ? compareComponents : []}
                    scroll={compareComponents && compareComponents.length ? { x: 710 } : {}}
                    className='pdn-result-components-table'
                  />
                </div>
              </div>
              {optCompareComponents.length > 0 ? <div id='pdn-result-history-compare-opt'><span>
                <span className="pdn-result-history-span">Decap optimization</span>
                <TotalDecap comps={optCompareComponents} />
                {optCompareComponents.length > 0 ? <Dropdown menu={{ items: this.Menu(optCompareComponents), className: "view-dropdown-button" }} trigger={['click']} overlayClassName='pdn-result-history-dropdown' disabled={!view}>
                  <Tooltip title={!view ? 'Please Open PCB Layout' : 'View In Layout'} overlayClassName='icon-tooltip'>
                    <EyeOutlined className="pdn-result-view-icon" />
                  </Tooltip>
                </Dropdown> : null}
              </span>
                <div className='pdn-result-history-table'>
                  <EditableTable
                    rowKey="part"
                    columns={optCompareComponents.length > 0 ? ComponentsColumns : defaultColumns}
                    size="small"
                    dataSource={optCompareComponents}
                    scroll={optCompareComponents.length ? { x: 710 } : {}}
                    className='pdn-result-components-table'
                  />
                </div></div> : null}
              {compareVRMOpt ? <div className='pdn-result-history-vrm-table pdn-result-history-select-box' id='pdn-result-history-compare-vrm'>
                <CalculateTable
                  title='Calculated VRM After Optimization'
                  dataSource={compareVRMOpt}
                />
              </div> : null}
            </div>
          </div>
        </Panel>
      </Fragment >
    )
    return createPortal(content, this.dialogRoot);
  }
}

const TotalDecap = (props) => {
  let count = 0;
  for (let { selectComps } of props.comps) {
    count += selectComps.length;
  }
  return count > 0 ? (
    <span className='pdn-opt-sum-title'>(<span style={{ fontWeight: "bold" }}>{count}</span> stuffed decaps)</span>
  ) : null
}

const mapState = (state) => {
  const {
    pdn: { VRMModel },
    project: { currentProjectPDNs, currentProjectId, PDNID, viewList },
    simulationReducer: { existResult }
  } = state.PDNReducer;
  return {
    currentProjectPDNs,
    projectId: currentProjectId,
    pdnId: PDNID,
    VRMModel,
    viewList,
    existResult
  }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(HistoryPanel);