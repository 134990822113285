import {
  CLOSE_TAB_FOOTER, OPEN_TAB_FOOTER, CHANGE_TAB_MENU, CHANGE_PROJECT,
  CHANGE_PDN, CLEAN_MONITOR_MSG, DEBUG_MONITOR
} from './actionTypes';
import { USER_LOGOUT } from '../../../Login/actionTypes';
import {
  CHANGE_PROJECT as _CHANGE_PROJECT,
  CHANGE_VERIFICATION as _CHANGE_VERIFICATION
} from '../../../tabMonitor/actionTypes';

const defaultState = {
  tabVisible: false,
  tabSelectKeys: ['monitor'],
  projectName: null,
  pdnName: null,
  menuType: 'simulation',
  currentVerificationId: null,
  debugMonitor: []
}

export const TabMonitorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLOSE_TAB_FOOTER:
      return {
        ...state,
        tabVisible: false
      }
    case OPEN_TAB_FOOTER:
      return {
        ...state,
        tabVisible: true
      }
    case CHANGE_TAB_MENU:
      return {
        ...state,
        tabSelectKeys: action.selectKeys,
        menuType: action.menuType,
        currentVerificationId: action.verificationId,
        currentProjectId: action.projectId
      }
    case CHANGE_PROJECT:
      return {
        ...state,
        projectName: action.projectName,
        pdnName: null,
        debugMonitor: []
      }
    case CHANGE_PDN:
      return {
        ...state,
        pdnName: action.pdnName,
        debugMonitor: []
      }
    case CLEAN_MONITOR_MSG:
      return {
        ...state,
        projectName: null,
        pdnName: null,
        currentVerificationId: null,
        tabVisible: false,
        debugMonitor: []
      }
    case DEBUG_MONITOR:
      return {
        ...state,
        debugMonitor: [...state.debugMonitor, ...action.msg]
      }
    case USER_LOGOUT:
      return {
        ...defaultState
      }
    case _CHANGE_PROJECT:
    case _CHANGE_VERIFICATION:
      return {
        ...state,
        debugMonitor: []
      }
    default: return state
  }
};