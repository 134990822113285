import { call, put, fork, take, cancel, takeEvery, select, delay } from 'redux-saga/effects';
import { message } from 'antd';
import {
  ADD_SIGNAL,
  GET_VERIFICATION_CONTENT,
  EDIT_SIGNAL_NAME,
  AUTO_SAVE_CONFIG_TO_SERVER,
  SAVE_CONFIG_TO_SERVER,
  EDIT_SIGNAL,
  UPDATE_INTERFACES,
  EDIT_CHANNEL_TYPE,
  DELETE_SIGNAL,
  CHANGE_SERDES_TYPE,
  CHANGE_DATA_RATE,
  SAVE_EXTRACTION,
  SAVE_CURRENT_VERIFICATION,
  CURRENT_VERIFICATION_DEBUG_VERIFY
} from './actionTypes';
import { updateAndesInterface, getVerificationContent, updateAndesContent, updateAndesInfo, updateInterfaces, updateNetsComps } from './action';
import {
  Signal,
  RLCCONComponent
} from '@/services/Andes/IntegratedInterface';
import { updateViewStatus, saveComponentsNetsInProject, autoGetVerificationList, updatePCBComponentsNets, updateCurrentVerificationStatus } from '../project/action';
import { CHANGE_VIEW } from '../project/actionTypes';
import {
  getVerificationContentPromise, updateInterfacePromise,
  BasicComponent, deleteCompsConnectWithNets, findPowerNetsByRes,
  getComponentsWithNetList, createInterfacePromise, deleteInterfacePromise
} from '@/services/Andes';
import AndesVerify from '@/services/Andes/AndesVerifyDatabase';
import { Extraction, AndesInterface } from '@/services/Andes/AndesInterface';
import { BasicCompModel } from '../../../../services/Andes/helper/Model';
import { checkExistNets } from '../../../../services/Andes';
import {
  checkVerificationStatus,
  getMonitor
} from '@/services/workflow/workflow';
import { checkRLCValue } from '@/services/helper/dataProcess';
import { VERIFY_SUCCESS } from '@/constants/verificationStatus';
import {
  getInterfaceWorkStatus
} from '@/services/project';
import DesignInfo from '@/services/Andes/pcbInfo';
import { ANDES_SETUP_VERSION } from '@/version';
import {
  updateErrorCheckList,
  verificationFlow,
  singleVerifyInfo,
  existResult,
  cleanSingleProgress,
  updateSingleMonitor,
  cleanMonitor,
  waitingIndexAction,
  updateSimulationMsg,
  cancelShow,
  debugVerify,
  startAndesVerification
} from '../simulation/action';
import LayoutData from '@/services/data/LayoutData';
import { getDefaultName } from '@/services/helper/setDefaultName';
import { getAndesErrorCheck } from '../../errorCheck/ErrorCheck';
import monitorLog from '@/services/helper/monitorLog';

function* getContent(action) {
  const { verificationID, loadPCB } = action;
  let { AndesReducer: { project: { pcbComponentsNets, currentProjectDesigns } } } = yield select();

  const response = yield call(getVerificationContentPromise, verificationID);
  let Interfaces = response.Interfaces;
  const SETUP = AndesVerify;
  const name = response.Name;
  let info = SETUP.mergeInterfacesInfo(Interfaces, name);
  const PCBsInInterface = Interfaces.map(item => item.pcbId);
  const andesInfo = {
    Interfaces,
    info,
    PCBsInInterface,
    verificationId: verificationID
  };
  yield put(updateAndesContent(andesInfo));

  try {
    if (PCBVersionChanged(currentProjectDesigns, Interfaces)) {
      const { deleteNets, deleteComponents } = yield call(updateContentAfterPCBUpdate, { Interfaces, name });
      const { AndesReducer: { project, andes: { updateNetsComponents } } } = yield select();
      let netsComponents = [...updateNetsComponents];
      let updateIndex = netsComponents.findIndex(item => item.verificationId === verificationID);
      if (updateIndex > -1) {
        if (!deleteNets.length && !deleteComponents.length) {
          netsComponents.splice(updateIndex, 1)
        } else {
          netsComponents[updateIndex].deleteNets = deleteNets;
          netsComponents[updateIndex].deleteComponents = deleteComponents;
        }
      } else {
        netsComponents.push({
          deleteNets,
          deleteComponents,
          verificationId: verificationID
        })
      }
      yield put(updateNetsComps(netsComponents));
      pcbComponentsNets = project.pcbComponentsNets;
    }
  } catch (error) {
    console.error(error);
  }
  // Load PCB
  if (loadPCB) {
    if (PCBsInInterface.length > 0) {
      const addPCB = PCBsInInterface.filter(pcbId => !pcbComponentsNets.includes(pcbId));
      yield put(saveComponentsNetsInProject(addPCB));
    } else {
      const { AndesReducer: { project: { currentProjectDesigns } } } = yield select();
      let pcbIds = currentProjectDesigns.length > 0 ? currentProjectDesigns.map(item => item.id) : [];
      if (pcbIds && pcbIds.length) {
        const addPCB = pcbIds.filter(pcbId => !pcbComponentsNets.includes(pcbId));
        yield put(saveComponentsNetsInProject(addPCB));
      }
    }
  };

};



function* addSignal(action) {
  const { AndesReducer: { andes, project: { currentProjectDesigns, currentProjectId, verificationId } } } = yield select();
  if (!andes.andesInfo || !andes.andesInfo.info) return;
  let signals = andes.andesInfo.info.signals;
  let name = andes.andesInfo.info.name;
  let _Interfaces = andes.andesInfo.Interfaces;
  const signalName = getDefaultName({ nameList: signals, defaultKey: 'Signal' });
  const newSignal = new Signal(signalName);
  signals.push(newSignal);
  if (_Interfaces.length > 0) {
    _Interfaces[0].content.signals = signals;
    yield put(updateAndesInfo({ Interfaces: _Interfaces }));
  } else {
    // Create interface
    let response = null;
    yield* currentProjectDesigns.map(function* (info) {
      let newInterface = new AndesInterface(name);
      newInterface.signals.push(new Signal(signalName));
      response = yield call(createInterfacePromise, {
        projectId: currentProjectId,
        designId: info.id,
        verificationId: verificationId,
        verificationName: name,
        content: newInterface,
        interfaceName: name,
        readyForSim: 0,
        version: ANDES_SETUP_VERSION,
        ifDoExtraction: 1
      });

      let channel = {
        type: 'SIwave'
      };
      let content = response.interfaceContent;
      content.channel = channel;
      // Update interface
      _Interfaces.push({
        content: content,
        interfaceId: response.id,
        name: name,
        pcb: info.name,
        pcbId: info.id,
        designVersion: response.designVersion !== undefined ? response.designVersion : 1
      })
    });
    // Update interfaces
    _Interfaces.forEach(_interface => {
      let signals = _interface.content.signals;
      const _signalNames = signals.map(it => it.name);
      if (!_signalNames.includes(signalName)) {
        signals.push(new Signal(signalName));
        _interface.content.signals = [...signals];
        _Interfaces[0] = _interface;
      };
    });
    const PCBsInInterface = _Interfaces.map(item => item.pcbId);
    // Update interfaces
    yield put(updateAndesInfo({ Interfaces: _Interfaces, PCBsInInterface }));
  }
  const SETUP = AndesVerify;
  const info = SETUP.mergeInterfacesInfo(_Interfaces, name);
  yield put(updateAndesInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
};

let autoSaveTask = null;
function* openVerificationPage(action) {
  const { view, verificationId, getStatus, rename } = action;
  if (!view) {
    return;
  }
  if (view === 'PCB') {
    yield put(updateViewStatus(action));
    return;
  };

  if (view === 'result' && !getStatus) {
    yield put(updateViewStatus(action));
    return;
  }

  let loadPCB = true;
  if (view === 'result') {
    loadPCB = false;
  }

  if (!verificationId) {
    yield put(updateViewStatus(action));
    return;
  }

  if (autoSaveTask) {
    yield cancel(autoSaveTask);
    if (!rename) {
      yield call(saveVerificationContentToServer);
    }
  }
  yield put(updateViewStatus(action));
  yield put(getVerificationContent(verificationId, loadPCB));
  autoSaveTask = yield fork(autoSave);
  const promise = yield call(checkVerificationStatus, verificationId);
  let resultExist = false;
  if (promise && promise.status) {
    yield put(updateCurrentVerificationStatus(promise.status));
    if (promise.status === VERIFY_SUCCESS) {
      resultExist = true;
    }
  };
  yield put(existResult(resultExist));
  yield put(cancelShow(false));
  yield put(singleVerifyInfo({ workflowId: null, verificationId: null }));
  const interfaceStatus = yield call(getInterfaceWorkStatus, verificationId);
  if (interfaceStatus) {
    yield put(verificationFlow({ verificationId, interfaceStatus }));
  } else {
    const { AndesReducer: { simulationReducer } } = yield select();
    let { singleProgress, singleMonitor } = simulationReducer;
    let index = singleProgress.findIndex(item => item.verificationId === verificationId);
    if (index > -1) {
      yield put(cleanSingleProgress(verificationId));
    }
    let monitorIndex = singleMonitor.findIndex(item => item.verificationId === verificationId);
    if (monitorIndex > -1) {
      let workflowId = singleMonitor[monitorIndex].workflowId;
      const log = yield call(getMonitor, workflowId);
      if (log.length > 0) {
        yield put(updateSingleMonitor({ workflowId, monitor: monitorLog(log), verificationId }));
      } else {
        yield put(cleanMonitor(verificationId));
      }
    }
    const { AndesReducer: { simulationReducer: { waitingQueue, simulationMsg } } } = yield select();
    let queue = [...waitingQueue];
    const n = queue.findIndex(item => item.verificationId === verificationId);
    if (n > -1) {
      queue.splice(n, 1);
    }
    yield put(waitingIndexAction(queue));

    let msg = [...simulationMsg];
    const m = msg.findIndex(item => item.verificationId === verificationId);
    if (m > -1) {
      yield put(updateSimulationMsg({ msg: null, verificationId }));
    }
  }
}

function* editSignalName(action) {
  const { name, prevName } = action;
  const { AndesReducer: { andes } } = yield select();
  let Interfaces = [...andes.andesInfo.Interfaces];
  let names = [];
  // Check repeat signal name
  Interfaces.forEach(item =>
    names.push(...item.content.signals.map(item => item.name))
  );
  if (names.includes(name)) {
    message.error('Signal name cannot be repeated.');
    return;
  };
  Interfaces.forEach(info => {
    let signal = info.content.signals.find(item => item.name === prevName);
    if (signal) {
      signal.name = name;
      for (let comp of info.content.components) {
        for (let pin of comp.pins) {
          if (pin.signal === prevName) {
            pin.signal = name;
          }
        }
      }
    }
  });
  // Update info
  const SETUP = AndesVerify;
  const info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
  yield put(updateAndesInterface({ ...info }));
  yield put(updateAndesInfo({ Interfaces: Interfaces }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* autoSave() {
  let lastTask;
  while (true) {
    const newAction = yield take([AUTO_SAVE_CONFIG_TO_SERVER, SAVE_CONFIG_TO_SERVER]);
    let delayTime = 3000;
    if (newAction.type === AUTO_SAVE_CONFIG_TO_SERVER) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveVerificationContentToServer, newAction.pcbs);
    } else if (newAction.type === SAVE_CONFIG_TO_SERVER) {
      delayTime = 3000;
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(debounce, delayTime, saveVerificationContentToServer, newAction.pcbs);
    }
  }
};

function* debounce(time = 0, callback, info) {
  yield delay(time);
  yield call(callback, info);
}

function* saveVerificationContentToServer(pcbs) {
  const { AndesReducer } = yield select();
  const andes = AndesReducer && AndesReducer.andes;
  if (!andes) return;
  const andesInfo = andes.andesInfo;
  if (!andesInfo) return;
  const { currentProjectId } = AndesReducer.project;
  const Interfaces = andesInfo.Interfaces;
  const verificationId = andesInfo.verificationId;
  let response = null;
  if (!Interfaces || !Interfaces.length) return;
  if (pcbs) {
    yield* pcbs.map(function* (pcbId) {
      const info = Interfaces.find(item => item.pcbId === pcbId);
      //1:ready, 0: not ready
      let readyForSim = 1;
      const errorCheck = getAndesErrorCheck(andesInfo);
      if (errorCheck) {
        readyForSim = 0;
      }

      const { AndesReducer: { simulationReducer } } = yield select();
      let { andesInfoErrorCheck } = simulationReducer;
      const index = andesInfoErrorCheck.findIndex(item => item.verificationId === verificationId);
      if (errorCheck) {
        if (index > -1) {
          andesInfoErrorCheck[index].errorCheck = { ...errorCheck };
        } else {
          andesInfoErrorCheck.push({
            verificationId: verificationId,
            errorCheck: errorCheck
          })
        }
        yield put(updateErrorCheckList(andesInfoErrorCheck));
      } else {
        if (index > -1) {
          andesInfoErrorCheck.splice(index, 1);
        }
        yield put(updateErrorCheckList(andesInfoErrorCheck));
      }

      if (!info.content.extraction) {
        info.content.extraction = new Extraction();
      }
      try {
        response = yield call(updateInterfacePromise, {
          designId: pcbId,
          interfaceId: info.interfaceId,
          interfaceName: info.name,
          projectId: currentProjectId,
          verificationId,
          verificationName: info.name,
          content: info.content,
          readyForSim: readyForSim,
          version: info.version,
          ifDoExtraction: info.ifDoExtraction,
          designVersion: info.designVersion !== undefined ? info.designVersion : 1
        });
      } catch (error) {
        console.error(error)
      }
    })
  } else {
    yield* Interfaces.map(function* (_interface) {
      //1:ready, 0: not ready
      let readyForSim = 1;
      const errorCheck = getAndesErrorCheck(andesInfo);
      if (errorCheck) {
        readyForSim = 0;
      }

      const { AndesReducer: { simulationReducer } } = yield select();
      let { andesInfoErrorCheck } = simulationReducer;
      const index = andesInfoErrorCheck.findIndex(item => item.verificationId === verificationId);
      if (errorCheck) {
        if (index > -1) {
          andesInfoErrorCheck[index].errorCheck = { ...errorCheck };
        } else {
          andesInfoErrorCheck.push({
            verificationId: verificationId,
            errorCheck: errorCheck
          })
        }
        yield put(updateErrorCheckList(andesInfoErrorCheck));
      } else {
        if (index > -1) {
          andesInfoErrorCheck.splice(index, 1);
        }
        yield put(updateErrorCheckList(andesInfoErrorCheck));
      }

      if (!_interface.content.extraction) {
        _interface.content.extraction = new Extraction();
      }
      try {
        response = yield call(updateInterfacePromise, {
          designId: _interface.pcbId,
          interfaceId: _interface.interfaceId,
          interfaceName: _interface.name,
          projectId: currentProjectId,
          verificationId,
          verificationName: _interface.name,
          content: _interface.content,
          readyForSim: readyForSim,
          version: _interface.version,
          ifDoExtraction: _interface.ifDoExtraction,
          designVersion: _interface.designVersion !== undefined ? _interface.designVersion : 1
        });
      } catch (error) {
        console.error(error)
      };
    })
  }
  yield put(autoGetVerificationList(currentProjectId));

  return response;
};

const RLCType = ['Res', 'Ind', 'Cap'];
const CON = 'Connector';
function* editSignal(action) {
  const { nets, pcbId, signalName, netType } = action;
  const { AndesReducer: { andes } } = yield select();
  const pcbInfo = DesignInfo.getPCBInfo(pcbId);
  if(!pcbInfo) return;
  const { netsList, layers } = pcbInfo;
  let _Interfaces = [...andes.andesInfo.Interfaces];
  const _InterfaceIndex = _Interfaces.findIndex(item => item.pcbId === pcbId);
  let editingInterface = _Interfaces[_InterfaceIndex];
  // // All exsit signal net name
  let signals = [...editingInterface.content.signals],
    editedSignalIndex = signals.findIndex(item => item.name === signalName);
  const currentSignal = signals[editedSignalIndex];
  let allSignalNetNamelist = [];
  signals.forEach(item => allSignalNetNamelist.push(...item.positive, ...item.negative));
  let existNets = [];
  let components = [...editingInterface.content.components];
  let refNets = { ...editingInterface.content.refNets };
  let prevNets = [];
  if (netType === 'positive') {
    prevNets = [...currentSignal.positive];
  } else if (netType === 'negative') {
    prevNets = [...currentSignal.negative];
  }
  let prevPowerNets = [];
  for (let comp of components) {
    if (comp.type !== 'Res') {
      continue;
    };
    for (let pin of comp.pins) {
      if (!pin.signal) {
        prevPowerNets.push(pin.net);
      }
    }
  };
  prevPowerNets = [...new Set(prevPowerNets)];
  // 1. Delete the components connected with deletedNets
  if (prevNets.length > 0) {
    // 1.Delete component
    // Delete components which are only connected with the deleted nets or delete pinModel
    const deletedNets = prevNets.filter(net => !nets.includes(net));
    if (deletedNets.length > 0) {
      // { netList, pcbNetsList, layers, pcbId }
      const { newComponents } = getComponentsWithNetList({ netList: deletedNets, pcbNetsList: netsList, layers, pcbId });
      const update = deleteCompsConnectWithNets({ signalName, deletedComps: newComponents, components })
      components = update.components;
    }
    // Check whether the new add nets is selected by other signals.
    const newAddNets = nets.filter(net => !prevNets.includes(net));
    existNets = checkExistNets(newAddNets, allSignalNetNamelist);
    if (existNets && existNets.length > 0) {
      message.error('Net(s) : ' + existNets.join(',') + ' has been selected!');
    }
  } else {
    // Check whether the net is selected by other signals.
    existNets = checkExistNets(nets, allSignalNetNamelist);
    if (existNets && existNets.length > 0) {
      message.error('Net(s) : ' + existNets.join(',') + ' has been selected!');
    }
  }

  // 2. Add nets, filter exist nets
  // netType - positive | negative
  currentSignal[netType] = nets.filter(net => !existNets.includes(net));
  if (editedSignalIndex > -1) {
    signals[editedSignalIndex] = currentSignal;
  } else {
    signals.push(currentSignal);
  }

  // 3. Add components - RLCCONComponent, BasicComponent(Connector.IC)
  // let exsitcomponentsName = components.map(component => component.name);
  // Get components with add nets
  const addNets = nets.filter(net => !prevNets.includes(net) && !existNets.includes(net));
  components = updateComponents({
    components,
    params: { netList: addNets, pcbNetsList: netsList, layers, pcbId },
    signalName
  });
  const _resFilter = components.filter(item => item.type === 'Res');
  const findPowerByRes = findPowerNetsByRes(_resFilter, netsList);
  const { comps } = findPowerByRes;
  comps.forEach(comp => {
    const index = components.findIndex(item => item.name === comp.name);
    if (index > -1) {
      components[index] = { ...comp };
    }
  });

  let newPowerNets = [];
  for (let comp of components) {
    if (comp.type !== 'Res') {
      continue;
    };
    for (let pin of comp.pins) {
      // power,ground nets
      if (!pin.signal) {
        newPowerNets.push(pin.net);
      }
    }
  };

  let _updatePowerNets = refNets.power.filter(item => !prevPowerNets.includes(item.name));

  const { updatePowerNets, updateGroundNets } = updatePowerGround({
    newPowerNets,
    updatePowerNets: _updatePowerNets,
    netsList
  });

  newPowerNets.forEach(net => {
    const find = updatePowerNets.find(item => item.name === net);
    if (!find && !net.match(/gnd/ig)) {
      updatePowerNets.push({ name: net })
    }
  });

  let _refNets = { power: [], ground: [] };
  _refNets.power = [...updatePowerNets];
  _refNets.ground = [...updateGroundNets];
  editingInterface.content.refNets = { ..._refNets };
  editingInterface.content.signals = [...signals];
  editingInterface.content.components = [...components];
  _Interfaces[_InterfaceIndex] = { ...editingInterface };

  // Update info
  const _name = andes.andesInfo.info.name;
  const SETUP = AndesVerify;
  let info = SETUP.mergeInterfacesInfo(_Interfaces, _name);
  yield put(updateAndesInterface({ ...info }));
  yield put(updateAndesInfo({ Interfaces: _Interfaces }));
  yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: [editingInterface.pcbId] });
}


function* _updateInterfaces(action) {
  const { Interfaces, pcbId } = action;
  yield put(updateAndesInfo({ Interfaces: Interfaces }));
  // Update info
  const SETUP = AndesVerify;
  const info = SETUP.mergeInterfacesInfo(Interfaces, Interfaces[0].name);
  yield put(updateAndesInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER, pcbs: [pcbId] });
}

function* editChannelType(action) {
  const { info } = action;
  const { pcb, channel } = info;
  const { AndesReducer: { andes } } = yield select();
  let _Interfaces = [...andes.andesInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);
  _Interfaces[index].content.channel.type = channel;
  let _channel = {
    type: channel
  };
  _Interfaces[index].content.channel = _channel;
  yield put(updateInterfaces({ Interfaces: _Interfaces, pcb, pcbId: _Interfaces[index].pcbId }));
};

function* deleteSignal(action) {
  try {
    const { name } = action;
    const { AndesReducer: { andes } } = yield select();
    let Interfaces = [...andes.andesInfo.Interfaces];
    let andesInfo = { ...andes.andesInfo };
    let delPcbIds = [];
    Interfaces.forEach(info => {
      let signals = [...info.content.signals];
      let components = [...info.content.components];
      const pcbInfo = DesignInfo.getPCBInfo(info.pcbId);
      const { netsList, layers } = pcbInfo;
      if (netsList && layers) {
        const signal = signals.find(item => item.name === name);
        let nets = signal ? [...signal.positive, ...signal.negative] : [];
        if (nets && nets.length > 0) {
          const { newComponents } = getComponentsWithNetList({ netList: nets, pcbNetsList: netsList, layers, pcbId: info.pcbId });
          const update = deleteCompsConnectWithNets({ signalName: name, deletedComps: newComponents, components });
          info.content.components = update.components;
          info.content.signals = signals.filter(item => item.name !== name);
          if (info.content.signals.length < 1) {
            delPcbIds.push(info.pcbId);
          }
        } else {
          info.content.signals = signals.filter(item => item.name !== name);
          if (info.content.signals.length < 1) {
            delPcbIds.push(info.pcbId);
          }
        }
      }
    });
    if (delPcbIds.length > 0) {
      yield* delPcbIds.map(function* (item) {
        const index = Interfaces.findIndex(info => info.pcbId === item);
        if (index > -1) {
          yield call(deleteInterfacePromise, [Interfaces[index].interfaceId]);
          Interfaces.splice(index, 1);
        }
      });
    }
    const SETUP = AndesVerify;
    let interfaceName = null;
    if (Interfaces.length === 0) {
      interfaceName = andesInfo.info.name;
    } else {
      interfaceName = Interfaces[0].name;
    }
    let info = SETUP.mergeInterfacesInfo(Interfaces, interfaceName);
    yield put(updateAndesInterface({ ...info }));
    yield put(updateAndesInfo({ Interfaces: Interfaces }));
    yield put({ type: SAVE_CONFIG_TO_SERVER });
  } catch (error) {
    console.error(error)
  }
}

function* changeSerdesType(action) {
  const { serdesType } = action;
  const { AndesReducer: { andes, project: { currentProjectDesigns, currentProjectId, verificationId } } } = yield select();
  let andesInfo = andes.andesInfo;
  let _Interfaces = andesInfo.Interfaces;
  let name = andes.andesInfo.info.name;
  if (currentProjectDesigns.length === 0) {
    return;
  }

  if (_Interfaces.length > 0) {
    _Interfaces[0].content.info.type = serdesType;
    yield put(updateAndesInfo({ Interfaces: _Interfaces }));
  } else {
    // Create interface
    let response = null;
    yield* currentProjectDesigns.map(function* (info) {
      let newInterface = new AndesInterface(name);
      newInterface.info.type = serdesType;
      response = yield call(createInterfacePromise, {
        projectId: currentProjectId,
        designId: info.id,
        verificationId: verificationId,
        verificationName: name,
        content: newInterface,
        interfaceName: name,
        readyForSim: 0,
        version: ANDES_SETUP_VERSION,
        ifDoExtraction: 1
      });

      let channel = {
        type: 'SIwave'
      };
      let content = response.interfaceContent;
      content.channel = channel;
      // Update interface
      _Interfaces.push({
        content: content,
        interfaceId: response.id,
        name: name,
        pcb: info.name,
        pcbId: info.id
      })
    });
    const PCBsInInterface = _Interfaces.map(item => item.pcbId);
    // Update interfaces
    yield put(updateAndesInfo({ Interfaces: _Interfaces, PCBsInInterface }));
  }
  const SETUP = AndesVerify;
  const info = SETUP.mergeInterfacesInfo(_Interfaces, name);
  yield put(updateAndesInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* changeDataRate(action) {
  const { dataRate } = action;
  const { AndesReducer: { andes, project: { currentProjectDesigns, currentProjectId, verificationId } } } = yield select();
  let andesInfo = andes.andesInfo;
  let _Interfaces = andesInfo.Interfaces;
  let name = andes.andesInfo.info.name;
  if (currentProjectDesigns.length === 0) {
    return;
  }

  if (_Interfaces.length > 0) {
    _Interfaces[0].content.info.datarate = dataRate;
    yield put(updateAndesInfo({ Interfaces: _Interfaces }));
  } else {
    // Create interface
    let response = null;
    yield* currentProjectDesigns.map(function* (info) {
      let newInterface = new AndesInterface(name);
      newInterface.info.datarate = dataRate;
      response = yield call(createInterfacePromise, {
        projectId: currentProjectId,
        designId: info.id,
        verificationId: verificationId,
        verificationName: name,
        content: newInterface,
        interfaceName: name,
        readyForSim: 0,
        version: ANDES_SETUP_VERSION,
        ifDoExtraction: 1
      });

      let channel = {
        type: 'SIwave'
      };
      let content = response.interfaceContent;
      content.channel = channel;
      // Update interface
      _Interfaces.push({
        content: content,
        interfaceId: response.id,
        name: name,
        pcb: info.name,
        pcbId: info.id
      })
    });
    const PCBsInInterface = _Interfaces.map(item => item.pcbId);
    // Update interfaces
    yield put(updateAndesInfo({ Interfaces: _Interfaces, PCBsInInterface }));
  }
  const SETUP = AndesVerify;
  const info = SETUP.mergeInterfacesInfo(_Interfaces, name);
  yield put(updateAndesInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
}

function* saveExtraction(action) {
  let { extraction, pcb } = action;
  const { AndesReducer: { andes } } = yield select();
  let _Interfaces = [...andes.andesInfo.Interfaces];
  const index = _Interfaces.findIndex(item => item.pcb === pcb);

  if (!_Interfaces.length) return;
  if (!extraction) {
    _Interfaces[index].content.extraction = new Extraction();
  } else {
    _Interfaces[index].content.extraction = { ...extraction };
  }
  yield put(updateAndesInfo({ Interfaces: _Interfaces }));
  const SETUP = AndesVerify;
  const info = SETUP.mergeInterfacesInfo(_Interfaces, _Interfaces[index].name);
  yield put(updateAndesInterface({ ...info }));
  yield put({ type: SAVE_CONFIG_TO_SERVER });
  /*  yield put(saveReExtraction(_Interfaces[index].interfaceId, 1)); */
}

function getLayoutDB(id) {
  return new Promise((resolve, reject) => {
    LayoutData.LoadLayoutDB(id).then(res => {
      resolve(true);
    }, error => {
      resolve(true);
    })
  })
}

function* saveCurrentVerificationToServer(action) {
  const { verificationIds, currentVerificationId } = action;
  //save current verification json
  const response = yield call(saveVerificationContentToServer);
  if (response) {
    yield put(startAndesVerification(verificationIds, currentVerificationId));
  }
}

function* verificationDebugVerify(action) {
  const { step, verificationId } = action;
  //save current verification json
  const response = yield call(saveVerificationContentToServer);
  if (response) {
    yield put(debugVerify(step, verificationId));
  }
}

export function* updateContentAfterPCBUpdate(action) {
  const SETUP = AndesVerify;
  const { AndesReducer: { project: { currentProjectDesigns, pcbComponentsNets } } } = yield select();
  const { Interfaces, name, update = true } = action;
  const _updateAndesInfo = !update ? false : true;
  let newInterfaces = [...Interfaces];
  let prevComponentNames = Interfaces.reduce((prev, curr) => {
    return [...prev, {
      pcbId: curr.pcbId,
      compNames: curr.content.components.map(comp => comp.name),
      components: curr.content.components
    }]
  }, []);
  let deleteNets = [];
  yield* newInterfaces.map(function* (info) {
    const currentPcb = currentProjectDesigns.find(item => item.id === info.pcbId);
    info.designVersion = currentPcb.designVersion;
    const { signals } = info.content;
    let pcbsLayoutDB = [...pcbComponentsNets];
    let pcbInfo = {};
    if (!pcbComponentsNets.includes(info.pcbId)) {
      yield call(getLayoutDB, info.pcbId);
      const _DesginData = LayoutData.getLayout(info.pcbId);
      pcbInfo = {
        netsList: [..._DesginData.mNetManager.mNetList.mVals],
        layers: [..._DesginData.mLayerMgr.mMetalLayers]
      };
      pcbsLayoutDB.push(info.pcbId);
      DesignInfo.savePCBInfo(info.pcbId, pcbInfo);
    } else {
      pcbInfo = DesignInfo.getPCBInfo(info.pcbId);
    }
    const { netsList, layers } = pcbInfo;
    yield put(updatePCBComponentsNets(pcbsLayoutDB));
    let netList = pcbInfo ? netsList.map(item => item.mName) : [];
    let components = [];
    yield* signals.map(function (signal) {
      const { positive, negative } = signal;
      const signalName = signal.name;
      let currentSignal = { ...signal };
      let allSignalNetNamelist = [], newNets = [], delNets = [];
      allSignalNetNamelist = [...positive, ...negative];
      allSignalNetNamelist.forEach(item => {
        if (netList.includes(item)) {
          newNets.push(item)
        } else {
          delNets.push(item);
          deleteNets.push(item);
        }
      })
      components = updateComponents({
        components,
        params: { netList: newNets, pcbNetsList: netsList, layers, pcbId: info.pcbId },
        prevComponentNames,
        pcbId: info.pcbId,
        signalName
      });

      currentSignal.positive = positive.filter(net => newNets.includes(net));
      currentSignal.negative = negative.filter(net => newNets.includes(net));
      let editedSignalIndex = info.content.signals.findIndex(item => item.name === signalName);
      if (editedSignalIndex > -1) {
        info.content.signals[editedSignalIndex] = currentSignal;
      } else {
        info.content.signals.push(currentSignal);
      };

      return signal;
    })

    const _resFilter = components.filter(item => item.type === 'Res');
    const findPowerByRes = findPowerNetsByRes(_resFilter, netsList);
    const { comps } = findPowerByRes;
    comps.forEach(comp => {
      const index = components.findIndex(item => item.name === comp.name);
      if (index > -1) {
        components[index] = { ...comp };
      }
    });

    let newPowerNets = [];
    for (let comp of components) {
      if (comp.type !== 'Res') {
        continue;
      };
      for (let pin of comp.pins) {
        // power,ground nets
        if (!pin.signal) {
          newPowerNets.push(pin.net);
        }
      }
    };

    const { updatePowerNets, updateGroundNets } = updatePowerGround({
      newPowerNets,
      updatePowerNets: [],
      netsList
    });

    let _refNets = { power: [], ground: [] };
    _refNets.power = [...updatePowerNets];
    _refNets.ground = [...updateGroundNets];
    info.content.refNets = { ..._refNets };
    info.content.components = [...components];

  })
  const mergeInfo = SETUP.mergeInterfacesInfo(Interfaces, name);
  const prevComp = prevComponentNames.reduce((prev, cur) => { return [...prev, ...cur.compNames] }, []);
  const newComp = Interfaces.reduce((prev, current) => { return [...prev, ...current.content.components.map(d => d.name)] }, []);
  const deleteComponents = prevComp.filter(d => !newComp.includes(d));

  const andesInfo = {
    Interfaces: newInterfaces,
    info: mergeInfo
  };
  if (_updateAndesInfo) {
    yield put({ type: SAVE_CONFIG_TO_SERVER });
    yield put(updateAndesContent(andesInfo));
  }
  return {
    deleteNets,
    deleteComponents,
    andesInfo
  }
}

export function PCBVersionChanged(designs, interfaces) {
  let changed = false;
  for (const data of interfaces) {
    const findPCB = designs.find(d => d.id === data.pcbId);
    if (findPCB && findPCB.designVersion !== data.designVersion) {
      changed = true;
      break;
    }
  };
  return changed;
};

function updateComponents(obj) {
  const { components, params, prevComponentNames = [], pcbId = null, signalName } = obj;
  let _components = [...components];

  let exsitcomponentsName = _components.map(component => component.name);
  const { newComponents, CompsInfo } = getComponentsWithNetList(params);
  for (const comp of newComponents) {
    const { pin, name, net, value, type, part } = comp;
    let currPrevComps = prevComponentNames.find(i => i.pcbId === pcbId);
    let currPrevComp = null;
    if (currPrevComps && currPrevComps.compNames && currPrevComps.compNames.includes(name)) {
      currPrevComp = currPrevComps.components.find(item => item.name === name);
    }
    if (type !== CON) {
      const _index = exsitcomponentsName.indexOf(name);
      if (_index < 0) {
        // Not exsit component
        let newComp;
        if (RLCType.includes(type)) {
          let _value = currPrevComp ? currPrevComp.value : value;
          if (RLCType.includes(type)) {
            _value = checkRLCValue(value);
          }
          // Cap 
          if (type === 'Cap') {
            if (currPrevComp) {
              _value = currPrevComp.value
            } else {
              _value = {
                r: "0",
                l: "0",
                c: _value || "0"
              }
            }
          }
          newComp = new RLCCONComponent({ name, type, value: _value });
        } else {
          newComp = new BasicComponent({ name, type });
        }
        newComp.pins = [new BasicCompModel({ pin, net, signal: signalName })];
        newComp.part = part;
        _components.push(newComp);
        // Update components name list
        exsitcomponentsName.push(name);
      } else {
        // Exsit component
        _components[_index].pins.push(new BasicCompModel({ pin, net, signal: signalName }));
      }
    }
  };
  let ctrlComponents = _components.filter(item => item.type === 'Controller');
  if (ctrlComponents && ctrlComponents.length > 0) {
    let pinLength = ctrlComponents[0].pins.length, compName = ctrlComponents[0].name;
    for (const ctrlItem of ctrlComponents) {
      if (ctrlItem.pins.length > pinLength) {
        pinLength = ctrlItem.pins.length;
        compName = ctrlItem.name;
      }
    };
    let maxPinLengthComp = ctrlComponents.filter(item => item.pins.length === pinLength);
    if (maxPinLengthComp.length > 1) {

      let mPinLength = CompsInfo[maxPinLengthComp[0].name].pinLength ? CompsInfo[maxPinLengthComp[0].name].pinLength : 0;
      for (const _item of maxPinLengthComp) {
        if (CompsInfo[_item.name].pinLength && CompsInfo[_item.name].pinLength > mPinLength) {
          mPinLength = CompsInfo[_item.name].pinLength;
          compName = _item.name;
        }
      };
    }
    let ctrlNames = ctrlComponents.map(item => item.name);
    _components.forEach(item => {
      if (ctrlNames.includes(item.name)) {
        if (item.name !== compName) {
          item.type = 'Device';
        }
      }
    })
  }
  return _components;
}

function updatePowerGround(obj) {
  const { newPowerNets, netsList } = obj;
  let updatePowerNets = obj.updatePowerNets;
  newPowerNets.forEach(net => {
    const find = updatePowerNets.find(item => item.name === net);
    if (!find && !net.match(/gnd/ig)) {
      updatePowerNets.push({ name: net })
    }
  });
  let updateGroundNets = [];
  const autoGND = netsList.filter(item => item.mName.match(/gnd/ig));
  if (autoGND.length > 0) {
    const findGND = autoGND.find(item => item.mName === 'GND')
    if (findGND) {
      const find = updateGroundNets.find(item => item.name === 'GND');
      if (!find) {
        updateGroundNets.push({ name: 'GND' });
      }
    } else {
      let via = 0, gndNet = null;
      for (let net of autoGND) {
        if (net.mViaList.length > via) {
          gndNet = net.mName;
          via = net.mViaList.length;
        }
      };
      const find = updateGroundNets.find(item => item.name === gndNet);
      if (!find) {
        updateGroundNets.push({ name: gndNet });
      }
    }
  };
  return {
    updatePowerNets,
    updateGroundNets
  }
}

function* AndesSaga() {
  yield takeEvery(GET_VERIFICATION_CONTENT, getContent);
  yield takeEvery(ADD_SIGNAL, addSignal);
  yield takeEvery(CHANGE_VIEW, openVerificationPage);
  yield takeEvery(EDIT_SIGNAL_NAME, editSignalName);
  yield takeEvery(EDIT_SIGNAL, editSignal);
  yield takeEvery(UPDATE_INTERFACES, _updateInterfaces);
  yield takeEvery(EDIT_CHANNEL_TYPE, editChannelType);
  yield takeEvery(DELETE_SIGNAL, deleteSignal);
  yield takeEvery(CHANGE_SERDES_TYPE, changeSerdesType);
  yield takeEvery(CHANGE_DATA_RATE, changeDataRate);
  yield takeEvery(SAVE_EXTRACTION, saveExtraction);
  yield takeEvery(SAVE_CURRENT_VERIFICATION, saveCurrentVerificationToServer);
  yield takeEvery(CURRENT_VERIFICATION_DEBUG_VERIFY, verificationDebugVerify);
}

export default AndesSaga;