import http from './http';


/**
 * Get backend log 
 * 2019.12.10
 *  
 * {
 *  "logType": "ccc",
 *  "logs": [{
 *    "time": "UTC",
 *    "log": "log content"
 *  }]
 *}
 * 
 * @export
 * @param type: server
 * @param subType:aws#Translation, aws#Simulation, aws#Ansys
 * @param {'info'|'detail'} level
 * @returns XMLHttpRequest
 */
export function getBackendLog({ type, subType, level = 'info' }) {
  return http.get(`/backend/log/${type}/${subType}/${level}`);
}

/**
 * Get daemon list 
 * 2019.12.11
 * 
 * @export
 * @returns XMLHttpRequest
 */
export function getDaemonList() {
  return http.get(`/backend/log/server_list`);
}

/**
 * Switch backend server
 * 2019.12.27
 * 
 * @export
 * @returns XMLHttpRequest
 */
export function updateCCCServerStatus(workerServers) {
  return http.put(`/backend/server/switch`, workerServers);
}

/**
 * 2020.04.24 Set Backend Debug
 *
 * @export
 * @param {Object} server { serverName: "ip_mac_uuid", "debug": false / true }
 * @returns
 */
export function setBackendServerDebug(server) {
  return http.put(`/backend/server/debug`, server);
}

/**
 * 2020.04.29 Get server list
 *
 * [{ 'taskType': 'Ansys|Simulation|Translation', 'servers': [{ "name": "machineIP_macAddress_uuid", "status": "free" }] }]
 * @export
 * @returns
 */
export function getServerList() {
  return http.get(`/backend/log/server_list/simple`);
}

/**
 * 2020.04.29 Select specific server for verification
 *
 * @export
 * @param {*} verificationId - verification id
 * @param {*} serverName - "machineIP_macAddress_uuid"
 * @returns
 */
export function selectServer({ verificationId, serverName }) {
  return http.post(`/project/verification/${verificationId}/server/${serverName}`)
}

/**
 * Select specify service for verification
 * 2021.06.11
 * @export
 * @param {Object} serviceObj - { verificationId, serverMap:{ taskName:[ server1, server2 ] } }
 * @returns
 */
export function selectSpecifyServiceForVerification(serverObj) {
  return http.post(`/verification/specify/server`, { ...serverObj })
}