import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import BasicRouter from './router';
import store from './pages/store';
import { ConfigProvider } from 'antd';
import Version, { AURORA } from './services/auroraVersion';
import { auroraTheme } from './theme/aurora';
Version.platformVersion = AURORA;

ReactDOM.render((
  <Provider store={store}>
    <ConfigProvider renderEmpty={() => <Fragment>No Data</Fragment>} theme={auroraTheme}>
      <BasicRouter />
    </ConfigProvider>
  </Provider>
), document.getElementById('root'));
// registerServiceWorker();
