import http from "../http";

/**
 * Get design clip polygon
 *
 * @export
 * @param {*} verificationId
 * @return {*} 
 */
export function getDesignClipInfo(verificationId) {
  return http.get('/ansys/design-clip', { params: { verificationId } })
}

/**
 * Save design clip ploygon
 *
 * @export
 * @param {*} params {
  "designClipDTO": {
    "unit": "string",
    "x": [
      0
    ],
    "y": [
      0
    ]
  },
  "verificationId": "string"
}
 * @return {*} 
 */
export function saveDesignClipInfo(params) {
  return http.post('/ansys/design-clip', params)
}



/**
 * Get design clip hybrid polygon
 *
 * @export
 * @param {*} verificationId
 * @return {*} 
 */
export function getDesignHybridInfo(verificationId) {
  return http.get('/ansys/hybrid-clip', { params: { verificationId } })
}

/**
 * Save design clip hybrid polygon
 *
 * @export
 * @param {*} params {
  "hfss_zones": {
    "unit": "string",
    "x": [
      0
    ],
    "y": [
      0
    ]
  },
  "hybrid_regions": [],
  "hybrid_lines": []
  "verificationId": "string"
}
 * @return {*} 
 */
export function saveDesignHybridInfo(params) {
  return http.post('/ansys/hybrid-clip', params)
}