import {
  INIT_USER_TABLE,
  CREATE_USER,
  RELOAD_USER_TABLE,
  CREATE_USER_FAILED,
  CLOSE_CREATE_USER_MODAL,
  OPEN_CREATE_USER_MODAL,
  INIT_USER_TABLE_SUCCESS,
  CHANGE_MENU,
  CHANGE_TABLE_SUCCESS,
  JUMP_TO_ADMIN_PAGE,
  JUMP_TO_PAGE_SUCCESS,
  OPEN_CREATE_ROLE_MODAL,
  CLOSE_CREATE_ROLE_MODAL,
  CREATE_ROLE,
  CREATE_ROLE_FAILED,
  CHANGE_EMAIL_MODAL_VISIBLE,
  SAVE_ROLE_LIST,
  GET_ROLE_PERM_LIST,
  SAVE_PERM_LIST,
  CLEAR_DATA,
  UPDATE_ROLE_MANAGEMENT
} from './actionTypes';

export const initUserTableAction = () => ({
  type: INIT_USER_TABLE,
})

export const createUserAction = (email, name, password, region, roles) => ({
  type: CREATE_USER,
  email,
  name,
  password,
  region,
  roles
})

export const reloadUserTableAction = (page) => ({
  type: RELOAD_USER_TABLE,
  page
})

export const creatUserErrorAction = (msg) => ({
  type: CREATE_USER_FAILED,
  msg,
})

export const closeCreateUserModal = () => ({
  type: CLOSE_CREATE_USER_MODAL,
})

export const openCreateUserModal = () => ({
  type: OPEN_CREATE_USER_MODAL
})

export const initUserSuccessAction = (responseData, pageNum) => ({
  type: INIT_USER_TABLE_SUCCESS,
  responseData,
  pageNum
})

export const changeMenuAction = (menu) => ({
  type: CHANGE_MENU,
  menu,
})

export const changeTableSuccessAction = (responseData) => ({
  type: CHANGE_TABLE_SUCCESS,
  responseData,
})

export const jumpToPageAction = (page, menu) => ({
  type: JUMP_TO_ADMIN_PAGE,
  page,
  menu,
})

export const jumpToPageSuccessAction = (responseData, pageNum) => ({
  type: JUMP_TO_PAGE_SUCCESS,
  responseData,
  pageNum
})

export const openCreateRoleModal = () => ({
  type: OPEN_CREATE_ROLE_MODAL
})

export const closeCreateRoleModal = () => ({
  type: CLOSE_CREATE_ROLE_MODAL,
})

export const createRoleAction = (value) => ({
  type: CREATE_ROLE,
  value
})

export const createUserRoleFailed = (roleMsg) => ({
  type: CREATE_ROLE_FAILED,
  roleMsg,
})

export const changeEmailModalVisible = (visible) => ({
  type: CHANGE_EMAIL_MODAL_VISIBLE,
  visible
})

export const getRoleAndPermissionList = () => ({
  type: GET_ROLE_PERM_LIST
})

export const saveRoleListAction = (list) => ({
  type: SAVE_ROLE_LIST,
  list
})

export const savePermissionListAction = (list) => ({
  type: SAVE_PERM_LIST,
  list
})

export const clearTableAction = () => ({
  type: CLEAR_DATA
});

export const updateRoleManagement = (roleManagement) => ({
  type: UPDATE_ROLE_MANAGEMENT,
  roleManagement
})