import { call, put, takeLatest, select, delay, takeEvery, fork } from 'redux-saga/effects';
import { getCCCLog } from '@/services/CCC';
import dayjs from 'dayjs';
import { GET_CCC_MSG, CHANGE_CCC_MENU, UPDATE_DAEMON_DATA, STOP_POLL_DATA, START_POLL_DATA, BROKER_LOG_CHANGE, GET_SERVER_LIST, STOP_SERVER_LIST, SELECT_TASK } from './actionTypes';
import { getBackendServerLog, getServerDaemonList, getBackendServerListSimple, selectSpecifyService } from '@/services/CCC';
import {
  updateQueueMsg,
  updateBackendLog,
  getCCCMsg,
  updateServerList,
  updateTaskTableList,
  updateSelectTaskMessage
} from './action';
import { strDelimited } from '@/services/helper/split';
import { ANDES_V2, ROCKY, CASCADE, HIMALAYAS } from '../../../constants/pageType';
import { SIGN_OFF_TEMPLATE } from '../../../constants/treeConstants';
import { selectTemplateTaskServices } from '../../../services/Cascade/SignOffTemplate';

let cccActive;
let listActive;
function* getCCCMessage(action) {
  let first = true; cccActive = true;
  const { isCCCPage } = action;
  while (cccActive) {
    if (!first) {
      yield delay(10000);
    }
    first = false;

    const { TabMonitorReducer, MonitorInfoReducer, CCCReducer, LoginReducer: { pageType } } = yield select();
    const { cccMenu } = CCCReducer;
    let { tabVisible, tabSelectKeys } = TabMonitorReducer;
    if ([ROCKY, ANDES_V2, CASCADE, HIMALAYAS].includes(pageType)) {
      tabVisible = MonitorInfoReducer.tabVisible;
      const { monitorScreenInfo } = MonitorInfoReducer;
      tabSelectKeys = monitorScreenInfo.tabSelectKeys;
    }

    if (!isCCCPage && (!tabVisible || !tabSelectKeys.includes('ccc'))) {
      cccActive = false;
      return;
    }

    if (!isCCCPage && (cccMenu.length > 0 && !cccMenu.includes('CCC_log'))) {
      listActive = false;
    }

    const log = yield call(getCCCLog);
    if (log) {
      const time = new Date();
      time.setUTCSeconds(time.getUTCSeconds());
      let nowTime = dayjs(time.toUTCString()).format('MM-DD HH:mm:ss');
      // let utcTime = dayjs.utc(time.toUTCString()).format('MM-DD HH:mm:ss');
      let _log = `==> Log refresh time: ${nowTime}\n${log}`;
      yield put(updateQueueMsg(_log));
    } else {
      yield put(updateQueueMsg(null));
    }
  }
}

function* cccMenuChange(action) {
  const { status, isCCCPage, cccMenu } = action;
  let type = status;
  if (type === 'CCC_log' || cccMenu.includes('CCC_log')) {
    listActive = false;
    cccActive = false;
    taskServiceActive = false;
    yield put(getCCCMsg(isCCCPage || cccMenu.includes('CCC_log')))
    return;
  } else if (type === 'daemon') {
    cccActive = false;
  } else if (type === 'broker' || type === 'ccc') {
    cccActive = false;
  }
  taskServiceActive = false;
  listActive = false;
  yield fork(serverDaemon);
  yield call(otherCCCMessage, { status: type });
}

function* otherCCCMessage(action) {
  let first = true;
  listActive = true;
  while (listActive) {
    if (!first) {
      yield delay(60000);
    }
    first = false;
    let { CCCReducer } = yield select();
    const { cccMenu, brokerLogType } = CCCReducer;
    if (cccMenu.length === 0 || cccMenu.includes('CCC_log') || (cccMenu.length === 1 && cccMenu[0] === 'daemon')) {
      listActive = false;
      return;
    }

    if (!listActive) {
      return;
    }
    yield* cccMenu.map(function* (type) {
      if (type !== 'daemon') {
        let subType = type;
        let level = 'info';
        if (type === 'broker') {
          level = brokerLogType;
        }
        const log = yield call(getBackendServerLog, type, subType, level);
        if (log) {
          const updateTime = new Date();
          updateTime.setUTCSeconds(updateTime.getUTCSeconds());
          let updateLogTime = dayjs(updateTime.toUTCString()).format('MM-DD HH:mm:ss');
          log.updateLogTime = updateLogTime;
          let _str = '';
          if (log.logs && log.logs.length > 0) {
            log.logs.forEach((it) => {
              _str += it.time + ' ' + it.log + '\n';
            });
          };
          log.logString = _str;
          yield put(updateBackendLog(log, type));
        } else {
          yield put(updateBackendLog(null, type));
        }
      };
    })
  }
}

let taskServiceActive = false;
function* serverDaemon(action) {
  taskServiceActive = true;
  let first = true;

  while (taskServiceActive) {
    if (!first) {
      yield delay(5000);
    }

    first = false;
    let { CCCReducer } = yield select();
    const { cccMenu, taskServiceData } = CCCReducer;

    if (!cccMenu.includes('daemon')) {
      taskServiceActive = false;
      return;
    }
    // stopPollTaskListData
    if (!taskServiceActive) {
      return;
    }
    const res = yield call(getServerDaemonList);
    if (res && res.length) {
      let taskServiceDataArr = [];
      res.forEach(daemon => {
        daemon.workers.forEach(worker => {
          const find = taskServiceData.find(it => it.machine === daemon.server && it.service === worker.name);
          const serverSplit = strDelimited(daemon.server, "_");
          let machineID = daemon.server, serviceID = '';
          if (serverSplit.length === 3) {
            machineID = serverSplit[0] + '_' + serverSplit[1];
            serviceID = serverSplit[2];
          }
          const obj = {
            machineID: machineID,
            serviceID: serviceID,
            machine: daemon.server,
            service: worker.name,
            status: worker.status,
            workerId: worker.taskId || '',
            view: find ? find.view : 'Summary', // 'Summary' - 'info' | 'Complete' - 'detail'
            debug: worker.debug || false,
            cpu: parseInt(daemon.info.cpuPercent) + '%',
            memory: daemon.info.freeMemory
          }
          taskServiceDataArr.push(obj);
        })
      });

      const updateTime = new Date();
      updateTime.setUTCSeconds(updateTime.getUTCSeconds());
      const taskServiceDataTime = dayjs(updateTime.toUTCString()).format('MM-DD HH:mm:ss');

      // Update task table
      yield put(updateTaskTableList({
        list: res,
        taskServiceData: taskServiceDataArr,
        taskServiceDataTime: taskServiceDataTime,
      }));
    }
  }
}

let daemonLogTime = 1;
function* getdDaemonLog(action) {
  const { daemonServer, worker } = action;
  const { CCCReducer: { level } } = yield select();
  let _level = 'info';
  if (action.level) {
    _level = action.level;
  } else {
    _level = level;
  };
  let first = true;

  let currentLogTime = daemonLogTime;
  while (currentLogTime === daemonLogTime) {
    if (!first) {
      yield delay(60000);
    }
    first = false;

    let { CCCReducer } = yield select();
    const { cccMenu } = CCCReducer;
    if (!cccMenu.includes('daemon')) {
      currentLogTime += 1;
      return;
    }

    if (currentLogTime !== daemonLogTime) {
      return;
    }
    let log = yield call(getBackendServerLog, daemonServer, worker, _level);
    if (log) {
      const updateTime = new Date();
      updateTime.setUTCSeconds(updateTime.getUTCSeconds());
      let updateLogTime = dayjs(updateTime.toUTCString()).format('MM-DD HH:mm:ss');
      log.updateLogTime = updateLogTime;
      let _str = '';
      if (log.logs && log.logs.length > 0) {
        log.logs.forEach((it) => {
          _str += it.time + ' ' + it.log + '\n';
        });
      }
      log.logString = _str;
      yield put(updateBackendLog(log, 'daemon'));
    } else {
      yield put(updateBackendLog(null, 'daemon'));
    }
  }
}

function stopPollTaskListData(action) {
  taskServiceActive = false;
}

function* startPollTaskListData(action) {
  yield call(serverDaemon);
}

function* brokerLogChange() {
  listActive = false;
  yield call(otherCCCMessage);
}

let serverList = false;
// CCC monitor server list
function* getServerList() {
  let first = true;
  serverList = true;
  while (serverList) {
    if (!first) {
      yield delay(60000);
    }
    first = false;

    if (!serverList) {
      return;
    }
    const res = yield call(getBackendServerListSimple);
    // const res = [
    //   {
    //     'taskType': 'Ansys',
    //     'servers': [{ "name": "888-888-888-888_AA-BB-CC-DD-EE-FF_ABCDEFGHI", "status": "free" },
    //     { "name": "888-888-888-888_AA-BB-CC-DD-EE-FF_ABCDEFGHI", "status": "free" }]
    //   }
    // ]
    if (res && res.length > 0) {
      yield put(updateServerList(res));
    }
  }
}

function stopServerList() {
  serverList = false;
}

function* selectTaskService(action) {
  const { verification, serviceSelectListMap, setupType } = action;
  try {
    const verificationId = verification.verificationId ? verification.verificationId : verification.id;
    let serverMap = {}, res = "";
    if (setupType === SIGN_OFF_TEMPLATE) {
      for (let key in serviceSelectListMap || {}) {
        let _serverMap = {};
        serviceSelectListMap[key].forEach(item => {
          if (item.task && item.selectedServers.length) {
            _serverMap[item.task] = [...item.selectedServers]
          }
        });
        serverMap[key] = _serverMap;
      }
      res = yield call(selectTemplateTaskServices, { verificationId, ...serverMap });
    } else {
      (serviceSelectListMap["verification"] || []).forEach(item => {
        if (item.task && item.selectedServers.length) {
          serverMap[item.task] = [...item.selectedServers]
        }
      });
      res = yield call(selectSpecifyService, { verificationId, serverMap });
    }

    yield put(updateSelectTaskMessage(res));
  } catch (error) {
    yield put(updateSelectTaskMessage("Set task service failed!"));
  }
}

function* CCCSagas() {
  yield takeLatest(GET_CCC_MSG, getCCCMessage);
  yield takeLatest(CHANGE_CCC_MENU, cccMenuChange);
  yield takeLatest(UPDATE_DAEMON_DATA, getdDaemonLog);
  yield takeEvery(STOP_POLL_DATA, stopPollTaskListData);
  yield takeEvery(START_POLL_DATA, startPollTaskListData);
  yield takeLatest(BROKER_LOG_CHANGE, brokerLogChange);
  yield takeLatest(GET_SERVER_LIST, getServerList);
  yield takeEvery(STOP_SERVER_LIST, stopServerList);
  yield takeEvery(SELECT_TASK, selectTaskService);
}

export default CCCSagas;