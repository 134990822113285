import React, { Component, Fragment } from "react";
import { Input } from "antd";
import { CAP, IND, RES } from "../../constants/componentType";
import CopySetupPanel from "../CopySetupPanel";
import { numberCheck } from "../../services/helper/dataProcess";
/* import { RLC_TYPES } from "../../services/PCBHelper"; */
import { splitValueUnit } from "../../services/helper/RLCValue";
import { _RLC_UNITS, /* RLC_PLACEHOLDER */ } from "../../services/helper/getRLCInputKey";
import UnitAddonAfter from "../UnitAddonAfter";
/* import { unitChange } from "../../services/helper/mathHelper"; */
import "./index.css";
import { getShuntRLCType, SHUNT_C, SHUNT_L, SHUNT_R } from "../../services/VirtualComponent";

class ValueEdit extends Component {
  constructor(props) {
    super(props);
    const { value, unit } = this.getValue();
    this.state = {
      selectedComps: [],
      value,
      inputValue: /* props.record.type === CAP ? { ...value } : */ value,
      unit
    };
    this.dialogRoot = document.getElementById("root");
  }

  getValue = () => {
    const { record } = this.props;
    /* if (record.type === CAP) {
      const { value: r, unit: rUnit } = record.value && record.value.r ? splitValueUnit(record.value.r || "", true) : {};
      const { value: l, unit: lUnit } = record.value && record.value.l ? splitValueUnit(record.value.l || "", true) : {};
      const { value: c, unit: cUnit } = record.value && record.value.c ? splitValueUnit(record.value.c || "", true) : {};
      return {
        value: {
          [RES]: r || "",
          [IND]: l || "",
          [CAP]: c || ""
        },
        unit: {
          [RES]: rUnit || "Ω",
          [IND]: lUnit || (!record.value || !record.value.l ? "n" : "H"),
          [CAP]: cUnit || (!record.value || !record.value.c ? "n" : "F")
        }
      }
    } */
    const { value, unit } = splitValueUnit(record.value, true);
    const { unit: _unit, defaultUnit } = this.getUnit(record.type);
    return { value, unit: (unit || value) ? unit || _unit : defaultUnit }
  }

  getUnit = (type) => {
    let unit = "", defaultUnit = "";
    switch (type) {
      case RES:
      case SHUNT_R:
        unit = "Ω";
        defaultUnit = "Ω";
        break;
      case IND:
      case SHUNT_L:
        unit = "H";
        defaultUnit = "n";
        break;
      case CAP:
      case SHUNT_C:
        unit = "F";
        defaultUnit = "n";
        break;
      default: break;
    }
    return { unit, defaultUnit }
  }

  componentDidMount = () => {
    this.getTreeData()
  }

  getTreeData = () => {
    let treeDataChildren = [];
    const { virtualComps = [], record } = this.props;
    for (let item of virtualComps) {
      if (item.name === record.name || item.type !== record.type) {
        continue;
      }

      treeDataChildren.push({
        title: item.name,
        key: item.name,
        value: item.name,
      })
    }
    const treeData = [{
      title: "All",
      key: "All",
      value: "All",
      children: treeDataChildren
    }];

    this.setState({
      treeData,
      selectedComps: treeDataChildren.map(item => item.value)
    })
  }
  inputChange = (e) => {
    this.setState({
      inputValue: e.target.value
    })
  }

  inputSave = (e) => {
    const value = e.target.value;
    if (!value) {
      this.setState({
        inputValue: this.state.value
      })
      return;
    }
    const error = numberCheck(value);
    if (error) {
      this.setState({
        inputValue: this.state.value
      })
      return;
    }
    this.setState({
      inputValue: value,
      value
    })
  }

  changeUnit = (type, newUnit) => {
    /* const { value, inputValue, unit } = this.state; */
    /*    const { record } = this.props; */
    /*     const _value = record.type === CAP ? value[type] : value;
        const newValue = unitChange({
          num: _value,
          oldUnit: record.type === CAP ? unit[type] : unit,
          newUnit,
          decimals: 3
        }).number; */

    /*  if (record.type === CAP) {
       this.setState({
         value: { ...value, [type]: newValue },
         unit: { ...unit, [type]: newUnit },
       })
       return
     } */
    this.setState({
      /*    value: newValue, */
      unit: newUnit
    })
  }

  addonAfter = (type, unit) => {
    return UnitAddonAfter({
      unit,
      changeUnit: (_unit) => this.changeUnit(type, _unit),
      list: _RLC_UNITS[getShuntRLCType(type).toUpperCase()] || []
    })
  }

  capInputChange = (e, type) => {
    const value = e.target.value;
    const { inputValue } = this.state;
    this.setState({
      inputValue: { ...inputValue, [type]: value }
    })
  }

  capInputSave = (e, type) => {
    const _value = e.target.value;
    const error = numberCheck(_value);
    const { value, inputValue } = this.state;
    if (error) {
      this.setState({
        inputValue: { ...inputValue, [type]: value[type] }
      })
      return
    }
    this.setState({
      value: { ...value, [type]: _value }
    })
  }

  getInput = () => {
    const { inputValue, unit } = this.state;
    const { record } = this.props;
    /*  if (record.type === CAP) {
       return RLC_TYPES.map((key, index) =>
         <div key={key} className={index < RLC_TYPES.length - 1 ? "virtual-comp-value-item virtual-comp-cap-value-item" : "virtual-comp-value-item"}>
           <span>{index === 0 ? "Value" : null}</span>
           <Input
             value={inputValue[key]}
             placeholder={RLC_PLACEHOLDER[key.toUpperCase()]}
             className="aurora-input"
             onChange={(value) => this.capInputChange(value, key)}
             onBlur={(value) => this.capInputSave(value, key)}
             onPressEnter={(value) => this.capInputSave(value, key)}
             addonAfter={this.addonAfter(key, unit[key])}
           />
         </div>)
     } */
    return <div className="virtual-comp-value-item">
      <span>Value</span>
      <Input
        value={inputValue}
        className="aurora-input"
        onChange={this.inputChange}
        onBlur={this.inputSave}
        onPressEnter={this.inputSave}
        addonAfter={this.addonAfter(record.type, unit)}
      />
    </div>
  }

  onTreeChange = (keys) => {
    this.setState({
      selectedComps: keys
    })
  }

  closeModal = () => {
    const { value, unit, selectedComps, treeData } = this.state;
    /*  const { record } = this.props; */
    const _selectedComps = selectedComps.includes("All") ? treeData[0].children.map(item => item.value) : selectedComps;
    let _value = value;
    /* if (record.type === CAP) {
      _value = {
        r: "",
        l: "",
        c: ""
      }
      const rUnit = unit[RES] === "Ω" ? "" : unit[RES];
      const lUnit = unit[IND] === "H" ? "" : unit[IND];
      const cUnit = unit[CAP] === "F" ? "" : unit[CAP];
      _value.r = value[RES] && parseFloat(value[RES]) !== 0 ? `${value[RES]}${rUnit}` : value[RES] || "";
      _value.l = value[IND] && parseFloat(value[IND]) !== 0 ? `${value[IND]}${lUnit}` : value[IND] || "";
      _value.c = value[CAP] && parseFloat(value[CAP]) !== 0 ? `${value[CAP]}${cUnit}` : value[CAP] || "";

    } else { */
    const _unit = ["Ω", "H", "F"].includes(unit) ? "" : unit;
    _value = value ? `${value}${_unit}` : "";
    /*  } */
    this.props.editComponentValue(_value, _selectedComps, this.props.record);
    this.props.save();
  }

  renderDialog = () => {
    const { treeData, selectedComps } = this.state;
    const { record } = this.props
    return <CopySetupPanel
      title={`${record.type} Value - ${record.name}`}
      children={this.getInput()}
      typeTitle="value"
      treeData={treeData}
      selectedValue={selectedComps}
      placeholder="Select Virtual Components"
      onTreeChange={this.onTreeChange}
      closeModal={this.closeModal}
    />
  }

  render() {
    return (
      <Fragment>
        <div className='editable-cell-value-wrap'>
          {this.props.text || ""}
        </div>
        {this.renderDialog()}
      </Fragment>
    )
  }
}

export default ValueEdit;