import {
  getTree, updateTree, deleteTree, runTree, uploadTreeFile, treeResult,
  getPowerTreeSetting, savePowerTreeSetting, multiTreeResult, updateTreeSingle,
  getPowerTreeSpiceStatus,
  multiTreeSpiceResult,
  powerTreeSpiceResult
} from '../../api/Cascade/PowerTree';
import apiProcess from '../../api/utility';

function getPowerTree(verificationId) {
  return apiProcess(getTree, verificationId)
}

function savePowerTree({ verificationId, params }) {
  return apiProcess(updateTree, { verificationId, params })
}

function deletePowerTree({ verificationId, ids }) {
  return apiProcess(deleteTree, { verificationId, ids })
}

function powerTreeSimulation(verificationId) {
  return apiProcess(runTree, verificationId)
}

function importTreeSpec({ verificationId, file }) {
  return apiProcess(uploadTreeFile, { verificationId, file })
}

function getTreeResult(verificationId) {
  return apiProcess(treeResult, verificationId)
}

function getPowerSetting(verificationId) {
  return apiProcess(getPowerTreeSetting, verificationId)
}

function savePowerSetting({ verificationId, setup }) {
  return apiProcess(savePowerTreeSetting, { verificationId, setup })
}

function getMultiTreeResult(verificationId) {
  return apiProcess(multiTreeResult, verificationId)
}

function savePowerTreeSingle({ verificationId, params }) {
  return apiProcess(updateTreeSingle, { verificationId, params })
}

function getTreeSpiceStatus(verificationId) {
  return apiProcess(getPowerTreeSpiceStatus, verificationId);
}

function getMultiTreeSpiceResult(verificationId) {
  return apiProcess(multiTreeSpiceResult, verificationId);
}

function getPowerTreeSpiceResult(verificationId) {
  return apiProcess(powerTreeSpiceResult, verificationId)
}

export {
  getPowerTree,
  savePowerTree,
  deletePowerTree,
  powerTreeSimulation,
  importTreeSpec,
  getTreeResult,
  getPowerSetting,
  savePowerSetting,
  getMultiTreeResult,
  savePowerTreeSingle,
  getTreeSpiceStatus,
  getMultiTreeSpiceResult,
  getPowerTreeSpiceResult
}