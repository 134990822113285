import CeDonutRound from './CeDonutRound';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeDonutSqrRnd = function () {
  CeDonutRound.call(this, "DonutSqrRnd");
}

// subclass extends superclass
CeDonutSqrRnd.prototype = Object.create(CeDonutRound.prototype);
CeDonutSqrRnd.prototype.constructor = CeDonutSqrRnd;

CeDonutSqrRnd.prototype.Clone = function () {
  var clnObj = new CeDonutSqrRnd();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeDonutSqrRnd.prototype.WriteIntoIODataNode = function () {
  var dataVals = CeDonutRound.prototype.GetDataString.call(this);
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

export default CeDonutSqrRnd;