import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Spin, Button, Checkbox, Select, /* Dropdown, */ Menu, Input, Radio } from 'antd';
import Panel from '@/components/Panel';
import designConstructor from '@/services/helper/designConstructor';
import DelConfirm from '@/components/DelConfirm';
import {
  DefaultInterfaceCreationConfig,
  GROUP_NAME
} from '@/services/Sierra/helper/InterfaceHelper'
import FindInterface from '@/services/Sierra/helper/findInterface';
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import componentDoNotStuff from '@/services/helper/componentsHelper/compDoNotStuff';
import { getInterDefaultName } from '@/services/helper/setDefaultName';
import { getVerificationContentPromise, getSierraLayoutComponents } from '@/services/Sierra';
import { DESIGN_SUCCESS } from '@/constants/designCategory';
import debounce from '@/services/helper/debounceFn';
import { getLayoutDBInfo } from '@/services/PCBHelper/getLayoutDB';
import { RLC_TYPES, getCompsByNets } from '@/services/PCBHelper';
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import DesignInfo from '@/services/Sierra/pcbInfo';
import {
  getSelectedInterfaces,
  getSearchAdvancedSetup,
  addSelectedSignalsToInterface,
  addSelectedSignalsToConnTable,
  getFilterIdentificationLog
} from '../../../../services/Sierra/helper/interfaceCreationHelper';
import PCBSelection from './PCBSelection';
import PCBSetup from './PCBSetup';
import InterfaceCreation from './interfaceCreation';
import Connections from '../Connections';
import {
  getConnectionsInterfaces,
  initNewInterfaceBySignals,
  addSingleInterfaceToConn
} from '../../../../services/Sierra/helper/interfaceAutoConnHelper';
import { getPanelMaxHeight, getPanelMaxWidth } from '../../../../services/helper/panelSizeHelper';
import MsgDialog from '../../../../components/MsgDialog';
import MergeSignals, { MERGE_SIGNAL_SUCCESS, /* NEED_MANUAL */ } from '../../../../services/Sierra/helper/mergeSignalsHelper';
import MergeSignalsPanel from './MergeSignalsPanel';
import { getSelectedDesignIDs } from '../../../../services/helper/dataProcess';
import { selectChange, changeDisplaySelected, selectLayer, changeShowNameStatus } from '../../../LayoutExplorer/store/Sierra/actionCreators';
import canvas from '@/services/LayoutCanvas';
import MsgDialogPanel from '../../../../components/MsgDialog/msgPanel';
import { getAutoCombineInterfaces } from '../../../../services/Sierra/helper/connectSignalsHelper';
import { getDesignSearchCriteria, updateDesignSearchCriteria } from '../../../../services/api/Design/design';
import { updateCreatePanelLoading, reCreateDuplicationVerifications } from '../../store/sierra/action';
import { getProjectConfig, updateProjectConfig } from '../../../../services/Sierra';
import compTableHelper from '../../../../services/Sierra/helper/compTableHelper';
import powerStore from '../../../../services/Sierra/helper/powerNetsTableHelper';
import CreateConfirmPanel from './createConfirmPanel';
import './index.css';

const confirmErrorMsg = "PCB is missing. Interface will not be created."
const NAME = "name", NO_INTERFACE = "noInterface",
  SELECT_INTERFACE = 'select_interface', CREATE_INTERFACE = 'create_interface',
  CONNECTION_TABLE = 1, NEW_INTERFACE = 2,
  SINGLE = "single",
  MULTI = "multi",
  SEPARATE = "separate",
  ONE = "one",
  MULTI_BOARD = "multi_board",
  MULTI_BOARD_MAPPING = "multi_board_mapping";

class CreateIdentificationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pcbSelectionList: [],
      loading: false,
      confirmVisible: false,
      contentType: CREATE_INTERFACE,
      name: '',
      inputName: "",
      errorMsg: null,
      allInterfaceCompNetData: [],
      selectKeys: [],
      mergeAllSignal: {
        single: ONE,
        multi: false
      },
      PCBList: [],
      pcbConfig: {},//{key:value} -> key:pcbId, value:interface config,
      pcbComponents: {}, //{key:value}-> key:pcbId, value:pcb component list,
      pcbInterfaceInfo: {}, //{key:value}-> key:pcbId, value:signals interface data
      searchInterfaceMsgList: [],
      applySetupAll: true,
      multiPCBConnections: [],
      expandedPCBKeys: [],
      interfaceList: [],
      expandedInterfaceKey: "",
      connInterfaces: [],
      updateConnStatus: false,
      maxWidth: 1000,
      maxHeight: 1000,
      selectedSignalKeys: [],
      selectedCombineInterface: NEW_INTERFACE,
      connections: [],
      PCBSetupExpanded: true,
      interfaceSetupExpanded: false,
      pcbConfigComponent: {},
      groupName: ""
    }
    this.dialogRoot = document.getElementById('root');
    this.pcbLoading = {};
    this.pcbComponent = {};
  }

  componentDidMount = () => {
    const { openProjectId } = this.props;
    window.addEventListener('resize', this.resize);
    this.resize();
    this.props.updateCreatePanelLoading(false)
    if (openProjectId) {
      this.setDefaultName('Interface')
      this.getDefaultPcb(openProjectId);
      this.getDefaultApplyAll();
    }
  }

  getDefaultApplyAll = async () => {
    try {
      const config = await getProjectConfig(this.props.openProjectId);
      this.setState({
        applySetupAll: typeof (config.searchApplyAll) === "boolean" ? config.searchApplyAll : true
      })
    } catch (error) {
      console.error(error)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 770),
      maxHeight: getPanelMaxHeight(offset, 402)
    })
  }

  componentDidUpdate = (prevProps) => {
    const { openProjectId, identificationCreateMsg, createdLoading } = this.props;
    if (openProjectId !== prevProps.openProjectId) {
      this.getDefaultPcb(openProjectId);
      this.getDefaultApplyAll();
    }
    if (identificationCreateMsg !== prevProps.identificationCreateMsg) {
      this.updateLoading(false)
    }

    if (createdLoading && createdLoading !== prevProps.createdLoading) {
      this.setState({
        loading: false
      })
      this.props.updateCreatePanelLoading(false)
    }
  }

  onFix = () => {
    this.setState({
      confirmVisible: false,
      loading: false
    })
  }

  changeConfirmVisible = (visible) => {
    this.setState({
      confirmVisible: visible
    })
  }

  updateLoading = (loading) => {
    this.setState({
      loading: loading
    })
  }

  closeCreatePanel = () => {
    this.props.closePanel(false)
  }

  updateInfo = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  getDefaultPcb = (openProjectId) => {
    const pcbSelectionList = designConstructor.getAvailableDesignsNotPreLayout(openProjectId) || {};

    const { channelList } = this.props;
    const channelLength = channelList.length;
    if (!pcbSelectionList || !pcbSelectionList.length) { return }
    if (pcbSelectionList.length === 1 || !channelLength) {
      this.setState({
        PCBList: [pcbSelectionList[0].id],
        pcbSelectionList
      }, () => {
        this.pcbSelectChange([pcbSelectionList[0].id], true)
      })

    } else if (pcbSelectionList.length > 1 && channelLength) {
      const lastDataId = channelList[channelLength - 1].id
      getVerificationContentPromise(lastDataId).then(res => {
        let key = pcbSelectionList[0].id
        if (res && res.Interfaces && res.Interfaces.length) {
          key = res.Interfaces[0].pcbId;
        }

        if (pcbSelectionList.map(item => item.id).includes(key)) {
          this.setState({
            PCBList: [key],
            pcbSelectionList
          }, () => {
            this.pcbSelectChange([key], true)
          })
        } else {
          this.setState({
            PCBList: [pcbSelectionList[0].id],
            pcbSelectionList
          }, () => {
            this.pcbSelectChange([pcbSelectionList[0].id], true)
          })
        }
      });
    }
  }

  pcbSelectChange = async (keys, isDefault = false) => {
    const { pcbSelectionList, PCBList, pcbConfig, applySetupAll, expandedPCBKeys, mergeAllSignal } = this.state;
    const newSelects = isDefault ? keys : keys.filter(item => !PCBList.includes(item));
    const delSelects = isDefault ? [] : PCBList.filter(item => !keys.includes(item));
    let _pcbConfig = { ...pcbConfig }, _PCBList = [...PCBList], _expandedPCBKeys = [...(expandedPCBKeys || [])];

    for (let id of newSelects) {
      this.pcbLoading[id] = true;
    }
    this.setState({
      PCBList: [...keys]
    })
    if (delSelects.length) {
      //delete pcb
      _PCBList = _PCBList.filter(item => !delSelects.includes(item));
      delSelects.forEach(id => delete _pcbConfig[id]);
    }

    if (newSelects.length) {
      const newPCBSetup = getSearchAdvancedSetup({ pcbConfig: _pcbConfig, applySetupAll });
      let excludeIsRegular = newPCBSetup.setting ? newPCBSetup.setting.excludeIsRegular : true, isUpdateExclude = false;
      for (let id of newSelects) {
        const findData = pcbSelectionList.find(item => item.id === id);
        if (!findData || findData.category !== DESIGN_SUCCESS) {
          continue;
        }

        _pcbConfig[id] = new DefaultInterfaceCreationConfig({ type: newPCBSetup.type, setting: newPCBSetup.setting });
        try {
          const config = await getDesignSearchCriteria(id);
          if (config && config.exclude && config.exclude.length && _pcbConfig[id].advancedConfig) {
            _pcbConfig[id].advancedConfig.EXCLUDE = [...config.exclude];
            _pcbConfig[id].advancedConfig.excludeIsRegular = true;
            if (!excludeIsRegular) {
              isUpdateExclude = true;
            }
          }
        } catch (error) {
          console.error(error)
        }

        if (applySetupAll && isUpdateExclude) {
          for (let id of Object.keys(_pcbConfig || {})) {
            if (_pcbConfig[id].advancedConfig.excludeIsRegular) {
              continue;
            }
            _pcbConfig[id].advancedConfig.excludeIsRegular = true;
            _pcbConfig[id].advancedConfig.EXCLUDE = [`.*${_pcbConfig[id].advancedConfig.EXCLUDE.join("|")}.*`];
          }
        }

        this.pcbLoading[id] = true;
        _PCBList.push(id);
        _expandedPCBKeys.push(id);
      }
    }

    _PCBList = [...new Set(_PCBList)];
    if (isDefault && _PCBList[0] && _pcbConfig && _pcbConfig[_PCBList[0]]) {
      this.changeDefaultName(_pcbConfig[_PCBList[0]].interfaceType || "Interface")
    }
    this.setState({
      pcbConfig: _pcbConfig,
      PCBList: _PCBList,
      expandedPCBKeys: _expandedPCBKeys,
      mergeAllSignal: { ...mergeAllSignal, [SINGLE]: _PCBList.length > 1 ? MULTI_BOARD : SEPARATE }
    }, async () => {
      newSelects.length && await this.loadPCB(newSelects);
    })
  }

  loadPCB = (ids) => {
    const { pcbComponents } = this.state;
    let _pcbComponents = { ...pcbComponents };
    for (let id of ids) {
      getLayoutDBInfo(id, true).then(pcbInfo => {
        if (pcbInfo) {
          DesignInfo.savePCBInfo(id, pcbInfo);
          const compList = getSierraLayoutComponents(pcbInfo.layers);
          let list = Object.values(compList);
          list = list.filter(item => item.part);
          const filterList = list.filter(item => !RLC_TYPES.includes(item.type) && item.pinList.length > 1/*  && item.name.match(reg) */);
          const _filterList = filterList.sort(function (a, b) {
            return b.pinList.length - a.pinList.length
          });
          _pcbComponents[id] = _filterList;

          if (_filterList.length === 1) {
            this.pcbComponent[id] = _filterList[0];
          }

          this.pcbLoading[id] = false;
          this.setState({
            pcbComponents: _pcbComponents
          })

        } else {
          _pcbComponents[id] = [];
          this.pcbLoading[id] = false;
          this.setState({
            pcbComponents: _pcbComponents,
          })
        }
      }, error => {
        console.error(error);
        _pcbComponents[id] = [];
        this.pcbLoading[id] = false;
        this.setState({
          pcbComponents: _pcbComponents,
        })
      })
    }
  }

  changeDefaultName = (key) => {
    const defaultKey = key.split('/')[0]
    this.setDefaultName(defaultKey);
  }

  setDefaultName = (defaultKey) => {
    // Get default naming
    const { channelList = [] } = this.props;
    const name = getInterDefaultName({ nameList: channelList, defaultKey });
    this.setState({
      name: name[0],
      inputName: name[0]
    })
  }

  inputChange = (e, type) => {
    const { errorMsg } = this.state;
    this.setState({
      [type]: e.target.value,
      errorMsg: errorMsg && [NO_INTERFACE, type, GROUP_NAME].includes(errorMsg.type) ? null : errorMsg
    });
  }

  checkGroupName = (value, type) => {
    let error = checkNameFormat(value, { errorType: "Group Name", maxLength: 100, allowEmpty: true });
    this.setState({
      errorMsg: error ? { type, msg: error } : this.state.errorMsg
    });
  }

  checkValue = (value, type, isCreate) => {
    if (type === GROUP_NAME) {
      this.checkGroupName(value, type);
      return;
    }
    const { channelList = [] } = this.props;
    const { inputName } = this.state;
    let error = checkNameFormat(isCreate ? inputName : value);

    const findChannel = channelList.find(item => item.name === value);
    if (findChannel) {
      error = `${value} already exists!`;
    }

    this.setState({
      errorMsg: error ? { type, msg: error } : this.state.errorMsg
    });
    return error;
  }

  getIdentificationInfo = async ({ data, advancedConfig, searchInterfaceMsgList, pcbInterfaceInfo, lastOne, searchLogs }) => {
    const { pcb, component, componentList } = data
    const interfaceInfo = new FindInterface(pcb.id);
    const compPrefixLib = await componentSetting.getPrefixLib(pcb.id);
    const compPinMap = await componentSetting.getCompPinMap(pcb.id);
    const doNotStuff = await componentDoNotStuff.getDoNotStuff(pcb.id);
    const _compTableHelper = await compTableHelper.getTable(pcb.id);
    const powerTableNets = await powerStore.getTable(pcb.id, "netName");
    let allInterfaceCompNetData = interfaceInfo.autoFilterInterfacePcbSignalNets({
      advancedConfig,
      component: component && component.name ? component : this.pcbComponent[pcb.id] || null,
      componentList,
      pcbs: [pcb.name],
      pcbId: pcb.id,
      currentPcbSetting: { compPrefixLib, doNotStuff, compPinMap, passiveTable: (_compTableHelper || {}).table, powerTableNets }
    });
    const logs = interfaceInfo.getLogList();
    searchLogs[pcb.id] = { name: pcb.name, logs };
    this.setState({
      searchLogs
    })
    if (!allInterfaceCompNetData || !allInterfaceCompNetData.length) {
      searchInterfaceMsgList.push(`${pcb.name}: No qualified signals found!`);
      this.updateSearchInterfaceMsg(searchInterfaceMsgList);
    } else {
      pcbInterfaceInfo[pcb.id] = {
        allInterfaceCompNetData,
        selectKeys: []
      }
      this.setState({
        pcbInterfaceInfo
      })
    }
    if (lastOne) {
      this.setState({
        contentType: SELECT_INTERFACE,
        errorMsg: null,
        loading: false
      })
    }
  }

  backToSetting = () => {
    const { identificationCreateMsg } = this.props;
    if (identificationCreateMsg) { this.props.updateIdentificationMassage(null) }
    this.setState({
      pcbInterfaceInfo: {},
      contentType: CREATE_INTERFACE,
      errorMsg: null,
      connInterfaces: [],
      interfaceList: [],
      selectedSignalKeys: [],
      expandedInterfaceKey: "",
      selectedCombineInterface: NEW_INTERFACE,
      updateConnStatus: false,
      PCBSetupExpanded: true,
      interfaceSetupExpanded: true
    })
  }

  selectInterface = () => {
    const { pcbInterfaceInfo, name, errorMsg, mergeAllSignal, interfaceList, selectedSignalKeys, connInterfaces, connections, PCBList, groupName } = this.state;
    const { identificationCreateMsg } = this.props;
    if ((!interfaceList.length && (!connInterfaces.length || !selectedSignalKeys.length))) {
      const msg = 'Please select at least one set of interfaces';
      this.setState({
        errorMsg: { type: SELECT_INTERFACE, msg }
      })
    } else {
      if (identificationCreateMsg || (errorMsg && errorMsg.msg)) { return }
      if ((PCBList.length === 1 || (!interfaceList.length && mergeAllSignal.multi)) && this.checkValue(name, NAME, true)) {
        //check interface name
        return;
      }
      this.setState({
        loading: 'Creating interfaces...',
        searchInterfaceMsgList: []
      }, () => {
        setTimeout(() => {
          const selectInterfaces = getSelectedInterfaces({
            pcbInterfaceInfo,
            name,
            mergeAllSignal: mergeAllSignal.multi,
            connInterfaces,
            interfaceList,
            selectedSignalKeys
          })
          this.props.createInterfaceIdentification({ interfaceIdentificationInfo: selectInterfaces, connections, groupName })
        }, 100)
      })
    }
  }

  searchInterface = () => {
    debounce(async () => {
      const { pcbSelectionList, pcbComponents, PCBList, pcbConfig, applySetupAll } = this.state;
      const { errorMsg, name } = this.state;
      if (errorMsg) { return }
      if (!PCBList.length) {
        this.changeConfirmVisible(true)
        return
      }
      this.setState({
        loading: true
      })
      let searchInterfaceMsgList = [], pcbInterfaceInfo = {}, searchLogs = {};
      const pcbList = PCBList.map(item => { return { id: item, name: pcbSelectionList.find(it => it.id === item).name } })
      for (let i = 0; i < pcbList.length; i++) {
        const pcb = pcbList[i];
        const currPcbConfig = pcbConfig[pcb.id] || {};
        const data = {
          pcb,
          name,
          type: currPcbConfig.interfaceType,
          component: currPcbConfig.component,
          componentList: (pcbComponents[pcb.id] || []).map(item => item.name)
        }
        searchInterfaceMsgList.push(`Search ${pcb.name} signals...`);
        this.updateSearchInterfaceMsg(searchInterfaceMsgList);
        try {
          await this.getIdentificationInfo({
            data,
            advancedConfig: currPcbConfig.advancedConfig || {},
            searchInterfaceMsgList,
            pcbInterfaceInfo,
            lastOne: i === pcbList.length - 1,
            searchLogs
          })
          if (currPcbConfig.advancedConfig && currPcbConfig.advancedConfig.EXCLUDE) {
            let _exclude = [...(currPcbConfig.advancedConfig.EXCLUDE || [])];
            if (!currPcbConfig.advancedConfig.excludeIsRegular && _exclude.length) {
              _exclude = [`.*${_exclude.join("|")}.*`];
            }

            await updateDesignSearchCriteria(pcb.id, { exclude: _exclude })
          }
        } catch (error) {
          console.error(error)
        }
      }
      try {
        await updateProjectConfig(this.props.openProjectId, { searchApplyAll: applySetupAll });
      } catch (error) {
        console.error(error)
      }

    }, 1000, true)();
  }

  updateSearchInterfaceMsg = (list) => {
    this.setState({
      searchInterfaceMsgList: list
    })
  }

  okConfirm = (type) => {
    const { contentType, errorMsg } = this.state;
    if (contentType === SELECT_INTERFACE) {
      if (this.props.identificationCreateMsg) { this.props.updateIdentificationMassage(null) }
      this.setState({
        errorMsg: errorMsg && errorMsg.type === SELECT_INTERFACE ? null : errorMsg
      }, () => {
        if (type === SINGLE) {
          this.createSelectedSignals()
        } else {
          this.selectInterface()
        }
      })
    } else {
      this.searchInterface()
    }
  }

  cancelConfirm = () => {
    const { contentType } = this.state;
    if (contentType === SELECT_INTERFACE) {
      this.backToSetting()
    } else {
      this.props.closePanel(false)
    }
  }

  getContentFooter = (msg) => {
    const { contentType, applySetupAll, PCBList, pcbConfig } = this.state;
    const disabled = PCBList.find(item => !!this.pcbLoading[item]);
    const interfaceType = pcbConfig && PCBList.length ? (pcbConfig[PCBList[0]] || {}).interfaceType : ""
    return <Fragment>
      {msg ?
        <div className={contentType === CREATE_INTERFACE ? 'create-panel-msg-box' : 'create-panel-msg-box select-content-msg-box'}>
          <span className='aurora-model-name-error-msg'>{msg}</span>
        </div> : null
      }
      {contentType === CREATE_INTERFACE && PCBList.length > 1 ? <div className='create-interface-merge-content'>
        <Checkbox
          checked={applySetupAll}
          onChange={(e) => this.onCheckAllChange(e, "applySetupAll")}
          className='create-interface-merge-checkbox'
        />
        <div>Apply {interfaceType === "Custom" ? "all" : "advanced"} setup to all PCBs</div>
      </div> : null}
      <div className="create-footer-content">
        {contentType === CREATE_INTERFACE ? <Button key="ok" type="primary" disabled={disabled} onClick={this.okConfirm}>OK</Button> : null}
        <Button key="cancel" style={{ marginLeft: 30 }} onClick={this.cancelConfirm} >Cancel</Button>
      </div>
    </Fragment>
  }

  createSelectedSignals = () => {
    const { pcbInterfaceInfo, name, errorMsg, mergeAllSignal, PCBList, pcbConfig, groupName } = this.state;
    const { identificationCreateMsg } = this.props;
    const allSelectKeys = Object.keys(pcbInterfaceInfo || {}).map(item => pcbInterfaceInfo[item].selectKeys || []).flat(2);
    if (!allSelectKeys.length) {
      const msg = 'Please select at least one set of interfaces';
      this.setState({
        errorMsg: { type: SELECT_INTERFACE, msg }
      })
    } else {
      if (identificationCreateMsg || (errorMsg && errorMsg.msg)) { return }
      if ((PCBList.length === 1 || mergeAllSignal.single === ONE) && this.checkValue(name, NAME, true)) {
        //check interface name
        return;
      }
      this.setState({
        loading: 'Creating interfaces...',
        searchInterfaceMsgList: []
      }, () => {
        setTimeout(() => {
          let selectInterfaces = []
          if (mergeAllSignal[SINGLE] === MULTI_BOARD) {
            const allSelectKeys = Object.keys(pcbInterfaceInfo || {}).map(item => pcbInterfaceInfo[item].selectKeys || []);
            if (allSelectKeys.find(item => item.length > 1)) {
              this.setState({
                loading: null,
                errorMsg: { type: SELECT_INTERFACE, msg: 'A PCB only allows one interface to be selected for combine.' }
              })
              return;
            }
            const { combineInterfaces, selectedSignalKeys } = getAutoCombineInterfaces({
              pcbInterfaceInfo,
              pcbConfig
            })
            selectInterfaces = getSelectedInterfaces({
              connInterfaces: combineInterfaces,
              name,
              mergeAllSignal: false,
              selectedSignalKeys
            })
          } else {
            selectInterfaces = getSelectedInterfaces({
              pcbInterfaceInfo,
              name,
              mergeAllSignal: mergeAllSignal.single === ONE,
              type: SINGLE
            })
          }
          if (!selectInterfaces.length) {
            this.setState({
              loading: null,
              searchInterfaceMsgList: [""]
            })
            return
          }

          this.props.createInterfaceIdentification({ interfaceIdentificationInfo: selectInterfaces, connections: [], groupName })
          /* this.setState({
            loading: null
          }) */
        }, 100)
      })
    }
  }

  selectCombineInterface = (value) => {
    this.setState({
      selectedCombineInterface: value
    })
  }

  combineSelectedSignals = () => {
    const { selectedSignalKeys, connInterfaces, interfaceList, selectedCombineInterface } = this.state;
    let newInterfaceList = initNewInterfaceBySignals({
      selectedSignalKeys,
      connInterfaces,
      interfaceName: selectedCombineInterface,
      interfaceList,
      channelList: this.props.channelList || []
    });
    this.setState({
      interfaceList: newInterfaceList,
      updateConnStatus: true,
      selectedSignalKeys: []
    })
  }

  getSelectInterfaceOptions = () => {
    const { mergeAllSignal, /* interfaceList, PCBList */ } = this.state;
    /*  const mergeVisible = PCBList.length > 1 && !interfaceList.length,
       nameVisible = mergeAllSignal.multi && !interfaceList.length;
     if (!mergeVisible && !nameVisible) {
       return null;
     } */
    return /* mergeVisible ? */ <div className='cerate-interface-footer'>
      {/* {nameVisible ? this.getInterfaceNameRender() : null} */}
      <div className='create-interface-merge-content create-interface-single-content'>
        <div className='create-interface-single-checkbox-content'>
          <Checkbox
            checked={mergeAllSignal.multi}
            onChange={(e) => this.onCheckAllChange(e, "mergeAllSignal", MULTI)}
            className='create-interface-merge-checkbox'
          />
          <div>Merge signals into one interface</div>
        </div>
        <div className='create-interface-single-create-button'>
          <Button
            key="connect"
            type="primary"
            size="small"
            /*  ghost */
            title="Create"
            className='create-selected-signal-button'
            onClick={() => this.okConfirm(MULTI)}
          >Create</Button>
        </div>
      </div>
    </div>/*  : null */
  }

  getCombineButtons = () => {
    const { interfaceList, selectedCombineInterface, selectedSignalKeys, connInterfaces } = this.state;
    if (!connInterfaces || !connInterfaces.length) {
      return null;
    }
    const text = selectedCombineInterface === NEW_INTERFACE ? "Create" : "Add";
    return <div className='create-interface-merge-content'>
      <div>Add selected signals into</div>
      <Select
        className="aurora-select"
        popupClassName="aurora-select-dropdown"
        onSelect={(value) => this.selectCombineInterface(value)}
        popupMatchSelectWidth={false}
        value={selectedCombineInterface}
        size='small'
        showSearch={true}
      >
        <Select.Option key={NEW_INTERFACE} value={NEW_INTERFACE}>New Interface</Select.Option>
        {(interfaceList || []).map(item => <Select.Option
          key={item.interfaceName}
          value={item.interfaceName}>
          {item.interfaceName}
        </Select.Option>)}
      </Select>
      <div>
        <Button
          key="combine"
          type="primary"
          ghost
          size="small"
          className='combine-selected-signals-button'
          disabled={!selectedCombineInterface || !selectedSignalKeys.length}
          onClick={this.combineSelectedSignals}
          title={text}>
          {text}
        </Button>
      </div>
    </div>
  }

  AddSelectedSignals = (interfaceName) => {
    if (!interfaceName) {
      return;
    }
    const { interfaceList, pcbInterfaceInfo, connInterfaces } = this.state;
    const { channelList } = this.props;
    if (interfaceName === CONNECTION_TABLE) {
      const { _connInterfaces, _pcbInterfaceInfo } = addSelectedSignalsToConnTable({ connInterfaces, interfaceName, pcbInterfaceInfo })
      this.setState({
        connInterfaces: _connInterfaces,
        pcbInterfaceInfo: _pcbInterfaceInfo,
        updateConnStatus: true
      })
    } else {
      const { _interfaceList, _pcbInterfaceInfo } = addSelectedSignalsToInterface({ interfaceList, interfaceName, pcbInterfaceInfo, channelList })
      this.setState({
        interfaceList: _interfaceList,
        pcbInterfaceInfo: _pcbInterfaceInfo,
        updateConnStatus: true
      })
    }
  }

  addSignalMenu = () => {
    const { interfaceList/* , PCBList */ } = this.state;
    const interfaceNames = (interfaceList || []).map(item => item.interfaceName);
    return <Menu items={[{ key: NEW_INTERFACE, label: 'New Interface', onClick: () => this.AddSelectedSignals(NEW_INTERFACE) }, ...interfaceNames.map(name => ({ key: name, label: name, onClick: () => this.AddSelectedSignals(name) }))]} />
  }

  onRadioChange = (e) => {
    const value = e.target.value;
    this.setState({
      mergeAllSignal: { ...this.state.mergeAllSignal, [SINGLE]: value }
    })
  }

  getSelectSignalsOptions = () => {
    const { contentType, mergeAllSignal, PCBList } = this.state;
    if (contentType === SELECT_INTERFACE) {
      return <div className={PCBList.length === 1 ? "pcb-setup-footer pcb-setup-single-footer" : 'pcb-setup-footer'}>
        {PCBList.length === 1 ?
          <div className='create-interface-single-checkbox-content'>
            <Checkbox
              checked={mergeAllSignal[SINGLE] === ONE}
              onChange={(e) => this.onCheckAllChange(e, "mergeAllSignal", SINGLE)}
              className='create-interface-merge-checkbox'
            />
            <div>Merge interfaces into one interface</div>
          </div>
          :
          <Fragment>
            <Radio.Group
              onChange={this.onRadioChange}
              value={mergeAllSignal[SINGLE]}>
              <Radio
                className='pcb-interface-creation-radio'
                value={SEPARATE}
              >Create separate interfaces</Radio>
              <Radio
                className='pcb-interface-creation-radio'
                value={ONE}
              >Create one interface</Radio>
              <Radio
                className='pcb-interface-creation-radio'
                value={MULTI_BOARD}
              >Create multi-board interface
              </Radio>
              <Radio
                className='pcb-interface-creation-radio'
                value={MULTI_BOARD_MAPPING}
              >Create multi-board interface by mapping table
              </Radio>
              {this.getConnections()}
            </Radio.Group>
          </Fragment>
        }

        <div className='create-interface-single-create-button'>
          <Button
            key="connect"
            type="primary"
            size="small"
            title="Create"
            className='create-selected-signal-button'
            disabled={mergeAllSignal[SINGLE] === MULTI_BOARD_MAPPING}
            onClick={() => this.okConfirm(SINGLE)}
          >Create</Button>
        </div>
      </div>
    }
  }

  mergeSignalsToInterfaceButton = (pcbId) => {
    const { pcbInterfaceInfo } = this.state;
    const mergeEnabled = pcbInterfaceInfo[pcbId] && pcbInterfaceInfo[pcbId].selectKeys && pcbInterfaceInfo[pcbId].selectKeys.length >= 2;

    return <div className='merge-selected-signal-button-div' onClick={(e) => { e.stopPropagation() }}>
      <Button
        key="merge"
        type="primary"
        ghost
        size="small"
        disabled={!mergeEnabled}
        onClick={() => this.mergeSelectedSignals(pcbId)}
        className='merge-selected-signal-button'
        title={"Merge selected signals into one signal"}
      >Merge Signals</Button>
    </div>;
  }

  mergeSelectedSignals = (pcbId) => {
    const { pcbInterfaceInfo, pcbConfig } = this.state;
    let _pcbInterfaceInfo = { ...pcbInterfaceInfo };
    const mergeSignals = new MergeSignals();
    const info = mergeSignals.mergeSignalsToOneSignals(_pcbInterfaceInfo, pcbConfig, pcbId);
    _pcbInterfaceInfo = info._pcbInterfaceInfo;

    if (info.mergeStatus === MERGE_SIGNAL_SUCCESS) {
      this.setState({
        pcbInterfaceInfo: _pcbInterfaceInfo,
      })
    } else {
      this.setState({
        mergeErrorMsgs: info.errorMsgs
      })
    }
  }

  connSignals = (connections) => {
    const { pcbInterfaceInfo, pcbConfig, PCBList } = this.state;
    const interfaceType = pcbConfig && PCBList.length ? (pcbConfig[PCBList[0]] || {}).interfaceType : ""
    let connInterfaces = getConnectionsInterfaces({ connections, pcbInterfaceInfo, interfaceType });
    let msg = "";
    if (connInterfaces.length) {
      msg = "PCBs are connected successfully!";
      connInterfaces = addSingleInterfaceToConn({ connInterfaces, pcbInterfaceInfo })
    } else {
      msg = "None of the PCBs are connected!"
    }
    this.setState({
      connInterfaces,
      updateConnStatus: true,
      connVisible: false,
      showMergeName: true,
      connMsg: msg,
      connections: JSON.parse(JSON.stringify(connections || [])),
      interfaceSetupExpanded: connInterfaces.length ? true : false,
      PCBSetupExpanded: connInterfaces.length ? false : true,
    })

    setTimeout(() => {
      this.setState({
        connMsg: ""
      })
    }, 3000);
  }

  updatePCBSetupExpanded = (status) => {
    this.setState({
      PCBSetupExpanded: status,
    })
  }

  updateConnectionStatus = (updateConnStatus) => {
    this.setState({
      updateConnStatus
    })
  }

  clearSelectedSignals = () => {
    const { pcbInterfaceInfo = {} } = this.state;
    let _pcbInterfaceInfo = { ...pcbInterfaceInfo };
    for (let pcb of Object.keys(_pcbInterfaceInfo)) {
      _pcbInterfaceInfo[pcb].selectKeys = []
    }
    this.setState({
      pcbInterfaceInfo: _pcbInterfaceInfo
    })
  }

  getMassage = () => {
    const { errorMsg, connMsg } = this.state;
    const { identificationCreateMsg } = this.props;
    if (identificationCreateMsg) { return identificationCreateMsg };
    if (errorMsg && errorMsg.msg) { return errorMsg.msg }
    if (connMsg && connMsg.match("None")) { return connMsg }
    return null
  }

  onCheckAllChange = (e, type, subType) => {
    const { pcbConfig, PCBList = [] } = this.state;
    let _pcbConfig = { ...pcbConfig };

    if (type === "applySetupAll" && e.target.checked) {
      //apply first pcb setup to all PCBs
      const newPCBSetup = getSearchAdvancedSetup({ pcbConfig: _pcbConfig, pcbId: PCBList[0], applySetupAll: e.target.checked });
      for (let id of Object.keys(_pcbConfig)) {
        _pcbConfig[id] = new DefaultInterfaceCreationConfig({
          type: newPCBSetup.type,
          component: _pcbConfig[id].component,
          setting: newPCBSetup.setting
        });
      }
    }
    if (type === "mergeAllSignal") {
      this.setState({
        mergeAllSignal: { ...this.state.mergeAllSignal, [subType]: subType !== MULTI ? (e.target.checked ? ONE : SEPARATE) : e.target.checked },
      })
    } else {
      this.setState({
        [type]: e.target.checked,
        pcbConfig: _pcbConfig
      })
    }
  }

  updatePCBConfig = (pcbConfig, pcbId, deleteComp = false) => {
    this.setState({
      pcbConfig
    })
    if (deleteComp) {
      delete this.pcbComponent[pcbId];
    }
  }

  updatePCBInterfaceInfo = (pcbInterfaceInfo) => {
    this.setState({
      pcbInterfaceInfo
    })
  }

  getFootHeight = ({ contentType, msg, PCBList }) => {
    let height = 52;
    if (contentType === CREATE_INTERFACE && PCBList.length > 1) {
      height = 90;
    }
    if (msg) {
      height += 38;
    }
    return height;
  }

  pcbExpandChange = (expandedPCBKeys) => {
    this.setState({
      expandedPCBKeys
    }, () => {
      this.resize(true)
    })
  }

  interfaceExpandChange = (expandedInterfaceKey) => {
    this.setState({
      expandedInterfaceKey
    })
  }

  getConnections = (isTable) => {
    const { mergeAllSignal } = this.state;
    return <div className={isTable ? "interface-creation-connections-button interface-creation-connections-table-button" : 'interface-creation-connections-button'}>
      <Button
        key="connections"
        type="primary"
        ghost
        size="small"
        title="Upload connections excel to connector interfaces"
        onClick={() => this.updateConnectionsVisible(true)}
        disabled={mergeAllSignal[SINGLE] !== MULTI_BOARD_MAPPING}
      >Connector mapping excel</Button>
    </div>
  }

  updateConnectionsVisible = (connVisible) => {
    this.setState({
      connVisible
    })
  }

  updateSelectSignalKeys = (selectedSignalKeys) => {
    this.setState({
      selectedSignalKeys
    })
  }

  updateInterfaceList = (interfaceList) => {
    this.setState({
      interfaceList
    })
  }

  changeInterfaceName = (e) => {
    const { errorMsg } = this.state;
    this.setState({
      inputName: e.target.value,
      errorMsg: errorMsg && errorMsg.type === NAME ? null : errorMsg
    })
  }

  checkName = (name) => {
    const error = this.checkValue(name, NAME);
    if (!error) {
      this.setState({
        name,
        inputName: name
      })
    }
  }

  getInterfaceNameRender = () => {
    const { inputName } = this.state;
    return <div className="create-content-interface-name-item">
      <label className='create-edit-input-label'>Interface Name</label>
      <Input
        className='aurora-input create-content-input'
        value={inputName}
        placeholder={"Interface Name"}
        onChange={(e) => this.changeInterfaceName(e)}
        onPressEnter={(e) => this.checkName(e.target.value)}
        onBlur={(e) => this.checkName(e.target.value)}
      />
    </div>
  }

  getInterfaceHeight = () => {
    let height = 40;
    const { mergeAllSignal, interfaceList, connMsg } = this.state;
    if (connMsg) {
      height += 47;
    }

    /*    if (mergeAllSignal.multi && !interfaceList.length) {
         height += 32;
       } */

    if (!mergeAllSignal.multi && interfaceList.length > 0) {
      height = 0;
    }
    return height;
  }

  clickDialogOkButton = () => {
    this.setState({
      mergeErrorMsgs: null
    })
  }

  closeMergeSignals = ({ save, pcbInterfaceInfo }) => {
    if (save) {
      this.setState({
        pcbInterfaceInfo
      })
    }
    this.setState({
      mergeVisible: false
    })
  }

  showNetsToLayout = ({ e, pcbId, signalNets, interfaceKey }) => {
    e && e.stopPropagation();
    const { pcbInterfaceInfo } = this.state;
    const currInfo = pcbInterfaceInfo[pcbId] || {};
    if (!Object.keys(currInfo).length) {
      return;
    }
    let nets = [];
    if (signalNets) {
      nets = [...signalNets];
    } else if (interfaceKey) {
      nets = (currInfo.allInterfaceCompNetData || []).filter(item => item.interfaceKey === interfaceKey).map(item => item.content || []).flat(2).map(item => item.nets || []).flat(2)
    } else {
      nets = (currInfo.allInterfaceCompNetData || []).map(item => item.content || []).flat(2).map(item => item.nets || []).flat(2)
    }

    nets = [...new Set(nets)];
    const { selectedDesignIDs } = this.props;

    if (nets.length && selectedDesignIDs.includes(pcbId)) {
      const layout = canvas.getLayout(pcbId);
      if (!layout || !layout.findCurrentLayer) {
        return;
      }
      const pcbInfo = DesignInfo.getPCBInfo(pcbId);
      const { netsList = [] } = pcbInfo || {}
      const { comps, pinsObj } = getCompsByNets({ nets, netsList });
      let layers = layout.findCurrentLayer(comps);
      layers = [...new Set(layers)];
      this.props._selectLayer(layers, pcbId);
      this.props._changeShowNameStatus(true, pcbId);
      this.props._selectChange({ pinsObj, comps, nets: [...nets] }, pcbId);
      this.props._changeDisplaySelected(true, pcbId)
    }
  }

  updateConnInterfaces = (connInterfaces) => {
    this.setState({
      connInterfaces
    })
  }

  expandSetup = (e, type) => {
    e.stopPropagation();
    this.setState({
      [type]: !this.state[type]
    })
  }

  changeSizeMax = (max) => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const { width, height } = offset;
    if (max) {
      this.setState({
        maxWidth: width - 10,
        maxHeight: height - 10,
        isMax: true
      })
    } else {
      this.resize()
      this.setState({
        isMax: false
      })
    }
  }

  showSearchLogs = (e, pcbId) => {
    e.stopPropagation();
    this.setState({
      showSearchLogId: pcbId
    })
  }

  closeSearchLogPanel = () => {
    this.setState({
      showSearchLogId: null
    })
  }

  render = () => {
    const { loading, confirmVisible, contentType, pcbSelectionList, applySetupAll, mergeAllSignal,
      pcbComponents, pcbInterfaceInfo, PCBList, pcbConfig, searchInterfaceMsgList, expandedPCBKeys,
      interfaceList, expandedInterfaceKey, connVisible, connInterfaces, updateConnStatus,
      maxWidth, maxHeight, showMergeName, selectedSignalKeys, mergeErrorMsgs, mergeVisible,
      PCBSetupExpanded, interfaceSetupExpanded, isMax, connMsg, searchLogs, showSearchLogId, selectedCombineInterface, groupName } = this.state;
    const showInterfaceCreate = contentType === SELECT_INTERFACE && (PCBList.length > 1 || interfaceList.length) && connInterfaces && connInterfaces.length;
    const msg = this.getMassage();
    const footerHeight = this.getFootHeight({ contentType, msg, PCBList });
    const contentMaxHeight = maxHeight - 44 - footerHeight;
    const { openProjectId, channelList, selectedDesignIDs, duplicationVerificationInfo, createVerificationInfo } = this.props;
    const content = (
      <Panel
        className='sierra-interface-create-panel aurora-create-panel sierra-panel'
        position={isMax ? "panel-top-left" : 'panel-center'}
        title={contentType === CREATE_INTERFACE ? 'Interface Identification' : 'Interface Creation'}
        zIndex={2000}
        onCancel={this.closeCreatePanel}
        width={contentType === CREATE_INTERFACE && !isMax ? 600 : maxWidth}
        draggable
        minHeight={contentType === CREATE_INTERFACE ? 400 : 500}
        maxHeight={maxHeight}
        footer={this.getContentFooter(msg)}
        footerHeight={footerHeight}
        overflow={"hidden"}
        maximizeVisible={true}
        dialogRoot={this.dialogRoot}
        changeSizeMax={this.changeSizeMax}
        minWidth={showInterfaceCreate ? 1118 : null}
      >
        <div className='sierra-interface-creation-main'
          style={{
            maxHeight: contentMaxHeight,
            height: isMax ? contentMaxHeight : null,
            /*  minWidth: showInterfaceCreate ? 1118 : null */
          }}>
          <Spin
            spinning={loading || (createVerificationInfo && createVerificationInfo.status) ? true : false}
            tip={loading && searchInterfaceMsgList ? searchInterfaceMsgList[searchInterfaceMsgList.length - 1] : ''}>
            {contentType === CREATE_INTERFACE ? <PCBSelection
              pcbSelectionList={pcbSelectionList}
              PCBList={PCBList || []}
              selectChange={(key) => this.pcbSelectChange(key || [])}
            /> : null}
            <div className={showInterfaceCreate ? "creation-interface-main" : "creation-interface-main-single-pcb"}>
              <div className='creation-interface-collapse-content'
                style={showInterfaceCreate ? {
                  width: PCBSetupExpanded ? (interfaceSetupExpanded ? "calc(50% - 10px)" : "calc(100% - 40px)") : 28,
                  minWidth: PCBSetupExpanded ? 520 : 28,
                  maxHeight: contentMaxHeight - 22
                } : { maxHeight: contentMaxHeight - 22 }}>
                {showInterfaceCreate ? <div
                  className={PCBSetupExpanded ? 'creation-interface-pcb-expand-main' : "creation-interface-pcb-expand-main creation-interface-pcb-not-expand-main"}
                  onClick={(e) => { this.expandSetup(e, "PCBSetupExpanded") }}
                  style={{ maxHeight: contentMaxHeight - 22 }}
                >
                  {
                    PCBSetupExpanded ? <CaretLeftOutlined className='creation-interface-pcb-expand-icon' /> : <CaretRightOutlined className='creation-interface-pcb-expand-icon' />
                  }
                </div> : null}
                {PCBSetupExpanded ? <Fragment>
                  <PCBSetup
                    pcbSelectionList={pcbSelectionList}
                    PCBList={PCBList || []}
                    pcbConfig={pcbConfig}
                    pcbLoading={this.pcbLoading}
                    pcbComponent={this.pcbComponent}
                    contentType={contentType}
                    pcbComponents={pcbComponents}
                    pcbInterfaceInfo={pcbInterfaceInfo}
                    applySetupAll={applySetupAll}
                    mergeAllSignal={mergeAllSignal.single}
                    showMergeName={showMergeName}
                    expandedPCBKeys={expandedPCBKeys}
                    contentMaxHeight={contentMaxHeight}
                    selectedDesignIDs={selectedDesignIDs}
                    searchLogs={searchLogs}
                    groupName={groupName}
                    pcbExpandChange={this.pcbExpandChange}
                    updatePCBConfig={this.updatePCBConfig}
                    updatePCBInterfaceInfo={this.updatePCBInterfaceInfo}
                    changeDefaultName={this.changeDefaultName}
                    mergeSignalsToInterfaceButton={this.mergeSignalsToInterfaceButton}
                    showNetsToLayout={this.showNetsToLayout}
                    showSearchLogs={this.showSearchLogs}
                    inputChange={this.inputChange}
                    checkValue={this.checkValue}
                  />
                  {this.getSelectSignalsOptions()}
                </Fragment> : null}
              </div>
              {showInterfaceCreate ?
                <div
                  className='interface-creation-content'
                  style={{
                    width: interfaceSetupExpanded ? (PCBSetupExpanded ? "calc(50% - 10px)" : "calc(100% - 40px)") : 28,
                    minWidth: interfaceSetupExpanded ? 382 : 28,
                    maxHeight: contentMaxHeight - 22
                  }}
                >
                  <div
                    className={interfaceSetupExpanded ? 'creation-interface-conn-expand-main' : "creation-interface-conn-expand-main creation-interface-conn-not-expand-main"}
                    onClick={(e) => { this.expandSetup(e, "interfaceSetupExpanded") }}
                  >
                    {
                      interfaceSetupExpanded ? <CaretRightOutlined className='creation-interface-conn-expand-icon' /> : <CaretLeftOutlined className='creation-interface-conn-expand-icon' />
                    }
                  </div>
                  {interfaceSetupExpanded ? <Fragment>
                    {connMsg ? <span className={connMsg.match("successfully") ? "aurora-success-msg" : 'aurora-model-name-error-msg'}>{connMsg}</span> : null}
                    <InterfaceCreation
                      PCBList={PCBList || []}
                      maxWidth={maxWidth}
                      expandedPCBKeys={expandedPCBKeys}
                      contentMaxHeight={contentMaxHeight}
                      pcbInterfaceInfo={pcbInterfaceInfo}
                      mergeAllSignal={mergeAllSignal.multi}
                      connInterfaces={connInterfaces}
                      interfaceList={interfaceList}
                      expandedInterfaceKey={expandedInterfaceKey}
                      updateConnStatus={updateConnStatus}
                      selectedSignalKeys={selectedSignalKeys}
                      selectedCombineInterface={selectedCombineInterface}
                      getCombineButtons={this.getCombineButtons}
                      updateConnectionStatus={this.updateConnectionStatus}
                      interfaceExpandChange={this.interfaceExpandChange}
                      updateSelectSignalKeys={this.updateSelectSignalKeys}
                      updateInterfaceList={this.updateInterfaceList}
                      channelList={channelList}
                      getInterfaceHeight={this.getInterfaceHeight}
                      updateConnInterfaces={this.updateConnInterfaces}
                      getConnectionsButton={this.getConnections}
                      selectCombineInterface={this.selectCombineInterface}
                      updatePCBSetupExpanded={this.updatePCBSetupExpanded}
                    />
                    {this.getSelectInterfaceOptions()}
                  </Fragment> : null}
                </div>
                : null}
            </div>
          </Spin>
        </div >
      </Panel>
    )
    return <Fragment>
      {createPortal(content, this.dialogRoot)}
      {confirmVisible ? <DelConfirm
        type="close"
        closePanel={this.closeCreatePanel}
        maskStyle={true}
        onFix={this.onFix}
        message={confirmErrorMsg}
      /> : null}
      {connVisible ?
        <Connections
          projectId={openProjectId}
          connSignals={this.connSignals}
          closePanel={this.updateConnectionsVisible} /> :
        null}
      {mergeErrorMsgs && mergeErrorMsgs.length ? <MsgDialog
        mask={false}
        type="error"
        allowButton={true}
        messageList={mergeErrorMsgs}
        className="sierra-panel"
        clickOkButton={this.clickDialogOkButton}
      /> : null}
      {mergeVisible ? <MergeSignalsPanel
        {...this.mergeSignalsInfo}
        closePanel={this.closeMergeSignals}
      /> : null}
      {showSearchLogId && searchLogs[showSearchLogId].logs && searchLogs[showSearchLogId].logs.length ?
        <MsgDialogPanel
          type="info"
          title="Identification Log"
          className="sierra-panel"
          allowDownload={true}
          showSearch={true}
          fileName={searchLogs[showSearchLogId].name ? `${searchLogs[showSearchLogId].name}_identification.log` : "identification.log"}
          messageList={searchLogs[showSearchLogId].logs}
          closePanel={this.closeSearchLogPanel}
          getSearchLog={getFilterIdentificationLog}
        />
        : null}
      {duplicationVerificationInfo && duplicationVerificationInfo.show && duplicationVerificationInfo.repeatVerifications && duplicationVerificationInfo.repeatVerifications.length
        ? <CreateConfirmPanel
          duplicationVerificationInfo={duplicationVerificationInfo}
          reCreate={this.props.reCreateDuplicationVerifications}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
        /> : null}
      {createVerificationInfo && createVerificationInfo.logs ?
        <MsgDialog
          mask={false}
          type="log"
          allowButton={false}
          messageList={createVerificationInfo.logs || []}
          className="aurora-msg-dialog-info-panel sierra-create-log-panel sierra-panel"
        />
        : null}
    </Fragment>
  }

}

const mapState = (state) => {
  const { SierraReducer: { project: { selectedKeys }, sierra: { createdLoading, duplicationVerificationInfo, createVerificationInfo } } } = state;

  return {
    selectedDesignIDs: getSelectedDesignIDs(selectedKeys),
    createdLoading,
    duplicationVerificationInfo,
    createVerificationInfo
  };
}

const mapDispatch = (dispatch) => ({
  _selectChange(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  _changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  _selectLayer(layers, designID) {
    dispatch(selectLayer(layers, designID))
  },
  _changeShowNameStatus(status, designID) {
    dispatch(changeShowNameStatus(status, designID))
  },
  updateCreatePanelLoading(loading) {
    dispatch(updateCreatePanelLoading(loading))
  },
  reCreateDuplicationVerifications({ selectKeys, cancel }) {
    dispatch(reCreateDuplicationVerifications({ selectKeys, cancel }))
  }
})

export default connect(mapState, mapDispatch)(CreateIdentificationContent);