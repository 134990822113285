import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Table from "@/components/EditableTable";
import { RightOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Spin } from 'antd';
import { changeMultiSetupPage, checkSimInterfaces, changeMultiSetupResult, getExtractionData } from '../store/multiInterface/action';
import BufferEditPanel from './bufferEditPanel';
import PassiveEditPanel from './passiveEditPanel';
import { getInterfaceTitleCheckBox, getInterfaceCheckBox } from '../../../services/Sierra/multiInterfaceSetup';
import MultiSetupOptionPanel from './multiSetupOptionPanel';
import MultiSetupExtractionOption from './multiSetupExtractionOption';
import { EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP, RESULT, VERIFICATION } from '../../../constants/treeConstants';
import { showResult, changeViewList } from '../store/project/action';
import { closeTabFooter } from '../../tabMonitor/action';
import designConstructor from '../../../services/helper/designConstructor';
import { PRE_LAYOUT } from '../../../constants/designVendor';
import './index.css';

const EXTRACTION_OPTIONS = 'Extraction Options',
  EXTRACTION_PORTS_SETUP = 'Extraction Ports';
const columns = [
  {
    title: "Group",
    dataIndex: "group",
    key: 'group',
    textWrap: 'word-break',
    ellipsis: true,
    width: 80
  },
  {
    title: "",
    dataIndex: "simulationCheck",
    key: 'simulationCheck',
    width: 60
  },
  {
    title: "Interface",
    dataIndex: "interfaceName",
    key: 'interfaceName',
    textWrap: 'word-break',
    ellipsis: true
  },
  {
    title: "Signal",
    dataIndex: "signal",
    key: 'signal',
    textWrap: 'word-break',
    ellipsis: true
  },
  {
    title: "PCB",
    dataIndex: "pcb",
    key: 'pcb',
    textWrap: 'word-break',
    ellipsis: true,
    render: (text) => (<span title={text}>{text}</span>)
  },
  {
    title: "Nets",
    dataIndex: "nets",
    key: 'nets',
    textWrap: 'word-break',
    ellipsis: true
  },
  {
    title: "Loads",
    dataIndex: "loads",
    key: 'loads',
    width: 60
  },
  {
    title: "Driver",
    dataIndex: "driver",
    key: 'driver'
  },
  {
    title: "Receiver",
    dataIndex: "receiver",
    key: 'receiver'
  },
  {
    title: "Passive",
    dataIndex: "passive",
    key: 'passive'
  },
  {
    title: "Setting",
    dataIndex: "setting",
    key: 'setting',
    width: 68
  },
  {
    title: "Result",
    dataIndex: "result",
    key: 'result',
    width: 60
  }
];

class MultiInterfaceSetupTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: {},
      extraction: {}
    };

    columns.forEach(column => {
      switch (column.dataIndex) {
        case "group":
          column.render = (text, record) => {
            return <span title={text}>{text}</span>
          }

          column.onCell = (record) => {
            return {
              edit: false,
              rowSpan: record.mergeGroupLength
            }
          }
          break;
        case "simulationCheck":
          column.title = () => {
            const { selectedInterfaces, verificationIds } = this.props;
            return getInterfaceTitleCheckBox({
              selectedInterfaces,
              verificationIds,
              checkInterfaces: this.checkInterfaces
            })
          }

          column.render = (name, record) => {
            return getInterfaceCheckBox({
              record,
              selectedInterfaces: this.props.selectedInterfaces,
              checkInterfaces: this.checkInterfaces
            })
          };

          column.onCell = (record) => {
            return {
              edit: false,
              rowSpan: record.mergeInterfaceLength
            }
          }
          break;
        case "interfaceName":
          column.render = (text, record) => {
            return <span title={text}>{text}</span>
          }

          column.onCell = (record) => {
            return {
              edit: false,
              rowSpan: record.mergeInterfaceLength
            }
          }
          break;
        case "signal":
          column.render = (text, record) => {
            return <span title={text}>{text}</span>
          }

          column.onCell = (record) => {
            return {
              edit: false,
              rowSpan: record.mergeSignalLength
            }
          }
          break;
        case "nets":
          column.render = (text, record) => {
            const _text = (record.nets || []).join(", ");
            return <span title={_text}>{_text}</span>;
          }
          break;
        case "driver":
          column.title = () => {
            return <div
              className="sierra-multi-setup-buffer-title"
              onClick={() => this.updateBufferPanel("Driver")}
            >Driver</div>;
          }

          column.render = (text, record) => {
            const _text = (record.driver || []).join(", ");
            return <span title={_text}>{_text}</span>;
          }

          column.onCell = (record) => {
            return {
              edit: true,
              record,
              customInput: BufferEditPanel,
              dataIndex: "driver",
              interfaceName: record.interfaceName,
              text: (record.driver || []).join(", "),
              usage: "Driver",
              verificationId: record.verificationId
            }
          }
          break;
        case "receiver":
          column.title = () => {
            return <div
              className="sierra-multi-setup-buffer-title"
              onClick={() => this.updateBufferPanel("Receiver")}
            >Receiver</div>;
          }

          column.render = (text, record) => {
            const _text = (record.receiver || []).join(", ");
            return <span title={_text}>{_text}</span>;
          }

          column.onCell = (record) => {
            return {
              edit: true,
              record,
              customInput: BufferEditPanel,
              interfaceName: record.interfaceName,
              dataIndex: "receiver",
              usage: "Receiver",
              text: (record.receiver || []).join(", "),
              verificationId: record.verificationId
            }
          }
          break;
        case "passive":
          // column.title = () => {
          //   return <div
          //     className="sierra-multi-setup-buffer-title"
          //     onClick={() => this.updateBufferPanel("Passive")}
          //   >Passive</div>;
          // }
          column.render = (text, record) => {
            const _text = (record.passive || []).join(", ");
            return <span title={_text}>{_text}</span>;
          }

          column.onCell = (record) => {
            return {
              edit: true,
              record,
              customInput: PassiveEditPanel,
              interfaceName: record.interfaceName,
              dataIndex: "passive",
              usage: "Passive",
              text: (record.passive || []).join(", "),
              verificationId: record.verificationId
            }
          }
          break;
        case "setting":
          column.render = (text, record) => {
            const { visible } = this.state;
            let opened = false;
            if (visible.sim === record.verificationId || visible.extraction === record.interfaceId || visible.ports === record.interfaceId) {
              opened = true;
            }
            return (
              <div className='interface-table-setting-content'>
                <Dropdown menu={{ items: this.getSettingMenu(record) }} trigger={['click']}>
                  <SettingOutlined
                    className={opened ? "multi-setup-setting-selected-icon multi-setup-setting-icon" : "multi-setup-setting-icon"} />
                </Dropdown>
              </div>
            );
          }
          column.onCell = (record) => {
            return {
              edit: false,
              rowSpan: record.mergeInterfaceLength
            }
          }
          break;
        case "result":
          column.render = (text, record) => {
            return <RightOutlined
              className="sierra-multi_setup-result-icon"
              title="Open Result"
              onClick={(e) => this.openResult(e, record)} />
          }

          column.onCell = (record) => {
            return {
              edit: false,
              rowSpan: record.mergeInterfaceLength,
              tdClassName: "sierra-multi-interface-result"
            }
          }
          break;
        default: break;
      }
    });
  }

  getSettingMenu = (record) => {
    const vendorList = record.pcbIds.map(item => designConstructor.getDesignVendor(item));
    let menu = [{ label: "Simulation Options", key: "sim" },
    { label: EXTRACTION_OPTIONS, key: "extraction" },
    { label: EXTRACTION_PORTS_SETUP, key: "ports" }];
    if (vendorList && vendorList.every(item => item === PRE_LAYOUT)) {
      menu = menu.filter(item => item.key !== "ports")
    }
    return menu.map(item => ({ ...item, onClick: () => this.openSettingPanel(item.key, item.label, record) }))
  }

  openResult = (e, record) => {
    e && e.stopPropagation();
    const { verificationId } = record;
    const { viewList, layout, currentProjectVerifications, multiSetupGroup } = this.props;
    this.props._changeMultiSetupResult(multiSetupGroup)
    const verificationName = currentProjectVerifications.find(item => item.id === verificationId).name;

    this.props._showResult('result', { verificationId, verificationName, getStatus: true, isMultiSetup: true });
    let list = [...viewList, RESULT];
    list = [...new Set(list)];
    list = list.filter(item => ![VERIFICATION, EXPERIMENTS, EXPERIMENTS_RESULT, MULTI_INTERFACE_SETUP].includes(item));
    if (layout === 3) {
      list = [RESULT]
    }
    this.props._changeViewList(list);
    const { closeTabFooter, tabVisible } = this.props;
    if (tabVisible) {
      closeTabFooter();
    }
  }

  openSettingPanel = (type, title, record) => {
    let extraction = this.state.extraction;
    let _type = type;
    if (type !== "sim") {
      const { pageData } = this.props;
      const filterVerList = pageData.filter(item => item.verificationId === record.verificationId);
      let pcbs = [];
      let pcbList = filterVerList.map(item => {
        if (!pcbs.includes(item.pcbId)) {
          pcbs.push(item.pcbId);
          const vendor = designConstructor.getDesignVendor(item.pcbId)
          return {
            pcbId: item.pcbId,
            pcb: item.pcb,
            interfaceId: item.interfaceId,
            vendor,
            verificationId: item.verificationId,
            disable: vendor === PRE_LAYOUT && type === "ports"
          }
        } else {
          return null;
        }
      }).filter(item => !!item);

      let pcb = record.pcb, interfaceId = record.interfaceId, pcbId = record.pcbId;
      if (designConstructor.getDesignVendor(pcbId) === PRE_LAYOUT) {
        const findPcb = pcbList.find(item => item.vendor !== PRE_LAYOUT) || pcbList[0] || {};
        pcb = findPcb.pcb;
        interfaceId = findPcb.interfaceId;
        pcbId = findPcb.pcbId;
      }

      extraction = {
        verificationId: record.verificationId,
        interfaceId,
        pcb,
        extractionType: title,
        pcbId,
        extrType: type,
        verificationInfo: {
          verificationId: record.verificationId,
          pcbs: [...new Set(filterVerList.map(item => item.pcb))],
          list: pcbList,
          interfaceName: record.interfaceName
        }
      }
      this.props._getExtractionData(extraction)
      _type = "extraction"
    }
    this.setState({
      visible: {
        ...(this.state.visible || {}),
        [_type]: type === "sim" ? record.verificationId : record.interfaceId
      },
      extraction
    })
  }

  closeSettingPanel = (type) => {
    let _type = type !== "sim" ? "extraction" : "sim";
    this.setState({
      visible: {
        ...(this.state.visible || {}),
        [_type]: false
      },
      extraction: type !== "sim" ? {} : this.state.extraction
    })
  }

  checkInterfaces = (e, key) => {
    const checked = e && e.target ? e.target.checked : false;
    this.props._checkedInterfaces(checked, key);
  }

  onPageChange = (page) => {
    this.props._changeMultiSetupPage(page)
  }

  updateBufferPanel = (bufferVisible) => {
    this.setState({
      bufferVisible
    })
  }

  render() {
    const { loading, pageData, page, pageSize, allDataLength } = this.props;
    const { bufferVisible, visible, extraction } = this.state;
    const _columns = pageData.find(item => !!item.group) ? columns : columns.filter(item => item.dataIndex !== "group");
    return (
      <Fragment>
        <Spin spinning={loading} tip={'Loading...'}>
          <div className='sierra-multi-setup-content'>
            <Table
              columns={_columns}
              dataSource={JSON.parse(JSON.stringify(pageData || []))}
              className="space-10 sierra-multi-setup-table"
              rowKey={(record) => `${record.verificationId}_${record.signal}_${record.pcbId}`}
              size="small"
              pagination={{
                position: "top",
                pageSize,
                current: page,
                hideOnSinglePage: true,
                onChange: this.onPageChange,
                total: allDataLength
              }}
            />
          </div>
        </Spin>
        {bufferVisible ?
          <BufferEditPanel
            closePanel={() => this.updateBufferPanel(false)}
          /> : null}
        {visible && visible.sim ?
          <MultiSetupOptionPanel
            verificationId={visible.sim}
            closePanel={() => this.closeSettingPanel("sim")}
          /> : null}
        {visible && visible.extraction ?
          <MultiSetupExtractionOption
            verificationId={extraction.verificationId}
            interfaceId={extraction.interfaceId}
            extractionType={extraction.extractionType}
            pcb={extraction.pcb}
            pcbId={extraction.pcbId}
            extrType={extraction.extrType}
            verificationInfo={extraction.verificationInfo}
            closePanel={() => this.closeSettingPanel(extraction.extrType)}
          /> : null}
      </Fragment>
    )
  }
}


const mapState = (state) => {
  const { SierraReducer: {
    multiInterfaceSetup: {
      setupData,
      loading,
      multiSetupGroup
    },
    project: { viewList, layout, currentProjectVerifications },
  },
    TabMonitorReducer: { tabVisible } } = state;
  const { pageData, page, pageSize, allDataLength, selectedInterfaces, verificationIds } = setupData || {};
  return {
    loading,
    pageData,
    page,
    pageSize,
    allDataLength,
    selectedInterfaces,
    verificationIds,
    multiSetupGroup,
    viewList,
    layout,
    currentProjectVerifications,
    tabVisible
  }
};

const mapDispatch = (dispatch) => ({
  _changeMultiSetupPage(page) {
    dispatch(changeMultiSetupPage(page))
  },
  _checkedInterfaces(checked, key) {
    dispatch(checkSimInterfaces(checked, key))
  },
  _getExtractionData(info) {
    dispatch(getExtractionData(info))
  },
  _showResult(view, info) {
    dispatch(showResult(view, info));
  },
  _changeViewList(viewList) {
    dispatch(changeViewList(viewList));
  },
  closeTabFooter() {
    dispatch(closeTabFooter());
  },
  _changeMultiSetupResult(multiSetupGroup) {
    dispatch(changeMultiSetupResult(multiSetupGroup))
  }
});

export default connect(mapState, mapDispatch)(MultiInterfaceSetupTable);