import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { LinkOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import './style.css';
class CCCMsg extends Component {

  constructor(props) {
    super(props);
    this.state = {
      topWidth: 800
    }
    this.scrollRef = createRef();
  }

  scrollToBottom = () => {
    if (this.scrollRef && this.scrollRef.current) {
      this.scrollRef.scrollIntoView({ behavior: "auto" });
    }
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidMount() {
    this.screenChange();
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
    this.footerRef = document.getElementsByClassName('tab-footer')[0];

    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      this.setState({
        topWidth: width
      });
    }
  }

  componentDidUpdate() {
    const { tabVisible } = this.props;
    tabVisible && this.scrollToBottom();
    const { topWidth } = this.state;
    this.footerRef = document.getElementsByClassName('tab-footer')[0];
    if (this.footerRef) {
      let width = this.footerRef.offsetWidth;
      if (width !== topWidth) {
        this.setState({
          topWidth: width
        });
      }
    }
  }

  render() {
    const { queueLog } = this.props;

    return (
      <Fragment>
        <div className='ccc-simulation-title'>
          {/* ccc backend log debug button */}
          <div className='ccc-debug-button'>
            <Tooltip title='Open CCC in new window.' overlayClassName='icon-tooltip'>
              <Link to='/ccc'><LinkOutlined style={{ fontSize: 19 }} /></Link>
            </Tooltip>
          </div>
        </div>
        <div className='my-ccc-monitor'>
          <pre className='ccc_monitor'>
            {queueLog}
          </pre>
          <div ref={(el) => { this.scrollRef = el; }}></div>
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  const { queueLog } = state.CCCReducer;

  return {
    queueLog
  }
}

export default connect(mapState, null)(CCCMsg);