// import StringHelper from '../utility/StringHelper';
/**
 * class CeBlockIO
 */
var CeBlockIO = function () { };

// public boolean ReadBlockFromFile(String filePath, CeIODataBlock ceBlock) throws IOException
CeBlockIO.ReadBlockFromFile = function (strFileContent, ceDataBlock) {

  // user regular expression to split the lines
  // this takes care of the windows new line format
  // use regular expression to split the strFileContent
  var lineBuffer = strFileContent.match(/[^\r\n]+/g);

  var counter = {
    indLine: 0,
    lenBuffer: lineBuffer.length
  };

  // read from the buffer
  while (counter.indLine < counter.lenBuffer) {
    var line = lineBuffer[counter.indLine].trim();
    counter.indLine++;

    if (line.length == 0 || CeBlockIO.isCommentsLine(line))
      continue;
    // search for a match in the block name
    var blockName = CeBlockIO.findBlockStart(line);
    if (blockName === ceDataBlock.GetName()) {
      // read the block using the virtual function
      ceDataBlock.loadBlockFromBuffer(lineBuffer, counter);
    }
  }
  return ceDataBlock.isEmpty() == false;
}; // readBlockFromFile

// public boolean ReadBlockFromBytes(byte[] array, CeIODataBlock ceBlock) throws IOException
// TODO

// static boolean IsCommentsLine(String line)
CeBlockIO.isCommentsLine = function (strLine) {
  return strLine.substr(1, 1) == "#";
};

// static String FindBlockStart(String line)
CeBlockIO.findBlockStart = function (strLine, old) {
  // block start line format:
  // 	   <block_name> {
  var length = strLine.length;
  if (strLine[length - 1] === '{') {
    return strLine[0] === "\"" ? strLine.substring(1, length - 3) : strLine.substring(0, length - 2);
  } else {
    return null;
  }
};

// public boolean WriteBlockToFile(String filePath, CeIODataBlock ceBlock)
CeBlockIO.WriteBlockToString = function (ceIODataBlock) {
  try {
    return ceIODataBlock.WriteBlockToBuffer("", 0);
  } catch (err) {
    console.log(err);
    return null;
  }
};

// public boolean WriteBlockToFile(String filePath, CeIODataBlock ceBlock)
// TODO

// public void WriteBlockToBytes(ByteArrayOutputStream out, CeIODataBlock ceBlock) {
// TODO

export default CeBlockIO;