import { ImpComponent, CompareMapRow, ImpSetup, ImpLayout, ImpExtraction } from './impedanceClass';
import { SortFn } from '../../helper/sort';
import { checkRLCValueFormat, numberCheck } from '../../helper/dataProcess';
import { getImpedanceResultRelated, getHistoryImpedanceRelated, getImpedanceOptNpi } from './impedanceCtrl';
import { getAllCascadeComponents, getComponentColor, getPMIC, getPowerDomain } from '../helper/setupData';
import { getNetPinList } from '../../helper/componentsHelper/componentData';
import { CAP, CHIP, DIODE, FERRITE, IGNORE, IND, JUMPER, REMOVED, RES, SWITCH, TRANSISTOR } from '../../PCBHelper';
import { HISTORY, IMPORT, OPTIMIZATION, SWEEPMODEL } from '../../../components/Sparameter/resultList';
import { getArraySimilar, splitArrayToArrays } from '../../helper/arrayHelper';
import { BGA, DIE, IC, IPD, PMIC } from '../../../constants/componentType';
import { MODEL_MATCH_WEIGHTS, TARGET_PACKAGE_INDEX, TARGET_PCB_INDEX } from '../constants';
import { IMPEDANCE_DIE, IMPEDANCE_PACKAGE } from '../constants';
import designConstructor from '../../helper/designConstructor';
import preLayoutData from '../prelayout/preLayoutData';
import { DECAP_RLC, DECAP_SPICE, DECAP_TOUCHSTONE, GENERIC_LIBRARY } from '../../../constants/libraryConstants';
import auroraDBJson from '../../Designs/auroraDbData';
import { getNearbyComponent } from '../../Designs/helper';
import { getPortData, autoSplitRefeByPower } from '@/services/helper/portCanvasHelper';
import LayoutData from '@/services/data/LayoutData';
import Decimal from 'decimal.js'
import { unitChange } from '../../helper/mathHelper';

const sortList = [CHIP, BGA, DIE, CAP, IPD, RES, JUMPER, FERRITE, SWITCH, IND, DIODE, REMOVED, TRANSISTOR, IGNORE];
function generateCompData(data = [], targetIC, designId) {
  let compData = {}, tabs = [], index = 1;
  for (let row of data) {
    const { id, content } = row;
    const { MAIN_POWER_NETS = [], Components = [] } = content;
    let comps = [];
    for (let comp of Components) {
      if (!comp) {
        continue;
      }
      const { name, part, pins, usage, COMP_TYPE } = comp;
      const partNumber = auroraDBJson.getPartNumberByPartName(designId, part);
      if (targetIC === name) {
        comps.push(new ImpComponent({ comp: { ...comp, COMP_TYPE: CHIP, partNumber }, PowerNets: MAIN_POWER_NETS, id: "" }))
        continue;
      }
      const findIndex = comps.findIndex(item => item.part === part && (usage === 'Unused' ? item.COMP_TYPE === COMP_TYPE && item.usage !== REMOVED : item.usage === usage));
      if (findIndex > -1) {
        comps[findIndex].components.push({ name, pins, usage })
      } else {
        comps.push(new ImpComponent({ comp: { ...comp, partNumber }, PowerNets: MAIN_POWER_NETS, id }))
      }
    }
    comps = SortFn(comps, sortList, 'usage');
    compData[MAIN_POWER_NETS.join(', ')] = comps;
    tabs.push(MAIN_POWER_NETS && MAIN_POWER_NETS.length ? MAIN_POWER_NETS.join(', ') : `PowerDomain ${index}`)
    index = index + 1;
  }
  return { compData, tabs }
}

function generateCompTabs(data = []) {
  let tabs = [], index = 1;
  for (let row of data) {
    const { content } = row;
    const { MAIN_POWER_NETS } = content;
    tabs.push(MAIN_POWER_NETS && MAIN_POWER_NETS.length ? MAIN_POWER_NETS.join(', ') : `PowerDomain ${index}`)
    index = index + 1;
  }
  return { tabs }
}

function generateCompDataByTabs(data = [], targetIC, designId, tab, currentDesignId, connectors) {
  const findData = data.find(item => item.content && item.content.MAIN_POWER_NETS && item.content.MAIN_POWER_NETS.join(', ') === tab);
  if (!findData) {
    return [];
  }

  const { id, content } = findData;
  const { MAIN_POWER_NETS, Components = [] } = content;
  let comps = [];
  let chips = []
  if (designId === currentDesignId) {
    chips.push(targetIC)
  } else {
    const connectorRule = (item) => { return item.from.length && item.from.every(f => f.designId !== currentDesignId) }
    const { trueArray: fromConnectors, falseArray: toConnectors } = splitArrayToArrays({ array: connectors.filter(item => item.from.length || item.to.length), rule: connectorRule });
    for (let connector of fromConnectors) {
      const { name } = connector;
      chips.push(name);
    }
  }
  for (let comp of Components) {
    if (!comp) {
      continue;
    }
    const { name, part, pins, usage, COMP_TYPE } = comp;
    const partNumber = auroraDBJson.getPartNumberByPartName(designId, part);
    if (chips.includes(name)) {
      comps.push(new ImpComponent({ comp: { ...comp, COMP_TYPE: CHIP, partNumber }, PowerNets: MAIN_POWER_NETS, id: "" }))
      continue;
    }
    const findIndex = comps.findIndex(item => item.part === part && (usage === 'Unused' ? item.COMP_TYPE === COMP_TYPE && item.usage !== REMOVED : item.usage === usage));
    if (findIndex > -1) {
      comps[findIndex].components.push({ name, pins, usage })
    } else {
      comps.push(new ImpComponent({ comp: { ...comp, partNumber }, PowerNets: MAIN_POWER_NETS, id }))
    }
  }
  comps = SortFn(comps, sortList, 'usage');
  return comps
}

function generateCompBasedDataByTabs(data = [], targetIC, designId, tab, currentDesignId, connectors) {
  const findData = data.find(item => item.content && item.content.MAIN_POWER_NETS && item.content.MAIN_POWER_NETS.join(', ') === tab);
  if (!findData) {
    return [];
  }

  const { id, content } = findData;
  const { MAIN_POWER_NETS, Components = [] } = content;
  let comps = [];
  let chips = []
  if (designId === currentDesignId) {
    chips.push(targetIC)
  } else {
    const connectorRule = (item) => { return item.from.length && item.from.every(f => f.designId !== currentDesignId) }
    const { trueArray: fromConnectors, falseArray: toConnectors } = splitArrayToArrays({ array: connectors.filter(item => item.from.length || item.to.length), rule: connectorRule });
    for (let connector of fromConnectors) {
      const { name } = connector;
      chips.push(name);
    }
  }
  for (let comp of Components) {
    if (!comp) {
      continue;
    }
    const { name, part, pins, usage, COMP_TYPE } = comp;
    const partNumber = auroraDBJson.getPartNumberByPartName(designId, part);
    if (chips.includes(name)) {
      comps.push(new ImpComponent({ comp: { ...comp, COMP_TYPE: CHIP, partNumber }, PowerNets: MAIN_POWER_NETS, id: "" }))
      continue;
    }
    comps.push(new ImpComponent({ comp: { ...comp, partNumber }, PowerNets: MAIN_POWER_NETS, id }))
  }
  comps = SortFn(comps, sortList, 'usage');
  return comps
}

function getPowerPinList(type, COMP_PREFIX_LIB = {}, record, Components = []) {
  if (!record || !COMP_PREFIX_LIB) {
    return [];
  }
  const { PowerNets = [], ReferenceNets = [], VRM } = record;
  let nets = type === 'powerPin' ? PowerNets : ReferenceNets;
  const { powerSwitch = [] } = COMP_PREFIX_LIB;
  let partName = [...getPMIC(COMP_PREFIX_LIB).map(item => item.partName), ...powerSwitch];
  const vrmComp = VRM.map(item => item.VRM_COMP).flat(2);

  // Filter components connect to power or ground
  return Components.filter(comp => {
    if (!comp) return false;
    if (partName.includes(comp.part)) {
      return comp;
    }
    const _nets = comp.pins.map(item => item.net);
    for (const net of _nets) {
      // Only Cap will have Removed usage
      if (comp.usage !== REMOVED && nets.includes(net) && [RES, JUMPER, IND, FERRITE].includes(comp.COMP_TYPE)) {
        return comp;
      } else if (type === 'powerPin' && nets.includes(net) && (comp.usage === SWITCH || vrmComp.includes(comp.name))) {
        return comp;
      }
    };
    return false;
  });
}

function mergeVRMInfo(newInfo, oldInfo) {
  let model = { name: "", id: "" }, partModel = [];
  for (let vrm of oldInfo.VRM) {
    if (vrm.model && vrm.model.id) {
      model = { ...vrm.model }
      break;
    }
  }

  for (let component of (oldInfo.Components || [])) {
    if (component.usage !== IGNORE && !partModel.find(item => component && item.part === component.part)) {
      if (component.usage === CAP) {
        partModel.push({ part: component.part, models: component.models, value: component.value })
      } else {
        partModel.push({ part: component.part, model: component.model, value: component.value })
      }
    }
  }

  let _Info = { ...newInfo }
  _Info.VRM = _Info.VRM.map(item => ({ ...item, model }));
  _Info.Components = _Info.Components.map(comp => {
    const data = partModel.find(item => item.part === comp.part);
    return data ? { ...comp, ...data } : { ...comp }
  })
  _Info.target = oldInfo.target ? oldInfo.target : []
  _Info.ports = oldInfo.ports ? oldInfo.ports : []
  _Info.voltage = oldInfo.voltage || "1"
  return _Info;
}

function ImpedanceErrorCheck(data, DecapList) {
  let error = [], index = 0;
  for (let layout of data) {
    const pcbId = layout.designId
    const pcbName = layout.designName
    const type = layout.type
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (type === IMPEDANCE_PACKAGE) {
      for (let row of (layout.powerDomains || [])) {
        let _errors = [];
        index = index + 1;
        const { content } = row;
        const { PowerNets, ReferenceNets } = content;
        const RowName = PowerNets.length ? PowerNets[0] : `Row ${index}`;

        // Power net check
        if (PowerNets.length < 1) {
          _errors.push(`[${pcbName}][Power Domain][${RowName}] No power nets set.`)
        }

        // Reference net check
        if (ReferenceNets.length < 1) {
          _errors.push(`[${pcbName}][Power Domain][${RowName}] No reference nets set.`)
        }

        if (_errors.length) {
          error.push(..._errors);
          row.readyForSim = 0;
        } else {
          row.readyForSim = 1;
        }
      }
    } else if (type === IMPEDANCE_DIE) {
      if (layout.ICs.length) {
        const die = layout.ICs[0];
        let _errors = [];
        if (!die) {
          _errors.push(`[${pcbName}]Can not find DIE.`)
        }

        const model = die.model;
        if (!model.type) {
          _errors.push(`[${pcbName}]Not set On-Die model, please click Die component to set.`)
        }
        //  else if (model.type === 'Touchstone' || model.type === 'SPICE') {
        //   const { pairs } = model;
        //   const pkg = data.find(item => item.type === IMPEDANCE_PACKAGE);
        //   if (pkg) {
        //     const powerNets = pkg.powerDomains.map(item => item.content.PowerNets).flat(2);
        //     const gndNets = pkg.powerDomains.map(item => item.content.ReferenceNets).flat(2);
        //     const pins = [...new Set(powerNets), ...new Set(gndNets)];
        //     for (let pin of pins) {
        //       const find = pairs.find(item => item.pin === pin);
        //       if (!find || !find.node) {
        //         _errors.push(`[${pcbName}][On-Die]Not set node of ${pin}, please click Die component to set.`)
        //       }
        //     }
        //   }

        //   if (_errors.length) {
        //     error.push(..._errors);
        //   }
        // }
      }
    } else {
      for (let row of (layout.powerDomains || [])) {
        let _errors = [];
        index = index + 1;
        const { content } = row;
        const { PowerNets = [], ReferenceNets = [], VRM = [], Components = [], MAIN_POWER_NETS = [], ports = [] } = content;
        const RowName = MAIN_POWER_NETS.length ? MAIN_POWER_NETS.join(', ') : `Row ${index}`;
        // Power net check
        if (PowerNets.length < 1) {
          _errors.push(`[${pcbName}][Power Domain][${RowName}] No power nets set.`)
        }

        // Reference net check
        if (ReferenceNets.length < 1) {
          _errors.push(`[${pcbName}][Power Domain][${RowName}] No reference nets set.`)
        }

        // Vrm and vrm model check
        if (VRM.length < 1) {
          _errors.push(`[${pcbName}][Power Domain][${RowName}] VRM is not set.`)
        }
        let vrmIndex = 0;
        for (let item of VRM) {
          vrmIndex = vrmIndex + 1;
          const { powerPin = [], groundPin = [] } = item;
          let vrmName = powerPin.length ? powerPin.map(p => p.comp).join(', ') : `Row${vrmIndex}`

          if (powerPin.length < 1 || powerPin.find(it => !it.comp)) {
            vrmName = powerPin.length ? `Row${vrmIndex}` : vrmName;
            _errors.push(`[${pcbName}][Power Domain][${RowName}][VRM][${vrmName}] No equivalent power pin is set.`);
          }

          if (groundPin.length < 1 || groundPin.find(it => !it.comp)) {
            vrmName = groundPin.length ? `Row${vrmIndex}` : vrmName;
            _errors.push(`[${pcbName}][Power Domain][${RowName}][VRM][${vrmName}] No equivalent ground pin is set.`);
          }

          //Find the pins of the component of the Eq.Pwr Pin connected to the Power nets
          if (ReferenceNets.length && pcbId && !isPreLayout) {
            for (let groupPin of groundPin) {
              if (!groupPin.comp) {
                continue;
              }
              const netsInfo = auroraDBJson.getNetsByNames(pcbId, ReferenceNets);
              const netPinList = netsInfo.map(net => net.pins.get(groundPin.comp)).flat();
              if (!netPinList.length) {
                _errors.push(`[${pcbName}][Power Domain][${RowName}][VRM][${vrmName}] No equivalent ground pin [${groupPin.comp}] not connected to any reference nets.`);
              }
            }
          }
        }

        for (let group of ports) {
          const { port, powerPins = [], referencePins = [] } = group;
          if (!powerPins.length) {
            _errors.push(`[${pcbName}][Power Domain][${RowName}][Port] Port ${port} power pins are empty.`);
          }
          if (!referencePins.length) {
            _errors.push(`[${pcbName}][Power Domain][${RowName}][Port] Port ${port} reference pins are empty.`);
          }
        }

        // Component check
        let errorList = []
        for (let comp of Components) {
          if (comp.usage === 'Cap') {
            if (errorList.includes(`${MAIN_POWER_NETS}-${comp.part}`)) {
              continue;
            }
            if ((!comp.models || !comp.models.length) && (!comp.value || (comp.value && !comp.value.r && !comp.value.l && !comp.value.c))) {
              _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} ${comp.name} - Decap model is not set.`);
              errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
            } else {
              for (let model of comp.models) {
                if ((!model || (model && !model.name))) {
                  _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} ${comp.name} ${model.editName} - Decap model is not set.`);
                  errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                } else if (model && model.name && model.id) {
                  if (DecapList && [DECAP_SPICE, DECAP_TOUCHSTONE, DECAP_RLC].includes(model.libraryType)) {
                    const library = DecapList.find(item => item.id === model.id);
                    if (!library) {
                      _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} ${model.editName} - Current model has been deleted from Library, please set again.`);
                      errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                    }
                  }
                  if ((model.libraryType === DECAP_SPICE && !model.subcktName && !(model.type === DECAP_TOUCHSTONE))
                    || (model.libraryType === GENERIC_LIBRARY && !model.subcktName)) {
                    _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} ${model.editName} - Subckt is not set.`);
                    errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                  }
                } else if (comp.value && (!model || (model && !model.name))) {
                  let rValue = comp.value.r;
                  //Check the Resistance value format
                  let rCheck = checkRLCValueFormat(rValue)

                  if (!rCheck) {
                    _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} - Resistance value format error.`);
                    errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                  }

                  //Check the Inductance value format
                  let lValue = comp.value.l;
                  let lCheck = checkRLCValueFormat(lValue)

                  if (!lCheck) {
                    _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} - Inductance value format error.`);
                    errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                  }

                  //Check the Capacitance value format
                  let cValue = comp.value.c;
                  let cCheck = checkRLCValueFormat(cValue, "Cap");

                  if (!cCheck) {
                    _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} - Capacitance value format error.`);
                    errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                  } else if (cCheck && cCheck === "0") {
                    _errors.push(`[${pcbName}][Components][${MAIN_POWER_NETS}] ${comp.part} - Capacitance value cannot be 0.`);
                    errorList.push(`${MAIN_POWER_NETS}-${comp.part}`)
                  }
                }
              }
            }
          }
          errorList = [...new Set(errorList)];
        }
        if (_errors.length) {
          error.push(..._errors);
          row.readyForSim = 0;
        } else {
          row.readyForSim = 1;
        }
      }
    }
    if (!layout.extraction) {
      layout.extraction = new ImpExtraction()
    }
  }

  return { error, data }
}

function ImpedancePackageErrorCheck(data) {
  let error = [], index = 0;
  for (let layout of data) {
    for (let row of (layout.powerDomains || [])) {
      let _errors = [];
      index = index + 1;
      const { content } = row;
      const { PowerNets, ReferenceNets } = content;
      const RowName = PowerNets.length ? PowerNets[0] : `Row ${index}`;

      // Power net check
      if (PowerNets.length < 1) {
        _errors.push(`[Power Domain][${RowName}] No power nets set.`)
      }

      // Reference net check
      if (ReferenceNets.length < 1) {
        _errors.push(`[Power Domain][${RowName}] No reference nets set.`)
      }

      if (_errors.length) {
        error.push(..._errors);
        row.readyForSim = 0;
      } else {
        row.readyForSim = 1;
      }
    }
  }
  return { error, data }
}

function ImpedanceWarningCheck(data) {
  let warning = [], index = 0;
  for (let layout of data) {
    for (let row of (layout.powerDomains || [])) {
      index = index + 1;
      const { content } = row;
      const { MAIN_POWER_NETS, Components = [] } = content;
      const RowName = MAIN_POWER_NETS.length ? MAIN_POWER_NETS.join(', ') : `Row ${index}`;
      let warningComp = [];
      for (let component of Components) {
        if (warningComp.find(part => part === component.part) || component.COMP_TYPE !== CAP || component.usage === REMOVED) {
          continue;
        }
        if (component.model && component.model.matchWeights) {
          if (component.model.matchWeights <= MODEL_MATCH_WEIGHTS) {
            warningComp.push(component.part)
          }
        }
      }
      warning.push(...warningComp.map(part => `[Warning][${RowName}][${part}] The matching similarity between the currently automatically selected Model and Part Number is low.`))
    }
  }

  return warning
}

async function npiFileParseCascade(data, layouts, verificationId, fileName, targetFile, type, portList) {
  let lineBuffer = data.match(/[^\r\n]+/g);
  let portNum = Number(lineBuffer[0].slice(5));
  let hashId = [];
  let ports = [],
    frequency = [],
    endLine = portNum + 1,
    targetFrequency = {};
  let powerDisc = {};
  let isOptResultExist = false
  const powerDomains = layouts && layouts.length && layouts[0].powerDomains && layouts[0].powerDomains.length ? layouts[0].powerDomains : []
  try {
    switch (type) {
      case HISTORY:
        powerDisc = await getHistoryImpedanceRelated(verificationId);
        break;
      case IMPORT:
        powerDisc = {};
        break;
      default:
        powerDisc = await getImpedanceResultRelated(verificationId);
        const res = await getImpedanceOptNpi(verificationId)
        isOptResultExist = res ? true : false
        break;
    }
  } catch (e) {
    console.error(e)
  }

  let newTargetFiles = targetFile ? JSON.parse(JSON.stringify(targetFile)) : [];
  for (let i = 1; i < endLine; i++) {
    if (!lineBuffer[i]) continue;
    let words = lineBuffer[i].split(' ');//"1", "1"
    //words => ['2', '0.1', 'power1_U9_G12::VDDA_0P8_DP']
    //['2', '0.1', 'power1_VRM::VDDA_0P8_DP']
    const [compInfo, powerNetName] = words[2].split("::");//['power1_U9_G12', 'VDDA_0P8_DP']
    let [power, comp, ...pin] = compInfo.split('_'); // ['power1, 'U9', 'G12']
    const _power = powerDisc[power] ? powerDisc[power] : power;
    let name = `${_power}`;
    const powerPin = pin && pin.length ? pin.join('_') : "";
    const isVRM = comp && comp === "VRM" && !pin.length ? true : false;
    if (isVRM) {
      pin = ["VRM"];
    }
    const findPort = portList.find(it => powerPin && it.powerPins && it.powerPins.includes(powerPin));
    let portName = "";
    if (findPort) {
      portName = findPort.portName || "";
    }
    //Generate 10-digit random numbers containing numbers and letters
    let str = Math.random().toString(36).slice(2, 12);
    //Determine if it already exists
    while (hashId.includes(str)) {
      str = Math.random().toString(36).slice(2, 12);
    };
    hashId.push(str);
    ports.push({
      index: ports.length + 1,
      wordIndex: Number(words[0]),
      impedance: Number(words[1]),
      name: name,
      pin: comp === 'sense' ? ['sense'] : (pin || []),
      power: _power,
      portType: comp === 'sense' ? 'sense' : 'simulation',
      comp: name,
      signal: name,
      compType: '',
      display: true,
      positivePin: '',
      negativePin: '',
      fileName,
      hashId: str,
      powerPin,
      portName,
      powerNetName,
      compName: comp
    })

    const powerDomain = powerDomains.find(domain => domain.content.MAIN_POWER_NETS[0] === name)
    if (type === 'simulation' && powerDomain) {
      const sweepComp = powerDomain.content.Components.find(comp => comp.applySweep)
      if (sweepComp && sweepComp.models && sweepComp.models.length) {
        sweepComp.models.forEach((model) => {
          let sweepStr = Math.random().toString(36).slice(2, 12);
          while (hashId.includes(sweepStr)) {
            sweepStr = Math.random().toString(36).slice(2, 12);
          };
          hashId.push(sweepStr)
          ports.push({
            index: ports.length + 1,
            wordIndex: Number(words[0]),
            impedance: Number(words[1]),
            name: name,
            pin: comp === 'sense' ? ['sense'] : (pin || []),
            power: _power,
            portType: SWEEPMODEL,
            comp: name,
            signal: name,
            compType: '',
            display: true,
            positivePin: '',
            negativePin: '',
            fileName,
            hashId: sweepStr,
            powerPin,
            portName,
            powerNetName,
            compName: comp,
            model
          })
        })
      }
    }

    if (type === 'simulation' && isOptResultExist) {
      let optStr = Math.random().toString(36).slice(2, 12);
      while (hashId.includes(optStr)) {
        optStr = Math.random().toString(36).slice(2, 12);
      };
      hashId.push(optStr)
      ports.push({
        index: ports.length + 1,
        wordIndex: Number(words[0]),
        impedance: Number(words[1]),
        name: name,
        pin: comp === 'sense' ? ['sense'] : (pin || []),
        power: _power,
        portType: OPTIMIZATION,
        comp: name,
        signal: name,
        compType: '',
        display: true,
        positivePin: '',
        negativePin: '',
        fileName,
        hashId: optStr,
        powerPin,
        portName,
        powerNetName,
        compName: comp
      })
    }
  }
  for (let target of newTargetFiles) {
    const { power, axis } = target;
    axis.forEach((item, index) => {
      let targetName = `Target - ${item.targetName || index}`;
      if (item.powerPins && item.powerPins.length) {
        targetName = `Port ${index + 1}${item.powerPins[0] ? ` - ${item.powerPins[0]}` : ""}${item.portName ? ` - ${item.portName}` : ""} Target - ${item.targetName || index}`;
      }
      //Generate 10-digit random numbers containing numbers and letters
      let str = Math.random().toString(36).substr(2, 12);
      //Determine if it already exists
      while (hashId.includes(str)) {
        str = Math.random().toString(36).substr(2, 12);
      }
      hashId.push(str);
      ports.push({
        index: ports.length + 1,
        impedance: ports.length + 1,
        name: targetName,
        power: power,
        portType: 'target',
        comp: targetName,
        signal: targetName,
        hashId: str,
        powerPin: item.powerPins && item.powerPins.length ? item.powerPins[0] : null,
        pin: item.powerPins && item.powerPins.length ? [item.powerPins[0]] : [],
        compType: '',
        display: true,
        positivePin: '',
        negativePin: '',
        fileName: `${power}@${index}`,
        port: (index + 1).toString() || null,
        portName: item.portName
      })
      targetFrequency[str] = item.frequency
    })
  }


  for (let i = portNum + 2, length = lineBuffer.length; i < length; i++) {
    frequency.push(Number(lineBuffer[i]))
  }
  return {
    ports: ports,
    freq: frequency,
    targetFrequency: { ...targetFrequency }
  }
}

function getFrequencyList(min, max, rate) {
  let frequencyList = [];
  let maxRatio = parseInt(Math.ceil(Math.log(max / min, 10) * 3));
  for (let i = 0; i < maxRatio; i++) {
    const value = min * Math.pow(10, i / rate);
    value <= max && frequencyList.push(value);
  }
  if (!frequencyList.includes(max)) {
    frequencyList.push(max)
  }
  return frequencyList;
}

function calcFrequencyPoints(res, ind, max = 2e8, min = 100) {
  let frequencyList = getFrequencyList(min, max, 4);
  let frequencyPoints = frequencyList.map(freq => {
    let impedance = Math.sqrt(res * res + Math.pow(2 * Math.PI * freq * ind, 2))
    return { frequency: freq, impedance }
  });
  return frequencyPoints;
}

function calcCurrentPoints(current) {
  const { idle, dt1, burst, dt2, current1, current2 } = current;
  const idleDecimal = new Decimal(idle);
  const dt1Decimal = new Decimal(dt1);
  const burstDecimal = new Decimal(burst);
  const dt2Decimal = new Decimal(dt2);
  const current1Decimal = new Decimal(current1);
  const current2Decimal = new Decimal(current2);

  const currentPoints = [
    { time: new Decimal(0), current: current1Decimal },
    { time: idleDecimal, current: current1Decimal },
    { time: idleDecimal.plus(dt1Decimal), current: current2Decimal },
    { time: idleDecimal.plus(dt1Decimal).plus(burstDecimal), current: current2Decimal },
    { time: idleDecimal.plus(dt1Decimal).plus(burstDecimal).plus(dt2Decimal), current: current1Decimal },
    { time: idleDecimal.times(2).plus(dt1Decimal).plus(burstDecimal).plus(dt2Decimal), current: current1Decimal },
  ];
  const _currentPoints = currentPoints.map(item => {
    return {
      time: item.time.toString(),
      current: item.current.toString()
    };
  });

  return _currentPoints;
}

function targetNameHandle(name) {
  return name ? name.replace(/\./g, 'P').replace(/[^0-9a-zA-Z_-]+/g, '') : "";
}

function filterPortsByPCB(designId, chip, PowerNets, ReferenceNets, ports = []) {
  let newPorts = [], deletePower = [], deleteRefe = [];
  if (ports.length) {
    const power = auroraDBJson.getNetsByNames(designId, PowerNets).map(net => net.pins.get(chip)).flat();
    const refe = auroraDBJson.getNetsByNames(designId, ReferenceNets).map(net => net.pins.get(chip)).flat();
    for (let port of ports) {
      let _port = { ...port };
      deletePower.push(..._port.powerPins.filter(pin => !power.includes(pin)))
      deleteRefe.push(..._port.referencePins.filter(pin => !refe.includes(pin)))
      _port.powerPins = _port.powerPins.filter(pin => power.includes(pin));
      _port.referencePins = _port.referencePins.filter(pin => refe.includes(pin));
      if (_port.powerPins.length && _port.referencePins.length) {
        newPorts.push(_port)
      }
    }
  }
  return { ports: newPorts, deletePower, deleteRefe }
}

function filterPortsByPackage(designId, dieComp, bgaComp, PowerNets, ReferenceNets, diePorts = [], bgaPorts = []) {
  let newDiePorts = [], newBgaPorts = [], deleteDiePower = [], deleteDieRefe = [], deleteBgaPower = [], deleteBgaRefe = []
  if (diePorts.length) {
    const power = auroraDBJson.getNetsByNames(designId, PowerNets).map(net => net.pins.get(dieComp)).flat();
    const refe = auroraDBJson.getNetsByNames(designId, ReferenceNets).map(net => net.pins.get(dieComp)).flat();
    for (let diePort of diePorts) {
      let _diePort = { ...diePort };
      deleteDiePower.push(..._diePort.powerPins.filter(pin => !power.includes(pin)))
      deleteDieRefe.push(..._diePort.referencePins.filter(pin => !refe.includes(pin)))
      _diePort.powerPins = _diePort.powerPins.filter(pin => power.includes(pin));
      _diePort.referencePins = _diePort.referencePins.filter(pin => refe.includes(pin));
      if (_diePort.powerPins.length && _diePort.referencePins.length) {
        newDiePorts.push(_diePort)
      }
    }
  }

  if (bgaPorts.length) {
    const power = auroraDBJson.getNetsByNames(designId, PowerNets).map(net => net.pins.get(bgaComp)).flat();
    const refe = auroraDBJson.getNetsByNames(designId, ReferenceNets).map(net => net.pins.get(bgaComp)).flat();
    for (let bgaPort of bgaPorts) {
      let _bgaPort = { ...bgaPort };
      deleteBgaPower.push(..._bgaPort.powerPins.filter(pin => !power.includes(pin)))
      deleteBgaRefe.push(..._bgaPort.referencePins.filter(pin => !refe.includes(pin)))
      _bgaPort.powerPins = _bgaPort.powerPins.filter(pin => power.includes(pin));
      _bgaPort.referencePins = _bgaPort.referencePins.filter(pin => refe.includes(pin));
      if (_bgaPort.powerPins.length && _bgaPort.referencePins.length) {
        newBgaPorts.push(_bgaPort)
      }
    }
  }

  return { newDiePorts, newBgaPorts, deleteDiePower, deleteDieRefe, deleteBgaPower, deleteBgaRefe }
}

function getCompUpdateLog(newInfo, oldInfo) {
  const newComp = newInfo.Components.map(item => item.name);
  const oldComp = oldInfo.Components.map(item => item.name);

  let lostComp = oldComp.filter(item => !newComp.includes(item));
  let addComp = newComp.filter(item => !oldComp.includes(item));
  let log = [];
  if (lostComp.length) {
    log.push(`[Update][Components][${newInfo.MAIN_POWER_NETS && newInfo.MAIN_POWER_NETS.join(',')}] ${lostComp.join(',')} ${lostComp.length > 1 ? 'have' : 'has'} been removed.`)
  }
  if (addComp.length) {
    log.push(`[Update][Components][${newInfo.MAIN_POWER_NETS && newInfo.MAIN_POWER_NETS.join(',')}] ${addComp.join(',')} ${addComp.length > 1 ? 'have' : 'has'} been added.`)
  }
  return log;
}


function sortReference(refeList, designId, pwr, pwrPins = [], referenceNets = []) {
  if (!designId || !pwr || !pwrPins.length) {
    return refeList;
  }
  const compPins = auroraDBJson.getComponentPins(designId, pwr).filter(pin => pwrPins.includes(pin.number))
  const pwrNets = compPins.map(item => item.net);
  const refes = getNearbyComponent({
    designId,
    target: pwr,
    components: refeList,
    targetNets: [...new Set(pwrNets)],
    sameLayer: false,
    nets: referenceNets,
    returnList: true
  })
  return refes
  // return references.sort((a, b) => {
  //   if (!a.pins || !a.pins.length) {
  //     return 1
  //   } else if (!b.pins || !b.pins.length) {
  //     return -1
  //   }
  //   const { x: aX, y: aY } = a.pins[0];
  //   const aXLength = Math.abs(pwrLocation.mX - aX), aYLength = Math.abs(pwrLocation.mY - aY);

  //   const { x: bX, y: bY } = b.pins[0];
  //   const bXLength = Math.abs(pwrLocation.mX - bX), bYLength = Math.abs(pwrLocation.mY - bY);

  //   return (aXLength * aXLength + aYLength * aYLength) - (bXLength * bXLength + bYLength * bYLength)
  // }).map(item => item.name)
}

function getImpTargetName({
  targetName,
  record,
  targetType,
  PowerNets,
}) {
  let name = targetName ? targetName : record && record.MAIN_POWER_NETS && record.MAIN_POWER_NETS.length
    ? `Target_${targetNameHandle(record.MAIN_POWER_NETS[0])}` : 'Target_Customize';
  if (targetType === "port") {
    if (targetName) {
      name = targetName;
    } else {
      const powerNet = PowerNets && PowerNets.length ? targetNameHandle(PowerNets[0]) : "Customize";
      const portName = record ? record.port : 'port';
      name = `Target_${powerNet}_${portName}`;
    }
  }
  return name;
}

function getImpedanceResultMaxFreq(content) {
  //FMAX
  if (!content || !content.layouts) {
    if (!content) {
      return null;
    }
    if (!content.Config || !Object.keys(content.Config).length) {
      return null;
    }
    return Number(content.Config.FMAX) || null;
  }

  if (content && content.layouts) {
    const FMAXList = content.layouts.map(layout => layout.extraction ? layout.extraction.FMAX : null).filter(item => !!item).map(item => Number(item));
    const max = Math.max(...FMAXList);
    return max || null;
  }
}

function getImpedanceRlMinAndRlMax(content) {
  let rlMin = null, rlMax = null
  if (content && content.Options) {
    if (content.Options.rlMin && !numberCheck(content.Options.rlMin)) {
      rlMin = unitChange({ num: content.Options.rlMin, newUnit: 'Hz', oldUnit: 'MHz' }).number
    }
    if (content.Options.rlMax && !numberCheck(content.Options.rlMax)) {
      rlMax = unitChange({ num: content.Options.rlMax, newUnit: 'Hz', oldUnit: 'MHz' }).number
    }
  }
  return { rlMin, rlMax }
}

function updateTargetFrequency(points, maxFreq) {
  if (!points || !points.length) {
    return points
  }
  let _points = JSON.parse(JSON.stringify(points));

  //sorted by frequency
  _points = _points.sort((a, b) => a.frequency - b.frequency);
  //add min frequency 0
  if (_points[0] && _points[0].frequency > 0) {
    const impedance = _points[0].impedance;
    _points.unshift({
      frequency: 0,
      impedance
    })
  }
  if (maxFreq && _points[_points.length - 1] && parseFloat(_points[_points.length - 1].frequency) < parseFloat(maxFreq)) {
    const impedance = _points[_points.length - 1].impedance;
    _points.push({
      frequency: maxFreq,
      impedance
    })
  }

  //Remove point and add max freq if frequency is greater than max frequency
  const pointIndex = _points.findIndex(item => parseFloat(item.frequency) > parseFloat(maxFreq));
  if (pointIndex > -1) {
    _points = _points.filter((item, index) => index <= pointIndex);
    _points[pointIndex].frequency = maxFreq;
  }

  return _points;
}

function getNetsAndCompsFromDetails(ImpData, targetIC, designId) {
  let nets = [], comps = [], referenceNets = [];
  const components = getAllCascadeComponents({ pcbId: designId });
  if (ImpData && ImpData.length) {
    for (let data of ImpData) {
      if (data.readyForSim === 0 || !data.content) {
        continue;
      }
      const { DEBUG_MONITOR: details = [], Components = [], PowerNets = [], ReferenceNets = [] } = data.content
      targetIC && comps.push(targetIC);
      if (!details.length) {
        comps.push(...Components.map(i => i.name));
        nets.push(...PowerNets)
      } else {
        for (let detail of details) {
          detail.forEach(item => {
            if (item.type === 'net') {
              nets.push(item.name)
            } else if (item.type === 'comp') {
              comps.push(item.name)
            }
          })
        }
      }
      referenceNets.push(...ReferenceNets)
    }
  }
  const _comps = [...new Set(comps)].map(comp => {
    const compInfo = components.get(comp);
    if (!compInfo) {
      return null
    }
    return { ...compInfo, pins: [...compInfo.pins.values()] }
  }).filter(item => !!item)
  return { nets: [...new Set(nets)], comps: _comps, referenceNets: [...new Set(referenceNets)] }
}

function generateNewCompareMap({ compareEffects, id }) {
  const verification = compareEffects.find(i => i.id === id);
  if (verification) {
    let effectRow = [];
    const { name, id, effects } = verification;
    for (let effect of effects) {
      const { portInformation, powerNet } = effect;
      let portRow = []
      for (let portInfo of portInformation) {
        const { port, powerPins } = portInfo;
        portRow.push(new CompareMapRow({
          origin: port,
          key: `${port}-${powerNet}-${id}`,
          id: port,
          powerPins,
          type: 'port',
          parent: powerNet,
          bind: ''
        }))
      }
      effectRow.push(new CompareMapRow({
        origin: powerNet,
        key: `${powerNet}-${id}`,
        id: powerNet,
        type: 'net',
        parent: id,
        bind: '',
        children: portRow
      }))
    }
    return new CompareMapRow({
      origin: name,
      key: id,
      id: id,
      type: 'verification',
      bind: '',
      children: effectRow
    })
  }
  return null
}

function autoMapCompare(row, info, type, params = {}) {
  let level = 0;
  const { curNet = '' } = params;
  switch (type) {
    case 'verification':
      level = 0;
      break;
    case 'net':
      level = 1;
      break;
    case 'port':
      level = 2;
      break;
    default:
      break;

  }

  let _row = { ...row };
  if (level < 1) {
    const current = info.find(i => i.effects && i.effects.length);
    if (!current) {
      return _row
    }
    _row.bind = current.name;
  }

  if (level < 2) {
    const current = info.find(i => i.name === _row.bind);
    if (!current || !current.effects.length) {
      return _row
    }
    const effects = current.effects;
    const used = []
    _row.children.forEach(net => {
      let effect = effects.find(eff => eff.powerNet === net.id && !used.includes(eff.powerNet));
      if (!effect) {
        effect = effects.find(eff => getArraySimilar(eff.powerNet, net.id) > 0.9 && !used.includes(eff.powerNet))
      }
      if (effect) {
        net.bind = effect.powerNet
        used.push(effect.powerNet)
      } else {
        net.bind = ""
      }
    })
  }

  if (level < 3) {
    const current = info.find(i => i.name === _row.bind);
    if (!current || !current.effects.length) {
      return _row
    }
    const effects = current.effects;
    _row.children.forEach(net => {
      if (!curNet || net.origin === curNet) {
        const effect = effects.find(eff => eff.powerNet === net.bind);
        if (effect) {
          const { portInformation } = effect;
          const used = []
          net.children.forEach(port => {
            const _port = portInformation.find(item => !used.includes(item.port) && (item.powerPins.every(p => port.powerPins.includes(p)) || port.powerPins.every(p => item.powerPins.includes(p))))
            if (_port) {
              port.bind = _port.port
              port.currentPins = _port.powerPins
              used.push(_port.port)
            } else {
              port.bind = ''
              port.currentPins = []
            }
          })
        } else {
          net.children.forEach(port => {
            port.bind = ''
            port.currentPins = []
          })
        }
      }
    })
  }
  return _row
}

function initCompareMap({ info, compareEffects, currentInfo }) {
  const { verificationId, historys } = info;
  let _map = [];
  for (let id of [verificationId, ...historys.map(i => i.historyId)]) {
    const row = generateNewCompareMap({ compareEffects, id });
    if (row) {
      const _row = autoMapCompare(row, currentInfo, 'verification');
      _map.push(_row)
    }
  }
  return _map
}

function upgradeSetupDataToMulti(newData) {
  const name = newData.name;
  const designId = newData.designId;
  const designType = newData.type;
  const additionalNets = newData.additionalNets;
  const setup = new ImpSetup({ ...newData, designType });
  if (!designId) {
    return {
      ...setup,
      layouts: [],
      name
    }
  }
  const powerDomains = newData.powerDomains || [];
  let ICs = [], Dies = [], Bgas = [], PMICs = [];
  const targetIC = newData.targetIC;
  if (powerDomains.length) {
    let allComponents = getAllCascadeComponents({ pcbId: designId });
    allComponents = allComponents ? [...allComponents.values()] : []
    if (designType === IMPEDANCE_PACKAGE) {
      const components = powerDomains.map(item => item.content.Components).flat(2);
      Bgas = components.filter(item => item.usage === BGA).map(item => item.name);
      Dies = components.filter(item => item.usage === DIE).map(item => item.name);

      Bgas = [...new Set(Bgas)];
      Dies = [...new Set(Dies)];

      Bgas = allComponents.filter(item => Bgas.includes(item.name));
      Dies = allComponents.filter(item => Dies.includes(item.name));

      Bgas = Bgas.map(item => ({ ...item, pins: [...item.pins.values()], usage: BGA, to: [{ designId, components: Dies.map(d => d.name) }], from: [] }));
      Dies = Dies.map(item => ({ ...item, pins: [...item.pins.values()], usage: DIE, to: [], from: [{ designId, components: Bgas.map(b => b.name) }] }));
    } else if (targetIC) {
      let vrms = powerDomains.map(item => item.content.VRM && item.content.VRM.map(vrm => vrm.VRM_COMP)).flat(3);
      vrms = [...new Set(vrms)];
      ICs = allComponents.filter(item => item.name === targetIC).map(item => ({ ...item, pins: [...item.pins.values()], usage: IC, from: [], to: [{ designId, components: vrms }] }));
      PMICs = allComponents.filter(item => vrms.includes(item.name)).map(item => ({ ...item, pins: [...item.pins.values()], usage: PMIC, from: [{ designId, components: [targetIC] }], to: [] }))
    }
  }

  const layouts = new ImpLayout({ ...newData, ICs, PMICs, Bgas, Dies, index: designType === IMPEDANCE_PACKAGE ? TARGET_PACKAGE_INDEX : TARGET_PCB_INDEX, additionalNets, extraction: newData.Config });
  return {
    ...setup,
    powerDomainIds: powerDomains.map(item => item.id),
    layouts: [layouts],
    name
  }
}

class OverviewComponent {
  constructor(item, index, side) {
    const { name, usage } = item;
    this.name = name;
    this.usage = usage;
    this.index = index;
    this.color = getComponentColor(usage);
    this.side = side;
    this.data = item;
  }
}

function sortOverviewComps(data) {
  let layoutList = [];
  for (let layout of data) {
    const { designId, type, Bgas = [], Dies = [], ICs = [], PMICs = [], connectors = [], powerDomains } = layout;
    let left = [], right = [];
    for (let conn of connectors) {
      const { from = [], to } = conn;
      if ((from.length && from.every(f => f.designId === designId)) || (!from.length && !to.some(t => t.designId === designId))) {
        right.push(conn);
      } else {
        left.push(conn)
      }
    }

    if (type === IMPEDANCE_PACKAGE) {
      right.push(...Bgas);
      left.push(...Dies);
    } else if (type === IMPEDANCE_DIE) {
      const pkgIndex = data.findIndex(item => item.index === TARGET_PACKAGE_INDEX)
      ICs.forEach(ic => {
        ic.powerNets = []
        data[pkgIndex].powerDomains.forEach(domain => {
          const { Components = [], PowerNets = [] } = domain.content
          if (Components.find(comp => comp.name === ic.to[0].components[0]) && !ic.powerNets.includes(PowerNets[0])) {
            ic.powerNets.push(PowerNets[0])
          }
        })
      })
      right.push(...ICs);
    } else {
      left.push(...JSON.parse(JSON.stringify(ICs)));
      right.push(...JSON.parse(JSON.stringify(PMICs)));
      powerDomains.forEach(domain => {
        const { content: { VRM = [], Components = [] } } = domain
        const comp = Components[0] ? Components[0].name : ''
        let display = []
        const ICIndex = left.findIndex(item => item.name === comp)
        VRM.forEach(item => {
          if (!item.VRM_COMP || !item.VRM_COMP.length) {
            const gnd = item.groundPin && item.groundPin.length ? item.groundPin[0].comp : '';
            const pwr = item.powerPin && item.powerPin.length ? item.powerPin[0].comp : '';
            if (gnd || pwr) {
              display.push(`[${pwr}, ${gnd}]`)
            }
          }
        })
        display.forEach(item => {
          right.push({ usage: 'VRM', name: item })
          if (ICIndex > -1) {
            left[ICIndex].to.push({ designId, components: [item] })
          }
        })
      })
    }

    left = left.map((item, index) => new OverviewComponent(item, index, 'left'))
    right = right.map((item, index) => new OverviewComponent(item, index, 'right'))
    const height = (left.length > right.length ? left.length : right.length) * 100 + 30 + 40 + 40; // 30 - space, 40 - add connector button, tag
    layoutList.push({ left, right, designId, height })
  }
  return layoutList
}

function reverseOverviewComps(data) {
  let layoutList = [];
  for (let layout of data) {
    const { designId, type, Bgas = [], Dies = [], ICs = [], PMICs = [], connectors = [], powerDomains } = layout;
    let left = [], right = [];

    for (let conn of connectors) {
      const { from = [], to } = conn;
      if ((from.length && from.every(f => f.designId === designId)) || (!from.length && !to.some(t => t.designId === designId))) {
        left.push(conn);
      } else {
        right.push(conn)
      }
    }

    if (type === IMPEDANCE_PACKAGE) {
      left.push(...Bgas);
      right.push(...Dies);
    } else if (type === IMPEDANCE_DIE) {
      const pkgIndex = data.findIndex(item => item.index === TARGET_PACKAGE_INDEX)
      ICs.forEach(ic => {
        ic.powerNets = []
        data[pkgIndex].powerDomains.forEach(domain => {
          const { Components = [], PowerNets = [] } = domain.content
          if (Components.find(comp => comp.name === ic.to[0].components[0]) && !ic.powerNets.includes(PowerNets[0])) {
            ic.powerNets.push(PowerNets[0])
          }
        })
      })
      left.push(...ICs);
    } else {
      right.push(...JSON.parse(JSON.stringify(ICs)));
      left.push(...JSON.parse(JSON.stringify(PMICs)));
      powerDomains.forEach(domain => {
        const { content: { VRM = [], Components = [] } } = domain
        const comp = Components[0] ? Components[0].name : ''
        let display = []
        const ICIndex = right.findIndex(item => item.name === comp)
        VRM.forEach(item => {
          if (!item.VRM_COMP || !item.VRM_COMP.length) {
            const gnd = item.groundPin && item.groundPin.length ? item.groundPin[0].comp : '';
            const pwr = item.powerPin && item.powerPin.length ? item.powerPin[0].comp : '';
            if (gnd || pwr) {
              display.push(`[${pwr}, ${gnd}]`)
            }
          }
        })
        display.forEach(item => {
          left.push({ usage: 'VRM', name: item })
          if (ICIndex > -1) {
            right[ICIndex].to.push({ designId, components: [item] })
          }
        })
      })
    }

    left = left.map((item, index) => new OverviewComponent(item, index, 'left'))
    right = right.map((item, index) => new OverviewComponent(item, index, 'right'))
    const height = (left.length > right.length ? left.length : right.length) * 100 + 30 + 40 + 40; // 30 - space, 40 - add connector button, tag
    layoutList.push({ left, right, designId, height })
  }
  return layoutList
}

function getImpedanceLayoutComp(name, designId, usage) {
  let findComp = {};
  const isPreLayout = designConstructor.isPreLayout(designId);
  if (isPreLayout) {
    const data = preLayoutData.getLocalPreLayout(designId);
    const { content = {} } = data;
    const components = content.components || [];
    findComp = components.find(item => item.name === name) || {};
  } else {
    const component = auroraDBJson.getComponent(designId, name);
    findComp = {
      ...component,
      pins: component.pins ? [...component.pins.values()] : []
    }
  }
  return {
    name,
    ...findComp,
    usage,
    from: [],
    to: [],

  }
}

function getConnectorLayoutIndex(connectors, layouts) {
  const maxIndex = Math.max(...layouts.map(d => Number(d.index)))
  if (!connectors.length) {
    return maxIndex;
  }

  const designIds = connectors.map(item => item.netShip.map(s => s.designId)).flat(2);
  const indexList = [...new Set(designIds)].map(designId => {
    const currentLayout = layouts.find(item => item.designId === designId);
    if (!currentLayout) {
      return undefined;
    }
    return Number(currentLayout.index);
  }).filter(item => !!item);

  if (!indexList.length) {
    return 1;
  }
  return Math.min(...indexList);
}

function getConnectionRelationship(layouts) {
  const connectionShip = {};

  for (let layout of layouts) {
    const { connectors, designId } = layout;
    for (let connector of connectors) {
      const { name, to = [], from = [] } = connector;
      connectionShip[`${designId}-${name}`] = []
      if (to && to.length) {
        to.forEach(item => {
          if (item.designId !== designId) {
            connectionShip[`${designId}-${name}`].push({ ...item, nets: item.nets[`${name}-${designId}`], connectNets: { ...item.nets }, type: 'to' })
          }
        })
      }

      if (from && from.length) {
        from.forEach(item => {
          if (item.designId !== designId) {
            connectionShip[`${designId}-${name}`].push({ ...item, nets: item.nets[`${name}-${designId}`], connectNets: { ...item.nets }, type: 'from' })
          }
        })
      }

      if (!connectionShip[`${designId}-${name}`].length) {
        delete connectionShip[`${designId}-${name}`];
      }
    }
  }
  return connectionShip;
}

function mergeConnectionTrace(layouts, traceInfo) {

  const connectionShip = getConnectionRelationship(layouts);
  let _traceInfo = { ...traceInfo };
  const keys = Object.keys(_traceInfo);
  for (let key of keys) {
    let powerDomains = JSON.parse(JSON.stringify(_traceInfo[key]));

    for (let i = 0; i < powerDomains.length; i++) {
      const DEBUG_MONITOR = powerDomains[i].DEBUG_MONITOR;
      let new_DEBUG_MONITOR = []

      for (let detail of DEBUG_MONITOR) {
        let _detail = [...detail];
        const connectComps = _detail.filter(item => item.type === 'comp');
        const connectNets = _detail.filter(item => item.type === 'net');

        const lastComp = connectComps[connectComps.length - 1];
        if (connectionShip[`${key}-${lastComp.name}`]) {
          const lastNet = connectNets[connectNets.length - 1];
          const connShip = connectionShip[`${key}-${lastComp.name}`];
          let toMap = [];
          connShip.filter(item => item.type === 'to').forEach(item => {
            if (item.nets && item.nets.includes(lastNet.name)) {
              const findIndex = item.nets.findIndex(n => n === lastNet.name)
              const tos = item.components.map(comp => {
                const net = item.connectNets[`${comp}-${item.designId}`] && item.connectNets[`${comp}-${item.designId}`][findIndex] ? item.connectNets[`${comp}-${item.designId}`][findIndex] : null
                return {
                  name: comp,
                  designId: item.designId,
                  nets: net ? [net] : []
                }
              })
              toMap.push(...tos);
            }
          })
          if (toMap.length) {
            _detail = [..._detail, { type: 'connect', children: toMap }];
          }
        }

        const firstComp = connectComps[0];
        if (connectionShip[`${key}-${firstComp.name}`]) {
          const firstNet = connectNets[0];
          const connShip = connectionShip[`${key}-${firstComp.name}`];
          let fromMap = [];
          connShip.filter(item => item.type === 'from').forEach(item => {
            if (item.nets && item.nets.includes(firstNet.name)) {
              const findIndex = item.nets.findIndex(n => n === firstNet.name)
              const froms = item.components.map(comp => {
                const net = item.connectNets[`${comp}-${item.designId}`] && item.connectNets[`${comp}-${item.designId}`][findIndex] ? item.connectNets[`${comp}-${item.designId}`][findIndex] : null
                return {
                  name: comp,
                  designId: item.designId,
                  nets: net ? [net] : []
                }
              })
              fromMap.push(...froms);
            }
          })
          if (fromMap.length) {
            _detail = [{ type: 'connect', children: fromMap }, ..._detail];
          }
        }
        new_DEBUG_MONITOR.push(_detail)
      }

      powerDomains[i].DEBUG_MONITOR = new_DEBUG_MONITOR;
    }
    _traceInfo[key] = powerDomains;
  }

  return _traceInfo;
}

function setNewConnectorNetsSpec(nets) {
  const keys = Object.keys(nets);
  let _nets = { ...nets };
  if (keys.length !== 2) {
    keys.forEach(key => _nets[key] = []);
    return _nets;
  }

  const powerNets_0 = _nets[keys[0]].powerNets;
  const powerNets_1 = _nets[keys[1]].powerNets;
  const referenceNets_0 = _nets[keys[0]].referenceNets;
  const referenceNets_1 = _nets[keys[1]].referenceNets;
  const keys_0 = [], keys_1 = [];
  for (let powerNet_0 of powerNets_0) {
    for (let powerNet_1 of powerNets_1) {
      keys_0.push(powerNet_0);
      keys_1.push(powerNet_1)
    }
  }

  for (let referenceNet_0 of referenceNets_0) {
    for (let referenceNet_1 of referenceNets_1) {
      keys_0.push(referenceNet_0);
      keys_1.push(referenceNet_1)
    }
  }
  _nets[keys[0]] = keys_0;
  _nets[keys[1]] = keys_1;

  return _nets
}

function getConnectionGroundNet({ designId, name, currentDesignId, targetIC, setting, data }) {
  const { groundNet } = getPowerDomain({ chip: targetIC, designId: currentDesignId, COMP_PREFIX_LIB: setting });
  const _groundNet = getGroundNetLoop({ designId, name, groundNet, currentDesignId, data })
  return _groundNet
}

function getGroundNetLoop({ designId, name, groundNet, currentDesignId, data }) {
  const currentLayout = data.find(item => item.designId === currentDesignId);
  if (currentLayout) {
    const connectors = currentLayout.connectors.filter(conn => conn.to.some(t => t.nets));
    let _groundNet = '';
    for (let connector of connectors) {
      const { to, name: conn } = connector;
      for (let t of to) {
        if (_groundNet || !t.nets) {
          break;
        }
        const { nets, components, designId: pcbId } = t;
        if (!components || !components.length) {
          continue;
        }
        const prevNets = nets[`${conn}-${currentDesignId}`];
        const nextNets = nets[`${components[0]}-${pcbId}`];
        const findIndex = prevNets.findIndex(item => item === groundNet);
        if (findIndex < 0) {
          continue;
        }
        const nextGroundNet = nextNets[findIndex] || '';
        if (components[0] === name && pcbId === designId) {
          return nextGroundNet
        }
        _groundNet = getGroundNetLoop({ designId, name, groundNet: nextGroundNet, currentDesignId: pcbId, data })
      }
    }
    return _groundNet
  }
  return ''
}

function getDiePortsByCPM({
  packageId,
  component,
  PowerNets,
  ReferenceNets,
  cpmPins
}) {
  const DesignData = LayoutData.getLayout(packageId);
  const filterData = getPortData(DesignData, component.name, PowerNets, ReferenceNets)
  const { powerPins, referencePins, data: PCBData } = filterData;
  const powers = Object.values(powerPins).flat(2), refe = Object.values(referencePins).flat(2);
  const groups = {};
  cpmPins.forEach(item => {
    const prefix = item.csmNet.split('_').slice(0, 3).join('_');
    if (!groups[prefix]) {
      groups[prefix] = { powerPins: [], referencePins: [] };
    }

    if (PowerNets.includes(item.net) && powers.includes(item.pin)) {
      groups[prefix].powerPins.push(item.pin);
    } else if (ReferenceNets.includes(item.net) && refe.includes(item.pin)) {
      groups[prefix].referencePins.push(item.pin);
    }
  });

  const portsTable = Object.keys(groups)
    .filter(prefix => groups[prefix].powerPins.length > 0)
    .map((prefix, index) => ({
      port: (index + 1).toString(),
      powerPins: groups[prefix].powerPins,
      referencePins: groups[prefix].referencePins
    }));
  return portsTable;
}

export {
  generateCompData,
  getPowerPinList,
  mergeVRMInfo,
  ImpedanceErrorCheck,
  npiFileParseCascade,
  calcFrequencyPoints,
  calcCurrentPoints,
  targetNameHandle,
  filterPortsByPCB,
  filterPortsByPackage,
  getCompUpdateLog,
  sortReference,
  getImpTargetName,
  getImpedanceResultMaxFreq,
  updateTargetFrequency,
  generateCompTabs,
  generateCompDataByTabs,
  generateCompBasedDataByTabs,
  ImpedancePackageErrorCheck,
  getNetsAndCompsFromDetails,
  generateNewCompareMap,
  autoMapCompare,
  initCompareMap,
  ImpedanceWarningCheck,
  upgradeSetupDataToMulti,
  sortOverviewComps,
  reverseOverviewComps,
  getImpedanceLayoutComp,
  getConnectorLayoutIndex,
  mergeConnectionTrace,
  setNewConnectorNetsSpec,
  getConnectionGroundNet,
  getDiePortsByCPM,
  getImpedanceRlMinAndRlMax
}