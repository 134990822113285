import CeObject from './CeObject';
import CePad from './CePad';
import CeLinkedMap from '../utility/CeLinkedMap';
import StringList from '../utility/StringList';
import StrSplitRule from '../utility/StrSplitRule';
import StringHelper from '../utility/StringHelper';
import CeIODataBlock from '../CeFileIO/CeIODataBlock';

var CeVia = function () {
  CeObject.call(this);
  this.mBarrel = new CePad();
  this.mLayerPads = new CeLinkedMap();
};

// subclass extends superclass
CeVia.prototype = Object.create(CeObject.prototype);
CeVia.prototype.constructor = CeVia;


CeVia.prototype.GetTopLayer = function () {
  return this.mLayerPads.getKey(0);
}

CeVia.prototype.SetBarrel = function (shapeId, rotation, cw) {
  this.mBarrel.SetPadData(shapeId, rotation, cw);
}

CeVia.prototype.AddLayerPad = function (layer, pad) {
  this.mLayerPads.put(layer, pad);
}

CeVia.prototype.GetDataString = function () {
  var dataStrs = new StringList();
  dataStrs.add(CeObject.prototype.GetID.call(this));
  dataStrs.add(this.mBarrel.mShapeId);

  for (var i = 0; i < this.mLayerPads.size(); i++) {
    var lyrName = this.mLayerPads.getKey(i);
    var pad = this.mLayerPads.getValue(i);
    dataStrs.add(lyrName + ":" + pad.mShapeId);
  }
  return dataStrs.Join(", ");
}

CeVia.prototype.UpdateByDataString = function (dataStr) {
  var splitRule = new StrSplitRule(" ,:<>()");
  var strItems = StringHelper.SplitString(dataStr, splitRule);
  if (strItems.size() > 2) {
    var objId = parseInt(strItems.get(0), 10);
    CeObject.prototype.GetID.call(this, objId);
    this.mBarrel.mShapeId = parseInt(strItems.get(1), 10);
    for (var i = 2; i < strItems.size(); i += 2) {
      var layer = strItems.get(i);
      var pad = new CePad();
      pad.SetID(parseInt(strItems.get(i + 1), 10));
      this.mLayerPads.put(layer, pad);
    }
  }
}

CeVia.prototype.WriteIntoIODataNode = function () {
  var viaBlock = new CeIODataBlock("Via");
  var IDName = CeObject.prototype.GetDataStrings.call(this);
  viaBlock.AddDataItem("IdName", IDName);
  viaBlock.AddDataItem("Barrel", this.mBarrel.GetDataString());

  for (var i = 0; i < this.mLayerPads.size(); i++) {
    var key = this.mLayerPads.getKey(i);
    var pad = this.mLayerPads.getValue(i);
    viaBlock.AddDataItem(key, pad.GetDataString());
  }

  return viaBlock;
}

CeVia.prototype.ReadFromIODataNode = function (viaBlk) {
  var idName = viaBlk.GetItemStringList("IdName");
  CeObject.prototype.UpdateByString.call(this, idName);

  var barrelData = viaBlk.GetItemStringList("Barrel");
  this.mBarrel.UpdateByString(barrelData);

  var padItems = viaBlk.GetAllBlockItems();
  for (var i = 2; i < padItems.length; i++) {
    var layer = padItems[i].GetName();
    var padData = padItems[i].GetItemValue();
    var pad = new CePad();
    pad.UpdateByString(padData);
    this.AddLayerPad(layer, pad);
  }

  return true;
}

export default CeVia;