import {
  LAYER_CHANGE,
  LOCATION_CHANGE,
  NET_CHANGE,
  COMP_CHANGE,
  CLEAR_SELECTION,
  OPEN_PANEL,
  CLOSE_PANEL,
  COLORBY_CHANGE,
  SELECTED_ALL,
  CANCEL_SELECTED_ALL,
  SHOW_SELECTED,
  HIDE_SELECTED,
  SELECTION_CHECKBOX_CHANGE,
  SELECTION_CLEAR_ONE,
  CLEAN_STATUS,
  CHANGE_SELECTED_DISPLAY,
  SELECT_CHANGE
} from './actionTypes';

export const updateLocation = (location) => ({
  type: LOCATION_CHANGE,
  location
})

export const selectLayer = (layers) => ({
  type: LAYER_CHANGE,
  layers
})

export const canvasSelect = (selection) => {
  if (selection && (selection.nets || selection.comps)) {
    return {
      type: selection.nets && selection.comps ? SELECT_CHANGE : selection.nets ? NET_CHANGE : COMP_CHANGE,
      ...selection
    }
  } else {
    return {
      type: CLEAR_SELECTION
    }
  }
}

export const openPanel = (panelType, designID) => ({
  type: OPEN_PANEL,
  panel: panelType,
  designID
})

export const closePanel = (designID) => ({
  type: CLOSE_PANEL,
  designID
})

export const changeColorBy = (colorBy, designID) => ({
  type: COLORBY_CHANGE,
  colorBy,
  designID
})

export const clearOneSelection = (arr) => ({
  type: SELECTION_CLEAR_ONE,
  arr
})

export const showSelected = () => ({
  type: SHOW_SELECTED
})

export const hideSelected = () => ({
  type: HIDE_SELECTED
})

export const selectedAll = () => ({
  type: SELECTED_ALL,
})

export const cancelSelectedAll = () => ({
  type: CANCEL_SELECTED_ALL,
})

export const checkboxChange = (canvasObj) => {
  return {
    type: SELECTION_CHECKBOX_CHANGE,
    payload: canvasObj
  };
}

export const cleanStatus = (designID) => ({
  type: CLEAN_STATUS,
  designID
})

export const changeDisplaySelected = (show) => ({
  type: CHANGE_SELECTED_DISPLAY,
  show
})
