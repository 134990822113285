import { numberToScientific } from "../../helper/dataProcess";
import { unitChange } from "../../helper/mathHelper";
import { valueUnitSplit } from "../../helper/valueUnitSplit";

class ResistanceData {
  constructor() {
    this.point1s = [new Point()];
    this.point2s = [new Point()];
    this.net = [];
  }
}


class Point {
  constructor() {
    this.comp = "";
    this.net = "";
    this.pins = [];
  }
  updateComp(comp) {
    this.comp = comp;
    return this;
  }
}

function getPathRLoopTableData(resistanceData, groupsList) {
  let data = [];

  for (let _index = 0; _index < groupsList.length; _index++) {
    const pairsItem = groupsList[_index];
    const ids = pairsItem.ids || [];
    let _dataList = [];
    let actualValueList = [];
    const idMap = new Map(ids.map((value, index) => [value, index]));
    const groupData = resistanceData.filter(it => ids.includes(it.id)).sort((a, b) => idMap.get(a.id) - idMap.get(b.id));
    for (let i = 0; i < groupData.length; i++) {
      const item = groupData[i];
      //calc actualValue
      let _resistance = item.resistance;
      if (["Inf", 'inf'].includes(item.resistance)) {
        actualValueList.push("Inf");
        _resistance = "Inf"
      } else if (item.resistance) {
        _resistance = numberToScientific(item.resistance) + 'Ω';
        actualValueList.push(item.resistance);
      }

      _dataList.push({
        ...item,
        resistance: _resistance,
        actualValue: "",
        rowSpan: i === 0 ? groupData.length : 0,
        rowClassName: i === groupData.length - 1 ? "path-r-row-dividing-line" : "",
        pairs: [...ids],
        spec: pairsItem.spec,
        tdClassName: i === 0 && _index !== groupsList.length - 1 ? "path-r-row-dividing-line-td" : "",
        groupIndex: _index + 1
      })
    }
    let actualValue = "";
    if (actualValueList.includes("Inf") || actualValueList.includes("inf")) {
      actualValue = "Inf";
    } else {
      actualValue = actualValueList.length ? 0 : "";
      for (let item of actualValueList) {
        actualValue += parseFloat(item);
      }
      /*     actualValue = numberToScientific(actualValue) + 'Ω'; */
    }
    const hasEmpty = _dataList.some(item => !item.resistance);
    _dataList.forEach(item => {

      if (item.actualValue !== "Inf" && item.actualValue !== "inf") {
        //spec 12m to 12000 ohm
        const { value, unit } = valueUnitSplit(item.spec || "");
        const specValue = unitChange({
          num: value,
          oldUnit: `${unit}ohm`,
          newUnit: "ohm",
          decimals: -1
        }).number;
        item.className = parseFloat(actualValue) > parseFloat(specValue) ? "cascade-target-actual-error-value" : ""
        // actualValue 14000 to 12m
        const _unit = !value ? "m" : unit;
        const _actualValue = actualValue && !isNaN(actualValue) ? unitChange({
          num: actualValue,
          oldUnit: "ohm",
          newUnit: `${_unit}ohm`,
          decimals: 2
        }).number : "";
        item.actualValue = hasEmpty ? "" : _actualValue ? `${_actualValue}${_unit}` : "";
      } else {
        item.actualValue = hasEmpty ? "" : actualValue;
      }
    })
    data.push(..._dataList);
  }
  data.forEach((item, index) => {
    item.arrIndex = index;
    if (index === data.length - 1) {
      item.rowClassName = "";
      item.tdClassName = "";
    }
  });

  return data;
}

export default ResistanceData;

export {
  getPathRLoopTableData
}
