import React, { Component, Fragment } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Switch, Radio, Input, Select } from 'antd';
import Panel from '../Panel';
import cookie from 'react-cookies';
import { FAST, MEDIUM, ORIGINAL, AURORA_NGSPICE, HSPICE, ANSYSDesigner, BUILT_IN, ANSYS_SIWAVE, ANSYS_HFSS, SWEEP, PARALLEL, DIE_PACKAGE_PCB, PCB_PACKAGE_DIE, PARTBASED, COMPONENTBASED } from '../../constants/resolution';
import { PIN_GROUP, GAP, CIRCUIT, WAVE } from "@/services/ExtractionPortsHelper"
import { getVersionPromise } from '../../services/authCtrl';
import { FASTPI as FASTPI_PAGE, SIERRA, ROCKY, CASCADE } from '@/constants/pageType';
import { saveCurrentPDNBySetting } from '../../pages/PDN/store/pdn/action';
import { connect } from 'react-redux';
import EmailSetting from './EmailSetting';
import { getUserNotificationDetailPromise, notificationUserEditPromise, getAllowNotificationProducts } from '../../services/Notification/notificationCtrl';
import { getSierraResultSettings, userDefaultSettings } from '../../services/userDefaultSetting/userDefaultSettingCtrl';
import { numberCheck } from '../../services/helper/dataProcess';
import { saveImpedanceDisplay } from '../../pages/Cascade/store/Impedance/action';
import { saveTemplateDisplay } from '../../pages/Cascade/store/SignOffTemplate/action';
import { updateFullPowerTreeDisplay } from '../../pages/Cascade/store/project/action';
import { UserSettingPortSetupRender } from '../ExtractionPorts/referencePinGroupRender';
import { ALL_PINS, NEARBY_PINS, nearbyPinsSetup, SINGLE_PIN } from '../../services/ExtractionPortsHelper';
import './index.css';

const { Option } = Select;
class SetupPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defaultChecked: false,
      sysVersion: '',
      defaultSIWE: 'off',
      advanced: false,
      couplingMinFreqValue: '3.5',
      couplingMinFreqUnit: 'MHz',
      error: null,
      receiveEmailValue: "",
      emailNotification: {
        ccList: [],
        notification: 0,
        receiveEmail: "",
        userEmail: "",
        userId: "",
        userName: "",
        products: []
      },
      notificationProducts: [],
      userDefaultSetting: null,
      userDefaultSettingVisible: false,
      sierraSettings: {
        simulator: HSPICE,
        extractionOption: ANSYS_SIWAVE,
        portType: PIN_GROUP,
        resultSettings: getSierraResultSettings(),
        portSetupInfo: { referenceType: SINGLE_PIN },
        ioBufferUsage: "Receiver"
      },
      rockySettings: {
        simulator: HSPICE,
        extractionOption: ANSYS_SIWAVE,
        portType: PIN_GROUP
      },
      fastpiSettings: {
        multiCapModelAssignment: SWEEP
      },
      cascadeSettings: {
        impedanceOverviewDisplay: DIE_PACKAGE_PCB,
        impedanceComponentsTableDisplay: PARTBASED,
        powerTreeLevel: 8,
        impedanceLevel: 5,
        showFullPowerTree: false
      },
      prevShowFullPowerTree: false
    }
  }

  componentWillMount = () => {
    this.initDebugMode();
    this.initSIWEmode();
    this.initCouplingMinFreq();
    this.getEmailNotification();
  }

  getEmailNotification = async () => {
    const emailInfo = await getUserNotificationDetailPromise();
    if (emailInfo) {
      this.setState({
        emailNotification: {
          ccList: emailInfo.ccList || [],
          notification: emailInfo.notification || 0,
          receiveEmail: emailInfo.receiveEmail,
          userEmail: emailInfo.userEmail,
          userId: emailInfo.userId,
          userName: emailInfo.userName,
          products: emailInfo.products
        },
        receiveEmailValue: emailInfo.receiveEmail
      })
    }
    const notificationProducts = await getAllowNotificationProducts();
    if (notificationProducts) {
      this.setState({
        notificationProducts: notificationProducts || []
      })
    }
  }

  componentDidMount = async () => {
    getVersionPromise().then(res => {
      this.setState({
        sysVersion: res.version
      })
    }, error => {
      console.error(error);
    });
    await this.initUserDefaultSetting();
  }

  initUserDefaultSetting = async () => {
    const res = await userDefaultSettings.getSettings();
    const { pageType } = this.props
    if (res) {
      if (pageType === SIERRA && res.sierraSettings) {
        const { simulator, extractionOption, portType, resultSettings, portSetupInfo, ioBufferUsage } = res.sierraSettings;
        this.setState({
          userDefaultSetting: res,
          sierraSettings: {
            simulator,
            extractionOption,
            portType,
            resultSettings: getSierraResultSettings(resultSettings),
            portSetupInfo: this.getDefaultSierraPortSetup(portSetupInfo, portType),
            ioBufferUsage: ioBufferUsage || "Receiver"
          }
        })
      } else if (pageType === ROCKY && res.rockySettings) {
        const { simulator, extractionOption, portType } = res.rockySettings
        this.setState({
          userDefaultSetting: res,
          rockySettings: {
            simulator,
            extractionOption,
            portType
          }
        })
      } else if (pageType === FASTPI_PAGE && res.fastpiSettings) {
        const { multiCapModelAssignment } = res.fastpiSettings;
        this.setState({
          userDefaultSetting: res,
          fastpiSettings: {
            multiCapModelAssignment
          }
        })
      } else if (pageType === CASCADE && res.cascadeSettings) {
        const { powerTreeLevel = 8, impedanceLevel = 5, impedanceOverviewDisplay = DIE_PACKAGE_PCB, impedanceComponentsTableDisplay = PARTBASED, showFullPowerTree = false } = res.cascadeSettings
        this.setState({
          userDefaultSetting: res,
          cascadeSettings: {
            ...res.cascadeSettings,
            powerTreeLevel,
            impedanceLevel,
            impedanceOverviewDisplay,
            impedanceComponentsTableDisplay: [PARTBASED, COMPONENTBASED].includes(impedanceComponentsTableDisplay) ? impedanceComponentsTableDisplay : PARTBASED,
            showFullPowerTree
          },
          prevShowFullPowerTree: showFullPowerTree
        })
      } else {
        this.setState({
          userDefaultSetting: res
        })
      }
    }
  }

  getDefaultSierraPortSetup = (portSetupInfo, portType) => {
    let _portSetupInfo = { referenceType: [WAVE, GAP].includes(portType) ? ALL_PINS : SINGLE_PIN };
    if (portSetupInfo && Object.keys(portSetupInfo).length) {
      _portSetupInfo = portSetupInfo.referenceType === NEARBY_PINS ? { ...portSetupInfo, ...nearbyPinsSetup(portSetupInfo) } : { ...portSetupInfo };
    }
    return _portSetupInfo;
  }

  initDebugMode = () => {
    let debugMode = cookie.load('debugMode');
    if (!debugMode) {
      cookie.save('debugMode', false, {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      });
    } else {
      if (debugMode === 'false') {
        debugMode = false;
      } else if (debugMode === 'true') {
        debugMode = true;
      }
      this.setState({
        defaultChecked: debugMode,
      })
    }
  }

  initSIWEmode = () => {
    let SIWEmode = cookie.load('UseSiWaveSolverExplicitly');
    if (SIWEmode === 'off') {
      SIWEmode = 'off';
    } else {
      SIWEmode = 'on';
    }
    cookie.save('UseSiWaveSolverExplicitly', SIWEmode, {
      maxAge: 7 * 24 * 60 * 60,
      path: '/'
    });
    this.setState({
      defaultSIWE: SIWEmode
    })
  }

  initCouplingMinFreq = () => {
    let couplingMinFreqValue = cookie.load('couplingMinFreq')
    let couplingMinFreqUnit, couplingMinFreqNum;
    if (!couplingMinFreqValue) {
      couplingMinFreqNum = '3.5'
      couplingMinFreqUnit = 'MHz';
      couplingMinFreqValue = `${couplingMinFreqNum}${couplingMinFreqUnit}`
    } else {
      couplingMinFreqUnit = couplingMinFreqValue.match(/[a-z|A-Z]+$/gi)
      couplingMinFreqNum = parseFloat(couplingMinFreqValue)
    }
    cookie.save('couplingMinFreq', couplingMinFreqValue, {
      maxAge: 7 * 24 * 60 * 60,
      path: '/'
    })
    this.setState({
      couplingMinFreqValue: couplingMinFreqNum,
      couplingMinFreqUnit: couplingMinFreqUnit
    })
  }

  handleChange = (checked) => {
    cookie.save('debugMode', checked, {
      maxAge: 7 * 24 * 60 * 60,
      path: '/'
    });
    this.setState({
      error: null
    })
  }

  resolutionChange = (e) => {
    const { userDefaultSetting } = this.state
    this.setState({
      userDefaultSetting: {
        ...userDefaultSetting,
        platformSettings: {
          ...userDefaultSetting.platformSettings,
          renderMode: e.target.value
        }
      }
    })
  }

  optionChangeHandler = (e, option, parentOption) => {
    const { pageType } = this.props;
    let settingParams = '';
    switch (pageType) {
      case ROCKY:
        settingParams = 'rockySettings';
        break;
      case CASCADE:
        settingParams = 'cascadeSettings';
        break;
      case FASTPI_PAGE:
        settingParams = 'fastpiSettings';
        break;
      default:
        break;
    }
    if (settingParams) {
      this.setState({
        [settingParams]: {
          ...this.state[settingParams],
          [option]: e.target.value
        }
      })
      return;
    }

    if (pageType === SIERRA) {
      const { sierraSettings } = this.state;
      if (parentOption) {
        this.setState({
          sierraSettings: {
            ...sierraSettings,
            resultSettings: {
              ...(sierraSettings.resultSettings || {}),
              [parentOption]: {
                ...(sierraSettings[parentOption] || {}),
                [option]: e.target.value
              }
            }
          }
        })
        return;
      }
      let portSetupInfo = sierraSettings.portSetupInfo || {};
      if (option === 'portType') {
        portSetupInfo = {
          referenceType: [WAVE, GAP].includes(e.target.value) ? ALL_PINS : SINGLE_PIN
        }
      }

      this.setState({
        sierraSettings: {
          ...sierraSettings,
          [option]: e.target.value,
          portSetupInfo
        }
      })
    }
  }

  switchChangeHandler = (checked, option) => {
    const { pageType } = this.props;
    let settingParams = '';
    switch (pageType) {
      case SIERRA:
        settingParams = 'sierraSettings';
        break;
      case ROCKY:
        settingParams = 'rockySettings';
        break;
      case CASCADE:
        settingParams = 'cascadeSettings';
        break;
      case FASTPI_PAGE:
        settingParams = 'fastpiSettings';
        break;
      default:
        break;
    }
    if (settingParams) {
      this.setState({
        [settingParams]: {
          ...this.state[settingParams],
          [option]: checked
        }
      })
    }
  }

  panelClose = async () => {
    if (this.state.error) { return }
    const { emailNotification } = this.state;
    const { pageType } = this.props
    try {
      if (emailNotification && emailNotification.userId) {
        await notificationUserEditPromise({ ...emailNotification });
      }
      // save user default setting
      let { userDefaultSetting, sierraSettings, rockySettings, fastpiSettings, cascadeSettings } = this.state
      if (pageType === SIERRA) {
        const portSetupInfo = sierraSettings.portSetupInfo;
        if (portSetupInfo && portSetupInfo.referenceType === NEARBY_PINS) {
          sierraSettings.portSetupInfo = {
            ...portSetupInfo,
            pinDistance: `${portSetupInfo.pinDistance}${portSetupInfo.pinDistanceUnit}`,
          }
          delete sierraSettings.portSetupInfo.pinDistanceUnit;
        }
        userDefaultSetting = {
          ...userDefaultSetting,
          sierraSettings
        }
      }

      if (pageType === ROCKY) {
        userDefaultSetting = {
          ...userDefaultSetting,
          rockySettings
        }
      }
      if (pageType === FASTPI_PAGE) {
        userDefaultSetting = {
          ...userDefaultSetting,
          fastpiSettings
        }
      }
      if (pageType === CASCADE) {
        userDefaultSetting = {
          ...userDefaultSetting,
          cascadeSettings
        }
      }
      this.updateSettingHandler()
      userDefaultSettings.updateUserDefaultSettings(userDefaultSetting)
    } catch (e) {
      throw Error(e)
    } finally {
      this.props.closeModal();
    }
  }

  updateSettingHandler = () => {
    const { pageType } = this.props
    if (pageType === CASCADE) {
      const { cascadeSettings, prevShowFullPowerTree } = this.state
      const { impedanceOverviewDisplay, impedanceComponentsTableDisplay, showFullPowerTree } = cascadeSettings
      this.props._saveImpedancewDisplay(impedanceOverviewDisplay, impedanceComponentsTableDisplay)
      this.props._saveTemplateDisplay(impedanceComponentsTableDisplay);
      if (prevShowFullPowerTree !== showFullPowerTree) {
        this.props.updateFullPowerTreeDisplay()
      }
    }
  }

  ChangeSetup = (checked) => {
    cookie.save('setupMode', checked, {
      maxAge: 7 * 24 * 60 * 60,
      path: '/'
    });
  }

  ChangeLayout = (checked) => {
    cookie.save('layoutMode', checked, {
      maxAge: 7 * 24 * 60 * 60,
      path: '/'
    });
  }

  changeAdvanced = (e) => {
    e.stopPropagation();
    const newAdvanced = !this.state.advanced;
    this.setState({
      advanced: newAdvanced
    })
  }

  changeCouplingMinFreq = (e) => {
    const value = e.target.value
    this.setState({
      couplingMinFreqValue: value,
      error: null
    })
  }

  changeSIWE = (checked) => {
    let mode = 'on';
    if (checked) {
      mode = 'on';
    } else {
      mode = 'off';
    }
    cookie.save('UseSiWaveSolverExplicitly', mode, {
      maxAge: 7 * 24 * 60 * 60,
      path: '/'
    });
    this.props._saveCurrentPDNBySetting()
    this.setState({
      error: null
    })
  }

  showFastPISetting = () => {
    const { pageType } = this.props;
    return pageType === FASTPI_PAGE;
  }

  showDevelopMode = () => {
    return this.props.email && this.props.email.includes('@aurora-system.com'); // aurora only
  }

  blurPowerTreeLevel = (e) => {
    const value = e && e.target.value;
    const error = numberCheck(value);
    if (!error) {
      this.setState({
        cascadeSettings: {
          ...this.state.cascadeSettings,
          powerTreeLevel: value
        }
      })
    }
  }

  blurImpedanceLevel = (e) => {
    const value = e && e.target.value;
    const error = numberCheck(value);
    if (!error) {
      this.setState({
        cascadeSettings: {
          ...this.state.cascadeSettings,
          impedanceLevel: value
        }
      })
    }
  }

  couplingMinSelectAfter = () => {
    return <Select
      dropdownStyle={{ zIndex: 100000 }}
      defaultValue={this.state.couplingMinFreqUnit}
      onChange={(e) => this.changeUnit(e)}
      style={{ width: 65 }}
      popupClassName="fastpi-simulate-select-dropdown"
    >
      <Option value="MHz">MHz</Option>
    </Select>
  };

  changeUnit = (key) => {
    this.setState({
      couplingMinFreqUnit: key
    })
  }

  inputBlur = (e) => {
    const { couplingMinFreqUnit, couplingMinFreqValue } = this.state
    const _couplingMinFreqValue = `${couplingMinFreqValue}${couplingMinFreqUnit}`
    if (couplingMinFreqValue < 1 || couplingMinFreqValue > 100) {
      e.target.focus();
      this.setState({
        error: 'Please set Intra-coupling Valid Frequency value from 1MHz to 100MHz.'
      })
    } else {
      cookie.save('couplingMinFreq', _couplingMinFreqValue, {
        maxAge: 7 * 24 * 60 * 60,
        path: '/'
      })
      this.props._saveCurrentPDNBySetting()
    }
  }

  updateEmailNotification = (emailNotification) => {
    this.setState({
      emailNotification
    })
  }

  updateEmailValue = (receiveEmailValue) => {
    this.setState({
      receiveEmailValue
    })
  }

  updateUserDefaultSettingVisible = () => {
    const { userDefaultSettingVisible } = this.state

    this.setState({
      userDefaultSettingVisible: !userDefaultSettingVisible
    })
  }

  extractionSetting = (simulator, extractionOption, portType, displayMode) => {
    const { pageType } = this.props;
    return <Fragment>
      <span className='user-default-setting-item'>Simulator Option</span>
      <div className='develop-content-render'>
        <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'simulator')} value={simulator}>
          <Radio value={AURORA_NGSPICE}><span className='develop-content-radio'>Aurora NGSPICE</span></Radio>
          <Radio value={HSPICE}><span className='develop-content-radio'>HSPICE</span></Radio>
          <Radio value={ANSYSDesigner}><span className='develop-content-radio'>Ansys Designer</span></Radio>
        </Radio.Group>
      </div>
      <span className='user-default-setting-item'>Extraction Option</span>
      <div className='develop-content-render'>
        <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'extractionOption')} value={extractionOption}>
          <Radio value={BUILT_IN}><span className='develop-content-radio'>Built-in</span></Radio>
          <Radio value={ANSYS_SIWAVE}><span className='develop-content-radio'>Ansys-SIwave</span></Radio>
          <Radio value={ANSYS_HFSS}><span className='develop-content-radio'>Ansys-HFSS</span></Radio>
        </Radio.Group>
      </div>
      <span className='user-default-setting-item'>Extraction Port Type</span>
      <div className='develop-content-render'>
        <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'portType')} value={portType}>
          <Radio value={PIN_GROUP}><span className='develop-content-radio'>Pin Group</span></Radio>
          <Radio value={GAP}><span className='develop-content-radio'>Gap</span></Radio>
          <Radio value={CIRCUIT}><span className='develop-content-radio'>Circuit</span></Radio>
          <Radio value={WAVE}><span className='develop-content-radio'>Wave</span></Radio>
        </Radio.Group>
      </div>
      {pageType === SIERRA ?
        <Fragment>
          {this.sierraPortSetupRender()}
          <span className='user-default-setting-item'>Waveform Display Mode</span>
          <div className='develop-content-render'>
            <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'displayMode', "waveform")} value={displayMode}>
              <Radio value={'Fast'}>Fast</Radio>
              <Radio value={'Hi-Re'}>Hi-Re</Radio>
            </Radio.Group>
          </div>
          {this.sierraUsageSetupRender()}
        </Fragment> : null}
    </Fragment>
  }

  fastpiExtractionRender = (multiCapModelAssignment) => {
    return <Fragment>
      <span className='user-default-setting-item'>Multi Cap Model Assignment</span>
      <div className='develop-content-render'>
        <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'multiCapModelAssignment')} value={multiCapModelAssignment}>
          <Radio value={SWEEP}><span className='develop-content-radio'>Sweep Cap Models</span></Radio>
          <Radio value={PARALLEL}><span className='develop-content-radio'>Parallel Cap Models</span></Radio>
        </Radio.Group>
      </div>
    </Fragment>
  }

  cascadeSettingRender = (powerTreeLevel, impedanceLevel, impedanceOverviewDisplay, impedanceComponentsTableDisplay, showFullPowerTree) => {
    return <Fragment>
      <div className='user-default-setting-div'>
        <span className='user-default-setting-item'>Use Full PCB Power Tree</span>
        <Switch
          size="small"
          className="aurora-switch-small"
          defaultChecked={showFullPowerTree}
          onChange={(checked) => this.switchChangeHandler(checked, 'showFullPowerTree')}
        />
      </div>
      <div className='user-default-setting-div'>
        <span className='user-default-setting-item'>Power Tree Max Trace Level</span>
        <Input
          className='aurora-input level-setting-input'
          defaultValue={powerTreeLevel}
          onBlur={(e) => this.blurPowerTreeLevel(e)}
        />
      </div>
      <div className='user-default-setting-div'>
        <span className='user-default-setting-item'>V/Z Explorer Max Trace Level</span>
        <Input
          className='aurora-input level-setting-input'
          defaultValue={impedanceLevel}
          onBlur={(e) => this.blurImpedanceLevel(e)}
        />
      </div>
      <div className='user-default-setting-div'>
        <span className='user-default-setting-item'>Z Explorer Overview</span>
        <div className='develop-content-render'>
          <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'impedanceOverviewDisplay')} value={impedanceOverviewDisplay}>
            <Radio value={DIE_PACKAGE_PCB}><span className='develop-content-radio'>Die-Packge-PCB</span></Radio>
            <Radio value={PCB_PACKAGE_DIE}><span className='develop-content-radio'>PCB-Packge-Die</span></Radio>
          </Radio.Group>
        </div>
      </div>
      <div className='user-default-setting-div'>
        <span className='user-default-setting-item'>Z Explorer Components Table</span>
        <div className='develop-content-render'>
          <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'impedanceComponentsTableDisplay')} value={impedanceComponentsTableDisplay}>
            <Radio value={PARTBASED}><span className='develop-content-radio'>Part Based</span></Radio>
            <Radio value={COMPONENTBASED}><span className='develop-content-radio'>Component Based</span></Radio>
          </Radio.Group>
        </div>
      </div>
    </Fragment>
  }

  userDefaultSettingContent = () => {
    const { pageType } = this.props
    if (pageType === SIERRA) {
      const { sierraSettings: { simulator, extractionOption, portType, resultSettings: { waveform } } } = this.state;
      const displayMode = waveform ? waveform.displayMode : "";
      return (
        <div className='user-default-setting-content'>
          {this.extractionSetting(simulator, extractionOption, portType, displayMode)}
        </div>
      )
    }
    if (pageType === ROCKY) {
      const { rockySettings: { simulator, extractionOption, portType } } = this.state;
      return (
        <div className='user-default-setting-content'>
          {this.extractionSetting(simulator, extractionOption, portType)}
        </div>
      )
    }

    if (pageType === FASTPI_PAGE) {
      const { fastpiSettings: { multiCapModelAssignment } } = this.state;
      return <div className='user-default-setting-content'>
        {this.fastpiExtractionRender(multiCapModelAssignment)}
      </div>
    }

    if (pageType === CASCADE) {
      const { cascadeSettings: { powerTreeLevel, impedanceLevel, impedanceOverviewDisplay, impedanceComponentsTableDisplay, showFullPowerTree } } = this.state;
      return <div className='user-default-setting-content'>
        {this.cascadeSettingRender(powerTreeLevel, impedanceLevel, impedanceOverviewDisplay, impedanceComponentsTableDisplay, showFullPowerTree)}
      </div>
    }
  }

  sierraPortSetupRender = () => {
    const { sierraSettings: { portType, portSetupInfo = {} } } = this.state;
    return <UserSettingPortSetupRender
      portType={portType}
      portSetupInfo={portSetupInfo}
      updatePortSetupInfo={this.updatePortSetupInfo}
    />
  }

  sierraUsageSetupRender = () => {
    const { sierraSettings: { ioBufferUsage } } = this.state;
    return <Fragment>
      <span className='user-default-setting-item user-default-setting-title-item'>Default usage of pins for the I/O buffer model</span>
      <div className='develop-content-render'>
        <Radio.Group onChange={(e) => this.optionChangeHandler(e, 'ioBufferUsage')} value={ioBufferUsage}>
          <Radio value={'Driver'}>Driver</Radio>
          <Radio value={'Receiver'}>Receiver</Radio>
        </Radio.Group>
      </div>
    </Fragment>
  }

  updatePortSetupInfo = (portSetupInfo) => {
    const { sierraSettings } = this.state;
    this.setState({
      sierraSettings: {
        ...sierraSettings,
        portSetupInfo: {
          ...portSetupInfo
        }
      }
    })
  }

  render() {
    const { defaultChecked, userDefaultSetting, sysVersion, advanced,
      couplingMinFreqValue, error, emailNotification, receiveEmailValue, notificationProducts, userDefaultSettingVisible } = this.state;
    const { pageType } = this.props
    const FastPISetting = this.showFastPISetting();
    const developModeVisible = this.showDevelopMode();
    const resolution = userDefaultSetting && userDefaultSetting.platformSettings ? userDefaultSetting.platformSettings.renderMode : FAST;
    let footer;
    footer = <Fragment>
      <span className='setting-version'><span style={{ fontWeight: "bold" }}>Version:</span> {sysVersion}</span>
    </Fragment>
    const Icon = userDefaultSettingVisible ? DownOutlined : RightOutlined;
    return (
      <Panel
        className='develop-panel'
        title={<div className='development-name'>Settings</div>}
        onCancel={this.panelClose}
        zIndex={2000}
        width={650}
        position='panel-center-top'
        mask={true}
        footer={footer}
        draggable
        minWidth={200}
        minHeight={250}
      >
        <div className='develop-content'>
          <div className='develop-content-item'>
            <span className='develop-content-title'>Render Mode</span>
            <div className='develop-content-render'>
              <Radio.Group onChange={this.resolutionChange} value={resolution}>
                <Radio value={FAST}><span className='develop-content-radio'>Fast</span></Radio>
                <Radio value={MEDIUM}><span className='develop-content-radio'>Medium</span></Radio>
                <Radio value={ORIGINAL}><span className='develop-content-radio'>Original</span></Radio>
              </Radio.Group>
            </div>
          </div>
          {developModeVisible &&
            <div className='develop-content-item'>
              <div className='develop-content-title'>Development Mode</div>
              <span className='develop-switch'>
                <Switch
                  size="small"
                  className="aurora-switch-small"
                  defaultChecked={defaultChecked}
                  onChange={this.handleChange}
                />
              </span>
            </div>
          }
          {FastPISetting && <Fragment>
            {/* <div className='develop-content-item'>
              <div className='translation-content-title'>Use SIwave Solver Explicitly</div>
              <span className='translation-switch'>
                <Switch
                  size="small"
                  className="aurora-switch-small"
                  defaultChecked={defaultSIWE === 'on'}
                  onChange={this.changeSIWE}
                />
              </span>
            </div> */}
            <div className='develop-content-item'>
              <div className="translation-content-title translation-content-title-cursor" onClick={(e) => this.changeAdvanced(e)}>
                {advanced ? <DownOutlined className='user-setup-panel-advanced-icon' /> : <RightOutlined className='user-setup-panel-advanced-icon' />}
                <span className='user-setup-panel-advanced'>Advanced</span>
              </div>
              {advanced && <div className="user-setup-panel-advanced-box">
                <div className='translation-content-title user-setup-couplingMinFreqValue'>Intra-Plane Coupling Valid Minimum Frequency</div>
                <Input
                  className="translation-content-input"
                  defaultValue={couplingMinFreqValue}
                  addonAfter={this.couplingMinSelectAfter()}
                  onBlur={(e) => this.inputBlur(e)}
                  onChange={(e) => this.changeCouplingMinFreq(e)} />
              </div>}
            </div>
            {error && <span className='translation-error-msg'>Error: {error}</span>}
          </Fragment>}
          {
            <EmailSetting
              emailNotification={emailNotification}
              receiveEmailValue={receiveEmailValue}
              notificationProducts={notificationProducts}
              updateEmailNotification={this.updateEmailNotification}
              updateEmailValue={this.updateEmailValue}
            />
          }
          {([ROCKY, SIERRA, FASTPI_PAGE, CASCADE].includes(pageType)) &&
            <Fragment>
              <div className='develop-content-item ' >
                <label onClick={this.updateUserDefaultSettingVisible}>
                  <Icon />
                  <div className='user-default-setting-title'>User Default Setting</div>
                </label>
              </div>
              {userDefaultSettingVisible && this.userDefaultSettingContent()}
            </Fragment>
          }
        </div>
      </Panel >
    );
  }
}

const mapState = (state) => {
  const { LoginReducer } = state;
  const { email } = LoginReducer;
  return {
    email
  }
}

const mapDispatch = (dispatch) => ({
  _saveCurrentPDNBySetting() {
    dispatch(saveCurrentPDNBySetting())
  },
  _saveImpedancewDisplay(overviewDisplay, compTableDisplay) {
    dispatch(saveImpedanceDisplay(overviewDisplay, compTableDisplay))
  },
  _saveTemplateDisplay(compTableDisplay) {
    dispatch(saveTemplateDisplay(compTableDisplay))
  },
  updateFullPowerTreeDisplay() {
    dispatch(updateFullPowerTreeDisplay())
  }
})

export default connect(mapState, mapDispatch)(SetupPanel);