import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { Select, Button, Progress, TreeSelect, message, Radio } from "antd";
import Panel from "@/components/Panel";
import { updatePDNReportConfig, getPDNReport, updatePDNReoprtMessage, updatePDNReportVisible, updatePDNProgress, clearReportInfo } from '../../../store/project/action';
import { generatePDNReportNew, postPDNReportConfigData } from '@/services/PDN/projectCtrl';
import ReportTemplate from '@/components/ReportTemplate/reportTemplate'
import DelConfirm from '@/components/DelConfirm';
import { getPanelMaxHeight, getPanelMaxWidth, getPanelWidth } from '@/services/helper/panelSizeHelper';
import { getReportPdnIds } from '@/services/PDN/reportHelper'

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const COMPLETE = 'complete', SHORT = 'short';

function getTreeData(verifications) {
  return [{
    title: 'All',
    value: 'all',
    key: 'all',
    children: verifications.map(name => ({ title: name, value: name, key: name }))
  }]
}

class PDNReportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reportType: "PPTX",
      nowThumbnail: 1,
      maxLength: 1,
      templateList: [],
      viewPanel: null,
      visiablePanel: false,
      closeVisible: false,
      maxWidth: 700,
      maxHeight: 320,
      reportVersion: COMPLETE,
      downloadLoading: true
    }
    this.dialogRoot = document.getElementById('root');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    this.setState({
      maxHeight: getPanelMaxHeight(offset, 320),
      maxWidth: getPanelMaxWidth(offset, 700)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  closeModalPanel = () => {
    const { reportVisible } = this.props;
    if (reportVisible) {
      this.setState({
        closeVisible: true,
      })
    } else {
      this.props._updateReportVisible(false);
      this.props.closeModal()
      this.props._clearReportInfo();
    }
  }

  onMinimize = () => {
    this.props.closeModal();
    this.props.miniConfig()
  }

  deleteConfirm = () => {
    this.props.closeModal();
    this.props._updateReportVisible(false);
    this.props._clearReportInfo();
  }

  cancelDel = () => {
    this.setState({
      closeVisible: false
    })
  }

  onTreeChange = (value) => {
    const { reportConfig } = this.props;
    let config = { ...reportConfig };
    config.verificationSelect = value;
    this.props._updatePDNReportConfig(config);
    this.props._updatePDNReoprtMessage("")
  }

  FileValueChange = (value) => {
    this.setState({
      reportType: value
    })
    this.props._updatePDNReoprtMessage("")
  }

  downloadPDNReport = () => {
    const { reportConfig, currentProjectPDNs, projectList } = this.props;
    const { reportType, reportVersion } = this.state;
    this.props._updateReportVisible(true);
    const { projectId, verificationSelect, templateId } = reportConfig;
    const find = projectList.find(item => item.id === projectId);
    let projectName = find ? find.name : "";
    let mime;
    let format = 'pptx';
    if (reportType === 'PDF') {
      format = 'pdf';
      mime = 'application/pdf';
    } else if (reportType === 'PPTX') {
      format = 'pptx';
      mime = 'application/vnd.ms-powerpoint';
    }
    this.props._updatePDNReportProgress(0);
    const config = {
      projectId: projectId,
      language: "english",
      format: reportType,
      pdnIds: getReportPdnIds(verificationSelect, currentProjectPDNs),
      templateId: templateId,
      version: reportVersion
    }
    generatePDNReportNew(projectId, config).then((res) => {
      this.props._getPDNReport({ projectId: projectId, format, mime, fileName: projectName })
    }, error => {
      message.error(`Download report in ${reportType} format failed! ` + error)
      this.props._updateReportVisible(false);
    })
    postPDNReportConfigData(config)
  }

  PDNReportConfig = (config) => {
    this.props._updatePDNReportConfig(config)

    if (this.state.downloadLoading) {
      this.setState({
        downloadLoading: false
      })
    }
  }

  PDNReoprtMessage = (message) => {
    this.props._updatePDNReoprtMessage(message)
  }

  versionChange = (e) => {
    this.setState({
      reportVersion: e.target.value
    })
  }

  render() {
    const { reportType, closeVisible, maxWidth, maxHeight, reportVersion, downloadLoading } = this.state;
    const { currentProjectPDNs, reportVisible, reportConfig, reportProgress, reportMessage, projectId } = this.props;
    const messageClassName = reportMessage && reportMessage.indexOf('successfully') > -1 ? 'download-success' : 'download-error';
    const verifications = currentProjectPDNs ? currentProjectPDNs.map(d => d.name).filter(name => name !== 'PDN') : [];
    const treeData = getTreeData(verifications)
    const { verificationSelect } = reportConfig;
    const content = (
      <Fragment>
        <Panel
          className='PDN-top-sus-report-panel PDN-x-scroll-hidden'
          title={<div className='PDN-top-sus-report-title'>Report Generation Options</div>}
          onCancel={this.closeModalPanel}
          onMinimize={this.onMinimize}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { minWidth: 270, defaultWidth: 700 })}
          minWidth={270}
          position='panel-center'
          draggable
          mask={true}
          minHeight={200}
          minimizeVisible={reportVisible ? true : false}
          maxHeight={maxHeight}
          overflow={"auto"}
        >
          <div className='top-sus-report-content clear' id="report-content-id">
            <div className="PDN-report-content-flex">
              <label className="PDN-report-item-title">PDN</label>
              <TreeSelect
                value={verificationSelect}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={treeData}
                treeCheckable={true}
                className='PDN-report-select'
                placeholder="Please select"
                showCheckedStrategy={SHOW_PARENT}
                onChange={this.onTreeChange}
                popupClassName='PDN-report-select-dropdown'
              />
            </div>
            <div>
              <label className="PDN-report-item-title">File Type</label>
              <Select
                value={reportType}
                onChange={(value) => this.FileValueChange(value)}
                className="PDN-report-select"
                popupClassName="PDN-report-select-dropdown"
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Option key="PPTX">PPTX</Option>
                <Option key="PDF">PDF</Option>
              </Select>
            </div>
            <div>
              <label className="PDN-report-item-title">Version</label>
              <Radio.Group
                value={reportVersion}
                onChange={this.versionChange}
                className="PDN-report-select"
              >
                <Radio value={COMPLETE}>Complete</Radio>
                <Radio value={SHORT}>Short</Radio>
              </Radio.Group>
            </div>
            <div style={{ marginTop: 5 }}>
              {projectId === reportConfig.projectId &&
                <ReportTemplate reportConfig={this.props.reportConfig} updateReportConfig={this.PDNReportConfig} updateReoprtMessage={this.PDNReoprtMessage} type="FASTPI"></ReportTemplate>
              }
            </div>
            <div className='PDN-top-report-message-main .clear'>
              {!reportVisible ?
                <Fragment>
                  {reportMessage ? <div className={`${messageClassName} PDN-report-download-message`}>{reportMessage}</div> : null}
                  <div className='PDN-report-download-button clear'>
                    <Button
                      title={"download"}
                      type="primary"
                      ghost
                      disabled={reportVisible || downloadLoading}
                      onClick={() => this.downloadPDNReport()}
                      className='PDN-report-download-btn'
                    >Download</Button>
                  </div>
                </Fragment> :
                <Progress
                  size={{ height: 10 }}
                  className='PDN-top-report-progress'
                  percent={reportProgress}
                />
              }
            </div>
          </div>
        </Panel>
        {
          closeVisible ? <DelConfirm
            data={null}
            maskStyle={true}
            deleteConfirm={this.deleteConfirm}
            cancelDel={this.cancelDel}
            message={'After closing the panel, the download report will end. Are you sure you want to close it?'}
          /> : null
        }
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}

const PDNReportState = (state) => {
  const { PDNReducer: { project: { currentProjectId, reportConfig, currentProjectPDNs, projectList, reportMessage, reportVisible, reportProgress } } } = state;
  return {
    projectId: currentProjectId,
    reportConfig,
    currentProjectPDNs,
    projectList,
    reportVisible,
    reportMessage,
    reportProgress
  }
}

const PDNReportDispatch = (dispatch) => ({
  _updatePDNReportConfig(reportConfig) {
    dispatch(updatePDNReportConfig(reportConfig))
  },
  _getPDNReport({ projectId, format, mime, fileName }) {
    dispatch(getPDNReport({ projectId, format, mime, fileName }));
  },
  _updateReportVisible(visible) {
    dispatch(updatePDNReportVisible(visible))
  },
  _updatePDNReportProgress(progress) {
    dispatch(updatePDNProgress(progress))
  },
  _updatePDNReoprtMessage(message) {
    dispatch(updatePDNReoprtMessage(message))
  },
  _clearReportInfo() {
    dispatch(clearReportInfo())
  }
})

export default connect(PDNReportState, PDNReportDispatch)(PDNReportPanel);