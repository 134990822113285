import { takeEvery, select, put, call, delay } from "redux-saga/effects";
import {
  CHANGE_MULTI_SETUP_PAGE,
  UPDATE_MULTI_INTERFACE_SETUP_STATUS,
  GET_MULTI_SETUP_BUFFER_DATA,
  GET_MULTI_SETUP_PASSIVE_DATA,
  SAVE_IC_COMP_MODEL,
  SAVE_IC_PIN_MODEL,
  SAVE_PASSIVE_MODEL,
  SAVE_PIN_VALUE,
  SAVE_POWER_OFF,
  UPDATE_COMPONENT_PIN_USAGE,
  UPDATE_PASSIVE_COMPONENTS,
  CHECK_SIM_INTERFACES,
  START_MULTI_SETUP_SIM,
  SAVE_SIM_CONFIG,
  CHANGE_MULTI_SETUP_GROUP,
  CHANGE_MULTI_BUFFER_PAGE,
  CHANGE_MULTI_PASSIVE_PAGE,
  GET_MULTI_INTERFACE_SETUP,
  RE_ASSIGN_BUFFER_MODEL,
  GET_MULTI_SETUP_EXTRACTION_DATA,
  SAVE_MULTI_SETUP_EXTRACTION,
  SAVE_MULTI_SETUP_EXTRACTION_PORTS,
  SAVE_SPLIT_COMPONENTS,
  SAVE_MERGE_COMPONENTS
} from "./actionTypes";
import {
  clearMultiSetupInfo,
  updateMultiSetupLoading,
  updateMultiSetupPageData,
  updateMultiSetupBufferData,
  updateMultiSetupPassiveData,
  updateMultiSetupGroup,
  updateMultiSetupSimErrorMsg,
  updateMultiSetupExtraction,
  updateMultiSetupExtractionPorts,
  updatePCBLoadingStatus,
  updateMultiSetupExtractionStatus,
  updateMultiSetupBufferModelLoading,
  clearMultiSetupExtraction,
  clearMultiSetupExtractionPorts,
  updateMultiSetupExtractionPortsLog,
  updateMultiSetupExtractionOptionsLog,
  updateMultiSetupExtractionApply,
  updateMultiExtrPCBSelectStatus
} from './action';
import { changeTreeSelected, changeViewList } from "../project/action";
import {
  MULTI_INTERFACE_SETUP,
  PCB,
  PCB_PRE_LAYOUT,
  RESULT,
  VERIFICATION,
  EXPERIMENTS_RESULT,
  VERIFICATION_GROUP
} from "../../../../constants/treeConstants";
import { strDelimited } from "../../../../services/helper/split";
import {
  getMultiSetupDataPromise,
  getMultiSetupData,
  getMultiSetupBufferDataPromise,
  getMultiSetupPassiveDataPromise,
  getMultiBufferData,
  getMultiPassiveData,
  saveMultiBufferDataPromise,
  saveMultiPassiveDataPromise,
  ALL_INTERFACES,
  updateMultiSetupSimConfig,
  getExtractionOptions,
  getSimConfig,
  getExtractionPorts,
  saveExtractionOptions,
  saveExtractionPorts,
  MultiPassiveTableItem,
  getExtractionApplyConfig,
  updateExtractionApplyConfig
} from "../../../../services/Sierra/multiInterfaceSetup";
import multiSetupBufferDataBase, { multiSetupPassiveDataBase } from "../../../../services/Sierra/multiInterfaceSetup/multiSetupBufferDataBase";
import { getPins } from "../../../../services/Sierra/library/IbisModelHelper";
import { ONLY_DRIVER_MODEL_TYPES, INPUT } from '@/services/LibraryHelper';
import { startSierraVerification } from "../simulation/action";
import { getPartNumber } from "../../../../services/PCBHelper";
import LayoutData from "../../../../services/data/LayoutData";
import { autoMatchBufferModelByPart, findComponentByPin, getBufferModelByPart, getLayoutDB } from "../sierra/saga";
import { updateSierraContent } from "../sierra/action";
import { changeTabMenu, openTabFooter } from "../../../tabMonitor/action";
import { Extraction, judgeModelUpdate, reExtraction, setDefaultSpiceBufferModel, updateICPinModels, updateICPinModelsByBuffer } from "../../../../services/Sierra";
import designConstructor from "../../../../services/helper/designConstructor";
import { PRE_LAYOUT } from "../../../../constants/designVendor";
import {
  getExtractionPortsSetup,
  judgeUpdatePowerSISetup,
  getDefaultPortsGenerateSetupList
} from "../../../../services/Sierra/helper/extractionPortsHelper";
import {
  CIRCUIT,
  judgeUpdateGapPortGapSizeSetup,
  PIN_GROUP,
  PortsGenerationSetup,
  updateHFSSExtractionCompsGapSize,
  updateSetupComponentsByPortType
} from "../../../../services/ExtractionPortsHelper";
import DesignInfo from '@/services/Sierra/pcbInfo';
import { getIbisModelList } from "../../../../services/Sierra/library";
import { IC, UNUSED } from "../../constants";
import componentSetting from '@/services/helper/componentsHelper/compSettingHelper';
import { SINGLE_PAGE_LAYOUT } from "../../../../constants/layoutConstants";
import multiSetupDataBase from "../../../../services/Sierra/multiInterfaceSetup/multiInterfaceDataBase";
import _ from "lodash";
import { userDefaultSettings } from "../../../../services/userDefaultSetting/userDefaultSettingCtrl";
import compTableHelper from "../../../../services/Sierra/helper/compTableHelper";
/* import {
  applySignalModels
} from '@/services/Sierra'; */

function* _openMultiInterfaceSetup(action) {
  const { group, close = false } = action;
  const { SierraReducer: { project: { viewList, selectedKeys, layout } } } = yield select();

  if (viewList.includes(MULTI_INTERFACE_SETUP) && close) {
    yield put(clearMultiSetupInfo())
    yield put(changeViewList(viewList.filter(item => item !== MULTI_INTERFACE_SETUP)));
    let newKeys = []
    selectedKeys.forEach((item) => {
      const [key] = strDelimited(item, "-");
      if (![VERIFICATION_GROUP].includes(key)) {
        newKeys.push(item);
      }
    });
    yield put(changeTreeSelected(newKeys))
    return;
  }
  yield put(updateMultiSetupLoading(true))
  yield put(updateSierraContent({
    info: { signals: [], components: [], powerNets: [], powerComponents: [], extraction: {}, channel: {} },
    Interfaces: [],
    PCBsInInterface: [],
    connectorList: []
  }))
  //update viewList
  let _viewList = layout === SINGLE_PAGE_LAYOUT ? [] : [...viewList].filter(item => [PCB, PCB_PRE_LAYOUT].includes(item));
  yield put(changeViewList([..._viewList, MULTI_INTERFACE_SETUP]));
  //update selectedKeys remove interface page key
  let newKeys = [];
  if (selectedKeys.length) {
    newKeys = [];
    selectedKeys.forEach((item) => {
      const [key] = strDelimited(item, "-");
      if (![RESULT, VERIFICATION, EXPERIMENTS_RESULT, VERIFICATION_GROUP].includes(key)) {
        newKeys.push(item);
      }
    });
  }
  if (group !== ALL_INTERFACES) {
    newKeys.push(`${VERIFICATION_GROUP}-${group}`)
  }

  yield put(changeTreeSelected(newKeys));
  const { SierraReducer: { project: { currentProjectId }, multiInterfaceSetup: { checkLogGroup } } } = yield select();
  if (checkLogGroup !== group) {
    yield put(updateMultiSetupSimErrorMsg([], group))
  }
  yield put(changeTabMenu({
    selectKeys: ['monitor'],
    menuType: 'group',
    verificationId: group,
    projectId: currentProjectId,
    verificationName: group,
  }));
  yield put(openTabFooter());

  yield call(_getMultiSetupData, { group })
  yield put(updateMultiSetupLoading(false))
}

function* _getMultiSetupData(action = {}) {
  let { group } = action;
  try {
    const { SierraReducer: { project: { currentProjectId, }, multiInterfaceSetup: { multiSetupGroup, setupData: { page } } } } = yield select();
    let _page = page;
    if (!group) {
      group = multiSetupGroup;
    }
    if (group !== multiSetupGroup) {
      _page = 1;
    }
    const _group = group === ALL_INTERFACES ? "" : group;
    const data = yield call(getMultiSetupDataPromise, currentProjectId, _group || "");
    const { dataList, page: statePage, pageSize, pages, allDataLength, verificationIds } = getMultiSetupData({ data, page: _page });
    yield put(updateMultiSetupPageData({
      pageData: dataList,
      page: statePage,
      pageSize,
      pages,
      allDataLength,
      verificationIds
    }))
    yield put(updateMultiSetupGroup(group))
  } catch (error) {
    console.error(error)
  }
  yield put(updateMultiSetupLoading(false))
}

function* changePage(action) {
  const { page } = action;
  const { SierraReducer: { multiInterfaceSetup: { setupData: { pageSize } } } } = yield select();
  const { dataList, pages, allDataLength } = getMultiSetupData({ page, pageSize });
  yield put(updateMultiSetupPageData({
    pageData: dataList,
    page,
    pageSize,
    pages,
    allDataLength
  }))
}

function* _getBufferData(action) {
  const { verificationId } = action;
  yield put(updateMultiSetupBufferData({
    loading: true
  }));
  try {
    const { SierraReducer: { project: { currentProjectId }, multiInterfaceSetup: { multiSetupGroup } } } = yield select();
    const group = multiSetupGroup === ALL_INTERFACES ? "" : multiSetupGroup;
    const data = yield call(getMultiSetupBufferDataPromise, currentProjectId, verificationId, group || "");
    const { dataList, pages, allDataLength, page, pageSize } = getMultiBufferData({ data });
    yield put(updateMultiSetupBufferData({
      pageData: dataList,
      page,
      pageSize,
      pages,
      allDataLength,
      loading: false,
      verificationId
    }));
  } catch (error) {
    console.error(error)
    yield put(updateMultiSetupBufferData({
      loading: false
    }));
  }
}

function* _getPassiveData(action) {
  const { verificationId } = action;
  yield put(updateMultiSetupPassiveData({
    loading: true
  }));
  try {
    const { SierraReducer: { project: { currentProjectId }, multiInterfaceSetup: { multiSetupGroup } } } = yield select();
    const group = multiSetupGroup === ALL_INTERFACES ? "" : multiSetupGroup;
    const data = yield call(getMultiSetupPassiveDataPromise, currentProjectId, verificationId, group || "");
    let compPrefixLibInfo = {};
    const resList = [];
    yield* data.map(function* (item) {
      resList.push({
        id: item.pcbId,
        data: yield call(componentSetting.getSetting, { designId: item.pcbId }),
        passive: compTableHelper.getTable(item.pcbId)
      })
    })
    for (let item of resList || []) {
      if (item.data && item.data.compPrefixLib) {
        compPrefixLibInfo[item.id] = item.data.compPrefixLib;
      }
    }
    const { dataList, pages, allDataLength, page, pageSize } = getMultiPassiveData({ data, compPrefixLibInfo });
    yield put(updateMultiSetupPassiveData({
      pageData: dataList,
      page,
      pageSize,
      pages,
      allDataLength,
      loading: false
    }));
  } catch (error) {
    console.error(error)
    yield put(updateMultiSetupPassiveData({
      loading: false
    }));
  }
}

function* _saveCompModel(action) {
  const { files, pcb, component } = action;
  const { SierraReducer: { multiInterfaceSetup: { bufferData } } } = yield select();
  const allData = multiSetupBufferDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let bufferPageData = [...(bufferData.pageData || [])];
  const pageSize = bufferData.pageSize;
  let updateData = [];
  for (let item of allData) {
    if (!(item.pcb === pcb && item.component === component)) {
      continue;
    }

    updateData.push({
      interfaceIds: item.interfaceIds,
      component: item.component,
      pin: item.pin,
      componentModel: {
        files,
        pairs: []
      }
    })
    item.componentModel = {
      files: JSON.parse(JSON.stringify(files || [])),
      pairs: []
    }
  }

  bufferPageData.forEach(it => {
    if (it.pcb === pcb && it.component === component) {
      it.componentModel = {
        files: JSON.parse(JSON.stringify(files || [])),
        pairs: []
      }
    }
  });
  multiSetupBufferDataBase.setData(allData);
  multiSetupBufferDataBase.resetPage(pageSize);
  yield put(updateMultiSetupBufferData({
    pageData: bufferPageData,
  }));
  //save updateData to server
  yield call(saveMultiBufferDataPromise, updateData)
}

function* _saveCompPinModel(action) {
  let { record, model, deviceVcc, pinModelsInfo, applyAll, powerOff,/*  applySignal  */ } = action;
  //applyAll - Apply to all pins of same component and same usage
  const { SierraReducer: { multiInterfaceSetup: { bufferData } } } = yield select();
  const allData = multiSetupBufferDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  deviceVcc = deviceVcc ? parseFloat(deviceVcc).toString() : ""
  let bufferPageData = [...(bufferData.pageData || [])];
  const pageSize = bufferData.pageSize;
  let updateData = [];
  const findPin = allData.find(item => item.pcb === record.pcb && item.component === record.component && item.pin === record.pin);
  if (!findPin) {
    return;
  }
  const prevModel = findPin.model || {};
  const isModelUpdate = judgeModelUpdate(prevModel, model);
  const { pu, pd, pinModels: newPinModels } = pinModelsInfo;
  let isPowerOffUpdate = false, isPUUpdate = false;

  if (findPin.usage === "Driver") {
    isPowerOffUpdate = powerOff !== findPin.powerOff;

    const findPU = (findPin.pinModels || []).find(item => item.pinName === "nd_pu") || {};
    const findPD = (findPin.pinModels || []).find(item => item.pinName === "nd_pd") || {};
    isPUUpdate = (powerOff === "1" || model.libType === "SPICE") && (pu !== findPU.voltage || pd !== findPD.voltage);
  }

  if (!isModelUpdate && !isPowerOffUpdate && !isPUUpdate) {
    return;
  }

  const findPUIndex = (newPinModels || []).findIndex(item => item.pinName === "nd_pu");
  const findPDIndex = (newPinModels || []).findIndex(item => item.pinName === "nd_pd");
  let newPU;
  if (findPUIndex > -1) {
    newPU = newPinModels[findPUIndex].voltage;
    newPinModels[findPUIndex].voltage = (powerOff === "1" || model.libType === "SPICE") && pu ? pu : newPinModels[findPUIndex].voltage;
  }
  if (findPDIndex > -1) {
    newPinModels[findPDIndex].voltage = (powerOff === "1" || model.libType === "SPICE") && pd ? pd : newPinModels[findPDIndex].voltage;
  }

  for (let item of allData) {
    if (!
      (item.pcb === record.pcb
        && item.component === record.component
      )) {
      continue;
    }

    let updatePin = false;
    const IBISComponent = model.libraryId ? yield call(findComponentByPin, model.libraryId, item.pin) : "";
    const prevFile = item.componentModel && item.componentModel.files && item.componentModel.files[0];
    const componentModel = model.libraryId ? {
      files: [{
        type: model.libType,
        libraryId: model.libraryId,
        component: IBISComponent || (prevFile && prevFile.libraryId === model.libraryId ? prevFile.component : ''),
        fileName: model.fileName
      }]
    } : { files: [] };
    if (!(
      item.pin === record.pin
      || (applyAll && item.usage === record.usage)
    )) {
      updateData.push({
        interfaceIds: item.interfaceIds,
        component: item.component,
        pin: item.pin,
        componentModel: JSON.parse(JSON.stringify(componentModel))
      })
      item.componentModel = JSON.parse(JSON.stringify(componentModel))
    } else {
      updatePin = true;
      //update pin Models nd_pu,nd_pd voltage
      let pinModels = item.pinModels;
      if (pinModelsInfo && item.usage === 'Driver') {

        pinModels = updateICPinModelsByBuffer({
          pinModels,
          usage: record.usage,
          libType: model.libType,
          modelType: model.modelType,
          newPinModels
        });
      } else if (record.usage === "Receiver") {
        pinModels = [];
      }

      updateData.push({
        interfaceIds: item.interfaceIds,
        component: item.component,
        pin: item.pin,
        componentModel,
        deviceVcc,
        model: {
          ...model
        },
        pinModels,
        powerOff
      })
      item.deviceVcc = deviceVcc || newPU || pu;
      item.model = {
        ...model
      };
      item.pinModels = JSON.parse(JSON.stringify(pinModels));
      item.powerOff = powerOff;
      item.componentModel = JSON.parse(JSON.stringify(componentModel));
    }

    const index = bufferPageData.findIndex(it => it.pcb === item.pcb && it.component === item.component && it.pin === item.pin);
    if (index > -1) {
      if (updatePin) {
        bufferPageData[index].deviceVcc = deviceVcc || newPU || pu;
        bufferPageData[index].model = {
          ...model
        };
        bufferPageData[index].pinModels = JSON.parse(JSON.stringify(item.pinModels));
        bufferPageData[index].powerOff = powerOff;
      }
      bufferPageData[index].componentModel = JSON.parse(JSON.stringify(item.componentModel));
    }
  }
  multiSetupBufferDataBase.setData(allData);
  multiSetupBufferDataBase.resetPage(pageSize);
  yield put(updateMultiSetupBufferData({
    pageData: bufferPageData,
  }));
  //save updateData to server
  yield call(saveMultiBufferDataPromise, updateData)
}

function* _savePassiveModel(action) {
  const { model, pcb, pcbId, value, part } = action;
  const { SierraReducer: { multiInterfaceSetup: { passiveData } } } = yield select();
  const allData = multiSetupPassiveDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let passivePageData = [...(passiveData.pageData || [])];
  const pageSize = passiveData.pageSize;
  let updateData = [];
  for (let item of allData) {
    if (item.pcb === pcb && item.part === part && item.pcbId === pcbId) {
      updateData.push({
        ...item,
        model: {
          ...model
        },
        value
      })
      item.model = { ...model };
      item.value = value;
      const index = passivePageData.findIndex(it => it.pcb === pcb && it.part === part && it.pcbId === pcbId);
      if (index > -1) {
        passivePageData[index].model = { ...model }
        passivePageData[index].value = value
      }
    }
  }

  multiSetupPassiveDataBase.setData(allData);
  multiSetupPassiveDataBase.resetPage(pageSize);
  yield put(updateMultiSetupPassiveData({
    pageData: passivePageData
  }));
  yield call(saveMultiPassiveDataPromise, updateData)
}

function* _updatePassiveComp(action) {
  const { compsName, checked, part, pcb, pcbId, _type } = action;
  const { SierraReducer: { multiInterfaceSetup: { passiveData } } } = yield select();
  const allData = multiSetupPassiveDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let passivePageData = [...(passiveData.pageData || [])];
  const pageSize = passiveData.pageSize;
  // Update the type property of the component
  let updateData = [];
  for (let item of allData) {
    if (item.pcb === pcb && item.part === part && item.pcbId === pcbId) {
      let _components = [...item.components]
      if (Array.isArray(_components)) {
        _components.forEach(comp => {
          if (compsName.includes(comp.name)) {
            comp.type = checked ? _type : UNUSED;
          }
        })
      }
      updateData.push({
        ...item,
        components: _components,
      })
      item.components = _components;
      const index = passivePageData.findIndex(it => it.pcb === pcb && it.part === part && it.pcbId === pcbId);
      if (index > -1) {
        passivePageData[index].components = [..._components]
      }
    }
  }
  multiSetupPassiveDataBase.setData(allData);
  multiSetupPassiveDataBase.resetPage(pageSize);
  yield put(updateMultiSetupPassiveData({
    pageData: passivePageData
  }));
  yield call(saveMultiPassiveDataPromise, updateData)
}

function* _saveSplitComponents(action) {
  const { part, splitPart, comps, pcb, pcbId } = action;
  const { SierraReducer: { multiInterfaceSetup: { passiveData } } } = yield select();
  const allData = multiSetupPassiveDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let passivePageData = [...(passiveData.pageData || [])];
  const pageSize = passiveData.pageSize;
  let updateData = [], newComponents = [], _components = [], newAllData = []
  for (let item of allData) {
    if (item.pcb === pcb && item.part === part && item.pcbId === pcbId) {
      if (Array.isArray(item.components)) {
        // The components was cut in duplicate
        item.components.forEach(comp => {
          const index = comps.findIndex((c) => c.name === comp.name)
          if (index > -1) {
            newComponents.push(comp)
          } else {
            _components.push(comp)
          }
        })
      }
      updateData.push({
        ...item,
        components: _components,
      })
      updateData.push({
        ...item,
        part: splitPart,
        components: newComponents
      })
      newAllData.push({
        ...item,
        part: splitPart,
        components: newComponents
      })
      item.components = _components;
      const firstSamePcbIndex = passivePageData.findIndex(it => it.pcb === pcb);
      const index = passivePageData.findIndex(it => it.pcb === pcb && it.part === part && it.pcbId === pcbId);
      if (index > -1) {
        passivePageData[index].components = [..._components];
      }
      // Modify the number of pcbs to merge
      if (firstSamePcbIndex > -1) {
        passivePageData[firstSamePcbIndex].mergePcbLength += 1;
      }
      passivePageData.splice(index + 1, 0, new MultiPassiveTableItem({
        ...item,
        part: splitPart,
        components: newComponents,
        mergePcbLength: 0
      }))
      break;
    }
  }
  allData.push(...newAllData)
  multiSetupPassiveDataBase.setData(allData);
  multiSetupPassiveDataBase.resetPage(pageSize);
  yield put(updateMultiSetupPassiveData({
    pageData: passivePageData
  }));
  yield call(saveMultiPassiveDataPromise, updateData)
}

function* _saveMergeComponents(action) {
  const { part, partList, pcb, pcbId } = action;
  const { SierraReducer: { multiInterfaceSetup: { passiveData } } } = yield select();
  const allData = multiSetupPassiveDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let passivePageData = [...(passiveData.pageData || [])];
  const pageSize = passiveData.pageSize;
  let updateData = [], _components = [], newAllData = [], newPassivePageData = [];
  const mergeComps = allData.filter(item => partList.includes(item.part))
  // Get all of the merged components
  mergeComps.forEach((item) => {
    _components = [..._components, ...item.components];
  })
  const mergeIndex = allData.findIndex(item => item.part === part);
  if (mergeIndex > -1) {
    updateData.push({
      ...allData[mergeIndex],
      components: _components,
    });
    allData[mergeIndex].components = _components;
    newAllData = allData.filter(item => !partList.includes(item.part) || item.part === part);
  }
  // Filter the data that were merged
  const index = passivePageData.findIndex(it => it.pcb === pcb && it.part === part && it.pcbId === pcbId);
  if (index > -1) {
    passivePageData[index].components = [..._components];
    newPassivePageData = passivePageData.filter(item => !partList.includes(item.part) || item.part === part)
  }
  // Modify the number of pcbs to merge
  const firstSamePcbIndex = passivePageData.findIndex(it => it.pcb === pcb);
  const newFirstSamePcbIndex = newPassivePageData.findIndex(it => it.pcb === pcb);
  if (newFirstSamePcbIndex > -1 && firstSamePcbIndex > -1) {
    newPassivePageData[newFirstSamePcbIndex].mergePcbLength = passivePageData[firstSamePcbIndex].mergePcbLength - (partList.length - 1);
  }
  multiSetupPassiveDataBase.setData(newAllData);
  multiSetupPassiveDataBase.resetPage(pageSize);
  yield put(updateMultiSetupPassiveData({
    pageData: newPassivePageData
  }));
  yield call(saveMultiPassiveDataPromise, updateData)
}

function* _savePinModels(action) {
  const { record, pinModels, applyAll, applyAllDriver } = action;
  //applyAll - Apply to all pins of same component and same usage
  const { SierraReducer: { multiInterfaceSetup: { bufferData } } } = yield select();
  const allData = multiSetupBufferDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let bufferPageData = [...(bufferData.pageData || [])];
  let updateData = [];
  const pageSize = bufferData.pageSize;
  for (let item of allData) {
    let save = false;

    if (item.pcb === record.pcb && item.component === record.component && item.pin === record.pin) {
      item.pinModels = JSON.parse(JSON.stringify(pinModels || []));
      save = true;
    } else if (item.usage === record.usage && item.usage === "Driver"
      && (applyAllDriver
        || (applyAll && item.pcb === record.pcb && item.component === record.component))) {

      const modelType = item.model && item.model.modelType ? item.model.modelType : "I/O";
      item.pinModels = updateICPinModels({
        pinModels: item.pinModels,
        usage: item.usage,
        modelType,
        libType: item.model && item.model.libType ? item.model.libType : "",
        newPinModels: pinModels
      })
      save = true;
    }

    if (!save) {
      continue;
    }
    updateData.push({
      interfaceIds: item.interfaceIds,
      component: item.component,
      pin: item.pin,
      pinModels: item.pinModels
    })
    const index = bufferPageData.findIndex(it => it.pcb === item.pcb && it.component === item.component && it.pin === item.pin);
    if (index > -1) {
      bufferPageData[index].pinModels = JSON.parse(JSON.stringify(item.pinModels));
    }
  }
  multiSetupBufferDataBase.setData(allData);
  multiSetupBufferDataBase.resetPage(pageSize);
  yield put(updateMultiSetupBufferData({
    pageData: bufferPageData,
  }));
  //save updateData to server
  yield call(saveMultiBufferDataPromise, updateData)
}

function* _savePowerOff(action) {
  const { record, powerOff, applyAll } = action;
  const { SierraReducer: { multiInterfaceSetup: { bufferData } } } = yield select();
  const allData = multiSetupBufferDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let bufferPageData = [...(bufferData.pageData || [])];
  let updateData = [];
  const pageSize = bufferData.pageSize;
  for (let item of allData) {
    if (!(
      item.pcb === record.pcb
      && item.component === record.component
      && (
        item.pin === record.pin
        || applyAll)
    )) {
      continue;
    }

    updateData.push({
      interfaceIds: item.interfaceIds,
      component: item.component,
      pin: item.pin,
      powerOff
    });
    item.powerOff = powerOff;
    const index = bufferPageData.findIndex(it => it.pcb === record.pcb && it.component === record.component && it.pin === item.pin);
    if (index > -1) {
      bufferPageData[index].powerOff = powerOff;
    }
  }
  multiSetupBufferDataBase.setData(allData);
  multiSetupBufferDataBase.resetPage(pageSize);
  yield put(updateMultiSetupBufferData({
    pageData: bufferPageData,
  }));

  //save updateData to server
  yield call(saveMultiBufferDataPromise, updateData)
}

function* _updateCompPinUsage(action) {
  const { record } = action;
  const { SierraReducer: { multiInterfaceSetup: { bufferData }, library: { defaultBufferSpice } } } = yield select();
  const allData = multiSetupBufferDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  let bufferPageData = [...(bufferData.pageData || [])];
  const verificationId = bufferData.verificationId;
  const pageSize = bufferData.pageSize, usageApplyAll = bufferData.usageApplyAll;
  let updateData = [];
  const signalNames = usageApplyAll ? [...new Set([record.signal, ...allData.filter(item => item.pcb === record.pcb && item.component === record.component).map(item => item.signal)])] : [record.signal];
  const receiverModel = yield call(setDefaultSpiceBufferModel, { defaultBufferSpice, usage: "Receiver" })
  for (let item of allData) {

    if (signalNames.includes(item.signal)
      && verificationId
      && record.usage === "Driver"
      && ((item.pcb === record.pcb && item.component !== record.component) || item.pcb !== record.pcb)) {
      //if default spice file exist, auto assign spice receiver model
      const isSetDefault = item.componentModel && item.componentModel.files
        && Array.isArray(item.componentModel.files) && item.componentModel.files[0]
        && item.componentModel.files[0].libraryId && item.componentModel.files[0].fileName && defaultBufferSpice && item.componentModel.files[0].libraryId !== defaultBufferSpice.libraryId ? false : true;
      item.usage = "Receiver";
      //remove output model
      if (item.model && ONLY_DRIVER_MODEL_TYPES.includes(item.model.modelType)) {
        item.model = {
          ...(item.model || {}),
          modelName: "",
          modelType: "",
          enableVoltage: ""
        }
      }
      if (isSetDefault && defaultBufferSpice &&
        defaultBufferSpice.libraryId
        && (!item.model || !item.model.libraryId || !item.model.fileName || !item.model.modelName
          || ONLY_DRIVER_MODEL_TYPES.includes(item.model.modelType)
        )
      ) {
        item.model = JSON.parse(JSON.stringify(receiverModel || {}));
        if (item.model && item.model.libraryId
          && (!item.componentModel || !item.componentModel.files || !item.componentModel.files.length
            || !item.componentModel.files.find(it => !!it.libraryId))) {
          item.componentModel = {
            files: [{
              type: "SPICE",
              libraryId: item.model.libraryId,
              component: "",
              fileName: item.model.fileName,
              version: item.model.version
            }]
          }
        }
      }
      updateData.push({
        interfaceIds: item.interfaceIds,
        component: item.component,
        pin: item.pin,
        model: item.model,
        usage: item.usage,
        pinModels: [],
        deviceVcc: item.deviceVcc,
        componentModel: item.componentModel
      });
      const index = bufferPageData.findIndex(it => it.pcb === item.pcb && it.component === item.component && it.pin === item.pin);
      if (index > -1) {
        bufferPageData[index].model = JSON.parse(JSON.stringify(item.model || {}));
        bufferPageData[index].usage = item.usage;
        bufferPageData[index].pinModels = [];
        bufferPageData[index].deviceVcc = item.deviceVcc;
        bufferPageData[index].componentModel = JSON.parse(JSON.stringify(item.componentModel || {}));
      }
      continue;
    }

    if ((item.pcb !== record.pcb
      || item.component !== record.component
      || !(usageApplyAll || item.pin === record.pin))) {
      continue;
    }

    const prevUsage = item.usage;
    item.usage = record.usage;

    const prevModelType = item.model && item.model.modelType ? item.model.modelType : null;
    const shouldClear = (prevModelType && ONLY_DRIVER_MODEL_TYPES.includes(prevModelType) && record.usage === 'Receiver')
      || (prevModelType && INPUT.includes(prevModelType) && record.usage === 'Driver');

    /*  let pinModels = item.pinModels; */
    if (item.usage !== prevUsage && shouldClear) {
      item.model = {
        ...item.model,
        modelName: "",
        modelType: "",
        enableVoltage: ""
      }
    }


    if (item.usage === "Receiver" && item.pinModels && item.pinModels.length) {
      item.pinModels = []
    }

    if (item.usage === "Driver" /* && (!item.pinModels || !item.pinModels.length) */) {
      const models = yield call(getIbisModelList, { libraryId: item.model.libraryId, usage: '' }) || {}
      const modelInfo = (models.models || []).find(it => it.name === item.model.modelName) || {};
      item.deviceVcc = modelInfo && modelInfo.deviceVcc && modelInfo.deviceVcc !== "NA" ? parseFloat(modelInfo.deviceVcc).toString() : "";
      let newPinModels = !item.pinModels || !item.pinModels.length ? getPins({ type: item.model.modelType, usage: item.usage }) : item.pinModels;

      if (newPinModels.length > 0) {
        newPinModels.forEach(it => {
          if (it.pinName === 'nd_pu') {
            it.voltage = item.deviceVcc
          }
          if (it.pinName === 'nd_pd') {
            it.voltage = "0"
          }
        })
      }
      item.pinModels = newPinModels;
    }

    //if default spice file exist, auto assign spice receiver model
    const isSetDefault = item.componentModel && item.componentModel.files && Array.isArray(item.componentModel.files)
      && item.componentModel.files[0]
      && item.componentModel.files[0].libraryId && item.componentModel.files[0].fileName && defaultBufferSpice && item.componentModel.files[0].libraryId !== defaultBufferSpice.libraryId ? false : true;
    if (item.usage === "Receiver"
      && isSetDefault
      && defaultBufferSpice && defaultBufferSpice.libraryId
      && (!item.model || !item.model.libraryId || !item.model.fileName || !item.model.modelName)) {
      item.model = JSON.parse(JSON.stringify(receiverModel || {}));
      if (item.model && item.model.libraryId && (!item.componentModel || !item.componentModel.files || !item.componentModel.files.length || !item.componentModel.files.find(it => !!it.libraryId))) {
        item.componentModel = {
          files: [{
            type: "SPICE",
            libraryId: item.model.libraryId,
            component: "",
            fileName: item.model.fileName,
            version: item.model.version
          }]
        }
      }
    }

    updateData.push({
      interfaceIds: item.interfaceIds,
      component: item.component,
      pin: item.pin,
      model: item.model,
      usage: item.usage,
      pinModels: item.pinModels,
      deviceVcc: item.deviceVcc,
      componentModel: item.componentModel
    });
    const index = bufferPageData.findIndex(it => it.pcb === item.pcb && it.component === item.component && it.pin === item.pin);
    if (index > -1) {
      bufferPageData[index].model = JSON.parse(JSON.stringify(item.model || {}));
      bufferPageData[index].usage = item.usage;
      bufferPageData[index].pinModels = JSON.parse(JSON.stringify(item.pinModels || []));
      bufferPageData[index].deviceVcc = item.deviceVcc;
      bufferPageData[index].componentModel = JSON.parse(JSON.stringify(item.componentModel || {}));
    }
  }
  multiSetupBufferDataBase.setData(allData);
  multiSetupBufferDataBase.resetPage(pageSize);
  yield put(updateMultiSetupBufferData({
    pageData: bufferPageData,
  }));

  //save updateData to server
  yield call(saveMultiBufferDataPromise, updateData)
}

function* _checkedSimInterfaces(action) {
  const { checked, key } = action;
  const { SierraReducer: { multiInterfaceSetup: { setupData: { verificationIds, selectedInterfaces } } } } = yield select();
  let _selectedInterfaces = [...selectedInterfaces || []]
  if (key === "all") {
    _selectedInterfaces = checked ? [...verificationIds] : [];
  } else {
    if (checked) {
      _selectedInterfaces = [...new Set([..._selectedInterfaces, key])];
    } else {
      _selectedInterfaces = _selectedInterfaces.filter(item => item !== key);
    }
  }
  yield put(updateMultiSetupPageData({
    selectedInterfaces: _selectedInterfaces
  }))
}

function* _startSim() {
  const { SierraReducer: { multiInterfaceSetup: { setupData: { selectedInterfaces }, multiSetupGroup }, project: { currentProjectId } } } = yield select();
  if (!selectedInterfaces || !selectedInterfaces.length) {
    return;
  }
  yield put(changeTabMenu({
    selectKeys: ['monitor'],
    menuType: 'group',
    verificationId: multiSetupGroup,
    projectId: currentProjectId,
    verificationName: multiSetupGroup,
  }));
  yield put(updateMultiSetupSimErrorMsg(
    [{
      title: "",
      logs: ["==> Preparing data..."]
    }],
    multiSetupGroup
  ))
  yield put(startSierraVerification(selectedInterfaces, selectedInterfaces[0], { isMultiSim: true }));
}

function* _saveSimulationConfig(action) {
  const { config, verificationIds, prevConfig } = action;

  if (!config || !verificationIds || !verificationIds.length) {
    return;
  }
  const timeStep = (config.timeStep && config.timeStep.value ? config.timeStep.value : '') + (config.timeStep && config.timeStep.unit ? config.timeStep.unit : 'n');
  const clock = (config.clock && config.clock.value ? config.clock.value : '') + (config.clock && config.clock.unit ? config.clock.unit : 'M');
  const cycles = config.cycles ? config.cycles : '';
  const simulate = config.simulate ? config.simulate : {};
  const ngspiceMDFLM = config.ngspiceMDFLM ? config.ngspiceMDFLM : '30ps';
  const xTalk = config.xTalk !== null ? config.xTalk : 1;
  const hspiceCores = config.hspiceCores ? config.hspiceCores : 2
  const _config = {
    timeStep,
    xTalk,
    cycles,
    clock,
    simulate,
    ngspiceMDFLM,
    hspiceCores,
    fixTimeStep: config.fixTimeStep || false
  };

  prevConfig && delete prevConfig.id;
  if (_.isEqual(prevConfig || {}, _config)) {
    return;
  }
  yield call(updateMultiSetupSimConfig, {
    verificationIds: verificationIds,
    config: _config
  });
}

function* _changeGroup(action) {
  const { group } = action;
  yield put(updateMultiSetupLoading(true))
  const { SierraReducer: { project: { currentProjectId, selectedKeys } } } = yield select();
  try {
    const _group = group === ALL_INTERFACES ? "" : group;
    let newKeys = [];
    if (selectedKeys.length) {
      newKeys = [];
      selectedKeys.forEach((item) => {
        const [key] = strDelimited(item, "-");
        if (![RESULT, VERIFICATION, EXPERIMENTS_RESULT, VERIFICATION_GROUP].includes(key)) {
          newKeys.push(item);
        }
      });
    }
    if (group !== ALL_INTERFACES) {
      newKeys = [`${VERIFICATION_GROUP}-${group}`]
    }
    yield put(changeTreeSelected(newKeys))
    yield put(updateMultiSetupSimErrorMsg([], group))
    yield put(changeTabMenu({
      selectKeys: ['monitor'],
      menuType: 'group',
      verificationId: group,
      projectId: currentProjectId,
      verificationName: group,
    }));
    yield put(openTabFooter());
    const data = yield call(getMultiSetupDataPromise, currentProjectId, _group || "");
    const { dataList, page, pageSize, pages, allDataLength, verificationIds } = getMultiSetupData({ data });
    yield put(updateMultiSetupPageData({
      pageData: dataList,
      page,
      pageSize,
      pages,
      allDataLength,
      verificationIds,
      selectedInterfaces: []
    }))
    yield put(updateMultiSetupGroup(group))
    yield put(updateMultiSetupLoading(false))
  } catch (error) {
    yield put(updateMultiSetupLoading(false))
  }
  yield put(updateMultiSetupBufferData({
    pageData: [],
    page: 1,
    pageSize: 20,
    pages: 1,
    allDataLength: 20,
    loading: false,
    verificationId: null
  }));
  yield put(updateMultiSetupPassiveData({
    pageData: [],
    page: 1,
    pageSize: 20,
    pages: 1,
    allDataLength: 20,
    loading: false
  }));
}

function* _changeBufferPage(action) {
  const { page } = action;
  const { SierraReducer: { multiInterfaceSetup: { bufferData: { pageSize } } } } = yield select();
  const { dataList, pages, allDataLength } = getMultiBufferData({ page, pageSize });
  yield put(updateMultiSetupBufferData({
    pageData: dataList,
    page,
    pageSize,
    pages,
    allDataLength,
    loading: false
  }));
}

function* _changePassivePage(action) {
  const { page } = action;
  const { SierraReducer: { multiInterfaceSetup: { bufferData: { pageSize } } } } = yield select();
  const { dataList, pages, allDataLength } = getMultiPassiveData({ page, pageSize });
  yield put(updateMultiSetupPassiveData({
    pageData: dataList,
    page,
    pageSize,
    pages,
    allDataLength,
    loading: false
  }));
}

function* _reAssignCompBufferModel() {
  const { SierraReducer: { multiInterfaceSetup: { bufferData } } } = yield select();
  const allData = multiSetupBufferDataBase.getData();
  if (!allData || !allData.length) {
    return;
  }
  yield put(updateMultiSetupBufferModelLoading(true))
  try {
    let bufferPageData = [...(bufferData.pageData || [])];
    const pageSize = bufferData.pageSize;
    let pcbMap = new Map();
    const pcbPartList = [...new Set(allData.map(item => `${item.pcbId}::${item.part}`))].filter(item => !!item);
    let partList = [];
    yield* pcbPartList.map(function* (item) {
      const [pcbId, part] = item.split("::");
      if (!pcbMap.get(pcbId) && pcbId) {
        yield call(getLayoutPartDB, pcbId);
      }
      pcbMap.set(pcbId, true);
      const partNumber = part && pcbId ? getPartNumber(part, pcbId) : null;
      part && partList.push({ partNumber, part });
    });
    if (!partList || !partList.length) {
      return;
    }
    const modelMap = yield call(getBufferModelByPart, { partList });
    const userDefaultSetting = yield call(userDefaultSettings.getSettings);
    const sierraSettings = userDefaultSetting && userDefaultSetting.sierraSettings ? userDefaultSetting.sierraSettings : null;
    const ioBufferUsage = sierraSettings && sierraSettings.ioBufferUsage ? sierraSettings.ioBufferUsage : null;

    let updateData = [];
    for (let itemData of allData || []) {
      let comps = [{
        name: itemData.component,
        type: IC,
        part: itemData.part,
        pins: [{ pin: itemData.pin }],
        model: itemData.componentModel
      }]
      comps = yield call(autoMatchBufferModelByPart, {
        components: comps,
        modelMap,
        isReplace: true,
        ioBufferUsage
      });
      itemData.componentModel = comps[0].model && comps[0].model.files && comps[0].model.files.length && comps[0].model.files[0].libraryId ? comps[0].model : itemData.componentModel;
      const pinInfo = comps[0].pins[0];
      const _model = pinInfo.model || {};
      itemData.model = _model.libraryId ? _model : itemData.model;
      itemData.usage = pinInfo.usage ? pinInfo.usage : itemData.usage;
      itemData.powerOff = _model.libraryId ? pinInfo.powerOff : itemData.powerOff;
      itemData.deviceVcc = _model.libraryId ? pinInfo.deviceVcc : itemData.deviceVcc;
      itemData.pinModels = pinInfo.usage && _model.libraryId && pinInfo.pinModels ? pinInfo.pinModels : (itemData.usage === "Receiver" ? [] : itemData.pinModels);

      itemData.deviceVcc = itemData.deviceVcc ? parseFloat(itemData.deviceVcc).toString() : ""
      updateData.push({
        interfaceIds: itemData.interfaceIds,
        component: itemData.component,
        componentModel: itemData.componentModel,
        pin: itemData.pin,
        model: itemData.model,
        usage: itemData.usage,
        powerOff: itemData.powerOff,
        deviceVcc: itemData.deviceVcc,
        pinModels: itemData.pinModels
      })

      const index = bufferPageData.findIndex(it => it.pcb === itemData.pcb && it.component === itemData.component && it.pin === itemData.pin);
      if (index < 0) {
        continue;
      }
      bufferPageData[index].componentModel = JSON.parse(JSON.stringify(itemData.componentModel));
      bufferPageData[index].model = JSON.parse(JSON.stringify(itemData.model || {}));
      bufferPageData[index].usage = itemData.usage;
      bufferPageData[index].powerOff = itemData.powerOff;
      bufferPageData[index].deviceVcc = itemData.deviceVcc;
      bufferPageData[index].pinModels = JSON.parse(JSON.stringify(itemData.pinModels || []));
    }
    multiSetupBufferDataBase.setData(allData);
    multiSetupBufferDataBase.resetPage(pageSize);
    yield put(updateMultiSetupBufferData({
      pageData: bufferPageData,
    }));
    yield put(updateMultiSetupBufferModelLoading(false))
    //save updateData to server
    yield call(saveMultiBufferDataPromise, updateData)
  } catch (error) {
    console.error(error)
    yield put(updateMultiSetupBufferModelLoading(false))
  }
}

export function* updateMultiSetupBySettings() {
  try {
    yield put(updateMultiSetupLoading(true))
    const { SierraReducer: { project: { currentProjectId, }, multiInterfaceSetup: {
      multiSetupGroup,
      setupData: { page, pageSize },
      bufferData: { pageData: bufferData, page: bufferPage, pageSize: bufferPageSize, verificationId } } } } = yield select();
    if (!multiSetupGroup) {
      return;
    }
    //update multi group setup
    const group = multiSetupGroup === ALL_INTERFACES ? "" : multiSetupGroup;
    const data = yield call(getMultiSetupDataPromise, currentProjectId, group || "");
    const { dataList, pages, allDataLength, page: _page, pageSize: _pageSize, verificationIds } = getMultiSetupData({ data, page, pageSize });

    yield put(updateMultiSetupPageData({
      pageData: dataList,
      page: _page,
      pageSize: _pageSize,
      pages,
      allDataLength,
      verificationIds
    }))
    yield put(updateMultiSetupGroup(multiSetupGroup))
    yield put(updateMultiSetupLoading(false))
    yield put(changeTabMenu({
      selectKeys: ['monitor'],
      menuType: 'group',
      verificationId: multiSetupGroup,
      projectId: currentProjectId,
      verificationName: multiSetupGroup,
    }));

    if (!bufferData || !bufferData.length) {
      return;
    }
    //update buffer panel data
    yield put(updateMultiSetupBufferData({
      loading: true
    }));
    const _data = yield call(getMultiSetupBufferDataPromise, currentProjectId, verificationId, group || "");
    const { dataList: _dataList,
      pages: _buffPages,
      allDataLength: buffAllDataLength,
      page: _buffPage,
      pageSize: _buffPageSize } = getMultiBufferData({ data: _data, page: bufferPage, pageSize: bufferPageSize });
    yield put(updateMultiSetupBufferData({
      pageData: _dataList,
      page: _buffPage,
      pageSize: _buffPageSize,
      pages: _buffPages,
      allDataLength: buffAllDataLength,
      loading: false,
      verificationId
    }));
  } catch (error) {
    console.error(error)
  }
  yield put(updateMultiSetupLoading(false))
  yield put(updateMultiSetupBufferData({
    loading: false
  }));
}

function getLayoutPartDB(designId) {
  return new Promise((resolve, reject) => {
    //designId  id
    //onlyCompLayer false
    //onlyPartDB true
    LayoutData.LoadLayoutDB(designId, true, true).then(res => {
      resolve(true);
    }, error => {
      resolve(true);
    })
  })
}

function* _getExtraction(action) {
  const { interfaceId, extrType, pcbId } = action;
  const applyType = extrType === "ports" ? "extrPortsApply" : "extrOptionsApply";

  yield put(updateMultiExtrPCBSelectStatus(false));
  if (!interfaceId) {
    yield put(updateMultiSetupExtractionApply({ applyType, config: {} }))
    yield put(clearMultiSetupExtraction({}))
    yield put(updateMultiSetupExtractionStatus(true))
    return;
  }

  let applyConfig = {};
  try {
    applyConfig = yield call(getExtractionApplyConfig, interfaceId);
  } catch (error) {
    console.error(error)
  }
  const config = applyConfig && applyConfig[applyType] && Object.keys(applyConfig[applyType]).length ? applyConfig[applyType] : {
    applyIds: [],
    applyType: "same"
  }
  yield put(updateMultiSetupExtractionApply({ applyType, config }))

  if (extrType === "ports") {
    //EXTRACTION ports
    const portsSetup = yield call(getExtractionPorts, interfaceId);
    yield put(updateMultiSetupExtractionPorts({
      [pcbId]: { ...(portsSetup || {}) }
    }))
    yield put(updateMultiSetupExtractionStatus(true))
    yield put(updatePCBLoadingStatus(true))
    yield delay(400)
    yield call(_getLayoutDB, pcbId);
    return
  }

  let info = {}
  try {
    //EXTRACTION options
    info = yield call(getExtractionOptions, interfaceId);
  } catch (error) {
    console.error(error)
  }

  if (!info || !info.verificationId) {
    yield put(updateMultiSetupExtractionApply({ applyType, config: {} }))
    yield put(clearMultiSetupExtraction({}))
    yield put(updateMultiSetupExtractionStatus(true))
    return;
  }

  let simConfig = {}, portsSetup = {};
  try {
    simConfig = yield call(getSimConfig, info.verificationId);
    portsSetup = yield call(getExtractionPorts, interfaceId);
  } catch (error) {
    console.error(error)
  }

  yield put(updateMultiSetupExtraction({
    [pcbId]: { ...info || {} },
    currentConfig: simConfig || {}
  }))
  yield put(updateMultiSetupExtractionPorts({
    [pcbId]: { ...(portsSetup || {}) }
  }))
  yield put(updateMultiSetupExtractionStatus(true))

}

function* _saveExtraction(action) {
  const { extraction, pcb, channel, prevChannelType, isClose, applyIds, xTalk, ifDoExtraction, applyType, update } = action;
  try {
    let { SierraReducer: { multiInterfaceSetup: { portsInfo, extractionInfo, extrOptionsApply } } } = yield select();
    yield put(updateMultiSetupExtractionOptionsLog("Saving...", isClose ? true : false));

    let _portsInfo = { ...(portsInfo[pcb] || {}) },
      _extractionInfo = { ...(extractionInfo[pcb] || {}) },
      currentConfig = { ...extractionInfo.currentConfig };

    if (!extraction) {
      _extractionInfo.extraction = new Extraction();
    } else {
      _extractionInfo.extraction = { ...extraction };
    }
    let copyInterfaceIds = [];
    const isPreLayout = designConstructor.getDesignVendor(pcb) === PRE_LAYOUT;
    if (update && applyIds && applyIds.length) {
      const interfaceList = multiSetupDataBase.getData() || [];
      //other verifications
      let existInterfaceIds = []
      const copyInterfaces = interfaceList.filter(item => {
        if ((applyIds || []).includes(item.verificationId)
          && ((isPreLayout && designConstructor.getDesignVendor(item.pcbId) === PRE_LAYOUT)
            || (!isPreLayout && designConstructor.getDesignVendor(item.pcbId) !== PRE_LAYOUT))
          && item.interfaceId !== _extractionInfo.interfaceId
          && !existInterfaceIds.includes(item.interfaceId)
          && (applyType === "all" || (applyType === "same" && item.pcbId === pcb))
        ) {
          existInterfaceIds.push(item.interfaceId);
          return { pcbId: item.pcbId, interfaceId: item.interfaceId, verificationId: item.verificationId, interfaceName: item.interfaceName, pcb: item.pcb }
        }
        return null;
      });

      //　update extraction and channel
      for (let itemData of copyInterfaces) {
        const { pcbId, interfaceId, verificationId, pcb, interfaceName } = itemData || {};
        yield put(updateMultiSetupExtractionOptionsLog(`Getting ${interfaceName} - ${pcb} extraction info...`));

        channel !== "Default" && copyInterfaceIds.push(interfaceId);
        const _currPortsInfo = isPreLayout ? null : yield call(getExtractionPorts, interfaceId);
        let currPortsInfo = _currPortsInfo ? _currPortsInfo : {}
        const _prevChannelType = _currPortsInfo && _currPortsInfo.channel ? _currPortsInfo.channel.type : null;

        yield put(updateMultiSetupExtractionOptionsLog(`Applying extraction setting to ${interfaceName} - ${pcb}...`));
        //default xTalk
        if (channel === "Default" && (channel !== _prevChannelType || _prevChannelType === "Default") && xTalk !== undefined) {
          const simConfig = yield call(getSimConfig, verificationId);
          if (!simConfig || !simConfig.config || simConfig.config.xTalk === xTalk) {
            continue;
          }
          simConfig.config.xTalk = xTalk;
          yield call(updateMultiSetupSimConfig, {
            verificationIds: [verificationId],
            config: simConfig.config
          });
          continue;
        }
        //if do extraction
        if (channel !== "Default" && ifDoExtraction !== currPortsInfo.ifDoExtraction) {
          yield call(reExtraction, interfaceId, ifDoExtraction || 0);
        }

        // If these two are different, update the ports
        if (channel !== _prevChannelType) {
          let isUpdate = false;

          if (channel === 'PowerSI' && judgeUpdatePowerSISetup(_currPortsInfo.ports_generate_setup_list)) {
            yield call(_getLayoutDB, pcbId)
            let data = {
              referenceNets: _currPortsInfo.referenceNets,
              port_setups: _currPortsInfo.port_setups,
              ports_generate_setup_list: _currPortsInfo.ports_generate_setup_list
            }
            _currPortsInfo.ports_generate_setup_list = getDefaultPortsGenerateSetupList({ components: _currPortsInfo.components });
            data = getExtractionPortsSetup({ ...data, ports_generate_setup_list: _currPortsInfo.ports_generate_setup_list }, pcbId);
            _currPortsInfo.port_setups = data.newData.port_setups;
            _currPortsInfo.ports_generate_setup_list = data.newData.ports_generate_setup_list;
            _currPortsInfo.referenceNets = data.newData.referenceNets;
            isUpdate = true;
          }

          if (judgeUpdateGapPortGapSizeSetup({
            components: currPortsInfo.components,
            extractionType: channel,
            prevExtractionType: prevChannelType,
            ports_generate_setup_list: currPortsInfo.ports_generate_setup_list
          })) {
            currPortsInfo.components = updateHFSSExtractionCompsGapSize(currPortsInfo.components, currPortsInfo.ports_generate_setup_list)
            isUpdate = true;
          }

          if (isUpdate) {
            let _currentPortsInfo_ = JSON.parse(JSON.stringify(currPortsInfo));
            delete _currentPortsInfo_.channel;
            yield call(saveExtractionPorts, {
              ..._currentPortsInfo_
            })
          }
        }
      }
    }
    _extractionInfo.channel = {
      type: channel,
      fileName: '',
      modelName: '',
      libraryId: '',
    }
    _portsInfo.channel = { ..._extractionInfo.channel }
    if (!isPreLayout && channel === 'PowerSI' && judgeUpdatePowerSISetup(_portsInfo.ports_generate_setup_list)) {
      yield call(_getLayoutDB, pcb)
      let data = {
        referenceNets: _portsInfo.referenceNets,
        port_setups: _portsInfo.port_setups,
        ports_generate_setup_list: _portsInfo.ports_generate_setup_list
      }
      _portsInfo.ports_generate_setup_list = getDefaultPortsGenerateSetupList({ components: _portsInfo.components });
      data = getExtractionPortsSetup({ ...data, ports_generate_setup_list: _portsInfo.ports_generate_setup_list }, pcb);
      _portsInfo.port_setups = data.newData.port_setups;
      _portsInfo.ports_generate_setup_list = data.newData.ports_generate_setup_list;
      _portsInfo.referenceNets = data.newData.referenceNets;
    }
    yield put(updateMultiSetupExtraction({ [pcb]: _extractionInfo }));
    yield put(updateMultiSetupExtractionPorts({ [pcb]: _portsInfo }));

    try {
      let _extractionInfo_ = JSON.parse(JSON.stringify(_extractionInfo))
      //extraction option
      if (!isPreLayout) {

        yield call(saveExtractionOptions, {
          ..._extractionInfo_,
          interfaceIds: [...new Set([_extractionInfo_.interfaceId, ...copyInterfaceIds])]
        });
        //ports
        let _portsInfo_ = JSON.parse(JSON.stringify(_portsInfo));
        delete _portsInfo_.channel;
        if (_portsInfo_ && Object.keys(_portsInfo_).length) {
          yield call(saveExtractionPorts, {
            ..._portsInfo_
          })
        }
      }
      //xTalk
      yield call(updateMultiSetupSimConfig, {
        verificationIds: [_extractionInfo.verificationId],
        config: currentConfig.config
      });
      yield call(updateExtractionApplyConfig, {
        interfaceId: _extractionInfo_.interfaceId,
        config: {
          extrOptionsApply
        }
      });
    } catch (error) {
      console.error(error);
    }

    yield put(updateMultiSetupExtractionOptionsLog("", false));
    if (isClose) {
      yield put(clearMultiSetupExtraction())
      yield put(clearMultiSetupExtractionPorts());
      yield put(updateMultiSetupExtractionApply({ applyType: "extrOptionsApply", config: {} }))
    } else {
      yield put(updateMultiExtrPCBSelectStatus(true));
    }
  } catch (error) {
    console.error(error)
  }
}

function* _getLayoutDB(pcbId) {
  try {
    yield call(getLayoutDB, pcbId, true)
    const _DesginData = LayoutData.getLayout(pcbId);
    const pcbInfo = {
      netsList: [..._DesginData.mNetManager.mNetList.mVals],
      layers: [..._DesginData.mLayerMgr.mMetalLayers]
    };
    DesignInfo.savePCBInfo(pcbId, pcbInfo);
    yield put(updatePCBLoadingStatus(false))
  } catch (error) {
    console.error(error)
    yield put(updatePCBLoadingStatus(false))
  }
}

function* _saveExtractionPorts(action) {
  const { port_setups, referenceNets, ports_generate_setup_list, components, saveType, pcbId, applyIds, applyType } = action;
  let { SierraReducer: { multiInterfaceSetup: { portsInfo, extrPortsApply } } } = yield select();
  const isClose = saveType === "close" ? true : false;
  try {
    let _portsInfo = JSON.parse(JSON.stringify(portsInfo[pcbId] || {}));
    yield put(updateMultiSetupExtractionPortsLog("Saving...", isClose ? true : false));

    delete _portsInfo.channel;
    try {
      yield call(saveExtractionPorts, {
        ..._portsInfo,
        port_setups,
        ports_generate_setup_list,
        referenceNets,
        components
      })
    } catch (error) {
      console.error(error)
    }

    if (!ports_generate_setup_list || !ports_generate_setup_list.length) {
      yield put(updateMultiSetupExtractionPortsLog("", false));
      if (isClose) {
        yield put(clearMultiSetupExtractionPorts())
        yield put(updateMultiSetupExtractionApply({ applyType: "extrPortsApply", config: {} }))
      } else if (saveType === "changePCB") {
        yield put(updateMultiExtrPCBSelectStatus(true));
      }
      return;
    }

    let copyInterfaceIds = [];
    if (applyIds && applyIds.length) {
      yield put(updateMultiSetupExtractionPortsLog("Applying extraction ports..."));
      const z0 = port_setups[0].z0;
      const interfaceList = multiSetupDataBase.getData() || [];
      //all verifications
      let existInterfaceIds = []
      const copyInterfaces = interfaceList.filter(item => {
        if ((applyIds || []).includes(item.verificationId)
          && designConstructor.getDesignVendor(item.pcbId) !== PRE_LAYOUT
          && item.interfaceId !== _portsInfo.interfaceId
          && !existInterfaceIds.includes(item.interfaceId)
          && (applyType === "all" || (applyType === "same" && item.pcbId === pcbId))
        ) {
          existInterfaceIds.push(item.interfaceId);
          return { pcbId: item.pcbId, interfaceId: item.interfaceId, verificationId: item.verificationId, interfaceName: item.interfaceName, pcb: item.pcb }
        }
        return null;
      });
      //　update extraction and channel
      for (let itemData of copyInterfaces) {
        const { pcbId, interfaceId, interfaceName, pcb } = itemData || {};
        yield put(updateMultiSetupExtractionPortsLog(`Getting ${interfaceName} - ${pcb} extraction ports...`));
        copyInterfaceIds.push(interfaceId);
        let _currPortsInfo = null;
        try {
          _currPortsInfo = yield call(getExtractionPorts, interfaceId);
        } catch (error) {
          console.error(error)
        }
        let currPortsInfo = _currPortsInfo ? _currPortsInfo : {};
        const channelType = currPortsInfo.channel ? currPortsInfo.channel.type : null;
        if (!channelType || channelType === "Default" || !currPortsInfo.port_setups || !currPortsInfo.port_setups.length) {
          continue;
        }
        let isSave = false;
        const copyPortSetup = ports_generate_setup_list[0].setup, applyComponents = [];
        if (z0 !== currPortsInfo.port_setups[0].z0) {
          currPortsInfo.port_setups.forEach(item => {
            item.z0 = port_setups[0].z0
          });
          isSave = true;
        }

        currPortsInfo.ports_generate_setup_list = currPortsInfo.ports_generate_setup_list.map(item => {
          let _copyPortSetup = {};
          //find save component
          const findSaveCompSetup = ports_generate_setup_list.find(it => it.component === item.component);
          if (findSaveCompSetup && findSaveCompSetup.setup) {
            _copyPortSetup = new PortsGenerationSetup({ ...findSaveCompSetup.setup });
          } else {
            _copyPortSetup = new PortsGenerationSetup({ ...copyPortSetup });
          }
          if (!_.isEqual(item.setup || {}, _copyPortSetup)
            && (currPortsInfo.channel.type !== "PowerSI" || [PIN_GROUP, CIRCUIT].includes(_copyPortSetup.portType))) {
            item.setup = _copyPortSetup;
            applyComponents.push(item.component)
          }
          return item;
        });

        if (applyComponents && applyComponents.length) {
          yield put(updateMultiSetupExtractionPortsLog(`Applying extraction ports to ${interfaceName} - ${pcb}...`));
          yield call(_getLayoutDB, pcbId)
          const data = getExtractionPortsSetup({
            referenceNets: currPortsInfo.referenceNets,
            port_setups: currPortsInfo.port_setups,
            ports_generate_setup_list: currPortsInfo.ports_generate_setup_list,
            applyComponents
          }, pcbId);
          currPortsInfo.port_setups = data.newData.port_setups;
          currPortsInfo.ports_generate_setup_list = data.newData.ports_generate_setup_list;
          currPortsInfo.referenceNets = data.newData.referenceNets;
          currPortsInfo.components = updateSetupComponentsByPortType({
            components: currPortsInfo.components,
            ports_generate_setup_list: currPortsInfo.ports_generate_setup_list,
            designId: pcbId,
            extractionType: currPortsInfo.channel ? currPortsInfo.channel.type : ""
          });
          isSave = true;
        }

        if (isSave) {
          let _currentPortsInfo_ = JSON.parse(JSON.stringify(currPortsInfo));
          delete _currentPortsInfo_.channel;
          try {
            yield call(saveExtractionPorts, {
              ..._currentPortsInfo_
            })
          } catch (error) {
            console.error(error);
          }
        }
      }
    }

    if (saveType) {
      try {
        yield call(updateExtractionApplyConfig, {
          interfaceId: _portsInfo.interfaceId,
          config: {
            extrPortsApply
          }
        });
      } catch (error) {
        console.error(error)
      }
    }

    yield put(updateMultiSetupExtractionPortsLog("", false));
    if (isClose) {
      yield put(clearMultiSetupExtractionPorts())
      yield put(updateMultiSetupExtractionApply({ applyType: "extrPortsApply", config: {} }))
    } else if (saveType === "changePCB") {
      yield put(updateMultiExtrPCBSelectStatus(true));
    }
  } catch (error) {
    console.error(error)
  }
}

function* sierraMultiInterfaceSaga() {
  yield takeEvery(UPDATE_MULTI_INTERFACE_SETUP_STATUS, _openMultiInterfaceSetup);
  yield takeEvery(CHANGE_MULTI_SETUP_PAGE, changePage);
  yield takeEvery(GET_MULTI_SETUP_BUFFER_DATA, _getBufferData);
  yield takeEvery(GET_MULTI_SETUP_PASSIVE_DATA, _getPassiveData);
  yield takeEvery(SAVE_IC_COMP_MODEL, _saveCompModel);
  yield takeEvery(SAVE_IC_PIN_MODEL, _saveCompPinModel);
  yield takeEvery(SAVE_PASSIVE_MODEL, _savePassiveModel);
  yield takeEvery(SAVE_PIN_VALUE, _savePinModels);
  yield takeEvery(SAVE_POWER_OFF, _savePowerOff);
  yield takeEvery(UPDATE_COMPONENT_PIN_USAGE, _updateCompPinUsage);
  yield takeEvery(UPDATE_PASSIVE_COMPONENTS, _updatePassiveComp);
  yield takeEvery(SAVE_SPLIT_COMPONENTS, _saveSplitComponents);
  yield takeEvery(SAVE_MERGE_COMPONENTS, _saveMergeComponents);
  yield takeEvery(CHECK_SIM_INTERFACES, _checkedSimInterfaces);
  yield takeEvery(START_MULTI_SETUP_SIM, _startSim);
  yield takeEvery(SAVE_SIM_CONFIG, _saveSimulationConfig);
  yield takeEvery(CHANGE_MULTI_SETUP_GROUP, _changeGroup);
  yield takeEvery(CHANGE_MULTI_BUFFER_PAGE, _changeBufferPage);
  yield takeEvery(CHANGE_MULTI_PASSIVE_PAGE, _changePassivePage);
  yield takeEvery(GET_MULTI_INTERFACE_SETUP, _getMultiSetupData);
  yield takeEvery(RE_ASSIGN_BUFFER_MODEL, _reAssignCompBufferModel);
  yield takeEvery(GET_MULTI_SETUP_EXTRACTION_DATA, _getExtraction);
  yield takeEvery(SAVE_MULTI_SETUP_EXTRACTION, _saveExtraction);
  yield takeEvery(SAVE_MULTI_SETUP_EXTRACTION_PORTS, _saveExtractionPorts)
}

export default sierraMultiInterfaceSaga;