import { uploadLicense, getLicenses, getLicenseFeatures, licenseHeartbeat, licenseCheckout, licenseUsage, licenseCheckin, licenseUsageHistory, licenseMode, licenseSwitch, checkInLicenseDirectly } from '../api/license';
import apiProcess from '../api/utility';

export function uploadLicensePromise(file) {
  return apiProcess(uploadLicense, file)
}

export function getLicensePromise() {
  return apiProcess(getLicenses)
}

export function getLicenseFeaturesPromise() {
  return apiProcess(getLicenseFeatures)
}

export function licenseHeartbeatPromise(featureName) {
  return apiProcess(licenseHeartbeat, featureName)
}

export function checkoutLicensePromise(featureName) {
  return apiProcess(licenseCheckout, featureName)
}

export function getLicenseUsagePromise() {
  return apiProcess(licenseUsage)
}

export function checkinLicensePromise(featureName) {
  return apiProcess(licenseCheckin, featureName);
}

export function getLicenseUsageHistoryPromise(params) {
  return apiProcess(licenseUsageHistory, params)
}

export function getLicenseModePromise() {
  return apiProcess(licenseMode)
}

export function licenseSwitchPromise(mode, url) {
  return apiProcess(licenseSwitch, { mode, url })
}

export function checkInLicenseDirectlyPromise(checkoutId) {
  return apiProcess(checkInLicenseDirectly, checkoutId)
}