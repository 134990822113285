import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Select } from 'antd';
import PCBSelect from '../components/PCBSelect';
import { changeSignOffPCB, updateTemplateSoc, updateTemplateByCompSetting, updateTemplateGnd, saveAdditionalNets } from '../store/SignOffTemplate/action';
import SignOffTemplateTable from './SignOffTemplateTable';
import SignOffComponentsTable from './SignOffComponentsTable';
import { getNets } from '@/services/Cascade/helper/setupData';
import DelConfirm from '@/components/DelConfirm';
import MsgDialog from '../../../components/MsgDialog';
import projectDesigns from '../../../services/helper/projectDesigns';
import AdditionalNets from '@/components/AdditionalNets'
import './index.css';

class SignOffContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      socList: [],
      nets: [],
      advanced: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize({ target: { innerWidth: window.innerWidth } });
    const { socList, nets } = this.state;
    const { designId } = this.props;
    if (((socList && socList.length === 0) || (nets && nets.length === 0)) && designId) {
      this.getNetsOptions(designId);
    }
  }

  componentDidUpdate(prevProps) {
    const { verificationId, designId, loading } = this.props;
    if ((verificationId && verificationId !== prevProps.verificationId)
      || (designId && designId !== prevProps.designId)
      || (!loading && loading !== prevProps.loading)) {
      this.getNetsOptions(designId);
    }

    if ((verificationId && verificationId !== prevProps.verificationId)) {
      this.setState({ advanced: false })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize = (e) => {
    this.setState({
      width: e.target.innerWidth ? e.target.innerWidth - 300 : null
    })
  }

  getNetsOptions = async (designId) => {
    const netsInfo = getNets(designId);
    if (netsInfo) {
      const { loadComponents, nets } = netsInfo;
      this.setState({
        socList: loadComponents,
        nets
      })
    }
  }


  downloadXlsx = () => {
    /*    const { verificationId } = this.props; */
    //todo download xlsx file
  }

  socSelect = (name) => {
    this.props._updateTemplateSoc(name)
  }

  gndSelect = (name) => {
    this.props._updateTemplateGnd(name)
  }

  updateCompPrefixVersion = (bool) => {
    this.props.updateTemplateByCompSetting(bool)
  }

  changeAdvanced = () => {
    this.setState({
      advanced: !this.state.advanced
    })
  }

  render() {
    const { designId, openProjectId, contentWidth, signOffTemplate, fileName, verificationId,
      loading, soc, templateCompSettingStatus, contentLoading, gnd, pcbUpdate,
      createTaskMsgList, templateSetupUpdate, templateValueUpdate, designName, additionalNets = [] } = this.props;
    const { width, socList = [], nets = [], advanced } = this.state;
    const noPCB = designId && !projectDesigns.getDesignExist(openProjectId, designId);
    const stickWidth = { width: width - 37, minWidth: 1200 }
    return (
      <div className='cascade-sign-off-template-content'>
        <Row className='cascade-signoff-stick-row' style={stickWidth}>
          <Col span={24} className='cascade-setup-border'>
            <div className='cascade-sign-off-top-title'>
              <div className='cascade-pcb-select-box'>
                <PCBSelect
                  pcbId={designId}
                  projectId={openProjectId}
                  designStatus={loading}
                  pcbUpdate={pcbUpdate}
                  noPCB={noPCB}
                  designName={designName}
                  selectPCB={this.props._changeSignOffPCB} />
              </div>
              <div className='cascade-pcb-target-box'>
                <span className="font-bold cascade-setup-title-color-only">Soc</span>
                <Select
                  value={loading ? 'Loading PCB...' : (contentLoading ? "Loading..." : soc)}
                  style={{ width: 240, marginLeft: 20 }}
                  onChange={this.socSelect}
                  getPopupContainer={((trigger) => trigger.parentNode)}
                  dropdownStyle={{ zIndex: 200 }}
                  showSearch={true}
                  disabled={loading || contentLoading}
                >
                  {socList.map(option => {
                    return <Select.Option key={option.name} value={option.name}>{option.name}</Select.Option>
                  })}
                </Select>
              </div>
              <div className='cascade-pcb-target-box'>
                <span className="font-bold cascade-setup-title-color-only">Gnd</span>
                <Select
                  value={loading ? 'Loading PCB...' : (contentLoading ? "Loading..." : gnd)}
                  style={{ width: 240, marginLeft: 20 }}
                  onChange={this.gndSelect}
                  getPopupContainer={((trigger) => trigger.parentNode)}
                  dropdownStyle={{ zIndex: 200 }}
                  showSearch={true}
                  disabled={loading || contentLoading}
                >
                  {nets.map(option => {
                    return <Select.Option key={option} value={option}>{option}</Select.Option>
                  })}
                </Select>
              </div>
              <div className='cascade-sign-off-file-box'>
                {fileName}
                {/* todo - download xlsx */}
                {/*  <Icon
                  type="download"
                  title="Download xlsx file"
                  className="cascade-sign-off-template-download-icon"
                  onClick={this.downloadXlsx}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row className='space-16'>
          <Col span={24} className='cascade-setup-border'>
            <SignOffTemplateTable
              signOffTemplate={signOffTemplate}
              designId={designId}
              soc={soc}
              gnd={gnd}
              socList={socList}
              nets={nets}
              contentLoading={contentLoading}
              verificationId={verificationId}
              loading={loading}
              templateValueUpdate={templateValueUpdate}
              templateSetupUpdate={templateSetupUpdate}
              contentWidth={contentWidth ? contentWidth : width}
            />
          </Col>
        </Row>
        <Row className='space-16 cascade-signoff-stick-row' style={stickWidth}>
          <Col span={24} className='cascade-setup-border'>
            <SignOffComponentsTable
              signOffTemplate={signOffTemplate}
              designId={designId}
              verificationId={verificationId}
              loading={loading}
            />
          </Col>
        </Row>
        {
          <Fragment>
            <Row className='space-16 cascade-signoff-stick-row' style={stickWidth}>
              <div className='cascade-page-advanced-title'>
                {advanced ? <DownOutlined onClick={this.changeAdvanced} /> : <RightOutlined onClick={this.changeAdvanced} />}
                <span onClick={this.changeAdvanced}>Advanced</span>
              </div>
            </Row>
            {
              advanced ? <Row className='space-16 cascade-signoff-stick-row' style={stickWidth}>
                <Col span={24} className='cascade-setup-border'>
                  <AdditionalNets designId={designId} additionalNets={additionalNets} saveAdditionalNets={this.props.saveAdditionalNets} matchWidth={false} />
                </Col>
              </Row> : null
            }
          </Fragment>
        }
        {templateCompSettingStatus ? <DelConfirm
          type='update'
          onUpdate={() => this.updateCompPrefixVersion(true)}
          cancelUpdate={() => this.updateCompPrefixVersion(false)}
          message={"The Component Setting of the current PCB has been updated. Do you want to update the Template?"}
        /> : null}
        {createTaskMsgList && createTaskMsgList.length ?
          <MsgDialog
            mark={true}
            messageList={createTaskMsgList}
          /> : null
        }
      </div>
    );
  }
}

const mapState = (state) => {
  const { CascadeReducer: { project: { openProjectId, pcbUpdate },
    SignOffTemplate: { data = {}, verificationId, loading,
      templateCompSettingStatus, contentLoading, createTaskMsgList, templateSetupUpdate, templateValueUpdate } } } = state;
  const { designId, signOffTemplate, components, fileName, soc, gnd, designName, additionalNets } = data || {};

  return {
    openProjectId,
    designId,
    signOffTemplate,
    fileName,
    verificationId,
    loading,
    components,
    soc,
    templateCompSettingStatus,
    contentLoading,
    gnd,
    pcbUpdate,
    createTaskMsgList,
    templateSetupUpdate,
    templateValueUpdate,
    designName,
    additionalNets
  };
}

const mapDispatch = (dispatch) => ({
  _changeSignOffPCB(designId) {
    dispatch(changeSignOffPCB(designId))
  },
  _updateTemplateSoc(name) {
    dispatch(updateTemplateSoc(name))
  },
  updateTemplateByCompSetting(update) {
    dispatch(updateTemplateByCompSetting(update))
  },
  _updateTemplateGnd(gnd) {
    dispatch(updateTemplateGnd(gnd))
  },
  saveAdditionalNets(nets) {
    dispatch(saveAdditionalNets(nets))
  }
})

export default connect(mapState, mapDispatch)(SignOffContent);