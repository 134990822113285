export const FOLDER = 0;
export const PROJECT = 1;
export const TRASH = 'trash';
export const SHARE_TO_ME = 'share_to_me';
export const SHARE_BY_ME = 'share_by_me'

export const LAYOUT = 1;
export const SCHEMATIC = 2;
export const S_PARAMETER = 3;
export const PACKAGE = 4;
export const DESIGN_CARD = 5; 