import SymbolIdentifier from './SymbolIdentifier';

var SymbolGroup = function (grpID, symbolList) {
  this.ID = grpID || '';
  this.symbolList = symbolList || [];
}

SymbolGroup.prototype.updateByString = function (dataString) {
  if (dataString.length > 1) {
    this.ID = dataString.shift();
    for (var i = 0; i < dataString.length; i++) {
      var dataStr = dataString[i];
      var identifier = new SymbolIdentifier();
      identifier.updateByString(dataStr);
      this.symbolList.push(identifier);
    }
  }
}

// TODO. id or name??????
SymbolGroup.prototype.getSymbolName = function () {
  return this.symbolList[0].symbolName;
}

export default SymbolGroup;