import {
  getImpedance, updateImpedanceSetup, updateImpedanceDomain, deleteDomains,
  simulationImpedance, getImpResult, getImpNpiFile, getImpCurves, getImpedanceRelated,
  uploadTouchstone, uploadPWLTable, getTargetTouchstone, deleteTargetFile, getEffectResult, getEffectFrequency,
  saveHistory, deleteHistory, getHistoryList,
  getImpHistoryCurves, getImpHistoryNpiFile, getImpHistoryResult, getImpedanceHistoryRelated, getHistoryEffectResult,
  getImpedanceJsonById, getDecapEffectiveRLById, saveCutDesign,
  importSnp, getImportList, getImpImportCurves, getImpImportNpiFile, deleteImportFile,
  getImpSuccess, getComparisonList, saveComparison, getHistoryDecapEffectiveRLById,
  uploadDecapGroup, getDecapGroupById, getHistoryDecapGroupById,
  uploadDecapModel, getTransientResultById,
  getRdieCdieResult,
  getImpOptCurves,
  getImpOptNpiFile,
  getImpOptDecap,
  getImpOptList,
  getImpSweepCurves
} from '../../api/Cascade/Impedance';
import apiProcess from '../../api/utility';

function getImpedanceInfo(verificationId) {
  return apiProcess(getImpedance, verificationId)
}

function saveImpedanceDomain({ verificationId, layouts }) {
  return apiProcess(updateImpedanceDomain, { verificationId, layouts })
}

function saveImpedanceSetup({ verificationId, content }) {
  return apiProcess(updateImpedanceSetup, { verificationId, content })
}

function deleteImpedanceDomains({ verificationId, ids, designId }) {
  return apiProcess(deleteDomains, { verificationId, ids, designId })
}

function runImpedance({ options, verificationId }) {
  return apiProcess(simulationImpedance, { options, verificationId }, false, true)
}

//result
function getImpedanceResultFile(verificationId) {
  return apiProcess(getImpResult, verificationId)
}

function getImpedanceNpi(verificationId, fileName) {
  return apiProcess(getImpNpiFile, { verificationId, fileName }, false, false, true)
}

function getImpedanceCurves({ verificationId, rows, cols, type, fileName }) {
  return apiProcess(getImpCurves, { verificationId, rows, cols, type, fileName })
}

function getImpedanceResultRelated(verificationId) {
  return apiProcess(getImpedanceRelated, verificationId)
}

function getImpedanceEffect({ verificationId, frequency }) {
  return apiProcess(getEffectResult, { verificationId, frequency })
}

function uploadTargetTouchstone({ files, name, config, portId, targetName, id }) {
  return apiProcess(uploadTouchstone, { files, name, config, portId, targetName, id })
}

function uploadPWLTableFile({ file }) {
  return apiProcess(uploadPWLTable, { file })
}

// only need libraryId or powerDomainId + targetFileName
function getTargetFile({ powerDomainId = "", targetFileName = "", libraryId = "" }) {
  return apiProcess(getTargetTouchstone, { powerDomainId, targetFileName, libraryId }, false, false, true)
}

function deleteTargetTouchstoneFile({ powerDomainId, libraryId }) {
  return apiProcess(deleteTargetFile, { powerDomainId, libraryId })
}

function getEffectiveFreq(verificationId) {
  return apiProcess(getEffectFrequency, { verificationId });
}

function getImpedanceRdieCdie(libraryId) {
  return apiProcess(getRdieCdieResult, libraryId)
}

// result history
function saveImpedanceHistory({ verificationId, name }) {
  return apiProcess(saveHistory, { verificationId, name });
}

function deleteImpedanceHistory(historyIds) {
  return apiProcess(deleteHistory, { historyIds });
}

function getImpedanceHistorys(verificationId) {
  return apiProcess(getHistoryList, verificationId);
}

function getImpedanceHistoryCurves({ verificationId, rows, cols, type, fileName }) {
  return apiProcess(getImpHistoryCurves, { historyId: verificationId, rows, cols, type, fileName })
}

function getImpedanceHistoryNpi(historyId, fileName) {
  return apiProcess(getImpHistoryNpiFile, { historyId, fileName }, false, false, true)
}

function getImpedanceHistoryFile(historyId) {
  return apiProcess(getImpHistoryResult, historyId)
}


function getHistoryImpedanceRelated(historyId) {
  return apiProcess(getImpedanceHistoryRelated, historyId)
}

function getImpedanceHistoryEffect({ historyId, frequency }) {
  return apiProcess(getHistoryEffectResult, { historyId, frequency })
}

function getImpedanceJson(verificationId) {
  return apiProcess(getImpedanceJsonById, verificationId)
}

function saveCascadeCutDesign(params) {
  return apiProcess(saveCutDesign, params)
}

function importImpedanceSnp({ zip, verificationId, isZip }) {
  return apiProcess(importSnp, { zip, verificationId, isZip })
}

function getImpResultImportList(verificationId) {
  return apiProcess(getImportList, verificationId)
}

function getImpResultImportCurves({ verificationId, rows, cols, type, fileName }) {
  return apiProcess(getImpImportCurves, { snpId: verificationId, rows, cols, type, fileName })
}

function getImpResultImportNpi(snpId) {
  return apiProcess(getImpImportNpiFile, { snpId }, false, false, true)
}

function deleteImpResultImports(snpIds) {
  return apiProcess(deleteImportFile, { snpIds });
}

function getImpedanceHasResult() {
  return apiProcess(getImpSuccess)
}

function getImpedanceComparison(verificationId) {
  return apiProcess(getComparisonList, verificationId);
}

function saveImpedanceComparison({ verificationId, comparison }) {
  return apiProcess(saveComparison, { verificationId, comparison })
}

function getDecapEffectiveRL(verificationId) {
  return apiProcess(getDecapEffectiveRLById, verificationId)
}

function getHistoryDecapEffectiveRL(historyId) {
  return apiProcess(getHistoryDecapEffectiveRLById, historyId)
}

function getDecapGroup(verificationId) {
  return apiProcess(getDecapGroupById, verificationId, false, false, true)
}

function getHistoryDecapGroup(historyId) {
  return apiProcess(getHistoryDecapGroupById, historyId, false, false, true)
}

function uploadDecapGroupExcel({ verificationId, file }) {
  return apiProcess(uploadDecapGroup, { verificationId, file })
}

function uploadDecapModelExcel({ file, type }) {
  return apiProcess(uploadDecapModel, { file, type })
}

function getTransientResult(verificationId) {
  return apiProcess(getTransientResultById, verificationId, false, false, true)
}

function getImpedanceOptCurves({ verificationId, rows, cols, type, fileName }) {
  return apiProcess(getImpOptCurves, { verificationId, rows, cols, type, fileName })
}

function getImpedanceSweepCurves({ verificationId, rows, cols, type, fileName, sweepKey }) {
  return apiProcess(getImpSweepCurves, { verificationId, rows, cols, type, fileName, sweepKey })
}

function getImpedanceOptNpi(verificationId) {
  return apiProcess(getImpOptNpiFile, verificationId, false, false, true)
}

function getImpedanceOptDecap(verificationId) {
  return apiProcess(getImpOptDecap, verificationId)
}

function getImpedanceOptList(verificationId) {
  return apiProcess(getImpOptList, verificationId)
}

export {
  getImpedanceInfo,
  saveImpedanceDomain,
  saveImpedanceSetup,
  deleteImpedanceDomains,
  runImpedance,
  getImpedanceResultFile,
  getImpedanceNpi,
  getImpedanceCurves,
  getImpedanceResultRelated,
  uploadTargetTouchstone,
  uploadPWLTableFile,
  deleteTargetTouchstoneFile,
  getTargetFile,
  getImpedanceEffect,
  getEffectiveFreq,
  saveImpedanceHistory,
  deleteImpedanceHistory,
  getImpedanceHistorys,
  getImpedanceHistoryCurves,
  getImpedanceHistoryNpi,
  getImpedanceHistoryFile,
  getHistoryImpedanceRelated,
  getImpedanceHistoryEffect,
  getImpedanceJson,
  saveCascadeCutDesign,
  importImpedanceSnp,
  getImpResultImportList,
  getImpResultImportCurves,
  getImpResultImportNpi,
  deleteImpResultImports,
  getImpedanceHasResult,
  getImpedanceComparison,
  saveImpedanceComparison,
  getDecapEffectiveRL,
  getHistoryDecapEffectiveRL,
  getDecapGroup,
  getHistoryDecapGroup,
  uploadDecapGroupExcel,
  uploadDecapModelExcel,
  getTransientResult,
  getImpedanceRdieCdie,
  getImpedanceOptCurves,
  getImpedanceOptNpi,
  getImpedanceOptDecap,
  getImpedanceOptList,
  getImpedanceSweepCurves
}