class SpiceCardTemplatesConstructor {
  constructor() {
    this.spiceCardTemplateList = new Map(); //key - spice card template id, value :{ id ,name, .... }
  }

  getSize = () => {
    return this.spiceCardTemplateList.size;
  };

  getList = () => {
    return this.spiceCardTemplateList;
  }

  getValues = () => {
    let list = [];
    for (let val of this.spiceCardTemplateList.values()) {
      list.push(val);
    }
    return list;
  }

  get = (id) => {
    return this.spiceCardTemplateList.get(id);
  }

  add = (id, data) => {
    this.spiceCardTemplateList.set(id, data);
  }

  addList = (list) => {
    for (let item of list || []) {
      this.spiceCardTemplateList.set(item.id, item);
    }
  }

  del = (id) => {
    this.spiceCardTemplateList.delete(id);
  }

  clearCache = () => {
    this.spiceCardTemplateList = new Map();
  }
};

let spiceCardTemplatesConstructor = new SpiceCardTemplatesConstructor();
export default spiceCardTemplatesConstructor;