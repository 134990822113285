import { UPDATE_DATA, SELECT_ROW, SHOW_VERIFICATION } from "./actionType";

const defaultPagination = {
  // TODO - 页码快速跳转 showQuickJumper: true,
  // showQuickJumper: false,
  current: 1,
  total: 0,
  // hideOnSinglePage: true
}

const defaultStatus = {
  current: {}, //current folder object, {id, type, name}, default is null
  path: [], // record parent folders of current
  dataSource: [], //table data
  loading: false, // record table loading status
  pagination: defaultPagination,
  selectedRowKeys: []
};

const designReducer = (state = defaultStatus, action) => {
  switch (action.type) {
    case UPDATE_DATA:
      return {
        ...state,
        ...action.data
      }
    case SELECT_ROW:
      return { ...state, selectedRowKeys: action.selectedRowKeys }
    default: return state;
  }
}

export default designReducer;