import { SINGLE_PAGE_LAYOUT, PCB_ONLY } from "../../constants/layoutConstants";
import { ANDES_V2, CASCADE, FASTPI, HIMALAYAS, ROCKY, SIERRA } from "../../constants/pageType";
import {
  PCB,
  PCB_CHANNEL,
  PCB_PRE_LAYOUT,
  RESULT,
  VERIFICATION,
  END_TO_END_CHANNEL,
  PCB_CHANNEL_RESULT,
  END_TO_END_CHANNEL_RESULT,
  DCR, IR_EXPLORER,
  IR_EXPLORER_RESULT,
  IMPEDANCE,
  IMPEDANCE_RESULT,
  PACKAGE,
  POWER_TREE,
  DESIGN_TREE,
  SINGLE_TREE,
  EXPERIMENTS_RESULT,
  EXPERIMENTS,
  PDN,
  SIGN_OFF_TEMPLATE,
  PACKAGE_VERIFICATION,
  PACKAGE_RESULT,
  CARD,
  SPICE_CARD_TEMPLATE,
  IO_SIM,
  VERIFICATION_GROUP,
  PACKAGE_PDN,
  PACKAGE_PDN_RESULT,
  CHANNEL,
  PACKAGE_PRE_LAYOUT,
  PCB_PDN,
  PCB_PDN_RESULT,
  PCB_PRE_LAYOUT_RESULT,
  PACKAGE_PRE_LAYOUT_RESULT
} from "../../constants/treeConstants";
import { CARD_RESULT, CARD_VERIFICATION, SSN_CHANNEL, SSN_RESULT } from "../Rocky/constants";
import { strDelimited } from "./split";
/**
 * @param {array} keys 
 * @param {string} filterKey
 * @param {string} filterId
 */
function filterKeys(keys, filterKey, filterId, setting) {
  const { delimiter = "-" } = setting ? setting : {};
  if (keys && keys.length > 0) {
    keys = keys.filter(item => {
      if (item) {
        const [_key, _id] = strDelimited(item, delimiter);
        if (!(_key === filterKey && _id !== filterId)) {
          return item;
        }
      };
      return false;
    });
  }
  return keys;
}

function updateKeys(keys, key, expandedKeys) {
  if (keys.includes(key)) {
    keys = keys.filter(k => k !== key);
  } else {
    keys.push(key);
  }
  keys = [...new Set([...keys])];
  const preExpand = expandedKeys.includes(key)
  return { preExpand, _keys: keys };
}

function updateTreeSelectKeys({ prevSelectedKeys, key, eventKey, selected, layout, pcbLayout, productType }) {
  function replace(selectedKeys, i = 1) {
    let selecteds = [...selectedKeys];
    let removeIndex = -1, existIndex = 0;
    const pageKeys = getPageKeyList(key);
    if (pageKeys.includes(key)) {
      for (let i = 0; i < selecteds.length; i++) {
        const ikey = selecteds[i].split('-')[0];
        if (pageKeys.includes(ikey)) {
          if (removeIndex === -1) {
            removeIndex = i;
          }
          existIndex++;
        }
      }
    };

    if (!existIndex || existIndex < i) {
      selecteds.push(eventKey);
    } else {
      selecteds.splice(removeIndex, 1);
      selecteds.push(eventKey);
    }
    return selecteds;
  }
  const pcbs = [PCB, PCB_PRE_LAYOUT, PACKAGE, CARD, PACKAGE_PRE_LAYOUT];
  if (!selected || !getPageAllKeyList(productType).includes(key)) return [];
  let selectedKeys = [...prevSelectedKeys];
  if (layout === SINGLE_PAGE_LAYOUT) {
    // filter
    if (pcbs.includes(key)) {
      selectedKeys = selectedKeys.filter(d => {
        const k = d.split('-')[0];
        return pcbs.includes(k);
      })
    } else {
      selectedKeys = selectedKeys.filter(d => {
        const k = d.split('-')[0];
        return !pcbs.includes(k);
      })
    }
  }

  if (pcbs.includes(key)) {
    if (pcbLayout === PCB_ONLY && layout === SINGLE_PAGE_LAYOUT) {
      return [eventKey];
    } else if (pcbLayout === PCB_ONLY && layout !== SINGLE_PAGE_LAYOUT) {
      // [PCB_TOP_BOTTOM, PCB_LEFT_RIGHT].includes(pcbLayout)
      return replace(selectedKeys);
    } else {
      return replace(selectedKeys, 2);
    }
  } else if (layout === SINGLE_PAGE_LAYOUT) {
    // Setup & Result page && layout === SINGLE_PAGE_LAYOUT
    return [eventKey];
  } else {
    // Setup & Result page && layout === LEFT_RIGHT_lAYOUT/TOP_BOTTOM_LAYOUT
    return replace(selectedKeys);
  }
};

function getPageAllKeyList(productType) {
  switch (productType) {
    case ANDES_V2:
      return [END_TO_END_CHANNEL, PCB_CHANNEL_RESULT, PCB, PCB_PRE_LAYOUT, PCB_CHANNEL, END_TO_END_CHANNEL_RESULT, PACKAGE, EXPERIMENTS];
    case CASCADE:
      return [PCB, PACKAGE, PCB_PRE_LAYOUT, DESIGN_TREE, DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, SIGN_OFF_TEMPLATE, SINGLE_TREE]
    case SIERRA:
      return [PCB, PCB_PRE_LAYOUT, VERIFICATION, RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, VERIFICATION_GROUP];
    case ROCKY:
      return [PCB, PCB_PRE_LAYOUT, VERIFICATION, RESULT, PACKAGE, PACKAGE_VERIFICATION, PACKAGE_RESULT, CARD, CARD_VERIFICATION, CARD_RESULT, SSN_CHANNEL, SSN_RESULT, PACKAGE_PDN, PACKAGE_PDN_RESULT, CHANNEL, PCB_CHANNEL, PCB_CHANNEL_RESULT, PCB_PDN, PCB_PDN_RESULT, PACKAGE_PRE_LAYOUT, PACKAGE_PRE_LAYOUT_RESULT, PCB_PRE_LAYOUT_RESULT];
    case FASTPI:
      return [PCB, PDN, RESULT];
    case HIMALAYAS:
      return [SPICE_CARD_TEMPLATE, IO_SIM, RESULT];
    default:
      return [VERIFICATION, RESULT, PCB];
  }
}

function getPageKeyList(key) {
  switch (key) {
    case VERIFICATION:
    case RESULT:
    case PCB_CHANNEL:
    case END_TO_END_CHANNEL:
    case PCB_CHANNEL_RESULT:
    case END_TO_END_CHANNEL_RESULT:
    case EXPERIMENTS:
    case EXPERIMENTS_RESULT:
    case PACKAGE_VERIFICATION:
    case PACKAGE_RESULT:
    case CARD_VERIFICATION:
    case CARD_RESULT:
    case SSN_CHANNEL:
    case SSN_RESULT:
    case CHANNEL:
    case PACKAGE_PDN:
    case PACKAGE_PDN_RESULT:
    case PCB_PDN:
    case PCB_PDN_RESULT:
    case VERIFICATION_GROUP:
      return [VERIFICATION, RESULT, PCB_CHANNEL, END_TO_END_CHANNEL, PCB_CHANNEL_RESULT,
        END_TO_END_CHANNEL_RESULT, EXPERIMENTS, EXPERIMENTS_RESULT, PACKAGE_VERIFICATION, PACKAGE_RESULT,
        CARD_VERIFICATION, CARD_RESULT, PACKAGE_PDN, PACKAGE_PDN_RESULT, SSN_CHANNEL, SSN_RESULT, CHANNEL,
        PCB_PDN, PCB_PDN_RESULT, VERIFICATION_GROUP, PACKAGE_PRE_LAYOUT_RESULT, PCB_PRE_LAYOUT_RESULT];
    case PCB:
    case PCB_PRE_LAYOUT:
    case PACKAGE:
    case CARD:
    case PACKAGE_PRE_LAYOUT:
      return [PCB, PCB_PRE_LAYOUT, PACKAGE, CARD, PACKAGE_PRE_LAYOUT];
    case DCR:
    case IR_EXPLORER:
    case IMPEDANCE:
    case POWER_TREE:
    case SIGN_OFF_TEMPLATE:
    case DESIGN_TREE:
    case SINGLE_TREE:
      return [DCR, IR_EXPLORER, IMPEDANCE, RESULT, IR_EXPLORER_RESULT, IMPEDANCE_RESULT, POWER_TREE, SIGN_OFF_TEMPLATE, DESIGN_TREE, SINGLE_TREE];
    case IR_EXPLORER_RESULT:
    case IMPEDANCE_RESULT:
      return [DCR, IR_EXPLORER, IMPEDANCE, POWER_TREE, DESIGN_TREE, SINGLE_TREE, SIGN_OFF_TEMPLATE]
    case SPICE_CARD_TEMPLATE:
    case IO_SIM:
      return [SPICE_CARD_TEMPLATE, IO_SIM, RESULT]
    default:
      return [];
  }
}

function getViewListBySelectedKey(selectedKeys) {
  let viewList = selectedKeys.map(d => d.split('-')[0]);
  return [...new Set(viewList)];
}

/**
 * Update selectedKey and viewList after changing PCB layout
 */
function updateTreeAfterChangePCBLayout(selectedKeys, viewList) {
  const pcbs = selectedKeys.filter(d => [PCB, PCB_PRE_LAYOUT, PACKAGE].includes(d.split('-')[0]));
  if (pcbs.length > 1) {
    const _selectedKeys = selectedKeys.filter(k => k !== pcbs[0]);
    const pcbKey = pcbs[1].split('-')[0];
    const filterKeys = [PCB, PCB_PRE_LAYOUT, PACKAGE].filter(d => d !== pcbKey);
    const _viewList = viewList.filter(d => !filterKeys.includes(d));
    return {
      _selectedKeys,
      _viewList
    }
  }
  return null;
}

export {
  filterKeys,
  updateKeys,
  updateTreeSelectKeys,
  getPageKeyList,
  getViewListBySelectedKey,
  updateTreeAfterChangePCBLayout
}