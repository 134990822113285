import React, { PureComponent } from 'react';
import DCMenu from './DCMenu';
import { pinColumns } from '@/services/Result/Public/DCTable/tableData';
import DCTable from './resultContent';

function bgaResultContent() {
  return (
    <div>
      <DCMenu />
      <DCTable
        tableColumns={pinColumns}
        chartXType='voltageDrop'
        chartYType='pin'
        svgHeight='500'
        rowKey='components'
        chartVisible={true}
      />
    </div>
  )
}

export default bgaResultContent
