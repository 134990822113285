import React, { Component, Fragment } from 'react';
import Panel from '@/components/Panel';
import UploadFile from '@/components/UploadFile';
import { createPortal } from 'react-dom';
import { uploadReportTemplate } from '@/services/Template'
import { checkNameFormat } from '@/services/helper/nameFormatCheck';
import '@/pages/Rocky/libraryPanel/rockyPanel.css';

class UploadTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      errorFileList: [],
      checking: false,
      uploading: false,
      errorMsg: '',
      uploadProgress: -1,
      errorName: []
    }
    this.dialogRoot = document.getElementById('root');
  }

  onUploadFile = (fileList, errorFileList) => {
    const { templateList } = this.props;
    fileList.forEach(file => {
      file.file.fileName = file.fileName;
      let _checkName = file.fileName.replace(/(.pptx)|(.ppt)/g, '');
      let index = templateList.filter(template => template.name.replace(/(.pptx)|(.ppt)/g, '') === _checkName);
      let errorMsg = '';
      if (index && index.length > 0) {
        errorMsg = 'The name already exists.';
      }
      //check name format
      errorMsg = checkNameFormat(_checkName);
      this.setState({
        errorMsg,
        checking: false,
        errorName: [file.fileName]
      });
    });
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    });
  }

  delFile = (fileList, errorFileList) => {
    this.setState({
      fileList: [...fileList],
      errorFileList: [...errorFileList]
    })
  }

  changeFileName = (name, prevName) => {
    const { fileList } = this.state;
    let _fileList = [...fileList];
    const findIndex = _fileList.findIndex(item => item.name === prevName);
    const fileSuffix = _fileList[findIndex].fileSuffix;
    _fileList[findIndex].name = name;
    _fileList[findIndex].fileName = `${name}${fileSuffix}`;
    _fileList[findIndex].file.fileName = `${name}${fileSuffix}`;
    this.setState({
      fileList: [..._fileList]
    });
    let _checkName = name.replace(/(.pptx)|(.ppt)/g, '');
    let errorMsg = checkNameFormat(_checkName), errorName = [];
    if (errorMsg) {
      errorName = [name];
    }
    this.setState({
      errorMsg: errorMsg,
      checking: false,
      errorName: errorName
    })
  }

  config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setTimeout(() => {
        this.setState({
          compressProgress: 100,
          uploadProgress: percentCompleted,
        });
      }, 50);
    }
  }

  saveTemplateFile = () => {
    const { fileList, uploadProgress } = this.state;
    const { templateList } = this.props;
    if (uploadProgress === -1) {
      this.setState({
        checking: true,
        errorMsg: '',
      });
      if (!fileList.length) {
        this.props.closeTemplatePanel(false);
        return;
      }
      let name = fileList[0].fileName.replace(/(.pptx)|(.ppt)/g, '');
      let index = templateList.filter(template => template.name.replace(/(.pptx)|(.ppt)/g, '') === name);
      if (index && index.length > 0) {
        this.setState({
          errorMsg: 'The name already exists.',
          checking: false,
          errorName: [name]
        });
        return;
      }
      const errorMsg = checkNameFormat(name);
      if (errorMsg) {
        this.setState({
          errorMsg,
          checking: false,
          errorName: [name]
        });
        return;
      }
      this.setState({
        checking: false,
        uploading: true
      })
      let libraries = [];
      let files = [];
      fileList.forEach(file => {
        libraries.push({
          name: file.fileName,
          fileName: file.fileName,
          libraryData: {}
        });
        files.push(file.file);
      });
      uploadReportTemplate({ files, name, metaData: this.metadata, config: this.config }).then(res => {
        if (res) {
          this.props.uploadTemplateSave(res.id);
          this.closeUploadTemplate(100);
        } else {
          this.closeUploadTemplate(-1, "Upload template failed.");
        }
      }, error => {
        console.error(error);
        this.closeUploadTemplate(-1, `${error} Upload template failed.`);
      });
    }
  }

  closeUploadTemplate = (progress, error) => {
    this.setState({
      uploading: false,
      repeatedName: [],
      uploadProgress: progress,
      errorMsg: error
    });
    if (!error) {
      setTimeout(() => {
        this.props.closeTemplatePanel(true);
      }, 500);
    }
  }

  shouldClosePanel = () => {
    if (this.state.uploading) {
      return;
    }
    this.props.closeTemplatePanel(false);
  }

  render() {
    const { title } = this.props;
    const { errorMsg, checking, errorName, uploading } = this.state;
    const accept = '.pptx';
    const content = (
      <Fragment>
        <Panel
          className='rocky-library-panel'
          position='panel-center-left'
          title={title}
          zIndex={2000}
          onCancel={this.shouldClosePanel}
          width={400}
          draggable
          minWidth={300}
          minHeight={180}
        >
          <UploadFile
            text={`Template Upload`}
            uploading={uploading}
            accept={accept}
            onUploadFile={this.onUploadFile}
            errorMsg={errorMsg}
            checking={checking}
            errorName={errorName}
            changeFileName={this.changeFileName}
            saveFile={this.saveTemplateFile}
            delFile={this.delFile}
          />
        </Panel>
      </Fragment>
    )
    return createPortal(content, this.dialogRoot)
  }
}

export default UploadTemplate;