export const CASCADE_PROJECT_VERSION = '0.0.1';

// IMPEDANCE
export const MODEL_MATCH_WEIGHTS = 5;
// type
export const IMPEDANCE_PCB = 'pcb',
  IMPEDANCE_PACKAGE = 'package',
  IMPEDANCE_DIE = 'Die',
  IMPEDANCE_PCB_PRELAYOUT = 'pcb_pre_layout',
  IMPEDANCE_INTERPOSER = 'interposer'
// -- TARGET
export const TARGET_TOUCHSTONE = 'touchstone',
  TARGET_TABLE = 'table',
  TARGET_RL_VALUE = 'RLvalue';

export const TARGET_PCB_INDEX = 1,
  TARGET_PACKAGE_INDEX = 0,
  TARGET_DIE_INDEX = -1;

// Library custom model
export const ON_DIE = 'On-Die', PASSIVE = 'Passive', INTERPOSER = 'Interposer', CONNECTOR = 'Connector';
export const TagList = [ON_DIE, PASSIVE, INTERPOSER];

// Title
export const IMPEDANCE_TITLE = 'Z Explorer',
  IR_TITLE = 'V Explorer',
  DCR_TITLE = 'R Explorer',
  TEMPLATE_TITLE = 'Sign-off Template',
  FULL_TITLE = 'Full PCB Power Tree',
  MULTI_TITLE = 'Multi PCB Power Tree',
  SINGLE_TITLE = 'Single PCB Power Tree',
  PCB_TITLE = 'PCB',
  PACKAGE_TITLE = 'Package',
  TREE_TITLE = 'Power Tree';
// -- Die Current
export const CURRENT_TRAPEZOIDAL = 'trapezoidal',
  CURRENT_PWL_TABLE = 'pwl_table',
  CURRENT_PWL_FILE = 'spice';

export const DIE_CURRENT = 'die_current';

