import CeGeometry from './CeGeometry';
import CeVertex from './CeVertex';
import GCPolygon from './GCPolygon';
import StringList from '../utility/StringList';
import CeIODataItem from '../CeFileIO/CeIODataItem';

const CeBox2D = function () {
  CeGeometry.call(this, "Box2d");
  this.mMinPnt = new CeVertex();
  this.mMaxPnt = new CeVertex();
}

// subclass extends superclass
CeBox2D.prototype = Object.create(CeGeometry.prototype);
CeBox2D.prototype.constructor = CeBox2D;

CeBox2D.prototype.SetData = function (x1, y1, x2, y2) {
  var xmin = x1 < x2 ? x1 : x2;
  var ymin = y1 < y2 ? y1 : y2;
  var xmax = x1 > x2 ? x1 : x2;
  var ymax = y1 > y2 ? y1 : y2;
  this.mMinPnt.SetData(xmin, ymin);
  this.mMaxPnt.SetData(xmax, ymax);
}

CeBox2D.prototype.Center = function () {
  var cx = 0.5 * (this.mMinPnt.mX + this.mMaxPnt.mX);
  var cy = 0.5 * (this.mMinPnt.mY + this.mMaxPnt.mY);
  var pnt = new CeVertex();
  pnt.SetData(cx, cy);
  return pnt;
}

CeBox2D.prototype.Width = function () {
  return this.mMaxPnt.mX - this.mMinPnt.mX;
}

CeBox2D.prototype.Height = function () {
  return this.mMaxPnt.mY - this.mMinPnt.mY;
}

CeBox2D.prototype.Expand = function (pnt) {
  if (pnt.mX < this.mMinPnt.mX)
    this.mMinPnt.mX = pnt.mX;
  if (pnt.mY < this.mMinPnt.mY)
    this.mMinPnt.mY = pnt.mY;
  if (pnt.mX > this.mMaxPnt.mX)
    this.mMaxPnt.mX = pnt.mX;
  if (pnt.mY > this.mMaxPnt.mY)
    this.mMaxPnt.mY = pnt.mY;
}

CeBox2D.prototype.ExpandParc = function (pnt) {
  if (pnt.minX < this.mMinPnt.mX)
    this.mMinPnt.mX = pnt.minX;
  if (pnt.minY < this.mMinPnt.mY)
    this.mMinPnt.mY = pnt.minY;
  if (pnt.maxX > this.mMaxPnt.mX)
    this.mMaxPnt.mX = pnt.maxX;
  if (pnt.maxY > this.mMaxPnt.mY)
    this.mMaxPnt.mY = pnt.maxY;
}

CeBox2D.prototype.Left = function () {
  return this.mMinPnt.mX;
}

CeBox2D.prototype.Right = function () {
  return this.mMaxPnt.mX;
}

CeBox2D.prototype.Top = function () {
  return this.mMaxPnt.mY;
}

CeBox2D.prototype.Bottom = function () {
  return this.mMinPnt.mY;
}

CeBox2D.prototype.equals = function (obj) {
  if (!(obj instanceof CeBox2D))
    return false;

  var fromObj = obj;

  if (this.mMinPnt.equals(fromObj.mMinPnt) == false ||
    this.mMaxPnt.equals(fromObj.mMaxPnt) == false)
    return false;

  return true;
}

CeBox2D.prototype.Move = function (dx, dy) {
  this.mMinPnt.Move(dx, dy);
  this.mMaxPnt.Move(dx, dy);
}

CeBox2D.prototype.CopyFrom = function (fromObj) {
  this.mMinPnt.CopyFrom(fromObj.mMinPnt);
  this.mMaxPnt.CopyFrom(fromObj.mMaxPnt);
  CeGeometry.prototype.CopyFrom.call(this, fromObj);
}

CeBox2D.prototype.Clone = function () {
  var clnObj = new CeBox2D();
  clnObj.CopyFrom(this);
  return clnObj;
}

CeBox2D.prototype.MoveToCenterLocalCS = function () {
  var center = new CeVertex();
  center.mX = 0.5 * (this.mMinPnt.mX + this.mMaxPnt.mX);
  center.mY = 0.5 * (this.mMinPnt.mY + this.mMaxPnt.mY);
  this.mMinPnt.mX -= center.mX;
  this.mMinPnt.mY -= center.mY;
  this.mMaxPnt.mX -= center.mX;
  this.mMaxPnt.mY -= center.mY;
  return center;
}

CeBox2D.prototype.ConvertToGCPolygon = function () {
  var xArray = [];
  var yArray = [];
  xArray[0] = this.mMinPnt.mX;
  yArray[0] = this.mMinPnt.mY;
  xArray[1] = this.mMaxPnt.mX;
  yArray[1] = this.mMinPnt.mY;
  xArray[2] = this.mMaxPnt.mX;
  yArray[2] = this.mMaxPnt.mY;
  xArray[3] = this.mMinPnt.mX;
  yArray[3] = this.mMaxPnt.mY;
  var gcPolygon = new GCPolygon();
  gcPolygon.SetData(4, xArray, yArray);
  return gcPolygon;
}

CeBox2D.prototype.GetDataString = function () {
  var defData = new StringList();
  defData.push(this.mMinPnt.GetDataString());
  defData.push(this.mMaxPnt.GetDataString());
  return defData;
}

CeBox2D.prototype.UpdateByString = function (dataVals) {
  // llX, llY, urX, urY
  if (dataVals.size() != 4)
    return false;

  var minPntVals = new StringList();
  minPntVals.push(dataVals.get(0));
  minPntVals.push(dataVals.get(1));
  this.mMinPnt = new CeVertex();
  this.mMinPnt.UpdateByString(minPntVals);

  var maxPntVals = new StringList();
  maxPntVals.push(dataVals.get(2));
  maxPntVals.push(dataVals.get(3));
  this.mMaxPnt = new CeVertex();
  this.mMaxPnt.UpdateByString(maxPntVals);

  return true;
}

CeBox2D.prototype.WriteIntoIODataNode = function () {
  var dataVals = this.mMinPnt.GetDataString();
  dataVals.push(this.mMaxPnt.GetDataString());
  return new CeIODataItem(this.GetGeomType(), dataVals);
}

CeBox2D.prototype.ReadFromIODataNode = function (fileItem) {
  var dataVals = fileItem.GetItemValue();
  return this.UpdateByString(dataVals);
}

export default CeBox2D;