import { unitConversion } from '../../helper/dataProcess';
import NP from 'number-precision';
import { scaleKeys } from '../../helper/constants';
import { toNonExponential } from '../../helper/numberHelper';
import { DDR3, DDR3L, DDR3_CLOCKs, DDR4, DDR4L, DDR4_CLOCKs, DDR5, NVDDR3, DDR4MR, DDR5_CLOCKs, LPDDR4, LPDDR4_CLOCKs, LPDDR5, LPDDR5_CLOCKs, portSavePath, DDR5_RDIMM, DDR5_SODIMM, DDR5_UDIMM, DDR5_RDIMM_CARD, DDR5_SODIMM_CARD, DDR5_UDIMM_CARD, DDR5_RDIMM_X4 } from '../constants';
import RockyProjectChannels from './projectChannels';
import RockyChannels from './channels';
import RockyChannelVerifications from './channelVerifications';
import { getInterfaceType } from './setupData';
import { splitComponentName } from '../../helper/splitComponent';
import DesignInfo from '@/services/Rocky/pcbInfo';
import { getDefaultPortSetupList } from "@/services/ExtractionPortsHelper";
import { getDefaultPortsGenerateSetupList } from './extractionPortsHelper';
import { updateInterfaceInfo } from '../helper/setupData';
import { GAP, SPHEROID, WAVE } from '../../ExtractionPortsHelper';
import { BALL_TYPE_NONE, USE_BALL_LIST } from '../../ExtractionPortsHelper/portTableHelper';
import { PROJECT_V2 } from '../../../constants/projectVersion';

const ChipTypes = ['Controller', 'Memory'];

//Round to the nearest multiple of 5
/* 
const grade1 = 15.2
const grade2 = 17.1
const grade3 = 12.3 */
/* console.log(roundGrade(grade1))
console.log(roundGrade(grade2))
console.log(roundGrade(grade3)) */
function roundGrade(grade) {
  const rem = grade % 5;
  return rem < 2.5 ? grade - rem : grade + (5 - rem);
}

function fakeReportProgress(progress, realProgress) {
  let _progress = progress;
  _progress = parseInt((_progress + Math.random()) * 10) / 10;

  if (realProgress > _progress) {
    _progress = realProgress;
  }

  if (_progress >= 96) {
    _progress = 96;
  }
  return _progress;
}

function getTimeStep(config, clock, clockType = 'clock') {
  //Time Step = 1/(100F) where F is frequency in Hz.
  //unit conversion scale
  const scale = unitConversion(0, scaleKeys[config[clockType].unit]);
  //Calculate the time step according to the formula
  let newTimeStep = NP.strip(NP.divide(1, NP.times(clock, scale, 100)));
  //unit conversion
  //Convert to ps first
  const sScale = unitConversion(-4, 0);
  newTimeStep = NP.strip(NP.times(newTimeStep, sScale));

  //Round to the nearest multiple of 5
  newTimeStep = roundGrade(newTimeStep);

  if (newTimeStep > 25) {
    newTimeStep = 25;
  }

  if (newTimeStep < 5) {
    newTimeStep = 5;
  }

  //Convert ps to current unit
  const pScale = unitConversion(scaleKeys[config[clockType === 'clock' ? 'timeStep' : 'caTimeStep'].unit], -4);
  newTimeStep = NP.strip(NP.times(newTimeStep, pScale));
  newTimeStep = toNonExponential(newTimeStep)

  return newTimeStep;
}

function getClockListByDDRType(ddrType) {
  let DDRClockList = [];
  switch (ddrType) {
    case DDR3:
    case DDR3L:
      DDRClockList = DDR3_CLOCKs;
      break;
    case DDR4:
    case DDR4L:
      DDRClockList = DDR4_CLOCKs;
      break;
    case LPDDR4:
      DDRClockList = LPDDR4_CLOCKs;
      break;
    case DDR5:
    case DDR5_RDIMM:
    case DDR5_SODIMM:
    case DDR5_UDIMM:
    case DDR5_RDIMM_CARD:
    case DDR5_SODIMM_CARD:
    case DDR5_UDIMM_CARD:
    case DDR5_RDIMM_X4:
      DDRClockList = DDR5_CLOCKs;
      break;
    case LPDDR5:
      DDRClockList = LPDDR5_CLOCKs;
      break;
    default: break;
  }
  return DDRClockList;
}

function getDefaultClockAndTimeStep(projectType) {
  let clock, timeStep;
  switch (projectType) {
    case DDR3:
    case DDR3L:
    case NVDDR3:
      clock = '800M';
      timeStep = '15p';
      break;
    case DDR5:
    case DDR5_RDIMM:
    case DDR5_SODIMM:
    case DDR5_UDIMM:
    case DDR5_RDIMM_CARD:
    case DDR5_SODIMM_CARD:
    case DDR5_UDIMM_CARD:
    case DDR5_RDIMM_X4:
      clock = '2400M';
      timeStep = '5p';
      break;
    case LPDDR5:
      clock = '3200M';
      timeStep = '5p';
      break;
    case DDR4:
    case DDR4MR:
    case LPDDR4:
    default:
      clock = '1200M';
      timeStep = '10p';
      break;
  }
  return { clock, timeStep }
}


function getCopySetupChannelList({ currentProjectId, verificationName, verificationId, currentChannelId, projectVersion }) {
  const channelIds = RockyProjectChannels.get(currentProjectId);
  const type = getInterfaceType(verificationName);
  const rule = /CLK_(ADR_CMD|ADR|CMD)/g;
  const channelList = channelIds.map(id => {
    const channel = RockyChannels.get(id);
    const children = RockyChannelVerifications.getVerificationsByChannelId(id) || [];
    let list = [];
    if (type === 'BYTE') {
      list = children.filter(item => getInterfaceType(item.name) === 'BYTE' && (id !== currentChannelId || item.id !== verificationId))
      list.unshift({ id: "allBytes", key: "allBytes", name: "All Bytes" });
    } else if (type === 'CLK') {
      list = children.filter(item => getInterfaceType(item.name) === 'CLK' && (id !== currentChannelId || item.id !== verificationId))
      if (projectVersion === PROJECT_V2 && list.length) {
        if (currentChannelId === id && verificationName) {
          const nameList = ['CLK_ADR', "CLK_CMD"].map(item => { return verificationName.replace(rule, item) })
          list = list.filter(item => !nameList.includes(item.name));
        }
        let newList = [], reNameList = [];
        for (let data of list) {
          const { name } = data;
          const replaceName = name.replace(rule, "CA");
          if (reNameList.includes(replaceName)) { continue };
          reNameList.push(replaceName)
          newList.push({
            ...data,
            name: replaceName
          })
        }
        list = [...newList]
      }
      currentChannelId !== id && list.unshift({ id: "allCLK", key: "allCLK", name: "All ADR and CMD" });
    }
    return {
      id,
      name: channel ? channel.name : "",
      children: list
    }
  });
  return channelList;
}

function getCopyPortSetupAllInterfaces({ currentProjectId, verificationName, verificationId, currentChannelId, currentProjectInterfaceInfo }) {
  const channelIds = RockyProjectChannels.get(currentProjectId);
  let copyChannelList = []
  const channelList = channelIds.map(id => {
    const channel = RockyChannels.get(id);
    const children = RockyChannelVerifications.getVerificationsByChannelId(id) || [];
    const list = children.filter(item => ['BYTE', 'CLK'].includes(getInterfaceType(item.name)) && (id !== currentChannelId || item.id !== verificationId))
    copyChannelList = [...copyChannelList, ...list]
    return {
      id,
      name: channel ? channel.name : "",
      children: list
    }

  })
  return { copyChannelList, channelIds }
}

function getCopyPackageInterface({ info, content, extraction, ComponentSetups }) {
  const _content = { ...content }
  const pcbInfo = DesignInfo.getPCBInfo(info.designId);
  let { PowerNets } = content;

  if (!PowerNets || !PowerNets.length) {
    let new_Interfaces = updateInterfaceInfo({ Interfaces: [{ Content: content }], pcbId: info.designId, pcbInfo })
    PowerNets = new_Interfaces[0].Content.PowerNets.length ? new_Interfaces[0].Content.PowerNets : [];
  }

  let { Ports_generate_setup_list, Components, Signals, Ports_generation_setup } = content;

  //update ports generation setup format
  if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
    Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
  }

  Ports_generate_setup_list.forEach(item => {
    const findComp = Components.find(comp => splitComponentName(comp.name) === item.component)
    if (findComp.type === "BGA") {
      item.setup = info.ports_generate_setup_list[0].setup;
    } else if (findComp.type === 'DIE') {
      item.setup = info.ports_generate_setup_list[1].setup
    }
  })

  // filter referenceNets
  const allReferenceNets = PowerNets.map(net => net.name)
  const _referenceNets = info.referenceNets.filter(net => allReferenceNets.includes(net))
  const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
    components: Components,
    signals: Signals,
    pcbInfo: pcbInfo,
    referenceNets: _referenceNets,
    designId: info.designId,
    ports_generate_setup_list: Ports_generate_setup_list,
    referenceZ0: info.referenceZ0,
    extractionType: extraction.type
  });

  _content.Ports_generate_setup_list = setupList;
  _content.ReferenceNets = _referenceNets;
  _content.Port_setups = port_setups;
  _content.Components.forEach(comp => {
    const compName = splitComponentName(comp.name);
    const filterComp = ComponentSetups.filter(it => it.type === comp.type);
    const compPortSetup = setupList.find(it => it.component === compName) || { setup: {} };
    if (filterComp && filterComp.length) {
      if ([...USE_BALL_LIST].includes(compPortSetup.setup.portType)) {
        if (compPortSetup.setup.portType === GAP) {
          comp.gap_size = filterComp[0].gap_size || "0";
        }
        comp.ball_type = filterComp[0].ball_type;

        if (comp.ball_type === BALL_TYPE_NONE) {
          delete comp.ball_size;
          delete comp.ball_height;
          delete comp.ball_material;
        } else {
          comp.ball_size = filterComp[0].ball_size;
          comp.ball_height = filterComp[0].ball_height;
          comp.ball_material = filterComp[0].ball_material;
        }

        if (comp.ball_type === SPHEROID) {
          comp.ball_mid_diameter = filterComp[0].ball_mid_diameter;
        } else {
          delete comp.ball_mid_diameter;
        }
      } else {
        delete comp.gap_size;
        delete comp.ball_size;
        delete comp.ball_height;
        delete comp.ball_mid_diameter;
        delete comp.ball_type;
        delete comp.ball_material;
      }
    }
  })
  return _content
}

function getCopyInterfaces({ info, interfaces, ComponentSetups, currentChannelInfo, projectType, extraction }) {
  let _interfaces = [...interfaces]
  const pcbInfo = DesignInfo.getPCBInfo(info.designId);
  const index = _interfaces.findIndex(i => portSavePath.find(p => i.name.match(p)));
  const content = _interfaces[index].Content;
  let { PowerNets } = content;

  let new_Interfaces = {}
  if ((!PowerNets || !PowerNets.length) && currentChannelInfo && currentChannelInfo.PowerNets) {
    new_Interfaces = updateInterfaceInfo({ Interfaces: interfaces, pcbId: info.designId, pcbInfo, projectType, currentChannelInfo, isReplace: false });
    PowerNets = new_Interfaces[index].Content.PowerNets.length ? new_Interfaces[index].Content.PowerNets : currentChannelInfo.PowerNets;
  }

  // Update Ports_generate_setup_list according to type
  let { Ports_generate_setup_list, Components, Signals, Ports_generation_setup } = _interfaces[index].Content;
  //update ports generation setup format
  if (!Ports_generate_setup_list || !Ports_generate_setup_list.length) {
    Ports_generate_setup_list = getDefaultPortsGenerateSetupList({ Ports_generation_setup, Components });
  }

  Ports_generate_setup_list.forEach(item => {
    const findComp = Components.find(comp => splitComponentName(comp.name) === item.component)
    if (findComp.type === "Controller") {
      item.setup = info.ports_generate_setup_list[0].setup;
    } else if (findComp.type === 'Memory') {
      item.setup = info.ports_generate_setup_list[1].setup
    }
  })

  // filter referenceNets
  const allReferenceNets = PowerNets && PowerNets.length ? PowerNets.map(net => net.name) : [];
  const _referenceNets = info.referenceNets.filter(net => allReferenceNets.includes(net))
  const { port_setups, ports_generate_setup_list: setupList } = getDefaultPortSetupList({
    components: Components,
    signals: Signals,
    pcbInfo: pcbInfo,
    referenceNets: _referenceNets,
    designId: info.designId,
    ports_generate_setup_list: Ports_generate_setup_list,
    types: ChipTypes,
    referenceZ0: info.referenceZ0,
    extractionType: extraction.channelType
  });

  _interfaces[index].Content.Ports_generate_setup_list = setupList;
  _interfaces[index].Content.ReferenceNets = _referenceNets;
  _interfaces[index].Content.Port_setups = port_setups;
  _interfaces.forEach(item => {
    item.Content.Components.forEach(comp => {
      if (['Controller', 'Memory'].includes(comp.type) && !comp.corner) {
        comp.corner = 'typ';
      }
      // if (!comp.corner) { comp.corner = 'typ'; }
      const compName = splitComponentName(comp.name);
      const filterComp = ComponentSetups.filter(it => it.type === comp.type);
      const compPortSetup = setupList.find(it => it.component === compName) || { setup: {} };
      if (filterComp && filterComp.length) {
        if ([...USE_BALL_LIST].includes(compPortSetup.setup.portType)) {
          if (compPortSetup.setup.portType === GAP) {
            comp.gap_size = filterComp[0].gap_size || "0";
          }
          comp.ball_type = filterComp[0].ball_type;
          if (comp.ball_type === BALL_TYPE_NONE) {
            delete comp.ball_size;
            delete comp.ball_height;
            delete comp.ball_material;
          } else {
            comp.ball_size = filterComp[0].ball_size;
            comp.ball_height = filterComp[0].ball_height;
            comp.ball_material = filterComp[0].ball_material;
          }
          if (comp.ball_type === SPHEROID) {
            comp.ball_mid_diameter = filterComp[0].ball_mid_diameter;
          } else {
            delete comp.ball_mid_diameter;
          }
        } else {
          delete comp.gap_size;
          delete comp.ball_size;
          delete comp.ball_height;
          delete comp.ball_mid_diameter;
          delete comp.ball_type;
          delete comp.ball_material;
        }
      }
    })
  })
  return { interfaces: _interfaces }
}

export { roundGrade, getTimeStep, fakeReportProgress, getClockListByDDRType, getCopySetupChannelList, getDefaultClockAndTimeStep, getCopyPortSetupAllInterfaces, getCopyInterfaces, getCopyPackageInterface };
