import {
  GET_VERIFICATION_CONTENT,
  UPDATE_SSN_CENTRIC_INFO,
  UPDATE_CENTRIC_CONTENT_LOADING,
  UPDATE_SELECT_INTERFACE_INFO,
  CHANGE_SELECT_INTERFACE_INFO,
  SAVE_CURRENT_SSN_VERIFICATION,
  UPDATE_SSN_PDN_CONTENT,
  JUMP_TO_PACKAGE_PDN,
  UPDATE_SSN_PART_CONTENT,
  UPDATE_PATTERN_LIBRARY,
  UPDATE_PATTERN_SWEEP_TABLE,
  UPDATE_PATTERN_SWEEP_INFO,
  UPDATE_VERIFICATION_IDS,
  UPDATE_SOME_CONTENT_INFO,
  CLEAR_SSN_INFO,
  SAVE_SSN_CONTENT,
  UPDATE_BUFFER_MODEL_BY_CSM
} from './actionType';

export const getCentricVerificationContent = (id, name) => ({
  type: GET_VERIFICATION_CONTENT,
  id,
  name,
})

export const updateSSNCentricInfo = (info) => ({
  type: UPDATE_SSN_CENTRIC_INFO,
  info
})

export const updateCentricContentLoading = (boolean) => ({
  type: UPDATE_CENTRIC_CONTENT_LOADING,
  boolean
})

export const updateSelectInterfaceInfo = (info) => ({
  type: UPDATE_SELECT_INTERFACE_INFO,
  info
})

export const changeSelectInterfaceInfo = (info) => ({
  type: CHANGE_SELECT_INTERFACE_INFO,
  info
})

export const saveCurrentSSNVerification = (verificationIds, isSSN, ssnVerificationId) => ({
  type: SAVE_CURRENT_SSN_VERIFICATION,
  verificationIds,
  isSSN,
  ssnVerificationId
})

export const updateSSNPdnContent = (pdn, notLoading, updateCSMInfo) => ({
  type: UPDATE_SSN_PDN_CONTENT,
  pdn,
  notLoading,
  updateCSMInfo
})

export const jumpToPackagePdn = (id) => ({
  type: JUMP_TO_PACKAGE_PDN,
  id
})

export const saveSSNPartContent = (updateInfo, notLoading) => ({
  type: UPDATE_SSN_PART_CONTENT,
  updateInfo,
  notLoading
})

export const updatePatternLibrary = (libraryId) => ({
  type: UPDATE_PATTERN_LIBRARY,
  libraryId
})

export const updatePatternSweepTable = (updatePatternTable) => ({
  type: UPDATE_PATTERN_SWEEP_TABLE,
  updatePatternTable
})

// export const updatePatternSweepInfo = (patternSweeps) => ({
//   type: UPDATE_PATTERN_SWEEP_INFO,
//   patternSweeps
// })

export const updateVerificationIds = (updateInfo) => ({
  type: UPDATE_VERIFICATION_IDS,
  updateInfo
})

export const updateSomeContentInfo = (info) => ({
  type: UPDATE_SOME_CONTENT_INFO,
  info
})

export const cleanSSNInfo = () => ({
  type: CLEAR_SSN_INFO
})

export const saveSSNInfo = (immediately) => ({
  type: SAVE_SSN_CONTENT,
  immediately
});

export const updateBufferModelByCSM = ({ id, updateCSMInfo }) => ({
  type: UPDATE_BUFFER_MODEL_BY_CSM,
  id,
  updateCSMInfo
})