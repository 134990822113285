import { updateModelsAndCableModels } from ".";
import channelSetupInfo from "../channel/channelInfo";
import { getChannelInfo } from "./connectionHelper";

function _updateEndToEndByChannelDeletedSignals({ content, channelId, deletedSignals, deletedComps }) {
  let _content = JSON.parse(JSON.stringify(content)), endToEndSignal = null;
  let pcbConnections = content.pcbConnections, connections = content.connections;
  const channel = pcbConnections.find(item => item.channelId === channelId);
  if (!channel) {
    return _content;
  }

  const prevConnectionId = channel.prevConnectionId,
    nextConnectionId = channel.nextConnectionId;
  //prev connection
  if (prevConnectionId) {
    const index = connections.findIndex(item => item.CONNECTION_ID === prevConnectionId);
    //signal_connections_map
    const signalMaps = connections[index].connection.signal_connections_map;
    //find deleted signal names by deletedSignals
    const deleteSignals = signalMaps.filter(item => item.channel2_signal && deletedSignals.includes(item.channel2_signal)).map(item => item.channel2_signal);
    let connector2 = connections[index].connection.connector2 || { pins: [] },
      connector1 = connections[index].connection.connector1 || { pins: [] };
    //delete signals and pins
    if (deleteSignals.length && connector2.component) {
      const _deleteComps = deletedComps.filter(item => item.name === connector2.component);
      const deletePins = _deleteComps.map(item => item.pin);
      //delete connector2 pin
      connector2.pins = connector2.pins.filter(item => !deletePins.includes(item.pin));
      //delete connector pin_map by connector1 deleted pins
      connector1.pins.forEach(item => { if (deletePins.includes(item.pin_map)) { item.pin_map = "" } });
      connections[index].connection.connector1 = connector1;
      connections[index].connection.connector2 = connector2;
    }
    deleteSignals.length && connections[index].connection.signal_connections_map.forEach(item => {
      if (deleteSignals.includes(item.channel2_signal)) {
        item.channel2_signal = "";
        endToEndSignal = item.name;
      }
    });
    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1: connections[index].connection.connector1,
      connector2: connections[index].connection.connector2,
      cableModels: connections[index].connection.cableModels
    });
    connections[index].connection.connector1 = updateConn.connector1;
    connections[index].connection.connector2 = updateConn.connector2;
    connections[index].connection.cableModels = updateConn.cableModels;
  }

  //next connection
  if (nextConnectionId) {
    //find connection
    const index = connections.findIndex(item => item.CONNECTION_ID === nextConnectionId);
    //signal_connections_map
    const signalMaps = connections[index].connection.signal_connections_map;
    //find deleted signal names by deletedSignals
    const deleteSignals = signalMaps.filter(item => item.channel1_signal && deletedSignals.includes(item.channel1_signal)).map(item => item.channel1_signal);
    let connector1 = connections[index].connection.connector1 || { pins: [] };

    //delete signals and pins
    if (deleteSignals.length && connector1.component) {
      const _deleteComps = deletedComps.filter(item => item.name === connector1.component);
      const deletePins = _deleteComps.map(item => item.pin);
      //delete connector2 pin
      connector1.pins = connector1.pins.filter(item => !deletePins.includes(item.pin));

      connections[index].connection.connector1 = connector1;
    }
    deleteSignals.length && connections[index].connection.signal_connections_map.forEach(item => {
      if (deleteSignals.includes(item.channel1_signal)) {
        item.channel1_signal = "";
        endToEndSignal = item.name;
      }
    });

    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1: connections[index].connection.connector1,
      connector2: connections[index].connection.connector2,
      cableModels: connections[index].connection.cableModels
    });
    connections[index].connection.connector1 = updateConn.connector1;
    connections[index].connection.connector2 = updateConn.connector2;
    connections[index].connection.cableModels = updateConn.cableModels;
  }

  content.pcbConnections = pcbConnections;
  content.connections = connections;

  return { content, endToEndSignal };
}

function _updateEndToEndByChannelComponent({ content, channelId, component }) {
  let _content = JSON.parse(JSON.stringify(content));
  let pcbConnections = content.pcbConnections, connections = content.connections;
  const channel = pcbConnections.find(item => item.channelId === channelId);
  if (!channel) {
    return _content;
  }

  const prevConnectionId = channel.prevConnectionId,
    nextConnectionId = channel.nextConnectionId;
  //prev connection
  if (prevConnectionId) {
    const index = connections.findIndex(item => item.CONNECTION_ID === prevConnectionId);

    let connector2 = connections[index].connection.connector2 || { pins: [] },
      connector1 = connections[index].connection.connector1 || { pins: [] };
    //delete connector component and pins
    if (connector2.component === component) {
      connector2.component = "";
      connector2.pins = [];
      //delete connector pin_map and cable model by connector1 deleted component pins
      connector1.pins.forEach(item => {
        item.pin_map = "";
        if (item.external_map) {
          item.external_map.cablePort = "";
          item.external_map.cableModelId = "";
        }
      });
      connections[index].connection.connector1 = connector1;
      connections[index].connection.connector2 = connector2;
    }
  }

  //next connection
  if (nextConnectionId) {
    //find connection
    const index = connections.findIndex(item => item.CONNECTION_ID === nextConnectionId);
    let connector2 = connections[index].connection.connector2 || { pins: [] },
      connector1 = connections[index].connection.connector1 || { pins: [] };
    //delete connector component and pins
    if (connector1.component === component) {
      connector1.component = "";
      connector1.pins = [];
      //delete connector cable model by connector1 deleted component pins
      connector2.pins.forEach(item => {
        if (item.external_map) {
          item.external_map.cablePort = "";
          item.external_map.cableModelId = "";
        }
      });
      connections[index].connection.connector1 = connector1;
      connections[index].connection.connector2 = connector2;
    }
  }

  content.pcbConnections = pcbConnections;
  content.connections = connections;

  return content;
}

function _updateEndToEndByDeletedComponents({
  content,
  channelId,
  deletedComps
}) {
  let _content = JSON.parse(JSON.stringify(content));
  let pcbConnections = content.pcbConnections, connections = content.connections;
  const channel = pcbConnections.find(item => item.channelId === channelId);
  if (!channel) {
    return _content;
  }

  const prevConnectionId = channel.prevConnectionId,
    nextConnectionId = channel.nextConnectionId;
  //prev connection
  if (prevConnectionId) {
    const index = connections.findIndex(item => item.CONNECTION_ID === prevConnectionId);
    //signal_connections_map
    let connector2 = connections[index].connection.connector2 || { pins: [] },
      connector1 = connections[index].connection.connector1 || { pins: [] };

    //delete component pins
    const _deleteComps = deletedComps.filter(item => item.name === connector2.component);
    if (deletedComps.length && _deleteComps) {
      const deletedPins = _deleteComps.map(item => item.pin);
      connector2.pins = connector2.pins.filter(item => !deletedPins.includes(item.pin));
      connector1.pins.forEach(item => {
        if (deletedPins.includes(item.pin_map)) {
          item.pin_map = "";
          if (item.external_map) {
            item.external_map.cableModelId = "";
            item.external_map.cablePort = "";
          }
        }
      });
    }
    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1,
      connector2,
      cableModels: connections[index].connection.cableModels
    });
    connections[index].connection.connector1 = updateConn.connector1;
    connections[index].connection.connector2 = updateConn.connector2;
    connections[index].connection.cableModels = updateConn.cableModels;
  }

  //next connection
  if (nextConnectionId) {
    //find connection
    const index = connections.findIndex(item => item.CONNECTION_ID === nextConnectionId);
    let connector1 = connections[index].connection.connector1 || { pins: [] },
      connector2 = connections[index].connection.connector2 || { pins: [] };
    //delete component pins
    const _deleteComps = deletedComps.filter(item => item.name === connector1.component);
    if (deletedComps.length && _deleteComps) {
      const deletedPins = _deleteComps.map(item => item.pin);
      const deletedPinMap = connector1.pins.filter(item => deletedPins.includes(item.pin)).map(item => item.pin_map);
      connector1.pins = connector1.pins.filter(item => !deletedPins.includes(item.pin));
      connector2.pins.forEach(item => {
        if (deletedPinMap.includes(item.pin) && item.external_map) {
          item.external_map.cableModelId = "";
          item.external_map.cablePort = "";
        }
      });
    }
    //filter model files by updated pins
    const updateConn = updateModelsAndCableModels({
      connector1,
      connector2,
      cableModels: connections[index].connection.cableModels
    });
    connections[index].connection.connector1 = updateConn.connector1;
    connections[index].connection.connector2 = updateConn.connector2;
    connections[index].connection.cableModels = updateConn.cableModels;
  }

  content.pcbConnections = pcbConnections;
  content.connections = connections;

  return content;
}

/**
 * update end to end setup connector component pins by channel added component pins
 * @param {Object} content end to end setup content :{ pcbConnections, connections }
 * @param {string} channelId added component pins channel id
 *  */
async function _updateEndToEndByAddedComponents({
  content,
  channelId,
  newComps,
  type,
  signal
}) {
  let _content = JSON.parse(JSON.stringify(content));
  let pcbConnections = content.pcbConnections, connections = content.connections;
  const channel = pcbConnections.find(item => item.channelId === channelId);
  if (!channel) {
    return _content;
  }

  const prevConnectionId = channel.prevConnectionId,
    nextConnectionId = channel.nextConnectionId;
  //prev connection
  if (prevConnectionId) {
    const index = connections.findIndex(item => item.CONNECTION_ID === prevConnectionId);
    //signal_connections_map
    const signalMap = connections[index].connection.signal_connections_map;
    let connector2 = connections[index].connection.connector2 || { pins: [] },
      connector1 = connections[index].connection.connector1 || { pins: [] };
    const _newComps = newComps.find(item => item.name === connector2.component);
    const findSignalMap = signalMap.find(item => signal === item.channel2_signal) || {};
    if (newComps.length && _newComps && signalMap) {
      const newPins = _newComps.pins;
      let channel1Signals = [], channel1Pins = [];
      if (connector1.component && connector1.pins.length && findSignalMap.channel1_signal) {
        const channelId = connections[index].channel1.channelId;
        await getChannelInfo(channelId);
        channel1Signals = channelSetupInfo.getSignals(channelId);
        const channel1Comps = channelSetupInfo.getComponents(channelId);
        channel1Pins = channel1Comps.find(item => item.name === connector1.component).pins;
      }

      for (let pin of newPins) {
        //find channel1 signal -> { name, nets_P, nets_N }
        const channel1Signal = channel1Signals.find(it => it.name === findSignalMap.channel1_signal);
        //find same net type pin
        const pinMapObj = channel1Pins.find(it => channel1Signal && channel1Signal[type].includes(it.net)) || { pin: "" };
        connector2.pins.push({
          pin: pin.pin
        })
        const pinIndex = connector1.pins.findIndex(item => item.pin === pinMapObj.pin);
        if (pinIndex > -1) {
          connector1.pins[pinIndex].pin_map = pin.pin;
        }
      }
    }
    connections[index].connection.connector1 = connector1;
    connections[index].connection.connector2 = connector2;
  }

  //next connection
  if (nextConnectionId) {
    //find connection
    const index = connections.findIndex(item => item.CONNECTION_ID === nextConnectionId);
    //signal_connections_map
    const signalMap = connections[index].connection.signal_connections_map;
    let connector1 = connections[index].connection.connector1 || { pins: [] },
      connector2 = connections[index].connection.connector2 || { pins: [] };

    const _newComps = newComps.find(item => item.name === connector1.component);
    const findSignalMap = signalMap.find(item => signal === item.channel1_signal);

    if (newComps.length && _newComps && findSignalMap) {
      const newPins = _newComps.pins;
      let channel2Signals = [], channel2Pins = [];
      if (connector2.component && connector2.pins.length && findSignalMap.channel2_signal) {
        const channelId = connections[index].channel2.channelId;
        await getChannelInfo(channelId);
        channel2Signals = channelSetupInfo.getSignals(channelId);
        const channel2Comps = channelSetupInfo.getComponents(channelId);
        channel2Pins = channel2Comps.find(item => item.name === connector2.component).pins;
      }

      for (let pin of newPins) {
        //find channel2 signal -> { name, nets_P, nets_N }
        const channel2Signal = channel2Signals.find(it => it.name === findSignalMap.channel2_signal);
        //find same net type pin
        const pinMapObj = channel2Pins.find(it => channel2Signal && channel2Signal[type].includes(it.net)) || { pin: "" };
        connector1.pins.push({
          pin: pin.pin,
          pin_map: pinMapObj.pin
        })
      }
    }
    connections[index].connection.connector1 = connector1;
    connections[index].connection.connector2 = connector2;
  }

  content.pcbConnections = pcbConnections;
  content.connections = connections;

  return content;
}

function _updateEndToEndByRenamedSignal({ content, channelId, signal, prevSignal }) {
  let _content = JSON.parse(JSON.stringify(content));
  let pcbConnections = content.pcbConnections, connections = content.connections;
  const channel = pcbConnections.find(item => item.channelId === channelId);
  if (!channel) {
    return _content;
  }

  const prevConnectionId = channel.prevConnectionId,
    nextConnectionId = channel.nextConnectionId;
  //prev connection
  if (prevConnectionId) {
    const index = connections.findIndex(item => item.CONNECTION_ID === prevConnectionId);

    const signalIndex = connections[index].connection.signal_connections_map.findIndex(item => item.channel2_signal === prevSignal);
    if (signalIndex > -1) {
      connections[index].connection.signal_connections_map[signalIndex].channel2_signal = signal;
    }
  }

  //next connection
  if (nextConnectionId) {
    //find connection
    const index = connections.findIndex(item => item.CONNECTION_ID === nextConnectionId);
    const _signalIndex = connections[index].connection.signal_connections_map.findIndex(item => item.channel1_signal === prevSignal);
    if (_signalIndex > -1) {
      connections[index].connection.signal_connections_map[_signalIndex].channel1_signal = signal;
    }
  }

  content.connections = connections;

  return content;
}

function getEndToEndSignalNameByChannelSignal(content, pcbIndex, channelSignal) {
  let signal = "";
  if (pcbIndex === 0) {
    const connectionId = content.pcbConnections[pcbIndex].nextConnectionId;
    const connection = content.connections.find(item => item.CONNECTION_ID === connectionId);
    const signal_connections_map = connection.connection.signal_connections_map || [];
    const findSignal = signal_connections_map.find(item => item.channel1_signal === channelSignal);
    signal = findSignal ? findSignal.name : "";
  }

  if (pcbIndex === content.pcbConnections.length - 1) {
    const connectionId = content.pcbConnections[pcbIndex].prevConnectionId;
    const connection = content.connections.find(item => item.CONNECTION_ID === connectionId);
    const signal_connections_map = connection.connection.signal_connections_map || [];
    const findSignal = signal_connections_map.find(item => item.channel2_signal === channelSignal);
    signal = findSignal ? findSignal.name : "";
  }
  return signal;
}

export {
  _updateEndToEndByChannelDeletedSignals,
  _updateEndToEndByChannelComponent,
  _updateEndToEndByAddedComponents,
  _updateEndToEndByDeletedComponents,
  _updateEndToEndByRenamedSignal,
  getEndToEndSignalNameByChannelSignal
}