export {
  preLayoutDefaultComps,
  getDefaultPreLayoutInfo,
  updateSignalSectionUnit,
  getPreLayoutUnitScale,
  getDefaultPreLayoutInfoByGroups,
  getPreLayoutSignalList,
  getPreLayoutCompPinList,
  getPreLayoutSignals
} from './preLayoutConfig';

export {
  getPreLayoutSignalColumns,
  gerPreLayoutTableData,
  getPreLayoutContentVersion,
  addSectionsToSignals,
  deleteSectionsInSignals,
  updateComponentPinsBySignal,
  updateAllSignalTemplate,
  updateSignalTemplate,
  getPreLayoutAllNets
} from './preLayoutHelper';

export {
  getPreLayoutInfoById,
  updatePreLayout
} from './preLayoutCtrl';

export {
  preLayoutSection,
  getModelFrequency,
  getPreLayoutModelFreq
} from './preLayoutRender';

export {
  PreLayoutModel,
  preLayoutModelFreq,
  DefaultPreLayout
} from './IntegratedPreLayout';

export {
  getPreLayoutErrorCheck
} from './errorCheck';

export {
  getPreLayoutAggressorsBySignal
} from './getPreLayoutSignalAggressors';

export const SCHEMATIC = "Schematic",
  MODEL = "model",
  preLayoutTypes = [{
    key: SCHEMATIC,
    title: "Schematic"
  },
  {
    key: MODEL,
    title: "Model"
  }];

export const TX = "TX", RX = "RX", CLK = "CLK";

export const PRE_LAYOUT_VERSION = "0.0.1",
  PRE_LAYOUT_DESIGN_VERSION = 1;

export const PCIE_SIGNALS_GROUPS = [CLK, TX, RX],
  HDMI_SIGNALS_GROUPS = ["HDMI", CLK];

/* signal */
export const
  INSERTION_LOSS = "insertionLoss",
  LOCATION = "location",
  CAPACITOR = "capacitor",
  RESISTOR = "resistor",
  INDUCTOR = "inductor";