import StringList from '../utility/StringList';

var CeObjectManager = function () {
  this.mNextObjID = 0;
  this.mObjects = [];
};

CeObjectManager.prototype.TakeNextID = function () {
  return this.mNextObjID++;
}

CeObjectManager.prototype.Add = function (newObj) {
  if (newObj.GetID() < 0)
    newObj.SetID(this.mNextObjID++);
  else if (newObj.GetID() >= this.mNextObjID)
    this.mNextObjID++;

  this.mObjects.push(newObj);

  return newObj.GetID();
}

CeObjectManager.prototype.GetAllObjects = function () {
  return this.mObjects;
}

CeObjectManager.prototype.GetCsObject = function (id) {
  for (var i = 0; i < this.mObjects.length; i++) {
    if (id == this.mObjects[i].GetID())
      return this.mObjects[i];
  }
  return null;
}

CeObjectManager.prototype.GetAllObjectNames = function () {
  var names = new StringList();
  for (var i = 0; i < this.mObjects.length; i++) {
    names.add(this.mObjects[i].GetName());
  }
  return names;
}

CeObjectManager.prototype.ClearAll = function () {
  this.mNextObjID = 0;
  this.mObjects = [];
}

export default CeObjectManager;