import { getResultConfig, updateResultConfig, getResultSetting, generateSEVResult, getSSNChannelTDRPowerSumResult } from "../../api/Rocky/rockyCtrl";
import WaveformResultHelper from '@/services/Rocky/result/helper/WaveformResultHelper';
import apiProcess from "../../api/utility";
import { PRELAYOUT } from "../preLayout/preLayoutConfig";

async function getPowerSumResultPromise({ type, id, pageType, fileList }) {
	let waveform = new WaveformResultHelper("result");
	if (pageType === PRELAYOUT) {
		let data = [];
		for (let fileInfo of fileList) {
			const { libraryId } = fileInfo;
			let waveformData = await getSSNChannelTDRPowerSumResult({ type, verificationId: id, libraryId, option: "power-sum" });
			if (waveformData && waveformData.data) {
				data.push({ data: waveformData.data, libraryId })
			}
		}
		waveform.parsePreLayoutRawCurve(data);
	} else {
		let waveformData = (await getSSNChannelTDRPowerSumResult({ type, verificationId: id, option: "power-sum" }));
		waveform.parseRawCurve(waveformData && waveformData.data ? waveformData.data : "", [], false, false, true);
	}
	return waveform;
}

function getResultConfigPromise(verificationId) {
	return apiProcess(getResultConfig, verificationId)
}

function updateResultConfigPromise({ verificationId, config }) {
	return apiProcess(updateResultConfig, { verificationId, config })
}

function getResultSettingPromise(verificationId, channelId) {
	return apiProcess(getResultSetting, { verificationId, channelId })
}

function generateSEVResultPromise(config, verificationId, channelId) {
	return apiProcess(generateSEVResult, { config, verificationId, channelId })
}

export {
	getPowerSumResultPromise,
	getResultConfigPromise,
	updateResultConfigPromise,
	getResultSettingPromise,
	generateSEVResultPromise,
}