import React, { Component, createRef } from 'react';
import Line from '@/components/Line';
import { MenuOutlined } from '@ant-design/icons';
import './resultLayout.css';

class ResultLayout extends Component {
  constructor(props) {
    super()
    this.contentRef = createRef();
    this.state = {
      leftWidth: 0,
      rightWidth: 0,
      openMenu: true,
      contentWidth: 0
    }
    this.defaultRightWidth = props.defaultRightWidth || 280;
    this.changeWidth = this.changeWidth.bind(this);
    this.changeSize = this.changeSize.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.screenChange();
    if (this.contentRef && this.contentRef.current && this.contentRef.current.offsetWidth) {
      const width = this.contentRef.current.offsetWidth;
      let rWidth = width * 0.31;
      let lWidth = width * 0.69;
      if (rWidth < this.defaultRightWidth) {
        rWidth = this.defaultRightWidth;
        lWidth = width - this.defaultRightWidth;
      }
      this.setState({
        leftWidth: lWidth,
        rightWidth: rWidth,
        contentWidth: width
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.contentRef && this.contentRef.current && this.contentRef.current.offsetWidth) {
      const contentWidth = this.contentRef.current.offsetWidth;

      if (prevState.contentWidth !== contentWidth) {
        this.resize()
      }
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  screenChange() {
    window.addEventListener('resize', this.resize);
  }

  resize() {
    const contentWidth = this.contentRef ? this.contentRef.current.offsetWidth : null;
    if (!contentWidth) return;
    const { rightWidth } = this.state;
    this.setState({
      contentWidth
    }, () => {
      this.changeWidth(contentWidth - rightWidth)
    })
  }

  openMenuChange = () => {
    const { contentWidth } = this.state;
    const leftWidth = contentWidth - this.defaultRightWidth;
    this.changeWidth(leftWidth);
  }

  changeWidth = (leftWidth) => {
    let lWidth = leftWidth;
    const { contentWidth } = this.state;
    let rWidth = contentWidth - lWidth;
    const r = rWidth;
    const l = lWidth;
    this.setState({
      rightWidth: r,
      leftWidth: l,
    }, () => {
      if (this.props.changeWidthCB) {
        this.props.changeWidthCB();
      }
    });
  }

  // drag end
  changeSize = (leftWidth) => {
    const contentWidth = this.contentRef.current.offsetWidth;
    const { rightWidth, openMenu } = this.state;
    const _leftWith = leftWidth + 0, _rightWidth = rightWidth + 0;
    let lWidth = _leftWith;
    let rWidth = contentWidth - _leftWith;
    let _openMenu = openMenu;
    // prev right width === 50 (openMenu - false)
    if (_rightWidth === 50 && rWidth > 50) {
      if (rWidth < this.defaultRightWidth) {
        rWidth = this.defaultRightWidth;
        lWidth = contentWidth - this.defaultRightWidth;
      }
      this.setState({
        openMenu: true
      });
      _openMenu = true;
    } else if (rWidth < this.defaultRightWidth) {
      this.setState({
        openMenu: false
      });
      _openMenu = false;
      rWidth = 50;
      lWidth = contentWidth - 50;
    };
    if (lWidth < 50) {
      lWidth = 50;
      rWidth = contentWidth - 50;
    }

    this.setState({ contentWidth }, () => {
      const { changeSettings } = this.props;
      if (!openMenu && _openMenu && changeSettings) {
        changeSettings()
      }
      this.changeWidth(lWidth);
    });
  }

  openMenu = (e) => {
    e.stopPropagation();
    const { contentWidth } = this.state;
    const leftWidth = contentWidth - this.defaultRightWidth;
    this.setState({
      openMenu: true,
    }, () => {
      const { changeSettings } = this.props;
      changeSettings && changeSettings()
    });
    this.changeWidth(leftWidth);
  }

  render() {
    const { leftWidth = 400, rightWidth = 600, openMenu } = this.state;
    const { resultRight, resultLeft, className = "" } = this.props;
    return (
      <div style={{ height: '100%', width: "100%" }} ref={this.contentRef} className={className}>
        <div style={{ width: leftWidth, height: '100%' }} className="result-left">
          {resultLeft()}
        </div>
        <Line
          position={{ position: "absolute", right: rightWidth }}
          changeWidth={this.changeWidth}
          width={leftWidth}
          resize={this.changeSize}
        />
        <div className='result-right' style={{ width: rightWidth }} >
          <div className='result-setting-menu' style={{ display: (!openMenu && 'block') || 'none' }}>
            <MenuOutlined onClick={(e) => this.openMenu(e)} />
          </div>
          {openMenu && resultRight()}
        </div>
      </div>
    );
  }
}

export default ResultLayout;