import React, { Component } from 'react';
import { END_TO_END_CHANNEL, PCB, PCB_PRE_LAYOUT, PCB_CHANNEL, PCB_CHANNEL_RESULT, END_TO_END_CHANNEL_RESULT, PACKAGE, EXPERIMENTS, EXPERIMENTS_RESULT } from '@/constants/treeConstants';
import ChannelResult from '../results/channel';
import LayoutSingle from '../../../components/ContentLayout/layoutSingle';
import TopBar from '../TopBar';
import EndToEndResult from '../results/endToEnd';
import ChannelSetup from '../Channel';
import EndToEndChannel from '../EndToEndChannel';
import PCBLayout from './pcbLayout';
import { topBarResize } from '@/services/helper/topBarResize';
import SweepContent from '../Sweep';
import SweepResult from '../results/sweep';

class LayoutContent extends Component {

  getContent = (height, view) => {
    const { siderWidth } = this.props;
    switch (view) {
      case PCB:
      case PCB_PRE_LAYOUT:
      case PACKAGE:
        return <PCBLayout siderWidth={siderWidth} bottomHeight={height} />
      case END_TO_END_CHANNEL:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <EndToEndChannel />;
      case PCB_CHANNEL:
        setTimeout(() => {
          this.topBarResize();
        }, 100)
        return <ChannelSetup />;
      case PCB_CHANNEL_RESULT:
        return <ChannelResult leftWidth={siderWidth} />
      case END_TO_END_CHANNEL_RESULT:
        return <EndToEndResult leftWidth={siderWidth} />
      case EXPERIMENTS:
        return <SweepContent />
      case EXPERIMENTS_RESULT:
        return <SweepResult leftWidth={siderWidth} />
      default: return null;
    }
  }

  getClassName = (view) => {
    switch (view) {
      case PCB:
      case PACKAGE:
        return 'andes-v2-pcb-normal';
      case PCB_PRE_LAYOUT:
        return 'andes-v2-pre-layout-normal';
      case END_TO_END_CHANNEL:
      case PCB_CHANNEL:
        return 'andes-v2-setup-normal';
      case PCB_CHANNEL_RESULT:
      case END_TO_END_CHANNEL_RESULT:
      case EXPERIMENTS_RESULT:
        return 'andes-v2-result-normal';
      default: return '';
    }
  }

  topBar = () => {
    return <TopBar />;
  }

  topBarResize = () => {
    topBarResize('andes-v2-top-bar', this.props.getTopBarWidth());
    topBarResize('andes-v2-prelayout-top-bar', 370);
  }

  render() {
    const { viewList } = this.props;
    const view = viewList.length ? viewList[0] : null;
    return (
      <LayoutSingle
        {...this.props}
        id="andes-v2-content-main"
        topBarResize={this.topBarResize}
        className={this.getClassName(view)}
        getContent={(height) => this.getContent(height, view)}
        topBar={this.topBar}
      />
    )
  }
}

export default LayoutContent;