import CeIODataNode from './CeIODataNode';
import CeIODataItem from './CeIODataItem';
import CeBlockIO from './CeBlockIO';
import StringHelper from '../utility/StringHelper';
import StrSplitRule from '../utility/StrSplitRule';
import StringList from '../utility/StringList';

/**
 *  class CeIODataBlock extends CeIODataNode
 */

// public CeIODataBlock(String name)
var CeIODataBlock = function (name) {
    CeIODataNode.call(this, name);
    ///	ArrayList<CeIODataNode> mBlockData;
    this.mBlockData = [];
    this.mblo = [];
};
var splitRule = new StrSplitRule(" ");
splitRule.AddReservePair("(", ")");
splitRule.AddReservePair("[", "]");
splitRule.AddReservePair("\"", "\"");

// inherits from CeIODataNode
CeIODataBlock.prototype = Object.create(CeIODataNode.prototype);
CeIODataBlock.prototype.constructor = CeIODataBlock;

// public boolean ReadBlockFromFile(String filePath)
CeIODataBlock.prototype.ReadBlockFromFile = function (strFileContent) {
    // if (rule === undefined) {
    //     splitRule = defaultSplitRule;
    // } else {
    //     splitRule = rule
    // }

    try {
        return CeBlockIO.ReadBlockFromFile(strFileContent, this);
    } catch (err) {
        return false;
    }
};

// public boolean ReadBlockFromBytes(byte[] array)
// TODO

// public boolean WriteBlockToFile(String filePath)
// TODO

// public void WriteBlockToBuffer(BufferedWriter output, int tabNum) throws IOException {
CeIODataBlock.prototype.WriteBlockToBuffer = function (intTabNum) {

    var strOutput = CeIODataNode.prototype.WriteBlockItem.call(this, intTabNum);

    strOutput += this.mName + ' {\n';
    for (var i in this.mBlockData) {
        strOutput += this.mBlockData[i].WriteBlockItem(intTabNum + 1);
    }
    strOutput += CeIODataNode.prototype.WriteBlockItem.call(this, intTabNum);
    strOutput += '}\n';

    return strOutput;
};

// public void WriteBlockItem(BufferedWriter output, int tabNum) {
CeIODataBlock.prototype.WriteBlockItem = function (intTabNum) {
    try {
        return this.WriteBlockToBuffer(intTabNum);
    } catch (err) {
        console.log(err);
        return "";
    }
};

// public byte[] WriteBlockToBytes()
// TODO

// public boolean IsEmpty()
CeIODataBlock.prototype.isEmpty = function () {
    return this.mBlockData.length == 0;
};

// public void LoadBlockFromBuffer(BufferedReader br) throws IOException
CeIODataBlock.prototype.loadBlockFromBuffer = function (lineBuffer, counter) {

    while (counter.indLine < counter.lenBuffer) {

        // read a new line from the buffer
        var strLine = lineBuffer[counter.indLine].trim();
        counter.indLine++;

        // check for empty line or comments
        if (strLine.length == 0 || CeBlockIO.isCommentsLine(strLine))
            continue;

        // end of the block
        if (strLine.length == 1 && strLine === "}")
            return true;

        var blockName = CeBlockIO.findBlockStart(strLine);
        if (blockName != null) {
            // if another block is found, read it recursively
            var newBlock = new CeIODataBlock(blockName);
            newBlock.loadBlockFromBuffer(lineBuffer, counter);
            this.mBlockData.push(newBlock);
        } else {
            // single line data item, split the line
            //var lineItems = StringHelper.SplitString(strLine, delimiter);
            var lineItems = StringHelper.SplitString(strLine, splitRule);
            var name = lineItems.shift();
            var newItem = new CeIODataItem(name, lineItems);
            this.mBlockData.push(newItem);
        }
    } // while (lineBuffer.length)

    console.log("Fail to load block: " + this.GetName());
    return false;

}; // loadBlockFromBuffer

// @Override
// public boolean IsBlock()
CeIODataBlock.prototype.IsBlock = function () {
    return true;
};

// public boolean HasDataItem(String name)
CeIODataBlock.prototype.hasDataItem = function (strName) {
    var dataNode = this.GetBlockNode(strName);
    return dataNode != null;
};

// public CeIODataNode GetBlockNode(String name)
CeIODataBlock.prototype.GetBlockNode = function (strName) {
    for (var i = 0, len = this.mBlockData.length; i < len; i++) {
        if (this.mBlockData[i].GetName() === strName) {
            return this.mBlockData[i];
        }
    }
    return null;
}; // getBlockNode


CeIODataBlock.prototype.GetItemBool = function (name, orgVal) {
    var dataItem = this.GetBlockNode(name);
    if (dataItem == null || dataItem.IsBlock())
        return orgVal;

    if (dataItem.mItemValues.size() == 1)
        return StringHelper.StringToBool(dataItem.mItemValues.get(0));

    return orgVal;
}

CeIODataBlock.prototype.GetItemLong = function (name, orgVal) {
    var dataItem = this.GetBlockNode(name);
    if (dataItem == null || dataItem.IsBlock())
        return orgVal;

    if (dataItem.mItemValues.size() == 1)
        return parseInt(dataItem.mItemValues.get(0));

    return orgVal;
}

CeIODataBlock.prototype.GetItemDouble = function (name, orgVal) {
    var dataItem = this.GetBlockNode(name);
    if (dataItem == null || dataItem.IsBlock())
        return orgVal;

    if (dataItem.mItemValues.size() == 1)
        return parseFloat(dataItem.mItemValues.get(0));

    return orgVal;
}

//   Javascript uses Number for all number tpyes. So this function
//   is used for GetItemLong, GetItemInt, and GetItemDouble
CeIODataBlock.prototype.GetItemNum = function (strName, numOrgVal) {

    // get the data item
    var item = this.GetBlockNode(strName);
    if (item == null || item.IsBlock()) {
        if (numOrgVal == undefined)
            return 0;
        else
            return numOrgVal;
    }

    // get the value
    if (item.mItemValues.size() == 1) {
        // single value
        return Number(item.mItemValues.get(0));
    } else {
        // cannot determine the value
        return numOrgVal;
    }
}; // GetItemNum

CeIODataBlock.prototype.GetItemInt = function (strName, intOrgVal) {
    return this.GetItemNum(strName, intOrgVal);
};

// public Color GetItemColor(String name, Color orgval)
// TODO

// public CsValue GetItemCsValue(String name, CsValue orgval)
// TODO

// public String GetItemString(String name)
// public String GetItemString(String name, String orgval)
CeIODataBlock.prototype.GetItemString = function (strName, strOrgVal) {

    // get the data item
    var item = this.GetBlockNode(strName);
    if (item == null || item.IsBlock()) {
        if (strOrgVal == undefined)
            return "";
        else
            return strOrgVal;
    }

    // get the value
    if (item.mItemValues.length == 1) {
        // single value
        return item.mItemValues[0];
    } else {
        // return all words in a single string
        return item.mItemValues.Join(" ");
    }

}; // GetItemString

// public StringList GetItemStringList(String name)
CeIODataBlock.prototype.GetItemStringList = function (strName) {
    // get the data item
    var item = this.GetBlockNode(strName);
    if (item == null || item.IsBlock())
        return null;
    else
        return item.mItemValues;
}; // GetItemStringList

// public CeIODataBlock GetSubBlock(String name)
CeIODataBlock.prototype.GetSubBlock = function (strName) {
    for (var i = 0; i < this.mBlockData.length; i++) {
        var blkItem = this.mBlockData[i];
        if ((blkItem instanceof CeIODataBlock) &&
            (strName === blkItem.GetName())) {
            return blkItem;
        }
    }
    return null;
}; // getSubBlock

// public ArrayList<CeIODataBlock> GetSubBlocksOfSameName(String blkName)
CeIODataBlock.prototype.GetSubBlocksOfSameName = function (strBlkName) {
    var blockList = [];
    for (var i = 0, len = this.mBlockData.length; i < len; i++) {
        var blkItem = this.mBlockData[i];
        if (blkItem.IsBlock() && strBlkName === blkItem.GetName()) {
            blockList.push(blkItem);
        }
    }
    return blockList;

}; // getSubBlocksOfSameName


CeIODataBlock.prototype.GetAllBlockItemsOfSameName = function (itemName) {
    var itemList = [];
    for (var i = 0, len = this.mBlockData.length; i < len; i++) {
        var blkItem = this.mBlockData[i];
        if (blkItem.IsBlock() == false && itemName == blkItem.GetName())
            itemList.push(blkItem);
    }
    return itemList;

}

// public ArrayList<CeIODataBlock> GetAllSubBlocks()
CeIODataBlock.prototype.GetAllSubBlocks = function () {

    var blockList = [];
    for (var i = 0; i < this.mBlockData.length; i++) {
        var blkItem = this.mBlockData[i];
        if (blkItem.IsBlock()) {
            blockList.push(blkItem);
        }
    }
    return blockList;

}; // getAllSubBlocks

// public ArrayList<CeIODataItem> GetAllBlockItems()
CeIODataBlock.prototype.GetBlockItemNames = function () {

    var itemNames = new StringList();
    for (var i = 0; i < this.mBlockData.length; i++) {
        var blkItem = this.mBlockData[i];
        if (blkItem.IsBlock() == false) {
            itemNames.push(blkItem.GetName());
        }
    }
    return itemNames;

}; // getAllBlockItems

// public ArrayList<CeIODataItem> GetAllBlockItems()
CeIODataBlock.prototype.GetAllBlockItems = function () {

    var itemList = [];
    for (var i = 0; i < this.mBlockData.length; i++) {
        var blkItem = this.mBlockData[i];
        if (blkItem.IsBlock() == false) {
            itemList.push(blkItem);
        }
    }
    return itemList;

}; // getAllBlockItems

// public ArrayList<CeIODataNode> GetBlockNodes()
CeIODataBlock.prototype.GetBlockNodes = function () {
    return this.mBlockData;
};

// public int GetNumberOfNodes()
CeIODataBlock.prototype.GetNumberOfNodes = function () {
    return this.mBlockData.length;
};

// public int GetNumberOfNodes()
CeIODataBlock.prototype.IsBlock = function () {
    return true;
};

CeIODataBlock.prototype.GetLastNode = function () {
    if (this.mBlockData.length < 1)
        return null;
    var lastN = this.mBlockData.length - 1;
    return this.mBlockData[lastN];
}
// public void AddDataItem(String name, boolean value)
// TODO

// public void AddDataItem(String name, long value)
// TODO

// public void AddDataItem(String name, int value)
// TODO

// public void AddDataItem(String name, Color color)
// TODO

// public void AddDataItem(String name, String value)
// TODO

// public void AddDataItem(String name, StringList values)
// TODO

// public void AddDataItem(String name, CsValue propVal)
// TODO


CeIODataBlock.prototype.AddBlockNode = function (value) {
    if (value != null)
        this.mBlockData.push(value);
}

CeIODataBlock.prototype.AddDataItem = function (name, value) {
    if (value == null)
        return;

    var itemVals = new StringList();
    if (typeof value == "boolean" ||
        typeof value == "number" ||
        typeof value == "string" ||
        value instanceof StringList)
        itemVals.add(value);
    else {
        var vtyp = typeof value;
        console.log("CeIODataBlock.AddDataItem needs supporting" + vtyp);
    }

    this.mBlockData.push(new CeIODataItem(name, itemVals));
}

// public ArrayList<CeIODataItem> GetAllBlockItems()
CeIODataBlock.prototype.BlockToString = function (numTab) {
    if (numTab == null || numTab == undefined)
        numTab = 0;

    var blockTab = "";
    for (var i = 0; i < numTab; i++)
        blockTab += '\t';

    var itemTab = "";
    for (var i = 0; i <= numTab; i++)
        itemTab += '\t';

    var blkString = blockTab + this.GetName() + " {" + "\n";
    for (var i = 0; i < this.mBlockData.length; i++) {
        var blkItem = this.mBlockData[i];
        if (blkItem.IsBlock() == false) {
            blkString += itemTab + blkItem.GetOneLineString();
            blkString += "\n";
        } else {
            blkString += blkItem.BlockToString(numTab + 1);
        }
    }
    blkString += blockTab + "}\n";
    CeIODataBlock.mTabNum -= 1;
    return blkString;

}; // getAllBlockItems

export default CeIODataBlock;