import { getChannelExtractionResult, getHistoryResults, getEndToEndChannelResult, getSweepExtractionResult } from '../../api/Andes_v2/result';
import { parseFile, getDataList } from '../../Andes/results/result';
import { getVerificationJson, getHistoryVerificationJson } from '.';
import { getSignalGroup, getPCBIndexMap } from './sparameter/endToEnd';
import { getMultiPCBHistoryResults } from '../../api/Andes_v2/multiPCBHistory';
import { splitSignalsByGroup } from '../channel/channelDisplayHelper';
import { SortFn } from '../../helper/sort';

export async function getDelayData(id, isEndToEnd, pair, signal, components) {
  try {
    let res = null;
    if (isEndToEnd) {
      res = (await getEndToEndChannelResult({ endToEndChannelId: id, type: 'DELAY', pair, signal }));
    } else {
      res = (await getChannelExtractionResult({ channelId: id, type: 'DELAY', pair, signal }));
    }
    if (res && res.data) {
      return await delayFileParse(res.data, isEndToEnd, id, false, pair, components)
    }
    return null;
  } catch (error) {
    console.error(error)
    return null;
  }
}

export async function getHistoryDelayData(historyId, isEndToEnd, pair, signal, components) {
  try {
    let res = null;
    if (isEndToEnd) {
      res = await getMultiPCBHistoryResults({ historyId, type: 'DELAY', pair, signal });
    } else {
      res = await getHistoryResults({ historyId, type: 'DELAY', pair, signal });
    }
    if (res && res.data) {
      return await delayFileParse(res.data, isEndToEnd, historyId, true, pair, components)
    }
    return null;
  } catch (error) {
    console.error(error)
    return null;
  }
}

export async function getExperimentDelayData(experimentId, isEndToEnd) {
  try {
    let res = await getSweepExtractionResult({ experimentId, type: 'DELAY' });
    if (res && res.data) {
      return await delayFileParse(res.data, isEndToEnd, experimentId, false)
    }
    return null;
  } catch (error) {
    console.error(error)
    return null;
  }
}

async function delayFileParse(resData, isEndToEnd, id, isHistory, pair, components) {
  if (isEndToEnd) {
    let parse = getDataList(parseFile(resData), pair, components);
    let interfaceContent = null;
    if (isHistory) {
      interfaceContent = await getHistoryVerificationJson(id, true)
    } else {
      interfaceContent = await getVerificationJson(id, true);
    }
    const pcbIndexMap = getPCBIndexMap(interfaceContent.endToEnd.content.pcbConnections);
    const signalGroup = getSignalGroup(interfaceContent);
    parse.dataList.forEach(d => {
      const find = signalGroup.find(s => {
        if (pair) {
          const signalName = (d.signal || "").split(" ")[0];
          return s.name === signalName
        } else {
          return s.name === d.signal
        }
      });
      if (find) {
        const beginIndex = pcbIndexMap.get(find.beginChannel.designName);
        const endIndex = pcbIndexMap.get(find.endChannel.designName);
        d.comp1._signal = d.comp1.signal.replace(`${find.beginChannel.designName}_`, `PCB${beginIndex + 1}\xa0`)
        d.comp2._signal = d.comp2.signal.replace(`${find.endChannel.designName}_`, `PCB${endIndex + 1}\xa0`)
      }
    })
    return { dataList: parse.dataList, v2: parse.v2 };
  } else {
    const parse = getDataList(parseFile(resData), pair, components);

    return { dataList: parse.dataList, v2: parse.v2 };
  }
}

function sortChannelDelaySignals(setupInfo, delayData, v2) {
  if (!setupInfo || !setupInfo.content || !setupInfo.content.signals || !setupInfo.content.signals.length) {
    return delayData;
  }

  let newData = [];
  const sortSignalGroups = splitSignalsByGroup(setupInfo.content.signals);
  const sortSignals = sortSignalGroups.map(item => item.signals).flat(2).map(item => item.name);
  const signalKey = v2 ? "signal" : "SignalName";
  const signals = SortFn([...new Set(delayData.map(item => item[signalKey]))], sortSignals);
  for (let i = 0; i < signals.length; i++) {
    const signal = signals[i];
    const filterSignals = delayData.filter(item => item[signalKey] === signal);
    if (filterSignals.length === 1) {
      newData.push(filterSignals[0])
    }
    if (filterSignals.length === 2) {
      const positiveSignal = filterSignals.find(item => item.sigType === "positive");
      const negativeSignal = filterSignals.find(item => item.sigType === "negative");
      newData.push({
        ...positiveSignal,
        index: i * 2
      },
        {
          ...negativeSignal,
          index: i * 2 + 1
        })
    }
  }
  return newData.length ? newData : delayData;
}

function sortEndToEndDelaySignals(setupInfo, delayData, v2) {
  if (!setupInfo
    || !setupInfo.endToEnd
    || !setupInfo.endToEnd.content
    || !setupInfo.endToEnd.content.connections
    || !setupInfo.endToEnd.content.connections.length) {
    return delayData;
  }
  const connection = (setupInfo.endToEnd.content.connections[0] || {}).connection;
  if (!connection || !connection.signal_connections_map || !connection.signal_connections_map.length) {
    return delayData;
  }
  const signalMap = connection.signal_connections_map.map(item => item.name);

  const signalKey = v2 ? "signal" : "SignalName";
  const signals = SortFn([...new Set(delayData.map(item => item[signalKey]))], signalMap);
  let newData = [];
  for (let i = 0; i < signals.length; i++) {
    const signal = signals[i];
    const filterSignals = delayData.filter(item => item[signalKey] === signal);
    if (filterSignals.length === 1) {
      newData.push(filterSignals[0])
    }
    if (filterSignals.length === 2) {
      const positiveSignal = filterSignals.find(item => item.sigType === "positive");
      const negativeSignal = filterSignals.find(item => item.sigType === "negative");
      newData.push({
        ...positiveSignal,
        index: i * 2
      },
        {
          ...negativeSignal,
          index: i * 2 + 1
        })
    }
  }
  return newData.length ? newData : delayData;
}

export function sortDelaySignals(setupInfo, delayData, isEndToEnd, v2) {
  if (isEndToEnd) {
    return sortEndToEndDelaySignals(setupInfo, delayData, v2)
  } else {
    return sortChannelDelaySignals(setupInfo, delayData, v2);
  }
}