import React, { PureComponent, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Select, Spin } from 'antd';
import Table from '@/components/EditableTable';
import { updateConfig, addSignal, addRefNets, changeLanes } from '../../store/actionCreators';
import ConfigSetting from './ConfigSetting';
import { getNetsByComp, getNetsBySignalName, Lanes, uploadPKGModel, USAGE } from '@/services/serdes/serdes';


const Option = Select.Option;

const ComponentColumns = [{
  title: 'Name',
  dataIndex: 'component_name',
  width: '25%'
},
{
  title: 'Usage',
  dataIndex: 'type',
  width: '25%',
  sorter: (a, b) => a.type.length - b.type.length,
  // defaultSortOrder: 'descend'
}, {
  title: 'Value',
  dataIndex: 'value',
  width: '25%'
}, {
  title: 'PKG Model',
  dataIndex: 'pkg',
  width: '25%'
},
  // {
  //   title: 'Net(s)',
  //   dataIndex: 'nets',
  //   width: '25%'
  // },
]

const lanesColumns = [{
  title: 'Lane',
  dataIndex: 'ID',
  width: '10%'
}, {
  title: 'Tx_P Nets',
  dataIndex: 'TxP',
  width: '22.5%'
}, {
  title: 'Tx_N Nets',
  dataIndex: 'TxN',
  width: '22.5%'
}, {
  title: 'Rx_P Nets',
  dataIndex: 'RxP',
  width: '22.5%'
}, {
  title: 'Rx_N Nets',
  dataIndex: 'RxN',
  width: '22.5%'
}];

class InterfaceConfig extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      compLoading: false,
    }

    this.pkgRef = createRef();
    this.currentUpload = null;
    this.lanesSelect = createRef();

    ComponentColumns[1].onCell = (record) => ({
      record,
      edit: 'select',
      options: USAGE,
      dataIndex: 'type',
      handleSave: this.saveUsage,
    })

    ComponentColumns[2].onCell = (record) => {
      const { type } = record;
      if (type === 'Cap' || type === 'Res' || type === 'Ind') {
        return {
          record,
          edit: true,
          dataIndex: 'value',
          handleSave: this.saveComponentValue
        }
      } else {
        return {
          edit: false,
        }
      }
    }

    ComponentColumns[3].render = (text, record, index) => {
      const { type } = record;
      if (type === 'Controller' || type === 'Device') {
        if (text) {
          return <a>{text} <CloseOutlined onClick={() => this.deleteComponentPKG(record)} /></a>;
        } else {
          return <a><PlusOutlined onClick={() => this.addComponentPKG(record)} /></a>;
        }
      } else {
        return null;
      }
    }

    // ComponentColumns[4].render = (test, record, index) => (
    //   <span>{this.getNetsByCompName(record)}</span>
    // )

    lanesColumns[1].render = (text, record, index) => (
      <div className="editable-cell-value-wrap" onClick={() => this.addSignal(record, 'Tx', 'P')}>
        {this.getNetsBySignal(record, 'Tx', 'P')}
      </div>
    )

    lanesColumns[2].render = (text, record, index) => (
      <div className="editable-cell-value-wrap" onClick={() => this.addSignal(record, 'Tx', 'N')}>
        {this.getNetsBySignal(record, 'Tx', 'N')}
      </div>
    )

    lanesColumns[3].render = (text, record, index) => (
      <div className="editable-cell-value-wrap" onClick={() => this.addSignal(record, 'Rx', 'P')}>
        {this.getNetsBySignal(record, 'Rx', 'P')}
      </div>
    )

    lanesColumns[4].render = (text, record, index) => (
      <div className="editable-cell-value-wrap" onClick={() => this.addSignal(record, 'Rx', 'N')}>
        {this.getNetsBySignal(record, 'Rx', 'N')}
      </div>
    )

  }

  render() {

    const { compLoading } = this.state;
    const { currentLanes, config } = this.props;
    const { Components, RefNets, Groups } = config;

    return (
      <Fragment>
        <ConfigSetting />
        <Divider className='serdes-config-divider' />
        <Row gutter={24} className='serdes-config-row' >
          <Col span={24} className='col-padding-0'>
            <span className="font-bold">Lanes</span>
            <Select
              className='serdes-select-version'
              value={currentLanes}
              onChange={this.lanesChange}
              ref={this.lanesSelect}
            >
              {Lanes.map(lane => <Option key={lane}>{lane}</Option>)}
            </Select>
            <Table
              columns={lanesColumns}
              size='middle'
              className="serdes-table serdes-table-auto"
              dataSource={Groups}
              rowKey={(record, index) => (record.name && record.name.length > 0) ? record.name : index}
              scroll={(Groups && Groups.length > 8) ? { y: 312 } : {}}
            />
          </Col>
        </Row>
        <Row gutter={24} className='serdes-config-row'>
          <Col span={24} className='ground-nets col-padding-0 clear'>
            <span className="font-bold fl-l">Ground Nets</span>
            <div
              className='config-serdes-ground-nets'
              onClick={this.addGroundNets}
            >{RefNets.length > 0 ? RefNets[0].nets.join(', ') : ''}</div>
          </Col>
        </Row>
        <Row gutter={24} className='serdes-config-row' >
          <Col span={24} className='col-padding-0'>
            <span className="font-bold">Components</span>
            <Spin spinning={compLoading}>
              <Table
                columns={ComponentColumns}
                size='middle'
                dataSource={Components}
                rowKey={record => record.component_name}
                className="serdes-table serdes-table-auto"
                scroll={(Components && Components.length > 8) ? { y: 312 } : {}}
              />
            </Spin>
            <input
              type='file'
              ref={this.pkgRef}
              style={{ display: 'none' }}
              onChange={this.onUploadPKG}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }

  addSignal = (record, TRX, Dir) => {
    const signalName = `${record.ID}_${TRX}_${Dir}`;
    this.props.selectSignalNets(signalName);
  }

  saveUsage = (row, preRow) => {
    const { Components } = this.props.config;
    const index = Components.indexOf(preRow);
    Components[index].type = row.type;
    this.props.updateConfig({ Components });
  }

  getNetsByCompName = (record) => {
    if (!record.component_name) return null;
    const { NetPins } = this.props.config;
    return getNetsByComp(record.component_name, NetPins).join(', ');
  }

  getNetsBySignal = (record, TRX, Dir) => {
    const signalName = `${record.ID}_${TRX}_${Dir}`;
    const { Signals } = this.props.config;
    return getNetsBySignalName(signalName, Signals).join(', ');
  }

  saveComponentValue = (row, preRow) => {
    const { Components } = this.props.config;
    const index = Components.indexOf(preRow);
    Components[index].value = row.value;
    this.props.updateConfig({ Components });
  }

  lanesChange = (value) => {
    this.props.changeLanes(value);
    this.lanesSelect.current.blur();
  }

  addGroundNets = () => {
    const { RefNets } = this.props.config;
    if (RefNets.length > 0) {
      this.props.addRefNets(RefNets[0].nets);
    } else {
      this.props.addRefNets();
    }
  }

  addComponentPKG = (record) => {
    this.currentUpload = record;
    const el = this.pkgRef.current;
    if (!el) return;
    el.click();
  }

  deleteComponentPKG = (record) => {

  }

  onUploadPKG = (e) => {
    const file = e.target.files[0];
    // Upload pkg model
    this.setState({
      compLoading: true,
    });
    const { projectId } = this.props;
    uploadPKGModel({ files: [file], projectId }).then((res) => {
      this.setState(() => {
        let { Components } = this.props.config;
        const index = Components.map(comp => comp.component_name).indexOf(this.currentUpload.component_name);
        Components[index].pkg = file.name;
        this.props.updateConfig({ Components });
        this.currentUpload = null;
        this.pkgRef.current.value = '';
        return {
          compLoading: false,
        }
      })
    }).catch((err) => {

    });
  }
}

const mapState = (state) => {
  const { config, currentLanes } = state.explorerSerdes;
  const { projectId } = state.default;
  return {
    config,
    currentLanes,
    projectId
  }
}

const mapDispatch = (dispatch) => ({
  updateConfig(obj) {
    dispatch(updateConfig(obj))
  },
  selectSignalNets(signalName) {
    dispatch(addSignal(signalName))
  },
  addRefNets(selectedNets) {
    dispatch(addRefNets(selectedNets));
  },
  changeLanes(value) {
    dispatch(changeLanes(value))
  }
})

export default connect(mapState, mapDispatch)(InterfaceConfig);