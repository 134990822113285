import { combineReducers } from 'redux';
import { BMAChannelReducer } from './channel';
import { BMAResultReducer } from './result';
import { BMASimulationReducer } from './simulation';

const rootReducer = combineReducers({
  channel: BMAChannelReducer,
  simulation: BMASimulationReducer,
  result: BMAResultReducer
})
export default rootReducer;