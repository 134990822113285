import React, { Component } from 'react';
import Panel from '@/components/Panel';
import { createPortal } from 'react-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import sampleVec from './sample.vec';
import { downloadFile } from '@/services/helper/downloadHelper'
import './index.css';

let sampleContent = null;
class SamplePanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileContent: null
    }
    this.width = this.getDefaultWidth();
  }

  getDefaultWidth = () => {
    const offset = this.props.root.getBoundingClientRect();
    const { width } = offset;
    if (!width) return 600;
    const _width = width * 0.7;
    return _width > 800 ? 800 : _width;
  }

  componentDidMount() {
    if (!sampleContent) {
      fetch(sampleVec)
        .then(r => r.text())
        .then(text => {
          sampleContent = text;
          this.setState({
            fileContent: text
          })
        });
    } else {
      this.setState({
        fileContent: sampleContent
      })
    }
  }

  downloadSample = (e) => {
    e.target.blur();
    e.preventDefault();
    e.stopPropagation();
    const filename = 'sample.vec';
    const { fileContent } = this.state;
    downloadFile(fileContent, filename)
  }

  render() {
    const { closeModal } = this.props;
    const { fileContent } = this.state;
    const content = (
      <Panel
        position='panel-center-left'
        title={<div>Information
          <Tooltip
            title={'Download'}
            placement={"right"}
            overlayClassName='vector-sample-icon-tooltip'
          >
            <DownloadOutlined
              onClick={this.downloadSample}
              className='vector-sample-file-download'
              style={{ fontSize: 16 }} />
          </Tooltip>
        </div>}
        onCancel={closeModal}
        zIndex={2000}
        width={this.width}
        draggable
        className='vector-sample-file-panel'
        id='vector-sample-file-view'
        minWidth={200}
        minHeight={200}
      >
        <div >
          <pre style={{ margin: 16, overflow: 'auto' }} className='vector-sample-file-content'>
            {fileContent}
          </pre>
        </div>
      </Panel>
    )
    return createPortal(content, this.props.root)
  }
}

export default SamplePanel;