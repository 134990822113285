let urlAlphabet = 'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict';
let urlNumber = '5748392016';

function newNanoId(size = 21, type = 'alphabeat') {
  let id = ''
  // A compact alternative for `for (var i = 0; i < step; i++)`.
  let i = size
  while (i--) {
    // `| 0` is more compact and faster than `Math.floor()`.
    const url = getUrl(type);
    id += url[(Math.random() * url.length) | '0']
  }
  return id
}

function getUrl(type) {
  switch (type) {
    case 'number':
      return urlNumber;
    default:
      return urlAlphabet;
  }
}

export {
  newNanoId
}