const STACKUP = 'stackup';
export const INIT_STACKUP_DATA = `${STACKUP}/init_stackup_data`;
export const CHANGE_STACKUP_TABLE = `${STACKUP}/change_stackup_table`;
export const SETTING_UNIT = `${STACKUP}/setting_unit`;
export const SAVE_MATERIAL = `${STACKUP}/save_material`;
export const UPDATE_STACKUP_COLUMNS_STATUS = `${STACKUP}/update_stackup_columns_status`;
export const SAVE_STACKUP_TABLE_DATA = `${STACKUP}/save_stackup_table_data`;
export const UPDATE_STACKUP_ERROR_MSG = `${STACKUP}/update_stackup_error_msg`;
export const SAVE_STACKUP_TO_SERVER = `${STACKUP}/save_stackup_to_server`;
export const SAVE_ROUGHNESS = `${STACKUP}/save_roughness`;
export const SAME_MENU_MATERIAL = `${STACKUP}/save_menu_material`;
export const DELETE_MATERIAL_LIST = `${STACKUP}/delete_material_list`;
export const CLEAN_STATUS = `${STACKUP}/clean_status`;
export const UPDATE_SHOW_ZONES_STATUS = `${STACKUP}/update_show_zones_status`;
export const CHANGE_STACKUP_ZONE_PANEL_SHOW = `${STACKUP}/change_stackup_zone_panel_show`;
export const UPDATE_SELECTED_ZONES = `${STACKUP}/update_selected_zones`;
export const UPDATE_HIDDEN_COMPS = `${STACKUP}/update_hidden_comps`;
export const REMOVE_STACKUP_DATA = `${STACKUP}/remove_stackup_data`;