import React, { Component, Fragment } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select, Tabs } from 'antd';
import _ from 'lodash';
import { getCascadeComponents } from '../../../../services/Cascade/helper/setupData';
import NetsConnection from '../../components/netsConnection';

const Option = Select.Option;
class AddConnection extends Component {

  constructor() {
    super();
    this.state = {
      connectorList: {},
      activeKey: '0',
      autoConnection: []
    }
  }

  saveConnectorInfo(key, value, refresh) {
    const { index } = this.props;
    this.props.saveConnectorInfo(index, key, value, refresh);
  }

  componentDidUpdate(prevProps) {
    const { connector } = this.props;
    if (!_.isEqual(connector, prevProps.connector)) {
      this.setConnectorList(connector.netShip || [])
    }
  }

  setConnectorList = async (netShip) => {
    const list = {};
    const { connectorList } = this.state;
    for (let ship of netShip) {
      if (!ship.designId || connectorList[ship.designId]) {
        continue;
      }
      const connectors = await this.props.getConnectionComps(ship.designId);
      list[ship.designId] = connectors
    }
    this.setState({
      connectorList: {
        ...connectorList,
        ...list
      }
    }, () => {
      this.props.resize()
    })
  }

  PCBSelect = async (value, index) => {
    const { connector } = this.props;
    let netShip = connector.netShip;
    netShip[index].designId = value;
    netShip[index].connector = "";
    netShip[index].nets = [];
    this.saveConnectorInfo('netShip', netShip);
    const { connectorList } = this.state;
    if (!connectorList[value] && value) {
      const connectors = await this.props.getConnectionComps(value);
      this.setState({
        connectorList: {
          ...connectorList,
          [value]: connectors
        }
      })
    }
  }

  connectorSelect = (value, index) => {
    const { connector } = this.props;
    let netShip = connector.netShip;
    netShip[index].connector = value;
    netShip[index].nets = [];
    this.netsRef && this.netsRef.removeAllLines()
    this.saveConnectorInfo('netShip', netShip, true);
  }

  saveName = async (key, value) => {
    this.saveConnectorInfo(key, value);
    const { connector: { netShip = [] }, designId } = this.props;
    let _netShip = [...netShip];
    _netShip.forEach(ship => ship.nets = []);
    this.netsRef && this.netsRef.removeAllLines()
    this.saveConnectorInfo('netShip', _netShip, true);
    if (designId && this.props.getAutoConnectionNet) {
      const connectionNets = await this.props.getAutoConnectionNet(designId, value);
      this.setState({
        autoConnection: connectionNets
      })
    }
  }

  saveNets = () => {
    if (this.netsRef) {
      const { nets, save, netsInfo } = this.netsRef.getNetsAndSave();
      if (save) {
        const { shipIndex } = netsInfo;
        const { connector } = this.props;
        const { netShip } = connector;
        netShip[shipIndex].nets = nets;
        // const { nets } = checkConnectCompsPinsAndNets({ name: basicComp, designId: basicDesignId, nets: record[name] }, { designId, name: comp });

        this.saveConnectorInfo('netShip', netShip);
      }
    }
  }

  removeRelationShip = (e, index) => {
    e && e.stopPropagation()
    const { connector: { netShip = [] } } = this.props;
    let _netShip = [...netShip];
    const ship = _netShip[index];
    _netShip.splice(index, 1);
    this.saveConnectorInfo('netShip', _netShip);
    if (ship.designId && ship.connector) {
      this.props.deleteShip(ship, this.props.connector, true);
    }
  }

  getTabTitle = (ship, index) => {
    const { connector, designId } = ship;

    if (!connector || !designId) {
      return `Tab ${index + 1}`
    }
    const { pcbList } = this.props;
    const pcb = pcbList.find(item => item.id === designId);
    if (!pcb) {
      return `Tab ${index + 1}`
    }

    return `${pcb.name} - ${connector}`
  }

  changeTab = (tab) => {
    this.setState({
      activeKey: tab
    }, () => {
      setTimeout(() => {
        this.netsRef && this.netsRef.removeAllLines()
        this.props.resize()
      }, 100)
    })
  }

  onEdit = (tab) => {
    this.removeRelationShip(null, tab);
    const { activeKey } = this.state;
    if (tab === activeKey) {
      const _tab = Number(tab) - 1 > -1 ? Number(tab) - 1 : 0
      this.setState({
        activeKey: String(_tab)
      }, () => {
        setTimeout(() => {
          this.netsRef && this.netsRef.removeAllLines()
          this.props.resize()
        }, 100)
      })
    }
  }

  onNetsRef = (ref) => {
    this.netsRef = ref;
  }

  getNetsInfo = (ship, index) => {
    const { connector, designId: pcbId, nets } = ship;
    const { connector: _connector } = this.props;
    const { name, designId } = _connector;
    const prevCompInfo = getCascadeComponents({ pcbId: designId, name })
    const next = {
      comp: name,
      name,
      compInfo: prevCompInfo,
      index: 1,
      type: 'next',
      pcb: designId,
      pcbKey: 'next'
    }
    const nextCompInfo = getCascadeComponents({ pcbId, name: connector })
    const prev = {
      comp: connector,
      name: connector,
      compInfo: nextCompInfo,
      index: 0,
      type: 'prev',
      pcb: pcbId,
      pcbKey: 'prev'
    }
    return { nets, next, prev, shipIndex: index }
  }

  shipRender = (ship, index) => {
    const { connector, designId } = ship;
    const { pcbList } = this.props;
    const { connectorList } = this.state;
    const connectors = connectorList[designId] || [];
    return <Fragment>
      <div className="cascade-conn-ship-content">
        <div className="add-connection-content">
          <span className="font-bold cascade-setup-title-color-only">PCB</span>
          <Select
            value={designId}
            className="add-connection-item"
            onChange={(value) => this.PCBSelect(value, index)}
            getPopupContainer={(() => document.getElementById('root'))}
            showSearch={true}
            popupClassName="aurora-select-dropdown"
          >
            {pcbList.map(option => {
              return <Option key={option.name} title={option.name} value={option.id}>{option.name}</Option>
            })}
          </Select>
        </div>
        <div className="add-connection-content">
          <span className="font-bold cascade-setup-title-color-only">Connector</span>
          <Select
            value={connector}
            className="add-connection-item"
            onChange={(value) => this.connectorSelect(value, index)}
            getPopupContainer={(() => document.getElementById('root'))}
            showSearch={true}
            popupClassName="aurora-select-dropdown"
          >
            {connectors.map(option => {
              return <Option key={option.name} title={option.name} value={option.name}>{option.name}</Option>
            })}
          </Select>
        </div>
      </div>
    </Fragment>
  }

  netConnectionRender = () => {
    const { activeKey, autoConnection } = this.state;
    const { contentWidth, contentHeight, drawStatus, connector } = this.props;
    const { netShip } = connector;
    const ship = netShip[activeKey];
    if (!ship) {
      return null;
    }
    const { nets } = ship
    const netsInfo = this.getNetsInfo(ship, activeKey);
    return <div className="cascade-position-relative">
      <NetsConnection
        nets={nets.flat(2)}
        netsInfo={netsInfo}
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        onRef={this.onNetsRef}
        drawStatus={drawStatus}
        saveNets={this.saveNets}
        autoConnection={autoConnection}
      />
    </div>
  }

  render() {
    const { connector, index, connComps, disabledName } = this.props;
    const { activeKey } = this.state;
    const { name, netShip } = connector;
    const items = netShip.map((ship, index) => ({ key: String(index), label: this.getTabTitle(ship, index), closable: true, children: this.shipRender(ship, index) }))
    return (
      <div className="cascade-imp-add-connection">
        <div className="add-connection-content">
          <span className="font-bold cascade-setup-title-color-only">Connector Name</span>
          <Select
            value={name}
            className="add-connection-item"
            onChange={(value) => this.saveName('name', value)}
            getPopupContainer={(() => document.getElementById('root'))}
            showSearch={true}
            popupClassName="aurora-select-dropdown"
            disabled={disabledName}
          >
            {
              connComps.map(item => <Option key={item.name}>{item.name}</Option>)
            }
          </Select>
        </div>
        <div className="add-connection-relationship">
          <span className="font-bold cascade-setup-title-color-only">Connected to <PlusCircleOutlined
            className='cascade-imp-icon'
            onClick={() => this.props.addConnectorShip(index, name)} /></span>
        </div>
        <Tabs
          activeKey={String(activeKey)}
          className="add-connection-tab"
          onChange={this.changeTab}
          type="editable-card"
          onEdit={this.onEdit}
          hideAdd={true}
          items={items}
        />
        {this.netConnectionRender()}
      </div>
    );
  }
}

export default AddConnection;