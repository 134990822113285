import CeComponent from './CeComponent';
import CeLayerBase from '../partlib/CeLayerBase';

var CeLayerComponent = function () {
  CeLayerBase.call(this, "COMPONENT");
  this.mComponents = [];
};

// inherits from CeLayerBase
CeLayerComponent.prototype = Object.create(CeLayerBase.prototype);
CeLayerComponent.prototype.constructor = CeLayerComponent;

CeLayerComponent.prototype.AddComponent = function (compName, addedPart) {
  var newComp = new CeComponent();
  newComp.SetName(compName);
  newComp.SetPartPtr(addedPart);
  this.mComponents.push(newComp);
  return newComp;
}

CeLayerComponent.prototype.GetComponents = function () {
  return this.mComponents;
}

CeLayerComponent.prototype.WriteIntoIODataNode = function (compBlock) {
  CeLayerBase.prototype.WriteIntoIODataNode.call(this, compBlock);

  for (var i = 0; i < this.mComponents.length; i++) {
    var fileItem = this.mComponents[i].WriteIntoIODataNode();
    compBlock.AddBlockNode(fileItem);
  }
}

CeLayerComponent.prototype.ReadFromIODataNode = function (compBlock, ceDB) {
  CeLayerBase.prototype.ReadFromIODataNode.call(this, compBlock);

  var allCompItems = compBlock.GetAllBlockItems();
  for (var i = 0; i < allCompItems.length; i++) {
    var newComp = new CeComponent();
    if (newComp.ReadFromIODataNode(allCompItems[i], ceDB.mPartMgr) == true)
      this.mComponents.push(newComp);
  }
}

export default CeLayerComponent;