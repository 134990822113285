export class CapComponent {
  constructor({ usage = '', part = '', name = '', pins = [] }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins; // [{ pin, net }]
    this.value = {}; // { r, l, c }
    this.model = {}; // { Model }
    this.COMP_TYPE = usage;
  };
};

export class RLComponent {
  constructor({ usage = '', part = '', name = '', pins = [], value = '' }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins;
    this.value = value;
    this.COMP_TYPE = usage;
  };
};

export class BasicComponent {
  constructor({ usage = '', part = '', name = '', pins = [], location }) {
    this.usage = usage;
    this.part = part;
    this.name = name;
    this.pins = pins;
    this.COMP_TYPE = usage;
    this.location = location;
  };
};

export class Model {
  constructor({ type = '', name = '', subcktName = '' }) {
    this.id = "";
    this.type = type;
    this.name = name;
    this.subcktName = subcktName;
    this.libraryType = "";
  };
};