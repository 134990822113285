import React, { Component, Fragment, createRef } from 'react';
import { createPortal } from 'react-dom';
import Panel from '@/components/Panel';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { getPanelMaxWidth, getPanelWidth, getPanelMaxHeight } from '@/services/helper/panelSizeHelper';
import { ConnectorPCB, ConnectorComp } from '@/services/Cascade/helper/setupClass';
import { getAllCascadeComponents, changeNewConnectors } from '@/services/Cascade/helper/setupData';
import IconCollection from '@/components/TreeIconCollection';
import ConnectionLine from '@/services/Cascade/helper/connectionLine';
import NetsConnectionPanel from './netsConnectionPanel';
import { CONNECTOR, IGNORE, LOAD } from '../../../constants/componentType';
import _ from 'lodash';
import designConstructor from '../../../services/helper/designConstructor';
import preLayoutData from '../../../services/Cascade/prelayout/preLayoutData';
import './index.css';
import { DC, PCB, PCB_PRE_LAYOUT } from '../../../constants/treeConstants';
import auroraDBJson from '../../../services/Designs/auroraDbData';
import componentSetting from '../../../services/Cascade/helper/compSettingHelper';

const { Option } = Select;
class connectionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidth: 1600,
      maxHeight: 1000,
      contentWidth: 800,
      contentHeight: 500,
      connectors: [],
      drawLine: false,
      netsInfo: {},
      openNets: false,
      load: true,
      refresh: false,
      nets: [],
      comps: {}
    };
    this.svgRef = createRef()
    this.dialogRoot = document.getElementById('root');
    this.connectionLine = null;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const offset = this.dialogRoot.getBoundingClientRect();
    const contentRoot = document.getElementById('cascade-connection-content') || {}
    this.setState({
      maxWidth: getPanelMaxWidth(offset, 1600),
      maxHeight: getPanelMaxHeight(offset, 1000),
      contentWidth: contentRoot.scrollWidth || 800,
      contentHeight: contentRoot.scrollHeight || 500
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.connectionLine = new ConnectionLine(this.svgRef.current, this.overClick, this.showNetConnectionPanel);
    const { connectors = [], powerTrees } = this.props;
    let _connectors = JSON.parse(JSON.stringify(connectors));
    if (!_connectors[0] || !_connectors[0][0] || !_connectors[0][0].pcb) {
      const { root = {} } = this.props;
      _connectors = root.pcbId ? [[new ConnectorPCB({ pcb: root.pcbId })]] : [];
    }
    let nets = powerTrees.map(tree => tree.tree.branch.flat(2).map(item => item.prevNet)).flat(4);
    nets = [...new Set(nets.filter(n => !!n))];
    this.setState({
      connectors: _connectors,
      nets
    }, () => {
      this.resize();
      this.setPointLocation(true);
      this.getAllComps();
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { contentWidth, contentHeight } = this.state;

    if (contentWidth !== prevState.contentWidth || contentHeight !== prevState.contentHeight) {
      this.setPointLocation(true);
    }
  }

  getAllComps = async () => {
    const { connectors } = this.props;
    let ids = connectors.map(item => item.map(i => i.pcb)).flat(2);
    ids = [...new Set(ids)];
    const comps = {}
    for (let id of ids) {
      const _comps = await this.getPCBComps(id);
      comps[id] = _comps;
    }
    this.setState({
      load: false,
      comps: { ...this.state.comps, ...comps }
    })
  }

  closeModal = () => {
    const { connectors, refresh } = this.state;
    const { connectors: _connectors = [] } = this.props;
    const _refresh = refresh ? true : _.isEqual(connectors, _connectors) ? false : connectors.length < 2 && _connectors.length < 2 ? false : true;
    this.props.saveConnectors(connectors, _refresh);
    this.props.closeModal()
  }

  addConnection = (e) => {
    e && e.stopPropagation();
    const { connectors } = this.state;
    this.setState({
      connectors: [...connectors, [new ConnectorPCB({ init: true })]]
    }, () => {
      this.resize()
      this.setPointLocation();
      const contentRoot = document.getElementById('cascade-connection-content') || {}
      this.setState({
        contentWidth: contentRoot.scrollWidth || 800,
        contentHeight: contentRoot.scrollHeight || 500
      })
    })
  }

  delConnection = (e, index) => {
    e && e.stopPropagation();
    const { connectors } = this.state;
    const _connectors = [...connectors];
    if (index - 1 >= 0) {
      _connectors[index - 1].forEach(pcb => {
        pcb.components.forEach(comp => {
          comp.next = []
        })
      })
    }
    if (index + 1 < _connectors.length) {
      _connectors[index + 1].forEach(pcb => {
        pcb.components.forEach(comp => {
          comp.prev = []
        })
      })
    }
    _connectors.splice(index, 1)
    this.setState({
      connectors: _connectors
    }, () => {
      this.resize()
      this.connectionLine.removeAllLines()
      this.setPointLocation(true);
    })
  }

  delPCB = (e, { index, pcbIndex }) => {
    e && e.stopPropagation();
    const { connectors } = this.state;
    const _connectors = [...connectors];
    const key = _connectors[index][pcbIndex].key;
    _connectors[index].splice(pcbIndex, 1);
    if (index - 1 >= 0) {
      _connectors[index - 1].forEach(pcb => {
        pcb.components.forEach(comp => {
          comp.next = comp.next.filter(n => n.pcbKey !== key)
        })
      })
    }
    if (index + 1 < _connectors.length) {
      _connectors[index + 1].forEach(pcb => {
        pcb.components.forEach(comp => {
          comp.prev = comp.prev.filter(p => p.pcbKey !== key)
        })
      })
    }
    this.setState({
      connectors: _connectors
    }, () => {
      this.resize()
      this.setPointLocation(true)
    })
  }

  changePCB = async (value, pcbIndex, index) => {
    const comps = {};
    if (!this.state.comps[value]) {
      const _comps = await this.getPCBComps(value);
      comps[value] = _comps
    }
    const { connectors } = this.state;
    const _connectors = changeNewConnectors(connectors, { value, pcbIndex, index })
    this.setState({
      connectors: _connectors,
      comps: { ...this.state.comps, ...comps }
    }, () => {
      this.setPointLocation(true)
    })
  }

  getPCBComps = async (pcbId) => {
    if (!pcbId) {
      return [];
    }
    const isPreLayout = designConstructor.isPreLayout(pcbId);
    if (isPreLayout) {
      await preLayoutData.getPreLayout(pcbId);
    } else {
      const setting = componentSetting.getSetting({ designId: pcbId });
      await auroraDBJson.getAuroraJson(pcbId, setting);
    }
    const components = getAllCascadeComponents({ pcbId })
    return [...components.values()].filter(comp => [IGNORE, CONNECTOR, LOAD].includes(comp.type)).sort((a, b) => a.name > b.name ? 1 : -1) || []
  }

  addNewPCB = (index) => {
    const { connectors } = this.state;
    const _connectors = [...connectors];
    _connectors[index].push(new ConnectorPCB({ init: true }));
    this.setState({
      connectors: _connectors
    }, () => {
      this.resize()
    })
  }

  addNewConnector = (e, { index, pcbIndex }) => {
    e && e.stopPropagation();
    const { connectors } = this.state;
    const _connectors = [...connectors];
    _connectors[index][pcbIndex].components.push(new ConnectorComp())
    this.setState({
      connectors: _connectors
    }, () => {
      this.resize()
    })
  }

  delConnector = (e, index, pcbIndex, compIndex) => {
    e && e.stopPropagation();
    const { connectors } = this.state;
    const _connectors = [...connectors];
    const key = connectors[index][pcbIndex].key;
    const name = connectors[index][pcbIndex].components[compIndex].name;
    if (index - 1 >= 0) {
      _connectors[index - 1].forEach(pcb => {
        pcb.components.forEach(comp => {
          comp.next = comp.next.filter(n => !(n.pcbKey === key && n.comp === name))
        })
      })
    }
    if (index + 1 < _connectors.length) {
      _connectors[index + 1].forEach(pcb => {
        pcb.components.forEach(comp => {
          comp.prev = comp.prev.filter(p => !(p.pcbKey === key && p.comp === name))
        })
      })
    }
    _connectors[index][pcbIndex].components.splice(compIndex, 1)
    this.setState({
      connectors: _connectors
    }, () => {
      this.resize()
      this.setPointLocation(true)
    })
  }

  changeConnector = (value, pcb, index, pcbIndex, compIndex) => {
    const { comps } = this.state;
    const compList = pcb ? comps[pcb] || [] : []
    const find = compList.find(item => item.name === value);
    if (find) {
      const { connectors } = this.state;
      const _connectors = [...connectors];
      const { prev, next, name } = _connectors[index][pcbIndex].components[compIndex];
      const hasNext = next.length ? true : false;
      const hasPrev = prev.length ? true : false;
      _connectors[index][pcbIndex].components[compIndex] = { name: value, prev: [], next: [] };
      const pcbKey = _connectors[index][pcbIndex].key;
      if (index - 1 >= 0 && hasPrev) {
        _connectors[index - 1].forEach(pcb => pcb.components.forEach(comp => comp.next = comp.next.filter(item => !(item.pcbKey === pcbKey && item.comp === name))))
      }
      if (index + 1 < _connectors.length && hasNext) {
        _connectors[index + 1].forEach(pcb => pcb.components.forEach(comp => comp.prev = comp.prev.filter(item => !(item.pcbKey === pcbKey && item.comp === name))))
      }
      this.setState({
        connectors: _connectors
      }, () => {
        this.setPointLocation(true)
      })
    }
  }

  clickLine = (e, key) => {
    e && e.stopPropagation();
    this.setState({
      drawLine: true
    }, () => {
      this.connectionLine.drawTempLine(key)
    })
  }

  overClick = (key, endX, endY) => {
    this.setState({
      drawLine: false
    })
    const keys = Object.keys(this.pointLocation);
    const [type, pcb, comp, index, pcbKey] = key.split('-');
    const start = this.pointLocation[key] || {}
    let x = start.x || 0, y = start.y || 0;
    if (this.connectionLine.isConnectionKey(key)) {
      return;
    }
    for (let _key of keys) {
      let value = this.pointLocation[_key];
      const { x: _x, y: _y } = value;
      if (key === _key) {
        continue;
      }
      if (Math.abs(endX - _x) <= 10 && Math.abs(endY - _y) <= 10) {
        const [_type, _pcb, _comp, _index, _pcbKey] = _key.split('-');
        if (this.connectionLine.isConnectionKey(_key)) {
          continue;
        }
        if (_type !== type && pcb !== _pcb && (Math.abs(Number(index) - Number(_index)) === 1)) {
          const id = `line-${key}-${_key}`;
          const ele = document.getElementById(id);
          const _ele = document.getElementById(id);
          if (!ele && !_ele) {
            this.connectionLine.drawLines(id, { x: type === 'next' ? x + 8 : x, y, _x: type === 'next' ? _x : _x + 8, _y })
            this.saveCompConnection({ index, pcb, pcbKey, comp, type }, { index: _index, pcb: _pcb, pcbKey: _pcbKey, comp: _comp, type: _type })
            this.showNetConnectionPanel(id)
          }
        }
      }
    }
  }

  setPointLocation = (redraw = false) => {
    const points = document.getElementsByClassName('connection-button');
    this.pointLocation = {};
    if (points) {
      for (let point of points) {
        let y = point.offsetTop, x = point.offsetLeft;
        let _point = point
        while (point.offsetParent) {
          _point = _point.offsetParent;
          x = x + _point.offsetLeft;
          y = y + _point.offsetTop;
          if (_point.id === "cascade-connection-content") {
            break;
          }
        }
        this.pointLocation[point.id] = { x: x - 10, y: y - 52 }
      }
    }
    redraw && this.redrawLine()
  }

  saveCompConnection = (current, another, nets = []) => {
    const { index, pcbKey, comp, type, pcb } = current;
    const { index: _index, pcbKey: _pcbKey, comp: _comp, pcb: _pcb, type: _type } = another;
    const { connectors } = this.state;
    const _connectors = [...connectors];
    const pcbIndex = _connectors[index].findIndex(item => item.key === pcbKey);
    if (pcbIndex > -1) {
      const compIndex = _connectors[index][pcbIndex].components.findIndex(item => item.name === comp);
      if (compIndex > -1) {
        const typeIndex = _connectors[index][pcbIndex].components[compIndex][type].findIndex(i => i.index === _index && i.pcb === _pcb && i.pcbKey === _pcbKey && i.comp === _comp);
        if (typeIndex > -1) {
          _connectors[index][pcbIndex].components[compIndex][type][typeIndex].nets = nets;
        } else {
          _connectors[index][pcbIndex].components[compIndex][type].push({ index: _index, pcb: _pcb, pcbKey: _pcbKey, comp: _comp, nets });
        }
      }
    }
    const _pcbIndex = _connectors[_index].findIndex(item => item.key === _pcbKey);
    if (_pcbIndex > -1) {
      const _compIndex = _connectors[_index][_pcbIndex].components.findIndex(item => item.name === _comp);
      if (_compIndex > -1) {
        const _typeIndex = _connectors[_index][_pcbIndex].components[_compIndex][_type].findIndex(i => i.index === index && i.pcb === pcb && i.pcbKey === pcbKey && i.comp === comp);
        if (_typeIndex > -1) {
          _connectors[_index][_pcbIndex].components[_compIndex][_type][_typeIndex].nets = nets;
        } else {
          _connectors[_index][_pcbIndex].components[_compIndex][_type].push({ index, pcb, pcbKey, comp, nets });
        }
      }
    }
    this.setState({
      connectors: _connectors
    })
  }

  redrawLine = () => {
    const { connectors } = this.state;
    this.connectionLine.removeAllLines();
    for (let i = 0; i < connectors.length - 1; i++) {
      const connection = connectors[i];
      for (let conn of connection) {
        const { pcb, key, components } = conn;
        for (let comp of components) {
          const id = `next-${pcb}-${comp.name}-${i}-${key}`;
          let location = this.pointLocation[id];
          if (location && comp.next && comp.next.length) {
            for (let next of comp.next) {
              const nextId = `prev-${next.pcb}-${next.comp}-${next.index}-${next.pcbKey}`;
              let nextLocation = this.pointLocation[nextId];
              if (nextLocation) {
                this.connectionLine.drawLines(`line-${id}-${nextId}`, { x: location.x + 8, y: location.y, _x: nextLocation.x, _y: nextLocation.y })
              }
            }
          }
        }
      }
    }
  }

  showNetConnectionPanel = (key) => {
    if (this.state.load) {
      return;
    }
    this.setState({
      netsInfo: {},
      openNets: false
    }, () => {
      const infos = key.split('-');
      const prevIndex = infos.findIndex(i => i === 'prev');
      const nextIndex = infos.findIndex(i => i === 'next');
      const { comps } = this.state;
      const getInfo = ([type, pcb, comp, index, pcbKey]) => {
        const compInfo = comps[pcb].find(c => c.name === comp);
        if (!compInfo) {
          console.log({ pcb, comps, comp })
        }
        return { type, pcb, comp, index, pcbKey, compInfo }
      }
      const next = getInfo(infos.slice(nextIndex, nextIndex + 5));
      const prev = getInfo(infos.slice(prevIndex, prevIndex + 5));
      let nets = [];
      const { connectors } = this.state;
      const { index, pcbKey, type, comp } = next;
      const pcbIndex = connectors[index].findIndex(item => item.key === pcbKey);
      if (pcbIndex > -1) {
        const compIndex = connectors[index][pcbIndex].components.findIndex(item => item.name === comp);
        if (compIndex > -1) {
          const typeIndex = connectors[index][pcbIndex].components[compIndex][type].findIndex(i => i.index === prev.index && i.pcbKey === prev.pcbKey && i.comp === prev.comp);
          if (typeIndex > -1) {
            nets = connectors[index][pcbIndex].components[compIndex][type][typeIndex].nets || [];
          }
        }
      }
      const nextPins = next && next.compInfo && next.compInfo.pins ? [...next.compInfo.pins.values()] : [];
      const prevPins = prev && prev.compInfo && prev.compInfo.pins ? [...prev.compInfo.pins.values()] : [];
      nets = nets.filter(net => {
        if (nextPins.find(item => item.net === net[0]) && prevPins.find(item => item.net === net[1])) {
          return true;
        }
        return false;
      })
      this.setState({
        netsInfo: {
          next, prev, nets
        },
        openNets: true
      })
    })
  }

  closeNetsConnection = (nets, refresh) => {
    const { netsInfo } = this.state;
    const { next, prev } = netsInfo;
    this.saveCompConnection(next, prev, nets)
    this.setState({
      netsInfo: {},
      openNets: false,
      refresh
    })
  }

  connectionRender = (connection, index) => {
    const couldDel = index > 0 && connection.length > 1 ? false : true
    return (
      <div className="cascade-connection-column" key={index}>
        <div className="connector-setting">
          {index > 0 ? <Fragment>
            <Button
              size='small'
              type='link'
              className='add-pcb-button'
              onClick={() => this.addNewPCB(index)}
            >
              + Add new PCB
            </Button>
            <CloseOutlined onClick={(e) => this.delConnection(e, index)} className='delete-icon' />
          </Fragment> : null}
        </div>
        <div className="connector-setup">
          {connection.map((info, pcbIndex) => this.pcbRender(info, pcbIndex, index, couldDel))}
        </div>
      </div>
    );
  }

  pcbRender = (info, pcbIndex, index, couldDel) => {
    const { designList = [] } = this.props;
    const { pcb, components, key } = info;
    const pcbIconMenu = [
      { title: 'Add Connector', func: this.addNewConnector },
      { title: 'Delete PCB', func: this.delPCB, disabled: couldDel }
    ];
    const _couldDel = true;
    const _designList = designList.filter(item => item.dataType === PCB || (item.dataType === PCB_PRE_LAYOUT && item.content && item.content.electric === DC))
    return <div className="cascade-connector-setup-pcb" key={key}>
      <div className="pcb-select">
        <span>PCB</span>
        <Select
          size='small'
          value={pcb}
          popupClassName='cascade-select-dropdown-list'
          showSearch
          disabled={index > 0 ? false : true}
          onChange={(value) => this.changePCB(value, pcbIndex, index)}
          popupMatchSelectWidth={false}
        >
          {_designList.map(design => <Option key={design.id}>{design.name}</Option>)}
        </Select>
        <IconCollection className='delete-icon' menu={pcbIconMenu} data={{ index, pcbIndex }} />
      </div>
      {components.map((comp, compIndex) => this.connectorRender({ comp, components, pcb, index, pcbIndex, compIndex, couldDel: _couldDel, key }))}
    </div>
  }

  connectorRender = ({ comp, components, pcb, index, pcbIndex, compIndex, couldDel, key }) => {
    const { comps } = this.state;
    const compList = pcb ? comps[pcb] || [] : []
    const { connectors } = this.state;
    const _comps = components.map(i => i.name)
    const _compList = compList.filter(item => !_comps.includes(item.name));
    const { prev, next } = comp;
    return (
      <div className='pcb-select connector-select' key={`connector-${compIndex}`}>
        <span>Connector</span>
        <Select
          size='small'
          value={comp.name}
          popupClassName='cascade-select-dropdown-list'
          showSearch
          onChange={(value) => this.changeConnector(value, pcb, index, pcbIndex, compIndex)}
        >
          {_compList.map(c => <Option key={c.name}>{c.name}</Option>)}
        </Select>
        {couldDel && <CloseOutlined
          onClick={(e) => this.delConnector(e, index, pcbIndex, compIndex)}
          className='delete-icon' />}
        {index > 0 && comp.name && !next.length && <div className='prev-button connection-button' id={`prev-${pcb}-${comp.name}-${index}-${key}`} onClick={(e) => this.clickLine(e, `prev-${pcb}-${comp.name}-${index}-${key}`)}></div>}
        {index < connectors.length - 1 && comp.name && !prev.length && <div className='next-button connection-button' id={`next-${pcb}-${comp.name}-${index}-${key}`} onClick={(e) => this.clickLine(e, `next-${pcb}-${comp.name}-${index}-${key}`)}></div>}
      </div>
    );
  }

  render() {
    const { maxWidth, maxHeight, connectors, contentWidth, drawLine, contentHeight, openNets, netsInfo, nets } = this.state;
    const content = (
      <Fragment>
        <Panel
          className='cascade-power-select-panel'
          title={<div className='cascade-power-select-title'>Connection</div>}
          onCancel={this.closeModal}
          zIndex={2000}
          width={getPanelWidth(maxWidth, { defaultWidth: 1000 })}
          height={500}
          maxHeight={maxHeight}
          position='panel-center'
          draggable
          minHeight={500}
          minWidth={500}
          defaultTop={200}
          resizeEnd={this.resize}
        >
          <div className="cascade-connection-content" id="cascade-connection-content">
            {connectors.map((connector, index) => this.connectionRender(connector, index))}
            <div className="cascade-connection-column cascade-connection-empty-column" title="Add new column" onClick={(e) => this.addConnection(e)}>
              <div><PlusOutlined className="plus-icon" /></div>
            </div>
            <svg ref={this.svgRef} className={`plot ${drawLine ? 'cascade-connect-draw-line-status' : ''}`} style={{ width: contentWidth - 20, height: contentHeight - 20 }}></svg>
          </div>
        </Panel>
        {openNets && <NetsConnectionPanel
          closeModal={this.closeNetsConnection}
          netsInfo={netsInfo}
          nets={nets}
        />}
      </Fragment>
    )
    return createPortal(content, this.dialogRoot);
  }
}


export default connectionPanel;
