import NP from 'number-precision';
import { unitConversion } from './dataProcess';
import { scaleKeys } from './constants';
import { numExponentialFormat } from './numberHelper';
import { unitChange } from './mathHelper';

//res ind default value list
const ResValues = ['0.2', '0.1', '50m', '25m', '15m', '5m', '3m', '3m', '3m'];
const IndValues = ['0.2n', '0.25n', '0.3n', '0.35n', '0.40n', '0.45n', '0.50n', '1n', '2n'];

//set res and ind default value by cap value
function getDefaultRLCValue(capValue, type) {
  let value = 0;
  if (!capValue) {
    return value;
  }
  let valueList = type === 'Res' ? ResValues : (type === 'Ind' ? IndValues : []);

  const valueObj = splitValueUnit(capValue);
  let capV = valueObj.value, capU = valueObj.unit;
  if (!capV || capV === 0 || capV === '0') {
    return value;
  }
  if (capU !== 'u') {
    const scale = unitConversion(scaleKeys.u, scaleKeys[capU]);
    capV = NP.strip(NP.times(parseFloat(capV), scale));
  }
  let valueIndex = -1;

  if (valueList.length > 0) {
    valueIndex = reward[findValue(cValues, capV)]
    value = valueList[valueIndex];
  }
  return value;
}

const cValues = [0.001, 0.01, 0.1, 1, 10, 100, 150, 200];
const reward = [0, 1, 2, 3, 4, 5, 6, 7, 8];
/*
   According to the specified value, find the first value larger than it in the cValues, return the corresponding index, and get the corresponding data in the reward through the index
     1: If the value is less than the minimum value of cValues, return 0
     2: If the value is greater than the maximum value of cValues, return 8
*/
//TODO test
function findValue(array, val) {
  // If the value is less than the minimum value of cValues, return 0
  if (val < Math.min.apply(null, array)) {
    return 0;
  };
  //If the value is greater than the maximum value of cValues, return 8
  if (val >= Math.max.apply(null, array)) {
    return array.length;
  };
  let index = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] > val) {
      index = i;
      break;
    };
  };
  return index;
};

//1u,10,30n,300p
function splitValueUnit(param, allowEmpty = false) {
  let value, unit;
  // If param is a number, the number 1 is expressed as 1MHz.
  if (!param) {
    return { value: allowEmpty ? "" : "0", unit: "" }
  } else {
    value = parseFloat(param);
    unit = param.slice(-1);
    if ((unit && !isNaN(Number(unit))) || !unit) {
      // If the last character of param is still a number, set the default.
      unit = "";
    }
    // If value is NaN
    if (isNaN(value)) {
      value = "";
    }
    return { value, unit }
  }
}

function changeUnit(value, oldUnit, newUnit = 'mΩ', decimals = -1) {
  let newValue = unitChange({ num: value, oldUnit, newUnit, decimals }).number;
  if (newValue >= 1000 || newValue < 0.001) {
    newValue = numExponentialFormat(newValue);
  }
  return newValue;
}

function checkRLCValueInPowerTree(num) {
  if (!num) {
    return null;
  }
  const { value, unit } = splitValueUnit(num);
  return changeUnit(value, `${unit}Ω`)
}

function getPassiveValue(num = '0', isEmpty) {
  let { value, unit } = splitValueUnit(num)
  if (value < 1 && unit === "") {
    const scale = unitConversion(scaleKeys.m, scaleKeys.ohm);
    value = NP.strip(NP.times(parseFloat(value), scale));
    unit = isEmpty && parseFloat(value) === 0 ? "" : "m"
  }

  return { value, unit };
}

function getUnitByValue(num) {
  // if value < 1 , Ω to be mΩ
  const scale = unitConversion(scaleKeys.m, scaleKeys.ohm);
  if (num < 1) return { value: NP.strip(NP.times(parseFloat(num), scale)), unit: 'm' }

  if (num >= 1000000) {
    // Ω to be MΩ
    const scale = unitConversion(scaleKeys.M, scaleKeys.ohm);
    return { value: NP.strip(NP.times(parseFloat(num), scale)), unit: 'M' }
  } else if (num >= 1000) {
    // Ω to be KΩ
    const scale = unitConversion(scaleKeys.K, scaleKeys.ohm);
    return { value: NP.strip(NP.times(parseFloat(num), scale)), unit: 'K' }
  }

  return { value: num, unit: '' }
}

function oldUnitTransfer(num) {
  // oldunit: mΩ to be Ω
  const scale = unitConversion(scaleKeys.ohm, scaleKeys.m);
  num = NP.strip(NP.times(parseFloat(num), scale));
  return getUnitByValue(num)
}

function getValueByComp(comp) {
  const { value, unit } = comp
  return changeUnit(value, `${unit}Ω`)
}

function partCapValueMatch({ param, f, returnValue = false }) {
  if (typeof (param) !== "string") {
    return "";
  }

  const pointMatch = param.match(/\d+[./|P]?\d+[UPN]F*/ig);
  if (pointMatch && pointMatch.length) {
    f = pointMatch[0].toLowerCase();
    f = f.replace(/F$/ig, '').replace(/p(?!$)/ig, '.')
    if (returnValue) {
      return f;
    }
    return f + 'F'
  }

  const capList = param.match(/(\d+\.)*\d+[UPN]F*/ig);

  let _capStr = param;
  if (capList && capList.length > 1) {
    //"6P3X4P5_47U_25V_105_M_C_250AVEA"
    const findCap = param.match(/[^0-9a-zA-Z](\d+\.)*\d+[UPN]F*[^0-9a-zA-Z]/ig);
    //"47U_6P3X4P5_25V_105_M_C_250AVEA" / "6P3X4P5_25V_105_M_C_250AVEA_47U"
    const _findCap = param.match(/(^(\d+\.)*\d+[UPN]F*[^0-9a-zA-Z])|([^0-9a-zA-Z](\d+\.)*\d+[UPN]?F?$)/ig)
    if (findCap || _findCap) {
      _capStr = findCap ? findCap[0] : (_findCap ? _findCap[0] : param);
    }
  }
  //find -> "11.6uF" / "4pF" / "1e3U" / "1e3uF"
  let fFind = _capStr.match(/^(\d+\.)*(\d+)?(e)?(-|\+)?\d+[UPN]?F?$/ig);
  if (fFind) {
    const fValue = _capStr.match(/^(\d+\.)*(\d+)?(e)?(-|\+)?\d+[UPN]?/ig);
    if (!fValue) {
      return "";
    }
    f = fValue[0].toLowerCase();
    f = f.replace(/F$/ig, '')
    if (returnValue) {
      return f;
    }
    return f + 'F'
  }

  //find -> "11u5"
  const _fFind = _capStr.match(/\d+[UPN]+(\d+)/ig);
  if (_fFind) {
    const _f = _fFind[0].toLowerCase();
    let fArr = _f.split("");
    const units = fArr.filter(item => !item.match(/[0-9]/g));
    const i = fArr.findIndex(item => item.match(/U|P|N/ig));
    fArr[i] = ".";
    f = fArr.join("") + units.join("").replace(/F$/ig, '')
  } else {
    //find --> "11u" / "11.5u"
    const _fValue = _capStr.match(/(\d+\.)*\d+[UPN]/ig);
    if (!_fValue) {
      return "";
    }
    f = _fValue[0].toLowerCase();
    f = f.replace(/F$/ig, '')
  }
  if (returnValue) {
    return f;
  }
  return f + 'F'
}

export {
  getDefaultRLCValue,
  checkRLCValueInPowerTree,
  getPassiveValue,
  oldUnitTransfer,
  getValueByComp,
  changeUnit,
  partCapValueMatch,
  splitValueUnit
};
