import designConstructor from "../../helper/designConstructor";
import { CascadeCompPrefixVersion } from "../helper/setupData";

const SIWAVE = 'SIwave', HFSS = 'HFSS', PSI = 'SIwavePSI', POWERSI = 'PowerSI';
const meshMethod_PHIMESH = 'Phi', meshMethod_PHIPLUS = 'PhiPlus', meshMethod_CLASSICMESH = 'Classic';
const OVERVIEW = 'overview';
const LAYOUT = 'layout', BASIC = 'basic', SIMULATION_BASIC = 'simulation_basic', SIMULATION_ADVANCED = 'simulation_advanced'
const NATURAL = 'NaturalBoundary', MAGNETIC = 'MagneticWall'
const NOCONSIDERED = 'NoConsidered', BASICMODE = 'BasicMode', ENHANCEDMODE = 'EnhancedMode'
const LOG = 'log', LINEAR = 'linear', DECADE = 'Decade', STEP = 'Step'
const KEEPDECAPWITHVALUENOLESS = 'keepDecapWithValueNoLess', KEEPDECAPLIST = 'keepDecapList'
const DEFAULT_TIMEOUT = '72'
function ImpExtraction(config = {}) {
  const { SIWE = 0, PKGR = 1, VRTC = "2.5", SAPD = 100, DCF = 1, RESA = 1, CLIP = 1,
    DISC = 0, REFR = "0.1", couplingMinFreq = "3.5MHz", VOID = '2', IPC = 1, FMAX = '2e8', PLNM = '1e-6',
    DC = 1, PADM = '0.1', vrFswSwitch = false, VR_FSW, type = SIWAVE,
    errorTolerance = '0.005', maxSolution = '250', maxPasses = '20', maxDelta = '0.02',
    meshFrequency = '1', autoMeshFreq = true, minConvergedPass = '1',
    psiLossModel = 'Level 2', psiAutoSmallHole = true, psiSmallHoleSize = '1.0',
    pwsiMaxPadSize = '', pwsiMinShapeSize = '', pwsiDogleg = '1.5',
    pwsiThermal = '1.5', pwsiHole = '1.5', pwsiViaHole = '1.5', ratio = '0.5',
    includeDC = true, exactDC = false, auroraAedb = false,
    bottomFloating = false, bottomDistance = '0.01', topFloating = false, topDistance = '0.01',
    psiAdaptSamp = true, psiEnforceDC = true,
    numCores = '4', corePercent = '50', ramPercent = '90', createModelByPartNumber = true,
    meshingMethod = meshMethod_PHIMESH,
    autoOutputResult = false,
    causality = false, passivity = true,
    customPlaneVoidMeshingSetting = false,
    // multi zone options
    multiZone = true, cleanup = "PowerSI", alignZoneShape = false, distance = '1e-3', fixOverlapping = true, createZones = false, enableBends = true,
    pwsiTab = BASIC,
    pwsiPolygonSimplificationThreshold = '0.0042', pwsiThermalTracePadTraceToShapeFactor = '2', pwsiThermalTracePadShapeToShapeThreshold = '1', pwsiViaAntipadSearchFactor = '0', pwsiViaAntipadDistanceRangeFactor = '1',
    pwsiMaxPadSizeRelative = '', pwsiPadRelative = false, pwsiApplyToPwrGndNets = true, pwsiApplyToSignalNets = true, pwsiArcDiscretizationPrecision = '0.2',
    pwsiKeepShapesOfDisabledNets = true, pwsiDisableCPLSimulation = false,
    pwsiSlenderHoleAreaThreshold = '0', pwsiSlenderHoleSizeThreshold = '0', pwsiAutoSpecialVoid = false,
    pwsiEnhancedPadAntipad = true, pwsiDetectActualViaClearance = true, pwsiAdvancedDiscontinuityHandling = false, pwsiCombineOverlapAntiPadHole = true, pwsiEnforceCausalityInMaterial = true, pwsiTemperature = '25',
    pwsiBoundaryCondition = NATURAL, pwsiCompensationWithShapeWidth = true, pwsiInterPlaneCoupling = NOCONSIDERED, pwsiAutoEnableRLGCAdjustment = false,
    pwsiMaxMeshEdgeLength = '', pwsiMaxEdgeCheck = false, pwsiSimplifyGeometry = true, pwsiCoarseMesh = false,
    pwsiStopForTraceRefError = false, pwsiIgnorePwrGndNetTraces = true,
    pwsiFilterSignalNet = true, pwsiNarrowGapModeling = false, pwsiCoplanarTracesModeling = false,
    pwsiAllowNegativeElement = false, pwsiIgnoreAllCurrentSource = false, pwsiAFSConvergenceCriteria = '0.0003', pwsiMinimumLayerThicknessForDcAcUpperFrequency = '0.002', pwsiReusePreFreqPts = false,
    ymlLibraryId = '', enableLinearSweep = false, discreteSweep = false,
    avoidSinglePinGroup = false,
    customFrequencySweep = [{
      start: '100',
      end: '1e8',
      type: LOG,
      step: '100',
      units: {
        start: 'Hz',
        end: 'MHz',
        step: 'MHz'
      }
    }, {
      start: '1e8',
      end: '2e8',
      type: LINEAR,
      step: '5e6',
      units: {
        start: 'MHz',
        end: 'MHz',
        step: 'MHz'
      }
    }],
    // pwsi dc option
    calculateDCPoint = true, bbsFitted = true, pdcEqualPotential = false, portGenFlow = false, accurateMode = false,
    // timeout option
    licWaitSwitch = false, licWait = '1', licWaitUnit = 'min', timeoutSwitch = false, timeoutHours = DEFAULT_TIMEOUT, timeoutMinutes = '0'
  } = config;

  this.type = type;
  this.SAPD = SAPD;
  this.FMAX = FMAX || "2e8";
  this.REFR = REFR;
  this.vrFswSwitch = vrFswSwitch;
  this.VR_FSW = VR_FSW ? VR_FSW : vrFswSwitch ? '600KHz' : '';
  this.includeDC = includeDC || false;
  this.exactDC = exactDC || false;
  this.numCores = numCores;
  this.corePercent = corePercent;
  this.CLIP = CLIP;
  this.ratio = ratio;
  this.enableLinearSweep = enableLinearSweep || false;
  this.discreteSweep = discreteSweep || false;
  this.customFrequencySweep = customFrequencySweep || []


  if (type !== POWERSI) {
    this.SIWE = SIWE;
    this.PKGR = PKGR;
    this.DCF = DCF;
    this.RESA = RESA;
    this.DISC = DISC;
    this.couplingMinFreq = couplingMinFreq;
    this.DC = DC;
  }

  if (type === POWERSI) {
    this.pwsiMaxPadSize = pwsiMaxPadSize;
    this.pwsiMinShapeSize = pwsiMinShapeSize;
    this.pwsiDogleg = pwsiDogleg;
    this.pwsiThermal = pwsiThermal;
    this.pwsiHole = pwsiHole;
    this.pwsiViaHole = pwsiViaHole;
    this.bottomFloating = bottomFloating;
    this.bottomDistance = bottomDistance;
    this.topFloating = topFloating;
    this.topDistance = topDistance;
    this.createModelByPartNumber = true;
    this.autoOutputResult = autoOutputResult;
    this.bbsFitted = bbsFitted;
    this.calculateDCPoint = calculateDCPoint;
    this.pdcEqualPotential = pdcEqualPotential;
    this.portGenFlow = portGenFlow;
    this.accurateMode = accurateMode;
    this.pwsiTab = pwsiTab
    this.pwsiPolygonSimplificationThreshold = pwsiPolygonSimplificationThreshold
    this.pwsiThermalTracePadTraceToShapeFactor = pwsiThermalTracePadTraceToShapeFactor
    this.pwsiThermalTracePadShapeToShapeThreshold = pwsiThermalTracePadShapeToShapeThreshold
    this.pwsiViaAntipadSearchFactor = pwsiViaAntipadSearchFactor
    this.pwsiViaAntipadDistanceRangeFactor = pwsiViaAntipadDistanceRangeFactor
    this.pwsiMaxPadSizeRelative = pwsiMaxPadSizeRelative
    this.pwsiPadRelative = pwsiPadRelative
    this.pwsiApplyToPwrGndNets = pwsiApplyToPwrGndNets
    this.pwsiApplyToSignalNets = pwsiApplyToSignalNets
    this.pwsiArcDiscretizationPrecision = pwsiArcDiscretizationPrecision
    this.pwsiKeepShapesOfDisabledNets = pwsiKeepShapesOfDisabledNets
    this.pwsiDisableCPLSimulation = pwsiDisableCPLSimulation
    this.pwsiSlenderHoleAreaThreshold = pwsiSlenderHoleAreaThreshold
    this.pwsiSlenderHoleSizeThreshold = pwsiSlenderHoleSizeThreshold
    this.pwsiAutoSpecialVoid = pwsiAutoSpecialVoid
    this.pwsiEnhancedPadAntipad = pwsiEnhancedPadAntipad
    this.pwsiDetectActualViaClearance = pwsiDetectActualViaClearance
    this.pwsiAdvancedDiscontinuityHandling = pwsiAdvancedDiscontinuityHandling
    this.pwsiCombineOverlapAntiPadHole = pwsiCombineOverlapAntiPadHole
    this.pwsiEnforceCausalityInMaterial = pwsiEnforceCausalityInMaterial
    this.pwsiTemperature = pwsiTemperature
    this.pwsiBoundaryCondition = pwsiBoundaryCondition
    this.pwsiCompensationWithShapeWidth = pwsiCompensationWithShapeWidth
    this.pwsiInterPlaneCoupling = pwsiInterPlaneCoupling
    this.pwsiAutoEnableRLGCAdjustment = pwsiAutoEnableRLGCAdjustment
    this.pwsiMaxMeshEdgeLength = pwsiMaxMeshEdgeLength
    this.pwsiMaxEdgeCheck = pwsiMaxEdgeCheck
    this.pwsiSimplifyGeometry = pwsiSimplifyGeometry
    this.pwsiCoarseMesh = pwsiCoarseMesh
    this.pwsiStopForTraceRefError = pwsiStopForTraceRefError
    this.pwsiIgnorePwrGndNetTraces = pwsiIgnorePwrGndNetTraces
    this.pwsiFilterSignalNet = pwsiFilterSignalNet
    this.pwsiNarrowGapModeling = pwsiNarrowGapModeling
    this.pwsiCoplanarTracesModeling = pwsiCoplanarTracesModeling
    this.pwsiAllowNegativeElement = pwsiAllowNegativeElement
    this.pwsiIgnoreAllCurrentSource = pwsiIgnoreAllCurrentSource
    this.pwsiAFSConvergenceCriteria = pwsiAFSConvergenceCriteria
    this.pwsiMinimumLayerThicknessForDcAcUpperFrequency = pwsiMinimumLayerThicknessForDcAcUpperFrequency
    this.pwsiReusePreFreqPts = pwsiReusePreFreqPts;
    this.ymlLibraryId = ymlLibraryId;
  }

  if (type === SIWAVE) {
    this.IPC = IPC;
    this.VOID = VOID;
    this.PLNM = PLNM;
    this.PADM = PADM;
    this.VRTC = VRTC;
    this.causality = causality;
    this.customPlaneVoidMeshingSetting = customPlaneVoidMeshingSetting
  }

  if (type === HFSS) {
    this.maxSolution = maxSolution;
    this.maxPasses = maxPasses;
    this.maxDelta = maxDelta;
    this.minConvergedPass = minConvergedPass;
    this.meshingMethod = meshingMethod;
  }

  if ([SIWAVE, HFSS].includes(type)) {
    this.meshFrequency = meshFrequency;
    this.autoMeshFreq = autoMeshFreq;
    this.ramPercent = ramPercent;
    this.ymlLibraryId = ymlLibraryId;
    this.errorTolerance = errorTolerance;
    this.passivity = passivity;
    this.avoidSinglePinGroup = avoidSinglePinGroup;
  }

  if ([SIWAVE, HFSS, PSI].includes(type)) {
    this.auroraAedb = auroraAedb;
  }

  if (type === PSI) {
    this.psiLossModel = psiLossModel;
    this.psiAutoSmallHole = psiAutoSmallHole;
    this.psiSmallHoleSize = psiSmallHoleSize;
    this.psiAdaptSamp = psiAdaptSamp;
    this.psiEnforceDC = psiEnforceDC;
  }

  // multi zone
  if (type === POWERSI) {
    this.multiZone = multiZone;
    this.cleanup = cleanup;
    this.alignZoneShape = alignZoneShape;
    this.distance = distance;
    this.fixOverlapping = fixOverlapping;
    this.createZones = createZones;
  }
  if (type === HFSS) {
    this.multiZone = multiZone;
    this.enableBends = enableBends;
    this.distance = distance;
  }

  //timeout
  this.licWaitSwitch = licWaitSwitch;
  this.licWait = licWait;
  this.licWaitUnit = licWaitUnit;
  this.timeoutSwitch = timeoutSwitch;
  this.timeoutHours = timeoutHours;
  this.timeoutMinutes = timeoutMinutes;
}

function ImpOptimization(config = {}) {
  const { cap, minimizeDecap, removeViolation } = config;
  this.cap = cap ? cap : '100u';
  this.minimizeDecap = minimizeDecap ? minimizeDecap : false;
  this.removeViolation = removeViolation ? removeViolation : false;
}

function PowerDomainRow({ id, PowerNets = [], ReferenceNets = [], extraNets = [], VRM = [], MAIN_POWER_NETS = [], MAIN_REF_NETS = [], Components = [], DEBUG_MONITOR = [], includeExtended, target = [], ports = [], sensePort = [], sensePorts = [], voltage = "1" }) {
  this.id = id;
  this.PowerNets = PowerNets;
  this.ReferenceNets = ReferenceNets;
  this.extraNets = extraNets;
  this.VRM = VRM;
  this.modelName = VRM.length && VRM[0].model ? VRM[0].model.name : "";
  this.MAIN_POWER_NETS = MAIN_POWER_NETS;
  this.MAIN_REF_NETS = MAIN_REF_NETS;
  this.Components = Components;
  this.extendNets = includeExtended ? PowerNets.filter(item => !MAIN_POWER_NETS.includes(item)) : [];
  this.detail = DEBUG_MONITOR;
  this.includeExtended = includeExtended ? includeExtended : false;
  this.targetName = target.map(t => t.targetName).join(', ');
  this.target = target;
  this.ports = ports;
  this.sensePort = sensePort;
  this.sensePorts = sensePorts;
  this.voltage = voltage;
}

function ImpComponent({ comp = {}, PowerNets = [], id, pcbInfo = {}, isPreLayout }) {
  const { model, name, part, pins, COMP_TYPE, usage, value, partNumber, models, applySweep } = comp;
  const { pcbId, pcbName } = pcbInfo
  this.id = id;
  this.power = PowerNets.join(', ');
  this.model = model;
  this.COMP_TYPE = COMP_TYPE;
  this.part = part;
  this.partNumber = partNumber;
  this.usage = usage && usage !== 'Unused' ? usage : COMP_TYPE;
  this.components = [{
    name,
    pins,
    usage
  }]
  this.value = value;
  this.pcbName = pcbName
  this.pcbId = pcbId
  this.isPreLayout = isPreLayout
  this.models = models
  this.applySweep = applySweep
}

function ImpVRM(index, model) {
  this.VRM_COMP = [];
  this.groundPin = [{ comp: '', pins: [] }];
  this.powerPin = [{ comp: '', pins: [] }];
  this.voltage = '';
  this.model = model;
  this.index = index;
}

function ImpVrmDisplay(index) {
  this.pwr = '';
  this.gnd = '';
  this.vrmComp = '';
  this.inductance = '';
  this.index = index;
  this.gndPins = [];
  this.pwrPins = [];
}

function ImpOptions(config = {}) {
  const { includeSense = false, vrmOpen = false, includeDecapModel = false, minimizeLargeDecaps = false, targetMargin = '10', keepDecapWithValueNoLess = '100', keepDecapList = [], decapOptimizationOption = KEEPDECAPWITHVALUENOLESS, rlMin, rlMax } = config;
  this.includeSense = includeSense;
  this.vrmOpen = vrmOpen || false;
  this.includeDecapModel = includeDecapModel;
  this.minimizeLargeDecaps = minimizeLargeDecaps;
  this.targetMargin = targetMargin;
  this.keepDecapWithValueNoLess = keepDecapWithValueNoLess;
  this.keepDecapList = keepDecapList;
  this.decapOptimizationOption = decapOptimizationOption;
  this.rlMax = rlMax;
  this.rlMin = rlMin;
}

function ImpPackageDomain({
  PowerNets = [],
  ReferenceNets = [],
  Components = [],
  diePorts = [],
  bgaPorts = [],
  MAIN_POWER_NETS = [],
  MAIN_REF_NETS = [],
  id = "",
  getIC = false
}) {
  this.PowerNets = PowerNets;
  this.ReferenceNets = ReferenceNets;
  this.Components = Components;
  this.diePorts = diePorts;
  this.bgaPorts = bgaPorts;
  this.MAIN_POWER_NETS = MAIN_POWER_NETS;
  this.MAIN_REF_NETS = MAIN_REF_NETS;
  if (getIC) {
    const BGA = Components.find(item => item.COMP_TYPE === 'BGA');
    const DIE = Components.find(item => item.COMP_TYPE === 'DIE');
    this.BGA = BGA ? BGA.name : '';
    this.DIE = DIE ? DIE.name : '';
    this.id = id
  }
}

function CompareMapRow(props = {}) {
  const { type, origin, bind, key, powerPins, parent, children, id, currentPins } = props;
  this.type = type || '';
  this.origin = origin || '';
  this.bind = bind || '';
  this.key = key || '';
  this.parent = parent || '';
  this.powerPins = powerPins || []
  this.children = children || undefined;
  this.id = id || ''
  this.currentPins = currentPins || []
}

function ImpSetup(setup) {
  const { Optimization, Options, targetIC, setupVersion, designType, designName, targetDie, designId, COMP_PREFIX_LIB, powerNetSelect, target, powerDomainGroups, componentsTableDisplay, hasInterposer } = setup;
  this.Optimization = Optimization ? Optimization : new ImpOptimization();
  this.Options = Options ? Options : new ImpOptions();
  this.targetIC = targetIC;
  this.setupVersion = setupVersion;
  this.type = designType;
  this.designName = designName;
  this.designId = designId;
  this.targetDie = targetDie;
  this.COMP_PREFIX_LIB = COMP_PREFIX_LIB;//temp
  this.targetNets = powerNetSelect ? powerNetSelect.select : [];
  this.target = target;
  this.powerDomainGroups = powerDomainGroups;
  this.componentsTableDisplay = componentsTableDisplay
  this.hasInterposer = hasInterposer
}

function ImpLayout(layout) {
  const { designId, designName, powerDomains = [], connectors = [], ICs = [], Dies = [], Bgas = [], PMICs = [], additionalNets = [], type, ballSizeMap = {}, COMP_PREFIX_LIB, index, extraction = {} } = layout;
  const isPreLayout = designConstructor.isPreLayout(designId);
  this.index = index;
  this.designId = designId;
  this.designName = designName;
  this.powerDomains = powerDomains;
  this.connectors = connectors;
  this.ICs = ICs;
  this.Dies = Dies;
  this.Bgas = Bgas;
  this.PMICs = PMICs;
  this.additionalNets = additionalNets;
  this.type = type;
  this.ballSizeMap = ballSizeMap;
  this.COMP_PREFIX_LIB = COMP_PREFIX_LIB || new CascadeCompPrefixVersion('0.0.1');
  this.extraction = isPreLayout ? new ImpExtraction({ FMAX: '2e9' }) : new ImpExtraction({ ...extraction });
}

export {
  ImpExtraction,
  ImpOptimization,
  PowerDomainRow,
  ImpComponent,
  ImpVRM,
  ImpVrmDisplay,
  SIWAVE,
  HFSS,
  PSI,
  POWERSI,
  ImpOptions,
  ImpPackageDomain,
  CompareMapRow,
  meshMethod_PHIMESH,
  meshMethod_PHIPLUS,
  meshMethod_CLASSICMESH,
  ImpSetup,
  ImpLayout,
  OVERVIEW,
  LAYOUT,
  SIMULATION_BASIC,
  SIMULATION_ADVANCED,
  NATURAL,
  MAGNETIC,
  NOCONSIDERED,
  BASICMODE,
  ENHANCEDMODE,
  LOG,
  LINEAR,
  DECADE,
  STEP,
  BASIC,
  KEEPDECAPWITHVALUENOLESS,
  KEEPDECAPLIST
}