class Canvas {
  constructor(props) {
    const { id, width, height, font } = props;
    this.id = id;
    this.width = width;
    this.height = height;
    this.powerNets = [];
    this.referenceNets = [];
    this.decaps = [];
    this.ctx = this.initCtx(id);
    this.font = font || 12;
    this.virtualCanvas = document.createElement("canvas");
  }

  initCtx = (id) => {
    const { width, height } = this;
    let canvas = document.getElementById(id);
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
    }
    const virtualCanvas = document.createElement("canvas");
    virtualCanvas.width = width || canvas.width;
    virtualCanvas.height = height || canvas.height;
    this.virtualCanvas = virtualCanvas;
    const ctx = virtualCanvas.getContext('2d');
    ctx.lineWidth = 1;
    ctx.font = `normal ${this.font}px Arial`;
    ctx.fillStyle = '#000000';
    return ctx;
  }

  clear = () => {
    const { ctx, width, height, id } = this;
    ctx.clearRect(0, 0, width, height);
    this.ctx = this.initCtx(id);
    let canvas = document.getElementById(id);
    if (canvas) {
      canvas.width = width;
    }
  }

  reset = ({ width, height }) => {
    this.clear();
    this.width = width;
    this.height = height;
    this.ctx = this.initCtx(this.id);
  }

  set = (param, value) => {
    this[param] = value;
  }

  redraw = () => {
    this.clear();
    this.draw();
  }

  draw = () => {
    this.drawNets();

    this.drawBall();

    this.drawToTrueCanvas();
  }

  drawToTrueCanvas = () => {
    const { id, virtualCanvas } = this;
    let canvas = document.getElementById(id);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(virtualCanvas, 0, 0);
  }

  drawBackground = () => {
    const { ctx, width, height } = this;
    ctx.beginPath();
    ctx.fillStyle = '#000000';
    ctx.rect(0, 0, width, height);
    ctx.fill();
    ctx.closePath();
    ctx.fillStyle = '#000000';
  }

  drawNets = () => {
    const { ctx, font, powerNets, referenceNets, width, height, decaps } = this;
    this.netName = []
    ctx.font = `normal ${font}px Arial`;
    ctx.translate(0.5, 0.5);
    ctx.lineWidth = 3;

    ctx.strokeStyle = '#a8c4e6';
    let powerHeight = 40;
    for (let powerNet of powerNets) {
      ctx.beginPath();
      ctx.moveTo(componentWidth, powerHeight);
      ctx.lineTo(componentWidth + parasiticsSpace, powerHeight);
      ctx.closePath();
      ctx.stroke();

      this.drawBall(powerHeight);

      ctx.beginPath();
      ctx.moveTo(componentWidth + parasiticsSpace + parasiticsWidth, powerHeight);
      ctx.lineTo(width - componentWidth, powerHeight);
      ctx.closePath();
      ctx.stroke();
      ctx.fillText(powerNet, 130, powerHeight + 20);
      powerHeight = powerHeight + 100;
    }


    ctx.strokeStyle = '#898989';
    let referenceHeight = height - 40;
    for (let referenceNet of referenceNets) {
      ctx.beginPath();
      ctx.moveTo(componentWidth, referenceHeight);
      ctx.lineTo(width - componentWidth, referenceHeight);
      ctx.closePath();
      ctx.stroke();
      ctx.fillText(referenceNet, 130, referenceHeight + 20);
      referenceHeight = referenceHeight - 100;
    }

    for (let decap of decaps) {
      const { x, decaps: _decaps } = decap;
      ctx.strokeStyle = '#a8c4e6';
      ctx.beginPath();
      ctx.moveTo(x + 0.5 * decapWidth, 40);
      ctx.lineTo(x + 0.5 * decapWidth, decapHeightToNet + decapHeight + 0.5 * decapHeightSpace);
      ctx.closePath();
      ctx.stroke();

      if (_decaps.length > 1) {
        const decapList = _decaps.map(item => item.x);
        const min = Math.min(...decapList);
        const max = Math.max(...decapList);
        ctx.beginPath();
        ctx.moveTo(min + 0.5 * decapWidth, decapHeightToNet + decapHeight + 0.5 * decapHeightSpace);
        ctx.lineTo(max + 0.5 * decapWidth, decapHeightToNet + decapHeight + 0.5 * decapHeightSpace);
        ctx.closePath();
        ctx.stroke();
      }

      for (let _decap of _decaps) {
        const _x = _decap.x + 0.5 * decapWidth;
        ctx.strokeStyle = '#a8c4e6';
        ctx.beginPath();
        ctx.moveTo(_x, decapHeightToNet + decapHeight + 0.5 * decapHeightSpace);
        ctx.lineTo(_x, decapHeightToNet + 1.5 * decapHeight + 0.5 * decapHeightSpace);
        ctx.closePath();
        ctx.stroke();

        ctx.strokeStyle = '#898989';
        ctx.beginPath();
        ctx.moveTo(_x, decapHeightToNet + 1.5 * decapHeight + 0.5 * decapHeightSpace);
        ctx.lineTo(_x, height - 40);
        ctx.closePath();
        ctx.stroke();
      }
    }

    ctx.translate(1, 1)
  }

  drawBall = (powerHeight) => {
    this.drawL(powerHeight, 1);
    this.drawR(powerHeight, 2);
    this.drawC(powerHeight, 3);
  }

  drawL = (powerHeight, index) => {
    const { ctx } = this;
    const ballWidth = parasiticsWidth / 3;
    const start = componentWidth + parasiticsSpace + (index - 1) * ballWidth;

    // L ball
    ctx.beginPath();
    ctx.moveTo(start - 1, powerHeight);
    ctx.lineTo(start + 0.1 * ballWidth + 1, powerHeight);
    ctx.closePath();
    ctx.stroke();

    ctx.lineWidth = 2;
    const segment = 4;
    const arcR = ballWidth / (segment * 2);
    ctx.beginPath();
    ctx.arc(start + 0.1 * ballWidth + arcR, powerHeight, arcR, Math.PI, 0.3 * Math.PI, false);
    ctx.stroke();
    ctx.closePath();

    for (let i = 1; i < segment - 1; i++) {
      ctx.beginPath();
      ctx.arc(start + 0.1 * ballWidth + (i * 2 + 1) * arcR - 0.1 * i * ballWidth, powerHeight, arcR, 0.7 * Math.PI, 0.3 * Math.PI, false);
      ctx.stroke();
      ctx.closePath();
    }

    ctx.beginPath();
    ctx.arc(start + 0.1 * ballWidth + (segment * 2 - 1) * arcR - 0.1 * (segment - 1) * ballWidth, powerHeight, arcR, 0.7 * Math.PI, 0, false);
    ctx.stroke();
    ctx.closePath();

    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.moveTo(start + 0.1 * ballWidth + segment * 2 * arcR - 0.1 * (segment - 1) * ballWidth - 1, powerHeight);
    ctx.lineTo(start + ballWidth + 1, powerHeight);
    ctx.closePath();
    ctx.stroke();
  }

  drawR = (powerHeight, index) => {
    const { ctx } = this;
    const ballWidth = parasiticsWidth / 3;
    const start = componentWidth + parasiticsSpace + (index - 1) * ballWidth;

    // L ball
    ctx.beginPath();
    ctx.moveTo(start - 1, powerHeight);
    ctx.lineTo(start + 0.1 * ballWidth + 1, powerHeight);
    ctx.closePath();
    ctx.stroke();

    ctx.lineWidth = 2;
    const segment = 9;
    const space = ballWidth * 0.8 / segment;
    for (let i = 0; i < segment; i++) {
      const startParam = i === 0 ? 1 : i % 2 === 0 ? 1.2 : 0.8;
      const endParam = i === segment - 1 ? 1 : i % 2 === 0 ? 0.8 : 1.2;

      ctx.beginPath();
      ctx.moveTo(start + 0.1 * ballWidth + i * space, powerHeight * startParam);
      ctx.lineTo(start + 0.1 * ballWidth + (i + 1) * space, powerHeight * endParam)
      ctx.closePath();
      ctx.stroke();
    }

    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.moveTo(start + 0.9 * ballWidth - 1, powerHeight);
    ctx.lineTo(start + ballWidth + 1, powerHeight);
    ctx.closePath();
    ctx.stroke();
  }

  drawC = (powerHeight, index) => {
    const { ctx } = this;
    const ballWidth = parasiticsWidth / 3;
    const start = componentWidth + parasiticsSpace + (index - 1) * ballWidth;

    // C ball
    ctx.beginPath();
    ctx.moveTo(start - 1, powerHeight);
    ctx.lineTo(start + ballWidth + 1, powerHeight);
    ctx.closePath();
    ctx.stroke();

    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(start + 0.5 * ballWidth, powerHeight - 1);
    ctx.lineTo(start + 0.5 * ballWidth, powerHeight + 30);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(start + 0.5 * ballWidth - 10, powerHeight + 30);
    ctx.lineTo(start + 0.5 * ballWidth + 10, powerHeight + 30);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(start + 0.5 * ballWidth - 10, powerHeight + 35);
    ctx.lineTo(start + 0.5 * ballWidth + 10, powerHeight + 35);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(start + 0.5 * ballWidth, powerHeight + 35);
    ctx.lineTo(start + 0.5 * ballWidth, powerHeight + 55);
    ctx.closePath();
    ctx.stroke();

    // arrow
    ctx.beginPath();
    ctx.moveTo(start + 0.5 * ballWidth - 5, powerHeight + 55);
    ctx.lineTo(start + 0.5 * ballWidth, powerHeight + 62);
    ctx.lineTo(start + 0.5 * ballWidth + 5, powerHeight + 55);
    ctx.closePath();
    ctx.stroke();

    ctx.lineWidth = 3;
  }
}

const decapHeight = 100, totalHeight = 600, decapHeightSpace = 100, decapHeightToNet = 150;
const marginWidth = 20, componentWidth = 100, parasiticsWidth = 240, parasiticsSpace = 50, decapSpace = 20, decapWidth = 120, decapWholeWidth = 2 * decapSpace + decapWidth;


function caclDecapPosition(decapGroups) {
  let x = marginWidth + componentWidth, groups = [];
  for (let group of decapGroups) {
    const { name, decaps } = group;
    const width = decaps.length * decapWholeWidth;
    const groupX = x + (width - decapWidth) / 2
    let _x = x, _decaps = [], i = 0;
    for (let decap of decaps) {
      _x = _x + decapSpace
      _decaps.push({ name: decap.name, x: _x + parasiticsWidth + parasiticsSpace, index: i });
      _x = _x + decapSpace + decapWidth;
      i = i + 1;
    }
    groups.push({ name, left: x + parasiticsWidth + parasiticsSpace, top: decapHeightToNet, height: decapHeight * 2 + decapHeightSpace, width: width, x: groupX + parasiticsWidth + parasiticsSpace, decaps: _decaps });
    x = x + width
  }
  return groups
}

export {
  Canvas,
  decapHeight,
  totalHeight,
  marginWidth,
  componentWidth,
  parasiticsWidth,
  parasiticsSpace,
  decapWholeWidth,
  decapHeightToNet,
  decapHeightSpace,
  decapWidth,
  caclDecapPosition
}