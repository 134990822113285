import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import TreeForm from '@/components/TreeNode';
import DelConfirm from '@/components/DelConfirm';
import {
  PROJECT,
  PCB,
  PCB_CHANNEL,
  PCB_PRE_LAYOUT,
  END_TO_END_CHANNEL,
  PCBS,
  END_TO_END_CHANNELS,
  ADD_PCB_CHANNEL,
  PACKAGE,
  EXPERIMENTS
} from '@/constants/treeConstants';
import {
  renderTitle,
  getType,
  getSimSelectKeys,
  getAllSimSelectKeys,
  PROJECTS_INDEX
} from '@/services/Andes_v2';
import projectConstructor from '@/services/Andes_v2/project/projectConstructor';
import {
  updateExpand,
  clearCurrentProject,
  updateProjectMenu,
  updateSelectKeys,
  openProject,
  openPage,
  closeProject,
  createProject,
  projectRename,
  copyProject,
  updateViewList,
  updateTreeList
} from '../store/project/action';
import {
  closeTabFooter
} from '../../MonitorStore/action';
import {
  channelRename,
  copyChannel,
  changeChannelCreateVisible,
  expandChannel
} from '../store/channel/action';
import {
  preLayoutRename
} from '../store/preLayout/action'
import CreateItem from '@/components/CreateItem';
import { filterKeys, updateKeys, updateTreeSelectKeys } from '@/services/helper/filterHelper';
import { strDelimited } from '@/services/helper/split';
import { getPopMessage } from '@/services/helper/popMessage';
import channelConstructor from '@/services/Andes_v2/channel/channelConstructor';
import { createChannel } from '../store/channel/action';
import CreateChannelPanel from '../Channel/createChannelPanel';
import { ANDES_V2 } from '../../../constants/pageType';
import { DESIGN_FAILED } from '@/constants/designCategory';
import { updateSimSelectKeys } from '../store/simulation/action';
import { startChannelExtraction } from '../store/simulation/extraction/action';
import { startEndToEndSimulation } from '../store/simulation/endToEndChannel/action';
import CreateEndToEndChannel from '../EndToEndChannel/createEndToEndChannel';
import endToEndChannelConstructor from '../../../services/Andes_v2/endToEndChannel/endToEndChannelConstructor';
import { createEndToEndChannel, copyEndToEndChannel, endToEndChannelRename } from '../store/endToEndChannel/action';
import { getDefaultName } from '../../../services/helper/setDefaultName';
import { downloadLibrary } from '../../../services/api/library';
import FileSaver from 'file-saver';
import './andesTree.css'
import { createExperiment, renameExperiment } from '../store/sweep/action';
import { EXPERIMENTS_CREATE } from '../../../constants/treeConstants';
import { getIndex } from '../../../services/Andes_v2/sweep';
import sweepConstructor from '../../../services/Andes_v2/sweep/sweepConstructor';
import { BUFFER_SPICE, SPICE } from '../../../constants/libraryConstants';
import { openLibraryFolder } from '../store/library/action';

class AndesTree extends Component {
  constructor(props) {
    super(props);
    this.createChannelInfo = {};
    this.state = {
      visible: {
        delConfirmVisible: false,
        EndToEndChannel: false
      },
      renameProjectInfo: null,
      renamePreLayoutInfo: null,
      renameChannelInfo: null,
      renameEndToEndChannelInfo: null,
      renameSweepInfo: null,
      downloadProgress: -1
    }
  }

  _addItem = (e, itemData, type) => {
    e && e.domEvent && e.domEvent.stopPropagation && e.domEvent.stopPropagation();
    this.spanClick(e);
    switch (itemData.dataType) {
      case PCB:
      case ADD_PCB_CHANNEL:
        this.props._changeChannelCreateVisible(true);
        this.createChannelInfo = { ...itemData };
        break;
      case END_TO_END_CHANNELS:
        this.setState({
          visible: {
            ...this.state.visible,
            EndToEndChannel: true
          }
        });
        this.createEndToEndChannelInfo = { ...itemData };
        break;
      default:
        this.props.actions.addItem(itemData, type);
        break;
    }
  }

  renameClick = (e, itemData, type) => {
    this.spanClick(e);
    this.setState({
      [`rename${type}Info`]: { ...itemData }
    })
  }

  copyClick = (e, itemData, type) => {
    this.spanClick(e);
    this.setState({
      [`copy${type}Info`]: { ...itemData }
    })
  }

  cancelRename = (type) => {
    this.setState({
      [`rename${type}Info`]: null
    })
  }

  downloadFile = (e, itemData) => {
    this.downloadLibraryInfo = { ...itemData }
    this.setState({
      downloadProgress: 0
    })

    this.Time = setInterval(() => {
      let progress = this.state.downloadProgress;
      progress += 4;
      if (progress > 98) {
        progress = 98;
      }
      this.setState({
        downloadProgress: progress
      })
    }, 800);

    downloadLibrary({ libraryId: itemData.id, productType: ANDES_V2 }).then(res => {
      if (res) {
        clearInterval(this.Time);
        this.setState({
          downloadProgress: 100
        })
        const libraryNameArr = itemData.name ? itemData.name.split(".") : [];
        const libraryName = libraryNameArr.length === 1 ? libraryNameArr[0] : libraryNameArr.filter((item, index) => index < libraryNameArr.length - 1).join('_');
        const fileName = libraryName || "library";
        FileSaver.saveAs(res, `${fileName}.zip`);
      } else {
        message.error(`Download ${itemData.name} failed!`)
      }
      setTimeout(() => {
        this.downloadLibraryInfo = null;
        this.setState({
          downloadProgress: -1
        });
      }, 1000)
    });
  }

  saveRename = (itemData, type) => {
    const prevName = this.state[`rename${type}Info`].name;
    this.setState({
      [`rename${type}Info`]: null
    })
    if (prevName === itemData.name) {
      return;
    }

    this.props[`_${type}Rename`](itemData);
  }

  _deleteClick = (e, item) => {
    this.spanClick(e);
    this.delConfirmInfo = { ...item };
    this.setState({
      visible: {
        ...this.state.visible,
        delConfirmVisible: true
      }
    })
  }

  deleteConfirm = (e, data) => {
    this.spanClick(e);
    this.props.actions.delItem(data);
    this.cancelDel();
  }

  cancelDel = (e) => {
    this.spanClick(e);
    this.delConfirmInfo = null;
    this.setState({
      visible: {
        ...this.state.visible,
        delConfirmVisible: false
      }
    })
  }

  projectCreate = (item) => {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={this.props._createProject}
      projectList={projectConstructor.getProjectValues()}
      cancelCreate={this.cancelCreateProject}
    />
  }

  experimentCreate = (item) => {
    return <CreateItem
      itemData={item}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => this.props._createExperiment(data, item)}
      projectList={this.getNameList(item)}
      cancelCreate={() => this.cancelCreateExperiment(item)}
    />
  }

  cancelCreateExperiment = (itemData) => {
    const { treeItems, openProjectId } = this.props;
    const _treeItems = [...treeItems];
    const { projectIndex, PCBsIndex, designIndex, channelIndex } = getIndex({ treeItems, projectId: openProjectId, designId: itemData.designId, channelId: itemData.channelId });
    if (channelIndex > -1) {
      const channelChildren = _treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex].children[designIndex].children[channelIndex].children.filter((item) => item.dataType !== EXPERIMENTS_CREATE)
      _treeItems[PROJECTS_INDEX].children[projectIndex].children[PCBsIndex]
        .children[designIndex].children[channelIndex].children = channelChildren;
      this.props._updateTreeList({ treeItems: _treeItems });
    }
  }

  getNameList = (itemData = {}) => {
    switch (itemData.dataType) {
      case PROJECT:
        return projectConstructor.getProjectValues() || [];
      case PCB_CHANNEL:
        return channelConstructor.getChannelValues(itemData.designId) || [];
      case END_TO_END_CHANNEL:
        return endToEndChannelConstructor.getEndToEndChannelValues(itemData.projectId) || [];
      case EXPERIMENTS:
      case EXPERIMENTS_CREATE:
        return sweepConstructor.getSweepValues(itemData.channelId) || [];
      default: return [];
    }
  }

  copyItem = (item, type) => {
    const nameList = this.getNameList(item);
    const defaultName = getDefaultName({
      nameList,
      defaultKey: `${item.name}_Copy`,
      firstIndex: 1
    });
    return <CreateItem
      itemData={{ name: defaultName }}
      getPopupContainer={() => document.getElementById("root")}
      createNodeId="andes-v2-check-project-icon"
      cancelNodeId="andes-v2-close-project-icon"
      createConfirm={(data) => this.saveCopy(data, item, type)}
      projectList={nameList}
      cancelCreate={() => this.cancelCopy(type)}
    />
  }

  cancelCopy = (type) => {
    this.setState({
      [`copy${type}Info`]: null
    })
  }

  saveCopy = (data, itemData, type) => {
    this.cancelCopy(type);

    if (itemData.dataType === PROJECT) {
      this.props._copyProject(data.name, itemData.id);
      return;
    }
    if (itemData.dataType === PCB_CHANNEL) {
      this.props._copyChannel(data.name, itemData.id);
    }
    if (itemData.dataType === END_TO_END_CHANNEL) {
      this.props._copyEndToEndChannel(data.name, itemData.id);
    }
  }

  channelCreate = () => {
    const { openProjectId } = this.props;

    return <CreateChannelPanel
      openProjectId={openProjectId}
      data={this.createChannelInfo}
      channelList={channelConstructor.getChannelValues(this.createChannelInfo.id)}
      closePanel={({ data, save, content, generationPortErrors, generationPortWarnings, netInfo }) => this.createChannel({ data, save, itemData: this.createChannelInfo, content, generationPortErrors, generationPortWarnings, netInfo })}
    />
  }

  createChannel = ({ data, save, itemData, content, generationPortErrors, generationPortWarnings, netInfo }) => {
    if (!save) {
      this.props._changeChannelCreateVisible(false);
      return;
    }
    const { id } = itemData;// id -> designId
    this.props._createChannel({ data, designId: id, content, generationPortErrors, generationPortWarnings, netInfo });
  }

  cancelCreateProject = () => {
    const { openProjectId, expandedKeys } = this.props;
    if (expandedKeys.find(key => key.match(new RegExp(`${PROJECT}-`)))) {
      this.props._updateProjectMenu({ openProjectId });
    } else {
      this.props._updateProjectMenu();
    }
  }

  endToEndChannelCreate = () => {
    const projectId = strDelimited(this.createEndToEndChannelInfo.key, "-", { returnIndex: 1 });
    return <CreateEndToEndChannel
      data={this.createEndToEndChannelInfo || {}}
      endToEndChannelList={endToEndChannelConstructor.getEndToEndChannelValues(projectId)}
      closePanel={({ data }) => this.addEndToEndChannel({ data, projectId })}
    />
  }

  addEndToEndChannel = ({ data, projectId }) => {
    this.setState({
      visible: {
        ...this.state.visible,
        EndToEndChannel: false
      }
    });
    this.props._createEndToEndChannel({ data, projectId })
  }

  onExpand = (keys, { expanded, node }) => {
    const [key, id] = strDelimited(node.key, "-");

    if (expanded) {
      //Only one project is allowed to open at the same time
      if (key.match(new RegExp(`${PROJECT}$`))) {
        keys = this.expandProject({ keys, id });
      } else if (key.match(new RegExp(`${PCB_CHANNEL}`))) {
        this.props._expandChannel(id, node.dataRef.designId);
      } else if (key.match(new RegExp(`${SPICE}|${BUFFER_SPICE}`))) {
        this.props._openLibraryFolder(node.dataRef, key);
      }
    } else {
      if (key.match(new RegExp(`${PROJECT}$`))) {
        this.props._closeProject();
      }
    }

    this.props._updateExpand(keys);
  }

  expandProject = ({ keys, id }) => {
    const { selectedKeys } = this.props;
    //filter keys -> removed other expanded project
    keys = filterKeys(keys, PROJECT, id);
    if (selectedKeys.length > 0) {
      this.props._updateSelectKeys([]);
      this.props._updateViewList([]);
    };
    //clear current / prev project info
    this.props._clearCurrentProject();
    //close monitor box
    this.props._closeTabFooter();
    //open new project
    this.props._openProject(id);
    return keys;
  };

  onSelect = (selectedKeys, { selected, node }) => {
    if (!selected) return;
    const { expandedKeys, layout, pcbLayout } = this.props;
    const eventKey = node.key;
    const [key, id] = strDelimited(eventKey, "-");
    if (!key) return;
    if (node.dataRef.category === DESIGN_FAILED) { return }
    const keys = key === EXPERIMENTS ? filterKeys(expandedKeys, EXPERIMENTS, id) : [...expandedKeys];

    // expand current click node
    const { _keys, preExpand } = updateKeys(keys, eventKey, expandedKeys);
    this.onExpand(_keys, { expanded: !preExpand, node });

    // update selectedKeys
    const prevSelectedKeys = this.props.selectedKeys;
    const selecteds = updateTreeSelectKeys({
      prevSelectedKeys: [...prevSelectedKeys],
      key,
      eventKey,
      selected,
      layout,
      pcbLayout,
      productType: ANDES_V2
    });
    selecteds.length && this.props._updateSelectKeys(selecteds);
    const preSelected = prevSelectedKeys.includes(eventKey);
    if (!preSelected && [PCB, PCB_PRE_LAYOUT, PACKAGE, PCB_CHANNEL, END_TO_END_CHANNEL, EXPERIMENTS].includes(key)) {
      // open page
      this.props._openPage({ pageType: key, id });
    }
  };

  spanClick = (e) => {
    e && e.stopPropagation && e.stopPropagation();
  }

  changeSimulateAll = (e, itemData) => {
    this.spanClick(e);
    const checked = e.target.checked;
    const { pcbChannelSimSelectKeys, endToEndSimSelectKeys } = this.props;
    switch (itemData.dataType) {
      case PCB:
        const keys = getAllSimSelectKeys(pcbChannelSimSelectKeys, itemData, checked);
        this.props._updateSimSelectKeys(keys, "pcbChannel");
        return;
      case END_TO_END_CHANNELS:
        const _keys = getAllSimSelectKeys(endToEndSimSelectKeys, itemData, checked);
        this.props._updateSimSelectKeys(_keys, "endToEnd");

        return;
      default: return;
    }
  }

  changeSimulateCheck = (e, itemData) => {
    this.spanClick(e);
    const { pcbChannelSimSelectKeys, endToEndSimSelectKeys } = this.props;
    switch (itemData.dataType) {
      case PCB_CHANNEL:
        const keys = getSimSelectKeys(itemData, pcbChannelSimSelectKeys);
        this.props._updateSimSelectKeys(keys, "pcbChannel");
        return;
      case END_TO_END_CHANNEL:
        const _keys = getSimSelectKeys(itemData, endToEndSimSelectKeys);
        this.props._updateSimSelectKeys(_keys, "endToEnd");
        return;
      default: return;
    }
  }

  startSimulation = (e, itemData, type) => {
    const event = type && e ? e.domEvent : e;
    this.spanClick(event);
    const runSeasim = type === "compliance";
    const runAds = type === "simulate";
    const { pcbChannelSimSelectKeys, endToEndSimSelectKeys } = this.props;
    switch (itemData.dataType) {
      case PCBS:
        this.props._startChannelExtraction(pcbChannelSimSelectKeys, { runSeasim, runAds });
        return;
      case END_TO_END_CHANNELS:
        this.props._startEndToEndSimulation(endToEndSimSelectKeys, { runSeasim });
        return;
      default: return;
    }
  }

  updateProjectMenu = () => {
    const { openProjectId } = this.props
    this.props._updateProjectMenu({ openProjectId })
  }

  importExportFolder = (e, item) => {
    e.stopPropagation();
    this.props.actions.importExportData(item)
  }

  render() {
    const { treeItems, expandedKeys, selectedKeys, channelCreateVisible } = this.props;
    const { visible: { delConfirmVisible, EndToEndChannel }, renameProjectInfo } = this.state;
    const type = this.delConfirmInfo ? getType(this.delConfirmInfo.dataType) : "";
    let errorMsg = this.delConfirmInfo ? getPopMessage(type, this.delConfirmInfo.dataType) : "";
    return (
      <Fragment>
        <TreeForm
          treeData={treeItems}
          renderTitle={(item) => renderTitle(item, this.actions(), { ...this.props })}
          onExpand={this.onExpand}
          expandedKeys={expandedKeys}
          onSelect={this.onSelect}
          selectedKeys={selectedKeys}
          autoExpandParent={false}
          defaultExpandParent={false}
          updateProjectMenu={this.updateProjectMenu}
          disableDragProjectId={renameProjectInfo && renameProjectInfo.id}
        // Expand parent: autoExpandParent || !prevProps && defaultExpandParent (Auto find the parent node)
        />

        {delConfirmVisible ? <DelConfirm
          data={this.delConfirmInfo}
          deleteConfirm={this.deleteConfirm}
          cancelDel={this.cancelDel}
          message={errorMsg}
        /> : null}
        {channelCreateVisible ? this.channelCreate() : null}
        {EndToEndChannel ? this.endToEndChannelCreate() : null}
      </Fragment>
    )
  }

  actions = () => {
    return {
      _addItem: this._addItem,
      _deleteClick: this._deleteClick,
      delItem: this.props.actions.delItem,
      editItem: this.props.actions.editItem,
      projectCreate: this.projectCreate,
      experimentCreate: this.experimentCreate,
      spanClick: this.spanClick,
      changeSimulateAll: this.changeSimulateAll,
      changeSimulateCheck: this.changeSimulateCheck,
      startSimulation: this.startSimulation,
      ...this.renameActions(),
      ...this.copyActions(),
      downloadLibraryInfo: this.downloadLibraryInfo,
      downloadFile: this.downloadFile,
      downloadProgress: this.state.downloadProgress,
      importExportFolder: this.importExportFolder,
      showImportExportModel: this.props.actions.showImportExportModel
    }
  }

  renameActions = () => {
    return {
      renameProjectInfo: this.state.renameProjectInfo,
      renamePreLayoutInfo: this.state.renamePreLayoutInfo,
      renameChannelInfo: this.state.renameChannelInfo,
      renameEndToEndChannelInfo: this.state.renameEndToEndChannelInfo,
      renameSweepInfo: this.state.renameSweepInfo,
      renameClick: this.renameClick,
      saveRename: this.saveRename,
      cancelRename: this.cancelRename,
    }
  }

  copyActions = () => {
    const { copyProjectLoadingId, copyChannelLoadingId, copyEndToEndChannelLoadingId } = this.props;
    const { copyProjectInfo, copyChannelInfo, copyEndToEndChannelInfo } = this.state;
    return {
      copyProjectInfo,
      copyChannelInfo,
      copyEndToEndChannelInfo,
      copyClick: this.copyClick,
      copyItem: this.copyItem,
      copyProjectLoadingId,
      copyChannelLoadingId,
      copyEndToEndChannelLoadingId
    }
  }
}

const mapState = (state) => {
  const { AndesV2Reducer: { project, simulation, channel: { channelCreateVisible } } } = state;
  const { treeItems, expandedKeys, selectedKeys, layout, pcbLayout, viewList, openProjectId, copyProjectLoadingId, copyChannelLoadingId, copyEndToEndChannelLoadingId } = project;
  const { pcbChannelSimSelectKeys, endToEndSimSelectKeys } = simulation;
  return {
    treeItems,
    expandedKeys,
    selectedKeys,
    layout,
    pcbLayout,
    viewList,
    pcbChannelSimSelectKeys,
    openProjectId,
    copyProjectLoadingId,
    copyChannelLoadingId,
    copyEndToEndChannelLoadingId,
    endToEndSimSelectKeys,
    channelCreateVisible
  }
}

const mapDispatch = (dispatch) => ({
  _updateExpand(expandedKeys) {
    dispatch(updateExpand(expandedKeys))
  },
  _clearCurrentProject() {
    dispatch(clearCurrentProject())
  },
  _updateProjectMenu(data) {
    dispatch(updateProjectMenu(data))
  },
  _updateSelectKeys(selectedKeys) {
    dispatch(updateSelectKeys(selectedKeys))
  },
  _updateViewList(viewList) {
    dispatch(updateViewList(viewList))
  },
  _closeTabFooter() {
    dispatch(closeTabFooter())
  },
  _openProject(id) {
    dispatch(openProject(id))
  },
  _openPage({ pageType, id }) {
    dispatch(openPage({ pageType, id }))
  },
  _closeProject() {
    dispatch(closeProject())
  },
  _createProject(data) {
    dispatch(createProject(data))
  },
  _createExperiment(data, channelInfo) {
    dispatch(createExperiment(data, channelInfo))
  },
  _createChannel({ data, designId, content, generationPortErrors, generationPortWarnings, netInfo }) {
    dispatch(createChannel({ data, designId, content, generationPortErrors, generationPortWarnings, netInfo }))
  },
  _updateSimSelectKeys(keys, simType) {
    dispatch(updateSimSelectKeys(keys, simType))
  },
  _startChannelExtraction(channelIds, { runSeasim, runAds }) {
    dispatch(startChannelExtraction(channelIds, { runSeasim, runAds }))
  },
  _startEndToEndSimulation(endToEndChannelIds, { runSeasim, runAds }) {
    dispatch(startEndToEndSimulation(endToEndChannelIds, { runSeasim, runAds }))
  },
  _createEndToEndChannel({ data, projectId }) {
    dispatch(createEndToEndChannel({ data, projectId }))
  },
  _ProjectRename(itemData) {
    dispatch(projectRename(itemData))
  },
  _PreLayoutRename(itemData) {
    dispatch(preLayoutRename(itemData))
  },
  _ChannelRename(itemData) {
    dispatch(channelRename(itemData))
  },
  _EndToEndChannelRename(itemData) {
    dispatch(endToEndChannelRename(itemData))
  },
  _copyProject(name, projectId) {
    dispatch(copyProject(name, projectId))
  },
  _copyChannel(name, channelId) {
    dispatch(copyChannel(name, channelId))
  },
  _copyEndToEndChannel(name, endToEndChannelId) {
    dispatch(copyEndToEndChannel(name, endToEndChannelId))
  },
  _changeChannelCreateVisible(visible) {
    dispatch(changeChannelCreateVisible(visible))
  },
  _expandChannel(channelId, designId) {
    dispatch(expandChannel(channelId, designId))
  },
  _updateTreeList({ treeItems }) {
    dispatch(updateTreeList({ treeItems }))
  },
  _SweepRename(itemData) {
    dispatch(renameExperiment(itemData));
  },
  _openLibraryFolder(itemData, libraryType) {
    dispatch(openLibraryFolder(itemData, libraryType))
  },
});

export default connect(mapState, mapDispatch)(AndesTree);