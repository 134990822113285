// ================= Basic Component Model =================
export class BasicCompModel {
  constructor({ signal, pin, net }) {
    this.signal = signal || '';
    this.pin = pin || '';
    this.net = net || '';
  }
}

export class Model {
  constructor({ modelType = '', enableVoltage = '', modelName = '', fileName = '', libType = "", libraryId = "" }) {
    this.modelType = modelType;
    this.enableVoltage = enableVoltage;
    this.modelName = modelName;
    this.fileName = fileName;
    this.libType = libType; // IBIS SPICE
    this.libraryId = libraryId
  }
}