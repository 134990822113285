import DDRSpec from './spec';
import DDRMask from './mask';
import { getEyeMaskDetailPromise, createUpdateEyeMaskPromise, getEyeMaskParametersPromise } from './maskCtrl';
import { DATA_RATE, DATA_RATE_CA, DATA_RATE_CS } from './parameters';
import { message } from 'antd';
import { DDR5, DIMM_DDR_TYPES, LPDDR5 } from '../Rocky/constants';

export function isDefault(id) {
  return ['Standard', 'Aurora'].includes(id);
};

class EyeMask {
  constructor() {
    this.specs = new Map(); // { ddr3: { } }
    this.masks = new Map(); // { ddr3: { Standard: { }, Aurora: { }, user_define: { } } }
  };

  getDDRSpec(type) {
    return new Promise((resolve, reject) => {
      // API - Get DDR spec
      if (!type) {
        resolve(null);
        return;
      }
      if (this.specs.has(type)) {
        const spec = this.specs.get(type);
        resolve(spec);
        return;
      } else {
        const getDataType = DIMM_DDR_TYPES.includes(type) ? DDR5 : type
        getEyeMaskParametersPromise(getDataType).then(spec => {
          const _spec = spec.content;
          const parameters = this.getParameters(type, _spec);
          this.specs.set(type, parameters);
          resolve(parameters);
        }, error => {
          message.error(`Get ${type} specification error! ` + error)
          resolve(null);
        });
      }
    });
  };

  /**
   * 
   * @param {String} type ddr type  DDR3|DDR4|DDR3L|LPDDR4 
   * @param {String} id Standard|Aurora|id
   */
  getDDRMask(type, id, name) {
    const key = type + '_' + id;
    return new Promise((resolve, reject) => {
      if (this.masks.has(type)) {
        if (this.masks.get(type).has(key)) {
          const parameters = this.masks.get(type).get(key);
          resolve(parameters);
          return;
        }
      } else {
        this.masks.set(type, new Map());
      };
      const _isDefault = isDefault(id);
      if (!_isDefault && !id) {
        // Create new ddr mask
        (async () => {
          const newDDREyeMask = await this.createNewDDREyeMask(type, name);
          resolve(newDDREyeMask);
        })();
        return;
      }
      // Standard|Aurora|Customized
      const origin = _isDefault ? id : 'Customized';
      const libraryId = id;
      getEyeMaskDetailPromise(libraryId, origin, type).then(mask => {
        if (!mask) {
          resolve(null);
          return;
        };
        const _mask = mask.content;
        const parameters = this.getParameters(key, _mask, true);
        this.masks.get(type).set(key, { parameters, dataRate: mask.dataRate });
        resolve({ parameters, dataRate: mask.dataRate });
      }, error => {
        message.error('Get eye mask error! ' + error)
        resolve(null);
      });
    });
  };

  async createNewDDREyeMask(type, name) {
    let mask;
    try {
      mask = await getEyeMaskDetailPromise('Standard', 'Standard', type);
    } catch (error) {
      message.error('Get eye mask error! ' + error)
      return null;
    }
    const _mask = mask.content;
    const spec = this.specs.get(type);
    let _default = null, caDefault = null, csDefault = null;
    if (spec && spec.options && spec.options.length) {
      _default = spec.options[0];
      caDefault = spec.options.find(it => it.id === DATA_RATE_CA);
      csDefault = spec.options.find(it => it.id === DATA_RATE_CS);
    }

    const dataRate = _default ? `${_default.definition.default}${_default.unit}` : '1600Mbps';
    const caDataRate = caDefault ? `${caDefault.definition.default}${caDefault.unit}` : (type === LPDDR5 ? '934Mbps' : "");
    const csDataRate = csDefault ? `${csDefault.definition.default}${csDefault.unit}` : (type === LPDDR5 ? '800Mbps' : "");
    const maskId = await createUpdateEyeMaskPromise({
      libraryId: null,
      type,
      content: _mask,
      name,
      dataRate,
      caDataRate,
      csDataRate
    });
    if (maskId) {
      const key = type + '_' + maskId;
      const parameters = this.getParameters(key, _mask, true);
      this.masks.get(type).set(key, { parameters, dataRate },);
      return { newMask: { parameters, dataRate, caDataRate, csDataRate }, maskId };
    };
    return null;
  }

  getParameters(name, data, isMask) {
    if (isMask) {
      // { read_mask: { name: "DDR3 DQ Read Mode Eye Mask", parameters }, write_mask: { name, parameters }, ca_mask: { name, parameters } }
      return Object.keys(data).map(d => {
        const param = new Parameters(d);
        param.init(data[d]);
        if (data[d].name) {
          param.setMaskName(data[d].name);
        }
        return param;
      });
    } else {
      const param = new Parameters(name);
      param.init(data);
      return param;
    }
  };

  maskToJson(maskParams) {
    let maskObj = {};
    if (Array.isArray(maskParams.parameters)) {
      maskParams.parameters.forEach(params => {
        maskObj[params.name] = {
          name: params.maskName ? params.maskName : params.name,
          parameters: params.parameters.map(item => item.eyeMaskToJson())
        }
      })
    };
    return { parameters: maskObj, dataRate: maskParams.dataRate };
  }

  async DDRMaskToJson(type, id) {
    const maskParams = await this.getDDRMask(type, id);
    return this.maskToJson(maskParams);
  }

  setDataRate(type, id, { dataRate, caDataRate, csDataRate }) {
    const key = type + '_' + id;
    const data = this.masks.get(type).get(key);
    this.masks.get(type).set(key, { ...data, dataRate, caDataRate, csDataRate });
  }
};

class Parameters {
  constructor(name) {
    this.name = name ? name : null;
    // this.options = [];
    this.parameters = [];
  };

  init(data) {
    if (!data) return;
    if (data.options) {
      this.options = this.initOptions(data);
    }
    this.parameters = this.initParameters(data);
  };

  initOptions(data) {
    if (!data.options) {
      return [];
    };
    let options = data.options.map(option => {
      const _option = new DDRSpec(option);
      _option.initSpec(option);
      return _option;
    })
    return options;
  };

  initParameters(data) {
    if (!data.parameters) {
      return;
    };
    let parameters = data.parameters.map(param => {
      const _param = new DDRMask(param);
      _param.initMask(param);
      if (param.description) {
        _param.description = param.description;
      }
      return _param;
    })
    return parameters;
  };

  setMaskName(name) {
    // DDR3 DQ Read Mode Eye Mask | DDR3 DQ Write Mode Eye Mask | DDR3 DQ Address/Command Eye Mask
    this.maskName = name;
  }

  getParameters() {
    return this.parameters;
  };

  getParametersKeys() {
    return this.parameters.map(d => d.id);
  };

  getParameterNameById(id) {
    const find = this.parameters.find(d => d.id === id);
    if (find && find.name) {
      return find.name;
    };
    return id;
  };

  getParameterDescription(id) {
    const find = this.parameters.find(d => d.id === id);
    if (find && find.description) {
      return find.description;
    };
    return null;
  }

  getParameterData = (id) => {
    const find = this.parameters.find(d => d.id === id);
    if (find) {
      return find;
    };
    return null;
  }

  getParameterValue = (id, dataRate) => {
    const _this = this;
    if ([DATA_RATE, DATA_RATE_CA, DATA_RATE_CS].includes(id)) {
      return dataRate;
    }
    const value = this.getParameterData(id) ? this.getParameterData(id).calculateValue(_this, dataRate) : null;
    return value;
  }

  setParameterDefinition(id, value) {
    const findIndex = this.parameters ? this.parameters.findIndex(d => d.id === id) : -1;
    if (findIndex < 0) {
      return true;
    };
    this.parameters[findIndex].setDefinitionValue(value);
    return true;
  }

  getOptions() {
    return this.options ? this.options : [];
  }
}

const eyeMask = new EyeMask();

export default eyeMask;
export { EyeMask };