import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Select, Row, Col, Tooltip } from 'antd';
import ScrollableBar from '@/components/ScrollableBar';
import { selectLayer, changeColorBy, changeDisplaySelected } from './store/actionCreators';
import Selection from './selection/Selection';
import './index.css';

const Option = Select.Option;

class LayerTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      open: false
    }
  }

  getLayerButton = (layer, i) => {
    const { checkedlayers, getColor } = this.props;
    const checked = checkedlayers.includes(layer);
    const backgroundColor = getColor(layer)
    return (
      <label className="ant-checkbox-wrapper" key={i} onClick={this.selectLayer(layer)}>
        <span className={`ant-checkbox ${checked ? 'ant-checkbox-checked' : ''}`}>
          <input type="checkbox" className='ant-checkbox-input' value={layer} onClick={this.onCheckLayer(layer)} />
          <span className="ant-checkbox-inner" style={{ backgroundColor }}></span>
        </span>
        <span>{layer}</span>
      </label>
    )
  }

  selectLayer = (layer) => (e) => {
    e.preventDefault();
    this.props.selectLayer([layer])
  }

  onCheckLayer = (layer) => (e) => {
    e.stopPropagation();
    const { checkedlayers, selectLayer } = this.props;
    const newList = [...checkedlayers];
    const index = newList.indexOf(layer);
    if (index < 0) {
      newList.push(layer);
    } else {
      newList.splice(index, 1);
    }
    selectLayer(newList);
  }

  SelectVisibleChange = (open) => {
    this.setState({
      open: open
    })
  }

  visibleChange = (visible) => {
    if (this.state.open) {
      visible = false;
    }
    this.setState({
      visible: visible,
    })
  }

  render() {
    const { metalLayers, changeColorBy, width, leftWidth, displayBoxShow, changeDisplaySelected } = this.props;
    return (
      <Row className='layer-tab'>
        <Col span={18} style={{ padding: '0 10px' }}>
          <ScrollableBar>
            {metalLayers.map(this.getLayerButton)}
          </ScrollableBar>
        </Col>
        <Col span={6} style={{ overflow: 'hidden', padding: '0 2px' }}>
          <label style={{ float: 'right' }} >
            <Selection width={width} leftWidth={leftWidth} displayBoxShow={displayBoxShow} changeDisplaySelected={changeDisplaySelected} />
          </label>
          <label style={{ float: 'right', marginRight: '6 px' }}>
            {/* Colored By:&nbsp; */}
            <Tooltip title='Colored by' overlayClassName='coloredby' open={this.state.visible} mouseEnterDelay={0} mouseLeaveDelay={0} onOpenChange={(visible) => { this.visibleChange(visible) }}>
              <Select defaultValue="net" size='small' style={{ width: '75px', color: '#fff' }} onChange={changeColorBy} onDropdownVisibleChange={this.SelectVisibleChange}>
                <Option value="net">Net</Option>
                <Option value="layer">Layer</Option>
              </Select>
            </Tooltip>
          </label>
        </Col>
        {/* </div> */}
      </Row>
    )
  }
}

const mapState = (state) => {
  const { layers: checkedlayers, displayBoxShow } = state.default;
  return { checkedlayers, displayBoxShow }
}


const mapDispatch = (dispatch) => ({
  selectLayer(checkedList) {
    dispatch(selectLayer(checkedList));
  },
  changeColorBy(value, designID) {
    dispatch(changeColorBy(value, designID))
  },
  changeDisplaySelected(show) {
    dispatch(changeDisplaySelected(show))
  }
})

export default connect(mapState, mapDispatch)(LayerTab);