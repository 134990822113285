import { connect } from 'react-redux';
import {
  selectLayer,
  selectChange,
  openPanel,
  closePanel,
  updateLocation,
  cancelSelectedAll,
  changeDisplaySelected,
  updatePCBRefreshStatus,
} from '../store/Sierra/actionCreators';
import SearchBox from './SearchBox';
import LayerTab from './LayerTab';
import ComponentTable from './DesignInfo/ComponentTable';
import NetTable from './DesignInfo/NetTable';
import higherOrderComponent from '../components/explorerHOC';
import LayoutExplorer from '../components/explorer';
import Stackup from '../components/Stackup_v1';
import { SIERRA } from '../../../constants/pageType';
import {
  saveData,
  updateShowZonesStatus,
  changeStackupZonePanelShow,
  updateSelectedZones
} from '../components/Stackup_v1/store/actionCreators';
import PadStackInfo from './DesignInfo/PadStackInfo';
import PCBDownload from '../components/pcbDownload';

const mapState = (state) => {
  const explorer = state.SierraReducer.explorer;
  const PanelStatus = state.PanelStatus;
  const {
    panelType,
    colorBy,
    simulation,
    showNameStatus,
    selections
  } = explorer
  const { defaultLeft, defaultTop } = PanelStatus;
  const { stackupObj } = state.StackupReducer;
  return {
    panelType, colorBy,
    simulation, defaultLeft, defaultTop, showNameStatus,
    selections,
    stackupObj,
    pageType: SIERRA
  }
}

const mapDispatch = (dispatch) => ({
  updateLocation(location, designID) {
    dispatch(updateLocation(location, designID))
  },
  select(obj = {}, designID) {
    dispatch(selectChange(obj, designID))
  },
  selectLayer(checkedList, designID) {
    dispatch(selectLayer(checkedList, designID));
  },
  openPanel(type, designID) {
    dispatch(openPanel(type, designID))
  },
  closePanel(designID) {
    dispatch(closePanel(designID))
  },
  cancelSelectedAll(designID) {
    dispatch(cancelSelectedAll(designID))
  },
  changeDisplaySelected(show, designID) {
    dispatch(changeDisplaySelected(show, designID))
  },
  updatePCBRefreshStatus(status, designID) {
    dispatch(updatePCBRefreshStatus(status, designID))
  },
  setStackupData({ data, materialList, stackups, zones, unit, designID, bends }) {
    dispatch(saveData({ data, materialList, stackups, zones, unit, designID, bends }));
  },
  _updateShowZonesStatus(show, designID) {
    dispatch(updateShowZonesStatus(show, designID))
  },
  _changeStackupZonePanelShow(show, designID) {
    dispatch(changeStackupZonePanelShow(show, designID))
  },
  _updateSelectedZones(selectedZones, designID) {
    dispatch(updateSelectedZones(selectedZones, designID))
  }
})

const LayoutExplorerHOC = higherOrderComponent({ LayoutExplorer, SearchBox, LayerTab, ComponentTable, NetTable, Stackup, PadStackInfo, PCBDownload });

export default connect(mapState, mapDispatch)(LayoutExplorerHOC);